import { formulaCharactersLimit } from '../constant/charactersLimit';

import * as productEndpoints from 'services/product/endpoints';
import sortByKey from 'utils/sortByKey';

import UploadPolyFlourDocumentSupporting from '../components/qa-prod-spec/upload-polyflour-supporting-document';

const mapValue = (value) => {
  if (value === 'null' || value === undefined) {
    return null;
  } else {
    return value;
  }
};

const cellEditorSelectorNumber = (max = 100, min = 0) => {
  return (params) => {
    return {
      component: 'NumericEditor',
      params: {
        max,
        min,
      },
      popup: true,
    };
  };
};

const cellEditorSelectorText = (characterLimitDefine) => {
  return (params) => {
    return {
      component: 'TextEditor',
      popup: true,
      params: {
        maxLength: characterLimitDefine?.[params?.colDef?.field] ?? null,
      },
    };
  };
};

const valueGetterPercent = () => {
  return (params) => {
    const formatValue = params?.data[`${params.colDef?.field}`];
    return formatValue ? formatValue + '%' : '';
  };
};

export const QA_INGREDIENT_FIELDS = [
  'NameofIngredients',
  'IngredientsPartOf',
  'IngredientsFunction',
  'IngredientsOrigin',
  'IngredientsCountryOfOrigin',
  'ReligiousClaim',
  'GMO',
  'Ionized',
  'Allergens',
  'PercentAddedOnProduction',
  'PercentInFinalProduct',
  'Declare',
  'IngredientsDocuments',
  'Total',
  'IngredientStatement',
  'OrganicOrBio',
  'IngredientsProductClaims',
];

export const ORGANIC_BIO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'Not Applicable', value: 'Not Applicable' },
];

export const QA_INGREDIENT_COLUMNS = {
  columns: [
    {
      field: 'nameOfIngredients',
      fieldNameCamelCase: 'nameofIngredients',
      displayName: 'Name of Ingredients',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Ingredients Part Of',
      field: 'ingredientsPartOf',
      fieldNameCamelCase: 'ingredientsPartOf',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Function',
      field: 'function',
      fieldNameCamelCase: 'ingredientsFunction',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Origin',
      field: 'origin',
      fieldNameCamelCase: 'ingredientsOrigin',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Ingredient Supplier',
      field: 'ingredientSupplier',
      fieldNameCamelCase: 'ingredientSupplier',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Country of Origin',
      field: 'countryOfOrigin',
      fieldNameCamelCase: 'ingredientsCountryOfOrigin',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Religious Claim',
      field: 'religiousClaim',
      fieldNameCamelCase: 'religiousClaim',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'GMO',
      field: 'gmo',
      fieldNameCamelCase: 'GMO',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Bioengineered',
      field: 'bioengineered',
      fieldNameCamelCase: 'Bioengineered',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Ionized',
      field: 'ionized',
      fieldNameCamelCase: 'Ionized',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Allergens',
      field: 'allergens',
      fieldNameCamelCase: 'Allergens',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: '% Added on Production',
      field: 'percentAddedOnProduction',
      fieldNameCamelCase: 'percentAddedOnProduction',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: '% in Final Product',
      field: 'percentInFinalProduct',
      fieldNameCamelCase: 'percentInFinalProduct',
      allowFilter: true,
      allowSort: true,
    },
    {
      displayName: 'Document',
      field: 'documents',
      fieldNameCamelCase: 'ingredientDocument',
      allowFilter: true,
      allowSort: true,
    },
  ],
  gridName: 'qa-spec-ingredient',
};

export const QA_FORMULA_COLUMNS = ({
  productEnums,
  productOwnerId,
  productId,
  options,
}) => {
  let originCodeEnum = [];
  let countryCodeEnum = [];
  productEnums.forEach((enumItem) => {
    if (enumItem?.enumName?.toLowerCase() === 'origincodeenum') {
      originCodeEnum = enumItem?.enumProperties;
    }
    if (enumItem?.enumName?.toLowerCase() === 'countrycodeenum') {
      countryCodeEnum = enumItem?.enumProperties;
    }
  });

  const { useSnapshot } = options || {};

  const cellRenderer = useSnapshot
    ? {
        TextRender: 'TextRenderSnapshot',
        CountryEditorRender: 'CountryEditorRenderSnapshot',
        FileUploadRender: 'FileUploadRenderSnapshot',
      }
    : {
        TextRender: 'TextRender',
        CountryEditorRender: 'CountryEditorRender',
        FileUploadRender: 'FileUploadRender',
      };

  return {
    columns: [
      {
        field: 'salesDesignation',
        displayName: 'Common/Label Name',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorText(formulaCharactersLimit),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'technicalDesignation',
        displayName: 'Technical Designation (IUPAC/INCI)',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorText(formulaCharactersLimit),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'casNumber',
        displayName: 'CAS Number',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorText(formulaCharactersLimit),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'country',
        displayName: 'Country',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: sortByKey(countryCodeEnum, 'enumDisplayName').map(
                (item) => ({
                  value: item.enumDisplayName,
                  displayName: item.enumDisplayName,
                })
              ),
              mapValue,
            },
            popup: true,
          };
        },
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'function',
        displayName: 'Function',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorText(formulaCharactersLimit),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'classificationAndRiskPhrases',
        displayName: 'Classification Risk Phrases',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorText(formulaCharactersLimit),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'origin',
        displayName: 'Origin',
        allowFilter: true,
        allowSort: true,

        valueGetter: function (params) {
          const value = params.data?.origin;

          const formatValue = originCodeEnum?.find(
            (item) => item.enumDisplayName === value
          )?.enumDisplayName;

          return formatValue;
        },

        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: sortByKey(originCodeEnum, 'enumDisplayName').map(
                (item) => ({
                  value: item.enumDisplayName,
                  displayName: item.enumDisplayName,
                })
              ),
              mapValue,
            },
            popup: true,
          };
        },
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'originSpecification',
        displayName: 'Origin Specification',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorText(formulaCharactersLimit),
        cellRenderer: cellRenderer['TextRender'],
      },

      {
        field: 'percentOfRawMaterialSubstance',
        displayName: '% (m/m) subtance in the raw material',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorNumber(),
        valueGetter: valueGetterPercent(),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'percentOfIncorporatedRawMaterial',
        displayName:
          '% (m/m) raw material incorporated in the finished product',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorNumber(),
        valueGetter: valueGetterPercent(),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'percentOfFinishedProductSubstance',
        displayName: '% (m/m) subtance in the finished product',
        allowFilter: true,
        allowSort: true,
        cellEditorSelector: cellEditorSelectorNumber(),
        valueGetter: valueGetterPercent(),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'documents',
        displayName: 'Document',
        allowFilter: true,
        allowSort: true,
        minWidth: 300,
        cellRenderer: cellRenderer['FileUploadRender'],
        cellEditorSelector: (params) => {
          return {
            component: 'FileUploadEditor',
            params: {
              values: originCodeEnum.map((item) => ({
                value: item.displayName,
                displayName: item.enumDisplayName,
              })),
              apiUrl: productEndpoints.UPLOAD_PIM_ASSET,
              additionalBodyPayload: async () => {
                const additionalBodyPayload = {
                  assetType: 'Document',
                  assetSubType: 'General',
                  OwnerId: productOwnerId,
                  productId,
                };

                return additionalBodyPayload;
              },
              supportTypes: QA_SPEC_DOCUMENT_FILE_TYPE,
              confirmDeleteMessage: 'Are you sure you want to delete the file?',
            },

            popup: true,
          };
        },
      },
    ],

    gridName: 'qa-spec-formula',
  };
};

export const QA_FORMULA_ACTIVE_INGREDIENT_COLUMNS = (options) => {
  const { useSnapshot } = options;
  const cellRenderer = useSnapshot
    ? {
        TextRender: 'TextRenderSnapshot',
        CountryEditorRender: 'CountryEditorRenderSnapshot',
      }
    : {
        TextRender: 'TextRender',
        CountryEditorRender: 'CountryEditorRender',
      };

  return {
    columns: [
      {
        field: 'error',
        displayName: ' ',
        maxWidth: 50,
        cellRenderer: 'StatusRender',
        editable: false,
        allowFilter: false,
        allowSort: false,
      },
      {
        field: 'ingredientName',
        displayName: 'Ingredient Name',
        allowFilter: true,
        allowSort: true,
        flex: 1,
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'purposeDescriptor',
        displayName: 'Purpose',
        allowFilter: true,
        allowSort: true,
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        field: 'quantity',
        displayName: 'Quantity',
        allowFilter: true,
        allowSort: true,
        maxWidth: 120,
        minWidth: 120,
        cellRenderer: cellRenderer['TextRender'],
        cellEditorSelector: (params) => {
          return {
            component: 'NumericEditor',
          };
        },
      },
      {
        field: 'quantityUom',
        displayName: 'UOM',
        allowFilter: true,
        allowSort: true,
        maxWidth: 120,
        minWidth: 120,
        cellRenderer: cellRenderer['TextRender'],
        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: [
                {
                  value: 'g',
                  displayName: 'g',
                },
                {
                  value: 'mg',
                  displayName: 'mg',
                },
                {
                  value: 'mcg',
                  displayName: 'mcg',
                },
              ],
            },
          };
        },
      },
    ],
  };
};

export const QA_ALLERGENS_COLUMNS = {
  columns: [
    {
      field: 'allergenType',
      displayName: 'Allergen',
      allowFilter: true,
      allowSort: true,
      editable: false,
      maxWidth: 250,
    },
    {
      field: 'isPresent',
      displayName: 'Is Present',
      allowFilter: true,
      allowSort: true,
    },
    {
      field: 'levelOfContainment',
      displayName: 'Level of Containment',
      allowFilter: true,
      allowSort: true,
    },
    {
      field: 'containmentSource',
      displayName: 'Containment Source',
      allowFilter: true,
      allowSort: true,
      maxWidth: 400,
      minWidth: 300,
    },
  ],
  gridName: 'qa-spec-allergen',
};

export const QA_SPEC_STYLE_CONST = {
  HEADER_OFFSET: 10,
  PRODUCT_HEADER_IMAGE_WRAPPER_PADDING: 7,
  TAB_HEADER_DISTANCE_TO_ICON: 25,
};

export const CATEGORY_LABEL = {
  category1: 'Category I',
  category2: 'Category II',
  category3: 'Category III',
  category4: 'Category IV',
};

export const skuPropertiesList = () => [
  {
    id: 1,
    label: 'qaSpecType',
    value: 'type',
    inputType: 'selectType',
  },
  {
    id: 2,
    label: 'qaSpecNationalBrandTarget',
    value: 'nationalBrandTarget',
    inputType: 'input',
  },
  {
    id: 3,
    label: 'qaSpecSKUType',
    value: 'skuType',
    inputType: 'input',
  },
  {
    id: 4,
    label: 'qaSpecNationalBrandTargetUpc',
    value: 'nationalBrandTargetUpc',
    inputType: 'input',
  },
  {
    id: 5,
    label: 'qaSpecRetailBanner',
    value: 'retailer',
    inputType: 'input',
  },
  {
    id: 6,
    label: 'qaSpecSmartLabelTemplate',
    value: 'smartLabelTemplate',
    inputType: 'selectSmartLabel',
  },
  {
    id: 7,
    label: 'qaSpecSupplierLocation',
    value: 'supplierLocation',
    inputType: 'countrySelect',
  },
  {
    id: 8,
    label: 'qaSpecCategory1',
    value: 'category1',
    inputType: 'disableInput',
  },
  {
    id: 9,
    label: 'qaSpecCategory2',
    value: 'category2',
    inputType: 'disableInput',
  },
  {
    id: 10,
    label: 'qaSpecCategory3',
    value: 'category3',
    inputType: 'disableInput',
  },
  {
    id: 11,
    label: 'qaSpecCategory4',
    value: 'category4',
    inputType: 'disableInput',
  },
];

export const CATEGORY_PRODUCT_LIST = [
  {
    id: 'category1',
    label: 'Category I',
    value: 'category1Id',
    inputType: 'disableInput',
  },
  {
    id: 'category2',
    label: 'Category II',
    value: 'category2Id',
    inputType: 'disableInput',
  },
  {
    id: 'category3',
    label: 'Category III',
    value: 'category3Id',
    inputType: 'disableInput',
  },
  {
    id: 'category4',
    label: 'Category IV',
    value: 'category4Id',
    inputType: 'disableInput',
  },
];

export const defaultType = 'Food';

export const QA_SPEC_CONTACT = {
  primaryBusiness: {
    key: 'primaryBusiness',
    fields: [
      {
        name: 'name',
        type: 'text',
      },
      {
        name: 'addressLine1',
        type: 'text',
      },
      {
        name: 'addressLine2',
        type: 'text',
      },
      {
        name: 'country',
        type: 'country',
      },
      {
        name: 'phone',
        type: 'phone',
      },
      {
        name: 'email',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
    ],
  },

  primaryQc: {
    key: 'primaryQc',
    fields: [
      {
        name: 'name',
        type: 'text',
      },
      {
        name: 'addressLine1',
        type: 'text',
      },
      {
        name: 'addressLine2',
        type: 'text',
      },
      {
        name: 'country',
        type: 'country',
      },
      {
        name: 'phone',
        type: 'phone',
      },
      {
        name: 'email',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
    ],
  },

  _24Hour: {
    key: '_24Hour',
    fields: [
      {
        name: 'name',
        type: 'text',
      },
      {
        name: 'addressLine1',
        type: 'text',
      },
      {
        name: 'addressLine2',
        type: 'text',
      },
      {
        name: 'country',
        type: 'country',
      },
      {
        name: 'phone',
        type: 'phone',
      },
      {
        name: 'email',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
    ],
  },

  packaging: {
    key: 'packaging',
    fields: [
      {
        name: 'name',
        type: 'text',
      },
      {
        name: 'addressLine1',
        type: 'text',
      },
      {
        name: 'addressLine2',
        type: 'text',
      },
      {
        name: 'country',
        type: 'country',
      },
      {
        name: 'phone',
        type: 'phone',
      },
      {
        name: 'email',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
    ],
  },

  productionFacilityNo1: {
    key: 'productionFacilityNo1',
    fields: [
      {
        name: 'facilityName',
        type: 'text',
      },
      {
        name: 'facilityAddressLine1',
        type: 'text',
      },
      {
        name: 'facilityAddressLine2',
        type: 'text',
      },
      {
        name: 'country',
        type: 'country',
      },
      {
        name: 'faxNumber',
        type: 'text',
      },
      {
        name: 'facilityPlantManagerEmail',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
      {
        name: 'facilityPlantManagerName',
        type: 'text',
      },
      {
        name: 'facilityPlantManagerPhone',
        type: 'phone',
      },
      {
        name: 'facilityQaEmail',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
      {
        name: 'facilityQaName',
        type: 'text',
      },
      {
        name: 'facilityQaPhone',
        type: 'phone',
      },
    ],
  },

  productionFacilityNo2: {
    key: 'productionFacilityNo2',
    fields: [
      {
        name: 'facilityName',
        type: 'text',
      },
      {
        name: 'facilityAddressLine1',
        type: 'text',
      },
      {
        name: 'facilityAddressLine2',
        type: 'text',
      },
      {
        name: 'country',
        type: 'country',
      },
      {
        name: 'faxNumber',
        type: 'text',
      },
      {
        name: 'facilityPlantManagerEmail',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
      {
        name: 'facilityPlantManagerName',
        type: 'text',
      },
      {
        name: 'facilityPlantManagerPhone',
        type: 'phone',
      },
      {
        name: 'facilityQaEmail',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
      {
        name: 'facilityQaName',
        type: 'text',
      },
      {
        name: 'facilityQaPhone',
        type: 'phone',
      },
    ],
  },

  productionFacilityNo3: {
    key: 'productionFacilityNo3',
    fields: [
      {
        name: 'facilityName',
        type: 'text',
      },
      {
        name: 'facilityAddressLine1',
        type: 'text',
      },
      {
        name: 'facilityAddressLine2',
        type: 'text',
      },
      {
        name: 'country',
        type: 'country',
      },
      {
        name: 'faxNumber',
        type: 'text',
      },
      {
        name: 'facilityPlantManagerEmail',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
      {
        name: 'facilityPlantManagerName',
        type: 'text',
      },
      {
        name: 'facilityPlantManagerPhone',
        type: 'phone',
      },
      {
        name: 'facilityQaEmail',
        type: 'text',
        rules: [{ type: 'email', message: 'Invalid Email' }],
      },
      {
        name: 'facilityQaName',
        type: 'text',
      },
      {
        name: 'facilityQaPhone',
        type: 'phone',
      },
    ],
  },
};

export const titleQAContactSection = {
  primaryBusiness: 'Primary Business Contact',
  primaryQc: 'Primary QC Contact',
  _24Hour: '24 Hour Contact',
  packaging: 'Packaging Contact',
  productionFacilityNo1: 'Production Facility #1',
  productionFacilityNo2: 'Production Facility #2',
  productionFacilityNo3: 'Production Facility #3',
};

export const INGREDIENT_LIST_ERRORS = {
  INGREDIENT_NAME_EXISTED: 'Ingredient name existed',
  INGREDIENT_NAME_EMPTY: 'Ingredient name cannot be empty',
};

export const ALLERGEN_LIST_ERRORS = {
  LEVEL_OF_CONTAINMENT_EMPTY: 'Level of containment cannot be empty',
  CONTAINMENT_SOURCE_EMPTY: 'Containment source cannot be empty',
};

export const PHYSICAL_AND_CHEMICAL_CHARACTERISTICS_ERRORS = {
  AT_LEAST_ONE_ENTRY: 'At least one field value is required',
};

export const CONTAINMENT_CHARACTERISTICS_ERRORS = {
  AT_LEAST_ONE_ENTRY: 'At least one field value is required',
};

export const MICROBIOLOGICAL_CHARACTERISTICS_ERRORS = {
  AT_LEAST_ONE_ENTRY: 'At least one field value is required',
};

export const ORGANOLEPTIC_CHARACTERISTICS_ERRORS = {
  SPECIFICATION: 'Specification/Description/Target is required',
  TOLERANCE: 'Tolerance is required',
  FREQUENCY: 'Frequency is required',
  RELEASE: 'Release Criteria is required',
};

export const MICROBIOLOGICAL_PARAMETER_OPTIONS = [
  { value: 'APC', displayName: 'APC' },
  { value: 'Campylobacter', displayName: 'Campylobacter' },
  { value: 'Coliforms', displayName: 'Coliforms' },
  { value: 'E. coli', displayName: 'E. coli' },
  {
    value: 'Listeria Monocytogenes',
    displayName: 'Listeria Monocytogenes',
  },
  { value: 'Mold', displayName: 'Mold' },
  { value: 'Other', displayName: 'Other' },
  { value: 'Salmonella', displayName: 'Salmonella' },
  { value: 'Shigella', displayName: 'Shigella' },
  { value: 'Yeast', displayName: 'Yeast' },
  {
    value: 'Staphylococcus Aureus',
    displayName: 'Staphylococcus Aureus',
  },
];

export const TYPE = ['HBC', 'Non-Food', 'Food'];
export const customWidthFormulaColumns = {
  Country: 300,
  documents: 300,
  technicalDesignation: 295,
  classificationAndRiskPhrases: 225,
  percentOfRawMaterialSubstance: 300,
  percentOfIncorporatedRawMaterial: 420,
  percentOfFinishedProductSubstance: 350,
};

export const QA_SPEC_DOCUMENT_FILE_TYPE = [
  'jpeg',
  'png',
  'jpg',
  'tiff',
  'tif',
  'mp4',
  'mov',
  'avi',
  'mp3',
  'zip',
  'rar',
  'txt',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'bmp',
  'gif',
  'webm',
  'csv',
];

export const documentTypes = ['ppt', 'pptx', 'doc', 'docx', 'pdf'];

export const PRODUCT_SPEC_FORM_SECTION_SCHEMA = {
  DATE_CODING: {
    key: 'productSpecDateCoding',
    fields: [
      {
        name: 'codeDateStamp',
        type: 'text',
      },
      {
        name: 'codeDateType',
        type: 'text',
      },
      {
        name: 'codeDateFormula',
        type: 'text',
      },
      {
        name: 'codeDateExample',
        type: 'text',
      },
      {
        name: 'batchCodeExample',
        type: 'text',
      },
      {
        name: 'lotCodeExample',
        type: 'text',
      },
    ],
  },

  CASE_TEMPERATURE: {
    key: 'productSpecCaseTemperature',
    fields: [
      {
        name: 'caseTemperatureRangeHigh',
        type: 'number',
      },
      {
        name: 'caseTemperatureRangeLow',
        type: 'number',
      },
      {
        name: 'caseShippingTemperatureHigh',
        type: 'number',
      },
      {
        name: 'caseShippingTemperatureLow',
        type: 'number',
      },
    ],
  },

  POLYFLUOROALKYL_SUBSTANCES: {
    key: 'polyfluoroalkylSubstances',
    fields: [
      {
        name: 'productContainPfas',
        type: 'select',
        options: (enumOptions) =>
          enumOptions?.polyflour?.['productContainPfas'],
        formItemProps: {
          labelCol: { span: 24 },
          wrapperCol: {
            span: 24,
          },
        },
      },
      {
        name: 'productPackagingContainPfas',
        type: 'select',
        options: (enumOptions) =>
          enumOptions?.polyflour?.['productPackagingContainPfas'],
        formItemProps: {
          labelCol: { span: 24 },
          wrapperCol: {
            span: 24,
          },
        },
      },
      {
        name: 'pfasTested',
        type: 'select',
        options: (enumOptions) => enumOptions?.polyflour?.['pfasTested'],
        formItemProps: {
          labelCol: { span: 24 },
          wrapperCol: {
            span: 24,
          },
        },
      },
      {
        name: 'pfasStatement',
        type: 'textarea',
        inputProps: {
          showCount: true,
          maxLength: 500,
          rows: 4,
        },
        formItemProps: {
          labelCol: { span: 24 },
          wrapperCol: {
            span: 24,
          },
        },
      },
      {
        name: 'supportingDocumentation',
        inputRender: ({ isEdit }) => (
          <UploadPolyFlourDocumentSupporting isEdit={isEdit} />
        ),
        formItemProps: {
          labelCol: { flex: '175px' },
          wrapperCol: {
            flex: 'auto',
          },
        },
      },
    ],
  },

  SHELF_LIFE: {
    key: 'productSpecShelfLife',
    fields: [
      {
        name: 'minimumShelfLife',
        type: 'number',
      },
      {
        name: 'maximumShelfLife',
        type: 'number',
      },
      {
        name: 'shelfLifeAfterOpening',
        type: 'text',
      },
    ],
  },

  DIETARY: {
    key: 'productSpecDietaryCertifications',
    fields: [
      {
        name: 'kosherYN',
        type: 'checkbox',
        col: 8,
        haveUploadCertificate: true,
      },
      {
        name: 'kosherCertifier',
        isList: true,
        type: 'select',
        enum: 'KosherCertificationEnum',
        col: 16,
        layout: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
      },
      {
        name: 'organicYN',
        type: 'checkbox',
        col: 8,
        haveUploadCertificate: true,
      },
      {
        name: 'organicCertifier',
        type: 'text',
        isList: true,
        col: 16,
        layout: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
      },
      {
        name: 'halalYN',
        type: 'checkbox',
        col: 8,
        haveUploadCertificate: true,
      },
      {
        name: 'halalCertifier',
        type: 'select',
        enum: 'HalalCertifierEnum',
        isList: true,
        col: 16,
        layout: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
      },
      {
        name: 'nonGMOYN',
        type: 'checkbox',
        col: 8,
        haveUploadCertificate: true,
      },
      {
        name: 'nonGMOCertifier',
        type: 'select',
        enum: 'NonGMOCertifierEnum',
        isList: true,
        col: 16,
        layout: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
      },
      {
        name: 'isoyn',
        type: 'checkbox',
        col: 8,
        haveUploadCertificate: true,
      },
      {
        name: 'isoCertifier',
        type: 'text',
        isList: true,
        col: 16,
        layout: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
      },
      {
        name: 'brcyn',
        type: 'checkbox',
        col: 8,
        haveUploadCertificate: true,
      },
      {
        name: 'brcCertifier',
        type: 'text',
        isList: true,
        col: 16,
        layout: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
      },
      {
        name: 'ifsyn',
        type: 'checkbox',
        col: 8,
        haveUploadCertificate: true,
      },
      {
        name: 'ifsCertifier',
        type: 'text',
        isList: true,
        col: 16,
        layout: { labelCol: { span: 6 }, wrapperCol: { span: 18 } },
      },
    ],
  },

  STATEMENT: {
    key: 'productSpecStatement',
    fields: [
      {
        name: 'gmoStatement',
        type: 'text',
      },
      {
        name: 'nonGmoStatement',
        type: 'text',
      },
      {
        name: 'productContainsBioengineeredIngredients',
        type: 'productContainsBioengineeredIngredients',
        option: 'productContainsBioengineeredIngredients',
      },
      {
        name: 'bioengineeredStatement',
        type: 'text',
      },
      // {
      //   name: 'nonfoodIngredientStatement',
      //   type: 'text',
      // },
      {
        name: 'rbghBstStatement',
        type: 'text',
      },
      {
        name: 'californiaProp65WarningStatement',
        type: 'text',
      },
      {
        name: 'fairTradeStatement',
        type: 'text',
      },
      {
        name: 'usdaStatement',
        type: 'text',
      },
    ],
  },

  LOCATION: {
    key: 'productSpecLocation',
    fields: [
      {
        name: 'targetMarket',
        type: 'targetMarket',
        option: 'targetMarket',
      },
    ],
  },
};

export const ALLERGEN_CONTAINMENT_SOURCE_OPTIONS = [
  {
    value: 'Manufactured on shared equipment',
    displayName: 'Manufactured on shared equipment',
  },
  {
    value: 'Manufactured in the same facility',
    displayName: 'Manufactured in the same facility',
  },
];

export const PRODUCT_SPEC_DIETARY_CERTIFICATION_FIELDS = {
  kosherYN: 'kosherCertifier',
  organicYN: 'organicCertifier',
  halalYN: 'halalCertifier',
  nonGMOYN: 'nonGMOCertifier',
  isoyn: 'isoCertifier',
  brcyn: 'brcCertifier',
  ifsyn: 'ifsCertifier',
};

export const PRODUCT_SPEC_DIETARY_CERTIFICATION_MAP_FIELD_SCHEMA_TO_CERTIFICATE_TYPE =
  {
    kosherCertifier: 'Kosher',
    organicCertifier: 'Organic',
    halalCertifier: 'Halal',
    nonGMOCertifier: 'Non GMO',
    isoCertifier: 'ISO',
    brcCertifier: 'BRC',
    ifsCertifier: 'IFS',
  };

export const MINIMUM_INPUT_FIELD = ['productSpecShelfLife'];

const INGREDIENT_STATEMENT_LIMIT_CHARACTERS = 1000;

export const INGREDIENT_LIMIT_CHARACTERS = {
  nameOfIngredients: 250,
  function: 5000,
  percentAddedOnProduction: 5000,
  percentInFinalProduct: Math.pow(10, 50) - 1,
  ingredientStatement: INGREDIENT_STATEMENT_LIMIT_CHARACTERS,
  ingredientClaims: 500,
};

export const FRESH_MEAT_LIMIT_CHARACTERS = {
  typeOfCutOrTrimSpec: 500,
};

export const FRESH_SEAFOOD_LIMIT_CHARACTERS = {
  typeOfCutOrTrimSpec: 500,
  methodOfProduction: 500,
  methodOfCapture: 500,
  productionSystem: 500,
  seafoodRiskCategory: 500,
  safeHandlingInstructions: 500,
};

export const PACKAGING_LIMIT_CHARACTERS = {
  ingredientStatement: INGREDIENT_STATEMENT_LIMIT_CHARACTERS,
  allergenStatement: 500,
  storageInstructions: 500,
  handlingInstructions: 500,
  romanceCopy: 500,
  usage: 500,
  cookingInstructions: 500,
  recipe: 500,
  bioengineeredStatement: 500,
  warningStatement: 500,
  otherCertifications: 500,
  thawingInstructions: 500,
};

export const ALLERGEN_LIMIT_CHARACTERS = {
  allergenStatement: 1200,
};

export const PRODUCT_SPEC_LIMIT_CHARACTERS = {
  document: {
    characteristicsType: 100,
  },
};

export const DOCUMENT_CERTIFICATE_LIMIT_CHARACTER = {
  documentType: 50,
  certificateBody: 50,
};

export const COMPONENTS_ERROR = {
  EXISTED: 'Component existed',
  EMPTY: 'Component cannot be empty',
};

export const DAIRY_LIMIT_CHARACTERS = {
  liveActiveCultures: 500,
  complianceStatement: 500,
  referralStatements: 500,
  eggsGrading: 500,
  relativeComparativeClaims: 500,
  healthClaims: 500,
  structureFunctionClaims: 500,
};

export const DAIRY_SECTION_TEXT_AREA = [
  {
    displayName: 'Quality Guarantee',
    fieldName: 'qualityGuarantee',
    limitCharacters: 500,
  },
  {
    displayName: 'Compliance Statement',
    fieldName: 'complianceStatement',
    limitCharacters: 500,
  },
  {
    displayName: 'Health Claims',
    fieldName: 'healthClaims',
    limitCharacters: 500,
  },
];

//* ticket #6159 - separate DAIRY_SECTION_TEXT_AREA to DAIRY_SECTION_TEXT_AREA_2 in order to put Gluten Free Claim between
export const DAIRY_SECTION_TEXT_AREA_2 = [
  {
    displayName: 'Structure / Function Claims',
    fieldName: 'structureFunctionClaims',
    limitCharacters: 500,
  },
  {
    displayName: 'Relative / Comparative Claims',
    fieldName: 'relativeComparativeClaims',
    limitCharacters: 500,
  },
  {
    displayName: 'Safe Handling Instructions',
    fieldName: 'safeHandlingInstructions',
    limitCharacters: 500,
  },
  {
    displayName: 'Live and Active Culture',
    fieldName: 'liveActiveCultures',
    limitCharacters: 500,
  },
];

export const MEAT_FISH_SECTION_TEXT_AREA = [
  {
    displayName: 'Safe Handling Instructions',
    fieldName: 'safeHandlingInstructions',
    limitCharacters: 500,
  },
  {
    displayName: 'Quality Guarantee',
    fieldName: 'qualityGuarantee',
    limitCharacters: 500,
  },
  {
    displayName: 'Thawing Instructions',
    fieldName: 'thawingInstructions',
    limitCharacters: 500,
  },
];

export const CONTAINER_EXPANDED_WIDTH = 1300;
export const CONTAINER_COLLAPSED_WIDTH = 1202;
export const NAV_WIDTH = 240;
