import React, { useEffect, useRef } from 'react';

import { Select, Col, Row, Typography } from 'antd';

import { useParams } from 'react-router-dom';

import classnames from 'classnames';

import { WithLoading, Form } from 'common/components';

import {
  EDIT_SECTION,
  EditTabSectionWrapper,
} from 'pages/qa-spec/components/edit-tab-checker';

import EditContainer from '../container/EditContainer';

import { useGetEnumsByEnumName } from 'hooks';
import {
  useGetFreshProduce,
  useQaGetFreshProduceSnapshot,
  useSaveFreshProduce,
} from './hooks';

import {
  HighlightContent,
  PrimaryFieldSnapshot,
  RetailerSnapshot,
} from '../qa-prod-spec/snapshot/RetailerSnapshot';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import './QaFreshProduce.less';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const PRODUCE_GRADING_ENUM = 'ProduceGradingEnum';
const RISK_CLASSIFICATION_ENUM = 'RiskClassificationEnum';
const FANCY_ENUM = 'FancyEnum';
const READY_TO_EAT_ENUM = ['Yes', 'No'];
const CLEAN_METHOD_ENUM = 'CleaningMethodEnum';

const QaFreshProduce = () => {
  const [formInstance] = Form.useForm();

  const { id: productId } = useParams();
  const refEditContainer = useRef();

  const { data: freshProduceData, isLoading } = useGetFreshProduce(productId);
  const { qaFreshProduceSnapshot } = useQaGetFreshProduceSnapshot();

  const freshProduceMutation = useSaveFreshProduce(productId);
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  useEffect(() => {
    if (freshProduceData) {
      formInstance.setFieldsValue({ ...freshProduceData });
    }
  }, [formInstance, freshProduceData]);

  const produceGradingEnum = useGetEnumsByEnumName(PRODUCE_GRADING_ENUM);
  const riskClassificationEnum = useGetEnumsByEnumName(
    RISK_CLASSIFICATION_ENUM
  );
  const fancyEnum = useGetEnumsByEnumName(FANCY_ENUM);
  const cleaningMethodEnum = useGetEnumsByEnumName(CLEAN_METHOD_ENUM);

  const handleSave = (toggleEditMode, ixoneIgnore) => {
    const formValues = formInstance.getFieldsValue() ?? {};
    const params = {
      productId,
      ...formValues,
    };

    freshProduceMutation.mutate(params, {
      onSuccess: async () => {
        toggleEditMode();
        await handleUpdateIXONEShield(ixoneIgnore);
      },
    });
  };

  const handleCancel = () => {
    formInstance.setFieldsValue({ ...freshProduceData });
  };

  return (
    <IXONECertificationWrapper
      onConfirm={(ixoneIgnore) => {
        const toggleEditMode = refEditContainer?.current?.onToggleEditMode;
        handleSave(toggleEditMode, ixoneIgnore);
      }}
      showModal
      type={TYPE_VIEW.EDIT_PRODUCT}
    >
      {(handleConfirmIXONE) => (
        <Form
          className='qa-fresh-produce'
          form={formInstance}
          style={{ paddingRight: 5 }}
          labelCol={{ flex: '160px' }}
        >
          <WithLoading loading={isLoading}>
            <EditContainer
              ref={refEditContainer}
              type={EDIT_SECTION.PROD_FRESH_PRODUCE}
              productId={productId}
              saveLoading={freshProduceMutation.isLoading}
              handleSave={async () => {
                handleConfirmIXONE({
                  originalData: freshProduceData,
                  changedFields: await formInstance.validateFields(),
                });
              }}
              handleCancel={handleCancel}
              float
            >
              {(toggleEditMode) => {
                const disabled = !toggleEditMode;

                const {
                  endUse,
                  cookingInstructions,
                  produceGrading,
                  riskClassification,
                  fancy,
                  readytoEat,
                  cleaningMethod,
                } = freshProduceData ?? {};

                const {
                  endUse: endUseSnapshot,
                  cookingInstructions: cookingInstructionsSnapshot,
                  produceGrading: produceGradingSnapshot,
                  riskClassification: riskClassificationSnapshot,
                  fancy: fancySnapshot,
                  readytoEat: readytoEatSnapshot,
                  cleaningMethod: cleaningMethodSnapshot,
                } = qaFreshProduceSnapshot;

                return (
                  <EditTabSectionWrapper.CheckerWrapper
                    isEdit={toggleEditMode}
                    type={EDIT_SECTION.PROD_FRESH_PRODUCE}
                  >
                    <Row
                      className={classnames('qa-fresh-produce__input', {
                        'qa-fresh-produce__input--edit': toggleEditMode,
                      })}
                      // style={{ pointerEvents: toggleEditMode ? 'auto' : 'none' }}
                    >
                      <Col span={8}>
                        <Row>
                          <Col span={24}>
                            <QaFreshProduceSnapshot
                              supplierModifiedValue={endUse}
                              snapshotValue={endUseSnapshot}
                              highlightContent={
                                <HighlightContent
                                  label='End Use:'
                                  value={endUseSnapshot}
                                />
                              }
                              placement='right'
                            >
                              <Form.Item
                                name='endUse'
                                label={<LabelForm label='End Use' />}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <PrimaryFieldSnapshot
                                  autoSize={{ minRows: 4, maxRows: 5 }}
                                  readOnly={disabled}
                                  maxLength={500}
                                  showCount
                                  variant='textarea'
                                />
                              </Form.Item>
                            </QaFreshProduceSnapshot>
                          </Col>
                          <Col span={24}>
                            <QaFreshProduceSnapshot
                              supplierModifiedValue={cookingInstructions}
                              snapshotValue={cookingInstructionsSnapshot}
                              highlightContent={
                                <HighlightContent
                                  label='Cooking Instructions / Directions:'
                                  value={cookingInstructionsSnapshot}
                                />
                              }
                              placement='right'
                            >
                              <Form.Item
                                name='cookingInstructions'
                                label={
                                  <LabelForm label='Cooking Instructions / Directions' />
                                }
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <PrimaryFieldSnapshot
                                  autoSize={{ minRows: 4, maxRows: 5 }}
                                  readOnly={disabled}
                                  maxLength={500}
                                  showCount
                                  variant='textarea'
                                />
                              </Form.Item>
                            </QaFreshProduceSnapshot>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={16}>
                        <Row>
                          <Col span={12}>
                            <QaFreshProduceSnapshot
                              supplierModifiedValue={produceGrading}
                              snapshotValue={produceGradingSnapshot}
                              highlightContent={
                                <HighlightContent
                                  label='Produce Grading:'
                                  value={produceGradingSnapshot}
                                />
                              }
                            >
                              <Form.Item
                                name='produceGrading'
                                label={<LabelForm label='Produce Grading' />}
                              >
                                <PrimaryFieldSnapshot
                                  disabled={disabled}
                                  variant='select'
                                >
                                  {produceGradingEnum.map(
                                    ({ enumDisplayName }) => (
                                      <Select.Option key={enumDisplayName}>
                                        {enumDisplayName}
                                      </Select.Option>
                                    )
                                  )}
                                </PrimaryFieldSnapshot>
                              </Form.Item>
                            </QaFreshProduceSnapshot>
                          </Col>
                          <Col span={12}>
                            <QaFreshProduceSnapshot
                              supplierModifiedValue={riskClassification}
                              snapshotValue={riskClassificationSnapshot}
                              highlightContent={
                                <HighlightContent
                                  label='Risk Classification:'
                                  value={riskClassificationSnapshot}
                                />
                              }
                            >
                              <Form.Item
                                name='riskClassification'
                                label={
                                  <LabelForm label='Risk Classification' />
                                }
                              >
                                <PrimaryFieldSnapshot
                                  disabled={disabled}
                                  variant='select'
                                >
                                  {riskClassificationEnum.map(
                                    ({ enumDisplayName }) => (
                                      <Select.Option key={enumDisplayName}>
                                        {enumDisplayName}
                                      </Select.Option>
                                    )
                                  )}
                                </PrimaryFieldSnapshot>
                              </Form.Item>
                            </QaFreshProduceSnapshot>
                          </Col>
                          <Col span={24}>
                            <QaFreshProduceSnapshot
                              supplierModifiedValue={fancy}
                              snapshotValue={fancySnapshot}
                              highlightContent={
                                <HighlightContent
                                  label='Fancy:'
                                  value={fancySnapshot}
                                />
                              }
                            >
                              <Form.Item
                                name='fancy'
                                label={<LabelForm label='Fancy' />}
                              >
                                <PrimaryFieldSnapshot
                                  disabled={disabled}
                                  variant='select'
                                >
                                  {fancyEnum.map(({ enumDisplayName }) => (
                                    <Select.Option key={enumDisplayName}>
                                      {enumDisplayName}
                                    </Select.Option>
                                  ))}
                                </PrimaryFieldSnapshot>
                              </Form.Item>
                            </QaFreshProduceSnapshot>
                          </Col>
                          <Col span={24}>
                            <QaFreshProduceSnapshot
                              supplierModifiedValue={readytoEat}
                              snapshotValue={readytoEatSnapshot}
                              highlightContent={
                                <HighlightContent
                                  label='Ready to Eat:'
                                  value={readytoEatSnapshot}
                                />
                              }
                            >
                              <Form.Item
                                name='readytoEat'
                                label={<LabelForm label='Ready to Eat' />}
                              >
                                <PrimaryFieldSnapshot
                                  listHeight={140}
                                  disabled={disabled}
                                  variant='select'
                                >
                                  {READY_TO_EAT_ENUM.map((value) => (
                                    <Select.Option key={value}>
                                      {value}
                                    </Select.Option>
                                  ))}
                                </PrimaryFieldSnapshot>
                              </Form.Item>
                            </QaFreshProduceSnapshot>
                          </Col>
                          <Col span={24}>
                            <QaFreshProduceSnapshot
                              supplierModifiedValue={cleaningMethod}
                              snapshotValue={cleaningMethodSnapshot}
                              highlightContent={
                                <HighlightContent
                                  label='Cleaning Method:'
                                  value={cleaningMethodSnapshot}
                                />
                              }
                            >
                              <Form.Item
                                name='cleaningMethod'
                                label={<LabelForm label='Cleaning Method' />}
                              >
                                <PrimaryFieldSnapshot
                                  listHeight={140}
                                  disabled={disabled}
                                  variant='select'
                                >
                                  {cleaningMethodEnum.map(
                                    ({ enumDisplayName }) => (
                                      <Select.Option key={enumDisplayName}>
                                        {enumDisplayName}
                                      </Select.Option>
                                    )
                                  )}
                                </PrimaryFieldSnapshot>
                              </Form.Item>
                            </QaFreshProduceSnapshot>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </EditTabSectionWrapper.CheckerWrapper>
                );
              }}
            </EditContainer>
          </WithLoading>
        </Form>
      )}
    </IXONECertificationWrapper>
  );
};

const LabelForm = ({ label }) => {
  return <Typography.Text strong>{label}</Typography.Text>;
};

const QaFreshProduceSnapshot = (props) => {
  const { isSuccess } = useQaGetFreshProduceSnapshot();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  if (isSuccess && isToggleSnapshot) {
    return <RetailerSnapshot {...props} />;
  }

  return <>{props.children}</>;
};

export default QaFreshProduce;
