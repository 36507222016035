export const CHARACTERS_LIMIT = {
  ProductName: {
    maxLength: 50,
  },
  ApplicableReferenceStandard: {
    maxLength: 500,
  },
  NameofIngredients: {
    maxLength: 250,
  },
  IngredientsFunction: {
    maxLength: 5000,
  },
  IngredientStatement: {
    maxLength: 1000,
  },
  PercentInFinalProduct: {
    maxLength: 50,
  },
  PercentAddedOnProduction: {
    maxLength: 5000,
  },
  IngredientsProductClaims: {
    maxLength: 500,
  },
  AllergenStatement: {
    maxLength: 1200,
  },
  Component: {
    maxLength: 50,
  },
  CAS_INCI_HSCode: {
    maxLength: 50,
  },
  ComponentsFunction: {
    maxLength: 50,
  },
  Manufacturer: {
    maxLength: 50,
  },
  QuantityInPercent: {
    maxLength: 100,
  },
  // This field will be updated to numeric type. So we don't need to maxLength for this field.
  // TotalWeight: {
  //   maxLength: 100,
  // },
  ComponentsProductClaims: {
    maxLength: 500,
  },
  SalesDesignation: {
    maxLength: 100,
  },
  TechnicalDesignation_IUPAC_INCI: {
    maxLength: 100,
  },
  CASNumber: {
    maxLength: 50,
  },
  FormulaFunction: {
    maxLength: 100,
  },
  ClassificationAndRiskPhrases: {
    maxLength: 50,
  },
  OriginSpecification: {
    maxLength: 50,
  },
  PercentOfRawMaterialSubstance: {
    maxLength: 50,
  },
  PercentOfIncorporatedRawMaterial: {
    maxLength: 50,
  },
  PercentOfFinishedProductSubstance: {
    maxLength: 50,
  },
  FormulaProductClaims: {
    maxLength: 500,
  },
  Process_Stage: {
    maxLength: 150,
  },
  NatureOfTheTests: {
    maxLength: 5000,
  },
  TargetValueAndTolerances: {
    maxLength: 5000,
  },
  Frequency: {
    maxLength: 5000,
  },
  Test: {
    maxLength: 150,
  },
  CriticalPointEstablishedInHACCP: {
    maxLength: 5000,
  },
  ManufacturingProcessDocumentName: {
    maxLength: 100,
  },
  OrganolepticCharacteristicsApperance_Specification: {
    maxLength: 5000,
  },
  OrganolepticCharacteristicsApperance_Tolerance: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsApperance_Frequency: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsColor_Specification: {
    maxLength: 5000,
  },
  OrganolepticCharacteristicsColor_Tolerance: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsColor_Frequency: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsOdour_Specification: {
    maxLength: 5000,
  },
  OrganolepticCharacteristicsOdour_Tolerance: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsOdour_Frequency: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsTaste_Specification: {
    maxLength: 5000,
  },
  OrganolepticCharacteristicsTaste_Tolerance: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsTaste_Frequency: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsTexture_Specification: {
    maxLength: 5000,
  },
  OrganolepticCharacteristicsTexture_Tolerance: {
    maxLength: 150,
  },
  OrganolepticCharacteristicsTexture_Frequency: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsParameter: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsValue: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsSpecification: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsTolerance: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsReferenceStandard: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsReferenceMethod: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsFrequency: {
    maxLength: 150,
  },
  PhysicalAndChemicalCharacteristicsDocumentName: {
    maxLength: 100,
  },
  PhysicalAndChemicalCharacteristicsType: {
    maxLength: 150,
  },

  ContaminantCharacteristicsParameter: {
    maxLength: 150,
  },
  ContaminantCharacteristicsValue: {
    maxLength: 150,
  },
  ContaminantCharacteristicsSpecification: {
    maxLength: 150,
  },
  ContaminantCharacteristicsTolerance: {
    maxLength: 150,
  },
  ContaminantCharacteristicsReferenceStandard: {
    maxLength: 150,
  },
  ContaminantCharacteristicsReferenceMethod: {
    maxLength: 150,
  },
  ContaminantCharacteristicsFrequency: {
    maxLength: 150,
  },
  ContaminantCharacteristicsDocumentName: {
    maxLength: 100,
  },
  ContaminantCharacteristicsType: {
    maxLength: 150,
  },
  MicrobiologicalCharacteristicsParameter: {
    maxLength: 150,
  },
  MicrobiologicalCharacteristicsValue: {
    maxLength: 150,
  },
  MicrobiologicalCharacteristicsSpecification: {
    maxLength: 150,
  },
  MicrobiologicalCharacteristicsTolerance: {
    maxLength: 150,
  },
  MicrobiologicalCharacteristicsReferenceStandard: {
    maxLength: 150,
  },
  MicrobiologicalCharacteristicsReferenceMethod: {
    maxLength: 150,
  },
  MicrobiologicalCharacteristicsDocumentName: {
    maxLength: 100,
  },
  MicrobiologicalCharacteristicsType: {
    maxLength: 150,
  },
  ProductSpecsDocumentName: {
    maxLength: 100,
  },
  ProductSpecsCharacteristicsType: {
    maxLength: 100,
  },
  ManufacturerName: {
    maxLength: 50,
  },
  CommercialName: {
    maxLength: 50,
  },
  QualityName: {
    maxLength: 50,
  },
  ManufacturerPosition: {
    maxLength: 50,
  },
  CommercialPosition: {
    maxLength: 50,
  },
  QualityPosition: {
    maxLength: 50,
  },
  DocumentName: {
    maxLength: 100,
  },
  DocumentType: {
    maxLength: 50,
  },
  Certificate: {
    maxLength: 100,
  },
  CertificateBody: {
    maxLength: 50,
  },
};

export const MAX_SLIDER_SHOW = 4;
export const MAX_SLIDER_SHOW_1366 = 3;

// This is the list enum that sends enumCode instead of enumDisplayName
export const SPECIAL_ENUMS = ['LanguageCodeEnum'];

// Allergen
export const PROPERTY_NAME_NEED_TO_CHECK_VALIDATION = 'Allergen';
export const MODULE_NAME_NEED_TO_CHECK_VALIDATION = 'AllergenInformation';
export const LIST_BOOLEAN_PROPERTY_NAME_NEED_TO_DISABLE = ['IsPublish'];
export const LIST_PROPERTY_NAME_NEED_TO_DISABLE_COPY_ACTION = ['Allergen'];

export const LIST_ALLERGEN_PROPERTY_NAME_CHECKABLE = [
  'allergenInformation.allergenRelatedInformation.allergen.allergenTypeCode',
  'allergenInformation.allergenRelatedInformation.allergen.levelOfContainmentCode',
  'allergenInformation.allergenRelatedInformation.allergen.containmentSource',
];

export const LIST_ALLERGEN_PROPERTY_NAME_CHECKABLE_DISABLED_FIELDS = [
  'ContainmentSource',
];
