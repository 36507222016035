import React from 'react';

import { Button, Row, Col, Divider } from 'antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';

import { ColorPicker } from 'common/components';

import {
  PageEditorToolHeader,
  PageEditorSectionHeader,
  PageEditorInput,
} from '.';

import { EDIT_TYPE } from '../../constants';

import { PageEditorAddNew } from '../page-editor-tool__add-new';

import classnames from 'classnames';

import './styles.less';

const fontOptions = [
  { label: 'default', value: '' },
  { label: 'sans-serif', value: 'sans-serif' },
  { label: 'serif', value: 'serif' },
  { label: 'monospace', value: 'monospace' },
  { label: 'cursive', value: 'cursive' },
  { label: 'fantasy', value: 'fantasy' },
];

const PageEditorDragTextEdit = (props) => {
  const {
    wrapId,
    wrapKey,
    textHandlers,
    closeToolDraw,
    showDetailComponentData,
    selectedHook,
    detailShowTargetHook,
    componentHandlers,
    scale,
  } = props;

  const [detailShowTarget] = detailShowTargetHook;

  const detailData = Object.values(showDetailComponentData)?.[0];

  return (
    <div
      className={classnames('page-editor-tool__wrap', 'page-editor-tool__text')}
    >
      <PageEditorToolHeader
        closeToolDraw={closeToolDraw}
        text='Edit text component'
      />

      <PageEditorSectionHeader text='Add new text' />
      <PageEditorAddNew
        wrapId={wrapId}
        wrapKey={wrapKey}
        editType={EDIT_TYPE.TEXT}
        selectedHook={selectedHook}
        detailShowTargetHook={detailShowTargetHook}
        showDetailComponentData={showDetailComponentData}
        componentHandlers={componentHandlers}
        scale={scale}
      />
      {detailShowTarget && (
        <>
          <PageEditorSectionHeader text='Position' />
          <div className='page-editor-tool__markup-pos'>
            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--up'
              )}
              onClick={textHandlers.moveUp}
            >
              <CaretUpOutlined />
            </Button>
            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--down'
              )}
              onClick={textHandlers.moveDown}
            >
              <CaretDownOutlined />
            </Button>

            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--left'
              )}
              onClick={textHandlers.moveLeft}
            >
              <CaretLeftOutlined />
            </Button>
            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--right'
              )}
              onClick={textHandlers.moveRight}
            >
              <CaretRightOutlined />
            </Button>
          </div>
          <Divider style={{ margin: '3px 0' }} />
          <PageEditorSectionHeader text='Style' />
          <Row>
            <Col xs={24} style={{ padding: '3px 3px' }}>
              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--font-type'
                )}
                value={detailData?.textFont}
                onChange={textHandlers.setTextFont}
                label='Font'
                type='selection'
                showSearch
                options={fontOptions}
                placeholder='Select a font'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              />

              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--font-size'
                )}
                value={detailData?.textWeight}
                onChange={textHandlers.setTextWeight}
                label='Font Weight'
                type='number'
                min={400}
                max={1000}
                step={50}
              />
              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--font-size'
                )}
                value={detailData?.textSize}
                onChange={textHandlers.setFontSize}
                label='Font size'
                type='number'
                min={5}
                max={100}
              />
              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--color'
                )}
                label='Color'
              >
                <ColorPicker
                  wrapClassName='page-editor-tool__color-picker'
                  onChange={textHandlers?.setTextColor}
                  isShowAddBtn={false}
                  initialColor={detailData?.textColor}
                />
              </PageEditorInput>

              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--border-thick'
                )}
                value={detailData?.textBorderWidth}
                onChange={textHandlers.setBorderWidth}
                label='Border Thick (px)'
                type='number'
                min={0}
              />

              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--color'
                )}
                label='Border Color'
              >
                <ColorPicker
                  wrapClassName='page-editor-tool__color-picker'
                  onChange={textHandlers?.setBorderColor}
                  isShowAddBtn={false}
                  initialColor={detailData?.textBorderColor}
                />
              </PageEditorInput>

              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--border-radius'
                )}
                value={detailData?.textRadius}
                onChange={textHandlers.setRadius}
                label='Border Radius'
                type='number'
                min={0}
              />

              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--padding'
                )}
                value={detailData?.textPadding}
                onChange={textHandlers.setTextPadding}
                label='Padding'
                type='number'
                min={0}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default PageEditorDragTextEdit;
