import * as actions from './constants';

export function updateLoadingExport(payload) {
  return {
    type: actions.UPDATE_LOADING_EXPORT,
    payload,
  };
}

export function changeFullViewMode(payload) {
  return {
    type: actions.CHANGE_FULL_VIEW_MODE,
    payload,
  };
}
