import React, { useEffect } from 'react';

import { Form } from 'common/components';

import {
  FormTextInput,
  FormTextAreaInput,
  FormSelectInput,
  FormNumberInput,
  FormBrickCodeInput,
  textAreaEnums,
  inputNumberEnums,
  selectEnums,
  inputTextEnums,
  brickCodeEnums,
} from 'common/components/product-add/ProductEditFormItem';

import { startCase } from 'lodash';

import './InstantFixFormRivirField.less';

const layoutForm = {
  labelCol: { span: 11 },
  wrapperCol: { span: 11 },
};

const InstantFixFormRivirField = (props) => {
  const { productEnums, errorInfo, productId, formName, productDetail } = props;

  const fieldName = errorInfo?.fieldName;

  const [formInstance] = Form.useForm();

  const productTypeEnums = productEnums?.filter(
    (type) => type.enumName === 'ProductTypeEnum'
  );

  const productBasicUOM = productEnums?.filter(
    (type) => type.enumName === 'BasicUomEnum'
  );

  useEffect(() => {
    const initFormValue = () => {
      const fieldName = errorInfo?.fieldName;
      const allProductFields = Object.keys(productDetail);

      const foundField = allProductFields.find(
        (field) => field.toLowerCase() === (fieldName || '').toLowerCase()
      );

      const customFormData = {
        productId: productId,
        fieldName: errorInfo?.fieldName,
        moduleName: '',
        [fieldName]: productDetail?.[foundField],
      };
      formInstance.setFieldsValue(customFormData);
    };

    if (productDetail) {
      initFormValue();
    }
  }, [productDetail, errorInfo, formInstance, productId]);

  const renderInput = () => {
    const findCallback = (enumItem) => {
      return enumItem?.name.toLowerCase() === (fieldName || '').toLowerCase();
    };

    let foundItem;

    foundItem = textAreaEnums.find(findCallback);
    if (foundItem) {
      return (
        <FormTextAreaInput
          {...foundItem}
          name={fieldName}
          label={startCase(fieldName)}
        />
      );
    }

    foundItem = inputTextEnums.find(findCallback);
    if (foundItem) {
      return (
        <FormTextInput
          {...foundItem}
          name={fieldName}
          label={startCase(fieldName)}
        />
      );
    }

    foundItem = selectEnums.find(findCallback);
    if (foundItem) {
      return (
        <FormSelectInput
          {...foundItem}
          name={fieldName}
          label={startCase(fieldName)}
          optionParams={{ productTypeEnums, productBasicUOM }}
        />
      );
    }

    foundItem = inputNumberEnums.find(findCallback);
    if (foundItem) {
      return (
        <FormNumberInput
          {...foundItem}
          name={fieldName}
          label={startCase(fieldName)}
        />
      );
    }

    foundItem = brickCodeEnums.find(findCallback);
    if (foundItem) {
      return (
        <FormBrickCodeInput
          brickCode={productDetail?.brickCode}
          brickCodeName={productDetail?.brickCodeName}
          name={fieldName}
          label={startCase(fieldName)}
        />
      );
    }
  };

  return (
    <>
      <Form
        {...layoutForm}
        form={formInstance}
        className='publication-instant-fix-rivir-field-input'
        id='form-group'
        name={formName}
      >
        {renderInput()}

        <Form.Item name='productId' hidden></Form.Item>
        <Form.Item name='moduleName' hidden></Form.Item>
        <Form.Item name='fieldName' hidden></Form.Item>
      </Form>
    </>
  );
};

export default InstantFixFormRivirField;
