import React from 'react';
import Messages from 'i18n/messages/home';

import { ReactComponent as IconDownloadTransaction } from 'common/components/button/svg-icons/ICO_10.54.svg';
import RibbonButton from 'common/components/button/RibbonButton';

const DownloadTransaction = () => {
  return (
    <RibbonButton
      icon={<IconDownloadTransaction style={{ height: 26 }} />}
      label={Messages.downloadTransaction}
    />
  );
};

DownloadTransaction.propTypes = {};

export default DownloadTransaction;
