import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { DeleteOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const CancelQueryControl = ({ onClick }) => {
  const onClickBtn = () => {
    onClick && onClick();
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.cancelEditUser}
      onClick={onClickBtn}
      k
    />
  );
};

CancelQueryControl.propTypes = {
  confirmEdit: PropTypes.func,
};

export default CancelQueryControl;
