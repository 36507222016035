import React from 'react';

import { Avatar, Row, Col, Typography } from 'antd';

import { formatMDY } from 'utils/formatDate';
import { formatMessage } from 'utils/string';

const { Text, Paragraph } = Typography;

const SearchResultItem = (props) => {
  const { value, item, participants, onClick } = props;
  const onClickHandler = () => {
    onClick(item);
  };

  const getUserInfo = () => {
    return participants?.find((user) => {
      return user.userId === item.senderId;
    });
  };

  const userInfo = getUserInfo();

  return (
    <Row
      className='chat-search-message__result-item'
      align='top'
      onClick={onClickHandler}
    >
      <Col flex={0} className='communication-list__avatar-wrapper'>
        <Avatar src={userInfo?.avatar} size={28}>
          {userInfo?.name?.charAt(0)}
        </Avatar>
      </Col>
      <Col flex={1} className='chat-search-message__result-item-message-col'>
        <Text
          className='chat-search-message__result-item-message-text'
          ellipsis
        >
          {item?.messageText &&
            formatMessage(item?.messageText).replace(/<[^>]*>?/gm, '')}
        </Text>
        <Paragraph style={{ marginBottom: 0 }}>
          <Text>{formatMDY(item.sendTime)}</Text>
          <Text> - </Text>
          <Text ellipsis style={{ maxWidth: 'calc(100% - 100px)' }}>
            {userInfo?.name}
          </Text>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default SearchResultItem;
