import * as productServices from 'services/product';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';

import { sleep } from 'utils/delay';
import { forwardTo } from 'utils/common/route';

import { qaAllergensKeys } from 'pages/qa-spec/components/qa-allergen/hooks';
import { useGetDataProductItemModules } from 'pages/product-full-view/shared/hooks';

export const multiplePanelsAllergens = {
  productAllergens: (productId) => [
    'multiple-panels/allergens',
    parseInt(productId),
  ],
};

export const useGetProductAllergens = (productId) => {
  const queries = useQuery({
    queryKey: multiplePanelsAllergens.productAllergens(productId),
    queryFn: async () => {
      const response = await productServices.getProductAllergens({ productId });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId,
  });

  return {
    allergenGroups: queries?.data?.allergenGroups ?? [],
    ...queries,
  };
};

export const useSaveProductAllergens = (productId) => {
  const queryClient = useQueryClient();
  const { refetchDataProductModules } = useGetDataProductItemModules(
    productId,
    false //* isEnabled
  );

  return useMutation({
    mutationFn: productServices.saveProductAllergens,
    onSuccess: async (result) => {
      if (result?.isSuccess) {
        await sleep(1000);
        queryClient.invalidateQueries({
          queryKey: multiplePanelsAllergens.productAllergens(productId),
        });
        queryClient.invalidateQueries({
          queryKey: qaAllergensKeys.list(productId),
        });
        refetchDataProductModules();
      } else {
        CustomNotification.error(result?.message ?? 'Something went wrong!');
      }
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });
};
