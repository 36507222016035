import * as api from 'config/axios';
import * as endpoints from './endpoints';

/**
 * Get product list service
 * @param {number} PageSize
 * @param {number} PageNumber
 * @param {string} Search
 */
export const getProductListService = (params) => {
  const endpoint = params.isFavoriteRoute
    ? endpoints.GET_FAVORITE_PRODUCT_DETAIL_GRID
    : endpoints.GET_PRODUCT_DETAIL_GRID;
  return api.sendPost(endpoint, params);
};

export const getMemberProductListService = (params) => {
  const endpoint = endpoints.GET_FAVORITE_PRODUCT_DETAIL_GRID;
  return api.sendPost(endpoint, params);
};

export const getSearchProductIntoAsset = (params) => {
  return api.sendPost(endpoints.GET_SEARCH_PRODUCT_INTO_ASSET, params);
};

export const getProductDocumentsService = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_DOCUMENTS_ENDPOINT, { params });
};

export const getProductHierarchy = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_HIERARCHY_DETAIL, {
    params,
  });
};

export const searchProductForAddHierarchy = (params) => {
  return api.sendPost(endpoints.SEARCH_PRODUCT_FOR_ADD_HIERARCHY, params);
};

export const saveProductHierarchy = (params) => {
  return api.sendPost(endpoints.SAVE_PRODUCT_HIERARCHY, params);
};

export const getProductShortDetail = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_SHORT_DETAIL, { params });
};

export const getProductHierarchiesShortDetail = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_HIERARCHIES_SHORT_DETAIL, {
    params: params,
  });
};

export const getProductFullView = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_FULL_VIEW, {
    params: params,
  });
};

export const deleteHierarchy = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_HIERARCHY, params);
};

export const editHierarchy = (params) => {
  return api.sendPost(endpoints.EDIT_PRODUCT_HIERARCHY, params);
};

export const getProductHeader = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_HEADER, { params });
};

//todo - 06/24/2021 - waiting for real API
export const toggleShowProductHeaderProfileImage = (params) => {
  return api.sendPost(
    endpoints.TOGGLE_SHOW_PRODUCT_HEADER_PROFILE_IMAGE,
    params
  );
};

//todo - 06/24/2021 - waiting for real API
export const toggleShowProductHeaderAddress = (params) => {
  return api.sendPost(endpoints.TOGGLE_SHOW_PRODUCT_HEADER_ADDRESS, params);
};

export const editProductHeaderBackgroundConfig = (params) => {
  return api.sendPost(endpoints.EDIT_PRODUCT_HEADER_BACKGROUND_CONFIG, params);
};

export const getProductHearBackground = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_HEADER_BACKGROUND, { params });
};

export const saveProductHeaderBackground = (params) => {
  return api.sendPost(endpoints.SAVE_PRODUCT_HEADER_BACKGROUND, params);
};

export const getProductBrandImage = (params) => {
  return api.sendPost(endpoints.GET_PRODUCT_BRAND_IMAGE, params);
};

export const getProductBrandOnAdd = (params) => {
  return api.sendPost(endpoints.GET_MEMBER_BRAND_PIM, params);
};

export const getProductCompanyInfo = (productId) => {
  const params = { productId };
  return api.sendGet(endpoints.GET_PRODUCT_COMPANY_INFO, { params });
};

export const getProductSocialMedia = (productId) => {
  const params = { productId };
  return api.sendGet(endpoints.GET_PRODUCT_SOCIAL_MEDIA, { params });
};

//todo - 06/29/2021 - waiting for real API
export const addProductSocialMedia = (params) => {
  return api.sendPost(endpoints.ADD_PRODUCT_SOCIAL_MEDIA, params);
};

//todo - 06/29/2021 - waiting for real API
export const deleteProductSocialMedia = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_SOCIAL_MEDIA, params);
};

export const getProductFactsPanel = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_FACTS_PANEL, { params });
};

export const getProductIngredients = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_INGREDIENTS, { params });
};

export const saveProductIngredient = (params) => {
  return api.sendPost(endpoints.SAVE_PRODUCT_INGREDIENT, params);
};

export const getProductEnums = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_ENUMS, params);
};

export const updateProductFactPanel = (params) => {
  return api.sendPost(endpoints.UPDATE_PRODUCT_FACTS_PANEL, params);
};

export const getProductImagery = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_IMAGERY, { params });
};

export const downloadProductImagery = (params) => {
  return api.sendPost(endpoints.DOWNLOAD_PRODUCT_IMAGERY, params);
};
export const getProductViaSpreadSheet = () => {
  return api.sendGet(endpoints.GET_PRODUCT_VIA_SPREADSHEET);
};
export const getTdcProductViaSpreadSheet = () => {
  return api.sendGet(endpoints.GET_TDC_PRODUCT_VIA_SPREADSHEET);
};

export const importProductViaSpreadSheet = (params) => {
  return api.sendPost(endpoints.IMPORT_PRODUCT_VIA_SPREADSHEET, params);
};

export const getProductHistory = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_HISTORY, {
    params,
  });
};

export const getPropertiesProductHistory = (params) => {
  return api.sendGet(endpoints.GET_PROPERTIES_FOR_HISTORY_PRODUCT, {
    params,
  });
};

export const getProductDetailHistory = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_DETAIL_HISTORY, {
    params: params,
  });
};

export const editBrandImageByProduct = (params) => {
  return api.sendPost(endpoints.EDIT_BRAND_IMAGES_BY_PRODUCT, params);
};

export const getMarketingAssets = (params) => {
  return api.sendGet(endpoints.GET_MARKETING_ASSETS, { params });
};

export const getProductHierarchyDetailsService = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_HIERARCHY_DETAILS, {
    params: params,
  });
};
export const downloadProductHierarchyDetailsService = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_PRODUCT_HIERARCHY_DETAILS, {
    params: params,
  });
};

export const addProduct = (params) => {
  return api.sendPost(endpoints.ADD_PRODUCT, params);
};
// Product item modules
export const getProductItemDetailSchema = () => {
  return api.sendGet(endpoints.GET_PRODUCT_ITEM_DETAIL_SCHEMA);
};

export const getProductItemModules = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_ITEM_MODULES, { params });
};

export const saveProductItemModules = (params) => {
  return api.sendPost(endpoints.SAVE_PRODUCT_ITEM_MODULES, params);
};

export const getProductItemEnums = () => {
  return api.sendGet(endpoints.GET_PRODUCT_ITEM_ENUMS);
};

export const getProductPrivateProperties = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_PRIVATE_PROPERTIES, { params });
};

export const saveProductPrivateProperties = (params) => {
  return api.sendPost(endpoints.SAVE_PRODUCT_PRIVATE_PROPERTIES, params);
};

export const getProductItemModulesByBrickCode = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_ITEM_MODULES_BY_BRICK_CODE, {
    params,
  });
};

// End

export const updateProductDetail = (params) => {
  return api.sendPost(endpoints.EDIT_PRODUCT_DETAIL, params);
};

export const uploadPimAsset = (payload, additionalBodyPayload) => {
  return api.sendUpload({
    api: endpoints.UPLOAD_PIM_ASSET,
    payload,
    additionalBodyPayload,
  });
};

export const replacePimAsset = (payload, additionalBodyPayload) => {
  return api.sendUpload({
    api: endpoints.REPLACE_PIM_ASSET,
    payload,
    additionalBodyPayload,
  });
};

export const linkAssetFromPIM = (params, associationType) => {
  return associationType === 'DOCUMENT'
    ? api.sendPost(endpoints.PIM_LINK_ASSET_DOCUMENT, params)
    : api.sendPost(endpoints.PIM_LINK_ASSET, params);
};

export const linkMultiAssetFromPIM = (params) => {
  return api.sendPost(endpoints.PIM_LINK_MULTI_ASSET, params);
};

export const unlinkAssetFromPIM = (params) => {
  return api.sendPost(endpoints.PIM_UNLINK_ASSET, params);
};

export const updateLinkAssetFromPIM = (params) => {
  return api.sendPost(endpoints.UPDATE_PRODUCT_IMAGE_LINK_METADATA, params);
};

export const getProductMappingMetadata = (params) =>
  api.sendGet(endpoints.GET_PRODUCT_MAPPING_METADATA, {
    params,
  });

//* Syndication
export const updateGDSNPublicationConfig = (params) => {
  return api.sendPost(endpoints.UPDATE_GDSN_PUBLICATION_CONFIG, params);
};

export const getGDSNPartyListInfo = (params) => {
  return api.sendPost(endpoints.GET_PARTY_INFO_GRID, params);
};

export const getPublishChecklist = (params) => {
  return api.sendPost(endpoints.GET_PUBLISH_CHECKLIST, params, {
    ignoreServerError: true,
  });
};

export const getPublicationList = (params) => {
  return api.sendPost(endpoints.GET_GDSN_RECEIVED_PRODUCTS, {
    params,
  });
};

export const deleteProductItems = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_ITEMS, params);
};

export const acceptPublication = (params) => {
  return api.sendPost(endpoints.ACCEPT_PUBLICATION, params);
};
export const rejectPublication = (params) => {
  return api.sendPost(endpoints.REJECT_PUBLICATION, params);
};

export const reviewPublication = (params) => {
  return api.sendPost(endpoints.REVIEW_PUBLICATION, params);
};

export const getUserSourceGLN = (params) => {
  return api.sendGet(endpoints.GET_SOURCE_GLN, { params });
};

export const getSyndicationHistoryShortDetailsService = (params) => {
  return api.sendGet(endpoints.GET_SYNDICATION_HISTORY_SHORT_DETAILS, {
    params,
  });
};

export const saveQuickPublicationFixForm = (params) =>
  api.sendPost(endpoints.SAVE_QUICK_PUBLICATION_FIX_FORM, params);

export const updateTimeGdsnActivities = () => {
  return api.sendPost(endpoints.UPDATE_TIME_UNREAD_GDSN_ACTIVITIES);
};

export const syncFromNutritionFactToAllergenModule = (params) => {
  return api.sendPost(
    endpoints.SYNC_FROM_NUTRITION_FACT_TO_ALLERGEN_MODULE,
    params
  );
};

export const syncFromAllergenModuleToNutritionFact = (params) => {
  return api.sendPost(
    endpoints.SYNC_FROM_ALLERGEN_MODULE_TO_NUTRITION_FACT,
    params
  );
};

export const syncFromNutritionFactToIngredientModule = (params) => {
  return api.sendPost(
    endpoints.SYNC_FROM_NUTRITION_FACT_TO_INGREDIENT_MODULE,
    params
  );
};

export const syncFromIngredientModuleToNutritionFact = (params) => {
  return api.sendPost(
    endpoints.SYNC_FROM_INGREDIENT_MODULE_TO_NUTRITION_FACT,
    params
  );
};

export const deleteProductNutritionPanel = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_NUTRITION_PANEL, params);
};

export const getPartyInfo = (params) => {
  return api.sendPost(endpoints.GET_PARTY_INFO_GRID, params);
};
export const deleteProductItemsPublication = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_ITEMS_PUBLICATION, params);
};
export const getAllPublications = (params) => {
  return api.sendPost(endpoints.GET_ALL_PUBLICATIONS, params);
};

export const moveProductToMember = (params) => {
  return api.sendPost(endpoints.MOVE_PRODUCT_TO_MEMBER, params);
};

export const downloadNewItem = (params) => {
  return api.sendPost(endpoints.DOWNLOAD_NEW_ITEM, params);
};

export const getPropertiesCustomizedGrid = (params) => {
  return api.sendGet(endpoints.GET_PROPERTIES_CUSTOMIZED_GRID, { params });
};

export const getProductCustomPropertiesData = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_CUSTOM_PROPERTIES_DATA, { params });
};

export const getMemberProductCustomProperties = (params) => {
  return api.sendGet(endpoints.GET_MEMBER_PRODUCT_CUSTOM_PROPERTIES, {
    params,
  });
};
export const saveMemberProductCustomProperties = (params) => {
  return api.sendPost(endpoints.SAVE_MEMBER_PRODUCT_CUSTOM_PROPERTIES, params);
};

export const getProductPropertiesToExport = () => {
  return api.sendGet(endpoints.GET_PRODUCT_PROPERTIES_TO_EXPORT);
};

export const exportProductProperties = (params) => {
  return api.sendPost(endpoints.EXPORT_PRODUCT_PROPERTIES, params);
};

export const downloadManualEditingBulkProduct = () => {
  return api.sendGet(endpoints.DOWNLOAD_MANUAL_BULK_EDITING_PRODUCT);
};

export const getQaProductIngredient = ({ productId }) => {
  const params = { productId };
  return api.sendGet(endpoints.GET_QA_PRODUCT_INGREDIENT, { params });
};

export const saveQaProductIngredient = (params) => {
  return api.sendPost(endpoints.SAVE_QA_PRODUCT_INGREDIENT, params);
};

export const getQaProductAllergen = ({ productId }) => {
  const params = { productId };
  return api.sendGet(endpoints.GET_QA_PRODUCT_ALLERGEN, { params });
};

export const SaveQaProductAllergen = (params) => {
  return api.sendPost(endpoints.SAVE_QA_PRODUCT_ALLERGEN, params);
};

export const getDataFormula = ({ productId }) => {
  const params = { productId };
  return api.sendGet(endpoints.GET_DATA_FORMULA, { params });
};
export const getSnapshotDataFormula = ({ productId }) => {
  const params = { productId };
  return api.sendGet(endpoints.GET_SNAPSHOT_DATA_FORMULA, { params });
};
export const saveDataFormula = (params) => {
  return api.sendPost(endpoints.SAVE_DATA_FORMULA, params);
};

export const getQaSpeecDataDocument = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_DOCUMENT, { params });
};

export const getQaSpecSnapshotDataDocument = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_SNAPSHOT_DATA_DOCUMENT, { params });
};

export const getQaSpeecDataCertificate = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_CERTIFICATE, { params });
};

export const getQaSpecSnapshotDataCertificate = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_SNAPSHOT_DATA_CERTIFICATE, {
    params,
  });
};

export const qaSpecLinkToDocCer = (params) => {
  const newParams = {
    digitalAssetId: params?.digitalAssetId,
    productItemId: params?.productItemId,
    linkType: params?.linkType,
    documentName: params?.documentName,
    documentType: params?.documentType,
    certificate: params?.certificate,
    certificateBody: params?.certificateBody,
    certificateExpiryDate: params?.certificateExpiryDate,
    type: params?.type,
    subtype: params?.subtype,
    certificateType: params?.certificateType,
  };
  return api.sendPost(
    endpoints.QA_SPEC_LINK_TO_DOCUMENT_CERTIFICATE,
    newParams
  );
};

export const qaSpecUnLinkToDocCer = (params) => {
  return api.sendPost(endpoints.QA_SPEC_UNLINK_TO_DOCUMENT_CERTIFICATE, params);
};

export const getQaSpecDataNutritionFacts = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_NUTRITION_FACTS, { params });
};

export const getQaSpecDrugFacts = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DRUG_FACTS, { params });
};

export const deleteQaSpecDrugFact = (params) => {
  return api.sendPost(endpoints.DELETE_QA_SPEC_DRUG_FACT, params);
};

export const saveQaSpecDataNutritionFacts = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_NUTRITION_FACTS, params);
};

export const deleteQaSpecNutritionFact = (params) => {
  return api.sendPost(endpoints.DELETE_QA_SPEC_NUTRITION_FACT, params);
};

export const getQaSpecDataSupplementFact = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_SUPPLEMENT_FACTS, { params });
};

export const saveQaSpecSupplementFact = (params) => {
  return api.sendPost(endpoints.SAVE_SUPPLEMENT_FACTS, params);
};

export const deleteQaSpecSupplementFact = (params) => {
  return api.sendPost(endpoints.DELETE_QA_SPEC_SUPPLEMENT_FACT, params);
};

export const saveQaSpecDataSupplementFacts = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_SUPPLEMENT_FACT, params);
};

export const saveDrugFacts = (params) => {
  return api.sendPost(endpoints.SAVE_DRUG_FACTS, params);
};

export const saveQaSpecDrugFacts = (params) => {
  return api.sendPost(endpoints.SAVE_QA_DRUG_FACTS, params);
};

export const setPrimaryProductImage = (params) => {
  return api.sendPost(endpoints.SET_PRIMARY_PRODUCT_IMAGE, params);
};

export const downloadAplProductList = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_APL_PRODUCT_LIST, { params });
};
export const unlinkProductDocument = (params) => {
  return api.sendPost(endpoints.PIM_UNLINK_ASSET_DOCUMENT, params);
};

export const downloadGDSNProduct = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_GDSN_RECEIVED_PRODUCT, { params });
};

export const qaSpecLinkProductToMSDSDocument = (params) => {
  return api.sendPost(
    endpoints.LINK_QA_SPEC_DATA_PRODUCT_MSDS_DOCUMENT,
    params
  );
};

export const qaSpecUnLinkProductToMSDSDocument = (params) => {
  return api.sendPost(
    endpoints.UNLINK_QA_SPEC_DATA_PRODUCT_MSDS_DOCUMENT,
    params
  );
};

export const saveQaSpecDataFreshSeafood = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_FRESH_SEAFOOD, params);
};

export const saveQaSpecDataFreshMeat = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_FRESH_MEAT, params);
};

export const getGDSNSchema = () => {
  return api.sendGet(endpoints.GET_GDSN_SCHEMA);
};

// Multiple Panels
export const getProductAllergens = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_ALLERGENS, { params });
};

export const saveProductAllergens = (params) => {
  return api.sendPost(endpoints.SAVE_PRODUCT_ALLERGENS, params);
};

export const updateProductSupplementPanel = (params) => {
  return api.sendPost(endpoints.SAVE_SUPPLEMENT_FACTS, params);
};

export const getProductSupplementFacts = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_SUPPLEMENT_FACTS, { params });
};

export const deleteProductSupplementPanel = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_SUPPLEMENT_PANEL, params);
};

export const getProductDrugFacts = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_DRUG_FACTS, { params });
};

export const deleteProductDrugFacts = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_DRUG_FACTS, params);
};

// Group data
export const getAllGroupProductData = (params) => {
  return api.sendGet(endpoints.GET_ALL_GROUP_PRODUCT_DATA, { params });
};

export const getGroupDataDetail = (params) => {
  return api.sendGet(endpoints.GET_GROUP_DATA_DETAIL, { params });
};

export const saveGroupProductData = (params) => {
  return api.sendPost(endpoints.SAVE_GROUP_PRODUCT_DATA, params);
};

export const assignGroupProductData = (params) => {
  return api.sendPost(endpoints.ASSIGN_GROUP_PRODUCT_DATA, params);
};

export const deleteGroupProductData = (params) => {
  return api.sendPost(endpoints.DELETE_GROUP_PRODUCT_DATA, params);
};

// QA Group Data
export const getQaGroupDataDetail = (params) => {
  return api.sendGet(endpoints.GET_QA_GROUP_DATA_DETAIL, { params });
};

export const saveQaGroupProductData = (params) => {
  return api.sendPost(endpoints.SAVE_QA_GROUP_PRODUCT_DATA, params);
};

export const assignQaGroupProductData = (params) => {
  return api.sendPost(endpoints.ASSIGN_QA_GROUP_PRODUCT_DATA, params);
};

export const deleteQaGroupProductData = (params) => {
  return api.sendPost(endpoints.DELETE_QA_GROUP_PRODUCT_DATA, params);
};

export const saveQaSpecDataPackaging = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_PACKAGING, params);
};

export const saveQaSpecDataClaims = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_CLAIMS, params);
};
export const saveQaSpecProductContain = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_PRODUCT_CONTAIN, params);
};

export const getPropertiesForCopyProduct = () => {
  return api.sendGet(endpoints.GET_PROPERTIES_FOR_COPY_PRODUCT);
};

export const copyProductItem = (params) => {
  return api.sendPost(endpoints.COPY_PRODUCT_ITEM, params);
};

// Walmart
export const postToWalmart = (params) => {
  return api.sendPost(endpoints.POST_TO_WALMART, params);
};

export const editProductLinkedAddress = (params) => {
  return api.sendPost(endpoints.EDIT_PRODUCT_LINKED_ADDRESS, params);
};

export const saveProductPetNutritionFacts = (params) => {
  return api.sendPost(endpoints.SAVE_PRODUCT_PET_NUTRITION_FACTS, params);
};

export const saveQaSpecProductPetNutritionFacts = (params) => {
  return api.sendPost(
    endpoints.SAVE_QA_SPEC_PRODUCT_PET_NUTRITION_FACTS,
    params
  );
};

export const getPetFoodFactsPanel = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_PET_NUTRITION_FACTS, { params });
};

export const getQaSpecPetFoodFactsPanel = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_PET_NUTRITION_FACTS, { params });
};

export const deletePetFoodFactsPanel = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_PET_NUTRITION_FACTS, params);
};

export const deleteQaSpecPetFoodFactsPanel = (params) => {
  return api.sendPost(endpoints.DELETE_QA_SPEC_PET_NUTRITION_FACTS, params);
};
//* Pet Food
export const saveQaSpecPetNonFood = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_PET_NON_FOOD, params);
};

// Snapshot
export const getSnapshotDrugFacts = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_DATA_DRUG_FACTS, { params });
};

export const getPetFoodSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_PET_FOOD_SNAPSHOT, { params });
};

export const getSnapshotNutritionFacts = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_DATA_NUTRITION_FACTS, { params });
};

export const getSnapshotSupplementFacts = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_DATA_SUPPLEMENT_FACTS, { params });
};

export const getSnapshotQaSpecHeader = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_QA_SPEC_HEADER, { params });
};

export const getSnapshotQaSpecClaims = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_QA_SPEC_CLAIMS, { params });
};

export const getSnapshotQaSpecPackaging = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_QA_SPEC_PACKING, { params });
};

export const getSnapshotQaSpecFreshMeat = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_QA_SPEC_FRESH_MEAT, { params });
};

export const getSnapshotQaSpecFreshSeafood = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_QA_SPEC_FRESH_SEAFOOD, { params });
};

export const getSnapshotQaSpecProductContains = (params) => {
  return api.sendGet(endpoints.GET_SNAPSHOT_QA_SPEC_PRODUCT_CONTAINS, {
    params,
  });
};

export const getQaProductSpecSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_PRODUCT_SPECIFICATION_SNAPSHOT, {
    params,
  });
};

export const getQaProductOrganolepticCharacteristicsSnapshot = (params) => {
  return api.sendGet(
    endpoints.GET_QA_PRODUCT_ORGANOLEPTIC_CHARACTERISTICS_SNAPSHOT,
    {
      params,
    }
  );
};

export const getQaProductPhysicalAndChemicalSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_PRODUCT_PHYSICAL_CHEMICAL_SNAPSHOT, {
    params,
  });
};

export const getQaProductContaminantCharacteristicsSnapshot = (params) => {
  return api.sendGet(
    endpoints.GET_QA_PRODUCT_CONTAMINANT_CHARACTERISTIC_SNAPSHOT,
    {
      params,
    }
  );
};

export const getQaProductMicrobiologicalCharacteristicsSnapshot = (params) => {
  return api.sendGet(
    endpoints.GET_QA_PRODUCT_MICROBIOLOGICAL_CHARACTERISTICS_SNAPSHOT,
    {
      params,
    }
  );
};

export const getQaProductSpecDocumentsSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_PRODUCT_SPEC_DOCUMENTS_SNAPSHOT, {
    params,
  });
};

export const getQaProductFreshProduceSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_PRODUCT_FRESH_PRODUCE_SNAPSHOT, {
    params,
  });
};

export const getQaProductManufacturingLocationsSnapshot = (params) => {
  return api.sendGet(
    endpoints.GET_QA_PRODUCT_MANUFACTURING_LOCATIONS_SNAPSHOT,
    {
      params,
    }
  );
};

export const getQaGetProcessStageSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_PRODUCT_PROCESS_STAGE_SNAPSHOT, {
    params,
  });
};

export const getQaManufacturingDocumentsSnapshot = (params) => {
  return api.sendGet(
    endpoints.GET_QA_PRODUCT_MANUFACTURING_DOCUMENTS_SNAPSHOT,
    {
      params,
    }
  );
};

export const getQaAllergensSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_ALLERGENS_SNAPSHOT, {
    params,
  });
};

export const getQaIngredientsSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_INGREDIENTS_SNAPSHOT, {
    params,
  });
};

export const updateProductIXONEShield = (params) => {
  return api.sendPost(endpoints.UPDATE_PRODUCT_IXONE_SHIELD, params);
};

export const getProductChangeHistory = (params) => {
  return api.sendPost(endpoints.GET_PRODUCT_CHANGES_HISTORY, params);
};

export const getQaClaimsData = (params) => {
  return api.sendGet(endpoints.GET_QA_CLAIMS, {
    params,
  });
};

export const getQaPacking = (params) => {
  return api.sendGet(endpoints.GET_QA_PACKING, {
    params,
  });
};

export const getFreshMeat = (params) => {
  return api.sendGet(endpoints.GET_FRESH_MEAT, {
    params,
  });
};

export const getFreshSeafood = (params) => {
  return api.sendGet(endpoints.GET_FRESH_SEAFOOD, {
    params,
  });
};

export const getProductContain = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_CONTAINS, {
    params,
  });
};

export const getProductHistorySchema = () => {
  return api.sendGet(endpoints.GET_PRODUCT_HISTORY_DETAIL_SCHEMA);
};
