import { useEffect, useRef } from 'react';

import isDeepEqual from 'lodash/isEqual';

const useCustomCompareEffect = (effectCallback, deps, deepEqualFn) => {
  const ref = useRef(undefined);

  if (!ref.current || !deepEqualFn(deps, ref.current)) {
    ref.current = deps;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, ref.current);
};

const isPrimitive = (val) => {
  const type = typeof val;
  return val === null || (type !== 'object' && type !== 'function');
};

// This hook should be used with dependencies are non-primitive type
export const useDeepCompareEffect = (effectCallback, deps) => {
  if (!(deps instanceof Array) || !deps.length) {
    console.warn(
      '`useDeepCompareEffect` should not be used with no dependencies. Use React.useEffect instead.'
    );
  }

  if (deps.every(isPrimitive)) {
    console.warn(
      '`useDeepCompareEffect` should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
    );
  }

  useCustomCompareEffect(effectCallback, deps, isDeepEqual);
};
