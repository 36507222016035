import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import './ButtonWithIcon.less';

const { Text } = Typography;

const ButtonSmallIcon = ({
  icon,
  label,
  toggle = false,
  className = '',
  textDisplay,
  intl,
  ...otherProps
}) => {
  const mapToLabelContent = () => {
    let newLabel = '';
    try {
      newLabel = intl.formatMessage(label);
    } catch (e) {
      newLabel = label;
    }
    return newLabel;
  };
  return (
    <Button
      className={classNames(className, {
        'button-small-icon': true,
        'button-small-icon--toggle': toggle,
      })}
      icon={icon}
      {...otherProps}
    >
      <Text
        className={classNames({
          'button-small-icon__message': true,
          'button-small-icon__message--toggle': toggle,
          'button-large-icon__message--disabled': otherProps.disabled,
        })}
      >
        {textDisplay
          ? textDisplay
          : // <FormattedMessage {...label} />
            mapToLabelContent()}
      </Text>
    </Button>
  );
};

ButtonSmallIcon.prototype = {
  icon: PropTypes.element,
  label: PropTypes.object,
  // display text without translation
  textDisplay: PropTypes.string,
  onClick: PropTypes.func,
  toggle: PropTypes.bool,
  className: PropTypes.string,
};

export default injectIntl(ButtonSmallIcon);
