import { Col, Menu, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import _ from 'lodash';

import {
  FormAddButton as AddButton,
  CarouselNext,
  CarouselPrevious,
  CarouselSection,
  CustomNotification,
  FormDeleteButton as DeleteButton,
  FormEditButton as EditButton,
  WithLoading,
  dialogFunction,
} from 'common/components';

import { useCheckAllowEdit } from 'pages/qa-spec/hooks';
import { TotalPanels } from 'pages/product-full-view/components/product-media-area/multiple-panel/TabFactPanels';

import AddLabel from 'pages/product-full-view/components/product-media-area/nutrition-label/add-label';
import { NutritionProvider } from 'pages/product-full-view/components/product-media-area/nutrition-label/context/nutritionContext';

import {
  GroupActions,
  GroupPartName,
  MenuItem,
  PanelContextContainer,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/components';

import {
  TYPE_GROUPS,
  useGetAllGroupProduct,
  useInvalidateGroupDetail,
  useVisibleGroupModal,
} from 'pages/product-full-view/components/product-media-area/shared/hooks';

import {
  getSelectedItemGroup,
  transformToTextGroupName,
} from 'pages/product-full-view/components/product-media-area/shared/utils';
import {
  QaGroupAddition,
  QaGroupAssign,
  QaGroupDeletion,
  QaGroupEdit,
  QaGroupPreview,
} from '../group-data';

import { GroupLayoutProvider } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/GroupLayoutProvider';

import { filterProductGroups } from 'pages/product-full-view/components/product-media-area/multiple-panel/tab-allergens/utils';
import {
  DRUG_FACTS_TYPE,
  NUTRITION_TYPE,
  PET_NUTRITION_TYPE,
  SUPPLEMENT_TYPE,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/constants';
import { productFullViewKeys, useCheckAllowManageGroupData } from 'hooks';
import {
  useGetAllNutritionData,
  useMutationDeleteQaSpecFactsPanel,
} from './hook';

import { sleep } from 'utils/delay';

import './QaSpecNutritionData.less';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

import {
  addMissingDefaultValue,
  compareSnapshotNutrients,
  getNutrientList,
} from './utils';
import VerticalLabel from './qa-spec-fact-panel/VerticalLabel';
import VerticalLabelWithMicronutrient from './qa-spec-fact-panel/VerticalLabelWithMicronutrient';
import LinearLabel from './qa-spec-fact-panel/LinearLabel';
import SupplementStandardLabel from './qa-spec-fact-panel/SupplementStandardLabel';
import DrugStandardLabel from './qa-spec-fact-panel/DrugStandardLabel';
import WrapperFactsPanelItem from './WrapperFactsPanelItem';
import { useQueryQaFormulaData } from 'pages/qa-spec/components/qa-formula/queries';
import PetNutritionFacts from './qa-spec-fact-panel/PetNutritionFacts';
import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import { useQueryClient } from '@tanstack/react-query';

const DEFAULT_SLIDE_SHOW = 1;

const QaSpecNutritionData = ({ productId }) => {
  const { totalFactsPanels, isLoading } = useGetAllNutritionData({
    productId,
  });

  const { invalidateQaFormulaData } = useQueryQaFormulaData({
    enabled: false,
  });
  const queryClient = useQueryClient();

  const carouselRef = useRef();
  const addLabelRef = useRef();

  const [visible, setVisible] = useState(false);
  const [factPanelItem, setFactPanelItem] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const {
    nutritionDeleteMutation,
    supplementDeleteMutation,
    drugFactDeleteMutation,
    petFoodDeleteMutation,
  } = useMutationDeleteQaSpecFactsPanel({ id: productId });

  /* Group Actions */

  const { productGroups } = useGetAllGroupProduct(productId);

  const {
    visibleModalAddGroup,
    visibleModalEditGroup,
    visibleModalPreviewGroup,
    visibleModalAssignGroup,
    visibleModalDeleteGroup,
    handler: { onGroupModal, offGroupModal },
  } = useVisibleGroupModal();

  const hasManageGroupPermission = useCheckAllowManageGroupData();

  const selectedFactsPanelGroupIds = factPanelItem?.groupIds ?? [];

  const { defaultGroup, selectedProductGroups } = getSelectedItemGroup(
    selectedFactsPanelGroupIds,
    productGroups
  );
  const filteredProductGroups = filterProductGroups(
    selectedFactsPanelGroupIds,
    productGroups
  );
  /* End Groups  */

  const handleSelectPanel = (panelItem, index) => {
    if (panelItem?.id === factPanelItem?.id) setFactPanelItem(null);
    else setFactPanelItem({ ...panelItem, index });
  };

  const getSelectedPanelInfo = () => {
    const deleteInfo = {
      mutationInfo: null,
      params: null,
    };

    if (factPanelItem.factsPanelType.toLowerCase() === SUPPLEMENT_TYPE) {
      deleteInfo.mutationInfo = supplementDeleteMutation;
      deleteInfo.params = {
        productItemId: productId,
        supplementPanelIds: [factPanelItem?.id],
      };
    }

    if (factPanelItem.factsPanelType.toLowerCase() === NUTRITION_TYPE) {
      deleteInfo.mutationInfo = nutritionDeleteMutation;
      deleteInfo.params = {
        productItemId: productId,
        nutritionPanelId: factPanelItem?.id,
      };
    }

    if (factPanelItem.factsPanelType.toLowerCase() === DRUG_FACTS_TYPE) {
      deleteInfo.mutationInfo = drugFactDeleteMutation;
      deleteInfo.params = {
        productItemId: productId,
        drugPanelIds: [factPanelItem?.id],
      };
    }

    if (factPanelItem.factsPanelType.toLowerCase() === PET_NUTRITION_TYPE) {
      deleteInfo.mutationInfo = petFoodDeleteMutation;
      deleteInfo.params = {
        productItemId: productId,
        petNutritionFactPanelIds: [factPanelItem?.id],
      };
    }

    return deleteInfo;
  };

  const confirmDelete = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning
          text='selected Facts Panel'
          ixoneIgnore={ixoneIgnore}
        >
          Are you sure you want to delete the selected Facts Panel?
        </IXONEShieldWarning>
      ),
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        const { params, mutationInfo } = getSelectedPanelInfo();

        await mutationInfo.mutateAsync(
          { ...params, ixOneIgnore: ixoneIgnore },
          {
            onSuccess: (result) => {
              if (result?.isSuccess) {
                invalidateQaFormulaData({ productId });
                CustomNotification.success(
                  'Delete selected Facts Panel successfully.'
                );
                setFactPanelItem(null);
                queryClient.invalidateQueries({
                  queryKey: productFullViewKeys.itemData(productId),
                });
              } else {
                CustomNotification.error(result?.message);
              }
            },
          }
        );
      },
    });
  };

  const handleEditPanel = async () => {
    setVisible(true);
    await sleep(100);
    addLabelRef?.current?.initAddLabel('edit');
  };

  const handleAddPanel = async () => {
    setFactPanelItem(null);
    setVisible(true);
    await sleep();
    addLabelRef?.current?.initAddLabel('add');
  };

  const isAllowEditProduct = useCheckAllowEdit();
  const isDisable = !isAllowEditProduct || !factPanelItem;

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const showCarousel = totalFactsPanels.every(
    (item) =>
      item.nutrientFormatType !== 'US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES'
  );

  const disableBtnCarousel =
    showCarousel && DEFAULT_SLIDE_SHOW < totalFactsPanels?.length;

  return (
    <NutritionProvider>
      <Row className='tab-qa-fact-panels__list-button' justify='space-between'>
        <Col>
          <Space>
            <Space>
              <CarouselPrevious
                onClick={() => {
                  if (carouselRef?.current) carouselRef.current.prev();
                }}
                disabled={!disableBtnCarousel || currentSlide === 0}
              />
              <CarouselNext
                onClick={() => {
                  if (carouselRef?.current) carouselRef.current.next();
                }}
                disabled={
                  !disableBtnCarousel ||
                  currentSlide === totalFactsPanels?.length - DEFAULT_SLIDE_SHOW
                }
              />
            </Space>
            <TotalPanels totalValue={totalFactsPanels?.length || 0} />
          </Space>
        </Col>
        {!isQASpecLocked ? (
          <Col>
            <Space>
              <AddButton
                onClick={handleAddPanel}
                disabled={!isAllowEditProduct}
              />
              <EditButton onClick={handleEditPanel} disabled={isDisable} />

              <IXONECertificationWrapper
                showModal={!!factPanelItem && factPanelItem?.fromTdc}
                type={TYPE_VIEW.FACT_PANEL}
                onConfirm={(ixoneIgnore) => {
                  confirmDelete(ixoneIgnore);
                }}
              >
                {(handleConfirmIXONE) => (
                  <DeleteButton
                    onClick={() => {
                      handleConfirmIXONE({
                        originalData: {},
                        changedFields:
                          factPanelItem.factsPanelType.toLowerCase(),
                      });
                    }}
                    disabled={isDisable}
                  />
                )}
              </IXONECertificationWrapper>

              <GroupActions
                overlay={() => (
                  <Space>
                    <Menu>
                      <MenuItem>
                        <MenuItem.AddGroup
                          disabled={!hasManageGroupPermission}
                          onClick={() => onGroupModal(TYPE_GROUPS.addGroup)}
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.PreviewGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.previewGroup)}
                          disabled={
                            selectedProductGroups?.length === 0 ||
                            !factPanelItem
                          }
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.AssignGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.assignGroup)}
                          disabled={
                            filteredProductGroups?.length === 0 ||
                            !factPanelItem ||
                            !hasManageGroupPermission
                          }
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.EditGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.editGroup)}
                          disabled={
                            selectedProductGroups?.length === 0 ||
                            !factPanelItem ||
                            !hasManageGroupPermission
                          }
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.DeleteGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.deleteGroup)}
                          disabled={
                            selectedProductGroups?.length === 0 ||
                            !factPanelItem ||
                            !hasManageGroupPermission
                          }
                        />
                      </MenuItem>
                    </Menu>
                  </Space>
                )}
              >
                <GroupActions.ButtonWrapper />
              </GroupActions>
            </Space>
          </Col>
        ) : null}
      </Row>
      <WithLoading loading={isLoading}>
        <div
          className={classNames({
            'tab-qa-fact-panels__hidden-clone-carousel':
              totalFactsPanels?.length < DEFAULT_SLIDE_SHOW,
            'tab-qa-fact-panels__slider': true,
            'hidden-border-field-set': true,
          })}
        >
          {showCarousel ? (
            <CarouselSection
              ref={carouselRef}
              slidesToShow={DEFAULT_SLIDE_SHOW}
              slidesToScroll={DEFAULT_SLIDE_SHOW}
              variableWidth={true}
              centerMode={false}
              infinite={false}
              afterChange={(e) => {
                setCurrentSlide(e);
              }}
            >
              {totalFactsPanels?.map((factPanel, index) => {
                return (
                  <RenderLabelType
                    key={factPanel?.id}
                    productId={productId}
                    property={factPanel}
                    selectedItem={factPanelItem}
                    onSelectPanel={(item) => handleSelectPanel(item, index)}
                  />
                );
              })}
            </CarouselSection>
          ) : (
            <div className='tab-qa-fact-panels__linear-label scroller'>
              {totalFactsPanels?.map((factPanel, index) => {
                return (
                  <RenderLabelType
                    key={factPanel?.id}
                    productId={productId}
                    property={factPanel}
                    selectedItem={factPanelItem}
                    onSelectPanel={(item) => handleSelectPanel(item, index)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </WithLoading>

      <AddLabel
        ref={addLabelRef}
        visible={visible}
        setVisible={setVisible}
        factsPanel={factPanelItem}
        typeView='qa-spec'
        onReset={() => {
          setFactPanelItem(null);
        }}
      />

      {/* Group Data */}
      {visibleModalAddGroup && (
        <QaGroupAddition
          visible={visibleModalAddGroup}
          onCancel={offGroupModal}
          productId={productId}
          onAddSuccess={() => setFactPanelItem(null)}
        />
      )}

      {visibleModalEditGroup && (
        <GroupLayoutProvider>
          <QaGroupEdit
            visible={visibleModalEditGroup}
            onCancel={offGroupModal}
            defaultGroupId={defaultGroup?.groupId}
            productGroups={selectedProductGroups}
            productId={productId}
            onEditSuccess={() => setFactPanelItem(null)}
          />
        </GroupLayoutProvider>
      )}

      {visibleModalAssignGroup && (
        <QaGroupAssign
          visible={visibleModalAssignGroup}
          onCancel={offGroupModal}
          defaultGroupId={filteredProductGroups?.[0]?.groupId}
          productGroups={filteredProductGroups}
          additionalNutritionFacts={
            factPanelItem?.factsPanelType === 'NutritionFactsPanel'
              ? [factPanelItem]
              : []
          }
          additionalSupplementFacts={
            factPanelItem?.factsPanelType === 'SupplementFactsPanel'
              ? [factPanelItem]
              : []
          }
          additionalDrugFacts={
            factPanelItem?.factsPanelType === 'DrugFactsPanel'
              ? [factPanelItem]
              : []
          }
          additionalPetFood={
            factPanelItem?.factsPanelType === 'PetNutritionFactsPanel'
              ? [factPanelItem]
              : []
          }
          selectedGroupIds={selectedFactsPanelGroupIds}
          productId={productId}
          onAssignSuccess={() => setFactPanelItem(null)}
        />
      )}

      {visibleModalPreviewGroup && (
        <QaGroupPreview
          visible={visibleModalPreviewGroup}
          onCancel={offGroupModal}
          defaultGroupId={defaultGroup?.groupId}
          productGroups={selectedProductGroups}
          productId={productId}
        />
      )}

      {visibleModalDeleteGroup && (
        <QaGroupDeletion
          visible={visibleModalDeleteGroup}
          onCancel={offGroupModal}
          productGroups={selectedProductGroups}
          productId={productId}
          onDeleteSuccess={() => setFactPanelItem(null)}
        />
      )}
      {/* End Group Data */}
    </NutritionProvider>
  );
};

export default QaSpecNutritionData;

const RenderLabelType = ({
  property,
  productId,
  selectedItem,
  onSelectPanel,
}) => {
  const { productGroups } = useGetAllGroupProduct(productId);

  const FactsPanel = {
    SupplementFactsPanel: RenderSupplement,
    NutritionFactsPanel: RenderNutritionPanel,
    DrugFactsPanel: RenderDrugFacts,
    PetNutritionFactsPanel: RenderPetFood,
  };

  const title = transformToTextGroupName(property?.groupIds, productGroups);

  const panelFactsType = property?.factsPanelType;
  const FactsPanelItem = FactsPanel?.[panelFactsType];

  return (
    <>
      {FactsPanelItem ? (
        <GroupPartName title={title}>
          <div
            className={classNames({
              'panel-item': true,
              'scroller--y': true,
              'panel-item__selected': selectedItem?.id === property?.id,
            })}
            onClick={() => onSelectPanel(property)}
          >
            <WrapperFactsPanelItem
              property={property}
              overlayStyle={{ zIndex: 3 }}
            >
              <PanelContextContainer
                labelPreview={
                  <FactsPanelItem
                    property={{ ...property, snapshotData: null }}
                  />
                }
                downloadFileName={`${property?.factsPanelType}_${property?.id}`}
              >
                <FactsPanelItem property={property} />
              </PanelContextContainer>
            </WrapperFactsPanelItem>
          </div>
        </GroupPartName>
      ) : null}
    </>
  );
};

export const RenderNutritionPanel = ({ property }) => {
  const { nutritionFactProperties = [], snapshotData, ...restLabel } = property;

  const labelType = {
    US_FDA_NFP_2020_STANDARD: VerticalLabel,
    US_FDA_NFP_2020_STANDARD_SIDE_BY_SIDE: VerticalLabelWithMicronutrient,
    US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES: LinearLabel,
  };

  const nutrientFormatType = property?.nutrientFormatType;
  const FactPanel = labelType?.[nutrientFormatType] || VerticalLabel;

  const { snapshotValue, currentValue } = addMissingDefaultValue({
    snapshotValue: snapshotData?.nutritionFactProperties || [],
    currentValue: nutritionFactProperties || [],
  });

  const snapshotNutrients = snapshotData
    ? compareSnapshotNutrients({
        snapshotValue,
        currentValue,
      })
    : nutritionFactProperties;

  const nutrients = getNutrientList(snapshotNutrients);

  return (
    <FactPanel
      property={restLabel}
      nutrients={nutrients}
      snapshotValue={snapshotData}
    />
  );
};

export const RenderSupplement = ({ property }) => {
  const { snapshotData, nutrients, dietaryIngredients, ...restLabel } =
    property ?? {};

  const snapshotNutrients = snapshotData
    ? compareSnapshotNutrients({
        snapshotValue: snapshotData?.nutrients || [],
        currentValue: nutrients,
      })
    : nutrients;

  const snapshotDietaryIngredients = snapshotData
    ? compareSnapshotNutrients({
        snapshotValue: snapshotData?.dietaryIngredients || [],
        currentValue: dietaryIngredients,
      })
    : dietaryIngredients;

  return (
    <SupplementStandardLabel
      property={restLabel}
      snapshotValue={snapshotData}
      nutrients={snapshotNutrients}
      dietaryIngredients={snapshotDietaryIngredients}
    />
  );
};

export const RenderDrugFacts = ({ property }) => {
  const { snapshotData } = property;
  const addHightTextDugFacts = _.cloneDeep(property);

  const path = [
    'otherInformation',
    'activeIngredients',
    'directions',
    'uses.useDetail',
    'warnings',
  ];

  path.forEach((item) => {
    const snapshotValue = snapshotData
      ? compareSnapshotNutrients({
          snapshotValue: _.get(snapshotData, item) || [],
          currentValue: _.get(property, item) || [],
        })
      : _.get(property, item);

    _.set(addHightTextDugFacts, item, snapshotValue);
  });

  return (
    <DrugStandardLabel
      property={addHightTextDugFacts}
      snapshotValue={snapshotData}
    />
  );
};

export const RenderPetFood = ({ property }) => {
  const { snapshotData, nutrients } = property;

  const snapshotNutrients = snapshotData
    ? compareSnapshotNutrients({
        snapshotValue: snapshotData?.nutrients || [],
        currentValue: nutrients,
      })
    : nutrients;

  return (
    <PetNutritionFacts
      currentData={property}
      previousData={snapshotData}
      nutrients={snapshotNutrients}
    />
  );
};
