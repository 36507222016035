import React from 'react';

import { Tooltip, Typography } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import FolderPanelActions from './FolderPanelActions';
import { IconFolderAsset, NodeTree } from 'common/components/folder/FolderTree';
import { FolderTree } from 'common/components/folder';

import {
  getNodeTreeData,
  handleOpenDetailItem,
} from 'common/components/folder/utils';

import { useGetFolderTreeData } from 'common/components/folder/hooks';
import { useResize, useChangeSize } from 'hooks/useResize';

import emptyFolderIcon from 'assets/folder/empty-folder.png';
import folderIcon from 'assets/folder/folder.png';
import classnames from 'classnames';

const FolderPanelContent = (props) => {
  const { shortDetail } = props;

  const parentNodeFolder = getNodeTreeData({
    node: { ...shortDetail, type: 'folder' }, // shortDetail doesn't have type value !!!
    folderParam: defaultFolderParam,
    fileParam: defaultFileParam,
  });

  const {
    treeData,
    selectedItem,
    expandedKeys,
    handleUpdateExpandedKeys,
    handleLoadMoreFolder,
    handleUpdateSelectedItem,
    handleBuildHierarchyTree,
  } = useGetFolderTreeData({
    defaultTreeProp: [parentNodeFolder],
    defaultExpandedKeys: [parentNodeFolder?.key],
    pageSize: 9999,
    filterType: 'all',
    folderParam: defaultFolderParam,
    fileParam: defaultFileParam,
  });

  const [treeWrapperWidth, treeWrapperHeight] =
    useChangeSize('#folder-panel-tree');
  const [treeWidth, treeHeight] = useChangeSize(
    '#folder-panel-tree > .folder-tree'
  );

  return (
    <div
      style={{
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <FolderPanelActions
          treeData={treeData}
          shortDetail={shortDetail}
          selectedItem={selectedItem?.selectedFolder}
          handleBuildHierarchyTree={handleBuildHierarchyTree}
        />
        <Typography.Title
          className='folder-panel-content__folder-name'
          style={{ fontSize: '16px' }}
        >
          Contents
        </Typography.Title>
      </div>
      <div
        className={classnames('folder-panel__tree', {
          'folder-panel__tree--hide-scroll': treeWrapperHeight > treeHeight,
        })}
        id='folder-panel-tree'
      >
        <FolderTree
          treeData={treeData}
          onLoadData={handleLoadMoreFolder}
          height={treeWrapperHeight}
          expandedKeys={expandedKeys}
          selectedItem={selectedItem}
          onExpandedChange={handleUpdateExpandedKeys}
          onSelectedChange={handleUpdateSelectedItem}
        />
      </div>
    </div>
  );
};

const checkEmptyContents = (node) => {
  const {
    items = 0,
    contentsCount = 0,
    assetCount = 0,
    memberCount = 0,
    productCount = 0,
    queryCount = 0,
    subFolderCount = 0,
    reportingCount = 0,
  } = node;

  return (
    items +
      contentsCount +
      assetCount +
      memberCount +
      productCount +
      queryCount +
      subFolderCount +
      reportingCount ===
    0
  );
};

const defaultFolderParam = {
  renderFolder: (node) => {
    const isEmptyContents = checkEmptyContents(node);

    if (isEmptyContents) {
      return {
        title: (
          <NodeTree title={node.folderName ?? node.description ?? ''}>
            <Tooltip title='View detail'>
              <PlayCircleOutlined
                style={{ fontSize: 16 }}
                onClick={() => handleOpenDetailItem(node?.type, node?.id)}
              />
            </Tooltip>
          </NodeTree>
        ),
        icon: <IconFolderAsset src={emptyFolderIcon} />,
        isLeaf: true,
      };
    }
    return {
      title: (
        <NodeTree title={node.folderName ?? node.description ?? ''}>
          <Tooltip title='View detail'>
            <PlayCircleOutlined
              style={{ fontSize: 16 }}
              onClick={() => handleOpenDetailItem(node?.type, node?.id)}
            />
          </Tooltip>
        </NodeTree>
      ),
      icon: <IconFolderAsset src={folderIcon} />,
    };
  },
};

const defaultFileParam = {
  renderFile: (node) => {
    return {
      title: (
        <NodeTree title={node.folderName ?? node.description ?? ''}>
          <Tooltip title='View detail'>
            <PlayCircleOutlined
              style={{ fontSize: 16 }}
              onClick={() => handleOpenDetailItem(node?.type, node?.id)}
            />
          </Tooltip>
        </NodeTree>
      ),
    };
  },
};

export default FolderPanelContent;
