import { useState } from 'react';
import { Row, Col, Form, Space, Button } from 'antd';

import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { BasicFormItem } from 'common/components/form/basic-form';
import AddAssetPimModal from 'pages/product-full-view/components/modal/add-asset-pim-modal';

import SnapshotFormItem from 'pages/qa-spec/components/qa-spec-tab/SnapshotFormItem';
import { renderPopoverContent } from 'pages/qa-spec/utils';

const CertificateFormList = (props) => {
  const {
    type = 'text',
    isEdit,
    disabled,
    selectProps,
    name: nameForm,
    certificateType,
    snapshotData,
    supplierModifiedData,
    isLoadingSnapshot,
  } = props;

  const [isLinkToNewAssetModalOpen, setIsLinkToNewAssetModalOpen] =
    useState(false);

  const toggleLinkToNewAssetModal = () => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
  };

  return (
    <>
      <Form.List name={nameForm}>
        {(fields, { add, remove }) => {
          const handleAddField = () => {
            add();
          };

          return (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Row key={key} align='middle' style={{ flexWrap: 'nowrap' }}>
                    <Col style={{ flex: 1 }}>
                      <SnapshotFormItem
                        supplierModifiedData={
                          supplierModifiedData?.[nameForm]?.[name]
                        }
                        snapshotData={snapshotData?.[nameForm]?.[name]}
                        isLoadingSnapshot={isLoadingSnapshot}
                        renderPopoverContent={renderPopoverContent(
                          'Certifier',
                          snapshotData?.[nameForm]?.[name],
                          supplierModifiedData?.[nameForm]?.[name]
                        )}
                      >
                        <BasicFormItem
                          {...restField}
                          name={name}
                          label='Certifier:'
                          layout='horizontal'
                          selectProps={selectProps}
                          type={
                            type === 'select' && isEdit && !disabled
                              ? 'select'
                              : 'text'
                          }
                          allowEdit={isEdit && !disabled}
                          inputProps={{
                            disabled: disabled,
                          }}
                        />
                      </SnapshotFormItem>
                    </Col>

                    <Col flex={'30px'} style={{ paddingLeft: 8 }}>
                      {isEdit && (
                        <Form.Item>
                          <CloseOutlined
                            className='form-list-remove-btn'
                            onClick={() => remove(name)}
                            style={{ color: 'red' }}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                );
              })}
              {isEdit && (
                <Row className='form-list-add-btn' gutter={[5, 5]}>
                  <Space>
                    <Button
                      block
                      onClick={() => handleAddField()}
                      icon={<PlusOutlined />}
                      disabled={disabled}
                      type='primary'
                    >
                      Add field
                    </Button>

                    <Button
                      danger
                      icon={<UploadOutlined />}
                      onClick={toggleLinkToNewAssetModal}
                      disabled={disabled}
                    >
                      Upload cetificate document
                    </Button>
                  </Space>
                </Row>
              )}
            </>
          );
        }}
      </Form.List>
      {isLinkToNewAssetModalOpen && (
        <AddAssetPimModal
          onCloseModal={toggleLinkToNewAssetModal}
          addType={'Certificate'}
          certificateType={certificateType}
          getImagery={[]}
          getDocument={() => {
            toggleLinkToNewAssetModal();
          }}
          qaSpec
        />
      )}
    </>
  );
};

export default CertificateFormList;
