import React, { useState, useEffect } from 'react';

import { Modal, Input } from 'antd';

import { Form } from 'common/components';

import { apiHandler } from 'utils/api';
import * as reportingServices from 'services/reporting';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

const CloneReportingModal = (props) => {
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();
  const [form] = Form.useForm();

  const { selectedReport, visible, setVisible } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const onOk = () => {
    callApiCloneReporting();
  };

  const onCancel = () => {
    setVisible(false);
  };

  const successCallback = () => {
    setVisible(false);
    reloadPage();
  };

  const onFinally = () => {
    setSubmitLoading(false);
  };

  const onValuesChange = (_, allValues) => {
    setSubmitDisabled(allValues?.name ? false : true);
  };

  const callApiCloneReporting = async () => {
    setSubmitLoading(true);

    const params = await form.validateFields();
    if (!params.name) return;

    params.advanceSearchType = selectedReport?.advType;
    params.id = selectedReport?.id;

    const successMessage = intl.formatMessage(Messages.reportingCloneSuccess);
    const errorMessage = intl.formatMessage(Messages.reportingCloneError);

    apiHandler({
      service: reportingServices.cloneReporting,
      params,
      successMessage,
      errorMessage,
      successCallback,
      onFinally,
    });
  };

  const resetForm = () => {
    if (!visible) form.resetFields();
  };

  useEffect(() => {
    resetForm();
  }, [visible]);

  return (
    <Modal
      layout='vertical'
      visible={visible}
      destroyOnClose
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ loading: submitLoading, disabled: submitDisabled }}
    >
      <Form form={form} layout='vertical' onValuesChange={onValuesChange}>
        <Form.Item
          label={intl.formatMessage(Messages.reportingCloneName)}
          name='name'
          rules={[
            {
              required: true,
              message: intl.formatMessage(Messages.reportingCloneRequired),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage(Messages.reportingCloneDescription)}
          name='description'
        >
          <Input.TextArea
            autoSize={{
              minRows: 3,
              maxRows: 6,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CloneReportingModal;
