import { useEffect } from 'react';

import { useAsync } from 'hooks/useAsync';

import { getListUserToAssignRequest } from 'services/request-grid';

export const useGetListUserToAssignRequest = ({ isEnabled }) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(getListUserToAssignRequest({ searchText: '' }));
    }
  }, [isEnabled, run]);

  return {
    assignedUsers: data?.data ?? [],
  };
};
