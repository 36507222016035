import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal, Row, Input, Typography } from 'antd';
import AgGrid from 'common/components/ag-grid/AgGrid';
import { dialogFunction } from 'common/components';

import * as securityServices from 'services/roles-security';
import * as securityEndpoints from 'services/roles-security/endpoints';

import { useStatePrevious } from 'hooks/usePrevious';
import { useHandleReloadPage } from 'hooks/useReloadPage';

import { apiHandler } from 'utils/api';

import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/member-profile';

import './NewMemberModal.less';

const { Paragraph, Text } = Typography;

/**
 * ! MAIN COMPONENTS
 * @param {*} props
 * @returns
 */
const NewMemberModal = (props) => {
  const { selectedRole, newMemberRoleGridColumns, visible, onClose, mapId } =
    props;

  const [__, setReloadGrid] = useHandleReloadPage();

  const selectedRowsHook = useStatePrevious([]);

  const [selectedRows] = selectedRowsHook;

  const handleAddMemberToRole = async () => {
    const params = {
      roleId: selectedRole?.roleId,
      memberIds: selectedRows,
    };

    const successCallback = () => {
      onClose();
      setReloadGrid();
    };

    await apiHandler({
      service: securityServices.addMembersToRole,
      params,
      successMessage: 'Add member(s) to role successfully',
      errorMessage: 'Failed to add member(s) to role',
      successCallback,
    });
  };

  const confirmAdd = () => {
    const confirmContent = (
      <Paragraph>
        Do you want to add the selected member(s) to the role&nbsp;
        <Text strong>{selectedRole?.roleName}</Text>?
      </Paragraph>
    );

    dialogFunction({
      type: 'warn',
      content: confirmContent,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleAddMemberToRole,
    });
  };

  const handleCancel = () => onClose();

  return (
    <Modal
      className='new-member-modal'
      title='Add new member(s) to role'
      visible={visible}
      onOk={confirmAdd}
      onCancel={handleCancel}
      okButtonProps={{ disabled: selectedRows.length <= 0 }}
      width={'50%'}
      height={'80%'}
      okText='Add'
      centered
      maskClosable={false}
      destroyOnClose
    >
      <NewMemberModalContent
        selectedRole={selectedRole}
        mapId={mapId}
        selectedRowsHook={selectedRowsHook}
        newMemberRoleGridColumns={newMemberRoleGridColumns}
      />
    </Modal>
  );
};

/**
 * ! SUB COMPONENT
 * @param {} props
 * @returns
 */
const NewMemberModalContent = (props) => {
  const { selectedRole, mapId, selectedRowsHook, newMemberRoleGridColumns } =
    props;

  const [__, setSelectedRow, refSelectedRow] = selectedRowsHook;

  const [search, setSearch] = useState('');
  const [gridApi, setGridApi, refGridApi] = useStatePrevious(null);

  const roleId = selectedRole?.roleId;

  const onSetSelectedRows = (selectedNodesDetail) => {
    const selectedMemberIdList = selectedNodesDetail.map(
      (nodeDetail) => nodeDetail?.id
    );
    setSelectedRow(selectedMemberIdList);
  };

  useEffect(() => {
    refGridApi.current && refGridApi.current.sizeColumnsToFit();
  }, [gridApi]);

  const paramsGrid = { roleId, search: { searchText: search } };

  return (
    <Row className='new-user-modal__content'>
      <Paragraph className='new-user-modal__text' strong>
        {<FormattedMessage {...Messages.roleText} />}:
        {' ' + selectedRole?.roleName}
      </Paragraph>
      <Input.Search
        className='new-user-modal__search'
        placeholder='Search members...'
        onSearch={(value) => setSearch(value)}
        enterButton
        allowClear
      />
      <Row style={{ height: '100%', width: '100%' }}>
        {newMemberRoleGridColumns && (
          <AgGrid
            columnDefs={newMemberRoleGridColumns}
            urlGrid={securityEndpoints.MEMBERS_NOT_HAVE_ROLE}
            urlGridDistinct={securityEndpoints.MEMBERS_HAVE_NOT_ROLE_DISTINCT}
            responseParams='data'
            paramsGrid={paramsGrid}
            isShowItemSelectionLog={false}
            onHandleSelectedRowCustom={onSetSelectedRows}
            getGridApi={(gridApi) => setGridApi(gridApi)}
            mapId={mapId}
          />
        )}
      </Row>
    </Row>
  );
};

NewMemberModal.propTypes = {
  selectedRole: PropTypes.object,
  newMemberRoleGridColumns: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  mapId: PropTypes.func,
};

NewMemberModalContent.propTypes = {
  selectedRole: PropTypes.object,
  mapId: PropTypes.func,
  selectedRowsHook: PropTypes.object,
  newMemberRoleGridColumns: PropTypes.object,
};

export default NewMemberModal;
