export const GET_QA_SPEC_DATA_HEADER = '/api/pim/get-qa-spec-data-header';
export const SAVE_QA_SPEC_DATA_HEADER = '/api/pim/save-qa-spec-data-header';

export const GET_QA_SPEC_PRODUCT_SPEC =
  '/api/pim/get-qa-spec-data-product-specification';
export const SAVE_QA_SPEC_PRODUCT_SPEC =
  '/api/pim/save-qa-spec-data-product-specification';
export const UPDATE_QA_SPEC_PRODUCT_SPEC_DOCUMENT =
  '/api/pim/update-qa-spec-data-product-specification-document';

export const GET_QA_SPEC_ORGANOLEPTIC_CHARACTERISTIC =
  '/api/pim/get-qa-spec-data-organoleptic-characteristics';
export const SAVE_QA_SPEC_ORGANOLEPTIC_CHARACTERISTIC =
  '/api/pim/save-qa-spec-data-organoleptic-characteristics';

export const GET_QA_SPEC_CONTAMINANT_CHARACTERISTIC =
  '/api/pim/get-qa-spec-data-contaminant-characteristics';
export const SAVE_QA_SPEC_CONTAMINANT_CHARACTERISTIC =
  '/api/pim/save-qa-spec-data-contaminant-characteristics';

export const GET_QA_SPEC_PHY_CHE_CHARACTERISTIC =
  '/api/pim/get-qa-spec-data-physical-and-chemical-characteristics';
export const SAVE_QA_SPEC_PHY_CHE_CHARACTERISTIC =
  '/api/pim/save-qa-spec-data-physical-and-chemical-characteristics';

export const GET_QA_SPEC_MICROBIOLOGICAL_CHARACTERISTIC =
  '/api/pim/get-qa-spec-data-microbiological-characteristics';
export const SAVE_QA_SPEC_MICROBIOLOGICAL_CHARACTERISTIC =
  '/api/pim/save-qa-spec-data-microbiological-characteristics';

export const GET_QA_SPEC_DOCUMENT =
  '/api/pim/get-qa-spec-data-product-specification-documents';
export const LINK_QA_SPEC_DOCUMENT =
  '/api/pim/link-qa-spec-data-product-specification-document';
export const UNLINK_QA_SPEC_DOCUMENT =
  '/api/pim/unlink-qa-spec-data-product-specification-document';
export const GET_SPEC_DATA_MANUFACTURING_PROCESS =
  '/api/pim/get-qa-spec-data-manufacturing-processes';
export const SAVE_SPEC_DATA_MANUFACTURING_PROCESS =
  '/api/pim/save-qa-spec-data-manufacturing-processes';

export const GET_SPEC_DATA_MANUFACTURING_LOCATION =
  '/api/pim/get-qa-spec-data-manufacturing-locations';
export const SAVE_SPEC_DATA_MANUFACTURING_LOCATION =
  '/api/pim/update-qa-spec-data-manufacturing-locations';

export const GET_SPEC_DATA_MANUFACTURING_PROCESS_DOCUMENT =
  '/api/pim/get-qa-spec-data-manufacturing-process-documents';
export const LINK_QA_SPEC_MANUFACTURING_PROCESS_DOCUMENT =
  '/api/pim/link-qa-spec-data-manufacturing-process-document';
export const UNLINK_QA_SPEC_MANUFACTURING_PROCESS_DOCUMENT =
  '/api/pim/unlink-qa-spec-data-manufacturing-process-document';
export const UPDATE_QA_SPEC_MANUFACTURING_PROCESS_DOCUMENT =
  '/api/pim/update-qa-spec-data-manufacturing-process-document';

export const GET_QA_SPEC_DATA_CONTACT = '/api/pim/get-qa-spec-data-contacts';
export const GET_QA_SPEC_DATA_CONTACT_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-contacts';

export const SAVE_QA_SPEC_DATA_CONTACT = '/api/pim/save-qa-spec-data-contacts';

export const UPDATE_DOCS_OR_CERTIFICATION =
  '/api/pim/qa-spec-update-document-or-certificate';

export const GET_DOWNLOAD_QA_SPEC_URL = '/api/pim/download-qa-spec-form';

// QA Evaluation Form
export const GET_QA_EVALUATION_FORM = '/api/pim/evaluation-form';

export const SAVE_DRAFT_QA_SPEC_EVALUATION_FORM =
  '/api/pim/save-draft-qa-spec-evaluation-form';

export const DOWNLOAD_QA_SPEC_FORM = '/api/pim/download-qa-spec-form';

export const UPLOAD_QA_EVALUATION_FORM_ASSET =
  '/api/pim/upload-qa-evaluation-form-asset';

export const SAVE_QA_SPEC_EVALUATION_FORM =
  '/api/pim/save-qa-spec-evaluation-form';

export const UNLINK_QA_SPEC_EVALUATION_FORM_ASSET =
  '/api/pim/unlink-qa-spec-evaluation-form-asset';

export const GET_QA_SPEC_COMPONENTS = '/api/pim/get-qa-spec-data-components';
export const GET_QA_SPEC_COMPONENTS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-components';
export const SAVE_QA_SPEC_COMPONENTS = '/api/pim/save-qa-spec-data-components';

export const ADD_CUSTOM_ALLERGEN = '/api/pim/save-qa-spec-allergens-item';

export const LINK_QA_SPEC_PRODUCT_SPEC_CERTIFICATION =
  '/api/pim/link-qa-spec-data-product-specification-certification';

// produce - deli
export const GET_QA_SPEC_PRODUCE = '/api/pim/get-qa-spec-data-fresh-produce';
export const SAVE_QA_SPEC_PRODUCE = '/api/pim/save-qa-spec-data-fresh-produce';

export const GET_QA_SPEC_DELI = '/api/pim/get-qa-spec-data-fresh-deli';
export const GET_QA_SPEC_DELI_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-fresh-deli';

export const SAVE_QA_SPEC_DELI = '/api/pim/save-qa-spec-data-fresh-deli';

export const GET_QA_SPEC_COMPANY_LOCATION =
  '/api/pim/get-qa-spec-company-locations';
export const SAVE_QA_SPEC_MANUFACTURING_LOCATION =
  '/api/pim/save-qa-spec-data-manufacturing-location';

// Pet food
export const GET_QA_SPEC_DATA_PET_FOOD = '/api/pim/get-qa-spec-data-pet-food';

export const SAVE_QA_SPEC_DATA_PET_FOOD = '/api/pim/save-qa-spec-data-pet-food';

export const GET_QA_PET_FOOD_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-pet-food';

export const GET_QA_PET_NON_FOOD_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-pet-non-food';

export const ADD_QA_SPEC_DATA_MANUFACTURING_LOCATION =
  '/api/pim/add-qa-spec-data-manufacturing-locations';

export const DELETE_QA_SPEC_DATA_MANUFACTURING_LOCATION =
  '/api/pim/delete-qa-spec-data-manufacturing-locations';
