import React from 'react';
import { Card, Avatar, Row, Typography, Col, Tooltip } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';

import { Draggable } from 'react-beautiful-dnd';

const ItemLinkAsset = ({ item }) => {
  return (
    <Draggable
      key={item?.id}
      draggableId={item?.id.toString()}
      index={item?.id}
    >
      {(provided, snapshot) => (
        <Col
          span={6}
          style={{ marginBottom: 10 }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Row
            style={{
              width: '100%',
              border: '1px solid #d9d9d9',
              position: 'relative',
            }}
          >
            <Tooltip title={item?.assetName}>
              <Card
                bordered={false}
                className={
                  snapshot.isDragging
                    ? 'card-asset card-asset--dragging'
                    : 'card-asset'
                }
              >
                <Avatar shape='square' size='large' src={item?.thumbSize100} />
              </Card>
            </Tooltip>
            <div
              style={{
                position: 'absolute',
                top: 4,
                right: 4,
                borderRadius: 1,
                backgroundColor: '#fff',
              }}
            >
              <ShareAltOutlined style={{ color: '#767676', fontSize: 14 }} />
            </div>
            <Row style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
              <Typography.Paragraph
                ellipsis={true}
                style={{
                  marginBottom: '0px',
                  height: '17px',
                  width: '100%',
                  textAlign: 'center',
                  lineHeight: '17px',
                }}
              >
                {item?.assetName?.substring(0, 11)}
              </Typography.Paragraph>
            </Row>
          </Row>
        </Col>
      )}
    </Draggable>
  );
};

export default ItemLinkAsset;
