import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import { CloseSquareOutlined, FolderOpenOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/member-profile';

import * as companyProfileActions from 'pages/company-profile/controllers/actions';
import * as companyProfileSelectors from 'pages/company-profile/controllers/selectors';

import ToggleUploadMediaBtn from '../../controls/member-full/ToggleUploadMediaBtn';

const ToggleTagLineUploadMediaSection = () => {
  const dispatch = useDispatch();

  const isTagLineUploadMediaOpen = useSelector(
    companyProfileSelectors.selectMemberIsTagLineUploadMediaOpen()
  );

  const mapStatusToIcon = () => {
    if (isTagLineUploadMediaOpen) {
      return <CloseSquareOutlined />;
    } else {
      return <FolderOpenOutlined />;
    }
  };

  const mapStatusToText = () => {
    if (isTagLineUploadMediaOpen) {
      return Messages.closeMedia;
    } else {
      return Messages.openMedia;
    }
  };

  const toggleIsTaLineMediaOpen = () => {
    dispatch(
      companyProfileActions.toggleUploadTagLineMedia(!isTagLineUploadMediaOpen)
    );
  };

  return (
    <ContainerButton
      style={{ display: 'flex', maxWidth: 250, marginBottom: '10px' }}
    >
      <ItemButton span={12}>
        <ToggleUploadMediaBtn
          icon={mapStatusToIcon()}
          label={mapStatusToText()}
          clickActionCallback={() => {
            toggleIsTaLineMediaOpen();
          }}
        />
      </ItemButton>
    </ContainerButton>
  );
};

export default ToggleTagLineUploadMediaSection;
