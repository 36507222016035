import * as types from './constants';

// Asset Data Actions
export function getAssetData(assetId) {
  return {
    type: types.GET_ASSET_DATA,
    assetId,
  };
}

export function getAssetDataSuccess(data) {
  return {
    type: types.GET_ASSET_DATA_SUCCESS,
    data,
  };
}

export function getAssetDataError(error) {
  return {
    type: types.GET_ASSET_DATA_ERROR,
    error,
  };
}

export function updateIsActive(payload) {
  return {
    type: types.UPDATE_IS_ACTIVE,
    payload,
  };
}

//* get ftp file list
export function getFtpFileList(payload) {
  return {
    type: types.GET_FTP_FILE_LIST,
    payload,
  };
}

export function getFtpFileListSuccess(payload) {
  return {
    type: types.GET_FTP_FILE_LIST_SUCCESS,
    payload,
  };
}

export function getFtpFileListError(error) {
  return {
    type: types.GET_FTP_FILE_LIST_ERROR,
    error,
  };
}

//* create digital asset
export function createDigitalAssetFull(digitalAsset) {
  return {
    type: types.CREATE_DIGITAL_ASSET_FULL,
    digitalAsset,
  };
}

export function createDigitalAssetFullSuccess(metaData) {
  return {
    type: types.CREATE_DIGITAL_ASSET_FULL_SUCCESS,
    metaData,
  };
}

export function createDigitalAssetFullError(error) {
  return {
    type: types.CREATE_DIGITAL_ASSET_FULL_ERROR,
    error,
  };
}

//* update digital asset
export function updateDigitalAsset(params) {
  return {
    type: types.UPDATE_DIGITAL_ASSET,
    params,
  };
}
export function updateDigitalReplaceAsset(params) {
  return {
    type: types.UPDATE_DIGITAL_REPLACE_ASSET,
    params,
  };
}
export function updateDigitalAssetSuccess() {
  return {
    type: types.UPDATE_DIGITAL_ASSET_SUCCESS,
  };
}

export function updateRequiredField(params) {
  return {
    type: types.UPDATE_REQUIRED_FIELDS,
    params,
  };
}

export function updateDigitalAssetError(error) {
  return {
    type: types.UPDATE_DIGITAL_ASSET_ERROR,
    error,
  };
}
export function gridColumnAssetAssociation(gridName) {
  return {
    type: types.GET_ASSET_ASSOCIATION_GRID_COLUMN,
    gridName,
  };
}

export function gridColumnAssetAssociationSuccess(columns) {
  return {
    type: types.GET_ASSET_ASSOCIATION_GRID_COLUMN_SUCCESS,
    columns,
  };
}

export function gridColumnAssetAssociationError(error) {
  return {
    type: types.GET_ASSET_ASSOCIATION_GRID_COLUMN_ERROR,
    error,
  };
}

export function cancelUpdateSuccess() {
  return {
    type: types.CANCEL_UPDATE_SUCCESS,
  };
}

export function updateAssetType(params) {
  return {
    type: types.UPDATE_ASSET_TYPE,
    params,
  };
}

export function updateAssetTypeSuccess() {
  return {
    type: types.UPDATE_ASSET_TYPE_SUCCESS,
  };
}

export function updateAssetTypeError(error) {
  return {
    type: types.UPDATE_ASSET_TYPE_ERROR,
    error,
  };
}

export const getAuthorizedTags = (memberId) => ({
  type: types.GET_AUTHORIZED_TAGS,
  memberId,
});

export const getAuthorizedTagsSuccess = (data) => ({
  type: types.GET_AUTHORIZED_TAGS_SUCCESS,
  data,
});

export const getAuthorizedTagsError = (error) => ({
  type: types.GET_AUTHORIZED_TAGS_ERROR,
  error,
});
