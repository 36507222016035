import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  editingLayout: false,
  isLayoutChanged: false,
  calendarView: 'day',
  calendarDate: new Date(),
  loadingCalendar: false,
  meetingList: [],
  errorCalendar: false,
  scheduleList: {
    loading: false,
    data: [],
    error: null,
  },
  projectList: {
    loading: false,
    data: [],
    error: false,
  },
  meetingId: null,

  selectedDate: {
    meetingId: null,
    startTime: null,
    endTime: null,
  },

  meetingDetails: {
    loading: false,
    data: null,
    error: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const productReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.TOGGLE_EDIT_LAYOUT:
        draft.editingLayout = action.payload;
        break;
      case types.UPDATE_LAYOUT_CHANGED:
        draft.isLayoutChanged = action.payload;
        break;
      case types.CALENDAR_DAY_VIEW:
      case types.CALENDAR_WEEK_VIEW:
      case types.CALENDAR_MONTH_VIEW:
      case types.CALENDAR_SCHEDULE_VIEW:
        draft.calendarView = action.calendarView;
        break;
      case types.GET_MEETING_LIST:
        draft.loadingCalendar = true;
        draft.errorCalendar = false;
        draft.meetingList = [];
        break;
      case types.GET_MEETING_LIST_SUCCESS:
        draft.loadingCalendar = false;
        draft.meetingList = action.meetingList;
        break;
      case types.GET_MEETING_LIST_ERROR:
        draft.loadingCalendar = false;
        draft.errorCalendar = action.error;
        break;

      case types.UPDATE_CALENDAR_DATE:
        draft.calendarDate = action.calendarDate;
        break;

      case types.GET_SCHEDULE_LIST:
        draft['scheduleList'].loading = true;
        draft['scheduleList'].data = [];
        draft['scheduleList'].error = null;
        break;
      case types.GET_SCHEDULE_LIST_SUCCESS:
        draft['scheduleList'].loading = false;
        draft['scheduleList'].data = action.payload;
        break;
      case types.GET_SCHEDULE_LIST_ERROR:
        draft['scheduleList'].loading = false;
        draft['scheduleList'].error = action.payload;
        break;
      case types.GET_PROJECTS_GRID:
        draft.projectList['loading'] = true;
        break;
      case types.GET_PROJECTS_GRID_SUCCESS:
        draft.projectList['loading'] = false;
        draft.projectList['data'] = action.data;
        break;
      case types.GET_PROJECTS_GRID_ERROR:
        draft.projectList['loading'] = false;
        draft.projectList['error'] = action.error;
        break;

      case types.SAVE_ID_MEETING:
        draft['meetingId'] = action.meetingId;
        break;

      case types.SET_SELECTED_DATE:
        draft.selectedDate['meetingId'] = action.payload?.id;
        draft.selectedDate['startTime'] = action.payload?.startTime;
        draft.selectedDate['endTime'] = action.payload?.endTime;
        break;

      case types.GET_MEETING_DETAILS:
        draft['meetingDetails'].loading = true;
        draft['meetingDetails'].data = [];
        draft['meetingDetails'].error = null;
        break;
      case types.GET_MEETING_DETAILS_SUCCESS:
        draft['meetingDetails'].loading = false;
        draft['meetingDetails'].data = action.payload;
        break;
      case types.GET_MEETING_DETAILS_ERROR:
        draft['meetingDetails'].loading = false;
        draft['meetingDetails'].error = action.payload;
        break;
      case types.RESET_MEETING_DETAILS:
        draft['meetingDetails'].loading = false;
        draft['meetingDetails'].data = null;
        draft['meetingDetails'].error = null;
        draft.meetingList = [];
        break;
    }
  });

export default productReducer;
