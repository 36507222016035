import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getAllGrids = () => {
  return api.sendGet(endpoints.GET_ALL_GRID);
};
export const gridColumnInfo = (params) => {
  return api.sendGet(endpoints.GRID_COLUMN_INFO + `/${params}`);
};

export const saveGridConfig = (params) => {
  return api.sendPost(endpoints.SAVE_GRID_CONFIG, params);
};

export const getDefaultGridConfig = (gridName) => {
  return api.sendGet(
    endpoints.GET_DEFAULT_GRID_CONFIG + `?gridName=${gridName}`
  );
};

export const getListGridConfigs = (params) => {
  return api.sendPost(endpoints.GET_LIST_GRID_CONFIGS, params);
};

export const deleteGridConfig = (params) => {
  return api.sendPost(endpoints.DELETE_GRID_CONFIG, params);
};

export const setDefaultGridConfig = (params) => {
  return api.sendPost(endpoints.SET_DEFAULT_GRID_CONFIG, params);
};
