import React, { useRef } from "react";

import { Popover, Input } from "antd";

import InputAutoHintList from "./InputAutoHintList";

import "./InputAutoComplete.less";

const KEY_CODE = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
};

const InputAutoComplete = (props) => {
  const {
    hintList,
    setSearchText,
    searchText,
    isLoading,
    selectedHint,
    setSelectedHint,
    onKeyDown,
    handleClickHintItem,
  } = props;

  const onInputFocus = () => {
    console.log("focus");
  };

  const onInputClick = () => {
    console.log("k");
  };

  const onInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const onInputBlur = () => {
    setSearchText("");
    setSelectedHint(0);
  };

  const onInputKey = (event) => {
    const keyCode = event.keyCode;
    if (keyCode === KEY_CODE.UP || keyCode === KEY_CODE.DOWN) {
      event.preventDefault();
    }
  };

  const refPop = useRef();

  console.log("refPop", refPop);
  return (
    <div className="avatar-tag-input-auto" onKeyDown={onKeyDown} tabIndex="0">
      <Popover
        ref={refPop}
        placement="bottom"
        title={null}
        content={
          <InputAutoHintList
            hintList={hintList}
            isLoading={isLoading}
            selectedHint={selectedHint}
            setSelectedHint={setSelectedHint}
            handleClickHintItem={handleClickHintItem}
          />
        }
        trigger="click"
        overlayClassName="avatar-tag-input-auto__hint"
      >
        <Input
          className="avatar-tag-input-auto__input"
          placeholder=""
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onClick={onInputClick}
          onChange={onInputChange}
          value={searchText}
          onKeyDown={onInputKey}
        />
      </Popover>
    </div>
  );
};

export default InputAutoComplete;
