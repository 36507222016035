export const mapMemberRoleFormToSubmitValue = ({ formValues, isAddNew }) => {
  let nextSubmitValue = { ...formValues };

  nextSubmitValue = {
    ...formValues,
    memberRolePermissions: formValues?.permissionList,
  };

  delete nextSubmitValue.permissionList;

  isAddNew && delete nextSubmitValue.roleId;

  return nextSubmitValue;
};
