import React from 'react';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import EmailControl from '../../controls/shared/EmailControl';

import SendToFTP from '../../controls/shared/SendToFTP';
import SendToMessage from '../../controls/shared/SendToMessage';

const CategoryHttp = () => {
  return (
    <ContainerButton>
      <ItemButton style={{ marginTop: 2 }} span={8}>
        <EmailControl />
      </ItemButton>
      <ItemButton style={{ marginRight: -86, marginTop: 4 }} span={14}>
        <SendToFTP />
        <SendToMessage />
      </ItemButton>
    </ContainerButton>
  );
};

export default CategoryHttp;
