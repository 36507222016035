import React from 'react';

import { Typography } from 'antd';
import { ReactComponent as PencilSvg } from 'assets/svg-icons/pencil.svg';

const { Text } = Typography;
const SectionHeader = (props) => {
  const { title, showPencil } = props;

  return (
    <>
      {showPencil ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text style={{ marginRight: 5, textTransform: 'uppercase' }}>
            {title}
          </Text>
          <PencilSvg style={{ width: 13, height: 13 }} />
        </div>
      ) : (
        <Text style={{ textTransform: 'uppercase' }}>{title && title}</Text>
      )}
    </>
  );
};

export { SectionHeader };
