import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Card, Typography } from 'antd';
import './AssetTileCard.less';

const { Text } = Typography;

const AssetListItemCard = (props) => {
  const { alt, src, widget, upc12, id, handleToggleAssetContext } = props;
  const [isChecked, setCheckedStatus] = useState(false);

  const onCheckHandler = (e) => {
    setCheckedStatus(e.target.checked);
  };
  console.log(src);
  return (
    <Card
      className='list-item-card'
      bodyStyle={{ padding: '5px', display: 'flex' }}
    >
      <Checkbox
        checked={isChecked}
        onChange={onCheckHandler}
        className='list-item-card__checkbox'
      />
      <div onDoubleClick={() => handleToggleAssetContext(id)}>
        <img alt={alt} src={src} className='list-item-card__img' />
        <Text>{`${upc12} - ${widget}`}</Text>
      </div>
    </Card>
  );
};

AssetListItemCard.propTypes = {
  // ? src - img source
  src: PropTypes.string,
  // ? alt - alternative for image source
  alt: PropTypes.string,
  // ? widget - unknown
  widget: PropTypes.string,
  // ? upc12 - unknown
  upc12: PropTypes.string,
  // ? id
  id: PropTypes.string,
  // ? handleToggleAssetContext
  handleToggleAssetContext: PropTypes.func,
};

export default AssetListItemCard;
