import React from 'react';

import classnames from 'classnames';

import { Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { TypePicker } from 'common/components';
import ChatImageFile from './ChatImageFile';

import { saveFile } from 'utils/saveFile';

import failImageSrc from 'assets/chat-n-video/file-broken-3.png';

export const imageTypes = ['.jpg', '.png'];

const ChatFile = ({ chatFileInfo, isSender }) => {
  const { images, files } = chatFileInfo;

  const handleDownloadFile = (fileUrl) => {
    if (fileUrl) {
      saveFile(fileUrl);
    }
  };

  const ellipsisFileName = (fileName, fileType) => {
    if (!fileName) return fileName;

    if (fileName.length > 54) {
      return fileName.substring(0, 50) + '... ' + fileType;
    }

    return fileName;
  };

  return (
    <div>
      {images?.length > 0 && (
        <div
          className={classnames({
            'chat-thread__image': true,
            'chat-thread__image--sender': !!isSender,
            'chat-thread__image--receiver': !isSender,
          })}
        >
          {images?.map((image) => {
            return (
              <ChatImageFile
                key={image.id}
                image={image}
                handleDownloadFile={handleDownloadFile}
                fallback={failImageSrc}
              />
            );
          })}
        </div>
      )}
      <div
        className={classnames({
          'chat-thread__file-container': true,
          'chat-thread__file-container--sender': !!isSender,
          'chat-thread__file-container--receiver': !isSender,
        })}
      >
        {files?.map((file) => (
          <div className='chat-thread__file-item' key={file?.id}>
            <Tooltip title={ellipsisFileName(file?.fileName, file?.fileType)}>
              <Button type='text' className='chat-thread__file-icon'>
                <TypePicker
                  group='file-type'
                  type={file?.fileType.substring(1, file.fileType.length)}
                />
                <div className='chat-thread__file-mask'>
                  <DownloadOutlined
                    onClick={() => handleDownloadFile(file.fileUrl)}
                  />
                </div>
              </Button>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ChatFile;
