import React from 'react';

import { useNode, useEditor } from '@craftjs/core';

import EmailEditorSection from './EmailEditorSection';
import EmailEditorContentElement from './EmailEditorContentElement';
import EmailEditorTag from './EmailEditorTag';
import EmailEditorInlineText from './EmailEditorInlineText';
import {
  EmailEditorSectionSetting,
  EmailEditorShippingInfoSettings,
} from '../settings';

const EmailEditorShippingTo = (props) => {
  const { id } = useNode();
  const { actions } = useEditor();
  const { text } = props;

  const onChangeContent = (value) => {
    actions.history.throttle().setProp(id, (props) => (props.text = value));
  };

  const shippingName = <EmailEditorTag tag='shippingName' />;
  const shippingCompany = <EmailEditorTag tag='shippingCompany' />;
  const shippingAddress1 = <EmailEditorTag tag='shippingAddress1' />;
  const shippingAddress2 = <EmailEditorTag tag='shippingAddress2' />;

  return (
    <EmailEditorSectionSetting settings={<EmailEditorShippingInfoSettings />}>
      <EmailEditorSection
        tags={[
          'shippingName',
          'shippingCompany',
          'shippingAddress1',
          'shippingAddress2',
        ]}
      >
        <EmailEditorContentElement
          content={
            <EmailEditorInlineText
              content={text}
              onChangeContent={onChangeContent}
            />
          }
        />
        <EmailEditorContentElement content={shippingName} />
        <EmailEditorContentElement content={shippingCompany} />
        <EmailEditorContentElement content={shippingAddress1} />
        <EmailEditorContentElement content={shippingAddress2} />
      </EmailEditorSection>
    </EmailEditorSectionSetting>
  );
};

EmailEditorShippingTo.craft = {
  displayName: 'Shipping Information',
  props: {
    text: 'Ship samples to:',
    settings: {
      bold: false,
      italic: false,
      underLine: false,
      fontSize: 14,
      show: [
        'shippingName',
        'shippingCompany',
        'shippingAddress1',
        'shippingAddress2',
      ],
      color: '#333',
    },
  },
  custom: {
    tags: [
      'shippingName',
      'shippingCompany',
      'shippingAddress1',
      'shippingAddress2',
    ],
  },
};

export default EmailEditorShippingTo;
