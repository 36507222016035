import React from 'react';
import Messages from 'i18n/messages/home';
import { useSelector } from 'react-redux';

import { ShoppingOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

import { forwardTo } from 'utils/common/route';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const OpenAPL = () => {
  const selectedAplDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const aplId =
    selectedAplDetail && selectedAplDetail.length > 0
      ? selectedAplDetail[0].id
      : undefined;

  const handleOpenApl = () => {
    forwardTo(`/products/apl/${aplId}`);
  };
  return (
    <RibbonButton
      icon={<ShoppingOutlined style={{ height: 26 }} />}
      label={Messages.openAPL}
      onClick={handleOpenApl}
      disabled={aplId === undefined || selectedAplDetail.length !== 1}
    />
  );
};

OpenAPL.propTypes = {};

export default OpenAPL;
