import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMemberMaintenance = (state) =>
  state.memberMaintenance || initialState;

const selectLoading = () =>
  createSelector(selectMemberMaintenance, (state) => state.loading);

const selectColumns = () =>
  createSelector(selectMemberMaintenance, (state) => state.columns);

const selectPaging = () =>
  createSelector(selectMemberMaintenance, (state) => state.paging);

const selectMaintenanceDataList = () =>
  createSelector(selectMemberMaintenance, (state) => state.memberMaintenance);

const selectIsMemberGridInfoView = () =>
  createSelector(
    selectMemberMaintenance,
    (state) => state.isMemberGridInfoView
  );

const selectCommunicationTemplates = () =>
  createSelector(
    selectMemberMaintenance,
    (state) => state.communicationTemplates
  );

const selectMemberMaintenanceDataDetail = () =>
  createSelector(selectMemberMaintenance, (state) => state.memberData);

const selectLoadingDetail = () =>
  createSelector(selectMemberMaintenance, (state) => state.loadingDetail);

const selectPublicContactList = () =>
  createSelector(selectMemberMaintenance, (state) => state.publicContactList);

const selectExportLoading = () =>
  createSelector(selectMemberMaintenance, (state) => state.exportLoading);

const makeSelectCurrentTemplate = () =>
  createSelector(selectMemberMaintenance, (state) => state.currentTemplate);

const selectToggleAddNewUser = () =>
  createSelector(selectMemberMaintenance, (state) => state.isAddNewUser);

const selectToggleEditUser = () =>
  createSelector(selectMemberMaintenance, (state) => state.isEditUser);

const makeSelectTemplateValidation = () =>
  createSelector(selectMemberMaintenance, (state) => state.templateValidation);

export {
  selectLoading,
  selectColumns,
  selectPaging,
  selectMaintenanceDataList,
  selectIsMemberGridInfoView,
  selectCommunicationTemplates,
  selectMemberMaintenanceDataDetail,
  selectLoadingDetail,
  selectPublicContactList,
  selectExportLoading,
  makeSelectCurrentTemplate,
  selectToggleAddNewUser,
  selectToggleEditUser,
  makeSelectTemplateValidation,
};
