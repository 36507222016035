import { defineMessages } from 'react-intl';

export const scope = 'Taco.logo-assets';

export default defineMessages({
  okBtn: {
    id: `${scope}.modal.okBtn`,
    defaultMessage: 'Select',
  },
  errorMessage: {
    id: `${scope}.list.errorMessage`,
    defaultMessage: 'Cannot get logo assets.',
  },
  emptyMessage: {
    id: `${scope}.list.emptyMessage`,
    defaultMessage: 'Cannot get logo assets.',
  },
  totalItems: {
    id: `${scope}.list.totalItems`,
    defaultMessage: 'items found',
  },
});
