import React from 'react';

import { Space } from 'antd';

import EmailEditorFontSetting from './EmailEditorFontSetting';

const EmailEditorProductRequestSettings = () => {
  return (
    <Space direction='horizontal' size={0}>
      <EmailEditorFontSetting />
    </Space>
  );
};

export default EmailEditorProductRequestSettings;
