import React from 'react';

import { Typography, Input } from 'antd';
import { RoleSectionWrapper, RoleEmptySection } from '../';

const { Text } = Typography;
const { TextArea } = Input;

const RoleDescription = (props) => {
  const { title, selectedRole, formRole, isEdit } = props;

  const roleDescription = isEdit
    ? formRole?.values?.description
    : selectedRole?.description;

  return (
    <RoleSectionWrapper
      className='role-desc-section__wrap'
      title={title}
      style={{ height: '100%' }}
    >
      {selectedRole ? (
        <div className='role-desc-section scroller' style={{ height: '100%' }}>
          {!isEdit ? (
            roleDescription ? (
              <Text>{roleDescription}</Text>
            ) : (
              <RoleEmptySection />
            )
          ) : (
            <>
              <TextArea
                name='description'
                value={roleDescription || ''}
                onChange={formRole?.onChange}
                style={{ height: '100%', background: '#E9E9E0' }}
                placeholder='input role description'
              />
            </>
          )}
        </div>
      ) : (
        <RoleEmptySection />
      )}
    </RoleSectionWrapper>
  );
};

export default RoleDescription;
