import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';

import { Form } from 'antd';
import _ from 'lodash';
import { produce } from 'immer';

import {
  StyledModal,
  WithLoading,
  CustomNotification,
} from 'common/components';
import AddProductHierarchy from './AddProductHierarchy';
import HierarchyLevelList from './HierarchyLevelList';

import * as productSelectors from 'pages/product-full-view/controllers/selectors';
import * as productActions from 'pages/product-full-view/controllers/actions';

import * as utils from 'utils/productHierarchy';
import { useGetProductFullView, useProductHierarchies } from 'hooks';

import * as productServices from 'services/product';

import {
  HIERARCHY_LEVEL,
  INIT_ADDING_HIERARCHY_LEVELS,
} from 'static/Constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

import './AddHierarchyModal.less';

const AddHierarchyModal = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const hierarchyProductListRef = useRef([]);
  const [QttForm] = Form.useForm();

  const { id: productId } = useParams();

  const { visible, setVisible, selectedHierarchy, isEdit, isCopy, isAdd } =
    props;

  const [modalLoading, setModalLoading] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [hierarchyLevelList, setHierarchyLevelList] = useState(
    _.cloneDeep(INIT_ADDING_HIERARCHY_LEVELS)
  );

  const { productFull: currentProduct } = useGetProductFullView({ productId });
  const currentHierarchyShortDetails = useSelector(
    productSelectors.makeSelectCurrentHierarchyShortDetails()
  );

  // const [hierarchyProductList, setHierarchyProductList] = useState([]);
  //* refactor hierarchyProductList from state to ref
  const hierarchyProductList = hierarchyProductListRef.current;
  const setHierarchyProductList = (newState) => {
    const data =
      typeof newState === 'function'
        ? newState(hierarchyProductList)
        : newState;

    hierarchyProductListRef.current = data;
  };

  const { saveHierarchiesMutation } = useProductHierarchies({
    productId: currentProduct?.productId,
  });

  const onCloseModal = () => {
    setVisible(false);
  };

  const onOkHandler = () => {
    if (selectedLevel) {
      selectProductForLevel();
      return;
    }

    callApi();
  };

  const selectProductForLevel = () => {
    if (!selectedLevel) return;
    appendSelectedProductToProductList();

    setHierarchyLevelList((prevState) => {
      const cloneState = _.cloneDeep(prevState);
      //* set product to level

      _.set(
        cloneState,
        `[${selectedLevel.index}].productItemId`,
        selectedProduct?.id
      );

      utils.setParentProductForThisLevel({
        levelList: cloneState,
        level: selectedLevel,
        addedProductId: selectedProduct?.id,
      });

      utils.setParentProductForChildrenLevel({
        levelList: cloneState,
        productList: hierarchyProductList,
        level: selectedLevel,
        addedProductId: selectedProduct?.id,
      });

      updateQuantityForm({ levelList: cloneState });

      return cloneState;
    });

    goBackToSelectLevelView();
  };

  const appendSelectedProductToProductList = () => {
    if (!selectedProduct) return;
    setHierarchyProductList((prevState) => {
      const productItem = _.cloneDeep(selectedProduct);

      _.set(productItem, 'productItemId', productItem.id);

      const newState = [...prevState, productItem];

      return newState;
    });
  };

  const updateQuantityForm = ({ levelList }) => {
    const hightestLevel = utils.getHighestLevel(levelList);
    const isHighestLevel = selectedLevel.packageLevel === hightestLevel;

    QttForm.setFieldsValue({
      [selectedLevel.index]: isHighestLevel ? 1 : null,
    });
  };

  const getApiParams = async () => {
    try {
      const quantities = await QttForm.validateFields();

      const clonedHierarchyLevelList = _.cloneDeep(hierarchyLevelList);
      const levelHasProductList = clonedHierarchyLevelList.filter((level) => {
        return level.productItemId;
      });

      const itemsParam = levelHasProductList.map((level) => {
        const { productItemId, parentProductItemId, packageLevel } = level;
        return {
          productItemId,
          parentProductItemId,
          packageLevel,
          quantity: quantities[level.index],
        };
      });

      return {
        hierarchyId: isEdit ? selectedHierarchy?.productHierarchyId : undefined,
        ownerId: currentProduct?.ownerId,
        items: itemsParam,
      };
    } catch (error) {
      console.log('error: ', error);
      return;
    }
  };

  const getApiMessages = () => {
    return {
      success: intl.formatMessage(
        Messages[
          isAdd
            ? 'addHierarchySuccess'
            : isEdit
            ? 'editHierarchySuccess'
            : 'copyHierarchySuccess'
        ]
      ),
      error: intl.formatMessage(
        Messages[
          isAdd
            ? 'addHierarchyError'
            : isEdit
            ? 'editHierarchyError'
            : 'copyHierarchyError'
        ]
      ),
    };
  };

  const callApi = async () => {
    const params = await getApiParams();
    const messages = getApiMessages();

    saveHierarchiesMutation.mutate(params, {
      onSuccess: (response) => {
        if (!response.isSuccess) {
          CustomNotification.error(response.message || messages.error);
          return;
        }

        setVisible(false);

        CustomNotification.success(messages.success);

        dispatch(productActions.updateSelectedHierarchy(null));
        dispatch(productActions.updateHierarchyEndpoint(null));

        //* reload hierarchies in drawer
        dispatch(
          productActions.getProductHierarchyShortDetails({
            hierarchyId: currentHierarchyShortDetails?.data?.hierarchyId,
          })
        );
        //* reload hierarchy dropdown in product header
        dispatch(
          productActions.getProductHierarchyShortDetail(
            currentProduct?.productId
          )
        );
      },
      onError: () => {
        CustomNotification.error(messages.error);
      },
    });
  };

  const goBackToSelectLevelView = () => {
    setSelectedLevel(null);
    setSelectedProduct(null);
  };

  const clearState = () => {
    setHierarchyProductList([]);
    setHierarchyLevelList(_.cloneDeep(INIT_ADDING_HIERARCHY_LEVELS));
    setSelectedLevel(null);
    setSelectedProduct(null);
    QttForm.resetFields();
  };

  const updateHierarchyLevelListWithEqualLevel = (levelKey, levelPackage) => {
    setHierarchyLevelList((prevState) => {
      const newState = produce(prevState, (draft) => {
        const equalWithIndex = draft.findIndex((levelItem) =>
          levelItem?.equalWith?.includes(levelKey)
        );

        draft[equalWithIndex].key = levelKey;
        draft[equalWithIndex].packageLevel = levelPackage;
      });

      return newState;
    });
  };

  const setCurrentProductToHierarchyLevelList = (level) => {
    setHierarchyLevelList((prevState) => {
      const newState = produce(prevState, (draft) => {
        const index = draft.findIndex((levelItem) => levelItem.key === level);

        draft[index].productItemId = currentProduct?.productId;

        //* set parentProductId for first lower level
        if (index < 4)
          draft[index + 1].parentProductItemId = currentProduct?.productId;
      });

      return newState;
    });
  };

  const addCurrentProductToHierarchyProductList = () => {
    const productItem = {
      productItemId: currentProduct.productId,
      productName: currentProduct.productName,
      thumbnail: currentProduct.productPrimaryImage,
      packageLevel: currentProduct.packageLevel,
    };

    setHierarchyProductList((prevState) => {
      prevState.push(productItem);

      return prevState;
    });
  };

  const initCurrentProduct = () => {
    if (!visible) return;
    if (isEdit || isCopy) return;

    if (!currentProduct) return;

    const currentProductLevel = currentProduct.packageLevel;
    const level = Object.values(HIERARCHY_LEVEL).find(
      (LEVEL) => LEVEL.NAME === currentProductLevel
    )?.KEY;

    const levelIndex = hierarchyLevelList.findIndex(
      (levelItem) => levelItem.key === level
    );

    if (levelIndex === -1) {
      //* levelIndex === -1 when level is not exist in INIT_ADDING_HIERARCHY_LEVELS (such as Display Shipper, ...)
      //* replace equal level with this level
      updateHierarchyLevelListWithEqualLevel(level, currentProductLevel);
    }

    addCurrentProductToHierarchyProductList();
    setCurrentProductToHierarchyLevelList(level);
  };

  const initHierarchyData = async () => {
    if (isAdd) return;
    if (!selectedHierarchy) return;
    if (!visible) return;

    setModalLoading(true);
    const params = {
      hierarchyId: selectedHierarchy.productHierarchyId,
    };

    const { isSuccess, data } =
      await productServices.getProductHierarchyDetailsService(params);

    let productList;

    if (isSuccess && data?.productItems?.length)
      productList = data?.productItems.map((productItem) => {
        const { productId, ...rest } = productItem;
        return {
          productItemId: productId,
          ...rest,
        };
      });
    else productList = selectedHierarchy.productItemLinks;

    //* INIT HIERARCHY DATA FROM PRODUCT LIST
    //* offsetLevels is missing levels on top
    const offsetLevels = utils.getOffsetLevels(productList);

    //* map product list to hierarchy level
    const initLevelList = utils.getInitLevelList(productList);

    //* sort initLevelList to the right order
    const sortedLevelList = utils.sortLevelList(initLevelList);

    //* add missing hierarchy to middle and bottom of initLevelList
    const filledLevelList = utils.fillLevelList(sortedLevelList);

    //* concat with offset levels to get completed list
    const completeInitLevelList = [...offsetLevels, ...filledLevelList];

    const reIndexInitLevelList = utils.reIndexLevelList(completeInitLevelList);

    initHierarchyProductList(productList);
    initHierarchyLevelList({ levelList: reIndexInitLevelList });
    initQuantityForm({ levelList: reIndexInitLevelList });

    setModalLoading(false);
  };

  const initHierarchyLevelList = ({ levelList }) => {
    setHierarchyLevelList(levelList);
  };

  const initHierarchyProductList = (productList) => {
    if (!productList) return;

    const initProductList = utils.getInitialProductList({
      productList,
      currentProduct,
    });

    setHierarchyProductList(initProductList);
  };

  const initQuantityForm = ({ levelList }) => {
    const initialQuantityForm = utils.getInitialQuantity({ levelList });

    QttForm.setFieldsValue(initialQuantityForm);
  };

  const checkHighestLevelProduct = () => {
    const highestLevelProduct = utils.getHighestLevelProduct({
      levelList: hierarchyLevelList,
    });

    const currentHighestLevelProduct =
      utils.getSelectedHierarchyHighestLevelProduct({
        levelList: hierarchyLevelList,
        selectedHierarchy,
      });

    return highestLevelProduct === currentHighestLevelProduct;
  };

  const updateSubmitBtnDisabled = () => {
    if (!isCopy) return false;
    if (!selectedHierarchy) return false;

    if (selectedLevel) return false;

    let disabled = checkHighestLevelProduct();

    return disabled;
  };

  //* mount update
  useEffect(() => {
    clearState();
    initHierarchyData();
    initCurrentProduct();
    //eslint-disable-next-line
  }, [visible]);

  const submitDisabled = updateSubmitBtnDisabled();

  //* render
  const modalTitle = intl.formatMessage(
    Messages[
      isEdit
        ? 'editHierarchyTitle'
        : isCopy
        ? 'copyHierarchyTitle'
        : 'addHierarchyTitle'
    ]
  );
  const okText = intl.formatMessage(
    Messages[
      selectedLevel
        ? 'selectProductHierarchy'
        : isEdit || isCopy
        ? 'editHierarchy'
        : 'addHierarchy'
    ]
  );

  const modalProps = {
    title: modalTitle,
    visible,
    onCancel: onCloseModal,
    wrapClassName: 'add-hierarchy__modal',
    bodyStyle: {
      height: selectedLevel ? 405 : 300,
      position: 'relative',
    },
    width: 700,
    destroyOnClose: true,
    closeable: false,
    okText,
    onOk: onOkHandler,
    okButtonProps: {
      loading: saveHierarchiesMutation?.isLoading,
      disabled: submitDisabled,
    },
  };

  const addProductHierarchyProps = {
    closeModal: onCloseModal,
    goBackToSelectLevelView,
    selectedLevel,
    selectedProduct,
    setSelectedProduct,
    productId,
  };

  const hierarchyLevelListProps = {
    isCopy,
    form: QttForm,
    currentProduct,
    hierarchyLevelList,
    hierarchyProductList,
    selectedHierarchy,
    closeModal: onCloseModal,
    goBackToSelectLevelView,
    setHierarchyLevelList,
    setHierarchyProductList,
    setSelectedLevel,
    checkHighestLevelProduct,
    initQuantityForm,
  };

  return (
    <StyledModal {...modalProps}>
      {modalLoading ? (
        <WithLoading />
      ) : selectedLevel ? (
        <AddProductHierarchy {...addProductHierarchyProps} />
      ) : (
        <HierarchyLevelList {...hierarchyLevelListProps} />
      )}
    </StyledModal>
  );
};

export default AddHierarchyModal;
