import React, { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'antd';
import { DoubleRightOutlined, CloseOutlined } from '@ant-design/icons';
import { MoveUserToMemberModal } from 'common/components';
import ThumbnailItem from 'common/components/thumb/ThumbnailItem';
import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import selectorUser from 'redux/user/selectors';

import { Images } from 'config/assets';

import * as memberServices from 'services/members';
import { checkIsSuperAdmin } from 'utils';
import * as getLink from 'utils/common/linkBuilder';

const MoveUserToMember = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);
  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const refSearchGridItemModal = useRef();

  const openModalMoveAssetToMember = () => {
    refSearchGridItemModal.current.openModal();
  };

  const defaultProductParams = {
    searchText: '',
  };

  const firstSelectedUser = selectedItemDetailList?.[0];

  const isAllowToMoveUsersToMember = useMemo(() => {
    return (
      (userInfo?.isSuperMember || isSuperAdmin) &&
      selectedItemDetailList?.length === 1
    );
  }, [selectedItemDetailList, userInfo, isSuperAdmin]);

  return (
    <>
      <RibbonButton
        icon={<DoubleRightOutlined style={{ height: 26 }} />}
        label='Move User to Member'
        onClick={openModalMoveAssetToMember}
        disabled={!isAllowToMoveUsersToMember}
      />

      <MoveUserToMemberModal
        ref={refSearchGridItemModal}
        title='Move user to Member'
        service={memberServices.getAllMemberForReassign}
        dataPath='allMembers'
        params={defaultProductParams}
        mapSearch={(searchText) => ({ searchText })}
        tagRender={MemberTagRender}
        itemIdName='companyId'
        selectedUserInfo={firstSelectedUser}
        isMultiple={false}
      />
    </>
  );
};

const MemberTagRender = ({ itemData, onClose }) => {
  const item = itemData;

  return (
    <div>
      <Button
        shape='circle'
        danger
        icon={<CloseOutlined />}
        type='primary'
        size='small'
        style={{ position: 'absolute', zIndex: 100, right: 11, top: -7 }}
        onClick={onClose}
      />
      <ThumbnailItem
        dataDetail={item}
        labelThumbnail={getLink.memberLink(
          item?.id || item?.companyId,
          item?.companyName
        )}
        imageThumbnail={
          item?.thumb300 ||
          item?.thumb ||
          item?.logo ||
          Images.RIVIR_LOGO_DEFAULT
        }
        hideCheckbox
        isMemberThumbnail
        isShareBtn={false}
        style={{ margin: 0 }}
      />
    </div>
  );
};

export default MoveUserToMember;
