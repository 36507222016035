import { useState, useEffect, useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import {
  getQueryGridView,
  getQueryDetailPane,
  getAllMemberRoles,
} from 'services/query';
import { getMemberList } from 'services/members';

import { deleteColumnCheckbox } from './utils';

import { useAsync, useCheckPermissions } from 'hooks';

import { PERMISSION_MANAGE_CURATED_QUERY } from 'static/Permission';

const useSetDefaultWidth = (form, columns, selectedFieldName) => {
  const valueColumns = deleteColumnCheckbox(columns);

  useEffect(() => {
    if (valueColumns.length === 0) return;

    const findColumn = valueColumns.find(
      (col) => col.fieldName === selectedFieldName
    );
    const width = findColumn?.width ?? 0;

    form.setFieldsValue({ width });
  }, [form, valueColumns, selectedFieldName]);
};

const useChangeWidth = (form, width) => {
  useEffect(() => {
    form.setFieldsValue({ width });
  }, [form, width]);
};

const useGetSavedConfig = (gridName, isMounted) => {
  const [data, setData] = useState();

  useEffect(() => {
    const params = {
      pageIndex: 1,
      pageSize: 10,
      sort: [
        {
          fieldName: 'lastModified',
          isAscending: false,
        },
      ],
      gridName,
    };
    const delayFetchGrid = setTimeout(() => {
      getQueryGridView(params).then((res) => {
        setData(res.data);
      });
    }, 1000);

    return () => clearTimeout(delayFetchGrid);
  }, [gridName, isMounted]);

  return data;
};

const DEFAULT_PAGE_SIZE = 9999;

const useGetMembers = ({ valuesFilter, enabled }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (!enabled) return;

    const params = {
      type: 'GET_MEMBER_LIST',
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      sort: [],
      filters: [
        {
          fieldName: 'id',
          values: valuesFilter,
        },
      ],
    };

    getMemberList(params).then((resp) => {
      setMembers(resp.gridData);
    });
  }, [enabled, valuesFilter]);

  return members;
};

const useGetCuratedQueryDetail = ({ curatedQueryId, enabled }) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (!enabled) return;

    run(getQueryDetailPane({ id: curatedQueryId }));
  }, [curatedQueryId, enabled, run]);

  const refetchGetCuratedQueryDetail = useCallback(() => {
    run(getQueryDetailPane({ id: curatedQueryId }));
  }, [curatedQueryId, run]);

  return {
    curatedQueryData: data ?? {},
    refetchGetCuratedQueryDetail,
  };
};

const getAllMemberRolesQueryKey = () => {
  return ['get-all-member-role'];
};

const useGetAllMemberRoles = () => {
  const hasPermissionManageCuratedQuery = useCheckPermissions(
    PERMISSION_MANAGE_CURATED_QUERY
  );

  const { data, ...restData } = useQuery({
    queryKey: getAllMemberRolesQueryKey(),
    queryFn: async () => {
      const response = await getAllMemberRoles();

      return response?.data;
    },
    enabled: hasPermissionManageCuratedQuery,
  });

  return {
    memberRoles: data?.memberRoles ?? [],
    ...restData,
  };
};

export {
  useSetDefaultWidth,
  useChangeWidth,
  useGetSavedConfig,
  useGetMembers,
  useGetCuratedQueryDetail,
  useGetAllMemberRoles,
};
