import React, { useState } from 'react';

import { Button } from 'antd';

import UpdateMinimumRequirementProductField from './UpdateMinimumRequirementProductField';
import UpdateMinimumRequirementProductGalleryModal from './UpdateMinimumRequirementProductGalleryModal';
import UpdateMinimumRequirementProductDocumentModal from './UpdateMinimumRequirementProductDocumentModal';

import { useUpdateMemberAdditionalRequirements } from 'hooks/useUpdateMemberAdditionalRequirements';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/home';

const OpenUpdateMinimumRequirementButton = (props) => {
  const intl = useIntl();
  const { openErrorUrl } = useUpdateMemberAdditionalRequirements();

  const { item, entityData, productDetail } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const onClickOpenBtn = () => {
    openModal();
    openEditMemberPage();
  };

  const onCloseModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    UpdateAdditionalRequirementModal && setModalVisible(true);
  };

  const openEditMemberPage = () => {
    openErrorUrl({
      fieldName: item.id,
      entityId: entityData.id,
    });
  };

  const updateModalCollection = {
    product_planoimage: (
      <UpdateMinimumRequirementProductGalleryModal
        visible={modalVisible}
        setVisible={setModalVisible}
        entity={entityData}
        productDetail={productDetail}
        type='Planogram'
      />
    ),
    product_additionalimage: (
      <UpdateMinimumRequirementProductGalleryModal
        visible={modalVisible}
        setVisible={setModalVisible}
        entity={entityData}
        productDetail={productDetail}
        type='Additional'
      />
    ),
    product_marketingimage: (
      <UpdateMinimumRequirementProductGalleryModal
        visible={modalVisible}
        setVisible={setModalVisible}
        entity={entityData}
        productDetail={productDetail}
        type='Marketing'
      />
    ),
    product_anyimage: (
      <UpdateMinimumRequirementProductGalleryModal
        visible={modalVisible}
        setVisible={setModalVisible}
        entity={entityData}
        productDetail={productDetail}
        type='Marketing'
      />
    ),
    product_document: (
      <UpdateMinimumRequirementProductDocumentModal
        productDetail={productDetail}
        visible={modalVisible}
        setVisible={setModalVisible}
        entity={entityData}
      />
    ),
    product_brand: (
      <UpdateMinimumRequirementProductField
        productDetail={productDetail}
        visible={modalVisible}
        setVisible={setModalVisible}
        entityId={entityData?.id}
        item={item}
      />
    ),
    product_upcgtin: (
      <UpdateMinimumRequirementProductField
        productDetail={productDetail}
        visible={modalVisible}
        setVisible={setModalVisible}
        entityId={entityData?.id}
        item={item}
      />
    ),
  };

  const UpdateAdditionalRequirementModal =
    updateModalCollection[item?.id?.toLowerCase()];

  return (
    <>
      <Button type='primary' danger size='small' onClick={onClickOpenBtn}>
        {intl.formatMessage(messages.previewRequirementsOpenButton)}
      </Button>
      {UpdateAdditionalRequirementModal
        ? UpdateAdditionalRequirementModal
        : null}
    </>
  );
};

export default OpenUpdateMinimumRequirementButton;
