import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { Video } from 'common/components';
import AssetPreviewTools from './AssetPreviewTools';

const AssetPreviewVideo = (props) => {
  const { assetData } = props;

  return (
    <>
      <AssetPreviewTools assetData={assetData} isVideo={true} />
      <Row className='dam-preview__img-part'>
        <Video url={assetData?.fileUrl} />
      </Row>
    </>
  );
};

AssetPreviewVideo.propTypes = {
  assetData: PropTypes.object,
};

export default AssetPreviewVideo;
