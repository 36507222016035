import React, { useState, useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  Input,
  Form,
  InputNumber,
  Checkbox,
  DatePicker,
  Row,
  Col,
  Tooltip,
  Button,
  Typography,
  Radio,
} from 'antd';
import { TextTooltip } from 'common/components';

import {
  SearchOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { NUMERIC_TYPE, DATE_FORMAT } from 'static/Constants';

import {
  NUMBER_OPERATORS,
  getValueInput,
  checkDisabledButton,
  getParameterTypes,
} from './utils';

const { RangePicker } = DatePicker;

export const FormItemText = ({ placeholder, statementType, ...otherProps }) => {
  const isRequired = statementType === 'Else' ? false : true;

  return (
    <Form.Item
      {...otherProps}
      rules={[{ required: isRequired, message: 'Value cannot be empty' }]}
    >
      <Input placeholder={placeholder} allowClear />
    </Form.Item>
  );
};

export const FormItemNumber = ({
  property,
  placeholder,
  statementType,
  ...otherProps
}) => {
  const isRequired = statementType === 'Else' ? false : true;
  return (
    <Form.Item
      {...otherProps}
      rules={[{ required: isRequired, message: 'Value cannot be empty' }]}
    >
      <Input
        placeholder={placeholder}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        maxLength={20}
        allowClear
      />
    </Form.Item>
  );
};

export const FormItemBoolean = (props) => {
  return (
    <Form.Item {...props}>
      <Checkbox />
    </Form.Item>
  );
};

export const FormItemDate = ({
  showError,
  compareToValue,
  statementType,
  ...props
}) => {
  const isRequired = statementType === 'Else' ? false : true;

  return (
    <Form.Item
      {...props}
      validateStatus={showError && (compareToValue ? '' : 'error')}
      help={showError && (compareToValue ? '' : 'This field is required!')}
      required={isRequired}
    >
      <DatePicker
        onChange={props?.onChange}
        value={compareToValue ? moment(compareToValue) : ''}
        format={DATE_FORMAT.SYSTEM_FORMAT}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
};

export const FormBetweenDate = ({
  onChange,
  compareToValue,
  compareToOtherValue,
  showError,
  statementType,
  ...otherProps
}) => {
  const isRequired = statementType === 'Else' ? false : true;
  const dateStart = compareToValue ? moment(compareToValue) : '';
  const dateEnd = compareToOtherValue ? moment(compareToOtherValue) : '';

  return (
    <Form.Item
      {...otherProps}
      validateStatus={showError && (compareToValue ? '' : 'error')}
      help={showError && (compareToValue ? '' : 'This field is required!')}
      required={isRequired}
    >
      <RangePicker
        value={[dateStart, dateEnd]}
        onChange={onChange}
        format={DATE_FORMAT.SYSTEM_FORMAT}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
};

const defaultLayoutItem = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const CompareToValueComponent = ({
  dataType,
  condition,
  onChangeRangeDate,
  handleChangeCompareValue,
  handleChangeCompareOtherValue,
  compareToValue,
  compareToOtherValue,
  handleChangeDate,
  showError,
  statementType,
}) => {
  if (!dataType || !condition) return null;

  if (condition === 'IsNotNull' || condition === 'IsNull') return null;

  if (NUMERIC_TYPE.includes(dataType)) {
    if (condition === 'Between') {
      return (
        <Row>
          <Col span={12}>
            <FormItemNumber
              label='Value'
              name='compareToValue'
              onChange={(e) => handleChangeCompareValue(e?.target?.value)}
              {...defaultLayoutItem}
              statementType={statementType}
            />
          </Col>
          <Col span={12}>
            <FormItemNumber
              label='Other Value'
              name='compareToOtherValue'
              onChange={(e) => handleChangeCompareOtherValue(e?.target?.value)}
              {...defaultLayoutItem}
              statementType={statementType}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col span={24}>
            <FormItemNumber
              label='Value'
              name='compareToValue'
              onChange={(e) => handleChangeCompareValue(e?.target?.value)}
              statementType={statementType}
            />
          </Col>
        </Row>
      );
    }
  } else if (dataType === 'string') {
    return (
      <Row>
        <Col span={24}>
          <FormItemText
            label='Value'
            name='compareToValue'
            onChange={(e) => handleChangeCompareValue(e?.target?.value)}
            statementType={statementType}
          />
        </Col>
      </Row>
    );
  } else if (dataType === 'datetime') {
    if (condition === 'Between') {
      return (
        <Row>
          <Col span={24}>
            <FormBetweenDate
              label='Value'
              // name='compareToValue'
              showError={showError}
              onChange={onChangeRangeDate}
              compareToValue={compareToValue}
              compareToOtherValue={compareToOtherValue}
              statementType={statementType}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col span={24}>
            <FormItemDate
              label='Value'
              // name='compareToValue'
              onChange={handleChangeDate}
              compareToValue={compareToValue}
              showError={showError}
              statementType={statementType}
            />
          </Col>
        </Row>
      );
    }
  } else if (dataType === 'boolean') {
    return (
      <Row>
        <Col span={24}>
          <FormItemBoolean
            label='Value'
            name='compareToValue'
            valuePropName='checked'
            onChange={(e) => handleChangeCompareValue(e?.target.checked)}
          />
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export const SelectSourceField = (props) => {
  const { onClick, valueInput, styleColButton } = props;

  return (
    <Row justify='space-between'>
      <Col span={22}>
        <Tooltip
          title={valueInput}
          placement='topLeft'
          overlayStyle={{ maxWidth: '500px' }}
        >
          <div>
            <Input
              style={{ width: '100%' }}
              disabled
              className='mapping-config__input-display'
              value={valueInput}
            />
          </div>
        </Tooltip>
      </Col>
      <Col span={2} style={{ textAlign: 'right', ...styleColButton }}>
        <Tooltip title='Search field'>
          <Button
            type='primary'
            icon={<SearchOutlined />}
            style={{ marginLeft: 6, borderRadius: 4 }}
            onClick={onClick}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

const getValue = (packageLevel, field) => {
  if (packageLevel && field) {
    return `[${packageLevel}].${field}`;
  }
  return '';
};

export const CompareToOtherFieldComponent = (props) => {
  const {
    condition,
    dataType,
    handleShowToSourceField,
    compareToFieldPath,
    compareToOtherFieldPath,
    compareToFieldPackageLevel,
    compareToOtherFieldPackageLevel,
    handleFilterProperties,
    statementType,
  } = props;

  const isRequired = statementType === 'Else' ? false : true;

  const handleShowSourceFieldWithFilter = (sourceFieldName, dataType) => {
    if (dataType === 'string') {
      handleFilterProperties('string');
    }
    if (dataType === 'boolean') {
      handleFilterProperties('boolean');
    }
    if (dataType === 'datetime') {
      handleFilterProperties('datetime');
    }
    if (
      dataType === 'int' ||
      dataType === 'double' ||
      dataType === 'long' ||
      dataType === 'decimal'
    ) {
      handleFilterProperties('number');
    }

    handleShowToSourceField(sourceFieldName);
  };

  if (!dataType || !condition) return null;

  if (condition === 'IsNotNull' || condition === 'IsNull') return null;

  if (condition === 'Between') {
    return (
      <Row>
        <Col span={24}>
          <Form.Item
            label='Field'
            name='compareToFieldPath'
            rules={[
              {
                required: isRequired,
                message: 'Field cannot be empty!',
              },
            ]}
          >
            <SelectSourceField
              onClick={() =>
                handleShowSourceFieldWithFilter('compareToFieldPath', dataType)
              }
              valueInput={getValue(
                compareToFieldPackageLevel,
                compareToFieldPath
              )}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label='Other Field'
            name='compareToOtherFieldPath'
            rules={[
              {
                required: isRequired,
                message: 'Field cannot be empty!',
              },
            ]}
          >
            <SelectSourceField
              onClick={() =>
                handleShowSourceFieldWithFilter(
                  'compareToOtherFieldPath',
                  dataType
                )
              }
              valueInput={getValue(
                compareToOtherFieldPackageLevel,
                compareToOtherFieldPath
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col span={24}>
          <Form.Item
            label='Field'
            name='compareToFieldPath'
            rules={[
              {
                required: isRequired,
                message: 'Field cannot be empty!',
              },
            ]}
          >
            <SelectSourceField
              onClick={() =>
                handleShowSourceFieldWithFilter('compareToFieldPath', dataType)
              }
              valueInput={getValue(
                compareToFieldPackageLevel,
                compareToFieldPath
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  }
};

export const SetParameters = ({
  setType,
  setParameters,
  handleAddValueParameter,
  handleAddOtherFieldParameter,
  handleChangeTypeValueParameter,
  handleDeleteParameter,
  handleShowToSourceField,
  handleChangePropertiesOfTypeValue,
  handleFilterProperties,
}) => {
  const [typeValue, setTypeValue] = useState('Value');

  const isNumberOperator = useMemo(() => NUMBER_OPERATORS.includes(setType), [
    setType,
  ]);

  const handleChangeTypeValue = (type, index) => {
    handleChangeTypeValueParameter(type, index);
    setTypeValue(type);
  };

  const handleAddParameter = () => {
    let param = {};

    if (setType === 'OtherField') {
      param = {
        ...param,
        type: 'OtherField',
        id: uuidv4(),
        setByFieldPath: '',
        setByFieldPackageLevel: 'Unit',
      };
      handleAddOtherFieldParameter(param);
    } else {
      if (typeValue === 'Value') {
        param = {
          ...param,
          id: uuidv4(),
          type: 'Value',
          setByValue: null,
        };
        handleAddValueParameter(param);
      } else {
        param = {
          ...param,
          id: uuidv4(),
          type: 'Value',
          setByFieldPath: '',
          setByFieldPackageLevel: 'Unit',
        };
        handleAddOtherFieldParameter(param);
      }
    }
  };

  return (
    <Row>
      <Col span={4} style={{ textAlign: 'right', paddingRight: 10 }}>
        <Typography.Text
          style={{
            fontSize: 13,
            color: 'rgba(0, 0, 0, 0.85)',
          }}
        >
          Values:
        </Typography.Text>
      </Col>
      <Col span={16} className='scroller' style={{ maxHeight: '410px' }}>
        <ParameterItem
          isNumberOperator={isNumberOperator}
          handleChangeTypeValue={handleChangeTypeValue}
          setParameters={setParameters}
          handleDeleteParameter={handleDeleteParameter}
          handleShowToSourceField={handleShowToSourceField}
          handleChangePropertiesOfTypeValue={handleChangePropertiesOfTypeValue}
          setType={setType}
          handleFilterProperties={handleFilterProperties}
        />
      </Col>
      <Col span={4} style={{ textAlign: 'right' }}>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          style={{ borderRadius: 4 }}
          disabled={checkDisabledButton(setParameters, setType)}
          onClick={handleAddParameter}
        />
      </Col>
    </Row>
  );
};

const ParameterItem = (props) => {
  const {
    isNumberOperator,
    handleChangeTypeValue,
    setParameters,
    handleDeleteParameter,
    handleShowToSourceField,
    handleChangePropertiesOfTypeValue,
    setType,
    handleFilterProperties,
  } = props;

  const handleShowSourceFieldWithFilter = (sourceFieldIndex) => {
    if (isNumberOperator) {
      handleFilterProperties('number');
    } else {
      handleFilterProperties('all');
    }

    handleShowToSourceField(sourceFieldIndex);
  };

  const renderElement = (parameter, index) => {
    if (isNumberOperator) {
      if (parameter.type === 'Value') {
        return (
          <Col span={22}>
            <InputNumber
              min={1}
              style={{ width: '100%' }}
              onChange={(value) =>
                handleChangePropertiesOfTypeValue(value, index)
              }
              value={parameter?.setByValue}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Col>
        );
      } else {
        return (
          <Col span={24}>
            <SelectSourceField
              onClick={() => handleShowSourceFieldWithFilter(index)}
              valueInput={getValueInput(parameter)}
              styleColButton={{ paddingLeft: 4, textAlign: 'left' }}
            />
          </Col>
        );
      }
    }

    if (setType === 'StringConcate') {
      if (parameter.type === 'Value') {
        return (
          <Col span={22}>
            <Input
              min={1}
              style={{ width: '100%' }}
              onChange={(event) =>
                handleChangePropertiesOfTypeValue(event.target.value, index)
              }
              value={parameter?.setByValue}
            />
          </Col>
        );
      } else {
        return (
          <Col span={24}>
            <SelectSourceField
              onClick={() => handleShowSourceFieldWithFilter(index)}
              valueInput={getValueInput(parameter)}
              styleColButton={{ paddingLeft: 4, textAlign: 'left' }}
            />
          </Col>
        );
      }
    }

    if (setType === 'OtherField') {
      return (
        <Col span={24}>
          <SelectSourceField
            onClick={() => handleShowSourceFieldWithFilter(index)}
            valueInput={getValueInput(parameter)}
            styleColButton={{ paddingLeft: 4, textAlign: 'left' }}
          />
        </Col>
      );
    }
  };

  return (
    <>
      {setParameters.map((parameter, index) => {
        return (
          <Row key={parameter?.id || index} style={{ margin: '0 20px 16px 0' }}>
            <Col span={24}>
              <Row align='middle'>
                <Col span={22}>
                  <Radio.Group
                    onChange={(event) =>
                      handleChangeTypeValue(event.target.value, index)
                    }
                    value={parameter.type}
                    style={{ width: '100%', marginBottom: 10 }}
                  >
                    {getParameterTypes(setType).map((type) => (
                      <Radio key={type.value} value={type.value}>
                        {type.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Col>
                <Col span={2} style={{ paddingLeft: 10 }}>
                  <CloseCircleOutlined
                    style={{
                      fontSize: 18,
                      color: '#ff4d4f',
                      paddingLeft: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleDeleteParameter(index)}
                  />
                </Col>
              </Row>
            </Col>

            {renderElement(parameter, index)}
          </Row>
        );
      })}
    </>
  );
};
