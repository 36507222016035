import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'common/components/slickslider/SlickSlider';
import Image from 'common/components/image/Image';
export const ProductAssets = (props) => {
  const { dataDetail } = props;
  return (
    <SlickSlider>
      {dataDetail &&
        dataDetail.assetImage &&
        dataDetail.assetImage.length > 0 &&
        dataDetail.assetImage.map((item, index) => {
          return (
            <div className="slick-children-element" key={index}>
              <Image src={item.url} alt={item.alt || 'image'} />
            </div>
          );
        })}
    </SlickSlider>
  );
};
ProductAssets.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};
export default ProductAssets;
