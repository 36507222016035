import { useRef, useEffect } from 'react';

import {
  useClickOuterMultiple,
  useClickOuterMultipleType,
} from 'hooks/useClickOuterMultiple';

import { useClickOuterMultipleDrag } from '../index';

import PropTypes from 'prop-types';

const clickOuterConfigDefine = [] || [
  PropTypes.element,
  useClickOuterMultipleType.clearSelect,
  PropTypes.string, //? element id,
];

const refPosDefault = {
  initX: undefined,
  initY: undefined,
};

const eventName = 'triggerDrapDropPostItem';

const useDrapDropPosHooks = (clickOuterConfig = clickOuterConfigDefine) => {
  const refDrapDropPos = useRef();

  const refDragTag = useRef();

  const refPos = useRef(refPosDefault);

  useClickOuterMultipleDrag(...clickOuterConfig);

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();

    refPos.current.initX = e.clientX;
    refPos.current.initY = e.clientY;
  }

  const dragItemStyle = (isSelected) => ({
    zIndex: isSelected ? 100 : 'unset',
  });

  const createDragEvent = (detail) => {
    const event = new CustomEvent(eventName, {
      detail,
    });
    document.dispatchEvent(event);
  };

  useEffect(() => {
    refDrapDropPos.current = {
      attach: (func) => {
        createDragEvent({ initPos: refPos, target: refDragTag, func });
      },
      deAttach: () => {
        createDragEvent(null);
      },
    };
  }, []);

  useEffect(() => {
    if (refDragTag?.current) {
      refDragTag.current.onmousedown = dragMouseDown;
    }
  }, []);

  return [refDrapDropPos, dragItemStyle, refDragTag];
};

export default useDrapDropPosHooks;
