import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCheckMemberSupplier } from 'hooks';
import { useCheckSharedOnlyMyCompany } from 'hooks/useCheckPrivateMember';
import { useGetQaSpecDataHeader } from 'pages/qa-spec/query';
import { useGetMemberInfo } from 'hooks/useGetMemberInfo';

import userSelector from 'redux/user/selectors';

const useCheckAssignProductToSupplier = () => {
  const { id: productId } = useParams();

  const isMemberSupplierUser = useCheckMemberSupplier();
  const isSharedOnlyMember = useCheckSharedOnlyMyCompany();

  let userInfo = useSelector(userSelector.makeSelectUserInfo());

  const { memberInfo } = useGetMemberInfo();

  const isPrivateOrSupplierMember = isMemberSupplierUser || isSharedOnlyMember;

  const getIdCurrentMember = () => {
    if (isPrivateOrSupplierMember && !memberInfo) return userInfo?.member?.id;
    return memberInfo?.id;
  };

  const { qaSpecDataHeader } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: !!productId,
  });

  const checkAssignProductToSupplier = () => {
    const idMember = getIdCurrentMember();

    const isCurrentMemberAssignedToQaSpec =
      qaSpecDataHeader?.assignTo === idMember;

    const allowSubmitBtnStatus = ['open', 'rejected'];

    const isOpenOrRejected = allowSubmitBtnStatus.includes(
      qaSpecDataHeader?.status?.toLowerCase()
    );

    return isCurrentMemberAssignedToQaSpec && isOpenOrRejected;
  };

  return {
    checkAssignProductToSupplier,
  };
};

export default useCheckAssignProductToSupplier;
