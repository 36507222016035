import React from 'react';

import { Button, Space, Typography } from 'antd';
import {
  DragOutlined,
  SettingOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useDragDropPosHooks, FormInputHTML } from 'common/components';

import { EDIT_TYPE } from '../../constants';

import {
  useAdjustPositionWhenSizeChange,
  useGet$wrapAnd$component,
} from '../../hooks';
import { sleep } from 'utils/delay';

import classnames from 'classnames';
import * as _ from 'lodash';

import './PageEditorDragText.less';

const { Text } = Typography;

const PageEditorDragText = (props) => {
  const {
    wrapId,
    selectedHook,
    isEdit,
    componentData,
    componentKey,
    componentHandlers,
    editTypeHook,
    detailShowTargetHook,
  } = props;

  const { onDeleteComponent, onChangeComponentValue, onDragComponent } =
    componentHandlers;

  const [selected, setSelected, clearSelect] = selectedHook;
  const [editType, setEditType] = editTypeHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;

  const [dragPosInst, dragStyle, refDragTag] = useDragDropPosHooks([
    clearSelect,
    componentKey,
    { disableClickouter: detailShowTarget === componentKey },
  ]);

  useAdjustPositionWhenSizeChange({
    wrapId,
    componentId: componentKey,
    onChangeComponentValue,
    fieldNameX: 'textLeft',
    fieldNameY: 'textTop',
    deps: [
      componentData?.textBorderWidth,
      componentData?.textSize,
      componentData?.textPadding,
      componentData?.textWeight,
    ],
  });

  const [refWrap, refComponent] = useGet$wrapAnd$component(
    wrapId,
    componentKey
  );

  const isSelected = selected?.id === componentKey && isEdit;

  const handleClickMarkupText = (event) => {
    const target = event?.currentTarget;
    if (selected?.id !== target?.id) {
      setSelected({ id: target?.id, element: target });
    }
  };

  const onClickDragIcon = () => {
    onDragComponent({
      componentData,
      componentKey,
      fieldNameX: 'textLeft',
      fieldNameY: 'textTop',
      refWrap,
      refComponent,
      dragPosInst,
    });
  };

  const toggleEditDetailText = () => {
    setEditType(EDIT_TYPE.TEXT);
    setDetailShowTarget(componentKey);
  };

  const onInputMarkupText = (value) => {
    onChangeComponentValue({
      key: componentKey,
      fieldName: 'text',
      value,
    });
  };

  const handleRemoveComponent = async () => {
    if (isSelected) {
      await sleep(200);

      onDeleteComponent({ key: componentKey });
      clearSelect();
    }
  };

  return (
    <>
      <div
        style={{
          top: componentData?.textTop,
          left: componentData?.textLeft,
          position: 'absolute',
          ...dragStyle(isSelected),
        }}
        id={componentKey}
        className={classnames('page-editor-drag-text', {
          'highlight-selected': isSelected,
        })}
        onClick={handleClickMarkupText}
      >
        <FormInputHTML
          onChange={onInputMarkupText}
          htmlValue={componentData?.text}
          styles={{
            color: componentData?.textColor,
            fontSize: componentData?.textSize,
            outline: 'none',
            whiteSpace: 'nowrap',
            border: `solid ${componentData?.textBorderWidth}px ${componentData?.textBorderColor}`,
            boxShadow: componentData?.textShadow,
            background:
              componentData?.isHaveTextBackground &&
              componentData?.textBackground,
            borderRadius: componentData?.textRadius,
            padding: componentData?.textPadding,
            fontWeight: componentData?.textWeight,
            fontFamily: componentData?.textFont,
          }}
          isEdit={isEdit}
        />

        {!componentData?.text && !isSelected && isEdit ? (
          <Text className='page-editor-drag-text__placeholder'>
            Input text here
          </Text>
        ) : null}
        <div
          className={classnames('page-editor__drag', {
            'page-editor__drag--show': isSelected,
          })}
        >
          <Space size={3}>
            <Button
              className={classnames(
                'page-editor__btn',
                'page-editor__btn--clear'
              )}
              onClick={handleRemoveComponent}
            >
              <CloseOutlined />
            </Button>
            <Button
              className={classnames('page-editor__btn')}
              onClick={toggleEditDetailText}
            >
              <SettingOutlined />
            </Button>
            <Button
              className={classnames('page-editor__btn')}
              ref={refDragTag}
              onMouseDown={onClickDragIcon}
            >
              <DragOutlined />
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
};

export default PageEditorDragText;
