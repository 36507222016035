import React from 'react';

import { Button } from 'antd';

import classnames from 'classnames';

import './SearchGridItemButton.less';

const SearchGridItemButton = (props) => {
  const { classNames = [], children, ...restProps } = props;
  return (
    <Button
      className={classnames('search-grid-item-btn', [...classNames])}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default SearchGridItemButton;
