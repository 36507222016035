import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  DownloadOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons';
import classnames from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, Typography, Button, Space } from 'antd';

import {
  Form,
  FormButton,
  dialogFunction,
  CustomNotification,
  FormCancelButton,
  FormEditButton,
} from 'common/components';
import ModalUpdateDocumentOrCertification from './ModalUpdateDocsAndCertificates';
import PreviewableModal from 'common/components/previewable-modal/PreviewableModal';
import AddAssetPimModal from 'pages/product-full-view/components/modal/add-asset-pim-modal';

import * as qaSelectors from 'pages/qa-spec/controllers/selectors';

import { useSetFormUpdateDocsAndCertification } from './hooks';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';
import { useCheckAllowEdit } from 'pages/qa-spec/hooks';
import { useTabExpand } from 'hooks/useTabExpandProduct';
import { useReloadDocumentCertificateTab } from 'hooks/useReloadDocumentCertificateTab';

import { bytesToMegaBytes } from 'utils/string';
import { formatMDY, exportFormDate } from 'utils/formatDate';
import { checkShowPdfPreview } from 'utils/checkShowPdfPreview';

import { getQaContainTabDataQueryKey } from 'pages/qa-spec/components/qa-product-contain/queries';

import * as api from 'config/axios';
import * as services from 'services/product';
import * as servicesQaSpec from 'services/qaSpec';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/qa-spec';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';

import { QaGridEdit } from 'pages/qa-spec/components';

import { getCellRenderer } from './utils';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import {
  useQueryQaSpecCertificateSnapshotData,
  useQueryQaSpecDocumentSnapshotData,
} from './queries';

import './QaSpecDocsAndCertificates.less';

const QaSpecDocsAndCertificates = ({ productId }) => {
  const intl = useIntl();
  const docGridRef = useRef(null);
  const cerGridRef = useRef(null);
  const queryClient = useQueryClient();

  const reloadDocumentAndCertificateTab = useSelector(
    qaSelectors.selectReloadDocumentAndCertificate()
  );

  const [rowDataDoc, setRowDataDoc] = useState([]);
  const [rowDataCer, setRowDataCer] = useState([]);
  const [isLinkToNewAssetModalOpen, setIsLinkToNewAssetModalOpen] =
    useState(false);
  const [addType, setAddStype] = useState('');
  const [visible, setVisible] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [isEditCer, setIsEditCer] = useState(false);

  const [rowPreview, setRowPreview] = useState();

  const [formInstance] = Form.useForm();

  const [selectedRow, setSelectedRow] = useState({
    document: null,
    certificate: null,
  });

  const [visibleModalUpdate, setVisibleModalUpdate] = useState({
    type: null,
    value: false,
  });

  const useSnapshot = useCheckSnapshotForRetailer(productId);
  const { data: snapshotDocsData } = useQueryQaSpecDocumentSnapshotData({
    productId,
    enabled: Boolean(productId && useSnapshot),
  });
  const { data: snapshotCertsData } = useQueryQaSpecCertificateSnapshotData({
    productId,
    enabled: Boolean(productId && useSnapshot),
  });

  const snapshotDocsGridData = snapshotDocsData?.documents;
  const snapshotCertsGridData = snapshotCertsData?.certificates;

  const { tableStyle } = useTabExpand();

  const { toggleReloadDocumentWhenDelete } = useReloadDocumentCertificateTab();

  useSetFormUpdateDocsAndCertification({
    isEnabled: Boolean(visibleModalUpdate.value),
    type: visibleModalUpdate.type,
    formInstance,
    dataForm:
      visibleModalUpdate.type === 'document'
        ? selectedRow.document
        : selectedRow.certificate,
  });

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const { linkPreview, extensionFile } = checkShowPdfPreview(
    rowPreview?.pdfPreview,
    rowPreview?.linkUrl,
    rowPreview?.fileType
  );

  const invalidateData = () => {
    //* reload product contain tab
    queryClient.invalidateQueries({
      queryKey: getQaContainTabDataQueryKey(productId),
    });
  };

  const handleDelete = useCallback((linkId, isDocumentType) => {
    services
      .qaSpecUnLinkToDocCer({ linkId: linkId })
      .then((response) => {
        const name = isDocumentType ? 'Document' : 'Certificate';
        if (response?.isSuccess) {
          CustomNotification.success(
            intl.formatMessage(Messages.qaSpecDocsAndCerRemoveMessageSuccess, {
              name,
            })
          );
          if (isDocumentType) {
            getQaSpecDoc();
            clearSelectedRow('document');
            toggleReloadDocumentWhenDelete();
            return;
          }

          getQaSpecCer();
          clearSelectedRow('certificate');
          //* reload product contain tab
          invalidateData();
        } else {
          CustomNotification.error(response?.message);
        }
      })
      .catch((e) => {})
      .finally(() => {});
  }, []);

  const handleDeleteModal = useCallback(
    (linkId, isDocumentType) => {
      dialogFunction({
        type: 'warn',
        content: 'Are you sure you want to delete the selected item?',
        okText: 'Delete',
        okButtonProps: {
          type: 'danger',
        },
        onOk: () => handleDelete(linkId, isDocumentType),
      });
    },
    [handleDelete]
  );

  const CellRenderAction = useCallback(
    (params) => {
      const isDocumentType = params?.data.hasOwnProperty('documentName');

      return (
        <>
          <Button
            type='link'
            icon={<EyeOutlined />}
            onClick={() => {
              setRowPreview(params?.data);
              setVisible(true);
            }}
          />
          <Button
            type='link'
            icon={<DownloadOutlined />}
            onClick={() => {
              const downloadUrl = params?.data?.linkUrl;

              if (!downloadUrl) {
                CustomNotification.error('Could not download file!');
              }
              api.sendDownload({
                url: downloadUrl,
              });
            }}
          />
          {!isQASpecLocked ? (
            <Button
              type='link'
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDeleteModal(params?.data?.linkId, isDocumentType);
              }}
            />
          ) : null}
        </>
      );
    },
    [isQASpecLocked, handleDeleteModal]
  );

  const formatFileSize = (params) => {
    return `${bytesToMegaBytes(params.value)} MB`;
  };

  const columnDefsDoc = useMemo(() => {
    const cellRenderer = getCellRenderer({
      snapshotGridValues: snapshotDocsGridData,
      useSnapshot,
      CellRenderAction,
    });

    const cols = [
      {
        dataType: 'string',
        displayName: 'Document Name',
        field: 'documentName',
        fieldNameCamelCase: 'documentName',
        cellRenderer: cellRenderer['TextRender'],
      },
      // {
      //   dataType: 'string',
      //   displayName: 'Document Type',
      //   fieldName: 'documentType',
      //   fieldNameCamelCase: 'documentType',
      // },
      {
        dataType: 'string',
        displayName: 'Type',
        field: 'type',
        fieldNameCamelCase: 'type',
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        dataType: 'string',
        displayName: 'Sub Type',
        field: 'subtype',
        fieldNameCamelCase: 'subtype',
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        dataType: 'string',
        displayName: 'File Type',
        field: 'fileType',
        fieldNameCamelCase: 'fileType',
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        dataType: 'string',
        displayName: 'File Size',
        field: 'fileSize',
        fieldNameCamelCase: 'fileSize',
        valueGetter: (params) => {
          return `${bytesToMegaBytes(params?.data.fileSize)} MB`;
        },
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        dataType: 'DateTime',
        displayName: 'Upload Date',
        field: 'uploadDate',
        fieldName: 'uploadData',
        fieldNameCamelCase: 'uploadDate',
        valueGetter: ({ data }) => formatMDY(data?.['uploadDate']),
        cellRenderer: cellRenderer['TextRenderForDate'],
      },
      {
        displayName: '',
        field: 'actions',
        cellRenderer: cellRenderer['CellRenderAction'],
        cellRendererParams: {
          isDocumentType: true,
        },
      },
    ];

    return cols.map((fieldItem) => {
      const nullCellEditor = () => {};

      return {
        cellEditorSelector: nullCellEditor,
        ...fieldItem,
        minWidth: 100,
        width: 200,
        flex: fieldItem?.fieldName === 'actions' ? 0 : 1,
      };
    });
  }, [useSnapshot, CellRenderAction, JSON.stringify(snapshotDocsGridData)]);

  const columnDefsCer = useMemo(() => {
    const cellRenderer = getCellRenderer({
      snapshotGridValues: snapshotCertsGridData,
      useSnapshot,
      CellRenderAction,
    });

    const cols = [
      {
        dataType: 'string',
        displayName: 'Certificate',
        field: 'certificate',
        fieldNameCamelCase: 'certificate',
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        dataType: 'string',
        displayName: 'Certificate Type',
        field: 'certificateType',
        fieldNameCamelCase: 'certificateType',
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        dataType: 'string',
        displayName: 'Certificate Body',
        field: 'certificateBody',
        fieldNameCamelCase: 'certificateBody',
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        dataType: 'DateTime',
        displayName: 'Certificate Expiry Date',
        field: 'certificateExpiryDate',
        fieldNameCamelCase: 'certificateExpiryDate',
        valueGetter: ({ data }) => formatMDY(data?.['certificateExpiryDate']),
        cellRenderer: cellRenderer['TextRenderForDate'],
      },
      {
        displayName: '',
        field: 'actions',
        cellRenderer: cellRenderer['CellRenderAction'],
      },
    ];

    return cols.map((fieldItem) => {
      const nullCellEditor = () => {};

      return {
        cellEditorSelector: nullCellEditor,
        ...fieldItem,
        minWidth: 100,
        width: 200,
        flex: fieldItem?.fieldName === 'actions' ? 0 : 1,
      };
    });
  }, [useSnapshot, CellRenderAction, JSON.stringify(snapshotCertsGridData)]);

  const getQaSpecDoc = useCallback(() => {
    services
      .getQaSpeecDataDocument({ productId })
      .then((response) => {
        if (response?.isSuccess && response?.data?.documents)
          setRowDataDoc(response?.data?.documents);
      })
      .catch((e) => {})
      .finally(() => {});
  }, [productId]);

  const getQaSpecCer = useCallback(() => {
    services
      .getQaSpeecDataCertificate({ productId })
      .then((response) => {
        if (response?.isSuccess && response?.data?.certificates)
          setRowDataCer(response?.data?.certificates);
      })
      .catch((e) => {})
      .finally(() => {});
  }, [productId]);

  const fetchQaSpecDocAndCer = () => {
    getQaSpecDoc();
    getQaSpecCer();
  };

  useEffect(() => {
    fetchQaSpecDocAndCer();
  }, []);

  useEffect(() => {
    if (reloadDocumentAndCertificateTab) {
      fetchQaSpecDocAndCer();
    }
  }, [reloadDocumentAndCertificateTab]);

  const clearSelectedRow = (grid) => {
    setSelectedRow((prevState) => ({ ...prevState, [grid]: null }));

    const gridInstance =
      grid === 'document' ? docGridRef?.current : cerGridRef?.current;

    gridInstance.api.deselectAll();
  };

  const reloadGrid = () => {
    if (addType === 'Document') {
      clearSelectedRow('document');
      getQaSpecDoc();
    } else {
      clearSelectedRow('certificate');
      getQaSpecCer();
    }
    handleToggleLinkAssetModal();
  };

  const handleToggleLinkAssetModal = () => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
  };

  const handleNewDoc = () => {
    setAddStype('Document');
    handleToggleLinkAssetModal();
  };
  const handleNewCer = () => {
    setAddStype('Certificate');
    handleToggleLinkAssetModal();
  };

  const toggleEditMode = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);
  const toggleEditModeCer = useCallback(() => {
    setIsEditCer((prev) => !prev);
  }, []);

  const onCancel = () => {
    toggleEditMode();
    docGridRef?.current?.api.deselectAll();
    setSelectedRow((prevRow) => ({
      ...prevRow,
      document: null,
    }));
  };
  const onCancelCer = () => {
    toggleEditModeCer();
    cerGridRef?.current?.api.deselectAll();
    setSelectedRow((prevRow) => ({
      ...prevRow,
      certificate: null,
    }));
  };

  const onCancelModalUpdate = () => {
    setVisibleModalUpdate({
      type: '',
      value: false,
    });
  };

  const handleUpdateDocsAndCertificate = async () => {
    const values = await formInstance.validateFields();
    const isDocumentType = visibleModalUpdate.type === 'document';
    const name = isDocumentType ? 'Document' : 'Certificate';
    let params = {};

    try {
      if (isDocumentType) {
        params = {
          ...params,
          linkId: selectedRow?.document?.linkId,
          documentName: values.documentName,
          subtype: values?.subtype,
          documentType: selectedRow?.document?.documentType,
          type: selectedRow?.document?.type,
        };
      } else {
        params = {
          ...params,
          documentType: selectedRow?.certificate?.type,
          type: selectedRow?.certificate?.type,
          subtype: selectedRow?.certificate?.subtype,
          linkId: selectedRow?.certificate?.linkId,
          certificate: values.certificate,
          certificateBody: values.certificateBody,
          certificateExpiryDate: exportFormDate(values.certificateExpiryDate),
          certificateType: values?.certificateType,
        };
      }

      const response = await servicesQaSpec.updateDocumentOrCertification(
        params
      );

      if (response?.isSuccess) {
        CustomNotification.success(
          intl.formatMessage(Messages.qaSpecDocsAndCerUpdateMessageSuccess, {
            name,
          })
        );
        onCancelModalUpdate();
        if (isDocumentType) {
          getQaSpecDoc();
          clearSelectedRow('document');
          toggleReloadDocumentWhenDelete();
        } else {
          getQaSpecCer();
          clearSelectedRow('certificate');
          //* reload product contain tab
          invalidateData();
        }
      } else {
        CustomNotification.error(response?.message ?? ' Something went wrong');
      }
    } catch (error) {}
  };

  const hasPermissionEdit = useCheckAllowEdit(productId);

  return (
    <Form style={{ paddingRight: 5 }} layout='vertical'>
      <Row style={{ flex: 1 }}>
        <Col xs={24}>
          <Row>
            <Space direction='vertical' style={{ width: '100%' }}>
              <div
                className='qa-spec-doc-n-cer__doc'
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Row align='bottom' style={{ width: '100%', height: 30 }}>
                    <Col span={12}>
                      <Typography.Title
                        level={5}
                        strong
                        style={{ marginBottom: 0 }}
                      >
                        Documents
                      </Typography.Title>
                    </Col>
                    {!isQASpecLocked ? (
                      <>
                        {isEdit && (
                          <Col
                            span={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Space>
                              <FormButton
                                text='New Document'
                                onClick={handleNewDoc}
                              />

                              <FormEditButton
                                text='Update Document'
                                disabled={!selectedRow?.document}
                                onClick={() =>
                                  setVisibleModalUpdate({
                                    value: true,
                                    type: 'document',
                                  })
                                }
                              />
                              <FormCancelButton onClick={onCancel} />
                            </Space>
                          </Col>
                        )}
                        {!isEdit && hasPermissionEdit && (
                          <Col
                            span={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              className='qa-spec-doc-n-cer__edit-btn'
                              icon={<EditOutlined />}
                              onClick={toggleEditMode}
                              style={{ border: 'none' }}
                            />
                          </Col>
                        )}
                      </>
                    ) : null}
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <Col span={24}>
                      <Row style={{ width: '100%' }}>
                        {columnDefsDoc && columnDefsDoc.length > 0 && (
                          <div
                            style={{
                              ...tableStyle,
                              width: '100%',
                            }}
                            className={classnames(
                              'ag-theme-alpine',
                              'ag-theme-alpine--with-scroller'
                            )}
                          >
                            <QaGridEdit
                              gridInst={docGridRef}
                              gridData={rowDataDoc}
                              overlayNoRowsTemplate='No Rows to Show'
                              rowSelection={'single'}
                              onRowSelected={(event) => {
                                if (!event?.node?.selected) return;

                                setSelectedRow((prevRow) => ({
                                  ...prevRow,
                                  document: event.data,
                                }));
                              }}
                              fields={columnDefsDoc}
                              snapshotGridValues={snapshotDocsGridData}
                              defaultColDef={{ resizable: true }}
                              isEdit={isEdit}
                            />
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Space>
              </div>
              <div
                className='qa-spec-doc-n-cer__cer'
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Row align='bottom' style={{ width: '100%', height: 30 }}>
                    <Col span={12}>
                      <Typography.Title
                        level={5}
                        strong
                        style={{ marginBottom: 0 }}
                      >
                        Certificates
                      </Typography.Title>
                    </Col>
                    {!isQASpecLocked ? (
                      <>
                        {isEditCer && (
                          <Col
                            span={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Space>
                              <FormButton
                                text='New Certificate'
                                onClick={handleNewCer}
                              />
                              <FormEditButton
                                text='Update Certificate'
                                disabled={!selectedRow?.certificate}
                                onClick={() =>
                                  setVisibleModalUpdate({
                                    value: true,
                                    type: 'certificate',
                                  })
                                }
                              />
                              <FormCancelButton onClick={onCancelCer} />
                            </Space>
                          </Col>
                        )}
                        {!isEditCer && hasPermissionEdit && (
                          <Col
                            span={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              className='qa-spec-doc-n-cer__edit-btn'
                              icon={<EditOutlined />}
                              onClick={toggleEditModeCer}
                              style={{ border: 'none' }}
                            />
                          </Col>
                        )}
                      </>
                    ) : null}
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <Col span={24}>
                      <Row style={{ width: '100%' }}>
                        {columnDefsCer && columnDefsCer.length > 0 && (
                          <div
                            style={{
                              ...tableStyle,
                              width: '100%',
                            }}
                            className={classnames(
                              'ag-theme-alpine',
                              'ag-theme-alpine--with-scroller'
                            )}
                          >
                            <QaGridEdit
                              gridInst={cerGridRef}
                              gridData={rowDataCer}
                              overlayNoRowsTemplate='No Rows to Show'
                              rowSelection={'single'}
                              onRowSelected={(event) => {
                                if (!event?.node?.selected) return;

                                setSelectedRow((prevRow) => ({
                                  ...prevRow,
                                  certificate: event.data,
                                }));
                              }}
                              fields={columnDefsCer}
                              defaultColDef={{ resizable: true }}
                              isEdit={isEdit}
                              snapshotGridValues={snapshotCertsGridData}
                            />
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Space>
              </div>
            </Space>
            {isLinkToNewAssetModalOpen && (
              <AddAssetPimModal
                onCloseModal={handleToggleLinkAssetModal}
                addType={addType}
                getImagery={[]}
                getDocument={reloadGrid}
                qaSpec={true}
              />
            )}
            {visible && (
              <PreviewableModal
                id={rowPreview?.linkId}
                link={linkPreview}
                title={rowPreview?.certificate || rowPreview?.documentName}
                visible={visible}
                fileName={rowPreview?.certificate || rowPreview?.documentName}
                cancelModal={() => setVisible(false)}
                downloadLink={rowPreview?.linkUrl}
                isDownload={false}
                fileType={extensionFile}
                fileSize={rowPreview?.fileSize}
                isDocument={true}
              />
            )}

            {visibleModalUpdate.value && (
              <ModalUpdateDocumentOrCertification
                type={visibleModalUpdate.type}
                formInstance={formInstance}
                title={
                  visibleModalUpdate.type === 'document'
                    ? 'Update Document'
                    : 'Update Certificate'
                }
                visible={visibleModalUpdate.value}
                onCancel={onCancelModalUpdate}
                width={500}
                bodyStyle={{
                  minHeight: '150px',
                }}
                onOk={handleUpdateDocsAndCertificate}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

QaSpecDocsAndCertificates.propTypes = {
  productItemModules: PropTypes.object,
};
export default QaSpecDocsAndCertificates;
