export const NUTRITION_TYPE = {
  'Fat, Total (fat, total)': {
    nutritionName: 'Total Fat',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'FAT',
    labelBold: true,
    percentBold: true,
    index: 1,
    defaultValue: true,
  },
  'Fat, Saturated (fatty acids, total saturated)': {
    nutritionName: 'Saturated Fat',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'FASAT',
    indent: 10,
    percentBold: true,
    index: 2,
    defaultValue: true,
  },
  'Fat, Trans (fatty acids, total trans)': {
    nutritionName: 'Trans Fat',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'FATRN',
    indent: 10,
    percentBold: true,
    index: 3,
    defaultValue: true,
  },
  'Fat, Polyunsaturated (fatty acids, total polyunsaturated)': {
    nutritionName: 'Polyunsaturated Fat',
    nutritionTypeCode: 'FAPU',
    indent: 10,
    index: 4,
  },
  'Fat, Monounsaturated  (fatty acids, total monounsaturated)': {
    nutritionName: 'Monounsaturated Fat',
    nutritionTypeCode: 'FAMS',
    indent: 10,
    index: 5,
  },
  'Cholesterol; Method Of Determination Unknown Or Variable': {
    nutritionName: 'Cholesterol',
    nutritionTypeCode: 'CHOL_',
    labelBold: true,
    percentBold: true,
    index: 6,
  },
  Sodium: {
    nutritionName: 'Sodium',
    quantity: 0,
    quantityUom: 'Milligram',
    nutritionTypeCode: 'NA',
    labelBold: true,
    percentBold: true,
    index: 7,
    defaultValue: true,
  },
  'Carbohydrate, Total; Method Of Determination Unknown Or Variable': {
    nutritionName: 'Carbohydrates',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'CHO_',
    labelBold: true,
    percentBold: true,
    index: 9,
    defaultValue: true,
  },
  'Dietary Fibre, total (fibre, total dietary; Wenlock modification)': {
    nutritionName: 'Dietary Fiber',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'FIBTG',
    indent: 10,
    percentBold: true,
    index: 11,
    defaultValue: true,
  },
  'Sugars, Total': {
    nutritionName: 'Total Sugars',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'SUGAR',
    indent: 10,
    index: 14,
    defaultValue: true,
  },
  'Sugar, Added': {
    nutritionName: 'Added Sugars',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'SUGAD',
    indent: 15,
    indentBar: 15,
    percentBold: true,
    index: 15,
    defaultValue: true,
  },
  'Sugar Alcohol': { indent: 10, index: 16 },
  'Protein, Total; Method Of Determination Unknown Or Variable': {
    nutritionName: 'Protein',
    quantity: 0,
    quantityUom: 'Gram',
    nutritionTypeCode: 'PRO_',
    labelBold: true,
    percentBold: true,
    index: 20,
    isSeparator: true,
    bottomBarHeight: 3,
    bottomBar: true,
    defaultValue: true,
  },
  'Vitamin D (vitamin D; calculated by summation of ergocalciferol and cholecalciferol)':
    { nutritionName: 'Vitamin D', nutritionTypeCode: 'VITD', index: 22 },
  Calcium: {
    nutritionName: 'Calcium',
    nutritionTypeCode: 'CA',
    index: 23,
    isStepByStep: true,
  },
  'Iron (iron, total)': {
    nutritionName: 'Iron',
    nutritionTypeCode: 'FE',
    index: 24,
    isStepByStep: true,
  },
  Potassium: {
    nutritionName: 'Potassium',
    nutritionTypeCode: 'K',
    index: 46,
    isStepByStep: true,
  },
  'Vitamin A; Calculated By Summation Of The Vitamin A Activities Of Retinol And The Active Carotenoids':
    {
      nutritionName: 'Vitamin A',
      nutritionTypeCode: 'VITA',
      index: 25,
      isStepByStep: true,
    },
  'Vitamin C': {
    nutritionName: 'Vitamin C',
    nutritionTypeCode: 'VITC',
    index: 26,
    isStepByStep: true,
  },
  'Vitamin E; Calculated By Summation Of The Vitamin E Activities Of The Active Tocopherols And Tocotrienols; Expressed As Alpha-Tocopherol Equivalents':
    {
      nutritionName: 'Vitamin E',
      nutritionTypeCode: 'VITE',
      index: 27,
      isStepByStep: true,
    },
  'Vitamin K': {
    nutritionName: 'Vitamin K',
    nutritionTypeCode: 'VITK',
    index: 28,
    isStepByStep: true,
  },
  Thiamin: { nutritionName: 'Thiamin', nutritionTypeCode: 'THIA', index: 29 },
  Riboflavin: {
    nutritionName: 'Riboflavin',
    nutritionTypeCode: 'RIBF',
    index: 30,
    isStepByStep: true,
  },
  'Folic Acid, Synthetic Folic Acid': {
    nutritionName: 'Folic Acid',
    nutritionTypeCode: 'FOLAC',
    index: 33,
    isStepByStep: true,
  },
  'Niacin, Preformed': {
    nutritionName: 'Niacin',
    nutritionTypeCode: 'NIA',
    index: 31,
    isStepByStep: true,
  },
  'Vitamin B6 Unk.': {
    nutritionName: 'Vitamin B6',
    nutritionTypeCode: 'VITB6_',
    index: 32,
    isStepByStep: true,
  },
  'Folate, Total': {
    nutritionName: 'Folate',
    nutritionTypeCode: 'FOL',
    index: 34,
    isStepByStep: true,
  },
  'Vitamin B-12': {
    nutritionName: 'Vitamin B12',
    nutritionTypeCode: 'VITB12',
    index: 35,
    isStepByStep: true,
  },
  Biotin: {
    nutritionName: 'Biotin',
    nutritionTypeCode: 'BIOT',
    index: 36,
    isStepByStep: true,
  },
  'Pantothenic Acid': {
    nutritionName: 'Pantothenic Acid',
    nutritionTypeCode: 'PANTAC',
    index: 37,
    isStepByStep: true,
  },
  Phosphorus: {
    nutritionName: 'Phosphorus',
    nutritionTypeCode: 'P',
    index: 38,
    isStepByStep: true,
  },
  Magnesium: {
    nutritionName: 'Magnesium',
    nutritionTypeCode: 'MG',
    index: 39,
    isStepByStep: true,
  },
  Zinc: {
    nutritionName: 'Zinc',
    nutritionTypeCode: 'ZN',
    index: 40,
    isStepByStep: true,
  },
  'Selenium, Total': {
    nutritionName: 'Selenium',
    nutritionTypeCode: 'SE',
    index: 41,
    isStepByStep: true,
  },
  Copper: {
    nutritionName: 'Copper',
    nutritionTypeCode: 'CU',
    index: 42,
    isStepByStep: true,
  },
  Manganese: {
    nutritionName: 'Manganese',
    nutritionTypeCode: 'MN',
    index: 43,
    isStepByStep: true,
  },
  Caffeine: {
    nutritionName: 'Caffeine',
    nutritionTypeCode: 'CAFFN',
    index: 44,
    topBarHeight: 3,
    topBar: true,
    isStepByStep: true,
  },
  Chromium: {
    nutritionName: 'Chromium',
    nutritionTypeCode: 'CR',
    index: 45,
    isStepByStep: true,
  },
  Erythritol: {
    nutritionName: 'Erythritol',
    nutritionTypeCode: 'ERYTHL',
    indent: 10,
    index: 19,
  },
  Fluoride: {
    nutritionName: 'Fluoride',
    nutritionTypeCode: 'FD',
    labelBold: true,
    percentBold: true,
    index: 8,
  },
  'Fibre, Water-Insoluble': {
    nutritionName: 'Insoluble Fibre',
    nutritionTypeCode: 'FIBINS',
    indent: 15,
    indentBar: 15,
    index: 13,
  },
  'Fibre, Water-Soluble': {
    nutritionName: 'Soluble Fibre',
    nutritionTypeCode: 'FIBSOL',
    indent: 15,
    indentBar: 15,
    index: 12,
  },
  Maltitol: {
    nutritionName: 'Maltitol',
    nutritionTypeCode: 'MALTL',
    indent: 10,
    index: 18,
  },
  Sorbitol: {
    nutritionName: 'Sorbitol',
    nutritionTypeCode: 'SORTL',
    indent: 10,
    index: 17,
  },
  Xylitol: {
    nutritionName: 'Xylitol',
    nutritionTypeCode: 'XYLTL',
    indent: 10,
    index: 10,
  },
};

export const DEFAULT_NUTRIENT_VALUE = [
  'Fat, Total (fat, total)',
  'Fat, Saturated (fatty acids, total saturated)',
  'Fat, Trans (fatty acids, total trans)',
  'Sodium',
  'Carbohydrate, Total; Method Of Determination Unknown Or Variable',
  'Dietary Fibre, total (fibre, total dietary; Wenlock modification)',
  'Sugars, Total',
  'Sugar, Added',
  'Protein, Total; Method Of Determination Unknown Or Variable',
];

export const MEASUREMENT_OPERATOR = {
  Approximately: 'approx.',
  'Greater Than': '>',
  'Greater Than Or Equal': '≥',
  'Less Than': '<',
  'Less Than Or Equal': '≤',
};
