import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Skeleton, Popover } from 'antd';

import { VideoCameraOutlined, LinkOutlined } from '@ant-design/icons';

import { UploadFile } from 'common/components';
import { AttachList } from '../index';
import { SwitchAccount } from './SwitchAccount';

import * as globalActions from 'redux/global/actions';
import * as chatActions from '../../controller/actions';
import * as globalSelectors from 'redux/global/selectors';
import * as chatSelectors from '../../controller/selectors';
import userSelectors from 'redux/user/selectors';

import usePrevious from 'hooks/usePrevious';
import useExitDelay from 'hooks/useExitDelay';
import useClickEntity from 'hooks/useClickEntity';

import {
  attachmentActions,
  useSendMessage,
  useSaveAccountChat,
  useUploadChatFiles,
} from './hooks';
import { checkNotAllowSendFiles } from './utils';

import { mapInnerHtmlToText } from '../../mapper/mapInnerHtmlToText';
import { MAXIMUM_CHAT_MESSAGE_LENGTH, UPLOAD_MAX_SIZE } from 'static/Constants';

import './ChatInputBar.less';

const ChatInputBar = (props) => {
  const {
    value: chatText,
    onChangeChatInput,
    getAttachFile,
    disabledCamera,
    show,
    groupUsers,
    maximizeChatView,
    highlightCameraBtn,
    onClickInputMsg,
    attachmentDispatch,
    attachmentState,
    attachList,
    setAttachList,
    warningMessage,
    warningRef,
    isVideoCallChat,
    forceSenderId,
  } = props;

  const refUpload = useRef();
  const refInput = useRef();

  const dispatch = useDispatch();

  const hubConnection = useSelector(globalSelectors.selectSignalRConnection());
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const threadInfo = useSelector(chatSelectors.selectThreadInfo());
  const accountSend = useSelector(chatSelectors.selectAccountSend());
  const communicationList = useSelector(
    chatSelectors.selectCommunicationList()
  );
  const communicationListData = communicationList?.data;

  const [senderId, setSenderId] = useState(null);

  // const prevAttachment = usePrevious(attachmentState.attachment);
  const prevAttachList = usePrevious(attachmentState.attachment);

  const [isHaveMaxFileWarning, kickoffWarning] = useExitDelay(3000);

  const activeThread = useSelector(chatSelectors.selectActiveThreadInfo());

  useSendMessage(hubConnection, dispatch);
  useSaveAccountChat({
    groupUsers,
    dispatch,
    setSenderId,
    userId: userInfo?.id,
    memberId: userInfo?.member?.id,
    isEnabled: true, // fix cache data from redux, maybe it effect email chat
    // isEnabled: !accountSend, // prevent first run not allow when accountSend has data
    // isVideoCallChat,
  });

  useUploadChatFiles({
    threadId: threadInfo?.id,
    attachList: attachmentState.attachment,
    prevAttachList,
    attachmentState,
    dispatch: attachmentDispatch,
  });

  const isClickInputBar = useClickEntity(refInput);

  const notAllowSendFiles = checkNotAllowSendFiles(attachmentState.attachment);

  useEffect(() => {
    onClickInputMsg && onClickInputMsg(isClickInputBar);
  }, [isClickInputBar]);

  const handleChangeInput = (e) => {
    const innerText = e.target.innerText;
    const innerHTML = e.target.innerHTML;
    const textChat =
      innerHTML?.length <= MAXIMUM_CHAT_MESSAGE_LENGTH
        ? innerHTML
        : innerText.substring(0, MAXIMUM_CHAT_MESSAGE_LENGTH);
    onChangeChatInput && onChangeChatInput(textChat);
  };

  const clearAttachment = () => {
    refUpload.current && refUpload.current.removeAllFile();
    setAttachList([]);
    attachmentDispatch({ type: attachmentActions.RESET });
  };

  const handleRemoveFileId = (fileUid) => {
    attachmentDispatch({
      type: attachmentActions.REMOVE_ATTACHMENT,
      fileUid,
    });
  };

  const handleClickSend = () => {
    const { attachmentIds, attachment } = attachmentState;

    if (hubConnection) {
      const procText = mapInnerHtmlToText(chatText).trim();

      if (attachment?.length === 0 && !procText.length) return;

      if (attachment?.length > 6 || notAllowSendFiles) {
        kickoffWarning();
        return;
      }

      refInput.current.innerHTML = '';
      onChangeChatInput('');

      const requestChat = {
        uniqueId: uuidv4(),
        threadId: threadInfo?.id,
        senderId: !isVideoCallChat ? accountSend?.senderId : forceSenderId,
        messageType: 0, // 0: Text, 1: Email
        messageText: procText || '',
        attachmentIds,
      };

      const payloadChat = {
        ...requestChat,
        messageType: 'Text',
        status: 'loading',
        sendTime: Date.now(),
      };

      try {
        dispatch(chatActions.sendMessageChat(payloadChat));

        onUpdateLatestMsgForThread(requestChat);

        hubConnection.invoke('SendMessage', requestChat);
      } catch (error) {
        console.log(error);
      }

      clearAttachment();
    }
  };

  const onUpdateLatestMsgForThread = (newMsgInfo) => {
    let result;
    if (!_.isEmpty(newMsgInfo) && Array.isArray(communicationListData)) {
      const existedThread = communicationListData?.find(
        (thread) => thread.threadId === newMsgInfo?.threadId
      );
      result =
        communicationListData?.length > 0 ? [...communicationListData] : [];
      if (existedThread) {
        result = _.sortBy(result, ({ threadId }) =>
          threadId === existedThread?.threadId ? 0 : 1
        );
        result[0] = {
          ...result[0],
          lastMessageText: newMsgInfo?.messageText,
          readStatus: true,
        };
        dispatch(chatActions.updateCommunicationThreadList(result));
      }
    }
  };

  const handleInputKeyPress = (e) => {
    const code = e.charCode || e.which;
    //? shift key
    if (e.shiftKey && code === 13) {
      return true;
    }

    //? enter
    if (code === 13 && !e.shiftKey) {
      e.preventDefault();
      if (refInput.current) {
        handleClickSend();
      }
      return false;
    }
  };

  const handleAttachFile = (e) => {
    if (refUpload.current) {
      refUpload.current.handleClickUpload();
    }
  };

  const onSelectAttachFile = (uniqueFilesState) => {
    getAttachFile && getAttachFile(uniqueFilesState);
  };

  const handleDropFileTo = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer.files;
    refUpload.current && refUpload.current.handleAddFileOuter(files);
  };

  const handleDragFileOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const startCall = () => {
    dispatch(
      globalActions.toggleVideoCall({
        status: true,
        params: { threadId: threadInfo?.id },
        threadItem: activeThread,
      })
    );
  };

  const handleSendMyContact = async (messageText) => {
    if (hubConnection) {
      const requestChat = {
        uniqueId: uuidv4(),
        threadId: threadInfo?.id,
        senderId: !isVideoCallChat ? accountSend?.senderId : forceSenderId,
        messageType: 2, // 0: Text, 1: Email, 2: Contact
        messageText: messageText || '',
      };

      const payloadChat = {
        ...requestChat,
        messageType: 'Contact',
        status: 'loading',
        sendTime: Date.now(),
      };

      try {
        dispatch(chatActions.sendMessageChat(payloadChat));

        onUpdateLatestMsgForThread(requestChat);

        hubConnection.invoke('SendMessage', requestChat);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const isAttachBtnAvail = useMemo(() => {
  //   if (chatText === '') return true;
  //   if (chatText !== '' && attachList?.length === 0) return false;
  //   if (attachList?.length > 0) return true;
  //   return false;
  // }, [chatText, attachList]);

  // const onClickInputBar = () => {
  //   onClickInputMsg && onClickInputMsg();
  // };

  const content = <div className='warning-text'>{warningMessage}</div>;

  return (
    <>
      {show && (
        <>
          <Popover
            content={content}
            trigger='click'
            ref={warningRef}
            overlayClassName='warning-message'
          >
            <div />
          </Popover>
          <AttachList
            fileList={attachmentState?.attachment}
            refUpload={refUpload}
            onDrop={handleDropFileTo}
            handleRemoveFileId={handleRemoveFileId}
            isHaveMaxFileWarning={isHaveMaxFileWarning}
            isSocial={true}
          />
          <Row className='chat-input-bar' gutter={8}>
            {!isVideoCallChat && (
              <Col>
                <Button
                  className={classnames('chat-input-bar__btn', {
                    'chat-input-bar__btn--disabled': disabledCamera,
                    'chat-input-bar__btn--highlight': highlightCameraBtn,
                  })}
                  onClick={startCall}
                >
                  <VideoCameraOutlined />
                </Button>
              </Col>
            )}
            <Col>
              <Button
                className={classnames('chat-input-bar__btn', {
                  // 'chat-input-bar__btn--disabled':
                  //   disabledFile || !isAttachBtnAvail,
                })}
                onClick={handleAttachFile}
              >
                <LinkOutlined />
              </Button>
              <div style={{ display: 'none', position: 'absolute' }}>
                <UploadFile
                  ref={refUpload}
                  onFileChange={onSelectAttachFile}
                  manualUpload
                  multiple
                  returnUniqueFiles
                />
              </div>
            </Col>

            <Col
              flex='1'
              style={{ position: 'relative', overflow: 'hidden' }}
              onDragOver={handleDragFileOver}
            >
              <div
                className={classnames({
                  scroller: true,
                  'chat-input-bar__input-wrapper': !maximizeChatView,
                  'chat-input-bar__input-max': maximizeChatView,
                })}
              >
                {chatText === '' && (
                  <div className='chat-input-bar__placeholder'>
                    Type a message here
                  </div>
                )}
                <div
                  className={classnames('chat-input-bar__input')}
                  ref={refInput}
                  contentEditable={true}
                  onInput={handleChangeInput}
                  onKeyPress={handleInputKeyPress}
                  onDrop={handleDropFileTo}
                ></div>
              </div>
            </Col>
            <Col>
              {accountSend ? (
                <SwitchAccount
                  handleClickSend={handleClickSend}
                  chatText={chatText}
                  groupUsers={groupUsers}
                  notAllowSendFiles={notAllowSendFiles}
                  isVideoCallChat={isVideoCallChat}
                  handleSendMyContact={handleSendMyContact}
                />
              ) : (
                <Skeleton.Avatar size={35} />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ChatInputBar;
