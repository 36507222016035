import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { FormAddButton, CustomNotification } from 'common/components';

import CreateFolderModal from 'pages/home/ribbon/components/controls/folders/CreateFolderModal';
import Messages from 'i18n/messages/folder';

import { useCheckAllowCreateFolder } from '../shared/hooks';

const AssetToFolderTool = (props) => {
  const { selectedItem, onCreateNewFolderSuccess } = props;

  const intl = useIntl();

  const createFolderModalVisibleHook = useState(false);

  const isAllowCreateFolder = useCheckAllowCreateFolder();

  const [, setCreateNewFolderModalVisible] = createFolderModalVisibleHook;

  const toggleCreateNewFolderModal = () => {
    setCreateNewFolderModalVisible((prev) => !prev);
  };

  const onReloadWhenSaveSuccess = () => {
    CustomNotification.success(
      intl.formatMessage(Messages.createFolderSuccess)
    );
    onCreateNewFolderSuccess(selectedItem);
  };

  return (
    <div>
      <FormAddButton
        text={selectedItem ? 'Add subfolder' : 'Add New Folder'}
        onClick={toggleCreateNewFolderModal}
        style={{ width: 170 }}
        disabled={!isAllowCreateFolder}
        {...(selectedItem ? { danger: true, type: null } : {})}
      />

      <React.Fragment>
        <CreateFolderModal
          visibleHook={createFolderModalVisibleHook}
          onReloadWhenSaveSuccess={onReloadWhenSaveSuccess}
          parentId={selectedItem?.id}
          skipNotification
        />
      </React.Fragment>
    </div>
  );
};

export default AssetToFolderTool;
