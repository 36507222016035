import React from 'react';
import { Link } from 'react-router-dom';

import { QueryLink } from 'pages/folders/components/custom-cell-components';

import { ROUTE } from 'static/Constants';

/**
 * build a link to open entity details full view
 * @param {string} path prefix route
 * @param {int} id entity id
 */
const generateLink = (path, id, displayText, target) => {
  if (!path && !id) return displayText || 'No Name';

  let aLink = (
    // eslint-disable-next-line no-template-curly-in-string
    <Link to={`${path}/${id}`} target={target}>
      {displayText}
    </Link>
  );
  return aLink;
};
/**
 * build product details link
 * @param {string} path prefix route
 * @param {int} id entity id
 */
export const assetLink = (id, displayText, linkTarget) => {
  return generateLink(ROUTE.ASSET, id, displayText, linkTarget);
};

/**
 * build product details link
 * @param {string} path prefix route
 * @param {int} id entity id
 */
export const productLink = (id, displayText) => {
  return generateLink(ROUTE.PRODUCT, id, displayText);
};

/**
 * build member details link
 * @param {string} path prefix route
 * @param {int} id entity id
 */
export const memberLink = (id, displayText, linkTarget) => {
  return generateLink(ROUTE.COMPANY, id, displayText, linkTarget);
};

/**
 * build reporting details link
 * @param {string} path prefix route
 * @param {int} id entity id
 */
export const reportingLink = (id, displayText) => {
  return generateLink(ROUTE.REPORTING, id, displayText);
};

/**
 * build folder details link
 * @param {string} path prefix route
 * @param {int} id entity id
 */
export const folderLink = (id, displayText, type) => {
  const path = getLinkPathByEntityType(type);

  const queryLink =
    type === 'query' ? <QueryLink value={displayText} data={{ id }} /> : null;

  return queryLink ? queryLink : generateLink(path, id, displayText);
};

export const queryLink = (id, displayText, type) => {
  return <QueryLink value={displayText} data={{ id }} />;
};

export const getLinkPathByEntityType = (type) => {
  const routeTypeCollection = {
    asset: 'ASSET',
    member: 'COMPANY',
    folder: 'FOLDER',
    product: 'PRODUCT',
    reporting: 'REPORTING',
  };

  const routeType = routeTypeCollection[type?.toLowerCase()];

  return ROUTE[routeType];
};
