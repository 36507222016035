import React, { useState } from 'react';
import { Typography, Space, Row, Col, Button, Tooltip, Modal } from 'antd';
import { get } from 'lodash';
import IconList from 'common/components/icon-list/IconList';
import EntityStatusTag from 'common/components/tag/entity-status-tag/EntityStatusTag';
import PropTypes from 'prop-types';
import { formatSizeUnits } from 'utils/formatSizeUnits';
import * as getLink from 'utils/common/linkBuilder';

const { Paragraph, Title, Link } = Typography;

const AssetTileBody = (props) => {
  const { dataDetail } = props;

  const [visible, setVisible] = useState(false);

  const status = get(dataDetail, 'status', '');
  const assetName = get(dataDetail, 'assetName', '');
  const id = get(dataDetail, 'id', '');
  const assetType = get(dataDetail, 'assetType', '');
  const fileType = get(dataDetail, 'fileType', '');
  const fileName = get(dataDetail, 'fileName', '');
  const fileSize = get(dataDetail, 'fileSize', 0);
  const additionalSearchTags = get(dataDetail, 'additionalSearchTags', '');
  const dataAssetSegments = get(dataDetail, 'dataAssetSegments', '');
  const description = get(dataDetail, 'description', '');
  const tagList = additionalSearchTags ? additionalSearchTags.split(',') : [];
  const aLink = getLink.assetLink(id, assetName);

  const renderDescriptionTooltip = (description) => {
    return (
      <>
        <Paragraph
          ellipsis={{
            rows: 10,
            symbol: 'more',
          }}
          style={{ color: '#fff', marginBottom: 0 }}
        >
          {description}
        </Paragraph>
        <Link style={{}} onClick={() => setVisible(true)}>
          More
        </Link>
      </>
    );
  };

  const renderDescriptionInfo = (description) => {
    return (
      <Modal
        width={800}
        className='asset-tile__body--tooltip-modal'
        title={'Description'}
        layout='vertical'
        visible={visible}
        destroyOnClose
        okButtonProps={{ display: 'none' }}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key='back' onClick={() => setVisible(false)}>
            Cancel
          </Button>,
        ]}
      >
        <Row className='asset-tile__body--tooltip-modal-description scroller'>
          <Col>{description}</Col>
        </Row>
      </Modal>
    );
  };

  return (
    <div className='asset-tile__body'>
      <Row justify='center' className='asset-tile__body--info-row'>
        <Space direction='vertical' size={0}>
          <Title className='asset-tile__body--title'>{aLink}</Title>
          <Paragraph className={`asset-tile__body--element`}>
            {assetType}
          </Paragraph>
          <Paragraph className={`asset-tile__body--element`}>
            {fileName}
          </Paragraph>
          <Paragraph className={`asset-tile__body--element`}>
            {fileType || 'file-type'} / {formatSizeUnits(fileSize)}
          </Paragraph>
          {description && (
            <Row className='asset-tile__body--description-wrapper'>
              <Tooltip
                title={renderDescriptionTooltip(description)}
                overlayStyle={{ width: '800px', zIndex: 999 }}
                overlayClassName='asset-tile-tooltip'
              >
                <Paragraph className='asset-tile__body--description'>
                  {description}
                </Paragraph>
              </Tooltip>
            </Row>
          )}
          {description && renderDescriptionInfo(description)}
        </Space>
      </Row>
      <Row className='asset-tile__body--status-row' justify='center'>
        <EntityStatusTag
          status={dataDetail?.status}
          position='static'
          exceptions={['Active']}
        />
      </Row>
      <Row className='asset-tile__body--segment-row'>
        <IconList
          numberOfShowedItems={4}
          itemList={dataAssetSegments}
          size='default'
          tooltipPosition='top'
        />
      </Row>
    </div>
  );
};

AssetTileBody.propTypes = {
  dataDetail: PropTypes.object,
};

export default AssetTileBody;
