import React from 'react';
import { Row, Col } from 'antd';
import { SearchControl, AdvanceSearchControl } from '../../index';

const SearchLayout = () => {
  return (
    <Row className='section section-search' align='middle' justify='center'>
      <Col>
        <SearchControl />
      </Col>
      <Col style={{ margin: '0px 0px 0px 8px' }}>
        <AdvanceSearchControl />
      </Col>
    </Row>
  );
};

SearchLayout.propTypes = {};

export default SearchLayout;
