import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Space, Typography } from 'antd';
import * as roleActions from 'pages/role/controllers/actions';

import {
  FormAddButton,
  FormDeleteButton,
  FormCancelButton,
  FormSaveButton,
  FormEditButton,
  CustomNotification,
  dialogFunction,
} from 'common/components';

import { mapMemberRoleFormToSubmitValue } from 'pages/role/mappers/mapMemerRoleFormToSubmitValue';

import { NEW_MEMBER_ROLE_FORM } from 'static/Constants';

import { isEmpty } from 'lodash';

import './styles.less';

const { Title, Paragraph, Text } = Typography;

const MemberRoleControl = (props) => {
  const {
    isEdit,
    isAddNew,
    toggleEditMode,
    formRole,
    isChanged,
    validateFormMemberRole,
    toggleCreateNewMode,
    memberRoleListData,
    selectedRole,
  } = props;

  const dispatch = useDispatch();

  const handleClickEditButton = () => {
    toggleEditMode && toggleEditMode();
  };

  const handleClickCancelButton = () => {
    toggleEditMode && toggleEditMode();
    if (isAddNew) {
      dispatch(
        roleActions.setSelectedMemberRole(
          memberRoleListData?.[memberRoleListData?.length - 2]
        )
      );
      toggleCreateNewMode();
    }
  };

  const handleStartToCreateNewRole = () => {
    toggleCreateNewMode && toggleCreateNewMode();
    toggleEditMode && toggleEditMode();
    dispatch(roleActions.setSelectedMemberRole(NEW_MEMBER_ROLE_FORM));
  };

  const handleClickSaveButton = async () => {
    const errors = await validateFormMemberRole();
    if (!isEmpty(errors)) {
      CustomNotification.error(`Please check role's name error`);
      return;
    }

    const submitValue = mapMemberRoleFormToSubmitValue({
      formValues: formRole?.values,
      isAddNew,
    });

    const successCallback = () => {
      if (isAddNew) {
        toggleCreateNewMode();
        CustomNotification.success('Create new role successfully');
      } else {
        CustomNotification.success('Edit role successfully');
      }

      handleClickEditButton();
    };

    const errorCallback = (error) => {
      if (isAddNew) {
        CustomNotification.error(error || 'Failed to create new role');
      } else {
        CustomNotification.error(error || 'Failed to edit role');
      }
    };

    dispatch(
      roleActions.saveMemberRole({
        formValues: submitValue,
        successCallback,
        errorCallback,
      })
    );
  };

  const handleDeleteMemberRole = () => {
    const successCallback = () => {
      CustomNotification.success('Delete role successfully');
    };

    const errorCallback = (error) => {
      CustomNotification.error(error || 'Failed to delete role');
    };

    const params = {
      memberRoleId: selectedRole?.roleId,
    };

    dispatch(
      roleActions.deleteMemberRole({
        params,
        successCallback,
        errorCallback,
      })
    );
  };

  const handleClickDeleteButton = () => {
    dialogFunction({
      type: 'warning',
      content: (
        <>
          <Title level={4}>Do you want to delete this member role?</Title>
          <Paragraph>
            Please remove all assigned members before system is allowed to
            delete this member role
          </Paragraph>
        </>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleDeleteMemberRole(),
    });
  };

  const saveBtnText = isAddNew ? 'Create new role' : 'Save';
  const hintText = isEdit
    ? `In the edit mode, you can edit name, description and permission of a member role item.`
    : isAddNew
    ? 'Input name, description of the new role and select permissions for the new role.'
    : 'Select a member role and add/remove member(s) from the role.';

  return (
    <Row style={{ height: '100%' }} align='middle'>
      <Col>
        <Text type='danger'>{hintText}</Text>
      </Col>
      <Col flex='auto' />
      <Col>
        <Space>
          {!isAddNew && !isEdit && (
            <>
              <FormAddButton
                text='Create a new role'
                onClick={handleStartToCreateNewRole}
              />
              <FormDeleteButton
                className='member-role-control-section__btn-delete'
                text='Delete Role'
                onClick={handleClickDeleteButton}
                disabled={!selectedRole}
              />
            </>
          )}
          {isEdit ? (
            <>
              <FormCancelButton
                text='Cancel'
                onClick={handleClickCancelButton}
              />
              <FormSaveButton
                text={saveBtnText}
                onClick={handleClickSaveButton}
                disabled={!isChanged}
              />
            </>
          ) : (
            <FormEditButton
              text='Edit'
              onClick={handleClickEditButton}
              disabled={!selectedRole}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default MemberRoleControl;
