import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';
// import { ReactComponent as IconGeneratePreview } from 'common/components/button/svg-icons/ICO_77.svg';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';

import RibbonButton from 'common/components/button/RibbonButton';
import { GeneratePreviewModal, GenerateConfirmModal } from '../../index';

import * as actionsAsset from 'pages/branded-assets/controllers/actions';
import * as assetsSelector from 'pages/branded-assets/controllers/selectors';
import * as digitalAssetsService from 'services/digitalAsset';
import * as ribbonSelector from 'redux/ribbon/selectors';

// import * as assetFullViewSelector from 'pages/asset-full-view/controllers/selectors';

import * as constant from 'static/Constants';
import { dialogFunction } from 'common/components/index';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import ribbonMessages from 'i18n/messages/home';
import assetPreviewMessages from 'i18n/messages/assetPreview';
import { injectIntl } from 'react-intl';

import { useCheckAllowEditAssets } from 'hooks';

const GeneratePreview = (props) => {
  const dispatch = useDispatch();
  const reloadPage = useDispatchReloadPage();
  // props
  const { intl, currentSelectedAssets, sectionDisabled, disabledPreview } =
    props;

  const cachedImages = useSelector(assetsSelector.makeSelectCachedImages());

  // const assetFullView = useSelector(
  //   assetFullViewSelector.makeSelectAssetData()
  // );

  const typeView = useSelector(ribbonSelector.selectRibbonType());

  const view = useSelector(ribbonSelector.selectRibbon());

  const isFullView = view === constant.RIBBON_VIEW.ASSET_FULL_VIEW.NAME;

  const { checkAllowEditAssetFull, checkAllowEditAssetGrid } =
    useCheckAllowEditAssets();

  const isAllowEditFull = checkAllowEditAssetFull();
  const isAllowEditGrid = checkAllowEditAssetGrid();

  // state
  // button disabled
  const [disabled, setDisabled] = useState(true);

  // generate thumbnail modal
  const [visible, setVisible] = useState(false);
  // confirm modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // dialog loading
  const loadingHook = useState(false);
  const [, setLoading] = loadingHook;

  // this is generate thumbnail response data, and the last data submit to update asset preview thumb
  // when user upload new thumb, this data will update
  const rootDataHook = useState([]);
  const [rootData] = rootDataHook;

  const selectGenerateThumbHook = useState([]);
  const [selectedGeneratedThumb, setSelectedGeneratedThumb] =
    selectGenerateThumbHook;

  // function
  const showConfirmModalHandler = () => {
    setShowConfirmModal(true);
  };

  const hideConfirmModalHandler = () => {
    setShowConfirmModal(false);
  };

  const showPopup = () => {
    hideConfirmModalHandler();
    setVisible(true);
    setLoading(true);
  };

  const handleOk = () => {
    setLoading(true);
    // call api submit update asset thumbnail
    callApiUpdateAssetsThumb();
  };

  const handleCancel = () => {
    setVisible(false);
    // reset preview dialog state
    setLoading(false);
    setSelectedGeneratedThumb([]);
  };

  const createCachedImages = (cacheDataList) => {
    const newCachedImages = cloneDeep(cachedImages);

    cacheDataList &&
      cacheDataList.forEach((assetId) => {
        if (newCachedImages[assetId]) {
          newCachedImages[assetId]++;
        } else {
          newCachedImages[assetId] = 1;
        }
      });

    dispatch(actionsAsset.updateCacheImage(newCachedImages));
  };
  const onClickOkSuccessMessage = (close) => {
    makePageReload();
    close();
  };
  const makePageReload = () => {
    // only reload page
    if (typeView !== constant.RIBBON_TYPES.DETAILSVIEW) {
      // reload  asset full view page
      reloadPage();
    }
  };

  const callApiUpdateAssetsThumb = () => {
    //generate request data
    const requestData = selectedGeneratedThumb.reduce((accumulator, id) => {
      const currentRootDataItem = rootData.find(({ assetId }) => {
        return assetId === id;
      });
      return { ...accumulator, [id]: currentRootDataItem.data };
    }, {});

    const data = {
      data: requestData,
    };

    digitalAssetsService
      .updateDigitalAssetThumb(data)
      .then((response) => {
        if (response.isSuccess) {
          // show message
          showSuccessMessage();

          // cached update image
          const cacheData = data?.data;
          const cacheDataList = cacheData && Object.keys(cacheData);
          createCachedImages(cacheDataList);
        } else {
          showErrorMessage(
            intl.formatMessage(assetPreviewMessages.generatePreviewError)
          );
        }
      })
      .catch((err) => {
        showErrorMessage(
          intl.formatMessage(assetPreviewMessages.generatePreviewError)
        );
      })
      .finally(() => {
        // hide loading
        setLoading(false);
        // close dialog
        setVisible(false);
      });
  };

  const backgroundGeneration = () => {
    hideConfirmModalHandler();
    const selectedAssetsId =
      currentSelectedAssets?.length &&
      currentSelectedAssets.map((asset) => {
        return asset.id;
      });

    const params = {
      assetIds: selectedAssetsId,
    };

    digitalAssetsService
      .generatePreviewBackground(params)
      .then((response) => {
        if (response.isSuccess) {
          // show success message
          showSuccessMessage();

          // cached update image
          createCachedImages(selectedAssetsId);
        } else {
          showErrorMessage(
            intl.formatMessage(assetPreviewMessages.generatePreviewError)
          );
        }
      })
      .catch((err) => {
        showErrorMessage(
          intl.formatMessage(assetPreviewMessages.generatePreviewError)
        );
      });
  };

  const showSuccessMessage = () => {
    dialogFunction({
      type: 'success',
      content: intl.formatMessage(assetPreviewMessages.generatePreviewSuccess),
      onOk: onClickOkSuccessMessage,
      cancelButtonProps: {
        style: {
          display: 'none',
        },
      },
    });
  };

  const showErrorMessage = (message) => {
    dialogFunction({
      type: 'error',
      content: message,
      cancelButtonProps: {
        style: {
          display: 'none',
        },
      },
    });
  };

  const checkButtonDisabled = (currentSelected) => {
    // const ownSelectedAssets = currentSelected?.map((asset) => {
    //   return asset?.isOwner; // before: asset.canEdit
    // });

    const isAllowGeneratePreview = checkAllowGeneratePreview(
      isFullView,
      isAllowEditFull,
      isAllowEditGrid
    );

    // The condition for the activation of the button is
    const isActivate =
      isAllowGeneratePreview &&
      // the preview section is not disabled
      !sectionDisabled;

    if (isActivate) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  // component did update
  // update disabled when currentSelectedAssets change
  useEffect(() => {
    checkButtonDisabled(currentSelectedAssets);
    //eslint-disable-next-line
  }, [currentSelectedAssets]);

  // variables
  // modal configs
  const modalConfig = {
    visible,
    okText: intl.formatMessage(assetPreviewMessages.generatePreviewSubmit),
    okButtonProps: {
      disabled: !selectedGeneratedThumb.length,
    },
    onOk: handleOk,
    onCancel: handleCancel,
    destroyOnClose: true,
    closable: false,
    wrapClassName: 'generate-preview__modal',
    width: 'auto',
    style: {
      maxWidth: 'min(1052px, 85%)',
      width: 'auto',
      display: 'inline-block',
    },
    bodyStyle: {
      maxHeight: '80vh',
      minWidth: '695px',
      overflowY: 'auto',
      padding: '16px 10px',
    },
  };

  const confirmModalConfig = {
    visible: showConfirmModal,
    onYes: backgroundGeneration,
    onNo: showPopup,
    onCancel: hideConfirmModalHandler,
  };

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<DeploymentUnitOutlined />}
        label={ribbonMessages.generatePreview}
        onClick={showConfirmModalHandler}
        disabled={disabled || disabledPreview}
      />
      <Modal {...modalConfig}>
        <GeneratePreviewModal
          selectGenerateThumbHook={selectGenerateThumbHook}
          rootDataHook={rootDataHook}
          selectedAssets={currentSelectedAssets}
          loadingHook={loadingHook}
        />
      </Modal>
      <GenerateConfirmModal {...confirmModalConfig} />
    </>
  );
};

const checkAllowGeneratePreview = (
  isFullView,
  isAllowEditFull,
  isAllowEditGrid
) => {
  if (isFullView) {
    return isAllowEditFull;
  }

  return isAllowEditGrid;
};

GeneratePreview.propsTypes = {
  /**
   * selected assets to generate thumbnail
   */
  currentSelectedAssets: PropTypes.array,
  /**
   * preview section ribbon disabled condition
   */
  sectionDisabled: PropTypes.bool,
};

export default injectIntl(GeneratePreview);
