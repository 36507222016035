import { defineMessages } from 'react-intl';

export const scope = 'Taco.broker-manager';

export default defineMessages({
  modalTitle: {
    id: `${scope}.components.searchBroker.label`,
    defaultMessage: 'Broker Management',
  },
  searchBrokerLabel: {
    id: `${scope}.components.searchBroker.label`,
    defaultMessage: 'Search Broker',
  },
  resetSearchBtn: {
    id: `${scope}.components.searchBroker.resetBtn`,
    defaultMessage: 'Reset',
  },
  permissionTitle: {
    id: `${scope}.components.permission.title`,
    defaultMessage: 'Permissions',
  },
  groupName: {
    memberGroup: {
      id: `${scope}.components.permission.groupName.member`,
      defaultMessage: 'Member',
    },
    productGroup: {
      id: `${scope}.components.permission.groupName.product`,
      defaultMessage: 'Product Item Management',
    },
    assetGroup: {
      id: `${scope}.components.permission.groupName.asset`,
      defaultMessage: 'Digital Asset Management',
    },
    folderGroup: {
      id: `${scope}.components.permission.groupName.folder`,
      defaultMessage: 'Folder',
    },
    reportingGroup: {
      id: `${scope}.components.permission.groupName.reporting`,
      defaultMessage: 'Reporting',
    },
    qaSpecificationGroup: {
      id: `${scope}.components.permission.groupName.qaSpecification`,
      defaultMessage: 'QA Specification Management',
    },
    evaluationFormGroup: {
      id: `${scope}.components.permission.groupName.evaluationForm`,
      defaultMessage: 'Evaluation Form',
    },
  },
  permissionDisplayName: {
    viewMemberInfo: {
      id: `${scope}.components.permission.displayName.member.view`,
      defaultMessage: 'VIEW MEMBER INFO',
    },
    editMemberInfo: {
      id: `${scope}.components.permission.displayName.member.edit`,
      defaultMessage: 'EDIT MEMBER INFO',
    },
    assignProductCategory: {
      id: `${scope}.components.permission.displayName.member.assignProductCategory`,
      defaultMessage: 'ASSIGN PRODUCT CATEGORY',
    },
    viewProduct: {
      id: `${scope}.components.permission.displayName.product.view`,
      defaultMessage: 'VIEW PRODUCT',
    },
    editProduct: {
      id: `${scope}.components.permission.displayName.product.edit`,
      defaultMessage: 'EDIT PRODUCT',
    },
    viewAsset: {
      id: `${scope}.components.permission.displayName.asset.view`,
      defaultMessage: 'VIEW ASSET',
    },
    editAsset: {
      id: `${scope}.components.permission.displayName.asset.edit`,
      defaultMessage: 'EDIT ASSET',
    },
    viewFolder: {
      id: `${scope}.components.permission.displayName.folder.view`,
      defaultMessage: 'VIEW FOLDER',
    },
    editFolder: {
      id: `${scope}.components.permission.displayName.folder.edit`,
      defaultMessage: 'EDIT FOLDER',
    },
    viewReporting: {
      id: `${scope}.components.permission.displayName.reporting.view`,
      defaultMessage: 'VIEW REPORTING',
    },
    editReporting: {
      id: `${scope}.components.permission.displayName.reporting.edit`,
      defaultMessage: 'EDIT REPORTING',
    },
    editQaSpecification: {
      id: `${scope}.components.permission.displayName.qaSpecification.edit`,
      defaultMessage: 'EDIT QA SPECIFICATION',
    },
    viewQaSpecification: {
      id: `${scope}.components.permission.displayName.qaSpecification.view`,
      defaultMessage: 'VIEW QA SPECIFICATION',
    },
    manageQaWorkflow: {
      id: `${scope}.components.permission.displayName.qaSpecification.qaWorkflow`,
      defaultMessage: 'MANAGE QA SPEC WORKFLOW',
    },
    editEvaluationForm: {
      id: `${scope}.components.permission.displayName.evaluationForm.edit`,
      defaultMessage: 'EDIT EVALUATION FORM',
    },
    viewEvaluationForm: {
      id: `${scope}.components.permission.displayName.evaluationForm.view`,
      defaultMessage: 'VIEW EVALUATION FORM',
    },
  },
  impersonateLevelTitle: {
    id: `${scope}.components.impersonateLevel.title`,
    defaultMessage: 'Impersonate Level',
  },
  impersonateLevelLabel: {
    member: {
      id: `${scope}.components.impersonateLevel.label.member`,
      defaultMessage: 'Member Level',
    },
    product: {
      id: `${scope}.components.impersonateLevel.label.product`,
      defaultMessage: 'Product Level',
    },
    brand: {
      id: `${scope}.components.impersonateLevel.label.brand`,
      defaultMessage: 'Brand Level',
    },
  },
  selectEntityLabel: {
    product: {
      id: `${scope}.components.impersonateLevel.selectEntity.product`,
      defaultMessage: 'Select Products',
    },
    brand: {
      id: `${scope}.components.impersonateLevel.selectEntity.brand`,
      defaultMessage: 'Select Brands',
    },
  },
  searchEntityLabel: {
    product: {
      id: `${scope}.components.impersonateLevel.searchEntity.product`,
      defaultMessage: 'Search Products',
    },
    brand: {
      id: `${scope}.components.impersonateLevel.searchEntity.brand`,
      defaultMessage: 'Search Brands',
    },
  },
  allProduct: {
    id: `${scope}.components.impersonateLevel.allProduct`,
    defaultMessage: 'All Products',
  },
  removeAllBtn: {
    id: `${scope}.components.impersonateLevel.removeAll`,
    defaultMessage: 'Remove All',
  },
  removeBtn: {
    id: `${scope}.components.impersonateLevel.remove`,
    defaultMessage: 'Remove',
  },
  submitBtn: {
    id: `${scope}.components.impersonateLevel.submit`,
    defaultMessage: 'Submit',
  },
  cancelBtn: {
    id: `${scope}.components.impersonateLevel.cancel`,
    defaultMessage: 'Cancel',
  },
  removeItemConfirmMessage: {
    all: {
      id: `${scope}.components.impersonateLevel.remove.confirmMessage.all`,
      defaultMessage: 'Are you sure you want to remove all?',
    },
    selected: {
      id: `${scope}.components.impersonateLevel.remove.confirmMessage.selected`,
      defaultMessage: 'Are you sure you want to remove selected items?',
    },
  },
  successBrokerMessage: {
    assign: {
      id: `${scope}.components.successBrokerMessage.successBrokerMessage.assign`,
      defaultMessage: 'Assign broker successfully!',
    },
    edit: {
      id: `${scope}.components.successBrokerMessage.successBrokerMessage.edit`,
      defaultMessage: 'Edit broker successfully!',
    },
  },
  errorBrokerMessage: {
    assign: {
      id: `${scope}.components.successBrokerMessage.successBrokerMessage.assign`,
      defaultMessage: 'Assign broker failed!',
    },
    edit: {
      id: `${scope}.components.successBrokerMessage.successBrokerMessage.edit`,
      defaultMessage: 'Edit broker failed!',
    },
  },
  brokerGridHint: {
    id: `${scope}.components.brokerGrid.hint`,
    defaultMessage: 'Input search text to find broker',
  },
  selectBrokerHint: {
    id: `${scope}.components.selectBroker.hint`,
    defaultMessage: 'Select broker to setup permissions',
  },
  selectedEntityLabel: {
    product: {
      id: `${scope}.components.impersonateLevel.selectedEntity.product`,
      defaultMessage: 'Selected Product(s)',
    },
    brand: {
      id: `${scope}.components.impersonateLevel.selectedEntity.brand`,
      defaultMessage: 'Selected Brand(s)',
    },
  },
});
