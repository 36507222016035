import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import { get } from 'lodash';

import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/branded-members';

import { formatMDY } from 'utils/formatDate';

const AssetTileFooter = (props) => {
  const { intl, dataDetail } = props;

  const loadedDate = get(dataDetail, 'memberSince', '');
  const lastUpdatedDate = get(dataDetail, 'lastUpdatedDate', '');

  return (
    <Row className='member-tile__footer' justify='center' align='middle'>
      <Col flex='auto'>
        <Row>
          <Col style={{ width: '85px', color: '#000' }}>
            {intl.formatMessage(Messages.lastUpdated)}
            {': '}
          </Col>
          <Col>{lastUpdatedDate && formatMDY(lastUpdatedDate)} </Col>
        </Row>
        <Row>
          <Col style={{ width: '85px', color: '#000' }}>
            {intl.formatMessage(Messages.memberSince)}
            {': '}
          </Col>
          <Col> {loadedDate && formatMDY(loadedDate)}</Col>
        </Row>
      </Col>
    </Row>
  );
};

AssetTileFooter.propTypes = {
  loadedDate: PropTypes.string,
  lastUpdate: PropTypes.string,
};

export default injectIntl(AssetTileFooter);
