import React from 'react';
import arts_crafts_needlework from 'assets/classifications/arts_crafts_needlework.svg';
import audio_visual_photography from 'assets/classifications/audio_visual_photography.svg';
import automotive from 'assets/classifications/automotive_icon.svg';
import beauty_personal_care_hygiene from 'assets/classifications/beauty_personal_care_hygiene.svg';
import building_products from 'assets/classifications/building_products.svg';
import camping from 'assets/classifications/camping_icon.svg';
import cleaning_hygiene_products from 'assets/classifications/cleaning_hygiene_products.svg';
import clothing from 'assets/classifications/clothing_icon.svg';
import communication from 'assets/classifications/communication_icon.svg';
import computing from 'assets/classifications/computing_icon.svg';
import crops from 'assets/classifications/crops_icon.svg';
import cross_segment from 'assets/classifications/cross_segment.svg';
import electrical_supplies from 'assets/classifications/electrical_supplies.svg';
import food_beverage_tobacco from 'assets/classifications/food_beverage_tobacco.svg';
import footwear from 'assets/classifications/footwear_icon.svg';
import fuels_gases from 'assets/classifications/fuels_gases.svg';
import healthcare from 'assets/classifications/healthcare_icon.svg';
import home_appliances from 'assets/classifications/home_appliances.svg';
import horticulture_plants from 'assets/classifications/horticulture_plants.svg';
import household_office_furniture_furnishings from 'assets/classifications/household_office_furniture_furnishings.svg';
import kitchenware_and_tableware from 'assets/classifications/kitchenware_and_tableware.svg';
import lawn_garden_supplies from 'assets/classifications/lawn_garden_supplies.svg';
import live_animals from 'assets/classifications/live_animals.svg';
import lubricant from 'assets/classifications/lubricant_icon.svg';
import monetary_assets from 'assets/classifications/monetary_assets.svg';
import music from 'assets/classifications/music_icon.svg';
import hand_equipment from 'assets/classifications/hand_equipment.svg';
import heating from 'assets/classifications/heating_icon.svg';
import office_machinery from 'assets/classifications/office_machinery.svg';
import personal_accessories from 'assets/classifications/personal_accessories.svg';
import petfood from 'assets/classifications/pet-food.svg';
import power_equipment from 'assets/classifications/power_equipment.svg';
import safety_protection from 'assets/classifications/safety_protection.svg';
import safety_security from 'assets/classifications/safety_security.svg';
import services from 'assets/classifications/services_icon.svg';
import sports_equipment from 'assets/classifications/sports_equipment.svg';
import storage from 'assets/classifications/storage_icon.svg';
import textual_print_material from 'assets/classifications/textual_print_material.svg';
import workshop_aids from 'assets/classifications/workshop_aids.svg';
import toy from 'assets/classifications/toy_icon.svg';
// import PackageLevelImage from 'assets/product/pallet.png';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import unitImage from 'assets/product/unit.png';
import innerpackImage from 'assets/product/innerpack.png';
import caseImage from 'assets/product/case.png';
import masterCaseImage from 'assets/product/master-case.png';
import palletImage from 'assets/product/pallet.png';

export const packageLevelIcons = [
  {
    id: 42,
    segmentId: 42,
    segmentDescription: 'Package Level: Unit',
    segmentIcon: unitImage,
    name: 'unit',
  },
  {
    id: 43,
    segmentId: 43,
    segmentDescription: 'Package Level: Inner Pack',
    segmentIcon: innerpackImage,
    name: 'inner pack',
  },
  {
    id: 44,
    segmentId: 44,
    segmentDescription: 'Package Level: Case',
    segmentIcon: caseImage,
    name: 'case',
  },
  {
    id: 45,
    segmentId: 45,
    segmentDescription: 'Package Level: Display Shipper',
    segmentIcon: caseImage,
    name: 'display shipper',
  },
  {
    id: 46,
    segmentId: 46,
    segmentDescription: 'Package Level: Master Case',
    segmentIcon: masterCaseImage,
    name: 'master case',
  },
  {
    id: 47,
    segmentId: 47,
    segmentDescription: 'Package Level: Pallet',
    segmentIcon: palletImage,
    name: 'pallet',
  },
];

const iconList = [
  {
    id: 1,
    segmentId: 1,
    segmentDescription: 'Arts/Crafts/Needlework',
    segmentIcon: arts_crafts_needlework,
  },
  {
    id: 2,
    segmentId: 2,
    segmentDescription: 'Audio Visual/Photography',
    segmentIcon: audio_visual_photography,
  },
  {
    id: 3,
    segmentId: 3,
    segmentDescription: 'Automotive',
    segmentIcon: automotive,
  },
  {
    id: 4,
    segmentId: 4,
    segmentDescription: 'Beauty/Personal Care/Hygiene',
    segmentIcon: beauty_personal_care_hygiene,
  },
  {
    id: 5,
    segmentId: 5,
    segmentDescription: 'Building Products',
    segmentIcon: building_products,
  },
  {
    id: 6,
    segmentId: 6,
    segmentDescription: 'Camping',
    segmentIcon: camping,
  },
  {
    id: 7,
    segmentId: 7,
    segmentDescription: 'Cleaning/Hygiene Products',
    segmentIcon: cleaning_hygiene_products,
  },
  {
    id: 8,
    segmentId: 8,
    segmentDescription: 'Clothing',
    segmentIcon: clothing,
  },
  {
    id: 9,
    segmentId: 9,
    segmentDescription: 'Communications',
    segmentIcon: communication,
  },
  {
    id: 10,
    segmentId: 10,
    segmentDescription: 'Computing',
    segmentIcon: computing,
  },
  {
    id: 11,
    segmentId: 11,
    segmentDescription: 'Crops',
    segmentIcon: crops,
  },
  {
    id: 12,
    segmentId: 12,
    segmentDescription: 'Cross Segment',
    segmentIcon: cross_segment,
  },
  {
    id: 13,
    segmentId: 13,
    segmentDescription: 'Electrical Supplies',
    segmentIcon: electrical_supplies,
  },
  {
    id: 14,
    segmentId: 14,
    segmentDescription: 'Food/Beverage/Tobacco',
    segmentIcon: food_beverage_tobacco,
  },
  {
    id: 15,
    segmentId: 15,
    segmentDescription: 'Footwear',
    segmentIcon: footwear,
  },
  {
    id: 16,
    segmentId: 16,
    segmentDescription: 'Fuels/Gases',
    segmentIcon: fuels_gases,
  },
  {
    id: 17,
    segmentId: 17,
    segmentDescription: 'Healthcare',
    segmentIcon: healthcare,
  },
  {
    id: 18,
    segmentId: 18,
    segmentDescription: 'Home Appliances',
    segmentIcon: home_appliances,
  },
  {
    id: 19,
    segmentId: 19,
    segmentDescription: 'Horticulture Plants',
    segmentIcon: horticulture_plants,
  },
  {
    id: 20,
    segmentId: 20,
    segmentDescription: 'Household/Office Furniture/Furnishings',
    segmentIcon: household_office_furniture_furnishings,
  },
  {
    id: 21,
    segmentId: 21,
    segmentDescription: 'Kitchenware and Tableware',
    segmentIcon: kitchenware_and_tableware,
  },
  {
    id: 22,
    segmentId: 22,
    segmentDescription: 'Lawn/Garden Supplies',
    segmentIcon: lawn_garden_supplies,
  },
  {
    id: 23,
    segmentId: 23,
    segmentDescription: 'Live Animals',
    segmentIcon: live_animals,
  },
  {
    id: 24,
    segmentId: 24,
    segmentDescription: 'Lubricants',
    segmentIcon: lubricant,
  },
  {
    id: 25,
    segmentId: 25,
    segmentDescription: 'Monetary Assets',
    segmentIcon: monetary_assets,
  },
  {
    id: 26,
    segmentId: 26,
    segmentDescription: 'Music',
    segmentIcon: music,
  },
  {
    id: 27,
    segmentId: 27,
    segmentDescription: 'Personal Accessories',
    segmentIcon: personal_accessories,
  },
  {
    id: 28,
    segmentId: 28,
    segmentDescription: 'Pet Care/Food',
    segmentIcon: petfood,
  },
  {
    id: 29,
    segmentId: 29,
    segmentDescription: 'Plumbing/Heating/Ventilation/Air Conditioning',
    segmentIcon: heating,
  },
  {
    id: 30,
    segmentId: 30,
    segmentDescription: 'Safety/Protection – DIY',
    segmentIcon: safety_protection,
  },
  {
    id: 31,
    segmentId: 31,
    segmentDescription: 'Safety/Security/Surveillance',
    segmentIcon: safety_security,
  },
  {
    id: 32,
    segmentId: 32,
    segmentDescription: 'Services',
    segmentIcon: services,
  },
  {
    id: 33,
    segmentId: 33,
    segmentDescription: 'Sports Equipment',
    segmentIcon: sports_equipment,
  },
  {
    id: 34,
    segmentId: 34,
    segmentDescription: 'Stationery/Office Machinery/Occasion Supplies',
    segmentIcon: office_machinery,
  },
  {
    id: 35,
    segmentId: 35,
    segmentDescription: 'Storage/Haulage Containers',
    segmentIcon: storage,
  },
  {
    id: 36,
    segmentId: 36,
    segmentDescription: 'Textual/Printed/Reference Materials',
    segmentIcon: textual_print_material,
  },
  {
    id: 37,
    segmentId: 37,
    segmentDescription: 'Tool Storage/Workshop Aids',
    segmentIcon: workshop_aids,
  },
  {
    id: 38,
    segmentId: 38,
    segmentDescription: 'Tools/Equipment – Hand',
    segmentIcon: hand_equipment,
  },
  {
    id: 39,
    segmentId: 39,
    segmentDescription: 'Tools/Equipment – Power',
    segmentIcon: power_equipment,
  },
  {
    id: 40,
    segmentId: 40,
    segmentDescription: 'Toys/Games',
    segmentIcon: toy,
  },
];

export const productSubscriptionIcon = {
  id: 41,
  segmentId: 41,
  segmentDescription: 'Subscribed',
};

export default iconList;
