export const GET_EMAIL_THREAD_LIST = '/api/chat/get-thread-list?GetEmail=true';
export const GET_EMAIL_THREAD_INFO = '/api/chat/get-thread-info';
export const GET_EMAIL_THREAD_INFO_CONTENT = '/api/chat/get-thread-messages';
export const GET_EMAIL_THREAD_USERS_INFO = '/api/chat/get-chat-users-info';
export const GET_EMAIL_THREAD_INFO_FILES = '/api/chat/get-files-in-thread';
export const UPLOAD_EMAIL_ATTACHMENT = '/api/chat/upload-email-attachment';
export const GET_EMAIL_ATTACHMENT = '/api/chat/get-email-attachment-info';

export const UPLOAD_FILE = '/api/chat/upload-file-or-images';
// Init Chat
export const INIT_EMAIL_COMMUNICATION = '/api/chat/init-chat';

export const SEARCH_MESSAGES = '/api/chat/search-message-in-thread';
