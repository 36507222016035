import React from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { dialogFunction } from 'common/components/index';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import RibbonButton from 'common/components/button/RibbonButton';
import Messages from 'i18n/messages/home';
import { deleteMappingGridConfigService } from 'services/mapping';

const DeleteMapping = ({ isDisabled }) => {
  const reloadPage = useDispatchReloadPage();
  const selectedMapping = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const handleDeleteModal = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the selected mapping?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDeleteMapping,
    });
  };

  const handleDeleteMapping = async () => {
    try {
      const response = await deleteMappingGridConfigService({
        mappingId: selectedMapping[0]?.id,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Delete mapping successfully!',
        });
        reloadPage();
      } else {
        notification.error({
          message: message || 'Cannot delete mapping!',
        });
      }
    } catch (error) {
      notification.error({ message: error || 'Cannot delete mapping!' });
    }
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.deleteMapping}
      onClick={handleDeleteModal}
      disabled={selectedMapping?.length !== 1 || isDisabled}
    />
  );
};

export default DeleteMapping;
