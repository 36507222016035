import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { get } from 'lodash';

import MemberTileBody from './items-new/MemberTileBody';
import MemberTileFooter from './items-new/MemberTileFooter';
import MemberTileHeader from './items-new/MemberTileHeader';

import './MemberTileCard.less';

import useDoubleClick from 'hooks/useDoubleClick';

/**
 * A component displays member information under card layout
 * @param {object} props
 */
const MemberTileCard = (props) => {
  // variables
  const { dataDetail } = props;
  const { onClickItemGrid, onDoubleClick } = props.clickEvents;

  const memberName = get(props.dataDetail, 'memberName', '');

  const divRef = React.useRef();
  useDoubleClick({
    onSingleClick: (e) => {
      if (onClickItemGrid) onClickItemGrid(dataDetail, e);
    },
    onDoubleClick: (e) => {
      if (onDoubleClick) onDoubleClick(dataDetail);
    },
    ref: divRef,
    latency: 250,
  });
  return (
    <Tooltip title={memberName}>
      <div
        style={{
          backgroundColor: 'transparent',
          position: 'absolute',
          zIndex: 30,
          width: '100%',
          height: '100%',
        }}
        ref={divRef}
      />

      <div className='member-tile'>
        <MemberTileHeader dataDetail={props.dataDetail} />
        <MemberTileBody dataDetail={props.dataDetail} />
        <MemberTileFooter dataDetail={props.dataDetail} />
      </div>
    </Tooltip>
  );
};

MemberTileCard.propTypes = {
  dataDetail: PropTypes.object,
  selected: PropTypes.bool,
  onClickItemGrid: PropTypes.func,
  onClickCheckboxItem: PropTypes.func,
};

export default MemberTileCard;
