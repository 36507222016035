import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, notification } from 'antd';
import AddCuratedQueryConfig from './AddCuratedQueryConfig';

import { getGridName } from 'utils/getGridName';

import { saveCuratedQuery } from 'services/markupMaintenance';
import * as markupActions from 'pages/markup-maintenance/controllers/actions';
import * as markupSelectors from 'pages/markup-maintenance/controllers/selectors';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import {
  useGetCuratedQueryDetail,
  useGetMembers,
  useGetAllMemberRoles,
} from './hooks';
import moment from 'moment';

import './AddCuratedQuery.less';

const PERMISSION_EDIT_CURATED_QUERY = [
  {
    action: ABILITY_ACTION.MANAGE,
    subject: ABILITY_SUBJECT.CURATED_QUERY,
  },
];

const AddCuratedQuery = (props) => {
  const { visible, setVisible, mode, selectedQueryDetail } = props;
  const hasPermissionEditCuratedQuery = useCheckPermissions(
    PERMISSION_EDIT_CURATED_QUERY
  );

  const { memberRoles } = useGetAllMemberRoles();

  const dispatch = useDispatch();

  const pageSize = useSelector(markupSelectors.selectCuratedQueryPageSize());

  const { curatedQueryData } = useGetCuratedQueryDetail({
    curatedQueryId: selectedQueryDetail[0]?.id,
    enabled:
      hasPermissionEditCuratedQuery &&
      mode === 'edit' &&
      Boolean(selectedQueryDetail[0]?.id),
  });

  const filteredMembers = useGetMembers({
    valuesFilter: curatedQueryData?.curatedQueryShareToMembers,
    enabled:
      hasPermissionEditCuratedQuery &&
      mode === 'edit' &&
      curatedQueryData?.curatedQueryShareToTotalsMembers > 0 &&
      curatedQueryData?.curatedQueryShareType === 'SpecificMembers',
  });

  const [form] = Form.useForm();

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState([]);
  const [shareType, setShareType] = useState('MemberRole');
  const [statusSubmit, setStatusSubmit] = useState('idle');

  const gridName = getGridName(window.location.pathname);

  useEffect(() => {
    form.setFieldsValue({ gridName });
  }, [form, gridName]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setSelectedMembers([]);
      setShareType('MemberRole');
    }
  }, [form, visible]);

  useEffect(() => {
    if (mode === 'edit' && selectedQueryDetail?.length > 0) {
      setShareType(selectedQueryDetail[0]?.curatedQueryShareType);

      if (selectedQueryDetail[0]?.curatedQueryShareToMemberType) {
        const curatedQueryShareToMemberRoleId =
          memberRoles.find(
            (role) =>
              role?.memberRoleName ===
              selectedQueryDetail[0]?.curatedQueryShareToMemberType
          )?.memberRoleId ?? null;

        form.setFieldsValue({
          curatedQueryShareToMemberRoleId,
        });
      }
    }
  }, [selectedQueryDetail, form, mode, visible, memberRoles]);

  useEffect(() => {
    if (hasPermissionEditCuratedQuery && visible) {
      form.setFieldsValue({
        //TODO: DATETIME
        dateActive: [
          curatedQueryData?.effectedDate
            ? moment(curatedQueryData?.effectedDate)
            : null,
          curatedQueryData?.effectedDate
            ? moment(curatedQueryData?.expirationDate)
            : null,
        ],
        curatedQueryShareToMemberType:
          curatedQueryData?.curatedQueryShareToMemberType,
      });
    }
  }, [
    hasPermissionEditCuratedQuery,
    form,
    visible,
    curatedQueryData.effectedDate,
    curatedQueryData.expirationDate,
    curatedQueryData.curatedQueryShareToMemberType,
  ]);

  useEffect(() => {
    if (hasPermissionEditCuratedQuery && filteredMembers.length > 0) {
      setSelectedMembers(filteredMembers);
    }
  }, [filteredMembers, hasPermissionEditCuratedQuery]);

  const handleCreateConfig = async () => {
    const { dateActive, ...values } = await form.validateFields();

    setStatusSubmit('loading');

    const submitId =
      selectedQueryDetail?.length > 0
        ? selectedQueryDetail[0]?.id
        : selectedQuery?.length > 0 && selectedQuery[0];

    let params = {
      ...values,
      id: submitId,
      gridName,
    };

    const { curatedQueryShareType, curatedQueryShareToMemberRoleId } = values;

    if (curatedQueryShareType === 'MemberRole') {
      if (curatedQueryShareToMemberRoleId) {
        params = {
          ...params,
          curatedQueryShareToMemberRoleId,
          curatedQueryShareToMemberType:
            memberRoles.find(
              (role) => role.memberRoleId === curatedQueryShareToMemberRoleId
            )?.memberRoleName ?? null,
        };
      }
      params = {
        ...params,
        curatedQueryShareType,
        curatedQueryShareToMembers: [],
      };
    }

    if (curatedQueryShareType === 'SpecificMembers') {
      params = {
        ...params,
        curatedQueryShareType,
        curatedQueryShareToMembers: selectedMembers.map((member) => member.id),
      };
    }

    if (curatedQueryShareType === 'AllMember') {
      params = {
        ...params,
        curatedQueryShareType,
        curatedQueryShareToMemberType: null,
        curatedQueryShareToMembers: [],
      };
    }

    if (dateActive) {
      params = {
        ...params,
        effectedDate: dateActive[0]?.toISOString(),
        expirationDate: dateActive[1]?.toISOString(),
      };
    } else {
      params = {
        ...params,
        effectedDate: null,
        expirationDate: null,
      };
    }

    try {
      const response = await saveCuratedQuery(params);
      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Save curated query successfully!',
        });
        form.resetFields();
        setVisible(false);
        setStatusSubmit('error');

        setTimeout(() => {
          dispatch(markupActions.getCuratedQueryList(1, pageSize, ''));
        }, 200);
      } else {
        setStatusSubmit('error');

        notification.error({
          message: message || 'Cannot save curated query!',
        });
      }
    } catch (error) {
      setStatusSubmit('error');
      notification.error({ message: error || 'Cannot save curated query!' });
    }
  };

  return (
    <>
      {visible && (
        <Modal
          title={mode === 'edit' ? 'Edit Curated Query' : 'New Curated Query'}
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          destroyOnClose
          width={1100}
          maskClosable={false}
          className='modal-create-query'
          footer={[
            <Button
              key='save'
              type='primary'
              disabled={mode === 'create' && selectedQuery?.length !== 1}
              onClick={handleCreateConfig}
              loading={statusSubmit === 'loading'}
            >
              Submit
            </Button>,
          ]}
        >
          <AddCuratedQueryConfig
            form={form}
            selectedQueryDetail={selectedQueryDetail}
            selectedQuery={selectedQuery}
            setSelectedQuery={setSelectedQuery}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            shareType={shareType}
            setShareType={setShareType}
            memberRoles={memberRoles}
            mode={mode}
          />
        </Modal>
      )}
    </>
  );
};

AddCuratedQuery.propTypes = {};

export default AddCuratedQuery;
