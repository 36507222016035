import { useMutation } from '@tanstack/react-query';

import * as loginServices from 'services/login';
import * as userServices from 'services/user';

const DEFAULT_OPTIONS = { isReturnData: true, alternateMessage: '' };

const handleResponse = (
  response,
  { isReturnData = true, alternateMessage = '' } = DEFAULT_OPTIONS
) => {
  const { isSuccess, message, data } = response || {};

  if (isSuccess) {
    return Promise.resolve(isReturnData ? data : true);
  } else {
    const errorMessage = message || alternateMessage;
    return Promise.reject(errorMessage);
  }
};

export const useGetOtpForUnlockAccountByEmail = () => {
  return useMutation({
    mutationFn: async ({ userName, email }) => {
      const response = await loginServices.unlockAccountSendOtpEmail({
        userName,
        email,
      });

      return handleResponse(response, {
        alternateMessage: 'Server failed to send OTP',
      });
    },
  });
};

export const useGetOtpForUnlockAccountByPhone = () => {
  return useMutation({
    mutationFn: async ({ userName, mfaPhoneNumber }) => {
      const response = await loginServices.unlockAccountSendOtpPhone({
        userName,
        mfaPhoneNumber,
      });

      return handleResponse(response, {
        alternateMessage: 'Server failed to send OTP',
      });
    },
  });
};

export const useGetOtpForNewMfaPhoneUserProfile = () => {
  return useMutation({
    mutationFn: async ({ userId, mfaPhoneNumber }) => {
      const response = await userServices.sendOtpToNewMfaPhoneUserProfile({
        userId,
        mfaPhoneNumber,
      });

      return handleResponse(response, {
        alternateMessage: 'Server failed to send OTP',
      });
    },
  });
};

export const useGetOtpForLogin = () => {
  return useMutation({
    mutationFn: async ({ loginSession, mfaPhoneNumber }) => {
      let params = mfaPhoneNumber
        ? {
            loginSession,
            mfaPhoneNumber,
          }
        : { loginSession };

      const response = await loginServices.loginSendOtp(params);

      return handleResponse(response, {
        isReturnData: false,
        alternateMessage: 'Server failed to send OTP',
      });
    },
  });
};

export const useVerifyOtpForNewMfaPhoneUserProfile = () => {
  return useMutation({
    mutationFn: async ({ mfaSession, verificationCode }) => {
      const response = await userServices.verifyOtpForNewMfaPhoneUserProfile({
        mfaSession,
        verificationCode,
      });

      return handleResponse(response, {
        isReturnData: false,
        alternateMessage: 'Wrong OTP',
      });
    },
  });
};

export const useVerifyOtpForLogin = () => {
  return useMutation({
    mutationFn: async ({
      loginSession,
      verificationCode,
      rememberMe,
      browserHash,
    }) => {
      const params = browserHash
        ? {
            loginSession,
            verificationCode,
            rememberMe,
            browserHash,
          }
        : {
            loginSession,
            verificationCode,
          };

      const response = await loginServices.loginCheckOtp(params);

      return handleResponse(response, {
        alternateMessage: 'Wrong OTP',
      });
    },
  });
};

export const useVerifyOtpForUnlockAccount = () => {
  return useMutation({
    mutationFn: async ({ mfaSession, verificationCode }) => {
      const response = await loginServices.unlockAccountCheckOtp({
        mfaSession,
        verificationCode,
      });

      return handleResponse(response, {
        alternateMessage: 'Wrong OTP',
      });
    },
  });
};
