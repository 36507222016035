import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyOutlined } from '@ant-design/icons';
import { notification } from 'antd';

import RibbonButton from 'common/components/button/RibbonButton';
import * as wfTemplateGridSelectors from 'pages/workflow-template/controllers/selectors';
import * as actionsWf from 'pages/workflow-template/controllers/actions';
import * as services from 'services/wfTemplate';

const CloneWorkflow = (props) => {
  const dispatch = useDispatch();

  const listSelected = useSelector(
    wfTemplateGridSelectors.makeSelectItemsSelected()
  );

  const onCloneWorkflow = () => {
    const params = {
      workflowTemplateId: listSelected[0].id,
    };
    services
      .cloneWorkFlow(params)
      .then((response) => {
        if (response?.isSuccess) {
          dispatch(actionsWf.reloadGridWf(true));
          dispatch(actionsWf.setWfItemSelected([]));
          notification.success({ message: 'Clone Workflow Template success' });
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setTimeout(() => {
          dispatch(actionsWf.reloadGridWf(false));
        });
      });
  };

  return (
    <RibbonButton
      icon={<CopyOutlined />}
      label='Clone Workflow'
      onClick={onCloneWorkflow}
      disabled={!(listSelected.length === 1)}
      style={{ width: 110 }}
    />
  );
};

export default CloneWorkflow;
