import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import ResultPage from './ResultPage';

import { getParamsFromUrl } from 'utils/common/route';

const INTEROP_KEY = 'interop_key';

const DropboxCallback = () => {
	const location = useLocation();
	const { code, state } = getParamsFromUrl(location);

	const isSuccess = code && state;

	useEffect(() => {
		if (code && state) {
			localStorage.setItem(
				INTEROP_KEY,
				JSON.stringify({
					code,
					state,
					status: 'Connected',
				})
			);
		}
	}, [code, state]);

	const title = isSuccess ? 'Authorized' : 'Unauthorized';
	const message = isSuccess
		? 'Your request has been authorized successfully.'
		: 'Your request has been authorized unsuccessfully';

	return (
		<div>
			<ResultPage
				isSuccess={isSuccess}
				title={title}
				message={message}
				btn='Close'
				onBtnClick={() => window.close()}
			/>
		</div>
	);
};

export default DropboxCallback;
