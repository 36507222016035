import React from 'react';

import SnapshotCellWrapper from './SnapshotCellWrapper';
import FileUploadRender from './FileUploadRender';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import { isEqual } from 'lodash';

const FileUploadRenderSnapshot = (props) => {
  const { snapshotValueRender, ...params } = props;
  const { column, rowIndex, data, context } = params;
  const { snapshotGridValues } = context || {};

  const useSnapshot = useCheckSnapshotForRetailer();

  const cellSnapshotValue = snapshotGridValues?.[rowIndex]?.[column.colId];
  const rawCellValue = data?.[column.colId];

  const defaultSnapshotValueRender = cellSnapshotValue ? (
    <FileUploadRender value={cellSnapshotValue} />
  ) : (
    'N/A'
  );

  const isHighlightChange =
    !isEqual(rawCellValue?.digitalAssetId, cellSnapshotValue?.digitalAssetId) &&
    useSnapshot;

  return (
    <SnapshotCellWrapper
      isHighlightChange={isHighlightChange}
      snapshotValueRender={snapshotValueRender ?? defaultSnapshotValueRender}
    >
      <FileUploadRender {...params} />
    </SnapshotCellWrapper>
  );
};

export default FileUploadRenderSnapshot;
