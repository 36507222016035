export const ALLOW_CHARS = /^.*$/;

export const VIMEO_REGEX =
  /\/\/(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/;

export const YOUTUBE_REGEX =
  /^(?:(https?):\/\/)?(?:(?:www|m)\.)?(?:youtube\.com\/watch.*v=|youtu\.be\/)([a-zA-Z0-9_-]+)/;

export const DATA_ATTR = ['id', 'value', 'denotationChar', 'link', 'target'];

export const MENTION_DENOTATION_CHARS = ['@', '#'];

export const taskFields = [
  {
    label: 'Status',
    name: 'status',
    option: [
      {
        value: 'Open',
        label: 'Open',
      },
      {
        value: 'Pending',
        label: 'Pending',
      },
      {
        value: 'Solved',
        label: 'Solved',
      },
    ],
  },
  {
    label: 'Priority',
    name: 'priority',
    option: [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ],
  },
  {
    label: 'Type',
    name: 'type',
    option: [
      {
        value: 'Inquiry',
        label: 'Inquiry',
      },
      {
        value: 'Complaint',
        label: 'Complaint',
      },
    ],
  },
  {
    label: 'Category',
    name: 'category',
    option: [
      { value: 'Compliment', label: 'Compliment' },
      { value: 'Complaint', label: 'Complaint' },
      { value: 'Inquiry', label: 'Inquiry' },
    ],
  },
  {
    label: 'Contact Method',
    name: 'contactMethod',
    option: [
      { value: 'Company Website', label: 'Company Website' },
      { value: 'Email', label: 'Email' },
      { value: 'Internal', label: 'Internal' },
      { value: 'Letter', label: 'Letter' },
      { value: 'Phone Call', label: 'Phone Call' },
      { value: 'Social Media', label: 'Social Media' },
    ],
  },
  {
    label: 'Severity',
    name: 'severity',
    option: [
      { value: 'High Risk / Medical', label: 'High Risk / Medical' },
      { value: 'Medium', label: 'Medium' },
      { value: 'Low', label: 'Low' },
    ],
  },
  {
    label: 'Sub Type',
    name: 'subtype',
    option: [
      {
        value: 'General',
        label: 'General',
      },
    ],
  },
];

export const SUBTYPES_INQUIRY = [
  { value: 'Product Claims', label: 'Product Claims' },
  {
    value: 'Allergens',
    label: 'Allergens',
  },
  {
    value: 'Sustainability',
    label: 'Sustainability',
  },
  {
    value: 'Sourcing',
    label: 'Sourcing',
  },
  {
    value: 'Packaging',
    label: 'Packaging',
  },
  {
    value: 'Recyclability',
    label: 'Recyclability',
  },
  {
    value: 'Other',
    label: 'Other',
  },
  {
    value: 'Product Availability',
    label: 'Product Availability',
  },
];

export const SUBTYPES_COMPLAINT_LOW_SEVERITY = [
  { value: 'Packaging Issues', label: 'Packaging Issues' },
  {
    value: "Doesn't perform as expected",
    label: "Doesn't perform as expected",
  },
  {
    value: 'Damaged',
    label: 'Damaged',
  },
  {
    value: 'Missing Components',
    label: 'Missing Components',
  },
  {
    value: 'Website Issues',
    label: 'Website Issues',
  },
  {
    value: 'Coupons/Pricing',
    label: 'Coupons/Pricing',
  },
  {
    value: 'Portion/Sizing',
    label: 'Portion/Sizing',
  },
  {
    value: 'Quality Issues',
    label: 'Quality Issues',
  },
];

export const SUBTYPES_COMPLAINT_HIGH_SEVERITY = [
  { value: 'Physical Harm/Illness', label: 'Physical Harm/Illness' },
  {
    value: 'Allergic Reaction',
    label: 'Allergic Reaction',
  },
  {
    value: 'Foreign Materials',
    label: 'Foreign Materials',
  },
  {
    value: 'Insect Infestation',
    label: 'Insect Infestation',
  },
];

export const INITIAL_FIELD_VALUE = {
  type: 'Complaint',
  subtype: null,
  description: null,
  status: 'Open',
  contactMethod: 'Letter',
  priority: 2,
  category: 'Complaint',
  severity: 'High Risk / Medical',
  tags: [],
  assignedTo: null,
  assignedType: null,
  ccEmails: [],
  customerFirstName: null,
  customerLastName: null,
  customerEmailAddress: null,
  customerStreetAddress: null,
  customerCity: null,
  customerState: null,
  customerZipCode: null,
  customerPhoneNumber: null,
  isSupplierContact: false,
  supplierContactName: null,
  saleContactName: null,
  supplierResponse: null,
};

export const CHANGELOG_ITEM_LAYOUTS = {
  ticketHistoryAssets: { span: 12 },
  ticketHistoryAttachments: { span: 12 },
  ticketHistoryDescription: { span: 24 },
  ticketHistoryDetails: { span: 12 },
  ticketHistoryProduct: { span: 12 },
  CcEmails: { span: 24 },
};

export const CHANGELOG_DETAIL_PROPERTIES = [
  'Title',
  'AssignedTo',
  'AssignedType',
  'Tags',
  'Status',
  'ContactMethod',
  'Priority',
  'Severity',
  'Category',
  'Type',
  'Subtype',
  'CcEmails',
  'LotCode',
  'Provided',
  'DollarValue',
];

export const CHANGELOG_CONTACT_PROPERTIES = [
  'CustomerFirstName',
  'CustomerLastName',
  'CustomerEmailAddress',
  'CustomerStreetAddress',
  'CustomerCity',
  'CustomerState',
  'CustomerZipCode',
  'CustomerPhoneNumber',
  'IsSupplierContact',
  'SupplierContactName',
  'SaleContactName',
  'SupplierResponse',
];

export const CHANGELOG_LIST_TYPE_PROPERTY = ['CcEmails, Tags'];

export const MAXIMUM_UPLOAD_IMAGE = 50000000;

export const ERROR_FIELDS_WITH_TAB_MAP = {
  customerEmailAddress: 'contacts',
};
