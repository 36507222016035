import { Col, Empty, Row } from 'antd';
import { InfoWithLabel, StyledModal } from 'common/components';
import React from 'react';

import './CalculatedPropertiesModal.less';
import { volumeProperties, weightProperties } from './Contants';

const styleProperties = {
  labelAlign: 'right',
  labelColProps: {
    flex: '180px',
  },
  infoColProps: {
    flex: 1,
  },
};

const CalculatedPropertiesModal = ({
  visible,
  setVisible,
  productDetail,
  primarySize,
}) => {
  const { eCommerceProductVolume, eCommerceProductWeight, primarySizeText } =
    productDetail ?? {};

  const calculatedProperties = (() => {
    let properties = [];
    if (eCommerceProductVolume) properties.push(...volumeProperties);
    if (eCommerceProductWeight) properties.push(...weightProperties);

    const mappingInfo = properties?.map((item) => {
      return {
        label: item?.label,
        info: productDetail[item?.key],
        className: item?.className,
      };
    });

    const eCommerceSize = {
      label: 'e-Commerce Size',
      info: (
        <>
          {eCommerceProductVolume || eCommerceProductWeight ? (
            <>
              <p>{eCommerceProductVolume}</p>
              <p>{eCommerceProductWeight}</p>
            </>
          ) : (
            <p>{primarySize}</p>
          )}
        </>
      ),
      className: 'calculated-property-content_e-Commerce-Size',
    };

    return [...mappingInfo, eCommerceSize];
  })();

  const propsStyleModal = {
    visible: visible,
    title: 'Weight & Volume Calculated Properties',
    wrapClassName: 'calculated-property-modal',
    onCancel: () => {
      setVisible(false);
    },
    width: '700px',
    bodyStyle: {
      height: '300px',
    },
    maskClosable: false,
    destroyOnClose: true,
  };

  return (
    <StyledModal {...propsStyleModal}>
      <Row className='calculated-property-modal_wrapper'>
        <Col span={12} className='calculated-property-content'>
          <div className='calculated-property-content_value'>
            {calculatedProperties.map((item, index) => {
              const props = {
                ...item,
                ...styleProperties,
              };
              return <InfoWithLabel key={index} {...props} />;
            })}
          </div>
          <div>
            <p className='calculated-property-content_size-text-label'>
              Product Size Text:
            </p>
            <p className='calculated-property-content_size-text'>
              {primarySizeText ? (
                primarySizeText
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </p>
          </div>
        </Col>
        <Col span={12} className='calculated-property_description'>
          <p>
            To help provide better visibility into the product in both imperial
            and metric declarations for contents, we've completed the
            calculations for you here. To best meet your organization's needs,
            and maintain accordance with the U.S and international labeling
            requirements for net content of a consumer food product, we present
            both options. Typically, fluid product will be measured in volume
            (gallons, liters, fluid ounces) and solid product will be measured
            in weight (pounds, grams, ounces).
          </p>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default CalculatedPropertiesModal;
