import * as types from './constants';

import { formatColumns, formatFieldsName } from '../utils';
import { isUndefined, isBoolean, pickBy } from 'lodash';

export function getProductList(
  pageSize,
  pageIndex,
  search,
  filters,
  advancedSearchContainer,
  searchCategory,
  isFavoriteRoute,
  packageLevels,
  fromDate = null
) {
  return {
    type: types.GET_PRODUCT_LIST,
    pageSize,
    pageIndex,
    search,
    filters,
    advancedSearchContainer,
    searchCategory,
    isFavoriteRoute,
    packageLevels,
    fromDate,
  };
}

export function getMemberProductList({
  pageSize,
  pageIndex,
  search,
  filters,
  advancedSearchContainer,
  searchCategory,
  packageLevels,
  isFavoriteRoute,
  fromDate = null,
  memberId,
}) {
  return {
    type: types.GET_MEMBER_PRODUCT_LIST,
    pageSize,
    pageIndex,
    search,
    filters,
    advancedSearchContainer,
    searchCategory,
    packageLevels,
    isFavoriteRoute,
    fromDate,
    memberId,
  };
}

export function getProductListSuccess(products, total) {
  return {
    type: types.GET_PRODUCT_LIST_SUCCESS,
    products,
    total,
  };
}

export function getProductListError(error) {
  return {
    type: types.GET_PRODUCT_LIST_ERROR,
    error,
  };
}

export function gridColumnInfo(gridName) {
  return {
    type: types.PRODUCT_GRID_COLUMN_INFO,
    gridName,
  };
}

export function gridColumnInfoSuccess(columns) {
  return {
    type: types.PRODUCT_GRID_COLUMN_INFO_SUCCESS,
    columns,
  };
}

export function gridColumnInfoError(error) {
  return {
    type: types.PRODUCT_GRID_COLUMN_INFO_ERROR,
    error,
  };
}

export function toggleReloadGridProduct(value = true) {
  return {
    type: types.TOGGLE_RELOAD_GRID_PRODUCT,
    payload: value,
  };
}

export function saveGridConfig(payload) {
  const { selectedColumns, jsonConfig, isHaveDefaultConfig } = payload;

  let nextPayload = {
    selectedColumns: isUndefined(selectedColumns) ? undefined : selectedColumns,
    allConfigFieldsName: isUndefined(jsonConfig)
      ? undefined
      : formatFieldsName(jsonConfig),
    defaultColumnsGrid: isUndefined(jsonConfig)
      ? undefined
      : formatColumns(jsonConfig),
    isConfigEmpty: isUndefined(selectedColumns)
      ? undefined
      : selectedColumns === null
      ? true
      : false,
    isHaveDefaultConfig: isBoolean(isHaveDefaultConfig)
      ? isHaveDefaultConfig
      : undefined,
  };

  nextPayload = pickBy(nextPayload, (value) => value !== undefined);

  return {
    type: types.SAVE_GRID_CONFIG,
    payload: nextPayload,
  };
}
export function applyGridConfig(data) {
  return {
    type: types.APPLY_GRID_CONFIG,
    payload: data,
  };
}
// Add Product
export function addProduct(params) {
  return {
    type: types.ADD_PRODUCT,
    params,
  };
}

export function addProductSuccess(data) {
  return {
    type: types.ADD_PRODUCT_SUCCESS,
    data,
  };
}

export function addProductError(params) {
  return {
    type: types.ADD_PRODUCT_ERROR,
    params,
  };
}

export const getProductBrandImageOnAdd = (payload) => {
  return {
    type: types.GET_PRODUCT_BRAND_ON_ADD,
    payload,
  };
};
export const getProductBrandImageOnAddSuccess = (payload) => {
  return {
    type: types.GET_PRODUCT_BRAND_ON_ADD_SUCCESS,
    payload,
  };
};
export const getProductBrandImageOnAddError = (payload) => ({
  type: types.GET_PRODUCT_BRAND_ON_ADD_ERROR,
  payload,
});

export const toggleProductNew = (status) => ({
  type: types.TOGGLE_PRODUCT_NEW,
  payload: status,
});

export function getGDSNPartyList(gridName) {
  return {
    type: types.GET_GDSN_PARTY_LIST,
    gridName,
  };
}

export function getGDSNPartyListSuccess(columns) {
  return {
    type: types.GET_GDSN_PARTY_LIST_SUCCESS,
    columns,
  };
}

export function getGDSNPartyListFailure(error) {
  return {
    type: types.GET_GDSN_PARTY_LIST_FAILURE,
    error,
  };
}

export const toggleProductUnmatch = (status) => ({
  type: types.TOGGLE_PRODUCT_UNMATCH,
  payload: status,
});
