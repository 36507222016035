import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { RIBBON_VIEW, RIBBON_OPTIONS, DEFAULT_OPTIONS } from 'static/Constants';
import Home from 'pages/home/ribbon/Home';
import HomeBlank from 'pages/home/ribbon/HomeBlank';
import Shared from 'pages/home/ribbon/Shared';
import Products from 'pages/home/ribbon/Products';
import MemberGrid from 'pages/home/ribbon/MemberGrid';
import AssetsGrid from 'pages/home/ribbon/AssetsGrid';

import { ribbonViewRouter } from 'utils/ribbonRouter';

/**
 * Direct selector to the language domain
 */

const ribbon = (state) => (state && state.ribbon) || initialState;

/**
 * get ribbon loading state
 */
const selectRibbonLoading = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.loading);

const selectRibbonView = () =>
  createSelector(ribbon, (ribbonState) => {
    switch (ribbonState.view) {
      case DEFAULT_OPTIONS.HOME.value:
        return Home;
      case RIBBON_VIEW.HOME_BLANK.NAME:
        return RIBBON_VIEW.HOME_BLANK.VIEW;
      // case DEFAULT_OPTIONS.SHARED.value:
      //   return Shared;
      case RIBBON_OPTIONS.IXONE.value:
        return RIBBON_VIEW.IXONE_VIEW.VIEW;
      case RIBBON_OPTIONS.PRODUCT.value:
        return Products;
      case RIBBON_OPTIONS.MEMBER.value:
        return MemberGrid;
      case RIBBON_OPTIONS.ASSET.value:
        return AssetsGrid;
      case RIBBON_VIEW.MEMBER_COMPANY_FULL_VIEW.NAME:
        return RIBBON_VIEW.MEMBER_COMPANY_FULL_VIEW.VIEW;
      case RIBBON_VIEW.MEMBER_FULL_VIEW.NAME:
        return RIBBON_VIEW.MEMBER_FULL_VIEW.VIEW;
      case RIBBON_VIEW.USER_FULL_VIEW.NAME:
        return RIBBON_VIEW.USER_FULL_VIEW.VIEW;
      case RIBBON_VIEW.WORKFLOW_GRID_VIEW.NAME:
        return RIBBON_VIEW.WORKFLOW_GRID_VIEW.VIEW;
      case RIBBON_VIEW.ASSET_FULL_VIEW.NAME:
        return RIBBON_VIEW.ASSET_FULL_VIEW.VIEW;
      case RIBBON_VIEW.FOLDER_VIEW.NAME:
        return RIBBON_VIEW.FOLDER_VIEW.VIEW;
      case RIBBON_VIEW.OWNED_FOLDER_VIEW.NAME:
        return RIBBON_VIEW.OWNED_FOLDER_VIEW.VIEW;
      case RIBBON_VIEW.SHARED_FOLDER_VIEW.NAME:
        return RIBBON_VIEW.SHARED_FOLDER_VIEW.VIEW;
      case RIBBON_VIEW.HELP_MAINTENANCE_CATEGORY_VIEW.NAME:
        return RIBBON_VIEW.HELP_MAINTENANCE_CATEGORY_VIEW.VIEW;
      case RIBBON_VIEW.HELP_POST_FULL_VIEW.NAME:
        return RIBBON_VIEW.HELP_POST_FULL_VIEW.VIEW;
      case RIBBON_VIEW.HELP_POST_GRID_VIEW.NAME:
        return RIBBON_VIEW.HELP_POST_GRID_VIEW.VIEW;
      case RIBBON_VIEW.MAINTENANCE_EULA_GRID_VIEW.NAME:
        return RIBBON_VIEW.MAINTENANCE_EULA_GRID_VIEW.VIEW;
      case RIBBON_VIEW.MAINTENANCE_EULA_FULL_VIEW.NAME:
        return RIBBON_VIEW.MAINTENANCE_EULA_FULL_VIEW.VIEW;
      case RIBBON_VIEW.ADVANCED_SEARCH_VIEW.NAME:
        return RIBBON_VIEW.ADVANCED_SEARCH_VIEW.VIEW;
      case RIBBON_VIEW.REPORTING_VIEW.NAME:
        return RIBBON_VIEW.REPORTING_VIEW.VIEW;
      case RIBBON_VIEW.HELP_ARTICLES_GRID_VIEW.NAME:
        return RIBBON_VIEW.HELP_ARTICLES_GRID_VIEW.VIEW;
      case RIBBON_VIEW.HELP_ARTICLES_FULL_VIEW.NAME:
        return RIBBON_VIEW.HELP_ARTICLES_FULL_VIEW.VIEW;
      case RIBBON_VIEW.COMMUNICATION_VIEW.NAME:
        return RIBBON_VIEW.COMMUNICATION_VIEW.VIEW;
      case RIBBON_VIEW.COMMUNICATION_FULLVIEW.NAME:
        return RIBBON_VIEW.COMMUNICATION_FULLVIEW.VIEW;
      case RIBBON_VIEW.MEMBER_MAINTENANCE_VIEW.NAME:
        return RIBBON_VIEW.MEMBER_MAINTENANCE_VIEW.VIEW;
      case RIBBON_VIEW.USERS_MAINTENANCE_VIEW.NAME:
        return RIBBON_VIEW.USERS_MAINTENANCE_VIEW.VIEW;
      case RIBBON_VIEW.QUERY_VIEW.NAME:
        return RIBBON_VIEW.QUERY_VIEW.VIEW;
      case RIBBON_VIEW.WORKFLOW_MAINTAIN.NAME:
        return RIBBON_VIEW.WORKFLOW_MAINTAIN.VIEW;
      case RIBBON_VIEW.ASSET_FOR_MEMBER.NAME:
        return RIBBON_VIEW.ASSET_FOR_MEMBER.VIEW;
      case RIBBON_VIEW.WF_TEMPLATE_FULL.NAME:
        return RIBBON_VIEW.WF_TEMPLATE_FULL.VIEW;
      case RIBBON_VIEW.WORKFLOW_ADMINISTRATION_GRID_VIEW.NAME:
        return RIBBON_VIEW.WORKFLOW_ADMINISTRATION_GRID_VIEW.VIEW;
      case RIBBON_VIEW.WORKFLOW_DETAIL_VIEW.NAME:
        return RIBBON_VIEW.WORKFLOW_DETAIL_VIEW.VIEW;
      case RIBBON_VIEW.PRODUCT_FOR_MEMBER.NAME:
        return RIBBON_VIEW.PRODUCT_FOR_MEMBER.VIEW;
      case RIBBON_VIEW.PRODUCT_FULL_VIEW.NAME:
        return RIBBON_VIEW.PRODUCT_FULL_VIEW.VIEW;
      case RIBBON_VIEW.PRODUCT_SUBSCRIPTION.NAME:
        return RIBBON_VIEW.PRODUCT_SUBSCRIPTION.VIEW;
      case RIBBON_VIEW.PRODUCT_HISTORY.NAME:
        return RIBBON_VIEW.PRODUCT_HISTORY.VIEW;
      case RIBBON_VIEW.MAINTENANCE_MARKUP_GRID_VIEW.NAME:
        return RIBBON_VIEW.MAINTENANCE_MARKUP_GRID_VIEW.VIEW;
      case RIBBON_VIEW.MAINTENANCE_MARKUP_FULL_VIEW.NAME:
        return RIBBON_VIEW.MAINTENANCE_MARKUP_FULL_VIEW.VIEW;
      case RIBBON_VIEW.PRODUCT_APL.NAME:
        return RIBBON_VIEW.PRODUCT_APL.VIEW;
      case RIBBON_VIEW.PRODUCT_APL_DETAIL.NAME:
        return RIBBON_VIEW.PRODUCT_APL_DETAIL.VIEW;
      case RIBBON_VIEW.MY_SUBSCRIPTION_GRID_VIEW.NAME:
        return RIBBON_VIEW.MY_SUBSCRIPTION_GRID_VIEW.VIEW;
      case RIBBON_VIEW.SUBSCRIPTION_TO_GRID_VIEW.NAME:
        return RIBBON_VIEW.SUBSCRIPTION_TO_GRID_VIEW.VIEW;
      case RIBBON_VIEW.CURATED_QUERY_GRID_VIEW.NAME:
        return RIBBON_VIEW.CURATED_QUERY_GRID_VIEW.VIEW;
      case RIBBON_VIEW.PRODUCT_MAPPING.NAME:
        return RIBBON_VIEW.PRODUCT_MAPPING.VIEW;
      case RIBBON_VIEW.PRODUCT_MAPPING_CONFIG.NAME:
        return RIBBON_VIEW.PRODUCT_MAPPING_CONFIG.VIEW;
      case RIBBON_VIEW.PRODUCT_SHEETS.NAME:
        return RIBBON_VIEW.PRODUCT_SHEETS.VIEW;
      case RIBBON_VIEW.PUBLICATION_GRID_VIEW.NAME:
        return RIBBON_VIEW.PUBLICATION_GRID_VIEW.VIEW;
      case RIBBON_VIEW.ROLE_SECURITY_MANAGE_VIEW.NAME:
        return RIBBON_VIEW.ROLE_SECURITY_MANAGE_VIEW.VIEW;
      case RIBBON_VIEW.RECIPIENTS_FIELDS_GRID_VIEW.NAME:
        return RIBBON_VIEW.RECIPIENTS_FIELDS_GRID_VIEW.VIEW;
      case RIBBON_VIEW.PRODUCT_SYNDICATION_HISTORY.NAME:
        return RIBBON_VIEW.PRODUCT_SYNDICATION_HISTORY.VIEW;

      case RIBBON_VIEW.REQUEST_GRID.NAME:
        return RIBBON_VIEW.REQUEST_GRID.VIEW;

      case RIBBON_VIEW.TRANSITION_PAGE.NAME:
        return RIBBON_VIEW.TRANSITION_PAGE.VIEW;
      case RIBBON_VIEW.TRANSITION_PAGE_DETAIL.NAME:
        return RIBBON_VIEW.TRANSITION_PAGE_DETAIL.VIEW;

      case RIBBON_VIEW.DASHBOARD.NAME:
        return RIBBON_VIEW.DASHBOARD.VIEW;

      case RIBBON_VIEW.SSO_APPROVAL_MEMBER.NAME:
        return RIBBON_VIEW.SSO_APPROVAL_MEMBER.VIEW;
      case RIBBON_VIEW.SSO_APPROVAL_USER.NAME:
        return RIBBON_VIEW.SSO_APPROVAL_USER.VIEW;
      case RIBBON_VIEW.FOLDER_DETAIL.NAME:
        return RIBBON_VIEW.FOLDER_DETAIL.VIEW;
      case RIBBON_VIEW.FORM_MANAGE.NAME:
        return RIBBON_VIEW.FORM_MANAGE.VIEW;
      case RIBBON_VIEW.FORM_UPLOAD.NAME:
        return RIBBON_VIEW.FORM_UPLOAD.VIEW;
      case RIBBON_VIEW.FORM_EXCEL.NAME:
        return RIBBON_VIEW.FORM_EXCEL.VIEW;
      case RIBBON_VIEW.FORM_MANAGE_DETAIL.NAME:
        return RIBBON_VIEW.FORM_MANAGE_DETAIL.VIEW;
      case RIBBON_VIEW.FORM_HISTORY_ENTITY.NAME:
        return RIBBON_VIEW.FORM_HISTORY_ENTITY.VIEW;
      case RIBBON_VIEW.TICKETING_SYSTEM.NAME:
        return RIBBON_VIEW.TICKETING_SYSTEM.VIEW;
        case RIBBON_VIEW.TICKETING_GROUP.NAME:
          return RIBBON_VIEW.TICKETING_GROUP.VIEW;
      case RIBBON_VIEW.QAS_REQUIRE_FIELD.NAME:
        return RIBBON_VIEW.QAS_REQUIRE_FIELD.VIEW;
      case RIBBON_VIEW.NONE.NAME:
        return null;
      default:
        return ribbonViewRouter(ribbonState.view);
    }
  });
const selectRibbon = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.view);
const selectRibbonError = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.error);
const selectRibbonType = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.typeView);
const selectRibbonHeight = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.heightRibbon);
const selectSaveClick = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.saveClick);
const selectMemberLayout = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.layout);
const selectShowBanList = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.showBanList);

// back route
const selectCanBackRoute = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.canBackRoute);

const selectCloseDetailStack = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.closeDetailStack);

const selectStoredDetailStack = () =>
  createSelector(ribbon, (ribbonState) => ribbonState.storedCloseDetailStack);

export {
  selectRibbon,
  selectRibbonLoading,
  selectRibbonView,
  selectRibbonError,
  selectRibbonType,
  selectRibbonHeight,
  selectSaveClick,
  selectCanBackRoute,
  selectMemberLayout,
  selectShowBanList,
  selectCloseDetailStack,
  selectStoredDetailStack,
};
