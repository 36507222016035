import React from 'react';

import classnames from 'classnames';
import { Typography, Empty, Row } from 'antd';
import { WithLoading } from 'common/components';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/common';

import './DataList.less';

const { Text } = Typography;

const DataDisplay = (props) => {
  const intl = useIntl();
  const { loading, error, data, style, classNameObj, children } = props;

  return (
    <div
      className={classnames({
        'data-list-comp': true,
        ...classNameObj,
      })}
      style={style}
    >
      {loading ? (
        <div className='data-list-comp__loading'>
          <WithLoading loading={loading} />
        </div>
      ) : error ? (
        <div className='data-list-comp__error'>
          <Text type='secondary'>
            {error ? error : intl.formatMessage(Messages.listErrorMessage)}
          </Text>
        </div>
      ) : data?.length === 0 ? (
        <Row justify='center' align='middle' style={{ height: '100%' }}>
          <Empty />
        </Row>
      ) : !!data?.length ? (
        children
      ) : null}
    </div>
  );
};

export default DataDisplay;
