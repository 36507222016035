import React from 'react';
import ManageSharing from '../../controls/shared/ManageSharing';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { useCheckPrivateMember } from 'hooks/useCheckPrivateMember';

const ManageSharingSection = () => {
  const isPrivateMember = useCheckPrivateMember();
  
  return (
    <React.Fragment>
      {!isPrivateMember && (
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.SHARED_ENTITY}>
          <ManageSharing />
        </Can>
      )}
    </React.Fragment>
  );
};

export default ManageSharingSection;
