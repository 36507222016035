import React from 'react';

import { Row, Col, Typography, Input, Button, Form } from 'antd';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/supplier-manager';

const { Text } = Typography;

const SupplierSearchBar = ({ onSearch, onReset, form }) => {
  const intl = useIntl();

  return (
    <Form form={form}>
      <Row gutter={16} wrap={false} align='middle'>
        <Col flex={0}>
          <Text strong>{intl.formatMessage(Messages.searchSupplierLabel)}</Text>
        </Col>
        <Col flex={1}>
          <Form.Item name='searchText' style={{ marginBottom: 0 }}>
            <Input.Search onSearch={onSearch} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button type='primary' onClick={onReset}>
            {intl.formatMessage(Messages.resetSearchBtn)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SupplierSearchBar;
