import { useMemo } from 'react';

import { useGridColumns } from 'hooks';

import { MemberLocationIconRenderer } from 'common/components/icon-renderer/IconRenderer';

const renderIconLocation = (params) => {
  return <MemberLocationIconRenderer item={params} />;
};

export const useMemberLocation = () => {
  const { data: locationColumns } = useGridColumns({
    gridName: 'member-locations',
  });

  const formattedColumns = useMemo(() => {
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 40,
        resizable: true,
      },
      {
        field: '',
        width: 100,
        isShowFilter: 'none',
        separateFieldName: ['isPrimaryAddress', 'status', 'addressType'],
        cellRenderer: renderIconLocation,
        resizable: true,
      },
    ];

    if (locationColumns?.length > 0) {
      // BP 12/28/2020 - add latitude, longitude into removed columns
      let hiddenCol = [
        'id',
        'isPrimaryAddress',
        'status',
        'addressType',
        'latitude',
        'longitude',
      ];
      locationColumns.forEach((col, index) => {
        if (hiddenCol.indexOf(col.fieldNameCamelCase) === -1) {
          col = { ...col, resizable: true, allowFilter: false };

          if (col.fieldNameCamelCase === 'mainAddress')
            col = { ...col, width: 250 };

          if (col.fieldNameCamelCase === 'locationName')
            col = { ...col, width: 170 };

          if (col.fieldNameCamelCase === 'locationDescription')
            col = { ...col, width: 250 };

          if (col.fieldNameCamelCase === 'zipcode')
            col = { ...col, width: 150 };

          columns.push(col);
        }
      });
    }

    return columns;
  }, [JSON.stringify(locationColumns)]);

  return { locationColumns: formattedColumns };
};
