import React from 'react';

import { List, Typography, Divider } from 'antd';

import classnames from 'classnames';

import { useGetQaSpecTodoList } from 'pages/qa-spec-workflow/hook';
import QaSpecWorkflowItem from './QaSpecWorkflowItem';
import { PERMISSION_MANAGE_QA_SPEC_WORKFLOW } from 'static/Permission';
import { useCheckPermissions } from 'hooks';

const { Title } = Typography;

const QaSpecWorkflowTodoList = ({ setVisible, visible }) => {
  const hasPermissionManageQaSpecWorkflow = useCheckPermissions(
    PERMISSION_MANAGE_QA_SPEC_WORKFLOW
  );

  const isEnable = visible && hasPermissionManageQaSpecWorkflow;

  const { qaSpecWorkflowTodoList } = useGetQaSpecTodoList(isEnable);

  return (
    <>
      <Title
        level={5}
        className={classnames({
          'header-task__popover-title': true,
          'header-task__popover-title--center': true,
        })}
        style={{ textAlign: 'center' }}
      >
        QA Spec Workflow List
      </Title>
      <Divider style={{ margin: 0 }} />
      <List
        itemLayout='horizontal'
        dataSource={qaSpecWorkflowTodoList}
        renderItem={(workflowItem) => (
          <QaSpecWorkflowItem
            workflowItem={workflowItem}
            setVisible={setVisible}
          />
        )}
      />
    </>
  );
};

export default QaSpecWorkflowTodoList;
