import React from 'react';

import { Row, Col, Alert } from 'antd';
import { StyledModal } from 'common/components';

import { ProductDetailProvider } from 'pages/product-full-view/components/product-new-detail-view';

import PublicationErrorProductDetail from './PublicationErrorProductDetail';

import { mapToPublishErrorAlert } from 'pages/branded-products/mapper/mapToPublishErrorAlert';

import { useGetProductSchema } from 'hooks';

import { sleep } from 'utils/delay';

const PublicationErrorDetailModal = (props) => {
  const {
    isErrModalVisible,
    publishingProduct,
    toggleCheckErrorModal,
    currentError,
    toggleIsDrawerOpen,
    getPublishCheckList,
    setReloadOneError,
    setErrModalVisible,
  } = props;
  const { productSchema } = useGetProductSchema();

  const onCancel = () => {
    toggleCheckErrorModal && toggleCheckErrorModal();
  };

  const alertConfig = mapToPublishErrorAlert(currentError, toggleIsDrawerOpen);

  const onResolveErrorPublicationSuccess = async () => {
    if (currentError) {
      getPublishCheckList &&
        typeof getPublishCheckList === 'function' &&
        getPublishCheckList();

      setErrModalVisible(false);
      if (setReloadOneError) {
        setReloadOneError(true);

        await sleep(1000);
        setReloadOneError(false);
      }
    }
  };

  return (
    <>
      {isErrModalVisible && (
        <StyledModal
          title='Publication Error Detail'
          visible={isErrModalVisible}
          onCancel={onCancel}
          maskClosable={false}
          bodyStyle={{ maxHeight: '85vh', height: '85vh' }}
          width='90vw'
          centered
          destroyOnClose
          footer={null}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Row>
              {currentError && (
                <Col xs={24}>
                  <Alert
                    message={alertConfig?.message}
                    type={alertConfig?.status}
                    showIcon
                    banner
                  />
                </Col>
              )}
            </Row>
            <ProductDetailProvider>
              <PublicationErrorProductDetail
                productDetail={publishingProduct ?? {}}
                productSchema={productSchema}
                currentError={currentError}
                onResolveErrorPublicationSuccess={
                  onResolveErrorPublicationSuccess
                }
              />
            </ProductDetailProvider>
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default PublicationErrorDetailModal;
