import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Collapse, Checkbox, Empty, Space, Tag } from 'antd';
import {
  BlockOutlined,
  CodeSandboxOutlined,
  CodepenOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { WithLoading } from 'common/components';
import ProductHierarchyTree from './ProductHierarchyTree';

import * as productActions from '../../controllers/actions';
import * as productSelectors from '../../controllers/selectors';

import { HIERARCHY_LEVEL, EVENT } from 'static/Constants';
import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

import { useProductHierarchies } from 'hooks';

import classnames from 'classnames';

import './ProductHierarchyDetail.less';

const { Title } = Typography;
const { Panel } = Collapse;

const ProductHierarchyDetail = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { productId, publishPackageList, isInCheckPublishErrorMode } = props;

  const refAtleastOneLevelActive = useRef([]);

  const selectedHierarchy = useSelector(
    productSelectors.makeSelectSelectedHierarchy()
  );

  const { hierarchyData, isLoading } = useProductHierarchies({
    productId: productId,
    enabled: !!productId,
  });

  const getTitle = (item) => {
    if (!item) return;
    const typeText = intl.formatMessage(Messages[`hierarchyType${item.type}`]);

    const gtin = item.gtinHighestLevel ? item.gtinHighestLevel : '';

    return `${typeText} ${gtin ? gtin + ' ' : ''}${
      item.packDescription ? `(${item.packDescription})` : ''
    }`;
  };

  const onClickPanelHeader = (item) => () => {
    if (!item) return;

    const { id } = item;

    if (selectedHierarchy?.productHierarchyId === id) {
      dispatch(productActions.updateSelectedHierarchy(null));
      return;
    }

    const hierarchyItemData = hierarchyData.find(
      (hierarchy) => hierarchy.productHierarchyId === id
    );

    dispatch(productActions.updateSelectedHierarchy(hierarchyItemData));
  };

  const onClickExpandIcon = (panelKey) => () => {
    const item = formattedHierarchyData.find(
      (hierarchy) => hierarchy.id === +panelKey
    );

    onClickPanelHeader(item)();
  };

  const getChecked = (item) => {
    if (!item) return;

    return item.id === selectedHierarchy?.productHierarchyId;
  };

  const formatHierarchyData = () => {
    if (!hierarchyData?.length) return [];

    const formattedData = hierarchyData.map((hierarchy, idx) => {
      const {
        productHierarchyId,
        gtin,
        packDescription,
        productItemLinks,
        status,
      } = hierarchy;

      const hierarchyTree = getHierarchyTree({
        productItemLinks,
        levelIndex: 0,
        parentProductItemId: null,
        idx,
      });

      return {
        id: productHierarchyId,
        gtinHighestLevel: gtin,
        type: 'Gtin',
        packDescription,
        hierarchyTree,
        status,
      };
    });

    return formattedData;
  };

  const getHierarchyTree = ({
    productItemLinks,
    levelIndex,
    parentProductItemId,
    isActive,
    idx,
  }) => {
    if (!productItemLinks.length) return null;
    if (levelIndex <= 4) {
      const icons = {
        [HIERARCHY_LEVEL.PALLET.NAME]: <CodeSandboxOutlined />,
        [HIERARCHY_LEVEL.MASTERCASE.NAME]: <CodeSandboxOutlined />,
        [HIERARCHY_LEVEL.CASE.NAME]: <CodeSandboxOutlined />,
        [HIERARCHY_LEVEL.INNERPACK.NAME]: <CodepenOutlined />,
        [HIERARCHY_LEVEL.UNIT.NAME]: <BlockOutlined />,
      };

      const hierarchyLevel = Object.values(HIERARCHY_LEVEL);

      const matchedLevelProduct = productItemLinks.filter((product) => {
        return product.parentProductItemId === parentProductItemId;
      });

      if (matchedLevelProduct.length) {
        return matchedLevelProduct.map((product) => {
          const isFoundActive = isInCheckPublishErrorMode
            ? !!publishPackageList.find(
                (packageItem) => packageItem?.ProductGTIN === product?.gtin
              )
            : true;

          if (isFoundActive) {
            let nextRefAtleastOneLevelActive = [
              ...refAtleastOneLevelActive.current,
            ];
            nextRefAtleastOneLevelActive[idx] = true;
            refAtleastOneLevelActive.current = nextRefAtleastOneLevelActive;
          }

          return {
            label: product.packageLevel,
            upc12: product.upc12,
            gtin: product.gtin,
            icon: icons[hierarchyLevel[levelIndex].NAME],
            nest: getHierarchyTree({
              productItemLinks,
              levelIndex: levelIndex + 1,
              parentProductItemId: product.productItemId,
              isActive: isFoundActive || isActive,
              idx,
            }),
            productId: product.productItemId,

            isActive: isFoundActive || isActive,
          };
        });
      }

      return getHierarchyTree({
        productItemLinks,
        levelIndex: levelIndex + 1,
        parentProductItemId,
        idx,
        isActive,
      });
    }
  };

  useEffect(() => {
    const emptyActiveHierrachy = () => {
      if (isLoading) {
        refAtleastOneLevelActive.current = [];
      }
    };

    emptyActiveHierrachy();
  }, [isLoading]);

  useEffect(() => {
    return () => {
      dispatch(productActions.updateSelectedHierarchy(null));
      dispatch(productActions.updateHierarchyEndpoint(null));
    };
  }, []);

  const formattedHierarchyData = formatHierarchyData();

  const renderExpandIcon = (props) => {
    return (
      <RightOutlined
        style={{ padding: 5 }}
        rotate={props.isActive ? 90 : 0}
        onClick={onClickExpandIcon(props.panelKey)}
      />
    );
  };

  return (
    <div className='product-hierarchy scroller'>
      <Title className='product-hierarchy__title' level={3}>
        {intl.formatMessage(Messages.hierarchies)}
      </Title>
      {isLoading ? (
        <WithLoading loading style={{ width: '100%', paddingTop: 50 }} />
      ) : !hierarchyData.length ? (
        <Empty />
      ) : (
        <Collapse
          className='product-hierarchy__list'
          defaultActiveKey={formattedHierarchyData[0]?.id}
          activeKey={selectedHierarchy?.productHierarchyId}
          expandIcon={renderExpandIcon}
          ghost
        >
          {formattedHierarchyData.map((item, panelIdx) => {
            const isDeleted = item.status === 'Deleted';
            const isHidden =
              isInCheckPublishErrorMode &&
              refAtleastOneLevelActive.current?.[panelIdx] !== true;

            return (
              <Panel
                className={classnames('product-hierarchy__item', {
                  'product-hierarchy__item--hidden': isHidden,
                })}
                header={
                  <Title
                    className='product-hierarchy__item-title'
                    level={4}
                    ellipsis
                    onClick={onClickPanelHeader(item)}
                  >
                    {getTitle(item)}
                    <Checkbox
                      style={{ marginLeft: 8 }}
                      checked={getChecked(item)}
                      disabled={isDeleted}
                    />
                  </Title>
                }
                key={item.id}
                extra={
                  <Space>
                    {isDeleted ? (
                      <Tag color='error'>
                        {intl.formatMessage(Messages.deletedHierarchy)}
                      </Tag>
                    ) : null}
                  </Space>
                }
                forceRender
              >
                {item.hierarchyTree?.length
                  ? item.hierarchyTree.map((tree, index) => {
                      return (
                        <ProductHierarchyTree
                          key={index}
                          id={item?.id}
                          {...tree}
                          isContainer
                        />
                      );
                    })
                  : null}
              </Panel>
            );
          })}
        </Collapse>
      )}
    </div>
  );
};

export default ProductHierarchyDetail;
