import React from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/home';

import { ClockCircleOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import { forwardTo } from 'utils/common/route';
import RibbonButton from 'common/components/button/RibbonButton';
import gridSelector from 'common/components/grid-view/controllers/selectors';

import * as digitalAssetSelectors from 'pages/asset-full-view/controllers/selectors';

const VersionAsset = ({ sectionDisabled, disabled }) => {
  const selectedAssetDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const currentAssetData = useSelector(
    digitalAssetSelectors.makeSelectAssetData()
  );
  const assetId =
    selectedAssetDetail && selectedAssetDetail.length > 0
      ? selectedAssetDetail[0]?.id
      : !_.isEmpty(currentAssetData)
      ? currentAssetData?.id
      : undefined;

  const disableButton = checkAllCondition(sectionDisabled);

  return (
    <RibbonButton
      icon={<ClockCircleOutlined />}
      label={Messages.versions}
      onClick={() => forwardTo(`/asset/versions/${assetId}`)}
      disabled={
        disableButton ||
        (_.isEmpty(currentAssetData) && selectedAssetDetail.length !== 1)
      }
    />
  );
};

const checkAllCondition = (...args) => {
  return args.some((ele) => ele);
};

export default VersionAsset;
