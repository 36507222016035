import React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

import SeparatorBar from 'pages/product-full-view/components/product-media-area/nutrition-label/components/SeparatorBar';

import {
  roundToTwoDecimals,
  renderDailyValue,
  renderName,
  renderDescription,
  getNameAndItalicizeNameSupplement,
} from 'pages/product-full-view/components/product-media-area/supplement-label/utils';

import {
  SUPPLEMENT_NUTRIENT_NAME,
  MEASUREMENT_UNIT,
} from 'pages/product-full-view/components/product-media-area/nutrition-label/constants';

const PADDING_PART_OF = 7;

const SupplementList = ({ data, isSupplementWithoutDailyValue }) => {
  const formatMeasurementUnit = (quantity, unit, MEASUREMENT_UNIT) => {
    if (isNil(quantity)) return null;
    return MEASUREMENT_UNIT[unit?.toLowerCase()] || unit;
  };

  return (
    <>
      {data.map((item) => {
        const type = isSupplementWithoutDailyValue
          ? SUPPLEMENT_NUTRIENT_NAME.INGREDIENT
          : SUPPLEMENT_NUTRIENT_NAME.NUTRIENT;

        const { name, italicName } = getNameAndItalicizeNameSupplement(
          item,
          type
        );
        const quantity = roundToTwoDecimals(item?.quantity);

        const styles = {
          indent: {
            backgroundColor: item?.hightLightText,
          },
        };

        return (
          <div key={item.name} className='supplement-standard__text'>
            <div className='supplement-standard__row' style={styles.indent}>
              <div
                style={{
                  flex: 1,
                  paddingLeft: item?.ingredientsPartOf
                    ? `${PADDING_PART_OF * item?.level}px`
                    : 0,
                  wordBreak: 'break-all',
                }}
              >
                {renderName(name, italicName)}{' '}
                {renderDescription(item?.descriptor, item?.italicDescriptor)}
              </div>
              <div className='supplement-standard__amountPerServing'>
                <p className='m-0'>
                  {quantity}
                  <span style={{ paddingLeft: '1px' }}>
                    {formatMeasurementUnit(
                      quantity,
                      item?.quantityUom,
                      MEASUREMENT_UNIT
                    )}
                  </span>
                </p>
                {item?.quantityDescription && (
                  <p className='m-0 supplement-standard__amountDescription'>
                    {renderDescription(item?.quantityDescription, false)}
                  </p>
                )}
              </div>
              <div
                className={classnames({
                  'supplement-standard__dv': true,
                  'supplement-standard__dv--calories': isNil(item?.dailyValue),
                  'supplement-standard__without-dv':
                    isSupplementWithoutDailyValue,
                })}
              >
                {renderDailyValue(item, isSupplementWithoutDailyValue)}
              </div>
            </div>
            <SeparatorBar />
            {item?.children && (
              <SupplementList
                isSupplementWithoutDailyValue={isSupplementWithoutDailyValue}
                data={item?.children}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default SupplementList;
