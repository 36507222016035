import React from 'react';

import { Row, Col, Empty } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';

import { Form, SectionWrapper, FormAddButton } from 'common/components';
import {
  HighlightContent,
  PrimaryFieldSnapshot,
} from '../qa-prod-spec/snapshot/RetailerSnapshot';
import { QaPetFoodSnapshot } from './QaPetFood';

const QaSpecPetFoodGeometries = (props) => {
  const { isEdit, formDefine, geometries, geometriesSnapshot } = props;

  return (
    <Form.List name='geometries'>
      {(fields, { add, remove }) => {
        return (
          <>
            {isEdit ? (
              <Row style={{ position: 'absolute', right: '5px', zIndex: 10 }}>
                <FormAddButton
                  style={{ marginBottom: '-12px' }}
                  onClick={() => {
                    add();
                  }}
                />
              </Row>
            ) : null}

            <SectionWrapper title='Geometries' style={{ paddingTop: 30 }}>
              {fields.length ? (
                fields.map((field, index) => {
                  const supplierModifiedField = geometries?.[index] ?? {};
                  const snapshotField = geometriesSnapshot?.[index] ?? {};

                  return (
                    <Row gutter={[8, 8]} style={{ alignItems: 'baseline' }}>
                      <Col flex={1}>
                        <Row gutter={[8, 8]} key={field.key}>
                          {formDefine.map((formDefineItem) => {
                            const supplierModifiedValue =
                              supplierModifiedField?.[`${formDefineItem.name}`];
                            const snapshotValue =
                              snapshotField?.[`${formDefineItem?.name}`];

                            return (
                              <Col span={6} key={formDefineItem.name}>
                                <QaPetFoodSnapshot
                                  supplierModifiedValue={supplierModifiedValue}
                                  snapshotValue={snapshotValue}
                                  highlightContent={
                                    <HighlightContent
                                      label={
                                        formDefineItem.label === 'Shape'
                                          ? `${formDefineItem.label} ${
                                              +field.name + 1
                                            }:`
                                          : `${formDefineItem.label}:`
                                      }
                                      value={snapshotValue}
                                    />
                                  }
                                >
                                  <Form.Item
                                    {...formDefineItem}
                                    name={[field.name, formDefineItem.name]}
                                    label={
                                      formDefineItem.label === 'Shape'
                                        ? `${formDefineItem.label} ${
                                            +field.name + 1
                                          }`
                                        : formDefineItem.label
                                    }
                                  >
                                    <PrimaryFieldSnapshot readOnly={!isEdit} />
                                  </Form.Item>
                                </QaPetFoodSnapshot>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                      <Col flex={0}>
                        {isEdit ? (
                          <MinusCircleOutlined
                            style={{
                              fontSize: 16,
                              color: red.primary,
                            }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <div style={{ padding: '16px 0' }}>
                  <Empty />
                </div>
              )}
            </SectionWrapper>
          </>
        );
      }}
    </Form.List>
  );
};

export default QaSpecPetFoodGeometries;
