import React from 'react';
import PropTypes from 'prop-types';

import { Card, Row, Col } from 'antd';

import ContactCardUserInfo from './ContactCardUserInfo';
import ContactCardContactInfo from './ContactCardContactInfo';

import './ContactCard.less';

const ContactCard = (props) => {
  const {
    userInfo: {
      fullName,
      image,
      position,
      address,
      phoneNumber,
      mobileNumber,
      faxNumber,
      country,
      city,
      state,
      email,
    },
  } = props;

  return (
    <Card className='contact-card'>
      <ContactCardUserInfo
        fullName={fullName}
        image={image}
        position={position}
        address={address}
        country={country}
        city={city}
        state={state}
      />
      <Row justify='end'>
        <Col flex={'330px'}>
          <ContactCardContactInfo
            phoneNumber={phoneNumber}
            mobileNumber={mobileNumber}
            faxNumber={faxNumber}
            email={email}
          />
        </Col>
      </Row>
    </Card>
  );
};

ContactCard.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

ContactCard.defaultProps = {
  userInfo: {},
};

export default ContactCard;
