import React, { useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';

import WithdrawPublicationModal from '../../modal/withdraw-publication/WithdrawPublicationModal';

import messages from 'i18n/messages/home';

const WithdrawPublicationButton = (props) => {
  const { products, isDisabled } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const onClick = () => {
    showModal();
  };

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<DeleteOutlined />}
        label={messages.withDrawProductPublicationFull}
        disabled={isDisabled}
        onClick={onClick}
      />
      <WithdrawPublicationModal
        visible={modalVisible}
        closeModal={closeModal}
        products={products}
      />
    </>
  );
};

export default WithdrawPublicationButton;
