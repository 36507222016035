import React, { useState, useEffect } from 'react';

import { Tabs } from 'antd';
import classnames from 'classnames';

import StyledModal from '../modal';
import UploadFile from '../uploadFile/UploadFile';
import LogoAssetGrid from './LogoAssetGrid';

import { getUploadImageUrl } from 'utils/image';

import Messages from 'i18n/messages/common';
import { useIntl } from 'react-intl';

import { UPLOAD_MAX_SIZE } from 'static/Constants';

import './EditLogoModal.less';

const { TabPane } = Tabs;

const SUPPORT_LOGO_TYPES = ['jpeg', 'png', 'jpg'];

const EditLogoModal = (props) => {
  const intl = useIntl();

  const {
    title,
    visible,
    setVisible,
    onSubmit,
    logoAssetsUniqueId,
    getLogoAssetsService,
    getLogoAssetResponseParams,
  } = props;

  const [file, setFile] = useState(null);
  const [logoAsset, setLogoAsset] = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const onChangeTab = (activeKey) => {
    setActiveTab(activeKey);
  };

  const onFileChange = (fileResult) => {
    if (!fileResult?.file) return;

    setFile(fileResult?.file?.originFileObj);
  };

  const onOk = () => {
    if (activeTab === '1' && file) getUploadImageUrl(file, onSubmit);
    if (activeTab === '2' && logoAsset) uploadLogoFromLogoAsset();
    onCancel();
  };

  const onCancel = () => {
    setVisible(false);
    setFile(null);
    setLogoAsset(null);
    setSubmitDisabled(true);
    setActiveTab('1');
  };

  const onSelectLogoAsset = (selectedLogoId, logoImage, selectedLogo) => {
    const uniqueObject = {};
    uniqueObject[logoAssetsUniqueId] = selectedLogoId;
    uniqueObject.imageSrc = logoImage;
    uniqueObject.selectedLogo = selectedLogo;

    setLogoAsset(uniqueObject);
  };

  const uploadLogoFromLogoAsset = () => {
    onSubmit(logoAsset);
  };

  const checkSubmitDisable = () => {
    if (!visible) return;

    if (activeTab === '1' && file) {
      setSubmitDisabled(false);
      return;
    }

    if (activeTab === '2' && logoAsset?.selectedLogo) {
      setSubmitDisabled(false);
      return;
    }

    setSubmitDisabled(true);
  };

  useEffect(() => {
    checkSubmitDisable();
  }, [visible, activeTab, file, logoAsset]);

  const okBtnText =
    activeTab === '1'
      ? intl.formatMessage(Messages.useImage)
      : intl.formatMessage(Messages.submit);
  return (
    <StyledModal
      wrapClassName={classnames({
        'edit-logo-modal': true,
        'hide-tab-bar': !getLogoAssetsService,
      })}
      title={title ? title : intl.formatMessage(Messages.uploadImage)}
      visible={visible}
      onOk={onOk}
      okText={okBtnText}
      okButtonProps={{ disabled: submitDisabled }}
      onCancel={onCancel}
      destroyOnClose={true}
      width={690}
      bodyStyle={{ height: 440 }}
    >
      <Tabs defaultActiveKey='1' onChange={onChangeTab}>
        <TabPane tab={intl.formatMessage(Messages.localImage)} key='1'>
          <UploadFile
            manualUpload
            showMultiple={false}
            getFileChange={onFileChange}
            onlyAcceptImage={true}
            supportTypes={SUPPORT_LOGO_TYPES}
            showSubmitBtn={false}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
          />
        </TabPane>
        {getLogoAssetsService ? (
          <TabPane tab={intl.formatMessage(Messages.rivirAssets)} key='2'>
            <LogoAssetGrid
              visible={visible}
              getLogoAssetRequest={getLogoAssetsService}
              onSelectLogoAsset={onSelectLogoAsset}
              responseParams={getLogoAssetResponseParams}
            />
          </TabPane>
        ) : null}
      </Tabs>
    </StyledModal>
  );
};

export default EditLogoModal;
