import React, { useState } from 'react';
import { notification } from 'antd';

import FileSaver from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import { backupHelpCenterService } from 'services/helpMaintenance';

import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/maintenance';

const Backup = ({ intl }) => {
  const [backupLoading, setBackupLoading] = useState(false);

  const handleBackup = async () => {
    setBackupLoading(true);
    try {
      const response = await backupHelpCenterService();
      const { isSuccess, data, message } = response;
      if (isSuccess) {
        FileSaver.saveAs(data?.fileUrl, data?.fileName);
        showSuccessMessageDialog();
      } else {
        callApiErrorHandler(message);
      }
      setBackupLoading(false);
    } catch (error) {
      setBackupLoading(false);
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.backupSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <RibbonButton
      icon={<DownloadOutlined />}
      label={Messages.backup}
      onClick={handleBackup}
      loading={backupLoading}
    />
  );
};

export default injectIntl(Backup);
