import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { LocaleContext } from 'context/Locale';
import { Select } from 'antd';

import { WrapperSelect } from 'common/components';

const { Option } = Select;
/**
 * An select component contains localization supporting values
 * @example
 * <SelectLocale
 *      style={{ width: 60 }}
 *      defaultValue={LOCALE_DEFAULT}
 *      dropdownClassName={theme === THEME.LIGHT ? "" : "dark"}
 *      data={LOCALE_SUPPORT}
 * />
 */
const SelectLocale = (props) => {
  const { data, ...rest } = props;
  const context = useContext(LocaleContext);
  const { locale, changeLocale } = context;

  return (
    <WrapperSelect
      onChange={(value) => changeLocale(value)}
      value={locale}
      {...rest}
    >
      {data.map((d) => (
        <Option key={d.key}>{d.value}</Option>
      ))}
    </WrapperSelect>
  );
};

SelectLocale.propTypes = {
  /**
   * list data [key, value] object items
   */
  data: PropTypes.arrayOf(PropTypes.object),
};

export default SelectLocale;
