import React from 'react';

import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Dropdown, Menu } from 'antd';
import { ClusterOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';

import AddHierarchy from '../../controls/product-full/AddHierarchy';
import EditHierarchy from '../../controls/product-full/EditHierarchy';
import CopyHierarchy from '../../controls/product-full/CopyHierarchy';
import DeleteHierarchy from '../../controls/product-full/DeleteHierarchy';

import { PRODUCT_VIEW } from 'static/Constants';

import messages from 'i18n/messages/home';

import * as productDetailSelectors from 'pages/product-full-view/controllers/selectors';

import {
  useCheckAllowEditProducts,
  useGetProductFullView,
  usePermissionEditProduct,
} from 'hooks';

import './HierarchyActions.less';

const HierarchyActions = ({ productId }) => {
  const location = useLocation();

  const { productFull } = useGetProductFullView({ productId });

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const hasPermissionEditProduct = usePermissionEditProduct();

  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  const isShowHierarchySections = hasPermissionEditProduct;

  const selectedHierarchy = useSelector(
    productDetailSelectors.makeSelectSelectedHierarchy()
  );

  const sectionDisabled = !isAllowEditProduct;

  const getActiveContent = () => {
    const regex = /\/(?:product)\/(.+)(?=\/\d+$)/;

    const results = location?.pathname?.match(regex);

    return results?.length ? results[1] : validateOverview();
  };

  const validateOverview = () => {
    const regex = /\/product\/\d+$/;
    const isOverview = regex.test(location.pathname);
    return isOverview ? PRODUCT_VIEW.OVERVIEW : null;
  };

  const activeContent = getActiveContent();

  const isHierarchyContent = activeContent === PRODUCT_VIEW.HIERARCHY;

  const hierarchyEditable =
    isHierarchyContent && !productFull?.gdsnShield && isAllowEditProduct;

  const renderHierarchyActions = (
    <Menu style={{ border: 'none' }} className='hierarchy-menu'>
      <Menu.Item key='add-hierarchy' style={{ backgroundColor: 'inherit' }}>
        <AddHierarchy sectionDisabled={sectionDisabled} />
      </Menu.Item>
      <Menu.Item key='edit-hierarchy' style={{ backgroundColor: 'inherit' }}>
        <EditHierarchy
          selectedHierarchy={selectedHierarchy}
          sectionDisabled={sectionDisabled}
        />
      </Menu.Item>
      <Menu.Item key='copy-hierarchy' style={{ backgroundColor: 'inherit' }}>
        <CopyHierarchy
          selectedHierarchy={selectedHierarchy}
          sectionDisabled={sectionDisabled}
        />
      </Menu.Item>
      <Menu.Item key='delete-hierarchy' style={{ backgroundColor: 'inherit' }}>
        <DeleteHierarchy
          selectedHierarchy={selectedHierarchy}
          sectionDisabled={sectionDisabled}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {isShowHierarchySections && hierarchyEditable && (
        <Dropdown overlay={renderHierarchyActions} placement='bottomLeft' arrow>
          <RibbonButton
            icon={<ClusterOutlined />}
            label={messages.productHierarchyActions}
          />
        </Dropdown>
      )}
    </>
  );
};

export default HierarchyActions;
