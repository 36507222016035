import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { Row, Input, Typography, Pagination, Button, Col, Tooltip } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { QuestionCircleTwoTone, ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/branded-assets-products';

import { RIBBON_VIEW } from 'static/Constants';
import * as ribbonSelector from 'redux/ribbon/selectors';
import * as actionsLinkAssets from './controller/actions';
import * as selectorsLinkAssets from './controller/selectors';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as assetFullViewSelector from 'pages/asset-full-view/controllers/selectors';
import * as actionsAsset from 'pages/home/ribbon/asset-full/controllers/actions';

import { dialogFunction } from 'common/components/index';
import GridLinkAssets from './GridLinkAssets';
import ItemLinkAsset from './ItemLinkAsset';
import {
  messageAddSameAsset,
  messageSuccessUpdate,
  messageSelfAsset,
  DeleteAssetGrid,
  Loading,
  NotFound,
} from './Helper';
import { handleDragEnd, columnFields } from './utils';

import './LinkAssets.less';

const { Search } = Input;
const { Text, Title } = Typography;

const LinkAssets = ({ intl }) => {
  const dispatch = useDispatch();

  const {
    status,
    assetsSearch,
    pageNumber,
    pageSize,
    totalItems,
    error,
  } = useSelector(selectorsLinkAssets.makeSelectLinkAssets());

  const i18nMessage = useMemo(
    () => [
      {
        message: intl.formatMessage({
          id: 'Taco.asset.linkAsset.messageSameAsset',
        }),
      },
      {
        message: intl.formatMessage({
          id: 'Taco.asset.linkAsset.messageSelfAsset',
        }),
      },
      {
        message: intl.formatMessage({
          id: 'Taco.asset.linkAsset.messageDeleteAssetGrid',
        }),
      },
      {
        message: intl.formatMessage({
          id: 'Taco.asset.linkAsset.updateMessage',
        }),
      },
    ],
    [intl]
  );

  const assetsGridState = useSelector(
    selectorsLinkAssets.makeSelectGridAssets()
  );
  const {
    status: statusGrid,
    error: errorGrid,
    statusUpdate,
    parentGridAssets,
    childGridAssets,
    additionParentAssets,
    additionChildAssets,
    deletedParentAssets,
    deletedChildAssets,
  } = assetsGridState;

  const selectedAsset = useSelector(
    gridSelector.makeSelectItemCurrentSelection()
  );
  const view = useSelector(ribbonSelector.selectRibbon());
  const digitalAsset = useSelector(assetFullViewSelector.assetDataLoading());

  const idAsset =
    view === RIBBON_VIEW.ASSET_FULL_VIEW.NAME
      ? digitalAsset?.id
      : selectedAsset?.id;

  const [valueSearch, setValueSearch] = useState('');
  const [statusDragDrop, setStatusDragDrop] = useReducer(
    (state, action) => ({ ...state, ...action }),
    { isExistAsset: false, isSelfAsset: false }
  );
  const { isExistAsset, isSelfAsset } = statusDragDrop;

  useEffect(() => {
    if (isExistAsset) {
      messageAddSameAsset(setStatusDragDrop, i18nMessage[0].message);
    }

    if (isSelfAsset) {
      messageSelfAsset(setStatusDragDrop, i18nMessage[1].message);
    }

    return;
  }, [i18nMessage, isExistAsset, isSelfAsset]);

  useEffect(() => {
    if (statusUpdate === 'success') {
      messageSuccessUpdate(i18nMessage[3].message);
      dispatch(actionsLinkAssets.fetchAssetsGrid(idAsset));
    }

    return;
  }, [dispatch, i18nMessage, idAsset, statusUpdate]);

  useEffect(() => {
    if (!idAsset) return;

    dispatch(actionsLinkAssets.fetchAssetsGrid(idAsset));
  }, [dispatch, idAsset]);

  const handleDeleteAssetGrid = (data, nameGrid) => {
    const associationType = nameGrid === 'parent' ? 1 : 2;

    const params = {
      id: idAsset,
      assetAssociation: [{ ...data, associationType, state: 'Deleted' }],
    };

    dialogFunction({
      type: 'warn',
      content: i18nMessage[2].message,
      onOk: () => {
        dispatch(actionsLinkAssets.updateLinkAsset(params));
      },
    });
  };

  // Add col in parent grid for deleting assets
  const colDeleteGridParent = {
    key: 3,
    field: '',
    flex: 1,
    menuTabs: [],

    cellRendererFramework: ({ data }) => (
      <DeleteAssetGrid
        data={data}
        nameGrid='parent'
        handleDeleteAssetGrid={handleDeleteAssetGrid}
      />
    ),
  };

  // Add col in child grid for deleting assets
  const colDeleteGridChild = {
    key: 3,
    field: '',
    flex: 1,
    menuTabs: [],
    cellRendererFramework: ({ data }) => (
      <DeleteAssetGrid
        data={data}
        nameGrid='child'
        handleDeleteAssetGrid={handleDeleteAssetGrid}
      />
    ),
  };

  const handleSearchAssets = (_, event) => {
    if (!valueSearch) {
      event.preventDefault();
    } else {
      dispatch(
        actionsLinkAssets.searchItemsLinkAsset(
          pageSize,
          pageNumber,
          valueSearch
        )
      );
    }
  };

  const handleChangePagination = (page, _) => {
    dispatch(
      actionsLinkAssets.searchItemsLinkAsset(pageSize, page, valueSearch)
    );
  };

  return (
    <DragDropContext
      onDragEnd={(propsDragDrop) => {
        handleDragEnd({
          assetsSearch: assetsSearch,
          idAsset,
          assetsGridState: assetsGridState,
          dispatch,
          setStatusDragDrop,
          ...propsDragDrop,
        });
      }}
    >
      <Row className='asset-search-container'>
        <div className='button-disable-link-asset'>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => dispatch(actionsAsset.disableModeLinkAsset())}
          >
            Back to
          </Button>
        </div>
        <div className='asset-search-container__label'>
          <Title level={5}>Search assets</Title>
          <Tooltip
            placement='left'
            title={<FormattedMessage {...Messages.hintGridAsset} />}
            color='#2db7f5'
          >
            <QuestionCircleTwoTone style={{ fontSize: 20 }} />
          </Tooltip>
        </div>
        <Search
          placeholder='Type name assets to search...'
          onChange={(event) => setValueSearch(event.target.value)}
          onSearch={handleSearchAssets}
          defaultValue={valueSearch}
        />
      </Row>
      {status === 'idle' ? null : status === 'loading' ? (
        <Loading className='asset-loading-search' />
      ) : status === 'success' ? (
        <>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(dropProvided) => (
              <>
                <Row
                  gutter={12}
                  style={{
                    marginTop: 14,
                    marginBottom: 3,
                    height: 80,
                  }}
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                >
                  {assetsSearch.length === 0 ? (
                    <NotFound />
                  ) : (
                    assetsSearch?.map((item) => (
                      <ItemLinkAsset item={item} key={item.id} />
                    ))
                  )}
                </Row>
                {assetsSearch?.length !== 0 ? (
                  <Pagination
                    size='small'
                    total={totalItems}
                    pageSize={pageSize}
                    defaultCurrent={pageNumber}
                    onChange={handleChangePagination}
                    showQuickJumper
                    showLessItems
                    className='link-asset-pagination'
                  />
                ) : null}
              </>
            )}
          </Droppable>
        </>
      ) : (
        <Text type='danger'>{error} </Text>
      )}
      {statusGrid === 'idle' ? null : statusGrid === 'loading' ? (
        <Loading className='asset-loading-search-grid' />
      ) : statusGrid === 'success' ? (
        <>
          <Title level={5} style={{ marginTop: 20 }}>
            Contains
          </Title>
          <Droppable droppableId='droppable-grid-parent' direction='horizontal'>
            {(dropProvided, snapshot) => (
              <>
                <Row
                  gutter={12}
                  className='row-grid-droppable'
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                >
                  <GridLinkAssets
                    columns={[...columnFields, colDeleteGridParent]}
                    rowData={assetsGridState.parentGridAssets}
                    dragOver={snapshot.isDraggingOver}
                  />
                </Row>
                <Row justify='space-between'>
                  <Col>
                    <Text strong>Total Rows: {parentGridAssets.length}</Text>
                  </Col>
                  <Col>
                    <Text strong>
                      Added Rows: {additionParentAssets.length}
                    </Text>
                  </Col>
                  <Col>
                    <Text strong>
                      Deleted Rows: {deletedParentAssets.length}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
          </Droppable>
          <Title level={5} style={{ marginTop: 20 }}>
            Included In
          </Title>
          <Droppable droppableId='droppable-grid-child' direction='horizontal'>
            {(dropProvided, snapshot) => (
              <>
                <Row
                  gutter={12}
                  className='row-grid-droppable'
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                >
                  <GridLinkAssets
                    columns={[...columnFields, colDeleteGridChild]}
                    rowData={assetsGridState.childGridAssets}
                    dragOver={snapshot.isDraggingOver}
                  />
                </Row>
                <Row justify='space-between' style={{ padding: '10px 0' }}>
                  <Col>
                    <Text strong>Total Rows: {childGridAssets.length}</Text>
                  </Col>
                  <Col>
                    <Text strong>Added Rows: {additionChildAssets.length}</Text>
                  </Col>
                  <Col>
                    <Text strong>
                      Deleted Rows: {deletedChildAssets.length}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
          </Droppable>
        </>
      ) : (
        <Text type='danger'>{errorGrid} </Text>
      )}

      {statusUpdate === 'loading' ? (
        <Loading className='asset-loading-grid' size='large' />
      ) : null}
    </DragDropContext>
  );
};

export default injectIntl(LinkAssets);
