import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Typography, Input, Row, Col, Select } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { RoleSectionWrapper, RoleEmptySection } from '../';
import RolePermissionGroup from './RolePermissionGroup';
import { FormAddButton, WrapperSelect } from 'common/components';

import * as roleSelectors from 'pages/role/controllers/selectors';

import { mapToCategoriesAndSearchPermisison } from 'pages/role/mappers/mapToCategoriesAndSearchPermisison';

import { isEmpty } from 'lodash';
import { forwardTo } from 'utils/common/route';
import { PERMISSION_CATEGORY_DEFINE } from 'static/Constants';

import './styles.less';

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const permissionListType = ['', 'add', 'remove'];

const permissionListTypeDefault = permissionListType?.[0];

const rowStyles = { marginBottom: 5 };

const RolePermissionList = (props) => {
  const {
    title,
    selectedRole,
    memberIdParams,
    memberId,
    roleIdParams,
    type = permissionListTypeDefault,
    formRole,
    rolePermissionListLoading,
    showEditPage,
    handleFocusInputSearch,
    handleBlurInputSearch,
  } = props;

  const permissionCateList = useMemo(() => {
    return Object.keys(PERMISSION_CATEGORY_DEFINE);
  }, []);

  const [searchText, setSearchText] = useState('');
  const [searchCategories, setSearchCategories] = useState([]);

  const refSearch = useRef();

  const availPermissionList = useSelector(
    roleSelectors.selectAvailPermission()
  );

  const rolePermissionList = useSelector(
    roleSelectors.selectRolePermisionList()
  );

  const availPermissionListData = availPermissionList?.data;
  const rolePermissionListData = rolePermissionList?.data;

  const roleId = selectedRole?.id;

  const onSearch = (value) => {
    setSearchText(value);
  };

  const handleChangeCate = (value) => {
    setSearchCategories(value);
  };

  const resetFilter = () => {
    setSearchCategories([]);
    if (refSearch.current && refSearch?.current?.handleReset) {
      setSearchText('');
    }
  };

  const handleGoToEditPage = () => {
    let redirectLink = '';

    if (memberIdParams) {
      redirectLink = `/security/role/${roleId}/member/${memberIdParams}/edit`;
    } else {
      redirectLink = `/security/role/${roleId}/edit`;
    }

    forwardTo(redirectLink);
  };

  const [filterdGroupPermissions, groupedPermissions] =
    mapToCategoriesAndSearchPermisison({
      availPermissions: rolePermissionListLoading
        ? []
        : availPermissionListData,
      rolePermissionListData: !selectedRole ? [] : rolePermissionListData || [],
      searchText: searchText.trim(),
      searchCategories: isEmpty(searchCategories)
        ? permissionCateList
        : searchCategories,
      formRole,
      type,
    });

  const permissionListHint =
    type === 'add'
      ? 'Select a permission to add to current role'
      : type === 'remove'
      ? 'Remove permission from the selected role'
      : '';

  useEffect(() => {
    if (roleId) {
      resetFilter();
    }
  }, [roleId]);

  useEffect(() => {
    if (roleIdParams && type === 'remove') {
      formRole.setFieldsValue({
        displayName: selectedRole?.displayName,
        description: selectedRole?.description,
        memberId: Number(memberIdParams) || memberId,
        permissions: rolePermissionListData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRole,
    rolePermissionListData,
    memberIdParams,
    memberId,
    roleIdParams,
    type,
  ]);

  return (
    <RoleSectionWrapper className='role-permission-section__wrap' title={title}>
      {!selectedRole ? (
        <RoleEmptySection />
      ) : (
        <>
          <Row gutter={[5, 5]} style={rowStyles}>
            {!type && (
              <>
                <Col flex='auto' />
                {showEditPage && (
                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <FormAddButton
                      text={
                        <div>
                          <Text
                            style={{
                              color:
                                selectedRole?.isStandardRole ||
                                selectedRole?.isSuperRole
                                  ? ''
                                  : 'white',
                              marginRight: 7,
                            }}
                          >
                            Go to permission-editing page
                          </Text>
                          <CaretRightOutlined
                            style={{
                              color:
                                selectedRole?.isStandardRole ||
                                selectedRole?.isSuperRole
                                  ? ''
                                  : 'white',
                            }}
                          />
                        </div>
                      }
                      disabled={
                        selectedRole?.isStandardRole ||
                        selectedRole?.isSuperRole
                      }
                      icon={null}
                      onClick={handleGoToEditPage}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>

          <Row gutter={[5, 5]}>
            <Col style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Text style={{ color: '#F3705A' }}>{permissionListHint}</Text>
            </Col>
          </Row>

          <Row gutter={[5, 10]} style={rowStyles}>
            <Col xs={24}>
              {!isEmpty(availPermissionListData) && (
                <Search
                  ref={refSearch}
                  placeholder='Search permissions...'
                  allowClear
                  onSearch={onSearch}
                  enterButton
                  onFocus={handleFocusInputSearch}
                  onBlur={handleBlurInputSearch}
                />
              )}
            </Col>
          </Row>

          <Row gutter={[5, 10]} style={rowStyles}>
            <Col xs={24}>
              {!isEmpty(availPermissionListData) && (
                <WrapperSelect
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Select permission categories'
                  onChange={handleChangeCate}
                  className='role-permission-section__cate-filter'
                  value={searchCategories}
                  onFocus={handleFocusInputSearch}
                  onBlur={handleBlurInputSearch}
                >
                  {permissionCateList.map((cate) => {
                    const isEmptyOptions = isEmpty(groupedPermissions?.[cate]);
                    return (
                      <Option key={cate} disabled={isEmptyOptions}>
                        {cate}
                      </Option>
                    );
                  })}
                </WrapperSelect>
              )}
            </Col>
          </Row>

          <Row style={{ flex: 1, overflow: 'hidden' }}>
            <Col xs={24} className='scroller' style={{ height: '100%' }}>
              {!isEmpty(filterdGroupPermissions) ? (
                Object.keys(filterdGroupPermissions).map((groupName) => (
                  <RolePermissionGroup
                    key={groupName}
                    title={groupName}
                    permissionListGrouped={filterdGroupPermissions?.[groupName]}
                    type={type}
                    formRole={formRole}
                  />
                ))
              ) : (
                <RoleEmptySection />
              )}
            </Col>
          </Row>
        </>
      )}
    </RoleSectionWrapper>
  );
};

export default RolePermissionList;
