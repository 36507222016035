export const GET_DROPBOX_LOGIN_URL =
  '/api/storage-service/dropbox-get-login-url';

export const GET_DROPBOX_CALLBACK = '/api/storage-service/dropbox-callback';

export const GET_INTEROP_CONNECTION_GRID =
  '/api/storage-service/get-interop-connection-grid';

export const SAVE_INTEROP_CONNECTION =
  '/api/storage-service/save-interop-connection';

export const DELETE_INTEROP_CONNECTION =
  '/api/storage-service/delete-interop-connection';

export const GET_DETAIL_INTEROP_CONNECTION =
  '/api/storage-service/get-interop-connection-detail';

export const TEST_INTEROP_CONNECTION =
  '/api/storage-service/test-interop-connection';

export const GET_INTEROP_LIST_ITEMS =
  '/api/storage-service/get-interop-list-files';

export const DOWNLOAD_INTEROP_FILES =
  '/api/storage-service/download-interop-files';

export const DOWNLOAD_INTEROP_FOLDER =
  '/api/storage-service/download-interop-folder';

export const IMPORT_ASSETS_FROM_INTEROP =
  '/api/storage-service/import-assets-from-interop';

export const CREATE_ASSET_FROM_INTEROP =
  '/api/storage-service/create-asset-from-interop';

export const GET_LIST_CONNECTIONS = '/api/storage-service/get-list-connections';
