import React from 'react';
import { Space } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const AssociationIconRenderer = (props) => {
  const { item } = props;
  return (
    <Space direction='horizontal' className='association-grid__icon-row'>
      {item &&
        item.data &&
        (item.data.isInBound ? (
          <ArrowDownOutlined className='association-grid__icon-row--icon' />
        ) : (
          <ArrowUpOutlined className='association-grid__icon-row--icon' />
        ))}
    </Space>
  );
};
export default AssociationIconRenderer;
