import * as types from './constants';

export function searchItemsLinkAsset(pageSize, pageNumber, searchText) {
  return {
    type: types.SEARCH_ITEMS_LINK_ASSETS,
    pageSize: pageSize,
    pageIndex: pageNumber,
    search: {
      searchText,
    },
  };
}

export function searchItemsLinkAssetSuccess(assets, total) {
  return {
    type: types.SEARCH_ITEMS_LINK_ASSETS_SUCCESS,
    assets,
    total,
  };
}

export function searchItemsLinkAssetFail(error) {
  return {
    type: types.SEARCH_ITEMS_LINK_ASSETS_FAIL,
    error,
  };
}

export function fetchAssetsChangePagination(pageSize, pageNumber, search) {
  return {
    type: types.FETCH_ASSETS_CHANGE_PAGINATION,
    pageSize: pageSize,
    pageIndex: pageNumber,
    'Search.SearchText': search,
  };
}

export function fetchAssetsChangePaginationSuccess(assets, total) {
  return {
    type: types.FETCH_ASSETS_CHANGE_PAGINATION_SUCCESS,
    assets,
    total,
  };
}

export function fetchAssetsChangePaginationFail(error) {
  return {
    type: types.SEARCH_ITEMS_LINK_ASSETS_FAIL,
    error,
  };
}

export function fetchAssetsGrid(id) {
  return { type: types.FETCH_ASSETS_GRID, id };
}

export function fetchAssetsGridSuccess(payload) {
  return { type: types.FETCH_ASSETS_GRID_SUCCESS, payload };
}

export function fetchAssetsGridFail(error) {
  return { type: types.FETCH_ASSETS_GRID_ERROR, error };
}

export function updateLinkAsset(params) {
  return { type: types.UPDATE_LINK_ASSET, payload: { params } };
}

export function updateLinkAssetSuccess() {
  return { type: types.UPDATE_LINK_ASSET_SUCCESS };
}

export function updateLinkAssetFail(error) {
  return { type: types.UPDATE_LINK_ASSET_FAIL, error };
}

export function resetLinkAsset() {
  return { type: types.RESET_LINK_ASSET };
}
