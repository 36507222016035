import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Space, Tooltip, Button, Divider } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  RotateRightOutlined,
  RotateLeftOutlined,
  RedoOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import { dialogFunction } from 'common/components';
import AssetPreviewDownloadBtn from './AssetPreviewDownloadBtn';

import { apiHandler } from 'utils/api';

import * as api from 'config/axios';

import * as productServices from 'services/product/index';

import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/asset-full-view';

import DigitalRight from 'assets/DRM.png';

const { Text } = Typography;

const AssetPreviewTools = (props) => {
  const {
    assetData,
    isVisiblePreviewBtn,
    setVisibleModal,
    zoomIn,
    zoomOut,
    resetTransform,
    onRotateLeft,
    onRotateRight,
    scale,
    isVideo,
    renderDownloadBtn,
  } = props;

  const [zoom, setZoom] = useState(100);

  const calculatePercent = (scale) => {
    let result = +(Math.round(scale * 100 + 'e+2') + 'e-2'); // Math for case the number has much digit after comma
    setZoom(result);
  };

  useEffect(() => {
    let timeout;

    timeout = setTimeout(() => {
      calculatePercent(scale);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [scale]);

  return (
    <div className='dam-preview__func'>
      <Space size={4}>
        {!isVideo && (
          <LeftPreviewTools
            onRotateLeft={onRotateLeft}
            onRotateRight={onRotateRight}
            resetTransform={resetTransform}
            zoom={zoom}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
          />
        )}
      </Space>
      <Space direction='horizontal'>
        {!isVideo && (
          <PreviewBtnOrTextRender
            isVisiblePreviewBtn={isVisiblePreviewBtn}
            setVisibleModal={setVisibleModal}
          />
        )}
        <DownloadBtnOrTextRender
          assetData={assetData}
          renderDownloadBtn={renderDownloadBtn}
        />
      </Space>
    </div>
  );
};

const LeftPreviewTools = (props) => {
  const {
    zoomIn,
    zoomOut,
    resetTransform,
    onRotateLeft,
    onRotateRight,
    scale,
    zoom,
  } = props;

  return (
    <>
      <Tooltip
        placement='top'
        color={'blue'}
        title={<FormattedMessage {...Messages.zoomIn} />}
      >
        <Button
          type='primary'
          className='dam-preview__btn dam-preview__btn--size'
          icon={<PlusOutlined />}
          onClick={zoomIn}
        ></Button>
      </Tooltip>
      <Text className='dam-preview__text-img'>{zoom}%</Text>
      <Tooltip
        placement='top'
        color={'blue'}
        title={<FormattedMessage {...Messages.zoomOut} />}
      >
        <Button
          type='primary'
          className='dam-preview__btn dam-preview__btn--size'
          icon={<MinusOutlined />}
          onClick={zoomOut}
        ></Button>
      </Tooltip>
      <Button
        type='primary'
        className='dam-preview__btn--rotate'
        icon={<RedoOutlined />}
        onClick={resetTransform}
      >
        <FormattedMessage {...Messages.resetZoom} />
      </Button>
      <Divider type='vertical' style={{}} className='dam-preview__divider' />
      <Button
        type='primary'
        className='dam-preview__btn--rotate'
        icon={<RotateLeftOutlined />}
        onClick={onRotateLeft}
      >
        <FormattedMessage {...Messages.rotateLeft} />
      </Button>
      <Button
        type='primary'
        className=' dam-preview__btn--rotate'
        icon={<RotateRightOutlined />}
        onClick={onRotateRight}
      >
        <FormattedMessage {...Messages.rotateRight} />
      </Button>
    </>
  );
};

const PreviewBtnOrTextRender = (props) => {
  const { isVisiblePreviewBtn, setVisibleModal } = props;
  return (
    <>
      {isVisiblePreviewBtn ? (
        <Tooltip
          placement='top'
          color={'blue'}
          title={<FormattedMessage {...Messages.previewAsset} />}
        >
          <Button
            type='primary'
            icon={<EyeOutlined />}
            className='dam-preview__btn'
            onClick={() => setVisibleModal(true)}
          />
        </Tooltip>
      ) : (
        <Text className='dam-preview__btn' style={{ color: 'white' }}>
          <FormattedMessage {...Messages.noPreviewAsset} />
        </Text>
      )}
    </>
  );
};

const DownloadBtnOrTextRender = (props) => {
  const { assetData, renderDownloadBtn } = props;

  const [loading, setLoading] = useState(false);

  const onClickDownloadBtn = () => {
    assetData?.drm
      ? showDrmNotify({ namingType: 'original' })
      : downloadFile({ namingType: 'original' });
  };

  const downloadFile = ({ namingType }) => {
    if (namingType === 'original') {
      downloadOriginalFile();
      return;
    }

    downloadFileWithNamingType(namingType);
  };

  const downloadOriginalFile = () => {
    setLoading(true);
    sendDownloadRequest(assetData.fileUrl);
  };

  const sendDownloadRequest = (url) => {
    api
      .sendDownload({
        url,
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadFileWithNamingType = (namingType) => {
    setLoading(true);

    const params = {
      imageIds: [assetData.id],
      namingType,
      formatType: assetData.fileType,
      imageSize: 'Full',
      deliveryMethod: 0,
    };

    const successMessage = 'Download successfully';
    const errorMessage = 'Cannot download';

    apiHandler({
      service: productServices.downloadProductImagery,
      params,
      successMessage,
      errorMessage,
      successCallback: (data) => {
        sendDownloadRequest(data.imageUrl);
      },
      onFinally: () => {
        setLoading(false);
      },
    });
  };

  const showDrmNotify = ({ namingType }) => {
    dialogFunction({
      type: 'warn',
      content: (
        <div style={{ fontSize: 13 }}>
          <img
            src={DigitalRight}
            alt='digital-right'
            height={20}
            width={20}
            style={{ marginRight: 5 }}
          />
          The Digital Asset you have selected for download is covered under a
          Digital Rights Restriction. Please ensure that you have reviewed the
          Digital Rights Restrictions associated with the Digital Asset conform
          with your intended usage.
        </div>
      ),

      okText: 'Cancel',
      onCancel: () => {
        downloadFile({ namingType });
      },
      cancelText: 'Download',
    });
  };

  return (
    <>
      {assetData?.fileUrl && !assetData?.isIFrame ? (
        <Tooltip
          placement='top'
          color={'blue'}
          title={<FormattedMessage {...Messages.downloadAsset} />}
        >
          {renderDownloadBtn ? (
            renderDownloadBtn({
              Button: AssetPreviewDownloadBtn,
              showDrmNotify,
              downloadFile,
              isDrm: assetData.isDrm,
              loading,
            })
          ) : (
            <AssetPreviewDownloadBtn
              onClick={onClickDownloadBtn}
              loading={loading}
            />
          )}
        </Tooltip>
      ) : (
        <Text className='dam-preview__btn' style={{ color: 'white' }}>
          <FormattedMessage {...Messages.noDownloadAsset} />
        </Text>
      )}
    </>
  );
};

AssetPreviewTools.propTypes = {
  assetData: PropTypes.object,
  isVisiblePreviewBtn: PropTypes.bool,
  setVisibleModal: PropTypes.func,
  zoomIn: PropTypes.func,
  zoomOut: PropTypes.func,
  resetTransform: PropTypes.func,
  onRotate: PropTypes.func,
  scale: PropTypes.number,
  isVideo: PropTypes.bool,
};

export default AssetPreviewTools;
