import React from 'react';

import { useHistory } from 'react-router-dom';

import { SafetyCertificateOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import useToggleActiveRibbon from './hook';

import { useGetProductFullView } from 'hooks';
import { useSsoProductParams } from 'hooks/useSso';

import Messages from 'i18n/messages/home';

const Subscription = ({ productId }) => {
  const history = useHistory();

  const { productFull } = useGetProductFullView({ productId });

  const { ssoParamUrl: ssoProductParams, productId: ssoProductId } =
    useSsoProductParams();

  const checkActiveContent = () => {
    const path = history?.location?.pathname;
    const regex = /\/product\/subscription\/\d+$/;
    return regex.test(path);
  };

  const isPathActive = checkActiveContent();
  const isActive = useToggleActiveRibbon(isPathActive);

  const handleClick = () => {
    if (productFull?.productId !== undefined) {
      if (isActive) {
        if (ssoProductParams) {
          history.push(`/product/${ssoProductId}/?${ssoProductParams}`);
        } else {
          history.push(`/product/${productFull?.productId}`);
        }
      } else {
        if (ssoProductParams) {
          history.push(
            `/product/subscription/${ssoProductId}?${ssoProductParams}`
          );
        } else {
          history.push(`/product/subscription/${productFull?.productId}`);
        }
      }
    }
  };

  return (
    <RibbonButton
      icon={<SafetyCertificateOutlined style={{ height: 26 }} />}
      label={Messages.subscription}
      onClick={handleClick}
      toggle={isPathActive}
      disabled={!productFull?.productId}
    />
  );
};

export default Subscription;
