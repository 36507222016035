import React from 'react';

import { Button, Select, Popover } from 'antd';
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  FontColorsOutlined,
} from '@ant-design/icons';
import { useNode } from '@craftjs/core';

import { WrapperSelect } from 'common/components';

import { EmailEditorColorInput } from '../input';

const { Option } = Select;

const EmailEditorFontSetting = () => {
  const {
    settings,
    actions: { setProp },
  } = useNode((state) => ({
    settings: state.data.props.settings,
  }));

  const onChangeFontSize = (value) => {
    setProp((props) => (props.settings.fontSize = value));
  };

  const onClickBtn = (key) => () => {
    setProp((props) => (props.settings[key] = !props.settings[key]));
  };

  const onChangeColor = (color) => {
    setProp((props) => (props.settings.color = color.hex));
  };

  const checkType = (key) => {
    return settings[key] ? 'link' : 'text';
  };

  return (
    <>
      <WrapperSelect
        bordered={false}
        defaultValue={settings?.fontSize}
        onChange={onChangeFontSize}
      >
        {[...Array(41).keys()].map((key) => {
          const isValid = key >= 8 && key % 2 === 0;
          return isValid ? (
            <Option key={key} value={key}>
              {key}
            </Option>
          ) : null;
        })}
      </WrapperSelect>
      <Button
        type={checkType('bold')}
        icon={<BoldOutlined />}
        onClick={onClickBtn('bold')}
      />
      <Button
        type={checkType('italic')}
        icon={<ItalicOutlined />}
        onClick={onClickBtn('italic')}
      />
      <Button
        type={checkType('underline')}
        icon={<UnderlineOutlined />}
        onClick={onClickBtn('underline')}
      />
      <Popover
        content={
          <EmailEditorColorInput
            onChange={onChangeColor}
            color={settings.color}
          />
        }
        placement='right'
        trigger='click'
      >
        <Button
          type='text'
          icon={<FontColorsOutlined style={{ color: settings.color }} />}
        />
      </Popover>
    </>
  );
};

export default EmailEditorFontSetting;
