import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Col } from 'antd';
import { CompactPicker } from 'react-color';
import useClickOuter from 'hooks/useClickOuter';
import './MdToolTextColor.less';

const { Text } = Typography;

const MdToolTextColor = (props) => {
  const { textColor, setTextColor } = props;
  const [isShowColorPicker, setIsShowColorPicker] = useState(false);

  const refColorTool = useRef(null);

  const handleClickOutside = () => {
    setIsShowColorPicker(false);
  };

  useClickOuter(refColorTool, handleClickOutside);

  const toggleColorPicker = () => {
    setIsShowColorPicker(!isShowColorPicker);
  };

  const handleChangeColor = (color) => {
    //todo
    //const procColor = color.hex !== '#000000' ? color.hex : '#000000A6';
    const procColor = color.hex !== '#000000' ? color.hex : '';

    setTextColor && setTextColor(procColor);
  };

  return (
    <Col className='md-tool-text-color' ref={refColorTool}>
      <Text className='md-tool-text-color__title'>Color</Text>
      <div
        className='md-tool-text-color__current-color'
        onClick={toggleColorPicker}
      >
        <div
          className='md-tool-text-color__current-color-inner'
          style={{ background: textColor || '#000000A6' }}
        />
      </div>
      {isShowColorPicker && (
        <div className='md-tool-text-color__picker'>
          <CompactPicker
            colors={[
              '#000000A6',
              '#2B8815',
              '#EFC050',
              '#FF6F61',
              '#6B5B95',
              '#88B04B',
              '#00b9f2',
              '#92A8D1',
              '#955251',
              '#DD4124',
              '#45B8AC',
              '#f1c40f',
              '#9b59b6',
              '#c0392b',
              '#e74c3c',
              '#27ae60',
              '#f39c12',
              '#1abc9c',
              '#e67e22',
            ]}
            onChangeComplete={handleChangeColor}
          />
        </div>
      )}
    </Col>
  );
};
MdToolTextColor.propTypes = {
  textColor: PropTypes.string,
  setTextColor: PropTypes.func,
};

export default MdToolTextColor;
