import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import { ViewLayout, AdvanceStack } from '../components';
import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from '../components/sections/shared/ManageSharingSection';
import DetailSection from '../components/sections/home-grid/DetailSection';
import SsoApprovalSection from '../components/sections/sso-approval/SsoApprovalSection';

import gridSelectors from 'common/components/grid-view/controllers/selectors';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import AssignUserSsoApproval from '../components/controls/sso-approval/AssignUserSSoApproval';
import ToggleSsoApproval from '../components/controls/sso-approval/ToggleSsoApproval';

const SsoApprovalUserRibbon = () => {
  const gridSelections = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const selectedItem = gridSelections.length > 0 && gridSelections[0];

  const disabled = gridSelections.length !== 1;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection disabled />
      <ViewLayout disabled={true} />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.PRODUCT}>
        <SsoApprovalSection
          selectedItem={selectedItem}
          sectionDisabled={disabled}
          approvalSubject={'user'}
        />
        <AssignUserSsoApproval
          selectedItem={selectedItem}
          sectionDisabled={disabled}
        />
        <RibbonDivider />
      </Can>
      <AdvanceStack isDisabled={true} />
      <RibbonDivider />
      <ToggleSsoApproval approvalSubject={'user'} />
    </RibbonBar>
  );
};

export default SsoApprovalUserRibbon;
