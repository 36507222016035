import React from 'react';
import { useDispatch } from 'react-redux';

import { UploadOutlined } from '@ant-design/icons';

import ButtonLargeIcon from 'common/components/button/ButtonLargeIcon';

import { forwardTo } from 'utils/common/route';

const FormUploadBtn = (props) => {
  // const dispatch = useDispatch();

  // const onClickUploadBtn = () => {
  //   forwardTo('/maintenance/form/upload');
  // };

  return (
    <ButtonLargeIcon
      icon={<UploadOutlined />}
      label={'Upload'}
      // onClick={onClickUploadBtn}
    />
  );
};

export default FormUploadBtn;
