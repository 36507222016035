import React, { useState, useCallback, useEffect } from 'react';

import { StyledModal, CustomNotification } from 'common/components';
import { Input, List, Row, Col, Typography, Tooltip } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import { useGetNifList } from './hooks';
import { useIntl } from 'react-intl';

import { downloadNifForm } from 'services/nif';
import messages from 'i18n/messages/home';
import classnames from 'classnames';
import { sortByKeyCallback } from 'utils/sortByKey';

import './SearchNifFormModal.less';

const { Text } = Typography;

const { Search } = Input;

/**
 * ! MAIN COMPONENT
 * @param {*} props
 * @returns
 */
const SearchNifFormModal = (props) => {
  const { isOpen, toggleModal, detailItems } = props;

  const [nifList, setNifList] = useState([]);

  const [nifDataDefault, setNifDataDefault] = useState([]);

  const { data: nifData } = useGetNifList(isOpen);

  const sortNifList = useCallback(
    (list) => list?.sort(sortByKeyCallback('name')),
    []
  );

  const onSearch = (e) => {
    const value = e.target.value;

    if (nifData.length === 0) return;

    const trimText = value.trim();

    const nextNifList = nifDataDefault.filter((nifItem) =>
      nifItem.name.toLowerCase().includes(trimText.toLowerCase())
    );

    setNifList(sortNifList(nextNifList));
  };

  const renderNifItem = (item) => {
    return <NifItem key={item.name} item={item} detailItems={detailItems} />;
  };

  useEffect(() => {
    if (!isOpen) return;
    //* set nif list to state
    const setDefaultNifList = () => {
      if (!nifData || !Array.isArray(nifData)) return;
      setNifDataDefault(sortNifList(nifData));
      setNifList(sortNifList(nifData));
    };

    setDefaultNifList();
  }, [nifData, isOpen, sortNifList]);

  return (
    <StyledModal
      title='Download New Item Form'
      visible={isOpen}
      onOk={toggleModal}
      onCancel={toggleModal}
      okText='Close'
      maskClosable={false}
      destroyOnClose={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={600}
      bodyStyle={{ height: 600, display: 'flex', flexDirection: 'column' }}
    >
      <Row>
        <Col xs={24}>
          <Search
            placeholder='Input search text'
            onChange={onSearch}
            enterButton
            allowClear
          />
        </Col>
      </Row>
      <Row style={{ flex: 1, overflow: 'hidden', padding: '5px 0 5px 0' }}>
        <Col className='scroller' xs={24} style={{ height: '100%' }}>
          <List dataSource={nifList || []} renderItem={renderNifItem} />
        </Col>
      </Row>
    </StyledModal>
  );
};

/**
 * !SUB COMPONENT - nif item in nif list
 * @param {*} props
 * @returns
 */
const NifItem = (props) => {
  const { detailItems, item } = props;

  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const clickDownloadNifForm = async (type) => {
    try {
      const productIds = detailItems?.map((productItem) => productItem.id);

      const params = { productIds, nifType: type };

      setLoading(true);
      const response = await downloadNifForm(params);
      setLoading(false);

      const { message, isSuccess } = response;

      if (isSuccess) {
        CustomNotification.info('Preparing to download new item form');
      } else {
        CustomNotification.error(message);
      }
    } catch (error) {
      if (error?.timeout) return;

      CustomNotification.error(
        intl.formatMessage(messages.downloadNewItem.error)
      );

      setLoading(false);
    }
  };

  return (
    <Row
      className={classnames('search-nif-form-modal__item', {
        'search-nif-form-modal__item--disabled': loading,
      })}
    >
      <Col>
        <Text strong>{item?.name} </Text>
      </Col>
      <Col flex={1} />
      <Col>
        {loading ? (
          <LoadingOutlined />
        ) : (
          <Tooltip title='Download' color={'#0070cc'} key={'#0070cc'}>
            <DownloadOutlined
              className='search-nif-form-modal__download-icon'
              onClick={() => clickDownloadNifForm(item?.type)}
            />
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

export default SearchNifFormModal;
