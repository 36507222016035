import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  isUploadTagLineMediaOpen: false,
  isShowUploadTagLineMedia: false,
  isShowPreviewUploadTagLineMedia: false,
};

/* eslint-disable default-case, no-param-reassign */
const memberTagLineReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.TOGGLE_TAG_LINE_UPLOAD_MEDIA:
        draft.isUploadTagLineMediaOpen = action.status;
        break;
      case types.TOGGLE_PREVIEW_TAG_LINE_UPLOAD_MEDIA:
        draft.isShowPreviewUploadTagLineMedia = action.status;
        break;
      case types.IS_SHOW_LINE_UPLOAD_MEDIA:
        draft.isShowUploadTagLineMedia = action.status;
        break;
    }
  });

export default memberTagLineReducer;
