import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
} from 'react';

import { SearchOutlined } from '@ant-design/icons';

import SearchGridItemModal from './SearchGridItemModal';

import { SearchGridItemButton } from '../components';
import './SearchEntityBtn.less';

const SearchEntityBtn = (props, ref) => {
  const { button } = props;

  const refSearchModal = useRef();

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      refSearchModal.current.closeModal();
    },
    removeItem: (itemData) => refSearchModal.current.removeItem(itemData),
  }));

  const clickSearchButton = useCallback(() => {
    refSearchModal.current.openModal();
  }, []);

  return (
    <>
      <SearchGridItemButton type='primary' onClick={clickSearchButton}>
        {button || <SearchOutlined />}
      </SearchGridItemButton>
      <SearchGridItemModal {...props} ref={refSearchModal} />
    </>
  );
};

export default forwardRef(SearchEntityBtn);
