import React from 'react';
import { formatMDYWithParam } from 'utils/formatDate';
/**
 ** COMPUTE PERCENT OF COMPLETENESS COLUMN
 * @param {{}} params
 * @returns {N} percent
 */
const computePercent = (params) => {
  const rowData = params.data;
  const { totalDataFields, totalMappingFields } = rowData;

  const percent =
    totalMappingFields > 0
      ? Math.round((totalDataFields / totalMappingFields) * 100)
      : 0;

  return percent;
};

/**
 ** MAPPING GRID COLUMN
 * @param {[]} columns : ;
 * @returns {[]} nextColumn ;
 */
const mapToMappingGridColDef = (columns) => {
  let nextColumns = [
    {
      headerName: 'Completeness',
      width: 150,

      cellRenderer: (params) => {
        const percent = computePercent(params);
        let style = {};

        if (percent <= 33) {
          style = { background: '#FF8042' };
        }
        if (percent > 33 && percent <= 66) {
          style = { background: '#FFBB28' };
        }
        if (percent > 66) {
          style = { background: '#00C49F' };
        }

        return (
          <div
            style={{
              ...style,
              fontWeight: 550,
              color: 'white',
              width: 35,
              height: 25,
              borderRadius: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 2,
            }}
          >
            <span>{percent}%</span>
          </div>
        );
      },
    },
  ];

  if (columns.length > 0) {
    let hiddenCol = ['id', 'mappingProductId'];

    columns.forEach((val) => {
      if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
        if (val.dataType === 'datetime') {
          val = {
            ...val,
            width: 150,
            cellRenderer: formatMDYWithParam,
            resizable: true,
          };
        } else {
          val = { ...val, width: 180, resizable: true };
        }
        nextColumns.push(val);
      }
    });
  }

  return nextColumns;
};

/**
 ** MAPPING SUPPORT MEMBER
 * @param {[]} columns : ;
 * @returns {[]} nextColumns : ;
 */
const mapToMappingSupportMemGridColDef = (columns) => {
  const nextColumns = [
    {
      field: '',
      width: 30,
      checkboxSelection: true,
      filter: false,
      suppressMenu: true,
    },
  ];

  if (columns.length > 0) {
    let hiddenCol = ['id'];

    columns.forEach((val) => {
      if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
        nextColumns.push({ ...val, flex: 1 });
      }
    });
  }

  return nextColumns;
};

export { mapToMappingGridColDef, mapToMappingSupportMemGridColDef };
