import { useQuery } from '@tanstack/react-query';

import { useQueryClient } from '@tanstack/react-query';

import * as ticketingServices from 'services/ticketingSystem';

export const useGetTicketSupplier = (props) => {
  const { enabled = false, ...restProps } = props;
  const queryClient = useQueryClient();

  const getTicketSuppliers = async ({ queryKey }) => {
    const response = await ticketingServices.getTicketSuppliers();

    const { isSuccess, data } = response;

    return isSuccess && data ? data?.suppliers : [];
  };

  const query = useQuery({
    queryKey: ['get-ticket-suppliers'],
    queryFn: getTicketSuppliers,
    enabled,
    ...restProps,
  });

  const invalidateTicketSupplierContact = () => {
    queryClient.invalidateQueries({
      queryKey: ['get-ticket-suppliers'],
    });
  };

  return { ...query, invalidateTicketSupplierContact };
};
