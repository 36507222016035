import { useLayoutEffect, useState } from 'react';

export const SCREEN_WIDTH = {
  EXTRA_LARGE: 1600,
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    let resizeTo;
    const updateSize = () => {
      if (resizeTo) clearTimeout(resizeTo);
      resizeTo = setTimeout(() => {
        setSize([window.innerWidth, window.innerHeight]);
      }, 500);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
