import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Row,
  Col,
  Input,
  Spin,
  Empty,
  Button,
  Typography,
  List,
  Divider,
  Tooltip,
  Avatar,
  notification,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AgGrid from 'common/components/ag-grid/AgGrid';
import {
  ThumbnailItem,
  TooltipParagraph,
  dialogFunction,
} from 'common/components';
import './LinkToAssetModal.less';
import * as endpoints from 'services/product/endpoints';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import * as productFullViewActions from '../../../controllers/actions';
import * as productFullViewSelectors from '../../../controllers/selectors';
import * as brandingActions from 'redux/branding/actions';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/product';
import AssetGS1Edit from 'pages/asset-full-view/components/add-link-product/AssetGS1Edit';
import classnames from 'classnames';

import { generateGS1CompliantName } from 'services/digitalAsset';
import { linkMultiAssetFromPIM } from 'services/product';

import { Images } from 'config/assets';

import * as constants from 'static/Constants';

const linkAssetsColumns = [
  {
    checkboxSelection: true,
    field: '',
    filter: false,
    resizable: false,
    suppressMenu: true,
    width: 50,
  },

  {
    dataType: 'string',
    displayName: 'File Extension',
    fieldName: 'fileExtension',
    fieldNameCamelCase: 'fileExtension',
    flex: 1,
    minWidth: 150,
  },
  {
    dataType: 'string',
    displayName: 'Asset Name',
    fieldName: 'assetName',
    fieldNameCamelCase: 'assetName',
    flex: 1,
    minWidth: 200,
  },
  {
    dataType: 'string',
    displayName: 'Asset Type',
    fieldName: 'assetType',
    fieldNameCamelCase: 'assetType',
    flex: 1,
    minWidth: 150,
  },
  {
    dataType: 'string',
    displayName: 'Sub Type',
    fieldName: 'assetSubType',
    fieldNameCamelCase: 'assetSubType',
    flex: 1,
    minWidth: 150,
  },
  {
    dataType: 'string',
    displayName: 'Status',
    fieldName: 'status',
    fieldNameCamelCase: 'status',
    flex: 1,
    minWidth: 100,
  },
];

const LinkToAssetModal = (props) => {
  const {
    productInfo,
    onClose,
    visible,
    getImagery,
    type,
    getMarketingAssets,
  } = props;
  const productId = productInfo?.productId;

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [rowsSelected, setRowSelected] = useState();
  const [addingLoading, setAddingLoading] = useState(false);
  const [requestParams, setRequestParams] = useState({});
  const [awaitingList, setAwaitingList] = useState([]);
  const [isAwaitingListLinkAsset, setIsAwaitingListLinkAsset] = useState(false);
  const submitRef = useRef(null);

  const setInitialState = () => {
    setSearch('');
    setRowSelected();
    setAddingLoading(false);
    setAwaitingList([]);
    setIsAwaitingListLinkAsset(false);
  };

  const handleCancel = () => {
    setInitialState();
    onClose();
  };

  useEffect(() => {
    let requestParams = {
      search: { searchText: search },
      productId: productId,
      filters:
        type === 'Planogram'
          ? [
              {
                fieldName: 'assetSubType',
                filterType: 'In',
                values: ['Planogram'],
              },
            ]
          : [
              {
                fieldName: 'assetSubType',
                filterType: 'NotIn',
                values: ['Planogram'],
              },
              {
                fieldName: 'assetType',
                filterType: 'NotIn',
                values: ['Document'],
              },
            ],
    };
    setRequestParams(requestParams);
  }, [search, productId]);

  const onSetSelectedRows = (selectedNodesDetail) => {
    if (selectedNodesDetail && selectedNodesDetail?.length > 0) {
      const findRowFromWatiting = awaitingList.find(
        (val) => val?.id === selectedNodesDetail[0]?.id
      );
      if (findRowFromWatiting) {
        setRowSelected(findRowFromWatiting);
        setIsAwaitingListLinkAsset(true);
      } else {
        setRowSelected(selectedNodesDetail[0]);
        setIsAwaitingListLinkAsset(false);
      }
    }
  };

  const handleRemoveAwaitingList = (id) => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete link asset?',
      onOk: () => confirmRemoveAwaitingList(id),
    });
  };

  const confirmRemoveAwaitingList = (id) => {
    setAwaitingList(awaitingList.filter((val) => val?.id !== id));
    if (id === rowsSelected?.id) {
      setIsAwaitingListLinkAsset(false);
      setRowSelected();
      gridApi?.deselectAll();
    }
  };

  const handleSubmitAwaitingList = (item) => {
    const findRowFromWatiting = awaitingList.find(
      (val) => val?.id === item?.id
    );
    if (findRowFromWatiting) {
      const nextAwaitingList = awaitingList.map((val) => {
        if (val?.id === item?.id) {
          return item;
        }
        return val;
      });
      setAwaitingList(nextAwaitingList);
      openNotification('success', 'Link image has been updated.');
    } else {
      setAwaitingList([...awaitingList, item]);
    }

    setRowSelected();
    gridApi?.deselectAll();
  };

  const handleClickItem = (item) => {
    setRowSelected(item);
    setIsAwaitingListLinkAsset(true);
    gridApi?.deselectAll();
  };

  const handleLink = async () => {
    setAddingLoading(true);

    const imageLinks = await Promise.all(
      awaitingList.map(async (val) => {
        const response =
          val?.associateType === constants.ASSOCIATION_TYPE.PLANOGRAM
            ? await generateGS1CompliantName({
                gtin: val.GTIN,
                imageType: val.imageType,
                facing: val.facing,
                angle: val.angle,
                state: val.packagingDisposition,
              })
            : true;

        if ((response.isSuccess && response.data?.fileName) || response) {
          const addedSection = {
            associateType: val?.associateType,
            gtin: val?.GTIN,
            compliantFileName: response?.data?.fileName,
            imageType: val?.imageType,
            packageLevel: val?.packageLevel,
            packagingDisposition: val?.packagingDisposition,
            angle: val?.angle,
            facing: val?.facing,
            linkType: val?.associateType,
            digitalAssetId: val.id,
          };
          return addedSection;
        }
      })
    );

    const params = { productItemId: productId, imageLinks };

    const res = await linkMultiAssetFromPIM(params);

    if (res?.isSuccess) {
      openNotification(
        'success',
        'Link all images has been updated successfully!'
      );
      handleCancel();
      getImagery();
      if (type === 'Additional' || type === 'Marketing') {
        if (typeof getMarketingAssets === 'function') {
          getMarketingAssets();
        }
      }
      dispatch(brandingActions.getBrandingNoLoading());
    } else {
      openNotification('error', 'Link all images has been updated failed!');
    }
    setAddingLoading(false);
  };

  const openNotification = (type, message, description) => {
    notification[type]({ message: message, description: description });
  };

  return (
    <Modal
      title={<FormattedMessage {...Messages.linkToExistAsset} />}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleLink}
      okButtonProps={{ disabled: awaitingList?.length === 0 }}
      width={'90vw'}
      height={'70%'}
      okText='Link All Images'
      centered
      destroyOnClose
      maskClosable={false}
      className='link-to-asset-modal'
    >
      <Spin spinning={addingLoading} tip='Linking to Assets...' size='large'>
        <Row className='link-to-asset-modal__wrapper'>
          <Col span={12}>
            <Input.Search
              className='link-to-asset-modal__search'
              placeholder='Search Asset...'
              onSearch={(value) => setSearch(value)}
            />
            <Row style={{ height: '100%', width: '100%' }}>
              <AgGrid
                columnDefs={linkAssetsColumns}
                urlGrid={endpoints.SEARCH_ASSET_NOT_ASSOCIATING}
                responseParams='data'
                requestParams={requestParams}
                isShowItemSelectionLog={false}
                onHandleSelectedRowCustom={onSetSelectedRows}
                getGridApi={(gridApi) => setGridApi(gridApi)}
                reloadGrid={visible}
                gridConfigProps={{
                  rowSelection: 'single',
                }}
                notShowHeaderCheckbox={true}
                // pageNumberPagination={pageNumber}
                showLessItem={true}
              />
            </Row>
          </Col>
          <Col
            span={awaitingList && awaitingList?.length > 0 ? 8 : 12}
            style={{ height: '100%' }}
          >
            {rowsSelected && (
              <div className='link-to-asset-modal__form'>
                <>
                  <Row
                    className='link-to-asset-modal__form--image-preview'
                    justify='center'
                  >
                    <ThumbnailItem
                      imageThumbnail={rowsSelected?.thumb}
                      hideCheckbox
                      hideLabel
                    />
                  </Row>
                  <Row
                    justify='center'
                    className='link-to-asset-modal__form--data '
                  >
                    <AssetGS1Edit
                      isPimImportExistAsset
                      isPimImport
                      pimId={productId}
                      assetId={rowsSelected?.id}
                      assetData={{
                        ...rowsSelected,
                        fileType: rowsSelected?.fileExtension, //* 4203: add file type as image to match for logic
                        metadata: { ...rowsSelected },
                      }}
                      productInfo={productInfo}
                      submitRef={submitRef}
                      isDisabledGtin={true}
                      setParentLoading={() => setAddingLoading(true)}
                      unsetParentLoading={() => setAddingLoading(false)}
                      onReloadPage={() => {
                        handleCancel();
                        getImagery();
                        if (type === 'Additional' || type === 'Marketing') {
                          if (typeof getMarketingAssets === 'function') {
                            getMarketingAssets();
                          }
                        }
                      }}
                      addType={type}
                      isAwaitingListLinkAsset={isAwaitingListLinkAsset}
                      handleSubmitAwaitingList={(value) => {
                        handleSubmitAwaitingList(value);
                      }}
                    />
                  </Row>
                </>
              </div>
            )}
            {!rowsSelected && (
              <Row className={'link-to-asset-modal__empty-form'}>
                <Empty description='Select asset to link'></Empty>
              </Row>
            )}
          </Col>
          {awaitingList && awaitingList?.length > 0 && (
            <Col span={4} style={{ height: '100%' }}>
              <Typography.Title level={4}>Awaiting List</Typography.Title>
              <Divider style={{ margin: 0 }} />
              <div
                className='scroller'
                style={{ height: '100%', paddingTop: 8 }}
              >
                <List
                  itemLayout='horizontal'
                  dataSource={awaitingList}
                  renderItem={(item) => (
                    <List.Item
                      className={classnames('link-to-asset-modal__item', {
                        'link-to-asset-modal__item--active':
                          item?.id === rowsSelected?.id,
                      })}
                    >
                      <List.Item.Meta
                        style={{ alignItems: 'center' }}
                        avatar={
                          <Avatar
                            shape='square'
                            size='large'
                            src={item?.thumb || Images.RIVIR_LOGO_DEFAULT}
                          />
                        }
                        title={
                          <TooltipParagraph
                            ellipsis={{ rows: 3 }}
                            style={{ marginBottom: 0 }}
                          >
                            {item?.assetName}
                          </TooltipParagraph>
                        }
                        description={
                          <div className='link-to-asset-modal__item--btn'>
                            <Tooltip title='Edit link asset'>
                              <Button
                                onClick={() => {
                                  handleClickItem(item);
                                }}
                                style={{
                                  borderRadius: '4px',
                                  marginRight: '4px',
                                }}
                                type='primary'
                              >
                                <EditOutlined />
                              </Button>
                            </Tooltip>
                            <Tooltip title='Remove link asset'>
                              <Button
                                style={{ borderRadius: '4px' }}
                                danger
                                onClick={(event) => {
                                  handleRemoveAwaitingList(item?.id);
                                }}
                              >
                                <DeleteOutlined />
                              </Button>
                            </Tooltip>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          )}
        </Row>
      </Spin>
    </Modal>
  );
};

LinkToAssetModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  getImagery: PropTypes.func,
};

export default LinkToAssetModal;
