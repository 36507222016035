import { useState, useEffect } from 'react';

import { useAsync } from 'hooks';

import { Form } from 'antd';
import * as api from 'config/axios';

import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { mapToSearchParams } from './mapper/mapToSearchParams';

import { get } from 'lodash';

const defaultPageInfo = {
  currentPageIndex: 1,
  currentPageSize: 20,
  totalRecord: null,
};

export const useSearchGrid = ({
  isModalOpen,
  params,
  savedSelected,
  dataPath,
  isMultiple,
  itemIdName,
  service,
  apiUrl,
  onlyFetchWithSearchText,
  mapSearch,
}) => {
  const [formInstance] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [showSearchHint, setShowSearchHint] = useState(true);

  const resetAll = () => {
    setSelectedItems([]);
    setPageInfo(defaultPageInfo);
    formInstance.resetFields();
    setShowSearchHint(true);
  };

  const { data, loading, fetchSearchList } = useFetchSearchList({
    isModalOpen,
    params,
    savedSelected,
    onlyFetchWithSearchText,
    dataPath,
    resetAll,
    service,
    apiUrl,
    showSearchHint,
    setShowSearchHint,
  });

  const handleSelectItem = (itemData) => {
    setSelectedItems((prev) => {
      let nextValue;
      if (prev !== undefined) {
        nextValue = [...prev];
      } else {
        nextValue = [];
      }

      const foundItemIdx = nextValue.findIndex(
        (item) =>
          (item?.[itemIdName] || item?.id) ===
          (itemData?.[itemIdName] || itemData?.id)
      );

      if (foundItemIdx !== -1) {
        nextValue.splice(foundItemIdx, 1);
      } else {
        if (!isMultiple) {
          nextValue = [itemData];
        } else {
          nextValue.push(itemData);
        }
      }

      return nextValue;
    });
  };

  const handleSearchItem = () => {
    const formValue = formInstance.getFieldsValue();

    const searchTextValue = formValue?.searchText;

    if (!searchTextValue) return;

    const procParams = mapToSearchParams(formValue, params, mapSearch);

    fetchSearchList(procParams);
  };

  return {
    formInstance,
    handleSelectItem,
    handleSearchItem,
    selectedItems,
    resetAll,
    isLoading: loading,
    showSearchHint,
    items: data,
  };
};

const useFetchSearchList = ({
  isModalOpen,
  params,
  savedSelected,
  onlyFetchWithSearchText,
  dataPath,
  resetAll,
  service,
  apiUrl,
  showSearchHint,
  setShowSearchHint,
}) => {
  const { run, data, status } = useAsync();

  const getServices = (params) => {
    if (service) return service(params);

    const endpoint = apiUrl;
    return api.sendPost(endpoint, params);
  };

  const fetchSearchList = (customParams = {}) => {
    //* search Hint only show before first fetch
    showSearchHint && onlyFetchWithSearchText && setShowSearchHint(false);
    run(getServices({ ...params, ...customParams }));
  };

  useEffect(() => {
    if (isModalOpen) {
      !onlyFetchWithSearchText && fetchSearchList(params);
    } else {
      !savedSelected && resetAll();
    }
  }, [
    isModalOpen,
    JSON.stringify(params),
    savedSelected,
    onlyFetchWithSearchText,
  ]);

  return {
    data: dataPath ? get(data, dataPath) : data?.gridData,
    pageInfo: data?.paging,
    loading: status === 'pending',
    fetchSearchList,
  };
};
