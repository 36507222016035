import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Checkbox } from 'antd';
import classnames from 'classnames';
import './AssetThumbnailCard.less';

const AssetThumbnailCard = ({ alt, src, id, handleToggleAssetContext }) => {
  const [isChecked, setCheckedStatus] = useState(false);

  const onCheckHandler = (e) => {
    setCheckedStatus(e.target.checked);
  };

  return (
    <Card className='asset-thumbnail'>
      <Checkbox
        checked={isChecked}
        onChange={onCheckHandler}
        className={classnames({
          'asset-thumbnail__checkbox': true,
          'asset-thumbnail__checkbox--inactive': !isChecked,
          'asset_thumbnail__checkbox--active': isChecked,
        })}
      />
      <img
        alt={alt}
        src={src}
        className='asset-thumbnail__img'
        onDoubleClick={() => handleToggleAssetContext(id)}
      />
    </Card>
  );
};

AssetThumbnailCard.propTypes = {
  // ? src
  src: PropTypes.string,
  // ? atl
  alt: PropTypes.string,
  // ? id
  id: PropTypes.string,
  //? handleToggleAssetContext
  handleToggleAssetContext: PropTypes.func,
};

export default AssetThumbnailCard;
