import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Checkbox, Row } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';
import useCustomScroll from 'hooks/useCustomScroll';
import * as mappingSelectors from 'pages/mapping/controllers/selectors';
import * as mappingActions from 'pages/mapping/controllers/actions';
import { MAPPING_FORMAT_TYPE } from 'static/Constants';

import Messages from 'i18n/messages/mapping';
import { useIntl } from 'react-intl';

import './FilterMapping.less';

const FormatTypeListRender = (props) => {
  const dispatch = useDispatch();

  const formatTypeFilter = useSelector(
    mappingSelectors.selectFormatTypeFilter()
  );

  useCustomScroll('.format-type-list-render');

  const handleSelectFormatType = (formatType) => {
    dispatch(gridViewActions.resetPageCurrent());
    dispatch(mappingActions.updateFormatTypeFilter(formatType));
  };

  return (
    <Row className='format-type-list-render'>
      <Checkbox.Group
        value={formatTypeFilter}
        onChange={handleSelectFormatType}
      >
        {MAPPING_FORMAT_TYPE.map((formatType, index) => (
          <Row key={index}>
            <Checkbox value={formatType}>{formatType}</Checkbox>
          </Row>
        ))}
      </Checkbox.Group>
    </Row>
  );
};

const FilterMapping = ({ ...rest }) => {
  const intl = useIntl();

  const formatTypeFilter = useSelector(
    mappingSelectors.selectFormatTypeFilter()
  );

  const isFiltering = formatTypeFilter?.length > 0;
  const filterBtnText = `${intl.formatMessage(Messages.filterMapping)}${
    isFiltering ? ` (+${formatTypeFilter?.length})` : ''
  }`;

  return (
    <Dropdown
      className='format-type-filter'
      overlay={<FormatTypeListRender {...rest} />}
      placement='bottomLeft'
      arrow
    >
      <RibbonButton
        icon={<FilterOutlined style={{ height: 26 }} />}
        toggle={isFiltering}
        label={filterBtnText}
      ></RibbonButton>
    </Dropdown>
  );
};

export default FilterMapping;
