import React from 'react';
import PropTypes from 'prop-types';

import { RibbonBar } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import BackControl from './components/controls/home/Back';

import { ViewLayout, AdvanceStack } from './components';
import { HIDE_CLOSE_DETAIL_BUTTON } from 'static/Constants';
import { DISABLE_SHOW_PREVIEW_IN_HOME_RIBBON } from 'static/Constants';
// const productsForMember = 'products/member';
// const assetsForMember = 'assets/member';

const Home = () => {
  const pathname = window.location.pathname;
  // const isBackToMemberProfile =
  //   pathname.includes(productsForMember) || pathname.includes(assetsForMember);
  // const isReportingsBack = pathname.includes('/reporting');

  const isDisableShowPreview = DISABLE_SHOW_PREVIEW_IN_HOME_RIBBON.some(
    (substring) => pathname.includes(substring)
  );

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection disabled={isDisableShowPreview} />
      <ViewLayout disabled />
      <AdvanceStack isDisabled />
      {!HIDE_CLOSE_DETAIL_BUTTON.includes(window.location.pathname) && (
        <BackControl
          ribbonSide='right'
          // isBackToMemberProfile={isBackToMemberProfile}
          // isReportingsBack={isReportingsBack}
        />
      )}
    </RibbonBar>
  );
};

Home.propTypes = {
  onSearch: PropTypes.func,
  toggleSearch: PropTypes.bool,
};

export default Home;
