import React from 'react';

import { ExperimentOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/apl';

import { RibbonButton } from 'common/components';

const RequestSample = (props) => {
  const { productList, setModalVisible } = props;

  const disabled = productList?.length !== 1;

  const onClickButton = () => {
    setModalVisible(true);
  };

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<ExperimentOutlined />}
        label={Messages.requestSample}
        disabled={disabled}
        onClick={onClickButton}
      />
    </>
  );
};

export default RequestSample;
