import { RIVIR_FIELDS, TAG_COLORS } from 'static/Constants';

import produce from 'immer';

export const getNewFieldsAfterChangeProperty = (
  data,
  index,
  value,
  propertyName
) => {
  const result = produce(data, (draftFile) => {
    draftFile[index][`${propertyName}`] = value;
  });

  return result;
};
export const getNewFieldsAfterChangePackageLevel = (data, index, value) => {
  const result = produce(data, (draftFile) => {
    draftFile[index].packageLevel = value;
    draftFile[index].fieldName = null;
  });

  return result;
};

export const getNewFieldsAfterChangeModuleName = (data, index, value) => {
  const result = produce(data, (draftFile) => {
    draftFile[index].moduleName = value;
    draftFile[index].fieldName = null;
  });

  return result;
};

export const getColorTag = (gln) => {
  if (gln[0] === '0') return TAG_COLORS[0];

  if (parseInt(gln.substring(0, 2)) % 2 === 0) return TAG_COLORS[1];

  if (parseInt(gln.substring(0, 2)) % 2 !== 0) return TAG_COLORS[2];
};

export const getListModuleNames = (productSchema) => {
  if (productSchema.length > 0) {
    return productSchema.map((schema) => ({
      moduleName: schema.moduleName,
      moduleDisplayName: schema.moduleDisplayName,
    }));
  }
  return [];
};

export const getNewFieldsAfterDelete = (fields, fieldKey) => {
  let cloneFields = fields.slice();
  cloneFields.splice(fieldKey, 1);
  return cloneFields;
};

export const getNewFieldsAfterClone = (fields, index, cloneField) => {
  let newFields = [];
  fields.forEach((field) => {
    if (!field) {
      newFields.push({
        packageLevel: '',
        moduleName: '',
        fieldName: '',
      });
    } else {
      newFields.push(field);
    }
  });

  const result = produce(newFields, (draftFile) => {
    draftFile[newFields.length - 1].moduleName = cloneField.moduleName;
    draftFile[newFields.length - 1].packageLevel = cloneField.packageLevel;
    draftFile[newFields.length - 1].fieldName = '';
  });

  return result;
};

const getSelectedFields = (fieldsState, fieldKey) => {
  if (fieldsState) {
    const { packageLevel } = fieldsState[fieldKey];

    let result = [];

    fieldsState.forEach((field) => {
      if (field && field.fieldName && field.packageLevel === packageLevel) {
        result.push(field.fieldName);
      }
    });

    return result;
  }
  return [];
};

export const getListFieldNames = (productSchema, fieldsState, fieldKey) => {
  if (fieldsState) {
    const selectedFields = getSelectedFields(fieldsState, fieldKey);

    if (fieldsState[fieldKey].type === 'RIVIR') {
      return RIVIR_FIELDS.filter(
        (field) => !selectedFields.includes(field.value)
      ).map((field) => ({
        fieldName: field.value,
        fieldDisplayName: field.name,
      }));
    }

    const foundModuleName = productSchema.find(
      (schema) => schema.moduleName === fieldsState[fieldKey].moduleName
    );

    if (!foundModuleName) return [];

    return foundModuleName.moduleProperties
      .filter((property) => !selectedFields.includes(property.propertyName))
      .map((property) => ({
        fieldName: property.propertyName,
        fieldDisplayName: property.propertyDisplayName,
      }));
  }
  return [];
};

export const getDisplayFieldName = (fieldKey, fieldsState, productSchema) => {
  if (fieldsState.length > 0) {
    if (
      fieldsState[fieldKey].type === 'RIVIR' &&
      fieldsState[fieldKey].fieldName
    ) {
      return fieldsState[fieldKey].fieldName;
    }

    const foundModuleName = productSchema.find(
      (schema) => schema.moduleName === fieldsState[fieldKey].moduleName
    );

    if (!foundModuleName) return `Field ${fieldKey + 1}`;

    const displayName = foundModuleName.moduleProperties.find(
      (property) => property.propertyName === fieldsState[fieldKey].fieldName
    );

    if (displayName) return displayName.propertyDisplayName;
    return `Field ${fieldKey + 1}`;
  }
  return `Field ${fieldKey + 1}`;
};

export const getRivirFieldDisplayName = (fieldName) => {
  const rivirField = RIVIR_FIELDS.find((field) => {
    return field.value === fieldName;
  });

  return rivirField?.name;
};

export const getFullFieldDisplayName = ({ fieldName, productSchema }) => {
  if (!productSchema) return '';
  if (!fieldName) return '';

  const fieldNamePath = fieldName.split('.');
  const displayNameListResult = fieldNamePath.reduce(
    (accumulator, currentFieldPath) => {
      accumulator = findFieldDisplayNameDeep({
        result: accumulator,
        fieldName: currentFieldPath,
      });

      return accumulator;
    },
    { displayNameList: [], fieldList: productSchema }
  );

  const displayName = displayNameListResult.displayNameList.join(' > ');

  return displayName;
};

const findFieldDisplayNameDeep = ({ result, fieldName }) => {
  const fieldData = result.fieldList?.find((fieldItem) => {
    const itemFieldName = fieldItem.moduleName || fieldItem.propertyName;
    const matched = fieldName.toLowerCase() === itemFieldName.toLowerCase();
    return matched ? fieldItem : null;
  });

  const fieldDisplayName = fieldData
    ? fieldData.moduleDisplayName || fieldData.propertyDisplayName
    : null;

  if (fieldDisplayName)
    result.displayNameList = [...result.displayNameList, fieldDisplayName];

  const childrenFields =
    fieldData?.moduleProperties || fieldData?.childProperties;

  if (childrenFields?.length) result.fieldList = childrenFields;

  return result;
};

export const checkDisabledModuleName = (fieldsState, fieldKey) => {
  if (fieldsState.length > 0) {
    return fieldsState[fieldKey].type === 'RIVIR';
  }
  return false;
};

export const filterFields = (fields) => {
  if (fields) {
    let result = [];
    fields.forEach((field) => {
      const { type, ...otherValues } = field;
      result.push(otherValues);
    });
    return result;
  }
  return [];
};

export const distinguishFieldsFromEditedFields = (fields) => {
  if (!fields.length) return [];
  return fields.map((fieldItem) => {
    const fieldName = fieldItem.fieldName;
    const isRivirType = fieldName.indexOf('.') < 0;

    return { ...fieldItem, type: isRivirType ? 'RIVIR' : null };
  });
};

export const updateModuleName = ({ key, moduleName, fieldList }) => {
  return produce(fieldList, (draft) => {
    draft[key].moduleName = moduleName;

    if (!moduleName) return; //* just clear field name when select module name

    draft[key].fieldName = null;
  });
};

export const getExpandedKeys = (productSchema, expandedKeyList) => {
  return productSchema.reduce((accumulator, currentSchemaItem) => {
    if (currentSchemaItem.moduleName)
      accumulator.push(currentSchemaItem.moduleName);

    if (currentSchemaItem.fieldFullPath)
      accumulator.push(currentSchemaItem.fieldFullPath);

    if (currentSchemaItem?.moduleProperties?.length)
      getExpandedKeys(currentSchemaItem.moduleProperties, accumulator);

    if (currentSchemaItem?.childProperties?.length)
      getExpandedKeys(currentSchemaItem.childProperties, accumulator);

    return accumulator;
  }, expandedKeyList || []);
};
