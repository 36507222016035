import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { Row, Col, Input } from 'antd';

import AdvancedSearchTitle from './AdvancedSearchTitle';
import EntitiesAndAttributes from './EntitiesAndAttributes';
import QueryConditions from './QueryConditions';

import _ from 'lodash';
import './AdvancedSearch.less';

const AdvancedSearch = (props) => {
  const {
    advancedSearch,
    advancedSearchShow,
    advancedSearchFields,
    queryConditions,
    selectedTaskIds,
    draggingTaskId,
    currentAreaDrop,
    queryChildHidden,
    collapseList,
    expandedList,
    productEnums,
    layoutToggle,
    handleSetAdvancedSearchShow,
    handleSetQueryConditions,
    handleSetSelectedTaskIds,
    handleSetDraggingTaskId,
    handleSetCurrentAreaDrop,
    handleSetQueryChildHidden,
    handleSetCollapseList,
    handleSetExpandedList,
  } = props;

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);
    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  });

  const onDragStart = (start) => {
    const id = start.draggableId;
    const selected = selectedTaskIds.find((taskId) => taskId === id);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }
    handleSetDraggingTaskId(start.draggableId);
    if (start.draggableId.indexOf('_query') > -1) {
      let itemDrag = queryConditions[start.source.index];
      let queryConditionsTemp = [...queryConditions];
      let queryHidden = [];
      let checkReturn = false;
      queryConditionsTemp.forEach((val, index) => {
        if (start.source.index < index) {
          if (val?.level > itemDrag?.level) {
            if (!checkReturn) {
              queryHidden.push(val.id);
            }
          } else {
            checkReturn = true;
          }
        }
      });
      handleSetQueryChildHidden(queryHidden);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId, combine } = result;

    // nothing to do
    if (!destination || result.reason === 'CANCEL') {
      if (!combine) {
        handleSetDraggingTaskId(null);
        return;
      } else {
        handleQueryConditionCombine(source, destination, draggableId, combine);
        handleSetDraggingTaskId(null);
        handleSetSelectedTaskIds([]);
        return;
      }
    }

    if (draggingTaskId.indexOf('_entity') > -1) {
      handleEntitiesAndAttributes(source, destination, draggableId);
    }

    if (draggingTaskId.indexOf('_query') > -1) {
      handleQueryCondiotions(source, destination, draggableId);
    }

    handleSetDraggingTaskId(null);
    handleSetSelectedTaskIds([]);
  };

  const handleEntitiesAndAttributes = (source, destination, draggableId) => {
    if (!selectedTaskIds.length) {
      let item = advancedSearchFields.find(
        (val) => val.mapFieldName + '_entity' === draggableId
      );
      if (destination?.droppableId === 'query_conditions') {
        let queryConditionsTemp = [...queryConditions];
        let maxId =
          handleFindMapId(queryConditionsTemp) > -1
            ? handleFindMapId(queryConditionsTemp)
            : -1;
        let itemNew = Object.assign({}, item);
        itemNew.level = 0;
        itemNew.id = maxId + 1;

        if (itemNew?.listName) {
          let enumItem = productEnums.filter(
            (val) => val.enumName === itemNew?.listName
          );
          let arrAcceptableValue = [];
          enumItem &&
            enumItem.length > 0 &&
            enumItem.map((val) => {
              if (val?.enumProperties && val?.enumProperties.length > 0) {
                val.enumProperties.map((value) => {
                  if (itemNew?.listName !== 'LanguageCodeISO639_1Enum') {
                    if (value?.enumDisplayName) {
                      arrAcceptableValue.push(value?.enumDisplayName);
                    }
                  } else {
                    arrAcceptableValue.push({
                      label: `${
                        value.enumDescription
                      } - (${value.enumCode.toUpperCase()})`,
                      value: value.enumCode,
                    });
                  }
                });
              }
              arrAcceptableValue.sort();
            });
          itemNew.acceptableValue = arrAcceptableValue;
        }

        queryConditionsTemp.splice(destination?.index, 0, itemNew);
        // queryConditionsTemp = _.uniqBy(queryConditionsTemp, 'displayName');
        handleSetQueryConditions(queryConditionsTemp);
      }
    } else {
      let data = [];
      advancedSearch &&
        advancedSearch.length > 0 &&
        advancedSearch.map((item) => {
          item &&
            item.fields &&
            item.fields.length > 0 &&
            item.fields.map((val) => {
              if (selectedTaskIds.indexOf(val.mapFieldName + '_entity') > -1) {
                data.push(val);
              }
            });
        });
      if (destination?.droppableId === 'query_conditions') {
        let queryConditionsTemp = [...queryConditions];
        let maxId =
          handleFindMapId(queryConditionsTemp) > -1
            ? handleFindMapId(queryConditionsTemp)
            : -1;
        data &&
          data.length > 0 &&
          data.map((val, i) => {
            let itemNew = Object.assign({}, val);
            itemNew.level = 0;
            itemNew.id = maxId + 1;
            maxId++;

            if (itemNew?.listName) {
              let enumItem = productEnums.filter(
                (val) => val.enumName === itemNew?.listName
              );
              let arrAcceptableValue = [];
              enumItem &&
                enumItem.length > 0 &&
                enumItem.map((val) => {
                  if (val?.enumProperties && val?.enumProperties.length > 0) {
                    val.enumProperties.map((value) => {
                      if (itemNew?.listName !== 'LanguageCodeISO639_1Enum') {
                        if (value?.enumDisplayName) {
                          arrAcceptableValue.push(value?.enumDisplayName);
                        }
                      } else {
                        arrAcceptableValue.push({
                          label: `${
                            value.enumDescription
                          } - (${value.enumCode.toUpperCase()})`,
                          value: value.enumCode,
                        });
                      }
                    });
                  }
                  arrAcceptableValue.sort();
                });
              itemNew.acceptableValue = arrAcceptableValue;
            }

            queryConditionsTemp.splice(destination?.index + i, 0, itemNew);
          });
        // queryConditionsTemp = _.uniqBy(queryConditionsTemp, 'displayName');
        handleSetQueryConditions(queryConditionsTemp);
      }
    }
  };

  const handleQueryCondiotions = (source, destination, draggableId) => {
    let queryConditionsTemp = [...queryConditions];

    if (
      queryChildHidden.length > 0 &&
      queryChildHidden.indexOf(queryConditionsTemp[destination.index].id) > -1
    ) {
      handleSetQueryChildHidden([]);
      return;
    }

    let levelNewItemDrag =
      destination.index === 0
        ? 0
        : queryConditionsTemp[
            source.index < destination.index
              ? destination.index === queryConditionsTemp.length - 1
                ? destination.index
                : destination.index + 1
              : destination.index
          ].level;
    let levelOldItemDrag = queryConditionsTemp[source.index].level;
    queryConditionsTemp[source.index].level = levelNewItemDrag;

    queryConditionsTemp = reorder(
      queryConditionsTemp,
      source.index,
      destination.index
    );

    if (queryChildHidden.length > 0) {
      queryChildHidden.forEach((queryChild, index) => {
        if (source.index < destination.index) {
          queryConditionsTemp[source.index].level =
            queryConditionsTemp[source.index].level -
            levelOldItemDrag +
            levelNewItemDrag;
          queryConditionsTemp = reorder(
            queryConditionsTemp,
            source.index,
            destination.index
          );
        } else {
          queryConditionsTemp[source.index + index + 1].level =
            queryConditionsTemp[source.index + index + 1].level -
            levelOldItemDrag +
            levelNewItemDrag;
          queryConditionsTemp = reorder(
            queryConditionsTemp,
            source.index + index + 1,
            destination.index + index + 1
          );
        }
      });
    }

    handleSetQueryConditions(queryConditionsTemp);
    handleSetQueryChildHidden([]);
  };

  const handleQueryConditionCombine = (
    source,
    destination,
    draggableId,
    combine
  ) => {
    let queryConditionsTemp = [...queryConditions];
    let indexIemCombine = queryConditions.findIndex(
      (val) =>
        val.mapFieldName + '_query' + '__' + val.id === combine.draggableId
    );
    let itemDrag = advancedSearchFields.find(
      (val) => val.mapFieldName + '_entity' === draggableId
    );
    if (draggingTaskId.indexOf('_entity') > -1) {
      if (!selectedTaskIds.length) {
        let maxId =
          handleFindMapId(queryConditionsTemp) > -1
            ? handleFindMapId(queryConditionsTemp)
            : -1;
        let itemNew = Object.assign({}, itemDrag);
        itemNew.level = queryConditionsTemp[indexIemCombine]?.level + 1;
        itemNew.id = maxId + 1;

        if (itemNew?.listName) {
          let enumItem = productEnums.filter(
            (val) => val.enumName === itemNew?.listName
          );
          let arrAcceptableValue = [];
          enumItem &&
            enumItem.length > 0 &&
            enumItem.map((val) => {
              if (val?.enumProperties && val?.enumProperties.length > 0) {
                val.enumProperties.map((value) => {
                  if (itemNew?.listName !== 'LanguageCodeISO639_1Enum') {
                    if (value?.enumDisplayName) {
                      arrAcceptableValue.push(value?.enumDisplayName);
                    }
                  } else {
                    arrAcceptableValue.push({
                      label: `${
                        value.enumDescription
                      } - (${value.enumCode.toUpperCase()})`,
                      value: value.enumCode,
                    });
                  }
                });
              }
              arrAcceptableValue.sort();
            });
          itemNew.acceptableValue = arrAcceptableValue;
        }

        queryConditionsTemp.splice(indexIemCombine + 1, 0, itemNew);
      } else {
        let data = [];
        advancedSearch &&
          advancedSearch.length > 0 &&
          advancedSearch.map((item) => {
            item &&
              item.fields &&
              item.fields.length > 0 &&
              item.fields.map((val) => {
                if (
                  selectedTaskIds.indexOf(val.mapFieldName + '_entity') > -1
                ) {
                  data.push(val);
                }
              });
          });
        let maxId =
          handleFindMapId(queryConditionsTemp) > -1
            ? handleFindMapId(queryConditionsTemp)
            : -1;
        data &&
          data.length > 0 &&
          data.map((val, i) => {
            let itemNew = Object.assign({}, val);
            itemNew.level = queryConditionsTemp[indexIemCombine]?.level + 1;
            itemNew.id = maxId + 1;
            maxId++;

            if (itemNew?.listName) {
              let enumItem = productEnums.filter(
                (val) => val.enumName === itemNew?.listName
              );
              let arrAcceptableValue = [];
              enumItem &&
                enumItem.length > 0 &&
                enumItem.map((val) => {
                  if (val?.enumProperties && val?.enumProperties.length > 0) {
                    val.enumProperties.map((value) => {
                      if (itemNew?.listName !== 'LanguageCodeISO639_1Enum') {
                        if (value?.enumDisplayName) {
                          arrAcceptableValue.push(value?.enumDisplayName);
                        }
                      } else {
                        arrAcceptableValue.push({
                          label: `${
                            value.enumDescription
                          } - (${value.enumCode.toUpperCase()})`,
                          value: value.enumCode,
                        });
                      }
                    });
                  }
                  arrAcceptableValue.sort();
                });
              itemNew.acceptableValue = arrAcceptableValue;
            }

            queryConditionsTemp.splice(indexIemCombine + 1 + i, 0, itemNew);
          });
      }
    } else {
      let itemQueryConditions = queryConditionsTemp.find(
        (val) =>
          val.mapFieldName + '_query' + '__' + val.id === combine.draggableId
      );
      let indexQueryChildren = queryChildHidden.indexOf(itemQueryConditions.id);

      if (queryChildHidden.length > 0 && indexQueryChildren > -1) {
        handleSetQueryChildHidden([]);
        return;
      }
      let levelNewItemDrag = queryConditionsTemp[indexIemCombine]?.level + 1;

      let removeItemDrag = queryConditionsTemp.splice(source.index, 1);
      let levelOldItemDrag = removeItemDrag[0]?.level;
      removeItemDrag[0].level = levelNewItemDrag;
      let indexNew =
        indexIemCombine < source.index ? indexIemCombine + 1 : indexIemCombine;
      queryConditionsTemp.splice(indexNew, 0, removeItemDrag[0]);
      if (queryChildHidden.length > 0) {
        queryChildHidden.forEach((queryChild, index) => {
          if (source.index < indexNew) {
            let removeQueryChild = queryConditionsTemp.splice(source.index, 1);
            removeQueryChild[0].level =
              removeQueryChild[0].level - levelOldItemDrag + levelNewItemDrag;
            queryConditionsTemp.splice(indexNew, 0, removeQueryChild[0]);
          } else {
            let removeQueryChild = queryConditionsTemp.splice(
              source.index + index + 1,
              1
            );
            removeQueryChild[0].level =
              removeQueryChild[0].level - levelOldItemDrag + levelNewItemDrag;
            queryConditionsTemp.splice(
              indexNew + index + 1,
              0,
              removeQueryChild[0]
            );
          }
        });
      }
    }

    handleSetQueryConditions(queryConditionsTemp);
    handleSetQueryChildHidden([]);
  };

  const handleFindMapId = (list) => {
    return Math.max.apply(
      Math,
      list.map(function (o) {
        return o.id;
      })
    );
  };

  const handleRemoveQueryConditions = (id) => {
    let queryConditionsTemp = [...queryConditions];

    let indexItemRemove = queryConditionsTemp.findIndex(
      (item) => item.id === id
    );
    let queryChildren = [];
    let checkReturn = false;
    queryConditionsTemp.forEach((val, index) => {
      if (indexItemRemove < index) {
        if (val?.level > queryConditionsTemp[indexItemRemove]?.level) {
          if (!checkReturn) {
            queryConditionsTemp[index].level = val?.level - 1;
          }
        } else {
          checkReturn = true;
        }
      }
    });
    queryConditionsTemp = queryConditionsTemp.filter((item) => item.id !== id);

    handleSetQueryConditions(queryConditionsTemp);
  };

  const handleQueryCondition = (id, value) => {
    let queryConditionsTemp = [...queryConditions];
    let indexQuery = queryConditionsTemp.findIndex((item) => item.id === id);

    let objTemp = Object.assign({}, queryConditionsTemp[indexQuery]);
    objTemp.queryCondition = value;
    queryConditionsTemp[indexQuery] = objTemp;

    handleSetQueryConditions(queryConditionsTemp);
  };

  const handleQueryValueConditionIn = (id, condition, value) => {
    let queryConditionsTemp = [...queryConditions];
    let indexQuery = queryConditionsTemp.findIndex((item) => item.id === id);

    let objTemp = Object.assign({}, queryConditionsTemp[indexQuery]);
    objTemp.queryCondition = condition;
    objTemp.value = value;
    queryConditionsTemp[indexQuery] = objTemp;

    handleSetQueryConditions(queryConditionsTemp);
  };

  const handleQueryValue = (id, value) => {
    let queryConditionsTemp = [...queryConditions];
    let indexQuery = queryConditionsTemp.findIndex((item) => item.id === id);

    let objTemp = Object.assign({}, queryConditionsTemp[indexQuery]);
    objTemp.value = value;
    queryConditionsTemp[indexQuery] = objTemp;

    handleSetQueryConditions(queryConditionsTemp);
  };
  const handleQueryOtherValue = (id, value) => {
    let queryConditionsTemp = [...queryConditions];
    let indexQuery = queryConditionsTemp.findIndex((item) => item.id === id);

    let objTemp = Object.assign({}, queryConditionsTemp[indexQuery]);
    objTemp.otherValue = value;
    queryConditionsTemp[indexQuery] = objTemp;

    handleSetQueryConditions(queryConditionsTemp);
  };

  const handleQueryDateBetween = (id, dateStrings) => {
    let queryConditionsTemp = [...queryConditions];
    let indexQuery = queryConditionsTemp.findIndex((item) => item.id === id);

    let objTemp = Object.assign({}, queryConditionsTemp[indexQuery]);
    objTemp.value =
      (dateStrings && dateStrings.length > 1 && dateStrings[0]) || null;
    objTemp.otherValue =
      (dateStrings && dateStrings.length > 1 && dateStrings[1]) || null;
    queryConditionsTemp[indexQuery] = objTemp;

    handleSetQueryConditions(queryConditionsTemp);
  };

  const handleConjunction = (id, checked) => {
    let queryConditionsTemp = [...queryConditions];
    let indexQuery = queryConditionsTemp.findIndex((item) => item.id === id);

    let objTemp = Object.assign({}, queryConditionsTemp[indexQuery]);
    objTemp.conjunction = checked;
    queryConditionsTemp[indexQuery] = objTemp;

    handleSetQueryConditions(queryConditionsTemp);
  };

  const onWindowKeyDown = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unselectAll();
    }
  };

  const onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const onWindowTouchEnd = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const toggleSelection = (taskId, areaDrop) => {
    const wasSelected = selectedTaskIds.includes(taskId);

    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();
    handleSetCurrentAreaDrop(areaDrop);
    handleSetSelectedTaskIds(newTaskIds);
  };

  const toggleSelectionInGroup = (taskId, areaDrop) => {
    if (currentAreaDrop !== areaDrop) {
      handleSetSelectedTaskIds([]);
      toggleSelection(taskId, areaDrop);
      return;
    }

    const index = selectedTaskIds.indexOf(taskId);

    // if not selected - add it to the selected items
    if (index === -1) {
      handleSetSelectedTaskIds([...selectedTaskIds, taskId]);
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedTaskIds];
    shallow.splice(index, 1);
    handleSetSelectedTaskIds(shallow);
  };

  const handleExpand = (displayName) => {
    handleSetCollapseList((c) => c.concat(displayName));
    handleSetExpandedList((c) => c.concat(displayName));
  };

  const handleCollapse = (displayName) => {
    handleSetCollapseList((c) => c.filter((val) => val !== displayName));
  };

  // This behaviour matches the MacOSX finder selection
  const multiSelectTo = (newTaskId, areaDrop) => {
    if (currentAreaDrop !== areaDrop) {
      handleSetSelectedTaskIds([]);
      toggleSelection(newTaskId, areaDrop);
      return;
    }
    if (!selectedTaskIds.length) {
      handleSetSelectedTaskIds(newTaskId);
      return;
    }
    let dataListFields = [];
    if (areaDrop === 'entity') {
      dataListFields = advancedSearchFields;
    }
    if (areaDrop === 'query') {
      dataListFields = queryConditions;
    }

    let indexFirst = dataListFields.findIndex(
      (val) => val.mapFieldName + '_' + areaDrop === selectedTaskIds[0]
    );
    let indexLast = dataListFields.findIndex(
      (val) => val.mapFieldName + '_' + areaDrop === newTaskId
    );
    if (indexFirst === indexLast) {
      return;
    }
    if (indexFirst > indexLast) {
      let indexTemp = indexFirst;
      indexFirst = indexLast;
      indexLast = indexTemp;
    }
    let dataFieldsMultiSelect = [];
    dataListFields.forEach((val, index) => {
      if (index >= indexFirst && index <= indexLast) {
        dataFieldsMultiSelect.push(val.mapFieldName + '_' + areaDrop);
      }
    });
    handleSetSelectedTaskIds(dataFieldsMultiSelect);
  };

  const unselect = () => {
    unselectAll();
  };

  const unselectAll = () => {
    handleSetSelectedTaskIds([]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onSearchEntities = (value) => {
    let advancedSearchShowTemp = [];

    let advancedSearchTemp = [...advancedSearch];
    advancedSearchTemp &&
      advancedSearchTemp.length > 0 &&
      advancedSearchTemp.map((val, index) => {
        let valTemp = Object.assign({}, val);
        valTemp.fields = [];
        advancedSearchShowTemp.push(valTemp);
        val &&
          val.fields &&
          val.fields.length > 0 &&
          val.fields.forEach((field) => {
            if (
              field.displayName.toUpperCase().indexOf(value.toUpperCase()) > -1
            ) {
              advancedSearchShowTemp[index].fields.push(field);
            }
          });
      });
    handleSetCollapseList([]);
    handleSetExpandedList([]);
    handleSetAdvancedSearchShow(advancedSearchShowTemp);
  };

  const handleUploadAdvancedFile = (data, id, removeFile) => {
    let queryConditionsTemp = [...queryConditions];
    let indexQuery = queryConditionsTemp.findIndex((item) => item.id === id);
    if (removeFile) {
      queryConditionsTemp[indexQuery].value = null;
      queryConditionsTemp[indexQuery].acceptableValue = [
        ...queryConditionsTemp[indexQuery].acceptableValueTemp,
      ];

      queryConditionsTemp[indexQuery].possibleOperator = [
        ...queryConditionsTemp[indexQuery].possibleOperatorTemp,
      ];
      queryConditionsTemp[indexQuery].queryCondition =
        queryConditionsTemp[indexQuery].possibleOperatorTemp.sort()[0];
      queryConditionsTemp[indexQuery].fileOverload = false;
    } else {
      if (data && data?.length > 100000) {
        queryConditionsTemp[indexQuery].value = null;
        queryConditionsTemp[indexQuery].acceptableValueTemp = [
          ...queryConditionsTemp[indexQuery].acceptableValue,
        ];
        queryConditionsTemp[indexQuery].acceptableValue = data;
        queryConditionsTemp[indexQuery].possibleOperatorTemp = [
          ...queryConditionsTemp[indexQuery].possibleOperator,
        ];
        queryConditionsTemp[indexQuery].possibleOperator = ['In'];
        queryConditionsTemp[indexQuery].queryCondition = 'In';
        queryConditionsTemp[indexQuery].fileOverload = true;
      } else {
        queryConditionsTemp[indexQuery].value = data;
        queryConditionsTemp[indexQuery].acceptableValueTemp = [
          ...queryConditionsTemp[indexQuery].acceptableValue,
        ];
        queryConditionsTemp[indexQuery].acceptableValue = data;
        queryConditionsTemp[indexQuery].possibleOperatorTemp = [
          ...queryConditionsTemp[indexQuery].possibleOperator,
        ];
        queryConditionsTemp[indexQuery].possibleOperator = ['In'];
        queryConditionsTemp[indexQuery].queryCondition = 'In';
      }
    }
    handleSetQueryConditions(queryConditionsTemp);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Row
        style={{ width: '100%', height: '100%' }}
        className='advanced-search'
      >
        <Col span={8}>
          <Row className='advanced-search__condition' style={{ padding: 8 }}>
            <Col span={24} className='advanced-search__list'>
              <AdvancedSearchTitle title={'Entities and Attributes'} />
              <Row className='advanced-search__header-search'>
                <Input.Search
                  placeholder='Input search Entities and Attributes'
                  // enterButton
                  size='large'
                  className='advanced-search__search-entities'
                  onSearch={onSearchEntities}
                />
              </Row>
              {advancedSearch && (
                <EntitiesAndAttributes
                  advancedSearch={advancedSearchShow}
                  selectedTaskIds={selectedTaskIds}
                  draggingTaskId={draggingTaskId}
                  toggleSelection={toggleSelection}
                  toggleSelectionInGroup={toggleSelectionInGroup}
                  multiSelectTo={multiSelectTo}
                  collapseList={collapseList}
                  expandedList={expandedList}
                  handleCollapse={handleCollapse}
                  handleExpand={handleExpand}
                  advancedSearchGridView={true}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <Row
            className='advanced-search__condition'
            style={{ padding: '8px 8px 8px 0px' }}
          >
            <Col span={24} className='advanced-search__list'>
              <AdvancedSearchTitle title={'Query Conditions'} />
              <QueryConditions
                queryConditions={queryConditions}
                selectedTaskIds={selectedTaskIds}
                draggingTaskId={draggingTaskId}
                toggleSelection={toggleSelection}
                toggleSelectionInGroup={toggleSelectionInGroup}
                multiSelectTo={multiSelectTo}
                layoutToggle={layoutToggle}
                queryChildHidden={queryChildHidden}
                handleRemoveQueryConditions={handleRemoveQueryConditions}
                handleQueryCondition={handleQueryCondition}
                handleQueryValueConditionIn={handleQueryValueConditionIn}
                handleQueryValue={handleQueryValue}
                handleConjunction={handleConjunction}
                handleQueryOtherValue={handleQueryOtherValue}
                advancedSearchGridView={true}
                handleQueryDateBetween={handleQueryDateBetween}
                handleUploadAdvancedFile={handleUploadAdvancedFile}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DragDropContext>
  );
};

AdvancedSearch.propTypes = {
  advancedSearch: PropTypes.array,
  advancedSearchShow: PropTypes.array,
  advancedSearchFields: PropTypes.array,
  queryConditions: PropTypes.array,
  selectedTaskIds: PropTypes.array,
  draggingTaskId: PropTypes.string,
  currentAreaDrop: PropTypes.string,
  queryChildHidden: PropTypes.array,
  collapseList: PropTypes.array,
  expandedList: PropTypes.array,
  productEnums: PropTypes.array,
  handleSetAdvancedSearchShow: PropTypes.func,
  handleSetQueryConditions: PropTypes.func,
  handleSetSelectedTaskIds: PropTypes.func,
  handleSetDraggingTaskId: PropTypes.func,
  handleSetCurrentAreaDrop: PropTypes.func,
  handleSetQueryChildHidden: PropTypes.func,
  handleSetCollapseList: PropTypes.func,
  handleSetExpandedList: PropTypes.func,
};

export default React.memo(AdvancedSearch);
