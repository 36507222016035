import React from 'react';

import { Button, Tooltip } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const MdCCEula = (props) => {
  const { onAddCCEula } = props;

  const onClick = (e) => {
    onAddCCEula && onAddCCEula(e);
  };

  return (
    <Tooltip title='Add Credit Card Terms & Conditions Content'>
      <Button
        className='md-editor-custom-toolbar__btn'
        icon={<CodeOutlined />}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default MdCCEula;
