import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Dropdown, Menu, Tooltip } from 'antd';
import { FormEditButton } from 'common/components';
import { CaretDownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';
import * as productDetailSelectors from '../../controllers/selectors';
import * as productFullViewActions from '../../controllers/actions';
import { isEmpty } from 'lodash';
import * as globalSelectors from 'redux/global/selectors';

const { Title, Text, Link } = Typography;

const ProductOverviewTitle = ({ product, ...rest }) => {
  const { isHierarchy, isFixPublicationErrorView, setEditProductId } = rest;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [currentHierarchy, setCurrentHierarchy] = useState(null);

  const productHierarchies = useSelector(
    productDetailSelectors.makeSelectProductDetailHeader()
  );

  const hierarchyAlertId = useSelector(
    globalSelectors.selectHierarchyAlertId()
  );

  const currentHierarchyShortDetail = useSelector(
    productDetailSelectors.makeSelectCurrentHierarchyShortDetails()
  );

  const reSelectCurrentHierarchy = () => {
    if (!currentHierarchy) return;
    handleHierarchyMenuClick({ key: currentHierarchy.productHierarchyId });
  };

  useEffect(() => {
    if (productHierarchies?.length > 0) {
      if (!currentHierarchy) {
        setCurrentHierarchy(productHierarchies[0]);
        dispatch(
          productFullViewActions.setCurrentHierarchy(productHierarchies[0])
        );
      }
    } else {
      dispatch(
        productFullViewActions.getProductHierarchyShortDetailsSuccess(null)
      );
    }

    reSelectCurrentHierarchy();
  }, [productHierarchies, dispatch]);

  useEffect(() => {
    if (hierarchyAlertId) {
      const tempHierarchy = productHierarchies?.filter(
        (item) => item?.productHierarchyId === hierarchyAlertId
      )[0];
      if (tempHierarchy) {
        setCurrentHierarchy(tempHierarchy);
        dispatch(productFullViewActions.setCurrentHierarchy(tempHierarchy));
      } else {
        setCurrentHierarchy(productHierarchies[0]);
        dispatch(
          productFullViewActions.setCurrentHierarchy(productHierarchies[0])
        );
      }
    }
  }, [hierarchyAlertId]);

  useEffect(() => {
    if (
      !isEmpty(currentHierarchy) &&
      currentHierarchy?.productHierarchyId !==
      currentHierarchyShortDetail?.data?.hierarchyId
    ) {
      dispatch(
        productFullViewActions.getProductHierarchyShortDetails({
          hierarchyId: currentHierarchy?.productHierarchyId,
        })
      );
    }
  }, [JSON.stringify(currentHierarchy)]);

  const handleHierarchyMenuClick = (element) => {
    const productHierarchy = productHierarchies?.find(
      (productHierarchyItem) => {
        return productHierarchyItem.productHierarchyId === +element.key;
      }
    );

    setCurrentHierarchy(productHierarchy);
    dispatch(productFullViewActions.setCurrentHierarchy(productHierarchy));
  };

  const getTitleProduct = () => {
    return `${product?.gtin ? product?.gtin + '- ' : ' '} ${product?.productName || product?.productDescription
      ? product?.productName || product?.productDescription
      : ''
      }`;
  };

  const titleProduct = getTitleProduct();

  const hierarchyMenu = (
    <Menu
      onClick={handleHierarchyMenuClick}
      style={{ minWidth: 150, textAlign: 'right' }}
      className='product-overview__hierarchy-menu scroller'
    >
      {productHierarchies?.length > 0 &&
        productHierarchies.map((level, index) => (
          <Menu.Item key={level.productHierarchyId}>
            {level?.packDescription || 'No Description'}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <>
      {isHierarchy && !isFixPublicationErrorView ? (
        <a
          className='product-overview__open-btn'
          href={`/product/${product.productId}`}
          target='_blank'
        >
          {intl.formatMessage(Messages.openProduct)}
        </a>
      ) : null}

      {isFixPublicationErrorView && (
        <FormEditButton onClick={() => setEditProductId(product.productId)}>
          Edit product
        </FormEditButton>
      )}

      <Title className='product-overview__product-name' ellipsis={{ rows: 2, tooltip: titleProduct, onEllipsis: () => { } }} level={4}>
        {titleProduct}
      </Title>

      <Link
        href={`/company/${product?.ownerId}`}
        style={{ fontSize: '13px', fontWeight: 500, letterSpacing: '1px' }}
        target='_blank'
      >
        {product?.ownerName}
      </Link>
      {!isHierarchy && productHierarchies?.length > 0 && (
        <Title className='product-overview__hierarchy-config' level={5}>
          <Text>{intl.formatMessage(Messages.hierarchyTitle)}:</Text>
          <Text> {currentHierarchy?.packDescription || 'No description'} </Text>
          <Dropdown overlay={hierarchyMenu} placement='bottomRight'>
            <Button
              className='product-overview__hierarchy-config-btn'
              type='link'
              icon={<CaretDownOutlined />}
            ></Button>
          </Dropdown>
        </Title>
      )}
    </>
  );
};

ProductOverviewTitle.propTypes = {
  product: PropTypes.object,
  isHierarchy: PropTypes.bool,
};

export default ProductOverviewTitle;
