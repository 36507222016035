import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  view: 'Home', // option value
  error: null,
  typeView: 'thumbnails',
  heightRibbon: 0,
  saveClick: false,
  canBackRoute: false,
  layout: 'overview',
  showBanList: false,
  closeDetailStack: {
    name: null,
    stack: [],
  },
  storedCloseDetailStack: [],
  ribbonDom: null,
};

/* eslint-disable default-case, no-param-reassign */
const ribbonReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.CHANGE_RIBBON_VIEW:
        draft.loading = true;
        break;
      case types.CHANGE_RIBBON_VIEW_SUCCESS:
        draft.loading = false;
        draft.view = action.view;
        draft.error = null;
        break;
      case types.CHANGE_RIBBON_VIEW_ERROR:
        draft.view = null;
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.CHANGE_RIBBON_TYPE:
        draft.typeView = action.typeView;
        break;
      case types.CHANGE_RIBBON_HEIGHT:
        draft.heightRibbon = action.heightRibbon;
        break;
      case types.SAVE_CLICK:
        draft.saveClick = action.saveClick;
        break;
      case types.SET_CAN_BACK_ROUTE:
        draft.canBackRoute = true;
        break;
      case types.CHANGE_RIBBON_MEMBER_LAYOUT:
        draft.layout = action.layout;
        break;
      case types.TOGGLE_BAN_LIST:
        draft.showBanList = !draft.showBanList;
        break;
      case types.CREATE_CLOSE_DETAIL_STACK:
        draft.closeDetailStack.name = action.name;
        draft.closeDetailStack.stack = [action.pathname];
        break;
      case types.UPDATE_CLOSE_DETAIL_STACK:
        draft.closeDetailStack = action.stack;
        break;
      case types.STORE_CURRENT_CLOSE_DETAIL_STACK_TO_SWITCH_STACK:
        draft.storedCloseDetailStack.push(action.stack);
        break;
      case types.UPDATE_STORED_STACK:
        draft.storedCloseDetailStack = action.stack;
        break;

      case types.UPDATE_RIBBON_DOM:
        draft.ribbonDom = action.ribbonDom;
        break;
    }
  });

export default ribbonReducer;
