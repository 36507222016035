/**
 * @source https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation
 */

import { useEffect, useRef, useLayoutEffect, useCallback } from 'react';

const useEffectEvent = (handler) => {
  const handlerRef = useRef(null);

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current;
    return fn(...args);
  }, []);
};

const useDocumentAttachEvent = (event) => {
  const { name, handler, dependencies = [], condition = true } = event;

  const onLatestHandler = useEffectEvent((e) => {
    handler(e);
  });

  useEffect(() => {
    if (!condition) return;

    document.removeEventListener(name, onLatestHandler);
    document.addEventListener(name, onLatestHandler);

    return () => {
      document.removeEventListener(name, onLatestHandler);
    };
    //eslint-disable-next-line
  }, dependencies);
};

export default useDocumentAttachEvent;
