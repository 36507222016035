import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Messages from 'i18n/messages/maintenance';
import { UserOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as globalSelectors from 'redux/global/selectors';
import * as memberMaintenanceActions from 'pages/member-maintenance/controllers/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import * as actionsGlobal from 'redux/global/actions';
import { forwardTo } from 'utils/common/route';

const Users = ({ ...rest }) => {
  const dispatch = useDispatch();
  const visibleContentPane = useSelector(selectorsGridView.makeSelectVisible());
  const isShowDetailOn = useSelector(globalSelectors.selectShowDetail());
  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );
  const changeMaintenanceView = () => {
    if (isShowDetailOn) {
      dispatch(actionsGlobal.hideDetail());
    }
    if (visibleContentPane) {
      dispatch(actionsGridView.updateVisible(false));
    }
    let memberId = detailCurrentItemsSelection[0]?.id;
    forwardTo(`/maintenance/member-management/${memberId}`);
  };

  return (
    <RibbonButton
      style={{ width: 52 }}
      icon={<UserOutlined />}
      label={Messages.users}
      onClick={changeMaintenanceView}
      {...rest}
    />
  );
};

export default Users;
