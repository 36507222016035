import React from 'react';
import { Alert } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import './SessionEnded.less';

export const SessionEnded = (props) => {
  const { title, message } = props;
  return (
    <Alert
      className='mfa-session-ended'
      message={title && title}
      description={<div>{message && message}</div>}
      type='info'
      icon={<InfoCircleFilled />}
      showIcon
    />
  );
};
