import { useState, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { dialogFunction, CustomNotification } from 'common/components';
import DocumentActions from '../components/qa-grid-cell/DocumentActions';

import { useFetch } from 'hooks/useAsync';

import { getAllRows } from 'pages/qa-spec/utils';
import { formatMDY } from 'utils/formatDate';
import { apiHandler } from 'utils/api';
import { bytesToMegaBytes } from 'utils/string';

import * as qaSpecServices from 'services/qaSpec';
import * as api from 'config/axios';

import { useReloadDocumentCertificateTab } from 'hooks/useReloadDocumentCertificateTab';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/qa-spec';
import CellActionRenderSnapshotWrapper from '../components/qa-grid-cell/CellActionRenderSnapshotWrapper';
import useCheckSnapshotForRetailer from './useCheckSnapshotForRetailer';

export const useProductSpecDocument = ({ productId, gridInst }) => {
  const intl = useIntl();

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const { status, run } = useFetch();
  const { reloadDocumentWhenDelete, reloadDocumentCertificateTab } =
    useReloadDocumentCertificateTab();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const columnDefs = useMemo(() => {
    const cols = [
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Document Name',
        field: 'documentName',
        editable: false,
        flex: null,
        width: 400,
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Characteristic Type',
        field: 'characteristicsType',
        editable: false,
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'File Type',
        field: 'fileType',
        editable: false,
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'File Size',
        field: 'fileSize',
        valueGetter: ({ data }) => bytesToMegaBytes(data?.['fileSize']) + ' MB',
        editable: false,
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Upload Date',
        field: 'uploadDate',
        valueGetter: ({ data }) => formatMDY(data?.['uploadDate']),
        editable: false,
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: '',
        editable: false,
        flex: null,
        width: 120,
        cellRenderer: (params) => {
          const linkUrl = params?.data?.linkUrl;
          const linkUrlSnapshot =
            params?.context?.snapshotGridValues?.[params?.rowIndex]?.linkUrl;

          return (
            <>
              {isToggleSnapshot ? (
                <CellActionRenderSnapshotWrapper
                  {...params}
                  isHighlightChange={linkUrl !== linkUrlSnapshot}
                >
                  <DocumentActions
                    onPreview={() => previewDocument(linkUrlSnapshot)}
                    onDownload={() => downloadDocument(linkUrlSnapshot)}
                    onDelete={() => deleteDocument(linkUrlSnapshot)}
                  />
                </CellActionRenderSnapshotWrapper>
              ) : (
                <DocumentActions
                  onPreview={() => previewDocument(params.data)}
                  onDownload={() => downloadDocument(params.data)}
                  onDelete={() => deleteDocument(params.data)}
                />
              )}
            </>
          );
        },
      },
    ];

    return cols.map((fieldItem) => {
      const DEFAULT_WIDTH = 150;

      let initColSetting = {
        type: 'editableColumn',
      };

      return {
        ...initColSetting,
        ...fieldItem,
        minWidth: DEFAULT_WIDTH,
      };
    });
  }, [isToggleSnapshot]);

  const previewDocument = (data) => {
    setPreviewData(data);
  };

  const downloadDocument = (data) => {
    if (!data?.linkUrl) {
      CustomNotification.success('Could not download file!');
    } else {
      api.sendDownload({ url: data.linkUrl });
    }
  };

  const deleteDocument = (data) => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.deleteDocumentConfirmMessage),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: removeDocument(data),
    });
  };

  const fetchData = () => {
    run(
      qaSpecServices.getQaSpecProductSpecDocument({ productId }),
      formatRowData
    );
  };

  const formatRowData = (response) => {
    const { isSuccess, data } = response;

    if (!isSuccess) return;

    setRowData(data?.documents || []);

    mapUidData();
  };

  const mapUidData = () => {
    setRowData((prevState) =>
      prevState.map((dataItem) => {
        const uuid = uuidv4();

        return { ...dataItem, uuid };
      })
    );
  };

  const addNewRow = () => {
    const newUuid = uuidv4();

    const allRowData = getAllRows(gridInst);

    const newRowIndex = allRowData?.length;

    gridInst.current.api.applyTransaction({
      add: [
        {
          uuid: newUuid,
          parameter: null,
          specification: null,
          tolerance: null,
          frequency: null,
          releaseCriteria: null,
          value: null,
          referenceStandard: null,
          referenceMethod: null,
          test: null,
        },
      ],
      addIndex: newRowIndex,
    });

    gridInst.current.api.ensureIndexVisible(newRowIndex);

    const newRow = gridInst.current.api.getRowNode(newUuid);
    newRow.setSelected(true, true);
  };

  const addDocument = ({
    digitalAssetId,
    characteristicsType,
    documentName,
    successCallback,
    onFinally,
  }) => {
    setLoading(true);

    const params = {
      digitalAssetId,
      productId,
      characteristicsType,
      documentName,
    };

    const successMessage = intl.formatMessage(
      Messages.productSpecAddDocumentSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.productSpecAddDocumentError
    );

    apiHandler({
      service: qaSpecServices.linkQaSpecProductSpecDocument,
      params,
      successMessage,
      errorMessage,
      successCallback: saveSuccessCallback(() => {
        successCallback();
        setSelectedRow(null);
      }),
      onFinally: addDocumentFinally(onFinally),
    });
  };

  const removeDocument = (data) => () => {
    setLoading(true);

    const params = {
      linkId: data.linkId,
    };

    const successMessage = intl.formatMessage(
      Messages.productSpecRemoveDocumentSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.productSpecRemoveDocumentError
    );

    apiHandler({
      service: qaSpecServices.unlinkQaSpecProductSpecDocument,
      params,
      successMessage,
      errorMessage,
      successCallback: saveSuccessCallback(() => {
        setSelectedRow(null);
      }),
      onFinally: addDocumentFinally(),
    });
  };

  const saveSuccessCallback = (callback) => () => {
    fetchData();
    callback && callback();

    reloadDocumentCertificateTab();
  };

  const addDocumentFinally = (callback) => () => {
    setLoading(false);
    callback && callback(callback);
  };

  const deleteData = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onDeleteData,
    });
  };

  const onDeleteData = () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();

    const removeList = selectedRows.map(
      (selectedRowItem) => selectedRowItem?.data
    );

    gridInst.current.api.applyTransaction({
      remove: removeList,
    });
  };

  const updateProductDocument = ({ params, successCallback }) => {
    const successMessage = intl.formatMessage(
      Messages.productSpecUpdateDocumentSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.productSpecUpdateDocumentError
    );

    apiHandler({
      service: qaSpecServices.updateQaSpecProductDocument,
      params,
      successMessage,
      errorMessage,
      successCallback: saveSuccessCallback(successCallback),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (reloadDocumentWhenDelete) {
      fetchData();
    }
  }, [reloadDocumentWhenDelete]);

  return {
    rowData,
    status,
    columnDefs,
    addNewRow,
    updateProductDocument,
    deleteData,
    addDocument,
    loading: status === 'pending' || loading,
    previewData,
    setPreviewData,
    selectedRow,
    setSelectedRow,
  };
};
