import React from 'react';
import Messages from 'i18n/messages/home';
import { CopyOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

const CloneTaskControl = ({ onClick }) => {
  return (
    <RibbonButton
      icon={<CopyOutlined style={{ height: 26 }} />}
      label={Messages.cloneTask}
      onClick={onClick}
    />
  );
};

CloneTaskControl.propTypes = {};

export default CloneTaskControl;
