import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Button, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { injectIntl } from 'react-intl';

import './RibbonButton.less';

const { Text } = Typography;

const RibbonButton = ({
  icon,
  label,
  toggle = false,
  className,
  intl,
  size,
  disabled,
  loading,
  ...otherProps
}) => {
  const mapToLabelContent = () => {
    let newLabel = '';
    try {
      newLabel = intl.formatMessage(label);
    } catch (e) {
      newLabel = label;
    }
    return newLabel;
  };

  const buttonIcon = loading ? <LoadingOutlined /> : icon;

  return (
    <Button
      className={classNames({
        'ribbon-button': true,
        'ribbon-button__small': size === 'small',
        'ribbon-button--toggle': toggle,
        'ribbon-button--disabled': disabled,
        [className]: !!className,
      })}
      {...otherProps}
    >
      {buttonIcon ? (
        <span className='ribbon-button__icon'>{buttonIcon}</span>
      ) : null}
      <Text
        className={classNames({
          'ribbon-button__message': true,
          'ribbon-button__small-message': size === 'small',
          'ribbon-button__message--toggle': toggle,
          'ribbon-button__message--disabled': disabled,
        })}
      >
        {mapToLabelContent()}
      </Text>
    </Button>
  );
};

RibbonButton.prototype = {
  icon: PropTypes.element,
  label: PropTypes.object,
  onClick: PropTypes.func,
  toggle: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default injectIntl(RibbonButton);
