import * as endpoints from './endpoints';
import * as api from 'config/axios';

export const getNewRivirAlert = (params) => {
  return api.sendPost(endpoints.GET_NEW_RIVIR_ALERTS, params);
};

export const markRivirAlertAsRead = (params) => {
  return api.sendPost(endpoints.MARK_RIVIR_ALERTS_AS_READ, params);
};
