import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import * as ssoServices from 'services/sso-approval';
import * as gridActions from 'common/components/grid-view/controllers/actions';
import * as brandingActions from 'redux/branding/actions';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import {
  AgGrid,
  CustomNotification,
  dialogFunction,
  StyledModal,
} from 'common/components';
import { CancelButton, SubmitButton } from '../button/FooterButton';
import { Col, Input, Row } from 'antd';
import { columnAssignUser } from '../controllers/constants';
import { GET_EXISTING_USERS } from 'services/sso-approval/endpoints';

const { Search } = Input;

const AssignUserModal = (props) => {
  const { visibleModal, setVisibleModal, selectedItem } = props;
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [userSelected, setUserSelected] = useState([]);

  const reloadPage = useDispatchReloadPage();
  const dispatch = useDispatch();

  const onSetSelectedRows = (selectedNodesDetail) => {
    const firstSelectedUser = selectedNodesDetail?.[0];
    setUserSelected(firstSelectedUser);
  };

  const handleSubmit = async () => {
    setIsLoadingButton(true);
    const params = {
      userId: selectedItem.id,
      rivirUserId: userSelected?.id,
    };

    try {
      const response = await ssoServices.assignExistingUser(params);
      if (response?.isSuccess) {
        dispatch(gridActions.updateDetailCurrentItemsSelection([]));
        reloadPage();
        dispatch(brandingActions.getBrandingNoLoading());
        CustomNotification.success('Successfully!');
      }
    } catch (error) {
      CustomNotification.error('Assign to existing user fail');
    } finally {
      setIsLoadingButton(false);
      setVisibleModal(false);
    }
  };

  const handleConfirm = () => {
    const userName = selectedItem.firstName + ' ' + selectedItem.lastName;
    const rivirUserName = userSelected.firstName + ' ' + userSelected.lastName;
    dialogFunction({
      type: 'warn',
      style: { top: '30%' },
      width: 500,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          Are you sure you want to assign SSO user request of&nbsp;
          <strong>{userName}</strong> to user&nbsp;
          <strong>{rivirUserName}</strong> of&nbsp;
          <strong>{userSelected.memberName}</strong> member?
        </div>
      ),
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => {
        handleSubmit();
      },
    });
  };

  const handleSearchItem = (searchTextValue) => {
    setSearchText({ searchText: searchTextValue });
  };

  const propsStyleModal = {
    visible: visibleModal,
    title: 'Assign to existing user',
    wrapClassName: 'assign-user-modal',
    width: '1000px',
    onCancel: () => {
      setVisibleModal(false);
    },
    bodyStyle: {
      height: '60vh',
    },
    maskClosable: false,
    destroyOnClose: true,
    footer: [
      <CancelButton setVisible={setVisibleModal} />,
      <SubmitButton
        isLoading={isLoadingButton}
        handleSubmit={handleConfirm}
        isDisabled={!userSelected?.id}
      />,
    ],
  };

  return (
    <>
      <StyledModal {...propsStyleModal} key={selectedItem.id}>
        <Row>
          <Search
            style={{ width: '300px', marginBottom: '20px' }}
            allowClear
            enterButton
            placeholder='search existing user...'
            onSearch={handleSearchItem}
          />
        </Row>
        <Row style={{ height: '85%' }}>
          <Col span={24}>
            <AgGrid
              sortAndFilterServerside
              columnDefs={columnAssignUser}
              urlGrid={GET_EXISTING_USERS}
              responseParams='data'
              styleGrid={{ width: '100%', height: '100%' }}
              requestParams={{
                search: searchText,
              }}
              gridConfigProps={{
                rowSelection: 'single',
              }}
              showCheckboxSelectionRender={false}
              onHandleSelectedRowCustom={onSetSelectedRows}
            />
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default AssignUserModal;
