import { createContext, useContext, useReducer } from 'react';

import { Form, Typography } from 'antd';

import produce from 'immer';

import {
  getNewFormValuesAfterCopying,
  mappingDataProductByFieldFullPath,
  lowercaseProductData,
  findFieldFullPathFormData,
} from './utils';

import { scrollToFoundModuleView } from 'pages/product-full-view/shared/utils';
import {
  checkChangedTypeCodeIsDefaultCode,
  checkDuplicationAllergenCodeInSameRegion,
  checkIsAllergenTypeCode,
  checkIsRegion,
  getChangedField,
  getFormValuesAfterUpdatingValueField,
  getNestedIndexField,
} from './allergenUtils';

const ProductDetailContext = createContext(null);
const ProductDetailDispatchContext = createContext(null);

const initialProductDetail = {
  baseModule: {
    isExpandAll: false,
    isShowOnlyValue: false,
    activeModules: [],
    searchText: null,
    foundFieldFullPath: null,
    foundModuleName: '',
  },
  advancedModule: {
    searchText: null,
    foundFieldFullPath: null,
    foundModuleName: '',
    isFullView: false,
    isExpandAll: false,
    isShowOnlyValue: false,
    activeModules: [],
  },
  publicationError: {
    fieldFullPathError: '',
    moduleNameError: '',
  },
};

export const productDetailActionTypes = {
  // product-detail
  COPY_FIELD_FORM: 'product-detail/copy-field-form',
  TOGGLE_FULL_VIEW: 'product-detail/toggle-full-view',
  EXPAND_ALL_MODULES: 'product-detail/expand-all-modules',
  SHOW_MODULE_HAS_VALUE: 'product-detail/show-module-has-value',

  SEARCH_PROPERTIES: 'product-detail/search-properties',
  CLEAR_SEARCH_PROPERTIES: 'product-detail/clear-search-properties',

  SEARCH_BASE_PROPERTIES: 'product-detail/search-base-properties',
  CLEAR_SEARCH_BASE_PROPERTIES: 'product-detail/clear-search-base-properties',

  UPDATE_FOUND_FIELD_FULL_PATH: 'product-detail/update-found-field-full-path',

  ACTIVE_MODULES: 'product-detail/active-modules',

  // validate allergen in AllergenInformation
  VALIDATE_VALUE_ALLERGEN_FIELD: 'product-detail/validate-value-allergen-field',

  // publication
  SET_ERROR_FIELD_FULL_PATH: 'publication/set-error-field-full-path',
  CLEAR_ERROR_PROPERTY: 'product-detail/clear-error-property',
  UPDATE_STATUS_ERROR_FIELD: 'product-detail/update-error-property',
};

export const ProductDetailProvider = ({ children }) => {
  const [formInstance] = Form.useForm();

  const [{ baseModule, advancedModule, publicationError }, dispatch] =
    useReducer(productDetailReducer, initialProductDetail);

  return (
    <ProductDetailContext.Provider
      value={{ formInstance, baseModule, advancedModule, publicationError }}
    >
      <ProductDetailDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDetailDispatchContext.Provider>
    </ProductDetailContext.Provider>
  );
};

const productDetailReducer = (state = initialProductDetail, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case productDetailActionTypes.COPY_FIELD_FORM: {
        const { formInstance, idxCopyField } = action.payload;

        const newFormValues = getNewFormValuesAfterCopying({
          formValues: formInstance.getFieldsValue(),
          idxCopyField,
        });

        formInstance.setFieldsValue(newFormValues);

        break;
      }

      case productDetailActionTypes.TOGGLE_FULL_VIEW: {
        const { value } = action.payload;

        draft.advancedModule.isFullView = value;
        draft.baseModule.isExpandAll = false;
        draft.baseModule.isShowOnlyValue = false;
        draft.baseModule.activeModules = [];

        break;
      }

      case productDetailActionTypes.EXPAND_ALL_MODULES: {
        const { moduleType, moduleSchema, value } = action.payload;

        if (moduleType === 'base') {
          if (value) {
            const activeCollapses = moduleSchema.map(
              (moduleItem) => moduleItem.moduleName
            );
            draft.baseModule.isExpandAll = value;
            draft.baseModule.activeModules = activeCollapses;
          } else {
            draft.baseModule.isExpandAll = value;
            draft.baseModule.activeModules = [];
          }
        } else {
          if (value) {
            const activeCollapses = moduleSchema.map(
              (moduleItem) => moduleItem.moduleName
            );
            draft.advancedModule.isExpandAll = value;
            draft.advancedModule.activeModules = activeCollapses;
          } else {
            draft.advancedModule.isExpandAll = value;
            draft.advancedModule.activeModules = [];
          }
        }

        break;
      }

      case productDetailActionTypes.SHOW_MODULE_HAS_VALUE: {
        const { moduleType, value } = action.payload;

        if (moduleType === 'base') {
          draft.baseModule.isShowOnlyValue = value;
        } else {
          draft.advancedModule.isShowOnlyValue = value;
        }

        break;
      }

      case productDetailActionTypes.SEARCH_BASE_PROPERTIES: {
        const { searchText, moduleSchema, modeView, formInstance } =
          action.payload;

        if (!searchText) {
          draft.baseModule.searchText = null;
          draft.baseModule.foundModuleName = '';
          draft.baseModule.foundFieldFullPath = null;
        } else {
          const foundModule = moduleSchema.find(
            (moduleItem) =>
              moduleItem.moduleName.toLowerCase() ===
              searchText.split('.')[0].toLowerCase() // searchText is fieldFullPath of a property
          );

          const { moduleName, moduleProperties } = foundModule || {};

          if (!moduleName || !moduleProperties) return;

          draft.baseModule.searchText = searchText;

          draft.baseModule.foundModuleName = moduleName;

          // Add module to collapse
          if (!draft.baseModule.activeModules.includes(moduleName)) {
            draft.baseModule.activeModules.push(moduleName);
          }

          // Scroll to found element
          if (modeView === 'edit') {
            // TODO: Check later
            const formValues =
              typeof formInstance?.getFieldsValue === 'function'
                ? formInstance.getFieldsValue()
                : formInstance;

            const fieldFullPathMapping = mappingDataProductByFieldFullPath(
              lowercaseProductData(formValues),
              moduleProperties
            );

            const foundFieldFullPathFormData = findFieldFullPathFormData(
              searchText,
              fieldFullPathMapping
            );

            draft.baseModule.foundFieldFullPath = foundFieldFullPathFormData;

            const elementByClassName = document.getElementsByClassName(
              `product-detail-view__label-form-item--${foundFieldFullPathFormData}`
            );
            scrollToFoundModuleView(elementByClassName);
          } else {
            const elementByClassName = document.getElementsByClassName(
              `product-detail-view__list-info-title--${moduleName?.toLowerCase()}`
            );
            scrollToFoundModuleView(elementByClassName);
          }
        }
        break;
      }

      case productDetailActionTypes.SEARCH_PROPERTIES: {
        const { searchText, moduleSchema, modeView, formInstance } =
          action.payload;

        if (!searchText) {
          draft.advancedModule.searchText = null;
          draft.advancedModule.foundModuleName = '';
          draft.advancedModule.foundFieldFullPath = null;
        } else {
          const foundModule = moduleSchema.find(
            (moduleItem) =>
              moduleItem.moduleName.toLowerCase() ===
              searchText.split('.')[0].toLowerCase() // searchText is fieldFullPath of a property
          );

          const { moduleName, moduleProperties } = foundModule || {};

          if (!moduleName || !moduleProperties) return;

          draft.advancedModule.searchText = searchText;

          draft.advancedModule.foundModuleName = moduleName;

          // Add module to collapse
          if (!draft.advancedModule.activeModules.includes(moduleName)) {
            draft.advancedModule.activeModules.push(moduleName);
          }

          // Scroll to found element
          if (modeView === 'edit') {
            // TODO: Check later
            const formValues =
              typeof formInstance?.getFieldsValue === 'function'
                ? formInstance.getFieldsValue()
                : formInstance;

            const fieldFullPathMapping = mappingDataProductByFieldFullPath(
              lowercaseProductData(formValues),
              moduleProperties
            );

            const foundFieldFullPathFormData = findFieldFullPathFormData(
              searchText,
              fieldFullPathMapping
            );

            draft.advancedModule.foundFieldFullPath =
              foundFieldFullPathFormData;

            const elementByClassName = document.getElementsByClassName(
              `product-detail-view__label-form-item--${foundFieldFullPathFormData}`
            );
            scrollToFoundModuleView(elementByClassName);
          } else {
            const elementByClassName = document.getElementsByClassName(
              `product-detail-view__list-info-title--${moduleName}`
            );
            scrollToFoundModuleView(elementByClassName);
          }
        }

        break;
      }

      case productDetailActionTypes.CLEAR_SEARCH_PROPERTIES: {
        draft.advancedModule.searchText = null;
        draft.advancedModule.foundModuleName = null;
        break;
      }

      case productDetailActionTypes.CLEAR_SEARCH_BASE_PROPERTIES: {
        draft.baseModule.searchText = null;
        draft.baseModule.foundModuleName = null;
        break;
      }

      case productDetailActionTypes.ACTIVE_MODULES: {
        const { value, moduleView } = action.payload;

        if (moduleView === 'base') {
          draft.baseModule.activeModules = value;
        } else {
          draft.advancedModule.activeModules = value;
        }

        break;
      }

      // Publication
      case productDetailActionTypes.UPDATE_FOUND_FIELD_FULL_PATH: {
        const { isEditBasedModule, foundFieldFullPath } = action.payload;

        if (isEditBasedModule) {
          draft.baseModule.foundFieldFullPath = foundFieldFullPath;
        } else {
          draft.advancedModule.foundFieldFullPath = foundFieldFullPath;
        }

        const elementByClassName = document.getElementsByClassName(
          `product-detail-view__label-form-item--${foundFieldFullPath}`
        );
        scrollToFoundModuleView(elementByClassName);

        break;
      }

      // Publication
      case productDetailActionTypes.SET_ERROR_FIELD_FULL_PATH: {
        const { fieldFullPath } = action.payload;
        const moduleName = fieldFullPath.split('.')[0];

        draft.publicationError.fieldFullPathError = fieldFullPath;
        draft.publicationError.moduleNameError = moduleName;

        break;
      }

      case productDetailActionTypes.CLEAR_ERROR_PROPERTY: {
        draft.publicationError.fieldFullPathError = '';
        draft.publicationError.moduleNameError = '';

        break;
      }

      case productDetailActionTypes.VALIDATE_VALUE_ALLERGEN_FIELD: {
        const { formInstance, indexField, changedValueField } = action.payload;

        const changedField = getChangedField(indexField);

        const isAllergenTypeCode = checkIsAllergenTypeCode(changedField);
        const isRegion = checkIsRegion(changedField);

        const indexIsPublishField = getNestedIndexField(
          indexField,
          'IsPublish'
        );

        let newFormValues = formInstance.getFieldsValue();

        // "IsPublish is obtained based on the AllergenTypeCode and Regional"
        if (isAllergenTypeCode) {
          if (!changedValueField) return;

          const indexRegionField = getNestedIndexField(indexField, 'Regional');
          const valueRegion = formInstance.getFieldValue(indexRegionField);

          const isDuplication = checkDuplicationAllergenCodeInSameRegion({
            formInstance,
            changedAllergenType: changedValueField,
            indexAllergenTypeField: indexField,
            valueRegion,
          });

          if (isDuplication) {
            formInstance.setFields([
              {
                name: indexField,
                errors: [
                  <div
                    style={{
                      paddingTop: 4,
                      paddingBottom: 6,
                      paddingRight: 12,
                    }}
                  >
                    <Typography.Text style={{ color: '#d93026' }}>
                      The allergen field already exists. Please add a different
                      value.
                    </Typography.Text>
                  </div>,
                ],
              },
            ]);
          } else {
            formInstance.setFields([
              {
                name: indexField,
                errors: [],
              },
            ]);
          }

          const isDefaultTypeCode = checkChangedTypeCodeIsDefaultCode({
            valueRegion: formInstance.getFieldValue(indexRegionField),
            valueAllergenTypeCode: changedValueField,
          });

          newFormValues = getFormValuesAfterUpdatingValueField({
            formValues: newFormValues,
            nestedIndex: indexIsPublishField,
            propertyName: 'IsPublish',
            value: isDefaultTypeCode ? true : false,
          });
        } else if (isRegion) {
          const indexAllergenTypeField = getNestedIndexField(
            indexField,
            'AllergenTypeCode'
          );
          const isDefaultTypeCode = checkChangedTypeCodeIsDefaultCode({
            valueRegion: changedValueField,
            valueAllergenTypeCode: formInstance.getFieldValue(
              indexAllergenTypeField
            ),
          });

          newFormValues = getFormValuesAfterUpdatingValueField({
            formValues: newFormValues,
            nestedIndex: indexIsPublishField,
            propertyName: 'IsPublish',
            value: isDefaultTypeCode ? true : false,
          });

          const isDuplication = checkDuplicationAllergenCodeInSameRegion({
            formInstance,
            changedAllergenType: formInstance.getFieldValue(
              indexAllergenTypeField
            ),
            indexAllergenTypeField: indexField,
            valueRegion: changedValueField,
          });

          if (isDuplication) {
            formInstance.setFields([
              {
                name: indexAllergenTypeField,
                errors: [
                  <div
                    style={{
                      paddingTop: 4,
                      paddingBottom: 6,
                      paddingRight: 12,
                    }}
                  >
                    <Typography.Text style={{ color: '#d93026' }}>
                      The allergen field already exists. Please add a different
                      value.
                    </Typography.Text>
                  </div>,
                ],
              },
            ]);
          } else {
            formInstance.setFields([
              {
                name: indexAllergenTypeField,
                errors: [],
              },
            ]);
          }
        }

        formInstance.setFieldsValue(newFormValues);

        break;
      }

      default:
        break;
    }
  });

export const useProductDetail = () => {
  const context = useContext(ProductDetailContext);

  if (context === undefined) {
    throw new Error(
      'useProductDetail must be used within a ProductDetailContext'
    );
  }
  return context;
};

export const useProductDetailDispatch = () => {
  const context = useContext(ProductDetailDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useProductDetailDispatch must be used within a ProductDetailContext'
    );
  }
  return context;
};
