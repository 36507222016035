import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getThreadList = (params) => {
  return api.sendGet(endpoints.GET_CHAT_THREAD_LIST, { params: params });
};

export const getCommunicationParticipant = async (params) => {
  return api.sendGet(endpoints.GET_CHAT_COMMUNICATION_PARTICIPANT, params);
};

export const getCommunicationMessage = async (params) => {
  return api.sendGet(endpoints.GET_CHAT_COMMUNICATION_MESSAGE, params);
};

export const getThreadMessage = (params) => {
  const requestParams = {
    threadId: params.threadId,
    timestamp: params.timestamp,
    direction: params.direction,
  };
  return api.sendGet(endpoints.GET_THREAD_MESSAGES, { params: requestParams });
};

export const getThreadInfo = (param) => {
  return api.sendGet(`${endpoints.GET_THREAD_INFO}`, { params: param });
};

export const getThreadListForSupporter = (data) => {
  return api.sendGet(`${endpoints.GET_THREAD_LIST_FOR_SUPPORTER}`, {
    params: data,
  });
};

export const getChatContentDetail = (data) => {
  return api.sendGet(`${endpoints.GET_CHAT_CONTENT_DETAIL}`, {
    params: data,
  });
};

export const getChatUsersInfo = (params) => {
  return api.sendPost(`${endpoints.GET_CHAT_USERS_INFO}`, {
    senderIds: params,
  });
};

export const getEmailAndMessageNotify = async (params) => {
  return api.sendGet(`${endpoints.GET_EMAIL_AND_MESSAGE_NOTIFY}`);
};

export const getSenderInfo = (params) => {
  return api.sendGet(`${endpoints.GET_SENDER_INFO}`, {
    params: params,
  });
};

export const makeAThreadAsRead = async (params) => {
  return api.sendPost(`${endpoints.MAKE_A_THREAD_AS_READ}`, { ...params });
};

export const initChatCommunication = (params) => {
  return api.sendPost(endpoints.INIT_CHAT_COMMUNICATION, params);
};

// update group name thread
export const updateGroupName = (params) => {
  return api.sendPost(endpoints.UPDATE_GROUP_NAME, params);
};

// upload chat files
export const uploadChatFile = (params) => {
  return api.sendPost(endpoints.UPLOAD_CHAT_FILE, params);
};

export const downloadChatFile = (params) => {
  return api.sendPost(endpoints.DOWNLOAD_CHAT_FILE, params);
};

export const getChatFileInfo = (params) => {
  return api.sendPost(endpoints.GET_CHAT_FILE_INFO, params);
};

export const searchMessages = (params) => {
  const options = { params };
  return api.sendGet(endpoints.SEARCH_MESSAGES, options);
};

export const deleteChatThread = (params) => {
  return api.sendPost(endpoints.DELETE_CHAT_THREAD, params);
};
