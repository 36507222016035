import React from 'react';

import SnapshotCellWrapper from './SnapshotCellWrapper';

import CheckboxRender from './CheckboxRender';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

const CheckboxRenderSnapshot = (props) => {
  const { column, rowIndex, data, context } = props;

  const { snapshotGridValues } = context || {};

  const useSnapshot = useCheckSnapshotForRetailer();

  const cellSnapshotValue = snapshotGridValues?.[rowIndex]?.[column.colId];
  const rawCellValue = data?.[column.colId];

  const isHighlightChange = cellSnapshotValue !== rawCellValue && useSnapshot;

  return (
    <SnapshotCellWrapper
      isHighlightChange={isHighlightChange}
      snapshotValueRender={
        <>
          {cellSnapshotValue === null || cellSnapshotValue === undefined ? (
            'n/a'
          ) : (
            <CheckboxRender value={cellSnapshotValue} />
          )}
        </>
      }
    >
      <CheckboxRender {...props} />
    </SnapshotCellWrapper>
  );
};

export default CheckboxRenderSnapshot;
