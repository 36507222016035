import React from 'react';
import Messages from 'i18n/messages/maintenance';
import { UserSwitchOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const ResetPasswordControl = (props) => {
  return (
    <RibbonButton
      size='small'
      icon={<UserSwitchOutlined />}
      label={Messages.resetPassword}
      {...props}
    />
  );
};

export default ResetPasswordControl;
