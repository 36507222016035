import React from 'react';

import { Modal, Button } from 'antd';

import { DialogIcon } from 'common/components';

import Messages from 'i18n/messages/assetPreview';
import { FormattedMessage } from 'react-intl';

import './GenerateConfirmModal.less';

const GenerateConfirmModal = (props) => {
  const { visible, onYes, onNo, onCancel } = props;

  const modalFooter = (
    <div className='generate-confirm__footer'>
      <Button className='dialog-cancel-button' onClick={onCancel}>
        <FormattedMessage {...Messages.generateConfirmCancelBtn} />
      </Button>
      <Button className='dialog-cancel-button' onClick={onNo}>
        <FormattedMessage {...Messages.generateConfirmNoBtn} />
      </Button>
      <Button className='dialog-ok-button' type='primary' onClick={onYes}>
        <FormattedMessage {...Messages.generateConfirmYesBtn} />
      </Button>
    </div>
  );

  const modalConfig = {
    visible: visible,
    wrapClassName: 'dialog-modal',
    footer: modalFooter,
    closable: false,
  };

  return (
    <Modal {...modalConfig}>
      <div className='generate-confirm__body'>
        <DialogIcon.InfoIcon className='generate-confirm__icon' />
        <div className='generate-confirm__content'>
          <FormattedMessage
            {...Messages.generatePreviewBackgroundConfirmMessage}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GenerateConfirmModal;
