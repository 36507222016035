import React from 'react';

import { Radio } from 'antd';
import { useControllableState } from 'hooks';

import { REGIONAL_ALLERGEN, US_VALUE } from './constants';

const QaAllergenRegional = ({
  value: valueProp,
  onChange: onChangeProp,
  children,
  ...otherProps
}) => {
  const [regional, setRegional] = useControllableState({
    defaultProp: US_VALUE,
    onChange: onChangeProp,
    prop: valueProp,
  });

  return (
    <div style={{ marginTop: 12 }}>
      <Radio.Group
        defaultValue={US_VALUE}
        value={regional}
        buttonStyle='solid'
        onChange={({ target: { value } }) => setRegional(value)}
        {...otherProps}
      >
        {REGIONAL_ALLERGEN.map((regional, index) => (
          <Radio.Button key={index} value={regional.value}>
            {regional.label}
          </Radio.Button>
        ))}
      </Radio.Group>
      {children}
    </div>
  );
};

export default QaAllergenRegional;
