import React from "react";
import PropTypes from "prop-types";

import {
  QuestionCircleOutlined,
  CheckCircleOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Space, Row, Col, Avatar, Typography } from "antd";

import { get } from "lodash";

import { FormattedMessage } from "react-intl";
import Messages from "i18n/messages/brandedProduct";

import "./ProductIngredient.less";
/**
 * TODO: remove this static image
 */
import ingredientIcon from "assets/ingredient.png";
const { Text, Paragraph } = Typography;

/**
 * This function just render static UI
 * TODO: apply detail data later
 */
const renderStaticIconList = () => {
  return (
    <Space direction="horizontal" size={10}>
      <QuestionCircleOutlined className="product-detail__ingredient__icon" />
      <CheckCircleOutlined className="product-detail__ingredient__icon" />
      <PieChartOutlined className="product-detail__ingredient__icon" />
    </Space>
  );
};

const renderIngredientItem = (item, key) => {
  const { label, render, extra } = item;
  return (
    <Row
      className={`product-detail__ingredient__row ${
        label && label.defaultMessage && label.defaultMessage.toLowerCase()
      }`}
      key={key}
    >
      <Col className="product-detail__ingredient__label" span={6}>
        {label ? (
          <Text strong>
            <FormattedMessage {...label} /> :
          </Text>
        ) : null}
      </Col>
      <Col flex={18} className="product-detail__ingredient__content">
        {render && render}
      </Col>
      {extra && (
        <Col span={24} className="product-detail__ingredient__content--extra">
          {extra}
        </Col>
      )}
    </Row>
  );
};

const ProductIngredient = (props) => {
  const { dataDetail } = props;
  const ingredientContent = get(dataDetail, "ingredient", false);

  const ingredientIconComp = (
    <Avatar src={ingredientIcon} shape="square" size={20} />
  );

  const ingredientExtraContent = ingredientContent ? (
    <Paragraph>{ingredientContent}</Paragraph>
  ) : null;

  const renderData = [
    // Ingredient
    {
      label: Messages.ingredientLabel,
      render: ingredientIconComp,
      extra: ingredientExtraContent,
    },
    // allergens
    {
      label: Messages.allergensLabel,
      render: renderStaticIconList(),
    },
    // Certification
    {
      label: Messages.certificationsLabel,
      render: renderStaticIconList(),
    },
  ];

  return (
    <Space
      className="product-detail__ingredient__container"
      direction="vertical"
      size={10}
    >
      {renderData &&
        renderData.length > 0 &&
        renderData.map((data, index) => renderIngredientItem(data, index))}
    </Space>
  );
};

ProductIngredient.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductIngredient;
