import React, { useState } from 'react';

import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import ProductImageSlider from './ProductImageSlider';
import PreviewGalleryModal from './preview-modal/PreviewGalleryModal';

import { useGetProductFullView } from 'hooks';

import { unlinkAssetFromPIM } from 'services/product';
import * as productFullViewActions from '../controllers/actions';
import * as productFullViewSelectors from '../controllers/selectors';

import './ProductGallery.less';

const ProductGallery = (props) => {
  const dispatch = useDispatch();
  const { getImagery, isOwner, isAllowEdit, getProductDetail, productId } =
    props;

  const [visible, setVisible] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [unlinkAssetLoading, setUnlinkAssetLoading] = useState(false);

  const additionalImage = useSelector(
    productFullViewSelectors.makeSelectAdditionalImages()
  );
  const planogramImage = useSelector(
    productFullViewSelectors.makeSelectPlanogramImages()
  );

  const { productFull } = useGetProductFullView({ productId });

  const gtin = productFull?.gtin;
  const productName = productFull?.productName;
  const rivirId = productFull?.rivirId;
  const upc12 = productFull?.upc12;
  const productDetail = productFull;

  const getMarketingAssets = () => {
    if (productDetail) {
      dispatch(
        productFullViewActions.getMarketingAssets({
          id: productDetail.productId,
        })
      );
    }
  };

  const productTitle =
    productName && productName !== null ? `- ${productName}` : '';

  const compareNumber = (item1, item2) => {
    if (item1 < item2) {
      return -1;
    }
    if (item1 > item2) {
      return 1;
    }
    return 0;
  };

  const compareId = (item1, item2) => {
    if (item1 && item2 && item1.planoFileName && item2.planoFileName) {
      const orderNumber1 = item1?.planoFileName?.split('.');
      const orderNumber2 = item2?.planoFileName?.split('.');
      if (compareNumber(orderNumber1[1], orderNumber2[1]) !== 0)
        return compareNumber(orderNumber1[1], orderNumber2[1]);
      else return compareNumber(orderNumber1[0], orderNumber2[0]);
    } else return compareNumber(item1?.id, item2?.id);
  };

  compareId(planogramImage[0], planogramImage[1]);

  const sortList = (list) => {
    const UnitList = list
      .filter((item) => item.packageLevel?.toLowerCase() === 'unit')
      .sort((item1, item2) => compareId(item1, item2));
    const InnerPackList = list
      .filter((item) => item.packageLevel?.toLowerCase() === 'inner pack')
      .sort((item1, item2) => compareId(item1, item2));
    const CaseList = list
      .filter((item) => item.packageLevel?.toLowerCase() === 'case')
      .sort((item1, item2) => compareId(item1, item2));
    const DisplayPackageList = list
      .filter((item) => item.packageLevel?.toLowerCase() === 'display shipper')
      .sort((item1, item2) => compareId(item1, item2));
    const MCaseList = list
      .filter((item) => item.packageLevel?.toLowerCase() === 'master case')
      .sort((item1, item2) => compareId(item1, item2));
    const PalletList = list
      .filter((item) => item.packageLevel?.toLowerCase() === 'pallet')
      .sort((item1, item2) => compareId(item1, item2));
    return [
      ...UnitList,
      ...InnerPackList,
      ...CaseList,
      ...DisplayPackageList,
      ...MCaseList,
      ...PalletList,
    ];
  };

  const displayAdditionalImage = sortList(additionalImage);
  const displayPlanogramImage = sortList(planogramImage);

  const handleUnlinkAsset = async (productIds) => {
    //* Waiting for BE to update
    //* Current only support unlink one asset per request.
    let isUnlinkAssetSuccess;
    setUnlinkAssetLoading(true);
    await unlinkAssetFromPIM({
      productImageLinkId: productIds?.[0],
    })
      .then((res) => {
        const { isSuccess, message } = res;
        if (isSuccess) {
          notification.success({ message: 'Unlink Asset success.' });
          getImagery();
          isUnlinkAssetSuccess = true;
        } else {
          notification.error({
            message: message ? message : 'Something went wrong!',
          });
          isUnlinkAssetSuccess = false;
        }
      })
      .catch((error) => {
        console.log('error');
        isUnlinkAssetSuccess = false;
      })
      .finally(() => {
        setUnlinkAssetLoading(false);
      });

    return isUnlinkAssetSuccess;
  };

  const handleCloseModal = () => {
    setVisible(false);
    setVisibleAdd(false);
  };

  return (
    <>
      <div className='product-gallery scroller'>
        <ProductImageSlider
          imageList={displayPlanogramImage}
          title='planogram'
          openPreview={() => setVisible(true)}
          sliderClass={'imagery-top-slider'}
          getImagery={getImagery}
          isAllowEdit={isAllowEdit}
          getProductDetail={getProductDetail}
          productId={productId}
        />

        <ProductImageSlider
          imageList={displayAdditionalImage}
          title='additional'
          openPreview={() => setVisibleAdd(true)}
          sliderClass={'imagery-bottom-slider'}
          getImagery={getImagery}
          getMarketingAssets={getMarketingAssets}
          isAllowEdit={isAllowEdit}
          getProductDetail={getProductDetail}
          productId={productId}
        />
        <PreviewGalleryModal
          imageList={displayPlanogramImage}
          visible={visible}
          titleModal={
            gtin
              ? `${gtin} ${productTitle}`
              : upc12
              ? `${upc12} ${productTitle}`
              : rivirId
              ? `${rivirId} ${productTitle}`
              : ''
          }
          title={'Planogram'}
          onCloseModal={handleCloseModal}
          getImagery={getImagery}
          handleUnlinkAsset={handleUnlinkAsset}
          unlinkAssetLoading={unlinkAssetLoading}
          isOwner={isOwner}
          productInfo={productDetail}
          getMarketingAssets={getMarketingAssets}
        />
        <PreviewGalleryModal
          imageList={displayAdditionalImage}
          visible={visibleAdd}
          titleModal={
            gtin
              ? `${gtin} ${productTitle}`
              : upc12
              ? `${upc12} ${productTitle}`
              : rivirId
              ? `${rivirId} ${productTitle}`
              : ''
          }
          title={'Additional'}
          onCloseModal={handleCloseModal}
          getImagery={getImagery}
          handleUnlinkAsset={handleUnlinkAsset}
          unlinkAssetLoading={unlinkAssetLoading}
          isOwner={isOwner}
          getMarketingAssets={getMarketingAssets}
          productInfo={productDetail}
        />
      </div>
    </>
  );
};

export default ProductGallery;
