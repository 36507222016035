import React from 'react';

import { Input } from 'antd';

import { Form, StyledModal } from 'common/components';

const ModalUpdateDocument = ({ formInstance, ...otherProps }) => {
  return (
    <StyledModal title='Update Document' {...otherProps}>
      <Form
        form={formInstance}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label='Document Name' name='documentName'>
          <Input />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default ModalUpdateDocument;
