import { useState } from 'react';

import { notification } from 'antd';

import { useFetch } from 'hooks/useAsync';
import { sortList } from 'utils/productGallery';
import * as productServices from 'services/product';

export const useProductGallery = (props) => {
  const { data, run } = useFetch();

  const additionalImages = data?.data?.additionalImages || [];
  const planogramImages = data?.data?.planogramImages || [];
  const marketingAssets = data?.data?.gridData || [];

  const { productId, type } = props;

  const [unlinkAssetLoading, setUnlinkAssetLoading] = useState(false);

  const getImagery = () => {
    if (type === 'Marketing') {
      run(productServices.getMarketingAssets({ productId, isShowAll: true }));
      return;
    }

    //* else for planogram and additional images
    run(productServices.getProductImagery({ productId }));
  };

  const handleUnlinkAsset = async (productIds) => {
    //* Waiting for BE to update
    //* Current only support unlink one asset per request.
    let isUnlinkAssetSuccess;
    setUnlinkAssetLoading(true);
    await productServices
      .unlinkAssetFromPIM({
        productImageLinkId: productIds?.[0],
      })
      .then((res) => {
        const { isSuccess, message } = res;
        if (isSuccess) {
          notification.success({ message: 'Unlink Asset success.' });
          getImagery();
          isUnlinkAssetSuccess = true;
        } else {
          notification.error({
            message: message ? message : 'Something went wrong!',
          });
          isUnlinkAssetSuccess = false;
        }
      })
      .catch((error) => {
        console.log('error');
        isUnlinkAssetSuccess = false;
      })
      .finally(() => {
        setUnlinkAssetLoading(false);
      });

    return isUnlinkAssetSuccess;
  };

  const displayAdditionalImage = sortList(additionalImages);
  const displayPlanogramImage = sortList(planogramImages);

  return {
    additionalImages: displayAdditionalImage,
    planogramImages: displayPlanogramImage,
    marketingAssets,
    getImagery,
    handleUnlinkAsset,
    unlinkAssetLoading,
  };
};
