import produce from 'immer';
import * as types from './constants';

export const initialState = {
  columns: [],
  wfItemsSelected: [],
  isReloadGrid: false,
};

/* eslint-disable default-case, no-param-reassign */
const productReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.WORKFLOW_TEMPLATE_COLUMN_INFO:
        draft.error = false;
        draft.gridName = action.gridName;
        break;
      case types.WORKFLOW_TEMPLATE_COLUMN_INFO_SUCCESS:
        draft.columns = action.columns;
        break;
      case types.WORKFLOW_TEMPLATE_ITEMS_SELECTED:
        draft.wfItemsSelected = action.items;
        break;
      case types.RELOAD_GRID_WF:
        draft.isReloadGrid = action.isReloadGrid;
        break;
    }
  });

export default productReducer;
