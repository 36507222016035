import React, { useState, useEffect, useRef } from 'react';

import { Row, Col, Divider } from 'antd';

import { EmailEditor } from 'common/components';
import AplSampleRequestDynamicContentForm from './AplSampleRequestDynamicContentForm';
import AplSampleRequestEmailForm from './AplSampleRequestEmailForm';

const AplSendSampleRequestEditor = (props) => {
  const {
    productData,
    emailTitle,
    dataRef,
    invalidTag,
    setInvalidTag,
    emailForm,
    contactEmail,
    template,
    setDynamicContent,
    dynamicContent,
    senderInfo,
  } = props;

  return (
    <Row className='apl-request-sample'>
      <Col span={6} style={{ height: '100%' }}>
        <AplSampleRequestDynamicContentForm
          setDynamicContent={setDynamicContent}
          dynamicContent={dynamicContent}
          invalidTag={invalidTag}
        />
      </Col>
      <Divider type='vertical' style={{ height: '100%' }} />
      <Col
        flex={1}
        span={18}
        style={{ height: '100%', maxWidth: 'calc(75% - 17px)' }}
      >
        <AplSampleRequestEmailForm
          form={emailForm}
          contactEmail={contactEmail}
        />
        <div className='apl-request-sample__email-content '>
          <EmailEditor
            dynamicContent={dynamicContent}
            setDynamicContent={setDynamicContent}
            productData={productData}
            setInvalidTag={setInvalidTag}
            invalidTag={invalidTag}
            dataRef={dataRef}
            emailTitle={emailTitle}
            senderInfo={senderInfo}
            template={template}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AplSendSampleRequestEditor;
