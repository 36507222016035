import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const disableChargeWarning = () => {
  return api.sendPost(endpoints.DISABLE_CHARGE_WARNING);
};

export const getUserCardEula = () => {
  return api.sendGet(endpoints.GET_USER_CREDIT_CARD_EULA);
};

export const acceptCardEula = (params) => {
  return api.sendPost(endpoints.ACCEPT_CARD_EULA, params);
};
export const rejectCardEula = (params) => {
  return api.sendPost(endpoints.REJECT_CARD_EULA, params);
};

export const getLinkIframeCard = (params) => {
  return api.sendPost(endpoints.GET_LINK_IFRAME_CARD, params);
};

export const communicatorIframe = () => {
  return api.sendGet(endpoints.IFRAME_COMMUNICATOR);
};

export const syncSavedMemberCard = (params) => {
  return api.sendPost(endpoints.SYNC_SAVED_MEMBER_CARD, params);
};

export const getSavedMemberCard = (params) => {
  return api.sendGet(endpoints.GET_SAVED_MEMBER_CARD, { params });
};

export const removeSavedMemberCard = (params) => {
  return api.sendPost(endpoints.REMOVE_SAVED_CARD, params);
};

export const getLinkIframeEditedCard = (params) => {
  return api.sendPost(endpoints.GET_LINK_IFRAME_EDITED_CARD, params);
};

export const getMemberBillingProperties = (params) => {
  return api.sendGet(endpoints.GET_MEMBER_BILLING_PROPERTIES, { params });
};

export const saveMemberBillingProperties = (params) => {
  return api.sendPost(endpoints.SAVE_MEMBER_BILLING_PROPERTIES, params);
};
