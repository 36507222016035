import React from 'react';
import { Button, Space, Tooltip } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/share';
import { FormattedMessage } from 'react-intl';

const ManageShareModalToolbar = (props) => {
  const { handleCancel, removeSelectedShare, selectedRow } = props;
  const buttonProps = { size: 'large' };
  return (
    <Space size={0} style={{ width: '100%', justifyContent: 'flex-end' }}>
      <Tooltip title={<FormattedMessage {...Messages.removeSelectedShare} />}>
        <Button
          {...buttonProps}
          icon={<DeleteOutlined />}
          disabled={selectedRow.length === 0}
          onClick={removeSelectedShare}
        />
      </Tooltip>

      <Button
        {...buttonProps}
        icon={<CloseOutlined />}
        onClick={handleCancel}
      />
    </Space>
  );
};

export default ManageShareModalToolbar;
