import * as api from 'config/axios';
import * as endpoints from './endpoints';

/**
 * * USER BRANDING DATA
 * @param {object} data user login information
 */
export const getBrandingService = () => {
  return api.sendGet(endpoints.BRANDING_GET_ENDPOINT);
};

/**
 * * USER BRANDING ACTION DATA
 * @param {object} data
 */
export const getBrandingActionService = () => {
  return api.sendGet(endpoints.BRANDING_ACTION_GET_ENDPOINT);
};

export const updateGetNewMember = () => {
  return api.sendPost(endpoints.LAST_UPDATE_NEW_MEMBER);
};

export const updateGetNewProduct = () => {
  return api.sendPost(endpoints.LAST_UPDATE_NEW_PRODUCT);
};

export const updateGetNewAsset = () => {
  return api.sendPost(endpoints.LAST_UPDATE_NEW_DAMS);
};
