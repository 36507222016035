import { Images } from 'config/assets';

import moment from 'moment';

import { mock_page_data } from 'common/components/page-editor/constants/mock';

const mockImage =
  'https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png';

const fakeData = {
  // '/api/user-profile/check-otp-and-save-mfa-number': {
  //   isSuccess: false,
  //   message: 'faile 2',
  //   data: {},
  // },
  // '/api/user-profile/send-otp': {
  //   isSuccess: false,
  //   message: 'fail rui',
  //   data: {
  //     mfaSession: 'VEc4263699e45ef7302a87d960298534d8',
  //     mfaPhoneNumber: '******4956',
  //     // expireAt: '2023-06-22T07:47:39.3623420Z',
  //     expireAt: moment(new Date()).add(20, 'seconds').toISOString(),
  //   },
  // },
  // '/api/token/login/check-otp': {
  //   isSuccess: false,
  //   message: 'dsadsjkahd kjashdjk',
  //   exception: null,
  // },
  // '/api/token/login/send-otp': {
  //   isSuccess: false,
  //   message: 'test long dsahdjk ashdkjhas djkhasjk',
  // },
  // '/api/mfa/verify-mfa': {
  //   isSuccess: true,
  //   data: {},
  // },
  // '/api/User/get-user-eula': {
  //   isSuccess: true,
  //   data: {},
  // },

  // 'api/user-info/change-password-on-login': {
  //   isSuccess: true,
  //   message: 'change password successfully',
  // },
  // '/api/token/check-otp-unlock-account': {
  //   isSuccess: true,
  //   data: {},
  // },
  // '/api/token/unlock-account-via-phone': {
  //   isSuccess: true,
  //   data: {
  //     isAccountLocked: true,
  //   },
  // },

  '/api/NMAOTTAY/get-transition-page-detail--remove': {
    isSuccess: true,
    data: {
      name: 'test name',
      startTime: moment(new Date()),
      endTime: moment(new Date()),
      status: 'Archived',
      content: JSON.stringify(mock_page_data),
    },
  },
  '/api/chat/getCommunicationMessage': {
    isSuccess: true,
    data: [
      {
        id: 1,
        type: 'message',
        message: 'non blandit massa enim nec',
        time: 'Tue, 13 Apr 2021 07:00:29 GMT',
      },
      {
        id: 1,
        type: 'message',
        message: 'morbi non arcu',
        time: 'Tue, 13 Apr 2021 07:00:29 GMT',
      },
      {
        id: 2,
        type: 'message',
        message: 'sed enim ut sem viverra aliquet eget sit amet tellus',
        time: 'Tue, 13 Apr 2021 07:00:29 GMT',
      },
      {
        id: 2,
        type: 'message',
        message: 'vulputate mi sit amet mauris commodo quis',
        time: 'Tue, 13 Apr 2021 07:00:29 GMT',
      },
      {
        id: 1,
        type: 'message',
        message:
          'consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque',
        time: 'Tue, 13 Apr 2021 08:05:29 GMT',
      },
      {
        id: 1,
        type: 'mail',
        message: {
          from: 'John Dick',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia lastMessageTexterunt mollit anim id est laborum.',
          attach: [
            {
              name: 'file-name.pdf',
              size: '300Gb',
              url: 'https://www.google.com',
            },
            {
              name: 'video.mp4',
              size: '200Gb',
              url: 'https://www.google.com',
            },
          ],
        },
        time: 'Tue, 13 Apr 2021 08:05:29 GMT',
      },
      {
        id: 1,
        type: 'message',
        message:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        time: 'Tue, 13 Apr 2021 08:05:30 GMT',
      },
      {
        id: 1,
        type: 'message',
        message: 'https://loremipsum.io/',
        time: 'Tue, 13 Apr 2021 08:05:30 GMT',
      },
      {
        id: 1,
        type: 'message',
        message: 'loremipsum.io',
        time: 'Tue, 13 Apr 2021 08:06:30 GMT',
      },
      {
        id: 2,
        type: 'message',
        message: 'https://www.youtube.com/watch?v=2lWJQmCf2CY',
        time: 'Tue, 13 Apr 2021 08:06:30 GMT',
      },
      {
        id: 1,
        type: 'message',
        message: {
          type: 'video',
          title:
            'Video title is too longlonglonglonglonglonglonglonglonglonglonglong',
          url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        },
        time: 'Tue, 13 Apr 2021 10:16:20 GMT',
      },
    ],
  },
  '/api/chat/search-messages': {
    isSuccess: true,
    data: [
      {
        userId: 'U-1',
        message:
          'mi tempus imperdiet nulla malesuada pellentesque elit eget gravida cum',
        time: 'Tue, 13 Apr 2021 10:16:20 GMT',
      },
      {
        userId: 'U-1',
        message:
          'mi tempus imperdiet nulla malesuada pellentesque elit eget gravida cum',
        time: 'Tue, 13 Apr 2021 10:16:20 GMT',
      },
      {
        userId: 'U-1',
        message:
          'mi tempus imperdiet nulla malesuada pellentesque elit eget gravida cum',
        time: 'Tue, 13 Apr 2021 10:16:20 GMT',
      },
      {
        userId: 'U-1',
        message:
          'mi tempus imperdiet nulla malesuada pellentesque elit eget gravida cum',
        time: 'Tue, 13 Apr 2021 10:16:20 GMT',
      },
      {
        userId: 'U-1',
        message:
          'mi tempus imperdiet nulla malesuada pellentesque elit eget gravida cum',
        time: 'Tue, 13 Apr 2021 10:16:20 GMT',
      },
    ],
  },
  '/api/v1/personalizer': { success: true },
  '/api/v1/message': {
    success: true,
    messages: [
      {
        _id: '5f004aacfeb23a07d00eb806',
        title: 'Critical Message',
        content: 'Critical Content',
        type: 'warning',
        active: true,
        __v: 0,
      },
    ],
  },
  '/api/v1/landingpage': { success: true },
  '/api/v1/member/system-information': {
    success: true,
    memberSystems: {
      visibility: {
        companyProfile: 'Public',
        products: 'Only If Shared',
        digitalAssets: 'Only If Shared',
        userAccounts: 'Main Contact Only',
      },
      defaults: {
        passwordReset: false,
        reusePassword: true,
        passwordStrength: 'any',
        userPermission: 'admin',
        userPersonalization: false,
        landingPage: 'products',
        type: 'UPC12',
      },
      apiServices: {
        apiEnabled: true,
        apiToken:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiQ2xpZW50IiwiX2lkIjoiNWVlNWNmYTdiOTc3OWIyNGRjMzk1MTQ2IiwiZW1haWwiOiJhZG1pbkBleGFtcGxlLmNvbSIsInBhc3N3b3JkIjoiJDJiJDEwJDlLb3guY0ZvWVRRdVJ0WTBvVE9LUS5rVlU2RVUxcjBzL2NBLmk5UVloNHcyMlNOT1lMeEt5IiwiX192IjowLCJhY2NlcHRlZF9ldWxhIjp0cnVlLCJyZXZpc2VkX3ZlcnNpb24iOiIxLjAuMCIsImlhdCI6MTU5NDg4ODg0MywiZXhwIjoxNTk0ODk4OTIzfQ.MLC-LrVbfKcOL8nNg-dlt3JBsGIHrcckFnWW8G0s3Yg',
        expirationDate: '06/01/2019',
        callPayload: 'JSON',
        apiLastConnection: '07/06/2020',
      },
      ftpServices: {
        ftpEnabled: true,
        userName: 'CompanyFTP',
        password: 'P@ssw*rd@##',
        diskUsage: '120 /500 MB',
        ftpLastConnection: '07/06/2020',
      },
      interoperabilities: [
        {
          id: 1,
          name: 'Instacart',
          interoperability: 'Direct Connection',
          icon: 'https://d2guulkeunn7d8.cloudfront.net/assets/beetstrap/brand/logo@3x-c01b12eeb889d8665611740b281d76fa1cf88d06bcbd8a50dbcae6baecdbe9da.png',
        },
        {
          id: 2,
          name: 'Salsify',
          interoperability: 'API Outbound Feed',
          icon: 'https://prnewswire2-a.akamaihd.net/p/1893751/sp/189375100/thumbnail/entry_id/1_p4uc6d95/def_height/785/def_width/1500/version/100031/type/2/q/100',
        },
        {
          id: 3,
          name: 'DropBox',
          interoperability: 'Main Company Folder',
          icon: 'https://blog.vu-review.com/wp-content/uploads/2020/03/dropbox-la-gi-696x599.jpg',
        },
        {
          id: 4,
          name: 'SharePoint',
          interoperability: 'Centralized Pricing',
          icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSg5zcRvomB6oHa_0T1wDjZaX0gDA48jBHtOg&usqp=CAU',
        },
        {
          id: 5,
          name: "Mary's Marketing",
          interoperability: 'SFTP (SSH) Dump',
          icon: 'https://prnewswire2-a.akamaihd.net/p/1893751/sp/189375100/thumbnail/entry_id/1_p4uc6d95/def_height/785/def_width/1500/version/100031/type/2/q/100',
        },
        {
          id: 6,
          name: "Bob's Backyard",
          interoperability: 'FTP Backup Server',
          icon: 'https://tinyurl.com/y3h89qsl',
        },
      ],
    },
  },
  '/api/v1/member/crm/advantage-relationships': {
    success: true,
    data: [
      {
        id: 1,
        name: 'Advantage Solutions ',
        companyType: 'Regional Boker - SW',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 2,
        name: 'Frontier Foods Brokerage, INC.',
        companyType: 'Regional Boker - SW',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 3,
        name: 'Green Cow Dairy Owned Company (Child)',
        companyType: 'Owned Company (Child)',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 4,
        name: 'Monica Brooks Group',
        companyType: 'Parent Company',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 5,
        name: 'The Lunch Box, LLC ',
        companyType: 'Owned Company (Child)',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 6,
        name: 'PLMA T',
        companyType: 'Trade Association - Member',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 7,
        name: 'QQA',
        companyType: 'Trade Association - Member',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 8,
        name: 'Instacart',
        companyType: 'E-Commerce Vendor - Client',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 9,
        name: 'Advantage Solutions ',
        companyType: 'Regional Boker - SW',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 10,
        name: 'Frontier Foods Brokerage, INC.',
        companyType: 'Regional Boker - SW',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 11,
        name: 'Green Cow Dairy Owned Company (Child)',
        companyType: 'Owned Company (Child)',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
      {
        id: 12,
        name: 'Monica Brooks Group',
        companyType: 'Parent Company',
        img: 'https://www.ix-one.net/wp-content/uploads/2017/06/IX-ONE-logo-ACH-1-1.png',
      },
    ],
  },
  '/api/v1/member/activity-log': {
    success: true,
    data: [
      {
        key: 1,
        direction: 'send',
        activityType: 'card',
        date: '01/01/2019',
        company: 'Marlin',
        type: 'Registration',
        description: 'Register Member as a client of Marlin',
        lastActivity: '01/10/2020',
        originator: 'Staff Member',
        mostRecent: 'Sales Member',
      },
      {
        key: 2,
        direction: 'send',
        activityType: 'email',
        date: '01/01/2019',
        company: 'IX-ONE',
        type: 'Email',
        description: 'Template - Did you know',
        lastActivity: '01/10/2020',
        originator: 'Joe Robertson',
        mostRecent: '',
      },
      {
        key: 3,
        direction: 'send',
        activityType: 'message',
        date: '01/01/2019',
        company: 'IX-ONE',
        type: 'Damaged Prd',
        description: '928899393213123 - Case was damaged upon reciept',
        lastActivity: '01/10/2020',
        originator: 'Billy Studio Guy',
        mostRecent: 'Julie M.LongName',
      },
      {
        key: 4,
        direction: 'receive',
        activityType: 'video',
        date: '01/01/2019',
        company: 'SageTree',
        type: 'Digital Asset',
        description: 'Heo Image - 3212312321 Yellow Puf Chips',
        lastActivity: '01/10/2020',
        originator: 'Artist Jim-Bo',
        mostRecent: '',
      },
      {
        key: 5,
        direction: 'receive',
        activityType: 'message',
        date: '01/01/2019',
        company: 'IX-ONE',
        type: 'Service Request',
        description: 'Syndication Help - Mapping Albertsons',
        lastActivity: '01/10/2020',
        originator: 'Julie M.LongName',
        mostRecent: 'Lisa Tyler',
      },
    ],
  },
  '/api/v1/member-shares': {
    success: true,
    data: [
      {
        key: 1,
        arrowDirection: 'up',
        iconType: 'folder',
        shareDate: '06/02/2020',
        companyName: 'Company ABC',
        userName: 'Jake Parker',
        description: 'Sellsheets fir Frozen Beets',
        permissions: 'Read Only',
        expirationDate: '',
      },
      {
        key: 2,
        arrowDirection: 'up',
        iconType: 'video',
        shareDate: '06/02/2020',
        companyName: 'Company ABC',
        userName: 'Jake Parker',
        description: 'Sampling Video - Quality',
        permissions: 'Shareable',
        expirationDate: '',
      },
      {
        key: 3,
        arrowDirection: 'down',
        iconType: 'cube',
        shareDate: '06/02/2020',
        companyName: 'Company PDQ',
        userName: 'Jake Parker',
        description: '778272848328 - Fresh Organic Beets',
        permissions: 'Read Only',
        expirationDate: '',
      },
    ],
  },
  '/api/v1/member/affiliation': {
    success: true,
    data: [
      {
        id: 1,
        name: 'Advantage Solutions ',
        parentName: 'Regional Boker - SW',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 2,
        name: 'Frontier Foods Brokerage, INC.',
        parentName: 'Regional Boker - SW',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 3,
        name: 'Green Cow Dairy Owned Company (Child)',
        parentName: 'Owned Company (Child)',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 4,
        name: 'Monica Brooks Group',
        parentName: 'Parent Company',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 5,
        name: 'The Lunch Box, LLC ',
        parentName: 'Owned Company (Child)',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 6,
        name: 'PLMA T',
        parentName: 'Trade Association - Member',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 7,
        name: 'QQA',
        parentName: 'Trade Association - Member',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 8,
        name: 'Instacart',
        parentName: 'E-Commerce Vendor - Client',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 9,
        name: 'Advantage Solutions ',
        parentName: 'Regional Boker - SW',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 10,
        name: 'Frontier Foods Brokerage, INC.',
        parentName: 'Regional Boker - SW',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 11,
        name: 'Green Cow Dairy Owned Company (Child)',
        parentName: 'Owned Company (Child)',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 12,
        name: 'Monica Brooks Group',
        parentName: 'Parent Company',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 13,
        name: 'The Lunch Box, LLC ',
        parentName: 'Owned Company (Child)',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 14,
        name: 'PLMA T',
        parentName: 'Trade Association - Member',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 15,
        name: 'QQA',
        parentName: 'Trade Association - Member',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 16,
        name: 'Instacart',
        parentName: 'E-Commerce Vendor - Client',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 17,
        name: 'Advantage Solutions ',
        parentName: 'Regional Boker - SW',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 18,
        name: 'Frontier Foods Brokerage, INC.',
        parentName: 'Regional Boker - SW',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 19,
        name: 'Green Cow Dairy Owned Company (Child)',
        parentName: 'Owned Company (Child)',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 20,
        name: 'Monica Brooks Group',
        parentName: 'Parent Company',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 21,
        name: 'The Lunch Box, LLC ',
        parentName: 'Owned Company (Child)',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 22,
        name: 'PLMA T',
        parentName: 'Trade Association - Member',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 23,
        name: 'QQA',
        parentName: 'Trade Association - Member',
        img: 'https://tinyurl.com/y3h89qsl',
      },
      {
        id: 24,
        name: 'Instacart',
        parentName: 'E-Commerce Vendor - Client',
        img: 'https://tinyurl.com/y3h89qsl',
      },
    ],
  },
  '/api/v1/asset/eidt': {
    success: true,
    data: { id: 1 },
  },
  '/api/v1/folder/folder-grid-view': {
    isSuccess: true,
    data: {
      gridData: [
        {
          id: 1,
          size: '356 MB',
          totalProduct: 22,
          totalImage: 451,
          owner: 'David Parker',
          member: '3 Cows Metats',
          lastUpdate: '2020-09-29T17:18:46.9019048',
          loadedDate: '2020-09-29T17:18:46.9019048',
          status: 'Active',
          additionalSearchTags: '',
          folderName: 'My Folder',
          folderType: 'emptyFolder',
          canEdit: true,
          dataAssetSegments: [],
          fileSize: 7017063,
          interoperabilityService: null,
          interoperabilityServiceAssetCreated: '2020-09-29T17:18:46.9030573',
          isOwner: true,
          originalFileName: '6610a344-618b-4f9b-8071-71d00656ec53.psd',
          ownerId: 1,
          thumbSize100: Images.emptyFolder,
          thumbSize300: Images.emptyFolder,
          uniqueId: 'b26e986b-ebfd-4186-afcc-ccfdff3c6580',
        },
        {
          id: 2,
          size: '356 MB',
          totalProduct: 22,
          totalImage: 451,
          owner: 'David Parker',
          member: '3 Cows',
          lastUpdate: '2020-09-29T17:18:46.9019048',
          loadedDate: '2020-09-29T17:18:46.9019048',
          status: 'Active',
          additionalSearchTags: '',
          folderName: 'Everything I Want To Download',
          folderType: 'folder',
          canEdit: true,
          dataAssetSegments: [],
          fileSize: 7017063,
          interoperabilityService: null,
          interoperabilityServiceAssetCreated: '2020-09-29T17:18:46.9030573',
          isOwner: true,
          originalFileName: '6610a344-618b-4f9b-8071-71d00656ec53.psd',
          ownerId: 1,
          thumbSize100: Images.folder,
          thumbSize300: Images.folder,
          uniqueId: '86b37fc7-6bb5-49a9-a7b6-ee032a820dff',
        },
        {
          id: 3,
          size: '356 MB',
          totalProduct: 22,
          totalImage: 451,
          owner: 'David Parker',
          member: '3 Cows',
          lastUpdate: '2020-09-29T17:18:46.9019048',
          loadedDate: '2020-09-29T17:18:46.9019048',
          status: 'Active',
          additionalSearchTags: '',
          folderName: 'My Shared Folder',
          folderType: 'shared',
          canEdit: true,
          dataAssetSegments: [],
          fileSize: 7017063,
          interoperabilityService: null,
          interoperabilityServiceAssetCreated: '2020-09-29T17:18:46.9030573',
          isOwner: true,
          originalFileName: '6610a344-618b-4f9b-8071-71d00656ec53.psd',
          ownerId: 1,
          thumbSize100: Images.sharedFolder,
          thumbSize300: Images.sharedFolder,
          uniqueId: '5b3cab1c-0f96-4ecf-9884-b25f440379de',
        },
        {
          id: 4,
          size: '356 MB',
          totalProduct: 22,
          totalImage: 451,
          owner: 'David Parker',
          member: '3 Cows',
          lastUpdate: '2020-09-29T17:18:46.9019048',
          loadedDate: '2020-09-29T17:18:46.9019048',
          status: 'Active',
          additionalSearchTags: '',
          folderName: 'Favorite Music',
          folderType: 'emptyFolder',
          canEdit: true,
          dataAssetSegments: [],
          fileSize: 7017063,
          interoperabilityService: null,
          interoperabilityServiceAssetCreated: '2020-09-29T17:18:46.9030573',
          isOwner: true,
          originalFileName: '6610a344-618b-4f9b-8071-71d00656ec53.psd',
          ownerId: 1,
          thumbSize100: Images.emptyFolder,
          thumbSize300: Images.emptyFolder,
          uniqueId: '6bc9dd9b-b5af-4f86-a051-0b68ab52f182',
        },
        {
          id: 5,
          size: '356 MB',
          totalProduct: 22,
          totalImage: 451,
          owner: 'David Parker',
          member: '3 Cows',
          lastUpdate: '2020-09-29T17:18:46.9019048',
          loadedDate: '2020-09-29T17:18:46.9019048',
          status: 'Active',
          additionalSearchTags: '',
          folderName: 'Favorite Videos',
          folderType: 'folder',
          canEdit: true,
          dataAssetSegments: [],
          fileSize: 7017063,
          interoperabilityService: null,
          interoperabilityServiceAssetCreated: '2020-09-29T17:18:46.9030573',
          isOwner: true,
          originalFileName: '6610a344-618b-4f9b-8071-71d00656ec53.psd',
          ownerId: 1,
          thumbSize100: Images.folder,
          thumbSize300: Images.folder,
          uniqueId: '321d0113-6356-4e29-8ba3-d0c9c2277705',
        },
        {
          id: 6,
          size: '356 MB',
          totalProduct: 22,
          totalImage: 451,
          owner: 'David Parker',
          member: '3 Cows',
          lastUpdate: '2020-09-29T17:18:46.9019048',
          loadedDate: '2020-09-29T17:18:46.9019048',
          status: 'Active',
          additionalSearchTags: '',
          folderName: 'Something Just Like This',
          folderType: 'shared',
          canEdit: true,
          dataAssetSegments: [],
          fileSize: 7017063,
          interoperabilityService: null,
          interoperabilityServiceAssetCreated: '2020-09-29T17:18:46.9030573',
          isOwner: true,
          originalFileName: '6610a344-618b-4f9b-8071-71d00656ec53.psd',
          ownerId: 1,
          thumbSize100: Images.sharedFolder,
          thumbSize300: Images.sharedFolder,
          uniqueId: 'ac8ff910-9a42-4f50-b50a-9c3c17d98657',
        },
      ],
      paging: { totalRecord: 1, currentPageIndex: 1, pageSize: 20 },
    },
  },

  '/api/khoidev/google-map-data': {
    html_attributions: [],
    next_page_token:
      'CpQCDwEAAJwdqLU_q4CMC9j-DOFIiwmwpP8ICAF_lHHKpWrNLfYsLS0hubKFTbau5sp7Tc_vC2bftXqZSRfq4uMk34-nE1f3weBRv77ZvsUhQn1MvJCfW6pNFA6zrDP_lPLBQZVrGVePIGhW8g485WnZdAHUyKpr4jjJ6hiOh2R6MxVBTYHyRuy5mDa-NGx4zQXDsHGSejOPH6BmPUoyfb-2MmRo1kcYABkBZRAaXpDmTtoXLmX8GxELbMOOAjEJ8Vzxdk-4GQkzoDYB1YwHBR1JXzxlawX0DdUyaJKjLWGjvt8GOwF8d9KVTF0r528jDCheFjvRgxOGMeYq1IrUx9lBofHH4sAX2plD6BGumN40mdBkDKVMEhC8pyLxL6t51mflBEc_WkF6GhTsH0kHwldrZYDnyUZ78TU9zeiiqg',
    results: [
      {
        formatted_address: '3818 Sunset Blvd, Los Angeles, CA 90026, USA',
        address1: '3818 Sunset Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90026',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 34.091158,
            lng: -118.2795188,
          },
          viewport: {
            northeast: {
              lat: 34.09258172989272,
              lng: -118.2780556701073,
            },
            southwest: {
              lat: 34.08988207010728,
              lng: -118.2807553298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '1113d3dd7339f965caae39387dd808a9e877bc2e',
        name: 'Flore Vegan',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 2336,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/100540448898812651487/photos">Roman Roze</a>',
            ],
            photo_reference:
              'CmRaAAAA69UVwaJnUQXQUSSX9IfB3b29opNIohkexsGAGoHTD5Lyg24lhpBtaiNlrgihstR-k7Su9Vgbc8-eE5qHEdeLVY1QTfiuyS9TPp3e2GMM_grW2FtrgrFQGtMJSeJ336cPEhCVHYfFzoOgrrKdXlk34rJiGhSXSv_XG1q1CtOrWJjWQrxJmLvIPg',
            width: 3504,
          },
        ],
        place_id: 'ChIJj62e80jHwoARusJT4mjohWw',
        price_level: 2,
        rating: 4.6,
        reference:
          'CmRbAAAAM9_YQ6Dt9T69zucczidzOd6HU2vmzaXvTG-lJ89KyBlJVBJ0aEfar2Exre4iDWKHjExUshYSpAXEzA-YqotVnOt4XznKY_vkD520XK5nzFz5v5IefUe6FDBqZPzYlxRDEhAgQvwzNjwC49WWlyoMKza5GhS6r-VIl1lXdMl_JEW67yL7fPkZdg',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '1700 Sunset Blvd, Los Angeles, CA 90026, USA',
        address1: '1700 Sunset Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90026',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.0771192,
            lng: -118.2587199,
          },
          viewport: {
            northeast: {
              lat: 34.07856197989273,
              lng: -118.2573112201073,
            },
            southwest: {
              lat: 34.07586232010728,
              lng: -118.2600108798928,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: 'd8bc8d867ccf72cbc552e100238a0820628963ee',
        name: 'Sage Plant Based Bistro and Brewery Echo Park',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 3024,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/105756638182226336287/photos">Anurag Singhai</a>',
            ],
            photo_reference:
              'CmRaAAAAW6v0EyZa77vrG5Aq8zbnU1sR7pljqfoDpSxXhISFgWoYWLkFY5hh7YCFbzYLj1XzflTJOrCXJa-q5jPT4L0vMY8cjXrhCzB5y7Z--qJTWOO_NxaRUBbB5QhpxyUT-R6tEhBru_ZZ_xcCxFueYrRxI6pFGhRgiExnWWOhU2Ii_NnW6M8R4xs4IQ',
            width: 4032,
          },
        ],
        place_id: 'ChIJ6T9ggBvHwoARc3aegK3PBe0',
        price_level: 2,
        rating: 4.6,
        reference:
          'CmRbAAAAy5CT3sE8bRlADeIeMYtC7NRdE58vKOCjOvhZNUs0QwBD7kSS6WIfo3wmxvt4EGZm5TJ6WdqOCRSnnnFoXAVpz9F3EyZPosUDCN2LJIvtYxwS3BYkwh6uVQRSj-OPLak6EhBRf8xOpcuc5WWWBgGNbGGbGhSYiomy4iNV-g_KzzqNrpymm3MxQw',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '8284 Melrose Ave, Los Angeles, CA 90046, USA',
        address1: '8284 Melrose Ave',
        address2: 'Los Angeles',
        address3: 'CA 90046',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Users',
        geometry: {
          location: {
            lat: 34.083527,
            lng: -118.370157,
          },
          viewport: {
            northeast: {
              lat: 34.08495787989272,
              lng: -118.3688093201073,
            },
            southwest: {
              lat: 34.08225822010727,
              lng: -118.3715089798927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '8529e7504ec45e2f380310882a3bd05fe050d701',
        name: 'Crossroads Kitchen',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 446,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/105056983516844525128/photos">Crossroads Vegan Restaurant</a>',
            ],
            photo_reference:
              'CmRaAAAAeNvmtrTLNo95XHaHxur4Lc3CSCbtl8zUk1cbjlFdDSpMupFJ0LXRP7iaqHQeP0N8srb3P9NzwI0jrASIvj7dn-8b0KkmjvgeAmETi9sDAEwQVOui2yIJd5yzzJN3LD_bEhCCaBWZ_g1fJHyzVzz9c18eGhTSCsqJIwmOF_xRWfhPBoFUO86y1A',
            width: 720,
          },
        ],
        place_id: 'ChIJhZy9Gba-woARQLEEpEN46uw',
        price_level: 3,
        rating: 4.5,
        reference:
          'CmRbAAAAU-AZsWP9B3I6f7bIB2lho68meak73rt_fxdoJug-tXoqRil2guPjWxQmycp2dPFKXZNG5azOwT-rEy9vJtXQf44uVj45j_ohS6ietOXClyyAppqZt_ngGVISdtcNvrFaEhDYSDnUEsrd7GxTol1wHfJ4GhRsQAnur588F9jnXy0S4IYaiaEAQA',
        types: [
          'bar',
          'restaurant',
          'food',
          'point_of_interest',
          'establishment',
        ],
      },
      {
        formatted_address: '4319 Sunset Blvd, Los Angeles, CA 90029, USA',
        address1: '4319 Sunset Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90029',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Users',
        geometry: {
          location: {
            lat: 34.0951843,
            lng: -118.283107,
          },
          viewport: {
            northeast: {
              lat: 34.09646662989272,
              lng: -118.2818451701072,
            },
            southwest: {
              lat: 34.09376697010727,
              lng: -118.2845448298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '287049135d7d1ab1bfd2de8d7654afcc97cfee77',
        name: 'My Vegan Gold',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 667,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/117593961088704552381/photos">Sayamon Riddang</a>',
            ],
            photo_reference:
              'CmRaAAAABpfiLbCymEfhpZDl0vsV5ZjC7UDVT-GQAryHJkQ1rZHakstWk4Cugb0HrxU3dsdqbjxvKU9gxVHsZjxToVuMwRw_dHWdwIPFNl-VGYtipa8Z6Uzf6RzmS2tDje45jzTREhD1E_zkXr3Z6v8wWLzDDxwlGhS8VB8VsaT96ycvEwdkjhzmNKSfDg',
            width: 1000,
          },
        ],
        place_id: 'ChIJh6ttFUzHwoAR9p8Wi_B8AbI',
        price_level: 2,
        rating: 4.6,
        reference:
          'CmRbAAAAUR_MLHOu0gPb-77cvoygt4blzXX2PtoI7PRfh8mqqOgx8MPgpclf8PcfCXkEvAfDxu4wafz5l1jBDTPTntDz3Epf0dJxilKvshezCkwMXdlIoZjBZwW8n6BMU5WKGCjXEhBd8OVC5aJ7Qc_S3skTq1KnGhQNTUFaUZAnY5r37iUSLCT59zfhrw',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address:
          '4442, 1769 Hillhurst Ave, Los Angeles, CA 90027, United States',
        address1: '4442, 1769 Hillhurst Ave',
        address2: 'Los Angeles',
        address3: 'CA 90027',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Users',
        geometry: {
          location: {
            lat: 34.1033401,
            lng: -118.2875469,
          },
          viewport: {
            northeast: {
              lat: 34.10468952989272,
              lng: -118.2861405701073,
            },
            southwest: {
              lat: 34.10198987010727,
              lng: -118.2888402298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '1401d50571af0bd7fc0267e6a3635bfb3d3dbda2',
        name: 'Green Leaves Vegan',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 3168,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/118194982700766373715/photos">Carl Afable</a>',
            ],
            photo_reference:
              'CmRaAAAAz4NOaoeJczV-BFF7HYE-unBeYl8bud0vmkVtYxCv-lKLYo1YnAgcf-Cisr4BQypE9WbaUwm157PYyxLkWkQ1CwVcP7bXtV6QAdNegOXB8QlWAEPR95Q6lm4NQ71B3Vm2EhD2nTXqDhitEm0BrjyRh3JxGhRYCJXLJCF4AWJiBXk5fcdcjylX_g',
            width: 4752,
          },
        ],
        place_id: 'ChIJ2RKN_rHAwoARIGWYxAgzzyQ',
        price_level: 1,
        rating: 4.5,
        reference:
          'CmRbAAAAtM6n50gnrmJYk2qR_1uslm5ILIFgla8_RVtkm5pLzg9pMV-UcNQ3LzYSc6iM2vm6rbIzSvmjYMvWZ0Qy0AnVyIhb0kYHi1HpNFbYF3aD5cxH2hyHuB9qbn_OY_7vTpWEEhDTEJ002c2Pt-QTBNerb9iOGhRnv28Ti7KlI3gYVtBKG5XKOju9Zg',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '707 E 10th St, Los Angeles, CA 90021, USA',
        address1: '707 E 10th St',
        address2: 'Los Angeles',
        address3: 'CA 90021',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.035798,
            lng: -118.251288,
          },
          viewport: {
            northeast: {
              lat: 34.03709272989273,
              lng: -118.2499997201073,
            },
            southwest: {
              lat: 34.03439307010728,
              lng: -118.2526993798928,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '01c75569ea2c5ee7ae29e91990ff4d872bc1ca5b',
        name: 'The Vegan Joint',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 680,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/106918371964521288041/photos">The Vegan Joint (Downtown)</a>',
            ],
            photo_reference:
              'CmRaAAAAGdHRzCGTwQ0_tc-fWo9wfezr51jbxPQ7IH1Gsc9OCRxoIijxg110YoNAaCTQSefXtCVdiH2xYcifKokVtnwxp01tE6J888NxT_MA1zpzxiM5ZM3MgJ9OxeJERDyE8jT0EhBSS5kv0YX7tmAxiUFHPOEXGhT2ZyiB6uRAfzpKchXvfOxyvA7S2Q',
            width: 1906,
          },
        ],
        place_id: 'ChIJL8eKojLGwoAR-inFlT4uF8Q',
        rating: 4.5,
        reference:
          'CmRbAAAAPj4xP0HMJoDhx-P7C2JZTRSxu44XKoALQqupq2WaiqDjqiqi7g1ua7imgENWEH_fXu8XS5XxAmpkYV4XcDWfbLeZFuOKbgNGIvxQhXBhL-215hJQvoHFodeO9_lCGliNEhC6QZwCTKktRp33eibHgQJyGhRZf9Z_PHxTO-dxhjCFmIY8KQUYlQ',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '2135 Sunset Blvd, Los Angeles, CA 90026, USA',
        address1: '2135 Sunset Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90026',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.0776068,
            lng: -118.2646526,
          },
          viewport: {
            northeast: {
              lat: 34.07886997989272,
              lng: -118.2632818701073,
            },
            southwest: {
              lat: 34.07617032010727,
              lng: -118.2659815298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '946525c8b38a73842b88f67b1786746dce63fc27',
        name: 'Elf Cafe',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 1365,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/104111246635874032234/photos">ZAGAT</a>',
            ],
            photo_reference:
              'CmRaAAAAXeyh-4q2GinyRWob20cRuMTPB9uwOMr4jceLbiGh1sUI3I3dU2tqPBg0fIALpeBD4h5cfCTyULBTj6vSqNEcDUcPGFnjOaDO3BYQALO94_WCtbjkY83fQkdM_BPFlm2oEhDshmrB_41OPbyEVGJ4fPUVGhSYvebsAZyOFjSgUoJm_QQAhDFhzA',
            width: 2048,
          },
        ],
        place_id: 'ChIJEy4mphDHwoARejZ9zUN_d4U',
        price_level: 2,
        rating: 4.7,
        reference:
          'CmRbAAAAwxF-jK7VtlDfSJigYrgWtGrgNXRvw-4pU62YULylvTajhxW9lwzvmYKmKKx4ZO0SBMR1TOqVE-rPlhlh7aZI5NfcL8RItn_IX1BxS7X9X2ode26MDbVwICFFC_qziC-MEhA4i-3OikQ9eHbTeu-L27G1GhRLgk421tk5hnWDbhrEbtK-S3Ap4A',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '4114 Santa Monica Blvd, Los Angeles, CA 90029, USA',
        address1: '4114 Santa Monica Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90029',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.0919263,
            lng: -118.2820544,
          },
          viewport: {
            northeast: {
              lat: 34.09333132989273,
              lng: -118.2807559701073,
            },
            southwest: {
              lat: 34.09063167010728,
              lng: -118.2834556298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: 'd358acb483e9bacaf445de016f78473908253696',
        name: 'Bulan Thai Vegetarian Kitchen',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 3024,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/107987703028923546874/photos">jeromy robert</a>',
            ],
            photo_reference:
              'CmRaAAAAbjH5vQk8Ese5ppONFAIwbunnNnhcEEkKtRrn5yttTYvxHxjbgbGDXIv7cNSfQMU7wICcox5NT__xnaKwJi-e3zr5230Z4t8TcReOcVoV8Ewjfn5g7AL4AK0gksctR-KqEhD6iw1PbCByU1zD7iD3lxPXGhT5AoSLQ7BHIzymU94-0xSvRMo9yA',
            width: 4032,
          },
        ],
        place_id: 'ChIJAaqI1E7HwoARRCxBLPw048Y',
        price_level: 2,
        rating: 4.6,
        reference:
          'CmRbAAAAbLDZZu-oOjRmRi7hsQpZELuusRQZEk6lE1YJL5r7pnP3RtWaSLlA_p9uYp0QIalfzXhS0605iRnNsEr_WDhos1HxubW4h0sPKz96GPUKeULOux319b2pYHqQCYwYUmOMEhCEXh-YmfKFafd1EtkAYkB2GhSbxBpYLCWz45RTP0YaWm6eeJecwQ',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '1047 S Fairfax Ave, Los Angeles, CA 90019, USA',
        address1: '1047 S Fairfax Ave',
        address2: 'Los Angeles',
        address3: 'CA 90019',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 34.0568525,
            lng: -118.3646369,
          },
          viewport: {
            northeast: {
              lat: 34.05817752989272,
              lng: -118.3632100701073,
            },
            southwest: {
              lat: 34.05547787010728,
              lng: -118.3659097298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '6f73d8dfd2f3dc8b08d127ea3d0691b33030973b',
        name: 'Rahel Ethiopian Vegan Cuisine',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 2988,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/100435880280656818067/photos">Edna Banayat</a>',
            ],
            photo_reference:
              'CmRaAAAAsltyy0_FJzHaxUaRfy3ks0qgLs8N0x2Y7dZqKDw21NUi7D9STyFscfcUKiL9z9E2LXC3jrIqq6vikR6j0hb8_L7kCDr1WjgVUamyzOw3GdXSmaDVEQrOWDkBSGWGCvSAEhA9zXJDgrZrtdC90Jah1VWnGhRr8wcVDTal8O1R7S6WOQ0482phCw',
            width: 5312,
          },
        ],
        place_id: 'ChIJqeeNwhW5woARdmN2K2Q107A',
        price_level: 2,
        rating: 4.6,
        reference:
          'CmRbAAAAoNTyxGrtvDexCku_OpmL47Akpq5T587ncWV1Rp8xWIc7YQlqk75_0h2_nB0bduMRadfyHsW6i6SXFUNicU0BWvaGGy3Mtta6Omd-9lePm-ZN2EQBbuT05kFBNHkWbs9zEhCfXjvnfKzALRwRyDKEeQr-GhRIXuOSJHoM7g6L-V83WDXl3mxUgQ',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address:
          '4664, 10438 National Blvd, Los Angeles, CA 90034, United States',
        address1: '4664, 10438 National Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90034',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 34.0285781,
            lng: -118.4115541,
          },
          viewport: {
            northeast: {
              lat: 34.02999047989272,
              lng: -118.4102540701073,
            },
            southwest: {
              lat: 34.02729082010728,
              lng: -118.4129537298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '92cc1f89e314ba9e518f1d7aa345a57441affe39',
        name: 'The Vegan Joint (West LA)',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 421,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/106873412906570774753/photos">The Vegan Joint (West LA)</a>',
            ],
            photo_reference:
              'CmRaAAAAN3v7CUQbGdsnPNSZ26qvp11iqa78bJjwe_P9pr0rA0meuKU916gHo0OktysWHWB-ZhFRtiVGYQeTm6mQUVChAxglotv9Tgv4VTRAIFdy_-WeLSvkQz-KhOf-mtvmfObfEhCOC0m29jY1Dvm0Xz_l2s1sGhT-9vWMge8CoQrb5wpFAtLrUB8phg',
            width: 750,
          },
        ],
        place_id: 'ChIJ74X8ncu7woARzxiCmkqeeXc',
        price_level: 2,
        rating: 4.4,
        reference:
          'CmRbAAAAh4e7HwMd83IQ1TW182b2n_qdhUDULWHH33HQjJOT_DGzaFV2HyLLEnN4z07DGn_-S0GsA2mwy3hvI-GFIKW1hM-VO8MOaJkiFMfWW347qlVy6iYmltY0DzU_-eA02rHPEhC0AGDxyCgGEH-2PrMsG0_ZGhTFDKatE5VBRZkTdWAaYfzSrs3QDg',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '3655 S Grand Ave, Los Angeles, CA 90007, USA',
        address1: '3655 S Grand Ave',
        address2: 'Los Angeles',
        address3: 'CA 90007',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 34.017339,
            lng: -118.278469,
          },
          viewport: {
            northeast: {
              lat: 34.01859817989273,
              lng: -118.2769120701073,
            },
            southwest: {
              lat: 34.01589852010728,
              lng: -118.2796117298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: 'da772e983e3e6dbc0cd042042311ab79839d1f28',
        name: 'Azla Ethiopian Vegan Cuisine',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 3024,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/110952242659405432450/photos">Benjamin Rubin</a>',
            ],
            photo_reference:
              'CmRaAAAARwfVFSLVUmk_dVkBaY43M8sPng1XdXX7Is882vzG7KcFewp2dMMlYqwKQSGyfAOSWZ3vJrgBUEypUwJESuqoxRneTLjp9W-XcQ4pQByDVabpYbT5r5y006esU3aTeHvIEhDXtTfa4Kh1z_-u4D_BFJT2GhSQDwL9zFfvKbwRuGmmcui08SdiLg',
            width: 4032,
          },
        ],
        place_id: 'ChIJmb4BkgrIwoAR-PS33wrjk_M',
        rating: 4.8,
        reference:
          'CmRbAAAA5lMrGEVyK5VhjDMMJZ2tUR0Poo9zQt4wZZBcW46e3CfsYUN0INPZKeeKERZJwkhcogLK-FsKAZI92jncCYN4RZQYqtyiJONx3I6VWIrTXGI9rOt01dSTtBCZEKtag0AcEhBlaMFWX2bnU8gMjCv3Fs1AGhSs8gJhrAa-jQNLRX7pxzIqBJ_HQg',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '8101½ Beverly Blvd, Los Angeles, CA 90048, USA',
        address1: '8101½ Beverly Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90048',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 34.0764288,
            lng: -118.3661624,
          },
          viewport: {
            northeast: {
              lat: 34.07760962989272,
              lng: -118.3648135201073,
            },
            southwest: {
              lat: 34.07490997010728,
              lng: -118.3675131798927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '1924e9b97b3101ea1d18ac33191e8c2d8fffcbc2',
        name: "Araya's Vegetarian Place",
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 3088,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/103485549458724391583/photos">Simon Catford</a>',
            ],
            photo_reference:
              'CmRaAAAAyNe-nZSiUqUrfU3RKs7KqCG2-yoYpRixjGySI1LQgkDKkmRycyaz8RNyaVTYD8bqIf8FpmH7MOvC84sF6gVTsOBKI3IUGeyx54Uxntf8IkZOx4MjDr-Gdh_e40hXYKePEhApDNZUIBhEwnQHrWJqcBA1GhSN3DbanyozQB0pl8wVQucBZbsg1g',
            width: 4160,
          },
        ],
        place_id: 'ChIJV-aT-jS5woAROYkaRCGf2s8',
        price_level: 2,
        rating: 4.6,
        reference:
          'CmRbAAAAeSSwdHRB2eK9ro7pBWjJZeiDbH12E8AhhMyUsosVZeCMtzmNgPNfRgbS96NRPgdbWrudWjLJrKcomMqCyH8PWpA8rhSKHf7Kj7u7rgBv0r3b4kowdzJ4VUe8WyNoGQ_VEhBVcix6m4C5QlEf-G3y7AveGhTqHZ55gdeHglCukJFsCIpkzMR9Cw',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '4507 S Centinela Ave, Los Angeles, CA 90066, USA',
        address1: '4507 S Centinela Ave',
        address2: 'Los Angeles',
        address3: 'CA 90066',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 33.9925942,
            lng: -118.4232475,
          },
          viewport: {
            northeast: {
              lat: 33.99401072989273,
              lng: -118.4218230701073,
            },
            southwest: {
              lat: 33.99131107010729,
              lng: -118.4245227298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '8f5ac5552a0e53f7ae627916c3d1d48b45030f54',
        name: 'LA Vegan',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 3644,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/108088361466169015751/photos">Eric Estenzo</a>',
            ],
            photo_reference:
              'CmRaAAAAdcatw_mFed_48beFNIsyCrjLRenujvkj0YkhN5PX8oJQAr013xQEGat49VQ1GmrvEMRTV8CIeWtBOYSwzIWUrD7kyY1c3sgmx-0y_ecKUIuSlxhlbDtjQeCa3U8SV5yGEhAGwkRlZ2kuFsZ5uBKLh79CGhRKQDVIo1Z_ijdaHPLZqjuRU8mcew',
            width: 7012,
          },
        ],
        place_id: 'ChIJZUzGG266woARXsRdJc2silw',
        price_level: 2,
        rating: 4.6,
        reference:
          'CmRbAAAAXBx1MbFAy5X5HJqg1yMWtv10pIpp5Qeq2lCD7y7dhJM5B8iHXgysRGXgjsD5eTeKnxuaM26srTPC3Ib7g1TfBAnaIcVVw7K0-JEiuRzs-dBnuj_1ey5A0r3zvXOQpcN0EhB18uIS3_Rj_T61b2Sfo0ecGhSmROJ3Of8T44ckuMzOqKUJsZng8Q',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '8393 Beverly Blvd, Los Angeles, CA 90048, USA',
        address1: '8393 Beverly Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90048',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 34.0764345,
            lng: -118.3730332,
          },
          viewport: {
            northeast: {
              lat: 34.07760002989272,
              lng: -118.3716821701073,
            },
            southwest: {
              lat: 34.07490037010728,
              lng: -118.3743818298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '1d6756ade69dac8cf5ce42aeeeb793535b4504e8',
        name: 'Vegan Glory',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 570,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/104981305421921650092/photos">Thom Uber</a>',
            ],
            photo_reference:
              'CmRaAAAA1YSeQpoJrDP6swpc1vw2b364pPMsjw2s2DVZYV4AWfENCwY02zHen8VpfI27Vnh8762bVFs4kda28R2sH354xKhju0vBIEM4yRlQr_G1-NvcywFtMQE7mBVtgegTo6ADEhBBLf_S0InHdmZ-SVC1KX8zGhQS9hpDvsrqVNDPNuWKLSPHy34XoA',
            width: 760,
          },
        ],
        place_id: 'ChIJyYMRL0u5woARId-DVapUFNk',
        rating: 4.4,
        reference:
          'CmRbAAAActbey6zjHroEmUk5ZVCyD6LR0-RHIuWbMAJW5v7PaA-V-pijZFT9MgDjuoCOYAy7asUfIXfLiPxli2mdJtUGTCsUW6qw91uHaZR6XQv62bUFwmM9qM6IB6gOcv4T5BKbEhD9Jo87T4pNpyFSgCZWcyMOGhT2Au7pVgKuofn6_B7s4vSFUNhXPA',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '1253 Vine St # 9, Los Angeles, CA 90038, USA',
        address1: '1253 Vine St # 9',
        address2: 'Los Angeles',
        address3: 'CA 90038',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Offices',
        geometry: {
          location: {
            lat: 34.093981,
            lng: -118.327638,
          },
          viewport: {
            northeast: {
              lat: 34.09533472989273,
              lng: -118.3257989701073,
            },
            southwest: {
              lat: 34.09263507010728,
              lng: -118.3284986298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: 'a869aba570e8103a6ae09ae71335a38e99b11e01',
        name: "Doomie's Home Cookin'",
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 2400,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/114793967685216290949/photos">Joe Gebis</a>',
            ],
            photo_reference:
              'CmRaAAAA20MBConiFPlO2-6BuoTBSGSAUBJaYoX0_m0xj_MNE2BPaqhDDSNtUXMTcmiKKZw0umwGkTaBt-Y3kNfKwKfRAdNnW9byHMuvElqBWXZTPSPuTIN_4xJKt1tKsosLuIfBEhB_ZCYjBhFWDue7-Z-UcfFjGhRyD45yAH2035Ifu15OklnylBIfhA',
            width: 3200,
          },
        ],
        place_id: 'ChIJ56wYlja_woAR7f2m0YbS8hk',
        price_level: 2,
        rating: 4.4,
        reference:
          'CmRbAAAAObVl81DX-3CYhyQv0itdfPojjR7bwG0eAv2JpZb9ZYoVBNg9F0znMXC72bBsDctx7ogiPKPvagqMNtEFkHYG7aLMk5VO-xtR8sfDAgeEiHHZV24oStUWs4aY-xqrU8W4EhAok2aSJPg0eRFD7nXeJpzzGhRPGtkJYZeCZHvy9UctDGItmFdbKg',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '710 W 1st St, Los Angeles, CA 90012, USA',
        address1: '710 W 1st St',
        address2: 'Los Angeles',
        address3: 'CA 90012',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.056385,
            lng: -118.2508724,
          },
          viewport: {
            northeast: {
              lat: 34.05794337989273,
              lng: -118.2493326201073,
            },
            southwest: {
              lat: 34.05524372010728,
              lng: -118.2520322798927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '53b4341b80e3596c05431abaa9b42b3fb0adf66d',
        name: 'Âu Lạc LA',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 3036,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/103902774314509444130/photos">Patrick Donnelly</a>',
            ],
            photo_reference:
              'CmRaAAAAmofVPSjKMklO3J2mpcRl_kHAwl710EqByuQjaP8rjOtmj08yJKKr1xcJICtaZ-quKiiG2aYUhFru5JF1eYeDHx-pILIPOmKspUN07oGjhMwKnUa73wikXvM-FVJuJcRMEhDLVub-Rx6D4N3iiN0JAtHhGhQQ2Un8dIsylPeLN9IcRcerUif-1w',
            width: 4048,
          },
        ],
        place_id: 'ChIJe0sPnFLGwoARxFDUYFEjhuQ',
        price_level: 2,
        rating: 4.5,
        reference:
          'CmRbAAAAHb0M5CcLv12TZJiEq0QRs5nexZ-fBdKvzQmTE8w3j_-YHX15hSy7WYb3EuweeyxhuIBirsVr4uBMtSUP4KoClwz1l6uE0KgO5jHySb6a9Qi5_Hoju3iN55xLA38dCGPTEhBqVAmmstFHL-wzx6LKFLkWGhTdLVJpc_s2vuSY-OL3rJzZStmuFg',
        types: [
          'bar',
          'restaurant',
          'food',
          'point_of_interest',
          'establishment',
        ],
      },
      {
        formatted_address: '2870 Rowena Ave, Los Angeles, CA 90039, USA',
        address1: '2870 Rowena Ave',
        address2: 'Los Angeles',
        address3: 'CA 90039',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.107701,
            lng: -118.2667943,
          },
          viewport: {
            northeast: {
              lat: 34.10916667989272,
              lng: -118.2653993701072,
            },
            southwest: {
              lat: 34.10646702010727,
              lng: -118.2680990298927,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '199ee04e9dbcb33b16d3aed792b6c0cc0e441d8d',
        name: 'Little Pine',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 563,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/113035519513505430750/photos">Karen TenEyck</a>',
            ],
            photo_reference:
              'CmRaAAAAoYVVT_rhyzFXzPVGgWSkRNXU5Fqm7AC5BcX7obilkGQX_cqVfu74Jb4Dm77gWDtvF3uov5Oeu06JutufKGQwbWlBJmdH0O3xCDoNA-t5uPOHjvZu26A2WYA3JtaMR4TzEhAbM1Fsz-8UUaZOh37fbEs3GhRdnpshI2u0QbwbL0KbAa_IiNPPdg',
            width: 1000,
          },
        ],
        place_id: 'ChIJjc_P0c3AwoARBWVMPN_YVRU',
        price_level: 2,
        rating: 4.7,
        reference:
          'CmRbAAAAugyZhEPl2BK5sW7_AnhEZv-d-21pR6GzooTVD3RJFsDF9hecZMusog36IO34t7wuFczZWyD8kEiVKilj4Sp9buBdtO077raxcOkVC6--BXKuhGrh2Lkq5dhR2pLXNLXZEhBKcQzGE58Xr82Fz7Oy--MaGhTT7qBjkenQ3jVinJpgqASlYR5c0g',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address:
          'Little Tokyo Shopping Center, 333 South Alameda St #310, Los Angeles, CA 90013, USA',
        address1: '333 South Alameda St #310',
        address2: 'Los Angeles',
        address3: 'CA 90013',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.0450139,
            lng: -118.2388682,
          },
          viewport: {
            northeast: {
              lat: 34.04636372989273,
              lng: -118.2375183701073,
            },
            southwest: {
              lat: 34.04366407010728,
              lng: -118.2402180298928,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: 'bdc0c9353ceb91395214f3f84f0016059271acd7',
        name: 'Shojin',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 2336,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/100540448898812651487/photos">Roman Roze</a>',
            ],
            photo_reference:
              'CmRaAAAALLVT3JKv4U1gRmbItfHhzOIdYRIibKmLMWThQa0gYU-umKbevliJUIogQL_Hm1_6oWGlutmR4kuzd6ikrpM4_eIeLSprpxAlHiEmi3FVNN5XvnBxfV8xWj6ggQLNM8DxEhDscXYyU5r1aKlYTCVLG61tGhQsd9XLF9OR7UL9XZo_eOm1Y98hCw',
            width: 3504,
          },
        ],
        place_id: 'ChIJyy_0pjnGwoARi47oqNbdedU',
        price_level: 3,
        rating: 4.6,
        reference:
          'CmRbAAAAORx-wW_TP5eWz0P93UBThqK-FdOLxos-ptjGRGlmiFaOf6ySc43rGF1ENCNilSFQL0Q-7RtFawCJMdvApBCTEyHZnRIIx4Jp4cRIcvSxRBJzB-S3aWfAglqv4L_sO7zsEhAjm-hxEpM5a-8fqVVcqJAbGhQDjg_IeMxUnePapODPTZPxOis_Lw',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '2520 Glendale Blvd, Los Angeles, CA 90039, USA',
        address1: '2520 Glendale Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90039',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Stores/Plants',
        geometry: {
          location: {
            lat: 34.1031997,
            lng: -118.2586152,
          },
          viewport: {
            northeast: {
              lat: 34.10455672989272,
              lng: -118.2575728201073,
            },
            southwest: {
              lat: 34.10185707010727,
              lng: -118.2602724798928,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '52be338abc98361f3c8664a7d4e853a11332afec',
        name: 'by CHLOE. Silver Lake',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 2988,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/104636687373132435944/photos">Jeannette G.</a>',
            ],
            photo_reference:
              'CmRaAAAA4Yb-teP-cLR0wHYMwtNvoo-wPlgVsj444v-VTgdwi63Gshd-PV5ZMhVPQSN2v5yPM-lxvZQW7Redm4wNKPLmGku5MNs0yhMuBysvXO6gpJ6kF4PGCepMIcZQ409LpBD_EhD8ZeAMspzsx_RnDHSQkuDlGhQ3-VOGj2K85VfvxsOCAyIS4HdVnw',
            width: 5312,
          },
        ],
        place_id: 'ChIJ4fYRfivHwoAR_2DlpZxO5_8',
        price_level: 2,
        rating: 4.5,
        reference:
          'CmRbAAAALsJqmhwH-aLZPy6SNAbLNzQRQ3qfT92yX3k6t13Ynq9K5hQatkTEBPhTr-pqcF3RznWRRYDNUoyPSoT8irbse6j2VZUHsgvo7wvPPVSTeYaeB3gZYXNaFnJmcqyUUU4CEhAhjByzVT-NNxUGH3pcyuS-GhTSUc8pvZUzjDie_3LXHrkObrtDzA',
        types: ['restaurant', 'food', 'point_of_interest', 'establishment'],
      },
      {
        formatted_address: '639 N Larchmont Blvd, Los Angeles, CA 90004, USA',
        address1: '639 N Larchmont Blvd',
        address2: 'Los Angeles',
        address3: 'CA 90004',
        address4: 'USA',
        comment: 'Comment & Description. that runs',
        type: 'Users',
        geometry: {
          location: {
            lat: 34.0828183,
            lng: -118.3241586,
          },
          viewport: {
            northeast: {
              lat: 34.08422687989272,
              lng: -118.3225729201073,
            },
            southwest: {
              lat: 34.08152722010728,
              lng: -118.3252725798928,
            },
          },
        },
        icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/restaurant-71.png',
        id: '21f5bb524cea1c8698187b817298300df290ad1b',
        name: 'Cafe Gratitude',
        opening_hours: {
          open_now: false,
          weekday_text: [],
        },
        photos: [
          {
            height: 1365,
            html_attributions: [
              '<a href="https://maps.google.com/maps/contrib/115181038312887901832/photos">Cafe Gratitude</a>',
            ],
            photo_reference:
              'CmRaAAAAX_eBNo4lUv8qGy5th3upd5Qp_Gx9NEQ55wfy0Yb7l1L_0M_gIwQqPb55RiJivmK_Wu3_CiyjBwEVGeeQCGRPHanVPYIG7_fQGV3_o2QgPSgQOAEfeKvuvdeUFGLX2TMzEhANtSJNyyzGbezwvsEmgvklGhSNKWwiEgOHaw28SqiWmqm1aAWDJQ',
            width: 2048,
          },
        ],
        place_id: 'ChIJjRPL7sm4woARFcIwlfejAGA',
        price_level: 2,
        rating: 4.5,
        reference:
          'CmRbAAAANXEcxEtfEYz2V5OIwdFEuyw35xZZ7SpF9AVuYFUqha3tf8CiZbuYrXLCG_y-twfXpcRiYyN0kXDfwmjA-uPZQaPbfpriBY397myHuiFF1A1ESI-5Hcb1y6UiRger5g3tEhCRq3Ml_vdNqA8AGua9iLXrGhTz1KC9902YirqAu7KLW4Bq7FYOEQ',
        types: [
          'cafe',
          'restaurant',
          'food',
          'point_of_interest',
          'establishment',
        ],
      },
    ],
    status: 'OK',
  },
  '/api/chat/get-thread-list-for-supporter': {
    isSuccess: true,
    data: [
      {
        _id: '6098f5892e467fe048f7689a',
        avatar: mockImage,
        name: 'Paul Levine',
        lastMessage:
          'Dolore duis commodo aliqua quis aliquip ex eiusmod deserunt mollit ut non mollit. Reprehenderit irure voluptate ex enim eiusmod nulla do sint anim nulla eiusmod id nulla. Cupidatat laboris ad anim mollit nulla mollit labore eu tempor. Excepteur exercitation veniam esse dolor et sint ipsum laboris dolore esse. Quis proident esse minim nulla esse aliquip deserunt. Tempor officia eiusmod eu exercitation sint aute tempor non consectetur voluptate sint consequat officia minim.\r\n',
        lastTimeStamp: '2016-08-21T05:26:07-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f589a2932f4b69ce7bf3',
        avatar: mockImage,
        name: 'Araceli Joyce',
        lastMessage:
          'Amet cillum est commodo aliquip quis nulla enim velit. Esse cillum consequat labore et voluptate. Labore labore dolore dolore proident.\r\n',
        lastTimeStamp: '2018-02-25T03:28:50-07:00',
        hasNewMessage: false,
      },
      {
        _id: '6098f589cc856cd5f1f79e3c',
        avatar: mockImage,
        name: 'Sarah Knapp',
        lastMessage:
          'Est laborum nulla amet excepteur tempor ad laborum. Exercitation ullamco deserunt exercitation anim. Est pariatur minim sunt aute elit velit in elit voluptate fugiat ad reprehenderit. Commodo proident exercitation ad aliqua irure velit qui eiusmod sunt velit. Ipsum laborum consectetur eu cupidatat fugiat. Ad proident do proident magna sint minim culpa non et ad tempor eu. Cillum do sunt sit dolor ex nulla anim irure laboris tempor.\r\n',
        lastTimeStamp: '2016-11-08T09:54:45-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f589b25cab1af21eef60',
        avatar: mockImage,
        name: 'Patty Gibson',
        lastMessage:
          'Veniam non est irure officia incididunt cillum proident velit deserunt nulla dolor. Do amet proident elit et labore duis mollit cillum cillum ut adipisicing. Amet Lorem in excepteur excepteur laboris officia eu esse velit. Velit minim ea veniam sunt cillum irure.\r\n',
        lastTimeStamp: '2018-04-24T01:04:16-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f5899ff635fd016dd36a',
        avatar: mockImage,
        name: 'Kristy Beach',
        lastMessage:
          'Incididunt mollit pariatur aute officia eiusmod eu labore. Irure pariatur amet adipisicing tempor et fugiat ea. Sint sit id magna enim do ullamco dolor nostrud ipsum et. Culpa esse Lorem enim duis consectetur ex ad est adipisicing ullamco esse qui fugiat aute. Commodo et consequat consectetur et officia pariatur quis amet ex sint Lorem velit nulla enim. Ad aute excepteur anim proident anim.\r\n',
        lastTimeStamp: '2021-04-26T10:51:49-07:00',
        hasNewMessage: false,
      },
      {
        _id: '6098f589c89819cbb200dcaa',
        avatar: mockImage,
        name: 'Meadows Stein',
        lastMessage:
          'Et esse consequat ex qui exercitation. Voluptate laborum eu labore non amet non adipisicing ipsum quis labore aliqua incididunt. Qui do mollit labore eu. Duis reprehenderit occaecat voluptate ad mollit tempor culpa exercitation proident nulla amet nisi. Incididunt non quis fugiat excepteur enim consequat irure dolor. Ipsum duis occaecat sunt dolore qui cupidatat reprehenderit eu veniam mollit Lorem voluptate. In esse laborum anim enim.\r\n',
        lastTimeStamp: '2019-12-30T05:24:45-07:00',
        hasNewMessage: false,
      },
      {
        _id: '6098f589021a195b57cce5b6',
        avatar: mockImage,
        name: 'Laurie Mullen',
        lastMessage:
          'Consectetur Lorem voluptate velit deserunt non eu fugiat anim proident dolore minim. Laborum magna anim amet consectetur. Laborum laboris consequat qui mollit reprehenderit anim aute velit exercitation ea. Eiusmod magna duis occaecat voluptate.\r\n',
        lastTimeStamp: '2020-11-29T07:35:01-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f5893d6864154b62f11f',
        avatar: mockImage,
        name: 'Frazier Davenport',
        lastMessage:
          'Ad esse ullamco ipsum officia enim duis. Id fugiat Lorem cupidatat sint excepteur sunt ut duis ullamco aliqua qui. Consequat velit proident aliquip excepteur quis id.\r\n',
        lastTimeStamp: '2014-05-25T04:07:08-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f589d7fbcab70ba44843',
        avatar: mockImage,
        name: 'Kathleen Fulton',
        lastMessage:
          'Labore deserunt cupidatat amet enim exercitation dolore nulla ea deserunt do culpa. Tempor enim proident mollit pariatur. Dolore dolore occaecat culpa minim sunt. Ea aliquip fugiat exercitation amet fugiat. Officia enim amet magna commodo adipisicing id labore nostrud pariatur eu amet culpa anim culpa. Qui incididunt veniam id nulla Lorem elit.\r\n',
        lastTimeStamp: '2016-06-12T09:42:46-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f589bb1ccc59e10c44e5',
        avatar: mockImage,
        name: 'Jenkins Guthrie',
        lastMessage:
          'Laborum adipisicing ex ea proident. Pariatur duis amet veniam id. Nostrud sint ad cupidatat deserunt. Anim nostrud amet exercitation culpa ipsum qui sit velit qui occaecat. Labore magna consectetur occaecat magna adipisicing aute adipisicing laboris dolor et aute occaecat deserunt. Ex commodo fugiat cupidatat sunt. Minim excepteur commodo deserunt officia labore proident.\r\n',
        lastTimeStamp: '2015-04-17T03:08:49-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f5897243309b33c8105b',
        avatar: mockImage,
        name: 'Arnold Byers',
        lastMessage:
          'Magna voluptate aute sit est aliquip duis deserunt ad duis id consequat ipsum ea. Reprehenderit aute Lorem ad commodo adipisicing occaecat pariatur. Ut pariatur dolor aliqua elit velit aute cupidatat ea. Sint ut ea enim consectetur cupidatat elit est excepteur et incididunt labore. Qui fugiat et mollit anim est voluptate veniam occaecat dolor.\r\n',
        lastTimeStamp: '2018-12-12T02:25:54-07:00',
        hasNewMessage: false,
      },
      {
        _id: '6098f5891b91032f99e4b38e',
        avatar: mockImage,
        name: 'Serena Gross',
        lastMessage:
          'Mollit sunt culpa veniam mollit officia labore enim duis cupidatat sunt ad anim anim nostrud. Enim fugiat incididunt cupidatat ullamco nulla nostrud enim fugiat consectetur enim consequat esse et. Laborum magna eu ea tempor labore. Elit sint incididunt et eu ad non magna dolor.\r\n',
        lastTimeStamp: '2015-02-01T05:02:03-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f589e89f7ef42ec294ea',
        avatar: mockImage,
        name: 'Warner Carney',
        lastMessage:
          'Dolore esse voluptate do exercitation. Occaecat esse Lorem in nisi Lorem aliquip et minim commodo voluptate. Ullamco sint ipsum ut duis labore incididunt id ut eiusmod enim.\r\n',
        lastTimeStamp: '2014-05-10T11:25:54-07:00',
        hasNewMessage: false,
      },
      {
        _id: '6098f58971bc9d5b2dd28e15',
        avatar: mockImage,
        name: 'Moore Murray',
        lastMessage:
          'Velit voluptate aute reprehenderit adipisicing sint pariatur labore. Laborum cillum proident exercitation velit. Est laborum laboris ea qui excepteur velit dolor excepteur consectetur magna velit. Commodo magna proident mollit deserunt do minim adipisicing quis.\r\n',
        lastTimeStamp: '2020-11-15T06:41:58-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f58904c08e5214184dff',
        avatar: mockImage,
        name: 'Snow Love',
        lastMessage:
          'Et magna aliqua officia deserunt labore culpa fugiat. Veniam reprehenderit anim excepteur officia ipsum deserunt in. Occaecat elit ipsum ad et occaecat culpa aute ullamco veniam qui ad. Reprehenderit qui sint id nostrud. Irure exercitation sunt eu consectetur. Laborum non reprehenderit exercitation consequat nisi sunt ad et consequat nulla veniam.\r\n',
        lastTimeStamp: '2017-06-22T08:10:06-07:00',
        hasNewMessage: false,
      },
      {
        _id: '6098f589631dd447956845ec',
        avatar: mockImage,
        name: 'Rhoda Houston',
        lastMessage:
          'Sunt in eu aute ea elit velit. Laboris culpa reprehenderit excepteur culpa laboris nostrud ut enim adipisicing duis. Officia aute dolore elit incididunt sit deserunt consequat. Amet sit exercitation cillum enim incididunt.\r\n',
        lastTimeStamp: '2014-06-20T04:19:17-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f5897f60b1f4173cedb8',
        avatar: mockImage,
        name: 'Veronica Rosa',
        lastMessage:
          'Consequat amet laboris ullamco exercitation occaecat sit quis. Ea dolore ad proident nisi ipsum excepteur qui minim exercitation. Ut ea exercitation occaecat do nisi quis tempor id elit exercitation.\r\n',
        lastTimeStamp: '2019-12-15T04:42:47-07:00',
        hasNewMessage: false,
      },
      {
        _id: '6098f589c909bb29d2c6e458',
        avatar: mockImage,
        name: 'Bridges Blake',
        lastMessage:
          'Voluptate quis occaecat et esse consectetur velit ipsum magna velit non sunt minim irure cillum. Esse cupidatat minim ut id aute consectetur veniam. Officia Lorem sunt veniam amet aliquip quis officia. Aliqua aute quis adipisicing incididunt laboris aliquip ullamco irure est eiusmod id enim consectetur.\r\n',
        lastTimeStamp: '2019-11-23T01:33:58-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f58988cbac92174c5b52',
        avatar: mockImage,
        name: 'Nixon Peterson',
        lastMessage:
          'In ullamco eu dolor duis nostrud nisi id minim pariatur. Qui deserunt fugiat dolor sit reprehenderit nostrud consequat culpa non enim proident dolore consectetur exercitation. Voluptate nulla eiusmod labore cillum cupidatat cillum. Officia tempor do et laboris aliqua ea occaecat aliquip elit velit enim. Excepteur veniam officia culpa nisi quis nostrud proident esse amet aliqua adipisicing. Qui nisi nostrud eu sint exercitation enim minim duis. Anim ad dolore sunt consectetur nisi elit aute reprehenderit Lorem ullamco ullamco.\r\n',
        lastTimeStamp: '2014-05-28T01:40:19-07:00',
        hasNewMessage: true,
      },
      {
        _id: '6098f589050230b7755d263f',
        avatar: mockImage,
        name: 'Sybil Gilmore',
        lastMessage:
          'Voluptate in deserunt aute labore. Magna quis eu do cillum nostrud ipsum ut elit duis ea et reprehenderit adipisicing. Pariatur duis commodo ullamco laborum est eu proident do voluptate incididunt commodo.\r\n',
        lastTimeStamp: '2019-12-15T08:23:57-07:00',
        hasNewMessage: false,
      },
    ],
  },
  '/api/chat/get-chat-content-detail': {
    isSuccess: true,
    data: {
      title: 'Nước Oxy Già',
      conversation: [
        {
          id: 2,
          type: 'message',
          message:
            'Proident anim culpa deserunt duis sit esse quis ea duis Lorem officia eiusmod quis duis. Aute ullamco quis reprehenderit consequat sit duis exercitation ad anim. Id ad eu fugiat ullamco id. Sunt Lorem id adipisicing duis enim consectetur exercitation. Qui anim laborum irure consectetur aliquip pariatur consequat id non fugiat reprehenderit. Irure voluptate ad occaecat cupidatat tempor ipsum elit nisi est consequat laboris velit.\r\n',
          time: '2020-10-11T02:51:08-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Pariatur aute incididunt cupidatat nisi dolore quis Lorem labore sint veniam esse cillum ut. Aliquip cillum ullamco sint qui aliqua nulla elit id Lorem elit. Occaecat nisi aliqua non in consequat enim id nulla irure consectetur. Dolor reprehenderit labore officia ex excepteur elit nulla incididunt enim consectetur cillum dolore.\r\n',
          time: '2014-06-22T01:03:31-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Aliqua non veniam culpa cupidatat aliqua labore quis ipsum nisi reprehenderit aliquip. Amet pariatur aute nisi adipisicing nulla cupidatat magna sint. In dolore culpa officia laborum ad.\r\n',
          time: '2016-07-30T11:27:27-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Ad ipsum culpa nostrud magna aliqua. Cillum sunt ea nostrud elit. Veniam non adipisicing consectetur sunt occaecat exercitation quis do tempor qui reprehenderit labore pariatur cupidatat. Eiusmod et officia ullamco ullamco nisi minim.\r\n',
          time: '2017-05-12T10:16:09-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Quis sunt culpa sunt est et adipisicing. Et esse tempor esse veniam in est qui irure enim eu pariatur. Et velit dolore commodo dolore nisi velit Lorem proident. Magna nulla excepteur officia elit ea id sit consequat anim consequat aliqua tempor irure eu.\r\n',
          time: '2015-03-31T10:31:54-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Culpa consequat anim id elit officia consequat nostrud magna eu Lorem nulla duis dolore. Nostrud ullamco ipsum cillum sit cillum. Commodo ipsum nisi Lorem ea aute cillum minim est. Esse ipsum dolore deserunt laboris amet laboris elit reprehenderit quis.\r\n',
          time: '2018-10-13T12:33:46-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Incididunt consectetur ut sit veniam. Labore aliqua cupidatat ipsum proident eiusmod elit. Quis laborum aute Lorem nostrud non aliquip id commodo laborum ea. Eiusmod aliquip aliqua ad consectetur consequat occaecat pariatur nulla tempor adipisicing. Ad amet do labore adipisicing commodo aute exercitation culpa elit aliqua excepteur est excepteur esse. Sit fugiat esse eu nostrud proident elit enim aliquip ad veniam id. Commodo officia fugiat proident do nostrud sunt Lorem mollit cillum tempor dolore.\r\n',
          time: '2019-01-02T11:11:17-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Commodo tempor incididunt ad ullamco esse esse. Exercitation proident adipisicing culpa commodo aliqua ipsum officia minim ipsum. Qui id laboris nisi consequat dolore minim anim in aliqua. Incididunt amet mollit ut ad occaecat cillum cillum elit consectetur do veniam. Mollit eu voluptate laborum quis pariatur exercitation deserunt nostrud sunt fugiat amet.\r\n',
          time: '2014-09-25T09:17:05-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Reprehenderit minim pariatur incididunt deserunt exercitation esse mollit. Tempor minim excepteur deserunt consectetur ut duis laborum. Velit laboris consectetur et fugiat Lorem culpa nisi incididunt qui.\r\n',
          time: '2019-02-02T11:19:51-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Sunt reprehenderit mollit elit commodo fugiat officia quis cupidatat ut duis dolore eiusmod et. Est consequat mollit ipsum velit ex ad officia fugiat anim adipisicing est consectetur qui. Ipsum reprehenderit eiusmod Lorem magna Lorem sit eiusmod ut aliqua pariatur labore culpa est irure. Ad officia commodo sint mollit dolor esse dolore ullamco. Eu culpa dolor cupidatat adipisicing.\r\n',
          time: '2014-06-06T06:48:54-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Reprehenderit occaecat incididunt culpa id pariatur consectetur est sit eiusmod do nostrud. Lorem nisi ullamco ex magna consequat cillum nulla laboris. Amet quis ad ex irure. Ad aliquip consequat do dolor amet Lorem officia dolore cupidatat. Et non nisi dolor qui nulla.\r\n',
          time: '2014-02-21T09:23:36-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Elit in nostrud incididunt eu. Elit commodo consequat ea ipsum adipisicing anim et anim quis laborum ipsum ex. Veniam Lorem cillum Lorem incididunt aliqua duis. Nisi sint est et elit minim quis fugiat officia. Voluptate minim qui velit nostrud laboris est labore magna fugiat reprehenderit sit tempor commodo quis.\r\n',
          time: '2019-07-12T01:33:54-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Nulla reprehenderit ipsum voluptate ad veniam occaecat nulla do ad consequat anim in irure. Fugiat laboris dolor velit velit do adipisicing exercitation exercitation id reprehenderit amet sit culpa aliqua. Laborum labore veniam adipisicing sunt cillum. Duis minim fugiat cupidatat quis fugiat incididunt et deserunt id qui do. Nostrud sunt aliquip laboris enim irure nisi nostrud ullamco dolore dolore tempor Lorem excepteur mollit.\r\n',
          time: '2017-02-05T04:23:15-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Excepteur aliquip aliquip incididunt reprehenderit. Minim aute elit enim laboris est in exercitation sunt. Velit deserunt ea esse sint velit aute eiusmod proident consequat non occaecat consectetur anim exercitation. Lorem ea enim esse pariatur proident ex ut et. Adipisicing velit sunt id adipisicing et Lorem fugiat quis exercitation labore exercitation ullamco voluptate voluptate. Nisi elit excepteur veniam et.\r\n',
          time: '2015-06-19T09:01:44-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Ad enim officia deserunt proident irure commodo sunt ea pariatur id veniam cillum. Cupidatat tempor enim laborum amet est non nisi ex cupidatat cillum id sunt qui. Anim in anim reprehenderit incididunt incididunt incididunt cupidatat amet officia adipisicing eu duis exercitation. Cupidatat culpa adipisicing mollit cupidatat aliqua incididunt dolor sint et eiusmod incididunt tempor. Exercitation laborum consectetur in nostrud consectetur eiusmod excepteur sit id ex voluptate tempor.\r\n',
          time: '2016-11-14T10:20:29-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Voluptate ipsum qui eiusmod dolore mollit pariatur commodo. Reprehenderit nulla consequat aliquip ipsum qui nulla ex. Nulla ut excepteur Lorem aliquip in do aliquip esse sint minim irure.\r\n',
          time: '2014-10-27T10:44:40-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Amet consequat deserunt veniam eu voluptate esse ex sint consectetur tempor minim. Ex culpa labore sunt Lorem est nostrud proident. Elit do minim ut velit nulla velit aute deserunt consectetur nostrud reprehenderit est aliquip quis. Qui sint culpa eiusmod ut ipsum minim occaecat.\r\n',
          time: '2018-12-13T05:44:54-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'In occaecat sint elit consectetur minim. Nulla incididunt esse tempor nostrud cillum enim. Reprehenderit magna in ut officia aute et veniam aliquip. Aliqua occaecat id sunt laboris eu proident quis voluptate. Duis mollit et nostrud adipisicing magna pariatur. Officia est laboris exercitation exercitation laboris enim commodo in cillum nostrud sunt tempor.\r\n',
          time: '2020-04-03T10:06:55-07:00',
        },
        {
          id: 2,
          type: 'message',
          message:
            'Veniam veniam non consectetur dolor sint velit veniam. Duis cupidatat adipisicing non aliqua. Occaecat culpa magna sunt aliqua. Consectetur quis proident culpa incididunt ea sit occaecat cillum magna dolor pariatur excepteur qui. Aliqua quis ad exercitation do id Lorem aute exercitation pariatur. Cillum esse non sunt ad cupidatat elit est dolore pariatur amet aliqua do.\r\n',
          time: '2019-08-11T06:40:53-07:00',
        },
        {
          id: 1,
          type: 'message',
          message:
            'Eiusmod in quis eu sint proident dolor aliqua minim enim ipsum pariatur qui non. Ex veniam proident Lorem cillum minim exercitation consequat magna. Tempor adipisicing sit nulla mollit officia excepteur quis ex esse non nulla aliqua officia duis. Minim sunt dolor do minim eiusmod officia pariatur et cupidatat veniam commodo et. Anim labore ea aute esse laborum in exercitation consequat dolor magna. Esse quis nostrud velit duis exercitation. Non veniam ad dolore aute ex eu anim nulla dolor in non elit cupidatat laboris.\r\n',
          time: '2016-02-22T04:38:50-07:00',
        },
      ],
      participants: [
        {
          id: 1,
          title: 'Tartaglia',
          avatar:
            'https://i.pinimg.com/originals/34/18/e8/3418e845a493a6bb93387845d738a5a5.jpg',
        },
        {
          id: 2,
          title: 'Yanfei',
          avatar: 'https://pbs.twimg.com/media/Ewor94UWgAAtr2m.png',
        },
      ],
    },
  },
};

export default fakeData;
