import React from 'react';
import { useSelector } from 'react-redux';

import { RibbonBar, RibbonDivider } from 'common/components';
import { ToggleHelpUploadMediaSection } from './components';
import EditView from './components/controls/maintenance/communication/fullview/EditView';
import DeleteTemplate from './components/controls/maintenance/communication/fullview/DeleteCommunicationTemplate';
import BackToTemplate from './components/controls/maintenance/communication/fullview/BackToTemplate';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import * as communicationTemplateSelectors from 'pages/communication-template/controllers/selectors';

const CommunicationTemplateFullView = () => {
  const disableMediaUpload = useSelector(
    communicationTemplateSelectors.selectDisableUploadMedia()
  );

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can
        I={ABILITY_ACTION.MAINTAIN}
        a={ABILITY_SUBJECT.COMMUNICATION_TEMPLATE}
      >
        <EditView />
        <RibbonDivider />
      </Can>
      <Can
        I={ABILITY_ACTION.MAINTAIN}
        a={ABILITY_SUBJECT.COMMUNICATION_TEMPLATE}
      >
        <DeleteTemplate />
        <RibbonDivider />
      </Can>
      <ToggleHelpUploadMediaSection disabled={disableMediaUpload} />
      <RibbonDivider />
      <AdvanceStack isDisabled />
      <BackToTemplate ribbonSide='right' />
    </RibbonBar>
  );
};

export default CommunicationTemplateFullView;
