import React, { useState, useCallback, useEffect } from 'react';
import reactCSS from 'reactcss';

import { Typography, Button, Col, Row, Checkbox } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FormCancelButton, FormSaveButton } from 'common/components';
import { EditTabSectionWrapper } from 'pages/qa-spec/components/edit-tab-checker';
import { useCheckAllowEdit } from 'pages/qa-spec/hooks';

import classNames from 'classnames';

import './WithQaGridHeader.less';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

const { Title } = Typography;

const styles = reactCSS({
  default: {
    buttonCol: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
});

const WithQaGridHeader = (props) => {
  const {
    title,
    children,
    show = true,
    buttons,
    extraButtons,
    allowEdit = true,
    handleCancel,
    showSaveBtn = true,
    showCancelBtn = true,
    showEditBtn = true,
    forceEdit,
    handleSave,
    showHeader = true,
    isShowAnalysisNotExecuted = false,
    analysisNotExecuted,
    type,
    productId,
    onEditChange,
  } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [isAnalysis, setIsAnalysis] = useState(analysisNotExecuted);

  const toggleEditMode = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);

  const onSave = () => {
    handleSave && handleSave(toggleEditMode, isAnalysis);
  };

  useEffect(() => {
    isShowAnalysisNotExecuted && setIsAnalysis(analysisNotExecuted);
  }, [analysisNotExecuted, isShowAnalysisNotExecuted]);

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const onCancel = () => {
    isShowAnalysisNotExecuted && setIsAnalysis(analysisNotExecuted);
    toggleEditMode();
    handleCancel && handleCancel();
  };

  const hasPermissionEdit = useCheckAllowEdit(productId);

  const handleChange = (e) => {
    setIsAnalysis(e.target.checked);
  };

  useEffect(() => {
    setIsEdit(forceEdit);
  }, [forceEdit]);

  useEffect(() => {
    onEditChange && onEditChange(isEdit);
  }, [isEdit]);

  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='qa-agGrid-edit-header'
      >
        {showHeader && (
          <Row
            style={{ minHeight: 27, marginBottom: 7, flexFlow: 'row' }}
            gutter={[5, 5]}
          >
            <Col
              span={isShowAnalysisNotExecuted && 6}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {title && (
                <Title level={5} strong style={{ marginBottom: 0 }}>
                  {title}
                </Title>
              )}
            </Col>
            {isShowAnalysisNotExecuted && (
              <Col>
                <Checkbox
                  className={classNames({
                    'qa-agGrid-edit-header__checkbox': !isEdit,
                  })}
                  onChange={handleChange}
                  checked={isAnalysis}
                >
                  <Title level={5} strong style={{ marginBottom: 0 }}>
                    Analysis Not Executed
                  </Title>
                </Checkbox>
              </Col>
            )}

            <Col flex='auto' />
            {!isQASpecLocked ? (
              <>
                <Col style={styles.buttonCol}>
                  {showSaveBtn && isEdit && (
                    <EditTabSectionWrapper.SaveActionTriggerWrapper
                      type={type}
                      onSave={onSave}
                    >
                      <FormSaveButton htmlType='submit' onClick={onSave} />
                    </EditTabSectionWrapper.SaveActionTriggerWrapper>
                  )}
                </Col>
                <Col style={styles.buttonCol}>
                  {showEditBtn &&
                    show &&
                    !isEdit &&
                    allowEdit &&
                    hasPermissionEdit && (
                      <Button
                        className='qa-agGrid-edit-header__edit-btn'
                        icon={<EditOutlined />}
                        style={{ border: 'none' }}
                        onClick={toggleEditMode}
                      />
                    )}
                  {showCancelBtn && isEdit && (
                    <EditTabSectionWrapper.CancelActionTriggerWrapper
                      type={type}
                      onCancel={onCancel}
                    >
                      <FormCancelButton onClick={onCancel} />
                    </EditTabSectionWrapper.CancelActionTriggerWrapper>
                  )}
                </Col>
                {extraButtons?.map((buttonItem, idx) => (
                  <Col style={styles.buttonCol} key={idx}>
                    {buttonItem(isEdit)}
                  </Col>
                ))}

                {isEdit && (
                  <>
                    {buttons?.map((buttonItem, idx) =>
                      buttonItem ? (
                        <Col style={styles.buttonCol} key={idx}>
                          {buttonItem(isEdit)}
                        </Col>
                      ) : null
                    )}
                  </>
                )}
              </>
            ) : null}
          </Row>
        )}
        <Row style={{ flex: 1 }}>
          <Col xs={24}>
            {typeof children === 'function' ? children(isEdit) : children}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default WithQaGridHeader;
