import { useRef, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Col, Row } from 'antd';

import { CustomNotification, Form, StyledModal } from 'common/components';

import {
  AllergenGroupForm,
  AllergenGroupWarning,
  defaultModalProps,
} from './AllergenGroup';

import QaAllergenGrid from './QaAllergenGrid';
import QaAllergenRegional from './QaAllergenRegional';

import CustomAllergenAddition from './CustomAllergenAddition';
import FormAllergen from './FormAllergen';

import { useGetProductEnums } from 'hooks';
import { useGetEnumOptions } from 'pages/qa-spec/hooks';
import { useGetAllergenGridHandlers } from 'pages/qa-spec/hooks/useAllergen';

import { US_VALUE, US_VALUE_LOWERCASE, WORLDWIDE_LOWERCASE } from './constants';
import { useSaveAllergenGroup } from './hooks';

import { getAllRows } from 'pages/qa-spec/utils';
import { validateAllergenGrid } from 'pages/qa-spec/utils/allergen';
import { sleep } from 'utils/delay';
import { addIdToAllergens } from './utils';

import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

export const AllergenGroupEdit = (props) => {
  const {
    onCancel: offModal,
    productId,
    allergenStatement,
    selectedIdx,
    allergensModel,
    ...rest
  } = props;

  const {
    allergenSpecificationAgency,
    allergenSpecificationName,
    allergenUnitedStates,
    allergenWorldWide,
  } = allergensModel[selectedIdx] ?? {};

  const [formInstance] = Form.useForm();
  const [customAllergenForm] = Form.useForm();

  const [regional, setRegional] = useState(US_VALUE);

  const [tempAllergensGrid, setTempAllergensGrid] = useState({
    us: addIdToAllergens(allergenUnitedStates),
    ww: addIdToAllergens(allergenWorldWide),
  });

  const gridInstance = useRef();
  const allergenOptions = useGetEnumOptions()?.allergen;
  const { productEnums } = useGetProductEnums();

  const allergenMutation = useSaveAllergenGroup(productId);
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const { onCellEditingStopped, onCellEditingStarted, onRowClicked } =
    useGetAllergenGridHandlers(gridInstance);

  const isUs = regional.toLowerCase() === US_VALUE_LOWERCASE;
  const isWW = regional.toLowerCase() === WORLDWIDE_LOWERCASE;

  const defaultAllergensGrid = isUs
    ? tempAllergensGrid['us']
    : tempAllergensGrid['ww'];

  const handleChangeRegional = (regional) => {
    gridInstance.current.api.stopEditing();

    const allergenRows = getAllRows(gridInstance);

    const isGridHaveError =
      validateAllergenGrid(gridInstance).checkAllergensGridHaveError();

    if (isGridHaveError) {
      CustomNotification.error(`Please resolve allergen grid's errors`);
    } else {
      if (regional.toLowerCase() === WORLDWIDE_LOWERCASE) {
        setTempAllergensGrid((prevValue) => ({
          ...prevValue,
          us: allergenRows,
        }));
        setRegional(regional);
      } else {
        setTempAllergensGrid((prevValue) => ({
          ...prevValue,
          ww: allergenRows,
        }));
        setRegional(regional);
      }
    }
  };

  const handleEditAllergen = async (ixoneIgnore) => {
    const {
      allergenStatement,
      allergenSpecificationAgency,
      allergenSpecificationName,
    } = formInstance.getFieldsValue();

    gridInstance.current.api.stopEditing();

    // delay a bit for accurately getting grid to validation
    await sleep(100);

    const allergenRows = getAllRows(gridInstance);

    const isGridHaveError =
      validateAllergenGrid(gridInstance).checkAllergensGridHaveError();

    if (isGridHaveError) {
      CustomNotification.error(`Please resolve allergen grid's errors`);
      return;
    }

    const editedAllergen = {
      allergenSpecificationAgency: allergenSpecificationAgency ?? null,
      allergenSpecificationName: allergenSpecificationName ?? null,
      allergenWorldWide: isWW ? allergenRows : tempAllergensGrid['ww'],
      allergenUnitedStates: isUs ? allergenRows : tempAllergensGrid['us'],
      index: selectedIdx,
    };

    let newAllergensModel = [...allergensModel];
    newAllergensModel[selectedIdx] = editedAllergen;

    const params = {
      productId,
      allergenStatement,
      allergensModel: newAllergensModel,
    };

    allergenMutation.mutate(params, {
      onSuccess: async (response) => {
        if (response?.isSuccess) {
          CustomNotification.success('Update Allergen data successfully!');
          await handleUpdateIXONEShield(ixoneIgnore);
          offModal();
        }
      },
    });
  };

  const handleUpdateDraftAllergen = async () => {
    const newId = uuidv4();

    gridInstance.current.api.stopEditing();

    await sleep();

    const allRowData = getAllRows(gridInstance);
    const newRowIndex = allRowData?.length;
    const {
      AllergenTypeCode: allergenType,
      ContainmentSource: containmentSource,
      LevelOfContainmentCode: levelOfContainment,
    } = customAllergenForm.getFieldsValue();

    gridInstance.current.api.applyTransaction({
      add: [
        {
          uuid: newId,
          error: [],
          isPresent: true,
          isPublish: false,
          regional,
          allergenType,
          containmentSource,
          levelOfContainment,
        },
      ],
      addIndex: newRowIndex,
    });

    gridInstance.current.api.ensureIndexVisible(newRowIndex);
    customAllergenForm.resetFields();
  };

  const checkChangedField = async () => {
    const { allergenSpecificationAgency, allergenSpecificationName } =
      formInstance.getFieldsValue();

    gridInstance.current.api.stopEditing();

    // delay a bit for accurately getting grid to validation
    await sleep(100);

    const allergenRows = getAllRows(gridInstance);

    const isGridHaveError =
      validateAllergenGrid(gridInstance).checkAllergensGridHaveError();

    if (isGridHaveError) {
      CustomNotification.error(`Please resolve allergen grid's errors`);
      return;
    }

    const editedAllergen = {
      allergenSpecificationAgency: allergenSpecificationAgency ?? null,
      allergenSpecificationName: allergenSpecificationName ?? null,
      allergenWorldWide: isWW ? allergenRows : tempAllergensGrid['ww'],
      allergenUnitedStates: isUs ? allergenRows : tempAllergensGrid['us'],
      index: selectedIdx,
    };

    const removedAllergenId = {
      ...editedAllergen,
      allergenWorldWide: editedAllergen?.allergenWorldWide?.map((item) => {
        const { uuid, ...rest } = item;
        return { ...rest };
      }),
      allergenUnitedStates: editedAllergen?.allergenUnitedStates?.map(
        (item) => {
          const { uuid, ...rest } = item;
          return { ...rest };
        }
      ),
    };
    const { groupIds, ...restAllergensModel } = allergensModel[selectedIdx];

    return (
      JSON.stringify(removedAllergenId) !== JSON.stringify(restAllergensModel)
    );
  };

  return (
    <IXONECertificationWrapper
      showModal
      type={TYPE_VIEW.ALLERGENS}
      onConfirm={(ixoneIgnore) => {
        handleEditAllergen(ixoneIgnore);
      }}
    >
      {(handleConfirmIXONE) => (
        <StyledModal
          {...defaultModalProps}
          {...rest}
          onCancel={offModal}
          onOk={async () => {
            const hasChangedField = await checkChangedField();
            handleConfirmIXONE({
              originalData: {},
              changedFields: hasChangedField,
            });
          }}
          okButtonProps={{
            loading: allergenMutation.isLoading,
          }}
        >
          <AllergenGroupForm
            form={formInstance}
            initialValues={{
              allergenStatement,
              allergenSpecificationAgency,
              allergenSpecificationName,
            }}
            isDisabledStatement={false}
          >
            <AllergenGroupWarning />
            <QaAllergenRegional
              value={regional}
              onChange={(value) => handleChangeRegional(value)}
            >
              <Row style={{ marginTop: 10, height: 300 }}>
                <Col xs={20}>
                  <QaAllergenGrid
                    gridInst={gridInstance}
                    data={defaultAllergensGrid}
                    isEdit={true}
                    allergenOptions={allergenOptions}
                    onCellEditingStopped={onCellEditingStopped}
                    onCellEditingStarted={onCellEditingStarted}
                    onRowClicked={onRowClicked}
                  />
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginLeft: 6,
                  }}
                >
                  <Form form={customAllergenForm}>
                    <CustomAllergenAddition
                      gridInstance={gridInstance}
                      regional={regional}
                      productId={productId}
                      onAddCustomAllergen={handleUpdateDraftAllergen}
                    >
                      <FormAllergen
                        productEnums={productEnums}
                        isUSRegion={isUs}
                        isWWRegion={isWW}
                      />
                    </CustomAllergenAddition>
                  </Form>
                </Col>
              </Row>
            </QaAllergenRegional>
          </AllergenGroupForm>
        </StyledModal>
      )}
    </IXONECertificationWrapper>
  );
};
