import React from 'react';
import { useSelector } from 'react-redux';

import { forwardTo } from 'utils/common/route';

import { VideoCameraAddOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import Messages from 'i18n/messages/home';

import CreateAsset from '../../controls/asset/CreateAsset';
import ReplaceAsset from '../../controls/asset/ReplaceAsset';
import DeleteAssets from '../../controls/asset/DeleteAssets';
import EditMultipleAsset from '../../controls/asset/EditMultipleAsset';
import UpdateMultipleAsset from '../../controls/asset/UpdateMultipleAsset';
import MoveAssetsToMember from '../../controls/asset/MoveAssetsToMember';

import { LinkSection, DownloadSection } from '../../sections/asset';
import {
  RibbonButton,
  DownloadFormItem,
  MinimumRequirementsValidate,
} from 'common/components';
import HistoryEntity from 'pages/home/ribbon/form-manange/components/form-history-grid/HistoryEntity';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import selectorUser from 'redux/user/selectors';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import {
  useCheckPermissions,
  usePermissionDeleteAsset,
  usePermissionDeleteSharedAsset,
  usePermissionEditAsset,
  usePermissionEditSharedAsset,
} from 'hooks';
import { checkAllowEdit } from 'utils';

import './AssetActionsSection.less';
import { ROUTE } from 'static/Constants';

const PERMISSION_CREATE_ASSET = [
  {
    action: ABILITY_ACTION.CREATE,
    subject: ABILITY_SUBJECT.ASSET,
  },
];

const AssetActionSection = ({
  isDisabled,
  isCreating,
  isEditting,
  isIframe,
  viewType,
  assetDetailId,
}) => {
  const hasPermissionCreateAsset =
    useCheckPermissions(PERMISSION_CREATE_ASSET) &&
    viewType !== ROUTE.MEMBER_ASSETS;
  const hasPermissionEditAsset = usePermissionEditAsset();
  const hasPermissionDeleteAsset = usePermissionDeleteAsset();
  const hasPermissionDeleteSharedAsset = usePermissionDeleteSharedAsset();
  const hasPermissionEditSharedAsset = usePermissionEditSharedAsset();

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const companyId = userInfo?.companyId;

  const detailCurrentItemsSelection =
    useSelector(selectorsGridView.makeSelectDetailCurrentITemsSelection()) ??
    [];
  const ownerIds =
    detailCurrentItemsSelection?.map((item) => item?.ownerId) ?? [];

  const isOwners =
    detailCurrentItemsSelection?.map((item) => item?.isOwner) ?? [];

  const selectedIds = detailCurrentItemsSelection?.map((item) => item?.id);

  const isOwner =
    ownerIds.every((id) => id === companyId) ||
    isOwners.every((item) => !!item);

  let isAllowLinkAsset,
    isAllowReplaceAsset,
    isAllowUpdateAssets,
    isEditMultiAssets,
    isAllowMoveAssets;

  isAllowLinkAsset =
    isEditMultiAssets =
    isAllowReplaceAsset =
    isAllowUpdateAssets =
    isAllowMoveAssets =
      checkAllowEdit(
        isOwner && hasPermissionEditAsset,
        hasPermissionEditSharedAsset
      );

  const isAllowDeleteAsset =
    hasPermissionDeleteAsset || hasPermissionDeleteSharedAsset;

  const isDisableDownloadSplashForm = selectedIds?.length === 0;

  const menuOptions = (
    <Menu>
      <Menu.Item key='createAsset' style={{ backgroundColor: 'inherit' }}>
        <CreateAsset
          createAsset={() => forwardTo('/asset/create')}
          isCreating={isCreating}
          isEditting={isEditting}
          disabled={!hasPermissionCreateAsset}
        />
      </Menu.Item>

      <Menu.Item key='linkAsset' style={{ backgroundColor: 'inherit' }}>
        <LinkSection disabled={!isAllowLinkAsset} />
      </Menu.Item>

      <Menu.Item key='replaceAsset' style={{ backgroundColor: 'inherit' }}>
        <ReplaceAsset isEditting={isEditting} disabled={!isAllowReplaceAsset} />
      </Menu.Item>

      <Menu.Item key='deleteAsset' style={{ backgroundColor: 'inherit' }}>
        <DeleteAssets isEditting={isEditting} disabled={!isAllowDeleteAsset} />
      </Menu.Item>

      {!isIframe ? (
        <Menu.Item key='downloadAsset' style={{ backgroundColor: 'inherit' }}>
          <DownloadSection viewType={viewType} />
        </Menu.Item>
      ) : null}

      <Menu.Item
        key='downloadAssetsTemplate'
        style={{ backgroundColor: 'inherit' }}
      >
        <EditMultipleAsset
          isEditting={isEditting}
          disabled={!isEditMultiAssets}
        />
      </Menu.Item>

      <Menu.Item
        key='uploadAssetsTemplate'
        style={{ backgroundColor: 'inherit' }}
      >
        <UpdateMultipleAsset
          isEditting={isEditting}
          disabled={!isAllowUpdateAssets}
        />
      </Menu.Item>

      <Menu.Item key='moveAssets' style={{ backgroundColor: 'inherit' }}>
        <MoveAssetsToMember disabled={!isAllowMoveAssets} viewType={viewType} />
      </Menu.Item>

      <Menu.Item
        key='download-splash-form'
        style={{ backgroundColor: 'inherit' }}
      >
        <DownloadFormItem
          selectedEntityIds={
            viewType === 'assetFullView' ? [assetDetailId] : selectedIds
          }
          disabled={isDisableDownloadSplashForm}
          entityType='DAM'
        />
      </Menu.Item>
      <Menu.Item key='history-entity' style={{ backgroundColor: 'inherit' }}>
        <HistoryEntity
          size='small'
          entityId={
            viewType === 'assetFullView'
              ? assetDetailId
              : selectedIds?.length > 0 && selectedIds[0]
          }
          entityType='DAM'
          disabled={
            viewType === 'assetFullView' ? false : selectedIds?.length !== 1
          }
        />
      </Menu.Item>
      {!assetDetailId ? (
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.ASSET}>
          <Menu.Item
            key='validate-minimum-requirements'
            style={{ backgroundColor: 'inherit' }}
          >
            <MinimumRequirementsValidate
              entity={
                detailCurrentItemsSelection.length === 1 && {
                  id: detailCurrentItemsSelection[0]?.id,
                  name: detailCurrentItemsSelection[0]?.assetName,
                  type: 'Asset',
                }
              }
            />
          </Menu.Item>
        </Can>
      ) : null}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menuOptions}
      placement='bottomLeft'
      arrow
      disabled={isDisabled}
    >
      <RibbonButton
        icon={<VideoCameraAddOutlined />}
        disabled={isDisabled}
        label={Messages.mediaActions}
      />
    </Dropdown>
  );
};

export default AssetActionSection;
