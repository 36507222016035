import React, { useState } from 'react';

import { List, Popover, Spin, Card, Typography } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import Action from './Action';
import WorkflowAlertItem from './WorkflowAlertItem';
import CreditCardAlert from './CreditCardAlert';

import { useRivirAlert } from 'hooks/useRivirAlert';

import './RivirAlert.less';

const { Text } = Typography;

const RivirAlert = (props) => {
  const { offset, iconStyle } = props;

  const [visible, setVisible] = useState(false);

  const { alertList, viewAlert, loading } = useRivirAlert();

  const rivirAlertContent = () => {
    return (
      <Spin spinning={loading}>
        <Card
          title={
            <Text strong style={{ fontSize: 19 }}>
              RIVIR Alert
            </Text>
          }
          size='small'
          bordered={false}
        >
          <List
            className='rivir-alert__workflow-list'
            itemLayout='horizontal'
            dataSource={alertList}
            renderItem={(item) =>
              item?.type === 'creditCard' ? (
                <CreditCardAlert />
              ) : (
                <WorkflowAlertItem
                  item={item}
                  viewAlert={viewAlert}
                  setVisible={setVisible}
                />
              )
            }
          />
        </Card>
      </Spin>
    );
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  return (
    <div className='rivir-alert'>
      <Popover
        title={false}
        content={rivirAlertContent}
        overlayClassName={classnames('rivir-alert__popover width-400')}
        placement='bottomRight'
        trigger='click'
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Action
          onClick={() => setVisible(true)}
          count={alertList?.length}
          offset={offset}
          icon={NotificationOutlined}
          iconStyle={iconStyle}
        />
      </Popover>
    </div>
  );
};

export default RivirAlert;
