import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'antd';
import classnames from 'classnames';

import {
  BackgroundEdit,
  WithLoading,
  CustomNotification,
} from 'common/components';

import HeaderEditActions from './header-action/HeaderEditActions';
import ProductHeaderSocial from './header-component/ProductHeaderSocial';
import ProductHeaderContactInfo from './header-component/ProductHeaderContactInfo';
import ProductHeaderProfileImage from './header-component/ProductHeaderProfileImage';
import ProductEditBrandModal from '../product-edit-brand/ProductEditBrandModal';

//* redux
import * as productActions from 'pages/product-full-view/controllers/actions';
import * as productSelectors from 'pages/product-full-view/controllers/selectors';

//* util
import { parseJSON } from 'utils';
import { mapToProductContact } from '../../mappers/mapToProductContact';

import { useCheckAllowEditProducts } from 'hooks';
import { useProductFullView } from 'hooks/useProductFullView';

import * as productServices from 'services/product';
import * as productEndpoints from 'services/product/endpoints';

import { injectIntl } from 'react-intl';

//* QUERIES
import { useGetProductHeaderBackground } from 'pages/product-full-view/queries';
import { useQueryProductCompanyInfo } from 'common/queries/product-module';

import './ProductHeader.less';

const ProductHeader = React.memo((props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const refProductBackground = useRef(null);
  const bgInstance = useRef();

  const { productDetail } = props;

  const [headerSize, setHeaderSize] = useState(null);

  const hidingData = {
    logo: false,
    address: false,
  };

  const [heightSize, setHeightSize] = useState(undefined);
  const [initHeightSize, setInitHeightSize] = useState(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const [shouldHideLogo, setHideLogo] = useState(hidingData.logo);
  const [shouldHideAddress, setHideAddress] = useState(hidingData.address);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSaveToggleShow, setIsLoadingSaveToggleShow] = useState(false);
  const [isDisplaySocialIconInline, setIsDisplaySocialIconInline] =
    useState(false);
  const [visibleEditBrand, setVisibleEditBrand] = useState(false);

  const { data: companyInfoState } = useQueryProductCompanyInfo({
    productId: +id,
  });

  const productSocialState = useSelector(
    productSelectors.makeSelectProductSocialMedia()
  );

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const { reloadProductFullView } = useProductFullView({
    productId: productDetail?.productId,
  });

  const isAllowEditProduct = checkAllowEditProductFull(productDetail);
  const loading = isLoading || isLoadingSaveToggleShow;
  const address = companyInfoState?.primaryAddress;
  const contact = mapToProductContact(companyInfoState);
  const socialData = productSocialState?.data;
  const brandImgSrc = companyInfoState?.brandImage?.fileUrl;

  const imageWidth = 150;
  const contactInfoWidth = 250;
  const numberSocialIcon =
    (socialData?.hasGS1SocialMedia ? 1 : 0) +
    (socialData?.hasIxOneSocialMedia ? 1 : 0) +
    (socialData?.productSocialMedias?.length
      ? socialData?.productSocialMedias?.length
      : 0);
  const socialIconWidth = numberSocialIcon * 40 + 12;

  const isTdcProduct = productDetail?.fromTdcProduct;

  const ixOneCertifiedStatus =
    productDetail?.ixOneCertifiedStatus?.toLowerCase();

  const showIxOneShield = isTdcProduct && ixOneCertifiedStatus !== 'removed';

  const onChangeShowLogo = () => {
    setHideLogo((prevState) => !prevState);
  };

  const onChangeShowAddress = () => {
    setHideAddress((prevState) => !prevState);
  };

  const onEditBrandImage = () => {
    if (isEdit) {
      setVisibleEditBrand(true);
    }
  };

  const handleSave = () => {
    bgInstance?.current?.handleSave && bgInstance.current?.handleSave();
    handleSaveToggleShowAvatarAndAddress();
  };

  const handleCancel = () => {
    bgInstance.current?.handleCancel && bgInstance.current.handleCancel();
    setIsEdit(false);
    setHideLogo(hidingData.logo);
    setHideAddress(hidingData.address);
    setHeightSize(initHeightSize);
  };

  const handleSaveToggleShowAvatarAndAddress = async () => {
    const updateHeaderAddressPromise =
      shouldHideAddress === hidingData.address
        ? Promise.reject()
        : productServices.toggleShowProductHeaderAddress({
            show: shouldHideAddress,
          });

    const updateHeaderProfileImagePromise =
      shouldHideLogo === hidingData.logo
        ? Promise.reject()
        : productServices.toggleShowProductHeaderProfileImage({
            show: shouldHideLogo,
          });

    setIsLoadingSaveToggleShow(true);

    const response = await Promise.allSettled([
      updateHeaderAddressPromise,
      updateHeaderProfileImagePromise,
    ]);

    setIsLoadingSaveToggleShow(false);

    const isSuccess = (_response) => _response?.value?.isSuccess;

    response.forEach((responseItem, idx) => {
      if (idx === 0) {
        if (isSuccess(responseItem) === false) {
          CustomNotification.error(`Failed to show/hide address!`);
        }
      }

      if (idx === 1) {
        if (isSuccess(responseItem) === false) {
          CustomNotification.error(`Failed to show/hide profile image!`);
        }
      }
    });
  };

  const editBrandCallback = () => {
    reloadProductFullView();
  };

  const {
    data: productHeaderBackground,
    refetch: refetchProductHeaderBackground,
  } = useGetProductHeaderBackground({ productId: id }, { enabled: !!id });

  useEffect(() => {
    if (productHeaderBackground) {
      const bgHeight = parseJSON(
        productHeaderBackground.backgroundConfiguration
      )?.heightConfig?.heightSize;
      setInitHeightSize(bgHeight || 100);
      setHeightSize(bgHeight || 100);
    }
  }, [productHeaderBackground]);

  const getBgRealSize = useCallback(() => {
    if (refProductBackground.current) {
      const width = refProductBackground.current.clientWith;
      const height = refProductBackground.current.clientHeight;
      return [width, height];
    }
  }, [refProductBackground]);

  useEffect(() => {
    if (headerSize) {
      setIsDisplaySocialIconInline(
        headerSize[0] - imageWidth - contactInfoWidth - 50 > socialIconWidth
      );
    }
  }, [headerSize, socialIconWidth, isEdit]);

  useEffect(() => {
    if (id) {
      dispatch(productActions.getProductSocialMedia(+id));
    }
  }, [id]);

  useEffect(() => {
    heightSize && setHeaderSize && setHeaderSize(getBgRealSize());
  }, [heightSize, isEdit]);

  return (
    <>
      <div ref={refProductBackground}>
        {isAllowEditProduct && (
          <Row
            className={classnames('pd-header-edit', {
              'pd-header-edit--active': isEdit,
            })}
            align='middle'
          >
            <Col flex={1}>
              {/* {isEdit && (
                <HeaderEditForm
                  intl={intl}
                  loading={loading}
                  onChangeShowLogo={onChangeShowLogo}
                  shouldHideLogo={shouldHideLogo}
                  onChangeShowAddress={onChangeShowAddress}
                  shouldHideAddress={shouldHideAddress}
                />
              )} */}
            </Col>

            <Col flex={0}>
              {isAllowEditProduct && (
                <HeaderEditActions
                  editHeader={isEdit}
                  setIsEditHeader={setIsEdit}
                  bgInstance={bgInstance}
                  loading={loading}
                  handleCancel={handleCancel}
                  handleSave={handleSave}
                />
              )}
            </Col>
          </Row>
        )}
        {visibleEditBrand && (
          <ProductEditBrandModal
            visible={visibleEditBrand}
            setVisibleEditBrand={setVisibleEditBrand}
            productDetail={productDetail}
            isEditProductHeader
            isEdit
            editCallback={editBrandCallback}
          />
        )}
        <Row className={'pd-header-background'}>
          <WithLoading loading={isLoading}>
            <div
              className={classnames({
                'pd-header-background__wrapper': !isDisplaySocialIconInline,
                'pd-header-background__wrapper-inline':
                  isDisplaySocialIconInline,
                'pd-header-background__wrapper--75': heightSize === 75,
                'pd-header-background__wrapper--50': heightSize === 50,
                'pd-header-background__wrapper-edit': isEdit,
                'pd-header-background__wrapper-edit--75':
                  isEdit && heightSize === 75,
                'pd-header-background__wrapper-edit--50':
                  isEdit && heightSize === 50,
              })}
            >
              <BackgroundEdit
                instance={bgInstance}
                id='member-background-edit'
                src={productHeaderBackground?.fileUrl || ''}
                backgroundConfig={
                  parseJSON(productHeaderBackground?.backgroundConfiguration)
                    ?.cropConfig || {}
                }
                isEdit={isEdit && isAllowEditProduct}
                apiUploadUrl={productEndpoints.SAVE_PRODUCT_HEADER_BACKGROUND}
                additionalUploadParams={{
                  ProductId: id,
                }}
                additionalPayload={{
                  productId: id,
                }}
                editBackgroundService={
                  productServices.editProductHeaderBackgroundConfig
                }
                heightSize={heightSize}
                initHeightSize={initHeightSize}
                setHeightSize={setHeightSize}
                setBgInitHeight={setInitHeightSize}
                setIsLoading={setIsLoading}
                setIsEdit={setIsEdit}
                onSaveSuccess={refetchProductHeaderBackground}
                limitAdjustImage={
                  productHeaderBackground?.headerImageType === 'MemberConfig'
                }
              />
              <AbsoluteComponent>
                <ProductHeaderProfileImage
                  shouldHideLogo={shouldHideLogo}
                  src={brandImgSrc}
                  isEdit={isEdit && isAllowEditProduct}
                  onClick={() => onEditBrandImage()}
                />
                <ProductHeaderContactInfo
                  address={address}
                  contact={contact}
                  shouldHideAddress={shouldHideAddress}
                  heightSize={heightSize}
                  isEdit={isEdit && isAllowEditProduct}
                  handleCancelEditHeader={handleCancel}
                />
                <ProductHeaderSocial
                  productId={id}
                  socialLink={socialData?.productSocialMedias || []}
                  heightSize={heightSize}
                  isEdit={isEdit && isAllowEditProduct}
                  setIsEdit={setIsEdit}
                  hasGS1SocialMedia={socialData?.hasGS1SocialMedia}
                  hasIxOneSocialMedia={socialData?.hasIxOneSocialMedia}
                  isDisplaySocialIconInline={isDisplaySocialIconInline}
                  showIxOneShield={showIxOneShield}
                />
              </AbsoluteComponent>
            </div>
          </WithLoading>
        </Row>
      </div>
    </>
  );
});

const AbsoluteComponent = ({ children }) => (
  <React.Fragment>{children}</React.Fragment>
);
export default injectIntl(ProductHeader);
