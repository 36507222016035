export const isLocalhost = () => {
  return window.location.origin.includes('localhost');
};

export const isQaSite = () => {
  return window.location.origin.includes('qa-tacotruck.gvivn.global');
};

export const isTestSite = () => {
  return window.location.origin.includes('test-portal.gorivir.com');
};
