import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Input, Select, Row, Typography } from 'antd';
import { WrapperSelect } from 'common/components';
import { checkSpecialField, specialField } from '../utils';

import { useWindowSize } from 'hooks/windowSize';

let searchTimeout;
const RenderValueIn = (props) => {
  const {
    property,
    acceptableValueSelect,
    propertyEnums,
    queryConditionsId,
    handleQueryCondition,
  } = props;

  const [valueInput, setValueInput] = useState();

  const [screenWidth, screenHeight] = useWindowSize();

  const debounceUpdateValue = (...args) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleQueryCondition(...args);
    }, 300);
  };

  const handleUpdateValue = (value) => {
    setValueInput(value);
    debounceUpdateValue(property?.valuePath, value, 'values');
  };

  useEffect(() => {
    setValueInput(property?.values);
  }, [property.values]);

  const listSelect =
    property?.acceptableValueTemp ||
    (acceptableValueSelect?.length > 0 ? acceptableValueSelect : propertyEnums);

  const specialValue = (item) => {
    const isSpecialField = checkSpecialField(property?.fieldName);

    if (typeof item === 'string') return item;

    if (isSpecialField) return item?.enumCode;

    return item?.enumDisplayName;
  };

  const sortedList = (() => {
    const [firstValue] = listSelect;

    if (typeof firstValue === 'string') return listSelect?.sort();

    if (listSelect?.length > 0)
      return _.sortBy(listSelect, ['enumDisplayName', 'enumCode']);

    return [];
  })();

  return (
    <>
      {property?.dataType === 'string' && (
        <div>
          {sortedList.length === 0 ? (
            <Input
              size='small'
              value={valueInput}
              placeholder='Enter start value'
              className='query-condition-draggable__input'
              onChange={(e) => handleUpdateValue(e.target.value)}
            />
          ) : sortedList.length > 0 && sortedList.length <= 100000 ? (
            <WrapperSelect
              size='small'
              getPopupContainer={() =>
                document.getElementById(queryConditionsId)
              }
              showSearch
              optionFilterProp='children'
              mode='multiple'
              maxTagCount='responsive'
              placeholder='Please select value'
              listHeight={screenHeight <= 768 ? 70 : 150}
              value={valueInput}
              className='query-condition-draggable__input'
              onChange={(value) => handleUpdateValue(value)}
              filterOption={(input, option) => {
                return (
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                );
              }}
            >
              {sortedList.map((item, index) => {
                const value = specialValue(item);
                const specialFields = specialField(property, item);

                return (
                  <Select.Option key={index} value={value}>
                    {specialFields || value}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          ) : (
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '4px',
              }}
            >
              <WrapperSelect
                size='small'
                getPopupContainer={() =>
                  document.getElementById(queryConditionsId)
                }
                showSearch
                optionFilterProp='children'
                mode='multiple'
                maxTagCount='responsive'
                // placeholder='Please select value'
                listHeight={screenHeight <= 768 ? 70 : 150}
                // value={valueInput}
                className='query-condition-draggable__input'
                onChange={(value) => handleUpdateValue(value)}
                filterOption={(input, option) => {
                  return (
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  );
                }}
                status='error'
                placeholder={
                  <Typography.Text type='danger' style={{ fontSize: '12px' }}>
                    {sortedList?.length} items
                  </Typography.Text>
                }
              >
                {/* {sortedList.map((item, index) => {
                  const value = specialValue(item);
                  const specialFields = specialField(property, item);

                  return (
                    <Select.Option key={index} value={value}>
                      {specialFields || value}
                    </Select.Option>
                  );
                })} */}
              </WrapperSelect>
              <Typography.Text type='danger' style={{ fontSize: '12px' }}>
                Exceed maximum 100,000 items
              </Typography.Text>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default RenderValueIn;
