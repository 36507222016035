export const SEARCH_ITEMS_LINK_ASSETS = 'SEARCH_ITEMS_LINK_ASSETS';
export const SEARCH_ITEMS_LINK_ASSETS_SUCCESS =
  'SEARCH_ITEMS_LINK_ASSETS_SUCCESS';
export const SEARCH_ITEMS_LINK_ASSETS_FAIL = 'SEARCH_ITEMS_LINK_ASSETS_FAIL';

export const FETCH_ASSETS_CHANGE_PAGINATION = 'FETCH_ASSETS_CHANGE_PAGINATION';
export const FETCH_ASSETS_CHANGE_PAGINATION_SUCCESS =
  'FETCH_ASSETS_CHANGE_PAGINATION_SUCCESS';
export const FETCH_ASSETS_CHANGE_PAGINATION_FAIL =
  'FETCH_ASSETS_CHANGE_PAGINATION_FAIL';

export const FETCH_ASSETS_GRID = 'FETCH_ASSETS_GRID';
export const FETCH_ASSETS_GRID_SUCCESS = 'FETCH_ASSETS_GRID_SUCCESS';
export const FETCH_ASSETS_GRID_ERROR = 'FETCH_ASSETS_GRID_ERROR';

export const DRAG_DROP_ASSETS = 'DRAG_DROP_ASSETS';
export const MOVE_TO_PARENT_GRID = 'MOVE_TO_PARENT_GRID';
export const MOVE_TO_CHILD_GRID = 'MOVE_TO_CHILD_GRID';
export const DELETE_ASSET_IN_PARENT = 'DELETE_ASSET_IN_PARENT';
export const DELETE_ASSET_IN_CHILD = 'DELETE_ASSET_IN_CHILD';

export const UPDATE_LINK_ASSET = 'UPDATE_LINK_ASSET';
export const UPDATE_LINK_ASSET_SUCCESS = 'UPDATE_LINK_ASSET_SUCCESS';
export const UPDATE_LINK_ASSET_FAIL = 'UPDATE_LINK_ASSET_FAIL';

export const RESET_LINK_ASSET = 'RESET_LINK_ASSET';
