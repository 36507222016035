import React from 'react';
import { Image, Typography, Tree, Button, Tag } from 'antd';

import { CloseOutlined } from '@ant-design/icons';

import folderIcon from 'assets/folder/folder.png';
import fileIcon from 'assets/file-formats/file-icon.webp';

import '../dropbox-upload/AssetDropboxUpload.less';

export const TitleFolderAsset = ({ title, style, ...rest }) => {
  return (
    <Typography.Text
      style={{
        marginLeft: 8,
        fontSize: 14,
        color: '#595959',
        ...style,
      }}
      strong
      {...rest}
    >
      {title}
    </Typography.Text>
  );
};

export const TitleFileAsset = ({ title, style, ...rest }) => {
  return (
    <Typography.Text
      style={{
        marginLeft: 8,
        color: '#595959',
        ...style,
      }}
      {...rest}
    >
      {title}
    </Typography.Text>
  );
};

export const IconAsset = ({ src, preview, ...rest }) => {
  return <Image preview={preview} width={20} height={20} src={src} {...rest} />;
};

export const IconFileAsset = (props) => {
  return (
    <IconAsset
      src={fileIcon}
      width={20}
      height={20}
      preview={false}
      {...props}
    />
  );
};

export const IconFolderAsset = (props) => {
  return <IconAsset src={folderIcon} preview={false} {...props} />;
};

const { DirectoryTree } = Tree;

export const TreeFiles = (props) => {
  return <DirectoryTree {...props} />;
};

export const SelectedFile = ({ name, itemId, type, onDeleteItem }) => {
  return (
    <Tag
      icon={
        type === 'folder' ? (
          <IconFolderAsset
            width={18}
            height={18}
            style={{
              marginTop: 2,
            }}
          />
        ) : (
          <IconFileAsset
            width={18}
            height={18}
            style={{
              marginTop: 2,
            }}
          />
        )
      }
      color='success'
      className='dropbox-asset__result-tag'
    >
      <Typography.Text
        strong
        style={{ color: '#1e8e3e', marginLeft: 4, fontSize: 14 }}
      >
        {name}
      </Typography.Text>
      <Button
        className='dropbox-asset__btn-delete'
        danger
        shape='circle'
        icon={<CloseOutlined />}
        style={{
          marginLeft: 4,
          border: 'none',
        }}
        onClick={() => onDeleteItem(itemId)}
      />
    </Tag>
  );
};
