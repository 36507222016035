import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Col,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import {
  CustomNotification,
  Form,
  SectionWrapper,
  WrapperSelect,
} from 'common/components';

import Nutrients from './components/Nutrients';
import { PET_FOOD_INITIAL_FORM } from '../../constants';
import { getPetFoodPanels } from '../../../multiple-panel/facts-panel/useQueryFactsPanel';
import { getKeyQaSpecPetFoodPanels } from 'pages/qa-spec/components/qa-nutrition-data/hook';
import { usePetFoodFactsLabel } from './hook';
import { useGetEnumsByEnumName } from 'hooks';

import './PetNutritionLabel.less';

const labelTypeOptions = [
  {
    label: 'Standard',
    value: 'US_FDA_NFP_2020_STANDARD',
  },
];

const PetNutritionLabel = forwardRef((props, forwardedRef) => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const {
    visible,
    factsPanel,
    typeView,
    onSubmitFail,
    setIsChangedForm,
    onSubmitSuccess,
  } = props;

  const queryClient = useQueryClient();

  const mutation = usePetFoodFactsLabel({ typeView });

  const submitForm = async (ixoneIgnore) => {
    const formValues = await form.validateFields();

    if (!formValues) return;

    const params = {
      productItemId: +id,
      data: {
        id: factsPanel?.id || 0,
        ...formValues,
      },
      ixOneIgnore: factsPanel?.id ? ixoneIgnore : null,
      deletedFields: [],
    };

    mutation.mutate(params, {
      onSuccess: (result) => {
        if (result.isSuccess) {
          resetForm();
          onSubmitSuccess && onSubmitSuccess();

          queryClient.invalidateQueries({
            queryKey: getPetFoodPanels(id),
          });
          queryClient.invalidateQueries({
            queryKey: getKeyQaSpecPetFoodPanels(id),
          });
          CustomNotification.success(
            'Update Pet Food Nutrition Facts successfully'
          );
        } else {
          form.resetFields();

          onSubmitFail && onSubmitFail();

          CustomNotification.error('Something went wrong!');
        }
      },
    });
  };

  const initForm = (on) => {
    const initialData = on === 'add' ? PET_FOOD_INITIAL_FORM : factsPanel;
    form.setFieldsValue({
      ...initialData,
    });
  };

  const resetForm = () => {
    form.resetFields();
  };

  useImperativeHandle(forwardedRef, () => {
    return {
      submitForm,
      initForm,
      resetForm,
    };
  });

  const petNutritionPerCupUnitEnum = useGetEnumsByEnumName(
    'PetNutritionPerCupUnitEnum'
  );

  return visible ? (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      className='pet-nutrition-form'
      onFieldsChange={() => {
        setIsChangedForm(true);
      }}
    >
      <Row wrap={false} gutter={16} style={{ height: '100%' }}>
        <Col span={8}>
          <Form.Item name='nutrientFormatType' label='Label Type'>
            <Select
              defaultValue={labelTypeOptions}
              options={labelTypeOptions}
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            />
          </Form.Item>
          <Row gutter={4}>
            <Col span={12}>
              <Form.Item
                name='measurementPerCup'
                label='Per Cup Measurement'
                rules={[
                  {
                    required: true,
                    message: 'Per Cup Measurement is required',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='measurementUomPerCup'
                label='Per Cup Measurement Unit'
                rules={[
                  {
                    required: true,
                    message: 'Per Cup Measurement Unit is required',
                  },
                ]}
              >
                <WrapperSelect
                  placeholder='Unit'
                  getPopupContainer={(triggerNode) => {
                    return triggerNode;
                  }}
                  allowClear={true}
                >
                  {[
                    petNutritionPerCupUnitEnum?.map((labelType) => {
                      return (
                        <Select.Option value={labelType?.enumDisplayName}>
                          {labelType?.enumDisplayName}
                        </Select.Option>
                      );
                    }),
                  ]}
                </WrapperSelect>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: '8px 0' }} />
          <SectionWrapper title='Calories Content'>
            <Row>
              <Col span={24}>
                <Row gutter={4}>
                  <Col span={12}>
                    <Form.Item name='calorieContent' label='Calories Content'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='caloriesPerCup' label='Calories Per Cup'>
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <p>From</p>
                <Form.Item
                  name='protein'
                  label='Protein'
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
                <Form.Item
                  name='fat'
                  label='Fat'
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
                <Form.Item
                  name='carbohydrate'
                  label='Carbohydrate'
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
            </Row>
          </SectionWrapper>
          <Divider style={{ margin: '16px 0' }} />
          <Form.Item label='Special Character' style={{ display: 'flex' }}>
            <Typography.Paragraph
              copyable={{
                text: '*',
              }}
            >
              * Not recognized as an essential nutrient by the AAFCO
            </Typography.Paragraph>
            <Typography.Paragraph copyable={{ text: '†' }}>
              † Calculated Value
            </Typography.Paragraph>
          </Form.Item>
        </Col>

        <Col span={8}>
          <SectionWrapper title='NUTRIENTS'>
            <Nutrients />
          </SectionWrapper>
        </Col>
        <Col span={8}>
          <Form.Item name='fdaLabelFootNote' label='Footer Note'>
            <Input.TextArea rows={5} maxLength={300} />
          </Form.Item>
          <Form.Item
            name='enzymesAndMicroorganisms'
            label='Enzymes And Microorganisms'
          >
            <Input.TextArea rows={5} maxLength={300} />
          </Form.Item>
          <Form.Item
            name='nutritionalAdequacyStatement'
            label='Nutritional Adequacy Statement'
          >
            <Input.TextArea rows={5} maxLength={300} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : null;
});

export default PetNutritionLabel;
