import React from 'react';

import { SnapshotHighlightText } from '../WrapperNutrient';
import NutrientRowNew from '../NutrientRowNew';
import { useGetBasicUomEnum } from 'hooks';
import SeparatorBar from 'pages/product-full-view/components/product-media-area/nutrition-label/components/SeparatorBar';
import { getUomTypesAbbreviations } from 'pages/product-full-view/components/product-media-area/nutrition-label/DailyValueConstants';
import { renderContainerDescriptor } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/utils';

import 'pages/product-full-view/components/product-media-area/multiple-panel/facts-panel/VerticalLabel.less';

function VerticalLabel({ property, nutrients, snapshotValue }) {
  const { basicUomProperties } = useGetBasicUomEnum();

  const servingPerContainerDescriptor = renderContainerDescriptor(
    property?.servingPerContainerDescriptor
  );

  return (
    <div class='nutrition-facts'>
      <h1 className='nutrition-facts__label'>Nutrition Facts</h1>
      <SeparatorBar />
      <div className='nutrition-facts__servingsize'>
        {property?.numberOfServingsPerPackage != null ? (
          <SnapshotHighlightText
            label={[
              'numberOfServingsPerPackage',
              'servingPerContainerDescriptor',
            ]}
            currentValue={property}
            snapshotValue={snapshotValue}
          >
            <div className='nutrition-facts__servings-per-container'>
              <p style={{ textTransform: 'capitalize', marginBottom: 0 }}>
                {servingPerContainerDescriptor ? (
                  <span>{servingPerContainerDescriptor}&nbsp;</span>
                ) : null}
                {property?.numberOfServingsPerPackage} servings per container
              </p>
            </div>
          </SnapshotHighlightText>
        ) : null}
        <SnapshotHighlightText
          label={[
            'servingSizeDescription',
            'servingSize',
            'servingSizeMeasurement',
          ]}
          currentValue={property}
          snapshotValue={snapshotValue}
        >
          <div className='nutrition-facts__servingsize-bold'>
            <div>Serving size</div>
            <div>
              {property?.servingSizeDescription}{' '}
              {property?.servingSize &&
                `(${property?.servingSize} ${getUomTypesAbbreviations(
                  basicUomProperties,
                  property?.servingSizeMeasurement
                )})`}
            </div>
          </div>
        </SnapshotHighlightText>
      </div>
      <SeparatorBar height={7} />
      <SnapshotHighlightText
        label={['calories']}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        <div className='nutrition-facts__calories-container'>
          <div>
            <div className='nutrition-facts__calories'>Amount Per Serving</div>
            <div className='nutrition-facts__calories_new-calories'>
              Calories
            </div>
          </div>

          <div className='nutrition-facts__calories_numeric'>
            {property?.calories}
          </div>
        </div>
      </SnapshotHighlightText>
      <SeparatorBar height={3} />
      <div className='nutrition-facts__dv-heading'>% Daily Value*</div>
      {nutrients?.map((nutrient) => {
        return <NutrientRowNew nutrient={nutrient} />;
      })}

      {property?.ingredientNotice && (
        <>
          <SeparatorBar height={3} />
          <div className='nutrition-facts__ingredient-notice'>
            <SnapshotHighlightText
              label={['ingredientNotice']}
              currentValue={property}
              snapshotValue={snapshotValue}
            >
              {property?.ingredientNotice}
            </SnapshotHighlightText>
          </div>
        </>
      )}
      <SeparatorBar height={3} />
      <div className='nutrition-facts__footnote'>
        {!!property?.fdaLabelFootNote && (
          <>
            <SnapshotHighlightText
              label={['fdaLabelFootNote']}
              currentValue={property}
              snapshotValue={snapshotValue}
            >
              <span>* {property?.fdaLabelFootNote}</span>
            </SnapshotHighlightText>
          </>
        )}
      </div>
    </div>
  );
}

export default VerticalLabel;
