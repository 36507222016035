import React, { useMemo } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import Messages from 'i18n/messages/home';

import { Menu, Dropdown } from 'antd';
import RibbonButton from 'common/components/button/RibbonButton';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { ReactComponent as UnitIcon } from 'assets/product/unit.svg';
import { ReactComponent as InnerPackIcon } from 'assets/product/innerpack.svg';
import { ReactComponent as CaseIcon } from 'assets/product/case.svg';
import { ReactComponent as MasterCaseIcon } from 'assets/product/master-case.svg';
import { ReactComponent as PalletIcon } from 'assets/product/pallet.svg';

import { useFilterGrid } from 'hooks/useFilterGrid';
import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';

import { FILTER_TYPE_PRODUCTS_BY_PACKAGE } from 'static/Constants';

import './FilterSection.less';

const FilterPackageSection = (props) => {
  const dispatch = useDispatch();

  const { entityType = 'product' } = props;

  const { gridFilters, updateGridFilter } = useFilterGrid({
    entityType,
  });

  const { label, icon, toggle } = useDisplayFilterButton({
    defaultButton: {
      label: 'Package Levels',
      icon: <FilterOutlined />,
    },
    filterOptions: FILTER_TYPE_PRODUCTS_BY_PACKAGE,
    filters: gridFilters.filter((filterItem) => {
      return FILTER_TYPE_PRODUCTS_BY_PACKAGE.find(
        (FILTER_ITEM) => FILTER_ITEM.value === filterItem
      );
    }),
  });

  const handleFilterProducts = (filter) => {
    dispatch(actionsGridView.resetPageCurrent());

    if (gridFilters.indexOf(filter) > -1) {
      updateGridFilter(gridFilters.filter((val) => val !== filter));
    } else {
      let newFilterProducts = [...gridFilters];

      newFilterProducts.push(filter);

      updateGridFilter(newFilterProducts);
    }
  };

  const packageMenu = (
    <Menu>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<PalletIcon />}
          label={Messages.pimPallet}
          onClick={() => handleFilterProducts('Pallet')}
          toggle={gridFilters.indexOf('Pallet') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<MasterCaseIcon />}
          label={Messages.pimMasterCase}
          onClick={() => handleFilterProducts('Master Case')}
          toggle={gridFilters.indexOf('Master Case') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<CaseIcon />}
          label={Messages.pimDisplayShipper}
          onClick={() => handleFilterProducts('Display Shipper')}
          toggle={gridFilters.indexOf('Display Shipper') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<CaseIcon />}
          label={Messages.pimCase}
          onClick={() => handleFilterProducts('Case')}
          toggle={gridFilters.indexOf('Case') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<InnerPackIcon />}
          label={Messages.pimInnerPack}
          onClick={() => handleFilterProducts('Inner Pack')}
          toggle={gridFilters.indexOf('Inner Pack') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<UnitIcon />}
          label={Messages.pimUnit}
          onClick={() => handleFilterProducts('Unit')}
          toggle={gridFilters.indexOf('Unit') > -1}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={packageMenu} arrow>
      <RibbonButton icon={icon} label={label} toggle={toggle} />
    </Dropdown>
  );
};

export default FilterPackageSection;
