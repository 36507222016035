export const GET_WORKFLOW_DETAIL = 'GET_WORKFLOW_DETAIL';
export const GET_WORKFLOW_DETAIL_SUCCESS = 'GET_WORKFLOW_DETAIL_SUCCESS';
export const GET_WORKFLOW_DETAIL_FAIL = 'GET_WORKFLOW_DETAIL_FAIL';

// workflow grid
export const GET_WORKFLOW_GRID = 'GET_WORKFLOW_GRID';
export const GET_WORKFLOW_GRID_SUCCESS = 'GET_WORKFLOW_GRID_SUCCESS';
export const GET_WORKFLOW_GRID_ERROR = 'GET_WORKFLOW_GRID_ERROR';

export const GET_WORKFLOW_GRID_COLUMN = 'GET_WORKFLOW_GRID_COLUMN';
export const GET_WORKFLOW_GRID_COLUMN_SUCCESS =
  'GET_WORKFLOW_GRID_COLUMN_SUCCESS';
export const GET_WORKFLOW_GRID_COLUMN_ERROR = 'GET_WORKFLOW_GRID_COLUMN_ERROR';

export const UPDATE_WORKFLOW_FILTER = 'UPDATE_WORKFLOW_FILTER';

export const GET_WORKFLOW_SELECTED_STEP = 'GET_WORKFLOW_SELECTED_STEP';
export const GET_WORKFLOW_SELECTED_STEP_SUCCESS =
  'GET_WORKFLOW_SELECTED_STEP_SUCCESS';
export const GET_WORKFLOW_SELECTED_STEP_FAIL =
  'GET_WORKFLOW_SELECTED_STEP_FAIL';

export const GET_WF_IMPL_INFO = 'GET_WF_IMPL_INFO';
export const GET_WF_IMPL_INFO_SUCCESS = 'GET_WF_IMPL_INFO_SUCCESS';
export const GET_WF_IMPL_INFO_FAIL = 'GET_WF_IMPL_INFO_FAIL';

export const GET_WORKFLOW_TASKS_INFO = 'GET_WORKFLOW_TASKS_INFO';
export const GET_WORKFLOW_TASKS_INFO_SUCCESS =
  'GET_WORKFLOW_TASKS_INFO_SUCCESS';
export const GET_WORKFLOW_TASKS_INFO_FAIL = 'GET_WORKFLOW_TASKS_INFO_FAIL';

export const SET_SELECTED_WORKFLOW_STEP = 'SET_SELECTED_WORKFLOW_STEP';

export const GET_WF_IMPL_STEPS = 'GET_WF_IMPL_STEPS';
export const GET_WF_IMPL_STEPS_SUCCESS = 'GET_WF_IMPL_STEPS_SUCCESS';
export const GET_WF_IMPL_STEPS_FAIL = 'GET_WF_IMPL_STEPS_FAIL';

export const GET_WF_IMPL_CURRENT_STEP = 'GET_WF_IMPL_CURRENT_STEP';
export const UPDATE_ANSWERED_TASK = 'UPDATE_ANSWERED_TASK';

export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';

export const UPDATE_ACCEPT_STEP_GRID = 'UPDATE_ACCEPT_STEP_GRID';
export const UPDATE_APPROVE_STEP_GRID = 'UPDATE_APPROVE_STEP_GRID';
