import { defineMessages } from 'react-intl';

export const scope = 'Taco.retailer-manager';

export default defineMessages({
  selectEntityLabel: {
    product: {
      id: `${scope}.components.selectEntity.product`,
      defaultMessage: 'Select Products',
    },
    productSelectedTitle: {
      id: `${scope}.components.selectEntity.productSelected.title`,
      defaultMessage: 'Selected Product(s)',
    },
  },
  removeItemConfirmMessage: {
    all: {
      id: `${scope}.components.message.removeAll`,
      defaultMessage: 'Are you sure you want to remove all?',
    },
    selected: {
      id: `${scope}.components.message.removeSelectedProduct`,
      defaultMessage: 'Are you sure you want to remove selected items?',
    },
  },
  successSupplierMessage: {
    assign: {
      id: `${scope}.components.message.assignSuccess`,
      defaultMessage: 'Assign supplier successfully!',
    },
    edit: {
      id: `${scope}.components.message.editSuccess`,
      defaultMessage: 'Edit successfully!',
    },
  },

  errorSupplierMessage: {
    assign: {
      id: `${scope}.components.message.assignFail`,
      defaultMessage: 'Failed to assign supplier',
    },
    edit: {
      id: `${scope}.components.message.editFail`,
      defaultMessage: 'Failed to edit supplier',
    },
  },

  searchSupplierLabel: {
    id: `${scope}.components.searchSupplier.label`,
    defaultMessage: 'Search Supplier',
  },

  removeAllBtn: {
    id: `${scope}.components.btn.removeAll`,
    defaultMessage: 'Remove All',
  },
  removeBtn: {
    id: `${scope}.components.btn.remove`,
    defaultMessage: 'Remove',
  },
  submitBtn: {
    id: `${scope}.components.btn.submit`,
    defaultMessage: 'Submit',
  },
  cancelBtn: {
    id: `${scope}.components.btn.cancel`,
    defaultMessage: 'Cancel',
  },

  searchBrokerLabel: {
    id: `${scope}.components.searchSupplier.label`,
    defaultMessage: 'Search Retailer',
  },
  resetSearchBtn: {
    id: `${scope}.components.searchSupplier.resetBtn`,
    defaultMessage: 'Reset',
  },

  supplierGridHint: {
    id: `${scope}.components.supplierGrid.hint`,
    defaultMessage: 'Input search text to find suppliers',
  },
  selectSupplierHint: {
    id: `${scope}.components.selectSupplier.hint`,
    defaultMessage: 'Select a supplier to assign product',
  },
  permissionGroupName: {
    productItemManagement: {
      id: `${scope}.components.permission.groupName.product`,
      defaultMessage: 'Product Item Management',
    },
  },

  assignSupplierModalTitle: {
    id: `${scope}.components.modal.assignSupplier.title`,
    defaultMessage: 'Supplier Management',
  },

  permissionGroupItem: {
    viewProduct: {
      id: `${scope}.components.permission.displayName.product.view`,
      defaultMessage: 'VIEW PRODUCT',
    },
    editProduct: {
      id: `${scope}.components.permission.displayName.product.edit`,
      defaultMessage: 'EDIT PRODUCT',
    },
  },
  initiateQASpecificationWorkflow: {
    id: `${scope}.components.modal.initiateQASpecificationWorkflow`,
    defaultMessage: 'Initiate QA Specification Workflow successfully!',
  },
});
