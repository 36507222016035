import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Checkbox } from 'antd';

import { MEMBER_PERMISSION_CATEGORY_DEFINE } from 'static/Constants';

import classnames from 'classnames';

import './MemberRolePermissionGroup.less';

const { Text } = Typography;

/**
 * ! DEFAULT COMPONENT
 * @param {*} props
 */
const MemberRolePermissionGroup = (props) => {
  const { categorizedPermission, formRole, changedPermisisonList } = props;

  return categorizedPermission ? (
    <div className='member-role-permission-group scroller'>
      {Object.entries(categorizedPermission).map((groupPermissionItem) => {
        const [groupPermissionName] = groupPermissionItem;

        return (
          <MemberRolePermissionGroupItem
            key={groupPermissionName}
            groupPermissionItem={groupPermissionItem}
            formRole={formRole}
            changedPermisisonList={changedPermisisonList}
          />
        );
      })}
    </div>
  ) : null;
};

/**
 * ! SUB COMPONENT
 * @param {*} props
 */
const MemberRolePermissionGroupItem = (props) => {
  const { groupPermissionItem, formRole, changedPermisisonList } = props;

  const [groupPermissionName, groupPermissionList] = groupPermissionItem;
  return (
    groupPermissionList.permission?.length > 0 && (
      <div className='member-role-permission-group__item'>
        <div className='member-role-permission-group__item-title'>
          {groupPermissionName}
        </div>
        {groupPermissionList.permission.map(
          ({ name, displayName, isActive }) => (
            <MemberRolePermissionGroupItemDetail
              key={name}
              displayName={displayName}
              name={name}
              isActive={isActive}
              formRole={formRole}
              changedPermisisonList={changedPermisisonList}
            />
          )
        )}
      </div>
    )
  );
};

/**
 * ! SUB COMPONENT
 * @param {*} props
 */
const MemberRolePermissionGroupItemDetail = (props) => {
  const { displayName, isActive, name, formRole, changedPermisisonList } =
    props;

  const onChangeCheckboxValue = (event, name) => {
    if (!formRole) return;

    const checked = event.target.checked;

    let nextPermissionList = formRole?.values?.permissionList;

    if (checked) {
      nextPermissionList = [...nextPermissionList, name];
    } else {
      nextPermissionList = nextPermissionList.filter(
        (permissionItem) => permissionItem !== name
      );
    }

    formRole.setFieldValue('permissionList', nextPermissionList);
  };

  const isPermissionStatusChanged = changedPermisisonList.includes(name);

  return (
    <div
      className={classnames('member-role-permission-group__item-detail', {
        'member-role-permission-group__item-detail--changed':
          isPermissionStatusChanged,
      })}
    >
      <Checkbox
        checked={isActive}
        onChange={(event) => onChangeCheckboxValue(event, name)}
      >
        <Text>{displayName}</Text>
      </Checkbox>
    </div>
  );
};

MemberRolePermissionGroup.propTypes = {};

export default MemberRolePermissionGroup;
