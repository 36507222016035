import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { CaretRightOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const RunQueryControl = (props) => {
  const { onClick, disabled } = props;

  const onClickBtn = () => {
    onClick && onClick();
  };

  return (
    <RibbonButton
      icon={<CaretRightOutlined />}
      label={Messages.runQuery}
      onClick={onClickBtn}
      disabled={disabled}
    />
  );
};

RunQueryControl.propTypes = {
  confirmRunQuery: PropTypes.func,
};

export default RunQueryControl;
