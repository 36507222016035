import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { CaretLeftOutlined } from '@ant-design/icons';
import { StepForm, CountdownTrack, dialogFunction } from 'common/components';
import { Button, Divider, Form } from 'antd';
import OtpCheck from '../otp-check/OtpCheck';
import OtpTrigger from '../otp-trigger/OtpTrigger';

import userSelector from 'redux/user/selectors';

import { forwardTo } from 'utils/common/route';
import { useStatePrevious } from 'hooks/usePrevious';
import { sessionTimeFormatter, getNewResendOtpTime } from '../../utils/utils';
import { getParameterFromQuery } from 'utils/common/route';

import { OTP_ACTION, OTP_TYPE } from '../../constants';

import './OtpVerifyLogin.less';

const OtpVerifyLogin = () => {
  const [otp, setOtp] = useState([]);
  const [step, setStep] = useState(0);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const timeAllowResendHook = useState();
  const allowResendHook = useStatePrevious(false);
  const [isAllowResend, setIsAllowResend] = allowResendHook;
  const [timeAllowResend, setTimeAllowResend] = timeAllowResendHook;
  const [otpCheckError, setOtpCheckError] = useState('');

  const returnUrl = getParameterFromQuery('returnUrl') || '/';
  const refOtpTrigger = useRef();
  const [formInst] = Form.useForm();

  const mfaLoginInfo = useSelector(userSelector.makeSelectMfaLoginInfo());

  const { loginSessionExpireAt } = mfaLoginInfo;

  const onResendOtp = () => {
    refOtpTrigger.current.onResendOtp();
    setTimeAllowResend(getNewResendOtpTime());
  };

  const handleClickBack = () => {
    setOtp([]);
    setStep(0);
  };

  useEffect(() => {
    setStep(0);
  }, []);

  useEffect(() => {
    if (!timeAllowResend) {
      setIsAllowResend(true);
    }
  }, [timeAllowResend]);

  useEffect(() => {
    if (isSessionExpired) {
      dialogFunction({
        type: 'info',
        content: 'MFA login session ended. Please try to login again',
        okText: 'OK',
        cancelText: 'Cancel',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: () => {
          forwardTo(`/login?returnUrl=${returnUrl}`);
        },
        maskClosable: false,
      });
    }
  }, [isSessionExpired, returnUrl]);

  const getSessionRemainingTime = (remainingTime) => {
    if (remainingTime === 0 && !isSessionExpired) {
      setIsSessionExpired(true);
    }
  };

  const stepDefine = [
    {
      step: 0,
      wrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 400,
      },
      component: !mfaLoginInfo?.mfaPhoneNumber ? (
        <div>
          <OtpTrigger
            ref={refOtpTrigger}
            setStep={setStep}
            mfaLoginInfo={mfaLoginInfo}
            formInst={formInst}
            setTimeAllowResend={setTimeAllowResend}
            isAllowResend={isAllowResend}
            timeAllowResend={timeAllowResend}
            setOtpCheckError={setOtpCheckError}
            setIsSessionExpired={setIsSessionExpired}
            addNew={!mfaLoginInfo?.mfaPhoneNumber}
            otpAction={OTP_ACTION.login}
            otpType={OTP_TYPE.phone}
          />
        </div>
      ) : (
        <OtpTrigger
          ref={refOtpTrigger}
          setStep={setStep}
          mfaLoginInfo={mfaLoginInfo}
          formInst={formInst}
          setTimeAllowResend={setTimeAllowResend}
          isAllowResend={isAllowResend}
          timeAllowResend={timeAllowResend}
          setOtpCheckError={setOtpCheckError}
          setIsSessionExpired={setIsSessionExpired}
          addNew={!mfaLoginInfo?.mfaPhoneNumber}
          otpAction={OTP_ACTION.login}
          otpType={OTP_TYPE.phone}
        />
      ),
    },
    {
      step: 1,
      wrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 400,
      },
      component: !mfaLoginInfo?.mfaPhoneNumber ? (
        <div>
          <Button
            type='primary'
            shape='round'
            icon={<CaretLeftOutlined />}
            style={{
              display: 'table',
              marginLeft: 'auto',
              marginRight: 10,
              marginBottom: 10,
            }}
            onClick={handleClickBack}
          >
            Back
          </Button>
          <Divider style={{ margin: '12px 0' }} />
          <OtpCheck
            otp={otp}
            setOtp={setOtp}
            mfaLoginInfo={mfaLoginInfo}
            onResendOtp={onResendOtp}
            step={step}
            timeAllowResendHook={timeAllowResendHook}
            allowResendHook={allowResendHook}
            otpCheckError={otpCheckError}
            setOtpCheckError={setOtpCheckError}
            otpType={OTP_TYPE.phone}
            otpAction={OTP_ACTION.login}
            addNew
          />
        </div>
      ) : (
        <OtpCheck
          otp={otp}
          setOtp={setOtp}
          mfaLoginInfo={mfaLoginInfo}
          onResendOtp={onResendOtp}
          step={step}
          timeAllowResendHook={timeAllowResendHook}
          allowResendHook={allowResendHook}
          otpCheckError={otpCheckError}
          setOtpCheckError={setOtpCheckError}
          setIsSessionExpired={setIsSessionExpired}
          otpType={OTP_TYPE.phone}
          otpAction={OTP_ACTION.login}
          addNew={false}
        />
      ),
    },
  ];

  return (
    <div className='otp-verify-login'>
      <StepForm currentStep={step} stepDefine={stepDefine} />
      <CountdownTrack
        wrapperClass='otp-verify-login__session-count-down'
        expirationTime={loginSessionExpireAt}
        getRemainingTime={getSessionRemainingTime}
        totalTime={600}
        timeFormatter={sessionTimeFormatter}
        noProgress
      />
    </div>
  );
};

export default OtpVerifyLogin;
