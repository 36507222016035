// export const GET_CHAT_THREAD_LIST = '/api/chat/Get-thread-list';
export const GET_CHAT_THREAD_LIST = '/api/chat/get-thread-list'; // todo, remove this
export const GET_CHAT_COMMUNICATION_PARTICIPANT =
  '/api/chat/getCommunicationParticipant';
export const GET_CHAT_COMMUNICATION_MESSAGE =
  '/api/chat/getCommunicationMessage';
export const GET_THREAD_MESSAGES = '/api/chat/get-thread-messages';
export const GET_THREAD_INFO = '/api/chat/get-thread-info';

export const GET_THREAD_LIST_FOR_SUPPORTER =
  '/api/chat/get-thread-list-for-supporter';
export const GET_CHAT_CONTENT_DETAIL = '/api/chat/get-chat-content-detail';
export const GET_CHAT_USERS_INFO = '/api/chat/get-chat-users-info';
export const GET_SENDER_INFO = '/api/chat/get-sender-info';

export const GET_CHAT_MESSAGE_NOTIFY = '/';
export const RESET_CHAT_MESSAGE_NOTIFY = '/';

export const SEARCH_MESSAGES = '/api/chat/search-message-in-thread';
export const GET_EMAIL_AND_MESSAGE_NOTIFY =
  '/api/chat/get-total-unread-message';
export const MAKE_A_THREAD_AS_READ = '/api/chat/make-a-thread-as-read';

// init chat
export const INIT_CHAT_COMMUNICATION = '/api/chat/init-chat';

// update group avatar
export const UPDATE_GROUP_AVATAR = '/api/chat/update-group-avatar';
// update group name
export const UPDATE_GROUP_NAME = '/api/chat/update-group-name';

// upload chat files
export const UPLOAD_CHAT_FILE = '/api/chat/upload-chat-file';
export const DOWNLOAD_CHAT_FILE = '/api/chat/download-chat-file';
export const GET_CHAT_FILE_INFO = '/api/chat/get-chat-file-info';

// delete thread
export const DELETE_CHAT_THREAD = '/api/chat/delete-chat-thread';
