export const GET_MARKUP_MAINTENANCE_GRID_COLUMNS = '';

// export const CREATE_MARKUP = '/api/User/create-eula';
// export const DELETE_MARKUP = '/api/User/delete-eula';
export const CLONE_MARKUP = '/api/User/clone-eula';

export const GET_MARKUP_DETAIL = '/api/User/get-eula-short-detail';
export const GET_MARKUP_MAINTENANCE_LIST = '/api/User/get-eula-detail-grid';
export const POST_DETAIL_GRID_DISTINCT =
  '/api/User/get-eula-detail-grid/distinct';
export const GET_MARKUP_DETAIL_CONTENT = '/api/User/get-eula-short-detail';

export const GET_MAIN_PAGE_GRID_DISTINCT =
  '/api/main-page/get-main-page-grid/distinct';

export const GET_MAIN_PAGE_GRID = '/api/main-page/get-main-page-grid';

export const GET_MAIN_PAGE_SHORT_DETAILS =
  '/api/main-page/get-main-page-short-details';

export const GET_MARKUP_ASSET = '/api/main-page/get-main-page-assets';
export const UPLOAD_MARKUP_ASSET = '/api/main-page/upload-main-page-asset';
export const SAVE_MARKUP = '/api/main-page/save-main-page';
export const CREATE_MARKUP = '/api/main-page/create-markup';
export const DELETE_MARKUP = '/api/main-page/delete-main-pages';

export const GET_MARKUP_TEXT = '/api/main-page/get-main-page-info';

// Curated Query
export const GET_CURATED_QUERY = '/api/main-page/get-curated-queries-grid';
export const POST_DETAIL_CURATED_GRID_DISTINCT =
  '/api/main-page/get-curated-queries-grid/distinct';
export const GET_CURATED_QUERY_SHORT_DETAIL =
  '/api/main-page/get-curated-query-short-details';

export const GET_ALL_QUERIES_NOT_CURATED_QUERY_GRID =
  '/api/main-page/get-all-queries-not-curated-query-grid';
export const GET_ALL_QUERIES_NOT_CURATED_QUERY_GRID_DISTINCT =
  '/api/main-page/get-all-queries-not-curated-query-grid/distinct';
export const SAVE_CURATED_QUERY = '/api/main-page/save-curated-query';
export const REVOKE_CURATED_QUERY = '/api/main-page/revoke-curated-query';
