import React, { useState, useMemo } from 'react';

import { DropboxFiles, DropboxSelection } from './DropboxFiles';
import DropboxActions from './DropboxActions';

import { useGetTreeData } from './hooks';
import { useGetListConnections } from 'pages/company-profile/components/tabs/system/interoperability/hooks';

import {
  findNodeTree,
  getChildNodesInFolder,
  filterArrayInAnotherArray,
  filterArrayObjectInAnotherArrayObject,
  findAncestorsOfChildNode,
  getOnlySelectedParentItems,
} from './utils';

import {
  findListNodeInTree,
  getAllFilesInFolders,
  getFilesAfterExpandingAll,
} from '../shared/utils';

import { DataToMsOffice } from 'utils/dataToMSoffice';

import './AssetDropboxUpload.less';

const AssetDropboxUpload = (props) => {
  const {
    isEnabledImportFolder,
    multipleImport,
    formInstance,
    handleCloseModal,
    idSelectionDropbox,
    setIdSelectionDropbox,
    typeViewAsset,
    importedFolderParams,
    destinationFolder,
  } = props;

  const { interoperabilities } = useGetListConnections({
    storageServiceType: 'dropbox',
  });

  // Default connection is first connection. I can't find any better name. Naming is so hard.
  const valueIdSelection = useMemo(() => {
    if (idSelectionDropbox) return idSelectionDropbox;

    if (interoperabilities.length > 0) return interoperabilities[0].id;

    return null;
  }, [idSelectionDropbox, interoperabilities]);

  const { treeData, isExpanding, setTreeData } = useGetTreeData({
    id: valueIdSelection,
    multipleImport,
    storageServiceType: 'dropbox',
  });

  const [selectedDropbox, setSelectedDropbox] = useState({
    selectedItems: [],
    selectedKeys: [],
    expandedKeys: [],
  });

  /* 
    status === 'loading': treeData is loading data inside
    status === 'success': treeData is already loaded data
  */
  const [expandAll, setExpandAll] = useState({
    value: false,
    status: 'idle',
  });

  const [statusCopy, setStatusCopy] = useState('idle');

  const handleSelectItems = (keys, items) => {
    if (multipleImport) {
      const parentSelectedItems = getOnlySelectedParentItems(
        treeData,
        items,
        keys
      );

      setSelectedDropbox((prevItems) => ({
        ...prevItems,
        selectedItems: parentSelectedItems,
        selectedKeys: keys,
      }));
    } else {
      setSelectedDropbox((prevItems) => ({
        ...prevItems,
        selectedItems: items,
        selectedKeys: keys,
      }));
    }
  };

  const handleDeleteItem = (itemId) => {
    if (!multipleImport) {
      setSelectedDropbox((prevItems) => ({
        ...prevItems,
        selectedItems: [],
        selectedKeys: [],
      }));
    } else {
      const { selectedItems, selectedKeys } = selectedDropbox;

      const foundNode = findNodeTree(treeData, itemId);
      const isHaveChildren = foundNode?.children?.length > 0;

      const ancestorNodes = findAncestorsOfChildNode(treeData, foundNode);
      const ancestorKeys = ancestorNodes.map((node) => node.key);

      if (isHaveChildren) {
        const childNodes = getChildNodesInFolder(foundNode.children);
        const childKeys = childNodes.map((node) => node.key);

        const allKeys = [foundNode.key].concat(ancestorKeys).concat(childKeys); // include select key and ancestor keys
        const allNodes = [foundNode].concat(ancestorNodes).concat(childNodes);

        const filteredSelectedKeys = filterArrayInAnotherArray(
          selectedKeys,
          allKeys
        );
        const filteredSelectedItems = filterArrayObjectInAnotherArrayObject(
          selectedItems,
          allNodes
        );

        setSelectedDropbox((prevItems) => ({
          ...prevItems,
          selectedItems: filteredSelectedItems,
          selectedKeys: filteredSelectedKeys,
        }));
      } else {
        const allKeys = [foundNode.key].concat(ancestorKeys); // include select key and ancestor keys
        const allNodes = [foundNode].concat(ancestorNodes);

        const filteredSelectedKeys = filterArrayInAnotherArray(
          selectedKeys,
          allKeys
        );
        const filteredSelectedItems = filterArrayObjectInAnotherArrayObject(
          selectedItems,
          allNodes
        );

        setSelectedDropbox((prevItems) => ({
          ...prevItems,
          selectedItems: filteredSelectedItems,
          selectedKeys: filteredSelectedKeys,
        }));
      }
    }
  };

  const handleCopyFileNameToExcel = async () => {
    setStatusCopy('loading');

    let copyItems = [];

    const { selectedItems } = selectedDropbox;

    const selectedFiles = selectedItems.filter(
      (item) => item.data.type === 'file'
    );
    const selectedFolders = selectedItems.filter(
      (item) => item.data.type === 'folder'
    );

    if (expandAll.status === 'success') {
      const folderNodes = findListNodeInTree(treeData, selectedFolders);
      const filesInFolder = getAllFilesInFolders(folderNodes);

      copyItems = selectedFiles.concat(filesInFolder);
    } else {
      const expandedFiles = await getFilesAfterExpandingAll({
        folderNodes: selectedFolders,
        id: idSelectionDropbox,
        storageServiceType: 'dropbox',
        multipleImport,
      });

      copyItems = selectedFiles.concat(expandedFiles);
    }

    DataToMsOffice().copy2DArrayClipboardToExcel(
      copyItems.map((fileItem) => [fileItem.data.name])
    );

    setStatusCopy('success');
  };

  // after 30 days if the function is not restored please delete this code, thanks

  // const handleExpandAllFiles = async (event) => {
  //   const checked = event.target.checked;

  //   if (checked) {
  //     setExpandAll((prevVal) => ({ ...prevVal, value: checked }));

  //     // If treeData loaded data, then does not need to call api again.
  //     if (expandAll.status !== 'success') {
  //       setExpandAll((prevVal) => ({ ...prevVal, status: 'loading' }));

  //       const newTreeData = await getTreeDataAfterExpandingAll({
  //         treeData,
  //         id: valueIdSelection,
  //         storageServiceType: 'dropbox',
  //         multipleImport,
  //       });
  //       const expandedKeys = getAllExpandedKeys(newTreeData);

  //       setSelectedDropbox((prevItems) => ({
  //         ...prevItems,
  //         expandedKeys,
  //       }));

  //       handleSetTreeData(newTreeData);
  //       setExpandAll((prevVal) => ({ ...prevVal, status: 'success' }));
  //     } else {
  //       const expandedKeys = getAllExpandedKeys(treeData);

  //       setSelectedDropbox((prevItems) => ({
  //         ...prevItems,
  //         expandedKeys,
  //       }));
  //     }
  //   } else {
  //     setExpandAll((prevVal) => ({ ...prevVal, value: checked }));
  //     setSelectedDropbox((prevItems) => ({
  //       ...prevItems,
  //       expandedKeys: [],
  //     }));
  //   }
  // };

  const handleExpandItems = (expandedKeys) => {
    setSelectedDropbox((prevItems) => ({
      ...prevItems,
      expandedKeys,
    }));
  };

  return (
    <div className='dropbox-asset__container'>
      <DropboxSelection
        valueIdSelection={valueIdSelection}
        interoperabilities={interoperabilities}
        onChangeIdConnection={(id) => {
          setIdSelectionDropbox(id);
          setExpandAll(false);
          setSelectedDropbox((prevItems) => ({
            ...prevItems,
            selectedItems: [],
            selectedKeys: [],
            expandedKeys: [],
          }));
          setExpandAll((prevVal) => ({ ...prevVal, status: 'idle' }));
        }}
        // onChangeExpandAll={handleExpandAllFiles}
        checked={expandAll.value}
      />
      <DropboxFiles
        idConnection={valueIdSelection}
        multipleImport={multipleImport}
        treeData={treeData}
        isExpanding={isExpanding}
        expandAll={expandAll}
        selectedDropbox={selectedDropbox}
        onSetTreeData={setTreeData}
        onSelectItems={handleSelectItems}
        onExpandItems={handleExpandItems}
      />
      <DropboxActions
        idConnection={valueIdSelection}
        formInstance={formInstance}
        destinationFolder={destinationFolder}
        multipleImport={multipleImport}
        statusCopy={statusCopy}
        selectedItems={selectedDropbox.selectedItems}
        typeViewAsset={typeViewAsset}
        handleCloseModal={handleCloseModal}
        onDeleteItem={handleDeleteItem}
        onCopyFileNameExcel={handleCopyFileNameToExcel}
        importedFolderParams={importedFolderParams}
        isEnabledImportFolder={isEnabledImportFolder}
      />
    </div>
  );
};

export default AssetDropboxUpload;
