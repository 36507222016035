import React from 'react';

import RibbonButton from 'common/components/button/RibbonButton';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';

import { forwardTo } from 'utils/common/route';

const EditFormBtn = (props) => {
  const { selectedForms, loading, ...restProps } = props;

  const onClickEdit = () => {
    const formId = selectedForms?.[0]?.formId;
    if (!formId) return;

    const formFormat = selectedForms?.[0]?.formFormat.toLowerCase();

    const typeParam = formFormat.includes('doc')
      ? 'word'
      : formFormat.includes('xls')
      ? 'excel'
      : formFormat.includes('ppt')
      ? 'powerpoint'
      : null;

    forwardTo(`/maintenance/form/${typeParam}/${formId}`);
  };

  return (
    <RibbonButton
      icon={loading ? <LoadingOutlined /> : <EditOutlined />}
      label={'Edit'}
      onClick={onClickEdit}
      {...restProps}
    />
  );
};

export default EditFormBtn;
