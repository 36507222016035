import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const { TextArea } = Input;

const MemberRoleDescriptionFormItem = (props) => {
  const { roleDescription, formRole } = props;

  return (
    <TextArea
      name='roleDescription'
      value={roleDescription || ''}
      onChange={formRole?.onChange}
      style={{ height: '100%', background: '#E9E9E0' }}
      placeholder='Input role description'
    />
  );
};

MemberRoleDescriptionFormItem.propTypes = {
  roleDescription: PropTypes.string,
  formRole: PropTypes.object,
};

export default MemberRoleDescriptionFormItem;
