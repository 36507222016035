import React from 'react';
import { Input } from 'antd';
import './Textarea.less';
import PropTypes from 'prop-types';

const Textarea = (props) => {
  const { content, minRows, maxRows } = props;
  const { TextArea } = Input;
  return (
    <TextArea
      autoSize={{ minRows, maxRows }}
      disabled
      value={content}
    ></TextArea>
  );
};

Textarea.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
};

Textarea.defaultProps = {
  minRows: 8,
  maxRows: 8,
};

export default Textarea;
