import { useContext } from 'react';
import { MemberFullContext } from 'context/MemberFullContext';

/**
 * //* this hook return edit permission for member full view for both my compnay profile and member full maintain
 * ///? references: MemberFullProvider, MemberFullContext
 *  @returns
 */
export const useCheckEditkMemberFullAndMyCompany = () => {
  const {
    isAllowEdit,
    isAllowEditInMaintainOnly,
    haveEditMemberPermission,
    isAllowAccessAndEditSupplierManagement,
  } = useContext(MemberFullContext);

  return {
    isAllowEdit,
    isAllowEditInMaintainOnly,
    haveEditMemberPermission,
    isAllowAccessAndEditSupplierManagement,
  };
};
