import { isNil } from 'lodash';

import SeparatorBar from 'pages/product-full-view/components/product-media-area/nutrition-label/components/SeparatorBar';
import { SnapshotHighlightText } from 'pages/qa-spec/components/qa-nutrition-data/WrapperNutrient';

const Calories = ({ property, snapshotValue, calories, caloriesFromFat }) => {
  if (isNil(calories)) return null;

  return (
    <div className='supplement-standard__text'>
      <SnapshotHighlightText
        label={['calories']}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        <div className='supplement-standard__row'>
          <div
            style={{
              flex: 1,
            }}
          >
            Calories
          </div>
          <div className='supplement-standard__amountPerServing'>
            <p className='m-0'>{calories}</p>
          </div>
          <div className='supplement-standard__dv supplement-standard__dv--calories'></div>
        </div>
      </SnapshotHighlightText>
      <SeparatorBar />
      {!isNil(caloriesFromFat) ? (
        <>
          <SnapshotHighlightText
            label={['caloriesFromFat']}
            currentValue={property}
            snapshotValue={snapshotValue}
          >
            <div className='supplement-standard__row'>
              <div
                style={{
                  flex: 1,
                  paddingLeft: '7px',
                }}
              >
                Calories from Fat
              </div>
              <div className='supplement-standard__amountPerServing'>
                <p className='m-0'>{caloriesFromFat}</p>
              </div>
              <div className='supplement-standard__dv supplement-standard__dv--calories'></div>
            </div>
          </SnapshotHighlightText>
          <SeparatorBar />
        </>
      ) : null}
    </div>
  );
};

export default Calories;
