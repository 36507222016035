import React, { useEffect } from 'react';

import { Typography, Input, Divider, Row, Col } from 'antd';

import { Form } from 'common/components';

import Messages from 'i18n/messages/apl';
import { useIntl } from 'react-intl';

import { validateEmail } from 'utils';

const { Title, Text } = Typography;

const AplSendQuestion = (props) => {
  const intl = useIntl();

  const {
    visible,
    selectedProduct,
    questionList,
    setQuestionList,
    setProductSendingStatusList,
    form,
    onUpdateEmail,
  } = props;

  const [formInstance] = Form.useForm();

  const isDraftEmail = questionList?.[selectedProduct?.id]?.isDraft;

  useEffect(() => {
    if (isDraftEmail && selectedProduct?.id) {
      const { id } = selectedProduct;
      const question = questionList[id];

      formInstance.setFieldsValue({
        email: question.email,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInstance, isDraftEmail, questionList, selectedProduct?.id]);

  const onFieldsChange = (_, allFields) => {
    const values = allFields.reduce(
      (accumulator, currentField) => {
        return { ...accumulator, [currentField.name[0]]: currentField.value };
      },
      {
        productId: selectedProduct?.id,
        email: selectedProduct?.contactEmail,
        isDraft: selectedProduct?.isDraft,
      }
    );

    updateQuestionList(values);
    updateProductSendingStatusList(values);
  };

  const updateQuestionList = (formValue) => {
    const { productId, ...restValue } = formValue;
    setQuestionList((prevState) => {
      return { ...prevState, [formValue?.productId]: restValue };
    });
  };

  const updateProductSendingStatusList = (formValue) => {
    setProductSendingStatusList((prevState) => {
      const isValidValue =
        formValue?.emailTitle &&
        formValue?.emailContent &&
        validateEmail(selectedProduct?.contactEmail);

      return {
        ...prevState,
        [formValue?.productId]: isValidValue ? 'success' : 'error',
      };
    });
  };

  const initFormValue = () => {
    if (!selectedProduct?.id) return;

    const formValue = questionList[selectedProduct?.id];

    formValue ? form.setFieldsValue(formValue) : form.resetFields();
  };

  const resetForm = () => {
    if (visible) return;
    form.resetFields();
    setQuestionList({});
  };

  useEffect(() => {
    resetForm();
  }, [visible]);

  useEffect(() => {
    initFormValue();
  }, [selectedProduct?.id]);

  const handleChangeEmail = (event) => {
    const email = event.target.value;

    if (validateEmail(email)) {
      onUpdateEmail(email);
    }

    const question = questionList[selectedProduct?.id] ?? {};

    setProductSendingStatusList((prevState) => {
      const isValidValue =
        question?.emailTitle && question?.emailContent && validateEmail(email);

      return {
        ...prevState,
        [selectedProduct?.id]: isValidValue ? 'success' : 'error',
      };
    });
  };

  const ProductName = Title;

  const rules = [
    { required: true, message: intl.formatMessage(Messages.requiredRule) },
  ];

  const displayInputEmail = !selectedProduct?.contactEmail || isDraftEmail;

  return (
    <div className='apl-send-question'>
      <ProductName level={5} ellipsis>
        {selectedProduct?.name}
      </ProductName>

      <Row style={{ height: 40 }}>
        <Col span={4} style={{ marginTop: 4 }}>
          <Typography.Text strong>
            {intl.formatMessage(Messages.contactEmail)}:
          </Typography.Text>
        </Col>
        <Col span={20}>
          {displayInputEmail ? (
            <Form
              form={formInstance}
              initialValues={{
                email: selectedProduct?.contactEmail,
              }}
            >
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Email is not valid!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (validateEmail(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  placeholder='Input email'
                  style={{ width: '100%' }}
                  allowClear
                  enterButton
                  onChange={handleChangeEmail}
                />
              </Form.Item>
            </Form>
          ) : (
            <div style={{ marginTop: 4 }}>
              <Text className='apl-send-question__contact-email-info' strong>
                {selectedProduct?.contactEmail}
              </Text>
            </div>
          )}
        </Col>
      </Row>

      <Divider style={{ margin: '4px 0' }} />
      <Title level={5}>{intl.formatMessage(Messages.sendQuestion)}</Title>
      <Form layout='vertical' form={form} onFieldsChange={onFieldsChange}>
        <Form.Item
          name='emailTitle'
          label={intl.formatMessage(Messages.emailTitle)}
          rules={rules}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='emailContent'
          label={intl.formatMessage(Messages.emailContent)}
          rules={rules}
        >
          <Input.TextArea rows={11} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AplSendQuestion;
