import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  TitleFolderAsset,
  IconFolderAsset,
  TitleFileAsset,
  IconFileAsset,
} from '../shared/components';

import { getInteropListItems } from 'services/interoperability';

import { ASSET_VIRTUAL_SCROLL_HEIGHT } from 'static/Constants';
import { getFolderShortDetail } from 'services/folder';

const getUuidKey = () => uuidv4();

const getNodeTreeData = (interop, multipleImport) => {
  if (interop.type === 'folder') {
    return {
      key: getUuidKey(),
      title: <TitleFolderAsset title={interop.name} />,
      icon: <IconFolderAsset />,
      data: interop,
      selectable: false,
      checkable: multipleImport ? true : false,
    };
  }
  return {
    key: getUuidKey(),
    title: <TitleFileAsset title={interop.name} />,
    icon: <IconFileAsset />,
    data: interop,
    selectable: multipleImport ? false : true,
    isLeaf: true,
  };
};

/**
 *
 * @param {*} list
 * @param {*} key
 * @param {*} children
 * @param {*} folderId - //! folder id can be used to check instead of key
 * @returns
 */
export const updateTreeData = (list, key, children, folderId) => {
  return list.map((node) => {
    const { data, key: nodeKey } = node;
    const { id } = data || {};

    if (id === folderId && folderId) {
      return { ...node, children };
    }

    if (nodeKey === key) {
      return { ...node, children };
    }

    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children, folderId),
      };
    }

    return node;
  });
};

export const mappingInteropItemsToTreeData = (
  interopItems = [],
  multipleImport = false
) => {
  let treeData = [];

  if (interopItems) {
    interopItems.forEach((interop) => {
      if (interop.type === 'folder') {
        let node = getNodeTreeData(interop, multipleImport);
        treeData.push(node);
      } else {
        let node = getNodeTreeData(interop, multipleImport);
        treeData.push(node);
      }
    });
  }

  return treeData;
};

export const getExpandedData = async (params) => {
  const response = await getInteropListItems(params);
  if (response?.isSuccess) {
    if (response?.data?.interopItems) {
      return response?.data?.interopItems;
    }
  }
  return null;
};

export const getFormDataParams = (params = {}) => {
  let formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const filterFoldersAndFilesInSelectedItems = (selectedItems = []) => {
  let folders = [];
  let files = [];

  selectedItems.forEach((item) => {
    const { data } = item;

    if (data.type === 'file') {
      files.push(item);
    } else if (data.type === 'folder') {
      if (Array.isArray(item.children) && item.children.length > 0) {
        folders.push(item);
        filterFoldersAndFilesInSelectedItems(item.children);
      } else {
        folders.push(item);
      }
    }
  });

  return {
    folders,
    files,
  };
};

const findFileExistInAnyFolders = (folders, file) => {
  return folders.some((item) => {
    if (item.key === file.key) {
      return true;
    } else if (item.children) {
      return findFileExistInAnyFolders(item.children, file);
    }
    return false;
  });
};

// A file will be sent to the server when folder that contains the file does not check
export const getFilesPath = (folders, files) => {
  let result = [];

  files.forEach((file) => {
    const isExist = findFileExistInAnyFolders(folders, file);

    if (!isExist) {
      result.push(file.data.path);
    }
  });

  return result;
};

const findFolderExistInAnyFolders = (folders, findFolder) => {
  return folders.some((folder) => {
    if (folder.key === findFolder.key) {
      return true;
    } else if (folder.children) {
      return findFolderExistInAnyFolders(folder.children, findFolder);
    }
    return false;
  });
};

// A folder will be sent to the server when folder that contains the file does not check
export const getFoldersPath = (folders) => {
  let result = [];

  folders.forEach((folder) => {
    const filteredFolders = folders.filter((f) => f.key !== folder.key);

    const isExist = findFolderExistInAnyFolders(filteredFolders, folder);

    if (!isExist) {
      result.push(folder.data.path);
    }
  });

  return result;
};

let foundNode = null;
export const findNodeTree = (treeData, itemId) => {
  for (let index = 0; index < treeData.length; index++) {
    const node = treeData[index];
    if (node.data.itemId === itemId) {
      foundNode = node;
      break;
    }

    if (node?.children) {
      findNodeTree(node.children, itemId);
    }
  }
  return foundNode;
};

export const getChildNodesInFolder = (treeData) => {
  let children = [];
  const flattenMembers = treeData.map((m) => {
    if (m.children && m.children.length) {
      children = [...children, ...m.children];
    }
    return m;
  });

  return flattenMembers.concat(
    children.length ? getChildNodesInFolder(children) : children
  );
};

export const filterArrayInAnotherArray = (arr1, arr2) => {
  return arr1.filter((item) => !arr2.includes(item));
};

export const findAncestorsOfChildNode = (treeData, childNode) => {
  if (Array.isArray(treeData) && treeData.length > 0) {
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i].key === childNode.key) return [];

      let result = findAncestorsOfChildNode(treeData[i].children, childNode);
      if (result !== null) {
        result = result.concat(treeData[i]);
        return result;
      }
    }
  }
  return null;
};

export const filterArrayObjectInAnotherArrayObject = (
  array1 = [],
  array2 = []
) => {
  let result = [];

  array1.forEach((item1) => {
    const foundItem = array2.find((item2) => item1.key === item2.key);

    if (!foundItem) {
      result.push(item1);
    }
  });

  return result;
};

export const getOnlySelectedParentItems = (treeData, items, keys) => {
  let result = [];
  items.forEach((item) => {
    const ancestorNodes = findAncestorsOfChildNode(treeData, item);
    const ancestorKeys = ancestorNodes.map((node) => node.key);
    const isParentSelected = findSelectedKeyInAnotherKeys(keys, ancestorKeys);

    if (!isParentSelected) {
      result.push(item);
    }
  });
  return result;
};

export const findSelectedKeyInAnotherKeys = (keys, selectedKeys) => {
  return selectedKeys.find((key) => {
    return keys.includes(key);
  });
};

export const getOnlyFilesInTree = (treeData = []) => {
  let result = [];

  treeData.forEach((treeNode) => {
    // file
    if (treeNode.isLeaf) {
      result.push(treeNode);
    } else {
      if (treeNode?.children?.length > 0) {
        const allChild = getChildNodesInFolder(treeNode.children);
        const files = allChild.filter((child) => child.data.type === 'file');
        result = result.concat(files);
      }
    }
  });

  return result;
};

export const findListNodeInTree = (treeData, folders) => {
  let result = [];

  folders.forEach((folder) => {
    const node = findNodeTree(treeData, folder.data.itemId);
    if (node) {
      result.push(node);
    }
  });

  return result;
};

export const getAllFilesInFolders = (folders = []) => {
  let result = [];

  folders.forEach((folder) => {
    if (folder?.children?.length > 0) {
      const nodes = getChildNodesInFolder(folder.children);
      result = result.concat(nodes);
    }
  });

  return result.filter((item) => item.data.type === 'file');
};

export const getFormValues = (form) => {
  const {
    effectivePeriod,
    defaultStatus,
    isCreateThumbs,
    autoApplyOrReplaceOnProducts,
  } = form.getFieldsValue();

  const startTime = effectivePeriod?.[0]?.$d;
  const endTime = effectivePeriod?.[1]?.$d;

  const formValues = {
    EffectedDate: startTime ? startTime.toISOString() : null,
    ExpirationDate: endTime ? endTime.toISOString() : null,
    defaultStatus,
    isCreateThumbs,
    autoApplyOrReplaceOnProducts,
  };

  return formValues;
};

export const getVirtualScrollHeight = (largeScreen, multipleImport) => {
  return largeScreen
    ? multipleImport
      ? ASSET_VIRTUAL_SCROLL_HEIGHT.multiImport
      : ASSET_VIRTUAL_SCROLL_HEIGHT.singleImport
    : ASSET_VIRTUAL_SCROLL_HEIGHT.xlHeight;
};

const getExpandedNodeData = async ({
  pathInfo,
  id,
  storageServiceType,
  multipleImport,
}) => {
  const items = await getExpandedData({ id, pathInfo, storageServiceType });
  if (items) {
    return mappingInteropItemsToTreeData(items, multipleImport);
  }

  return [];
};

const getNodesAfterExpandingAll = async ({
  folderNodes,
  id,
  storageServiceType,
  multipleImport,
}) => {
  let result = [];

  for (const node of folderNodes) {
    if (node.data.type === 'folder') {
      const expandedNodes = await getExpandedNodeData({
        pathInfo: node.data.path,
        id,
        storageServiceType,
        multipleImport,
      });

      result.push({
        ...node,
        children: await getNodesAfterExpandingAll({
          folderNodes: expandedNodes,
          id,
          storageServiceType,
          multipleImport,
        }),
        selectable: false,
        checkable: multipleImport ? true : false,
      });
    } else {
      result.push(node);
    }
  }

  return result;
};

export const getTreeDataAfterExpandingAll = async ({
  treeData,
  id,
  storageServiceType,
  multipleImport,
}) => {
  const folderNodes = treeData.filter(
    (folder) => folder.data.type === 'folder'
  );
  const fileNodes = treeData.filter((folder) => folder.data.type === 'file');

  const expandedNodes = await getNodesAfterExpandingAll({
    folderNodes,
    id,
    storageServiceType,
    multipleImport,
  });

  return expandedNodes.concat(fileNodes);
};

export const getAllFoldersInTreeData = (treeData) => {
  let childNodes = [];
  const folders = treeData.filter((folder) => folder.data.type === 'folder');

  folders.forEach((folder) => {
    if (folder?.children?.length > 0) {
      const nodes = getChildNodesInFolder(folder.children);
      childNodes = childNodes.concat(nodes);
    }
  });

  const childFolders = childNodes.filter((item) => item.data.type === 'folder');

  return folders.concat(childFolders);
};

export const getAllExpandedKeys = (treeData) => {
  return getAllFoldersInTreeData(treeData).map((folder) => folder.key);
};

export const getFilesAfterExpandingAll = async ({
  folderNodes,
  id,
  storageServiceType,
  multipleImport,
}) => {
  const expandedNodes = await getNodesAfterExpandingAll({
    folderNodes,
    id,
    storageServiceType,
    multipleImport,
  });

  return getAllFilesInFolders(expandedNodes);
};

export const generateDestinationFolderPath = async (fullPathIds) => {
  let result = '';

  const promises = fullPathIds.map((id) =>
    getFolderShortDetail({
      id,
      IsLoadContent: false,
    })
  );

  const detailFolderResponses = await Promise.all(promises);

  detailFolderResponses.forEach((resp) => {
    const path = resp?.data?.folderName ?? resp?.data?.description;
    result = result + `/${path}`;
  });

  return result;
};

export const getDestinationFolderParams = async (selectedFolder = {}) => {
  if (selectedFolder) {
    const {
      hierarchy,
      id: selectedId,
      folderName,
      description,
    } = selectedFolder;
    const isRootFolder = hierarchy === '/';

    if (isRootFolder)
      return {
        destinationPath: `/${folderName ?? description}`,
        destinationFolderId: selectedId,
      };

    const parentIds = hierarchy
      ? hierarchy
          .split('/')
          .filter(Boolean)
          .map((id) => parseInt(id))
      : [];
    const fullPathIds = parentIds.concat(selectedId);

    const destinationPath = await generateDestinationFolderPath(fullPathIds);

    return {
      destinationPath,
      destinationFolderId: selectedId,
    };
  }

  return {};
};
