import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Menu,
  Dropdown,
  Typography,
  Button,
  notification,
  message,
} from 'antd';
import { FileOutlined } from '@ant-design/icons';
import messages from 'i18n/messages/home';
import { ReactComponent as IconDownloadSheet } from 'common/components/button/svg-icons/ICO_10.46.svg';
import RibbonButton from 'common/components/button/RibbonButton';
import { saveFile } from 'utils/saveFile';
import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/member-profile';
import { INFORMATION_SHEET_OPTIONS } from 'static/Constants';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as memberService from 'services/members';
import './DownloadInformation.less';

const { Text } = Typography;

const InformationSheet = ({ text, disabled }) => {
  return (
    <Button
      icon={<FileOutlined />}
      disabled={disabled}
      className='grid-member__item-dropdown deleted-border'
    >
      <Text>{text}</Text>
    </Button>
  );
};

const menuOptions = (memberIds, setDownloadLoading, companyInfo, intl) => {
  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };
  const downloadInformationSheet = async (sheet) => {
    setDownloadLoading('loading');
    try {
      if (!companyInfo) {
        const response = await memberService.downloadInformationSheetGridView({
          sheetType: sheet,
          memberIds,
        });
        const { isSuccess, data, message } = response;
        if (isSuccess) {
          if (data?.url) {
            saveFile(data?.url);
          }
          if (message) {
            setDownloadLoading('idle');
            notification.warning({
              message,
            });
          } else {
            setDownloadLoading('success');
          }
        } else {
          callApiErrorHandler(message);
        }
      } else {
        const memberId = companyInfo?.id;
        const response = await memberService.downloadMemberInformationSheet({
          sheetType: sheet,
          memberId,
        });
        const { isSuccess, data, message } = response;
        if (isSuccess) {
          if (data?.url) {
            saveFile(data?.url);
          }
          if (message) {
            setDownloadLoading('idle');
            notification.warning({
              message,
            });
          } else {
            setDownloadLoading('success');
          }
        } else {
          callApiErrorHandler(message);
        }
      }
    } catch (error) {
      callApiErrorHandler(error);
    } finally {
      setDownloadLoading('idle');
    }
  };
  return (
    <Menu>
      {INFORMATION_SHEET_OPTIONS.map((sheet, index) => {
        return (
          <Menu.Item
            key={index}
            style={{ backgroundColor: 'inherit' }}
            onClick={() => downloadInformationSheet(sheet)}
          >
            <InformationSheet text={sheet} />
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const DownloadInformation = ({ companyInfo,entityType }) => {
  const memberIds = useSelector(gridSelector.makeSelectItemsSelection());
  const intl = useIntl();
  const [downloadLoading, setDownloadLoading] = useState('idle');
  const key = 'download';

  const disabledDropdown =
    companyInfo?.isLoadedMemberInformationSheet === true
      ? false
      : memberIds.length > 0
      ? false
      : true;
  useEffect(() => {
    if (downloadLoading === 'success') {
      message.success({
        content: intl.formatMessage(Messages.downloadInformationSheetSuccess),
        key,
        duration: 2,
      });
    }
    if (downloadLoading === 'loading') {
      message.loading({
        content: intl.formatMessage(Messages.downloadingInformationSheet),
        key,
      });
    }
  }, [downloadLoading]);

  return (
    <Dropdown
      overlay={menuOptions(memberIds, setDownloadLoading, companyInfo, intl)}
      placement='bottomCenter'
      disabled={true}
      // 5757: Temporarily disabled
      // disabled={disabledDropdown}
      arrow
    >
      <RibbonButton
        size={ entityType === 'MEM' ? 'small' : 'large' }
        icon={<IconDownloadSheet style={{ height: 26 }} />}
        label={messages.downloadSheet}
      ></RibbonButton>
    </Dropdown>
  );
};

DownloadInformation.propTypes = {};

export default DownloadInformation;
