import { parseToHourMinuteSecond } from 'utils/parser';

import { DEFAULT_TIME_TO_RESEND_OTP } from '../constants';

import { isArray } from 'lodash';

export const sessionTimeFormatter = (remainingTime) => {
  const parsedTime = parseToHourMinuteSecond(remainingTime);

  if (remainingTime === 0) {
    return `MFA Login session ended`;
  } else {
    return `MFA login session expired in ${parsedTime?.minutes}:${parsedTime?.seconds}`;
  }
};

export const resendTimeFormatter = (remainingTime) => {
  const parsedTime = parseToHourMinuteSecond(remainingTime);

  return `${parsedTime?.seconds}s`;
};

export const getOtpTimeFormartter = (remainingTime) => {
  const parsedTime = parseToHourMinuteSecond(remainingTime);
  return `Get OTP in  ${parsedTime?.seconds}s`;
};

export const getNewResendOtpTime = (secondsToResend) => {
  let newExpTime = new Date();
  newExpTime.setSeconds(
    newExpTime.getSeconds() + (secondsToResend || DEFAULT_TIME_TO_RESEND_OTP)
  );
  return newExpTime.toISOString();
};

export const mapOtp = (otpArray) => {
  if (isArray(otpArray)) {
    return otpArray.join('');
  } else {
    return '';
  }
};
