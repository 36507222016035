export const US_VALUE = 'United States';
export const US_VALUE_LOWERCASE = 'united states';

export const WORLDWIDE_VALUE = 'Worldwide';
export const WORLDWIDE_LOWERCASE = 'worldwide';

export const NONE_VALUE_LOWERCASE = 'none';

export const REGIONAL_ALLERGEN = [
  {
    label: 'United States',
    value: US_VALUE,
  },
  {
    label: 'Worldwide',
    value: WORLDWIDE_VALUE,
  },
];

export const REQUIRED_FIELDS = ['AllergenTypeCode', 'LevelOfContainmentCode'];
export const CHECKABLE_DISABLED_FIELDS = ['ContainmentSource'];

export const DEFAULT_US_ALLERGENS = [
  {
    allergenType: 'Eggs',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Soy',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Wheat',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Milk',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Fish',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Crustaceans',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Sesame',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Peanuts',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Tree Nuts',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Oats',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Seeds',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Corn',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
  {
    allergenType: 'Shellfish',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'United States',
    isPublish: true,
  },
];

export const DEFAULT_WW_ALLERGENS = [
  {
    allergenType: 'Cereals and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Crustaceans and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Eggs and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Fish and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Peanuts and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Soybean and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Milk and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Sesame Seeds and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Sulfur Dioxide and Sulfits',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Celery and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Mustard and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Lupine and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Molluscs and its derivatives',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Shell nuts and by-products',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
  {
    allergenType: 'Bee Pollen/ Propolis',
    isPresent: false,
    levelOfContainment: null,
    containmentSource: null,
    regional: 'Worldwide',
    isPublish: true,
  },
];
