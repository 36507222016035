import { sleep } from './delay';

/**
 * !select element by class name
 * @param {*} name
 * @returns
 */
const $class = (name) => {
  return document.getElementsByClassName(name);
};

/**
 * !select element by id
 * @param {*} name
 * @returns
 */
const $id = (name) => {
  return document.getElementById(name);
};

/**
 * * get center point of element
 * @param {*} element
 * @returns
 */
const getPositionAtCenter = (element) => {
  const { top, left, width, height } = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
};

/**
 * * get distance between two element
 * @param {*} $elementA
 * @param {*} $elementB
 * @returns
 */
const getDistanceBetweenElements = ($elementA, $elementB) => {
  const aPosition = getPositionAtCenter($elementA);
  const bPosition = getPositionAtCenter($elementB);

  return [aPosition.x - bPosition.x, aPosition.y - bPosition.y];
};

/**
 * * get cover range of element
 * @param {*} element
 * @returns
 */
const getCoverRangeComponent = (element, scale = 1) => {
  const { top, left, width, height } = element.getBoundingClientRect();

  const scaledResult = {
    top: top / scale,
    left: left / scale,
    width: width / scale,
    height: height / scale,
  };

  return {
    x: [scaledResult.left, scaledResult.left + scaledResult.width],
    y: [scaledResult.top, scaledResult.top + scaledResult.height],
    offset: { left: scaledResult.left, top: scaledResult.top },
    size: { width: scaledResult.width, height: scaledResult.height },
  };
};

/**
 * * get cover range of element
 * @param {*} element
 * @returns
 */
const getFullSizeComponent = (element) => {
  const { top, left, width, height } = element.getBoundingClientRect();

  return {
    x: [left, left + width],
    y: [top, top + height],
    offset: { left, top },
    size: { width, height },
  };
};

/**
 * * check if component overlap an other component
 * @param {*} elemArray
 * @param {*} $elementCover
 * @returns
 */
const checkOverlapComponents = (elemArray, $elementCover) => {
  let overLapArray = [];

  for (let i = 0; i < elemArray.length; i++) {
    const { offset: currElemOffset, size: currElemSize } =
      getCoverRangeComponent(elemArray[i]);

    const { offset: coverElemOffset, size: coverElemSize } =
      getCoverRangeComponent($elementCover);

    var a_sx = currElemOffset.left;
    var a_ex = a_sx + currElemSize.width;

    var b_sx = coverElemOffset.left;
    var b_ex = b_sx + coverElemSize.width;

    var a_sy = currElemOffset.top;
    var a_ey = a_sy + currElemSize.height;

    var b_sy = coverElemOffset.top;
    var b_ey = b_sy + coverElemSize.height;

    const sastified_y =
      (a_sy <= b_sy && a_ey >= b_sy) ||
      (a_sy >= b_sy && a_ey <= b_ey) ||
      (a_sy <= b_ey && a_ey >= b_ey) ||
      (a_sy <= b_sy && a_ey >= b_ey);

    const sastified_x =
      (a_sx <= b_sx && a_ex >= b_sx) ||
      (a_sx >= b_sx && a_ex <= b_ex) ||
      (a_sx <= b_ex && a_ex >= b_ex) ||
      (a_sx <= b_sx && a_ex >= b_ex);

    if (sastified_x && sastified_y) {
      overLapArray.push(true);
    } else {
      overLapArray.push(false);
    }
  }

  return overLapArray;
};

/**
 * * check if component is inside a component
 * @param {*} elemArray
 * @param {*} $elementCover
 * @returns
 */
const checkComponentInside = ({
  elements: elemArray,
  elementCover: $elementCover,
  tolerance = 0,
  futureMove = {
    x: 0,
    y: 0,
  },
  scale = 1,
}) => {
  let overLapArray = [];

  for (let i = 0; i < elemArray.length; i++) {
    const { offset: currElemOffset, size: currElemSize } = elemArray[i]
      ?.nodeType
      ? getCoverRangeComponent(elemArray[i], scale)
      : elemArray[i];

    const { offset: coverElemOffset, size: coverElemSize } =
      getCoverRangeComponent($elementCover, scale);

    var a_sx = currElemOffset.left + futureMove?.x;
    var a_ex = a_sx + currElemSize.width;

    var b_sx = coverElemOffset.left + tolerance;
    var b_ex = coverElemOffset.left + coverElemSize.width - tolerance;

    var a_sy = currElemOffset.top + futureMove?.y;
    var a_ey = a_sy + currElemSize.height;

    var b_sy = coverElemOffset.top + tolerance;
    var b_ey = coverElemOffset.top + coverElemSize.height - tolerance;

    const sastified_y = a_sy >= b_sy && a_ey <= b_ey;
    const sastified_x = a_sx >= b_sx && a_ex <= b_ex;
    const touchLeft = a_sx <= b_sx;
    const touchRight = a_ex >= b_ex;
    const touchTop = a_sy <= b_sy;
    const touchBottom = a_ey >= b_ey;
    const overLeft = b_sx - a_sx;
    const overRight = a_ex - b_ex;
    const overTop = b_sy - a_sy;
    const overBottom = a_ey - b_ey;

    const touchResult = {
      touchLeft,
      touchRight,
      touchTop,
      touchBottom,
      overLeft,
      overRight,
      overTop,
      overBottom,
    };

    if (sastified_x && sastified_y) {
      overLapArray.push({
        isInside: true,
        ...touchResult,
      });
    } else {
      overLapArray.push({
        isInside: false,
        ...touchResult,
      });
    }
  }

  return overLapArray;
};

const smoothScrollInElement = (element) => {
  const scrollTo = async (getTo, duration, delay) => {
    if (delay) {
      await sleep(delay);
    }
    const topPos = element.offsetTop;

    const to = getTo(topPos);
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easyTop(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const easyTop = (now, start, change, duration) => {
    now /= duration / 2;
    if (now < 1) return (change / 2) * now * now + start;
    now--;
    return (-change / 2) * (now * (now - 2) - 1) + start;
  };

  return { scrollTo };
};

export {
  $id,
  $class,
  getDistanceBetweenElements,
  checkOverlapComponents,
  checkComponentInside,
  getCoverRangeComponent,
  smoothScrollInElement,
};
