import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Typography } from 'antd';

import { EditContainer } from 'pages/qa-spec/components';
import {
  Form,
  WithLoading,
  SectionWrapper,
  CustomNotification,
} from 'common/components';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useParams } from 'react-router-dom';

import { useGetQaPackagingTabData, useSaveQaPackagingTab } from './queries';

import { BasicFormItemList } from 'common/components/form/basic-form';

import {
  useGetQaPackagingFormDefine,
  useGetSnapshotQaPackaging,
} from './hooks';

import { qaSpecLinkToDocCer } from 'services/product';
import UploadFilePackaging from './UploadFilePackaging';
import { getCertificateParams, parsePackagingData } from './utils';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const QaPackaging = (props) => {
  const { isTabActive, productFull } = props;

  const editHook = useState(false);
  const [isEdit] = editHook;

  const [uploadDocumentModalVisible, setUploadDocumentModalVisible] =
    useState(false);
  const [uploadModalTitle, setUploadModalTitle] = useState('');

  const refEditContainer = useRef();
  const { id: productId } = useParams();
  const [formInst] = Form.useForm();

  const {
    data,
    isLoading: loading,
    refetch: refetchQaPackagingTabData,
  } = useGetQaPackagingTabData(
    { productId },
    { enabled: false, retry: false, staleTime: 0 }
  );

  useEffect(() => {
    if (isTabActive && !isEdit) {
      refetchQaPackagingTabData();
    }
  }, [isTabActive, isEdit, refetchQaPackagingTabData]);

  const handleSaveQaPackagingTab = useSaveQaPackagingTab();
  const { isLoading: saveLoading } = handleSaveQaPackagingTab;

  const { handleUpdateIXONEShield } = useUpdateIXONEShield();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const { data: snapshotData, isLoading: isLoadingSnapshotPackaging } =
    useGetSnapshotQaPackaging({
      enabled: !!productId && isToggleSnapshot,
      productId,
    });

  const parsedData = parsePackagingData(data);

  useEffect(() => {
    formInst.setFieldsValue(parsedData);
  }, [JSON.stringify(data)]);

  const handleSave = async (toggleEditMode, ixoneIgnore) => {
    formInst
      .validateFields()
      .then(async (values) => {
        const certificateParams = getCertificateParams(values);

        const formData = { ...values, ...certificateParams };
        handleSaveQaPackagingTab.mutate(
          {
            productId,
            formData: formData,
          },
          {
            onSuccess: async () => {
              toggleEditMode();
              await handleUpdateIXONEShield(ixoneIgnore);
            },
          }
        );
      })
      .catch((e) => {
        if (e?.errorFields?.length > 0) {
          CustomNotification.error(
            'Please resolve errors in the Labeling tab.'
          );
        }
      });
  };

  const addDocument = async ({ successCallback, ...payload }) => {
    const params = {
      productItemId: productId,
      linkType: 'DOCUMENT',
      ...payload,
    };
    const { isSuccess } = await qaSpecLinkToDocCer(params);
    if (isSuccess) {
      setUploadDocumentModalVisible(false);
      successCallback();
      CustomNotification.success('Update document successfully.');
    } else {
      setUploadDocumentModalVisible(false);
      CustomNotification.error('Update failed.');
    }
  };

  const handleCancel = () => {
    const parsedData = parsePackagingData(data);
    formInst.setFieldsValue(parsePackagingData(parsedData));
  };

  const handleToggleUploadDocumentModal = () => {
    setUploadDocumentModalVisible((prev) => !prev);
  };

  const {
    formProductDefine,
    formLeftBottomDefine,
    formProductClaimFirstSectionDefine,
    formRightSecondSection,
    formDietaryThirdSectionDefine,
    formRightFourthSectionDefine,
  } = useGetQaPackagingFormDefine({
    handleToggleUploadDocumentModal: useMemo(
      () => handleToggleUploadDocumentModal,
      []
    ),
    supplierModifiedData: parsedData,
    snapshotData,
    setUploadModalTitle,
    productFull,
  });

  const onFormChange = (changedValues, allValues) => {
    if (
      changedValues.hasOwnProperty('recyclable') &&
      (changedValues?.recyclable === 'No' || !changedValues?.recyclable)
    ) {
      formInst.setFieldsValue({ recyclableTypes: [] });
    }
    if (
      changedValues.hasOwnProperty('bilingual') &&
      (changedValues?.bilingual === 'No' || !changedValues?.bilingual)
    ) {
      formInst.setFieldsValue({ secondaryLanguage: [] });
    }
  };

  return (
    <IXONECertificationWrapper
      onConfirm={(ixoneIgnore) => {
        const toggleEditMode = refEditContainer?.current?.onToggleEditMode;
        handleSave(toggleEditMode, ixoneIgnore);
      }}
      showModal
      type={TYPE_VIEW.EDIT_PRODUCT}
    >
      {(handleConfirmIXONE) => (
        <Form
          className='qa-packaging'
          form={formInst}
          style={{ paddingRight: 5 }}
          layout='vertical'
          onValuesChange={onFormChange}
        >
          <WithLoading loading={loading || saveLoading}>
            <EditContainer
              type={EDIT_SECTION.QA_PACKAGING}
              productId={productId}
              handleCancel={handleCancel}
              handleSave={async () => {
                handleConfirmIXONE({
                  originalData: parsedData,
                  changedFields: await formInst.validateFields(),
                });
              }}
              float
              ref={refEditContainer}
            >
              {(isEdit) => {
                return (
                  <EditTabSectionWrapper.CheckerWrapper
                    isEdit={isEdit}
                    type={EDIT_SECTION.QA_PACKAGING}
                  >
                    <Row gutter={[20, 20]}>
                      <Col xs={12}>
                        <Row>
                          <Col xs={24}>
                            <SectionWrapper title='Product'>
                              <BasicFormItemList
                                allowEdit={isEdit}
                                formDefine={formProductDefine}
                                style={{ paddingTop: 8 }}
                                labelFn={labelFn}
                                isLoadingSnapshot={isLoadingSnapshotPackaging}
                              />
                            </SectionWrapper>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col xs={24}>
                            <BasicFormItemList
                              allowEdit={isEdit}
                              formDefine={formLeftBottomDefine}
                              labelFn={labelFn}
                              isLoadingSnapshot={isLoadingSnapshotPackaging}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col xs={24}>
                            <SectionWrapper title='Product Claims'>
                              <BasicFormItemList
                                allowEdit={isEdit}
                                formDefine={formProductClaimFirstSectionDefine}
                                style={{ paddingTop: 8 }}
                                labelFn={labelFn}
                                isLoadingSnapshot={isLoadingSnapshotPackaging}
                              />
                            </SectionWrapper>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col xs={24}>
                            <BasicFormItemList
                              allowEdit={isEdit}
                              formDefine={formRightSecondSection}
                              labelFn={labelFn}
                              isLoadingSnapshot={isLoadingSnapshotPackaging}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col flex='540px'>
                            <SectionWrapper title='Dietary Certificate'>
                              <BasicFormItemList
                                allowEdit={isEdit}
                                formDefine={formDietaryThirdSectionDefine}
                                style={{ paddingTop: 20 }}
                                labelFn={labelFn}
                                isLoadingSnapshot={isLoadingSnapshotPackaging}
                              />
                            </SectionWrapper>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: 16 }}>
                          <Col xs={24}>
                            <BasicFormItemList
                              allowEdit={isEdit}
                              formDefine={formRightFourthSectionDefine}
                              labelFn={labelFn}
                              isLoadingSnapshot={isLoadingSnapshotPackaging}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </EditTabSectionWrapper.CheckerWrapper>
                );
              }}
            </EditContainer>
          </WithLoading>
          {uploadDocumentModalVisible && (
            <UploadFilePackaging
              productId={productId}
              title={uploadModalTitle}
              addDocument={addDocument}
              visible={uploadDocumentModalVisible}
              setVisible={setUploadDocumentModalVisible}
            />
          )}
        </Form>
      )}
    </IXONECertificationWrapper>
  );
};

const labelFn = (labelText) => {
  return <Typography.Text strong>{labelText && labelText}</Typography.Text>;
};

export default QaPackaging;
