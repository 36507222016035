// bp 14/12/2020: consider to remove pathname because when adding new pathname, this function will miss cover
const checkIsOwner = (data, userId) => {
  if (data?.type === 'member') return true;
  if (data?.hasOwnProperty('isOwner')) {
    return data?.isOwner;
  }
  return userId === data?.ownerId;
};

export { checkIsOwner };
