import React from 'react';

import SnapshotCellWrapper from './SnapshotCellWrapper';
import CountryEditorRender from './CountryEditorRender';

import { isEqual } from 'lodash';
import { formatCompareValue } from 'pages/qa-spec/utils';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

const CountryEditorRenderSnapshot = (props) => {
  const { column, rowIndex, data, context } = props;

  const { snapshotGridValues } = context || {};

  const useSnapshot = useCheckSnapshotForRetailer();

  const cellSnapshotValue = formatCompareValue(
    snapshotGridValues?.[rowIndex]?.[column.colId]
  );

  const rawCellValue = formatCompareValue(data?.[column.colId]);

  const isHighlightChange =
    !isEqual(rawCellValue, cellSnapshotValue) && useSnapshot;

  return (
    <SnapshotCellWrapper
      isHighlightChange={isHighlightChange}
      snapshotValueRender={`${cellSnapshotValue}`}
    >
      <CountryEditorRender {...props} />
    </SnapshotCellWrapper>
  );
};

export default CountryEditorRenderSnapshot;
