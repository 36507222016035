import React from 'react';
import PropTypes from 'prop-types';

import { FolderOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import { dialogFunction } from 'common/components';

import * as folderService from 'services/folder';

import Messages from 'i18n/messages/home';
import { injectIntl } from 'react-intl';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';

const DeleteFolderControl = (props) => {
  const { intl, selectedItemList, isSectionDisabled } = props;

  const clickDeleteHandler = () => {
    showConfirmDialog();
  };

  const showConfirmDialog = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.deleteFromFoldersConfirm),
      onOk: callApiDelete,
    });
  };

  const callApiDelete = () => {
    const itemList = getItemList(selectedItemList);

    folderService
      .deleteInAllFolders(itemList)
      .then((response) => {
        const { isSuccess } = response;
        if (isSuccess) {
          showSuccessMessageDialog();
        } else {
          callApiErrorHandler();
        }
      })
      .catch(() => {
        callApiErrorHandler();
      });
  };

  const getItemList = (itemList) => {
    return itemList?.map((item) => {
      const { id, type } = item;
      return {
        id,
        type,
      };
    });
  };

  const showSuccessMessageDialog = () => {
    dialogFunction({
      type: 'success',
      content: intl.formatMessage(Messages.deleteFromFoldersSuccess),
      cancelButtonProps: {
        style: { display: 'none' },
      },
    });
  };

  const callApiErrorHandler = () => {
    dialogFunction({
      type: 'error',
      content: intl.formatMessage(Messages.deleteFromFoldersError),
      cancelButtonProps: {
        style: { display: 'none' },
      },
    });
  };

  const EDIT_FOLDERS = [
    { action: ABILITY_ACTION.EDIT, subject: ABILITY_SUBJECT.FOLDER },
  ];

  const disabled = !useCheckPermissions(EDIT_FOLDERS) || isSectionDisabled;

  return (
    <ButtonSmallIcon
      icon={<FolderOutlined />}
      label={Messages.deleteFromFolders}
      className='button-item-dropdown'
      onClick={clickDeleteHandler}
      disabled={disabled}
    />
  );
};

DeleteFolderControl.propTypes = {
  /**
   * Current selected items
   */
  selectedItemList: PropTypes.arrayOf(PropTypes.object),
  /**
   * Disable state of ribbon section
   */
  isSectionDisabled: PropTypes.bool,
};

export default injectIntl(DeleteFolderControl);
