/**
 * Create the store with dynamic reducers
 */

import { configureStore } from '@reduxjs/toolkit';

import { applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import globalSaga from 'redux/global/saga';
import brandingSaga from 'redux/branding/saga';
import ribbonSaga from 'redux/ribbon/saga';
import userSaga from 'redux/user/saga';
import chatSaga from 'common/components/message/controller/saga';
import ssoProductSaga from 'pages/sso-product/controllers/saga';

export default function configureAppStore(initialState = {}, history) {
  const reduxSagaMonitorOptions = {};

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
    ],
    initialState,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(enhancers),
  });

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  // run global, branding saga
  store.runSaga(brandingSaga);
  store.runSaga(globalSaga);
  store.runSaga(ribbonSaga);
  store.runSaga(chatSaga);
  store.runSaga(userSaga);
  store.runSaga(ssoProductSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
