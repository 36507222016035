/*
 * Grid View Messages
 *
 * This contains all the text for the GridView component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.digitalAsset.assetDetail';

export default defineMessages({
  assetName: {
    id: `${scope}.assetName`,
    defaultMessage: 'Asset Name',
  },
  assetStatus: {
    id: `${scope}.assetStatus`,
    defaultMessage: 'Asset Status',
  },
  assetDescription: {
    id: `${scope}.assetDescription`,
    defaultMessage: 'Description',
  },
  additionalSearchTags: {
    id: `${scope}.additionalSearchTags`,
    defaultMessage: 'Additional Search Tags',
  },
  classifications: {
    id: `${scope}.classifications`,
    defaultMessage: 'Classifications',
  },
  channelOutput: {
    id: `${scope}.channelOutput`,
    defaultMessage: 'Channel Output',
  },
  clippingPath: {
    id: `${scope}.clippingPath`,
    defaultMessage: 'Clipping Path',
  },
  colorDepth: {
    id: `${scope}.colorDepth`,
    defaultMessage: 'Color Depth',
  },
  colorModel: {
    id: `${scope}.colorModel`,
    defaultMessage: 'Color Model',
  },
  cropRange: {
    id: `${scope}.cropRange`,
    defaultMessage: 'Crop Range',
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'Duration',
  },
  exifData: {
    id: `${scope}.exifData`,
    defaultMessage: 'EXIF Data',
  },
  fileName: {
    id: `${scope}.fileName`,
    defaultMessage: 'File Name',
  },
  fileSize: {
    id: `${scope}.fileSize`,
    defaultMessage: 'File Size',
  },
  fileType: {
    id: `${scope}.fileType`,
    defaultMessage: 'File Type',
  },
  fps: {
    id: `${scope}.fps`,
    defaultMessage: 'Fps',
  },
  bitRate: {
    id: `${scope}.bitRate`,
    defaultMessage: 'Bit Rate',
  },
  frameRate: {
    id: `${scope}.frameRate`,
    defaultMessage: 'Frame Rate',
  },
  frameSize: {
    id: `${scope}.frameSize`,
    defaultMessage: 'Frame Size',
  },
  sampleRate: {
    id: `${scope}.sampleRate`,
    defaultMessage: 'Sample Rate',
  },
  chanelOutput: {
    id: `${scope}.chanelOutput`,
    defaultMessage: 'Chanel Output',
  },
  height: {
    id: `${scope}.height`,
    defaultMessage: 'Height',
  },
  lastModified: {
    id: `${scope}.lastModified`,
    defaultMessage: 'Last Modified',
  },
  loadedDate: {
    id: `${scope}.loadedDate`,
    defaultMessage: 'Loaded Date',
  },
  mediaFormat: {
    id: `${scope}.mediaFormat`,
    defaultMessage: 'Media Format',
  },
  owner: {
    id: `${scope}.owner`,
    defaultMessage: 'Owner',
  },
  pageCount: {
    id: `${scope}.pageCount`,
    defaultMessage: 'Page Count',
  },
  resolution: {
    id: `${scope}.resolution`,
    defaultMessage: 'Resolution',
  },
  width: {
    id: `${scope}.width`,
    defaultMessage: 'Width',
  },
  specificInformationSection: {
    id: `${scope}.specificInformationSection`,
    defaultMessage: 'Specific Information Section',
  },
  gsId: {
    id: `${scope}.gsId`,
    defaultMessage: 'Link Id',
  },
  gsCompliantFileName: {
    id: `${scope}.gsCompliantFileName`,
    defaultMessage: 'GS1 Compliant File Name',
  },
  gsAngle: {
    id: `${scope}.gsAngle`,
    defaultMessage: 'Angle',
  },
  gsFacing: {
    id: `${scope}.gsFacing`,
    defaultMessage: 'Facing',
  },
  gsImageProperties: {
    id: `${scope}.gsImageProperties`,
    defaultMessage: 'Image Properties',
  },
  gsImageType: {
    id: `${scope}.gsImageType`,
    defaultMessage: 'Image Type',
  },
  gsLanguage: {
    id: `${scope}.gsLanguage`,
    defaultMessage: 'Language',
  },
  gsPackageLevel: {
    id: `${scope}.gsPackageLevel`,
    defaultMessage: 'Package Level',
  },
  gsPackagingDisposition: {
    id: `${scope}.gsPackagingDisposition`,
    defaultMessage: 'Packaging Disposition',
  },
  gsAssociationType: {
    id: `${scope}.gsAssociationType`,
    defaultMessage: 'Association Type',
  },
  gsGTIN: {
    id: `${scope}.gsGTIN`,
    defaultMessage: 'GTIN',
  },
  gsBack: {
    id: `${scope}.gsBack`,
    defaultMessage: 'Back to View',
  },
  gsConfirmRemove: {
    id: `${scope}.gsConfirmRemove`,
    defaultMessage: 'Are you sure you want to remove this item?',
  },
  isShowcase: {
    id: `${scope}.isShowcase`,
    defaultMessage: 'Showcase',
  },
  visibility: {
    id: `${scope}.visibility`,
    defaultMessage: 'Visibility',
  },
  assetNamePlaceholder: {
    id: `${scope}.placeholder.assetName`,
    defaultMessage: 'Please input asset name',
  },
  assetNameRequiredMessage: {
    id: `${scope}.errorMessage.assetNameRequired`,
    defaultMessage: 'Please input asset name',
  },
  assetNameWhiteSpaceMessage: {
    id: `${scope}.errorMessage.assetNameWhiteSpace`,
    defaultMessage: 'Only has whitespace is not accepted',
  },
});
