import React, { useMemo } from 'react';

import classnames from 'classnames';
import {
  Empty,
  Button,
  Checkbox,
  InputNumber,
  Row,
  Col,
  Typography,
  Space,
} from 'antd';

import {
  Form,
  StyledModal,
  WithLoading,
  FullWidthSpace,
} from 'common/components';
import {
  MinimumRequirementsRow,
  MinimumRequirementsText,
  MinimumRequirementsWrapperContent,
} from '../minimum-requirements';

import { mappingGridProperties } from 'pages/home/ribbon/components/sections/customize-grid/utils';

import { useValidateMinimumRequirement } from 'hooks/useValidateMinimumRequirement';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/home';

import { useSelector } from 'react-redux';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';
import { useGetProductFullView } from 'hooks';
import { MINIMUM_REQUIREMENT_ENTITY_TYPE } from 'pages/minimum-requirements/constants';

import './PreviewRequirementsModal.less';

const { Title } = Typography;

const PreviewRequirementsModal = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { visible, setVisible, entity, entityType } = props;

  const {
    validateMinimumRequirements,
    propertiesRequired,
    additionalRequirements,
    validateLoading,
    fetchDataLoading,
    onSubmitRequiredPropertiesForm,
    submitLoading,
    submitDisabled,
    hasAdditionalRequirementsError,
    appliedTo,
  } = useValidateMinimumRequirement({
    visible,
    closeModal: () => handleCancel(),
    entityId: entity?.id,
    entityType,
    form,
  });

  const isProductEntity =
    entityType?.toLowerCase() === MINIMUM_REQUIREMENT_ENTITY_TYPE.PRODUCT;

  const productId =
    hasAdditionalRequirementsError && isProductEntity ? entity?.id : null;
  const { productFull } = useGetProductFullView({ productId });

  const itemCurrentSelection = useSelector(
    gridViewSelectors.makeSelectItemCurrentSelection()
  );

  const mappingProperties = useMemo(
    () =>
      isProductEntity
        ? mappingGridProperties(propertiesRequired)
        : propertiesRequired,
    [propertiesRequired, isProductEntity]
  );

  const onClickValidateBtn = () => {
    validateMinimumRequirements();
  };

  const onClickSubmitBtn = () => {
    onSubmitRequiredPropertiesForm();
  };

  const onClickCancelBtn = () => {
    handleCancel();
  };

  const handleCancel = () => {
    form && form.resetFields();
    setVisible(false);
  };

  //* RENDER
  const renderModalFooter = () => {
    const ValidateBtn = Button;
    const CancelBtn = Button;
    const SubmitBtn = Button;

    return (
      <Row>
        <Col flex={0}>
          <ValidateBtn
            type='primary'
            loading={validateLoading}
            onClick={onClickValidateBtn}
          >
            {intl.formatMessage(messages.previewRequirementsValidateButton)}
          </ValidateBtn>
        </Col>
        <Col flex={1}></Col>
        <Col flex={0}>
          <CancelBtn onClick={onClickCancelBtn}>
            {intl.formatMessage(messages.previewRequirementsCancelButton)}
          </CancelBtn>
          <SubmitBtn
            type='primary'
            onClick={onClickSubmitBtn}
            loading={submitLoading}
            disabled={submitDisabled}
          >
            {intl.formatMessage(messages.previewRequirementsSubmitButton)}
          </SubmitBtn>
        </Col>
      </Row>
    );
  };

  const renderEmpty = () => {
    return (
      <div className='requirement-preview-modal__empty-wrapper'>
        <Empty className='requirement-preview-modal__empty-icon' />
      </div>
    );
  };

  const renderRequireProperties = (propertiesRequired, form) => {
    const hasBrickCode = propertiesRequired.some((property) => {
      return property.fieldName === 'brickCode';
    });

    if (propertiesRequired?.length > 0) {
      return propertiesRequired.map((item) => {
        return (
          <MinimumRequirementsRow
            cols={[12, 12]}
            key={item.id}
            status={item.checkStatus}
            errorComponent={item.errorComponent}
            item={item}
            entityData={entity?.data}
            updateFormValue={form?.setFieldsValue}
            hasBrickCode={hasBrickCode}
          >
            <MinimumRequirementsText
              text={item.fullPathDisplayName}
              isError={item.checkStatus === 'error'}
            />
          </MinimumRequirementsRow>
        );
      });
    } else {
      return renderEmpty();
    }
  };

  const renderRequirePropertiesByModule = (propertiesRequired) => {
    if (propertiesRequired?.length > 0) {
      return propertiesRequired.map((item) => {
        return (
          <MinimumRequirementsRow
            key={item.moduleDisplayName}
            cols={[24, 0]}
            showCheck={false}
          >
            <Row>
              <Col span={24}>
                <MinimumRequirementsText
                  className='minimum-requirement__subtitle'
                  text={item.moduleDisplayName}
                />
              </Col>
            </Row>
            {renderRequireProperties(item?.moduleProperties, form)}
          </MinimumRequirementsRow>
        );
      });
    } else {
      return renderEmpty();
    }
  };

  const modalProps = {
    visible,
    wrapClassName: 'requirement-preview-modal',
    title: entity
      ? intl.formatMessage(messages.previewRequirementsModalTitle[entity.type])
      : '',
    width: 750,
    bodyStyle: {
      position: 'relative',
      height: '80vh',
    },
    centered: true,
    closable: true,
    maskClosable: false,
    onCancel: handleCancel,
    footer: renderModalFooter(),
  };

  const renderPrivateLabel = () => {
    if (!isProductEntity) return null;

    return (
      <MinimumRequirementsRow>
        <Space>
          <MinimumRequirementsText text='Private Label' />
          <Checkbox checked={itemCurrentSelection?.isPrivateLabel} />
        </Space>
      </MinimumRequirementsRow>
    );
  };

  return (
    <StyledModal {...modalProps}>
      {fetchDataLoading ? (
        <WithLoading loading />
      ) : (
        <FullWidthSpace size={20} id='validate-minimum-requirement'>
          <Title level={4} style={{ margin: 0 }}>
            {entity?.gtin ? `GTIN ${entity?.gtin} - ` : ''}
            {entity?.name ? entity?.name : ''}
          </Title>

          <MinimumRequirementsWrapperContent title='Scope'>
            <MinimumRequirementsRow>
              <MinimumRequirementsText text={appliedTo} />
            </MinimumRequirementsRow>
            {renderPrivateLabel()}
          </MinimumRequirementsWrapperContent>

          <Form form={form}>
            <MinimumRequirementsWrapperContent title='Required Properties'>
              {isProductEntity
                ? renderRequirePropertiesByModule(mappingProperties)
                : renderRequireProperties(mappingProperties)}
            </MinimumRequirementsWrapperContent>
          </Form>

          <MinimumRequirementsWrapperContent title='Additional Requirements'>
            {additionalRequirements.length
              ? additionalRequirements.map((item) => (
                  <MinimumRequirementsRow
                    key={item.id}
                    cols={[12, 12]}
                    status={item.checkStatus}
                    errorComponent={item.errorComponent}
                    item={item}
                    entityData={entity?.data}
                    productDetail={productFull}
                  >
                    <Row gutter={[8, 0]}>
                      <Col span={15}>
                        <Checkbox
                          checked={item.active}
                          className='requirement-preview-modal__checkbox'
                        >
                          <MinimumRequirementsText
                            text={item.name}
                            isError={item.checkStatus === 'error'}
                          />
                        </Checkbox>
                      </Col>
                      <Col span={9}>
                        {item.value !== undefined ? (
                          <InputNumber
                            value={item.value}
                            readOnly={true}
                            defaultValue={0}
                            className={classnames({
                              'requirement-preview-modal__input': true,
                              'requirement-preview-modal__input--error':
                                item.checkStatus === 'error',
                            })}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </MinimumRequirementsRow>
                ))
              : renderEmpty()}
          </MinimumRequirementsWrapperContent>
        </FullWidthSpace>
      )}
    </StyledModal>
  );
};

export default PreviewRequirementsModal;
