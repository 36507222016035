import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, Input, Space, Col, Row, Select, Button } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Form, UploadFile, FormButton, WrapperSelect } from 'common/components';
import LinkAssetToQaSpec from '../../qa-grid-cell/LinkAssetToQaSpec';

import { UPLOAD_MAX_SIZE } from 'static/Constants';
import { SUPPORT_TYPES } from 'pages/qa-spec/components/qa-manufacturing-process/constants';

import * as productEndpoints from 'services/product/endpoints';

import { useSelectProductFullDetailData } from 'pages/qa-spec/hooks';

import { useMutationLinkAssetToPim } from './queries';

const DEFAULT_ASSET_CONFIG = {
  type: 'Document',
  subtype: 'PFAS',
};

const DEFAULT_LINK_ASSET_CONFIG = {
  ...DEFAULT_ASSET_CONFIG,
  documentName: '',
};

const UploadPolyFlourDocumentSupporting = (props) => {
  const [form] = Form.useForm();
  const { id: productId } = useParams();

  const { isEdit } = props;

  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [assetId, setAssetId] = useState(null);

  const mutateLinkAssetToPim = useMutationLinkAssetToPim({
    onSuccess: () => {
      setVisible(false);
    },
  });
  const productDetail = useSelectProductFullDetailData({ productId });

  const onClickBackBtn = () => {
    setCurrentStep(0);
  };

  const onClickMoveToConfig = () => {
    setCurrentStep(1);
  };

  const onFileChange = () => {
    setAssetId(null);
  };

  const handleUploadFile = (response) => {
    if (!response?.data) return;

    const {
      data: { assetId },
    } = response.data;

    if (assetId) {
      setAssetId(assetId);
      setCurrentStep(1);
    }
    return;
  };

  const onSubmitConfigForm = (formValues) => {
    const params = {
      digitalAssetId: assetId,
      productItemId: parseInt(productId),
      linkType: 'DOCUMENT',
      ...formValues,
    };

    mutateLinkAssetToPim.mutate(params);
  };

  useEffect(() => {
    const resetState = () => {
      form.setFieldsValue(DEFAULT_LINK_ASSET_CONFIG);
      setCurrentStep(0);
      setAssetId(null);
    };

    resetState();
  }, [visible, setCurrentStep, setAssetId, form]);

  const renderUploadFile = () => {
    return (
      <>
        <div style={{ height: 300, marginTop: 12 }}>
          <UploadFile
            multiple={false}
            multipleImport={false}
            supportTypes={SUPPORT_TYPES}
            showSubmitBtn
            showList
            manualUpload
            showPreview
            showMultiple={false}
            additionalBodyPayload={{
              ownerId: productDetail?.ownerId,
              productId,
              ...DEFAULT_ASSET_CONFIG,
            }}
            apiUrl={productEndpoints.UPLOAD_PIM_ASSET}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
            getResponse={handleUploadFile}
            onFileChange={onFileChange}
            forceDisableManudalUpload={assetId}
          />
        </div>
        {assetId ? (
          <Row justify='end' style={{ marginTop: 12 }}>
            <FormButton
              type='default'
              icon={<ArrowRightOutlined />}
              text='Move to next step'
              onClick={onClickMoveToConfig}
            />
          </Row>
        ) : null}
      </>
    );
  };

  const renderConfigForm = () => {
    return (
      <Form
        style={{ height: 350 }}
        form={form}
        onFinish={onSubmitConfigForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Row
          style={{ flexDirection: 'column', height: '100%', paddingTop: 30 }}
        >
          <Col>
            <Form.Item name='type' label='Type: '>
              <WrapperSelect disabled>
                <Select.Option value='Document'>Document</Select.Option>
              </WrapperSelect>
            </Form.Item>

            <Form.Item name='subtype' label='Sub Type: '>
              <WrapperSelect disabled>
                <Select.Option value='Certificate'>PFAS</Select.Option>
              </WrapperSelect>
            </Form.Item>

            <Form.Item label='Document Name' name='documentName'>
              <Input />
            </Form.Item>
          </Col>
          <Col flex={1}></Col>
          <Col>
            <Space style={{ float: 'right' }}>
              <FormButton
                type='default'
                icon={<ArrowLeftOutlined />}
                text='Back to asset creation'
                onClick={onClickBackBtn}
              />
              <FormButton
                htmlType='submit'
                text='Complete Setup'
                loading={mutateLinkAssetToPim.loading}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Button
        icon={<UploadOutlined />}
        danger
        disabled={!isEdit}
        onClick={() => setVisible(true)}
      >
        Upload
      </Button>
      <LinkAssetToQaSpec
        modalProps={{
          title: 'Link supporting document',
          visible: visible,
          onCancel: () => setVisible(false),
          width: 460,
          bodyStyle: {
            minHeight: '375px',
          },
        }}
        stepProps={{
          currentStep,
          items: [
            {
              key: 0,
              title: (
                <Typography.Text strong style={{ fontSize: 16 }}>
                  Create an asset
                </Typography.Text>
              ),
            },
            {
              key: 1,
              title: (
                <Typography.Text strong style={{ fontSize: 16 }}>
                  Link to QA specification
                </Typography.Text>
              ),
            },
          ],
        }}
      >
        <div style={{ display: currentStep === 0 ? 'block' : 'none' }}>
          {renderUploadFile()}
        </div>
        <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
          {renderConfigForm()}
        </div>
      </LinkAssetToQaSpec>
    </>
  );
};

export default UploadPolyFlourDocumentSupporting;
