import React from 'react';
import { Typography, List, Avatar } from 'antd';
import { DragDropWrapUtils } from 'common/components';

import { Images } from 'config/assets';

import classnames from 'classnames';

const { Title, Text } = Typography;

const ConfirmDeleteReporting = (props) => {
  const { listData = [], title } = props;

  const renderTitle = (item) => {
    const { id, name } = item;

    return (
      <a href={`/reporting/${id}`} target='_blank' rel='noreferrer'>
        <Text ellipsis={{ tooltip: name }}>{name}</Text>
      </a>
    );
  };

  return (
    <div>
      <Title level={5}>{title}</Title>
      <div className={classnames('add-to-folder-confirm__list', 'scroller')}>
        <List
          itemLayout='horizontal'
          dataSource={listData}
          renderItem={(item) => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <div>
                      <Avatar
                        src={
                          DragDropWrapUtils.mapItemDataToAvatarConfirmList(
                            item
                          ) || Images.RIVIR_LOGO_DEFAULT
                        }
                        width={32}
                        height={32}
                      />
                    </div>
                  }
                  title={renderTitle(item)}
                />
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmDeleteReporting;
