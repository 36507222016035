import DrugFactSection from './DrugFactSection';
import DrugFactHeading from './DrugFactHeading';
import DrugFactDetailList from './DrugFactDetailList';

const DrugFactDirections = ({ directions }) => {
  if (!directions?.length) return null;

  const directionMappingList = directions.map((item) => {
    const ageGroupMapping = item.ageGroup ?? '';

    const directionDosageMapping = item.directionDosage ?? '';

    if (ageGroupMapping && directionDosageMapping)
      return `${ageGroupMapping}: ${directionDosageMapping}`;

    if (ageGroupMapping && !directionDosageMapping) return ageGroupMapping;
    return directionDosageMapping;
  });
  return (
    <DrugFactSection>
      <DrugFactHeading heading='Directions' />
      {directionMappingList?.length > 0 ? (
        <DrugFactDetailList list={directionMappingList} />
      ) : null}
    </DrugFactSection>
  );
};

export default DrugFactDirections;
