import { CIC_REASON, CIC_REASON_FREE_FORM } from 'static/Constants';

export const mappingReason = (values) => {
  let result = [];

  if (values?.gdsnCicReasons) {
    values.gdsnCicReasons.forEach((value) => {
      const foundReason = CIC_REASON.find(
        (reason) => reason.displayText === value.reason
      );
      result.push({
        additionalDescription: value?.additionalDescription ?? '',
        statusCode: foundReason.statusCode,
      });
    });
  }

  return result;
};

export const filterParams = (values = []) => {
  let reasons = [];

  if (values.length > 0) {
    values.forEach((item) => {
      if (item.statusCode === CIC_REASON_FREE_FORM.statusCode) {
        reasons.push({
          statusCode: item.statusCode,
          additionalDescription: item.additionalDescription,
        });
      } else {
        reasons.push({
          statusCode: item.statusCode,
        });
      }
    });
  }

  return {
    gdsnCicReasons: reasons,
  };
};

export const getUniqueReasons = (reasons = []) => {
  const valuesReason = reasons.map((r) => r.displayText);

  return CIC_REASON.filter((r) => !valuesReason.includes(r.displayText));
};
