import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from 'i18n/messages/gridView';
import { Row, Col, Typography, Modal, Tooltip, Button } from 'antd';
import './AbandonDialog.less';
const AbandonDialog = (props) => {
  const {
    visibleSelection,
    handleModalAbandon,
    handleModalAppend,
    handleModalCancel,
  } = props;
  return (
    <Modal
      title={<FormattedMessage {...messages.titleModal} />}
      visible={visibleSelection}
      closable={false}
      footer={[
        <Tooltip
          key='abandon'
          title={<FormattedMessage {...messages.abandonTooltip} />}
        >
          <Button type='primary' onClick={handleModalAbandon}>
            <FormattedMessage {...messages.abandon} />
          </Button>
        </Tooltip>,
        <Tooltip
          key='append'
          title={<FormattedMessage {...messages.appendTooltip} />}
        >
          <Button type='primary' onClick={handleModalAppend}>
            <FormattedMessage {...messages.append} />
          </Button>
        </Tooltip>,
        <Tooltip
          key='cancel'
          title={<FormattedMessage {...messages.cancelTooltip} />}
        >
          <Button onClick={handleModalCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Tooltip>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Typography.Text>
            <FormattedMessage {...messages.option} />
          </Typography.Text>
        </Col>
        <Col span={24} className='abandon-selection__col'>
          <Typography.Text className='abandon-selection__text'>
            <FormattedMessage {...messages.abandonTooltip} />
          </Typography.Text>
        </Col>
        <Col span={24} className='abandon-selection__col'>
          <Typography.Text className='abandon-selection__text'>
            <FormattedMessage {...messages.appendTooltip} />
          </Typography.Text>
        </Col>
        <Col span={24} className='abandon-selection__col'>
          <Typography.Text className='abandon-selection__text'>
            <FormattedMessage {...messages.cancelTooltip} />
          </Typography.Text>
        </Col>
      </Row>
    </Modal>
  );
};
AbandonDialog.propTypes = {
  visibleSelection: PropTypes.bool.isRequired,
  handleModalAbandon: PropTypes.func.isRequired,
  handleModalAppend: PropTypes.func.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
};

export default AbandonDialog;
