import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as globalSelectors from 'redux/global/selectors';

const mapArgToObj = (msg, signalType) => ({ signalType, ...msg });

const useHubFilter = () => {
  const [signal, setSignal] = useState(null);

  const hubConnection = useSelector(globalSelectors.selectSignalRConnection());
  // ?  o	3623 - CallRecived (thread id: string, sender id: string)
  // ?  	Passive Signal
  // ?  o	3624 – CallAccepted (thread id, sender id)
  // ?  	Passive Signal
  // ?  o	3625 – CallRejected (thread id, sender id)
  // ?  	Passive Signal
  // ?  o	xxx – KickOffMeeting (meeting Id, channel id)
  // ?  	Passive Signal
  // ?  o	xxx – MeetingEnded (sender Id, channel id)
  // ?  	Passive Signal

  const resetSignal = () => {
    setSignal(null);
  };

  useEffect(() => {
    if (hubConnection) {
      hubConnection.on('CallReceived', (msg) => {
        setSignal(mapArgToObj(msg, 'CallReceived'));
        console.log();
      });

      hubConnection.on('CallAccepted', (msg) => {
        setSignal(mapArgToObj(msg, 'CallAccepted'));
      });

      hubConnection.on('CallRejected', (msg) => {
        setSignal(mapArgToObj(msg, 'CallRejected'));
      });

      hubConnection.on('CallEnded', (msg) => {
        setSignal(mapArgToObj(msg, 'CallEnded'));
      });

      hubConnection.on('KickOffMeeting', (msg) => {
        setSignal(mapArgToObj(msg, 'KickOffMeeting'));
      });

      hubConnection.on('MeetingEnded', (msg) => {
        setSignal(mapArgToObj(msg, 'MeetingEnded'));
      });
    }
  }, [hubConnection]);

  return [signal, resetSignal];
};

export default useHubFilter;
