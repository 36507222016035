import produce from 'immer';
import * as types from './constants';
//todo: mock only please remove when api updated
// import RIVIR_LOGO from 'assets/RIVIR_Logo_R3-Icon-2.jpg';

// initial state
export const initialState = {
  brandingRoot: {
    loading: true,
    error: null,
    data: null,
  },
  loadingUpdate: false,
  loadingError: null,
};

/* eslint-disable default-case, no-param-reassign */
const brandingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      //* get branding data
      case types.GET_BRANDING:
        draft.brandingRoot.loading = true;
        draft.brandingRoot.error = null;
        break;
      case types.GET_BRANDING_SUCCESS:
        draft.brandingRoot.loading = false;
        draft.brandingRoot.data = {
          ...action.payload,
        };
        draft.brandingRoot.error = null;
        break;
      case types.GET_BRANDING_FAIL:
        draft.brandingRoot.loading = false;
        draft.brandingRoot.data = null;
        draft.brandingRoot.error = action.error;
        break;
      case types.CLEAR_BRANDING:
        draft.brandingRoot.data = null;
        draft.brandingRoot.loading = false;
        break;
      case types.GET_BRANDING_NO_LOADING:
        draft.brandingRoot.error = null;
        break;
      case types.GET_BRANDING_NO_LOADING_SUCCESS:
        draft.brandingRoot.data = {
          ...draft.brandingRoot.data,
          ...action.payload,
        };
        draft.brandingRoot.loading = false;
        draft.brandingRoot.error = null;
        break;
      case types.GET_BRANDING_NO_LOADING_FAIL:
        draft.brandingRoot.data = null;
        draft.brandingRoot.error = action.error;
        draft.brandingRoot.loading = false;
        break;
      case types.UPDATE_LAST_GET_NEW_MEMBER:
        draft.loadingUpdate = true;
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_NEW_MEMBER_SUCCESS:
        draft.loadingUpdate = true;
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_NEW_MEMBER_ERROR:
        draft.loadingUpdate = true;
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_NEW_PRODUCT:
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_NEW_PRODUCT_SUCCESS:
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_NEW_PRODUCT_ERROR:
        draft.loadingError = action.payload;
        break;
      case types.UPDATE_LAST_GET_NEW_ASSET:
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_NEW_ASSET_SUCCESS:
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_NEW_ASSET_ERROR:
        draft.loadingError = action.payload;
        break;

      case types.UPDATE_LAST_GET_UNMATCH_MEMBER:
        draft.loadingUpdate = true;
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_UNMATCH_MEMBER_SUCCESS:
        draft.loadingUpdate = true;
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_UNMATCH_MEMBER_ERROR:
        draft.loadingUpdate = true;
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_UNMATCH_ASSET:
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_UNMATCH_ASSET_SUCCESS:
        draft.loadingError = null;
        break;
      case types.UPDATE_LAST_GET_UNMATCH_ASSET_ERROR:
        draft.loadingError = action.payload;
        break;
    }
  });

export default brandingReducer;
