import React from 'react';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import DownloadInformation from '../../controls/member-grid/DownloadInformation';

const DownloadSheet = ({ companyInfo,entityType }) => {
  return <DownloadInformation companyInfo={companyInfo} entityType={entityType} />;
};

export default DownloadSheet;
