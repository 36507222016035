import React from 'react';
import { Input } from 'antd';

import { Form } from 'common/components';

const InactiveIngredients = () => {
  return (
    <Form.Item name='inactiveIngredients' style={{ marginTop: 8 }}>
      <Input.TextArea rows={3} />
    </Form.Item>
  );
};

export default InactiveIngredients;
