import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Space, Checkbox, Card, Avatar, Row } from 'antd';
import { Images } from 'config/assets';
import './ProductListItem.less';

const ListItem = (props) => {
  const { dataDetail, selected, onClickItemGrid } = props;

  return (
    <Row
      className={
        selected
          ? 'product-list-item product-list-item--selected'
          : 'product-list-item'
      }
    >
      <Checkbox className='product-list-item__checkbox' />
      <Card
        bordered={false}
        size={'small'}
        className='product-list-item__card'
        onClick={(e) => {
          if (onClickItemGrid) onClickItemGrid(dataDetail, e);
        }}
      >
        <Space align='center'>
          <Avatar
            className='product-list-item__image'
            shape='square'
            src={
              (dataDetail && dataDetail.imageUrl) || Images.RIVIR_LOGO_DEFAULT
            }
          />
          <Typography.Text ellipsis>
            {dataDetail && dataDetail.upc12} - Widget #1
          </Typography.Text>
        </Space>
      </Card>
    </Row>
  );
};

ListItem.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ListItem;
