import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Dropdown, Empty, List, Typography } from 'antd';
import {
  MailOutlined,
  MessageOutlined,
  FileTextOutlined,
  CommentOutlined,
  VideoCameraOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import Messages from 'i18n/messages/maintenance';
import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as memberMaintainanceActions from 'pages/member-maintenance/controllers/actions';

import RibbonButton from 'common/components/button/RibbonButton';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';

import './MemberCommunicateSection.less';

const { SubMenu } = Menu;

const COMMUNICATION_PERMISSION = [
  {
    action: ABILITY_ACTION.MAINTAIN,
    subject: ABILITY_SUBJECT.COMMUNICATION_TEMPLATE,
  },
];

const MemberCommunicateSection = (props) => {
  const dispatch = useDispatch();

  const hasPermissionCommunication = useCheckPermissions(
    COMMUNICATION_PERMISSION
  );

  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );
  const communicationTemplates = useSelector(
    memberMaintenanceSelectors.selectCommunicationTemplates()
  );

  const selectedUserOrMember = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const memberTemplates = communicationTemplates.filter(
    (template) => template.entityType === 'Member'
  );
  const userTemplates = communicationTemplates.filter(
    (template) => template.entityType === 'User'
  );
  const textMemberTemplates = memberTemplates.filter((template) =>
    template.communicateTypes.includes('Text')
  );
  const emailMemberTemplates = memberTemplates.filter((template) =>
    template.communicateTypes.includes('Mail')
  );
  const chatMemberTemplates = memberTemplates.filter((template) =>
    template.communicateTypes.includes('Chat')
  );
  const videoMemberTemplates = memberTemplates.filter((template) =>
    template.communicateTypes.includes('Video')
  );
  const textUserTemplates = userTemplates.filter((template) =>
    template.communicateTypes.includes('Text')
  );
  const emailUserTemplates = userTemplates.filter((template) =>
    template.communicateTypes.includes('Mail')
  );
  const chatUserTemplates = userTemplates.filter((template) =>
    template.communicateTypes.includes('Chat')
  );
  const videoUserTemplates = userTemplates.filter((template) =>
    template.communicateTypes.includes('Video')
  );
  const textTemplates = isMemberGridInfoView
    ? textMemberTemplates
    : textUserTemplates;
  const emailTemplates = isMemberGridInfoView
    ? emailMemberTemplates
    : emailUserTemplates;
  const chatTemplates = isMemberGridInfoView
    ? chatMemberTemplates
    : chatUserTemplates;
  const videoTemplates = isMemberGridInfoView
    ? videoMemberTemplates
    : videoUserTemplates;

  const onclickTemplate = (template, methodType) => {
    dispatch(
      memberMaintainanceActions.setCurrentExecutionTemplate({
        ...template,
        methodType,
      })
    );
  };

  const renderTemplatesListing = (templateListing, methodType) => {
    return templateListing && templateListing.length > 0 ? (
      templateListing.map((template, index) => {
        return (
          <Menu.Item
            key={index}
            className='member-communicate-section__submenu--item'
          >
            <RibbonButton
              size='small'
              icon={<UnorderedListOutlined />}
              textDisplay={template.title}
              onClick={() => onclickTemplate(template, methodType)}
            />
          </Menu.Item>
        );
      })
    ) : (
      <Empty />
    );
  };

  const menuOptions = (
    <Menu className='member-communicate-section__menu'>
      <SubMenu
        className='member-communicate-section__submenu'
        popupClassName='member-communicate-section'
        key='text'
        style={{ backgroundColor: 'inherit' }}
        title={
          <RibbonButton
            size='small'
            icon={<FileTextOutlined />}
            label={Messages.text}
          />
        }
      >
        {renderTemplatesListing(textTemplates, 'Text')}
      </SubMenu>
      <SubMenu
        className='member-communicate-section-submenu'
        popupClassName='member-communicate-section'
        key='email'
        style={{ backgroundColor: 'inherit' }}
        title={
          <RibbonButton
            size='small'
            icon={<MailOutlined />}
            label={Messages.email}
          />
        }
      >
        {renderTemplatesListing(emailTemplates, 'Mail')}
      </SubMenu>
      <SubMenu
        className='member-communicate-section-submenu'
        popupClassName='member-communicate-section'
        key='chat'
        style={{ backgroundColor: 'inherit' }}
        title={
          <RibbonButton
            size='small'
            icon={<CommentOutlined />}
            label={Messages.chat}
          />
        }
      >
        {renderTemplatesListing(chatTemplates, 'Chat')}
      </SubMenu>
      <SubMenu
        className='member-communicate-section-submenu'
        popupClassName='member-communicate-section'
        key='videoMessage'
        style={{ backgroundColor: 'inherit' }}
        title={
          <RibbonButton
            size='small'
            icon={<VideoCameraOutlined />}
            label={Messages.videoMessage}
          />
        }
      >
        {renderTemplatesListing(videoTemplates, 'Video')}
      </SubMenu>
    </Menu>
  );

  return (
    <Dropdown
      overlay={hasPermissionCommunication ? menuOptions : <></>}
      arrow={hasPermissionCommunication ? true : false}
      disabled={
        selectedUserOrMember && selectedUserOrMember?.length > 0 ? false : true
      }
    >
      <RibbonButton icon={<MessageOutlined />} label={Messages.communicate} />
    </Dropdown>
  );
};

export default MemberCommunicateSection;
