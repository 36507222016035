import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Row, Typography, Col, Switch } from 'antd';

import * as _ from 'lodash';
import classnames from 'classnames';

//* SAGA
import * as assetActions from 'pages/asset-full-view/controllers/actions';
import * as assetSelectors from 'pages/asset-full-view/controllers/selectors';
import * as homeActions from 'pages/home/ribbon/asset-full/controllers/actions';

//* COMPONENTS
import UploadFile from 'common/components/uploadFile/UploadFile';
import {
  AssetFtpList,
  IframeAsset,
  AssetDropboxUpload,
  AssetUploadSelect,
  AssetToFolder,
  ConfirmContent,
} from 'pages/asset-full-view/components';

//* UTILS
import { forwardTo } from 'utils/common/route';

//* DEFINDE
import {
  ASSET_UPLOAD_TYPE,
  ASSET_UPLOAD_METHOD_DEFINE,
} from 'static/Constants';

//* STYLING
import './AssetUpload.less';
import { FormButton } from 'common/components';
import { useCheckAllowImportToFolder, useImportToFolder } from './shared/hooks';

import { TYPE_VIEW_ASSET } from 'static/Constants';

const { Title } = Typography;

function AssetUpload(props) {
  const {
    isBase64,
    apiUrl,
    ftpApiUrl,
    getLocalFileResponse,
    getFtpFileResponse,
    onlyAcceptImage,
    manualUpload,
    showMultiple,
    multiple = false,
    base64UploadKey,
    multipleImport,
    callbackDataList,
    customRequestFtp,
    maxSize,
    getFileChange,
    showList,
    additionalBodyPayload,
    showUploadSuccessMsg,
    showXlsTemplate,
    templateUrl,
    confirmBeforeUpload,
    methodList = ASSET_UPLOAD_METHOD_DEFINE.DEFAULT,
    isShowCancelButton,
    handleCancel = () => {},
    showServerMessage,
    formInstance,
    uploadType,
    setUploadType,
    idSelectionDropbox,
    setIdSelectionDropbox,
    typeViewAsset,
  } = props;

  const isCreateAssetView = typeViewAsset === TYPE_VIEW_ASSET.CREATE_ASSET;

  const [searchText, setSearchText] = useState('');

  const {
    isEnabledImportFolder,
    importedFolderParams,
    folderData,
    isLoading,
    selectedFolder,
    isCreateSubfolder,
    handleLoadMoreItem,
    handleUpdateFolder,
    handleToggleCreateSubfolder,
    handleToggleImportFolder,
    onCreateNewFolderSuccess,
    removeSelectedFolder,
  } = useImportToFolder({
    isEnabled: Boolean(isCreateAssetView),
    searchText,
  });

  const isAllowImportToFolder = useCheckAllowImportToFolder();

  const dispatch = useDispatch();
  const ftpList = useSelector(assetSelectors.makeSelectFtpFileList());

  const getFtpListIfNeed = useCallback((uploadType) => {
    if (
      uploadType !== ASSET_UPLOAD_TYPE.UPLOAD_LOCAL_FILE.name &&
      ASSET_UPLOAD_TYPE[uploadType].params
    ) {
      dispatch(
        assetActions.getFtpFileList(ASSET_UPLOAD_TYPE[uploadType].params)
      );
    }
  }, []);

  useEffect(() => {
    getFtpListIfNeed(uploadType);
  }, [uploadType, dispatch, getFtpListIfNeed]);

  const onCreateIframeSuccess = (id, metaData) => {
    dispatch(assetActions.cancelUpdateSuccess());
    dispatch(assetActions.createDigitalAssetFullSuccess(metaData));
    dispatch(homeActions.editAsset());
    dispatch(homeActions.creatingAsset());
    forwardTo(`/asset/${id}`);
  };

  const destinationFolder = {
    destinationFolder: selectedFolder,
    isCreateSubfolder,
  };

  const {
    isUploadFromLocal,
    isUploadFromFtp,
    isUploadFromUrl,
    isUploadFromDropbox,
  } = getTypeUpload(uploadType);

  const UploadTypeToArray = _.toArray(ASSET_UPLOAD_TYPE);

  const includeSubFolders =
    isEnabledImportFolder && (isUploadFromFtp || isUploadFromDropbox)
      ? { includeSubFolders: isCreateSubfolder }
      : null;

  const AdditionalBodyPayloadFolder = {
    ...additionalBodyPayload,
    ...importedFolderParams,
    ...includeSubFolders,
  };

  const importToFolderParams = {
    ...importedFolderParams,
    ...includeSubFolders,
  };

  const uploadInLocalParams = {
    ...AdditionalBodyPayloadFolder,
    StorageServiceType: 'local',
  };

  if (isUploadFromUrl)
    return (
      <IframeAsset
        setUploadType={setUploadType}
        onSuccess={onCreateIframeSuccess}
        isCreateAsset={true}
        uploadType={uploadType}
        methodList={methodList}
        typeViewAsset={typeViewAsset}
      />
    );

  return (
    <div className='asset-upload'>
      <div className='asset-upload__dialog'>
        <div
          className={classnames('asset-upload__dialog-header', {
            'asset-upload__dialog-header--iframe': isUploadFromUrl,
          })}
        >
          <Title level={4} className='asset-upload__dialog-title'>
            {!multipleImport
              ? UploadTypeToArray.map((uploadOption) => (
                  <React.Fragment key={'upload-titlle-' + uploadOption.name}>
                    {uploadType === uploadOption.name &&
                      uploadOption.title &&
                      uploadOption.title}
                  </React.Fragment>
                ))
              : 'Choose Files To Import'}
          </Title>

          {isCreateAssetView && isAllowImportToFolder && (
            <Switch
              style={{
                position: 'absolute',
                right: 186,
                top: 18,
              }}
              checkedChildren='Import to folder'
              unCheckedChildren='Import to folder'
              label='Import to folder'
              onChange={(check) => handleToggleImportFolder(check)}
              checked={isEnabledImportFolder}
            />
          )}

          <AssetUploadSelect
            setUploadType={setUploadType}
            uploadType={uploadType}
            methodList={methodList}
            typeViewAsset={typeViewAsset}
          />
        </div>

        <div className='asset-upload__dialog-content-wrapper'>
          <div className='asset-upload__dialog-content'>
            {isUploadFromLocal && (
              <ImportAssetSection
                isEnabledImportFolder={isEnabledImportFolder}
                assetUpload={
                  <UploadFile
                    base64={isBase64}
                    apiUrl={apiUrl}
                    getResponse={(result) => {
                      getLocalFileResponse && getLocalFileResponse(result);
                    }}
                    manualUpload={manualUpload}
                    multiple={multiple}
                    onlyAcceptImage={onlyAcceptImage}
                    showMultiple={showMultiple}
                    base64UploadKey={base64UploadKey}
                    multipleImport={multipleImport}
                    callbackDataList={callbackDataList}
                    maxSize={maxSize}
                    getFileChange={getFileChange}
                    showList={showList}
                    additionalBodyPayload={uploadInLocalParams}
                    showUploadSuccessMsg={showUploadSuccessMsg}
                    showXlsTemplate={showXlsTemplate}
                    templateUrl={templateUrl}
                    confirmBeforeUpload={
                      <ConfirmContent
                        isUploadFromLocal={isUploadFromLocal}
                        destinationFolder={destinationFolder}
                        isEnabledImportFolder={isEnabledImportFolder}
                        multiImportFormValue={additionalBodyPayload}
                      />
                    }
                    isShowCancelButton={isShowCancelButton}
                    handleCancel={handleCancel}
                    showServerMessage={showServerMessage}
                  />
                }
                destinationFolder={
                  <AssetToFolder
                    loadingTree={isLoading}
                    treeData={folderData}
                    onSearch={(searchText) => {
                      removeSelectedFolder();
                      setSearchText(searchText);
                    }}
                    onUpdateFolder={handleUpdateFolder}
                    loadData={handleLoadMoreItem}
                    onToggleCreateSubfolder={handleToggleCreateSubfolder}
                    subFolderParams={{
                      isShow: isUploadFromFtp || isUploadFromDropbox,
                      isCreateSubfolder,
                    }}
                    selectedItem={selectedFolder}
                    onCreateNewFolderSuccess={onCreateNewFolderSuccess}
                  />
                }
              />
            )}
            {isUploadFromFtp && (
              <ImportAssetSection
                isEnabledImportFolder={isEnabledImportFolder}
                assetUpload={
                  <AssetFtpList
                    ftpListFile={ftpList}
                    onlyAcceptImage={onlyAcceptImage}
                    apiUrl={ftpApiUrl}
                    customRequestFtp={customRequestFtp}
                    multiple={multiple}
                    multipleImport={multipleImport}
                    callbackDataList={callbackDataList}
                    getResponse={(result) => {
                      getFtpFileResponse && getFtpFileResponse(result);
                    }}
                    showUploadSuccessMsg={showUploadSuccessMsg}
                    showXlsTemplate={showXlsTemplate}
                    templateUrl={templateUrl}
                    // confirmBeforeUpload={
                    //   <ConfirmContent
                    //     isEnabledImportFolder={isEnabledImportFolder}
                    //     destinationFolder={destinationFolder}
                    //     multiImportFormValue={additionalBodyPayload}
                    //   />
                    // }
                    destinationFolder={destinationFolder}
                    formInstance={formInstance}
                    handleCloseModal={handleCancel}
                    typeViewAsset={typeViewAsset}
                    multiImportFormValue={additionalBodyPayload}
                    importedFolderParams={AdditionalBodyPayloadFolder}
                    isEnabledImportFolder={isEnabledImportFolder}
                  />
                }
                destinationFolder={
                  <AssetToFolder
                    loadingTree={isLoading}
                    treeData={folderData}
                    onSearch={(searchText) => setSearchText(searchText)}
                    onUpdateFolder={handleUpdateFolder}
                    loadData={handleLoadMoreItem}
                    onToggleCreateSubfolder={handleToggleCreateSubfolder}
                    subFolderParams={{
                      isShow: isUploadFromFtp || isUploadFromDropbox,
                      isCreateSubfolder,
                    }}
                    selectedItem={selectedFolder}
                    onCreateNewFolderSuccess={onCreateNewFolderSuccess}
                  />
                }
              />
            )}

            {isUploadFromDropbox && (
              <ImportAssetSection
                isEnabledImportFolder={isEnabledImportFolder}
                assetUpload={
                  <AssetDropboxUpload
                    isEnabledImportFolder={isEnabledImportFolder}
                    destinationFolder={destinationFolder}
                    multipleImport={multipleImport}
                    formInstance={formInstance}
                    handleCloseModal={handleCancel}
                    idSelectionDropbox={idSelectionDropbox}
                    setIdSelectionDropbox={setIdSelectionDropbox}
                    typeViewAsset={typeViewAsset}
                    importedFolderParams={importToFolderParams}
                  />
                }
                destinationFolder={
                  <AssetToFolder
                    loadingTree={isLoading}
                    treeData={folderData}
                    onSearch={(searchText) => setSearchText(searchText)}
                    onUpdateFolder={handleUpdateFolder}
                    loadData={handleLoadMoreItem}
                    onToggleCreateSubfolder={handleToggleCreateSubfolder}
                    subFolderParams={{
                      isShow: isUploadFromFtp || isUploadFromDropbox,
                      isCreateSubfolder,
                    }}
                    selectedItem={selectedFolder}
                    onCreateNewFolderSuccess={onCreateNewFolderSuccess}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const ImportAssetSection = ({
  isEnabledImportFolder,
  assetUpload,
  destinationFolder,
  ...rest
}) => {
  return (
    <>
      <Row style={{ width: '100%', height: '100%' }} {...rest}>
        <Col span={isEnabledImportFolder ? 12 : 24}>{assetUpload}</Col>
        {isEnabledImportFolder ? (
          <Col span={12}>{destinationFolder}</Col>
        ) : null}
      </Row>
    </>
  );
};

const getTypeUpload = (uploadType) => {
  const isUploadFromDropbox =
    uploadType === ASSET_UPLOAD_TYPE.UPLOAD_DROP_BOX_FILE.name;
  const isUploadFromFtp =
    uploadType === ASSET_UPLOAD_TYPE.UPLOAD_ADV_FTP_FILE.name;
  const isUploadFromLocal =
    uploadType === ASSET_UPLOAD_TYPE.UPLOAD_LOCAL_FILE.name;
  const isUploadFromUrl = uploadType === ASSET_UPLOAD_TYPE.UPLOAD_URL.name;

  return {
    isUploadFromDropbox,
    isUploadFromFtp,
    isUploadFromLocal,
    isUploadFromUrl,
  };
};

AssetUpload.propTypes = {
  //? isBase64 - base64 upload for local file upload
  isBase64: PropTypes.bool,
  //? apiUrl - api to upload file
  apiUrl: PropTypes.string,
  //? ftpApiUrl - api to upload ftp file
  ftpApiUrl: PropTypes.string,
  //? getLocalFileResponse - get reponse to handle
  getLocalFileResponse: PropTypes.func,
  //? getFtpFileResponse  - get reponse to handle
  getFtpFileResponse: PropTypes.func,
  //? onlyAccepImage - only accept raster image that allow Preview
  onlyAcceptImage: PropTypes.bool,
  //? manualUpload - upload by click upload button instead of auto upload
  manualUpload: PropTypes.bool,
  //todo - showMultiple - show multiple file or signle file - in development
  showMultiple: PropTypes.bool,
  //? multiple: accept multiple files or not
  multiple: PropTypes.bool,
  //? ftpList - ftp files returned from server
  ftpList: PropTypes.object,
  //todo - base64 upload key - pass key as id of thumbnail
  base64UploadKey: PropTypes.string,
  //? customRequestFtp - get selected files and custom request of ftp upload
  customRequestFtp: PropTypes.func,
  //? maxSize: max file size
  maxSize: PropTypes.number,
  multipleImport: PropTypes.bool,
  callbackDataList: PropTypes.func,
};

export default AssetUpload;
