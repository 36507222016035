import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//* components
import { Row, Col } from 'antd';
import Draggable from 'react-draggable';

import VideoLoading from './loading/VideoLoading';
import JitsiFrame from './jitsi-frame/JitsiFrame';
import DisableFrame from './jitsi-frame/DisableFrame';
import VideoCallBranding from './branding/VideoCallBranding';
import VideoCallToolbox from './toolbox/VideoCallToolbox';
import CallerRington from './dial/CallerRington';
import VideoCallRecommend from './branding/VideoCallRecommend';
import ChatVideo from './chat-video';
import { CustomNotification } from 'common/components';

//*  saga
import * as globalActions from 'redux/global/actions';
import userSelectors from 'redux/user/selectors';
import * as chatSelectors from 'common/components/message/controller/selectors';

//* services
import * as videoCallServices from 'services/videoCallServices';
import * as extVideoCallServices from 'services/ext-video-call';

//* utils
import useFullScreen from 'hooks/useFullScreen';
import useHubFilter from 'hooks/useHubFilter';
import { useWindowSize } from 'hooks/windowSize';
import { loadJitsi } from 'utils/common/jitsiScriptLoader';
import { useChatDesctruction } from 'common/components/message/hooks/useChatDesctruction';
import * as servicesChat from 'services/chatServices';
import classnames from 'classnames';
import { JITSI_DOMAIN } from 'static/Constants';

import './VideoCall.less';

const interface_config = {
  SHOW_CHROME_EXTENSION_BANNER: false,
  HIDE_INVITE_MORE_HEADER: true,
  SHOW_JITSI_WATERMARK: false,
  SHOW_BRAND_WATERMARK: false,
  DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
  DISABLE_FOCUS_INDICATOR: true,
  SETTINGS_SECTIONS: ['devices', 'language'],
};

const config = {
  prejoinPageEnabled: false,
  disableTileView: true,
  toolbarButtons: [
    'microphone',
    'camera',
    'settings',
    'mute-everyone',
    'mute-video-everyone',
    'select-background',
    'videoquality',
  ],
  hideConferenceSubject: true,
  enableNoisyMicDetection: false,
};

const start_pos = {
  x: 0,
  y: 0,
};

const VideoCall = (props) => {
  const { callData, isVideoCallOpen, isChatViewOpen } = props;

  const { currentCall, isCallAccepted } = callData;

  const room_data = useMemo(
    () => ({
      domain: JITSI_DOMAIN,
    }),
    []
  );

  const roomId = useMemo(() => currentCall?.roomId, [currentCall?.roomId]);

  const ringingUsers = useRef();
  const rejectList = useRef([]);
  const participantList = useRef([]);
  const refRoomId = useRef(null);
  const refDrag = useRef();

  const [screenWidth, screenHeight] = useWindowSize();

  ringingUsers.current = currentCall?.ringingUsers;

  const [loading, setLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLockFrame, setIsLockFrame] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isChatViewVideoOn, setIsChatViewVideoOn] = useState(false);
  const [isMinized, setIsMinized] = useState(false);

  const resetChatRedux = useChatDesctruction();

  const setParticipantList = (value) => (participantList.current = value);

  const toggleFullScreen = () => {
    setIsFullScreen((prevIsFull) => {
      if (!prevIsFull && isMinized) {
        //* when we go full screen => need to turn off minimized mode
        setIsMinized(false);
      }
      return !prevIsFull;
    });

    if (!fullScreenInstance.current) return;

    !isFullScreen && fullScreenInstance.current.goFullScreen();
    isFullScreen && fullScreenInstance.current.exitFullScreen();
  };

  const dispatch = useDispatch();

  const ref = useRef();
  const refApi = useRef();

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const userProfile = useSelector(userSelectors.makeSelectUserInfo());
  const avatarUrl = userProfile?.avatarUrl;

  const [fullScreenInstance] = useFullScreen();

  const refCallerRing = useRef();

  const [newSignal, resetSignal] = useHubFilter();

  const communicationItemData = useSelector(chatSelectors.selectThreadInfo());
  const statusThreadMsg = useSelector(chatSelectors.selectStatusThreadMsg());

  const isAnonymous = userInfo?.isAnonymous;

  const setRejectList = (nextList) => {
    rejectList.current = nextList;
  };

  const startConference = (JitsiMeetExternalAPI, roomSetting) => {
    try {
      const options = {
        roomName: roomSetting.roomName,
        userInfo: {
          displayName: userInfo?.name,
          avatarUrl: avatarUrl,
        },
        parentNode: ref.current,
        configOverwrite: config,
        interfaceConfigOverwrite: interface_config,
      };

      const api = new JitsiMeetExternalAPI(room_data.domain, options);
      refApi.current = api;

      if (api) {
        api.on('readyToClose', () => {
          console.log('close chat');
        });
      }

      if (!api) {
        console.error('Failed to create video call istance');
        //todo - please do test
        dispatch(globalActions.toggleIsCallAccepted(false));
        dispatch(globalActions.toggleVideoCall({ status: false }));
      }

      api.addEventListener('videoConferenceJoined', (joinedInfo) => {
        setLoading(false);
        console.warn('joinedInfo', joinedInfo);
        //? fullName is for anon user
        api.executeCommand(
          'displayName',
          userInfo?.fullName || userInfo?.firstName + ' ' + userInfo?.lastName
        );
        const url = window.location.origin + avatarUrl;
        api.executeCommand('avatarUrl', url);
        setParticipantList(api.getParticipantsInfo());
      });

      api.addEventListener('participantRoleChanged', (event) => {
        if (event.role === 'moderator') {
          api.executeCommand('password', roomSetting.password);
        }
      });

      api.addEventListener('participantLeft', (participant) => {
        setParticipantList(api.getParticipantsInfo());
      });

      api.addEventListener('participantJoined', (participant) => {
        setParticipantList(api.getParticipantsInfo());

        console.warn('participantJoined', participant);
        CustomNotification.info(
          `${
            participant.displayName || 'The participant'
          } have joined the meeting`
        );
      });

      // join a protected channel
      api.on('passwordRequired', () => {
        api.executeCommand('password', roomSetting.password);
      });

      api.on('screenSharingStatusChanged', (event) => {
        if (event?.on) {
          setIsScreenSharing(true);
        } else {
          setIsScreenSharing(false);
        }
      });

      api.addEventListener('participantKickedOut', (kickInfo) => {
        if (kickInfo.kicked.local) {
          dispatch(globalActions.toggleVideoCall({ status: false }));
          api.executeCommand('hangup');
          CustomNotification.info('you have been kicked out of the meeting!');
        }
      });
    } catch (error) {
      console.error('Failed to start the conference', error);
    }
  };

  const toggleShareScreen = () => {
    refApi.current?.executeCommand &&
      refApi.current.executeCommand('toggleShareScreen');
  };

  const resetVideoCallState = () => {
    setIsScreenSharing(false);
    setIsMinized(false);
    setIsFullScreen(false);
    setIsChatViewVideoOn(false);
  };

  const endCall = () => {
    dispatch(globalActions.toggleIsCallAccepted(false));
    setRejectList([]);
    resetSignal();

    if (participantList.current?.length < 2 && currentCall.roomId) {
      const params = { threadId: currentCall.roomId };

      const endVideoCallServices = isAnonymous
        ? extVideoCallServices.endVideoMeeting
        : videoCallServices.endVideoMeeting;
      endVideoCallServices(params);
    }

    refApi.current?.executeCommand && refApi.current.executeCommand('hangup');
    dispatch(globalActions.toggleVideoCall({ status: false }));

    //todo - need to test more if there is any bug please fix it
    resetVideoCallState();
  };

  const endLoadScreen = () => {
    endCall();
    refCallerRing.current && refCallerRing.current.stopCallerDial();
  };

  const toggleLockFrame = (status) => {
    setIsLockFrame(status);
  };

  const resetDragPos = () => {
    refDrag.current.state.x = 0;
    refDrag.current.state.y = 0;
  };

  const toggleMinimize = (status) => {
    if (status === undefined) {
      setIsMinized((prev) => {
        if (prev) {
          resetDragPos();
        } else {
          isChatViewVideoOn && setIsChatViewVideoOn(false);
        }
        return !prev;
      });
      return;
    }
    setIsMinized(status);
  };

  const toggleChatView = (status) => {
    if (status === undefined) {
      return setIsChatViewVideoOn((prev) => !prev);
    } else {
      return setIsChatViewVideoOn(status);
    }
  };

  const resetApi = () => {
    refApi.current = null;
    return true;
  };

  const handleStartCall = async (roomId) => {
    loadJitsi()
      .then((externalAPI) => {
        if (externalAPI) {
          refApi.current = externalAPI;

          const roomSetting = {
            roomName: roomId,
          };

          startConference(externalAPI, roomSetting);
        }
      })
      .catch((e) => {
        CustomNotification.error(e);
      });
  };

  const handleGetRejectUser = (newSignal, ringingUsers) => {
    rejectList.current = [...rejectList.current, newSignal?.senderId];
    if (ringingUsers.current?.length === rejectList.current?.length) {
      if (ringingUsers?.current?.length > 1) {
        endCall();
        refCallerRing.current && refCallerRing.current.stopCallerDial();
        CustomNotification.info('All users are busy!');
      } else {
        servicesChat
          .getChatUsersInfo([newSignal?.senderId])
          .then((res) => {
            if (res.isSuccess) {
              const senderInfo =
                res?.data?.senders &&
                res?.data?.senders?.length > 0 &&
                res?.data?.senders[0];

              CustomNotification.info(`${senderInfo?.name} busy!`);
            } else {
              CustomNotification.info('All users are busy!');
            }
          })
          .catch((err) => console.log('err ', err))
          .then(() => {
            endCall();
            refCallerRing.current && refCallerRing.current.stopCallerDial();
          });
      }
    }
  };

  const handleUnmountVideoCall = () => {
    refApi.current?.executeCommand && refApi.current.executeCommand('hangup');
    refCallerRing.current?.stopCallerDial &&
      refCallerRing.current.stopCallerDial();

    resetApi();

    if (participantList.current?.length < 2 && refRoomId.current) {
      videoCallServices.endVideoMeeting({ threadId: refRoomId.current });
    }

    //todo - need to test more if there is any bug please fix it
    resetVideoCallState();

    dispatch(globalActions.toggleIsCallAccepted(false));
    dispatch(globalActions.toggleVideoCall({ status: false }));
  };

  const handleClickJFrame = () => {
    if (isMinized) {
      setIsMinized(false);
    }
  };

  const videoCallWrapperStyle = () => {
    let width, height;

    const chatWidth = 434;
    const ratio = 1.58;
    const maxHeight = Math.min(screenHeight, 850);

    width = screenWidth - chatWidth - 50;
    height = width / ratio;

    if (height > maxHeight) {
      height = maxHeight - 50;
      width = height * ratio;
    }

    if (isFullScreen) {
      width = '100%';
      height = '100%';
    }

    if (isMinized) {
      // height = 420 / ratio;
      //* better height without using fixed ratio
      height = 225;
      width = 420;
    }

    return {
      width,
      height,
    };
  };

  useEffect(() => {
    return () => handleUnmountVideoCall();
  }, []);

  useEffect(() => {
    if (roomId) {
      handleStartCall(roomId);
      refRoomId.current = roomId;
    }
  }, [roomId]);

  useEffect(() => {
    if (
      !isCallAccepted &&
      newSignal?.signalType === 'CallAccepted' &&
      newSignal?.threadId === currentCall?.roomId
    ) {
      dispatch(globalActions.toggleIsCallAccepted(true));
    }
  }, [newSignal, isCallAccepted, currentCall?.roomId]);

  useEffect(() => {
    if (
      !isCallAccepted &&
      newSignal?.signalType === 'CallRejected' &&
      ringingUsers.current &&
      newSignal?.threadId === currentCall?.roomId
    ) {
      handleGetRejectUser(newSignal, ringingUsers);
    }
  }, [newSignal, isCallAccepted, currentCall?.roomId]);

  useEffect(() => {
    if (
      communicationItemData?.id &&
      communicationItemData?.id !== callData?.threadItem?.threadId
    ) {
      setIsChatViewVideoOn(false);
    }
  }, [communicationItemData, callData]);

  useEffect(() => {
    if (!isChatViewOpen && !isVideoCallOpen) {
      resetChatRedux();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatViewOpen, isVideoCallOpen]);

  const isChatInvisible =
    !isChatViewVideoOn ||
    statusThreadMsg === 'loading' ||
    communicationItemData?.id !== callData?.threadItem?.threadId;

  if (!isVideoCallOpen) return null;

  return (
    <Draggable
      bounds='parent'
      handle='strong'
      position={isFullScreen ? start_pos : null}
      ref={refDrag}
    >
      <div
        className={classnames('video-call-frame__wrapper', {
          'video-call-frame__wrapper--expanded': isFullScreen,
          'video-call-frame__wrapper--minimized': isMinized,
        })}
        style={videoCallWrapperStyle()}
      >
        <Row className={classnames('video-call-frame')}>
          <Col className='video-call-frame__toolbar' flex='55px' />
          <Col
            flex='auto'
            className={classnames('video-call-frame__body', {
              'video-call-frame__body--disabled': isLockFrame,
            })}
            onClick={handleClickJFrame}
          >
            <div className={classnames('video-call-frame__body-inner')}>
              <VideoCallBranding />
              <VideoCallRecommend />
              <VideoLoading loading={loading} />
              <JitsiFrame jitsiRef={ref} loading={loading} />
              {isLockFrame && <DisableFrame />}
            </div>
            <CallerRington
              ref={refCallerRing}
              isCallAccepted={isCallAccepted}
              hangup={endCall}
              callData={callData}
            />
          </Col>

          <Col
            className={classnames('video-call-frame__chat', {
              'video-call-frame__chat--invisible': isChatInvisible,
            })}
            style={{ width: 330 }}
          >
            <ChatVideo callData={callData} />
          </Col>

          <Col className='video-call-frame__toolbar' flex='55px'>
            <VideoCallToolbox
              loading={loading}
              isCallAccepted={isCallAccepted}
              isFullScreen={isFullScreen}
              toggleFullScreen={toggleFullScreen}
              endCall={endCall}
              toggleLockFrame={toggleLockFrame}
              endLoadScreen={endLoadScreen}
              toggleShareScreen={toggleShareScreen}
              isScreenSharing={isScreenSharing}
              toggleChatView={toggleChatView}
              isChatViewVideoOn={isChatViewVideoOn}
              toggleMinimize={toggleMinimize}
              isMinized={isMinized}
              callData={callData}
              userInfo={userInfo}
            />
          </Col>
        </Row>
      </div>
    </Draggable>
  );
};

VideoCall.propTypes = {
  //? data of current call
  callData: PropTypes.object,
  //? show video call frame or not
  isVideoCallOpen: PropTypes.bool,
};

export default VideoCall;
