import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Avatar, Empty, Typography, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import GroupCompanyDelete from '../../ui/MaximizeChatView/GroupCompanyDelete';
import GroupPeopleDelete from '../../ui/MaximizeChatView/GroupPeopleDelete';
import { CHATTYPE } from 'static/Constants';

const { Title } = Typography;

const ThreadUserList = ({ groupUsers }) => {
  const [userInitialList, setUserInitialList] = useState([]);
  const [companyInitialList, setCompanyInitialList] = useState([]);

  useEffect(() => {
    if (groupUsers?.length > 0) {
      const { sendersCompany, senderUser } = filterUsersDivideTwo(groupUsers);
      setUserInitialList(senderUser);
      setCompanyInitialList(sendersCompany);
    }
  }, [JSON.stringify(groupUsers)]);

  const filterUsersDivideTwo = (users) => {
    const sendersCompany = users
      .filter((item) => item.senderType === CHATTYPE.COMPANY)
      .map((item) => {
        return {
          companyId: item.senderId,
          companyName: item.name,
          logo: item.avatar,
        };
      });
    const senderUser = users
      .filter((item) => item.senderType === CHATTYPE.USER)
      .map((item) => {
        return {
          avatar: item.avatar,
          companyName: '',
          fullName: item.name,
          gender: '',
          userId: item.senderId,
        };
      });
    return { sendersCompany, senderUser };
  };

  return (
    <div>
      <Row justify='space-between'>
        <Title level={2}>Contacts</Title>
      </Row>
      {userInitialList?.length === 0 && companyInitialList?.length === 0 && (
        <Empty description='No contacts in chat group' />
      )}
      {userInitialList && userInitialList.length > 0 && (
        <>
          <Row>
            <Title level={5}>User list:</Title>
          </Row>
          <Row>
            <GroupPeopleDelete
              listPeople={userInitialList}
              isCreatorRole={false}
            />
          </Row>
        </>
      )}
      {companyInitialList && companyInitialList.length > 0 && (
        <>
          <Row>
            <Title level={5}>Company list:</Title>
          </Row>
          <Row>
            <GroupCompanyDelete
              listPeople={companyInitialList}
              isCreatorRole={false}
            />
          </Row>
        </>
      )}
    </div>
  );
};

ThreadUserList.propTypes = {};

export default ThreadUserList;
