import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/maintenance';

import { RibbonSection } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';

import * as communicationTemplateActions from 'pages/communication-template/controllers/actions';
import * as communicationTemplateSelectors from 'pages/communication-template/controllers/selectors';
import * as maintainHelpActions from 'pages/maintenance/controllers/actions';

import { forwardTo } from 'utils/common/route';

const EditView = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    communicationTemplateSelectors.selectToggleSaveTemplate()
  );

  return (
    <RibbonSection>
      <RibbonButton
        icon={<SaveOutlined />}
        label={Messages.saveTemplate}
        onClick={() =>
          dispatch(communicationTemplateActions.enableSaveTemplate())
        }
        loading={isLoading}
      />
      <RibbonButton
        icon={<CloseCircleOutlined />}
        label={Messages.cancelTemplate}
        onClick={() => {
          dispatch(communicationTemplateActions.resetTemplate());
          dispatch(maintainHelpActions.toggleUploadHelpMedia(false));
          forwardTo('/communication-templates');
        }}
      />
    </RibbonSection>
  );
};

export default EditView;
