import * as api from 'config/axios';
import * as endpoints from './endpoints';

// Get Folder List
export const getFolderList = (params) => {
  params.pageIndex = params.pageNumber;
  const endpoint = params.isFavoriteRoute
    ? endpoints.FAVORITE_FOLDER_LIST_ENDPOINT
    : endpoints.FOLDER_LIST_ENDPOINT;
  return api.sendPost(endpoint, params);
};

// Get Folder service
export const getFolderDetailGrid = (params) => {
  return api.sendPost(endpoints.FOLDER_DETAIL_GRID, params);
};

// Get Folder Detail Grid Distinct
export const getFolderDetailGridDistinct = (params) => {
  return api.sendPost(endpoints.FOLDER_DETAIL_GRID_DISTINCT, params);
};

export const getFavoriteFolderList = (params) => {
  return api.sendPost(endpoints.FAVORITE_FOLDER_LIST_ENDPOINT, params);
};

/**
 * User login service
 * @param {object} itemList List of item which need to find
 */
export const findInAllFolders = (params) => {
  const { itemList, pageIndex, pageSize } = params;
  const data = {
    itemList,
    pageIndex,
    pageSize,
  };
  return api.sendPost(endpoints.FIND_IN_ALL_FOLDERS, data);
};

/**
 * Delete entity in all folders
 * @param {array} itemList the list of entity with id and types
 */
export const deleteInAllFolders = (itemList) => {
  return api.sendPost(endpoints.DELETE_IN_ALL_FOLDERS, {
    itemList,
  });
};

export const createFolder = (params) => {
  return api.sendPost(endpoints.CREATE_FOLDER, params);
};

export const deleteFolders = (params) => {
  return api.sendPost(endpoints.DELETE_FOLDERS, params);
};

export const copyFolder = (params) => {
  return api.sendPost(endpoints.COPY_FOLDER, params);
};

export const moveFolder = (params) => {
  return api.sendPost(endpoints.MOVE_FOLDER, params);
};

export const getFolderListService = (params) => {
  return api.sendGet(endpoints.FOLDER_GRID_VIEW, {
    params: params,
  });
};
export const getFolderOwnByUser = (params) => {
  return api.sendGet(endpoints.FOLDER_OWN_BY_USER, {
    params: params,
  });
};
export const addEntityToFolder = (params) => {
  return api.sendPost(endpoints.ADD_ENTITY_TO_FOLDER, params);
};

export const getFolderShortDetail = (params) => {
  return api.sendGet(endpoints.FOLDER_SHORT_DETAIL, { params: params });
};

export const getMemberPreview = (params) => {
  return api.sendGet(endpoints.MEMBER_PREVIEW, { params: params });
};

export const getDigitalAssetPreview = (params) => {
  return api.sendGet(endpoints.ASSET_PREVIEW, { params: params });
};

// Get Download Setting
export const getDownloadSetting = (data) => {
  return api.sendGet(endpoints.GET_DOWNLOAD_SETTING, {
    params: data,
  });
};

// Folder Download
export const folderDownload = (data) => {
  return api.sendPost(endpoints.FOLDER_DOWNLOAD, { ...data });
};

export const deleteEntityAssocicationFromShortContent = (data) => {
  return api.sendPost(
    endpoints.DELETE_ENTITY_ASSOCIATION_FROM_SHORT_CONTENT,
    data
  );
};

// edit folder short content
export const editFolderShortContent = (params) => {
  return api.sendPost(endpoints.EDIT_FOLDER_SHORT_CONTENT, params);
};

export const configScheduleDownload = (params) => {
  return api.sendPost(endpoints.CONFIG_SCHEDULE_DOWNLOAD, { ...params });
};

// Folder details

export const getFolderHeaderDetailGrid = (params) => {
  return api.sendGet(endpoints.FOLDER_HEADER_DETAIL, { params: params });
};

export const getFolderDetailGridThumb = (params) => {
  return api.sendPost(endpoints.FOLDER_DETAIL_GRID_THUMB, params);
};

export const getQueryPreview = (params) => {
  return api.sendGet(endpoints.QUERY_PREVIEW, { params: params });
};
export const getReportingPreview = (params) => {
  return api.sendGet(endpoints.REPORTING_PREVIEW, { params: params });
};

export const getProductPreview = (params) => {
  return api.sendGet(endpoints.PRODUCT_PREVIEW, { params: params });
};
