import * as maintenanceFormServices from 'services/maintenanceForm';

import { dialogFunction } from 'common/components';

import { isStringHaveWhiteSpace } from 'utils';

import {
  ALIAS_NAME_REGEX,
  PARAM_ALIAS_NAME_REGEX,
} from 'pages/maintenance-form/constant';

export const confirmDeleteFormItem = (body) =>
  new Promise((resolve, reject) => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the form?',
      okText: 'OK',
      onCancel: () => reject(),
      onOk: async () => {
        const response = await maintenanceFormServices.deleteFormItem(body);
        resolve(response);
      },
    });
  });

export const confirmCloneFormItem = (body) =>
  new Promise((resolve, reject) => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to clone the selected form?',
      okText: 'OK',
      onCancel: () => reject(),
      onOk: async () => {
        const response = await maintenanceFormServices.cloneFormTemplate(body);
        resolve(response);
      },
    });
  });

export const isAliasNameEmpty = (aliasName, trim) => {
  return trim
    ? !aliasName || aliasName.trim().length === 0
    : !aliasName || aliasName.length === 0;
};

export const isAliasNameHaveWhiteSpace = (aliasName) => {
  return isStringHaveWhiteSpace(aliasName);
};

export const isAliasNameIncludeSpecialCharacters = (aliasName) => {
  return !ALIAS_NAME_REGEX.test(aliasName);
};

export const isParamAliasNameIncludeSpecialCharacters = (paramAliasName) => {
  return !PARAM_ALIAS_NAME_REGEX.test(paramAliasName);
};

export const isParamAliasInvalid = (aliasName) => {
  const isFailMaxLength = aliasName.length > 50;

  return (
    isFailMaxLength ||
    isAliasNameEmpty(aliasName) ||
    isAliasNameHaveWhiteSpace(aliasName) ||
    isParamAliasNameIncludeSpecialCharacters(aliasName)
  );
};
