import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Dropdown, Menu, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import './InputTimeOutLook.less';

const InputTimeOutLook = (props) => {
  const {
    idKey,
    handleChangeTime,
    value,
    formInstance,
    timeInput,
    setTimeInput,
    timeChange,
    setTimeChange,
  } = props;

  const [stateScrollToOption, setStateScrollToOption] = useState(false);

  useLayoutEffect(() => {
    const element = document.getElementById(idKey + 'idScrollToTimeOption');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [stateScrollToOption]);

  const { startDate, endDate } = formInstance.getFieldsValue();
  const currentDate = new Date();
  const checkDiffStartEnd =
    moment(startDate).format('YYYY-MM-DD') !==
    moment(endDate).format('YYYY-MM-DD');
  const formattedValue =
    idKey === 'start'
      ? moment('12:00 AM', 'hh:mm A')
      : checkDiffStartEnd
      ? moment('12:00 AM', 'hh:mm A')
      : moment(formInstance.getFieldValue('startTime'), 'hh:mm A');
  const hour = formattedValue.hour();
  const minute = formattedValue.minute();

  const objTime = [
    { h: 0, m: 0 },
    { h: 0, m: 30 },
    { h: 1, m: 0 },
    { h: 1, m: 30 },
    { h: 2, m: 0 },
    { h: 2, m: 30 },
    { h: 3, m: 0 },
    { h: 3, m: 30 },
    { h: 4, m: 0 },
    { h: 4, m: 30 },
    { h: 5, m: 0 },
    { h: 5, m: 30 },
    { h: 6, m: 0 },
    { h: 6, m: 30 },
    { h: 7, m: 0 },
    { h: 7, m: 30 },
    { h: 8, m: 0 },
    { h: 8, m: 30 },
    { h: 9, m: 0 },
    { h: 9, m: 30 },
    { h: 10, m: 0 },
    { h: 10, m: 30 },
    { h: 11, m: 0 },
    { h: 11, m: 30 },
    { h: 12, m: 0 },
    { h: 12, m: 30 },
    { h: 13, m: 0 },
    { h: 13, m: 30 },
    { h: 14, m: 0 },
    { h: 14, m: 30 },
    { h: 15, m: 0 },
    { h: 15, m: 30 },
    { h: 16, m: 0 },
    { h: 16, m: 30 },
    { h: 17, m: 0 },
    { h: 17, m: 30 },
    { h: 18, m: 0 },
    { h: 18, m: 30 },
    { h: 19, m: 0 },
    { h: 19, m: 30 },
    { h: 20, m: 0 },
    { h: 20, m: 30 },
    { h: 21, m: 0 },
    { h: 21, m: 30 },
    { h: 22, m: 0 },
    { h: 22, m: 30 },
    { h: 23, m: 0 },
    { h: 23, m: 30 },
  ];

  const handleCheckTimeUpdate = (e) => {
    if (timeChange) {
      const regexPattern = new RegExp(/^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/);

      const time =
        regexPattern.exec(moment(e.target.value, 'hh:mmA').format('hh:mm A')) ||
        regexPattern.exec(
          moment(e.target.value, 'hh:mm A').format('hh:mm A')
        ) ||
        regexPattern.exec(moment(e.target.value, 'hh:mm').format('hh:mm A')) ||
        regexPattern.exec(moment(e.target.value, 'hhmm').format('hh:mm A')) ||
        regexPattern.exec(moment(e.target.value, 'hmm').format('hh:mm A')) ||
        regexPattern.exec(moment(e.target.value, 'hh').format('hh:mm A'));

      if (time && time.length > 0) {
        return handleChangeTime(time[0]);
      } else {
        const date = new Date();
        const newStartDate = moment(date)
          .minute(0)
          .second(0)
          .add(1, 'h')
          .format('hh:mm A');
        return handleChangeTime(newStartDate);
      }
    }
  };

  const menu = (
    <Menu style={{ height: 200, width: 200 }} className='scroller'>
      {objTime.map((val) => {
        let time = moment(
          `${hour + val.h}:${minute + val.m}:00`,
          'HH:mm:ss'
        ).format('hh:mm A');
        let newStartDate = moment(startDate).hour(val.h).minute(val.m);

        let checkDisabled =
          idKey === 'end' ? false : moment(newStartDate).isBefore(currentDate);
        return (
          <Menu.Item
            key={`${idKey}${time}`}
            style={{ backgroundColor: 'inherit' }}
            id={value === time ? idKey + 'idScrollToTimeOption' : null}
          >
            <Button
              type='text'
              className={
                value === time ? 'btn-time btn-time--selected' : 'btn-time'
              }
              disabled={checkDisabled}
              onClick={() => {
                if (idKey === 'end' && !checkDiffStartEnd) {
                  handleChangeTime(
                    moment(`${val.h}:${val.m}`, 'hh:mm').format('hh:mm A'),
                    true
                  );
                } else {
                  handleChangeTime(time);
                }
              }}
            >
              {time}{' '}
              {idKey === 'end' && !checkDiffStartEnd
                ? val.h != 0
                  ? val.m === 0
                    ? '(' + val.h + ' hours)'
                    : '(' + val.h + '.5' + ' hours)'
                  : '(' + val.m + ' minutes)'
                : ''}
            </Button>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Input
      value={timeInput}
      suffix={
        <Dropdown
          overlay={menu}
          trigger={['click']}
          overlayStyle={{ height: 200 }}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <Button
            type='text'
            icon={<DownOutlined />}
            onClick={() => {
              setTimeChange(false);
              setStateScrollToOption(!stateScrollToOption);
            }}
          />
        </Dropdown>
      }
      onChange={(e) => {
        setTimeInput(e.target.value);
        setTimeChange(true);
      }}
      onPressEnter={(e) => handleCheckTimeUpdate(e)}
      onBlur={(e) => handleCheckTimeUpdate(e)}
    />
  );
};

export default InputTimeOutLook;
