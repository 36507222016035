import { defineMessages } from 'react-intl';

export const scope = 'Taco.product';

export default defineMessages({
  level: {
    id: `${scope}.components.fullView.level`,
    defaultMessage: 'Level',
  },
  GTIN: {
    id: `${scope}.components.fullView.GTIN`,
    defaultMessage: 'GTIN',
  },
  UPC12: {
    id: `${scope}.components.fullView.UPC12`,
    defaultMessage: 'UPC12',
  },
  UPC11: {
    id: `${scope}.components.fullView.UPC11`,
    defaultMessage: 'UPC11',
  },
  UPC10: {
    id: `${scope}.components.fullView.UPC10`,
    defaultMessage: 'UPC10',
  },
  EAN13: {
    id: `${scope}.components.fullView.EAN13`,
    defaultMessage: 'EAN13',
  },
  EAN8: {
    id: `${scope}.components.fullView.EAN8`,
    defaultMessage: 'EAN8',
  },
  height: {
    id: `${scope}.components.fullView.height`,
    defaultMessage: 'Height',
  },
  width: {
    id: `${scope}.components.fullView.width`,
    defaultMessage: 'Width',
  },
  depth: {
    id: `${scope}.components.fullView.depth`,
    defaultMessage: 'Depth',
  },
  weight: {
    id: `${scope}.components.fullView.weight`,
    defaultMessage: 'Weight',
  },
  hierarchyTitle: {
    id: `${scope}.components.fullView.hierarchyTitle`,
    defaultMessage: 'Hierarchy Pack Configuration',
  },
  hierarchies: {
    id: `${scope}.components.fullView.hierarchies`,
    defaultMessage: 'Hierarchies',
  },
  deletePackageLevelMessage: {
    id: `${scope}.components.fullView.deletePackageLevelMessage`,
    defaultMessage: 'Are you sure you want to delete current package level?',
  },
  hierarchyProductNoInfo: {
    id: `${scope}.components.fullView.hierarchy.product.noDescription`,
    defaultMessage: 'No Description',
  },
  previewRequirementsProduct: {
    id: `${scope}.components.product.previewRequirementsProduct`,
    defaultMessage: 'Validate Product',
  },
  deletePackageLevelYes: {
    id: `${scope}.components.fullView.deletePackageLevelYes`,
    defaultMessage: 'Yes',
  },
  status: {
    id: `${scope}.components.fullView.status`,
    defaultMessage: 'Status',
  },
  modified: {
    id: `${scope}.components.fullView.modified`,
    defaultMessage: 'Modified',
  },
  visibility: {
    id: `${scope}.components.fullView.visibility`,
    defaultMessage: 'Visibility',
  },
  riverId: {
    id: `${scope}.components.fullView.riverId`,
    defaultMessage: 'RIVIR ID',
  },
  ixOneId: {
    id: `${scope}.components.fullView.ixOneId`,
    defaultMessage: 'IX-ONE ID',
  },
  rivirID: {
    id: `${scope}.components.fullView.rivirID`,
    defaultMessage: 'RIVIR ID',
  },
  type: {
    id: `${scope}.components.fullView.type`,
    defaultMessage: 'Type',
  },
  editBrandMode: {
    id: `${scope}.components.fullView.editBrandMode`,
    defaultMessage: 'Edit Brand',
  },
  anotherBrand: {
    id: `${scope}.components.fullView.anotherBrand`,
    defaultMessage: 'Another Brand',
  },
  brandNameIsRequired: {
    id: `${scope}.components.fullView.brandNameIsRequired`,
    defaultMessage: 'Brand name is required!',
  },
  anotherBrandIsRequired: {
    id: `${scope}.components.fullView.anotherBrandIsRequired`,
    defaultMessage: 'Another brand is required!',
  },
  editBrandModeIsRequired: {
    id: `${scope}.components.fullView.editBrandModeIsRequired`,
    defaultMessage: 'Edit brand mode is required!',
  },
  editProductBrandSuccess: {
    id: `${scope}.components.fullView.editProductBrandSuccess`,
    defaultMessage: 'Edit successfully!',
  },
  pleaseUpdateBrandImage: {
    id: `${scope}.components.fullView.pleaseUpdateBrandImage`,
    defaultMessage: 'Brand logo is required',
  },
  brandName: {
    id: `${scope}.components.fullView.brandName`,
    defaultMessage: 'Brand Name',
  },
  supplierNames: {
    id: `${scope}.components.fullView.supplierNames`,
    defaultMessage: 'Supplier Name',
  },
  targetMarket: {
    id: `${scope}.components.fullView.targetMarket`,
    defaultMessage: 'Target Market',
  },
  productType: {
    id: `${scope}.components.fullView.productType`,
    defaultMessage: 'Product Type',
  },
  downloadHierarchySuccess: {
    id: `${scope}.components.fullView.downloadHierarchySuccess`,
    defaultMessage: 'Download successfully!',
  },
  createBrandError: {
    id: `${scope}.components.fullView.createBrandError`,
    defaultMessage: 'Cannot create new brand',
  },
  serverError: {
    id: `${scope}.components.fullView.serverError`,
    defaultMessage: 'Server Error!',
  },
  existedBrand: {
    id: `${scope}.components.fullView.existedBrand`,
    defaultMessage: 'Existing Brand(s)',
  },
  other: {
    id: `${scope}.components.fullView.other`,
    defaultMessage: 'Other',
  },
  currentBrand: {
    id: `${scope}.components.fullView.currentBrand`,
    defaultMessage: 'Current Brand',
  },
  newBrand: {
    id: `${scope}.components.fullView.newBrand`,
    defaultMessage: 'New Brand',
  },
  barcode: {
    id: `${scope}.components.fullView.barcode`,
    defaultMessage: 'Barcode',
  },
  bricCode: {
    id: `${scope}.components.fullView.bricCode`,
    defaultMessage: 'Brick Code',
  },
  description: {
    id: `${scope}.components.fullView.description`,
    defaultMessage: 'Description',
  },
  size: {
    id: `${scope}.components.fullView.size`,
    defaultMessage: 'Size',
  },
  dimensions: {
    id: `${scope}.components.fullView.dimensions`,
    defaultMessage: 'Dimensions',
  },
  certification: {
    id: `${scope}.components.fullView.certification`,
    defaultMessage: 'Certifications',
  },
  documentBackupModal: {
    id: `${scope}.components.fullView.documentBackupModal`,
    defaultMessage: 'Documents Modal',
  },
  totalItems: {
    id: `${scope}.components.fullView.totalItems`,
    defaultMessage: 'items found',
  },
  packageLevelTooltip: {
    id: `${scope}.components.fullView.packageLevelTooltip`,
    defaultMessage:
      'This product is in hierarchy, please remove this from all hierarchy to enable this field.',
  },
  minimumRequirementMessage: {
    id: `${scope}.components.fullView.minimumRequirementMessage`,
    defaultMessage: 'This field is required for active product',
  },
  hierarchyTypeGtin: {
    id: `${scope}.components.fullView.hierarchy.gtin`,
    defaultMessage: 'GTIN',
  },
  hierarchyTypeUpc12: {
    id: `${scope}.components.fullView.hierarchy.upc12`,
    defaultMessage: 'UPC12',
  },
  pallet: {
    id: `${scope}.components.fullView.hierarchy.pallet`,
    defaultMessage: 'Pallet',
  },
  masterCase: {
    id: `${scope}.components.fullView.hierarchy.masterCase`,
    defaultMessage: 'Master case',
  },
  case: {
    id: `${scope}.components.fullView.hierarchy.case`,
    defaultMessage: 'Case',
  },
  innerPack: {
    id: `${scope}.components.fullView.hierarchy.inner`,
    defaultMessage: 'Inner pack',
  },
  unit: {
    id: `${scope}.components.fullView.hierarchy.unit`,
    defaultMessage: 'Unit',
  },
  viewProduct: {
    id: `${scope}.components.fullView.hierarchy.viewProduct`,
    defaultMessage: 'View Product',
  },
  searchProductPlaceholder: {
    id: `${scope}.components.fullView.hierarchy.searchProductPlaceholder`,
    defaultMessage: 'Search product',
  },
  searchProductError: {
    id: `${scope}.components.fullView.hierarchy.searchProductError`,
    defaultMessage: 'Cannot search any product',
  },
  addHierarchyTitle: {
    id: `${scope}.components.fullView.hierarchy.addTitle`,
    defaultMessage: 'Add Hierarchy',
  },
  editHierarchyTitle: {
    id: `${scope}.components.fullView.hierarchy.editTitle`,
    defaultMessage: 'Edit Hierarchy',
  },
  copyHierarchyTitle: {
    id: `${scope}.components.fullView.hierarchy.copyTitle`,
    defaultMessage: 'Copy Hierarchy',
  },
  addHierarchy: {
    id: `${scope}.components.fullView.hierarchy.add`,
    defaultMessage: 'Add',
  },
  editHierarchy: {
    id: `${scope}.components.fullView.hierarchy.edit`,
    defaultMessage: 'Submit',
  },
  selectProductHierarchy: {
    id: `${scope}.components.fullView.hierarchy.selectProduct`,
    defaultMessage: 'Select',
  },
  revertTooltip: {
    id: `${scope}.components.fullView.hierarchy.revertTooltip`,
    defaultMessage: 'Revert',
  },
  quantityPlaceholder: {
    id: `${scope}.components.fullView.hierarchy.quantityPlaceholder`,
    defaultMessage: 'Quantity',
  },
  changeProduct: {
    id: `${scope}.components.fullView.hierarchy.changeProduct`,
    defaultMessage: 'Change product',
  },
  changeProductTooltip: {
    id: `${scope}.components.fullView.hierarchy.changeProductTooltip`,
    defaultMessage: 'Change product to copy',
  },
  required: {
    id: `${scope}.components.fullView.hierarchy.required`,
    defaultMessage: 'Required',
  },
  addHierarchySuccess: {
    id: `${scope}.components.fullView.hierarchy.addSuccess`,
    defaultMessage: 'Add new hierarchy successfully',
  },
  addHierarchyError: {
    id: `${scope}.components.fullView.hierarchy.addError`,
    defaultMessage: 'Cannot add new hierarchy',
  },
  editHierarchySuccess: {
    id: `${scope}.components.fullView.hierarchy.editSuccess`,
    defaultMessage: 'Edit hierarchy successfully',
  },
  editHierarchyError: {
    id: `${scope}.components.fullView.hierarchy.editError`,
    defaultMessage: 'Cannot edit hierarchy',
  },
  copyHierarchySuccess: {
    id: `${scope}.components.fullView.hierarchy.copySuccess`,
    defaultMessage: 'Copy hierarchy successfully',
  },
  copyHierarchyError: {
    id: `${scope}.components.fullView.hierarchy.copyError`,
    defaultMessage: 'Cannot copy hierarchy',
  },
  deletedHierarchy: {
    id: `${scope}.components.fullView.hierarchy.deleteHierarchy`,
    defaultMessage: 'Deleted',
  },
  savePrivateProperties: {
    id: `${scope}.components.fullView.detail.savePrivateProperties`,
    defaultMessage: 'Save private properties successfully',
  },
  productHistoryTitle: {
    id: `${scope}.components.history.title`,
    defaultMessage: 'Product History',
  },
  noVersionDescription: {
    id: `${scope}.components.history.noVersions`,
    defaultMessage: 'This product item has not been updated yet!',
  },
  productNameHistory: {
    id: `${scope}.components.history.productName`,
    defaultMessage: 'Product Name',
  },
  upc12History: {
    id: `${scope}.components.history.upc12`,
    defaultMessage: 'UPC12',
  },
  createdByHistory: {
    id: `${scope}.components.history.createdBy`,
    defaultMessage: 'Created By',
  },
  modifiedAtHistory: {
    id: `${scope}.components.history.modifiedAt`,
    defaultMessage: 'Modified At',
  },
  moduleName: {
    id: `${scope}.components.history.moduleName`,
    defaultMessage: 'Module Name',
  },
  gtinHistory: {
    id: `${scope}.components.history.gtin`,
    defaultMessage: 'GTIN',
  },
  modifiedByHistory: {
    id: `${scope}.components.history.modifiedBy`,
    defaultMessage: 'Modified By',
  },
  createProductSuccess: {
    id: `${scope}.components.pimAction.create`,
    defaultMessage: 'Create product successfully.',
  },
  uploadTdcSuccess: {
    id: `${scope}.components.pimAction.uploadTdcSuccess`,
    defaultMessage:
      'Importing TDC product data is being processed. You will receive result email shortly.',
  },
  createAPLAction: {
    id: `${scope}.components.apl.createAPLAction`,
    defaultMessage: 'Create APL',
  },
  editAPLAction: {
    id: `${scope}.components.apl.editAPLAction`,
    defaultMessage: 'Edit APL',
  },
  deleteAPLAction: {
    id: `${scope}.components.apl.deleteAPLAction`,
    defaultMessage: 'Delete APL',
  },
  openAPLAction: {
    id: `${scope}.components.apl.openAPLAction`,
    defaultMessage: 'Open APL',
  },
  confirmDeleteProductToApl: {
    id: `${scope}.components.apl.confirmDeleteProductToApl`,
    defaultMessage: 'Are you sure want to delete selected APL item?',
  },
  confirmExecuteMatchingApl: {
    id: `${scope}.components.apl.confirmExecuteMatchingApl`,
    defaultMessage: 'Are you sure want to execute matching the APL?',
  },
  executeMatchingAplSuccess: {
    id: `${scope}.components.apl.executeMatchingAplSuccess`,
    defaultMessage: 'Execute Matching the APL successfully',
  },
  deleteProductAplSuccess: {
    id: `${scope}.ribbon.product.apl.deleteProductAplSuccess`,
    defaultMessage: 'Delete product item APL successfully',
  },
  addProductToAplSuccess: {
    id: `${scope}.components.apl.confirmDeleteProductToApl`,
    defaultMessage: 'Add product to APL successfully',
  },
  addProductToAplLabel: {
    id: `${scope}.components.apl.confirmDeleteProductToApl`,
    defaultMessage: 'Add product to APL',
  },
  addSearchProductToAplSuccess: {
    id: `${scope}.components.apl.confirmDeleteProductToApl`,
    defaultMessage: 'Add search results to APL successfully',
  },
  addSearchProductToAplLabel: {
    id: `${scope}.components.apl.confirmDeleteProductToApl`,
    defaultMessage: 'Add search results to APL',
  },
  editAplInfoMetadata: {
    id: `${scope}.components.apl.editAplInfoMetadata`,
    defaultMessage: 'Edit Info Metadata',
  },
  editAplInfoMetadataSuccess: {
    id: `${scope}.components.apl.editAplInfoMetadata`,
    defaultMessage: 'Edit Info Successfully',
  },
  openProduct: {
    id: `${scope}.components.fullView.overview.openProduct`,
    defaultMessage: 'open',
  },
  replaceAsset: {
    id: `${scope}.components.fullView.replaceAsset`,
    defaultMessage: 'Replace Asset',
  },
  linkToNewAsset: {
    id: `${scope}.components.fullView.linkToNewAsset`,
    defaultMessage: 'Link to New asset',
  },
  linkToExistingAsset: {
    id: `${scope}.components.fullView.linkToExistingAsset`,
    defaultMessage: 'Link to Existing asset',
  },
  addProductSuccess: {
    id: `${scope}.components.product.addSuccess`,
    defaultMessage: 'Add Product Success',
  },
  addProduct: {
    id: `${scope}.components.product.add`,
    defaultMessage: 'Add Product',
  },
  editProduct: {
    id: `${scope}.components.product.edit`,
    defaultMessage: 'Edit Product',
  },
  cloneProduct: {
    id: `${scope}.components.product.clone`,
    defaultMessage: 'Copy Product',
  },
  productNameRequired: {
    id: `${scope}.components.product.name.required`,
    defaultMessage: 'Product Name is required',
  },
  productTypeRequired: {
    id: `${scope}.components.product.type.required`,
    defaultMessage: 'Product Type is required',
  },
  productGTINRequired: {
    id: `${scope}.components.product.gtin.required`,
    defaultMessage: 'Product GTIN is required',
  },
  productPackageLvRequired: {
    id: `${scope}.components.product.packageLevel.required`,
    defaultMessage: 'Package Level is required',
  },
  productUPC12: {
    id: `${scope}.components.product.upc12.required`,
    defaultMessage: 'UPC12 is required',
  },
  hierarchyEndpointPreviewDefaultText: {
    id: `${scope}.components.fullView.hierarchy.endpointPreviewDefaultText`,
    defaultMessage: 'Adding Hierarchy',
  },
  updateStatusActiveError: {
    id: `${scope}.components.product.updateStatusError`,
    defaultMessage: 'Cannot update Status to Active',
  },
  updateProductSuccess: {
    id: `${scope}.components.product.updateSuccess`,
    defaultMessage: 'Update product successfully.',
  },
  updateProductExceptStatus: {
    id: `${scope}.components.product.updateProductExceptStatus`,
    defaultMessage:
      "However, following fields' values are missing to activate product",
  },
  warningMessage: {
    id: `${scope}.components.product.warningMessage`,
    defaultMessage: "However, following fields' values are missing to activate",
  },
  createAPLSuccess: {
    id: `${scope}.components.product.createAPLSuccess`,
    defaultMessage: 'Create APL successfully',
  },
  cancelBtn: {
    id: `${scope}.components.product.cancelBtn`,
    defaultMessage: 'Cancel',
  },
  okBtn: {
    id: `${scope}.components.product.okBtn`,
    defaultMessage: 'Submit',
  },
  createNewAPLLabel: {
    id: `${scope}.components.product.createNewAPLLabel`,
    defaultMessage: 'Create New APL',
  },
  addProductToNewAPLLabel: {
    id: `${scope}.components.product.addProductToNewAPLLabel`,
    defaultMessage: 'Add Product(s) To New APL',
  },
  addProductToNewAPLSuccess: {
    id: `${scope}.components.product.addProductToNewAPLSuccess`,
    defaultMessage: 'Add Product(s) To New APL successfully',
  },
  addFolderToNewAPLSuccess: {
    id: `${scope}.components.product.addProductToNewAPLSuccess`,
    defaultMessage: 'Add Folder(s) To New APL successfully',
  },
  linkToExistAsset: {
    id: `${scope}.components.product.linkToExistAsset`,
    defaultMessage: 'Link existing asset.',
  },
  packageLevel: {
    id: `${scope}.components.product.packageLevel`,
    defaultMessage: 'Package Level',
  },
  facing: {
    id: `${scope}.components.product.facing`,
    defaultMessage: 'Facing',
  },
  angle: {
    id: `${scope}.components.product.angle`,
    defaultMessage: 'Angle',
  },
  planoFileName: {
    id: `${scope}.components.product.planoFileName`,
    defaultMessage: 'Planogram File Name',
  },
  packageDisposition: {
    id: `${scope}.components.product.packageDisposition`,
    defaultMessage: 'Package Disposition',
  },
  complaintFileName: {
    id: `${scope}.components.product.packageDisposition`,
    defaultMessage: 'Compliant File Name',
  },
  mappingConfig: {
    id: `${scope}.components.product.mappingConfig`,
    defaultMessage: 'Mapping Config',
  },
  editMappingConfig: {
    id: `${scope}.components.product.editMappingConfig`,
    defaultMessage: 'Edit Mapping Config',
  },
  editLogoTitle: {
    id: `${scope}.components.product.editLogoTitle`,
    defaultMessage: 'Select Logo Image',
  },
  startAt: {
    id: `${scope}.components.syndication.startAt`,
    defaultMessage: 'Start at',
  },
  recipient: {
    id: `${scope}.components.syndication.recipient`,
    defaultMessage: 'Recipient:',
  },
  method: {
    id: `${scope}.components.syndication.method`,
    defaultMessage: 'Method',
  },
  dataFormat: {
    id: `${scope}.components.syndication.dataFormat`,
    defaultMessage: 'Data Format',
  },
  targetCompany: {
    id: `${scope}.components.syndication.targetCompany`,
    defaultMessage: 'Target Company',
  },
  publicationType: {
    id: `${scope}.components.syndication.publicationType`,
    defaultMessage: 'Publication Type',
  },
  errorMessages: {
    id: `${scope}.components.syndication.errorMessages`,
    defaultMessage: 'Error Messages',
  },
  rejectReasonMessages: {
    id: `${scope}.components.syndication.rejectReasonMessages`,
    defaultMessage: 'Rejection Reason Messages',
  },
  validationFail: {
    id: `${scope}.components.syndication.validationFail`,
    defaultMessage: 'Validation Fail',
  },
  recipientGLN: {
    id: `${scope}.components.syndication.recipientGLN`,
    defaultMessage: 'Recipient GLN',
  },
  sourceGLN: {
    id: `${scope}.components.syndication.sourceGLN`,
    defaultMessage: 'Source GLN',
  },
  syncNutritionToAllergen: {
    id: `${scope}.components.productFullView.syncNutritionToAllergen`,
    defaultMessage: 'Allergen information has been updated successfully!',
  },
  syncAllergenToNutrition: {
    id: `${scope}.components.productFullView.syncNutritionToAllergen`,
    defaultMessage: 'Nutrition facts has been updated successfully!',
  },
  syncNutritionToIngredient: {
    id: `${scope}.components.productFullView.syncNutritionToIngredient`,
    defaultMessage: 'Ingredient statement has been updated successfully!',
  },
  syncIngredientToNutrition: {
    id: `${scope}.components.productFullView.syncIngredientToNutrition`,
    defaultMessage: 'Nutrition facts has been updated successfully!',
  },
  bulkEditProduct: {
    id: `${scope}.components.bulkEdit`,
    defaultMessage: 'Bulk Edit Product(s)',
  },
  exportSelectedProduct: {
    id: `${scope}.components.exportSelectedProduct`,
    defaultMessage: 'Export Selected Product(s)',
  },
  importBuildEdit: {
    id: `${scope}.components.importBuildEdit`,
    defaultMessage: 'Import Product For Bulk Editing',
  },
  exportProperties: {
    id: `${scope}.components.exportProperties`,
    defaultMessage: 'Export Properties',
  },
  copyProperties: {
    id: `${scope}.components.copyProperties`,
    defaultMessage: 'Copy Properties',
  },
  exportPropertiesSearch: {
    id: `${scope}.components.exportPropertiesSearch`,
    defaultMessage: 'Search Properties',
  },
  exportPropertiesExpandAll: {
    id: `${scope}.components.exportPropertiesExpandAll`,
    defaultMessage: 'Expand All',
  },
  exportPropertiesShowSelection: {
    id: `${scope}.components.exportPropertiesShowSelection`,
    defaultMessage: 'Show Selection Only',
  },
  exportPropertiesGetDataError: {
    id: `${scope}.components.exportPropertiesGetDataError`,
    defaultMessage: 'Cannot get data',
  },
  exportPropertiesSubmit: {
    id: `${scope}.components.exportPropertiesGetDataError`,
    defaultMessage: 'Submit',
  },
  totalItemSelected: {
    id: `${scope}.components.totalItemSelected`,
    defaultMessage: 'item selected',
  },
  totalItemsSelected: {
    id: `${scope}.components.totalItemsSelected`,
    defaultMessage: 'items selected',
  },
  sectionTitle: {
    productProperties: {
      id: `${scope}.components.sectionTitle.productProperties`,
      defaultMessage: 'Product Properties',
    },
    exportProperties: {
      id: `${scope}.components.sectionTitle.exportProperties`,
      defaultMessage: 'Export Properties',
    },
    copyProperties: {
      id: `${scope}.components.sectionTitle.copyProperties`,
      defaultMessage: 'Copy Properties',
    },
  },
  searchProductCustomPropertiesPlaceholder: {
    id: `${scope}.components.productFullView.searchProductCustomPropertiesPlaceholder`,
    defaultMessage: 'Search for field',
  },
  searchProductCustomPropertiesNumberError: {
    id: `${scope}.components.productFullView.searchProductCustomPropertiesNumberError`,
    defaultMessage: 'This field is required a number',
  },
  saveProductCustomPropertiesSuccess: {
    id: `${scope}.components.productFullView.saveProductCustomPropertiesSuccess`,
    defaultMessage: 'Save product custom properties successfully!',
  },
  saveProductCustomPropertiesError: {
    id: `${scope}.components.productFullView.saveProductCustomPropertiesError`,
    defaultMessage: 'Cannot save product custom properties',
  },
  getProductPropertyErrorMessage: {
    id: `${scope}.components.productFullView.getProductPropertyErrorMessage`,
    defaultMessage: 'Cannot get product properties',
  },
  exportProductPropertiesFieldNameLabel: {
    id: `${scope}.components.productFullView.exportProductPropertiesFieldNameLabel`,
    defaultMessage: 'Field Name',
  },
  exportProductPropertiesFieldTypeLabel: {
    id: `${scope}.components.productFullView.exportProductPropertiesFieldTypeLabel`,
    defaultMessage: 'Type',
  },
  exportProductPropertiesError: {
    id: `${scope}.components.productFullView.exportProductPropertiesError`,
    defaultMessage: 'Cannot export the selected product',
  },
  copyProductPropertiesError: {
    id: `${scope}.components.productFullView.copyProductPropertiesError`,
    defaultMessage: 'Cannot copy the selected product',
  },
  exportProductPropertiesNoHeaderTitle: {
    id: `${scope}.components.productFullView.exportProductPropertiesNoHeaderTitle`,
    defaultMessage: 'Product Basic Information',
  },
  importProductBulkEditTitle: {
    id: `${scope}.components.productFullView.importProductBulkEditTitle`,
    defaultMessage: 'Upload Product(s) For Bulk Editing',
  },
  importProductBulkEditSuccess: {
    id: `${scope}.components.productFullView.importProductBulkEditSuccess`,
    defaultMessage: 'Edit product(s) successfully',
  },
  importProductBulkEditError: {
    id: `${scope}.components.productFullView.importProductBulkEditError`,
    defaultMessage: 'Cannot edit product(s)',
  },
  exportPropertiesDownloadManualTitleToolTip: {
    id: `${scope}.components.productFullView.exportPropertiesDownloadManualTitleToolTip`,
    defaultMessage: 'Download User Guide ',
  },
  exportPropertiesDownloadManualError: {
    id: `${scope}.components.productFullView.exportPropertiesDownloadManualTitleToolTip`,
    defaultMessage: 'Cannot download user guide',
  },
  validateStatusMessage: {
    warning: {
      id: `${scope}.components.productFullView.exportPropertiesDownloadManualTitleToolTip`,
      defaultMessage: 'Missing requirements to Active',
    },
    success: {
      id: `${scope}.components.productFullView.exportPropertiesDownloadManualTitleToolTip`,
      defaultMessage: 'Validation success. Active status could be set',
    },
  },
  validateLoading: {
    id: `${scope}.components.productFullView.validateLoading`,
    defaultMessage: 'Validating...',
  },
  supplementLabelNutrients: {
    id: `${scope}.components.productFullView.supplementLabel.nutrientTitle`,
    defaultMessage: 'Nutrients',
  },
  supplementLabelDietaryIngredient: {
    id: `${scope}.components.productFullView.supplementLabel.DietaryIngredient`,
    defaultMessage: 'Dietary Ingredients',
  },
  supplementServingSize: {
    id: `${scope}.components.productFullView.supplementLabel.servingSize`,
    defaultMessage: 'Serving Size',
  },
  supplementLabelTypeLabel: {
    id: `${scope}.components.productFullView.supplementLabel.label.labelType`,
    defaultMessage: 'Label Type',
  },
  supplementServingSizeValueLabel: {
    id: `${scope}.components.productFullView.supplementLabel.label.servingSize.value`,
    defaultMessage: 'Value',
  },
  supplementServingSizeUmoLabel: {
    id: `${scope}.components.productFullView.supplementLabel.label.servingSize.umo`,
    defaultMessage: 'Unit of Measure',
  },
  supplementServingSizeDescriptionLabel: {
    id: `${scope}.components.productFullView.supplementLabel.label.servingSize.description`,
    defaultMessage: 'Description',
  },
  supplementServingPerContainerLabel: {
    id: `${scope}.components.productFullView.supplementLabel.label.servingPerContainer`,
    defaultMessage: 'Servings Per Container',
  },
  supplementNutrientName: {
    id: `${scope}.components.productFullView.supplementLabel.label.nutrientName`,
    defaultMessage: 'Nutrient Name',
  },
  supplementIngredientName: {
    id: `${scope}.components.productFullView.supplementLabel.label.ingredientName`,
    defaultMessage: 'Dietary Ingredient Name',
  },
  supplementPartOf: {
    id: `${scope}.components.productFullView.supplementLabel.label.PartOf`,
    defaultMessage: 'Part Of',
  },
  supplementQuantity: {
    id: `${scope}.components.productFullView.supplementLabel.label.quantity`,
    defaultMessage: 'Quantity',
  },
  supplementQuantityDescription: {
    id: `${scope}.components.productFullView.supplementLabel.label.quantityDescription`,
    defaultMessage: 'Quantity Description',
  },
  supplementDailyValue: {
    id: `${scope}.components.productFullView.supplementLabel.label.dailyValue`,
    defaultMessage: 'Daily Value',
  },
  supplementDailySymbol: {
    id: `${scope}.components.productFullView.supplementLabel.label.dailySymbol`,
    defaultMessage: 'Daily Symbol',
  },
  supplementCalories: {
    id: `${scope}.components.productFullView.supplementLabel.label.calories`,
    defaultMessage: 'Calories',
  },
  supplementCaloriesFat: {
    id: `${scope}.components.productFullView.supplementLabel.label.caloriesFat`,
    defaultMessage: 'Calories from Fat',
  },
  supplementFooterNote: {
    id: `${scope}.components.productFullView.supplementLabel.label.footerNote`,
    defaultMessage: 'Footer Note',
  },
  supplementOtherIngredients: {
    id: `${scope}.components.productFullView.supplementLabel.label.otherIngredients`,
    defaultMessage: 'Other Ingredients',
  },
  supplementSpecialCharacters: {
    id: `${scope}.components.productFullView.supplementLabel.label.specialCharacter`,
    defaultMessage: 'Special Character',
  },
  supplementSpecialCharactersStar: {
    id: `${scope}.components.productFullView.supplementLabel.label.specialCharacter.star`,
    defaultMessage: '* Percent Daily Values are based a 2,000 calories diet.',
  },
  supplementSpecialCharactersPlus: {
    id: `${scope}.components.productFullView.supplementLabel.label.specialCharacter.plus`,
    defaultMessage: '† Percent Daily Value not established',
  },
  supplementLabelTypeStandard: {
    id: `${scope}.components.productFullView.supplementLabel.label.labelType.standard`,
    defaultMessage: 'Standard',
  },
  supplementAddButton: {
    id: `${scope}.components.productFullView.supplementLabel.button.add`,
    defaultMessage: 'Add',
  },
  supplementUpdateButton: {
    id: `${scope}.components.productFullView.supplementLabel.button.update`,
    defaultMessage: 'Update',
  },
  supplementAddNewButton: {
    id: `${scope}.components.productFullView.supplementLabel.button.addNew`,
    defaultMessage: 'Add New',
  },
  supplementCancelButton: {
    id: `${scope}.components.productFullView.supplementLabel.button.cancel`,
    defaultMessage: 'Cancel',
  },
  supplementItalicName: {
    id: `${scope}.components.productFullView.supplementLabel.italicName`,
    defaultMessage: 'Italic Name',
  },
  supplementItalicDescriptor: {
    id: `${scope}.components.productFullView.supplementLabel.italicDescriptor`,
    defaultMessage: 'Italic Descriptor',
  },
  supplementDescriptor: {
    id: `${scope}.components.productFullView.supplementLabel.descriptor`,
    defaultMessage: 'Descriptor',
  },
  versionCollapseTitle: {
    0: {
      id: `${scope}.fullView.history.collapseTitle.today`,
      defaultMessage: 'Today',
    },
    1: {
      id: `${scope}.fullView.history.collapseTitle.yesterday`,
      defaultMessage: 'Yesterday',
    },
    7: {
      id: `${scope}.fullView.history.collapseTitle.7days`,
      defaultMessage: 'Last 7 days',
    },
    30: {
      id: `${scope}.fullView.history.collapseTitle.lastMonth`,
      defaultMessage: 'Last month',
    },
    older: {
      id: `${scope}.fullView.history.collapseTitle.older`,
      defaultMessage: 'Older',
    },
  },
  modifiedAt: {
    id: `${scope}.fullView.modifyAt`,
    defaultMessage: 'Modified At',
  },
});
