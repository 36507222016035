import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Typography, Button, Empty } from 'antd';
import classnames from 'classnames';
import './ImageArea.less';
import { Images } from 'config/assets';
import { ThumbnailItem } from 'common/components';
import { InfoWithLabel } from 'common/components';
import messagesProduct from 'i18n/messages/product';

import { mapFileTypeToImage } from 'utils/fileType';

const { Text } = Typography;
const ImageArea = (props) => {
  const {
    imageList,
    onClick,
    title,
    imagePreview,
    visible,
    idField,
    type,
    onClickItem,
    itemPreview,
    isMarketing,
  } = props;
  const [selected, setSelected] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    if (imageList) {
      const imageTemp = imagePreview?.map((item) => item?.[idField]);
      setSelected(imageTemp);
    }
  }, [imagePreview, visible]);

  const handleClick = (item) => {
    const isTrue =
      selected?.filter((value) => value === item?.[idField])?.length > 0;
    if (selected && !isTrue) {
      setSelected(selected.concat([item?.[idField]]));
    } else if (selected) {
      const selectTemp = selected.filter((value) => value !== item?.[idField]);
      setSelected(selectTemp);
    }
    onClick(item);
  };

  const onClickItemGrid = (item) => {
    onClickItem && onClickItem(item);
  };

  const replaceError = (value) => {
    value.target.src = Images.RIVIR_LOGO_DEFAULT;
  };

  const renderProductImage = (item) => {
    return (
      item?.thumb500 ||
      item?.thumb1000 ||
      item?.thumbFull ||
      mapFileTypeToImage(item?.fileType)
    );
  };

  const renderMarketingImage = (item) => {
    return (
      item?.thumb500 ||
      item?.thumb1000 ||
      item?.thumbFull ||
      mapFileTypeToImage(item?.fileType)
    );
  };

  const checkButton = () => {
    if (selected?.length === imageList?.length) {
      setSelected([]);
      onClick('remove');
    } else {
      const temp = imageList?.map((item) => item?.[idField]);
      setSelected(temp);
      onClick('all');
    }
  };

  const infoColProps = {
    infoColProps: {
      flex: 'auto',
      style: { maxWidth: 'calc(100% - 150px)' },
    },
    labelColProps: {
      flex: '150px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  return (
    <>
      <Row style={{ height: '100%', padding: 20 }}>
        <Col flex={'50px'}>
          <Text className='image-area__slider-title'>{title}</Text>
        </Col>
        <Col flex={'calc(100% - 50px)'} style={{ height: '100%' }}>
          {!isMarketing && (
            <Row className='image-area__meta-data' gutter={2}>
              <>
                <Col xl={12} xxl={8}>
                  <InfoWithLabel
                    info={
                      itemPreview?.packageLevel
                        ? itemPreview?.packageLevel?.toUpperCase()
                        : ''
                    }
                    label={intl.formatMessage(messagesProduct.packageLevel)}
                    {...infoColProps}
                  />
                </Col>
                <Col xl={12} xxl={8}>
                  <InfoWithLabel
                    info={
                      itemPreview?.angle
                        ? itemPreview?.angle?.toUpperCase()
                        : ''
                    }
                    label={intl.formatMessage(messagesProduct.angle)}
                    {...infoColProps}
                  />
                </Col>
                <Col xl={12} xxl={8}>
                  <InfoWithLabel
                    info={
                      itemPreview?.compliantFileName
                        ? itemPreview?.compliantFileName?.toUpperCase()
                        : ''
                    }
                    label={intl.formatMessage(
                      messagesProduct.complaintFileName
                    )}
                    {...infoColProps}
                    showTooltip
                  />
                </Col>
                <Col xl={12} xxl={8}>
                  <InfoWithLabel
                    info={
                      itemPreview?.packagingDisposition
                        ? itemPreview?.packagingDisposition?.toUpperCase()
                        : ''
                    }
                    label={intl.formatMessage(
                      messagesProduct.packageDisposition
                    )}
                    {...infoColProps}
                  />
                </Col>
                <Col xl={12} xxl={8}>
                  <InfoWithLabel
                    info={
                      itemPreview?.facing
                        ? itemPreview?.facing?.toUpperCase()
                        : ''
                    }
                    label={intl.formatMessage(messagesProduct.facing)}
                    {...infoColProps}
                  />
                </Col>
                <Col xl={12} xxl={8}>
                  <InfoWithLabel
                    info={
                      itemPreview?.planoFileName
                        ? itemPreview?.planoFileName?.toUpperCase()
                        : ''
                    }
                    label={intl.formatMessage(messagesProduct.planoFileName)}
                    {...infoColProps}
                    showTooltip
                  />
                </Col>
              </>
            </Row>
          )}

          <Row
            className={classnames('image-area', {
              'image-area__marketing': isMarketing,
              'image-area__imagery': !isMarketing,
            })}
          >
            {imageList?.length ? (
              <>
                <Button
                  onClick={() => checkButton()}
                  className={classnames({
                    'image-area__check-button-marketing': isMarketing,
                    'image-area__check-button-imagery': !isMarketing,
                  })}
                >
                  {selected?.length === imageList?.length ? (
                    <>Deselect all assets</>
                  ) : (
                    <>Select all assets</>
                  )}
                </Button>
                <div className='image-area__item-wrapper scroller'>
                  {imageList?.map((item, index) => {
                    return (
                      <div
                        className={classnames({
                          'image-area__thumbnail': true,
                          'image-area__thumbnail--selected':
                            item?.id === itemPreview?.id,
                        })}
                      >
                        <ThumbnailItem
                          imageThumbnail={
                            type === 'Imagery'
                              ? renderProductImage(item)
                              : renderMarketingImage(item)
                          }
                          hideLabel={true}
                          responsive={false}
                          selected={selected?.includes(item?.[idField])}
                          onClickCheckboxItem={handleClick}
                          onClickItemGrid={onClickItemGrid}
                          dataDetail={item}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <Empty className='image-area__no-data' />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ImageArea;
