import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, notification } from 'antd';
import { Form } from 'common/components';
import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/chat';
import * as chatActions from '../../controller/actions';
import * as chatSelectors from '../../controller/selectors';
import * as chatService from 'services/chatServices';

import './UpdateThreadNameForm.less';

const UpdateThreadNameForm = (props) => {
  const { info, setIsEditMode, intl } = props;
  const currentActiveThread = useSelector(
    chatSelectors.selectActiveThreadInfo()
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ threadName: info?.name });
  }, [form, info?.name]);

  const onFinish = (values) => {
    updateGroupNameThread(values?.threadName);
  };

  const updateGroupNameThread = async (threadName) => {
    try {
      const response = await chatService.updateGroupName({
        threadId: info?.id,
        threadName,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        dispatch(
          chatActions.getThreadInfo(
            currentActiveThread?.threadId,
            currentActiveThread?.userId
          )
        );
        setIsEditMode(false);
        setTimeout(() => {
          showSuccessMessageDialog();
        }, 500);
      } else {
        callApiErrorHandler(message);
      }
    } catch (error) {
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.updateThreadNameSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <Form
      form={form}
      name='update-thread-name-form'
      className='update-thread-name-form'
      id='update-thread-name-form'
      onFinish={onFinish}
    >
      <Form.Item
        label=''
        name='threadName'
        rules={[{ required: true, message: '' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default injectIntl(UpdateThreadNameForm);
