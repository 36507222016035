import React, { useState, useEffect } from 'react';

import { VideoCameraOutlined } from '@ant-design/icons';
import { Form, Button, Typography, notification, Image } from 'antd';

import Messages from 'i18n/messages/dashboard';
import meetingNowSrc from 'assets/dashboard/online-meeting.png';

import {
  FormCreateMeeting,
  AdditionAttendee,
  DisplaySomeAttendees,
  ExternalAttendee,
} from './ModalCreateMeeting';
import { RibbonButton, StyledModal } from 'common/components';

import { createNewMeeting } from 'services/dashboard';

import { ROUTE } from 'static/Constants';
import { forwardTo } from 'utils/common/route';

import moment from 'moment';

import './NewMeeting.less';

const MeetingNow = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        icon={
          <Image
            className='meeting-now__image'
            src={meetingNowSrc}
            preview={false}
          />
        }
        label={Messages.meetingNow}
        onClick={() => setVisible(true)}
        className='meeting-now__btn'
      />
      {visible && (
        <ModalMeetingNow visible={visible} onCancel={() => setVisible(false)} />
      )}
    </>
  );
};

const ModalMeetingNow = ({ visible, onCancel }) => {
  const [formInstance] = Form.useForm();
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [externalAttendees, setExternalAttendees] = useState([]);

  const [statusSubmit, setStatusSubmit] = useState('idle');

  const [startTimeInput, setStartTimeInput] = useState();
  const [startTimeChange, setStartTimeChange] = useState(false);
  const [endTimeInput, setEndTimeInput] = useState();
  const [endTimeChange, setEndTimeChange] = useState(false);

  useEffect(() => {
    if (visible) {
      const date = new Date();
      const startDate = moment(date);
      const endDate = moment(startDate).add(30, 'm');
      formInstance.setFieldsValue({
        startDate,
        endDate,
        startTime: moment(startDate, 'hh:mm:ss').format('hh:mm A'),
        endTime: moment(endDate, 'hh:mm:ss').format('hh:mm A'),
      });

      setStartTimeInput(startDate.format('hh:mm A'));
      setStartTimeChange(false);
      setEndTimeInput(endDate.format('hh:mm A'));
      setEndTimeChange(false);
    }
  }, [visible]);

  const handleAddAttendees = (attendee) => {
    setSelectedAttendees((prevAttendee) => prevAttendee.concat(attendee));
  };

  const handleDeleteAttendees = (attendeeId) => {
    const filteredAttendees = selectedAttendees.filter(
      (attendee) => attendee.id !== attendeeId
    );
    setSelectedAttendees(filteredAttendees);
  };

  //* External Attendees
  const handleAddExternalAttendees = (attendee) => {
    setExternalAttendees((prevAttendee) => prevAttendee.concat(attendee));
  };

  const handleDeleteExternalAttendees = (attendeeId) => {
    const filteredExternalAttendees = externalAttendees.filter(
      (attendee) => attendee.id !== attendeeId
    );
    setExternalAttendees(filteredExternalAttendees);
  };

  const handleMeetingNow = async () => {
    try {
      const { title, endDate, endTime } = await formInstance.validateFields();

      setStatusSubmit('loading');

      const newEndTime = moment(
        moment(endDate).format('MM-DD-YY') + ` ${endTime}`
      ).toISOString();

      const attendees = selectedAttendees.map((user) => user.id);
      const externalEmails = externalAttendees.map(
        (attendee) => attendee?.email
      );
      const params = {
        title,
        startTime: null,
        endTime: newEndTime,
        isStartMeetingNow: true,
        description: null,
        attendees,
        externalEmails,
      };

      const response = await createNewMeeting(params);
      if (response?.isSuccess) {
        notification.success({
          message: 'Create a meeting now successfully!',
        });

        forwardTo(ROUTE.MEETING + `/${response?.data?.id}?start_now=true`);
        setStatusSubmit('success');
        onCancel();
      } else {
        notification.error({
          message: response?.message ?? 'Something went wrong!',
        });
        setStatusSubmit('error');
        //onCancel();
      }
    } catch (error) {}
  };

  return (
    <StyledModal
      title='Meeting Now'
      width='1000px'
      visible={visible}
      onCancel={onCancel}
      bodyStyle={{
        minHeight: '400px',
      }}
      maskClosable={false}
      footer={null}
    >
      <FormCreateMeeting
        formInstance={formInstance}
        isMeetingNow
        startTimeInput={startTimeInput}
        setStartTimeInput={setStartTimeInput}
        endTimeInput={endTimeInput}
        setEndTimeInput={setEndTimeInput}
        startTimeChange={startTimeChange}
        setStartTimeChange={setStartTimeChange}
        endTimeChange={endTimeChange}
        setEndTimeChange={setEndTimeChange}
      >
        <Button
          icon={<VideoCameraOutlined />}
          size='large'
          className='modal-meeting__btn-send'
          onClick={handleMeetingNow}
          loading={statusSubmit === 'loading'}
          type='primary'
        >
          <Typography.Text style={{ color: '#FFF' }}>NOW</Typography.Text>
        </Button>
      </FormCreateMeeting>

      <div style={{ display: 'flex', marginLeft: '18.5%' }}>
        <AdditionAttendee
          selectedAttendees={selectedAttendees}
          handleAddAttendees={handleAddAttendees}
        />
        <ExternalAttendee
          externalAttendees={externalAttendees}
          handleAddExternalAttendees={handleAddExternalAttendees}
          handleDeleteExternalAttendees={handleDeleteExternalAttendees}
        />
      </div>
      <div style={{ height: 67 }}>
        {selectedAttendees?.length !== 0 && (
          <DisplaySomeAttendees
            selectedAttendees={selectedAttendees}
            handleAddAttendees={handleAddAttendees}
            handleDeleteAttendees={handleDeleteAttendees}
          />
        )}

        {/* //* Display External Attendees */}
        {externalAttendees?.length !== 0 && (
          <DisplaySomeAttendees
            selectedAttendees={externalAttendees}
            handleAddAttendees={handleAddExternalAttendees}
            handleDeleteAttendees={handleDeleteExternalAttendees}
            isExternalAttendees
          />
        )}
      </div>
    </StyledModal>
  );
};

export default MeetingNow;
