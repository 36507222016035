import React, { useState, useCallback, useMemo } from 'react';
// import ReactDOM from 'react-dom';
// import 'antd/dist/antd.css';
// import './index.css';
import * as servicesHelp from 'services/help';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Input, AutoComplete } from 'antd';
import * as _ from 'lodash';
import './InputSearchAddRemoveList.less';

function getRandomInt(max, min = 0) {
  return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
}

const InputSearchAddRemoveList = (props) => {
  const { isHaveAddRemove } = props;
  // const [options, setOptions] = useState([]);
  const [searchList, setSearchList] = useState(null);

  const handleSearchText = async (value) => {
    if (value === '') {
      setSearchList(null);
    } else {
      const result = await servicesHelp.searchHelpAutoComplete(value);
      console.log(result);
      setSearchList(result?.data?.suggests);
    }
  };
  const deboundSearchCommunication = useCallback(
    _.debounce((value) => handleSearchText(value), 700),
    []
  );

  const handleSearch = (value) => {
    deboundSearchCommunication(value);
  };

  // const handleSearch = (value) => {

  //   // const searchText = value !== '' ? value.replace(/(^\s+|\s+$)/g, '') : value;

  //   // dispatch(globalActions.searchHelpPostSuccess([]));

  //   setOptions(value ? searchResult(value) : []);
  // };

  const onSelect = (value) => {
    console.log('onSelect', value);
  };

  const searchResult = (query) =>
    new Array(getRandomInt(5))
      .join('.')
      .split('.')
      .map((_, idx) => {
        const category = `${query}${idx}`;
        return {
          value: category,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span></span>
              {isHaveAddRemove && (
                <>
                  {idx % 2 > 0 ? (
                    <PlusCircleOutlined className='input-search-remove-add__drop-list-btn' />
                  ) : (
                    <MinusCircleOutlined className='input-search-remove-add__drop-list-btn' />
                  )}
                </>
              )}
            </div>
          ),
        };
      });

  const options = useMemo(() => {
    if (!searchList) return [];
    console.log('searchList', searchList);
    return searchList.map((itemData, idx) => {
      const category = `test${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>{itemData?.title}</span>
            {isHaveAddRemove && (
              <>
                {idx % 2 > 0 ? (
                  <PlusCircleOutlined className='input-search-remove-add__drop-list-btn' />
                ) : (
                  <MinusCircleOutlined className='input-search-remove-add__drop-list-btn' />
                )}
              </>
            )}
          </div>
        ),
      };
    });
  }, [searchList]);

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{
        width: 300,
      }}
      className='input-search-remove-add'
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
    >
      <Input.Search size='large' placeholder='input here' enterButton />
    </AutoComplete>
  );
};

export default InputSearchAddRemoveList;
