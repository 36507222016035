import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as IconDay } from 'assets/dashboard/day.svg';

import Messages from 'i18n/messages/dashboard';

import { RibbonButton } from 'common/components';
import * as dashboardSelectors from 'pages/dashboard/controllers/selectors';
import * as dashboardActions from 'pages/dashboard/controllers/actions';

const WeekView = () => {
  const dispatch = useDispatch();
  const calendarView = useSelector(dashboardSelectors.makeSelectCalendarView());
  return (
    <RibbonButton
      icon={<IconDay />}
      label={Messages.weekView}
      onClick={() => dispatch(dashboardActions.calendarWeekView('week'))}
      toggle={calendarView === 'week'}
      style={{ padding: '0px 14px' }}
    />
  );
};

export default WeekView;
