import { bytesToMegaBytes } from 'utils/string';
import { formatMDY } from 'utils/formatDate';

export const processStageCols = [
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Process / Stage',
    field: 'processStage',
    type: 'editableColumn',
    minWidth: 200,
    flex: 1,
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Nature of the tests',
    field: 'natureOfTheTests',
    fieldCamelCase: 'NatureOfTheTests',
    type: 'editableColumn',
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Target value and Tolerances',
    field: 'targetValueAndTolerances',
    type: 'editableColumn',
    width: 250,
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Frequency',
    field: 'frequency',
    type: 'editableColumn',
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Test',
    field: 'test',
    type: 'editableColumn',
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Recognized Methods',
    field: 'recognizedMethods',
    type: 'editableColumn',
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Critical Point Established in HACCP',
    field: 'criticalPointEstablishedInHACCP',
    width: 280,
  },
];

export const statusCol = {
  allowFilter: true,
  allowSort: true,
  dataType: 'string',
  field: 'errors',
  headerName: 'Edit Status',
  cellRenderer: 'StatusRender',
  type: 'editableColumn',
  lockPosition: 'left',
  lockPinned: true,
  editable: false,
  width: 120,
};

export const PROCESS_STAGE_LIST_ERRORS = {
  PROCESS_STAGE_EXISTED: 'Process stage existed',
  PROCESS_STAGE_EMPTY: 'Process stage cannot be empty',
};

export const columnDefsDocument = [
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'Document Name',
    field: 'documentName',
    cellRenderer: 'TextRender',
    flex: 1,
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'File Type',
    field: 'fileType',
    cellRenderer: 'TextRender',
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    headerName: 'File Size',
    field: 'fileSize',
    valueGetter: (params) => {
      return `${bytesToMegaBytes(params?.data.fileSize)} MB`;
    },
    cellRenderer: 'TextRender',
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'DateTime',
    headerName: 'Upload Date',
    field: 'uploadDate',
    valueGetter: ({ data }) => formatMDY(data?.['uploadDate']),
    cellRenderer: 'TextRender',
  },
  {
    allowFilter: true,
    allowSort: true,
    headerName: '',
    field: 'documentActions',
    width: 150,
  },
];

export const SUPPORT_TYPES = [
  'jpeg',
  'png',
  'jpg',
  'tiff',
  'tif',
  'mp4',
  'mov',
  'avi',
  'mp3',
  'zip',
  'rar',
  'txt',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'bmp',
  'gif',
  'webm',
  'csv',
];
