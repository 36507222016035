import * as types from './constants';

/**
 * turn on edit mode in asset full view
 */
export const editAsset = () => {
  return {
    type: types.EDIT_ASSET,
  };
};

/**
 * turn off edit mode in asset full view
 */
export const cancelEditAsset = () => {
  return {
    type: types.CANCEL_EDIT_ASSET,
  };
};

/**
 * save dirty asset data in full view
 */
export const savingAsset = () => {
  return {
    type: types.SAVING_ASSET_FULL,
  };
};

/**
 * save dirty asset data in full view
 */
export const saveAsset = (payload) => {
  return {
    type: types.SAVE_ASSET_FULL,
    payload,
  };
};

/**
 * finish edit asset data in full view
 */
export const finishEditAsset = () => {
  return {
    type: types.FINISH_EDIT_ASSET,
  };
};

export const finishEditAssetSuccess = () => {
  return {
    type: types.FINISH_EDIT_ASSET_SUCCESS,
  };
};

export const finishEditAssetError = () => {
  return {
    type: types.FINISH_EDIT_ASSET_ERROR,
  };
};

export const getAssetId = (assetId) => {
  return {
    type: types.GET_ASSET_ID,
    payload: {
      assetId,
    },
  };
};

export const creatingAsset = () => {
  return {
    type: types.CREATING_ASSET,
  };
};

export const cancelCreateAsset = () => {
  return {
    type: types.CANCEL_CREATE_ASSET,
  };
};

export const cancelSavingAsset = () => {
  return {
    type: types.CANCEL_SAVING_ASSET,
  };
};

export const enableModeLinkAsset = () => {
  return { type: types.ENABLE_MODE_LINK_ASSETS };
};

export const disableModeLinkAsset = () => {
  return { type: types.DISABLE_MODE_LINK_ASSETS };
};

/**
 * Replace Preview
 */
export const toggleReplacePreview = () => {
  return {
    type: types.TOGGLE_REPLACE_PREVIEW,
  };
};

/**
 * Replace Preview
 */
export const cancelReplacePreview = () => {
  return {
    type: types.CANCEL_REPLACE_PREVIEW,
  };
};

export const getUniqueId = (uniqueId) => {
  return {
    type: types.GET_UNIQUE_ID,
    payload: {
      uniqueId,
    },
  };
};

export const clearUniqueId = () => {
  return {
    type: types.CLEAR_UNIQUE_ID,
  };
};

export const getCanEdit = (canEdit) => {
  return {
    type: types.GET_CAN_EDIT,
    canEdit,
  };
};
