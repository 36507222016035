import React from 'react';

import { Divider } from 'antd';

import {
  ManageSharingSection,
  DetailSection,
  ViewLayout,
  AdvanceStack,
  OtherLayout,
} from '../components';
import { RibbonBar, RibbonDivider } from 'common/components';

import { SplashUserManual, AcceptedFieldListing } from './components';

const FormUpload = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <AdvanceStack isDisabled />
      <RibbonDivider />
      <SplashUserManual />
      <AcceptedFieldListing />
    </RibbonBar>
  );
};

export default FormUpload;
