import {
  addErrorsToRow,
  removeErrorsFromRow,
  getAllRows,
} from 'pages/qa-spec/utils';
import { cloneDeep, isEmpty } from 'lodash';
import {
  CONTAINMENT_CHARACTERISTICS_ERRORS,
  PHYSICAL_AND_CHEMICAL_CHARACTERISTICS_ERRORS,
  MICROBIOLOGICAL_CHARACTERISTICS_ERRORS,
} from 'pages/qa-spec/constant';
import { ORGANOLEPTIC_CHARACTERISTICS_REQUIRED_FIELDS } from './constants';
import produce from 'immer';

/**
 * ? check if grid have error
 * @param {*} grid : ;
 * @returns
 */
const getCheckIfGridHaveErrorFunc = (grid) => {
  return () => {
    const allRowData = getAllRows(grid);
    return allRowData.some((rowDataItem) => rowDataItem?.error?.length > 0);
  };
};

/**
 * ? check if at least one col of a row have value
 * @param {*} param0
 * @returns
 */
const getCheckAtLeastOneEntryPerRowFunc = ({ grid, errorMessage }) => {
  return async ({ event, rowIndex }) => {
    try {
      const allRowData = getAllRows(grid);
      const rowData = allRowData[event?.rowIndex ?? rowIndex];

      const someColHaveValue = Object.keys(rowData).find((key) => {
        if (key === 'uuid' || key === 'error') return false;

        return !isEmpty(rowData[key]);
      });

      let newRowData = {};

      if (!someColHaveValue) {
        newRowData = addErrorsToRow(rowData, [errorMessage]);
      } else {
        newRowData = removeErrorsFromRow(rowData, [errorMessage]);
      }

      grid.current.api.applyTransaction({
        update: [newRowData],
      });

      if (newRowData?.error.includes(errorMessage)) {
        return { error: errorMessage };
      } else {
        return { error: null };
      }
    } catch (e) {
      console.log('e', e);
    }
  };
};

/**
 * ? validate containment characteristic grid
 * @param {*} {grid}
 * @param {*} rowDataItem
 * @returns
 */
export const validateContainmentCharacteristicGrid = ({ grid } = {}) => {
  const checkAtLeastOneEntryPerRow = getCheckAtLeastOneEntryPerRowFunc({
    grid,
    errorMessage: CONTAINMENT_CHARACTERISTICS_ERRORS.AT_LEAST_ONE_ENTRY,
  });

  const checkIfGridHaveError = getCheckIfGridHaveErrorFunc(grid);

  const validateAllRows = async () => {
    try {
      const allRowData = getAllRows(grid);

      for (const rowItemIndex in allRowData) {
        await checkAtLeastOneEntryPerRow({ rowIndex: rowItemIndex });
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  return {
    checkAtLeastOneEntryPerRow,
    checkIfGridHaveError,
    validateAllRows,
  };
};

/**
 *? validate physical and chemical characteristic grid
 * @param {*} param0
 * @returns
 */
export const validatePhysicalAndChemicalCharacteristicGrid = ({
  grid,
} = {}) => {
  const checkAtLeastOneEntryPerRow = getCheckAtLeastOneEntryPerRowFunc({
    grid,
    errorMessage:
      PHYSICAL_AND_CHEMICAL_CHARACTERISTICS_ERRORS.AT_LEAST_ONE_ENTRY,
  });

  const checkIfGridHaveError = getCheckIfGridHaveErrorFunc(grid);

  const validateAllRows = async () => {
    try {
      const allRowData = getAllRows(grid);

      for (const rowItemIndex in allRowData) {
        await checkAtLeastOneEntryPerRow({ rowIndex: rowItemIndex });
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  return {
    checkAtLeastOneEntryPerRow,
    checkIfGridHaveError,
    validateAllRows,
  };
};

/**
 *? validate microbiological characteristic grid
 * @param {*} param0
 * @returns
 */
export const validateMicrobiologicalCharacteristicGrid = ({ grid } = {}) => {
  const checkAtLeastOneEntryPerRow = getCheckAtLeastOneEntryPerRowFunc({
    grid,
    errorMessage: MICROBIOLOGICAL_CHARACTERISTICS_ERRORS.AT_LEAST_ONE_ENTRY,
  });

  const checkIfGridHaveError = getCheckIfGridHaveErrorFunc(grid);

  const validateAllRows = async () => {
    try {
      const allRowData = getAllRows(grid);

      for (const rowItemIndex in allRowData) {
        await checkAtLeastOneEntryPerRow({ rowIndex: rowItemIndex });
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  return {
    checkAtLeastOneEntryPerRow,
    checkIfGridHaveError,
    validateAllRows,
  };
};

export const getOrganolepticRequiredFields = (requiredFields) => {
  if (!requiredFields) return;

  const regex =
    /^qaSpecification\.qaSpecProductSpecification\.qaSpecProductSpecsOrganolepticCharacteristics\.(.+)$/;

  return requiredFields.filter((fieldFullPath) => {
    return regex.test(fieldFullPath);
  });
};

const checkOrganolepticFieldsIsRequired = ({
  colName,
  parameterType,
  requiredFields,
}) => {
  const parametersMap = {
    appearance: 'Appearance',
    color: 'Color',
    'aroma/odor': 'Odor',
    'flavor/taste': 'Taste',
    texture: 'Texture',
  };
  const fieldPath =
    'qaSpecification.qaSpecProductSpecification.qaSpecProductSpecsOrganolepticCharacteristics';

  const fieldFullPath = `${fieldPath}.${colName}.${parametersMap[parameterType]}`;

  return requiredFields?.includes(fieldFullPath);
};

/**
 *? validate organoleptic characteristic grid
 * @param {*} param0
 * @returns
 */
export const validateOrganolepticCharacteristicGrid = ({
  grid,
  requiredFields,
} = {}) => {
  const organolepticRequiredFields =
    getOrganolepticRequiredFields(requiredFields);

  const checkAllRequiredFieldsByParameterType = async ({ event, rowIndex }) => {
    const allRowData = getAllRows(grid);
    const rowData = allRowData[event?.rowIndex ?? rowIndex];

    const parameterType = rowData?.parameter?.toLowerCase();
    const requiredFieldsObject =
      ORGANOLEPTIC_CHARACTERISTICS_REQUIRED_FIELDS[parameterType];

    let newRowData = { ...rowData };

    Object.keys(requiredFieldsObject).forEach((colName) => {
      const isRequired = checkOrganolepticFieldsIsRequired({
        colName,
        parameterType,
        requiredFields: organolepticRequiredFields,
      });

      if (isRequired && isEmpty(rowData[colName])) {
        newRowData = addErrorsToRow(newRowData, [
          requiredFieldsObject[colName].message,
        ]);
      } else {
        newRowData = removeErrorsFromRow(newRowData, [
          requiredFieldsObject[colName].message,
        ]);
      }
    });

    grid.current.api.applyTransaction({
      update: [newRowData],
    });
  };

  const checkIfGridHaveError = getCheckIfGridHaveErrorFunc(grid);

  const validateAllRows = async () => {
    try {
      const allRowData = getAllRows(grid);

      for (const rowItemIndex in allRowData) {
        await checkAllRequiredFieldsByParameterType({ rowIndex: rowItemIndex });
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  return {
    checkAllRequiredFieldsByParameterType,
    checkIfGridHaveError,
    validateAllRows,
  };
};

export const validateAllRows = ({ gridInst, requiredFields }) => {
  const allRowData = getAllRows(gridInst);

  allRowData?.forEach((rowItem, rowIndex) => {
    requiredFields?.forEach((item) => {
      validateRequiredFieldProductSpec({
        gridInst,
        rowIndex,
        valueProp: rowItem?.[item?.fieldName],
        requiredFieldError: item.errorMessage,
      });
    });
  });
};

export const validateRequiredFieldProductSpec = ({
  rowIndex,
  gridInst,
  event,
  valueProp,
  requiredFieldError,
}) => {
  const allRows = getAllRows(gridInst);

  let validatedRowData = allRows;

  const currentValue = event?.value ?? valueProp;
  const foundIdx = event?.rowIndex ?? rowIndex;

  const foundSelectedRow = allRows?.[foundIdx];

  if (!currentValue || currentValue?.length === 0) {
    let foundSelectedRowError = foundSelectedRow?.error ?? [];

    const isExistMessage =
      foundSelectedRowError?.findIndex(
        (error) => error === requiredFieldError
      ) !== -1;

    const newError = isExistMessage
      ? foundSelectedRowError
      : foundSelectedRowError?.concat(requiredFieldError);

    validatedRowData = getNewRowDataAfterSettingError({
      allRows: validatedRowData,
      index: foundIdx,
      error: newError || [],
    });
  } else {
    const newError = foundSelectedRow?.error?.filter(
      (error) => error !== requiredFieldError
    );

    validatedRowData = getNewRowDataAfterSettingError({
      allRows: validatedRowData,
      index: foundIdx,
      error: newError || [],
    });
  }

  gridInst.current.api.applyTransaction({
    update: cloneDeep(validatedRowData),
  });
};

export const getProductSpecRequiredFields = ({
  gridInst,
  requiredFields,
  requiredFieldPath,
}) => {
  const allColumns = gridInst?.current?.columnApi?.columnModel?.columnDefs;

  if (!allColumns) return [];

  const requiredFieldData = allColumns
    ?.filter((column) =>
      requiredFields?.includes(`${requiredFieldPath}.${column.field}`)
    )
    .map((column) => {
      return {
        fieldName: column.field,
        errorMessage: `${column.headerName} is required`,
      };
    });

  if (requiredFieldData?.length) return requiredFieldData;

  return [];
};

const getNewRowDataAfterSettingError = ({ allRows, index, error }) => {
  return produce(allRows, (draftState) => {
    draftState[index].error = error;
  });
};
