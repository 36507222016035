import React, { useState } from 'react';

import { Row, Col, Space, Button } from 'antd';

import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';

import { FileViewer } from './FileUploadEditor';
import PreviewableModal from 'common/components/previewable-modal/PreviewableModal';

import * as api from 'config/axios';
import { checkShowPdfPreview } from 'utils/checkShowPdfPreview';

const FileUploadRender = (props) => {
  const { value } = props;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const { linkPreview, extensionFile } = checkShowPdfPreview(
    value?.pdfPreview,
    value?.linkUrl,
    value?.fileType
  );

  const handleDownloadFile = () => {
    value?.linkUrl &&
      api.sendDownload({
        url: value?.linkUrl,
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 3,
      }}
    >
      {value && (
        <Row style={{ width: '100%' }}>
          <Col style={{ flex: 1, overflow: 'hidden' }}>
            {value?.documentName && <FileViewer data={value} />}
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <Button
                type='link'
                icon={<EyeOutlined />}
                onClick={() => setIsPreviewOpen(true)}
                style={{ border: 'none' }}
                disabled={!value?.linkUrl || !value?.fileType}
              />
              <Button
                type='link'
                icon={<DownloadOutlined />}
                onClick={handleDownloadFile}
                style={{ border: 'none' }}
                disabled={!value?.linkUrl}
              />
            </Space>
          </Col>
        </Row>
      )}

      {isPreviewOpen && (
        <PreviewableModal
          id={value?.documentName}
          fileType={extensionFile}
          link={linkPreview}
          title={value?.documentName}
          visible={isPreviewOpen}
          fileName={value?.documentName}
          cancelModal={() => setIsPreviewOpen(false)}
          isDownload={false}
          isDocument={true}
          downloadLink={value?.linkUrl}
          fileSize={value?.fileSize}
        />
      )}
    </div>
  );
};

export default FileUploadRender;
