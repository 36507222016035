import React, { useRef } from 'react';

import { RibbonButton } from 'common/components';
import { MoveAllEntitiesToMemberModal } from 'common/components/modal';

import { PicRightOutlined } from '@ant-design/icons';

const MoveAllToMember = (props) => {
  const { disabled, selectedMember } = props;
  const refModal = useRef();

  const onClickMoveAllBtn = () => {
    refModal.current && refModal.current.openModal();
  };

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<PicRightOutlined />}
        label={'Transfer All Entities to Member'}
        onClick={onClickMoveAllBtn}
        disabled={disabled}
      />
      <MoveAllEntitiesToMemberModal
        ref={refModal}
        isMultiple={false}
        dataPath='data.allMembers'
        params={{ searchText: '' }}
        mapSearch={(searchText) => ({ searchText })}
        tagRender={{ value: (itemData) => itemData?.companyName }}
        itemIdName='companyId'
        originMember={selectedMember}
      />
    </>
  );
};

export default MoveAllToMember;
