import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMemberList = (state) => state.memberList || initialState;

const makeSelectLoading = () =>
  createSelector(selectMemberList, (memberState) => memberState.loading);
const makeSelectMemberList = () =>
  createSelector(selectMemberList, (memberState) => memberState.members);
const makeSelectTotal = () =>
  createSelector(selectMemberList, (memberState) => memberState.total);
const makeSelectPageSize = () =>
  createSelector(selectMemberList, (memberState) => memberState.pageSize);
const makeSelectPageNumber = () =>
  createSelector(selectMemberList, (memberState) => memberState.pageNumber);
const makeSelectSearch = () =>
  createSelector(selectMemberList, (memberState) => memberState.search);
const makeSelectColumns = () =>
  createSelector(selectMemberList, (memberState) => memberState.columns);
const makeSelectBanListColumns = () =>
  createSelector(selectMemberList, (memberState) => memberState.banListColumns);
const makeSelectLoadingDetail = () =>
  createSelector(selectMemberList, (memberState) => memberState.loadingDetail);
const makeSelectMemberData = () =>
  createSelector(selectMemberList, (memberState) => memberState.memberData);
const makeSelectPublicContactList = () =>
  createSelector(
    selectMemberList,
    (memberState) => memberState.publicContactList
  );

const makeSelectProductsForMember = () =>
  createSelector(
    selectMemberList,
    (memberState) => memberState.productsForMember
  );

const makeSelectIsDisplayMemberNew = () =>
  createSelector(
    selectMemberList,
    (memberState) => memberState.isDisplayMemberNew
  );

export {
  selectMemberList,
  makeSelectLoading,
  makeSelectMemberList,
  makeSelectTotal,
  makeSelectPageSize,
  makeSelectPageNumber,
  makeSelectSearch,
  makeSelectColumns,
  makeSelectLoadingDetail,
  makeSelectMemberData,
  makeSelectProductsForMember,
  makeSelectPublicContactList,
  makeSelectBanListColumns,
  makeSelectIsDisplayMemberNew,
};
