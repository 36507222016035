import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Space,
  Collapse,
  Row,
  Col,
  Input,
  InputNumber,
  Checkbox,
  DatePicker,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import {
  Form,
  InfoWithLabel,
  FormSaveButton,
  FormCancelButton,
  EditButton,
  TextTooltip,
} from 'common/components';

import userSelectors from 'redux/user/selectors';

import * as digitalAssetServices from 'services/digitalAsset';
import {
  parseToString,
  parseToNumber,
  parseToBoolean,
  parseToDate,
  parseToDateObject,
} from 'utils/parser';
import { apiHandler } from 'utils/api';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/asset-full-view';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { OPEN_ITEM_CLASS } from 'static/Constants';

import {
  useCheckPermissions,
  useCheckAllowEditAssets,
  useCheckIsSuperAdmin,
} from 'hooks';

import classnames from 'classnames';
import './MemberDefinedPropertiesPanel.less';

const { Panel } = Collapse;

const MemberDefinedPropertiesPanel = (props) => {
  const {
    assetId,
    memberDefinedProperties,
    isEditable,
    isActive,
    isOwner,
    isHiddenEditSection = false,
  } = props;

  const [form] = Form.useForm();
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();

  const configData = useSelector(
    userSelectors.makeSelectUserDefinedProperties()
  );

  const { isSuperMember } = useSelector(userSelectors.makeSelectUserInfo());
  const isSuperAdmin = useCheckIsSuperAdmin();

  const [isNoRenderData, setNoRenderData] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const valueData = memberDefinedProperties;
  const hasMemberDefinedProperties = Boolean(valueData?.length);

  const hiddenMemberDefinedProperties =
    (isSuperMember && !isOwner && !hasMemberDefinedProperties) ||
    (isSuperAdmin && !isOwner && !hasMemberDefinedProperties) ||
    (!isOwner && !hasMemberDefinedProperties);

  const hasPermissionMemberDefinedProperties = useCheckPermissions([
    { action: ABILITY_ACTION.EDIT, subject: ABILITY_SUBJECT.ASSET },
    { action: ABILITY_ACTION.DELETE, subject: ABILITY_SUBJECT.ASSET },
  ]);

  const { checkAllowEditAssetFull } = useCheckAllowEditAssets();
  const isAllowEdit = checkAllowEditAssetFull();

  const allowEditMemberDefinedProperties =
    hasPermissionMemberDefinedProperties || isAllowEdit;

  const mapValueWithConfig = () => {
    if (isSuperAdmin && !isOwner) {
      return (
        !!valueData?.length &&
        valueData.map((propertyValue) => {
          return {
            ...propertyValue,
            fieldType: propertyValue?.fieldType,
          };
        })
      );
    }

    return (
      !!valueData?.length &&
      valueData.map((propertyValue) => {
        const matchedPropertyConfig = configData.find(
          (propertyConfig) =>
            propertyConfig.fieldName === propertyValue.fieldName
        );

        return {
          ...propertyValue,
          fieldType: matchedPropertyConfig?.fieldType,
        };
      })
    );
  };

  const mapConfigWithValue = () => {
    if (isSuperAdmin && !isOwner) {
      return (
        !!valueData?.length &&
        valueData.map((propertyValue) => {
          return {
            ...propertyValue,
            value: propertyValue?.value,
          };
        })
      );
    }

    return (
      !!configData?.length &&
      configData.map((propertyConfig) => {
        const matchedPropertyValue = valueData.find(
          (propertyValue) =>
            propertyConfig.fieldName === propertyValue.fieldName
        );

        return {
          ...propertyConfig,
          value: matchedPropertyValue?.value,
        };
      })
    );
  };

  const mapConfigWithFormValue = (value) => {
    const formKeyArray = Object.keys(value);

    if (isSuperAdmin && !isOwner) {
      return formKeyArray.map((propertyKey) => {
        const matchedPropertyConfig = valueData.find(
          (propertyConfig) => propertyConfig.fieldName === propertyKey
        );

        return {
          ...matchedPropertyConfig,
          value: value[propertyKey],
        };
      });
    }

    return formKeyArray.map((propertyKey) => {
      const matchedPropertyConfig = configData.find(
        (propertyConfig) => propertyConfig.fieldName === propertyKey
      );

      return {
        ...matchedPropertyConfig,
        value: value[propertyKey],
      };
    });
  };

  const generateRenderProperties = () => {
    const mappedProperties = mapValueWithConfig();

    return (
      !!mappedProperties?.length &&
      mappedProperties?.reduce((renderProperties, property) => {
        const wrongFieldTypeNotice = renderWrongFiledTypeNotice(property);
        const { fieldType, value } = property;
        //* if fieldType === undefined ==> this property has been removed
        if (fieldType && value) {
          renderProperties.push({
            label: property.fieldName,
            info: parseValue({ property }),
            infoAppend: wrongFieldTypeNotice,
          });
        }
        return renderProperties;
      }, [])
    );
  };

  const parseValue = ({ property, toDateObject }) => {
    const { value, fieldType } = property;

    const parser = {
      string: parseToString,
      numeric: parseToNumber,
      boolean: parseToBoolean,
      date: toDateObject ? parseToDateObject : parseToDate,
    };

    return parser[fieldType] ? parser[fieldType](value) : value;
  };

  const onEditClick = () => {
    setEditMode(true);
  };

  const onCancelClick = () => {
    setEditMode(false);
  };

  const onSaveClick = () => {
    form.validateFields().then((value) => {
      callApiEditMemberDefinedProperties(value);
    });
  };

  const callApiEditMemberDefinedProperties = (value) => {
    setBtnLoading(true);

    const memberDefinedProperties = mapConfigWithFormValue(value);

    const params = {
      digitalAssetId: assetId,
      memberDefinedProperties,
    };
    const successMessage = intl.formatMessage(
      Messages.editMemberDefinedPropertiesValueSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.editMemberDefinedPropertiesValueError
    );

    apiHandler({
      service: digitalAssetServices.editMemberDefinedPropertiesValue,
      params,
      successMessage,
      errorMessage,
      successCallback: editSuccessCallback,
      onFinally: hideBtnLoading,
    });
  };

  const editSuccessCallback = () => {
    reloadPage();
  };

  const hideBtnLoading = () => {
    setBtnLoading(false);
  };

  const setFormInitialValue = () => {
    const mappedProperties = mapConfigWithValue();

    const formValue =
      mappedProperties &&
      mappedProperties?.reduce((accumulator, propertyConfig) => {
        //* get field value from memberDefinedProperties
        accumulator[propertyConfig.fieldName] = propertyConfig.value
          ? parseValue({
              property: propertyConfig,
              toDateObject: true,
            })
          : null;

        return accumulator;
      }, {});

    return formValue;
  };

  //* render
  const renderViewMode = () => {
    const renderData = generateRenderProperties();

    if (!renderData?.length && !isNoRenderData) {
      setNoRenderData(true);
      return;
    }

    return (
      !!renderData &&
      renderData.map((item, index) => {
        return (
          item.info !== null && (
            <InfoWithLabel
              key={index}
              {...item}
              infoColProps={{ span: 12 }}
              labelColProps={{ span: 12 }}
              labelAlign='right'
            />
          )
        );
      })
    );
  };

  const renderEditMode = () => {
    const layout = { labelCol: { span: 8 }, wrapperCol: { span: 12 } };

    let newConfigData =
      isSuperAdmin && !isOwner ? memberDefinedProperties : configData;

    // TODO: Maybe bad data fieldName is null
    // newConfigData = newConfigData.filter((config) => Boolean(config.fieldName));

    return (
      <Form
        className={classnames(
          'asset-edit member-defined-property__edit-value-form',
          [OPEN_ITEM_CLASS]
        )}
        form={form}
        initialValues={setFormInitialValue()}
        {...layout}
      >
        {newConfigData?.map((property) => {
          return renderFormField(property);
        })}
      </Form>
    );
  };

  const renderFormField = (property) => {
    const { fieldType, fieldName } = property;
    const isCheckBox = fieldType === 'boolean';
    const inputs = {
      string: Input,
      numeric: InputNumber,
      boolean: Checkbox,
      date: DatePicker,
    };
    const FieldComponent = inputs[fieldType] ?? null;

    const formItemProps = {
      label: <TextTooltip text={fieldName} />,
      name: fieldName,
      key: fieldName,
    };

    if (isCheckBox) formItemProps.valuePropName = 'checked';

    return (
      <Form.Item {...formItemProps}>
        <FieldComponent />
      </Form.Item>
    );
  };

  const renderMemberDefinedPropertiesActions = () => {
    return isEditMode ? (
      <Row justify='end'>
        <Space>
          <FormSaveButton onClick={onSaveClick} loading={btnLoading} />
          <FormCancelButton onClick={onCancelClick} disabled={btnLoading} />
        </Space>
      </Row>
    ) : (
      allowEditMemberDefinedProperties && (
        <EditButton
          className='member-defined-property__edit-btn'
          onClick={onEditClick}
        />
      )
    );
  };

  const renderWrongFiledTypeNotice = (property) => {
    const { fieldType, value } = property;

    const isBooleanType = fieldType === 'boolean';
    const isBooleanValue = value === 'true' || value === 'false';
    const shouldShow = isBooleanType && !isBooleanValue;

    const noticeText = intl.formatMessage(
      Messages.memberDefinedPropertiesWrongFiledTypeNoticeText,
      { value }
    );

    return (
      shouldShow && (
        <Tooltip placement='top' title={noticeText}>
          <InfoCircleOutlined className='member-defined-property__wrong-field-type-notice' />
        </Tooltip>
      )
    );
  };

  const viewable = !!valueData && !isNoRenderData && isActive;
  const isOwnerEditable = isEditable && isAllowEdit;

  const shouldShowCollapse =
    !hiddenMemberDefinedProperties && (viewable || isOwnerEditable);

  return shouldShowCollapse ? (
    <Collapse
      className='asset-overview__collapse member-defined-property__collapse'
      ghost
      expandIconPosition='right'
    >
      <Panel header='Member Defined Properties' key='1' forceRender>
        {!isHiddenEditSection && renderMemberDefinedPropertiesActions()}
        <Row>
          <Col span={24}>
            {isEditMode ? renderEditMode() : renderViewMode()}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  ) : null;
};

export default MemberDefinedPropertiesPanel;
