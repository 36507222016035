import { getAllRows } from 'pages/qa-spec/utils';
import { REQUIRED_FIELDS_QA_INGREDIENT } from './requiredFieldsQaIngredient';

export const coverIngredientStatementsData = ({
  data,
  currentIngredient,
  ingredientStatementValue,
}) => {
  let ingredientStatements = [];
  if (currentIngredient?.index !== null) {
    ingredientStatements = data?.ingredientStatements?.map((item) => {
      if (currentIngredient?.index === item?.index)
        return {
          ...currentIngredient,
          ingredients: ingredientStatementValue,
        };
      return item;
    });
  } else {
    const defaultIngredientStatements = data?.ingredientStatements ?? [];

    ingredientStatements = [
      ...defaultIngredientStatements,
      {
        ...currentIngredient,
        ingredients: ingredientStatementValue,
      },
    ];
  }
  return ingredientStatements;
};

export const createIngredientParams = ({ formInst, gridInst }) => {
  const { ingredientClaims } = formInst.getFieldsValue();
  const ingredientListForm = getAllRows(gridInst).map((ingredientItem) => {
    // ingredientsPartOf != null
    if (ingredientItem?.isChild) {
      const [value, parentId] = ingredientItem?.ingredientsPartOf?.split('.');
      return {
        ...ingredientItem,
        ingredientsPartOf: value,
        ingredientsPartOfId: parentId,
        digitalAssetId: ingredientItem?.documents?.digitalAssetId,
      };
    }
    return {
      ...ingredientItem,
      ingredientsPartOfId: null,
      digitalAssetId: ingredientItem?.documents?.digitalAssetId,
    };
  });

  const submitValue = {
    ingredientClaims,
    ingredients: ingredientListForm,
  };
  return submitValue;
};

export const errorRequiredFieldsForSupplier = [
  REQUIRED_FIELDS_QA_INGREDIENT.nameOfIngredients.message,
  REQUIRED_FIELDS_QA_INGREDIENT.countryOfOrigin.message,
  REQUIRED_FIELDS_QA_INGREDIENT.gmo.message,
  REQUIRED_FIELDS_QA_INGREDIENT.bioengineered.message,
];
