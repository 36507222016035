import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  filterAssignToMe: false,
  filterStatus: [],
  filterTags: [],
  fullViewMode: 'view',
  loadingExport: false,
};

/* eslint-disable default-case, no-param-reassign */
const ticketingSystemReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_LOADING_EXPORT:
        draft.loadingExport = action.payload;
        break;
      case types.FILTER_STATUS:
        draft.filterStatus = action.payload;
        break;
      case types.FILTER_TAGS:
        draft.filterTags = action.payload;
        break;

      case types.CHANGE_FULL_VIEW_MODE:
        draft.fullViewMode = action.payload;
        break;
      default:
    }
  });

export default ticketingSystemReducer;
