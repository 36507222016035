import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Image from 'common/components/image/Image';
import { mapFileTypeToImage } from 'utils/fileType';
import PreviewableModal from 'common/components/previewable-modal/PreviewableModal';
import { IframePreview } from 'common/components';
import AssetPreviewTools from './AssetPreviewTools';
import AssetPreviewVideo from './AssetPreviewVideo';
import {
  VIDEO_TYPES_PREVIEW,
  TYPES_CONVERT_PDF_PREVIEW,
  ASSET_TYPES_FOR_PREVIEW,
} from 'static/FileType';
import './AssetPreview.less';

const AssetPreview = (props) => {
  const { assetData, renderDownloadBtn } = props;

  const [isVisiblePreviewBtn, setIsVisiblePreviewBtn] = useState(false);
  const [isPdfGroup, setPdfGroup] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [iFramePreview, setIFramePreview] = useState(
    assetData?.iFrameLink || assetData?.urlIFrame
  );
  const [degRotate, setDegRotate] = useState(0);
  const [fileType, setFileType] = useState('');
  const [visibleVideo, setVisibleVideo] = useState(false);

  const assetImage = assetData && assetData.thumbnail;

  useEffect(() => {
    const currentIFrame = JSON.parse(localStorage.getItem('currentIFrame'));
    setIFramePreview(
      currentIFrame && currentIFrame[0]
        ? currentIFrame[1]
        : assetData?.iFrameLink || assetData?.urlIFrame
    );
  }, [assetData, localStorage.getItem('currentIFrame')]);

  useEffect(() => {
    onSetFileType();
    onCheckVideoType(assetData)
      ? setVisibleVideo(true)
      : setVisibleVideo(false);
  }, [assetData]);

  useEffect(() => {
    setIsVisiblePreviewBtn(onSettingVisiblePreviewBtn(assetData));
  }, [assetData, fileType]);

  let timeout;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onSetFileType = () => {
    let type = assetData?.isIFrame
      ? 'iframe'
      : assetData?.metadata?.fileType ||
        assetData?.fileExtension ||
        assetData?.assetType;

    setFileType(type?.toLowerCase());
  };

  const onCheckVideoType = (asset) => {
    return (
      asset &&
      asset?.fileUrl &&
      VIDEO_TYPES_PREVIEW.indexOf(asset.fileType) > -1
    );
  };

  const onSettingVisiblePreviewBtn = (assetData) => {
    if (
      assetData?.fileUrl &&
      assetData?.pdfPreview &&
      TYPES_CONVERT_PDF_PREVIEW.indexOf(fileType) > -1
    ) {
      setPdfGroup(true);
      return true;
    }
    if (
      (assetData?.fileUrl || assetData?.iFrameLink) &&
      ASSET_TYPES_FOR_PREVIEW.indexOf(fileType) > -1
    ) {
      return true;
    }
    return false;
  };

  const onRotateRight = () => {
    setDegRotate(degRotate + 90);
  };
  const onRotateLeft = () => {
    setDegRotate(degRotate - 90);
  };

  return (
    <Row className='dam-preview'>
      {!assetData || loading ? (
        // <LoadingOutlined
        //   style={{ fontSize: '50px', width: '100%', alignSelf: 'center' }}
        // />
        <Skeleton loading={loading} />
      ) : (
        <>
          {visibleVideo ? (
            <AssetPreviewVideo assetData={assetData} />
          ) : (
            <TransformWrapper
              defaultScale={1}
              wheel={{ disabled: true }}
              zoomIn={{ step: 5 }}
            >
              {({
                zoomIn,
                zoomOut,
                resetTransform,
                positionX,
                positionY,
                scale,
                ...rest
              }) => (
                <>
                  <AssetPreviewTools
                    assetData={assetData}
                    isVisiblePreviewBtn={isVisiblePreviewBtn}
                    setVisibleModal={setVisibleModal}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    resetTransform={resetTransform}
                    onRotateRight={onRotateRight}
                    onRotateLeft={onRotateLeft}
                    scale={scale}
                    renderDownloadBtn={renderDownloadBtn}
                  />
                  <Row className='dam-preview__img-part'>
                    <TransformComponent>
                      <ImageRender
                        assetData={assetData}
                        assetImage={assetImage}
                        fileType={fileType}
                        deg={degRotate}
                      />
                    </TransformComponent>
                  </Row>
                </>
              )}
            </TransformWrapper>
          )}
          {fileType === 'iframe' ? (
            <IframePreview
              title={assetData?.assetName || 'New IFrame Asset'}
              width='100vw'
              height='100%'
              url={iFramePreview}
              visible={visibleModal}
              onClose={() => setVisibleModal(false)}
            />
          ) : (
            <PreviewableModal
              fileType={isPdfGroup ? 'pdf' : fileType}
              link={isPdfGroup ? assetData?.pdfPreview : assetData?.fileUrl}
              title={assetData?.assetName}
              visible={visibleModal}
              fileName={assetData?.fileName}
              cancelModal={() => setVisibleModal(false)}
              isDownload={false}
              isDocument={true}
            />
          )}
        </>
      )}
    </Row>
  );
};

AssetPreview.propTypes = {
  assetData: PropTypes.object,
};

const ImageRender = ({ assetImage, assetData, fileType, deg }) => {
  const setClassname = (assetData) => {
    if (assetData?.thumbnail) return '';
    else return 'dam-preview__file';
  };

  return (
    <div
      className='dam-preview__content'
      style={{ transform: `rotate(${deg}deg)` }}
    >
      <Image
        className={'dam-preview__image' + ' ' + setClassname(assetData)}
        src={assetImage || mapFileTypeToImage(fileType)}
        alt={assetData.assetName}
      />
    </div>
  );
};

export default AssetPreview;
