import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Input } from 'antd';
import { SyncOutlined, UploadOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import * as _ from 'lodash';
import classnames from 'classnames';

//* COMPONENTS
import UploadFile from 'common/components/uploadFile/UploadFile';
import { MarkdownUploadItem } from './components';

//* UTILS
import { setAssetType } from 'utils/fileType';
import * as helpMaintainApi from 'services/helpMaintenance/endpoints';
import { UPLOAD_MAX_SIZE } from 'static/Constants';

//* SAGA
import * as globalSelectors from 'redux/global/selectors';
import * as globalActions from 'redux/global/actions';

//* STYLING
import './MarkdownUploadMedia.less';

const pageSize = 20;

const { Search } = Input;

const MarkDownUploadMedia = (props) => {
  const {
    mdEditor,
    maxWidthCustomImage,
    apiService,
    apiUploadUrl,
    additionalParams = {},
    contentId,
    minimized,
    wrapperId,
    host,
    isAlwaysKeepScale,
    supportTypes = ['jpeg', 'jpg', 'png'],
    onInsertVideo,
    isVertical = false, //todo - currently only support minimized
    noCopy = false,
    noInsert = false,
    insertText,
    handleSelect,
  } = props;

  const [focusItemIndex, setFocusItemIndex] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [filter, setFilter] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);

  const dispatch = useDispatch();
  const refMeidaList = useRef(null);

  const mediaAssetFiles = useSelector(globalSelectors.selectMediaAssetFiles());
  const isFinishLoad = useSelector(globalSelectors.selectIsFinishLoadMore());

  const videoTag = (src) => {
    return `![@video](${host ? host + src : src})`;
  };

  const imageTag = (src) => {
    return `![image_name](${host ? host + src : src})`;
  };

  const haveMoreItemToFetch = () => {
    if (mediaAssetFiles.totalRecord !== null) {
      if (
        mediaAssetFiles?.data.length < mediaAssetFiles?.totalRecord &&
        !isWaiting
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleClickUpload = () => {
    if (wrapperId) {
      const $uploadEle = document.querySelector(
        `#${wrapperId} .ant-upload-btn`
      );
      $uploadEle && $uploadEle.click();
    }
  };

  const handleSearch = (value) => {
    const nextFilter = {
      search: { searchText: value },
    };
    setFilter(nextFilter);
    dispatch(
      globalActions.getMediaAssetFileSuccess({
        data: [],
        pageNumber: 0,
        totalRecord: null,
      })
    );
  };

  const resetList = () => {
    dispatch(
      globalActions.getMediaAssetFileSuccess({
        data: [],
        pageNumber: 0,
        totalRecord: null,
      })
    );
  };

  const getResponse = (result) => {
    const response = result?.response;

    if (!response) return;

    if (response?.isSuccess) {
      resetList();
    }
  };

  useEffect(() => {
    if (isFinishLoad) {
      setIsWaiting(false);
    } else {
      setIsWaiting(true);
    }
  }, [isFinishLoad]);

  const procAdditionalParams = {
    ...additionalParams,
    ...filter,
  };

  return (
    <Row
      className={classnames('markdown-upload-media', {
        'markdown-upload-media--vertical': isVertical,
      })}
      id={wrapperId}
    >
      {!minimized && (
        <Col className='markdown-upload-media__upload'>
          <div className='markdown-upload-media__title-box'>
            Upload Media File
          </div>
          <UploadFile
            showList={false}
            showMultiple={false}
            apiUrl={apiUploadUrl || helpMaintainApi.UPLOAD_HELP_ASSET}
            getResponse={getResponse}
            supportTypes={supportTypes}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
          />
        </Col>
      )}
      <Col
        className={classnames('markdown-upload-media__list', {
          'markdown-upload-media__list--minimized': minimized,
          'markdown-upload-media__list--vertical': isVertical,
        })}
      >
        <div className='markdown-upload-media__title-box'>Media Files</div>
        {isVertical && minimized && (
          <Search
            placeholder='Input search text'
            enterButton
            style={{
              padding: '4px 20px 0 14px',
            }}
            size='small'
            onSearch={handleSearch}
          />
        )}
        <div
          className={classnames(
            'markdown-upload-media__list-content',
            'scroller',
            {
              'markdown-upload-media__list-content--minimized': minimized,
              'markdown-upload-media__list-content--vertical': isVertical, //todo - only support minizied
            }
          )}
          ref={refMeidaList}
        >
          <InfiniteScroll
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
            }}
            initialLoad={true}
            pageStart={0}
            loadMore={() => {
              dispatch(
                globalActions.getMediaAssetFile({
                  apiService,
                  pageSize,
                  pageNumber: mediaAssetFiles?.pageNumber + 1,
                  // additionalParams,
                  additionalParams: procAdditionalParams,
                })
              );
              setIsWaiting(true);
            }}
            hasMore={haveMoreItemToFetch()}
            loader={
              <div
                className='markdown-upload-media__list-content-loading'
                key={0}
              >
                <SyncOutlined spin />
              </div>
            }
            useWindow={false}
          >
            {mediaAssetFiles?.data.map((fileInfo, ind) => {
              const mediaType = setAssetType(fileInfo?.fileType);
              const markDownContent =
                mediaType === 'Video'
                  ? videoTag(fileInfo?.url)
                  : imageTag(fileInfo?.url);
              return (
                <MarkdownUploadItem
                  key={'asset-item__' + fileInfo?.uniqueId}
                  id={'media-item__' + fileInfo?.uniqueId}
                  fileInfo={fileInfo}
                  focusItemIndex={focusItemIndex}
                  setFocusItemIndex={setFocusItemIndex}
                  markDownContent={markDownContent}
                  ind={ind}
                  mediaType={mediaType}
                  mdEditor={mdEditor}
                  maxWidthCustomImage={maxWidthCustomImage}
                  contentId={contentId}
                  host={host}
                  isAlwaysKeepScale={isAlwaysKeepScale}
                  onInsertVideo={onInsertVideo}
                  isVertical={isVertical}
                  noCopy={noCopy}
                  insertText={insertText}
                  handleSelect={handleSelect}
                  noInsert={noInsert}
                />
              );
            })}
          </InfiniteScroll>
        </div>
        {minimized && supportTypes.length > 0 && (
          <p className='markdown-upload-media__support-type'>
            Support Types: {supportTypes.join(', ')}
          </p>
        )}
        {minimized && (
          <Button
            className={classnames({
              'markdown-upload-media__btn-upload--normal': !isVertical,
              'markdown-upload-media__btn-upload--vertical': isVertical,
            })}
            type={isVertical ? 'primary' : ''}
            onClick={() => handleClickUpload()}
            loading={isUploading}
          >
            {!isUploading && <UploadOutlined />}
          </Button>
        )}
      </Col>

      {minimized && (
        <div style={{ visibility: 'hidden', position: 'absolute' }}>
          <UploadFile
            showList={false}
            showMultiple={false}
            apiUrl={apiUploadUrl || helpMaintainApi.UPLOAD_HELP_ASSET}
            getResponse={getResponse}
            supportTypes={supportTypes}
            getLoading={(loading) => setIsUploading(loading)}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
          />
        </div>
      )}
    </Row>
  );
};

MarkdownUploadItem.propTypes = {
  mdEditor: PropTypes.object,
  apiService: PropTypes.func,
};

export default React.memo(MarkDownUploadMedia);
