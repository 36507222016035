import React from 'react';

import ChatSpeech from './ChatSpeech';
import ChatMail from './ChatMail';

const ChatTurnItem = (props) => {
  const { item, participants, isNewChain } = props;

  const getParticipantInfo = () => {
    return participants?.find((participant) => {
      return participant.userId === item.senderId;
    });
  };

  const participant = getParticipantInfo();
  const isMail = item?.type === 'mail';

  return isMail ? (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <ChatMail item={item} />
    </div>
  ) : (
    <ChatSpeech item={item} participant={participant} isNewChain={isNewChain} />
  );
};

export default ChatTurnItem;
