import moment from 'moment';
import { useEffect } from 'react';

import { importFormDate, exportFormDate } from 'utils/formatDate';

export const useSetFormUpdateDocsAndCertification = ({
  isEnabled,
  type,
  dataForm,
  formInstance,
}) => {
  useEffect(() => {
    if (isEnabled) {
      if (type === 'document') {
        formInstance.setFieldsValue(dataForm);
      } else {
        const { certificateExpiryDate } = dataForm;
        formInstance.setFieldsValue({
          ...dataForm,
          certificateExpiryDate: importFormDate(certificateExpiryDate),
        });
      }
    }
    return;
  }, [dataForm, type, isEnabled, formInstance]);
};
