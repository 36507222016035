const getAssetTypeFilter = (filters, filterAssets) => {
  let filterAssetType = {
    fieldName: 'assetType',
    filterType: 'In',
    values: [],
  };
  if (filterAssets.includes('asset')) {
    filterAssetType.values.push('asset');
  }
  if (filterAssets.includes('document')) {
    filterAssetType.values.push('document');
  }
  if (filterAssets.includes('multimedia')) {
    filterAssetType.values.push('multimedia');
  }
  if (filterAssetType.values.length > 0) {
    filters.push(filterAssetType);
  }
};

export const filterAsset = (refMemberId, filterAssets) => {
  const defaultFilter = [
    {
      fieldName: 'ownerId',
      value: parseInt(refMemberId.current),
      filterType: 'Equal',
    },
  ];
  let filters = defaultFilter;

  if (filterAssets.indexOf('owner') > -1) {
    filters.push({
      fieldName: 'isOwner',
      value: true,
      filterType: 'Equal',
    });
  }
  if (
    filterAssets.indexOf('active') > -1 &&
    filterAssets.indexOf('pending') > -1
  ) {
    filters.push({
      fieldName: 'status',
      values: ['Active', 'Pending'],
      filterType: 'In',
    });
  } else {
    if (filterAssets.indexOf('active') > -1) {
      filters.push({
        fieldName: 'status',
        value: 'Active',
        filterType: 'Equal',
      });
    }
    if (filterAssets.indexOf('pending') > -1) {
      filters.push({
        fieldName: 'status',
        value: 'Pending',
        filterType: 'Equal',
      });
    }
    getAssetTypeFilter(filters, filterAssets);
  }
  if (filterAssets.indexOf('unmatched') > -1) {
    filters.push({
      fieldName: 'meetMinRequirement',
      value: false,
      filterType: 'Equal',
    });
    filters.push({
      fieldName: 'status',
      value: 'Active',
      filterType: 'Equal',
    });
  }

  return filters;
};
