import React from 'react';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import AddBrand from '../../controls/member-full/AddBrand';
import DeleteBrand from '../../controls/member-full/DeleteBrand';
import DownloadBrand from '../../controls/member-full/DownloadBrand';

function BrandImage() {
  return (
    <ContainerButton style={{ maxWidth: 270 }}>
      <ItemButton span={9}>
        <AddBrand />
      </ItemButton>
      <ItemButton span={15} style={{ marginTop: '6px', height: 54 }}>
        <DeleteBrand />
        <DownloadBrand />
      </ItemButton>
    </ContainerButton>
  );
}

export default BrandImage;
