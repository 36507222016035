import React from 'react';
import { isNil } from 'lodash';

import {
  getSupplementMappingLevel,
  groupDataByPartOf,
  orderNutrientList,
} from 'pages/product-full-view/components/product-media-area/supplement-label/utils';
import {
  SUPPLEMENT_NUTRIENTS,
  SUPPLEMENT_NUTRIENT_NAME,
} from 'pages/product-full-view/components/product-media-area/nutrition-label/constants';
import SeparatorBar from 'pages/product-full-view/components/product-media-area/nutrition-label/components/SeparatorBar';
import Calories from 'pages/product-full-view/components/product-media-area/supplement-label/Calories';
import SupplementList from 'pages/product-full-view/components/product-media-area/supplement-label/SupplementList';
import { SnapshotHighlightText } from '../WrapperNutrient';

import 'pages/product-full-view/components/product-media-area/supplement-label/Supplement.less';
import { renderContainerDescriptor } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/utils';

const SupplementStandardLabel = ({
  property,
  snapshotValue,
  nutrients,
  dietaryIngredients,
}) => {
  const {
    servingSize,
    servingSizeMeasurement,
    servingSizeDescription,
    numberOfServingsPerContainer,
    fdaLabelFootNote,
    calories,
    caloriesFromFat,
    otherIngredients,
  } = property || {};

  const nutrientOrdered = orderNutrientList(
    nutrients ?? [],
    SUPPLEMENT_NUTRIENTS
  );
  const nutrientGrouping = groupDataByPartOf(
    nutrientOrdered,
    SUPPLEMENT_NUTRIENT_NAME.NUTRIENT
  );
  const nutrientGroupMappingKey = getSupplementMappingLevel(nutrientGrouping);

  const dietaryIngredientsGrouping = groupDataByPartOf(
    dietaryIngredients ?? [],
    SUPPLEMENT_NUTRIENT_NAME.INGREDIENT
  );
  const dietaryIngredientsGroupMappingKey = getSupplementMappingLevel(
    dietaryIngredientsGrouping
  );

  const servingPerContainerDescriptor = renderContainerDescriptor(
    property?.servingPerContainerDescriptor
  );

  return (
    <div className='supplement-standard'>
      <div className='supplement-standard__wrapper'>
        <h1 className='m-0 supplement-standard__label'>Supplement Facts</h1>
        <SnapshotHighlightText
          label={[
            'servingSize',
            'servingSizeMeasurement',
            'servingSizeDescription',
          ]}
          currentValue={property}
          snapshotValue={snapshotValue}
        >
          <p className='m-0 supplement-standard__serving'>
            Serving Size: {servingSize} {servingSizeMeasurement}{' '}
            {servingSizeDescription}
          </p>
        </SnapshotHighlightText>
        <SnapshotHighlightText
          label={[
            'numberOfServingsPerContainer',
            'servingPerContainerDescriptor',
          ]}
          currentValue={property}
          snapshotValue={snapshotValue}
        >
          <p className='m-0 supplement-standard__serving'>
            {servingPerContainerDescriptor ? (
              <span>{servingPerContainerDescriptor}&nbsp;</span>
            ) : null}
            {numberOfServingsPerContainer} servings per container
          </p>
        </SnapshotHighlightText>
        <SeparatorBar height={8} />
        <div className='supplement-standard__heading'>
          <p className='m-0' style={{ marginLeft: 'auto' }}>
            Amount Per Serving
          </p>
          <p className='supplement-standard__heading-dv'>%Daily Value</p>
        </div>
        <SeparatorBar height={4} />
        <Calories
          property={property}
          calories={calories}
          snapshotValue={snapshotValue}
          caloriesFromFat={caloriesFromFat}
        />
        <SupplementList
          isSupplementWithoutDailyValue={false}
          data={nutrientGroupMappingKey}
        />
        {nutrientGroupMappingKey?.length || !isNil(calories) ? (
          <SeparatorBar height={8} />
        ) : null}
        <SupplementList
          isSupplementWithoutDailyValue={true}
          data={dietaryIngredientsGroupMappingKey}
        />
        {dietaryIngredientsGroupMappingKey?.length ? (
          <SeparatorBar height={8} marginTop={'-1px'} />
        ) : null}
        <SnapshotHighlightText
          label={['fdaLabelFootNote']}
          currentValue={property}
          snapshotValue={snapshotValue}
        >
          <p
            className='supplement-standard__footnote'
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {fdaLabelFootNote}
          </p>
        </SnapshotHighlightText>
      </div>
      <SnapshotHighlightText
        label={['otherIngredients']}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        {otherIngredients && (
          <p className='supplement-standard__other-ingredient'>
            <strong className='supplement-standard__other-ingredient-title'>
              Other Ingredients:
            </strong>
            <span className='supplement-standard__other-ingredient-text'>
              {otherIngredients}
            </span>
          </p>
        )}
      </SnapshotHighlightText>
    </div>
  );
};

export default SupplementStandardLabel;
