import { useEffect, useState, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useHandleHistoryBlockEvent } from './useHandleHistoryBlockEvent';

import { useHandleCancelRoutingEvent } from './useHandleCancelRouting';
import { useRoutingConfirm } from './useRoutingConfirm';
import { useHandleReloadPageKeyboardEvent } from './useHandleReloadPageKeyboardEvent';
import { checkRouteBlocked } from './utils';

export const useBlockRouteChange = ({
  isPreventRouting,
  blockId: blockIdProps,
} = {}) => {
  const [nextUrl, setNextUrl] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [blockId] = useState(blockIdProps || uuidv4());

  const { watchRoutingConfirm, triggerAndWatchRoutingConfirm } =
    useRoutingConfirm();

  const removeBlockItem = useCallback(() => {
    const { [blockId]: removedBlockItem, ...newUrlBlocked } = window.urlBlocked;
    window.urlBlocked = newUrlBlocked;
  }, [blockId]);

  const setBlockItem = useCallback(
    ({ blocked, confirmStatus, isPreventRouting } = {}) => {
      const currBlockItemInfo = window.urlBlocked[blockId] || {};

      window.urlBlocked[blockId] = {
        ...(currBlockItemInfo || {}),
        blocked,
        confirmStatus,
        isPreventRouting:
          isPreventRouting ?? currBlockItemInfo?.isPreventRouting,
      };
    },
    [blockId]
  );

  useHandleHistoryBlockEvent({
    blockId,
    setIsShowModal,
    setNextUrl,
    isPreventRouting,
  });

  const { onCancleRouting } = useHandleCancelRoutingEvent({
    blockId,
    setBlockItem,
    setIsShowModal,
  });

  useHandleReloadPageKeyboardEvent({ blockId });

  useEffect(() => {
    /**
     ** re-set: blockItem info when isPreventRouting value change
     */
    setBlockItem({
      blocked: isPreventRouting,
      confirmStatus: isPreventRouting ? null : true,
      isPreventRouting,
    });

    return () => {
      removeBlockItem();
    };
  }, [setBlockItem, removeBlockItem, isPreventRouting]);

  /**
   ** call this function => confirm 'yes' to routing
   */
  const onConfirmRouting = useCallback(async () => {
    setBlockItem({ blocked: false, confirmStatus: true });
  }, [setBlockItem]);

  useEffect(() => {
    const handleBeforeUnload = async (e) => {
      const isSomeBlocked = checkRouteBlocked();

      if (isSomeBlocked) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return {
    isShowModal,
    onConfirmRouting,
    onCancleRouting,
    nextUrl,
    watchRoutingConfirm,
    triggerAndWatchRoutingConfirm,
  };
};
