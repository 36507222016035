import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  loading: false,
  memberShortDetail: null,
  error: null,
  initialPublicContact: null,
  publicContact: null,
  publicContactList: null,
};
/* eslint-disable default-case, no-param-reassign */
const memberPreviewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MEMBER_SHORT_DETAIL_PREVIEW:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_MEMBER_SHORT_DETAIL_PREVIEW_SUCCESS:
        draft.loading = false;
        draft.memberShortDetail = action.data;
        break;
      case types.GET_MEMBER_SHORT_DETAIL_PREVIEW_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.GET_PUBLIC_CONTACT_COLUMN:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_COLUMN_SUCCESS:
        draft.loading = false;
        draft.publicContact = action.columns;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_COLUMN_ERROR:
        draft.loading = false;
        draft.publicContact = null;
        draft.error = action.error;
        break;
      case types.GET_PUBLIC_CONTACT:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_SUCCESS:
        draft.loading = false;
        draft.publicContactList = action.data;
        draft.initialPublicContact = action.data;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_ERROR:
        draft.loading = false;
        draft.publicContactList = null;
        draft.error = action.error;
        break;
      case types.UPDATE_PUBLIC_CONTACT:
        draft.publicContactList = action.data;
        break;
    }
  });
export default memberPreviewReducer;
