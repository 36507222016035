import React from 'react';

import { Tooltip, Typography, List } from 'antd';
import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';

import reactCSS from 'reactcss';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';

const styles = reactCSS({
  default: {
    iconDefault: {
      fontSize: 18,
    },
    commonAntIcon: {
      fontSize: 20,
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: -2,
    },
  },
});

const StatusRender = (props) => {
  const { value } = props;
  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();

  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  const isHaveError = value?.length > 0;

  let errorMessage = (
    <Tooltip
      title={isHaveError ? value?.[0] : 'Good'}
      color={isHaveError ? '#faad14' : 'blue'}
      mouseLeaveDelay={0}
    >
      {isHaveError ? (
        <WarningTwoTone style={styles.iconDefault} twoToneColor='#fa8c16' />
      ) : (
        <CheckCircleTwoTone style={styles.iconDefault} twoToneColor='#73d13d' />
      )}
    </Tooltip>
  );

  if (isProductAssignedByRetailer) {
    const titleTooltip = (
      <List
        dataSource={value || []}
        bordered={false}
        renderItem={(item) => (
          <List.Item key={item}>
            <Typography.Text
              style={{
                color: '#fff',
              }}
            >
              {item}
            </Typography.Text>
          </List.Item>
        )}
      />
    );

    errorMessage = (
      <Tooltip
        title={isHaveError ? titleTooltip : 'Good'}
        color={isHaveError ? '#faad14' : 'blue'}
        mouseLeaveDelay={0}
      >
        {isHaveError ? (
          <WarningTwoTone style={styles.iconDefault} twoToneColor='#fa8c16' />
        ) : (
          <CheckCircleTwoTone
            style={styles.iconDefault}
            twoToneColor='#73d13d'
          />
        )}
      </Tooltip>
    );
  }

  return (
    <div
      style={{
        width: 45,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={styles.iconWrapper}>{errorMessage}</div>
    </div>
  );
};

export default StatusRender;
