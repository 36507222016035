import { useQuery } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';
import {
  getPetFoodFactsPanel,
  getProductDrugFacts,
  getProductFactsPanel,
  getProductSupplementFacts,
} from 'services/product';

export const getKeyFactPanels = (id) => {
  return ['overview/multiple-panels/get-facts-panel', parseInt(id)];
};

export const getKeySupplementPanels = (id) => {
  return ['overview/multiple-panels/get-supplement-facts-panel', parseInt(id)];
};

export const getKeyDrugPanels = (id) => {
  return ['overview/multiple-panels/get-drug-facts-panel', parseInt(id)];
};

export const getPetFoodPanels = (id) => {
  return ['overview/multiple-panels/get-pet-food-panel', parseInt(id)];
};

export const useQueryFactsPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeyFactPanels(id),
    queryFn: async () => {
      const { data } = await getProductFactsPanel({ ProductItemId: id });
      return data?.nutritionFacts || [];
    },
  });

  return {
    listFactsPanel: data || [],
    ...restQuery,
  };
};

export const useQuerySupplementFactsPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeySupplementPanels(id),
    queryFn: async () => {
      const { data } = await getProductSupplementFacts({
        ProductItemId: id,
      });
      return data?.supplementFacts || [];
    },
  });

  return {
    listSupplementFactsPanel: data || [],
    ...restQuery,
  };
};

export const useQueryDrugFactsPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeyDrugPanels(id),
    queryFn: async () => {
      const { data } = await getProductDrugFacts({
        ProductItemId: id,
      });
      return data?.drugFacts || [];
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });

  return {
    listDrugFactsPanel: data || [],
    ...restQuery,
  };
};

export const useQueryPetFoodPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getPetFoodPanels(id),
    queryFn: async () => {
      const { data } = await getPetFoodFactsPanel({
        ProductItemId: id,
      });
      return data?.petNutritionFacts || [];
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });

  return {
    listPetFoodPanel: data || [],
    ...restQuery,
  };
};
