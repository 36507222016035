import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loadingCompanyInfo: false,
  errorCompanyInfo: false,
  loadingOffice: false,
  companyInfo: null,
  loadingShowcasedAsset: false,
  // overviewShowcasedAssets: [], // overview showcased
  // showcasedAssets: [], // showcased on tab showcase
  errorShowcasedAssets: null,
  officesList: null,
  errorOffice: false,
  totalOffice: null,
  overviewShowcasedAssets: {
    items: [],
    pagination: [],
    totalRecord: 0,
    pageIndex: 0,
    loading: false,
    error: null,
  },
  brands: {
    loading: false,
    pageIndex: 1,
    pageSize: 20,
    totalItems: 0,
    brandsList: [],
    columns: [],
    memberId: null,
  },
  memberId: null,
};

/* eslint-disable default-case, no-param-reassign */
const companyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_COMPANY_INFO:
        draft.loadingCompanyInfo = true;
        break;
      case types.GET_COMPANY_INFO_SUCCESS:
        draft.loadingCompanyInfo = false;
        draft.companyInfo = action.data;
        break;
      case types.GET_COMPANY_INFO_ERROR:
        draft.loadingCompanyInfo = false;
        draft.companyInfo = null;
        draft.errorCompanyInfo = action.error;
        break;
      case types.RESET_COMPANY_INFO_ERROR:
        draft.errorCompanyInfo = false;
        break;
      case types.GET_COMPANY_INFO_SHOWCASED_MEDIA_GRID:
        draft.overviewShowcasedAssets.loading = true;
        break;
      case types.GET_COMPANY_INFO_OVERVIEW_SHOWCASED_MEDIA_SUCCESS:
        draft.overviewShowcasedAssets.loading = false;
        draft.overviewShowcasedAssets.items = action.payload.gridData;
        draft.overviewShowcasedAssets.pagination = action.payload.paging;
        break;
      case types.GET_COMPANY_INFO_SHOWCASED_MEDIA_GRID_ERROR:
        draft.overviewShowcasedAssets.loading = false;
        draft.overviewShowcasedAssets.items = [];
        draft.overviewShowcasedAssets.pagination = null;
        draft.overviewShowcasedAssets.error = action.error;
        break;
      case types.GET_OFFICES_AND_FACILITIES:
        draft.loadingOffice = true;
        break;
      case types.GET_OFFICES_AND_FACILITIES_SUCCESS:
        draft.loadingOffice = false;
        draft.officesList = action?.data?.gridData;
        draft.totalOffice = action?.data?.paging?.totalRecord;
        break;
      case types.GET_OFFICES_AND_FACILITIES_ERROR:
        draft.loadingOffice = false;
        draft.officesList = null;
        draft.errorOffice = action.error;
        break;
      case types.GET_MEMBER_COMPANY_BRANDS:
        draft.brands['loading'] = true;
        break;
      case types.GET_MEMBER_COMPANY_BRANDS_SUCCESS:
        draft.brands['loading'] = false;
        draft.brands['brandsList'] = action?.data?.gridData || [];
        draft.brands['pageIndex'] =
          action?.data?.paging?.currentPageIndex ||
          initialState.brands.pageIndex;
        draft.brands['pageSize'] =
          action?.data?.paging?.currentPageSize || initialState.brands.pageSize;
        draft.brands['totalItems'] =
          action?.data?.paging?.totalRecord || initialState.brands.totalItems;
        break;
      case types.GET_MEMBER_COMPANY_BRANDS_ERROR:
        draft.brands['loading'] = false;
        break;
      case types.GET_MEMBER_ID:
        draft.memberId = action.memberId;
        break;
    }
  });

export default companyReducer;
