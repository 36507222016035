import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { DeleteOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const CancelEdit = ({ confirmEdit }) => {
  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.cancelEditUser}
      onClick={confirmEdit}
    />
  );
};

CancelEdit.propTypes = {
  confirmEdit: PropTypes.func,
};

export default CancelEdit;
