import React from 'react';
import { isNil } from 'lodash';

import SeparatorBar from 'pages/product-full-view/components/product-media-area/nutrition-label/components/SeparatorBar';
import SupplementList from 'pages/product-full-view/components/product-media-area/supplement-label/SupplementList';
import {
  groupDataByPartOf,
  getSupplementMappingLevel,
  orderNutrientList,
} from 'pages/product-full-view/components/product-media-area/supplement-label/utils';
import {
  SUPPLEMENT_NUTRIENTS,
  SUPPLEMENT_NUTRIENT_NAME,
} from 'pages/product-full-view/components/product-media-area/nutrition-label/constants/index';
import Calories from './Calories';

import './Supplement.less';
import { renderContainerDescriptor } from '../multiple-panel/components/utils';

const SupplementStandardLabel = ({ label }) => {
  const {
    nutrients,
    dietaryIngredients,
    servingSize,
    servingSizeMeasurement,
    servingSizeDescription,
    numberOfServingsPerContainer,
    fdaLabelFootNote,
    calories,
    caloriesFromFat,
    otherIngredients,
  } = label || {};

  const nutrientOrdered = orderNutrientList(
    nutrients ?? [],
    SUPPLEMENT_NUTRIENTS
  );
  const nutrientGrouping = groupDataByPartOf(
    nutrientOrdered,
    SUPPLEMENT_NUTRIENT_NAME.NUTRIENT
  );
  const nutrientGroupMappingKey = getSupplementMappingLevel(nutrientGrouping);

  const dietaryIngredientsGrouping = groupDataByPartOf(
    dietaryIngredients ?? [],
    SUPPLEMENT_NUTRIENT_NAME.INGREDIENT
  );
  const dietaryIngredientsGroupMappingKey = getSupplementMappingLevel(
    dietaryIngredientsGrouping
  );

  const servingPerContainerDescriptor = renderContainerDescriptor(
    label?.servingPerContainerDescriptor
  );

  return (
    <div className='supplement-standard scroller scroller--y'>
      <div className='supplement-standard__wrapper'>
        <h1 className='m-0 supplement-standard__label'>Supplement Facts</h1>
        <p className='m-0 supplement-standard__serving'>
          Serving Size: {servingSize} {servingSizeMeasurement}{' '}
          {servingSizeDescription}
        </p>
        <p className='m-0 supplement-standard__serving'>
          {servingPerContainerDescriptor ? (
            <span>{servingPerContainerDescriptor}&nbsp;</span>
          ) : null}
          {numberOfServingsPerContainer} servings per container
        </p>
        <SeparatorBar height={8} />
        <div className='supplement-standard__heading'>
          <p className='m-0' style={{ marginLeft: 'auto' }}>
            Amount Per Serving
          </p>
          <p className='supplement-standard__heading-dv'>%Daily Value</p>
        </div>
        <SeparatorBar height={4} />
        <Calories calories={calories} caloriesFromFat={caloriesFromFat} />
        <SupplementList
          isSupplementWithoutDailyValue={false}
          data={nutrientGroupMappingKey}
        />
        {nutrientGroupMappingKey?.length || !isNil(calories) ? (
          <SeparatorBar height={8} />
        ) : null}
        <SupplementList
          isSupplementWithoutDailyValue={true}
          data={dietaryIngredientsGroupMappingKey}
        />
        {dietaryIngredientsGroupMappingKey?.length ? (
          <SeparatorBar height={8} marginTop={'-1px'} />
        ) : null}
        <p
          className='supplement-standard__footnote'
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {fdaLabelFootNote}
        </p>
      </div>
      {otherIngredients && (
        <p className='supplement-standard__other-ingredient'>
          <strong className='supplement-standard__other-ingredient-title'>
            Other Ingredients:
          </strong>
          <span className='supplement-standard__other-ingredient-text'>
            {otherIngredients}
          </span>
        </p>
      )}
    </div>
  );
};

export default SupplementStandardLabel;
