import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileAddOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { CreateWorkFlowModal } from 'common/components';

const CreateWorkflow = (props) => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <RibbonButton
        icon={<FileAddOutlined />}
        label='Create'
        onClick={() => setVisibleModal(true)}
      />
      <CreateWorkFlowModal
        visible={visibleModal}
        closeModal={() => setVisibleModal(false)}
      />
    </>
  );
};

export default CreateWorkflow;
