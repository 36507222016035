import React from 'react';

import { Tabs, Typography } from 'antd';

import TabAllergens from './tab-allergens';
import TabIngredients from './TabIngredients';
import MultipleFactsPanel from './facts-panel/MultipleFactsPanel';

import './MultiplePanel.less';

const MultiplePanel = () => {
  return (
    <div className='multiple-panel__container'>
      <Tabs type='card' defaultActiveKey='fact-panels'>
        <Tabs.TabPane
          key='fact-panels'
          tab={
            <TabHeader
              icon={<i class='fa-solid fa-table'></i>}
              description={'Facts Panel'}
            />
          }
          className='tab-fact-panels'
        >
          <MultipleFactsPanel />
        </Tabs.TabPane>
        <Tabs.TabPane
          key='ingredients'
          tab={
            <TabHeader
              icon={<i class='fa-solid fa-leaf'></i>}
              description={'Ingredients'}
            />
          }
        >
          <TabIngredients />
        </Tabs.TabPane>
        <Tabs.TabPane
          key='allergens'
          tab={
            <TabHeader
              icon={<i class='fa-solid fa-person-dots-from-line'></i>}
              description={'Allergens'}
            />
          }
          className='multiple-panel__allergen-tab'
        >
          <TabAllergens />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const TabHeader = ({ icon, description }) => {
  return (
    <div className='multiple-panel__tab-header'>
      <div className='multiple-panel__tab-header--icon'>{icon}</div>
      {description && (
        <div className='multiple-panel__tab-header--description'>
          <Typography.Text>{description}</Typography.Text>
        </div>
      )}
    </div>
  );
};

export default MultiplePanel;
