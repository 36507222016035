import React from 'react';

import { Dropdown, Menu, Typography, Divider } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import { orange } from '@ant-design/colors';

import reactCSS from 'reactcss';
import { formatMDY } from 'utils/formatDate';

const { Title, Paragraph } = Typography;

const SharedIcon = (props) => {
  const { dataDetail, style = {}, shape = 'square' } = props;
  /**
   * public asset when visibility equals public
   * @param {string} visibility
   */
  const publicAsset = (visibility) => {
    if (!visibility) return false;
    return visibility.toLowerCase() === 'public';
  };

  const styles = reactCSS({
    default: {
      icon: {
        color: 'white',
        background: orange[5],
        padding: 2,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconSquare: {
        width: 19,
        height: 19,
        borderRadius: 2,
        ...style,
      },
      iconRound: {
        width: 22,
        height: 22,
        borderRadius: '100px',
        ...style,
      },
      overlay: {
        padding: 4,
        fontWeight: 900,
      },
    },
  });

  const iconWrapperStyle = {
    round: { ...styles.icon, ...styles.iconRound },
    square: { ...styles.icon, ...styles.iconSquare },
  };

  const menu = () => {
    const nameOfSharer = dataDetail?.companyName || dataDetail?.ownerName;

    return (
      <Menu className='thumbnail-item__dropdown' style={{ padding: '4px' }}>
        <Menu.Item>
          {dataDetail && publicAsset(dataDetail?.visibility) ? (
            <>
              <Title level={5} style={{ fontSize: '13px' }}>
                Public
              </Title>
              <Divider />
              {nameOfSharer && <Paragraph>{nameOfSharer} (Public)</Paragraph>}
            </>
          ) : (
            <>
              <Title level={5} style={{ fontSize: '13px' }}>
                Direct Share
              </Title>
              <Divider />
              {nameOfSharer && <Paragraph>Shared by: {nameOfSharer}</Paragraph>}
              {dataDetail?.dateShared && (
                <Paragraph>
                  Date shared: {formatMDY(dataDetail.dateShared)}
                </Paragraph>
              )}
            </>
          )}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown overlay={menu} placement='bottomRight'>
      <ShareAltOutlined style={iconWrapperStyle[shape]} />
    </Dropdown>
  );
};

export default SharedIcon;
