import React, { useMemo } from 'react';

import { UNLOCK_METHOD, OTP_ACTION, OTP_TYPE } from '../../constants';

import OtpTrigger from '../otp-trigger/OtpTrigger';

const OtpTriggerUnlockAccount = (props) => {
  const {
    unlockMethod,
    refOtpTrigger,
    setStep,
    formInst,
    setTimeAllowResend,
    isAllowResend,
    timeAllowResend,
    setOtpCheckError,
    setIsSessionExpired,
    setMfaSession,
  } = props;

  const otpTriggerProps = {
    ref: refOtpTrigger,
    setStep,
    formInst,
    setTimeAllowResend,
    isAllowResend,
    timeAllowResend,
    setOtpCheckError,
    setIsSessionExpired,
    addNew: true,
    otpAction: OTP_ACTION.unlockAccount,
    otpType:
      unlockMethod === UNLOCK_METHOD.phone
        ? OTP_TYPE.phone
        : unlockMethod === UNLOCK_METHOD.email
        ? OTP_TYPE.email
        : null,
    setMfaSession,
  };

  return <OtpTrigger {...otpTriggerProps} />;
};

export default OtpTriggerUnlockAccount;
