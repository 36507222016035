import React from 'react';
import { Typography, Tooltip } from 'antd';
import './Recipient.less';
export const Recipient = ({ item, className, ...otherProps }) => {
  const { partyGln, partyName, partyCountryName } = item;

  return (
    <div {...otherProps}>
      <div className='recipient-name'>
        <Tooltip title={partyName}>
          <Typography.Text
            ellipsis
            strong
            style={{ fontSize: 14, maxWidth: '100%' }}
          >
            {partyName}
          </Typography.Text>
        </Tooltip>
      </div>
      <div className='recipient-info'>
        <Typography.Text ellipsis>
          {partyGln}&nbsp;|&nbsp;
          {`${partyCountryName ?? 'n/a'}`}
        </Typography.Text>
      </div>
    </div>
  );
};
