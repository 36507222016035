export const GET_SSO_APPROVAL_USER =
  '/api/user-approval/get-user-approval-grid';
export const GET_SSO_APPROVAL_USER_DISTINCT =
  '/api/user-approval/get-user-approval-grid/distinct';
export const ACCEPT_OR_REJECT_USER =
  '/api/user-approval/approve-or-reject-user';
export const GET_EXISTING_USERS = '/api/user-approval/get-existing-users';
export const ASSIGN_EXISTING_USER =
  '/api/user-approval/assign-to-existing-user';

export const ACCEPT_OR_REJECT_MEMBER =
  '/api/user-approval/approve-or-reject-member';
export const ASSIGN_EXISTING_MEMBER =
  '/api/user-approval/assign-to-existing-member';
export const GET_SSO_APPROVAL_MEMBER =
  '/api/user-approval/get-member-approval-grid';
export const GET_SSO_APPROVAL_MEMBER_DISTINCT =
  '/api/user-approval/get-member-approval-grid/distinct';

export const GET_SUPPLIER_APPROVAL_GRID =
  '/api/supplier-approval/get-supplier-approval-grid';
export const GET_SUPPLIER_APPROVAL_DISTINCT =
  '/api/supplier-approval/get-supplier-approval-grid/distinct';
export const GET_SUPPLIER_APPROVAL_DETAIL =
  '/api/supplier-approval/get-supplier-approval-details';
export const APPROVAL_OF_REJECT_SUPPLIER =
  '/api/supplier-approval/approve-or-reject-supplier';

export const GET_SUPPLIER_ASSIGN_LIST =
  'api/MemberProfile/get-all-suppliers-reassign-items';
export const ASSIGN_SUPPLIER =
  'api/supplier-approval/assign-to-existing-supplier';
