import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { PushpinOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const AddOpenItemBtn = ({ clickActionCallback }) => {
  const onCheckDisableBtn = () => {
    const routes = [
      '/asset/create',
      '/asset/create-iframe',
      '/member-management-company',
      '/asset/versions',
    ];
    let currentRoute = window.location.pathname;
    let isDisable = false;
    routes.every((route) => {
      if (currentRoute.includes(route)) {
        isDisable = true;
        return false;
      } else return true;
    });
    return isDisable;
  };

  return (
    <RibbonButton
      icon={<PushpinOutlined />}
      label={Messages.addOpenItem}
      onClick={clickActionCallback}
      disabled={onCheckDisableBtn()}
    />
  );
};

AddOpenItemBtn.propTypes = {
  clickActionCallback: PropTypes.func,
};

export default AddOpenItemBtn;
