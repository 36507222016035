import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getRequestGrid = (params) => {
  return api.sendPost(endpoints.GET_REQUEST_GRID, params);
};

export const createNewRequest = (params) => {
  return api.sendPost(endpoints.CREATE_NEW_REQUEST, params);
};

export const editRequestDetail = (params) => {
  return api.sendPost(endpoints.EDIT_REQUEST, params);
};

export const getListUserToAssignRequest = (params) => {
  return api.sendGet(endpoints.GET_LIST_USER_TO_ASSIGN_REQUEST, { params });
};

export const getRequestDetailPanel = (params) => {
  return api.sendGet(endpoints.GET_REQUEST_DETAIL_PANEL, { params });
};

export const getRequestDiscussionList = (params) => {
  return api.sendGet(endpoints.GET_REQUEST_DISCUSSION_LIST, { params });
};

export const addRequestDiscussion = (params) => {
  return api.sendPost(endpoints.ADD_DISCUSSION, params);
};

export const updateLastTimeRequest = () => {
  return api.sendPost(endpoints.UPDATE_LAST_TIME_REQUEST);
};
