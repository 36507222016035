import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getListTicketGroup,
  saveTicketGroup,
  deleteTicketGroup,
  addUserToTicketGroup,
  removeUserFormTicketGroup,
} from 'services/ticketingSystem/index';
import { KEY_TICKET_FULL_VIEW } from 'hooks/ticketing-system/useTicketFullView';
import { KEY_GET_TICKET_ASSIGNMENTS } from 'hooks/ticketing-system/useGetTicketAssignTo';

export const KEY_TICKET_GROUP = {
  TICKET_GROUP_LIST: ['ticket', 'get-list-ticket-groups'],
};

export const useGetListTicketGroup = (memberId) => {
  const queries = useQuery({
    queryKey: [...KEY_TICKET_GROUP.TICKET_GROUP_LIST, memberId],
    queryFn: async () => {
      const response = await getListTicketGroup(memberId);
      return response?.data?.ticketGroupInfos;
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
    retry: false,
  });

  return {
    ticketGroupList: queries?.data,
    ...queries,
  };
};

export const useSaveTicketGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: saveTicketGroup,
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: KEY_TICKET_GROUP.TICKET_GROUP_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: [KEY_TICKET_FULL_VIEW],
      });
      queryClient.invalidateQueries({
        queryKey: [KEY_GET_TICKET_ASSIGNMENTS],
      });
    },
  });
};

export const useDeleteTicketGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTicketGroup,
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: KEY_TICKET_GROUP.TICKET_GROUP_LIST,
      });
      queryClient.invalidateQueries({
        queryKey: [KEY_TICKET_FULL_VIEW],
      });
      queryClient.invalidateQueries({
        queryKey: [KEY_GET_TICKET_ASSIGNMENTS],
      });
    },
  });
};

export const useAddUserToTicketGroup = () => {
  return useMutation({
    mutationFn: addUserToTicketGroup,
  });
};

export const useRemoveUserFormTicketGroup = () => {
  return useMutation({
    mutationFn: removeUserFormTicketGroup,
  });
};
