import { useRef, useState } from 'react';

const useExitDelay = (exitTime = 3000) => {
  const refTime = useRef(0);
  const refTimeOut = useRef();
  const [flag, setFlag] = useState(false);

  const eventHandler = () => {
    refTime.current = exitTime;
    const timeStep = exitTime < 1000 ? exitTime : 1000;

    if (refTime.current === exitTime && !flag) {
      setFlag(true);
      if (!refTimeOut.current) {
        const timeOut = setInterval(() => {
          if (refTime.current <= 0) {
            setFlag(false);
            refTimeOut.current && clearInterval(refTimeOut.current);
            refTimeOut.current = null;
          } else if (refTime.current > 0) {
            refTime.current = refTime.current - timeStep;
          }
        }, timeStep);

        refTimeOut.current = timeOut;
      }
    }
  };
  return [flag, eventHandler];
};

export default useExitDelay;
