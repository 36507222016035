import React, { useState, useEffect } from 'react';

import { Typography, Input, Space, Col, Row, Select, DatePicker } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { Form, UploadFile, FormButton, WrapperSelect } from 'common/components';
import LinkAssetToQaSpec from '../qa-grid-cell/LinkAssetToQaSpec';

import {
  ASSET_TYPE,
  DATE_FORMAT,
  DOCUMENT_CERTIFICATE_FIELD,
  UPLOAD_MAX_SIZE,
} from 'static/Constants';
import { SUPPORT_TYPES } from 'pages/qa-spec/components/qa-manufacturing-process/constants';

import * as productEndpoints from 'services/product/endpoints';

import { useSelectProductFullDetailData } from 'pages/qa-spec/hooks';
import { DOCUMENT_CERTIFICATE_LIMIT_CHARACTER } from 'pages/qa-spec/constant';

const UploadCertificate = (props) => {
  const [form] = Form.useForm();

  const { visible, setVisible, addDocument, productId } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [assetId, setAssetId] = useState(null);
  const [loading, setLoading] = useState(false);

  const productDetail = useSelectProductFullDetailData({ productId });

  const onClickBackBtn = () => {
    setCurrentStep(0);
  };

  const onClickMoveToConfig = () => {
    setCurrentStep(1);
  };

  const onFileChange = () => {
    setAssetId(null);
  };

  const handleUploadFile = (response) => {
    if (!response?.data) return;

    const {
      data: { assetId },
    } = response.data;

    if (assetId) {
      setAssetId(assetId);
      setCurrentStep(1);
    }
    return;
  };

  const onSubmitConfigForm = (values) => {
    setLoading(true);

    const params = {
      digitalAssetId: assetId,
      ...values,
      successCallback: () => {
        setVisible(false);
      },
      onFinally: () => {
        setLoading(false);
      },
    };

    addDocument(params);
  };

  useEffect(() => {
    form.setFieldsValue({
      certificateType: 'Other',
      type: 'Document',
      subtype: 'Certificate',
    });
  }, []);

  const renderUploadFile = () => {
    return (
      <>
        <div style={{ height: 300, marginTop: 12 }}>
          <UploadFile
            multiple={false}
            multipleImport={false}
            supportTypes={SUPPORT_TYPES}
            showSubmitBtn
            showList
            manualUpload
            showPreview
            showMultiple={false}
            additionalBodyPayload={{
              assetType: 'Document',
              assetSubType: 'Certificate',
              ownerId: productDetail?.ownerId,
              productId,
            }}
            apiUrl={productEndpoints.UPLOAD_PIM_ASSET}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
            getResponse={handleUploadFile}
            onFileChange={onFileChange}
            forceDisableManudalUpload={assetId}
          />
        </div>
        {assetId ? (
          <Row justify='end' style={{ marginTop: 12 }}>
            <FormButton
              type='default'
              icon={<ArrowRightOutlined />}
              text='Move to next step'
              onClick={onClickMoveToConfig}
            />
          </Row>
        ) : null}
      </>
    );
  };

  const renderConfigForm = () => {
    return (
      <Form
        style={{ height: 350 }}
        form={form}
        onFinish={onSubmitConfigForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Row
          style={{ flexDirection: 'column', height: '100%', paddingTop: 30 }}
        >
          <Col>
            <Form.Item name='type' label='Type: '>
              <WrapperSelect disabled>
                <Select.Option value='Document'>Document</Select.Option>
              </WrapperSelect>
            </Form.Item>

            <Form.Item name='subtype' label='Sub Type: '>
              <WrapperSelect disabled>
                <Select.Option value='Certificate'>Certificate</Select.Option>
              </WrapperSelect>
            </Form.Item>
            <Form.Item name='certificateType' label='Certificate Type'>
              <WrapperSelect>
                {DOCUMENT_CERTIFICATE_FIELD?.slice()
                  .sort()
                  .map((item, index) => {
                    return (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
              </WrapperSelect>
            </Form.Item>
            <Form.Item label='Certificate' name='certificate'>
              <Input />
            </Form.Item>
            <Form.Item label='Certificate Body' name='certificateBody'>
              <Input
                showCount
                maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.certificateBody}
              />
            </Form.Item>
            <Form.Item
              label='Certificate Expiry Date'
              name='certificateExpiryDate'
            >
              <DatePicker format={DATE_FORMAT.SYSTEM_FORMAT} />
            </Form.Item>
          </Col>
          <Col flex={1}></Col>
          <Col>
            <Space style={{ float: 'right' }}>
              <FormButton
                type='default'
                icon={<ArrowLeftOutlined />}
                text='Back to asset creation'
                onClick={onClickBackBtn}
              />
              <FormButton
                htmlType='submit'
                text='Complete Setup'
                loading={loading}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <LinkAssetToQaSpec
      modalProps={{
        title: 'Link certificate document',
        visible: visible,
        onCancel: () => setVisible(false),
        width: 460,
        bodyStyle: {
          minHeight: '375px',
        },
      }}
      stepProps={{
        currentStep,
        items: [
          {
            key: 0,
            title: (
              <Typography.Text strong style={{ fontSize: 16 }}>
                Create an asset
              </Typography.Text>
            ),
          },
          {
            key: 1,
            title: (
              <Typography.Text strong style={{ fontSize: 16 }}>
                Link to QA specification
              </Typography.Text>
            ),
          },
        ],
      }}
    >
      <div style={{ display: currentStep === 0 ? 'block' : 'none' }}>
        {renderUploadFile()}
      </div>
      <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
        {renderConfigForm()}
      </div>
    </LinkAssetToQaSpec>
  );
};

export default UploadCertificate;
