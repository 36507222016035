import React from 'react';
import TabFactPanels from '../TabFactPanels';

import { useParams } from 'react-router-dom';

import {
  useQueryFactsPanel,
  useQuerySupplementFactsPanel,
  useQueryDrugFactsPanel,
  useQueryPetFoodPanel,
} from './useQueryFactsPanel';

const MultipleFactsPanel = () => {
  const { id } = useParams();

  const { listFactsPanel, isLoading: factsPanelLoading } = useQueryFactsPanel({
    id,
  });

  const { listSupplementFactsPanel, isLoading: supplementLoading } =
    useQuerySupplementFactsPanel({
      id,
    });

  const { listDrugFactsPanel, isLoading: drugLoading } = useQueryDrugFactsPanel(
    {
      id,
    }
  );
  const { listPetFoodPanel, isLoading: petFoodLoading } = useQueryPetFoodPanel({
    id,
  });

  const totalFactsPanels = [
    ...listFactsPanel,
    ...listSupplementFactsPanel,
    ...listDrugFactsPanel,
    ...listPetFoodPanel,
  ];

  const loading =
    factsPanelLoading || supplementLoading || drugLoading || petFoodLoading;

  return (
    <TabFactPanels loading={loading} totalFactsPanels={totalFactsPanels} />
  );
};

export default MultipleFactsPanel;
