import React from 'react';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { get } from 'lodash';

import * as servicesGrid from 'services/grid';
import * as helpMaintainServices from 'services/helpMaintenance';
import * as types from './constants';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  getPostList,
  getPostContentDetail,
  submitCategory,
  getCategoryGrid,
  saveHelpPost,
  getHelpPost,
} from 'services/helpMaintenance';
import { formatMDYWithParam } from 'utils/formatDate';
import { dateFilterComparator } from 'utils/agGridUtils';
import { DEFAULT_SORT } from 'static/Constants';
import PostTypeIcon from '../components/help/post/icon/PostTypeIcon';
import HelpCategoryGridViewIcon from '../components/help/category/HelpCategoryGridViewIcon';
import HelpCategoryIconFilter from '../components/help/category/HelpCategoryIconFilter';
import userSelectors from 'redux/user/selectors';
import { PERMISSION } from 'static/Permission';

export function* getPostListSaga(payload) {
  try {
    const params = {
      type: payload.type,
      pageSize: payload.pageSize,
      pageIndex: payload.pageIndex,
      search: { searchText: payload['Search.SearchText'] },
      sort: DEFAULT_SORT,
    };
    const response = yield call(getPostList, params);
    if (response.isSuccess) {
      yield put(
        actions.getPostListSuccess(
          response.data.gridData,
          response.data.paging.totalRecord
        )
      );
    } else {
      yield put(actions.getPostListError(response.message));
    }
  } catch (error) {
    yield put(actions.getPostListError(error));
  }
}

const renderPostIcon = (props) => {
  return <PostTypeIcon {...props} />;
};

export function* gridPostColumnInfo(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        sortable: false,
      },
    ];

    if (response?.columns?.length > 0) {
      let hiddenCol = ['id', 'content', 'categoryId'];
      response.columns.forEach((val, index) => {
        if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
          // eslint-disable-next-line no-lone-blocks
          {
            if (val.fieldNameCamelCase === 'title') {
              val = { ...val, flex: 1, minWidth: 200, resizable: true };
            } else if (
              val.fieldNameCamelCase === 'created' ||
              val.fieldNameCamelCase === 'lastModified'
            ) {
              val = {
                ...val,
                width: 140,
                cellRenderer: formatMDYWithParam,
                resizable: true,
              };
            } else if (val.fieldNameCamelCase === 'index') {
              val = { ...val, width: 90, resizable: true };
            } else if (val.fieldNameCamelCase === 'iconName') {
              val = {
                ...val,
                width: 130,
                resizable: true,
                cellRenderer: renderPostIcon,
                filter: 'agSetColumnFilter',
                filterParams: {
                  cellRenderer: 'postTypeFilter',
                },
              };
            } else if (val.fieldNameCamelCase === 'categoryTitle') {
              val = { ...val, width: 200, resizable: true };
            } else if (val.fieldNameCamelCase === 'createdBy') {
              val = { ...val, width: 120, resizable: true };
            } else if (val.fieldNameCamelCase === 'lastModifiedBy') {
              val = { ...val, width: 160, resizable: true };
            } else {
              val = { ...val, width: 90, resizable: true };
            }
            columns.push(val);
          }
        }
      });
    }

    yield put(actions.getPostGridColumnsSuccess(columns));
  } catch (error) {
    yield put(actions.getPostGridColumnsError(error));
  }
}

export function* getColumnsFilter(payload) {
  const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
  return { response };
}

const getCellRenderer = (field) => {
  const customRender = {
    iconName: HelpCategoryGridViewIcon,
    created: formatMDYWithParam,
    lastModified: formatMDYWithParam,
  };
  return customRender[field];
};

const rowDragText = (params) => {
  const index = get(params, 'rowNode.data.index', '');

  return index;
};

const formatGridColumns = (responseColumns) => {
  const showColumns = [
    'iconName',
    'title',
    'description',
    'status',
    'totalOfPosts',
    'created',
    'lastModified',
  ];

  return showColumns.map((colName) => {
    const colData = responseColumns.find((colDataItem) => {
      return colDataItem.fieldNameCamelCase === colName;
    });
    colData.field = colData.fieldNameCamelCase;
    colData.cellRendererFramework = getCellRenderer(colData.fieldNameCamelCase);
    switch (colName) {
      case 'iconName':
        return {
          ...colData,
          width: 50,
          field: 'iconName',
          headerValueGetter: () => {
            return '';
          },
          filter: 'agSetColumnFilter',
          filterParams: { cellRendererFramework: HelpCategoryIconFilter },
        };
      case 'title':
        return {
          ...colData,
          filter: 'agTextColumnFilter',
          width: 200,
        };
      case 'description':
        return {
          ...colData,
          flex: 1,
          filter: 'agTextColumnFilter',
          minWidth: 300,
        };
      case 'status':
        return { ...colData, width: 100 };
      //TODO: DATETIME
      case 'created':
        return {
          ...colData,
          width: 150,
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams,
        };
      //TODO: DATETIME
      case 'lastModified':
        return {
          ...colData,
          width: 150,
          headerValueGetter: () => {
            return 'Last Modified';
          },
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams,
        };
      case 'totalOfPosts':
        return {
          ...colData,
          width: 100,
          headerValueGetter: () => {
            return 'Posts';
          },
        };
      default:
        return colData;
    }
  });
};

const dateFilterParams = {
  comparator: dateFilterComparator,
  browserDatePicker: true,
};

function* getCategoryGridColumn(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
    const userPermisstion = yield select(
      userSelectors.makeSelectUserPermisstion()
    );
    let columns = [
      {
        field: '',
        width:
          userPermisstion.includes(PERMISSION.SUPER_ADMIN) ||
          userPermisstion.includes(PERMISSION.MANAGE_HELP_CATEGORY)
            ? 85
            : 50,
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        sortable: false,
        rowDrag:
          userPermisstion.includes(PERMISSION.SUPER_ADMIN) ||
          userPermisstion.includes(PERMISSION.MANAGE_HELP_CATEGORY)
            ? true
            : false,
        rowDragText,
        resizable: false,
      },
    ];

    const responseColumns = response?.columns;
    if (responseColumns.length > 0) {
      const formatResponseColumns = formatGridColumns(responseColumns);
      columns = columns.concat(formatResponseColumns);
    } else {
      yield put(actions.getCategoryGridColumnsError({ message: 'No data' }));
    }
    yield put(actions.getCategoryGridColumnsSuccess(columns));
  } catch (error) {
    yield put(actions.getCategoryGridColumnsError(error));
  }
}

export function* getPostContentDetailSaga(payload) {
  try {
    const response = yield call(getPostContentDetail, payload.params);
    if (response.isSuccess) {
      yield put(actions.getPostContentDetailSuccess(response.data));
    } else {
      yield put(actions.getPostContentDetailError(response.message));
    }
  } catch (error) {
    yield put(actions.getPostContentDetailError(error));
  }
}

function* submitCategorySaga(payload) {
  try {
    const response = yield call(submitCategory, payload.params);
    if (response.isSuccess) {
      yield put(actions.sumbitCategorySuccess());
    } else {
      yield put(actions.sumbitCategoryError());
    }
  } catch (error) {
    yield put(actions.sumbitCategoryError(error));
  }
}

function* getHelpCategoryGrid(payload) {
  try {
    const response = yield call(getCategoryGrid, payload.params);
    if (response.isSuccess) {
      yield put(actions.getCategoryGridSuccess(response.data.gridData));
    } else {
      yield put(actions.getCategoryGridError());
    }
  } catch (error) {
    yield put(actions.getCategoryGridError(error));
  }
}

export function* saveHelpMaintenancePost(payload) {
  try {
    const response = yield call(saveHelpPost, payload.params);
    if (response.isSuccess) {
      yield put(actions.saveHelpPostSuccess(response.data.id));
    } else {
      yield put(actions.saveHelpPostError(response.message));
    }
  } catch (error) {
    yield put(actions.saveHelpPostError(error));
  }
}

export function* saveHelpPostDetail(payload) {
  try {
    const response = yield call(getHelpPost, payload.id);
    if (response.isSuccess) {
      yield put(actions.savePostDetailSuccess(response.data));
    } else {
      yield put(actions.savePostDetailError(response.message));
    }
  } catch (error) {
    yield put(actions.savePostDetailError(error));
  }
}

export function* updateHelpAssetFileSaga(action) {
  const helpAssetFiles = yield select(selectors.selectHelpAssetFiles());
  if (!action.payload) {
    yield put(actions.updateHelpAssetFileSuccess([]));
    return;
  } else {
    yield put(
      actions.updateHelpAssetFileSuccess([
        ...helpAssetFiles,
        {
          ...action.payload,
          fileUrl: window.location.origin + action.payload.fileUrl,
        },
      ])
    );
  }
}

export function* getHelpAssetFilesSaga(action) {
  const { pageNumber, pageSize } = action.payload;
  let paramsApi = {
    pageSize,
    pageIndex: pageNumber,
    sort: [
      {
        fieldName: 'created',
        isAscending: false,
      },
    ],
    filters: [],
  };
  const helpAssetFiles = yield select(selectors.selectHelpAssetFiles());
  let response = yield call(helpMaintainServices.getHelpAssets, paramsApi);
  if (response?.isSuccess === true) {
    let procHelpAssets = response?.data.gridData.map((helpAsset) => ({
      ...helpAsset,
      url: window.location.origin + helpAsset?.url,
    }));
    const fetchPageIndex = response?.data?.paging?.currentPageIndex;
    const fetchPageSize = response?.data?.paging?.currentPageSize;

    const headArray = helpAssetFiles?.data.slice(
      0,
      (fetchPageIndex - 1) * fetchPageSize
    );
    const replaceArray = helpAssetFiles?.data.slice(
      (fetchPageIndex - 1) * fetchPageSize,
      pageSize
    );

    const tailSecondArg =
      helpAssetFiles?.data.length - headArray.length - replaceArray.length;
    const tailArray = helpAssetFiles?.data.slice(
      fetchPageIndex * fetchPageSize,
      tailSecondArg > 0 ? tailSecondArg : 1
    );

    yield put(
      actions.getHelpAssetFileSuccess({
        data: [...headArray, ...procHelpAssets, ...tailArray],
        pageNumber,
        totalRecord: response?.data?.paging.totalRecord,
      })
    );
  }
}

function* watchAll() {
  yield all([
    takeLatest(
      types.GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN,
      getCategoryGridColumn
    ),
    takeLatest(types.GET_POST_GRID, getPostListSaga),
    takeLatest(types.GET_POST_GRID_COLUMN, gridPostColumnInfo),
    takeLatest(types.GET_POST_CONTENT_DETAIL, getPostContentDetailSaga),
    takeLatest(types.SUBMIT_CATEGORY, submitCategorySaga),
    takeLatest(types.GET_HELP_MAINTENANCE_CATEGORY_GRID, getHelpCategoryGrid),
    takeLatest(types.SAVE_HELP_MAINTENANCE_POST, saveHelpMaintenancePost),
    takeLatest(types.SAVE_POST_DETAIL, saveHelpPostDetail),
    takeLatest(types.UPDATE_HELP_ASSET_FILE, updateHelpAssetFileSaga),
    takeLatest(types.GET_HELP_ASSETS, getHelpAssetFilesSaga),
  ]);
}

export default watchAll;
