import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, Button, Divider, Row, Col, Typography, Space } from 'antd';

import { Form, NewPasswordFormItem } from 'common/components';
import { useChangeUserPassword } from 'hooks/useChangeUserPassword';

import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/user-profile.js';

import './ChangePassWordForm.less';

const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ChangePasswordForm = (props) => {
  const {
    form,
    loadingModal,
    strengthTypeMode,
    errorMessage,
    ignoreCurrentPassword,
    cancelButtonProps = {},
  } = props;
  const mode = strengthTypeMode;

  const [disabledChangePassword, setDisabledChangePassword] = useState(true);

  // useEffect(() => {
  //   const hasEmptyField =
  //     (!ignoreCurrentPassword && currentPassword === '') ||
  //     newPassword === '' ||
  //     confirmedPassword === '';
  //   const isSameOldPassword = currentPassword === newPassword;
  //   const isConfirmNotCorrect = newPassword !== confirmedPassword;
  //   const isContainSpecialCharacters = !isNotSpecialCharacter(newPassword);

  //   const isDisabled =
  //     hasEmptyField ||
  //     isSameOldPassword ||
  //     isContainSpecialCharacters ||
  //     !validatedNewPass ||
  //     isConfirmNotCorrect
  //       ? true
  //       : false;

  //   setDisabledChangePassword(isDisabled);
  // }, [currentPassword, newPassword, confirmedPassword, validatedNewPass]);

  const onFinish = (values) => {
    props.handleOk(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onValuesChange = (changedValues, allValues) => {
    updateSubmitDisabled(allValues);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onCancel = () => {
    onReset();
    props.handleCancel();
  };

  const updateSubmitDisabled = (values) => {
    setDisabledChangePassword(() => {
      const { newPassword, currentPassword, confirmedPassword } = values;

      if (!newPassword) return true;
      if (!ignoreCurrentPassword && !currentPassword) return true;
      if (!confirmedPassword) return true;

      if (confirmedPassword !== newPassword) return true;

      if (currentPassword === newPassword) return true;
      if (!checkSpecialCharacter(newPassword)) return true;

      const error = form.getFieldError('newPassword');
      if (error?.length > 0) return true;

      return false;
    });
  };

  const checkSpecialCharacter = (password) => {
    const specialCharRegex = new RegExp(/^[^~`()_+\-=\[\]{};':"\\|,.<>\/?]*$/);
    if (specialCharRegex.test(password)) {
      return true;
    }
    return false;
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };

  return (
    <Form
      {...layout}
      form={form}
      name='basic'
      className='change-password-form'
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
    >
      {!ignoreCurrentPassword && (
        <Form.Item
          label={<FormattedMessage {...Messages.currentPassword} />}
          name='currentPassword'
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage {...Messages.inputYourCurrentPassword} />
              ),
            },
          ]}
        >
          <Input.Password onKeyDown={handleKeyDown} />
        </Form.Item>
      )}
      <NewPasswordFormItem
        mode={mode}
        label={<FormattedMessage {...Messages.newPassword} />}
        dependencies={['currentPassword']}
        name='newPassword'
        layout={layout}
        rules={[
          {
            required: true,
            message: <FormattedMessage {...Messages.inputYourNewPassword} />,
          },
          () => ({
            validator(_, value) {
              if (checkSpecialCharacter(value) || value === '') {
                return Promise.resolve();
              }
              return Promise.reject(
                <FormattedMessage {...Messages.passwordContain} />
              );
            },
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('currentPassword') !== value) {
                return Promise.resolve();
              }
              return Promise.reject(
                <FormattedMessage {...Messages.passwordShouldNotSame} />
              );
            },
          }),
        ]}
      >
        <Input.Password onKeyDown={handleKeyDown} />
      </NewPasswordFormItem>

      <Form.Item
        label={<FormattedMessage {...Messages.confirmedPassword} />}
        name='confirmedPassword'
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage {...Messages.inputYourConfirmedPassword} />
            ),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('newPassword') === value || value === '') {
                return Promise.resolve();
              }
              return Promise.reject(
                <FormattedMessage {...Messages.passwordNotMatch} />
              );
            },
          }),
        ]}
      >
        <Input.Password onKeyDown={handleKeyDown} />
      </Form.Item>
      {errorMessage && errorMessage !== '' && (
        <Row>
          <Col span={16} offset={8}>
            <Text type='danger'>{errorMessage}</Text>
          </Col>
        </Row>
      )}
      <Divider className='change-password-form__divider' />
      <Row className='change-password-form__footer'>
        <Space>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledChangePassword}
            loading={loadingModal}
          >
            <FormattedMessage {...Messages.changePassword} />
          </Button>
          <Button onClick={onCancel} {...cancelButtonProps}>
            <FormattedMessage {...Messages.cancel} />
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  // loadingModal: to set loading button
  loadingModal: PropTypes.bool,
  // strengthTypeMode: strength password type: any, weak, medium, strong
  strengthTypeMode: PropTypes.string.isRequired,
  // ignoreCurrentPassword: hide current password field
  ignoreCurrentPassword: PropTypes.bool,
};

export default ChangePasswordForm;
