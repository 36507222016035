import React from 'react';

import { Typography, Input, Tree, Checkbox, Row, Col } from 'antd';

import classnames from 'classnames';

import { WithLoading } from 'common/components';
import AssetToFolderTool from './AssetToFolderTool';

import './AssetToFolder.less';

const AssetToFolder = (props) => {
  const {
    loadingTree,
    subFolderParams = {},
    treeData,
    onSearch,
    onUpdateFolder,
    onToggleCreateSubfolder,
    selectedItem,
    onCreateNewFolderSuccess,
    ...rest
  } = props;

  const { isShow, isCreateSubfolder } = subFolderParams;

  return (
    <div style={{ padding: 6 }}>
      <Row style={{ padding: '3px 6px' }} gutter={[24, 24]} align='middle'>
        <Col>
          <Typography.Title level={5} style={{ marginTop: 4 }}>
            Destination Folder:
          </Typography.Title>
        </Col>
        <Col style={{ flex: 1 }}>
          <Input.Search
            placeholder='Input to search folder...'
            onSearch={onSearch}
            enterButton
            allowClear
          />
        </Col>

        {isShow && (
          <Col>
            <Checkbox
              style={{ marginLeft: 16, flexShrink: 0 }}
              onChange={(event) => {
                onToggleCreateSubfolder(event.target.checked);
              }}
              checked={isCreateSubfolder}
            >
              <Typography.Text>Create subfolders</Typography.Text>
            </Checkbox>
          </Col>
        )}
        <Col>
          <AssetToFolderTool
            selectedItem={selectedItem}
            onCreateNewFolderSuccess={onCreateNewFolderSuccess}
          />
        </Col>
      </Row>

      <WithLoading loading={loadingTree}>
        <div
          className={classnames({
            'asset-folder__tree': true,
            'asset-folder__tree--multiple': true,
          })}
        >
          <Tree
            showIcon
            height={500}
            multiple={false}
            checkable={false}
            treeData={treeData}
            selectable={true}
            onSelect={onUpdateFolder}
            {...rest}
          />
        </div>
      </WithLoading>
    </div>
  );
};

export default AssetToFolder;
