// dropdown
import SelectLocale from './dropdown/SelectLocale';
// image
import Image from './image/Image';
// indicator
import CustomIndicator from './indicator/Indicator';
import SuspendIndicator from './indicator/SuspendIndicator';
// layout
import GridStyleLayout from './layout/GridStyleLayout';
import Content from './layout/Content';
import Footer from './layout/Footer';
import Header from './layout/Header';
import ArrangeableLayout from './arrangeable-layout/ArrangeableLayout';
// text
import Counter from './text/Counter';

//? empty
import EmptyList from './empty-view/EmptyList';

//? wrapper
import WithAbility from './wrapper/WithAbility';
import WithHelmet from './wrapper/WithHelmet';
import WithIndicator from './wrapper/WithIndicator';
import WithLoading from './wrapper/WithLoading';
import WithAbsoluteContainer from './wrapper/WithAbsoluteContainer';
import WithReloadInternalData from './wrapper/WithReloadInternalData';

//? button
import VerticalButton from './button/VerticalButton';
import DeleteButton from './button/delete-button/DeleteButton';
import FormSaveButton from './button/FormSaveButton';
import FormAddButton from './button/FormAddButton';
import FormEditButton from './button/FormEditButton';
import FormCancelButton from './button/FormCancelButton';
import FormCreateButton from './button/FormCreateButton';
import FormDeleteButton from './button/FormDeleteButton';
import FormViewButton from './button/FormViewButton';
import FormButton from './button/FormButton';
import EditButton from './button/EditButton';
import SwitchButton from './button/SwitchButton';
import CopyButton from './button/CopyButton';
import ReGenerateButton from './button/ReGenerateButton';
import ShowHideButton from './button/ShowHideButton';
import BoldTextButton from './button/BoldTextButton';
import GeneralButton from './button/GeneralButton';
import BorderButton from './button/BorderButton';

//? upload file
import UploadFile from './uploadFile/UploadFile';
import UploadFileModal from './uploadFile/UploadFileModal';

//FMK_410 Grid
import GridView from './grid-view/GridView';
import AgGridClient from './ag-grid/AgGridClient';
import AgGrid from './ag-grid/AgGrid';
import AgGridThumbnail from './ag-grid/AgGridThumbnail';
import AgGridIcons from './ag-grid/components/AgGridIcons';
import ThumbnailItem from './thumb/ThumbnailItem';
import ContentPane from './grid-view/components/content-pane/ContentPane';
import Textarea from './textarea/Textarea';
import TextSlickSlider from './slickslider/text-slick-slider/TextSlickSlider';
import {
  WithAggridHeaderEdit,
  AgGridDownloadOptions,
} from './ag-grid/components';

// profile content
import ProfileImage from './image/ProfileImage';
import ProfileAddress from './profile-address/ProfileAddress';
import ContactActions from './contact-action/ContactActions';
import SocialLink from './social-link/SocialLink';
import InfoWithLabel from './info-with-label/InfoWithLabel';

import TextAreaGrid from './textarea-grid/TextAreaGrid';

import TabLayout from './tab-layout/TabLayout';

import AbandonDialog from './grid-view/components/abandon-dialog/AbandonDialog';

import Chat from './badge/Chat';
import Task from './badge/Task';
import Email from './badge/Email';

//? images
import CropImage from './cropImage/CropImage';

// history
import HistorySection from './history/HistorySection';
import ProfileContactHistory from './history/ProfileContactHistory';

// tooltip
import TooltipParagraph from './tooltip/TooltipParagraph';
import GlnTooltip from './tooltip/GlnTooltip';

// icon
import TypePicker from './type-picker/TypePicker';

// service
import ServiceType from './service-type/ServiceType';

// context
import LabelValueWrapper from './label-value-wrapper/LabelValueWrapper';

// iframe preview
import IframePreview from './iframe-preview/IframePreview';

// color picker
import ColorPicker from './color-picker/ColorPicker';

//dialog
import dialogFunction from './dialog-function/DialogFunction';
import dialogOkFunction from './dialog-function/DialogOkFunction';
import DialogModal from './dialog-function/DialogModal';
import * as DialogIcon from './dialog-function/DialogIcon';

//? markdown
import EnhancedMarkdown from './markdown/EnhancedMarkdown';
import EnhancedMarkdownEditor from './markdown/EnhancedMarkdownEditor';
import MarkdownUploadMedia from './markdown/uploadMedia/MarkdownUploadMedia';
import MdEditorCustomToolbar from './markdown/customToolbar/MdEditorCustomToolbar';

//? Modal
import ModalAddToFolder from './modal-add-to-folder/ModalAddToFolder';
import ModalShare from './modal-share/ShareModal';
import ShareMappingModal from './modal-share/ShareMappingModal';
import ContactCardModal from './modal-contact-card/ContactCardModal';
import StyledModal from './modal';
import {
  ReassignAssetToMemberModal,
  ReassignProductToMemberModal,
  ReassignGLNToMemberModal,
  MoveUserToMemberModal,
  SearchEntityBtn,
} from './modal';

//? Anonymous
import AnonLogin from './anonymous-view/AnonLogin';
import AnonViewWrap from './anonymous-view/AnonViewWrap';

//? notification
import * as CustomNotification from './notification/CustomNotification';
import LogoAssetModal from './modal-logo-assets/LogoAssetModal';

import EmailSelection from './email-selection/EmailSelection';

//? background
import BackgroundEdit from './backgroundEdit/BackgroundImageEdit';

//? form
import Form from './form/Form';
import FakeForm from './form/fake-form/FakeForm';
import TagInput from './form/tagInput/TagInput';
import SuggestionInput from './input/SuggestionInput';
import SaveCancelGroup from './form/saveCancelGroup/SaveCancelGroup';
import SliderNumber from './form/slider-number/SliderNumber';
import AvatarTagAutocomplete from './form/avatar-tag-autocomplete';
import OuterForm from './form/use-outer-form/OuterForm';
import StepForm from './form/step-form';
import useFormHook from './form/use-form/useFormHook';
import FormInputHTML from './form/form-input-html/FormInputHtml';
import MemberSelectInput from './form/member-select-input/MemberSelectInput';
import TargetMarketInput from './input/TargetMarketInput';
import {
  VerticalSplitFormRow,
  UploadFileFormInput,
  ThreeStateCheckboxFieldInput,
  FlagCountrySelectionFieldInput,
} from './form';
import NumberTextInput from './input/NumberTextInput';
import NewPasswordFormItem from './form/NewPasswordFormItem';
import { BasicFormItemList } from 'common/components/form/basic-form';

//? tag
import SquareTag from './tag/squareTag/SquareTag';
import HelpCenterTag from './tag/helpCenterTag/HelpCenterTag';
import EntityStatusTag from './tag/entity-status-tag/EntityStatusTag';

//? button
import CloseModalBtn from './button/modal/CloseModalBtn';
import PopoverBtn from './popoverBtn/PopoverBtn';

//? drap-drop
import DragWrap from './drapDropWrap/drap-and-drop/DrapWrap';
import DropWrap from './drapDropWrap/drap-and-drop/DropWrap';
import * as DragDropWrapUtils from './drapDropWrap/drap-and-drop/utils/mapGridViewDataToDragData';
import DrapDropPosWrap from './drapDropWrap/drag-drop-pos';
import useDragDropPosHooks from './drapDropWrap/drag-drop-pos/hooks/useDrapDropHooks';

//? openitems
import * as OpenItem from './openItem';

//? chat
import ChatView from './message/Chat';
import VideoCall from './videoCall/VideoCall';
import { ReceiverRington } from './videoCall';

//? Email
import EmailView from './mail/Email';
import EmailEditor from './email-editor';

//? input search
import InputSearchAddRemoveList from './inputSearchAddRemoveList/InputSearchAddRemoveList';

// favorite star icon
import FavoriteStarIconRender from './favorite-star/FavoriteStarIconRender';
// Edit Asset Type Form
import AssetTypeEdit from './asset-type-edit/AssetTypeEdit';
import Video from './video-preview/VideoPreview';

//advanced search
import AdvancedSearch from './advanced-search/AdvancedSearch';

import EditButtonsSection from './btn-save-cancel/EditButtonsSection';

import FullWidthSpace from './space/FullWidthSpace';
import TextTooltip from './text/TextTooltip';

// icon
import IconList from './icon-list/IconList';

//?workflow
import CreateWorkFlowModal from './workflow-maintain/CreateWorkFlowModal';

//? progress
import CountdownTrack from './countdownTrack';

//? button
import RibbonButton from './button/RibbonButton';
import ButtonLargeIcon from './button/ButtonLargeIcon';
import ButtonSmallIcon from './button/ButtonSmallIcon';
import ItemButton from './button/ItemButton';
import ContainerButton from './button/ContainerButton';

//? chart
import GaugeChart from './chart/gauge/GaugeChart';

//? signalR
import SignalrHandler from './signal-handler';

//? security matrix
import {
  MemberRoleList,
  MemberRolePermissionList,
  MemberRoleSectionWrapper,
  MemberRoleDescription,
} from 'pages/role/components';
import { ModalCreateRequest } from 'pages/home/ribbon/security-request/NewRequest.js';

//? page editor
import { PageEditor, PageEditorPreview } from './page-editor';

import {
  SourceField,
  ModulePackageLevel,
  SourceFieldComplexType,
} from './mapping-properties/MappingSourceProperties';

import Fieldset from './fieldset/Fieldset';

import {
  FieldsetWrapper,
  FieldsetContent,
  FieldsetTitle,
} from './fieldset/Fieldset';

// import DataList from './list/DataList';
import EntityList from './list/EntityList';
import DataDisplay from './list/DataDisplay';
import RivirAlert from './rivir-alert/index';

import ValidateFieldNameMapping from './validate-field-name/ValidateFieldName';
import { DisplayErrorsGlns } from './validate-field-name/ValidateFieldName';
import MemberGlnList from './member-grid/MemberGlnList';
import InputTimeOutLook from './input-time-outlook/InputTimeOutLook';

import Loading from './loading/Loading';
import MaskLoading from './mask-loading/MaskLoading';

import CustomizeTransfer from './custom-transfer/CustomTransfer';

import WrapperSelect from './select';

import RibbonBar, { RibbonSection } from './ribbon/RibbonBar';
import RibbonPortal from './ribbon/RibbonPortal';
import RibbonDivider from './ribbon/RibbonDivider';
import MinimumRequirementsValidate from './ribbon/button/MinimumRequirementsValidate';

//* minimum requirements
import OpenUpdateMinimumRequirementButton from './preview-requirements/OpenUpdateMinimumRequirementButton';
import MinimumRequirementPropertiesInput from './preview-requirements/MinimumRequirementPropertiesInput';

import DownloadFormItem from './download-new-form-item/DownloadFormItem';

//* create product
import BrickCodeInput from './product-add/BrickCodeInput';

import NestedDetailFrameWork from './product-nested-detail-grid/NestedDetailFramework';
import SectionWrapper from './section-wrapper/SectionWrapper';
import SectionHeader from './section-header/SectionHeader';
import PreviewableModal from './previewable-modal/PreviewableModal';
import ProductCategoryTree from './product-category-tree/ProductCategoryTree';
import RemoveButtonWrapper from './wrapper/RemoveButtonWrapper';

// folder
import { FolderList, FolderSelector } from './folder';

// form-item
export * from './form/form-item';

// carousel-section
export * from './carousel-section';

// fieldset

// export
export const LocaleDropdown = SelectLocale;
export const Img = Image;
export const Indicator = CustomIndicator;
export const DelayedFallback = SuspendIndicator;
export const ContentLayout = Content;
export const FooterLayout = Footer;
export const HeaderLayout = Header;
export const CounterText = Counter;
export const WithAbilityWrapper = WithAbility;
export const WithHelmetWrapper = WithHelmet;
export const WithIndicatorWrapper = WithIndicator;

const FormComponents = {
  TagInput,
  SliderNumber,
  SaveCancelGroup,
  FormInputHTML,
};

export {
  VerticalButton,
  ThumbnailItem,
  GridView,
  //? Aggrid
  AgGridClient,
  AgGrid,
  AgGridDownloadOptions,
  AgGridThumbnail,
  AgGridIcons,
  WithAggridHeaderEdit,
  ContentPane,
  ProfileImage,
  ContactActions,
  SocialLink,
  InfoWithLabel,
  TextAreaGrid,
  ProfileAddress,
  ProfileContactHistory,
  AbandonDialog,
  Textarea,
  TextSlickSlider,
  TabLayout,
  Chat,
  Task,
  Email,
  RivirAlert,
  HistorySection,
  TypePicker,
  ServiceType,
  LabelValueWrapper,
  dialogFunction,
  dialogOkFunction,
  DialogIcon,
  ModalAddToFolder,
  DeleteButton,
  DialogModal,
  //? upload file
  UploadFile,
  UploadFileModal,
  //? markdown
  EnhancedMarkdown,
  MarkdownUploadMedia,
  MdEditorCustomToolbar,
  EnhancedMarkdownEditor,
  //?
  IframePreview,
  FormSaveButton,
  FormAddButton,
  FormEditButton,
  FormCancelButton,
  FormCreateButton,
  FormDeleteButton,
  FormViewButton,
  EditButton,
  BoldTextButton,
  GeneralButton,
  TooltipParagraph,
  WithLoading,
  CustomNotification,
  StyledModal,
  LogoAssetModal,
  ColorPicker,
  EmailSelection,
  CropImage,
  BackgroundEdit,
  //? modal
  ModalShare,
  ShareMappingModal,
  ContactCardModal,
  // SearchGridItemModal,
  ReassignAssetToMemberModal,
  ReassignProductToMemberModal,
  ReassignGLNToMemberModal,
  MoveUserToMemberModal,
  SearchEntityBtn,
  //? anonymous
  AnonLogin,
  AnonViewWrap,
  //? empty
  EmptyList,
  //? wrap
  WithAbsoluteContainer,
  //? button
  CloseModalBtn,
  PopoverBtn,
  FormButton,
  //? form
  Form,
  FakeForm,
  TagInput,
  SaveCancelGroup,
  FormComponents,
  AvatarTagAutocomplete,
  OuterForm,
  StepForm,
  useFormHook,
  FormInputHTML,
  MemberSelectInput,
  VerticalSplitFormRow,
  UploadFileFormInput,
  ThreeStateCheckboxFieldInput,
  FlagCountrySelectionFieldInput,
  MaskLoading,
  //? loading
  //? tag
  SquareTag,
  HelpCenterTag,
  EntityStatusTag,
  //? drap-drop
  DragWrap,
  DropWrap,
  DragDropWrapUtils,
  useDragDropPosHooks,
  DrapDropPosWrap,
  //? openitem
  OpenItem,
  //? Chat
  ChatView,
  VideoCall,
  ReceiverRington,
  //? Email
  EmailView,
  EmailEditor,
  //? input
  InputSearchAddRemoveList,
  //? progress
  CountdownTrack,
  //?
  FavoriteStarIconRender,
  AssetTypeEdit,
  Video,
  AdvancedSearch,
  EditButtonsSection,
  FullWidthSpace,
  TextTooltip,
  SwitchButton,
  CopyButton,
  ReGenerateButton,
  ShowHideButton,
  IconList,
  CreateWorkFlowModal,
  ButtonSmallIcon,
  ButtonLargeIcon,
  ItemButton,
  ContainerButton,
  SourceField,
  ModulePackageLevel,
  SourceFieldComplexType,
  // DataDisplay,
  //? chart
  GaugeChart,
  ValidateFieldNameMapping,
  DisplayErrorsGlns,
  GlnTooltip,
  MemberGlnList,
  ArrangeableLayout,
  //? signalR
  SignalrHandler,
  InputTimeOutLook,
  Loading,
  //? security matrix
  MemberRoleList,
  MemberRolePermissionList,
  MemberRoleSectionWrapper,
  MemberRoleDescription,
  ModalCreateRequest,
  //? page editor
  PageEditor,
  PageEditorPreview,
  CustomizeTransfer,
  WrapperSelect,
  RibbonButton,
  RibbonSection,
  RibbonBar,
  RibbonPortal,
  RibbonDivider,
  DownloadFormItem,
  MinimumRequirementsValidate,
  OpenUpdateMinimumRequirementButton,
  MinimumRequirementPropertiesInput,
  NumberTextInput,
  //* create product
  BrickCodeInput,
  NestedDetailFrameWork,
  SectionWrapper,
  SectionHeader,
  PreviewableModal,
  TargetMarketInput,
  NewPasswordFormItem,
  GridStyleLayout,
  ProductCategoryTree,
  // folder
  FolderList,
  FolderSelector,
  // reload data
  WithReloadInternalData,
  Fieldset,
  EntityList,
  RemoveButtonWrapper,
  FieldsetWrapper,
  FieldsetContent,
  FieldsetTitle,
  SuggestionInput,
  BorderButton,
  DataDisplay,
  BasicFormItemList,
};
