export const updateDynamicContentByTag = ({
  prevState,
  tags,
  field,
  value = true,
}) => {
  const newState = Object.keys(prevState).reduce(
    (accumulator, currentField) => {
      if (tags.includes(currentField)) {
        const newCurrentField = {
          ...prevState[currentField],
          [field]: value,
        };
        return { ...accumulator, [currentField]: newCurrentField };
      }

      //* remove highlight for other tags
      if (field === 'highlight') {
        const newCurrentField = {
          ...prevState[currentField],
          highlight: false,
        };
        return { ...accumulator, [currentField]: newCurrentField };
      }

      return accumulator;
    },
    prevState
  );

  return newState;
};
