import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getAvailableMembersForSharing = (data) => {
  return api.sendGet(endpoints.GET_AVAILABLE_MEMBERS_FOR_SHARING, {
    params: data,
  });
};
export const getAvailableUsersForSharing = (data) => {
  return api.sendGet(endpoints.GET_AVAILABLE_USERS_FOR_SHARING, {
    params: data,
  });
};

export const getShareList = (data) => {
  return api.sendPost(endpoints.GET_SHARE_DATA_LIST, data);
};

export const updateSharing = (listEntities) => {
  const data = {
    listEntities,
  };
  return api.sendPost(endpoints.UPDATE_SHARING, data);
};

export const removeSharing = (listEntities) => {
  const data = {
    listEntities,
  };
  return api.sendPost(endpoints.REMOVE_SHARING, data);
};

export const getMemberSharing = (params) => {
  return api.sendPost(endpoints.GET_MEMBER_SHARING, params);
};

export const shareDataApi = (params) => {
  return api.sendPost(endpoints.SHARE_BUTTON, params);
};
