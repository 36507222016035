import React from 'react';

export const SnapshotHighlightText = (props) => {
  const { label = [], currentValue, snapshotValue, children } = props;

  if (!snapshotValue) return <>{children}</>;

  const isHightLightText = label?.some(
    (item) => currentValue?.[item] !== snapshotValue?.[item]
  );

  return (
    <div style={{ backgroundColor: isHightLightText ? '#e6f7ff' : '' }}>
      {children}
    </div>
  );
};

export const HighlightTextLinear = (props) => {
  const { label = [], currentValue, snapshotValue, children } = props;

  if (!snapshotValue) return <>{children}</>;

  const isHightLightText = label?.every(
    (item) => currentValue?.[item] !== snapshotValue?.[item]
  );

  return (
    <span style={{ backgroundColor: isHightLightText ? '#e6f7ff' : '' }}>
      {children}
    </span>
  );
};
