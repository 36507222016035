import React, { useEffect, useState, useRef, useMemo } from 'react';
import MDEditor from '@uiw/react-md-editor';
import TextRender from './markdownRenderers/TextRender';
import ImageRender from './markdownRenderers/ImageRender';
import ParagraphRender from './markdownRenderers/ParagraphRender';
import HeadingRender from './markdownRenderers/HeadingRender';

import * as _ from 'lodash';

/**
 * @param {ReactMarkdown.ReactMarkdownProps} props
 * @type {ReactMarkdown.ReactMarkdownProps['renderers']} /
 */
const EnhancedMarkdown = ({ source, refSource, ...props }) => {
  const [refComponents, setRefComponents] = useState(null);

  const refEnhancedMarkdown = useRef(null);

  const imagePreprocessor = (source) =>
    source?.replace(
      /(!\[[^\]]*\]\([^)\s]+) =([0-9]+x|x[0-9]+|[0-9]+x[0-9]+)\)/g,
      '$1_33B2BF251EFD_$2)'
    );

  source = imagePreprocessor(source);

  const renderers = useMemo(
    () => ({
      image: ImageRender(refComponents),
      text: TextRender,
      paragraph: ParagraphRender,
      heading: HeadingRender,
    }),
    [refComponents]
  );

  useEffect(() => {
    setRefComponents(refSource);
  }, [refSource]);

  return (
    <div ref={refEnhancedMarkdown} style={{ width: '100%' }}>
      <MDEditor.Markdown source={source} renderers={renderers} {...props} />
    </div>
  );
};

export default EnhancedMarkdown;
