import * as types from './constants';

export function getPostList(pageSize, pageNumber, search) {
  return {
    type: types.GET_POST_GRID,
    pageSize: pageSize,
    pageIndex: pageNumber,
    'Search.SearchText': search,
  };
}

export function getPostListSuccess(posts, total) {
  return {
    type: types.GET_POST_GRID_SUCCESS,
    posts,
    total,
  };
}

export function getPostListError(error) {
  return {
    type: types.GET_POST_GRID_ERROR,
    error,
  };
}

export const getPostGridColumns = (gridName) => ({
  type: types.GET_POST_GRID_COLUMN,
  gridName,
});

export const getPostGridColumnsSuccess = (columns) => {
  return {
    type: types.GET_POST_GRID_COLUMN_SUCCESS,
    columns,
  };
};

export const getPostGridColumnsError = (error) => ({
  type: types.GET_POST_GRID_COLUMN_ERROR,
  error,
});

export function toggleEditCategory() {
  return {
    type: types.TOGGLE_EDIT_CATEGORY,
  };
}

export function toggleCreateCategory() {
  return {
    type: types.TOGGLE_CREATE_CATEGORY,
  };
}

export function closeCreateCategory() {
  return {
    type: types.CLOSE_CREATE_CATEGORY,
  };
}

export function closeEditCategory() {
  return {
    type: types.CLOSE_EDIT_CATEGORY,
  };
}

export function toggleCategoryDetail() {
  return {
    type: types.TOGGLE_CATEGORY_DETAIL,
  };
}

export function getCategoryGridColumn(gridName) {
  return {
    type: types.GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN,
    gridName,
  };
}

export const getCategoryGridColumnsSuccess = (columns) => {
  return {
    type: types.GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_SUCCESS,
    columns,
  };
};

export const getCategoryGridColumnsError = (error) => ({
  type: types.GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_ERROR,
  error,
});

export function getCategoryGrid(params) {
  return {
    type: types.GET_HELP_MAINTENANCE_CATEGORY_GRID,
    params,
  };
}

export const getCategoryGridSuccess = (data) => {
  return {
    type: types.GET_HELP_MAINTENANCE_CATEGORY_GRID_SUCCESS,
    data,
  };
};

export const getCategoryGridError = (error) => ({
  type: types.GET_HELP_MAINTENANCE_CATEGORY_GRID_ERROR,
  error,
});
export function updateIsDetailsView(isDetailsView) {
  return {
    type: types.DISABLED_VIEW_MODE,
    isDetailsView,
  };
}

export function getPostContentDetail(params) {
  return {
    type: types.GET_POST_CONTENT_DETAIL,
    params,
  };
}

export function getPostContentDetailSuccess(data) {
  return {
    type: types.GET_POST_CONTENT_DETAIL_SUCCESS,
    data,
  };
}

export function getPostContentDetailError(error) {
  return {
    type: types.GET_POST_CONTENT_DETAIL_ERROR,
    error,
  };
}

export function changeSelectCategory(data) {
  return {
    type: types.CHANGE_SELECT_CATEGORY,
    data,
  };
}

export function enableEditMode(data) {
  return {
    type: types.ENABLE_EDIT_MODE,
    data,
  };
}

export function setEditCategory(id) {
  return {
    type: types.ENABLE_EDIT_MODE,
    payload: id,
  };
}

export const sumbitCategory = (params) => {
  return {
    type: types.SUBMIT_CATEGORY,
    params,
  };
};

export const sumbitCategorySuccess = () => ({
  type: types.SUBMIT_CATEGORY_SUCCESS,
});

export const sumbitCategoryError = (error) => ({
  type: types.SUBMIT_CATEGORY_ERROR,
  error,
});

export const sumbitCategoryFinish = () => ({
  type: types.SUBMIT_CATEGORY_FINISH,
});
// Help-maintenance post
export const enableCreatePost = () => ({
  type: types.ENABLE_HELP_MAINTENANCE_CREATE_POST,
});

export const enableOpenPost = () => ({
  type: types.ENABLE_HELP_MAINTENANCE_OPEN_POST,
});

export const enableEditPost = () => ({
  type: types.ENABLE_HELP_MAINTENANCE_EDIT_POST,
});

export const disableEditPost = () => ({
  type: types.DISABLE_HELP_MAINTENANCE_EDIT_POST,
});

export const saveHelpPost = (params) => {
  return {
    type: types.SAVE_HELP_MAINTENANCE_POST,
    params,
  };
};

export const saveHelpPostSuccess = (data) => {
  return {
    type: types.SAVE_HELP_MAINTENANCE_POST_SUCCESS,
    data,
  };
};

export const saveHelpPostError = (error) => {
  return {
    type: types.SAVE_HELP_MAINTENANCE_POST_ERROR,
    error,
  };
};

export const savePostDetail = (id) => {
  return {
    type: types.SAVE_POST_DETAIL,
    id,
  };
};

export const savePostDetailSuccess = (data) => {
  return {
    type: types.SAVE_POST_DETAIL_SUCCESS,
    data,
  };
};

export const savePostDetailError = (error) => {
  return {
    type: types.SAVE_HELP_MAINTENANCE_POST_ERROR,
    error,
  };
};

export const resetDataPost = () => {
  return {
    type: types.RESET_DATA_POST,
  };
};

export const confirmChangeHelpPost = () => {
  return {
    type: types.CONFIRM_CHANGE_UPDATE,
  };
};

export const saveIdPost = (id) => {
  return { type: types.SAVE_ID_POST, data: id };
};

//* upload help asset file
export function updateHelpAssetFile(payload) {
  return {
    type: types.UPDATE_HELP_ASSET_FILE,
    payload,
  };
}

export function updateHelpAssetFileSuccess(payload) {
  return {
    type: types.UPDATE_HELP_ASSET_FILE_SUCCESS,
    payload,
  };
}

//* toggle upload media box
export function toggleUploadHelpMedia(status) {
  return {
    type: types.TOGGLE_HELP_UPLOAD_MEDIA,
    status,
  };
}

export function closeCategoryPanel() {
  return {
    type: types.CLOSE_CATEGORY_PANEL,
  };
}

//* get help assets
export function getHelpAssetFile(payload) {
  return {
    type: types.GET_HELP_ASSETS,
    payload,
  };
}

export function getHelpAssetFileSuccess(payload) {
  return {
    type: types.GET_HELP_ASSETS_SUCCESS,
    payload,
  };
}
//* update category panel
export const updateCategoryPanel = (isVisibleCategory) => {
  return {
    type: types.UPDATE_CATEGORY_PANEL,
    isVisibleCategory,
  };
};
