import http from 'assets/download-method/http.png';
import email from 'assets/download-method/email.png';
import interop from 'assets/download-method/interop.png';

// Array of types digital asset map with images
export const DownloadMethodImage = [
  { fileType: 'direct', fileImg: http },
  { fileType: 'email', fileImg: email },
  { fileType: 'ftp', fileImg: interop },
];
