import * as types from './constants';

export function gridMemberColumnInfo(gridName) {
  return {
    type: types.WORKFLOW_TEMPLATE_COLUMN_INFO,
    gridName,
  };
}

export function gridMemberColumnInfoSuccess(columns) {
  return {
    type: types.WORKFLOW_TEMPLATE_COLUMN_INFO_SUCCESS,
    columns,
  };
}

export function gridMemberColumnInfoError(error) {
  return {
    type: types.WORKFLOW_TEMPLATE_COLUMN_INFO_ERROR,
  };
}

export const setWfItemSelected = (items) => {
  return {
    type: types.WORKFLOW_TEMPLATE_ITEMS_SELECTED,
    items,
  };
};

export const reloadGridWf = (isReloadGrid) => {
  return {
    type: types.RELOAD_GRID_WF,
    isReloadGrid,
  };
};
