import './Container.less';

export const SectionContainer = (props) => {
  const { children, innerStyle = {}, containerStyle = {} } = props;

  return (
    <div
      className='search-entity-modal__section-container'
      style={containerStyle}
    >
      <div
        className='search-entity-modal__section-container-inner'
        style={innerStyle}
      >
        {children && children}
      </div>
    </div>
  );
};
