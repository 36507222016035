import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { dialogFunction } from 'common/components';

import {
  createFormulaColDef,
  createFormulaActiveIngredientColDef,
} from '../utils/gridColumnUtils';
import { getAllRows } from '../utils';

import { useAsync } from 'hooks';
import { useSelectProductFullDetailData } from 'pages/qa-spec/hooks';

import { COMPONENTS_ERROR } from 'pages/qa-spec/constant';
import { getDataFormula } from 'services/product';

export const useGetDataFormula = () => {
  const { data, setData, run } = useAsync();
  const { id: productId } = useParams();

  const fetchDataFormula = useCallback(
    (productId) => {
      run(getDataFormula({ productId }));
    },
    [run]
  );

  useEffect(() => {
    if (productId) {
      fetchDataFormula(productId);
    }
    return;
  }, [productId, run, fetchDataFormula]);

  const formatedData = useMemo(() => {
    return {
      ...(data || {}),
      formula: data?.formula?.map((item) => {
        return { ...item, uuid: uuidv4() };
      }),
    };
  }, [JSON.stringify(data)]);

  return {
    data: formatedData,
    setData,
    fetchDataFormula,
  };
};

export const useGetFormulaColDefs = (productEnums, productId, options) => {
  const productDetail = useSelectProductFullDetailData({ productId });

  const formulaColDef = useMemo(() => {
    const { productId, ownerId: productOwnerId } = productDetail;
    return createFormulaColDef({
      productEnums,
      productOwnerId,
      productId,
      options,
    });
  }, [
    JSON.stringify(productEnums),
    JSON.stringify(productDetail),
    JSON.stringify(options),
  ]);

  return formulaColDef;
};

export const useGetFormulaGridHandlers = (gridInst) => {
  const handleAddFormula = () => {
    const newUuid = uuidv4();
    const allRowData = getAllRows(gridInst);
    const newRowIndex = allRowData?.length;

    gridInst.current.api.applyTransaction({
      add: [
        {
          uuid: newUuid,
          salesDesignation: null,
          technicalDesignation: null,
          casNumber: null,
          function: null,
          classificationAndRiskPhrases: null,
          origin: null,
          originSpecification: null,
          country: null,
          percentOfRawMaterialSubstance: null,
          percentOfIncorporatedRawMaterial: null,
          percentOfFinishedProductSubstance: null,
          documents: null,
        },
      ],
      addIndex: newRowIndex,
    });

    gridInst.current.api.ensureIndexVisible(newRowIndex);
    const newRow = gridInst.current.api.getRowNode(newUuid);

    newRow.setSelected(true, true);
  };

  const onDeleteFormula = async () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();

    const removeList = selectedRows.map(
      (selectedRowItem) => selectedRowItem?.data
    );

    gridInst.current.api.applyTransaction({
      remove: removeList,
    });
  };

  const confirmDeleteFormula = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onDeleteFormula,
    });
  };

  const handleDeleteFormula = () => {
    confirmDeleteFormula();
  };

  return {
    handleAddFormula,
    handleDeleteFormula,
  };
};

export const useGetFormulaActiveIngredientColDefs = (options) => {
  const formulaColDef = useMemo(() => {
    return createFormulaActiveIngredientColDef(options);
  }, [JSON.stringify(options)]);

  return formulaColDef;
};

export const useFormulaActiveIngredientHandler = (gridInst) => {
  const handleAddActiveIngredient = () => {
    const newUuid = uuidv4();
    const allRowData = getAllRows(gridInst);
    const newRowIndex = allRowData?.length;

    gridInst.current.api.applyTransaction({
      add: [
        {
          uuid: newUuid,
          ingredientName: null,
          purposeDescriptor: null,
          quantity: null,
          quantityUom: 'g',
          error: ['Ingredient Name is required'],
        },
      ],
      addIndex: newRowIndex,
    });

    gridInst.current.api.ensureIndexVisible(newRowIndex);
    const newRow = gridInst.current.api.getRowNode(newUuid);

    newRow.setSelected(true, true);
  };

  const onDeleteIngredient = async () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();

    const removeList = selectedRows.map(
      (selectedRowItem) => selectedRowItem?.data
    );

    gridInst.current.api.applyTransaction({
      remove: removeList,
    });
  };

  const confirmDeleteIngredient = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onDeleteIngredient,
    });
  };

  const handleDeleteIngredient = () => {
    confirmDeleteIngredient();
  };

  return {
    handleAddActiveIngredient,
    handleDeleteIngredient,
  };
};
