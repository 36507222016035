import React from 'react';

import { useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import ManageSharing from '../../controls/shared/ManageSharing';
import RemoveSharing from '../../controls/shared/RemoveSharing';

import gridSelectors from 'common/components/grid-view/controllers/selectors';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const ManageShare = () => {
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const checkSectionDisable = () => {
    const hasItemSelected = selectedItemList.length > 0;
    return !hasItemSelected;
  };

  const isSectionDisabled = checkSectionDisable();
  return (
    <ContainerButton style={{ marginLeft: 5 }}>
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.SHARED_ENTITY}>
        <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.SHARED_ENTITY}>
          <ItemButton>
            <ManageSharing isSectionDisabled={isSectionDisabled} />
          </ItemButton>
        </Can>
        <ItemButton>
          <RemoveSharing
            isSectionDisabled={isSectionDisabled}
            selectedItemList={selectedItemList}
          />
        </ItemButton>
      </Can>
    </ContainerButton>
  );
};

export default ManageShare;
