import { useQueryDataProductSpec } from 'pages/qa-spec/hooks/useProductSpecInfo';

import { isEmpty, get, set, isArray } from 'lodash';

import { useGetQaPackagingTabData } from '../qa-packaging/queries';
import { useGetQaSpecDataContact } from 'pages/qa-spec/components/qa-contact/queries';
import { useGetIngredients } from '../qa-ingredient/hook';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import {
  useGetOrganolepticQuery,
  useGetQaSpecDataHeader,
  useContaminantCharacteristicsQuery,
  useGetPhysicalChemicalQuery,
  useGetMicrobiologicalCharacteristicsQuery,
} from 'pages/qa-spec/query';

import { filterRequiredFieldsGroupData } from './utils';
import { useGetAllProductFields } from 'hooks/qas-require-field/useQasRequireField';

import { useGetQaProductContainTabData } from '../qa-product-contain/queries';

const ORGANOLEPTIC_PARAMETER_REQUIRED_FIELD_MAP = {
  'Aroma/Odor': 'Odor',
  'Flavor/Taste': 'Taste',
};

export const AT_LEAST_ONE_ENTRY_GROUPS = [
  'Physical & Chemical Characteristics',
  'Contaminant Characteristics',
  'Microbiological Characteristics',
];

export const getInvalidRequiredFields = ({ data, fields }) => {
  if (!fields) return {};
  if (isEmpty(data)) return fields;

  let result = {};

  if (fields?.length > 0) {
    for (const field of fields) {
      const fieldNameArray = field?.fieldFullPath?.split('.');
      const fieldName = fieldNameArray[fieldNameArray?.length - 1];
      const valueField = data[fieldName];
      const isNumberType = typeof valueField === 'number';
      const isNullOrUndefinedValue =
        valueField === null || valueField === undefined;

      if (Array.isArray(valueField) && valueField?.length === 0) {
        result = {
          ...result,
          [field]: true,
        };
      } else if (isNullOrUndefinedValue && isNumberType) {
        result = {
          ...result,
          [field]: true,
        };
      } else if (!valueField && !isNumberType) {
        result = {
          ...result,
          [field]: true,
        };
      } else {
        result = {
          ...result,
          [field]: false,
        };
      }
    }
  }

  return result;
};

export const useRequiredFieldsValidation = (productId) => {
  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  const { data: productFieldGroups } = useGetAllProductFields({
    enabled: isProductAssignedByRetailer,
  });
  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
    enabled: isProductAssignedByRetailer,
  });

  const { dataProductSpec } = useQueryDataProductSpec({
    id: productId,
    enabled: isProductAssignedByRetailer,
  });

  const { qaSpecDataHeader } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: Boolean(productId) && isProductAssignedByRetailer,
  });

  const { organolepticData } = useGetOrganolepticQuery({
    productId,
    enabled: !!productId && isProductAssignedByRetailer,
  });

  const { contaminantCharacteristicsData } = useContaminantCharacteristicsQuery(
    {
      productId,
      enabled: !!productId && isProductAssignedByRetailer,
    }
  );

  const { physicalChemicalData } = useGetPhysicalChemicalQuery({
    productId,
    enabled: !!productId && isProductAssignedByRetailer,
  });
  const { microbiologicalCharacteristicData } =
    useGetMicrobiologicalCharacteristicsQuery({
      productId,
      enabled: !!productId && isProductAssignedByRetailer,
    });

  const { data: dataQaIngredient } = useGetIngredients(productId, {
    enabled: isProductAssignedByRetailer,
  });

  const { data: dataQaPackaging } = useGetQaPackagingTabData(
    {
      productId,
    },
    {
      enabled: !!productId && isProductAssignedByRetailer,
      retry: false,
    }
  );

  const { qaSpecDataContact } = useGetQaSpecDataContact({
    id: productId,
    isEnabled: !!productId && isProductAssignedByRetailer,
  });

  const { data: productContainsData } = useGetQaProductContainTabData(
    {
      productId,
    },
    { enabled: !!productId && isProductAssignedByRetailer }
  );

  const isNumber = (value) => {
    return typeof value === 'number';
  };

  const validateObjectTypeData = (data) => {
    if (!data) return {};

    return Object.keys(data).reduce((accumulator, key) => {
      const value = data[key];
      if (isNumber(value)) accumulator[key] = true;
      else accumulator[key] = isArray(value) ? !!value?.length : !!value;

      return accumulator;
    }, {});
  };

  const validateListTypeData = (dataList) => {
    if (!dataList?.length) return {};

    const dataItem = dataList[0];

    const data = {};

    Object.keys(dataItem).forEach((fieldName) => {
      const value = dataList.every((rowData) => {
        const fieldValue = rowData[fieldName];
        if (isArray(fieldValue)) return fieldValue.length > 0;

        return !!rowData[fieldName];
      });

      set(data, [fieldName], value);
    });

    return data;
  };

  const validateOrganolepticData = () => {
    if (!organolepticData?.organolepticCharacteristics) return {};

    return organolepticData?.organolepticCharacteristics?.reduce(
      (accumulator, dataItem) => {
        Object.keys(dataItem).forEach((fieldName) => {
          const value = !!dataItem[fieldName];
          const parameter =
            ORGANOLEPTIC_PARAMETER_REQUIRED_FIELD_MAP[dataItem.parameter] ||
            dataItem.parameter;

          set(accumulator, [fieldName, parameter], value);
        });
        return accumulator;
      },
      {}
    );
  };

  const checkValidRequiredField = ({ field }) => {
    if (!validatedData) return false;

    const value = get(validatedData, field?.fieldFullPath);

    if (isArray(value)) return !!value?.length;

    return !!value;
  };

  const checkValidAllFields = (groups) => {
    return groups.every((groupItem) => {
      const isAllFieldValid = groupItem?.fields?.every((fieldItem) =>
        checkValidRequiredField({ field: fieldItem })
      );

      const isAllChildGroupValid =
        groupItem?.childGroups?.length > 0
          ? checkValidAllFields(groupItem?.childGroups)
          : true;

      return isAllFieldValid && isAllChildGroupValid;
    });
  };

  const validatedData = {
    qaSpecification: {
      ...validateObjectTypeData(qaSpecDataHeader),
      qaSpecProductSpecification: {
        ...validateObjectTypeData(dataProductSpec),
        qaSpecProductSpecsOrganolepticCharacteristics:
          validateOrganolepticData(),
        qaSpecProductSpecsPhysicalAndChemicalCharacteristics:
          validateListTypeData(
            physicalChemicalData?.physicalAndChemicalCharacteristics
          ),
        qaSpecProductSpecsContaminantCharacteristics: validateListTypeData(
          contaminantCharacteristicsData?.contaminantCharacteristics
        ),
        qaSpecProductSpecsMicrobiologicalCharacteristics: validateListTypeData(
          microbiologicalCharacteristicData?.microbiologicalCharacteristics
        ),
      },
      qaSpecIngredients: {
        ingredientStatement: dataQaIngredient?.ingredientStatements?.length > 0,
        ...validateListTypeData(dataQaIngredient?.ingredients),
      },
      qaSpecContacts: {
        primaryBusinessContact: validateObjectTypeData(
          qaSpecDataContact?.primaryBusiness
        ),
        primaryQcContact: validateObjectTypeData(qaSpecDataContact?.primaryQc),
        _24HourContact: validateObjectTypeData(qaSpecDataContact?._24Hour),
      },
      labeling: validateObjectTypeData(dataQaPackaging),
      qaSpecProductContains: validateObjectTypeData(productContainsData),
    },
  };

  const groupRequiredFields = filterRequiredFieldsGroupData({
    groupsData: productFieldGroups,
    requiredFields,
  });

  const isAllFieldsValid = checkValidAllFields(groupRequiredFields);

  return { isAllFieldsValid, groupRequiredFields, checkValidRequiredField };
};
