import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { oneOfType } from 'prop-types';
import {
  Row,
  Col,
  Radio,
  Select,
  Typography,
  Divider,
  Collapse,
  Checkbox,
  Button,
  notification,
  Tooltip,
  InputNumber,
} from 'antd';
// import digitalAssetIcon from 'assets/asset-icons/DigitalAssets.png';
// import memberIcon from 'assets/asset-icons/Member.png';
// import productIcon from 'assets/asset-icons/Product.png';
// import queryIcon from 'assets/asset-icons/Queries.png';
// import reportIcon from 'assets/asset-icons/Queries.png';
import {
  // DashboardOutlined,
  ClearOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Form, TypePicker, EmailSelection } from 'common/components';
import {
  FOLDER_DOWNLOAD_TYPE,
  FOLDER_DELIVERY_METHOD,
  IMAGE_FORMAT_OPTIONS,
  IMAGE_SIZE_OPTIONS,
  IMAGE_SIZE_OPTIONS_SINGLE_DIGIT,
  CROP_SIZE_OPTIONS,
  RESOLUTION_OPTIONS,
  BACKGROUND_OPTIONS,
  PRODUCTS_DATA_FORMAT,
  PRODUCTS_DATA_FORMAT_WITH_GDSN,
  QUERIES_DATA_FORMAT,
  REPORTS_DATA_FORMAT,
  PLANOGRAM_TYPE,
  PRODUCT_CONFIG,
  INFORMATION_SHEET_OPTIONS,
} from 'static/Constants';
import { folderDownload } from 'services/folder';
import { FOLDER_DOWNLOAD } from 'services/folder/endpoints';
import userSelectors from 'redux/user/selectors';
import * as api from 'config/axios';
import { injectIntl } from 'react-intl';
import * as actionsMember from 'pages/branded-members/controllers/actions';
import * as memberSelectors from 'pages/branded-members/controllers/selectors';
import * as companySelectors from 'pages/company-profile/controllers/selectors';
import * as memberActions from 'pages/company-profile/controllers/actions';

import { WrapperSelect } from 'common/components';

import * as folderSelectors from '../../controllers/selectors';
import * as folderActions from '../../controllers/actions';

import { ConfigProductDownload } from './ConfigDownload';

import Messages from 'i18n/messages/folder';

import {
  useSetIndeterminateCheckbox,
  checkContainsSomeTrueValue,
  checkContainsAllTrueValue,
  checkOneOfThemHasTrueValue,
  initProductConfigValue,
} from './utils';

import { deleteObjectField } from 'utils';

import * as _ from 'lodash';

import './DownloadConfiguration.less';

const { Option } = Select;
const { Panel } = Collapse;
const { Text } = Typography;

// Form Layouts
const formFormatLayout = { labelCol: { span: 12 } };
const formLayout = { labelCol: { span: 5 }, wrapperCol: { span: 10 } };
const formCheckboxLayout = { labelCol: { span: 16 }, wrapperCol: { span: 8 } };

const addButtonStyle = {
  flex: 'none',
  padding: '8px',
  display: 'block',
  cursor: 'pointer',
};

const customSetupStyle = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  padding: 8,
};

const defaultConfig = {
  productUnit: false,
  productUnitPlanogram: false,
  productUnitMarketing: false,
  productUnitAdditionInformation: false,
  productDisplayShipper: false,
  productDisplayShipperPlanogram: false,
  productDisplayShipperMarketing: false,
  productDisplayShipperAdditionInformation: false,
  productInnerPack: false,
  productInnerPackPlanogram: false,
  productInnerPackMarketing: false,
  productInnerPackAdditionInformation: false,
  productCase: false,
  productCasePlanogram: false,
  productCaseMarketing: false,
  productCaseAdditionInformation: false,
  productMasterCase: false,
  productMasterCasePlanogram: false,
  productMasterCaseMarketing: false,
  productMasterCaseAdditionInformation: false,
  productPallet: false,
  productPalletCasePlanogram: false,
  productPalletCaseMarketing: false,
  productPalletCaseAdditionInformation: false,
};

const DownloadConfiguration = (props) => {
  const {
    downloadSetting,
    id,
    setLoading,
    onSetType,
    onSetMethod,
    intl,
    continueSchedule,
    downloadEntityAmount,
    memberOwnerId,
    formId,
  } = props;

  const formName = formId || 'folder';

  const [form] = Form.useForm();
  const [visibleEmailSelection, setVisibleEmailSelection] = useState(false);
  const [formFieldsValue, setFormFieldsValue] = useState({});
  const [resolutionOptions, setResolution] = useState(RESOLUTION_OPTIONS);
  const [imageSizeOptions, setImageSize] = useState(IMAGE_SIZE_OPTIONS);
  const [customResolution, setCustomResolution] = useState('');
  const [customImageSize, setCustomImageSize] = useState('');

  const [indeterminate, setIndeterminate] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {}
  );

  const [assetResolutionOptions, setAssetResolution] =
    useState(RESOLUTION_OPTIONS);
  const [assetImageSizeOptions, setAssetImageSize] =
    useState(IMAGE_SIZE_OPTIONS);
  const [customAssetResolution, setCustomAssetResolution] = useState('');
  const [customAssetImageSize, setCustomAssetImageSize] = useState('');

  const dispatch = useDispatch();

  const alternativeEmail = useSelector(
    userSelectors.makeSelectUserAlternativeEmail()
  );
  const selectedMapping = useSelector(folderSelectors.selectSelectedMapping());

  const primaryEmail = useSelector(userSelectors.makeSelectUserEmail());
  const memberData = useSelector(memberSelectors.makeSelectMemberData());
  const memberInfo = useSelector(companySelectors.selectMemberProfile());
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  useEffect(() => {
    if (_.isEmpty(memberInfo)) {
      dispatch(memberActions.getMemberProfileHeader(userInfo?.member?.id));
    }
  }, [dispatch, userInfo?.member?.id]);

  useSetIndeterminateCheckbox(downloadSetting, setIndeterminate);
  // useCheckSelectMapping();

  const dropdownConfigs = [
    {
      name: 'productImageNamingFormat',
      options: PLANOGRAM_TYPE,
    },
    {
      name: 'productImageFormat',
      options: IMAGE_FORMAT_OPTIONS,
    },
    {
      name: 'productImageSize',
      options: IMAGE_SIZE_OPTIONS,
    },
    {
      name: 'productCropSize',
      options: CROP_SIZE_OPTIONS,
    },
    {
      name: 'productResolution',
      options: RESOLUTION_OPTIONS,
    },
    {
      name: 'productBackground',
      options: BACKGROUND_OPTIONS,
    },
  ];

  // Configuration Data
  // const productData = [
  //   {
  //     name: 'productDataFormat',
  //     option:
  //       memberData?.gln?.toLowerCase() === 'gln'
  //         ? PRODUCTS_DATA_FORMAT_WITH_GDSN
  //         : PRODUCTS_DATA_FORMAT,
  //   },
  //   { name: '', option: [] },
  //   { name: 'productPlanogramType', option: PLANOGRAM_TYPE },
  //   { name: 'productImageFormat', option: IMAGE_FORMAT_OPTIONS },
  //   { name: 'productImageSize', option: IMAGE_SIZE_OPTIONS },
  //   { name: 'productCropSize', option: CROP_SIZE_OPTIONS },
  //   { name: 'productResolution', option: RESOLUTION_OPTIONS },
  //   { name: 'productBackground', option: BACKGROUND_OPTIONS },
  // ];
  const digitalAssetData = [
    {
      name: 'digitalAssetImageFormat',
      option: IMAGE_FORMAT_OPTIONS.map((item) => item?.toUpperCase()),
    },
    { name: 'digitalAssetImageSize', option: IMAGE_SIZE_OPTIONS },
    { name: 'digitalAssetResolution', option: RESOLUTION_OPTIONS },
  ];
  const resetData = () => {
    form.setFieldsValue({
      downloadType: downloadSetting?.downloadType,
      deliveryMethod: downloadSetting?.deliveryMethod,
      productDataFormat: downloadSetting?.productDataFormat,
      productImageNamingFormat: downloadSetting?.productImageNamingFormat,
      productImageFormat: downloadSetting?.productImageFormat?.toUpperCase(),
      productImageSize: downloadSetting?.productImageSize,
      productCropSize: downloadSetting?.productCropSize ?? null,
      productResolution: downloadSetting?.productResolution ?? null,
      productBackground: downloadSetting?.productBackground ?? 'Original',
      digitalAssetImageFormat:
        downloadSetting?.digitalAssetImageFormat === null
          ? 'ORIGINAL'
          : downloadSetting?.digitalAssetImageFormat?.toUpperCase(),
      digitalAssetImageSize: downloadSetting?.digitalAssetImageSize,
      digitalAssetResolution: downloadSetting?.digitalAssetResolution ?? null,
      queryDataFormat: downloadSetting?.queryDataFormat?.toUpperCase(),
      memberDefinedSheets:
        downloadSetting?.memberDefinedSheets || 'RIVIR Default',
      keepFolderStructure: true,
      includeSubfolders: true,
    });

    if (!downloadSetting) return;

    initProductConfigValue(downloadSetting, form, onChangeBotCb);

    onSetType && onSetType(form.getFieldValue('downloadType'));
    onSetMethod && onSetMethod(form.getFieldValue('deliveryMethod'));
  };

  const onOptionChange = (type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'resolution':
        setCustomResolution(value);
        break;
      case 'imageSize':
        setCustomImageSize(value);
        break;
      case 'assetResolution':
        setCustomAssetResolution(value);
        break;
      case 'assetImageSize':
        setCustomAssetImageSize(value);
        break;
    }
  };

  // Product Resolution
  const addResolutionItem = () => {
    if (!customResolution)
      openNotification(
        'error',
        intl.formatMessage(Messages.resolutionCustomize),
        intl.formatMessage(Messages.resolutionMissing)
      );
    else if (resolutionOptions.includes(customResolution))
      openNotification(
        'error',
        intl.formatMessage(Messages.resolutionCustomize),
        intl.formatMessage(Messages.resolutionDuplicated)
      );
    else setResolution([...resolutionOptions, customResolution]);
  };

  // Asset Resolution
  const addAssetResolutionItem = () => {
    if (!customAssetResolution)
      openNotification(
        'error',
        intl.formatMessage(Messages.resolutionCustomize),
        intl.formatMessage(Messages.resolutionMissing)
      );
    else if (assetResolutionOptions.includes(customAssetResolution))
      openNotification(
        'error',
        intl.formatMessage(Messages.resolutionCustomize),
        intl.formatMessage(Messages.resolutionDuplicated)
      );
    else setAssetResolution([...assetResolutionOptions, customAssetResolution]);
  };

  const imageSizeCustomRender = (value) => {
    if (IMAGE_SIZE_OPTIONS.includes(value)) return value;
    else return `${value}x${value}`;
  };

  // Product Image Size
  const addImageSizeItem = () => {
    if (!customImageSize)
      openNotification(
        'error',
        intl.formatMessage(Messages.imageSizeCustomize),
        intl.formatMessage(Messages.imageSizeMissing)
      );
    else if (
      imageSizeOptions.includes(customImageSize) ||
      IMAGE_SIZE_OPTIONS_SINGLE_DIGIT.includes(customImageSize)
    )
      openNotification(
        'error',
        intl.formatMessage(Messages.imageSizeCustomize),
        intl.formatMessage(Messages.imageSizeDuplicated)
      );
    else setImageSize([...imageSizeOptions, customImageSize]);
  };

  // Asset Image Size
  const addAssetImageSizeItem = () => {
    if (!customAssetImageSize)
      openNotification(
        'error',
        intl.formatMessage(Messages.imageSizeCustomize),
        intl.formatMessage(Messages.imageSizeMissing)
      );
    else if (
      assetImageSizeOptions.includes(customAssetImageSize) ||
      IMAGE_SIZE_OPTIONS_SINGLE_DIGIT.includes(customAssetImageSize)
    )
      openNotification(
        'error',
        intl.formatMessage(Messages.imageSizeCustomize),
        intl.formatMessage(Messages.imageSizeDuplicated)
      );
    else setAssetImageSize([...assetImageSizeOptions, customAssetImageSize]);
  };

  const openNotification = (result, message, description, key, close) => {
    let type;
    switch (result) {
      case true:
        type = 'success';
        break;
      case false:
        type = 'error';
        break;
      default:
        type = result;
        break;
    }
    notification[type]({
      message: message,
      description: description,
      duration: type === 'warning' ? 0 : 4.5,
      key: key,
      onClose: close,
    });
  };

  useEffect(() => {
    resetData();
  }, [downloadSetting]);

  useEffect(() => {
    memberOwnerId &&
      dispatch(actionsMember.getMemberShortDetail(memberOwnerId));
  }, [dispatch, memberOwnerId]);

  const openNotify = (result, description) => {
    notification[result ? 'success' : 'error']({
      message: 'Folder Download',
      description: description,
    });
  };

  async function onFolderDownload(values) {
    dispatch(folderActions.saveStatusDownloadFolder('loading'));

    const mappingId = selectedMapping?.id ? selectedMapping?.id : 0;
    const newFormValues = {
      ...values,
    };

    // if (downloadEntityAmount?.product && mappingId === null) return;

    if (downloadEntityAmount?.member && values.memberDefinedSheets === null) {
      values = { ...values, memberDefinedSheets: 'RIVIR Default' };
    }

    setLoading(true);
    if (values.deliveryMethod === 'direct') {
      await api
        .requestToDownload({
          method: 'POST',
          apiEndpoint: FOLDER_DOWNLOAD,
          payload: {
            folderId: id,
            mappingId,
            ...newFormValues,
          },
          options: { timeout: 1800000 },
        })
        .then((response) => {
          openNotify(response?.isSuccess, response?.message);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((e) => {
          console.log('e', e);
        });
    } else if (values.deliveryMethod === 'email') {
      setFormFieldsValue(newFormValues);
      openEmailSelection();
    } else {
      await folderDownload({
        folderId: id,
        ...newFormValues,
      }).then((response) => {
        openNotify(response.isSuccess, response.message);
      });
    }
    setLoading(false);
    dispatch(folderActions.saveStatusDownloadFolder('idle'));
  }

  const openEmailSelection = () => {
    setVisibleEmailSelection(true);
  };

  const onFolderDownloadByEmail = async (emails) => {
    // todo
    const mappingId = selectedMapping?.id ? selectedMapping?.id : null;

    const newFormValues = {
      ...formFieldsValue,
    };

    await folderDownload({
      folderId: id,
      mappingId,
      ...newFormValues,
      emails: emails,
    }).then((response) => {
      openNotify(response.isSuccess, response.message);
    });
  };

  const onSendMultiEmail = async (emails) => {
    setLoading(true);
    setVisibleEmailSelection(false);
    await onFolderDownloadByEmail(emails);
    setFormFieldsValue({});
    setLoading(false);
  };

  const onFinish = (values) => {
    PRODUCT_CONFIG.forEach((item) => {
      delete values[item.name];
      for (const [key, child] of item.option?.entries()) {
        if (!values[child.name]) {
          return;
        }
        delete values[child.name]['value'];
        values[child.name] = Object.keys(values[child.name]).filter((key) => {
          return values[child.name][key];
        });
      }
    });

    if (!values.downloadType && !values.deliveryMethod)
      openNotify(
        false,
        intl.formatMessage({
          id: 'Taco.folder.component.download.missing',
        })
      );
    else if (!values.downloadType)
      openNotify(
        false,
        intl.formatMessage({
          id: 'Taco.folder.component.download.downloadType.missing',
        })
      );
    else if (!values.deliveryMethod)
      openNotify(
        false,
        intl.formatMessage({
          id: 'Taco.folder.component.download.deliveryMethod.missing',
        })
      );
    else onFolderDownload(values);
  };

  const onChangeTopCb = (item) => {
    const data = form.getFieldValue(item.name);
    for (const [key, child] of item.option?.entries()) {
      form.setFieldsValue({ [child.name]: { value: data } });
      for (const [key, grandChild] of child.option?.entries()) {
        form.setFieldsValue({ [child.name]: { [grandChild]: data } });
      }
    }

    handleIndeterminationTopValue(item, data);
  };

  const onChangeMidCb = (item, parent) => {
    const data = form.getFieldValue([item.name, 'value']);
    let signal = data;
    for (const [key, child] of parent.option?.entries()) {
      signal = signal && form.getFieldValue([child.name, 'value']);
      form.setFieldsValue({ [parent.name]: signal });
      for (const [key, child] of item.option?.entries()) {
        form.setFieldsValue({ [item.name]: { [child]: data ? true : false } });
      }
    }

    handleIndeterminationMidValue(parent, form, item);
  };

  const onChangeBotCb = (item, parent, grandParent) => {
    handleIndeterminationBotValue(parent, form, grandParent);
    const data = form.getFieldValue([parent.name, item]);
    let signal = data;
    for (const [key, child] of parent.option?.entries()) {
      signal = signal && form.getFieldValue([parent.name, child]);
      form.setFieldsValue({ [parent.name]: { value: signal } });
    }
    for (const [key, child] of grandParent.option?.entries()) {
      signal = signal && form.getFieldValue([child.name, 'value']);
      form.setFieldsValue({ [grandParent.name]: signal });
    }
  };

  const handleIndeterminationTopValue = (item, data, form) => {
    const nameItem = item.name;
    const parentIndeterminateConfig = item?.option.reduce(
      (nextParentIndeterminateConfig, parent) => {
        nextParentIndeterminateConfig[parent?.name] = false;
        return nextParentIndeterminateConfig;
      },
      {}
    );

    setIndeterminate({ [nameItem]: false, ...parentIndeterminateConfig });
  };

  const handleIndeterminationMidValue = (parent, form, item) => {
    const parentName = parent.name;
    const itemName = item.name;
    const childNames = parent.option.map((option) => option.name);
    const haveCheckAllTrueValues = checkContainsAllTrueValue(childNames, form);
    const haveSomeTrueValues = checkContainsSomeTrueValue(childNames, form);

    if (haveCheckAllTrueValues) {
      setIndeterminate({ [parentName]: false, [itemName]: false });
      return;
    }

    if (haveSomeTrueValues) {
      setIndeterminate({ [parentName]: true, [itemName]: false });
      return;
    }

    setIndeterminate({ [parentName]: false });
  };

  const handleIndeterminationBotValue = (parent, form, grandParent) => {
    const nameParent = parent.name;
    const nameGrandParent = grandParent.name;
    let grandParentAllValues = grandParent.option.map(
      (grandParentOptionItem) => {
        let parentValues = form.getFieldValue(grandParentOptionItem?.name);
        return deleteObjectField(parentValues, ['value'], true);
      }
    );

    const isAllChildInGrandParentTrue = grandParentAllValues.reduce(
      (isAllChildTrue, parentValues) => {
        return (
          isAllChildTrue *
          Object.values(parentValues).every((value) => value === true)
        );
      },
      true
    );

    const isAllChildInGrandParentFalse = grandParentAllValues.reduce(
      (isAllChildFalse, parentValues) => {
        return (
          isAllChildFalse *
          Object.values(parentValues).every((value) => value === false)
        );
      },
      true
    );

    let valueParent = form.getFieldValue()[`${nameParent}`];
    const valueChild = Object.values(
      deleteObjectField(valueParent, ['value'], true)
    );
    const hasTrueValue = valueChild.includes(true);
    const haveAllTrueValue = valueChild.every((value) => value === true);

    if (haveAllTrueValue) {
      setIndeterminate({ [nameParent]: false, [nameGrandParent]: true });
    }

    if (isAllChildInGrandParentTrue) {
      setIndeterminate({ [nameGrandParent]: false });
      return;
    }

    if (isAllChildInGrandParentFalse) {
      setIndeterminate({ [nameGrandParent]: false });
    }

    if (hasTrueValue && !haveAllTrueValue) {
      const childNames = grandParent.option.map((child) => child.name);

      const isOneChildHaveTrueValue = checkOneOfThemHasTrueValue(
        childNames,
        form
      );

      if (isOneChildHaveTrueValue) {
        setIndeterminate({ [nameParent]: true, [nameGrandParent]: true });
      } else {
        setIndeterminate({ [nameParent]: true, [nameGrandParent]: false });
      }
      return;
    }

    setIndeterminate({ [nameParent]: false });
  };

  // Header Render
  const headerRender = (title) => {
    return (
      <>
        <Text className='download-configuration__config-header'>{title}</Text>
      </>
    );
  };

  // Checkbox Header Render
  const cbHeaderRender = (item, child, grandChild) => {
    if (grandChild) {
      return (
        <Form.Item name={[child.name, grandChild]} valuePropName='checked'>
          <Checkbox onChange={() => onChangeBotCb(grandChild, child, item)}>
            {grandChild}
          </Checkbox>
        </Form.Item>
      );
    } else if (child) {
      return (
        <Form.Item
          name={[child.name, 'value']}
          valuePropName='checked'
          onClick={(event) => event.stopPropagation()}
        >
          <Checkbox
            onChange={() => onChangeMidCb(child, item)}
            indeterminate={indeterminate[`${child.name}`]}
          >
            {intl.formatMessage({
              id: `Taco.folder.download.configuration.${child.name}`,
            })}
          </Checkbox>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={item.name}
          valuePropName='checked'
          onClick={(event) => event.stopPropagation()}
        >
          <Checkbox
            onChange={() => onChangeTopCb(item)}
            indeterminate={indeterminate[`${item.name}`]}
          >
            {intl.formatMessage({
              id: `Taco.folder.download.configuration.${item.name}`,
            })}
          </Checkbox>
        </Form.Item>
      );
    }
  };

  const selectOptionRender = (type, data) => {
    switch (type) {
      case 'productImageSize':
        return imageSizeOptions;
      case 'productResolution':
        return resolutionOptions;
      case 'digitalAssetImageSize':
        return assetImageSizeOptions;
      case 'digitalAssetResolution':
        return assetResolutionOptions;
      default:
        return data;
    }
  };

  const selectConfigRender = (item, data) => {
    switch (item) {
      case 'productImageSize':
        return (
          <div>
            {data}
            <Divider style={{ margin: '4px 0' }} />
            <div style={customSetupStyle}>
              <div>
                <InputNumber
                  style={{ width: '100%', marginBottom: 5 }}
                  min={10}
                  max={2500}
                  onChange={(e) => onOptionChange('imageSize', e)}
                />
              </div>
              <a style={addButtonStyle} onClick={addImageSizeItem}>
                <PlusOutlined />
              </a>
            </div>
          </div>
        );

      case 'productResolution':
        return (
          <div>
            {data}
            <Divider style={{ margin: '4px 0' }} />
            <div style={customSetupStyle}>
              <InputNumber
                style={{ flex: 'auto' }}
                min={72}
                max={1200}
                onChange={(e) => onOptionChange('resolution', e, '')}
              />
              <a style={addButtonStyle} onClick={addResolutionItem}>
                <PlusOutlined />
              </a>
            </div>
          </div>
        );

      case 'digitalAssetImageSize':
        return (
          <div>
            {data}
            <Divider style={{ margin: '4px 0' }} />
            <div style={customSetupStyle}>
              <div>
                <InputNumber
                  style={{ width: '100%', marginBottom: 5 }}
                  min={10}
                  max={2500}
                  onChange={(e) => onOptionChange('assetImageSize', e)}
                />
              </div>
              <a style={addButtonStyle} onClick={addAssetImageSizeItem}>
                <PlusOutlined />
              </a>
            </div>
          </div>
        );

      case 'digitalAssetResolution':
        return (
          <div>
            {data}
            <Divider style={{ margin: '4px 0' }} />
            <div style={customSetupStyle}>
              <InputNumber
                style={{ flex: 'auto' }}
                min={72}
                max={1200}
                onChange={(e) => onOptionChange('assetResolution', e, '')}
              />
              <a style={addButtonStyle} onClick={addAssetResolutionItem}>
                <PlusOutlined />
              </a>
            </div>
          </div>
        );

      default:
        return data;
    }
  };

  // Product Config Render
  // const productConfigRender = (data) => {
  //   return (
  //     <Row className='download-configuration__item' gutter={8}>
  //       {data?.map((item) => {
  //         return (
  //           <Col span={12} key={item.name}>
  //             {item.name && (
  //               <Tooltip
  //                 title={
  //                   item.name === 'productBackground'
  //                     ? PRODUCT_BACKGROUND_TOOLTIP
  //                     : item.name === 'productCropSize' &&
  //                       PRODUCT_CROPSIZE_TOOLTIP
  //                 }
  //                 getPopupContainer={() => document.getElementById('folder')}
  //               >
  //                 <Form.Item
  //                   {...formProductLayout}
  //                   name={item.name}
  //                   label={intl.formatMessage({
  //                     id: `Taco.folder.download.configuration.${item.name}`,
  //                   })}
  //                 >
  //                   <WrapperSelect
  //                     getPopupContainer={() =>
  //                       document.getElementById('folder')
  //                     }
  //                     dropdownRender={(data) =>
  //                       selectConfigRender(item.name, data)
  //                     }
  //                     listHeight={145}
  //                   >
  //                     {selectOptionRender(item.name, item.option).map(
  //                       (option, index) => {
  //                         return (
  //                           <Option key={index} value={option}>
  //                             {item.name === 'productImageSize'
  //                               ? imageSizeCustomRender(option)
  //                               : option}
  //                           </Option>
  //                         );
  //                       }
  //                     )}
  //                   </WrapperSelect>
  //                 </Form.Item>
  //               </Tooltip>
  //             )}
  //           </Col>
  //         );
  //       })}
  //     </Row>
  //   );
  // };

  // Digital Asset Config Render
  const digitalAssetConfigRender = (item, index) => {
    if (item.name) {
      return (
        <Form.Item
          {...formLayout}
          name={item.name}
          label={intl.formatMessage({
            id: `Taco.folder.download.configuration.${item.name}`,
          })}
          key={index}
        >
          <WrapperSelect
            getPopupContainer={() => document.getElementById(formName)}
            dropdownRender={(data) => selectConfigRender(item.name, data)}
            listHeight={110}
          >
            {selectOptionRender(item.name, item.option).map((option, index) => {
              const isImageFormat =
                item.name === 'digitalAssetImageFormat' ||
                'digitalAssetImageFormat';
              const isOrigin = option === 'ORIGINAL';
              const shouldNull = isImageFormat && isOrigin;
              const optionValue = shouldNull ? null : option;
              return (
                <Option key={index} value={optionValue}>
                  {item.name === 'digitalAssetImageSize'
                    ? imageSizeCustomRender(option)
                    : item.name === 'digitalAssetResolution'
                    ? option === null
                      ? 'Original'
                      : option
                    : option}
                </Option>
              );
            })}
          </WrapperSelect>
        </Form.Item>
      );
    }
  };

  // Checkbox Group Render
  const checkboxGroupRender = (data) => {
    return (
      <Row className='download-configuration__item' gutter={8}>
        <Col span={24} offset={1}>
          <Collapse
            className='download-configuration__config download-configuration__config--checkbox'
            expandIconPosition='right'
            ghost
          >
            {data?.map((item) => {
              return (
                <Panel
                  header={cbHeaderRender(item)}
                  key={item.name}
                  forceRender
                >
                  {item.option?.map((child) => {
                    return (
                      <Collapse
                        className='download-configuration__config'
                        expandIconPosition='right'
                        ghost
                        key={`checkbox-${item}-${child.name}`}
                        style={{ marginLeft: 20 }}
                      >
                        <Panel
                          header={cbHeaderRender(item, child)}
                          key={`${item}-${child.name}`}
                          forceRender
                        >
                          <Row>
                            {child.option?.map((grandChild, index) => {
                              return (
                                <Col
                                  span={9}
                                  offset={index % 2 === 0 ? 5 : 0}
                                  key={`checkbox-${item}-${child.name}-${grandChild}`}
                                >
                                  {cbHeaderRender(item, child, grandChild)}
                                </Col>
                              );
                            })}
                          </Row>
                        </Panel>
                      </Collapse>
                    );
                  })}
                </Panel>
              );
            })}
          </Collapse>
        </Col>
      </Row>
    );
  };

  const clearIndeterminate = () => {
    setIndeterminate(defaultConfig);
  };

  const onClear = () => {
    PRODUCT_CONFIG.forEach((item) => {
      for (const [, child] of item.option?.entries()) {
        form.setFieldsValue({
          [child.name]: false,
        });
        for (const [, grandChild] of child.option?.entries()) {
          form.setFieldsValue({
            [child.name]: {
              [grandChild]: false,
            },
          });
        }
      }
      form.setFieldsValue({ [item.name]: false });
    });

    form.setFieldsValue({
      downloadType: null,
      deliveryMethod: null,
      productImageNamingFormat: null,
      productImageFormat: null,
      productImageSize: null,
      productCropSize: 0,
      productResolution: null,
      productBackground: null,
    });

    clearIndeterminate();
    onSetType && onSetType(form.getFieldValue('downloadType'));
    onSetMethod && onSetMethod(form.getFieldValue('deliveryMethod'));
  };

  const onReset = () => {
    resetData();
  };

  useEffect(() => {
    if (selectedMapping && selectedMapping?.formatTypes?.length) {
      form.setFieldsValue({
        productDataFormat: selectedMapping?.formatTypes?.[0],
      });
    } else {
      form.setFieldsValue({ productDataFormat: 'JSON' });
    }
  }, [selectedMapping, form]);

  return (
    <>
      <Form
        form={form}
        name={formName}
        className='download-configuration'
        onFinish={onFinish}
        style={{ position: 'relative' }}
      >
        <Row className='download-configuration__item' gutter={16}>
          {/* Clear will be kept -> click ->reset , Reset will be removed, task #3705 */}
          <Col offset={20} span={4}>
            <Button type='primary' onClick={onReset} icon={<ClearOutlined />}>
              Clear
            </Button>
          </Col>
        </Row>
        <Row className='download-configuration__item'>
          <Col span={24} className='download-configuration__item-text'>
            <Text>
              {intl.formatMessage({
                id: `Taco.folder.download.configuration.welcomeText`,
              })}
            </Text>
          </Col>
          <Col span={11}>
            <Form.Item
              {...formFormatLayout}
              name='downloadType'
              label={intl.formatMessage({
                id: `Taco.folder.download.configuration.downloadType`,
              })}
            >
              <Radio.Group
                onChange={() =>
                  onSetType && onSetType(form.getFieldValue('downloadType'))
                }
              >
                {FOLDER_DOWNLOAD_TYPE?.map((option, index) => {
                  return (
                    <Tooltip
                      getPopupContainer={() =>
                        document.getElementById(formName)
                      }
                      title={`${option.toUpperCase()} File Extension Download`}
                      key={`type-${index}`}
                    >
                      <Radio.Button
                        className='download-configuration__item-radio'
                        value={option.toLowerCase()}
                      >
                        <TypePicker group='file-type' type={option} />
                      </Radio.Button>
                    </Tooltip>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              {...formFormatLayout}
              name='deliveryMethod'
              label={intl.formatMessage({
                id: `Taco.folder.download.configuration.deliveryMethod`,
              })}
            >
              <Radio.Group
                onChange={() =>
                  onSetMethod?.(form.getFieldValue('deliveryMethod'))
                }
              >
                {FOLDER_DELIVERY_METHOD?.map((option, index) => {
                  if (option === 'ftp' && !memberInfo?.ftpEnabled) return null;
                  return (
                    <Tooltip
                      getPopupContainer={() =>
                        document.getElementById(formName)
                      }
                      title={intl.formatMessage({
                        id: `Taco.folder.download.configuration.deliveryMethod.${option}`,
                      })}
                      key={`method-${index}`}
                    >
                      <Radio.Button
                        className='download-configuration__item-radio'
                        value={option.toLowerCase()}
                      >
                        <TypePicker group='download-method' type={option} />
                      </Radio.Button>
                    </Tooltip>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formCheckboxLayout}
              name='includeSubfolders'
              label={intl.formatMessage(Messages.downloadIncludeAll)}
              valuePropName='checked'
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formCheckboxLayout}
              name='keepFolderStructure'
              label={intl.formatMessage(Messages.downloadKeepStructure)}
              valuePropName='checked'
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Divider className='download-configuration__divider' />
        <Collapse
          className='download-configuration__config'
          expandIconPosition='right'
          ghost
        >
          {downloadEntityAmount?.product && (
            <Panel
              header={headerRender(
                `Product Hierarchy (${downloadEntityAmount?.product})`
              )}
              key='product'
              forceRender
            >
              <ConfigProductDownload
                mappingId={downloadSetting?.mappingId}
                selectedMapping={selectedMapping}
                dropdownConfigs={dropdownConfigs}
                formName={formName}
              />
              {checkboxGroupRender(PRODUCT_CONFIG)}
              {/* {productConfigRender(productData)} */}
            </Panel>
          )}
          {downloadEntityAmount?.digitalAsset && (
            <Panel
              header={headerRender(
                `Digital Assets (${downloadEntityAmount?.digitalAsset})`
              )}
              key='digitalAsset'
              forceRender
            >
              {digitalAssetData.map((item, index) => {
                return digitalAssetConfigRender(item, index);
              })}
            </Panel>
          )}
          {downloadEntityAmount?.member && (
            <Panel
              header={headerRender(`Members (${downloadEntityAmount?.member})`)}
              key='member'
              forceRender
            >
              <Form.Item
                {...formLayout}
                name='memberDefinedSheets'
                label={intl.formatMessage(Messages.memberDefinedSheets)}
              >
                <WrapperSelect
                  getPopupContainer={() => document.getElementById(formName)}
                >
                  {INFORMATION_SHEET_OPTIONS.map((option, index) => {
                    return (
                      <Option key={index} value={option}>
                        {option?.toUpperCase()}
                      </Option>
                    );
                  })}
                </WrapperSelect>
              </Form.Item>
            </Panel>
          )}
          {downloadEntityAmount?.query && (
            <Panel
              header={headerRender(`Queries (${downloadEntityAmount?.query})`)}
              key='queries'
              forceRender
            >
              <Form.Item
                {...formLayout}
                name='queryDataFormat'
                label={intl.formatMessage({
                  id: `Taco.folder.download.configuration.queryDataFormat`,
                })}
              >
                <WrapperSelect
                  getPopupContainer={() => document.getElementById(formName)}
                >
                  {QUERIES_DATA_FORMAT.map((option, index) => {
                    return (
                      <Option key={index} value={option}>
                        {option?.toUpperCase()}
                      </Option>
                    );
                  })}
                </WrapperSelect>
              </Form.Item>
            </Panel>
          )}
          {downloadEntityAmount?.report && (
            <Panel
              header={headerRender(`Reports (${downloadEntityAmount?.report})`)}
              key='reports'
              forceRender
            >
              <Form.Item
                {...formLayout}
                name='reportingDataFormat'
                label={intl.formatMessage({
                  id: `Taco.folder.download.configuration.reportDataFormat`,
                })}
              >
                <WrapperSelect
                  getPopupContainer={() => document.getElementById(formName)}
                >
                  {REPORTS_DATA_FORMAT.map((option, index) => {
                    return (
                      <Option key={index} value={option}>
                        {option?.toUpperCase()}
                      </Option>
                    );
                  })}
                </WrapperSelect>
              </Form.Item>
            </Panel>
          )}
        </Collapse>
      </Form>
      <button
        id='download-configuration__schedule-button'
        style={{ display: 'none' }}
        onClick={() => {
          const params = form.getFieldsValue();
          PRODUCT_CONFIG.forEach((item) => {
            delete params[item.name];
            for (const [key, child] of item.option?.entries()) {
              if (!params[child.name]) {
                return;
              }
              delete params[child.name]['value'];
              params[child.name] = Object.keys(params[child.name]).filter(
                (key) => {
                  return params[child.name][key];
                }
              );
            }
          });
          continueSchedule(
            downloadEntityAmount?.member && params.memberDefinedSheets === null
              ? { ...params, memberDefinedSheets: 'RIVIR Default' }
              : params
          );
        }}
      />
      <EmailSelection
        onClose={() => setVisibleEmailSelection(false)}
        visible={visibleEmailSelection}
        onSendEmail={onSendMultiEmail}
        primaryEmail={primaryEmail}
        alternativeEmail={alternativeEmail}
      ></EmailSelection>
    </>
  );
};

DownloadConfiguration.propTypes = {
  downloadSetting: oneOfType([PropTypes.array, PropTypes.object]),
  id: PropTypes.number,
  setLoading: PropTypes.func,
  onSetType: PropTypes.func,
  onSetMethod: PropTypes.func,
};

export default injectIntl(DownloadConfiguration);
