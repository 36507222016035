import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SECURE_CONFIG } from 'utils/SecureRoute';
import homeMessages from 'i18n/messages/home';
import Messages from 'i18n/messages/messageAssetUpload';

import MemberFull from 'pages/home/ribbon/MemberFull';
import MemberCompanyFull from 'pages/home/ribbon/MemberCompanyFull';
import UserFull from 'pages/home/ribbon/UserFull';
import WorkflowGrid from 'pages/home/ribbon/WorkflowGrid';
import WorkflowAdministrationGridView from 'pages/home/ribbon/WorkflowAdministrationGridView';
import AssetFull from 'pages/home/ribbon/AssetFull';
import HelpMaintenanceCategoryRibbon from 'pages/home/ribbon/HelpMaintenanceCategory';
import Folders from 'pages/home/ribbon/Folders';
import OwnedFolders from 'pages/home/ribbon/OwnedFolders';
import SharedFolders from 'pages/home/ribbon/SharedFolders';
import HelpPostFullView from 'pages/home/ribbon/HelpPostFullView';
import HelpPostGridView from 'pages/home/ribbon/HelpPostGridView';
import HelpArticlesGridView from 'pages/home/ribbon/HelpArticlesGridView';
import HelpArticlesFullView from 'pages/home/ribbon/HelpArticleFullView';
import MaintenanceEulaGridView from 'pages/home/ribbon/MaintenanceEulaGridView';
import ReportingFullViewRibbon from 'pages/home/ribbon/ReportingFullViewRibbon';
import Reportings from 'pages/home/ribbon/Reportings';
import CommunicationTemplateGridView from 'pages/home/ribbon/CommunicationTemplateGridView';
import CommunicationTemplateFullView from 'pages/home/ribbon/CommunicationTemplateFullView';
import MemberMaintenanceGridView from 'pages/home/ribbon/MemberMaintenanceGridView';
import UserMaintenanceGridView from 'pages/home/ribbon/UsersMaintenanceGridView';
import Queries from 'pages/home/ribbon/Queries';
import Home from 'pages/home/ribbon/Home';
import AssetForMember from 'pages/home/ribbon/AssetForMember';
import IxoneRibbon from 'pages/home/ribbon/IxoneRibbon';
import OnlyCloseRibbon from 'pages/home/ribbon/OnlyCloseRibbon';
import WorkflowMaintain from 'pages/home/ribbon/WorkflowMaintain';
import WfTemplateFullView from 'pages/home/ribbon/WfTemplateFullView';
import WorkflowDetailRibbon from 'pages/home/ribbon/WorkflowDetailRibbon';
import ProductForMemberRibbon from 'pages/home/ribbon/ProductForMemberRibbon';
import ProductFullViewRibbon from 'pages/home/ribbon/ProductFullViewRibbon';
import ProductAPLGrid from 'pages/home/ribbon/ProductAPLGrid';
import ProductMapping from 'pages/home/ribbon/ProductMapping';
import ProductMappingConfig from 'pages/home/ribbon/ProductMappingConfig';
import ProductSyndicationHistory from 'pages/home/ribbon/ProductSyndicationHistory';
import FolderDetailRibbon from 'pages/home/ribbon/FolderDetailRibbon';

import ProductAPLDetail from 'pages/home/ribbon/ProductAPLDetail';
import MySubscriptionGridView from 'pages/home/ribbon/MySubscriptionGridView';
import SubscriptionToGridView from 'pages/home/ribbon/SubscriptionToGridView';

import MaintenanceMarkupGridView from 'pages/home/ribbon/MaintenanceMarkupGridView';
import MaintenanceMarkupFullView from 'pages/home/ribbon/MaintenanceMarkupFullView';
import CuratedQueryGridView from 'pages/home/ribbon/CuratedQueryGridView';
import RecipientsFieldsGridView from 'pages/home/ribbon/RecipientsFieldsGridView';
import Dashboard from 'pages/home/ribbon/Dashboard';

import RibbonRoleSecurityManage from 'pages/home/ribbon/RibbonRoleSecurityManage';
import HomeBlank from 'pages/home/ribbon/HomeBlank';

import ProductSubScription from 'pages/home/ribbon/ProductSubScription';
import PublicationGridView from 'pages/home/ribbon/PublicationGridView';

import ProductFullViewSheets from 'pages/home/ribbon/ProductFullViewSheets';

import RequestGrid from 'pages/home/ribbon/security-request/RequestGrid';
import TransitionPage from 'pages/home/ribbon/transition-page/TransitionPage';
import TransitionPageDetail from 'pages/home/ribbon/transition-page/TransitionPageDetail';

import FormManangeRibbon from 'pages/home/ribbon/form-manange/FornManage';
import FormUploadRibbon from 'pages/home/ribbon/form-manange/FormUpload';
import FormExcelRibbon from 'pages/home/ribbon/form-manange/FormExcel';
import FormManangeDetailRibbon from 'pages/home/ribbon/form-manange/FormManangeDetail';
import FormHistoryRibbon from 'pages/home/ribbon/form-manange/FormHistory';
import FormHistoryEntityRibbon from 'pages/home/ribbon/form-manange/FormHistoryEntity';

import SsoApprovalMember from 'pages/home/ribbon/sso-approval/SsoApprovalMemberRibbon';
import SsoApprovalUser from 'pages/home/ribbon/sso-approval/SsoApprovalUserRibbon';
import TicketingSystemRibbon from 'pages/home/ribbon/ticketing-system/TicketingSystemRibbon';
import QasRequireFieldRibbon from 'pages/home/ribbon/qas-require-field/QasRequireFieldRibbon';

import TicketingGroupRibbon from 'pages/home/ribbon/ticketing-group/TicketingGroupRibbon';

import HomeNavSubMenuTitle from 'pages/home/components/navigation/HomeNavSubMenuTitle';

import classnames from 'classnames';

import { find } from 'lodash';

import {
  ShoppingOutlined,
  TeamOutlined,
  HomeOutlined,
  ShareAltOutlined,
  UserOutlined,
  GoldOutlined,
  QuestionCircleOutlined,
  FolderOutlined,
  SnippetsOutlined,
  SettingOutlined,
  RetweetOutlined,
  MessageOutlined,
  MailOutlined,
  PlaySquareOutlined,
  PictureOutlined,
  ReconciliationOutlined,
  FileSearchOutlined,
  ProjectOutlined,
  ClockCircleOutlined,
  BellOutlined,
  SwapOutlined,
  FileTextOutlined,
  RestOutlined,
  DashboardOutlined,
  HistoryOutlined,
  IssuesCloseOutlined,
  CreditCardOutlined,
  FieldTimeOutlined,
  SafetyCertificateFilled,
  CloudSyncOutlined,
  FilterOutlined,
  WarningOutlined,
  ProfileOutlined,
  VideoCameraOutlined,
  CheckCircleOutlined,
  ApartmentOutlined,
  LockOutlined,
  StarOutlined,
  DeleteOutlined,
  MehOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { ReactComponent as UnitIcon } from 'assets/product/unit.svg';
import { ReactComponent as InnerPackIcon } from 'assets/product/innerpack.svg';
import { ReactComponent as CaseIcon } from 'assets/product/case.svg';
import { ReactComponent as MasterCaseIcon } from 'assets/product/master-case.svg';
import { ReactComponent as PalletIcon } from 'assets/product/pallet.svg';
import { ReactComponent as IconAssignToRequest } from 'assets/security-request/assign-to.svg';

import AssetReport from 'assets/reporting/AssetReport.png';
import MemberReport from 'assets/reporting/MemberReport.png';
import MemberQuery from 'assets/query/MemberQuery.png';
import AssetQuery from 'assets/query/AssetQuery.png';
import FolderQuery from 'assets/query/FolderQuery.png';
import ReportQuery from 'assets/query/ReportQuery.png';
import EulaRibbon from 'pages/eula-maintenance/components/form/EulaRibbon';

// delay
export const DEFAULT_TIMEOUT = 600000;
export const DEFAULT_WAIT = 300;
export const WAITING_SCREEN = 3000;

// component size
export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';
// minimum screen size 1024x768
export const SCREENSIZE_MIN_WIDTH = 1024;
export const SCREENSIZE_MIN_HEIGHT = 768;
export const HEIGHT_HEADER = 40;
export const HEIGHT_RIBBON = 68;
// export const HEIGHT_RIBBON = 86;
export const HEIGHT_SEARCH = 28;
export const HEIGHT_PAGINATION = 42;
export const PADDING_GRID = 16;
export const PADDING_RIBBON = 8;
export const HEIGHT_OTHER =
  HEIGHT_HEADER + HEIGHT_RIBBON + HEIGHT_SEARCH + HEIGHT_PAGINATION;
// PADDING_GRID;
export const WIDTH_NAVIGATION = 240;
export const WIDTH_GRID_VIEW = WIDTH_NAVIGATION + PADDING_GRID;
export const HEIGHT_GRID_NO_PAGIANTION =
  HEIGHT_HEADER + HEIGHT_RIBBON + HEIGHT_SEARCH + PADDING_GRID;
export const HEIGHT_MAIN_APPLICATION_WITHOUT_SEARCH =
  HEIGHT_HEADER + HEIGHT_RIBBON - PADDING_RIBBON;

//width Contant Pane
export const WIDTH_CONTANT_PANE = 500;
// width row skeletion
export const WIDTH_ROW_SKELETION = 136;
// storage key
export const USER_KEY = 'User';
export const IMPERSONATE_KEY = 'impersonate';
export const SESSION_KEY = 'Session';
export const LOCALE_KEY = 'Locale';
export const TIMEZONE_KEY = 'tz';
export const BRANDING_KEY = 'Branding';
export const RECENT_SEARCH_KEY = 'RecentSearch';
export const ANON_KEY = 'Anon';
export const SSO_KEY = 'sso_key';
export const URL_TARGET_BLUEPRINT = 'target_url_blueprint';

export const PRODUCT_EDIT_BRAND_MODE_OPTIONS = [
  'CreateNewBrand',
  'EditCurrentBrand',
  'SelectAnotherBrand',
];

//? CONTACT US EMAIL
export const CONTACT_US_EMAIL = 'gvi_support@gvi.global';

//? VIDEO CALL
export const JITSI_DOMAIN = 'chat.gorivir.com';

//? NAVIGATION
export const FAVORITE_NAV = {
  itemGroup: {
    key: '/favorites',
    title: <FormattedMessage {...homeMessages.favorites} />,
  },
  subItems: [
    {
      key: '/favorite/queries',
      link: '/favorite/queries',
      title: <FormattedMessage {...homeMessages.queries} />,
      path: 'numberOfQueries',
      secure: SECURE_CONFIG.MY_QUERY.PASS,
      totalField: 'totalFavoritesMyQueries',
    },
    {
      key: '/favorite/sharedqueries',
      link: '/favorite/sharedqueries',
      title: <FormattedMessage {...homeMessages.sharedQueries} />,
      path: 'numberOfSharedQueries',
      secure: SECURE_CONFIG.SHARED_QUERY.PASS,
      totalField: 'totalFavoritesSharedQueries',
    },
    {
      key: '/favorite/favorite-products',
      link: '/favorite/favorite-products',
      title: <FormattedMessage {...homeMessages.favoriteProducts} />,
      path: 'numberOfProducts',
      secure: SECURE_CONFIG.PRODUCT.PASS,
      totalField: 'totalFavoritesProducts',
    },
    {
      key: '/favorite/favorite-assets',
      link: '/favorite/favorite-assets',
      title: <FormattedMessage {...homeMessages.favoriteAssets} />,
      path: 'numberOfAssets',
      secure: SECURE_CONFIG.ASSET.PASS,
      totalField: 'totalFavoritesAssets',
    },
    {
      key: '/favorite/favorite-members',
      link: '/favorite/favorite-members',
      title: <FormattedMessage {...homeMessages.favoriteMembers} />,
      path: 'numberOfMembers',
      secure: SECURE_CONFIG.MEMBER.PASS,
      totalField: 'totalFavoritesMembers',
    },
    {
      key: '/favorite/favorite-folders',
      link: '/favorite/favorite-folders',
      title: <FormattedMessage {...homeMessages.favoriteFolders} />,
      path: 'numberOfFolders',
      secure: SECURE_CONFIG.FOLDER.PASS,
      totalField: 'totalFavoritesFolders',
    },
  ],
};

export const IXONE_NAV = {
  key: '/dashboard',
  title: 'RIVIR EXCHANGE',
  keyName: '/dashboard',
  link: '/dashboard',
  secure: SECURE_CONFIG.ALL_AUTH.PASS,
};

export const MEMBER_NAV = {
  key: '/members/main',
  link: '/members/main',
  title: <FormattedMessage {...homeMessages.members} />,
  secure: SECURE_CONFIG.MEMBER.PASS,
  newCount: 'totalNewMembers',
};

export const WORKFLOW_MAINTAIN_NAV = {
  itemGroup: {
    key: '/workflow-maintenance',
    title: <FormattedMessage {...homeMessages.workflowMaintenance} />,
    keyName: '/workflow-maintenance',
    secure: SECURE_CONFIG.WORKFLOW_MAINTENANCE.PASS,
  },
  subItems: [
    {
      key: '/workflow-maintenance/administration',
      link: '/workflow-maintenance/administration',
      title: <FormattedMessage {...homeMessages.administration} />,
      newCount: null,
      secure: SECURE_CONFIG.WORKFLOW_ADMINISTRATION.PASS,
    },
    {
      key: '/workflow-maintenance/definition',
      link: '/workflow-maintenance/definition',
      title: <FormattedMessage {...homeMessages.definition} />,
      newCount: null,
      secure: SECURE_CONFIG.WORKFLOW_DEFINITION.PASS,
    },
  ],
};

export const WORKFLOW_NAV = {
  key: '/workflows',
  link: '/workflows',
  title: <FormattedMessage {...homeMessages.workflow} />,
  newCount: null,
  secure: SECURE_CONFIG.WORKFLOW.PASS,
};

export const QA_SPEC_WORKFLOW_NAV = {
  key: '/qa-spec-workflows',
  link: '/qa-spec-workflows',
  title: <FormattedMessage {...homeMessages.qaSpecWorkFlow} />,
  newCount: null,
  secure: SECURE_CONFIG.MANAGE_QA_SPEC_WORKFLOW.PASS,
};

export const ROLE_NAV = {
  itemGroup: (selectedKeys, openKeys) => ({
    key: '/role',
    className: classnames({
      'home-nav-menu__sub-menu--expand': !!openKeys.find(
        (key) => key === '/role'
      ),
    }),
    title: (
      <HomeNavSubMenuTitle
        titleName='Roles'
        keyName='/role'
        selectedKeys={selectedKeys}
      />
    ),
  }),
  subItems: [
    {
      key: '/security',
      link: '/security',
      title: <FormattedMessage {...homeMessages.roleSecurity} />,
      secure: SECURE_CONFIG.EDIT_USER_PERMISSION.PASS,
    },
  ],
};

export const MAINTAIN_MEMBER_ROLE = {
  itemGroup: {
    key: '/security-matrix',
    title: <FormattedMessage {...homeMessages.memberRole} />,
    keyName: '/security-matrix',
    secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
  },

  subItems: [
    {
      key: '/security-matrix/member-role',
      link: '/security-matrix/member-role',
      title: <FormattedMessage {...homeMessages.roleManagement} />,
      newCount: null,
      secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
    },
    {
      key: '/security-matrix/permission-request',
      link: '/security-matrix/permission-request',
      title: <FormattedMessage {...homeMessages.permissionRequest} />,
      newCount: 'totalNewUpdateOnPermissionRequest',
      secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
    },
  ],
};

export const PRODUCT_NAV = {
  itemGroup: {
    key: '/products',
    selectedKeyFunc: (selectedKeys) =>
      selectedKeys === '/products' || selectedKeys.includes('/products/action'),
    openKeyFunc: (openKeys) => !!find(openKeys, (key) => key === '/products'),
    titleName: <FormattedMessage {...homeMessages.products} />,
    link: '/products/main',
    actionDataPathNew: 'totalNewProducts',
    actionDataPathUnmatched: 'totalProductUnmatchedMinimumRequirements',
    actionLink: '/products',
    secure: [SECURE_CONFIG.PRODUCT.PASS],
  },
  subItems: [
    {
      key: '/products/apl',
      link: '/products/apl',
      title: <FormattedMessage {...homeMessages.apl} />,
      secure: SECURE_CONFIG.PRODUCT_APL.PASS,
      newCount: 'totalNewAplProducts',
    },
    {
      key: '/products/mappings',
      link: '/products/mappings',
      title: <FormattedMessage {...homeMessages.mapping} />,
      secure: SECURE_CONFIG.MANAGE_PIM_MAPPING.PASS,
    },
    {
      key: '/products/gdsn-received-products',
      link: '/products/gdsn-received-products',
      title: <FormattedMessage {...homeMessages.publications} />,
      secure: [
        SECURE_CONFIG.PRODUCT.PASS,
        SECURE_CONFIG.ACCESS_GDSN.PASS,
        SECURE_CONFIG.ACCESS_GDSN_RECEIVED_PRODUCT.PASS,
      ],
      newCount: 'totalPendingGdsnReceivedProducts',
    },
    {
      key: '/products/syndication-history',
      link: '/products/syndication-history',
      title: <FormattedMessage {...homeMessages.syndicationHistory} />,
      secure: [
        SECURE_CONFIG.PRODUCT.PASS,
        SECURE_CONFIG.ACCESS_GDSN.PASS,
        SECURE_CONFIG.ACCESS_SYNDICATION.PASS,
      ],
      newCount: 'totalUnreadGdsnActivities',
    },
  ],
};

export const DIGITAL_MEDIA_NAV = {
  itemGroup: {
    key: '/digital-media',
    title: <FormattedMessage {...homeMessages.assets} />,
    keyName: '/digital-media',
    link: '/digital-media',
    secure: SECURE_CONFIG.ASSET.PASS,
  },
  subItems: [
    {
      key: '/digital-media/assets',
      link: '/digital-media/assets',
      title: <FormattedMessage {...homeMessages.productAssets} />,
      secure: SECURE_CONFIG.ASSET.PASS,
      newCount: '',
    },
    {
      key: '/digital-media/documents',
      link: '/digital-media/documents',
      title: <FormattedMessage {...homeMessages.docAssets} />,
      secure: SECURE_CONFIG.ASSET.PASS,
      newCount: '',
    },
    {
      key: '/digital-media/multi-media',
      link: '/digital-media/multi-media',
      title: <FormattedMessage {...homeMessages.multiMedia} />,
      secure: SECURE_CONFIG.ASSET.PASS,
      newCount: '',
    },
  ],
};

export const REPORTING_NAV = {
  itemGroup: {
    key: '/reportings',
    title: <FormattedMessage {...homeMessages.reporting} />,
    keyName: '/reportings',
    link: '/reportings',
    secure: SECURE_CONFIG.REPORTING.PASS,
  },
  subItems: [
    {
      key: '/reportings/owned',
      link: '/reportings/owned',
      title: <FormattedMessage {...homeMessages.reportingOwned} />,
      secure: SECURE_CONFIG.REPORTING.PASS,
      newCount: null,
    },
    {
      key: '/reportings/shared',
      link: '/reportings/shared',
      title: <FormattedMessage {...homeMessages.reportingShared} />,
      secure: SECURE_CONFIG.REPORTING.PASS,
      newCount: null,
    },
  ],
};

export const SUBSCRIPTION_NAV = {
  itemGroup: {
    key: '/subscriptions',
    selectedKeyFunc: (selectedKeys) =>
      selectedKeys === '/subscriptions' ||
      selectedKeys.includes('/subscriptions/action'),
    openKeyFunc: (openKeys) =>
      !!find(openKeys, (key) => key === '/subscriptions'),
    titleName: <FormattedMessage {...homeMessages.subscriptions} />,
    actionDataPathNew: null,
    secure: [SECURE_CONFIG.PRODUCT.PASS, SECURE_CONFIG.ACCESS_GDSN.PASS],
  },
  subItems: [
    {
      key: '/subscriptions/my-subscriptions',
      link: '/subscriptions/my-subscriptions',
      title: <FormattedMessage {...homeMessages.mySubscriptions} />,
      secure: [
        SECURE_CONFIG.PRODUCT.PASS,
        SECURE_CONFIG.ACCESS_MY_SUBSCRIPTIONS.PASS,
      ],
      newCount: null,
    },
    {
      key: '/subscriptions/subscriptions-to',
      link: '/subscriptions/subscriptions-to',
      title: <FormattedMessage {...homeMessages.subscribers} />,
      secure: SECURE_CONFIG.PRODUCT.PASS,
      newCount: null,
    },
  ],
};

export const PROGRAM_NAV = {
  key: '/programs',
  link: '/programs',
  title: <FormattedMessage {...homeMessages.programs} />,
  newCount: null,
  secure: SECURE_CONFIG.ALL_AUTH.PASS,
};

export const CURATED_QUERY_NAV = {
  key: '/curated-query',
  link: '/curated-query',
  title: <FormattedMessage {...homeMessages.curatedQuery} />,
  newCount: null,
  secure: SECURE_CONFIG.CURATED_QUERY.PASS,
};

export const RECIPIENTS_FIELDS_NAV = {
  key: '/recipients-fields',
  link: '/recipients-fields',
  title: <FormattedMessage {...homeMessages.recipientsFields} />,
  newCount: null,
  secure: [SECURE_CONFIG.RECIPIENTS_FIELDS.PASS],
};

export const SPLASH_FORM_HISTORY_NAV = {
  key: '/exported-splash-forms',
  link: '/exported-splash-forms',
  title: <FormattedMessage {...homeMessages.splashDownloadHistory} />,
  newCount: null,
  secure: [SECURE_CONFIG.DOWNLOAD_SPLASH_HISTORY.PASS],
};

export const FOLDER_NAV = {
  itemGroup: {
    key: '/folders',
    title: <FormattedMessage {...homeMessages.folders} />,
    keyName: '/folders',
    link: '/folders',
    newCount: null,
    secure: SECURE_CONFIG.FOLDER.PASS,
  },
  subItems: [
    {
      key: '/folders/owned',
      link: '/folders/owned',
      title: <FormattedMessage {...homeMessages.myFolders} />,
      newCount: null,
      secure: SECURE_CONFIG.FOLDER.PASS,
    },
    {
      key: '/folders/shared',
      link: '/folders/shared',
      title: <FormattedMessage {...homeMessages.sharedFolders} />,
      newCount: null,
      secure: SECURE_CONFIG.FOLDER.PASS,
    },
  ],
};

export const MAINTAIN_NAV = {
  key: '/maintenance',
  title: <FormattedMessage {...homeMessages.maintenance} />,
  keyName: '/maintenance',
  // secure: [
  //   SECURE_CONFIG.HELP_CATEGORY.PASS,
  //   SECURE_CONFIG.HELP_POST.PASS,
  //   SECURE_CONFIG.ARTICLE.PASS,
  //   SECURE_CONFIG.EULA.PASS,
  //   SECURE_CONFIG.MEMBER.PASS,
  //   SECURE_CONFIG.COMMUNICATION_TEMPLATE.PASS,
  // ],
};

export const MAINTAIN_HELP_NAV = {
  itemGroup: {
    key: '/help',
    title: <FormattedMessage {...homeMessages.helpSystem} />,
    keyName: '/help',
    // secure: [
    //   SECURE_CONFIG.HELP_CATEGORY.PASS,
    //   SECURE_CONFIG.HELP_POST.PASS,
    //   SECURE_CONFIG.ARTICLE.PASS,
    // ],
  },
  subItems: [
    {
      key: '/maintenance/help/category',
      link: '/maintenance/help/category',
      title: <FormattedMessage {...homeMessages.categoryHelp} />,
      newCount: null,
      secure: SECURE_CONFIG.HELP_CATEGORY.PASS,
    },
    {
      key: '/maintenance/help/post',
      link: '/maintenance/help/post',
      title: <FormattedMessage {...homeMessages.postHelp} />,
      newCount: null,
      secure: SECURE_CONFIG.HELP_POST.PASS,
    },
    {
      key: '/maintenance/help/articles',
      link: '/maintenance/help/articles',
      title: <FormattedMessage {...homeMessages.articles} />,
      newCount: null,
      secure: SECURE_CONFIG.ARTICLE.PASS,
    },
  ],
};
// BP 09/01/2022 - ppppp
export const MAINTAIN_EULA_NAV = {
  itemGroup: {
    key: '/eula',
    title: <FormattedMessage {...homeMessages.eulaCenter} />,
    keyName: '/eula',
    secure: SECURE_CONFIG.EULA.PASS,
  },
  subItems: [
    {
      key: '/maintenance/eula/list',
      link: '/maintenance/eula/list',
      title: <FormattedMessage {...homeMessages.eula} />,
      newCount: null,
      secure: SECURE_CONFIG.EULA.PASS,
    },
    {
      key: '/maintenance/eula/credit-card',
      link: '/maintenance/eula/credit-card',
      title: <FormattedMessage {...homeMessages.eulaCard} />,
      newCount: null,
      secure: SECURE_CONFIG.EULA.PASS,
    },
  ],
};

export const MAINTAIN_ACCOUNT_NAV = {
  itemGroup: {
    key: '/data-management',
    title: <FormattedMessage {...homeMessages.dataManagement} />,
    keyName: '/data-management',
    secure: SECURE_CONFIG.MANAGE_MEMBER.PASS,
  },
  subItems: [
    {
      key: '/maintenance/member-management',
      link: '/maintenance/member-management',
      title: <FormattedMessage {...homeMessages.memberManagement} />,
      newCount: null,
      secure: SECURE_CONFIG.MANAGE_MEMBER.PASS,
    },
    {
      key: '/maintenance/user-management',
      link: '/maintenance/user-management',
      title: <FormattedMessage {...homeMessages.usersManagement} />,
      newCount: null,
      secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
    },
    {
      key: '/maintenance/data-sync-time-tracking',
      link: '/maintenance/data-sync-time-tracking',
      title: <FormattedMessage {...homeMessages.dataSyncTimeTracking} />,
      newCount: null,
      secure: SECURE_CONFIG.MANAGE_BILLABLE_TRACKING.PASS,
    },
    {
      key: '/maintenance/qc-time-tracking',
      link: '/maintenance/qc-time-tracking',
      title: <FormattedMessage {...homeMessages.qcTimeTracking} />,
      newCount: null,
      secure: SECURE_CONFIG.MANAGE_BILLABLE_TRACKING.PASS,
    },
  ],
};

export const MFA_VERIFICATION_LOG_NAV = {
  key: '/maintenance/mfa-verification-log',
  link: '/maintenance/mfa-verification-log',
  title: <FormattedMessage {...homeMessages.mfaVerificationLog} />,
  newCount: null,
  secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
};

export const MAINTAIN_REMAIN_NAV = {
  subItems: [
    {
      key: '/maintenance/form',
      link: '/maintenance/form',
      title: <FormattedMessage {...homeMessages.form} />,
      newCount: null,
      secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
    },
    {
      key: '/communication-templates',
      link: '/communication-templates',
      title: <FormattedMessage {...homeMessages.communication} />,
      newCount: null,
      secure: SECURE_CONFIG.COMMUNICATION_TEMPLATE.PASS,
    },
    {
      key: '/maintenance/transition-page',
      link: '/maintenance/transition-page',
      title: <FormattedMessage {...homeMessages.transitionPage} />,
      newCount: null,
      secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
    },
    // {
    //   key: '/maintenance/qas-required-fields',
    //   link: '/maintenance/qas-required-fields',
    //   title: <FormattedMessage {...homeMessages.qasRequireField} />,
    //   newCount: null,
    //   secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
    // },
    {
      key: '/maintenance/minimum-requirements',
      link: '/maintenance/minimum-requirements',
      title: <FormattedMessage {...homeMessages.minimumRequirements} />,
      newCount: null,
      secure: SECURE_CONFIG.SUPER_ADMIN.PASS,
    },
  ],
};

export const MAINTAIN_MARKUP_NAV = {
  itemGroup: {
    key: '/markup',
    title: <FormattedMessage {...homeMessages.mainPageCenter} />,
    keyName: '/markup',
    secure: SECURE_CONFIG.MARKUP.PASS,
  },
  subItems: [
    {
      key: '/maintenance/markup/list',
      link: '/maintenance/markup/list',
      title: <FormattedMessage {...homeMessages.markup} />,
      newCount: null,
      secure: SECURE_CONFIG.MARKUP.PASS,
    },
  ],
};

export const SSO_APPROVAL_NAV = {
  itemGroup: {
    key: '/sso-approval',
    title: <FormattedMessage {...homeMessages.ssoApprovalNav} />,
    keyName: '/markup',
    secure: SECURE_CONFIG.MANAGE_SSO_APPROVAL.PASS,
  },
  subItems: [
    {
      key: '/sso-approval/member',
      link: '/sso-approval/member',
      title: <FormattedMessage {...homeMessages.ssoApprovalMemberNav} />,
      newCount: 'totalSsoPendingMember',
      secure: SECURE_CONFIG.MANAGE_SSO_APPROVAL.PASS,
    },
    {
      key: '/sso-approval/user',
      link: '/sso-approval/user',
      title: <FormattedMessage {...homeMessages.ssoApprovalUserNav} />,
      newCount: 'totalSsoPendingUser',
      secure: SECURE_CONFIG.MANAGE_SSO_APPROVAL.PASS,
    },
    // {
    //   key: '/sso-approval/supplier',
    //   link: '/sso-approval/supplier',
    //   title: <FormattedMessage {...homeMessages.ssoApprovalSupplierNav} />,
    //   secure: SECURE_CONFIG.MANAGE_SSO_APPROVAL.PASS,
    // },
  ],
};

export const TICKETING_SYSTEM_NAV = {
  key: '/ticketing-system',
  link: '/ticketing-system',
  title: <FormattedMessage {...homeMessages.ticketingSystem} />,
  newCount: 'totalNewTickets',
  secure: SECURE_CONFIG.TICKET_SYSTEM.PASS,
};

export const TICKETING_GROUP_NAV = {
  key: '/ticket-group-assignment',
  link: '/ticket-group-assignment',
  title: <FormattedMessage {...homeMessages.ticketingGroup} />,
  newCount: null,
  secure: SECURE_CONFIG.TICKET_GROUP.PASS,
};

//? TIME
export const WORKFLOW_STEP_DURATION = 3600 * 2;

export const MAXIMUM_CHAT_MESSAGE_LENGTH = 50000;

//? PAGE SIZE
export const HELP_SEARCH_PAGE_SIZE = 10;
export const MEMBER_SHOWCASE_MEDIA_PAGE_SIZE = 30;
export const MEMBER_OVERVIEW_SHOWCASE_MEDIA_PAGE_SIZE = 10;

//? ROUTES
export const ROUTE = {
  DIGITAL_MEDIA: '/digital-media',
  DIGITAL_MEDIA_ASSET: '/digital-media/assets',
  DIGITAL_MEDIA_DOCUMENT: '/digital-media/documents',
  DIGITAL_MEDIA_MULTI_MEDIA: '/digital-media/multi-media',
  ASSET: '/asset',
  MEMBER: '/members',
  COMPANY: '/company',
  REPORTINGS: '/reportings',
  REPORTING: '/reporting',
  REPORTING_OWNED: '/reportings/owned',
  REPORTING_SHARED: '/reportings/shared',
  PRODUCT: '/product',
  PRODUCTS: '/products',
  FOLDERS: '/folders',
  FOLDER: '/folder',
  FOLDER_OWNED: '/folders/owned',
  FOLDER_SHARED: '/folders/shared',
  APL_PRODUCTS_SCHEDULE: '/products/apl/schedule',
  MARKUP_MAINTENANCE_GRID: '/maintenance/markup/list',
  MEETING: '/meeting',
  COMMUNICATION_TEMPLATE: '/communication-templates',
  PERMISSION_REQUEST: '/security-matrix/permission-request',
  TRANSITION_PAGE_MANAGE: '/maintenance/transition-page',
  MEMBER_MAINTENANCE: '/member-management-company',
  MEMBER_PRODUCTS: '/member-products',
  MEMBER_ASSETS: '/member-assets',
};

//? ELEMENT ID (AS NEEDED)
export const ELEMENT_ID = {
  MEMBER_FULL_VIEW_HEADER: 'MEMBER_FULL_VIEW_HEADER',
  COMPANY_HEADER_BACKGROUND: 'COMPANY_HEADER_BACKGROUND',
  HELP_FORM_POST_MARKDOWN: 'HELP_FORM_POST_MARKDOWN',
  ARTICLE_FORM_POST_MARKDOWN: 'ARTICLE_FORM_POST_MARKDOWN',
  COMMUNICATION_TEMPLATE_MARKDOWN: 'COMMUNICATION_TEMPLATE_MARKDOWN',
};

//?Date
export const DATE_FORMAT = {
  COMMON: 'YYYY/MM/DD',
  SYSTEM_FORMAT: 'MM/DD/YYYY',
};

// search criteria
export const SEARCH_CRITERIA = {
  ALL: 'All',
  WORKFLOW: 'Workflow',
  PRODUCT: 'Product',
  ASSET: 'Asset',
  MEMBER: 'Member',
  FOLDER: 'Folder',
  QUERY: 'Query',
  HELP_MAINTENANCE: 'HelpMaintenance',
  HELP_MAINTENANCE_POST: 'Post',
  HELP_MAINTENANCE_ARTICLES: 'Articles',
  MAINTENANCE_EULA: 'EULA',
  MAINTENANCE_ACCEPTED_USER_EULA: 'Accepted User Eula',
  COMMUNICATION_TEMPLATE: 'Communications',
  MEMBER_MAINTENANCE: 'Members',
  USER_MAINTENANCE: 'Users',
  REPORTING: 'Report', // BP 08/30/2020: TODO - will set disabled search when REPORT is not an entity
  DASHBOARD: 'Dashboard', // BP 08/30/2020: TODO - will set disabled search when DASHBOARD is not an entity
  PROGRAM: 'Program', // BP 08/30/2020: TODO - will set disabled search when PROGRAM is not an entity
  MAINTENANCE_MARKUP: 'Markup',
  MAINTENANCE_ACCEPTED_USER_MARKUP: 'Accepted User Markup',
  MAINTENANCE_CURATED_QUERY: 'Curated Query',
  RECIPIENTS_FIELDS: 'recipients fields',
  SUBSCRIPTION: 'Subscription',
  APL: 'APL',
  PRODUCT_MAPPING: 'Mapping',
  REQUEST_GRID: 'request',
  SSO_APPROVAL: 'SSO Approval',
  VERIFICATION_LOG: 'verification log',
  TRANSITION_PAGE: 'transition page',
  FORM_MANAGE: 'Form Management',
  MINIMUM_REQUIREMENTS: 'minimum requirements',
  SSO_APPROVAL_MEMBER: 'member',
  SSO_APPROVAL_USER: 'user',
  SSO_APPROVAL_SUPPLIER: 'supplier',
  EXPORTED_FORM: 'exported form',
  EULA_CREDIT_CARD: 'Credit Card T&C',
  DATA_SYNC_TIME_TRACKING: 'data sync time tracking',
  QC_TIME_TRACKING: 'QC time tracking',
  TICKETING_SYSTEM: 'Ticketing System',
  QA_SPEC_WORKFLOW: 'QA Specification workflow',
  QAS_REQUIRE_FIELD: 'QAS require field',
};

//! RIBBON VIEW - declare name dsa
export const RIBBON_VIEW = {
  NONE: {
    NAME: 'NONE',
  },
  IXONE_VIEW: {
    NAME: 'IXONE_VIEW',
    VIEW: IxoneRibbon,
  },
  MEMBER_FULL_VIEW: {
    NAME: 'MEMBER_FULL_VIEW',
    VIEW: MemberFull,
  },
  MEMBER_COMPANY_FULL_VIEW: {
    NAME: 'MEMBER_COMPANY_FULL_VIEW',
    VIEW: MemberCompanyFull,
  },

  MEMBER_CONTENT_PANE: {
    NAME: 'MEMBER_CONTENT_PANE',
    VIEW: () => <div> this is member ribbon content view</div>,
  },

  USER_FULL_VIEW: {
    NAME: 'USER_FULL_VIEW',
    VIEW: UserFull,
  },

  WORKFLOW_GRID_VIEW: {
    NAME: 'WORKFLOW_GRID_VIEW',
    VIEW: WorkflowGrid,
  },

  TRANSITION_PAGE: {
    NAME: 'TRANSITION_PAGE',
    VIEW: TransitionPage,
  },
  TRANSITION_PAGE_DETAIL: {
    NAME: 'TRANSITION_PAGE_DETAIL',
    VIEW: TransitionPageDetail,
  },
  MFA_VERIFICATION_LOG: {
    NAME: 'MFA_VERIFICATION_LOG',
    VIEW: null,
  },

  FORM_MANAGE: {
    NAME: 'FORM_MANAGE',
    VIEW: FormManangeRibbon,
  },
  FORM_HISTORY: {
    NAME: 'FORM_HISTORY',
    VIEW: FormHistoryRibbon,
  },
  FORM_HISTORY_ENTITY: {
    NAME: 'FORM_HISTORY_ENTITY',
    VIEW: FormHistoryEntityRibbon,
  },
  FORM_UPLOAD: {
    NAME: 'FORM_UPLOAD',
    VIEW: FormUploadRibbon,
  },
  FORM_EXCEL: {
    NAME: 'FORM_EXCEL',
    VIEW: FormExcelRibbon,
  },
  FORM_MANAGE_DETAIL: {
    NAME: 'FORM_MANAGE_DETAIL',
    VIEW: FormManangeDetailRibbon,
  },

  REQUEST_GRID: {
    NAME: 'REQUEST_GRID',
    VIEW: RequestGrid,
  },

  WORKFLOW_ADMINISTRATION_GRID_VIEW: {
    NAME: 'WORKFLOW_ADMINISTRATION_GRID_VIEW',
    VIEW: WorkflowAdministrationGridView,
  },
  WORKFLOW_DETAIL_VIEW: {
    NAME: 'WORKFLOW_DETAIL_VIEW',
    VIEW: WorkflowDetailRibbon,
  },
  ASSET_FULL_VIEW: {
    NAME: 'ASSET_FULL_VIEW',
    VIEW: AssetFull,
  },

  FOLDER_VIEW: {
    NAME: 'FOLDER_VIEW',
    VIEW: Folders,
  },
  OWNED_FOLDER_VIEW: {
    NAME: 'OWNED_FOLDER_VIEW',
    VIEW: Folders,
  },
  SHARED_FOLDER_VIEW: {
    NAME: 'SHARED_FOLDER_VIEW',
    VIEW: Folders,
  },
  HELP_VIEW: {
    NAME: 'HELP_VIEW',
    VIEW: AssetFull,
  },
  HELP_MAINTENANCE_CATEGORY_VIEW: {
    NAME: 'HELP_MAINTENANCE_CATEGORY_VIEW',
    VIEW: HelpMaintenanceCategoryRibbon,
  },
  HELP_POST_FULL_VIEW: {
    NAME: 'HELP_POST_FULL_VIEW',
    VIEW: HelpPostFullView,
  },
  HELP_POST_GRID_VIEW: {
    NAME: 'HELP_POST_GRID_VIEW',
    VIEW: HelpPostGridView,
  },
  HELP_ARTICLES_GRID_VIEW: {
    NAME: 'HELP_ARTICLES_GRID_VIEW',
    VIEW: HelpArticlesGridView,
  },
  HELP_ARTICLES_FULL_VIEW: {
    NAME: 'HELP_ARTICLES_FULL_VIEW',
    VIEW: HelpArticlesFullView,
  },
  MAINTENANCE_EULA_GRID_VIEW: {
    NAME: 'MAINTENANCE_EULA_GRID_VIEW',
    VIEW: MaintenanceEulaGridView,
  },
  ACCEPTED_EULA_GRID_VIEW: {
    NAME: 'ACCEPTED_EULA_GRID_VIEW',
    VIEW: Home,
  },
  MANAGE_SHARING_GRID_VIEW: {
    NAME: 'MANAGE_SHARING_GRID_VIEW',
    VIEW: Home,
  },
  FOLDER_DETAIL: {
    NAME: 'FOLDER_DETAIL',
    VIEW: FolderDetailRibbon,
  },
  MAINTENANCE_EULA_FULL_VIEW: {
    NAME: 'MAINTENANCE_EULA_FULL_VIEW',
    VIEW: EulaRibbon,
  },
  ADVANCED_SEARCH_VIEW: {
    NAME: 'ADVANCED_SEARCH_VIEW',
    VIEW: ReportingFullViewRibbon,
  },
  REPORTING_VIEW: {
    NAME: 'REPORTING_VIEW',
    VIEW: Reportings,
  },
  COMMUNICATION_VIEW: {
    NAME: 'COMMUNICATION_VIEW',
    VIEW: CommunicationTemplateGridView,
  },
  COMMUNICATION_FULLVIEW: {
    NAME: 'COMMUNICATION_FULLVIEW',
    VIEW: CommunicationTemplateFullView,
  },
  MEMBER_MAINTENANCE_VIEW: {
    NAME: 'MEMBER_MAINTENANCE_VIEW',
    VIEW: MemberMaintenanceGridView,
  },
  USERS_MAINTENANCE_VIEW: {
    NAME: 'USERS_MAINTENANCE_VIEW',
    VIEW: UserMaintenanceGridView,
  },
  QUERY_VIEW: {
    NAME: 'QUERY_VIEW',
    VIEW: Queries,
  },
  ASSET_FOR_MEMBER: {
    NAME: 'ASSET_FOR_MEMBER',
    VIEW: AssetForMember,
  },
  WORKFLOW_MAINTAIN: {
    NAME: 'WORKFLOW_MAINTAIN',
    VIEW: WorkflowMaintain,
  },
  WF_TEMPLATE_FULL: {
    NAME: 'WF_TEMPLATE_FULL',
    VIEW: WfTemplateFullView,
  },
  PRODUCT_FOR_MEMBER: {
    NAME: 'PRODUCT_FOR_MEMBER',
    VIEW: ProductForMemberRibbon,
  },
  PRODUCT_FULL_VIEW: {
    NAME: 'PRODUCT_FULL_VIEW',
    VIEW: ProductFullViewRibbon,
  },
  PRODUCT_SUBSCRIPTION: {
    NAME: 'PRODUCT_SUBSCRIPTION',
    VIEW: ProductSubScription,
  },
  PRODUCT_HISTORY: {
    NAME: 'PRODUCT_HISTORY',
    VIEW: OnlyCloseRibbon,
  },
  PRODUCT_SHEETS: {
    NAME: 'PRODUCT_SHEETS',
    VIEW: ProductFullViewSheets,
  },
  PRODUCT_APL: {
    NAME: 'PRODUCT_APL',
    VIEW: ProductAPLGrid,
  },
  PRODUCT_MAPPING: {
    NAME: 'PRODUCT_MAPPING',
    VIEW: ProductMapping,
  },
  PRODUCT_MAPPING_CONFIG: {
    NAME: 'PRODUCT_MAPPING_CONFIG',
    VIEW: ProductMappingConfig,
  },
  PRODUCT_APL_DETAIL: {
    NAME: 'PRODUCT_APL_DETAIL',
    VIEW: ProductAPLDetail,
  },
  PRODUCT_SYNDICATION_HISTORY: {
    NAME: 'PRODUCT_SYNDICATION_HISTORY',
    VIEW: ProductSyndicationHistory,
  },
  MAINTENANCE_MARKUP_GRID_VIEW: {
    NAME: 'MAINTENANCE_MARKUP_GRID_VIEW',
    VIEW: MaintenanceMarkupGridView,
  },
  ACCEPTED_MARKUP_GRID_VIEW: {
    NAME: 'ACCEPTED_MARKUP_GRID_VIEW',
    VIEW: Home,
  },
  MAINTENANCE_MARKUP_FULL_VIEW: {
    NAME: 'MAINTENANCE_MARKUP_FULL_VIEW',
    VIEW: MaintenanceMarkupFullView,
  },
  CURATED_QUERY_GRID_VIEW: {
    NAME: 'CURATED_QUERY_GRID_VIEW',
    VIEW: CuratedQueryGridView,
  },
  RECIPIENTS_FIELDS_GRID_VIEW: {
    NAME: 'RECIPIENTS_FIELDS_GRID_VIEW',
    VIEW: RecipientsFieldsGridView,
  },
  MY_SUBSCRIPTION_GRID_VIEW: {
    NAME: 'MY_SUBSCRIPTION_GRID_VIEW',
    VIEW: MySubscriptionGridView,
  },
  SUBSCRIPTION_TO_GRID_VIEW: {
    NAME: 'SUBSCRIPTION_TO_GRID_VIEW',
    VIEW: SubscriptionToGridView,
  },
  PUBLICATION_GRID_VIEW: {
    NAME: 'PUBLICATION_GRID_VIEW',
    VIEW: PublicationGridView,
  },
  ROLE_SECURITY_MANAGE_VIEW: {
    NAME: 'ROLE_SECURITY_MANAGE',
    VIEW: RibbonRoleSecurityManage,
  },
  DASHBOARD: {
    NAME: 'DASHBOARD',
    VIEW: Dashboard,
  },
  HOME_BLANK: {
    NAME: 'HOME_BLANK',
    VIEW: HomeBlank,
  },
  SSO_APPROVAL_MEMBER: {
    NAME: 'SSO_APPROVAL_MEMBER',
    VIEW: SsoApprovalMember,
  },
  SSO_APPROVAL_USER: {
    NAME: 'SSO_APPROVAL_USER',
    VIEW: SsoApprovalUser,
  },
  TICKETING_SYSTEM: {
    NAME: 'TICKETING_SYSTEM',
    VIEW: TicketingSystemRibbon,
  },
  TICKETING_GROUP: {
    NAME: 'TICKETING_GROUP',
    VIEW: TicketingGroupRibbon,
  },
  QAS_REQUIRE_FIELD: {
    NAME: 'QAS_REQUIRE_FIELD',
    VIEW: QasRequireFieldRibbon,
  },
};

// ribbon options
export const RIBBON_OPTIONS = {
  BLANK: {},
  IXONE: {
    label: 'RIVIR',
    value: RIBBON_VIEW.IXONE_VIEW.NAME,
    icon: ShoppingOutlined,
  },

  TRANSITION_PAGE: {
    label: 'Transition Page',
    value: RIBBON_VIEW.TRANSITION_PAGE.NAME,
    icon: ProjectOutlined,
  },
  TRANSITION_PAGE_DETAIL: {
    label: 'Transition Page Detail',
    value: RIBBON_VIEW.TRANSITION_PAGE_DETAIL.NAME,
    icon: ProjectOutlined,
  },
  MFA_VERIFICATION_LOG: {
    label: 'mfa verification log',
    value: RIBBON_VIEW.TRANSITION_PAGE.NAME,
    icon: ProjectOutlined,
  },

  FORM_MANAGE: {
    label: 'Form Manage',
    value: RIBBON_VIEW.FORM_MANAGE.NAME,
    icon: ProjectOutlined,
  },
  FORM_HISTORY: {
    label: 'SPLASH Download History',
    value: RIBBON_VIEW.FORM_HISTORY.NAME,
    icon: ProjectOutlined,
  },
  FORM_HISTORY_ENTITY: {
    label: 'History SPLASH Form Entity',
    value: RIBBON_VIEW.FORM_HISTORY_ENTITY.NAME,
    icon: HistoryOutlined,
  },
  FORM_UPLOAD: {
    label: 'Form Upload',
    value: RIBBON_VIEW.FORM_UPLOAD.NAME,
    icon: ProjectOutlined,
  },
  FORM_EXCEL: {
    label: 'Form EXCEL',
    value: RIBBON_VIEW.FORM_EXCEL.NAME,
    icon: ProjectOutlined,
  },
  FORM_MANAGE_DETAIL: {
    label: 'Form Detail',
    value: RIBBON_VIEW.FORM_MANAGE_DETAIL.NAME,
    icon: ProjectOutlined,
  },

  REQUEST_GRID: {
    label: 'Request',
    value: RIBBON_VIEW.REQUEST_GRID.NAME,
    icon: ProjectOutlined,
  },

  WORKFLOW_GRID_VIEW: {
    label: 'Workflow',
    value: RIBBON_VIEW.WORKFLOW_GRID_VIEW.NAME,
    icon: ProjectOutlined,
  },
  WORKFLOW_ADMINISTRATION_GRID_VIEW: {
    label: 'Workflow Administration',
    value: RIBBON_VIEW.WORKFLOW_ADMINISTRATION_GRID_VIEW.NAME,
    icon: ProjectOutlined,
  },
  WORKFLOW_DETAIL_VIEW: {
    label: 'Workflow',
    value: RIBBON_VIEW.WORKFLOW_DETAIL_VIEW.NAME,
    icon: ProjectOutlined,
  },
  PRODUCT: {
    label: 'Products',
    value: 'products',
    icon: ShoppingOutlined,
  },
  PRODUCT_FOR_MEMBER: {
    label: 'Product For Member',
    value: RIBBON_VIEW.PRODUCT_FOR_MEMBER.NAME,
    icon: ShoppingOutlined,
  },
  PRODUCT_FULL_VIEW: {
    label: 'Product',
    value: RIBBON_VIEW.PRODUCT_FULL_VIEW.NAME,
    icon: ShoppingOutlined,
  },
  PRODUCT_SUBSCRIPTION: {
    label: 'Product',
    value: RIBBON_VIEW.PRODUCT_SUBSCRIPTION.NAME,
    icon: ShoppingOutlined,
  },
  PRODUCT_HISTORY: {
    label: 'History',
    value: RIBBON_VIEW.PRODUCT_HISTORY.NAME,
    icon: ClockCircleOutlined,
  },
  PRODUCT_SHEETS: {
    label: 'SHEETS',
    value: RIBBON_VIEW.PRODUCT_SHEETS.NAME,
    icon: FileTextOutlined,
  },
  PRODUCT_APL: {
    label: 'APL',
    value: RIBBON_VIEW.PRODUCT_APL.NAME,
    icon: ShoppingOutlined,
  },
  PRODUCT_MAPPING: {
    label: 'Mapping',
    value: RIBBON_VIEW.PRODUCT_MAPPING.NAME,
    icon: SwapOutlined,
  },
  PRODUCT_MAPPING_CONFIG: {
    label: 'Mapping Config',
    value: RIBBON_VIEW.PRODUCT_MAPPING_CONFIG.NAME,
    icon: SwapOutlined,
  },
  PRODUCT_APL_DETAIL: {
    label: 'APL Detail',
    value: RIBBON_VIEW.PRODUCT_APL_DETAIL.NAME,
    icon: ShoppingOutlined,
  },
  PRODUCT_SYNDICATION_HISTORY: {
    label: 'Syndication History',
    value: RIBBON_VIEW.PRODUCT_SYNDICATION_HISTORY.NAME,
    icon: HistoryOutlined,
  },
  ASSET: {
    label: 'Assets',
    value: 'assets',
    icon: GoldOutlined,
  },
  ASSET_FULL_VIEW: {
    label: 'Asset',
    value: RIBBON_VIEW.ASSET_FULL_VIEW.NAME,
    icon: GoldOutlined,
  },
  MEMBER: {
    label: 'Member',
    value: 'member',
    icon: TeamOutlined,
  },
  MEMBER_FULL_VIEW: {
    label: 'Member',
    value: RIBBON_VIEW.MEMBER_FULL_VIEW.NAME,
    icon: TeamOutlined,
  },
  MEMBER_COMPANY_FULL_VIEW: {
    label: 'Member',
    value: RIBBON_VIEW.MEMBER_COMPANY_FULL_VIEW.NAME,
    icon: TeamOutlined,
  },
  USER_FULL_VIEW: {
    label: 'User',
    value: RIBBON_VIEW.USER_FULL_VIEW.NAME,
    icon: UserOutlined,
  },
  FOLDER: {
    label: 'Folders',
    value: RIBBON_VIEW.FOLDER_VIEW.NAME,
    icon: FolderOutlined,
  },
  OWNED_FOLDER: {
    label: 'My Folders',
    value: RIBBON_VIEW.OWNED_FOLDER_VIEW.NAME,
    icon: FolderOutlined,
  },
  SHARED_FOLDER: {
    label: 'Folders Shared to Me',
    value: RIBBON_VIEW.SHARED_FOLDER_VIEW.NAME,
    icon: FolderOutlined,
  },
  HELP_MAINTENANCE_CATEGORY: {
    label: 'Help',
    value: RIBBON_VIEW.HELP_MAINTENANCE_CATEGORY_VIEW.NAME,
    icon: QuestionCircleOutlined,
  },
  HELP_POST_FULL_VIEW: {
    label: 'Help',
    value: RIBBON_VIEW.HELP_POST_FULL_VIEW.NAME,
    icon: QuestionCircleOutlined,
  },
  HELP_POST_GRID_VIEW: {
    label: 'Help',
    value: RIBBON_VIEW.HELP_POST_GRID_VIEW.NAME,
    icon: QuestionCircleOutlined,
  },
  HELP_ARTICLES_GRID_VIEW: {
    label: 'Article',
    value: RIBBON_VIEW.HELP_ARTICLES_GRID_VIEW.NAME,
    icon: QuestionCircleOutlined,
  },
  HELP_ARTICLES_FULL_VIEW: {
    label: 'Article',
    value: RIBBON_VIEW.HELP_ARTICLES_FULL_VIEW.NAME,
    icon: QuestionCircleOutlined,
  },
  MAINTENANCE_EULA_GRID_VIEW: {
    label: 'Eula',
    value: RIBBON_VIEW.MAINTENANCE_EULA_GRID_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  MY_SUBSCRIPTION_GRID_VIEW: {
    label: 'My Subscriptions',
    value: RIBBON_VIEW.MY_SUBSCRIPTION_GRID_VIEW.NAME,
    icon: BellOutlined,
  },
  SUBSCRIPTION_TO_GRID_VIEW: {
    label: 'Subscribers',
    value: RIBBON_VIEW.SUBSCRIPTION_TO_GRID_VIEW.NAME,
    icon: BellOutlined,
  },
  MAINTENANCE_EULA_FULL_VIEW: {
    label: 'Eula',
    value: RIBBON_VIEW.MAINTENANCE_EULA_FULL_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  ACCEPTED_EULA_GRID_VIEW: {
    label: 'Accepted Eula',
    value: RIBBON_VIEW.ACCEPTED_EULA_GRID_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  MANAGE_SHARING_GRID_VIEW: {
    label: 'Manage Sharing',
    value: RIBBON_VIEW.MANAGE_SHARING_GRID_VIEW.NAME,
    icon: ShareAltOutlined,
  },
  FOLDER_DETAIL: {
    label: 'Folder',
    value: RIBBON_VIEW.FOLDER_DETAIL.NAME,
    icon: FolderOutlined,
  },
  ADVANCED_SEARCH_VIEW: {
    label: 'Reporting',
    value: RIBBON_VIEW.ADVANCED_SEARCH_VIEW.NAME,
    icon: SettingOutlined,
  },
  REPORTING_VIEW: {
    label: 'Reporting',
    value: RIBBON_VIEW.REPORTING_VIEW.NAME,
    icon: SettingOutlined,
  },
  COMMUNICATION_VIEW: {
    label: 'Template',
    value: RIBBON_VIEW.COMMUNICATION_VIEW.NAME,
    icon: MessageOutlined,
  },
  COMMUNICATION_FULLVIEW: {
    label: 'Template',
    value: RIBBON_VIEW.COMMUNICATION_FULLVIEW.NAME,
    icon: MessageOutlined,
  },
  MEMBER_MAINTENANCE_VIEW: {
    label: 'Member management',
    value: RIBBON_VIEW.MEMBER_MAINTENANCE_VIEW.NAME,
    icon: TeamOutlined,
  },
  USERS_MAINTENANCE_VIEW: {
    label: 'User management',
    value: RIBBON_VIEW.USERS_MAINTENANCE_VIEW.NAME,
    icon: TeamOutlined,
  },
  QUERY_VIEW: {
    label: 'Query',
    value: RIBBON_VIEW.QUERY_VIEW.NAME,
    icon: SettingOutlined,
  },
  ASSET_FOR_MEMBER: {
    label: 'Asset For Member',
    value: RIBBON_VIEW.ASSET_FOR_MEMBER.NAME,
    icon: SnippetsOutlined,
  },
  WORKFLOW_MAINTAIN: {
    label: 'Workflow maintain',
    value: RIBBON_VIEW.WORKFLOW_MAINTAIN.NAME,
    icon: SettingOutlined,
  },
  WF_TEMPLATE_FULL: {
    label: 'Template',
    value: RIBBON_VIEW.WF_TEMPLATE_FULL.NAME,
    icon: SnippetsOutlined,
  },
  MAINTENANCE_MARKUP_GRID_VIEW: {
    label: 'Markup',
    value: RIBBON_VIEW.MAINTENANCE_MARKUP_GRID_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  MAINTENANCE_MARKUP_FULL_VIEW: {
    label: 'Markup',
    value: RIBBON_VIEW.MAINTENANCE_MARKUP_FULL_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  ACCEPTED_MARKUP_GRID_VIEW: {
    label: 'Accepted Markup',
    value: RIBBON_VIEW.ACCEPTED_MARKUP_GRID_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  CURATED_QUERY_GRID_VIEW: {
    label: 'Curated Query',
    value: RIBBON_VIEW.CURATED_QUERY_GRID_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  RECIPIENTS_FIELDS_GRID_VIEW: {
    label: 'Recipients Fields',
    value: RIBBON_VIEW.RECIPIENTS_FIELDS_GRID_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  PUBLICATION_GRID_VIEW: {
    label: 'GDSN Received Product',
    value: RIBBON_VIEW.PUBLICATION_GRID_VIEW.NAME,
    icon: SnippetsOutlined,
  },
  ROLE_SECURITY_MANAGE: {
    label: 'Role & Security',
    value: RIBBON_VIEW.ROLE_SECURITY_MANAGE_VIEW.NAME,
    icon: ShoppingOutlined,
  },
  HOME_BLANK: {
    label: 'Home',
    value: RIBBON_VIEW.HOME_BLANK.NAME,
    icon: ShoppingOutlined,
  },
  DASHBOARD: {
    label: 'Dashboard',
    value: RIBBON_VIEW.DASHBOARD.NAME,
    icon: DashboardOutlined,
  },
  SSO_APPROVAL_MEMBER_GRID_VIEW: {
    label: 'SSO Approval Member',
    value: RIBBON_VIEW.SSO_APPROVAL_MEMBER.NAME,
    icon: TeamOutlined,
  },
  SSO_APPROVAL_USER_GRID_VIEW: {
    label: 'SSO Approval User',
    value: RIBBON_VIEW.SSO_APPROVAL_USER.NAME,
    icon: UserOutlined,
  },
  SSO_APPROVAL_SUPPLIER_GRID_VIEW: {
    label: 'SSO Approval Supplier',
    icon: UserOutlined,
  },
  MINIMUM_REQUIREMENTS: {
    label: 'Minimum Requirements',
    icon: IssuesCloseOutlined,
  },
  EULA_CREDIT_CARD: {
    label: 'Credit Card T&C',
    icon: CreditCardOutlined,
  },
  DATA_SYNC_TIME_TRACKING: {
    label: 'Data Sync Time Tracking',
    icon: FieldTimeOutlined,
  },
  QC_TIME_TRACKING: {
    label: 'Qc Time Tracking',
    icon: FieldTimeOutlined,
  },
  TICKETING_SYSTEM: {
    label: 'Ticketing System',
    value: RIBBON_VIEW.TICKETING_SYSTEM.NAME,
    icon: ProjectOutlined,
  },
  TICKETING_SYSTEM_FULL: {
    label: 'Ticketing System',
    value: RIBBON_VIEW.TICKETING_SYSTEM.NAME,
    icon: ProjectOutlined,
  },
  TICKETING_GROUP: {
    label: 'Ticket Group Assignment',
    value: RIBBON_VIEW.TICKETING_GROUP.NAME,
    icon: UsergroupAddOutlined,
  },
  QA_SPEC_WORKFLOW: {
    label: 'QA SPEC WORKFLOW',
    icon: FieldTimeOutlined,
  },
  QAS_REQUIRE_FIELD: {
    label: 'QAS Required Fields',
    value: RIBBON_VIEW.QAS_REQUIRE_FIELD.NAME,
    icon: ProjectOutlined,
  },
};

export const DEFAULT_OPTIONS = {
  HOME: { label: 'Home', value: 'Home', icon: HomeOutlined },
  // SHARED: { label: 'Shared', value: 'Shared', icon: ShareAltOutlined },
};
// ribbon types
export const RIBBON_TYPES = {
  THUMBNAILS: 'thumbnails',
  LISTVIEW: 'listview',
  TILESVIEW: 'tilesview',
  DETAILSVIEW: 'detailsview',
};

// layout advanced search
export const LAYOUT_ADVANCED_SEARCH = {
  MEMBER: 'member',
  ASSET: 'asset',
  PRODUCT: 'product',
};

export const REPORTING_PANELS = {
  CONFIG: 'config',
  RESULT: 'result',
};

export const REPORTING_VIEW_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create',
};
export const REPORTING_PRIMARY_MODULE_DISPLAYNAME = [
  // 'Member Information',
  'Member',
  'Digital Media',
  'Product',
];
// lorem
export const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fermentum nisi quis libero semper suscipit. Quisque nec purus a nisl commodo aliquet non dignissim tortor. Sed luctus quis libero ornare dictum. Quisque nec eros mauris. Pellentesque fringilla, tellus in finibus maximus, purus eros commodo arcu, id tincidunt orci ante vel felis. Fusce posuere eros ac dapibus varius. Vivamus ullamcorper rutrum sapien, quis tempus nisi volutpat nec. In hac habitasse platea dictumst. Vestibulum at justo quam. Vestibulum aliquet eu erat sit amet sagittis. Duis quis massa ac lorem aliquam consectetur. In fringilla erat ut nisl volutpat, nec mollis leo tempor. Maecenas viverra eros vitae feugiat interdum. Nullam laoreet felis in eros sodales pellentesque. Curabitur tincidunt tortor at felis luctus ultricies.';

export const RETURN_URL_EXCEPTION = ['/notfound', '/timeout', '/500-error'];
export const MAX_SOCIAL_LINK = 7;
export const ENABLE_POST_PROCESS = false; // false to ignore post process
export const DEFAULT_ROUTE = '/dashboard';

export const STRENGTH_PASSWORD = {
  ANY: 'Any',
  WEAK: 'Weak',
  MEDIUM: 'Medium',
  STRONG: 'Strong',
};

export const COLOR_PASSWORD = {
  0: '#DB4437',
  40: '#F4B400',
  80: '#0F9D58',
};

export const PASS_STRENGTH_RANGE = {
  0: STRENGTH_PASSWORD.WEAK,
  40: STRENGTH_PASSWORD.MEDIUM,
  80: STRENGTH_PASSWORD.STRONG,
};

export const VALIDATION_RULES = {
  Any: [],
  Weak: ['6 or more characters.'],
  Medium: [
    'Your password must have 6 or more characters and',
    'has upper and lower letters.',
    'or has upper letters and numbers.',
    'or has lower letters and numbers.',
  ],
  Strong: [
    '8 or more characters.',
    'at least 1 lower letter.',
    'at least 1 upper letter.',
    'at least 1 special character ( !@#$%^&* ).',
  ],
};

export const NEW_GDSN_SUBSCRIPTION = {
  ERROR:
    'Hmm…. The number you entered is not the right length for a GS1 key. Can you try again?',
  WRONG_FORMAT: `You've entered wrong digit format`,
};

// format types
export const FORMAT_TYPE = {
  IMAGE_TYPES: 'IMAGE_TYPES',
  VIDEOS_TYPES: 'VIDEOS_TYPES',
  FILE: 'FILE',
};
// Ppt accept type
export const PPT_ACCEPT_TYPE = 'application/vnd.ms-powerpoint';
export const PPT_ACCEPT_TYPE_NAME = 'ppt';
// Pptx accept type
export const PPTX_ACCEPT_TYPE =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const PPTX_ACCEPT_TYPE_NAME = 'pptx';
// Asset Status
export const ASSET_STATUS_OPTIONS = ['Active', 'Inactive', 'Pending'];
export const ASSET_CREATE_STATUS_OPTIONS = ['Active', 'Pending'];

// Assets Configuration
export const RESOLUTION_OPTIONS = [null, 72, 300];
export const CROP_SIZE_OPTIONS = [null, 0, 50, 100];
export const IMAGE_SIZE_OPTIONS = [
  '50x50',
  '100x100',
  '300x300',
  '500x500',
  '1000x1000',
  'Full-size',
];
// Information sheet options
export const INFORMATION_SHEET_OPTIONS = ['RIVIR Default', 'Member Loaded'];
export const IMAGE_SIZE_OPTIONS_SINGLE_DIGIT = [50, 100, 300, 500, 1000];
export const BACKGROUND_OPTIONS = ['Original', 'White', 'Black', 'Transparent'];
// Asset type field
export const ASSET_FIELD_DOCUMENT = [
  {
    displayName: 'File Name',
    type: 'string',
    element: 'input',
    field: 'fileName',
  },
  { displayName: 'Owner', type: 'string', element: 'text', field: 'owner' },
  {
    displayName: 'File Size',
    type: 'number',
    element: 'text',
    field: 'fileSize',
  },
  {
    displayName: 'File Type',
    type: 'string',
    element: 'text',
    field: 'fileType',
  },
  {
    displayName: 'Page Count',
    type: 'number',
    element: 'text',
    field: 'pageCount',
  },
];
export const ASSET_FIELD_ZIP = [
  {
    displayName: 'File Name',
    type: 'string',
    element: 'input',
    field: 'fileName',
  },
  { displayName: 'Owner', type: 'string', element: 'text', field: 'owner' },
  {
    displayName: 'File Size',
    type: 'number',
    element: 'text',
    field: 'fileSize',
  },
  {
    displayName: 'File Type',
    type: 'string',
    element: 'text',
    field: 'fileType',
  },
];
export const ASSET_FIELD_AUDIO = [
  {
    displayName: 'File Name',
    type: 'string',
    element: 'input',
    field: 'fileName',
  },
  { displayName: 'Owner', type: 'string', element: 'text', field: 'owner' },
  {
    displayName: 'File Size',
    type: 'number',
    element: 'text',
    field: 'fileSize',
  },
  {
    displayName: 'File Type',
    type: 'string',
    element: 'text',
    field: 'fileType',
  },
  {
    displayName: 'Bit Rate',
    type: 'string',
    element: 'input',
    field: 'bitRate',
  },
  {
    displayName: 'Frame Rate',
    type: 'string',
    element: 'input',
    field: 'frameRate',
  },
  {
    displayName: 'Frame Size',
    type: 'string',
    element: 'input',
    field: 'frameSize',
  },
  {
    displayName: 'Sample Rate',
    type: 'string',
    element: 'input',
    field: 'sampleRate',
  },
  {
    displayName: 'Chanel Output',
    type: 'string',
    element: 'input',
    field: 'chanelOutput',
  },
  {
    displayName: 'Media Format',
    type: 'string',
    element: 'input',
    field: 'mediaFormat',
  },
  {
    displayName: 'Duration',
    type: 'string',
    element: 'input',
    field: 'duration',
  },
];
export const ASSET_FIELD_VIDEO = [
  {
    displayName: 'File Name',
    type: 'string',
    element: 'input',
    field: 'fileName',
  },
  { displayName: 'Owner', type: 'string', element: 'text', field: 'owner' },
  {
    displayName: 'File Size',
    type: 'number',
    element: 'text',
    field: 'fileSize',
  },
  {
    displayName: 'File Type',
    type: 'string',
    element: 'text',
    field: 'fileType',
  },
  {
    displayName: 'Bit Rate',
    type: 'string',
    element: 'input',
    field: 'bitRate',
  },
  {
    displayName: 'Frame Rate',
    type: 'string',
    element: 'input',
    field: 'frameRate',
  },
  {
    displayName: 'Frame Size',
    type: 'string',
    element: 'input',
    field: 'frameSize',
  },
  {
    displayName: 'Color Model',
    type: 'string',
    element: 'input',
    field: 'colorModel',
  },
  {
    displayName: 'Fps',
    type: 'string',
    element: 'input',
    field: 'fps',
  },
  {
    displayName: 'Media Format',
    type: 'string',
    element: 'input',
    field: 'mediaFormat',
  },
  {
    displayName: 'Duration',
    type: 'string',
    element: 'input',
    field: 'duration',
  },
];
export const ASSET_FIELD_IMAGE = [
  {
    displayName: 'File Name',
    type: 'string',
    element: 'input',
    field: 'fileName',
  },
  { displayName: 'Owner', type: 'string', element: 'text', field: 'owner' },
  {
    displayName: 'File Size',
    type: 'number',
    element: 'text',
    field: 'fileSize',
  },
  {
    displayName: 'File Type',
    type: 'string',
    element: 'text',
    field: 'fileType',
  },
  {
    displayName: 'Width',
    type: 'number',
    element: 'text',
    field: 'width',
  },
  {
    displayName: 'Height',
    type: 'number',
    element: 'text',
    field: 'height',
  },
  // {
  //   displayName: 'Crop Range',
  //   type: 'number',
  //   element: 'text',
  //   field: 'cropRange',
  // },
  {
    displayName: 'Clipping Path',
    type: 'checkbox',
    element: 'text',
    field: 'clippingPath',
  },
  {
    displayName: 'Color Depth',
    type: 'string',
    element: 'text',
    field: 'colorDepth',
  },
  {
    displayName: 'Resolution',
    type: 'string',
    element: 'text',
    field: 'resolution',
  },
  {
    displayName: 'EXIF Data',
    type: 'string',
    element: 'text',
    field: 'exifData',
  },
];

export const ASSET_VISIBILITY = ['Only If Shared', 'Public', 'Private'];

export const PRIVATE_VISIBILITY_ASSET = ['Only If Shared', 'Private'];

export const APL_VISIBILITY = ['Company', 'Personal'];

export const FOLDER_VISIBILITY = ['Only If Shared', 'Public', 'Private'];

export const PRIVATE_FOLDER_VISIBILITY = ['Only If Shared', 'Private'];

export const STATUS_PRODUCT = [
  { displayName: 'Active', value: 'Active' },
  { displayName: 'Archived', value: 'Archived' },
  { displayName: 'Pending', value: 'Pending' },
  { displayName: 'Discontinued', value: 'Discontinued' },
];

export const IMAGE_TYPE = [
  {
    value: 'Product Image High Resolution',
    label: 'Product Image High Resolution',
  },
  { value: 'Product Image Web', label: 'Product Image Web' },
  {
    value: 'Product Image With Supporting Elements Web',
    label: 'Product Image With Supporting Elements Web',
  },
  {
    value: 'Product Image With Supporting Elements High Resolution',
    label: 'Product Image With Supporting Elements High Resolution',
  },
  { value: 'Product Image 360D3D', label: 'Product Image 360D3D' },
  { value: 'Detail Technology Image', label: 'Detail Technology Image' },
  { value: 'Content Texture', label: 'Content Texture' },
  {
    value: 'Sample Prototype Mockup Image',
    label: 'Sample Prototype Mockup Image',
  },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Application', label: 'Application' },
  { value: 'Size Comparison', label: 'Size Comparison' },
  { value: 'Ambience Mood', label: 'Ambience Mood' },
  { value: 'Mobile Ready Hero Image', label: 'Mobile Ready Hero Image' },
  { value: 'Montage Composite Image', label: 'Montage Composite Image' },
  {
    value: 'Product Packaging Label Information',
    label: 'Product Packaging Label Information',
  },
  { value: 'Sidekick Image', label: 'Sidekick Image' },
  { value: '_3D Rendered', label: '_3D Rendered' },
  { value: 'Optimised Hero Image', label: 'Optimised Hero Image' },
  { value: 'Display', label: 'Display' },
];

export const PACKAGE_LEVEL = [
  { value: 'Unit', label: 'Unit' },
  { value: 'InnerPack', label: 'Inner Pack' },
  { value: 'Case', label: 'Case' },
  { value: 'MasterCase', label: 'Master Case' },
  { value: 'Pallet', label: 'Pallet' },
  { value: 'Display Shipper', label: 'Display Shipper' },
];

export const FACING = [
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: 'Front', label: 'Front' },
  { value: 'Left', label: 'Left' },
  { value: 'Top', label: 'Top' },
  { value: 'Back', label: 'Back' },
  { value: 'Right', label: 'Right' },
  { value: 'Bottom', label: 'Bottom' },
  { value: 'Facing Type', label: 'Facing Type' },
  { value: 'Full Flat', label: 'Full Flat' },
  { value: 'Nutritional Label', label: 'Nutritional Label' },
  { value: 'Barcode', label: 'Barcode' },
  { value: 'Ingredients', label: 'Ingredients' },
  // { value: 'NUT. FACT', label: 'NUT. FACT' },
  {
    value: 'Nutritional Ingredients Combined',
    label: 'Nutritional Ingredients Combined',
  },
  {
    value: 'Marketing Content QR Code',
    label: 'Marketing Content QR Code',
  },
  { value: 'Certification Seals Claims', label: 'Certification Seals Claims' },
  { value: 'Preparation Instructions', label: 'Preparation Instructions' },
  {
    value: 'Petfood Feeding Instructions Ingredients',
    label: 'Petfood Feeding Instructions Ingredients',
  },
  { value: 'Safe Handling Instructions', label: 'Safe Handling Instructions' },
  { value: 'Special Cases', label: 'Special Cases' },
];

export const FACING_ABCDU = [
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: 'Front', label: 'Front' },
  { value: 'Left', label: 'Left' },
  { value: 'Top', label: 'Top' },
  { value: 'Back', label: 'Back' },
  { value: 'Right', label: 'Right' },
  { value: 'Bottom', label: 'Bottom' },
];
export const FACING_EFS3DR = [];
export const FACING_H = [{ value: 'Front', label: 'Front' }];
export const FACING_M = [{ value: 'Facing Type', label: 'Facing Type' }];
export const FACING_L = [
  { value: 'Full Flat', label: 'Full Flat' },
  { value: 'Nutritional Label', label: 'Nutritional Label' },
  { value: 'Barcode', label: 'Barcode' },
  { value: 'Ingredients', label: 'Ingredients' },
  {
    value: 'Nutritional Ingredients Combined',
    label: 'Nutritional Ingredients Combined',
  },
  {
    value: 'Marketing Content QR Code',
    label: 'Marketing Content QR Code',
  },
  { value: 'Certification Seals Claims', label: 'Certification Seals Claims' },
  { value: 'Preparation Instructions', label: 'Preparation Instructions' },
  {
    value: 'Petfood Feeding Instructions Ingredients',
    label: 'Petfood Feeding Instructions Ingredients',
  },
  { value: 'Safe Handling Instructions', label: 'Safe Handling Instructions' },
  { value: 'Special Cases', label: 'Special Cases' },
];

export const ANGLE = [
  { value: 'Centre', label: 'Centre' },
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
  { value: 'No Plunge', label: 'No Plunge' },
  { value: 'Sequence Number', label: 'Sequence Number' },
];
export const ANGLE_ABCDHU = [
  { value: 'Centre', label: 'Centre' },
  { value: 'Left', label: 'Left' },
  { value: 'Right', label: 'Right' },
  { value: 'No Plunge', label: 'No Plunge' },
];
export const ANGLE_FM = [
  { value: 'Sequence Number', label: 'Sequence Number' },
];
export const ANGLE_ELS3DR = [{ value: 'No Plunge', label: 'No Plunge' }];

export const ASSOCIATION_TYPE = {
  PLANOGRAM: 'PLANOGRAM',
  MARKETING: 'MARKETING',
  GENERAL: 'GENERAL',
  DOCUMENT: 'DOCUMENT',
  INFORMATIONAL: 'INFORMATIONAL',
};

export const DAM_TO_PIM_ASSOCIATION_TYPE = [
  { value: 'PLANOGRAM', label: 'PLANOGRAM' },
  { value: 'MARKETING', label: 'MARKETING' },
  { value: 'GENERAL', label: 'GENERAL' },
  { value: 'DOCUMENT', label: 'DOCUMENT' },
  { value: 'INFORMATIONAL', label: 'INFORMATIONAL' },
];

export const DAM_TO_PIM_ASSOCIATION_TYPE_FOR_IMAGE =
  DAM_TO_PIM_ASSOCIATION_TYPE;

export const DAM_TO_PIM_ASSOCIATION_TYPE_FOR_NON_IMAGE = [
  { value: 'MARKETING', label: 'MARKETING' },
  { value: 'DOCUMENT', label: 'DOCUMENT' },
];

// * ALL ASSOCIATION TYPE FOR ADDING PLANOGRAM ASSET TO PIM
export const PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO = [
  { value: 'PLANOGRAM', label: 'PLANOGRAM' },
];

// * ALL ASSOCIATION TYPE FOR ADDING MARKETING ASSET TO PIM
export const PIM_TO_DAM_ASSOCIATION_TYPE_FOR_MARKETING = [
  { value: 'MARKETING', label: 'MARKETING' },
];

// * ALL ASSOCIATION TYPE FOR ADDING ADDITIONAL ASSET TO PIM
export const PIM_TO_DAM_ASSOCIATION_TYPE_FOR_ADDITIONAL = [
  { value: 'GENERAL', label: 'GENERAL' },
];

// * ALL ASSOCIATION TYPE FOR ADDING DOCUMENT ASSET TO PIM
export const PIM_TO_DAM_ASSOCIATION_TYPE_FOR_DOCUMENT = [
  { value: 'DOCUMENT', label: 'DOCUMENT' },
];

export const PACKAGE_LEVELS = [
  'Unit',
  'Inner Pack',
  'Case',
  'Display Shipper',
  'Master Case',
  'Pallet',
];
export const FILTER_TYPE_MEMBERS = [
  {
    value: 'unmatched',
    label: 'Unmatched',
    icon: <WarningOutlined />,
  },
];

export const FILTER_TYPE_PRODUCTS = [
  {
    value: 'subscription',
    label: 'Subscription',
    icon: <SafetyCertificateFilled />,
  },
  {
    value: 'syndication',
    label: 'Syndication',
    icon: <CloudSyncOutlined />,
  },
  {
    value: 'owner',
    label: 'Owner',
    icon: <FilterOutlined />,
  },
  {
    value: 'shared',
    label: 'Shared',
    icon: <ShareAltOutlined />,
  },
  {
    value: 'pending',
    label: 'Pending',
    icon: <ClockCircleOutlined />,
  },
  {
    value: 'active',
    label: 'Active',
    icon: <CheckCircleOutlined />,
  },
  {
    value: 'unmatched',
    label: 'Unmatched',
    icon: <WarningOutlined />,
  },
  {
    value: 'productCategory',
    label: 'Product category',
    icon: <ApartmentOutlined />,
  },
  {
    value: 'brickCodeHierarchy',
    label: 'Product category',
    icon: <ApartmentOutlined />,
  },
  {
    value: 'privateLabel',
    label: 'Private Label',
    icon: <i class='fa-solid fa-handshake'></i>,
  },
];
export const FILTER_TYPE_USER_MAINTENANCE = [
  {
    value: 'locked',
    label: 'Locked Users',
    icon: <LockOutlined />,
  },
];
export const FILTER_TYPE_PRODUCTS_BY_PACKAGE = [
  {
    value: 'Pallet',
    label: 'Pallet',
    icon: <PalletIcon />,
  },
  { value: 'Master Case', label: 'Master Case', icon: <MasterCaseIcon /> },
  { value: 'Display Shipper', label: 'Display Shipper', icon: <CaseIcon /> },
  { value: 'Case', label: 'Case', icon: <CaseIcon /> },
  { value: 'Inner Pack', label: 'Inner Pack', icon: <InnerPackIcon /> },
  { value: 'Unit', label: 'Unit', icon: <UnitIcon /> },
];

export const FILTER_TYPE_ASSETS = [
  {
    value: 'owner',
    label: 'Owner',
    icon: <FilterOutlined />,
  },
  {
    value: 'pending',
    label: 'Pending',
    icon: <ClockCircleOutlined />,
  },
  {
    value: 'asset',
    label: 'Assets',
    icon: <PictureOutlined />,
  },
  {
    value: 'document',
    label: 'Documents',
    icon: <ProfileOutlined />,
  },
  {
    value: 'multimedia',
    label: 'Multimedia',
    icon: <VideoCameraOutlined />,
  },
  {
    value: 'unmatched',
    label: 'Unmatched',
    icon: <WarningOutlined />,
  },
];
export const FILTER_TYPE_WORKFLOW = [
  {
    value: 'active',
    label: 'Active',
    icon: <CheckCircleOutlined />,
  },
  {
    value: 'history',
    label: 'History',
    icon: <ClockCircleOutlined />,
  },
];

export const FILTER_TICKETING_SYSTEM = [
  {
    value: 'assignedToMe',
    label: 'Assigned To Me',
    icon: <FilterOutlined />,
  },
  {
    value: 'following',
    label: 'Following',
    icon: <FilterOutlined />,
  },
  {
    value: 'open',
    label: 'Open',
    icon: <StarOutlined />,
  },
  {
    value: 'pending',
    label: 'Pending',
    icon: <ClockCircleOutlined />,
  },
  {
    value: 'solved',
    label: 'Solved',
    icon: <CheckCircleOutlined />,
  },
];

export const PACKAGING_DISPOSITION = [
  { value: 'In Packaging', label: 'In Packaging' },
  { value: 'Out Of Packaging', label: 'Out Of Packaging' },
  { value: 'Case', label: 'Case' },
  { value: 'Inner Pack', label: 'Inner Pack' },
  { value: 'Raw Uncooked', label: 'Raw Uncooked' },
  { value: 'Prepared', label: 'Prepared' },
  { value: 'Plated', label: 'Plated' },
  { value: 'Styled', label: 'Styled' },
  { value: 'Staged', label: 'Staged' },
  { value: 'Held', label: 'Held' },
  { value: 'Worn', label: 'Worn' },
  { value: 'Used', label: 'Used' },
  { value: 'Family', label: 'Family' },
  { value: 'Open Case', label: 'Open Case' },
  { value: 'Pallet Display', label: 'Pallet Display' },
];
export const PACKAGING_DISPOSITION_AC = [
  { value: 'In Packaging', label: 'In Packaging' },
  { value: 'Out Of Packaging', label: 'Out Of Packaging' },
  { value: 'Case', label: 'Case' },
  { value: 'Inner Pack', label: 'Inner Pack' },
  { value: 'Raw Uncooked', label: 'Raw Uncooked' },
  { value: 'Prepared', label: 'Prepared' },
  { value: 'Open Case', label: 'Open Case' },
  { value: 'Pallet Display', label: 'Pallet Display' },
];
export const PACKAGING_DISPOSITION_EFMLS3DR = [
  { value: 'In Packaging', label: 'In Packaging' },
  { value: 'Case', label: 'Case' },
  { value: 'Inner Pack', label: 'Inner Pack' },
];
export const PACKAGING_DISPOSITION_H = [
  { value: 'In Packaging', label: 'In Packaging' },
  { value: 'Out Of Packaging', label: 'Out Of Packaging' },
];

export const ASSET_TYPE = ['Asset', 'Company', 'Document', 'Multimedia'];

export const ASSET_SUBTYPE = [
  'Ancillary',
  'General',
  'Ecommerce',
  'Panel',
  'Planogram',
];

export const COMPANY_SUBTYPE = [
  'Brochure/ Sell Sheet',
  'General',
  'Logo',
  'Marketing',
  'Presentation',
  'Social Media',
];

export const DOCUMENT_SUBTYPE = [
  'Certificate',
  'Company',
  'General',
  'Company Audit',
  'Product Audit',
  'Mock Recall',
  'HACCP - Food Safety',
  'PFAS',
];

export const QA_SPEC_DOCUMENT_SUBTYPE = [
  'Company',
  'General',
  'Product Audit',
  'Company Audit',
  'Mock Recall',
  'HACCP - Food Safety',
  'PFAS',
];

export const MULTIMEDIA_SUBTYPE = ['Audio', 'Video'];

export const ASSET_PANEL_FIELD = [
  'Amino Acid',
  'Ingredient',
  'Nutritional',
  'Supplement',
  'Other',
];

export const COMPANY_LOGO_FIELD = ['Brand Logo', 'Company Logo'];

export const DOCUMENT_CERTIFICATE_FIELD = [
  'Gluten Free',
  'Halal',
  'Kosher',
  'Non GMO',
  'Organic',
  'ISO',
  'BRC',
  'IFS',
  'Palm Oil',
  'Other',
];

export const MEMBER_CERTIFICATE_FIELD = [
  '(8)a Small Business',
  'B Corp',
  'HUBZone Business',
  'LEED Certification',
  'LGBT Business',
  'Minority Owned',
  'Veteran Owned',
  'Woman Owned',
];

export const DOCUMENT_COMPANY_FIELD = [
  'Brand/ Style Guide',
  'GDSN Requirements',
  'Insurance',
  'Legal',
  'P&P',
  'White Paper',
];

export const ACCEPT_UPLOAD_IMAGE_FILE_TYPE =
  '.png,.jpg,.gif,.tiff,.ico,.svg,.bnp';

export const BROWSER_NOT_SUPPORTED_IMAGE_TYPES = {
  Chrome: ['image/tiff', 'image/jp2', 'image/mng', 'image/heif'],
  Firefox: ['image/tiff', 'image/jp2', 'image/mng', 'image/heif'],
  Safari: ['image/webp', 'image/jp2', 'image/mng'],
};

// Folder Download Configuration Constants
export const FOLDER_DOWNLOAD_TYPE = ['zip', 'rar', 'tar'];
export const FOLDER_DELIVERY_METHOD = ['direct', 'email', 'ftp'];
export const IMAGE_FORMAT_OPTIONS = [
  'ORIGINAL',
  'tif',
  'jpg',
  'png',
  'gif',
  'tga',
];
export const MEMBERS_DATA_FORMAT = ['xls', 'xlsx', 'csv', 'json', 'xml'];
export const QUERIES_DATA_FORMAT = ['xls', 'xlsx', 'csv', 'json', 'xml'];
export const REPORTS_DATA_FORMAT = ['xls', 'xlsx', 'csv', 'json', 'xml'];
// export const PRODUCTS_DATA_FORMAT = ['xls', 'xlsx', 'csv', 'xml'];
export const PRODUCTS_DATA_FORMAT = ['json', 'xls', 'csv'];
export const PRODUCTS_DATA_FORMAT_WITH_GDSN = [
  'xls',
  'xlsx',
  'csv',
  'xml',
  'gdsn',
];

export const PLANOGRAM_TYPE = [
  'Planogram 5/5',
  'Planogram 6/5',
  'Planogram 10',
  'Planogram 11',
  'Planogram 12',
  'Planogram 13',
  'Planogram PDF',
  'Planogram GS1',
  // 'OneDirectory',
  // 'Plano',
  'GS1 Compliant',
];

export const NUTRITION_LABEL_TYPE = [
  // { label: 'Dual Column', value: 'US_FDA_NFP_2020_DUAL_COLUMN' },
  // { label: 'Dual Tabular', value: 'US_FDA_NFP_2020_TABULAR_SMALL_PACKAGES' },
  { label: 'Standard', value: 'US_FDA_NFP_2020_STANDARD' },
  {
    label: 'Standard Side By Side',
    value: 'US_FDA_NFP_2020_STANDARD_SIDE_BY_SIDE',
  },
  {
    label: 'Linear for Small Packages',
    value: 'US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES',
  },
  // { label: 'Tabular', value: 'US_FDA_NFP_2020_TABULAR' },
];

export const DISABLED_LABEL_TYPE = [
  'US_FDA_NFP_2020_TABULAR',
  'US_FDA_NFP_2020_TABULAR_SMALL_PACKAGES',
  'US_FDA_NFP_2020_DUAL_COLUMN',
];

export const PRODUCT_CONFIG = [
  {
    name: 'productUnit',
    option: [
      {
        name: 'productUnitPlanogram',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back'],
      },
      {
        name: 'productUnitMarketing',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back', 'Other'],
      },
      {
        name: 'productUnitAdditionInformation',
        option: [
          'Ancillary',
          'Ecommerce',
          'Panel',
          'Brochure/ Sell Sheet',
          'General',
          'Logo',
          'Presentation',
          'Social media',
          'Audio',
          'Video',
        ],
      },
    ],
  },
  {
    name: 'productDisplayShipper',
    option: [
      {
        name: 'productDisplayShipperPlanogram',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back'],
      },
      {
        name: 'productDisplayShipperMarketing',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back', 'Other'],
      },
      {
        name: 'productDisplayShipperAdditionInformation',
        option: [
          'Ancillary',
          'Ecommerce',
          'Panel',
          'Brochure/ Sell Sheet',
          'General',
          'Logo',
          'Presentation',
          'Social media',
          'Audio',
          'Video',
        ],
      },
    ],
  },
  {
    name: 'productInnerPack',
    option: [
      {
        name: 'productInnerPackPlanogram',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back'],
      },
      {
        name: 'productInnerPackMarketing',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back', 'Other'],
      },
      {
        name: 'productInnerPackAdditionInformation',
        option: [
          'Ancillary',
          'Ecommerce',
          'Panel',
          'Brochure/ Sell Sheet',
          'General',
          'Logo',
          'Presentation',
          'Social media',
          'Audio',
          'Video',
        ],
      },
    ],
  },
  {
    name: 'productCase',
    option: [
      {
        name: 'productCasePlanogram',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back'],
      },
      {
        name: 'productCaseMarketing',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back', 'Other'],
      },
      {
        name: 'productCaseAdditionInformation',
        option: [
          'Ancillary',
          'Ecommerce',
          'Panel',
          'Brochure/ Sell Sheet',
          'General',
          'Logo',
          'Presentation',
          'Social media',
          'Audio',
          'Video',
        ],
      },
    ],
  },
  {
    name: 'productMasterCase',
    option: [
      {
        name: 'productMasterCasePlanogram',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back'],
      },
      {
        name: 'productMasterCaseMarketing',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back', 'Other'],
      },
      {
        name: 'productMasterCaseAdditionInformation',
        option: [
          'Ancillary',
          'Ecommerce',
          'Panel',
          'Brochure/ Sell Sheet',
          'General',
          'Logo',
          'Presentation',
          'Social media',
          'Audio',
          'Video',
        ],
      },
    ],
  },
  {
    name: 'productPallet',
    option: [
      {
        name: 'productPalletPlanogram',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back'],
      },
      {
        name: 'productPalletMarketing',
        option: ['Front', 'Left', 'Right', 'Top', 'Bottom', 'Back', 'Other'],
      },
      {
        name: 'productPalletAdditionInformation',
        option: [
          'Ancillary',
          'Ecommerce',
          'Panel',
          'Brochure/ Sell Sheet',
          'General',
          'Logo',
          'Presentation',
          'Social media',
          'Audio',
          'Video',
        ],
      },
    ],
  },
];

export const OPEN_ITEM = {
  TYPE: {
    ASSET_CONTENT_PANE: 'ASSET_CONTENT_PANE',
    ASSET_FULL_VIEW: 'ASSET_FULL_VIEW',
    MEMBER_CONTENT_PANE: 'MEMBER_CONTENT_PANE',
    MEMBER_FULL_VIEW: 'MEMBER_FULL_VIEW',
    MEMBER_MAINTENANCE_CONTENT_PANE: 'MEMBER_MAINTENANCE_CONTENT_PANE',
    FOLDER_CONTENT_PANE: 'FOLDER_CONTENT_PANE',
    FOLDER_FULL_VIEW: 'FOLDER_FULL_VIEW',
    REPORTING_CONTENT_PANE: 'REPORTING_CONTENT_PANE',
    REPORTING_FULL_VIEW: 'REPORTING_FULL_VIEW',
    PRODUCT_CONTENT_PANE: 'PRODUCT_CONTENT_PANE',
    PRODUCT_FULL_VIEW: 'PRODUCT_FULL_VIEW',
    PRODUCT_APL_CONTENT_PANE: 'PRODUCT_APL_CONTENT_PANE',
    REQUIRED_FIELD_CONTENT_PANE: 'REQUIRED_FIELD_CONTENT_PANE',
    QA_SPECIFICATION: 'QA_SPECIFICATION',
  },
  MODE: {
    EDIT: 'EDIT',
    VIEW: 'VIEW',
  },
  KEY: {
    ASSET_VIEW: 'ASSET_VIEW',
    MEMBER_FULL_VIEW: 'MEMBER_FULL_VIEW',
    REPORTING_VIEW: 'REPORTING_VIEW',
    PRODUCT_VIEW: 'PRODUCT_VIEW',
    PANEL_VIEW: 'PANEL_VIEW',
    FOLDER_VIEW: 'FOLDER_VIEW',
  },
};

export const OPEN_ITEM_CLASS = 'open-item--editting';

export const DEFAULT_SORT = [{ fieldName: 'id', isAscending: false }];

export const PASSWORD_RESET = [
  { value: null, label: 'No' },
  { value: 30, label: '30 Days' },
  { value: 60, label: '60 Days' },
  { value: 90, label: '90 Days' },
  { value: 180, label: '180 Days' },
];

export const PASSWORD_STRENGTH = [
  { value: 'Any', label: 'Any' },
  { value: 'Weak', label: 'Weak' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Strong', label: 'Strong' },
];

export const LANDING_PAGE = [
  { value: null, label: 'None' },
  { value: 'digital-media', label: 'Digital-Media' },
  { value: 'folders', label: 'Folders' },
  { value: 'members', label: 'Members' },
  { value: 'products', label: 'Products' },
  { value: 'reportings', label: 'Reportings' },
];

export const USER_PERMISSION = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Account manager', label: 'Account manager' },
  { value: 'Accounting department', label: 'Accounting department' },
];

export const GTIN_UPC12 = [
  { value: 'GTIN', label: 'GTIN' },
  { value: 'UPC12', label: 'UPC12' },
];

export const MARKET_REGION = [
  { value: 'Region 1', label: 'Region 1' },
  { value: 'Region 2', label: 'Region 2' },
  { value: 'Region 3', label: 'Region 3' },
];

export const HIDE_CLOSE_DETAIL_BUTTON = [
  '/products/main',
  '/members/main',
  '/digital-media/main',
  '/reportings/main',
  '/folders/main',
];

export const DISABLED_CLOSE_DETAIL_BUTTON = [
  '/favorite/queries',
  'favorite/sharedqueries',
  '/favorite/favorite-products',
  '/favorite/favorite-assets',
  '/favorite/favorite-members',
  '/favorite/favorite-folders',
  // '/products',
  '/products/newitems',
  '/products/apl',
  '/products/syndication',
  // '/members',
  // '/digital-media',
  '/digital-media/assets',
  '/digital-media/documents',
  '/digital-media/multi-media',
  // '/reportings',
  '/reportings/owned',
  '/reportings/shared',
  '/programs',
  '/folders',
  // '/folders/owned',
  '/folders/shared',
  '/maintenance/help/category',
  '/maintenance/help/post',
  '/maintenance/eula/list',
  '/maintenance/eula/accepted-list',
  '/maintenance/member-management',
  '/maintenance/markup/list',
  '/maintenance/markup/accepted-list',
];

export const WEEK_DAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const MONTH_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'Octorber',
  'November',
  'December',
];

export const DAY_LIST = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

export const ORDER_LIST = ['First', 'Second', 'Third', 'Fourth', 'Last'];

export const supportPhoneFormats = [
  '18001234567',
  '1 800 123 4567',
  '+1 800 123-4567',
  '+1 (800) 123 4567',
  '+86 800 123 45678',
  '+86 800 123 4567',
  '+86 800 123 456',
  '1-800-123-4567',
  '1 (800) 123-4567',
  '(800)123-4567',
  '(800) 123-4567',
  '(800)1234567',
  '800-123-4567',
];

//* Old regex contact phone
//* /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{2,3}[\s.-]?\d{4}$/gm;

export const REGEX_CONTACT_PHONE =
  /^(?![*|":<>[\]{}`\\.';@&$-])[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{2,3}[\s.-]?\d{3,5}$/m;

export const REGEX_SYMBOL = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const LANGUAGE_LIST = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bihari languages',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan, Valencian',
  'Central Khmer',
  'Chamorro',
  'Chechen',
  'Chichewa, Chewa, Nyanja',
  'Chinese',
  'Church Slavonic, Old Bulgarian, Old Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi, Dhivehi, Maldivian',
  'Dutch, Flemish',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'French',
  'Fulah',
  'Gaelic, Scottish Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Gikuyu, Kikuyu',
  'Greek (Modern)',
  'Greenlandic, Kalaallisut',
  'Guarani',
  'Gujarati',
  'Haitian, Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua (International Auxiliary Language Association)',
  'Interlingue',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Kinyarwanda',
  'Komi',
  'Kongo',
  'Korean',
  'Kwanyama, Kuanyama',
  'Kurdish',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Letzeburgesch, Luxembourgish',
  'Limburgish, Limburgan, Limburger',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Moldovan, Moldavian, Romanian',
  'Mongolian',
  'Nauru',
  'Navajo, Navaho',
  'Northern Ndebele',
  'Ndonga',
  'Nepali',
  'Northern Sami',
  'Norwegian',
  'Norwegian Bokmål',
  'Norwegian Nynorsk',
  'Nuosu, Sichuan Yi',
  'Occitan (post 1500)',
  'Ojibwa',
  'Oriya',
  'Oromo',
  'Ossetian, Ossetic',
  'Pali',
  'Panjabi, Punjabi',
  'Pashto, Pushto',
  'Persian',
  'Polish',
  'Portuguese',
  'Quechua',
  'Romansh',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Serbian',
  'Shona',
  'Sindhi',
  'Sinhala, Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sotho, Southern',
  'South Ndebele',
  'Spanish, Castilian',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur, Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volap_k',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang, Chuang',
  'Zulu',
];

export const MEMBER_LAYOUT = {
  OVERVIEW: 'overview',
  DETAILS: 'details',
  CONTACTS: 'contacts',
  BILLING: 'billing',
  SYSTEM: 'system',
  PREVIEW: 'preview',
};

export const NAME_PREFIX = ['Mr', 'Mrs', 'Miss', 'Ms'];

export const MEMBER_PUBLIC_STATUS_OPTIONS = [
  {
    value: 'Public',
    label: 'Public',
  },
  {
    value: 'Private',
    label: 'Private',
  },
];

export const MEMBER_PUBLIC_GENDER_OPTIONS = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const SHOWCASE_TEXT_HELPFUL = [
  'Drag and drop to re-order the list in showcase. Default 10 first assets will be showed on the carousel showcase',
  'To set showcase, click on the show showcase checkbox when creating a new asset or edit the asset, and status of asset is Active, Visiblity is Public',
];

export const SHOWCASE_TEXT =
  'The cat fell over the dog and chickens have a tendency to lean, so let eat green paint and make the monkey wash the car';

export const MEMBER_EDIT_PREVIEW_INSTRUCTION = {
  TAG_LINE: {
    title: 'Editing Company Overview',
    content:
      "One small piece of your business plan is the company overview (also known as company information or a company summary). It is an essential part of a business plan. Let's start your overview with an Elevator Pitch!",
  },
  BACKGROUND: {
    title: 'Editing Company Preview Background',
    content:
      'Company preview background is the first impression a visitor has of your company. A good background sets the tone for your company preview without distracting from its content. It’s always visible but it doesn’t stand out. It should grab your audience’s attention and keep them intrigued to find out more about your business.',
  },
  CONTACT: {
    title: 'Editing Company Contacts',
    content:
      'By setting certain public contacts as marketing , you can control the number of your contacts info in preview. It gives your customer a quick and easy access to any selected contacts.',
  },
};

export const alternativeCompanyBackgroundUrl =
  '/api/assets/member-editor-asset/EditorAsset/a599741e-f9ad-49e1-9522-a509522fe856/ThumbSizeFull/49850b37-64fc-4fea-a677-b59d43de3f56_hinh-background-dep.jpg';

export const alternativeBackgroundConfiguration = {
  left: 0,
  top: 726,
  width: 2100,
  height: 363,
};

// ? MARKDOWN CONSTANTS

export const mdFontList = [
  {
    text: 'Default',
    value: '',
  },
  {
    text: 'Georgia',
    value: 'georgia',
  },
  {
    text: 'Sans serif',
    value: 'sans-serif',
  },
];

export const mdTextTypeList = [
  {
    text: 'Paragraph',
    value: 'p',
  },
  {
    text: 'Phrasing',
    value: 'span',
  },
];

export const PhrasingSamples = [
  {
    head: ['(#color=green|font=tahoma)', { lineBreak: false }],
    text: ['your text', { lineBreak: false }],
    tail: ['(~)', { lineBreak: false }],
  },
  {
    head: ['(#color=green)', { lineBreak: false }],
    text: ['your text', { lineBreak: false }],
    tail: ['(~)', { lineBreak: false }],
  },
  {
    head: ['(#font=tahoma)', { lineBreak: false }],
    text: ['your text', { lineBreak: false }],
    tail: ['(~)', { lineBreak: false }],
  },
  {
    head: ['(#font=tahoma)', { lineBreak: true }],
    text: [
      'your text',
      {
        lineBreak: true,
        help: [`Don't accept consecutive line breaks in your text`],
      },
    ],
    tail: ['(~)', { lineBreak: false }],
  },
];

export const ParagraphSamples = [
  {
    head: ['(!p|color=green|font=tahoma)', { lineBreak: true }],
    text: ['your paragraph content', { lineBreak: false }],
  },
  {
    head: ['(!p|color=green)', { lineBreak: true }],
    text: ['your paragraph content', { lineBreak: false }],
  },
  {
    head: ['(!p|font=tahoma)', { lineBreak: true }],
    text: ['your paragraph content', { lineBreak: false }],
  },
];

export const TEXT_CONFIG_DEFAULT = 'rbga(255,255,255,1)';
export const BACKGROUND_TEXT_CONFIG_DEFAULT = 'rbga(0,0,0,0.3)';

export const PRODUCT_BACKGROUND_TOOLTIP =
  'Only images with a Adobe Photoshop clipping path or a transparent background will be affected by the background selection.';
export const PRODUCT_CROPSIZE_TOOLTIP =
  'Setting the crop size will remove the unwanted outer areas from a photographic or illustrated image.';

export const ARTICLE_KEYS = [
  'RIVIR.company.profile.showcase',
  'RIVIR.ixone.default',
];

export const ENABLE_COUNTRY = [
  'US',
  'FR',
  'ES',
  'PT',
  'DE',
  'IN',
  'IT',
  'RU',
  'IR',
  'CN',
];

export const CUSTOM_LABELS = {
  US: 'English',
  FR: 'French',
  ES: 'Spanish',
  PT: 'Portuguese',
  DE: 'German',
  IN: 'Hindil',
  IT: 'Italian',
  RU: 'Russian',
  IR: 'Persian',
  CN: 'Chinese',
};

export const memberManagementFeatures = [
  'accessMember',
  'accessProducts',
  'accessDigitalAssets',
  'accessFolders',
  'accessReporting',
  'accessGDSN',
  'accessFTP',
  'accessInterops',
  'accessSyndication',
  'accessChat',
  // 'accessQaSpec',
  'accessWorkflow',
  // 'accessTicketSystem',
  // BP 11/19/2021: remove 3 options
  // 3 options do not have requirements
  /* 'accessVideoConf',
  'accessVideoStreaming',
  'accessThirdPartyAPI', */
  // end BP 11/19/2021: remove 3 options
];
export const GTIN_MAX_LENGTH = 14;

export const MEMBER_EXECUTE_TEMPLATE_METHOD = [
  {
    name: 'Text',
    icon: () => <span>T</span>,
    toolTip: 'Text',
  },
  {
    name: 'Mail',
    icon: MailOutlined,
    toolTip: 'Email',
  },
  {
    name: 'Video',
    icon: PlaySquareOutlined,
    toolTip: 'Video',
  },
  {
    name: 'Chat',
    icon: MessageOutlined,
    toolTip: 'Chat',
  },
];

export const MEMBER_SYSTEM_EDIT_FORM_LAYOUT = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

export const API_SERVICE_CALL_PAYLOAD_OPTIONS = ['JSON'];

export const USER_PROFILE_STATUS = ['Active', 'Pending', 'Inactive', 'Expired'];

export const DRAG_DEFINE = [
  {
    type: 'member',
    namePath: 'memberName',
    dragIcon: TeamOutlined,
    detailLink: ROUTE.MEMBER,
    confirmImage: 'thumb300',
  },
  {
    type: 'asset',
    namePath: 'assetName',
    dragIcon: PictureOutlined,
    detailLink: ROUTE.ASSET,
    confirmImage: 'thumbSize300',
  },
  {
    type: 'Product',
    namePath: 'productName',
    dragIcon: RestOutlined,
    detailLink: ROUTE.PRODUCTS,
    confirmImage: 'thumbnail',
  },
  //* REPORTING
  {
    type: 'Reporting',
    advType: 'Member',
    namePath: 'name',
    dragIcon: ReconciliationOutlined,
    confirmImage: MemberReport,
    detailLink: ROUTE.REPORTINGS,
  },
  {
    type: 'Reporting',
    advType: 'DigitalMedia',
    namePath: 'name',
    dragIcon: ReconciliationOutlined,
    confirmImage: AssetReport,
    detailLink: ROUTE.REPORTINGS,
  },
  //* QUERY
  {
    type: 'Reporting',
    namePath: 'name',
    dragIcon: ReconciliationOutlined,
    confirmImage: ReportQuery,
  },
  {
    type: 'Folder',
    namePath: 'name',
    dragIcon: FileSearchOutlined,
    confirmImage: FolderQuery,
  },
  {
    type: 'Asset',
    namePath: 'name',
    dragIcon: FileSearchOutlined,
    confirmImage: AssetQuery,
  },
  {
    type: 'Member',
    namePath: 'name',
    dragIcon: FileSearchOutlined,
    confirmImage: MemberQuery,
  },
  {
    type: 'Query',
    namePath: 'name',
    dragIcon: FileSearchOutlined,
    confirmImage: ReportQuery,
  },
];

export const GS1_DOWNLOAD_FORMAT = ['TIFF', 'JPEG', 'PNG', 'GIF', 'TGA'];

export const ASSET_UPLOAD_TYPE = {
  UPLOAD_LOCAL_FILE: {
    name: 'UPLOAD_LOCAL_FILE',
    params: null,
    optionName: <FormattedMessage {...Messages.localSelectOption} />,
    title: <FormattedMessage {...Messages.titleSelectLocalFile} />,
  },
  UPLOAD_ADV_FTP_FILE: {
    name: 'UPLOAD_ADV_FTP_FILE',
    params: 'AdvFTP',
    optionName: <FormattedMessage {...Messages.advfptSelectOption} />,
    title: <FormattedMessage {...Messages.titleSelectAdvFtpFile} />,
  },
  UPLOAD_URL: {
    name: 'UPLOAD_URL',
    params: null,
    optionName: 'From URL',
    title: null,
  },
  UPLOAD_FTP_FILE: {
    name: 'UPLOAD_FTP_FILE',
    params: 'FTP',
    optionName: <FormattedMessage {...Messages.fptSelectOption} />,
    title: <FormattedMessage {...Messages.titleSelectFtpFile} />,
    disabled: true,
  },
  UPLOAD_DROP_BOX_FILE: {
    name: 'UPLOAD_DROP_BOX_FILE',
    params: 'Dropbox',
    optionName: <FormattedMessage {...Messages.dropboxSelectOption} />,
    title: <FormattedMessage {...Messages.titleSelectDropboxFile} />,
  },
  UPLOAD_SAGE_TREE_FILE: {
    name: 'UPLOAD_SAGE_TREE_FILE',
    params: 'SageTree',
    optionName: <FormattedMessage {...Messages.sageTreeSelectOption} />,
    title: <FormattedMessage {...Messages.titleSelectSageTreeFile} />,
    disabled: true,
  },
};

export const ASSET_UPLOAD_METHOD_DEFINE = {
  DEFAULT: [
    ASSET_UPLOAD_TYPE.UPLOAD_LOCAL_FILE.name,
    ASSET_UPLOAD_TYPE.UPLOAD_ADV_FTP_FILE.name,
    ASSET_UPLOAD_TYPE.UPLOAD_URL.name,
    ASSET_UPLOAD_TYPE.UPLOAD_DROP_BOX_FILE.name,
  ],
  SINGLE_ASSET_CREATION: [
    ASSET_UPLOAD_TYPE.UPLOAD_LOCAL_FILE.name,
    ASSET_UPLOAD_TYPE.UPLOAD_ADV_FTP_FILE.name,
    ASSET_UPLOAD_TYPE.UPLOAD_URL.name,
    ASSET_UPLOAD_TYPE.UPLOAD_DROP_BOX_FILE.name,
  ],
  MULTIPLE_ASSET_CREATION: [
    ASSET_UPLOAD_TYPE.UPLOAD_LOCAL_FILE.name,
    ASSET_UPLOAD_TYPE.UPLOAD_ADV_FTP_FILE.name,
    ASSET_UPLOAD_TYPE.UPLOAD_DROP_BOX_FILE.name,
  ],
};

export const pathnameFavMember = '/favorite/favorite-members';
export const pathnameFavAssets = '/favorite/favorite-assets';
export const pathnameFavFolders = '/favorite/favorite-folders';
export const pathnameFavProducts = '/favorite/favorite-products';

export const pathnameProduct = '/products';
export const pathnameMember = '/members';
export const pathnameDigitalMedia = '/digital-media';
export const pathnameDigitalMediaNew = '/digital-media/new';
export const pathnameAssets = '/digital-media/assets';
export const pathnameDocument = '/digital-media/documents';
export const pathnameMultiMedia = '/digital-media/multi-media';

export const pathnameReportings = '/reportings';
export const pathnameReportingsOwned = '/reportings/owned';
export const pathnameReportingsShared = '/reportings/shared';

export const pathnameFolders = '/folders';
export const pathnameFoldersOwned = '/folders/owned';
export const pathnameFoldersShared = '/folders/shared';
export const pathnameAssetsForMember = '/member-assets';
export const pathnameProductsForMember = '/member-products';

export const MAX_LEVEL_EXPAND_TREE = 16;

export const PROGRESSION_LIST = [
  { key: 'Initiation', color: '#dedede', order: 0, name: 'Initiation' },
  { key: 'Warning', color: '#ef892c', order: 1, name: '75% Warning' },
  { key: 'Overdue', color: '#bad235', order: 2, name: 'Overdue' },
  { key: 'Expire', color: '#ed4141', order: 3, name: 'Expire' },
  { key: 'Completion', color: '#157a3d', order: 4, name: 'Completion' },
  // { key: 'ReOpen', color: '#007bee', order: 5 },
  // { key: 'Repeat', color: '#aa1f70', order: 6 },
];

export const WF_STEP_RESULT_TYPE = {
  Int: 'number',
  Decimal: 'number',
  String: 'text',
  DateTime: 'date',
  Bool: 'boolean',
  Binary: 'file',
};
const WORKFLOW_TEMPLATE = 'WORKFLOW_TEMPLATE';
const STEP = 'STEP';
const PROGRESSION = 'PROGRESSION';
const TASK = 'TASK';
const ADD_NEW_STEP = 'ADD_NEW_STEP';
const ADD_NEW_TASK = 'ADD_NEW_TASK';

export const WORKFLOW_TEMPLATE_RIGHT_VIEWS = {
  WORKFLOW_TEMPLATE: WORKFLOW_TEMPLATE,
  STEP: STEP,
  PROGRESSION: PROGRESSION,
  TASK: TASK,
  ADD_NEW_STEP: ADD_NEW_STEP,
  ADD_NEW_TASK: ADD_NEW_TASK,
};

export const WORKFLOW_STEP_STATUS = {
  AUTO_COMPLETE: 'AutoComplete',
  OPEN: 'Open',
  ACTIVE: 'Active',
  ASSIGNED: 'Assigned',
  CANCELLED: 'Cancelled',
  WAITING_FOR_APPROVE: 'WaitingForApprove',
  APPROVING: 'Approving',
  COMPLETED: 'Completed',
  EXPIRED: 'Expired',
};

export const MESSAGE_TYPE = {
  0: 'text',
  1: 'email',
  2: 'file',
  3: 'image',
};

export const GOOGLE_MAP_API_KEY = 'AIzaSyCUts2zNDniW6aRIiAVAVamFSYJknzfEWA';

export const CHATTYPE = {
  COMPANY: 'Company',
  USER: 'User',
};

export const PRODUCT_VIEW = {
  OVERVIEW: 'overview',
  HIERARCHY: 'hierarchy',
  SUBCRIPTION: 'subscription',
  DETAILVIEW: 'detail',
  SYNDICATION: 'mapping',
  QA_SPEC: 'qa-specification',
  QA_EVALUATION_FORM: 'evaluation',
  VERSIONS: 'versions',
};

export const HIERARCHY_LEVEL = {
  PALLET: {
    NAME: 'Pallet',
    KEY: 'pallet',
    INDEX: 0,
  },
  MASTERCASE: {
    NAME: 'Master Case',
    KEY: 'masterCase',
    INDEX: 1,
  },
  DISPLAY_SHIPPER: {
    NAME: 'Display Shipper',
    KEY: 'displayShipper',
    INDEX: 2, //* equal with Case
  },
  CASE: {
    NAME: 'Case',
    KEY: 'case',
    INDEX: 2,
  },
  INNERPACK: {
    NAME: 'Inner Pack',
    KEY: 'innerPack',
    INDEX: 3,
  },
  UNIT: {
    NAME: 'Unit',
    KEY: 'unit',
    INDEX: 4,
  },
};

export const INIT_ADDING_HIERARCHY_LEVELS = [
  {
    index: 0,
    key: HIERARCHY_LEVEL.PALLET.KEY,
    packageLevel: HIERARCHY_LEVEL.PALLET.NAME,
    productItemId: null,
    parentProductItemId: null,
  },
  {
    index: 1,
    key: HIERARCHY_LEVEL.MASTERCASE.KEY,
    packageLevel: HIERARCHY_LEVEL.MASTERCASE.NAME,
    productItemId: null,
    parentProductItemId: null,
  },
  {
    index: 2,
    key: HIERARCHY_LEVEL.CASE.KEY,
    packageLevel: HIERARCHY_LEVEL.CASE.NAME,
    productItemId: null,
    parentProductItemId: null,
    equalWith: ['case', 'displayShipper'],
  },
  {
    index: 3,
    key: HIERARCHY_LEVEL.INNERPACK.KEY,
    packageLevel: HIERARCHY_LEVEL.INNERPACK.NAME,
    productItemId: null,
    parentProductItemId: null,
  },
  {
    index: 4,
    key: HIERARCHY_LEVEL.UNIT.KEY,
    packageLevel: HIERARCHY_LEVEL.UNIT.NAME,
    productItemId: null,
    parentProductItemId: null,
  },
];

export const EVENT = {
  RELOAD_PRODUCT_HIERARCHY: 'reloadProductHierarchy',
  RELOAD_MAPPING_SUPPORTED_MEMBERS: 'reloadMappingSupportMembers',
  DASHBOARD_LAYOUT: 'dashboardLayout',
  EMAIL_EDITOR_HIGHLIGHT_TAG: 'email-editor-highlight-tag',
  EMAIL_EDITOR_HIGHLIGHT_ERROR_TAG: 'email-editor-highlight-error-tag',
  EMAIL_EDITOR_GET_HTML: 'email-editor-get-html',
  EMAIL_EDITOR_GET_HTML_SUCCESS: 'email-editor-get-html-success',
  EMAIL_EDITOR_CLEAR_SELECTED_SECTION: 'email-editor-clear-selected-section',
  EMAIL_EDITOR_REMOVE_SECTION: 'email-editor-remove-section',
  SAVE_TRANSITION_PAGE: 'save-transition-page',
  EXPORT_PRODUCT_PROPERTIES_TRANSFER: {
    toLeft: 'EXPORT_PRODUCT_PROPERTIES_TRANSFER_LEFT',
    toRight: 'EXPORT_PRODUCT_PROPERTIES_TRANSFER_RIGHT',
    getExportedPropertyFieldName: 'get-export-properties-field-name',
    getExportedPropertyFieldNameCallback:
      'get-export-properties-field-name-callback',
  },
  RELOAD_CUSTOM_PROPERTIES_GRID: 'reload-custom-properties-grid',
  TOGGLE_PRESS_ENTER_EVENT_LISTENER: 'toggle-press-enter-event-listener',
  RELOAD_REPORTING_RESULT_GRID: 'reload-reporting-result-grid',
  RELOAD_MANAGE_QUERY_TABLE: 'reload-manage-query-table',
  REPORTING_SAVE_ERROR: 'reporting-save-error',
  RUN_REPORTING_RESULT: 'reporting-run-result',
  ACCEPT_CARD_EULA: 'accept-card-eula',
  RELOAD_CATEGORY: 'reload-category',
  BACK_FOLDER_GRID_TO_HOME: 'back-folder-grid-to-home',
  CANCEL_EDIT_TICKET_EVENT: 'CANCEL_EDIT_TICKET',
  SAVE_TICKET_EVENT: 'SAVE_TICKET_EVENT',
  SAVE_TICKET_COMMENT_DONE_EVENT: 'SAVE_TICKET_COMMENT_DONE_EVENT',
  RELOAD_DOC_GRID_WHEN_ACCEPT_WORKFLOW: 'reload-grid-when-accept-workflow',
};

export const imageType = [
  'jpg',
  'jpeg',
  'jfif',
  'png',
  'gif',
  'webp',
  'tiff',
  'tif',
  'psd',
  'bmp',
  'svg',
  'ai',
  'ico',
];

export const SPREADSHEET_FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const PIM_ASSET_TYPE_DEFINE = [
  'Planogram',
  'Additional',
  'Marketing',
  'Document',
  'Certificate',
];

export const MAIN_PAGE_OPTIONS = [
  {
    value: 'assets',
    label: 'Digital Assets',
  },
  { value: 'members', label: 'Members' },
  {
    value: 'products',
    label: 'Products',
  },
  {
    value: 'folders',
    label: 'Folders',
  },
  {
    value: 'reportings',
    label: 'Reportings',
  },
];

export const MAIN_PAGE_TYPE = {
  DIGITAL_ASSET: {
    name: 'DIGITAL_ASSET',
    route: ROUTE.DIGITAL_MEDIA,
    gridName: 'digital-asset-detail-grid',
    pageName: 'assets',
  },
  PRODUCT: {
    name: 'PRODUCT',
    route: ROUTE.PRODUCTS,
    pageName: 'products',
    gridName: 'product-detail-grid',
  },
  REPORTING: {
    name: 'REPORTING',
    route: ROUTE.REPORTINGS,
    gridName: 'query-reporting-grid',
    //? check bug if avail
    pageName: 'reportings',
  },
  MEMBER: {
    name: 'MEMBER',
    route: ROUTE.MEMBER,
    pageName: 'members',
    gridName: 'member-detail-grid',
  },
  FOLDER: {
    name: 'FOLDER',
    route: ROUTE.FOLDERS,
    pageName: 'folders',
    gridName: 'folder-detail-grid',
  },
};

export const CURATED_QUERY_GRID_NAME = {
  ASSET: 'digital-asset-detail-grid',
  PRODUCT: 'product-detail-grid',
  REPORTING: 'query-reporting-grid',
  MEMBER: 'member-detail-grid',
  FOLDER: 'folder-detail-grid',
};

export const MAPPING_FORMAT_TYPE = ['XLS', 'XML', 'JSON'];

export const MAPPING_DELIVERY_METHODS = [
  'Download',
  'API Pull',

  // 'API Push',
  // 'Interop Push',
];

export const MEMBER_TYPES = [
  'Retailer',
  'Distributor',
  'Broker',
  'Manufacturer',
  // 'SUPPLIER',
  // 'ASSOCIATED',
];

export const MEMBER_TYPE_RETAILER = 'Retailer';
export const MEMBER_TYPE_MANUFACTURER = 'Manufacturer';
export const MEMBER_TYPE_SHARED_ONLY = 'Shared Only';
export const MEMBER_TYPE_PRIVATE_MEMBER = 'private member';
export const MEMBER_TYPE_SHARED_ONLY_MEMBER = 'shared only';

// TODO: RIVIR PUSH, INTEROP PUSH are implemented after
export const SYNDICATION_METHOD = {
  enableRivirPull: 'RIVIR PULL',
  enableRivirAlert: 'RIVIR ALERT',
  // enableGDSN: 'GDSN',
  // enableRivirPush: 'RIVIR PUSH',
  // enableInteropPush: 'INTEROP PUSH',
};

export const NUMERIC_TYPE = ['int32', 'int64', 'double', 'decimal', 'long'];

export const MAPPING_TYPE = [
  {
    value: 'Simple',
    label: 'Simple',
  },
  {
    value: 'StaticValue',
    label: 'Static',
  },
  {
    value: 'Expression',
    label: 'Expression',
  },
];

export const MAPPING_REQUIRED = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Warning',
    label: 'Warning',
  },
  {
    value: 'Strict',
    label: 'Strict',
  },
];

export const RIVIR_ADMIN_ROLE = 'RIVIR Member Admin';

export const GRID_FILTER_TYPE = {
  contains: 'In',
};

export const SHOW_SEND_GDSN_PARTY_REGISTER_BUTTON = [
  'None',
  'Register Error',
  'Unregistered',
];
export const SHOW_PARTY_UNREGISTER_BUTTON = ['Register Success'];

export const MEMBER_PERMISSION_CATEGORY_DEFINE = {
  MEM: {
    permission: ['VIEW_PUBLIC_MEMBERS', 'VIEW_PUBLIC_MEMBERS_IN_MANUFACTURER'],
  },
  PIM: {
    permission: [
      'VIEW_PUBLIC_PRODUCTS',
      'VIEW_OWNED_PRODUCTS',
      'VIEW_SHARED_PRODUCTS',
      'VIEW_PUBLIC_PRODUCTS_OF_MANUFATURER_ROLE',
    ],
  },
  DAM: {
    permission: [
      'VIEW_PUBLIC_ASSETS',
      'VIEW_OWNED_ASSETS',
      'VIEW_SHARED_ASSETS',
      'VIEW_PUBLIC_ASSETS_OF_MANUFATURER_ROLE',
    ],
  },
};

export const PERMISSION_CATEGORY_DEFINE = {
  'Super Administrator': {
    permission: [
      'SUPER ADMIN',
      'VIEW ALL ENTITIES IN SYSTEM',
      'EDIT ALL ENTITIES IN SYSTEM',
    ],
  },

  'Members group permissions': {
    permission: [
      'VIEW MANAGE MEMBER',
      'CREATE MEMBER',
      'EDIT MEMBER',
      'DELETE MEMBERS',
      'VIEW ACTIVITY LOG OF USER',
      'IMPERSONATE PERMISSION',
      'VIEW MEMBER DEFINED PROPERTIES',
      'EDIT MEMBER DEFINED PROPERTIES',
      'MANAGE MEMBER CATEGORIES',
    ],
  },

  'Company group permissions': {
    permission: [
      'EDIT COMPANY PROFILE',
      'VIEW FULL COMPANY PROFILE',
      'VIEW SYSTEM TAB IN COMPANY PROFILE',
      'MANAGE BAN LIST',
      'CHAT CALL VIDEO VIA COMPANY ACCOUNT',
      'VIEW COMMUNICATION TEMPLATE',
      'MANAGE COMMUNICATION TEMPLATE',
      'EXECUTE COMMUNICATION TEMPLATE',
    ],
  },
  // BP 09/01/2022 - ppppp
  // 'Card group permissions': {
  // 	permission: ['PAY WITH MEMBER CREDIT CARD', 'MANAGE MEMBER CREDIT CARD'],
  // },

  'Card group permissions': {
    permission: [
      'PAY WITH MEMBER CREDIT CARD',
      'MANAGE MEMBER CREDIT CARD',
      'MANAGE MEMBER BILLING',
    ],
  },

  'Billable tracking permissions': {
    permission: ['MANAGE BILLABLE TRACKING'],
  },

  'Member (view only)': {
    permission: ['VIEW MEMBERS'],
  },

  'Reporting group permissions': {
    permission: [
      'VIEW REPORTING',
      'CREATE REPORTING',
      'EDIT REPORTING',
      'DELETE REPORTING',
    ],
  },

  'Product Management': {
    permission: [
      'VIEW PRODUCTS',
      'CREATE PRODUCT',
      'EDIT PRODUCT',
      'DELETE PRODUCTS',
      'MANAGE PIM MAPPING',
      'EDIT BRAND',
      'ASSIGN PRODUCT CATEGORY',
    ],
  },
  // 'QA Specification Management': {
  //   permission: ['VIEW QA SPECIFICATION', 'EDIT QA SPECIFICATION'],
  // },

  // 'Evaluation Form Management': {
  //   permission: ['VIEW EVALUATION FORM', 'EDIT EVALUATION FORM'],
  // },

  'Asset group permissions': {
    permission: ['VIEW ASSETS', 'CREATE ASSETS', 'EDIT ASSET', 'DELETE ASSETS'],
  },

  'Folder group permissions': {
    permission: [
      'VIEW FOLDERS',
      'CREATE FOLDER',
      'EDIT FOLDER',
      'DELETE FOLDERS',
    ],
  },

  'User group permissions': {
    permission: [
      'VIEW USERS',
      'CREATE USER',
      'EDIT USER',
      'DELETE USERS',
      'EDIT USERS PERMISSIONS',
      'SEND CONTACT CARD',
      'SEND EMAIL',
      'SEND MESSAGE',
      'SEND TEXT',
    ],
  },

  'Document Writer': {
    permission: [
      'VIEW HELP CATEGORIES',
      'MANAGE HELP CATEGORY',
      'EDIT USER',
      'VIEW HELP POSTS',
      'MANAGE HELP POST',
      'VIEW EULAS',
      'MANAGE EULAS',
      'VIEW ARTICLES',
      'MANAGE ARTICLES',
      'VIEW HELP ASSETS',
      'MANAGE HELP ASSETS',
    ],
  },

  'Share group permissions': {
    permission: ['VIEW SHARED ENTITIES', 'MANAGE SHARED ENTITIES'],
  },

  'Landing Page group permissions': {
    permission: ['MANAGE MAIN PAGE', 'MANAGE CURATED QUERY'],
  },

  'Chat & Video call group permissions': {
    permission: ['CHAT CALL VIDEO VIA PERSONAL ACCOUNT'],
  },
  // 'Ticket System group permissions': {
  //   permission: [
  //     'VIEW TICKET',
  //     'CREATE TICKET',
  //     'EDIT TICKET',
  //     'DELETE TICKET',
  //     'MANAGE TICKET GROUP',
  //   ],
  // },
  // 'QA Specification Workflow': {
  //   permission: ['MANAGE QA SPEC WORKFLOW'],
  // },
};

export const BACK_TO_PRODUCT_OVERVIEW = [
  'product/detail',
  'product/hierarchy',
  'product/subscription',
  'product/versions',
  'product/syndication',
  'product/sheets',
];

export const TAG_COLORS = ['#2a9d8f', '#e76f51', '#277da1'];

export const RIVIR_FIELDS = [
  {
    name: 'UPC8',
    value: 'Upc8',
  },
  {
    name: 'UPC10',
    value: 'Upc10',
  },
  {
    name: 'UPC11',
    value: 'Upc11',
  },
  {
    name: 'UPC12',
    value: 'Upc12',
  },
  {
    name: 'UPC13',
    value: 'Upc13',
  },
];

export const MEETING_STATUS = {
  SCHEDULED: 'Scheduled',
  ON_GOING: 'OnGoing',
  CLOSED: 'Closed',
};

export const DISABLE_SHOW_PREVIEW_IN_HOME_RIBBON = ['/folder/'];

export const CIC_REASON = [
  {
    statusCode: 'CIC019',
    displayText: 'Missing chemical ingredients information.',
    //  "The item is missing required information on chemical ingredients."
  },

  {
    statusCode: 'CIC020',
    displayText: 'Incorrect or outdated chemical ingredients information.',
    //  "The chemical ingredients information received is incorrect or outdated for the trade item."
  },

  {
    statusCode: 'CIC100',
    displayText: 'Attribute Analysis Requested',
    // "Data Recipient is requesting further review of specific attribute(s)."
  },

  {
    statusCode: 'CIC101',
    displayText: 'Wrong CIN Publication Type',
    //  "The message Publication Type was not as expected by Data Recipient."
  },

  {
    statusCode: 'CIC102',
    displayText: 'Unable to Synchronize',
    // "Data Recipient is not able to synchronize data."
  },

  {
    statusCode: 'CIC103',
    displayText: 'Missing GTIN in Item Hierarchy',
    //  "Data Recipient has identified a missing GTIN level(s) of the published hierarchy.",
  },

  {
    statusCode: 'CIC104',
    displayText: 'Required Attribute Information for Data Recipient Missing',
    //  "Data Recipient has identified missing attribute(s) that are mandatory for their specific GDSN implementation."
  },

  {
    statusCode: 'CIC200',
    displayText: 'Incorrect Context',
    // "The data sent to the recipient has been sent with an incorrect product context (wrong context for GPC brick)."
  },

  {
    statusCode: 'CIC999',
    displayText: 'Free Form Text to be populated by the Data Recipient',
    // "The Data Recipient is providing a free-form text explanation for the Confirmation Status Code
    //  they have returned to the Data Source or are providing information on additional issues that cannot be identified
    //  with a specific Confirmation Status Code. Possible Resolution:
    //   If further explanation is required, the Data Source should contact the Data Recipient."
  },
].sort((a, b) => (a.displayText > b.displayText ? 1 : -1));
export const CIC_REASON_FREE_FORM = {
  statusCode: 'CIC999',
  displayText: 'Free Form Text to be populated by the Data Recipient',
};

export const ALL_ALLERGENS = [
  'milk',
  'egg', // eggs
  'fish',
  'shellfish',
  'treenuts',
  'wheat',
  'peanuts',
  'soybeans', // soy
  'sesame',
  'crustaceans',
];

//* ========================================================================
//* CLOSE DETAIL

//* step 1: create close detai stack:
//* * define stack with:
//* - key: stack name
//* - value: url of first page in stack
export const CLOSE_DETAIL_STACK_TYPE_BY_PATHNAME = {
  asset: [
    '/digital-media',
    '/digital-media/assets',
    '/digital-media/documents',
    '/digital-media/multi-media',
    '/favorite/favorite-assets',
  ],
  member: ['/members', '/favorite/favorite-members'],
  product: ['/products', '/favorite/favorite-products'],
  folder: [
    '/folders',
    '/folders/owned',
    '/folders/shared',
    '/favorite/favorite-folders',
  ],
  reporting: ['/reportings', '/reportings/owned', '/reportings/shared'],
  query: ['/favorite/queries', '/favorite/sharedqueries'],
  apl: ['/products/apl'],
  mapping: ['/products/mappings'],
  workflow: ['/workflows'],
  workflowMaintenance: ['/workflow-maintenance/definition'],
  memberMaintenance: ['/maintenance/member-management'],
  memberMaintenanceUser: ['/maintenance/member-management'],
  userMaintenance: ['/maintenance/user-management'],
  transitionPage: ['/maintenance/transition-page'],
  ssoApprovalMember: ['/sso-approval/member'],
  companyUserDetail: ['/company'],
  maintenanceForm: ['/maintenance/form'],
  maintenanceRequirements: ['/maintenance/maintenance-requirements'],
  memberProduct: ['/member-products'],
  memberAsset: ['/member-assets'],
  markup: ['/maintenance/markup/list'],
  ticketingSystem: ['/ticketing-system'],
};

//* step 2: define regex to check detail page: when reload detail page, detail stack will be created, closing detail will redirect to head url defined in step 3
//* note: in regex "|" mean "or"
export const CLOSE_DETAIL_DETAIL_STACK = {
  memberDetail: /^\/company(\/edit)?\/\d+$/,
  productDetail:
    /^\/product(\/(detail|hierarchy|subscription|syndication-history|versions|qa-specification|evaluation))?\/(\d+)(\?.*|)/,
  productVersion: /^\/product\/versions\/\d+$/,
  folderDetail: /^\/folder\/\d+$/,
  assetDetail: /^\/asset\/\d+$/,
  reportingDetail: /^\/reporting\/(\d+|create)$/,
  mappingDetail: /^\/products\/mapping\/config\/\d+$/,
  aplDetail: /^\/products\/apl\/\d+$/,
  workflowDetail: /^\/workflow$/,
  workflowMaintenance: /\/workflow-maintenance\/definition\/\d+$/,
  memberAsset: /^\/member-assets/,
  memberMaintenance:
    /^\/security\/member|ticket-group-assignment\/member\/\d+$/,
  memberMaintenanceUser: /^\/maintenance\/member-management\/\d+$/,
  transitionPageDetail: /^\/maintenance\/transition-page\/(\d+|new)$/,
  memberEdit: /^\/member-management-company\/\d+$/,
  companyUserDetail: /^\/user\/\d+$/,
  maintenanceFormDetail:
    /^\/maintenance\/form\/(excel\/\d+|word\/\d+|powerpoint\/\d+)$/,
  memberProduct: /^\/member-products/,
  markup: /^\/maintenance\/markup\/(list\/\d+|create)$/,
  ticketingDetail: /^\/ticketing-system\/\d+$/,
};

//* step 3: define url to go back from detail page when reloading detail page (usually it is grid page):
export const CLOSE_DETAIL_HARD_URL = {
  memberDetail: '/members',
  productDetail: '/products',
  productVersion: '/products',
  folderDetail: '/folders',
  assetDetail: '/digital-media',
  reportingDetail: '/reportings',
  mappingDetail: '/products/mapping',
  aplDetail: '/products/apl',
  workflowDetail: 'workflows',
  workflowMaintenance: '/workflow-maintenance/definition',
  memberMaintenance: '/maintenance/member-management',
  memberMaintenancaUser: '/maintenance/member-management',
  transitionPageDetail: '/maintenance/transition-page',
  maintenanceFormDetail: '/maintenance/form',
  memberEdit: '/maintenance/member-management',
  memberProduct: '/members',
  memberAsset: '/members',
  markup: '/maintenance/markup/list',
  ticketingDetail: '/ticketing-system',
};

const assetDetailStack = [
  /^(\/(asset\/(versions\/\d+|create)))|(history-splash-form\/DAM\/\d+)$/,
];
const memberDetailStack = [
  /^\/((products|assets)\/member)|(user-profile)|(member-management-company)|(history-splash-form\/MEM)\/\d+$/,
  /^\/(asset|product|history-splash-form\/MEM|member-products?memberId)\/\d+$/,
  /(^\/asset\/create$)|(^\/product\/(detail|hierarchy|subscription|syndication-history|versions)\/\d+$)/,
];
const productDetailStack = [
  /^\/(product(\/(detail|hierarchy|subscription|syndication-history|versions|qa-specification|evaluation))?|history-splash-form\/PIM)\/\d+$/,
];
const folderDetailStack = [
  /^\/(product|asset|company|reporting)\/\d+$/,
  /(^\/product\/(detail|hierarchy|subscription|syndication-history|versions)\/\d+$)|(^\/((products|assets)\/member)|(user-profile)\/\d+$)|(^\/asset\/(versions\/\d+|create)$)/,
];

const assetGridStack = [
  /^\/(asset(\/versions)?|history-splash-form\/DAM)\/(\d+|create)|(\/manage-shared)$/,
  ...assetDetailStack,
];
const productGridStack = [
  /^\/(product|product\/versions|history-splash-form\/PIM)\/\d+$/,
];

//* step 4: define a array to check whether url is in current stack or not
//* - key: stack name
//* - regex array: the first regex is the second page of stack, because the first url is the starting page of stack so it was checked when creating stack - step 1
export const CLOSE_DETAIL_STACK_REGEX = {
  asset: assetGridStack,
  memberAsset: assetGridStack,

  member: [
    /^\/(company|history-splash-form\/MEM)\/\d+|(\/manage-shared)$/,
    ...memberDetailStack,
  ],

  product: productGridStack,
  memberProduct: productGridStack,

  reporting: [
    /^\/reporting\/(\d+|create)|(\/manage-shared)$/,
    ...productDetailStack, //? need to update when supporing open asset/member
  ],
  reportingDetail: productDetailStack,
  query: [/^\/manage-shared$/],

  folder: [
    /^\/(folder\/\d+|manage-shared|(product|asset|company|reporting)\/\d+)$/,
    ...folderDetailStack,
  ],
  apl: [/^\/products\/apl\/\d+$/, /^\/product\/\d+$/],
  mapping: [/^\/products\/mapping\/config\/\d+$/],
  workflow: [/^\/workflow$/],
  workflowMaintenance: [/\/workflow-maintenance\/definition\/\d+$/],
  companyUserDetail: [CLOSE_DETAIL_DETAIL_STACK.companyUserDetail],
  memberMaintenance: [
    /^\/(security\/member|member-management-company|maintenance\/member-management|ticket-group-assignment\/member)\/\d+$/,
    /^\/user\/\d+$/,
  ],
  userMaintenance: [
    /^\/(security\/member|user)\/\d+$/,
    /^\/security\/role\/\d+\/member\/\d+\/edit$/,
  ],
  memberMaintenanceUser: [/^\/user\/\d+$/],
  transitionPage: [CLOSE_DETAIL_DETAIL_STACK.transitionPageDetail],
  ssoApprovalMember: [CLOSE_DETAIL_DETAIL_STACK.memberEdit],

  assetDetail: assetDetailStack,
  folderDetail: folderDetailStack,
  memberDetail: memberDetailStack,

  maintenanceForm: [CLOSE_DETAIL_DETAIL_STACK.maintenanceFormDetail],
  markup: [CLOSE_DETAIL_DETAIL_STACK.markup],
  ticketingSystem: [/^\/ticketing-system\/\d+$/],
};

export const SWITCH_DETAIL_STACK_REGEX = {
  memberProduct: /^\/member-products/,
  memberAsset: /^\/member-assets/,
};

export const CLOSE_DETAIL_SAFE_ROUTE = ['/my-profile', '/company'];

export const SAMPLE_REQUEST_TEMPLATE = `eyJST09UIjp7InR5cGUiOiJkaXYiLCJpc0NhbnZhcyI6dHJ1ZSwicHJvcHMiOnt9LCJkaXNwbGF5TmFtyi9jdXN0b23GIGhpZGRlbiI6ZmFsc2UsIm5vZGVzIjpbIkp3eVZWZV9YclkiLCJkUXZRQTUwbGRLIiwidmQ1MjR2NzVEMiIsInBUYkdmNEtEX04iLCI5VzF3RkQzVXJqIiwiM1NYeHlnamFzWiIsIjV4SEMxUnFCbmsiXSwibGlua2VkTsZre319LMxu6QDceyJyZXNvbHZlZOcAvUVtYWlsRWRpdG9yU2VuZGVySW5mbyJ97AD/5wDG6AEAInNldHRpbmfFDGJvbGTJIml0YWxpY8kPdcRPTGluZckSZm9udFNpemUiOjE0LCJzaG935AEWZGF0ZSIsIm7kAJEsImNvbXBhbnkiLCJhZGRyZXNz5ADdY29sb3IiOiIjMzMzIuQA3O4BguYAuiDlALvuAYpwYXJlbnQiOuYB4/kBmvUBQOsBof8BQOYBQEdyZWXkARn+AT50ZXjkAJBEZWFyIiz/AUz/AUzrAUz/ASHpAJvrAR4idGHEeFsicmVjaXBpZW50xS1d/wE2/wE27ALK/wE25gE2RnJlZVTkART/ATblATb/ATL/ATL/ATLtATJDb2505AEE9AExcHJvamVj5gEvLCJudW1iZXJTYW1wbP8BPv8BPuQAhesD+/8BPuYBPlByb2R1Y3RSZXF1ZXN0ZWT/AUbmAUbHNXMgyTc6/wFZ/wFZ/wFZ7QFZx33KfP8Dsv8BPu0BPusFLP8BPucE8mhpcHBpbmdU/wTy5wE4xC8gc+UB6XMgdG//ATX/ATX2BQxz5wCS5wJcyA9D6QUVyBJB5gUdMdITMv8FMeUFMccz5QUzcm1hdGlvbvUC6/8AkP8AkOgAkP8B1/8B1+wG9v8B1+YB10FkZGnkANJhbP8Gzf8Gzf8Bwf8C9u0BceoAk/8Bc1vkBvfsAL7/AT3/AT3tCCb/AT3nAxRpZ25pbmdPZmb/AxTmAxRSZWdhcmRzLP8DDP8BS/8BS+4CvOYAoSDkAKL/BDv/ASfqASd9`;
export const SAMPLE_REQUEST_BLANK_TEMPLATE = `eyJST09UIjp7InR5cGUiOiJkaXYiLCJpc0NhbnZhcyI6dHJ1ZSwicHJvcHMiOnt9LCJkaXNwbGF5TmFtyi9jdXN0b23GIGhpZGRlbiI6ZmFsc2UsIm5vZGVzIjpbXSwibGlua2VkTsYRe319fQ==`;

export const EMAIL_CONTENT_SHORT_CODE = '[{rivir_email_content}]';
export const EMAIL_HTML_TEMPLATE = `<!DOCTYPE html>
<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">

<head>
	<title></title>
	<meta charset="UTF-8">
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<style>
    * {
			box-sizing: border-box;
			margin: 0;
			padding: 0;
		}
		p {
			line-height: inherit
		}
	</style>
</head>
<body>
${EMAIL_CONTENT_SHORT_CODE}
</body>

</html>`;

export const APL_SAMPLE_REQUEST_TEMPLATE_LIST = [
  {
    id: 1,
    name: 'Sample Request',
    type: 'sample',
    temp: SAMPLE_REQUEST_TEMPLATE,
  },
  {
    id: 2,
    name: 'Blank',
    type: 'blank',
    temp: SAMPLE_REQUEST_BLANK_TEMPLATE,
    disabled: true,
  },
];

export const APL_SAMPLE_REQUEST_DYNAMIC_CONTENT = {
  recipientName: { required: true, visible: true },
  projectName: { required: false, visible: true },
  numberSample: { required: false, visible: true },
  shippingName: { required: true, eye: true, visible: true },
  shippingCompany: { required: true, eye: true, visible: true },
  shippingAddress1: { required: true, eye: true, visible: true },
  shippingAddress2: { eye: true, visible: true },
  additionalInfo: { required: true, trash: true, visible: true },
};

export const EMAIL_EDITOR_BLANK_CONTENT_PLACEHOLDER = 'Input content here';

export const STATUS_REQUEST = [
  'New',
  'Developing',
  'Review',
  'Done',
  'Expired',
  'Deleted',
  'Closed',
];

export const NEW_MEMBER_ROLE_FORM = {
  roleName: '',
  roleDescription: '',
  permissionList: [],
  roleId: 'new',
};

export const DOWNLOAD_NEW_ITEM_TYPE = [
  'FRESH_THYME',
  'GFS',
  'UNFI',
  'UNFI_RFI',
  'CANTEEN_FOOD_BUY',
  'DPI',
];

export const TRANSITION_PAGE_WRAP_KEY = 'transition-page';

export const TRANSITION_PAGE = {
  STATUS: {
    ACTIVE: 'Active',
    PENDING: 'Pending',
    ARCHIVED: 'Archived',
  },
  CONTAINER_SIZE: {
    width: 1366,
    height: 520,
  },
  PREVIEW_SIZE_LIST: [
    {
      ID: 1,
      WIDTH: 1920,
      HEIGHT: 1080,
      REAL_HEIGHT: 950,
    },
    {
      ID: 2,
      WIDTH: 1366,
      HEIGHT: 768,
      REAL_HEIGHT: 660,
    },
  ],
};

export const TAG_RANDOM_COLORS = ['#0070cc', '#10ac84', '#ffb142'];

export const RECENT_SEARCH_STORE_DEFAULT = {
  products: [],
  digitalAssets: [],
  members: [],
};

export const UPLOAD_MAX_SIZE = {
  GENERAL: 250,
};

export const DEBOUNCE_TIME = {
  GENERAL: 300,
};

export const DEFAULT_FIELDS_GRID_PRODUCTS = [
  'id',
  'productName',
  'productDescription',
  'ownerName',
  'shareable',
  'gtin',
  'upc12',
  'status',
  'isOwner',
  'subscription',
  'brandName',
  // 'sharedSupplierNames',
];

export const DEFAULT_PRODUCT_COLUMNS = [
  'productThumbnail',
  'stateIcons',
  'productDescription',
  'isOwner',
  'subscription',
  'subscriptionShield',
  'isFavorited',
  'shareable',
  'productName',
  'ixOneId',
  'ownerName',
  'category1Name',
  'category2Name',
  'category3Name',
  'category4Name',
  'brandName',
  // 'sharedSupplierNames',
  'status',
  'gtin',
  'upc12',
  'isPrivateLabel',
];

export const CUSTOM_FIELD_PATH = 'customFields';

export const TYPE_ERROR_MESSAGE_SYNDICATION = ['REJECTED', 'ERROR', 'REVIEW'];

export const STATUS_REACTIVE_USER_MEMBER = ['DELETED', 'EXPIRED'];

export const additionalRequirementData = {
  Product: [
    {
      isCheck: false,
      isInput: true,
      fieldDisplayName: 'Planogram Image',
      fieldName: 'product_PlanoImage',
    },
    {
      isCheck: false,
      isInput: true,
      fieldDisplayName: 'Additional Image',
      fieldName: 'product_AdditionalImage',
    },
    {
      isCheck: false,
      isInput: true,
      fieldDisplayName: 'Marketing Image',
      fieldName: 'product_MarketingImage',
    },
    {
      isCheck: false,
      isInput: true,
      fieldDisplayName: 'Document',
      fieldName: 'product_Document',
    },
    {
      isCheck: false,
      isInput: true,
      fieldDisplayName: 'Total Image',
      fieldName: 'product_AnyImage',
    },
    {
      isCheck: false,
      isInput: false,
      fieldDisplayName: 'Product Brand',
      fieldName: 'product_Brand',
    },
    {
      isCheck: false,
      isInput: false,
      fieldDisplayName: 'At least one UPC/GTIN field',
      fieldName: 'product_UpcGtin',
    },
  ],
  Member: [
    {
      isCheck: false,
      isInput: false,
      fieldDisplayName: 'Primary Contact',
      fieldName: 'member_PrimaryContact',
    },
    {
      isCheck: false,
      isInput: false,
      fieldDisplayName: 'Primary Address',
      fieldName: 'member_PrimaryAddress',
    },
    {
      isCheck: false,
      isInput: false,
      fieldDisplayName: 'Member Logo',
      fieldName: 'member_MemberLogo',
    },
    {
      isCheck: false,
      isInput: true,
      fieldDisplayName: 'Member Brands',
      fieldName: 'member_MemberBrands',
    },
    {
      isCheck: false,
      isInput: false,
      fieldDisplayName: 'GLNs',
      fieldName: 'member_Glns',
    },
  ],
  Asset: [],
};
export const STATUS_FILTER_TAG = { NEW: 'NEW', PENDING: 'PENDING' };

export const OFFSET_X_UNMATCHED = { offsetXHight: 65, offsetXLow: 22 };
export const OFFSET_X_UNMATCHED_MEMBER = { offsetXHight: 82, offsetXLow: 39 };

export const BOOLEAN = [true, false];

export const BROKER_PERMISSION_LIST = [
  {
    groupName: 'memberGroup',
    permissions: [
      { displayName: 'viewMemberInfo', value: 'VIEW_FULL_COMPANY_PROFILE' },
      { displayName: 'editMemberInfo', value: 'EDIT_COMPANY_PROFILE' },
    ],
  },
  {
    groupName: 'productGroup',
    permissions: [
      { displayName: 'viewProduct', value: 'VIEW_PRODUCTS' },
      { displayName: 'editProduct', value: 'EDIT_PRODUCT' },
      {
        displayName: 'assignProductCategory',
        value: 'ASSIGN_PRODUCT_CATEGORY',
      },
    ],
  },
  {
    groupName: 'assetGroup',
    permissions: [
      { displayName: 'viewAsset', value: 'VIEW_ASSETS' },
      { displayName: 'editAsset', value: 'EDIT_ASSET' },
    ],
  },
  {
    groupName: 'folderGroup',
    permissions: [
      { displayName: 'viewFolder', value: 'VIEW_FOLDERS' },
      { displayName: 'editFolder', value: 'EDIT_FOLDER' },
    ],
  },
  {
    groupName: 'reportingGroup',
    permissions: [
      { displayName: 'viewReporting', value: 'VIEW_REPORTING' },
      { displayName: 'editReporting', value: 'EDIT_REPORTING' },
    ],
  },
  // {
  //   groupName: 'qaSpecificationGroup',
  //   permissions: [
  //     { displayName: 'viewQaSpecification', value: 'VIEW_QA_SPECIFICATION' },
  //     { displayName: 'editQaSpecification', value: 'EDIT_QA_SPECIFICATION' },
  //     { displayName: 'manageQaWorkflow', value: 'MANAGE_QA_SPEC_WORKFLOW' },
  //   ],
  // },
  // {
  //   groupName: 'evaluationFormGroup',
  //   permissions: [
  //     { displayName: 'viewEvaluationForm', value: 'VIEW_EVALUATION_FORM' },
  //     { displayName: 'editEvaluationForm', value: 'EDIT_EVALUATION_FORM' },
  //   ],
  // },
];

export const DEFAULT_SUPPLIER_PERMISSION_LIST = ['VIEW_PRODUCTS'];

export const SUPPLIER_PERMISSION_LIST = [
  {
    groupName: 'productItemManagement',
    permissions: [
      { displayName: 'viewProduct', value: 'VIEW_PRODUCTS', disabled: true },
      { displayName: 'editProduct', value: 'EDIT_PRODUCT' },
    ],
  },
];

export const PUBLISH_TYPES = {
  NEW: { value: 'New', label: 'New' },
  REPUBLISH: { value: 'Republish', label: 'Republish' },
  INITIAL: { value: 'Initial Load', label: 'Initial Load' },
};

export const ASSET_VIRTUAL_SCROLL_HEIGHT = {
  multiImport: 290,
  singleImport: 335,
  xlHeight: 500,
};

export const TYPE_VIEW_ASSET = {
  CREATE_ASSET: 'create-asset',
  REPLACE_ASSET: 'replace-asset',
  REPLACE_THUMBNAIL: 'replace-thumbnail',
};

export const SUPPORT_IMAGES_TYPES = ['jpeg', 'png', 'jpg', 'tiff', 'tif'];

export const SUPPORT_COMMON_TYPES = [
  'jpeg',
  'png',
  'jpg',
  'tiff',
  'tif',
  'mp4',
  'mov',
  'avi',
  'mp3',
  'zip',
  'rar',
  'txt',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'bmp',
  'gif',
  'webm',
  'csv',
];

export const QA_SPEC_EXPAND_STYLE = {
  TAB_HEIGHT: 66,
  GRID_GAP: 3,
};

export const PRODUCT_DETAIL_EXPAND_STYLE = {
  TAB_HEIGHT: 44,
  GRID_GAP: 3,
};

export const QA_SPEC_TABLE_HEIGHT = {
  NON_EXPAND: 270,
  EXPAND: 330,
};

export const ITEM_TYPE = {
  all: ['query', 'asset', 'product', 'folder'],
  query: ['query'],
  notHaveDetailView: ['query'],
};

export const ENTITY_TYPE = {
  FOLDER: 'folder',
  PRODUCT: 'product',
  ASSET: 'asset',
  REPORTING: 'reporting',
};

export const PRODUCT_REQUIRED_GRID_COLUMN = [
  {
    fieldName: 'type',
  },
  {
    fieldName: 'isOwner',
  },
  //* for publication
  {
    fieldName: 'status',
  },
  {
    fieldName: 'subscriptionShield',
  },
  {
    fieldName: 'gdsnShield',
  },
  {
    fieldName: 'syndication',
  },
  {
    fieldName: 'visibility',
  },
];

//* thesẽ columns is all get-product-detail-grid (thumbview) data fields
export const PRODUCT_DEFAULT_GRID_COLUMN = [
  {
    fieldName: 'id',
  },
  {
    fieldName: 'type',
  },
  {
    fieldName: 'ownerId',
  },
  {
    fieldName: 'ownerName',
  },
  {
    fieldName: 'isOwner',
  },
  {
    fieldName: 'brandId',
  },
  {
    fieldName: 'brandName',
  },
  {
    fieldName: 'thumbnail',
  },
  {
    fieldName: 'productName',
  },
  {
    fieldName: 'productType',
  },
  {
    fieldName: 'productDescription',
  },
  {
    fieldName: 'packageLevel',
  },
  {
    fieldName: 'status',
  },
  {
    fieldName: 'visibility',
  },
  {
    fieldName: 'upc12',
  },
  {
    fieldName: 'gtin',
  },
  {
    fieldName: 'targetMarket',
  },
  {
    fieldName: 'rivirId',
  },
  {
    fieldName: 'primarySize',
  },
  {
    fieldName: 'primarySizeUOM',
  },
  {
    fieldName: 'segmentName',
  },
  {
    fieldName: 'created',
  },
  {
    fieldName: 'lastModified',
  },
  {
    fieldName: 'subscription',
  },
  {
    fieldName: 'subscriptionShield',
  },
  {
    fieldName: 'syndication',
  },
  {
    fieldName: 'isFavorited',
  },
  {
    fieldName: 'shareable',
  },
  {
    fieldName: 'editable',
  },
  {
    fieldName: 'gdsnShield',
  },
  {
    fieldName: 'doNotGdsnSync',
  },
  {
    fieldName: 'publishConfigTargetGln',
  },
  {
    fieldName: 'publishConfigTargetMarket',
  },
  {
    fieldName: 'category1Name',
  },
  {
    fieldName: 'category2Name',
  },
  {
    fieldName: 'category3Name',
  },
  {
    fieldName: 'category4Name',
  },
  {
    fieldName: 'isPrivateLabel',
  },
  {
    fieldName: 'memberShareOuts',
  },
];

export const DOWNLOAD_FOLDER_PANEL_STEP = {
  NONE: 0,
  DIRECT: 1,
  SCHEDULED: 2,
};

export const LOCKED_ACCOUNT_MESSAGE =
  'Your account has been locked due to multiple unsuccessful login attempts.';

export const KEY_COPY_PROPERTIES = {
  HEADER_ONLY: [
    'noHeader.ownerId',
    'noHeader.brandId',
    'TradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation.brandName',
    'noHeader.productName',
    'noHeader.primarySize',
    'noHeader.primarySizeUOM',
    'noHeader.upc8',
    'noHeader.upc10',
    'noHeader.upc11',
    'noHeader.upc12',
    'noHeader.upc13',
    'noHeader.gtin',
    'noHeader.brickCode',
    'noHeader.brickCodeName',
    'noHeader.targetMarket',
  ],
  SAME_SIZE: [
    'noHeader.primarySize',
    'noHeader.primarySizeUOM',
    'noHeader.height',
    'noHeader.width',
    'noHeader.depth',
    'noHeader.weight',
    'noHeader.dimensionUOM',
    'noHeader.weightUOM',
    'noHeader.visibility',
    'noHeader.packageLevel',
    'noHeader.brickCode',
    'noHeader.brickCodeName',
    'noHeader.targetMarket',
    'noHeader.brandId',
    'TradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation.brandName',
    'Header.manufacturerNameHeader',
    'Header.secondarySize',
    'Header.secondarySizeUOM',
    'Header.isTradeItemAVariableUnit',
    'Header.isTradeItemUnitOfUse',
    'Header.isTradeItemReinstated',
    'Header.isTradeItemRecalled',
    'Header.isTradeItemNonphysical',
    'Header.isTradeItemAService',
    'Header.isTradeItemAnInvoiceUnit',
    'Header.isTradeItemAConsumerUnit',
    'Header.isTradeItemADispatchUnit',
    'Header.isTradeItemAnOrderableUnit',
    'Header.isTradeItemABaseUnit',

    'noHeader.imperialHeight',
    'noHeader.imperialWidth',
    'noHeader.imperialDepth',
    'noHeader.imperialWeight',
    'noHeader.imperialDimensionUOM',
  ],
  SAME_STYLE_FLAVOR: [
    'noHeader.productName',
    'noHeader.productDescription',
    'noHeader.visibility',
    'noHeader.packageLevel',
    'noHeader.brickCode',
    'noHeader.brickCodeName',
    'noHeader.targetMarket',
    'noHeader.brandId',
    'TradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation.brandName',
    'Header.manufacturerNameHeader',
    'Header.productFlavor',
    'Header.productScent',
    'Header.productStyle',
  ],
  SAME_SIZE_NOT_INCLUDE: [
    'noHeader',
    'Header',
    'AllergenInformationModule',
    'FoodAndBeverageIngredientModule',
    'NutritionFacts',
    'SupplementFacts',
    'DrugFacts',
    'PetNutritionFacts',
  ],
  SAME_STYLE_NOT_INCLUDE: ['noHeader', 'Header'],
  SAME_STYLE_INCLUDE: [
    'TradeItemDataCarrierAndIdentificationModule',
    'TradeItemDescriptionModule',
    'TradeItemDisposalInformationModule',
    'TradeItemHandlingModule',
    'TradeItemHumidityInformationModule',
    'TradeItemLicensingModule',
    'TradeItemLifespanModule',
    'TradeItemMeasurementsModule',
    'TradeItemSizeModule',
    'TradeItemTemperatureInformationModule',
  ],
};
export const MAIN_PAGE_LINK = [
  '/products/main',
  '/reportings/main',
  '/digital-media/main',
  '/members/main',
  '/folders/main',
];

export const TICKET_STATUS = ['Open', 'Pending', 'Solved'];
export const TICKET_SEVERITY = ['High Risk / Medical', 'Medium', 'Low'];
export const TICKET_PRIORITY = [1, 2, 3, 4, 5];

export const VALID_URL_PATTERN =
  /^(https?):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/=]*)$/;

export const MAX_INT32 = 2147483647;
