import React from 'react';
import Messages from 'i18n/messages/home';

import { ReactComponent as IconDateRange } from 'common/components/button/svg-icons/ICO_10.26.svg';
import RibbonButton from 'common/components/button/RibbonButton';

const DateRange = () => {
  return (
    <RibbonButton
      icon={<IconDateRange style={{ height: 26 }} />}
      label={Messages.dateRange}
    />
  );
};

DateRange.propTypes = {};

export default DateRange;
