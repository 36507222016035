import { useDispatch, useSelector } from 'react-redux';

import * as folderSelectors from 'pages/folders/controllers/selectors';
import * as folderActions from 'pages/folders/controllers/actions';

export const useDownloadFolderSetting = (props) => {
  const dispatch = useDispatch();

  const downloadSetting = useSelector(folderSelectors.selectDownloadSetting());

  const getDownloadSetting = (id) => {
    dispatch(folderActions.getDownloadSetting(id));
  };

  return {
    downloadSetting,
    getDownloadSetting,
  };
};
