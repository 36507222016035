import React from 'react';
import { Button } from 'antd';
import { FolderViewOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/common';

const FormViewButton = (props) => {
  return (
    <Button
      type='primary'
      style={{ borderRadius: '4px', background: 'green' }}
      icon={<FolderViewOutlined />}
      {...props}
    >
      <span>
        <FormattedMessage {...Messages.viewBtn} />
      </span>
    </Button>
  );
};

export default FormViewButton;
