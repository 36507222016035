import { PUBLISH_TYPES } from 'static/Constants';

const mapToPublishProductRequestParams = ({
  publishChecklist,
  requestParams,
}) => {
  let params;

  let greenData =
    publishChecklist &&
    publishChecklist.length > 0 &&
    publishChecklist.filter((val) => val.status === 'Green');

  let publishInfo = [];

  greenData &&
    greenData.length > 0 &&
    greenData.map((val) => {
      publishInfo.push({
        productId: val?.productId,
        recipientGLN: val?.recipientGLN,
      });
    });

  let publishType = requestParams?.publishType;

  const publishTypeParam =
    publishType === PUBLISH_TYPES.REPUBLISH.value
      ? { IsNewLoad: false, IsInitialLoad: false }
      : publishType === PUBLISH_TYPES.NEW.value
      ? { IsNewLoad: true, IsInitialLoad: false }
      : publishType === PUBLISH_TYPES.INITIAL.value
      ? { IsNewLoad: false, IsInitialLoad: true }
      : {};

  params = {
    sourceGLN: requestParams.sourceGLN,
    customImageFormat: requestParams.customImageFormat,
    publishInfo: publishInfo,
    ...publishTypeParam,
  };

  return params;
};

export default mapToPublishProductRequestParams;
