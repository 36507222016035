import React, { useState } from 'react';
import Messages from 'i18n/messages/mapping';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

import { Input, Checkbox } from 'antd';

import { Form } from 'common/components';

import { useDeepCompareEffect } from 'hooks';

import isEmpty from 'lodash/isEmpty';

import {
  MAPPING_FORMAT_TYPE,
  MAPPING_DELIVERY_METHODS,
} from 'static/Constants';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 17,
  },
};

const SaveMappingConfigForm = (props) => {
  const { form, mode, mappingEditData } = props;
  const intl = useIntl();

  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [formatTypes, setFormatTypes] = useState([]);

  useDeepCompareEffect(() => {
    if (mode === 'edit') {
      if (!isEmpty(mappingEditData)) {
        const {
          mappingName,
          mappingDescription,
          formatTypes,
          deliveryMethods,
        } = mappingEditData;

        form.setFieldsValue({
          mappingName,
          mappingDescription,
          formatTypes,
          deliveryMethods,
        });

        setDeliveryMethods(deliveryMethods);
        setFormatTypes(formatTypes);
      }
    }
  }, [mode, mappingEditData]);

  const onChangeFormatType = (checkedValues) => {
    setFormatTypes(checkedValues);

    form.setFieldsValue({
      formatTypes: checkedValues,
    });
  };

  const onChangeDeliveryMethods = (checkedValues) => {
    setDeliveryMethods(checkedValues);

    form.setFieldsValue({
      deliveryMethods: checkedValues,
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      initialValues={{
        formatTypes: [MAPPING_FORMAT_TYPE[0]],
        deliveryMethods: [MAPPING_DELIVERY_METHODS[0]],
      }}
      className='save-mapping-config-form'
    >
      <Form.Item
        name='mappingName'
        label={<FormattedMessage {...Messages.mappingName} />}
        rules={[
          {
            required: true,
            message: intl.formatMessage(Messages.mappingNameIsRequired),
          },
          {
            pattern: /(?!^$)([^\s])/,
            message: intl.formatMessage(Messages.mappingNameIsRequired),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='mappingDescription'
        label={<FormattedMessage {...Messages.mappingDescription} />}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        name='deliveryMethods'
        label={<FormattedMessage {...Messages.deliveryMethods} />}
        required
        rules={[
          {
            required: true,
            message: intl.formatMessage(Messages.deliveryMethodsIsRequired),
          },
        ]}
      >
        <Checkbox.Group
          options={MAPPING_DELIVERY_METHODS}
          onChange={onChangeDeliveryMethods}
          value={deliveryMethods}
        />
      </Form.Item>
      <Form.Item
        name='formatTypes'
        label={<FormattedMessage {...Messages.formatType} />}
        required
        rules={[
          {
            required: true,
            message: intl.formatMessage(Messages.formatTypeIsRequired),
          },
        ]}
      >
        <Checkbox.Group value={formatTypes} onChange={onChangeFormatType}>
          {MAPPING_FORMAT_TYPE.map((type) => (
            <Checkbox key={type} value={type}>
              {type}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default SaveMappingConfigForm;
