import React, { useRef, useMemo } from 'react';
import { ReassignProductToMemberModal } from 'common/components';
import { useSelector } from 'react-redux';

import { DoubleRightOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import selectorUser from 'redux/user/selectors';

import * as memberServices from 'services/members';

import { checkIsSuperAdmin } from 'utils';

const defaultSearchParams = {
  searchText: '',
};

const MoveProductsToMember = (props) => {
  const { disabled } = props;

  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);

  const refSearchGridItemModal = useRef();

  const openModalMoveAssetToMember = () => {
    refSearchGridItemModal.current.openModal();
  };

  const isAllowToMoveProduct = useMemo(() => {

    if (!selectedItemDetailList?.length) {
      return false;
    }

    if (userInfo?.isSuperMember || isSuperAdmin) {
      return true;
    } else {
      return false;
    }
  }, [selectedItemDetailList, userInfo, isSuperAdmin]);

  return (
    <>
      <ButtonSmallIcon
        className='button-item-dropdown'
        icon={<DoubleRightOutlined />}
        textDisplay='Move Products to Member'
        onClick={openModalMoveAssetToMember}
        disabled={!isAllowToMoveProduct || disabled}
      />
      <ReassignProductToMemberModal
        title='Assign Product(s) to Member'
        ref={refSearchGridItemModal}
        isMultiple={false}
        service={memberServices.getAllMemberForReassign}
        dataPath='data.allMembers'
        params={defaultSearchParams}
        mapSearch={(searchText) => ({ searchText })}
        tagRender={{ value: (itemData) => itemData?.companyName }}
        itemIdName='companyId'
        productList={selectedItemDetailList}
        memberId={userInfo?.member?.id}
      />
    </>
  );
};

MoveProductsToMember.propTypes = {};

export default MoveProductsToMember;
