import * as api from 'config/axios';
import * as endpoints from './endpoints';
import { getToken } from 'utils/common/session';
/**
 * Member service
 * @param {object} data member profile information, include member profile header, membership, overview
 */
export const getMemberProfileHeader = (data) => {
  return api.sendGet(endpoints.MEMBER_PROFILE_HEADER_ENDPOINT, {
    params: data,
  });
};

/**
 * Member service
 * @param {object} data member contact history
 */
export const getMemberContactHistory = (data) => {
  return api.sendPost(endpoints.MEMBER_CONTACT_HISTORY_ENDPOINT, data.payload);
};

/**
 * GET MEMBER LOCATIONS
 */
export const getCompanyLocations = (params) => {
  return api.sendPost(endpoints.MEMBER_LOCATIONS_ENDPOINT, params);
};

/**
 * GET MEMBER BILLING
 */
export const getMemberBilling = (params) => {
  return api.sendPost(endpoints.MEMBER_BILLING_ENDPOINT, params);
};

export const getMemberBillingDistinct = (params) => {
  return api.sendPost(endpoints.MEMBER_BILLING_ENDPOINT_DISTINCT, params);
};

/**
 * Member service
 * @param {object} data member's active shares
 */
export const getMemberShares = () => {
  return api.sendGet(endpoints.MEMBER_SHARES_ENDPOINT);
};

export async function getMemberOverviewBrand(data) {
  const response = await api.sendGet(endpoints.USER_MEMBER_OVERVIEW_BRAND, {
    params: data,
  });
  return response;
}

export const getMemberOverviewBrands = (payload) => {
  return api.sendPost(endpoints.GET_MEMBER_BRANDS, payload);
};

export async function getMemberOverviewAsset(data) {
  const response = await api.sendGet(endpoints.USER_MEMBER_OVERVIEW_ASSET, {
    params: data,
  });
  return response;
}

export async function getMemberOverviewDocument(data) {
  const response = await api.sendGet(endpoints.USER_MEMBER_OVERVIEW_DOCUMENT, {
    params: data,
  });
  return response;
}

export async function getPublicContact(data) {
  const response = await api.sendPost(endpoints.PUBLIC_CONTACT_LIST, data);
  return response;
}

/**
 * Member service
 * @param {object} data member system data
 */
export const getMemberSystems = () => {
  return api.sendGet(endpoints.USER_MEMBER_SYSTEM_ENDPOINT);
};

export const getCRMRelationship = (body) => {
  return api.sendGet(endpoints.USER_MEMBER_CRM_RELATIONSHIP);
};

export async function getCRMActivityLog(params) {
  const response = await api.sendPost(
    endpoints.MEMBER_CRM_ACTIVITIES_ENDPOINT,
    params
  );
  return response;
}

export const getMemberAffiliation = () => {
  return api.sendGet(endpoints.MEMBER_AFFILIATION_ENDPOINT);
};

export const updateMemberProfile = (payload) => {
  return api.sendPost(endpoints.MEMBER_PROFILE_EDIT, payload);
};

export const getMemberList = (params) => {
  params.pageIndex = params.pageNumber;

  const { searchText, ...rest } = params;

  const newParams = {
    ...rest,
    search: { searchText },
  };

  const endpoint = params.isFavoriteRoute
    ? endpoints.FAVORITE_MEMBER_LIST_ENDPOINT
    : endpoints.MEMBER_LIST_ENDPOINT;
  return api.sendPost(endpoint, newParams);
};

export const getMemberContact = (params) => {
  return api.sendPost(endpoints.MEMBER_CONTACT, params);
};
export const getMemberContactDistinct = (params) => {
  return api.sendPost(endpoints.MEMBER_CONTACT_DISTINCT, params);
};
export const getMemberDetailGrid = (params) => {
  return api.sendPost(endpoints.MEMBER_DETAIL_GRID, params);
};

export const getMemberShortDetail = (params) => {
  return api.sendGet(endpoints.MEMBER_SHORT_DETAIL, { params: params });
};

export const getMemberDetailGridDistinct = (params) => {
  return api.sendPost(endpoints.MEMBER_DETAIL_GRID_DISTINCT, params);
};
export const getMemberCrmActivityGridDistinct = (params) => {
  return api.sendPost(endpoints.MEMBER_CRM_ACTIVITY_GRID_DISTINCT, params);
};
export const getMemberCompanyLocationGridDistinct = (params) => {
  return api.sendPost(endpoints.MEMBER_COMPANY_LOCATION_GRID_DISTINCT, params);
};

//* edit member header - start
export const addNewSocialLink = (params) => {
  return api.sendPost(endpoints.ADD_NEW_MEMBER_SOCIAL_LINK, params);
};

export const deleteSocialLink = (params) => {
  return api.sendPost(endpoints.DELETE_MEMBER_SOCIAL_LINK, params);
};

export const editMemberName = (params) => {
  return api.sendPost(endpoints.EDIT_MEMBER_NAME, params);
};

export const editMemberLogo = (data) => {
  return api.sendPost(endpoints.EDIT_MEMBER_LOGO, data);
};

export const getMemberLogo = (payload) => {
  return api.sendPost(endpoints.GET_MEMBER_LOGO, payload);
};
//* edit member header - end

// showcased media asset grid
export const getMemberShowcasedMediaGrid = (params) => {
  return api.sendPost(endpoints.MEMBER_SHOWCASED_MEDIA_GRID, params);
};

export const getOfficesAndFacilities = (params) => {
  return api.sendPost(endpoints.GET_OFFICES_AND_FACILITES, params);
};

export const editCompanyTagLine = (params) => {
  return api.sendPost(endpoints.EDIT_COMPANY_TAG_LINE, params);
};

//* add/ update member background
export const getBackground = (params) => {
  return api.sendGet(endpoints.GET_BG_HEADER, { params: params });
};

export const editBackgroundConfig = (payload) => {
  return api.sendPost(endpoints.MEMBER_EDIT_BACKGROUND_CONFIG, payload);
};

export const editAlterBackgroundConfig = (payload) => {
  return api.sendPost(endpoints.MEMBER_EDIT_ALTER_BACKGROUND_CONFIG, payload);
};

//* add / update / delete user location
export const updateMyCompanyLocation = (params) => {
  return api.sendPost(endpoints.MY_COMPANY_UPDATE_LOCATION, params);
};
export const deleteMyCompanyLocation = (params) => {
  return api.sendPost(endpoints.MY_COMPANY_DELETE_LOCATION, params);
};
export const addMyCompanyLocation = (params) => {
  return api.sendPost(endpoints.MY_COMPANY_ADD_LOCATION, params);
};

export const getCompanyLocationDetail = (id) => {
  const options = {
    params: {
      id,
    },
  };
  return api.sendGet(endpoints.MY_COMPANY_GET_LOCATION_DETAIL, options);
};

export const getCompanyContactDetail = (id) => {
  const options = {
    params: {
      id,
    },
  };
  return api.sendGet(endpoints.MY_COMPANY_GET_CONTACT_DETAIL, options);
};

//* add / update / delete user contact + reset password
export const updateMyCompanyContact = async (params) => {
  return api.sendPost(endpoints.MY_COMPANY_UPDATE_CONTACT, params);
};
export const addMyCompanyContact = async (params) => {
  return api.sendPost(endpoints.MY_COMPANY_ADD_CONTACT, params);
};
export const deleteMyCompanyContact = async (params) => {
  return api.sendPost(endpoints.MY_COMPANY_DELETE_CONTACT, params);
};
export const resetMyCompanyPassword = async (params) => {
  return api.sendPost(endpoints.MY_COMPANY_RESET_PASSWORD, params);
};

//* select media upload from member asset
export const getMemberMediaAssets = (params) => {
  return api.sendPost(endpoints.GET_MEDIA_ASSET_BY_MEMBER, params);
};

// Assets-for-member
export const getAssetsForMember = (params) => {
  return api.sendPost(endpoints.GET_ASSETS_FOR_MEMBER, params);
};
// delete Members
export const deleteMembers = (params) => {
  return api.sendPost(endpoints.DELETE_MEMBERS, params);
};

export const reorderShowcase = (params) => {
  return api.sendPost(endpoints.REORDER_SHOWCASE_TAG_LINE, params);
};

export const editShowcaseText = (params) => {
  return api.sendPost(endpoints.EDIT_SHOWCASE_TEXT, params);
};

export const editMarketingCallOut = (params) => {
  return api.sendPost(endpoints.EDIT_MARKETING_CALL_OUT, params);
};

export const editProfileDefaultSetting = (params) => {
  return api.sendPost(endpoints.EDIT_PROFILE_DEFAULT_SETTING, params);
};

export const editMemberVisibility = (params) => {
  return api.sendPost(endpoints.EDIT_MEMBER_VISIBILITY, params);
};

export const editMemberOverview = (params) => {
  return api.sendPost(endpoints.EDIT_MEMBER_OVERVIEW, params);
};

export const editMemberDetail = (params) => {
  return api.sendPost(endpoints.EDIT_MEMBER_DETAIL, params);
};

export const editMemberShowingOptions = (params) => {
  return api.sendPost(endpoints.EDIT_MEMBER_SHOWING_OPTIONS, params);
};

export const addToBanList = (params) => {
  return api.sendPost(endpoints.ADD_TO_BAN_LIST, params);
};

export const removeToBanList = (params) => {
  return api.sendPost(endpoints.REMOVE_FROM_BAN_LIST, params);
};

// Certification
export const getCertificationsOfMember = (memberId) => {
  return api.sendGet(
    endpoints.GET_CERTIFICATIONS_OF_MEMBER + `?MemberId=${memberId}`
  );
};

export const createCertificationService = (params) => {
  return api.sendPost(endpoints.CREATE_CERTIFICATION, params);
};

export const editCertificationService = (params) => {
  return api.sendPost(endpoints.EDIT_CERTIFICATION, params);
};

export const editCertificationLogoService = (params) => {
  return api.sendPost(endpoints.EDIT_CERTIFICATION_LOGO, params);
};

export const getLogoCertificationsAssets = (params) => {
  return api.sendPost(endpoints.GET_LOGO_CERTIFICATION_ASSETS, params);
};

export const deleteCertificationService = (params) => {
  return api.sendPost(endpoints.DELETE_CERTIFICATION, params);
};

//member detail - brands

export const createBrandInfo = (params) => {
  return api.sendPost(endpoints.CREATE_BRAND_INFO, params);
};

export const editBrandInfo = (params) => {
  return api.sendPost(endpoints.EDIT_BRAND_INFO, params);
};

export const deleteBrand = (params) => {
  return api.sendPost(endpoints.DELETE_BRAND, params);
};

export const editBrandLogo = (params) => {
  return api.sendPost(endpoints.CHANGE_BRAND_LOGO, params);
};

export const getMemberBrandLogo = (payload) => {
  return api.sendPost(endpoints.GET_MEMBER_BRAND_LOGO, payload);
};

export const getDaymon = (data) => {
  return api.sendGet(endpoints.GET_DAYMON, {
    params: data,
  });
};

export const editDaymon = (params) => {
  return api.sendPost(endpoints.EDIT_DAYMON, params);
};

export const getAllArticles = () => {
  return api.sendGet(endpoints.GET_ALL_ARTICLES);
};

export const getMemberDocument = (params) => {
  return api.sendPost(endpoints.GET_MEMBER_DOCUMENT, params);
};

export const deleteMemberDocument = (params) => {
  return api.sendPost(endpoints.DELETE_MEMBER_DOCUMENT, params);
};

export const getMemberDefinedProperties = (params) => {
  return api.sendGet(endpoints.GET_MEMBER_DEFINED_PROPERTIES, { params });
};

export const saveMemberDefinedProperties = (params) => {
  return api.sendPost(endpoints.SAVE_MEMBER_DEFINED_PROPERTIES, params);
};

export const deleteMemberDefinedProperties = (params) => {
  return api.sendPost(endpoints.DELETE_MEMBER_DEFINED_PROPERTIES, params);
};
export const editEnforceTagsRestrictions = (params) => {
  return api.sendPost(endpoints.EDIT_ENFORCE_TAGS_RESTRICTIONS, params);
};

export const editEnforceDefinedProperties = (params) => {
  return api.sendPost(endpoints.EDIT_ENFORCE_DEFINED_PROPERTIES, params);
};

export const editFtpService = (params) => {
  return api.sendPost(endpoints.EDIT_FTP_SERVICE, params);
};

export const generateFtpAccount = (params) => {
  return api.sendPost(endpoints.GENERATE_FTP_SERVICE, params);
};

export const generateFtpPassword = (params) => {
  return api.sendPost(endpoints.GENERATE_FTP_PASSWORD, params);
};

export const sendTokenViaEmail = (params) => {
  return api.sendPost(endpoints.SEND_TOKEN_VIA_EMAIL, params);
};

export const generateApiToken = (params) => {
  return api.sendPost(endpoints.GENERATE_API_TOKEN, params);
};

export const editApiService = (params) => {
  return api.sendPost(endpoints.EDIT_API_SERVICE, params);
};

export const visitorGetMemberDetail = (data) => {
  return api.sendGet(endpoints.VISITOR_GET_MEMBER_DETAIL, {
    params: data,
  });
};

export const getMemberInformationSheetGrid = (params) => {
  return api.sendPost(endpoints.GET_INFORMATION_SHEET_DETAIL_GRID, params);
};

export const deleteMemberInformationSheet = (params) => {
  return api.sendPost(endpoints.DELETE_INFORMATION_SHEET, params);
};

export const reactiveMemberInformationSheet = (params) => {
  return api.sendPost(endpoints.REACTIVE_INFORMATION_SHEET, params);
};

export const reGenerateMemberInformationSheet = (params) => {
  return api.sendPost(endpoints.RE_GENERATE_MEMBER_INFORMATION_SHEET, params);
};

// download member overview information sheets
export const downloadMemberOverviewInformationSheet = (params) => {
  return api.sendPost(
    endpoints.DOWNLOAD_MEMBER_OVERVIEW_INFORMATION_SHEET,
    params
  );
};

// download information sheets in grid view
export const downloadInformationSheetGridView = (params) => {
  return api.sendPost(endpoints.DOWNLOAD_INFORMATION_SHEET_GRID_VIEW, params);
};

// download information sheets in company
export const downloadMemberInformationSheet = (params) => {
  return api.sendPost(endpoints.DOWNLOAD_MEMBER_INFORMATION_SHEET, params);
};

export const getMemberInfoSheet = (data) => {
  return api.sendGet(endpoints.GET_MEMBER_INFO_SHEET, {
    params: data,
  });
};

export const getMemberProfileImage = (params) => {
  return api.sendPost(endpoints.GET_MEMBER_PROFILE_IMAGE, params);
};

export const getGdsnPartyInfo = (params) => {
  return api.sendGet(endpoints.GET_GDSN_PARTY_INFO, {
    params,
  });
};

export const editGdsnPartyInfo = (params) => {
  return api.sendPost(endpoints.EDIT_GDSN_PARTY_INFO, params);
};

export const sendGdsnPartyRegister = (params) => {
  return api.sendPost(endpoints.SEND_GDSN_PARTY_REGISTER, params);
};

export const unregisterMemberGlns = (params) => {
  return api.sendPost(endpoints.UNREGISTER_MEMBER_GLNS, params);
};

/**
 *
 * @param {*} payload
 * @param {*} additionalBodyPayload
 * @returns //* allow user to add new showcase eventhough without having asset permission
 */
export const addNewShowcase = (payload, additionalBodyPayload) =>
  api.sendUpload({
    api: endpoints.ADD_NEW_SHOWCASE,
    payload,
    additionalBodyPayload,
  });

/**
 * @param {*} params
 * @returns //* remove asset from showcase list
 */
export const removeShowcase = (params) =>
  api.sendPost(endpoints.REMOVE_SHOWCASE, params);

/**
 * @param {*} params
 * @returns //* get all member for reassign
 */
export const getAllMemberForReassign = (params) =>
  api.sendGet(endpoints.GET_ALL_MEMBERS_FOR_REASSIGN, {
    params,
  });

export const getCustomProperties = (params) =>
  api.sendGet(endpoints.GET_MEMBER_CUSTOM_PROPERTIES_BY_MEMBER_ID, {
    params,
  });

export const createCustomField = (params) =>
  api.sendPost(endpoints.CREATE_CUSTOM_FIELD, params);

export const editCustomField = (params) =>
  api.sendPost(endpoints.EDIT_CUSTOM_FIELD, params);

export const deleteCustomProperty = (params) =>
  api.sendPost(endpoints.DELETE_CUSTOM_FIELD, params);
/**
 *
 * @param {*} params
 * @returns //* reactive delete user account in company contact list
 */
export const reactiveCompanyContact = (params) => {
  return api.sendPost(endpoints.REACTIVE_COMPANY_CONTACT, params);
};

export const reactiveUser = (params) => {
  return api.sendPost(endpoints.REACTIVE_USER, params);
};

export const editECommerceProduct = (params) => {
  return api.sendPost(endpoints.EDIT_E_COMMERCE_PRODUCT, params);
};

export const moveAllToMember = (params) => {
  return api.sendPost(endpoints.MOVE_ALL_TO_MEMBER, params);
};

// export const getMemberProductList = (params) => {
//   const endpoint = endpoints.GET_PRODUCTS_FOR_MEMBER;
//   return api.sendPost(endpoint, params);
// };
