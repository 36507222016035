import React from 'react';

import { useSelector } from 'react-redux';

import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';

import { GaugeChart } from 'common/components';

const MatchPercent = () => {
  const aplDetail = useSelector(makeSelectAplDetail());

  const aplMatch = aplDetail?.aplMatch ? aplDetail?.aplMatch * 100 : 0;

  return (
    <GaugeChart percentage={aplMatch} width={100} thick={10} fontSize={23} />
  );
};

export default MatchPercent;
