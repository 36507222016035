import React, { useState } from 'react';

import { Row, Drawer, Col, Typography } from 'antd';

import {
  ProductHierarchyDetail,
  ProductHierarchyEndpointInfo,
} from 'pages/product-full-view/components';

import * as productService from 'services/product';

const { Text } = Typography;

const ProductItemSearchDrawer = (props) => {
  const {
    toggleIsDrawerOpen,
    isDrawerVisible,
    productDetail,
    setEditProductId,
    hierarchyItems,
  } = props;

  const [hierarchyLoading, setHierarchyLoading] = useState(false);
  const [hierarchyData, setHierarchyData] = useState([]);

  const getHierarchyData = () => {
    setHierarchyLoading(true);

    const params = {
      productId: productDetail?.productId,
    };

    productService
      .getProductHierarchy(params)
      .then((response) => {
        const { isSuccess, data } = response;

        if (isSuccess && data?.productHierarchies) {
          setHierarchyData(data.productHierarchies);
        }
      })
      .finally(() => {
        setHierarchyLoading(false);
      });
  };

  return (
    <Drawer
      title={
        <div style={{ height: 35, display: 'flex', alignItems: 'center' }}>
          <Text>Select product item from hierarchy view</Text>
        </div>
      }
      placement='right'
      onClose={toggleIsDrawerOpen}
      visible={isDrawerVisible}
      width={630}
      className='fix-publication-invalid-modal__hierarchy-drawer'
      zIndex={1200}
    >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Row style={{ flex: 1, overflow: 'hidden' }}>
          <Col xs={24} style={{ height: '100%' }}>
            <ProductHierarchyDetail
              hierarchyData={hierarchyData}
              hierarchyLoading={hierarchyLoading}
              getHierarchyData={getHierarchyData}
              publishPackageList={hierarchyItems || []}
              isInCheckPublishErrorMode
            />
          </Col>
        </Row>
        <Row style={{ height: 400, overflow: 'hidden' }}>
          <Col
            className='scroller'
            xs={24}
            style={{ height: '100%', paddingTop: 10 }}
          >
            <ProductHierarchyEndpointInfo
              hierarchyData={hierarchyData}
              hierarchyLoading={hierarchyLoading}
              setEditProductId={setEditProductId}
              isFixPublicationErrorView
            />
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};

export default ProductItemSearchDrawer;
