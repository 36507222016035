import React, { useState, useEffect, useMemo } from 'react';

import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Card, Row, Col, Form, Space, Typography, Select, Tooltip } from 'antd';
import classnames from 'classnames';

import { dialogFunction, WrapperSelect } from 'common/components';
import { SearchProductProperties } from 'pages/product-full-view/components/product-new-detail-view/QueryProperties';

import sortByKey from 'utils/sortByKey';
import { getExpandedKeys } from './utils';
import { generateModuleProductToTree } from 'pages/product-full-view/shared/utils';

import { PACKAGE_LEVELS, RIVIR_FIELDS } from 'static/Constants';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const FormFieldItem = (props) => {
  const {
    fieldName,
    fieldType,
    fieldDisplayName,
    moduleName,
    moduleNameOptions,
    packageLevel,
    fieldNameList,
    productSchema,
    onAddItem,
    onRemoveItem,
    onChangeModuleName,
  } = props;

  const [expandedKeys, setExpandedKeys] = useState([]);

  const treeData = useMemo(() => {
    const moduleFilteredData = moduleName
      ? productSchema.filter((moduleItem) => {
          return moduleItem.moduleName === moduleName;
        })
      : productSchema;

    return generateModuleProductToTree(moduleFilteredData);
  }, [productSchema, moduleName]);

  const handleChangeModuleName = (value, fieldKey) => {
    onChangeModuleName && onChangeModuleName(value, fieldKey);
  };

  const updateExpandedKeys = () => {
    const moduleFilteredData = moduleName
      ? productSchema.filter((moduleItem) => {
          return moduleItem.moduleName === moduleName;
        })
      : productSchema;

    const expandedKeys = getExpandedKeys(moduleFilteredData);
    setExpandedKeys(expandedKeys);
  };

  const onTreeExpand = (expandedKey) => {
    setExpandedKeys(expandedKey);
  };

  const validateDuplicatedField = (formItem, value) => {
    const thisFieldList = fieldNameList.filter((fieldItem) => {
      return (
        fieldItem.fieldName === value &&
        !!packageLevel &&
        fieldItem.packageLevel === packageLevel
      );
    });

    const isExisted = thisFieldList.length > 1;

    return new Promise((resolve, reject) => {
      isExisted ? reject('Field Name is duplicated') : resolve();
    });
  };

  useEffect(() => {
    updateExpandedKeys();
  }, [treeData]);

  return (
    <Card
      className={classnames({
        'fields-form__item': true,
        'fields-form__item--rivir': fieldType === 'RIVIR',
      })}
      size='small'
      title={
        <Row align='middle' wrap={false} gutter={8}>
          <Col flex={1}>
            <Typography.Text strong>
              {fieldDisplayName ? fieldDisplayName : ` Field ${fieldName + 1}`}
            </Typography.Text>
          </Col>
          <Col>
            <Space size={4}>
              <Tooltip title='Copy value'>
                <CopyOutlined
                  style={{
                    fontSize: 15,
                    paddingTop: 2,
                    color: '#8c8c8c',
                  }}
                  onClick={() => {
                    onAddItem && onAddItem();
                  }}
                />
              </Tooltip>
              <DeleteOutlined
                style={{
                  fontSize: 17,
                  paddingTop: 2,
                  paddingLeft: 5,
                  color: '#ff4d4f',
                }}
                onClick={() => {
                  dialogFunction({
                    type: 'warn',
                    content: 'Are you sure want to delete the field?',
                    onOk: () => {
                      onRemoveItem && onRemoveItem();
                    },
                  });
                }}
              />
            </Space>
          </Col>
        </Row>
      }
    >
      <Col span={24}>
        <Form.Item
          {...formItemLayout}
          label='Package Level'
          name={[fieldName, 'packageLevel']}
          rules={[
            {
              required: true,
              message: 'Please select package level',
            },
          ]}
        >
          <WrapperSelect>
            {PACKAGE_LEVELS?.map((level) => (
              <Select.Option key={level} value={level}>
                {level}
              </Select.Option>
            ))}
          </WrapperSelect>
        </Form.Item>
      </Col>
      {fieldType === 'RIVIR' ? null : (
        <Form.Item
          {...formItemLayout}
          label='Module Name'
          name={[fieldName, 'moduleName']}
        >
          <WrapperSelect
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => handleChangeModuleName(value, fieldName)}
            placeholder='Select Module to filter fields'
          >
            {sortByKey(moduleNameOptions, 'moduleDisplayName').map((option) => (
              <Select.Option key={option.moduleName} value={option.moduleName}>
                {option.moduleDisplayName}
              </Select.Option>
            ))}
          </WrapperSelect>
        </Form.Item>
      )}
      <Col span={24}>
        <Form.Item
          {...formItemLayout}
          className='form-item__field-name'
          label='Field Name'
          name={[fieldName, 'fieldName']}
          rules={[
            {
              required: true,
              message: 'Please input field name',
            },
            {
              validator: validateDuplicatedField,
            },
          ]}
        >
          {fieldType === 'RIVIR' ? (
            <WrapperSelect>
              {RIVIR_FIELDS?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.name}
                </Select.Option>
              ))}
            </WrapperSelect>
          ) : (
            <SearchProductProperties
              treeData={treeData}
              treeExpandedKeys={expandedKeys}
              onTreeExpand={onTreeExpand}
            />
          )}
        </Form.Item>
      </Col>
    </Card>
  );
};

export default FormFieldItem;
