import produce from 'immer';
import * as types from './constants';
import { mapToCommonFetchState } from './mapper/mapToReducerState';

import { TRANSITION_PAGE } from 'static/Constants';

const initData = {
  data: null,
  loading: false,
  error: null,
};

export const initialState = {
  transitionPageDetail: initData,
  saveTransitionPageAPI: initData,
  previewSize: {
    ID: 1,
    WIDTH: 1920,
    HEIGHT: 1080,
    REAL_HEIGHT: 950,
  },
};

const transitionPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_TRANSITION_PAGE_DETAIL:
      case types.GET_TRANSITION_PAGE_DETAIL_FAIL:
      case types.GET_TRANSITION_PAGE_DETAIL_SUCCESS:
        mapToCommonFetchState(action, draft, 'transitionPageDetail', true);
        break;

      case types.SAVE_TRANSITION_PAGE:
      case types.SAVE_TRANSITION_PAGE_FAIL:
      case types.SAVE_TRANSITION_PAGE_SUCCESS:
        mapToCommonFetchState(action, draft, 'saveTransitionPageAPI', true);
        break;

      case types.CHANGE_TRANSITION_PAGE_PREVIEW_SIZE:
        draft.previewSize = action.payload;
        break;

      default:
        break;
    }
  });

export default transitionPageReducer;
