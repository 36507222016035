import React, { useMemo, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import classnames from 'classnames';

import { CloseOutlined } from '@ant-design/icons';

import {
  Form,
  Space,
  Input,
  Select,
  List,
  Typography,
  Tooltip,
  Button,
} from 'antd';

import {
  StyledModal,
  CustomNotification,
  WrapperSelect,
  WithLoading,
  FormDeleteButton,
  FormCancelButton,
} from 'common/components';

import { WarnIcon } from 'common/components/dialog-function/DialogIcon';

import * as GroupPart from './GroupPart';

import {
  GroupAllergens,
  GroupAllergensFormItem,
  GroupFactsPanelFormItem,
  GroupFactsPanelType,
  GroupIngredients,
  GroupIngredientsFormItem,
  GroupName,
} from './GroupLayout';

import { GroupLayoutProvider } from './GroupLayoutProvider';
import { CarouselSection, CarouselNext, CarouselPrevious } from '../Carousel';

import { useQueryProductIngredients } from '../tab-ingredients/useProductIngredients';
import { useGetProductAllergens } from '../tab-allergens/queries';
import {
  useDeleteGroupProduct,
  useFactsPanelsGroup,
  useGetGroupDetail,
  useSaveGroupProduct,
} from './hooks';

import {
  useGetAllGroupProduct,
  useInitialFormGroup,
  useInvalidateGroupDetail,
} from '../../shared/hooks';

import { transformToTextGroupName } from '../../shared/utils';
import { filterIsPresentAllergens } from '../tab-allergens/utils';
import {
  getComponentFactsPanelType,
  separateSupplementAndNutritionIds,
} from './utils';

const defaultModalProps = {
  destroyOnClose: true,
  width: 1140,
  maskClosable: false,
  bodyStyle: {
    minHeight: '60vh',
  },
};

const MAX_SLIDE_TO_SHOW = 3;

export const GroupAddition = (props) => {
  const {
    onCancel: offModal,
    productId: productIdProp,
    onAddSuccess,
    ...rest
  } = props;

  const [formInstance] = Form.useForm();

  const { id } = useParams();

  const { factsPanelsGroup } = useFactsPanelsGroup(productIdProp ?? id);

  const groupMutation = useSaveGroupProduct(productIdProp ?? id);

  const handleSaveGroup = async () => {
    const {
      groupName,
      ingredientGroupIndices,
      allergenGroupIndices,
      factsPanelsGroupIndices,
    } = await formInstance.validateFields();

    const {
      supplementFactIds,
      nutritionFactIds,
      drugFactIds,
      petNutritionFactIds,
    } = separateSupplementAndNutritionIds(
      factsPanelsGroup,
      factsPanelsGroupIndices
    );

    const params = {
      productItemId: productIdProp ?? id,
      groupId: null,
      groupName,
      ingredientGroupIndices: ingredientGroupIndices ?? null,
      allergenGroupIndices: allergenGroupIndices ?? null,
      nutritionFactIds,
      supplementFactIds,
      drugFactIds,
      petNutritionFactIds,
    };

    groupMutation.mutate(params, {
      onSuccess: (result) => {
        result?.isSuccess &&
          CustomNotification.success('Add group data successfully');
        formInstance.resetFields();
        offModal();
        onAddSuccess && onAddSuccess();
      },
    });
  };

  return (
    <StyledModal
      {...defaultModalProps}
      title='Add Group'
      onCancel={offModal}
      onOk={handleSaveGroup}
      okButtonProps={{
        loading: groupMutation.isLoading,
      }}
      wrapClassName='group-layout__addition'
      {...rest}
    >
      <GroupLayoutProvider>
        <Form form={formInstance}>
          <GroupName>
            <Input placeholder='Please input group name' />
          </GroupName>
          <IngredientsGroup productId={productIdProp} />
          <AllergensGroup productId={productIdProp} />
          <FactsPanelGroup factsPanelsGroup={factsPanelsGroup} />
        </Form>
      </GroupLayoutProvider>
    </StyledModal>
  );
};

export const GroupEdit = (props) => {
  const {
    defaultGroupId,
    productGroups = [],
    onCancel: offModal,
    onEditSuccess,
    ...rest
  } = props;

  const [formInstance] = Form.useForm();

  const { id: productId } = useParams();

  const [groupId, setGroupId] = useState(defaultGroupId);

  const { factsPanelsGroup: allFactsPanels } = useFactsPanelsGroup(productId);

  const editMutation = useSaveGroupProduct(productId);

  const { groupDetail, isLoading } = useGetGroupDetail({
    productId,
    groupId,
  });

  const { handleSyncSingleDetailGroup } = useInvalidateGroupDetail(
    productId,
    groupId
  );

  const {
    ingredientGroups = [],
    allergenGroups = [],
    nutritionFacts = [],
    supplementFacts = [],
    drugFacts = [],
    petNutritionFacts = [],
  } = groupDetail ?? {};

  const factsPanelGroups = useMemo(
    () => [
      ...nutritionFacts,
      ...supplementFacts,
      ...drugFacts,
      ...petNutritionFacts,
    ],
    [nutritionFacts, supplementFacts, drugFacts, petNutritionFacts]
  );

  useInitialFormGroup({
    formInstance,
    groupDetail,
  });

  const handleSaveGroup = async () => {
    const {
      ingredientGroupIndices,
      allergenGroupIndices,
      factsPanelsGroupIndices,
    } = await formInstance.getFieldsValue();

    const {
      supplementFactIds,
      nutritionFactIds,
      drugFactIds,
      petNutritionFactIds,
    } = separateSupplementAndNutritionIds(
      allFactsPanels,
      factsPanelsGroupIndices
    );

    const params = {
      productItemId: productId,
      groupId,
      ingredientGroupIndices: ingredientGroupIndices ?? null,
      allergenGroupIndices: allergenGroupIndices ?? null,
      drugFactIds,
      nutritionFactIds,
      supplementFactIds,
      petNutritionFactIds,
    };

    editMutation.mutate(params, {
      onSuccess: (result) => {
        result?.isSuccess &&
          CustomNotification.success('Edit group data successfully');
        handleSyncSingleDetailGroup();
        formInstance.resetFields();
        offModal();
        onEditSuccess && onEditSuccess();
      },
    });
  };

  return (
    <StyledModal
      {...defaultModalProps}
      title='Edit Group'
      onCancel={offModal}
      onOk={handleSaveGroup}
      okButtonProps={{
        loading: editMutation.isLoading,
      }}
      {...rest}
    >
      <WithLoading loading={isLoading}>
        <Form form={formInstance}>
          <GroupName>
            <WrapperSelect
              allowClear={false}
              placeholder='Please select a group name'
              onChange={(value) => setGroupId(value)}
              defaultValue={groupId}
            >
              {productGroups.map((group) => {
                return (
                  <Select.Option key={group.groupId} value={group.groupId}>
                    {group.groupName}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </GroupName>
          <IngredientsGroup ingredientList={ingredientGroups} />
          <AllergensGroup allergenList={allergenGroups} />
          <FactsPanelGroup factsPanelsGroup={factsPanelGroups} />
        </Form>
      </WithLoading>
    </StyledModal>
  );
};

export const GroupDeletion = ({
  productGroups,
  productId,
  onCancel,
  onDeleteSuccess,
  ...rest
}) => {
  const [selectedGroups, setSelectedGroups] = useState(productGroups);

  const deleteMutation = useDeleteGroupProduct(productId);

  const handleDeleteGroup = () => {
    const params = {
      productItemId: productId,
      groupIds: selectedGroups?.map((group) => group?.groupId),
    };

    deleteMutation.mutate(params, {
      onSuccess: () => {
        onCancel();
        onDeleteSuccess && onDeleteSuccess();
      },
    });
  };

  const isMultipleGroups = selectedGroups?.length > 1;

  const defaultTextProps = {
    style: {
      color: '#6c757d',
      fontSize: 16,
    },
    ellipsis: false,
  };

  return (
    <StyledModal
      title={null}
      width={420}
      bodyStyle={{ paddingTop: 24 }}
      footer={[
        <Space>
          <FormDeleteButton
            loading={deleteMutation.isLoading}
            onClick={handleDeleteGroup}
          />
          <FormCancelButton onClick={onCancel} />
        </Space>,
      ]}
      onCancel={onCancel}
      {...rest}
    >
      <WarnIcon />
      <div style={{ textAlign: 'center', marginTop: 4 }}>
        {isMultipleGroups ? (
          <Typography.Text {...defaultTextProps}>
            Are you sure you want to delete the group(s)?
          </Typography.Text>
        ) : (
          <Typography.Text {...defaultTextProps}>
            Are you sure you want to delete&nbsp;
            <strong>{selectedGroups?.[0]?.groupName?.toUpperCase()}</strong>
            &nbsp;group?
          </Typography.Text>
        )}
      </div>

      {isMultipleGroups && (
        <List
          style={{ marginTop: 12 }}
          dataSource={selectedGroups}
          renderItem={(item) => (
            <List.Item
              actions={
                selectedGroups?.length > 1
                  ? [
                      <Tooltip title='Ignore group'>
                        <Button
                          danger
                          type='text'
                          icon={<CloseOutlined />}
                          onClick={() => {
                            setSelectedGroups((prevGroups) =>
                              prevGroups.filter(
                                (group) => group?.groupId !== item?.groupId
                              )
                            );
                          }}
                        />
                      </Tooltip>,
                    ]
                  : null
              }
            >
              <List.Item.Meta
                title={
                  <Typography.Text ellipsis={{ tooltip: item?.groupName }}>
                    {item?.groupName?.toUpperCase()}
                  </Typography.Text>
                }
              />
            </List.Item>
          )}
        />
      )}
    </StyledModal>
  );
};

export const IngredientsGroup = ({
  ingredientList: ingredientListProps,
  productId: productIdProp,
}) => {
  const ref = useRef();

  const { id } = useParams();

  const { productGroups } = useGetAllGroupProduct(productIdProp ?? id);

  const { data: ingredientList } = useQueryProductIngredients({
    id: productIdProp ?? id,
  });

  const _ingredientList = ingredientListProps
    ? ingredientListProps
    : ingredientList;

  const disabledCarouselBtn = _ingredientList?.length <= MAX_SLIDE_TO_SHOW;

  return (
    <section
      className={classnames({
        'group-layout__ingredients-section': true,
        'group-layout__ingredients-section-empty':
          _ingredientList?.length === 0,
      })}
    >
      <GroupPart.GroupPartItem title='Ingredients'>
        <div style={{ margin: '8px 0' }}>
          {disabledCarouselBtn ? null : (
            <Space>
              <CarouselPrevious
                onClick={() => {
                  if (ref?.current) ref.current.prev();
                }}
                disabled={disabledCarouselBtn}
              />
              <CarouselNext
                onClick={() => {
                  if (ref?.current) ref.current.next();
                }}
                disabled={disabledCarouselBtn}
              />
            </Space>
          )}
        </div>
        <GroupIngredientsFormItem>
          <CarouselSection
            ref={ref}
            slidesToShow={MAX_SLIDE_TO_SHOW}
            className='group-layout__ingredients-carousel'
            variableWidth
            infinite={disabledCarouselBtn ? false : true}
          >
            {_ingredientList?.length > 0 ? (
              _ingredientList.map((ingredientItem) => (
                <GroupIngredients
                  value={ingredientItem?.index}
                  key={ingredientItem?.index}
                >
                  <GroupPart.PartStatements
                    className='scroller'
                    title={transformToTextGroupName(
                      ingredientItem?.groupIds,
                      productGroups
                    )}
                    statements={ingredientItem?.ingredients}
                  />
                </GroupIngredients>
              ))
            ) : (
              <GroupPart.EmptyGroup />
            )}
          </CarouselSection>
        </GroupIngredientsFormItem>
      </GroupPart.GroupPartItem>
    </section>
  );
};

export const AllergensGroup = ({
  allergenList: allergenListProps,
  productId: productIdProp,
}) => {
  const ref = useRef();
  const { id } = useParams();

  const { allergenGroups } = useGetProductAllergens(productIdProp ?? id);
  const { productGroups } = useGetAllGroupProduct(productIdProp ?? id);

  const _allergenList = allergenListProps ? allergenListProps : allergenGroups;

  const presentAllergenGroups = useMemo(
    () => filterIsPresentAllergens(_allergenList),
    [_allergenList]
  );

  const disabledCarouselBtn =
    presentAllergenGroups?.length <= MAX_SLIDE_TO_SHOW;

  return (
    <section
      className={classnames({
        'group-layout__allergens-section': true,
        'group-layout__allergens-section-empty':
          presentAllergenGroups?.length === 0,
      })}
    >
      <GroupPart.GroupPartItem title='Allergens'>
        <div style={{ margin: '8px 0' }}>
          {disabledCarouselBtn ? null : (
            <Space>
              <CarouselPrevious
                onClick={() => {
                  if (ref?.current) ref.current.prev();
                }}
                disabled={disabledCarouselBtn}
              />
              <CarouselNext
                onClick={() => {
                  if (ref?.current) ref.current.next();
                }}
                disabled={disabledCarouselBtn}
              />
            </Space>
          )}
        </div>
        <GroupAllergensFormItem>
          <CarouselSection
            ref={ref}
            slidesToShow={MAX_SLIDE_TO_SHOW}
            className='group-layout__allergens-carousel'
            variableWidth
            infinite={disabledCarouselBtn ? false : true}
          >
            {presentAllergenGroups?.length > 0 ? (
              presentAllergenGroups.map((allergenGroup) => (
                <GroupAllergens
                  value={allergenGroup.index}
                  key={allergenGroup.index}
                >
                  <GroupPart.PartStatements
                    className='scroller'
                    title={transformToTextGroupName(
                      allergenGroup?.groupIds,
                      productGroups
                    )}
                    statements={allergenGroup.allergens.map(
                      (allergen) => allergen.allergenType
                    )}
                  />
                </GroupAllergens>
              ))
            ) : (
              <GroupPart.EmptyGroup />
            )}
          </CarouselSection>
        </GroupAllergensFormItem>
      </GroupPart.GroupPartItem>
    </section>
  );
};

const MAX_FACTS_PANEL_TO_SHOW = 3;

export const FactsPanelGroup = ({
  factsPanelsGroup,
  productId: productIdProp,
}) => {
  const ref = useRef();

  const { id } = useParams();
  const { productGroups } = useGetAllGroupProduct(productIdProp ?? id);

  const disabledCarouselBtn =
    factsPanelsGroup?.length <= MAX_FACTS_PANEL_TO_SHOW;

  return (
    <section
      className={classnames({
        'group-layout__facts-panel-section': true,
        'group-layout__facts-panel-section-empty':
          factsPanelsGroup?.length === 0,
      })}
    >
      <GroupPart.GroupPartItem title='Facts Panel'>
        <div style={{ margin: '8px 0' }}>
          {disabledCarouselBtn ? null : (
            <Space>
              <CarouselPrevious
                onClick={() => {
                  if (ref?.current) ref.current.prev();
                }}
                disabled={disabledCarouselBtn}
              />
              <CarouselNext
                onClick={() => {
                  if (ref?.current) ref.current.next();
                }}
                disabled={disabledCarouselBtn}
              />
            </Space>
          )}
        </div>

        <div className='group-layout__facts-panel hidden-border-field-set'>
          <GroupFactsPanelFormItem>
            <CarouselSection
              ref={ref}
              variableWidth
              slidesToShow={MAX_FACTS_PANEL_TO_SHOW}
              infinite={disabledCarouselBtn ? false : true}
            >
              {factsPanelsGroup?.length > 0 ? (
                factsPanelsGroup?.map((factsPanel) => {
                  const FactsPanelType = getComponentFactsPanelType(factsPanel);

                  return (
                    <Space key={factsPanel.id}>
                      <GroupPart.GroupPartName
                        title={transformToTextGroupName(
                          factsPanel?.groupIds,
                          productGroups
                        )}
                      >
                        <GroupFactsPanelType value={factsPanel?.id}>
                          <FactsPanelType label={factsPanel} />
                        </GroupFactsPanelType>
                      </GroupPart.GroupPartName>
                    </Space>
                  );
                })
              ) : (
                <GroupPart.EmptyGroup />
              )}
            </CarouselSection>
          </GroupFactsPanelFormItem>
        </div>
      </GroupPart.GroupPartItem>
    </section>
  );
};
