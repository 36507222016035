import React from 'react';
import { useDispatch } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { DownloadOutlined } from '@ant-design/icons';

import * as maintenanceFormActions from 'pages/maintenance-form/controllers/actions';

const DownloadFormBtn = (props) => {
  const { selectedForms, disabled } = props;

  const dispatch = useDispatch();

  const downloadLink = selectedForms?.[0]?.downloadLink;

  const onClickDownloadBtn = () => {
    dispatch(maintenanceFormActions.downloadExportedFormSaga(downloadLink));
  };

  return (
    <RibbonButton
      icon={<DownloadOutlined />}
      label={'Download'}
      disabled={disabled}
      onClick={onClickDownloadBtn}
    />
  );
};

export default DownloadFormBtn;
