import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Messages from 'i18n/messages/home';
import { DeleteOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components/index';
import { CustomNotification } from 'common/components';

import { deleteReportingQuery } from 'services/reporting';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import ConfirmDeleteReporting from './ConfirmDeleteReporting';

const DeleteReporting = () => {
  const dispatch = useDispatch();

  const reloadPage = useDispatchReloadPage();
  const selectedItems = useSelector(gridSelector.makeSelectItemsSelection());
  const selectedDetailItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const isDisabledDelete = checkDisabledDeleteBtn(selectedDetailItems);

  const handleDeleteModal = () => {
    dialogFunction({
      type: 'warn',
      content: (
        <ConfirmDeleteReporting
          listData={selectedDetailItems}
          title={`Are you sure you want to delete ${selectedDetailItems?.length} selecting reports: `}
        />
      ),
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDeleteReporting,
    });
  };

  const handleDeleteReporting = () => {
    const params = {
      listQueries: selectedItems,
    };

    deleteReportingQuery(params)
      .then((res) => {
        if (res?.isSuccess) {
          CustomNotification.success('Delete successfully');
          dispatch(actionsGridView.deleteItemsSelection());
          reloadPage();
        } else {
          CustomNotification.error(
            res?.message || 'Delete failed! Please try!'
          );
        }
      })
      .catch((error) => {
        CustomNotification.error('Delete failed! Something go wrong!');
      });
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.deleteReport}
      onClick={handleDeleteModal}
      disabled={isDisabledDelete}
    />
  );
};

const checkAnyShareReportingItem = (items) =>
  items.some((item) => !item?.isOwner);

const checkDisabledDeleteBtn = (items) => {
  if (items?.length === 0) return true;

  if (checkAnyShareReportingItem(items)) return true;

  return false;
};

DeleteReporting.propTypes = {};

export default DeleteReporting;
