import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Row, Col, Typography, Space } from 'antd';

import ProductOverviewQuestionButton from './ProductOverviewQuestionButton';
import { SectionWrapper } from 'common/components';

const { Text } = Typography;

const ProductOverviewDimension = ({
  imperialHeight,
  imperialWidth,
  imperialDepth,
  imperialDimensionUOM,
  imperialWeight,
  imperialWeightUOM,
  metricHeight,
  metricWidth,
  metricDepth,
  metricDimensionUOM,
  metricWeight,
  metricWeightUOM,
  showPackageDrawer,
}) => {
  const isShowSupplied =
    isNaN(parseFloat(metricHeight)) &&
    isNaN(parseFloat(metricWidth)) &&
    isNaN(parseFloat(metricDepth)) &&
    isNaN(parseFloat(metricWeight));
  const isShowValidated =
    isNaN(parseFloat(imperialHeight)) &&
    isNaN(parseFloat(imperialWidth)) &&
    isNaN(parseFloat(imperialDepth)) &&
    isNaN(parseFloat(imperialWeight));

  const onArrangeDimensionInfo = () => {
    const imperialDimension = [
      { label: 'H', info: formatImperialDimension(imperialHeight) },
      { label: 'W', info: formatImperialDimension(imperialWidth) },
      { label: 'D', info: formatImperialDimension(imperialDepth) },
      {
        label: 'Weight',
        info: `${
          isNaN(parseFloat(imperialWeight))
            ? 'N/A'
            : Math.round(parseFloat(imperialWeight) * 100) / 100 +
              ' ' +
              (imperialWeightUOM || '')
        }`,
      },
    ];

    const metricDimension = [
      {
        label: 'H',
        info: formatMetricDimension(metricHeight),
      },
      { label: 'W', info: formatMetricDimension(metricWidth) },
      { label: 'D', info: formatMetricDimension(metricDepth) },
      {
        label: 'Weight',
        info: `${
          isNaN(parseFloat(metricWeight))
            ? 'N/A'
            : Math.round(parseFloat(metricWeight) * 100) / 100 +
              ' ' +
              (metricWeightUOM || '')
        }`,
      },
    ];
    const dimensions = {
      imperial: imperialDimension,
      metric: metricDimension,
    };
    // const dimensions = interleave(imperialDimension, metricDimension);
    return dimensions;
  };

  const formatImperialDimension = (size) =>
    `${
      isNaN(parseFloat(size))
        ? 'N/A'
        : Math.round(parseFloat(size) * 100) / 100 +
          ' ' +
          (imperialDimensionUOM || '')
    } `;

  const formatMetricDimension = (size) =>
    `${
      isNaN(parseFloat(size))
        ? 'N/A'
        : Math.round(parseFloat(size) * 100) / 100 +
          ' ' +
          (metricDimensionUOM || '')
    } `;

  const interleave = (arr1, arr2) => {
    let newArr = [];
    arr1.forEach((ele, index) => {
      newArr.push(ele, arr2[index]);
    });
    return newArr;
  };

  const renderDimensionItem = (item, index) => {
    const { infoAppend, info, label } = item;
    return (
      <Row key={index}>
        <Space align='baseline'>
          <Text style={{ fontWeight: 700 }}>{label}:</Text>
          <Text className='product-overview__metadata-dimensions-info-text'>
            {info}
          </Text>
          {infoAppend ? infoAppend : null}
        </Space>
      </Row>
    );
  };

  const productDimensionInfo = onArrangeDimensionInfo();

  return (
    <Row className='product-overview__metadata-dimensions' gutter={[8, 8]}>
      {!isShowSupplied && (
        <Col flex={1}>
          <SectionWrapper title={'Supplied'}>
            {productDimensionInfo?.metric?.map(renderDimensionItem)}
          </SectionWrapper>
        </Col>
      )}
      {!isShowValidated && (
        <Col flex={1}>
          <SectionWrapper title={'Validated'}>
            {productDimensionInfo?.imperial?.map(renderDimensionItem)}
          </SectionWrapper>
        </Col>
      )}
    </Row>
  );
};

ProductOverviewDimension.propTypes = {
  imperialHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imperialWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imperialDepth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imperialDimensionUOM: PropTypes.string,
  imperialWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imperialWeightUOM: PropTypes.string,
  metricHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metricWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metricDepth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metricDimensionUOM: PropTypes.string,
  metricWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metricWeightUOM: PropTypes.string,
};

export default ProductOverviewDimension;
