import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import * as helpMaintenanceActions from 'pages/maintenance/controllers/actions';

import Messages from 'i18n/messages/maintenance';

const EditCategory = (props) => {
  const dispatch = useDispatch();

  const { selectedCategory, isCreating } = props;

  const onClickHandler = () => {
    dispatch(helpMaintenanceActions.toggleEditCategory());
  };

  const isDisabled = selectedCategory?.length !== 1 || isCreating;

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editCategory}
      onClick={onClickHandler}
      disabled={isDisabled}
    />
  );
};

EditCategory.propTypes = {
  selectedCategory: PropTypes.array,
  isCreating: PropTypes.bool,
};

export default EditCategory;
