import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Col, Menu, Row, Space, Typography } from 'antd';

import {
  FormAddButton as AddButton,
  FormEditButton as EditButton,
  FormDeleteButton as DeleteButton,
  CustomNotification,
  dialogFunction,
  WithLoading,
} from 'common/components';

import {
  useCheckAllowEditProducts,
  useCheckAllowManageGroupData,
  useGetProductFullView,
} from 'hooks';

import {
  useMutationIngredients,
  useQueryProductIngredients,
} from './tab-ingredients/useProductIngredients';

import RenderIngredients from './tab-ingredients/RenderIngredients';
import IngredientForm from './tab-ingredients/IngredientsForm';
import { CarouselNext, CarouselPrevious } from './Carousel';

import {
  GroupActions,
  GroupAddition,
  GroupAssign,
  GroupDeletion,
  GroupEdit,
  GroupPreview,
  MenuItem,
} from './components';
import {
  getSelectedItemGroup,
  transformToTextGroupName,
} from '../shared/utils';
import {
  TYPE_GROUPS,
  useGetAllGroupProduct,
  useVisibleGroupModal,
} from '../shared/hooks';
import { filterProductGroups } from './tab-allergens/utils';
import { GroupLayoutProvider } from './components/GroupLayoutProvider';

import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

import './TabFactPanel.less';

const TabIngredients = () => {
  const { id } = useParams();

  const [visible, setVisible] = useState(false);
  const [ingredientId, setIngredientId] = useState(null);
  const [ingredientsMode, setIngredientsMode] = useState('add');

  const { productFull } = useGetProductFullView({ productId: id });
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const { data, isLoading } = useQueryProductIngredients({ id });
  const { productGroups } = useGetAllGroupProduct(id);

  const handleSelectIngredient = (id) => {
    if (id === ingredientId) setIngredientId(null);
    else setIngredientId(id);
  };

  const selectedIngredientGroup = data?.find(
    (ingredientGroup) => ingredientGroup?.index === ingredientId
  );

  const {
    visibleModalAddGroup,
    visibleModalEditGroup,
    visibleModalPreviewGroup,
    visibleModalAssignGroup,
    visibleModalDeleteGroup,
    handler: { onGroupModal, offGroupModal },
  } = useVisibleGroupModal();

  const selectedIngredientGroupIds = selectedIngredientGroup?.groupIds ?? [];

  const { defaultGroup, selectedProductGroups } = getSelectedItemGroup(
    selectedIngredientGroupIds,
    productGroups
  );

  const filteredProductGroups = filterProductGroups(
    selectedIngredientGroupIds,
    productGroups
  );

  const mutation = useMutationIngredients({ setIngredientId, id });

  const confirmDelete = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning
          text='selected Ingredient'
          ixoneIgnore={ixoneIgnore}
        >
          Are you sure you want to delete the selected Ingredient?
        </IXONEShieldWarning>
      ),
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        const params = {
          productId: id,
          ingredientGroups: [
            {
              index: ingredientId,
              isRemoved: true,
              ingredients: data[ingredientId]?.ingredients,
            },
          ],
        };
        await mutation.mutateAsync(params, {
          onSuccess: async (result) => {
            if (result?.isSuccess) {
              setIngredientId(null);
              CustomNotification.success('Delete Ingredients successfully.');
              await handleUpdateIXONEShield(ixoneIgnore);
            } else {
              CustomNotification.error(result?.message);
            }
          },
          onError: () => {
            CustomNotification.error('Delete failed!');
          },
        });
      },
    });
  };

  const handleEditPanel = async () => {
    setVisible(true);
    setIngredientsMode('edit');
  };

  const handleAddPanel = async () => {
    setIngredientId(null);
    setIngredientsMode('add');
    setVisible(true);
  };

  const isAllowEditProduct = checkAllowEditProductFull(productFull);
  const isDisable = !isAllowEditProduct || ingredientId == null;
  const hasManageGroupPermission = useCheckAllowManageGroupData();

  return (
    <>
      <Row className='tab-fact-panels__list-button' justify='space-between'>
        <Col>
          <Space>
            <Space>
              <CarouselPrevious disabled />
              <CarouselNext disabled />
            </Space>
            <TotalPanels totalValue={data?.length} />
          </Space>
        </Col>
        <Col>
          <Space>
            <AddButton
              onClick={handleAddPanel}
              disabled={!isAllowEditProduct}
            />
            <EditButton onClick={handleEditPanel} disabled={isDisable} />
            <IXONECertificationWrapper
              showModal
              type={TYPE_VIEW.INGREDIENTS}
              onConfirm={(ixoneIgnore) => {
                confirmDelete(ixoneIgnore);
              }}
            >
              {(handleConfirmIXONE) => (
                <DeleteButton
                  onClick={handleConfirmIXONE}
                  disabled={isDisable || data?.length === 1}
                />
              )}
            </IXONECertificationWrapper>
            <GroupActions
              overlay={() => (
                <Space>
                  <Menu>
                    <MenuItem>
                      <MenuItem.AddGroup
                        disabled={!hasManageGroupPermission}
                        onClick={() => onGroupModal(TYPE_GROUPS.addGroup)}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.PreviewGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.previewGroup)}
                        disabled={selectedProductGroups?.length === 0}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.AssignGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.assignGroup)}
                        disabled={
                          ingredientId === null ||
                          filteredProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.EditGroup
                        disabled={
                          ingredientId === null ||
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                        onClick={() => onGroupModal(TYPE_GROUPS.editGroup)}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.DeleteGroup
                        onClick={() => {
                          onGroupModal(TYPE_GROUPS.deleteGroup);
                        }}
                        disabled={
                          ingredientId === null ||
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                      />
                    </MenuItem>
                  </Menu>
                </Space>
              )}
            >
              <GroupActions.ButtonWrapper />
            </GroupActions>
          </Space>
        </Col>
      </Row>
      <WithLoading loading={isLoading}>
        <div className='scroller multiple-panel__tab-ingredients'>
          {data?.map((item) => {
            const { index, ingredients, groupIds } = item ?? {};
            const title = transformToTextGroupName(groupIds, productGroups);
            return (
              <RenderIngredients
                key={index}
                title={title}
                ingredients={ingredients}
                isSelected={index === ingredientId}
                onClickIngredients={() => handleSelectIngredient(index)}
              />
            );
          })}
        </div>
        {visible && (
          <IngredientForm
            visible={visible}
            setVisible={setVisible}
            ingredientsMode={ingredientsMode}
            currentIngredient={data?.[ingredientId]}
            onReset={() => {
              setIngredientId(null);
            }}
          />
        )}
      </WithLoading>

      {visibleModalPreviewGroup && (
        <GroupPreview
          visible={visibleModalPreviewGroup}
          onCancel={offGroupModal}
          defaultGroupId={defaultGroup?.groupId}
          productGroups={selectedProductGroups}
        />
      )}

      {visibleModalAddGroup && (
        <GroupAddition
          title='Create Group'
          visible={visibleModalAddGroup}
          onCancel={offGroupModal}
        />
      )}

      {visibleModalEditGroup && (
        <GroupLayoutProvider>
          <GroupEdit
            title='Edit Group'
            visible={visibleModalEditGroup}
            onCancel={offGroupModal}
            defaultGroupId={defaultGroup?.groupId}
            productGroups={selectedProductGroups}
          />
        </GroupLayoutProvider>
      )}

      {visibleModalAssignGroup && (
        <GroupAssign
          visible={visibleModalAssignGroup}
          onCancel={offGroupModal}
          defaultGroupId={filteredProductGroups?.[0]?.groupId}
          productGroups={filteredProductGroups}
          additionalIngredientsGroups={[selectedIngredientGroup]}
          selectedGroupIds={selectedIngredientGroupIds}
        />
      )}

      {visibleModalDeleteGroup && (
        <GroupDeletion
          productId={id}
          onCancel={offGroupModal}
          visible={visibleModalDeleteGroup}
          productGroups={selectedProductGroups}
        />
      )}
    </>
  );
};

export default TabIngredients;

const TotalPanels = ({ totalValue }) => {
  return (
    <Typography.Text strong>Total: {totalValue} Ingredient(s)</Typography.Text>
  );
};
