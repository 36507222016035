import { useEffect } from 'react';
import { getPartyInfo } from 'services/product';

import { useAsync } from 'hooks';

const checkProductStatus = (items) => {
  return items.some((item) => item?.status !== 'Active');
};

const validateProductOwner = (items) => {
  return items.every((item) => Boolean(item?.isOwner));
};

const validateAllActiveProducts = (items) => {
  return items.every((item) => item?.status?.toLowerCase() === 'active');
};

const validateSubscriptedShield = (item) =>
  item?.subscriptionShield?.toLowerCase() !== 'none' &&
  item?.subscriptionShield?.toLowerCase() !== 'null';

const checkProductsSubscriptionShield = (items) => {
  return items.some((item) => validateSubscriptedShield(item));
};

const verifyGDSNProductShield = (item) =>
  item?.gdsnShield !== null && item?.gdsnShield !== 'false';

const checkIsGDSNProducts = (items) => {
  return items.some((item) => verifyGDSNProductShield(item));
};

const checkIsNotSyndication = (items) => {
  return items.some((item) => item?.syndication !== true);
};

const useGetPartyInfo = ({ searchText, pageIndex, pageSize }) => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    const params = {
      filters: [],
      pageIndex,
      pageSize,
      search: { searchText },
      sort: [{ fieldName: 'id', isAscending: false }],
    };

    run(getPartyInfo(params));
  }, [pageIndex, pageSize, run, searchText]);

  return {
    gridData: data?.gridData ?? [],
    paging: data?.paging ?? {},
    isLoading: status === 'pending',
  };
};

const mapToPublishProductCheckListRequest = ({
  products,
  recipientGLNs,
  formValues,
  productFull,
  isFullView,
}) => {
  const productIds = products.map((items) => items.id);
  const recipientIds = recipientGLNs?.map((items) => items.partyGln);
  let params = {
    ...formValues,
    productIds: isFullView ? [productFull?.productId] : productIds,
    recipientGLNs: recipientIds,
  };

  return params;
};

export {
  validateProductOwner,
  validateAllActiveProducts,
  checkProductStatus,
  checkProductsSubscriptionShield,
  checkIsGDSNProducts,
  checkIsNotSyndication,
  useGetPartyInfo,
  mapToPublishProductCheckListRequest,
  verifyGDSNProductShield,
  validateSubscriptedShield,
};
