import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';

import * as productServices from 'services/product';
import { forwardTo } from 'utils/common/route';

export const productFullViewKeys = {
  itemData: (productId) => ['product-full-view', parseInt(productId)],
};

const defaultProductFull = {};

export const useGetProductFullView = ({ productId, ...otherOptions } = {}) => {
  const queryClient = useQueryClient();

  const queries = useQuery({
    queryKey: productFullViewKeys.itemData(productId),
    queryFn: async () => {
      const response = await productServices.getProductFullView({
        productItemId: productId,
      });

      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
    enabled: Boolean(productId),
    retry: 1,
    ...otherOptions,
  });

  const handleRefetchProductFullView = () => {
    queryClient.invalidateQueries({
      queryKey: productFullViewKeys.itemData(productId),
    });
  };

  return {
    productFull: queries?.data ?? defaultProductFull,
    handleRefetchProductFullView,
    ...queries,
  };
};
