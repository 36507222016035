import React from 'react';
import { useSelector } from 'react-redux';

import { Menu, Dropdown } from 'antd';
import { SubnodeOutlined } from '@ant-design/icons';

import {
  DownloadFormItem,
  MinimumRequirementsValidate,
} from 'common/components';
import Download from './Download';
import { RibbonButton } from 'common/components';

import SubscriptionMember from './SubscriptionMember';
import HistoryEntity from 'pages/home/ribbon/form-manange/components/form-history-grid/HistoryEntity';

import gridSelectors from 'common/components/grid-view/controllers/selectors';
import MoveAllToMember from 'pages/home/ribbon/components/sections/shared/MoveAllToMember';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import messages from 'i18n/messages/home';

const MemAction = () => {
  const selectedItemList =
    useSelector(gridSelectors.makeSelectDetailCurrentITemsSelection()) ?? [];

  const ids = selectedItemList.map((item) => item.id);

  const isDisabledAddition = selectedItemList?.length === 0;
  const menuOptions = (
    <Menu>
      <Menu.Item>
        <Download entityType='MEM' />
      </Menu.Item>
      <Menu.Item>
        <SubscriptionMember entityType='MEM' />
      </Menu.Item>
      <Menu.Item>
        <DownloadFormItem
          selectedEntityIds={ids}
          entityType='MEM'
          disabled={isDisabledAddition}
        />
      </Menu.Item>
      <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL}>
        <Menu.Item>
          <MoveAllToMember
            disabled={selectedItemList?.length !== 1}
            selectedMember={selectedItemList?.[0]}
          />
        </Menu.Item>
      </Can>
      <Menu.Item>
        <HistoryEntity
          size='small'
          entityId={selectedItemList?.length > 0 && selectedItemList[0].id}
          entityType='MEM'
          disabled={selectedItemList?.length !== 1}
        />
      </Menu.Item>
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.MEMBER}>
        <Menu.Item>
          <MinimumRequirementsValidate
            entity={
              selectedItemList.length === 1 && {
                id: selectedItemList[0].id,
                name: selectedItemList[0].memberName,
                type: 'Member',
                data: selectedItemList[0],
              }
            }
          />
        </Menu.Item>
      </Can>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
        <RibbonButton icon={<SubnodeOutlined />} label={messages.memActions} />
      </Dropdown>
    </>
  );
};

export default MemAction;
