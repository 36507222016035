import React from 'react';

import { Tooltip } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import classnames from 'classnames';

const GdsnShieldIcon = (props) => {
  const { gdsnShield } = props;

  const gdsnShieldColour = gdsnShield?.toLowerCase();

  const showSafetyShield = () => {
    let tooltipContent = 'Subscribed Product';
    if (gdsnShieldColour === 'yellow')
      tooltipContent = 'Received from GDSN, and modified';
    if (gdsnShieldColour === 'green') tooltipContent = 'Received from GDSN';
    return (
      <Tooltip title={tooltipContent} placement='top'>
        <SafetyOutlined
          className={classnames({
            'thumbnail-item__gdsn-shield': true,
            'thumbnail-item__gdsn-shield--yellow':
              gdsnShieldColour === 'yellow',
            'thumbnail-item__gdsn-shield--green': gdsnShieldColour === 'green',
          })}
        />
      </Tooltip>
    );
  };

  return <>{showSafetyShield()}</>;
};

export default GdsnShieldIcon;
