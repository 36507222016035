import React from 'react';
import { Row, Col, Slider, InputNumber, Typography } from 'antd';

const { Text } = Typography;

const SliderNumber = (props) => {
  const {
    style,
    labelStyle,
    labelClassName,
    labelWrapClassName,
    labelwrapStyle,
    className,
    onChange,
    value,
    disabled,
    label,
    min,
    max,
  } = props;

  return (
    <Row className={className} style={style || {}}>
      <Col xs={24} className={labelWrapClassName} style={labelwrapStyle || {}}>
        <Text
          className={labelClassName}
          style={{ paddingLeft: 5, ...labelStyle } || { paddingLeft: 5 }}
        >
          {label}
        </Text>
      </Col>
      <Col span={12}>
        <Slider
          min={min}
          max={max}
          onChange={onChange && onChange}
          value={value}
          disabled={disabled}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          min={min}
          max={max}
          style={{ margin: '0 16px' }}
          value={value}
          onChange={onChange && onChange}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default SliderNumber;
