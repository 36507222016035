import React, { useState, useRef, useMemo, useEffect } from 'react';

import { Row, Col, Button, Typography, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';

import {
  FormAddButton,
  FormDeleteButton,
  FormCancelButton,
  FormSaveButton,
  dialogFunction,
  CustomNotification,
  WithLoading,
} from 'common/components';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import QaGridEdit from '../qa-grid-edit/QaGridEdit';

import { saveQaSpecDataManufacturingProcess } from 'services/qaSpec';

import { getAllRows } from 'pages/qa-spec/utils';
import {
  useCheckAllowEdit,
  useCheckSnapshotForRetailer,
} from 'pages/qa-spec/hooks';

import { validateRowData, generateColumDefs } from './utils';

import { processStageCols } from './constants';

import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';
import { useQaGetProcessStageSnapshot } from './hooks';

import './QaSpecManufacturingProcess.less';

const QaSpecProcessStage = ({
  tableStyle,
  productEnums,
  manufacturingStageQuery,
  productId,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [statusSubmit, setStatusSubmit] = useState('idle');
  const [selectedRows, setSelectedRows] = useState([]);

  const gridApi = useRef();

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const { rowData, refetchManufacturingStageData, isFetchingGrid } =
    manufacturingStageQuery;

  const { qaProcessStageSnapshot } = useQaGetProcessStageSnapshot();

  const handleAddProcessStage = () => {
    if (gridApi?.current) {
      const id = uuidv4();
      const newRowIdx = gridApi.current.api.getRenderedNodes()?.length;

      gridApi.current.api.applyTransaction({
        add: [
          {
            id,
            processStage: null,
            natureOfTheTests: null,
            targetValueAndTolerances: null,
            frequency: null,
            test: '',
            recognizedMethods: '',
            criticalPointEstablishedInHACCP: null,
            errors: [],
          },
        ],
        addIndex: newRowIdx,
      });

      gridApi.current.api.ensureIndexVisible(newRowIdx);
      const newRow = gridApi.current.api.getRowNode(id);
      const firstCol = gridApi.current.columnApi.getAllDisplayedColumns()[0];

      newRow.setSelected(true, true);
      gridApi.current.api.setFocusedCell(newRowIdx, firstCol);
    }
  };

  const handleSaveProcessStage = async () => {
    if (gridApi?.current) {
      gridApi.current.api.stopEditing();

      const rowData = getAllRows(gridApi);

      setStatusSubmit('loading');

      try {
        const params = {
          productId,
          processes: rowData,
        };

        const response = await saveQaSpecDataManufacturingProcess(params);

        if (response?.isSuccess) {
          setStatusSubmit('success');
          setEditMode(false);
          setSelectedRows([]);

          CustomNotification.success(
            'Update Manufacturing Process data successfully!'
          );
          refetchManufacturingStageData();
        } else {
          setStatusSubmit('error');
          CustomNotification.error(
            response?.message ?? 'Something went wrong!'
          );
        }
      } catch (error) {}
    }
  };

  const handleCancelProcessStage = () => {
    setEditMode(false);
    setSelectedRows([]);
    gridApi.current.api.setRowData(rowData);
  };

  const handleDeleteProcessStage = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => {
        const selectedRows = gridApi.current.api.getSelectedNodes();

        const removeList = selectedRows.map(
          (selectedRowItem) => selectedRowItem?.data
        );

        gridApi.current.api.applyTransaction({
          remove: removeList,
        });

        const allRowData = getAllRows(gridApi);
        const newRowData = validateRowData(allRowData);
        gridApi.current.api.applyTransaction({
          update: newRowData,
        });
      },
    });
  };

  const handleCellEditingStopped = (event) => {
    const colId = event.column.colId;

    const allRowData = getAllRows(gridApi);

    if (colId === 'processStage') {
      const newRowData = validateRowData(allRowData);

      gridApi.current.api.applyTransaction({
        update: newRowData,
      });
    }
    return;
  };

  return (
    <div className='process-stage'>
      <Row style={{ width: '100%' }}>
        <Col span={6}>
          <Typography.Title level={5} strong style={{ marginBottom: 0 }}>
            Process Stage
          </Typography.Title>
        </Col>
        <Col span={18}>
          {!isQASpecLocked ? (
            <EditSection
              isEditMode={editMode}
              onEditBtnClick={() => setEditMode(true)}
              productId={productId}
              style={{ marginRight: 4, width: '100%', height: 30 }}
            >
              <Space direction='horizontal' size={4}>
                <FormAddButton text='Add' onClick={handleAddProcessStage} />
                <EditTabSectionWrapper.SaveActionTriggerWrapper
                  type={EDIT_SECTION.QA_MANUFACTURING_PROCESS_STAGE}
                  onSave={handleSaveProcessStage}
                >
                  <FormSaveButton
                    loading={statusSubmit === 'loading'}
                    onClick={handleSaveProcessStage}
                  />
                </EditTabSectionWrapper.SaveActionTriggerWrapper>

                <FormDeleteButton
                  onClick={handleDeleteProcessStage}
                  disabled={selectedRows?.length === 0}
                />
                <EditTabSectionWrapper.CancelActionTriggerWrapper
                  onCancel={handleCancelProcessStage}
                  type={EDIT_SECTION.QA_MANUFACTURING_PROCESS_STAGE}
                >
                  <FormCancelButton onClick={handleCancelProcessStage} />
                </EditTabSectionWrapper.CancelActionTriggerWrapper>
              </Space>
            </EditSection>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <WithLoading loading={isFetchingGrid}>
            <EditTabSectionWrapper.CheckerWrapper
              type={EDIT_SECTION.QA_MANUFACTURING_PROCESS_STAGE}
              isEdit={editMode}
            >
              <ProcessStageGrid
                editMode={editMode}
                gridApi={gridApi}
                rowData={rowData}
                tableStyle={tableStyle}
                onCellEditingStopped={handleCellEditingStopped}
                productEnums={productEnums}
                onRowSelected={() => {
                  const selectedRows = gridApi.current.api.getSelectedNodes();
                  setSelectedRows(selectedRows);
                }}
                qaProcessStageSnapshot={qaProcessStageSnapshot}
              />
            </EditTabSectionWrapper.CheckerWrapper>
          </WithLoading>
        </Col>
      </Row>
    </div>
  );
};

const ProcessStageGrid = ({
  rowData,
  editMode,
  gridApi,
  productEnums,
  tableStyle,
  onRowSelected,
  onCellEditingStopped,
  qaProcessStageSnapshot,
}) => {
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const mappedColumnDef = useMemo(() => {
    return generateColumDefs({
      processStageCols,
      productEnums,
      isToggleSnapshot,
    });
  }, [productEnums, isToggleSnapshot]);

  return (
    <Row style={{ width: '100%', marginTop: 8, ...tableStyle }}>
      {mappedColumnDef?.length > 0 && (
        <div
          style={{
            ...tableStyle,
            width: '100%',
          }}
          className={classnames(
            'ag-theme-alpine',
            'ag-theme-alpine--with-scroller',
            'process-stage__grid-container'
          )}
        >
          <QaGridEdit
            gridInst={gridApi}
            fields={mappedColumnDef}
            defaultColDef={{
              resizable: true,
            }}
            isEdit={editMode}
            gridData={cloneDeep(rowData)}
            getRowNodeId={(data) => {
              return data?.id;
            }}
            onCellEditingStopped={onCellEditingStopped}
            onRowSelected={onRowSelected}
            snapshotGridValues={qaProcessStageSnapshot}
          />
        </div>
      )}
    </Row>
  );
};

export const EditSection = ({
  isEditMode,
  onEditBtnClick,
  productId,
  children,
  ...otherProps
}) => {
  const hasPermissionEdit = useCheckAllowEdit(productId);
  return (
    <Row justify='end' {...otherProps}>
      {isEditMode ? (
        <>{children}</>
      ) : (
        <Col>
          {hasPermissionEdit && (
            <Button
              className='qa-manufacturing-process__edit-btn'
              icon={<EditOutlined />}
              style={{ border: 'none' }}
              onClick={onEditBtnClick}
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default QaSpecProcessStage;
