//* asset product api
export const GET_ASSET_PRODUCT_ENDPOINT = '/api/v1/assets';
export const ASSET_LIST_ENDPOINT = '/api/dam/digital-assets-grid-view';
export const ASSET_LIST_FAVORITE = '/api/favorites/get-favorites-data-assets';
export const DIGITAL_ASSET_DETAIL_GRID = '/api/dam/digital-asset-detail-grid';
export const DIGITAL_ASSET_DETAIL_GRID_DISTINCT =
  '/api/dam/digital-asset-detail-grid/distinct';
export const DIGITAL_ASSET_DETAIL_GRID_FAVORITE_DISTINCT =
  '/api/favorites/get-favorites-data-assets/distinct';
export const ASSET_SHORT_DETAIL = '/api/dam/asset-short-detail-view';
export const DELETE_ASSETS = '/api/dam/assets';
export const GET_ASSET_ASSOCIATION = '/api/dam/get-asset-association';
export const UPDATE_LINK_ASSET = '/api/dam/link-asset';
export const UPDATE_ASSET_TYPE = '/api/dam/change-asset-type-variable-fields';
export const UPDATE_LAST_GET_NEW_DAM = '/api/dam/update-last-get-new-dams';
