import React from 'react';

import { Divider } from 'antd';

import {
  ManageSharingSection,
  DetailSection,
  ViewLayout,
  AdvanceStack,
} from '../components';
import { RibbonBar, RibbonDivider } from 'common/components';
import BackControl from '../components/controls/home/Back';

import { FormHistoryDownloadSection } from './components';

const FormHistoryEntity = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <FormHistoryDownloadSection />
      <RibbonDivider />
      <BackControl ribbonSide='right' />
    </RibbonBar>
  );
};

export default FormHistoryEntity;
