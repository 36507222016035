import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { ViewLayout, AdvanceStack, OtherLayout } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import ProductHierarchy from './components/controls/product-full/ProductHierarchy';
import Subscription from './components/controls/product-full/Subscription';
import HistorySection from './components/sections/product/HistorySection';
import IXOneSection from 'pages/home/ribbon/components/sections/product/IXOneSection';
import ProductDetailView from './components/controls/product-full/ProductDetailView';
import ActionsRibbonBar from 'common/components/contact-action/ActionsRibbonBar';
import DetailQaSpec from './components/controls/product-full/DetailQaSpec';
// import QASpecAssignSupplier from './components/controls/product-full/QASpecAssignSupplier';
// import QaEvaluationForm from './components/controls/product-full/QaEvaluationForm';
import ProductPackaging from './components/controls/product-full/ProductPackaging';

import HierarchyActions from './components/controls/product-full/HierarchyActions';
import ProductActions from './components/controls/product-full/ProductActions';
import * as actionsGlobal from 'redux/global/actions';

import { useCheckAllowEditProducts, useGetProductFullView } from 'hooks';
import { useOpenReportingResult } from 'hooks/useOpenReportingResult';
import {
  useCheckPermissionOR,
  useCheckPermissions,
} from 'hooks/useCheckPermissions';
import { useFolderByLevel } from 'hooks/useFolderByLevel';
// import { useInitialWorkflowQaSpec } from 'hooks/useInitialWorkflowQaSpec';
import useCheckIXOneCapture from 'hooks/useCheckIXOneCapture';

import { PERMISSION_VIEW_PRODUCT } from 'static/Permission';

const ProductFullViewRibbon = ({
  qaEvaluationFormId,
  isFetchingEvaluationForm,
  isHaveAddOpenItem = true,
  productId,
  shouldShowPackaging = true,
}) => {
  const dispatch = useDispatch();
  const { keepSetting, keepResultGridPagination } = useOpenReportingResult();
  const checkPermission = useCheckPermissionOR();

  const isShowIXOneCapture = useCheckIXOneCapture();

  const hasQaSpecPermission = checkPermission([
    [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.QA_SPECIFICATION],
  ]);

  const hasQaSpecPackingPermission = checkPermission([
    [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.QA_SPECIFICATION],
    [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.QA_SPECIFICATION],
  ]);

  // const hasEvaluationFormPermission = checkPermission([
  //   [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.EVALUATION_FORM],
  // ]);

  const { productFull } = useGetProductFullView({ productId });

  const hasPermissionViewProduct = useCheckPermissions(PERMISSION_VIEW_PRODUCT);

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const { shouldKeepFolderBreadcrumb, keepFolderBreadcrumb } =
    useFolderByLevel();

  const closeDetailCallback = () => {
    keepSetting();
    keepResultGridPagination();

    //* keep folder breadcrumb when close folder item detail
    shouldKeepFolderBreadcrumb && keepFolderBreadcrumb(true);
  };

  //* Reset not go back to members route.
  //* In case user go form member full view -> company's product.
  useEffect(() => {
    dispatch(actionsGlobal.resetProdAssetsToMember());
  }, [dispatch]);

  // const { checkInitialWorkflowQaSpecRibbon } = useInitialWorkflowQaSpec();
  // const isInitialWorkflowQaSpecRibbon = checkInitialWorkflowQaSpecRibbon();
  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <RibbonDivider />
      <ProductDetailView productId={productId} />

      <ProductActions productFull={productFull} productId={productId} />

      <RibbonDivider />
      {shouldShowPackaging && hasPermissionViewProduct ? (
        <ProductPackaging productFull={productFull} />
      ) : null}
      <ProductHierarchy productId={productId} />
      {isAllowEditProduct ? <Subscription productId={productId} /> : null}

      <RibbonDivider />

      <HierarchyActions productId={productId} />

      <HistorySection />

      <RibbonDivider />
      {!isAllowEditProduct && (
        <>
          <ActionsRibbonBar
            view='product-shared'
            selectedItemList={[
              { id: productFull?.ownerId, memberName: productFull?.ownerName },
            ]}
          />
          <RibbonDivider />
        </>
      )}

      {/* {hasQaSpecPermission && <DetailQaSpec productData={productFull} />} */}

      {/* {isInitialWorkflowQaSpecRibbon && (
        <>
          <QASpecAssignSupplier productData={productFull} />
          <RibbonDivider />
        </>
      )} */}

      {/* {hasEvaluationFormPermission && (
        <>
          <QaEvaluationForm
            qaEvaluationFormId={qaEvaluationFormId}
            isFetchingEvaluationForm={isFetchingEvaluationForm}
            productData={productFull}
          />
          <RibbonDivider />
        </>
      )} */}
      {/* {isShowIXOneCapture ? (
        <>
          <IXOneSection width='30px' label='IX-ONE Capture' />
          <RibbonDivider />
        </>
      ) : null} */}

      <AdvanceStack isDisabled={true} />
      {/* {isBpSsoProductValidate &&
        bpSsoProductData.productId == productFull?.productId && (
          <>
            <RibbonDivider />
            <SsoProductComplete />
            <SsoProductCancel />
          </>
        )} */}
      <OtherLayout
        ribbonSide='right'
        ribbonType='products'
        isHaveAddOpenItem={isHaveAddOpenItem}
        closeDetailCallback={closeDetailCallback}
      />
    </RibbonBar>
  );
};

export default ProductFullViewRibbon;
