const starValues = [
  {
    id: 1,
    full: false,
    half: false,
  },
  {
    id: 2,
    full: false,
    half: false,
  },
  {
    id: 3,
    full: false,
    half: false,
  },
  {
    id: 4,
    full: false,
    half: false,
  },
  {
    id: 5,
    full: false,
    half: false,
  },
];

const getClassNameStar = (star) => {
  let classNameStar = 'rv-chat-rate-star';
  if (star.half) {
    return `${classNameStar} rv-chat-rate-star-half rv-chat-rate-star-active`;
  }

  if (star.full) {
    return `${classNameStar} rv-chat-rate-star-full`;
  }

  return `${classNameStar} rv-chat-rate-star-zero`;
};

const getDefaultStarValues = (rating) => {
  if (!rating) return starValues;

  // rating is even
  if (rating % 2 === 0) {
    return starValues.map((star) => {
      if (star.id <= parseInt(rating / 2)) {
        return {
          ...star,
          full: true,
        };
      }
      return star;
    });
  } else {
    const indexStar = Math.round(rating / 2);
    return starValues.map((star) => {
      if (star.id < indexStar)
        return {
          ...star,
          full: true,
        };

      if (star.id === indexStar)
        return {
          ...star,
          half: true,
        };

      return star;
    });
  }
};

const getNewStars = (rating) => {
  const indexStar = Math.round(rating / 2);
  const newStars = starValues.map((star) => {
    if (rating % 2 === 0) {
      if (star.id <= indexStar) {
        return {
          ...star,
          full: true,
        };
      }
      return star;
    } else {
      if (star.id < indexStar) {
        return {
          ...star,
          full: true,
        };
      }

      if (star.id === indexStar) {
        return {
          ...star,
          half: true,
        };
      }
      return star;
    }
  });

  return newStars;
};

const calculatorRating = (idStar, typeStar) => {
  if (!idStar) return;

  if (typeStar === 'half') return 2 * (idStar - 1) + 1;

  return 2 * idStar;
};

export {
  calculatorRating,
  getClassNameStar,
  getDefaultStarValues,
  getNewStars,
  starValues,
};
