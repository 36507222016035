import React from 'react';
import { Col, Tag, Typography, Tooltip } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';

const COLORS = ['#0070cc', '#10ac84', '#ffb142'];

const { Text } = Typography;

const SearchItemSelectList = (props) => {
  const { selectedItems, handleSelectItem, tagRender, style = {} } = props;

  const forMap = (itemData, idx) => {
    const ItemComponent = tagRender;

    const isCustomTag = tagRender && typeof tagRender === 'function';

    const TagWrap = isCustomTag ? CustomTagWrap : Tag;

    let renderContent =
      !isCustomTag && tagRender?.value && tagRender.value(itemData);

    const onClose = (e) => {
      e.preventDefault();
      handleSelectItem(itemData);
    };

    const tagProps = {
      onClose,
      closable: true,
      color: COLORS[idx % COLORS.length],
    };

    const tagElem = (
      <Tooltip title={itemData?.productName}>
        <TagWrap
          {...(!isCustomTag && tagProps)}
          style={{
            marginBottom: 4,
            maxWidth: '100%',
            paddingRight: 18,
            position: 'relative',
          }}
        >
          {isCustomTag ? (
            <ItemComponent itemData={itemData} onClose={onClose} idx={idx} />
          ) : (
            <Text style={{ color: '#f8f8f8' }} isEllips>
              {renderContent || itemData?.id}
            </Text>
          )}
        </TagWrap>
      </Tooltip>
    );

    return (
      <span
        key={itemData?.id}
        //  style={{ display: 'inline-block' }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = (
    selectedItems !== 'init' && selectedItems !== undefined ? selectedItems : []
  ).map(forMap);

  return (
    <div
      className='scroller'
      style={{ flex: 1, padding: '0 5px', height: '100%', ...style }}
    >
      <TweenOneGroup
        enter={{
          scale: 1,
          opacity: 0.5,
          type: 'from',
          duration: 100,
          onComplete: (e) => {
            e.target.style = '';
          },
        }}
        leave={{ opacity: 0, width: 0, scale: 0, duration: 0 }}
        appear={false}
        style={{ width: '100%' }}
      >
        {tagChild}
      </TweenOneGroup>
    </div>
  );
};

const CustomTagWrap = (props) => {
  const { onClose, children, ...restProps } = props;
  return (
    <div style={{ display: 'block' }} {...restProps}>
      {children}
    </div>
  );
};

export default SearchItemSelectList;
