import React from 'react';
import { Tooltip } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';

import messages from 'i18n/messages/home';
import classNames from 'classnames';

import { RibbonButton, RibbonDivider } from 'common/components';

import './GdsnShield.less';

const GdsnShield = (props) => {
  const { gdsnShield } = props;
  const gdsnShieldColour = gdsnShield?.toLowerCase();

  const showSafetyShield = () => {
    let tooltipContent = 'Subscribed Product';
    if (gdsnShieldColour === 'yellow')
      tooltipContent = 'Received from GDSN, and modified';
    if (gdsnShieldColour === 'green') tooltipContent = 'Received from GDSN';
    return (
      <Tooltip title={tooltipContent} placement='top'>
        <RibbonButton
          icon={<SafetyOutlined style={{ height: 26 }} />}
          label={messages.GdsnShield}
          className={classNames({
            'gdsn-shield': true,
            'gdsn-shield__yellow': gdsnShieldColour === 'yellow',
            'gdsn-shield__green': gdsnShieldColour === 'green',
          })}
        />
      </Tooltip>
    );
  };

  return gdsnShield ? (
    <>
      {showSafetyShield()}
      <RibbonDivider />
    </>
  ) : null;
};

export default GdsnShield;
