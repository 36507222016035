/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line jsx-a11y/alt-text

import React from 'react';

import classnames from 'classnames';

import { Row, Avatar } from 'antd';
import PropTypes from 'prop-types';
import iframe from 'assets/iframe.png';

import { useImageLoaded } from 'hooks';

import { Images } from 'config/assets';

const AssetTileHeader = (props) => {
  const { dataDetail } = props;

  // Viet - get image with version suffix to prevent cache image
  const imageThumbnail = dataDetail?.thumbSize300;
  const image = dataDetail?.isIFrame ? iframe : dataDetail && imageThumbnail;

  const [ref, loaded, onLoad] = useImageLoaded();

  return (
    <Row className='asset-tile__header' justify='center'>
      <div className='asset-tile__header--background'></div>

      <div className='asset-tile__header--image-wrapper'>
        <Avatar
          shape='square'
          className={classnames({
            'asset-tile__header--image': true,
            'asset-tile__header--image-loading': !loaded,
          })}
          alt={dataDetail && dataDetail.assetName}
        >
          <img
            style={{ width: '100%', height: '100%' }}
            ref={ref}
            src={image ? image.toUrl() : Images.RIVIR_LOGO_DEFAULT}
            onLoad={onLoad}
            loading='lazy'
          />
        </Avatar>
      </div>
    </Row>
  );
};

AssetTileHeader.propTypes = {
  dataDetail: PropTypes.object,
};

export default AssetTileHeader;
