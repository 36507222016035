import { put, call, all, delay, select } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import * as types from './constants';
import * as actions from './actions';
import * as globalSelectors from 'redux/global/selectors';
import * as ssoProductSelectors from 'pages/sso-product/controllers/selectors';
import { DEFAULT_SORT } from 'static/Constants';
import * as services from 'services/product';
import * as servicesSubscription from 'services/subscription';
import * as servicesGrid from 'services/grid';
import {
  mapToMappingGridColDef,
  mapToMappingSupportMemGridColDef,
} from './mapper/mapToGridColumn';

import { formatMDYWithParam } from 'utils/formatDate';
import { forwardTo } from 'utils/common/route';

export function* getProductHierarchyShortSaga(action) {
  try {
    const response = yield call(services.getProductHierarchiesShortDetail, {
      ...action.payload,
    });

    const { isSuccess, data, message } = response;

    if (isSuccess) {
      yield put(
        actions.getProductHierarchyShortDetailSuccess(data?.productHierarchies)
      );
    } else {
      yield put(actions.getProductHierarchyShortDetailError(message));
    }
  } catch (error) {
    yield put(actions.getProductHierarchyShortDetailError(error));
  }
}

export function* getSyndicationHistoryShortDetailsSaga(payload) {
  try {
    const response = yield call(
      services.getSyndicationHistoryShortDetailsService,
      {
        id: payload.id,
      }
    );
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getSyndicationHistoryShortDetailsSuccess(data));
    } else {
      yield put(actions.getSyndicationHistoryShortDetailsError(message));
    }
  } catch (error) {
    yield put(actions.getSyndicationHistoryShortDetailsError(error));
  }
}

/**
 * Root saga manages watcher lifecycle
 */

export function* getProductDocumentsSaga(payload) {
  try {
    const response = yield call(services.getProductDocumentsService, {
      ...payload.params,
    });
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getProductDocumentsSuccess(data));
    } else {
      yield put(actions.getProductDocumentsError(message));
    }
  } catch (error) {
    yield put(actions.getProductDocumentsError(error));
  }
}

export function* getProductBrandImageSaga(action) {
  try {
    const response = yield call(services.getProductBrandImage, action.payload);
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getProductBrandImageSuccess(data));
    } else {
      yield put(actions.getProductBrandImageFail(message));
    }
  } catch (error) {
    yield put(actions.getProductBrandImageFail('error'));
  }
}

export function* getProductSocialMediaSaga(action) {
  try {
    const response = yield call(
      services.getProductSocialMedia,
      action?.payload
    );
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getProductSocialMediaSuccess(data));
    } else {
      yield put(actions.getProductSocialMediaFail(message));
    }
  } catch (error) {
    yield put(actions.getProductSocialMediaFail('error'));
  }
}

export function* getMarketingAssets(action) {
  try {
    const submitData = {
      ProductId: action.payload.id,
      IsShowAll: true,
    };
    const response = yield call(services.getMarketingAssets, submitData);
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      let formatData =
        data?.gridData?.length > 0
          ? data.gridData.map((item) => ({
              ...item,
              id: item?.productImageLinkId,
            }))
          : [];
      yield put(actions.getMarketingAssetsSuccess(formatData || []));
    } else {
      yield put(actions.getMarketingAssetsFail(message));
    }
  } catch (error) {
    yield put(actions.getMarketingAssetsFail('error'));
  }
}

export function* getMarketingAssetsPrimary(action) {
  try {
    const submitData = {
      ProductId: action.payload.id,
      IsShowAll: false,
    };
    const response = yield call(services.getMarketingAssets, submitData);
    const { isSuccess, data, message } = response;

    if (isSuccess) {
      yield put(actions.getMarketingAssetsPrimarySuccess(data?.gridData));
    } else {
      yield put(actions.getMarketingAssetsPrimaryFail(message));
    }
  } catch (error) {
    yield put(actions.getMarketingAssetsPrimaryFail('error'));
  }
}

export function* getProductFactsPanelSaga(payload) {
  try {
    const response = yield call(services.getProductFactsPanel, payload.params);
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getProductFactPanelSuccess(data));
    } else {
      yield put(actions.getProductFactPanelError(message));
    }
  } catch (error) {
    yield put(actions.getProductFactPanelError(error));
  }
}

export function* updateProductFactsPanelSaga(payload) {
  const params = { ProductItemId: payload?.params?.productItemId };
  try {
    const response = yield call(
      services.updateProductFactPanel,
      payload.params
    );
    const { isSuccess, message } = response;
    if (isSuccess) {
      yield put(actions.updateProductFactPanelSuccess(isSuccess));
      yield delay(1000);
      yield call(getProductFactsPanelSaga, { params });
    } else {
      yield put(actions.updateProductFactPanelError(message));
    }
  } catch (error) {
    yield put(actions.updateProductFactPanelError(error));
  }
}
export function* getProductImagery(action) {
  const { hierarchyId, productId } = action.payload;

  const params = { ProductItemHierarchyId: hierarchyId, productId };
  try {
    const response = yield call(services.getProductImagery, params);
    const { isSuccess, data, message } = response;

    if (isSuccess) {
      yield put(actions.getProductImagerySuccess(data));
    } else {
      yield put(actions.getProductImageryFail(message));
    }
  } catch (error) {
    yield put(actions.getProductImageryFail('error'));
  }
}

// Product hierarchy short details to show drawer
export function* getProductHierarchyShortDetailsSaga(payload) {
  try {
    const response = yield call(services.getProductHierarchyDetailsService, {
      ...payload.params,
    });
    const { isSuccess, data, message } = response;

    if (isSuccess) {
      yield put(actions.getProductHierarchyShortDetailsSuccess(data));
    } else {
      yield put(actions.getProductHierarchyShortDetailsError(message));
    }
  } catch (error) {
    yield put(actions.getProductHierarchyShortDetailsError(error));
  }
}

// Edit product detail
export function* updateProductDetailSage(payload) {
  try {
    const response = yield call(services.updateProductDetail, {
      ...payload.params,
    });
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.updateProductDetailSuccess(data));
    } else {
      yield put(actions.updateProductDetailError(message));
    }
  } catch (error) {
    yield put(actions.updateProductDetailError(error));
  }
}

export function* gridColumnInfo(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);

    let columns = [];

    if (response?.columns?.length > 0) {
      let hiddenCol = [
        'id',
        'subscriptionId',
        'productItemId',
        'memberId',
        'accountId',
        'subscriberId',
        'subscriptionMemberAccountId',
        'subscriptionMemberId',
      ];
      const colAuto = [
        'subscriptionMemberName',
        'hierarchyPackDescription',
        'subscriptionMemberType',
      ];
      response.columns.forEach((val) => {
        if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
          // eslint-disable-next-line no-lone-blocks

          if (colAuto.includes(val.fieldNameCamelCase)) {
            val = {
              ...val,
              flex: 1,
              resizable: true,
              minWidth: 250,
            };
          }
          if (val.fieldNameCamelCase === 'subscriptionMemberName') {
            val = {
              ...val,
              displayName: 'Subscriber Name',
            };
          } else if (val.fieldNameCamelCase === 'subscriptionMemberType') {
            val = {
              ...val,
              displayName: 'Member Type',
            };
          } else if (val.fieldNameCamelCase === 'targetGln') {
            val = {
              ...val,
              displayName: 'Publisher GLN',
            };
          } else if (val.fieldNameCamelCase === 'fromGln') {
            val = {
              ...val,
              displayName: 'Subscriber GLN',
            };
          }

          if (val.dataType === 'datetime') {
            val = {
              ...val,
              width: 200,
              cellRenderer: formatMDYWithParam,
              resizable: true,
            };
          } else {
            val = { ...val, width: 200, resizable: true };
          }
          columns.push(val);
        }
      });
    }
    yield put(actions.subscriptionProductGridColumnInfoSuccess(columns));
  } catch (error) {
    yield put(actions.subscriptionProductGridColumnInfoError(error));
  }
}

export function* getMappingGridColumnSaga() {
  try {
    const { response } = yield call(getColumnsFilter, {
      gridName: 'product-mappings-grid',
    });

    const columnData = response?.columns;

    if (columnData?.length > 0) {
      const nextColumns = mapToMappingGridColDef(columnData);
      yield put(actions.getMappingColumnInfoSucces(nextColumns));
    }
  } catch (error) {}
}

export function* getMappingSupportMemGridColumnSaga() {
  try {
    const { response } = yield call(getColumnsFilter, {
      gridName: 'mapping-supported-members',
    });

    const columnData = response?.columns;

    if (columnData?.length > 0) {
      const nextColumns = mapToMappingSupportMemGridColDef(columnData);
      yield put(actions.getMappingSupportMemColumnInfoSucces(nextColumns));
    }
  } catch (error) {}
}

export function* getColumnsFilter(payload) {
  const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
  // const filter = yield call(getFilter, response);
  return { response };
}

export function* getSubscriptionProductList(payload) {
  try {
    const response = yield call(
      servicesSubscription.getSubscriptionProductOwnerDetailGrid,
      payload.params
    );

    if (response?.isSuccess) {
      yield put(actions.getSubscriptionProductListSuccess(response?.data));
    }
  } catch (error) {
    yield put(actions.getSubscriptionProductListError(error));
  }
}

export function* getMappingMetadataSaga(action) {
  const params = action.payload;
  try {
    const response = yield call(services.getProductMappingMetadata, params);

    if (response?.isSuccess) {
      yield put(actions.getMappingMetadataSuccess(response?.data));
    }
  } catch (error) {
    yield put(actions.getMappingMetadataFail(error));
  }
}

export function* gridMappingDataPropertiesColumnInfo(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
    let columns = [
      {
        field: '',
        width: 30,
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
      },
    ];
    if (response && response?.columns?.length > 0) {
      response.columns.forEach((val) => {
        if (
          val.fieldNameCamelCase !== 'id' &&
          val.fieldNameCamelCase !== 'mappingFieldId' &&
          val.fieldNameCamelCase !== 'mappingId' &&
          val.fieldNameCamelCase !== 'mappingProductId'
        )
          columns.push({ ...val, resizable: true, flex: 1, minWidth: 180 });
      });
    }
    yield put(actions.gridColumnMappingDataPropertiesSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnMappingDataPropertiesError(error));
  }
}

export function* reloadMappingSupportMemberGridSaga(action) {
  yield put(actions.reloadMappingSupportMemGridSuccess(true));
  yield delay(1000);
  yield put(actions.reloadMappingSupportMemGridSuccess(false));
}

export function* getSyndicationHistoryGridColumns(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);
    const checkbox = {
      field: '',
      checkboxSelection: true,
      filter: false,
      suppressMenu: true,
      sortable: false,
    };
    let columns = [];

    if (response?.columns?.length > 0) {
      let hiddenCol = ['id'];
      response.columns.forEach((val) => {
        if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
          // eslint-disable-next-line no-lone-blocks

          if (val.dataType === 'datetime') {
            val = {
              ...val,
              width: 200,
              cellRenderer: formatMDYWithParam,
              resizable: true,
            };
          } else if (val.fieldNameCamelCase === 'dataFormat') {
            val = {
              ...val,
              minWidth: 250,
              flex: 1,
              resizable: true,
              allowFilter: false,
              allowSort: false,
              suppressMenu: true,
            };
          } else if (val.fieldNameCamelCase === 'productId') {
            val = {
              ...val,
              width: 100,
              resizable: true,
              allowFilter: false,
              allowSort: false,
              suppressMenu: true,
            };
          } else if (val.fieldNameCamelCase === 'gtin') {
            val = {
              ...val,
              displayName: 'GTIN',
            };
          } else {
            val = { ...val, width: 250, resizable: true };
          }

          columns.push(val);
        }
      });
    }

    const newColumns = [checkbox, ...columns];

    yield put(actions.getSyndicationHistoryGridColumnsSuccess(newColumns));
  } catch (error) {
    yield put(actions.getSyndicationHistoryGridColumnsFailure(error));
  }
}

export default function* watchProduct() {
  yield all([
    takeLatest(
      types.GET_PRODUCT_HIERARCHY_SHORT_DETAIL,
      getProductHierarchyShortSaga
    ),
    takeLatest(types.GET_PRODUCT_DOCUMENTS, getProductDocumentsSaga),
    takeLatest(types.GET_PRODUCT_BRAND_IMAGE, getProductBrandImageSaga),
    takeLatest(types.GET_PRODUCT_SOCIAL_MEDIA, getProductSocialMediaSaga),
    takeLatest(types.GET_MARKETING_ASSETS, getMarketingAssets),
    takeLatest(types.GET_PRODUCT_FACTS_PANEL, getProductFactsPanelSaga),
    takeLatest(types.UPDATE_PRODUCT_FACTS_PANEL, updateProductFactsPanelSaga),
    takeLatest(types.GET_PRODUCT_IMAGERY, getProductImagery),
    takeLatest(types.GET_MARKETING_ASSETS_PRIMARY, getMarketingAssetsPrimary),
    takeLatest(
      types.GET_PRODUCT_HIERARCHY_DETAILS,
      getProductHierarchyShortDetailsSaga
    ),
    takeLatest(
      types.GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN,
      gridMappingDataPropertiesColumnInfo
    ),
    takeLatest(types.EDIT_PRODUCT_DETAIL, updateProductDetailSage),
    takeLatest(types.GET_SUBSCRIPTION_PRODUCT_LIST, getSubscriptionProductList),
    takeLatest(types.SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO, gridColumnInfo),
    takeLatest(types.GET_MAPPING_GRID_COLUMN, getMappingGridColumnSaga),
    takeLatest(
      types.GET_MAPPING_SUPPORT_MEM_COLUMN,
      getMappingSupportMemGridColumnSaga
    ),
    takeLatest(types.GET_MAPPING_METADATA, getMappingMetadataSaga),
    takeLatest(
      types.RELOAD_MAPPING_SUPPORTED_MEMBER_GRID,
      reloadMappingSupportMemberGridSaga
    ),

    takeLatest(
      types.GET_SYNDICATION_HISTORY_GRID_COLUMNS,
      getSyndicationHistoryGridColumns
    ),
    takeLatest(
      types.GET_SYNDICATION_HISTORY_SHORT_DETAILS,
      getSyndicationHistoryShortDetailsSaga
    ),
  ]);
}
