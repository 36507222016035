import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import {
  Avatar,
  Row,
  Col,
  Button,
  Typography,
  Tooltip,
  Dropdown,
  Menu,
  Space,
  Badge,
} from 'antd';
import {
  CloseOutlined,
  BorderOutlined,
  MinusOutlined,
  LeftOutlined,
  UserAddOutlined,
  SwitcherOutlined,
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
  KeyOutlined,
  HomeOutlined,
  PlusOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import ChatSearchInput from '../searchInput/ChatSearchInput';
import HeaderFilter from './HeaderFilter';
import UpdateAvatarModal from './UpdateAvatarModal';
import UpdateThreadNameForm from './UpdateThreadNameForm';
import SearchMessageButton from '../button/SearchMessageButton';
import ThreadInfoModal from '../modal/ThreadInfoModal';
import { dialogFunction, CustomNotification } from 'common/components';

import userSelectors from 'redux/user/selectors';
import * as chatActions from '../../controller/actions';
import * as chatSelectors from '../../controller/selectors';
import * as chatServices from 'services/chatServices';

import './ChatHeader.less';

import { useCheckPermissionOR } from 'hooks/useCheckPermissions';
import { SECURE_CONFIG } from 'utils/SecureRoute';

const { Text } = Typography;

const mockInfo = {
  avatar:
    'https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png',
  title: 'Group Chat TacoTruck',
};

const ChatHeader = (props) => {
  const {
    onClickMinimizeBtn,
    isInChatThread,
    onClickBackBtn,
    onChangeSearchBar,
    onChangeFilter,
    onClickBackSearchBar,
    onMaximizeChatView,
    onCloseChatView,
    textSearch,
    communicationListData,
    groupUsers,
    info,
    maximizeChatView,
    newChatThread,
    newChatPrivate,
    editThread,
    filterSearch,
    participants,
  } = props;

  const [visibleThreadModal, setVisibleThreadModal] = useState(false);

  const memberID = useSelector(userSelectors.makeSelectUserMemberId());
  const userID = useSelector(userSelectors.makeSelectUserId());
  const threadMember = useSelector(chatSelectors.selectThreadInfo())?.users;
  const chatType = useSelector(chatSelectors.selectThreadInfo())?.type;
  const accountSend = useSelector(chatSelectors.selectAccountSend());

  const isAvailableInGroup =
    memberID &&
    threadMember &&
    threadMember?.filter(
      (item) => item.userId === `U-${userID}` || item.userId === `C-${memberID}`
    );
  const isCreator =
    memberID &&
    isAvailableInGroup &&
    isAvailableInGroup.filter((item) => item.role === 'Creator').length > 0;
  const checkPermission = useCheckPermissionOR();

  const canChatPersonal = checkPermission(SECURE_CONFIG.CHAT_PERSONAL.PASS);
  const canChatCompany = checkPermission(SECURE_CONFIG.CHAT_COMPANY.PASS);

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  useEffect(() => {
    if (info?.name || info?.avatarUrl) {
      updateCommunicationListData();
    }
  }, [info?.name, info?.avatarUrl]);

  const updateCommunicationListData = () => {
    let result =
      communicationListData?.length > 0 ? [...communicationListData] : [];
    const threadIndex = result?.findIndex(
      (thread) => thread.threadId === info?.id
    );
    if (threadIndex > -1) {
      if (result[threadIndex]?.name !== info?.name) {
        result[threadIndex] = {
          ...result[threadIndex],
          name: info?.name,
        };
      } else if (result[threadIndex].avatarUrl !== info?.avatarUrl) {
        result[threadIndex] = {
          ...result[threadIndex],
          avatarUrl: info?.avatarUrl,
        };
      }
      dispatch(chatActions.updateCommunicationThreadList(result));
    }
  };

  const checkIsCreatorRole = (groupUsers, userId, memberId) => {
    if (info?.type !== 'ChatGroup') {
      return false;
    }
    if (groupUsers?.length > 0) {
      if (userId) {
        const accountUser = groupUsers.find(
          (user) => user.userId === `U-${userId}`
        );
        if (accountUser?.role === 'Creator') {
          return true;
        }
      }
      if (memberId) {
        const accountCompany = groupUsers.find(
          (user) => user.userId === `C-${memberId}`
        );
        if (accountCompany?.role === 'Creator') {
          return true;
        }
      }
      return false;
    }
  };

  const isCreatorRole = checkIsCreatorRole(
    groupUsers,
    userInfo?.id,
    userInfo?.member?.id
  );

  const updateAvatar = () => {
    if (checkIsCreatorRole(groupUsers, userInfo?.id, userInfo?.member?.id)) {
      setVisible(true);
    }
  };

  const updateThreadName = () => {
    if (checkIsCreatorRole(groupUsers, userInfo?.id, userInfo?.member?.id)) {
      setIsEditMode(true);
    }
  };

  const onDeleteThread = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete thread message?',
      onOk: () => onHandleDeleteThread(info),
    });
  };

  const onHandleDeleteThread = async (thread) => {
    const param = {
      threadId: thread?.id,
    };
    chatServices.deleteChatThread(param).then(
      (res) => {
        if (res?.isSuccess) {
          setVisibleThreadModal(false);
          updateThreadListAfterDeleting(thread?.id);
          dispatch(chatActions.getThreadInfoSuccess(null));
          setTimeout(() => {
            onClickBackBtn && onClickBackBtn();
          }, 500);
        } else {
          CustomNotification.error(res.message);
        }
      },
      (err) => {
        CustomNotification.error('Delete failed! Please try');
      }
    );
  };

  const updateThreadListAfterDeleting = (threadId) => {
    let result =
      communicationListData?.length > 0 ? [...communicationListData] : [];
    if (threadId) {
      let existedThreadIndex = result?.findIndex(
        (thread) => thread.threadId === threadId
      );
      if (existedThreadIndex > -1) {
        result.splice(existedThreadIndex, 1);

        dispatch(chatActions.updateCommunicationThreadList(result));
      }
    }
  };

  const menu = (
    <Menu>
      {canChatPersonal ? (
        <Menu.Item onClick={() => newChatPrivate()}>Private Chat</Menu.Item>
      ) : null}
      {canChatCompany ? (
        <Menu.Item onClick={() => newChatThread()}>Group Chat</Menu.Item>
      ) : null}
    </Menu>
  );

  const headerMinimize = () => {
    return (
      <>
        {isInChatThread && (
          <Row style={{ alignItems: 'center', padding: 8 }}>
            <Col
              style={{
                transition: 'all 0.5s',
                width: isInChatThread ? 26 : 0,
              }}
            >
              <Button
                className={classnames(
                  'chat-header__btn',
                  'chat-header__btn--back'
                )}
                style={{ borderRadius: '100px' }}
                onClick={onClickBackBtn}
              >
                <LeftOutlined />
              </Button>
            </Col>

            <Col style={{ flex: 1 }}>
              <div className='chat-header__title-btn'>
                {accountSend?.type === 'Company' ? (
                  <Badge
                    count={<HomeOutlined />}
                    offset={[-5, 35]}
                    className='chat-header__badge'
                  >
                    <div className='chat-header__avatar-wrapper'>
                      <Avatar src={info?.avatarUrl}>
                        {info?.name?.charAt(0)}
                      </Avatar>
                    </div>
                  </Badge>
                ) : (
                  <div className='chat-header__avatar-wrapper'>
                    <Avatar src={info?.avatarUrl}>
                      {info?.name?.charAt(0)}
                    </Avatar>
                  </div>
                )}
                <Text
                  className='chat-header__title-btn-text'
                  ellipsis
                  onClick={() => setVisibleThreadModal(true)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {info?.name || mockInfo?.title}
                </Text>
              </div>
            </Col>
            <Col>
              <SearchMessageButton
                participants={participants}
                threadInfo={info}
                placement='left'
                width={250}
                resultWidth={290}
                resultPosition='topLeft'
              >
                <Button
                  className='chat-header__btn'
                  icon={<SearchOutlined />}
                />
              </SearchMessageButton>
              {chatType === 'ChatGroup' && (
                <Tooltip title='Add/Remove thread members.'>
                  <Button
                    className='chat-header__btn'
                    icon={<UserAddOutlined />}
                    disabled={!chatType === 'ChatGroup'}
                    onClick={() => editThread()}
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
        )}
        {!isInChatThread && (
          <Row style={{ alignItems: 'center', padding: 8 }}>
            <Col span={22}>
              <ChatSearchInput
                value={textSearch}
                onChange={onChangeSearchBar}
                onClear={onClickBackSearchBar}
              />
            </Col>
            <Col span={2}>
              <Tooltip title='New Chat'>
                <Dropdown overlay={menu} placement='bottomLeft'>
                  <Button className='chat-header__btn'>
                    <MessageOutlined
                      style={{ fontSize: '20px', marginLeft: '-3px' }}
                    />
                    <PlusOutlined className='chat-header__plus-btn' />
                  </Button>
                </Dropdown>
              </Tooltip>
            </Col>
            <HeaderFilter
              showFilter={true}
              onChangeFilter={onChangeFilter}
              filter={filterSearch}
            />
          </Row>
        )}
      </>
    );
  };

  const headerMaximize = () => {
    return (
      <Row>
        <Col
          span={8}
          style={{
            padding: 8,
            background: '#f0f4f8',
            borderRight: '1px solid #ccc',
          }}
        >
          <Row style={{ alignItems: 'center' }}>
            <Col style={{ flex: 1 }}>
              <ChatSearchInput
                value={textSearch}
                onChange={onChangeSearchBar}
                onClear={onClickBackSearchBar}
                maximizeChatView={maximizeChatView}
              />
            </Col>
            <Col>
              <Dropdown overlay={menu} placement='bottomLeft'>
                <Button className='chat-header__btn-max'>
                  <MessageOutlined
                    style={{ fontSize: '20px', marginLeft: '-3px' }}
                  />
                  <PlusOutlined className='chat-header__plus-btn' />
                </Button>
              </Dropdown>
            </Col>
          </Row>
          <HeaderFilter
            showFilter={true}
            onChangeFilter={onChangeFilter}
            filter={filterSearch}
          />
        </Col>
        <Col span={16} style={{ height: 89, background: '#fff' }}>
          {isInChatThread ? (
            <Row
              style={{
                alignItems: 'center',
                height: 88,
                borderBottom: '1px solid #ccc',
                padding: 8,
              }}
            >
              {isCreatorRole && (
                <Space className='chat-header__key'>
                  <KeyOutlined />
                  <Text>Creator</Text>
                </Space>
              )}
              <div
                className={classnames({
                  'chat-header__avatar-wrapper': isCreatorRole,
                  'chat-header__avatar-disabled-wrapper': !isCreatorRole,
                })}
              >
                {isCreatorRole && (
                  <Button
                    icon={<EditOutlined style={{ fontSize: '20px' }} />}
                    type='link'
                    className='chat-header__avatar-wrapper--edit-btn'
                    onClick={() => updateAvatar()}
                  />
                )}
                <Avatar
                  size={64}
                  src={info?.avatarUrl}
                  style={{
                    cursor: isCreatorRole ? 'pointer' : 'default',
                  }}
                  onClick={() => updateAvatar()}
                >
                  <span className='chat-header__avatar-wrapper--letter'>
                    {info?.name?.charAt(0)}
                  </span>
                </Avatar>
              </div>
              <UpdateAvatarModal
                info={info && info}
                visible={visible}
                setVisible={setVisible}
              />
              <Col
                style={{ flex: 1, maxWidth: 'calc(100% - 170px)', padding: 4 }}
                className='chat-header__maximize-title'
              >
                {!isEditMode ? (
                  <>
                    <Typography.Title
                      level={4}
                      ellipsis
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setVisibleThreadModal(true)}
                    >
                      {info?.name || mockInfo?.title}
                    </Typography.Title>
                    {isCreatorRole && (
                      <Button
                        icon={<EditOutlined style={{ fontSize: '20px' }} />}
                        type='link'
                        className='chat-header__maximize-title--edit-btn'
                        onClick={() => setIsEditMode(true)}
                      />
                    )}
                  </>
                ) : (
                  <Space
                    size={4}
                    className='chat-header__maximize-title--save-group-btn'
                  >
                    <UpdateThreadNameForm
                      info={info && info}
                      setIsEditMode={setIsEditMode}
                    />
                    <Tooltip title='Save'>
                      <Button
                        className='chat-header__btn chat-header__btn-max'
                        icon={<CheckOutlined />}
                        size='large'
                        htmlType='submit'
                        form='update-thread-name-form'
                      />
                    </Tooltip>
                    <Tooltip title='Close'>
                      <Button
                        className='chat-header__btn chat-header__btn-max'
                        size='large'
                        icon={<CloseOutlined />}
                        onClick={() => setIsEditMode(false)}
                      />
                    </Tooltip>
                  </Space>
                )}
              </Col>
              <Col flex={1} />
              <SearchMessageButton
                participants={participants}
                threadInfo={info}
                placement='left'
              >
                <Button
                  className='chat-header__btn-max'
                  size='large'
                  icon={<SearchOutlined style={{ fontSize: 26 }} />}
                />
              </SearchMessageButton>
              {chatType === 'ChatGroup' && (
                <Tooltip title='Add/Remove thread members.'>
                  <Button
                    className='chat-header__btn-max'
                    size='large'
                    disabled={!chatType === 'ChatGroup'}
                    icon={<UserAddOutlined style={{ fontSize: 26 }} />}
                    onClick={() => editThread()}
                  />
                </Tooltip>
              )}
            </Row>
          ) : (
            <Row></Row>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className='chat-header'>
        <Row style={{ alignItems: 'center', padding: 8 }}>
          <Col>
            <strong className='chat-header__title'>Messages</strong>
          </Col>
          <Col flex='auto' />
          <Col>
            <Button className='chat-header__btn' onClick={onClickMinimizeBtn}>
              <MinusOutlined />
            </Button>
            {maximizeChatView ? (
              <Button
                className='chat-header__btn'
                onClick={() => onMaximizeChatView(false)}
              >
                <SwitcherOutlined />
              </Button>
            ) : (
              <Button
                className='chat-header__btn'
                onClick={() => onMaximizeChatView(true)}
              >
                <BorderOutlined />
              </Button>
            )}

            <Button className='chat-header__btn' onClick={onCloseChatView}>
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
        {!maximizeChatView ? headerMinimize() : headerMaximize()}
      </div>
      <ThreadInfoModal
        visibleModal={visibleThreadModal}
        setVisibleModal={setVisibleThreadModal}
        isCreator={isCreatorRole}
        groupUsers={groupUsers}
        onDeleteThread={onDeleteThread}
        thread={info}
      />
    </>
  );
};

export default ChatHeader;
