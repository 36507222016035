import { Typography } from 'antd';
import { useMemo } from 'react';
import { addPrimaryToFieldName } from '../utils';

const useGetProperties = (props) => {
  const { propertiesData, entityType } = props;

  const properties =
    window?.advancedSearch?.entityAttributes?.[entityType] || propertiesData;

  const flattenProperties = useMemo(
    () => handleFlattenProperties(properties),
    [JSON.stringify(properties)]
  );

  const getPropertyData = (fieldName) => {
    return { ...flattenProperties[fieldName] } || {};
  };

  const selectTreeData = useMemo(
    () => mappingTreeData(propertiesData),
    [JSON.stringify(propertiesData)]
  );

  return { selectTreeData, getPropertyData };
};

export default useGetProperties;

export const handleFlattenProperties = (propertiesList) => {
  const properties = {};
  const flattenProperties = (fields, parentDisplayName) => {
    fields?.forEach((field) => {
      const fullPathDisplayName = `${parentDisplayName}${
        parentDisplayName && field.displayName ? ' - ' : ''
      }${field.displayName}`;

      const fieldName = addPrimaryToFieldName(field?.fieldName);

      if (field?.childFields?.length > 0) {
        flattenProperties(field?.childFields, fullPathDisplayName);
      } else {
        properties[fieldName] = {
          ...field,
          fullPathDisplayName,
          fieldName,
        };
      }
    });
  };

  propertiesList?.forEach((module) => {
    const childrenFields = module?.fields || module?.childFields;
    flattenProperties(childrenFields, module?.displayName);
  });

  return properties;
};

const mappingTreeData = (propertiesList) => {
  const recursionFunc = (module, parentDisplayName) => {
    const treeData = [];
    module?.forEach((property) => {
      const childFields = property?.fields || property?.childFields;
      const hasChildField = childFields?.length > 0;

      const fullPathDisplayName = `${parentDisplayName}${
        parentDisplayName && property.displayName ? ' - ' : ''
      }${property.displayName}`;

      const node = getNodeTreeData(property, hasChildField);

      if (hasChildField) {
        treeData?.push({
          ...node,
          children: recursionFunc(childFields, property?.displayName),
          fullPathDisplayName,
        });
      } else {
        treeData?.push({ ...node, fullPathDisplayName });
      }
    });
    return treeData;
  };

  const data = recursionFunc(propertiesList, null);

  return data;
};

const getNodeTreeData = (property, isNestedProperty) => {
  const fieldName = addPrimaryToFieldName(property?.fieldName);
  return {
    title: isNestedProperty ? (
      <Typography.Title
        level={5}
        style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.65)', marginBottom: 0 }}
      >
        {property.displayName}
      </Typography.Title>
    ) : (
      <Typography.Text strong>{property.displayName}</Typography.Text>
    ),
    isLeaf: isNestedProperty ? false : true,
    selectable: isNestedProperty ? false : true,
    data: property,
    value: fieldName || property.displayName,
  };
};
