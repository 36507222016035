import React from 'react';
import PropTypes from 'prop-types';

import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-040843 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Global Vertical Innovations, LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 11 May 2024 )____[v2]_MTcxNTM4MjAwMDAwMA==d035e6c32ae72202e23beccd712ab105'
);

const getAgGridProps = (rowData = []) => {
  return {
    defaultColDef: {
      enableRowGroup: false,
      enablePivot: false,
      enableValue: false,
      width: 100,
      sortable: false,
      resizable: false,
      filter: false,
    },
    headerHeight: 32,
    rowHeight: 32,
    // rowSelection: 'multiple',
    // suppressRowClickSelection: true,
    rowData,
    pivotPanelShow: 'never',

    // onRowSelected: (row) => console.log(row),

    // style color row
    rowClassRules: {
      'new-row': ({ data }) => data?.state === 'Added',
      'old-row': ({ data }) => !data?.state !== 'Added',
    },
  };
};

const GridLinkAssets = ({ columns, rowData, dragOver = false }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        className={
          dragOver
            ? 'grid-link-assets-container--drag'
            : 'grid-link-assets-container'
        }
      >
        <div id='my-grid-link-asset' className='ag-theme-alpine'>
          <AgGridReact {...getAgGridProps(rowData)}>
            {columns?.map((columnProps) => (
              <AgGridColumn {...columnProps} />
            ))}
          </AgGridReact>
        </div>
      </div>
    </div>
  );
};

GridLinkAssets.prototype = {
  columns: PropTypes.array,
  rowData: PropTypes.array,
  dragOver: PropTypes.bool,
};

export default GridLinkAssets;
