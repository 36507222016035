import React from 'react';

import { Button } from 'antd';

import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

const DocumentActions = (props) => {
  const { onPreview, onDownload, onDelete } = props;

  const { isQASpecLocked } = useCheckQaSpecLocked();

  return (
    <>
      <Button type='link' icon={<EyeOutlined />} onClick={onPreview} />
      <Button type='link' icon={<DownloadOutlined />} onClick={onDownload} />
      {!isQASpecLocked ? (
        <Button
          type='link'
          danger
          icon={<DeleteOutlined />}
          onClick={onDelete}
        />
      ) : null}
    </>
  );
};

export default DocumentActions;
