import React, { useState } from 'react';

import { LockOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';
import ProductPackagingModal from '../../modal/product-packaging/ProductPackagingModal';

import Messages from 'i18n/messages/home';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

const ProductPackaging = ({ productFull }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const onClick = () => {
    setModalVisible(true);
  };

  const { isQASpecLocked } = useCheckQaSpecLocked();

  return (
    <>
      <RibbonButton
        icon={
          isQASpecLocked ? (
            <LockOutlined />
          ) : (
            <i class='fa-solid fa-box-open'></i>
          )
        }
        label={Messages.productPackaging}
        onClick={onClick}
      />
      <ProductPackagingModal
        visible={modalVisible}
        closeModal={closeModal}
        isQASpecLocked={isQASpecLocked}
        productFull={productFull}
      />
    </>
  );
};

export default ProductPackaging;
