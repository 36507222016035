import React from 'react';
import { put, call, takeLatest, all } from 'redux-saga/effects';

import * as types from './constants';
import * as actions from './actions';
import * as services from 'services/members';
import * as servicesGrid from 'services/grid';
import * as ribbonType from 'redux/ribbon/constants';
import { AgGridIcons, GlnTooltip, AgGridThumbnail } from 'common/components';
import { DEFAULT_SORT } from 'static/Constants';
import { notification } from 'antd';
import CheckboxGrid from '../components/checkbox-grid/CheckboxGrid';
import { MemberLogo } from '../components';

import { formatProductDetailColumns } from 'pages/branded-products/controllers/saga';

const renderIcons = (params) => {
  const data = params?.data;

  return (
    <AgGridIcons
      data={data}
      isFavorite={data?.isFavorited}
      subscriptionShield={data?.subscription && data?.subscriptionShield}
    />
  );
};

const onMemberLogoRender = (param) => {
  return <MemberLogo {...param?.data} />;
};

const renderGlns = (params) => {
  return <GlnTooltip glns={params?.value} />;
};

export function* getMemberList(payload) {
  const { searchText } = payload;

  const params = {
    ...payload,
    ...(searchText ? null : { sort: DEFAULT_SORT }),
  };

  try {
    const response = yield call(services.getMemberList, params);

    const data = response?.data?.gridData || response?.gridData;
    const paging = response?.data?.paging || response?.paging;
    yield put(actions.getMemberListSuccess(data, paging?.totalRecord));
  } catch (error) {
    yield put(actions.getMemberListError(error));
  }
}

export function* getMemberShortDetail(payload) {
  try {
    const response = yield call(services.getMemberShortDetail, {
      Id: payload.id,
    });
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getMemberShortDetailSuccess(data));
    } else {
      yield put(actions.getMemberShortDetailError(message));
    }
  } catch (error) {
    yield put(actions.getMemberShortDetailError(error));
  }
}

export function* gridMemberColumnInfo(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);

    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
      },
      {
        field: 'memberLogo',
        headerName: 'Member Logo',
        cellRenderer: onMemberLogoRender,
        filter: false,
        width: 70,
        resizable: false,
        allowSort: false,
        headerClass: 'header-text-hide',
      },
      {
        field: 'stateIcons',
        headerName: 'States',
        minWidth: 150,
        cellRenderer: renderIcons,
        filter: false,
        resizable: true,
        allowSort: false,
        headerClass: 'header-text-hide',
      },
    ];

    if (response && response.columns && response.columns.length > 0) {
      response.columns.forEach((val, index) => {
        if (
          val.fieldName !== 'Id' &&
          val.fieldName !== 'Type' &&
          val.fieldName !== 'ParentCompanyId' &&
          val.fieldName !== 'Thumb300' &&
          val.fieldName !== 'Thumb'
        ) {
          // let obj = Object.assign(val, { filterValues: filter[index] });
          const col = { ...val, resizable: true };
          if (val.fieldName === 'MemberName') {
            col.linkTo = `/company/{id}`;
            col.minWidth = 300;
          }

          if (val.fieldName === 'Glns') {
            col.filter = false;
            col.allowFilter = false;
            col.suppressMenu = true;
            col.allowSort = false;
            col.cellRenderer = renderGlns;
          }

          columns.push(col);
        }
      });
    }

    yield put(actions.gridMemberColumnInfoSuccess(columns));
  } catch (error) {
    yield put(actions.gridMemberColumnInfoError(error));
  }
}

export function* getColumnsFilter(payload) {
  const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
  // const filter = yield call(getFilter, response);
  return { response };
}

export function* getFilter(response) {
  const filter = yield all(
    response.columns &&
      response.columns.length > 0 &&
      response.columns.map((val) =>
        call(services.getMemberDetailGridDistinct, {
          fieldName: val.fieldName,
        })
      )
  );
  return filter;
}

export function* getColumnsProductsForMembersSaga(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);

    const columns = formatProductDetailColumns(response);

    yield put(actions.getColumnsProductsForMemberSuccess(columns));
  } catch (error) {
    yield put(actions.getColumnsProductsForMemberError(error));
  }
}

const renderIconOwner = (params) => {
  return <CheckboxGrid params={params} />;
};

export function* getColumnForBanList(action) {
  try {
    const { response } = yield call(getColumnsFilter, action);

    const checkboxColumn = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        resizable: true,
      },
    ];

    let result = [];

    if (response && response.columns && response.columns.length > 0) {
      response.columns.forEach((val, index) => {
        if (val.fieldNameCamelCase === 'banByMemberName') {
          result.push({ ...val, resizable: true, width: 240 });
        } else if (val.fieldNameCamelCase === 'bannedMemberName') {
          result.push({ ...val, resizable: true, flex: 1 });
        }
      });
    }
    yield put(actions.banListColumnInfoSuccess([...checkboxColumn, ...result]));
  } catch (error) {
    yield put(actions.banListColumnInfoError(error));
  }
}

export function* fetchPublicContact(payload) {
  if (payload.memberId) {
    try {
      const response = yield call(services.getPublicContact, {
        memberId: Number(payload.memberId),
        pageIndex: 1,
        pageSize: 100,
      });
      yield put({
        type: types.GET_PUBLIC_CONTACT_MEMBER_SUCCESS,
        data: response?.data?.gridData,
      });
    } catch (error) {
      yield put({
        type: types.GET_PUBLIC_CONTACT_MEMBER_ERROR,
        error,
      });
    }
  }
}

export function* addToBanList(payload) {
  if (payload) {
    try {
      const response = yield call(services.addToBanList, {
        memberIds: payload.data,
      });
      if (response.isSuccess) {
        notification.success({ message: 'Add to ban list success' });
        yield put({
          type: types.GET_MEMBER_LIST,
          isFavoriteRoute: payload.isFavoriteRoute,
          pageSize: payload.page[1] !== 0 ? payload.page[1] : 20,
          pageNumber: 1,
        });
      }
    } catch (error) {}
  }
}

export function* removeToBanList(payload) {
  if (payload) {
    try {
      const response = yield call(services.removeToBanList, {
        memberIds: payload.data,
      });
      if (response.isSuccess) {
        notification.success({ message: 'Remove from ban list success' });
      }
    } catch (error) {}
  }
}

function* watchAll() {
  yield all([
    takeLatest(types.GET_MEMBER_SHORT_DETAIL, getMemberShortDetail),
    takeLatest(types.GET_MEMBER_LIST, getMemberList),
    takeLatest(types.MEMBER_GRID_COLUMN_INFO, gridMemberColumnInfo),
    takeLatest(
      types.GET_COLUMNS_PRODUCTS_FOR_MEMBER,
      getColumnsProductsForMembersSaga
    ),
    takeLatest(types.GET_PUBLIC_CONTACT_MEMBER, fetchPublicContact),
    takeLatest(ribbonType.ADD_TO_BAN_LIST, addToBanList),
    takeLatest(types.BAN_LIST_GRID_COLUMN_INFO, getColumnForBanList),
    takeLatest(types.REMOVE_BAN_LIST, removeToBanList),
  ]);
}

export default watchAll;
