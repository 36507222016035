import React from 'react';

import { Col, Alert } from 'antd';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

const HierarchyChangeProductMessage = (props) => {
  const intl = useIntl();
  const { shouldShow } = props;

  return shouldShow ? (
    <Col span={24} className='add-hierarchy__level-change-product-message'>
      <Alert
        message={intl.formatMessage(Messages.changeProductTooltip)}
        type='error'
        showIcon
      />
    </Col>
  ) : null;
};

export default HierarchyChangeProductMessage;
