import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as pimVersionServices from 'services/product';
import { useGetProductFullView } from 'hooks';
import { removeEmptyHistory } from './utils';
import { Typography } from 'antd';

export const productVersioningKey = {
  all: () => ['pim', 'product-full-view/version'],
  detail: (filter) => ['pim', 'product-full-view/version', filter],
};

export const useGetProductVersionList = ({
  productId,
  versionListParams,
  ...otherOptions
} = {}) => {
  const queryClient = useQueryClient();
  const { productFull } = useGetProductFullView({ productId });

  const fetchProductChange = async (filter) => {
    const response = await pimVersionServices.getProductChangeHistory(filter);
    if (response?.isSuccess) {
      return response?.data || {};
    } else {
      return {};
    }
  };

  const queries = useQuery({
    queryKey: productVersioningKey.detail(versionListParams),
    queryFn: () => fetchProductChange(versionListParams),
    enabled: Boolean(productId),
    staleTime: 0,
    refetchOnWindowFocus: false,
    ...otherOptions,
  });

  const handleRefetchProductVersioning = (versionListParams) => {
    const key = versionListParams
      ? productVersioningKey.detail(versionListParams)
      : productVersioningKey.all();
    queryClient.invalidateQueries({
      queryKey: key,
    });
  };

  const getProductVersionInfo = () => {
    const { productPrimaryImage } = productFull;
    if (!queries?.data?.gridData?.length)
      return {
        versionList: [],
      };

    const removedEmptyHistory = removeEmptyHistory(queries?.data?.gridData);

    let versioningMapping = removedEmptyHistory?.map((item) => {
      return {
        ...item,
        productPrimaryImage,
      };
    });

    return {
      ...queries?.data?.paging,
      versionList: versioningMapping,
    };
  };

  const productVersionInfo = getProductVersionInfo();

  return {
    ...productVersionInfo,
    handleRefetchProductVersioning,
    status: queries?.status,
    fetchProductChange,
    ...queries,
  };
};

export const getProductHistorySchemaKey = () => {
  return ['get_product_history_detail_schema'];
};

export const useGetProductVersioningSchema = () => {
  const queries = useQuery({
    queryKey: getProductHistorySchemaKey(),
    queryFn: async () => {
      const response = await pimVersionServices.getProductHistorySchema();
      return response?.data?.modules;
    },
    staleTime: Infinity,
  });

  return {
    ...queries,
  };
};

export const useGetProductVersioningTreeData = () => {
  const { data } = useGetProductVersioningSchema();

  const createNode = (properties) => {
    return properties?.map((property) => {
      const propertyObj = {
        isLeaf: true,
        title: (
          <Typography.Text strong>
            {property.propertyDisplayName}
          </Typography.Text>
        ),
        value: property.fieldFullPath.toLowerCase(),
        key: property.fieldFullPath.toLowerCase(),
        data: { ...property },
        children: [],
      };

      if (property.childProperties && property.childProperties.length > 0) {
        propertyObj.isLeaf = false;
        propertyObj.title = (
          <Typography.Title
            level={5}
            style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)' }}
          >
            {property.propertyDisplayName}
          </Typography.Title>
        );
        propertyObj.children = createNode(property.childProperties);
      }

      return propertyObj;
    });
  };

  const mapDataToTree = (input) => {
    return input?.map((module) => {
      const moduleObj = {
        isLeaf: false,
        title: (
          <Typography.Title
            level={5}
            style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)' }}
          >
            {module.moduleDisplayName}
          </Typography.Title>
        ),
        value: module.moduleName.toLowerCase(),
        key: module.moduleName.toLowerCase(),
        data: {
          ...module,
          fieldFullPath: module.moduleName.toLowerCase(),
          propertyDisplayName: module.moduleDisplayName,
        },
        children: [],
      };

      if (module.moduleProperties) {
        module.moduleProperties.forEach((property) => {
          const propertyObj = {
            isLeaf: true,
            title: (
              <Typography.Text strong>
                {property.propertyDisplayName}
              </Typography.Text>
            ),
            value: property.fieldFullPath.toLowerCase(),
            key: property.fieldFullPath.toLowerCase(),
            data: { ...property },
            children: [],
          };
          moduleObj.children.push(propertyObj);

          if (property.childProperties && property.childProperties.length > 0) {
            propertyObj.isLeaf = false;
            propertyObj.title = (
              <Typography.Title
                level={5}
                style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)' }}
              >
                {property.propertyDisplayName}
              </Typography.Title>
            );
            propertyObj.children = createNode(property.childProperties);
          }
        });
      }

      return moduleObj;
    });
  };

  const sortVersionTreeData = (tree) => {
    if (!Array.isArray(tree)) return tree;

    return tree
      .map((node) => {
        if (!node.isLeaf) {
          return { ...node, children: sortVersionTreeData(node.children) };
        }
        return node;
      })
      .sort((nodePrev, nodeNext) =>
        nodePrev.data?.propertyDisplayName?.localeCompare(
          nodeNext.data?.propertyDisplayName
        )
      );
  };

  const treeData = mapDataToTree(data);

  const treeSorted = sortVersionTreeData(treeData);

  return treeSorted;
};
