import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image, message, Tooltip, Typography, Tag, Row, Col } from 'antd';

import videoThumbnail from 'assets/asset-icons/video-thumbnail.png';
import {
  MarkdownMediaCustomImage,
  MarkdownMediaCustomVideo,
  MarkdownMediaCustomModal,
} from '../index';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import classnames from 'classnames';
import './MarkdownUploadItem.less';

const { Text } = Typography;

const MarkdownUploadItem = (props) => {
  const {
    fileInfo,
    markDownContent,
    mediaType,
    mdEditor,
    id,
    maxWidthCustomImage,
    contentId,
    host,
    isAlwaysKeepScale,
    onInsertVideo,
    noCopy,
    insertText,
    handleSelect,
    noInsert,
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const [isCustomImageModalOpen, setIsCustomImageModalOpen] = useState(false);
  const [isCustomVideoModalOpen, setIsCustomVideoModalOpen] = useState(false);

  const insertVideo = () => {
    if (mdEditor) {
      mdEditor.doc.replaceSelection(markDownContent + '\n');
      message.success({
        content: <Text>Insert Video Successfully</Text>,
      });
    }
    // callback return fileInfo
    if (onInsertVideo) {
      onInsertVideo(fileInfo);
    }
  };

  const handleSelectCustomImage = () => {
    setIsCustomImageModalOpen(true);
  };

  const handleSelectCustomVideo = () => {
    setIsCustomVideoModalOpen(true);
  };

  return (
    <React.Fragment>
      <div
        className='markdown-upload-item'
        id={id}
        onMouseEnter={() => {
          setIsFocus(true);
        }}
        onMouseLeave={() => {
          setIsFocus(false);
        }}
      >
        <div className='markdown-upload-item__inner'>
          {mediaType === 'Image' && (
            <React.Fragment>
              <Row className='markdown-upload-item__btn-group'>
                {!noCopy && (
                  <Col xs={8}>
                    <CopyToClipboard
                      text={markDownContent}
                      onCopy={() => {
                        message.success({
                          content: (
                            <React.Fragment>
                              <Text>Copied Image Successfully</Text>
                            </React.Fragment>
                          ),
                        });
                      }}
                    >
                      <div
                        className={classnames(
                          'markdown-upload-item__btn-copy',
                          'markdown-upload-item__btn'
                        )}
                      >
                        Copy
                      </div>
                    </CopyToClipboard>
                  </Col>
                )}
                {!noInsert && (
                  <Col flex='auto'>
                    <div
                      className={classnames(
                        'markdown-upload-item__btn-insert',
                        'markdown-upload-item__btn'
                      )}
                      onClick={() => {
                        handleSelect
                          ? handleSelect(fileInfo)
                          : handleSelectCustomImage();
                      }}
                    >
                      {insertText || 'Custom & Insert'}
                    </div>
                  </Col>
                )}
              </Row>
              <Image src={fileInfo?.url} preview={false} />
            </React.Fragment>
          )}
          {mediaType === 'Video' && (
            <React.Fragment>
              <Row className='markdown-upload-item__btn-group'>
                {!noCopy && (
                  <Col xs={8}>
                    <CopyToClipboard
                      text={markDownContent}
                      onCopy={() => {
                        message.success({
                          content: (
                            <React.Fragment>
                              <Text>Copied Video Successfully</Text>
                            </React.Fragment>
                          ),
                        });
                      }}
                    >
                      <div
                        className={classnames(
                          'markdown-upload-item__btn-copy',
                          'markdown-upload-item__btn'
                        )}
                      >
                        Copy
                      </div>
                    </CopyToClipboard>
                  </Col>
                )}
                {!noInsert && (
                  <Col flex='auto'>
                    <div
                      className={classnames(
                        'markdown-upload-item__btn-insert',
                        'markdown-upload-item__btn'
                      )}
                      onClick={() =>
                        onInsertVideo
                          ? insertVideo()
                          : handleSelectCustomVideo()
                      }
                    >
                      {insertText || 'Custom & Insert'}
                    </div>
                  </Col>
                )}
              </Row>
              <div>
                <Tag
                  className='markdown-upload-item__video-tag'
                  color='rgb(255, 85, 0, 0.8)'
                >
                  MP4
                </Tag>
                <video
                  className='markdown-upload-item__video-player'
                  controls={isFocus}
                  preload='none'
                  poster={videoThumbnail}
                >
                  <source src={fileInfo?.url} type='video/mp4' />
                </video>
              </div>
            </React.Fragment>
          )}
          <Row className='markdown-upload-item__name'>
            <Tooltip
              placement='bottom'
              title={fileInfo?.fileName}
              getPopupContainer={() => document.getElementById(id)}
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              autoAdjustOverflow={true}
              destroyTooltipOnHide={true}
              overlayStyle={{ position: 'absolute' }}
            >
              <Text ellipsis>{fileInfo?.fileName}</Text>
            </Tooltip>
          </Row>
        </div>
      </div>
      {isCustomImageModalOpen && (
        <MarkdownMediaCustomModal
          fileInfo={fileInfo}
          isCustomImageModalOpen={isCustomImageModalOpen}
          setIsCustomImageModalOpen={setIsCustomImageModalOpen}
          mdEditor={mdEditor}
          maxWidth={maxWidthCustomImage}
          contentId={contentId}
          host={host}
          isAlwaysKeepScale={isAlwaysKeepScale}
          previewType='image'
          modalTitle='Custom Image'
        />
      )}
      {isCustomVideoModalOpen && (
        <MarkdownMediaCustomModal
          fileInfo={fileInfo}
          isCustomImageModalOpen={isCustomVideoModalOpen}
          setIsCustomImageModalOpen={setIsCustomVideoModalOpen}
          mdEditor={mdEditor}
          maxWidth={maxWidthCustomImage}
          contentId={contentId}
          host={host}
          previewType='video'
          modalTitle='Custom Video'
        />
      )}
    </React.Fragment>
  );
};

MarkdownUploadItem.propTypes = {
  fileInfo: PropTypes.object,
  markDownContent: PropTypes.string,
  mediaType: PropTypes.string,
  mdEditor: PropTypes.object,
  id: PropTypes.string,
};

export default MarkdownUploadItem;
