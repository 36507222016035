import DrugFactCase from './DrugFactCase';
import classNames from 'classnames';
const DrugFactDetailList = ({
  italic = false,
  strong = false,
  list,
  className,
}) => {
  return (
    <div
      className={classNames({
        'drug-fact__detail-list': true,
        [className]: !!className,
      })}
    >
      {list.map((item, idx) => {
        return (
          <DrugFactCase key={idx} text={item} italic={italic} strong={strong} />
        );
      })}
    </div>
  );
};

export default DrugFactDetailList;
