import React from 'react';

import { Typography } from 'antd';

import { FormattedMessage } from 'react-intl';

import messages from 'i18n/messages/gridView';

const MAX_ITEMS = 100000;
const TEXT_MAX_ITEMS = '99,999+';

const DisplayTotalItem = ({ totalPagination }) => {
  const isLargeThanMax = totalPagination >= MAX_ITEMS;

  return (
    <>
      <Typography.Text strong style={{ marginLeft: 6 }}>
        {typeof totalPagination === 'number'
          ? isLargeThanMax
            ? TEXT_MAX_ITEMS
            : totalPagination
          : ''}
      </Typography.Text>
      &nbsp;
      <FormattedMessage {...messages.totalItems} />
    </>
  );
};

export default DisplayTotalItem;
