import React from 'react';

import { Menu, Typography, Dropdown } from 'antd';
import PropTypes from 'prop-types';

import {
  SafetyCertificateOutlined,
  SafetyCertificateFilled,
} from '@ant-design/icons';

import './ProductSubscription.less';

const { Text } = Typography;

const ProductSubscription = ({ color, isFill, type = 'product' }) => {
  const colorShield = color?.toLowerCase();

  const getToolTipContentByShieldColor = () => {
    let tooltipContent = '';

    if (type === 'product') {
      tooltipContent = 'Subscribed Product';

      if (colorShield === 'yellow')
        tooltipContent = 'Received from GDSN, and modified';
      if (colorShield === 'green') tooltipContent = 'Received from GDSN';
    }

    if (type === 'member') {
      if (colorShield === 'yellow') {
        tooltipContent = 'Received from GDSN, and modified';
      }

      if (colorShield === 'blue') {
        tooltipContent = 'Subscribed Member';
      }

      if (colorShield === 'red') {
        tooltipContent = 'Subscribed Member';
      }
    }

    return tooltipContent;
  };

  const toolTipContent = getToolTipContentByShieldColor();

  const overLay = (
    <Menu style={{ padding: 4, fontWeight: 900 }}>
      <Menu.Item>
        <Text strong>{toolTipContent}</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='pd-subscribe'>
      <Dropdown overlay={overLay} placement='bottomRight'>
        {isFill ? (
          <SafetyCertificateFilled
            className={`pd-subscribe__icon pd-subscribe__icon--` + colorShield}
          />
        ) : (
          <SafetyCertificateOutlined
            className={`pd-subscribe__icon pd-subscribe__icon--` + colorShield}
          />
        )}
      </Dropdown>
    </div>
  );
};

ProductSubscription.propsTypes = {
  color: PropTypes.string,
};

export default ProductSubscription;
