import React, { useState, useEffect, useRef, useMemo } from 'react';

import {
  Typography,
  Row,
  Col,
  Modal,
  notification,
  Result,
  InputNumber,
  Space,
} from 'antd';

import ExportPropertiesTree from './ExportPropertiesTree';
import CopyProductToolbar from './CopyProductToolbar';
import CopyProductResult from './CopyProductResult';

import * as productServices from 'services/product';
import { useGetProductPropertiesToCopy } from 'hooks/copyProductPropertiesHooks';
import {
  CustomNotification,
  WithLoading,
  CustomizeTransfer,
  dialogFunction,
} from 'common/components';

import { useGetProductItemModules } from 'pages/product-full-view/components/product-detail-view/hooks';
import {
  useQueryFactsPanel,
  useQuerySupplementFactsPanel,
  useQueryDrugFactsPanel,
  useQueryPetFoodPanel,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/facts-panel/useQueryFactsPanel';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';
import { useMutation } from '@tanstack/react-query';
import { isArray, isObject, union } from 'lodash';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useDispatch } from 'react-redux';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { KEY_COPY_PROPERTIES } from 'static/Constants';

import './BulkEditProduct.less';

const { Title } = Typography;

const CopyProductPropertiesModal = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const addedKeysRef = useRef([]);
  const reloadPage = useDispatchReloadPage();

  const { visible, setVisible, productId } = props;

  const [leftExpandedKeys, setLeftExpandedKeys] = useState([]);
  const [isShowSelection, setIsShowSelection] = useState(false);
  const [isHeaderOnly, setIsHeaderOnly] = useState(false);
  const [isSameSize, setIsSameSize] = useState(false);
  const [isSameStyleFlavor, setIsSameStyleFlavor] = useState(false);
  const [isCopyAllPopulated, setIsCopyAllPopulated] = useState(false);
  const searchHook = useState('');
  const [searchText, setSearchText] = searchHook;
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [numberOfCopies, setNumberOfCopies] = useState(1);
  const [keyHeaderOnly, setKeyHeaderOnly] = useState([]);
  const [keySameSize, setKeySameSize] = useState([]);
  const [keySameStyle, setKeySameStyle] = useState([]);

  const { sourceData, rootData, loadingStatus } =
    useGetProductPropertiesToCopy(visible);

  const productItemQuery = useGetProductItemModules(productId, true);
  const { productItemModules, statusFetchProductItem: moduleStatus } =
    productItemQuery;

  const { listFactsPanel, isLoading: loadingFacts } = useQueryFactsPanel({
    id: productId,
  });
  const { listSupplementFactsPanel, isLoading: loadingSupFacts } =
    useQuerySupplementFactsPanel({
      id: productId,
    });
  const { listDrugFactsPanel, isLoading: loadingDrugFacts } =
    useQueryDrugFactsPanel({
      id: productId,
    });

  const { listPetFoodPanel, isLoading: loadingPetFood } = useQueryPetFoodPanel({
    id: productId,
  });

  const copyMutation = useMutation({
    mutationFn: productServices.copyProductItem,
  });

  const onOk = () => {
    dialogFunction({
      type: 'warn',
      okButtonProps: {
        loading: copyMutation.isLoading,
      },
      content: `Do you want to copy ${numberOfCopies} product(s)?`,
      onOk: callApiCopyProperties,
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onTransferChange = (addedKeys) => {
    addedKeysRef.current = addedKeys;
    setSubmitDisabled(!addedKeys.length);
  };

  const callApiCopyProperties = () => {
    const properties = getPropertyFieldName({
      data: sourceData,
      addedKeys: addedKeysRef.current,
    });
    const params = {
      productItemId: productId,
      numberOfCopies: numberOfCopies,
      isPopulated: isCopyAllPopulated,
      selectedColumns: properties,
    };

    copyMutation.mutate(params, {
      onSuccess: async (result) => {
        if (result?.isSuccess) {
          dialogFunction({
            type: 'info',
            content: (
              <CopyProductResult listData={result?.data?.copyProductItems} />
            ),
            cancelButtonProps: {
              hidden: true,
            },
          });
          reloadPage();
          dispatch(actionsGridView.deleteItemsSelection());
        } else {
          notification.error({
            message:
              result?.message ||
              result?.data?.message ||
              intl.formatMessage(Messages.copyProductPropertiesError),
          });
        }
        setVisible(false);
      },
      onError: () => {
        notification.error({
          message: intl.formatMessage(Messages.copyProductPropertiesError),
        });
      },
    });
  };

  const getPropertyFieldName = ({ data, addedKeys }) => {
    return addedKeys.reduce((accumulator, currentAddedKeys) => {
      const addedPropertyData = data.find((propertyItem) => {
        return propertyItem.key === currentAddedKeys;
      });
      const fieldFullPath = addedPropertyData?.data?.fieldFullPath;

      if (addedPropertyData && fieldFullPath)
        accumulator.push({ fieldName: fieldFullPath });

      return accumulator;
    }, []);
  };

  const resetState = () => {
    if (visible) return;

    setIsShowSelection(false);
    setLeftExpandedKeys([]);
    setSearchText('');
  };

  useEffect(() => {
    resetState();
  }, [visible]);

  const getKeyHasValue = (arrKey, data, nameKey) => {
    const invalidValues = [null, undefined, ''];
    for (const [key, value] of Object.entries(data)) {
      if (!invalidValues.includes(value)) {
        if (isArray(value)) {
          value.forEach((val) => {
            if (isObject(val)) {
              getKeyHasValue(arrKey, val, `${nameKey}.${key}`);
            } else {
              arrKey.push(`${nameKey}.${key}`.toLocaleLowerCase());
            }
          });
        } else if (isObject(value)) {
          getKeyHasValue(arrKey, value, `${nameKey}.${key}`);
        } else {
          arrKey.push(`${nameKey}.${key}`.toLocaleLowerCase());
        }
        // triggle BE field LIST STRING
        if (
          nameKey.includes('DrugFacts') &&
          (key === 'useDetail' ||
            key === 'warningDetail' ||
            key === 'otherInformation') &&
          value?.length > 0
        ) {
          arrKey.push(`${nameKey}.${key}`.toLocaleLowerCase());
        }
      }
    }
  };

  const keyNonHeaderData = useMemo(() => {
    let key = [];
    productItemModules?.productItemModules?.length > 0 &&
      productItemModules?.productItemModules.forEach((module) => {
        if (module.moduleName !== 'Header') {
          const arrKey = [];
          getKeyHasValue(arrKey, module?.data, module.moduleName);
          key = key.concat(arrKey);
        }
      });
    listFactsPanel?.length > 0 &&
      listFactsPanel.forEach((fact) => {
        const arrKey = [];
        getKeyHasValue(arrKey, fact, 'NutritionFacts');
        key = key.concat(arrKey);
      });
    listSupplementFactsPanel?.length > 0 &&
      listSupplementFactsPanel.forEach((fact) => {
        const arrKey = [];
        getKeyHasValue(arrKey, fact, 'SupplementFacts');
        key = key.concat(arrKey);
      });
    listDrugFactsPanel?.length > 0 &&
      listDrugFactsPanel.forEach((fact) => {
        const arrKey = [];
        getKeyHasValue(arrKey, fact, 'DrugFacts');
        key = key.concat(arrKey);
      });
    listPetFoodPanel?.length > 0 &&
      listPetFoodPanel.forEach((fact) => {
        const arrKey = [];
        getKeyHasValue(arrKey, fact, 'PetNutritionFacts');
        key = key.concat(arrKey);
      });
    return key;
  }, [
    productItemModules,
    listFactsPanel,
    listSupplementFactsPanel,
    listDrugFactsPanel,
    listPetFoodPanel,
  ]);

  const getKey = (sameSize) => {
    let key = [];
    let keyInclude = [];
    let keyNotInclude = [];

    if (sameSize) {
      key = KEY_COPY_PROPERTIES.SAME_SIZE;
      keyNotInclude = KEY_COPY_PROPERTIES.SAME_SIZE_NOT_INCLUDE;
    } else {
      key = KEY_COPY_PROPERTIES.SAME_STYLE_FLAVOR;
      keyNotInclude = KEY_COPY_PROPERTIES.SAME_STYLE_NOT_INCLUDE;
      keyInclude = KEY_COPY_PROPERTIES.SAME_STYLE_INCLUDE;
    }
    sourceData.forEach((val) => {
      if (val?.data)
        if (!keyNotInclude.includes(val?.key)) {
          const splitKey = val?.key?.split('.');
          if (!keyNotInclude.includes(splitKey[0])) {
            if (keyNonHeaderData && keyNonHeaderData.length > 0) {
              if (
                keyNonHeaderData.includes(
                  val?.data?.fieldFullPath?.toLocaleLowerCase()
                )
              ) {
                key.push(val.key);
              }
            }
          }
          if (keyInclude && keyInclude.includes(splitKey[0])) {
            key.push(val.key);
          }
        }
    });
    return key;
  };

  const onChangeSameSize = (e) => {
    const checked = e.target.checked;
    setIsSameSize(checked);
    let keyStyle = getKey(false);
    let keySize = getKey(true);
    let key = [];
    if (checked) {
      if (isHeaderOnly) {
        key = key.concat(KEY_COPY_PROPERTIES.HEADER_ONLY);
      }
      if (isSameStyleFlavor) {
        key = key.concat(keyStyle);
      }

      const newKey = addedKeysRef?.current.filter((val) => !key.includes(val));
      setKeySameSize(union(keySize, newKey));
    } else {
      key = key.concat(keySize);
      if (isHeaderOnly) {
        key = key.concat(KEY_COPY_PROPERTIES.HEADER_ONLY);
      }
      if (isSameStyleFlavor) {
        key = key.concat(keyStyle);
      }
      const newKey = addedKeysRef?.current.filter((val) => !key.includes(val));
      setKeySameSize(newKey);
    }
    if (!isSameStyleFlavor) {
      setKeySameStyle([]);
    }
    if (!isHeaderOnly) {
      setKeyHeaderOnly([]);
    }
  };

  const onChangeSameStyleFlavor = (e) => {
    const checked = e.target.checked;
    setIsSameStyleFlavor(checked);
    let keyStyle = getKey(false);
    let keySize = getKey(true);
    let key = [];

    if (checked) {
      if (isHeaderOnly) {
        key = key.concat(KEY_COPY_PROPERTIES.HEADER_ONLY);
      }
      if (isSameSize) {
        key = key.concat(keySize);
      }
      const newKey = addedKeysRef?.current.filter((val) => !key.includes(val));
      setKeySameStyle(union(keyStyle, newKey));
    } else {
      key = key.concat(keyStyle);
      if (isHeaderOnly) {
        key = key.concat(KEY_COPY_PROPERTIES.HEADER_ONLY);
      }
      if (isSameSize) {
        key = key.concat(keySize);
      }
      const newKey = addedKeysRef?.current.filter((val) => !key.includes(val));
      setKeySameStyle(newKey);
    }
    if (!isSameSize) {
      setKeySameSize([]);
    }
    if (!isHeaderOnly) {
      setKeyHeaderOnly([]);
    }
  };

  const onChangeHeaderOnly = (e) => {
    const checked = e.target.checked;
    setIsHeaderOnly(checked);

    let keyStyle = getKey(false);
    let keySize = getKey(true);
    let key = [];
    if (checked) {
      if (isSameSize) {
        key = key.concat(keySize);
      }
      if (isSameStyleFlavor) {
        key = key.concat(keyStyle);
      }
      const newKey = addedKeysRef?.current.filter((val) => !key.includes(val));
      setKeyHeaderOnly(union(KEY_COPY_PROPERTIES.HEADER_ONLY, newKey));
    } else {
      key = key.concat(KEY_COPY_PROPERTIES.HEADER_ONLY);
      if (isSameSize) {
        key = key.concat(keySize);
      }
      if (isSameStyleFlavor) {
        key = key.concat(keyStyle);
      }
      const newKey = addedKeysRef?.current.filter((val) => !key.includes(val));
      setKeyHeaderOnly(newKey);
    }
    if (!isSameSize) {
      setKeySameSize([]);
    }
    if (!isSameStyleFlavor) {
      setKeySameStyle([]);
    }
  };

  const addRightKey = useMemo(() => {
    return union(keySameSize, keySameStyle, keyHeaderOnly);
  }, [keySameSize, keySameStyle, keyHeaderOnly]);

  const renderModalTitle = () => {
    const title = intl.formatMessage(Messages.copyProperties);

    return (
      <Row style={{ paddingRight: 30 }}>
        <Col flex={0}>
          <Title level={5} style={{ marginBottom: 0 }}>
            {title}
          </Title>
        </Col>
        <Col flex={1}></Col>
        <Col flex={0}>
          <Space>
            <Typography.Text style={{ fontSize: 13 }}>
              Number of copies
            </Typography.Text>
            <InputNumber
              min={1}
              max={20}
              value={numberOfCopies}
              onChange={(value) => {
                setNumberOfCopies(value);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onBlur={() => {
                if (!numberOfCopies) {
                  setNumberOfCopies(1);
                }
              }}
            />
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title={renderModalTitle()}
      visible={visible}
      bodyStyle={{
        height: '80vh',
        maxHeight: 600,
      }}
      onOk={onOk}
      okButtonText={intl.formatMessage(Messages.exportPropertiesSubmit)}
      okButtonProps={{
        loading: copyMutation.isLoading,
        disabled: submitDisabled && !isCopyAllPopulated,
      }}
      onCancel={onCancel}
      width={1300}
      destroyOnClose
      centered
      keyboard={false}
      maskClosable={false}
    >
      <CopyProductToolbar
        visible={visible}
        dataSource={sourceData}
        leftExpandedKeys={leftExpandedKeys}
        setLeftExpandedKeys={setLeftExpandedKeys}
        isShowSelection={isShowSelection}
        setIsShowSelection={setIsShowSelection}
        isSameSize={isSameSize}
        onChangeSameSize={onChangeSameSize}
        isSameStyleFlavor={isSameStyleFlavor}
        onChangeSameStyleFlavor={onChangeSameStyleFlavor}
        isHeaderOnly={isHeaderOnly}
        onChangeHeaderOnly={onChangeHeaderOnly}
        isCopyAllPopulated={isCopyAllPopulated}
        setIsCopyAllPopulated={setIsCopyAllPopulated}
        searchHook={searchHook}
        disabled={
          loadingFacts ||
          loadingSupFacts ||
          loadingDrugFacts ||
          loadingPetFood ||
          moduleStatus !== 'resolved' ||
          loadingStatus === 'loading'
        }
      />
      {loadingStatus === 'loading' ? (
        <div className='export-properties__loading-container'>
          <WithLoading
            loading
            className='export-properties__loading-spinning'
          />
        </div>
      ) : null}
      {loadingStatus === 'error' ? (
        <div className='export-properties__error-container'>
          <Result
            status='error'
            title={intl.formatMessage(Messages.exportPropertiesGetDataError)}
            className='export-properties__error-result'
          />
        </div>
      ) : null}
      {loadingStatus === 'success' ? (
        <div className='export-properties__transfer-container'>
          <CustomizeTransfer
            visible={visible}
            dataSource={sourceData}
            leftExpandedKeys={leftExpandedKeys}
            setLeftExpandedKeys={setLeftExpandedKeys}
            isShowSelection={isShowSelection}
            searchText={searchText}
            onChange={onTransferChange}
            addRightKey={addRightKey}
          >
            {(listProps) => (
              <ExportPropertiesTree
                {...listProps}
                propertyTitle='copyProperties'
                rootData={rootData}
              />
            )}
          </CustomizeTransfer>
        </div>
      ) : null}
    </Modal>
  );
};

export default CopyProductPropertiesModal;
