/*
 * Grid View Messages
 *
 * This contains all the text for the GridView component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.common.components.gridView';

export default defineMessages({
  titleModal: {
    id: `${scope}.component.abondonDialog.titleModal`,
    defaultMessage:
      'The user that the selection event will result in the un-selection of currently selected items',
  },
  abandonTooltip: {
    id: `${scope}.component.abondonDialog.abandonTooltip`,
    defaultMessage: 'Unselect previously selected items and select this one.',
  },
  appendTooltip: {
    id: `${scope}.component.abondonDialog.appendTooltip`,
    defaultMessage: 'Add new item to the selected items.',
  },
  cancelTooltip: {
    id: `${scope}.component.abondonDialog.cancelTooltip`,
    defaultMessage: 'Cancel the selection of this single item.',
  },
  abandon: {
    id: `${scope}.component.abondonDialog.abandon`,
    defaultMessage: 'Abandon',
  },
  append: {
    id: `${scope}.component.abondonDialog.append`,
    defaultMessage: 'Append',
  },
  cancel: {
    id: `${scope}.component.abondonDialog.cancel`,
    defaultMessage: 'Cancel',
  },
  option: {
    id: `${scope}.component.abondonDialog.option`,
    defaultMessage: 'The options would be:',
  },
  totalItems: {
    id: `${scope}.totalItems`,
    defaultMessage: 'items found',
  },
  numberItem: {
    id: `${scope}.numberItem`,
    defaultMessage: 'Items Selected',
  },
  numberItemMultiPage: {
    id: `${scope}.numberItemMultiPage`,
    defaultMessage: 'Items Select on multiple Pages',
  },
  addToFolder: {
    id: `${scope}.addToFolder`,
    defaultMessage: 'Add to Folder',
  },
  addToNewFolder: {
    id: `${scope}.component.abondonDialog.addToNewFolder`,
    defaultMessage: 'Create new folder',
  },
  cancelAddToFolder: {
    id: `${scope}.cancelAddToFolder`,
    defaultMessage: 'Cancel',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  addToFolderSuccess: {
    id: `${scope}.addToFolderSuccess`,
    defaultMessage: 'Add to Folder successfully',
  },
  addToFolderExist: {
    id: `${scope}.addToFolderExist`,
    defaultMessage: 'Item(s) already existing in folder',
  },
  errorList: {
    id: `${scope}.errorList`,
    defaultMessage: 'Plaase select item in list folder',
  },
  errorGrid: {
    id: `${scope}.errorGrid`,
    defaultMessage: 'Plaase select item(s) in grid view',
  },

  setDefaultGrid: {
    id: `${scope}.gridConfig.setDefault`,
    defaultMessage: 'Set config successfully',
  },

  setDefaultGridError: {
    id: `${scope}.gridConfig.setDefaultError`,
    defaultMessage: "Can't set default",
  },

  updateConfigSuccess: {
    id: `${scope}.gridConfig.updateConfigSuccess`,
    defaultMessage: 'Updated successfully',
  },

  updateConfigError: {
    id: `${scope}.gridConfig.updateConfigError`,
    defaultMessage: "Can't update config",
  },

  createConfigSuccess: {
    id: `${scope}.gridConfig.createConfigSuccess`,
    defaultMessage: 'Created successfully',
  },

  createConfigError: {
    id: `${scope}.gridConfig.createConfigError`,
    defaultMessage: "Can't create config",
  },

  applyConfig: {
    id: `${scope}.gridConfig.applyConfig`,
    defaultMessage: 'Applied config successfully',
  },
  advanceFilter: {
    id: `${scope}.advanceFilter`,
    defaultMessage: 'Advance Filter',
  },
});
