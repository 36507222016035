import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseSquareOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';

import Messages from 'i18n/messages/maintenance';

import * as globalActions from 'redux/global/actions';
import * as maintainHelpActions from 'pages/maintenance/controllers/actions';
import * as maintainHelpSelectors from 'pages/maintenance/controllers/selectors';

import ToggleUploadMediaBtn from '../../controls/maintenance/help/ToggleUploadMediaBtn';

const ToggleHelpUploadMediaSection = ({ disabled }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (disabled) {
      dispatch(maintainHelpActions.toggleUploadHelpMedia(false));
    }
    return;
  }, [disabled, dispatch]);

  const isHelpUploadMediaOpen = useSelector(
    maintainHelpSelectors.selectIsHelpUploadMediaOpen()
  );

  const mapStatusToIcon = () => {
    if (isHelpUploadMediaOpen) {
      return <CloseSquareOutlined />;
    } else {
      return <FolderOpenOutlined />;
    }
  };

  const mapStatusToText = () => {
    if (isHelpUploadMediaOpen) {
      return Messages.closeMedia;
    } else {
      return Messages.openMedia;
    }
  };

  const toggleIsHelpMediaOpen = () => {
    dispatch(maintainHelpActions.toggleUploadHelpMedia(!isHelpUploadMediaOpen));
  };

  return (
    <ToggleUploadMediaBtn
      icon={mapStatusToIcon()}
      label={mapStatusToText()}
      clickActionCallback={() => {
        toggleIsHelpMediaOpen();
      }}
      disabled={disabled}
    />
  );
};

export default ToggleHelpUploadMediaSection;
