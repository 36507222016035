import React from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ButtonSmallIcon, dialogFunction } from 'common/components';
import Messages from 'i18n/messages/common';

import { deleteApl } from 'services/apl';

import {
  toggleReloadGridApl,
  resetReloadGridApl,
} from 'pages/branded-products-apl/controllers/actions';

import { sleep } from 'utils/delay';

const DeleteAplControl = ({ selectedItems }) => {
  const dispatch = useDispatch();

  const isDisabledDeleteButton =
    !selectedItems?.length || selectedItems?.some((item) => !item?.isOwner);

  const confirmDeleteAPL = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the APL(s)?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDeleteAPL,
    });
  };

  const handleDeleteAPL = async () => {
    try {
      const aplIdList = selectedItems.map((item) => item.id);

      const response = await deleteApl({
        aplIds: aplIdList,
      });

      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Delete APL successfully!',
        });
        
        dispatch(toggleReloadGridApl());
      } else {
        notification.error({
          message: message || 'Cannot delete APL!',
        });
      }
    } catch (error) {
      notification.error({ message: error || 'Cannot delete APL!' });
    } finally {
      await sleep(200);
      dispatch(resetReloadGridApl());
    }
  };

  return (
    <ButtonSmallIcon
      icon={<DeleteOutlined />}
      label={Messages.deleteAPLBtn}
      onClick={confirmDeleteAPL}
      disabled={isDisabledDeleteButton}
      className='button-item-dropdown'
    />
  );
};

export default DeleteAplControl;
