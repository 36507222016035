import { useMemo } from 'react';
import { useUserInfo } from './userInfoHook';

import {
  MEMBER_TYPE_MANUFACTURER,
  MEMBER_TYPE_PRIVATE_MEMBER,
  MEMBER_TYPE_SHARED_ONLY,
  MEMBER_TYPE_SHARED_ONLY_MEMBER,
} from 'static/Constants';

export const useCheckMemberSupplier = () => {
  const { userInfo } = useUserInfo();

  const isSupplierMemberUser = useMemo(() => {
    return userInfo?.member?.memberType === MEMBER_TYPE_MANUFACTURER;
  }, [userInfo]);

  return isSupplierMemberUser;
};

export const useCheckLoginUserIsPrivateMember = () => {
  const { userInfo } = useUserInfo();

  const isPrivateMemberUser = useMemo(() => {
    return (
      userInfo?.member?.memberType?.toLowerCase() ===
        MEMBER_TYPE_PRIVATE_MEMBER ||
      userInfo?.member?.memberType?.toLowerCase() ===
        MEMBER_TYPE_SHARED_ONLY_MEMBER
    );
  }, [userInfo]);

  return isPrivateMemberUser;
};

export const useCheckMemberSharedOnly = () => {
  const { userInfo } = useUserInfo();

  const isSharedOnlyMemberUser = useMemo(() => {
    return userInfo?.member?.memberType === MEMBER_TYPE_SHARED_ONLY;
  }, [userInfo]);

  return isSharedOnlyMemberUser;
};
