import React from 'react';

import { Row, Col, Skeleton } from 'antd';
import { MarkdownUploadMedia } from 'common/components';

import { CaretRightOutlined } from '@ant-design/icons';

import { getMarkupAsset } from 'services/markupMaintenance';
import * as maintanMarkupEndpoints from 'services/markupMaintenance/endpoints';

const style = {
  skeleton: {
    height: 800,
    maxHeight: 'calc(100vh - 300px)',
    width: 375,
    background: 'white',
    paddingTop: 15,
  },
};

const MainPageMedia = (props) => {
  const { handleSelect, changeFileTarget, closeToolDraw, loading } = props;

  const mapToHeadText = () => {
    const isChangingBackgroundImage =
      changeFileTarget && changeFileTarget.split('_').pop() === 'background';

    return changeFileTarget
      ? isChangingBackgroundImage
        ? 'Please select background image'
        : 'Please select image'
      : '';
  };

  return (
    <div className='page-editor-tool__media'>
      <div className='page-editor-tool__media-header'>
        <Row>
          <Col>{mapToHeadText()}</Col>
          <Col flex='auto' />
          <Col>
            <CaretRightOutlined
              onClick={() => closeToolDraw && closeToolDraw()}
            />
          </Col>
        </Row>
      </div>
      <Skeleton loading={loading} style={style.skeleton}>
        <div style={{ flex: 1, width: 375 }}>
          <MarkdownUploadMedia
            wrapperId='markup-maintain-upload-media'
            mdEditor={null}
            maxWidthCustomImage={450}
            apiService={getMarkupAsset}
            apiUploadUrl={maintanMarkupEndpoints.UPLOAD_MARKUP_ASSET}
            minimized
            isVertical
            insertText='select'
            handleSelect={handleSelect}
            noInsert={changeFileTarget === null}
            noCopy
          />
        </div>
      </Skeleton>
    </div>
  );
};

export default MainPageMedia;
