import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { notification } from 'antd';

import { ButtonSmallIcon } from 'common/components';

import {
  getProductViaSpreadSheet,
  getTdcProductViaSpreadSheet,
} from 'services/product';
import { saveFile } from 'utils/saveFile';

const DownloadProductTemplateFromMember = (props) => {
  const handleDownloadTemplateProduct = (type) => async () => {
    try {
      const response =
        type === 'tdc'
          ? await getTdcProductViaSpreadSheet()
          : await getProductViaSpreadSheet();

      const urlPath = type === 'tdc' ? 'fileUrl' : 'url';

      if (response.isSuccess) {
        saveFile(response.data[urlPath]);
      } else {
        notification.error({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<DownloadOutlined />}
      label={'Download Template'}
      onClick={handleDownloadTemplateProduct('pim')}
      disabled={props.disabled}
    />
  );
};

DownloadProductTemplateFromMember.propTypes = {};

export default DownloadProductTemplateFromMember;
