import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { Form, notification, Button, Typography, Col } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import Messages from 'i18n/messages/dashboard';
import { useIntl } from 'react-intl';

import * as dashboardActions from 'pages/dashboard/controllers/actions';

import { RibbonButton, StyledModal } from 'common/components';
import {
  FormCreateMeeting,
  AdditionAttendee,
  DisplaySomeAttendees,
  DescriptionMeeting,
  ExternalAttendee,
} from './ModalCreateMeeting';

import { concatenateTime, correctTime } from './utils';

import { updateMeeting } from 'services/dashboard';
import selectorUser from 'redux/user/selectors';

import { addUtcForIsoString } from 'utils/timezone';

import './NewMeeting.less';

const EditMeeting = ({ meetingDetail, disabled, onRefetchMeetingCalendar }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        icon={<EditOutlined />}
        label={Messages.editMeeting}
        onClick={() => setVisible(true)}
        disabled={disabled}
      />
      {visible && (
        <ModalEditMeeting
          visible={visible}
          onCancel={() => setVisible(false)}
          meetingDetail={meetingDetail}
          onRefetchMeetingCalendar={onRefetchMeetingCalendar}
        />
      )}
    </>
  );
};

const ModalEditMeeting = (props) => {
  const { visible, onCancel, meetingDetail, onRefetchMeetingCalendar } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const [formInstance] = Form.useForm();
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [description, setDescription] = useState('');
  const [statusSubmit, setStatusSubmit] = useState('idle');
  const [externalAttendees, setExternalAttendees] = useState([]);

  const [startTimeInput, setStartTimeInput] = useState();
  const [startTimeChange, setStartTimeChange] = useState(false);
  const [endTimeInput, setEndTimeInput] = useState();
  const [endTimeChange, setEndTimeChange] = useState(false);

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const isAttendeesError =
    !selectedAttendees.length && !externalAttendees.length;

  useEffect(() => {
    if (visible && !isEmpty(meetingDetail)) {
      const {
        title,
        startTime,
        endTime,
        description,
        meetingUsers,
        externalEmails,
      } = meetingDetail;

      const formatDescriptionOnEdit = description?.replaceAll('<br/>', '\n');

      const selectedAttendees = meetingUsers
        .map((user) => ({
          ...user,
          id: user.userId,
        }))
        .filter((user) => user.id !== userInfo?.id);

      const selectedExternalAttendees = externalEmails?.map((email) => ({
        email,
        id: uuidv4(),
      }));

      const formValues = {
        title,
        startDate: moment(addUtcForIsoString(startTime)),
        startTime: moment(addUtcForIsoString(startTime)).format('hh:mm A'),
        endDate: moment(addUtcForIsoString(endTime)),
        endTime: moment(addUtcForIsoString(endTime)).format('hh:mm A'),
      };

      formInstance.setFieldsValue(formValues);

      setStartTimeInput(
        moment(addUtcForIsoString(startTime)).format('hh:mm A')
      );
      setStartTimeChange(false);
      setEndTimeInput(moment(addUtcForIsoString(endTime)).format('hh:mm A'));
      setEndTimeChange(false);

      setSelectedAttendees(selectedAttendees);
      setExternalAttendees(selectedExternalAttendees);
      setDescription(formatDescriptionOnEdit);
    }
  }, [formInstance, meetingDetail, userInfo?.id, visible]);

  const handleAddAttendees = (attendee) => {
    setSelectedAttendees((prevAttendee) => prevAttendee.concat(attendee));
  };

  const handleDeleteAttendees = (attendeeId) => {
    const filteredAttendees = selectedAttendees.filter(
      (attendee) => attendee.id !== attendeeId
    );
    setSelectedAttendees(filteredAttendees);
  };

  //* External Attendees
  const handleAddExternalAttendees = (attendee) => {
    setExternalAttendees((prevAttendee) => prevAttendee.concat(attendee));
  };

  const handleDeleteExternalAttendees = (attendeeId) => {
    const filteredExternalAttendees = externalAttendees.filter(
      (attendee) => attendee.id !== attendeeId
    );
    setExternalAttendees(filteredExternalAttendees);
  };

  const callbackUpdateSuccess = () => {
    notification.success({
      message: 'Update meeting successfully!',
    });

    setStatusSubmit('success');
    onRefetchMeetingCalendar();

    onCancel();
  };

  const callbackUpdateError = (message) => {
    !isAttendeesError &&
      notification.error({
        message: message ?? 'Something went wrong!',
      });

    setStatusSubmit('error');
  };

  const handleUpdateMeeting = async () => {
    try {
      const { title, startDate, startTime, endDate, endTime } =
        await formInstance.validateFields();

      setStatusSubmit('loading');

      const attendees = selectedAttendees
        .map((user) => user.id)
        .filter((id) => id !== userInfo?.id);

      const externalEmails = externalAttendees.map(
        (attendee) => attendee?.email
      );

      const formatDescription = description?.replaceAll('\n', '<br/>');

      const params = {
        id: meetingDetail.meetingId,
        title,
        startTime: startDate,
        endTime: endDate,
        isStartMeetingNow: false,
        description: formatDescription,
        attendees,
        externalEmails,
      };

      const response = await updateMeeting(params);

      if (response?.isSuccess) {
        callbackUpdateSuccess();

        dispatch(
          dashboardActions.getMeetingDetails({
            meetingId: meetingDetail.meetingId,
          })
        );
      } else {
        callbackUpdateError(response?.message);
      }
    } catch (error) {
      isAttendeesError && setStatusSubmit('error');
    }
  };

  return (
    <StyledModal
      title='Update Meeting'
      width='1000px'
      visible={visible}
      onCancel={onCancel}
      bodyStyle={{
        minHeight: '50vh',
      }}
      maskClosable={false}
      footer={null}
    >
      <FormCreateMeeting
        formInstance={formInstance}
        startTimeInput={startTimeInput}
        setStartTimeInput={setStartTimeInput}
        endTimeInput={endTimeInput}
        setEndTimeInput={setEndTimeInput}
        startTimeChange={startTimeChange}
        setStartTimeChange={setStartTimeChange}
        endTimeChange={endTimeChange}
        setEndTimeChange={setEndTimeChange}
      >
        <Button
          icon={<SendOutlined />}
          size='large'
          className='modal-meeting__btn-send'
          onClick={handleUpdateMeeting}
          loading={statusSubmit === 'loading'}
          type='primary'
        >
          <Typography.Text style={{ color: '#FFF' }}>SEND</Typography.Text>
        </Button>
      </FormCreateMeeting>

      <div style={{ display: 'flex', marginLeft: '18.5%' }}>
        <AdditionAttendee
          selectedAttendees={selectedAttendees}
          handleAddAttendees={handleAddAttendees}
        />
        <ExternalAttendee
          externalAttendees={externalAttendees}
          handleAddExternalAttendees={handleAddExternalAttendees}
          handleDeleteExternalAttendees={handleDeleteExternalAttendees}
        />
      </div>

      <div style={{ height: 67, marginLeft: 5 }}>
        {selectedAttendees?.length !== 0 && (
          <DisplaySomeAttendees
            selectedAttendees={selectedAttendees}
            handleAddAttendees={handleAddAttendees}
            handleDeleteAttendees={handleDeleteAttendees}
          />
        )}

        {/* //* Display External Attendees */}
        {externalAttendees?.length !== 0 && (
          <DisplaySomeAttendees
            selectedAttendees={externalAttendees}
            handleAddAttendees={handleAddExternalAttendees}
            handleDeleteAttendees={handleDeleteExternalAttendees}
            isExternalAttendees
          />
        )}
        {statusSubmit === 'error' && isAttendeesError ? (
          <Col offset={5} span={19}>
            <Typography.Text type='danger'>
              {intl.formatMessage(Messages.attendeesErrorMessage)}
            </Typography.Text>
          </Col>
        ) : null}
      </div>
      <DescriptionMeeting
        description={description}
        handleChangeDescription={(description) => setDescription(description)}
      />
    </StyledModal>
  );
};

export default EditMeeting;
