import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form, notification } from 'antd';
import SaveMappingConfigForm from './SaveMappingConfigForm';
import {
  getMappingConfigService,
  saveMappingConfigService,
} from 'services/mapping';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

const SaveMappingConfig = (props) => {
  const { visible, setVisible, selectedMapping, mode } = props;

  const [form] = Form.useForm();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [mappingEditData, setMappingEditData] = useState({});
  const reloadPage = useDispatchReloadPage();

  useEffect(() => {
    if (visible && mode === 'edit' && selectedMapping?.length > 0) {
      getMappingConfig();
    }
  }, [selectedMapping[0]?.id, mode, visible]);

  const getMappingConfig = async () => {
    try {
      const response = await getMappingConfigService({
        mappingId: selectedMapping[0]?.id,
      });
      const { isSuccess, data, message } = response;
      if (isSuccess) {
        setMappingEditData(data);
      } else {
        notification.error({
          message: message || 'Cannot get mapping config!',
        });
      }
    } catch (error) {
      notification.error({ message: 'Cannot get mapping!' });
    }
  };

  const handleSaveMappingConfig = async () => {
    const { ...values } = await form.validateFields();
    let params = {
      ...values,
      deliveryMethods: values?.deliveryMethods.filter(
        (item) => item !== 'GDSN'
      ),
      mappingName: values?.mappingName?.trim(),
      mappingDescription: values?.mappingDescription?.trim(),
    };
    params =
      mode === 'edit' ? { ...params, id: selectedMapping[0]?.id } : params;
    try {
      setIsSubmitLoading(true);
      const response = await saveMappingConfigService(params);
      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Save mapping successfully!',
        });
        form.resetFields();
        setMappingEditData({});
        reloadPage();
        setVisible(false);
      } else {
        notification.error({
          message: message || 'Cannot save mapping!',
        });
      }
      setIsSubmitLoading(false);
    } catch (error) {
      setIsSubmitLoading(false);
      notification.error({ message: 'Cannot save mapping!' });
    }
  };
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <Modal
          title={mode === 'edit' ? 'Edit Mapping' : 'Create New Mapping'}
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={onCancel}
          destroyOnClose
          width={700}
          maskClosable={false}
          className='modal-create-query'
          footer={[
            <Button
              key='save'
              type='primary'
              loading={isSubmitLoading}
              disabled={mode === 'edit' && selectedMapping?.length !== 1}
              onClick={handleSaveMappingConfig}
            >
              Submit
            </Button>,
            <Button key='back' onClick={onCancel}>
              Cancel
            </Button>,
          ]}
        >
          <SaveMappingConfigForm
            form={form}
            mode={mode}
            selectedMapping={selectedMapping}
            mappingEditData={mappingEditData}
          />
        </Modal>
      )}
    </>
  );
};

SaveMappingConfig.propTypes = {};

export default SaveMappingConfig;
