import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Modal } from 'antd';
import './IframePreview.less';

const IframePreview = (props) => {
  const { title, height, width, url, visible, onClose, ...otherProps } = props;

  const iframe = `<iframe style="width: ${width}; height: ${height}" src="${url}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  const Iframe = ({ iframe }) => {
    return (
      <div
        style={{ height: '100%' }}
        dangerouslySetInnerHTML={{ __html: iframe ? iframe : '' }}
      />
    );
  };

  return (
    <Modal
      className='iframe-preview'
      title={title}
      visible={visible}
      onCancel={() => onClose()}
      width='100%'
      bodyStyle={{
        height: 'calc(100vh - 36px)',
        display: ' flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      footer={null}
      {...otherProps}
    >
      <Iframe iframe={iframe} />
    </Modal>
  );
};

IframePreview.propsTypes = {
  title: PropTypes.string,
  height: oneOfType([PropTypes.string, PropTypes.number]),
  width: oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default IframePreview;
