import * as types from './constants';

/**
 * ? GET  TRANSITION PAGE DETAIL
 * @param {*} payload
 * @returns action;
 */
export const getTransitionPageDetail = (payload) => ({
  type: types.GET_TRANSITION_PAGE_DETAIL,
  payload,
});
export const getTransitionPageDetailSuccess = (payload) => ({
  type: types.GET_TRANSITION_PAGE_DETAIL_SUCCESS,
  payload,
});
export const getTransitionPageDetailFail = (payload) => ({
  type: types.GET_TRANSITION_PAGE_DETAIL_FAIL,
  payload,
});

/**
 * ? SAVE TRANSITION PAGE
 * @param {*} payload
 * @returns action;
 */
export const saveTransitionPage = (payload) => ({
  type: types.SAVE_TRANSITION_PAGE,
  payload,
});
export const saveTransitionPageSuccess = (payload) => ({
  type: types.SAVE_TRANSITION_PAGE_SUCCESS,
  payload,
});
export const saveTransitionPageFail = (payload) => ({
  type: types.SAVE_TRANSITION_PAGE_FAIL,
  payload,
});

/**
 * ? CHANGE TRANSITION PAGE PREVIEW SIZE
 * @param {*} payload
 * @returns action;
 */
export const changeTransitionPagePreviewSize = (payload) => ({
  type: types.CHANGE_TRANSITION_PAGE_PREVIEW_SIZE,
  payload,
});
