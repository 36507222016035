import { handleCallApiReducer } from 'utils/api';

export const mapToWorkflowDetail = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'workflowDetail');
};

export const mapToWfSelectedStep = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'wfSelectedStep');
};

export const mapToWfImplInfo = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'wfImplInfo');
};

export const mapToWfImplSteps = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'wfImplSteps');
};

export const mapToWorkflowTasks = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'workflowTasks');
};
