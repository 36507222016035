import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';

import { TabLayout } from 'common/components';
import {
  UserProfileOverview,
  UserActivityLogTab,
  UserSecurityTab,
} from '../index';

import * as userActions from 'redux/user/actions';

const UserBottomContent = (props) => {
  const { classNamePrefix, userProfile, editable, loginUserInfo } = props;
  const dispatch = useDispatch();

  const tabOpts = [
    {
      key: 'overview',
      title: 'Overview',
      module: (
        <UserProfileOverview
          userProfile={userProfile}
          loginUserInfo={loginUserInfo}
          editable={editable}
        />
      ),
    },
    {
      key: 'activity-log',
      title: 'Activity Log',
      module: <UserActivityLogTab editable={editable} />,
    },
    {
      key: 'security',
      title: 'Security',
      module: <UserSecurityTab editable={editable} userProfile={userProfile} />,
    },
  ];

  const onChangeTab = (activeKey) => {
    if (activeKey === 'activity-log') {
      dispatch(userActions.updateIsActivityLogTab(true));
    } else {
      dispatch(userActions.updateIsActivityLogTab(false));
    }
  };

  return (
    <Row className={`${classNamePrefix}__bottom-content`}>
      <Col
        span={24}
        className={`${classNamePrefix}__tab-layout`}
        style={{ padding: '0 8px' }}
      >
        <TabLayout
          onChangeTab={onChangeTab}
          options={tabOpts}
          defaultActiveTab='overview'
        />
      </Col>
    </Row>
  );
};

UserBottomContent.propTypes = {
  classNamePrefix: PropTypes.string.isRequired,
};

export default UserBottomContent;
