export const IX_ONE_FIELD = [
  'productname',
  'productdescription',
  'height',
  'width',
  'depth',
  'weight',
  'ecommercefeatures',
  'ecommercegordonfoodservice',
  'ecommercegordonfoodservicebullet1',
  'ecommercegordonfoodservicebullet2',
  'ecommercegordonfoodservicebullet3',
  'ecommercegordonfoodservicebullet4',
  'ecommercegordonfoodservicebullet5',
  'ecommercelongdescriptiona',
  'ecommercelongdescriptionb',
  'ecommercenamea',
  'ecommercenamew',
  'ecommerceproductdescription',
  'ecommerceriteaidproductdescription',
  'ecommerceriteaidproducttitle',
  'ecommercesearchterms',
  'ecommerceshelfdescription',
  'ecommerceshortdescription',
  'itemromancedescription',
  'manufacturernameheader',
  'productflavor',
  'productscent',
  'productstyle',
  'vendoritemnumber',
  'tdcproperties.itemromancedescription',
  'tradeitemdescription.tradeitemdescriptioninformation.additionaltradeitemdescription.languagecode',
  'tradeitemdescription.tradeitemdescriptioninformation.additionaltradeitemdescription.value',
  'tradeitemdescription.tradeitemdescriptioninformation.descriptionshort',
  'tradeitemdescription.tradeitemdescriptioninformation.descriptionshort.languagecode',
  'tradeitemdescription.tradeitemdescriptioninformation.descriptionshort.value',
  'tradeitemdescription.tradeitemdescriptioninformation.functionalname.languagecode',
  'tradeitemdescription.tradeitemdescriptioninformation.functionalname.value',
  'tradeitemdescription.tradeitemdescriptioninformation.tradeitemdescription',
  'tradeitemdescription.tradeitemdescriptioninformation.tradeitemdescription.languagecode',
  'tradeitemdescription.tradeitemdescriptioninformation.tradeitemdescription.value',
  'marketinginformation.marketinginformation.tradeitemmarketingmessage',
  'marketinginformation.marketinginformation.tradeitemmarketingmessage.codelistversion',
  'marketinginformation.marketinginformation.tradeitemmarketingmessage.formattingpattern',
  'marketinginformation.marketinginformation.tradeitemmarketingmessage.languagecode',
  'marketinginformation.marketinginformation.tradeitemmarketingmessage.sequencenumber',
  'marketinginformation.marketinginformation.tradeitemmarketingmessage.value',
  'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.brandname',
  'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.languagespecificbrandname.value',
  'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.languagespecificbrandname.languagecode',
];

export const TDC_PROPERTIES = {
  'tdcproperties.case_planogramheight': 'tdcProperties.case_PlanogramHeight',
  'tdcproperties.status': 'tdcProperties.status',
  'tdcproperties.producttransferring': 'tdcProperties.productTransferring',
  'tdcproperties.transferredtoproduction':
    'tdcProperties.transferredToProduction',
  'tdcproperties.transferredtoproductiondate':
    'tdcProperties.transferredToProductionDate',
  'tdcproperties.transferredtoproductionexception':
    'tdcProperties.transferredToProductionException',
  'tdcproperties.transferredtoproductionsuccessful':
    'tdcProperties.transferredToProductionSuccessful',
  'tdcproperties.biobasedyn': 'tdcProperties.bioBasedYN',
  'tdcproperties.ecofisheryyn': 'tdcProperties.ecoFisheryYN',
  'tdcproperties.hasfoodgradeplasticscertifier':
    'tdcProperties.hasFoodGradePlasticsCertifier',
  'tdcproperties.highpotency': 'tdcProperties.highPotency',
  'tdcproperties.liveandactiveculture': 'tdcProperties.liveAndActiveCulture',
  'tdcproperties.nongmoyn': 'tdcProperties.nonGMOYN',
  'tdcproperties.organicyn': 'tdcProperties.organicYN',
  'tdcproperties.pacayn': 'tdcProperties.pACAYN',
  'tdcproperties.pasayn': 'tdcProperties.pASAYN',
  'tdcproperties.productisbiodegradable':
    'tdcProperties.productIsBiodegradable',
  'tdcproperties.releasenotation': 'tdcProperties.releaseNotation',
  'tdcproperties.sustainedrelease': 'tdcProperties.sustainedRelease',
  'tdcproperties.veteransyn': 'tdcProperties.veteransYN',
  'tdcproperties.waterqualityassociationcertified':
    'tdcProperties.waterQualityAssociationCertified',
  'tdcproperties.wercsregistered': 'tdcProperties.wERCSRegistered',
  'tdcproperties.wfglobalanimalpartnershipratingclaimlevel':
    'tdcProperties.wfGlobalAnimalPartnershipRatingClaimLevel',
  'tdcproperties.liveandactiveculturecertified':
    'tdcProperties.liveAndActiveCultureCertified',
  'tdcproperties.primarysizetext': 'tdcProperties.primarySizeText',
  'tdcproperties.ingredientschina': 'tdcProperties.ingredientsChina',
  'tdcproperties.enlargedtoshowtexture': 'tdcProperties.enlargedtoShowTexture',
  'tdcproperties.itemromancedescription':
    'tdcProperties.itemRomanceDescription',
  'tdcproperties.qrcode': 'tdcProperties.qRCode',
  'tdcproperties.biodegradable': 'tdcProperties.biodegradable',
  'tdcproperties.bpafreeliner': 'tdcProperties.bPAFreeLiner',
  'tdcproperties.bpafreepackaging': 'tdcProperties.bPAFreePackaging',
  'tdcproperties.containsbpa': 'tdcProperties.containsBPA',
  'tdcproperties.foodgradeplastic': 'tdcProperties.foodGradePlastic',
  'tdcproperties.foodservicebulkpack': 'tdcProperties.foodserviceBulkPack',
  'tdcproperties.recycledmaterial': 'tdcProperties.recycledMaterial',
  'tdcproperties.recycledmaterialtype': 'tdcProperties.recycledMaterialType',
  'tdcproperties.soyink': 'tdcProperties.soyInk',
  'tdcproperties.catchweightitem': 'tdcProperties.catchWeightItem',
  'tdcproperties.eannumber': 'tdcProperties.eANNumber',
  'tdcproperties.grossweight': 'tdcProperties.grossWeight',
  'tdcproperties.tareweight': 'tdcProperties.tareWeight',
  'tdcproperties.recipe1': 'tdcProperties.recipe1',
  'tdcproperties.recipe2': 'tdcProperties.recipe2',
  'tdcproperties.recipe3': 'tdcProperties.recipe3',
  'tdcproperties.recipe4': 'tdcProperties.recipe4',
  'tdcproperties.recipe5': 'tdcProperties.recipe5',
  'tdcproperties.alcoholcontentproof': 'tdcProperties.alcoholContentProof',
  'tdcproperties.containercolor': 'tdcProperties.containerColor',
  'tdcproperties.containermaterialandsize':
    'tdcProperties.containerMaterialAndSize',
  'tdcproperties.msds': 'tdcProperties.mSDS',
  'tdcproperties.sulfitesppm': 'tdcProperties.sulfitesPPM',
  'tdcproperties.bottledby': 'tdcProperties.bottledBy',
  'tdcproperties.brokensealwarningpresent':
    'tdcProperties.brokenSealWarningPresent',
  'tdcproperties.codedateexample': 'tdcProperties.codeDateExample',
  'tdcproperties.codedateformula': 'tdcProperties.codeDateFormula',
  'tdcproperties.codedateposition': 'tdcProperties.codeDatePosition',
  'tdcproperties.codedatestamp': 'tdcProperties.codeDateStamp',
  'tdcproperties.codedatetype': 'tdcProperties.codeDateType',
  'tdcproperties.containertype': 'tdcProperties.containerType',
  'tdcproperties.countryoforigintext': 'tdcProperties.countryOfOriginText',
  'tdcproperties.distributedby': 'tdcProperties.distributedBy',
  'tdcproperties.importedby': 'tdcProperties.importedBy',
  'tdcproperties.madein': 'tdcProperties.madeIn',
  'tdcproperties.manufacturercitypackaging':
    'tdcProperties.manufacturerCityPackaging',
  'tdcproperties.manufacturercodeexample':
    'tdcProperties.manufacturerCodeExample',
  'tdcproperties.manufacturercodeformula':
    'tdcProperties.manufacturerCodeFormula',
  'tdcproperties.manufacturercodeposition':
    'tdcProperties.manufacturerCodePosition',
  'tdcproperties.manufacturercountrypackaging':
    'tdcProperties.manufacturerCountryPackaging',
  'tdcproperties.manufacturernamepackaging':
    'tdcProperties.manufacturerNamePackaging',
  'tdcproperties.manufacturerphonenumberpackaging':
    'tdcProperties.manufacturerPhoneNumberPackaging',
  'tdcproperties.manufacturerstatepackaging':
    'tdcProperties.manufacturerStatePackaging',
  'tdcproperties.manufacturerstreetpackaging':
    'tdcProperties.manufacturerStreetPackaging',
  'tdcproperties.manufacturerzipcodepackaging':
    'tdcProperties.manufacturerZipCodePackaging',
  'tdcproperties.merchandisedintray': 'tdcProperties.merchandisedInTray',
  'tdcproperties.packedin': 'tdcProperties.packedIn',
  'tdcproperties.peghole': 'tdcProperties.pegHole',
  'tdcproperties.perishable': 'tdcProperties.perishable',
  'tdcproperties.processedandpackedin': 'tdcProperties.processedandPackedIn',
  'tdcproperties.producedin': 'tdcProperties.producedIn',
  'tdcproperties.productof': 'tdcProperties.productOf',
  'tdcproperties.restriction': 'tdcProperties.restriction',
  'tdcproperties.shelflifeafteropening': 'tdcProperties.shelfLifeAfterOpening',
  'tdcproperties.retaincase': 'tdcProperties.retainCase',
  'tdcproperties.retaininner': 'tdcProperties.retainInner',
  'tdcproperties.retainunit': 'tdcProperties.retainUnit',
  'tdcproperties.stampdate': 'tdcProperties.stampDate',
  'tdcproperties.cubicinches': 'tdcProperties.cubicInches',
  'tdcproperties.upc_check_digit': 'tdcProperties.uPC_Check_Digit',
  'tdcproperties.allowedmembers': 'tdcProperties.allowedMembers',
  'tdcproperties.restrictedmembers': 'tdcProperties.restrictedMembers',
  'tdcproperties.allergensancillary': 'tdcProperties.allergensAncillary',
  'tdcproperties.processedonequipment': 'tdcProperties.processedOnEquipment',
  'tdcproperties.attributesancillary': 'tdcProperties.attributesAncillary',
  'tdcproperties.calorieclaim': 'tdcProperties.calorieClaim',
  'tdcproperties.contains': 'tdcProperties.contains',
  'tdcproperties.doesnotcontain': 'tdcProperties.doesNotContain',
  'tdcproperties.fatcontent': 'tdcProperties.fatContent',
  'tdcproperties.milktype': 'tdcProperties.milkType',
  'tdcproperties.phlevel': 'tdcProperties.pHLevel',
  'tdcproperties.process': 'tdcProperties.process',
  'tdcproperties.saltcontent': 'tdcProperties.saltContent',
  'tdcproperties.sugarsweetener': 'tdcProperties.sugarSweetener',
  'tdcproperties.foodgradeplasticscertifier':
    'tdcProperties.foodGradePlasticsCertifier',
  'tdcproperties.organiccertifier': 'tdcProperties.organicCertifier',
  'tdcproperties.ingredientbreakout': 'tdcProperties.ingredientBreakout',
  'tdcproperties.ingredientsancillary': 'tdcProperties.ingredientsAncillary',
  'tdcproperties.cookinginstructions': 'tdcProperties.cookingInstructions',
  'tdcproperties.instructionsancillary': 'tdcProperties.instructionsAncillary',
  'tdcproperties.openinginstructions': 'tdcProperties.openingInstructions',
  'tdcproperties.useorfreezeby': 'tdcProperties.useOrFreezeBy',
  'tdcproperties.copyrighttrademarkregistered':
    'tdcProperties.copyrightTrademarkRegistered',
  'tdcproperties.productwarningstatement':
    'tdcProperties.productWarningStatement',
  'tdcproperties.questionorcommentsstatement':
    'tdcProperties.questionorCommentsStatement',
  'tdcproperties.slogan': 'tdcProperties.slogan',
  'tdcproperties.socialmedia': 'tdcProperties.socialMedia',
  'tdcproperties.socialmediaaddresses': 'tdcProperties.socialMediaAddresses',
  'tdcproperties.website': 'tdcProperties.website',
  'tdcproperties.ancillarypackaginginformation':
    'tdcProperties.ancillaryPackagingInformation',
  'tdcproperties.packagingdescriptors': 'tdcProperties.packagingDescriptors',
  'tdcproperties.recyclestatement': 'tdcProperties.recycleStatement',
  'tdcproperties.ancillaryinformation': 'tdcProperties.ancillaryInformation',
  'tdcproperties.redemptionvalues': 'tdcProperties.redemptionValues',
  'tdcproperties.rulesandregulationsancillary':
    'tdcProperties.rulesAndRegulationsAncillary',
  'tdcproperties.transportationrestrictions':
    'tdcProperties.transportationRestrictions',
  'tdcproperties.warninglabels': 'tdcProperties.warningLabels',
  'tdcproperties.manufacturercodesupplementaryinformation':
    'tdcProperties.manufacturerCodeSupplementaryInformation',
  'tdcproperties.manufactureremailaddressfrompackage':
    'tdcProperties.manufacturerEmailAddressFromPackage',
  'tdcproperties.supplementarycodedateinformation':
    'tdcProperties.supplementaryCodeDateInformation',
  'tdcproperties.supplychainancillary': 'tdcProperties.supplyChainAncillary',
  'tdcproperties.contractmanufacturer': 'tdcProperties.contractManufacturer',
  'tdcproperties.italcertifiedsealclaim':
    'tdcProperties.italCertifiedSealClaim',
  'tdcproperties.italconscioussealclaim':
    'tdcProperties.italConsciousSealClaim',
  'tdcproperties.italsacramentsealclaim':
    'tdcProperties.italSacramentSealClaim',
  'tdcproperties.beebetterclaim': 'tdcProperties.beeBetterClaim',
  'tdcproperties.nongmocertificationbyearthkosherclaim':
    'tdcProperties.nonGMOCertificationByEarthKosherClaim',
  'tdcproperties.glyphosateresiduefreeclaim':
    'tdcProperties.glyphosateResidueFreeClaim',
  'tdcproperties.sellable': 'tdcProperties.sellable',
  'tdcproperties.originalpackagelevel': 'tdcProperties.originalPackageLevel',
  'tdcproperties.nongmogecertifiedbynsf':
    'tdcProperties.nonGMOGECertifiedByNSF',
  'tdcproperties.nongmocertificationbyearthkosher':
    'tdcProperties.nonGMOCertificationByEarthKosher',
  'tdcproperties.gmostatement': 'tdcProperties.gMOStatement',
  'tdcproperties.newyorkstategrowncertifierclaim':
    'tdcProperties.newYorkStateGrownCertifierClaim',
  'tdcproperties.nongmogmostatement': 'tdcProperties.nonGmoGmoStatement',
  'tdcproperties.rbghbststatement': 'tdcProperties.rbghBstStatement',
  'tdcproperties.vegetablebasedink': 'tdcProperties.vegetableBasedInk',
  'tdcproperties.regenerativeorganicclaim':
    'tdcProperties.regenerativeOrganicClaim',
  'tdcproperties.californiaprop65compliant':
    'tdcProperties.californiaProp65Compliant',
  'tdcproperties.processedmanufacturedinfacilitystatement':
    'tdcProperties.processedManufacturedInFacilityStatement',
  'tdcproperties.infacilityonequipmentincluding':
    'tdcProperties.inFacilityOnEquipmentIncluding',
  'tdcproperties.infacilityonequipmentstatement':
    'tdcProperties.inFacilityOnEquipmentStatement',
  'tdcproperties.patentinformation': 'tdcProperties.patentInformation',
  'tdcproperties.brokensealwarningstatement':
    'tdcProperties.brokenSealWarningStatement',
  'tdcproperties.manufacturedforanddistributedby':
    'tdcProperties.manufacturedForAndDistributedBy',
  'tdcproperties.ixonecertificationlevel':
    'tdcProperties.ixOneCertificationLevel',
  'tdcproperties.ixonecoredate': 'tdcProperties.ixOneCoreDate',
  'tdcproperties.alcoholbyvolumestatement':
    'tdcProperties.alcoholByVolumeStatement',
  'tdcproperties.alcoholproofstatement': 'tdcProperties.alcoholProofStatement',
  'tdcproperties.importedbottledby': 'tdcProperties.importedBottledBy',
  'tdcproperties.producedby': 'tdcProperties.producedBy',
  'tdcproperties.producedbottledby': 'tdcProperties.producedBottledBy',
  'tdcproperties.flavortastingnotes': 'tdcProperties.flavorTastingNotes',
  'tdcproperties.significantsource_p': 'tdcProperties.significantSource_P',
  'tdcproperties.excellentsource_p': 'tdcProperties.excellentSource_P',
  'tdcproperties.notsignificantsource_p':
    'tdcProperties.notSignificantSource_P',
  'tdcproperties.primarynutritionalitemancillary_p':
    'tdcProperties.primaryNutritionalItemAncillary_P',
  'tdcproperties.highpressurecertifiedclaim':
    'tdcProperties.highPressureCertifiedClaim',
  'tdcproperties.secondaryexcellentsource_p':
    'tdcProperties.secondaryExcellentSource_P',
  'tdcproperties.secondarynutritionalitemancillary_p':
    'tdcProperties.secondaryNutritionalItemAncillary_P',
  'tdcproperties.availablelanguages': 'tdcProperties.availableLanguages',
  'tdcproperties.mrmstatus': 'tdcProperties.mRMStatus',
  'tdcproperties.producteta': 'tdcProperties.productETA',
  'tdcproperties.mrmcomment': 'tdcProperties.mRMComment',
  'tdcproperties.californiaprop65warningsymbolpresent':
    'tdcProperties.californiaProp65WarningSymbolPresent',
  'tdcproperties.californiaprop65warningstatement':
    'tdcProperties.californiaProp65WarningStatement',
  'tdcproperties.publishfailmissingfields':
    'tdcProperties.publishFailMissingFields',
  'tdcproperties.containerandmaterialtypes':
    'tdcProperties.containerAndMaterialTypes',
  'tdcproperties.inktypes': 'tdcProperties.inkTypes',
  'tdcproperties.excisetaxes': 'tdcProperties.exciseTaxes',
  'tdcproperties.expectedcost': 'tdcProperties.expectedCost',
  'tdcproperties.actualcost': 'tdcProperties.actualCost',
  'tdcproperties.lostworkcost': 'tdcProperties.lostWorkCost',
  'tdcproperties.aplupload': 'tdcProperties.aPLUpload',
  'tdcproperties.ispaid': 'tdcProperties.isPaid',
  'tdcproperties.isshippingdocumentprinted':
    'tdcProperties.isShippingDocumentPrinted',
  'tdcproperties.totaldownloads': 'tdcProperties.totalDownloads',
  'tdcproperties.totalviews': 'tdcProperties.totalViews',
  'tdcproperties.memberimages': 'tdcProperties.memberImages',
  'tdcproperties.productdiscontinued': 'tdcProperties.productDiscontinued',
  'tdcproperties.receivedin': 'tdcProperties.receivedIn',
  'tdcproperties.datereceived': 'tdcProperties.dateReceived',
  'tdcproperties.completelistingingredient':
    'tdcProperties.completeListingIngredient',
  'tdcproperties.ingredientssourcedfromchina':
    'tdcProperties.ingredientsSourcedFromChina',
  'tdcproperties.sustainablepackagingfeatures':
    'tdcProperties.sustainablePackagingFeatures',
  'tdcproperties.ncgparentcompanyname': 'tdcProperties.ncgParentCompanyName',
  'tdcproperties.ncgreviewstatus': 'tdcProperties.ncgReviewStatus',
  'tdcproperties.ncgreviewimportdate': 'tdcProperties.ncgReviewImportDate',
  'tdcproperties.ncgreviewsubmitdate': 'tdcProperties.ncgReviewSubmitDate',
  'tdcproperties.nonirradiatedingredients':
    'tdcProperties.nonIrradiatedIngredients',
  'tdcproperties.personalcareitemcontains':
    'tdcProperties.personalCareItemContains',
  'tdcproperties.animalproductcontains': 'tdcProperties.animalProductContains',
  'tdcproperties.mrmstatusdate': 'tdcProperties.mrmStatusDate',
  'tdcproperties.productstatusdate': 'tdcProperties.productStatusDate',
  'tdcproperties.note': 'tdcProperties.note',
  'tdcproperties.bronzedisposition': 'tdcProperties.bronzeDisposition',
  'tdcproperties.bronzedispositionsubmitdate':
    'tdcProperties.bronzeDispositionSubmitDate',
  'tdcproperties.bronzereportprocessing':
    'tdcProperties.bronzeReportProcessing',
  'tdcproperties.bronzereportcompleted': 'tdcProperties.bronzeReportCompleted',
  'tdcproperties.dateaddeddisplayexchange':
    'tdcProperties.dateAddedDisplayExchange',
  'tdcproperties.billingentity': 'tdcProperties.billingEntity',
  'tdcproperties.productpublishas': 'tdcProperties.productPublishAs',
  'tdcproperties.bioengineeredyn': 'tdcProperties.bioengineeredYN',
  'tdcproperties.bioengineeredstatement':
    'tdcProperties.bioengineeredStatement',
  'tdcproperties.bioengineeredtype': 'tdcProperties.bioengineeredType',
  'tdcproperties.productworkflowstatus': 'tdcProperties.productWorkflowStatus',
  'tdcproperties.magicnumber': 'tdcProperties.magicNumber',
  'tdcproperties.planogramheight': 'tdcProperties.planogramHeight',
  'tdcproperties.planogramdepth': 'tdcProperties.planogramDepth',
  'tdcproperties.planogramwidth': 'tdcProperties.planogramWidth',
  'tdcproperties.thccontentpercent': 'tdcProperties.tHCContentPercent',
  'tdcproperties.supplementingredientstatement':
    'tdcProperties.supplementIngredientStatement',
  'tdcproperties.imagevendorname': 'tdcProperties.imageVendorName',
  'tdcproperties.availableforcapture': 'tdcProperties.availableForCapture',
  'tdcproperties.bronzeskipcheckout': 'tdcProperties.bronzeSkipCheckout',
  'tdcproperties.gdsnprovidednutclaim': 'tdcProperties.gDSNProvidedNutClaim',
  'tdcproperties.eli_code': 'tdcProperties.eLI_CODE',
  'tdcproperties.lastrequestupcitemdbdate':
    'tdcProperties.lastRequestUpcItemDbDate',
  'tdcproperties.lastupdateddate': 'tdcProperties.lastUpdatedDate',
  'tdcproperties.haspanel': 'tdcProperties.hasPanel',
  'tdcproperties.hassupplementpanel': 'tdcProperties.hasSupplementPanel',
  'tdcproperties.hasaminoacidpanel': 'tdcProperties.hasAminoAcidPanel',
  'tdcproperties.hasnutritionpanel': 'tdcProperties.hasNutritionPanel',
  'tdcproperties.returnproducttomanufacturer':
    'tdcProperties.returnProductToManufacturer',
  'tdcproperties.caseaseach': 'tdcProperties.caseasEach',
  'tdcproperties.productisalsocase': 'tdcProperties.productIsAlsoCase',
  'tdcproperties.heightinpixels': 'tdcProperties.heightInPixels',
  'tdcproperties.widthinpixels': 'tdcProperties.widthInPixels',
  'tdcproperties.depthinpixels': 'tdcProperties.depthInPixels',
  'tdcproperties.ratioheightwidthpixels':
    'tdcProperties.ratioHeightWidthPixels',
  'tdcproperties.ratioheightdepthpixels':
    'tdcProperties.ratioHeightDepthPixels',
  'tdcproperties.ratiodepthwidthpixels': 'tdcProperties.ratioDepthWidthPixels',
  'tdcproperties.needreviewdimension': 'tdcProperties.needReviewDimension',
  'tdcproperties.fairtradestatement': 'tdcProperties.fairTradeStatement',
  'tdcproperties.plu': 'tdcProperties.pLU',
  'tdcproperties._donotbill': 'tdcProperties._DoNotBill',
  'tdcproperties._donotbillreason': 'tdcProperties._DoNotBillReason',
  'tdcproperties.lastlistmanagementupdated':
    'tdcProperties.lastListManagementUpdated',
  'tdcproperties.paiddate': 'tdcProperties.paidDate',
  'tdcproperties.paidreason': 'tdcProperties.paidReason',
  'tdcproperties.governmentwarning': 'tdcProperties.governmentWarning',
  'tdcproperties.sulfitesppc': 'tdcProperties.sulfitesPPC',
  'tdcproperties.winecategory': 'tdcProperties.wineCategory',
  'tdcproperties.winevarietal': 'tdcProperties.wineVarietal',
  'tdcproperties.winesweetness': 'tdcProperties.wineSweetness',
  'tdcproperties.winecorktype': 'tdcProperties.wineCorkType',
  'tdcproperties.winepairswellwith': 'tdcProperties.winePairsWellWith',
  'tdcproperties.winebody': 'tdcProperties.wineBody',
  'tdcproperties.beerbrewery': 'tdcProperties.beerBrewery',
  'tdcproperties.beercategory': 'tdcProperties.beerCategory',
  'tdcproperties.ibus': 'tdcProperties.iBUs',
  'tdcproperties.beertaste': 'tdcProperties.beerTaste',
  'tdcproperties.beerbody': 'tdcProperties.beerBody',
  'tdcproperties.beerfinalgravity': 'tdcProperties.beerFinalGravity',
  'tdcproperties.bwsproducedby': 'tdcProperties.bWSProducedBy',
  'tdcproperties.bwsdistributedby': 'tdcProperties.bWSDistributedBy',
  'tdcproperties.bwsimportedby': 'tdcProperties.bWSImportedBy',
  'tdcproperties.finishedandbottledin': 'tdcProperties.finishedAndBottledIn',
  'tdcproperties.transferringdate': 'tdcProperties.transferringDate',
  'tdcproperties.productrenewaldisposition':
    'tdcProperties.productRenewalDisposition',
  'tdcproperties.productrenewalconfirmeddate':
    'tdcProperties.productRenewalConfirmedDate',
  'tdcproperties.ncgshipped': 'tdcProperties.ncgShipped',
  'tdcproperties.ncgshippeddate': 'tdcProperties.ncgShippedDate',
  'tdcproperties.ncgcontactname': 'tdcProperties.ncgContactName',
  'tdcproperties.ncgcontactemail': 'tdcProperties.ncgContactEmail',
  'tdcproperties.ncgcontactphone': 'tdcProperties.ncgContactPhone',
  'tdcproperties.bwsbottledby': 'tdcProperties.bWSBottledBy',
  'tdcproperties.distilledby': 'tdcProperties.distilledBy',
  'tdcproperties.deletedreason': 'tdcProperties.deletedReason',
  'tdcproperties.businessownermanufactureryn':
    'tdcProperties.businessOwnerManufacturerYN',
  'tdcproperties.mergefrom': 'tdcProperties.mergeFrom',
  'tdcproperties.memberdiscontinued': 'tdcProperties.memberDiscontinued',
  'tdcproperties.memberdiscontinueddate':
    'tdcProperties.memberDiscontinuedDate',
  'tdcproperties._notforretailsale_upc12':
    'tdcProperties._NotForRetailSale_UPC12',
  'tdcproperties.isdeleteddiscontinuedproduct':
    'tdcProperties.isDeletedDiscontinuedProduct',
  'tdcproperties.originalstatus': 'tdcProperties.originalStatus',
  'tdcproperties.usdastatement': 'tdcProperties.uSDAStatement',
  'tdcproperties.stateregioncertification':
    'tdcProperties.stateRegionCertification',
  'tdcproperties.grade': 'tdcProperties.grade',
  'tdcproperties.archivedate': 'tdcProperties.archiveDate',
  'tdcproperties.damagenote': 'tdcProperties.damageNote',
  'tdcproperties.damageapproved': 'tdcProperties.damageApproved',
  'tdcproperties.capturefee': 'tdcProperties.captureFee',
  'tdcproperties.nonfoodproduct': 'tdcProperties.nonFoodProduct',
  'tdcproperties.segmentcode': 'tdcProperties.segmentCode',
  'tdcproperties.segmentdescription': 'tdcProperties.segmentDescription',
  'tdcproperties.familycode': 'tdcProperties.familyCode',
  'tdcproperties.familydescription': 'tdcProperties.familyDescription',
  'tdcproperties.classcode': 'tdcProperties.classCode',
  'tdcproperties.classdescription': 'tdcProperties.classDescription',
  'tdcproperties.brickcodedescription': 'tdcProperties.brickCodeDescription',
  'tdcproperties.innerpack_qrcode': 'tdcProperties.innerPack_QRCode',
  'tdcproperties.innerpack_biodegradable':
    'tdcProperties.innerPack_Biodegradable',
  'tdcproperties.innerpack_bpafreeliner':
    'tdcProperties.innerPack_BPAFreeLiner',
  'tdcproperties.innerpack_bpafreepackaging':
    'tdcProperties.innerPack_BPAFreePackaging',
  'tdcproperties.innerpack_foodservicebulkpack':
    'tdcProperties.innerPack_FoodserviceBulkPack',
  'tdcproperties.innerpack_recycledmaterial':
    'tdcProperties.innerPack_RecycledMaterial',
  'tdcproperties.innerpack_recycledmaterialtype':
    'tdcProperties.innerPack_RecycledMaterialType',
  'tdcproperties.innerpack_soyink': 'tdcProperties.innerPack_SoyInk',
  'tdcproperties.innerpack_eannumber': 'tdcProperties.innerPack_EANNumber',
  'tdcproperties.innerpack_sellable': 'tdcProperties.innerPack_Sellable',
  'tdcproperties.innerpack_codedateexample':
    'tdcProperties.innerPack_CodeDateExample',
  'tdcproperties.innerpack_codedateformula':
    'tdcProperties.innerPack_CodeDateFormula',
  'tdcproperties.innerpack_codedateposition':
    'tdcProperties.innerPack_CodeDatePosition',
  'tdcproperties.innerpack_codedatestamp':
    'tdcProperties.innerPack_CodeDateStamp',
  'tdcproperties.innerpack_codedatetype':
    'tdcProperties.innerPack_CodeDateType',
  'tdcproperties.innerpack_containertype':
    'tdcProperties.innerPack_ContainerType',
  'tdcproperties.innerpack_manufacturercodeexample':
    'tdcProperties.innerPack_ManufacturerCodeExample',
  'tdcproperties.innerpack_manufacturercodeformula':
    'tdcProperties.innerPack_ManufacturerCodeFormula',
  'tdcproperties.innerpack_manufacturercodeposition':
    'tdcProperties.innerPack_ManufacturerCodePosition',
  'tdcproperties.innerpack_copyrighttrademarkregistered':
    'tdcProperties.innerPack_CopyrightTrademarkRegistered',
  'tdcproperties.innerpack_socialmedia': 'tdcProperties.innerPack_SocialMedia',
  'tdcproperties.innerpack_website': 'tdcProperties.innerPack_Website',
  'tdcproperties.innerpack_ancillarypackaginginformation':
    'tdcProperties.innerPack_AncillaryPackagingInformation',
  'tdcproperties.innerpack_corrugateinformation':
    'tdcProperties.innerPack_CorrugateInformation',
  'tdcproperties.innerpack_recyclestatement':
    'tdcProperties.innerPack_RecycleStatement',
  'tdcproperties.innerpack_manufacturercodesupplementaryinformation':
    'tdcProperties.innerPack_ManufacturerCodeSupplementaryInformation',
  'tdcproperties.innerpack_supplementarycodedateinformation':
    'tdcProperties.innerPack_SupplementaryCodeDateInformation',
  'tdcproperties.innerpack_supplychainancillary':
    'tdcProperties.innerPack_SupplyChainAncillary',
  'tdcproperties.innerpack_vegetablebasedink':
    'tdcProperties.innerPack_VegetableBasedInk',
  'tdcproperties.innerpack_boxcertification':
    'tdcProperties.innerPack_BoxCertification',
  'tdcproperties.innerpack_bursttest': 'tdcProperties.innerPack_BurstTest',
  'tdcproperties.innerpack_boxdoublewall':
    'tdcProperties.innerPack_BoxDoublewall',
  'tdcproperties.innerpack_edgecrushtest':
    'tdcProperties.innerPack_EdgeCrushTest',
  'tdcproperties.innerpack_grosswtlimit':
    'tdcProperties.innerPack_GrossWtLimit',
  'tdcproperties.innerpack_mincombwtfacings':
    'tdcProperties.innerPack_MinCombWtFacings',
  'tdcproperties.innerpack_boxsinglewall':
    'tdcProperties.innerPack_BoxSinglewall',
  'tdcproperties.innerpack_sizelimit': 'tdcProperties.innerPack_SizeLimit',
  'tdcproperties.innerpack_boxmanufactureraddress':
    'tdcProperties.innerPack_BoxManufacturerAddress',
  'tdcproperties.innerpack_manufacturedforanddistributedby':
    'tdcProperties.innerPack_ManufacturedForAndDistributedBy',
  'tdcproperties.innerpack_contains': 'tdcProperties.innerPack_Contains',
  'tdcproperties.innerpack_doesnotcontain':
    'tdcProperties.innerPack_DoesNotContain',
  'tdcproperties.innerpack_alcoholbyvolumestatement':
    'tdcProperties.innerPack_AlcoholByVolumeStatement',
  'tdcproperties.innerpack_alcoholproofstatement':
    'tdcProperties.innerPack_AlcoholProofStatement',
  'tdcproperties.innerpack_importedbottledby':
    'tdcProperties.innerPack_ImportedBottledBy',
  'tdcproperties.innerpack_producedby': 'tdcProperties.innerPack_ProducedBy',
  'tdcproperties.innerpack_producedbottledby':
    'tdcProperties.innerPack_ProducedBottledBy',
  'tdcproperties.innerpack_vintageyear': 'tdcProperties.innerPack_VintageYear',
  'tdcproperties.innerpack_flavortastingnotes':
    'tdcProperties.innerPack_FlavorTastingNotes',
  'tdcproperties.innerpack_winery': 'tdcProperties.innerPack_Winery',
  'tdcproperties.innerpack_brewery': 'tdcProperties.innerPack_Brewery',
  'tdcproperties.innerpack_californiaprop65warningsymbolpresent':
    'tdcProperties.innerPack_CaliforniaProp65WarningSymbolPresent',
  'tdcproperties.innerpack_californiaprop65warningstatement':
    'tdcProperties.innerPack_CaliforniaProp65WarningStatement',
  'tdcproperties.innerpack_nocodepresent':
    'tdcProperties.innerPack_NoCodePresent',
  'tdcproperties.innerpack_containerandmaterialtypes':
    'tdcProperties.innerPack_ContainerAndMaterialTypes',
  'tdcproperties.innerpack_inktypes': 'tdcProperties.innerPack_InkTypes',
  'tdcproperties.innerpack_innerpackidentifier':
    'tdcProperties.innerPack_InnerpackIdentifier',
  'tdcproperties.innerpack_innerpack_note':
    'tdcProperties.innerPack_InnerPack_Note',
  'tdcproperties.innerpack_oldinnerpackidentifier':
    'tdcProperties.innerPack_OldInnerpackIdentifier',
  'tdcproperties.innerpack_planogramheight':
    'tdcProperties.innerPack_PlanogramHeight',
  'tdcproperties.innerpack_planogramdepth':
    'tdcProperties.innerPack_PlanogramDepth',
  'tdcproperties.innerpack_planogramwidth':
    'tdcProperties.innerPack_PlanogramWidth',
  'tdcproperties.innerpack_ingredientschina':
    'tdcProperties.innerPack_IngredientsChina',
  'tdcproperties.innerpack_ingredientbreakout':
    'tdcProperties.innerPack_IngredientBreakout',
  'tdcproperties.innerpack_ingredientsancillary':
    'tdcProperties.innerPack_IngredientsAncillary',
  'tdcproperties.innerpack_cookinginstructions':
    'tdcProperties.innerPack_CookingInstructions',
  'tdcproperties.innerpack_instructionsancillary':
    'tdcProperties.innerPack_InstructionsAncillary',
  'tdcproperties.innerpack_openinginstructions':
    'tdcProperties.innerPack_OpeningInstructions',
  'tdcproperties.innerpack_heightinpixels':
    'tdcProperties.innerPack_HeightInPixels',
  'tdcproperties.innerpack_widthinpixels':
    'tdcProperties.innerPack_WidthInPixels',
  'tdcproperties.innerpack_depthinpixels':
    'tdcProperties.innerPack_DepthInPixels',
  'tdcproperties.innerpack_ratioheightwidthpixels':
    'tdcProperties.innerPack_RatioHeightWidthPixels',
  'tdcproperties.innerpack_ratioheightdepthpixels':
    'tdcProperties.innerPack_RatioHeightDepthPixels',
  'tdcproperties.innerpack_ratiodepthwidthpixels':
    'tdcProperties.innerPack_RatioDepthWidthPixels',
  'tdcproperties.innerpack_needreviewdimension':
    'tdcProperties.innerPack_NeedReviewDimension',
  'tdcproperties.innerpack_lastlistmanagementupdated':
    'tdcProperties.innerPack_LastListManagementUpdated',
  'tdcproperties.innerpack_alcoholproof':
    'tdcProperties.innerPack_AlcoholProof',
  'tdcproperties.innerpack_percentalcoholbyvolume':
    'tdcProperties.innerPack_PercentAlcoholByVolume',
  'tdcproperties.innerpack_governmentwarning':
    'tdcProperties.innerPack_GovernmentWarning',
  'tdcproperties.innerpack_distilledin': 'tdcProperties.innerPack_DistilledIn',
  'tdcproperties.innerpack_distilledfrom':
    'tdcProperties.innerPack_DistilledFrom',
  'tdcproperties.innerpack_sulfitesppc': 'tdcProperties.innerPack_SulfitesPPC',
  'tdcproperties.innerpack_winecategory':
    'tdcProperties.innerPack_WineCategory',
  'tdcproperties.innerpack_winevarietal':
    'tdcProperties.innerPack_WineVarietal',
  'tdcproperties.innerpack_winesweetness':
    'tdcProperties.innerPack_WineSweetness',
  'tdcproperties.innerpack_wineregion': 'tdcProperties.innerPack_WineRegion',
  'tdcproperties.innerpack_winecorktype':
    'tdcProperties.innerPack_WineCorkType',
  'tdcproperties.innerpack_winepairswellwith':
    'tdcProperties.innerPack_WinePairsWellWith',
  'tdcproperties.innerpack_winecomplements':
    'tdcProperties.innerPack_WineComplements',
  'tdcproperties.innerpack_wineyear': 'tdcProperties.innerPack_WineYear',
  'tdcproperties.innerpack_winevintage': 'tdcProperties.innerPack_WineVintage',
  'tdcproperties.innerpack_winetaste': 'tdcProperties.innerPack_WineTaste',
  'tdcproperties.innerpack_winebody': 'tdcProperties.innerPack_WineBody',
  'tdcproperties.innerpack_winecharacter':
    'tdcProperties.innerPack_WineCharacter',
  'tdcproperties.innerpack_spiritsyearsaged':
    'tdcProperties.innerPack_SpiritsYearsAged',
  'tdcproperties.innerpack_spiritsbarreltype':
    'tdcProperties.innerPack_SpiritsBarrelType',
  'tdcproperties.innerpack_spiritsflavor':
    'tdcProperties.innerPack_SpiritsFlavor',
  'tdcproperties.innerpack_spiritscategory':
    'tdcProperties.innerPack_SpiritsCategory',
  'tdcproperties.innerpack_spiritssubcategory':
    'tdcProperties.innerPack_SpiritsSubCategory',
  'tdcproperties.innerpack_spiritsstyle':
    'tdcProperties.innerPack_SpiritsStyle',
  'tdcproperties.innerpack_spiritsregion':
    'tdcProperties.innerPack_SpiritsRegion',
  'tdcproperties.innerpack_spiritstaste':
    'tdcProperties.innerPack_SpiritsTaste',
  'tdcproperties.innerpack_beerbrewery': 'tdcProperties.innerPack_BeerBrewery',
  'tdcproperties.innerpack_beerregion': 'tdcProperties.innerPack_BeerRegion',
  'tdcproperties.innerpack_beercategory':
    'tdcProperties.innerPack_BeerCategory',
  'tdcproperties.innerpack_ibus': 'tdcProperties.innerPack_IBUs',
  'tdcproperties.innerpack_beerstyle': 'tdcProperties.innerPack_BeerStyle',
  'tdcproperties.innerpack_beertaste': 'tdcProperties.innerPack_BeerTaste',
  'tdcproperties.innerpack_beerbody': 'tdcProperties.innerPack_BeerBody',
  'tdcproperties.innerpack_beerfinalgravity':
    'tdcProperties.innerPack_BeerFinalGravity',
  'tdcproperties.innerpack_bwsproducedby':
    'tdcProperties.innerPack_BWSProducedBy',
  'tdcproperties.innerpack_bwsdistributedby':
    'tdcProperties.innerPack_BWSDistributedBy',
  'tdcproperties.innerpack_bwsimportedby':
    'tdcProperties.innerPack_BWSImportedBy',
  'tdcproperties.innerpack_finishedandbottledin':
    'tdcProperties.innerPack_FinishedAndBottledIn',
  'tdcproperties.innerpack_wineorigin': 'tdcProperties.innerPack_WineOrigin',
  'tdcproperties.innerpack_bwsbottledby':
    'tdcProperties.innerPack_BWSBottledBy',
  'tdcproperties.innerpack_distilledby': 'tdcProperties.innerPack_DistilledBy',
  'tdcproperties.case_qrcode': 'tdcProperties.case_QRCode',
  'tdcproperties.case_boxcertification': 'tdcProperties.case_BoxCertification',
  'tdcproperties.case_boxdoublewall': 'tdcProperties.case_BoxDoublewall',
  'tdcproperties.case_boxmanufacturer': 'tdcProperties.case_BoxManufacturer',
  'tdcproperties.case_boxmanufacturercity':
    'tdcProperties.case_BoxManufacturerCity',
  'tdcproperties.case_boxmanufacturerfaxnumber':
    'tdcProperties.case_BoxManufacturerFaxNumber',
  'tdcproperties.case_boxmanufacturerstate':
    'tdcProperties.case_BoxManufacturerState',
  'tdcproperties.case_boxmanufacturerstreet':
    'tdcProperties.case_BoxManufacturerStreet',
  'tdcproperties.case_boxmanufacturertelephonenumber':
    'tdcProperties.case_BoxManufacturerTelephoneNumber',
  'tdcproperties.case_boxmanufacturerzip':
    'tdcProperties.case_BoxManufacturerZip',
  'tdcproperties.case_bpafreeliner': 'tdcProperties.case_BPAFreeLiner',
  'tdcproperties.case_burstingtest': 'tdcProperties.case_BurstingTest',
  'tdcproperties.case_edgecrushtest': 'tdcProperties.case_EdgeCrushTest',
  'tdcproperties.case_foodservicebulkpack':
    'tdcProperties.case_FoodserviceBulkPack',
  'tdcproperties.case_mincombwtfacings': 'tdcProperties.case_MinCombWtFacings',
  'tdcproperties.case_packagedondatecode':
    'tdcProperties.case_PackagedOnDateCode',
  'tdcproperties.case_recycledmaterialtype':
    'tdcProperties.case_RecycledMaterialType',
  'tdcproperties.case_sizelimit': 'tdcProperties.case_SizeLimit',
  'tdcproperties.case_soyink': 'tdcProperties.case_SoyInk',
  'tdcproperties.case_eannumber': 'tdcProperties.case_EANNumber',
  'tdcproperties.case_sellable': 'tdcProperties.case_Sellable',
  'tdcproperties.case_casesperpallet': 'tdcProperties.case_CasesPerPallet',
  'tdcproperties.case_codedateexample': 'tdcProperties.case_CodeDateExample',
  'tdcproperties.case_codedateformula': 'tdcProperties.case_CodeDateFormula',
  'tdcproperties.case_codedateposition': 'tdcProperties.case_CodeDatePosition',
  'tdcproperties.case_codedatestamp': 'tdcProperties.case_CodeDateStamp',
  'tdcproperties.case_codedatetype': 'tdcProperties.case_CodeDateType',
  'tdcproperties.case_containertype': 'tdcProperties.case_ContainerType',
  'tdcproperties.case_manufacturercodeexample':
    'tdcProperties.case_ManufacturerCodeExample',
  'tdcproperties.case_manufacturercodeformula':
    'tdcProperties.case_ManufacturerCodeFormula',
  'tdcproperties.case_temperaturerangehigh':
    'tdcProperties.case_TemperatureRangeHigh',
  'tdcproperties.case_temperaturerangelow':
    'tdcProperties.case_TemperatureRangeLow',
  'tdcproperties.case_copyrighttrademarkregistered':
    'tdcProperties.case_CopyrightTrademarkRegistered',
  'tdcproperties.case_socialmedia': 'tdcProperties.case_SocialMedia',
  'tdcproperties.case_website': 'tdcProperties.case_Website',
  'tdcproperties.case_ancillarypackaginginformation':
    'tdcProperties.case_AncillaryPackagingInformation',
  'tdcproperties.case_boxmanufacturerwebsite':
    'tdcProperties.case_BoxManufacturerWebsite',
  'tdcproperties.case_recyclestatement': 'tdcProperties.case_RecycleStatement',
  'tdcproperties.case_manufacturercodesupplementaryinformation':
    'tdcProperties.case_ManufacturerCodeSupplementaryInformation',
  'tdcproperties.case_supplementarycodedateinformation':
    'tdcProperties.case_SupplementaryCodeDateInformation',
  'tdcproperties.case_supplychainancillary':
    'tdcProperties.case_SupplyChainAncillary',
  'tdcproperties.case_vegetablebasedink':
    'tdcProperties.case_VegetableBasedInk',
  'tdcproperties.case_boxmanufactureraddress':
    'tdcProperties.case_BoxManufacturerAddress',
  'tdcproperties.case_manufacturedforanddistributedby':
    'tdcProperties.case_ManufacturedForAndDistributedBy',
  'tdcproperties.case_alcoholbyvolumestatement':
    'tdcProperties.case_AlcoholByVolumeStatement',
  'tdcproperties.case_alcoholproofstatement':
    'tdcProperties.case_AlcoholProofStatement',
  'tdcproperties.case_importedbottledby':
    'tdcProperties.case_ImportedBottledBy',
  'tdcproperties.case_producedby': 'tdcProperties.case_ProducedBy',
  'tdcproperties.case_producedbottledby':
    'tdcProperties.case_ProducedBottledBy',
  'tdcproperties.case_vintageyear': 'tdcProperties.case_VintageYear',
  'tdcproperties.case_flavortastingnotes':
    'tdcProperties.case_FlavorTastingNotes',
  'tdcproperties.case_winery': 'tdcProperties.case_Winery',
  'tdcproperties.case_brewery': 'tdcProperties.case_Brewery',
  'tdcproperties.case_californiaprop65warningsymbolpresent':
    'tdcProperties.case_CaliforniaProp65WarningSymbolPresent',
  'tdcproperties.case_californiaprop65warningstatement':
    'tdcProperties.case_CaliforniaProp65WarningStatement',
  'tdcproperties.case_nocodepresent': 'tdcProperties.case_NoCodePresent',
  'tdcproperties.case_inktypes': 'tdcProperties.case_InkTypes',
  'tdcproperties.case_caseidentifier': 'tdcProperties.case_CaseIdentifier',
  'tdcproperties.case_note': 'tdcProperties.case_Note',
  'tdcproperties.case_oldcaseidentifier':
    'tdcProperties.case_OldCaseIdentifier',
  'tdcproperties.case_transportationrestrictions':
    'tdcProperties.case_TransportationRestrictions',
  'tdcproperties.case_ingredientschina': 'tdcProperties.case_IngredientsChina',
  'tdcproperties.case_ingredientbreakout':
    'tdcProperties.case_IngredientBreakout',
  'tdcproperties.case_ingredientsancillary':
    'tdcProperties.case_IngredientsAncillary',
  'tdcproperties.case_cookinginstructions':
    'tdcProperties.case_CookingInstructions',
  'tdcproperties.case_instructionsancillary':
    'tdcProperties.case_InstructionsAncillary',
  'tdcproperties.case_openinginstructions':
    'tdcProperties.case_OpeningInstructions',
  'tdcproperties.case_heightinpixels': 'tdcProperties.case_HeightInPixels',
  'tdcproperties.case_widthinpixels': 'tdcProperties.case_WidthInPixels',
  'tdcproperties.case_depthinpixels': 'tdcProperties.case_DepthInPixels',
  'tdcproperties.case_ratioheightwidthpixels':
    'tdcProperties.case_RatioHeightWidthPixels',
  'tdcproperties.case_ratioheightdepthpixels':
    'tdcProperties.case_RatioHeightDepthPixels',
  'tdcproperties.case_ratiodepthwidthpixels':
    'tdcProperties.case_RatioDepthWidthPixels',
  'tdcproperties.case_needreviewdimension':
    'tdcProperties.case_NeedReviewDimension',
  'tdcproperties.case_lastlistmanagementupdated':
    'tdcProperties.case_LastListManagementUpdated',
  'tdcproperties.case_alcoholproof': 'tdcProperties.case_AlcoholProof',
  'tdcproperties.case_percentalcoholbyvolume':
    'tdcProperties.case_PercentAlcoholByVolume',
  'tdcproperties.case_governmentwarning':
    'tdcProperties.case_GovernmentWarning',
  'tdcproperties.case_distilledin': 'tdcProperties.case_DistilledIn',
  'tdcproperties.case_distilledfrom': 'tdcProperties.case_DistilledFrom',
  'tdcproperties.case_sulfitesppc': 'tdcProperties.case_SulfitesPPC',
  'tdcproperties.case_winecategory': 'tdcProperties.case_WineCategory',
  'tdcproperties.case_winevarietal': 'tdcProperties.case_WineVarietal',
  'tdcproperties.case_winesweetness': 'tdcProperties.case_WineSweetness',
  'tdcproperties.case_wineregion': 'tdcProperties.case_WineRegion',
  'tdcproperties.case_winecorktype': 'tdcProperties.case_WineCorkType',
  'tdcproperties.case_winepairswellwith':
    'tdcProperties.case_WinePairsWellWith',
  'tdcproperties.case_winecomplements': 'tdcProperties.case_WineComplements',
  'tdcproperties.case_wineyear': 'tdcProperties.case_WineYear',
  'tdcproperties.case_winevintage': 'tdcProperties.case_WineVintage',
  'tdcproperties.case_winetaste': 'tdcProperties.case_WineTaste',
  'tdcproperties.case_winebody': 'tdcProperties.case_WineBody',
  'tdcproperties.case_winecharacter': 'tdcProperties.case_WineCharacter',
  'tdcproperties.case_spiritsyearsaged': 'tdcProperties.case_SpiritsYearsAged',
  'tdcproperties.case_spiritsbarreltype':
    'tdcProperties.case_SpiritsBarrelType',
  'tdcproperties.case_spiritsflavor': 'tdcProperties.case_SpiritsFlavor',
  'tdcproperties.case_spiritscategory': 'tdcProperties.case_SpiritsCategory',
  'tdcproperties.case_spiritssubcategory':
    'tdcProperties.case_SpiritsSubCategory',
  'tdcproperties.case_spiritsstyle': 'tdcProperties.case_SpiritsStyle',
  'tdcproperties.case_spiritsregion': 'tdcProperties.case_SpiritsRegion',
  'tdcproperties.case_spiritstaste': 'tdcProperties.case_SpiritsTaste',
  'tdcproperties.case_beerbrewery': 'tdcProperties.case_BeerBrewery',
  'tdcproperties.case_beerregion': 'tdcProperties.case_BeerRegion',
  'tdcproperties.case_beercategory': 'tdcProperties.case_BeerCategory',
  'tdcproperties.case_ibus': 'tdcProperties.case_IBUs',
  'tdcproperties.case_beerstyle': 'tdcProperties.case_BeerStyle',
  'tdcproperties.case_beertaste': 'tdcProperties.case_BeerTaste',
  'tdcproperties.case_beerbody': 'tdcProperties.case_BeerBody',
  'tdcproperties.case_beerfinalgravity': 'tdcProperties.case_BeerFinalGravity',
  'tdcproperties.case_bwsproducedby': 'tdcProperties.case_BWSProducedBy',
  'tdcproperties.case_bwsdistributedby': 'tdcProperties.case_BWSDistributedBy',
  'tdcproperties.case_bwsimportedby': 'tdcProperties.case_BWSImportedBy',
  'tdcproperties.case_finishedandbottledin':
    'tdcProperties.case_FinishedAndBottledIn',
  'tdcproperties.case_wineorigin': 'tdcProperties.case_WineOrigin',
  'tdcproperties.case_shippingtemperaturerangehigh':
    'tdcProperties.case_ShippingTemperatureRangeHigh',
  'tdcproperties.case_shippingtemperaturerangelow':
    'tdcProperties.case_ShippingTemperatureRangeLow',
  'tdcproperties.case_bwsbottledby': 'tdcProperties.case_BWSBottledBy',
  'tdcproperties.case_distilledby': 'tdcProperties.case_DistilledBy',
  'tdcproperties.unit_krogerclasscode': 'tdcProperties.unit_KrogerClassCode',
  'tdcproperties.unit_minimumshelflife': 'tdcProperties.unit_MinimumShelfLife',
  'tdcproperties.unit_maximumshelflife': 'tdcProperties.unit_MaximumShelfLife',
  'tdcproperties.unit_minimumtransporttemp':
    'tdcProperties.unit_MinimumTransportTemp',
  'tdcproperties.unit_maximumtransporttemp':
    'tdcProperties.unit_MaximumTransportTemp',
  'tdcproperties.unit_minimumstoragehandlingtemp':
    'tdcProperties.unit_MinimumStorageHandlingTemp',
  'tdcproperties.unit_maximumstoragehandlingtemp':
    'tdcProperties.unit_MaximumStorageHandlingTemp',
  'tdcproperties.unit_tie': 'tdcProperties.unit_Tie',
  'tdcproperties.unit_high': 'tdcProperties.unit_High',
  'tdcproperties.case_krogerclasscode': 'tdcProperties.case_KrogerClassCode',
  'tdcproperties.case_minimumshelflife': 'tdcProperties.case_MinimumShelfLife',
  'tdcproperties.case_maximumshelflife': 'tdcProperties.case_MaximumShelfLife',
  'tdcproperties.case_minimumtransporttemp':
    'tdcProperties.case_MinimumTransportTemp',
  'tdcproperties.case_maximumtransporttemp':
    'tdcProperties.case_MaximumTransportTemp',
  'tdcproperties.case_minimumstoragehandlingtemp':
    'tdcProperties.case_MinimumStorageHandlingTemp',
  'tdcproperties.case_maximumstoragehandlingtemp':
    'tdcProperties.case_MaximumStorageHandlingTemp',
  'tdcproperties.case_tie': 'tdcProperties.case_Tie',
  'tdcproperties.case_high': 'tdcProperties.case_High',
  'tdcproperties.innerpack_krogerclasscode':
    'tdcProperties.innerPack_KrogerClassCode',
  'tdcproperties.innerpack_minimumshelflife':
    'tdcProperties.innerPack_MinimumShelfLife',
  'tdcproperties.innerpack_maximumshelflife':
    'tdcProperties.innerPack_MaximumShelfLife',
  'tdcproperties.innerpack_minimumtransporttemp':
    'tdcProperties.innerPack_MinimumTransportTemp',
  'tdcproperties.innerpack_maximumtransporttemp':
    'tdcProperties.innerPack_MaximumTransportTemp',
  'tdcproperties.innerpack_minimumstoragehandlingtemp':
    'tdcProperties.innerPack_MinimumStorageHandlingTemp',
  'tdcproperties.innerpack_maximumstoragehandlingtemp':
    'tdcProperties.innerPack_MaximumStorageHandlingTemp',
  'tdcproperties.innerpack_tie': 'tdcProperties.innerPack_Tie',
  'tdcproperties.innerpack_high': 'tdcProperties.innerPack_High',
  'tdcproperties.nutpanel_indicators': 'tdcProperties.nutPanel_Indicators',
  'tdcproperties.nutpanel_indicatorsforamount':
    'tdcProperties.nutPanel_IndicatorsForAmount',
  'tdcproperties.suppanel_indicators': 'tdcProperties.supPanel_Indicators',
  'tdcproperties.suppanel_indicatorsforamount':
    'tdcProperties.supPanel_IndicatorsForAmount',
  'tdcproperties.aminopanel_essential': 'tdcProperties.aminoPanel_Essential',
  'tdcproperties.aminopanel_onescoop': 'tdcProperties.aminoPanel_OneScoop',
  'tdcproperties.aminopanel_twoscoop': 'tdcProperties.aminoPanel_TwoScoop',
  'tdcproperties.aminopanel_indicators': 'tdcProperties.aminoPanel_Indicators',
  'tdcproperties.ixoneid': 'tdcproperties.ixoneid',
};

export const BASE_MODULE = {
  adasealofacceptance: 'ADASealofAcceptance',
  authenticmadeproducedbyamericanindianscertifier:
    'AuthenticMadeProducedByAmericanIndiansCertifier',
  beebettercertifier: 'BeeBetterCertifier',
  biobasedcertifier: 'BioBasedCertifier',
  biodynamiccertifier: 'BioDynamicCertifier',
  businessownermanufacturercertifier: 'BusinessOwnerManufacturerCertifier',
  carbonfootprintcertifier: 'CarbonFootprintCertifier',
  cbdhempcertifier: 'CBDHempCertifier',
  certificationsancillary: 'CertificationsAncillary',
  certifiedauthenticmadeproducedbyamericanindians:
    'CertifiedAuthenticMadeProducedByAmericanIndians',
  certifiedbcorporation: 'CertifiedBCorporation',
  coldpressuredprotectedclaim: 'ColdPressuredProtectedClaim',
  coldpressureverified: 'ColdPressureVerified',
  cradletocradlecertifier: 'CradleToCradleCertifier',
  crueltyfreecertifier: 'CrueltyFreeCertifier',
  diabeticfriendlycertifier: 'DiabeticFriendlyCertifier',
  ecofisherycertification: 'EcoFisheryCertification',
  fairtradecertifier: 'FairTradeCertifier',
  forlifecertifier: 'ForLifeCertifier',
  gdsnprovidedcertifications: 'GDSNProvidedCertifications',
  glutenfreecertifier: 'GlutenFreeCertifier',
  glycemicindexcertifier: 'GlycemicIndexCertifier',
  glyphosateintransition: 'GlyphosateInTransition',
  glyphosateintransitionclaim: 'GlyphosateInTransitionClaim',
  glyphosateresiduefree: 'GlyphosateResidueFree',
  gmpcertifier: 'GMPCertifier',
  grassfedcertifier: 'GrassFedCertifier',
  halalcertifier: 'HalalCertifier',
  hearthealthycertifier: 'HeartHealthyCertifier',
  highpressurecertifiedcertifier: 'HighPressureCertifiedCertifier',
  highpressurecoldpressurecoldpressuredstatement:
    'HighPressureColdPressureColdPressuredStatement',
  internationalpackagediceassociation: 'InternationalPackagedIceAssociation',
  italcertifiedsealcertifier: 'ItalCertifiedSealCertifier',
  italconscioussealcertifier: 'ItalConsciousSealCertifier',
  italsacramentsealcertifier: 'ItalSacramentSealCertifier',
  ketocertifier: 'KetoCertifier',
  koshercertification: 'KosherCertification',
  koshertype: 'KosherType',
  diettypecode: 'DietTypeCode',
  liveandactiveculturecertifier: 'LiveAndActiveCultureCertifier',
  lowglycemiccertifier: 'LowGlycemicCertifier',
  naturalpersonalcarecertifier: 'NaturalPersonalCareCertifier',
  newyorkstategrowncertifier: 'NewYorkStateGrownCertifier',
  nongmocertifier: 'NonGMOCertifier',
  npacertifier: 'NPACertifier',
  nutrasouceigenprogram: 'NutrasouceIGENProgram',
  onepercentfortheplanetcertifier: 'OnePercentForThePlanetCertifier',
  organiccertifyingbody: 'OrganicCertifyingBody',
  organicpercentage: 'OrganicPercentage',
  organicpercentagetext: 'OrganicPercentageText',
  organicstandardsagency: 'OrganicStandardsAgency',
  organicstandardsagencylogo: 'OrganicStandardsAgencyLogo',
  pacacertifier: 'PACACertifier',
  paleocertifier: 'PaleoCertifier',
  pasacertifier: 'PASACertifier',
  plantbasedderivedcertifier: 'PlantBasedDerivedCertifier',
  rainforestalliancecertifier: 'RainForestAllianceCertifier',
  realcertified: 'REALCertified',
  realsealcertifier: 'RealSealCertifier',
  regenerativeorganiccertifier: 'RegenerativeOrganicCertifier',
  renewableenergycertifier: 'RenewableEnergyCertifier',
  sustainabilitycertifier: 'SustainabilityCertifier',
  sustainablefisheriespartnership_sfp: 'SustainableFisheriesPartnership_SFP',
  vegancertifier: 'VeganCertifier',
  vegetariancertifier: 'VegetarianCertifier',
  veteranscertifier: 'VeteransCertifier',
  viticulturecertifier: 'ViticultureCertifier',
  waterqualityassociationcertifier: 'WaterQualityAssociationCertifier',
  packagingmarkedlabelaccreditationcode:
    'PackagingMarkedLabelAccreditationCode',
  wholegraincertifier: 'WholeGrainCertifier',
  womensbusinessenterprisenationalcouncilcertifier:
    'WomensBusinessEnterpriseNationalCouncilCertifier',
  brandname: 'BrandName',
  brickcode: 'BrickCode',
  brickcodename: 'BrickCodeName',
  dateadded: 'DateAdded',
  datecaptured: 'DateCaptured',
  drainedweight: 'DrainedWeight',
  drainedweightuom: 'DrainedWeightUOM',
  esymbolpresent: 'eSymbolPresent',
  imperialsize: 'ImperialSize',
  imperialuom: 'ImperialUOM',
  inproduction: 'InProduction',
  ismultipack: 'IsMultiPack',
  ixoneid: 'IXOneId',
  manufacturernameheader: 'ManufacturerNameHeader',
  metricsize: 'MetricSize',
  metricuom: 'MetricUOM',
  primarysize: 'PrimarySize',
  individualunitmaximumsize: 'IndividualUnitMaximumSize',
  netcontent: 'NetContent',
  netcontentstatement: 'NetContentStatement',
  primarysizeuom: 'PrimarySizeUOM',
  netcontentuom: 'NetContentUom',
  individualunitmaximumsizeuom: 'IndividualUnitMaximumSizeUom',
  productname: 'ProductName',
  productdescription: 'ProductDescription',
  tradeitemdescription: 'TradeItemDescription',
  functionalname: 'FunctionalName',
  productflavor: 'ProductFlavor',
  productscent: 'ProductScent',
  productstyle: 'ProductStyle',
  secondarydrainedweight: 'SecondaryDrainedWeight',
  secondarydrainedweightuom: 'SecondaryDrainedWeightUOM',
  secondaryproductdescription: 'SecondaryProductDescription',
  secondarysize: 'SecondarySize',
  secondarysizeuom: 'SecondarySizeUOM',
  servingsuggestionpresent: 'ServingSuggestionPresent',
  tertiarydescription: 'TertiaryDescription',
  tertiarysize: 'TertiarySize',
  tertiarysizeuom: 'TertiarySizeUOM',
  unitcount: 'UnitCount',
  vendoritemnumber: 'VendorItemNumber',
};

export const ADVANCE_MODULE = {
  'tradeitemmeasurements.tradeitemmeasurements.netcontent.value':
    'tradeitemmeasurements.tradeitemmeasurements.netcontent.value',
  'tradeitemmeasurements.tradeitemmeasurements.netcontent.measurementunitcode':
    'tradeitemmeasurements.tradeitemmeasurements.netcontent.measurementunitcode',
  'tradeitemmeasurements.tradeitemmeasurements.netcontentstatement.value':
    'tradeitemmeasurements.tradeitemmeasurements.netcontentstatement.value',
  'tradeitemmeasurements.tradeitemmeasurements.netcontentstatement.languagecode':
    'tradeitemmeasurements.tradeitemmeasurements.netcontentstatement.languagecode',
  'tradeitemmeasurements.tradeitemmeasurements.width.value':
    'tradeitemmeasurements.tradeitemmeasurements.width.value',
  'tradeitemmeasurements.tradeitemmeasurements.width.measurementunitcode':
    'tradeitemmeasurements.tradeitemmeasurements.width.measurementunitcode',
  'tradeitemmeasurements.tradeitemmeasurements.height.value':
    'tradeitemmeasurements.tradeitemmeasurements.height.value',
  'tradeitemmeasurements.tradeitemmeasurements.height.measurementunitcode':
    'tradeitemmeasurements.tradeitemmeasurements.height.measurementunitcode',
  'tradeitemmeasurements.tradeitemmeasurements.depth.value':
    'tradeitemmeasurements.tradeitemmeasurements.depth.value',
  'tradeitemmeasurements.tradeitemmeasurements.depth.measurementunitcode':
    'tradeitemmeasurements.tradeitemmeasurements.depth.measurementunitcode',
  'tradeitemmeasurements.tradeitemmeasurements.tradeitemweight.grossweight.value':
    'tradeitemmeasurements.tradeitemmeasurements.tradeitemweight.grossweight.value',
  'tradeitemmeasurements.tradeitemmeasurements.tradeitemweight.grossweight.measurementunitcode':
    'tradeitemmeasurements.tradeitemmeasurements.tradeitemweight.grossweight.measurementunitcode',
  'tradeitemmeasurements.tradeitemmeasurements.inboxcubedimension.value':
    'tradeitemmeasurements.tradeitemmeasurements.inboxcubedimension.value',
  'tradeitemmeasurements.tradeitemmeasurements.inboxcubedimension.measurementunitcode':
    'tradeitemmeasurements.tradeitemmeasurements.inboxcubedimension.measurementunitcode',
  'tradeitemlifespan.tradeitemlifespan.minimumtradeitemlifespanfromtimeofarrival':
    'TradeItemLifespan.TradeItemLifespan.MinimumTradeItemLifespanFromTimeOfArrival',
  'tradeitemlifespan.tradeitemlifespan.minimumtradeitemlifespanfromtimeofproduction':
    'TradeItemLifespan.TradeItemLifespan.MinimumTradeItemLifespanFromTimeOfProduction',
  'foodandbeverageingredient.juicecontentpercent':
    'FoodAndBeverageIngredient.JuiceContentPercent',
  'foodandbeverageingredient.ingredientstatement.value':
    'foodandbeverageingredient.ingredientstatement.value',
  'consumerinstructions.consumerinstructions.consumerstorageinstructions.value':
    'consumerinstructions.consumerinstructions.consumerstorageinstructions.value',
  'consumerinstructions.consumerinstructions.consumerstorageinstructions.languagecode':
    'consumerinstructions.consumerinstructions.consumerstorageinstructions.languagecode',
  'consumerinstructions.consumerinstructions.consumerusageinstructions.value':
    'consumerinstructions.consumerinstructions.consumerusageinstructions.value',
  'consumerinstructions.consumerinstructions.consumerusageinstructions.languagecode':
    'consumerinstructions.consumerinstructions.consumerusageinstructions.languagecode',
  'marketinginformation.marketinginformation.marketingcampaign.tastingnotes.value':
    'marketinginformation.marketinginformation.marketingcampaign.tastingnotes.value',
  'marketinginformation.marketinginformation.marketingcampaign.tastingnotes.languagecode':
    'marketinginformation.marketinginformation.marketingcampaign.tastingnotes.languagecode',
  'marketinginformation.marketinginformation.educationalfocusdescription.value':
    'marketinginformation.marketinginformation.educationalfocusdescription.value',
  'marketinginformation.marketinginformation.educationalfocusdescription.languagecode':
    'marketinginformation.marketinginformation.educationalfocusdescription.languagecode',
  'marketinginformation.marketinginformation.tradeitemmarketingmessage.value':
    'MarketingInformation.MarketingInformation.TradeItemMarketingMessage.Value',
  'marketinginformation.marketinginformation.specialitemcode':
    'MarketingInformation.MarketingInformation.SpecialItemCode',
  'healthwellnesspackagingmarking.healthwellnesspackagingmarking.packagingmarkeddietallergencode':
    'HealthWellnessPackagingMarking.HealthWellnessPackagingMarking.PackagingMarkedDietAllergenCode',
  'packaginginformation.packaging.packagingtypecode':
    'PackagingInformation.Packaging.PackagingTypeCode',
  'packaginginformation.packaging.packagingtypedescription':
    'PackagingInformation.Packaging.PackagingTypeDescription',
  'packaginginformation.packaging.packagingtypedescription.value':
    'packaginginformation.packaging.packagingtypedescription.value',
  'packaginginformation.packaging.packagingtypedescription.languagecode':
    'packaginginformation.packaging.packagingtypedescription.languagecode',
  'packaginginformation.packaging.packagingmaterial.packagingmaterialtypecode':
    'packaginginformation.packaging.packagingmaterial.packagingmaterialtypecode',
  'packaginginformation.packaging.packagingrecyclingprocesstypecode':
    'PackagingInformation.Packaging.PackagingRecyclingProcessTypeCode',
  'packagingmarking.packagingmarking.ispriceonpack':
    'PackagingMarking.PackagingMarking.IsPriceOnPack',
  'packagingmarking.packagingmarking.hasbatchnumber':
    'PackagingMarking.PackagingMarking.HasBatchNumber',
  'packagingmarking.packagingmarking.ispackagingmarkedreturnable':
    'packagingmarking.packagingmarking.ispackagingmarkedreturnable',
  'packagingmarking.packagingmarking.packagingmarkedlabelaccreditationcode':
    'PackagingMarking.PackagingMarking.PackagingMarkedLabelAccreditationCode',
  'dietinformation.dietinformation.diettypeinformation.diettypecode':
    'dietinformation.dietinformation.diettypeinformation.diettypecode',
  'healthrelatedinformation.healthrelatedinformation.cannabiscbdtypecode':
    'HealthRelatedInformation.HealthRelatedInformation.CannabisCBDTypeCode',
  'organismclassification.organismclassification.species':
    'OrganismClassification.OrganismClassification.Species',
  'farmingandprocessinginformation.tradeitemfarmingandprocessing.growingmethodcode':
    'farmingandprocessinginformation.tradeitemfarmingandprocessing.growingmethodcode',

  'foodandbeveragepreparationserving.preparationserving.recipe.value':
    'foodandbeveragepreparationserving.preparationserving.recipe.value',
  'foodandbeveragepreparationserving.preparationserving.recipe.languagecode':
    'foodandbeveragepreparationserving.preparationserving.recipe.languagecode',

  'foodandbeveragepreparationserving.servingquantityinformation.numberofservingperpackage':
    'FoodAndBeveragePreparationServing.ServingQuantityInformation.NumberOfServingPerPackage',

  'foodandbeveragepreparationserving.servingquantityinformation.numberofservingperpackagemeasurementpercisioncode':
    'FoodAndBeveragePreparationServing.ServingQuantityInformation.NumberOfServingPerPackageMeasurementPercisioncode',
  'foodandbeveragepreparationserving.preparationserving.preparationinstructions.value':
    'FoodAndBeveragePreparationServing.PreparationServing.PreparationInstructions.Value',
  'foodandbeveragepreparationserving.servingquantityinformation.numberofservingsperpackagemeasurementprecisioncode':
    'foodandbeveragepreparationserving.servingquantityinformation.numberofservingsperpackagemeasurementprecisioncode',
  'foodandbeveragepreparationserving.servingquantityinformation.numberofservingsperpackage':
    'foodandbeveragepreparationserving.servingquantityinformation.numberofservingsperpackage',

  'packagingsustainability.individualpackagingcomponentlevel.packagingrecycledcontent.packagingrecycledcontentdescription.value':
    'packagingsustainability.individualpackagingcomponentlevel.packagingrecycledcontent.packagingrecycledcontentdescription.value',
  'packagingsustainability.individualpackagingcomponentlevel.packagingrecycledcontent.packagingrecycledcontentdescription.languagecode':
    'packagingsustainability.individualpackagingcomponentlevel.packagingrecycledcontent.packagingrecycledcontentdescription.languagecode',
  'packagingsustainability.individualpackagingcomponentlevel.packagingrecovery.packagingrecoveryratetypecode':
    'PackagingSustainability.IndividualPackagingComponentLevel.PackagingRecovery.PackagingRecoveryRateTypeCode',
  'packagingsustainability.individualpackagingcomponentlevel.packagingrecovery.doespackagingrecoveryratetypecodemeetthestandard':
    'packagingsustainability.individualpackagingcomponentlevel.packagingrecovery.doespackagingrecoveryratetypecodemeetthestandard',
  'packagingsustainability.lifecycleindicators.acidificationpotential.acidificationmeasurement.value':
    'packagingsustainability.lifecycleindicators.acidificationpotential.acidificationmeasurement.value',
  'packagingsustainability.lifecycleindicators.acidificationpotential.acidificationmeasurement.measurementunitcode':
    'packagingsustainability.lifecycleindicators.acidificationpotential.acidificationmeasurement.measurementunitcode',
  'allergeninformation.allergenrelatedinformation.allergen.allergentypecode':
    'allergeninformation.allergenrelatedinformation.allergen.allergentypecode',
  'allergeninformation.allergenrelatedinformation.allergen.levelofcontainmentcode':
    'allergeninformation.allergenrelatedinformation.allergen.levelofcontainmentcode',
  'allergeninformation.allergenrelatedinformation.allergenstatement.value':
    'allergeninformation.allergenrelatedinformation.allergenstatement.value',
  'allergeninformation.allergenrelatedinformation.allergenspecificationname':
    'AllergenRelatedInformation.AllergenSpecificationName',
  'allergeninformation.allergenrelatedinformation.allergenspecificationagency':
    'AllergenRelatedInformation.AllergenSpecificationAgency',
  'allergeninformation.allergenrelatedinformation.allergen.containmentsource':
    'AllergenRelatedInformation.Allergen.ContainmentSource',
  'allergeninformation.allergenRelatedInformation.allergen.allergentypecode':
    'AllergenRelatedInformation.Allergen.AllergenTypeCode',
  'ingredientstatement.value': 'IngredientStatement.Value',
  'alcoholinformation.alcoholinformation.alcoholproof':
    'AlcoholInformation.AlcoholInformation.AlcoholProof',
  'alcoholinformation.alcoholinformation.percentageofalcoholbyvolume':
    'AlcoholInformation.AlcoholInformation.PercentageOfAlcoholByVolume',
  'alcoholinformation.alcoholinformation.beerstylecode':
    'AlcoholInformation.AlcoholInformation.BeerStyleCode',
  'alcoholinformation.alcoholinformation.alcoholbeveragedistilledfromcode':
    'AlcoholInformation.AlcoholInformation.AlcoholBeverageDistilledFromCode',
  '"alcoholinformation.alcoholinformation.primaryaddedflavouringcode':
    '"AlcoholInformation.AlcoholInformation.PrimaryAddedFlavouringCode',
  'alcoholinformation.alcoholinformation.secondaryaddedflavouringcode"':
    'AlcoholInformation.AlcoholInformation.SecondaryAddedFlavouringCode"',
  'alcoholinformation.alcoholinformation.alcoholbeverageage.value':
    'alcoholinformation.alcoholinformation.alcoholbeverageage.value',
  'alcoholinformation.alcoholinformation.alcoholbeverageage.timemeasurementunitcode':
    'alcoholinformation.alcoholinformation.alcoholbeverageage.timemeasurementunitcode',
  'alcoholinformation.alcoholinformation.alcoholicbeveragesubregion':
    'AlcoholInformation.AlcoholInformation.AlcoholicBeverageSubregion',
  'alcoholinformation.alcoholinformation.alcoholbeveragetypecode':
    'AlcoholInformation.AlcoholInformation.AlcoholBeverageTypeCode',
  'alcoholinformation.alcoholinformation.alcoholbeveragecontainer.containertypecode':
    'AlcoholInformation.AlcoholInformation.AlcoholBeverageContainer.ContainerTypeCode',
  'alcoholinformation.alcoholinformation.alcoholbeveragecontainer.containershapecode':
    'AlcoholInformation.AlcoholInformation.AlcoholBeverageContainer.ContainerShapeCode',
  'alcoholinformation.alcoholinformation.alcoholbeveragecontainer.containermaterialcode':
    'AlcoholInformation.AlcoholInformation.AlcoholBeverageContainer.ContainerMaterialCode',
  'alcoholinformation.alcoholinformation.vintage':
    'AlcoholInformation.AlcoholInformation.Vintage',
  'tradeitemdatacarrierandidentification.datacarrier.datacarrierfamilytypecode':
    'TradeItemDataCarrierAndIdentification.DataCarrier.DataCarrierFamilyTypeCode',
  'tradeitemdatacarrierandidentification.datacarrier.datacarriertypecode':
    'TradeItemDataCarrierAndIdentification.DataCarrier.DataCarrierTypeCode',
  'tradeitemdatacarrierandidentification.gs1tradeitemidentificationkey.gs1tradeitemidentificationkeycode':
    'TradeItemDataCarrierAndIdentification.GS1TradeItemIdentificationKey.Gs1TradeItemIdentificationKeyCode',
  'tradeitemdatacarrierandidentification.gs1tradeitemidentificationkey.gs1tradeitemidentificationkeyvalue':
    'TradeItemDataCarrierAndIdentification.GS1TradeItemIdentificationKey.Gs1TradeItemIdentificationKeyValue',
  'placeofitemactivity.placeofproductactivity.countryoforigin.countrycode':
    'placeofitemactivity.placeofproductactivity.countryoforigin.countrycode',
  'placeofitemactivity.placeofproductactivity.countryoforigin.countrysubdivisioncode':
    'placeofitemactivity.placeofproductactivity.countryoforigin.countrysubdivisioncode',
  'placeofitemactivity.importclassification.importclassificationtypecode':
    'PlaceOfItemActivity.ImportClassification.ImportClassificationTypeCode',
  'placeofitemactivity.importclassification.importclassificationvalue':
    'PlaceOfItemActivity.ImportClassification.ImportClassificationValue',
  'placeofitemactivity.placeofproductactivity.countryoforiginstatement.value':
    'placeofitemactivity.placeofproductactivity.countryoforiginstatement.value',
  'placeofitemactivity.placeofproductactivity.countryoforiginstatement.languagecode':
    'placeofitemactivity.placeofproductactivity.countryoforiginstatement.languagecode',
  'deliverypurchasinginformation.deliverypurchasinginformation.orderquantityminimum':
    'DeliveryPurchasingInformation.DeliveryPurchasingInformation.OrderQuantityMinimum',
  'deliverypurchasinginformation.deliverypurchasinginformation.orderingunitofmeasure':
    'DeliveryPurchasingInformation.DeliveryPurchasingInformation.OrderingUnitOfMeasure',
  'deliverypurchasinginformation.deliverypurchasinginformation.distributiondetails.orderingleadtime.value':
    'deliverypurchasinginformation.deliverypurchasinginformation.distributiondetails.orderingleadtime.value',
  'deliverypurchasinginformation.deliverypurchasinginformation.distributiondetails.orderingleadtime.measurementunitcode':
    'deliverypurchasinginformation.deliverypurchasinginformation.distributiondetails.orderingleadtime.measurementunitcode',
  'salesinformation.salesinformation.targetmarketsalesconditions.salesconditiontargetmarketcountry.countrycode':
    'SalesInformation.SalesInformation.TargetMarketSalesConditions.SalesConditionTargetMarketCountry.CountryCode',
  'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.brandname':
    'TradeItemDescription.TradeItemDescriptionInformation.BrandNameInformation.BrandName',
  'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.languagespecificbrandname.value':
    'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.languagespecificbrandname.value',
  'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.languagespecificbrandname.languagecode':
    'tradeitemdescription.tradeitemdescriptioninformation.brandnameinformation.languagespecificbrandname.languagecode',
  'tradeitemdescription.tradeitemdescriptioninformation.tradeitemdescription.value':
    'tradeitemdescription.tradeitemdescriptioninformation.tradeitemdescription.value',
  'tradeitemdescription.tradeitemdescriptioninformation.tradeitemdescription.languagecode':
    'tradeitemdescription.tradeitemdescriptioninformation.tradeitemdescription.languagecode',
  'tradeitemdescription.tradeitemdescriptioninformation.additionaltradeitemdescription.value':
    'tradeitemdescription.tradeitemdescriptioninformation.additionaltradeitemdescription.value',
  'tradeitemdescription.tradeitemdescriptioninformation.descriptionshort.value':
    'tradeitemdescription.tradeitemdescriptioninformation.descriptionshort.value',
  'tradeitemdescription.tradeitemdescriptioninformation.functionalname.value':
    'tradeitemdescription.tradeitemdescriptioninformation.functionalname.value',
  'tradeitemdescription.tradeitemdescriptioninformation.functionalname.languagecode':
    'tradeitemdescription.tradeitemdescriptioninformation.functionalname.languagecode',
  'rivir.productitem.depth/ width/ height and tdcproperties.planogramdepth/planogramwidth/planogramheight':
    'Rivir.productitem.Depth/ Width/ Height and TDCProperties.PlanogramDepth/PlanogramWidth/PlanogramHeight',
  'rivir.productitem.weight and tdcproperties.suppliedweight':
    'Rivir.productitem.Weight and TDCProperties.SuppliedWeight',
  'rivir.productitem.imperialdepth/ imperialwidth/ imperialheight':
    'Rivir.productitem.ImperialDepth/ ImperialWidth/ ImperialHeight',
  'rivir.productitem.imperialweight': 'Rivir.productitem.ImperialWeight',
  'tradeitemtemperatureinformation.tradeitemtemperatureconditiontypecode':
    'TradeItemTemperatureInformation.TradeItemTemperatureConditionTypeCode',
  'allergenrelatedinformation.allergenspecificationname':
    'AllergenRelatedInformation.AllergenSpecificationName',
  'allergenrelatedinformation.allergenspecificationagency':
    'AllergenRelatedInformation.AllergenSpecificationAgency',
  'allergenrelatedinformation.allergen.levelofcontainmentcode':
    'AllergenRelatedInformation.Allergen.LevelOfContainmentCode',
  'allergenrelatedinformation.allergen.containmentsource':
    'AllergenRelatedInformation.Allergen.ContainmentSource',
  'allergenRelatedInformation.allergen.allergentypecode':
    'AllergenRelatedInformation.Allergen.AllergenTypeCode',
  'tradeitemhierarchy.tradeitemhierarchy.quantityoftradeitemsperpalletlayer':
    'TradeItemHierarchy.TradeItemHierarchy.QuantityOfTradeItemsPerPalletLayer',
  'tradeitemhierarchy.tradeitemhierarchy.quantityoflayersperpallet':
    'TradeItemHierarchy.TradeItemHierarchy.QuantityOfLayersPerPallet',
  'tradeitemhandling.tradeitemhandlinginformation.handlinginstructionsdescription.value':
    'TradeItemHandling.TradeItemHandlingInformation.HandlingInstructionsDescription.Value',
};

export const BASIC_FIELD = {
  gtin: 'gtin',
  producttype: 'ProductType',
  brickcode: 'BrickCode',
  brickcodename: 'BrickCodeName',
  ixoneid: 'IXOneId',
  primarysize: 'PrimarySize',
  primarysizeuom: 'PrimarySizeUOM',
  productname: 'ProductName',
  depth: 'Depth',
  height: 'Height',
  upc10: 'UPC10',
  upc11: 'UPC11',
  upc12: 'UPC12',
  upc13: 'UPC13',
  upc8: 'UPC8',
  weight: 'Weight',
  width: 'Width',
  targetmarket: 'TargetMarket',
  packagelevel: 'Packagelevel',
};

export const PRODUCT_SPEC = {
  codedatestamp: 'codeDateStamp',
  codedatetype: 'codeDateType',
  codedateformula: 'codeDateFormula',
  codedateexample: 'codeDateExample',
  gmostatement: 'gmoStatement',
  nongmostatement: 'nonGmoStatement',
  bioengineeredstatement: 'bioengineeredStatement',
  productcontainsbioengineeredingredients:
    'productContainsBioengineeredIngredients',
  rbghbststatement: 'rbghBstStatement',
  californiaprop65warningstatement: 'californiaProp65WarningStatement',
  fairtradestatement: 'fairTradeStatement',
  usdastatement: 'usdaStatement',
  minimumshelflife: 'minimumShelfLife',
  maximumshelflife: 'maximumShelfLife',
  shelflifeafteropening: 'shelfLifeAfterOpening',
  casetemperaturerangehigh: 'caseTemperatureRangeHigh',
  casetemperaturerangelow: 'caseTemperatureRangeLow',
  caseshippingtemperaturehigh: 'caseShippingTemperatureHigh',
  caseshippingtemperaturelow: 'caseShippingTemperatureLow',
  countryoforigin: 'countryOfOrigin',
  targetmarket: 'targetMarket',
  halalyn: 'halalYN',
  kosheryn: 'kosherYN',
  koshercertifier: 'kosherCertifier',
  halalcertifier: 'halalCertifier',
  nongmoyn: 'nonGMOYN',
  nongmocertifier: 'nonGMOCertifier',
  organicyn: 'organicYN',
  organiccertifier: 'organicCertifier',
  isocertifier: 'isoCertifier',
  ifscertifier: 'ifsCertifier',
  brccertifier: 'brcCertifier',
};

export const LABELING = {
  upc12: 'upc12',
  uom: 'uom',
  size: 'size',
  ingredientstatement: 'ingredientStatement',
  storageinstructions: 'storageInstructions',
  handlinginstructions: 'handlingInstructions',
  usage: 'usage',
  cookinginstructions: 'cookingInstructions',
  recipe: 'recipe',
  bioengineeredstatement: 'bioengineeredStatement',
  warningstatement: 'warningStatement',
  recyclabletypes: 'recyclableTypes',
  packagingtype: 'packagingType',
  packagingmaterial: 'packagingMaterial',
  qrcode: 'qrCode',
  halalyn: 'halalYN',
  kosheryn: 'kosherYN',
  koshercertifier: 'kosherCertifier',
  halalcertifier: 'halalCertifier',
  nongmoyn: 'nonGMOYN',
  nongmocertifier: 'nonGMOCertifier',
  organicyn: 'organicYN',
  organiccertifier: 'organicCertifier',
  isocertifier: 'isoCertifier',
  ifscertifier: 'ifsCertifier',
  brccertifier: 'brcCertifier',
};

export const CLAIMS = {
  onepercentfortheplanetclaim: 'onePercentForThePlanetClaim',
  adasealofacceptanceclaim: 'adaSealofAcceptanceClaim',
  beebetterclaim: 'beeBetterClaim',
  biobasedyn: 'bioBasedYN',
  biodegradable: 'biodegradable',
  productisbiodegradable: 'productIsBiodegradable',
  biodynamicyn: 'bioDynamicYN',
  bioengineeredyn: 'bioengineeredYN',
  containsbpa: 'containsBPA',
  bpafreeliner: 'bpaFreeLiner',
  bpafreepackaging: 'bpaFreePackaging',
  carbonfootprintyn: 'carbonFootprintYN',
  cradletocradleyn: 'cradleToCradleYN',
  crueltyfreeyn: 'crueltyFreeYN',
  diabeticfriendlyyn: 'diabeticFriendlyYN',
  dolphinfriendly: 'dolphinFriendly',
  ecofisheryyn: 'ecoFisheryYN',
  fairtradeyn: 'fairTradeYN',
  forlifeyn: 'forLifeYN',
  glyphosateresiduefreeclaim: 'glyphosateResidueFreeClaim',
  gmpyn: 'gmpyn',
  grassfedyn: 'grassFedYN',
  hearthealthyyn: 'heartHealthyYN',
  highpotency: 'highPotency',
  highpressurecertifiedclaim: 'highPressureCertifiedClaim',
  ingredientschina: 'ingredientsChina',
  italcertifiedsealclaim: 'italCertifiedSealClaim',
  italconscioussealclaim: 'italConsciousSealClaim',
  italsacramentsealclaim: 'italSacramentSealClaim',
  ketoyn: 'ketoYN',
  liveandactiveculture: 'liveAndActiveCulture',
  lowglycemicyn: 'lowGlycemicYN',
  organicingredientsmadewith: 'organicIngredientsMadeWith',
  nonirradiatedingredients: 'nonIrradiatedIngredients',
  npayn: 'npayn',
  pacayn: 'pacayn',
  paleoyn: 'paleoYN',
  pasayn: 'pasayn',
  plantbasedderivedclaim: 'plantBasedDerivedClaim',
  rainforestallianceyn: 'rainForestAllianceYN',
  realcertifiedclaim: 'realCertifiedClaim',
  contains: 'contains',
  doesnotcontain: 'doesNotContain',
};

export const PET_FOOD = {
  warnings: 'warnings',
  usageinstructions: 'usageInstructions',
};

export const FRESH_SEAFOOD = {
  safehandlinginstructions: 'safeHandlingInstructions',
};

export const FACT_PANEL = {
  'petnutritionfacts.nutrientformattype':
    'petnutritionfacts.nutrientformattype',
  'petnutritionfacts.measurementpercup': 'petnutritionfacts.measurementpercup',
  'petnutritionfacts.measurementuompercup':
    'petnutritionfacts.measurementuompercup',
  'petnutritionfacts.caloriespercup': 'petnutritionfacts.caloriespercup',
  'petnutritionfacts.protein': 'petnutritionfacts.protein',
  'petnutritionfacts.fat': 'petnutritionfacts.fat',
  'petnutritionfacts.carbohydrate': 'petnutritionfacts.carbohydrate',
  'petnutritionfacts.caloriecontent': 'petnutritionfacts.caloriecontent',
  'petnutritionfacts.enzymesandmicroorganisms':
    'petnutritionfacts.enzymesandmicroorganisms',
  'petnutritionfacts.nutritionaladequacystatement':
    'petnutritionfacts.nutritionaladequacystatement',
  'petnutritionfacts.fdalabelfootnote': 'petnutritionfacts.fdalabelfootnote',
  'petnutritionfacts.nutrients.nutrientname':
    'petnutritionfacts.nutrients.nutrientname',
  'petnutritionfacts.nutrients.limit': 'petnutritionfacts.nutrients.limit',
  'petnutritionfacts.nutrients.guaranteed':
    'petnutritionfacts.nutrients.guaranteed',
  'petnutritionfacts.nutrients.guaranteeduom':
    'petnutritionfacts.nutrients.guaranteeduom',
  'petnutritionfacts.nutrients.quantity':
    'petnutritionfacts.nutrients.quantity',
  'petnutritionfacts.nutrients.quantityuom':
    'petnutritionfacts.nutrients.quantityuom',
  'supplementfacts.id': 'supplementfacts.id',
  'supplementfacts.nutrientformattype': 'supplementfacts.nutrientformattype',
  'supplementfacts.servingsize': 'supplementfacts.servingsize',
  'supplementfacts.servingsizemeasurement':
    'supplementfacts.servingsizemeasurement',
  'supplementfacts.servingsizedescription':
    'supplementfacts.servingsizedescription',
  'supplementfacts.numberofservingspercontainer':
    'supplementfacts.numberofservingspercontainer',
  'supplementfacts.calories': 'supplementfacts.calories',
  'supplementfacts.caloriesfromfat': 'supplementfacts.caloriesfromfat',
  'supplementfacts.fdalabelfootnote': 'supplementfacts.fdalabelfootnote',
  'supplementfacts.otheringredients': 'supplementfacts.otheringredients',
  'supplementfacts.languagecode': 'supplementfacts.languagecode',
  'supplementfacts.servingpercontainerdescriptor':
    'supplementfacts.servingpercontainerdescriptor',
  'supplementfacts.nutrients.nutritionname':
    'supplementfacts.nutrients.nutritionname',
  'supplementfacts.nutrients.italicnutrient':
    'supplementfacts.nutrients.italicnutrient',
  'supplementfacts.nutrients.descriptor':
    'supplementfacts.nutrients.descriptor',
  'supplementfacts.nutrients.italicdescriptor':
    'supplementfacts.nutrients.italicdescriptor',
  'supplementfacts.nutrients.ingredientspartof':
    'supplementfacts.nutrients.ingredientspartof',
  'supplementfacts.nutrients.quantity': 'supplementfacts.nutrients.quantity',
  'supplementfacts.nutrients.quantityuom':
    'supplementfacts.nutrients.quantityuom',
  'supplementfacts.nutrients.quantitydescription':
    'supplementfacts.nutrients.quantitydescription',
  'supplementfacts.nutrients.dailyvalue':
    'supplementfacts.nutrients.dailyvalue',
  'supplementfacts.nutrients.dailysymbol':
    'supplementfacts.nutrients.dailysymbol',
  'supplementfacts.dietaryingredients.ingredientname':
    'supplementfacts.dietaryingredients.ingredientname',
  'supplementfacts.dietaryingredients.italicdietary':
    'supplementfacts.dietaryingredients.italicdietary',
  'supplementfacts.dietaryingredients.descriptor':
    'supplementfacts.dietaryingredients.descriptor',
  'supplementfacts.dietaryingredients.italicdescriptor':
    'supplementfacts.dietaryingredients.italicdescriptor',
  'supplementfacts.dietaryingredients.ingredientspartof':
    'supplementfacts.dietaryingredients.ingredientspartof',
  'supplementfacts.dietaryingredients.quantity':
    'supplementfacts.dietaryingredients.quantity',
  'supplementfacts.dietaryingredients.quantityuom':
    'supplementfacts.dietaryingredients.quantityuom',
  'supplementfacts.dietaryingredients.quantitydescription':
    'supplementfacts.dietaryingredients.quantitydescription',
  'supplementfacts.dietaryingredients.dailysymbol':
    'supplementfacts.dietaryingredients.dailysymbol',
  'nutritionfacts.id': 'nutritionfacts.id',
  'nutritionfacts.nutrientformattype': 'nutritionfacts.nutrientformattype',
  'nutritionfacts.numberofservingsperpackage':
    'nutritionfacts.numberofservingsperpackage',
  'nutritionfacts.servingpercontainerdescriptor':
    'nutritionfacts.servingpercontainerdescriptor',
  'nutritionfacts.servingsize': 'nutritionfacts.servingsize',
  'nutritionfacts.servingsizemeasurement':
    'nutritionfacts.servingsizemeasurement',
  'nutritionfacts.servingsizedescription':
    'nutritionfacts.servingsizedescription',
  'nutritionfacts.calories': 'nutritionfacts.calories',
  'nutritionfacts.ingredientnotice': 'nutritionfacts.ingredientnotice',
  'nutritionfacts.fdalabelfootnote': 'nutritionfacts.fdalabelfootnote',
  'nutritionfacts.nutrientbasisquantitytypecode':
    'nutritionfacts.nutrientbasisquantitytypecode',
  'nutritionfacts.nutrientbasisvalue': 'nutritionfacts.nutrientbasisvalue',
  'nutritionfacts.unitofmeasure': 'nutritionfacts.unitofmeasure',
  'nutritionfacts.languagecode': 'nutritionfacts.languagecode',
  'nutritionfacts.nutritionfactproperties.id':
    'nutritionfacts.nutritionfactproperties.id',
  'nutritionfacts.nutritionfactproperties.nutritiontypecode':
    'nutritionfacts.nutritionfactproperties.nutritiontypecode',
  'nutritionfacts.nutritionfactproperties.measurementprecisioncode':
    'nutritionfacts.nutritionfactproperties.measurementprecisioncode',
  'nutritionfacts.nutritionfactproperties.nutritionname':
    'nutritionfacts.nutritionfactproperties.nutritionname',
  'nutritionfacts.nutritionfactproperties.dailyvalueintakepercent':
    'nutritionfacts.nutritionfactproperties.dailyvalueintakepercent',
  'nutritionfacts.nutritionfactproperties.quantity':
    'nutritionfacts.nutritionfactproperties.quantity',
  'nutritionfacts.nutritionfactproperties.quantityuom':
    'nutritionfacts.nutritionfactproperties.quantityuom',
  'nutritionfacts.nutritionfactproperties.nutritionindicator':
    'nutritionfacts.nutritionfactproperties.nutritionindicator',
};
