import React from 'react';
import { useDispatch } from 'react-redux';

import { Menu, Dropdown } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import RibbonButton from 'common/components/button/RibbonButton';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { useFilterGrid } from 'hooks/useFilterGrid';
import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';

import { FILTER_TYPE_MEMBERS } from 'static/Constants';
import Messages from 'i18n/messages/home';

const FilterMemberSection = () => {
  const dispatch = useDispatch();

  const { gridFilters: filterMembers, updateGridFilter } = useFilterGrid({
    entityType: 'member',
  });

  const { label, icon, toggle } = useDisplayFilterButton({
    filterOptions: FILTER_TYPE_MEMBERS,
    filters: filterMembers,
  });

  const handleFilterMembers = (filter) => {
    // isMemberNew && dispatch(globalActions.toggleMemberNew(false));
    dispatch(actionsGridView.resetPageCurrent());
    if (filterMembers.indexOf(filter) > -1) {
      updateGridFilter(filterMembers.filter((val) => val !== filter));
    } else {
      let newFilterMembers = [...filterMembers];
      newFilterMembers.push(filter);
      updateGridFilter(newFilterMembers);
    }
  };

  const packageMenu = (
    <Menu>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<WarningOutlined />}
          label={Messages.unmatchedMembers}
          onClick={() => handleFilterMembers('unmatched')}
          className={classNames({
            'filter-section__filter-button--active':
              filterMembers.indexOf('unmatched') > -1,
          })}
          toggle={filterMembers.indexOf('unmatched') > -1}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={packageMenu} arrow>
      <RibbonButton icon={icon} label={label} toggle={toggle} />
    </Dropdown>
  );
};

export default FilterMemberSection;
