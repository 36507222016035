import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getQueryGridView = (params) => {
  return api.sendGet(endpoints.QUERY_GRID_VIEW, { params: params });
};

export const queryDetailGrid = (params) => {
  return api.sendPost(endpoints.QUERY_DETAIL_GRID, params);
};
export const queryDetailGridDistinct = (params) => {
  return api.sendPost(endpoints.QUERY_DETAIL_GRID_DISTINCT, params);
};
export const saveQuery = (params) => {
  return api.sendPost(endpoints.SAVE_QUERY, params);
};
export const editQuery = (params) => {
  return api.sendPost(endpoints.EDIT_QUERY, params);
};
export const deleteQuery = (params) => {
  return api.sendPost(endpoints.DELETE_QUERY, params);
};

export const getQueryDetailPane = (params) => {
  return api.sendGet(endpoints.QUERY_DETAIL_PANE, { params: params });
};

export const getFavoriteQueryGridView = (params) => {
  return api.sendPost(endpoints.FAVORITE_QUERY_GRID_VIEW, params);
};
export const getFavoriteQueryGridViewDistinct = (params) => {
  return api.sendPost(endpoints.FAVORITE_QUERY_GRID_VIEW_DISTINCT, params);
};

export const getAllMemberRoles = () => {
  return api.sendGet(endpoints.GET_ALL_MEMBER_ROLES);
};
