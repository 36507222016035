import React, { createContext, useContext } from 'react';

export const EmailEditorContext = createContext();

export const useEmailEditorContext = () => useContext(EmailEditorContext);

export const EmailEditorProvider = (props) => {
  const {
    dynamicContent,
    setDynamicContent,
    productData,
    invalidTag,
    setInvalidTag,
    senderInfo,
    children,
    template,
    visible,
  } = props;

  return (
    <EmailEditorContext.Provider
      value={{
        dynamicContent,
        setDynamicContent,
        invalidTag,
        setInvalidTag,
        productData,
        senderInfo,
        template,
        visible,
      }}
    >
      {children}
    </EmailEditorContext.Provider>
  );
};
