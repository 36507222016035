import React, { useState, useEffect, useMemo } from 'react';

import classnames from 'classnames';

import { useSelector } from 'react-redux';
import { Typography, Tooltip, Checkbox, Col, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { ColumnContainer, CollapseView, TextInfo } from './ShareComponents';
import FormEditCustomProductProperties from './FormEditCustomProductProperties';

import userSelectors from 'redux/user/selectors';
import {
  useGetProductCustomProperties,
  useGetMemberProductCustomProperties,
} from './hooks';

import { useCheckAllowEditProducts, useGetProductFullView } from 'hooks';

const keyPanel = 'Private Properties';

const ProductCustomProperties = React.memo((props) => {
  const {
    productId,
    ownerId,
    hasPermissionEditProperties,
    queryLeftProperties = {},
  } = props;

  const { expandAll, onlyShowValues } = queryLeftProperties;

  const [activeKeyPanels, setActivePanels] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  const { productFull } = useGetProductFullView({ productId });
  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  useEffect(() => {
    if (expandAll) {
      setActivePanels('Private Properties');
    } else {
      setActivePanels([]);
    }
  }, [expandAll]);

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const { data, refetchProductCustomPropertiesData } =
    useGetProductCustomProperties(productId);
  const {
    data: memberProductCustomPropertiesData,
    error: getProductCustomPropertiesErrorMessage,
  } = useGetMemberProductCustomProperties(ownerId);

  const productCustomProperties = data?.productCustomFieldsData ?? [];
  const memberProductCustomProperties =
    memberProductCustomPropertiesData?.productCustomFields;

  const isDisplayPrivateProperties = useMemo(() => {
    if (onlyShowValues) {
      return productCustomProperties?.length > 0;
    }
    return !!productCustomProperties;
  }, [onlyShowValues, productCustomProperties?.length]);

  const handleCollapseView = (value) => {
    setActivePanels(value);
  };

  const turnOffModal = () => setVisibleModal(false);

  const renderIconEdit = () => {
    const isShowEditBtn =
      checkAllowEditProductFull(productFull) &&
      hasPermissionEditProperties &&
      activeKeyPanels.includes(keyPanel);

    return (
      <>
        {isShowEditBtn && (
          <Tooltip placement='left' title={`Edit Custom Properties`}>
            <EditOutlined
              className='product-detail-view__edit-icon'
              onClick={(event) => {
                event.stopPropagation();
                setVisibleModal(true);
              }}
            />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      {isDisplayPrivateProperties && (
        <div className='product-detail-view__private-properties'>
          <CollapseView
            activeKey={activeKeyPanels}
            collapsible='header'
            headerPanel={
              <Typography.Title
                level={5}
                className={classnames('product-detail-view__list-info-title')}
              >
                Custom Properties
              </Typography.Title>
            }
            keyPanel={keyPanel}
            expandIconPosition='left'
            extraPanel={() => renderIconEdit()}
            onChange={handleCollapseView}
          >
            <ViewInfoCustomProperties
              productCustomProperties={productCustomProperties}
              memberProductCustomProperties={memberProductCustomProperties}
            />
          </CollapseView>
        </div>
      )}

      <FormEditCustomProductProperties
        productId={productId}
        memberId={userInfo?.member?.id}
        visibleModal={visibleModal}
        turnOffModal={turnOffModal}
        memberProductCustomProperties={memberProductCustomProperties}
        productCustomProperties={productCustomProperties}
        refetchProductCustomPropertiesData={refetchProductCustomPropertiesData}
        error={getProductCustomPropertiesErrorMessage}
      />
    </>
  );
});

const ViewInfoCustomProperties = ({
  memberProductCustomProperties,
  productCustomProperties,
}) => {
  const getDisplayName = (fieldName) => {
    const property = memberProductCustomProperties?.find(
      (propertyItem) => propertyItem.fieldName === fieldName
    );
    return property?.displayName || fieldName;
  };

  const renderValue = (property) => {
    const propertyData = memberProductCustomProperties?.find(
      (propertyItem) => propertyItem.fieldName === property.fieldName
    );

    let value = property?.value;

    if (!value || !propertyData) return null;

    const isBoolean =
      propertyData?.fieldType === 'boolean' ||
      propertyData?.fieldType === 'bool';
    const booleanValue = isBoolean && value?.toString()?.toLowerCase();

    if (isBoolean) {
      return (
        <ColumnContainer style={{ textAlign: 'left', paddingLeft: 8 }}>
          <Checkbox checked={booleanValue} />
        </ColumnContainer>
      );
    }

    if (propertyData?.fieldType === 'number') {
      value = parseFloat(value);
    }

    return (
      <TextInfo ellipsis style={{ whiteSpace: 'pre-wrap' }}>
        {value}
      </TextInfo>
    );
  };

  return (
    <>
      {productCustomProperties?.length > 0
        ? productCustomProperties?.map((property) => {
            const shouldDisplaying =
              property?.fieldType !== 'bool' && property?.value;
            const displayName = getDisplayName(property.fieldName);

            return shouldDisplaying ? (
              <div
                key={property.fieldName}
                className='product-detail-view__row-view-product'
              >
                <Row>
                  <Col
                    style={{
                      textAlign: 'right',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    xs={12}
                    className='product-detail__text-info--label'
                  >
                    <Tooltip title={displayName}>
                      <TextInfo
                        strong
                        ellipsis
                        className={'product-detail__text-info--label'}
                      >
                        {displayName}
                      </TextInfo>
                    </Tooltip>
                    :
                  </Col>
                  <Col style={{ textAlign: 'left' }} xs={12}>
                    {renderValue(property)}
                  </Col>
                </Row>
              </div>
            ) : null;
          })
        : null}
    </>
  );
};

export default ProductCustomProperties;
