import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col, Typography } from 'antd';
import ChangePassword from './ChangePassword';
import Multifactor from './Multifactor';

import userSelectors from 'redux/user/selectors';

import './UserSecurityTab.less';

const { Text } = Typography;

export const SecurityItemContainer = (props) => {
  const { children, title } = props;

  return (
    <div className='user-security-tab__item'>
      <Row>
        <Col xs={24} className='user-security-tab__item-title'>
          <Text strong>{title && title}</Text>
        </Col>
      </Row>
      <Row align='middle'>
        <Col flex='400px'>{children?.[0] && children?.[0]}</Col>
        <Col flex='1' />
        <Col flex='250px'>{children?.[1] && children?.[1]}</Col>
        <Col flex='1' />
        <Col flex='200px' className='user-security-tab__item-control'>
          {children?.[2] && children?.[2]}
        </Col>
      </Row>
    </div>
  );
};

const UserSecurityTab = (props) => {
  const { userProfile = {}, editable } = props;
  const userInfo = useSelector(userSelectors.makeSelectUserInfo()) || {};
  const { passwordExpiration, requireMfa, mfaPhoneNumber } = userProfile || {};

  const isAllowToChangePassword = editable && userInfo?.id === userProfile?.id;
  const isAllowToChangeMfaPhoneNumber =
    editable && userInfo?.id === userProfile?.id && requireMfa;

  return (
    <div className='user-security-tab__wrapper'>
      <div className='user-security-tab__fluid'>
        <SecurityItemContainer title='Multi-factor Authentication'>
          <Multifactor.Label mfaPhoneNumber={mfaPhoneNumber} />
          <Multifactor.Status requireMfa={requireMfa} />
          <Multifactor.Control
            isAllowToChangeMfaPhoneNumber={isAllowToChangeMfaPhoneNumber}
          />
        </SecurityItemContainer>
        <SecurityItemContainer title='Change Password'>
          <ChangePassword.Label passwordExpiration={passwordExpiration} />
          <ChangePassword.Status passwordExpiration={passwordExpiration} />
          <ChangePassword.Control
            isAllowToChangePassword={isAllowToChangePassword}
          />
        </SecurityItemContainer>
      </div>
    </div>
  );
};

export default UserSecurityTab;
