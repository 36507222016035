import { useEffect } from 'react';

import { useGetProductEnums } from 'hooks';

import {
  getAssetPropertySchema,
  getMemberPropertySchema,
  getProductPropertySchema,
} from 'utils/minimumRequirementUtils';

export const useGetMinimumRequirementFieldSchema = (props) => {
  const { propertiesRequired, setPropertiesRequired, entityType } = props;

  const { productEnums } = useGetProductEnums();

  const getProductSchema = () => {
    if (entityType !== 'Product') return;
    if (!hasError) return;

    setPropertiesRequired((prevState) => {
      return prevState.map((requirementPropertyItem) => {
        const propertySchema = getProductPropertySchema(
          requirementPropertyItem,
          productEnums
        );

        return { ...requirementPropertyItem, inputSchema: propertySchema };
      });
    });
  };

  const getAssetSchema = () => {
    if (entityType !== 'Asset') return;

    setPropertiesRequired((prevState) => {
      return prevState.map((requirementPropertyItem) => {
        const propertySchema = getAssetPropertySchema(requirementPropertyItem);

        return { ...requirementPropertyItem, inputSchema: propertySchema };
      });
    });
  };

  const getMemberSchema = () => {
    if (entityType !== 'Member') return;

    setPropertiesRequired((prevState) => {
      return prevState.map((requirementPropertyItem) => {
        const propertySchema = getMemberPropertySchema(
          requirementPropertyItem,
          productEnums
        );

        return { ...requirementPropertyItem, inputSchema: propertySchema };
      });
    });
  };

  const hasError = propertiesRequired.some(
    (propertyItem) => propertyItem.checkStatus === 'error'
  );

  useEffect(() => {
    getProductSchema();
    getAssetSchema();
    getMemberSchema();
  }, [hasError]);
};
