import React, { useEffect, useState, useRef } from 'react';

import { Typography, Button, Spin, Checkbox } from 'antd';
import { CountdownTrack } from 'common/components';
import AntdOtp from 'common/components/antd-otp';

import reactCSS from 'reactcss';

import { mapOtp, resendTimeFormatter } from '../../utils/utils';
import { useOtpCheck } from '../../hooks/useOtpCheck';
import { OTP_ACTION, OTP_VIEW_CUSTOM_CONFIG } from '../../constants';

import './OtpCheck.less';

const { Text, Link, Paragraph } = Typography;

const OtpCheck = (props) => {
  const {
    otp,
    setOtp,
    onResendOtp,
    step,
    mfaLoginInfo,
    changeMfaPhoneSession,
    onClose,
    allowResendHook,
    timeAllowResendHook,
    otpCheckError,
    setOtpCheckError,
    setIsSessionExpired,
    otpAction = OTP_ACTION.verfiyOnly,
    otpType,
    addNew = false,
    mfaSession,
  } = props;

  const refOtpInput = useRef();
  const [dontChallengeMe, setDontChallengeMe] = useState(false);

  const { loginSession } = mfaLoginInfo || {};

  const [timeAllowResend] = timeAllowResendHook;

  const [isAllowResend, setIsAllowResend, refIsAllowResend] = allowResendHook;

  const onClickResendOtp = () => {
    if (!isAllowResend) return;
    onResendOtp();
  };

  const onChangeChallengeCheckbox = (e) => {
    setDontChallengeMe(e?.target?.checked);
  };

  const getRemainingTime = (remainingTime) => {
    if ([0, null].includes(remainingTime)) {
      setIsAllowResend(true);
    } else {
      if (refIsAllowResend.current === true) {
        setIsAllowResend(false);
      }
    }
  };

  const handleFocusOtpInput = (index) => {
    refOtpInput.current && refOtpInput.current.focusInput(index);
  };

  const {
    onVerifyOtpForNewMfaPhoneUserProfile,
    onVerifyOtpForLoginAllowAddMfaPhone,
    onVerifyOtpForLogin,
    onVerifyOtpForUnlockAccount,
    isLoading,
  } = useOtpCheck({
    setOtpCheckError,
    onClose,
    changeMfaPhoneSession,
    otp,
    setOtp,
    setIsSessionExpired,
    loginSession,
    mfaSession,
    dontChallengeMe,
    mfaLoginInfo,
    handleFocusOtpInput,
  });

  const onVerify = (_otp) => {
    if (otpAction === OTP_ACTION.changeMfaPhone) {
      onVerifyOtpForNewMfaPhoneUserProfile();
    } else if (otpAction === OTP_ACTION.login) {
      switch (addNew) {
        case true:
          onVerifyOtpForLoginAllowAddMfaPhone(_otp);
          break;
        case false:
          onVerifyOtpForLogin(_otp);
          break;
        default:
          break;
      }
    } else if (otpAction === OTP_ACTION.unlockAccount) {
      onVerifyOtpForUnlockAccount(_otp);
    }
  };

  const handleChangeOtp = (value) => {
    setOtp(value);
    const stringOtp = mapOtp(value);

    if (stringOtp?.length === 7) {
      if ([OTP_ACTION.login, OTP_ACTION.unlockAccount].includes(otpAction)) {
        onVerify(stringOtp);
      }
    }
  };

  useEffect(() => {
    if (step === 0) {
      setOtpCheckError('');
    } else if (step === 1) {
      if (refOtpInput.current) {
        refOtpInput.current.focusInput(0);
      }
    }
  }, [step, otpAction, setOtpCheckError]);

  const styles = reactCSS({
    default: {
      otpConfainerStyle: {
        margin: '10px 0',
      },
      resendLink: {
        cursor: isAllowResend ? 'pointer' : 'not-allowed',
        color: isAllowResend ? '' : 'inherit',
      },
      dontChallengeMe: {
        paragraph: {
          margin: '10px 0px 0px 0px',
        },
        checkbox: {
          color: '#238dd9',
        },
      },
      error: {
        minHeight: 18,
      },
    },
  });

  const otpCheckViewConfig = OTP_VIEW_CUSTOM_CONFIG?.[otpType]?.otpCheck;

  return (
    <Spin spinning={isLoading}>
      <div className='otp-check'>
        <Text className='otp-check__title'>{otpCheckViewConfig?.title}</Text>
        <Text className='otp-check__des'>{otpCheckViewConfig?.hint}</Text>

        <AntdOtp
          ref={refOtpInput}
          onChange={handleChangeOtp}
          otp={otp}
          length={7}
          inputClassName='otp-check__input'
          inputType='numeric'
        />
        {otpAction === OTP_ACTION.login ? (
          <Paragraph strong style={styles.dontChallengeMe.paragraph}>
            <Checkbox
              onChange={onChangeChallengeCheckbox}
              style={styles.dontChallengeMe.checkbox}
            >
              Don't Challenge Me for 24 hours
            </Checkbox>
          </Paragraph>
        ) : null}

        <div className='otp-check__hint'>
          <Text>Didn't receive the code?</Text>
          <Link
            target='_blank'
            onClick={onClickResendOtp}
            style={styles.resendLink}
          >
            &nbsp; Re-send OTP {!isAllowResend && 'in'}
          </Link>
          <CountdownTrack
            wrapperClass='otp-check__hint-count-down'
            expirationTime={timeAllowResend}
            getRemainingTime={getRemainingTime}
            totalTime={60}
            timeFormatter={resendTimeFormatter}
            style={{ display: isAllowResend ? 'none' : '' }}
          />
        </div>
        {otpAction === OTP_ACTION.changeMfaPhone && (
          <Button
            className='otp-check__btn-verify'
            type='primary'
            onClick={onVerify}
          >
            Verify OTP and save new mfa phone number
          </Button>
        )}
        <div style={styles.error}>
          {otpCheckError && <Text type='danger'>{otpCheckError}</Text>}
        </div>
      </div>
    </Spin>
  );
};

export default OtpCheck;
