import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { DownloadOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const ExportQueryControl = (props) => {
  const { onClick, disabled, loading } = props;

  const onClickBtn = () => {
    onClick && onClick();
  };

  return (
    <RibbonButton
      loading={loading}
      icon={<DownloadOutlined />}
      label={Messages.exportQuery}
      onClick={onClickBtn}
      disabled={disabled}
    />
  );
};

ExportQueryControl.propTypes = {
  confirmExportQuery: PropTypes.func,
};

export default ExportQueryControl;
