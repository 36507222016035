import { useRef } from 'react';

export const useMdInstance = () => {
  const itemsRef = useRef();
  const mdEditor = {
    __INTERNAL__: {
      itemRef: (node) => {
        if (node) {
          itemsRef.current = node;
        } else {
          itemsRef.current = undefined;
        }
      },
    },
    editor: itemsRef.current?.CodeMirror?.editor,
  };

  return [mdEditor];
};
