import React, { useState, useEffect } from "react";

import { Row, Col, Typography } from "antd";

import AvatarTagItem from "./components/AvatarTagItem";
import InputAutoComplete from "./components/InputAutoComplete";

import { TweenOneGroup } from "rc-tween-one";

import "./styles.less";

const { Text } = Typography;

const link =
  "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";

const mock = [
  {
    name: "Duy Nguyen",
    avatar: link,
    email: "nnqduy.spkt@gmail.com",
  },
  {
    name: "Duy Nguyen 1",
    avatar: link,
    email: "nnqduy.spkt@gmail.com",
  },
  {
    name: "Duy Nguyen 2",
    avatar: link,
    email: "nnqduy.spkt@gmail.com",
  },
  {
    name: "Duy Nguyen 3",
    avatar: link,
    email: "nnqduy.spkt@gmail.com",
  },
  {
    name: "Duy Nguyen 4",
    avatar: link,
    email: "nnqduy.spkt@gmail.com",
  },
  {
    name: "Duy Nguyen 5",
    avatar: link,
    email: "nnqduy.spkt@gmail.com",
  },
];

const KEY_CODE = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
};

const AvatarTagAutocomplete = (props) => {
  const { value, onChange } = props;

  const [tags, setTags] = useState([]);
  const [hint, setHint] = useState(mock);
  const [searchText, setSearchText] = useState("");
  const [selectedHint, setSelectedHint] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [randomNo, setRandomNo] = useState(0);

  //todo - mock only data
  useEffect(() => {
    const max = 5;
    const min = 3;
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    setHint(mock.slice(0, random));
    setSelectedHint(0);
  }, [searchText]);

  const handleMouseDown = (event) => {
    const keyCode = event?.keyCode;
    if (keyCode === KEY_CODE.DOWN) {
      if (selectedHint >= hint?.length - 1) return;
      setSelectedHint((prev) => prev + 1);
      return;
    }
    if (keyCode === KEY_CODE.UP) {
      if (selectedHint <= 0) return;
      setSelectedHint((prev) => prev - 1);
      return;
    }

    if (keyCode === KEY_CODE.ENTER) {
      setTags((prevTags) => [...prevTags, hint[selectedHint]]);
      setSelectedHint(0);
      setSearchText("");
    }
  };

  const handleClickHintItem = (selected) => {
    setTags((prevTags) => [...prevTags, hint[selected]]);
    setSelectedHint(0);
    setSearchText("");
  };

  const handleRemoveAvatarTag = (selected) => {
    setTags((prevTags) => {
      const nextTags = [...prevTags];
      nextTags.splice(selected, 1);
      return nextTags;
    });
  };

  const TagListRender = tags.map((itemData, idx) => {
    return (
      <AvatarTagItem
        itemDetail={itemData}
        handleRemoveAvatarTag={handleRemoveAvatarTag}
        idx={idx}
      />
    );
  });

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setTags(value);
    }
  }, [value]);

  useEffect(() => {
    if (tags) {
      onChange && onChange(tags);
    }
  }, [tags]);

  return (
    <Row className="avatar-tag-auto-complete">
      <Col xs={24}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: "from",
            duration: 100,
            onComplete: (e) => {
              e.target.style = "";
            },
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
          style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
        >
          {TagListRender}
          <InputAutoComplete
            onKeyDown={handleMouseDown}
            hintList={hint}
            selectedHint={selectedHint}
            setSelectedHint={setSelectedHint}
            setSearchText={setSearchText}
            searchText={searchText}
            isLoading={isLoading}
            handleClickHintItem={handleClickHintItem}
            handleRemoveAvatarTag={handleRemoveAvatarTag}
          />
        </TweenOneGroup>
      </Col>
    </Row>
  );
};

export default AvatarTagAutocomplete;
