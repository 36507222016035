import BackgroundSrc from 'assets/landing-page/background-test.webp';
import { COMPONENT_TYPE } from './index';
import marketing_1 from 'assets/landing-page/marketing_1.png';
import marketing_2 from 'assets/landing-page/marketing_2.png';

const mock_page_data = {
  components: [
    'transition-page_background',
    'transition-page_100',
    'transition-page_101',
    'transition-page_102',
    'transition-page_103',
    'transition-page_104',
  ],
  componentDetails: {
    'transition-page_background': {
      src: marketing_2,
      top: 0,
      left: 10,
      scale: 1,
      type: 'background',
    },
    'transition-page_100': {
      textColor: '#f6f6f6',
      textSize: 12,
      textBorderWidth: 0,
      textBorderColor: '',
      textShadow: null,
      textBackground: null,
      textRadius: 0,
      textPadding: 0,
      textWeight: 600,
      textFont: null,
      textTop: -340,
      textLeft: -505,
      text: 'RIVIR FOR WORLD DATE MANAGEMENT',
      type: 'text',
    },
    'transition-page_101': {
      textColor: 'white',
      textSize: 20,
      textBorderWidth: 0,
      textBorderColor: '',
      textShadow: null,
      textBackground: null,
      textRadius: 0,
      textPadding: 0,
      textWeight: 400,
      textFont: null,
      textTop: 50,
      textLeft: 35,
      text: 'GS1 GDSN simplifies data exhanges',
      type: 'text',
    },
    'transition-page_103': {
      src: marketing_1,
      top: 12,
      left: 12,
      width: 250,
      radius: 20,
      borderWidth: 0,
      borderColor: '',
      type: 'image',
    },
    'transition-page_104': {
      src: 'https://www.youtube.com/watch?v=K-sOQJHytxk&ab_channel=GS1US',
      top: 60,
      left: 39.5,
      width: 350,
      radius: 10,
      borderWidth: 0,
      borderColor: '',
      type: COMPONENT_TYPE.YOUTUBE,
    },
  },
};

export { mock_page_data };
