import React, { useState } from 'react';

import { LayoutOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';
import PreviewRequirementsModal from 'common/components/preview-requirements/PreviewRequirementsModal';

import messages from 'i18n/messages/home';

const MinimumRequirementsValidate = (props) => {
  const { size = 'small', entity, disable } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const onClickButton = () => {
    openMinimumRequirementModal();
  };

  const openMinimumRequirementModal = () => {
    setModalVisible(true);
  };

  const disabled = !entity || disable;
  return (
    <>
      <RibbonButton
        size={size}
        className='button-item-dropdown'
        icon={<LayoutOutlined />}
        label={messages.previewRequirements}
        onClick={onClickButton}
        disabled={disabled}
      />
      {modalVisible && (
        <PreviewRequirementsModal
          entity={entity}
          entityType={entity.type}
          visible={modalVisible}
          setVisible={setModalVisible}
        />
      )}
    </>
  );
};

export default MinimumRequirementsValidate;
