import { useReducer, useCallback } from 'react';

import { getNewSetParameters } from './utils';

const initialStatementState = {
  compareFieldPath: '',
  displayCompareFieldPath: '',
  compareFieldPackageLevel: 'Unit',

  compareToFieldPath: '',
  displayCompareToFieldPath: '',
  compareToFieldPackageLevel: 'Unit',

  compareToOtherFieldPath: '',
  displayCompareToOtherFieldPath: '',
  compareToOtherFieldPackageLevel: 'Unit',

  condition: 'Equal',
  compareType: 'Value',
  statementType: 'If',
  valueFilter: 'all',
  nameRenderSourceField: null,
  setType: 'StringConcate',
  setParameters: [],
  indexParam: null,
  dataType: null,

  compareToValue: null,
  compareToOtherValue: null,
};

const statementActions = {
  UPDATE_PACKAGE_LEVEL: 'UPDATE_PACKAGE_LEVEL',
  UPDATE_COMPARE_FIELD_PATH: 'UPDATE_COMPARE_FIELD_PATH',

  UPDATE_TO_FIELD_PACKAGE_LEVEL: 'UPDATE_TO_FIELD_PACKAGE_LEVEL',
  UPDATE_COMPARE_TO_FIELD_PATH: 'UPDATE_COMPARE_TO_FIELD_PATH',

  UPDATE_TO_OTHER_FIELD_PACKAGE_LEVEL: 'UPDATE_TO_OTHER_FIELD_PACKAGE_LEVEL',
  UPDATE_COMPARE_TO_OTHER_FIELD_PATH: 'UPDATE_COMPARE_TO_OTHER_FIELD_PATH',

  UPDATE_CONDITION: 'UPDATE_CONDITION',
  COMPARE_TYPE: 'COMPARE_TYPE',
  SAVE_NAME_RENDER_SOURCE_FIELD: 'SAVE_NAME_RENDER_SOURCE_FIELD',
  CLEAR_NAME_RENDER_SOURCE_FIELD: 'CLEAR_NAME_RENDER_SOURCE_FIELD',
  SET_TYPE: 'SET_TYPE',

  SAVE_COMPARE_TO_VALUE: 'SAVE_COMPARE_TO_VALUE',
  SAVE_COMPARE_TO_OTHER_VALUE: 'SAVE_COMPARE_TO_OTHER_VALUE',

  ADD_VALUE_PARAMETER: 'ADD_VALUE_PARAMETER',
  ADD_OTHER_FIELD_PARAMETER: 'ADD_OTHER_FIELD_PARAMETER',
  CHANGE_TYPE_VALUE_PARAMETER: 'CHANGE_TYPE_VALUE_PARAMETER',
  DELETE_PARAMETER: 'DELETE_PARAMETER',
  RESET_PARAMETER: 'RESET_PARAMETER',

  CHANGE_PROPERTIES_OF_TYPE_VALUE: 'CHANGE_PROPERTIES_OF_TYPE_VALUE',
  CHANGE_PROPERTY_PACKAGE_LEVEL_PARAM: 'CHANGE_PROPERTY_PACKAGE_LEVEL_PARAM',
  CHANGE_PROPERTY_FIELD_PARAM: 'CHANGE_PROPERTY_FIELD_PARAM',
  SHOW_SOURCE_FIELD_PARAMETER: 'SHOW_SOURCE_FIELD_PARAMETER',
  RESET_STATE: 'RESET_STATE',

  SAVE_STATEMENT_STATE: 'SAVE_STATEMENT_STATE',
  HANDLE_SET_STEPS: 'HANDLE_SET_STEPS',
  CHANGE_STATEMENT_TYPE: 'CHANGE_STATEMENT_TYPE',
};

const statementReducer = (state, action) => {
  switch (action.type) {
    // Compare Field
    case statementActions.UPDATE_PACKAGE_LEVEL: {
      if (state.displayCompareFieldPath) {
        const { moduleDisplayName, displayName } = state.compareFieldPath;
        return {
          ...state,
          compareFieldPackageLevel: action.packageLevel,
          displayCompareFieldPath: `[${action.packageLevel}].${moduleDisplayName}.${displayName}`,
        };
      } else {
        return {
          ...state,
          compareFieldPackageLevel: action.packageLevel,
        };
      }
    }
    case statementActions.UPDATE_COMPARE_FIELD_PATH: {
      const {
        moduleDisplayName,
        moduleName,
        displayName,
        dataType,
        fieldName,
      } = action.compareFieldPath;
      return {
        ...state,
        compareFieldPath:
          moduleName !== 'NoHeader'
            ? `${moduleName}.${fieldName}`
            : `${fieldName}`,
        dataType,
        condition: 'Equal',
        displayCompareFieldPath: `[${state.compareFieldPackageLevel}].${moduleDisplayName}.${displayName}`,

        // Reset to default
        compareToValue: null,
        compareToOtherValue: null,

        compareToFieldPath: '',
        displayCompareToFieldPath: '',
        compareToFieldPackageLevel: 'Unit',

        compareToOtherFieldPath: '',
        displayCompareToOtherFieldPath: '',
        compareToOtherFieldPackageLevel: 'Unit',

        setParameters: [],
      };
    }

    // Compare To Field
    case statementActions.UPDATE_TO_FIELD_PACKAGE_LEVEL: {
      if (state.displayCompareToFieldPath) {
        const { moduleDisplayName, displayName } = state.compareToFieldPath;
        return {
          ...state,
          compareToFieldPackageLevel: action.packageLevel,
          displayCompareToFieldPath: `[${action.packageLevel}].${moduleDisplayName}.${displayName}`,
        };
      } else {
        return {
          ...state,
          compareToFieldPackageLevel: action.packageLevel,
        };
      }
    }
    case statementActions.UPDATE_COMPARE_TO_FIELD_PATH: {
      const { moduleDisplayName, displayName, moduleName, fieldName } =
        action.compareToFieldPath;
      return {
        ...state,
        compareToFieldPath:
          moduleName !== 'NoHeader'
            ? `${moduleName}.${fieldName}`
            : `${fieldName}`,
        displayCompareToFieldPath: `[${state.compareToFieldPackageLevel}].${moduleDisplayName}.${displayName}`,
      };
    }

    // Compare To Other Field
    case statementActions.UPDATE_TO_OTHER_FIELD_PACKAGE_LEVEL: {
      if (state.displayCompareToOtherFieldPath) {
        const { moduleDisplayName, displayName } =
          state.compareToOtherFieldPath;
        return {
          ...state,
          compareToOtherFieldPackageLevel: action.packageLevel,
          displayCompareToOtherFieldPath: `[${action.packageLevel}].${moduleDisplayName}.${displayName}`,
        };
      } else {
        return {
          ...state,
          compareToOtherFieldPackageLevel: action.packageLevel,
        };
      }
    }
    case statementActions.UPDATE_COMPARE_TO_OTHER_FIELD_PATH: {
      const { moduleDisplayName, displayName, moduleName, fieldName } =
        action.compareToOtherFieldPath;
      return {
        ...state,
        compareToOtherFieldPath:
          moduleName !== 'NoHeader'
            ? `${moduleName}.${fieldName}`
            : `${fieldName}`,
        displayCompareToOtherFieldPath: `[${state.compareToOtherFieldPackageLevel}].${moduleDisplayName}.${displayName}`,
      };
    }

    case statementActions.UPDATE_CONDITION: {
      return {
        ...state,
        condition: action.condition,
      };
    }

    case statementActions.COMPARE_TYPE: {
      return {
        ...state,
        compareType: action.compareType,

        // Reset to default
        compareToValue: null,
        compareToOtherValue: null,

        compareToFieldPath: '',
        displayCompareToFieldPath: '',
        compareToFieldPackageLevel: 'Unit',

        compareToOtherFieldPath: '',
        displayCompareToOtherFieldPath: '',
        compareToOtherFieldPackageLevel: 'Unit',

        setParameters: [],
      };
    }

    case statementActions.SAVE_COMPARE_TO_VALUE: {
      if (action.compareToValue === '') {
        return {
          ...state,
          compareToValue: action.compareToValue,
          setParameters: [],
        };
      } else {
        return {
          ...state,
          compareToValue: action.compareToValue,
        };
      }
    }

    case statementActions.SAVE_COMPARE_TO_OTHER_VALUE: {
      return {
        ...state,
        compareToOtherValue: action.compareToOtherValue,
      };
    }

    case statementActions.SAVE_VALUE_FILTER_PROPERTIES: {
      return {
        ...state,
        valueFilter: action.valueFilter,
      };
    }

    case statementActions.RESET_VALUE_FILTER_PROPERTIES: {
      return {
        ...state,
        valueFilter: initialStatementState?.valueFilter,
      };
    }

    case statementActions.SAVE_KEY_SOURCE_FIELD: {
      return {
        ...state,
        keySourceField: action.keySourceField,
      };
    }

    case statementActions.SAVE_NAME_RENDER_SOURCE_FIELD: {
      return {
        ...state,
        nameRenderSourceField: action.nameRenderSourceField,
      };
    }

    case statementActions.CLEAR_NAME_RENDER_SOURCE_FIELD: {
      if (state.indexParam) {
        const newParameters = getNewSetParameters(
          state.setParameters,
          state.indexParam,
          'nameRenderSourceField',
          null
        );

        return {
          ...state,
          nameRenderSourceField: null,
          indexParam: null,
          setParameters: newParameters,
        };
      }

      return {
        ...state,
        nameRenderSourceField: null,
      };
    }

    case statementActions.SET_TYPE: {
      return {
        ...state,
        setType: action.setType,
        setParameters: [],
      };
    }

    case statementActions.ADD_VALUE_PARAMETER:
    case statementActions.ADD_OTHER_FIELD_PARAMETER: {
      return {
        ...state,
        setParameters: [...state.setParameters, action.parameter],
      };
    }

    case statementActions.CHANGE_TYPE_VALUE_PARAMETER: {
      const { type, index } = action.payload;
      const { setParameters } = state;
      let parameter = {
        type,
      };

      if (type === 'Value') {
        parameter = {
          ...parameter,
          setByValue: null,
        };
      } else {
        parameter = {
          ...parameter,
          setByFieldPath: '',
          setByFieldPackageLevel: 'Unit',
          // displayName: '',
        };
      }

      const newSetParameters = [
        ...setParameters.slice(0, index),
        parameter,
        ...setParameters.slice(index + 1, setParameters.length),
      ];

      return {
        ...state,
        setParameters: newSetParameters,
      };
    }

    case statementActions.DELETE_PARAMETER: {
      const { index } = action;
      const { setParameters } = state;

      const newSetParameters = [
        ...setParameters.slice(0, index),
        ...setParameters.slice(index + 1, setParameters.length),
      ];

      if (setParameters[index].type === 'OtherField') {
        return {
          ...state,
          indexParam: null,
          nameRenderSourceField: null,
          setParameters: newSetParameters,
        };
      }

      return {
        ...state,
        setParameters: newSetParameters,
      };
    }

    case statementActions.SHOW_SOURCE_FIELD_PARAMETER: {
      const { index } = action.payload;

      const nameRenderSourceField = `parameterSourceField-${index}`;

      const newParameters = getNewSetParameters(
        state.setParameters,
        index,
        'nameRenderSourceField',
        nameRenderSourceField
      );

      return {
        ...state,
        nameRenderSourceField,
        indexParam: index,
        setParameters: newParameters,
      };
    }

    case statementActions.RESET_PARAMETER: {
      return {
        ...state,
        setParameters: [],
      };
    }

    case statementActions.CHANGE_PROPERTIES_OF_TYPE_VALUE: {
      const { setByValue, index } = action.payload;

      const newParameters = getNewSetParameters(
        state.setParameters,
        index,
        'setByValue',
        setByValue
      );

      return {
        ...state,
        setParameters: newParameters,
      };
    }

    case statementActions.CHANGE_PROPERTY_PACKAGE_LEVEL_PARAM: {
      const { setByFieldPackageLevel } = action.payload;

      const newParameters = getNewSetParameters(
        state.setParameters,
        state.indexParam,
        'setByFieldPackageLevel',
        setByFieldPackageLevel
      );

      return {
        ...state,
        setParameters: newParameters,
      };
    }

    case statementActions.CHANGE_PROPERTY_FIELD_PARAM: {
      const { field } = action;

      const fieldName =
        field?.moduleName !== 'NoHeader'
          ? `${field?.moduleName}.${field?.fieldName}`
          : `${field?.fieldName}`;

      const newParameters = getNewSetParameters(
        state.setParameters,
        state.indexParam,
        'setByFieldPath',
        fieldName
      );

      return {
        ...state,
        setParameters: newParameters,
      };
    }
    case statementActions.SAVE_STATEMENT_STATE: {
      return {
        ...initialStatementState,
        ...action.statement,
      };
    }

    case statementActions.CHANGE_STATEMENT_TYPE: {
      return {
        ...state,
        statementType: action?.statementType,
      };
    }

    case statementActions.RESET_STATE: {
      return initialStatementState;
    }

    default: {
      return state;
    }
  }
};

export const useAddStatement = () => {
  const [statementState, dispatchStatement] = useReducer(
    statementReducer,
    initialStatementState
  );

  const handleSelectPackageLevel = (packageLevel) => {
    dispatchStatement({
      type: statementActions.UPDATE_PACKAGE_LEVEL,
      packageLevel,
    });
  };

  const handleSelectToFieldPackageLevel = (packageLevel) => {
    dispatchStatement({
      type: statementActions.UPDATE_TO_FIELD_PACKAGE_LEVEL,
      packageLevel,
    });
  };

  const handleSelectToOtherFieldPackageLevel = (packageLevel) => {
    dispatchStatement({
      type: statementActions.UPDATE_TO_OTHER_FIELD_PACKAGE_LEVEL,
      packageLevel,
    });
  };

  const handleGetSourceField = useCallback((sourceField) => {
    let compareFieldPath = '';

    if (!sourceField) {
      return;
    } else {
      compareFieldPath = sourceField;
    }

    dispatchStatement({
      type: statementActions.UPDATE_COMPARE_FIELD_PATH,
      compareFieldPath,
    });
  }, []);

  const handleGetSourceToField = useCallback((sourceField) => {
    let compareToFieldPath = '';

    if (!sourceField) {
      return;
    } else {
      compareToFieldPath = sourceField;
    }

    dispatchStatement({
      type: statementActions.UPDATE_COMPARE_TO_FIELD_PATH,
      compareToFieldPath,
    });
  }, []);

  const handleGetSourceToOtherField = useCallback((sourceField) => {
    let compareToOtherFieldPath = '';

    if (!sourceField) {
      return;
    } else {
      compareToOtherFieldPath = sourceField;
    }

    dispatchStatement({
      type: statementActions.UPDATE_COMPARE_TO_OTHER_FIELD_PATH,
      compareToOtherFieldPath,
    });
  }, []);

  const handleFilterProperties = (valueFilter) => {
    dispatchStatement({
      type: statementActions.SAVE_VALUE_FILTER_PROPERTIES,
      valueFilter,
    });
  };

  const handleSelectCompareField = (compareType) => {
    dispatchStatement({
      type: statementActions.COMPARE_TYPE,
      compareType,
    });
  };

  const handleSaveNameRenderSourceField = (nameRenderSourceField) => {
    dispatchStatement({
      type: statementActions.SAVE_NAME_RENDER_SOURCE_FIELD,
      nameRenderSourceField,
    });
  };

  const handleChangeCondition = (condition) => {
    dispatchStatement({
      type: statementActions.UPDATE_CONDITION,
      condition,
    });
  };

  const handleResetNameRenderSourceField = () => {
    dispatchStatement({
      type: statementActions.CLEAR_NAME_RENDER_SOURCE_FIELD,
    });
  };

  const handleSetType = (setType) => {
    dispatchStatement({
      type: statementActions.SET_TYPE,
      setType,
    });
  };

  const handleAddValueParameter = (parameter) => {
    dispatchStatement({
      type: statementActions.ADD_VALUE_PARAMETER,
      parameter,
    });
  };

  const handleAddOtherFieldParameter = (parameter) => {
    dispatchStatement({
      type: statementActions.ADD_OTHER_FIELD_PARAMETER,
      parameter,
    });
  };

  const handleChangeTypeValueParameter = (type, index) => {
    dispatchStatement({
      type: statementActions.CHANGE_TYPE_VALUE_PARAMETER,
      payload: { type, index },
    });
  };

  const handleDeleteParameter = (index) => {
    dispatchStatement({
      type: statementActions.DELETE_PARAMETER,
      index,
    });
  };

  const handleResetParameter = (value) => {
    dispatchStatement({
      type: statementActions.RESET_PARAMETER,
      value,
    });
  };

  const handleChangePropertiesOfTypeValue = (setByValue, index) => {
    dispatchStatement({
      type: statementActions.CHANGE_PROPERTIES_OF_TYPE_VALUE,
      payload: { setByValue, index },
    });
  };

  const handleChangePropertyPackageLevelParam = (setByFieldPackageLevel) => {
    dispatchStatement({
      type: statementActions.CHANGE_PROPERTY_PACKAGE_LEVEL_PARAM,
      payload: { setByFieldPackageLevel },
    });
  };

  const handleChangePropertyFieldParam = useCallback((sourceField) => {
    let field = '';

    if (!sourceField) {
      return;
    } else {
      field = sourceField;
    }

    dispatchStatement({
      type: statementActions.CHANGE_PROPERTY_FIELD_PARAM,
      field,
    });
  }, []);

  const handleShowSourceFieldParameter = (index) => {
    dispatchStatement({
      type: statementActions.SHOW_SOURCE_FIELD_PARAMETER,
      payload: { index },
    });
  };

  const handleResetState = useCallback(() => {
    dispatchStatement({
      type: statementActions.RESET_STATE,
    });
  }, []);

  const handleSaveStatementState = useCallback((statement) => {
    dispatchStatement({
      type: statementActions.SAVE_STATEMENT_STATE,
      statement,
    });
  }, []);

  const handleSaveCompareToValue = (compareToValue) => {
    dispatchStatement({
      type: statementActions.SAVE_COMPARE_TO_VALUE,
      compareToValue,
    });
  };

  const handleSaveCompareOtherValue = (compareToOtherValue) => {
    dispatchStatement({
      type: statementActions.SAVE_COMPARE_TO_OTHER_VALUE,
      compareToOtherValue,
    });
  };

  const handleChangeStatementType = (statementType) => {
    dispatchStatement({
      type: statementActions.CHANGE_STATEMENT_TYPE,
      statementType,
    });
  };

  const handleResetSourceFieldFilter = (params) => {
    dispatchStatement({
      type: statementActions.RESET_VALUE_FILTER_PROPERTIES,
      params,
    });
  };

  return {
    statementState,
    handleSelectPackageLevel,
    handleSelectToFieldPackageLevel,
    handleSelectToOtherFieldPackageLevel,
    handleSelectCompareField,
    handleGetSourceField,
    handleGetSourceToField,
    handleGetSourceToOtherField,
    handleFilterProperties,
    handleSaveNameRenderSourceField,
    handleChangeCondition,
    handleResetNameRenderSourceField,
    handleSetType,
    handleAddValueParameter,
    handleAddOtherFieldParameter,
    handleChangeTypeValueParameter,
    handleDeleteParameter,
    handleShowSourceFieldParameter,
    handleChangePropertyPackageLevelParam,
    handleChangePropertyFieldParam,
    handleChangePropertiesOfTypeValue,
    handleResetState,
    handleSaveStatementState,
    handleSaveCompareToValue,
    handleSaveCompareOtherValue,
    handleResetParameter,
    handleChangeStatementType,
    handleResetSourceFieldFilter,
  };
};
