import React, { useState, forwardRef } from 'react';
import { Typography, List, Avatar, Tooltip, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { DragDropWrapUtils } from 'common/components';

import { Images } from 'config/assets';

import classnames from 'classnames';

import './AddToFolderConfirm.less';
import _ from 'lodash';

const { Title, Text } = Typography;

const AddToFolderConfirm = forwardRef((props, ref) => {
  const { listData = [], folder, handleAddToFolder } = props;

  const [listItem, setlistItem] = useState(listData);

  const handleRemoveItem = (item) => {
    const newList = _.cloneDeep(listItem).filter((val) => val?.id !== item?.id);
    setlistItem(newList);
  };

  const triggleAddToFolder = () => {
    handleAddToFolder(listItem, folder);
  };

  if (ref) {
    ref.current = { triggleAddToFolder };
  }

  const renderTitle = (item) => {
    const hostname = DragDropWrapUtils.mapItemDataToDetailLink(item);
    const titleNamePath = DragDropWrapUtils.mapItemDataToDragNamePath(item);
    if (hostname) {
      return (
        <a href={hostname + `/${item?.id}`} target='_blank'>
          {_.get(item, titleNamePath)}
        </a>
      );
    } else return <Text>{_.get(item, titleNamePath)}</Text>;
  };

  return (
    <div>
      <Title level={5}>
        <span style={{ fontWeight: 400 }}>
          {' '}
          Do you want to add&nbsp;
          {listItem?.length === 1
            ? 'this item'
            : `these ${listItem?.length} items`}
          &nbsp;to{' '}
        </span>
        <span style={{ fontWeight: 600 }}>
          {folder?.folderName || folder?.description}
        </span>
      </Title>
      <div className={classnames('add-to-folder-confirm__list', 'scroller')}>
        <List
          itemLayout='horizontal'
          dataSource={listItem}
          renderItem={(item) => {
            return (
              <List.Item
                actions={
                  listItem?.length > 1
                    ? [
                        <Tooltip title='Remove item'>
                          <Button
                            danger
                            type='text'
                            icon={<CloseOutlined />}
                            onClick={() => {
                              handleRemoveItem(item);
                            }}
                          />
                        </Tooltip>,
                      ]
                    : null
                }
              >
                <List.Item.Meta
                  avatar={
                    <div>
                      <Avatar
                        src={
                          DragDropWrapUtils.mapItemDataToAvatarConfirmList(
                            item
                          ) || Images.RIVIR_LOGO_DEFAULT
                        }
                        width={32}
                        height={32}
                      />
                    </div>
                  }
                  title={renderTitle(item)}
                />
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
});

export default AddToFolderConfirm;
