import {
  handleCallApiReducer,
  handleCallApiReducerForLoadMore,
} from 'utils/api';

export const mapToEmailInfo = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'emailThreadInfo');
};

export const mapToEmailList = (action, _draft, state) => {
  handleCallApiReducerForLoadMore(action, _draft, state, 'emailThreadList');
};
