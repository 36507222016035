/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import { LocaleContext } from 'context/Locale';
import moment from 'moment';

/**
 * A Language provider which wraps IntlProvider to support i18n base on current locale
 * @author Brian Pick
 */
const LanguageProvider = (props) => {
  const context = useContext(LocaleContext);
  // eslint-disable-next-line
  const { locale, changeLocale } = context;

  locale && moment.locale(locale || 'en');

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={props.messages[locale]}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  /**
   * current localization
   */
  locale: PropTypes.string,
  /**
   * list of i18n locale messages
   */
  messages: PropTypes.object,
  /**
   * React element
   */
  children: PropTypes.element,
};

export default LanguageProvider;
