import { useState, useRef, useEffect } from 'react';

import {
  Form,
  Row,
  Col,
  Typography,
  Button,
  Space,
  Tooltip,
  Popover,
  List,
  Checkbox,
} from 'antd';
import {
  QuestionCircleTwoTone,
  ReloadOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import _ from 'lodash';

import { FullWidthSpace } from 'common/components';

import { useValidateMinimumRequirement } from 'hooks/useValidateMinimumRequirement';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import { EVENT } from 'static/Constants';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/product';

import { useGetProductFullView } from './product-full/hooks';
import { MINIMUM_REQUIREMENT_ENTITY_TYPE } from 'pages/minimum-requirements/constants';

const { Text, Title, Paragraph } = Typography;

export const useValidateStatusField = (props) => {
  const intl = useIntl();
  const validatedRef = useRef(false);

  const { form, entityType, entityId, placement } = props;

  const value = Form.useWatch('status', form);

  const [infoPopupVisible, setInfoPopupVisible] = useState(true);

  const {
    validateLoading,
    fetchDataLoading,
    propertiesRequired,
    additionalRequirements,
    fetchEntityMinimumRequirementDetail,
  } = useValidateMinimumRequirement({
    visible: entityId && value === 'Active', //* this prop makes validating automatically when mounted if true
    autoValidateAfterFetchData: false,
    entityId,
    entityType,
    validateCallback: () => {
      validateCallback();
    },
  });

  const loading = fetchDataLoading || validateLoading;

  const groupedProReq = _.groupBy(propertiesRequired, 'moduleDisplayName');

  const onClickValidateBtn = () => {
    fetchEntityMinimumRequirementDetail();
  };

  const isProductEntity =
    entityType?.toLowerCase() === MINIMUM_REQUIREMENT_ENTITY_TYPE.PRODUCT;

  const isGetProductFullView = isProductEntity && !!entityId;

  const { productFull } = useGetProductFullView({
    productId: entityId,
    enabled: isGetProductFullView,
  });

  const onInfoPopupVisibleChange = (visible) => {
    setInfoPopupVisible(visible);
  };

  const checkMinReqError = () => {
    const hasPropertiesRequiredError = propertiesRequired.some(
      (requirementItem) => {
        return requirementItem.checkStatus === 'error';
      }
    );

    const hasAdditionalPropertiesError = additionalRequirements.some(
      (requirementItem) => {
        return requirementItem.checkStatus === 'error';
      }
    );

    return hasPropertiesRequiredError || hasAdditionalPropertiesError;
  };

  const validateCallback = () => {
    validatedRef.current = true;
  };

  const showInfoPopup = () => {
    setInfoPopupVisible(hasMinReqError);
  };

  useEffect(() => {
    showInfoPopup();
  }, [hasMinReqError, value, loading]);

  useDocumentAttachEvent({
    name: EVENT.VALIDATE_STATUS_FIELD,
    handler: () => {
      fetchEntityMinimumRequirementDetail();
    },
  });

  const renderPropertyReqModule = (item) => {
    const moduleName = item[0];
    const propertyList = item[1];

    return (
      <div>
        {moduleName !== 'undefined' && moduleName !== 'null' ? (
          <Text strong style={{ display: 'block', marginBottom: 4 }}>
            {moduleName}
          </Text>
        ) : null}
        {propertyList.map((propertyItem) => {
          return (
            <Row style={{ marginBottom: 4 }} gutter={16} wrap={false}>
              <Col flex={1}>
                <Text
                  style={{
                    paddingLeft: 8,
                    lineHeight: 'normal',
                    display: 'inline-block',
                  }}
                >
                  {propertyItem.fullPathDisplayName}
                </Text>
              </Col>
              <Col flex={0}>{renderIcon(propertyItem)}</Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const renderAdditionalReqItem = (item) => {
    return (
      <Row style={{ marginBottom: 2 }} gutter={16} wrap={false}>
        <Col flex={1}>
          <Text
            disabled={!item.active}
            style={{
              paddingLeft: 8,
            }}
          >
            {item.name}
          </Text>
        </Col>
        <Col flex={0}>{item.value}</Col>
        <Col flex={0}>{renderIcon(item)}</Col>
      </Row>
    );
  };

  const renderIcon = (propertyItem) => {
    const statusIconCollection = {
      success: { icon: CheckOutlined, color: '#389e0d' },
      error: { icon: CloseOutlined, color: '#cf1322' },
    };

    const icon = statusIconCollection[propertyItem.checkStatus];
    const StatusIcon = icon?.icon;
    const statusColor = icon?.color;

    return StatusIcon ? (
      <StatusIcon style={{ color: statusColor, margin: '4px 0px' }} />
    ) : null;
  };

  const shouldValidate = entityId && value === 'Active';
  const hasMinReqError = checkMinReqError();
  const messageVisible = shouldValidate && (validatedRef.current || loading); //* make sure the message is only visible when the validation is complete
  const shouldShowErrorMessage = shouldValidate && !loading;
  const validatingStatus = loading
    ? 'validating'
    : hasMinReqError
    ? 'error'
    : 'success';
  const messageType = hasMinReqError ? 'warning' : 'success';
  const colorMessage = hasMinReqError ? 'danger' : 'success';
  const message = intl.formatMessage(
    messages.validateStatusMessage[messageType]
  );

  const ValidatingInfo = (
    <FullWidthSpace
      className='scroller--y'
      style={{
        width: 300,
        maxHeight: 360,
        padding: '0px 8px',
        margin: '0px -8px',
      }}
    >
      {isProductEntity && (
        <Space>
          <Text strong style={{ marginBottom: '0' }}>
            Private Label
          </Text>
          <Checkbox checked={productFull?.isPrivateLabel} />
        </Space>
      )}
      <div>
        <Title level={5}>Properties Requirement</Title>
        <List
          dataSource={Object.entries(groupedProReq)}
          renderItem={renderPropertyReqModule}
        />
      </div>
      <div>
        <Title level={5}>Additional Requirement</Title>
        <List
          dataSource={additionalRequirements}
          renderItem={renderAdditionalReqItem}
        />
      </div>
    </FullWidthSpace>
  );

  const statusValidateProps = {
    validateStatus: shouldValidate ? validatingStatus : undefined,
    hasFeedback: messageVisible,
    help: messageVisible ? (
      <Row style={{ margin: '4px 0' }} wrap={false}>
        <Col flex={1}>
          {loading ? (
            <Paragraph style={{ margin: '1px 0px' }}>
              {intl.formatMessage(messages.validateLoading)}
            </Paragraph>
          ) : (
            <Text type={colorMessage}>{message}</Text>
          )}
        </Col>
        <Col flex={0}>
          {shouldShowErrorMessage ? (
            <Space size={4} align='center'>
              <Tooltip title='Click to validate'>
                <Button
                  type='text'
                  size='small'
                  icon={<ReloadOutlined />}
                  onClick={onClickValidateBtn}
                ></Button>
              </Tooltip>
              <Popover
                placement={placement}
                trigger='click'
                content={ValidatingInfo}
                visible={infoPopupVisible}
                onVisibleChange={onInfoPopupVisibleChange}
              >
                <QuestionCircleTwoTone
                  twoToneColor={hasMinReqError ? '#f5222d' : '#52c41a'}
                />
              </Popover>
            </Space>
          ) : null}
        </Col>
      </Row>
    ) : undefined,
  };

  return { statusValidateProps };
};
