import React, { memo, useMemo, forwardRef, useImperativeHandle } from 'react';

import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { QaGridEdit } from 'pages/qa-spec/components';

import { useGetFormulaActiveIngredientColDefs } from 'pages/qa-spec/hooks/useFormula';

import { getAllRows } from 'pages/qa-spec/utils';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

const QaFormulaActiveIngredientGrid = forwardRef((props, forwardedRef) => {
  const {
    gridInst,
    isEdit,
    activeIngredientList = [],
    snapShotActiveIngredientList = [],
    setSelectedRows,
    mode,
  } = props;

  const rowData = useMemo(() => {
    return activeIngredientList.map((ingredientItem) => ({
      ...ingredientItem,
      uuid: uuidv4(),
    }));
  }, [JSON.stringify(activeIngredientList)]);

  const useSnapshot = useCheckSnapshotForRetailer();

  const fields = useGetFormulaActiveIngredientColDefs({
    useSnapshot: useSnapshot && mode !== 'add',
  });

  const stopEditing = () => {
    gridInst.current.api.stopEditing();
  };

  const updateRowError = () => {
    const allRowsData = getAllRows(gridInst);
    let newRowsData = _.cloneDeep(allRowsData);

    // validate error
    newRowsData = newRowsData.map((row) => {
      const error = [];

      if (!row.ingredientName) error.push('Ingredient Name is required');

      return { ...row, error: error };
    });

    gridInst.current.api.applyTransaction({
      update: newRowsData,
    });
  };

  const onRowSelected = () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();
    setSelectedRows(selectedRows);
  };

  useImperativeHandle(forwardedRef, () => ({
    getGridData: () => {
      return getAllRows(gridInst);
    },
    updateGridData: (data) => {
      gridInst.current.api.setRowData(data);
    },
    stopEditing: () => {
      stopEditing();
    },
    clearSelectedRows: () => {
      setSelectedRows([]);
    },
  }));

  const onCellEditingStopped = (params) => {
    updateRowError(params);
  };

  const rowClassRules = useMemo(
    () => ({
      'ag-row--error': (params) => {
        return !!params?.data?.error?.length > 0;
      },
    }),
    []
  );

  return (
    <QaGridEdit
      fields={fields}
      isEdit={isEdit}
      gridInst={gridInst}
      gridData={rowData}
      getRowNodeId={(data) => {
        return data?.['uuid'];
      }}
      onRowSelected={onRowSelected}
      rowClassRules={rowClassRules}
      onCellEditingStopped={onCellEditingStopped}
      snapshotGridValues={snapShotActiveIngredientList}
    />
  );
});

export default memo(QaFormulaActiveIngredientGrid);
