import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Row, Col, Tooltip, Space } from 'antd';
import {
  iconList,
  locationTypeIcon,
  statusIcon,
  publicPrivateIcon,
  primaryContactIcon,
  primaryLocationIcon,
  contactStatusIcon,
  locationStatusIcon,
} from './IconRendererMapping';
import './IconRenderer.less';

const MemberBillingIconRenderer = (props) => {
  const { item } = props;
  const TypeIcon = iconList.filter(
    (icon) => icon.value === item.value.trim()
  )[0]?.icon;
  return (
    <Row gutter={8}>
      <Col span={24} className='icon-renderer__col'>
        {TypeIcon && <TypeIcon className='icon-renderer__icon' />}
      </Col>
    </Row>
  );
};

const MemberContactIconRenderer = (props) => {
  const { item } = props;
  const StatusIcon = contactStatusIcon.filter(
    (icon) => icon.value === item.data.status
  )[0];
  const PublicPrivateIcon = publicPrivateIcon.filter(
    (icon) => icon.value === item.data.visibility
  )[0];
  const PrimaryContactIcon = primaryContactIcon[0];

  return (
    <div>
      <Space size={8}>
        {StatusIcon?.icon && (
          <Tooltip
            placement='top'
            title={StatusIcon.tooltip || StatusIcon.value}
          >
            <StatusIcon.icon
              className={`icon-renderer__icon icon-renderer__icon--${StatusIcon.suffix}`}
            />
          </Tooltip>
        )}
        {PublicPrivateIcon?.icon && (
          <Tooltip placement='top' title={PublicPrivateIcon.tooltip}>
            <PublicPrivateIcon.icon className='icon-renderer__icon' />
          </Tooltip>
        )}
        {item.data.isPrimaryContact && (
          <Tooltip placement='top' title={PrimaryContactIcon.tooltip}>
            <PrimaryContactIcon.icon className='icon-renderer__icon' />
          </Tooltip>
        )}
      </Space>
    </div>
  );
};

const MemberLocationIconRenderer = (props) => {
  const { item } = props;
  const StatusIcon = locationStatusIcon.filter(
    (icon) => icon.value === item.data.status
  )[0];
  const LocationTypeIcon = locationTypeIcon.filter(
    (icon) => icon.value === item.data.addressType
  )[0];
  const PrimaryLocationIcon = primaryLocationIcon[0];

  return (
    <Row gutter={16}>
      <Col span={8} className='icon-renderer__col'>
        {StatusIcon?.icon && (
          <Tooltip placement='top' title={StatusIcon.value}>
            <StatusIcon.icon
              className={`icon-renderer__icon icon-renderer__icon--${StatusIcon.suffix}`}
            />
          </Tooltip>
        )}
      </Col>
      <Col span={8} className='icon-renderer__col'>
        {LocationTypeIcon && (
          <Tooltip placement='top' title={LocationTypeIcon.label}>
            <LocationTypeIcon.icon className='icon-renderer__icon' />
          </Tooltip>
        )}
      </Col>
      <Col span={8} className='icon-renderer__col'>
        {item.data.isPrimaryAddress && (
          <Tooltip placement='top' title={PrimaryLocationIcon.tooltip}>
            <PrimaryLocationIcon.icon className='icon-renderer__icon' />
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

MemberBillingIconRenderer.propsTypes = {
  item: oneOfType([PropTypes.object, PropTypes.array]),
};

MemberContactIconRenderer.propsTypes = {
  item: oneOfType([PropTypes.object, PropTypes.array]),
};

MemberLocationIconRenderer.propsTypes = {
  item: oneOfType([PropTypes.object, PropTypes.array]),
};

export {
  MemberBillingIconRenderer,
  MemberContactIconRenderer,
  MemberLocationIconRenderer,
};
