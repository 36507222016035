export const EDIT_SECTION = {
  PROD_SPEC_INFO: {
    id: 'qa-spec-product-info',
    name: 'Product Specification - Product Info',
    tab: 'product-specification',
  },
  PROD_FRESH_PRODUCE: {
    id: 'qa-spec-fresh-produce',
    name: 'Fresh - Produce',
    tab: 'fresh-produce',
  },
  PROD_FRESH_DELI: {
    id: 'qa-spec-fresh-deli',
    name: 'Fresh - Deli',
    tab: 'fresh-deli',
  },
  PROD_SPEC_ORGANOLEPTIC: {
    id: 'qa-spec-organoleptic',
    name: 'Product Specification - Organoleptic Characteristics',
    tab: 'product-specification',
  },
  PROD_SPEC_PHY_N_CHE: {
    id: 'qa-physic-n-chemical',
    name: 'Product Specification - Physical and Chemical Characteristics',
    tab: 'product-specification',
  },
  PROD_SPEC_CONTAMINANT: {
    id: 'qa-contaminent',
    name: 'Product Specification - Contaminant Characteristics',
    tab: 'product-specification',
  },
  PROD_SPEC_MICROBIOLOGICAL_CHARACTER: {
    id: 'qa-microbiological-characteristic',
    name: 'Product Specification - Microbiological Characteristics',
    tab: 'product-specification',
  },
  PROD_SPEC_DOC: {
    id: 'qa-prod-spec-document',
    name: 'Product Specification - Documents',
    tab: 'product-specification',
  },
  QA_INGREDIENT: {
    id: 'qa-ingredient',
    name: 'Ingredients',
    tab: 'ingredients',
  },
  QA_ALLERGEN: {
    id: 'qa-allergen',
    name: 'Allergens',
    tab: 'allergens',
  },
  QA_NUTRITION: {
    id: 'qa-nutrition',
    name: 'Facts Panel',
    tab: 'facts-panel',
  },
  QA_FORMULA: {
    id: 'qa-formula',
    name: 'Formula',
    tab: 'formula',
  },
  QA_MANUFACTURING_PROCESS_STAGE: {
    id: 'qa-manufacturing-process-stage',
    name: 'Manufacturing Process - Process Stage',
    tab: 'manufacturing-process',
  },
  QA_MANUFACTURING_LOCATION: {
    id: 'qa-manufacturing-location',
    name: 'Manufacturing Process - Manufacturing Location',
    tab: 'manufacturing-process',
  },
  QA_MANUFACTURING_DOCUMENT: {
    id: 'qa-manufacturing-document',
    name: 'Manufacturing Process - Documents',
    tab: 'manufacturing-process',
  },
  QA_CONTACT: {
    id: 'qa-contact',
    name: 'Contacts',
    tab: 'contacts',
  },
  QA_DOC_N_CERT_DOC: {
    id: 'qa-doc-n-cert-doc',
    name: 'Documents/ Certificates - Documents',
    tab: 'documents-certificates',
  },
  QA_DOC_N_CERT_CERT: {
    id: 'qa-doc-n-cert-cert',
    name: 'Documents/ Certificates - Certificates',
    tab: 'documents-certificates',
  },
  QA_COMPONENTS: {
    id: 'qa-components',
    name: 'Components',
    tab: 'components',
  },
  QA_FRESH_MEAT: {
    id: 'qa-fresh-meat',
    name: 'Fresh-Meat',
    tab: 'fresh-meat',
  },
  QA_FRESH_SEAFOOD: {
    id: 'qa-fresh-seafood',
    name: 'Fresh-Seafood',
    tab: 'fresh-seafood',
  },
  QA_PACKAGING: {
    id: 'qa-packaging',
    name: 'Labeling',
    tab: 'labeling',
  },
  QA_CLAIMS: {
    id: 'qa-claims',
    name: 'Claims',
    tab: 'claims',
  },
  QA_PRODUCT_CONTAINS: {
    id: 'qa-product-contains',
    name: 'Product Contains',
    tab: 'product-contains',
  },
  // QA_PET_NON_FOOD: {
  //   id: 'qa-pet-non-food',
  //   name: 'Pet Non Food',
  //   tab: 'pet-non-food',
  // },
  QA_PET_FOOD: {
    id: 'qa-fet-food',
    name: 'Pet Food',
    tab: 'pet-food',
  },
};

export const TAB_DEFINE = {
  'product-specification': 'Product Specification',
  'fresh-produce': 'Fresh - Produce',
  'fresh-deli': 'Fresh - Deli',
  ingredients: 'Ingredients',
  allergens: 'Allergens',
  'facts-panel': 'Facts Panel',
  formula: 'Formula',
  'manufacturing-process': 'Manufacturing Process',
  contacts: 'Contacts',
  'documents-certificates': 'Documents/ Certificates',
  components: 'Components',
  'fresh-seafood': 'Fresh- Seafood',
  'fresh-meat': 'Fresh - Meat',
  claims: 'Claims',
  labeling: 'Labeling',
  'product-contains': 'Product Contains',
  'pet-non-food': 'Pet Non-Food',
  'pet-food': 'Pet Food',
};
