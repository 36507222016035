import React from "react";

import { Typography, Avatar } from "antd";

import { CloseOutlined } from "@ant-design/icons";

import "./AvatarTagItem.less";

const { Text } = Typography;

const AvatarTagItem = (props) => {
  const { itemDetail, handleRemoveAvatarTag, idx } = props;

  return (
    <div className="avatar-tag-item">
      <Text className="avatar-tag-item__text">
        {itemDetail?.name}
        <CloseOutlined
          className="avatar-tag-item__close"
          onClick={() => {
            handleRemoveAvatarTag && handleRemoveAvatarTag(idx);
          }}
        />
      </Text>
      <div className="avatar-tag-item__avatar">
        <Avatar src={itemDetail?.avatar} />
      </div>
    </div>
  );
};

export default AvatarTagItem;
