import { useState, useEffect } from 'react';

import _ from 'lodash';

import { getChildNodes } from 'utils/dom';

export const useShowMoreRibbonButtons = (props) => {
  const { ribbonRef, children } = props;

  const [shouldShowMore, setShouldShowMore] = useState(false);
  const [activeMoreButton, setActiveMoreButton] = useState(false);

  const checkShowMore = () => {
    const {
      ribbonBarWidth,
      totalLeftSideButtonWidth,
      totalRightSideButtonWidth,
      moreButtonWidth,
    } = getAllElementsWidth();

    const sideOffset =
      ribbonBarWidth -
      totalLeftSideButtonWidth -
      totalRightSideButtonWidth -
      moreButtonWidth;

    if (!ribbonBarWidth) return false;

    const isOverflow = sideOffset < 0;

    if (isOverflow) updateButtonVisible();

    return isOverflow;
  };

  const getAllElementsWidth = () => {
    const ribbonBar = ribbonRef.current;
    const leftSideContainer = ribbonBar?.querySelector(
      '.ribbon-bar__side-left'
    );
    const rightSideContainer = ribbonBar?.querySelector(
      '.ribbon-bar__side-right'
    );

    const ribbonBarWidth = ribbonBar?.offsetWidth;
    const totalLeftSideButtonWidth =
      calculateTotalButtonWidth(leftSideContainer);
    const totalRightSideButtonWidth =
      calculateTotalButtonWidth(rightSideContainer);

    return {
      ribbonBarWidth,
      totalLeftSideButtonWidth,
      totalRightSideButtonWidth,
      moreButtonWidth: 105,
    };
  };

  const calculateTotalButtonWidth = (container) => {
    const buttons = getChildNodes(container);

    const buttonsWidth = buttons.reduce((accumulator, currentButton) => {
      const buttonWidth = currentButton.offsetWidth;
      return (accumulator += buttonWidth);
    }, 0);

    return buttonsWidth;
  };

  const updateButtonVisible = () => {
    const ribbonBar = ribbonRef.current;
    const { ribbonBarWidth, totalRightSideButtonWidth, moreButtonWidth } =
      getAllElementsWidth();

    const visibleLeftSideWidth =
      ribbonBarWidth - totalRightSideButtonWidth - moreButtonWidth;

    const leftSideContainer = ribbonBar.querySelector('.ribbon-bar__side-left');
    const leftSideButtons = getChildNodes(leftSideContainer);
    let accumulator = 0;
    leftSideButtons.forEach((button) => {
      const buttonWidth = button.offsetWidth;
      accumulator += buttonWidth;
      if (accumulator > visibleLeftSideWidth) button.classList.add('show-more');
      else button.classList.remove('show-more');
    });
  };

  const debouncedCheckShowMore = _.debounce(() => {
    setShouldShowMore(checkShowMore());
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', debouncedCheckShowMore);
    return () => {
      window.removeEventListener('resize', debouncedCheckShowMore);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShouldShowMore(checkShowMore());
    }, 300);
  }, [ribbonRef.current, children]);

  return {
    shouldShowMore: activeMoreButton || shouldShowMore,
    activeMoreButton,
    setActiveMoreButton,
  };
};
