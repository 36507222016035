import React from 'react';

import { Button } from 'antd';

const BorderButton = (props) => {
  const { danger, children, style, ...restProps } = props;

  return (
    <Button
      style={
        !danger ? { borderColor: '#1677ff', color: '#1677ff', ...style } : style
      }
      danger={danger}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default BorderButton;
