import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import {
  pathnameFavMember,
  pathnameFavAssets,
  pathnameFavFolders,
  pathnameFavProducts,
  pathnameProduct,
  pathnameMember,
  pathnameDigitalMedia,
  pathnameDigitalMediaNew,
  pathnameAssets,
  pathnameDocument,
  pathnameMultiMedia,
  pathnameReportings,
  pathnameReportingsOwned,
  pathnameReportingsShared,
  pathnameFolders,
  pathnameFoldersOwned,
  pathnameFoldersShared,
  pathnameAssetsForMember,
  pathnameProductsForMember,
} from 'static/Constants';

const useGetDataAdvanceFilter = (pathname) => {
  const [state, setState] = useState([]);

  const favMembersQueryAdvance = useSelector(
    gridViewSelectors.makeSelectFavMembersQueryAdvance()
  );
  const favAssetsQueryAdvance = useSelector(
    gridViewSelectors.makeSelectFavAssetsQueryAdvance()
  );
  const favFoldersQueryAdvance = useSelector(
    gridViewSelectors.makeSelectFavFoldersQueryAdvance()
  );
  const favProductsQueryAdvance = useSelector(
    gridViewSelectors.makeSelectFavProductsQueryAdvance()
  );
  const productsQueryAdvance = useSelector(
    gridViewSelectors.makeSelectProductsQueryAdvance()
  );
  const membersQueryAdvance = useSelector(
    gridViewSelectors.makeSelectMembersQueryAdvance()
  );
  const digitalMediaQueryAdvance = useSelector(
    gridViewSelectors.makeSelectDigitalMediaQueryAdvance()
  );
  const digitalMediaNewQueryAdvance = useSelector(
    gridViewSelectors.makeSelectDigitalMediaNewQueryAdvance()
  );
  const assetsQueryAdvance = useSelector(
    gridViewSelectors.makeSelectAssetsQueryAdvance()
  );
  const documentsQueryAdvance = useSelector(
    gridViewSelectors.makeSelectDocumentsQueryAdvance()
  );
  const multiMediaQueryAdvance = useSelector(
    gridViewSelectors.makeSelectMultiMediaQueryAdvance()
  );
  const reportingsQueryAdvance = useSelector(
    gridViewSelectors.makeSelectReportingsQueryAdvance()
  );
  const myReportsQueryAdvance = useSelector(
    gridViewSelectors.makeSelectMyReportsQueryAdvance()
  );
  const reportsSharedToMeQueryAdvance = useSelector(
    gridViewSelectors.makeSelectReportsSharedToMeQueryAdvance()
  );
  const foldersQueryAdvance = useSelector(
    gridViewSelectors.makeSelectFoldersQueryAdvance()
  );
  const myFoldersQueryAdvance = useSelector(
    gridViewSelectors.makeSelectMyFoldersQueryAdvance()
  );
  const foldersSharedToMeQueryAdvance = useSelector(
    gridViewSelectors.makeSelectFoldersSharedToMeQueryAdvance()
  );
  const assetsForMemberQueryAdvance = useSelector(
    gridViewSelectors.makeSelectAssetsForMemberQueryAdvance()
  );
  const productsForMemberQueryAdvance = useSelector(
    gridViewSelectors.makeSelectProductsForMemberQueryAdvance()
  );

  useEffect(() => {
    let data = [];

    switch (pathname) {
      case pathnameFavMember:
        data = favMembersQueryAdvance;
        break;
      case pathnameFavAssets:
        data = favAssetsQueryAdvance;
        break;
      case pathnameFavFolders:
        data = favFoldersQueryAdvance;
        break;
      case pathnameFavProducts:
        data = favProductsQueryAdvance;
        break;
      case pathnameProduct:
        data = productsQueryAdvance;
        break;
      case pathnameMember:
        data = membersQueryAdvance;
        break;
      case pathnameDigitalMedia:
        data = digitalMediaQueryAdvance;
        break;
      case pathnameDigitalMediaNew:
        data = digitalMediaNewQueryAdvance;
        break;
      case pathnameAssets:
        data = assetsQueryAdvance;
        break;
      case pathnameDocument:
        data = documentsQueryAdvance;
        break;
      case pathnameMultiMedia:
        data = multiMediaQueryAdvance;
        break;
      case pathnameReportings:
        data = reportingsQueryAdvance;
        break;
      case pathnameReportingsOwned:
        data = myReportsQueryAdvance;
        break;
      case pathnameReportingsShared:
        data = reportsSharedToMeQueryAdvance;
        break;
      case pathnameFolders:
        data = foldersQueryAdvance;
        break;
      case pathnameFoldersOwned:
        data = myFoldersQueryAdvance;
        break;
      case pathnameFoldersShared:
        data = foldersSharedToMeQueryAdvance;
        break;
      default:
        break;
    }
    if (pathname.includes(pathnameAssetsForMember)) {
      data = assetsForMemberQueryAdvance;
    }
    if (pathname.includes(pathnameProductsForMember)) {
      data = productsForMemberQueryAdvance;
    }
    setState(data);
  }, [
    pathname,
    favMembersQueryAdvance,
    favAssetsQueryAdvance,
    favFoldersQueryAdvance,
    favProductsQueryAdvance,
    productsQueryAdvance,
    membersQueryAdvance,
    digitalMediaQueryAdvance,
    digitalMediaNewQueryAdvance,
    assetsQueryAdvance,
    documentsQueryAdvance,
    multiMediaQueryAdvance,
    reportingsQueryAdvance,
    myReportsQueryAdvance,
    reportsSharedToMeQueryAdvance,
    foldersQueryAdvance,
    myFoldersQueryAdvance,
    foldersSharedToMeQueryAdvance,
    assetsForMemberQueryAdvance,
    productsForMemberQueryAdvance,
  ]);

  return state;
};

export default useGetDataAdvanceFilter;
