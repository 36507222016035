import React from 'react';

import { useGetProductEnums } from 'hooks';
import { findDimensionEnumDisplay } from 'pages/product-full-view/mappers/mapToEnums';

import * as _ from 'lodash';

const PrimarySizeUOM = (props) => {
  const { params } = props;

  const { productEnums } = useGetProductEnums();
  if (_.isEmpty(productEnums)) {
    return <div></div>;
  }

  const enumDisplay =
    findDimensionEnumDisplay(params?.data?.primarySizeUOM, productEnums) ||
    params?.data?.primarySizeUOM ||
    '';
  return <div>{enumDisplay}</div>;
};

export default PrimarySizeUOM;
