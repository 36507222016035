import React, { useRef } from 'react';

import { Space, Row, Col } from 'antd';
import {
  PlusCircleOutlined,
  StopOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { Form, SectionWrapper } from 'common/components';
import SupplementFormButton from './SupplementFormButton';
import SupplementNutrientForm from './SupplementNutrientForm';
import SupplementIngredientForm from './SupplementIngredientForm';
import SupplementDeleteConfirmation from './SupplementDeleteConfirmation';
import SupplementNutrientItemView from './SupplementNutrientItemView';

import { getNameFieldSupplement } from 'pages/product-full-view/components/product-media-area/supplement-label/utils';

import { SUPPLEMENT_NUTRIENT_NAME } from 'pages/product-full-view/components/product-media-area/nutrition-label/constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';

const SupplementNutrientItem = (props) => {
  const intl = useIntl();
  const form = Form.useFormInstance();

  const prevDataList = useRef();
  const deleteConfirmHandleRef = useRef();

  const { fieldName, data, index, setMode, remove } = props;

  const nutrients = Form.useWatch(fieldName, form) || [];

  const backupData = () => {
    const formValue = form.getFieldsValue([fieldName]);

    prevDataList.current = formValue[fieldName];
  };

  const revertData = () => {
    form.setFieldsValue({ [fieldName]: prevDataList.current });
  };

  const showDeleteConfirm = () => {
    deleteConfirmHandleRef.current?.showModal();
  };

  const onClickEdit = () => {
    //* backup data
    backupData();

    setMode(`edit-${index}`);
  };

  const onClickDelete = () => {
    const shouldShowConfirm = deleteConfirmHandleRef.current?.shouldShowModal;

    if (shouldShowConfirm) return showDeleteConfirm();

    remove && remove(index);
  };

  const onClickCancel = () => {
    revertData();
    setMode('view');
  };

  const onClickUpdate = () => {
    const nameField = getNameFieldSupplement(fieldName);
    form
      .validateFields([
        [fieldName, index, nameField],
        [fieldName, index, 'quantity'],
        [fieldName, index, 'dailyValue'],
      ])
      .then(() => {
        
        const nutrientsMapping = nutrients.map((item) => {
          return {
            ...item,
            quantityUom: !isNil(item.quantity) ? item.quantityUom : null,
          };
        });
        form.setFieldsValue({ [fieldName]: nutrientsMapping });
        setMode('view');
      });
  };

  const renderEditMode = () => {
    const UpdateButton = SupplementFormButton;
    const CancelButton = SupplementFormButton;

    const NutrientForm =
      fieldName === SUPPLEMENT_NUTRIENT_NAME.NUTRIENT
        ? SupplementNutrientForm
        : fieldName === SUPPLEMENT_NUTRIENT_NAME.INGREDIENT
        ? SupplementIngredientForm
        : null;

    return (
      <NutrientForm fieldName={fieldName} namePath={[index]}>
        <Row gutter={4}>
          <Col span={12}>
            <CancelButton
              color='#f5222d'
              icon={<StopOutlined />}
              text={intl.formatMessage(Messages.supplementCancelButton)}
              onClick={onClickCancel}
            />
          </Col>
          <Col span={12}>
            <UpdateButton
              type='primary'
              icon={<PlusCircleOutlined />}
              text={intl.formatMessage(Messages.supplementUpdateButton)}
              onClick={onClickUpdate}
            />
          </Col>
        </Row>
      </NutrientForm>
    );
  };

  const itemType =
    fieldName === SUPPLEMENT_NUTRIENT_NAME.NUTRIENT ? 'nutrient' : 'ingredient';

  return (
    <SectionWrapper
      title={
        !data.isEdit ? (
          <Space>
            <EditOutlined onClick={onClickEdit} />
            <DeleteOutlined onClick={onClickDelete} />
          </Space>
        ) : null
      }
      placement='right'
      style={{ width: '100%', maxWidth: '100%' }}
    >
      {data.isEdit ? (
        renderEditMode()
      ) : (
        <SupplementNutrientItemView item={data} fieldName={fieldName} />
      )}
      <SupplementDeleteConfirmation
        ref={deleteConfirmHandleRef}
        data={data}
        fieldName={fieldName}
        type={itemType}
      />
    </SectionWrapper>
  );
};

export default SupplementNutrientItem;
