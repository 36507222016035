import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import selectorUser from 'redux/user/selectors';

import {
  PERMISSION_MANAGE_QA_SPEC_WORKFLOW,
  PERMISSION_VIEW_QA_SPECIFICATION,
} from 'static/Permission';
import { MEMBER_TYPE_RETAILER } from 'static/Constants';

import { useGetProductFullView } from 'hooks';
import { useCheckIsSuperAdmin } from 'hooks';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import _ from 'lodash';

export const useInitialWorkflowQaSpec = () => {
  const { id: productId } = useParams();

  const { productFull } = useGetProductFullView({ productId });

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const isSuperAdmin = useCheckIsSuperAdmin();

  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const isRetailer =
    userInfo?.member?.memberType?.toLowerCase() ===
    MEMBER_TYPE_RETAILER.toLowerCase();

  const hasPermissionManageQaSpecWorkflow = useCheckPermissions(
    PERMISSION_MANAGE_QA_SPEC_WORKFLOW
  );

  const hasPermissionViewQaSpec = useCheckPermissions(
    PERMISSION_VIEW_QA_SPECIFICATION
  );

  const checkInitialWorkflowQaSpecRibbon = () => {
    if (!productFull) return false;

    // bp hotfix in test: private label of one product
    const isPrivateLabel = productFull?.isPrivateLabel;
    if (!isPrivateLabel) return false;

    if (isSuperAdmin) return true;

    if (
      isRetailer &&
      productFull?.isOwner &&
      hasPermissionManageQaSpecWorkflow &&
      hasPermissionViewQaSpec
    )
      return true;

    return false;
  };

  const checkInitialWorkflowToProductGrid = () => {
    if (selectedItemDetailList?.length <= 0) return false;

    const isAllPrivateLabel = selectedItemDetailList?.every(
      (productItem) => productItem?.isPrivateLabel
    );
    // one product is NOT private label, disable button
    if (!isAllPrivateLabel) return false;

    // const someIsProductOverlay =
    //   selectedItemDetailList?.length > 0 &&
    //   selectedItemDetailList.some(
    //     (productItem) => productItem.isOverlayProduct === true
    //   );
    // // contain overlay product, disable button
    // if (someIsProductOverlay) return false;

    if (isSuperAdmin) return true;

    const isAllOwner = selectedItemDetailList?.every(
      (productItem) => productItem?.isOwner
    );

    if (
      isRetailer &&
      isAllOwner &&
      hasPermissionManageQaSpecWorkflow &&
      hasPermissionViewQaSpec
    )
      return true;
    return false;
  };

  const checkInitialWorkflowToProductFullView = () => {
    if (!_.isEmpty(productFull) && !selectedItemDetailList?.length) {
      const isOwnerProduct = productFull?.isOwner;

      if (!productFull?.isPrivateLabel) return false;

      // if (productFull?.isOverlayProduct) return false;

      if (isSuperAdmin) return true;

      if (
        isRetailer &&
        isOwnerProduct &&
        hasPermissionManageQaSpecWorkflow &&
        hasPermissionViewQaSpec
      )
        return true;
      return false;
    }
  };

  const isInitialWorkflowToProduct = productId
    ? checkInitialWorkflowToProductFullView()
    : checkInitialWorkflowToProductGrid();

  return { isInitialWorkflowToProduct, checkInitialWorkflowQaSpecRibbon };
};
