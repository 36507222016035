export const GET_COMMUNICATION_DETAIL_ITEM = 'GET_COMMUNICATION_DETAIL_ITEM';
export const GET_COMMUNICATION_DETAIL_ITEM_SUCCESS =
  'GET_COMMUNICATION_DETAIL_ITEM_SUCCESS';
export const GET_COMMUNICATION_DETAIL_ITEM_FAIL =
  'GET_COMMUNICATION_DETAIL_ITEM_FAIL';

export const GET_COMMUNICATION_LIST = 'GET_COMMUNICATION_LIST';
export const GET_COMMUNICATION_LIST_SUCCESS = 'GET_COMMUNICATION_LIST_SUCCESS';
export const GET_COMMUNICATION_LIST_FAIL = 'GET_COMMUNICATION_LIST_FAIL';

export const GET_COMMUNICATION_LIST_MORE = 'GET_COMMUNICATION_LIST_MORE';
export const GET_COMMUNICATION_LIST_MORE_SUCCESS =
  'GET_COMMUNICATION_LIST_MORE_SUCCESS';
export const GET_COMMUNICATION_LIST_MORE_FAIL =
  'GET_COMMUNICATION_LIST_MORE_FAIL';

export const TOGGLE_CHAT_FULL_VIEW = 'TOGGLE_CHAT_FULL_VIEW';
export const GET_THREAD_LIST_FOR_SUPPORTER = 'GET_THREAD_LIST_FOR_SUPPORTER';
export const GET_THREAD_LIST_FOR_SUPPORTER_SUCCESS =
  'GET_THREAD_LIST_FOR_SUPPORTER_SUCCESS';
export const GET_THREAD_LIST_FOR_SUPPORTER_FAIL =
  'GET_THREAD_LIST_FOR_SUPPORTER_FAIL';
export const GET_CHAT_CONTENT_DETAIL = 'GET_CHAT_CONTENT_DETAIL';
export const GET_CHAT_CONTENT_DETAIL_SUCCESS =
  'GET_CHAT_CONTENT_DETAIL_SUCCESS';
export const GET_CHAT_CONTENT_DETAIL_FAIL = 'GET_CHAT_CONTENT_DETAIL_FAIL';
export const MINIMIZE_CHAT = 'MINIMIZE_CHAT';
export const TOGGLE_CHAT_BOX = 'TOGGLE_CHAT_BOX';
export const MINIMIZE_CHAT_THREAD = 'MINIMIZE_CHAT_THREAD';

export const SHOW_NEW_CHAT_WITH_USER = 'SHOW_NEW_CHAT_WITH_USER';
export const SHOW_NEW_CHAT_WITH_COMPANY = 'SHOW_NEW_CHAT_WITH_COMPANY';
export const GET_THREAD_MESSAGES = 'GET_THREAD_MESSAGES';
export const GET_THREAD_MESSAGES_SUCCESS = 'GET_THREAD_MESSAGES_SUCCESS';
export const GET_THREAD_MESSAGES_FAIL = 'GET_THREAD_MESSAGES_FAIL';

export const GET_THREAD_INFO = 'GET_THREAD_INFO';
export const GET_THREAD_INFO_SUCCESS = 'GET_THREAD_INFO_SUCCESS';
export const GET_THREAD_INFO_ERROR = 'GET_THREAD_INFO_ERROR';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';

export const RECEIVE_SENT_MESSAGE_SAME_ACCOUNT =
  'RECEIVE_SENT_MESSAGE_SAME_ACCOUNT';

export const SAVE_ACCOUNT_CHAT = 'SAVE_ACCOUNT_CHAT';

export const TOGGLE_NEW_MESSAGE = 'TOGGLE_NEW_MESSAGE';
export const SET_NEW_MSG = 'SET_NEW_MSG';
export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
export const SET_CLEAR_UNREAD_MESSAGES = 'SET_CLEAR_UNREAD_MESSAGES';

export const FILTER_NEW_MSG_IN_THREAD_MSG = 'FILTER_NEW_MSG_IN_THREAD_MSG';

export const UPDATE_COMMUNICATION_THREAD_LIST =
  'UPDATE_COMMUNICATION_THREAD_LIST';

export const RESET_STATUS_THREAD_INFO = 'RESET_STATUS_THREAD_INFO';
export const REMOVE_THREAD = 'REMOVE_THREAD';
export const RESET_STATUS_MSG_THREAD = 'RESET_STATUS_MSG_THREAD';

export const SAVE_ACTIVE_THREAD_INFO = 'SAVE_ACTIVE_THREAD_INFO';

export const SAVE_INDEX_CHAT_FILE = 'SAVE_INDEX_CHAT_FILE';

export const SELECT_SEARCH_RESULT = 'SELECT_SEARCH_RESULT';
export const UPDATE_FINISH_LOAD_DOWN = 'UPDATE_FINISH_LOAD_DOWN ';
export const UPDATE_SEARCH_RESULT_POSITION = 'UPDATE_SEARCH_RESULT_POSITION';
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
export const UPDATE_SHOW_THREAD_WARNING_MODAL =
  'UPDATE_SHOW_THREAD_WARNING_MODAL';

export const UPDATE_THREAD_INFO_ERROR = 'UPDATE_THREAD_INFO_ERROR';

//? force chat thread scroll to bottom
export const SCROLL_CHAT_THREAD_BOTTOM = 'SCROLL_CHAT_THREAD_BOTTOM';

//? toggle chat spinning
export const TOGGLE_CHAT_SPINNING = 'TOGGLE_CHAT_SPINNING';

//? set chat view - second condition to check view type (thread list or thread messages)
export const RESET_CHAT_VIEW = 'RESET_CHAT_VIEW';
export const SET_CHAT_VIEW = 'SET_CHAT_VIEW';

//? set is focus chat to know unread or read
export const SET_IS_FOCUS_CHAT = 'SET_IS_FOCUS_CHAT';

export const RESET_THREAD_INFO = 'RESET_THREAD_INFO';
