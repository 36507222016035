import _ from 'lodash';
import moment from 'moment';
import ct from 'countries-and-timezones';
// require('date-time-format-timezone');
const timezones = ct.getAllTimezones();

/**
 * Example:
 * {
 *  name: 'Asia/Tel_Aviv',
 *  country: 'IL',
 *  utcOffset: 120,
 *  utcOffsetStr: '+02:00',
 *  dstOffset: 180,
 *  dstOffsetStr: '+03:00',
 *  aliasOf: 'Asia/Jerusalem'
 * }
 */
export function getTimezones() {
  const country_utc = [];
  for (let [, value] of Object.entries(timezones)) {
    country_utc.push(value);
  }

  return country_utc;
}

export function getUtcTzLabelValue(labelField, valueField, withName) {
  labelField = labelField || 'name';
  valueField = valueField || 'value';
  const country_utc = [];
  for (let [, value] of Object.entries(timezones)) {
    // eslint-disable-next-line
    const found = country_utc.some(
      (el) => el.label == `UTC ${value[labelField]}`
    );
    if (!found) {
      let l = `UTC ${value[labelField]}`;
      if (withName) l += ` (${value['name']})`;
      country_utc.push({
        label: l,
        value: value[valueField],
      });
    }
  }

  let sorted_country_utc = _.sortBy(country_utc, 'label');
  return sorted_country_utc;
}

export function getLocaleTime(dateValue, locale, options) {
  return new Intl.DateTimeFormat(locale, options).format(dateValue);
}

export const utcTz = getTimezones().map((t) => `${t.name} ${t.utcOffsetStr}`);

export const checkUtcIsoFormat = (str) => {
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(str))
    return { iso: true, utc: true };

  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str))
    return { iso: true, utc: false };

  return { iso: false, utc: false };
};

export const addUtcForIsoString = (str) => {
  const { iso, utc } = checkUtcIsoFormat(str);
  if (iso && !utc) return str + 'Z';
  if (iso && utc) return str;

  return 'invalid ISO';
};

export const disabledPreviousDays = (current) => {
  return current && current < moment().startOf('day');
};
