import { useLayoutEffect, useState, useEffect, useRef } from 'react';

import { sleep } from 'utils/delay';

export const useResize = (idOrElement, refresh) => {
  const [size, setSize] = useState([0, 0]);

  const refElement = useRef();

  const addResizeEvent = () => {
    window.addEventListener('resize', () => updateSize(refElement.current));
  };

  const updateSize = () => {
    if (refElement.current) {
      const element = refElement.current;

      setSize([element.offsetWidth, element.offsetHeight]);
    }
  };

  const updateElement = async (idOrElement) => {
    await sleep(1000);
    if (idOrElement) {
      const $element =
        typeof idOrElement === 'string'
          ? document.getElementById(idOrElement)
          : idOrElement?.nodeType
          ? idOrElement
          : idOrElement?.current;

      if ($element) {
        refElement.current = $element;
        updateSize();
        addResizeEvent();
      }
    }
  };

  useLayoutEffect(() => {
    return () => window.removeEventListener('resize', () => updateSize());
  }, []);

  useEffect(() => {
    updateElement(idOrElement);
  }, [idOrElement]);

  useEffect(() => {
    if (refresh === true) {
      updateElement(idOrElement);
    }
  }, [refresh]);

  return size;
};

export const useChangeSize = (selector) => {
  const [size, setSize] = useState([0, 0]);

  const refElement = useRef();

  const onChangeSize = () => {
    const $element = refElement.current;
    setSize([$element.offsetWidth, $element.offsetHeight]);
  };

  const listenToResizing = () => {
    if (!refElement.current) return;
    new ResizeObserver(onChangeSize).observe(refElement.current);
  };

  const updateElement = async () => {
    await sleep(1500);
    refElement.current = document.querySelector(selector);
    listenToResizing();
  };

  useEffect(() => {
    if (!selector) return;
    updateElement();
  }, [selector]);

  return size;
};
