import React from 'react';
import Messages from 'i18n/messages/maintenance';
import { LogoutOutlined } from '@ant-design/icons';
import { forwardTo } from 'utils/common/route';
import RibbonButton from 'common/components/button/RibbonButton';

const BackToPost = () => {
  return (
    <RibbonButton
      icon={<LogoutOutlined style={{ transform: 'rotate(180deg)' }} />}
      label={Messages.backToPost}
      onClick={() => forwardTo(`/maintenance/help/post`)}
    />
  );
};

export default BackToPost;
