import React from 'react';
import { Modal } from 'antd';
import './DialogFunction.less';

import { ErrorIcon, InfoIcon, SuccessIcon, WarnIcon } from './DialogIcon';

import classnames from 'classnames';

function dialogFunction(props) {
  const { type, className } = props;

  return Modal.confirm({
    ...props,
    className: classnames('dialog-modal', className),
    icon:
      type === 'warn' ? (
        <WarnIcon />
      ) : type === 'error' ? (
        <ErrorIcon />
      ) : type === 'success' ? (
        <SuccessIcon />
      ) : (
        <InfoIcon />
      ),
    cancelButtonProps: {
      ...props.cancelButtonProps,
      className: 'dialog-cancel-button',
    },
    okButtonProps: { ...props.okButtonProps, className: 'dialog-ok-button' },
  });
}
dialogFunction.defaultProps = {
  okText: 'OK',
  cancelText: 'Cancel',
  type: 'info',
};

export default dialogFunction;

export const customFooterDialogFunction = ({ ...props }) => {
  const modal = dialogFunction({
    ...props,
    className: 'custom-dialog-function',
  });

  return modal;
};
