import React from 'react';
import SeparatorBar from './SeparatorBar';

function NutrientSideBySide({
  label,
  gram,
  percent,
  showDot,
  hideBar,
  indentBar,
}) {
  return (
    <>
      <div className='nutrition-facts__vitamin'>
        {showDot && <div>•</div>}
        <div>
          {label} {gram} {percent === 0 || percent > 0 ? `${percent}%` : null}
        </div>
      </div>
      {hideBar ? null : <SeparatorBar indentBar={indentBar || 0} />}
    </>
  );
}

export default NutrientSideBySide;
