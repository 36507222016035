export const GET_LIST_SUPPLIER = '/api/member-manager/get-supplier-management';

export const GET_SUPPLIER_PRODUCT_LIST =
  '/api/member-manager/get-supplier-product-grid';

export const ASSIGN_SUPPLIER = '/api/member-manager/assign-supplier';

export const ASSIGN_SUPPLIER_PIM_ACTION = '/api/pim/assign-supplier';

export const GET_ALL_SUPPLIERS = '/api/member-manager/get-all-supplier';

export const GET_SUPPLIER_DETAIL = '/api/member-manager/get-supplier-detail';

export const EDIT_SUPPLIER = '/api/member-manager/edit-supplier';

export const REMOVE_SUPPLIER = '/api/member-manager/remove-supplier';
