import React from 'react';
import { Col, Row } from 'antd';

import { useGetBasicUomEnum } from 'hooks';
import { getUomTypesAbbreviations } from '../constants';

import NutrientRowNew from '../NutrientRowNew';
import { SnapshotHighlightText } from '../WrapperNutrient';
import SeparatorBar from 'pages/product-full-view/components/product-media-area/nutrition-label/components/SeparatorBar';
import NutrientSideBySide from 'pages/product-full-view/components/product-media-area/nutrition-label/components/NutrientSideBySide';

function VerticalLabelWithMicronutrient({ property, nutrients, snapshotValue }) {
  const { basicUomProperties } = useGetBasicUomEnum();

  return (
    <div class='nutrition-facts scroller'>
      <h1 className='nutrition-facts__label'>Nutrition Facts</h1>
      <SeparatorBar />
      <div className='nutrition-facts__servingsize'>
        {Boolean(property?.servingsPerContainer) && (
          <SnapshotHighlightText
            label={['servingsPerContainer']}
            currentValue={property}
            snapshotValue={snapshotValue}
          >
            <div className='nutrition-facts__servings-per-container'>
              {property?.servingsPerContainer} serving per container
            </div>
          </SnapshotHighlightText>
        )}
        <SnapshotHighlightText
          label={[
            'servingSizeDescription',
            'servingSize',
            'servingSizeMeasurement',
          ]}
          currentValue={property}
          snapshotValue={snapshotValue}
        >
          <div className='nutrition-facts__servingsize-bold'>
            <div>Serving size</div>
            <div>
              {property?.servingSizeDescription}{' '}
              {property?.servingSize &&
                `(${property?.servingSize} ${getUomTypesAbbreviations(
                  basicUomProperties,
                  property?.servingSizeMeasurement
                )})`}
            </div>
          </div>
        </SnapshotHighlightText>
      </div>
      <SeparatorBar height={7} />
      <SnapshotHighlightText
        label={['calories']}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        <div className='nutrition-facts__calories-container'>
          <div>
            <div className='nutrition-facts__calories'>Amount Per Serving</div>
            <div className='nutrition-facts__calories_new-calories'>
              Calories
            </div>
          </div>

          <div className='nutrition-facts__calories_numeric'>
            {property?.calories}
          </div>
        </div>
      </SnapshotHighlightText>
      <SeparatorBar height={3} />
      <div className='nutrition-facts__dv-heading'>% Daily Value*</div>
      <Row>
        {nutrients?.map((nutrient, index) => {
          const { isStepByStep } = nutrient;
          return (
            <Col span={isStepByStep ? 12 : 24} key={index}>
              {isStepByStep ? (
                <NutrientSideBySide index={index} nutrient={nutrient} />
              ) : (
                <NutrientRowNew nutrient={nutrient} />
              )}
            </Col>
          );
        })}
      </Row>
      <SeparatorBar height={3} />
      <SnapshotHighlightText
        label={['fdaLabelFootNote']}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        <div className='nutrition-facts__footnote'>
          <span>*</span>
          {property?.fdaLabelFootNote}
        </div>
      </SnapshotHighlightText>
    </div>
  );
}

export default VerticalLabelWithMicronutrient;
