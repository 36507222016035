import React, { useEffect, memo, useState } from 'react';
import { Steps, Row, Col, Button, Skeleton } from 'antd';
// component
import { Form, StyledModal, CustomNotification } from 'common/components';
// hooks
import {
  useGetQasRequiredFields,
  useCreateQasRequiredFields,
  useEditQasRequiredFields,
} from 'hooks/qas-require-field/useQasRequireField';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useGetAllProductFields } from 'hooks/qas-require-field/useQasRequireField';
import { FormQasTargetMember, SectionQasRequireField } from './';

import { difference, isArray } from 'lodash';

import './ModalFormQasRequireField.less';

const selectedItemsDefine = ['init', undefined, Array];

const ModalFormQasRequireField = (props) => {
  const { isEdit, id, isModalOpen, setIsModalOpen } = props;
  const [formInstance] = Form.useForm();
  const reloadPage = useDispatchReloadPage();
  const createQasRequiredFields = useCreateQasRequiredFields();
  const editQasRequiredFields = useEditQasRequiredFields();

  const { data, isLoading } = useGetQasRequiredFields(id);
  const { data: productFieldGroups } = useGetAllProductFields();

  const [step, setStep] = useState(0);
  const [selectedItems, setSelectedItems] = useState();
  const [requiredProperties, setRequiredProperties] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [formData, setFormData] = useState('');

  const productType = Form.useWatch('productType', formInstance);

  useEffect(() => {
    if ((isEdit, data)) {
      formInstance.setFieldsValue({
        productType: data?.productType,
        searchText: data?.targetMember,
      });
      setStep(1);
      setSearchText(data?.targetMember);
      setRequiredProperties(data?.requiredFields);
      setSelectedItems([
        { companyId: data?.targetMemberId, companyName: data?.targetMember },
      ]);
      setFormData({
        productType: data?.productType,
        searchText: data?.targetMember,
      });
    } else {
      formInstance.resetFields();
    }
  }, [isEdit, data]);

  useEffect(() => {
    if (requiredProperties?.length === 0 || !isArray(productFieldGroups)) {
      return;
    }
    let newProductFieldGroups = [];
    let fieldsByProductType = [];
    let fieldsByMemberId = [];
    let newFieldsRequire = [...requiredProperties];

    if (productType === 'HBC / OTC' || productType === 'Non-Food') {
      newProductFieldGroups = productFieldGroups.filter(
        (val) => val?.groupName === 'Ingredients'
      );
    }
    if (productType === 'Pet') {
      newProductFieldGroups = productFieldGroups.filter(
        (val) => val?.groupName === 'Labeling'
      );
    }
    fieldsByProductType = getAllFields(newProductFieldGroups);
    if (
      selectedItems?.length > 0 &&
      selectedItems[0]?.companyId === process.env.REACT_APP_DOLLAR_GENERAL_ID
    ) {
      const newFields = productFieldGroups.filter(
        (val) => val?.groupName === 'Product Contains'
      );
      fieldsByMemberId = getAllFields(newFields);
    }
    if (fieldsByProductType?.length > 0) {
      newFieldsRequire = difference(newFieldsRequire, fieldsByProductType);
    }
    if (fieldsByMemberId?.length > 0) {
      newFieldsRequire = difference(newFieldsRequire, fieldsByMemberId);
    }
    setRequiredProperties(newFieldsRequire);
  }, [productType, selectedItems]);

  const getAllFields = (tree) => {
    let arrFields = [];
    const handleTreeGetFields = (node) => {
      node?.childGroups?.length > 0 &&
        node.childGroups.map((child) => handleTreeGetFields(child));
      node?.fields?.length > 0 &&
        node.fields.map((child) => {
          arrFields.push(child?.fieldFullPath);
        });
    };
    tree.forEach((val) => {
      handleTreeGetFields(val);
    });
    return arrFields;
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onOk = async () => {
    if (step === 0) {
      await formInstance.validateFields().then(async (value) => {
        setFormData(value);
        setStep(1);
      });
    } else {
      const targetMemberId =
        selectedItems?.length > 0 && selectedItems[0]?.companyId;

      let params = {
        targetMemberId: targetMemberId,
        productType: formData?.productType,
        requiredFields: requiredProperties,
      };
      let title = 'Create';
      let qasMutate = createQasRequiredFields;

      if (isEdit) {
        params.id = id;
        title = 'Edit';
        qasMutate = editQasRequiredFields;
      }

      qasMutate.mutate(params, {
        onSuccess: async (response) => {
          if (response?.isSuccess) {
            CustomNotification.success(`${title} successfully`);
            onCancel();
            reloadPage();
          } else {
            CustomNotification.error(
              response?.message || `${title} failed! Please try!`
            );
          }
        },
        onError: (error) => {
          CustomNotification.error(
            error?.message || `${title} failed! Please try!`
          );
        },
      });
    }
  };

  const disabledBtnOk =
    step === 0 ? selectedItems?.length !== 1 : requiredProperties?.length === 0;

  return (
    <StyledModal
      visible={isModalOpen}
      title={`${isEdit ? 'Update' : 'Create'} QA Specification Required Fields`}
      maskClosable={false}
      onCancel={onCancel}
      width='60vw'
      bodyStyle={{
        height: '80vh',
      }}
      className='qa-spec-require-field__modal'
      footer={
        <Row gutter={[8, 8]}>
          <Col>
            {step === 1 && (
              <Button
                key='cancel'
                onClick={() => {
                  setStep(0);
                }}
              >
                Back
              </Button>
            )}
          </Col>
          <Col flex={1} />
          <Col>
            <Button key='back' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={disabledBtnOk}
              key='shared'
              type='primary'
              onClick={onOk}
            >
              {step === 0 ? 'Next' : isEdit ? 'Update' : 'Create'}
            </Button>
          </Col>
        </Row>
      }
    >
      <Skeleton active loading={isEdit && isLoading}>
        <Steps current={step}>
          <Steps.Step title='Select Target Member' />
          <Steps.Step title='Select Required Properties' />
        </Steps>
        <Row style={{ marginTop: 16 }}>
          <Col span={24}>
            {step === 0 ? (
              <FormQasTargetMember
                isEdit={isEdit}
                formInstance={formInstance}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            ) : (
              <SectionQasRequireField
                isEdit={isEdit}
                formData={formData}
                targetMember={selectedItems}
                requiredProperties={requiredProperties}
                setRequiredProperties={setRequiredProperties}
              />
            )}
          </Col>
        </Row>
      </Skeleton>
    </StyledModal>
  );
};

export default memo(ModalFormQasRequireField);
