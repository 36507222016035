import _ from 'lodash';

export const getFieldFullPath = ({ parentPath, fieldName }) => {
  return `${parentPath ? parentPath + '.' : ''}${fieldName}`;
};

export const filterObjectTypeAttribute = ({ columnData, parentPath }) => {
  const parentPathArr = parentPath.split('.');

  return columnData.filter((colItemData) => {
    const fieldPathArr = colItemData.fieldName.split('.');
    const nextColumnPath = [
      ...parentPathArr,
      fieldPathArr[parentPathArr.length],
    ].join('.');

    const isShowCol = colItemData.fieldName === nextColumnPath;

    const isObject = colItemData.dataType === 'object';

    return isShowCol && isObject;
  });
};

export const filterNoneObjectTypeAttribute = (data) => {
  if (data.length) {
    return;
  }

  const attributeFields = Object.keys(data);

  return attributeFields.filter((field) => {
    const fieldValue = data[field];
    const isObject = typeof fieldValue === 'object';

    return !isObject;
  });
};

export const filterAttributeFieldColumn = ({ parentPath, columnData }) => {
  if (!parentPath) return [];
  const parentPathArr = parentPath.split('.');

  return columnData.filter((colItem) => {
    const fieldNamePathArr = colItem.fieldName.split('.');
    const nextPath = [
      ...parentPathArr,
      fieldNamePathArr[parentPathArr.length],
    ].join('.');

    return colItem.fieldName === nextPath && colItem.dataType !== 'object';
  });
};

export const checkRenderDetail = (column) => {
  if (!column) return;

  return column.dataType === 'object' && column?.isMultiple;
};

export const calculateDetailRowHeight = (data) => {
  if (!data) return;
  //* data is object, it is checked before in checkRenderDetail()

  const objectTypeAttributes = filterObjectTypeAttribute(data);

  let detailRowHeight = 0;

  detailRowHeight = objectTypeAttributes.reduce((accumulator, currentField) => {
    const fieldData = data[currentField];

    accumulator = calculateDetailContentHeight({
      detailRowHeight: accumulator,
      data: fieldData,
    });

    return accumulator;
  }, detailRowHeight);

  return detailRowHeight + 20; //* offset bottom
};

const calculateDetailContentHeight = ({ detailRowHeight, data }) => {
  detailRowHeight = detailRowHeight + 30; //* panel header height

  //* single row table
  const noneObjectTypeAttributes = filterNoneObjectTypeAttribute(data);
  const hasTable = noneObjectTypeAttributes?.length;

  if (hasTable) detailRowHeight = detailRowHeight + 80;

  //* collapse
  const objectTypeAttributes = filterObjectTypeAttribute(data);
  const hasCollapse = objectTypeAttributes?.length;

  if (hasCollapse)
    detailRowHeight = objectTypeAttributes.reduce(
      (accumulator, currentField) => {
        const fieldData = data[currentField];

        accumulator = calculateDetailContentHeight({
          detailRowHeight: accumulator,
          data: fieldData,
        });

        return accumulator;
      },
      detailRowHeight
    );

  //* multiple row table
  const numberOfRows = countNumberOfRow(data);

  if (numberOfRows > 0)
    detailRowHeight = detailRowHeight + 40 + 25 * numberOfRows;

  return detailRowHeight;
};

const countNumberOfRow = (data) => {
  if (!data.length) return 0;

  const isObjectArray = typeof data[0] === 'object';

  if (isObjectArray) return data.length;

  return 0;
};

export const checkShowColumns = ({ fieldName, columnData }) => {
  return columnData.some((colDataItem) => {
    return colDataItem.fieldName === fieldName;
  });
};

export const getNestedGridData = ({ data, fieldName }) => {
  return _.get(data, fieldName);
};

export const getNestedGridDataFromFieldFullPath = ({
  data,
  parentPath,
  fieldFullPath,
}) => {
  const keyData = fieldFullPath.replace(parentPath + '.', '');

  return _.get(data, keyData);
};

export const getColumnRenderer = ({ columnData, isArray }) => {
  const cellRendererCollection = {
    boolean: 'checkboxCell',
    datetime: 'datetimeCell',
  };

  return isArray
    ? 'arrayCell'
    : columnData?.listName
    ? 'listNameCell'
    : cellRendererCollection[columnData?.dataType];
};
