import { useState } from 'react';

import SnapshotCellWrapper from './SnapshotCellWrapper';
import { Button } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { CustomNotification } from 'common/components';
import PreviewableModal from 'common/components/previewable-modal/PreviewableModal';

import { checkShowPdfPreview } from 'utils/checkShowPdfPreview';
import * as api from 'config/axios';
import { isEqual } from 'lodash';

const CellActionRenderSnapshotWrapper = (props) => {
  const { children, isHighlightChange, snapshotValueRender, ...params } = props;
  const { context, rowIndex, data } = params;
  const { snapshotGridValues } = context || {};

  const snapshotData = snapshotGridValues?.[rowIndex];

  const defaultIsHighlightChange = !isEqual(
    snapshotData?.linkUrl,
    data?.linkUrl
  );

  return (
    <SnapshotCellWrapper
      isHighlightChange={isHighlightChange ?? defaultIsHighlightChange}
      snapshotValueRender={
        snapshotValueRender ?? (
          <CellActionSnapshot snapshotData={snapshotData} />
        )
      }
    >
      {children && children}
    </SnapshotCellWrapper>
  );
};

const CellActionSnapshot = (props) => {
  const { snapshotData } = props;
  const [visible, setVisible] = useState(false);
  const [rowPreview, setRowPreview] = useState();

  const { linkPreview, extensionFile } = checkShowPdfPreview(
    rowPreview?.pdfPreview,
    rowPreview?.linkUrl,
    rowPreview?.fileType
  );

  return (
    <>
      <Button
        type='link'
        icon={<EyeOutlined />}
        onClick={() => {
          setRowPreview(snapshotData);
          setVisible(true);
        }}
        disabled={!snapshotData}
      />
      <Button
        type='link'
        icon={<DownloadOutlined />}
        onClick={() => {
          const downloadUrl = snapshotData?.linkUrl;

          if (!downloadUrl) {
            CustomNotification.error('Could not download file!');
          }
          api.sendDownload({
            url: downloadUrl,
          });
        }}
        disabled={!snapshotData}
      />
      {visible && (
        <PreviewableModal
          id={rowPreview?.linkId}
          link={linkPreview}
          title={rowPreview?.certificate || rowPreview?.documentName}
          visible={visible}
          fileName={rowPreview?.certificate || rowPreview?.documentName}
          cancelModal={() => setVisible(false)}
          downloadLink={rowPreview?.linkUrl}
          isDownload={false}
          fileType={extensionFile}
          fileSize={rowPreview?.fileSize}
          isDocument={true}
        />
      )}
    </>
  );
};

export default CellActionRenderSnapshotWrapper;
