import React from 'react';

import { Col } from 'antd';

const Section = ({ children, ...props }) => {
  return (
    <Col span={12} {...props}>
      {children}
    </Col>
  );
};

export default Section;
