import React, { useState } from 'react';
import { List, Avatar, Typography, Spin } from 'antd';
import {
  PaperClipOutlined,
  LoadingOutlined,
  DownOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { exportToChatTime } from 'utils/formatDate';
import { useSelector, useDispatch } from 'react-redux';
import { WithLoading } from 'common/components';

import useGetThreadAndMsgInfo from '../../controller/hooks';
import FlipMove from 'react-flip-move';
import userSelectors from 'redux/user/selectors';
import InfiniteScroll from 'react-infinite-scroller';
import { formatMessage } from 'utils/string';

import * as globalActions from 'redux/global/actions';
import * as emailActions from '../../controller/actions';

import './Thread.less';

function EmailThreadList({
  emailThreadListData,
  emailThreadListLoading,
  emailThreadListHasMore,
  setThreadId,
  threadId,
  handleLoadMoreEmail,
  emailThreadInfoLoading,
  handleResetNewEmail,
}) {
  const { Text } = Typography;

  const dispatch = useDispatch();

  const userID = useSelector(userSelectors.makeSelectUserId());

  const [userId, setUserId] = useState(null);

  const handleClickEmailItem = (emailInfo) => {
    handleResetNewEmail();
    const { threadId, userId, readStatus } = emailInfo;

    const currentUserId = userId ? userId : `U-${userID}`;

    // Make Thread as read
    const payload = { threadId, userId: currentUserId };
    if (!readStatus) {
      dispatch(globalActions.makeAThreadAsRead(payload));
    }
    dispatch(emailActions.saveActiveEmailThreadInfo(emailInfo));
    dispatch(emailActions.saveAccountMail(emailInfo));
    setThreadId(threadId);
    setUserId(currentUserId);
  };

  const params = {
    pageIndex: 1,
    pageSize: 20,
    threadId,
    userId,
  };

  useGetThreadAndMsgInfo(params);

  const handleLoadMore = (page) => {
    handleLoadMoreEmail(page);
  };

  const handleFormatLatestText = (text) => {
    if (text?.lastMessageType === 2) {
      return (
        <span>
          <IdcardOutlined /> {text?.lastMessageText}
        </span>
      );
    } else {
      return formatMessage(text?.lastMessageText).replace(/<[^>]*>?/gm, '');
    }
  };

  return (
    <>
      {emailThreadInfoLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <WithLoading
            loading={emailThreadInfoLoading}
            indicator={<LoadingOutlined />}
          />
        </div>
      ) : (
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={handleLoadMore}
          hasMore={emailThreadListHasMore && !emailThreadListLoading}
          useWindow={false}
        >
          <List className='email-list' itemLayout='horizontal'>
            <FlipMove
              staggerDurationBy='30'
              duration={500}
              enterAnimation={'none'}
              leaveAnimation={'none'}
              typeName='div'
            >
              {emailThreadListData?.length > 0 &&
                emailThreadListData?.map((item) => (
                  <div key={item?.uniqueId}>
                    <List.Item
                      onClick={() => handleClickEmailItem(item)}
                      actions={[
                        <div className='email-list__item-status'>
                          <div className='email-list__item-last-time'>
                            {item?.hasAttachment && <PaperClipOutlined />}
                            <span style={{ paddingLeft: '3px' }}>
                              {exportToChatTime(item?.lastMessageTime)}
                            </span>
                          </div>
                          {!item?.readStatus && (
                            <div className='email-list__notify'>
                              <div className='email-list__item-dot' />
                              <p className='email-list__item-unread'>
                                unread mail
                              </p>
                            </div>
                          )}
                        </div>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <div className='email-list__avatar-wrapper'>
                            <Avatar src={item?.avatarUrl} size={38}>
                              {item?.name?.charAt(0)}
                            </Avatar>
                          </div>
                        }
                        title={<Text>{item?.emailSender}</Text>}
                        description={
                          <div>
                            <p style={styles.title}>{item?.name}</p>
                            <span style={styles.message}>
                              {handleFormatLatestText(item)}
                            </span>
                          </div>
                        }
                      ></List.Item.Meta>
                    </List.Item>
                  </div>
                ))}
            </FlipMove>

            <div className='communication-list__scroll-container'>
              {emailThreadListLoading && <Spin />}
              {emailThreadListHasMore && !emailThreadListHasMore && (
                <>
                  <p>Scroll to show more</p>
                  <DownOutlined
                    style={{ fontSize: '15px', color: '#1d3557' }}
                  />
                </>
              )}
            </div>
          </List>
        </InfiniteScroll>
      )}
    </>
  );
}

export default React.memo(EmailThreadList);

const styles = {
  title: {
    color: '#000',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
  },
  message: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
    fontWeight: 'bold',
    fontSize: '11px',
  },
};
