import produce from 'immer';

import moment from 'moment';

import { NUMERIC_TYPE } from 'static/Constants';

export const CONDITION_TYPES = [
  { label: 'Contains', value: 'Contains' },
  { label: 'Equal', value: 'Equal' },
  { label: 'Not Equal', value: 'NotEqual' },
  { label: 'Is Null', value: 'IsNull' },
  { label: 'Is Not Null', value: 'IsNotNull' },
  { label: 'Less Than', value: 'LessThan' },
  { label: 'Less Than Or Equal', value: 'LessThanOrEqual' },
  { label: 'Greater Than', value: 'GreaterThan' },
  { label: 'Greater Than Or Equal', value: 'GreaterThanOrEqual' },
  { label: 'Between', value: 'Between' },
];

export const DATE_AND_NUMERIC_CONDITION_TYPES = [
  'GreaterThanOrEqual',
  'GreaterThan',
  'LessThanOrEqual',
  'LessThan',
  'Equal',
  'NotEqual',
  'IsNull',
  'IsNotNull',
  'Between',
];

export const STRING_CONDITION_TYPES = [
  'Contains',
  'Equal',
  'NotEqual',
  'IsNull',
  'IsNotNull',
];

export const OTHER_FIELD_CONDITION_TYPES = [
  'Contains',
  'Equal',
  'NotEqual',
  'IsNull',
  'IsNotNull',
  'Between',
];

export const BOOL_CONDITION_TYPES = [
  'Equal',
  'NotEqual',
  'IsNull',
  'IsNotNull',
];

export const COMPARE_TYPES = [
  { label: 'Other Field', value: 'OtherField' },
  { label: 'Value', value: 'Value' },
];

export const SET_TYPES = [
  { label: 'Concatenation', value: 'StringConcate' },
  { label: 'Addition', value: '+' },
  { label: 'Subtraction', value: '-' },
  { label: 'Multiplication ', value: '*' },
  { label: 'Division', value: '/' },
  // { label: 'Value', value: 'value' },
  { label: 'Other Field', value: 'OtherField' },
];

export const NUMBER_OPERATORS = ['+', '-', '*', '/'];

export const getConditionTypes = (dataType) => {
  let result = CONDITION_TYPES;

  if (!dataType) return [];

  if (NUMERIC_TYPE.includes(dataType)) {
    result = CONDITION_TYPES.filter((type) =>
      DATE_AND_NUMERIC_CONDITION_TYPES.includes(type.value)
    );
  } else if (dataType === 'datetime') {
    result = CONDITION_TYPES.filter((type) =>
      DATE_AND_NUMERIC_CONDITION_TYPES.includes(type.value)
    );
  } else if (dataType === 'boolean') {
    result = CONDITION_TYPES.filter((type) =>
      BOOL_CONDITION_TYPES.includes(type.value)
    );
  } else {
    result = CONDITION_TYPES.filter((type) =>
      STRING_CONDITION_TYPES.includes(type.value)
    );
  }

  return result;
};

export const getNewSetParameters = (data, index, field, value) => {
  const result = produce(data, (draftFile) => {
    draftFile[index][`${field}`] = value;
  });

  return result;
};

export const getSelectedParameter = (parameters, index) => {
  if (index !== null || index !== undefined) {
    if (parameters) {
      return parameters[index];
    }
  }
  return {};
};

export const getValueInput = (param) => {
  if (param?.setByFieldPackageLevel && param?.setByFieldPath) {
    return `[${param.setByFieldPackageLevel}].${param.setByFieldPath}`;
  }
  return '';
};

export const checkDisabledButton = (parameters, type) => {
  if (type === 'OtherField') {
    if (parameters.length > 0) return true;
  }
  return false;
};

export const getParameterTypes = (type) => {
  if (type === 'OtherField') {
    return COMPARE_TYPES.filter((type) => type.value === 'OtherField');
  }
  return COMPARE_TYPES;
};

export const getValueInputCompareField = (packageLevel, fieldPath) => {
  if (packageLevel && fieldPath) {
    return `[${packageLevel}].${fieldPath}`;
  }
  return '';
};

export const getCompareValueParam = (
  dataType,
  condition,
  compareToValue,
  compareToOtherValue
) => {
  if (dataType === 'datetime') {
    if (condition === 'Between') {
      return {
        value: compareToValue ? moment(compareToValue).toISOString() : '',
        otherValue: compareToOtherValue
          ? moment(compareToOtherValue).toISOString()
          : '',
      };
    } else {
      return {
        value: compareToValue ? moment(compareToValue).toISOString() : '',
      };
    }
  } else {
    if (condition === 'Between') {
      return { value: compareToValue, otherValue: compareToOtherValue };
    } else {
      return {
        value: compareToValue,
      };
    }
  }
};

export const checkShowErrorFormStatement = (
  compareType,
  compareToValue,
  dataType,
  condition
) => {
  if (['IsNull', 'IsNotNull'].includes(condition)) {
    return false;
  }

  return (
    !compareToValue && compareType !== 'OtherField' && dataType !== 'boolean'
  );
};

export const checkRenderSetType = (
  compareToValue,
  compareToFieldPath,
  dataType,
  condition,
  statementType
) => {
  if (statementType === 'Else') {
    return true;
  }

  if (['IsNull', 'IsNotNull'].includes(condition)) {
    return true;
  }

  return compareToValue || compareToFieldPath || dataType === 'boolean';
};

export const checkRenderCondition = (compareToFieldPath, statementType) => {
  if (statementType === 'Else') {
    return false;
  }
  if (compareToFieldPath) {
    return true;
  }
  return false;
};

export const checkDisabledTypeStatement = (
  rowData,
  rowSelected,
  modeStatement
) => {
  const countElseStatement = getNumberOfElseStatement(rowData);

  if (rowData?.length === 0) return true;

  if (modeStatement === 'edit' && rowSelected === 0) return true;

  if (countElseStatement >= 1) return true;

  return false;
};

const getNumberOfElseStatement = (rowData) => {
  let count = 0;

  if (rowData?.length > 0) {
    rowData.forEach((data) => {
      if (data.statementType === 'Else') {
        count++;
      }
    });
  }
  return count;
};
