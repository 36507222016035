import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectTicketingGroup = (state) => state.ticketingGroup || initialState;

const makeSelectSelectedGroup = () =>
  createSelector(
    selectTicketingGroup,
    (ticketingGroup) => ticketingGroup.selectedGroup
  );

const makeSelectMemberId = () =>
  createSelector(
    selectTicketingGroup,
    (ticketingGroup) => ticketingGroup.memberId
  );

const makeSelectTicketGroupList = () =>
  createSelector(
    selectTicketingGroup,
    (ticketingGroup) => ticketingGroup.ticketGroupList
  );

export {
  selectTicketingGroup,
  makeSelectSelectedGroup,
  makeSelectMemberId,
  makeSelectTicketGroupList,
};
