import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';

import {
  Row,
  Input,
  Skeleton,
  Typography,
  Pagination,
  Empty,
  Modal,
  notification,
} from 'antd';

import ItemMap from './ItemMap';
import MarkerMap from './MarkerMap';
import useCustomScroll from 'hooks/useCustomScroll';

import * as api from 'config/axios';

import './GoogleMapViewModal.less';
import { debounce } from 'lodash';

import * as constants from 'static/Constants';

const GoogleMapViewModal = (props) => {
  const {
    currentFilter,
    officeList,
    loadingOffice,
    fetchOffice,
    totalOffice,
    memberId,
  } = props;
  const [loading, setLoading] = useState(false);
  const [placesData, setPlacesData] = useState([]);
  const [placesSearch, setPlacesSearch] = useState([]);
  const [places, setPlaces] = useState([]);
  const [mapId, setMapId] = useState(false);
  const [hoverMapId, setHoverMapId] = useState(false);
  const [search, setSearch] = useState('');
  const [visibleMap, setVisibleMap] = useState(false);
  const [pageCurrent, setPageCurrent] = useState(1);

  const [centerMap, setCenterMap] = useState([34.0522, -118.2437]);
  const [zoomMap, setZoomMap] = useState(0);

  const addressRef = useRef(null);

  useCustomScroll('.google-map-view--with-scroller');
  useEffect(() => {
    setLoading(true);
    // api
    //   .sendGet('/api/khoidev/google-map-data')
    //   .then((response) => {
    //     response.results.forEach((result) => {
    //       result.show = false; // eslint-disable-line no-param-reassign
    //       result.hover = false;
    //     });
    //     setPlacesData(response.results);
    //     setPlacesSearch(response.results);
    //     setPlaces(response.results.slice(0, 10));
    //     setLoading(false);
    //   })
    //   .catch((error) => {});
    const officePlace = officeList?.map((office, id) => {
      return {
        ...office,
        show: false,
        hover: false,
      };
    });
    setPlacesData(officePlace);
    setPlacesSearch(officePlace);
    setPlaces(officePlace);

    if (officeList && officeList.length > 0) {
      setCenterMap([
        parseFloat(officeList[0].latitude || 0),
        parseFloat(officeList[0].longitude || 0),
      ]);
    }
    setLoading(false);
  }, [officeList]);

  const onBoundsChange = (center, zoom, bounds, marginBounds) => {
    setZoomMap(zoom);
  };

  const onChildClickCallback = (key, childProps) => {
    handleShowInfoAndSelectedItem(key, true);
  };

  const onClickItemMap = (item) => {
    handleShowInfoAndSelectedItem(item.id);
    setVisibleMap(true);
    // if (item.latitude && item.longitude) {

    // } else
    //   notification.error({
    //     message: 'This contact can not display because of lack of map info.',
    //   });
  };

  const onCloseInfoWindow = () => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((val) => {
      val.show = false;
      return val;
    });
    setPlaces(tempPlaces);
    setMapId(false);
  };

  const onMouseEnterItemMap = (item) => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((val) => {
      if (val.id === item.id) {
        val.hover = true;
      } else {
        val.hover = false;
      }
      return val;
    });
    setPlaces(tempPlaces);
  };

  const onMouseLeaveItemMap = (item) => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((val) => {
      val.hover = false;
      return val;
    });
    setPlaces(tempPlaces);
  };
  const handleShowInfoAndSelectedItem = (id, scrollSelected) => {
    let tempPlaces = [...places];
    let indexScroll = 0;
    tempPlaces = tempPlaces.map((val, index) => {
      if (val.id.toString() === id.toString()) {
        setCenterMap([
          parseFloat(val.latitude || 0),
          parseFloat(val.longitude || 0),
        ]);
        indexScroll = index;
        val.show = true;
      } else {
        val.show = false;
      }
      return val;
    });
    setPlaces(tempPlaces);
    setMapId(id);
    if (addressRef.current) addressRef.current.scrollTop = indexScroll * 126;
  };

  const onChangePagination = (page, pageSize) => {
    setPageCurrent(page);
    // let index = page === 1 ? 0 : 11;
    // let indexEnd = index === 0 ? 10 : placesSearch.length;
    // let arrTemp = placesSearch.slice(index, indexEnd);
    // arrTemp.forEach((val) => {
    //   val.show = false;
    //   val.hover = false;
    // });
    // setPlaces(arrTemp);
    setMapId(false);
    if (addressRef.current) addressRef.current.scrollTop = 0;
  };

  const renderItemMap = (item, index) => {
    let selected = false;
    if (item.id.toString() === mapId.toString()) {
      selected = true;
    }
    return (
      <ItemMap
        selected={selected}
        onClickItemMap={onClickItemMap}
        onMouseEnterItemMap={onMouseEnterItemMap}
        onMouseLeaveItemMap={onMouseLeaveItemMap}
        item={item}
        key={index}
        memberId={memberId}
      />
    );
  };

  return (
    <Row
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Modal
        title='Contact Map'
        visible={visibleMap}
        onCancel={() => setVisibleMap(false)}
        className='contact-map-modal'
      >
        <Row className='contact-map-modal__map-wrapper'>
          <GoogleMapReact
            defaultZoom={12}
            center={centerMap}
            bootstrapURLKeys={{
              key: constants.GOOGLE_MAP_API_KEY,
            }}
            onChildClick={onChildClickCallback}
            onBoundsChange={onBoundsChange}
          >
            {places &&
              places.length > 0 &&
              places.map((place) => (
                <MarkerMap
                  key={place.id}
                  lat={place.latitude || 0}
                  lng={place.longitude || 0}
                  show={place.show}
                  hover={place.hover}
                  place={place}
                  style={{ width: 27, height: 43 }}
                  zoomMap={zoomMap}
                  onCloseInfoWindow={onCloseInfoWindow}
                />
              ))}
          </GoogleMapReact>
        </Row>
      </Modal>
      <Row
        style={{
          width: '100%',
        }}
      >
        <Skeleton
          paragraph={{
            rows: 10,
          }}
          active={true}
          loading={loading}
        >
          <Row
            className='google-map-view-modal--with-scroller'
            style={{
              width: '100%',
              padding: '0 8px',
              display: 'block',
            }}
            ref={addressRef}
          >
            {places && places.length > 0 ? (
              places.map((item, index) => {
                return renderItemMap(item, index);
              })
            ) : (
              <Empty />
            )}
          </Row>
        </Skeleton>
      </Row>
    </Row>
  );
};

export default GoogleMapViewModal;
