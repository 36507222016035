import produce from 'immer';

import {
  MULTIPLE_PANEL_ALLERGENS,
  MULTIPLE_PANEL_ALLERGEN_FIELDS,
} from './constants';

import { filterAllergenType } from 'pages/qa-spec/components/group-data/utils';

const FORM_FIELDS = [
  'allergenType',
  'levelOfContainment',
  'isPresent',
  'containmentSource',
];

export const filterIsPresentAllergens = (allergenGroups) => {
  let result = [];

  if (!allergenGroups) return [];

  allergenGroups.forEach((allergenGroup) => {
    let filterAllergenGroup = {
      ...allergenGroup,
      allergens: filterAllergenType(
        allergenGroup?.allergens?.filter((allergen) =>
          Boolean(allergen.isPresent)
        )
      ),
    };
    result.push(filterAllergenGroup);
  });

  return result;
};

export const getFormAllergenFields = () => {
  return MULTIPLE_PANEL_ALLERGEN_FIELDS.filter((allergen) =>
    FORM_FIELDS.includes(allergen.fieldName)
  );
};

let defaultFieldForm = {
  allergenType: '',
  levelOfContainment: null,
  isPresent: false,
  containmentSource: null,
};

export const generateAddingAllergenFormValues = () => {
  let result = [];

  MULTIPLE_PANEL_ALLERGENS.forEach((allergenValue) => {
    const field = {
      ...defaultFieldForm,
      allergenType: allergenValue,
    };
    result.push(field);
  });

  return result;
};

export const getAllergensByIndex = (allergensGroup, index) => {
  return allergensGroup.find((allergenGroup) => allergenGroup.index === index)
    ?.allergens;
};

export const checkDisabledFormField = ({
  isPresent = false,
  levelOfContainment = '',
}) => {
  return {
    levelOfContainment: !isPresent,
    containmentSource: levelOfContainment?.toLowerCase() !== 'may contain',
  };
};

export const checkRequiredFormField = ({
  isPresent = false,
  levelOfContainment,
}) => {
  return {
    levelOfContainment: Boolean(isPresent),
    containmentSource: levelOfContainment?.toLowerCase() === 'may contain',
  };
};

export const updateAllergenFormValues = ({
  formValues,
  index,
  fieldName,
  value,
}) => {
  return produce(formValues, (draftState) => {
    draftState.allergens[index][fieldName] = value;
  });
};

const resetFields = ['levelOfContainment', 'containmentSource'];
export const resetFormAllergensAfterUncheckPresent = ({
  formValues,
  index,
}) => {
  let result = formValues;

  resetFields.forEach((field) => {
    result = updateAllergenFormValues({
      formValues: result,
      fieldName: field,
      index,
      value: null,
    });
  });

  return result;
};

export const verifyEmptyPrimaryAllergens = (formValues) => {
  const presentValues = formValues?.allergens?.map((value) => value.isPresent);
  return presentValues.every((present) => !present);
};

export const filterProductGroups = (groupIds, productGroups = []) => {
  return productGroups.filter((group) => !groupIds.includes(group.groupId));
};
