import { useEffect, useRef } from 'react';

const useFullScreen = () => {
  const refFullScreen = useRef();

  useEffect(() => {
    const goFullScreen = () => {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    };

    const exitFullScreen = () => {
      if (document.fullscreenElement) {
        document
          .exitFullscreen()
          .then(() => console.log('Document Exited from Full screen mode'))
          .catch((err) => console.error(err));
      }
    };

    refFullScreen.current = {
      goFullScreen,
      exitFullScreen,
    };
  }, []);

  return [refFullScreen];
};

export default useFullScreen;
