import React from 'react';
import { Divider } from 'antd';

import DeleteReporting from 'pages/home/ribbon/components/controls/reporting/DeleteReporting';
import { RibbonSection, RibbonDivider } from 'common/components';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const DeleteReportSection = () => {
  return (
    <RibbonSection>
      <Can I={ABILITY_ACTION.DELETE} a={ABILITY_SUBJECT.REPORTING}>
        <DeleteReporting />
        <RibbonDivider />
      </Can>
    </RibbonSection>
  );
};

export default DeleteReportSection;
