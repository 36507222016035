import React from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { supportPhoneFormats } from 'static/Constants';
import phoneVerifySrc from 'assets/security/smartphone_check.png';
import emailVerifySrc from 'assets/security/email_check.png';

import { PhoneInput } from '../components/shared/PhoneSection';
import { EmailInput } from '../components/shared/EmailSection';

export const DEFAULT_TIME_TO_RESEND_OTP = 60;
export const LOGIN_SESSION_EXP_MESSAGE_FROM_BE =
  'Your login session has expired. Please login again.';

export const UNLOCK_METHOD = {
  phone: 'phone',
  email: 'email',
  admin: 'admin',
};

export const OTP_TYPE = {
  phone: 'phone',
  email: 'email',
};

export const OTP_ACTION = {
  login: 'login',
  changeMfaPhone: 'changeMfaPhone',
  unlockAccount: 'unlockAccount',
  verfiyOnly: 'verifyOnly',
};

export const OTP_VIEW_CUSTOM_CONFIG = {
  phone: {
    otpSend: {
      title: (
        <>
          Continue with your phone
          <Popover
            content={() => (
              <React.Fragment>
                <ul>
                  {supportPhoneFormats.map((format, idx) => (
                    <li key={'phone-format-' + idx}>{format}</li>
                  ))}
                </ul>
              </React.Fragment>
            )}
            title='Support Formats'
            trigger='hover'
            placement='rightBottom'
          >
            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </>
      ),
      inputComponent: PhoneInput,
      inputHint: 'You will receive a 7-digit code to verify your phone number',
      hintImageSrc: phoneVerifySrc,
    },
    otpCheck: {
      title: 'Verify Phone',
      hint: 'Code was sent to your phone',
    },
  },
  email: {
    otpSend: {
      title: 'Continue with your email',
      inputComponent: EmailInput,
      hintImageSrc: emailVerifySrc,
      inputHint: 'You will receive a 7-digit code to verify your email account',
    },
    otpCheck: {
      title: 'Verify Email',
      hint: 'Code was sent to your email',
    },
  },
};
