import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import ProductMarketingAll from './ProductMarketingAll';
import * as productFullViewSelectors from '../../controllers/selectors';
import * as productFullViewActions from '../../controllers/actions';

import PreviewMarketingModal from '../preview-modal/PreviewMarketingModal';

import { useGetProductFullView } from 'hooks';

import './ProductMarketingVideo.less';

const ProductMarketingVideo = (props) => {
  const { getImagery, isAllowEdit, getProductDetail } = props;

  const { id } = useParams();

  const dispatch = useDispatch();

  const [isOpenPreview, setIsOpenReview] = useState(false);
  const itemList = useSelector(
    productFullViewSelectors.makeSelectMarketingAssets()
  );

  const { productFull } = useGetProductFullView({ productId: id });

  const gtin = productFull?.gtin;
  const rivirId = productFull?.rivirId;
  const upc12 = productFull?.upc12;

  const productName = productFull?.productName;
  const productTitle =
    productName && productName !== 'null' ? `- ${productName}` : '';

  const getMarketingAssets = () => {
    if (productFull) {
      dispatch(
        productFullViewActions.getMarketingAssets({
          id: productFull.productId,
        })
      );
    }
  };

  return (
    <>
      <ProductMarketingAll
        itemList={itemList}
        openPreview={() => setIsOpenReview(true)}
        getMarketingAssets={getMarketingAssets}
        getImagery={getImagery}
        isAllowEdit={isAllowEdit}
        getProductDetail={getProductDetail}
      />
      <PreviewMarketingModal
        visible={isOpenPreview}
        productInfo={productFull}
        onCloseModal={() => setIsOpenReview(false)}
        imageList={itemList}
        getImagery={getImagery}
        getMarketingAssets={getMarketingAssets}
        titleModal={
          gtin
            ? `${gtin} ${productTitle}`
            : upc12
            ? `${upc12} ${productTitle}`
            : rivirId
            ? `${rivirId} ${productTitle}`
            : ''
        }
        title={'Marketing'}
      />
    </>
  );
};
export default ProductMarketingVideo;
