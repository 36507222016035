import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';

import * as emailActions from 'common/components/mail/controller/actions';
import emailSaga from 'common/components/mail/controller/saga';
import emailReducer from 'common/components/mail/controller/reducer';

import * as chatActions from 'common/components/message/controller/actions';

import * as globalActions from 'redux/global/actions';
import userSelectors from 'redux/user/selectors';

import * as chatServices from 'services/chatServices';
import * as emailServices from 'services/email';

const key = 'email';

export const useSendMessageOrEmail = () => {
  useInjectReducer({ key, reducer: emailReducer });
  useInjectSaga({ key, saga: emailSaga });

  const dispatch = useDispatch();

  const userId = useSelector(userSelectors.makeSelectUserId());
  const creatorId = `U-${userId}`;

  const sendEmail = useCallback(
    async (userId, email) => {
      dispatch(globalActions.toggleEmailView(true));

      const params = {
        chatType: 'Email',
        companies: [],
        threadName: email,
        userChatType: 'User',
        users: [userId],
      };

      try {
        const response = await emailServices.initEmailCommunication(params);
        const threadId = response?.data?.threadId;

        if (response?.isSuccess && threadId) {
          dispatch(
            emailActions.getEmailThreadInfo({
              threadId: threadId,
              userId: creatorId,
            })
          );
          dispatch(emailActions.getEmailThreadInfoContent(threadId));
        }
      } catch (error) {
        console.log('error: ', error);
      }
    },
    [creatorId, dispatch]
  );

  const sendMessage = useCallback(
    async (userId) => {
      dispatch(globalActions.toggleChatView(true));

      const params = {
        chatType: 'P2P',
        companies: [],
        threadName: null,
        userChatType: 'User',
        users: [userId],
      };

      try {
        const response = await chatServices.initChatCommunication(params);
        if (response?.isSuccess) {
          dispatch(chatActions.setChatView('threadMsg'));
          dispatch(
            chatActions.getThreadInfo(response?.data?.threadId, creatorId)
          );
          dispatch(chatActions.getThreadMessages(response?.data?.threadId));
        }
      } catch (error) {
        console.log('error: ', error);
      }
    },
    [creatorId, dispatch]
  );

  return {
    sendMessage,
    sendEmail,
  };
};
