import React, { Fragment } from 'react';

import classnames from 'classnames';

const GridStyleLayout = (props) => {
  const {
    children,
    className,
    rows,
    columns,
    areas,
    gap,
    style,
    as,
    ...restProps
  } = props;

  if (!children) return null;

  const generateAreas = () => {
    if (!areas) return '';
    let areaString = '';

    areas.forEach((row) => {
      if (!row.length) areaString += '';

      areaString += `'${row.join(' ')}'`;
    });

    return areaString;
  };

  const getGapStyle = () => {
    let rowGap = 0;
    let colGap = 0;

    if (typeof gap === 'number') {
      rowGap = gap;
      colGap = gap;
    }

    if (typeof gap === 'object' && gap.length) {
      rowGap = gap[0];
      colGap = gap[1];
    }

    return `${rowGap}px ${colGap}px`;
  };

  const renderLayout = (children) => {
    return children.map((child) => {
      const { layoutArea, gridItemStyle = {} } = child.props;

      return (
        <div
          key={layoutArea}
          className='rivir-layout__item'
          style={{ ...gridItemStyle, gridArea: layoutArea }}
        >
          {child}
        </div>
      );
    });
  };

  const isFragment = !children.length && children.type === Fragment;

  const areaStyle = generateAreas();
  const gapStyle = getGapStyle();

  const Layout = props.as ? props.as : 'div';

  return (
    <Layout
      className={classnames('rivir-layout', className)}
      style={{
        display: 'grid',
        gridTemplateAreas: areaStyle,
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        gap: gapStyle,

        height: '100%',

        ...style,
      }}
      {...restProps}
    >
      {isFragment
        ? renderLayout(children.props.children)
        : children.length
        ? renderLayout(children)
        : children}
    </Layout>
  );
};

export default GridStyleLayout;
