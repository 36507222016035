import * as types from './constants';

export const getMappingGridColumns = (gridName) => ({
  type: types.GET_MAPPING_GRID_COLUMNS,
  gridName,
});

export const getMappingGridColumnsSuccess = (columns) => ({
  type: types.GET_MAPPING_GRID_COLUMNS_SUCCESS,
  columns,
});

export const getMappingGridColumnsFailure = () => ({
  type: types.GET_MAPPING_GRID_COLUMNS_FAILURE,
});

export const getMappingConfigGridColumns = (gridName) => ({
  type: types.GET_MAPPING_CONFIG_GRID_COLUMNS,
  gridName,
});

export const getMappingConfigGridColumnsSuccess = (columns) => ({
  type: types.GET_MAPPING_CONFIG_GRID_COLUMNS_SUCCESS,
  columns,
});

export const getMappingConfigGridColumnsFailure = () => ({
  type: types.GET_MAPPING_CONFIG_GRID_COLUMNS_FAILURE,
});

export const getMappingSourceProperties = (params) => ({
  type: types.GET_MAPPING_SOURCE_PROPERTIES,
  params,
});

export const getMappingSourcePropertiesSuccess = (properties) => ({
  type: types.GET_MAPPING_SOURCE_PROPERTIES_SUCCESS,
  properties,
});

export const getMappingSourcePropertiesError = (error) => ({
  type: types.GET_MAPPING_SOURCE_PROPERTIES_ERROR,
  error,
});

//* get MAPPING grid data
export const getMappingList = (payload) => ({
  type: types.GET_MAPPING_GRID,
  payload,
});

export const getMappingListSuccess = (data) => ({
  type: types.GET_MAPPING_GRID_SUCCESS,
  data,
});

export const getMappingListError = (error) => ({
  type: types.GET_MAPPING_GRID_ERROR,
  error,
});

export const setCurrentMappingId = (id) => ({
  type: types.SET_CURRENT_MAPPING_ID,
  id,
});

export const getMappingConfigProperties = (params) => ({
  type: types.GET_MAPPING_CONFIG_PROPERTIES,
  params,
});

export const getMappingConfigPropertiesSuccess = (data) => ({
  type: types.GET_MAPPING_CONFIG_PROPERTIES_SUCCESS,
  data,
});

export const getMappingConfigPropertiesFailure = (error) => ({
  type: types.GET_MAPPING_CONFIG_PROPERTIES_FAILURE,
  error,
});

export const getMappingDetail = (params) => ({
  type: types.GET_MAPPING_CONFIG,
  params,
});

export const getMappingDetailSuccess = (data) => ({
  type: types.GET_MAPPING_CONFIG_SUCCESS,
  data,
});

export const getMappingDetailFailure = (error) => ({
  type: types.GET_MAPPING_CONFIG_FAILURE,
  error,
});

export const updateFormatTypeFilter = (formatTypeFilter) => ({
  type: types.UPDATE_MAPPING_FORMAT_TYPE_FILTER,
  formatTypeFilter,
});
