import React from 'react';
import { useSelector } from 'react-redux';
import { CheckOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components/index';

import { apiHandler } from 'utils/api';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import * as productServices from 'services/product';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';

const AcceptPublication = (props) => {
  const reloadPage = useDispatchReloadPage();
  const intl = useIntl();

  const {
    selectionPublication,
    sectionDisabled,
    onRefetchNavigationMenu,
    onClearItemGrid,
  } = props;

  const onClickBtn = () => {
    dialogFunction({
      type: 'info',
      content: intl.formatMessage(Messages.acceptPublicationConfirm),
      okText: intl.formatMessage(Messages.acceptPublicationConfirmYes),
      cancelText: intl.formatMessage(Messages.acceptPublicationConfirmNo),
      onOk: callApiAcceptPublication,
    });
  };

  const callApiAcceptPublication = () => {
    const params = {
      id: selectionPublication?.id,
    };

    const successMessage = intl.formatMessage(
      Messages.acceptPublicationSuccess
    );
    const errorMessage = intl.formatMessage(Messages.acceptPublicationError);

    apiHandler({
      service: productServices.acceptPublication,
      params,
      successMessage,
      errorMessage,
      successCallback,
    });
  };
  const successCallback = () => {
    reloadPage();
    onRefetchNavigationMenu();
    onClearItemGrid();
  };

  const checkDisabled = () => {
    const status = selectionPublication?.status;

    const statusDisabled = status && status?.toLowerCase() !== 'pending';

    return sectionDisabled || statusDisabled;
  };

  return (
    <RibbonButton
      icon={<CheckOutlined />}
      label={Messages.acceptSubscription}
      onClick={onClickBtn}
      disabled={checkDisabled()}
    />
  );
};

export default AcceptPublication;
