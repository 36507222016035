import React from 'react';

import { Image, Typography, Tree } from 'antd';

import { useControllableState } from 'hooks/useControllableState';

import './FolderTree.less';

const noop = () => {};

export const FolderTree = (props) => {
  const {
    treeData,
    expandedKeys: expandedKeysProp,
    onExpandedChange = noop,
    selectedItem: selectedItemProp,
    onSelectedChange = noop,
    onLoadData = () => new Promise((resolve) => resolve()),
    ...rest
  } = props;

  const [expandedKeys, setExpandedKeys] = useControllableState({
    defaultProp: [],
    prop: expandedKeysProp,
    onChange: onExpandedChange,
  });

  const [{ selectedKeys }, setSelectedItem] = useControllableState({
    defaultProp: {},
    prop: selectedItemProp,
    onChange: onSelectedChange,
  });

  const onExpandItem = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  const onSelectItem = (selectedKeys, info) => {
    setSelectedItem({ selectedKeys, info });
  };

  return (
    <TreeFiles
      showIcon
      rootClassName='folder-tree'
      treeData={treeData}
      expandedKeys={expandedKeys}
      selectedKeys={selectedKeys}
      onExpand={onExpandItem}
      onSelect={onSelectItem}
      loadData={onLoadData}
      {...rest}
    />
  );
};

export const TitleFolder = ({ title, style, ...rest }) => {
  return (
    <Typography.Text
      style={{
        marginLeft: 8,
        fontSize: 14,
        color: '#595959',
        ...style,
      }}
      ellipsis={{ tooltip: title }}
      strong
      {...rest}
    >
      {title}
    </Typography.Text>
  );
};

export const IconAsset = ({ src, preview, ...rest }) => {
  return <Image preview={preview} width={20} height={20} src={src} {...rest} />;
};

export const IconFileAsset = (props) => {
  return <IconAsset width={20} height={20} preview={false} {...props} />;
};

export const IconFolderAsset = (props) => {
  return <IconAsset preview={false} {...props} />;
};

export const TreeFiles = (props) => {
  return <Tree {...props} />;
};

export const NodeTree = ({ title, children, style, id, ...rest }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...style,
      }}
      {...rest}
    >
      <TitleFolder title={title} />
      {children}
    </div>
  );
};
