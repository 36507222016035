import { formatMDYWithParam } from 'utils/formatDate';

//* MEMBER
export const GET_SSO_APPROVAL_MEMBER_GRID_COLUMNS =
  'GET_SSO_APPROVAL_MEMBER_GRID_COLUMNS';
export const GET_SSO_APPROVAL_MEMBER_GRID_COLUMNS_SUCCESS =
  'GET_SSO_APPROVAL_MEMBER_GRID_COLUMNS_SUCCESS';
export const GET_SSO_APPROVAL_MEMBER_GRID_COLUMNS_FAILURE =
  'GET_SSO_APPROVAL_MEMBER_GRID_COLUMNS_FAILURE';
export const GET_SSO_APPROVAL_MEMBER_LIST = 'GET_SSO_APPROVAL_MEMBER_LIST';
export const GET_SSO_APPROVAL_MEMBER_LIST_SUCCESS =
  'GET_SSO_APPROVAL_MEMBER_LIST_SUCCESS';
export const GET_SSO_APPROVAL_MEMBER_LIST_FAILURE =
  'GET_SSO_APPROVAL_MEMBER_LIST_FAILURE';
//* USER
export const GET_SSO_APPROVAL_USER_GRID_COLUMNS =
  'GET_SSO_APPROVAL_USER_GRID_COLUMNS';
export const GET_SSO_APPROVAL_USER_GRID_COLUMNS_SUCCESS =
  'GET_SSO_APPROVAL_USER_GRID_COLUMNS_SUCCESS';
export const GET_SSO_APPROVAL_USER_GRID_COLUMNS_FAILURE =
  'GET_SSO_APPROVAL_USER_GRID_COLUMNS_FAILURE';
export const GET_SSO_APPROVAL_USER_LIST = 'GET_SSO_APPROVAL_MEMBER_LIST';
export const GET_SSO_APPROVAL_USER_LIST_SUCCESS =
  'GET_SSO_APPROVAL_USER_LIST_SUCCESS';
export const GET_SSO_APPROVAL_USER_LIST_FAILURE =
  'GET_SSO_APPROVAL_USER_LIST_FAILURE';

export const columnAssignUser = [
  {
    dataType: 'string',
    displayName: 'First Name',
    fieldName: 'FirstName',
    fieldNameCamelCase: 'firstName',
    allowSort: true,
    width: 150,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    displayName: 'Last Name',
    fieldName: 'LastName',
    fieldNameCamelCase: 'lastName',
    allowSort: true,
    width: 150,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    displayName: 'Email',
    fieldName: 'Email',
    fieldNameCamelCase: 'email',
    allowSort: true,
    width: 200,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    displayName: 'Status',
    fieldName: 'Status',
    fieldNameCamelCase: 'status',
    allowSort: true,
    width: 120,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'int32',
    displayName: 'Member Id',
    fieldName: 'MemberId',
    fieldNameCamelCase: 'memberId',
    allowSort: true,
    width: 100,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    displayName: 'Member Name',
    fieldName: 'MemberName',
    fieldNameCamelCase: 'memberName',
    allowSort: true,
    width: 230,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
];

export const columnsApproval = [
  {
    field: '',
    checkboxSelection: true,
    filter: false,
    suppressMenu: true,
    sortable: false,
  },
  {
    dataType: 'int32',
    displayName: 'Id',
    fieldName: 'Id',
    fieldNameCamelCase: 'id',
    allowFilter: true,
    allowSort: true,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    headerName: 'Supplier Name',
    fieldName: 'MemberName',
    fieldNameCamelCase: 'memberName',
    allowFilter: true,
    allowSort: true,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    headerName: 'Reject Reason',
    fieldName: 'RejectReason',
    fieldNameCamelCase: 'rejectReason',
    flex: 1,
    allowFilter: true,
    allowSort: true,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    headerName: 'DBA',
    fieldName: 'Dba',
    fieldNameCamelCase: 'dba',
    allowFilter: true,
    allowSort: true,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    headerName: 'Status',
    fieldName: 'Status',
    fieldNameCamelCase: 'status',
    allowFilter: true,
    allowSort: true,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    headerName: 'Requested By',
    fieldName: 'RequestedBy',
    fieldNameCamelCase: 'requestedBy',
    allowFilter: true,
    allowSort: true,
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    dataType: 'datetime',
    displayName: 'Created Date',
    fieldName: 'Created',
    fieldNameCamelCase: 'created',
    allowFilter: true,
    allowSort: true,
    width: 150,
    valueFormatter: formatMDYWithParam,
    resizable: true,
  },
];
