import React, { useRef, useState, useEffect } from 'react';

import { Typography, Input, Row, Col, Select, Space, Skeleton } from 'antd';
import {
  FilterTwoTone,
  FilterOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import { RoleEmptySection } from '../';

import { WrapperSelect } from 'common/components';

import MemberRolePermissionGroup from './MemberRolePermissionGroup';

import { isEmpty } from 'lodash';
import {
  mapToGroupedMemberPermissionList,
  getAllCateFromAllMemberPermissionList,
} from 'pages/role/mappers/mapToMemberPermissionList';
import useExitDelay from 'hooks/useExitDelay';

import './styles.less';

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const rowStyle = {
  marginBottom: 5,
};

const MemberRolePermissionList = (props) => {
  const { selectedRole, availMemberPermissionList, formRole, isEdit } = props;

  const [searchText, setSearchText] = useState('');
  const [searchCategories, setSearchCategories] = useState([]);
  const [categorizedPermission, setCategorizedPermission] = useState(null);
  const [allCategory, setAllCategory] = useState([]);
  const [changedPermisisonList, setChangedPermissionList] = useState([]);
  const [isLoadingPermissionList, kickOffLoading] = useExitDelay(100);

  const refSearch = useRef();

  const permissionListFromRole = isEdit
    ? formRole?.values?.permissionList
    : selectedRole?.permissionList;

  const availPermissionListData = availMemberPermissionList?.data;

  const roleId = selectedRole?.id;

  const onSearch = (value) => {
    setSearchText(value);
  };

  const handleChangeCate = (value) => {
    setSearchCategories(value);
  };

  const resetFilter = () => {
    setSearchCategories([]);
    if (refSearch.current && refSearch?.current?.handleReset) {
      refSearch.current.handleReset({
        type: 'click',
        target: refSearch.current.Input,
      });
      setSearchText('');
    }
  };

  useEffect(() => {
    if (roleId) {
      resetFilter();
    }
  }, [roleId]);

  useEffect(() => {
    //* processed permission list
    const processPermissionList = async () => {
      const nextCategorizedPermission = await mapToGroupedMemberPermissionList({
        permissionList: permissionListFromRole,
        availPermissions: availPermissionListData,
        searchText,
        searchCategories,
        allCategory,
      });

      setCategorizedPermission(nextCategorizedPermission);
    };

    processPermissionList();
  }, [
    searchText,
    searchCategories,
    permissionListFromRole,
    availPermissionListData,
    allCategory,
  ]);

  useEffect(() => {
    //* set cate name list
    const setCateNameList = () => {
      if (availPermissionListData) {
        const nextCateNameList = getAllCateFromAllMemberPermissionList({
          availPermissions: availPermissionListData,
        });
        setAllCategory(nextCateNameList);
      }
    };

    setCateNameList();
  }, [availPermissionListData]);

  useEffect(() => {
    //* detect changed permission items
    const detectChangedPermissionItems = () => {
      if (!isEdit) {
        setChangedPermissionList([]);
        return;
      }
      const formPermissionList = formRole?.values?.permissionList ?? [];
      const permissionList = selectedRole?.permissionList ?? [];

      const nextChangedPermissionList = [
        ...permissionList.filter(
          (permissionItem) => !formPermissionList.includes(permissionItem)
        ),
        ...formPermissionList.filter(
          (permissionItem) => !permissionList.includes(permissionItem)
        ),
      ];

      setChangedPermissionList(nextChangedPermissionList);
    };

    detectChangedPermissionItems();
  }, [isEdit, selectedRole, formRole]);

  useEffect(() => {
    //* create loading animation for permission list
    const makeLoadingWhenChangeSelectedRole = () => {
      kickOffLoading();
    };

    makeLoadingWhenChangeSelectedRole();
  }, [JSON.stringify(selectedRole)]);

  return (
    <div className='member-role-permission-section__wrap'>
      {!selectedRole ? (
        <RoleEmptySection />
      ) : (
        !isEmpty(availPermissionListData) && (
          <>
            <Row gutter={[5, 10]} style={rowStyle}>
              <Col xs={24}>
                <Search
                  ref={refSearch}
                  placeholder='Search permissions...'
                  allowClear
                  onSearch={onSearch}
                  enterButton
                />
              </Col>
            </Row>

            <Row gutter={[5, 10]} style={rowStyle}>
              <Col xs={24}>
                <WrapperSelect
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Select permission categories'
                  onChange={handleChangeCate}
                  className='member-role-permission-section__cate-filter'
                  value={searchCategories}
                >
                  {allCategory.length > 0 &&
                    allCategory.map((cate) => {
                      return <Option key={cate}>{cate}</Option>;
                    })}
                </WrapperSelect>
              </Col>
            </Row>
            <Row style={rowStyle}>
              <Col xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                {searchText || searchCategories?.length > 0 ? (
                  <Space>
                    <FilterTwoTone />
                    <Text>Filter on</Text>
                    <ClearOutlined
                      className='member-role-permission-section__btn-clear-filter'
                      onClick={resetFilter}
                    />
                  </Space>
                ) : (
                  <>
                    <FilterOutlined style={{ marginRight: 3 }} />
                    <Text>Filter off</Text>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ ...rowStyle, overflowY: 'auto', flex: 1 }}>
              <Col xs={24} style={{ height: '100%' }}>
                {isLoadingPermissionList ? (
                  <Skeleton />
                ) : (
                  <MemberRolePermissionGroup
                    categorizedPermission={categorizedPermission}
                    formRole={formRole}
                    changedPermisisonList={changedPermisisonList}
                  />
                )}
              </Col>
            </Row>
          </>
        )
      )}
    </div>
  );
};

export default MemberRolePermissionList;
