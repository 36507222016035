// Action Types
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';
export const VALIDATE_EULA = 'VALIDATE_EULA';
export const VALIDATE_ACCEPTED_EULA = 'VALIDATE_ACCEPTED_EULA';
export const VALIDATE_PERSONALIZATION = 'VALIDATE_PERSONALIZATION';
export const VALIDATE_CRITICAL_MESSAGE = 'VALIDATE_CRITICAL_MESSAGE';
export const VALIDATE_LANDING_PAGE = 'VALIDATE_LANDING_PAGE';
export const CHANGE_RIBBON_ACTIONS = 'CHANGE_RIBBON_ACTIONS';
export const SEARCH_CRITERIA = 'SEARCH_CRITERIA';
export const FAVORITE_QUERIES = 'FAVORITE_QUERIES';
export const FAVORITE_SHARED_QUERIES = 'FAVORITE_SHARED_QUERIES';
export const FAVORITE_PRODUCTS = 'FAVORITE_PRODUCTS';
export const FAVORITE_ASSETS = 'FAVORITE_ASSETS';
export const FAVORITE_MEMBERS = 'FAVORITE_MEMBERS';
export const SELECTED_KEY = 'SELECTED_KEY';
export const TOGGLE_DETAIL = 'TOGGLE_DETAIL';
export const UPDATE_TOGGLE_DETAIL = 'UPDATE_TOGGLE_DETAIL';
export const HIDE_DETAIL = 'HIDE_DETAIL';
export const INCONTAINER_DETAIL = 'INCONTAINER_DETAIL';
export const TOGGLE_QUICKVIEW = 'TOGGLE_QUICKVIEW';
export const UPDATE_SEARCH_CRITERIA = 'UPDATE_SEARCH_CRITERIA';
export const UPDATE_LOGGING = 'UPDATE_LOGGING';
export const LOGOUT = 'LOGOUT';
export const SEARCH_ALL_ITEMS = 'SEARCH_ALL_ITEMS';
export const HIDE_SEARCH_BAR = 'HIDE_SEARCH_BAR ';
export const UNHIDE_SEARCH_BAR = 'UNHIDE_SEARCH_BAR ';
//* HIDE/SHOW RIBBON BAR
export const TOGGLE_RIBBON_BAR = 'TOGGLE_RIBBON_BAR';
export const TOGGLE_RIBBON_BAR_SUCCESS = 'TOGGLE_RIBBON_BAR_SUCCESS';
export const TOGGLE_RIBBON_BAR_FAIL = 'TOGGLE_RIBBON_BAR_FAIL';
//* UPDATE UP/DOWN FILE PROGRESS BAR
export const UPDATE_PROGRESS_BAR = 'UPDATE_PROGRESS_BAR';
export const UPDATE_PROGRESS_BAR_SUCCESS = 'UPDATE_PROGRESS_BAR_SUCCESS';
//* REMOVE ONE/ALLL PROGRESS BAR ITEM
export const REMOVE_PROGRESS_BAR_ITEM = 'REMOVE_PROGRESS_BAR_ITEM';
//* TOGGLE HIDDEN + TOGGLE MINIMIZE PROGRESS BAR
export const TOGGLE_MINIMIZED_PROGRESS_BAR = 'TOGGLE_MINIMIZED_PROGRESS_BAR';
export const TOGGLE_HIDDEN_PROGRESS_BAR = 'TOGGLE_HIDDEN_PROGRESS_BAR';

export const TOGGLE_MANAGE_SHARE = 'TOGGLE_MANAGE_SHARE';
export const TOGGLE_ADD_TO_FOLDER = 'TOGGLE_ADD_TO_FOLDER';
export const UPDATE_ADD_TO_FOLDER = 'UPDATE_ADD_TO_FOLDER';
export const TOGGLE_ADD_FOLDER_TO_APL = 'TOGGLE_ADD_TO_APL';
export const UPDATE_ADD_FOLDER_TO_APL = 'UPDATE_ADD_TO_APL';

export const MEMBER_FOR_SHARING_COLUMN_INFO = 'MEMBER_FOR_SHARING_COLUMN_INFO';
export const MEMBER_FOR_SHARING_COLUMN_INFO_SUCCESS =
  'MEMBER_FOR_SHARING_COLUMN_INFO_SUCCESS';
export const MEMBER_FOR_SHARING_COLUMN_INFO_ERROR =
  'MEMBER_FOR_SHARING_COLUMN_INFO_ERROR';
export const USER_FOR_SHARING_COLUMN_INFO = 'USER_FOR_SHARING_COLUMN_INFO';
export const USER_FOR_SHARING_COLUMN_INFO_SUCCESS =
  'USER_FOR_SHARING_COLUMN_INFO_SUCCESS';
export const USER_FOR_SHARING_COLUMN_INFO_ERROR =
  'USER_FOR_SHARING_COLUMN_INFO_ERROR';
//* INIT OPEN ITEM
export const INIT_OPEN_ITEM = 'INIT_OPEN_ITEM';
//* CLEAR OPEN ITEM
export const CLEAR_OPEN_ITEMS = 'CLEAR_OPEN_ITEMS';
//* UPDATE OPEN ITEM
export const UPDATE_OPEN_ITEM = 'UPDATE_OPEN_ITEM';
export const UPDATE_OPEN_ITEM_SUCCESS = 'UPDATE_OPEN_ITEM_SUCCESS';
//* UPDATE OPEN ITEM FORM (edit mode)
export const UPDATE_OPEN_ITEM_FORM = 'UPDATE_OPEN_ITEM_FORM';
export const UPDATE_OPEN_ITEM_FORM_SUCCESS = 'UPDATE_OPEN_ITEM_FORM_SUCCESS';
//* TOGGLE IS_ADDING OPEM ITEM (edit mode + view mode)
export const TOGGLE_IS_ADDING_OPEM_ITEM = 'TOGGLE_IS_ADDING_OPEN_ITEM';
export const TOGGLE_IS_ADDING_OPEM_ITEM_SUCCESS =
  'TOGGLE_IS_ADDING_OPEN_ITEM_SUCCESS';
//* TOGGLE IS_ADDING OPEM ITEM FORM DATA (edit mode)
export const TOGGLE_IS_ADDING_OPEM_ITEM_FORM_DATA =
  'TOGGLE_IS_ADDING_OPEN_ITEM_FORM_DATA';
//* UPDATE CURRENT TABS
export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB';
export const UPDATE_CURRENT_TAB_SUCCESS = 'UPDATE_CURRENT_TAB_SUCCESS';
//* UPDATE CURRENT OPEN ITEM
export const UPDATE_CURRENT_OPEN_ITEM = 'UPDATE_CURRENT_OPEN_ITEM';
//* DELETE ACTIVATED OPEN ITEM
export const DELETE_ACTIVATED_OPEN_ITEM = 'DELETE_ACTIVATED_OPEN_ITEM';
export const DELETE_ACTIVATED_OPEN_ITEM_SUCCESS =
  'DELETE_ACTIVATED_OPEN_ITEM_SUCCESS';
//* DELETE OPEN ITEM
export const DELETE_OPEN_ITEM = 'DELETE_OPEN_ITEM';
//* TOGGLE HELP CENTER
export const TOGGLE_HELP_CENTER = 'TOGGLE_HELP_CENTER';

export const SEARCH_HELP_POST = 'SEARCH_HELP_POST';
export const SEARCH_HELP_POST_SUCCESS = 'SEARCH_HELP_POST_SUCCESS';
export const SEARCH_HELP_POST_ERROR = 'SEARCH_HELP_POST_ERROR';
export const UPDATE_FILTER_ASSETS = 'UPDATE_FILTER_ASSETS';
//* get media asset files
export const GET_MEDIA_ASSETS = 'GET_MEDIA_ASSETS';
export const GET_MEDIA_ASSETS_SUCCESS = 'GET_MEDIA_ASSETS_SUCCESS';
export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER';
export const REMOVE_CURRENT_FOLDER = 'REMOVE_CURRENT_FOLDER';

export const UPDATE_SEARCH_TEXT_MEMBER = 'UPDATE_SEARCH_TEXT_MEMBER';
export const UPDATE_SEARCH_TEXT_DIGITAL_MEDIA =
  'UPDATE_SEARCH_TEXT_DIGITAL_MEDIA';
export const UPDATE_SEARCH_TEXT_DIGITAL_MEDIA_NEW =
  'UPDATE_SEARCH_TEXT_DIGITAL_MEDIA_NEW';
export const UPDATE_SEARCH_TEXT_ASSET = 'UPDATE_SEARCH_TEXT_ASSET';
export const UPDATE_SEARCH_TEXT_DOCUMENTS = 'UPDATE_SEARCH_TEXT_DOCUMENTS';
export const UPDATE_SEARCH_TEXT_MULTI_MEDIA = 'UPDATE_SEARCH_TEXT_MULTI_MEDIA';
export const UPDATE_SEARCH_TEXT_REPORTINGS = 'UPDATE_SEARCH_TEXT_REPORTINGS';
export const UPDATE_SEARCH_TEXT_REPORTINGS_OWNED =
  'UPDATE_SEARCH_TEXT_REPORTINGS_OWNED';
export const UPDATE_SEARCH_TEXT_REPORTINGS_SHARED =
  'UPDATE_SEARCH_TEXT_REPORTINGS_SHARED';
export const UPDATE_SEARCH_TEXT_FAVORITE_QUERIES =
  'UPDATE_SEARCH_TEXT_FAVORITE_QUERIES';
export const UPDATE_SEARCH_TEXT_MAINTENANCE = 'UPDATE_SEARCH_TEXT_MAINTENANCE';
export const UPDATE_SEARCH_TEXT_MEMBER_MAINTENANCE_USER =
  'UPDATE_SEARCH_TEXT_MEMBER_MAINTENANCE_USER';
export const UPDATE_SEARCH_TEXT_USER_MAINTENANCE =
  'UPDATE_SEARCH_TEXT_USER_MAINTENANCE';
export const ADD_BRANDING_INFO = 'ADD_BRANDING_INFO';
export const UPDATE_RELOAD_PAGE = 'UPDATE_RELOAD_PAGE';
export const UPDATE_IS_ONLY_DETAIL_GRID = 'UPDATE_IS_ONLY_DETAIL_GRID';

export const TOGGLE_PROD_TO_MEM = 'TOGGLE_PROD_TO_MEM';
export const TOGGLE_ASSETS_TO_MEM = 'TOGGLE_ASSETS_TO_MEM';
export const RESET_PROD_AND_ASSETS_TO_MEMBER =
  'RESET_PROD_AND_ASSETS_TO_MEMBER';

export const RESET_SEARCH_TEXT = 'RESET_SEARCH_TEXT';

export const UPDATE_SEARCH_TEXT_TRANSITION_PAGE =
  'UPDATE_SEARCH_TEXT_TRANSITION_PAGE';

export const UPDATE_SHOW_ADVANCE_FILTER = 'UPDATE_SHOW_ADVANCE_FILTER';
export const TOGGLE_ADVANCE_FILTER = 'TOGGLE_ADVANCE_FILTER';

export const UPDATE_MARKDOWN_REFERENCE = 'UPDATE_MARKDOWN_REFERENCE';
export const UPDATE_HELP_CENTER_MARKDOWN_REFERENCE =
  'UPDATE_HELP_CENTER_MARKDOWN_REFERENCE';

export const GET_ALL_ARTICLES = 'GET_ALL_ARTICLES';
export const GET_ALL_ARTICLES_SUCCESS = 'GET_ALL_ARTICLES_SUCCESS';

export const GET_ALL_ARTICLES_ERROR = 'GET_ALL_ARTICLES_ERROR';

// Member maintenance id - Member maintenance management
export const UPDATE_MEMBER_MAINTENANCE_ID = 'UPDATE_MEMBER_MAINTENANCE_ID';

export const UPDATE_IS_BACK_FROM_QUERY = 'UPDATE_IS_BACK_FROM_QUERY';

export const UPDATE_IS_BACK_FROM_REPORTING = 'UPDATE_IS_BACK_FROM_REPORTING';

export const UPDATE_FOLDER_ID_PANEL = 'UPDATE_FOLDER_ID_PANEL';

export const IS_DISPLAY_SHOW_PREVIEW = 'IS_DISPLAY_SHOW_PREVIEW';

export const IS_SHOW_GRID_QUERY = 'IS_SHOW_GRID_QUERY';

export const CLEAR_SEARCH_TEXT_DAM = 'CLEAR_SEARCH_TEXT_DAM';

export const UPDATE_SEARCH_FAV_MEMBER = 'UPDATE_SEARCH_FAV_MEMBER';

export const UPDATE_SEARCH_FAV_ASSET = 'UPDATE_SEARCH_FAV_ASSET';

export const UPDATE_SEARCH_FAV_PRODUCT = 'UPDATE_SEARCH_FAV_PRODUCT';

export const UPDATE_SEARCH_ASSET_FOR_MEMBER = 'UPDATE_SEARCH_ASSET_FOR_MEMBER';

export const UPDATE_SEARCH_FOLDER_TEXT = 'UPDATE_SEARCH_FOLDER_TEXT';

export const UPDATE_SEARCH_FOLDER_OWNED_TEXT =
  'UPDATE_SEARCH_FOLDER_OWNED_TEXT';

export const UPDATE_SEARCH_FOLDER_SHARED_TEXT =
  'UPDATE_SEARCH_FOLDER_SHARED_TEXT';

export const UPDATE_SEARCH_FAV_FOLDER = 'UPDATE_SEARCH_FAV_FOLDER';

export const UPDATE_SEARCH_WF_MAINTENANCE_DEFINITION =
  'UPDATE_SEARCH_WF_MAINTENANCE_DEFINITION';

export const GET_MEMBER_INFO_SHEET = 'GET_MEMBER_INFO_SHEET';
export const GET_MEMBER_INFO_SHEET_SUCCESS = 'GET_MEMBER_INFO_SHEET_SUCCESS';
export const GET_MEMBER_INFO_SHEET_ERROR = 'GET_MEMBER_INFO_SHEET_ERROR';
export const GET_TODO_LIST = 'GET_TODO_LIST';
export const GET_TODO_LIST_SUCCESS = 'GET_TODO_LIST_SUCCESS';
export const GET_TODO_LIST_ERROR = 'GET_TODO_LIST_ERROR';
export const RESOLVE_TODO_LIST = 'RESOLVE_TODO_LIST';
export const RESOLVE_TODO_LIST_SUCCESS = 'RESOLVE_TODO_LIST_SUCCESS';
export const RESOLVE_TODO_LIST_ERROR = 'RESOLVE_TODO_LIST_ERROR';

export const TOGGLE_CHAT_VIEW = 'TOGGLE_CHAT_VIEW';
export const TOGGLE_CHAT_VIEW_SUCCESS = 'TOGGLE_CHAT_VIEW_SUCCESS';

export const GET_AWAITING_LIST = 'GET_AWAITING_LIST';
export const GET_AWAITING_LIST_SUCCESS = 'GET_AWAITING_LIST_SUCCESS';
export const GET_AWAITING_LIST_ERROR = 'GET_AWAITING_LIST_ERROR';

export const TOGGLE_EMAIL_VIEW = 'TOGGLE_EMAIL_VIEW';
export const TOGGLE_EMAIL_VIEW_SUCCESS = 'TOGGLE_EMAIL_VIEW_SUCCESS';

export const SET_SIGNALR_CONNECTION = 'SET_SIGNALR_CONNECTION';
export const SET_SIGNALR_CONNECTION_SUCCESS = 'SET_SIGNALR_CONNECTION_SUCCESS';
export const SET_SIGNALR_CONNECTION_FAIL = 'SET_SIGNALR_CONNECTION_FAIL';

//* RECEIVE NEW MESSAGE OR EMAIL
export const NEW_MSG_RECEIVE_INFO = 'NEW_MSG_RECEIVE_INFO';
export const NEW_EMAIL_RECEIVE_INFO = 'NEW_EMAIL_RECEIVE_INFO';
export const RESET_NEW_EMAIL_INFO = 'RESET_NEW_EMAIL_INFO';

//* TOGGLE VIDEO CALL
export const TOGGLE_VIDEO_CALL = 'TOGGLE_VIDEO_CALL';
export const TOGGLE_VIDEO_CALL_SUCCESS = 'TOGGLE_VIDEO_CALL_SUCCESS';

export const TOGGLE_IS_CALL_ACCEPTED = 'TOGGLE_IS_CALL_ACCEPTED';

//* EMAIL & MESSAGE NOTIFICATION THREAD
export const GET_EMAIL_AND_MESSAGE_NOTIFY = 'GET_EMAIL_AND_MESSAGE_NOTIFY';
export const GET_EMAIL_NOTIFY_SUCCESS = 'GET_EMAIL_NOTIFY_SUCCESS';
export const TOGGLE_IS_RECEIVING_CALL = 'TOGGLE_IS_RECEIVING_CALL';

export const GET_MESSAGE_NOTIFY = 'GET_MESSAGE_NOTIFY';
export const GET_MESSAGE_NOTIFY_SUCCESS = 'GET_MESSAGE_NOTIFY_SUCCESS';
export const GET_EMAIL_AND_MESSAGE_NOTIFY_ERROR =
  'GET_EMAIL_AND_MESSAGE_NOTIFY_ERROR';

export const MAKE_A_THREAD_AS_READ = 'MAKE_A_THREAD_AS_READ';
export const MAKE_A_THREAD_AS_READ_SUCCESS = 'MAKE_A_THREAD_AS_READ_SUCCESS';
export const MAKE_A_THREAD_AS_READ_ERROR = 'MAKE_A_THREAD_AS_READ_ERROR';
//*

export const RESET_MESSAGE_NOTIFY = 'RESET_MESSAGE_NOTIFY';
export const RESET_MESSAGE_NOTIFY_SUCCESS = 'RESET_MESSAGE_NOTIFY_SUCCESS';
export const RESET_MESSAGE_NOTIFY_ERROR = 'RESET_MESSAGE_NOTIFY_ERROR';

export const TOGGLE_VIDEO_CALL_FAIL = 'TOGGLE_VIDEO_CALL_FAIL';

//* markdown table and entity
export const UPDATE_MD_TABLE_ACTION = 'UPDATE_MD_TABLE_ACTION';

export const TOGGLE_IS_GETTING_PLACEHOLDER_LIST =
  'TOGGLE_IS_GETTING_PLACEHOLDER_LIST';

export const UPDATE_FILTER_PRODUCTS = 'UPDATE_FILTER_PRODUCTS';
export const UPDATE_FILTER_MEMBER_PRODUCT = 'UPDATE_FILTER_MEMBER_PRODUCT';

// Get Product Enums
export const GET_PRODUCT_ENUMS = 'GET_PRODUCT_ENUMS';
export const GET_PRODUCT_ENUMS_SUCCESS = 'GET_PRODUCT_ENUMS_SUCCESS';
export const GET_PRODUCT_ENUMS_ERROR = 'GET_PRODUCT_ENUMS_ERROR';
export const UPDATE_SEARCH_TEXT_PRODUCT = 'UPDATE_SEARCH_TEXT_PRODUCT';
export const UPDATE_SEARCH_TEXT_PRODUCT_FOR_MEMBER =
  'UPDATE_SEARCH_TEXT_PRODUCT_FOR_MEMBER';
export const UPDATE_SEARCH_TEXT_SSO_APPROVAL_MEMBER =
  'UPDATE_SEARCH_TEXT_SSO_APPROVAL_MEMBER';
export const UPDATE_SEARCH_TEXT_MAINTENANCE_FORM =
  'UPDATE_SEARCH_TEXT_MAINTENANCE_FORM';

export const LOAD_PRODUCT_BRICK_CODE = 'LOAD_PRODUCT_BRICK_CODE';

export const TOGGLE_FINISH_LOAD_MORE_MEDIA = 'TOGGLE_FINISH_LOAD_MORE_MEDIA';
export const GET_NEW_RIVIR_ALERTS = 'GET_NEW_RIVIR_ALERTS';
export const GET_NEW_RIVIR_ALERTS_SUCCESS = 'GET_NEW_RIVIR_ALERTS_SUCCESS';
export const GET_NEW_RIVIR_ALERTS_ERROR = 'GET_NEW_RIVIR_ALERTS_ERROR';

export const LOAD_PRODUCT_TARGET_MARKET = 'LOAD_PRODUCT_TARGET_MARKET';
export const REMOVE_HIERARCHY_ALERT_ID = 'REMOVE_HIERARCHY_ALERT_ID';
export const CHANGE_HIERARCHY_ALERT_ID = 'CHANGE_HIERARCHY_ALERT_ID';

export const GET_MEETING_NOTIFICATION = 'GET_MEETING_NOTIFICATION';
export const GET_MEETING_NOTIFICATION_SUCCESS =
  'GET_MEETING_NOTIFICATION_SUCCESS';
export const GET_MEETING_NOTIFICATION_ERROR = 'GET_MEETING_NOTIFICATION_ERROR';
export const UPDATE_LIST_AFTER_SEND_MESSAGE = 'UPDATE_LIST_AFTER_SEND_MESSAGE';
export const UPDATE_LIST_AFTER_SEND_EMAIL = 'UPDATE_LIST_AFTER_SEND_EMAIL';

export const TOGGLE_NEW_SYNDICATION_HISTORY = 'TOGGLE_NEW_SYNDICATION_HISTORY';

export const TOGGLE_FILTER_PENDING_PUBLICATION =
  'TOGGLE_FILTER_PENDING_PUBLICATION';

export const TOGGLE_PRODUCT_NEW = 'TOGGLE_PRODUCT_NEW';
export const TOGGLE_MEMBER_NEW = 'TOGGLE_MEMBER_NEW';
export const TOGGLE_ASSET_NEW = 'TOGGLE_ASSET_NEW';
export const TOGGLE_REQUEST_NEW = 'TOGGLE_REQUEST_NEW';

export const SAVE_CURRENT_SELECTED_PERMISSION_REQUEST =
  'SAVE_CURRENT_SELECTED_PERMISSION_REQUEST';

export const UPDATE_ENVIRONEMENT = 'UPDATE_ENVIROBMENT';

export const TOGGLE_PRODUCT_UNMATCH = 'TOGGLE_PRODUCT_UNMATCH';
export const TOGGLE_MEMBER_UNMATCH = 'TOGGLE_MEMBER_UNMATCH';
export const TOGGLE_ASSET_UNMATCH = 'TOGGLE_ASSET_UNMATCH';
export const TOGGLE_REQUEST_UNMATCH = 'TOGGLE_REQUEST_UNMATCH';
export const UPDATE_FILTER_MEMBERS = 'UPDATE_FILTER_MEMBERS';
export const FILTER_SSO_USER_PENDING = 'FILTER_SSO_USER_PENDING';
export const FILTER_SSO_MEMBER_PENDING = 'FILTER_SSO_MEMBER_PENDING';

export const TOGGLE_CREDIT_CARD_EULA_MODAL = 'TOGGLE_CREDIT_CARD_EULA_MODAL';
export const UPDATE_CREDIT_CARD_EULA_ACCEPTED =
  'UPDATE_CREDIT_CARD_EULA_ACCEPTED';
export const UPDATE_SHOW_CHARGE_WARNING = 'UPDATE_SHOW_CHARGE_WARNING';
export const UPDATE_SHOW_CREDIT_CARD_MODAL_WHEN =
  'UPDATE_SHOW_CREDIT_CARD_MODAL_WHEN';

export const UPDATE_SEARCH_TEXT_EULA_CARD = 'UPDATE_SEARCH_TEXT_EULA_CARD';
export const UPDATE_SEARCH_TEXT_DATA_SYNC_TIME_TRACKING =
  'UPDATE_SEARCH_TEXT_DATA_SYNC_TIME_TRACKING';
export const UPDATE_SEARCH_TEXT_QC_TIME_TRACKING =
  'UPDATE_SEARCH_TEXT_QC_TIME_TRACKING';
export const UPDATE_SEARCH_TEXT_TICKETING_SYSTEM =
  'UPDATE_SEARCH_TEXT_TICKETING_SYSTEM';

export const SHOW_EXPORT_LOADING_RIBBON = 'SHOW_EXPORT_LOADING_RIBBON';
export const CANCEL_EXPORT_LOADING_RIBBON = 'CANCEL_EXPORT_LOADING_RIBBON';

export const UPDATE_FILTER_USER_MAINTENANCE = 'UPDATE_FILTER_USER_MAINTENANCE';
export const UPDATE_FILTER_TICKETING_SYSTEM = 'UPDATE_FILTER_TICKETING_SYSTEM';
export const TOGGLE_NEW_TICKETING_SYSTEM = 'TOGGLE_NEW_TICKETING_SYSTEM';

export const UPDATE_SEARCH_TEXT_QA_SPEC_WORKFLOW =
  'UPDATE_SEARCH_TEXT_QA_SPEC_WORKFLOW';

export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';
