import React from 'react';
import { useSelector } from 'react-redux';

import { RibbonBar } from "common/components"; 
import { ViewLayout, AdvanceStack } from './components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import PublicationSection from './components/sections/product/PublicationSection';

import gridSelectors from 'common/components/grid-view/controllers/selectors';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const PublicationGridView = () => {
  const gridSelections = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const selectionPublication = gridSelections.length > 0 && gridSelections[0];

  const disabled = gridSelections.length !== 1;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.PRODUCT}>
        <PublicationSection
          selectionPublication={selectionPublication}
          sectionDisabled={disabled}
        />
      </Can>
      <AdvanceStack isDisabled={true} />
    </RibbonBar>
  );
};

export default PublicationGridView;
