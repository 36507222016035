import React from 'react';
import classnames from 'classnames';
import { Avatar, Col, Typography } from 'antd';
import { AvatarPropertiesItem } from 'common/components/mapping-properties/MappingSourceProperties';
import { getHighlightedText } from 'common/components/reporting/entity-and-attributes/utils';

const { Text } = Typography;

const PropertiesItems = React.memo(
  ({ displayName, dataType, searchText, children }) => {
    const highlightSearchText = getHighlightedText(displayName, searchText);

    return (
      <>
        <Col style={{ paddingLeft: 0, width: 40 }}>
          <Avatar
            shape='square'
            className='property-item__icon'
            icon={<AvatarPropertiesItem propertyType={dataType} />}
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'calc(100% - 40px)',
          }}
        >
          <Text
            className={classnames({
              'property-item__name': true,
            })}
            strong={true}
            ellipsis={{ tooltip: displayName }}
          >
            {highlightSearchText}
          </Text>
          {children}
        </Col>
      </>
    );
  }
);

export default PropertiesItems;
