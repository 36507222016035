import React, { forwardRef, useImperativeHandle } from 'react';
import { set } from 'lodash';

const FakeForm = forwardRef((props, ref) => {
  const { children } = props;
  useImperativeHandle(ref, () => {
    return {
      resetValues,
    };
  });
  const handleChange = (e) => {
    const value = e?.target?.value;
    const name = e?.target?.name;

    if (!name) return;

    set(ref.current, ['value', name], value);
  };

  const resetValues = (resetValue) => {
    ref.current = resetValue;
  };

  return (
    <div className='fake-form'>
      {children &&
        children({
          onItemChange: handleChange,
        })}
    </div>
  );
});

export default FakeForm;
