import { useQuery } from '@tanstack/react-query';

import { CustomNotification } from 'common/components';

import { getTicketGrid } from 'services/ticketingSystem';

export const getKeyTicketGrid = () => {
  return ['ticketing-system/get-ticket-grid'];
};

export const useGetTicketGrid = () => {
  const { isLoading, data } = useQuery({
    queryKey: getKeyTicketGrid(),
    queryFn: async () => {
      const { data } = await getTicketGrid({ pageSize: 9999, pageIndex: 1 });

      return data.gridData || [];
    },
    onSuccess: () => {},
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });
  return {
    ticketList: data || null,
    isLoading,
  };
};
