import React, { useState } from 'react';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/home';
import FolderEditModal from 'pages/folders/components/modal/FolderEditModal';

export const WIDTH_CONTANT_PANE = 500;

const EditFolderDetail = (props) => {
  const {
    selectedFolders,

    onReloadWhenSaveSuccess,
    disabled,
  } = props;

  const [visible, setVisible] = useState(false);

  const clickEditFolderHandler = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };
  const onSaveSuccess = () => {
    onCancel();

    onReloadWhenSaveSuccess &&
      onReloadWhenSaveSuccess(selectedFolders.id, 'edit');
  };

  return (
    <>
      <RibbonButton
        size='small'
        icon={<FormOutlined />}
        label={Messages.editFoldersRibbon}
        onClick={clickEditFolderHandler}
        disabled={disabled}
      />
      {visible ? (
        <FolderEditModal
          visible={visible}
          onCancel={onCancel}
          onSaveSuccess={onSaveSuccess}
          folderInfo={selectedFolders}
          idSelected={selectedFolders?.id}
        />
      ) : null}
    </>
  );
};

export default EditFolderDetail;
