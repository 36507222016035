import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction, CustomNotification } from 'common/components';
// redux
import { useSelector } from 'react-redux';
import gridSelector from 'common/components/grid-view/controllers/selectors';
// hooks
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useDeleteQasRequiredFields } from 'hooks/qas-require-field/useQasRequireField';

const DeleteQasRequireField = () => {
  const reloadPage = useDispatchReloadPage();
  const deleteQas = useDeleteQasRequiredFields();

  const selectedDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const id =
    selectedDetail && selectedDetail.length > 0
      ? selectedDetail[0].id
      : undefined;

  const handleDeleteModal = () => {
    dialogFunction({
      type: 'warn',
      content: `Are you sure you want to delete selected item?`,
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDelete,
    });
  };

  const handleDelete = () => {
    deleteQas.mutate(
      { id },
      {
        onSuccess: (response) => {
          if (response?.isSuccess) {
            CustomNotification.success('Delete successfully');
            reloadPage();
          } else {
            CustomNotification.error(
              response?.message || 'Delete failed! Please try!'
            );
          }
        },
        onError: (error) => {
          CustomNotification.error(
            error?.message || 'Delete failed! Please try!'
          );
        },
      }
    );
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={'Delete'}
      onClick={handleDeleteModal}
      disabled={id === undefined || selectedDetail.length !== 1}
    />
  );
};

export default DeleteQasRequireField;
