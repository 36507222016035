import React, { useState, useRef, useMemo } from 'react';

import { Row, Col, Typography, Space } from 'antd';

import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';

import {
  FormAddButton,
  FormDeleteButton,
  FormCancelButton,
  dialogFunction,
  CustomNotification,
  WithLoading,
} from 'common/components';

import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';
import QaGridEdit from '../qa-grid-edit/QaGridEdit';
import { EditSection } from './QaSpecProcessStage';

import {
  getDeletedLocationMutationKey,
  useAddManufacturingLocation,
  useDeleteManufacturingLocation,
  useManufacturingLocation,
  useQaGetManufacturingLocationsSnapshot,
} from './hooks';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import { generateLocationColumDefs } from './utils';

import QaSpecLinkFacilityModal from './QaSpecLinkFacilityModal';

import { useIsMutating } from '@tanstack/react-query';

import './QaSpecManufacturingProcess.less';

const QaSpecManufacturingLocation = ({ tableStyle, productId, ownerId }) => {
  const [editMode, setEditMode] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const [linkModalVisible, setLinkModalVisible] = useState(false);

  const addLocation = useAddManufacturingLocation();
  const deleteManufacturingLocation = useDeleteManufacturingLocation();

  const gridApi = useRef();

  const { rowData, refetchManufacturingLocation, isFetching } =
    useManufacturingLocation(productId);

  const { qaManufacturingLocationsSnapshot } =
    useQaGetManufacturingLocationsSnapshot();

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const isLoading = useIsMutating({
    mutationKey: getDeletedLocationMutationKey(),
  });

  const handleAdd = () => {
    setLinkModalVisible(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setSelectedRows([]);
    gridApi.current.api.setRowData(rowData);
  };

  const resetStateWhenAddingOrDeletingSuccess = () => {
    refetchManufacturingLocation();
    setEditMode(false);
    setSelectedRows([]);
  };

  const linkFacilityLocation = async (selectedLocation) => {
    if (gridApi?.current) {
      const idSelectedList = selectedLocation.map((item) => item.id);
      await addLocation.mutateAsync(
        {
          locations: idSelectedList,
          productId,
        },
        {
          onSuccess: (result) => {
            const { message, isSuccess } = result;

            if (isSuccess) {
              CustomNotification.success(
                'Add Manufacturing Location successfully.'
              );
              resetStateWhenAddingOrDeletingSuccess();
              closeLinkModal();
            } else {
              CustomNotification.error(message);
            }
          },
        }
      );
    }
  };

  const handleDeleteProcessStage = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: async () => {
        const selectedRows = gridApi.current.api.getSelectedNodes();

        const removeList = selectedRows.map(
          (selectedRowItem) => selectedRowItem?.data
        );

        const removedIdList = removeList.map((item) => item.id);

        await deleteManufacturingLocation.mutateAsync(
          {
            locations: removedIdList,
            productId,
          },
          {
            onSuccess: (result) => {
              const { message, isSuccess } = result;

              if (isSuccess) {
                CustomNotification.success(
                  'Delete Manufacturing Location successfully.'
                );
                resetStateWhenAddingOrDeletingSuccess();
              } else {
                CustomNotification.error(message);
              }
            },
          }
        );
      },
    });
  };

  const closeLinkModal = () => {
    setLinkModalVisible(false);
  };

  return (
    <div className='process-stage' style={{ marginBottom: 12 }}>
      <Row style={{ width: '100%' }}>
        <Col span={6}>
          <Typography.Title level={5} strong style={{ marginBottom: 0 }}>
            Manufacturing Location(s)
          </Typography.Title>
        </Col>
        <Col span={18}>
          {!isQASpecLocked ? (
            <EditSection
              isEditMode={editMode}
              onEditBtnClick={() => setEditMode(true)}
              productId={productId}
              style={{ marginRight: 4, width: '100%', height: 30 }}
            >
              <Space direction='horizontal' size={4}>
                <FormAddButton text='Add' onClick={handleAdd} />

                <FormDeleteButton
                  onClick={handleDeleteProcessStage}
                  disabled={selectedRows?.length === 0}
                  loading={isLoading > 0}
                />
                <EditTabSectionWrapper.CancelActionTriggerWrapper
                  onCancel={handleCancel}
                  type={EDIT_SECTION.QA_MANUFACTURING_LOCATION}
                >
                  <FormCancelButton onClick={handleCancel} />
                </EditTabSectionWrapper.CancelActionTriggerWrapper>
              </Space>
            </EditSection>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <WithLoading loading={isFetching}>
            <EditTabSectionWrapper.CheckerWrapper
              type={EDIT_SECTION.QA_MANUFACTURING_LOCATION}
              isEdit={editMode}
              hideSaveBtn={true}
            >
              <ManufacturingLocationGrid
                editMode={editMode}
                gridApi={gridApi}
                rowData={rowData}
                tableStyle={tableStyle}
                onRowSelected={() => {
                  const selectedRows = gridApi.current.api.getSelectedNodes();
                  setSelectedRows(selectedRows);
                }}
                qaManufacturingLocationsSnapshot={
                  qaManufacturingLocationsSnapshot
                }
              />
            </EditTabSectionWrapper.CheckerWrapper>
          </WithLoading>
        </Col>
      </Row>

      <QaSpecLinkFacilityModal
        visible={linkModalVisible}
        productId={productId}
        ownerId={ownerId}
        closeModal={closeLinkModal}
        onOk={linkFacilityLocation}
      />
    </div>
  );
};

const ManufacturingLocationGrid = ({
  rowData,
  editMode,
  gridApi,
  tableStyle,
  onRowSelected,
  qaManufacturingLocationsSnapshot,
}) => {
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const mappedColumnDef = useMemo(() => {
    return generateLocationColumDefs({
      isToggleSnapshot,
    });
  }, [isToggleSnapshot]);

  return (
    <Row style={{ width: '100%', marginTop: 8, ...tableStyle }}>
      {mappedColumnDef?.length > 0 && (
        <div
          style={{
            ...tableStyle,
            width: '100%',
          }}
          className={classnames(
            'ag-theme-alpine',
            'ag-theme-alpine--with-scroller',
            'process-stage__grid-container'
          )}
        >
          <QaGridEdit
            gridInst={gridApi}
            fields={mappedColumnDef}
            defaultColDef={{
              resizable: true,
            }}
            isEdit={editMode}
            gridData={cloneDeep(rowData)}
            getRowNodeId={(data) => {
              return data?.id;
            }}
            onRowSelected={onRowSelected}
            snapshotGridValues={qaManufacturingLocationsSnapshot}
          />
        </div>
      )}
    </Row>
  );
};

export default QaSpecManufacturingLocation;
