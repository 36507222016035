import React, { useMemo } from 'react';
import reactCSS from 'reactcss';
import { isArray } from 'lodash';
import { Typography, Tooltip } from 'antd';

const { Text } = Typography;

const styles = reactCSS({
  default: {
    wrapper: { paddingRight: 17, paddingLeft: 7, position: 'relative' },
    contentWrapper: { overflow: 'hidden' },
    content: {
      display: 'inline-block',
      width: 'max-content',
    },
    text: {
      display: 'inline-block',
      alignItems: 'center',
      marginRight: 6,
    },
    dot: {
      position: 'absolute',
      right: 4,
      zIndex: 10,
      bottom: 1,
    },
  },
});

const CountryEditorRender = (params) => {
  const { value, wrapperStyle = {} } = params;

  const country = useMemo(() => {
    if (isArray(value)) {
      return value;
    } else if (value) {
      return value?.split(', ') || [];
    } else {
      return [];
    }
  }, [value]);

  return (
    <div style={{ ...styles.wrapper, ...wrapperStyle }}>
      <Tooltip
        title={country.join(', ')}
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
      >
        <div style={styles.contentWrapper}>
          <Text style={styles.text} ellipsis>
            {country.join(', ')}
          </Text>
        </div>
      </Tooltip>
    </div>
  );
};

export default CountryEditorRender;
