import React, { useCallback, useEffect, useMemo } from 'react';

import { Input, InputNumber, Select, Col, Row, Typography } from 'antd';

import { useParams } from 'react-router-dom';

import classnames from 'classnames';

import {
  WithLoading,
  Form,
  WrapperSelect,
  SectionWrapper,
} from 'common/components';
import {
  SnapshotInputWrapper,
  SnapshotFormItemWrapper,
  FormLabel,
} from 'pages/qa-spec/components/form';

import {
  EDIT_SECTION,
  EditTabSectionWrapper,
} from 'pages/qa-spec/components/edit-tab-checker';

import EditContainer from '../container/EditContainer';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import { useGetEnumsByEnumName } from 'hooks';
import {
  useGetFreshDeli,
  useSaveFreshDeli,
  useQueryQaFreshDeliSnapshot,
} from './hooks';

import { useGetSnapshotFormItemWrapperProps } from 'pages/qa-spec/components/form/hooks';

import './QaFreshDeli.less';

const SMOKE_TYPE_ENUM = 'SmokeTypeEnum';
const RENNET_ENUM = 'RennetEnum';
const PASTEURIZING_METHOD_ENUM = 'PasteurizingMethodEnum';
const YES_NO_ENUM = ['Yes', 'No'];

const NAME_FORM_MEAL = 'qaSpecFreshDeliMeat';
const NAME_FORM_CHEESE = 'qaSpecFreshDeliCheese';

const QaFreshDeli = () => {
  const [formInstance] = Form.useForm();

  const { id: productId } = useParams();

  const smokeTypeEnum = useGetEnumsByEnumName(SMOKE_TYPE_ENUM);
  const rennetEnum = useGetEnumsByEnumName(RENNET_ENUM);
  const pasteurizingMethodEnumEnum = useGetEnumsByEnumName(
    PASTEURIZING_METHOD_ENUM
  );

  const useSnapshot = useCheckSnapshotForRetailer();

  const { data: freshDeliData, isLoading } = useGetFreshDeli(productId);
  const { data: freshDeliSnapshotData } = useQueryQaFreshDeliSnapshot({
    productId,
    enabled: Boolean(productId && useSnapshot),
  });
  const freshDeliMutation = useSaveFreshDeli(productId);

  useEffect(() => {
    if (freshDeliData) {
      formInstance.setFieldsValue({ ...freshDeliData });
    }
  }, [formInstance, freshDeliData]);

  const handleSave = (toggleEditMode) => {
    const formValues = formInstance.getFieldsValue() ?? {};
    const params = {
      productId,
      ...formValues,
    };

    freshDeliMutation.mutate(params, { onSuccess: () => toggleEditMode() });
  };

  const handleCancel = () => {
    formInstance.setFieldsValue({ ...freshDeliData });
  };

  const { getSnapshotFormItemWrapperProps } =
    useGetSnapshotFormItemWrapperProps({
      snapshotValues: freshDeliSnapshotData,
    });

  return (
    <Form
      className='qa-fresh-deli'
      form={formInstance}
      style={{ paddingRight: 5 }}
      labelCol={{ flex: '166px' }}
    >
      <WithLoading loading={isLoading || freshDeliMutation.isLoading}>
        <EditContainer
          type={EDIT_SECTION.PROD_FRESH_DELI}
          productId={productId}
          saveLoading={freshDeliMutation.isLoading}
          handleSave={handleSave}
          handleCancel={handleCancel}
          float
        >
          {(toggleEditMode) => {
            const disabled = !toggleEditMode;

            return (
              <EditTabSectionWrapper.CheckerWrapper
                isEdit={toggleEditMode}
                type={EDIT_SECTION.PROD_FRESH_DELI}
              >
                <Row
                  gutter={[0, 12]}
                  className={classnames('qa-fresh-deli__input', {
                    'qa-fresh-deli__input--edit': toggleEditMode,
                  })}
                >
                  <Col span={24}>
                    <SectionWrapper title='Meat'>
                      <Row gutter={[24, 12]}>
                        <Col span={10}>
                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_MEAL, 'proteinType'],
                              label: 'Protein Type',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_MEAL, 'proteinType']}
                                label={<FormLabel label='Protein Type' />}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <Input readOnly={disabled} />
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>
                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_MEAL, 'protein'],
                              label: 'Protein %',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_MEAL, 'protein']}
                                label={<FormLabel label='Protein %' />}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <InputNumber
                                    min={0}
                                    readOnly={disabled}
                                    style={{ width: '100%' }}
                                  />
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>

                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_MEAL, 'smoked'],
                              label: 'Smoked',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_MEAL, 'smoked']}
                                label={<FormLabel label='Smoked' />}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <WrapperSelect
                                    listHeight={140}
                                    disabled={disabled}
                                  >
                                    {YES_NO_ENUM.map((value) => (
                                      <Select.Option key={value} value={value}>
                                        {value}
                                      </Select.Option>
                                    ))}
                                  </WrapperSelect>
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>
                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_MEAL, 'smokeType'],
                              label: 'Smoke Type',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_MEAL, 'smokeType']}
                                label={<FormLabel label='Smoke Type' />}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <WrapperSelect disabled={disabled}>
                                    {smokeTypeEnum.map(
                                      ({ enumDisplayName }) => (
                                        <Select.Option
                                          key={enumDisplayName}
                                          value={enumDisplayName}
                                        >
                                          {enumDisplayName}
                                        </Select.Option>
                                      )
                                    )}
                                  </WrapperSelect>
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>
                        </Col>
                        <Col span={14}>
                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_MEAL, 'composition'],
                              label:
                                'Composition (Ex.  Chopped and Formed, Whole Muscle, etc)',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_MEAL, 'composition']}
                                label={
                                  <FormLabel label='Composition (Ex.  Chopped and Formed, Whole Muscle, etc)' />
                                }
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                    maxLength={500}
                                    showCount
                                    readOnly={disabled}
                                  />
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>
                        </Col>
                      </Row>
                    </SectionWrapper>
                  </Col>
                  <Col span={24}>
                    <SectionWrapper title='Cheese'>
                      <Row gutter={[24, 12]}>
                        <Col span={10}>
                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_CHEESE, 'cheeseType'],
                              label: 'Cheese Type',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_CHEESE, 'cheeseType']}
                                label={<FormLabel label='Cheese Type' />}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                    readOnly={disabled}
                                    maxLength={500}
                                    showCount
                                  />
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>

                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_CHEESE, 'milkType'],
                              label: 'Milk Type',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_CHEESE, 'milkType']}
                                label={<FormLabel label='Milk Type' />}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                    readOnly={disabled}
                                    maxLength={500}
                                    showCount
                                  />
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>
                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_CHEESE, 'processFlows'],
                              label: 'Process Flows',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_CHEESE, 'processFlows']}
                                label={<FormLabel label='Process Flows' />}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                    readOnly={disabled}
                                    maxLength={500}
                                    showCount
                                  />
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>
                          <SnapshotFormItemWrapper
                            {...getSnapshotFormItemWrapperProps({
                              fieldPath: [NAME_FORM_CHEESE, 'agingDetails'],
                              label: 'Aging Details',
                            })}
                          >
                            {(inputWrapperProps) => (
                              <Form.Item
                                name={[NAME_FORM_CHEESE, 'agingDetails']}
                                label={<FormLabel label='Aging Details' />}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <SnapshotInputWrapper
                                  {...inputWrapperProps}
                                  popoverProps={{ visible: false }}
                                >
                                  <Input.TextArea
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                    readOnly={disabled}
                                    maxLength={500}
                                    showCount
                                  />
                                </SnapshotInputWrapper>
                              </Form.Item>
                            )}
                          </SnapshotFormItemWrapper>
                        </Col>
                        <Col span={14}>
                          <Row>
                            <Col span={12}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [NAME_FORM_CHEESE, 'rind'],
                                  label: 'Rind',
                                })}
                              >
                                {(inputWrapperProps) => (
                                  <Form.Item
                                    name={[NAME_FORM_CHEESE, 'rind']}
                                    label={<FormLabel label='Rind' />}
                                  >
                                    <SnapshotInputWrapper
                                      {...inputWrapperProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <WrapperSelect
                                        listHeight={140}
                                        disabled={disabled}
                                      >
                                        {YES_NO_ENUM.map((value) => (
                                          <Select.Option
                                            key={value}
                                            value={value}
                                          >
                                            {value}
                                          </Select.Option>
                                        ))}
                                      </WrapperSelect>
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                            <Col span={12}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [NAME_FORM_CHEESE, 'rindEdible'],
                                  label: 'Rind Edible',
                                })}
                              >
                                {(inputWrapperProps) => (
                                  <Form.Item
                                    name={[NAME_FORM_CHEESE, 'rindEdible']}
                                    label={<FormLabel label='Rind Edible' />}
                                  >
                                    <SnapshotInputWrapper
                                      {...inputWrapperProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <WrapperSelect
                                        listHeight={140}
                                        disabled={disabled}
                                      >
                                        {YES_NO_ENUM.map((value) => (
                                          <Select.Option
                                            key={value}
                                            value={value}
                                          >
                                            {value}
                                          </Select.Option>
                                        ))}
                                      </WrapperSelect>
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                            <Col span={12}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [NAME_FORM_CHEESE, 'ccp'],
                                  label: 'CCP (Critical Control Point)',
                                })}
                              >
                                {(inputWrapperProps) => (
                                  <Form.Item
                                    name={[NAME_FORM_CHEESE, 'ccp']}
                                    label={
                                      <FormLabel label='CCP (Critical Control Point)' />
                                    }
                                  >
                                    <SnapshotInputWrapper
                                      {...inputWrapperProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <WrapperSelect
                                        listHeight={140}
                                        disabled={disabled}
                                      >
                                        {YES_NO_ENUM.map((value) => (
                                          <Select.Option
                                            key={value}
                                            value={value}
                                          >
                                            {value}
                                          </Select.Option>
                                        ))}
                                      </WrapperSelect>
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                            <Col span={12}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [NAME_FORM_CHEESE, 'pasteurized'],
                                  label: 'Pasteurized',
                                })}
                              >
                                {(inputWrapperProps) => (
                                  <Form.Item
                                    name={[NAME_FORM_CHEESE, 'pasteurized']}
                                    label={<FormLabel label='Pasteurized' />}
                                  >
                                    <SnapshotInputWrapper
                                      {...inputWrapperProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <WrapperSelect
                                        listHeight={140}
                                        disabled={disabled}
                                      >
                                        {YES_NO_ENUM.map((value) => (
                                          <Select.Option
                                            key={value}
                                            value={value}
                                          >
                                            {value}
                                          </Select.Option>
                                        ))}
                                      </WrapperSelect>
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                            <Col span={24}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [NAME_FORM_CHEESE, 'rennet'],
                                  label: 'Rennet',
                                })}
                              >
                                {(inputWrapperProps) => (
                                  <Form.Item
                                    name={[NAME_FORM_CHEESE, 'rennet']}
                                    label={<FormLabel label='Rennet' />}
                                  >
                                    <SnapshotInputWrapper
                                      {...inputWrapperProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <WrapperSelect
                                        listHeight={140}
                                        disabled={disabled}
                                      >
                                        {rennetEnum.map(
                                          ({ enumDisplayName }) => (
                                            <Select.Option
                                              key={enumDisplayName}
                                              value={enumDisplayName}
                                            >
                                              {enumDisplayName}
                                            </Select.Option>
                                          )
                                        )}
                                      </WrapperSelect>
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                            <Col span={24}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [
                                    NAME_FORM_CHEESE,
                                    'pasteurizingMethod',
                                  ],
                                  label: 'Pasteurizing Method',
                                })}
                              >
                                {(inputWrapperProps) => (
                                  <Form.Item
                                    name={[
                                      NAME_FORM_CHEESE,
                                      'pasteurizingMethod',
                                    ]}
                                    label={
                                      <FormLabel label='Pasteurizing Method' />
                                    }
                                  >
                                    <SnapshotInputWrapper
                                      {...inputWrapperProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <WrapperSelect
                                        listHeight={140}
                                        disabled={disabled}
                                      >
                                        {pasteurizingMethodEnumEnum.map(
                                          ({ enumDisplayName }) => (
                                            <Select.Option
                                              key={enumDisplayName}
                                              value={enumDisplayName}
                                            >
                                              {enumDisplayName}
                                            </Select.Option>
                                          )
                                        )}
                                      </WrapperSelect>
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                            <Col span={24}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [
                                    NAME_FORM_CHEESE,
                                    'productionControls',
                                  ],
                                  label: 'Production Controls',
                                })}
                              >
                                {(inputWrapperProps) => (
                                  <Form.Item
                                    name={[
                                      NAME_FORM_CHEESE,
                                      'productionControls',
                                    ]}
                                    label={
                                      <FormLabel label='Production Controls' />
                                    }
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SnapshotInputWrapper
                                      {...inputWrapperProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <Input.TextArea
                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                        readOnly={disabled}
                                        maxLength={500}
                                        showCount
                                      />
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                            <Col span={24}>
                              <SnapshotFormItemWrapper
                                {...getSnapshotFormItemWrapperProps({
                                  fieldPath: [
                                    NAME_FORM_CHEESE,
                                    'productionControls',
                                  ],
                                  label: 'Production Controls',
                                })}
                              >
                                {(inputProps) => (
                                  <Form.Item
                                    name={[NAME_FORM_CHEESE, 'qualityControls']}
                                    label={
                                      <FormLabel label='Quality Controls' />
                                    }
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SnapshotInputWrapper
                                      {...inputProps}
                                      popoverProps={{ visible: false }}
                                    >
                                      <Input.TextArea
                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                        maxLength={500}
                                        showCount
                                        readOnly={disabled}
                                      />
                                    </SnapshotInputWrapper>
                                  </Form.Item>
                                )}
                              </SnapshotFormItemWrapper>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </SectionWrapper>
                  </Col>
                </Row>
              </EditTabSectionWrapper.CheckerWrapper>
            );
          }}
        </EditContainer>
      </WithLoading>
    </Form>
  );
};

export default QaFreshDeli;
