import React from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/home';

// import { ReactComponent as IconOpenAsset } from 'common/components/button/svg-icons/ICO_69.svg';
import { FolderOpenOutlined } from '@ant-design/icons';

import { forwardTo } from 'utils/common/route';
import RibbonButton from 'common/components/button/RibbonButton';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const OpenAsset = () => {
  const selectedAssetDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const assetId =
    selectedAssetDetail && selectedAssetDetail?.length > 0
      ? selectedAssetDetail[0]?.id
      : undefined;

  return (
    <RibbonButton
      icon={<FolderOpenOutlined />}
      label={Messages.openAsset}
      onClick={() => forwardTo(`/asset/${assetId}`)}
      disabled={assetId === undefined || selectedAssetDetail.length !== 1}
    />
  );
};

export default OpenAsset;
