import { JITSI_DOMAIN } from 'static/Constants';

export const loadJitsi = () =>
  new Promise(async (resolve, reject) => {
    if (window.JitsiMeetExternalAPI) {
      resolve(window.JitsiMeetExternalAPI);
    } else {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');

      script.setAttribute('type', 'text/javascript');
      script.setAttribute(
        'src',
        'https://' + JITSI_DOMAIN + '/external_api.js'
      );

      script.addEventListener(
        'load',
        function (event) {
          if (event.target.nodeName === 'SCRIPT') {
            resolve(window.JitsiMeetExternalAPI);
          }
        },
        true
      );

      script.onerror = function (err) {
        reject('Fail to start video server. Please try again!');
      };

      head.appendChild(script);
    }
  });
