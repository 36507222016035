import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Select, Row } from 'antd';
import * as Const from 'static/Constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from 'i18n/messages/assetDetail';
import {
  Form,
  FormSaveButton,
  FormCancelButton,
  WrapperSelect,
  FlagCountrySelectionFieldInput,
} from 'common/components';
import { DownOutlined } from '@ant-design/icons';
import './style.less';
import * as assetSelectors from 'pages/branded-assets/controllers/selectors';

import { sort } from 'lodash';

const AssetEdit = (props) => {
  const { onSubmit, onCancel, typeData } = props;
  const [form] = Form.useForm();
  const [type, setType] = useState(typeData?.assetType);
  const [subType, setSubType] = useState(typeData?.assetSubType);
  const { Option } = Select;
  const initialForm = {
    assetType: null,
    assetSubType: null,
    packageLevel: null,
    packagingDisposition: null,
    angle: null,
    facing: null,
    language: null,
    panelType: null,
    logoType: null,
    certificateType: null,
    documentType: null,
  };

  const assetLoading = useSelector(assetSelectors.selectEditTypeLoading());

  const getCodeByCountry = (values) => {
    return Object.keys(customLabels).find(
      (key) => customLabels[key] === values
    );
  };
  const enableCountry = [
    'US',
    'FR',
    'ES',
    'PT',
    'DE',
    'IN',
    'IT',
    'RU',
    'IR',
    'CN',
  ];
  const customLabels = {
    US: 'English',
    FR: 'French',
    ES: 'Spanish',
    PT: 'Portuguese',
    DE: 'German',
    IN: 'Hindil',
    IT: 'Italian',
    RU: 'Russian',
    IR: 'Persian',
    CN: 'Chinese',
  };

  useEffect(() => {
    form.setFieldsValue(typeData);
    setType(typeData?.assetType);
    setSubType(typeData?.assetSubType);
  }, [typeData]);

  return (
    <>
      <Form
        form={form}
        name='asset-type-form'
        id='asset-type-form'
        onFinish={(value) => onSubmit(value)}
      >
        <div className='asset-type-form__edit-button-group'>
          <Row justify='end'>
            <FormSaveButton
              loading={assetLoading}
              onClick={() => form.submit()}
            />
            <FormCancelButton
              onClick={() => {
                form.setFieldsValue(initialForm);
                setType(null);
                setSubType(null);
                onCancel();
              }}
            />
          </Row>
        </div>
        <Form.Item
          name='assetType'
          label='Type: '
          rules={[
            {
              required: true,
              message: 'Please choose asset type',
            },
          ]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
        >
          <WrapperSelect
            onChange={(value) => {
              setType(value);
              setSubType(null);
              form.setFieldsValue({ ...initialForm, assetType: value });
            }}
          >
            {Const.ASSET_TYPE?.slice()
              .sort()
              .map((item, index) => {
                return (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                );
              })}
          </WrapperSelect>
        </Form.Item>
        {type && (
          <Form.Item
            name='assetSubType'
            label='Sub type: '
            rules={[
              {
                required: true,
                message: 'Please choose asset subtype',
              },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
          >
            <WrapperSelect
              onChange={(value) => {
                setSubType(value);
                form.setFieldsValue({
                  ...initialForm,
                  assetSubType: value,
                  assetType: type,
                });
              }}
            >
              {type === 'Asset'
                ? Const.ASSET_SUBTYPE?.slice()
                    .sort()
                    .map((item, index) => {
                      return (
                        <Option value={item} key={index}>
                          {item}
                        </Option>
                      );
                    })
                : type === 'Company'
                ? Const.COMPANY_SUBTYPE.sort().map((item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    );
                  })
                : type === 'Document'
                ? Const.DOCUMENT_SUBTYPE.sort().map((item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    );
                  })
                : type === 'Multimedia'
                ? Const.MULTIMEDIA_SUBTYPE.sort().map((item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    );
                  })
                : null}
            </WrapperSelect>
          </Form.Item>
        )}

        {subType === 'Planogram' ? (
          <>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name='packageLevel'
              label={<FormattedMessage {...messages.gsPackageLevel} />}
            >
              <WrapperSelect
                getPopupContainer={() =>
                  document.getElementById('asset-type-form')
                }
              >
                {Const.PACKAGE_LEVEL?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name='packagingDisposition'
              label={<FormattedMessage {...messages.gsPackagingDisposition} />}
            >
              <WrapperSelect
                getPopupContainer={() =>
                  document.getElementById('asset-type-form')
                }
              >
                {Const.PACKAGING_DISPOSITION?.slice()
                  .sort()
                  .map((item, index) => {
                    return (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
              </WrapperSelect>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name='angle'
              label={<FormattedMessage {...messages.gsAngle} />}
            >
              <WrapperSelect
                getPopupContainer={() =>
                  document.getElementById('asset-type-form')
                }
              >
                {Const.ANGLE?.slice()
                  .sort()
                  .map((item, index) => {
                    return (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
              </WrapperSelect>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name='facing'
              label={<FormattedMessage {...messages.gsFacing} />}
            >
              <WrapperSelect
                getPopupContainer={() =>
                  document.getElementById('asset-type-form')
                }
              >
                {Const.FACING?.slice()
                  .sort()
                  .map((item, index) => {
                    return (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
              </WrapperSelect>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name='language'
              label={<FormattedMessage {...messages.gsLanguage} />}
            >
              {/* <WrapperSelect
                getPopupContainer={() =>
                  document.getElementById('asset-type-form')
                }>
                {Const.LANGUAGE_LIST.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select> */}

              <FlagCountrySelectionFieldInput
                searchable={true}
                className={'asset-type-form__country-form'}
                placeholder={''}
                defaultCountry={getCodeByCountry(typeData?.language)}
                isLanguage
                onSelect={(value) => {
                  form.setFieldsValue({
                    ...form.getFieldValue(),
                    language: customLabels[value],
                  });
                }}
              />
              <DownOutlined className='asset-type-form__country-form--icon' />
            </Form.Item>
          </>
        ) : subType === 'Panel' ? (
          <>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name='panelType'
              label={'Panel type:'}
            >
              <WrapperSelect
                getPopupContainer={() =>
                  document.getElementById('asset-type-form')
                }
              >
                {Const.ASSET_PANEL_FIELD?.slice()
                  .sort()
                  .map((item, index) => {
                    return (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
              </WrapperSelect>
            </Form.Item>
          </>
        ) : subType === 'Logo' ? (
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='logoType'
            label={'Logo type:'}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
            >
              {Const.COMPANY_LOGO_FIELD?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
        ) : subType === 'Certificate' ? (
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='certificateType'
            label={'Certificate type:'}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
            >
              {Const.DOCUMENT_CERTIFICATE_FIELD?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
        ) : subType === 'Company' ? (
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='documentType'
            label={'Document type:'}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
            >
              {Const.DOCUMENT_COMPANY_FIELD?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
        ) : null}
      </Form>
    </>
  );
};

export default injectIntl(AssetEdit);
