export const noModuleProductPropertiesSchema = [
  {
    name: 'ownerId',
    type: 'number',
  },
  {
    name: 'brandId',
    type: 'number',
  },
  {
    name: 'productName',
    type: 'text',
  },
  {
    name: 'productType',
    type: 'text',
  },
  {
    name: 'productDescription',
    type: 'text',
  },
  {
    name: 'status',
    type: 'select',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Pending', value: 'Pending' },
    ],
  },
  {
    name: 'visibility',
    type: 'select',
    options: [
      {
        label: 'Only If Shared',
        value: 'Only If Shared',
      },
      {
        label: 'Private',
        value: 'Private',
      },
      {
        label: 'Public',
        value: 'Public',
      },
    ],
  },
  {
    name: 'packageLevel',
    type: 'text',
    options: ['Unit', 'Inner Pack', 'Case', 'Master Case', 'Pallet'].map(
      (level) => ({ label: level, value: level })
    ),
  },
  {
    name: 'syndication',
    type: 'checkbox',
  },
  {
    name: 'upc8',
    type: 'numberText',
  },
  {
    name: 'upc10',
    type: 'numberText',
  },
  {
    name: 'upc11',
    type: 'numberText',
  },
  {
    name: 'upc12',
    type: 'numberText',
  },
  {
    name: 'upc13',
    type: 'numberText',
  },
  {
    name: 'gtin',
    type: 'numberText',
  },
  {
    name: 'targetMarket',
    type: 'custom',
  },
  {
    name: 'rivirId',
    type: 'text',
  },
  {
    name: 'ixOneId',
    type: 'text',
  },
  {
    name: 'segmentName',
    type: 'text',
  },
  {
    name: 'brickCode',
    type: 'custom',
  },
  {
    name: 'brickCodeName',
    type: 'custom',
  },
  {
    name: 'primarySize',
    type: 'number',
  },
  {
    name: 'primarySizeUOM',
    type: 'select',
  },
  {
    name: 'height',
    type: 'number',
  },
  {
    name: 'width',
    type: 'number',
  },
  {
    name: 'depth',
    type: 'number',
  },
  {
    name: 'weight',
    type: 'number',
  },
  {
    name: 'dimensionUOM',
    type: 'select',
  },
  {
    name: 'weightUOM',
    type: 'select',
  },
  {
    name: 'imperialHeight',
    type: 'number',
  },
  {
    name: 'imperialWidth',
    type: 'number',
  },
  {
    name: 'imperialDepth',
    type: 'number',
  },
  {
    name: 'imperialWeight',
    type: 'number',
  },
  {
    name: 'imperialDimensionUOM',
    type: 'select',
  },
  {
    name: 'imperialWeightUOM',
    type: 'select',
  },
  {
    name: 'certifications',
    type: 'text',
  },
  {
    name: 'extendedProductInformation',
    type: 'text',
  },
  {
    name: 'backgroundConfiguration',
    type: 'text',
  },
  {
    name: 'gdsnShield',
    type: 'text',
  },
  {
    name: 'meetMinRequirement',
    type: 'checkbox',
  },
  {
    name: 'id',
    type: 'number',
  },
  {
    name: 'createdBy',
    type: 'number',
  },
  {
    name: 'created',
    type: 'datePicker',
  },
  {
    name: 'lastModifiedBy',
    type: 'number',
  },
  {
    name: 'lastModified',
    type: 'datePicker',
  },
  {
    name: 'Member.MemberName',
    type: 'text',
  },
  {
    name: 'Member.MemberRoleName',
    type: 'text',
  },
  {
    name: 'BrandName',
    type: 'text',
  },
];

export const CUSTOM_INPUT_TYPES = [
  'brickCode',
  'brickCodeName',
  'targetMarket',
];
