import { useEffect } from 'react';
import { useAsync } from './useAsync';
import { getMemberSupplier } from 'services/memberMaintenance';

export const useGetMemberSupplier = () => {
  const { data, run, status } = useAsync();
  const handleGetApi = () => {
    run(getMemberSupplier());
  };

  useEffect(() => {
    handleGetApi();
  }, []);

  return {
    memberSupplier: data ?? [],
    isLoading: status === 'pending',
  };
};
