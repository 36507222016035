import { put, call, takeLatest, all } from 'redux-saga/effects';

import * as types from './constants';
import * as globalActions from './actions';
import * as actions from './actions';

import * as servicesEmail from 'services/email';

import mapEmailsWithAttachUrl from '../mapper/mapEmailsWithAttachUrl';

export function* getEmailThreadListSaga(action) {
  try {
    const response = yield call(
      servicesEmail.getEmailThreadList,
      action.params
    );

    if (response?.isSuccess) {
      yield put(actions.getEmailThreadListSuccess(response?.data));
    } else {
      yield put(actions.getEmailThreadListFail(response?.message));
    }
  } catch (error) {
    yield put(globalActions.getEmailThreadListFail(error));
  }
}

export function* getEmailThreadListMoreSaga(action) {
  try {
    const response = yield call(
      servicesEmail.getEmailThreadList,
      action.payload
    );

    if (response?.isSuccess) {
      yield put(actions.getEmailThreadListMoreSuccess(response?.data));
    } else {
      yield put(actions.getEmailThreadListMoreFail(response?.message));
    }
  } catch (error) {
    yield put(globalActions.getEmailThreadListMoreFail(error));
  }
}

export function* getEmailThreadInfoSaga(action) {
  try {
    const response = yield call(
      servicesEmail.getEmailThreadInfo,
      action.payload
    );

    if (response?.isSuccess) {
      yield put(actions.getEmailThreadInfoSuccess(response?.data));
    } else {
      yield put(actions.getEmailThreadInfoFail(response?.message));
    }
  } catch (error) {
    yield put(globalActions.getEmailThreadInfoFail(error));
  }
}

export function* getEmailThreadInfoContent(action) {
  try {
    const response = yield call(
      servicesEmail.getEmailThreadInfoContent,
      action
    );

    if (response.isSuccess) {
      const threadMessages = response?.data?.threadMessages;

      const handledThreadMessages = yield call(
        mapEmailsWithAttachUrl,
        threadMessages,
        action?.threadId
      );

      yield put(
        actions.getEmailThreadInfoContentSuccess(handledThreadMessages)
      );
      //* get latest message
      if (!action.timestamp && threadMessages.length) {
        yield put(actions.updateLastMessage(threadMessages[0].messageId));
      }
    } else {
      yield put(actions.getEmailThreadInfoContentFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getEmailThreadInfoContentFail(error));
  }
}

export function* getEmailThreadInfoFiles(action) {
  try {
    const response = yield call(
      servicesEmail.getEmailThreadInfoFiles,
      action.params
    );
    if (response?.isSuccess) {
      yield put(actions.getEmailThreadInfoFilesSuccess(response.data));
    } else {
      yield put(actions.getEmailThreadInfoFilesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getEmailThreadInfoFilesFail(error));
  }
}

export default function* watchAll() {
  yield all([takeLatest(types.GET_EMAIL_THREAD_LIST, getEmailThreadListSaga)]);
  yield all([
    takeLatest(types.GET_EMAIL_THREAD_LIST_MORE, getEmailThreadListMoreSaga),
  ]);
  yield all([takeLatest(types.GET_EMAIL_THREAD_INFO, getEmailThreadInfoSaga)]);
  yield all([
    takeLatest(types.GET_EMAIL_THREAD_INFO_CONTENT, getEmailThreadInfoContent),
  ]);
  yield all([
    takeLatest(types.GET_EMAIL_THREAD_INFO_FILES, getEmailThreadInfoFiles),
  ]);
}
