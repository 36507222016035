import React, { useState, useEffect, useRef } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

import { sleep } from 'utils/delay';
import classnames from 'classnames';

const defaultGraphData = [
  { name: 'Group A', value: 333 },
  { name: 'Group B', value: 333 },
  { name: 'Group C', value: 333 },
];

const defaultColors = ['#FF8042', '#FFBB28', '#00C49F'];

const percentClass = classnames(
  'ani--slideInRight',
  'ani--fill-forward',
  'ani--iter-1',
  'ani--time-70',
  'ani--easy'
);

const offset = 5;

const GaugeChart = (props) => {
  const {
    graphData = defaultGraphData,
    width = 200,
    colors = defaultColors,
    percentage,
    thick = 10,
    fontSize = 24,
  } = props;

  const [percent, setPercent] = useState(0);

  const refInterval = useRef(null);
  const refPercent = useRef(0);

  const computePointer = () => {
    const step = Math.PI / 100;
    const angle = percent * step;
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const R = width / 2 - offset - thick / 2;
    const offX = width / 2;
    const offY = width / 2;
    const diff = thick / 2;
    return [
      { x: offX - cos * (R + diff), y: offY - sin * (R + diff) },
      { x: offX - cos * (R - diff), y: offY - sin * (R - diff) },
    ];
  };

  const clearTimer = () => {
    clearInterval(refInterval.current);
    refInterval.current = null;
  };

  const poinAnimation = async (start, end) => {
    clearTimer();
    setPercent(start);

    const range = end - start;
    const step = range / 20;

    const isFinish = (end, prev) => {
      if (end >= start && Math.round(prev) >= Math.round(end)) {
        return true;
      }
      if (end < start && Math.round(prev) <= Math.round(end)) {
        return true;
      }
      return false;
    };

    refInterval.current = setInterval(() => {
      setPercent((prev) => {
        if (isFinish(end, prev)) {
          clearTimer();
          return end;
        } else {
          return prev + step;
        }
      });
    }, 50);
  };

  useEffect(() => {
    refPercent.current = percent;
  }, [percent]);

  useEffect(() => {
    if (
      percentage >= 0 &&
      percentage <= 100 &&
      typeof percentage === 'number'
    ) {
      poinAnimation(refPercent.current, percentage);
    }
  }, [percentage]);

  const pos = computePointer();

  return (
    <div style={{ width, position: 'relative', height: width / 2 }}>
      <PieChart width={width} height={width} style={{ top: 5 }}>
        <Pie
          data={graphData}
          cx='50%'
          cy='100%'
          startAngle={180}
          endAngle={0}
          innerRadius={width / 2 - (offset + thick)}
          outerRadius={width / 2 - offset}
          fill='#8884d8'
          paddingAngle={5}
          dataKey='value'
          style={{ transform: 'translate(0, -50%)' }}
        >
          {graphData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>

        <text
          className={percentClass}
          x={width / 2}
          y={width / 2 - fontSize / 2 - offset}
          fill='black'
          font-size={fontSize}
          dominantBaseline='central'
          text-anchor='middle'
        >
          {Math.round(percent)}%
        </text>
      </PieChart>
      <svg
        style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}
      >
        <g>
          <path
            id='pointerLine'
            d={`M${pos[0].x},${pos[0].y}L${pos[1].x},${pos[1].y}`}
            stroke='#ff4d4f'
            stroke-width={6}
            stroke-linecap='round'
          />
        </g>
      </svg>
    </div>
  );
};

export default GaugeChart;
