import React from 'react';
import { Row, Col } from 'antd';
import classnames from 'classnames';

import './ConfirmResult.less';

const AssetUploadConfirmResult = ({
  value,
  iconsRender,
  isDateRender,
  placeholderValue,
}) => {
  if (!isDateRender) {
    return iconsRender.map((item) => {
      const isRender = item?.conditionCheck(value);
      const Icon = item.icon;
      return (
        <>
          {isRender && (
            <Col style={{ marginLeft: 5 }} flex='auto'>
              <Row align='middle'>
                <Icon
                  className={classnames(
                    'asset-upload-confirm-result__icon',
                    `asset-upload-confirm-result__icon--${item.status}`
                  )}
                />
                {item.text}
              </Row>
            </Col>
          )}
        </>
      );
    });
  }

  let ActiveIcon = iconsRender?.[0]?.icon ? iconsRender[0].icon : <div />;
  let InActiveIcon = iconsRender?.[1]?.icon ? iconsRender[1].icon : <div />;

  return (
    <Col style={{ marginLeft: 5, marginTop: 5 }} flex='auto'>
      <Row align='middle'>
        {value ? (
          <ActiveIcon
            className={classnames(
              'asset-upload-confirm-result__icon',
              `asset-upload-confirm-result__icon--${iconsRender?.[0].status}`
            )}
          />
        ) : (
          <InActiveIcon
            className={classnames(
              'asset-upload-confirm-result__icon',
              `asset-upload-confirm-result__icon--${iconsRender?.[1].status}`
            )}
          />
        )}
        {value || placeholderValue}
      </Row>
    </Col>
  );
};

export default AssetUploadConfirmResult;
