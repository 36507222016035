import React from 'react';

import { Menu, Dropdown } from 'antd';
import { SubnodeOutlined } from '@ant-design/icons';

import AddProductFromMember from './AddProductFromMember';
import DownloadProductTemplateFromMember from './DownloadProductTemplateFromMember';
import UploadProductTemplateFromMember from './UploadProductTemplateFromMember';

import RibbonButton from 'common/components/button/RibbonButton';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const PimActions = (props) => {
  const { memberId } = props;

  const renderMenuOptions = () => (
    <Menu>
      <Menu.Item style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.PRODUCT} passThrough>
          {(allowed) => (
            <AddProductFromMember
              disabled={!allowed}
              memberId={memberId}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT} passThrough>
          {(allowed) => (
            <DownloadProductTemplateFromMember
              disabled={!allowed}
              memberId={memberId}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <UploadProductTemplateFromMember
          memberId={memberId}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderMenuOptions()}
      placement='bottomLeft'
      arrow
    >
      <RibbonButton icon={<SubnodeOutlined />} label={'PIM Actions'} />
    </Dropdown>
  );
};

PimActions.propTypes = {};

export default PimActions;
