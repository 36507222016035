import React from 'react';
import { useHistory } from 'react-router-dom';

import { ProfileOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import useToggleActiveRibbon from './hook';

import { useSsoProductParams } from 'hooks/useSso';

import Messages from 'i18n/messages/home';
import { useGetProductFullView } from 'hooks';

const ProductDetailView = ({ productId: productIdProp }) => {
  const history = useHistory();

  const { productFull } = useGetProductFullView({ productId: productIdProp });

  const { ssoParamUrl: ssoProductParams, productId: ssoProductId } =
    useSsoProductParams();

  const checkActiveContent = () => {
    const path = history?.location?.pathname;
    const regex = /\/product\/detail\/\d+$/;
    return regex.test(path);
  };

  const isPathActive = checkActiveContent();
  const isActive = useToggleActiveRibbon(isPathActive);

  const handleClick = () => {
    if (isActive) {
      if (ssoProductParams) {
        history.push(`/product/${ssoProductId}/?${ssoProductParams}`);
      } else {
        history.push(`/product/${productFull?.productId}`);
      }
    } else {
      if (ssoProductParams) {
        history.push(`/product/detail/${ssoProductId}?${ssoProductParams}`);
      } else {
        history.push(`/product/detail/${productFull?.productId}`);
      }
    }
  };

  return (
    <RibbonButton
      icon={<ProfileOutlined style={{ height: 26 }} />}
      label={Messages.productDetailView}
      onClick={handleClick}
      toggle={isPathActive}
      disabled={!productFull?.productId}
    />
  );
};

export default ProductDetailView;
