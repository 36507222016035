import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

// redux
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import reducer from 'pages/branded-products/controllers/reducer';
import saga from 'pages/branded-products/controllers/saga';

import { useWindowSize } from 'hooks/windowSize';
import { Skeleton } from 'antd';
import _ from 'lodash';

import * as actionsProduct from 'pages/branded-products/controllers/actions';
import * as selectorProduct from 'pages/branded-products/controllers/selectors';

import * as actionsGlobal from 'redux/global/actions';
import * as selectorsGlobal from 'redux/global/selectors';
import * as ribbonSelector from 'redux/ribbon/selectors';

import { GridView } from 'common/components/';
import { OpenItemContentPane } from 'common/components/openItem';

import {
  ProductTile,
  ProductContentPanel,
} from 'pages/branded-products/components/index';

import * as constant from 'static/Constants';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';
import ProductThumbnail from 'pages/branded-products/components/card/thumbnail/ProductThumbnail';
import { clearQueryCondition } from 'utils/queryCondition';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as brandingSelectors from 'redux/branding/selectors';
import * as endpointsProduct from 'services/product/endpoints';
import * as brandingActions from 'redux/branding/actions';

import { getGridName } from 'utils/getGridName';
import { updateSizeCurrent } from 'common/components/grid-view/utils';
import {
  useSaveConfigColumns,
  useSaveAllColumns,
  useSaveChosenColumns,
} from 'hooks/configGridHooks';
import {
  useListenReloadPage,
  useUpdateSelectedData,
} from 'hooks/useReloadPage';

import { useClearSearchText } from 'hooks/useClearSearchText';
import { useFilterGrid } from 'hooks/useFilterGrid';

import { useGetMemberId } from './hooks';

import useGetDefaultConfig from 'pages/branded-products/hooks/useGetDefaultConfig';

import { handleCreateSearchContainer } from 'pages/reporting/utils';
import { getParamsFromUrl } from 'utils/common/route';

const key = 'product';

const ProductForMemberGrid = (props) => {
  const option = constant.RIBBON_OPTIONS.PRODUCT_FOR_MEMBER;
  const searchCriteria = constant.SEARCH_CRITERIA.PRODUCT;

  const location = useLocation();
  const { brandId } = getParamsFromUrl(location) ?? {};

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const isFavoriteRoute = useCheckFavoriteRoute();

  const [width] = useWindowSize();

  const { refMemberId } = useGetMemberId();

  const fetchProductForMember = useCallback((params) => {
    if (!refMemberId.current) return;

    dispatch(
      actionsProduct.getMemberProductList({
        ...params,
      })
    );
  }, []);

  // const searchAllItems = useSelector(selectorsGlobal.selectSearchAllItems());

  const searchCategoriesEffected = useSelector(
    selectorsGridView.makeSelectSearchCategoriesList()
  );
  const [requestParams, setRequestParams] = useState({
    searchCategory: searchCategoriesEffected || [],
  });
  const [filterFromAssets, setFilterFromAssets] = useState([]);
  const [packageLevels, setPackageLevels] = useState([]);
  const [packageArray, setPackageArray] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);

  const { allColumns, isRefreshGrid } = useSelector(
    selectorsGridView.makeSelectGridConfig()
  );

  const reloadGrid = useSelector(selectorProduct.makeSelectReloadGrid());
  const typeView = useSelector(ribbonSelector.selectRibbonType());

  const {
    allConfigFieldsName,
    selectedColumns,
    defaultColumnsGrid: customGridColumns,
    isConfigEmpty,
  } = useSelector(selectorProduct.makeSelectGridConfig());

  const applyGridConfig = useSelector(
    selectorProduct.makeSelectApplyGridConfig()
  );

  const isDetailView = typeView === constant.RIBBON_TYPES.DETAILSVIEW;

  useGetDefaultConfig({
    isEnabled: Boolean(isDetailView),
    applyGridConfig,
  });

  const { gridFilters } = useFilterGrid({
    entityType: 'memberProduct',
    clearOtherFilter: true,
  });

  // add 2 default cols to configColumns

  // ribbon button change
  const updateRibbonCallback = useCallback(() => {
    dispatch(actionsGlobal.changeRibbonActions(option));
  }, [dispatch]);

  // change search criteriaProduct
  const updateSearchCriteriaCallback = useCallback(() => {
    dispatch(actionsGlobal.updateSearchCriteria(searchCriteria));
  }, [dispatch]);

  const products = useSelector(selectorProduct.makeSelectProducts());
  const totalPagination = useSelector(selectorProduct.makeSelectTotal());

  const loading = useSelector(selectorProduct.makeSelectLoading());

  const {
    productForMemberPage: [productPageNumber, productPageSize],
  } = useSelector(selectorsGridView.makeSelectPageCurrent());

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const { productForMemberText } = useSelector(
    selectorsGlobal.selectSelectSearchText()
  );

  const searchText = productForMemberText;
  const columns = useSelector(selectorProduct.makeSelectColumns());

  const isDisplayProductNew = useSelector(
    selectorsGlobal.selectDisplayProductNew()
  );
  const brandingData = useSelector(brandingSelectors.getBranding());
  const stringSearchCategories = JSON.stringify(searchCategoriesEffected);
  const queryConditions = useSelector(
    selectorsGridView.makeSelectProductsForMemberQuery()
  );
  const prevPathnameQuery = useSelector(
    selectorsGridView.makeSelectPrevPathnameQuery()
  );
  clearQueryCondition(dispatch, window.location.pathname, prevPathnameQuery);
  const checkQueryCondition = useSelector(
    selectorsGridView.makeSelectCheckQueryCondition()
  );
  const myQueryDoubleClick = useSelector(
    selectorsGridView.makeSelectMyQueryDoubleClick()
  );
  const gridName = getGridName(window.location.pathname);

  useSaveConfigColumns(gridName);
  useSaveAllColumns(columns);
  useSaveChosenColumns(columns);

  const pageSize = productPageSize;
  const pageNumber = productPageNumber;

  useClearSearchText(dispatch, 'productForMember', window.location.pathname);

  const getRequestColumns = (columns) => {
    //* merge 2 default columns with columns to make sure no fields missed
    const mergedColumns = columns.concat(constant.PRODUCT_DEFAULT_GRID_COLUMN);

    //* remove duplicated column
    const requestColumns = _.unionBy(mergedColumns, 'fieldName');

    return requestColumns;
  };

  useEffect(() => {
    updateRibbonCallback();
    updateSearchCriteriaCallback();
  }, [updateRibbonCallback, updateSearchCriteriaCallback]);

  useEffect(() => {
    return () => {
      hideNewProductView();
    };
  }, []);

  useEffect(() => {
    if (Boolean(selectedColumns) && isDetailView) {
      requestParams.searchCategory = searchCategoriesEffected;
      requestParams.fromDate = currentDate;
      requestParams.columns = getRequestColumns(allConfigFieldsName);
      setRequestParams(requestParams);
    } else {
      setRequestParams({
        ...requestParams,
        searchCategory: searchCategoriesEffected,
        fromDate: currentDate,
        columns: getRequestColumns([]),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(searchCategoriesEffected),
    currentDate,
    selectedColumns,
    isDetailView,
  ]);

  //* Dispatch get Product Columns
  useEffect(() => {
    if (typeView === constant.RIBBON_TYPES.DETAILSVIEW) {
      // columns?.length === 0 &&
      dispatch(actionsProduct.gridColumnInfo('product-detail-grid'));
    }
  }, [dispatch, typeView, window.location.pathname, isDisplayProductNew]);

  useEffect(() => {
    if (checkQueryCondition && myQueryDoubleClick) {
      updateSizeCurrent(dispatch, 1, pageSize, window.location.pathname);

      const advancedSearchContainer =
        handleCreateSearchContainer(queryConditions);
      requestParams.advancedSearchContainer = advancedSearchContainer;
      setRequestParams(requestParams);
      dispatch(actionsGridView.deleteItemsSelection());
      if (typeView === constant.RIBBON_TYPES.DETAILSVIEW) {
        dispatch(actionsGridView.updateIsRefreshGrid());
      } else {
        fetchProductForMember({
          pageSize, // pageSizePagination,
          pageIndex: 1,
          search: searchText,
          filters: filterFromAssets,
          advancedSearchContainer,
          searchCategory: searchCategoriesEffected,
          packageLevels,
          isFavoriteRoute,
          fromDate: currentDate,
        });
      }
      dispatch(actionsGridView.checkQueryCondition(false));
      dispatch(actionsGridView.myQueryDoubleClick(false));
    }
  }, [
    dispatch,
    checkQueryCondition,
    pageSize,
    // filterFromAssets,
    // queryConditions,
    // searchCategoriesEffected
  ]);

  useEffect(() => {
    if (stringSearchCategories === 'null') return;

    const categoryParams = window?.productCategory;
    const brickCodeParams = window?.brickCodeHierarchy;
    const defaultFilter = [
      {
        fieldName: 'ownerId',
        value: parseInt(refMemberId.current),
        filterType: 'Equal',
      },
    ];

    let filters = defaultFilter;
    let arrValue = [];
    if (gridFilters.indexOf('subscription') > -1) {
      filters.push({
        fieldName: 'subscription',
        value: true,
        filterType: 'Equal',
      });
    }
    if (gridFilters.indexOf('syndication') > -1) {
      filters.push({
        fieldName: 'syndication',
        value: true,
        filterType: 'Equal',
      });
    }
    if (gridFilters.indexOf('owner') > -1) {
      filters.push({
        fieldName: 'isOwner',
        value: true,
        filterType: 'Equal',
      });
    }

    if (gridFilters.indexOf('pending') > -1) {
      filters.push({
        fieldName: 'status',
        value: 'Pending',
        filterType: 'Equal',
      });
    }

    if (gridFilters.indexOf('active') > -1) {
      filters.push({
        fieldName: 'status',
        value: 'Active',
        filterType: 'Equal',
      });
    }
    if (gridFilters.indexOf('productCategory') > -1) {
      filters.push(...categoryParams);
    }
    if (gridFilters.indexOf('brickCodeHierarchy') > -1) {
      filters.push(...brickCodeParams);
    }
    if (gridFilters.indexOf('unmatched') > -1) {
      filters.push({
        fieldName: 'meetMinRequirement',
        value: false,
        filterType: 'Equal',
      });
      filters.push({
        fieldName: 'status',
        value: 'Active',
        filterType: 'Equal',
      });
    }

    if (brandId) {
      filters.push({
        fieldName: 'brandId',
        value: brandId,
        filterType: 'Equal',
      });
    }

    if (gridFilters.indexOf('privateLabel') > -1) {
      filters.push({
        fieldName: 'isPrivateLabel',
        value: true,
        filterType: 'Equal',
      });
    }

    if (
      gridFilters.indexOf('Pallet') > -1 ||
      gridFilters.indexOf('Master Case') > -1 ||
      gridFilters.indexOf('Case') > -1 ||
      gridFilters.indexOf('Inner Pack') > -1 ||
      gridFilters.indexOf('Unit') > -1 ||
      gridFilters.indexOf('Display Shipper') > -1
    ) {
      if (gridFilters.indexOf('Pallet') > -1) {
        arrValue.push('Pallet');
      }
      if (gridFilters.indexOf('Master Case') > -1) {
        arrValue.push('Master Case');
      }
      if (gridFilters.indexOf('Case') > -1) {
        arrValue.push('Case');
      }
      if (gridFilters.indexOf('Inner Pack') > -1) {
        arrValue.push('Inner Pack');
      }
      if (gridFilters.indexOf('Unit') > -1) {
        arrValue.push('Unit');
      }
      if (gridFilters.indexOf('Display Shipper') > -1) {
        arrValue.push('Display Shipper');
      }
    }
    requestParams.packageLevels = arrValue;
    requestParams.searchCategory = searchCategoriesEffected;
    requestParams.columns = getRequestColumns(
      Boolean(selectedColumns) ? allConfigFieldsName : []
    );

    setPackageLevels(arrValue);
    setRequestParams(requestParams);
    setFilterFromAssets(filters);
    if (
      //!warning = this condtion might be wrong since the querycondition should be applied for all view type
      // typeView !== constant.RIBBON_TYPES.DETAILSVIEW &&
      !checkQueryCondition &&
      !myQueryDoubleClick
    ) {
      const advancedSearchContainer =
        handleCreateSearchContainer(queryConditions);
      requestParams.advancedSearchContainer = advancedSearchContainer;
      requestParams.searchCategory = searchCategoriesEffected;
      requestParams.fromDate = currentDate;

      setRequestParams(requestParams);
      setPackageArray(arrValue);

      //* this line makes bug occur in grid view selection when change type view (sprint 34 - ticket 1305)

      if (typeView !== constant.RIBBON_TYPES.DETAILSVIEW) {
        //* when view type !== detail view

        currentDate &&
          isDisplayProductNew &&
          fetchProductForMember({
            pageSize,
            pageIndex: pageNumber,
            search: searchText,
            filters,
            advancedSearchContainer,
            searchCategory: searchCategoriesEffected,
            packageLevels: arrValue,
            isFavoriteRoute,
            fromDate: currentDate,
          });

        !currentDate &&
          !isDisplayProductNew &&
          fetchProductForMember({
            pageSize,
            pageIndex: pageNumber,
            search: searchText,
            filters,
            advancedSearchContainer,
            searchCategory: searchCategoriesEffected,
            packageLevels: arrValue,
            isFavoriteRoute,
          });
      }
    }
  }, [
    dispatch,
    searchText,
    typeView,
    isFavoriteRoute,
    gridFilters,
    stringSearchCategories,
    currentDate,
    pageSize,
    pageNumber,
    brandId,
  ]);

  useEffect(() => {
    if (reloadGrid && typeView !== constant.RIBBON_TYPES.DETAILSVIEW) {
      const advancedSearchContainer =
        handleCreateSearchContainer(queryConditions);
      fetchProductForMember({
        pageSize, // pageSizePagination,
        pageIndex: pageNumber, // pageIndexPagination,
        search: searchText,
        filters: filterFromAssets,
        advancedSearchContainer,
        searchCategory: searchCategoriesEffected,
        packageArray,
        isFavoriteRoute,
        fromDate: currentDate,
      });
    }
  }, [dispatch, typeView, reloadGrid, pageSize, pageNumber]);

  useEffect(() => {
    return () => {
      dispatch(actionsGridView.deleteItemsSelection());
      dispatch(actionsGridView.updatePagesSelection([]));
      dispatch(actionsGridView.updateItemPageSelection([]));
    };
  }, [
    dispatch,
    searchText,
    gridFilters,
    JSON.stringify(searchCategoriesEffected),
  ]);

  useEffect(() => {
    updateCurrentDate();
  }, [isDisplayProductNew, isFavoriteRoute]);

  //update here when api release
  useEffect(() => {
    if (isDisplayProductNew && currentDate) {
      updateLatestGetNew();
    }
  }, [isDisplayProductNew, currentDate]);

  // re-call api when page need to reload
  useListenReloadPage(() => {
    const advancedSearchContainer =
      handleCreateSearchContainer(queryConditions);

    fetchProductForMember({
      pageSize,
      pageIndex: pageNumber,
      search: searchText,
      filters: filterFromAssets,
      advancedSearchContainer,
      searchCategory: searchCategoriesEffected,
      packageLevels,
      isFavoriteRoute,
      fromDate: currentDate,
    });
  });

  useUpdateSelectedData(products);

  const ProductTileRender = (configProps) => {
    return <ProductTile {...configProps} {...{ pathname: '/product' }} />;
  };

  const ProductThumbnailRender = (configProps) => {
    return <ProductThumbnail {...configProps} {...{ pathname: '/product' }} />;
  };

  const onClickToSeeAll = () => {
    //todo - fix reset page current later - 1/26/2022
    // dispatch(actionsGridView.resetPageCurrentProduct());
    hideNewProductView();
  };

  const hideNewProductView = () => {
    dispatch(actionsProduct.toggleProductNew(false));
    setCurrentDate(null);
    setFilterFromAssets([]);
  };

  const updateCurrentDate = () => {
    if (isFavoriteRoute) {
      hideNewProductView();
      return;
    }
    if (isDisplayProductNew) {
      dispatch(actionsGlobal.updateFilterProducts([]));
      setCurrentDate(brandingData?.lastGetNewProductsTime);
    } else {
      setCurrentDate(null);
    }
  };

  const updateLatestGetNew = () => {
    dispatch(brandingActions.updateLastGetNewProduct());
  };

  const urlGrid = endpointsProduct.GET_PRODUCT_DETAIL_CUSTOMIZED_GRID;
  const urlGridDistinct =
    endpointsProduct.GET_PRODUCT_DETAIL_CUSTOMIZED_GRID_DISTINCT;

  const productInfo = detailCurrentItemsSelection?.[0];

  return (
    <Skeleton
      className='product__skeleton product__sketelon--custom'
      paragraph={{
        rows: Math.ceil(width / constant.WIDTH_ROW_SKELETION),
      }}
      active={true}
      loading={loading}
    >
      <GridView
        dataList={products}
        typeView={typeView}
        tileGridBody={ProductTileRender}
        thumbnailGridChildren={ProductThumbnailRender}
        totalPagination={totalPagination}
        pageSizePagination={pageSize}
        pageNumberPagination={pageNumber}
        columnDefs={isConfigEmpty ? allColumns : customGridColumns}
        isDisplaySearchCategories={true}
        urlGrid={urlGrid}
        urlGridDistinct={urlGridDistinct}
        responseParams='data'
        pathname='/product'
        filterFromMainPage={filterFromAssets}
        requestParams={requestParams}
        reloadGrid={reloadGrid}
        queryConditions={queryConditions}
        shouldReloadDetailGrid={isRefreshGrid}
        panelDetail={
          <OpenItemContentPane
            config={{
              type: constant.OPEN_ITEM.TYPE.PRODUCT_CONTENT_PANE,
              mode: constant.OPEN_ITEM.MODE.VIEW,
              url: `/product/${productInfo?.id}`,
              name:
                productInfo?.productDescription ||
                'Product Full View (default)',
            }}
            productInfo={detailCurrentItemsSelection?.[0]}
          >
            <ProductContentPanel />
          </OpenItemContentPane>
        }
        isToggle={isDisplayProductNew}
        onClickToSeeAll={onClickToSeeAll}
        applyDefaultSort={searchText ? false : true}
      />
    </Skeleton>
  );
};

export default ProductForMemberGrid;
