import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import * as _ from 'lodash';
import classnames from 'classnames';

import './TabLayout.less';

const { TabPane } = Tabs;

const TabLayout = (props) => {
  const {
    options,
    defaultActiveTab,
    className,
    onChangeTab,
    containerHeight,
    onTabClick,
    activeKey,
  } = props;

  return (
    <Tabs
      className={classnames([className], 'tab-layout-component__wrapper')}
      defaultActiveKey={defaultActiveTab}
      style={{ height: containerHeight }}
      onChange={onChangeTab}
      onTabClick={onTabClick}
      activeKey={activeKey}
    >
      {options &&
        !!options.length &&
        options?.map((item) => {
          const { title, key, module, disabled = false } = item;

          return (
            <TabPane
              className='tab-layout-component__tab-item'
              tab={title}
              key={key}
              disabled={disabled}
            >
              {module}
            </TabPane>
          );
        })}
    </Tabs>
  );
};

TabLayout.propTypes = {
  /**
   * className of wrapper
   */
  className: PropTypes.string,
  /**
   * Tab item option array
   */
  option: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string,
      module: PropTypes.func,
    })
  ),
  /**
   * Function to set active tab
   */
  setTabKey: PropTypes.func,
};
TabLayout.defaultProps = {
  className: '',
};
export default TabLayout;
