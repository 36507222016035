import React from 'react';

import { Input } from 'antd';

import { Form, StyledModal } from 'common/components';

import { PRODUCT_SPEC_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';

const ModalUpdateProductDocument = ({ formInstance, ...otherProps }) => {
  return (
    <StyledModal title='Update Document' {...otherProps}>
      <Form
        form={formInstance}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label='Document Name' name='documentName'>
          <Input />
        </Form.Item>
        <Form.Item label='Characteristic Type' name='characteristicsType'>
          <Input
            showCount
            maxLength={
              PRODUCT_SPEC_LIMIT_CHARACTERS.document.characteristicsType
            }
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default ModalUpdateProductDocument;
