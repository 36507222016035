import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { notification } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import { RibbonButton, dialogFunction } from 'common/components';

import { deleteProductFromApl } from 'services/apl';
import {
  makeSelectAplProductListSelect,
  makeSelectAplDetail,
} from 'pages/product-apl-detail/controllers/selectors';
import { toggleReloadGrid } from 'pages/product-apl-detail/controllers/actions';

import { sleep } from 'utils/delay';

const DeleteProductApl = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const selectedItems = useSelector(makeSelectAplProductListSelect());
  const apiDetail = useSelector(makeSelectAplDetail());

  const enableDeletion = selectedItems?.length === 1 && apiDetail?.isOwner;

  const handleSubmit = async () => {
    const params = {
      aplProductId: selectedItems?.[0],
    };

    try {
      const response = await deleteProductFromApl(params);

      if (response?.isSuccess) {
        notification.success({
          message: intl.formatMessage(messagesProduct.deleteProductAplSuccess),
        });

        await sleep(1000);
        dispatch(toggleReloadGrid(true));
      } else {
        notification.error({
          message: response?.message,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        dispatch(toggleReloadGrid(false));
      }, 200);
    }
  };

  const handleDeleteProductApl = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(messagesProduct.confirmDeleteProductToApl),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleSubmit,
    });
  };

  return (
    <RibbonButton
      size='small'
      icon={<CloseCircleOutlined style={{ height: 26 }} />}
      label={Messages.deleteProductApl}
      onClick={handleDeleteProductApl}
      disabled={!enableDeletion}
      className='button-item-dropdown'
    />
  );
};

export default DeleteProductApl;
