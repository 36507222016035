import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Input, Form, Select, Checkbox } from 'antd';

import {
  StyledModal,
  AgGrid,
  FormAddButton,
  FormDeleteButton,
  dialogFunction,
  WrapperSelect,
} from 'common/components';
import {
  InformationLabel,
  InformationData,
  Loading,
} from 'pages/branded-products/components/content-pane/SharedComponents';

import * as folderActions from '../../controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { findMappingSubscription } from './utils';
import { GRID_ID } from 'common/components/grid-view/utils';
import { mappingColumns } from 'common/components/product-subscription/utils';

import * as endpoints from 'services/mapping/endpoints';
import { PRODUCTS_DATA_FORMAT } from 'static/Constants';

import { useIntl } from 'react-intl';
import messagesMapping from 'i18n/messages/mapping';

const formProductLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const ConfigProductDownload = ({
  mappingId,
  selectedMapping,
  dropdownConfigs,
  formName = 'folder',
}) => {
  const dispatch = useDispatch();

  const [visibleModal, setVisibleModal] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!mappingId) return;
    findMappingSubscription(mappingId)
      .then((data) => {
        dispatch(folderActions.saveSelectedMapping(data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch, mappingId]);

  const handleDeleteMapping = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the selected mapping?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => {
        dispatch(folderActions.saveSelectedMapping(null));
      },
    });
  };

  const dataFormat = selectedMapping?.formatTypes
    ? selectedMapping?.formatTypes?.slice().sort()
    : PRODUCTS_DATA_FORMAT.map((formatType) => formatType.toUpperCase());
  const renderProductCropSize = (options) => {
    return (
      <>
        {options?.map((option) => (
          <Select.Option key={option} value={option}>
            <span>{option === null ? 'No Crop' : `${option} Pixels`}</span>
          </Select.Option>
        ))}
      </>
    );
  };

  const renderProductImageFormat = (options) => {
    return (
      <>
        {options?.map((option) => (
          <Select.Option key={option} value={option}>
            <span>{option?.toUpperCase()}</span>
          </Select.Option>
        ))}
      </>
    );
  };

  const renderDefaultDropDownConfigs = (options) => {
    return (
      <>
        {options?.map((option) => (
          <Select.Option key={option} value={option}>
            <span>{option}</span>
          </Select.Option>
        ))}
      </>
    );
  };

  const renderResolution = (options) => {
    return (
      <>
        {options?.map((option) => (
          <Select.Option key={option} value={option}>
            <span>{option === null ? 'Original' : option}</span>
          </Select.Option>
        ))}
      </>
    );
  };

  const renderDropdownConfigs = (options, name) => {
    if (name === 'productCropSize') {
      return renderProductCropSize(options);
    }
    if (name === 'productImageFormat') {
      return renderProductImageFormat(options);
    }
    if (name === 'productResolution') {
      return renderResolution(options);
    }

    return renderDefaultDropDownConfigs(options);
  };

  return (
    <>
      <Row
        className='download-configuration__item'
        gutter={8}
        style={{ marginLeft: 10 }}
      >
        <Col span={24}>
          <Row align='middle' style={{ marginBottom: 6 }}>
            {selectedMapping ? (
              <>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <FormDeleteButton
                    onClick={handleDeleteMapping}
                    style={{ borderRadius: 0 }}
                  />
                </Col>
                <DisplayMapping selectedMapping={selectedMapping} />
              </>
            ) : (
              <Col span={24} style={{ textAlign: 'right', marginBottom: 10 }}>
                <FormAddButton
                  text='Mapping'
                  onClick={() => setVisibleModal(true)}
                  style={{ borderRadius: 0 }}
                />
              </Col>
            )}
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 4]} className='download-configuration__product'>
            <Col span={12}>
              <Form.Item
                {...formProductLayout}
                name='productDataFormat'
                label='Data Format'
              >
                <WrapperSelect
                  getPopupContainer={() => document.getElementById(formName)}
                  allowClear={false}
                >
                  {dataFormat.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </WrapperSelect>
              </Form.Item>
            </Col>

            {dropdownConfigs.map(({ name, options, layout }) => (
              <Col span={12} key={name}>
                <Form.Item
                  {...formProductLayout}
                  name={name}
                  label={intl.formatMessage({
                    id: `Taco.folder.download.configuration.${name}`,
                  })}
                >
                  <WrapperSelect
                    getPopupContainer={() => document.getElementById(formName)}
                  >
                    {renderDropdownConfigs(options, name)}
                  </WrapperSelect>
                </Form.Item>
              </Col>
            ))}

            <Col span={12}>
              <Form.Item
                {...formProductLayout}
                name='includeExtension'
                label='Include Extension'
                valuePropName='checked'
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      {visibleModal && (
        <MappingSubscriptionGrid
          visibleModal={visibleModal}
          handleCancelModal={() => setVisibleModal(false)}
        />
      )}
    </>
  );
};

const MappingSubscriptionGrid = (props) => {
  const gridApi = useRef(null);
  const dispatch = useDispatch();

  const { visibleModal, handleCancelModal } = props;

  const [searchText, setSearchText] = useState('');

  const handleAddMapping = () => {
    if (gridApi?.current?.getSelectedRows().length > 0) {
      const selectedRow = gridApi.current.getSelectedRows()[0];
      dispatch(folderActions.saveSelectedMapping(selectedRow));
      handleCancelModal();
    }
  };

  const modalProps = {
    visible: visibleModal,
    title: 'Select Mapping',
    onCancel: handleCancelModal,
    onOk: handleAddMapping,
    okText: 'Add',
    maskClosable: false,
    width: '600px',
    destroyOnClose: true,
    bodyStyle: { minHeight: '70vh' },
  };

  return (
    <StyledModal {...modalProps}>
      <div style={{ height: '430px', marginBottom: 40 }}>
        {mappingColumns?.length === 0 ? (
          <Loading />
        ) : (
          <>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <Input.Search
                placeholder='Search mapping'
                allowClear
                onSearch={(value) => setSearchText(value)}
              />
            </div>
            <AgGrid
              columnDefs={mappingColumns}
              urlGrid={endpoints.GET_SUB_MAPPING_GRID}
              responseParams='data'
              gridConfigProps={{
                rowSelection: 'single',
              }}
              getGridApi={(api) => {
                gridApi.current = api;
              }}
              paramsGrid={{
                search: {
                  searchText,
                },
                enableRivirDownload: true,
              }}
            />
          </>
        )}
      </div>
    </StyledModal>
  );
};

const DisplayMapping = ({ selectedMapping }) => {
  const intl = useIntl();

  const { mappingName, mappingDescription, deliveryMethods, formatTypes } =
    selectedMapping;

  return (
    <Row style={{ margin: '8px 0' }}>
      <InformationLabel>
        {intl.formatMessage(messagesMapping.mappingName)}
      </InformationLabel>
      <InformationData data={mappingName} />

      <InformationLabel>
        {intl.formatMessage(messagesMapping.mappingDescription)}
      </InformationLabel>
      <InformationData data={mappingDescription} />

      <InformationLabel>
        {intl.formatMessage(messagesMapping.deliveryMethods)}
      </InformationLabel>
      <InformationData data={deliveryMethods.join(', ')} />

      <InformationLabel>
        {intl.formatMessage(messagesMapping.formatTypes)}
      </InformationLabel>
      <InformationData data={formatTypes.join(', ')} />
    </Row>
  );
};

export { ConfigProductDownload };
