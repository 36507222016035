import { defineMessages } from 'react-intl';

export const scope = 'Taco.email-editor';

export default defineMessages({
  subject: {
    id: `${scope}.subject`,
    defaultMessage: 'Subject',
  },
  upc12: {
    id: `${scope}.product.upc`,
    defaultMessage: 'UPC',
  },
  gtin: {
    id: `${scope}.product.gtin`,
    defaultMessage: 'GTIN',
  },
  name: {
    id: `${scope}.product.name`,
    defaultMessage: 'Name',
  },
  brand: {
    id: `${scope}.product.brand`,
    defaultMessage: 'Brand',
  },
  description: {
    id: `${scope}.product.description`,
    defaultMessage: 'Description',
  },
  size: {
    id: `${scope}.product.size`,
    defaultMessage: 'Size',
  },
  sizeUom: {
    id: `${scope}.product.sizeUom`,
    defaultMessage: 'UOM',
  },
  placeholder: {
    id: `${scope}.component.placeholder`,
    defaultMessage: 'Please input ',
  },
  recipientName: {
    id: `${scope}.components.recipientName`,
    defaultMessage: 'Recipient Name',
  },
  projectName: {
    id: `${scope}.components.projectName`,
    defaultMessage: 'Project Name',
  },
  numberSample: {
    id: `${scope}.components.numberSample`,
    defaultMessage: 'Number of Sample',
  },
  shippingName: {
    id: `${scope}.components.shippingName`,
    defaultMessage: 'Shipping Name',
  },
  shippingCompany: {
    id: `${scope}.components.shippingCompany`,
    defaultMessage: 'Shipping Company',
  },
  shippingAddress1: {
    id: `${scope}.components.shippingAddress1`,
    defaultMessage: 'Shipping Address 1',
  },
  shippingAddress2: {
    id: `${scope}.components.shippingAddress2`,
    defaultMessage: 'Shipping Address 2',
  },
  additionalInfo: {
    id: `${scope}.components.additionInfo`,
    defaultMessage: 'Additional Information',
  },
  senderInfo: {
    id: `${scope}.components.add.senderInfo`,
    defaultMessage: 'Sender Information',
  },
  greeting: {
    id: `${scope}.components.add.greeting`,
    defaultMessage: 'Greeting',
  },
  content: {
    id: `${scope}.components.add.content`,
    defaultMessage: 'Content',
  },
  productRequest: {
    id: `${scope}.components.add.productRequest`,
    defaultMessage: 'Product Request',
  },
  shippingTo: {
    id: `${scope}.components.add.shippingTo`,
    defaultMessage: 'Shipping Information',
  },
  signingOff: {
    id: `${scope}.components.add.signingOff`,
    defaultMessage: 'Signing Off',
  },
  undo: {
    id: `${scope}.components.toolbar.undo`,
    defaultMessage: 'Undo',
  },
  redo: {
    id: `${scope}.components.toolbar.redo`,
    defaultMessage: 'Redo',
  },
  company: {
    id: `${scope}.components.showHide.options.company`,
    defaultMessage: 'Company',
  },
  address: {
    id: `${scope}.components.showHide.options.address`,
    defaultMessage: 'Address',
  },
  date: {
    id: `${scope}.components.showHide.options.date`,
    defaultMessage: 'Date',
  },
  noContentShow: {
    id: `${scope}.components.showHide.message.noContentShow`,
    defaultMessage: 'No content displayed, ',
  },
  pleaseClick: {
    id: `${scope}.components.showHide.message.pleaseClick`,
    defaultMessage: 'please click ',
  },
  toShowContent: {
    id: `${scope}.components.showHide.message.toShowContent`,
    defaultMessage: 'to show content.',
  },
  addSectionTooltip: {
    id: `${scope}.components.action.tooltip.add`,
    defaultMessage: 'Click to Add new section',
  },
  dragSectionTooltip: {
    id: `${scope}.components.action.tooltip.move`,
    defaultMessage: 'Drag to Move',
  },
  startingMessages: {
    id: `${scope}.components.messages.starting`,
    defaultMessage: 'Add a section',
  },
});
