// FLOW SSO PRODUCT
export const BP_SSO_PRODUCT_VALIDATE = 'BP_SSO_PRODUCT_VALIDATE';
export const BP_SSO_PRODUCT_VALIDATE_DETAIL = 'BP_SSO_PRODUCT_VALIDATE_DETAIL';
export const BP_SSO_PRODUCT_UPDATE_DATA = 'BP_SSO_PRODUCT_UPDATE_DATA';
// CREATE SSO PRODUCT
export const BP_SSO_CREATE_NEW_PRODUCT = 'BP_SSO_CREATE_NEW_PRODUCT';
export const BP_SSO_CREATE_NEW_PRODUCT_SUCCESS =
  'BP_SSO_CREATE_NEW_PRODUCT_SUCCESS';
export const BP_SSO_CREATE_NEW_PRODUCT_ERROR =
  'BP_SSO_CREATE_NEW_PRODUCT_ERROR';
export const BP_SSO_REDIRECT_PAGE = 'BP_SSO_REDIRECT_PAGE';
// GET SSO PRODUCT
export const BP_SSO_GET_PRODUCT = 'BP_SSO_GET_PRODUCT';
export const BP_SSO_GET_PRODUCT_SUCCESS = 'BP_SSO_GET_PRODUCT_SUCCESS';
export const BP_SSO_GET_PRODUCT_ERROR = 'BP_SSO_GET_PRODUCT_ERROR';
// UPDATE SSO PRODUCT
export const BP_SSO_UPDATE_PRODUCT = 'BP_SSO_UPDATE_PRODUCT';
export const BP_SSO_UPDATE_PRODUCT_SUCCESS = 'BP_SSO_UPDATE_PRODUCT_SUCCESS';
export const BP_SSO_UPDATE_PRODUCT_ERROR = 'BP_SSO_UPDATE_PRODUCT_ERROR';
// GET SSO PRODUCT INFO
export const BP_SSO_GET_PRODUCT_INFO = 'BP_SSO_GET_PRODUCT_INFO';
export const BP_SSO_GET_PRODUCT_INFO_SUCCESS =
  'BP_SSO_GET_PRODUCT_INFO_SUCCESS';
export const BP_SSO_GET_PRODUCT_INFO_ERROR = 'BP_SSO_GET_PRODUCT_INFO_ERROR';
