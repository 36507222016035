import React from 'react';
import Messages from 'i18n/messages/home';
import { BankOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import * as actionsRibbon from 'redux/ribbon/actions';

const OfficeFacility = () => {
  const dispatch = useDispatch();
  return (
    <RibbonButton
      icon={<BankOutlined />}
      label={Messages.officeFacility}
      onClick={() => dispatch(actionsRibbon.changeMemberLayout('office'))}
    />
  );
};

export default OfficeFacility;
