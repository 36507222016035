import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Drawer, Typography, Empty } from 'antd';

import { WithLoading } from 'common/components';
import {
  ProductOverview,
  ProductPrimaryInfo,
  PackageHeaderDrawer,
  PackageContentDrawer,
} from '../index';

import * as productSelectors from '../../controllers/selectors';

import { useGetProductEnums, useProductHierarchies } from 'hooks';

import * as productServices from 'services/product';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

import './ProductHierarchyEndpointInfo.less';

const { Title } = Typography;

const ProductHierarchyEndpointInfo = (props) => {
  const intl = useIntl();
  const { productEnums } = useGetProductEnums();

  const { setEditProductId, isFixPublicationErrorView } = props;

  const hierarchyEndpoint = useSelector(
    productSelectors.makeSelectHierarchyEndpoint()
  );
  const productId = hierarchyEndpoint?.productId;
  const hierarchyId = hierarchyEndpoint?.hierarchyId;

  const [productInfo, setProductInfo] = useState(false);
  const [productInfoLoading, setProductInfoLoading] = useState(false);
  const [productInfoError, setProductInfoError] = useState(false);

  const [hierarchyShortDetailVisible, setHierarchyShortDetailVisible] =
    useState(false);
  const [hierarchyShortDetail, setHierarchyShortDetail] = useState(null);
  const [hierarchyShortDetailLoading, setHierarchyShortDetailLoading] =
    useState(null);

  const { hierarchyData, isLoading: hierarchyLoading } = useProductHierarchies({
    productId,
    enabled: !!productId,
  });

  const showHierarchyDrawer = () => {
    setHierarchyShortDetailVisible(true);
  };

  const closeHierarchyDrawer = () => {
    setHierarchyShortDetailVisible(false);
  };

  const getProductInfo = () => {
    if (!productId) return;

    setProductInfoLoading(true);
    setProductInfo(null);

    const params = {
      ProductItemId: productId,
    };

    productServices
      .getProductFullView(params)
      .then((response) => {
        const { isSuccess, data } = response;
        if (isSuccess && data) {
          setProductInfo(data);
          return;
        }
        throw new Error();
      })
      .catch((error) => {
        setProductInfoError(true);
      })
      .then(() => {
        setProductInfoLoading(false);
      });
  };

  const getHierarchyShortDetail = () => {
    if (!hierarchyShortDetailVisible) return;

    setHierarchyShortDetailLoading(true);

    const params = {
      hierarchyId,
    };
    productServices
      .getProductHierarchyDetailsService(params)
      .then((response) => {
        const { isSuccess, data } = response;
        if (isSuccess && data) {
          setHierarchyShortDetail(data);
          return;
        }
        throw new Error();
      })
      .catch(() => {})
      .finally(() => {
        setHierarchyShortDetailLoading(false);
      });
  };

  const clearProductInfo = () => {
    if (productId) return null;
    if (hierarchyId) return null;

    setProductInfo(false);
  };

  useEffect(() => {
    getProductInfo();
    clearProductInfo();
  }, [productId, hierarchyId]);

  useEffect(() => {
    getHierarchyShortDetail();
  }, [hierarchyShortDetailVisible]);

  useEffect(() => {
    clearProductInfo();
  }, [productId, hierarchyId]);

  const shouldShowDefaultText =
    !hierarchyLoading && hierarchyData?.length === 0;

  const shouldShowEmpty =
    !productInfoLoading && hierarchyData && hierarchyData?.length !== 0;

  return (
    <div className='product-hierarchy-endpoint'>
      <div className='product-hierarchy-endpoint__inner scroller'>
        {shouldShowDefaultText ? (
          <Title level={4} className='product-hierarchy-endpoint__default-text'>
            {intl.formatMessage(Messages.hierarchyEndpointPreviewDefaultText)}
          </Title>
        ) : null}
        {productInfoLoading && (
          <WithLoading style={{ width: '100%', paddingTop: 60 }} />
        )}
        {productInfo ? (
          <>
            <ProductPrimaryInfo
              product={productInfo}
              setEditProductId={setEditProductId}
              isFixPublicationErrorView={isFixPublicationErrorView}
              isHierarchy
            />
            <ProductOverview
              product={productInfo}
              productEnums={productEnums}
              isHiddenQuestionIcon={true}
              showPackageDrawer={showHierarchyDrawer}
            />
            <Drawer
              title={<PackageHeaderDrawer detail={hierarchyShortDetail} />}
              placement='right'
              onClose={closeHierarchyDrawer}
              visible={hierarchyShortDetailVisible}
              key='right'
              mask={false}
              getContainer={false}
              style={{
                height: '550px',
                position: 'fixed',
              }}
              width={825}
              closable
            >
              <WithLoading loading={hierarchyShortDetailLoading}>
                <PackageContentDrawer
                  productItems={hierarchyShortDetail?.productItems || []}
                />
              </WithLoading>
            </Drawer>
          </>
        ) : shouldShowEmpty ? (
          <Empty className='product-hierarchy-endpoint__empty' />
        ) : null}
      </div>
    </div>
  );
};

export default ProductHierarchyEndpointInfo;
