import React, { useState, useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Row, Col, Typography, Skeleton } from 'antd';
import { LabelValueWrapper } from 'common/components';
import { formatMDY } from 'utils/formatDate';
import { formatSizeUnits } from 'utils/formatSizeUnits';
import EmptyFolderImg from 'assets/folder/empty-folder.png';
import FolderImg from 'assets/folder/folder.png';
import ShareFolderImg from 'assets/folder/shared-folder.png';
import { get } from 'lodash';
import './DownloadInformation.less';

const { Text } = Typography;

const DownloadInformation = (props) => {
  const { downloadSetting, ownerFolder } = props;

  const [leftCol, setLeftCol] = useState([]);
  const [rightCol, setRightCol] = useState([]);
  const thumbnailImg = !ownerFolder
    ? ShareFolderImg
    : get(downloadSetting, 'items') > 0
    ? FolderImg
    : EmptyFolderImg;

  // Modify Data
  const modifyData = (key, value) => {
    if (typeof value === 'boolean') {
      switch (value) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return value;
      }
    } else {
      switch (key) {
        case 'dateCreated':
        case 'dateShared':
        case 'lastUpdated':
        case 'lastDownload':
        case 'lastSchedule':
          return formatMDY(value);
        case 'estSize':
          return formatSizeUnits(value);
        default:
          return value;
      }
    }
  };

  // Filter Object By Key
  const filterObjectByKey = (obj, filter) => {
    return Object.entries(obj).reduce((newObj, [key, value]) => {
      if (filter.includes(key)) {
        newObj[key] = modifyData(key, value);
      }
      return newObj;
    }, {});
  };

  useEffect(() => {
    if (downloadSetting) {
      setLeftCol(
        filterObjectByKey(downloadSetting, [
          'ownerName',
          'dateCreated',
          'dateShared',
          'editable',
          'shareable',
          'specialPurpose',
        ])
      );
      setRightCol(
        filterObjectByKey(downloadSetting, [
          'items',
          'estSize',
          'lastUpdated',
          'lastUpdatedBy',
          'lastDownload',
          'lastSchedule',
        ])
      );
    }
  }, [downloadSetting]);

  return (
    <Skeleton loading={!downloadSetting} active>
      <div className='download-information'>
        <Row align='bottom' style={{ marginBottom: 10 }}>
          <Col span={6}>
            <img
              className='download-information__logo'
              src={thumbnailImg}
              alt={`${downloadSetting?.folderName}`}
            />
          </Col>
          <Col span={18}>
            <Text className='download-information__name'>
              {downloadSetting?.folderName}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {leftCol && (
              <LabelValueWrapper
                object={leftCol}
                parentScoop='folder.download'
                colspan={11}
              />
            )}
          </Col>
          <Col span={12}>
            {rightCol && (
              <LabelValueWrapper
                object={rightCol}
                parentScoop='folder.download'
                colspan={11}
              />
            )}
          </Col>
        </Row>
      </div>
    </Skeleton>
  );
};

DownloadInformation.propTypes = {
  downloadSetting: oneOfType([PropTypes.array, PropTypes.object]),
  ownerFolder: PropTypes.bool,
};

export default DownloadInformation;
