import _ from 'lodash';
import React, { useState } from 'react';
import {
  AutoComplete,
  Col,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import {
  AddNewItem,
  UpdateValueButton,
} from '../../drug-facts-label/components/DrugFactsButton';
import { useGetEnumsByEnumName } from 'hooks';
import { Form, SectionWrapper, WrapperSelect } from 'common/components';

import './Nutrients.less';

const NUTRIENTS_FIELD_NAME = [
  'nutrientName',
  'limit',
  'guaranteed',
  'guaranteedUom',
  'quantity',
  'quantityUom',
];

const Nutrients = () => {
  const form = Form.useFormInstance();
  const nutrientsList = Form.useWatch('nutrients', form);

  const [mode, setMode] = useState();

  const onClickAddNew = () => {
    form.resetFields(NUTRIENTS_FIELD_NAME);
    setMode('add');
    form.setFieldsValue({ guaranteedUom: '%' });
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    form
      .validateFields(NUTRIENTS_FIELD_NAME)
      .then((value) => {
        form.setFieldsValue({
          nutrients: [...(nutrientsList || []), value],
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ padding: '8px 0px' }} className='pet-food'>
      <NutrientsList setMode={setMode} mode={mode} />
      {mode === 'add' && <AddNutrientsForm />}
      <AddNewItem
        mode={mode}
        onClickAdd={onClickAdd}
        onClickCancel={onClickCancel}
        onClickAddNew={onClickAddNew}
      />
    </div>
  );
};

export default Nutrients;

const NutrientsList = ({ setMode, mode }) => {
  const form = Form.useFormInstance();
  const nutrientsList = Form.useWatch('nutrients', form);

  const onClickUpdate = (index) => {
    const cloneNutrientsList = _.cloneDeep(nutrientsList);

    form
      .validateFields(NUTRIENTS_FIELD_NAME)
      .then((value) => {
        cloneNutrientsList[index] = value;

        form.setFieldsValue({
          nutrients: cloneNutrientsList,
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickDelete = (index) => {
    const cloneNutrientsList = _.cloneDeep(nutrientsList);
    cloneNutrientsList.splice(index, 1);

    form.setFieldsValue({
      nutrients: cloneNutrientsList,
    });
  };

  const onClickEdit = (index, item) => {
    setMode(`edit-${index}`);
    form.setFieldsValue({ ...item });
  };

  const onCancel = () => {
    setMode('view');
  };

  return (
    <>
      <Form.Item name='nutrients' hidden />
      {nutrientsList?.map((item, index) => {
        const {
          nutrientName,
          quantity,
          quantityUom,
          guaranteed,
          guaranteedUom,
          limit,
        } = item;

        const isEdit = mode === `edit-${index}`;

        const perCupValue = quantity ? quantity + ' ' + quantityUom : '';
        const guaranteedValue = guaranteed + ' ' + guaranteedUom;

        return (
          <div key={index}>
            {isEdit ? (
              <>
                <AddNutrientsForm />
                <UpdateValueButton
                  onCancel={onCancel}
                  onClickUpdate={() => onClickUpdate(index)}
                />
              </>
            ) : (
              <SectionWrapper
                title={
                  <Space>
                    <EditOutlined onClick={() => onClickEdit(index, item)} />
                    <DeleteOutlined onClick={() => onClickDelete(index)} />
                  </Space>
                }
                placement='right'
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <Row wrap={false} gutter={4}>
                  <Col span={15}>
                    <Typography.Text>
                      {nutrientName} <span>{limit ? `(${limit})` : ''}</span>
                    </Typography.Text>
                  </Col>

                  <Col span={5} style={{ textAlign: 'center' }}>
                    <Typography.Text ellipsis={{ tooltip: guaranteedValue }}>
                      {guaranteedValue}
                    </Typography.Text>
                  </Col>

                  <Col span={4} style={{ textAlign: 'center' }}>
                    <Typography.Text
                      ellipsis={{
                        tooltip: perCupValue,
                      }}
                    >
                      {perCupValue}
                    </Typography.Text>
                  </Col>
                </Row>
              </SectionWrapper>
            )}
          </div>
        );
      })}
    </>
  );
};

const AddNutrientsForm = () => {
  const form = Form.useFormInstance();
  const nutrients = Form.useWatch('nutrients', form);
  const petNutrientEnum = useGetEnumsByEnumName('PetNutrientEnum');
  const guaranteedUnitEnum = useGetEnumsByEnumName('GuaranteedUnitEnum');
  const petNutrientLimitEnum = useGetEnumsByEnumName('PetNutrientLimitEnum');
  const petNutrientQuantityUnitEnum = useGetEnumsByEnumName(
    'PetNutrientQuantityUnitEnum'
  );

  const petFoodSuggestions = petNutrientEnum?.map((item) => {
    return {
      label: item?.enumDisplayName,
      value: item?.enumDisplayName,
    };
  });

  const quantity = Form.useWatch('quantity', form);

  return (
    <div className='pet-food__nutrient-form'>
      {nutrients?.length > 0 && <Divider style={{ margin: '8px 0' }} />}
      <Row gutter={4}>
        <Col span={24}>
          <Form.Item
            name='nutrientName'
            rules={[{ required: true, message: 'Nutrient Name is required' }]}
          >
            <AutoComplete
              options={petFoodSuggestions}
              filterOption={(inputValue, option) => {
                return (
                  option?.label
                    ?.toLowerCase()
                    ?.indexOf(inputValue?.toLowerCase()) > -1
                );
              }}
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            >
              <Input placeholder='Nutrient name' />
            </AutoComplete>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='limit'>
            <WrapperSelect
              placeholder='Select measurement limit'
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
              allowClear={true}
            >
              {[
                petNutrientLimitEnum?.map((labelType) => {
                  return (
                    <Select.Option value={labelType?.enumDisplayName}>
                      {labelType?.enumDisplayName}
                    </Select.Option>
                  );
                }),
              ]}
            </WrapperSelect>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name='guaranteed'
            rules={[{ required: true, message: 'Guaranteed is required' }]}
          >
            <InputNumber placeholder='Guaranteed' style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='guaranteedUom'
            rules={[{ required: true, message: 'Guaranteed Unit is required' }]}
          >
            <WrapperSelect
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
              allowClear={true}
              placeholder='Unit'
            >
              {[
                guaranteedUnitEnum?.map((labelType) => {
                  return (
                    <Select.Option value={labelType?.enumDisplayName}>
                      {labelType?.enumDisplayName}
                    </Select.Option>
                  );
                }),
              ]}
            </WrapperSelect>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name='quantity'>
            <InputNumber
              onChange={() => form?.validateFields(['quantityUom'])}
              style={{ width: '100%' }}
              placeholder='Quantity'
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name='quantityUom'
            rules={[
              {
                required: quantity != null,
                message: 'Quality Unit is required',
              },
            ]}
          >
            <WrapperSelect
              placeholder='Unit'
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
              allowClear={true}
            >
              {[
                petNutrientQuantityUnitEnum?.map((labelType) => {
                  return (
                    <Select.Option value={labelType?.enumDisplayName}>
                      {labelType?.enumDisplayName}
                    </Select.Option>
                  );
                }),
              ]}
            </WrapperSelect>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
