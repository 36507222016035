import React from 'react';
// components
import { RibbonBar, RibbonDivider } from 'common/components';
import {
  ManageSharingSection,
  DetailSection,
  ViewLayout,
  AdvanceStack,
  BackControl,
} from 'pages/home/ribbon/components';
import {
  CreateTicketingGroup,
  EditTicketingGroup,
  DeleteTicketingGroup,
} from 'pages/home/ribbon/ticketing-group/components';

const TicketingGroupRibbon = () => {
  const regex = /^\/ticket-group-assignment\/member\/\d+$/;
  const showCloseDetail = regex.test(window.location.pathname);
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <CreateTicketingGroup />
      <EditTicketingGroup />
      <DeleteTicketingGroup />
      <RibbonDivider />
      <AdvanceStack isDisabled />
      {showCloseDetail && <BackControl ribbonSide='right' />}
    </RibbonBar>
  );
};

export default TicketingGroupRibbon;
