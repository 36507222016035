import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col, Typography } from 'antd';

import {
  StyledModal,
  SectionWrapper,
  BasicFormItemList,
  FullWidthSpace,
  WithLoading,
  Form,
  CustomNotification,
} from 'common/components';
import AddAssetPimModal from 'pages/product-full-view/components/modal/add-asset-pim-modal';

import { useGetQaPackagingFormDefine } from 'pages/qa-spec/components/qa-packaging/hooks';
import {
  useGetQaPackagingTabData,
  useSaveQaPackagingTab,
} from 'pages/qa-spec/components/qa-packaging/queries';

import {
  getCertificateParams,
  parsePackagingData,
} from 'pages/qa-spec/components/qa-packaging/utils';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import { useGetProductVersionList } from 'pages/product-history/hook';
import { useCheckAllowEditProducts } from 'hooks';

const ProductPackagingModal = (props) => {
  const [formInst] = Form.useForm();
  const { productId, id } = useParams();

  const { visible, closeModal, isQASpecLocked, productFull } = props;

  const [uploadModalTitle, setUploadModalTitle] = useState('');
  const [uploadDocumentModalVisible, setUploadDocumentModalVisible] =
    useState(false);

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  const _productId = productId || id;

  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const { data, isLoading: loading } = useGetQaPackagingTabData(
    { productId: _productId },
    { enabled: !!visible, retry: false }
  );

  const { handleRefetchProductVersioning } = useGetProductVersionList({
    enabled: false,
  });

  const {
    formProductDefine,
    formLeftBottomDefine,
    formProductClaimFirstSectionDefine,
    formRightSecondSection,
    formDietaryThirdSectionDefine,
    formRightFourthSectionDefine,
  } = useGetQaPackagingFormDefine({
    handleToggleUploadDocumentModal: () => {
      handleToggleUploadDocumentModal();
    },
    setUploadModalTitle,
    productFull,
  });
  const handleSaveQaPackagingTab = useSaveQaPackagingTab();
  const { isLoading: saveLoading } = handleSaveQaPackagingTab;

  const handleToggleUploadDocumentModal = () => {
    setUploadDocumentModalVisible((prev) => !prev);
  };

  const handleSave = async (ixoneIgnore) => {
    formInst
      .validateFields()
      .then(async (values) => {
        const certificateParams = getCertificateParams(values);

        const formData = { ...values, ...certificateParams };

        handleSaveQaPackagingTab.mutate(
          {
            productId: _productId,
            formData: formData,
          },
          {
            onSuccess: async () => {
              await handleUpdateIXONEShield(ixoneIgnore);
              handleRefetchProductVersioning();
              closeModal && closeModal();
            },
          }
        );
      })
      .catch((e) => {
        if (e?.errorFields?.length > 0) {
          CustomNotification.error('Please resolve errors.');
        }
      });
  };
  const onCancel = () => {
    const parsedData = parsePackagingData(data);
    formInst.setFieldsValue(parsedData);
    closeModal && closeModal();
  };

  const onFormChange = (changedValues, allValues) => {
    if (
      changedValues.hasOwnProperty('recyclable') &&
      (changedValues?.recyclable === 'No' || !changedValues?.recyclable)
    ) {
      formInst.setFieldsValue({ recyclableTypes: [] });
    }
    if (
      changedValues.hasOwnProperty('bilingual') &&
      (changedValues?.bilingual === 'No' || !changedValues?.bilingual)
    ) {
      formInst.setFieldsValue({ secondaryLanguage: [] });
    }
  };

  const parsedData = parsePackagingData(data);

  useEffect(() => {
    formInst.setFieldsValue(parsedData);
  }, [JSON.stringify(data)]);

  return (
    <IXONECertificationWrapper
      onConfirm={(ixoneIgnore) => {
        handleSave(ixoneIgnore);
      }}
      showModal
      type={TYPE_VIEW.EDIT_PRODUCT}
      onCancel={onCancel}
    >
      {(handleConfirmIXONE) => (
        <StyledModal
          title={'Product Labeling'}
          visible={visible}
          width={1200}
          bodyStyle={{ height: '80vh' }}
          onCancel={onCancel}
          okButtonProps={{ disabled: !isAllowEditProduct }}
          maskClosable={false}
          onOk={async () => {
            handleConfirmIXONE({
              originalData: parsedData,
              changedFields: await formInst.validateFields(),
            });
          }}
        >
          <WithLoading loading={loading || saveLoading}>
            <div className='scroller--y'>
              <Form
                className='qa-packaging'
                form={formInst}
                style={{ paddingRight: 5 }}
                layout='vertical'
                onValuesChange={onFormChange}
              >
                <Row gutter={[20, 20]}>
                  <Col span={12}>
                    <FullWidthSpace size={15}>
                      <SectionWrapper title='Product'>
                        <BasicFormItemList
                          formDefine={formProductDefine}
                          style={{ paddingTop: 8 }}
                          labelFn={labelFn}
                          allowEdit={isAllowEditProduct}
                        />
                      </SectionWrapper>

                      <BasicFormItemList
                        formDefine={formLeftBottomDefine}
                        labelFn={labelFn}
                        allowEdit={isAllowEditProduct}
                      />
                    </FullWidthSpace>
                  </Col>

                  <Col span={12}>
                    <FullWidthSpace size={15}>
                      <SectionWrapper title='Product Claims'>
                        <BasicFormItemList
                          formDefine={formProductClaimFirstSectionDefine}
                          style={{ paddingTop: 8 }}
                          labelFn={labelFn}
                          allowEdit={isAllowEditProduct}
                        />
                      </SectionWrapper>

                      <BasicFormItemList
                        formDefine={formRightSecondSection}
                        labelFn={labelFn}
                        allowEdit={isAllowEditProduct}
                      />

                      <SectionWrapper title='Dietary Certificate'>
                        <BasicFormItemList
                          formDefine={formDietaryThirdSectionDefine}
                          style={{ paddingTop: 20 }}
                          labelFn={labelFn}
                          allowEdit={isAllowEditProduct}
                        />
                      </SectionWrapper>

                      <BasicFormItemList
                        formDefine={formRightFourthSectionDefine}
                        labelFn={labelFn}
                        allowEdit={isAllowEditProduct}
                      />
                    </FullWidthSpace>
                  </Col>
                </Row>
                {uploadDocumentModalVisible && (
                  <AddAssetPimModal
                    onCloseModal={handleToggleUploadDocumentModal}
                    addType={'Document'}
                    getImagery={[]}
                    qaSpec={true}
                    getDocument={handleToggleUploadDocumentModal}
                    title={uploadModalTitle}
                    noCapitalize={true}
                  />
                )}
              </Form>
            </div>
          </WithLoading>
        </StyledModal>
      )}
    </IXONECertificationWrapper>
  );
};

const labelFn = (labelText) => {
  return <Typography.Text strong>{labelText && labelText}</Typography.Text>;
};

export default ProductPackagingModal;
