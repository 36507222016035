import * as api from 'config/axios';
import * as endpoints from './endpoints';

/**
 * User login service
 * @param {object} data user login information
 */
export const getAssetProductService = (options) => {
  return api.sendGet(endpoints.GET_ASSET_PRODUCT_ENDPOINT, options);
};

export const getAssetList = (params) => {
  params.pageIndex = params.pageNumber;
  const endpoint = params.isFavoriteRoute
    ? endpoints.ASSET_LIST_FAVORITE
    : endpoints.ASSET_LIST_ENDPOINT;
  return api.sendPost(endpoint, params);
};

export const getDigitalAssetShortDetail = (params) => {
  return api.sendGet(endpoints.ASSET_SHORT_DETAIL, { params: params });
};

export const deleteAssets = (payload) => {
  return api.sendPost(endpoints.DELETE_ASSETS, payload);
};

export const getAssetAssociation = (id) => {
  return api.sendGet(`${endpoints.GET_ASSET_ASSOCIATION}?Id=${id}`);
};

export const updateLinkAsset = (payload) => {
  return api.sendPost(endpoints.UPDATE_LINK_ASSET, payload);
};
export const updateAssetType = (data) => {
  return api.sendPost(endpoints.UPDATE_ASSET_TYPE, data);
};
export const updateLastGetNewDam = () => {
  return api.sendPost(endpoints.UPDATE_LAST_GET_NEW_DAM);
};
