import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import {
  ViewLayout,
  AdvanceStack,
  ManageSharingSection,
  DetailSection,
} from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import {
  CreateField,
  EditField,
  DeleteFields,
} from '../ribbon/components/controls/recipients-fields';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { Can } from 'context/Can';

const RecipientsFieldsGridView = () => {
  const requiredFieldId = useSelector(
    selectorsGridView.makeSelectItemsSelection()
  );

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.PRODUCT}>
        <EditField />
        <CreateField isSmallBtn />
        <DeleteFields isSmallBtn fieldId={requiredFieldId} />
        <RibbonDivider />
      </Can>
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default RecipientsFieldsGridView;
