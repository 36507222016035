import React from 'react';

import { Col, Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import ThumbnailItem from 'common/components/thumb/ThumbnailItem';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './SelectedProductList.less';

const SelectedProductList = (props) => {
  const { productList, handleRemoveActiveProduct, scrollerX, mode } = props;

  return (
    <div
      className={classNames('selected-product-list scroller', {
        'scroller--x': scrollerX,
      })}
      // xs={24}
      style={{ height: '100%' }}
    >
      <TransitionGroup
        component='div'
        className={classNames({ 'selected-product-list-wrapper': scrollerX })}
      >
        {productList &&
          productList.length > 0 &&
          productList.map((item, index) => {
            item = { ...item, isOwner: true };

            return (
              <CSSTransition
                key={item?.id}
                timeout={500}
                classNames='selected-product-list__ani'
              >
                <div
                  className={classNames({
                    'selected-product-list__item': true,
                  })}
                >
                  <ThumbnailItem
                    imageThumbnail={item?.thumbnail}
                    dataDetail={item}
                    isProductThumbnail
                    isShareBtn
                  />
                  <Button
                    className='selected-product-list__item-btn-delete'
                    shape='circle'
                    danger
                    icon={
                      mode === 'assign-category' ? (
                        <Tooltip title='deselect product'>
                          <CloseOutlined />
                        </Tooltip>
                      ) : (
                        <CloseOutlined />
                      )
                    }
                    type='primary'
                    size='small'
                    onClick={() => handleRemoveActiveProduct(item)}
                  />
                </div>
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </div>
  );
};

export default SelectedProductList;
