import React from 'react';

import {
  Col,
  Button,
  Image,
  Dropdown,
  Menu,
  Space,
  Typography,
  Result,
  Tag,
  Tooltip,
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';

import brokerSrc from 'assets/member/broker.png';
import distributorSrc from 'assets/member/distributor.png';
import manufacturerSrc from 'assets/member/manufacturer.png';
import retailerSrc from 'assets/member/retailer.png';

import './MemberType.less';

const MemberType = ({ memberType = '' }) => {
  const dropdownContentRender = () => {
    return (
      <Menu className='member-type__drop-down' style={{ padding: '4px' }}>
        <Menu.Item>
          <div className='member-type__drop-down-header'>
            {memberType ? (
              <Space>
                <Typography.Title
                  level={5}
                  style={{ fontSize: '13px', margin: 0 }}
                >
                  Member Type:
                </Typography.Title>

                <Tag color='#108ee9'>
                  {memberType ? memberType.toUpperCase() : ''}
                </Tag>
              </Space>
            ) : (
              <Result status='error' subTitle='Member type is not found' />
            )}
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const memberTypeImage = getImageMemberType(memberType);

  return (
    <Col className='member-type'>
      {memberTypeImage.imageSrc ? (
        <Dropdown overlay={dropdownContentRender} placement='bottomRight'>
          <Button className='member-type__btn'>
            <Image
              className='member-type__btn-icon'
              src={memberTypeImage.imageSrc}
              preview={false}
            />
          </Button>
        </Dropdown>
      ) : (
        <Dropdown overlay={dropdownContentRender} placement='bottomRight'>
          {memberTypeImage.icon}
        </Dropdown>
      )}
    </Col>
  );
};

export const MemberTypeTile = ({ memberType, ...otherProps }) => {
  const memberTypeImage = getImageMemberType(memberType);

  return (
    <div
      style={{ height: 20, display: 'flex', alignItems: 'center', zIndex: 30 }}
    >
      {memberTypeImage.imageSrc ? (
        <TooltipMemberType memberType={memberType}>
          <Image
            src={memberTypeImage.imageSrc}
            preview={false}
            style={{ height: 20 }}
          />
        </TooltipMemberType>
      ) : (
        <TooltipMemberType memberType={memberType}>
          <Tooltip title='test'>{memberTypeImage.icon}</Tooltip>
        </TooltipMemberType>
      )}
    </div>
  );
};

const TooltipMemberType = ({ memberType, children }) => {
  return (
    <Tooltip
      title={() => (
        <Typography.Text style={{ color: '#fff', textTransform: 'capitalize' }}>
          Member Type: {memberType}
        </Typography.Text>
      )}
    >
      {children}
    </Tooltip>
  );
};

const getImageMemberType = (memberType = '') => {
  const lcMemberType = memberType?.toLowerCase();

  if (lcMemberType === 'broker') {
    return {
      imageSrc: brokerSrc,
    };
  }

  if (lcMemberType === 'distributor')
    return {
      imageSrc: distributorSrc,
    };

  if (lcMemberType === 'manufacturer') {
    return {
      imageSrc: manufacturerSrc,
    };
  }

  if (lcMemberType === 'retailer')
    return {
      imageSrc: retailerSrc,
    };

  return {
    icon: <QuestionCircleOutlined style={{ fontSize: 18 }} />,
  };
};

export default MemberType;
