import React, { useRef } from 'react';

import { Row, Col } from 'antd';
import classnames from 'classnames';
import { EllipsisOutlined, LeftOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import RibbonDivider from './RibbonDivider';

import { useShowMoreRibbonButtons } from 'hooks/useShowMoreRibbonButtons';

import { Can } from 'context/Can';

import Messages from 'i18n/messages/home';

import './RibbonBar.less';

const RibbonBar = (props) => {
  const ribbonRef = useRef(null);

  const { children, className } = props;

  const { shouldShowMore, activeMoreButton, setActiveMoreButton } =
    useShowMoreRibbonButtons({ ribbonRef, children });

  const leftSideChildren = children.length
    ? children.filter((child) => child?.props?.ribbonSide !== 'right')
    : [];

  const rightSideChildren = children.length
    ? children.filter((child) => child?.props?.ribbonSide === 'right')
    : [];

  const onClickMoreButton = () => {
    setActiveMoreButton((prevState) => !prevState);
  };

  if (!children) return children;

  if (!children.length)
    return (
      <Row className={classnames('ribbon-bar', className)}>
        <Col className='ribbon-bar__side' flex={1}>
          {renderRibbonChildren(children)}
        </Col>
      </Row>
    );

  const MoreButtonIcon = activeMoreButton ? LeftOutlined : EllipsisOutlined;
  const moreButtonLabel = activeMoreButton
    ? Messages.moreButtonBack
    : Messages.moreButton;

  return (
    <Row
      ref={ribbonRef}
      className={classnames(className, {
        'ribbon-bar': true,
        'ribbon-bar--show-more': shouldShowMore,
        'ribbon-bar--active-show-more': activeMoreButton,
      })}
    >
      <Col className='ribbon-bar__side ribbon-bar__side-left' flex={1}>
        {renderRibbonChildren(leftSideChildren)}
      </Col>
      {shouldShowMore ? (
        <RibbonSection>
          <RibbonButton
            style={{ width: 85 }}
            icon={<MoreButtonIcon />}
            label={moreButtonLabel}
            onClick={onClickMoreButton}
          />
          <RibbonDivider />
        </RibbonSection>
      ) : null}
      <Col className='ribbon-bar__side ribbon-bar__side-right' flex={0}>
        {renderRibbonChildren(rightSideChildren)}
      </Col>
    </Row>
  );
};

export const RibbonSection = (props) => {
  const { children } = props;

  return (
    <div className='ribbon-bar__section'>{renderRibbonChildren(children)}</div>
  );
};

const renderRibbonChildren = (children) => {
  if (!children) return children;

  //* render only one child
  const isChildrenArray =
    typeof children === 'object' && children.length !== undefined;
  if (!isChildrenArray) return renderRibbonChildren([children]);

  //* render list of children
  return children?.length
    ? children.map((child, index) => {
        if (!child) return child;

        if (typeof child === 'function') {
          return renderRibbonChildren([child()]);
        }

        const isSmallBtn = child?.props?.isSmallBtn;
        const isFragment = child.type === React.Fragment;
        const isCan = child.type === Can;

        if (isFragment) return renderRibbonChildren(child.props.children);

        if (isCan) {
          const { children, ...rest } = child.props;
          return (
            <Can {...rest} key={index}>
              <>{renderRibbonChildren(child.props.children)}</>
            </Can>
          );
        }

        return (
          <div
            className={classnames({
              'ribbon-bar__large-item': !isSmallBtn,
              'ribbon-bar__small-item': isSmallBtn,
            })}
            key={index}
          >
            {child}
          </div>
        );
      })
    : null;
};

export default RibbonBar;
