import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMappings = (state) => state.mappings || initialState;

const selectLoading = () =>
  createSelector(selectMappings, (state) => state.loading);

const selectColumns = () =>
  createSelector(selectMappings, (state) => state.columns);

const selectMappingConfigColumns = () =>
  createSelector(selectMappings, (state) => state.configGridColumns);

const selectMappingSourceProperties = () =>
  createSelector(selectMappings, (state) => state.mappingSourceProperties);

const selectCurrentMappingId = () =>
  createSelector(selectMappings, (state) => state.currentMappingId);

const selectMappingConfigProperties = () =>
  createSelector(selectMappings, (state) => state.mappingConfigProperties);

const selectMappingDetail = () =>
  createSelector(selectMappings, (state) => state.mappingDetail);

const selectFormatTypeFilter = () =>
  createSelector(selectMappings, (state) => state.formatTypeFilter);

export {
  selectLoading,
  selectColumns,
  selectMappingConfigColumns,
  selectMappingSourceProperties,
  selectCurrentMappingId,
  selectMappingConfigProperties,
  selectMappingDetail,
  selectFormatTypeFilter,
};
