import React, { useState } from 'react';

import { Typography } from 'antd';

import videoThumbnail from 'assets/asset-icons/video-thumbnail.png';

const { Text } = Typography;

const VideoMessage = (props) => {
  const { message } = props;
  const [isFocused, setIsFocused] = useState(false);

  const toggleFocus = () => {
    setIsFocused((preState) => {
      return !preState;
    });
  };

  return (
    <div className='chat-thread__video'>
      {message?.title ? (
        <Text className='chat-thread__video-title' strong>
          {message?.title}
        </Text>
      ) : null}
      <div
        className='chat-thread__video-frame-container'
        onMouseEnter={toggleFocus}
        onMouseLeave={toggleFocus}
      >
        <video
          className='chat-thread__video-video'
          controls={isFocused}
          muted
          preload='none'
          poster={videoThumbnail}
          controlsList='nodownload'
          disablePictureInPicture
        >
          <source src={message?.url} />
        </video>
      </div>
    </div>
  );
};

export default VideoMessage;
