export const manufacturingProcessCharactersLimit = {
  processStage: 150,
  natureOfTheTests: 5000,
  targetValueAndTolerances: 5000,
  frequency: 5000,
  test: 150,
  criticalPointEstablishedInHACCP: 5000,
};

export const formulaCharactersLimit = {
  salesDesignation: 100,
  technicalDesignation: 100,
  casNumber: 50,
  function: 50,
  classificationAndRiskPhrases: 50,
  originSpecification: 50,
};
