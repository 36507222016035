import React, { useState, useEffect } from 'react';

import { Row, Col, Button } from 'antd';

import { StyledModal } from 'common/components';

import { useIsMutating } from '@tanstack/react-query';

import QaSpecFacilityGrid from './QaSpecFacilityGrid';
import { getAddedLocationMutationKey } from './hooks';

const QaSpecLinkFacilityModal = (props) => {
  const { visible, closeModal, ownerId, onOk } = props;

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [rowsSelected, setRowSelected] = useState([]);

  const isLoading = useIsMutating({
    mutationKey: getAddedLocationMutationKey(),
  });

  const clearSelectedRows = () => {
    setRowSelected([]);
  };

  const onCancelBtnClick = () => {
    closeModal();
  };

  const onAddBtnClick = () => {
    onOk && onOk(rowsSelected);
  };

  const onCreateBtnClick = () => {
    setCreateModalVisible(true);
  };

  useEffect(() => {
    clearSelectedRows();
  }, [visible]);

  return (
    <StyledModal
      title='Assign Production Facility'
      visible={visible}
      width={1200}
      bodyStyle={{ height: '80vh', maxHeight: 500 }}
      destroyOnClose
      maskClosable={false}
      onCancel={closeModal}
      footer={
        <Row>
          <Col flex={0}>
            <Button type='primary' onClick={onCreateBtnClick}>
              Create New Facility
            </Button>
          </Col>
          <Col flex={1}></Col>
          <Col flex={0}>
            <Button onClick={onCancelBtnClick}>Cancel</Button>
            <Button
              type='primary'
              disabled={!rowsSelected.length}
              onClick={onAddBtnClick}
              loading={isLoading > 0}
            >
              Add to Specification
            </Button>
          </Col>
        </Row>
      }
    >
      <QaSpecFacilityGrid
        isAddLocation={createModalVisible}
        setIsAddLocation={setCreateModalVisible}
        setRowSelected={setRowSelected}
        ownerId={ownerId}
      />
    </StyledModal>
  );
};

export default QaSpecLinkFacilityModal;
