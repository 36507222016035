import React, { useState, useEffect } from 'react';

import NutritionBreakdown from '../../nutrition-label/components/utils/NutritionBreakdown';
import LinearVitaminLabel from '../../nutrition-label/components/LinearVitaminLabel';

import { getUomTypesAbbreviations } from '../../nutrition-label/DailyValueConstants';
import { parseNumber } from 'utils/parser';
import { getNutritionNameByTypeCode } from '../../nutrition-label/constants';

import { useGetBasicUomEnum } from 'hooks';

function LinearLabel({ label }) {
  const [primaryNutrition, setPrimaryNutrition] = useState([]);
  const [secondaryNutrition, setSecondaryNutrition] = useState([]);

  const { basicUomProperties } = useGetBasicUomEnum();

  useEffect(() => {
    async function getData() {
      const nutrition_breakdown = await NutritionBreakdown(
        label?.nutritionFactProperties
      );
      setPrimaryNutrition(nutrition_breakdown?.primaryNutrition);
      setSecondaryNutrition(nutrition_breakdown?.secondaryNutrition);
    }
    if (label) {
      getData();
    }
  }, [label]);

  const highlightLabelName = [
    'FAT',
    'CHOLESTEROL',
    'NA',
    'CHOLESTEROL',
    'CARBOHYDRATES',
    'PROTEIN',
    'FD',
  ];

  const checkHighlightName = (typeCode) =>
    highlightLabelName.includes(typeCode);

  const filteredPrimaryNutrition = Object.values(primaryNutrition)?.filter(
    (i) => i !== null
  );

  const filteredSecondaryNutrient = Object.values(secondaryNutrition)?.filter(
    (i) => i !== null
  );

  return (
    <div className='nutrition-facts__label--linear'>
      <span className='nutrition-facts__label--linear-label'>
        Nutrition Facts
      </span>
      {label?.servingsPerContainer && (
        <span>Servings: {label?.servingsPerContainer},</span>
      )}

      <span className='nutrition-facts__label--linear__servingsize-bold'>
        Serv. Size:{' '}
      </span>
      <span className='nutrition-facts__label--linear__servingsize-bold'>
        {label?.servingSizeDescription}{' '}
        {label?.servingSize &&
          `(${label?.servingSize} ${getUomTypesAbbreviations(
            basicUomProperties,
            label?.servingSizeMeasurement
          )}),`}
      </span>
      <span>Amount Per Serving:</span>
      <span className='nutrition-facts__label--linear__servingsize-bold'>
        {`Calories ${label?.calories ? label?.calories : 0},`}
      </span>
      <>
        {filteredPrimaryNutrition?.map((vit, idx) => (
          <LinearVitaminLabel
            key={idx}
            labelBold={checkHighlightName(vit?.nutritionTypeCode)}
            label={getNutritionNameByTypeCode(vit?.nutritionTypeCode)}
            gram={`${parseNumber(vit?.quantity)} ${getUomTypesAbbreviations(
              basicUomProperties,
              vit?.quantityUom
            )}`}
            percent={parseNumber(vit?.dailyValueIntakePercent)}
          />
        ))}
        {filteredSecondaryNutrient?.map((vit, idx) => (
          <LinearVitaminLabel
            key={idx}
            isEnd={idx - filteredSecondaryNutrient.length === -1}
            label={getNutritionNameByTypeCode(vit?.nutritionTypeCode)}
            gram={`${parseNumber(vit?.quantity)} ${getUomTypesAbbreviations(
              basicUomProperties,
              vit?.quantityUom
            )}`}
            percent={parseNumber(vit?.dailyValueIntakePercent)}
          />
        ))}
      </>
    </div>
  );
}

export default LinearLabel;
