import React from 'react';

import {
  Popover,
  Typography,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Row,
  Col,
} from 'antd';

import classnames from 'classnames';

import { WrapperSelect } from 'common/components';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import isEqual from 'lodash/isEqual';

import './RetailerSnapshot.less';

const { Option } = Select;

export const RetailerSnapshot = ({
  supplierModifiedValue,
  snapshotValue,
  highlightContent,
  children,
  ...rest
}) => {
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const isDiffValue = !isEqual(supplierModifiedValue, snapshotValue);

  let element = children;

  if (isToggleSnapshot && isDiffValue) {
    element = (
      <Popover
        overlayClassName='retailer-snapshot__popover'
        title={<Typography.Text strong>Previous Version</Typography.Text>}
        trigger='hover'
        overlayInnerStyle={{ minWidth: 400 }}
        content={highlightContent}
        {...rest}
      >
        <div className='retailer-snapshot__highlight'>{children}</div>
      </Popover>
    );
  }

  return element;
};

export const RetailerSnapshotInput = (props) => {
  const { className, ...rest } = props;

  return (
    <Input
      className={classnames({
        'retailer-snapshot__input': true,
        [className]: !!className,
      })}
      {...rest}
    />
  );
};

export const RetailerSnapshotInputNumber = (props) => {
  const { className, ...rest } = props;

  return (
    <InputNumber
      className={classnames({
        'retailer-snapshot__input-number': true,
        [className]: !!className,
      })}
      {...rest}
    />
  );
};

export const RetailerSnapshotTextarea = (props) => {
  const { className, ...rest } = props;

  return (
    <Input.TextArea
      className={classnames({
        'retailer-snapshot__input': true,
        [className]: !!className,
      })}
      {...rest}
    />
  );
};

export const RetailerSnapshotCheckbox = (props) => {
  const { className, ...rest } = props;

  return (
    <Checkbox
      className={classnames({
        'retailer-snapshot__checkbox': true,
        [className]: !!className,
      })}
      {...rest}
    />
  );
};

export const RetailerSnapshotSelect = (props) => {
  const { className, children, ...rest } = props;

  return (
    <WrapperSelect
      className={classnames({
        'retailer-snapshot__select': true,
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </WrapperSelect>
  );
};

export const RetailerSnapshotOption = (props) => {
  const { className, children, ...rest } = props;

  return (
    <Option
      className={classnames({
        'retailer-snapshot__select-option': true,
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </Option>
  );
};

export const PrimaryFieldSnapshot = ({ variant = 'text', ...otherProps }) => {
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  switch (variant) {
    case 'text':
      return isToggleSnapshot ? (
        <RetailerSnapshotInput {...otherProps} />
      ) : (
        <Input {...otherProps} />
      );

    case 'textarea':
      return isToggleSnapshot ? (
        <RetailerSnapshotTextarea {...otherProps} />
      ) : (
        <Input.TextArea {...otherProps} />
      );

    case 'number':
      return isToggleSnapshot ? (
        <RetailerSnapshotInputNumber {...otherProps} />
      ) : (
        <InputNumber {...otherProps} />
      );

    case 'select':
      return isToggleSnapshot ? (
        <RetailerSnapshotSelect {...otherProps} />
      ) : (
        <WrapperSelect {...otherProps} />
      );

    case 'checkbox':
      return isToggleSnapshot ? (
        <RetailerSnapshotCheckbox {...otherProps} />
      ) : (
        <Checkbox {...otherProps} />
      );

    default:
      return <Input {...otherProps} />;
  }
};

export const HighlightContent = ({
  label,
  value,
  variant = 'text',
  ...otherProps
}) => {
  return (
    <Row gutter={[6, 0]} {...otherProps}>
      <Col>
        <Typography.Text strong>{label}</Typography.Text>
      </Col>
      <Col flex={1}>
        {variant === 'checkbox' ? (
          <HighlightContentCheckbox value={value} />
        ) : (
          <Typography.Text>{value ?? 'N/A'}</Typography.Text>
        )}
      </Col>
    </Row>
  );
};

export const HighlightContentCheckbox = ({ value }) => {
  return <Checkbox checked={value} disabled />;
};
