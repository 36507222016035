import { useDispatch } from 'react-redux';

import { notification } from 'antd';

import * as brandingActions from 'redux/branding/actions';
import * as gridActions from 'common/components/grid-view/controllers/actions';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useGridView } from 'hooks/useGridView';

import * as favoriteServices from 'services/favorite';

import favoriteMessages from 'i18n/messages/favorite';
import { useIntl } from 'react-intl';

export const useFavorite = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    entityList = [],
    type,
    isFavoriteView,
    shouldReloadPage = true,
  } = props;

  const reloadPage = useDispatchReloadPage();
  const { clearGridSelection } = useGridView();

  const getRequestData = () => {
    if (entityList)
      return entityList?.map((item) => {
        return item.id;
      });
    else return [];
  };

  const toggleFavoriteErrorHandler = (message) => {
    notification.error({
      message,
    });
  };

  const callToggleFavoriteApi = async (isFavorite) => {
    const successMessage = isFavorite
      ? favoriteMessages.addToFavoriteSuccess
      : favoriteMessages.removeFromFavoriteSuccess;
    const errorMessage = isFavorite
      ? favoriteMessages.addToFavoriteError
      : favoriteMessages.removeFromFavoriteError;

    const apiService = isFavorite
      ? favoriteServices.addToFavorite
      : favoriteServices.removeFromFavorite;

    const data = getRequestData();

    try {
      const response = await apiService(data, entityList ? type : 'member');

      const { isSuccess, message } = response;

      if (!isSuccess) {
        toggleFavoriteErrorHandler(message || errorMessage);
        return Promise.reject();
      }

      notification.success({
        message: intl.formatMessage(successMessage),
      });

      !isFavorite &&
        isFavoriteView &&
        //* unselect removed item
        clearGridSelection();

      //* update navigation info (favorite total number)
      try {
        dispatch(brandingActions.getBrandingNoLoading());
      } catch (error) {
        console.error(
          'get branding info error after add item to favorite: ',
          error
        );
      }

      shouldReloadPage && reloadPage();

      return Promise.resolve(isSuccess);
    } catch (error) {
      toggleFavoriteErrorHandler(errorMessage);

      return Promise.reject();
    }
  };

  return { callToggleFavoriteApi };
};
