import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Avatar } from 'antd';
import PropTypes from 'prop-types';
import userSelectors from 'redux/user/selectors';

import AssetReport from 'assets/reporting/AssetReport.png';
import ProductReport from 'assets/reporting/ProductReport.png';
import MemberReport from 'assets/reporting/MemberReport.png';

const ReportingTileHeader = (props) => {
  const { dataDetail } = props;
  const userId = useSelector(userSelectors.makeSelectUserId());
  const image =
    (dataDetail?.advType === 'DigitalMedia' && AssetReport) ||
    (dataDetail?.advType === 'Member' && MemberReport) ||
    (dataDetail?.advType === 'ProductItem' && ProductReport);

  return (
    <Row className='reporting-tile__header' justify='center'>
      <div className='reporting-tile__header--background'></div>

      <div className='reporting-tile__header--image-wrapper'>
        <Avatar
          className='reporting-tile__header--image'
          src={image}
          alt={dataDetail && dataDetail.name}
        />
      </div>
    </Row>
  );
};

ReportingTileHeader.propTypes = {
  dataDetail: PropTypes.object,
};

export default ReportingTileHeader;
