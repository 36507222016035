import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * take digital asset full state from redux
 * @param {object} state
 */
const selectAssetData = (state) => state?.digitalAsset || initialState;

const selectDigitalAsset = () =>
  createSelector(selectAssetData, (state) => state.digitalAsset);

const selectDigitalAssetMetaData = () =>
  createSelector(selectAssetData, (state) => state.metaData);

const selectDigitalAssetLoading = () =>
  createSelector(selectAssetData, (state) => state.loading);

const selectDigitalAssetError = () =>
  createSelector(selectAssetData, (state) => state.error);

const selectIsActive = () =>
  createSelector(selectAssetData, (state) => state.isActive);

const assetDataLoading = () =>
  createSelector(selectAssetData, (state) => state.assetData);

const selectRequiredFields = () =>
  createSelector(selectAssetData, (state) => state.requiredFields);

const assetDataColumnInfo = () =>
  createSelector(selectAssetData, (state) => state.columns);

const selectEditSuccess = () =>
  createSelector(selectAssetData, (state) => state.editSuccess);

const selectDigitalAssetEditError = () =>
  createSelector(selectAssetData, (state) => state.editError);

const selectEditTypeLoading = () =>
  createSelector(selectAssetData, (state) => state.editTypeLoading);

//* ftp list selector
const makeSelectFtpFileList = () =>
  createSelector(selectAssetData, (subState) => subState.ftpFileList);

const makeSelectAssetData = () =>
  createSelector(selectAssetData, (subState) => subState.assetData);

const selectAuthorizedTagsLoading = () =>
  createSelector(selectAssetData, (subState) => subState.authorizedTagsLoading);

const selectAuthorizedTags = () =>
  createSelector(selectAssetData, (subState) => subState.authorizedTags);

const selectAuthorizedTagsError = () =>
  createSelector(selectAssetData, (subState) => subState.authorizedTagsError);

export {
  selectIsActive,
  selectDigitalAsset,
  selectDigitalAssetLoading,
  selectDigitalAssetMetaData,
  selectRequiredFields,
  selectAssetData,
  assetDataLoading,
  makeSelectFtpFileList,
  selectDigitalAssetError,
  assetDataColumnInfo,
  selectEditSuccess,
  selectDigitalAssetEditError,
  makeSelectAssetData,
  selectEditTypeLoading,
  selectAuthorizedTagsLoading,
  selectAuthorizedTags,
  selectAuthorizedTagsError,
};
