import React from 'react';

import { Row, Col, Avatar } from 'antd';

const ContactCardMessagesItem = (props) => {
  const { icon, children, customStyle } = props;

  return (
    <Row
      className='contact-card-messages__contact-item'
      gutter={8}
      align='middle'
      style={{ ...customStyle }}
    >
      <Col span={2}>
        <Avatar src={icon} />
      </Col>
      <Col span={22}>{children}</Col>
    </Row>
  );
};

export default ContactCardMessagesItem;
