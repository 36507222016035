import { useMutation } from '@tanstack/react-query';
import { saveDrugFacts, saveQaSpecDrugFacts } from 'services/product';

const nutritionFactsApi = {
  'qa-spec': saveQaSpecDrugFacts,
  overview: saveDrugFacts,
};

export const useAddDrugFactsLabel = ({ productId, typeView = 'overview' }) => {
  const mutation = useMutation({
    mutationKey: ['add-label'],
    mutationFn: nutritionFactsApi[typeView],
  });

  return mutation;
};
