import React, { useMemo } from 'react';
import { Typography, Space, Row, Col, Tooltip } from 'antd';
import classnames from 'classnames';

import { StarFilled, SafetyOutlined } from '@ant-design/icons';
import IconList from 'common/components/icon-list/IconList';
import { packageLevelIcons } from 'common/components/icon-list/assets';
import ProductSubscription from 'common/components/thumb/product/ProductSubscription';
import EntityStatusTag from 'common/components/tag/entity-status-tag/EntityStatusTag';
import PrivateLabelIcon from 'common/components/thumb/product/PrivateLabelcon';

import * as getLink from 'utils/common/linkBuilder';

const ProductTileBody = (props) => {
  const {
    productName,
    productDescription,
    brandName,
    isFavorited,
    subscription,
    packageLevel,
    categories,
    weight,
    weightUOM,
    imperialWeight,
    imperialWeightUOM,
    primarySize,
    primarySizeUOM,
    subscriptionShield,
    gdsnShield,
    id,
    status,
    isPrivateLabel,
  } = props;
  const { Paragraph, Title } = Typography;

  const categoryIcon = useMemo(() => {
    const defaultCategory = categories || [];
    let nextCategoryList = [...defaultCategory];

    if (packageLevel) {
      const foundPackageIcon = packageLevelIcons.find(
        (packItem) => packItem?.name === packageLevel.toLowerCase()
      );

      if (foundPackageIcon) {
        nextCategoryList = [foundPackageIcon, ...nextCategoryList];
      }
    }

    if (true) {
      nextCategoryList = [
        // {
        //   ...productSubscriptionIcon,
        //   style: {
        //     fontSize: 19,
        //     color: subscriptionShield,
        //     top: 3,
        //     position: 'relative',
        //   },
        // },
        ...nextCategoryList,
      ];
    }

    return nextCategoryList;
  }, [subscription, packageLevel, categories]);

  const aLink = getLink.productLink(id, productName);
  return (
    <div className='product-tile__body'>
      <Row className='product-tile__body-top' justify='center'>
        <Space direction='vertical' size={0}>
          <Title
            className='product-tile__body--title'
            style={{ fontSize: '14px' }}
          >
            {aLink}
          </Title>
          <Paragraph className={`product-tile__body--element`}>
            {productDescription}
          </Paragraph>

          <Paragraph className={`product-tile__body--element`}>
            {brandName}
          </Paragraph>
          {!!weight && (
            <Paragraph className={`product-tile__body--element`}>
              {weight} {weightUOM}
            </Paragraph>
          )}
          {!!imperialWeight && (
            <Paragraph className={`product-tile__body--element`}>
              {imperialWeight} {imperialWeightUOM}
            </Paragraph>
          )}
          {!!primarySize && (
            <Paragraph className={`product-tile__body--element`}>
              {primarySize} {primarySizeUOM}
            </Paragraph>
          )}
        </Space>
      </Row>
      <div className='product-tile__body-bottom'>
        <Row className='product-tile__body--status-row' justify='center'>
          <EntityStatusTag
            status={status}
            position='static'
            exceptions={['Active']}
          />
        </Row>
        <Row className='product-tile__body--segment-row' align='middle'>
          <IconList
            itemList={categoryIcon}
            size='default'
            tooltipPosition='top'
          />
          <Col flex={1} />
          <Col style={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
            {isPrivateLabel && <PrivateLabelIcon shape='square' />}
            {gdsnShield && (
              <Tooltip title='GDSN Shield'>
                <SafetyOutlined
                  className={classnames({
                    'thumbnail-item__gdsn-shield': true,
                    'thumbnail-item__gdsn-shield--yellow':
                      gdsnShield?.toLowerCase() === 'yellow',
                    'thumbnail-item__gdsn-shield--green':
                      gdsnShield?.toLowerCase() === 'green',
                  })}
                />
              </Tooltip>
            )}
            {isFavorited && <StarFilled style={styles.favoriteIcon} />}
            {subscription && <ProductSubscription color={subscriptionShield} />}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const styles = {
  favoriteIcon: {
    fontSize: '18px',
    color: '#fec810',
  },
};

export default ProductTileBody;
