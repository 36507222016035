export const BP_SSO_CREATE_NEW_PRODUCT =
  '/api/blueprint-sso/create-new-product';
export const BP_SSO_GET_PRODUCT_DETAILS =
  '/api/blueprint-sso/get-product-details';
export const BP_SSO_UPDATE_PRODUCT_DETAILS =
  '/api/blueprint-sso/update-product-details';
export const BP_SSO_GET_BP_ADD_PRODUCT_INFO =
  '/api/blueprint-sso/get-bp-add-product-info';
export const BP_SSO_CREATE_SUPPLIER = 
'/api/blueprint-sso/create-supplier';
