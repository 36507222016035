import { notification } from 'antd';
import * as _ from 'lodash';

import { CustomNotification } from 'common/components';

import { getAnonToken } from 'utils/common/session';

const responseErrorHandler = (message, skipNotification) => {
  !skipNotification && CustomNotification.error(message);
};

export const apiHandler = async (args) => {
  const {
    service,
    params,
    successMessage,
    errorMessage,
    successCallback,
    failCallback,
    onFinally,
    skipNotification,
    isReturnResponse,
  } = args;
  try {
    const response = (await service(params)) || {};

    const { isSuccess, message, data } = response;

    if (isSuccess) {
      !skipNotification &&
        successMessage &&
        CustomNotification.success(successMessage || message);

      if (successCallback) {
        await successCallback(isReturnResponse ? response : data || response);
      }
    } else {
      responseErrorHandler(message, skipNotification);

      if (failCallback) {
        await failCallback(message, data);
      }
    }
  } catch (error) {
    responseErrorHandler(errorMessage, skipNotification);
    if (failCallback) {
      await failCallback(error);
    }
  }

  onFinally && onFinally();
};

export const handleCallApiReducer = (
  action,
  _draft,
  statePath,
  isKeepPrevData = false
) => {
  const actionType = action?.type;
  const payload = action?.payload;
  const error = action?.error;

  if (actionType.includes('_SUCCESS')) {
    _.set(_draft, statePath + '.loading', false);
    _.set(_draft, statePath + '.data', payload);
    _.set(_draft, statePath + '.error', null);
    return;
  }

  if (actionType.includes('_FAIL')) {
    _.set(_draft, statePath + '.loading', false);
    _.set(_draft, statePath + '.data', null);
    _.set(_draft, statePath + '.error', error || true);
    return;
  }

  _.set(_draft, statePath + '.loading', true);
  !isKeepPrevData && _.set(_draft, statePath + '.data', null);
  _.set(_draft, statePath + '.error', null);
};

export const handleCallApiReducerForLoadMore = (
  action,
  _draft,
  state,
  statePath
) => {
  const actionType = action?.type;
  const payload = action?.payload;
  if (actionType.includes('_SUCCESS')) {
    if (payload?.gridData?.length > 0) {
      if (payload?.gridData?.length === 20) {
        _.set(_draft, statePath + '.hasMore', true);
      } else {
        _.set(_draft, statePath + '.hasMore', false);
      }

      if (state[statePath]?.data?.length > 0) {
        const array = state[statePath]?.data.concat(payload?.gridData);
        _.set(_draft, statePath + '.data', array);
      } else {
        _.set(_draft, statePath + '.data', payload?.gridData);
      }
    } else {
      _.set(_draft, statePath + '.hasMore', false);
      _.set(_draft, statePath + '.data', state[statePath]?.data);
    }
    _.set(_draft, statePath + '.loadingMore', false);
    _.set(_draft, statePath + '.error', null);
    return;
  }

  if (actionType.includes('_FAIL')) {
    _.set(_draft, statePath + '.loadingMore', false);
    _.set(_draft, statePath + '.data', null);
    _.set(_draft, statePath + '.error', null);
    return;
  }

  _.set(_draft, statePath + '.loadingMore', true);
  // _.set(_draft, statePath + '.data', null);
  _.set(_draft, statePath + '.error', null);
};

export const selectAnonOrAuthRequest = (requestArray) => {
  const anonToken = getAnonToken();

  const [authRequest, anonRequest] = requestArray;

  if (anonToken) {
    return anonRequest;
  } else {
    return authRequest;
  }
};
