import React from 'react';

import { Drawer } from 'antd';

import { useSelector } from 'react-redux';

import {
  PackageHeaderDrawer,
  PackageContentDrawer,
} from 'pages/product-full-view/components';

import * as productFullViewSelectors from 'pages/product-full-view/controllers/selectors';

const widthDrawer = 845;

const PackageContent = ({ visible, onClose }) => {
  const currentHierarchyShortDetails = useSelector(
    productFullViewSelectors.makeSelectCurrentHierarchyShortDetails()
  );

  return (
    <Drawer
      title={
        <PackageHeaderDrawer detail={currentHierarchyShortDetails?.data} />
      }
      placement='right'
      onClose={onClose}
      visible={visible}
      key='right'
      mask={false}
      getContainer={false}
      style={{
        height: 'calc(100vh - 306px)',
        position: 'fixed',
      }}
      width={widthDrawer}
      closable
    >
      <PackageContentDrawer
        productItems={currentHierarchyShortDetails?.data?.productItems}
      />
    </Drawer>
  );
};
export default PackageContent;
