import React from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';

import './UserProfileError.less';

const UserProfileError = (props) => {
  const { errorMessage, size } = props;
  return (
    <Result
      className={size && size === 'small' ? 'user-profile-error' : ''}
      title={errorMessage}
      status='error'
    />
  );
};

UserProfileError.propTypes = {
  errorMessage: PropTypes.string,
};

export default UserProfileError;
