import React from 'react';

import LinkAssets from '../../controls/asset/LinkAssets';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import { useSelector } from 'react-redux';
import AssetRibbonSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';

const LinkAssetsSection = ({ disabled }) => {
  const isCreating = useSelector(AssetRibbonSelectors.selectAssetCreating());
  const sectionDisabled =
    isCreating || window.location.pathname === '/asset/create' || disabled;

  return (
    <ContainerButton>
      <ItemButton>
        <LinkAssets sectionDisabled={sectionDisabled} />
      </ItemButton>
    </ContainerButton>
  );
};

export default LinkAssetsSection;
