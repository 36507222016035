import React from 'react';
import { ExclamationOutlined } from '@ant-design/icons';
import './Icon.less';

const InfoIcon = () => {
  return (
    <div className='dialog-info-icon'>
      <div className='dialog-info-icon-warn'>
        <ExclamationOutlined className='dialog-info-icon-warn__icon' />
      </div>
    </div>
  );
};

export default InfoIcon;
