import React from 'react';

import SnapshotCellWrapper from './SnapshotCellWrapper';
import TextRender from './TextRender';
import { formatCompareValue } from 'pages/qa-spec/utils';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import { isEqual } from 'lodash';

const TextRenderSnapshot = (props) => {
  const { isHighlightChange, snapshotValueRender, ...params } = props;

  const { column, rowIndex, data, context } = params;

  const { snapshotGridValues } = context || {};

  const useSnapshot = useCheckSnapshotForRetailer();

  const cellSnapshotValue = formatCompareValue(
    snapshotGridValues?.[rowIndex]?.[column.colId]
  );

  const rawCellValue = formatCompareValue(data?.[column.colId]);

  const defaultSnapshotValueRender = cellSnapshotValue;

  const defaultIsHighlightChange =
    !isEqual(`${rawCellValue}`, `${cellSnapshotValue}`) && useSnapshot;

  return (
    <SnapshotCellWrapper
      isHighlightChange={isHighlightChange ?? defaultIsHighlightChange}
      snapshotValueRender={snapshotValueRender ?? defaultSnapshotValueRender}
    >
      <TextRender {...params} />
    </SnapshotCellWrapper>
  );
};

export default TextRenderSnapshot;
