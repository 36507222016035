import React from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Button, Space } from 'antd';

import SupplierSelectEntity from './SupplierSelectEntity';
import Messages from 'i18n/messages/broker-manager';
import { dialogFunction } from 'common/components';

const SupplierProductListAction = (props) => {
  const intl = useIntl();
  const {
    brandData,
    productData,
    isProduct,
    handleAddEntityData,
    handleRemoveEntityData,
    selectedEntityLevel,
    handleResetAll,
  } = props;

  const onCLickRemove = (removeCase) => () => {
    const message = intl.formatMessage(
      Messages.removeItemConfirmMessage[removeCase]
    );

    dialogFunction({
      type: 'warn',
      content: message,
      style: { top: '30%' },
      onOk: removeItems(removeCase),
    });
  };

  const removeItems = (removeCase) => () => {
    handleRemoveEntityData(removeCase, 'product');
  };

  return (
    <Row align='middle'>
      <Col flex={0}>
        {!isProduct && (
          <SupplierSelectEntity
            brandData={brandData}
            productData={productData}
            handleAddEntityData={handleAddEntityData}
          />
        )}
      </Col>
      <Col flex={1}></Col>
      <Col flex={0} style={{ paddingRight: 20 }}>
        <Space>
          <Button
            danger
            type='primary'
            onClick={onCLickRemove('selected')}
            disabled={selectedEntityLevel.length === 0}
          >
            {intl.formatMessage(Messages.removeBtn)}
          </Button>
          <Button
            danger
            onClick={onCLickRemove('all')}
            disabled={productData.length === 0}
          >
            {intl.formatMessage(Messages.removeAllBtn)}
          </Button>
          {isProduct && (
            <Button type='primary' onClick={handleResetAll}>
              Reset
            </Button>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default SupplierProductListAction;
