import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import Messages from 'i18n/messages/home';
import {
  RIBBON_TYPES,
  pathnameProduct,
  pathnameFavProducts,
} from 'static/Constants';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import { selectRibbonType } from 'redux/ribbon/selectors';

import {
  GridColumnsControl,
  SaveGridControl,
  ManageQuery,
  SavedConfigurations,
  ClearQuery,
} from '../../index';

import './GridLayout.less';

const { SubMenu } = Menu;

const GridConfig = (props) => {
  const { disabledOption } = props;
  const [visible, setVisible] = React.useState(false);

  const [isMounted, setIsMounted] = React.useState(true);

  const ribbonTypes = useSelector(selectRibbonType());

  const isDisabledChooseColumns =
    pathnameProduct !== window.location.pathname ||
    pathnameFavProducts !== window.location.pathname;

  const displayChooseColumns =
    ribbonTypes === RIBBON_TYPES.DETAILSVIEW && !isDisabledChooseColumns;

  return (
    <Menu
      className={classnames('grid-config__menu', {
        'grid-config--menu-disabled': disabledOption,
      })}
      mode='vertical'
      style={{ padding: 3 }}
    >
      <SubMenu
        key='gridConfig'
        title={
          <ButtonSmallIcon
            icon={<TableOutlined />}
            label={Messages.gridConfig}
            className='grid-config__button'
          />
        }
        disabled={disabledOption}
      >
        {displayChooseColumns && (
          <Menu.Item
            key='chooseColumns'
            style={{ backgroundColor: 'inherit', padding: 0 }}
          >
            <GridColumnsControl />
          </Menu.Item>
        )}

        <Menu.Item
          key='savedConfig'
          style={{ backgroundColor: 'inherit', padding: 0 }}
        >
          <SavedConfigurations setVisible={setVisible} isMounted={isMounted} />
        </Menu.Item>
        <Menu.Item
          key='saveConfig'
          style={{ backgroundColor: 'inherit', padding: 0 }}
        >
          <SaveGridControl setIsMounted={setIsMounted} />
        </Menu.Item>
        <Menu.Item
          key='clearConfig'
          style={{ backgroundColor: 'inherit', padding: 0 }}
        >
          <ClearQuery />
        </Menu.Item>

        <Menu.Item
          key='manageConfig'
          style={{ backgroundColor: 'inherit', padding: 0 }}
        >
          <ManageQuery
            setIsMounted={setIsMounted}
            defaultVisible={visible}
            resetDefaultVisible={() => setVisible(false)}
          />
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

GridConfig.propTypes = {
  disabledOption: PropTypes.bool,
};

export default GridConfig;
