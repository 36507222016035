import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useSelector } from 'react-redux';
import * as selectors from 'redux/branding/selectors';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import classnames from 'classnames';
import HomeNavActionGroup from './HomeNavActionGroup';
import './HomeNavSubMenuTitle.less';
import { OFFSET_X_UNMATCHED } from 'static/Constants';

import { MAIN_PAGE_LINK } from 'static/Constants';
import { forwardTo } from 'utils/common/route';

const { Text } = Typography;

function HomeNavSubMenuTitle(props) {
  const {
    titleName,
    keyName,
    link,
    selectedKeys,
    actionDataNew,
    actionDataUnmatched,
    actionLink,
  } = props;
  const pathName = window.location.pathname;
  const branding = useSelector(selectors.getBranding());

  const getPositionUnmatchedByTotalNew = (totalNew) => {
    if (totalNew && totalNew > 0) {
      return OFFSET_X_UNMATCHED.offsetXHight;
    }

    return OFFSET_X_UNMATCHED.offsetXLow;
  };

  const calcPositionActionInNavMenu = () => {
    switch (actionLink) {
      case '/products': {
        return getPositionUnmatchedByTotalNew(branding.totalNewProducts);
      }
      case '/digital-media': {
        return getPositionUnmatchedByTotalNew(branding.totalNewDams);
      }
      default:
        break;
    }
  };

  const positionUnmatched = calcPositionActionInNavMenu();

  const handleClickMenuTitle = (link) => {
    if (MAIN_PAGE_LINK.includes(link)) {
      forwardTo(`/blank?returnUrl=${link}`);
    }
  };

  return (
    <div className='home-nav-sub-menu-title' key-owns={keyName}>
      {!MAIN_PAGE_LINK.includes(link) && link ? (
        <Link
          to={link}
          style={{ pointerEvents: link === pathName ? 'none' : 'auto' }}
          className='home-nav-menu__sub-menu-link home-nav-sub-menu-title__link'
        >
          <Text
            className={classnames({
              'home-nav-menu__sub-menu-title': true,
              'home-nav-sub-menu-title__text': true,
              'text-color-white':
                selectedKeys === keyName ||
                selectedKeys.includes(keyName + '/action'),
            })}
          >
            {titleName || 'NO NAME'}
          </Text>
        </Link>
      ) : (
        <a
          href={link}
          onClick={(e) => {
            e.preventDefault();
            handleClickMenuTitle(link);
          }}
        >
          <Text
            className={classnames({
              'home-nav-menu__sub-menu-title': true,
              'home-nav-sub-menu-title__text': true,
              'text-color-white':
                selectedKeys === keyName ||
                selectedKeys.includes(keyName + '/action'),
            })}
          >
            {titleName || 'NO NAME'}
          </Text>
        </a>
      )}
      <HomeNavActionGroup
        type='unmatched'
        absoluteRight={positionUnmatched}
        absoluteTop={3}
        actionData={actionDataUnmatched}
        link={actionLink ? actionLink : link ? link : '/'}
      />
      <HomeNavActionGroup
        type='new'
        absoluteRight={22}
        absoluteTop={3}
        actionData={actionDataNew}
        link={actionLink ? actionLink : link ? link : '/'}
      />
    </div>
  );
}

HomeNavSubMenuTitle.propTypes = {
  //? titleName - name of submenu
  titleName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  //? keyName - keyname when it is selected - required
  keyName: PropTypes.string.isRequired,
  // ? link - path name
  link: oneOfType([
    PropTypes.string.isRequired,
    PropTypes.oneOf([undefined]).isRequired,
  ]),
  // ? selectedKey - selected key of menu Navigation
  selectedKey: PropTypes.string,
  // ? actionData - action data of sub menu item only
  actionData: PropTypes.number,
};

export default React.memo(HomeNavSubMenuTitle);
