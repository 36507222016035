import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EyeOutlined, FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import * as actions from 'pages/maintenance-form/controllers/actions';
import * as selectors from 'pages/maintenance-form/controllers/selectors';

const EditPreviewBtn = (props) => {
  const dispatch = useDispatch();
  const modeView = useSelector(selectors.makeSelectModeViewForm());

  const onClickModeView = () => {
    dispatch(actions.updateModeView(!modeView));
  };
  return (
    <RibbonButton
      icon={modeView ? <FormOutlined /> : <EyeOutlined />}
      label={modeView ? 'Edit' : 'Preview'}
      onClick={onClickModeView}
    />
  );
};

export default EditPreviewBtn;
