export const DISABLE_CHARGE_WARNING = '/api/payment/disable-charge-warning';

export const GET_USER_CREDIT_CARD_EULA = 'api/payment/get-user-card-eula';

export const ACCEPT_CARD_EULA = 'api/payment/accept-card-eula';
export const REJECT_CARD_EULA = 'api/payment/reject-card-eula';

export const GET_LINK_IFRAME_CARD = '/api/payment/add-card';

export const IFRAME_COMMUNICATOR = '/api/payment/communicator';

export const SYNC_SAVED_MEMBER_CARD = '/api/payment/update-member-saved-cards';

export const GET_SAVED_MEMBER_CARD = '/api/payment/get-member-saved-cards';

export const REMOVE_SAVED_CARD = '/api/payment/remove-card';

export const GET_LINK_IFRAME_EDITED_CARD = '/api/payment/edit-card';

export const GET_MEMBER_BILLING_PROPERTIES =
  '/api/payment/get-member-billing-properties';

export const SAVE_MEMBER_BILLING_PROPERTIES =
  '/api/payment/save-member-billing-properties';
