import React from 'react';

import { List, Row, Col, Checkbox, Typography } from 'antd';
import classnames from 'classnames';

const { Text } = Typography;

const HierarchyProductList = (props) => {
  const {
    productList,
    selectedProduct,
    setSelectedProduct,
    getProduct,
    totalProduct,
    pageIndex,
    setPageIndex,
  } = props;

  const onClickProduct = (product) => () => {
    setSelectedProduct(product);
  };

  const onChangePagination = (page) => {
    setPageIndex(page);
    getProduct({ page });
  };

  const checkSelectedProduct = (product) => {
    return selectedProduct?.id === product.id;
  };

  const pagination = {
    pageSize: 5,
    total: totalProduct,
    current: pageIndex,
    onChange: onChangePagination,
  };

  return (
    <List className='hierarchy-product__list' bordered pagination={pagination}>
      {productList.map((product, index) => {
        const isSelected = checkSelectedProduct(product);
        return (
          <List.Item
            className={classnames({
              'hierarchy-product__item': true,
              'hierarchy-product__item--selected': isSelected,
            })}
            key={index}
          >
            <Row
              className='hierarchy-product__item-container'
              gutter={[8, 0]}
              align='middle'
              onClick={onClickProduct(product)}
            >
              <Col className='hierarchy-product__col-checkbox'>
                <Checkbox checked={isSelected} />
              </Col>
              <Col className='hierarchy-product__col-thumb'>
                <div
                  className='hierarchy-product__thumb'
                  style={{
                    backgroundImage: `url(${product.thumbnail})`,
                  }}
                ></div>
              </Col>
              <Col className='hierarchy-product__col-info'>
                <Row className='hierarchy-product__title'>
                  <Col className='hierarchy-product__name'>
                    <Text strong>{product.productName}</Text>
                  </Col>
                </Row>
                <Row className='hierarchy-product__meta' gutter={[8, 0]}>
                  <Col className='hierarchy-product__package'>
                    <Text className='hierarchy-product__meta-label'>
                      Package Level:
                    </Text>
                    <Text>{product.packageLevel}</Text>
                  </Col>
                  <Col className='hierarchy-product__type'>
                    <Text className='hierarchy-product__meta-label'>
                      UPC12:
                    </Text>
                    <Text>{product.upc12}</Text>
                  </Col>
                  <Col className='hierarchy-product__gtin'>
                    <Text className='hierarchy-product__meta-label'>GTIN:</Text>
                    <Text>{product.gtin}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </List.Item>
        );
      })}
    </List>
  );
};

export default HierarchyProductList;
