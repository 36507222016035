import { Typography } from 'antd';
import { LIST_ALLERGEN_PROPERTY_NAME_CHECKABLE_DISABLED_FIELDS } from '../constants';
import { sleep } from 'utils/delay';
import sortByKey from 'utils/sortByKey';

const modulesNameHeader = ['Header', 'ECommerce', 'Certification'];

const orderByDisplayName = (properties) => {
  let result = [];
  if (properties?.length > 0) {
    const orderedKey = properties
      .map((item) => item.propertyDisplayName)
      .sort();

    orderedKey.forEach((key) => {
      const property = properties.find((p) => p.propertyDisplayName === key);
      result.push(property);
    });
  }

  return result ?? properties;
};

const getOrderedByDisplayNameProperties = (productSchema) => {
  let result = [];

  if (productSchema?.length > 0) {
    productSchema.forEach((schema) => {
      if (schema.moduleName === 'ECommerce') {
        result.push({
          ...schema,
          moduleProperties: orderByDisplayName(schema.moduleProperties),
        });
      } else {
        result.push({
          ...schema,
        });
      }
    });
  }
  return result ?? productSchema;
};

export const lowercaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const uppercaseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getDataByPropertyName = (data, propertyName) => {
  return data[propertyName];
};

export const getDataByFieldFullPath = (data, fieldFullPath) => {
  return data[fieldFullPath];
};

export const filterModuleProductSchema = (productSchema) => {
  const baseProductSchema = [];
  const advancedProductSchema = [];

  if (productSchema) {
    productSchema.forEach((schema) => {
      if (modulesNameHeader.includes(schema.moduleName)) {
        baseProductSchema.push(schema);
      } else {
        advancedProductSchema.push(schema);
      }
    });
  }

  return {
    baseProductSchema: getOrderedByDisplayNameProperties(baseProductSchema),
    advancedProductSchema,
  };
};

export const filterModuleBrickCode = (rightSchema, modulesBrickCode) => {
  const lowerCaseBrickCode = modulesBrickCode.map((item) => item.toLowerCase());

  return rightSchema.filter((schema) =>
    lowerCaseBrickCode.includes(schema.moduleName.toLowerCase())
  );
};

const getNodeTreeData = (property, isNestedProperty) => {
  return {
    title: isNestedProperty ? (
      <Typography.Title
        level={5}
        style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.65)', marginBottom: 0 }}
      >
        {property.propertyDisplayName}
      </Typography.Title>
    ) : (
      <Typography.Text strong>{property.propertyDisplayName}</Typography.Text>
    ),
    isLeaf: isNestedProperty ? false : true,
    data: property,
    value: property.fieldFullPath,
  };
};

export const generateModuleProductToTree = (moduleSchema) => {
  let tree = [];

  moduleSchema.forEach((moduleProduct) => {
    const { moduleDisplayName, moduleProperties } = moduleProduct;
    const sortedProperties = sortByKey(moduleProperties, 'propertyDisplayName');

    if (sortedProperties.length > 0) {
      const moduleNodes = generatePropertiesToNodesTree(sortedProperties);
      const moduleTree = {
        title: (
          <Typography.Title
            level={5}
            style={{
              fontSize: 14,
              color: 'rgba(0, 0, 0, 0.65)',
              marginBottom: 0,
            }}
          >
            {moduleDisplayName}
          </Typography.Title>
        ),
        isLeaf: false,
        selectable: false,
        children: moduleNodes,
        value: moduleProduct.moduleName,
        data: {
          propertyDisplayName: moduleDisplayName,
        },
      };
      tree.push(moduleTree);
    }
  });

  return tree;
};

export const generatePropertiesToNodesTree = (properties) => {
  let tree = [];

  properties.forEach((property) => {
    const isNestedProperty = property?.childProperties?.length > 0;
    const node = getNodeTreeData(property, isNestedProperty);

    if (isNestedProperty) {
      tree.push({
        ...node,
        children: generatePropertiesToNodesTree(property.childProperties),
      });
    } else {
      tree.push(node);
    }
  });

  return tree;
};

export const formatSpecialEnum = (enumVal) => {
  return `${enumVal?.enumDescription} - (${enumVal?.enumCode?.toUpperCase()})`;
};

export const mappingSpecialEnumToDisplay = (values, enums = []) => {
  let result = [];

  values.forEach((value) => {
    const foundEnum = enums.find((enumVal) => enumVal?.enumCode === value);
    const displayingResult = formatSpecialEnum(foundEnum);
    result.push(displayingResult);
  });

  return result;
};

export const scrollToFoundModuleView = async (elementByClassName) => {
  if (elementByClassName?.[0]) {
    await sleep(100);
    elementByClassName?.[0].scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }
};

export const checkDisabledFormField = ({
  propertyName = '',
  levelOfCode = '',
}) => {
  if (
    LIST_ALLERGEN_PROPERTY_NAME_CHECKABLE_DISABLED_FIELDS.includes(propertyName)
  ) {
    if (
      propertyName?.toLowerCase() === 'containmentsource' &&
      levelOfCode?.toLowerCase() !== 'may contain'
    )
      return true;

    return false;
  }

  return false;
};

export const checkRequiredFormField = (levelOfContainment) => {
  return {
    AllergenTypeCode: true,
    LevelOfContainmentCode: true,
    ContainmentSource: levelOfContainment?.toLowerCase() === 'may contain',
  };
};

const SPECIAL_FIELD = {
  'packagingmarking.packagingmarking.hasbatchnumber':
    'packagingmarking.packagingmarking.hasBatchNumber',
  'packagingmarking.packagingmarking.isnetcontentdeclarationindicated':
    'packagingmarking.packagingmarking.isNetContentDeclarationIndicated',
  'packagingmarking.packagingmarking.ispackagingmarkedreturnable':
    'packagingmarking.packagingmarking.isPackagingMarkedReturnable',
  'packagingmarking.packagingmarking.ispriceonpack':
    'packagingmarking.packagingmarking.isPriceOnPack',
  'packagingmarking.packagingmarking.istradeitemmarkedasrecyclable':
    'packagingmarking.packagingmarking.isTradeItemMarkedAsRecyclable',
};

export const checkSpecialField = (fieldFullPath) => {
  return Boolean(SPECIAL_FIELD?.[fieldFullPath?.toLowerCase()]);
};
