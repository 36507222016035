import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Typography, Popover, Switch } from 'antd';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import * as userActions from 'redux/user/actions';
import * as _ from 'lodash';

//* COMPONENTS
import {
  Form,
  CustomNotification,
  StyledModal,
  FlagCountrySelectionFieldInput,
} from 'common/components';

//* SERVICES
import * as userServices from 'services/user';
import Messages from 'i18n/messages/message-my-company';

//* UTILS
import { searchCountryCode } from 'utils/country';
import CountryCode from 'static/CountryCode';
import { supportPhoneFormats, REGEX_CONTACT_PHONE } from 'static/Constants';
import { GRID_ID } from 'common/components/grid-view/utils';

//* REDUX
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import './OverviewLocationsForm.less';

const { Text } = Typography;
const { TextArea } = Input;

const OverviewLocationsForm = (props) => {
  const { isOpen, isAddNew, setIsOpen, itemData, intl, gridApi, userId } =
    props;

  const activeGrid = useSelector(selectorsGridView.makeSelecActiveGrid());

  const [formInstance] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [locationDetail, setLocationDetail] = useState({});

  const dispatch = useDispatch();

  const refCountry = useRef(null);

  const handleSubmitForm = () => {
    formInstance
      .validateFields()
      .then(async (value) => {
        const isPrimaryAddressValue = value?.isPrimaryAddress;
        setIsLoading(true);
        let response;
        if (isAddNew) {
          response = await userServices.addUserProfileLocation({
            userId,
            ...value,
          });
        } else {
          // for edit
          response = await userServices.updateUserProfileLocation({
            id: itemData?.id,
            userId,
            ...value,
          });
        }
        setIsLoading(false);
        if (response?.isSuccess) {
          CustomNotification.success(
            isAddNew
              ? intl.formatMessage(Messages.msgLocationCreateSuccess)
              : intl.formatMessage(Messages.msgLocationUpdateSuccess)
          );
          gridApi?.rivirPurgeServerSideCache();
          if (isPrimaryAddressValue) {
            dispatch(userActions.getUserProfileHeader(userId));
          }

          // if (value?.locationType === 'Headquarters') {
          //   dispatch(companyActions.getMemberProfileHeader(memberId));
          // }
          setIsOpen(false);
        } else {
          CustomNotification.error(response?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.errorFields) {
          formInstance.scrollToField(error?.errorFields[0].name[0]);
          CustomNotification.error(
            intl.formatMessage(Messages.msgInvalidFields)
          );
        } else {
          CustomNotification.error(intl.formatMessage(Messages.msgExcepEdit));
        }
      });
  };

  const setEditFormValue = useCallback(
    async (id) => {
      if (!isAddNew) {
        formInstance.resetFields();
        const response = await userServices.getUserProfileLocationDetail({
          id,
          userId,
        });
        if (response) {
          formInstance.setFieldsValue(response.data);
          refCountry &&
            refCountry.current &&
            refCountry.current.updateSelected(
              searchCountryCode(response.data?.country)
            );
          setLocationDetail(response.data);
        }
      }
    },
    [itemData, isAddNew, formInstance]
  );

  useEffect(() => {
    if (itemData?.id && activeGrid === GRID_ID.USER_PROFILE_LOCATION) {
      setEditFormValue(itemData?.id);
    }
  }, [itemData, setEditFormValue]);

  useEffect(() => {
    if (isAddNew) {
      formInstance.resetFields();
      formInstance.setFieldsValue({
        ...formInstance.getFieldsValue(),
        country: CountryCode['US'],
      });
    }
  }, [isAddNew, formInstance]);

  return (
    <React.Fragment>
      {(isOpen || isAddNew) && (
        <StyledModal
          className={classnames('overview-locations-form__wrapper', {
            'overview-locations-form__wrapper--loading': isLoading,
          })}
          title={
            isAddNew ? (
              <FormattedMessage {...Messages.formLocationTitleNew} />
            ) : (
              <FormattedMessage {...Messages.formLocationTitle} />
            )
          }
          visible={!isAddNew ? isOpen : isAddNew}
          onOk={handleSubmitForm}
          onCancel={() => {
            setIsOpen(false);
          }}
          okText={
            isLoading ? (
              <React.Fragment>
                <LoadingOutlined />
                <Text style={{ color: 'white' }}>
                  <FormattedMessage {...Messages.btnSending} />
                </Text>
              </React.Fragment>
            ) : (
              <FormattedMessage {...Messages.btnSubmit} />
            )
          }
          cancelText={<FormattedMessage {...Messages.btnCancel} />}
          maskClosable={false}
        >
          <Form
            form={formInstance}
            className='overview-locations-form'
            initialValues={{
              status: 'Active',
            }}
            layout='vertical'
            scrollToFirstError={true}
            id='overview-locations-edit-form'
          >
            <Form.Item
              name='isPrimaryAddress'
              label={<FormattedMessage {...Messages.labelPrimaryLocation} />}
              rules={[]}
              valuePropName='checked'
            >
              <Switch className='overview-locations-form__switch' />
            </Form.Item>
            <Form.Item
              name='mainAddress'
              label={<FormattedMessage {...Messages.labelAddress} />}
              rules={[
                {
                  required: true,
                  message: 'Address is required',
                },
              ]}
            >
              <Input
                name='address'
                placeholder={intl.formatMessage(Messages.placeHolderAddress)}
              />
            </Form.Item>
            <Form.Item
              name='city'
              label={<FormattedMessage {...Messages.labelCity} />}
              rules={[
                {
                  required: true,
                  message: 'City is required',
                },
              ]}
            >
              <Input
                name='city'
                placeholder={intl.formatMessage(Messages.placeHolderCity)}
              />
            </Form.Item>
            <Form.Item
              name='state'
              label={<FormattedMessage {...Messages.labelState} />}
            >
              <Input
                name='state'
                placeholder={intl.formatMessage(Messages.placeHolderState)}
              />
            </Form.Item>
            <Form.Item
              name='country'
              label={<FormattedMessage {...Messages.labelCountry} />}
            >
              <FlagCountrySelectionFieldInput
                searchable={true}
                className={'overview-locations-form__country-input'}
                placeholder={''}
                onSelect={(value) => {
                  formInstance.setFieldsValue({
                    ...formInstance.getFieldsValue(),
                    country: CountryCode[value],
                  });
                }}
                ref={refCountry}
                defaultCountry='US'
              />
            </Form.Item>
            <Form.Item
              name='zipcode'
              label='Zipcode'
              rules={[
                () => ({
                  validator(rule, value) {
                    const regex = /^\d{5}[-\s]?(?:\d{4})?$/gm;
                    if (regex.exec(value) || !value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Invalid Zipcode');
                    }
                  },
                }),
              ]}
            >
              <Input
                name='zip'
                placeholder={intl.formatMessage(Messages.placeHolderZipCode)}
              />
            </Form.Item>
            <Form.Item
              name='locationName'
              label={<FormattedMessage {...Messages.labelLocationName} />}
              rules={[
                {
                  required: true,
                  message: 'Location Name is required',
                },
              ]}
            >
              <Input
                name='locationName'
                placeholder={intl.formatMessage(
                  Messages.placeHolderLocationName
                )}
              />
            </Form.Item>
            <Form.Item
              name='locationDescription'
              label={
                <FormattedMessage {...Messages.labelLocationDescription} />
              }
            >
              <TextArea
                showCount={false}
                maxLength={300}
                placeholder={intl.formatMessage(
                  Messages.placeHolderLocationDes
                )}
                autoSize={{ minRows: 5, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item
              name='phoneNumber'
              label={
                <div>
                  <FormattedMessage {...Messages.labelPhone} />
                </div>
              }
            >
              <Input
                name='phoneNumber'
                placeholder={intl.formatMessage(Messages.placeHolderPhone)}
              />
            </Form.Item>
            <Form.Item
              name='mobileNumber'
              label={
                <div>
                  <FormattedMessage {...Messages.labelMobile} />
                </div>
              }
            >
              <Input
                name='mobileNumber'
                placeholder={intl.formatMessage(Messages.placeHolderMobile)}
              />
            </Form.Item>
            <Form.Item
              name='faxNumber'
              label={<FormattedMessage {...Messages.labelFax} />}
            >
              <Input
                name='faxNumber'
                placeholder={intl.formatMessage(Messages.placeHolderFax)}
              />
            </Form.Item>
            <Form.Item
              name='email'
              label={<FormattedMessage {...Messages.labelEmail} />}
              rules={[{ type: 'email', message: 'Invalid Email' }]}
            >
              <Input
                name='email'
                placeholder={intl.formatMessage(Messages.placeHolderEmail)}
              />
            </Form.Item>
          </Form>
        </StyledModal>
      )}
    </React.Fragment>
  );
};

OverviewLocationsForm.propTypes = {
  isOpen: PropTypes.bool,
  isAddNew: PropTypes.bool,
  setIsOpen: PropTypes.func,
  itemData: PropTypes.object,
};

export default injectIntl(OverviewLocationsForm);
