import React from 'react';

import { Row, Button, Space } from 'antd';

export default function ApplyFilter(event) {
  const handleApply = () => {
    const key = event?.colDef?.field;
    // Get a reference to the 'name' filter instance
    const filter = event?.api?.getFilterInstance(key);

    // Apply the model to ensure any changes in the UI or via API methods are recognised
    filter?.applyModel();

    // Tell grid to run filter operation again
    event?.api?.onFilterChanged();
    event?.api?.hidePopupMenu();
  };
  const handleReset = () => {
    const key = event?.colDef?.field;
    // Get a reference to the 'name' filter instance
    const filter = event?.api?.getFilterInstance(key);
    filter?.setModel(undefined);
    // Apply the model to ensure any changes in the UI or via API methods are recognised
    filter?.applyModel();

    // Tell grid to run filter operation again
    event?.api?.onFilterChanged();
    event?.api?.hidePopupMenu();
  };
  return (
    <Row
      style={{
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: 8,
      }}
    >
      <Space>
        <Button type='primary' size='large' onClick={handleApply}>
          Apply
        </Button>
        <Button size='large' onClick={handleReset}>
          Reset
        </Button>
      </Space>
    </Row>
  );
}
