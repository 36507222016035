export const GET_MAPPING_GRID_COLUMNS = 'GET_MAPPING_GRID_COLUMNS';
export const GET_MAPPING_GRID_COLUMNS_SUCCESS =
  'GET_MAPPING_GRID_COLUMNS_SUCCESS';
export const GET_MAPPING_GRID_COLUMNS_FAILURE =
  'GET_MAPPING_GRID_COLUMNS_FAILURE';

export const GET_MAPPING_CONFIG_GRID_COLUMNS =
  'GET_MAPPING_CONFIG_GRID_COLUMNS';
export const GET_MAPPING_CONFIG_GRID_COLUMNS_SUCCESS =
  'GET_MAPPING_CONFIG_GRID_COLUMNS_SUCCESS';
export const GET_MAPPING_CONFIG_GRID_COLUMNS_FAILURE =
  'GET_MAPPING_CONFIG_GRID_COLUMNS_FAILURE';

export const GET_MAPPING_SOURCE_PROPERTIES = 'GET_MAPPING_SOURCE_PROPERTIES';
export const GET_MAPPING_SOURCE_PROPERTIES_SUCCESS =
  'GET_MAPPING_SOURCE_PROPERTIES_SUCCESS';
export const GET_MAPPING_SOURCE_PROPERTIES_ERROR =
  'GET_MAPPING_SOURCE_PROPERTIES_ERROR';

export const GET_MAPPING_GRID = 'GET_MAPPING_GRID';
export const GET_MAPPING_GRID_SUCCESS = 'GET_MAPPING_GRID_SUCCESS';
export const GET_MAPPING_GRID_ERROR = 'GET_MAPPING_GRID_ERROR';

export const SET_CURRENT_MAPPING_ID = 'SET_CURRENT_MAPPING_ID';

export const GET_MAPPING_CONFIG_PROPERTIES = 'GET_MAPPING_CONFIG_PROPERTIES';
export const GET_MAPPING_CONFIG_PROPERTIES_SUCCESS =
  'GET_MAPPING_CONFIG_PROPERTIES_SUCCESS';
export const GET_MAPPING_CONFIG_PROPERTIES_FAILURE =
  'GET_MAPPING_CONFIG_PROPERTIES_FAILURE';

// * Mapping Detail
export const GET_MAPPING_CONFIG = 'GET_MAPPING_CONFIG';
export const GET_MAPPING_CONFIG_SUCCESS = 'GET_MAPPING_CONFIG_SUCCESS';
export const GET_MAPPING_CONFIG_FAILURE = 'GET_MAPPING_CONFIG_FAILURE';

export const UPDATE_MAPPING_FORMAT_TYPE_FILTER =
  'UPDATE_MAPPING_FORMAT_TYPE_FILTER';
