import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {  injectIntl } from 'react-intl';
import './style.less';
import AssetType from './AssetType';
import AssetEdit from './AssetEdit';

const AssetTypeEdit = (props) => {
  const { onSubmit, typeData, canEdit } = props;
  const [isEdit, setIsEdit] = useState(false);

  return (
    <>
      <div className='asset-type-wrapper'>
        {isEdit ? (
          <AssetEdit
            onSubmit={onSubmit}
            onCancel={() => setIsEdit(false)}
            typeData={typeData}
          />
        ) : (
          <>
            {canEdit && (
              <Tooltip title='Edit asset type' placement='topRight'>
                <Button
                  className='asset-type-wrapper--edit-button'
                  icon={<EditOutlined />}
                  type='link'
                  onClick={() => setIsEdit(true)}
                />
              </Tooltip>
            )}

            <AssetType {...typeData} />
          </>
        )}
      </div>
    </>
  );
};

export default injectIntl(AssetTypeEdit);
