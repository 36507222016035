import ADASEALOFACCEPTANCE_CLAIMANDCERTIFIER from 'assets/Certifiers/ADASealofAcceptance/ClaimandCertifier.jpg';
import AUTHENTICMADEPRODUCEDBYAMERICANINDIANSCERTIFIER_CMBAI from 'assets/Certifiers/AuthenticMadeProducedByAmericanIndiansCertifier/CMBAI.jpg';
import BEEBETTERCERTIFIER_BEEBETTER from 'assets/Certifiers/BeeBetterCertifier/BeeBetter.jpg';
import BEEBETTERCERTIFIER_BEEFRIENDLY from 'assets/Certifiers/BeeBetterCertifier/BeeFriendly.jpg';
import BEEBETTERCERTIFIER_BEEFRIENDLY1 from 'assets/Certifiers/BeeBetterCertifier/BeeFriendly1.jpg';
import BIOBASEDCERTIFIER_USDABIOBASED from 'assets/Certifiers/BioBasedCertifier/USDABioBased.jpg';
import BIOBASEDCERTIFIER_WALKINGTHEWALK from 'assets/Certifiers/BioBasedCertifier/WalkingTheWalk.jpg';
import BIODYNAMICCERTIFIER_DEMETER from 'assets/Certifiers/BioDynamicCertifier/Demeter.jpg';
import BIOENGINEEREDTYPE_BIOENGINEERED from 'assets/Certifiers/BioengineeredType/Bioengineered.jpg';
import BIOENGINEEREDTYPE_DERIVEDFROM from 'assets/Certifiers/BioengineeredType/DerivedFrom.jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_DISABILITYOWNEDBUSINESSENTERPRISEDOBE from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Disability-Owned Business Enterprise (DOBE).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_HOMEGROWNBYHEROES from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Homegrown by Heroes.jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_MADEPRODUCEDBYAMERICANINDIANS from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Made Produced by American Indians.jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_MINORITYBUSINESSENTERPRISEMBE from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Minority Business Enterprise (MBE).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_SERVICEDISABLEDVETERANOWNEDSMALLBUSINESSSDVDOBE from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Service-Disabled Veteran-Owned Small Business (SDV-DOBE).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANOWNEDBUSINESSVOB from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Veteran Owned Business (VOB).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANOWNEDSMALLBUSINESSVOSB from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Veteran Owned Small Business (VOSB).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANDISABILITYOWNEDBUSINESSENTERPRISEVDOBE from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Veteran-Disability Owned Business Enterprise (V-DOBE).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENBUSINESSENTERPRISEWBE from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Women Business Enterprise (WBE).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENBUSINESSENTERPRISENATIONALCOUNCILWBENC from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Women Business Enterprise National Council (WBENC).jpg';
import BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENOWNEDSMALLBUSINESSWOSB from 'assets/Certifiers/BusinessOwnerManufacturerCertifier/Women Owned Small Business (WOSB).jpg';
import CARBONFOOTPRINTCERTIFIER_CARBONFREE from 'assets/Certifiers/CarbonFootprintCertifier/CarbonFree.jpg';
import CARBONFOOTPRINTCERTIFIER_CARBONREDUCTION from 'assets/Certifiers/CarbonFootprintCertifier/Carbonreduction.jpg';
import CARBONFOOTPRINTCERTIFIER_CARBONTRUST from 'assets/Certifiers/CarbonFootprintCertifier/CarbonTrust.jpg';
import CBDHEMPCERTIFIER_CERTIFIEDKIND from 'assets/Certifiers/CBDHempCertifier/CertifiedKind.jpg';
import CBDHEMPCERTIFIER_USHEMPAUTHORITY from 'assets/Certifiers/CBDHempCertifier/USHempAuthority.jpg';
import CERTIFIEDBCORPORATION_CERTIFIEDBCORPORATION from 'assets/Certifiers/CertifiedBCorporation/CertifiedBCorporation.jpg';
import CERTIFIEDBCORPORATION_THUMBS from 'assets/Certifiers/CertifiedBCorporation/Thumbs.db';
import CRADLETOCRADLECERTIFIER_BASIC from 'assets/Certifiers/CradleToCradleCertifier/basic.jpg';
import CRADLETOCRADLECERTIFIER_BRONZE from 'assets/Certifiers/CradleToCradleCertifier/bronze.jpg';
import CRADLETOCRADLECERTIFIER_GOLD from 'assets/Certifiers/CradleToCradleCertifier/gold.jpg';
import CRADLETOCRADLECERTIFIER_PLATINUM from 'assets/Certifiers/CradleToCradleCertifier/Platinum.jpg';
import CRADLETOCRADLECERTIFIER_SILVER from 'assets/Certifiers/CradleToCradleCertifier/silver.jpg';
import CRUELTYFREECERTIFIER_ANIMALWELFAREAPPROVED from 'assets/Certifiers/CrueltyFreeCertifier/AnimalWelfareApproved.jpg';
import CRUELTYFREECERTIFIER_CERTIFIEDHUMANERAISEDANDHANDLED from 'assets/Certifiers/CrueltyFreeCertifier/CertifiedHumaneRaisedAndHandled.jpg';
import CRUELTYFREECERTIFIER_CHOOSECRUELTYFREE from 'assets/Certifiers/CrueltyFreeCertifier/ChooseCrueltyFree.jpg';
import CRUELTYFREECERTIFIER_CRUELTYFREE from 'assets/Certifiers/CrueltyFreeCertifier/CrueltyFree.jpg';
import CRUELTYFREECERTIFIER_CRUELTYFREEANDVEGANCRUELTY from 'assets/Certifiers/CrueltyFreeCertifier/CrueltyFreeAndVeganCruelty.jpg';
import CRUELTYFREECERTIFIER_CRUELTYFREECERTIFIER from 'assets/Certifiers/CrueltyFreeCertifier/CrueltyFreeCertifier.jpg';
import CRUELTYFREECERTIFIER_PETA from 'assets/Certifiers/CrueltyFreeCertifier/peta.jpg';
import CRUELTYFREECERTIFIER_SPCACERTIFIED from 'assets/Certifiers/CrueltyFreeCertifier/SPCA Certified.jpg';
import DIABETICFRIENDLYCERTIFIER_GLYCEMICRESEARCHINSTITUTEDIABETICFRIENDLY from 'assets/Certifiers/DiabeticFriendlyCertifier/GlycemicResearchInstituteDiabeticFriendly.jpg';
import ECOFISHERYCERTIFICATION_ASCCERTIFIED from 'assets/Certifiers/EcoFisheryCertification/ASC Certified.jpg';
import ECOFISHERYCERTIFICATION_BESTAQUACULTUREPRACTICESCERTIFIED from 'assets/Certifiers/EcoFisheryCertification/Best Aquaculture Practices Certified.jpg';
import ECOFISHERYCERTIFICATION_FRIENDOFTHESEA from 'assets/Certifiers/EcoFisheryCertification/Friend of the Sea.jpg';
import ECOFISHERYCERTIFICATION_MARINESTEWARDSHIPCOUNCIL from 'assets/Certifiers/EcoFisheryCertification/marine stewardship council.jpg';
import ECOFISHERYCERTIFICATION_QUALITYECOAPPROVEDORIGINIRELANDCERTIFICATION from 'assets/Certifiers/EcoFisheryCertification/Quality Eco Approved Origin Ireland Certification.jpg';
import FAIRTRADECERTIFIER_FAIRFORLIFE from 'assets/Certifiers/FairTradeCertifier/FairForLife.jpg';
import FAIRTRADECERTIFIER_FAIRTRADECERTIFIED from 'assets/Certifiers/FairTradeCertifier/FairTradeCertified.jpg';
import FAIRTRADECERTIFIER_FAIRTRADEINGREDIENTS from 'assets/Certifiers/FairTradeCertifier/FairTradeIngredients.jpg';
import FAIRTRADECERTIFIER_FAIRTRADEINTERNATIONAL from 'assets/Certifiers/FairTradeCertifier/FairTradeInternational.jpg';
import FAIRTRADECERTIFIER_FFL from 'assets/Certifiers/FairTradeCertifier/FFL.jpg';
import FAIRTRADECERTIFIER_NATURELAND from 'assets/Certifiers/FairTradeCertifier/NATURELAND.jpg';
import FOODGRADEPLASTICSCERTIFIER_FOODGRADEPLASTIC from 'assets/Certifiers/FoodGradePlasticsCertifier/FoodGradePlastic.jpg';
import FORLIFECERTIFIER_FORLIFE from 'assets/Certifiers/ForLifeCertifier/ForLife.jpg';
import GLUTENFREECERTIFIER_CELIACSUPPORTASSOCIATION from 'assets/Certifiers/GlutenFreeCertifier/CeliacSupportAssociation.jpg';
import GLUTENFREECERTIFIER_GLUTENFREECERTIFICATIONORGANIZATION from 'assets/Certifiers/GlutenFreeCertifier/Gluten Free Certification Organization.jpg';
import GLUTENFREECERTIFIER_GLUTENFREECELIACFRIENDLYCOELIACFRIENDLY from 'assets/Certifiers/GlutenFreeCertifier/GlutenFreeCeliacFriendlyCoeliacFriendly.jpg';
import GLUTENFREECERTIFIER_GLUTENGUARD from 'assets/Certifiers/GlutenFreeCertifier/GlutenGuard.jpg';
import GLUTENFREECERTIFIER_ICSGLUTENFREE from 'assets/Certifiers/GlutenFreeCertifier/ICSGlutenFree.jpg';
import GLUTENFREECERTIFIER_NATIONALFOUNDATIONFORCELIACAWARENESS from 'assets/Certifiers/GlutenFreeCertifier/NationalFoundationForCeliacAwareness.jpg';
import GLUTENFREECERTIFIER_NSFGLUTENFREE from 'assets/Certifiers/GlutenFreeCertifier/NSFGlutenFree.jpg';
import GLYCEMICINDEXCERTIFIER_GILABSTESTED from 'assets/Certifiers/GlycemicIndexCertifier/GILabsTested.jpg';
import GLYPHOSATEINTRANSITION_GLYPHOSATEINTRANSITION from 'assets/Certifiers/GlyphosateInTransition/GlyphosateInTransition.jpg';
import GLYPHOSATERESIDUEFREE_GLYPHOSATERESIDUEFREE from 'assets/Certifiers/GlyphosateResidueFree/GlyphosateResidueFree.jpg';
import GMPCERTIFIER_NPAGMP from 'assets/Certifiers/GMPCertifier/NPAGMP.jpg';
import GRASSFEDCERTIFIER_AMERICANGRASSFED from 'assets/Certifiers/GrassFedCertifier/AmericanGrassfed.jpg';
import GRASSFEDCERTIFIER_PCO from 'assets/Certifiers/GrassFedCertifier/PCO.jpg';
import HALALCERTIFIER_AHF from 'assets/Certifiers/HalalCertifier/AHF.jpg';
import HALALCERTIFIER_HFCI from 'assets/Certifiers/HalalCertifier/HFCI.jpg';
import HALALCERTIFIER_HFCU from 'assets/Certifiers/HalalCertifier/HFCU.jpg';
import HALALCERTIFIER_HTM from 'assets/Certifiers/HalalCertifier/HTM.jpg';
import HALALCERTIFIER_HTO from 'assets/Certifiers/HalalCertifier/HTO.jpg';
import HALALCERTIFIER_HTOH from 'assets/Certifiers/HalalCertifier/HTOH.jpg';
import HALALCERTIFIER_HTP from 'assets/Certifiers/HalalCertifier/HTP.jpg';
import HALALCERTIFIER_HTZ from 'assets/Certifiers/HalalCertifier/HTZ.jpg';
import HALALCERTIFIER_IFANCA from 'assets/Certifiers/HalalCertifier/IFANCA.jpg';
import HALALCERTIFIER_ISA from 'assets/Certifiers/HalalCertifier/ISA.jpg';
import HALALCERTIFIER_ISNA from 'assets/Certifiers/HalalCertifier/ISNA.jpg';
import HALALCERTIFIER_ISWA from 'assets/Certifiers/HalalCertifier/ISWA.jpg';
import HALALCERTIFIER_MCGH from 'assets/Certifiers/HalalCertifier/MCGH.jpg';
import HEARTHEALTHYCERTIFIER_AMERICANHEARTASSOCIATIONHEARTHEALTHY from 'assets/Certifiers/HeartHealthyCertifier/AmericanHeartAssociationHeartHealthy.jpg';
import HIGHPRESSURECERTIFIEDCERTIFIER_HIGHPRESSURECERTIFIED from 'assets/Certifiers/HighPressureCertifiedCertifier/HighPressureCertified.jpg';
import INTERNATIONALPACKAGEDICEASSOCIATION_INTERNATIONALPACKAGEDICEASSOCIATION from 'assets/Certifiers/InternationalPackagedIceAssociation/InternationalPackagedIceAssociation.jpg';
import ITALCERTIFIEDSEALCERTIFIER_ITAL from 'assets/Certifiers/ItalCertifiedSealCertifier/Ital.jpg';
import ITALCONSCIOUSSEALCERTIFIER_ITALCONSCIOUSSEAL from 'assets/Certifiers/ItalConsciousSealCertifier/ItalConsciousSeal.jpg';
import ITALSACRAMENTSEALCERTIFIER_ITALSACRAMENTSEAL from 'assets/Certifiers/ItalSacramentSealCertifier/ItalSacramentSeal.jpg';
import KETOCERTIFIER_KETOFOUNDATIONKETOCERTIFIED from 'assets/Certifiers/KetoCertifier/Keto Foundation Keto Certified.jpg';
import KETOCERTIFIER_KETOGENICCOM from 'assets/Certifiers/KetoCertifier/Ketogenic.com.jpg';
import KOSHERCERTIFICATION_ATLANTAKOSHERCOMMISSION from 'assets/Certifiers/KosherCertification/Atlanta Kosher Commission.jpg';
import KOSHERCERTIFICATION_BEISDINTZEDEKOFTHEEIDAHHACHAREIDISOFJERUSALEM from 'assets/Certifiers/KosherCertification/Beis Din Tzedek of the Eidah Hachareidis of Jerusalem.jpg';
import KOSHERCERTIFICATION_BLUERIBBONKOSHER from 'assets/Certifiers/KosherCertification/Blue Ribbon Kosher.jpg';
import KOSHERCERTIFICATION_BVKVAADHAKASHRUTOFBUFFALO from 'assets/Certifiers/KosherCertification/BVK Vaad HaKashrut of Buffalo.jpg';
import KOSHERCERTIFICATION_CHABADOFHAWAII from 'assets/Certifiers/KosherCertification/Chabad of Hawaii.jpg';
import KOSHERCERTIFICATION_CHICAGORABBINICALCOUNCIL from 'assets/Certifiers/KosherCertification/Chicago Rabbinical Council.jpg';
import KOSHERCERTIFICATION_CONTACTMANUFACTURER from 'assets/Certifiers/KosherCertification/Contact Manufacturer.jpg';
import KOSHERCERTIFICATION_DIAMONDKKASHRUTHSUPERVISIONBYBETHDINOFCONGREGATIONLUBAVITCH from 'assets/Certifiers/KosherCertification/Diamond K Kashruth Supervision by Beth Din of Congregation Lubavitch.jpg';
import KOSHERCERTIFICATION_EARTHKOSHER from 'assets/Certifiers/KosherCertification/EarthKosher.jpg';
import KOSHERCERTIFICATION_FLORIDAKOSHER from 'assets/Certifiers/KosherCertification/Florida Kosher.jpg';
import KOSHERCERTIFICATION_GENERICKOSHERK from 'assets/Certifiers/KosherCertification/Generic Kosher K.jpg';
import KOSHERCERTIFICATION_HOUSTONKASHRUTHASSOCIATION from 'assets/Certifiers/KosherCertification/Houston Kashruth Association.jpg';
import KOSHERCERTIFICATION_INTERNATIONALKOSHERCOUNCIL from 'assets/Certifiers/KosherCertification/International Kosher Council.jpg';
import KOSHERCERTIFICATION_JEWISHCOMMUNITYCOUNCILOFMONTREALVAADHAIR from 'assets/Certifiers/KosherCertification/Jewish Community Council of Montreal Vaad Hair.jpg';
import KOSHERCERTIFICATION_KAKOSHERSUPERVISORESENCALIDADKOSHERMEXICO from 'assets/Certifiers/KosherCertification/KA Kosher Supervisores en Calidad Kosher Mexico.jpg';
import KOSHERCERTIFICATION_KASHRUTHCOUNCILOFCANADA from 'assets/Certifiers/KosherCertification/Kashruth Council of Canada.jpg';
import KOSHERCERTIFICATION_KEHILLAKOSHEROKKOSHERWESTCOAST from 'assets/Certifiers/KosherCertification/Kehilla Kosher OK Kosher West Coast.jpg';
import KOSHERCERTIFICATION_KLBDKOSHERCERTIFICATIONKASHRUTDIVISIONOFLONDONBETHDIN from 'assets/Certifiers/KosherCertification/KLBD Kosher Certification (Kashrut Division of London Beth Din).jpg';
import KOSHERCERTIFICATION_KOAKOSHER from 'assets/Certifiers/KosherCertification/KOA Kosher.jpg';
import KOSHERCERTIFICATION_KOFKKOSHER from 'assets/Certifiers/KosherCertification/KOF-K Kosher.jpg';
import KOSHERCERTIFICATION_KOLAKOSHERLA from 'assets/Certifiers/KosherCertification/KOLA Kosher La.jpg';
import KOSHERCERTIFICATION_KOSHERAUSTRALIA from 'assets/Certifiers/KosherCertification/Kosher Australia.jpg';
import KOSHERCERTIFICATION_KOSHERCERTIFICATIONSERVICE from 'assets/Certifiers/KosherCertification/Kosher Certification Service.jpg';
import KOSHERCERTIFICATION_KOSHERCHECKFORMERLYBCKOSHER from 'assets/Certifiers/KosherCertification/Kosher Check (formerly BC Kosher).jpg';
import KOSHERCERTIFICATION_KOSHERKIWILICENSINGAUTHORITYNEWZEALAND from 'assets/Certifiers/KosherCertification/Kosher Kiwi Licensing Authority New Zealand.jpg';
import KOSHERCERTIFICATION_KOSHEROVERSEERSASSOCIATESOFAMERICA from 'assets/Certifiers/KosherCertification/Kosher Overseers Associates of America.jpg';
import KOSHERCERTIFICATION_KOSHERSUPERVISIONOFAMERICA from 'assets/Certifiers/KosherCertification/Kosher Supervision of America.jpg';
import KOSHERCERTIFICATION_KVHVAADHARABONIMOFNEWENGLAND from 'assets/Certifiers/KosherCertification/KVH Vaad Harabonim of New England.jpg';
import KOSHERCERTIFICATION_METROPOLITANKASHRUTHCOUNCILOFMICHIGAN from 'assets/Certifiers/KosherCertification/Metropolitan Kashruth Council of Michigan.jpg';
import KOSHERCERTIFICATION_MKMANCHESTERBETHDIN from 'assets/Certifiers/KosherCertification/MK Manchester Beth Din.jpg';
import KOSHERCERTIFICATION_MYSTERY from 'assets/Certifiers/KosherCertification/MYSTERY.jpg';
import KOSHERCERTIFICATION_NATURALFOODCERTIFIERS from 'assets/Certifiers/KosherCertification/Natural Food Certifiers.jpg';
import KOSHERCERTIFICATION_NERTAMIDKOSHER from 'assets/Certifiers/KosherCertification/Ner Tamid Kosher.jpg';
import KOSHERCERTIFICATION_OHIOKKOSHERSUPERVISION from 'assets/Certifiers/KosherCertification/Ohio-K Kosher Supervision.jpg';
import KOSHERCERTIFICATION_OKKOSHER from 'assets/Certifiers/KosherCertification/OK Kosher.jpg';
import KOSHERCERTIFICATION_OREGONKOSHER from 'assets/Certifiers/KosherCertification/Oregon Kosher.jpg';
import KOSHERCERTIFICATION_ORTHODOXRABBINICALCOUNCILOFSANFRANCISCO from 'assets/Certifiers/KosherCertification/Orthodox Rabbinical Council of San Francisco.jpg';
import KOSHERCERTIFICATION_ORTHODOXUNIONKOSHER from 'assets/Certifiers/KosherCertification/Orthodox Union Kosher.jpg';
import KOSHERCERTIFICATION_QUALITYKOSHERSUPERVISION from 'assets/Certifiers/KosherCertification/Quality Kosher Supervision.jpg';
import KOSHERCERTIFICATION_RABBINICALCOUNCILOFCALIFORNIA from 'assets/Certifiers/KosherCertification/Rabbinical Council of California.jpg';
import KOSHERCERTIFICATION_RABINATODEPERU from 'assets/Certifiers/KosherCertification/RABINATO DE PERU.jpg';
import KOSHERCERTIFICATION_SCROLLKVAADHAKASHRUSOFDENVER from 'assets/Certifiers/KosherCertification/Scroll K Vaad Hakashrus of Denver.jpg';
import KOSHERCERTIFICATION_SQUAREKKOSHERSERVICES from 'assets/Certifiers/KosherCertification/Square-K Kosher Services.jpg';
import KOSHERCERTIFICATION_STARKKOSHER from 'assets/Certifiers/KosherCertification/Star-K Kosher.jpg';
import KOSHERCERTIFICATION_TABLETKKOSHERSUPERVISION from 'assets/Certifiers/KosherCertification/Tablet-K Kosher Supervision.jpg';
import KOSHERCERTIFICATION_TRIANGLEKKOSHERSUPERVISION from 'assets/Certifiers/KosherCertification/Triangle K Kosher Supervision.jpg';
import KOSHERCERTIFICATION_UMUNITEDMEHADRINKOSHER from 'assets/Certifiers/KosherCertification/UM United Mehadrin Kosher.jpg';
import KOSHERCERTIFICATION_VAADHAKASHRUSOFMONTANA from 'assets/Certifiers/KosherCertification/Vaad HaKashrus of Montana.jpg';
import KOSHERCERTIFICATION_VAADHAKASHRUTHOFKANSASCITY from 'assets/Certifiers/KosherCertification/Vaad HaKashruth of Kansas City.jpg';
import KOSHERCERTIFICATION_VAADHARABANIMOFGREATERSEATTLE from 'assets/Certifiers/KosherCertification/Vaad HaRabanim of Greater Seattle.jpg';
import KOSHERCERTIFICATION_VAADOFNORTHERNCALIFORNIA from 'assets/Certifiers/KosherCertification/Vaad of Northern California.jpg';
import KOSHERCERTIFICATION_WHOLEKOSHERSERVICES from 'assets/Certifiers/KosherCertification/Whole Kosher Services.jpg';
import LIVEANDACTIVECULTURECERTIFIER_LIVEANDACTIVECULTURESCERTIFIEDBYTHENATIONALYOGURTASSOCIATION from 'assets/Certifiers/LiveAndActiveCultureCertifier/LiveandActiveCulturesCertifiedbytheNationalYogurtAssociation.jpg';
import LOWGLYCEMICCERTIFIER_CERTIFIED from 'assets/Certifiers/LowGlycemicCertifier/Certified.jpg';
import LOWGLYCEMICCERTIFIER_CERTIFIEDBYTHEGLYCEMICRESEARCHINSTITUTECERTIFIEDNATUARLBEVERAGE from 'assets/Certifiers/LowGlycemicCertifier/CertifiedbytheGlycemicResearchInstituteCertifiedNatuarlBeverage.jpg';
import LOWGLYCEMICCERTIFIER_GLYCEMICINDEXFOUNDATION from 'assets/Certifiers/LowGlycemicCertifier/GlycemicIndexFoundation.jpg';
import LOWGLYCEMICCERTIFIER_GLYCEMICRESEARCHINSTITUTELOWGLYCEMIC from 'assets/Certifiers/LowGlycemicCertifier/GlycemicResearchinstituteLowGlycemic.jpg';
import LOWGLYCEMICCERTIFIER_LOWGLYCENMICINDEXG1HEALTHYOPTIONS from 'assets/Certifiers/LowGlycemicCertifier/LowGlycenmicIndexG1HealthyOptions.jpg';
import NATURALPERSONALCARECERTIFIER_NATURALPERSONALCARE from 'assets/Certifiers/NaturalPersonalCareCertifier/NaturalPersonalCare.jpg';
import NEWYORKSTATEGROWNCERTIFIER_NEWYORKSTATEGROWNANDCERTIFIED from 'assets/Certifiers/NewYorkStateGrownCertifier/NewYorkStateGrownandCertified.jpg';
import NONGMOCERTIFICATIONBYEARTHKOSHER_NONGMOCERTIFICATIONBYEARTHKOSHER from 'assets/Certifiers/NonGMOCertificationByEarthKosher/NonGMOCertificationByEarthKosher.jpg';
import NONGMOCERTIFIER_GMOGUARD from 'assets/Certifiers/NonGMOCertifier/GMOGuard.jpg';
import NONGMOCERTIFIER_GREENSTARCERTIFIED from 'assets/Certifiers/NonGMOCertifier/GreenStarCertified.jpg';
import NONGMOCERTIFIER_IGENNONGMOCERTIFIED from 'assets/Certifiers/NonGMOCertifier/IGENNONGMOCERTIFIED.jpg';
import NONGMOCERTIFIER_NONGMOCERTIFICATIONBYEARTHKOSHER from 'assets/Certifiers/NonGMOCertifier/NonGMOCertificationByEarthKosher.jpg';
import NONGMOCERTIFIER_NONGMOCERTIFIEDBYNSF from 'assets/Certifiers/NonGMOCertifier/NonGMOCertifiedByNSF.jpg';
import NONGMOCERTIFIER_NONGMOGECERTIFIEDBYNSF from 'assets/Certifiers/NonGMOCertifier/NonGMOGECertifiedByNSF.jpg';
import NONGMOCERTIFIER_NONGMOPROJECT from 'assets/Certifiers/NonGMOCertifier/NonGMOProject.jpg';
import NONGMOCERTIFIER_NUTRASOURCEIGENCERTIFIED from 'assets/Certifiers/NonGMOCertifier/Nutrasource IGEN Certified.jpg';
import NONGMOCERTIFIER_NUTRASOURCEIGENTESTED from 'assets/Certifiers/NonGMOCertifier/Nutrasource IGEN Tested.jpg';
import NONGMOGECERTIFIEDBYNSF_NONGMOCERTIFIEDBYNSF from 'assets/Certifiers/NonGMOGECertifiedByNSF/NonGMOCertifiedByNSF.jpg';
import NONGMOGECERTIFIEDBYNSF_NONGMOGECERTIFIEDBYNSF from 'assets/Certifiers/NonGMOGECertifiedByNSF/NonGMOGECertifiedByNSF.jpg';
import NPACERTIFIER_NATURALPRODUCTSASSOCIATIONCERTIFIED from 'assets/Certifiers/NPACertifier/NaturalProductsAssociationCertified.jpg';
import ONEPERCENTFORTHEPLANETCERTIFIER_1FORTHEPLANET from 'assets/Certifiers/OnePercentForThePlanetCertifier/1ForThePlanet.jpg';
import ORGANICCERTIFYINGBODY_1FORTHEPLANET from 'assets/Certifiers/OrganicCertifyingBody/1ForThePlanet.jpg';
import ORGANICCERTIFYINGBODY_ABAGRICULTUREBIOLOGIQUE from 'assets/Certifiers/OrganicCertifyingBody/AB Agriculture Biologique.jpg';
import ORGANICCERTIFYINGBODY_ABOABEEORGANIC from 'assets/Certifiers/OrganicCertifyingBody/ABO A Bee Organic.jpg';
import ORGANICCERTIFYINGBODY_ABO from 'assets/Certifiers/OrganicCertifyingBody/ABO.jpg';
import ORGANICCERTIFYINGBODY_ACOAUSTRALIANCERTIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/ACO Australian Certified Organic.jpg';
import ORGANICCERTIFYINGBODY_AGRICULTUREBIOLOGIQUE from 'assets/Certifiers/OrganicCertifyingBody/AgricultureBiologique.jpg';
import ORGANICCERTIFYINGBODY_AIAMERICERTINTERNATIONAL from 'assets/Certifiers/OrganicCertifyingBody/AI Americert International.jpg';
import ORGANICCERTIFYINGBODY_AI from 'assets/Certifiers/OrganicCertifyingBody/AI.jpg';
import ORGANICCERTIFYINGBODY_AMERICERTFARMGAP from 'assets/Certifiers/OrganicCertifyingBody/AmeriCertFarmgap.jpg';
import ORGANICCERTIFYINGBODY_ARGARGENCERTSA from 'assets/Certifiers/OrganicCertifyingBody/ARG Argencert S.A..jpg';
import ORGANICCERTIFYINGBODY_ASCOAGRICULTURALSERVICESCERTIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/ASCO Agricultural Services Certified Organic.jpg';
import ORGANICCERTIFYINGBODY_ASCO from 'assets/Certifiers/OrganicCertifyingBody/ASCO.jpg';
import ORGANICCERTIFYINGBODY_AUSQUALAUSQUALPTYLTD from 'assets/Certifiers/OrganicCertifyingBody/AUS-QUAL Aus-Qual Pty. Ltd..jpg';
import ORGANICCERTIFYINGBODY_BACBIOAGRICERT from 'assets/Certifiers/OrganicCertifyingBody/BAC BioAgriCert.jpg';
import ORGANICCERTIFYINGBODY_BCSKIWAOKOGARANTIEGMBH from 'assets/Certifiers/OrganicCertifyingBody/BCS Kiwa Oko-Garantie GmbH.jpg';
import ORGANICCERTIFYINGBODY_BIOHBIOHELLASSA from 'assets/Certifiers/OrganicCertifyingBody/BIOH Bio-Hellas S.A..jpg';
import ORGANICCERTIFYINGBODY_BIOIBIOINSPECTA from 'assets/Certifiers/OrganicCertifyingBody/BIOI Bio.Inspecta.jpg';
import ORGANICCERTIFYINGBODY_BIOLBIOLATINA from 'assets/Certifiers/OrganicCertifyingBody/BIOL Bio Latina.jpg';
import ORGANICCERTIFYINGBODY_BOCBAYSTATEORGANICCERTIFIERS from 'assets/Certifiers/OrganicCertifyingBody/BOC Baystate Organic Certifiers.jpg';
import ORGANICCERTIFYINGBODY_BOC from 'assets/Certifiers/OrganicCertifyingBody/BOC.jpg';
import ORGANICCERTIFYINGBODY_CAAECERTIFICATIONSERVICE from 'assets/Certifiers/OrganicCertifyingBody/CAAE Certification Service.jpg';
import ORGANICCERTIFYINGBODY_CANADAORGANIC from 'assets/Certifiers/OrganicCertifyingBody/CanadaOrganic.jpg';
import ORGANICCERTIFYINGBODY_CCOFCERTIFICATIONSERVICESLLC from 'assets/Certifiers/OrganicCertifyingBody/CCOF Certification Services, LLC.jpg';
import ORGANICCERTIFYINGBODY_CCOF from 'assets/Certifiers/OrganicCertifyingBody/CCOF.jpg';
import ORGANICCERTIFYINGBODY_CCPBSRL from 'assets/Certifiers/OrganicCertifyingBody/CCPB Srl.jpg';
import ORGANICCERTIFYINGBODY_CDACOLORADODEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/CDA Colorado Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_CDA from 'assets/Certifiers/OrganicCertifyingBody/CDA.jpg';
import ORGANICCERTIFYINGBODY_CERESCERTIFICATIONOFENVIRONMENTALSTANDARDSGMBH from 'assets/Certifiers/OrganicCertifyingBody/CERES Certification of Environmental Standards - GmbH.jpg';
import ORGANICCERTIFYINGBODY_CMEXCERTIFICADORAMEXICANADEPRODUCTOSYPROCESOSECOLOGICOSSC from 'assets/Certifiers/OrganicCertifyingBody/CMEX Certificadora Mexicana de Productos y Procesos Ecologicos SC .jpg';
import ORGANICCERTIFYINGBODY_CSYSCERTISYS from 'assets/Certifiers/OrganicCertifyingBody/CSYS Certisys.jpg';
import ORGANICCERTIFYINGBODY_CUCLEMSONUNIVERSITY from 'assets/Certifiers/OrganicCertifyingBody/CU Clemson University.jpg';
import ORGANICCERTIFYINGBODY_CUCCONTROLUNIONCERTIFICATIONS from 'assets/Certifiers/OrganicCertifyingBody/CUC Control Union Certifications.jpg';
import ORGANICCERTIFYINGBODY_DPI from 'assets/Certifiers/OrganicCertifyingBody/DPI.jpg';
import ORGANICCERTIFYINGBODY_ECOCERTECOICOECOCERT from 'assets/Certifiers/OrganicCertifyingBody/ECOCERT-ECO ICO EcoCert.jpg';
import ORGANICCERTIFYINGBODY_ECOCERT from 'assets/Certifiers/OrganicCertifyingBody/EcoCert.jpg';
import ORGANICCERTIFYINGBODY_EGLOECOGLOBELLC from 'assets/Certifiers/OrganicCertifyingBody/EGLO Ecoglobe, LLC.jpg';
import ORGANICCERTIFYINGBODY_EKOAGROSEKOAGROS from 'assets/Certifiers/OrganicCertifyingBody/EKOAGROS Ekoagros.jpg';
import ORGANICCERTIFYINGBODY_ETKOECOLOGICALFARMINGCONTROLORGANIZATION from 'assets/Certifiers/OrganicCertifyingBody/ETKO Ecological Farming Control Organization.jpg';
import ORGANICCERTIFYINGBODY_EUOCEUROPEANUNIONORGANICCOMMISSION from 'assets/Certifiers/OrganicCertifyingBody/EUOC European Union Organic Commission.jpg';
import ORGANICCERTIFYINGBODY_EUOC from 'assets/Certifiers/OrganicCertifyingBody/EUOC.jpg';
import ORGANICCERTIFYINGBODY_FARMVERIFIED from 'assets/Certifiers/OrganicCertifyingBody/FarmVerified.jpg';
import ORGANICCERTIFYINGBODY_FSFOODSAFETYSA from 'assets/Certifiers/OrganicCertifyingBody/FS Food Safety S.A..jpg';
import ORGANICCERTIFYINGBODY_FVOFARMVERIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/FVO Farm Verified Organic.jpg';
import ORGANICCERTIFYINGBODY_GCIA from 'assets/Certifiers/OrganicCertifyingBody/GCIA.jpg';
import ORGANICCERTIFYINGBODY_GCIANOPGEORGIACROPIMPROVEMENTASSOCIATION from 'assets/Certifiers/OrganicCertifyingBody/GCIANOP Georgia Crop Improvement Association.jpg';
import ORGANICCERTIFYINGBODY_GLOGLOBALCULTURE from 'assets/Certifiers/OrganicCertifyingBody/GLO Global Culture.jpg';
import ORGANICCERTIFYINGBODY_GLOBALCULTURECERTIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/GlobalCultureCertifiedOrganic.jpg';
import ORGANICCERTIFYINGBODY_GOAGLOBALORGANICALLIANCEINC from 'assets/Certifiers/OrganicCertifyingBody/GOA Global Organic Alliance, Inc.jpg';
import ORGANICCERTIFYINGBODY_GOTSGLOBALORGANICTEXTILESTANDARD from 'assets/Certifiers/OrganicCertifyingBody/GOTS Global Organic Textile Standard.jpg';
import ORGANICCERTIFYINGBODY_GOTS from 'assets/Certifiers/OrganicCertifyingBody/GOTS.jpg';
import ORGANICCERTIFYINGBODY_IBDCERTIFICATIONS from 'assets/Certifiers/OrganicCertifyingBody/IBD Certifications.jpg';
import ORGANICCERTIFYINGBODY_ICEAISTITUTOPERLACERTIFICAZIONEETICAEAMBIENTALE from 'assets/Certifiers/OrganicCertifyingBody/ICEA Istituto per la Certificazione Etica e Ambientale.jpg';
import ORGANICCERTIFYINGBODY_ICO from 'assets/Certifiers/OrganicCertifyingBody/ICO.jpg';
import ORGANICCERTIFYINGBODY_ICSINTERNATIONALCERTIFICATIONSERVICESINC from 'assets/Certifiers/OrganicCertifyingBody/ICS International Certification Services, Inc.jpg';
import ORGANICCERTIFYINGBODY_ICS from 'assets/Certifiers/OrganicCertifyingBody/ICS.jpg';
import ORGANICCERTIFYINGBODY_IDALSIOWADEPARTMENTOFAGRICULTUREANDLANDSTEWARDSHIP from 'assets/Certifiers/OrganicCertifyingBody/IDALS Iowa Department of Agriculture and Land Stewardship.jpg';
import ORGANICCERTIFYINGBODY_IDDA from 'assets/Certifiers/OrganicCertifyingBody/IDDA.jpg';
import ORGANICCERTIFYINGBODY_IDOA from 'assets/Certifiers/OrganicCertifyingBody/IDOA.jpg';
import ORGANICCERTIFYINGBODY_IDOALS from 'assets/Certifiers/OrganicCertifyingBody/IDOALS.jpg';
import ORGANICCERTIFYINGBODY_IMOCIMOCERTLATINOAMERICALTDA from 'assets/Certifiers/OrganicCertifyingBody/IMOC IMOcert Latinoamerica LTDA.jpg';
import ORGANICCERTIFYINGBODY_IODA from 'assets/Certifiers/OrganicCertifyingBody/IODA.jpg';
import ORGANICCERTIFYINGBODY_ISDAIDAHOSTATEDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/ISDA Idaho State Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_KDAKENTUCKYDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/KDA Kentucky Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_LACONGMBH from 'assets/Certifiers/OrganicCertifyingBody/LACON GmbH.jpg';
import ORGANICCERTIFYINGBODY_LETISSA from 'assets/Certifiers/OrganicCertifyingBody/LETIS S.A..jpg';
import ORGANICCERTIFYINGBODY_LOGIECOLOGICASA from 'assets/Certifiers/OrganicCertifyingBody/LOGI Eco-LOGICA S.A..jpg';
import ORGANICCERTIFYINGBODY_MAYAMAYACERTSA from 'assets/Certifiers/OrganicCertifyingBody/MAYA Mayacert S.A..jpg';
import ORGANICCERTIFYINGBODY_MCCOMONTEREYCOUNTYCERTIFIEDORGANICS from 'assets/Certifiers/OrganicCertifyingBody/MCCO Monterey County Certified Organics.jpg';
import ORGANICCERTIFYINGBODY_MCCO from 'assets/Certifiers/OrganicCertifyingBody/MCCO.jpg';
import ORGANICCERTIFYINGBODY_MCIAMINNESOTACROPIMPROVEMENTASSOCIATION from 'assets/Certifiers/OrganicCertifyingBody/MCIA Minnesota Crop Improvement Association.jpg';
import ORGANICCERTIFYINGBODY_MCSMOFGACERTIFICATIONSERVICES from 'assets/Certifiers/OrganicCertifyingBody/MCS MOFGA Certification Services.jpg';
import ORGANICCERTIFYINGBODY_MDAMARYLANDDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/MDA Maryland Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_MOCAMARINORGANICCERTIFIEDAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/MOCA Marin Organic Certified Agriculture.jpg';
import ORGANICCERTIFYINGBODY_MOFGA from 'assets/Certifiers/OrganicCertifyingBody/MOFGA.jpg';
import ORGANICCERTIFYINGBODY_MOSAMIDWESTORGANICSERVICESASSOCIATIONINC from 'assets/Certifiers/OrganicCertifyingBody/MOSA Midwest Organic Services Association, Inc..jpg';
import ORGANICCERTIFYINGBODY_MOSA from 'assets/Certifiers/OrganicCertifyingBody/MOSA.jpg';
import ORGANICCERTIFYINGBODY_MTDAMONTANADEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/MTDA Montana Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_NCONASAACERTIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/NCO NASAA Certified Organic.jpg';
import ORGANICCERTIFYINGBODY_NFCNATURALFOODCERTIFIERS from 'assets/Certifiers/OrganicCertifyingBody/NFC Natural Food Certifiers.jpg';
import ORGANICCERTIFYINGBODY_NFC from 'assets/Certifiers/OrganicCertifyingBody/NFC.jpg';
import ORGANICCERTIFYINGBODY_NHDAMFNEWHAMPSHIREDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/NHDAMF New Hampshire Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_NICSNATURESINTERNATIONALCERTIFICATIONSERVICES from "assets/Certifiers/OrganicCertifyingBody/NICS Nature's International Certification Services.jpg";
import ORGANICCERTIFYINGBODY_NJDANEWJERSEYDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/NJDA New Jersey Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_NJDA from 'assets/Certifiers/OrganicCertifyingBody/NJDA.jpg';
import ORGANICCERTIFYINGBODY_NMDANEWMEXICODEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/NMDA New Mexico Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_NOFANYNOFANYCERTIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/NOFA-NY NOFA-NY Certified Organic.jpg';
import ORGANICCERTIFYINGBODY_NOFANY from 'assets/Certifiers/OrganicCertifyingBody/NOFANY.jpg';
import ORGANICCERTIFYINGBODY_NSFINTERNATIONAL from 'assets/Certifiers/OrganicCertifyingBody/NSF International.jpg';
import ORGANICCERTIFYINGBODY_NSF from 'assets/Certifiers/OrganicCertifyingBody/NSF.jpg';
import ORGANICCERTIFYINGBODY_NSO from 'assets/Certifiers/OrganicCertifyingBody/NSO.jpg';
import ORGANICCERTIFYINGBODY_OCORGANICCERTIFIERSINC from 'assets/Certifiers/OrganicCertifyingBody/OC Organic Certifiers, Inc..jpg';
import ORGANICCERTIFYINGBODY_OCIAORGANICCROPIMPROVEMENTASSOCIATION from 'assets/Certifiers/OrganicCertifyingBody/OCIA Organic Crop Improvement Association.jpg';
import ORGANICCERTIFYINGBODY_OCIA from 'assets/Certifiers/OrganicCertifyingBody/OCIA.jpg';
import ORGANICCERTIFYINGBODY_ODAOREGONDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/ODA Oregon Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_ODAFFOKLAHOMADEPTOFAGRICULTUREFOODANDFORESTRY from 'assets/Certifiers/OrganicCertifyingBody/ODAFF Oklahoma Dept of Agriculture Food and Forestry.jpg';
import ORGANICCERTIFYINGBODY_OEFFAOHIOECOLOGICALFOODANDFARMASSOCIATION from 'assets/Certifiers/OrganicCertifyingBody/OEFFA Ohio Ecological Food and Farm Association.jpg';
import ORGANICCERTIFYINGBODY_OFCORGANICFEDERATIONOFCANADA from 'assets/Certifiers/OrganicCertifyingBody/OFC Organic Federation of Canada.jpg';
import ORGANICCERTIFYINGBODY_OFDCORGANICFOODDEVELOPMENTANDCERTIFICATIONCENTREOFCHINA from 'assets/Certifiers/OrganicCertifyingBody/OFDC Organic Food Development And Certification Centre of China.jpg';
import ORGANICCERTIFYINGBODY_OFFORGANICFOODFEDERATION from 'assets/Certifiers/OrganicCertifyingBody/OFF Organic Food Federation.jpg';
import ORGANICCERTIFYINGBODY_OFF from 'assets/Certifiers/OrganicCertifyingBody/OFF.jpg';
import ORGANICCERTIFYINGBODY_OIAORGANIZACIONINTERNACIONALAGROPECUARIA from 'assets/Certifiers/OrganicCertifyingBody/OIA Organizacion Internacional Agropecuaria.jpg';
import ORGANICCERTIFYINGBODY_OMICOVERSEASMERCHANDISEINSPECTIONCOLTD from 'assets/Certifiers/OrganicCertifyingBody/OMIC Overseas Merchandise Inspection Co., Ltd..jpg';
import ORGANICCERTIFYINGBODY_ONEOCIONECERT from 'assets/Certifiers/OrganicCertifyingBody/ONE-OCI OneCert.jpg';
import ORGANICCERTIFYINGBODY_ONMONMARKCERTIFICATIONSERVICES from 'assets/Certifiers/OrganicCertifyingBody/ONM OnMark Certification Services.jpg';
import ORGANICCERTIFYINGBODY_ORGANICCERTIFIERS from 'assets/Certifiers/OrganicCertifyingBody/OrganicCertifiers.jpg';
import ORGANICCERTIFYINGBODY_OTCOOREGONTILTHCERTIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/OTCO Oregon Tilth Certified Organic.jpg';
import ORGANICCERTIFYINGBODY_OTCO from 'assets/Certifiers/OrganicCertifyingBody/OTCO.jpg';
import ORGANICCERTIFYINGBODY_PACSPACIFICAGRICULTURALCERTIFICATIONSOCIETY from 'assets/Certifiers/OrganicCertifyingBody/PACS PACIFIC AGRICULTURAL CERTIFICATION SOCIETY.jpg';
import ORGANICCERTIFYINGBODY_PACS from 'assets/Certifiers/OrganicCertifyingBody/PACS.jpg';
import ORGANICCERTIFYINGBODY_PCOPENNSYLVANIACERTIFIEDORGANIC from 'assets/Certifiers/OrganicCertifyingBody/PCO Pennsylvania Certified Organic.jpg';
import ORGANICCERTIFYINGBODY_PJRPERRYJOHNSONREGISTRARFOODSAFETYINC from 'assets/Certifiers/OrganicCertifyingBody/PJR Perry Johnson Registrar Food Safety, Inc..jpg';
import ORGANICCERTIFYINGBODY_PLPRIMUSLABS from 'assets/Certifiers/OrganicCertifyingBody/PL Primus Labs.jpg';
import ORGANICCERTIFYINGBODY_PROPROCERTORGANICSYSTEMSLTD from 'assets/Certifiers/OrganicCertifyingBody/PRO Pro-Cert Organic Systems, Ltd..jpg';
import ORGANICCERTIFYINGBODY_PROCERT from 'assets/Certifiers/OrganicCertifyingBody/procert.jpg';
import ORGANICCERTIFYINGBODY_QAIQUALITYASSURANCEINTERNATIONAL from 'assets/Certifiers/OrganicCertifyingBody/QAI - Quality Assurance International.jpg';
import ORGANICCERTIFYINGBODY_QAITRANSITIONALCERTIFIED from 'assets/Certifiers/OrganicCertifyingBody/QAI TRANSITIONAL CERTIFIED.jpg';
import ORGANICCERTIFYINGBODY_QAI from 'assets/Certifiers/OrganicCertifyingBody/QAI.jpg';
import ORGANICCERTIFYINGBODY_QCSQUALITYCERTIFICATIONSERVICES from 'assets/Certifiers/OrganicCertifyingBody/QCS Quality Certification Services.jpg';
import ORGANICCERTIFYINGBODY_RIDEMRHODEISLANDDEPARTMENTOFENVIRONMENTALMANAGEMENT from 'assets/Certifiers/OrganicCertifyingBody/RIDEM Rhode Island Department of Environmental Management.jpg';
import ORGANICCERTIFYINGBODY_SAOSOILASSOCIATION from 'assets/Certifiers/OrganicCertifyingBody/SAO Soil association.jpg';
import ORGANICCERTIFYINGBODY_SAO from 'assets/Certifiers/OrganicCertifyingBody/SAO.jpg';
import ORGANICCERTIFYINGBODY_SCSGLOBALSERVICESINC from 'assets/Certifiers/OrganicCertifyingBody/SCS Global Services, Inc..jpg';
import ORGANICCERTIFYINGBODY_SPCA from 'assets/Certifiers/OrganicCertifyingBody/SPCA.jpg';
import ORGANICCERTIFYINGBODY_STELSTELLARCERTIFICATIONSERVICES from 'assets/Certifiers/OrganicCertifyingBody/STEL Stellar Certification Services.jpg';
import ORGANICCERTIFYINGBODY_STEL from 'assets/Certifiers/OrganicCertifyingBody/STEL.jpg';
import ORGANICCERTIFYINGBODY_TDATEXASDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/TDA Texas Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_TOCTSEXINORGANICCERTIFICATIONCORPORATION from 'assets/Certifiers/OrganicCertifyingBody/TOC Tse-Xin Organic Certification Corporation.jpg';
import ORGANICCERTIFYINGBODY_TRANSITIONALCERTIFIEDCLAIM from 'assets/Certifiers/OrganicCertifyingBody/TransitionalCertifiedClaim.jpg';
import ORGANICCERTIFYINGBODY_UDAFUTAHDEPARTMENTOFAGRICULTUREANDFOOD from 'assets/Certifiers/OrganicCertifyingBody/UDAF Utah Department of Agriculture and Food.jpg';
import ORGANICCERTIFYINGBODY_USDANOPNATIONALORGANICPROGRAM from 'assets/Certifiers/OrganicCertifyingBody/USDA NOP National Organic Program.jpg';
import ORGANICCERTIFYINGBODY_USDA from 'assets/Certifiers/OrganicCertifyingBody/USDA.jpg';
import ORGANICCERTIFYINGBODY_VOCAVEDICORGANICCERTIFICATIONAGENCY from 'assets/Certifiers/OrganicCertifyingBody/VOCA Vedic organic certification agency.jpg';
import ORGANICCERTIFYINGBODY_VOCA from 'assets/Certifiers/OrganicCertifyingBody/VOCA.jpg';
import ORGANICCERTIFYINGBODY_VOFVERMONTORGANICFARMERSLLC from 'assets/Certifiers/OrganicCertifyingBody/VOF - Vermont Organic Farmers, LLC.jpg';
import ORGANICCERTIFYINGBODY_VOF from 'assets/Certifiers/OrganicCertifyingBody/VOF.jpg';
import ORGANICCERTIFYINGBODY_WSDAWASHINGTONSTATEDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/WSDA - Washington State Department of Agriculture.jpg';
import ORGANICCERTIFYINGBODY_WSDA from 'assets/Certifiers/OrganicCertifyingBody/WSDA.jpg';
import ORGANICCERTIFYINGBODY_YDAYOLOCOUNTYDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicCertifyingBody/YDA Yolo County Department of Agriculture.jpg';
import ORGANICSTANDARDSAGENCYLOGO_CFIACANADIANFOODINSPECTIONAGENCY from 'assets/Certifiers/OrganicStandardsAgencyLogo/CFIA Canadian Food Inspection Agency.jpg';
import ORGANICSTANDARDSAGENCYLOGO_COSMOSSTANDARDFORNATURALANDORGANICCOSMETICS from 'assets/Certifiers/OrganicStandardsAgencyLogo/COSMOS Standard for Natural and Organic Cosmetics.jpg';
import ORGANICSTANDARDSAGENCYLOGO_EUOCEUROPEANUNIONORGANICCOMMISSION from 'assets/Certifiers/OrganicStandardsAgencyLogo/EUOC European Union Organic Commission.jpg';
import ORGANICSTANDARDSAGENCYLOGO_GOTSGLOBALORGANICTEXTILESTANDARD from 'assets/Certifiers/OrganicStandardsAgencyLogo/GOTS Global Organic Textile Standard.jpg';
import ORGANICSTANDARDSAGENCYLOGO_IFOAMINTERNATIONALFEDERATIONOFAGRICULTUREMOVEMENTS from 'assets/Certifiers/OrganicStandardsAgencyLogo/IFOAM International Federation of Agriculture Movements.jpg';
import ORGANICSTANDARDSAGENCYLOGO_JASJAPANAGRICULTURALSTANDARDS from 'assets/Certifiers/OrganicStandardsAgencyLogo/JAS Japan Agricultural Standards.jpg';
import ORGANICSTANDARDSAGENCYLOGO_OCSORGANICCONTENTSTANDARD from 'assets/Certifiers/OrganicStandardsAgencyLogo/OCS Organic Content Standard.jpg';
import ORGANICSTANDARDSAGENCYLOGO_USDAUNITEDSTATESDEPARTMENTOFAGRICULTURE from 'assets/Certifiers/OrganicStandardsAgencyLogo/USDA United States Department of Agriculture.jpg';
import PACACERTIFIER_USDAPACA from 'assets/Certifiers/PACACertifier/USDA PACA.jpg';
import PALEOCERTIFIER_CERTIFIEDPALEO from 'assets/Certifiers/PaleoCertifier/CertifiedPaleo.jpg';
import PALEOCERTIFIER_PALEOAPPROVEDBYTHEPALEOFOUNDATION from 'assets/Certifiers/PaleoCertifier/PaleoApprovedbythePaleoFoundation.jpg';
import PALEOCERTIFIER_PALEOCERTIFIEDBYEARTHKOSHER from 'assets/Certifiers/PaleoCertifier/PaleoCertifiedbyEarthKosher.jpg';
import PALEOCERTIFIER_PALEOFRIENDLYBYTHEPALEOFOUNDATION from 'assets/Certifiers/PaleoCertifier/PaleoFriendlybythePaleoFoundation.jpg';
import PALEOCERTIFIER_PALEOFRIENDLYTHISPRODUCTISCERTIFIEO from 'assets/Certifiers/PaleoCertifier/PaleoFriendlyThisProductIsCertifieo.jpg';
import PALEOCERTIFIER_PALEOVEGANCERTIFIEDBYPALEOFOUNDATION from 'assets/Certifiers/PaleoCertifier/PaleoVeganCertifiedbyPaleoFoundation.jpg';
import PASACERTIFIER_PENNSYLVANIAASSOCIATIONFORSUSTAINABLEAGRICULTURE from 'assets/Certifiers/PASACertifier/Pennsylvania Association for Sustainable Agriculture.jpg';
import PLANTBASEDDERIVEDCERTIFIER_NSFPLANTBASED from 'assets/Certifiers/PlantBasedDerivedCertifier/NSF Plant Based.jpg';
import PLANTBASEDDERIVEDCERTIFIER_WFPB from 'assets/Certifiers/PlantBasedDerivedCertifier/WFPB.jpg';
import RAINFORESTALLIANCECERTIFIER_RAINFORESTALLIANCE from 'assets/Certifiers/RainForestAllianceCertifier/RainForestAlliance.jpg';
import REALCERTIFIED_RESPONSIBLEEPICUREANANDAGRICULTURALLEADERSHIPCERTIFIER from 'assets/Certifiers/REALCertified/ResponsibleEpicureanandAgriculturalLeadershipCertifier.jpg';
import REALSEALCERTIFIER_REAL from 'assets/Certifiers/RealSealCertifier/REAL.jpg';
import RECYCLEDMATERIALTYPE_1PET from 'assets/Certifiers/RecycledMaterialType/1 (PET).jpg';
import RECYCLEDMATERIALTYPE_1PETE from 'assets/Certifiers/RecycledMaterialType/1 (PETE).jpg';
import RECYCLEDMATERIALTYPE_100RECYCLEDPAPERBOARD from 'assets/Certifiers/RecycledMaterialType/100RecycledPaperboard.jpg';
import RECYCLEDMATERIALTYPE_100RPB from 'assets/Certifiers/RecycledMaterialType/100RPB.jpg';
import RECYCLEDMATERIALTYPE_2HDPE from 'assets/Certifiers/RecycledMaterialType/2 (HDPE).jpg';
import RECYCLEDMATERIALTYPE_3PVC from 'assets/Certifiers/RecycledMaterialType/3 (PVC).jpg';
import RECYCLEDMATERIALTYPE_4LDPE from 'assets/Certifiers/RecycledMaterialType/4 (LDPE).jpg';
import RECYCLEDMATERIALTYPE_5PP from 'assets/Certifiers/RecycledMaterialType/5 (PP).jpg';
import RECYCLEDMATERIALTYPE_6PS from 'assets/Certifiers/RecycledMaterialType/6 (PS).jpg';
import RECYCLEDMATERIALTYPE_7OTHER from 'assets/Certifiers/RecycledMaterialType/7 (OTHER).jpg';
import RECYCLEDMATERIALTYPE_CIRCULARRECYCLINGSYMBOL from 'assets/Certifiers/RecycledMaterialType/CIRCULAR RECYCLING SYMBOL.jpg';
import RECYCLEDMATERIALTYPE_CORRUGATEDBOARD from 'assets/Certifiers/RecycledMaterialType/CorrugatedBoard.jpg';
import RECYCLEDMATERIALTYPE_CORRUGATEDFIBERBOARD from 'assets/Certifiers/RecycledMaterialType/CorrugatedFiberBoard.jpg';
import RECYCLEDMATERIALTYPE_CORRUGATEDRECYCLES from 'assets/Certifiers/RecycledMaterialType/CorrugatedRecycles.jpg';
import RECYCLEDMATERIALTYPE_RECYCLABLESTEEL from 'assets/Certifiers/RecycledMaterialType/RecyclableSteel.jpg';
import RECYCLEDMATERIALTYPE_STANDARDRECYCLINGSYMBOL from 'assets/Certifiers/RecycledMaterialType/StandardRecyclingSymbol.jpg';
import RECYCLEDMATERIALTYPE_STEELRECYCLE from 'assets/Certifiers/RecycledMaterialType/SteelRecycle.jpg';
import RECYCLEDMATERIALTYPE_TERRACYCLE from 'assets/Certifiers/RecycledMaterialType/TerraCycle.jpg';
import REGENERATIVEORGANICCERTIFIER_REGENERATIVEORGANICCERTIFIER from 'assets/Certifiers/RegenerativeOrganicCertifier/RegenerativeOrganicCertifier.jpg';
import RENEWABLEENERGYCERTIFIER_GREENE from 'assets/Certifiers/RenewableEnergyCertifier/GreenE.jpg';
import SOCIALMEDIA_FACEBOOK from 'assets/Certifiers/SocialMedia/Facebook.jpg';
import SOCIALMEDIA_GOOGLE from 'assets/Certifiers/SocialMedia/Google.jpg';
import SOCIALMEDIA_INSTAGRAM from 'assets/Certifiers/SocialMedia/Instagram.jpg';
import SOCIALMEDIA_PINTEREST from 'assets/Certifiers/SocialMedia/Pinterest.jpg';
import SOCIALMEDIA_SNAPCHAT from 'assets/Certifiers/SocialMedia/SnapChat.jpg';
import SOCIALMEDIA_TWITTER from 'assets/Certifiers/SocialMedia/Twitter.jpg';
import SOCIALMEDIA_YOUTUBE from 'assets/Certifiers/SocialMedia/YouTube.jpg';
import SOCIALMEDIA_YOUTUBE2 from 'assets/Certifiers/SocialMedia/YouTube2.jpg';
import SOCIALMEDIA_YOUTUBE3 from 'assets/Certifiers/SocialMedia/YouTube3.jpg';
import SUSTAINABILITYCERTIFIER_GREENSEAL from 'assets/Certifiers/SustainabilityCertifier/GreenSeal.jpg';
import SUSTAINABILITYCERTIFIER_RSPO from 'assets/Certifiers/SustainabilityCertifier/RSPO.jpg';
import SUSTAINABILITYCERTIFIER_SIPCERTIFIED from 'assets/Certifiers/SustainabilityCertifier/SIPCertified.jpg';
import SUSTAINABILITYCERTIFIER_UTZCERTIFIED from 'assets/Certifiers/SustainabilityCertifier/UTZCertified.jpg';
import SUSTAINABILITYCERTIFIER_VERIFLORA from 'assets/Certifiers/SustainabilityCertifier/Veriflora.jpg';
import VEGANCERTIFIER_AMERICANVEGETARIANASSOCIATIONVEGAN from 'assets/Certifiers/VeganCertifier/American Vegetarian Association Vegan.jpg';
import VEGANCERTIFIER_BIOAGRICERTVEGAN from 'assets/Certifiers/VeganCertifier/BioAgri Cert Vegan.jpg';
import VEGANCERTIFIER_EARTHKOSHERVEGANVERIFIED from 'assets/Certifiers/VeganCertifier/Earth Kosher Vegan Verified.jpg';
import VEGANCERTIFIER_NFCNATURALFOODCERTIFIERS from 'assets/Certifiers/VeganCertifier/NFC Natural Food Certifiers.jpg';
import VEGANCERTIFIER_PALEOFOUNDATIONPALEOVEGAN from 'assets/Certifiers/VeganCertifier/Paleo Foundation Paleo Vegan.jpg';
import VEGANCERTIFIER_PETAAPPROVEDVEGAN from 'assets/Certifiers/VeganCertifier/PETA Approved Vegan.jpg';
import VEGANCERTIFIER_PETACRUELTYFREEANDVEGAN from 'assets/Certifiers/VeganCertifier/PETA Cruelty Free and Vegan.jpg';
import VEGANCERTIFIER_VEGANOK from 'assets/Certifiers/VeganCertifier/Vegan OK.jpg';
import VEGANCERTIFIER_VEGANSOCIETY from 'assets/Certifiers/VeganCertifier/Vegan Society.jpg';
import VEGANCERTIFIER_VEGANORG from 'assets/Certifiers/VeganCertifier/Vegan.org.jpg';
import VEGANCERTIFIER_VEGETARIANSOCIETYVEGANAPPROVED from 'assets/Certifiers/VeganCertifier/Vegetarian Society Vegan Approved.jpg';
import VEGETARIANCERTIFIER_AMERICANVEGETARIANASSOCIATIONVEGETARIAN from 'assets/Certifiers/VegetarianCertifier/AmericanVegetarianAssociationVegetarian.jpg';
import VEGETARIANCERTIFIER_VEGECERT from 'assets/Certifiers/VegetarianCertifier/VegeCert.jpg';
import VEGETARIANCERTIFIER_VEGETARIANSOCIETY from 'assets/Certifiers/VegetarianCertifier/VegetarianSociety.jpg';
import VETERANSCERTIFIER_HOMEGROWNBYHEROES from 'assets/Certifiers/VeteransCertifier/HomegrownByHeroes.jpg';
import VETERANSCERTIFIER_WOUNDEDWARRIOR from 'assets/Certifiers/VeteransCertifier/WOUNDED WARRIOR.jpg';
import VITICULTURECERTIFIER_BIODYVINSYNDICATINTERNATIONALDESVIGNERONSENCULTUREBIODYNAMIQUESIVCBD from 'assets/Certifiers/ViticultureCertifier/BIODYVIN - Syndicat International des Vignerons en Culture Bio-Dynamique (SIVCBD).jpg';
import VITICULTURECERTIFIER_BIODYVIN from 'assets/Certifiers/ViticultureCertifier/BIODYVIN.jpg';
import VITICULTURECERTIFIER_CALIFORNIASUSTAINABLEWINEGROWINGALLIANCE from 'assets/Certifiers/ViticultureCertifier/California Sustainable Winegrowing Alliance.jpg';
import VITICULTURECERTIFIER_CANADIANVINTNERSASSOCIATION from 'assets/Certifiers/ViticultureCertifier/Canadian Vintners Association.jpg';
import VITICULTURECERTIFIER_DELINATBIOGARANTIE from 'assets/Certifiers/ViticultureCertifier/Delinat Bio Garantie.jpg';
import VITICULTURECERTIFIER_DEUTSCHESGUTENBANDWEINDLG from 'assets/Certifiers/ViticultureCertifier/Deutsches Gutenband Wein (DLG).jpg';
import VITICULTURECERTIFIER_IPWSOUTHAFRICASOUTHAFRICASINTEGRATEDPRODUCTIONOFWINESYSTEMS from "assets/Certifiers/ViticultureCertifier/IPW - South Africa (South Africa's Integrated Production of Wine Systems).jpg";
// import VITICULTURECERTIFIER_IPWSOUTHAFRICASOUTHAFRICASINTEGRATEDPRODUCTIONOFWINESYSTEMS from 'assets/Certifiers/ViticultureCertifier/IPW – South Africa (South Africa’s Integrated Production of Wine Systems).jpg';
import VITICULTURECERTIFIER_IPW from 'assets/Certifiers/ViticultureCertifier/IPW.jpg';
import VITICULTURECERTIFIER_LIVELOWINPUTVITICULTUREANDENOLOGYOROREGONLIVE from 'assets/Certifiers/ViticultureCertifier/LIVE (Low Input Viticulture and Enology) or Oregon LIVE.jpg';
import VITICULTURECERTIFIER_LODIRULESSUSTAINABLEWINEGROWING from 'assets/Certifiers/ViticultureCertifier/Lodi Rules Sustainable Winegrowing.jpg';
import VITICULTURECERTIFIER_LONGISLANDSUSTAINABLEWINEGROWING from 'assets/Certifiers/ViticultureCertifier/Long Island Sustainable Winegrowing.jpg';
import VITICULTURECERTIFIER_NAPAGREENWINERY from 'assets/Certifiers/ViticultureCertifier/NAPA Green Winery.jpg';
import VITICULTURECERTIFIER_NAPAGREEN from 'assets/Certifiers/ViticultureCertifier/NAPA Green.jpg';
import VITICULTURECERTIFIER_SUSTAINABLEWINEGROWINGNEWZEALAND from 'assets/Certifiers/ViticultureCertifier/Sustainable Winegrowing New Zealand.jpg';
import VITICULTURECERTIFIER_VINEBALANCESUSTAINABLEVITICULTUREINTHENORTHEAST from 'assets/Certifiers/ViticultureCertifier/Vine BALANCE (Sustainable Viticulture in the Northeast).jpg';
import VITICULTURECERTIFIER_VINEWISEWASHINGTONGUIDETOSUSTAINABLEVITICULTURE from 'assets/Certifiers/ViticultureCertifier/VineWise (Washington Guide to Sustainable Viticulture).jpg';
import VITICULTURECERTIFIER_VINTNERSQUALITYALLIANCE from 'assets/Certifiers/ViticultureCertifier/VintnersQualityAlliance.jpg';
import VITICULTURECERTIFIER_WINECOUNCILOFONTARIO from 'assets/Certifiers/ViticultureCertifier/Wine Council of Ontario.jpg';
import WATERQUALITYASSOCIATIONCERTIFIER_WQA from 'assets/Certifiers/WaterQualityAssociationCertifier/WQA.jpg';
import WHOLEFOODSMARKETPREMIUMBODYCARE_WHOLEFOODSMARKETPREMIUMBODYCARE from 'assets/Certifiers/WholeFoodsMarketPremiumBodyCare/WholeFoodsMarketPremiumBodyCare.jpg';
import WHOLEFOODSMARKETRESPONSIBLYFARMED3RDPARTYVERIFIED_WHOLEFOODSMARKETRESPONSIBLYFARMED3RDPARTYVERIFIED from 'assets/Certifiers/WholeFoodsMarketResponsiblyFarmed3rdPartyVerified/WholeFoodsMarketResponsiblyFarmed3rdPartyVerified.jpg';
import WHOLEFOODSSOURCEDFORGOOD_WHOLEFOODSSOURCEDFORGOOD from 'assets/Certifiers/WholeFoodsSourcedForGood/WholeFoodsSourcedForGood.jpg';
import WHOLEFOODSWHOLETRADEGUARANTEECERTIFIED_WTG from 'assets/Certifiers/WholeFoodsWholeTradeGuaranteeCertified/WTG.jpg';
import WHOLEFOODSWHOLETRADEGUARANTEECERTIFIER_WTG from 'assets/Certifiers/WholeFoodsWholeTradeGuaranteeCertifier/WTG.jpg';
import WHOLEGRAINCERTIFIER_WHOLEGRAINSCOUNCIL from 'assets/Certifiers/WholeGrainCertifier/WholeGrainsCouncil.jpg';
import WOMENSBUSINESSENTERPRISENATIONALCOUNCILCERTIFIER_WBENC from 'assets/Certifiers/WomensBusinessEnterpriseNationalCouncilCertifier/WBENC.jpg';
import WOMENSBUSINESSENTERPRISENATIONALCOUNCILCERTIFIER_WOMENOWNED from 'assets/Certifiers/WomensBusinessEnterpriseNationalCouncilCertifier/WomenOwned.jpg';
import REGENERATIVEAGRICULTURECERTIFIER_LANDTOMARKET from 'assets/Certifiers/RegenerativeAgricultureCertifier/LandToMarketCert.png';


import { Images } from 'config/assets';

const CERTIFICATION_IMAGES = {
  ADASEALOFACCEPTANCE_CLAIMANDCERTIFIER: ADASEALOFACCEPTANCE_CLAIMANDCERTIFIER,
  AUTHENTICMADEPRODUCEDBYAMERICANINDIANSCERTIFIER_CMBAI: AUTHENTICMADEPRODUCEDBYAMERICANINDIANSCERTIFIER_CMBAI,
  BEEBETTERCERTIFIER_BEEBETTER: BEEBETTERCERTIFIER_BEEBETTER,
  BEEBETTERCERTIFIER_BEEFRIENDLY: BEEBETTERCERTIFIER_BEEFRIENDLY,
  BEEBETTERCERTIFIER_BEEFRIENDLY1: BEEBETTERCERTIFIER_BEEFRIENDLY1,
  BIOBASEDCERTIFIER_USDABIOBASED: BIOBASEDCERTIFIER_USDABIOBASED,
  BIOBASEDCERTIFIER_WALKINGTHEWALK: BIOBASEDCERTIFIER_WALKINGTHEWALK,
  BIODYNAMICCERTIFIER_DEMETER: BIODYNAMICCERTIFIER_DEMETER,
  BIOENGINEEREDTYPE_BIOENGINEERED: BIOENGINEEREDTYPE_BIOENGINEERED,
  BIOENGINEEREDTYPE_DERIVEDFROM: BIOENGINEEREDTYPE_DERIVEDFROM,
  BUSINESSOWNERMANUFACTURERCERTIFIER_DISABILITYOWNEDBUSINESSENTERPRISEDOBE: BUSINESSOWNERMANUFACTURERCERTIFIER_DISABILITYOWNEDBUSINESSENTERPRISEDOBE,
  BUSINESSOWNERMANUFACTURERCERTIFIER_HOMEGROWNBYHEROES: BUSINESSOWNERMANUFACTURERCERTIFIER_HOMEGROWNBYHEROES,
  BUSINESSOWNERMANUFACTURERCERTIFIER_MADEPRODUCEDBYAMERICANINDIANS: BUSINESSOWNERMANUFACTURERCERTIFIER_MADEPRODUCEDBYAMERICANINDIANS,
  BUSINESSOWNERMANUFACTURERCERTIFIER_MINORITYBUSINESSENTERPRISEMBE: BUSINESSOWNERMANUFACTURERCERTIFIER_MINORITYBUSINESSENTERPRISEMBE,
  BUSINESSOWNERMANUFACTURERCERTIFIER_SERVICEDISABLEDVETERANOWNEDSMALLBUSINESSSDVDOBE: BUSINESSOWNERMANUFACTURERCERTIFIER_SERVICEDISABLEDVETERANOWNEDSMALLBUSINESSSDVDOBE,
  BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANOWNEDBUSINESSVOB: BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANOWNEDBUSINESSVOB,
  BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANOWNEDSMALLBUSINESSVOSB: BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANOWNEDSMALLBUSINESSVOSB,
  BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANDISABILITYOWNEDBUSINESSENTERPRISEVDOBE: BUSINESSOWNERMANUFACTURERCERTIFIER_VETERANDISABILITYOWNEDBUSINESSENTERPRISEVDOBE,
  BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENBUSINESSENTERPRISEWBE: BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENBUSINESSENTERPRISEWBE,
  BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENBUSINESSENTERPRISENATIONALCOUNCILWBENC: BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENBUSINESSENTERPRISENATIONALCOUNCILWBENC,
  BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENOWNEDSMALLBUSINESSWOSB: BUSINESSOWNERMANUFACTURERCERTIFIER_WOMENOWNEDSMALLBUSINESSWOSB,
  CARBONFOOTPRINTCERTIFIER_CARBONFREE: CARBONFOOTPRINTCERTIFIER_CARBONFREE,
  CARBONFOOTPRINTCERTIFIER_CARBONREDUCTION: CARBONFOOTPRINTCERTIFIER_CARBONREDUCTION,
  CARBONFOOTPRINTCERTIFIER_CARBONTRUST: CARBONFOOTPRINTCERTIFIER_CARBONTRUST,
  CBDHEMPCERTIFIER_CERTIFIEDKIND: CBDHEMPCERTIFIER_CERTIFIEDKIND,
  CBDHEMPCERTIFIER_USHEMPAUTHORITY: CBDHEMPCERTIFIER_USHEMPAUTHORITY,
  CERTIFIEDBCORPORATION_CERTIFIEDBCORPORATION: CERTIFIEDBCORPORATION_CERTIFIEDBCORPORATION,
  CERTIFIEDBCORPORATION_THUMBS: CERTIFIEDBCORPORATION_THUMBS,
  CRADLETOCRADLECERTIFIER_BASIC: CRADLETOCRADLECERTIFIER_BASIC,
  CRADLETOCRADLECERTIFIER_BRONZE: CRADLETOCRADLECERTIFIER_BRONZE,
  CRADLETOCRADLECERTIFIER_GOLD: CRADLETOCRADLECERTIFIER_GOLD,
  CRADLETOCRADLECERTIFIER_PLATINUM: CRADLETOCRADLECERTIFIER_PLATINUM,
  CRADLETOCRADLECERTIFIER_SILVER: CRADLETOCRADLECERTIFIER_SILVER,
  CRUELTYFREECERTIFIER_ANIMALWELFAREAPPROVED: CRUELTYFREECERTIFIER_ANIMALWELFAREAPPROVED,
  CRUELTYFREECERTIFIER_CERTIFIEDHUMANERAISEDANDHANDLED: CRUELTYFREECERTIFIER_CERTIFIEDHUMANERAISEDANDHANDLED,
  CRUELTYFREECERTIFIER_CHOOSECRUELTYFREE: CRUELTYFREECERTIFIER_CHOOSECRUELTYFREE,
  CRUELTYFREECERTIFIER_CRUELTYFREE: CRUELTYFREECERTIFIER_CRUELTYFREE,
  CRUELTYFREECERTIFIER_CRUELTYFREEANDVEGANCRUELTY: CRUELTYFREECERTIFIER_CRUELTYFREEANDVEGANCRUELTY,
  CRUELTYFREECERTIFIER_CRUELTYFREECERTIFIER: CRUELTYFREECERTIFIER_CRUELTYFREECERTIFIER,
  CRUELTYFREECERTIFIER_PETA: CRUELTYFREECERTIFIER_PETA,
  CRUELTYFREECERTIFIER_SPCACERTIFIED: CRUELTYFREECERTIFIER_SPCACERTIFIED,
  DIABETICFRIENDLYCERTIFIER_GLYCEMICRESEARCHINSTITUTEDIABETICFRIENDLY: DIABETICFRIENDLYCERTIFIER_GLYCEMICRESEARCHINSTITUTEDIABETICFRIENDLY,
  ECOFISHERYCERTIFICATION_ASCCERTIFIED: ECOFISHERYCERTIFICATION_ASCCERTIFIED,
  ECOFISHERYCERTIFICATION_BESTAQUACULTUREPRACTICESCERTIFIED: ECOFISHERYCERTIFICATION_BESTAQUACULTUREPRACTICESCERTIFIED,
  ECOFISHERYCERTIFICATION_FRIENDOFTHESEA: ECOFISHERYCERTIFICATION_FRIENDOFTHESEA,
  ECOFISHERYCERTIFICATION_MARINESTEWARDSHIPCOUNCIL: ECOFISHERYCERTIFICATION_MARINESTEWARDSHIPCOUNCIL,
  ECOFISHERYCERTIFICATION_QUALITYECOAPPROVEDORIGINIRELANDCERTIFICATION: ECOFISHERYCERTIFICATION_QUALITYECOAPPROVEDORIGINIRELANDCERTIFICATION,
  FAIRTRADECERTIFIER_FAIRFORLIFE: FAIRTRADECERTIFIER_FAIRFORLIFE,
  FAIRTRADECERTIFIER_FAIRTRADECERTIFIED: FAIRTRADECERTIFIER_FAIRTRADECERTIFIED,
  FAIRTRADECERTIFIER_FAIRTRADEINGREDIENTS: FAIRTRADECERTIFIER_FAIRTRADEINGREDIENTS,
  FAIRTRADECERTIFIER_FAIRTRADEINTERNATIONAL: FAIRTRADECERTIFIER_FAIRTRADEINTERNATIONAL,
  FAIRTRADECERTIFIER_FFL: FAIRTRADECERTIFIER_FFL,
  FAIRTRADECERTIFIER_NATURELAND: FAIRTRADECERTIFIER_NATURELAND,
  FOODGRADEPLASTICSCERTIFIER_FOODGRADEPLASTIC: FOODGRADEPLASTICSCERTIFIER_FOODGRADEPLASTIC,
  FORLIFECERTIFIER_FORLIFE: FORLIFECERTIFIER_FORLIFE,
  GLUTENFREECERTIFIER_CELIACSUPPORTASSOCIATION: GLUTENFREECERTIFIER_CELIACSUPPORTASSOCIATION,
  GLUTENFREECERTIFIER_GLUTENFREECERTIFICATIONORGANIZATION: GLUTENFREECERTIFIER_GLUTENFREECERTIFICATIONORGANIZATION,
  GLUTENFREECERTIFIER_GLUTENFREECELIACFRIENDLYCOELIACFRIENDLY: GLUTENFREECERTIFIER_GLUTENFREECELIACFRIENDLYCOELIACFRIENDLY,
  GLUTENFREECERTIFIER_GLUTENGUARD: GLUTENFREECERTIFIER_GLUTENGUARD,
  GLUTENFREECERTIFIER_ICSGLUTENFREE: GLUTENFREECERTIFIER_ICSGLUTENFREE,
  GLUTENFREECERTIFIER_NATIONALFOUNDATIONFORCELIACAWARENESS: GLUTENFREECERTIFIER_NATIONALFOUNDATIONFORCELIACAWARENESS,
  GLUTENFREECERTIFIER_NSFGLUTENFREE: GLUTENFREECERTIFIER_NSFGLUTENFREE,
  GLYCEMICINDEXCERTIFIER_GILABSTESTED: GLYCEMICINDEXCERTIFIER_GILABSTESTED,
  GLYPHOSATEINTRANSITION_GLYPHOSATEINTRANSITION: GLYPHOSATEINTRANSITION_GLYPHOSATEINTRANSITION,
  GLYPHOSATERESIDUEFREE_GLYPHOSATERESIDUEFREE: GLYPHOSATERESIDUEFREE_GLYPHOSATERESIDUEFREE,
  GMPCERTIFIER_NPAGMP: GMPCERTIFIER_NPAGMP,
  GRASSFEDCERTIFIER_AMERICANGRASSFED: GRASSFEDCERTIFIER_AMERICANGRASSFED,
  GRASSFEDCERTIFIER_PCO: GRASSFEDCERTIFIER_PCO,
  HALALCERTIFIER_AHF: HALALCERTIFIER_AHF,
  HALALCERTIFIER_HFCI: HALALCERTIFIER_HFCI,
  HALALCERTIFIER_HFCU: HALALCERTIFIER_HFCU,
  HALALCERTIFIER_HTM: HALALCERTIFIER_HTM,
  HALALCERTIFIER_HTO: HALALCERTIFIER_HTO,
  HALALCERTIFIER_HTOH: HALALCERTIFIER_HTOH,
  HALALCERTIFIER_HTP: HALALCERTIFIER_HTP,
  HALALCERTIFIER_HTZ: HALALCERTIFIER_HTZ,
  HALALCERTIFIER_IFANCA: HALALCERTIFIER_IFANCA,
  HALALCERTIFIER_ISA: HALALCERTIFIER_ISA,
  HALALCERTIFIER_ISNA: HALALCERTIFIER_ISNA,
  HALALCERTIFIER_ISWA: HALALCERTIFIER_ISWA,
  HALALCERTIFIER_MCGH: HALALCERTIFIER_MCGH,
  HEARTHEALTHYCERTIFIER_AMERICANHEARTASSOCIATIONHEARTHEALTHY: HEARTHEALTHYCERTIFIER_AMERICANHEARTASSOCIATIONHEARTHEALTHY,
  HIGHPRESSURECERTIFIEDCERTIFIER_HIGHPRESSURECERTIFIED: HIGHPRESSURECERTIFIEDCERTIFIER_HIGHPRESSURECERTIFIED,
  INTERNATIONALPACKAGEDICEASSOCIATION_INTERNATIONALPACKAGEDICEASSOCIATION: INTERNATIONALPACKAGEDICEASSOCIATION_INTERNATIONALPACKAGEDICEASSOCIATION,
  ITALCERTIFIEDSEALCERTIFIER_ITAL: ITALCERTIFIEDSEALCERTIFIER_ITAL,
  ITALCONSCIOUSSEALCERTIFIER_ITALCONSCIOUSSEAL: ITALCONSCIOUSSEALCERTIFIER_ITALCONSCIOUSSEAL,
  ITALSACRAMENTSEALCERTIFIER_ITALSACRAMENTSEAL: ITALSACRAMENTSEALCERTIFIER_ITALSACRAMENTSEAL,
  KETOCERTIFIER_KETOFOUNDATIONKETOCERTIFIED: KETOCERTIFIER_KETOFOUNDATIONKETOCERTIFIED,
  KETOCERTIFIER_KETOGENICCOM: KETOCERTIFIER_KETOGENICCOM,
  KOSHERCERTIFICATION_ATLANTAKOSHERCOMMISSION: KOSHERCERTIFICATION_ATLANTAKOSHERCOMMISSION,
  KOSHERCERTIFICATION_BEISDINTZEDEKOFTHEEIDAHHACHAREIDISOFJERUSALEM: KOSHERCERTIFICATION_BEISDINTZEDEKOFTHEEIDAHHACHAREIDISOFJERUSALEM,
  KOSHERCERTIFICATION_BLUERIBBONKOSHER: KOSHERCERTIFICATION_BLUERIBBONKOSHER,
  KOSHERCERTIFICATION_BVKVAADHAKASHRUTOFBUFFALO: KOSHERCERTIFICATION_BVKVAADHAKASHRUTOFBUFFALO,
  KOSHERCERTIFICATION_CHABADOFHAWAII: KOSHERCERTIFICATION_CHABADOFHAWAII,
  KOSHERCERTIFICATION_CHICAGORABBINICALCOUNCIL: KOSHERCERTIFICATION_CHICAGORABBINICALCOUNCIL,
  KOSHERCERTIFICATION_CONTACTMANUFACTURER: KOSHERCERTIFICATION_CONTACTMANUFACTURER,
  KOSHERCERTIFICATION_DIAMONDKKASHRUTHSUPERVISIONBYBETHDINOFCONGREGATIONLUBAVITCH: KOSHERCERTIFICATION_DIAMONDKKASHRUTHSUPERVISIONBYBETHDINOFCONGREGATIONLUBAVITCH,
  KOSHERCERTIFICATION_EARTHKOSHER: KOSHERCERTIFICATION_EARTHKOSHER,
  KOSHERCERTIFICATION_FLORIDAKOSHER: KOSHERCERTIFICATION_FLORIDAKOSHER,
  KOSHERCERTIFICATION_GENERICKOSHERK: KOSHERCERTIFICATION_GENERICKOSHERK,
  KOSHERCERTIFICATION_HOUSTONKASHRUTHASSOCIATION: KOSHERCERTIFICATION_HOUSTONKASHRUTHASSOCIATION,
  KOSHERCERTIFICATION_INTERNATIONALKOSHERCOUNCIL: KOSHERCERTIFICATION_INTERNATIONALKOSHERCOUNCIL,
  KOSHERCERTIFICATION_JEWISHCOMMUNITYCOUNCILOFMONTREALVAADHAIR: KOSHERCERTIFICATION_JEWISHCOMMUNITYCOUNCILOFMONTREALVAADHAIR,
  KOSHERCERTIFICATION_KAKOSHERSUPERVISORESENCALIDADKOSHERMEXICO: KOSHERCERTIFICATION_KAKOSHERSUPERVISORESENCALIDADKOSHERMEXICO,
  KOSHERCERTIFICATION_KASHRUTHCOUNCILOFCANADA: KOSHERCERTIFICATION_KASHRUTHCOUNCILOFCANADA,
  KOSHERCERTIFICATION_KEHILLAKOSHEROKKOSHERWESTCOAST: KOSHERCERTIFICATION_KEHILLAKOSHEROKKOSHERWESTCOAST,
  KOSHERCERTIFICATION_KLBDKOSHERCERTIFICATIONKASHRUTDIVISIONOFLONDONBETHDIN: KOSHERCERTIFICATION_KLBDKOSHERCERTIFICATIONKASHRUTDIVISIONOFLONDONBETHDIN,
  KOSHERCERTIFICATION_KOAKOSHER: KOSHERCERTIFICATION_KOAKOSHER,
  KOSHERCERTIFICATION_KOFKKOSHER: KOSHERCERTIFICATION_KOFKKOSHER,
  KOSHERCERTIFICATION_KOLAKOSHERLA: KOSHERCERTIFICATION_KOLAKOSHERLA,
  KOSHERCERTIFICATION_KOSHERAUSTRALIA: KOSHERCERTIFICATION_KOSHERAUSTRALIA,
  KOSHERCERTIFICATION_KOSHERCERTIFICATIONSERVICE: KOSHERCERTIFICATION_KOSHERCERTIFICATIONSERVICE,
  KOSHERCERTIFICATION_KOSHERCHECKFORMERLYBCKOSHER: KOSHERCERTIFICATION_KOSHERCHECKFORMERLYBCKOSHER,
  KOSHERCERTIFICATION_KOSHERKIWILICENSINGAUTHORITYNEWZEALAND: KOSHERCERTIFICATION_KOSHERKIWILICENSINGAUTHORITYNEWZEALAND,
  KOSHERCERTIFICATION_KOSHEROVERSEERSASSOCIATESOFAMERICA: KOSHERCERTIFICATION_KOSHEROVERSEERSASSOCIATESOFAMERICA,
  KOSHERCERTIFICATION_KOSHERSUPERVISIONOFAMERICA: KOSHERCERTIFICATION_KOSHERSUPERVISIONOFAMERICA,
  KOSHERCERTIFICATION_KVHVAADHARABONIMOFNEWENGLAND: KOSHERCERTIFICATION_KVHVAADHARABONIMOFNEWENGLAND,
  KOSHERCERTIFICATION_METROPOLITANKASHRUTHCOUNCILOFMICHIGAN: KOSHERCERTIFICATION_METROPOLITANKASHRUTHCOUNCILOFMICHIGAN,
  KOSHERCERTIFICATION_MKMANCHESTERBETHDIN: KOSHERCERTIFICATION_MKMANCHESTERBETHDIN,
  KOSHERCERTIFICATION_MYSTERY: KOSHERCERTIFICATION_MYSTERY,
  KOSHERCERTIFICATION_NATURALFOODCERTIFIERS: KOSHERCERTIFICATION_NATURALFOODCERTIFIERS,
  KOSHERCERTIFICATION_NERTAMIDKOSHER: KOSHERCERTIFICATION_NERTAMIDKOSHER,
  KOSHERCERTIFICATION_OHIOKKOSHERSUPERVISION: KOSHERCERTIFICATION_OHIOKKOSHERSUPERVISION,
  KOSHERCERTIFICATION_OKKOSHER: KOSHERCERTIFICATION_OKKOSHER,
  KOSHERCERTIFICATION_OREGONKOSHER: KOSHERCERTIFICATION_OREGONKOSHER,
  KOSHERCERTIFICATION_ORTHODOXRABBINICALCOUNCILOFSANFRANCISCO: KOSHERCERTIFICATION_ORTHODOXRABBINICALCOUNCILOFSANFRANCISCO,
  KOSHERCERTIFICATION_ORTHODOXUNIONKOSHER: KOSHERCERTIFICATION_ORTHODOXUNIONKOSHER,
  KOSHERCERTIFICATION_QUALITYKOSHERSUPERVISION: KOSHERCERTIFICATION_QUALITYKOSHERSUPERVISION,
  KOSHERCERTIFICATION_RABBINICALCOUNCILOFCALIFORNIA: KOSHERCERTIFICATION_RABBINICALCOUNCILOFCALIFORNIA,
  KOSHERCERTIFICATION_RABINATODEPERU: KOSHERCERTIFICATION_RABINATODEPERU,
  KOSHERCERTIFICATION_SCROLLKVAADHAKASHRUSOFDENVER: KOSHERCERTIFICATION_SCROLLKVAADHAKASHRUSOFDENVER,
  KOSHERCERTIFICATION_SQUAREKKOSHERSERVICES: KOSHERCERTIFICATION_SQUAREKKOSHERSERVICES,
  KOSHERCERTIFICATION_STARKKOSHER: KOSHERCERTIFICATION_STARKKOSHER,
  KOSHERCERTIFICATION_TABLETKKOSHERSUPERVISION: KOSHERCERTIFICATION_TABLETKKOSHERSUPERVISION,
  KOSHERCERTIFICATION_TRIANGLEKKOSHERSUPERVISION: KOSHERCERTIFICATION_TRIANGLEKKOSHERSUPERVISION,
  KOSHERCERTIFICATION_UMUNITEDMEHADRINKOSHER: KOSHERCERTIFICATION_UMUNITEDMEHADRINKOSHER,
  KOSHERCERTIFICATION_VAADHAKASHRUSOFMONTANA: KOSHERCERTIFICATION_VAADHAKASHRUSOFMONTANA,
  KOSHERCERTIFICATION_VAADHAKASHRUTHOFKANSASCITY: KOSHERCERTIFICATION_VAADHAKASHRUTHOFKANSASCITY,
  KOSHERCERTIFICATION_VAADHARABANIMOFGREATERSEATTLE: KOSHERCERTIFICATION_VAADHARABANIMOFGREATERSEATTLE,
  KOSHERCERTIFICATION_VAADOFNORTHERNCALIFORNIA: KOSHERCERTIFICATION_VAADOFNORTHERNCALIFORNIA,
  KOSHERCERTIFICATION_WHOLEKOSHERSERVICES: KOSHERCERTIFICATION_WHOLEKOSHERSERVICES,
  LIVEANDACTIVECULTURECERTIFIER_LIVEANDACTIVECULTURESCERTIFIEDBYTHENATIONALYOGURTASSOCIATION: LIVEANDACTIVECULTURECERTIFIER_LIVEANDACTIVECULTURESCERTIFIEDBYTHENATIONALYOGURTASSOCIATION,
  LOWGLYCEMICCERTIFIER_CERTIFIED: LOWGLYCEMICCERTIFIER_CERTIFIED,
  LOWGLYCEMICCERTIFIER_CERTIFIEDBYTHEGLYCEMICRESEARCHINSTITUTECERTIFIEDNATUARLBEVERAGE: LOWGLYCEMICCERTIFIER_CERTIFIEDBYTHEGLYCEMICRESEARCHINSTITUTECERTIFIEDNATUARLBEVERAGE,
  LOWGLYCEMICCERTIFIER_GLYCEMICINDEXFOUNDATION: LOWGLYCEMICCERTIFIER_GLYCEMICINDEXFOUNDATION,
  LOWGLYCEMICCERTIFIER_GLYCEMICRESEARCHINSTITUTELOWGLYCEMIC: LOWGLYCEMICCERTIFIER_GLYCEMICRESEARCHINSTITUTELOWGLYCEMIC,
  LOWGLYCEMICCERTIFIER_LOWGLYCENMICINDEXG1HEALTHYOPTIONS: LOWGLYCEMICCERTIFIER_LOWGLYCENMICINDEXG1HEALTHYOPTIONS,
  NATURALPERSONALCARECERTIFIER_NATURALPERSONALCARE: NATURALPERSONALCARECERTIFIER_NATURALPERSONALCARE,
  NEWYORKSTATEGROWNCERTIFIER_NEWYORKSTATEGROWNANDCERTIFIED: NEWYORKSTATEGROWNCERTIFIER_NEWYORKSTATEGROWNANDCERTIFIED,
  NONGMOCERTIFICATIONBYEARTHKOSHER_NONGMOCERTIFICATIONBYEARTHKOSHER: NONGMOCERTIFICATIONBYEARTHKOSHER_NONGMOCERTIFICATIONBYEARTHKOSHER,
  NONGMOCERTIFIER_GMOGUARD: NONGMOCERTIFIER_GMOGUARD,
  NONGMOCERTIFIER_GREENSTARCERTIFIED: NONGMOCERTIFIER_GREENSTARCERTIFIED,
  NONGMOCERTIFIER_IGENNONGMOCERTIFIED: NONGMOCERTIFIER_IGENNONGMOCERTIFIED,
  NONGMOCERTIFIER_NONGMOCERTIFICATIONBYEARTHKOSHER: NONGMOCERTIFIER_NONGMOCERTIFICATIONBYEARTHKOSHER,
  NONGMOCERTIFIER_NONGMOCERTIFIEDBYNSF: NONGMOCERTIFIER_NONGMOCERTIFIEDBYNSF,
  NONGMOCERTIFIER_NONGMOGECERTIFIEDBYNSF: NONGMOCERTIFIER_NONGMOGECERTIFIEDBYNSF,
  NONGMOCERTIFIER_NONGMOPROJECT: NONGMOCERTIFIER_NONGMOPROJECT,
  NONGMOCERTIFIER_NUTRASOURCEIGENCERTIFIED: NONGMOCERTIFIER_NUTRASOURCEIGENCERTIFIED,
  NONGMOCERTIFIER_NUTRASOURCEIGENTESTED: NONGMOCERTIFIER_NUTRASOURCEIGENTESTED,
  NONGMOGECERTIFIEDBYNSF_NONGMOCERTIFIEDBYNSF: NONGMOGECERTIFIEDBYNSF_NONGMOCERTIFIEDBYNSF,
  NONGMOGECERTIFIEDBYNSF_NONGMOGECERTIFIEDBYNSF: NONGMOGECERTIFIEDBYNSF_NONGMOGECERTIFIEDBYNSF,
  NPACERTIFIER_NATURALPRODUCTSASSOCIATIONCERTIFIED: NPACERTIFIER_NATURALPRODUCTSASSOCIATIONCERTIFIED,
  ONEPERCENTFORTHEPLANETCERTIFIER_1FORTHEPLANET: ONEPERCENTFORTHEPLANETCERTIFIER_1FORTHEPLANET,
  ORGANICCERTIFYINGBODY_1FORTHEPLANET: ORGANICCERTIFYINGBODY_1FORTHEPLANET,
  ORGANICCERTIFYINGBODY_ABAGRICULTUREBIOLOGIQUE: ORGANICCERTIFYINGBODY_ABAGRICULTUREBIOLOGIQUE,
  ORGANICCERTIFYINGBODY_ABOABEEORGANIC: ORGANICCERTIFYINGBODY_ABOABEEORGANIC,
  ORGANICCERTIFYINGBODY_ABO: ORGANICCERTIFYINGBODY_ABO,
  ORGANICCERTIFYINGBODY_ACOAUSTRALIANCERTIFIEDORGANIC: ORGANICCERTIFYINGBODY_ACOAUSTRALIANCERTIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_AGRICULTUREBIOLOGIQUE: ORGANICCERTIFYINGBODY_AGRICULTUREBIOLOGIQUE,
  ORGANICCERTIFYINGBODY_AIAMERICERTINTERNATIONAL: ORGANICCERTIFYINGBODY_AIAMERICERTINTERNATIONAL,
  ORGANICCERTIFYINGBODY_AI: ORGANICCERTIFYINGBODY_AI,
  ORGANICCERTIFYINGBODY_AMERICERTFARMGAP: ORGANICCERTIFYINGBODY_AMERICERTFARMGAP,
  ORGANICCERTIFYINGBODY_ARGARGENCERTSA: ORGANICCERTIFYINGBODY_ARGARGENCERTSA,
  ORGANICCERTIFYINGBODY_ASCOAGRICULTURALSERVICESCERTIFIEDORGANIC: ORGANICCERTIFYINGBODY_ASCOAGRICULTURALSERVICESCERTIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_ASCO: ORGANICCERTIFYINGBODY_ASCO,
  ORGANICCERTIFYINGBODY_AUSQUALAUSQUALPTYLTD: ORGANICCERTIFYINGBODY_AUSQUALAUSQUALPTYLTD,
  ORGANICCERTIFYINGBODY_BACBIOAGRICERT: ORGANICCERTIFYINGBODY_BACBIOAGRICERT,
  ORGANICCERTIFYINGBODY_BCSKIWAOKOGARANTIEGMBH: ORGANICCERTIFYINGBODY_BCSKIWAOKOGARANTIEGMBH,
  ORGANICCERTIFYINGBODY_BIOHBIOHELLASSA: ORGANICCERTIFYINGBODY_BIOHBIOHELLASSA,
  ORGANICCERTIFYINGBODY_BIOIBIOINSPECTA: ORGANICCERTIFYINGBODY_BIOIBIOINSPECTA,
  ORGANICCERTIFYINGBODY_BIOLBIOLATINA: ORGANICCERTIFYINGBODY_BIOLBIOLATINA,
  ORGANICCERTIFYINGBODY_BOCBAYSTATEORGANICCERTIFIERS: ORGANICCERTIFYINGBODY_BOCBAYSTATEORGANICCERTIFIERS,
  ORGANICCERTIFYINGBODY_BOC: ORGANICCERTIFYINGBODY_BOC,
  ORGANICCERTIFYINGBODY_CAAECERTIFICATIONSERVICE: ORGANICCERTIFYINGBODY_CAAECERTIFICATIONSERVICE,
  ORGANICCERTIFYINGBODY_CANADAORGANIC: ORGANICCERTIFYINGBODY_CANADAORGANIC,
  ORGANICCERTIFYINGBODY_CCOFCERTIFICATIONSERVICESLLC: ORGANICCERTIFYINGBODY_CCOFCERTIFICATIONSERVICESLLC,
  ORGANICCERTIFYINGBODY_CCOF: ORGANICCERTIFYINGBODY_CCOF,
  ORGANICCERTIFYINGBODY_CCPBSRL: ORGANICCERTIFYINGBODY_CCPBSRL,
  ORGANICCERTIFYINGBODY_CDACOLORADODEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_CDACOLORADODEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_CDA: ORGANICCERTIFYINGBODY_CDA,
  ORGANICCERTIFYINGBODY_CERESCERTIFICATIONOFENVIRONMENTALSTANDARDSGMBH: ORGANICCERTIFYINGBODY_CERESCERTIFICATIONOFENVIRONMENTALSTANDARDSGMBH,
  ORGANICCERTIFYINGBODY_CMEXCERTIFICADORAMEXICANADEPRODUCTOSYPROCESOSECOLOGICOSSC: ORGANICCERTIFYINGBODY_CMEXCERTIFICADORAMEXICANADEPRODUCTOSYPROCESOSECOLOGICOSSC,
  ORGANICCERTIFYINGBODY_CSYSCERTISYS: ORGANICCERTIFYINGBODY_CSYSCERTISYS,
  ORGANICCERTIFYINGBODY_CUCLEMSONUNIVERSITY: ORGANICCERTIFYINGBODY_CUCLEMSONUNIVERSITY,
  ORGANICCERTIFYINGBODY_CUCCONTROLUNIONCERTIFICATIONS: ORGANICCERTIFYINGBODY_CUCCONTROLUNIONCERTIFICATIONS,
  ORGANICCERTIFYINGBODY_DPI: ORGANICCERTIFYINGBODY_DPI,
  ORGANICCERTIFYINGBODY_ECOCERTECOICOECOCERT: ORGANICCERTIFYINGBODY_ECOCERTECOICOECOCERT,
  ORGANICCERTIFYINGBODY_ECOCERT: ORGANICCERTIFYINGBODY_ECOCERT,
  ORGANICCERTIFYINGBODY_EGLOECOGLOBELLC: ORGANICCERTIFYINGBODY_EGLOECOGLOBELLC,
  ORGANICCERTIFYINGBODY_EKOAGROSEKOAGROS: ORGANICCERTIFYINGBODY_EKOAGROSEKOAGROS,
  ORGANICCERTIFYINGBODY_ETKOECOLOGICALFARMINGCONTROLORGANIZATION: ORGANICCERTIFYINGBODY_ETKOECOLOGICALFARMINGCONTROLORGANIZATION,
  ORGANICCERTIFYINGBODY_EUOCEUROPEANUNIONORGANICCOMMISSION: ORGANICCERTIFYINGBODY_EUOCEUROPEANUNIONORGANICCOMMISSION,
  ORGANICCERTIFYINGBODY_EUOC: ORGANICCERTIFYINGBODY_EUOC,
  ORGANICCERTIFYINGBODY_FARMVERIFIED: ORGANICCERTIFYINGBODY_FARMVERIFIED,
  ORGANICCERTIFYINGBODY_FSFOODSAFETYSA: ORGANICCERTIFYINGBODY_FSFOODSAFETYSA,
  ORGANICCERTIFYINGBODY_FVOFARMVERIFIEDORGANIC: ORGANICCERTIFYINGBODY_FVOFARMVERIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_GCIA: ORGANICCERTIFYINGBODY_GCIA,
  ORGANICCERTIFYINGBODY_GCIANOPGEORGIACROPIMPROVEMENTASSOCIATION: ORGANICCERTIFYINGBODY_GCIANOPGEORGIACROPIMPROVEMENTASSOCIATION,
  ORGANICCERTIFYINGBODY_GLOGLOBALCULTURE: ORGANICCERTIFYINGBODY_GLOGLOBALCULTURE,
  ORGANICCERTIFYINGBODY_GLOBALCULTURECERTIFIEDORGANIC: ORGANICCERTIFYINGBODY_GLOBALCULTURECERTIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_GOAGLOBALORGANICALLIANCEINC: ORGANICCERTIFYINGBODY_GOAGLOBALORGANICALLIANCEINC,
  ORGANICCERTIFYINGBODY_GOTSGLOBALORGANICTEXTILESTANDARD: ORGANICCERTIFYINGBODY_GOTSGLOBALORGANICTEXTILESTANDARD,
  ORGANICCERTIFYINGBODY_GOTS: ORGANICCERTIFYINGBODY_GOTS,
  ORGANICCERTIFYINGBODY_IBDCERTIFICATIONS: ORGANICCERTIFYINGBODY_IBDCERTIFICATIONS,
  ORGANICCERTIFYINGBODY_ICEAISTITUTOPERLACERTIFICAZIONEETICAEAMBIENTALE: ORGANICCERTIFYINGBODY_ICEAISTITUTOPERLACERTIFICAZIONEETICAEAMBIENTALE,
  ORGANICCERTIFYINGBODY_ICO: ORGANICCERTIFYINGBODY_ICO,
  ORGANICCERTIFYINGBODY_ICSINTERNATIONALCERTIFICATIONSERVICESINC: ORGANICCERTIFYINGBODY_ICSINTERNATIONALCERTIFICATIONSERVICESINC,
  ORGANICCERTIFYINGBODY_ICS: ORGANICCERTIFYINGBODY_ICS,
  ORGANICCERTIFYINGBODY_IDALSIOWADEPARTMENTOFAGRICULTUREANDLANDSTEWARDSHIP: ORGANICCERTIFYINGBODY_IDALSIOWADEPARTMENTOFAGRICULTUREANDLANDSTEWARDSHIP,
  ORGANICCERTIFYINGBODY_IDDA: ORGANICCERTIFYINGBODY_IDDA,
  ORGANICCERTIFYINGBODY_IDOA: ORGANICCERTIFYINGBODY_IDOA,
  ORGANICCERTIFYINGBODY_IDOALS: ORGANICCERTIFYINGBODY_IDOALS,
  ORGANICCERTIFYINGBODY_IMOCIMOCERTLATINOAMERICALTDA: ORGANICCERTIFYINGBODY_IMOCIMOCERTLATINOAMERICALTDA,
  ORGANICCERTIFYINGBODY_IODA: ORGANICCERTIFYINGBODY_IODA,
  ORGANICCERTIFYINGBODY_ISDAIDAHOSTATEDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_ISDAIDAHOSTATEDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_KDAKENTUCKYDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_KDAKENTUCKYDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_LACONGMBH: ORGANICCERTIFYINGBODY_LACONGMBH,
  ORGANICCERTIFYINGBODY_LETISSA: ORGANICCERTIFYINGBODY_LETISSA,
  ORGANICCERTIFYINGBODY_LOGIECOLOGICASA: ORGANICCERTIFYINGBODY_LOGIECOLOGICASA,
  ORGANICCERTIFYINGBODY_MAYAMAYACERTSA: ORGANICCERTIFYINGBODY_MAYAMAYACERTSA,
  ORGANICCERTIFYINGBODY_MCCOMONTEREYCOUNTYCERTIFIEDORGANICS: ORGANICCERTIFYINGBODY_MCCOMONTEREYCOUNTYCERTIFIEDORGANICS,
  ORGANICCERTIFYINGBODY_MCCO: ORGANICCERTIFYINGBODY_MCCO,
  ORGANICCERTIFYINGBODY_MCIAMINNESOTACROPIMPROVEMENTASSOCIATION: ORGANICCERTIFYINGBODY_MCIAMINNESOTACROPIMPROVEMENTASSOCIATION,
  ORGANICCERTIFYINGBODY_MCSMOFGACERTIFICATIONSERVICES: ORGANICCERTIFYINGBODY_MCSMOFGACERTIFICATIONSERVICES,
  ORGANICCERTIFYINGBODY_MDAMARYLANDDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_MDAMARYLANDDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_MOCAMARINORGANICCERTIFIEDAGRICULTURE: ORGANICCERTIFYINGBODY_MOCAMARINORGANICCERTIFIEDAGRICULTURE,
  ORGANICCERTIFYINGBODY_MOFGA: ORGANICCERTIFYINGBODY_MOFGA,
  ORGANICCERTIFYINGBODY_MOSAMIDWESTORGANICSERVICESASSOCIATIONINC: ORGANICCERTIFYINGBODY_MOSAMIDWESTORGANICSERVICESASSOCIATIONINC,
  ORGANICCERTIFYINGBODY_MOSA: ORGANICCERTIFYINGBODY_MOSA,
  ORGANICCERTIFYINGBODY_MTDAMONTANADEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_MTDAMONTANADEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_NCONASAACERTIFIEDORGANIC: ORGANICCERTIFYINGBODY_NCONASAACERTIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_NFCNATURALFOODCERTIFIERS: ORGANICCERTIFYINGBODY_NFCNATURALFOODCERTIFIERS,
  ORGANICCERTIFYINGBODY_NFC: ORGANICCERTIFYINGBODY_NFC,
  ORGANICCERTIFYINGBODY_NHDAMFNEWHAMPSHIREDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_NHDAMFNEWHAMPSHIREDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_NICSNATURESINTERNATIONALCERTIFICATIONSERVICES: ORGANICCERTIFYINGBODY_NICSNATURESINTERNATIONALCERTIFICATIONSERVICES,
  ORGANICCERTIFYINGBODY_NJDANEWJERSEYDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_NJDANEWJERSEYDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_NJDA: ORGANICCERTIFYINGBODY_NJDA,
  ORGANICCERTIFYINGBODY_NMDANEWMEXICODEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_NMDANEWMEXICODEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_NOFANYNOFANYCERTIFIEDORGANIC: ORGANICCERTIFYINGBODY_NOFANYNOFANYCERTIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_NOFANY: ORGANICCERTIFYINGBODY_NOFANY,
  ORGANICCERTIFYINGBODY_NSFINTERNATIONAL: ORGANICCERTIFYINGBODY_NSFINTERNATIONAL,
  ORGANICCERTIFYINGBODY_NSF: ORGANICCERTIFYINGBODY_NSF,
  ORGANICCERTIFYINGBODY_NSO: ORGANICCERTIFYINGBODY_NSO,
  ORGANICCERTIFYINGBODY_OCORGANICCERTIFIERSINC: ORGANICCERTIFYINGBODY_OCORGANICCERTIFIERSINC,
  ORGANICCERTIFYINGBODY_OCIAORGANICCROPIMPROVEMENTASSOCIATION: ORGANICCERTIFYINGBODY_OCIAORGANICCROPIMPROVEMENTASSOCIATION,
  ORGANICCERTIFYINGBODY_OCIA: ORGANICCERTIFYINGBODY_OCIA,
  ORGANICCERTIFYINGBODY_ODAOREGONDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_ODAOREGONDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_ODAFFOKLAHOMADEPTOFAGRICULTUREFOODANDFORESTRY: ORGANICCERTIFYINGBODY_ODAFFOKLAHOMADEPTOFAGRICULTUREFOODANDFORESTRY,
  ORGANICCERTIFYINGBODY_OEFFAOHIOECOLOGICALFOODANDFARMASSOCIATION: ORGANICCERTIFYINGBODY_OEFFAOHIOECOLOGICALFOODANDFARMASSOCIATION,
  ORGANICCERTIFYINGBODY_OFCORGANICFEDERATIONOFCANADA: ORGANICCERTIFYINGBODY_OFCORGANICFEDERATIONOFCANADA,
  ORGANICCERTIFYINGBODY_OFDCORGANICFOODDEVELOPMENTANDCERTIFICATIONCENTREOFCHINA: ORGANICCERTIFYINGBODY_OFDCORGANICFOODDEVELOPMENTANDCERTIFICATIONCENTREOFCHINA,
  ORGANICCERTIFYINGBODY_OFFORGANICFOODFEDERATION: ORGANICCERTIFYINGBODY_OFFORGANICFOODFEDERATION,
  ORGANICCERTIFYINGBODY_OFF: ORGANICCERTIFYINGBODY_OFF,
  ORGANICCERTIFYINGBODY_OIAORGANIZACIONINTERNACIONALAGROPECUARIA: ORGANICCERTIFYINGBODY_OIAORGANIZACIONINTERNACIONALAGROPECUARIA,
  ORGANICCERTIFYINGBODY_OMICOVERSEASMERCHANDISEINSPECTIONCOLTD: ORGANICCERTIFYINGBODY_OMICOVERSEASMERCHANDISEINSPECTIONCOLTD,
  ORGANICCERTIFYINGBODY_ONEOCIONECERT: ORGANICCERTIFYINGBODY_ONEOCIONECERT,
  ORGANICCERTIFYINGBODY_ONMONMARKCERTIFICATIONSERVICES: ORGANICCERTIFYINGBODY_ONMONMARKCERTIFICATIONSERVICES,
  ORGANICCERTIFYINGBODY_ORGANICCERTIFIERS: ORGANICCERTIFYINGBODY_ORGANICCERTIFIERS,
  ORGANICCERTIFYINGBODY_OTCOOREGONTILTHCERTIFIEDORGANIC: ORGANICCERTIFYINGBODY_OTCOOREGONTILTHCERTIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_OTCO: ORGANICCERTIFYINGBODY_OTCO,
  ORGANICCERTIFYINGBODY_PACSPACIFICAGRICULTURALCERTIFICATIONSOCIETY: ORGANICCERTIFYINGBODY_PACSPACIFICAGRICULTURALCERTIFICATIONSOCIETY,
  ORGANICCERTIFYINGBODY_PACS: ORGANICCERTIFYINGBODY_PACS,
  ORGANICCERTIFYINGBODY_PCOPENNSYLVANIACERTIFIEDORGANIC: ORGANICCERTIFYINGBODY_PCOPENNSYLVANIACERTIFIEDORGANIC,
  ORGANICCERTIFYINGBODY_PJRPERRYJOHNSONREGISTRARFOODSAFETYINC: ORGANICCERTIFYINGBODY_PJRPERRYJOHNSONREGISTRARFOODSAFETYINC,
  ORGANICCERTIFYINGBODY_PLPRIMUSLABS: ORGANICCERTIFYINGBODY_PLPRIMUSLABS,
  ORGANICCERTIFYINGBODY_PROPROCERTORGANICSYSTEMSLTD: ORGANICCERTIFYINGBODY_PROPROCERTORGANICSYSTEMSLTD,
  ORGANICCERTIFYINGBODY_PROCERT: ORGANICCERTIFYINGBODY_PROCERT,
  ORGANICCERTIFYINGBODY_QAIQUALITYASSURANCEINTERNATIONAL: ORGANICCERTIFYINGBODY_QAIQUALITYASSURANCEINTERNATIONAL,
  ORGANICCERTIFYINGBODY_QAITRANSITIONALCERTIFIED: ORGANICCERTIFYINGBODY_QAITRANSITIONALCERTIFIED,
  ORGANICCERTIFYINGBODY_QAI: ORGANICCERTIFYINGBODY_QAI,
  ORGANICCERTIFYINGBODY_QCSQUALITYCERTIFICATIONSERVICES: ORGANICCERTIFYINGBODY_QCSQUALITYCERTIFICATIONSERVICES,
  ORGANICCERTIFYINGBODY_RIDEMRHODEISLANDDEPARTMENTOFENVIRONMENTALMANAGEMENT: ORGANICCERTIFYINGBODY_RIDEMRHODEISLANDDEPARTMENTOFENVIRONMENTALMANAGEMENT,
  ORGANICCERTIFYINGBODY_SAOSOILASSOCIATION: ORGANICCERTIFYINGBODY_SAOSOILASSOCIATION,
  ORGANICCERTIFYINGBODY_SAO: ORGANICCERTIFYINGBODY_SAO,
  ORGANICCERTIFYINGBODY_SCSGLOBALSERVICESINC: ORGANICCERTIFYINGBODY_SCSGLOBALSERVICESINC,
  ORGANICCERTIFYINGBODY_SPCA: ORGANICCERTIFYINGBODY_SPCA,
  ORGANICCERTIFYINGBODY_STELSTELLARCERTIFICATIONSERVICES: ORGANICCERTIFYINGBODY_STELSTELLARCERTIFICATIONSERVICES,
  ORGANICCERTIFYINGBODY_STEL: ORGANICCERTIFYINGBODY_STEL,
  ORGANICCERTIFYINGBODY_TDATEXASDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_TDATEXASDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_TOCTSEXINORGANICCERTIFICATIONCORPORATION: ORGANICCERTIFYINGBODY_TOCTSEXINORGANICCERTIFICATIONCORPORATION,
  ORGANICCERTIFYINGBODY_TRANSITIONALCERTIFIEDCLAIM: ORGANICCERTIFYINGBODY_TRANSITIONALCERTIFIEDCLAIM,
  ORGANICCERTIFYINGBODY_UDAFUTAHDEPARTMENTOFAGRICULTUREANDFOOD: ORGANICCERTIFYINGBODY_UDAFUTAHDEPARTMENTOFAGRICULTUREANDFOOD,
  ORGANICCERTIFYINGBODY_USDANOPNATIONALORGANICPROGRAM: ORGANICCERTIFYINGBODY_USDANOPNATIONALORGANICPROGRAM,
  ORGANICCERTIFYINGBODY_USDA: ORGANICCERTIFYINGBODY_USDA,
  ORGANICCERTIFYINGBODY_VOCAVEDICORGANICCERTIFICATIONAGENCY: ORGANICCERTIFYINGBODY_VOCAVEDICORGANICCERTIFICATIONAGENCY,
  ORGANICCERTIFYINGBODY_VOCA: ORGANICCERTIFYINGBODY_VOCA,
  ORGANICCERTIFYINGBODY_VOFVERMONTORGANICFARMERSLLC: ORGANICCERTIFYINGBODY_VOFVERMONTORGANICFARMERSLLC,
  ORGANICCERTIFYINGBODY_VOF: ORGANICCERTIFYINGBODY_VOF,
  ORGANICCERTIFYINGBODY_WSDAWASHINGTONSTATEDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_WSDAWASHINGTONSTATEDEPARTMENTOFAGRICULTURE,
  ORGANICCERTIFYINGBODY_WSDA: ORGANICCERTIFYINGBODY_WSDA,
  ORGANICCERTIFYINGBODY_YDAYOLOCOUNTYDEPARTMENTOFAGRICULTURE: ORGANICCERTIFYINGBODY_YDAYOLOCOUNTYDEPARTMENTOFAGRICULTURE,
  ORGANICSTANDARDSAGENCYLOGO_CFIACANADIANFOODINSPECTIONAGENCY: ORGANICSTANDARDSAGENCYLOGO_CFIACANADIANFOODINSPECTIONAGENCY,
  ORGANICSTANDARDSAGENCYLOGO_COSMOSSTANDARDFORNATURALANDORGANICCOSMETICS: ORGANICSTANDARDSAGENCYLOGO_COSMOSSTANDARDFORNATURALANDORGANICCOSMETICS,
  ORGANICSTANDARDSAGENCYLOGO_EUOCEUROPEANUNIONORGANICCOMMISSION: ORGANICSTANDARDSAGENCYLOGO_EUOCEUROPEANUNIONORGANICCOMMISSION,
  ORGANICSTANDARDSAGENCYLOGO_GOTSGLOBALORGANICTEXTILESTANDARD: ORGANICSTANDARDSAGENCYLOGO_GOTSGLOBALORGANICTEXTILESTANDARD,
  ORGANICSTANDARDSAGENCYLOGO_IFOAMINTERNATIONALFEDERATIONOFAGRICULTUREMOVEMENTS: ORGANICSTANDARDSAGENCYLOGO_IFOAMINTERNATIONALFEDERATIONOFAGRICULTUREMOVEMENTS,
  ORGANICSTANDARDSAGENCYLOGO_JASJAPANAGRICULTURALSTANDARDS: ORGANICSTANDARDSAGENCYLOGO_JASJAPANAGRICULTURALSTANDARDS,
  ORGANICSTANDARDSAGENCYLOGO_OCSORGANICCONTENTSTANDARD: ORGANICSTANDARDSAGENCYLOGO_OCSORGANICCONTENTSTANDARD,
  ORGANICSTANDARDSAGENCYLOGO_USDAUNITEDSTATESDEPARTMENTOFAGRICULTURE: ORGANICSTANDARDSAGENCYLOGO_USDAUNITEDSTATESDEPARTMENTOFAGRICULTURE,
  PACACERTIFIER_USDAPACA: PACACERTIFIER_USDAPACA,
  PALEOCERTIFIER_CERTIFIEDPALEO: PALEOCERTIFIER_CERTIFIEDPALEO,
  PALEOCERTIFIER_PALEOAPPROVEDBYTHEPALEOFOUNDATION: PALEOCERTIFIER_PALEOAPPROVEDBYTHEPALEOFOUNDATION,
  PALEOCERTIFIER_PALEOCERTIFIEDBYEARTHKOSHER: PALEOCERTIFIER_PALEOCERTIFIEDBYEARTHKOSHER,
  PALEOCERTIFIER_PALEOFRIENDLYBYTHEPALEOFOUNDATION: PALEOCERTIFIER_PALEOFRIENDLYBYTHEPALEOFOUNDATION,
  PALEOCERTIFIER_PALEOFRIENDLYTHISPRODUCTISCERTIFIEO: PALEOCERTIFIER_PALEOFRIENDLYTHISPRODUCTISCERTIFIEO,
  PALEOCERTIFIER_PALEOVEGANCERTIFIEDBYPALEOFOUNDATION: PALEOCERTIFIER_PALEOVEGANCERTIFIEDBYPALEOFOUNDATION,
  PASACERTIFIER_PENNSYLVANIAASSOCIATIONFORSUSTAINABLEAGRICULTURE: PASACERTIFIER_PENNSYLVANIAASSOCIATIONFORSUSTAINABLEAGRICULTURE,
  PLANTBASEDDERIVEDCERTIFIER_NSFPLANTBASED: PLANTBASEDDERIVEDCERTIFIER_NSFPLANTBASED,
  PLANTBASEDDERIVEDCERTIFIER_WFPB: PLANTBASEDDERIVEDCERTIFIER_WFPB,
  RAINFORESTALLIANCECERTIFIER_RAINFORESTALLIANCE: RAINFORESTALLIANCECERTIFIER_RAINFORESTALLIANCE,
  REALCERTIFIED_RESPONSIBLEEPICUREANANDAGRICULTURALLEADERSHIPCERTIFIER: REALCERTIFIED_RESPONSIBLEEPICUREANANDAGRICULTURALLEADERSHIPCERTIFIER,
  REALSEALCERTIFIER_REAL: REALSEALCERTIFIER_REAL,
  RECYCLEDMATERIALTYPE_1PET: RECYCLEDMATERIALTYPE_1PET,
  RECYCLEDMATERIALTYPE_1PETE: RECYCLEDMATERIALTYPE_1PETE,
  RECYCLEDMATERIALTYPE_100RECYCLEDPAPERBOARD: RECYCLEDMATERIALTYPE_100RECYCLEDPAPERBOARD,
  RECYCLEDMATERIALTYPE_100RPB: RECYCLEDMATERIALTYPE_100RPB,
  RECYCLEDMATERIALTYPE_2HDPE: RECYCLEDMATERIALTYPE_2HDPE,
  RECYCLEDMATERIALTYPE_3PVC: RECYCLEDMATERIALTYPE_3PVC,
  RECYCLEDMATERIALTYPE_4LDPE: RECYCLEDMATERIALTYPE_4LDPE,
  RECYCLEDMATERIALTYPE_5PP: RECYCLEDMATERIALTYPE_5PP,
  RECYCLEDMATERIALTYPE_6PS: RECYCLEDMATERIALTYPE_6PS,
  RECYCLEDMATERIALTYPE_7OTHER: RECYCLEDMATERIALTYPE_7OTHER,
  RECYCLEDMATERIALTYPE_CIRCULARRECYCLINGSYMBOL: RECYCLEDMATERIALTYPE_CIRCULARRECYCLINGSYMBOL,
  RECYCLEDMATERIALTYPE_CORRUGATEDBOARD: RECYCLEDMATERIALTYPE_CORRUGATEDBOARD,
  RECYCLEDMATERIALTYPE_CORRUGATEDFIBERBOARD: RECYCLEDMATERIALTYPE_CORRUGATEDFIBERBOARD,
  RECYCLEDMATERIALTYPE_CORRUGATEDRECYCLES: RECYCLEDMATERIALTYPE_CORRUGATEDRECYCLES,
  RECYCLEDMATERIALTYPE_RECYCLABLESTEEL: RECYCLEDMATERIALTYPE_RECYCLABLESTEEL,
  RECYCLEDMATERIALTYPE_STANDARDRECYCLINGSYMBOL: RECYCLEDMATERIALTYPE_STANDARDRECYCLINGSYMBOL,
  RECYCLEDMATERIALTYPE_STEELRECYCLE: RECYCLEDMATERIALTYPE_STEELRECYCLE,
  RECYCLEDMATERIALTYPE_TERRACYCLE: RECYCLEDMATERIALTYPE_TERRACYCLE,
  REGENERATIVEORGANICCERTIFIER_REGENERATIVEORGANICCERTIFIER: REGENERATIVEORGANICCERTIFIER_REGENERATIVEORGANICCERTIFIER,
  RENEWABLEENERGYCERTIFIER_GREENE: RENEWABLEENERGYCERTIFIER_GREENE,
  SOCIALMEDIA_FACEBOOK: SOCIALMEDIA_FACEBOOK,
  SOCIALMEDIA_GOOGLE: SOCIALMEDIA_GOOGLE,
  SOCIALMEDIA_INSTAGRAM: SOCIALMEDIA_INSTAGRAM,
  SOCIALMEDIA_PINTEREST: SOCIALMEDIA_PINTEREST,
  SOCIALMEDIA_SNAPCHAT: SOCIALMEDIA_SNAPCHAT,
  SOCIALMEDIA_TWITTER: SOCIALMEDIA_TWITTER,
  SOCIALMEDIA_YOUTUBE: SOCIALMEDIA_YOUTUBE,
  SOCIALMEDIA_YOUTUBE2: SOCIALMEDIA_YOUTUBE2,
  SOCIALMEDIA_YOUTUBE3: SOCIALMEDIA_YOUTUBE3,
  SUSTAINABILITYCERTIFIER_GREENSEAL: SUSTAINABILITYCERTIFIER_GREENSEAL,
  SUSTAINABILITYCERTIFIER_RSPO: SUSTAINABILITYCERTIFIER_RSPO,
  SUSTAINABILITYCERTIFIER_SIPCERTIFIED: SUSTAINABILITYCERTIFIER_SIPCERTIFIED,
  SUSTAINABILITYCERTIFIER_UTZCERTIFIED: SUSTAINABILITYCERTIFIER_UTZCERTIFIED,
  SUSTAINABILITYCERTIFIER_VERIFLORA: SUSTAINABILITYCERTIFIER_VERIFLORA,
  VEGANCERTIFIER_AMERICANVEGETARIANASSOCIATIONVEGAN: VEGANCERTIFIER_AMERICANVEGETARIANASSOCIATIONVEGAN,
  VEGANCERTIFIER_BIOAGRICERTVEGAN: VEGANCERTIFIER_BIOAGRICERTVEGAN,
  VEGANCERTIFIER_EARTHKOSHERVEGANVERIFIED: VEGANCERTIFIER_EARTHKOSHERVEGANVERIFIED,
  VEGANCERTIFIER_NFCNATURALFOODCERTIFIERS: VEGANCERTIFIER_NFCNATURALFOODCERTIFIERS,
  VEGANCERTIFIER_PALEOFOUNDATIONPALEOVEGAN: VEGANCERTIFIER_PALEOFOUNDATIONPALEOVEGAN,
  VEGANCERTIFIER_PETAAPPROVEDVEGAN: VEGANCERTIFIER_PETAAPPROVEDVEGAN,
  VEGANCERTIFIER_PETACRUELTYFREEANDVEGAN: VEGANCERTIFIER_PETACRUELTYFREEANDVEGAN,
  VEGANCERTIFIER_VEGANOK: VEGANCERTIFIER_VEGANOK,
  VEGANCERTIFIER_VEGANSOCIETY: VEGANCERTIFIER_VEGANSOCIETY,
  VEGANCERTIFIER_VEGANORG: VEGANCERTIFIER_VEGANORG,
  VEGANCERTIFIER_VEGETARIANSOCIETYVEGANAPPROVED: VEGANCERTIFIER_VEGETARIANSOCIETYVEGANAPPROVED,
  VEGETARIANCERTIFIER_AMERICANVEGETARIANASSOCIATIONVEGETARIAN: VEGETARIANCERTIFIER_AMERICANVEGETARIANASSOCIATIONVEGETARIAN,
  VEGETARIANCERTIFIER_VEGECERT: VEGETARIANCERTIFIER_VEGECERT,
  VEGETARIANCERTIFIER_VEGETARIANSOCIETY: VEGETARIANCERTIFIER_VEGETARIANSOCIETY,
  VETERANSCERTIFIER_HOMEGROWNBYHEROES: VETERANSCERTIFIER_HOMEGROWNBYHEROES,
  VETERANSCERTIFIER_WOUNDEDWARRIOR: VETERANSCERTIFIER_WOUNDEDWARRIOR,
  VITICULTURECERTIFIER_BIODYVINSYNDICATINTERNATIONALDESVIGNERONSENCULTUREBIODYNAMIQUESIVCBD: VITICULTURECERTIFIER_BIODYVINSYNDICATINTERNATIONALDESVIGNERONSENCULTUREBIODYNAMIQUESIVCBD,
  VITICULTURECERTIFIER_BIODYVIN: VITICULTURECERTIFIER_BIODYVIN,
  VITICULTURECERTIFIER_CALIFORNIASUSTAINABLEWINEGROWINGALLIANCE: VITICULTURECERTIFIER_CALIFORNIASUSTAINABLEWINEGROWINGALLIANCE,
  VITICULTURECERTIFIER_CANADIANVINTNERSASSOCIATION: VITICULTURECERTIFIER_CANADIANVINTNERSASSOCIATION,
  VITICULTURECERTIFIER_DELINATBIOGARANTIE: VITICULTURECERTIFIER_DELINATBIOGARANTIE,
  VITICULTURECERTIFIER_DEUTSCHESGUTENBANDWEINDLG: VITICULTURECERTIFIER_DEUTSCHESGUTENBANDWEINDLG,
  VITICULTURECERTIFIER_IPWSOUTHAFRICASOUTHAFRICASINTEGRATEDPRODUCTIONOFWINESYSTEMS: VITICULTURECERTIFIER_IPWSOUTHAFRICASOUTHAFRICASINTEGRATEDPRODUCTIONOFWINESYSTEMS,
  // VITICULTURECERTIFIER_IPWSOUTHAFRICASOUTHAFRICASINTEGRATEDPRODUCTIONOFWINESYSTEMS: VITICULTURECERTIFIER_IPWSOUTHAFRICASOUTHAFRICASINTEGRATEDPRODUCTIONOFWINESYSTEMS,
  VITICULTURECERTIFIER_IPW: VITICULTURECERTIFIER_IPW,
  VITICULTURECERTIFIER_LIVELOWINPUTVITICULTUREANDENOLOGYOROREGONLIVE: VITICULTURECERTIFIER_LIVELOWINPUTVITICULTUREANDENOLOGYOROREGONLIVE,
  VITICULTURECERTIFIER_LODIRULESSUSTAINABLEWINEGROWING: VITICULTURECERTIFIER_LODIRULESSUSTAINABLEWINEGROWING,
  VITICULTURECERTIFIER_LONGISLANDSUSTAINABLEWINEGROWING: VITICULTURECERTIFIER_LONGISLANDSUSTAINABLEWINEGROWING,
  VITICULTURECERTIFIER_NAPAGREENWINERY: VITICULTURECERTIFIER_NAPAGREENWINERY,
  VITICULTURECERTIFIER_NAPAGREEN: VITICULTURECERTIFIER_NAPAGREEN,
  VITICULTURECERTIFIER_SUSTAINABLEWINEGROWINGNEWZEALAND: VITICULTURECERTIFIER_SUSTAINABLEWINEGROWINGNEWZEALAND,
  VITICULTURECERTIFIER_VINEBALANCESUSTAINABLEVITICULTUREINTHENORTHEAST: VITICULTURECERTIFIER_VINEBALANCESUSTAINABLEVITICULTUREINTHENORTHEAST,
  VITICULTURECERTIFIER_VINEWISEWASHINGTONGUIDETOSUSTAINABLEVITICULTURE: VITICULTURECERTIFIER_VINEWISEWASHINGTONGUIDETOSUSTAINABLEVITICULTURE,
  VITICULTURECERTIFIER_VINTNERSQUALITYALLIANCE: VITICULTURECERTIFIER_VINTNERSQUALITYALLIANCE,
  VITICULTURECERTIFIER_WINECOUNCILOFONTARIO: VITICULTURECERTIFIER_WINECOUNCILOFONTARIO,
  WATERQUALITYASSOCIATIONCERTIFIER_WQA: WATERQUALITYASSOCIATIONCERTIFIER_WQA,
  WHOLEFOODSMARKETPREMIUMBODYCARE_WHOLEFOODSMARKETPREMIUMBODYCARE: WHOLEFOODSMARKETPREMIUMBODYCARE_WHOLEFOODSMARKETPREMIUMBODYCARE,
  WHOLEFOODSMARKETRESPONSIBLYFARMED3RDPARTYVERIFIED_WHOLEFOODSMARKETRESPONSIBLYFARMED3RDPARTYVERIFIED: WHOLEFOODSMARKETRESPONSIBLYFARMED3RDPARTYVERIFIED_WHOLEFOODSMARKETRESPONSIBLYFARMED3RDPARTYVERIFIED,
  WHOLEFOODSSOURCEDFORGOOD_WHOLEFOODSSOURCEDFORGOOD: WHOLEFOODSSOURCEDFORGOOD_WHOLEFOODSSOURCEDFORGOOD,
  WHOLEFOODSWHOLETRADEGUARANTEECERTIFIED_WTG: WHOLEFOODSWHOLETRADEGUARANTEECERTIFIED_WTG,
  WHOLEFOODSWHOLETRADEGUARANTEECERTIFIER_WTG: WHOLEFOODSWHOLETRADEGUARANTEECERTIFIER_WTG,
  WHOLEGRAINCERTIFIER_WHOLEGRAINSCOUNCIL: WHOLEGRAINCERTIFIER_WHOLEGRAINSCOUNCIL,
  WOMENSBUSINESSENTERPRISENATIONALCOUNCILCERTIFIER_WBENC: WOMENSBUSINESSENTERPRISENATIONALCOUNCILCERTIFIER_WBENC,
  WOMENSBUSINESSENTERPRISENATIONALCOUNCILCERTIFIER_WOMENOWNED: WOMENSBUSINESSENTERPRISENATIONALCOUNCILCERTIFIER_WOMENOWNED,
  REGENERATIVEAGRICULTURECERTIFIER_LANDTOMARKET: REGENERATIVEAGRICULTURECERTIFIER_LANDTOMARKET,
};

// This list define certification type = boolean (base on api)
const CERTIFICATION_IMAGES_TYPE_BOOLEAN = {
  CERTIFIEDAUTHENTICMADEPRODUCEDBYAMERICANINDIANS: AUTHENTICMADEPRODUCEDBYAMERICANINDIANSCERTIFIER_CMBAI,
  CERTIFIEDBCORPORATION: CERTIFIEDBCORPORATION_CERTIFIEDBCORPORATION,
  COLDPRESSUREDPROTECTEDCLAIM: Images.RIVIR_LOGO_DEFAULT, // because it doesn't have images
  COLDPRESSUREVERIFIED: Images.RIVIR_LOGO_DEFAULT, // because it doesn't have images
  GLYPHOSATEINTRANSITIONCLAIM: Images.RIVIR_LOGO_DEFAULT, // because it doesn't have images
  INTERNATIONALPACKAGEDICEASSOCIATION: INTERNATIONALPACKAGEDICEASSOCIATION_INTERNATIONALPACKAGEDICEASSOCIATION,
};

export { CERTIFICATION_IMAGES, CERTIFICATION_IMAGES_TYPE_BOOLEAN };
