import React from 'react';

import { Button, Col, Divider, Row } from 'antd';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from '@ant-design/icons';
import classnames from 'classnames';

import { DisplayName } from './QueryDraggable';
import { UPDATE_COLUMNS } from '../utils/constans';

import './ColumnDraggable.less';
import { CustomNotification } from 'common/components';

const ColumnDraggable = (props) => {
  const {
    index,
    property,
    viewMode,
    dataColumns,
    selectTreeData,
    handleUpdateDataColumns,
  } = props;

  const onChange = (fieldName) => {
    if (dataColumns.includes(fieldName)) {
      CustomNotification.warning('Column already exists');
    } else {
      handleUpdateDataColumns({
        fieldName,
        type: UPDATE_COLUMNS.UPDATE,
        index,
      });
    }
  };

  const reorderColumn = (type) => {
    handleUpdateDataColumns({
      fieldName: property?.fieldName,
      type,
      index,
    });
  };
  return (
    <Row
      gutter={4}
      align='middle'
      className={classnames({
        'property-item': true,
      })}
      style={{ position: 'relative', width: '100%' }}
    >
      <Col span={20} style={{ display: 'flex' }}>
        <DisplayName
          text='Select column'
          onChange={onChange}
          property={property}
          selectTreeData={selectTreeData}
        />
      </Col>
      {!viewMode && (
        <Col
          span={4}
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={index === 0}
            onClick={() => {
              reorderColumn(UPDATE_COLUMNS.UP);
            }}
            type='text'
            icon={<UpCircleOutlined />}
          />
          <Button
            disabled={index === dataColumns?.length - 1}
            type='text'
            icon={<DownCircleOutlined />}
            onClick={() => {
              reorderColumn(UPDATE_COLUMNS.DOWN);
            }}
          />
          <Divider
            type='vertical'
            style={{
              margin: '0 2px',
              borderLeft: '1px solid rgba(0, 0, 0, .5)',
            }}
          />
          <Button
            onClick={() => {
              handleUpdateDataColumns({ index, type: UPDATE_COLUMNS.ADD });
            }}
            type='text'
            icon={<PlusCircleOutlined />}
          />
          <Button
            type='text'
            icon={<MinusCircleOutlined />}
            onClick={() => {
              handleUpdateDataColumns({
                index,
                type: UPDATE_COLUMNS.REMOVE,
              });
            }}
          />
        </Col>
      )}
    </Row>
  );
};

export default React.memo(ColumnDraggable);
