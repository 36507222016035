import moment from 'moment';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Input, InputNumber, Select } from 'antd';

import { WrapperSelect } from 'common/components';
import { NUMERIC_TYPE } from 'static/Constants';
import { checkSpecialField, specialField } from '../utils';

import { useWindowSize } from 'hooks/windowSize';

let searchTimeout;
const RenderValue = (props) => {
  const {
    property,
    handleQueryCondition,
    queryConditionsId,
    propertyEnums,
    acceptableValueSelect,
  } = props;

  const NUMBER_TYPE = [...NUMERIC_TYPE, 'double?', 'int'];
  const [valueInput, setValueInput] = useState(property?.value);

  const [screenWidth, screenHeight] = useWindowSize();

  const debounceUpdateValue = (...args) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleQueryCondition(...args);
    }, 300);
  };

  const handleUpdateValue = (value) => {
    setValueInput(value);
    debounceUpdateValue(property?.valuePath, value, 'value');
  };

  const listSelect =
    acceptableValueSelect?.length > 0 ? acceptableValueSelect : propertyEnums;

  const specialValue = (item) => {
    const isSpecialField = checkSpecialField(property?.fieldName);

    if (typeof item === 'string') return item;

    if (isSpecialField) return item?.enumCode;

    return item?.enumDisplayName;
  };

  const sortedList = (() => {
    const [firstValue] = listSelect;

    if (typeof firstValue === 'string') return listSelect?.sort();

    if (listSelect?.length > 0)
      return _.sortBy(listSelect, ['enumDisplayName', 'enumCode']);

    return [];
  })();

  const dataType = property?.dataType?.toLowerCase();
  const showCheckbox = dataType === 'boolean' || dataType === 'bool';

  useEffect(() => {
    setValueInput(property?.value);
  }, [property?.value]);

  return (
    <>
      {property?.dataType === 'string' && (
        <div>
          {sortedList.length > 0 ? (
            <WrapperSelect
              size='small'
              allowClear={false}
              getPopupContainer={() =>
                document.getElementById(queryConditionsId)
              }
              showSearch
              optionFilterProp='children'
              value={valueInput}
              listHeight={screenHeight <= 768 ? 70 : 150}
              placeholder='Please select value'
              className='query-condition-draggable__input'
              onChange={(value) => handleUpdateValue(value)}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
              bordered={false}
            >
              {sortedList.map((item, index) => {
                const value = specialValue(item);

                const specialFields = specialField(property, item);
                return (
                  <Select.Option key={index} value={value}>
                    {specialFields || value}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          ) : (
            <Input
              bordered={false}
              className='query-condition-draggable__input'
              size='small'
              value={valueInput}
              placeholder='Enter value'
              onChange={(e) => handleUpdateValue(e.target.value)}
            />
          )}
        </div>
      )}
      {property?.dataType === 'datetime' && (
        <DatePicker
          allowClear={false}
          bordered={false}
          size='small'
          className='query-condition-draggable__input'
          defaultValue={Boolean(valueInput) ? moment(valueInput) : null}
          placeholder='Select date'
          onChange={(date, dateString) => {
            handleQueryCondition(property.valuePath, dateString, 'value');
          }}
        />
      )}
      {NUMBER_TYPE.includes(property?.dataType?.toLowerCase()) && (
        <InputNumber
          bordered={false}
          className='query-condition-draggable__input'
          size='small'
          value={valueInput}
          placeholder='Input a number'
          onChange={(value) => handleUpdateValue(value)}
        />
      )}
      {showCheckbox && (
        <Checkbox
          className='query-condition-draggable__input-checkbox'
          checked={valueInput}
          onChange={(e) => handleUpdateValue(e.target.checked)}
        />
      )}
    </>
  );
};

export default RenderValue;
