import React, { useState } from 'react';

import { FolderAddOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import CreateTicketModal from './CreateTicketModal';

import Messages from 'i18n/messages/home';

const CreateTicketingSystem = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const onClick = () => {
    setModalVisible(true);
  };

  return (
    <>
      <RibbonButton
        icon={<FolderAddOutlined />}
        label={Messages.createTiketingSystem}
        onClick={onClick}
      />
      <CreateTicketModal visible={modalVisible} closeModal={closeModal} />
    </>
  );
};

export default CreateTicketingSystem;
