import React from 'react';
import { Tooltip } from 'antd';

import './SquareTag.less';

const SquareTag = (props) => {
  const { content, AntIcon, tooltip, color, gutter } = props;
  return (
    <Tooltip placement='topLeft' title={tooltip}>
      <div
        className='squrea-tag__wrapper'
        style={{
          paddingTop: gutter[0],
          paddingRight: gutter[1],
          paddingBottom: gutter[2],
          paddingLeft: gutter[3],
        }}
      >
        <div className='square-tag' style={{ background: color }}>
          {AntIcon && <AntIcon className='square-tag__icon' />}
        </div>
      </div>
    </Tooltip>
  );
};

export default SquareTag;
