import React from 'react';

import './Comment.less';

const Comment = (props) => {
  const { comment } = props;
  return (
    <div className='chat-comment'>
      <div className='chat-comment-inner'>
        <div className='chat-comment-avatar'>
          <img src={comment?.avatar} alt='comment-avatar' />
        </div>
        <div className='chat-comment-content'>
          <div className='chat-comment-content-author'>
            <span className='chat-comment-content-author-name'>
              {comment?.authorName}
            </span>
            <span className='chat-comment-content-author-time'>
              <span>{comment?.authorTime}</span>
            </span>
          </div>
          <div className='chat-comment-content-detail'>
            <p>{comment?.detail}</p>
          </div>
          <ul className='chat-comment-actions'>
            <li>
              <span>Reply to</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {};

export default Comment;
