import React, { useState } from 'react';
import { Avatar, Image, Tooltip, Button } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import './GroupPeopleCreate.less';

export const GroupPeopleCreate = (props) => {
  const { listPeople, removeItem } = props;
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <div className='group-people-create scroller'>
        {showAll
          ? listPeople?.map((item, index) => {
              return (
                <Tooltip title={item.fullName} key={index}>
                  <div className='group-people-create__avatar-wrapper'>
                    <Avatar
                      key={index}
                      className='group-people-create__avatar'
                      src={item?.avatar && item?.avatar}
                      icon={!item?.avatar && <UserOutlined />}
                      size={60}
                    />
                    <Button
                      shape='circle'
                      onClick={() => removeItem(item.userId)}
                      icon={<CloseOutlined />}
                      className='group-people-create__avatar-remove'
                      size='small'
                    />
                  </div>
                </Tooltip>
              );
            })
          : listPeople?.map((item, index) => {
              if (index <= 5)
                return (
                  <Tooltip title={item.fullName}>
                    <div className='group-people-create__avatar-wrapper'>
                      <Avatar
                        key={index}
                        className='group-people-create__avatar'
                        src={item?.avatar && item?.avatar}
                        icon={!item?.avatar && <UserOutlined />}
                        size={60}
                      />
                      <Button
                        shape='circle'
                        onClick={() => removeItem(item.userId)}
                        icon={<CloseOutlined />}
                        className='group-people-create__avatar-remove'
                        size='small'
                      />
                    </div>
                  </Tooltip>
                );
            })}

        {listPeople?.length > 6 && !showAll && (
          <div
            className=' group-people-create__see-more'
            onClick={() => setShowAll(true)}
          >
            ... {listPeople?.length - 6} more
          </div>
        )}
        {listPeople?.length >= 6 && showAll && (
          <div
            className=' group-people-create__see-more'
            onClick={() => setShowAll(false)}
          >
            Show less
          </div>
        )}
      </div>
    </>
  );
};

export default GroupPeopleCreate;
