import React from 'react';
import { Divider } from 'antd';

import CategoryShare from './components/sections/shared/CategoryShare';
import CategoryHttp from './components/sections/shared/CategoryHttp';
import ManageShare from './components/sections/shared/ManageShare';

const Shared = () => {
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      {/* <CategoryShare /> */}
      {/* <CategoryHttp /> */}
      <Divider
        type='vertical'
        style={{ height: 40, margin: 4, marginLeft: 45 }}
      />
      <ManageShare />
    </div>
  );
};

export default Shared;
