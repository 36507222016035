import React from 'react';

import { RollbackOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction, CustomNotification } from 'common/components';

import { revokeSubscription } from 'services/subscription';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import Messages from 'i18n/messages/home';

const RevokeSubscription = (props) => {
  const reloadPage = useDispatchReloadPage();

  const { selectionSubscription, activeRevoke, sectionDisabled } = props;

  const handleBtnlick = () => {
    dialogFunction({
      type: 'info',
      content:
        'Are you sure you want to revoke selecting subscription request?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleOkClick,
    });
  };

  const handleOkClick = () => {
    const params = {
      subId: selectionSubscription.subscriptionId || selectionSubscription.id,
    };

    revokeSubscription(params)
      .then((res) => {
        if (res?.isSuccess) {
          CustomNotification.success('Successfully');
          reloadPage();
        } else {
          CustomNotification.error(
            res?.message || 'Revoke failed! Please try!'
          );
        }
      })
      .catch((error) => {
        CustomNotification.error('Revoke failed! Something go wrong!');
      });
  };

  const checkDisabled = () => {
    let disabled = false;

    disabled = selectionSubscription?.status !== 'Active';

    if (activeRevoke) {
      disabled = !selectionSubscription;
    }

    return disabled || sectionDisabled;
  };

  return (
    <RibbonButton
      icon={<RollbackOutlined style={{ height: 26 }} />}
      label={Messages.revokeSubscription}
      onClick={handleBtnlick}
      disabled={checkDisabled()}
    />
  );
};

RevokeSubscription.propTypes = {};

export default RevokeSubscription;
