import React from 'react';
import Messages from 'i18n/messages/home';
import { DeleteOutlined } from '@ant-design/icons';
// components
import { dialogFunction } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';
// redux
import { useSelector } from 'react-redux';
import gridSelector from 'common/components/grid-view/controllers/selectors';
// hooks-query
import { useDeleteTicket } from 'hooks';
// hooks
import { useDispatchReloadPage } from 'hooks/useReloadPage';

const DeleteTicketingSystem = () => {
  const reloadPage = useDispatchReloadPage();

  const selectedDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const { deleteTicketMutation } = useDeleteTicket();

  const id =
    selectedDetail && selectedDetail.length > 0
      ? selectedDetail[0].id
      : undefined;

  const handleDeleteModal = () => {
    dialogFunction({
      type: 'warn',
      content: `Are you sure you want to delete selected ticket?`,
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDelete,
    });
  };

  const handleDelete = () => {
    const params = { ticketId: id };
    deleteTicketMutation.mutate(params, {
      onSuccess: (response) => {
        if (response?.isSuccess) {
          reloadPage();
        }
      },
    });
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.deleteTiketingSystem}
      onClick={handleDeleteModal}
      disabled={id === undefined || selectedDetail.length !== 1}
    />
  );
};

export default DeleteTicketingSystem;
