import React, { useEffect, useRef } from 'react';

import { Input, Select } from 'antd';

import { StyledModal, Form, CustomNotification } from 'common/components';

import { useFormatMessage, useTicketFullView, useGetBranding } from 'hooks';
import { forwardTo } from 'utils/common/route';

import { INITIAL_FIELD_VALUE } from 'pages/ticket-full-view/constants';

import Messages from 'i18n/messages/ticket-system';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const CreateTicketModal = (props) => {
  const [form] = Form.useForm();
  const inputRef = useRef();

  const { visible, closeModal } = props;

  const messages = useFormatMessage(Messages);

  const { saveTicketMutation } = useTicketFullView();
  const { getBranding } = useGetBranding();

  const initForm = () => {
    if (!visible) return;

    form.setFieldsValue({
      status: INITIAL_FIELD_VALUE.status,
      severity: INITIAL_FIELD_VALUE.severity,
      category: INITIAL_FIELD_VALUE.category,
      priority: INITIAL_FIELD_VALUE.priority,
    });
  };

  const resetState = () => {
    form.resetFields();
  };

  const autoFocusInput = () => {
    setTimeout(() => {
      inputRef?.current?.focus({
        cursor: 'start',
      });
    }, 100);
  };

  const submitForm = async () => {
    const formValue = await form.validateFields();

    saveTicketMutation.mutate(
      { ...formValue, status: 'Open' },
      {
        onSuccess: (data) => {
          CustomNotification.success(messages.saveTicketSuccess);
          getBranding();
          forwardTo(`/ticketing-system/${data.id}`);
          closeModal();
        },
        onError: () => {
          CustomNotification.error(messages.saveTicketError);
        },
      }
    );
  };

  const onCancel = () => {
    closeModal();
  };

  const onOk = () => {
    submitForm();
  };

  useEffect(() => {
    if (visible) {
      initForm();
      autoFocusInput();
    } else resetState();
  }, [visible]);

  return (
    <StyledModal
      title={messages.createTicketTitle}
      visible={visible}
      destroyOnClose
      maskClosable={false}
      onOk={onOk}
      okButtonProps={{ loading: saveTicketMutation?.isLoading }}
      onCancel={onCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item
          label={messages.ticketTitle}
          name='title'
          rules={[{ required: true, message: messages.requireFieldMessage }]}
        >
          <Input ref={inputRef} />
        </Form.Item>

        <Form.Item label={messages.statusLabel} name='status' hidden>
          <Select>
            <Select.Option value='Open'>Open</Select.Option>
            <Select.Option value='Pending'>Pending</Select.Option>
            <Select.Option value='Solved'>Solved</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={messages.categoryLabel} name='category'>
          <Select>
            <Select.Option value='Compliment'>Compliment</Select.Option>
            <Select.Option value='Complaint'>Complaint</Select.Option>
            <Select.Option value='Inquiry'>Inquiry</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={messages.severityLabel} name='severity'>
          <Select>
            <Select.Option value='High Risk / Medical'>
              High Risk / Medical
            </Select.Option>
            <Select.Option value='Medium'>Medium</Select.Option>
            <Select.Option value='Low'>Low</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={messages.priorityLabel} name='priority'>
          <Select>
            <Select.Option value={1}>1</Select.Option>
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
            <Select.Option value={4}>4</Select.Option>
            <Select.Option value={5}>5</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default CreateTicketModal;
