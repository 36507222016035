import React from 'react';
import { Button } from 'antd';

import classnames from 'classnames';

import './DialBtnWrap.less';

const DialBtnWrap = (props) => {
  const { className, children, type, ...restProps } = props;

  return (
    <Button
      className={classnames('dial-btn-wrap', className, {
        'dial-btn-wrap--accept': type === 'accept',
        'dial-btn-wrap--reject': type === 'reject',
      })}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default DialBtnWrap;
