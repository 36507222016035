import React from 'react';

import { Divider } from 'antd';

import {
  ManageSharingSection,
  DetailSection,
  ViewLayout,
  AdvanceStack,
} from '../components';
import { RibbonBar, RibbonDivider } from 'common/components';
import NewRequest from './NewRequest';
import FilterRequest from './FilterRequest';

const RequestGridRibbon = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />

      <NewRequest />
      <FilterRequest />
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default RequestGridRibbon;
