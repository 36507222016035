import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FolderBreadcrumb,
  FolderItemContainer,
} from 'common/components/folder';
import { FolderThumbnailCard, FolderTile } from '../components';

import * as globalActions from 'redux/global/actions';

import { useGridView } from 'hooks/useGridView';
import { useFolderByLevel } from 'hooks/useFolderByLevel';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';
import { useFolderGridItem } from './hooks';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import { forwardTo } from 'utils/common/route';

import { EVENT } from 'static/Constants';

export const FolderGridViewSection = ({
  folderOption,
  // isHideBreadcrumb,
  searchText,
  requestParams,
  children,
  ...rest
}) => {
  const dispatch = useDispatch();

  const isFavoriteRoute = useCheckFavoriteRoute();

  const { clearGridSelection } = useGridView();
  const {
    folderBreadcrumb,
    handleFetchFolderByLevel,
    updateFolderBreadcrumb,
    resetFolderBreadcrumb,
  } = useFolderByLevel();

  const handleGetFolderByBreadcrumb = async (idBreadcrumb) => {
    const currentBreadcrumb = folderBreadcrumb[folderBreadcrumb.length - 1];

    if (currentBreadcrumb?.id === idBreadcrumb) return;

    const { id } =
      folderBreadcrumb.find((item) => item?.id === idBreadcrumb) ?? {};

    const isRoot = id === 'home';

    const advFilterParams = isRoot ? requestParams : {};

    handleFetchFolderByLevel({
      folderId: isRoot ? null : id,
      folderOption,
      params: { ...advFilterParams, folderOption, isFavoriteRoute },
    });

    clearGridSelection();
    dispatch(globalActions.updateToggleDetail(false));

    //* update breadcrumb
    if (isRoot) {
      resetFolderBreadcrumb();
    } else {
      const foundIdxBreadcrumb = folderBreadcrumb.findIndex(
        (item) => parseInt(item.id) === parseInt(id)
      );

      const newBreadcrumbs = folderBreadcrumb.slice(0, foundIdxBreadcrumb + 1);
      updateFolderBreadcrumb(newBreadcrumbs);
    }
  };

  const backToHome = () => {
    handleGetFolderByBreadcrumb('home');
  };

  useDocumentAttachEvent({
    name: EVENT.BACK_FOLDER_GRID_TO_HOME,
    handler: backToHome,
  });

  return (
    <div className='folder-grid-view' {...rest}>
      {/* {isHideBreadcrumb ? null : (
        )} */}
      <FolderBreadcrumb
        maxItems={10}
        items={folderBreadcrumb}
        onClick={(breadcrumb) => handleGetFolderByBreadcrumb(breadcrumb)}
      />
      {children}
    </div>
  );
};

export const FolderViewContainer = ({ children, configProps }) => {
  const dispatch = useDispatch();

  const { clearGridSelection } = useGridView();

  const { onClickItemGrid, dataDetail, folderOption } = configProps || {};

  const { openEntity, openNestedFolderGrid } = useFolderGridItem({
    dataDetail,
    folderOption,
  });
  const { updateShouldKeepBreadcrumb } = useFolderByLevel();

  const onDoubleClick = () => {
    if (!dataDetail) return;
    forwardTo(`/folder/${dataDetail?.id}`);
  };

  const onClickLink = () => {
    //* clicking on link will redirect to item detail page
    //* when closing detail, breadcrumb will reset

    //* So prevent resetting breadcrumb
    dataDetail?.type.toLowerCase() !== 'query' &&
      updateShouldKeepBreadcrumb(true);
  };

  /* 
    Checkbox is wrong, because onClick is overlapping onCheckGrid
  */

  return (
    <FolderItemContainer
      onDoubleClick={onDoubleClick}
      onClick={(event) => {
        const target = event.srcElement.tagName;
        if (target === 'A') {
          onClickLink();

          //* prevent selecting
          return;
        }

        onClickItemGrid && onClickItemGrid(dataDetail, event);
      }}
    >
      {children}
    </FolderItemContainer>
  );
};

export const FolderGridThumbnail = (configProps) => {
  return (
    <FolderViewContainer configProps={configProps}>
      <FolderThumbnailCard {...configProps} {...{ pathname: '/folder' }} />
    </FolderViewContainer>
  );
};

export const FolderGridTile = (configProps) => {
  return (
    <FolderViewContainer configProps={configProps}>
      <FolderTile {...configProps} {...{ pathname: '/folder' }} />
    </FolderViewContainer>
  );
};
