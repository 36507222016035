/**
 * Branded members Messages
 *
 * This contains all the text for the member grid component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.branded-members';

export default defineMessages({
  memberSince: {
    id: `${scope}.components.memberSince.`,
    defaultMessage: 'Since',
  },
  lastUpdated: {
    id: `${scope}.components.lastUpdated.`,
    defaultMessage: 'Last Updated',
  },
});
