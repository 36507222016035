import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Row, Col, Input, Space } from 'antd';

import { SearchOutlined, RetweetOutlined } from '@ant-design/icons';

import {
  Form,
  StyledModal,
  CustomNotification,
  FormDeleteButton,
} from 'common/components';
import {
  SearchGrid,
  SearchGridItemButton,
  SectionContainer,
} from '../components';
import FormMove from './FormMove';
import SelectedAssetList from './SelectedAssetsList';

import * as damServices from 'services/digitalAsset';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { mapToSearchParams } from '../mapper/mapToSearchParams';
import * as api from 'config/axios';
import { get } from 'lodash';

import './styles.less';

const defaultPageInfo = {
  currentPageIndex: 1,
  currentPageSize: 20,
  totalRecord: null,
};

const selectedItemsDefine = ['init', undefined, Array];

const SearchGridItemModal = (props, ref) => {
  const {
    searchType = 'member',
    isMultiple = true,
    apiUrl,
    params,
    title,
    tagRender,
    savedItem,
    savedSelected = false,
    service,
    dataPath,
    mapSearch,
    itemIdName,
    assetList,
    memberId,
    onlyFetchWithSearchText = true,
    ...rest
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(selectedItemsDefine[0]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [showSearchHint, setShowSearchHint] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  const [activeAssets, setActiveAssets] = useState([]);
  const methodHook = useState('move');

  const [moveMethod] = methodHook;

  const refSelectedItems = useRef([]);

  const [formInstance] = Form.useForm();

  const reloadPage = useDispatchReloadPage();

  const getServices = (params) => {
    if (service) return service(params);

    const endpoint = apiUrl;
    return api.sendPost(endpoint, params);
  };

  const fetchItemList = async (customParams = {}) => {
    setIsLoading(true);

    //* search Hint only show before first fetch
    showSearchHint && onlyFetchWithSearchText && setShowSearchHint(false);

    const response = await getServices({
      ...params,
      ...customParams,
    });
    setIsLoading(false);
    if (response?.isSuccess) {
      const gridData = dataPath
        ? get(response, dataPath)
        : response?.data?.gridData;
      const paging = response?.data?.paging;
      setItems(gridData);
      setPageInfo(paging);
    }
  };

  const handleSearchItem = () => {
    const formValue = formInstance.getFieldsValue();

    const searchTextValue = formValue?.searchText;

    if (!searchTextValue) return;

    const procParams = mapToSearchParams(formValue, params, mapSearch);

    fetchItemList(procParams);
  };

  const handleClearSearch = () => {
    formInstance.resetFields();
    fetchItemList(params);
  };

  const handleSelectItem = (itemData) => {
    //* if same member Id does not allow to select
    // if (itemData?.[itemIdName] === memberId) return;

    setSelectedItems((prev) => {
      let nextValue;
      if (prev !== 'init' && prev !== undefined) {
        nextValue = [...prev];
      } else {
        nextValue = [];
      }

      const foundItemIdx = nextValue.findIndex(
        (item) =>
          (item?.[itemIdName] || item?.id) ===
          (itemData?.[itemIdName] || itemData?.id)
      );

      if (foundItemIdx !== -1) {
        nextValue.splice(foundItemIdx, 1);
      } else {
        if (!isMultiple) {
          nextValue = [itemData];
        } else {
          nextValue.push(itemData);
        }
      }

      return nextValue;
    });
  };

  const handleClickOk = async () => {
    refSelectedItems.current = selectedItems;
    setModalLoading(true);

    const response = await damServices.moveAssetToMember({
      targetMemberId: selectedItems?.[0]?.companyId,
      assetIds: activeAssets.map((item) => item?.id),
      assetOption: moveMethod,
    });

    setModalLoading(false);

    const { isSuccess, message } = response;

    if (isSuccess) {
      const successMsg =
        (moveMethod === 'move' ? 'Move' : ' Move copied') +
        ' asset(s) to selected member successfully';

      CustomNotification.success(successMsg);
      setIsModalOpen(false);
      reloadPage();
    } else {
      CustomNotification.error(message || 'Failed to move asset');
    }
  };

  const resetActiveAssets = () => {
    setActiveAssets(assetList);
  };

  const resetAll = () => {
    setSelectedItems(selectedItemsDefine[0]);
    setPageInfo(defaultPageInfo);
    formInstance.resetFields();
    setItems([]);
    setShowSearchHint(true);
  };

  const handleRemoveActiveAsset = (assetItem) => {
    setActiveAssets((prevAssetList) => {
      let nextAssetList = [...prevAssetList];
      return nextAssetList.filter((item) => item?.id !== assetItem?.id);
    });
  };

  const makeDisableSameMember = (id) => {
    if (memberId === id) {
      return {
        opacity: '0.5',
        pointerEvent: 'none',
      };
    } else return {};
  };

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setIsModalOpen(false);
    },
    openModal: () => {
      setIsModalOpen(true);
    },
  }));

  useEffect(() => {
    if (isModalOpen) {
      !onlyFetchWithSearchText && fetchItemList(params);
    } else {
      !savedSelected && resetAll();
    }
  }, [
    isModalOpen,
    JSON.stringify(params),
    savedSelected,
    onlyFetchWithSearchText,
  ]);

  useEffect(() => {
    if (assetList) {
      setActiveAssets(assetList);
    }
  }, [assetList]);

  return (
    <StyledModal
      visible={isModalOpen}
      className='search-grid-item-asset-to-member__modal'
      title={title}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      onOk={handleClickOk}
      centered
      destroyOnClose
      maskClosable={false}
      okButtonProps={{
        disabled:
          !selectedItems?.length ||
          typeof selectedItems === 'string' ||
          !activeAssets.length ||
          modalLoading,
      }}
      confirmLoading={modalLoading}
      {...rest}
    >
      <Form form={formInstance}>
        <Row style={{ height: '100%' }}>
          <Col>
            <Form.Item label='Search Text' name='searchText'>
              <Input
                style={{ width: 270 }}
                onPressEnter={handleSearchItem}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col>
            <Space>
              <SearchGridItemButton type='primary' onClick={handleSearchItem}>
                <SearchOutlined />
              </SearchGridItemButton>

              {!onlyFetchWithSearchText && (
                <SearchGridItemButton
                  type='primary'
                  onClick={handleClearSearch}
                >
                  Reset
                </SearchGridItemButton>
              )}
            </Space>
          </Col>
          <Col flex='auto' />
          <Col>
            <FormDeleteButton
              text='Reset asset list'
              icon={<RetweetOutlined />}
              onClick={resetActiveAssets}
            />
          </Col>
        </Row>
      </Form>
      <Row
        className='search-grid-item-asset-to-member__modal-grid-n-preview'
        style={{ marginLeft: -8, marginRight: -8 }}
      >
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ flex: 1, overflow: 'hidden' }}>
            <SectionContainer>
              <SearchGrid
                items={items}
                handleSelectItem={handleSelectItem}
                selectedItems={selectedItems}
                isLoading={isLoading}
                searchType={searchType}
                itemIdName={itemIdName}
                showSearchHint={showSearchHint}
              />
            </SectionContainer>
          </Row>
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Row style={{ flex: 1, overflow: 'hidden', paddingBottom: 5 }}>
            <Col xs={24} style={{ height: '100%' }}>
              <SectionContainer>
                <SelectedAssetList
                  assetList={activeAssets}
                  handleRemoveActiveAsset={handleRemoveActiveAsset}
                />
              </SectionContainer>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <SectionContainer>
                <FormMove
                  methodHook={methodHook}
                  selectedItems={selectedItems}
                  items={items}
                  handleSelectItem={handleSelectItem}
                  tagRender={tagRender}
                />
              </SectionContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default forwardRef(SearchGridItemModal);
