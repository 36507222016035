import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getUsersMeeting = (params) => {
  return api.sendPost(endpoints.GET_MEETING_USERS_GRID, params);
};

export const createNewMeeting = (params) => {
  return api.sendPost(endpoints.CREATE_NEW_MEETING, params);
};

export const updateMeeting = (params) => {
  return api.sendPost(endpoints.UPDATE_MEETING, params);
};

export const cancelMeeting = (params) => {
  return api.sendPost(endpoints.CANCEL_MEETING, params);
};

export const getMeetingDetails = (params) => {
  return api.sendGet(endpoints.GET_MEETING_DETAILS, { params });
};

export const getScheduleList = (params) => {
  return api.sendGet(endpoints.GET_SCHEDULE_LIST, { params });
};

export const updateAcceptOrRejectMeeting = (params) => {
  return api.sendPost(endpoints.ACCEPT_OR_REJECT_MEETING, params);
};

export const getProjectsGridService = (params) => {
  return api.sendGet(endpoints.GET_PROJECTS_GRID, { params: params });
};

export const getMeetingDetail = (params) => {
  return api.sendGet(endpoints.GET_MEETING_DETAIL, { params });
};

export const startMeeting = (params) => {
  return api.sendPost(endpoints.START_MEETING, params);
};

export const endMeeting = (params) => {
  return api.sendPost(endpoints.END_MEETING, params);
};

export const kickOffMeeting = (params) => {
  return api.sendPost(endpoints.KICK_OFF_MEETING, params);
};
