import { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { useQueryClient } from '@tanstack/react-query';

import { getProductItemModules } from 'services/product';

import selectorUser from 'redux/user/selectors';

import { useAsync } from 'hooks/useAsync';
import { useGetProductEnums, useGetProductFullView } from 'hooks';

import { isEmpty, every } from 'lodash';

import { getQaSpecDataHeader, getQaSpecDataContact } from 'services/qaSpec';
import { checkIsSuperAdmin, checkOwnerOneItem } from 'utils';

import {
  MEMBER_TYPE_MANUFACTURER,
  MEMBER_TYPE_SHARED_ONLY,
} from 'static/Constants';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks';

import useCheckSnapshotForRetailer from './useCheckSnapshotForRetailer';

const useGetProductItemModules = (id, isEnabled = true) => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    if (id && isEnabled) {
      run(getProductItemModules({ productId: id }));
    }
    return;
  }, [id, isEnabled, run]);

  const refetchProductItems = useCallback(() => {
    return run(getProductItemModules({ productId: id }));
  }, [id, run]);

  return {
    productItemModules: data,
    statusFetchProductItem: status,
    refetchProductItems,
  };
};

const useGetDproperties = (productItemModules) => {
  const dProperties = useMemo(() => {
    if (!productItemModules) return null;

    return productItemModules?.productItemModules?.find(
      (module) => module?.moduleName === 'DProperties'
    );
  }, [JSON.stringify(productItemModules)]);

  return dProperties;
};

const useGetGridData = (dProperties, columnDef) => {
  const data = useMemo(() => {
    let nextData = {};

    if (!dProperties) return [];

    for (const [fieldName, value] of Object.entries(dProperties?.data)) {
      if (columnDef.map((fieldItem) => fieldItem?.field).includes(fieldName)) {
        nextData[fieldName] = value;
      }
    }

    return every(nextData, isEmpty) ? [] : [nextData];
  }, [JSON.stringify(dProperties), columnDef]);

  return data;
};

const useGetAllergenGridData = (dProperties, productSchema) => {
  const data = useMemo(() => {
    let nextData = [];

    if (!dProperties || !productSchema) return nextData;

    const allergenFieldList = productSchema
      ?.find((schemaItem) => schemaItem?.moduleName === 'DProperties')
      ?.moduleProperties.filter(
        (propertyItem) => propertyItem?.propertyGroup === 'Allergens'
      );

    for (const [fieldName, value] of Object.entries(dProperties?.data)) {
      const foundField = allergenFieldList?.find(
        (allergenFieldItem) => allergenFieldItem?.propertyName === fieldName
      );

      if (foundField) {
        let firstValue, secondValue;

        const inverseValue = (currentValue) => {
          if (!currentValue) return '';
          if (currentValue.includes('Yes')) return 'No';
          if (currentValue.includes('Not Applicable')) return 'Not Applicable';
          if (currentValue.includes('No')) return 'Yes';
        };

        if (value && foundField?.propertyName !== 'AllergenStatement') {
          const currentValue = value.split(':')?.[1];

          if (value.includes('Intentionally Present as Ingredient:')) {
            firstValue = currentValue;
            secondValue = inverseValue(currentValue);
          } else {
            firstValue = inverseValue(currentValue);
            secondValue = currentValue;
          }
        }

        nextData = [
          ...nextData,
          {
            propertyName: foundField?.propertyName,
            Allergens:
              foundField?.propertyDescription ||
              foundField?.propertyDisplayName,
            IntentionallyPresentAsIngredient: firstValue,
            PresentAsResultPotentialContamination: secondValue,
            OriginalValue: value,
          },
        ];
      }
    }

    return nextData;
  }, [JSON.stringify(dProperties), productSchema]);

  return data;
};

const enumCheckboxToSelect = [
  { value: true, displayName: 'Yes' },
  { value: false, displayName: 'No' },
];

const useGetEnumOptions = ({ optionMapping } = { optionMapping: null }) => {
  const { productEnums } = useGetProductEnums();

  const options = useMemo(() => {
    const enumList = [
      'BasicUomEnum',
      'DaymonNonBinaryLogicCodeEnum',
      'OriginCodeEnum',
      'ReligiousClaimCodeEnum',
      'LevelOfContainmentCodeEnum',
      'ContainmentSourceEnum',
      'PhysicalAndChemicalCharacteristicsParameterCodeEnum',
      'MicrobiologicalCharacteristicsParameterCodeEnum',
      'YesNoEnum',
      'NonBinaryLogicEnumerationEnum',
      'UsdaGradingEnum',
      'FreshSeafoodSourceEnum',
      'OnOffEnum',
      'KosherCertificationEnum',
      'HalalCertifierEnum',
      'NonGMOCertifierEnum',
      'PackagingRecyclingSchemeCodeEnum',
      'PackageTypeCode_GDSN_Enum',
      'PackagingMaterialTypeCodeEnum',
      'LanguageCodeEnum',
      'ClaimsEnum',
    ];
    let qaSpecEnums = {};

    productEnums.forEach((enumItem) => {
      const foundEnumName = enumList.find(
        (enumListItem) => enumListItem === enumItem?.enumName
      );
      if (foundEnumName) {
        qaSpecEnums[foundEnumName] = optionMapping
          ? enumItem?.enumProperties.map(optionMapping)
          : enumItem?.enumProperties;
      }

      if (qaSpecEnums?.length === enumList) {
        return;
      }
    });

    return {
      ingredient: {
        organicOrBio: qaSpecEnums?.DaymonNonBinaryLogicCodeEnum || [],
        origin: qaSpecEnums?.OriginCodeEnum || [],
        religiousClaim: qaSpecEnums?.ReligiousClaimCodeEnum || [],
        gmo: qaSpecEnums?.DaymonNonBinaryLogicCodeEnum || [],
        ionized: qaSpecEnums?.DaymonNonBinaryLogicCodeEnum || [],
        allergens: qaSpecEnums?.DaymonNonBinaryLogicCodeEnum || [],
        bioengineered: qaSpecEnums?.DaymonNonBinaryLogicCodeEnum || [],
      },
      allergen: {
        levelOfContainment: qaSpecEnums?.LevelOfContainmentCodeEnum || [],
        containmentSource: qaSpecEnums?.ContainmentSourceEnum || [],
      },
      productSpec: {
        polyflour: {
          productContainPfas: qaSpecEnums?.['YesNoEnum'],
          productPackagingContainPfas: qaSpecEnums?.['YesNoEnum'],
          pfasTested: qaSpecEnums?.['YesNoEnum'],
        },
        dietaryCertificate: {},
        physicAndChem: {
          parameter:
            qaSpecEnums?.PhysicalAndChemicalCharacteristicsParameterCodeEnum ||
            [],
        },
        microbiological: {
          parameter:
            qaSpecEnums?.MicrobiologicalCharacteristicsParameterCodeEnum || [],
        },
      },
      freshMeat: {
        suitableForVetgetarianLogo: qaSpecEnums?.['YesNoEnum'],
        irradiated: qaSpecEnums?.['NonBinaryLogicEnumerationEnum'],
        fortification: qaSpecEnums?.['YesNoEnum'],
        freeRange: qaSpecEnums?.['YesNoEnum'],
        usdaGrading: qaSpecEnums?.['UsdaGradingEnum'],
        dryAged: qaSpecEnums?.['YesNoEnum'],
        coldSmoked: qaSpecEnums?.['YesNoEnum'],
      },
      freshSeafood: {
        source: qaSpecEnums?.['FreshSeafoodSourceEnum'],
        feeledDeveined: qaSpecEnums?.['YesNoEnum'],
        tailOffOrOn: qaSpecEnums?.['OnOffEnum'],
      },
      packaging: {
        productContainsBioengineeredIngredients: qaSpecEnums?.['YesNoEnum'],
        frontOfPackIcons: qaSpecEnums?.['YesNoEnum'],
        recyclabel: qaSpecEnums?.['YesNoEnum'],
        recyclableType: qaSpecEnums?.['PackagingRecyclingSchemeCodeEnum'],
        packagingType: qaSpecEnums?.['PackageTypeCode_GDSN_Enum'],
        packagingMaterial: qaSpecEnums?.['PackagingMaterialTypeCodeEnum'],
        qrCode: enumCheckboxToSelect,
        bilingual: qaSpecEnums?.['YesNoEnum'],
        uom: qaSpecEnums?.['BasicUomEnum'],
        secondaryLanguage: qaSpecEnums?.['LanguageCodeEnum'],
        dietaryCertificate: {
          kosherCertifier: qaSpecEnums?.['KosherCertificationEnum'],
          halalCertifier: qaSpecEnums?.['HalalCertifierEnum'],
          nonGMOCertifier: qaSpecEnums?.['NonGMOCertifierEnum'],
        },
        hasFoodGradePlasticsCertifier: enumCheckboxToSelect,
        recycledMaterial: enumCheckboxToSelect,
        soyInk: enumCheckboxToSelect,
        vegetableBasedInk: enumCheckboxToSelect,
      },
      claims: {
        contains: qaSpecEnums?.['ClaimsEnum'],
        doesNotContain: qaSpecEnums?.['ClaimsEnum'],
      },
    };
  }, [productEnums, optionMapping]);

  return options;
};

const PERMISSION_EDIT_QA_SPEC = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.QA_SPECIFICATION,
  },
];

export const checkEditForSupplier = ({ productFull, companyInfo }) => {
  const memberType = companyInfo?.member?.memberType;

  const isSupplier =
    MEMBER_TYPE_SHARED_ONLY === memberType ||
    MEMBER_TYPE_MANUFACTURER === memberType;

  const supplierExisted = productFull?.suppliers?.some(
    (supplier) => supplier?.id === companyInfo?.member?.id
  );

  if (isSupplier && supplierExisted) return true;

  if (isSupplier && productFull?.editable) {
    return true;
  }

  return false;
};

const useCheckAllowEdit = (productIdProps) => {
  const params = useParams();

  const userInfo = useSelector(selectorUser.makeSelectUserInfo()) || {};
  const { productFull } = useGetProductFullView({
    productId: productIdProps || params?.id,
  });

  const hasEditQaSpecPermission = useCheckPermissions(PERMISSION_EDIT_QA_SPEC);

  const { roles, isSuperMember } = userInfo ?? {};
  const isSuperAdmin = checkIsSuperAdmin(roles);
  const isOwner = checkOwnerOneItem(productFull);

  const hasEditPermissionForSupplier = checkEditForSupplier({
    productFull,
    companyInfo: userInfo,
  });

  const checkAllowQAEdit = () => {
    if (isSuperAdmin) return true;

    if (!hasEditQaSpecPermission) return false;

    if (isSuperMember) return true;

    if (isOwner) return true;

    if (hasEditPermissionForSupplier) return true;

    return false;
  };

  return checkAllowQAEdit();
};

const useSelectProductFullDetailData = ({ productId }) => {
  const { productFull } = useGetProductFullView({ productId });

  return productFull || {};
};

export {
  useCheckAllowEdit,
  useGetProductItemModules,
  useGetDproperties,
  useGetGridData,
  useGetAllergenGridData,
  useGetEnumOptions,
  useSelectProductFullDetailData,
  useCheckSnapshotForRetailer,
};
