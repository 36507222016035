import React from 'react';
import PropTypes from 'prop-types';

import { Row, Avatar } from 'antd';
import { get } from 'lodash';
import { Images } from 'config/assets';

const AssetTileHeader = (props) => {
  const { dataDetail } = props;

  return (
    <Row className='member-tile__header' justify='center'>
      <div className='member-tile__header--background'></div>

      <div className='member-tile__header--image-wrapper'>
        <Avatar
          className='member-tile__header--image'
          src={
            dataDetail.thumb300 || dataDetail.thumb || Images.RIVIR_LOGO_DEFAULT
          }
          alt={dataDetail && dataDetail.memberName}
        />
      </div>
    </Row>
  );
};

AssetTileHeader.propTypes = {
  dataDetail: PropTypes.object,
};

export default AssetTileHeader;
