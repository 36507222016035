export const QA_TOGGLE_TAB_EXPAND = 'QA_TOGGLE_TAB_EXPAND';

export const QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB =
  'QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB';

export const QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB_SUCCESS =
  'QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB_SUCCESS';

export const QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE =
  'QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE';

export const QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE_SUCCESS =
  'QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE_SUCCESS';

export const QA_SPEC_EDIT_BULK = 'QA_SPEC_EDIT_BULK';
export const QA_SPEC_EDIT_BULK_SUCCESS = 'QA_SPEC_EDIT_BULK_SUCCESS';
