import React from 'react';
import { Row, Col } from 'antd';
import {
  QuestionCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';

const ProductSyndicationHistoryStatus = (props) => {
  const { status, style } = props;

  const renderIconStatus = () => {
    const iconStatus = status?.length > 0 ? status?.toLowerCase() : '';
    switch (iconStatus) {
      case 'pending':
        return (
          <QuestionCircleOutlined
            style={{ fontSize: '80px', color: '#faad14' }}
          />
        );
      case 'error':
      case 'rejected':
        return (
          <CloseCircleOutlined style={{ fontSize: '80px', color: '#ff4d4f' }} />
        );
      case 'accepted':
      case 'synchronised':
        return (
          <CheckCircleOutlined style={{ fontSize: '80px', color: '#52c41a' }} />
        );
      case 'review':
      case 'received':
        return (
          <ClockCircleOutlined style={{ fontSize: '80px', color: '#0070cc' }} />
        );
      case 'sent':
        return <SendOutlined style={{ fontSize: '80px', color: '#0070cc' }} />;
      default:
        <QuestionCircleOutlined
          style={{ fontSize: '80px', color: '#faad14' }}
        />;
    }
  };
  return (
    <div className='syndication-history-panel__status' style={style}>
      <Row className='syndication-history-panel__status--wrapper'>
        <Col
          span={24}
          className='syndication-history-panel__status--wrapper-icon'
        >
          {renderIconStatus()}
        </Col>
        <Col className='syndication-history-panel__status--wrapper-text'>
          {status}
        </Col>
      </Row>
    </div>
  );
};

export default ProductSyndicationHistoryStatus;
