export const SUPPORT_COMMON_TYPES = [
  'jpeg',
  'png',
  'jpg',
  'tiff',
  'tif',
  'mp4',
  'mov',
  'avi',
  'mp3',
  'zip',
  'rar',
  'txt',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'bmp',
  'gif',
  'webm',
  'csv',
];