import React from 'react';
import { SendOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const SendToFTP = ({ ...otherProps }) => {
  return (
    <ButtonSmallIcon
      icon={<SendOutlined />}
      label={Messages.sendToFTP}
      className='button-item-dropdown'
      {...otherProps}
    />
  );
};

SendToFTP.propTypes = {};

export default SendToFTP;
