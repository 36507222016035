import React from 'react';

import { groupBy, values } from 'lodash';

import ChatTurnItem from './ChatTurnItem';

import { List } from 'antd';

import classnames from 'classnames';

const ChatTurnList = (props) => {
  const { data, participants, isUnread } = props;

  const checkNewChain = (msg, index) => {
    if (index === 0) return true;

    const prevMsg = data[index - 1];
    if (msg.senderId !== prevMsg.senderId) return true;
    if (msg.sendTime - prevMsg.sendTime > 60000) return true;

    return false;
  };

  const renderItem = (item, index) => {
    let isNewChain = checkNewChain(item, index);
    return (
      <List.Item>
        <ChatTurnItem
          item={item}
          isNewChain={isNewChain}
          participants={participants}
        />
      </List.Item>
    );
  };

  return (
    <List
      className={classnames('chat-thread__list', {
        'chat-thread__list--unread': isUnread,
      })}
      dataSource={data}
      bordered={false}
      renderItem={renderItem}
    ></List>
  );
};

export default ChatTurnList;
