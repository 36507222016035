import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  selectedGroup: null,
  memberId: null,
  ticketGroupList: null,
};

/* eslint-disable default-case, no-param-reassign */
const ticketingGroupReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SELECTED_GROUP:
        draft.selectedGroup = action.payload;
        break;
      case types.UPDATE_MEMBER_ID:
        draft.memberId = action.payload;
        break;
      case types.UPDATE_TICKET_GROUP_LIST:
        draft.ticketGroupList = action.payload;
        break;
      default:
    }
  });

export default ticketingGroupReducer;
