import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Radio } from 'antd';
import {
  HomeOutlined,
  ProfileOutlined,
  UserOutlined,
  DollarCircleOutlined,
  SettingOutlined,
  LayoutOutlined,
} from '@ant-design/icons';

import * as actions from 'redux/ribbon/actions';
import { selectMemberLayout } from 'redux/ribbon/selectors';

import { RibbonButton, RibbonSection } from 'common/components';

import { MEMBER_LAYOUT } from 'static/Constants';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { useCheckCreditCardPermission } from 'hooks/useCheckCreditCardPermission';

import Messages from 'i18n/messages/home';

import './MemberLayout.less';

function MemberLayout() {
  const dispatch = useDispatch();
  const layout = useSelector(selectMemberLayout());

  const canViewCompanyProfile = useCheckPermissions([
    {
      action: ABILITY_ACTION.VIEW,
      subject: ABILITY_SUBJECT.COMPANY_PROFILE,
    },
  ]);

  const { canAccessBilling } = useCheckCreditCardPermission();

  const handleChangeLayout = (value) => {
    dispatch(actions.changeMemberLayout(value));
  };

  return (
    <Radio.Group value={layout} className='member-layout__radio-group'>
      <RibbonSection>
        <Radio.Button value={MEMBER_LAYOUT.OVERVIEW} type='text'>
          <RibbonButton
            icon={<HomeOutlined />}
            label={Messages.overviewLayout}
            onClick={() => handleChangeLayout(MEMBER_LAYOUT.OVERVIEW)}
          />
        </Radio.Button>

        <Radio.Button
          value={MEMBER_LAYOUT.PREVIEW}
          type='text'
          disabled={canViewCompanyProfile}
        >
          <RibbonButton
            icon={<LayoutOutlined />}
            label={Messages.previewLayout}
            onClick={() => handleChangeLayout(MEMBER_LAYOUT.PREVIEW)}
            disabled={!canViewCompanyProfile}
          />
        </Radio.Button>

        <Radio.Button value={MEMBER_LAYOUT.DETAILS} type='text' isSmallBtn>
          <RibbonButton
            size='small'
            style={{ width: '100%' }}
            icon={<ProfileOutlined />}
            label={Messages.detailsLayout}
            onClick={() => handleChangeLayout(MEMBER_LAYOUT.DETAILS)}
            className='member-layout__ribbon-button'
          />
        </Radio.Button>

        {canAccessBilling ? (
          <Radio.Button value={MEMBER_LAYOUT.BILLING} type='text' isSmallBtn>
            <RibbonButton
              size='small'
              style={{ width: '100%' }}
              icon={<DollarCircleOutlined />}
              label={Messages.billingLayout}
              onClick={() => handleChangeLayout(MEMBER_LAYOUT.BILLING)}
              className='member-layout__ribbon-button'
            />
          </Radio.Button>
        ) : null}

        <Radio.Button value={MEMBER_LAYOUT.CONTACTS} type='text' isSmallBtn>
          <RibbonButton
            size='small'
            style={{ width: '100%' }}
            icon={<UserOutlined />}
            label={Messages.contactsLayout}
            onClick={() => handleChangeLayout(MEMBER_LAYOUT.CONTACTS)}
            className='member-layout__ribbon-button'
          />
        </Radio.Button>

        <Radio.Button value={MEMBER_LAYOUT.SYSTEM} type='text' isSmallBtn>
          <RibbonButton
            size='small'
            style={{ width: '100%' }}
            icon={<SettingOutlined />}
            label={Messages.systemLayout}
            onClick={() => handleChangeLayout(MEMBER_LAYOUT.SYSTEM)}
            className='member-layout__ribbon-button'
          />
        </Radio.Button>
      </RibbonSection>
    </Radio.Group>
  );
}

export default MemberLayout;
