import React from 'react';
import { Divider } from 'antd';

import { useSelector } from 'react-redux';

import OpenSection from './components/sections/mapping/OpenSection';
import { RibbonBar, RibbonDivider } from 'common/components';
import ShareSection from './components/sections/mapping/ShareSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import gridSelectors from 'common/components/grid-view/controllers/selectors';
import CreateMapping from './components/controls/mapping/CreateMapping';
import EditMapping from './components/controls/mapping/EditMapping';
import CloneMapping from './components/controls/mapping/CloneMapping';
import DeleteMapping from './components/controls/mapping/DeleteMapping';
import FilterMapping from './components/controls/mapping/FilterMapping';
import selectorUser from 'redux/user/selectors';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const ProductMapping = () => {
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const memberId = userInfo?.member?.id;

  const isDisabled =
    selectedItemList?.[0]?.ownerCompanyId === memberId ? false : true;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabled />
      </Can>

      <RibbonDivider />
      <Can
        I={ABILITY_ACTION.MANAGE}
        a={ABILITY_SUBJECT.PIM_MAPPING}
        passThrough
      >
        {(allowed) => (
          <ShareSection
            selectedItemList={selectedItemList}
            isDisabled={!allowed}
          />
        )}
      </Can>

      <RibbonDivider />
      <OpenSection selectedItemList={selectedItemList} />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.PRODUCT}>
          <CreateMapping isSmallBtn />
        </Can>
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.PRODUCT}>
          <EditMapping isSmallBtn isDisabled={isDisabled} />
        </Can>
      </Can>
      <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.PRODUCT}>
        <CloneMapping />
      </Can>
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.MEMBER}>
        <DeleteMapping
          selectedItemList={selectedItemList}
          isDisabled={isDisabled}
        />
      </Can>
      <RibbonDivider />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <FilterMapping />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <RibbonDivider />
        <AdvanceStack isDisabled />
      </Can>
      {/* <RibbonDivider />
      <DeleteReportSection /> */}
    </RibbonBar>
  );
};

ProductMapping.propTypes = {};

export default ProductMapping;
