import React from 'react';
import { useSelector } from 'react-redux';

import * as selectorsGlobal from 'redux/global/selectors';
import * as actionsGridView from './controllers/actions';

const pathnameMember = '/members';
const pathnameDigitalMedia = '/digital-media';
const pathnameDigitalMediaNew = '/digital-media/new';
const pathnameAsset = '/digital-media/assets';
const pathnameAssetForMember = '/member-assets';
const pathnameDocument = '/digital-media/documents';
const pathnameMultiMedia = '/digital-media/multi-media';
const pathnameFavAssets = '/favorite/favorite-assets';
const pathnameReporting = '/reportings';
const pathnameOwnedReporting = '/reportings/owned';
const pathnameSharedReporting = '/reportings/shared';
const pathnameMemberMaintenance = '/maintenance/member-management';
const pathnameFavoriteAssets = '/favorite/favorite-assets';
const pathnameFavoriteProducts = '/favorite/favorite-products';
const pathnameFavoriteMembers = '/favorite/favorite-members';
const pathnameFormManage = '/maintenance/form';

const pathnameProduct = '/products';
const pathnameProductForMember = '/member-products';
const pathnameFolder = '/folders';
const pathnameSharedFolder = '/folders/shared';
const pathnameOwnedFolder = '/folders/owned';
const pathnameFavoriteFolder = '/favorite/favorite-folders';

const pathnameRequestGrid = '/security-matrix/permission-request';
const pathnameSsoApproval = '/sso-approval/member';

export const GRID_ID = {
  COMPANY_CONTACT: 'GRID_COMPANY_CONTACT',
  COMPANY_LOCATION: 'GRID_COMPANY_LOCATION',
  USER_PROFILE_LOCATION: 'GRID_USER_PROFILE_LOCATION',
  SHARE_MODAL: 'GRID_SHARE_MODAL',
  WORKFLOW_ACCEPT_STEP: 'WORKFLOW_ACCEPT_STEP',
  WORKFLOW_APPROVE_STEP: 'WORKFLOW_APPROVE_STEP',
  MAPPING_SHARED_MEMBERS: 'MAPPING_SHARED_MEMBERS',
  PARTY_GDSN_LIST: 'PARTY_GDSN_LIST',
  MEMBER_MAINTENANCE: 'MEMBER_MAINTENANCE',
  MEMBER_MAINTENANCE_USER: 'MEMBER_MAINTENANCE_USER',
  MINIMUM_REQUIREMENTS: 'MINIMUM_REQUIREMENTS',
  TRANSITION_PAGE: 'TRANSITION_PAGE',
  MFA_VERIFICATION_LOG: 'MFA_VERIFICATION_LOG',
  SSO_APPROVAL_MEMBER: 'SSO_APPROVAL_MEMBER',
  FORM_MAMANGE: 'FORM_MANAGE',
  SHAREABLE_MEMBER_LIST: 'SHAREABLE_MEMBER_LIST',
  SHAREABLE_USER_LIST: 'SHAREABLE_USER_LIST',
  SPLASH_FORM_HISTORY_BY_MEMBER: 'SPLASH_FORM_HISTORY_BY_MEMBER',
  SPLASH_FORM_HISTORY_BY_ENTITY: 'SPLASH_FORM_HISTORY_BY_ENTITY',
  MAPPING_SUBSCRIPTION_GRID: 'MAPPING_SUBSCRIPTION_GRID',
  MANAGE_QUERY: 'MANAGE_QUERY',
  TICKETING_SYSTEM: 'TICKETING_SYSTEM',
  PUBLICATION: 'PUBLICATION',
  QAS_REQUIRE_FIELD: 'QAS_REQUIRE_FIELD',
};

export const updateSizeCurrent = (dispatch, pageNumber, pageSize, pathname) => {
  if (pathname.includes(pathnameAssetForMember)) {
    dispatch(
      actionsGridView.updatePageCurrentAssetForMember([pageNumber, pageSize])
    );

    return;
  }

  if (pathname.includes(pathnameProductForMember)) {
    dispatch(
      actionsGridView.updatePageCurrentProductForMember([pageNumber, pageSize])
    );

    return;
  }

  switch (pathname) {
    case pathnameMember:
      dispatch(actionsGridView.updatePageCurrentMember([pageNumber, pageSize]));
      break;

    case pathnameFolder:
      dispatch(actionsGridView.updatePageCurrentFolder([pageNumber, pageSize]));
      break;
    case pathnameOwnedFolder:
      dispatch(
        actionsGridView.updatePageCurrentOwnedFolder([pageNumber, pageSize])
      );
      break;
    case pathnameSharedFolder:
      dispatch(
        actionsGridView.updatePageCurrentSharedFolder([pageNumber, pageSize])
      );
      break;
    case pathnameFavoriteFolder:
      dispatch(
        actionsGridView.updatePageCurrentFavoriteFolder([pageNumber, pageSize])
      );
      break;

    case pathnameReporting:
      dispatch(
        actionsGridView.updatePageCurrentReporting([pageNumber, pageSize])
      );
      break;
    case pathnameOwnedReporting:
      dispatch(
        actionsGridView.updatePageCurrentOwnedReporting([pageNumber, pageSize])
      );
      break;
    case pathnameSharedReporting:
      dispatch(
        actionsGridView.updatePageCurrentSharedReporting([pageNumber, pageSize])
      );
      break;

    case pathnameDigitalMedia:
      dispatch(
        actionsGridView.updatePageCurrentDigitalMedia([pageNumber, pageSize])
      );
      break;

    case pathnameDigitalMediaNew:
      dispatch(
        actionsGridView.updatePageCurrentDigitalMediaNew([pageNumber, pageSize])
      );
      break;

    case pathnameAsset:
      dispatch(actionsGridView.updatePageCurrentAsset([pageNumber, pageSize]));
      break;

    case pathnameDocument:
      dispatch(
        actionsGridView.updatePageCurrentDocument([pageNumber, pageSize])
      );
      break;

    case pathnameMultiMedia:
      dispatch(
        actionsGridView.updatePageCurrentMultiMedia([pageNumber, pageSize])
      );
      break;

    case pathnameFavoriteAssets:
      dispatch(
        actionsGridView.updatePageCurrentFavoriteAsset([pageNumber, pageSize])
      );
      break;

    case pathnameFavoriteProducts:
      dispatch(
        actionsGridView.updatePageCurrentFavoriteProduct([pageNumber, pageSize])
      );
      break;

    case pathnameFavoriteMembers:
      dispatch(
        actionsGridView.updatePageCurrentFavoriteMember([pageNumber, pageSize])
      );
      break;

    case GRID_ID.SHARE_MODAL:
      dispatch(
        actionsGridView.updatePageCurrentShareModal([pageNumber, pageSize])
      );
      break;

    case GRID_ID.WORKFLOW_ACCEPT_STEP:
      dispatch(
        actionsGridView.updatePageCurrentWorkflowAcceptStep([
          pageNumber,
          pageSize,
        ])
      );
      break;

    case GRID_ID.WORKFLOW_APPROVE_STEP:
      dispatch(
        actionsGridView.updatePageCurrentWorkflowApproveStep([
          pageNumber,
          pageSize,
        ])
      );
      break;

    case GRID_ID.TRANSITION_PAGE:
      dispatch(
        actionsGridView.updatePageCurrentTransition([pageNumber, pageSize])
      );
      break;

    case pathnameProduct:
      dispatch(
        actionsGridView.updatePageCurrentProduct([pageNumber, pageSize])
      );
      break;

    case pathnameRequestGrid:
      dispatch(
        actionsGridView.updatePageCurrentRequestGrid([pageNumber, pageSize])
      );
      break;

    case GRID_ID.PARTY_GDSN_LIST:
      dispatch(
        actionsGridView.updatePageCurrentPartyGDSNList([pageNumber, pageSize])
      );
      break;

    case pathnameSsoApproval:
      dispatch(
        actionsGridView.updatePageCurrentSsoApprovalMember([
          pageNumber,
          pageSize,
        ])
      );
      break;

    case GRID_ID.FORM_MAMANGE:
      dispatch(
        actionsGridView.updatePageCurrentFormMaintenance([pageNumber, pageSize])
      );
      break;
    case GRID_ID.SPLASH_FORM_HISTORY_BY_ENTITY:
      dispatch(
        actionsGridView.updatePageCurrentFormMaintenance([pageNumber, pageSize])
      );
      break;
    case GRID_ID.MANAGE_QUERY:
      dispatch(
        actionsGridView.updatePageCurrentManageQuery([pageNumber, pageSize])
      );
      break;
    case GRID_ID.TICKETING_SYSTEM:
      dispatch(
        actionsGridView.updatePageCurrentTicketingSystem([pageNumber, pageSize])
      );
    case GRID_ID.PUBLICATION:
      dispatch(
        actionsGridView.updatePageCurrentPublication([pageNumber, pageSize])
      );
      break;
    case GRID_ID.QAS_REQUIRE_FIELD:
      dispatch(
        actionsGridView.updatePageCurrentQasRequireField([pageNumber, pageSize])
      );
      break;
    default:
      dispatch(actionsGridView.updateSizeCurrent([pageNumber, pageSize]));
      break;
  }
};

export const checkProductsAreSubscription = (ribbon, filterProducts) => {
  if (ribbon === 'products' && filterProducts.includes('subscription')) {
    return true;
  }
  return false;
};

export const checkProductsAreUnit = (ribbon, filterProducts) => {
  if (ribbon === 'products' && filterProducts.includes('Unit')) {
    return true;
  }
  return false;
};

export const checkAssetsAreOwner = (ribbon, filterAssets) => {
  if (ribbon === 'assets' && filterAssets.includes('owner')) {
    return true;
  }
  return false;
};
