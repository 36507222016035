import React from 'react';
import { Col, Input } from 'antd';
import classNames from 'classnames';

import { GroupPartName } from '../components/GroupPart';
import { WrapperSelect } from 'common/components';

const RenderIngredients = ({
  title,
  ingredients,
  onClickIngredients,
  isSelected,
}) => {
  const value = ingredients ? ingredients : [];
  return (
    <GroupPartName
      title={title}
      onClick={onClickIngredients}
      className={classNames({
        'ingredient-selected': isSelected,
      })}
    >
      <Col span={24} style={{ pointerEvents: 'none' }}>
        <p className='title-section'>Ingredients Breakout</p>
        <WrapperSelect
          mode='tags'
          style={{ width: '100%' }}
          placeholder='INGREDIENTS'
          name='ingredients'
          listItemHeight={10}
          listHeight={250}
          value={value}
        />
      </Col>
      <Col span={24}>
        <p className='title-section'>Ingredients Statement</p>
        <Input.TextArea
          rows={5}
          maxLength={3000}
          value={value}
          readOnly
          style={{ cursor: 'default' }}
        />
      </Col>
    </GroupPartName>
  );
};

export default RenderIngredients;
