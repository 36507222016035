import React from 'react';
import { Image } from 'antd';
import Icon from '@ant-design/icons';

import { SocialLinkComponent, IconNotDefinedType } from 'static/Icons';

import classnames from 'classnames';

const SocialIcon = React.memo((props) => {
  const { type } = props;

  const filterIcon = SocialLinkComponent.filter((s) => s.type === type);

  let svgComponent, imgComponent;

  svgComponent =
    filterIcon?.length <= 0 ? IconNotDefinedType : filterIcon?.[0]?.component;

  imgComponent = filterIcon?.[0]?.imageUrl ? filterIcon?.[0] : null;

  return (
    <>
      {svgComponent && (
        <Icon
          component={svgComponent}
          className={classnames('social-link__icon', {
            'social-link__icon--custom-34':
              type === 'Twitter' || type === 'Webx' || type === 'Whatsapp',
            'social-link__icon--custom-38': type === 'Instagram',
          })}
        />
      )}
      {imgComponent && (
        <Image
          className={classnames('social-link__icon-image', {
            'social-link__icon-image--gs1': type === 'gs1',
            'social-link__icon-image--ixone': type === 'ixone',
          })}
          src={imgComponent?.imageUrl}
          preview={false}
        />
      )}
    </>
  );
});

export default SocialIcon;
