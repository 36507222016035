import React from 'react';
import { Typography, Space } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { helpIcon } from 'static/HelpIcon';

const { Text } = Typography;

const HelpCategoryIconFilter = (props) => {
  const { value } = props;

  // use value for filter cellRenderer
  const iconName = value;
  const iconObj = helpIcon.find((icon) => {
    return icon.type === iconName;
  });

  // fallback for filter
  if (value === '(Select All)') {
    return value;
  }

  const Icon = iconObj?.icon;
  const iconTitle = iconObj?.name;

  return (
    <Space>
      {Icon ? <Icon /> : <QuestionOutlined />}
      {value ? <Text>{iconTitle}</Text> : null}
    </Space>
  );
};

export default HelpCategoryIconFilter;
