// react
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

/**
 * Wrapper helmet component lets allow user update display helmet data
 * @author Brian Pick
 */

const HelmetWrapper = (props) => {
  const { title, defaultTitle, content, children, ...restProps } = props;

  return (
    <React.Fragment>
      <Helmet title={title} defaultTitle={defaultTitle} {...restProps}>
        <title>RIVIR</title>
      </Helmet>
      {children && children}
    </React.Fragment>
  );
};

// window.FileViewer = FileViewer;

HelmetWrapper.propTypes = {
  /**
   * The title document header
   */
  title: PropTypes.string,
  /**
   * The default title document header
   */
  defaultTitle: PropTypes.string,
  /**
   * The content display of document header
   */
  content: PropTypes.string,
  /**
   * Remaining props that will pass into Layout
   */
  rest: PropTypes.object,
};

export default HelmetWrapper;
