import React from 'react';
import { Tag, Tooltip } from 'antd';
import * as IconListArray from 'common/components/icon-list/assets';
import {
  languageCode,
  currencyData,
  legalEntityType,
  targetMarketCountryCode,
  ownershipType,
  statusType,
} from 'static/CountryCode';

export const fiscalYearStartDateFormat = 'MM/DD';

const IconListName = IconListArray.default;

const tagRender = (props) => {
  const { value, closable, onClose } = props;
  const src = IconListName.filter((val) => val.segmentId === value);
  return (
    <Tooltip placement='top' title={src && src[0] && src[0].segmentDescription}>
      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        <img
          style={{ width: 20, height: 20, marginRight: 4 }}
          src={src && src[0] && src[0].segmentIcon}
          alt='icon-render'
        />
      </Tag>
    </Tooltip>
  );
};

const getClassificationsOptions = () => {
  return IconListName.map((val) => {
    return {
      label: val.segmentDescription,
      value: val.segmentId,
      icon: val.segmentIcon,
    };
  });
};

const getCountryOptions = () => {
  return Object.values(targetMarketCountryCode)
    ?.slice()
    .sort()
    .map((country) => {
      return {
        label: country,
        value: country,
      };
    });
};

const getLanguageOptions = () => {
  return languageCode
    ?.slice()
    .sort()
    .map((language) => {
      return {
        label: language,
        value: language,
      };
    });
};

const getCurrencyOptions = () => {
  return Object.keys(currencyData).map((currencyCode) => {
    return {
      label: `(${currencyCode}) ${currencyData[currencyCode]}`,
      value: currencyCode,
    };
  });
};

const getLegalEntityTypesOptions = () => {
  return legalEntityType.map((type) => ({
    label: type,
    value: type,
  }));
};

const getOwnershipTypeOptions = () => {
  return ownershipType.map((type) => ({
    label: type,
    value: type,
  }));
};

export const getStatusTypesOptions = () => {
  return statusType.map((type) => ({
    label: type,
    value: type,
  }));
};

const editFormSchema = [
  {
    name: 'dba',
    label: 'dbaLabel',
    type: 'text',
  },
  {
    name: 'legalEntityType',
    label: 'legalEntityTypeLabel',
    type: 'select',
    options: getLegalEntityTypesOptions(),
  },
  {
    name: 'status',
    label: 'statusLabel',
    type: 'select',
    options: getStatusTypesOptions(),
  },
  {
    name: 'headquartersCountry',
    label: 'headquartersCountryLabel',
    type: 'selectEnum',
    showSearch: true,
    options: getCountryOptions(),
    enumName: 'CountryCode3166Enum',
  },
  {
    name: 'stateOfIncorporation',
    label: 'incorporationStateLabel',
    type: 'custom',
  },
  {
    name: 'currency',
    label: 'currencyLabel',
    type: 'selectEnum',
    showSearch: true,
    options: getCurrencyOptions(),
    enumName: 'CurrencyCodeISO4217Enum',
  },
  {
    name: 'primaryLanguage',
    label: 'languageLabel',
    type: 'selectEnum',
    showSearch: true,
    options: getLanguageOptions(),
    enumName: 'LanguageCodeISO639_1Enum',
  },
  // {
  //   name: 'gln',
  //   label: 'glnLabel',
  //   type: 'text',
  // },
  {
    name: 'duns',
    label: 'dunsLabel',
    type: 'text',
  },
  {
    name: 'stockSymbol',
    label: 'stockSymbolLabel',
    type: 'text',
  },
  {
    name: 'sicCode',
    label: 'sicCodeLabel',
    type: 'text',
  },
  {
    name: 'naicsCode',
    label: 'naicsLabel',
    type: 'text',
  },
  {
    name: 'annualRevenue',
    label: 'annualRevenueLabel',
    type: 'text',
  },
  {
    name: 'representedSales',
    label: 'representedSalesLabel',
    type: 'text',
  },
  {
    name: 'minorityOwnedType',
    label: 'minorityOwnedTypeLabel',
    type: 'select',
    mode: 'multiple',
    options: [
      {
        label: 'Minority Owned Business Certifications',
        value: 'Minority Owned Business Certifications',
      },
      {
        label: '8(a) Business Development Program',
        value: '8(a) Business Development Program',
      },
      {
        label: 'Asian-Indian',
        value: 'Asian-Indian',
      },
      {
        label: 'Certified B Corp',
        value: 'Certified B Corp',
      },
      {
        label: 'Non Profit',
        value: 'Non Profit',
      },
      {
        label: 'Asian-Pacific American',
        value: 'Asian-Pacific American',
      },
      {
        label: 'Not for Profit',
        value: 'Not for Profit',
      },
      {
        label: 'DOBE (Disability-Owned Business Enterprise)',
        value: 'DOBE (Disability-Owned Business Enterprise)',
      },
      {
        label: 'African American',
        value: 'African American',
      },
      {
        label: 'LGBTBE (LGBT-Owned Business Enterprise)',
        value: 'LGBTBE (LGBT-Owned Business Enterprise)',
      },
      {
        label: 'Small Business (SBA / SBE)',
        value: 'Small Business (SBA / SBE)',
      },
      {
        label: 'DBE (Disadvantaged Business Enterprise)',
        value: 'DBE (Disadvantaged Business Enterprise)',
      },
      {
        label: 'WOSB (Women Owned Small Business)',
        value: 'WOSB (Women Owned Small Business)',
      },
      {
        label: 'NMSDC Certified (Ethnic Minority Owned)',
        value: 'NMSDC Certified (Ethnic Minority Owned)',
      },
      {
        label: 'Hispanic American',
        value: 'Hispanic American',
      },
      {
        label: 'Women Owned',
        value: 'Women Owned',
      },
      {
        label: 'Military Veteran-Owned',
        value: 'Military Veteran-Owned',
      },
      {
        label: 'State Certified Minority Owned',
        value: 'State Certified Minority Owned',
      },
      {
        label: 'VOSB (Veteran Owned Small Business)',
        value: 'VOSB (Veteran Owned Small Business)',
      },
      {
        label: 'Historically Underutilized Business (HUB)',
        value: 'Historically Underutilized Business (HUB)',
      },
      {
        label: 'Latin American',
        value: 'Latin American',
      },
      {
        label: 'MBE (Minority Business Enterprise)',
        value: 'MBE (Minority Business Enterprise)',
      },
      {
        label: 'Native American',
        value: 'Native American',
      },
      {
        label: 'NOD Leading Disability Employer',
        value: 'NOD Leading Disability Employer',
      },
      {
        label: 'Small & Disadvantaged Business',
        value: 'Small & Disadvantaged Business',
      },
      {
        label: 'Other Diversity Based Certification',
        value: 'Other Diversity Based Certification',
      },
      {
        label: 'GEEIS (Gender Equality European & International Standard)',
        value: 'GEEIS (Gender Equality European & International Standard)',
      },
      {
        label: 'SDVOSB (Service-Disabled Veteran-Owned Small Business)',
        value: 'SDVOSB (Service-Disabled Veteran-Owned Small Business)',
      },
      {
        label: 'EDWOSB (Economically Disadvantaged Woman-Owned Small Business)',
        value: 'EDWOSB (Economically Disadvantaged Woman-Owned Small Business)',
      },
      {
        label: 'WBENC (Womans Business Enterprise National Council)',
        value: 'WBENC (Womans Business Enterprise National Council)',
      },
      {
        label: 'NaVOBA (National Veteran-Owned Business Association)',
        value: 'NaVOBA (National Veteran-Owned Business Association)',
      },
    ],
  },
  {
    name: 'ownershipType',
    label: 'ownershipTypeLabel',
    type: 'select',
    options: getOwnershipTypeOptions(),
  },
  {
    name: 'contractPackaging',
    label: 'contractPackagingLabel',
    type: 'checkbox',
  },
  {
    name: 'minorityOwned',
    label: 'minorityOwnedLabel',
    type: 'checkbox',
  },
  {
    name: 'bCorp',
    label: 'bCorpLabel',
    type: 'checkbox',
  },
  {
    name: 'coOp',
    label: 'coOpLabel',
    type: 'checkbox',
  },
  {
    name: 'coOp50',
    label: 'coOp50Label',
    type: 'checkbox',
  },
  {
    name: 'coPacker',
    label: 'coPackerLabel',
    type: 'checkbox',
  },
  {
    name: 'fiscalYearStartDate',
    label: 'fiscalYearStartDateLabel',
    type: 'datePicker',
    dateFormat: fiscalYearStartDateFormat,
  },
  {
    name: 'numberOfEmployees',
    label: 'numberOfEmployeesLabel',
    type: 'number',
    min: 0,
  },
  {
    name: 'numberOfCustomers',
    label: 'numberOfCustomersLabel',
    type: 'number',
    min: 0,
  },
  {
    name: 'customers',
    label: 'customersLabel',
    type: 'select',
    mode: 'tags',
  },
  {
    name: 'classifications',
    label: 'majorIndustryClassificationsLabel',
    type: 'select',
    mode: 'multiple',
    tagRender: tagRender,
    options: getClassificationsOptions(),
    fullWidth: true,
  },
  {
    name: 'productCapabilities',
    label: 'productCapabilitiesLabel',
    type: 'text',
  },
  {
    name: 'productionCapabilities',
    label: 'productionCapabilitiesLabel',
    type: 'text',
  },
  {
    name: 'marketRegions',
    label: 'marketRegionsLabel',
    type: 'select',
    mode: 'tags',
  },
  {
    name: 'produceNationalBrands',
    label: 'produceNationalBrandsLabel',
    type: 'checkbox',
  },
  {
    name: 'exportRegions',
    label: 'exportRegionsLabel',
    type: 'select',
    mode: 'tags',
  },
  {
    name: 'exportPercentage',
    label: 'exportPercentageLabel',
    type: 'number',
    min: 0,
    format: '%',
  },
  {
    name: 'exportCountries',
    label: 'exportCountriesLabel',
    type: 'select',
    mode: 'multiple',
    options: getCountryOptions(),
    placement: 'topLeft',
  },
  {
    name: 'certifications',
    label: 'certificationsLabel',
    type: 'custom',
  },
  {
    name: 'privateLabel',
    label: 'privateLabelLabel',
    type: 'checkbox',
  },
  {
    name: 'privateLabelBusinessPercentage',
    label: 'privateLabelBusinessPercentageLabel',
    type: 'number',
    format: '%',
    min: 0,
  },
  {
    name: 'privateLabelClassifications',
    label: 'privateLabelClassificationsLabel',
    type: 'select',
    mode: 'multiple',
    tagRender: tagRender,
    options: getClassificationsOptions(),
    fullWidth: true,
    placement: 'topLeft',
    filterOption: (input, option) => {
      return (option?.label?.toLowerCase() ?? '').includes(
        input?.toLowerCase()
      );
    },
  },
  {
    name: 'autoSendAcceptCic',
    label: 'autoSendAcceptCicLabel',
    type: 'checkbox',
  },
  // {
  //   name: 'fiscalYearStartDate',
  //   label: 'fiscalYearStartDateLabel',
  //   type: 'datePicker',
  //   dateFormat: fiscalYearStartDateFormat,
  // },
  // {
  //   name: 'fiscalYearStartDate',
  //   label: 'fiscalYearStartDateLabel',
  //   type: 'datePicker',
  //   dateFormat: fiscalYearStartDateFormat,
  // },
];

export default editFormSchema;
