import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getCommunicationTemplatesService = (params) => {
  return api.sendPost(endpoints.GET_COMMUNICATION_DETAILS_GRID, params);
};

// Mutate templates
export const createCommunicationTemplatesTextService = (params) => {
  return api.sendPost(endpoints.CREATE_COMMUNICATION_TEMPLATE_TEXT, params);
};

export const editCommunicationTemplatesTextService = (params) => {
  return api.sendPost(endpoints.EDIT_COMMUNICATION_TEMPLATE_TEXT, params);
};

export const createCommunicationTemplatesVideoService = (params) => {
  return api.sendPost(endpoints.CREATE_COMMUNICATION_TEMPLATE_VIDEO, params);
};

export const editCommunicationTemplatesVideoService = (params) => {
  return api.sendPost(endpoints.EDIT_COMMUNICATION_TEMPLATE_VIDEO, params);
};

export const deleteCommunicationTemplatesService = (params) => {
  return api.sendPost(endpoints.DELETE_COMMUNICATION_TEMPLATES, params);
};

export const getDetailTemplateService = (id) => {
  const options = {
    params: {
      id,
    },
  };
  return api.sendGet(endpoints.GET_COMMUNICATION_TEMPLATE_DETAIL, options);
};
