import * as endpoints from './endpoints';
import * as api from 'config/axios';

export const getMeetingNotification = (params) => {
  return api.sendPost(endpoints.GET_MEETING_NOTIFICATION, params);
};

export const acceptOrRejectMeeting = (params) => {
  return api.sendPost(endpoints.ACCEPT_OR_REJECT_MEETING, params);
};
