import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Input, Spin, Divider } from 'antd';

import { FolderList } from './FolderList';
import { FolderBreadcrumb } from './FolderBreadcrumb';
import DropWrap from '../drapDropWrap/drap-and-drop/DropWrap';

import { useGetAllFolderGrid } from './hooks';
import './FolderSelector.less';

const DEFAULT_KEY_FOLDER = [{ id: 'home', folderName: 'Home' }];

export const FolderSelector = forwardRef((props, ref) => {
  const { onDrop, onExpandFolder, onBreadcrumbChange, disableItemHandler } =
    props;

  const [keyFolder, setKeyFolder] = useState(DEFAULT_KEY_FOLDER);
  const [currentFolderId, setCurrentFolderId] = useState();
  const [selectedFolder, setSelectedFolder] = useState();

  const {
    folderData,
    isLoading,
    getGridFolder,
    handleSearchFolder,
    handleFetchMoreFolder,
  } = useGetAllFolderGrid({
    isEnabled: true,
    onResetParentId: () => setCurrentFolderId(null),
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        reloadList: (id) => {
          id ? handleFetchMoreFolder(id) : getGridFolder();
        },
      };
    },
    []
  );

  const onSearch = (searchText) => {
    setKeyFolder(DEFAULT_KEY_FOLDER);
    handleSearchFolder(searchText);
  };

  const onSelectFolder = (currentFolder) => {
    const breadcrumbSelected = keyFolder[keyFolder?.length - 1];

    const newSelectedFolder =
      selectedFolder?.id === currentFolder?.id
        ? breadcrumbSelected
        : currentFolder;

    setSelectedFolder(newSelectedFolder);
    props?.onSelectFolder?.(newSelectedFolder);
  };

  const handleBreadcrumb = (valueSelected, hierarchyList) => {
    const folderId = valueSelected !== 'home' ? valueSelected : null;

    setCurrentFolderId(folderId);
    setSelectedFolder(null);
    setKeyFolder((pre) => {
      const indexBreadcrumb = keyFolder
        .map((item) => item?.id)
        .indexOf(valueSelected);
      return pre.slice(0, indexBreadcrumb + 1);
    });

    const breadcrumbSelected = keyFolder?.filter(
      (item) => valueSelected === item?.id
    );

    onBreadcrumbChange &&
      onBreadcrumbChange(folderId, hierarchyList, breadcrumbSelected?.[0]);
  };

  const handleExpand = (folder, folderName) => {
    setCurrentFolderId(folder?.id);
    setSelectedFolder(null);
    handleFetchMoreFolder(folder?.id);
    setKeyFolder((pre) => [...pre, { id: folder?.id, folderName }]);

    onExpandFolder && onExpandFolder(folder);
  };

  const breadcrumbKey = currentFolderId ? currentFolderId : 'home';
  const breadcrumbData = folderData?.[breadcrumbKey] ?? [];

  return (
    <Spin
      spinning={isLoading}
      tip='loading...'
      wrapperClassName='folder-selector'
    >
      <Input.Search
        placeholder='Search Folder items...'
        onSearch={onSearch}
        allowClear
      />
      <FolderBreadcrumb
        maxItems={4}
        items={keyFolder}
        onClick={handleBreadcrumb}
      />
      <Divider style={{ margin: '4px 0px' }} />
      <div
        className='scroller'
        style={{ height: 'calc(100% - 56px)', position: 'relative' }}
      >
        <FolderList
          folderData={breadcrumbData}
          selectedFolderId={selectedFolder?.id}
          handleDropItem={onDrop}
          onExpand={handleExpand}
          onSelectFolder={onSelectFolder}
          disableItemHandler={disableItemHandler}
        />
        <DropWrap
          className='drop-wrap-empty'
          onDropData={(dropData) =>
            onDrop && onDrop(dropData, keyFolder[keyFolder.length - 1])
          }
        />
      </div>
    </Spin>
  );
});

export default FolderSelector;
