import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import ProductOverviewSubscriptionShield from './ProductOverviewSubscriptionShield';
import ProductOverviewTitle from './ProductOverviewTitle';
import ProductOverviewStatusInfo from './ProductOverviewStatusInfo';
import GdsnShield from 'pages/home/ribbon/components/controls/product-full/GdsnShield';

import { useCheckAllowEditProducts } from 'hooks';

const ProductPrimaryInfo = ({ product, refPrimaryInfo, ...rest }) => {
  const {
    isHierarchy,
    statusWidth,
    setEditProductId,
    isFixPublicationErrorView,
  } = rest;

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const isAllowEditProduct = checkAllowEditProductFull(product);

  return (
    <Row className='product-overview' align='middle' ref={refPrimaryInfo}>
      {!isAllowEditProduct && product?.subscriptionShield && (
        <Col>
          <ProductOverviewSubscriptionShield product={product} />
        </Col>
      )}

      {product?.gdsnShield && (
        <Col>
          <GdsnShield gdsnShield={product?.gdsnShield} />
        </Col>
      )}
      <Col flex={1}>
        <ProductOverviewTitle
          product={product}
          isHierarchy={isHierarchy}
          isFixPublicationErrorView={isFixPublicationErrorView}
          setEditProductId={setEditProductId}
        />
      </Col>
      <Col flex={statusWidth ? `${statusWidth}px` : '180px'}>
        <ProductOverviewStatusInfo data={product} />
      </Col>
    </Row>
  );
};

ProductPrimaryInfo.propTypes = {
  product: PropTypes.object,
};

export default ProductPrimaryInfo;
