import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { Form, Input, Button, Checkbox, Typography, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/login';
import Img from 'common/components/image/Image';

import { CustomNotification } from 'common/components';

import * as actions from 'redux/user/actions';

import Introduction from 'pages/login/introduction';

import RIVIR_LOGO_DEFAULT from 'assets/rivir-logo-thumbnail.png';

import classnames from 'classnames';

import './AnonLogin.less';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 16,
  },
};

const { Item } = Form;
const { Text } = Typography;

const AnonymousLogin = (props) => {
  const { error, branding, usercls, ...rest } = props;
  const logoSrc = RIVIR_LOGO_DEFAULT;

  const { meetingId } = useParams();

  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();

  const handleAnonLogin = () => {
    formInstance
      .validateFields()
      .then(async (value) => {
        dispatch(
          actions.loginAnon({
            ...value,
            meetingUniqueId: meetingId,
          })
        );
      })
      .catch((error) => {
        CustomNotification.error('Could not submit form');
      });
  };

  return (
    <div className='anony-login__wrapper'>
      <Row className='anony-login'>
        <Col flex='350px'>
          <Introduction usercls='login__intro' isAnonymousLogin />
        </Col>
        <Col flex='auto'>
          <Form
            {...layout}
            name='anony-login-form'
            className='anony-login__form'
            initialValues={{
              remember: true,
            }}
            form={formInstance}
          >
            {/* <Item label=' ' colon={false}> */}
            <div className='anony-login__form-branding'>
              <Img src={logoSrc} alt={(branding && branding.name) || 'RIVIR'} />
            </div>
            {/* </Item> */}

            <Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                { type: 'email', message: 'Invalid Email' },
              ]}
            >
              <Input />
            </Item>

            <Item
              label='Full Name'
              name='fullName'
              rules={[
                {
                  required: true,
                  message: 'Your full name is required',
                },
              ]}
            >
              <Input />
            </Item>

            <Item {...tailLayout}>
              <Button
                type='primary'
                shape='round'
                className={classnames(
                  'anony-login__btn',
                  'anony-login__btn--submit'
                )}
                onClick={handleAnonLogin}
              >
                <FormattedMessage {...Messages.loginAsGuest} />
              </Button>
              {error ? (
                <Text className={`${usercls}-error-message`} type='danger'>
                  {error}
                </Text>
              ) : (
                <Text className={`${usercls}-error-message`} type='danger'>
                  &nbsp;
                </Text>
              )}
            </Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AnonymousLogin;
