import React, { useState, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { Input, InputNumber, Select, notification, Popover } from 'antd';
import { SisternodeOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import {
  Form,
  RibbonButton,
  StyledModal,
  WrapperSelect,
} from 'common/components';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import { addProductToApl } from 'services/apl';
import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';
import { toggleReloadGrid } from 'pages/product-apl-detail/controllers/actions';
import { supportPhoneFormats, REGEX_CONTACT_PHONE } from 'static/Constants';

import { useGetProductEnums } from 'hooks';

import { formatObjectFormData } from 'utils';
import sortByKey from 'utils/sortByKey';

import './AddProductToApl.less';

const AddProductToApl = () => {
  const [visibleModal, setVisibleModal] = useState(false);

  const aplDetail = useSelector(makeSelectAplDetail());

  return (
    <>
      <RibbonButton
        size='small'
        icon={<SisternodeOutlined style={{ height: 26 }} />}
        label={Messages.addProductToApl}
        onClick={() => setVisibleModal(true)}
        disabled={!aplDetail?.isOwner}
        className='button-item-dropdown'
      />

      {visibleModal && (
        <ModalAddProductToApl
          visibleModal={visibleModal}
          turnOffModal={() => setVisibleModal(false)}
          aplDetail={aplDetail}
        />
      )}
    </>
  );
};

const layoutForm = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const ModalAddProductToApl = ({ visibleModal, turnOffModal, aplDetail }) => {
  const [formInstance] = Form.useForm();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { productEnums } = useGetProductEnums();
  const sizeUomEnum = useMemo(() => getUomEnum(productEnums), [productEnums]);

  const handleSubmitForm = () => {
    formInstance.validateFields().then(() => {
      let params = formatObjectFormData(formInstance.getFieldsValue());

      params = {
        ...params,
        aplId: aplDetail?.aplId,
      };

      setIsLoading(true);

      addProductToApl(params)
        .then((response) => {
          if (response?.isSuccess) {
            notification.success({
              message: intl.formatMessage(
                messagesProduct.addProductToAplSuccess
              ),
            });
            dispatch(toggleReloadGrid(true));
            turnOffModal();
          } else {
            notification.error({
              message: response?.message,
            });
            turnOffModal();
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(toggleReloadGrid(false));
          }, 200);
        });
    });
  };

  const modalProps = {
    title: intl.formatMessage(messagesProduct.addProductToAplLabel),
    bodyStyle: { minHeight: '35vh' },
    width: '600px',
    destroyOnClose: true,
    visible: visibleModal,
    onCancel: turnOffModal,
    onOk: handleSubmitForm,
    confirmLoading: !!isLoading,
  };

  const gtinUpc12Rule = ({ getFieldValue }) => ({
    validator() {
      if (getFieldValue('upc12') || getFieldValue('gtin')) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('GTIN or UPC12 is required'));
    },
  });

  return (
    <StyledModal {...modalProps}>
      <Form {...layoutForm} form={formInstance}>
        <Form.Item
          name='name'
          label='Product Name'
          rules={[{ required: true, message: 'Please input a product name' }]}
        >
          <Input placeholder='Input product name' />
        </Form.Item>
        <Form.Item
          name='gtin'
          label={
            <>
              <span className='add-product-to-apl__required-icon'>*</span>
              GTIN
            </>
          }
          rules={[gtinUpc12Rule]}
        >
          <Input placeholder='Input GTIN' />
        </Form.Item>
        <Form.Item
          name='upc12'
          label={
            <>
              <span className='add-product-to-apl__required-icon'>*</span>
              UPC12
            </>
          }
          rules={[gtinUpc12Rule]}
        >
          <Input placeholder='Input UPC12' />
        </Form.Item>

        <Form.Item
          name='brand'
          label='Brand'
          // rules={[{ required: true, message: 'Please input a brand' }]}
        >
          <Input placeholder='Input brand' />
        </Form.Item>
        <Form.Item name='size' label='Size'>
          <InputNumber
            min={1}
            style={{ width: '100%' }}
            placeholder='Input size'
          />
        </Form.Item>
        <Form.Item name='sizeUom' label='Size Uom'>
          <WrapperSelect
            showSearch
            placeholder='Select size Uom'
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
          >
            {sortByKey(sizeUomEnum, 'enumDisplayName')?.map((enumOption) => (
              <Select.Option
                key={enumOption.enumDisplayName}
                value={enumOption.enumDisplayName}
              >
                {enumOption.enumDisplayName}
              </Select.Option>
            ))}
          </WrapperSelect>
        </Form.Item>
        <Form.Item name='category' label='Category'>
          <Input placeholder='Input category' />
        </Form.Item>

        <Form.Item name='contactName' label='Contact Name'>
          <Input placeholder='Input Contact Name' />
        </Form.Item>

        <Form.Item
          name='contactEmail'
          label='Contact Email'
          rules={[
            {
              type: 'email',
              message: 'Invalid email address',
            },
          ]}
        >
          <Input placeholder='Input Contact Email' />
        </Form.Item>

        <Form.Item
          name='contactPhone'
          label={
            <div>
              Contact Phone
            </div>
          }
        >
          <Input placeholder='Contact Phone Number' />
        </Form.Item>

        <Form.Item name='description' label='Description'>
          <Input.TextArea placeholder='Input description' rows={4} />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

const getUomEnum = (enums) => {
  const foundEnum = enums.find((item) => item.enumName === 'BasicUomEnum');
  return foundEnum?.enumProperties ?? [];
};

export default AddProductToApl;
