import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Typography } from 'antd';
import { StyledModal, dialogFunction } from 'common/components';
import {
  RoleList,
  RoleDescription,
  RolePermissionList,
} from 'pages/role/components';

import RemoveUserRoleAction from './RemoveUserRoleAction';

import * as roleActions from 'pages/role/controllers/actions';
import * as roleSelectors from 'pages/role/controllers/selectors';

import * as securityServices from 'services/roles-security';

import { useHandleReloadPage } from 'hooks/useReloadPage';

import { apiHandler } from 'utils/api';

import Messages from 'i18n/messages/maintenance';
import { useIntl } from 'react-intl';

import './RemoveRoleFromUserModal.less';

/**
 * ! MAIN COMPONENT
 * @param {*} props
 * @returns
 */
const RemoveRoleFromUserModal = (props) => {
  const intl = useIntl();
  const shouldReloadRef = useRef(false);
  const { isVisible, toggleModal, selectedUser } = props;

  const dispatch = useDispatch();

  const [__, setReloadGrid] = useHandleReloadPage();

  const [assignedRoles, setAssignedRoles] = useState([]);

  const selectedRole = useSelector(roleSelectors.selectSelectedRole());

  const roleList = useSelector(roleSelectors.selectRoleList());

  const roleListData = roleList?.data;

  const onClickRemoveCallback = () => {
    confirmRemoveMemberToRole();
    updateShouldReload(true);
  };

  const onCloseModal = () => {
    toggleModal();
    dispatch(roleActions.setSelectedRole(null));
    if (shouldReloadRef.current) setReloadGrid();
    updateShouldReload(false);
  };

  const initAssignedRoles = () => {
    if (!isVisible) return;

    const assignRoles = getUserAssignedRole();

    setAssignedRoles(assignRoles);
  };

  const getUserAssignedRole = () => {
    const userRoles = selectedUser?.roles?.split(',');
    if (!userRoles?.length) return [];
    if (!roleListData?.length) return [];

    const assignedRoleData = roleListData.reduce(
      (accumulator, currentMemberRoleItem) => {
        const isRoleAssigned = userRoles.some(
          (userRoleItem) => currentMemberRoleItem.displayName === userRoleItem
        );

        if (isRoleAssigned) accumulator.push(currentMemberRoleItem);

        return accumulator;
      },
      []
    );

    return assignedRoleData;
  };

  const removeFromAssignedRole = () => {
    setAssignedRoles((prevState) => {
      return prevState.filter((roleItem) => {
        return roleItem.id !== selectedRole.id;
      });
    });
  };

  const handleRemoveRoleFromUser = async () => {
    const params = {
      roleIds: [selectedRole.id],
      userId: selectedUser.id,
    };

    const successCallback = () => {
      removeFromAssignedRole();
      updateShouldReload(true);
    };

    await apiHandler({
      service: securityServices.removeRolesFromUser,
      params,
      successMessage: intl.formatMessage(Messages.removeUserRoleSuccess),
      errorMessage: intl.formatMessage(Messages.removeUserRoleError),
      successCallback,
    });
  };

  const updateShouldReload = (value) => {
    shouldReloadRef.current = value;
  };

  const confirmRemoveMemberToRole = () => {
    const infoContent = (
      <Typography.Paragraph>
        {intl.formatMessage(Messages.removeUserRoleConfirm)}
      </Typography.Paragraph>
    );

    dialogFunction({
      type: 'warn',
      content: infoContent,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleRemoveRoleFromUser,
    });
  };

  useEffect(() => {
    //* reset selected member role
    const resetSelectedMemberRole = () => {
      if (!isVisible) dispatch(roleActions.setSelectedMemberRole(null));
    };

    const getMemberRoleList = () => {
      if (isVisible)
        dispatch(roleActions.getRoleList({ memberId: selectedUser?.memberId }));
    };

    resetSelectedMemberRole();
    getMemberRoleList();
  }, [isVisible]);

  useEffect(() => {
    initAssignedRoles();
  }, [isVisible, roleListData, selectedUser]);

  const modalTitle = `Remove User Role${
    selectedUser?.firstName ? ' - ' + selectedUser.firstName : ''
  }`;

  return (
    <StyledModal
      className='remove-role-from-user-modal'
      title={modalTitle}
      visible={isVisible}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={onCloseModal}
      width={900}
      footer={null}
    >
      <RemoveRoleModalContent
        assignedRoles={assignedRoles}
        memberId={selectedUser?.memberId}
        selectedRole={selectedRole}
        onClickRemoveCallback={onClickRemoveCallback}
      />
    </StyledModal>
  );
};

/**
 * ! SUB COMPONENT
 * @param {*} props
 * @returns
 */
const RemoveRoleModalContent = (props) => {
  const { selectedRole, assignedRoles, memberId, onClickRemoveCallback } =
    props;

  const dispatch = useDispatch();

  useEffect(() => {
    //* get user's member role list
    //* get all member (avail) permission
    const getAllMemberPermissionList = () => {
      dispatch(roleActions.getAvailablePermissions());
    };

    getAllMemberPermissionList();
  }, []);

  useEffect(() => {
    if (selectedRole?.id) {
      dispatch(roleActions.getRolePermissionList({ roleId: selectedRole?.id }));
    }
  }, [selectedRole?.id]);

  const viewType = 'role-management';

  return (
    <div className='remove-role-from-user-modal'>
      <Row gutter={[5, 5]} style={{ flex: 'auto', overflow: 'hidden' }}>
        <Col xs={10} style={{ height: '100%' }}>
          <RemoveUserRoleAction
            onClickRemoveCallback={onClickRemoveCallback}
            assignedRoles={assignedRoles}
          />
          <div className='remove-role-from-user-modal__role-list'>
            <RoleList
              title='role list'
              roleListData={assignedRoles}
              selectedRole={selectedRole}
              viewType={viewType}
            />
          </div>
        </Col>
        <Col
          xs={14}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div className='remove-role-from-user-modal__role-desc'>
            <RoleDescription
              title='Role Description'
              selectedRole={selectedRole}
            />
          </div>

          <div className='remove-role-from-user-modal__permission-list'>
            <RolePermissionList
              title='Permission List'
              selectedRole={selectedRole}
              memberIdParams={memberId}
              showEditPage={false}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}></Col>
      </Row>
    </div>
  );
};

export default RemoveRoleFromUserModal;
