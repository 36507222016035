import React from 'react';

import { Row, Typography } from 'antd';

const { Text } = Typography;

const SupplierManagerHintMessage = (props) => {
  const { children } = props;

  return (
    <Row style={{ height: '100%' }}>
      <Text type='secondary' style={{ margin: 'auto', fontSize: 18 }}>
        {children}
      </Text>
    </Row>
  );
};

export default SupplierManagerHintMessage;
