import React from 'react';

import {
  useCheckMemberSupplier,
  useCheckIsSuperAdmin,
  useCheckIsSuperMember,
} from 'hooks';

const useCheckIsShowForSupplierMember = () => {
  const isMemberSupplierUser = useCheckMemberSupplier();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const isSuperMember = useCheckIsSuperMember();

  if (isSuperMember || isSuperAdmin) {
    return true;
  }

  if (isMemberSupplierUser) {
    return false;
  }

  return true;
};

const IsShowForSupplierMemberWrap = ({ children }) => {
  const isShow = useCheckIsShowForSupplierMember();

  return <> {isShow ? children && children : null}</>;
};

export { useCheckIsShowForSupplierMember, IsShowForSupplierMemberWrap };
