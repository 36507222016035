import React, { useEffect, useState } from 'react';
import { message, Dropdown, Menu, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { dialogFunction } from 'common/components';
import { injectIntl } from 'react-intl';

import { getLinkDownloadAsset } from 'services/digitalAsset';
import * as api from 'config/axios';
import DigitalRight from 'assets/DRM.png';

const { Text } = Typography;

const defaultAsset = 'Original';

const AssetPanelDownload = ({ assetData, intl }) => {
  const { id, fileUrl, fileType, downloadFormats } = assetData;

  const downloadOriginalFormat = async () => {
    const params = `${fileUrl}?dl=1`;
    try {
      await api.sendDownload({
        url: params,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const downloadAnotherFormat = async (key) => {
    try {
      const response = await getLinkDownloadAsset(id, key);
      if (response.isSuccess) {
        api.sendDownload({
          url: response.data.url,
        });
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const addMissingFileType = () => {
    if (!fileType) return;
    const upCaseFileType = fileType.toUpperCase();
    return Array.from(new Set([...downloadFormats, upCaseFileType]?.sort()));
  };

  const listTypeDownload = addMissingFileType() ?? [];

  const triggerDownload = (key) => {
    if (key === 'Original' && fileUrl) {
      downloadOriginalFormat();
    } else {
      downloadAnotherFormat(key.toLowerCase());
    }
  };

  const menu = (
    <Menu
      onClick={({ key }) => {
        assetData?.drm ? handlePreDownload(key) : triggerDownload(key);
      }}
    >
      {listTypeDownload.map((format) => (
        <Menu.Item key={format}>
          <Text>{format}</Text>
        </Menu.Item>
      ))}
    </Menu>
  );

  function handlePreDownload(data) {
    dialogFunction({
      type: 'warn',
      content: (
        <div style={{ fontSize: 13 }}>
          <img
            src={DigitalRight}
            alt='digital-right'
            height={20}
            width={20}
            style={{ marginRight: 5 }}
          />
          {intl.formatMessage({
            id: 'Taco.home.ribbon.dialogDeleteDRMsingle',
          })}
        </div>
      ),

      okText: 'Cancel',
      onCancel: () => triggerDownload(data),
      cancelText: 'Download',
    });
  }

  return (
    <Dropdown
      overlay={menu}
      disabled={disableDownloadButton(assetData)}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <DownloadOutlined
        style={{ fontSize: 24 }}
        onClick={() => {
          assetData?.drm
            ? handlePreDownload(defaultAsset)
            : triggerDownload(defaultAsset);
        }}
      />
    </Dropdown>
  );
};

const disableDownloadButton = (assetData) => {
  if (assetData?.isIFrame) return true;
  return false;
};

export default injectIntl(AssetPanelDownload);
