// import { v4 as uuidv4 } from 'uuid';
import { deleteObjectField } from 'utils';
import { INGREDIENT_LIST_ERRORS } from 'pages/qa-spec/constant';
import { sum, toNumber, isEqual, sortBy, cloneDeep } from 'lodash';
import { getAllRows } from 'pages/qa-spec/utils';

import produce from 'immer';
import { REQUIRED_FIELDS_QA_INGREDIENT } from 'pages/qa-spec/components/qa-ingredient/requiredFieldsQaIngredient';

export const getHierarchyIngredientData = (data, isUpdate) => {
  let topLevel = 0;
  let levels = [];
  let prevLevels;
  let allowRemoveIngredientPartOf = false;

  const procData = data.map((dataItem) => {
    let newDataItem = deleteObjectField(
      dataItem,
      ['levelIdx', 'level', 'done'],
      true
    );

    newDataItem = {
      ...newDataItem,
      isParent: !dataItem?.ingredientsPartOf ? true : false,
      isChild: dataItem?.ingredientsPartOf ? true : false,
    };
    return newDataItem;
  });

  const getHierarchyItem = (data) => {
    if (isEqual(prevLevels, levels)) {
      allowRemoveIngredientPartOf = true;
    }
    prevLevels = levels;
    const nextData = data.map((dataItem) => {
      let nextDataItem = {
        ...dataItem,
      };

      // I don't know this line code
      // if (!isUpdate) {
      //   nextDataItem = { ...nextDataItem, id: uuidv4() };
      // }

      if (dataItem?.done === true) {
        return dataItem;
      }

      if (dataItem?.ingredientsPartOf === null) {
        topLevel++;
        const nextLevel = {
          ...nextDataItem,
          levelIdx: `${topLevel}`,
          level: topLevel,
          done: true,
        };
        levels.push(nextLevel);
        return nextLevel;
      } else {
        let parentLevel;
        let childLevel;

        levels.forEach((levelItem) => {
          const transformValueLevelItem =
            transformValueIngredientsPartOf(levelItem);

          if (transformValueLevelItem === dataItem?.ingredientsPartOf) {
            parentLevel = levelItem;
          }

          if (
            levelItem?.ingredientsPartOf ===
            transformValueIngredientsPartOf(parentLevel)
            // dataItem?.nameOfIngredients !== levelItem?.nameOfIngredients
          ) {
            childLevel = levelItem;
          }
        });

        let nextLevel = {};

        if (!parentLevel) {
          if (allowRemoveIngredientPartOf) {
            return { ...nextDataItem, ingredientsPartOf: null };
          }
          return nextDataItem;
        }

        if (!childLevel) {
          nextLevel = {
            ...nextDataItem,
            levelIdx: `${parentLevel?.levelIdx}.1`,
            level: 1,
            done: true,
          };
        } else {
          nextLevel = {
            ...nextDataItem,
            levelIdx: `${parentLevel?.levelIdx}.${childLevel?.level + 1}`,
            level: childLevel?.level + 1,
            done: true,
          };
        }

        levels.push(nextLevel);

        return nextLevel;
      }
    });

    if (nextData.some((nextDataItem) => !nextDataItem?.done)) {
      allowRemoveIngredientPartOf = false;
      return getHierarchyItem([...nextData]);
    } else {
      return nextData;
    }
  };

  const result = getHierarchyItem(procData);

  const sortedResult = sortBy(result, [
    (dataItem) => {
      const splitLevelIdx = dataItem?.levelIdx?.split(/\b(\d+)\b/);
      for (let i = 1; i < splitLevelIdx?.length; i += 2) {
        splitLevelIdx[i] = splitLevelIdx[i]?.padStart(16, '0');
      }
      return splitLevelIdx?.join('');
    },
  ]).map((dataItem) => ({
    ...dataItem,
    isParent: !dataItem?.ingredientsPartOf ? true : false,
    isChild: dataItem?.ingredientsPartOf ? true : false,
  }));
  return sortedResult;
};

/**
 * ? validate ingredient grid
 * @param {*} gridInst
 * @param {*} rowDataItem
 * @returns
 */

export const removeErrors = (rowItem, errors) => {
  const newError = (rowItem?.error || []).filter(
    (errorItem) => !errors.includes(errorItem)
  );
  return { ...rowItem, error: newError };
};

export const addErrors = (rowItem, errors) => {
  return { ...rowItem, error: [...(rowItem?.error || []), ...errors] };
};

export const validateIngredientGrid = (gridInst, rowDataItem) => {
  const allRowData = getAllRows(gridInst);

  const validateSameIngredientName = () => {
    const parentRows = allRowData.filter((row) => row?.isParent);

    const validatedRowData = allRowData.map((rowItem) => {
      const foundSameItem = parentRows.findIndex((item) => {
        return (
          item?.nameOfIngredients === rowItem?.nameOfIngredients &&
          item?.id !== rowItem?.id && // don't check row itself
          rowItem?.isParent // only check parent row
        );
      });

      if (foundSameItem !== -1) {
        return addErrors(rowItem, [
          INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EXISTED,
        ]);
      }
      return removeErrors(rowItem, [
        INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EXISTED,
      ]);
    });

    gridInst.current.api.applyTransaction({
      update: validatedRowData,
    });
  };

  const validateIngredientNameEmpty = ({
    event,
    idx,
    isProductAssignedByRetailer = false,
  }) => {
    const typeError = isProductAssignedByRetailer
      ? REQUIRED_FIELDS_QA_INGREDIENT.nameOfIngredients.message
      : INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EMPTY;

    const nameOfIngredient = rowDataItem?.nameOfIngredients;

    let nextRowDataItem = { ...rowDataItem };

    let nextRowData = [...getAllRows(gridInst)];

    if (!nameOfIngredient || nameOfIngredient === '') {
      nextRowDataItem = addErrors(nextRowDataItem, [typeError]);
      if (event) {
        // reset the children's ingredient parts of
        if (event) {
          nextRowData = nextRowData.map((rowDataItem) => {
            const transformEventOldValue = transformValueIngredientsPartOf({
              nameOfIngredients: event?.oldValue,
              id: event?.data?.id,
            });

            if (rowDataItem?.ingredientsPartOf === transformEventOldValue) {
              return {
                ...rowDataItem,
                ingredientsPartOf: null,
              };
            } else {
              return rowDataItem;
            }
          });
          nextRowData[event.rowIndex] = nextRowDataItem;
        } else {
          nextRowData[idx] = nextRowDataItem;
        }

        nextRowData[event.rowIndex] = nextRowDataItem;
      }
    } else {
      nextRowDataItem = removeErrors(nextRowDataItem, [typeError]);

      if (event) {
        nextRowData = nextRowData.map((rowDataItem) => {
          const transformEventOldValue = transformValueIngredientsPartOf({
            nameOfIngredients: event?.oldValue,
            id: event?.data?.id,
          });

          const transformEventNewValue = transformValueIngredientsPartOf({
            nameOfIngredients: event?.value,
            id: event?.data?.id,
          });
          if (
            Boolean(rowDataItem?.ingredientsPartOf) &&
            rowDataItem?.ingredientsPartOf === transformEventOldValue
          ) {
            return {
              ...rowDataItem,
              ingredientsPartOf: transformEventNewValue,
            };
          } else {
            return rowDataItem;
          }
        });
        nextRowData[event.rowIndex] = nextRowDataItem;
      } else {
        nextRowData[idx] = nextRowDataItem;
      }
    }

    gridInst.current.api.applyTransaction({
      update: nextRowData,
    });
  };

  const checkIngredientGridHaveError = () => {
    const allRowData = getAllRows(gridInst);

    return allRowData.some((rowDataItem) => rowDataItem?.error?.length > 0);
  };

  return {
    validateSameIngredientName,
    validateIngredientNameEmpty,
    checkIngredientGridHaveError,
  };
};

const getNewRowDataAfterSettingError = ({ allRows, index, error }) => {
  return produce(allRows, (draftState) => {
    draftState[index].error = error;
  });
};

export const validateRequiredFieldForSupplier = ({
  gridInst,
  event,
  requiredFieldError,
}) => {
  const allRows = getAllRows(gridInst);

  let validatedRowData = allRows;

  const currentValue = event?.value;
  const selectedId = event?.data?.id;

  const foundSelectedRow = allRows.find(
    (rowData) => rowData?.id === selectedId
  );
  const foundIdx = allRows.findIndex((rowData) => rowData?.id === selectedId);

  if (!currentValue || currentValue?.length === 0) {
    let foundSelectedRowError = foundSelectedRow?.error ?? [];

    const isExistMessage =
      foundSelectedRowError?.findIndex(
        (error) => error === requiredFieldError
      ) !== -1;

    const newError = isExistMessage
      ? foundSelectedRowError
      : foundSelectedRowError?.concat(requiredFieldError);

    validatedRowData = getNewRowDataAfterSettingError({
      allRows: validatedRowData,
      index: foundIdx,
      error: newError,
    });
  } else {
    const newError = foundSelectedRow?.error?.filter(
      (error) => error !== requiredFieldError
    );

    validatedRowData = getNewRowDataAfterSettingError({
      allRows: validatedRowData,
      index: foundIdx,
      error: newError,
    });
  }

  gridInst.current.api.applyTransaction({
    update: cloneDeep(validatedRowData),
  });
};

export const validateWholeIngredientGrid = (
  gridInst,
  isProductAssignedByRetailer = false
) => {
  const allRowData = getAllRows(gridInst);

  allRowData.forEach((rowDataItem, idx) => {
    validateIngredientGrid(gridInst, rowDataItem).validateIngredientNameEmpty({
      event: null,
      idx,
      isProductAssignedByRetailer,
    });
  });

  validateIngredientGrid(gridInst).validateSameIngredientName();
};

export const getIngredientPercentTotal = (data, gridInst) => {
  const allRowData = gridInst ? getAllRows(gridInst) : data;
  const allPercent = allRowData.map((rowDataItem) => {
    if (rowDataItem?.ingredientsPartOf) {
      return null;
    } else {
      return toNumber(rowDataItem?.percentInFinalProduct);
    }
  });

  return sum(allPercent);
};

export const transformValueIngredientsPartOf = (row) =>
  `${row?.nameOfIngredients}.${row?.id}`;

export const validatedRowData = ({ rowData, changedRow, changedValue }) => {
  let result = [];

  rowData.forEach((row) => {
    if (row?.id === changedRow?.id) {
      // child
      if (changedValue) {
        const newRow = {
          ...removeErrors(row, [
            INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EXISTED,
          ]),
          isChild: true,
          isParent: false,
        };
        result.push(newRow);
      } else {
        const newRow = {
          ...row,
          isChild: false,
          isParent: true,
        };
        result.push(newRow);
      }
    } else {
      result.push(row);
    }
  });

  const parentRows = result.filter((row) => row?.isParent);

  return result.map((rowItem) => {
    const foundSameItem = parentRows.findIndex((item) => {
      return (
        item?.nameOfIngredients === rowItem?.nameOfIngredients &&
        item?.id !== rowItem?.id && // don't check row itself
        rowItem?.isParent // only check parent row
      );
    });

    if (foundSameItem !== -1) {
      return addErrors(rowItem, [
        INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EXISTED,
      ]);
    }
    return removeErrors(rowItem, [
      INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EXISTED,
    ]);
  });
};

export const mappingInitialIngredientList = (ingredients = []) => {
  return ingredients?.map((ingredient) => {
    if (ingredient?.ingredientsPartOf) {
      return {
        ...ingredient,
        ingredientsPartOf: `${ingredient?.ingredientsPartOf}.${ingredient?.ingredientsPartOfId}`,
      };
    }
    return ingredient;
  });
};
