import * as api from 'config/axios';
import * as endpoints from './endpoints';

import { LAYOUT_ADVANCED_SEARCH } from 'static/Constants';

// Get Folder List
export const getReportingList = (params) => {
  return api.sendPost(endpoints.QUERY_GRID_VIEW, params);
};

export const cloneReporting = (params) => {
  return api.sendPost(endpoints.QUERY_REPORTING_CLONE, params);
};

export const deleteReportingQuery = (params) => {
  return api.sendPost(endpoints.DELETE_REPORTING_QUERY, params);
};

export const getProductSearchableFields = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_SEARCHABLE_FIELDS, {
    params: params,
  });
};

export const getMemberSearchableFields = (params) => {
  return api.sendGet(endpoints.GET_MEMBER_SEARCHABLE_FIELDS, {
    params: params,
  });
};

export const getAssetSearchableFields = (params) => {
  return api.sendGet(endpoints.GET_ASSET_SEARCHABLE_FIELDS, {
    params: params,
  });
};

export const getReportingSearchableFields = (params) => {
  return api.sendGet(endpoints.GET_REPORTING_SEARCHABLE_FIELDS, {
    params: params,
  });
};

export const getFolderSearchableFields = (params) => {
  return api.sendGet(endpoints.GET_FOLDER_SEARCHABLE_FIELDS, {
    params: params,
  });
};

export const getReportingDetailPane = (params) => {
  return api.sendGet(endpoints.GET_REPORTING_DETAIL_PANE, {
    params: params,
  });
};

export const saveReportingQuery = (params) => {
  return api.sendPost(endpoints.SAVE_REPORTING_QUERY, params);
};

export const editReportingQuery = (params) => {
  return api.sendPost(endpoints.EDIT_REPORTING_QUERY, params);
};

export const exportReporting = (params) => {
  return api.sendPost(endpoints.EXPORT_REPORTING, params);
};

export const runReporting = (entityType, params) => {
  const endpointByEntityType = {
    [LAYOUT_ADVANCED_SEARCH.PRODUCT]: endpoints.RUN_PRODUCT_REPORTING,
    [LAYOUT_ADVANCED_SEARCH.MEMBER]: endpoints.RUN_MEMBER_REPORTING,
    [LAYOUT_ADVANCED_SEARCH.ASSET]: endpoints.RUN_ASSET_REPORTING,
  };

  return api.sendPost(endpointByEntityType[entityType], params);
};
