import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
} from 'react';

import { produce } from 'immer';
import { Space, Typography, Row, Col } from 'antd';

import {
  dialogFunction,
  FullWidthSpace,
  CarouselSection,
  CarouselNext,
  CarouselPrevious,
  FormAddButton,
  FormEditButton,
  FormDeleteButton,
} from 'common/components';

import QaFormulaDrugForm from './QaFormulaDrugForm';
import QaFormulaDrugModal from './QaFormulaDrugModal';

const QaFormulaDrug = forwardRef((props, ref) => {
  const carouselRef = useRef();
  const drugFactFormRef = useRef();

  const { drugFactList = [], snapshotDrugFactList, isEdit } = props;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [drugFactListState, setDrugFactListState] = useState([]);

  const [formulaDrugModalStatus, setFormulaDrugModalStatus] =
    useState('closed');

  //* editing data
  const currentSlideData = useMemo(() => {
    if (!drugFactListState?.length) return null;
    if (formulaDrugModalStatus !== 'edit') return null;

    return drugFactListState[currentSlide];
  }, [currentSlide, formulaDrugModalStatus, drugFactListState]);

  const showFormulaDrugModal = (status) => {
    setFormulaDrugModalStatus(status);
  };

  const closeFormulaDrugModal = () => {
    setFormulaDrugModalStatus('closed');
  };

  // const deleteDrugFact = () => {
  //   setDrugFactListState((prevState) => {
  //     return prevState.filter((_, index) => {
  //       return index !== currentSlide;
  //     });
  //   });
  // };

  const onClickPrev = () => {
    if (carouselRef?.current) carouselRef.current.prev();
  };

  const onClickNext = () => {
    if (carouselRef?.current) carouselRef.current.next();
  };

  const onChangeSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  const onClickAdd = () => {
    showFormulaDrugModal('add');
  };

  const onClickEdit = () => {
    showFormulaDrugModal('edit');
  };

  // const onClickDelete = () => {
  //   dialogFunction({
  //     type: 'warn',
  //     content: 'Are you sure you want to delete this Drug Fact?',
  //     onOk: deleteDrugFact,
  //   });
  // };

  const onSubmitFormulaDrugForm = (drugFactValue) => {
    const isEditing = !!currentSlideData;

    setDrugFactListState((prevState) => {
      const newState = produce(prevState, (draft) => {
        //* add new drug fact
        if (!isEditing) {
          draft.push(drugFactValue);
          return;
        }
        const editedData = draft[currentSlide];

        draft[currentSlide] = { ...editedData, ...drugFactValue };
      });

      return newState;
    });

    closeFormulaDrugModal();
  };

  const isPrevDisabled = currentSlide === 0;
  const isNextDisabled =
    !drugFactListState?.length ||
    currentSlide === drugFactListState?.length - 1;
  const isEditDisabled = drugFactListState?.length === 0;
  // const isDeleteDisabled = drugFactListState.length === 0;

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        setDrugFactListState(drugFactList || []);
      },
      getDrugFactData: () => {
        return drugFactListState;
      },
    };
  });

  useEffect(() => {
    if (drugFactList?.length) setDrugFactListState(drugFactList);
  }, [JSON.stringify(drugFactList)]);

  return (
    <FullWidthSpace className='formula-drug'>
      <Row>
        <Col flex={0}>
          <Space>
            <CarouselPrevious disabled={isPrevDisabled} onClick={onClickPrev} />
            <CarouselNext disabled={isNextDisabled} onClick={onClickNext} />
            <Typography.Text strong>
              Total: {drugFactListState.length} Drug Fact(s)
            </Typography.Text>
          </Space>
        </Col>
        <Col flex={1}></Col>
        <Col flex={0}>
          {isEdit ? (
            <Space>
              <FormAddButton onClick={onClickAdd} />
              <FormEditButton disabled={isEditDisabled} onClick={onClickEdit} />
              {/* <FormDeleteButton
                disabled={isDeleteDisabled}
                onClick={onClickDelete}
              /> */}
            </Space>
          ) : null}
        </Col>
      </Row>

      {drugFactListState.length ? (
        <CarouselSection
          ref={carouselRef}
          fade
          lazyLoad
          slidesToShow={1}
          afterChange={onChangeSlide}
        >
          {drugFactListState?.map((drugFactItem, index) => {
            return (
              <QaFormulaDrugForm
                key={index}
                ref={drugFactFormRef}
                isEdit={false}
                drugFactData={drugFactItem}
                drugFactSnapshotData={snapshotDrugFactList?.[index]}
              />
            );
          })}
        </CarouselSection>
      ) : (
        <QaFormulaDrugForm
          ref={drugFactFormRef}
          isEdit={false}
          drugFactData={null}
        />
      )}

      <QaFormulaDrugModal
        visible={formulaDrugModalStatus !== 'closed'}
        drugData={currentSlideData}
        snapshotDrugData={
          formulaDrugModalStatus !== 'add'
            ? snapshotDrugFactList?.[currentSlide] || {}
            : null
        }
        closeModal={closeFormulaDrugModal}
        onSubmit={onSubmitFormulaDrugForm}
        mode={formulaDrugModalStatus}
      />
    </FullWidthSpace>
  );
});

export default QaFormulaDrug;
