import React from 'react';
import { useSelector } from 'react-redux';

import * as selectors from 'pages/company-profile/controllers/selectors';

import { RibbonBar, RibbonDivider, RibbonSection } from 'common/components';
import {
  OtherLayout,
  ViewLayout,
  AdvanceStack,
  PimActions,
} from './components';
import ActionsRibbonBar from 'common/components/contact-action/ActionsRibbonBar';
import Addition from './components/sections/home-grid/AdditionSection';
import MemberLayout from './components/sections/member-full/MemberLayout';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import ShowProducts from 'pages/home/ribbon/components/controls/member-grid/ShowProducts';

import ShowAssets from './components/controls/member-grid/ShowAssets';

import { useFolderByLevel } from 'hooks/useFolderByLevel';

import { CanShow } from 'common/components/wrapper/show-condition';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const MemberFull = () => {
  const disabledBackControl = window.location.pathname === '/company';

  const memberInfo = useSelector(selectors.selectMemberProfile());

  const memberId = memberInfo?.id;

  const { shouldKeepFolderBreadcrumb, keepFolderBreadcrumb } =
    useFolderByLevel();

  const closeDetailCallback = () => {
    //* keep folder breadcrumb when close folder item detail
    shouldKeepFolderBreadcrumb && keepFolderBreadcrumb(true);
  };

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <RibbonDivider />
      <Addition isDisabled={true} />
      <ActionsRibbonBar />
      <RibbonDivider />
      <MemberLayout />
      <RibbonDivider />

      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <CanShow.SharedOnlyMember>
          <RibbonSection>
            <ShowProducts memberId={memberId} />
          </RibbonSection>
        </CanShow.SharedOnlyMember>
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.ASSET}>
        <ShowAssets companyId={memberId} />
      </Can>
      <RibbonDivider />
      <AdvanceStack isDisabled={true} />

      <OtherLayout
        ribbonSide='right'
        disableBackControl={disabledBackControl}
        closeDetailCallback={closeDetailCallback}
      />
    </RibbonBar>
  );
};

export default MemberFull;
