// Action Types
export const CHANGE_RIBBON_VIEW = 'CHANGE_RIBBON_VIEW';
export const CHANGE_RIBBON_VIEW_SUCCESS = 'CHANGE_RIBBON_VIEW_SUCCESS';
export const CHANGE_RIBBON_VIEW_ERROR = 'CHANGE_RIBBON_VIEW_ERROR';

export const CHANGE_RIBBON_TYPE = 'CHANGE_RIBBON_TYPE';
export const CHANGE_RIBBON_HEIGHT = 'CHANGE_RIBBON_HEIGHT';
export const SAVE_CLICK = 'SAVE_CLICK';

export const SET_CAN_BACK_ROUTE = 'SET_CAN_BACK_ROUTE';
export const CHANGE_RIBBON_MEMBER_LAYOUT = 'CHANGE_RIBBON_MEMBER_LAYOUT';

export const TOGGLE_BAN_LIST = 'TOGGLE_BAN_LIST';
export const ADD_TO_BAN_LIST = 'ADD_TO_BAN_LIST';

export const CREATE_CLOSE_DETAIL_STACK = 'CREATE_CLOSE_DETAIL_STACK';
export const UPDATE_CLOSE_DETAIL_STACK = 'UPDATE_CLOSE_DETAIL_STACK';
export const STORE_CURRENT_CLOSE_DETAIL_STACK_TO_SWITCH_STACK =
  'STORE_CURRENT_CLOSE_DETAIL_STACK_TO_SWITCH_STACK';
export const UPDATE_STORED_STACK = 'UPDATE_STORED_STACK';

export const UPDATE_RIBBON_DOM = 'UPDATE_RIBBON_DOM';
