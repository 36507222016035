import React from 'react';

import { CrownOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/apl';

import { RibbonButton } from 'common/components';

const SendAward = (props) => {
  return (
    <RibbonButton
      size='small'
      className='button-item-dropdown'
      icon={<CrownOutlined />}
      label={Messages.sendAward}
      disabled={true}
    />
  );
};

export default SendAward;
