import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';

import customCellComponents, {
  QueryLink,
} from 'pages/folders/components/custom-cell-components';

import { CustomNotification } from 'common/components';

import {
  FolderGridItemContainer,
  useFolderGridDetailView,
} from '../shared/grid-detail';

import { useCallbackRef } from 'hooks';
import { useGetColumnsGrid } from 'hooks/useGetColumns';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';

import * as folderSelectors from '../controllers/selectors';

import { formatMDYWithParam } from 'utils/formatDate';
import { formatSizeUnitByParam } from 'utils/formatSizeUnits';
import { sortFolderToTop } from '../shared/utils';

import { getFolderDetailGrid, getFavoriteFolderList } from 'services/folder';

import { entityTypeIcon } from 'utils/entityTypeIcon';
import { sleep } from 'utils/delay';

export const createServerSideDatasource = (blockSizeData) => {
  return {
    getRows: (params) => {
      const response = blockSizeData.getData(params.request);

      if (response.success) {
        params.success({
          rowData: response.rows,
          rowCount: response.lastRow,
        });
      } else {
        params.fail();
      }
    },
  };
};

export const createDataByBlockSize = (allData) => {
  return {
    getData: (request) => {
      const requestedRows = allData.slice(request.startRow, request.endRow);
      return {
        success: true,
        rows: requestedRows,
        lastRow: allData.length,
      };
    },
  };
};

const pathUrl = {
  folder: 'folder',
  product: 'product',
  asset: 'asset',
  member: 'company',
  reporting: 'reporting',
  query: 'query',
};

const mappingColumns = (columns = []) => {
  let result = [
    {
      field: '',
      checkboxSelection: true,
      filter: false,
      suppressMenu: true,
      resizable: false,
      allowFilter: false,
      allowSort: false,
    },
    {
      field: '',
      width: 85,
      cellRenderer: customCellComponents.renderFavSharePackIcons,
      filter: false,
      suppressMenu: true,
      resizable: false,
      allowFilter: false,
      allowSort: false,
    },
    {
      field: 'type',
      cellRenderer: (params) => {
        const { type } = params?.data ?? {};
        const isFolderType = type.toLowerCase() === 'folder';

        if (isFolderType) {
          return (
            <FolderGridItemContainer params={params}>
              {customCellComponents.renderFolderOptionIcon(params)}
            </FolderGridItemContainer>
          );
        }

        return (
          <div className='folder-detail-view__type'>{entityTypeIcon(type)}</div>
        );
      },
      filter: false,
      suppressMenu: true,
      width: 70,
      resizable: false,
      allowFilter: false,
      allowSort: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      resizable: true,
      cellRenderer: (params) => {
        const name = params?.data?.folderName ?? params?.data?.description;
        if (params?.data?.type?.toLowerCase() === 'query') {
          return <QueryLink value={name} data={{ id: params?.data?.id }} />;
        }

        const to = `${pathUrl[params?.data?.type?.toLowerCase()]}/${
          params?.data?.id
        }`;
        return (
          <Typography.Link href={to} target='_blank' strong>
            {params?.data?.folderName ?? params?.data?.description}
          </Typography.Link>
        );
      },
      minWidth: 300,
      flex: 1,
      allowFilter: true,
      allowSort: true,
    },
  ];

  const ShowColumns = [
    // 'description',
    'folderSize',
    'productCount',
    'assetCount',
    'creatorFullName',
    'memberName',
    'dateCreated',
    'lastUpdated',
    'drm',
  ];

  if (columns?.length > 0) {
    columns.forEach((col) => {
      if (ShowColumns.indexOf(col?.fieldNameCamelCase) > -1) {
        if (
          col.fieldNameCamelCase === 'creatorFullName' ||
          col.fieldNameCamelCase === 'memberName' ||
          col.fieldNameCamelCase === 'description'
        ) {
          col = { ...col, width: 150 };
        } else if (col.fieldNameCamelCase === 'folderSize') {
          col = {
            ...col,
            cellRenderer: formatSizeUnitByParam,
            width: 150,
          };
        } else if (
          col.fieldNameCamelCase === 'productCount' ||
          col.fieldNameCamelCase === 'assetCount'
        ) {
          col = { ...col, width: 150 };
        } else if (col.fieldNameCamelCase === 'drm') {
          col = { ...col, headerName: 'DRM', width: 150 };
        } else if (
          col.fieldNameCamelCase === 'dateCreated' ||
          col.fieldNameCamelCase === 'lastUpdated'
        ) {
          col = {
            ...col,
            cellRenderer: formatMDYWithParam,
            width: 150,
          };
        }

        // if (col.fieldNameCamelCase === 'description')
        //   col = { ...col, linkTo: '/folder/{id}', minWidth: 300, flex: 1 };
        result.push({ ...col, resizable: true });
      }
    });
  }

  return result;
};

const defaultFolderParams = {
  pageSize: 9999,
  pageIndex: 1,
  folderId: null,
  sort: [
    [
      {
        fieldName: 'id',
        isAscending: false,
      },
    ],
  ],
  filters: [],
  search: {
    searchText: '',
  },
};

const moveFolderToTop = (data) => {
  return data.slice().sort(sortFolderToTop);
};

export const useAgGridFolder = ({
  folderOption,
  searchText,
  typeView,
  requestParams: propsRequestParams,
}) => {
  const isDetailGridView = typeView === 'detailsview';

  const columns = useGetColumnsGrid(
    isDetailGridView ? 'folder-detail-grid' : ''
  );

  const gridApi = useFolderGridDetailView();
  const isFavoriteRoute = useCheckFavoriteRoute();

  const { folderBreadcrumb } = useSelector(
    folderSelectors.selectFolderListByLevel()
  );

  const isRoot = folderBreadcrumb?.length === 1;

  const columnDefs = useMemo(() => mappingColumns(columns), [columns]);

  const [shouldReloadAgGrid, setShouldReloadAgGrid] = useState(false);
  const [requestParamsFolder, setRequestParamsFolder] = useState({
    folderId: null,
  });

  const getFolderDetailService =
    isFavoriteRoute && isRoot ? getFavoriteFolderList : getFolderDetailGrid;

  const fetchRootFolders = async (agGridParams) => {
    const folderParams = {
      ...defaultFolderParams,
      ...propsRequestParams,
      sort: searchText
        ? []
        : [
            {
              fieldName: 'id',
              isAscending: false,
            },
          ],
      folderOption,
      search: {
        searchText,
      },
    };

    const responseData = await getFolderDetailService(folderParams);

    if (responseData?.isSuccess) {
      const folderData = responseData?.data?.gridData ?? [];

      const blockSizeData = createDataByBlockSize(folderData);
      const datasource = createServerSideDatasource(blockSizeData);

      agGridParams.api.setServerSideDatasource(datasource);
    } else {
      agGridParams.api.setServerSideDatasource([]);
      CustomNotification.error(responseData?.message ?? 'Something went wrong');
    }
  };

  const getSubFolderContent = async (agGridParams) => {
    //* get subfolder content
    const currentBreadcrumb = folderBreadcrumb[folderBreadcrumb.length - 1];
    const folderId = currentBreadcrumb?.id;

    const folderParams = {
      ...defaultFolderParams,
      ...propsRequestParams,
      sort: searchText
        ? []
        : [
            {
              fieldName: 'id',
              isAscending: false,
            },
          ],
      search: {
        searchText,
      },
      folderOption,
      folderId,
    };

    const responseData = await getFolderDetailService(folderParams);

    if (responseData?.isSuccess) {
      const folderList = responseData?.data?.gridData ?? [];
      const sortedFolderList = moveFolderToTop(folderList);

      const blockSizeData = createDataByBlockSize(sortedFolderList);
      const datasource = createServerSideDatasource(blockSizeData);

      agGridParams.api.setServerSideDatasource(datasource);
    } else {
      agGridParams.api.setServerSideDatasource([]);
      CustomNotification.error(responseData?.message ?? 'Something went wrong');
    }
  };

  /* 
    I don't want use this useEffect, because AgGrid force me
  */
  useEffect(() => {
    if (!isDetailGridView) return;

    if (folderBreadcrumb?.length > 1) {
      const currentBreadcrumb = folderBreadcrumb[folderBreadcrumb.length - 1];
      const folderId = currentBreadcrumb?.id;

      setRequestParamsFolder((prevVal) => ({
        ...prevVal,
        folderId,
      }));
    } else {
      setRequestParamsFolder((prevVal) => ({
        ...prevVal,
        ...propsRequestParams,
        folderId: null,
      }));
    }
  }, [folderBreadcrumb?.length, JSON.stringify(propsRequestParams)]);

  const onGridReady = useCallbackRef(async (params) => {
    if (params?.api) {
      gridApi.current = params?.api;
    }

    if (isRoot) {
      fetchRootFolders(params);
    } else {
      getSubFolderContent(params);
    }
  });

  const handleToggleReloadAgGrid = async () => {
    setShouldReloadAgGrid(true);
    await sleep(200);
    setShouldReloadAgGrid(false);
  };

  const onSortChanged = useCallbackRef(async () => {
    if (isRoot) {
      setRequestParamsFolder((prevVal) => ({
        ...prevVal,
        folderId: null,
      }));
    } else {
      const currentBreadcrumb = folderBreadcrumb[folderBreadcrumb.length - 1];
      const folderId = currentBreadcrumb?.id;
      setRequestParamsFolder((prevVal) => ({
        ...prevVal,
        folderId,
      }));
    }
    handleToggleReloadAgGrid();
  });

  const onFilterChanged = useCallbackRef(async () => {
    if (isRoot) {
      setRequestParamsFolder((prevVal) => ({
        ...prevVal,
        folderId: null,
      }));
    } else {
      const currentBreadcrumb = folderBreadcrumb[folderBreadcrumb.length - 1];
      const folderId = currentBreadcrumb?.id;
      setRequestParamsFolder((prevVal) => ({
        ...prevVal,
        folderId,
      }));
    }
    handleToggleReloadAgGrid();
  });

  return {
    gridConfigProps: {
      onGridReady,
      serverSideStoreType: 'partial',
      cacheBlockSize: 50,
      blockLoadDebounceMillis: 500,
      notCheckTotalRecordAgGrid: true,
      onSortChanged,
      onFilterChanged,
      // maxBlocksInCache: 5,
    },
    requestParamsFolder,
    columnDefs,
    shouldReloadAgGrid,
  };
};
