export const GET_COLUMNS_REQUIRED_FIELDS = 'GET_COLUMNS_REQUIRED_FIELDS';
export const GET_COLUMNS_REQUIRED_FIELDS_SUCCESS =
  'GET_COLUMNS_REQUIRED_FIELDS_SUCCESS';
export const GET_COLUMNS_REQUIRED_FIELDS_FAIL =
  'GET_COLUMNS_REQUIRED_FIELDS_FAIL';

export const GET_REQUIRED_FIELDS = 'GET_REQUIRED_FIELDS';
export const GET_REQUIRED_FIELDS_SUCCESS = 'GET_REQUIRED_FIELDS_SUCCESS';
export const GET_REQUIRED_FIELDS_FAIL = 'GET_REQUIRED_FIELDS_FAIL';

export const TOGGLE_RELOAD_GRID = 'TOGGLE_RELOAD_GRID';

export const GET_REQUIRED_FIELD_SHORT_PANEL = 'GET_REQUIRED_FIELD_SHORT_PANEL';
export const GET_REQUIRED_FIELD_SHORT_PANEL_SUCCESS =
  'GET_REQUIRED_FIELD_SHORT_PANEL_SUCCESS';
export const GET_REQUIRED_FIELD_SHORT_PANEL_FAILURE =
  'GET_REQUIRED_FIELD_SHORT_PANEL_FAILURE';
