const CountryCode = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia, Plurinational State of',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Democratic Republic of the Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  PF: 'French Polynesia',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  HT: 'Haiti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Republic of Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Republic of Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russian',
  RW: 'Rwanda',
  KN: 'Saint Kitts and Nevis',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan, Province of China',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela, Bolivarian Republic of',
  VN: 'Viet Nam',
  VI: 'Virgin Islands',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const targetMarketCountryCode = {
  '004': 'Afghanistan',
  '008': 'Albania',
  '010': 'Antarctica',
  '012': 'Algeria',
  '016': 'American Samoa',
  '020': 'Andorra',
  '024': 'Angola',
  '028': 'Antigua and Barbuda',
  '031': 'Azerbaijan',
  '032': 'Argentina',
  '036': 'Australia',
  '040': 'Austria',
  '044': 'Bahamas',
  '048': 'Bahrain',
  '050': 'Bangladesh',
  '051': 'Armenia',
  '052': 'Barbados',
  '056': 'Belgium',
  '060': 'Bermuda',
  '064': 'Bhutan',
  '068': 'Bolivia, Plurinational State of',
  '070': 'Bosnia and Herzegovina',
  '072': 'Botswana',
  '074': 'Bouvet Island',
  '076': 'Brazil',
  '084': 'Belize',
  '086': 'British Indian Ocean Territory',
  '090': 'Solomon Islands',
  '092': 'Virgin Islands, British',
  '096': 'Brunei Darussalam',
  100: 'Bulgaria',
  104: 'Myanmar',
  108: 'Burundi',
  112: 'Belarus',
  116: 'Cambodia',
  120: 'Cameroon',
  124: 'Canada',
  132: 'Cape Verdean',
  136: 'Cayman Islands',
  140: 'Central African Republic',
  144: 'Sri Lanka',
  148: 'Chad',
  152: 'Chile',
  156: 'China',
  158: 'Taiwan, Province of China',
  162: 'Christmas Island',
  166: 'Cocos (Keeling) Islands',
  170: 'Colombia',
  174: 'Comoros',
  175: 'Mayotte',
  178: 'Congo',
  184: 'Cook Islands',
  180: 'Congo, the Democratic Republic of the',
  188: 'Costa Rica',
  191: 'Croatia',
  192: 'Cuba',
  196: 'Cyprus',
  203: 'Czech Republic',
  204: 'Benin',
  208: 'Denmark',
  231: 'Ethiopia',
  212: 'Dominica',
  214: "Dominican' Republic",
  218: 'Ecuador',
  222: 'El Salvador',
  226: 'Equatorial Guinea',
  232: 'Eritrea',
  233: 'Estonia',
  234: 'Faroe Islands',
  238: 'Falkland Islands (Malvinas)',
  239: 'South Georgia and the South Sandwich Islands',
  242: 'Fiji',
  246: 'Finland',
  249: 'Metropolitan France',
  248: 'Åland Islands',
  250: 'France',
  254: 'French Guiana',
  258: 'French Polynesia',
  260: 'French Southern Territories',
  262: 'Djibouti',
  266: 'Gabon',
  268: 'Georgia',
  270: 'Gambia',
  275: 'Occupied Palestinian Territory',
  276: 'Germany',
  288: 'Ghana',
  292: 'Gibraltar',
  296: 'Kiribati',
  300: 'Greece',
  304: 'Greenland',
  308: 'Grenada',
  312: 'Guadeloupe',
  316: 'Guam',
  320: 'Guatemala',
  324: 'Guinea',
  328: 'Guyana',
  332: 'Haiti',
  334: 'Heard Island and McDonald Islands',
  336: 'Holy See (Vatican City State)',
  340: 'Honduras',
  344: 'Hong Kong',
  348: 'Hungary',
  352: 'Iceland',
  356: 'India',
  360: 'Indonesia',
  364: 'Iran, Islamic Republic of',
  368: 'Iraq',
  372: 'Ireland',
  376: 'Israel',
  380: 'Italy',
  384: "Côte d'Ivoire",
  388: 'Jamaica',
  392: 'Japan',
  398: 'Kazakhstan',
  400: 'Jordan',
  404: 'Kenya',
  408: "Korea, Democratic People's Republic of",
  410: 'Korea, Republic of',
  414: 'Kuwait',
  417: 'Kyrgyzstan',
  418: "Lao People's Democratic Republic",
  422: 'Lebanon',
  426: 'Lesotho',
  428: 'Latvia',
  430: 'Liberia',
  434: 'Libyan Arab Jamahiriya',
  438: 'Liechtenstein',
  440: 'Lithuania',
  442: 'Luxembourg',
  446: 'Macao',
  450: 'Madagascar',
  454: 'Malawi',
  458: 'Malaysia',
  462: 'Maldives',
  466: 'Mali',
  470: 'Malta',
  474: 'Martinique',
  478: 'Mauritania',
  480: 'Mauritius',
  484: 'Mexico',
  492: 'Monaco',
  496: 'Mongolia',
  498: 'Moldova, Republic of',
  499: 'Montenegro',
  500: 'Montserrat',
  504: 'Morocco',
  508: 'Mozambique',
  512: 'Oman',
  516: 'Namibia',
  520: 'Nauru',
  524: 'Nepal',
  528: 'Netherlands',
  531: 'Curacao',
  533: 'Aruba',
  534: 'Sint-Maarten (Dutch part)',
  535: 'Bonaire, Saint Eustatius, SABA',
  540: 'New Caledonia',
  548: 'Vanuatu',
  554: 'New Zealand',
  558: 'Nicaragua',
  562: 'Niger',
  566: 'Nigeria',
  570: 'Niue',
  574: 'Norfolk Island',
  578: 'Norway',
  580: 'Northern Mariana Islands',
  581: 'United States Minor Outlying Islands',
  583: 'Micronesia, Federated States of',
  584: 'Marshall Islands',
  585: 'Palau',
  586: 'Pakistan',
  591: 'Panama',
  598: 'Papua New Guinea',
  600: 'Paraguay',
  604: 'Peru',
  608: 'Philippines',
  612: 'Pitcairn',
  616: 'Poland',
  620: 'Portugal',
  624: 'Guinea-Bissau',
  626: 'Timor-Leste',
  630: 'Puerto Rico',
  634: 'Qatar',
  638: 'Réunion',
  642: 'Romania',
  643: 'Russian Federation',
  646: 'Rwanda',
  652: 'Saint Barthélemy',
  654: 'Saint Helena',
  659: 'Saint Kitts and Nevis',
  660: 'Anguilla',
  662: 'Saint Lucia',
  663: 'Saint Martin (French part)',
  666: 'Saint Pierre and Miquelon',
  670: 'Saint Vincent and the Grenadines',
  674: 'San Marino',
  678: 'Sao Tome and Principe',
  682: 'Saudi Arabia',
  686: 'Senegal',
  688: 'Serbia',
  690: 'Seychelles',
  694: 'Sierra Leone',
  702: 'Singapore',
  703: 'Slovakia',
  704: 'Viet Nam',
  705: 'Slovenia',
  706: 'Somalia',
  710: 'South Africa',
  716: 'Zimbabwe',
  724: 'Spain',
  732: 'Western Sahara',
  736: 'Sudan',
  740: 'Suriname',
  744: 'Svalbard and Jan Mayen',
  748: 'Swaziland',
  752: 'Sweden',
  756: 'Switzerland',
  760: 'Syrian Arab Republic',
  762: 'Tajikistan',
  764: 'Thailand',
  768: 'Togo',
  772: 'Tokelau',
  776: 'Tonga',
  780: 'Trinidad and Tobago',
  784: 'United Arab Emirates',
  788: 'Tunisia',
  792: 'Turkey',
  795: 'Turkmenistan',
  796: 'Turks and Caicos Islands',
  798: 'Tuvalu',
  800: 'Uganda',
  804: 'Ukraine',
  807: 'Macedonia, the former Yugoslav Republic of',
  818: 'Egypt',
  826: 'United Kingdom',
  831: 'Guernsey',
  832: 'Jersey',
  833: 'Isle of Man',
  834: 'Tanzania, United Republic of',
  840: 'United States',
  850: 'Virgin Islands, U.S.',
  854: 'Burkina Faso',
  858: 'Uruguay',
  860: 'Uzbekistan',
  862: 'Venezuela, Bolivarian Republic of',
  876: 'Wallis and Futuna',
  882: 'Samoa',
  887: 'Yemen',
  894: 'Zambia',
};

export const languageCode = [
  'English',
  'Chinese',
  'Spanish',
  'Hindi',
  'Bengali',
  'Portuguese',
  'Russian',
  'Japanese',
  'Western Punjabi',
  'Marathi',
  'Telugu',
  'Turkish',
  'Korean',
  'French',
  'German',
  'Vietnamese',
  'Tamil',
  'Urdu',
  'Javanese',
  'Italian',
  'Egyptian Arabic',
  'Gujarati',
  'Iranian Persian',
  'Bhojpuri',
  'Hausa',
  'Kannada',
  'Indonesian',
  'Polish',
  'Yoruba',
  'Malayalam',
  'Odia',
  'Maithili',
  'Burmese',
  'Eastern Punjabi',
  'Sunda',
  'Sudanese Arabic',
  'Algerian Arabic',
  'Moroccan Arabic',
  'Ukrainian',
  'Igbo',
  'Northern Uzbek',
  'Sindhi',
  'North Levantine Arabic',
  'Romanian',
  'Tagalog',
  'Dutch',
  'Saʽidi Arabic',
  'Amharic',
  'Northern Pashto',
  'Magahi',
  'Thai',
  'Saraiki',
  'Khmer',
  'Chhattisgarhi',
  'Somali',
  'Cebuano',
  'Nepali',
  'Mesopotamian Arabic',
  'Assamese',
  'Sinhalese',
  'Northern Kurdish',
  'Hejazi Arabic',
  'Nigerian Fulfulde',
  'Bavarian',
  'South Azerbaijani',
  'Greek',
  'Chittagonian',
  'Kazakh',
  'Deccan',
  'Hungarian',
  'Kinyarwanda',
  'Zulu',
  'South Levantine Arabic',
  'Tunisian Arabic',
  'Sanaani Spoken Arabic',
  'Southern Pashto',
  'Rundi',
  'Czech',
  'Taʽizzi-Adeni Arabic',
  'Uyghur',
  'Sylheti',
];

export const currencyData = {
  AED: 'United Arab Emirates Dirham',
  AFN: 'Afghan Afghani',
  ALL: 'Albanian Lek',
  AMD: 'Armenian Dram',
  ANG: 'Netherlands Antillean Guilder',
  AOA: 'Angolan Kwanza',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  AWG: 'Aruban Florin',
  AZN: 'Azerbaijani Manat',
  BAM: 'Bosnia-Herzegovina Convertible Mark',
  BBD: 'Barbadian Dollar',
  BDT: 'Bangladeshi Taka',
  BGN: 'Bulgarian Lev',
  BHD: 'Bahraini Dinar',
  BIF: 'Burundian Franc',
  BMD: 'Bermudan Dollar',
  BND: 'Brunei Dollar',
  BOB: 'Bolivian Boliviano',
  BRL: 'Brazilian Real',
  BSD: 'Bahamian Dollar',
  BTC: 'Bitcoin',
  BTN: 'Bhutanese Ngultrum',
  BWP: 'Botswanan Pula',
  BYN: 'Belarusian Ruble',
  BZD: 'Belize Dollar',
  CAD: 'Canadian Dollar',
  CDF: 'Congolese Franc',
  CHF: 'Swiss Franc',
  CLF: 'Chilean Unit of Account (UF)',
  CLP: 'Chilean Peso',
  CNH: 'Chinese Yuan (Offshore)',
  CNY: 'Chinese Yuan',
  COP: 'Colombian Peso',
  CRC: 'Costa Rican Colón',
  CUC: 'Cuban Convertible Peso',
  CUP: 'Cuban Peso',
  CVE: 'Cape Verdean Escudo',
  CZK: 'Czech Republic Koruna',
  DJF: 'Djiboutian Franc',
  DKK: 'Danish Krone',
  DOP: 'Dominican Peso',
  DZD: 'Algerian Dinar',
  EGP: 'Egyptian Pound',
  ERN: 'Eritrean Nakfa',
  ETB: 'Ethiopian Birr',
  EUR: 'Euro',
  FJD: 'Fijian Dollar',
  FKP: 'Falkland Islands Pound',
  GBP: 'British Pound Sterling',
  GEL: 'Georgian Lari',
  GGP: 'Guernsey Pound',
  GHS: 'Ghanaian Cedi',
  GIP: 'Gibraltar Pound',
  GMD: 'Gambian Dalasi',
  GNF: 'Guinean Franc',
  GTQ: 'Guatemalan Quetzal',
  GYD: 'Guyanaese Dollar',
  HKD: 'Hong Kong Dollar',
  HNL: 'Honduran Lempira',
  HRK: 'Croatian Kuna',
  HTG: 'Haitian Gourde',
  HUF: 'Hungarian Forint',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli New Sheqel',
  IMP: 'Manx pound',
  INR: 'Indian Rupee',
  IQD: 'Iraqi Dinar',
  IRR: 'Iranian Rial',
  ISK: 'Icelandic Króna',
  JEP: 'Jersey Pound',
  JMD: 'Jamaican Dollar',
  JOD: 'Jordanian Dinar',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KGS: 'Kyrgystani Som',
  KHR: 'Cambodian Riel',
  KMF: 'Comorian Franc',
  KPW: 'North Korean Won',
  KRW: 'South Korean Won',
  KWD: 'Kuwaiti Dinar',
  KYD: 'Cayman Islands Dollar',
  KZT: 'Kazakhstani Tenge',
  LAK: 'Laotian Kip',
  LBP: 'Lebanese Pound',
  LKR: 'Sri Lankan Rupee',
  LRD: 'Liberian Dollar',
  LSL: 'Lesotho Loti',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirham',
  MDL: 'Moldovan Leu',
  MGA: 'Malagasy Ariary',
  MKD: 'Macedonian Denar',
  MMK: 'Myanma Kyat',
  MNT: 'Mongolian Tugrik',
  MOP: 'Macanese Pataca',
  MRO: 'Mauritanian Ouguiya (pre-2018)',
  MRU: 'Mauritanian Ouguiya',
  MUR: 'Mauritian Rupee',
  MVR: 'Maldivian Rufiyaa',
  MWK: 'Malawian Kwacha',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  MZN: 'Mozambican Metical',
  NAD: 'Namibian Dollar',
  NGN: 'Nigerian Naira',
  NIO: 'Nicaraguan Córdoba',
  NOK: 'Norwegian Krone',
  NPR: 'Nepalese Rupee',
  NZD: 'New Zealand Dollar',
  OMR: 'Omani Rial',
  PAB: 'Panamanian Balboa',
  PEN: 'Peruvian Nuevo Sol',
  PGK: 'Papua New Guinean Kina',
  PHP: 'Philippine Peso',
  PKR: 'Pakistani Rupee',
  PLN: 'Polish Zloty',
  PYG: 'Paraguayan Guarani',
  QAR: 'Qatari Rial',
  RON: 'Romanian Leu',
  RSD: 'Serbian Dinar',
  RUB: 'Russian Ruble',
  RWF: 'Rwandan Franc',
  SAR: 'Saudi Riyal',
  SBD: 'Solomon Islands Dollar',
  SCR: 'Seychellois Rupee',
  SDG: 'Sudanese Pound',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  SHP: 'Saint Helena Pound',
  SLL: 'Sierra Leonean Leone',
  SOS: 'Somali Shilling',
  SRD: 'Surinamese Dollar',
  SSP: 'South Sudanese Pound',
  STD: 'São Tomé and Príncipe Dobra (pre-2018)',
  STN: 'São Tomé and Príncipe Dobra',
  SVC: 'Salvadoran Colón',
  SYP: 'Syrian Pound',
  SZL: 'Swazi Lilangeni',
  THB: 'Thai Baht',
  TJS: 'Tajikistani Somoni',
  TMT: 'Turkmenistani Manat',
  TND: 'Tunisian Dinar',
  TOP: "Tongan Pa'anga",
  TRY: 'Turkish Lira',
  TTD: 'Trinidad and Tobago Dollar',
  TWD: 'New Taiwan Dollar',
  TZS: 'Tanzanian Shilling',
  UAH: 'Ukrainian Hryvnia',
  UGX: 'Ugandan Shilling',
  USD: 'United States Dollar',
  UYU: 'Uruguayan Peso',
  UZS: 'Uzbekistan Som',
  VEF: 'Venezuelan Bolívar Fuerte (Old)',
  VES: 'Venezuelan Bolívar Soberano',
  VND: 'Vietnamese Dong',
  VUV: 'Vanuatu Vatu',
  WST: 'Samoan Tala',
  XAF: 'CFA Franc BEAC',
  XAG: 'Silver Ounce',
  XAU: 'Gold Ounce',
  XCD: 'East Caribbean Dollar',
  XDR: 'Special Drawing Rights',
  XOF: 'CFA Franc BCEAO',
  XPD: 'Palladium Ounce',
  XPF: 'CFP Franc',
  XPT: 'Platinum Ounce',
  YER: 'Yemeni Rial',
  ZAR: 'South African Rand',
  ZMW: 'Zambian Kwacha',
  ZWL: 'Zimbabwean Dollar',
};

export const usStateData = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const legalEntityType = [
  'LLC',
  'Partnership',
  'C corp',
  'S corp',
  'Non-profit',
];

export const ownershipType = [
  'Sole proprietorship',
  'Partnership',
  'LLP',
  'LLC',
  'Series LLC',
  'C corporation',
  'S corporation',
  'Nonprofit corporation',
  'Benefit corporation',
  'L3C',
];

export const statusType = ['Active', 'Inactive', 'Pending'];

export default CountryCode;
