import React from 'react';
import Action from './Action';
import { MessageOutlined } from '@ant-design/icons';

const Chat = (props) => {
  const { count, offset, iconStyle, onClick } = props;
  return (
    <Action
      count={count}
      offset={offset}
      icon={MessageOutlined}
      iconStyle={iconStyle}
      onClick={onClick}
    />
  );
};

export default Chat;
