import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toArray } from 'lodash';
import { Button, Select, Row, Col, Tag, Empty } from 'antd';

import { Form, StyledModal, WrapperSelect } from 'common/components';
import { PublishProductComponent } from './PublishProductComponent';
import { PartyDataList } from './PartyDataList';

import * as productActions from 'pages/branded-products/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { mapToPublishProductCheckListRequest } from './utils';
import { useCreditCardChargeWarning } from 'hooks/useCreditCardChargeWarning';

import { TAG_COLORS, PUBLISH_TYPES } from 'static/Constants';
import { getUserSourceGLN } from 'services/product';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/home';

import './PublishProduct.less';

const { Option } = Select;

const layout = {
  labelCol: { xxl: { span: 5 }, xl: { span: 24 } },
  wrapperCol: { xxl: { span: 19 }, xl: { span: 24 } },
  layout: 'horizontal',
};

function PublicationSetupModal({
  visible,
  handleCancel,
  handleOk,
  disabled,
  isFullView,
  productFull,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const [sourceGLNList, setSourceGLNList] = useState([]);

  const [recipientGLNs, setRecipientGLNs] = useState([]);
  const [products, setProducts] = useState(() =>
    isFullView ? [productFull] : detailCurrentItemsSelection
  );

  useEffect(() => {
    getUserSourceGLN().then((res) => {
      if (res?.isSuccess) {
        setSourceGLNList(res?.data?.memberGLNs);
      }
    });
  }, []);

  useEffect(() => {
    if (setSourceGLNList) {
      form.setFieldsValue({
        sourceGLN: sourceGLNList?.[0],
        publishType: PUBLISH_TYPES.NEW.value,
      });
    }
  }, [form, sourceGLNList]);

  useEffect(() => {
    dispatch(productActions.getGDSNPartyList('all-party-info-grid'));
  }, [dispatch]);

  const handleAddGln = (item) => {
    const newGlns = recipientGLNs.concat(item);

    setRecipientGLNs(newGlns);

    form.setFieldsValue({
      recipientGLNs: newGlns,
    });
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        const params = mapToPublishProductCheckListRequest({
          products,
          recipientGLNs,
          isFullView,
          productFull,
          formValues: values,
        });

        handleOk(params);
      })
      .catch((err) => {});
  };

  const { showCreditCardChargeWarning } = useCreditCardChargeWarning({
    onOkCallback: submitForm,
  });

  // BP 09/01/2022 - ppppp
  const handleSubmit = () => {
    submitForm();
  };

  const handleRemoveGln = (removedItem) => {
    const newGLNList = recipientGLNs?.filter(
      (item) => item.partyGln !== removedItem.partyGln
    );

    form.setFieldsValue({
      recipientGLNs: newGLNList,
    });
    setRecipientGLNs(newGLNList);
  };

  const handleRemoveProduct = (id) => {
    const newProductList = products?.filter((item) => item?.id !== id);
    setProducts(newProductList);
  };

  const handleSourceGLN = (e) => {
    form.setFieldsValue({ sourceGLN: e });
  };

  useEffect(() => {
    getUserSourceGLN().then((res) => {
      if (res?.isSuccess) {
        setSourceGLNList(res?.data?.memberGLNs);
      }
    });
  }, []);

  useEffect(() => {
    if (setSourceGLNList) {
      form.setFieldsValue({
        sourceGLN: sourceGLNList?.[0],
        publishType: PUBLISH_TYPES.NEW.value,
      });
    }
  }, [form, sourceGLNList]);

  useEffect(() => {
    dispatch(productActions.getGDSNPartyList('all-party-info-grid'));
  }, [dispatch]);

  return (
    <StyledModal
      title={intl.formatMessage(messages.GDSNPublicationSetup)}
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      width={1135}
      centered={true}
      maskClosable={false}
      bodyStyle={{ height: 650 }}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={handleSubmit}
          disabled={disabled || products?.length === 0}
          style={{
            backgroundColor: '#1e8e3e',
            borderColor: '#1e8e3e',
          }}
        >
          Publish
        </Button>,
      ]}
    >
      <Form {...layout} className='publish-product__form' form={form}>
        <Row className='publish-product__form-inner' gutter={(8, 16)}>
          <Col className='publish-product__form-col' span={10}>
            <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              name='sourceGLN'
              label='Source GLN'
              rules={[
                {
                  required: true,
                  message: 'GLN Source is required!',
                },
              ]}
            >
              <WrapperSelect
                showSearch
                onChange={handleSourceGLN}
                placeholder='Please choose a GLN Source'
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {sourceGLNList?.map((item, idx) => (
                  <Option value={item} key={idx}>
                    {item}
                  </Option>
                ))}
              </WrapperSelect>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              name='publishType'
              label='Publish Type'
              rules={[
                {
                  required: true,
                  message: 'Publish type is required!',
                },
              ]}
            >
              <Select placeholder='Please choose publish type'>
                {toArray(PUBLISH_TYPES)?.map((item, idx) => (
                  <Option value={item.value} key={idx}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              name='recipientGLNs'
              label='Target GLNs'
              rules={[
                {
                  required: true,
                  message: 'Target GLN is required!',
                },
              ]}
            />

            <div className='party-list-selected scroller'>
              {recipientGLNs?.length > 0 ? (
                recipientGLNs?.map((item) => (
                  <Tag
                    key={item?.partyGln}
                    closable
                    onClose={() => handleRemoveGln(item)}
                    style={styles.tag}
                    color={getTagColor(item?.partyGln)}
                  >
                    {`GLN ${item?.partyGln}`}
                  </Tag>
                ))
              ) : (
                <Empty />
              )}
            </div>

            <Form.Item
              name='customImageFormat'
              label={intl.formatMessage(messages.customImageFormatLabel)}
            >
              <WrapperSelect
                placeholder={intl.formatMessage(
                  messages.customImageFormatPlaceholder
                )}
              >
                <Option value={'png'}>PNG</Option>
                <Option value={'jpg'}>JPG</Option>
              </WrapperSelect>
            </Form.Item>

            <div className='publish-product__product-list'>
              <PublishProductComponent
                products={products}
                handleRemoveProduct={handleRemoveProduct}
              />
            </div>
          </Col>

          <Col className='publish-product__form-col' span={14}>
            <PartyDataList
              handleAddGln={handleAddGln}
              recipientGLNs={recipientGLNs}
              handleRemoveGln={handleRemoveGln}
            />
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
}

export default PublicationSetupModal;

const styles = {
  emptyDiv: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    marginBottom: '5px',
  },
};

const getTagColor = (gln) => {
  if (gln[0] === '0') return TAG_COLORS[0];

  if (parseInt(gln.substring(0, 2)) % 2 === 0) return TAG_COLORS[1];

  if (parseInt(gln.substring(0, 2)) % 2 !== 0) return TAG_COLORS[2];
};
