import * as api from 'config/axios';
import * as endpoints from './endpoints';
import { getToken } from 'utils/common/session';

const setUserApiHeader = () => {
  const accessToken = getToken();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

/**
 * !Post to get user contact history
 * @param {object} data PageIndex + PageSize
 */
export const getUserContactHistory = (params, cancelToken) => {
  return api.sendPost(endpoints.GET_USER_CONTACT_HISTORY, {
    headers: setUserApiHeader(),
    ...params,
    cancelToken,
  });
};

/**
 * Get user profile
 * @param {object} data user profile information
 */
export const getUserProfile = (data) => {
  return api.sendGet(endpoints.GET_USER_PROFILE, {
    headers: setUserApiHeader(),
    params: data,
  });
};

/**
 * Get user profile
 * @param {object} data user profile information
 */
export const getUserActivityLog = (data) => {
  return api.sendPost(endpoints.GET_USER_ACTIVITY_LOG, data);
};

export const getUserActivityLogDistinct = (data) => {
  return api.sendPost(endpoints.GET_USER_ACTIVITY_LOG_DISTINCT, data);
};

/**
 * Get user profile
 * @param {object} data user password
 */

export const changeUserPassword = (data) => {
  return api.sendPost(endpoints.CHANGE_USER_PASSWORD, data, {
    headers: setUserApiHeader(),
  });
};

export const getUserPasswordStrengthType = (cancelToken) => {
  return api.sendGet(endpoints.GET_USER_PASSWORD_STRENGTH_TYPE, {
    headers: setUserApiHeader(),
    cancelToken,
  });
};

/**
 * Get user profile locations
 * @param {object} data user profile information locations
 */

export const getUserProfileLocations = (data) => {
  return api.sendPost(endpoints.GET_USER_PROFILE_LOCATIONS, data, {
    headers: setUserApiHeader(),
  });
};

/**
 * Get user profile locations
 * @param {object} data user profile information locations distinct
 */

export const getUserProfileLocationsDistinct = (data) => {
  return api.sendPost(endpoints.GET_USER_PROFILE_LOCATIONS_DISTINCT, data, {
    headers: setUserApiHeader(),
  });
};

/**
 ** Get user profile permission
 */
export const getUserPermissionGridColumn = () => {
  return api.sendGet(endpoints.GET_USER_PERMISSION_GRID_COLUMN);
};

/**
 ** Get user profile permission role grid data
 */
export const getUserPermissionRoleGridData = () => {
  return api.sendGet(endpoints.GET_USER_PERMISSION_ROLE_GRID_DATA);
};

// user-profile
export const editUserProfileBio = (params) => {
  return api.sendPost(endpoints.EDIT_USER_PROFILE_BIO, params);
};

export const createUserProfileImage = (params) => {
  return api.sendPost(endpoints.CREATE_USER_PROFILE_IMAGE, params);
};

export const getSuggestionUsers = (data) => {
  return api.sendGet(endpoints.GET_SUGGESTION_USERS, { params: data });
};

export const getPublicSpecification = (data) => {
  return api.sendGet(endpoints.GET_PUBLIC_SPECIFICATION, { params: data });
};

export const checkUsernameAvailable = (data) => {
  return api.sendGet(endpoints.CHECK_USERNAME_AVAILABLE, { params: data });
};

export const getUserProfileHeader = (data) => {
  return api.sendGet(endpoints.GET_USER_PROFILE_HEADER, { params: data });
};

export const editUserProfileHeader = (params) => {
  return api.sendPost(endpoints.EDIT_USER_PROFILE_HEADER, params);
};

export const getAllArticles = () => {
  return api.sendGet(endpoints.GET_ALL_ARTICLES);
};

//* add / update / delete user location
export const updateUserProfileLocation = (params) => {
  return api.sendPost(endpoints.USER_PROFILE_UPDATE_LOCATION, params);
};
export const deleteUserProfileLocation = (params) => {
  return api.sendPost(endpoints.USER_PROFILE_DELETE_LOCATION, params);
};
export const addUserProfileLocation = (params) => {
  return api.sendPost(endpoints.USER_PROFILE_ADD_LOCATION, params);
};

export const getUserProfileLocationDetail = (params) => {
  return api.sendGet(endpoints.USER_PROFILE_GET_LOCATION_DETAIL, {
    params: params,
  });
};

export const editUserProfileFullName = (params) => {
  return api.sendPost(endpoints.EDIT_USER_PROFILE_FULL_NAME, params);
};

export const editUserProfileEmail = (params) => {
  return api.sendPost(endpoints.EDIT_USER_PROFILE_EMAIL, params);
};

//* user profile save asset download preference
export const saveAssetDownloadPreferences = (params) => {
  return api.sendPost(endpoints.SAVE_ASSET_DOWNLOAD_PREFERENCES, params);
};

//* api to send otp to new mfa phone number in user profile/security tab
export const sendOtpToNewMfaPhoneUserProfile = (params) => {
  return api.sendPost(
    endpoints.SEND_OTP_TO_NEW_MFA_PHONE_NUMBER_USER_PROFILE,
    params
  );
};

export const verifyOtpForNewMfaPhoneUserProfile = (params) => {
  return api.sendPost(
    endpoints.VERIFY_OTP_AND_SAVE_NEW_MFA_PHONE_NUMBER_USER_PROFILE,
    params
  );
};

export const changePasswordUnlockAccount = (params) => {
  return api.sendPost(endpoints.CHANGE_PASSWORD_UNLOCK_ACCOUNT, params);
};
