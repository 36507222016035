import React from 'react';
import { Form, Input, Typography } from 'antd';
import {
  CATEGORY_FORM_ITEM_INPUT,
  CATEGORY_LEVEL_1,
  CATEGORY_LEVEL_2,
  CATEGORY_LEVEL_3,
  CATEGORY_LEVEL_4,
} from 'pages/company-profile/components/tabs/system/category-management/constant';
import {
  checkEmptyChildCategoryInAddMode,
  resetFieldError,
} from 'pages/company-profile/components/tabs/system/category-management/utils';

import './ProductCategoryForm.less';

const { Text } = Typography;

const ProductCategoryForm = ({
  mode,
  formName,
  getMap,
  nodeSelected,
  disableForm,
}) => {
  const form = Form.useFormInstance();
  const isModeNullOrBulkReassignMode = !mode || mode === 'bulkReassignProducts';

  const checkDisabled = (level, node, mode) => {
    if (disableForm) return true;
    if (level !== node?.level && mode === 'edit') return true;

    if (mode === 'add') {
      let levelList = [];
      for (let i = 1; i <= node?.level; i++) {
        levelList.push(i);
      }
      return levelList.includes(level);
    }
    return false;
  };

  const handleSetRefForEachCategoryInput = (input, formItem) => {
    const map = getMap ? getMap() : null;
    if (!map) return;
    input ? map?.set(formItem.level, input) : map?.delete(formItem.level);
  };

  const handleChangeValueForm = (e, mode, level) => {
    const errorMessage = 'Category Name is required';

    if (mode === 'edit') {
      const errors = e.target.value === '' ? [errorMessage] : [];
      form.setFields([{ name: `category${level}`, errors }]);
    }

    if (mode === 'add') {
      const emptyChildLevel = checkEmptyChildCategoryInAddMode(
        form.getFieldsValue()
      );
      if (!emptyChildLevel) {
        resetFieldError(form);
        return;
      }
      const validFields = [];
      const errorFields = [];

      [
        CATEGORY_LEVEL_1,
        CATEGORY_LEVEL_2,
        CATEGORY_LEVEL_3,
        CATEGORY_LEVEL_4,
      ].forEach((level) => {
        const fieldName = `category${level}`;
        const errorsExist = emptyChildLevel.includes(level);

        if (errorsExist) {
          errorFields.push({ name: fieldName, errors: [errorMessage] });
        } else {
          validFields.push({ name: fieldName, errors: [] });
        }
      });

      form.setFields([...validFields, ...errorFields]);
      return;
    }
  };

  return (
    <>
      {CATEGORY_FORM_ITEM_INPUT.map((formItem) => {
        return (
          <Form.Item
            className='product-category-form__form-item'
            key={formItem.level}
            label={
              <Text className='product-category-form__label'>
                {formItem.label}
              </Text>
            }
            name={
              mode === 'bulkReassignProducts'
                ? [[formName], `${formItem.name}`]
                : `${formItem.name}`
            }
          >
            <Input
              style={{
                cursor: isModeNullOrBulkReassignMode
                  ? 'not-allowed'
                  : 'pointer',
              }}
              readOnly={isModeNullOrBulkReassignMode}
              disabled={checkDisabled(formItem.level, nodeSelected, mode)}
              ref={(input) => handleSetRefForEachCategoryInput(input, formItem)}
              onChange={(e) => handleChangeValueForm(e, mode, formItem.level)}
            />
          </Form.Item>
        );
      })}
    </>
  );
};

export default ProductCategoryForm;
