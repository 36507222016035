import React from 'react';

import { RibbonSection } from 'common/components';
import SaveQueryControl from '../../controls/advanced-search/SaveQueryControl';
import CancelQueryControl from '../../controls/advanced-search/CancelQueryControl';
import EditQueryControl from '../../controls/advanced-search/EditQueryControl';
import CloneQueryControl from '../../controls/advanced-search/CloneQueryControl';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { REPORTING_VIEW_MODE } from 'static/Constants';

const SaveSection = (props) => {
  const { onClickRibbonBtn, modeView, isOwner, disableSave } = props;

  const onClickSaveBtn = () => {
    onClickRibbonBtn('save');
  };

  const onClickCancelBtn = () => {
    onClickRibbonBtn('cancel');
  };

  const onClickCloneBtn = () => {
    onClickRibbonBtn('clone');
  };

  const onClickEditBtn = () => {
    onClickRibbonBtn('edit');
  };

  const renderEditMode = () => {
    return modeView === REPORTING_VIEW_MODE.EDIT ? (
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.REPORTING}>
        <SaveQueryControl disabled={disableSave} onClick={onClickSaveBtn} />
        <CancelQueryControl onClick={onClickCancelBtn} />
      </Can>
    ) : null;
  };

  const renderViewMode = () => {
    return modeView === REPORTING_VIEW_MODE.VIEW ? (
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.REPORTING}>
        {isOwner ? (
          <EditQueryControl onClick={onClickEditBtn} />
        ) : (
          <CloneQueryControl onClick={onClickCloneBtn} />
        )}
      </Can>
    ) : null;
  };

  const renderCreateView = () => {
    return modeView === REPORTING_VIEW_MODE.CREATE ? (
      <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.REPORTING}>
        <SaveQueryControl disabled={disableSave} onClick={onClickSaveBtn} />
      </Can>
    ) : null;
  };

  return (
    <RibbonSection>
      {renderViewMode()}
      {renderEditMode()}
      {renderCreateView()}
    </RibbonSection>
  );
};

export default SaveSection;
