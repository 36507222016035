import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';

import { saveQaSpecDataFreshSeafood } from 'services/product';
import { getQaFreshMeatTabDataQueryKey } from '../qa-fresh-meat/queries';
import { getKeyProductSpec } from 'pages/qa-spec/hooks/useProductSpecInfo';
import * as productServices from 'services/product';
import { forwardTo } from 'utils/common/route';

export const getQaFreshSeafoodTabDataQueryKey = (productId) => {
  return ['qa-spec', parseInt(productId), 'qa-spec-data-fresh-seafood'];
};

export const useGetQaFreshSeafoodTabData = ({ productId }, options) => {
  return useQuery({
    queryKey: getQaFreshSeafoodTabDataQueryKey(productId),
    queryFn: async () => {
      const response = await productServices.getFreshSeafood({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    ...options,
  });
};

export const useSaveQaFreshSeafoodTab = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ productId, qaFreshSeaFoodFormData }) => {
      const params = { productId, ...qaFreshSeaFoodFormData };
      const response = await saveQaSpecDataFreshSeafood(params);

      const { isSuccess, data, message } = response || {};

      if (isSuccess) {
        return Promise.resolve(data);
      } else if (message) {
        return Promise.reject(message);
      }
    },
    onSuccess: (data, { productId }) => {
      queryClient.invalidateQueries({
        queryKey: getQaFreshSeafoodTabDataQueryKey(productId),
      });
      queryClient.invalidateQueries({
        queryKey: getQaFreshMeatTabDataQueryKey(productId),
      });

      queryClient.invalidateQueries({
        queryKey: getKeyProductSpec(productId),
      });

      CustomNotification.success('Update Fresh-Seafood data successfully!');
    },
    onError: (error) => {
      CustomNotification.error(error || 'Server Error');
    },
  });
};
