import { useSelector } from 'react-redux';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';
import * as actionsAsset from 'pages/branded-assets/controllers/actions';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import {
  pathnameFavMember,
  pathnameFavAssets,
  pathnameFavFolders,
  pathnameFavProducts,
  pathnameProduct,
  pathnameMember,
  pathnameDigitalMedia,
  pathnameDigitalMediaNew,
  pathnameAssets,
  pathnameDocument,
  pathnameMultiMedia,
  pathnameReportings,
  pathnameReportingsOwned,
  pathnameReportingsShared,
  pathnameFolders,
  pathnameFoldersOwned,
  pathnameFoldersShared,
  pathnameAssetsForMember,
  pathnameProductsForMember,
} from 'static/Constants';

const updateQueryConditions = (dispatch, value, pathname, isQuery) => {
  switch (pathname) {
    case pathnameFavMember:
      dispatch(gridViewActions.updateQueryFavMember(value));
      break;
    case pathnameFavAssets:
      dispatch(gridViewActions.updateQueryFavAsset(value));
      break;
    case pathnameFavFolders:
      dispatch(gridViewActions.updateQueryFavFolder(value));
      break;
    case pathnameFavProducts:
      dispatch(gridViewActions.updateQueryFavProduct(value));
      break;
    case pathnameProduct:
      dispatch(gridViewActions.updateQueryProduct(value));
      break;
    case pathnameMember:
      dispatch(gridViewActions.updateQueryMember(value));
      break;
    case pathnameDigitalMedia:
      dispatch(gridViewActions.updateQueryDigitalMedia(value));
      break;
    case pathnameDigitalMediaNew:
      dispatch(gridViewActions.updateQueryDigitalMediaNew(value));
      break;
    case pathnameAssets:
      dispatch(gridViewActions.updateQueryAsset(value));
      break;
    case pathnameDocument:
      dispatch(gridViewActions.updateQueryDocument(value));
      break;
    case pathnameMultiMedia:
      dispatch(gridViewActions.updateQueryMultiMedia(value));
      break;
    case pathnameReportings:
      dispatch(gridViewActions.updateQueryReporting(value));
      break;
    case pathnameReportingsOwned:
      dispatch(gridViewActions.updateQueryReportingOwned(value));
      break;
    case pathnameReportingsShared:
      dispatch(gridViewActions.updateQueryReportingShared(value));
      break;
    case pathnameFolders:
      dispatch(gridViewActions.updateQueryFolder(value));
      break;
    case pathnameFoldersOwned:
      dispatch(gridViewActions.updateQueryFolderOwned(value));
      break;
    case pathnameFoldersShared:
      dispatch(gridViewActions.updateQueryFolderShared(value));
      break;
  }
  if (pathname.includes(pathnameAssetsForMember)) {
    dispatch(gridViewActions.updateQueryAssetsForMember(value));
  }
  if (pathname.includes(pathnameProductsForMember)) {
    dispatch(gridViewActions.updateQueryProductsForMember(value));
  }
  if (!isQuery) {
    dispatch(gridViewActions.updatePrevPathnameQuery(pathname));
  }
};
const updateQueryAdvance = (dispatch, value, pathname, isQuery) => {
  switch (pathname) {
    case pathnameFavMember:
      dispatch(gridViewActions.updateQueryAdvanceFavMember(value));
      break;
    case pathnameFavAssets:
      dispatch(gridViewActions.updateQueryAdvanceFavAsset(value));
      break;
    case pathnameFavFolders:
      dispatch(gridViewActions.updateQueryAdvanceFavFolder(value));
      break;
    case pathnameFavProducts:
      dispatch(gridViewActions.updateQueryAdvanceFavProduct(value));
      break;
    case pathnameProduct:
      dispatch(gridViewActions.updateQueryAdvanceProduct(value));
      break;
    case pathnameMember:
      dispatch(gridViewActions.updateQueryAdvanceMember(value));
      break;
    case pathnameDigitalMedia:
      dispatch(gridViewActions.updateQueryAdvanceDigitalMedia(value));
      break;
    case pathnameDigitalMediaNew:
      dispatch(gridViewActions.updateQueryAdvanceDigitalMediaNew(value));
      break;
    case pathnameAssets:
      dispatch(gridViewActions.updateQueryAdvanceAsset(value));
      break;
    case pathnameDocument:
      dispatch(gridViewActions.updateQueryAdvanceDocument(value));
      break;
    case pathnameMultiMedia:
      dispatch(gridViewActions.updateQueryAdvanceMultiMedia(value));
      break;
    case pathnameReportings:
      dispatch(gridViewActions.updateQueryAdvanceReporting(value));
      break;
    case pathnameReportingsOwned:
      dispatch(gridViewActions.updateQueryAdvanceReportingOwned(value));
      break;
    case pathnameReportingsShared:
      dispatch(gridViewActions.updateQueryAdvanceReportingShared(value));
      break;
    case pathnameFolders:
      dispatch(gridViewActions.updateQueryAdvanceFolder(value));
      break;
    case pathnameFoldersOwned:
      dispatch(gridViewActions.updateQueryAdvanceFolderOwned(value));
      break;
    case pathnameFoldersShared:
      dispatch(gridViewActions.updateQueryAdvanceFolderShared(value));
      break;
  }
  if (pathname.includes(pathnameAssetsForMember)) {
    dispatch(gridViewActions.updateQueryAdvanceAssetsForMember(value));
  }
  if (pathname.includes(pathnameProductsForMember)) {
    dispatch(gridViewActions.updateQueryAdvanceProductsForMember(value));
  }
  if (!isQuery) {
    dispatch(gridViewActions.updatePrevPathnameQuery(pathname));
  }
};

const updateIdQuery = (dispatch, value, pathname, isQuery) => {
  switch (pathname) {
    case pathnameFavMember:
      dispatch(gridViewActions.updateIdQueryFavMember(value));
      break;
    case pathnameFavAssets:
      dispatch(gridViewActions.updateIdQueryFavAsset(value));
      break;
    case pathnameFavFolders:
      dispatch(gridViewActions.updateIdQueryFavFolder(value));
      break;
    case pathnameFavProducts:
      dispatch(gridViewActions.updateIdQueryFavProduct(value));
      break;
    case pathnameProduct:
      dispatch(gridViewActions.updateIdQueryProduct(value));
      break;
    case pathnameMember:
      dispatch(gridViewActions.updateIdQueryMember(value));
      break;
    case pathnameDigitalMedia:
      dispatch(gridViewActions.updateIdQueryDigitalMedia(value));
      break;
    case pathnameDigitalMediaNew:
      dispatch(gridViewActions.updateIdQueryDigitalMediaNew(value));
      break;
    case pathnameAssets:
      dispatch(gridViewActions.updateIdQueryAsset(value));
      break;
    case pathnameDocument:
      dispatch(gridViewActions.updateIdQueryDocument(value));
      break;
    case pathnameMultiMedia:
      dispatch(gridViewActions.updateIdQueryMultiMedia(value));
      break;
    case pathnameReportings:
      dispatch(gridViewActions.updateIdQueryReporting(value));
      break;
    case pathnameReportingsOwned:
      dispatch(gridViewActions.updateIdQueryReportingOwned(value));
      break;
    case pathnameReportingsShared:
      dispatch(gridViewActions.updateIdQueryReportingShared(value));
      break;
    case pathnameFolders:
      dispatch(gridViewActions.updateIdQueryFolder(value));
      break;
    case pathnameFoldersOwned:
      dispatch(gridViewActions.updateIdQueryFolderOwned(value));
      break;
    case pathnameFoldersShared:
      dispatch(gridViewActions.updateIdQueryFolderShared(value));
      break;
  }
  if (pathname.includes(pathnameAssetsForMember)) {
    dispatch(gridViewActions.updateIdQueryAssetsForMember(value));
  }
  if (!isQuery) {
    dispatch(gridViewActions.updatePrevPathnameQuery(pathname));
  }
};

const clearQueryCondition = (dispatch, pathname, prevPathnameQuery) => {
  if (!prevPathnameQuery || prevPathnameQuery === pathname) return;

  setTimeout(() => {
    if (prevPathnameQuery === pathnameFavMember) {
      dispatch(gridViewActions.updateQueryFavMember([]));
      dispatch(gridViewActions.updateQueryAdvanceFavMember([]));
      dispatch(gridViewActions.updateIdQueryFavMember(null));
    }
    if (prevPathnameQuery === pathnameFavAssets) {
      dispatch(gridViewActions.updateQueryFavAsset([]));
      dispatch(gridViewActions.updateQueryAdvanceFavAsset([]));
      dispatch(gridViewActions.updateIdQueryFavAsset(null));
    }
    if (prevPathnameQuery === pathnameFavFolders) {
      dispatch(gridViewActions.updateQueryFavFolder([]));
      dispatch(gridViewActions.updateQueryAdvanceFavFolder([]));
      dispatch(gridViewActions.updateIdQueryFavFolder(null));
    }
    if (prevPathnameQuery === pathnameFavProducts) {
      dispatch(gridViewActions.updateQueryFavProduct([]));
      dispatch(gridViewActions.updateQueryAdvanceFavProduct([]));
      dispatch(gridViewActions.updateIdQueryFavProduct(null));
    }
    if (prevPathnameQuery === pathnameProduct) {
      dispatch(gridViewActions.updateQueryProduct([]));
      dispatch(gridViewActions.updateQueryAdvanceProduct([]));
      dispatch(gridViewActions.updateIdQueryProduct(null));
    }
    if (prevPathnameQuery === pathnameMember) {
      dispatch(gridViewActions.updateQueryMember([]));
      dispatch(gridViewActions.updateQueryAdvanceMember([]));
      dispatch(gridViewActions.updateIdQueryMember(null));
    }
    if (prevPathnameQuery === pathnameDigitalMedia) {
      dispatch(gridViewActions.updateQueryDigitalMedia([]));
      dispatch(gridViewActions.updateQueryAdvanceDigitalMedia([]));
      dispatch(gridViewActions.updateIdQueryDigitalMedia(null));
      // dispatch(actionsAsset.toggleAssetNew(false));
      // dispatch(actionsAsset.cacheFromDate(null));
    }
    if (prevPathnameQuery === pathnameDigitalMediaNew) {
      dispatch(gridViewActions.updateQueryDigitalMediaNew([]));
      dispatch(gridViewActions.updateQueryAdvanceDigitalMediaNew([]));
      dispatch(gridViewActions.updateIdQueryDigitalMediaNew(null));
    }
    if (prevPathnameQuery === pathnameAssets) {
      dispatch(gridViewActions.updateQueryAsset([]));
      dispatch(gridViewActions.updateQueryAdvanceAsset([]));
      dispatch(gridViewActions.updateIdQueryAsset(null));
    }
    if (prevPathnameQuery === pathnameDocument) {
      dispatch(gridViewActions.updateQueryDocument([]));
      dispatch(gridViewActions.updateQueryAdvanceDocument([]));
      dispatch(gridViewActions.updateIdQueryDocument(null));
    }
    if (prevPathnameQuery === pathnameMultiMedia) {
      dispatch(gridViewActions.updateQueryMultiMedia([]));
      dispatch(gridViewActions.updateQueryAdvanceMultiMedia([]));
      dispatch(gridViewActions.updateIdQueryMultiMedia(null));
    }
    if (prevPathnameQuery === pathnameReportings) {
      dispatch(gridViewActions.updateQueryReporting([]));
      dispatch(gridViewActions.updateQueryAdvanceReporting([]));
      dispatch(gridViewActions.updateIdQueryReporting(null));
    }
    if (prevPathnameQuery === pathnameReportingsOwned) {
      dispatch(gridViewActions.updateQueryReportingOwned([]));
      dispatch(gridViewActions.updateQueryAdvanceReportingOwned([]));
      dispatch(gridViewActions.updateIdQueryReportingOwned(null));
    }
    if (prevPathnameQuery === pathnameReportingsShared) {
      dispatch(gridViewActions.updateQueryReportingShared([]));
      dispatch(gridViewActions.updateQueryAdvanceReportingShared([]));
      dispatch(gridViewActions.updateIdQueryReportingShared(null));
    }
    if (prevPathnameQuery === pathnameFolders) {
      dispatch(gridViewActions.updateQueryFolder([]));
      dispatch(gridViewActions.updateQueryAdvanceFolder([]));
      dispatch(gridViewActions.updateIdQueryFolder(null));
    }
    if (prevPathnameQuery === pathnameFoldersOwned) {
      dispatch(gridViewActions.updateQueryFolderOwned([]));
      dispatch(gridViewActions.updateQueryAdvanceFolderOwned([]));
      dispatch(gridViewActions.updateIdQueryFolderOwned(null));
    }
    if (prevPathnameQuery === pathnameFoldersShared) {
      dispatch(gridViewActions.updateQueryFolderShared([]));
      dispatch(gridViewActions.updateQueryAdvanceFolderShared([]));
      dispatch(gridViewActions.updateIdQueryFolderShared(null));
    }

    if (prevPathnameQuery.includes(pathnameAssetsForMember)) {
      dispatch(gridViewActions.updateQueryAssetsForMember([]));
      dispatch(gridViewActions.updateQueryAdvanceAssetsForMember([]));
      dispatch(gridViewActions.updateIdQueryAssetsForMember(null));
    }

    if (prevPathnameQuery.includes(pathnameProductsForMember)) {
      dispatch(gridViewActions.updateQueryProductsForMember([]));
      dispatch(gridViewActions.updateQueryAdvanceAssetsForMember([]));
      // dispatch(gridViewActions.updateIdQueryAssetsForMember(null));
    }
    dispatch(gridViewActions.updatePrevPathnameQuery(pathname));
  }, 100);
};

export {
  updateQueryConditions,
  updateQueryAdvance,
  updateIdQuery,
  clearQueryCondition,
};
