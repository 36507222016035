import { traditionalReplaceAll } from 'utils';

export const mapInnerHtmlToText = (innerHtml) => {
  const replacedBrContent = traditionalReplaceAll(innerHtml, '<br>', '\n');
  const replacedNbspContent = traditionalReplaceAll(
    replacedBrContent,
    '&nbsp;',
    ' '
  );
  return replacedNbspContent;
};
