import { useEffect, useRef, useState } from 'react';

import { Col, Input, Row, Space, Typography } from 'antd';

import classnames from 'classnames';

import {
  Form,
  CarouselPrevious,
  CarouselNext,
  Fieldset,
  WithLoading,
  CustomNotification,
} from 'common/components';

import QaAllergenGrid from './QaAllergenGrid';
import QaAllergenRegional from './QaAllergenRegional';

import FormAllergen from './FormAllergen';
import CustomAllergenAddition from './CustomAllergenAddition';

import {
  useCheckSnapshotForRetailer,
  useGetEnumOptions,
} from 'pages/qa-spec/hooks';
import { useGetAllergenGridHandlers } from 'pages/qa-spec/hooks/useAllergen';
import { useGetProductEnums } from 'hooks';
import { useGetAllGroupProduct } from 'pages/product-full-view/components/product-media-area/shared/hooks';
import {
  useAddCustomAllergen,
  useGetSpecAllergensSnapshot,
  useMappingQaAllergenModel,
} from './hooks';

import { ALLERGEN_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';
import { US_VALUE, US_VALUE_LOWERCASE, WORLDWIDE_LOWERCASE } from './constants';

import { transformToTextGroupName } from 'pages/product-full-view/components/product-media-area/shared/utils';
import {
  HighlightContent,
  PrimaryFieldSnapshot,
  RetailerSnapshot,
} from '../qa-prod-spec/snapshot/RetailerSnapshot';

export const AllergenGroupHeader = ({
  title,
  disabledNext,
  disabledPrevious,
  onPrevCarousel,
  onNextCarousel,
  children,
  ...rest
}) => {
  return (
    <Row style={{ marginBottom: 12 }} justify='space-between' {...rest}>
      <Col>
        <Space>
          <CarouselPrevious
            disabled={disabledPrevious}
            onClick={() => {
              onPrevCarousel();
            }}
          />
          <CarouselNext
            disabled={disabledNext}
            onClick={() => {
              onNextCarousel();
            }}
          />
          {title}
        </Space>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

export const defaultModalProps = {
  destroyOnClose: true,
  width: 1140,
  maskClosable: false,
  bodyStyle: {
    minHeight: '60vh',
  },
};

export const AllergenGroupSection = (props) => {
  const { idxCurrentSlide, allergenStatement, allergenModel, productId } =
    props;

  const gridInstance = useRef();

  const [regional, setRegional] = useState(US_VALUE);

  const [allergenGroupForm] = Form.useForm();
  const [customAllergenForm] = Form.useForm();

  const allergenOptions = useGetEnumOptions()?.allergen;
  const { productEnums } = useGetProductEnums();

  const { productGroups } = useGetAllGroupProduct(productId);

  const customAllergenMutation = useAddCustomAllergen(productId);

  const { qaAllergenListSnapshot } = useGetSpecAllergensSnapshot();

  const isUs = regional.toLowerCase() === US_VALUE_LOWERCASE;
  const isWorldwide = regional.toLowerCase() === WORLDWIDE_LOWERCASE;

  const { onCellEditingStopped, onCellEditingStarted, onRowClicked } =
    useGetAllergenGridHandlers(gridInstance);

  const mappingAllergenData =
    useMappingQaAllergenModel(allergenModel, allergenStatement) ?? {};

  const mappingAllergenDataSnapshot = useMappingQaAllergenModel(
    qaAllergenListSnapshot?.allergensModel?.[idxCurrentSlide],
    qaAllergenListSnapshot?.allergenStatement
  );

  const { allergenForm } = mappingAllergenData;
  const { allergenForm: allergenFormSnapshot } = mappingAllergenDataSnapshot;

  const handleChangeRegion = (value) => {
    setRegional(value);
  };

  const handleAddCustomAllergen = async () => {
    const customAllergenValue = await customAllergenForm.validateFields();
    const params = {
      productId,
      index: idxCurrentSlide,
      allergen: [
        {
          ...customAllergenValue,
          isPublish: false,
          regional,
        },
      ],
    };
    customAllergenMutation.mutate(params, {
      onSuccess: (response) => {
        if (response?.isSuccess) {
          customAllergenForm.resetFields();
          CustomNotification.success('Add new allergen successfully!');
        }
      },
    });
  };

  const religionAllergenData = isUs
    ? mappingAllergenData?.allergenUnitedStates ?? []
    : isWorldwide
    ? mappingAllergenData?.allergenWorldWide ?? []
    : [];

  const religionAllergenDataSnapshot = isUs
    ? mappingAllergenDataSnapshot?.allergenUnitedStates ?? []
    : isWorldwide
    ? mappingAllergenDataSnapshot?.allergenWorldWide ?? []
    : [];

  if (allergenForm) {
    return (
      <Fieldset
        title={transformToTextGroupName(allergenModel?.groupIds, productGroups)}
      >
        <AllergenGroupItem
          formInstance={allergenGroupForm}
          initialValues={{
            ...allergenForm,
          }}
          allergenFormSnapshot={allergenFormSnapshot}
        />
        <AllergenGroupWarning />
        <QaAllergenRegional
          key={idxCurrentSlide}
          value={regional}
          onChange={(value) => handleChangeRegion(value)}
        >
          <Row style={{ marginTop: 10, height: 555 }}>
            <Col xs={20}>
              <QaAllergenGrid
                isEdit={false}
                gridInst={gridInstance}
                data={religionAllergenData}
                snapshotGridValues={religionAllergenDataSnapshot}
                allergenOptions={allergenOptions}
                onCellEditingStopped={onCellEditingStopped}
                onCellEditingStarted={onCellEditingStarted}
                onRowClicked={onRowClicked}
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginLeft: 6,
              }}
            >
              <Form form={customAllergenForm}>
                <CustomAllergenAddition
                  gridInstance={gridInstance}
                  regional={regional}
                  productId={productId}
                  idxCurrent={idxCurrentSlide}
                  onAddCustomAllergen={handleAddCustomAllergen}
                >
                  <FormAllergen
                    productEnums={productEnums}
                    isUSRegion={isUs}
                    isWWRegion={isWorldwide}
                  />
                </CustomAllergenAddition>
              </Form>
            </Col>
          </Row>
        </QaAllergenRegional>
      </Fieldset>
    );
  }

  return (
    <WithLoading loading={true}>
      <Fieldset title={`Taco ${idxCurrentSlide + 1}`}>
        <AllergenGroupItem
          formInstance={allergenGroupForm}
          initialValues={{
            ...allergenForm,
          }}
        />
        <AllergenGroupWarning />
        <QaAllergenRegional
          key={idxCurrentSlide}
          value={regional}
          onChange={(value) => handleChangeRegion(value)}
        >
          <Row style={{ marginTop: 10, height: 555 }}>
            <Col xs={20}>
              <QaAllergenGrid
                isEdit={false}
                gridInst={gridInstance}
                data={religionAllergenData}
                allergenOptions={allergenOptions}
                onCellEditingStopped={onCellEditingStopped}
                onCellEditingStarted={onCellEditingStarted}
                onRowClicked={onRowClicked}
              />
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginLeft: 6,
              }}
            >
              <Form form={customAllergenForm}>
                <CustomAllergenAddition
                  gridInstance={gridInstance}
                  regional={regional}
                  productId={productId}
                  onAddCustomAllergen={handleAddCustomAllergen}
                >
                  <FormAllergen
                    productEnums={productEnums}
                    isUSRegion={isUs}
                    isWWRegion={isWorldwide}
                  />
                </CustomAllergenAddition>
              </Form>
            </Col>
          </Row>
        </QaAllergenRegional>
      </Fieldset>
    </WithLoading>
  );
};

export const AllergenGroupWarning = () => {
  return (
    <Typography.Text className='qa-allergen__text-warning'>
      If 'Level of Containment' is 'May Contain', then a Containment Sources
      must be selected
    </Typography.Text>
  );
};

export const AllergenGroupItem = (props) => {
  const { formInstance, initialValues, allergenFormSnapshot, ...rest } = props;

  useEffect(() => {
    formInstance.setFieldsValue({
      ...initialValues,
    });
  }, [formInstance, initialValues]);

  const isEdit = false;

  return (
    <Form
      className='qa-allergen'
      form={formInstance}
      style={{ paddingRight: 5 }}
      layout='vertical'
      {...rest}
    >
      <Row
        className={classnames('qa-allergen__input', {
          'qa-allergen__input--edit': isEdit,
        })}
        justify='space-between'
        style={{ marginBottom: 10 }}
      >
        <Col xs={11} style={{ maxWidth: 1000 }}>
          <QaAllergenSnapshot
            supplierModifiedValue={initialValues?.allergenStatement}
            snapshotValue={allergenFormSnapshot?.allergenStatement}
            highlightContent={
              <HighlightContent
                label='Allergen Statement:'
                value={allergenFormSnapshot?.allergenStatement}
              />
            }
            placement='right'
          >
            <Form.Item
              name='allergenStatement'
              label={
                <Typography.Text strong>Allergen Statement</Typography.Text>
              }
            >
              <PrimaryFieldSnapshot
                autoSize={{ minRows: 5, maxRows: 5 }}
                maxLength={ALLERGEN_LIMIT_CHARACTERS.allergenStatement}
                showCount
                readOnly={!isEdit}
                variant='textarea'
              />
            </Form.Item>
          </QaAllergenSnapshot>
        </Col>
        <Col xs={11}>
          <QaAllergenSnapshot
            supplierModifiedValue={initialValues?.allergenSpecificationAgency}
            snapshotValue={allergenFormSnapshot?.allergenSpecificationAgency}
            highlightContent={
              <HighlightContent
                label='Allergen Specification Agency:'
                value={allergenFormSnapshot?.allergenSpecificationAgency}
              />
            }
          >
            <Form.Item
              name='allergenSpecificationAgency'
              label={
                <Typography.Text strong>
                  Allergen Specification Agency
                </Typography.Text>
              }
            >
              <PrimaryFieldSnapshot variant='text' readOnly={!isEdit} />
            </Form.Item>
          </QaAllergenSnapshot>
          <QaAllergenSnapshot
            supplierModifiedValue={initialValues?.allergenSpecificationName}
            snapshotValue={allergenFormSnapshot?.allergenSpecificationName}
            highlightContent={
              <HighlightContent
                label='Allergen Specification Name:'
                value={allergenFormSnapshot?.allergenSpecificationName}
              />
            }
          >
            <Form.Item
              name='allergenSpecificationName'
              label={
                <Typography.Text strong>
                  Allergen Specification Name
                </Typography.Text>
              }
            >
              <PrimaryFieldSnapshot variant='text' readOnly={!isEdit} />
            </Form.Item>
          </QaAllergenSnapshot>
        </Col>
      </Row>
    </Form>
  );
};

export const AllergenGroupForm = ({
  children,
  isDisabledStatement = true,
  ...rest
}) => {
  return (
    <>
      <Form
        className='qa-allergen'
        style={{ paddingRight: 5 }}
        layout='vertical'
        {...rest}
      >
        <Row
          className='qa-allergen__input'
          justify='space-between'
          style={{ marginBottom: 10 }}
        >
          <Col xs={11} style={{ maxWidth: 1000 }}>
            <Form.Item
              name='allergenStatement'
              label={
                <Typography.Text strong>Allergen Statement</Typography.Text>
              }
            >
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 5 }}
                maxLength={ALLERGEN_LIMIT_CHARACTERS.allergenStatement}
                showCount
                disabled={isDisabledStatement}
              />
            </Form.Item>
          </Col>
          <Col xs={11}>
            <Form.Item
              name='allergenSpecificationAgency'
              label={
                <Typography.Text strong>
                  Allergen Specification Agency
                </Typography.Text>
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='allergenSpecificationName'
              label={
                <Typography.Text strong>
                  Allergen Specification Name
                </Typography.Text>
              }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {children}
    </>
  );
};

const QaAllergenSnapshot = (props) => {
  const { isSuccess } = useGetSpecAllergensSnapshot();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  if (isSuccess && isToggleSnapshot) {
    return <RetailerSnapshot {...props} />;
  }

  return <>{props.children}</>;
};
