import React, { useState, useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Row, Col, Typography, Skeleton } from 'antd';
import { LabelValueWrapper } from 'common/components';
import { formatMDY } from 'utils/formatDate';
import { formatSizeUnits } from 'utils/formatSizeUnits';
import EmptyFolderImg from 'assets/folder/empty-folder.png';
import FolderImg from 'assets/folder/folder.png';
import ShareFolderImg from 'assets/folder/shared-folder.png';
import { get } from 'lodash';
import './ScheduleInformation.less';
import { injectIntl } from 'react-intl';
import { InfoWithLabel } from 'common/components';

const { Text } = Typography;

const ScheduleInfomation = (props) => {
  const { ownerFolder, intl, scheduleSetting } = props;

  const thumbnailImg = !ownerFolder
    ? ShareFolderImg
    : get(scheduleSetting, 'items') > 0
    ? FolderImg
    : EmptyFolderImg;

  const metaData = [
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.owner',
      }),
      info:
        get(scheduleSetting, 'ownerName', '') &&
        get(scheduleSetting, 'ownerName', ''),
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.dateCreated',
      }),
      info:
        formatMDY(get(scheduleSetting, 'dateCreated', '')) !== 'Invalid Date'
          ? formatMDY(get(scheduleSetting, 'dateCreated', ''))
          : '',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.dateShared',
      }),
      info:
        formatMDY(get(scheduleSetting, 'dateShared', '')) !== 'Invalid Date'
          ? formatMDY(get(scheduleSetting, 'dateShared', ''))
          : '',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.editable',
      }),
      info: get(scheduleSetting, 'editable', '') ? 'Yes' : 'No',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.shareable',
      }),
      info: get(scheduleSetting, 'shareable', '') ? 'Yes' : 'No',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.specialPurpose',
      }),
      info: get(scheduleSetting, 'specialPurpose', '') ? 'Yes' : 'No',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.items',
      }),
      info:
        get(scheduleSetting, 'items', '') && get(scheduleSetting, 'items', ''),
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.estSize',
      }),
      info:
        get(scheduleSetting, 'estSize', '') &&
        formatSizeUnits(get(scheduleSetting, 'estSize', '')),
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.lastUpdated',
      }),
      info: (
        <>
          <div>
            {formatMDY(get(scheduleSetting, 'lastUpdated', '')) !==
            'Invalid Date'
              ? formatMDY(get(scheduleSetting, 'lastUpdated', ''))
              : ''}
          </div>
          <div>
            {get(scheduleSetting, 'lastUpdatedBy', '') &&
              get(scheduleSetting, 'lastUpdatedBy', '')}
          </div>
        </>
      ),
    },

    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.lastDownload',
      }),
      info:
        formatMDY(get(scheduleSetting, 'lastDownload', '')) !== 'Invalid Date'
          ? formatMDY(get(scheduleSetting, 'lastDownload', ''))
          : '',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.lastSchedule',
      }),
      info:
        formatMDY(get(scheduleSetting, 'lastSchedule', '')) !== 'Invalid Date'
          ? formatMDY(get(scheduleSetting, 'lastSchedule', ''))
          : '',
    },
  ];

  const infoColProps = {
    infoColProps: {
      flex: 'auto',
    },
    labelColProps: {
      flex: '135px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  const dataLeft = metaData
    ? metaData.slice(0, Math.ceil(metaData.length / 2))
    : [];
  const dataRight = metaData
    ? metaData.slice(Math.ceil(metaData.length / 2), metaData.length)
    : [];
  return (
    <Skeleton loading={!scheduleSetting} active>
      <div className='download-information'>
        <Row align='bottom' style={{ marginBottom: 10 }}>
          <Col span={6}>
            <img
              className='download-information__logo'
              src={thumbnailImg}
              atl={scheduleSetting?.folderName}
            />
          </Col>
          <Col span={18}>
            <Text className='download-information__name'>
              {scheduleSetting?.folderName}
            </Text>
          </Col>
        </Row>
        <div className='folder-panel-header__meta-info'>
          <Row>
            <Col span={12}>
              {dataLeft &&
                dataLeft.length > 0 &&
                dataLeft.map((item, index) => {
                  return (
                    <InfoWithLabel
                      key={index}
                      info={item.info}
                      label={item.label}
                      {...infoColProps}
                    />
                  );
                })}
            </Col>
            <Col span={12}>
              {dataRight &&
                dataRight.length > 0 &&
                dataRight.map((item, index) => {
                  return (
                    <InfoWithLabel
                      key={index}
                      info={item.info}
                      label={item.label}
                      {...infoColProps}
                    />
                  );
                })}
            </Col>
          </Row>
        </div>
      </div>
    </Skeleton>
  );
};

export default injectIntl(ScheduleInfomation);
