import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Messages from 'i18n/messages/maintenance';
import { FormOutlined } from '@ant-design/icons';

import { forwardTo } from 'utils/common/route';
import RibbonButton from 'common/components/button/RibbonButton';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as maintenanceActions from 'pages/maintenance/controllers/actions';

const EditPostGridView = () => {
  const dispatch = useDispatch();
  const selectedPostDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const postId =
    selectedPostDetail && selectedPostDetail.length > 0
      ? selectedPostDetail[0].id
      : undefined;

  // const handleOpenPost = () => {
  //   dispatch(maintenanceActions.enableOpenPost());
  //   forwardTo(`/maintenance/help/post/${postId}`);
  // };

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editPost}
      onClick={() => forwardTo(`/maintenance/help/post/${postId}`)}
      disabled={postId === undefined || selectedPostDetail.length !== 1}
    />
  );
};

export default EditPostGridView;
