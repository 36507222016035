import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import { forwardTo } from 'utils/common/route';

import markupMaintenanceReducer from 'pages/markup-maintenance/controllers/reducer';
import markupMaintenanceSaga from 'pages/markup-maintenance/controllers/saga';

import { RibbonBar, RibbonDivider } from 'common/components';
import MaintenanceMarkupCreateView from './MaintenanceMarkupCreateView';
import { Divider } from 'antd';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import * as markupMaintenanceActions from 'pages/markup-maintenance/controllers/actions';
import * as markupMaintenanceSelectors from 'pages/markup-maintenance/controllers/selectors';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const key = 'markupMaintenance';

const MaintenanceMarkupFullView = () => {
  useInjectReducer({ key, reducer: markupMaintenanceReducer });
  useInjectSaga({ key, saga: markupMaintenanceSaga });

  const dispatch = useDispatch();

  const { isCreate, statusClone } = useSelector(
    markupMaintenanceSelectors.selectMarkupForm()
  );

  const handleCancelMarkup = () => {
    forwardTo('/maintenance/markup/list');
  };

  const handleCloneMarkup = () => {
    dispatch(markupMaintenanceActions.confirmCloneMarkup());
  };

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <AdvanceStack isDisabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.MARKUP}>
        <MaintenanceMarkupCreateView
          handleCloneMarkup={handleCloneMarkup}
          statusClone={statusClone}
          handleCancelMarkup={handleCancelMarkup}
          isCreate={isCreate}
        />
      </Can>
    </RibbonBar>
  );
};

export default MaintenanceMarkupFullView;
