import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMarkupMaintenance = (state) =>
  state.markupMaintenance || initialState;

const selectMarkupGridLoading = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.gridLoading
  );

const selectMarkupForm = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.markupForm
  );

const selectMarkupGridPageSize = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.pageSize
  );

const selectMarkupGridPageNumber = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.pageNumber
  );

const selectMarkupGridSearch = () =>
  createSelector(selectMarkupMaintenance, (markupState) => markupState.search);

const selectMarkupList = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.markupList
  );

const selectMarkupGridColumns = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.gridColumns
  );

const selectMarkupGridTotal = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.gridTotal
  );

const selectMarkupDetailData = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.detailData
  );

const selectIsDetailsViewMarkup = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.isDetailsView
  );

const selectIsMarkupUploadMediaOpen = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.isUploadMarkupMediaOpen
  );

// Curated Query
const selectCuratedQueryLoading = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryLoading
  );
const selectCuratedQueryColumns = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryColumns
  );
const selectCuratedQuery = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQuery
  );
const selectCuratedQueryPageSize = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryPageSize
  );
const selectCuratedQueryPageNumber = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryPageNumber
  );
const selectCuratedQueryTotal = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryTotal
  );
const selectCuratedQueryError = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryError
  );
const selectCuratedQuerySearch = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQuerySearch
  );

const selectCuratedQueryShortDetailData = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryShortDetailData
  );

const selectCuratedQueryShortDetailLoading = () =>
  createSelector(
    selectMarkupMaintenance,
    (markupState) => markupState.curatedQueryShortDetailLoading
  );

export {
  selectMarkupGridLoading,
  selectMarkupGridPageSize,
  selectMarkupGridPageNumber,
  selectMarkupGridSearch,
  selectMarkupList,
  selectMarkupGridColumns,
  selectMarkupGridTotal,
  selectMarkupDetailData,
  selectIsDetailsViewMarkup,
  selectMarkupForm,
  selectIsMarkupUploadMediaOpen,
  // Curated Query
  selectCuratedQueryLoading,
  selectCuratedQueryColumns,
  selectCuratedQuery,
  selectCuratedQueryPageSize,
  selectCuratedQueryPageNumber,
  selectCuratedQueryTotal,
  selectCuratedQueryError,
  selectCuratedQuerySearch,
  selectCuratedQueryShortDetailData,
  selectCuratedQueryShortDetailLoading,
};
