import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectTransitionPageState = (state) =>
  state.transitionPage || initialState;

const selectTransitionPageDetail = () =>
  createSelector(
    selectTransitionPageState,
    (state) => state.transitionPageDetail
  );

const selectSaveTransitionPageAPI = () =>
  createSelector(
    selectTransitionPageState,
    (state) => state.saveTransitionPageAPI
  );

const selectTransitionPagePreviewSize = () =>
  createSelector(selectTransitionPageState, (state) => state.previewSize);

export {
  selectTransitionPageDetail,
  selectSaveTransitionPageAPI,
  selectTransitionPagePreviewSize,
};
