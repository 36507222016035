import React, { useState, useRef } from 'react';

import { useNode, useEditor } from '@craftjs/core';
import { Popover, Menu, Tooltip } from 'antd';
import { PlusOutlined, DragOutlined } from '@ant-design/icons';

import EmailEditorSectionMenu from './EmailEditorSectionMenu';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const EmailEditorSectionAction = () => {
  const addBtnRef = useRef(null);
  const intl = useIntl();
  const {
    connectors: { drag },
    parentId,
    id,
  } = useNode((state) => {
    return { parentId: state.data.parent };
  });

  const { enabled } = useEditor((state) => ({
    nodes: state.nodes,
    enabled: state.options.enabled,
  }));

  const [visible, setVisible] = useState(false);

  const onClickAddBtn = () => {
    setVisible(true);
  };

  const clickOutAddSectionMenu = (e) => {
    if (!addBtnRef.current) return;

    if (
      e.target !== addBtnRef?.current &&
      !addBtnRef.current.contains(e.target)
    )
      setVisible(false);
  };

  useDocumentAttachEvent({ name: 'click', handler: clickOutAddSectionMenu });

  return enabled ? (
    <div className='email-editor__section-action'>
      <div className='email-editor__section-action-add-btn'>
        <Popover
          visible={visible}
          trigger='click'
          placement='right'
          content={<EmailEditorSectionMenu nodeId={id} parentId={parentId} />}
          overlayClassName='email-editor__section-pop'
        >
          <div ref={addBtnRef}>
            <Tooltip
              title={intl.formatMessage(Messages.addSectionTooltip)}
              mouseEnterDelay={1}
            >
              <PlusOutlined onClick={onClickAddBtn} />
            </Tooltip>
          </div>
        </Popover>
      </div>
      <div className='email-editor__section-action-drag-btn' ref={drag}>
        <Tooltip
          title={intl.formatMessage(Messages.dragSectionTooltip)}
          mouseEnterDelay={1}
        >
          <DragOutlined />
        </Tooltip>
      </div>
    </div>
  ) : null;
};

export default EmailEditorSectionAction;
