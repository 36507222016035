import _ from 'lodash';

import useGetProperties from './useGetProperties';

import QueryDraggable from '../components/QueryDraggable';
import RenderTitleQueryCondition, {
  AddQueryButton,
  RenderStrictMode,
} from '../components/RenderRootTitle';
import { useGetProductEnums } from 'hooks';
import { findProductEnums } from 'hooks/useProductEnums';

export const useRenderTree = ({ propertiesData, entityType }) => {
  const { selectTreeData, getPropertyData } = useGetProperties({
    propertiesData,
    entityType,
  });

  const { flatProductEnums } = useGetProductEnums();

  const mappingTreeTitle = ({
    queryConditionsId,
    queryConditions,
    PropertyAdvancedFilter = null,
    addQueryCondition,
    ...restProp
  }) => {
    const { handleConjunction, handleQueryCondition, viewMode } = restProp;
    let treeData = _.cloneDeep(queryConditions);
    const rootKey = 'root';
    const keyList = [rootKey];
    const recursionRenderTreeProperty = ({
      dataConditions,
      parentValuePath,
    }) => {
      dataConditions?.forEach((conditionItem, index) => {
        const isParent = !!conditionItem?.children?.length;
        //* valuePath([]) is path of _.set, use for updating value, delete either as a key of tree item
        const valuePath = [...parentValuePath, index + ''];
        const key = valuePath.join('.');

        const property = getPropertyData(conditionItem?.fieldName);

        property['children'] = conditionItem?.children || [];
        property.strictMode = conditionItem?.strictMode;
        property.id = conditionItem?.id;
        property.values = conditionItem?.values;
        property.valuePath = valuePath;
        property.value = conditionItem.value;
        property.otherValue = conditionItem.otherValue;
        property.isParent = isParent;
        property.operator = conditionItem.operator;
        property.acceptableValueTemp = conditionItem?.acceptableValueTemp;
        property.operation = conditionItem?.operation || 'OR';

        const propertyEnums = findProductEnums(
          flatProductEnums,
          property?.listName
        );

        conditionItem['key'] = key;
        conditionItem['title'] = (
          <>
            {PropertyAdvancedFilter ? (
              <PropertyAdvancedFilter key={key} property={property} />
            ) : (
              <QueryDraggable
                key={key}
                index={index}
                draggableId={key}
                property={property}
                selectTreeData={selectTreeData}
                propertyEnums={propertyEnums}
                queryConditionsId={queryConditionsId}
                addQueryCondition={addQueryCondition}
                {...restProp}
              />
            )}
          </>
        );
        if (isParent) {
          recursionRenderTreeProperty({
            dataConditions: conditionItem?.children || [],
            parentValuePath: [...valuePath, 'children'],
          });
          keyList.push(key);
        }
      });
    }; //* end recursionRenderTreeProperty

    //* root fields
    treeData['isParent'] = true;
    treeData['key'] = rootKey;
    treeData['title'] = (
      <>
        {PropertyAdvancedFilter ? (
          <PropertyAdvancedFilter key={treeData?.rootKey} property={treeData} />
        ) : (
          <RootGroupQuery
            viewMode={viewMode}
            treeData={treeData}
            handleConjunction={handleConjunction}
            handleQueryCondition={handleQueryCondition}
            queryConditions={queryConditions}
            addQueryCondition={addQueryCondition}
          />
        )}
      </>
    );
    recursionRenderTreeProperty({
      dataConditions: treeData?.children || [],
      parentValuePath: ['children'],
    });

    treeData['collapseKey'] = keyList;
    if (!treeData?.isAddButton && !PropertyAdvancedFilter) {
      treeData['children'] = [
        ...(treeData?.children || []),
        {
          title: (
            <div style={{ pointerEvents: viewMode ? 'none' : 'auto' }}>
              <AddQueryButton
                isRoot
                property={{ valuePath: ['children'] }}
                addQueryCondition={addQueryCondition}
              />
            </div>
          ),
          key: 'add_button',
        },
      ];
      treeData['isAddButton'] = true;
    }

    return treeData;
  };

  return { mappingTreeTitle };
};

const RootGroupQuery = ({
  viewMode,
  treeData,
  handleConjunction,
  handleQueryCondition,
  queryConditions,
  addQueryCondition,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        pointerEvents: viewMode ? 'none' : 'auto',
      }}
    >
      <RenderTitleQueryCondition
        viewMode={viewMode}
        style={{
          marginTop: 8,
          with: '100%',
          flex: '1',
        }}
        handleConjunction={handleConjunction}
        property={{
          valuePath: [],
          operation: treeData?.operation,
        }}
      />
      <RenderStrictMode
        handleQueryCondition={handleQueryCondition}
        queryConditions={queryConditions}
      />
      <div
        className='query-condition-draggable__icon'
        style={{ marginLeft: 'auto', marginRight: 7 }}
      >
        <AddQueryButton
          property={{ valuePath: ['children'] }}
          addQueryCondition={addQueryCondition}
        />
      </div>
    </div>
  );
};
