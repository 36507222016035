import * as types from './constants';

//? get workflow detail
export function getWorkflowDetail(payload) {
  return {
    type: types.GET_WORKFLOW_DETAIL,
    payload,
  };
}

export function getWorkflowDetailSuccess(payload) {
  return {
    type: types.GET_WORKFLOW_DETAIL_SUCCESS,
    payload,
  };
}

export function getWorkflowDetailFail(payload) {
  return {
    type: types.GET_WORKFLOW_DETAIL_FAIL,
    payload,
  };
}

// workflow grid
export function getWorkflowGrid(payload) {
  return {
    type: types.GET_WORKFLOW_GRID,
    payload,
  };
}

export function getWorkflowGridSuccess(payload) {
  return {
    type: types.GET_WORKFLOW_GRID_SUCCESS,
    payload,
  };
}

export function getWorkflowGridError(payload) {
  return {
    type: types.GET_WORKFLOW_GRID_ERROR,
    payload,
  };
}
// workflow grid column
export const getWorkflowGridColumns = (gridName) => ({
  type: types.GET_WORKFLOW_GRID_COLUMN,
  gridName,
});

export const getWorkflowGridColumnsSuccess = (columns) => {
  return {
    type: types.GET_WORKFLOW_GRID_COLUMN_SUCCESS,
    columns,
  };
};

export const getWorkflowGridColumnsError = (error) => ({
  type: types.GET_WORKFLOW_GRID_COLUMN_ERROR,
  error,
});

// update workflow filters
export const updateWorkflowFilters = (workflowFilters) => {
  return { type: types.UPDATE_WORKFLOW_FILTER, workflowFilters };
};

//* get workflow step detail
export function getWfSelectedStep(payload) {
  return {
    type: types.GET_WORKFLOW_SELECTED_STEP,
    payload,
  };
}

export function getWfSelectedStepSuccess(payload) {
  return {
    type: types.GET_WORKFLOW_SELECTED_STEP_SUCCESS,
    payload,
  };
}

export function getWfSelectedStepFail(payload) {
  return {
    type: types.GET_WORKFLOW_SELECTED_STEP_FAIL,
    payload,
  };
}

//* get workflow imple info
export function getWfImplInfo(payload) {
  return {
    type: types.GET_WF_IMPL_INFO,
    payload,
  };
}

export function getWfImplInfoSuccess(payload) {
  return {
    type: types.GET_WF_IMPL_INFO_SUCCESS,
    payload,
  };
}

export function getWfImplInfoFail(payload) {
  return {
    type: types.GET_WF_IMPL_INFO_FAIL,
    payload,
  };
}

//* get workflow tasks detail
export function getWorkflowTasks(payload) {
  return {
    type: types.GET_WORKFLOW_TASKS_INFO,
    payload,
  };
}

export function getWorkflowTasksSuccess(payload) {
  return {
    type: types.GET_WORKFLOW_TASKS_INFO_SUCCESS,
    payload,
  };
}

export function getWorkflowTasksFail(payload) {
  return {
    type: types.GET_WORKFLOW_TASKS_INFO_FAIL,
    payload,
  };
}

//* get workflow implement steps
export function getWfImplSteps(payload) {
  return {
    type: types.GET_WF_IMPL_STEPS,
    payload,
  };
}

export function getWfImplStepsSuccess(payload) {
  return {
    type: types.GET_WF_IMPL_STEPS_SUCCESS,
    payload,
  };
}

export function getWfImplStepsFail(payload) {
  return {
    type: types.GET_WF_IMPL_STEPS_FAIL,
    payload,
  };
}

//* get workflow implement current step
export function getWfImplCurrentStep(payload) {
  return {
    type: types.GET_WF_IMPL_CURRENT_STEP,
    payload,
  };
}

export function updateAnsweredTask(payload) {
  return {
    type: types.UPDATE_ANSWERED_TASK,
    payload,
  };
}

export function updateCurrentStep() {
  return {
    type: types.UPDATE_CURRENT_STEP,
  };
}

export function updateAcceptStepGrid(isUpdateAcceptStepGrid) {
  return {
    type: types.UPDATE_ACCEPT_STEP_GRID,
    isUpdateAcceptStepGrid,
  };
}

export function updateApproveStepGrid(isUpdateApproveStepGrid) {
  return {
    type: types.UPDATE_APPROVE_STEP_GRID,
    isUpdateApproveStepGrid,
  };
}
