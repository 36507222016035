import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Input, Spin, notification, Row } from 'antd';
import gridViewReducer from 'common/components/grid-view/controllers/reducer';
import gridViewSaga from 'common/components/grid-view/controllers/saga';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import { Form } from 'common/components';

import * as selectorsGlobal from 'redux/global/selectors';
import * as actionGlobal from 'redux/global/actions';
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import * as services from 'services/apl/index.js';
import messagesProduct from 'i18n/messages/product';
import { useIntl } from 'react-intl';
const keyGridView = 'gridView';

const ModalAddFolderToAPL = (props) => {
  useInjectReducer({ key: keyGridView, reducer: gridViewReducer });
  useInjectSaga({ key: keyGridView, saga: gridViewSaga });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const visibleModal = useSelector(selectorsGlobal.selectShowAddFolderToAPL());
  const folderList = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );
  const folderListIds = folderList?.map((item) => item.id);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const onCloseModal = () => {
    form.resetFields();
    dispatch(actionGlobal.updateAddFolderToAPL(false));
  };
  const intl = useIntl();

  const handleAddToAPL = (value) => {
    setLoading(true);
    const params = {
      folderIds: folderListIds,
      aplName: value.aplName,
      aplDescription: value.aplDescription,
    };
    services
      .addFoldersIntoNewAPL(params)
      .then((response) => {
        if (response.isSuccess) {
          notification.success({
            message: (
              <>
                <Row style={{ marginBottom: 10 }}>
                  {response.data.productIdsAdded.length} product(s) adds
                  successfully
                </Row>
                <Row>
                  {response.data.productIdsIgnored.length} product(s) adds error
                </Row>
              </>
            ),
          });
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {
        notification.error({ message: error });
      })
      .finally(() => {
        setLoading(false);
        onCloseModal();
      });
  };

  return (
    <>
      <Modal
        bodyStyle={{ padding: 20 }}
        title={intl.formatMessage(messagesProduct.addProductToNewAPLLabel)}
        visible={visibleModal}
        width={'30vw'}
        onCancel={onCloseModal}
        destroyOnClose
        onOk={() => form.submit()}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            id='add-to-apl-form'
            onFinish={(value) => handleAddToAPL(value)}
          >
            <Form.Item
              name='aplName'
              label='Name: '
              rules={[
                {
                  required: true,
                  message: 'APL name is required',
                },
              ]}
              placeHolder='Input APL name'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 17 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='aplDescription'
              label='Description: '
              placeHolder='Input APL description'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 17 }}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ModalAddFolderToAPL;
