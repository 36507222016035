import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, Col, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { FormCancelButton } from 'common/components';

import './WithAggridHeaderEdit.less';

const { Title } = Typography;

const WithAggridHeaderEdit = (props, ref) => {
  const {
    title,
    children,
    show = false,
    buttons,
    extraButtons,
    allowEdit = true,
  } = props;

  const [showEdit, setShowEdit] = useState(show);
  const [isEdit, setIsEdit] = useState(false);

  const onMouseEnter = () => {
    !show && setShowEdit(true);
  };

  const onMouseLeave = () => {
    !show && setShowEdit(false);
  };

  const toggleEditMode = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);

  useImperativeHandle(ref, () => ({
    changeEditMode: (editMode) => {
      setIsEdit(editMode);
    },
    getEditMode: () => isEdit,
  }));

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Row
        align='middle'
        style={{ height: 27, marginBottom: 7 }}
        gutter={[5, 5]}
      >
        <Col>
          <Title className='agGrid-edit-header__title'>
            {title || 'Unknown'}
          </Title>
        </Col>
        <Col flex='auto' />
        {isEdit && (
          <>
            {buttons?.map((buttonItem, idx) => (
              <Col key={idx}>{buttonItem}</Col>
            ))}
          </>
        )}
        <Col className='agGrid-edit-header__section-btn-wrapper'>
          {showEdit && !isEdit && allowEdit && (
            <Button
              className={classnames(
                'agGrid-edit-header__section-btn',
                'agGrid-edit-header__section-btn-edit'
              )}
              type='link'
              icon={<EditOutlined />}
              onClick={toggleEditMode}
            />
          )}
          {isEdit && (
            <FormCancelButton
              className={classnames(
                'agGrid-edit-header__section-btn',
                'agGrid-edit-header__section-btn-cancel'
              )}
              onClick={toggleEditMode}
            />
          )}
        </Col>
        {extraButtons?.map((buttonItem, idx) => (
          <Col key={idx}>{buttonItem}</Col>
        ))}
      </Row>
      <Row>
        <Col xs={24}>{children && children}</Col>
      </Row>
    </div>
  );
};

WithAggridHeaderEdit.propsTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  show: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.object),
  extraButtons: PropTypes.arrayOf(PropTypes.object),
  allowEdit: PropTypes.bool,
};

export default forwardRef(WithAggridHeaderEdit);
