import _ from 'lodash';
import React, { useState } from 'react';
import {
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Form, SectionWrapper, WrapperSelect } from 'common/components';
import { AddNewItem, UpdateValueButton } from './DrugFactsButton';

import './ActiveIngredients.less';

const ACTIVE_INGREDIENTS_FIELD_NAME = [
  'ingredientName',
  'purposeDescriptor',
  'quantity',
  'quantityUom',
  'italicName',
  'italicPurposeDescriptor',
];

const ActiveIngredients = () => {
  const form = Form.useFormInstance();
  const activeIngredientsList = Form.useWatch('activeIngredients', form);

  const [mode, setMode] = useState();

  const onClickAddNew = () => {
    form.resetFields(ACTIVE_INGREDIENTS_FIELD_NAME);
    setMode('add');
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    form
      .validateFields(ACTIVE_INGREDIENTS_FIELD_NAME)
      .then((value) => {
        form.setFieldsValue({
          activeIngredients: [...(activeIngredientsList || []), value],
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ padding: '8px 0px', minHeight: '47vh' }}>
      <Row>
        <Col span={24}>
          <Form.Item name='dosageUnit' label='Dosage Unit'>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <ActiveIngredientsList setMode={setMode} mode={mode} />
      {mode === 'add' && <AddIngredientsForm />}
      <AddNewItem
        mode={mode}
        onClickAdd={onClickAdd}
        onClickCancel={onClickCancel}
        onClickAddNew={onClickAddNew}
      />
    </div>
  );
};

export default ActiveIngredients;

const ActiveIngredientsList = ({ setMode, mode }) => {
  const form = Form.useFormInstance();
  const activeIngredientsList = Form.useWatch('activeIngredients', form);

  const onClickUpdate = (index) => {
    const cloneActiveIngredientsList = _.cloneDeep(activeIngredientsList);

    form
      .validateFields(ACTIVE_INGREDIENTS_FIELD_NAME)
      .then((value) => {
        cloneActiveIngredientsList[index] = value;

        form.setFieldsValue({
          activeIngredients: cloneActiveIngredientsList,
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickDelete = (index) => {
    const cloneActiveIngredientsList = _.cloneDeep(activeIngredientsList);
    cloneActiveIngredientsList.splice(index, 1);

    form.setFieldsValue({
      activeIngredients: cloneActiveIngredientsList,
    });
  };

  const onClickEdit = (index, item) => {
    setMode(`edit-${index}`);
    form.setFieldsValue({ ...item });
  };

  const onCancel = () => {
    setMode('view');
  };

  return (
    <>
      <Form.Item name='activeIngredients' hidden />
      {activeIngredientsList?.map((item, index) => {
        const {
          ingredientName,
          quantity,
          quantityUom,
          purposeDescriptor,
          italicName,
          italicPurposeDescriptor,
        } = item;

        const isEdit = mode === `edit-${index}`;
        return (
          <div key={index}>
            {isEdit ? (
              <>
                <AddIngredientsForm />
                <UpdateValueButton
                  onCancel={onCancel}
                  onClickUpdate={() => onClickUpdate(index)}
                />
              </>
            ) : (
              <SectionWrapper
                title={
                  <Space>
                    <EditOutlined onClick={() => onClickEdit(index, item)} />
                    <DeleteOutlined onClick={() => onClickDelete(index)} />
                  </Space>
                }
                placement='right'
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <Row wrap={false}>
                  <Col style={{ maxWidth: '48%' }}>
                    <Typography.Text italic={italicName}>
                      {ingredientName}
                      {(quantity || quantityUom) && (
                        <span>
                          (
                          <span>
                            {quantity}
                            {quantityUom}
                          </span>
                          )
                        </span>
                      )}
                    </Typography.Text>
                  </Col>

                  <Col flex={1}></Col>

                  <Col style={{ maxWidth: '48%' }}>
                    <Typography.Text
                      italic={italicPurposeDescriptor}
                      style={{ textAlign: 'right', display: 'block' }}
                    >
                      {purposeDescriptor}
                    </Typography.Text>
                  </Col>
                </Row>
              </SectionWrapper>
            )}
          </div>
        );
      })}
    </>
  );
};

const AddIngredientsForm = () => {
  const form = Form.useFormInstance();
  const activeIngredientsList = Form.useWatch('activeIngredients', form);
  return (
    <div className='add-directions-form'>
      {activeIngredientsList?.length > 0 && <Divider />}
      <Row gutter={4}>
        <Col span={24}>
          <Form.Item
            name='ingredientName'
            rules={[{ required: true, message: 'Ingredient Name is required' }]}
          >
            <Input placeholder='Ingredient Name' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='purposeDescriptor'>
            <Input placeholder='Purpose' />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name='quantity'>
            <InputNumber style={{ width: '100%' }} placeholder='Quantity' />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='quantityUom'>
            <WrapperSelect
              placeholder='Unit'
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
              allowClear={true}
            >
              <Select.Option value='g'>g</Select.Option>
              <Select.Option value='mg'>mg</Select.Option>
              <Select.Option value='mcg'>mcg</Select.Option>
            </WrapperSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='italicName' valuePropName='checked'>
            <Checkbox>Italic Name</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='italicPurposeDescriptor' valuePropName='checked'>
            <Checkbox>Italic Purpose</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
