import React from 'react';
import PropTypes from 'prop-types';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import ShareControl from '../../controls/mapping/ShareControl';

const ShareSection = (props) => {
  const { selectedItemList, isDisabled } = props;
  return (
    <ShareControl
      detailCurrentItemsSelection={selectedItemList}
      isDisabled={isDisabled}
    />
  );
};

ShareSection.propsTypes = {
  selectedItemList: PropTypes.array,
};

export default ShareSection;
