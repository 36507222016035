import { Col, Popover, Row, Tooltip, Typography } from 'antd';

import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleTwoTone,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { useRequiredFieldsValidation } from './hooks';

import { FullWidthSpace } from 'common/components';

import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';

import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';

export const RequiredFieldsError = ({ productId, children }) => {
  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const { isAllFieldsValid } = useRequiredFieldsValidation(productId);

  const shouldShowValidateIcon =
    isProductAssignedByRetailer && requiredFields?.length > 0;

  return shouldShowValidateIcon ? (
    <Popover
      placement='left'
      title={null}
      content={<RequiredFieldsContent productId={productId} />}
      trigger='hover'
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <QuestionCircleTwoTone
          twoToneColor={isAllFieldsValid ? '#52c41a' : '#faad14'}
          style={{ fontSize: 18, paddingRight: 6 }}
        />
        {children}
      </div>
    </Popover>
  ) : null;
};

const RequiredFieldsContent = ({ productId }) => {
  const { checkValidRequiredField, groupRequiredFields } =
    useRequiredFieldsValidation(productId);

  return (
    <FullWidthSpace
      className='scroller--y qa-header-form__required-fields'
      style={{
        width: 300,
        maxHeight: 350,
        padding: '0px 8px',
        margin: '0px',
      }}
    >
      {groupRequiredFields.map((groupItem, index) => {
        return (
          <ErrorGroup
            type='parent'
            key={index}
            groupItem={groupItem}
            checkValidRequiredField={checkValidRequiredField}
          />
        );
      })}
    </FullWidthSpace>
  );
};

const ErrorGroup = ({ type, groupItem, checkValidRequiredField }) => {
  const headerExtra = groupItem.tooltip ? (
    <Tooltip title={groupItem.tooltip}>
      <QuestionCircleOutlined />
    </Tooltip>
  ) : null;

  return (
    <ErrorLayout
      header={groupItem.groupName}
      type={type}
      headerExtra={headerExtra}
    >
      {groupItem?.fields?.map((field) => {
        const isValidField = checkValidRequiredField({
          field,
        });
        const label = field.displayName;

        const icon = isValidField
          ? iconMessage['success']
          : iconMessage['error'];

        return <ErrorItem label={label}>{icon}</ErrorItem>;
      })}
      {groupItem?.childGroups?.length > 0 ? (
        <FullWidthSpace>
          {groupItem?.childGroups?.map((groupItem, index) => {
            return (
              <ErrorGroup
                type='child'
                key={index}
                groupItem={groupItem}
                checkValidRequiredField={checkValidRequiredField}
              />
            );
          })}
        </FullWidthSpace>
      ) : null}
    </ErrorLayout>
  );
};

const iconMessage = {
  error: <CloseOutlined style={{ color: '#cf1322' }} />,
  success: <CheckOutlined style={{ marginRight: 0, color: '#389e0d' }} />,
};

const ErrorLayout = ({ header, type, headerExtra, children, ...rest }) => {
  return (
    <div {...rest}>
      <Row wrap={false} gutter={8} style={{ alignItems: 'baseline' }}>
        <Col flex={1} style={{ minWidth: 0 }}>
          <Typography.Text
            style={{
              fontSize: 14,
              fontWeight:
                type === 'parent' ? 'bold' : 'child' ? 600 : undefined,
            }}
          >
            {header}
          </Typography.Text>
        </Col>

        <Col flex={0}>{headerExtra ? headerExtra : null}</Col>
      </Row>

      <div style={{ paddingLeft: type === 'child' ? 8 : undefined }}>
        {children}
      </div>
    </div>
  );
};

const ErrorItem = ({ label, children, flexIcon, ...rest }) => {
  return (
    <Row wrap={false} style={{ margin: '4px 0' }} {...rest}>
      <Col flex={1} style={{ minWidth: 0 }}>
        <Typography.Text>{label}</Typography.Text>
      </Col>
      <Col flex={0}>{children}</Col>
    </Row>
  );
};
