import * as actions from './constants';

export const qaToggleTabExpand = (status) => ({
  type: actions.QA_TOGGLE_TAB_EXPAND,
  status,
});

export const qaToggleReloadDocumentCertificateTab = () => ({
  type: actions.QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB,
});

export const qaToggleReloadDocumentCertificateTabSuccess = (status) => ({
  type: actions.QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB_SUCCESS,
  status,
});

export const qaToggleReloadDocumentWhenDelete = () => ({
  type: actions.QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE,
});

export const qaToggleReloadDocumentWhenDeleteSuccess = (status) => ({
  type: actions.QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE_SUCCESS,
  status,
});

export const qaSpecEditBulk = () => ({
  type: actions.QA_SPEC_EDIT_BULK,
});

export const qaSpecEditBulkSuccess = (editBulk) => ({
  type: actions.QA_SPEC_EDIT_BULK_SUCCESS,
  editBulk,
});
