import _ from 'lodash';

export const changeBreakout = (statement) => {
  if (!statement?.length) return;

  let coverToStringStatement = statement;
  let regex = /\([^\(]+\)|(,)/g;

  if (_.isArray(statement)) {
    coverToStringStatement = statement?.join();
  }

  const ingredientsRegex = coverToStringStatement?.replace(
    regex,
    (m, group1) => {
      if (!group1) return m;
      else return ';';
    }
  );

  const splittedIngredient = ingredientsRegex?.split(/[;]+/);
  const trimIngredients = splittedIngredient
    ?.filter(Boolean)
    ?.map((string) => string.trim());

  const removeDuplicateIngredients = [...new Set(trimIngredients)]?.filter(
    (item) => item !== ''
  );

  return removeDuplicateIngredients;
};
