import { NUMERIC_TYPE } from 'static/Constants';

const nullableModuleName = 'No Header';

export const getModuleFromMappingSource = (source) => {
  const modules = [];

  source.forEach((mapping) => {
    const foundModule = modules.find(
      (item) => item.moduleName === mapping.moduleName
    );
    const foundNullableModule = modules.find(
      (item) => item.moduleName === nullableModuleName
    );
    if (!foundModule) {
      if (!foundNullableModule && mapping.moduleName === null) {
        modules.push({
          moduleName: nullableModuleName,
          moduleDisplayName: nullableModuleName,
          moduleProperties: [],
        });
      } else if (mapping.moduleName !== null) {
        modules.push({
          moduleName: mapping.moduleName,
          moduleDisplayName: mapping.moduleDisplayName,
          moduleProperties: [],
        });
      }
    }
  });

  return modules;
};

const checkValidDataType = (type, filteredType) => {
  if (filteredType === 'all') return true;

  if (filteredType === 'number') {
    if (NUMERIC_TYPE.includes(type)) return true;
  }

  return type === filteredType;
};

export const formatMappingSourceProperties = (source, filteredType) => {
  let result = getModuleFromMappingSource(source);

  source.forEach((item) => {
    if (item.moduleName === null) {
      const foundIndexModule = result.findIndex(
        (module) => module.moduleName === nullableModuleName
      );
      if (checkValidDataType(item.dataType, filteredType)) {
        result[foundIndexModule].moduleProperties.push(item);
      }
    } else {
      const foundIndexModule = result.findIndex(
        (module) => module.moduleName === item.moduleName
      );
      if (checkValidDataType(item.dataType, filteredType)) {
        result[foundIndexModule].moduleProperties.push(item);
      }
    }
  });

  return result.filter((item) => item.moduleProperties.length > 0);
};

export const searchModuleProperties = (modulesNameMapping, searchText) => {
  let result = [];

  modulesNameMapping.forEach((item) => {
    const foundProperties = item.moduleProperties.filter((property) =>
      property.displayName?.toLowerCase().includes(searchText?.toLowerCase())
    );

    if (foundProperties.length > 0) {
      result.push({
        ...item,
        moduleProperties: foundProperties,
      });
    }
  });

  return result;
};

export const findProperty = (modulesNameMapping, propertyName, moduleName) => {
  for (const item of modulesNameMapping) {
    const foundProperty = item.moduleProperties.find(
      (property) =>
        property.fieldName === propertyName &&
        property.moduleName === moduleName
    );

    if (foundProperty) {
      return {
        ...foundProperty,
        moduleName: foundProperty.moduleName
          ? foundProperty.moduleName
          : 'NoHeader',
        moduleDisplayName: foundProperty.moduleDisplayName
          ? foundProperty.moduleDisplayName
          : 'No Header',
      };
    }
  }
};

export const checkDisplayAddButton = (selectedProperty, property) => {
  return selectedProperty?.fieldName !== property?.fieldName;
};
