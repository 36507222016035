export const initState = {
  loading: true,
  searchText: '',
  draggingIds: null,
  propertiesData: [],
  dataSearch: null,
  dataQueryConditions: {},
  selectedPropertyIds: [],
};

export const actionConfig = {
  UPDATE_LOADING: 'UPDATE_LOADING',
  UPDATE_DATA_ENTITY: 'UPDATE_DATA_ENTITY',
  UPDATE_DATA_SEARCH: 'UPDATE_DATA_SEARCH',
  UPDATE_SEARCH_TEXT: 'UPDATE_SEARCH_TEXT',
  UPDATE_QUERY_CONDITIONS: 'UPDATE_QUERY_CONDITIONS',
  UPDATE_SELECTED_PROPERTY_IDS: 'UPDATE_SELECTED_PROPERTY_IDS',
  UPDATE_DRAGGING_PROPERTY_IDS: 'UPDATE_DRAGGING_PROPERTY_IDS',
};

export const configReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'UPDATE_DATA_ENTITY':
      return {
        ...state,
        propertiesData: action.payload,
      };
    case 'UPDATE_QUERY_CONDITIONS':
      return {
        ...state,
        dataQueryConditions: action.payload,
      };

    case 'UPDATE_SELECTED_PROPERTY_IDS':
      return {
        ...state,
        selectedPropertyIds: action.payload,
      };
    case 'UPDATE_DRAGGING_PROPERTY_IDS':
      return {
        ...state,
        draggingIds: action.payload,
      };
    case 'UPDATE_DATA_SEARCH':
      return {
        ...state,
        dataSearch: action.payload,
      };
    case 'UPDATE_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    default:
      break;
  }
};
