import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import * as _ from 'lodash';
import { AssetTileCard } from 'pages/branded-assets/components';

function AssetTileView(props) {
  const { tileData, handleToggleAssetContext } = props;
  return (
    <React.Fragment>
      {!!tileData && (
        <List
          grid={{
            gutter: 3,
          }}
          dataSource={tileData}
          renderItem={(tileItemData, ind) => (
            <List.Item>
              <AssetTileCard
                key={
                  'asset-tile-view__tile-item-' +
                  _.get(tileItemData, 'id') +
                  ind
                }
                upc12={_.get(tileItemData, 'upc12')}
                widget={_.get(tileItemData, 'widget')}
                src={_.get(tileItemData, 'imgUrl')}
                alt='logo image'
                handleToggleAssetContext={handleToggleAssetContext}
              />
            </List.Item>
          )}
        />
      )}
    </React.Fragment>
  );
}

AssetTileView.propTypes = {
  // ? tileData -> data of tile table
  tileData: PropTypes.array,
};

export default React.memo(AssetTileView);
