import { getBase64 } from 'utils/image';
import { FileExcelOutlined, FileOutlined } from '@ant-design/icons';

export const mapFileTypeToPreviewFile = async (fileItem) => {
  if (fileItem?.type?.includes('image')) {
    const imageSrc = await getBase64(fileItem?.originFileObj);
    return imageSrc;
  }
  if (fileItem?.type?.includes('spreadsheetml.sheet')) {
    return FileExcelOutlined;
  }
  return FileOutlined;
};
