import React, { useMemo } from 'react';

import { Input, Form, InputNumber, Select, Checkbox, Tooltip } from 'antd';

import classnames from 'classnames';
import _ from 'lodash';

import './BasicFormItem.less';

const { TextArea } = Input;
const { Option } = Select;

const BasicFormItem = (props) => {
  const {
    name,
    label,
    rules = [],
    type,
    renderInput,
    selectProps = {
      options: [],
      inputProps: {},
    },
    inputProps = {},
    allowEdit = true,
    layout,
    showTooltip = false,
    ...restProps
  } = props;

  const newRuleItems = rules?.map((ruleItem = {}) => {
    let newRuleItem;

    const { type, message } = ruleItem;

    switch (type) {
      default:
        newRuleItem = ruleItem;
    }

    return newRuleItem;
  });

  const renderInputComponent = () => {
    if (renderInput) {
      return renderInput({ allowEdit, inputProps, name });
    }

    if (type === 'numeric') {
      return <InputNumber readOnly={!allowEdit} {...inputProps} />;
    }

    if (type === 'textarea') {
      return <TextArea readOnly={!allowEdit} {...inputProps} />;
    }

    if (type === 'checkbox') {
      return (
        <Checkbox
          className={classnames('basic-form-item__checkbox', {
            'basic-form-item__checkbox--no-edit': !allowEdit,
          })}
          disabled={!allowEdit}
          {...inputProps}
        />
      );
    }

    if (type === 'select') {
      let customSelectProps = selectProps?.inputProps || {};
      let customOtptionsProps =
        selectProps?.optionProps ||
        function () {
          return {};
        };

      if (!allowEdit) {
        customSelectProps = {
          ...customSelectProps,
          open: false,
          showSearch: false,
          allowClear: false,
        };
      } else {
      }

      return (
        <Select {...customSelectProps}>
          {selectProps?.options?.map((optionItem, idx) => (
            <Option
              value={optionItem?.value}
              key={optionItem?.value}
              {...customOtptionsProps(optionItem)}
            >
              {optionItem?.displayName}
            </Option>
          ))}
        </Select>
      );
    }

    return (
      <InputWrapper showTooltip={showTooltip}>
        <Input readOnly={!allowEdit} {...inputProps} />
      </InputWrapper>
    );
  };

  let defaultFormItemProps = useMemo(() => {
    let nextDefaultFormItemPropsProps = {};
    if (type === 'checkbox') {
      nextDefaultFormItemPropsProps = {
        ...nextDefaultFormItemPropsProps,
        valuePropName: 'checked',
      };

      if (layout === 'horizontal') {
        nextDefaultFormItemPropsProps = {
          ...nextDefaultFormItemPropsProps,
          htmlFor: name,
          label: (
            <span
              onClick={(e) => {
                name &&
                  document
                    .querySelector(`.basic-form-item__name-${name} input`)
                    ?.click();
              }}
            >
              {label && label}
            </span>
          ),
        };
      }
    }

    return nextDefaultFormItemPropsProps;
  }, [type, name, layout, label]);

  const { className, ...restDefaultFormItemProps } = defaultFormItemProps;

  return (
    <Form.Item
      shouldUpdate={true}
      className={classnames(
        'basic-form-item',
        `basic-form-item__name-${name}`,
        {
          'basic-form-item--no-edit': !allowEdit,
          'basic-form-item--horizontal-layout': layout === 'horizontal',
        }
      )}
      name={name}
      label={label}
      rules={newRuleItems}
      {...restDefaultFormItemProps}
      {...restProps}
    >
      {renderInputComponent()}
    </Form.Item>
  );
};

export default BasicFormItem;

const InputWrapper = (props) => {
  const { children, showTooltip, ...restProps } = props;

  const { value, checked } = restProps || {};

  const inputValue = value || checked;

  const renderChildren = () => {
    return React.Children.map(props.children, (child) => {
      return React.cloneElement(child, restProps);
    });
  };

  if (!showTooltip) {
    return <>{renderChildren()}</>;
  }
  return <Tooltip title={inputValue}>{renderChildren()}</Tooltip>;
};
