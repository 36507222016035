import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { QaGridEdit } from 'pages/qa-spec/components';

import {
  useGetIngredientColDefs,
  useProcessIngredientData,
} from 'pages/qa-spec/hooks/useIngredient';

const QaIngredientGrid = (props) => {
  const {
    gridInst,
    data,
    isEdit,
    onCellEditingStopped,
    ingredientOptions,
    onRowSelected,
    onReady,
    ...rest
  } = props;

  const { id: productId } = useParams();
  const procData = useProcessIngredientData(data);

  const fields = useGetIngredientColDefs(isEdit, ingredientOptions, productId);

  const rowClassRules = useMemo(
    () => ({
      'ag-row--error': (params) => {
        return !!params?.data?.error?.length > 0;
      },
    }),
    []
  );

  return (
    <QaGridEdit
      gridInst={gridInst}
      gridData={procData}
      fields={fields}
      isEdit={isEdit}
      getRowNodeId={(data) => {
        return data?.['id'];
      }}
      onCellEditingStopped={onCellEditingStopped}
      onRowSelected={onRowSelected}
      rowClassRules={rowClassRules}
      onReady={onReady}
      {...rest}
    />
  );
};

export default QaIngredientGrid;
