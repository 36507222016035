import React from 'react';

import reactCSS from 'reactcss';

import {
  FolderGridThumbnail,
  FolderGridTile,
} from 'pages/folders/shared/components';

const FolderGridTileComponent = (props) => {
  return (
    <div style={styles.folder.tile.wrapper}>
      <FolderGridTile {...props} />
    </div>
  );
};

const GRID_ITEM_RENDER = {
  thumbnails: {
    folder: FolderGridThumbnail,
  },
  tilesview: {
    folder: FolderGridTileComponent,
  },
};

const styles = reactCSS({
  default: {
    gridItem: {
      wrapper: { cursor: 'pointer' },
    },
    folder: {
      tile: {
        wrapper: {
          display: 'inline-block',
          margin: '12px 0 0 12px ',
          border: '1px solid grey',
        },
      },
    },
  },
});

const GridItem = (props) => {
  const { searchType, viewType, handleDoubleClick, ...gridItemProps } = props;
  const GridItemComponent = GRID_ITEM_RENDER[viewType][searchType];

  const newGridItemProps = {
    ...gridItemProps,
    disableEvent: true,
  };

  return (
    <div
      style={styles.gridItem.wrapper}
      onDoubleClick={() => handleDoubleClick(gridItemProps)}
    >
      <GridItemComponent {...newGridItemProps} />;
    </div>
  );
};

export { GridItem };
