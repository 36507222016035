import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form, notification } from 'antd';
import NewGdsnSubscriptionForm from './NewGdsnSubscriptionForm';
import { sendGdsnSubscriptionToOutsideService } from 'services/subscription';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import userSelectors from 'redux/user/selectors';

const NewGdsnSubscriptionModal = (props) => {
  const { visible, setVisible } = props;

  const [form] = Form.useForm();
  const [brick, setBrick] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [isInvalidField, setIsInvalidField] = useState(true);
  const [showGlnGrid, setShowGlnGrid] = useState(false);
  const reloadPage = useDispatchReloadPage();

  const { isRivirGdsnParty } = useSelector(userSelectors.makeSelectUserInfo());

  const handleSaveMappingConfig = async () => {
    const { ...values } = await form.validateFields();

    const brickCode = brick?.brickCode;
    let params = {
      ...values,
      targetGpc: brickCode,
    };

    try {
      setIsSubmitLoading(true);
      const response = await sendGdsnSubscriptionToOutsideService(params);
      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Subscribe new GDSN successfully!',
        });
        onCancel();
        reloadPage();
      } else {
        notification.error({
          message: message || 'Cannot subscript gdsn!',
        });
      }
      setIsSubmitLoading(false);
    } catch (error) {
      setIsSubmitLoading(false);
      notification.error({ message: 'Cannot subscript gdsn!' });
    }
  };

  const onCancel = () => {
    form.resetFields();
    setBrick('');
    setShowGlnGrid(false);
    setIsInvalidField(true);
    setIsDisabledSubmit(true);
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <Modal
          title={'New GDSN subscription'}
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={onCancel}
          destroyOnClose
          width={showGlnGrid ? 1100 : 825}
          maskClosable={false}
          className='modal-create-query'
          footer={[
            <Button
              key='save'
              type='primary'
              loading={isSubmitLoading}
              disabled={!isRivirGdsnParty || isDisabledSubmit || isInvalidField}
              onClick={handleSaveMappingConfig}
            >
              Submit
            </Button>,
            <Button key='back' onClick={onCancel}>
              Close
            </Button>,
          ]}
        >
          <NewGdsnSubscriptionForm
            form={form}
            brick={brick}
            setBrick={setBrick}
            setIsDisabledSubmit={setIsDisabledSubmit}
            setIsInvalidField={setIsInvalidField}
            showGlnGrid={showGlnGrid}
            setShowGlnGrid={setShowGlnGrid}
          />
        </Modal>
      )}
    </>
  );
};

export default NewGdsnSubscriptionModal;
