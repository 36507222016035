import React from 'react';
import PropTypes from 'prop-types';
import { Row, Drawer } from 'antd';

const ProductDrawer = (props) => {
  const { visible, isDisplayMask, contentDrawer, onClose } = props;
  const widthDrawer = props.widthDrawer ? props.widthDrawer : 500;
  const parentPadding = props.parentPadding ? props : 24;

  return (
    <Row>
      <Row
        style={{
          width: `calc(100% - ${
            visible && !isDisplayMask
              ? `${widthDrawer - parentPadding}px`
              : '0px'
          })`,
        }}
      ></Row>
      <Row width={visible && !isDisplayMask ? `${widthDrawer}px` : '0px'}>
        <Row className='site-drawer-render-in-current-wrapper'>
          <Drawer
            placement='right'
            closable={!isDisplayMask}
            onClose={onClose}
            visible={visible}
            getContainer={false}
            mask={isDisplayMask}
            style={{ position: 'absolute' }}
            width={widthDrawer}
          >
            {contentDrawer}
          </Drawer>
        </Row>
      </Row>
    </Row>
  );
};
ProductDrawer.propTypes = {
  // visible: show or hide drawer
  visible: PropTypes.bool.isRequired,
  // isDisplayMask: display mask of drawer or not
  isDisplayMask: PropTypes.bool.isRequired,
  // contentDrawer: content of drawer need to show
  contentDrawer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ProductDrawer;
