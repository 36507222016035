import DrugFactCase from './DrugFactCase';
import classNames from 'classnames';
const DrugFactDetailList = ({
  italic = false,
  strong = false,
  list,
  className,
}) => {
  return (
    <div
      className={classNames({
        'drug-fact__detail-list': true,
        [className]: !!className,
      })}
    >
      {list.map(({ text, hightLightText }, idx) => {
        return (
          <div
            style={{ backgroundColor: hightLightText ? hightLightText : '' }}
          >
            <DrugFactCase
              key={idx}
              text={text}
              italic={italic}
              strong={strong}
            />
          </div>
        );
      })}
    </div>
  );
};

export default DrugFactDetailList;
