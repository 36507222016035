import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { AgGridDownloadOptions } from 'common/components';

import { updateLoadingExport } from 'pages/ticketing-system/controllers/actions';
import { makeSelectLoadingExport } from 'pages/ticketing-system/controllers/selectors';

const ExportTicketingSystem = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(makeSelectLoadingExport());

  const handleDownload = (fileType) => {
    //* trigger download member grid event
    const exportTicketingSystemEvent = new CustomEvent(
      'exportTicketingSystemEvent',
      {
        detail: fileType,
      }
    );
    document.dispatchEvent(exportTicketingSystemEvent);
    //*show loading
    dispatch(updateLoadingExport(true));
  };

  return (
    <Dropdown
      overlay={
        <AgGridDownloadOptions
          handleDownload={handleDownload}
          loading={isLoading}
        />
      }
      placement='bottomLeft'
      style={{ backgroundColor: '#fff' }}
    >
      <RibbonButton
        icon={<DownloadOutlined />}
        label='Export'
        loading={isLoading}
      />
    </Dropdown>
  );
};

export default ExportTicketingSystem;
