import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';

import ActionsRibbonBar from 'common/components/contact-action/ActionsRibbonBar';
import { RibbonBar, RibbonDivider } from 'common/components';
import Transactions from './components/sections/user-full/Transactions';
import { OtherLayout } from './components';
import userSelectors from 'redux/user/selectors';

const UserFull = () => {
  const isActivityLogTab = useSelector(
    userSelectors.makeSelectIsActivityLogTab()
  );
  const contactCardInfo = useSelector(
    userSelectors.makeSelectContactCardInfo()
  );

  const disableBackControl = window.location.pathname === '/my-profile';

  return (
    <RibbonBar>
      {isActivityLogTab ? (
        <>
          <Transactions />
          <RibbonDivider />
        </>
      ) : null}
      <ActionsRibbonBar
        view='user'
        selectedItemList={[
          { id: contactCardInfo?.userId, fullName: contactCardInfo?.fullName },
        ]}
      />
      <OtherLayout
        ribbonSide='right'
        isHaveAddOpenItem={false}
        disableBackControl={disableBackControl}
      />
    </RibbonBar>
  );
};

export default UserFull;
