import React from 'react';
import { Space } from 'antd';
import {
  CodeSandboxOutlined,
  FileImageOutlined,
  FileUnknownOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';

const AssociationIconRenderer = (props) => {
  const { item } = props;
  return (
    <Space direction='horizontal' className='association-grid__icon-row'>
      {item &&
        item.data &&
        (item.data.type === 'DigitalAssets' ? (
          <FileImageOutlined className='association-grid__icon-row--icon' />
        ) : item.data.type === 'Folder' ? (
          <FolderOpenOutlined className='association-grid__icon-row--icon' />
        ) : item.data.type === 'Product' ? (
          <CodeSandboxOutlined className='association-grid__icon-row--icon' />
        ) : (
          <FileUnknownOutlined className='association-grid__icon-row--icon' />
        ))}
    </Space>
  );
};
export default AssociationIconRenderer;
