import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import NewStepControl from '../../controls/wf-template-full/NewStepControl';
import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import * as wfTemplateActions from 'pages/workflow-template-full/controllers/actions';

const NewStepSection = (props) => {
  const dispatch = useDispatch();
  const onAddNewStep = () => {
    dispatch(wfTemplateActions.toggleAddNewStep(true));
  };

  return <NewStepControl onClick={onAddNewStep} />;
};

export default NewStepSection;
