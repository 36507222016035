import { useLocalStorage } from 'hooks/localStorage';
import { useEffect } from 'react';

import {
  RECENT_SEARCH_KEY,
  RECENT_SEARCH_STORE_DEFAULT,
} from 'static/Constants';

export const useInitApp = () => {
  useLocalStorage(RECENT_SEARCH_KEY, RECENT_SEARCH_STORE_DEFAULT, true);

  useEffect(() => {
    window.urlBlocked = {};
  }, []);
};
