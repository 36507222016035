import React from 'react';
import { useSelector } from 'react-redux';

import { ViewLayout, AdvanceStack } from 'pages/home/ribbon/components';
import { RibbonBar, RibbonDivider } from 'common/components';
import DetailSection from 'pages/home/ribbon/components/sections/home-grid/DetailSection';
import ManageSharingSection from 'pages/home/ribbon/components/sections/shared/ManageSharingSection';
import FolderSection from 'pages/home/ribbon/components/sections/folder/FolderSection';
import OpenFolder from 'pages/home/ribbon/components/controls/folders/OpenFolder';
import Addition from './components/sections/home-grid/AdditionSection';
import ShareSection from './components/sections/shared/ShareSection';
import BackControl from './components/controls/home/Back';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as ribbonSelectors from 'redux/ribbon/selectors';

import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { useFolderByLevel } from 'hooks/useFolderByLevel';
import { useGridView } from 'hooks/useGridView';

import { PERMISSION_VIEW_ADVANCED_FILTER_IN_FOLDER } from 'static/Permission';
import { RIBBON_TYPES } from 'static/Constants';
import './Folder.less';

// const productsForMember = 'products/member';
// const assetsForMember = 'assets/member';

const FolderRibbon = () => {
  const { keepFolderBreadcrumb, showGridLoading } = useFolderByLevel();
  const { togglePreventCallApiGridView } = useGridView();

  const selectedItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  // const isBackToMemberProfile =
  //   pathname.includes(productsForMember) || pathname.includes(assetsForMember);
  // const isReportingsBack = pathname.includes('/reporting');

  const hasPermissionViewAdvancedFilter = useCheckPermissions(
    PERMISSION_VIEW_ADVANCED_FILTER_IN_FOLDER
  );

  const onChangeView = (typeView) => {
    //* not for detail view
    if (typeView === RIBBON_TYPES.DETAILSVIEW) return;

    //* show loading instantly because the loading in the grid shows up after the items change type
    showGridLoading();

    //* set keepFolderBreadcrumb to true for preventing clear breadcrumb in useEffect in folder gird
    keepFolderBreadcrumb(true);

    //* let useEffect call api
    togglePreventCallApiGridView(false);
  };

  const pathname = window.location.pathname;
  const isFavoriteView = pathname === '/favorite/favorite-folders';
  const isDisabledCloseDetail =
    window.location.search !== '?query=1' ||
    closeDetailStack?.name !== 'query' ||
    closeDetailStack?.stack.length < 2;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout callback={onChangeView} />
      <RibbonDivider />
      <Addition
        type='folder'
        selectedItemList={selectedItems}
        isFavoriteView={isFavoriteView}
      />
      <ShareSection selectedItemList={selectedItems} />
      <RibbonDivider />
      <OpenFolder />
      <FolderSection selectedItems={selectedItems} />
      <RibbonDivider />
      <AdvanceStack
        isNested
        disabledAdvanceFilter={!hasPermissionViewAdvancedFilter}
      />
      <BackControl
        ribbonSide='right'
        isDisabled={isDisabledCloseDetail}
        // isBackToMemberProfile={isBackToMemberProfile}
        // isReportingsBack={isReportingsBack}
      />
    </RibbonBar>
  );
};

export default FolderRibbon;
