import React from 'react';

import { Typography } from 'antd';
import { ReactComponent as CircleAddSvg } from 'assets/svg-icons/style-circle-add.svg';
import { ReactComponent as CircleRemoveSvg } from 'assets/svg-icons/style-circle-remove.svg';
import { ReactComponent as CheckedSvg } from 'assets/svg-icons/style-checked.svg';
import { ReactComponent as CircleLeftSvg } from 'assets/svg-icons/style-circle-left.svg';
import { ReactComponent as CircleRightSvg } from 'assets/svg-icons/style-circle-right.svg';

import PermissionTypeIcon from './PermissionTypeIcon';

import { deleteObjectField } from 'utils';
import classnames from 'classnames';

import './RolePermissionGroup.less';

const { Text } = Typography;

const RolePermissionGroup = (props) => {
  const { title, permissionListGrouped, type, formRole } = props;

  const handleClickAddAll = () => {
    let nextPermissionListGrouped = [...permissionListGrouped];
    
    nextPermissionListGrouped.map((permitData) => {
      deleteObjectField(permitData, ['isNew']);
      return permitData;
    });

    let nextPermissions = [
      ...formRole?.values?.permissions,
      ...nextPermissionListGrouped,
    ];

    formRole.setFieldValue('permissions', nextPermissions);
  };

  const handleClickRemoveAll = () => {
    let nextPermissions = [...formRole?.values?.permissions];

    permissionListGrouped.forEach((permitData) => {
      const findPermitIndex = nextPermissions.findIndex(
        (permit) => permit?.permissionName === permitData?.permissionName
      );
      nextPermissions.splice(findPermitIndex, 1);
    });

    formRole.setFieldValue('permissions', nextPermissions);
  };

  return (
    <div
      className={classnames('role-permission-group__wrap', {
        'role-permission-group__wrap--add': type === 'add',
        'role-permission-group__wrap--remove': type === 'remove',
      })}
    >
      <div className='role-permission-group__title'>
        <Text ellipsis>{title}</Text>
        {type === 'add' ? (
          <CircleRightSvg
            className='role-permission-group__title-get-all'
            onClick={handleClickAddAll}
          />
        ) : type === 'remove' ? (
          <CircleLeftSvg
            className='role-permission-group__title-get-all'
            onClick={handleClickRemoveAll}
          />
        ) : null}
      </div>
      <div className='role-permission-group__list'>
        {permissionListGrouped?.length > 0 &&
          permissionListGrouped.map((permitData) => {
            return (
              <div
                key={`permisison__${permitData?.permissionDisplayName}`}
                className={classnames('role-permission-group__list-item', {
                  'role-permission-group__list-item--new': permitData?.isNew,
                })}
              >
                <PermissionTypeIcon
                  type={type}
                  permitData={permitData}
                  formRole={formRole}
                />
                <Text>{permitData?.permissionDisplayName}</Text>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RolePermissionGroup;
