import React from 'react';

import { useNode, useEditor } from '@craftjs/core';

import EmailEditorSection from './EmailEditorSection';
import EmailEditorContentElement from './EmailEditorContentElement';
import EmailEditorInlineText from './EmailEditorInlineText';
import EmailEditorTag from './EmailEditorTag';
import {
  EmailEditorSectionSetting,
  EmailEditorGreetingSettings,
} from '../settings';

const EmailEditorGreeting = (props) => {
  const { id } = useNode();
  const { actions } = useEditor();
  const { text } = props;

  const onChangeContent = (value) => {
    actions.history.throttle().setProp(id, (props) => (props.text = value));
  };

  const content = (
    <>
      <EmailEditorInlineText content={text} onChangeContent={onChangeContent} />
      <EmailEditorTag tag='recipientName' />,
    </>
  );

  return (
    <EmailEditorSectionSetting settings={<EmailEditorGreetingSettings />}>
      <EmailEditorSection tags={['recipientName']}>
        <EmailEditorContentElement content={content} />
      </EmailEditorSection>
    </EmailEditorSectionSetting>
  );
};

EmailEditorGreeting.craft = {
  displayName: 'Greeting',
  props: {
    text: 'Dear',
    settings: {
      bold: false,
      italic: false,
      underLine: false,
      fontSize: 14,
      color: '#333',
    },
  },
  custom: { tags: ['recipientName'] },
};

export default EmailEditorGreeting;
