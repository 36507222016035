import { useEffect, useMemo, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { CustomNotification } from 'common/components';

import { useGetGroupLayoutContext } from '../multiple-panel/components/GroupLayoutProvider';

import * as productServices from 'services/product';

import { forwardTo } from 'utils/common/route';
import { multiplePanelsAllergens } from '../multiple-panel/tab-allergens/queries';
import { getKeyProductIngredients } from '../multiple-panel/tab-ingredients/useProductIngredients';

import {
  getKeySupplementPanels,
  getKeyFactPanels,
  getKeyDrugPanels,
  getPetFoodPanels,
} from '../multiple-panel/facts-panel/useQueryFactsPanel';
import {
  getKeyQaSpecDrugFactPanels,
  getKeyQaSpecFactPanels,
  getKeyQaSpecPetFoodPanels,
  getKeyQaSpecSupplementPanels,
} from 'pages/qa-spec/components/qa-nutrition-data/hook';

import { qaAllergensKeys } from 'pages/qa-spec/components/qa-allergen/hooks';

import { queryKeyQaSpecIngredients } from 'pages/qa-spec/components/qa-ingredient/hook';
import { qaGroupProductData } from 'pages/qa-spec/components/group-data/hooks';
import { productFullViewKeys } from 'hooks';

export const groupProductData = {
  getAllGroups: (productId) => [
    'group-product-data/get-all-groups',
    parseInt(productId),
  ],
  getDetailGroup: (productId, groupId) => [
    'group-product-data/get-group-detail',
    parseInt(productId),
    parseInt(groupId),
  ],
  getAllProductDetailGroup: (productId) => [
    'group-product-data/get-group-detail',
    parseInt(productId),
  ],
};

export const useGetAllGroupProduct = (productId) => {
  const queries = useQuery({
    queryKey: groupProductData.getAllGroups(productId),
    queryFn: async () => {
      const response = await productServices.getAllGroupProductData({
        productItemId: productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    cacheTime: 5 * 60 * 1000, // 30s
    enabled: !!productId,
  });

  return {
    productGroups: queries?.data?.groups ?? [],
    defaultGroup:
      queries?.data?.groups?.length >= 1 ? queries?.data?.groups?.[0] : null,
    ...queries,
  };
};

export const useInitialFormGroup = ({ groupDetail, formInstance }) => {
  const { onSetGroupLayoutState } = useGetGroupLayoutContext();

  const {
    ingredientGroups = [],
    allergenGroups = [],
    nutritionFacts = [],
    supplementFacts = [],
  } = groupDetail ?? {};

  const factsPanelGroups = useMemo(
    () => [...nutritionFacts, ...supplementFacts],
    [nutritionFacts, supplementFacts]
  );

  useEffect(() => {
    if (!groupDetail) return;

    formInstance.setFieldsValue({
      ingredientGroupIndices: ingredientGroups?.map(
        (ingredient) => ingredient.index
      ),
      allergenGroupIndices: allergenGroups?.map((allergen) => allergen.index),
      factsPanelsGroupIndices: factsPanelGroups?.map(
        (factsPanel) => factsPanel.id
      ),
    });

    onSetGroupLayoutState((prevState) => ({
      ...prevState,
      ingredients: ingredientGroups?.map((ingredient) => ingredient.index),
      allergens: allergenGroups?.map((allergen) => allergen.index),
      factsPanels: factsPanelGroups?.map((factsPanel) => factsPanel.id),
    }));
  }, [
    groupDetail,
    ingredientGroups,
    onSetGroupLayoutState,
    formInstance,
    factsPanelGroups,
    allergenGroups,
  ]);
};

export const useInvalidateFactsPanel = (productId) => {
  const queryClient = useQueryClient();

  const handleSyncAllergens = () => {
    // overview
    queryClient.invalidateQueries({
      queryKey: multiplePanelsAllergens.productAllergens(productId),
    });
    // qa-spec
    queryClient.invalidateQueries({
      queryKey: qaAllergensKeys.list(productId),
    });
  };

  const handleSyncIngredients = () => {
    // overview
    queryClient.invalidateQueries({
      queryKey: getKeyProductIngredients(productId),
    });
    // qa-spec
    queryClient.invalidateQueries({
      queryKey: queryKeyQaSpecIngredients(productId),
    });
  };

  const handleSyncFactsPanels = () => {
    // overview
    queryClient.invalidateQueries({
      queryKey: getKeyFactPanels(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getKeyDrugPanels(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getKeySupplementPanels(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getPetFoodPanels(productId),
    });

    // qa-spec
    queryClient.invalidateQueries({
      queryKey: getKeyQaSpecFactPanels(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getKeyQaSpecSupplementPanels(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getKeyQaSpecDrugFactPanels(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getKeyQaSpecPetFoodPanels(productId),
    });
  };

  const handleSyncAllTabPanels = () => {
    handleSyncAllergens();
    handleSyncIngredients();
    handleSyncFactsPanels();
  };

  return {
    handleSyncAllergens,
    handleSyncFactsPanels,
    handleSyncIngredients,
    handleSyncAllTabPanels,
  };
};

export const useInvalidateGroupDetail = (productId, groupId) => {
  const queryClient = useQueryClient();

  const handleSyncAllProductDetailGroup = () => {
    queryClient.invalidateQueries({
      queryKey: groupProductData.getAllProductDetailGroup(productId),
    });
  };

  const handleSyncSingleDetailGroup = () => {
    queryClient.invalidateQueries({
      queryKey: groupProductData.getDetailGroup(productId, groupId),
    });
  };

  const handleSyncAllQaDetailGroup = () => {
    queryClient.invalidateQueries({
      queryKey: qaGroupProductData.getAllProductQaDetailGroup(productId),
    });
  };

  const handleSyncSingleQaDetailGroup = () => {
    queryClient.invalidateQueries({
      queryKey: qaGroupProductData.getQaDetailGroup(productId, groupId),
    });
  };

  return {
    handleSyncAllProductDetailGroup,
    handleSyncSingleDetailGroup,
    handleSyncAllQaDetailGroup,
    handleSyncSingleQaDetailGroup,
  };
};

export const TYPE_GROUPS = {
  addGroup: 'ADD_GROUP',
  editGroup: 'EDIT_GROUP',
  previewGroup: 'PREVIEW_GROUP',
  assignGroup: 'ASSIGN_GROUP',
  deleteGroup: 'DELETE_GROUP',
};

export const useVisibleGroupModal = () => {
  const [visibleModal, setVisibleModal] = useState(null);

  const handler = useMemo(
    () => ({
      onGroupModal: (typeModal) => setVisibleModal(typeModal),
      offGroupModal: () => setVisibleModal(null),
    }),
    []
  );

  const visibleModalAddGroup = visibleModal === TYPE_GROUPS.addGroup;

  const visibleModalEditGroup = visibleModal === TYPE_GROUPS.editGroup;

  const visibleModalModificationGroup =
    visibleModalAddGroup || visibleModalEditGroup;

  const visibleModalPreviewGroup = visibleModal === TYPE_GROUPS.previewGroup;

  const visibleModalAssignGroup = visibleModal === TYPE_GROUPS.assignGroup;

  const visibleModalDeleteGroup = visibleModal === TYPE_GROUPS.deleteGroup;

  return {
    visibleModalAddGroup,
    visibleModalEditGroup,
    visibleModalModificationGroup,
    visibleModalPreviewGroup,
    visibleModalAssignGroup,
    visibleModalDeleteGroup,
    handler,
  };
};
