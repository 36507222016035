import * as api from 'config/axios';
import * as endpoints from './endpoints';

const getWfTemplateDetail = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_TEMPLATE_DETAIL, {
    params: params,
  });
};

const getWfStepInfo = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_STEP_INFO, {
    params: params,
  });
};

// Event Pipe
const addEventPipeToProgression = (params) => {
  return api.sendPost(endpoints.ADD_EVENT_PIPE_TO_PROGRESSION, params);
};

const getEntityProperties = (params) => {
  return api.sendGet(endpoints.GET_ENTITY_PROPERTIES, { params });
};

const addWfProgressionCondition = (params) => {
  return api.sendPost(endpoints.ADD_WORKFLOW_PROGRESSION_CONDITION, params);
};

const editEventPipeToProgression = (params) => {
  return api.sendPost(endpoints.EDIT_WORKFLOW_PROGRESSION_CONDITION, params);
};

const addWfProgressionAction = (params) => {
  return api.sendPost(endpoints.ADD_WORKFLOW_PROGRESSION_ACTION, params);
};

const getConditionCompareOperator = () => {
  return api.sendGet(endpoints.GET_CONDITION_COMPARE_OPERATOR);
};

const getWfTaskForCondition = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_TASK_FOR_CONDITION, { params });
};

const switchEventPipeCondition = (params) => {
  return api.sendPost(endpoints.SWITCH_EVENT_PIPE_CONDITION, params);
};

const removeWfProgressionCondition = (params) => {
  return api.sendPost(endpoints.REMOVE_WORKFLOW_PROGRESSION_CONDITION, params);
};

const removeWfProgressionAction = (params) => {
  return api.sendPost(endpoints.REMOVE_WORKFLOW_PROGRESSION_ACTION, params);
};

const getWfProgressionInfo = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_PROGRESSION_INFO, { params });
};

const deleteWfProgressionInfo = (params) => {
  return api.sendPost(endpoints.REMOVE_WORKFLOW_PROGRESSION_INFO, params);
};

const setFinalActionForProgression = (params) => {
  return api.sendPost(endpoints.SET_FINAL_ACTION, params);
};
//

const createWorkflowStepService = (params) => {
  return api.sendPost(endpoints.CREATE_WORKFLOW_STEP, params);
};

const createWorkflowTaskService = (params) => {
  return api.sendPost(endpoints.CREATE_WORKFLOW_TASK, params);
};

const editWorkflowStepService = (params) => {
  return api.sendPost(endpoints.EDIT_WORKFLOW_STEP, params);
};

const reorderWfTasks = (data) => {
  return api.sendPost(endpoints.REORDER_WORKFLOW_TASKS, data);
};

const getWfTemplateVersions = (data) => {
  return api.sendPost(endpoints.GET_WORKFLOW_TEMPLATE_VERSIONS, data);
};
const editWorkflowTemplateService = (params) => {
  return api.sendPost(endpoints.EDIT_WORKFLOW_TEMPLATE, params);
};

const getUserWorkflowRolesService = (params) => {
  return api.sendGet(endpoints.GET_USER_WORKFLOW_ROLES, {
    params: params,
  });
};

const getWorkflowTaskInfoService = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_TASK_INFO, {
    params: params,
  });
};
const editWorkflowTaskService = (params) => {
  return api.sendPost(endpoints.EDIT_WORKFLOW_TASK, params);
};

const getUsersToAssign = (params) => {
  return api.sendGet(endpoints.GET_USERS_TO_ASSIGN, { params: params });
};
const addWorkflowAction = (params) => {
  return api.sendPost(endpoints.ADD_WORKFLOW_ACTION, params);
};
const editWorkflowAction = (params) => {
  return api.sendPost(endpoints.EDIT_WORKFLOW_ACTION, params);
};
const cloneWorkFlow = (params) => {
  return api.sendPost(endpoints.CLONE_WORKFLOW, params);
};
const cloneWorkFlowStep = (params) => {
  return api.sendPost(endpoints.CLONE_WORKFLOW_STEP, params);
};
const cloneWorkFlowTask = (params) => {
  return api.sendPost(endpoints.CLONE_WORKFLOW_TASK, params);
};
const removeWorkflowAction = (params) => {
  return api.sendPost(endpoints.REMOVE_WORKFLOW_ACTION, params);
};

const getWorkflowMetricService = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_METRIC, {
    params: params,
  });
};
const getRequiredTaskService = (params) => {
  return api.sendGet(endpoints.GET_REQUIRED_TASK, {
    params: params,
  });
};
const getWorkflowStepMetricService = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_STEP_METRIC, {
    params: params,
  });
};
export {
  getWfTemplateDetail,
  getWfStepInfo,
  addEventPipeToProgression,
  getEntityProperties,
  addWfProgressionCondition,
  editEventPipeToProgression,
  addWfProgressionAction,
  getConditionCompareOperator,
  getWfTaskForCondition,
  switchEventPipeCondition,
  removeWfProgressionCondition,
  removeWfProgressionAction,
  getWfProgressionInfo,
  deleteWfProgressionInfo,
  createWorkflowStepService,
  reorderWfTasks,
  getWfTemplateVersions,
  editWorkflowStepService,
  editWorkflowTaskService,
  getWorkflowTaskInfoService,
  getUserWorkflowRolesService,
  editWorkflowTemplateService,
  getUsersToAssign,
  addWorkflowAction,
  editWorkflowAction,
  removeWorkflowAction,
  setFinalActionForProgression,
  createWorkflowTaskService,
  getWorkflowMetricService,
  getRequiredTaskService,
  getWorkflowStepMetricService,
  cloneWorkFlow,
  cloneWorkFlowStep,
  cloneWorkFlowTask,
};
