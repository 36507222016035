import { useDispatch } from 'react-redux';

import * as chatActions from '../controller/actions';

export const useChatDesctruction = () => {
  const dispatch = useDispatch();

  const onDesctruction = () => {
    dispatch(chatActions.getThreadInfoSuccess(null));
    dispatch(chatActions.getThreadMessagesSuccess([]));
    dispatch(chatActions.getCommunicationListSuccess(null));
    dispatch(chatActions.getCommunicationDetailSuccess(null));
  };

  return onDesctruction;
};
