import { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import { dialogFunction, CustomNotification } from 'common/components';

import TextRender from '../components/qa-grid-cell/TextRender';
import TextRenderSnapshot from '../components/qa-grid-cell/TextRenderSnapshot';

import {
  createTextEditorRenderer,
  createNumericEditorRenderer,
} from 'pages/qa-spec/utils/gridColumnUtils';
import { getAllRows } from 'pages/qa-spec/utils';
import { apiHandler } from 'utils/api';
import { sleep } from 'utils/delay';

import * as qaSpecServices from 'services/qaSpec';

import { useQaSpecGrid } from './useQaSpecGrid';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import { useGetQaSpecComponentQuery } from '../query';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/qa-spec';

import { COMPONENTS_ERROR } from 'pages/qa-spec/constant';

const FIELD_COMPONENTS_PART_OF = 'componentsPartOf';
const FIELD_COMPONENT = 'component';

export const useQaSpecComponents = (props) => {
  const intl = useIntl();
  const productClaimsRef = useRef();
  const gridInst = useRef();

  const { formInst, productId, isGridEditing } = props;

  const [saveLoading, setSaveLoading] = useState(false);

  const useSnapshot = useCheckSnapshotForRetailer();

  const { componentData, handleRefetchQaSpecComponent, isLoading, isFetching } =
    useGetQaSpecComponentQuery({
      productId,
      enabled: Number(productId) && !isGridEditing,
    });

  const fetchDataLoading = isLoading && isFetching;

  const {
    addGridRow,
    deleteSelectedGridRow,
    selectGridRow,
    setSelectedRow,
    rowData,
    selectedRow,
  } = useQaSpecGrid({
    gridInst,
    gridData: componentData?.components,
    isGridEditing,
  });

  const makeColumnDefs = useCallback(({ useSnapshot = false } = {}) => {
    const cellRenderer = useSnapshot
      ? {
          TextRender: 'TextRenderSnapshot',
          CountryEditorRender: 'CountryEditorRenderSnapshot',
        }
      : {
          TextRender: 'TextRender',
          CountryEditorRender: 'CountryEditorRender',
        };

    return [
      {
        field: 'error',
        displayName: ' ',
        minWidth: 50,
        maxWidth: 50,
        cellRenderer: 'StatusRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        minWidth: 150,
        headerName: 'Component',
        field: FIELD_COMPONENT,
        type: 'editableColumn',
        cellEditorSelector: createTextEditorRenderer(50),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Part Of',
        minWidth: 120,
        field: FIELD_COMPONENTS_PART_OF,
        type: 'editableColumn',
        cellEditorSelector: (params) => {
          const options = getOptionsComponentsPartOf({
            gridInst,
            params,
          });

          return {
            component: 'SelectionEditor',
            params: {
              values: options.map((optionItem) => ({
                value: optionItem?.value,
                displayName:
                  optionItem?.displayName === null
                    ? ''
                    : optionItem?.displayName,
              })),
              mapValue: (value) => {
                if (value === 'null' || value === undefined) {
                  return null;
                } else {
                  return value;
                }
              },
            },
            popup: true,
          };
        },
        cellRenderer: (params) => {
          const { context, rowIndex, column } = params;

          const options = getOptionsComponentsPartOf({
            gridInst,
            params,
          });

          const value =
            options?.find((option) => option?.value === params?.value)
              ?.displayName ?? '';

          const cellSnapshotValue =
            context?.snapshotGridValues?.[rowIndex]?.[column.colId] ?? 'N/A';

          const rawCellValue =
            params?.value?.split('.').slice(0, -1).join('.') ?? 'N/A';

          const isHighlightChange =
            !isEqual(`${cellSnapshotValue}`, `${rawCellValue}`) && useSnapshot;

          return useSnapshot ? (
            <TextRenderSnapshot
              {...params}
              value={value}
              isHighlightChange={isHighlightChange}
            />
          ) : (
            <TextRender value={value} />
          );
        },
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        minWidth: 200,
        headerName: 'CAS / INCI / HS Code',
        field: 'caS_INCI_HSCode',
        type: 'editableColumn',
        cellEditorSelector: createTextEditorRenderer(50),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        minWidth: 120,
        headerName: 'Function',
        field: 'componentsFunction',
        type: 'editableColumn',
        cellEditorSelector: createTextEditorRenderer(50),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        minWidth: 150,
        headerName: 'Manufacturer',
        field: 'manufacturer',
        type: 'editableColumn',
        cellEditorSelector: createTextEditorRenderer(50),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Country of Origin',
        minWidth: 180,
        field: 'componentsCountryOfOrigin',
        type: 'editableColumn',
        cellRenderer: cellRenderer['CountryEditorRender'],
        cellEditorSelector: () => {
          return {
            component: 'CountryEditor',
            popup: true,
          };
        },
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Quantity in %',
        minWidth: 150,
        field: 'quantityInPercent',
        type: 'editableColumn',
        cellEditorSelector: createTextEditorRenderer(100),
        cellRenderer: cellRenderer['TextRender'],
      },
      {
        allowFilter: true,
        allowSort: true,
        minWidth: 150,
        dataType: 'text',
        headerName: 'Total Weight',
        field: 'totalWeight',
        type: 'editableColumn',
        cellEditorSelector: createNumericEditorRenderer({
          min: 0,
        }),
        cellRenderer: cellRenderer['TextRender'],
      },
    ];
  }, []);

  const columnDefs = useMemo(() => {
    return makeColumnDefs({ useSnapshot });
  }, [makeColumnDefs, useSnapshot]);

  const onSave = async (toggleEditMode) => {
    gridInst.current.api.stopEditing();

    await sleep(100);

    callApiUpdateQaSpecComponents(toggleEditMode);

    //* clear selected row
    selectGridRow(null);
  };

  const onCancel = () => {
    gridInst.current.api.stopEditing();

    const transformRowData = transformQaComponentGrid(rowData);

    gridInst.current.api.setRowData(cloneDeep(transformRowData));

    setSelectedRow(null);
    formInst.setFieldsValue({ productClaims: productClaimsRef.current });
  };

  const onUpdateComponentsSuccess = (toggleEditMode) => () => {
    //* reload data
    handleRefetchQaSpecComponent();

    toggleEditMode();
  };

  const initData = () => {
    initFormValues(componentData);

    //* store productClams for reset value when canceling
    productClaimsRef.current = componentData?.productClaims;
  };

  const selectRow = () => {
    selectGridRow();
  };

  const deleteRow = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onDeleteData,
    });
  };

  const onDeleteData = () => {
    const selectedRow = gridInst.current.api.getSelectedNodes()?.[0];
    const allRowData = getAllRows(gridInst);

    const newRowData = getRowDataAfterDeletingRow({
      rowData: allRowData,
      deletingRow: selectedRow,
    });

    gridInst.current.api.applyTransaction({
      update: newRowData,
    });

    deleteSelectedGridRow();
  };

  const onSaveFinally = () => {
    setSaveLoading(false);
  };

  const onCellEditingStopped = (params) => {
    updateRowError(params);
  };

  const validateEmptyComponent = (error, data) => {
    if (!data.component) error.push(COMPONENTS_ERROR.EMPTY);
  };

  const validateExistedComponent = (error, data) => {
    const allRowsData = getAllRows(gridInst);
    const parentRows = allRowsData.filter((row) => !row.componentsPartOf); // don't validate child row

    const isExisted = parentRows.some((row) => {
      return row.uuid !== data.uuid && row.component === data.component;
    });

    if (isExisted) error.push(COMPONENTS_ERROR.EXISTED);
  };

  const validateGridError = () => {
    const allRowsData = getAllRows(gridInst);

    const hasError = allRowsData.some((row) => row.error?.length > 0);

    return hasError;
  };

  const updateRowError = (params = {}) => {
    const {
      column: { colId },
    } = params;

    const allRowsData = getAllRows(gridInst);
    let newRowsData = allRowsData;

    if (colId === FIELD_COMPONENT) {
      // update children of row
      newRowsData = getRowDataAfterChangingComponentField({
        rowData: newRowsData,
        changingRow: params?.data,
      });
    } else if (colId === FIELD_COMPONENTS_PART_OF) {
      newRowsData = getRowDataAfterChangingPartsOfField({
        rowData: newRowsData,
        changingRow: params?.data,
      });
    }

    // validate error
    newRowsData = newRowsData.map((row) => {
      const error = [];

      validateEmptyComponent(error, row);

      // don't need to check on parent row
      if (!row?.componentsPartOf) {
        validateExistedComponent(error, row);
      }

      return { ...row, error: error };
    });

    gridInst.current.api.applyTransaction({
      update: newRowsData,
    });
  };

  const addRow = async () => {
    gridInst.current.api.stopEditing();
    await sleep();

    addGridRow({
      component: null,
      componentsPartOf: null,
      componentsPartOfId: null,
      caS_INCI_HSCode: null,
      componentsFunction: null,
      manufacturer: null,
      componentsCountryOfOrigin: null,
      quantityInPercent: null,
      totalWeight: null,
      error: [COMPONENTS_ERROR.EMPTY],
    });
  };

  const initFormValues = (data) => {
    formInst.setFieldsValue({
      productClaims: data?.productClaims,
    });
  };

  const callApiUpdateQaSpecComponents = (toggleEditMode) => {
    const allRowData = getAllRows(gridInst);

    const hasGridError = validateGridError();

    if (hasGridError) {
      CustomNotification.error(`Please resolve Ingredient grid's errors`);
      return;
    }

    const formValues = formInst.getFieldsValue();

    const params = {
      productId,
      components: allRowData.map((row) => {
        if (row?.componentsPartOf) {
          // componentsPartOf = 'componentValueOfParent.componentsPartOfIdOfParent' -> Improve displayname for the BlueProperties module
          const componentsPartOf = row?.componentsPartOf?.split('.')?.[0];
          return {
            ...row,
            id: row?.uuid,
            componentsPartOf,
          };
        }
        return {
          ...row,
          id: row?.uuid,
        };
      }),
      productClaims: formValues.productClaims,
    };

    setSaveLoading(true);

    const successMessage = intl.formatMessage(
      Messages.qaSpecUpdateComponentsSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.qaSpecUpdateComponentsError
    );

    apiHandler({
      service: qaSpecServices.saveQaSpecComponents,
      params,
      successMessage,
      errorMessage,
      successCallback: onUpdateComponentsSuccess(toggleEditMode),
      onFinally: onSaveFinally,
    });
  };

  useEffect(() => {
    initData();
  }, [JSON.stringify(componentData)]);

  return {
    gridInst,
    rowData,
    columnDefs,
    isLoading: fetchDataLoading || saveLoading,
    selectedRow,
    selectRow,
    addRow,
    deleteRow,
    onSave,
    onCancel,
    onCellEditingStopped,
  };
};

export const getRowDataAfterChangingComponentField = ({
  rowData = [],
  changingRow,
}) => {
  let result = [];

  rowData.forEach((row) => {
    if (row?.componentsPartOf) {
      const [, parentUuid] = row.componentsPartOf.split('.');

      if (parentUuid === changingRow?.uuid) {
        const newRow = {
          ...row,
          componentsPartOf: changingRow?.component
            ? transformValueComponentsPartOf(changingRow)
            : null,
        };
        result.push(newRow);
      } else {
        result.push(row);
      }
    } else {
      result.push(row);
    }
  });

  return result;
};

export const transformQaComponentGrid = (rowData) => {
  return rowData.map((row) => {
    if (row?.componentsPartOf) {
      return {
        ...row,
        componentsPartOf: `${row.componentsPartOf}.${row.componentsPartOfId}`,
      };
    }
    return row;
  });
};

export const getRowDataAfterChangingPartsOfField = ({
  rowData,
  changingRow,
}) => {
  let result = [];

  rowData.forEach((row) => {
    if (row.uuid === changingRow.uuid) {
      const parentUuid = row?.componentsPartOf?.split('.')?.[1];
      const newRow = {
        ...row,
        componentsPartOfId: changingRow?.componentsPartOf ? parentUuid : null,
      };
      result.push(newRow);
    } else {
      result.push(row);
    }
  });

  return result;
};

export const transformValueComponentsPartOf = (row) =>
  `${row?.component}.${row?.uuid}`;

export const getOptionsComponentsPartOf = ({ gridInst, params }) => {
  const allRowData = getAllRows(gridInst);

  const options = allRowData
    ?.map((itemData, index) => ({
      uuid: itemData?.uuid,
      value: transformValueComponentsPartOf(itemData),
      component: itemData?.component,
      displayName: `${itemData?.component} (row ${index + 1})`,
    }))
    .filter(
      (optionItem) =>
        optionItem?.component && optionItem?.uuid !== params?.data?.uuid
    );

  return options;
};

export const getRowDataAfterDeletingRow = ({ rowData, deletingRow }) => {
  let result = [];

  rowData.forEach((row) => {
    if (row?.componentsPartOf) {
      const [, parentUuid] = row.componentsPartOf.split('.');

      if (parentUuid === deletingRow?.data?.uuid) {
        const newRow = {
          ...row,
          componentsPartOf: null,
        };
        result.push(newRow);
      }
    } else {
      result.push(row);
    }
  });

  return result;
};
