import * as api from 'config/axios';
import * as endpoints from './endpoints';

import { getAnonToken } from 'utils/common/session';

export const rejectVideoMeeting = (params) => {
  //*	Input: threadId, senderId
  //* output
  //? bool, it is always true if no technical error

  const token = getAnonToken();
  if (!token) return;

  return api.sendPost(`${endpoints.ANON_REJECT_CALL}/${token}`, params);
};

export const acceptVideoMeeting = (params) => {
  //* Input: threadId, senderId
  //* output
  //todo - {roomId, jitsi url}

  const token = getAnonToken();
  if (!token) return;

  return api.sendPost(`${endpoints.ANON_ACCEPT_CALL}/${token}`, params);
};

export const endVideoMeeting = (params) => {
  //* Input: threadId
  //* output

  const token = getAnonToken();
  if (!token) return;

  return api.sendPost(`${endpoints.ANON_END_CALL}/${token}`, params);
};
