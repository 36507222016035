import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Typography } from 'antd';
import { RoleSectionWrapper, RoleEmptySection } from '../';

import './styles.less';

const { Text } = Typography;

const RoleName = (props) => {
  const { title, selectedRole, formRole, validateFormRole } = props;

  return (
    <RoleSectionWrapper
      className='role-name-section__wrap'
      title={title}
      style={{ height: '100%' }}
    >
      {selectedRole ? (
        <div className='role-name-section'>
          <Input
            name='displayName'
            value={formRole?.values?.displayName}
            style={{ background: '#E9E9E0' }}
            onChange={formRole?.onChange}
            onBlur={validateFormRole}
            placeholder='input the role name'
          />
          <Text
            type='danger'
            style={{ display: 'block', position: 'absolute' }}
          >
            {formRole?.errors?.displayName?.[0]}
          </Text>
        </div>
      ) : (
        <RoleEmptySection />
      )}
    </RoleSectionWrapper>
  );
};

export default RoleName;
