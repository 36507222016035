import React from 'react';

import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { DeleteOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction, CustomNotification } from 'common/components';

import * as productActions from 'pages/product-full-view/controllers/actions';
import * as productSelectors from 'pages/product-full-view/controllers/selectors';

import { useProductHierarchies, useProductFullView } from 'hooks';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';

const DeleteHierarchy = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { id: productId } = useParams();

  const { selectedHierarchy, sectionDisabled } = props;

  const currentHierarchyShortDetails = useSelector(
    productSelectors.makeSelectCurrentHierarchyShortDetails()
  );

  const { productFull } = useProductFullView({ productId });
  const { deleteHierarchiesMutation } = useProductHierarchies({
    productId: productFull?.productId,
  });

  const loading = deleteHierarchiesMutation.isLoading;

  const showDeleteConfirmDialog = () => {
    dialogFunction({
      type: 'warn',
      okButtonProps: {
        loading: loading,
      },
      content: intl.formatMessage(Messages.deleteHierarchyMessage),
      onOk: callApiDeleteHierarchy,
    });
  };

  const callApiDeleteHierarchy = () => {
    const successMessage = intl.formatMessage(Messages.deleteHierarchySuccess);
    const errorMessage = intl.formatMessage(Messages.deleteHierarchyError);

    const params = {
      hierarchyId: selectedHierarchy?.productHierarchyId,
    };

    deleteHierarchiesMutation.mutate(params, {
      onSuccess: ({ isSuccess }) => {
        if (!isSuccess) CustomNotification.error(errorMessage);

        CustomNotification.success(successMessage);
        dispatch(productActions.updateSelectedHierarchy(null));
        dispatch(productActions.updateHierarchyEndpoint(null));
        //* reload hierarchies in drawer
        dispatch(
          productActions.getProductHierarchyShortDetails({
            hierarchyId: currentHierarchyShortDetails?.data?.hierarchyId,
          })
        );

        //* reload hierarchy dropdown in product header
        dispatch(
          productActions.getProductHierarchyShortDetail(productFull?.productId)
        );
      },
      onError: () => {
        CustomNotification.error(errorMessage);
      },
    });
  };

  const onClickHandler = () => {
    showDeleteConfirmDialog();
  };

  const isDisabled = !selectedHierarchy || sectionDisabled;

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<DeleteOutlined style={{ height: 26 }} />}
        label={Messages.deleteHierarchy}
        onClick={onClickHandler}
        disabled={isDisabled}
      />
    </>
  );
};

export default DeleteHierarchy;
