import React, { useState, useRef, useEffect } from "react";

const useForm = () => {
  const [formValue, setFormValue] = useState({});

  const refForm = useRef();

  const setFieldsValue = (valueObject) => {
    setFormValue((prevValue) => ({ ...prevValue, ...valueObject }));
  };

  const resetFieldsValue = () => {
    setFormValue({});
  };

  const getFieldsValue = () => {
    return formValue;
  };

  return [{ setFieldsValue, resetFieldsValue, getFieldsValue }];
};

export default useForm;
