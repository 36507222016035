import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';

import {
  RibbonButton,
  dialogFunction,
  FullWidthSpace,
} from 'common/components';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import { apiHandler } from 'utils/api';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import * as memberMaintenanceServices from 'services/memberMaintenance';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/maintenance';

const { Paragraph, Text } = Typography;

const UnlockUser = () => {
  const intl = useIntl();

  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const reloadPage = useDispatchReloadPage();

  const getLockedUsers = () => {
    if (!selectedItemDetailList.length) return [];

    return selectedItemDetailList.filter(
      (user) => user?.status?.toLowerCase() === 'locked'
    );
  };

  const checkDisabledBtn = () => {
    if (selectedItemDetailList.length === 0) return true;

    if (!lockedUsers.length) return true;

    return false;
  };

  const getLockedUserIds = () => {
    return lockedUsers.map((user) => user.id);
  };

  const showConfirmMessage = () => {
    dialogFunction({
      type: 'warn',
      content: (
        <>
          <Paragraph>
            {intl.formatMessage(Messages.unlockUserConfirmMessage)}
          </Paragraph>
          <FullWidthSpace>
            {lockedUsers.map((user) => {
              return <Text strong>{user?.userName}</Text>;
            })}
          </FullWidthSpace>
        </>
      ),
      width: 450,
      onOk: callApiUnlockedUsers,
    });
  };

  const callApiUnlockedUsers = () => {
    const successMessage = intl.formatMessage(Messages.unlockUserSuccess);
    const errorMessage = intl.formatMessage(Messages.unlockUserError);

    const params = {
      userIds: getLockedUserIds(),
    };

    apiHandler({
      service: memberMaintenanceServices.unlockUsers,
      params,
      successMessage,
      errorMessage,
      successCallback: onUnlockUsersSuccess,
    });
  };

  const onClick = () => {
    showConfirmMessage();
  };

  const onUnlockUsersSuccess = () => {
    reloadPage();
  };

  const lockedUsers = getLockedUsers();
  const disabled = checkDisabledBtn();

  return (
    <RibbonButton
      icon={<UnlockOutlined style={{ height: 26 }} />}
      label={Messages.unlockUser}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default UnlockUser;
