import React from 'react';
import Messages from 'i18n/messages/maintenance';
import { injectIntl } from 'react-intl';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const EditMode = ({ intl, enableModeEdit, disabled }) => {
  const label = {
    id: 'Taco.maintenance.help.ribbon.editPost',
    defaultMessage: intl.formatMessage(Messages.editPost),
  };

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={label}
      onClick={enableModeEdit}
      disabled={disabled}
    />
  );
};

export default injectIntl(EditMode);
