import React, { useEffect, useState } from 'react';

import { Row, Col, Radio, Image } from 'antd';

import {
  PictureOutlined,
  HighlightOutlined,
  FontSizeOutlined,
  FileTextOutlined,
  CloudUploadOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';

import {
  PageEditorMedia,
  PageEditorDragTextEdit,
  PageEditorDragImageEdit,
  PageEditorBackgroundEdit,
  PageEditorDragYoutubeEdit,
  PageEditorToolBtn,
} from '.';
import { WithAbsoluteContainer } from 'common/components';

import useKeyDown from 'hooks/useKeyDown';

import { mapToComponentByKey } from '../../mappers';

import {
  useGetImageHandlers,
  useGetTextHandlers,
  useGetBackgroundHandlers,
  useGetYoutubeHandlers,
} from '../../hooks/pageEditorToolHooks';

import {
  COMPONENT_TYPE,
  EDIT_TYPE,
  BACKGROUND_CONFIG_DEFAULT,
  TOOL_TAIL_ID,
} from '../../constants';
import { EVENT } from 'static/Constants';
import DefaultImage from 'assets/asset-icons/image-thumbnail.jpg';

import classnames from 'classnames';
import { sleep } from 'utils/delay';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import './styles.less';

const slideInLeft = classnames(
  'ani--slideInRight',
  'ani--fill-forward',
  'ani--iter-1',
  'ani--time-70',
  'ani--easy'
);

const PageEditorTool = (props) => {
  const {
    wrapId,
    selectedHook,
    editTypeHook,
    wrapKey,
    changeFileTargetHook,
    pageData,
    detailShowTargetHook,
    componentHandlers,
    isDragging,
    isHaveBackground,
    scale,
  } = props;

  const [editType, setEditType] = editTypeHook;
  const [changeFileTarget, setChangeFileTarget] = changeFileTargetHook;
  const [selected, setSelected, clearSelect] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const [loadingMedia, setLoadingMedia] = useState(true);

  const { onChangeComponentValue, onCreateNewComponent } = componentHandlers;

  const imageHandlers = useGetImageHandlers(
    componentHandlers,
    detailShowTargetHook,
    editTypeHook,
    changeFileTargetHook
  );

  const youtubeHandlers = useGetYoutubeHandlers(
    componentHandlers,
    detailShowTargetHook,
    editTypeHook,
    changeFileTargetHook
  );

  const textHandlers = useGetTextHandlers(
    componentHandlers,
    detailShowTargetHook
  );

  const backgroundKey = `${wrapKey}_background`;

  const backgroundHandlers = useGetBackgroundHandlers(
    backgroundKey,
    componentHandlers,
    detailShowTargetHook,
    editTypeHook,
    changeFileTargetHook
  );

  const [markupBackgroundEditKeyAllowance] = useKeyDown([
    { key: 37, func: backgroundHandlers.moveLeft },
    { key: 39, func: backgroundHandlers.moveRight },
    { key: 38, func: backgroundHandlers.moveUp },
    { key: 40, func: backgroundHandlers.moveDown },
    { key: 189, func: backgroundHandlers.zoomOut },
    { key: 187, func: backgroundHandlers.zoomIn },
  ]);

  const handleChangeEditType = (event) => {
    const editTypeValue = event.target.value;
    setEditType(editTypeValue);
    setDetailShowTarget(null);

    //* since background is only one target with its specific key
    if (editTypeValue === EDIT_TYPE.BACKGROUND) {
      setDetailShowTarget(backgroundKey);
      clearSelect();
    }
  };

  const saveTransitionPage = () => {
    setEditType(EDIT_TYPE.FORM);
    setDetailShowTarget(null);
  };

  const handleSelectFile = (fileInfo) => {
    if (!changeFileTarget) return;

    const isChangingBackgroundImage =
      changeFileTarget.split('_').pop() === 'background';

    if (isChangingBackgroundImage) {
      isHaveBackground
        ? onChangeComponentValue({
            key: changeFileTarget,
            value: { ...BACKGROUND_CONFIG_DEFAULT, src: fileInfo?.url },
          })
        : onCreateNewComponent({
            componentType: COMPONENT_TYPE.BACKGROUND,
            values: { src: fileInfo?.url },
          });
    } else {
      onChangeComponentValue({
        key: changeFileTarget,
        fieldName: 'src',
        value: fileInfo?.url,
      });
    }
  };

  const closeToolDraw = () => {
    setEditType(EDIT_TYPE.DEFAULT);
    setChangeFileTarget(null);
    setDetailShowTarget(null);
  };

  useEffect(() => {
    const setKeyAllowance = (key, refAllow, config = {}) => {
      const { disabled } = config;
      if (selected?.id === key) {
        if (disabled) {
          refAllow.current.set(false);
          return;
        }
        refAllow.current.set(true);
      } else {
        refAllow.current.set(false);
      }
    };

    //* handle map key to relative section
    const setKeyboardEditAgainstSection = () => {
      const backgroundKey = `${wrapKey}_background`;

      setKeyAllowance(backgroundKey, markupBackgroundEditKeyAllowance);
    };

    setKeyboardEditAgainstSection();
  }, [editType, selected, wrapKey, pageData?.componentDetails]);

  useEffect(() => {
    //* toggle loading media
    const toggleLoadingMediaLoading = async () => {
      if (editType === EDIT_TYPE.UPLOAD) {
        setLoadingMedia(true);
        await sleep(800);
        setLoadingMedia(false);
      }
    };

    toggleLoadingMediaLoading();
  }, [editType]);

  useDocumentAttachEvent({
    name: EVENT.SAVE_TRANSITION_PAGE,
    handler: saveTransitionPage,
  });

  const showDetailComponentData = mapToComponentByKey(
    pageData,
    detailShowTarget
  );

  //* render
  const toolList = [
    {
      component: (
        <PageEditorBackgroundEdit
          backgroundHandlers={backgroundHandlers}
          selectedHook={selectedHook}
          closeToolDraw={closeToolDraw}
          componentKey={backgroundKey}
        />
      ),
      editType: EDIT_TYPE.BACKGROUND,
    },
    {
      component: (
        <PageEditorDragImageEdit
          wrapId={wrapId}
          wrapKey={wrapKey}
          imageHandlers={imageHandlers}
          selectedHook={selectedHook}
          closeToolDraw={closeToolDraw}
          showDetailComponentData={showDetailComponentData}
          detailShowTargetHook={detailShowTargetHook}
          componentHandlers={componentHandlers}
          scale={scale}
        />
      ),
      editType: EDIT_TYPE.IMG,
    },
    {
      component: (
        <PageEditorDragTextEdit
          wrapId={wrapId}
          wrapKey={wrapKey}
          textHandlers={textHandlers}
          selectedHook={selectedHook}
          closeToolDraw={closeToolDraw}
          showDetailComponentData={showDetailComponentData}
          detailShowTargetHook={detailShowTargetHook}
          componentHandlers={componentHandlers}
          scale={scale}
        />
      ),
      editType: EDIT_TYPE.TEXT,
    },
    {
      component: (
        <PageEditorDragYoutubeEdit
          wrapId={wrapId}
          wrapKey={wrapKey}
          youtubeHandlers={youtubeHandlers}
          selectedHook={selectedHook}
          closeToolDraw={closeToolDraw}
          showDetailComponentData={showDetailComponentData}
          detailShowTargetHook={detailShowTargetHook}
          componentHandlers={componentHandlers}
          scale={scale}
        />
      ),
      editType: EDIT_TYPE.YOUTUBE,
    },
    {
      component: (
        <PageEditorMedia
          closeToolDraw={closeToolDraw}
          handleSelect={handleSelectFile}
          changeFileTarget={changeFileTarget}
          loading={loadingMedia}
        />
      ),
      editType: EDIT_TYPE.UPLOAD,
    },
    {
      component: null,
      editType: EDIT_TYPE.FORM,
    },
  ];

  const pageEditorToolKey = `${wrapKey}_${TOOL_TAIL_ID}`;

  const isHideToolBar = isDragging;

  return (
    <Row className='page-editor-tool'>
      <Col
        id={pageEditorToolKey}
        style={{ width: '100%', height: 40, position: 'absolute' }}
      />
      <Col style={{ opacity: isHideToolBar ? 0 : 1, transition: '0.5s' }}>
        {toolList.map((tool) =>
          tool?.editType === editType ? (
            <div className={slideInLeft} key={tool?.editType}>
              {tool?.component}
            </div>
          ) : null
        )}
      </Col>
      <Col>
        <Radio.Group
          className='page-editor-tool__ribbon'
          value={editType}
          onChange={handleChangeEditType}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <PageEditorToolBtn
            value={EDIT_TYPE.BACKGROUND}
            tooltip='Edit background'
            icon={<PictureOutlined />}
          />
          <PageEditorToolBtn
            value={EDIT_TYPE.IMG}
            tooltip='Edit Image'
            icon={<HighlightOutlined />}
          />
          <PageEditorToolBtn
            value={EDIT_TYPE.TEXT}
            tooltip='Edit text'
            icon={<FontSizeOutlined />}
          />
          <PageEditorToolBtn
            value={EDIT_TYPE.YOUTUBE}
            tooltip='Edit youtube'
            icon={<YoutubeOutlined />}
          />
          <PageEditorToolBtn
            value={EDIT_TYPE.FORM}
            tooltip='Page Editor Form'
            icon={<FileTextOutlined />}
          />
          <PageEditorToolBtn
            value={EDIT_TYPE.UPLOAD}
            tooltip='Media assets'
            icon={<CloudUploadOutlined />}
          />
        </Radio.Group>
      </Col>
      <WithAbsoluteContainer>
        <Image style={{ width: 0, height: 0 }} src={DefaultImage} />
      </WithAbsoluteContainer>
    </Row>
  );
};

export default PageEditorTool;
