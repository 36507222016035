import React from 'react';
import { useDispatch } from 'react-redux';

import { FolderAddOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import * as maintenanceActions from 'pages/maintenance/controllers/actions';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const CreatePost = () => {
  const dispatch = useDispatch();

  const handleCreatePost = () => {
    dispatch(maintenanceActions.enableCreatePost());
    forwardTo(`/maintenance/help/post/create`);
  };

  return (
    <RibbonButton
      size='small'
      icon={<FolderAddOutlined />}
      label={Messages.createPost}
      onClick={handleCreatePost}
    />
  );
};

export default CreatePost;
