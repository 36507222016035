import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Col, Row, Modal, notification } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import NewUserControl from '../../controls/maintenance/member/NewUserControl';
import DeleteUserControl from '../../controls/maintenance/member/DeleteUserControl';
import ResetPasswordControl from '../../controls/maintenance/member/ResetPasswordControl';
import EditUserControl from '../../controls/maintenance/member/EditUserControl';
import { InfoWithLabel, RibbonSection } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';
import { FormattedMessage } from 'react-intl';

import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';
import * as memberMaintenanceActions from 'pages/member-maintenance/controllers/actions';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import * as memberMaintenanceServices from 'services/memberMaintenance';
import * as globalActions from 'redux/global/actions';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import Messages from 'i18n/messages/maintenance';

import dialogFunction from 'common/components/dialog-function/DialogFunction';

const UserActionsSection = (props) => {
  const dispatch = useDispatch();
  const selectedUsers = useSelector(
    gridViewSelectors.makeSelectItemsSelection()
  );
  const { isUsersManagement } = props;
  const reloadPage = useDispatchReloadPage();

  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );

  const onAddUserClick = () => {
    dispatch(memberMaintenanceActions.toggleAddNewUser(true));
  };

  const onEditUserClick = () => {
    dispatch(memberMaintenanceActions.toggleEditUser(true));
  };

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleDeleteUsers,
    });
  };

  const handleDeleteUsers = async () => {
    if (selectedUsers.length > 0) {
      const res = await memberMaintenanceServices.deleteUsers({
        listIds: selectedUsers,
      });
      if (res?.isSuccess) {
        notification.success({
          message: 'Deleted users successfully!',
          top: 50,
        });
        reloadPage();

        setTimeout(() => {
          dispatch(globalActions.updateReloadPage(false));
        }, 100);
      } else {
        notification.error({
          message: 'Failed to delete users!',
          top: 50,
        });
      }
    } else {
      notification.error({
        message: 'Should choose users to delete!',
        top: 50,
      });
    }
  };

  const handleResetPassword = async () => {
    const response = await memberMaintenanceServices.resetUserPassword({
      userId: selectedUsers[0],
    });
    if (response?.isSuccess) {
      Modal.success({
        title: 'Reset Password Successfully',
        content: (
          <Row>
            <Col xs={24}>
              {mapResetPasswordToCol(response?.data).map((item) => (
                <InfoWithLabel
                  info={item?.info}
                  label={item?.label}
                  infoColProps={{
                    flex: '250px',
                  }}
                  infoTextStyle={{
                    maxWidth: '215px',
                  }}
                  labelColProps={{
                    flex: '120px',
                  }}
                  boldInfo={true}
                  labelAlign='right'
                />
              ))}
            </Col>
          </Row>
        ),
      });
    }
  };

  const mapResetPasswordToCol = (data) => {
    return [
      {
        label: 'User Name',
        info: data?.userName,
      },
      {
        label: 'Email',
        info: data?.email,
      },
      {
        label: 'New Password',
        info: data?.newPassword,
      },
    ];
  };

  const onConfirmResetPassword = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to reset password?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleResetPassword,
    });
  };

  const EditUserButton = RibbonButton;

  return (
    <RibbonSection>
      {isUsersManagement ? (
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.USER}>
          <EditUserButton
            disabled={selectedUsers.length !== 1}
            onClick={onEditUserClick}
            icon={<FormOutlined />}
            label={<FormattedMessage {...Messages.editUser} />}
          />
        </Can>
      ) : null}

      {!isUsersManagement ? (
        <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.USER}>
          <NewUserControl isSmallBtn onClick={onAddUserClick} />
        </Can>
      ) : null}

      <Can I={ABILITY_ACTION.DELETE} a={ABILITY_SUBJECT.USER}>
        <DeleteUserControl
          isSmallBtn
          onClick={confirmDelete}
          disabled={selectedUsers.length <= 0}
        />
      </Can>
      {!isUsersManagement ? (
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.USER}>
          <EditUserControl
            isSmallBtn
            disabled={selectedUsers.length !== 1}
            onClick={onEditUserClick}
          />
        </Can>
      ) : null}
      <ResetPasswordControl
        isSmallBtn
        disabled={selectedUsers.length !== 1}
        onClick={onConfirmResetPassword}
      />
    </RibbonSection>
  );
};

export default UserActionsSection;
