export const getCertificateParams = (formValues) => {
  const disabledCertifier = {
    kosherCertifier: 'kosherYN',
    organicCertifier: 'organicYN',
    halalCertifier: 'halalYN',
    nonGMOCertifier: 'nonGMOYN',
    isoCertifier: 'isoyn',
    brcCertifier: 'brcyn',
    ifsCertifier: 'ifsyn',
  };
  return Object.keys(disabledCertifier).reduce((accumulator, currentKey) => {
    const checkValue = formValues[disabledCertifier[currentKey]];
    const value = formValues[currentKey];

    return { ...accumulator, [currentKey]: checkValue ? value : null };
  }, {});
};

export const parsePackagingData = (data) => {
  if (data) {
    return {
      ...data,
      recyclableTypes: data?.recyclableTypes || [],
      secondaryLanguage: data?.secondaryLanguage || [],
    };
  }

  return {};
};
