import { useState, useRef } from 'react';

import { CustomNotification, dialogFunction } from 'common/components';

import AddToFolderConfirm from 'common/components/grid-view/components/add-to-folder/AddToFolderConfirm';

import { addEntityToFolder } from 'services/folder';
import { sleep } from 'utils/delay';

export const useAddToFolder = (props) => {
  const folderSelectorRef = useRef();
  const confirmRef = useRef();

  const { close, shouldShowConfirm = true } = props;

  const [addToFolderLoading, setAddToFolderLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [currentFolderId, setCurrentFolderId] = useState();

  const onSelectFolder = (selectedFolder) => {
    setSelectedFolder(selectedFolder);
  };

  const onBreadcrumbChange = (folderId, _, breadcrumbSelected) => {
    setCurrentFolderId(folderId);
    setSelectedFolder(breadcrumbSelected);
  };

  const onExpandFolder = async (folder) => {
    await sleep(300);
    setCurrentFolderId(folder?.id);
    setSelectedFolder(folder);
  };

  const handleSubmit = (itemsAdded, folder) => {

    shouldShowConfirm
      ? showConfirm(itemsAdded, folder)
      : handleAddToFolder(itemsAdded, folder);
  };

  const showConfirm = (itemsAdded, folder) => {
    dialogFunction({
      type: 'info',
      content: (
        <AddToFolderConfirm
          listData={itemsAdded}
          folder={folder || selectedFolder}
          ref={confirmRef}
          handleAddToFolder={handleAddToFolder}
        />
      ),
      onOk: () => confirmRef?.current?.triggleAddToFolder(),
    });
  };

  const handleAddToFolder = async (itemList, folder) => {
    if (folder?.id === 'home') {
      CustomNotification.error('Cannot add to folder');
      return;
    }

    let params = {
      folderId: folder?.id || selectedFolder?.Id || currentFolderId,
      itemList: itemList.map((item) => ({
        id: item?.id,
        type: item?.type,
      })),
    };
    try {
      setAddToFolderLoading(true);

      const { isSuccess, message } = await addEntityToFolder(params);
      if (isSuccess) {
        close && close();
        CustomNotification.success(`Add to Folder successfully`);
      } else {
        CustomNotification.error(message ?? 'Cannot add to folder');
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Cannot add to folder');
    }

    setAddToFolderLoading(false);
  };

  const handleReloadFolderList = (folderId) => {
    folderSelectorRef.current.reloadList(folderId || currentFolderId);
  };

  return {
    folderSelectorRef,
    onSelectFolder,
    onBreadcrumbChange,
    onExpandFolder,
    handleSubmit,
    handleReloadFolderList,
    handleAddToFolder,
    selectedFolder,
    currentFolderId,
    addToFolderLoading,
  };
};
