import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import produce from 'immer';

import { Row } from 'antd';
import { SendOutlined } from '@ant-design/icons';

import { RibbonButton, StyledModal } from 'common/components';
import UpcProducts from './UpcProducts';
import Invite from './Invite';

import Messages from 'i18n/messages/apl';
import * as aplSelectors from 'pages/product-apl-detail/controllers/selectors';

import './SendMeetingInvite.less';

const SendMeetingInvite = () => {
  const [visible, setVisible] = useState(false);

  const productList = useSelector(
    aplSelectors.makeSelectAplProductDetailListSelect()
  );

  return (
    <>
      <RibbonButton
        size='small'
        icon={<SendOutlined />}
        label={Messages.sendMeetingInvite}
        onClick={() => setVisible(true)}
        disabled={productList?.length === 0}
        className='button-item-dropdown'
      />
      {visible && (
        <ModalMeetingInvite
          visible={visible}
          onCancel={() => setVisible(false)}
          productList={productList}
        />
      )}
    </>
  );
};

const ModalMeetingInvite = ({ visible, onCancel, productList }) => {
  const [idSelectedProduct, setIdSelectedProduct] = useState(null);
  const [sentProducts, setSentProducts] = useState([]);
  const [productListState, setProductListState] = useState(productList);
  const [updatedEmails, setUpdatedEmails] = useState([]);

  const [cachedForm, setCachedForm] = useState({});

  useEffect(() => {
    if (productList.length > 0) {
      setIdSelectedProduct(productList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(productList)]);

  const handleSelectProduct = (event) =>
    setIdSelectedProduct(parseInt(event.key));

  const handleUpdateSendProducts = (product) => {
    setSentProducts((prevProduct) => [...prevProduct, product]);
  };

  const handleUpdateProductList = (email) => {
    const foundIndex = productListState.findIndex(
      (product) => product.id === idSelectedProduct
    );

    const newProductList = getNewProductList(
      productListState,
      foundIndex,
      email
    );
    setProductListState(newProductList);
  };

  const handleSaveUpdatedEmail = (email) => {
    const newEmails = getNewUpdatedEmails(
      updatedEmails,
      email,
      idSelectedProduct
    );
    setUpdatedEmails(newEmails);
  };

  const addedEmail = updatedEmails.find(
    (product) => product.id === idSelectedProduct
  );

  const selectedProduct = productListState.find(
    (product) => product.id === idSelectedProduct
  );

  return (
    <StyledModal
      title='Meeting Invitation'
      width='1000px'
      visible={visible}
      onCancel={onCancel}
      bodyStyle={{ height: '70vh' }}
      className='meeting-invitation-modal'
      maskClosable={false}
      footer={null}
    >
      <Row gutter={8} style={{ height: '100%' }}>
        <UpcProducts
          idSelectedProduct={idSelectedProduct}
          productList={productListState}
          sentProducts={sentProducts}
          onSelectProduct={handleSelectProduct}
          selectedProduct={selectedProduct}
        />

        <Invite
          key={selectedProduct?.id}
          selectedProduct={selectedProduct}
          sentProducts={sentProducts}
          addedEmail={addedEmail}
          cachedForm={cachedForm}
          setCachedForm={setCachedForm}
          onUpdateSentProducts={handleUpdateSendProducts}
          onSaveUpdatedEmail={handleSaveUpdatedEmail}
          onUpdateProductList={handleUpdateProductList}
        />
      </Row>
    </StyledModal>
  );
};

const getNewProductList = (data, index, value) => {
  const result = produce(data, (draftFile) => {
    draftFile[index].contactEmail = value;
  });

  return result;
};

const getNewUpdatedEmails = (listEmails, email, id) => {
  const foundIndex = listEmails.findIndex((item) => item.id === id);

  if (foundIndex !== -1) {
    const result = produce(listEmails, (draftFile) => {
      draftFile[foundIndex].email = email;
    });
    return result;
  }

  return [...listEmails, { id, email, isDraft: true }];
};

export default SendMeetingInvite;
