export const GET_TRANSITION_PAGE_DETAIL = 'GET_TRANSITION_PAGE_DETAIL';
export const GET_TRANSITION_PAGE_DETAIL_SUCCESS =
  'GET_TRANSITION_PAGE_DETAIL_SUCCESS';
export const GET_TRANSITION_PAGE_DETAIL_FAIL =
  'GET_TRANSITION_PAGE_DETAIL_FAIL';

export const SAVE_TRANSITION_PAGE = 'SAVE_TRANSITION_PAGE';
export const SAVE_TRANSITION_PAGE_SUCCESS = 'SAVE_TRANSITION_PAGE_SUCCESS';
export const SAVE_TRANSITION_PAGE_FAIL = 'SAVE_TRANSITION_PAGE_FAIL';

export const CHANGE_TRANSITION_PAGE_PREVIEW_SIZE =
  'CHANGE_TRANSITION_PAGE_PREVIEW_SIZE';
