import React, { useMemo } from 'react';

import { QaGridEdit } from 'pages/qa-spec/components';

import { useGetAllergenColDefs } from 'pages/qa-spec/hooks/useAllergen';

import { cloneDeep } from 'lodash';

const QaAllergenGrid = (props) => {
  const {
    gridInst,
    data,
    isEdit,
    onCellEditingStopped,
    onCellEditingStarted,
    onRowClicked,
    allergenOptions,
    ...rest
  } = props;

  const procData = useMemo(() => {
    return cloneDeep(data);
  }, [data]);

  const fields = useGetAllergenColDefs(isEdit, allergenOptions);

  const rowClassRules = useMemo(
    () => ({
      'ag-row--error': (params) => {
        return !!params?.data?.error?.length > 0;
      },
    }),
    []
  );

  return (
    <QaGridEdit
      gridInst={gridInst}
      gridData={procData}
      fields={fields}
      isEdit={isEdit}
      getRowNodeId={(data) => {
        return data?.['uuid'];
      }}
      onCellEditingStopped={onCellEditingStopped}
      onCellEditingStarted={onCellEditingStarted}
      onRowClicked={onRowClicked}
      rowClassRules={rowClassRules}
      {...rest}
    />
  );
};

export default QaAllergenGrid;
