import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';

import { CustomNotification } from 'common/components';
import {
  deleteQaSpecDrugFact,
  deleteQaSpecNutritionFact,
  deleteQaSpecPetFoodFactsPanel,
  deleteQaSpecSupplementFact,
  getPetFoodSnapshot,
  getQaSpecDataNutritionFacts,
  getQaSpecDataSupplementFact,
  getQaSpecDrugFacts,
  getQaSpecPetFoodFactsPanel,
  getSnapshotDrugFacts,
  getSnapshotNutritionFacts,
  getSnapshotSupplementFacts,
} from 'services/product';

import { useInvalidateGroupDetail } from 'pages/product-full-view/components/product-media-area/shared/hooks';

import { sleep } from 'utils/delay';
import {
  getKeyDrugPanels,
  getKeyFactPanels,
  getKeySupplementPanels,
  getPetFoodPanels,
  useQueryPetFoodPanel,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/facts-panel/useQueryFactsPanel';
import { addSnapshotData } from './utils';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

export const getKeyQaSpecFactPanels = (id) => {
  return ['qa-spec', parseInt(id), 'multiple-panels/get-facts-panel'];
};

export const getKeyQaSpecSupplementPanels = (id) => {
  return [
    'qa-spec',
    parseInt(id),
    'multiple-panels',
    'get-supplement-facts-panel',
  ];
};

export const getKeyQaSpecDrugFactPanels = (id) => {
  return ['qa-spec', parseInt(id), 'multiple-panels', 'get-drug-facts-panel'];
};

export const getKeyQaSpecPetFoodPanels = (id) => {
  return [
    'qa-spec',
    parseInt(id),
    'multiple-panels',
    'get-pet-food-facts-panel',
  ];
};

export const getKeySnapshotNutritionFacts = (productId) => [
  'pim',
  'snapshot',
  parseInt(productId),
  'qa-spec-fact-panel',
  'nutrition-facts',
];

export const getKeySnapShoSupplementFacts = (productId) => [
  'pim',
  'snapshot',
  parseInt(productId),
  'qa-spec-fact-panel',
  'supplement-facts',
];

export const getKeySnapShoDrugFacts = (productId) => [
  'pim',
  'snapshot',
  parseInt(productId),
  'qa-spec-fact-panel',
  'drug-facts',
];

export const getKeyPetFoodSnapshot = (productId) => [
  'pim',
  'snapshot',
  parseInt(productId),
  'qa-spec-fact-panel',
  'pet-food',
];

export const useQueryFactsPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeyQaSpecFactPanels(id),
    queryFn: async () => {
      const { data } = await getQaSpecDataNutritionFacts({ productId: id });
      return data?.nutritionFacts || [];
    },
  });

  return {
    listFactsPanel: data || [],
    ...restQuery,
  };
};

export const useQueryDrugFactsPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeyQaSpecDrugFactPanels(id),
    queryFn: async () => {
      const { data } = await getQaSpecDrugFacts({
        ProductItemId: id,
      });
      return data?.drugFacts || [];
    },
  });

  return {
    listDrugFactsPanel: data || [],
    ...restQuery,
  };
};

export const useQuerySupplementFactsPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeyQaSpecSupplementPanels(id),
    queryFn: async () => {
      const { data } = await getQaSpecDataSupplementFact({
        ProductItemId: id,
      });
      return data?.supplementFacts || [];
    },
  });

  return {
    listSupplementFactsPanel: data || [],
    ...restQuery,
  };
};

export const useQueryPetFoodFactsPanel = ({ id }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeyQaSpecPetFoodPanels(id),
    queryFn: async () => {
      const { data } = await getQaSpecPetFoodFactsPanel({
        ProductItemId: id,
      });
      return data?.petNutritionFacts || [];
    },
  });

  return {
    listPetNutritionFacts: data || [],
    ...restQuery,
  };
};

export const useQuerySnapshotNutritionFacts = ({ productId, ...rest }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeySnapshotNutritionFacts(productId),
    queryFn: async () => {
      const { data } = await getSnapshotNutritionFacts({
        ProductId: productId,
      });
      return data?.nutritionFacts || [];
    },
    ...rest,
  });

  return {
    snapshotNutritionFacts: data || [],
    ...restQuery,
  };
};

export const useQuerySnapshotSupplementFacts = ({ productId, ...rest }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeySnapShoSupplementFacts(productId),
    queryFn: async () => {
      const { data } = await getSnapshotSupplementFacts({
        ProductItemId: productId,
      });
      return data?.supplementFacts || [];
    },
    ...rest,
  });

  return {
    snapshotSupplementFacts: data || [],
    ...restQuery,
  };
};
export const useQuerySnapshotDrugFacts = ({ productId, ...rest }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeySnapShoDrugFacts(productId),
    queryFn: async () => {
      const { data } = await getSnapshotDrugFacts({
        ProductItemId: productId,
      });
      return data?.drugFacts || [];
    },
    ...rest,
  });

  return {
    snapshotDrugFacts: data || [],
    ...restQuery,
  };
};

export const useQueryPetFoodSnapshot = ({ productId, ...rest }) => {
  const { data, ...restQuery } = useQuery({
    queryKey: getKeyPetFoodSnapshot(productId),
    queryFn: async () => {
      const { data } = await getPetFoodSnapshot({
        ProductItemId: productId,
      });
      return data?.petNutritionFacts || [];
    },
    ...rest,
  });

  return {
    dataPetFoodSnapshot: data || [],
    ...restQuery,
  };
};

export const useMutationDeleteQaSpecFactsPanel = ({ id }) => {
  const queryClient = useQueryClient();

  const { handleSyncAllQaDetailGroup } = useInvalidateGroupDetail(id);

  const nutritionDeleteMutation = useMutation({
    mutationFn: deleteQaSpecNutritionFact,
    onSuccess: async () => {
      await sleep(500);
      handleSyncAllQaDetailGroup();
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecFactPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyFactPanels(id),
      });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  const supplementDeleteMutation = useMutation({
    mutationFn: deleteQaSpecSupplementFact,
    onSuccess: async () => {
      await sleep(500);
      handleSyncAllQaDetailGroup();
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecSupplementPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getKeySupplementPanels(id),
      });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  const drugFactDeleteMutation = useMutation({
    mutationFn: deleteQaSpecDrugFact,
    onSuccess: async () => {
      await sleep(500);
      queryClient.invalidateQueries({
        queryKey: getKeyDrugPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecDrugFactPanels(id),
      });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  const petFoodDeleteMutation = useMutation({
    mutationFn: deleteQaSpecPetFoodFactsPanel,
    onSuccess: async () => {
      await sleep(500);
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecPetFoodPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getPetFoodPanels(id),
      });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  return {
    drugFactDeleteMutation,
    nutritionDeleteMutation,
    supplementDeleteMutation,
    petFoodDeleteMutation,
  };
};

export const useGetAllNutritionData = ({ productId }) => {
  const isSnapshot = useCheckSnapshotForRetailer();

  const { listFactsPanel, isLoading: nutritionLoading } = useQueryFactsPanel({
    id: productId,
  });

  const { listSupplementFactsPanel, isLoading: supplementLoading } =
    useQuerySupplementFactsPanel({
      id: productId,
    });

  const { listDrugFactsPanel, isLoading: drugLoading } = useQueryDrugFactsPanel(
    {
      id: productId,
    }
  );

  const { listPetFoodPanel, isLoading: petFoodLoading } = useQueryPetFoodPanel({
    id: productId,
  });

  const { snapshotNutritionFacts } = useQuerySnapshotNutritionFacts({
    productId,
    enabled: isSnapshot && !!productId,
  });

  const { snapshotSupplementFacts } = useQuerySnapshotSupplementFacts({
    productId,
    enabled: isSnapshot && !!productId,
  });

  const { snapshotDrugFacts } = useQuerySnapshotDrugFacts({
    productId,
    enabled: isSnapshot && !!productId,
  });

  const { dataPetFoodSnapshot } = useQueryPetFoodSnapshot({
    productId: productId,
    enabled: isSnapshot && !!productId,
  });

  const addSnapshotDataNutritionFacts = addSnapshotData({
    snapshotValue: snapshotNutritionFacts,
    currentValue: listFactsPanel,
    isSnapshot,
  });

  const addSnapshotDataSupplementFacts = addSnapshotData({
    snapshotValue: snapshotSupplementFacts,
    currentValue: listSupplementFactsPanel,
    isSnapshot,
  });

  const addPetFoodSnapshot = addSnapshotData({
    snapshotValue: dataPetFoodSnapshot,
    currentValue: listPetFoodPanel,
    isSnapshot,
  });

  const addSnapshotDataDrugFacts = addSnapshotData({
    snapshotValue: snapshotDrugFacts,
    currentValue: listDrugFactsPanel,
    isSnapshot,
  });

  const totalFactsPanels = [
    ...addSnapshotDataNutritionFacts,
    ...addSnapshotDataSupplementFacts,
    ...addSnapshotDataDrugFacts,
    ...addPetFoodSnapshot,
  ];

  const loading =
    nutritionLoading || supplementLoading || drugLoading || petFoodLoading;

  return {
    totalFactsPanels,
    isLoading: loading,
  };
};
