import React from 'react';
import { useSelector } from 'react-redux';
import { Provider } from 'react-redux';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { DragWrap, DragDropWrapUtils } from 'common/components';

import store from 'index';

const DragRender = (props) => {
  const { data } = props;

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  return (
    <Provider store={store}>
      <DragWrap
        dragListData={detailCurrentItemsSelection}
        dragData={data}
        dragNamePath={DragDropWrapUtils.mapItemDataToDragNamePath(data)}
        icon={DragDropWrapUtils.mapItemDataToDragIcon(data)}
      >
        <span className='ag-icon ag-icon-grip' role='presentation' />
      </DragWrap>
    </Provider>
  );
};

export default DragRender;
