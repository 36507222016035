import { useState, useEffect, useMemo, useCallback } from 'react';

import { useAsync, useFetch } from 'hooks';

import {
  getInteropConnections,
  getDropboxCallbackService,
  testInteropConnection,
  getListConnections,
} from 'services/interoperability';

import {
  getListStatusInterop,
  saveAuthDataIntoLocalStorage,
  removeInteropLocalStorage,
  AUTH_DATA_KEY,
} from './utils';

import { CustomNotification } from 'common/components';
import { useGetMemberId } from 'hooks/useGetMemberId';

export const useVisibleModal = () => {
  const [visible, setVisible] = useState(false);

  const handler = useMemo(
    () => ({
      onModal: () => setVisible(true),
      offModal: () => setVisible(false),
      toggle: () => setVisible((modal) => !modal),
    }),
    []
  );

  return [visible, handler];
};

const PAGE_SIZE = 9999;
const PAGE_INDEX = 1;

export const useGetInteropConnections = () => {
  const { data, run } = useAsync();

  const { memberId } = useGetMemberId();

  useEffect(() => {
    run(
      getInteropConnections({
        pageSize: PAGE_SIZE,
        pageIndex: PAGE_INDEX,
        memberId,
      })
    );
  }, [memberId, run]);

  const refetchDropboxConnections = useCallback(() => {
    run(
      getInteropConnections({
        pageSize: PAGE_SIZE,
        pageIndex: PAGE_INDEX,
        memberId,
      })
    );
  }, [memberId, run]);

  return {
    interoperabilities: data?.gridData ?? [],
    refetchDropboxConnections,
  };
};

export const useGetListConnections = ({ storageServiceType }) => {
  const { data, run } = useAsync();

  const { memberId } = useGetMemberId();

  useEffect(() => {
    run(
      getListConnections({
        pageSize: PAGE_SIZE,
        pageIndex: PAGE_INDEX,
        storageServiceType,
      })
    );
  }, [memberId, run, storageServiceType]);

  return {
    interoperabilities: data?.gridData ?? [],
  };
};

export const useGetInteropParams = ({
  isEnabled,
  code,
  stateInterop,
  formInstance,
  forceRender,
}) => {
  const { data, status, resetData, run } = useAsync();
  const isAuthenticated = status === 'resolved';

  useEffect(() => {
    if (data?.authData && data?.state && isEnabled) {
      const { AccessToken: accessToken } = JSON.parse(data.authData);
      formInstance.setFieldsValue({
        accessToken,
        status: 'Connected',
      });
      forceRender();
      saveAuthDataIntoLocalStorage(data.authData, data.state);
    }
  }, [data, forceRender, formInstance, isEnabled]);

  useEffect(() => {
    if (!isEnabled) return;

    // Prevent calling multiple times api when switching other tabs
    if (isAuthenticated) return;

    const fetchDropboxParamsCb = async () => {
      return await getDropboxCallbackService({
        code,
        state: stateInterop,
      });
    };

    run(fetchDropboxParamsCb());
  }, [code, isAuthenticated, isEnabled, run, stateInterop]);

  return {
    isAuthenticating: status === 'pending',
    isAuthenticated,
    authData: data?.authData ?? null,
    state: data?.state,
    status: data?.status,
    resetStateAuthentication: () => resetData(),
  };
};

// Track active state when switching between tab. Use for the authentication
export const useTabActive = () => {
  const [visibilityState, setVisibilityState] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return visibilityState;
};

export const useFocusPage = () => {
  const [tabHasFocus, setTabHasFocus] = useState(true);

  useEffect(() => {
    const handleFocus = () => {
      setTabHasFocus(true);
    };

    const handleBlur = () => {
      setTabHasFocus(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return tabHasFocus;
};

// Check status of list connection
export const useCheckStatusConnections = (interop) => {
  const { data, status: statusFetching, run } = useFetch();

  useEffect(() => {
    const getStatusInteropCb = () => {
      return getListStatusInterop(interop);
    };
    run(getStatusInteropCb());
  }, [interop, run]);

  return {
    statusConnections:
      data?.map((result) => ({
        statusResponse: result.status,
        statusConnection: result.value.message,
        id: result?.value.data?.id,
      })) ?? [],

    isAuthenticating: statusFetching === 'pending',
  };
};

// Only check on edit mode
export const useCheckStatusConnection = ({
  isEnabled,
  selectedInterop,
  formInstance,
  forceRender,
}) => {
  const { data, status, resetData, run } = useFetch();
  const isError =
    status === 'rejected' ||
    data?.isSuccess === false ||
    data?.message?.toLowerCase() === 'disconnected';

  const isConnected =
    status === 'resolved' &&
    data?.isSuccess &&
    data?.message?.toLowerCase() === 'connected';

  useEffect(() => {
    if (isEnabled) {
      const params = {
        id: selectedInterop.id,
        storageServiceType: selectedInterop.storageServiceType,
        authData: selectedInterop.authData,
      };
      run(testInteropConnection(params));
    }
  }, [selectedInterop, run, isEnabled]);

  // Save authData into localStorage for editing and testing the interop
  useEffect(() => {
    if (isEnabled && isConnected) {
      const { authData } = selectedInterop;
      const parseAuthData = JSON.parse(authData);

      formInstance.setFieldsValue({
        ...formInstance.getFieldsValue(),
        status: 'Connected',
      });

      localStorage.setItem(
        AUTH_DATA_KEY,
        JSON.stringify({ authData: parseAuthData, state: null })
      );
    }
  }, [isConnected, isEnabled, selectedInterop]);

  // reset data form when authentication is error
  useEffect(() => {
    if (isEnabled && isError) {
      formInstance.setFieldsValue({
        accessToken: null,
        status: null,
      });
      CustomNotification.error(
        'The connection has been disconnected. Please authorize your account to connect!'
      );
      forceRender();
      removeInteropLocalStorage();
    }
  }, [forceRender, formInstance, isEnabled, isError]);

  return {
    isCheckingStatus: status === 'pending',
    isError,
    isConnected,
    resetStatusConnection: () => resetData(),
  };
};
