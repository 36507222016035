import React from 'react';

import { ReactComponent as CircleAddSvg } from 'assets/svg-icons/style-circle-add.svg';
import { ReactComponent as CircleRemoveSvg } from 'assets/svg-icons/style-circle-remove.svg';
import { ReactComponent as CheckedSvg } from 'assets/svg-icons/style-checked.svg';

import { deleteObjectField } from 'utils';
import classnames from 'classnames';

const PermissionTypeIcon = (props) => {
  const { type, permitData, formRole } = props;

  const handleClickAdd = () => {
    let nextPermitData = { ...permitData };
    deleteObjectField(nextPermitData, ['isNew']);
    let nextPermissions = [...formRole?.values?.permissions, nextPermitData];
    formRole.setFieldValue('permissions', nextPermissions);
  };

  const handleClickRemove = () => {
    let nextPermissions = [...formRole?.values?.permissions];
    const findPermitIndex = nextPermissions.findIndex(
      (permit) => permit?.permissionName === permitData?.permissionName
    );
    nextPermissions.splice(findPermitIndex, 1);
    formRole.setFieldValue('permissions', nextPermissions);
  };

  return (
    <div
      className={classnames('role-permission-group__list-item-icon', {
        'role-permission-group__list-item-icon--disabled': !type,
      })}
    >
      {type === 'add' ? (
        <CircleAddSvg onClick={handleClickAdd} />
      ) : type === 'remove' ? (
        <CircleRemoveSvg onClick={handleClickRemove} />
      ) : (
        <CheckedSvg />
      )}
    </div>
  );
};

export default PermissionTypeIcon;
