import produce from 'immer';
import * as types from './constants';

export const initialState = {
  loading: false,
  error: null,

  isBpSsoProductValidate: false,
  isBpSsoProductValidateDetail: false,
  bpSsoProductData: null,

  isRedirect: false,
  bpSsoProductSuccess: null,
  rivirRetailerId: null,
};

const ssoProductReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // FLOW SSO PRODUCT
      case types.BP_SSO_PRODUCT_VALIDATE:
        draft.isBpSsoProductValidate = action.payload;
        break;
      case types.BP_SSO_PRODUCT_VALIDATE_DETAIL:
        draft.isBpSsoProductValidateDetail = action.payload;
        break;
      case types.BP_SSO_PRODUCT_UPDATE_DATA:
        draft.bpSsoProductData = action.payload;
        break;
      // CREATE SSO PRODUCT
      case types.BP_SSO_REDIRECT_PAGE:
        draft.isRedirect = action.payload;
        break;
      case types.BP_SSO_CREATE_NEW_PRODUCT:
        draft.loading = true;
        draft.isRedirect = false;
        draft.bpSsoProductSuccess = null;
        break;
      case types.BP_SSO_CREATE_NEW_PRODUCT_SUCCESS:
        draft.loading = false;
        draft.bpSsoProductSuccess = action.payload;
        break;
      case types.BP_SSO_CREATE_NEW_PRODUCT_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      // GET SSO PRODUCT
      case types.BP_SSO_GET_PRODUCT:
        draft.loading = true;
        break;
      case types.BP_SSO_GET_PRODUCT_SUCCESS:
        draft.loading = false;
        break;
      case types.BP_SSO_GET_PRODUCT_ERROR:
        draft.loading = false;
        break;
      // UPDATE SSO PRODUCT
      case types.BP_SSO_UPDATE_PRODUCT:
        draft.loading = true;
        break;
      case types.BP_SSO_UPDATE_PRODUCT_SUCCESS:
        draft.loading = false;
        break;
      case types.BP_SSO_UPDATE_PRODUCT_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;
      // GET SSO PRODUCT INFO
      case types.BP_SSO_GET_PRODUCT_INFO:
        draft.loading = true;
        break;
      case types.BP_SSO_GET_PRODUCT_INFO_SUCCESS:
        draft.loading = false;
        draft.rivirRetailerId = action.payload;
        break;
      case types.BP_SSO_GET_PRODUCT_INFO_ERROR:
        draft.loading = false;
        break;
      default:
        break;
    }
  });

export default ssoProductReducer;
