import _ from 'lodash';

export const handlePayloadParamsQuery = (val, index, queryConditions) => {
  let closeString = ')';
  let possibleOperator = [...val?.possibleOperator] || [];
  if (possibleOperator?.length > 0) {
    possibleOperator.sort();
  }
  let paramsQuery = {
    openParentheses: '',
    closeParentheses: '',
    mapFieldName: val.mapFieldName,
    fieldName: val.fieldName,
    displayName: val.displayName,
    operator: val.queryCondition || possibleOperator[0],
    value:
      val.dataType !== 'bool'
        ? val.dataType === 'string' &&
          (val.queryCondition || possibleOperator[0]) === 'In'
          ? null
          : val.value
        : val.value === true
        ? 'True'
        : 'False',
    otherValue: val.otherValue,
    dataType: val.dataType,
    isMultipleValue: val?.isMultipleValue,
    isIgnoreCase: true,
    conjunction: val.conjunction === false ? 'OR' : 'AND',
    level: val.level,
  };
  if ((val.queryCondition || possibleOperator[0]) === 'In') {
    if (_.isArray(val?.value)) {
      paramsQuery.values = val?.value;
    } else {
      paramsQuery.values = [val?.value];
    }
  }
  if (index === 0) {
    paramsQuery.openParentheses = '';
    paramsQuery.conjunction = '';
    if (queryConditions.length - 1 === index) {
      paramsQuery.closeParentheses = '';
    }
  } else if (queryConditions.length - 1 === index) {
    if (val.level > 0) {
      if (val.level > queryConditions[index - 1].level) {
        paramsQuery.openParentheses = '(';
      }
      paramsQuery.closeParentheses = closeString.repeat(val.level);
    } else {
      paramsQuery.closeParentheses = '';
    }
  } else {
    if (val.level > queryConditions[index - 1].level) {
      paramsQuery.openParentheses = '(';
    }
    if (val.level > queryConditions[index + 1].level) {
      let difLevel = val.level - queryConditions[index + 1].level;
      paramsQuery.closeParentheses = closeString.repeat(difLevel);
    }
  }

  return paramsQuery;
};
