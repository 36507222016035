import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Skeleton,
  Table,
  Button,
  Space,
  Typography,
  Result,
  Empty,
} from 'antd';
import { UserOutlined, TeamOutlined } from '@ant-design/icons';
import { DeleteButton } from 'common/components';

import gridSelectors from 'common/components/grid-view/controllers/selectors';

import { getShareList } from 'services/manageSharing';
import { formatMDY } from 'utils/formatDate';
import { entityTypeIconRenderer } from 'static/Icons';

import Messages from 'i18n/messages/share';
import { injectIntl } from 'react-intl';

import './ManageShareModal.less';

const { Text } = Typography;

const ManageShareModal = (props) => {
  const shareWithIcons = {
    user: (props) => <UserOutlined {...props} />,
    member: (props) => <TeamOutlined {...props} />,
  };

  const pageSize = 10;

  //* props
  const {
    intl,
    selectedRowHook,
    shareListHook,
    removeShareItem,
    reloadListHook,
  } = props;

  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  //* state
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [shareList, setShareList] = shareListHook;
  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [selectedRow, setSelectedRow] = selectedRowHook;
  const [reloadList, setReloadListHook] = reloadListHook;

  //* function
  const fetchData = (pageIndex) => {
    const params = generateGetShareListParams(pageIndex);
    getShareList(params)
      .then((response) => {
        const { data, isSuccess } = response;
        if (isSuccess) {
          setShareList(data?.gridData || []);
          setTotal(data?.paging?.totalRecord);
        } else {
          fetchDataErrorHandler();
        }
      })
      .catch(() => {
        fetchDataErrorHandler();
      })
      .finally(() => {
        setLoading(false);
        setListLoading(false);
        setReloadListHook(false);
      });
  };

  const generateGetShareListParams = (pageIndex) => {
    const entities =
      selectedItemList?.length &&
      selectedItemList.map((item) => {
        const { type, id } = item;
        return {
          entityType: type,
          id,
        };
      });

    return {
      entities,
      pageIndex,
      pageSize,
      sort: [],
      filters: [],
    };
  };

  const fetchDataErrorHandler = () => {
    setError(true);
  };

  const generateColumns = () => {
    return [
      {
        title: intl.formatMessage(Messages.entityHeading),
        dataIndex: 'sharedName',
        key: 'sharedName',
        ellipsis: true,
        render: renderEntityColumn,
      },
      {
        title: intl.formatMessage(Messages.shareWithHeading),
        dataIndex: 'with',
        key: 'with',
        ellipsis: true,
        render: renderShareWithColumn,
      },
      {
        title: intl.formatMessage(Messages.shareAtHeading),
        dataIndex: 'dateShare',
        key: 'dateShare',
        align: 'center',
        width: '200px',
        render: (text) => {
          return text ? formatMDY(text) : null;
        },
      },
      {
        title: '',
        dataIndex: 'remove',
        key: 'remove',
        align: 'right',
        width: '50px',
        render: renderRemoveColumn,
      },
    ];
  };

  const renderEntityColumn = (text, record) => {
    const { shareType } = record;

    const Icon = (props) => {
      return entityTypeIconRenderer(shareType, props);
    };

    return (
      <Space size={8}>
        <Icon className='manage-share__entity-icon' />
        <Text className='manage-share__entity-name'>{text}</Text>
      </Space>
    );
  };

  const renderShareWithColumn = (text, record) => {
    const { userFullName, memberFullName } = record;
    const type = !!userFullName ? 'user' : 'member';

    const Icon = shareWithIcons[type];
    const displayName = !!userFullName ? userFullName : memberFullName;
    return (
      <Space size={8}>
        <Icon className='manage-share__share-with-icon' />
        <Text strong>{displayName}</Text>
      </Space>
    );
  };

  const renderRemoveColumn = (text, record) => {
    const { isEditable } = record;
    return isEditable ? (
      <Button
        className='manage-share__remove-button'
        type='link'
        danger={true}
        icon={<DeleteButton />}
        onClick={onRemoveBtnClick(record)}
      />
    ) : null;
  };

  const onRemoveBtnClick = (record) => () => {
    const deleteShareList = [record];
    removeShareItem(deleteShareList);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRow(selectedRowKeys);
  };

  const handleShareListTableChange = (pagination, filters, sorter, extra) => {
    const { current } = pagination;

    setListLoading(true);
    setPageIndex(current);
    fetchData(current);
  };

  const renderShareList = () => {
    const rowSelection = {
      selectedRowKeys: selectedRow,
      onChange: onSelectChange,
      columnWidth: '40px',
    };

    const paginationConfigs = {
      position: ['bottomLeft'],
      current: pageIndex,
      total: total,
      pageSize: pageSize,
    };

    return (
      <Table
        className='manage-share__list'
        columns={generateColumns()}
        rowKey='id'
        loading={listLoading}
        dataSource={shareList}
        rowSelection={rowSelection}
        rowClassName='manage-share__list-row'
        pagination={paginationConfigs}
        onChange={handleShareListTableChange}
      />
    );
  };

  //* did mount
  useEffect(() => {
    fetchData(pageIndex);
  }, []);

  useEffect(() => {
    if (reloadList) {
      setListLoading(true);
      fetchData(pageIndex);
    }
    // eslint-disable-next-line
  }, [reloadList]);

  return (
    <Skeleton
      active={true}
      loading={loading}
      paragraph={{ rows: 12, width: '100%' }}
      title={false}
    >
      {error ? (
        <Result
          className='manage-share__center-content'
          status='error'
          title={intl.formatMessage(Messages.getSharingErrorMessage)}
        />
      ) : shareList.length ? (
        renderShareList()
      ) : (
        <Empty
          className='manage-share__center-content'
          description={intl.formatMessage(Messages.getSharingEmptyData)}
        />
      )}
    </Skeleton>
  );
};

export default injectIntl(ManageShareModal);
