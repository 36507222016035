import React from 'react';

import { useIntl } from 'react-intl';

import { StyledModal } from 'common/components';
import CreateProductForm from './CreateProductForm';
import CreateProductModalFooter from './CreateProductModalFooter';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import useCreateProduct from 'hooks/createProductHooks';
import { useGetProductFullView } from 'hooks';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';

import messages from 'i18n/messages/product';

import './CreateProductViaFormModal.less';

function CreateProductViaFormModal({
  setAddModalVisible,
  addModalVisible,
  isEdit,
  memberId,
  productId,
}) {
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();

  const { productFull } = useGetProductFullView({ productId });

  const handleCancel = () => {
    resetFormState();
    setAddModalVisible(false);
  };

  const {
    form,
    submitForm,
    formLoading,
    resetFormState,
    ...createProductFormProps
  } = useCreateProduct({
    modalVisible: addModalVisible,
    productDetail: productFull,
    isEdit,
    reloadPage,
    callApiCallback: handleCancel,
    memberId: memberId,
    productId,
  });

  const modalProps = {
    title: isEdit
      ? intl.formatMessage(messages.editProduct)
      : intl.formatMessage(messages.addProduct),
    visible: addModalVisible,
    width: '85%',
    closable: true,
    maskClosable: false,
    onCancel: handleCancel,
    wrapClassName: 'document-modal',
  };

  return (
    <div>
      <IXONECertificationWrapper
        showModal={isEdit}
        type={TYPE_VIEW.EDIT_PRODUCT}
        onConfirm={(ixoneIgnore) => {
          submitForm(ixoneIgnore);
        }}
        onCancel={handleCancel}
      >
        {(handleConfirmIXONE) => (
          <StyledModal
            {...modalProps}
            isLoading={formLoading}
            loadingContent={{ loadingText: 'Loading' }}
            centered
            footer={
              <CreateProductModalFooter
                onCancel={handleCancel}
                onOk={async () => {
                  handleConfirmIXONE({
                    originalData: productFull,
                    changedFields: await form.validateFields(),
                  });
                }}
                okLoading={formLoading}
                disabledOkButton={false}
              />
            }
          >
            <CreateProductForm
              form={form}
              memberIdBrand={memberId}
              {...createProductFormProps}
            />
          </StyledModal>
        )}
      </IXONECertificationWrapper>
    </div>
  );
}

export default CreateProductViaFormModal;
