import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import ProductSubscriptionModal from 'common/components/product-subscription/ProductSubscriptionModal';

import Messages from 'i18n/messages/home';

const EditSubscription = (props) => {
  const { disabled, selectedSubscription } = props;

  const [visible, setVisible] = useState(false);

  const onClickHandler = () => {
    setVisible(true);
  };

  const checkDisabled = () => {
    const editableStatus = ['Active', 'Pending'];
    const isEditable = editableStatus.includes(selectedSubscription?.status);
    const isSubscriptionTypeGDSN =
      selectedSubscription?.subscriptionType === 'GDSN';
    return disabled || !isEditable || isSubscriptionTypeGDSN;
  };

  const isDisabled = checkDisabled();

  return (
    <>
      <RibbonButton
        icon={<EditOutlined style={{ height: 26 }} />}
        label={Messages.subscriptionEdit}
        onClick={onClickHandler}
        disabled={isDisabled}
      />

      {visible && (
        <ProductSubscriptionModal
          useVisibleModal={[visible, setVisible]}
          selectedSubscription={selectedSubscription}
          isEdit
          modalTitle='Edit Subscribe Product'
          displaySourceGlns={true}
        />
      )}
    </>
  );
};

export default EditSubscription;
