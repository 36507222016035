export const detectUrl = (message) => {
  let url;

  try {
    url = new URL(message);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const detectObjectStrContactCard = (message) => {
  if (message) {
    try {
      const isObjString = JSON.parse(message);
      if (
        typeof isObjString === 'object' &&
        isObjString?.type === 'contact-info'
      ) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
};

export const detectImage = (message) => {
  return message.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const detectYoutubeVideo = (message) => {
  const regExp =
    /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  const match = message?.match(regExp);
  return match && match[1].length === 11 ? match[1] : false;
};

export const traditionalReplaceAll = (str, target, replacement) => {
  return str.split(target).join(replacement);
};

export const mapInnerHtmlToText = (innerHtml) => {
  return traditionalReplaceAll(innerHtml, '<br>', '\n');
};

// TODOS: Replace special characters in message chat
const replacements = [
  { '<br>': '\n' },
  { '&nbsp;': ' ' },
  { '&amp;': '&' },
  { '&lt;': '<' },
  { '&gt;': '>' },
  { '&quot;': '"' },
  { '&apos;': "'" },
  { '&grave;': '`' },
];

const replaceManyStr = (replacements, message) => {
  let result = message;
  if (!result) return '';
  for (const replacement of replacements) {
    const newRegExp = new RegExp(Object.keys(replacement), 'g');
    result = result.replace(newRegExp, Object.values(replacement)[0]); // Object.values return a array
  }

  return result;
};
export const formatMessage = (message) => {
  return replaceManyStr(replacements, message);
};

export const sortArrayByString = (stringA, stringB) => {
  if (stringA < stringB) {
    return -1;
  }
  if (stringA > stringB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const toCapitalize = (str) => {
  if (!str) return str;
  return str.toLowerCase().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
};
