import React from 'react';
import PropTypes from 'prop-types';

import { CloseCircleOutlined } from '@ant-design/icons';

import './DeleteButton.less';

const DeleteButton = (props) => {
  const { onClick } = props;
  return (
    <CloseCircleOutlined className='delete-button' onClick={onClick}>
      Delete
    </CloseCircleOutlined>
  );
};
DeleteButton.propsTypes = {
  onClick: PropTypes.func,
};

DeleteButton.defaultProps = {
  onClick: () => null,
};
export default DeleteButton;
