import React, { useState, useEffect } from 'react';

import { Modal } from 'antd';
import { UploadFile } from 'common/components';

import ErrorModal from 'common/components/product-publication-setup/ErrorModal.js';

import * as services from 'services/reporting/endpoints';

import { UPLOAD_MAX_SIZE } from 'static/Constants';

import './ModalUploadAvancedFile.less';

const ModalUploadAdvancedFile = (props) => {
  const { visibleModal, setVisibleModal, handleUploadAdvancedFile } = props;

  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    if (visibleModal) setErrorList([]);
  }, [visibleModal]);

  const handleResponseUpload = (response) => {
    if (response?.data) {
      if (response?.data?.isSuccess) {
        setVisibleModal(false);
        handleUploadAdvancedFile(response?.data?.data);
      } else {
        if (response?.data?.errors) {
          setErrorList(response?.data?.errors);
        }
      }
    }
  };

  return (
    <Modal
      width={'50vw'}
      bodyStyle={{ height: '50vh' }}
      title='Upload Values Condition'
      visible={visibleModal}
      onCancel={() => setVisibleModal(false)}
      closable={true}
      maskClosable={false}
      footer={null}
      destroyOnClose
      centered
    >
      <div className='upload-advanced-file__modal'>
        <UploadFile
          manualUpload={true}
          isBase64={false}
          showMultiple={false}
          showXlsTemplate={false}
          apiUrl={services.GET_VALUES_FROM_FILE}
          nameFormData='File'
          showUploadSuccessMsg={false}
          showServerMessage={true}
          getResponse={(response) => handleResponseUpload(response)}
          maxSize={UPLOAD_MAX_SIZE.GENERAL}
          supportTypes={['xlsx', 'xls', 'xlsm', 'txt']}
        />
      </div>
      <div className='upload-advanced-file__modal--error-text'>
        {errorList && errorList.length > 0 && (
          <ErrorModal errorList={errorList} title={'Upload File Errors'} />
        )}
      </div>
    </Modal>
  );
};

export default ModalUploadAdvancedFile;
