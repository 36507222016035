import React, { createContext, useState, useCallback, useMemo } from 'react';

import { getInitialStateAllergens } from './utils';

const FormContext = createContext();

const FormContextProvider = ({ children }) => {
  const [searchModuleName, setSearchModuleName] = useState('');
  const [syncAllergenData, setSyncAllergenData] = useState(() =>
    getInitialStateAllergens()
  );

  const handleSearch = useCallback((value) => {
    setSearchModuleName(value);
  }, []);

  const value = useMemo(
    () => ({
      searchModuleName,
      syncAllergenData,
      setSyncAllergenData,
      handleSearch,
    }),
    [searchModuleName, syncAllergenData, handleSearch]
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

const useFormContext = () => {
  const context = React.useContext(FormContext);

  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormContextProvider');
  }
  return context;
};

export { FormContextProvider, useFormContext };
