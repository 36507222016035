import React, { useEffect, useRef, useState } from 'react';

import { DragContext } from './context/DragContext';

import useDragDropPosHooks from './hooks/useDrapDropHooks';
import {
  useClickOuterSelectedDrag,
  useClickOuterMultipleDrag,
} from './hooks/useClickOuterDrag';

const DrapDropPosWrap = (props) => {
  const { children } = props;
  const [isDragging, setIsDragging] = useState(false);

  const refDragItem = useRef(null);

  const handleTriggerDragItem = (e) => {
    const dataEvent = e.detail;
    if (dataEvent) {
      refDragItem.current = e.detail;

      document.onmouseup = stopDragging;
      document.onmousemove = handleDrag;
      setIsDragging(true);
    }
  };

  function handleDrag(e) {
    e = e || window.event;
    e.preventDefault();
    const distanceX = refDragItem?.current?.initPos?.current?.initX - e.clientX;
    const distanceY = refDragItem?.current?.initPos?.current?.initY - e.clientY;

    const handler = refDragItem?.current?.func;
    handler && handler(distanceX, distanceY);
  }

  const stopDragging = async () => {
    refDragItem.current = null;
    document.onmouseup = null;
    document.onmousemove = null;
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('triggerDrapDropPostItem', handleTriggerDragItem);
    return () => {
      document.removeEventListener(
        'triggerDrapDropPostItem',
        handleTriggerDragItem
      );
    };
  }, []);

  return (
    <DragContext.Provider value={isDragging}>{children}</DragContext.Provider>
  );
};

export {
  useDragDropPosHooks,
  useClickOuterMultipleDrag,
  useClickOuterSelectedDrag,
};

export default DrapDropPosWrap;
