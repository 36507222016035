import produce from 'immer';
import * as types from './constants';

export const initialState = {
  loading: false,
  paging: {
    pageSize: 20,
    pageNumber: 1,
    total: 0,
  },
  columns: [],
  memberMaintenance: [],
  error: null,
  isMemberGridInfoView: true,
  communicationTemplates: [],
  communicationTemplatesLoading: false,
  communicationTemplatesError: null,
  memberData: null,
  publicContactList: [],
  loadingDetail: false,
  errorDetail: null,
  exportLoading: false,
  isExecuteTemplateViewOpen: false,
  currentTemplate: null,
  templateValidation: null,
  isAddNewUser: false,
  isEditUser: false,
};

const memberMaintenanceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MEMBER_MAINTENANCE_GRID_COLUMN:
        draft.loading = true;
        break;

      case types.GET_MEMBER_MAINTENANCE_GRID_COLUMN_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;

      case types.GET_MEMBER_MAINTENANCE_GRID_COLUMN_ERROR:
        draft.loading = false;
        draft.columns = [];
        draft.error = action.error;
        break;

      case types.GET_MEMBER_MAINTENANCE_GRID:
        draft.loading = true;
        draft.error = false;
        break;

      case types.GET_MEMBER_MAINTENANCE_GRID_SUCCESS:
        draft.loading = false;
        draft.memberMaintenance = action.memberMaintenance;

        draft['paging'].pageSize = action.paging.currentPageSize;
        draft['paging'].pageNumber = action.paging.currentPageIndex;
        draft['paging'].total = action.paging.totalRecord;
        break;

      case types.GET_MEMBER_MAINTENANCE_GRID_ERROR:
        draft.loading = false;
        draft['paging'].total = 0;
        draft.memberMaintenance = [];
        draft.error = action.error;
        break;

      case types.CHANGE_MEMBER_GRID_INFO_VIEW:
        draft.isMemberGridInfoView = action.isMemberGridInfoView;
        break;

      case types.GET_ALL_COMMUNICATION_TEMPLATES_LIST:
        draft.communicationTemplatesLoading = true;
        break;
      case types.GET_ALL_COMMUNICATION_TEMPLATES_LIST_SUCCESS:
        draft.communicationTemplatesLoading = false;
        draft.communicationTemplates = action.communicationTemplates;
        break;
      case types.GET_ALL_COMMUNICATION_TEMPLATES_LIST_ERROR:
        draft.communicationTemplatesLoading = false;
        draft.communicationTemplatesError = action.error;
        break;
      // case types.MEMBER_MAINTAIN_TOGGLE_TEMPLATE_EXECUTION_VIEW:
      //   draft.isExecuteTemplateViewOpe = action.status;
      //   break;
      case types.MEMBER_MAINTAIN_SET_CURRENT_EXECUTION_TEMPLATE:
        draft.currentTemplate = action.payload;

      case types.GET_MEMBER_MAINTENANCE_SHORT_DETAIL:
        draft.loadingDetail = true;
        break;
      case types.GET_MEMBER_MAINTENANCE_SHORT_DETAIL_SUCCESS:
        draft.loadingDetail = false;
        draft.memberData = action.data;
        break;
      case types.GET_MEMBER_MAINTENANCE_SHORT_DETAIL_ERROR:
        draft.loadingDetail = false;
        draft.errorDetail = action.error;
        break;
      case types.GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE:
        draft.loadingDetail = true;
        break;
      case types.GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_SUCCESS:
        draft.loadingDetail = false;
        draft.publicContactList = action.data;
        break;
      case types.GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_ERROR:
        draft.loadingDetail = false;
        draft.publicContactList = [];
        draft.errorDetail = action.error;
        break;
      case types.SHOW_EXPORT_LOADING:
        draft.exportLoading = true;
        break;
      case types.HIDE_EXPORT_LOADING:
        draft.exportLoading = false;
        break;
      case types.TOGGLE_ADD_NEW_USER:
        draft.isAddNewUser = action.isAddNewUser;
        break;
      case types.TOGGLE_EDIT_USER:
        draft.isEditUser = action.isEditUser;
        break;
      case types.MEMBER_MAINTAIN_SET_TEMPLATE_VALIDATION:
        draft.templateValidation = action.payload;
        break;

      default:
        break;
    }
  });

export default memberMaintenanceReducer;
