import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import UserSelector from 'redux/user/selectors';
import { Row, Col, Space, Typography } from 'antd';
import { pick } from 'lodash';
import { useIntl } from 'react-intl';
import notificationMessages from 'i18n/messages/messages';

import { UserPrimaryInfo, OverviewBio, EditUserBio } from '../index';
import { EditButtonsSection, CustomNotification } from 'common/components';

import { useRefetchUserProfile } from 'pages/user-profile/components/hook/useFetchUserProfile';

import * as userServices from 'services/user';
import * as action from 'redux/user/actions';

const { Title } = Typography;

const UserTopContent = (props) => {
  const { editable } = props;
  const dispatch = useDispatch();

  // props
  const { classNamePrefix, userProfile } = props;
  const [isSavingBio, setIsSavingBio] = useState(false);
  const [content, setContent] = useState(null);
  const [isMouseInside, setMouseInside] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const isEditUser = useSelector(UserSelector.makeSelectIsEditMode());

  const { fetch: fetchUserProfile } = useRefetchUserProfile();

  const getUserPrimaryInfo = () => {
    const keys = [
      'primaryAddress',
      'firstName',
      'lastName',
      'email',
      'imageUrl',
      'socialMedias',
    ];
    return pick(userProfile, keys);
  };

  // variable
  const primaryInfo = getUserPrimaryInfo();
  const title =
    userProfile && userProfile.firstName
      ? `${userProfile.firstName}'s Bio`
      : 'Bio';

  const intl = useIntl();

  useEffect(() => {
    setContent(userProfile?.bio);
  }, [userProfile?.bio]);

  useEffect(() => {
    setIsEditMode(isEditUser);
  }, [isEditUser]);

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
    setContent(userProfile?.bio);
  };

  const handleChangeContent = (value) => {
    setContent(value);
  };

  const handleSaveContent = async () => {
    setIsSavingBio(true);
    try {
      const params = {
        userId: userProfile?.id,
        bioText: content,
      };
      const response = await userServices.editUserProfileBio(params);
      if (response?.isSuccess) {
        CustomNotification.success(
          intl.formatMessage(notificationMessages.updateSuccessMsg)
        );
        fetchUserProfile();
        setIsEditMode(false);
        dispatch(action.toggleEditUserMode(false));
      } else {
        CustomNotification.error(
          intl.formatMessage(notificationMessages.updateFailMsg)
        );
      }
    } catch (error) {
      CustomNotification.error(
        intl.formatMessage(notificationMessages.updateFailMsg)
      );
    } finally {
      setIsSavingBio(false);
    }
  };

  return (
    <Row className={`${classNamePrefix}__top-content`} gutter={20}>
      {isEditMode && editable ? (
        <Col
          className={`${classNamePrefix}__col ${classNamePrefix}__info-col`}
          span={12}
        >
          <EditUserBio
            content={userProfile?.bio}
            handleChangeContent={handleChangeContent}
          />
        </Col>
      ) : (
        <Col
          span={9}
          xxl={8}
          className={`${classNamePrefix}__col ${classNamePrefix}__info-col`}
          onMouseEnter={() => setMouseInside(true)}
          onMouseLeave={() => setMouseInside(false)}
        >
          <Space direction='vertical' style={{ width: '100%' }} size={8}>
            <UserPrimaryInfo
              classNamePrefix={classNamePrefix + '__primary-info'}
              primaryInfo={primaryInfo}
              userId={userProfile?.id}
              isEditMode={isMouseInside && editable}
            />
          </Space>
        </Col>
      )}
      <Col
        span={isEditMode && editable ? 12 : 15}
        xxl={isEditMode && editable ? 12 : 16}
        className={`${classNamePrefix}__col ${classNamePrefix}__contact-history-col`}
      >
        <Title level={5} underline={true}>
          {title}
        </Title>
        <OverviewBio content={content} />
        <div className={`${classNamePrefix}__bio-btn-edit`}>
          {editable ? (
            <EditButtonsSection
              onEdit={onEdit}
              onSave={handleSaveContent}
              onCancel={onCancel}
              loading={isSavingBio}
              isEdit={isEditMode}
            />
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

UserTopContent.propTypes = {
  classNamePrefix: PropTypes.string.isRequired,
};

export default UserTopContent;
