import React from 'react';
import { Space, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import classnames from 'classnames';

import './ParagraphConfigTag.less';

const ParagraphConfigTag = (props) => {
  const { align, onDelete, onEdit, children } = props;
  return (
    <div
      className={classnames('paragraph-config-tag', {
        'paragraph-config-tag--left': align === 'right',
        'paragraph-config-tag--right': align === 'left',
      })}
    >
      <Button
        className={classnames(
          'paragraph-config-tag__btn-edit',
          'paragraph-config-tag__btn'
        )}
        type='primary'
        onClick={onEdit && onEdit}
      >
        <EditOutlined />
      </Button>
      <Button
        className={classnames(
          'paragraph-config-tag__btn-delete',
          'paragraph-config-tag__btn'
        )}
        type='primary'
        onClick={onDelete && onDelete}
        danger
      >
        <DeleteOutlined />
      </Button>
    </div>
  );
};

export default ParagraphConfigTag;
