import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomNotification } from 'common/components';

import * as globalSelector from 'redux/global/selectors';
import * as globalActions from 'redux/global/actions';

import { useFetch } from 'hooks/useAsync';

import * as paymentServices from 'services/payment';
import { EVENT } from 'static/Constants';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

export const useCreditCardEula = ({ context } = {}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const modalVisible = useSelector(
    globalSelector.selectCreditCardModalVisible()
  );
  const isEulaAccepted = useSelector(
    globalSelector.selectCreditCardIsAccepted()
  );
  const shouldShowChargeWarning = useSelector(
    globalSelector.selectShowChargeWarning()
  );
  const showEulaWhen = useSelector(globalSelector.selectCreditCardShowWhen());

  const [actionLoading, setActionLoading] = useState(false);
  const [isErrorGetUserCardEula, setIsErrorGetUserCardEula] = useState(false);

  const { data, run: runFetchEula, status } = useFetch();
  const eulaData = data?.data;

  const fetchEula = () => {
    runFetchEula(paymentServices.getUserCardEula(), fetchSuccessCallback);
  };

  const fetchSuccessCallback = (response) => {
    const { isSuccess, data } = response;

    if (!isSuccess || !data) {
      setIsErrorGetUserCardEula(true);
      return;
    }

    updateEulaAccepted(data?.isAccepted ? true : false);
    updateShowChargeWarning(!data?.notShowChargePopupWarning);
  };

  const updateEulaAccepted = (value) => {
    dispatch(globalActions.updateCreditCardEulaAccepted(value));
  };

  const updateShowChargeWarning = (value) => {
    dispatch(globalActions.updateShowChargeWarning(value));
  };

  const acceptEula = () => {
    setActionLoading(true);

    const params = { cardEulaId: eulaData?.cardEulaId };

    return paymentServices
      .acceptCardEula(params)
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          updateCreditCardEulaModalVisible(false);
          dispatchAcceptEvent({ when: showEulaWhen }); //? still dispatch when call api fail?
          return;
        } else {
          notifyError(message);
        }

        return response;
      })
      .catch(() => {
        notifyError();
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const rejectEula = () => {
    const params = { cardEulaId: eulaData?.cardEulaId };

    paymentServices.rejectCardEula(params);
  };

  const notifyError = (message) => {
    CustomNotification.error(
      message || intl.formatMessage(Messages.creditCardEulaAcceptError)
    );
  };

  const dispatchAcceptEvent = ({ when }) => {
    const event = new CustomEvent(EVENT.ACCEPT_CARD_EULA, {
      detail: { when },
    });

    document.dispatchEvent(event);
  };

  const updateCreditCardEulaModalVisible = (value) => {
    dispatch(globalActions.updateCreditCardEulaModal(value));
  };

  const showCardEulaModal = ({ when } = {}) => {
    dispatch(globalActions.updateShowCardEulaModalWhen(when));
    updateCreditCardEulaModalVisible(true);
  };

  return {
    modalVisible: modalVisible,
    isEulaAccepted,
    updateCreditCardEulaModalVisible,
    eulaContent: eulaData?.cardEulaContent,
    eulaLoading: status === 'pending',
    actionLoading,
    isErrorGetUserCardEula,
    fetchEula,
    acceptEula,
    rejectEula,
    showCardEulaModal,
    shouldShowChargeWarning,
  };
};
