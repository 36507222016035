import { Col, Row, Checkbox } from 'antd';

import {
  PropertyLabel as AllergenLabel,
  PropertyInfo as AllergenInfo,
} from 'pages/product-full-view/shared/components';

import { GroupPartName } from '../components';

export const AllergenItem = ({ title, className = '', children, ...rest }) => {
  return (
    <GroupPartName
      title={title}
      className={`scroller multiple-panel__allergen-wrapper ${className}`}
      {...rest}
    >
      {children}
    </GroupPartName>
  );
};

export const AllergenContent = ({ label, type, value }) => {
  return (
    <Row style={{ padding: '4px 2px' }}>
      <Col span={10}>
        <AllergenLabel ellipsis={{ tooltip: label }}>{label}:</AllergenLabel>
      </Col>
      <Col span={14}>
        <AllergenInfo>
          <AllergenContentValue value={value} type={type} />
        </AllergenInfo>
      </Col>
    </Row>
  );
};

export const AllergenContentValue = ({ type, value }) => {
  if (type === 'boolean') {
    return <Checkbox checked={value} />;
  }

  if (type === 'string') {
    return value ?? 'N/A';
  }

  return null;
};

export const AllergenList = ({ children, ...rest }) => {
  return (
    <div className='multiple-panel__allergen-list' {...rest}>
      {children}
    </div>
  );
};
