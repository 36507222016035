import React from 'react';
import { Button, Tooltip, Row, Col } from 'antd';
import { EditOutlined, StopOutlined, SaveOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import './SaveCancelGroup.less';

const SaveCancelGroup = ({ edit, setIsEdit, editControllers, isLoading }) => {
  if (edit) {
    return (
      <Row
        style={{ marginBottom: 4, pointerEvents: isLoading ? 'none' : 'auto' }}
      >
        <Col flex='auto' />
        <Col>
          <Button
            icon={<SaveOutlined />}
            onClick={() => {
              editControllers?.saveAction && editControllers.saveAction();
            }}
            type='primary'
            className={classnames(
              'save-cancel-group__btn-save',
              'save-cancel-group__btn'
            )}
            loading={isLoading}
          >
            Save
          </Button>
        </Col>
        <Col>
          <Button
            icon={<StopOutlined />}
            onClick={() => {
              editControllers?.cancelAction && editControllers.cancelAction();
              setIsEdit && setIsEdit(false);
            }}
            type='default'
            className={classnames(
              'save-cancel-group__btn-cancel',
              'save-cancel-group__btn'
            )}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    );
  }
};

export default SaveCancelGroup;
