import React from 'react';
import PropTypes from 'prop-types';

import { StarFilled } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import { useFavorite } from 'hooks/useFavorite';

import Messages from 'i18n/messages/home';

const UnfavoriteControl = (props) => {
  const { type, selectedItemList, isFavoriteView } = props;

  const { callToggleFavoriteApi } = useFavorite({
    entityList: selectedItemList,
    type,
    isFavoriteView,
  });

  //* variables
  const isDisabled = selectedItemList?.length === 0;

  //* functions
  const onClickHandler = async () => {
    callToggleFavoriteApi(false);
  };

  return (
    <ButtonSmallIcon
      icon={<StarFilled />}
      label={Messages.removeFavorite}
      className='button-item-dropdown'
      onClick={onClickHandler}
      disabled={isDisabled}
    />
  );
};

UnfavoriteControl.propTypes = {
  type: PropTypes.string,
};

export default UnfavoriteControl;
