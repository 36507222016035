import React, { useState, useEffect } from 'react';
// import { supportTiffThumb } from 'utils/image';
import { Images } from 'config/assets';
import { LoadingOutlined } from '@ant-design/icons';

const ImageThumb = (props) => {
  const { image, styles, imageFileType } = props;
  const [activebase64, setActivebase64] = useState(null);

  const getImageUrl = (image) => {
    return (
      image?.thumb500 || image?.thumb1000 || image?.thumbFull || imageFileType
    );
  };

  const checkLoadableUrl = (url) => {
    if (!url) return;

    const regex = /.(png|jpg|jpeg)$/;

    return regex.test(url.toLowerCase());
  };

  useEffect(() => {
    let url = getImageUrl(image);

    const loadableUrl = checkLoadableUrl(url);

    if (loadableUrl) {
      setActivebase64(url);
    }

    // : supportTiffThumb(url, setActivebase64);
  }, []);

  const replaceError = (value) => {
    value.target.src = Images.RIVIR_LOGO_DEFAULT;
  };

  return activebase64 ? (
    <img
      src={activebase64}
      style={styles}
      alt={'broken thumb'}
      onError={(value) => replaceError(value)}
    ></img>
  ) : (
    <div className='thumbnail_image--loading'>
      <LoadingOutlined className='thumbnail_image--spin' />
    </div>
  );
};

export default ImageThumb;
