import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { Button, Popover, Menu, Typography, Avatar, Skeleton } from 'antd';

import {
  SendOutlined,
  HomeOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import * as chatSelectors from '../../controller/selectors';
import * as emailSelector from '../../../mail/controller/selectors';

import userSelectors from 'redux/user/selectors';
import * as memberSelectors from 'pages/company-profile/controllers/selectors';

import './ChatInputBar.less';
import { CHATTYPE } from 'static/Constants';

const { Text } = Typography;

const SwitchAccount = (props) => {
  const {
    handleClickSend,
    chatText,
    groupUsers,
    isInEmail,
    notAllowSendFiles,
    isVideoCallChat,
    handleSendMyContact,
  } = props;

  const chatThreadInfo = useSelector(chatSelectors.selectActiveThreadInfo());
  const emailThreadInfo = useSelector(
    emailSelector.selectEmailActiveThreadInfo()
  );

  const activeThreadInfo = isInEmail ? emailThreadInfo : chatThreadInfo;

  const foundAccount = groupUsers?.find(
    (user) => user.userId === activeThreadInfo?.userId
  );

  const renderButton = () => {
    if (notAllowSendFiles) {
      return (
        <Button
          onClick={handleClickSend}
          className={classnames('chat-input-bar__btn', {
            'chat-input-bar__btn--disabled': notAllowSendFiles,
          })}
        >
          <SendOutlined />
        </Button>
      );
    }

    if (foundAccount?.type === 'User') {
      //todo - 10/21/2021 - recheck if avatar is needed or switch account is required
      if (foundAccount?.avatar && !isVideoCallChat) {
        return (
          <div className='chat-input-bar__account' onClick={handleClickSend}>
            <Avatar src={`${foundAccount?.avatar}`} />
          </div>
        );
      }
      return (
        <Button
          onClick={handleClickSend}
          className={classnames(
            'chat-input-bar__btn',
            'chat-input-bar__btn-send',
            {
              'btn-shadow--active': chatText !== '',
            }
          )}
        >
          <SendOutlined />
        </Button>
      );
    }

    if (foundAccount?.type === CHATTYPE.COMPANY) {
      //todo - 10/21/2021 - recheck if avatar is needed or switch account is required
      if (foundAccount?.avatar && !isVideoCallChat) {
        return (
          <div className='chat-input-bar__account' onClick={handleClickSend}>
            <Avatar src={`${foundAccount?.avatar}`} />
          </div>
        );
      }

      return (
        <Button
          onClick={handleClickSend}
          className={classnames(
            'chat-input-bar__btn',
            'chat-input-bar__btn-send',
            {
              'btn-shadow--active': chatText !== '',
            }
          )}
        >
          <SendOutlined />
        </Button>
      );
    }

    return (
      <Button
        onClick={handleClickSend}
        className={classnames(
          'chat-input-bar__btn',
          'chat-input-bar__btn-send',
          {
            'btn-shadow--active': chatText !== '',
          }
        )}
      >
        <SendOutlined />
      </Button>
    );
  };

  return <div className='chat-input-bar__popover'>{renderButton()}</div>;
};

const ContactInfo = ({ handleSendMyContact }) => {
  const contactCardInfo = useSelector(
    userSelectors.makeSelectContactCardInfo()
  );

  const memberInfo = useSelector(memberSelectors.selectMemberProfile());

  const renderMyContact = () => {
    return (
      <Menu.Item
        className='chat-input-bar__accounts--item'
        onClick={() => {
          const messageText = JSON.stringify({
            type: 'contact-info',
            fullName: contactCardInfo?.fullName,
            email: contactCardInfo?.email,
            phone: contactCardInfo?.phoneNumber,
            userId: contactCardInfo?.userId,
            avatar: contactCardInfo?.image,
          });

          handleSendMyContact(messageText);
        }}
      >
        <div className='chat-input-bar__account'>
          <Avatar size={32} src={contactCardInfo?.image} />
          <Text className='chat-input-bar__account--name'>My Contact Info</Text>
        </div>
      </Menu.Item>
    );
  };

  const renderPrimaryContact = () => {
    return (
      <Menu.Item
        className='chat-input-bar__accounts--item'
        onClick={() => {
          const messageText = JSON.stringify({
            type: 'contact-info',
            fullName: memberInfo?.contactInformation?.contactInfoFullName,
            email: memberInfo?.contactInformation?.contactInfoEmail,
            phone: memberInfo?.contactInformation?.contactInfoPhoneNumber,
            userId: memberInfo?.contactInformation?.contactInfoId,
            avatar: memberInfo?.contactInformation?.contactAvatar,
          });

          handleSendMyContact(messageText);
        }}
      >
        <div className='chat-input-bar__account'>
          <span>
            <Avatar
              size={32}
              src={memberInfo?.contactInformation?.contactAvatar}
            />
          </span>

          <Text className='chat-input-bar__account--name' ellipsis>
            Primary Contact
          </Text>
        </div>
      </Menu.Item>
    );
  };

  return (
    <div className='chat-input-bar__accounts-container'>
      <Menu className='chat-input-bar__accounts'>
        {renderPrimaryContact()}
        {renderMyContact()}
      </Menu>
    </div>
  );
};

export { SwitchAccount };
