import produce from 'immer';
import * as types from './constants';
import { GRID_ID } from 'common/components/grid-view/utils';

// initial state
const PAGE_SIZE = 20;

const DEFAULT_PAGE_CONFIG = [1, PAGE_SIZE];

export const defaultPageCurrent = {
  memberPage: DEFAULT_PAGE_CONFIG,
  folderPage: DEFAULT_PAGE_CONFIG,
  ownedFolderPage: DEFAULT_PAGE_CONFIG,
  sharedFolderPage: DEFAULT_PAGE_CONFIG,
  reportingPage: DEFAULT_PAGE_CONFIG,
  ownedReportingPage: DEFAULT_PAGE_CONFIG,
  sharedReportingPage: DEFAULT_PAGE_CONFIG,
  digitalAssetPage: DEFAULT_PAGE_CONFIG,
  digitalAssetPageNew: DEFAULT_PAGE_CONFIG,
  assetPage: DEFAULT_PAGE_CONFIG,
  assetForMemberPage: DEFAULT_PAGE_CONFIG,
  documentPage: DEFAULT_PAGE_CONFIG,
  multiMediaPage: DEFAULT_PAGE_CONFIG,
  favoriteAssetPage: DEFAULT_PAGE_CONFIG,
  favoriteProductPage: DEFAULT_PAGE_CONFIG,
  requestPage: DEFAULT_PAGE_CONFIG,

  [GRID_ID.SHARE_MODAL]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.WORKFLOW_ACCEPT_STEP]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.WORKFLOW_APPROVE_STEP]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.MAPPING_SHARED_MEMBERS]: DEFAULT_PAGE_CONFIG,
  productPage: DEFAULT_PAGE_CONFIG,
  productForMemberPage: DEFAULT_PAGE_CONFIG,
  favoriteMemberPage: DEFAULT_PAGE_CONFIG,
  favoriteFolderPage: DEFAULT_PAGE_CONFIG,
  [GRID_ID.COMPANY_LOCATION]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.COMPANY_CONTACT]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.PARTY_GDSN_LIST]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.MEMBER_MAINTENANCE]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.MEMBER_MAINTENANCE_USER]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.TRANSITION_PAGE]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.SSO_APPROVAL_MEMBER]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.FORM_MAMANGE]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.SHAREABLE_MEMBER_LIST]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.SHAREABLE_USER_LIST]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.SPLASH_FORM_HISTORY_BY_ENTITY]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.MANAGE_QUERY]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.TICKETING_SYSTEM]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.PUBLICATION]: DEFAULT_PAGE_CONFIG,
  [GRID_ID.QAS_REQUIRE_FIELD]: DEFAULT_PAGE_CONFIG,
};

export const initialState = {
  visibleSelection: false,
  itemCurrentSelection: null,
  itemSelectionDialog: null,
  itemsSelection: [],
  pagesSelection: [],
  itemPageSelection: [],
  countPageSelection: [],
  isDisplayMask: true,
  visible: false,
  sizeCurrent: [1, 0],
  activeGrid: null,

  pageCurrent: defaultPageCurrent,
  gridConfig: {
    allColumns: [],
    defaultColumns: [],
    chosenColumns: [],
    idConfig: null,
    isRefreshGrid: false,
    gridQuery: [],
    toggleRefreshQuery: true,
  },

  typeViewCurrent: null,
  searchCurrent: null,
  detailCurrentItemsSelection: [],
  agGridItemsSelection: [],
  folderList: [],
  folderLoading: false,
  folderError: false,
  folderItem: false,
  addEntityToFolderLoading: false,
  addEntityToFolderError: false,
  addEntityToFolderSuccess: false,

  reloadGrid: false,
  paramsGrid: {
    sort: [
      {
        fieldName: 'requestId',
        isAscending: false,
      },
    ],
    filters: [],
  },

  checkQueryCondition: false,
  myQueryDoubleClick: false,

  folderQueryList: [],
  folderQueryLoading: false,
  folderQueryError: false,
  searchCategoriesEffected: null,

  //  queryConditions
  favMembersQuery: [],
  favAssetsQuery: [],
  favFoldersQuery: [],
  favProductsQuery: [],
  productsQuery: [],
  membersQuery: [],
  digitalMediaQuery: [],
  assetsQuery: [],
  documentsQuery: [],
  multiMediaQuery: [],
  reportingsQuery: [],
  myReportsQuery: [],
  reportsSharedToMeQuery: [],
  foldersQuery: [],
  myFoldersQuery: [],
  foldersSharedToMeQuery: [],
  assetsForMemberQuery: [],
  productsForMemberQuery: [],
  digitalMediaNewQuery: [],

  // queryAdvanceFilter
  favMembersQueryAdvance: [],
  favAssetsQueryAdvance: [],
  favFoldersQueryAdvance: [],
  favProductsQueryAdvance: [],
  productsQueryAdvance: [],
  membersQueryAdvance: [],
  digitalMediaQueryAdvance: [],
  assetsQueryAdvance: [],
  documentsQueryAdvance: [],
  multiMediaQueryAdvance: [],
  reportingsQueryAdvance: [],
  myReportsQueryAdvance: [],
  reportsSharedToMeQueryAdvance: [],
  foldersQueryAdvance: [],
  myFoldersQueryAdvance: [],
  foldersSharedToMeQueryAdvance: [],
  assetsForMemberQueryAdvance: [],
  productsForMemberQueryAdvance: [],
  digitalMediaNewQueryAdvance: [],

  // idQueryActive
  favMembersIdQuery: null,
  favAssetsIdQuery: null,
  favFoldersIdQuery: null,
  favProductsIdQuery: null,
  productsIdQuery: null,
  membersIdQuery: null,
  digitalMediaIdQuery: null,
  assetsIdQuery: null,
  documentsIdQuery: null,
  multiMediaIdQuery: null,
  reportingsIdQuery: null,
  myReportsIdQuery: null,
  reportsSharedToMeIdQuery: null,
  foldersIdQuery: null,
  myFoldersIdQuery: null,
  foldersSharedToMeIdQuery: null,
  assetsForMemberIdQuery: null,
  digitalMediaIdQueryNew: null,
  prevPathnameQuery: null,

  // only use in folder view
  preventCallApiGridView: false,

  // nested advance search
  isOpenAdvanceSearch: false,
};

const getColumnsAfterDeletedCheckbox = (columns) => {
  return columns[2]?.field === 'type' ? columns.slice(3) : columns.slice(2);
};

// Includes: Checkbox, icon favorite, folder
const getTypeColumns = (columns) => {
  return columns[2]?.field === 'type'
    ? columns.slice(0, 3)
    : columns.slice(0, 2);
};

const getOrderedColumnsGrid = (fields, columns) => {
  const orderedColumns = fields.reduce((result, field) => {
    const findItem = columns.find((col) => col.fieldNameCamelCase === field);

    if (findItem) {
      result.push(findItem);
    }
    return result;
  }, []);

  return orderedColumns;
};

/* eslint-disable default-case, no-param-reassign */
const gridViewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_VISIBLE_SELECTION:
        draft.visibleSelection = action.visibleSelection;
        break;
      case types.UPDATE_ITEM_CURRENT_SELECTION:
        draft.itemCurrentSelection = action.itemCurrentSelection;
        break;
      case types.UPDATE_ITEMS_SELECTION_DIALOG:
        draft.itemSelectionDialog = action.itemSelectionDialog;
        break;
      case types.UPDATE_ITEMS_SELECTION:
        draft.itemsSelection = action.itemsSelection;
        break;
      case types.UPDATE_PAGES_SELECTION:
        draft.pagesSelection = action.pagesSelection;
        break;
      case types.UPDATE_ITEM_PAGE_SELECTION:
        draft.itemPageSelection = action.itemPageSelection;
        break;
      case types.UPDATE_COUNT_PAGE_SELECTION:
        draft.countPageSelection = action.countPageSelection;
        break;
      case types.UPDATE_IS_DISPLAY_MASK:
        draft.isDisplayMask = action.isDisplayMask;
        break;
      case types.UPDATE_VISIBLE:
        draft.visible = action.visible;
        break;
      case types.UPDATE_SIZE_CURRENT:
        draft.sizeCurrent = action.sizeCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_MEMBER_MAINTAIN_USER:
        draft.pageCurrent[GRID_ID.MEMBER_MAINTENANCE_USER] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_MEMBER:
        draft.pageCurrent['memberPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_FOLDER:
        draft.pageCurrent['folderPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_OWNED_FOLDER:
        draft.pageCurrent['ownedFolderPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_SHARED_FOLDER:
        draft.pageCurrent['sharedFolderPage'] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_REPORTING:
        draft.pageCurrent['reportingPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_OWNED_REPORTING:
        draft.pageCurrent['ownedReportingPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_SHARED_REPORTING:
        draft.pageCurrent['sharedReportingPage'] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_DIGITAL_MEDIA:
        draft.pageCurrent['digitalAssetPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_DIGITAL_MEDIA_NEW:
        draft.pageCurrent['digitalAssetPageNew'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_ASSET:
        draft.pageCurrent['assetPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_ASSET_FOR_MEMBER:
        draft.pageCurrent['assetForMemberPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_DOCUMENT:
        draft.pageCurrent['documentPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_MULTI_MEDIA:
        draft.pageCurrent['multiMediaPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_FAVORITE_ASSET:
        draft.pageCurrent['favoriteAssetPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_FAVORITE_PRODUCT:
        draft.pageCurrent['favoriteProductPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_FAVORITE_MEMBER:
        draft.pageCurrent['favoriteMemberPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_FAVORITE_FOLDER:
        draft.pageCurrent['favoriteFolderPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_PRODUCT:
        draft.pageCurrent['productPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_PRODUCT_FOR_MEMBER:
        draft.pageCurrent['productForMemberPage'] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_SHARE_MODAL:
        draft.pageCurrent[GRID_ID.SHARE_MODAL] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_WORKFLOW_ACCEPT_STEP:
        draft.pageCurrent[GRID_ID.WORKFLOW_ACCEPT_STEP] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_WORKFLOW_APPROVE_STEP:
        draft.pageCurrent[GRID_ID.WORKFLOW_APPROVE_STEP] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_MAPPING_SHARED_MEMBERS:
        draft.pageCurrent[GRID_ID.MAPPING_SHARED_MEMBERS] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_TRANSITION:
        draft.pageCurrent[GRID_ID.TRANSITION_PAGE] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_SSO_APPROVAL_MEMBER:
        draft.pageCurrent[GRID_ID.SSO_APPROVAL_MEMBER] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_SHAREABLE_MEMBER_LIST:
        draft.pageCurrent[GRID_ID.SHAREABLE_MEMBER_LIST] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_SHAREABLE_USER_LIST:
        draft.pageCurrent[GRID_ID.SHAREABLE_USER_LIST] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_FORM_MANANGE:
        draft.pageCurrent[GRID_ID.FORM_MAMANGE] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_HISTORY_ENTITY:
        draft.pageCurrent[GRID_ID.SPLASH_FORM_HISTORY_BY_ENTITY] =
          action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_MANAGE_QUERY:
        draft.pageCurrent[GRID_ID.MANAGE_QUERY] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_PUBLICATION:
        draft.pageCurrent[GRID_ID.PUBLICATION] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_REQUEST_GRID:
        draft.pageCurrent['requestPage'] = action.pageCurrent;
        break;
      case types.UPDATE_PAGE_CURRENT_TICKETING_SYSTEM:
        draft.pageCurrent[GRID_ID.TICKETING_SYSTEM] = action.pageCurrent;
        break;

      case types.UPDATE_PAGE_CURRENT_TRANSITION:
        draft.pageCurrent[GRID_ID.QAS_REQUIRE_FIELD] = action.pageCurrent;
        break;

      case types.RESET_PAGE_CURRENT:
        draft.pageCurrent = defaultPageCurrent;
        break;
      // ? reset all page current number but still keep current page size
      case types.RESET_PAGE_CURRENT_KEEP_PAGE_SIZE:
        let nextPageCurrent = draft.pageCurrent;
        Object.entries(nextPageCurrent).forEach(([key, value]) => {
          nextPageCurrent[key] = [1, value?.[1]];
        });
        draft.pageCurrent = nextPageCurrent;

        break;
      case types.RESET_PAGE_CURRENT_MEMBER:
        draft.pageCurrent['memberPage'] = DEFAULT_PAGE_CONFIG;
        break;
      case types.RESET_PAGE_CURRENT_MEDIA:
        draft.pageCurrent['digitalAssetPage'] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent['digitalAssetPageNew'] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent['assetPage'] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent['documentPage'] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent['multiMediaPage'] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent[GRID_ID.SHARE_MODAL] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent[GRID_ID.WORKFLOW_ACCEPT_STEP] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent[GRID_ID.WORKFLOW_APPROVE_STEP] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent[GRID_ID.MAPPING_SHARED_MEMBERS] = DEFAULT_PAGE_CONFIG;
        draft.pageCurrent['favoriteAssetPage'] = DEFAULT_PAGE_CONFIG;
        break;

      case types.RESET_PAGE_CURRENT_FAVORITE_ASSET:
        draft.pageCurrent['favoriteAsset'] = DEFAULT_PAGE_CONFIG;
        break;

      case types.RESET_PAGE_CURRENT_FAVORITE_MEMBER:
        draft.pageCurrent['favoriteMember'] = DEFAULT_PAGE_CONFIG;
        break;

      case types.RESET_PAGE_CURRENT_FAVORITE_FOLDER:
        draft.pageCurrent['favoriteFolder'] = DEFAULT_PAGE_CONFIG;
        break;

      case types.RESET_PAGE_CURRENT_ASSETS:
        draft.pageCurrent = action.pageCurrent;
        break;
      case types.RESET_PAGE_CURRENT_PRODUCTS:
        draft.pageCurrent['productPage'] = DEFAULT_PAGE_CONFIG;
        break;

      case types.UPDATE_TYPE_VIEW_CURRENT:
        draft.typeViewCurrent = action.typeViewCurrent;
        break;
      case types.UPDATE_SEARCH_CURRENT:
        draft.searchCurrent = action.searchCurrent;
        break;
      case types.UPDATE_DETAIL_CURRENT_ITEMS_SELECTION:
        draft.detailCurrentItemsSelection = action.detailCurrentItemsSelection;
        break;
      case types.DELETE_ITEMS_SELECTION:
        draft.itemsSelection = [];
        draft.detailCurrentItemsSelection = [];
        break;
      case types.DELETE_CURRENT_ITEMS_SELECTION:
        draft.detailCurrentItemsSelection = [];
        break;
      case types.UPDATE_AG_GRID_ITEMS_SELECTION:
        draft.agGridItemsSelection = action.agGridItemsSelection;
        break;
      case types.GET_FOLDER_LIST:
        draft.folderLoading = true;
        draft.folderError = false;
        draft.folderList = [];
        break;
      case types.GET_FOLDER_LIST_SUCCESS:
        draft.folderLoading = false;
        draft.folderList = action.folderList;
        break;
      case types.GET_FOLDER_LIST_ERROR:
        draft.folderLoading = false;
        draft.folderList = [];
        draft.folderError = action.error;
        break;
      case types.ITEM_FOLDER_SELECTED:
        draft.folderItem = action.folderItem;
        break;
      case types.ADD_ENTITY_TO_FOLDER:
        draft.addEntityToFolderLoading = true;
        draft.addEntityToFolderError = false;
        draft.addEntityToFolderSuccess = false;
        break;
      case types.ADD_ENTITY_TO_FOLDER_SUCCESS:
        draft.addEntityToFolderLoading = false;
        draft.addEntityToFolderError = false;
        draft.addEntityToFolderSuccess = action.success;
        break;
      case types.ADD_ENTITY_TO_FOLDER_ERROR:
        draft.addEntityToFolderLoading = false;
        draft.addEntityToFolderSuccess = false;
        draft.addEntityToFolderError = action.error;
        break;
      case types.UPDATE_MESSAGE_ADD_ENTITY_TO_FOLDER:
        draft.addEntityToFolderSuccess = false;
        draft.addEntityToFolderError = false;
        break;

      case types.SAVE_GRID_COLUMNS:
        draft.gridConfig['allColumns'] = action.columns;
        break;

      case types.SAVE_CHOSEN_COLUMNS:
        draft.gridConfig['chosenColumns'] = action.columns;
        break;

      case types.CHOOSE_GRID_COLUMNS:
        draft.gridConfig['defaultColumns'] = action.payload.columns;
        draft.gridConfig['idConfig'] = action.payload.id;
        break;

      case types.CREATE_GRID_CONFIG:
        draft.statusCreate = 'loading';
        break;

      case types.CREATE_GRID_CONFIG_SUCCESS:
        draft.idConfig = action.id;
        draft.statusCreate = 'success';
        break;

      case types.CREATE_GRID_CONFIG_ERROR:
        draft.idConfig = action.id;
        draft.statusCreate = 'error';
        break;

      case types.SAVE_CONFIG_COLUMNS_SUCCESS: {
        const { jsonConfig, id } = action.data;
        const { columns } = JSON.parse(jsonConfig);
        draft.gridConfig['defaultColumns'] = columns;
        draft.gridConfig['idConfig'] = id;
        break;
      }

      case types.TOGGLE_CHANGE_GRID_CONFIG:
        draft.isToggleGrid = true;
        break;

      case types.CHANGE_WIDTH_COLUMN:
        draft.gridConfig['chosenColumns'] = action.columns;
        break;

      case types.CHANGE_SELECTED_COLUMNS:
        draft.gridConfig['chosenColumns'] = action.selectedColumns;
        break;

      case types.CHANGE_POSITION_COLUMNS:
      case types.CHANGE_DISPLAYED_COLUMNS:
        const listFields = action.columns;

        const newColumns = getColumnsAfterDeletedCheckbox(
          state.gridConfig.allColumns
        );
        const typeColumns = getTypeColumns(state.gridConfig.chosenColumns);
        const orderedColumns = getOrderedColumnsGrid(listFields, newColumns);
        const newChosenColumns = [...typeColumns, ...orderedColumns];

        draft.gridConfig['chosenColumns'] = newChosenColumns;
        break;

      case types.UPDATE_GRID_VIEW_PAGINATION_SUCCESS:
        draft.pageCurrent[action.payload.gridId] = [
          action.payload.pageNumber,
          action.payload.pageSize,
        ];
        break;
      case types.UPDATE_ACTIVE_GRID_NAME:
        draft.activeGrid = action.gridId;
        break;
      case types.CHECK_QUERY_CONDITION:
        draft.checkQueryCondition = action.checkQueryCondition;
        break;
      case types.MY_QUERY_DOUBLE_CLICK:
        draft.myQueryDoubleClick = action.myQueryDoubleClick;
        break;
        break;
      case types.UPDATE_IS_REFRESH_GRID:
        draft.gridConfig['isRefreshGrid'] = !state.gridConfig.isRefreshGrid;
        break;
      case types.CLEAR_GRID_CONFIG:
        draft.gridConfig['chosenColumns'] = [];
        draft.gridConfig['defaultColumns'] = [];
        draft.gridConfig['allColumns'] = [];
        break;
      case types.GET_FOLDER_LIST_QUERY:
        draft.folderQueryLoading = true;
        draft.folderQueryError = false;
        draft.folderQueryList = [];
        break;
      case types.GET_FOLDER_LIST_QUERY_SUCCESS:
        draft.folderQueryLoading = false;
        draft.folderQueryList = action.folderListQuery;
        break;
      case types.GET_FOLDER_LIST_QUERY_ERROR:
        draft.folderQueryLoading = false;
        draft.folderQueryError = action.error;
        break;
      case types.GET_GRID_QUERY_SUCCESS:
        draft.gridConfig['gridQuery'] = action.data;
        break;

      case types.TOGGLE_REFRESH_QUERY:
        draft.gridConfig['toggleRefreshQuery'] = action.value;
        break;

      case types.UPDATE_QUERY_FAV_MEMBER:
        draft.favMembersQuery = action.favMembersQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_FAV_MEMBER:
        draft.favMembersQueryAdvance = action.favMembersQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_FAV_MEMBER:
        draft.favMembersIdQuery = action.favMembersIdQuery;
        break;
      case types.UPDATE_QUERY_FAV_ASSET:
        draft.favAssetsQuery = action.favAssetsQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_FAV_ASSET:
        draft.favAssetsQueryAdvance = action.favAssetsQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_FAV_ASSET:
        draft.favAssetsIdQuery = action.favAssetsIdQuery;
        break;
      case types.UPDATE_QUERY_FAV_FOLDER:
        draft.favFoldersQuery = action.favFoldersQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_FAV_FOLDER:
        draft.favFoldersQueryAdvance = action.favFoldersQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_FAV_FOLDER:
        draft.favFoldersIdQuery = action.favFoldersIdQuery;
        break;

      case types.UPDATE_QUERY_FAV_PRODUCT:
        draft.favProductsQuery = action.favProductsQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_FAV_PRODUCT:
        draft.favProductsQueryAdvance = action.favProductsQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_FAV_PRODUCT:
        draft.favProductsIdQuery = action.favProductsIdQuery;
        break;

      case types.UPDATE_QUERY_PRODUCT:
        draft.productsQuery = action.productsQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_PRODUCT:
        draft.productsQueryAdvance = action.productsQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_PRODUCT:
        draft.productsIdQuery = action.productsIdQuery;
        break;
      case types.UPDATE_QUERY_MEMBER:
        draft.membersQuery = action.membersQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_MEMBER:
        draft.membersQueryAdvance = action.membersQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_MEMBER:
        draft.membersIdQuery = action.membersIdQuery;
        break;
      case types.UPDATE_QUERY_DIGITAL_MEDIA:
        draft.digitalMediaQuery = action.digitalMediaQuery;
        break;
      case types.UPDATE_QUERY_DIGITAL_MEDIA_NEW:
        draft.digitalMediaNewQuery = action.digitalMediaQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA:
        draft.digitalMediaQueryAdvance = action.digitalMediaQueryAdvance;
        break;
      case types.UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA_NEW:
        draft.digitalMediaNewQueryAdvance = action.digitalMediaQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_DIGITAL_MEDIA:
        draft.digitalMediaIdQuery = action.digitalMediaIdQuery;
        break;
      case types.UPDATE_ID_QUERY_DIGITAL_MEDIA_NEW:
        draft.digitalMediaIdQueryNew = action.digitalMediaIdQuery;
        break;
      case types.UPDATE_QUERY_ASSET:
        draft.assetsQuery = action.assetsQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_ASSET:
        draft.assetsQueryAdvance = action.assetsQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_ASSET:
        draft.assetsIdQuery = action.assetsIdQuery;
        break;
      case types.UPDATE_QUERY_DOCUMENT:
        draft.documentsQuery = action.documentsQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_DOCUMENT:
        draft.documentsQueryAdvance = action.documentsQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_DOCUMENT:
        draft.documentsIdQuery = action.documentsIdQuery;
        break;
      case types.UPDATE_QUERY_MULTI_MEDIA:
        draft.multiMediaQuery = action.multiMediaQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_MULTI_MEDIA:
        draft.multiMediaQueryAdvance = action.multiMediaQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_MULTI_MEDIA:
        draft.multiMediaIdQuery = action.multiMediaIdQuery;
        break;
      case types.UPDATE_QUERY_REPORTING:
        draft.reportingsQuery = action.reportingsQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_REPORTING:
        draft.reportingsQueryAdvance = action.reportingsQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_REPORTING:
        draft.reportingsIdQuery = action.reportingsIdQuery;
        break;
      case types.UPDATE_QUERY_REPORTING_OWNED:
        draft.myReportsQuery = action.myReportsQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_REPORTING_OWNED:
        draft.myReportsQueryAdvance = action.myReportsQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_REPORTING_OWNED:
        draft.myReportsIdQuery = action.myReportsIdQuery;
        break;
      case types.UPDATE_QUERY_REPORTING_SHARED:
        draft.reportsSharedToMeQuery = action.reportsSharedToMeQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_REPORTING_SHARED:
        draft.reportsSharedToMeQueryAdvance =
          action.reportsSharedToMeQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_REPORTING_SHARED:
        draft.reportsSharedToMeIdQuery = action.reportsSharedToMeIdQuery;
        break;
      case types.UPDATE_QUERY_FOLDER:
        draft.foldersQuery = action.foldersQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_FOLDER:
        draft.foldersQueryAdvance = action.foldersQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_FOLDER:
        draft.foldersIdQuery = action.foldersIdQuery;
        break;
      case types.UPDATE_QUERY_FOLDER_OWNED:
        draft.myFoldersQuery = action.myFoldersQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_FOLDER_OWNED:
        draft.myFoldersQueryAdvance = action.myFoldersQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_FOLDER_OWNED:
        draft.myFoldersIdQuery = action.myFoldersIdQuery;
        break;

      case types.UPDATE_QUERY_FOLDER_SHARED:
        draft.foldersSharedToMeQuery = action.foldersSharedToMeQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_FOLDER_SHARED:
        draft.foldersSharedToMeQueryAdvance =
          action.foldersSharedToMeQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_FOLDER_SHARED:
        draft.foldersSharedToMeIdQuery = action.foldersSharedToMeIdQuery;
        break;

      case types.UPDATE_QUERY_ASSET_FOR_MEMBER:
        draft.assetsForMemberQuery = action.assetsForMemberQuery;
        break;
      case types.UPDATE_QUERY_PRODUCT_FOR_MEMBER:
        draft.productsForMemberQuery = action.productsForMemberQuery;
        break;
      case types.UPDATE_QUERY_ADVANCE_ASSET_FOR_MEMBER:
        draft.assetsForMemberQueryAdvance = action.assetsForMemberQueryAdvance;
        break;
      case types.UPDATE_QUERY_ADVANCE_PRODUCT_FOR_MEMBER:
        draft.productsForMemberQueryAdvance =
          action.productsForMemberQueryAdvance;
        break;
      case types.UPDATE_ID_QUERY_ASSET_FOR_MEMBER:
        draft.assetsForMemberIdQuery = action.assetsForMemberIdQuery;
        break;
      case types.SET_USER_SEARCH_CATEGORIES_EFFECTED:
        draft.searchCategoriesEffected = action.searchCategoriesEffected;
        break;
      case types.UPDATE_PAGE_CURRENT_PARTY_GDSN:
        draft.pageCurrent[GRID_ID.PARTY_GDSN_LIST] = action.pageCurrent;
        break;

      case types.TOGGLE_RELOAD_GRID:
        draft.reloadGrid = action.reloadGrid;
        break;

      case types.UPDATE_PARAMS_GRID_VIEW:
        draft.paramsGrid.filters = action.filters;
        break;

      case types.CLEAR_FILTER_PARAMS_VIEW:
        draft.paramsGrid.filters = [];
        break;
      case types.UPDATE_PREV_PATHNAME_QUERY:
        draft.prevPathnameQuery = action.prevPathnameQuery;
        break;

      case types.TOGGLE_PREVENT_CALL_API_GRID_VIEW:
        draft.preventCallApiGridView = action.value;
        break;
      case types.UPDATE_IS_OPEN_ADVANCE_SEARCH:
        draft.isOpenAdvanceSearch = action.isOpenAdvanceSearch;
        break;
    }
  });

export default gridViewReducer;
