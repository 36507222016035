import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Input,
  Switch,
  Checkbox,
  Radio,
  Select,
  DatePicker,
  Row,
  Col,
  Typography,
  Badge,
} from 'antd';

import { CloseOutlined } from '@ant-design/icons';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';

import { Form, AgGrid, FormAddButton, WrapperSelect } from 'common/components';
import MemberThumbnailCard from 'pages/branded-members/components/card/MemberThumbnailCard';

import {
  MEMBER_DETAIL_GRID,
  MEMBER_DETAIL_GRID_DISTINCT,
} from 'services/members/endpoints';

import reducerMember from 'pages/branded-members/controllers/reducer';
import sagaMember from 'pages/branded-members/controllers/saga';
import { makeSelectColumns } from 'pages/branded-members/controllers/selectors';
import { gridMemberColumnInfo } from 'pages/branded-members/controllers/actions';
import selectorUser from 'redux/user/selectors';

import { usePrevious } from 'hooks';
import { filterColumnsGrid, filterSelectedMembers } from './utils';

import { DATE_FORMAT } from 'static/Constants';

import './CreateGridConfig.less';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const CreateGridConfig = (props) => {
  const {
    form,
    checked,
    renderDefault = true,
    setIsMounted,
    selectedMembers,
    setSelectedMembers,
    toggleCuratedQuery,
    setCuratedQuery,
    gridName,
    shareType,
    setShareType,
    memberRoles,
  } = props;

  const { isSuperMember } = useSelector(selectorUser.makeSelectUserInfo()); // TDC/Daymon
  const hasCuratedQuery = !!gridName && isSuperMember; // TDC/Daymon  and only supports on views: MEM, DAM, FOLDER, REPORTING

  useEffect(() => {
    form.setFieldsValue({
      curatedQueryShareType: shareType,
    });
  }, [form, shareType]);

  useEffect(() => {
    return () => setIsMounted((c) => !c);
  }, [setIsMounted]);

  return (
    <Row gutter={[6]}>
      <Col
        span={shareType === 'SpecificMembers' && toggleCuratedQuery ? 12 : 24}
      >
        <Form
          {...layout}
          form={form}
          className='create-grid-config__form'
          initialValues={{
            curatedQueryShareType: 'MemberRole',
          }}
        >
          <Form.Item
            name='name'
            label='Name'
            rules={[
              {
                required: true,
                message: 'Please select your name!',
              },
            ]}
          >
            <Input placeholder='Input name query' />
          </Form.Item>
          <Form.Item name='description' label='Description'>
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder='Input description'
            />
          </Form.Item>

          {renderDefault && (
            <Form.Item
              name='isDefault'
              label='Default'
              valuePropName={checked ? 'checked' : null}
            >
              <Switch />
            </Form.Item>
          )}

          {isSuperMember && (
            <Form.Item
              name='isMostPopularQuery'
              label='Most Popular Query'
              valuePropName='checked'
            >
              <Checkbox />
            </Form.Item>
          )}

          {hasCuratedQuery && (
            <Form.Item
              name='isCuratedQuery'
              label='Curated Query'
              valuePropName='checked'
            >
              <Checkbox onChange={(e) => setCuratedQuery(e.target.checked)} />
            </Form.Item>
          )}

          {toggleCuratedQuery && (
            <>
              <Form.Item name='curatedQueryShareType' label='Share Type'>
                <Radio.Group
                  onChange={(e) => setShareType(e.target.value)}
                  value={shareType}
                >
                  <Radio value='MemberRole'>Member Role</Radio>
                  <Radio value='SpecificMembers'>Pickup Members</Radio>
                  <Radio value='AllMember'>All Member</Radio>
                </Radio.Group>
              </Form.Item>

              {/* Keep memberType prevent errors from old data */}
              {shareType === 'MemberRole' || shareType === 'MemberType' ? (
                <Form.Item
                  name='curatedQueryShareToMemberRoleId'
                  label='Member Role'
                >
                  <WrapperSelect placeholder='Select member role'>
                    {memberRoles
                      ?.slice()
                      .sort()
                      .map((role) => (
                        <Select.Option
                          key={role?.memberRoleId}
                          value={role?.memberRoleId}
                        >
                          {role?.memberRoleName}
                        </Select.Option>
                      ))}
                  </WrapperSelect>
                </Form.Item>
              ) : null}

              <Form.Item name='dateActive' label='Date Active'>
                <RangePicker format={DATE_FORMAT.SYSTEM_FORMAT} />
              </Form.Item>

              {shareType === 'SpecificMembers' &&
                selectedMembers.length > 0 && (
                  <Row>
                    <Col span={5} style={{ marginTop: 5, textAlign: 'right' }}>
                      <Text
                        style={{
                          fontSize: 13,
                          color: 'rgba(0, 0, 0, 0.85)',
                          paddingRight: 10,
                        }}
                      >
                        Members:
                      </Text>
                    </Col>
                    <Col
                      span={18}
                      style={{ maxHeight: 300 }}
                      className='scroller'
                    >
                      <MemberDisplay
                        selectedMembers={selectedMembers}
                        setSelectedMembers={setSelectedMembers}
                      />
                    </Col>
                  </Row>
                )}
            </>
          )}
        </Form>
      </Col>

      {shareType === 'SpecificMembers' && toggleCuratedQuery && (
        <Col span={12}>
          <MemberSelection
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
          />
        </Col>
      )}
    </Row>
  );
};

const nameGrid = 'member-detail-grid';
const key = 'memberList';

const MemberSelection = ({ selectedMembers, setSelectedMembers }) => {
  useInjectReducer({ key, reducer: reducerMember });
  useInjectSaga({ key, saga: sagaMember });

  const [selectedIds, setSelectedIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [membersGrid, setMembersGrid] = useState([]);

  const prevSearchText = usePrevious(searchText);

  const [pagination, setPagination] = useState(1);

  const dispatch = useDispatch();

  const memberColumns = useSelector(makeSelectColumns());

  useEffect(() => {
    dispatch(gridMemberColumnInfo(nameGrid));
  }, [dispatch]);

  useEffect(() => {
    if (searchText !== prevSearchText) {
      setPagination(1);
    }
  }, [dispatch, prevSearchText, searchText]);

  const filteredMemberColumns = useMemo(
    () => filterColumnsGrid(memberColumns),
    [memberColumns]
  );

  const handleAddMembers = () => {
    const filteredMembers = filterSelectedMembers(
      membersGrid,
      selectedIds,
      selectedMembers
    );

    setSelectedMembers((prevMembers) => [...prevMembers, ...filteredMembers]);
  };

  return (
    <div style={{ height: '430px', marginBottom: 40 }}>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <Search
          placeholder='Search member'
          allowClear
          onSearch={(value) => setSearchText(value)}
        />
        <FormAddButton
          text='Add'
          style={{ marginLeft: 10 }}
          onClick={handleAddMembers}
        />
      </div>

      {filteredMemberColumns?.length > 0 && (
        <AgGrid
          columnDefs={filteredMemberColumns}
          urlGrid={MEMBER_DETAIL_GRID}
          urlGridDistinct={MEMBER_DETAIL_GRID_DISTINCT}
          responseParams='data'
          callbackDataListSelection={(ids) => setSelectedIds(ids)}
          handleChangePagination={(page) => setPagination(page)}
          getDataFromGrid={(items) => setMembersGrid(items)}
          paramsGrid={{
            search: {
              searchText,
            },
          }}
          pageNumberPagination={pagination}
          rowMultiSelectWithClick={true}
        />
      )}
    </div>
  );
};

const MemberDisplay = ({ selectedMembers, setSelectedMembers }) => {
  const handleDeleteMember = (id) => {
    const result = selectedMembers.filter((member) => member.id !== id);
    setSelectedMembers(result);
  };

  return (
    <Row>
      {selectedMembers.map((member) => (
        <Col key={member.id}>
          <Badge
            count={
              <span onClick={() => handleDeleteMember(member.id)}>
                <CloseOutlined />
              </span>
            }
            className='member-display__badge'
          >
            <MemberThumbnailCard
              dataDetail={member}
              hideCheckbox={true}
              style={{ margin: '0px 10px 5px 0px' }}
            />
          </Badge>
        </Col>
      ))}
    </Row>
  );
};

export default CreateGridConfig;
