import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Space } from 'antd';
import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/common';
import './EditButtonsSection.less';

const EditButtonsSection = (props) => {
  const {
    loading,
    onSave,
    onCancel,
    onEdit,
    hiddenSave,
    isEdit,
    iconOnly,
  } = props;

  return (
    <>
      {isEdit ? (
        !hiddenSave && (
          <Space className='edit-buttons-section'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              loading={loading}
              onClick={onSave}
              style={{ borderRadius: '4px' }}
            >
              {!iconOnly && <FormattedMessage {...Messages.saveBtn} />}
            </Button>
            <Button
              style={{ borderRadius: '4px' }}
              icon={<StopOutlined />}
              onClick={onCancel}
            >
              {!iconOnly && <FormattedMessage {...Messages.cancelBtn} />}
            </Button>
          </Space>
        )
      ) : (
        <Tooltip title={<FormattedMessage {...Messages.editBtn} />}>
          <Button
            className='edit-buttons-section__edit-button'
            onClick={onEdit}
            icon={<EditOutlined />}
            type='link'
          />
        </Tooltip>
      )}
    </>
  );
};

EditButtonsSection.propTypes = {
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  hiddenSave: PropTypes.bool,
  isEdit: PropTypes.bool,
};

export default EditButtonsSection;
