import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useUserInfo } from './userInfoHook';
import { getParamsObj } from 'utils/common/route';

const REGEX_MEMBER_URL_AND_MEMBER_ID = [
  {
    path: /^\/company\/\d+$/,
    idName: 'id',
  },
  {
    path: /^\/member-management-company\/\d+$/,
    idName: 'id',
  },
  {
    path: /^\/member-management\/\d+$/,
    idName: 'memberId',
  },
];

/**
 * *  member id come from 3 sources
 * * 1 ?memberId=
 * * 2 memberId or id from router params
 * * 3 memberId in logged user's info
 * @returns
 */
export const useGetMemberId = () => {
  const history = useHistory();
  const params = useParams();
  const pathName = history.location.pathname;

  const paramsObjUrl = getParamsObj(history.location.search.substring(1)) ?? {};

  const { memberId: memberIdQueryString } = paramsObjUrl;

  const memberIdRouterParam = useMemo(() => {
    const foundMemberUrl = REGEX_MEMBER_URL_AND_MEMBER_ID.find((regextItem) => {
      if (regextItem.path.test(pathName)) {
        return true;
      } else {
        return false;
      }
    });

    return params?.[foundMemberUrl?.idName];
  }, [pathName, params]);

  const { userInfo } = useUserInfo();

  const memberId = memberIdQueryString
    ? +memberIdQueryString
    : memberIdRouterParam
    ? +memberIdRouterParam
    : userInfo?.member.id;

  return { memberId };
};
