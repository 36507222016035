export const UPLOAD_NEW_FORM_TEMPLATE =
  '/api/form-template/upload-new-form-template';

export const GET_FORM_DETAIL = '/api/form-template/get-form-template-detail';

export const GET_EXPORTED_FORM_SHORT_DETAIL =
  '/api/form-template/get-form-export-history-detail';

export const GET_FORM_TEMPLATE_DETAIL =
  '/api/form-template/get-form-template-detail';
export const EDIT_FORM_TEMPLATE = '/api/form-template/edit-form-template';

export const REPLACE_FORM_FILE =
  '/api/form-template/replace-form-template-file';

export const DELETE_FORM_ITEM = '/api/form-template/delete-forms';

export const GET_ALL_PRODUCT_FIELDS =
  '/api/form-template/get-all-product-fields';

export const GET_ALL_ASSET_FIELDS =
  '/api/form-template/get-all-digital-asset-fields';

export const GET_ALL_MEMBER_FIELDS = '/api/form-template/get-all-member-fields';

export const GET_ALL_FORM_TEMPLATES =
  '/api/form-template/get-all-form-templates';

export const PREPARE_EXPORT_FORM_DATA =
  '/api/form-template/prepare-export-form-data';

export const EXPORT_FORM_FOR_SELECTED_ENTITIES =
  '/api/form-template/export-form-for-selected-entities';

export const CLONE_FORM_TEMPLATE = '/api/form-template/clone-form-template';

export const DOWNLOAD_SPLASH_MANUAL =
  '/api/form-template/download-splash-manual';

export const DOWNLOAD_ACCEPTED_FIELDS =
  '/api/form-template/download-accepted-fields';

export const GET_FORM_HISTORY_GRID_BY_MEMBER =
  '/api/form-template/get-form-export-history-grid';

export const GET_FORM_HISTORY_GRID_BY_MEMBER_DISTINCT =
  '/api/form-template/get-form-export-history-grid/distinct';
