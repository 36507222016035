import isEmpty from 'lodash/isEmpty';

import { appendDataGroup } from 'pages/product-full-view/components/product-media-area/shared/utils';

import uniqBy from 'lodash/uniqBy';

const FILTER_ALLERGEN_TYPE_TEXT = 'and its derivatives';

export const filterAllergenType = (allergens = []) => {
  const replacedAllergen = allergens
    .map((allergen) => ({
      ...allergen,
      allergenType: allergen.allergenType
        .replace(FILTER_ALLERGEN_TYPE_TEXT, '')
        .trim(),
    }))
    .filter((allergen) => allergen.isPresent);

  return uniqBy(replacedAllergen, 'allergenType');
};

export const mappingQaAllergenList = (allergensModel) => {
  let result = [];

  if (!allergensModel || allergensModel?.length === 0) return [];

  allergensModel.forEach((allergenModel) => {
    const { allergenUnitedStates, allergenWorldWide, groupIds, index } =
      allergenModel;

    const newAllergen = {
      allergens: filterAllergenType(
        allergenUnitedStates.concat(allergenWorldWide)
      ),
      groupIds,
      index,
    };

    result.push(newAllergen);
  });

  return result;
};

export const getAdditionalAllergenGroups = (currentAllergen) => {
  if (isEmpty(currentAllergen)) return [];

  const { allergenUnitedStates, allergenWorldWide, index, groupIds } =
    currentAllergen;

  return {
    index,
    groupIds,
    allergens: appendDataGroup(allergenUnitedStates, allergenWorldWide),
  };
};

export const mappingAllergenGroupsWhenEditing = (allergenGroups) => {
  let result = [];

  if (!allergenGroups || allergenGroups?.length === 0) return [];

  allergenGroups.forEach((allergenGroup) => {
    const newAllergenGroup = {
      ...allergenGroup,
      allergens: filterAllergenType(allergenGroup?.allergens),
    };
    result.push(newAllergenGroup);
  });

  return result;
};
