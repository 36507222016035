import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const downloadTagRestrictionsTemplate = (params) => {
  return api.sendGet(
    `${endpoints.DOWNLOAD_TAG_RESTRICTIONS_TEMPLATE_ENDPOINT}/${params}`
  );
};

export const downloadTagCleanupTemplate = (params) => {
  return api.sendGet(
    `${endpoints.DOWNLOAD_TAG_CLEANUP_TEMPLATE_ENDPOINT}/${params}`
  );
};

export const getAuthorizedTags = (params) => {
  return api.sendGet(
    `${endpoints.GET_AUTHORIZED_TAGS_ENDPOINT}?MemberId=${params}`
  );
};

export const executeTagsLoadSheets = (params) => {
  return api.sendPost(endpoints.EXECUTE_TAGS_SHEETS, params);
};
