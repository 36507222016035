export const QUERY_GRID_VIEW = '/api/reporting/query-reporting-grid-view';
export const QUERY_REPORTING_DETAIL_GRID =
  '/api/reporting/query-reporting-detail-grid';
export const QUERY_REPORTING_DETAIL_GRID_DISTINCT =
  '/api/reporting/query-reporting-detail-grid/distinct';
export const QUERY_REPORTING_CLONE = '/api/reporting/clone-reporting-query';
export const EXPORT_REPORTING = '/api/reporting/export-reporting-data/download';

export const DELETE_REPORTING_QUERY = '/api/reporting/delete-reporting-query';
export const GET_VALUES_FROM_FILE = '/api/reporting/get-values-from-file';

export const GET_PRODUCT_SEARCHABLE_FIELDS =
  '/api/reporting/product-searchable-fields';
export const GET_MEMBER_SEARCHABLE_FIELDS =
  '/api/reporting/member-searchable-fields';
export const GET_ASSET_SEARCHABLE_FIELDS =
  '/api/reporting/data-asset-searchable-fields';
export const GET_REPORTING_SEARCHABLE_FIELDS =
  '/api/reporting/reporting-searchable-fields';
export const GET_FOLDER_SEARCHABLE_FIELDS =
  '/api/reporting/folder-searchable-fields';

export const GET_REPORTING_DETAIL_PANE =
  '/api/reporting/query-reporting-detail-pane';

export const SAVE_REPORTING_QUERY = '/api/reporting/save-reporting-query';
export const EDIT_REPORTING_QUERY = '/api/reporting/edit-reporting-query';

export const RUN_PRODUCT_REPORTING = '/api/reporting/product-reporting';
export const RUN_MEMBER_REPORTING = '/api/reporting/member-reporting';
export const RUN_ASSET_REPORTING = '/api/reporting/data-asset-reporting';
