import React, { useState } from 'react';

import { Input, notification, Spin } from 'antd';
import { SisternodeOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { Form } from 'common/components';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import {
  StyledModal,
  ButtonSmallIcon,
  ContainerButton,
  ItemButton,
} from 'common/components';

import { addProductsToNewApl } from 'services/apl';
import './AddProductToNewApl.less';

const AddProductToNewApl = ({ selectedItemList }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <ContainerButton>
        <ItemButton>
          <ButtonSmallIcon
            icon={<SisternodeOutlined style={{ height: 26 }} />}
            label={Messages.addProductToNewApl}
            onClick={() => setVisibleModal(true)}
            className='button-item-dropdown'
            disabled={selectedItemList && selectedItemList.length === 0}
          />
        </ItemButton>
      </ContainerButton>

      {visibleModal && (
        <ModalFormCreateApl
          visibleModal={visibleModal}
          turnOffModal={() => setVisibleModal(false)}
          selectedItemList={selectedItemList}
        />
      )}
    </>
  );
};

const layoutForm = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const ModalFormCreateApl = ({
  visibleModal,
  turnOffModal,
  selectedItemList,
}) => {
  const [formInstance] = Form.useForm();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const handleSubmitForm = () => {
    formInstance.validateFields().then(() => {
      setLoading(true);
      let params = formInstance.getFieldsValue();
      let productIds =
        selectedItemList &&
        selectedItemList?.length > 0 &&
        selectedItemList.map((val) => val.id);
      params.productIds = productIds;
      addProductsToNewApl(params)
        .then((resp) => {
          if (resp.isSuccess) {
            notification.success({
              message: intl.formatMessage(
                messagesProduct.addProductToNewAPLSuccess
              ),
            });

            turnOffModal();
          } else {
            notification.error({
              message: resp?.message,
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: error,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const modalProps = {
    title: intl.formatMessage(messagesProduct.addProductToNewAPLLabel),
    bodyStyle: { height: '120px' },
    width: 600,
    destroyOnClose: true,
    visible: visibleModal,
    onCancel: turnOffModal,
    onOk: handleSubmitForm,
    okButtonProps: { loading: loading },
    cancelButtonProps: { disabled: loading },
    maskClosable: false,
    closable: !loading,
  };

  return (
    <StyledModal {...modalProps}>
      <Spin spinning={loading} tip='Add product(s) to new APL...' size='large'>
        <Form {...layoutForm} form={formInstance} name='formAddProductToNewApl'>
          <Form.Item
            name='aplName'
            label='Name'
            rules={[{ required: true, message: 'Please input APL name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='aplDescription' label='Description'>
            <Input />
          </Form.Item>
        </Form>{' '}
      </Spin>
    </StyledModal>
  );
};

export default AddProductToNewApl;
