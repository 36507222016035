import moment from 'moment';
import * as typesAsset from './controller/constants';

import * as actionsLinkAssets from './controller/actions';

function dateFormatter(params) {
  return moment(params.value).format('MM/DD/YYYY');
}

const columnFields = [
  {
    key: '0',
    field: 'assetName',
    width: 160,
    // checkboxSelection: true,
    // headerCheckboxSelection: true,
    menuTabs: [],
  },
  { key: '1', field: 'fileType', width: 110, menuTabs: [] },
  {
    key: '2',
    field: 'dateAssigned',
    cellRendererFramework: dateFormatter,
    width: 140,
    menuTabs: [],
  },
];

// Order item after dragdrop
const reorder = (assets, source, destination) => {
  const startIndex = assets.findIndex((item) => item.id === source.index);
  const endIndex = assets.findIndex((item) => item.id === destination.index);

  const result = Array.from(assets);
  const [removedAsset] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removedAsset);

  return result;
};

const handleDragEnd = ({
  dispatch,
  setStatusDragDrop,
  assetsSearch,
  idAsset,
  assetsGridState,
  ...props
}) => {
  const { destination, source } = props;

  // parentGridAssets: This is a grid assets contain the selected asset
  // childGridAssets: This is a grid assets inside the selected asset
  const { parentGridAssets, childGridAssets } = assetsGridState;

  if (!destination) return;

  if (destination.index === source.index) return;

  // Dragdrop same line
  if (destination.droppableId === source.droppableId) {
    const assetsAfterDrop = reorder(assetsSearch, source, destination);

    dispatch({
      type: typesAsset.DRAG_DROP_ASSETS,
      payload: {
        assets: assetsAfterDrop,
      },
    });
  }

  // Dragdrop asset to two grids
  else {
    // Table grid parent
    if (destination.droppableId === 'droppable-grid-parent') {
      const existingAssetIndex = parentGridAssets.findIndex(
        (asset) => asset.assetId === source.index
      );

      if (idAsset === source.index) {
        setStatusDragDrop({ isSelfAsset: true });
        return;
      }

      if (existingAssetIndex !== -1) {
        setStatusDragDrop({ isExistAsset: true });
        return;
      }

      const { assetName, id, assetType } = assetsSearch.find(
        (asset) => asset.id === source.index
      );

      // Append fileType to commit with field in AgGrid for rendering.
      const movedAsset = {
        id: 0, // 0: addition
        assetId: id,
        assetName: assetName,
        fileType: assetType,
        associationType: 1, // 1: Parent grid
        state: 'Added',
      };

      const params = {
        id: idAsset,
        assetAssociation: [movedAsset],
      };

      dispatch(actionsLinkAssets.updateLinkAsset(params));

      dispatch({
        type: typesAsset.MOVE_TO_PARENT_GRID,
        payload: { movedAsset },
      });
    }

    // Table grid child
    if (destination.droppableId === 'droppable-grid-child') {
      const existingAssetIndex = childGridAssets.findIndex(
        (asset) => asset.assetId === source.index
      );

      if (idAsset === source.index) {
        setStatusDragDrop({ isSelfAsset: true });
        return;
      }

      if (existingAssetIndex !== -1) {
        setStatusDragDrop({ isExistAsset: true });
        return;
      }

      const { assetName, id, assetType } = assetsSearch.find(
        (asset) => asset.id === source.index
      );

      // Append fileType to commit with field in AgGrid for rendering.
      const movedAsset = {
        id: 0, // 0: addition
        assetId: id,
        assetName: assetName,
        fileType: assetType,
        associationType: 2, // 2: Child grid
        state: 'Added',
      };

      const params = {
        id: idAsset,
        assetAssociation: [movedAsset],
      };

      dispatch(actionsLinkAssets.updateLinkAsset(params));

      dispatch({
        type: typesAsset.MOVE_TO_CHILD_GRID,
        payload: { movedAsset },
      });
    }
  }
};

export { dateFormatter, reorder, handleDragEnd, columnFields };
