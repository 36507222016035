import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/home';

import { RibbonButton, CustomNotification } from 'common/components';

import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';
import * as aplServices from 'services/apl';

import { saveFile } from 'utils/saveFile';

const DownloadProductApl = () => {
  const aplDetail = useSelector(makeSelectAplDetail());

  const onDownloadAPLProductList = () => {
    aplServices
      .downloadAplProductList({ aplId: aplDetail?.aplId })
      .then((res) => {
        if (res?.isSuccess) {
          saveFile(res?.data?.url);
        } else {
          CustomNotification.error(res?.message || 'Failed to download');
        }
      })
      .catch((err) => CustomNotification.error('Failed to download'));
  };

  return (
    <RibbonButton
      size='small'
      icon={<DownloadOutlined style={{ height: 26 }} />}
      label={'Download Template for Updating'}
      onClick={onDownloadAPLProductList}
    />
  );
};

export default DownloadProductApl;
