import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components/index';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as mainPageActions from 'pages/new-main-page/controllers/actions';

import { sleep } from 'utils/delay';
import * as markupMaintenanceService from 'services/markupMaintenance';

import Messages from 'i18n/messages/maintenance';
import { injectIntl } from 'react-intl';

import { useInvalidateMainPage } from 'common/queries/new-main-page-module';

const DeleteMarkupGridView = ({ intl }) => {
  const dispatch = useDispatch();

  const selectedMarkupDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const { invalidateMainPage } = useInvalidateMainPage();

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  const callApiDelete = async () => {
    const markupIds = selectedMarkupDetail.map((markup) => {
      return markup.id;
    });
    try {
      const response = await markupMaintenanceService.deleteMarkup({
        ids: markupIds,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        await sleep(1000); //! BE return response before elastic update
        dispatch(mainPageActions.reloadMarkupGrid());
        invalidateMainPage();
        showSuccessMessageDialog();
      } else {
        showErrorMessageDialog(message);
      }
    } catch (error) {
      showErrorMessageDialog('Failed to delete item');
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const showErrorMessageDialog = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <RibbonButton
      size='small'
      icon={<DeleteOutlined />}
      label={Messages.deleteMarkup}
      onClick={confirmDelete}
      disabled={checkDisableDeleteMarkup(selectedMarkupDetail)}
    />
  );
};

const checkDisableDeleteMarkup = (selectedItems) => {
  const notAllowItem = selectedItems.some(
    (item) => item.status !== 'Active' && item.status !== 'Pending'
  );
  if (selectedItems.length === 0) return true;

  if (notAllowItem) return true;

  return false;
};

export default injectIntl(DeleteMarkupGridView);
