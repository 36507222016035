import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/mapping';
import { FolderAddOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import MappingConfig from 'pages/mapping/components/mapping-config/MappingConfig';
import * as mappingSelectors from 'pages/mapping/controllers/selectors';

// *Mapping Property
const CreateMappingConfig = ({
  mappingId,
  mappingConfig,
  mappingConfigStatus,
}) => {
  const selectMappingDetail = useSelector(
    mappingSelectors.selectMappingDetail()
  );

  const { data } = selectMappingDetail;

  const handleCreateMapping = () => {
    setVisible(true);
  };

  const [visible, setVisible] = useState(false);

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <RibbonButton
        icon={<FolderAddOutlined />}
        label={Messages.createMapping}
        onClick={handleCreateMapping}
        disabled={!data?.isOwner}
      />
      {visible && (
        <MappingConfig
          mappingId={mappingId}
          visible={visible}
          setVisible={setVisible}
          onCancel={onCancel}
          mappingConfig={mappingConfig}
          mappingConfigStatus={mappingConfigStatus}
        />
      )}
    </>
  );
};

export default CreateMappingConfig;
