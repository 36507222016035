import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomNotification, dialogFunction } from 'common/components';

import MdTableEdit from './MdTableEdit';

import MdToolbarTable from './MdToolbarTable';
import MdTableList from './MdTableList';

import { traditionalReplaceAll } from 'utils';

import './MdToolTable.less';

const MD_TABLE_MODAL_VIEW = {
  DEFAULT: 'DEFAULT',
  TABLE_EDIT: 'TABLE_EDIT',
  TABLE_CREATE: 'TABLE_CREATE',
};

const MdToolTable = (props) => {
  const {
    mdEditor,
    closeEditView,
    markdownRefUpdateAction,
    markdownRefSelector,
    setInterEntityOpen,
    setEntityOpen,
    tempMdTableData,
    toolList,
  } = props;

  const [mdTableView, setMdTableView] = useState(MD_TABLE_MODAL_VIEW.DEFAULT);
  const [isOpenTableList, setIsOpenTableList] = useState(false);
  const [initTable, setInitTable] = useState([0, 0]);
  const [edittingTable, setEdittingTable] = useState(null);

  const dispatch = useDispatch();

  const mdReference = useSelector(
    markdownRefSelector ? markdownRefSelector() : () => {}
  );

  const toggleEditTable = () => {
    if (mdTableView !== MD_TABLE_MODAL_VIEW.TABLE_EDIT) {
      setMdTableView(MD_TABLE_MODAL_VIEW.TABLE_EDIT);
    } else {
      setMdTableView(MD_TABLE_MODAL_VIEW.DEFAULT);
    }
  };

  const toggleCreateTable = () => {
    if (mdTableView !== MD_TABLE_MODAL_VIEW.TABLE_CREATE) {
      setMdTableView(MD_TABLE_MODAL_VIEW.TABLE_CREATE);
    } else {
      setMdTableView(MD_TABLE_MODAL_VIEW.DEFAULT);
    }
  };

  const toggleTableList = () => {
    setIsOpenTableList(!isOpenTableList);
  };

  const saveNewTable = (newTable) => {
    const allTables = mdReference?.table || [];
    const totalTable = mdReference?.totalTable || 0;
    markdownRefUpdateAction &&
      dispatch(
        markdownRefUpdateAction({
          ...mdReference,
          table: [
            ...allTables,
            {
              ...newTable,
              name: `@mdTable${totalTable + 1}`,
            },
          ],
          totalTable: totalTable + 1,
        })
      );
    mdEditor.doc.replaceSelection(`![@table](@mdTable${totalTable + 1})`);
  };

  const searchTableIndex = (tableName) => {
    return mdReference?.table.findIndex(
      (tableItem) => tableItem.name === tableName
    );
  };

  const saveEditTable = (tableData) => {
    const allTables = mdReference?.table;
    const foundTableIndex = searchTableIndex(tableData?.name);
    let newTableList = [...allTables];
    newTableList[foundTableIndex] = tableData;
    markdownRefUpdateAction &&
      dispatch(
        markdownRefUpdateAction({
          ...mdReference,
          table: newTableList,
        })
      );
  };

  const searchedTableIndexInList = () => {
    if (!mdReference && mdReference?.table?.length <= 0) return -1;
    const tableName = mdEditor.doc.getSelection();
    return searchTableIndex(tableName);
  };

  const handleDeleteTable = (tableName) => {
    dialogFunction({
      type: 'warn',
      content: 'Do you want to delete this table ?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        const allTables = mdReference?.table;
        markdownRefUpdateAction &&
          dispatch(
            markdownRefUpdateAction({
              ...mdReference,
              table: [...allTables].filter(
                (tableItem) => tableItem?.name !== tableName
              ),
            })
          );
        const currentValue = mdEditor.doc.getValue();
        const deletedContent = traditionalReplaceAll(
          currentValue,
          `![@table](${tableName})`,
          ''
        );
        const scrollInfo = mdEditor.doc.cm.getScrollInfo();
        mdEditor.doc.setValue(deletedContent);
        mdEditor.doc.cm.scrollTo(scrollInfo.left, scrollInfo.top);
      },
    });
  };

  const handleInsertTable = (tableName) => {
    if (!mdEditor) return;
    mdEditor.doc.replaceSelection(`![@table](${tableName})`);
    closeEditView && closeEditView();
    CustomNotification.success('Insert Table Successfully!');
  };

  const isAllowToCloseTool = () => {
    if (mdTableView === MD_TABLE_MODAL_VIEW.DEFAULT && !isOpenTableList) {
      return true;
    }
    return false;
  };

  const mapEditTableDataProps = (editingTable) => {
    if (editingTable) {
      return {
        editData: editingTable,
      };
    }
    const searchedTableIdx = searchedTableIndexInList();
    if (searchedTableIdx >= 0) {
      return {
        editData: mdReference?.table[searchedTableIdx],
      };
    } else {
      return {};
    }
  };

  return (
    <div className='md-tool-table'>
      <MdToolbarTable
        toggleCreateTable={toggleCreateTable}
        toggleEditTable={toggleEditTable}
        toggleTableList={toggleTableList}
        searchedTableIndexInList={searchedTableIndexInList}
        setInitTable={setInitTable}
        closeEditView={closeEditView}
        isAllowToCloseTool={isAllowToCloseTool()}
      />
      {mdTableView === MD_TABLE_MODAL_VIEW.TABLE_EDIT && (
        <MdTableEdit
          visible={mdTableView === MD_TABLE_MODAL_VIEW.TABLE_EDIT}
          toggleIsEditTableOpen={toggleEditTable}
          saveEditTable={saveEditTable}
          modalTitle='Edit Table'
          setEntityOpen={setEntityOpen}
          setInterEntityOpen={setInterEntityOpen}
          tempMdTableData={tempMdTableData}
          toolList={toolList}
          {...mapEditTableDataProps(edittingTable)}
        />
      )}
      {mdTableView === MD_TABLE_MODAL_VIEW.TABLE_CREATE && (
        <MdTableEdit
          visible={mdTableView === MD_TABLE_MODAL_VIEW.TABLE_CREATE}
          toggleIsEditTableOpen={toggleCreateTable}
          initTable={initTable}
          saveNewTable={saveNewTable}
          modalTitle='Create New Table'
          setInterEntityOpen={setInterEntityOpen}
          setEntityOpen={setEntityOpen}
          tempMdTableData={tempMdTableData}
          toolList={toolList}
        />
      )}
      {isOpenTableList && (
        <MdTableList
          visible={isOpenTableList}
          tableList={mdReference?.table}
          toggleTableList={toggleTableList}
          setEdittingTable={setEdittingTable}
          toggleIsEditTableOpen={toggleEditTable}
          onDeleteTable={handleDeleteTable}
          onInsertTable={handleInsertTable}
        />
      )}
    </div>
  );
};

export default MdToolTable;
