import React, { useEffect, useRef } from 'react';

function useKeyDown(handlers = []) {
  const refAllowEdit = useRef(false);

  const handleKey = (event) => {
    const code = event.keyCode;
    const allowEdit = refAllowEdit.current?.value;

    try {
      handlers &&
        handlers.forEach((handler) => {
          const { key, func } = handler;
          if (allowEdit && key === code) {
            func && func();
          }
        });
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    refAllowEdit.current = {
      set: (status) => {
        refAllowEdit.current.value = status;
      },
      value: (() => refAllowEdit.current.value)(),
    };
    document.addEventListener('keydown', handleKey);
    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, []);

  return [refAllowEdit];
}

export default useKeyDown;
