// get minimum requirements grid
export const GET_MINIMUM_REQUIREMENTS =
  '/api/min-requirements/get-minimum-field-requirement-grid';
export const GET_MINIMUM_FIELD_REQUIREMENT_DETAIL =
  '/api/min-requirements/get-minimum-field-requirement-detail';

export const VALIDATE_MINIMUM_REQUIREMENT =
  '/api/min-requirements/validate-minimum-requirements';

export const GET_ALL_PRODUCT_FIELDS =
  '/api/min-requirements/get-all-product-fields';

export const GET_ALL_ASSET_FIELDS =
  '/api/min-requirements/get-all-digital-asset-fields';

export const GET_ALL_MEMBER_FIELDS = '/api/min-requirements/get-all-member-fields';

export const CREATE_MINIMUM_REQUIREMENTS =
  '/api/min-requirements/create-minimum-field-requirement';

export const DELETE_MINIMUM_REQUIREMENTS =
  '/api/min-requirements/delete-minimum-field-requirement';

export const GET_ENTITY_MINIMUM_REQUIREMENT_DETAIL =
  '/api/min-requirements/get-minimum-requirement-for-entity';
export const EDIT_MINIMUM_REQUIREMENTS =
  '/api/min-requirements/edit-minimum-field-requirement';

export const GET_DETAIL_MINIMUM_REQUIREMENTS =
  '/api/min-requirements/get-minimum-field-requirement-detail';

export const UPDATE_UNMATCHED_REQUIREMENTS =
  '/api/min-requirements/update-unmatched-requirement-properties';

export const GET_MINIMUM_REQUIREMENTS_DISTINCT =
  '/api/min-requirements/get-minimum-field-requirement-grid/distinct';
