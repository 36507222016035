import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Row, Col, Input, Space } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { Form, StyledModal, CustomNotification } from 'common/components';

import {
  SearchGrid,
  SearchGridItemButton,
  SectionContainer,
} from '../components';

import TargetMemberList from './TargetMemberList';
import SelectedUserInfo from './SelectedUserInfo';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import * as memberMaintenanceServices from 'services/memberMaintenance';

import { useSearchGrid } from '../hooks';

import reactCSS from 'reactcss';

import './styles.less';

const styles = reactCSS({
  default: {
    formRow: { height: '100%' },
    searchInput: { width: 200 },
    bottomContent: {
      flexFlow: 'row',
      overflow: 'hidden',
      flex: 'auto',
      marginLeft: -8,
      marginRight: -8,
      col_1: {
        display: 'flex',
        flexDirection: 'column',
        row: {
          flex: 1,
          overflow: 'hidden',
        },
      },
      col_2: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      },
    },
  },
});

const SearchGridItemModal = (props, ref) => {
  const {
    searchType = 'member',
    isMultiple = true,
    apiUrl,
    params,
    title,
    tagRender,
    savedItem,
    savedSelected = false,
    service,
    dataPath,
    mapSearch,
    itemIdName,
    selectedUserInfo,
    onlyFetchWithSearchText = true,
    ...restProps
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    formInstance,
    handleSelectItem,
    handleSearchItem,
    selectedItems,
    isLoading,
    showSearchHint,
    items,
  } = useSearchGrid({
    isModalOpen,
    params,
    savedSelected,
    dataPath,
    isMultiple,
    itemIdName,
    service,
    apiUrl,
    onlyFetchWithSearchText,
    mapSearch,
  });

  const reloadPage = useDispatchReloadPage();

  const handleClickOk = async () => {
    setSubmitLoading(true);

    const response = await memberMaintenanceServices.moveUsertoMember({
      userId: selectedUserInfo?.id,
      destinationMemberId: selectedItems?.[0]?.companyId,
    });

    const { isSuccess } = response;

    if (isSuccess) {
      const successMsg = 'Move user to selected member successfully';

      CustomNotification.success(successMsg);
      setIsModalOpen(false);
      reloadPage();
    } else {
      CustomNotification.error(
        response?.message || 'Failed to move user to member'
      );
    }

    setSubmitLoading(false);
  };

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setIsModalOpen(false);
    },
    openModal: () => {
      setIsModalOpen(true);
    },
  }));

  const isDisabledOkBtn = !selectedItems?.length || submitLoading;

  const makeDisableSameMember = (memberData) => {
    if (selectedUserInfo?.memberName === memberData?.companyName) {
      return {
        opacity: '0.5',
      };
    } else return {};
  };

  const makeGridItemDisabled = (memberData) => {
    if (selectedUserInfo?.memberName === memberData?.companyName) {
      return true;
    }
    return false;
  };

  return (
    <StyledModal
      visible={isModalOpen}
      className='search-grid-item-gln-to-member__modal'
      title={title}
      onCancel={() => setIsModalOpen(false)}
      onOk={handleClickOk}
      centered
      destroyOnClose
      maskClosable={false}
      okButtonProps={{
        disabled: isDisabledOkBtn,
      }}
      confirmLoading={submitLoading}
      {...restProps}
    >
      <Form form={formInstance}>
        <Row style={styles.formRow}>
          <Col>
            <Form.Item label='Search Text' name='searchText'>
              <Input
                style={styles.searchInput}
                onPressEnter={handleSearchItem}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col>
            <Space>
              <SearchGridItemButton type='primary' onClick={handleSearchItem}>
                <SearchOutlined />
              </SearchGridItemButton>

              {!onlyFetchWithSearchText && (
                <SearchGridItemButton type='primary'>
                  Reset
                </SearchGridItemButton>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
      <Row style={styles.bottomContent}>
        <Col style={styles.bottomContent.col_1}>
          <Row style={styles.bottomContent.col_1.row}>
            <SectionContainer>
              <SearchGrid
                items={items}
                handleSelectItem={handleSelectItem}
                selectedItems={selectedItems}
                isLoading={isLoading}
                searchType={searchType}
                itemIdName={itemIdName}
                showSearchHint={showSearchHint}
                makeGridItemStyle={makeDisableSameMember}
                makeGridItemDisabled={makeGridItemDisabled}
              />
            </SectionContainer>
          </Row>
        </Col>
        <Col style={styles.bottomContent.col_2}>
          <Row>
            <Col xs={24}>
              <SectionContainer>
                <SelectedUserInfo selectedUserInfo={selectedUserInfo} />
              </SectionContainer>
            </Col>
          </Row>
          <Row style={{ flex: 1 }}>
            <Col xs={24}>
              <SectionContainer>
                <TargetMemberList
                  selectedItems={selectedItems}
                  items={items}
                  handleSelectItem={handleSelectItem}
                  tagRender={tagRender}
                />
              </SectionContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default forwardRef(SearchGridItemModal);
