import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components/index';
import { CustomNotification } from 'common/components';

import { accpetSubscription } from 'services/subscription';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import Messages from 'i18n/messages/home';

const AcceptSubscription = (props) => {
  const reloadPage = useDispatchReloadPage();

  const { selectionSubscription, sectionDisabled } = props;

  const handleBtnlick = () => {
    dialogFunction({
      type: 'info',
      content:
        'Are you sure you want to accept selecting subscription request?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleOkClick,
    });
  };

  const handleOkClick = () => {
    const params = {
      subId: selectionSubscription.id,
    };

    accpetSubscription(params)
      .then((res) => {
        if (res?.isSuccess) {
          CustomNotification.success('Successfully');
          reloadPage();
        } else {
          CustomNotification.error(
            res?.message || 'Accept failed! Please try!'
          );
        }
      })
      .catch((error) => {
        CustomNotification.error('Accept failed! Something go wrong!');
      });
  };

  const checkDisabled = () => {
    let disabled = false;

    disabled = selectionSubscription?.status !== 'Pending';

    return disabled || sectionDisabled;
  };

  return (
    <RibbonButton
      icon={<CheckOutlined style={{ height: 26 }} />}
      label={Messages.acceptSubscription}
      onClick={handleBtnlick}
      disabled={checkDisabled()}
    />
  );
};

AcceptSubscription.propTypes = {};

export default AcceptSubscription;
