import React from 'react';

import TableRender from './TableRender';

import MdImage from '../mdMedia/MdImage';
import MdVideo from '../mdMedia/MdVideo';
import MdYoutube from '../mdMedia/MdYoutube';

const imageSizeRegex = /_33B2BF251EFD_([0-9]+x|x[0-9]+|[0-9]+x[0-9]+)$/;
const alignConfigRegex = /\?align=\w*([\?]| *[^?])/;
const spaceConfigRegex = /\?space=\w*([\?]| *[^?])/;

const ImageRender = (refComponents) => ({ src, ...props }) => {
  const match = imageSizeRegex.exec(src);
  const alt = props?.alt;

  const findRefSourceToRender = (tableName) => {
    const tableList = refComponents?.table;
    if (!tableList) return undefined;
    const foundTable = tableList.find(
      (tableItem) => tableItem.name === tableName
    );
    return foundTable;
  };

  const getAlignConfig = (alternate) => {
    const alignConfig = alignConfigRegex.exec(alternate);
    if (alignConfig) {
      return alignConfig[0].split('=')[1].trim();
    } else {
      return null;
    }
  };

  const getSpaceConfig = (alternate) => {
    const spaceConfig = spaceConfigRegex.exec(alternate);
    if (spaceConfig) {
      const spaceResult = spaceConfig[0].split('=')[1].trim();
      return parseFloat(spaceResult);
    } else {
      return null;
    }
  };

  const mapToPaddingStyle = (align, space) => {
    if (align) {
      if (space) {
        if (align === 'left' && space)
          return { marginRight: space + 'px', marginBottom: space + 'px' };
        if (align === 'right')
          return { marginLeft: space + 'px', marginBottom: space + 'px' };
      }
      return { margin: '0px' };
    }
    return space
      ? { marginLeft: space + 'px', marginRight: space + 'px' }
      : { marginLeft: '0px', marginRight: '0px' };
  };

  let procSrc, width, height;
  if (match) {
    const matchSize = match?.[1]?.split('x');
    if (matchSize) {
      [width, height] = matchSize?.map((s) =>
        s === '' ? undefined : Number(s)
      );
      procSrc = match.input.replace(match[0], '');
    }
  }

  const alignConfig = getAlignConfig(alt);
  const spaceConfig = getSpaceConfig(alt);

  if (alt === '@table') {
    if (src.indexOf('http') < 0) {
      const foundTable = findRefSourceToRender(src);
      return <TableRender tableData={foundTable} />;
    }
  }
  if (alt.includes('@video')) {
    if (src.indexOf('youtube') >= 0) {
      const alignConfig = getAlignConfig(alt);
      const spaceConfig = getSpaceConfig(alt);
      return (
        <MdYoutube
          src={procSrc || src}
          width={width}
          height={height}
          mapToPaddingStyle={mapToPaddingStyle}
          alignConfig={alignConfig}
          spaceConfig={spaceConfig}
        />
      );
    } else {
      return (
        <MdVideo
          src={procSrc || src}
          width={width}
          mapToPaddingStyle={mapToPaddingStyle}
          alignConfig={alignConfig}
          spaceConfig={spaceConfig}
        />
      );
    }
  }

  return (
    <MdImage
      src={procSrc || src}
      width={width}
      height={height}
      mapToPaddingStyle={mapToPaddingStyle}
      alignConfig={alignConfig}
      spaceConfig={spaceConfig}
      alt='content-img'
    />
  );
};

export default ImageRender;
