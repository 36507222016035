export const REQUIRED_FIELDS_QA_INGREDIENT = {
  ingredientStatements: {
    message: 'Ingredient Statement is required',
    label: 'Ingredient Statement',
  },
  nameOfIngredients: {
    message: 'Name Of Ingredients is required',
    label: 'Name',
  },
  countryOfOrigin: {
    message: 'Country Of Origin is required',
    label: 'Country Of Origin',
  },
  bioengineered: {
    message: 'Bioengineered is required',
    label: 'Bioengineered',
  },
  gmo: {
    message: 'GMO is required',
    label: 'GMO',
  },
};
