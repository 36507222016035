import React, { useState } from 'react';
import { Avatar, Tooltip, Button } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import './GroupPeopleDelete.less';

export const GroupPeopleDelete = (props) => {
  const { listPeople, removeItem, isCreatorRole } = props;
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <div className='group-people-delete scroller'>
        {showAll
          ? listPeople?.map((item, index) => {
              return (
                <Tooltip title={item.fullName} key={index}>
                  <div className='group-people-delete__wrapper'>
                    <div className='group-people-delete__avatar-wrapper'>
                      <Avatar
                        key={index}
                        className='group-people-delete__avatar'
                        src={item?.avatar && item?.avatar}
                        icon={!item?.avatar && <UserOutlined />}
                        size={60}
                      />
                      {isCreatorRole && (
                        <Button
                          shape='circle'
                          onClick={() => removeItem(item.userId)}
                          icon={<CloseOutlined />}
                          className='group-people-delete__avatar-remove'
                          size='small'
                        />
                      )}
                    </div>
                    <div className='group-people-delete__name'>
                      {item.fullName}
                    </div>
                  </div>
                </Tooltip>
              );
            })
          : listPeople?.map((item, index) => {
              if (index <= 5)
                return (
                  <Tooltip title={item.fullName} key={index}>
                    <div className='group-people-delete__wrapper'>
                      <div className='group-people-delete__avatar-wrapper'>
                        <Avatar
                          key={index}
                          className='group-people-delete__avatar'
                          src={item?.avatar && item?.avatar}
                          icon={!item?.avatar && <UserOutlined />}
                          size={60}
                        />
                        {isCreatorRole && (
                          <Button
                            shape='circle'
                            onClick={() => removeItem(item.userId)}
                            icon={<CloseOutlined />}
                            className='group-people-delete__avatar-remove'
                            size='small'
                          />
                        )}
                      </div>
                      <div className='group-people-delete__name'>
                        {item.fullName}
                      </div>
                    </div>
                  </Tooltip>
                );
            })}

        {listPeople?.length > 6 && !showAll && (
          <div
            className=' group-people-delete__see-more'
            onClick={() => setShowAll(true)}
          >
            ... {listPeople?.length - 6} more
          </div>
        )}
        {listPeople?.length >= 6 && showAll && (
          <div
            className=' group-people-delete__see-more'
            onClick={() => setShowAll(false)}
          >
            Show less
          </div>
        )}
      </div>
    </>
  );
};

export default GroupPeopleDelete;
