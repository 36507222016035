import React from 'react';

import { Row, Col } from 'antd';

import classnames from 'classnames';

import cloneDeep from 'lodash/cloneDeep';

import QaGridEdit from '../qa-grid-edit/QaGridEdit';

import './QaSpecDocument.less';

const QaSpecDocumentGrid = ({
  rowData,
  tableStyle,
  gridRef,
  columnsDef,
  ...otherProps
}) => {
  return (
    <Col span={24}>
      <Row style={{ width: '100%' }}>
        {columnsDef && columnsDef.length > 0 && (
          <div
            style={{
              ...tableStyle,
              width: '100%',
            }}
            className={classnames(
              'ag-theme-alpine',
              'ag-theme-alpine--with-scroller',
              'qa-spec-document__grid'
            )}
          >
            <QaGridEdit
              gridInst={gridRef}
              rowData={cloneDeep(rowData)}
              fields={columnsDef}
              defaultColDef={{
                resizable: true,
              }}
              overlayNoRowsTemplate='No Rows to Show'
              getRowNodeId={(data) => {
                return data?.linkId;
              }}
              {...otherProps}
            />
          </div>
        )}
      </Row>
    </Col>
  );
};
export default QaSpecDocumentGrid;
