import { useMemo } from 'react';

import { useGetProductEnums } from './useProductEnums';

export const useGetBasicUomEnum = () => {
  const { productEnums } = useGetProductEnums();

  const productBasicUOM = useMemo(
    () => productEnums?.filter((type) => type?.enumName === 'BasicUomEnum'),
    [productEnums]
  );

  return {
    basicUomProperties: productBasicUOM?.[0]?.enumProperties ?? [],
  };
};
