import React from 'react';
import { useHistory } from 'react-router-dom';
import { HistoryOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';
import useToggleActiveRibbon from './hook';
import { forwardTo } from 'utils/common/route';
import { RibbonButton } from 'common/components';

const SyndicationHistorySection = (props) => {
  const { disabled, productFull } = props;

  const history = useHistory();

  const checkActiveContent = () => {
    const path = history?.location?.pathname;
    const regex = /\/product\/\d+\/mapping$/;

    return regex.test(path);
  };

  const isPathActive = checkActiveContent();
  const isActive = useToggleActiveRibbon(isPathActive);

  const handleClickToSyndicationHistory = () => {
    forwardTo(`/product/syndication-history/${productFull?.productId}`);
  };

  return (
    <RibbonButton
      size='small'
      icon={<HistoryOutlined style={{ height: 26 }} />}
      label={Messages.syndicationHistory}
      onClick={handleClickToSyndicationHistory}
      disabled={disabled}
      toggle={isActive}
      className='button-item-dropdown'
    />
  );
};

export default SyndicationHistorySection;
