import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';

const arrowStyle = {
  float: 'right',
  top: 4,
  position: 'relative',
  right: 5,
  cursor: 'pointer',
};

const PageEditorToolHeader = (props) => {
  const { text = '', closeToolDraw } = props;
  return (
    <div className='page-editor-tool__header'>
      {text}
      <CaretRightOutlined
        style={arrowStyle}
        onClick={() => closeToolDraw && closeToolDraw()}
      />
    </div>
  );
};

export default PageEditorToolHeader;
