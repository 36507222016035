import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AssetAssociations from '../../../asset-full-view/components/metadata-panel/AssetAssociations';
import * as selectors from '../../../asset-full-view/controllers/selectors';
import * as assetActions from '../../../asset-full-view/controllers/actions';

const AssetPanelAssociation = (props) => {
  const { assetData } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const associationColumn = useSelector(selectors.assetDataColumnInfo());
  useEffect(() => {
    dispatch(assetActions.gridColumnAssetAssociation('asset-association-grid'));
  }, [dispatch]);

  return (
    <div className='asset-panel-association'>
      <div className='asset-panel-association__title'>Associations:</div>
      <div className='asset-panel-association__list'>
        <AssetAssociations
          associationColumn={
            associationColumn.length > 0 ? associationColumn : [{}]
          }
          assetData={assetData}
        />
      </div>
    </div>
  );
};

export default AssetPanelAssociation;
