import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const saveWorkflowRole = (params) => {
  return api.sendPost(endpoints.SAVE_WORKFLOW_ROLE, params);
};

export const removeWorkflowRole = (params) => {
  return api.sendPost(endpoints.REMOVE_WORKFLOW_ROLE, params);
};

export const addUsersToWorkflowRole = (params) => {
  return api.sendPost(endpoints.ADD_USERS_TO_WORKFLOW_ROLE, params);
};

export const removeUsersToWorkflowRole = (params) => {
  return api.sendPost(endpoints.REMOVE_USERS_TO_WORKFLOW_ROLE, params);
};

export const getAllWorkflowSteps = (params) => {
  return api.sendGet(endpoints.GET_ALL_WORKFLOW_STEPS, { params: params });
};

// get user workflow roles
export const getUserWorkflowRolesService = () => {
  return api.sendGet(endpoints.GET_USER_WORKFLOW_ROLES_ENDPOINT);
};

// get workflow grid
export const getWorkflowGridService = (params) => {
  return api.sendPost(endpoints.GET_WORKFLOW_GRID_LIST, params);
};

//* get workflow step info
export const getWorkflowStepInfo = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_STEP_INFO, { params });
};

//* get workflow implement steps
export const getWfImplSteps = (workflowImplementId) => {
  const options = {
    params: {
      workflowImplementId,
    },
  };
  return api.sendGet(endpoints.GET_WF_IMPL_STEPS, options);
};

//* get workflow implement step
export const getWfImplStepInfo = (WorkflowImplementationStepId) => {
  const options = {
    params: {
      WorkflowImplementationStepId,
    },
  };
  return api.sendGet(endpoints.GET_WORKFLOW_IMPL_STEP_INFO, options);
};

//* get workflow tasks info
export const getWorkflowImplementTasksInfo = (params) => {
  return api.sendGet(endpoints.GET_WORKFLOW_IMPLEMENT_TASKS, { params });
};

//* get workflow implement step
export const getWfImplInfo = (WorkflowImplementId) => {
  const options = {
    params: {
      WorkflowImplementId,
    },
  };
  return api.sendGet(endpoints.GET_WF_IMPL_INFO, options);
};

export const getWorkflowContentPane = (params) => {
  const option = { params };
  return api.sendGet(endpoints.GET_WORKFLOW_CONTENT_PANE, option);
};

export const acceptWorkflowStep = (params) => {
  return api.sendPost(endpoints.ACCEPT_WORKFLOW_STEP, params);
};

export const approveWorkflowStep = (params) => {
  return api.sendPost(endpoints.APPROVE_WORKFLOW_STEP, params);
};
export const acceptApproveWorkflowStep = (params) => {
  return api.sendPost(endpoints.ACCEPT_APPROVE_WORKFLOW_STEP, params);
};
export const createWorkflowService = (params) => {
  return api.sendPost(endpoints.CREATE_WORKFLOW_TEMPLATE, params);
};

export const deleteWorkflowService = (params) => {
  return api.sendPost(endpoints.DELETE_WORKFLOW_TEMPLATE, params);
};
// get all workflow templates to start workflow
export const getAllWorkflowTemplatesService = (params) => {
  return api.sendGet(endpoints.GET_ALL_WORKFLOW_TEMPLATES, { params: params });
};
// start workflow
export const startWorkflowService = (params) => {
  return api.sendPost(endpoints.START_WORKFLOW, params);
};

export const saveTasksValue = (params) => {
  return api.sendPost(endpoints.SAVE_TASK_VALUE, params);
};

export const addTaskValue = (params) => {
  return api.sendPost(endpoints.ADD_TASK_VALUE, params);
};

export const getAllWfRoles = (params) => {
  return api.sendPost(endpoints.GET_WORKFLOW_ROLES_GRID, params);
};

export const repeatableWorkflowStep = (params) => {
  return api.sendPost(endpoints.REPEAT_WORKFLOW_IMPLEMENTATION_STEP, params);
};
