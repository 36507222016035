import React, { useState, useEffect, useMemo } from 'react';
import { Form, message, Button, Typography } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditOutlined,
} from '@ant-design/icons';

import ChangePasswordModal from './ChangePasswordModal';
import SecurityLabel from './SecurityLabel';
import changePassSrc from 'assets/security/change_password.png';
import { formatMDY } from 'utils/formatDate';

import * as userProfileService from 'services/user';
import axios from 'axios';
import { useRefetchUserProfile } from 'pages/user-profile/components/hook/useFetchUserProfile';

import reactCSS from 'reactcss';
import moment from 'moment';

const { Text } = Typography;

const ChangePasswordLabel = (props) => {
  const { passwordExpiration } = props;

  return (
    <SecurityLabel
      labelImageSrc={changePassSrc}
      labelTitle='Password Expiration Date'
      detailText={passwordExpiration ? formatMDY(passwordExpiration) : 'N/A'}
      hintText='Please contact support team when your password expired.'
    />
  );
};

const ChangePasswordControl = (props) => {
  const { isAllowToChangePassword } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const [strengthTypeMode, setStrengthTypeMode] = useState('Any');
  const [loadingModal, setLoadingModal] = useState(false);

  const { fetch: fetchUserProfile } = useRefetchUserProfile();

  const [form] = Form.useForm();
  let isCancel = false;
  const signal = axios.CancelToken.source();

  const getUserPasswordStrength = () => {
    userProfileService
      .getUserPasswordStrengthType(signal.token)
      .then((response) => {
        if (response && response.passwordStrength && !isCancel) {
          setStrengthTypeMode(response.passwordStrength);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancel = () => {
    setErrorMessage('');
    form.resetFields();
    setChangePasswordModalVisible(false);
  };

  const handleOk = async (value) => {
    const { currentPassword, newPassword } = value;
    setLoadingModal(true);

    try {
      const response = await userProfileService.changeUserPassword({
        currentPassword,
        newPassword,
      });
      if (!response?.isSuccess) {
        setErrorMessage(response?.errorMessage);
        setLoadingModal(false);
      } else {
        setLoadingModal(false);
        successMessage('Your password has been changed successfully!');
        setErrorMessage('');
        handleCancel();
        fetchUserProfile();
      }
    } catch (error) {
      const errorText = error.message || errorMessage;
      setErrorMessage(errorText);
      setLoadingModal(false);
    }
  };

  const successMessage = (text) => {
    message.success(text);
  };

  const showChangePasswordModal = () => {
    setChangePasswordModalVisible(true);
  };

  useEffect(() => {
    if (changePasswordModalVisible) getUserPasswordStrength();
    return () => {
      // cancel api calling when unmounted
      signal.cancel();
      // eslint-disable-next-line
      isCancel = true;
    };
    // eslint-disable-next-line
  }, [changePasswordModalVisible]);

  return (
    <div>
      <Button
        type='primary'
        onClick={showChangePasswordModal}
        disabled={!isAllowToChangePassword}
        icon={<EditOutlined />}
      >
        Change password
      </Button>
      <ChangePasswordModal
        visible={changePasswordModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        loadingModal={loadingModal}
        strengthTypeMode={strengthTypeMode}
        errorMessage={errorMessage}
      />
    </div>
  );
};

const ChangePasswordStatus = (props) => {
  const { passwordExpiration } = props;

  const statusInfo = useMemo(() => {
    let result = {
      status: 'good',
      message: 'No expiration date',
    };

    if (!passwordExpiration) {
      return result;
    }

    const diff = moment(passwordExpiration).diff(new Date(), 'hours');

    if (diff < 168) {
      result.message = 'Expired ' + window.moment(passwordExpiration).fromNow();
      result.status = 'warning';
    } else {
      result.message =
        'Expired on ' + window.moment(passwordExpiration).calendar();
    }

    return result;
  }, [passwordExpiration]);

  const styles = reactCSS({
    default: {
      icon: {
        check: {
          color: '#389e0d',
          fontSize: 18,
          marginRight: 5,
        },
        close: {
          color: '#ffa940',
          fontSize: 18,
          marginRight: 5,
        },
        wrap: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  });

  return (
    <span style={styles.icon.wrap}>
      {statusInfo?.status === 'good' ? (
        <CheckCircleFilled style={styles.icon.check} />
      ) : (
        <CloseCircleFilled style={styles.icon.close} />
      )}
      <Text>{statusInfo?.message}</Text>
    </span>
  );
};

const ChangePassword = {
  Label: ChangePasswordLabel,
  Control: ChangePasswordControl,
  Status: ChangePasswordStatus,
};

export default ChangePassword;
