import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import EditCategory from './components/controls/maintenance/help/EditCategory';
import CreateCategory from './components/controls/maintenance/help/CreateCategory';
import DeleteCategory from './components/controls/maintenance/help/DeleteCategory';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import * as helpMaintenanceSelectors from 'pages/maintenance/controllers/selectors';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const HelpMaintenanceCategory = () => {
  const selectedCategory = useSelector(
    helpMaintenanceSelectors.selectSelectCategory()
  );

  const isCreating = useSelector(helpMaintenanceSelectors.selectIsCreate());

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.HELP_CATEGORY}>
        <DetailSection />
      </Can>
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.HELP_CATEGORY}>
        <EditCategory
          selectedCategory={selectedCategory}
          isCreating={isCreating}
        />
        <CreateCategory
          isSmallBtn
          selectedCategory={selectedCategory}
          isCreating={isCreating}
        />
        <DeleteCategory
          isSmallBtn
          selectedCategory={selectedCategory}
          isCreating={isCreating}
        />
      </Can>
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default HelpMaintenanceCategory;
