import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  edit: false,
  saving: false,
  loading: false,
  assetId: null,
  creating: false,
  updating: false,
  // asset-grid
  modeLinkAssets: false,
  replacePreview: false,
  uniqueId: null,
  canEdit: false,
};

const ribbonAssetFullReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.EDIT_ASSET:
        draft.edit = true;
        draft.saving = false;
        break;
      case types.CANCEL_EDIT_ASSET:
        draft.edit = false;
        draft.saving = false;
        draft.creating = false;
        break;
      case types.SAVING_ASSET_FULL:
        draft.saving = true;
        break;
      case types.SAVE_ASSET_FULL:
        draft.loading = true;
        draft.saving = false;
        break;
      case types.SAVE_ASSET_FULL_SUCCESS:
        draft.loading = false;
        break;
      case types.SAVE_ASSET_FULL_ERROR:
        draft.loading = false;
        break;
      case types.FINISH_EDIT_ASSET:
        draft.updating = true;
        break;
      case types.FINISH_EDIT_ASSET_SUCCESS:
        draft.edit = false;
        draft.updating = false;
        draft.creating = false;
        draft.loading = false;
        break;
      case types.FINISH_EDIT_ASSET_ERROR:
        draft.loading = false;
        break;
      case types.GET_ASSET_ID:
        draft.assetId = parseInt(action.payload.assetId, 10);
        break;
      case types.CREATING_ASSET:
        draft.creating = true;
        break;
      case types.CANCEL_CREATE_ASSET:
        draft.creating = false;
        break;
      case types.CANCEL_SAVING_ASSET:
        draft.saving = false;
        break;
      case types.ENABLE_MODE_LINK_ASSETS:
        draft.modeLinkAssets = true;
        break;
      case types.DISABLE_MODE_LINK_ASSETS:
        draft.modeLinkAssets = false;
        break;
      case types.REPLACE_PREVIEW:
        draft.replacePreview = true;
        break;
      case types.TOGGLE_REPLACE_PREVIEW:
        draft.replacePreview = !state.replacePreview;
        break;
      case types.CANCEL_REPLACE_PREVIEW:
        draft.replacePreview = false;
        break;
      case types.GET_UNIQUE_ID:
        draft.uniqueId = action.payload.uniqueId;
        break;
      case types.CLEAR_UNIQUE_ID:
        draft.uniqueId = null;
        break;
      case types.GET_CAN_EDIT:
        draft.canEdit = action.canEdit;
        break;
      default:
        break;
    }
  });

export default ribbonAssetFullReducer;
