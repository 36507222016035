import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { Spin } from 'antd';
import { AnonLogin, AnonViewWrap } from 'common/components';

import * as globalActions from 'redux/global/actions';
import * as userActions from 'redux/user/actions';
import userSelectors from 'redux/user/selectors';

import axios from 'axios';
import * as services from 'services/login';

import {
  clearAnnoInfo,
  getAnonToken,
  clearToken,
  getAnonInfo,
} from 'utils/common/session';

const AnonymousRoute = ({ component: Component, path, props, ...rest }) => {
  const [authenticated, setIsAuthenticated] = useState(false);
  const [isPreparing, setIsPreparing] = useState(true);

  const dispatch = useDispatch();

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const handleLogout = () => {
    /**
     * * forward to login first since
     * ? 1 - updated redux state by dispatch might not be detected by useEffect at some component when they were unmounted
     * ? 2 - some unmounted component need to fetch an api before to be unmounted will not work without bear token
     * todo - need to test further more
     */

    //* set false authenticated state when anon log out
    setIsAuthenticated(false);
    sessionStorage.removeItem('expandedKey');
    dispatch(globalActions.updateProgressBarSuccess([]));
    dispatch(globalActions.setSignalRConnectionSuccess(null));
    dispatch(globalActions.toggleChatView(false));
    dispatch(globalActions.toggleEmailView(false));
    //* add remove user info when log out
    dispatch(userActions.updateUserInfo(null));
    //* clear anon info when log out
    clearAnnoInfo();
  };

  useEffect(() => {
    const checkAnoymousAuth = () => {
      const isAnonValid = !!getAnonToken();
      const anonInfo = getAnonInfo();

      const href = anonInfo?.href;

      if (isAnonValid !== authenticated) {
        if (isAnonValid && userInfo?.isAnonymous) {
          //* condition for external meeting only
          if (href === window.location.href) {
            setIsAuthenticated(true);
          }
        } else {
          setIsAuthenticated(false);
        }
      }
    };

    checkAnoymousAuth();
  }, [userInfo, authenticated]);

  useEffect(() => {
    const checkAnonInfo = () => {
      const anonInfo = getAnonInfo();

      if (!anonInfo) {
        setIsPreparing(false);
      }

      dispatch(
        userActions.updateUserInfo({
          ...anonInfo,
          id: anonInfo?.userChatId,
          member: { id: null },
        })
      );

      setIsPreparing(false);
    };

    checkAnonInfo();
  }, [dispatch]);

  return (
    <Route {...rest}>
      {authenticated ? (
        <AnonViewWrap handleLogout={handleLogout}>
          <Component {...props} anonLogOut={handleLogout} />
        </AnonViewWrap>
      ) : isPreparing ? (
        <Spin
          spinning={isPreparing}
          tip='loading...'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <AnonLogin />
      )}
    </Route>
  );
};

export default AnonymousRoute;
