import { useMemo, useContext } from 'react';

import { NutritionContext } from '../context/nutritionContext';
import { useGetProductEnums } from 'hooks';

const useGetNutritionEnum = () => {
  const { productEnums } = useGetProductEnums();

  const nutritionEnum = useMemo(() => {
    const NUTRITION = productEnums?.filter(
      (type) => type.enumName === 'NutrientTypeCodeEnum'
    )?.[0]?.enumProperties;

    const productBasicUOM = productEnums?.filter(
      (type) => type.enumName === 'BasicUomEnum'
    );

    const measurementPrecisionCodeEnum = productEnums?.filter(
      (type) => type.enumName === 'MeasurementPrecisionCodeEnum'
    );

    const basicUomProperties = productBasicUOM?.[0]?.enumProperties ?? [];

    return {
      NUTRITION,
      productBasicUOM,
      measurementPrecisionCodeEnum,
      basicUomProperties,
    };
  }, [productEnums]);

  const {
    NUTRITION,
    productBasicUOM,
    measurementPrecisionCodeEnum,
    basicUomProperties,
  } = nutritionEnum;

  return {
    NUTRITION,
    productBasicUOM,
    measurementPrecisionCodeEnum,
    basicUomProperties,
  };
};

export const useNutritionContext = () => {
  const contextValue = useContext(NutritionContext);

  return contextValue;
};

export { useGetNutritionEnum };
