import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCommunications = (state) => state.communications || initialState;

const selectLoading = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.loading
  );

const selectColumns = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.columns
  );

const selectPaging = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.paging
  );

const selectCommunicationList = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.communications
  );

const selectCommunicationMode = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.mode
  );

const selectToggleSaveTemplate = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.isToggleSave
  );

const selectIdTemplate = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.idTemplate
  );

const selectDisableUploadMedia = () =>
  createSelector(
    selectCommunications,
    (communicationsState) => communicationsState.disableUploadMedia
  );

export {
  selectLoading,
  selectColumns,
  selectPaging,
  selectCommunicationList,
  selectCommunicationMode,
  selectToggleSaveTemplate,
  selectIdTemplate,
  selectDisableUploadMedia,
};
