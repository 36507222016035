import React from 'react';
import Messages from 'i18n/messages/maintenance';
import { UserAddOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const NewUserControl = (props) => {
  return (
    <RibbonButton
      size='small'
      icon={<UserAddOutlined />}
      label={Messages.newUser}
      {...props}
    />
  );
};

export default NewUserControl;
