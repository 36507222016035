import React, { useEffect } from 'react';

import { useNode, useEditor } from '@craftjs/core';
import { Typography, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import EmailEditorSectionAction from './EmailEditorSectionAction';

import { useEmailEditorContext } from '../../controllers/context';
import { updateDynamicContentByTag } from 'utils/email-editor';
import { EVENT } from 'static/Constants';

const { Text } = Typography;

const EmailEditorSection = (props) => {
  const { setDynamicContent } = useEmailEditorContext();
  const {
    id,
    connectors: { connect },
    selected,
    settings,
    displayName,
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
    hovered: state.events.hovered,
    settings: state.data.props.settings,
    displayName: state.data.displayName,
  }));

  const { enabled, actions } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { children, tags = [] } = props;

  const onClickDeleteBtn = () => {
    actions.delete(id);

    setDynamicContent((prevState) => {
      return updateDynamicContentByTag({
        prevState,
        tags,
        field: 'show',
        value: false,
      });
    });
    setDynamicContent((prevState) => {
      return updateDynamicContentByTag({
        prevState,
        tags,
        field: 'value',
        value: null,
      });
    });

    dispatchRemoveSectionEvent();
  };

  const highlightTag = () => {
    if (!selected) return;

    setDynamicContent((prevState) => {
      return updateDynamicContentByTag({ prevState, tags, field: 'highlight' });
    });
  };

  const dispatchRemoveSectionEvent = () => {
    const event = new CustomEvent(EVENT.EMAIL_EDITOR_REMOVE_SECTION, {
      detail: { tags },
    });

    document.dispatchEvent(event);
  };

  useEffect(() => {
    highlightTag();
  }, [selected]);

  const DeleteBtn = Button;

  return (
    <div
      ref={connect}
      className={classnames({
        'email-editor__section': enabled,
        'email-editor__section--selected': selected,
      })}
      style={{
        marginBottom: 16,
        fontWeight: settings?.bold ? 600 : 400,
        fontSize: `${settings?.fontSize}px`,
        fontStyle: settings?.italic ? 'italic' : 'normal',
        textDecoration: settings?.underline ? 'underline' : 'none',
        color: settings?.color,
      }}
    >
      {selected ? (
        <>
          <div className='email-editor__section-top-nail'>
            <Text className='email-editor__section-display-name'>
              {displayName}
            </Text>
          </div>
          <div className='email-editor__section-bottom-nail'>
            <DeleteBtn
              className='email-editor__section-delete-btn'
              type='primary'
              size='small'
              danger
              icon={<DeleteOutlined />}
              onClick={onClickDeleteBtn}
            ></DeleteBtn>
          </div>
        </>
      ) : null}
      <div>{children}</div>
      <EmailEditorSectionAction />
    </div>
  );
};

export default EmailEditorSection;
