import { useMemo } from 'react';

import _ from 'lodash';

import * as productServices from 'services/product';
import { NUMERIC_TYPE } from 'static/Constants';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';
import { useQuery } from '@tanstack/react-query';

const KEY_EXPORT_PRODUCT = {
  GET_PRODUCT_PROPERTIES_TO_EXPORT: [
    'pim',
    'get-product-properties-to-export-data',
  ],
};

const useGetPropertiesForExportProduct = (visible) =>
  useQuery({
    queryKey: KEY_EXPORT_PRODUCT.GET_PRODUCT_PROPERTIES_TO_EXPORT,
    queryFn: async () => {
      const response = await productServices.getProductPropertiesToExport();
      if (response?.isSuccess) {
        return response?.data?.properties;
      } else {
        throw new Error(response?.message);
      }
    },
    enabled: visible,
    retry: false,
  });

export const useGetProductPropertiesToExport = (visible) => {
  const intl = useIntl();

  const { status: loadingStatus, data: rootData } =
    useGetPropertiesForExportProduct(visible);

  const noHeaderLabel = intl.formatMessage(
    Messages.exportProductPropertiesNoHeaderTitle
  );

  const generateSourceData = (data) => {
    if (!data) return [];
    const moduleData = generateModuleData(data);
    const propertyItemData = generatePropertyItemData(moduleData, data);
    const objectTypePropertyData =
      generateOjectTypePropertiesData(propertyItemData);

    return [...moduleData, ...objectTypePropertyData, ...propertyItemData];
  };

  const generateModuleData = (data) => {
    if (!data) return [];
    const moduleData = data.map((propertyItem) => {
      return {
        key: propertyItem.moduleName || 'noHeader',
        title: propertyItem.moduleDisplayName || noHeaderLabel,
        type: 'group',
      };
    });

    const uniqData = _.uniqBy(moduleData, 'key');

    return uniqData;
  };

  const generatePropertyItemData = (moduleData, data) => {
    if (!moduleData || !data) return;

    const formattedPropertiesData = data.map((propertyItem) => {
      const { dataType, displayName, fieldFullPath, fieldName } = propertyItem;

      let changeInt;
      if (NUMERIC_TYPE.includes(dataType?.toLowerCase())) changeInt = 'Number';

      const propertyModuleName =
        propertyItem.moduleDisplayName || noHeaderLabel;

      const modulePath = moduleData.find((moduleItem) => {
        return moduleItem.title === propertyModuleName;
      })?.key;
      const propertyPathArray = fieldFullPath.split('.');
      //* remove the first path as it is module path
      if (propertyItem.moduleDisplayName) propertyPathArray.shift();

      const realPropertyPath = propertyPathArray.join('.');
      const formattedPropertyPath = modulePath + '.' + realPropertyPath;

      return {
        key: formattedPropertyPath,
        data: {
          displayName,
          fieldName,
          fieldFullPath,
          dataType: changeInt ?? dataType,
        },
      };
    });
    return formattedPropertiesData;
  };

  const generateOjectTypePropertiesData = (propertiesData) => {
    if (!propertiesData) return [];
    const objectTypePropertyData = [];
    propertiesData.forEach((propertyItem) => {
      const paths = propertyItem.key.split('.');
      if (paths.length <= 2) return;
      let pathString = '';

      for (let i = 0; i < paths.length - 1; i++) {
        pathString += paths[i];

        const isPathStringAdded = objectTypePropertyData.find(
          (propertyObjectItem) => propertyObjectItem.key === pathString
        );

        if (!isPathStringAdded && pathString.includes('.')) {
          objectTypePropertyData.push({
            key: pathString,
            fieldName: paths[i],
            title: _.startCase(paths[i]),
            type: 'object',
          });
        }
        pathString += '.';
      }
    });
    return objectTypePropertyData;
  };

  const sourceData = useMemo(() => {
    return generateSourceData(rootData);
  }, [rootData]);

  return { rootData, sourceData, loadingStatus };
};
