import * as types from './constants';

export const showLoading = () => {
  return {
    type: types.SHOW_LOADING,
  };
};

export function getMemberList(
  pageSize,
  pageNumber,
  search,
  atTime,
  advancedSearchContainer,
  searchCategory,
  filters,
  isFavoriteRoute
) {
  if (atTime)
    return {
      type: types.GET_MEMBER_LIST,
      fromDate: atTime,
      pageSize,
      pageNumber,
      'Search.SearchText': search,
      searchText: search,
      advancedSearchContainer,
      searchCategory,
      filters,
      isFavoriteRoute,
    };
  else
    return {
      type: types.GET_MEMBER_LIST,
      pageSize,
      pageNumber,
      'Search.SearchText': search,
      searchText: search,
      advancedSearchContainer,
      searchCategory,
      filters,
      isFavoriteRoute,
    };
}

export function getMemberListSuccess(members, total) {
  return {
    type: types.GET_MEMBER_LIST_SUCCESS,
    members,
    total,
  };
}

export function getMemberListError(error) {
  return {
    type: types.GET_MEMBER_LIST_ERROR,
    error,
  };
}

export function gridMemberColumnInfo(gridName) {
  return {
    type: types.MEMBER_GRID_COLUMN_INFO,
    gridName,
  };
}

export function gridMemberColumnInfoSuccess(columns) {
  return {
    type: types.MEMBER_GRID_COLUMN_INFO_SUCCESS,
    columns,
  };
}

export function gridMemberColumnInfoError(error) {
  return {
    type: types.MEMBER_GRID_COLUMN_INFO_ERROR,
  };
}

export function banListColumnInfo(gridName, userId) {
  return {
    type: types.BAN_LIST_GRID_COLUMN_INFO,
    gridName,
  };
}

export function banListColumnInfoSuccess(columns) {
  return {
    type: types.BAN_LIST_GRID_COLUMN_INFO_SUCCESS,
    columns,
  };
}

export function banListColumnInfoError(error) {
  return {
    type: types.BAN_LIST_GRID_COLUMN_INFO_ERROR,
  };
}

export function getMemberShortDetail(id) {
  return {
    type: types.GET_MEMBER_SHORT_DETAIL,
    id,
  };
}

export function getMemberShortDetailSuccess(data) {
  return {
    type: types.GET_MEMBER_SHORT_DETAIL_SUCCESS,
    data,
  };
}

export function getMemberShortDetailError(error) {
  return {
    type: types.GET_MEMBER_SHORT_DETAIL_ERROR,
    error,
  };
}

// Products for member
export function getProductsForMember(params) {
  return {
    type: types.GET_PRODUCTS_FOR_MEMBER,
    params,
  };
}

export function getProductsForMemberSuccess(data) {
  return {
    type: types.GET_PRODUCTS_FOR_MEMBER_SUCCESS,
    data,
  };
}

export function getProductsForMemberError(error) {
  return {
    type: types.GET_PRODUCTS_FOR_MEMBER_ERROR,
    error,
  };
}

export function resetProductsForMemberState() {
  return {
    type: types.RESET_PRODUCT_FOR_MEMBER_STATE,
  };
}

export function getColumnsProductsForMember(gridName) {
  return {
    type: types.GET_COLUMNS_PRODUCTS_FOR_MEMBER,
    gridName,
  };
}

export function getColumnsProductsForMemberSuccess(columns) {
  return {
    type: types.GET_COLUMNS_PRODUCTS_FOR_MEMBER_SUCCESS,
    columns,
  };
}

export function getColumnsProductsForMemberError(error) {
  return {
    type: types.GET_COLUMNS_PRODUCTS_FOR_MEMBER_ERROR,
    error,
  };
}

export function saveIdMember(id) {
  return {
    type: types.SAVE_ID_MEMBER,
    id,
  };
}

export function getPublicContact(memberId) {
  return {
    type: types.GET_PUBLIC_CONTACT_MEMBER,
    memberId,
  };
}

export function getPublicContactSuccess(data) {
  return {
    type: types.GET_PUBLIC_CONTACT_MEMBER_SUCCESS,
    data,
  };
}

export function getPublicContactError(error) {
  return {
    type: types.GET_PUBLIC_CONTACT_MEMBER_ERROR,
    error,
  };
}

export function removeBanList(data) {
  return {
    type: types.REMOVE_BAN_LIST,
    data,
  };
}

export function removeBanListSuccess(data) {
  return {
    type: types.REMOVE_BAN_LIST_SUCCESS,
    data,
  };
}

export function removeBanListError(error) {
  return {
    type: types.REMOVE_BAN_LIST_ERROR,
    error,
  };
}
