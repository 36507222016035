import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import history from './utils/common/history';

// Router Import
import { ConnectedRouter } from 'connected-react-router';

// Import i18n messages
import { translationMessages } from './i18n';

import { applyApiDefaults } from 'config/axios';
import { DEFAULT_TIMEOUT } from 'static/Constants';
import { API_ENDPOINT } from './config/env';

// Load the favicon and the .htaccess file
//import '!file-loader?name=[name].[ext]!./images/favicon.ico';
//import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import configureStore from './configureStore';

import './index.less';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';

// casl
import ability from './common/casl/ability';
import { AbilityContext } from './context/Can';

import { customQueryFn } from 'config/axios';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
export default store;
const MOUNT_NODE =
  document.getElementById('root') || document.createElement('div');

// init axios default baseURL
// set default timeout
applyApiDefaults(
  {
    baseURL: API_ENDPOINT,
    timeout: DEFAULT_TIMEOUT,
  },
  store
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      queryFn: customQueryFn,
    },
  },
});

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <AbilityContext.Provider value={ability}>
        <ConfigProvider>
          <ConnectedRouter history={history}>
            <QueryClientProvider client={queryClient}>
              <App messages={messages} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ConnectedRouter>
        </ConfigProvider>
      </AbilityContext.Provider>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('@formatjs/intl-relativetimeformat/polyfill'),
        import('@formatjs/intl-relativetimeformat/dist/locale-data/en'),
        import('@formatjs/intl-relativetimeformat/dist/locale-data/vi'),
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
