import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, notification } from 'antd';
import { QueryTileBody, QueryTileFooter, QueryTileHeader } from '../index';
import { get } from 'lodash';
import useDoubleClick from 'hooks/useDoubleClick';
import './QueryTile.less';

import { forwardTo } from 'utils/common/route';

import { RIBBON_TYPES } from 'static/Constants';
import * as actionsRibbon from 'redux/ribbon/actions';
import * as ribbonSelectors from 'redux/ribbon/selectors';
import * as actionsGlobal from 'redux/global/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import { updateSearchText } from 'pages/home/utils';

import {
  updateQueryConditions,
  updateQueryAdvance,
  updateIdQuery,
} from 'utils/queryCondition';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

const QueryTile = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { dataDetail, disableEvent } = props;
  const { onClickItemGrid } = props.clickEvents;
  const name = get(dataDetail, 'name', '');

  const pathname = window.location.pathname;
  const { queryConditions, columns, ribbonTypes, urlForwardTo, searchText } =
    JSON.parse(dataDetail?.query);

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  const divRef = React.useRef();
  useDoubleClick({
    onSingleClick: (e) => {
      if (onClickItemGrid) onClickItemGrid(dataDetail, e);
    },
    onDoubleClick: (e) => {
      const invalidQuery = !urlForwardTo;

      if (invalidQuery) {
        showErrorMessage();
        return;
      }

      pushCloseDetailStack(urlForwardTo);
      forwardTo(`${urlForwardTo}?query=1`);

      dispatch(actionsGridView.myQueryDoubleClick(true));
      if (ribbonTypes === RIBBON_TYPES.DETAILSVIEW) {
        dispatch(actionsGridView.chooseGridColumns(columns, dataDetail?.id));
      } else {
        dispatch(actionsGridView.chooseGridColumns([], dataDetail?.id));
      }

      updateQueryConditions(
        dispatch,
        queryConditions,
        window.location.pathname,
        true
      );
      updateQueryAdvance(
        dispatch,
        queryConditions,
        window.location.pathname,
        true
      );
      updateIdQuery(dispatch, dataDetail?.id, window.location.pathname, true);

      dispatch(actionsRibbon.changeType(ribbonTypes));
      dispatch(actionsGridView.updateVisible(false));
      dispatch(actionsGlobal.updateIsBackFromQuery(true));
      updateSearchText(dispatch, searchText, urlForwardTo);
      dispatch(actionsGridView.checkQueryCondition(true));

      setTimeout(() => {
        dispatch(actionsGridView.toggleRefreshQuery(true));
        dispatch(actionsGridView.updatePrevPathnameQuery(urlForwardTo));
      }, 200);
    },
    ref: divRef,
    latency: 250,
  });

  const showErrorMessage = () => {
    notification.error({
      message: intl.formatMessage(Messages.queryOpenError),
    });
  };

  const pushCloseDetailStack = (urlForwardTo) => {
    const { name, stack } = closeDetailStack;
    dispatch(
      actionsRibbon.updateCloseDetailStack({
        name,
        stack: [...stack, urlForwardTo],
      })
    );
  };

  return (
    <Tooltip title={name}>
      <div className='query-tile'>
        {/*Placeholder for click events*/}
        <div
          style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            zIndex: 30,
            width: '100%',
            height: '100%',
          }}
          ref={divRef}
        />
        <QueryTileHeader dataDetail={dataDetail} />
        <QueryTileBody dataDetail={dataDetail} />
        <QueryTileFooter dataDetail={dataDetail} />
      </div>
    </Tooltip>
  );
};

export default QueryTile;
