import React from 'react';
import PropTypes from 'prop-types';
import { Row, Space, Avatar } from 'antd';
import { Images } from 'config/assets';
const ProductIdentification = (props) => {
  const { dataDetail } = props;

  return (
    <Row justify='center' className='product-info__identification'>
      <Space>
        <Avatar
          shape='square'
          className='product-info__identification-image'
          src={(dataDetail && dataDetail.imageUrl) || Images.RIVIR_LOGO_DEFAULT}
          alt='icon1'
        />
        <Avatar
          shape='square'
          className='product-info__identification-image'
          src={(dataDetail && dataDetail.imageUrl) || Images.RIVIR_LOGO_DEFAULT}
          alt='icon2'
        />
      </Space>
    </Row>
  );
};

ProductIdentification.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductIdentification;
