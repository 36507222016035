import React from 'react';

import {
  QuerySection,
  LayoutSection,
  ClearSection,
  SaveSection,
} from './components/sections/advanced-search';
import { OtherLayout } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import OpenResultControl from './components/controls/advanced-search/OpenResultControl';
import { useFolderByLevel } from 'hooks/useFolderByLevel';

const ReportingFullViewRibbon = (props) => {
  const {
    type,
    disabledSaveQuery,
    disabledExportQuery,
    disableRun,
    onClickRibbonBtn,
    modeView,
    selectedResult,
    isOwner,
  } = props;

  const { shouldKeepFolderBreadcrumb, keepFolderBreadcrumb } =
    useFolderByLevel();

  const closeDetailCallback = () => {
    //* keep folder breadcrumb when close folder item detail
    shouldKeepFolderBreadcrumb && keepFolderBreadcrumb(true);
  };

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <RibbonDivider />

      <QuerySection
        disableRun={disableRun}
        onClickRibbonBtn={onClickRibbonBtn}
        disabledExportQuery={disabledExportQuery}
      />
      <SaveSection
        disableSave={disabledSaveQuery}
        onClickRibbonBtn={onClickRibbonBtn}
        modeView={modeView}
        isOwner={isOwner}
      />
      <RibbonDivider />
      <LayoutSection
        onClickRibbonBtn={onClickRibbonBtn}
        type={type}
        modeView={modeView}
      />
      <OpenResultControl
        type={type}
        selectedResult={selectedResult}
        onClickRibbonBtn={onClickRibbonBtn}
      />
      <RibbonDivider />
      <AdvanceStack isDisabled={true} />
      <OtherLayout
        ribbonSide='right'
        ribbonType='reportings'
        closeDetailCallback={closeDetailCallback}
      />
    </RibbonBar>
  );
};

ReportingFullViewRibbon.propTypes = {};

export default ReportingFullViewRibbon;
