import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import selectorUser from 'redux/user/selectors';

import { checkIsSuperAdmin, checkOwnerOneItem } from 'utils';

import { useCheckPermissionOR } from './useCheckPermissions';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useGetProductFullView } from './product-full/hooks';
import { checkEditForSupplier } from 'pages/qa-spec/hooks';

const PERMISSION_MANAGE_GROUP_DATA = [
  [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.PRODUCT],
  [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.QA_SPECIFICATION],
];

export const useCheckAllowManageGroupData = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo()) || {};
  const paramProduct = useParams();

  const { id, productId } = paramProduct ?? {};

  const { productFull } = useGetProductFullView({
    productId: id || productId,
  });

  const checkPermissionOr = useCheckPermissionOR();
  const isAllowMangeGroup = checkPermissionOr(PERMISSION_MANAGE_GROUP_DATA);

  const { roles, isSuperMember } = userInfo ?? {};
  const isSuperAdmin = checkIsSuperAdmin(roles);
  const isOwner = checkOwnerOneItem(productFull);

  const hasEditPermissionForSupplier = checkEditForSupplier({
    productFull,
    companyInfo: userInfo,
  });

  const checkAllowManageGroupData = () => {
    if (isSuperAdmin) return true;

    if (!isAllowMangeGroup) return false;

    if (isSuperMember) return true;

    if (isOwner) return true;

    if (hasEditPermissionForSupplier) return true;

    return false;
  };

  return checkAllowManageGroupData();
};
