import React from 'react';
import { useSelector } from 'react-redux';

import { Divider, Col } from 'antd';

import { ViewLayout, AdvanceStack, OtherLayout } from './components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import SubscriptionActionSection from './components/sections/product/SubscriptionActionSection';
import { RibbonBar, RibbonDivider } from 'common/components';

import * as productSelectors from 'pages/product-full-view/controllers/selectors';

const ProductFullViewSubscriptionRibbon = () => {
  const selectionSubscription = useSelector(
    productSelectors.makeSelectSelectionSubscription()
  );

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <RibbonDivider />
      <SubscriptionActionSection
        selectionSubscription={selectionSubscription}
        hideAccept
        hideReject
        activeRevoke
      />
      <RibbonDivider />
      <AdvanceStack isDisabled={true} />
      <Col flex='auto' />
      <OtherLayout ribbonType='products' isHaveAddOpenItem={true} />
    </RibbonBar>
  );
};

export default ProductFullViewSubscriptionRibbon;
