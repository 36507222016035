import { TYPES_CONVERT_PDF_PREVIEW } from 'static/FileType';

export const checkShowPdfPreview = (pdfPreview, linkUrl, fileType) => {
  const isPdfPreview =
    TYPES_CONVERT_PDF_PREVIEW.indexOf(fileType) > -1 || pdfPreview;

  const extensionFile = isPdfPreview ? 'pdf' : fileType;

  return {
    linkPreview: isPdfPreview ? pdfPreview : linkUrl,
    extensionFile,
  };
};
