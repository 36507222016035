import React from 'react';

import { Row, Col, Button } from 'antd';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/product';

const CreateProductModalFooter = (props) => {
  const intl = useIntl();

  const { onCancel, onOk, okLoading, okText = 'Submit' } = props;

  const OkButton = Button;
  const CancelButton = Button;

  return (
    <Row justify='end'>
      <Col>
        <CancelButton onClick={onCancel}>
          {intl.formatMessage(messages.cancelBtn)}
        </CancelButton>
        <OkButton type='primary' onClick={onOk} loading={okLoading}>
          {okText}
        </OkButton>
      </Col>
    </Row>
  );
};

export default CreateProductModalFooter;
