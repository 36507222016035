export const GET_TICKETING_SYSTEM_GRID = '/api/ticket/get-ticket-grid';
export const GET_TICKETING_SYSTEM_GRID_DISTINCT =
  '/api/ticket/get-ticket-grid/distinct';
export const GET_TICKETING_SYSTEM_SHORT_DETAIL =
  '/api/ticket/get-ticket-short-detail';
export const DELETE_TICKETING_SYSTEM = '/api/ticket/delete-ticket';
export const GET_ALL_TICKET_TAGS = '/api/ticket/get-all-ticket-tags';
export const UPDATE_LAST_TIME_GET_NEW_TICKET =
  '/api/ticket/update-last-time-get-new-tickets';
export const EXPORT_TICKET_GRID = '/api/ticket/get-ticket-grid/download';

export const GET_TICKET_ASSIGNMENT = 'api/ticket/get-available-assignments';
export const GET_TICKET_SUPPLIERS =
  'api/ticket/get-suppliers-for-ticket-contact';
export const GET_TICKET_PRODUCT = 'api/ticket/get-ticket-products';
export const GET_TICKET_ASSET = 'api/ticket/get-ticket-assets';
export const SEARCH_TICKET_PRODUCT = 'api/ticket/search-products';
export const SEARCH_TICKET_ASSET = 'api/ticket/search-digital-assets';

export const GET_TICKET_FULL_VIEW = 'api/ticket/get-ticket-details-full-view';
export const SAVE_TICKET_DETAIL = 'api/ticket/save-ticket-detail';
export const UPLOAD_TICKET_ATTACHMENTS =
  '/api/ticket/upload-ticket-attachments';

export const GET_TICKET_ATTACHMENTS = '/api/ticket/get-ticket-attachments';

export const SAVE_TICKET_ATTACHMENTS = '/api/ticket/save-ticket-attachments';

export const UPLOAD_TICKET_IMAGE = '/api/ticket/upload-ticket-image';
export const GET_LIST_TICKET_GROUP = '/api/ticket/get-list-ticket-groups';
export const SAVE_TICKET_GROUP = '/api/ticket/save-ticket-group';
export const DELETE_TICKET_GROUP = '/api/ticket/delete-ticket-group';
export const GET_USER_IN_TICKET_GROUP = '/api/ticket/get-users-in-ticket-group';
export const GET_USER_IN_TICKET_GROUP_DISTINCT =
  '/api/ticket/get-users-in-ticket-group/distinct';
export const GET_USER_NOT_IN_TICKET_GROUP =
  '/api/ticket/get-users-not-in-ticket-group';
export const GET_USER_NOT_IN_TICKET_GROUP_DISTINCT =
  '/api/ticket/get-users-not-in-ticket-group/distinct';
export const ADD_USER_IN_TICKET_GROUP = '/api/ticket/add-users-to-ticket-group';
export const REMOVE_USER_FROM_TICKET_GROUP =
  '/api/ticket/remove-users-from-ticket-group';
export const UPDATE_FOLLOW_TICKET = '/api/ticket/update-following-ticket';

export const GET_TICKET_CHANGELOG_LIST = '/api/ticket/get-all-ticket-history';
export const GET_TICKET_CHANGELOG_DETAIL =
  '/api/ticket/get-ticket-history-detail';

export const GET_TICKET_COMMENTS = '/api/ticket/get-ticket-comments';
export const SAVE_TICKET_COMMENT = '/api/ticket/save-ticket-comment';
export const DELETE_TICKET_COMMENT = '/api/ticket/delete-ticket-comment';
