import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';

import {
  getQaSpecDataManufacturingProcess,
  getQaSpecDataManufacturingProcessDocuments,
  getQaSpecDataManufacturingLocation,
  saveQaSpecCompanyLocation,
  addQaSpecDataManufacturingLocation,
  deleteQaSpecDataManufacturingLocation,
} from 'services/qaSpec';

import * as productServices from 'services/product';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import { forwardTo } from 'utils/common/route';

export const getQaManufacturingDocumentQueryKey = (productId) => {
  return [
    'qa-spec',
    parseInt(productId),
    'qa-spec-data-manufacturing-process-documents',
  ];
};
export const getQaManufacturingLocationQueryKey = (productId) => {
  return [
    'qa-spec',
    parseInt(productId),
    'qa-spec-data-manufacturing-locations',
  ];
};
export const getQaManufacturingStagQueryKey = (productId) => {
  return [
    'qa-spec',
    parseInt(productId),
    'qa-spec-data-manufacturing-processes',
  ];
};

export const getAddedLocationMutationKey = () => {
  return ['add-qa-spec-data-manufacturing-location'];
};

export const getDeletedLocationMutationKey = () => {
  return ['delete-qa-spec-data-manufacturing-location'];
};

const mappingData = (data) => {
  return data.map((item) => ({
    ...item,
    id: uuidv4(),
    errors: [],
  }));
};

export const useManufacturingStageData = (productId) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: getQaManufacturingStagQueryKey(productId),
    queryFn: async () => {
      const response = await getQaSpecDataManufacturingProcess({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
    },
  });

  const refetchManufacturingStageData = () => {
    queryClient.invalidateQueries({
      queryKey: getQaManufacturingStagQueryKey(productId),
    });
  };

  return {
    rowData: mappingData(query?.data?.processes ?? []),
    isFetchingGrid: query?.isFetching,
    refetchManufacturingStageData,
  };
};

export const useManufacturingStageDocuments = (productId) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: getQaManufacturingDocumentQueryKey(productId),
    queryFn: async () => {
      const response = await getQaSpecDataManufacturingProcessDocuments({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
    },
  });

  const refetchManufacturingStageDocuments = () => {
    queryClient.invalidateQueries({
      queryKey: getQaManufacturingDocumentQueryKey(productId),
    });
  };

  return {
    documents: query?.data?.documents ?? [],
    isFetchingGrid: query?.isFetching,
    refetchManufacturingStageDocuments,
  };
};

export const useManufacturingLocation = (productId) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: getQaManufacturingLocationQueryKey(productId),
    queryFn: async () => {
      const response = await getQaSpecDataManufacturingLocation({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
    },
  });

  const refetchManufacturingLocation = () => {
    queryClient.invalidateQueries({
      queryKey: getQaManufacturingLocationQueryKey(productId),
    });
  };

  return {
    rowData: query?.data?.locations ?? [],
    isFetching: query?.isFetching,
    refetchManufacturingLocation,
  };
};

export const useSaveManufacturingLocation = () => {
  const saveManufacturingLocation = async (params) => {
    const response = await saveQaSpecCompanyLocation(params);

    return Promise.resolve(response);
  };

  const saveManufacturingLocationMutation = useMutation({
    mutationKey: ['save-qa-spec-data-manufacturing-location'],
    mutationFn: saveManufacturingLocation,
  });

  return saveManufacturingLocationMutation;
};
export const useAddManufacturingLocation = () => {
  const addManufacturingLocation = async (params) => {
    return await addQaSpecDataManufacturingLocation(params);
  };

  const addManufacturingLocationMutation = useMutation({
    mutationKey: getAddedLocationMutationKey(),
    mutationFn: addManufacturingLocation,
  });

  return addManufacturingLocationMutation;
};

export const useDeleteManufacturingLocation = () => {
  const deleteManufacturingLocation = async (params) => {
    return await deleteQaSpecDataManufacturingLocation(params);
  };

  const deleteManufacturingLocationMutation = useMutation({
    mutationKey: getDeletedLocationMutationKey(),
    mutationFn: deleteManufacturingLocation,
  });

  return deleteManufacturingLocationMutation;
};

// Snapshot
export const qaManufacturingLocationsSnapshotKeys = {
  getManufacturingLocations: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-manufacturing-locations',
  ],
};

export const useQaGetManufacturingLocationsSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey:
      qaManufacturingLocationsSnapshotKeys.getManufacturingLocations(productId),
    queryFn: async () => {
      const response =
        await productServices.getQaProductManufacturingLocationsSnapshot({
          productId,
        });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    qaManufacturingLocationsSnapshot: queries?.data?.locations ?? [],
    ...queries,
  };
};

export const qaProcessStageSnapshotKeys = {
  getQaProcessStage: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-process-stage',
  ],
};

export const useQaGetProcessStageSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey: qaProcessStageSnapshotKeys.getQaProcessStage(productId),
    queryFn: async () => {
      const response = await productServices.getQaGetProcessStageSnapshot({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    qaProcessStageSnapshot: queries?.data?.processes ?? [],
    ...queries,
  };
};

export const qaManufacturingDocumentsSnapshotKeys = {
  getQaManufacturingDocuments: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-manufacturing-documents',
  ],
};

export const useQaManufacturingDocumentsSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey:
      qaManufacturingDocumentsSnapshotKeys.getQaManufacturingDocuments(
        productId
      ),
    queryFn: async () => {
      const response =
        await productServices.getQaManufacturingDocumentsSnapshot({
          productId,
        });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    qaManufacturingDocumentsSnapshot: queries?.data?.documents ?? [],
    ...queries,
  };
};
