import React, { useState } from 'react';

import { ReactComponent as IconAddFolder } from 'common/components/button/svg-icons/add-folder.svg';

import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/home';
import CreateFolderModal from './CreateFolderModal';

const CreateFolderDetail = (props) => {
  const { selectedFolders, onReloadWhenSaveSuccess, disabled } = props;

  const createFolderModalVisibleHook = useState(false);
  const [, setVisibleModal] = createFolderModalVisibleHook;

  const openNewFolderModal = () => {
    setVisibleModal(true);
  };

  return (
    <>
      <RibbonButton
        size='small'
        icon={<IconAddFolder />}
        label={Messages.newFolder}
        onClick={openNewFolderModal}
        disabled={disabled}
      />

      <CreateFolderModal
        visibleHook={createFolderModalVisibleHook}
        onReloadWhenSaveSuccess={onReloadWhenSaveSuccess}
        parentId={selectedFolders?.id}
        skipNotification
      />
    </>
  );
};

export default CreateFolderDetail;
