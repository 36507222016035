import React, { forwardRef, useRef } from 'react';

import { Button, Carousel } from 'antd';

import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

export const CarouselNext = (props) => {
  return <Button type='primary' icon={<CaretRightOutlined />} {...props} />;
};

export const CarouselPrevious = (props) => {
  return <Button type='primary' icon={<CaretLeftOutlined />} {...props} />;
};

export const CarouselSection = forwardRef((props, ref) => {
  const {
    currentSlide = 0, // first slide
    slidesToShow = 1,
    slidesToScroll = 1,
    children,
    ...rest
  } = props;
  return (
    <Carousel
      dots={false}
      ref={ref}
      currentSlide={currentSlide}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToScroll}
      {...rest}
    >
      {children}
    </Carousel>
  );
});

export const useCarousel = () => {
  const carouselRef = useRef();

  const onPrevCarousel = () => {
    if (carouselRef?.current) carouselRef.current.prev();
  };

  const onNextCarousel = () => {
    if (carouselRef?.current) carouselRef.current.next();
  };

  return {
    carouselRef,
    onNextCarousel,
    onPrevCarousel,
  };
};
