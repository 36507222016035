import { editAplProductContactService } from 'services/aplDetail';
import isEmpty from 'lodash/isEmpty';

export const getListIdsNeedToUpdateEmail = (
  questionList,
  productSendingStatusList
) => {
  let ids = [];

  if (!isEmpty(questionList)) {
    for (const [key, value] of Object.entries(questionList)) {
      if (value?.isDraft && productSendingStatusList[key] === 'success') {
        ids = ids.concat(parseInt(key));
      }
    }
  }

  return ids;
};

export const getListParamsUpdateEmail = (productList, ids, questionList) => {
  let params = [];

  if (productList.length > 0) {
    productList.forEach((product) => {
      const { id, contactName, contactPhone, category } = product;
      if (ids.includes(id)) {
        params = params.concat({
          aplProductId: id,
          contactEmail: questionList[id].email,
          contactName,
          contactPhone,
          category,
        });
      }
    });
  }

  return params;
};

export const getListApiUpdateEmail = (params) => {
  let apis = [];

  params.forEach((param) => {
    apis.push(editAplProductContactService(param));
  });

  return apis;
};

export const getListProductReadySend = (productSendingStatusList) => {
  let products = [];

  if (!isEmpty(productSendingStatusList)) {
    for (const [key, value] of Object.entries(productSendingStatusList)) {
      if (value === 'success') {
        products = products.concat(parseInt(key));
      }
    }
  }

  return products;
};

export const getListProductNotReadySend = (productSendingStatusList) => {
  let products = [];

  if (!isEmpty(productSendingStatusList)) {
    for (const [key, value] of Object.entries(productSendingStatusList)) {
      if (value !== 'success') {
        products = products.concat(parseInt(key));
      }
    }
  }

  return products;
};

export const disabledSendQuestions = (productStatusList) => {
  if (!isEmpty(productStatusList)) {
    const listStatus = Object.values(productStatusList);

    return listStatus.every((status) => status === null || status === 'error');
  }

  return false;
};
