/*
 * i18n Messages page
 *
 * This contains all the text for the Messages page component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.messages.notification';

export default defineMessages({
  tip: {
    id: `${scope}.loading.tip`,
    defaultMessage: 'Checking Notifications...',
  },
  addSocialLinkModalTitle: {
    id: `${scope}.socialLink.addSocialLink.modalTitle`,
    defaultMessage: 'Add new social link',
  },
  addSocialOkText: {
    id: `${scope}.socialLink.addSocialLink.okText`,
    defaultMessage: 'Add',
  },
  addSocialCancelText: {
    id: `${scope}.socialLink.addSocialLink.cancelText`,
    defaultMessage: 'Close',
  },
  addSocialTypeErrorMessage: {
    id: `${scope}.socialLink.addSocialLink.errorMessage.type`,
    defaultMessage: 'Please select one.',
  },
  addSocialUrlErrorMessage: {
    id: `${scope}.socialLink.addSocialLink.errorMessage.url`,
    defaultMessage: 'Please input url.',
  },
  addSocialInvalidUrlErrorMessage: {
    id: `${scope}.socialLink.addSocialLink.errorMessage.urlInvalid`,
    defaultMessage: 'Invalid URL.',
  },
  addSocialLinkErrorMessage: {
    id: `${scope}.socialLink.errorMessage`,
    defaultMessage: 'Cannot add new social link',
  },
  addSocialLinkSuccessMessage: {
    id: `${scope}.socialLink.successMessage`,
    defaultMessage: 'Add new social link successfully',
  },
  deleteSocialLinkConfirmMessage: {
    id: `${scope}.socialLink.deleteSocialLink.confirmMessage`,
    defaultMessage: 'Are you sure to delete?',
  },
  acceptDelete: {
    id: `${scope}.socialLink.deleteSocialLink.accept`,
    defaultMessage: 'Yes',
  },
  cancelDelete: {
    id: `${scope}.socialLink.deleteSocialLink.cancel`,
    defaultMessage: 'No',
  },
  deleteSocialLinkSuccessMessage: {
    id: `${scope}.socialLink.deleteSocialLink.successMessage`,
    defaultMessage: 'Delete successfully!',
  },
  deleteSocialLinkErrorMessage: {
    id: `${scope}.socialLink.deleteSocialLink.errorMessage`,
    defaultMessage: 'Cannot delete',
  },
  updateSuccessMsg: {
    id: `${scope}.update.success`,
    defaultMessage: `Update successfully`,
  },
  updateFailMsg: {
    id: `${scope}.update.fail`,
    defaultMessage: `Update fail. Please try again`,
  },
  updateLogoSuccessMsg: {
    id: `${scope}.logo.update.success`,
    defaultMessage: `Upload logo successfully`,
  },
});
