import React from 'react';

const TableRender = ({ tableData }) => {
  const haveLeftBorder = (index, length) => index === 0;

  if (tableData) {
    return (
      <div className='md-enhance-markdown-table'>
        <div
          className='tr'
          style={{
            display: 'flex',
          }}
        >
          {tableData.columns.map((headItem, idx) => {
            if (idx !== headItem.length - 1) {
              return (
                <div
                  className='th'
                  style={{
                    minWidth: headItem?.width ? parseInt(headItem?.width) : '',
                    maxWidth: headItem?.width ? parseInt(headItem?.width) : '',
                    margin: '0',
                    padding: '0.5rem',
                    position: 'relative',
                    whiteSpace: 'pre-wrap',
                    textAlign: 'left',
                    display: 'inline-block',
                    border: 'solid 1px #dde2eb',
                    borderWidth: `1px 3px 1px ${
                      haveLeftBorder(idx) ? '1px' : '0'
                    }`,
                    background: '#3a7d90',
                    color: 'white',
                  }}
                  key={'head-item' + idx}
                >
                  {headItem?.Header}
                </div>
              );
            } else {
              return (
                <div
                  className='th'
                  style={{
                    minWidth: headItem?.width ? parseInt(headItem?.width) : '',
                    margin: '0',
                    padding: '0.5rem',
                    background: '#fafafa',
                    position: 'relative',
                    whiteSpace: 'pre-wrap',
                    border: 'solid 1px #dde2eb',
                    borderWidth: `1px 1px 1px ${
                      haveLeftBorder(idx) ? '1px' : '0'
                    }`,
                  }}
                  key={'head-item' + idx}
                >
                  {headItem?.Header}
                </div>
              );
            }
          })}
        </div>

        <div className='tb'>
          {tableData.data.map((rowItem, rowIdx) => (
            <div
              className='tr'
              key={'row-item' + rowIdx}
              style={{
                minHeight: 41,
                display: 'flex',
              }}
            >
              {tableData.columns.map((headItem, idx) => {
                return (
                  <div
                    className='td'
                    key={'cell-item' + idx}
                    style={{
                      margin: 0,
                      padding: '0.5rem',
                      position: 'relative',
                      whiteSpace: 'pre-wrap',
                      border: 'solid 1px #dde2eb',
                      borderWidth: `0 3px 1px ${
                        haveLeftBorder(idx) ? '1px' : '0'
                      }`,
                      minWidth: headItem?.width
                        ? parseInt(headItem?.width)
                        : '',
                      maxWidth: headItem?.width
                        ? parseInt(headItem?.width)
                        : '',
                      background: rowIdx % 2 ? '#ecf3f3' : 'white',
                    }}
                  >
                    {rowItem[headItem?.accessor]}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <span>![@table](unknown table)</span>;
  }
};

export default TableRender;
