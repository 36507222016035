/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import classnames from 'classnames';

import { Row, Avatar } from 'antd';
import { Images } from 'config/assets';

import { useImageLoaded } from 'hooks';

const ProductTileHeader = (props) => {
  const { productName, thumbnail } = props;

  const [ref, loaded, onLoad] = useImageLoaded();

  console.log('loaded', { loaded });

  return (
    <Row className='product-tile__header' justify='center'>
      <div className='product-tile__header-background'></div>

      <div className='product-tile__header-image-wrapper'>
        <div
          shape='square'
          style={{ borderRadius: '4px' }}
          className={classnames({
            'product-tile__header-image': true,
            'product-tile__header-image-loading': !loaded,
          })}
        >
          <img
            style={{ width: '100%', height: '100%' }}
            ref={ref}
            src={thumbnail || Images.RIVIR_LOGO_DEFAULT}
            onLoad={onLoad}
            loading='lazy'
            alt={productName}
          />
        </div>
      </div>
    </Row>
  );
};

export default ProductTileHeader;
