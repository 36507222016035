import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { RibbonButton, StyledModal, AgGrid } from 'common/components';

import { CheckCircleOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/maintenance';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import * as eulaMaintenanceEndpoints from 'services/eulaMaintenance/endpoints';

import { formatMDYWithParam } from 'utils/formatDate';

const ViewAcceptance = () => {
  const [visible, setVisible] = useState(false);

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const isDisabledBtn = detailCurrentItemsSelection?.length !== 1;

  return (
    <>
      <RibbonButton
        icon={<CheckCircleOutlined />}
        label={Messages.viewAcceptance}
        disabled={isDisabledBtn}
        onClick={() => setVisible(true)}
      />

      {visible && (
        <ViewAcceptanceModal
          visible={visible}
          onCancel={() => setVisible(false)}
          eulaId={detailCurrentItemsSelection?.[0]?.id}
        />
      )}
    </>
  );
};

const propertiesCol = {
  allowFilter: true,
  allowSort: true,
  resizable: true,
};

const defaultColumns = [
  {
    dataType: 'string',
    displayName: 'EULA Name',
    fieldName: 'EulaName',
    fieldNameCamelCase: 'eulaName',
    flex: 1,
    minWidth: 200,
    ...propertiesCol,
  },
  {
    dataType: 'string',
    displayName: 'Member Name',
    fieldName: 'MemberName',
    fieldNameCamelCase: 'memberName',
    minWidth: 200,
    ...propertiesCol,
  },
  {
    dataType: 'string',
    displayName: 'Status',
    fieldName: 'Status',
    fieldNameCamelCase: 'status',
    width: 100,
    ...propertiesCol,
  },
  {
    dataType: 'datetime',
    displayName: 'Created',
    fieldName: 'Created',
    fieldNameCamelCase: 'created',
    width: 100,
    cellRenderer: formatMDYWithParam,
    ...propertiesCol,
  },
  {
    dataType: 'datetime',
    displayName: 'EULA Accepted Date',
    fieldName: 'EulaAcceptedDate',
    fieldNameCamelCase: 'eulaAcceptedDate',
    cellRenderer: formatMDYWithParam,
    ...propertiesCol,
  },
  {
    dataType: 'string',
    displayName: 'User Email',
    fieldName: 'UserEmail',
    fieldNameCamelCase: 'userEmail',
    ...propertiesCol,
  },
  {
    dataType: 'string',
    displayName: 'User First Name',
    fieldName: 'UserFirstName',
    fieldNameCamelCase: 'userFirstName',
    width: 150,
    ...propertiesCol,
  },
  {
    dataType: 'string',
    displayName: 'User Last Name',
    fieldName: 'UserLastName',
    fieldNameCamelCase: 'userLastName',
    width: 150,
    ...propertiesCol,
  },
  {
    dataType: 'string',
    displayName: 'Eula Accepted Ip Address',
    fieldName: 'EulaAcceptedIpAddress',
    fieldNameCamelCase: 'eulaAcceptedIpAddress',
    width: 200,
    ...propertiesCol,
  },
  {
    dataType: 'string',
    displayName: 'Eula Accepted User Agent',
    fieldName: 'EulaAcceptedUserAgent',
    fieldNameCamelCase: 'eulaAcceptedUserAgent',
    width: 400,
    ...propertiesCol,
  },
];

const ViewAcceptanceModal = ({ visible, onCancel, eulaId }) => {
  const modalProps = {
    title: 'Accepted EULA',
    visible,
    onCancel,
    width: '60vw',
    bodyStyle: {
      height: '60vh',
    },
    maskClosable: false,
    footer: null,
  };

  return (
    <>
      <StyledModal {...modalProps}>
        <div id='my-grid-link-asset' className='ag-theme-alpine'>
          <AgGrid
            columnDefs={defaultColumns}
            requestParams={{ eulaId }}
            responseParams='data'
            urlGrid={eulaMaintenanceEndpoints.GET_ACCEPTED_USER_EULA}
            urlGridDistinct={
              eulaMaintenanceEndpoints.ACCEPTED_USER_EULA_GRID_DISTINCT
            }
            notShowHeaderCheckbox={true}
            showCheckboxSelectionRender={false}
            gridConfigProps={{
              rowSelection: 'single',
            }}
            mapId={(item) => ({
              ...item,
              id: uuidv4(),
            })}
          />
        </div>
      </StyledModal>
    </>
  );
};

export default ViewAcceptance;
