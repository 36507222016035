import React from 'react';

import { FolderOpenOutlined } from '@ant-design/icons';

import { RibbonButton, RibbonSection, RibbonDivider } from 'common/components';

import Messages from 'i18n/messages/home';

const OpenResultControl = (props) => {
  const { type, selectedResult, onClickRibbonBtn } = props;

  const onClickOpenResult = () => {
    onClickRibbonBtn('openResult');
  };

  const disabled = !selectedResult;

  return type === 'product' ? (
    <RibbonSection>
      <RibbonDivider />
      <RibbonButton
        icon={<FolderOpenOutlined />}
        label={Messages.openResult}
        onClick={onClickOpenResult}
        disabled={disabled}
      />
    </RibbonSection>
  ) : null;
};

export default OpenResultControl;
