import { useState, useEffect } from 'react';

import _ from 'lodash';

import * as minimumRequirementServices from 'services/minimumRequirements';

export const useGetEntityProperties = (props) => {
  const { entityType, fetchOnMount } = props;

  const [fetchStatus, setFetchStatus] = useState('idle');

  const entityProperties = window.entityProperties?.[entityType];

  const fetchEntityProperties = () => {
    if (entityProperties)
      return new Promise((resolve) => resolve(entityProperties));

    const getEntityPropertiesServices = {
      Product: minimumRequirementServices.getAllProductFields,
      Member: minimumRequirementServices.getAllMemberFields,
      Asset: minimumRequirementServices.getAllAssetFields,
    };

    const service = getEntityPropertiesServices[entityType];
    setFetchStatus('pending');

    return (
      service &&
      service()
        .then((response) => {
          const { isSuccess, data } = response;

          if (isSuccess && data) {
            _.set(
              window,
              ['entityProperties', entityType],
              response?.data?.schemas || response?.data?.fields
            );

            return data.fields;
          }
        })
        .finally(() => {
          setFetchStatus('idle');
        })
    );
  };

  const getEntityProperties = () => {
    return window.entityProperties?.[entityType];
  };

  useEffect(() => {
    if (fetchOnMount) fetchEntityProperties();
  }, [fetchOnMount]);

  return {
    status: fetchStatus,
    getEntityProperties,
    fetchEntityProperties,
  };
};
