import React from 'react';
import PropTypes from 'prop-types';
import { Col, Tooltip, Button } from 'antd';
import { Images } from 'config/assets';
import * as _ from 'lodash';

const CertificationsImages = ({ certs, onShowMore }) => {
  const replaceError = (value) => {
    value.target.src = Images.RIVIR_LOGO_DEFAULT;
  };

  return (
    <>
      {certs?.length > 0 &&
        certs?.slice(0, 4).map((item, index) => {
          return (
            <Col
              key={item.id || index}
              style={{ marginLeft: index > 0 ? 5 : 0 }}
              className='certifications-display'
            >
              <Tooltip
                placement='topLeft'
                title={item?.propertyDisplayName}
                overlayClassName='certifications-display__tooltip'
              >
                <img
                  className='certifications-display__image'
                  src={
                    !_.isEmpty(item.certImage)
                      ? item.certImage
                      : Images.RIVIR_LOGO_DEFAULT
                  }
                  onError={(value) => replaceError(value)}
                  alt={item?.name || ''}
                />
              </Tooltip>
            </Col>
          );
        })}
      {certs?.length >= 5 && (
        <Button type='link' onClick={onShowMore}>
          More
        </Button>
      )}
    </>
  );
};

CertificationsImages.propTypes = {};

export default CertificationsImages;
