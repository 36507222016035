import { getFolderShortDetail } from 'services/folder';

const homeBreadcrumb = [
  {
    id: 'home',
    folderName: 'Home',
  },
];

export const generateChildBreadcrumbs = async (fullPathIds) => {
  let result = [];

  const promises = fullPathIds.map((id) =>
    getFolderShortDetail({
      id,
      IsLoadContent: false,
    })
  );

  const detailFolderResponses = await Promise.all(promises);

  detailFolderResponses.forEach((resp) => {
    const breadcrumb = {
      id: resp?.data?.id,
      folderName: resp?.data?.folderName ?? resp?.data?.description,
    };
    result.push(breadcrumb);
  });

  return result;
};

export const buildHierarchyBreadcrumb = async (selectedFolder) => {
  if (selectedFolder) {
    const {
      level,
      hierarchy,
      id: selectedId,
      folderName,
      description,
      editable,
    } = selectedFolder;
    const isRootFolder = level === 1;

    if (isRootFolder) {
      const breadcrumb = {
        id: selectedId,
        folderName: folderName ?? description,
        editable,
      };
      return homeBreadcrumb.concat(breadcrumb);
    }

    const parentIds = hierarchy
      ? hierarchy
          .split('/')
          .filter(Boolean)
          .map((id) => parseInt(id))
      : [];
    const fullPathIds = parentIds.concat(selectedId);

    const childBreadcrumbs = await generateChildBreadcrumbs(fullPathIds);

    return homeBreadcrumb.concat(childBreadcrumbs);
  }

  return [];
};

export const sortFolderToTop = (a, b) => {
  if (a.type.toLowerCase() === 'folder') {
    if (b.type.toLowerCase() === 'folder') return 0;
    return -1; //* sort folder to the top
  } else if (a.type.toLowerCase() !== 'folder') {
    //* this is not convenient but write details like this just to support Firefox
    if (b.type.toLowerCase() === 'folder') return 1;
    return 0;
  }

  return 0;
};
