import React from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Typography } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import useCustomScroll from 'hooks/useCustomScroll';
import './EntitiesAndAttributes.less';

import EntityDraggable from './EntityDraggable';

const EntitiesAndAttriButes = (props) => {
  const {
    advancedSearch,
    draggingTaskId,
    selectedTaskIds,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    collapseList,
    expandedList,
    handleCollapse,
    handleExpand,
    viewMode,
    advancedSearchGridView,
  } = props;
  var indexDrag = 0;
  const nameClass = !!advancedSearchGridView
    ? 'entities-attribues-adv'
    : 'entities-attribues';
  useCustomScroll(`.${nameClass}`);
  return (
    <Droppable droppableId={'entities_and_attributes'} isDropDisabled={true}>
      {(provided, { isDraggingOver }) => (
        <div ref={provided.innerRef} className={`${nameClass} scroller-extend`}>
          {advancedSearch.map((item, index) => {
            if (index === 0) {
              indexDrag = 0;
            } else {
              indexDrag++;
            }
            if (item?.fields?.length === 0) {
              return null;
            }
            return (
              <div key={index}>
                <Draggable
                  key={indexDrag}
                  draggableId={item.mapFieldName + '_entity'}
                  index={indexDrag}
                  isDragDisabled={true}
                >
                  {(provided, snapshot) => (
                    <>
                      <div
                        className={classnames({
                          'entities-attribues-adv__card':
                            true && advancedSearchGridView,
                          'entities-attribues-adv__card--drag':
                            snapshot.isDragging && advancedSearchGridView,
                          'entities-attribues__card':
                            true && !advancedSearchGridView,
                          'entities-attribues__card--drag':
                            snapshot.isDragging && !advancedSearchGridView,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => {
                          if (collapseList.indexOf(item.displayName) === -1) {
                            handleExpand(item.displayName);
                          } else {
                            handleCollapse(item.displayName);
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {collapseList.indexOf(item.displayName) === -1 ? (
                          <RightOutlined style={{ fontSize: 14, margin: 4 }} />
                        ) : (
                          <DownOutlined style={{ fontSize: 14, margin: 4 }} />
                        )}
                        <Typography.Text
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          {item.displayName}
                        </Typography.Text>
                      </div>
                      {provided.placeholder}
                    </>
                  )}
                </Draggable>
                <div style={{ pointerEvents: viewMode ? 'none' : 'auto' }}>
                  {expandedList.indexOf(item.displayName) > -1 &&
                    item?.fields &&
                    item?.fields.length > 0 &&
                    item.fields.map((task, i) => {
                      indexDrag++;
                      const isSelected =
                        selectedTaskIds.indexOf(
                          task?.mapFieldName + '_entity'
                        ) > -1;
                      const isGhosting =
                        isSelected &&
                        draggingTaskId &&
                        draggingTaskId !== task?.mapFieldName + '_entity';
                      return (
                        <EntityDraggable
                          task={task}
                          index={indexDrag}
                          key={task.mapFieldName + '_entity'}
                          isSelected={isSelected}
                          isGhosting={isGhosting}
                          selectionCount={selectedTaskIds.length}
                          toggleSelection={toggleSelection}
                          toggleSelectionInGroup={toggleSelectionInGroup}
                          multiSelectTo={multiSelectTo}
                          checkShow={
                            collapseList.indexOf(item.displayName) === -1
                          }
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

EntitiesAndAttriButes.propTypes = {
  advancedSearch: PropTypes.array,
  draggingTaskId: PropTypes.string,
  selectedTaskIds: PropTypes.array,
  toggleSelection: PropTypes.func,
  toggleSelectionInGroup: PropTypes.func,
  multiSelectTo: PropTypes.func,
  collapseList: PropTypes.array,
  expandedList: PropTypes.array,
  handleCollapse: PropTypes.func,
  handleExpand: PropTypes.func,
  viewMode: PropTypes.bool,
  advancedSearchGridView: PropTypes.bool,
};

export default React.memo(EntitiesAndAttriButes);
