import React from 'react';

import { EDIT_TYPE } from '../../constants';

import {
  PageEditorToolAddNewImage,
  PageEditorToolAddNewText,
  PageEditorToolAddNewYoutube,
} from '.';

import './PageEditorAddNew.less';

const PageEditorAddNew = (props) => {
  const {
    wrapId,
    wrapKey,
    editType,
    children,
    wrapClassName,
    className,
    selectedHook,
    pageData,
    detailShowTargetHook,
    showDetailComponentData,
    componentHandlers,
    scale,
    ...restProps
  } = props;

  const InputRender = () => {
    switch (editType) {
      case EDIT_TYPE.IMG:
        return (
          <PageEditorToolAddNewImage
            wrapId={wrapId}
            selectedHook={selectedHook}
            wrapKey={wrapKey}
            showDetailComponentData={showDetailComponentData}
            detailShowTargetHook={detailShowTargetHook}
            componentHandlers={componentHandlers}
            scale={scale}
          />
        );
      case EDIT_TYPE.TEXT:
        return (
          <PageEditorToolAddNewText
            wrapId={wrapId}
            selectedHook={selectedHook}
            wrapKey={wrapKey}
            showDetailComponentData={showDetailComponentData}
            detailShowTargetHook={detailShowTargetHook}
            componentHandlers={componentHandlers}
            scale={scale}
          />
        );

      case EDIT_TYPE.YOUTUBE:
        return (
          <PageEditorToolAddNewYoutube
            wrapId={wrapId}
            selectedHook={selectedHook}
            wrapKey={wrapKey}
            showDetailComponentData={showDetailComponentData}
            detailShowTargetHook={detailShowTargetHook}
            componentHandlers={componentHandlers}
            scale={scale}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={`page-editor-tool-add-new ${wrapClassName}`}>
      {children ? children : InputRender()}
    </div>
  );
};

export default PageEditorAddNew;
