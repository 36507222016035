import React from 'react';
import { Menu, Dropdown, Button, Typography, notification } from 'antd';
import Messages from 'i18n/messages/home';
import { useDispatch, useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';

import { ReactComponent as IconFilterMember } from 'common/components/button/svg-icons/ICO_10.29.svg';

import { FormattedMessage } from 'react-intl';
import { CloseCircleOutlined } from '@ant-design/icons';
import AddToBan from '../../controls/member-grid/AddToBan';
import ListBan from '../../controls/member-grid/ListBan';
import * as actions from 'redux/ribbon/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import * as brandingActions from 'redux/branding/actions';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import dialogFunction from 'common/components/dialog-function/DialogFunction';
import { Can } from 'context/Can';
import RibbonButton from 'common/components/button/RibbonButton';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';

const { Text } = Typography;

const renderMenuOptions = (props) => {
  const { banList, addBanList } = props;
  return (
    <Menu>
      <Menu.Item style={{ backgroundColor: 'inherit' }}>
        <AddToBan handleClick={() => addBanList()} />
      </Menu.Item>
      <Menu.Item style={{ backgroundColor: 'inherit' }}>
        <ListBan handleClick={() => banList()} />
      </Menu.Item>
    </Menu>
  );
};

const BanMember = () => {
  const dispatch = useDispatch();
  const isFavoriteRoute = useCheckFavoriteRoute();
  const selectedFolder = useSelector(gridSelector.makeSelectItemsSelection());
  const selectedPage = useSelector(gridSelector.makeSelectPageCurrent());
  function toggleBanList() {
    dispatch(actions.toggleBanList());
  }

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to add selected items into ban list?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: addToBanList,
    });
  };

  function addToBanList() {
    dispatch(
      actions.addToBanList(
        selectedFolder,
        selectedPage.memberPage,
        isFavoriteRoute
      )
    );
    dispatch(
      actionsGridView.updatePageCurrentMember([1, selectedPage.memberPage[1]])
    );
    dispatch(actionsGridView.updateVisibleSelection(false));
    dispatch(actionsGridView.updateItemCurrentSelection(null));
    dispatch(actionsGridView.updateItemsSelectionDialog(null));
    dispatch(actionsGridView.updateItemsSelection([]));
    dispatch(actionsGridView.updatePagesSelection([]));
    dispatch(actionsGridView.updateItemPageSelection([]));
    dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));
    dispatch(actionsGridView.updateCountPageSelection([]));
    dispatch(brandingActions.getBrandingNoLoading());
  }

  function handleAddToBanList() {
    if (selectedFolder.length > 0) {
      confirmDelete();
    } else
      notification.warn({ message: 'Must select item adding to ban list' });
  }

  return (
    <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.BAN_LIST} passThrough>
      {(allowed) => (
        <Dropdown
          overlay={renderMenuOptions({
            banList: toggleBanList,
            addBanList: handleAddToBanList,
          })}
          placement='bottomLeft'
          disabled={!allowed}
          arrow
        >
          <RibbonButton
            disabled={!allowed}
            icon={<CloseCircleOutlined style={{ height: 26 }} />}
            label={Messages.filterMember}
          ></RibbonButton>
        </Dropdown>
      )}
    </Can>
  );
};

export default BanMember;
