import produce from 'immer';

import moment from 'moment';
import { lowercaseFirstLetter } from '../../shared/utils';

/* 
- There are 2 types of property value:
    + 1-1 type: {...} (childProperties > 0 && isArray = false)
    + 1-n type: [{}, {}, ...] (childProperties > 0 && isArray = true)

  - formValues is [{}, {}]. So we need to map formValues by type of property
  */

const invalidValues = [null, undefined, ''];

const isObject = (value) => {
  if (typeof value === 'object' && value !== null && !Array.isArray(value))
    return true;

  return false;
};

const lowercaseName = (name) => name.toLowerCase();

const isPrimitiveType = (val) => {
  if (val === null || val === undefined) return false;

  return !isObject(val);
};

const checkArrayOfPrimitiveValues = (val) =>
  val.some((item) => isPrimitiveType(item));

const checkIsMomentType = (val) => moment.isMoment(val);

const isEmptyArray = (array = []) => array?.length === 0;

const isEmptyObject = (object) => Object.keys(object).length === 0;

export const checkIsValidObjectValues = (data = {}) => {
  let isValid = false;

  const values = Object.values(data);

  values.forEach((value) => {
    if (Array.isArray(value)) {
      const isArrayPrimitiveTypes = checkArrayOfPrimitiveValues(value);
      if (isArrayPrimitiveTypes) {
        if (!isEmptyArray(value)) {
          isValid = true;
        }
      }
      value.forEach((childVal) => {
        if (!checkEmptyDataProductModule(childVal)) {
          isValid = true;
        }
      });
    } else if (isObject(value)) {
      if (!checkEmptyDataProductModule(value)) {
        isValid = true;
      }
    } else {
      if (!invalidValues.includes(value)) {
        isValid = true;
      }
    }
  });

  return isValid;
};

export const checkEmptyDataProductModule = (data) => {
  if (!data || isEmptyObject(data)) return true;

  const values = Object.values(data);

  return values.every((value) => {
    if (Array.isArray(value)) {
      const isArrayPrimitiveTypes = checkArrayOfPrimitiveValues(value);
      if (isArrayPrimitiveTypes) {
        return value.filter(Boolean).length === 0;
      }
      return value.every((item) => checkEmptyDataProductModule(item));
    } else if (isObject(value)) {
      return checkEmptyDataProductModule(value);
    } else {
      if (Array.isArray(value)) return value.length === 0;

      return invalidValues.includes(value);
    }
  });
};

export const checkNestedPropertyHasValidValues = (values = []) => {
  let isValidValue = false;

  values.forEach((value) => {
    if (checkIsValidObjectValues(value)) {
      isValidValue = true;
    }
  });

  return isValidValue;
};

export const getCopyFieldInNestObject = ({
  formValues,
  nestedFields,
  idxRootField,
  idxCopyField,
}) => {
  const [rootField, ...childFields] = nestedFields;
  let result = formValues[rootField][idxRootField];

  childFields.forEach((childField) => {
    result = result[childField];
  });

  return result[idxCopyField];
};

export const generateNestedIdxFormElement = ({
  parentNestedIndex = [],
  propertyName,
  field,
}) => {
  return parentNestedIndex.concat([propertyName, field.name]);
};

export const getIdxCopiedField = ({
  parentNestedIndex,
  parentPropertyName,
  field,
}) => {
  return parentNestedIndex.concat([parentPropertyName, field.name]);
};

export const getFieldValueFromListIdx = (formValues, indexes) => {
  let result = formValues;

  indexes.forEach((key) => {
    if (result) {
      result = result[key];
    } else {
      result = undefined;
    }
  });

  return result;
};

export const getNewFormValuesAfterCopying = ({ formValues, idxCopyField }) => {
  const copyFieldValue = getFieldValueFromListIdx(formValues, idxCopyField);

  // Remove last item
  const idxParentField = idxCopyField.slice(0, idxCopyField.length - 1); // [root, parentField1, parentField1,..., indexChild]

  return produce(formValues, (draftState) => {
    let result = draftState;

    idxParentField.forEach((key) => {
      result = result[key];
    });

    result.push(copyFieldValue);
  });
};

export const getDataByPropertyName = (data, propertyName) => {
  const formattedPropertyName = lowercaseFirstLetter(propertyName);

  return data[formattedPropertyName];
};

export const findProductPropertyEnums = (propertyName, dataEnums) => {
  const foundEnums = dataEnums.find((item) => item.enumName === propertyName);
  if (foundEnums) {
    return foundEnums.enumProperties;
  }

  return [];
};

export const getDataProductModule = (data, moduleName) => {
  return data?.productItemModules?.find(
    (item) => item.moduleName === moduleName
  );
};

// Lowercase key in object data, prevent some potential bugs
export const lowercaseProductData = (data = {}) => {
  if (!data) return {};

  let result = {};

  for (const [key, value] of Object.entries(data)) {
    const lowercaseKey = lowercaseName(key);
    const isMomentType = checkIsMomentType(value);

    if (isObject(value) && !isMomentType) {
      result = {
        ...result,
        [lowercaseKey]: lowercaseProductData(value),
      };
    } else if (Array.isArray(value)) {
      const isArrayPrimitiveTypes = checkArrayOfPrimitiveValues(value);
      if (isArrayPrimitiveTypes) {
        result = {
          ...result,
          [lowercaseKey]: value,
        };
      } else {
        result = {
          ...result,
          [lowercaseKey]: value.map((val) => lowercaseProductData(val)),
        };
      }
    } else {
      result = {
        ...result,
        [lowercaseKey]: value,
      };
    }
  }
  return result;
};

export const mappingDataProductByFieldFullPath = (
  dataProduct = {},
  moduleProperties
) => {
  let result = {};

  moduleProperties.forEach((module) => {
    const { propertyName, fieldFullPath, childProperties, propertyType } =
      module;
    const lowercasePropertyName = lowercaseName(propertyName);
    const value = dataProduct[lowercasePropertyName];

    // nested
    if (childProperties.length > 0) {
      if (isObject(value)) {
        /* 
          Why do I map to array of value here? Because UI of 1-1 type is same ui 1-n of type 
        */
        result = {
          ...result,
          [fieldFullPath]: [
            mappingDataProductByFieldFullPath(value, childProperties),
          ],
        };
      } else if (Array.isArray(value)) {
        const isArrayPrimitiveTypes = checkArrayOfPrimitiveValues(value);

        if (isArrayPrimitiveTypes) {
          result = {
            ...result,
            [fieldFullPath]: value,
          };
        } else {
          result = {
            ...result,
            [fieldFullPath]: value.map((val) =>
              mappingDataProductByFieldFullPath(val, childProperties)
            ),
          };
        }
      } else {
        if (propertyType === 'datetime') {
          result = {
            ...result,
            [fieldFullPath]: value ? moment(value) : null,
          };
        } else {
          result = {
            ...result,
            [fieldFullPath]: value,
          };
        }
      }
    } else {
      if (propertyType === 'datetime') {
        result = {
          ...result,
          [fieldFullPath]: value
            ? Array.isArray(value)
              ? value.map((item) => (item ? moment(item) : null))
              : moment(value)
            : null,
        };
      } else {
        result = {
          ...result,
          [fieldFullPath]: value,
        };
      }
    }
  });

  return result;
};

export const getFormValues = (dataProduct = {}, moduleProperties) => {
  let result = {};

  moduleProperties.forEach((module) => {
    const { propertyName, childProperties, propertyType, isArray } = module;
    const lowercasePropertyName = lowercaseName(propertyName);
    const value = dataProduct[lowercasePropertyName];

    // nested
    if (childProperties.length > 0) {
      if (!value) {
        result = {
          ...result,
          [propertyName]: isArray ? [] : null,
        };
      } else if (isObject(value)) {
        result = {
          ...result,
          [propertyName]: getFormValues(value, childProperties),
        };
      } else if (Array.isArray(value)) {
        result = {
          ...result,
          [propertyName]: value.map((val) =>
            getFormValues(val, childProperties)
          ),
        };
      } else {
        if (propertyType === 'datetime') {
          result = {
            ...result,
            [propertyName]: value ? moment(value) : null,
          };
        } else {
          result = {
            ...result,
            [propertyName]: value,
          };
        }
      }
    } else {
      if (propertyType === 'datetime') {
        result = {
          ...result,
          [propertyName]: value
            ? Array.isArray(value)
              ? value.map((item) => (item ? moment(item) : null))
              : moment(value)
            : null,
        };
      } else {
        result = {
          ...result,
          [propertyName]: !value ? (isArray ? [] : value) : value,
        };
      }
    }
  });

  return result;
};

const orderModuleProperties = (moduleProperties = {}) => {
  // Order and move other to last
  const orderedKeys = Object.keys(moduleProperties)
    .sort()
    .filter((key) => key !== 'Other')
    .concat('Other');

  let result = [];

  orderedKeys.forEach((key) => {
    result.push({
      nameGroup: key,
      groupProperties: moduleProperties[key] ?? [],
    });
  });

  return result;
};

export const groupProductModuleProperties = (moduleProduct) => {
  let newModuleProperties = {};

  for (const {
    propertyGroup,
    ...otherProps
  } of moduleProduct?.moduleProperties) {
    if (!propertyGroup) {
      if (!newModuleProperties['Other']) {
        newModuleProperties['Other'] = [].concat(otherProps);
      } else {
        newModuleProperties['Other'] =
          newModuleProperties['Other'].concat(otherProps);
      }
    } else {
      if (!newModuleProperties[propertyGroup]) {
        newModuleProperties[propertyGroup] = [].concat(otherProps);
      } else {
        newModuleProperties[propertyGroup] =
          newModuleProperties[propertyGroup].concat(otherProps);
      }
    }
  }

  const isGroupProperties = Object.keys(newModuleProperties).length > 1; // ["Other", ...]

  if (isGroupProperties) {
    return {
      ...moduleProduct,
      isGroup: true,
      moduleGroupProperties: orderModuleProperties(newModuleProperties),
    };
  } else {
    return {
      ...moduleProduct,
      isGroup: false,
    };
  }
};

export const checkInvalidDataInGroupProperties = (data, properties = []) => {
  let result = [];

  if (properties?.length > 0) {
    properties.forEach((property) => {
      const dataProperty = data[property.fieldFullPath];
      result.push(dataProperty);
    });

    return result.every(
      (val) => invalidValues.includes(val) || isEmptyArray(val)
    );
  }
};

export const getDataByModuleNames = (data, moduleSchema) => {
  let result = [];

  if (data) {
    moduleSchema.forEach(({ moduleName }) => {
      const dataModule = data.productItemModules.find(
        (val) => val.moduleName === moduleName
      );
      result.push(dataModule);
    });
  }

  return result;
};

export const getProductSchemaHasValidData = (dataProduct, productSchema) => {
  let result = [];

  if (dataProduct) {
    const filteredProductData = getDataByModuleNames(
      dataProduct,
      productSchema
    );

    productSchema.forEach((schemaItem) => {
      const value =
        filteredProductData.find(
          (item) => item.moduleName === schemaItem.moduleName
        )?.data ?? {};
      const isValidValue = checkIsValidObjectValues(value);

      if (isValidValue) {
        result.push(schemaItem);
      }
    });
  }

  return result;
};

export const checkTypeOfFormNested = (childProperties = [], isArray) => {
  if (childProperties.length > 0 && isArray)
    return {
      isSingleNestedValue: false,
      isMultipleNestedValue: true,
    };

  if (childProperties.length > 0 && !isArray)
    return {
      isSingleNestedValue: true,
      isMultipleNestedValue: false,
    };

  return {
    isSingleNestedValue: false,
    isMultipleNestedValue: false,
  };
};

export const getFormNameItem = (parentName, childName) => {
  if (parentName) {
    if (Array.isArray(parentName)) return parentName.concat(childName);

    return [parentName, childName];
  }

  return childName;
};

export const getValuesAfterDeletingSingleNestedForm = ({
  formValues,
  parentNestedIndex,
  propertyName,
}) => {
  return produce(formValues, (draftState) => {
    parentNestedIndex.forEach((idx) => {
      if (idx === propertyName) {
        draftState[`${idx}`] = null;
      } else {
        draftState = draftState[idx];
      }
    });
  });
};

// {}, undefined in []
export const filterParamFormValues = (formValues, moduleProperties) => {
  let result = {};

  moduleProperties.forEach((moduleItem) => {
    const { propertyName, childProperties, propertyType, isArray } = moduleItem;
    const value = formValues[propertyName];

    // nested
    if (childProperties.length > 0) {
      if (!value) {
        result = {
          ...result,
          [propertyName]: isArray ? [] : {},
        };
      } else if (isObject(value)) {
        result = {
          ...result,
          [propertyName]: filterParamFormValues(value, childProperties),
        };
      } else if (Array.isArray(value)) {
        const isArrayPrimitiveTypes = checkArrayOfPrimitiveValues(value);
        if (isArrayPrimitiveTypes) {
          result = {
            ...result,
            [propertyName]: value.filter((val) => !invalidValues.includes(val)),
          };
        } else {
          // [{}, {}]
          result = {
            ...result,
            [propertyName]: value
              .filter((val) => checkIsValidObjectValues(val))
              .map((val) => filterParamFormValues(val, childProperties)),
          };
        }
      } else {
        if (propertyType === 'datetime') {
          result = {
            ...result,
            [propertyName]: value ? moment(value).toISOString() : null,
          };
        } else {
          result = {
            ...result,
            [propertyName]: value,
          };
        }
      }
    } else {
      if (!value && typeof value !== 'number' && typeof value !== 'boolean') {
        result = {
          ...result,
          [propertyName]: isArray ? [] : null,
        };
      } else if (Array.isArray(value)) {
        result = {
          ...result,
          [propertyName]: value
            ? value.filter((val) => !invalidValues.includes(val))
            : [],
        };
      } else if (propertyType === 'datetime') {
        result = {
          ...result,
          [propertyName]: value ? moment(value).toISOString() : null,
        };
      } else {
        result = {
          ...result,
          [propertyName]: value,
        };
      }
    }
  });

  return result;
};

export const findRelatedFieldFullPath = (
  searchText,
  data,
  templateArr = []
) => {
  let arrayResult = [];

  if (data === undefined || data === null) return;

  if (typeof data === 'object' && !Array.isArray(data)) {
    Object.entries(data).forEach(([key, value]) => {
      if (key === searchText) {
        arrayResult.push(key);
        templateArr.push(key);
      }

      const isRelatedPath =
        typeof key === 'string' &&
        searchText?.length > key?.length &&
        searchText.includes(key) &&
        !templateArr.includes(key);

      if (isRelatedPath) {
        const newSearchTextArr = getNewSearchArr(searchText, key);

        if (newSearchTextArr.join('.') === key) {
          arrayResult.push(key);
          templateArr.push(key);
        }
      }
      if (Array.isArray(value)) {
        arrayResult = arrayResult.concat(
          findRelatedFieldFullPath(searchText, value, templateArr)
        );
      }
    });
  }
  if (Array.isArray(data)) {
    data.forEach((item) => {
      arrayResult = arrayResult.concat(
        findRelatedFieldFullPath(searchText, item, templateArr)
      );
    });
  }
  return arrayResult;
};

const getNewSearchArr = (searchText, searchKey) => {
  let newSearchTextArr = [];
  const searchTextArr = searchText.split('.');
  const searchkeyArr = searchKey.split('.');
  for (let i = 0; i < searchkeyArr.length; i++) {
    newSearchTextArr.push(searchTextArr[i]);
  }
  return newSearchTextArr;
};

export const findFieldFullPathFormData = (searchText, data) => {
  const relatedListFieldFullPath = findRelatedFieldFullPath(searchText, data);
  return relatedListFieldFullPath[relatedListFieldFullPath.length - 1];
};

// Publication

export const generateEmptyForm = (childProperties) => {
  let result = {};
  childProperties.forEach((property) => {
    const { isSingleNestedValue, isMultipleNestedValue } =
      checkTypeOfFormNested(property.childProperties, property.isArray);

    if (isSingleNestedValue) {
      result = {
        ...result,
        [property.propertyName]: null,
      };
    } else if (isMultipleNestedValue) {
      result = {
        ...result,
        [property.propertyName]: [],
      };
    } else {
      result = {
        ...result,
        [property.propertyName]: null,
      };
    }
  });

  // return isArray ? [result] : result;
  return result;
};

export const mappingFormValuesToObject = (formValues) => {
  let result = {};

  Object.entries(formValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        result = {
          ...result,
          [key]: mappingFormValuesToObject(value[0]),
        };
      } else {
        result = {
          ...result,
          [key]: {},
        };
      }
    } else if (isObject(value)) {
      result = {
        ...result,
        [key]: mappingFormValuesToObject(value),
      };
    } else {
      result = {
        ...result,
        [key]: value,
      };
    }
  });

  return result;
};

export const getIdxNameFormOfProperty = ({
  properties,
  paths,
  fieldFullPathError,
}) => {
  let result = [];

  if (paths.length >= 1) {
    const path = paths[0];
    const isLastPath = paths.length === 1;
    const foundProperty =
      properties.find((property) => property.propertyName === path) ?? {};

    const { childProperties, isArray } = foundProperty;

    const newName = isArray && !isLastPath ? [path, 0] : path;

    const childNewName = getIdxNameFormOfProperty({
      properties: childProperties,
      fieldFullPathError,
      paths: paths.slice(1),
    });
    result = result.concat(newName).concat(childNewName);
  }
  return result;
};

export const updateObject = (obj, keys, value) => {
  let newObj = JSON.parse(JSON.stringify(obj));
  let current = newObj;
  for (let i = 0; i < keys.length; i++) {
    if (i === keys.length - 1) {
      if (Array.isArray(current[keys[i]])) {
        current[keys[i]].push(value);
      } else {
        current[keys[i]] = value;
      }
    } else {
      if (Array.isArray(current[keys[i]])) {
        current = current[keys[i]][0];
      } else {
        current = current[keys[i]];
      }
    }
  }
  return newObj;
};

export const initFormProperties = ({
  formValues,
  property,
  loopKeys,
  fieldFullPathError,
  otherFieldFullPath,
}) => {
  let result = formValues;

  const { childProperties, fieldFullPath } = property;

  const paths = childProperties.map((property) => property.fieldFullPath);
  const emptyForm = generateEmptyForm(property.childProperties);

  if (fieldFullPath === fieldFullPathError) {
    return result;
  }

  if (paths.includes(fieldFullPathError)) {
    result = updateObject(result, loopKeys, emptyForm);
    return result;
  }

  result = updateObject(result, loopKeys, emptyForm);

  const foundProperty = childProperties.find(
    (property) => property.propertyName === otherFieldFullPath[0]
  );
  return initFormProperties({
    formValues: result,
    property: foundProperty,
    loopKeys: loopKeys.concat(foundProperty.propertyName),
    fieldFullPathError,
    otherFieldFullPath: otherFieldFullPath.slice(1),
  });
};

// Get childProperties -> generate to fieldFullPathError
export const generateFormValuesForPublishing = ({
  formValues,
  foundProperty,
  fieldFullPathError,
  nearestPath,
  otherFieldFullPath,
}) => {
  const { isArray, childProperties, propertyName } = foundProperty;
  const { isMultipleNestedValue, isSingleNestedValue } = checkTypeOfFormNested(
    childProperties,
    isArray
  );
  const initialNearestPropertyFormValues = {
    [propertyName]: null,
  };
  const loopKeys = [propertyName];

  const initialForm = initFormProperties({
    formValues: initialNearestPropertyFormValues,
    property: foundProperty,
    loopKeys,
    fieldFullPathError,
    otherFieldFullPath,
  });

  const parentProperty = nearestPath[0];

  if (isMultipleNestedValue) {
    if (otherFieldFullPath.length === 0) {
      return formValues;
    }

    return produce(formValues, (draftState) => {
      let result = draftState;

      nearestPath.forEach((key) => {
        result = result[key];
      });

      result.push(initialForm[propertyName]);
    });
  }

  if (isSingleNestedValue) {
    if (nearestPath.length === 1) {
      return produce(formValues, (draftState) => {
        draftState[parentProperty] = initialForm[propertyName];
      });
    }

    return produce(formValues, (draftState) => {
      nearestPath.forEach((key) => {
        draftState[parentProperty][key] = initialForm[propertyName];
      });
    });
  }

  return formValues;
};

export const getNearestFieldPath = (formValues, paths) => {
  const value = getFieldValueFromListIdx(formValues, paths);

  if (value !== undefined) {
    return {
      value,
      nearestPath: paths,
    };
  }

  return getNearestFieldPath(formValues, paths.slice(0, -1));
};

export const getPropertyByFieldFullPath = (
  productProperties,
  fieldFullPath
) => {
  for (let property of productProperties) {
    if (property.fieldFullPath === fieldFullPath) {
      return property;
    } else if (property.childProperties.length > 0) {
      let result = getPropertyByFieldFullPath(
        property.childProperties,
        fieldFullPath
      );
      if (result) {
        return result;
      }
    }
  }
  return null;
};

// Path is list index of property ([property, 0, ...]). So need to filter to get the string type path
export const joinListPath = (paths = []) => {
  return paths
    .filter((path) => typeof path === 'string')
    .map((path) => lowercaseFirstLetter(path))
    .join('.');
};

export const getNearestFieldFullPathError = (nearestPath, moduleNameError) => {
  const stringPath = joinListPath(nearestPath);
  return `${moduleNameError}.${stringPath}`;
};

export const validateForm = ({ formValues }) => {
  const needValidatedModules = {
    AllergenRelatedInformation: validateAllergenModule,
  };

  const validatedResult = Object.keys(formValues).reduce(
    (accumulator, currentValidatedModule) => {
      const validator = needValidatedModules[currentValidatedModule];
      const moduleFormValue = formValues[currentValidatedModule];

      if (!validator) return accumulator;
      if (!moduleFormValue) return accumulator;

      const validateResult = validator(moduleFormValue);

      accumulator = validateResult;

      return accumulator;
    },
    []
  );

  return validatedResult;
};

const validateAllergenModule = (data) => {
  const result = data.reduce((accumulator, currentDataItem, index) => {
    const allergen = currentDataItem.Allergen;
    if (!allergen.length) return accumulator;

    const validatedAllergenResult = validateAllergen(allergen, index);

    return [...accumulator, ...validatedAllergenResult];
  }, []);

  return result;
};

const validateAllergen = (allergen, allergenRelatedIndex) => {
  return allergen.reduce((accumulator, currentAllergen, index) => {
    const { ContainmentSource, LevelOfContainmentCode } = currentAllergen;
    const field = [
      'AllergenRelatedInformation',
      allergenRelatedIndex,
      'Allergen',
      index,
      'ContainmentSource',
    ];

    let message = '';

    //* Rule 1: LevelOfContainmentCode === 'May Contain' and ContainmentSource === null => invalid
    if (LevelOfContainmentCode === 'May Contain' && !ContainmentSource)
      message =
        'If Level Of Containment Code is May Contain, you have to select value for Containment Source';

    //* Rule 2: LevelOfContainmentCode !== 'May Contain' and ContainmentSource has value => invalid
    if (LevelOfContainmentCode !== 'May Contain' && ContainmentSource)
      message =
        'If Level Of Containment Code is not May Contain, Containment Source must not have value';

    accumulator = message
      ? [
          ...accumulator,
          {
            field,
            message,
          },
        ]
      : accumulator;

    return accumulator;
  }, []);
};

const keywordForRenderTextarea = ['Description', 'Features', 'Introduction'];
const LENGTH_TEXT_AREA = 200;

export const checkRenderTextArea = (displayName, maxLength = 0) => {
  if (maxLength >= LENGTH_TEXT_AREA) return true;

  for (const keyword of keywordForRenderTextarea) {
    if (displayName.includes(keyword)) return true;
  }
  return false;
};

const syncAllergens = [
  'Allergen_Eggs',
  'Allergen_Soy',
  'Allergen_Wheat',
  'Allergen_Milk',
  'Allergen_Fish',
  'Allergen_Crustaceans',
  'Allergen_Sesame',
  'Allergen_Peanuts',
  'Allergen_TreeNuts',
  'Allergen_Shellfish',
  'Allergen_Corn',
  'Allergen_Oats',
  'Allergen_Seeds',
];

const syncCodeAllergens = [
  'LevelOfContainmentCode_Eggs',
  'LevelOfContainmentCode_Soy',
  'LevelOfContainmentCode_Wheat',
  'LevelOfContainmentCode_Milk',
  'LevelOfContainmentCode_Fish',
  'LevelOfContainmentCode_Crustaceans',
  'LevelOfContainmentCode_Sesame',
  'LevelOfContainmentCode_Peanuts',
  'LevelOfContainmentCode_TreeNuts',
  'LevelOfContainmentCode_Shellfish',
  'LevelOfContainmentCode_Corn',
  'LevelOfContainmentCode_Oats',
  'LevelOfContainmentCode_Seeds',
];

const containmentSourceAllergens = [
  'ContainmentSource_Eggs',
  'ContainmentSource_Soy',
  'ContainmentSource_Wheat',
  'ContainmentSource_Milk',
  'ContainmentSource_Fish',
  'ContainmentSource_Crustaceans',
  'ContainmentSource_Sesame',
  'ContainmentSource_Peanuts',
  'ContainmentSource_TreeNuts',
  'ContainmentSource_Shellfish',
  'ContainmentSource_Corn',
  'ContainmentSource_Oats',
  'ContainmentSource_Seeds',
];

const allPropertiesAllergens = [
  ...syncAllergens,
  ...syncCodeAllergens,
  ...containmentSourceAllergens,
];

export const getInitialStateAllergens = () => {
  let result = {};

  allPropertiesAllergens.forEach((key) => {
    if (syncAllergens.includes(key)) {
      result = {
        ...result,
        [key]: false,
      };
    } else {
      result = {
        ...result,
        [key]: null,
      };
    }
  });

  return result;
};

export const checkFormHasError = (formFields) => {
  return formFields.some((field) => field?.errors?.length > 0);
};

const ALLERGEN_MODULE_NAME = 'AllergenInformation';

export const checkIsAllergenModuleName = (moduleName) => {
  return moduleName?.toLowerCase() === ALLERGEN_MODULE_NAME?.toLowerCase();
};

// Do not allow deleting the last remaining field.
const fieldsNeedToDisabled = [
  'allergenInformation.allergenRelatedInformation',
  'foodAndBeverageIngredient.ingredientStatement',
];

export const checkDisabledDeleteFormField = ({
  totalFields,
  fieldFullPath,
}) => {
  return fieldsNeedToDisabled.includes(fieldFullPath)
    ? totalFields === 1
    : false;
};

export const getKeyNestedObj = (object, path = '') =>
  Object.keys(object).reduce((res, key) => {
    if (typeof object[key] === 'object' && object[key] !== null) {
      return [...res, ...getKeyNestedObj(object[key], path + '.' + key)];
    }
    return [...res, path + '.' + key];
  }, []);

const SPECIAL_FIELD = [
  'foodandbeverageingredient.ingredientstatement',
  'allergeninformation',
];

export const checkSpecialField = (path) => {
  const fieldFullPath = path?.toLowerCase();
  return SPECIAL_FIELD?.some((module) =>
    fieldFullPath?.includes(module?.toLowerCase())
  );
};
