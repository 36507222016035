import React from 'react';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';

import { Row, Col, Typography, Menu } from 'antd';

const UpcProducts = ({
  idSelectedProduct,
  productList,
  sentProducts,
  onSelectProduct,
}) => {
  return (
    <Col span={6} className='upc-products__container scroller'>
      <Menu
        className='upc-products__menu'
        onClick={onSelectProduct}
        selectedKeys={[`${idSelectedProduct}`]}
      >
        {productList?.map((product) => {
          return (
            <Menu.Item key={product.id}>
              <Row align='middle' justify='space-between'>
                <Col flex={1} style={{ maxWidth: '160px' }}>
                  <Typography.Text strong ellipsis style={{ maxWidth: '100%' }}>
                    {product?.gtin || product?.upc12 || product.name}
                  </Typography.Text>
                </Col>
                <StatusSendMeeting
                  product={product}
                  sentProducts={sentProducts}
                />
              </Row>
            </Menu.Item>
          );
        })}
      </Menu>
    </Col>
  );
};

const StatusSendMeeting = ({ product, sentProducts }) => {
  const { contactEmail, id } = product;
  const foundProduct = sentProducts.find((product) => product.id === id) ?? {};

  if (foundProduct.status === 'loading') {
    return (
      <LoadingOutlined className='upc-products__status-icon upc-products__status-icon--loading' />
    );
  }

  if (foundProduct.status === 'success') {
    return (
      <CheckCircleTwoTone
        className='upc-products__status-icon upc-products__status-icon--success'
        twoToneColor='#52c41a'
      />
    );
  }

  if (!contactEmail) {
    return (
      <CloseCircleTwoTone
        className='upc-products__status-icon upc-products__status-icon--error'
        twoToneColor='#ff4d4f'
      />
    );
  }

  return null;
};

export default UpcProducts;
