import React from 'react';

import {
  PlaySquareOutlined,
  CodeSandboxOutlined,
  UserOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  SnippetsOutlined,
  FolderOutlined,
} from '@ant-design/icons';

const entityTypeIcon = (type) => {
  switch (type?.toLowerCase()) {
    case 'query':
      return <FileSearchOutlined />;

    case 'asset':
      return <PlaySquareOutlined />;

    case 'document':
      return <FileTextOutlined />;

    case 'product':
      return <CodeSandboxOutlined />;

    case 'folder':
      return <FolderOutlined />;

    case 'member':
      return <UserOutlined />;

    case 'reporting':
      return <SnippetsOutlined />;

    default:
      return null;
  }
};

export { entityTypeIcon };
