import React from 'react';
import PropTypes from 'prop-types';

import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import FolderDetailSection from './components/sections/folder/FolderDetailSection';

import { ViewLayout, AdvanceStack, OtherLayout } from './components';

import { useFolderByLevel } from 'hooks/useFolderByLevel';

const FolderDetailRibbon = (props) => {
  const {
    folderDetail,
    handleLoading,
    onReloadWhenSaveSuccess,
    onMoveFolder,
    onCopyFolder,
  } = props;

  const { keepFolderBreadcrumb } = useFolderByLevel();

  const closeDetailCallback = () => {
    keepFolderBreadcrumb(true);
  };

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection disabled={true} />
      <ViewLayout disabled />
      <RibbonDivider />
      <FolderDetailSection
        folderDetail={folderDetail}
        onReloadWhenSaveSuccess={onReloadWhenSaveSuccess}
        handleLoading={handleLoading}
        onMoveFolder={onMoveFolder}
        onCopyFolder={onCopyFolder}
      />
      <RibbonDivider />
      <AdvanceStack isDisabled />
      <OtherLayout
        ribbonSide='right'
        ribbonType='folder-detail'
        closeDetailCallback={closeDetailCallback}
      />
    </RibbonBar>
  );
};

FolderDetailRibbon.propTypes = {
  onSearch: PropTypes.func,
  toggleSearch: PropTypes.bool,
};

export default FolderDetailRibbon;
