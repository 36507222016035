import React, { useMemo, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Space, Checkbox, Form, Select, Row, Col, Typography } from 'antd';

import {
  StyledModal,
  FormItemContainer,
  WrapperSelect,
  WithLoading,
} from 'common/components';

import * as GroupPart from './GroupPart';

import { CarouselSection, CarouselNext, CarouselPrevious } from '../Carousel';

import { useGetGroupDetail } from './hooks';
import { useGetGroupLayoutContext } from './GroupLayoutProvider';

import { filterIsPresentAllergens } from '../tab-allergens/utils';
import { getComponentFactsPanelType } from './utils';

import './GroupLayout.less';

const defaultModalProps = {
  destroyOnClose: true,
  width: 1140,
  maskClosable: false,
  bodyStyle: {
    minHeight: '60vh',
    maxHeight: '70vh',
  },
};

const MAX_SLIDES_TO_SHOW = 3;

export const PreviewGroup = (props) => {
  const { defaultGroupId, productGroups = [] } = props;

  const ref = useRef();

  const [formInstance] = Form.useForm();

  const { id: productId } = useParams();

  const [groupId, setGroupId] = useState(defaultGroupId);

  const { groupDetail, isLoading } = useGetGroupDetail({
    productId,
    groupId,
  });

  const {
    ingredientGroups = [],
    allergenGroups = [],
    nutritionFacts = [],
    supplementFacts = [],
  } = groupDetail;

  const factsPanelsGroup = nutritionFacts.concat(supplementFacts);

  const disabledCarouselBtn = factsPanelsGroup?.length <= MAX_SLIDES_TO_SHOW;

  const presentAllergenGroups = useMemo(
    () => filterIsPresentAllergens(allergenGroups),
    [allergenGroups]
  );

  return (
    <StyledModal {...defaultModalProps} {...props}>
      <WithLoading loading={isLoading}>
        <Form form={formInstance}>
          <GroupName>
            <WrapperSelect
              allowClear={false}
              placeholder='Please select a group name'
              onChange={(value) => setGroupId(value)}
              defaultValue={groupId}
            >
              {productGroups.map((group) => {
                return (
                  <Select.Option key={group.groupId} value={group.groupId}>
                    {group.groupName}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </GroupName>
        </Form>

        <Row gutter={[12, 16]} className='group-layout__assign-item'>
          <Col span={24}>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Typography.Text
                strong
                style={{ textTransform: 'uppercase', fontSize: 14 }}
              >
                Ingredients:
              </Typography.Text>

              {ingredientGroups?.length ? (
                ingredientGroups.map((ingredientGroup, index) => (
                  <GroupPart.PartStatements
                    key={ingredientGroup?.index}
                    className='scroller'
                    title={`Ingredient ${index + 1}`}
                    statements={ingredientGroup.ingredients}
                  />
                ))
              ) : (
                <GroupPart.EmptyGroup />
              )}
            </Space>
          </Col>

          <Col span={24}>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Typography.Text
                strong
                style={{ textTransform: 'uppercase', fontSize: 14 }}
              >
                Contains:
              </Typography.Text>

              {presentAllergenGroups?.length ? (
                presentAllergenGroups.map((allergenGroup, index) => (
                  <GroupPart.PartStatements
                    key={index}
                    className='scroller'
                    title={`Allergen ${index + 1}`}
                    statements={allergenGroup.allergens.map(
                      (allergen) => allergen.allergenType
                    )}
                  />
                ))
              ) : (
                <GroupPart.EmptyGroup />
              )}
            </Space>
          </Col>

          <Col span={24}>
            <Space>
              <CarouselPrevious
                onClick={() => {
                  if (ref?.current) ref.current.prev();
                }}
                disabled={disabledCarouselBtn} // need to check permission in here
              />
              <CarouselNext
                onClick={() => {
                  if (ref?.current) ref.current.next();
                }}
                disabled={disabledCarouselBtn} // need to check permission in here
              />
            </Space>

            <div style={{ marginTop: 12 }}>
              <CarouselSection
                ref={ref}
                // adaptiveHeight
                slidesToShow={3}
                infinite={false}
                variableWidth
              >
                {factsPanelsGroup?.length > 0 ? (
                  factsPanelsGroup?.map((factsPanel, index) => {
                    const FactsPanelType =
                      getComponentFactsPanelType(factsPanel);

                    return (
                      <Space key={factsPanel.id}>
                        <div
                          className='group-layout__assign-fact-panel'
                          key={index}
                        >
                          <FactsPanelType key={index} label={factsPanel} />
                        </div>
                      </Space>
                    );
                  })
                ) : (
                  <GroupPart.EmptyGroup />
                )}
              </CarouselSection>
            </div>
          </Col>
        </Row>
      </WithLoading>
    </StyledModal>
  );
};

export const GroupName = ({ children, ...rest }) => {
  return (
    <FormItemContainer
      name='groupName'
      displayName='Group Name'
      labelCol={{ span: 6 }}
      required
      {...rest}
    >
      {children}
    </FormItemContainer>
  );
};

export const GroupFactsPanelFormItem = ({ children, ...rest }) => {
  const { factsPanels } = useGetGroupLayoutContext();

  return (
    <FormItemContainer
      noStyle
      name='factsPanelsGroupIndices'
      labelCol={{ span: 0 }}
      wrapperCol={{ flex: 'auto' }}
      colon={false}
      valuePropName='checked'
      {...rest}
    >
      <Checkbox.Group style={{ width: '100%' }} value={factsPanels}>
        {children}
      </Checkbox.Group>
    </FormItemContainer>
  );
};

export const GroupFactsPanelType = ({
  children,
  className,
  checked,
  value: valueProps,
  ...rest
}) => {
  const { onSelectFactsPanels } = useGetGroupLayoutContext();

  return (
    <div className={`group-layout__facts-panel-type ${className}`} {...rest}>
      <Checkbox
        value={valueProps}
        checked={checked}
        onChange={onSelectFactsPanels}
        className='group-layout__facts-panel-checkbox'
      >
        {children}
      </Checkbox>
    </div>
  );
};

export const GroupAllergensFormItem = ({ children, ...rest }) => {
  const { allergens } = useGetGroupLayoutContext();

  return (
    <FormItemContainer
      noStyle
      name='allergenGroupIndices'
      labelCol={{ span: 0 }}
      wrapperCol={{ flex: 'auto' }}
      colon={false}
      valuePropName='checked'
      {...rest}
    >
      <Checkbox.Group style={{ width: '100%' }} value={allergens}>
        {children}
      </Checkbox.Group>
    </FormItemContainer>
  );
};

export const GroupAllergens = ({
  value,
  className,
  checked,
  children,
  ...rest
}) => {
  const { onSelectAllergens } = useGetGroupLayoutContext();

  return (
    <div className={`group-layout__allergens-item ${className}`} {...rest}>
      <Checkbox
        className='group-layout__allergens-checkbox'
        value={value}
        checked={checked}
        onChange={onSelectAllergens}
      >
        {children}
      </Checkbox>
    </div>
  );
};

export const GroupIngredientsFormItem = ({ children, ...rest }) => {
  const { ingredients } = useGetGroupLayoutContext();
  return (
    <FormItemContainer
      noStyle
      name='ingredientGroupIndices'
      labelCol={{ span: 0 }}
      wrapperCol={{ flex: 'auto' }}
      colon={false}
      valuePropName='checked'
      {...rest}
    >
      <Checkbox.Group style={{ width: '100%' }} value={ingredients}>
        {children}
      </Checkbox.Group>
    </FormItemContainer>
  );
};

export const GroupIngredients = ({
  value,
  className,
  checked,
  children,
  ...rest
}) => {
  const { onSelectIngredients } = useGetGroupLayoutContext();

  return (
    <div className={`group-layout__ingredients-item ${className}`} {...rest}>
      <Checkbox
        className='group-layout__ingredients-checkbox'
        value={value}
        checked={checked}
        onChange={onSelectIngredients}
      >
        {children}
      </Checkbox>
    </div>
  );
};
