import { useCallback, useEffect, useState } from 'react';

import { CustomNotification } from 'common/components';

import { useAsync, useCallbackRef, useControllableState } from 'hooks';

import { getFolderList, getItemsOfFolder } from 'services/new-folder';
import { getFolderOwnByUser, getFolderShortDetail } from 'services/folder';

import {
  buildHierarchyTreeFolder,
  getHierarchyExpandedKeys,
  getNodeTreeData,
  mappingFolderToTreeData,
  updateTreeData,
  findNodeTreeById,
} from './utils';

import { sleep } from 'utils/delay';
import { toUniqueList } from 'utils';
import { sortFolderToTop } from 'pages/folders/shared/utils';

export const filterParams = {
  folder: [
    {
      fieldName: 'type',
      filterType: 'Equal',
      value: 'folder', //
    },
  ],
  all: [],
};

const defaultFolderParam = {
  isAllowViewDetail: false,
};

const defaultFileParam = {
  isAllowViewDetail: false,
};

export const useGetFolderTreeData = (params) => {
  const {
    defaultTreeProp = [],
    defaultSelectedItem = {},
    defaultExpandedKeys = [],
    pageIndex = 1,
    pageSize = 20,
    filterType = 'all',
    folderParam = defaultFolderParam,
    fileParam = defaultFileParam,
    navigatedIdFolder,
  } = params;
  const { folderDetail } = useGetFolderDetail({
    folderId: navigatedIdFolder,
    isLoadContent: false,
  });

  const { hierarchy, level } = folderDetail;
  const isRoot = level === 1;

  const [statusGenerateTree, setStatusGenerateTree] = useState('idle');
  const [treeData, setTreeData] = useControllableState({
    defaultProp: defaultTreeProp,
  });
  const [expandedKeys, setExpandedKeys] = useControllableState({
    defaultProp: defaultExpandedKeys,
  });
  const [loadedKeys, setLoadedKeys] = useState([]);
  const [selectedItem, setSelectedItem] = useControllableState({
    defaultProp: defaultSelectedItem,
  });

  const handleUpdateTreeData = useCallback(
    (data) => setTreeData(data),
    [setTreeData]
  );

  const handleUpdateExpandedKeys = useCallback(
    (keys) => setExpandedKeys(keys),
    [setExpandedKeys]
  );

  const handleSelectNodeById = useCallbackRef((id) => {
    const foundNode = findNodeTreeById(treeData, id);

    if (!foundNode) return false; //* 4641: cannot found nested folder, so return result to rebuild tree

    setSelectedItem({
      selectedKeys: [foundNode.key],
      selectedFolder: foundNode.data,
    });
    setExpandedKeys(expandedKeys.concat([foundNode.key]));

    return foundNode;
  });

  const handleUpdateSelectedItem = useCallbackRef((item) => {
    const wasClickOnSameItem = selectedItem?.selectedKeys?.includes(
      item?.info?.node?.key
    );

    const isFolderType =
      item?.info?.node?.data?.type?.toLowerCase() === 'folder';

    if (wasClickOnSameItem) return;

    setSelectedItem({
      selectedKeys: item?.selectedKeys,
      selectedFolder: item?.info?.node?.data,
    });

    isFolderType &&
      setExpandedKeys(toUniqueList(expandedKeys.concat(item.selectedKeys)));
  });

  const handleUpdateLoadedKeys = useCallback(
    (keys) =>
      setLoadedKeys((prevVal) => {
        if (prevVal?.includes(keys)) {
          return prevVal;
        }
        return prevVal?.concat(keys);
      }),
    [setLoadedKeys]
  );

  const handleBuildRootTree = useCallbackRef(() => {
    const rootNode = getNodeTreeData({
      node: { ...folderDetail, type: 'folder' }, // folder doesn't have type value !!!
      folderParam,
      fileParam,
    });
    setExpandedKeys([rootNode.key]);
    setStatusGenerateTree('success');
    setSelectedItem({
      selectedKeys: [rootNode?.key],
      selectedFolder: rootNode?.data,
    });
    handleUpdateTreeData([rootNode]);
  });

  const handleBuildHierarchyTree = useCallbackRef(async (hierarchyId) => {
    const listIds = hierarchyId.split('/').filter(Boolean); //

    setStatusGenerateTree('loading');

    try {
      const hierarchyTreeData = await buildHierarchyTreeFolder({
        listIds,
        folderParam,
        fileParam,
        filterType,
      });
      const foundNode = findNodeTreeById(hierarchyTreeData, navigatedIdFolder);

      const expandedKeys = getHierarchyExpandedKeys(
        hierarchyTreeData,
        listIds.map((id) => parseInt(id))
      );

      setExpandedKeys(expandedKeys);
      setLoadedKeys(expandedKeys);
      setSelectedItem({
        selectedKeys: [foundNode?.key],
        selectedFolder: foundNode?.data,
      });
      setStatusGenerateTree('success');
      handleUpdateTreeData(hierarchyTreeData);

      return {
        foundNode,
        hierarchyTreeData,
      };
    } catch (error) {
      setStatusGenerateTree('error');
      handleUpdateTreeData([]);
    }
  });

  useEffect(() => {
    if (isRoot) {
      handleBuildRootTree();
      return;
    }

    //* build tree from subfolder
    if (hierarchy) {
      // hierarchy is parent path
      const fullHierarchyId = `${hierarchy}/${navigatedIdFolder}`;
      handleBuildHierarchyTree(fullHierarchyId);
    }
  }, [
    isRoot,
    hierarchy,
    handleBuildHierarchyTree,
    handleBuildRootTree,
    navigatedIdFolder,
  ]);

  // Need to catch error, this function is using pageSize, pageIndex as params. Wrap this function with useCallbackRef ???
  const handleLoadMoreFolder = async ({ key, children, data }) => {
    if (loadedKeys.includes(key)) return;

    const { id: folderId } = data;

    const params = {
      folderId,
      pageIndex, // change later
      pageSize, // change later
      filters: filterParams[filterType],
      sort: [
        {
          fieldName: 'id',
          isAscending: false,
        },
      ], // new first,
    };

    const response = await getItemsOfFolder(params);

    return new Promise(async (resolve) => {
      if (children || response === null) {
        resolve();
        return;
      }

      const sortedData = response?.data?.gridData?.sort(sortFolderToTop);

      const mappedTreeData = mappingFolderToTreeData(
        sortedData,
        folderParam,
        fileParam
      );

      await sleep(500); // sleep a little bit to update tree (get idea from antd :))
      setTreeData((prevTreeData) =>
        updateTreeData(prevTreeData, key, mappedTreeData)
      );
      resolve();
    });
  };

  const handleSetStatusTree = (status) => {
    setStatusGenerateTree(status);
  };

  const handleSelectDeepNestedFolder = (folder) => {
    const folderId = folder?.type?.toLowerCase() === 'folder' && folder?.id;

    if (!folderId) return;

    const foundTreeNode = handleSelectNodeById(folderId);

    if (!foundTreeNode) rebuildTreeToSelectFolder(folder);
  };

  const rebuildTreeToSelectFolder = async (selectedFolder) => {
    if (!selectedFolder) return;

    const buildingHierarchy = `${selectedFolder.hierarchy}/${selectedFolder.id}`;

    const { foundNode } = await handleBuildHierarchyTree(buildingHierarchy);

    if (!foundNode) return;

    //* if foundNode mean building succeed
    await sleep(1); //* make sure state updated
    handleSelectNodeById(selectedFolder.id);
  };

  return {
    isLoadingTree:
      statusGenerateTree === 'idle' || statusGenerateTree === 'loading',
    treeData,
    expandedKeys,
    loadedKeys,
    selectedItem,
    handleSelectNodeById,
    handleUpdateSelectedItem,
    handleUpdateTreeData,
    handleLoadMoreFolder,
    handleUpdateExpandedKeys,
    handleUpdateLoadedKeys,
    handleSetStatusTree,
    handleBuildHierarchyTree,
    handleSelectDeepNestedFolder,
  };
};

export const useGetAllFolderGrid = ({ isEnabled, onResetParentId }) => {
  const { data: initialData, run, status, setData } = useAsync();

  const [fetchMoreFolderLoading, setFetchMoreFolderLoading] = useState(false);

  const handleCacheListFolder = (key, data) => {
    setData({
      ...initialData,
      [key]: data,
    });
  };

  const getGridFolder = () => {
    //? need to improve: use load on scroll for better performance
    const params = {
      folderId: null,
      pageIndex: 1,
      pageSize: 9999,
      filters: [
        {
          fieldName: 'isOwner',
          filterType: 'equal',
          value: true,
        },
      ],
      sort: [
        {
          fieldName: 'id',
          isAscending: false,
        },
      ],
    };

    run(getFolderList(params));
  };

  const handleSearchFolder = async (searchText) => {
    onResetParentId?.();
    handleCacheListFolder('home', []);

    if (!searchText) {
      getGridFolder();
      return;
    }

    try {
      const { data, isSuccess } = await getFolderOwnByUser({
        Search: searchText,
      });
      if (isSuccess) {
        handleCacheListFolder('home', data?.folderList);
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Folder is not found');
    }
  };

  const handleFetchMoreFolder = async (folderId) => {
    const params = {
      folderId,
      pageIndex: 1, // change later
      pageSize: 9999, // change later
      filters: [
        {
          fieldName: 'isOwner',
          filterType: 'equal',
          value: true,
        },
        {
          fieldName: 'type',
          filterType: 'equal',
          value: 'folder',
        },
      ],
      sort: [
        {
          fieldName: 'id',
          isAscending: false,
        },
      ],
    };
    try {
      setFetchMoreFolderLoading(true);
      const { isSuccess, data, message } =
        (await getItemsOfFolder(params)) ?? {};
      if (isSuccess) {
        handleCacheListFolder(folderId, data?.gridData);
      } else {
        CustomNotification.error(message ?? 'Folder is not found');
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Folder is not found');
    }

    setFetchMoreFolderLoading(false);
  };
  useEffect(() => {
    if (isEnabled) {
      getGridFolder();
    }
  }, [run, isEnabled]);

  useEffect(() => {
    if (!initialData?.['home'] && initialData?.gridData)
      setData({
        home: initialData?.gridData ?? [],
      });
  }, [JSON.stringify(initialData)]);

  return {
    isLoading: status === 'pending' || fetchMoreFolderLoading,
    folderData: initialData,
    getGridFolder,
    handleSearchFolder,
    handleFetchMoreFolder,
  };
};

export const useGetFolderDetail = ({ folderId, isLoadContent = false }) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (folderId) {
      run(
        getFolderShortDetail({
          id: folderId,
          IsLoadContent: isLoadContent,
        })
      );
    }
  }, [folderId, run]);

  return {
    folderDetail: data ?? {},
  };
};
