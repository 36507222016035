import produce from 'immer';
import * as types from './constants';

import { mapToCommonFetchState } from 'pages/qa-spec/mappers';

const INIT_FETCH_STATE = {
  error: null,
  loading: false,
  data: null,
};

// initial state
export const initialState = {
  isTabExpanded: false,
  reloadDocumentAndCertificateTab: false,
  reloadDocumentWhenDelete: false,
  editBulk: false,
};

/* eslint-disable default-case, no-param-reassign */
const qaSpecReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.QA_TOGGLE_TAB_EXPAND:
        draft.isTabExpanded = action.status;
        break;
      case types.QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB_SUCCESS:
        draft.reloadDocumentAndCertificateTab = action.status;
        break;
      case types.QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE_SUCCESS:
        draft.reloadDocumentWhenDelete = action.status;
        break;
      case types.QA_SPEC_EDIT_BULK_SUCCESS:
        draft.editBulk = action.editBulk;
        break;
      default:
        break;
    }
  });

export default qaSpecReducer;
