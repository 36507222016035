import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useQueryClient } from '@tanstack/react-query';

import cloneDeep from 'lodash/cloneDeep';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Pagination, Row, Typography, Col, Empty } from 'antd';
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

import { AgGridReact } from 'ag-grid-react';

import * as api from 'config/axios';
import * as formatDate from 'utils/formatDate';
import { updateSizeCurrent } from 'common/components/grid-view/utils';

import { AbandonDialog } from 'common/components/grid-view/components/';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import * as globalSelectors from 'redux/global/selectors';

import { DEFAULT_SORT } from 'static/Constants';

import LinkRender from './components/renderers/LinkRender';
import DragRender from './components/renderers/DragRender';
import CheckboxSelectionRender from './components/renderers/CheckboxSelectionRender';
import HeaderCheckboxRender from './components/renderers/HeaderCheckboxRender';
import HeaderNullRender from './components/renderers/HeaderNullRender';
import CheckboxRender from './components/renderers/CheckboxRender';

import usePrevious from 'hooks/usePrevious';
import * as _ from 'lodash';
import { useStatePrevious } from 'hooks/usePrevious';

import { useGetColumnDefs } from './hooks/getColumnDefsHook';

import DisplayTotalItem from '../grid-view/DisplayTotalItem';

import SelectionLog from './components/selection/SelectionLog';
import { GridContext } from './context/gridContext';

import './AgGrid.less';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-040843 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Global Vertical Innovations, LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 11 May 2024 )____[v2]_MTcxNTM4MjAwMDAwMA==d035e6c32ae72202e23beccd712ab105'
);

const AgGrid = React.memo((props) => {
  const {
    styleGrid,
    columnDefs,
    urlGrid,
    urlGridDistinct,
    paramsGrid,
    gridView = false,
    responseParams,
    requestParams,
    callbackDataListSelection,
    hidePagination,
    filterFromMainPage,
    hideAgSetColumnFilter,
    getGridApi,
    isPreview,
    isShowItemSelectionLog = true,
    isCacheEditMode = false,
    getDataFromGrid,
    pageNumberPagination,
    pageSizePagination,
    rowClassRules,
    frameworkComponents = {},
    gridId,
    isHiddenAbbandonDialog = false,
    onHandleSelectedRowCustom,
    urlDownload,
    triggerDownload,
    onDownloadGridHandler,
    downloadFileType,
    gridConfigProps = {},
    shouldReloadDetailGrid,
    onReloadGridCallback,
    isColumnsChanged = true,
    onGetLengthSize,
    isDownloadAllColumns,
    reloadGrid,
    notShowHeaderCheckbox,
    minimizePagination,
    initialPageSize,
    resizePagination,
    showCheckboxSelectionRender = true,
    handleChangePagination,
    mapId,
    nodeIdName,
    forceLoading,
    isSmallSizePagination = false,
    applyDefaultSort = true,
    showLessItem,
    sortAndFilterServerside = false,
    rowHeight,
    sortDataHandler, //? function
    onRowDoubleClicked,
  } = props;

  const refGrid = useRef();
  const refSelection = useRef();

  const queryClient = useQueryClient();

  const refItemSelection = useRef([]);
  const refItemsSelectionDetail = useRef([]);
  const refPageItemsSelection = useRef([]);
  const refPageItemsSelectionDetail = useRef([]);

  const refVirtualColumns = useRef([]);

  const dispatch = useDispatch();
  const currentRequestDataParams = useRef({});
  const filterFromMainPageRef = useRef([]);

  const [isGridReady, setIsGridReady] = useState(false);
  const [agGridPageSize, setAgGridPageSize, refAgGridPageSize] =
    useStatePrevious(initialPageSize || 20);
  const [
    agGridCurrentPagination,
    setAgGridCurrentPagination,
    refAgGridCurrentPagination,
  ] = useStatePrevious(1);

  const prevAgGridPageSize = usePrevious(agGridPageSize);
  const prevAgGridCurrentPagination = usePrevious(agGridCurrentPagination);

  const [
    agGridTotalPagination,
    setAgGridTotalPagination,
    refAgGridTotalPagination,
  ] = useStatePrevious(0);
  const [agGridNameColumnFilter, setAgGridNameColumnFilter] = useState(false);

  const [visibleSelection, setVisibleSelection] = useState(false);

  const [currentDefColumns, setCurrentDefColumns] = useState(null);

  const [isSwitchingPage, setIsSwitchingPage] = useState(false);
  const [gridFetched, setGridFetched, refGridFetched] = useStatePrevious(false);
  const [isFetching, setIsFetching] = useState(false);

  const isShowDetailOn = useSelector(globalSelectors.selectShowDetail());
  const showAddToFolder = useSelector(globalSelectors.selectShowAddToFolder());

  const resetSelection = useCallback(() => {
    refItemSelection.current = [];
    refPageItemsSelection.current = [];
    refItemsSelectionDetail.current = [];
    refPageItemsSelectionDetail.current = [];

    setItemsSelectionLog(refItemSelection.current);
    setPageItemsSelectionLog(refPageItemsSelection.current);
    setItemsSelectionDetailLog(refItemsSelectionDetail.current);
    setPageItemsSelectionDetailLog(refPageItemsSelectionDetail.current);
  }, []);

  const deselectAll = useCallback(() => {
    refGrid.current.api && refGrid.current.api.deselectAll();
  }, []);

  const refreshAggrid = useCallback(() => {
    resetSelection();
    deselectAll();
    setClassNameHeaderCheckbox(
      refAgGridCurrentPagination.current,
      refAgGridTotalPagination.current,
      refAgGridPageSize.current
    );
  }, []);

  const isItemIdEqualToNodeId = (item, nodeId) => {
    return _.toString(item.id) === nodeId;
  };

  const setItemsSelectionLog = (value) =>
    refSelection.current.setItemsSelectionLog(value);

  const setPageItemsSelectionLog = (value) =>
    refSelection.current.setPageItemsSelectionLog(value);

  const setItemsSelectionDetailLog = (value) =>
    refSelection.current.setItemsSelectionDetailLog(value);

  const setPageItemsSelectionDetailLog = (value) =>
    refSelection.current.setPageItemsSelectionDetailLog(value);

  const setClickedRow = (rowData) =>
    refSelection.current.setRefLastSelectedRowData(rowData);

  const cols = useGetColumnDefs({
    urlGrid,
    columnDefs,
    notShowHeaderCheckbox,
    hideAgSetColumnFilter,
  });

  useEffect(() => {
    //* set page size and page current from page info props
    const setPageInfoFromProps = () => {
      if (
        pageNumberPagination &&
        pageNumberPagination !== refAgGridCurrentPagination.current
      ) {
        setAgGridCurrentPagination(pageNumberPagination);
      }

      if (
        pageSizePagination &&
        pageSizePagination !== refAgGridPageSize.current
      ) {
        setAgGridPageSize(pageSizePagination);
      }
    };

    setPageInfoFromProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberPagination, pageSizePagination]);

  useEffect(() => {
    const isPageSizeChange = () =>
      agGridPageSize && prevAgGridPageSize !== agGridPageSize;

    const isPageNumberChange = () =>
      agGridCurrentPagination &&
      prevAgGridCurrentPagination !== agGridCurrentPagination;

    const gridApi = refGrid.current.api;

    //* handle load page
    const goToNewPage = async (options) => {
      if (agGridPageSize && agGridCurrentPagination && gridApi) {
        gridApi.gridOptionsWrapper.setProperty(
          'cacheBlockSize',
          agGridPageSize
        );

        let datasource = serverSideDataSource({
          page: agGridCurrentPagination,
          pageSize: agGridPageSize,
        });
        gridApi.setServerSideDatasource(datasource);
      }
    };

    //* handle refetch page when page info update
    const handleRefetchWhenPageInfoChange = async () => {
      const isPageSizeChanged = isPageSizeChange();
      const isPageNumberChanged = isPageNumberChange();

      if (!gridFetched) {
        goToNewPage();
      } else {
        //* if page info state change
        if (isPageNumberChanged || isPageSizeChanged) {
          goToNewPage();
        }
      }

      setIsSwitchingPage(false);
    };

    handleRefetchWhenPageInfoChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agGridPageSize,
    agGridCurrentPagination,
    prevAgGridPageSize,
    prevAgGridCurrentPagination,
    gridFetched,
    isGridReady,
  ]);

  useEffect(() => {
    //* update page size and page current

    if (
      agGridPageSize !== prevAgGridPageSize ||
      agGridCurrentPagination !== prevAgGridCurrentPagination
    ) {
      if (isCacheEditMode) {
        //* this part is for page number and page size passed down by props
        dispatch(
          actionsGridView.updateGridViewPagination({
            gridId: gridId,
            pageNumber: agGridCurrentPagination,
            pageSize: agGridPageSize,
          })
        );
      } else {
        updateSizeCurrent(
          dispatch,
          agGridCurrentPagination,
          agGridPageSize,
          gridId || window.location.pathname
        );
      }
    }
  }, [
    agGridPageSize,
    agGridCurrentPagination,
    prevAgGridPageSize,
    prevAgGridCurrentPagination,
    gridId,
    dispatch,
    isCacheEditMode,
  ]);

  useEffect(() => {
    if (hidePagination) {
      setAgGridPageSize(10000);
    }
  }, []);

  // download grid data
  const callApiDownloadGrid = () => {
    const params = _.cloneDeep(currentRequestDataParams.current);

    // download all data of grid, not just 1 page
    params.pageIndex = 1;
    params.pageSize = 999999999;

    // add columns to download display columns only
    params.columns = isDownloadAllColumns ? null : getDownloadColumns();

    // add file type
    params.fileType = downloadFileType;

    // call api download
    onDownloadGridHandler &&
      onDownloadGridHandler(
        api
          .sendPost(urlDownload, params)
          .then((response) => {
            if (response?.isSuccess) {
              return api.sendDownload({ url: response?.data?.url });
            } else {
              return new Promise.reject({ message: response.message });
            }
          })
          .catch((err) => {
            return new Promise.reject(err);
          })
      );
  };

  const getDownloadColumns = () => {
    const agGridColumnApi = refGrid.current.columnApi;

    const displayColumns = agGridColumnApi.getAllDisplayedColumns();
    const columnsParams = displayColumns.reduce((accumulator, currentCol) => {
      const displayName = currentCol?.colDef?.displayName;

      //* check for orgFieldName first to get field name of hidden header title columns
      const orgFieldName = currentCol?.colDef?.orgFieldName;

      if (orgFieldName) {
        accumulator.push({
          fieldName: orgFieldName,
          displayName: orgFieldName,
        });
        return accumulator;
      }

      //* check for separateFieldName next to get field name of merge values columns
      const separateFieldName = currentCol?.colDef?.separateFieldName;
      if (separateFieldName && separateFieldName.length) {
        separateFieldName.length &&
          separateFieldName.forEach((field) => {
            accumulator.push({
              fieldName: field,
              displayName: field,
            });
          });

        return accumulator;
      }

      //* finally, when col def not contain orgFieldName and separateFieldName, check colDef field for normal columns
      const colField = currentCol?.colDef?.field;
      if (!colField) return accumulator;
      if (colField === 'checkboxSelection') return accumulator;

      accumulator.push({
        fieldName: colField,
        displayName: displayName || colField,
      });
      return accumulator;
    }, []);

    return columnsParams;
  };

  useEffect(() => {
    if (triggerDownload && urlDownload) callApiDownloadGrid();
    // eslint-disable-next-line
  }, [triggerDownload]);

  const searchTextRequest = requestParams?.search?.searchText;
  const advCriteria = requestParams?.advCriteria;
  const searchTextParams = paramsGrid?.search?.searchText;
  const paramsGridStringify = JSON.stringify(paramsGrid);
  const requestParamStringify = JSON.stringify(requestParams);

  const reloadData = ({ keepPageIndex } = {}) => {
    const agGridApi = refGrid.current.api;

    if (agGridApi) {
      //* set current pagination UI to page 1
      setAgGridCurrentPagination(1);

      let datasource = serverSideDataSource({
        page: 1,
        pageSize: refAgGridPageSize.current,
      });
      agGridApi && agGridApi.setServerSideDatasource(datasource);
      refreshAggrid();
      onReloadGridCallback && onReloadGridCallback();
    }
  };

  const onRequestServerSideDataSource = useCallback(() => {
    reloadData();
    //eslint-disable-next-line
  }, [
    searchTextRequest,
    searchTextParams,
    urlGrid,
    urlGridDistinct,
    paramsGridStringify,
    requestParamStringify,
    //* NOTE: filterFromAsset - empty array make it reloadData when no cahnge
    JSON.stringify(filterFromMainPage),
    // advCriteria,
    // columnDefs,
  ]);

  const onRequestServerSideDataSourceKeepPageIndex = useCallback(() => {
    if (shouldReloadDetailGrid) reloadData({ keepPageIndex: true });
    //eslint-disable-next-line
  }, [shouldReloadDetailGrid]);

  const onRequestServerSideDataSourceWithFlag = useCallback(() => {
    if (reloadGrid) {
      onPurgeServerSideCache();
    }
    //eslint-disable-next-line
  }, [reloadGrid]);

  // reload data and refresh grid when search
  useEffect(() => {
    onRequestServerSideDataSource();
    //eslint-disable-next-line
  }, [onRequestServerSideDataSource]);

  // reload data
  useEffect(() => {
    onRequestServerSideDataSourceWithFlag();
    //eslint-disable-next-line
  }, [onRequestServerSideDataSourceWithFlag]);

  useEffect(() => {
    setCurrentDefColumns([]);
    //eslint-disable-next-line
  }, [shouldReloadDetailGrid]);

  // reload data when shouldReloadDetailGrid and keep page index
  useEffect(() => {
    onRequestServerSideDataSourceKeepPageIndex();

    //eslint-disable-next-line
  }, [onRequestServerSideDataSourceKeepPageIndex]);

  // unmount
  useEffect(() => {
    return () => {
      refItemSelection.current = [];
    };
  }, []);

  useEffect(() => {
    filterFromMainPageRef.current = filterFromMainPage;
  }, [filterFromMainPage]);

  const serverSideDataSource = ({ page, pageSize } = {}) => {
    return {
      // called by the grid when more rows are required
      getRows: function (params) {
        setIsFetching(true);

        //* if page or pageSize undefined get default grid param from aggrid

        if (!page || !pageSize) {
          pageSize = params.api.paginationGetPageSize();
          page = params.api.paginationGetCurrentPage() + 1;
        }

        let paramsApi = {
          pageSize: pageSize,
          pageIndex: page,
          sort: [],
          filters: [],
        };

        // if there are more standard request params
        if (requestParams) {
          paramsApi = { ...paramsApi, ...requestParams };
        }
        if (params.request.sortModel.length > 0) {
          // Clone a new array, don't mutate the original, prevent stupid AgGrid's  bugs
          let newSort = [];

          params.request.sortModel.forEach((val) => {
            newSort.push({
              fieldName: val.colId,
              isAscending: val.sort === 'asc' ? true : false,
            });
          });

          paramsApi.sort = newSort;
        } else {
          if (applyDefaultSort) {
            paramsApi.sort = DEFAULT_SORT;
          }
        }
        let keyFilter = Object.keys(params.request.filterModel);
        let filters = filterParams(keyFilter, params.request.filterModel);
        if (filterFromMainPage && filterFromMainPage.length > 0) {
          filters = filters.concat(filterFromMainPage);
        }
        if (requestParams?.filters?.length > 0) {
          filters = filters.concat(requestParams.filters);
        }

        if (requestParams?.sort?.length > 0) {
          paramsApi.sort = paramsApi.sort.concat(requestParams.sort);
        }

        paramsApi.filters = filters;

        Object.assign(paramsApi, paramsGrid);

        currentRequestDataParams.current = paramsApi;

        api
          .sendPost(urlGrid, paramsApi)
          .then((response) => {
            //* fetch data first time success => allow to fetch saved pagination config
            if (response) {
              !refGridFetched.current && setGridFetched(true);
              setIsFetching(false);
            }

            let data;
            if (responseParams) {
              data = response[responseParams];

              // !New
              // ?Hide loading progress when data = null
              if (data) {
                // callback response data
                getDataFromGrid && getDataFromGrid(response.data.gridData);
              } else {
                const loadingElement =
                  document?.getElementsByClassName('ag-row-loading');
                if (loadingElement?.length > 0) {
                  loadingElement[0].style.display = 'none';
                }
              }
            } else {
              data = response;
            }
            if (
              data &&
              data.gridData &&
              data.gridData.length >= 0 &&
              data.paging &&
              data.paging.totalRecord >= 0
            ) {
              // supply rows for requested block to grid
              const gridData = data.gridData ? data.gridData : [];

              const gridDataMappedId = mapId ? gridData.map(mapId) : gridData;
              const sortedGridData = sortDataHandler
                ? cloneDeep(gridDataMappedId)?.sort(sortDataHandler)
                : gridDataMappedId;

              let nextTotalRecord;

              //todo
              const numberOfItemNotInLastPage =
                refAgGridCurrentPagination.current * refAgGridPageSize.current -
                data.paging.totalRecord;

              if (
                numberOfItemNotInLastPage > 0 &&
                data.paging.totalRecord !== 0
              ) {
                nextTotalRecord =
                  refAgGridPageSize.current - numberOfItemNotInLastPage;
              } else {
                nextTotalRecord = data.paging.totalRecord;
              }

              params.successCallback(sortedGridData, nextTotalRecord);
              setAgGridTotalPagination(data.paging.totalRecord);
              onGetLengthSize && onGetLengthSize(data.paging.totalRecord);

              params.api.forEachNode((node) => {
                // return;
                if (refItemSelection.current.indexOf(node.id) > -1) {
                  node.setSelected(true);

                  if (showCheckboxSelectionRender) {
                    node.setDataValue('checkboxSelection', true);
                  }
                } else {
                  node.setSelected(false);

                  if (showCheckboxSelectionRender) {
                    node.setDataValue('checkboxSelection', false);
                  }
                }

                //Should update after demo
                if (isPreview) {
                  if (node?.data?.showInMemberPreview) {
                    node.setSelected(true);

                    if (showCheckboxSelectionRender) {
                      node.setDataValue('checkboxSelection', true);
                    }
                    refItemSelection.current.push(node.id);
                    refItemsSelectionDetail.current.push(node?.data);

                    refPageItemsSelection.current.push({
                      page: agGridCurrentPagination,
                      items: [node.id],
                    });
                    refPageItemsSelectionDetail.current.push({
                      page: agGridCurrentPagination,
                      items: [node?.data],
                    });
                  }
                }
              });
              //Should update after demo
              setClassNameHeaderCheckbox(
                page,
                data.paging.totalRecord,
                pageSize
              );

              window.rowDataServerSide = data.gridData;
            } else {
              //* show no data
              params.successCallback([], 0);
            }
          })
          .catch((error) => {
            // inform grid request failed
            params.failCallback();
          });
      },
    };
  };

  const onPurgeServerSideCache = () => {
    const agGridApi = refGrid.current?.api;

    if (!agGridApi) return;

    agGridApi && agGridApi.refreshServerSide({ purge: true });

    refreshAggrid();
  };

  const onGridReadyfunction = useCallback((params) => {
    setIsGridReady(true);

    params.api.rivirPurgeServerSideCache = onPurgeServerSideCache;

    getGridApi && getGridApi(params.api);
  }, []);

  const onChangePagination = (page, pageSize) => {
    const isPageSizeChanged = pageSize !== agGridPageSize;

    const nextPage = isPageSizeChanged ? 1 : page;

    setAgGridCurrentPagination(nextPage);
    setAgGridPageSize(pageSize);

    handleChangePagination && handleChangePagination(page);
  };

  const onShowSizeChange = (current, size) => {
    const agGridApi = refGrid.current.api;

    setAgGridPageSize(size);
    agGridApi.gridOptionsWrapper.setProperty('cacheBlockSize', size);

    resetSelection();
  };

  const onSelectionChanged = (params) => {
    // setClassNameHeaderCheckbox(params);
  };

  const onRowSelected = useCallback((params) => {
    if (showCheckboxSelectionRender) {
      params.node.setDataValue('checkboxSelection', params.node.selected);
    }
  }, []);

  const filterParams = (keyFilter, modelFilter, columnName) => {
    let filterParams = [];
    if (keyFilter.length > 0) {
      keyFilter &&
        keyFilter.length > 0 &&
        keyFilter.forEach((key) => {
          let filterModel = modelFilter[key];
          if (
            filterModel &&
            filterModel.filterType === 'multi' &&
            filterModel.filterModels &&
            filterModel.filterModels.length > 0
          ) {
            filterModel.filterModels.forEach((model) => {
              const item = getFilterParamsItem(model, key, columnName);
              item && filterParams.push(item);
            });
          } else {
            // when filter type is AgSetColumnFilter, filterModel is not an array, so we don't need to loop through
            const item = getFilterParamsItem(filterModel, key, columnName);
            item && filterParams.push(item);
          }
        });
    }
    return filterParams;
  };

  const getFilterParamsItem = (model, filterKey, columnName) => {
    if (!model) return;
    let filterType = 'Like';
    let value = model.filter;
    let otherValue = false;
    if (model.filterType === 'set') {
      if (filterKey === columnName) return;
      if (model.values && model.values.length === 0) return;
      filterType = 'In';
      if (
        (model.values &&
          model.values.length > 0 &&
          model.values[0] === 'True') ||
        model.values[0] === 'False'
      ) {
        filterType = 'Equal';
        value = model.values[0] === 'True' ? true : false;
      }
    }
    if (model.type === 'equals') {
      filterType = 'Equal';
      if (model.filterType === 'date') {
        filterType = 'Equal';
        value = formatDate.formatYMD(model.dateFrom, 'day');
      }
    }
    if (model.type === 'notEqual') {
      filterType = 'NotEqual';
    }
    if (model.type === 'contains') {
      filterType = 'Like';
    }
    if (model.type === 'lessThan') {
      filterType = 'LessThan';
      if (model.filterType === 'date') {
        value = formatDate.formatYMD(model.dateFrom, 'day');
      }
    }
    if (model.type === 'lessThanOrEqual') {
      filterType = 'LessThanOrEqual';
      if (model.filterType === 'date') {
        value = formatDate.formatYMD(model.dateFrom, 'day');
      }
    }
    if (model.type === 'greaterThan') {
      filterType = 'GreaterThan';
      if (model.filterType === 'date') {
        value = formatDate.formatYMD(model.dateFrom, 'day');
      }
    }
    if (model.type === 'greaterThanOrEqual') {
      filterType = 'GreaterThanOrEqual';
      if (model.filterType === 'date') {
        value = formatDate.formatYMD(model.dateFrom, 'day');
      }
    }
    if (model.type === 'blank') {
      filterType = 'IsNull';
    }
    if (model.type === 'notBlank') {
      filterType = 'IsNotNull';
    }
    let obj = {
      FieldName: filterKey,
      filterType: filterType,
    };
    if (filterType === 'In') {
      Object.assign(obj, { values: model.values });
    } else {
      Object.assign(obj, { value: value });
    }
    if (otherValue) {
      Object.assign(obj, { otherValue: otherValue });
    }

    return obj;
  };

  const handleGetDistinctQueries = async ({ distinctKeys, paramsApi }) => {
    let responseData = await queryClient.fetchQuery({
      queryKey: distinctKeys,
      queryFn: async () => {
        return await api.sendPost(urlGridDistinct, paramsApi);
      },
      staleTime: 0,
    });

    return { data: responseData };
  };

  const displaySetFilter = (instance, show) => {
    if (instance) {
      if (instance.getChildFilterInstance(0)?.filterNameKey === 'setFilter') {
        instance.getChildFilterInstance(0).setDisplayed(show);
      }
      if (instance.getChildFilterInstance(1)?.filterNameKey === 'setFilter') {
        instance.getChildFilterInstance(1).setDisplayed(show);
      }
    }
  };

  const onMenuClick = async (params) => {
    let columnId = params.column?.colId;
    const fieldName = columnId;

    if (columnId) {
      setAgGridNameColumnFilter(columnId);
      let modelFilterChanged = params.api.getFilterModel();

      let keyFilter = Object.keys(modelFilterChanged);
      let filters = filterParams(keyFilter, modelFilterChanged, columnId);
      if (filterFromMainPageRef.current?.length)
        filters = [...filters, ...filterFromMainPageRef.current];

      let paramsApi = { fieldName: columnId, filters: filters };

      paramsApi = {
        ...paramsApi,
        ...requestParams,
        ...paramsGrid,
      };
      const searchText = paramsGrid?.search?.searchText;
      const distinctKeys = ['ag-grid', fieldName, filters, searchText];

      try {
        const response = await handleGetDistinctQueries({
          distinctKeys,
          paramsApi,
        });

        let data;

        if (responseParams) {
          data = response?.data?.[responseParams];
        } else {
          data = response?.data;
        }

        let instance = params.api.getFilterInstance(columnId);

        if (data && data.length > 0 && data.length < 51) {
          if (instance.__proto__.hasOwnProperty('setFilterValues')) {
            // supply rows for requested block to grid
            instance.setFilterValues(data);
            displaySetFilter(instance, true);
            instance.applyModel();
          } else {
            displaySetFilter(instance, true);
            if (!instance.getChildFilterInstance(1)) return;
            instance.getChildFilterInstance(1).setFilterValues(data);
            instance.getChildFilterInstance(1).applyModel();
          }
        } else {
          if (instance.__proto__.hasOwnProperty('setFilterValues')) {
            // supply rows for requested block to grid
            instance.setFilterValues([]);
            displaySetFilter(instance, false);
            instance.applyModel();
          } else {
            displaySetFilter(instance, false);

            if (!instance.getChildFilterInstance(1)) return;
            instance.getChildFilterInstance(1).setFilterValues([]);
            instance.getChildFilterInstance(1).applyModel();
          }
        }
      } catch (error) {
        console.error('Error fetching filter data: ', error);
      }
    }
  };

  const onHandleSortChanged = (event) => {
    refreshAggrid();
  };

  const onHandleFilterChanged = async (event) => {
    let modelFilterChanged = event.api.getFilterModel();
    let keyFilter = Object.keys(modelFilterChanged);
    let filters = filterParams(
      keyFilter,
      modelFilterChanged,
      agGridNameColumnFilter
    );
    if (filterFromMainPageRef.current?.length)
      filters = [...filters, ...filterFromMainPageRef.current];
    let paramsApi = {
      fieldName: agGridNameColumnFilter,
      filters: filters,
    };

    const searchText = paramsGrid?.search?.searchText;

    paramsApi = {
      ...paramsApi,
      ...paramsGrid,
      ...requestParams,
    };

    const distinctKeys = [
      'ag-grid',
      agGridNameColumnFilter,
      filters,
      searchText,
    ];

    try {
      const response = await handleGetDistinctQueries({
        distinctKeys,
        paramsApi,
      });
      if (agGridCurrentPagination !== 1) {
        setAgGridCurrentPagination(1);
      }
      let instance = event.api.getFilterInstance(agGridNameColumnFilter);
      if (
        modelFilterChanged[agGridNameColumnFilter] === undefined ||
        modelFilterChanged[agGridNameColumnFilter].filterModels[1] === null ||
        modelFilterChanged[agGridNameColumnFilter].filterModels[1].values
          .length === 0
      ) {
        let data;
        if (responseParams) {
          data = response?.data?.[responseParams];
        } else {
          data = response?.data;
        }
        if (data && data.length > 0 && data.length < 51) {
          // supply rows for requested block to grid
          instance.getChildFilterInstance(1).setFilterValues(data);
          displaySetFilter(instance, true);
        } else {
          displaySetFilter(instance, false);
          instance.getChildFilterInstance(1).setFilterValues([]);
        }
        instance.getChildFilterInstance(1).applyModel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      resetSelection();
      deselectAll();
      setClassNameHeaderCheckbox(
        refAgGridCurrentPagination.current,
        refAgGridTotalPagination.current,
        refAgGridPageSize.current
      );
    }
  };

  const setClassNameHeaderCheckbox = (pageCurrent, totalPage, pageSize) => {
    let page = pageCurrent || agGridCurrentPagination;
    let total = totalPage || agGridTotalPagination;
    let size = pageSize || agGridPageSize;
    let pageItemsSelectionFilter =
      (refPageItemsSelection.current &&
        refPageItemsSelection.current.length > 0 &&
        refPageItemsSelection.current.filter(
          (pageItem) => pageItem.page === page
        )) ||
      [];

    let length =
      (pageItemsSelectionFilter &&
        pageItemsSelectionFilter.length > 0 &&
        pageItemsSelectionFilter[0].items &&
        pageItemsSelectionFilter[0].items.length) ||
      0;
    let lastPage = Math.ceil(totalPage / size) === page ? true : false;
    let lastLength = totalPage - (page - 1) * size;
    if (
      length === size ||
      length === total ||
      (lastPage && length === lastLength)
    ) {
      let element = document.getElementsByClassName(urlGrid)[0];
      let classNameCheckbox =
        element && element.className.replace('ant-checkbox-indeterminate', '');
      if (classNameCheckbox) {
        classNameCheckbox = classNameCheckbox.replace(
          'ant-checkbox-checked',
          ''
        );
        classNameCheckbox = classNameCheckbox.concat(' ant-checkbox-checked');
      }
      if (element) {
        element.className = classNameCheckbox;
      }
    } else if (length === 0) {
      let element = document.getElementsByClassName(urlGrid)[0];
      if (element) {
        let classNameCheckbox = element.className.replace(
          'ant-checkbox-indeterminate',
          ''
        );
        classNameCheckbox = classNameCheckbox.replace(
          'ant-checkbox-checked',
          ''
        );
        if (element) {
          element.className = classNameCheckbox;
        }
      }
    } else {
      let element = document.getElementsByClassName(urlGrid)[0];
      let classNameCheckbox =
        element &&
        element.className &&
        element.className.replace('ant-checkbox-indeterminate', '');
      if (classNameCheckbox) {
        classNameCheckbox = classNameCheckbox.replace(
          'ant-checkbox-checked',
          ''
        );
        classNameCheckbox = classNameCheckbox.concat(
          ' ant-checkbox-indeterminate'
        );
      }
      if (element) {
        element.className = classNameCheckbox;
      }
    }
  };

  const onHandleCheckboxCellClick = (params) => {
    let pageItemsSelectionFilter =
      (refPageItemsSelection.current &&
        refPageItemsSelection.current.length > 0 &&
        refPageItemsSelection.current.filter(
          (pageItem) => pageItem.page === refAgGridCurrentPagination.current
        )) ||
      [];

    let pageItemsSelectionDetailFilter =
      (refPageItemsSelectionDetail.current &&
        refPageItemsSelectionDetail.current.length > 0 &&
        refPageItemsSelectionDetail.current.filter(
          (pageItem) => pageItem.page === refAgGridCurrentPagination.current
        )) ||
      [];

    let pageItemsSelectionFilterDiff =
      (refPageItemsSelection.current &&
        refPageItemsSelection.current.length > 0 &&
        refPageItemsSelection.current.filter(
          (pageItem) => pageItem.page !== refAgGridCurrentPagination.current
        )) ||
      [];

    let pageItemsSelectionDetailFilterDiff =
      (refPageItemsSelectionDetail.current &&
        refPageItemsSelectionDetail.current.length > 0 &&
        refPageItemsSelectionDetail.current.filter(
          (pageItem) => pageItem.page !== refAgGridCurrentPagination.current
        )) ||
      [];

    let nodeId = _.toString(params.data.id);
    let nodeData = params.data;

    if (refItemSelection.current.indexOf(nodeId) > -1) {
      refItemSelection.current = refItemSelection.current.filter(
        (item) => item !== nodeId
      );
      refItemsSelectionDetail.current = refItemsSelectionDetail.current.filter(
        (item) => !isItemIdEqualToNodeId(item, nodeId)
      );
      pageItemsSelectionFilter[0].items.splice(
        pageItemsSelectionFilter[0].items.indexOf(nodeId),
        1
      );
      const findId = pageItemsSelectionDetailFilter[0].items.findIndex((item) =>
        isItemIdEqualToNodeId(item, nodeId)
      );
      pageItemsSelectionDetailFilter[0].items.splice(findId, 1);
      if (pageItemsSelectionFilter[0].items?.length === 0) {
        refPageItemsSelection.current = pageItemsSelectionFilterDiff;
        refPageItemsSelectionDetail.current =
          pageItemsSelectionDetailFilterDiff;
      } else {
        refPageItemsSelection.current = pageItemsSelectionFilterDiff.concat(
          pageItemsSelectionFilter
        );
        refPageItemsSelectionDetail.current =
          pageItemsSelectionDetailFilterDiff.concat(
            pageItemsSelectionDetailFilter
          );
      }
    } else {
      if (gridConfigProps?.rowSelection === 'single') {
        refItemSelection.current = [];
        refItemsSelectionDetail.current = [];
        refPageItemsSelection.current = [];
        refPageItemsSelectionDetail.current = [];
        pageItemsSelectionFilter = [];
        pageItemsSelectionDetailFilter = [];
      }

      refItemSelection.current = [...refItemSelection.current, nodeId];
      refItemsSelectionDetail.current = [
        ...refItemsSelectionDetail.current,
        nodeData,
      ];
      if (pageItemsSelectionFilter && pageItemsSelectionFilter.length > 0) {
        pageItemsSelectionFilter[0].items.push(nodeId);
        pageItemsSelectionDetailFilter[0].items.push(nodeData);

        refPageItemsSelection.current = pageItemsSelectionFilterDiff.concat(
          pageItemsSelectionFilter
        );
        refPageItemsSelectionDetail.current =
          pageItemsSelectionDetailFilterDiff.concat(
            pageItemsSelectionDetailFilter
          );
      } else {
        refPageItemsSelection.current = refPageItemsSelection.current.concat([
          {
            page: refAgGridCurrentPagination.current,
            items: [nodeId],
          },
        ]);
        refPageItemsSelectionDetail.current =
          refPageItemsSelectionDetail.current.concat([
            {
              page: refAgGridCurrentPagination.current,
              items: [nodeData],
            },
          ]);
      }
    }

    params.api.forEachNode((node) => {
      if (refItemSelection.current.indexOf(node.id) > -1) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });

    if (gridView && refItemSelection.current?.length === 1) {
      const currentItem = refItemsSelectionDetail.current?.[0];

      let data = Object.assign({}, params?.data);
      if (refItemSelection.current.indexOf(data?.id) > -1) {
        dispatch(actionsGridView.updateItemCurrentSelection(data));
      } else {
        dispatch(
          actionsGridView.updateItemCurrentSelection({
            ...currentItem,
            checkboxSelection: false,
          })
        );
      }
    }
  };

  const onHandleCellsRowClick = (params) => {
    let nodeSelections = params.api.getSelectedNodes();

    let itemsSelectionNew = [];
    let itemsSelectionDetailNew = [];
    let dataNode;

    if (refItemSelection.current.length === 0) {
      nodeSelections &&
        nodeSelections.length > 0 &&
        nodeSelections.forEach((node) => {
          itemsSelectionNew.push(node.id);
          itemsSelectionDetailNew.push(node.data);
          dataNode = node;
        });
      refItemSelection.current = itemsSelectionNew;
      refItemsSelectionDetail.current = itemsSelectionDetailNew;
      refPageItemsSelection.current = [
        {
          page: refAgGridCurrentPagination.current,
          items: [...refItemSelection.current],
        },
      ];
      refPageItemsSelectionDetail.current = [
        {
          page: refAgGridCurrentPagination.current,
          items: [...refItemsSelectionDetail.current],
        },
      ];
      if (gridView) {
        const data = Object.assign({}, dataNode?.data);

        dispatch(actionsGridView.updateItemCurrentSelection(data));
        isShowDetailOn && dispatch(actionsGridView.updateVisible(true));
      }
    } else {
      if (nodeSelections.length === 1) {
        let pageItemsSelectionFilter =
          (refPageItemsSelection.current &&
            refPageItemsSelection.current.length > 0 &&
            refPageItemsSelection.current.filter(
              (pageItems) =>
                pageItems.page !== refAgGridCurrentPagination.current
            )) ||
          [];
        if (pageItemsSelectionFilter && pageItemsSelectionFilter.length > 0) {
          if (!isHiddenAbbandonDialog) {
            if (gridConfigProps?.rowSelection === 'single') {
              handleModalAbandon();
            } else {
              setVisibleSelection(true);
            }
          }
        } else {
          nodeSelections &&
            nodeSelections.length > 0 &&
            nodeSelections.forEach((node) => {
              itemsSelectionNew.push(node.id);
              itemsSelectionDetailNew.push(node.data);
              dataNode = node;
            });
          refItemSelection.current = itemsSelectionNew;
          refItemsSelectionDetail.current = itemsSelectionDetailNew;
          refPageItemsSelection.current = [
            {
              page: refAgGridCurrentPagination.current,
              items: [...refItemSelection.current],
            },
          ];
          refPageItemsSelectionDetail.current = [
            {
              page: refAgGridCurrentPagination.current,
              items: [...refItemsSelectionDetail.current],
            },
          ];

          if (gridView) {
            const data = Object.assign({}, dataNode?.data);

            dispatch(actionsGridView.updateItemCurrentSelection(data));
            isShowDetailOn && dispatch(actionsGridView.updateVisible(true));
          }
        }
      } else {
        params.api.forEachNode((node) => {
          if (node.selected) {
            itemsSelectionNew.push(node.id);
            itemsSelectionDetailNew.push(node.data);
          } else {
            refItemSelection.current = refItemSelection.current.filter(
              (item) => item !== node.id
            );
            refItemsSelectionDetail.current =
              refItemsSelectionDetail.current.filter(
                (item) => !isItemIdEqualToNodeId(item, node.id)
              );
          }
        });
        refItemSelection.current = _.union(
          refItemSelection.current,
          itemsSelectionNew
        );
        refItemsSelectionDetail.current = _.uniqBy(
          [...refItemsSelectionDetail.current, ...itemsSelectionDetailNew],
          'id'
        );
        let pageItemsSelectionFilter =
          refPageItemsSelection.current &&
          refPageItemsSelection.current.length > 0 &&
          refPageItemsSelection.current.filter(
            (pageItems) => pageItems.page !== refAgGridCurrentPagination.current
          );
        let pageItemsSelectionDetailFilter =
          refPageItemsSelectionDetail.current &&
          refPageItemsSelectionDetail.current.length > 0 &&
          refPageItemsSelectionDetail.current.filter(
            (pageItems) => pageItems.page !== refAgGridCurrentPagination.current
          );
        pageItemsSelectionFilter.push({
          page: refAgGridCurrentPagination.current,
          items: itemsSelectionNew,
        });
        pageItemsSelectionDetailFilter.push({
          page: refAgGridCurrentPagination.current,
          items: itemsSelectionDetailNew,
        });
        refPageItemsSelection.current = pageItemsSelectionFilter;
        refPageItemsSelectionDetail.current = pageItemsSelectionDetailFilter;
      }
    }
  };

  const onRowClicked = (params) => {
    if (
      params.event.target &&
      params.event.target.name &&
      params.event.target.name.indexOf('ag-grid-input-check-clicked') > -1
    ) {
      onHandleCheckboxCellClick(params);
    } else {
      onHandleCellsRowClick(params);
    }

    setClickedRow(params?.data);

    if (refPageItemsSelection.current) {
      let result = [];
      refPageItemsSelection.current.forEach((data) => {
        result = result.concat(data.items);
      });
      const getSelectionList =
        callbackDataListSelection?.func || callbackDataListSelection;
      if (typeof getSelectionList === 'function') {
        getSelectionList(result.map((stringId) => _.toNumber(stringId)));
      }
    }

    setItemsSelectionLog(refItemSelection.current);
    setItemsSelectionDetailLog(refItemsSelectionDetail.current);
    setPageItemsSelectionLog(refPageItemsSelection.current);
    setPageItemsSelectionDetailLog(refPageItemsSelectionDetail.current);

    setClassNameHeaderCheckbox(
      refAgGridCurrentPagination.current,
      refAgGridTotalPagination.current,
      refAgGridPageSize.current
    );
  };

  const handleModalAbandon = () => {
    const agGridApi = refGrid.current.api;

    let nodeSelections = agGridApi.getSelectedNodes();
    let itemsSelectionNew = [];
    let itemsSelectionDetailNew = [];
    let dataNode;
    nodeSelections &&
      nodeSelections.length > 0 &&
      nodeSelections.forEach((node) => {
        itemsSelectionNew.push(node.id);
        itemsSelectionDetailNew.push(node.data);
        dataNode = node;
      });
    refItemSelection.current = itemsSelectionNew;
    refItemsSelectionDetail.current = itemsSelectionDetailNew;
    refPageItemsSelection.current = [
      { page: agGridCurrentPagination, items: [...refItemSelection.current] },
    ];
    refPageItemsSelectionDetail.current = [
      {
        page: agGridCurrentPagination,
        items: [...refItemsSelectionDetail.current],
      },
    ];
    setItemsSelectionLog(refItemSelection.current);
    setItemsSelectionDetailLog(refItemsSelectionDetail.current);
    setPageItemsSelectionLog(refPageItemsSelection.current);
    setPageItemsSelectionDetailLog(refPageItemsSelectionDetail.current);
    setClassNameHeaderCheckbox();
    // setItemsSelection(itemsSelectionNew);
    setVisibleSelection(false);
    const data = Object.assign({}, dataNode?.data);
    dispatch(actionsGridView.updateItemCurrentSelection(data));
    isShowDetailOn && dispatch(actionsGridView.updateVisible(true));
  };
  const handleModalAppend = () => {
    const agGridApi = refGrid.current.api;

    let nodeSelections = agGridApi.getSelectedNodes();
    let itemsSelectionNew = [];
    let itemsSelectionDetailNew = [];

    nodeSelections &&
      nodeSelections.length > 0 &&
      nodeSelections.forEach((node) => {
        itemsSelectionNew.push(node.id);
        itemsSelectionDetailNew.push(node.data);
      });
    agGridApi.forEachNode((node) => {
      if (refItemSelection.current.indexOf(node.id) > -1) {
        node.setSelected(true);
      }
    });
    refItemSelection.current = _.union(
      refItemSelection.current,
      itemsSelectionNew
    );
    refItemsSelectionDetail.current = _.uniqBy(
      [...refItemsSelectionDetail.current, ...itemsSelectionDetailNew],
      'id'
    );
    refPageItemsSelection.current.push({
      page: agGridCurrentPagination,
      items: itemsSelectionNew,
    });
    refPageItemsSelectionDetail.current.push({
      page: agGridCurrentPagination,
      items: itemsSelectionDetailNew,
    });

    setItemsSelectionLog(refItemSelection.current);
    setItemsSelectionDetailLog(refItemsSelectionDetail.current);
    setPageItemsSelectionLog(refPageItemsSelection.current);
    setPageItemsSelectionDetailLog(refPageItemsSelectionDetail.current);
    setClassNameHeaderCheckbox();
    setVisibleSelection(false);
  };

  const handleModalCancel = () => {
    const agGridApi = refGrid.current.api;

    let nodeSelections = agGridApi.getSelectedNodes();
    nodeSelections &&
      nodeSelections.length > 0 &&
      nodeSelections.forEach((node) => {
        node.setSelected(false);
      });

    agGridApi.forEachNode((node) => {
      if (refItemSelection.current.indexOf(node.id) > -1) {
        node.setSelected(true);
      }
    });
    setItemsSelectionLog(refItemSelection.current);
    setItemsSelectionDetailLog(refItemsSelectionDetail.current);
    setPageItemsSelectionLog(refPageItemsSelection.current);
    setPageItemsSelectionDetailLog(refPageItemsSelectionDetail.current);
    setClassNameHeaderCheckbox();
    setVisibleSelection(false);
  };

  const getRowNodeId = useCallback((data) => {
    return nodeIdName ? data?.[nodeIdName] : data.id;
  }, []);

  const onCallbackSelectedRowFromOutside = (rowData) => {
    if (typeof onHandleSelectedRowCustom === 'function') {
      const selectedItemsDetail =
        refSelection.current.getItemsSelectionDetailLog();

      onHandleSelectedRowCustom(selectedItemsDetail || [], rowData);
    }
  };

  const handleMovedColumn = () => {
    const agGridColumnApi = refGrid.current.columnApi;

    const result = agGridColumnApi
      .getAllGridColumns()
      .map((col) => col.colDef?.field);

    dispatch(actionsGridView.changePositionColumns(result));
  };

  // add isColumnsChanged props to fix bug when the modal grid show will make
  // columns in modal choose columns will uncheck
  const handleDisplayedColumnsChanged = () => {
    const agGridColumnApi = refGrid.current.columnApi;

    if (typeof agGridColumnApi === 'object') {
      const virtualColumns = agGridColumnApi.getAllDisplayedVirtualColumns();
      if (virtualColumns && isColumnsChanged) {
        const result = virtualColumns.map((col) => col.colDef?.field);
        if (!_.isEqual(result, refVirtualColumns.current)) {
          refVirtualColumns.current = result;
          dispatch(actionsGridView.changeDisplayedColumns(result));
        }
      }
    }
  };

  const onCellFocused = (e) => {
    if (e?.column?.colDef?.field === 'dragColumn') {
      e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
    } else {
      e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
    }
  };

  const onHeaderCheckboxClick = (e, params) => {
    let className = e.currentTarget.className;
    let itemsSelectionNew = [];
    let itemsSelectionDetailNew = [];

    if (className.indexOf('ant-checkbox-checked') > -1) {
      params.api.forEachNode((node) => {
        node.setSelected(false);
        refItemSelection.current = refItemSelection.current.filter(
          (item) => item !== node.id
        );
        refItemsSelectionDetail.current =
          refItemsSelectionDetail.current.filter(
            (item) => !isItemIdEqualToNodeId(item, node?.id)
          );
      });
      let pageItemsSelectionFilter =
        (refPageItemsSelection.current &&
          refPageItemsSelection.current.length > 0 &&
          refPageItemsSelection.current.filter(
            (pageItems) => pageItems.page !== refAgGridCurrentPagination.current
          )) ||
        [];
      let pageItemsSelectionDetailFilter =
        (refPageItemsSelectionDetail.current &&
          refPageItemsSelectionDetail.current.length > 0 &&
          refPageItemsSelectionDetail.current.filter(
            (pageItems) => pageItems.page !== refAgGridCurrentPagination.current
          )) ||
        [];
      refPageItemsSelection.current = pageItemsSelectionFilter;
      refPageItemsSelectionDetail.current = pageItemsSelectionDetailFilter;
    } else {
      params.api.forEachNode((node) => {
        node.setSelected(true);
        itemsSelectionNew.push(node.id);
        itemsSelectionDetailNew.push(node.data);
      });
      refItemSelection.current = _.union(
        refItemSelection.current,
        itemsSelectionNew
      );
      refItemSelection.current = _.union(
        refItemSelection.current,
        itemsSelectionNew
      );
      refItemsSelectionDetail.current = _.uniqBy(
        [...refItemsSelectionDetail.current, ...itemsSelectionDetailNew],
        'id'
      );
      let pageItemsSelectionFilter =
        (refPageItemsSelection.current &&
          refPageItemsSelection.current.length > 0 &&
          refPageItemsSelection.current.filter(
            (pageItems) => pageItems.page !== refAgGridCurrentPagination.current
          )) ||
        [];
      let pageItemsSelectionDetailFilter =
        (refPageItemsSelectionDetail.current &&
          refPageItemsSelectionDetail.current.length > 0 &&
          refPageItemsSelectionDetail.current.filter(
            (pageItems) => pageItems.page !== refAgGridCurrentPagination.current
          )) ||
        [];
      pageItemsSelectionFilter.push({
        page: refAgGridCurrentPagination.current,
        items: itemsSelectionNew,
      });
      pageItemsSelectionDetailFilter.push({
        page: refAgGridCurrentPagination.current,
        items: itemsSelectionDetailNew,
      });
      refPageItemsSelection.current = pageItemsSelectionFilter;
      refPageItemsSelectionDetail.current = pageItemsSelectionDetailFilter;
    }
    setItemsSelectionLog(refItemSelection.current);
    setPageItemsSelectionLog(refPageItemsSelection.current);

    if (refPageItemsSelection.current) {
      let result = [];
      refPageItemsSelection.current.forEach((data) => {
        result = result.concat(data.items);
      });
      const getSelectionList =
        callbackDataListSelection?.func || callbackDataListSelection;
      if (typeof getSelectionList === 'function') {
        getSelectionList(result.map((stringId) => _.toNumber(stringId)));
      }
    }

    setItemsSelectionDetailLog(refItemsSelectionDetail.current);
    setPageItemsSelectionDetailLog(refPageItemsSelectionDetail.current);

    setClassNameHeaderCheckbox(
      refAgGridCurrentPagination.current,
      refAgGridTotalPagination.current,
      refAgGridPageSize.current
    );
  };

  const { notCheckTotalRecordAgGrid } = gridConfigProps ?? {};

  return (
    <GridContext.Provider
      value={{
        onHeaderCheckboxClick,
      }}
    >
      <div
        style={
          styleGrid ? styleGrid : { width: '100%', height: 'calc(100% - 46px)' }
        }
        className={classnames(
          'ag-theme-alpine',
          'ag-theme-alpine--with-scroller',
          {
            'ag-theme-alpine--with-hidden-row': isSwitchingPage,
          },
          'ag-grid'
        )}
      >
        {forceLoading ? (
          <div>Reloading...</div>
        ) : (
          <React.Fragment>
            <AgGridReact
              ref={refGrid}
              serverSideFilterOnServer={
                gridView === true || sortAndFilterServerside === true
              }
              serverSideSortOnServer={
                gridView === true || sortAndFilterServerside === true
              }
              disableStaticMarkup={true}
              animateRows={true}
              // immutableData={true}
              getRowNodeId={getRowNodeId}
              columnDefs={cols}
              frameworkComponents={{
                CheckboxSelectionRender: CheckboxSelectionRender,
                HeaderCheckboxRender: HeaderCheckboxRender,
                HeaderNullRender: HeaderNullRender,
                DragRender: DragRender,
                LinkRender: LinkRender,
                CheckboxRender: CheckboxRender,
                ...frameworkComponents,
              }}
              pagination={true}
              paginationPageSize={agGridPageSize}
              cacheBlockSize={agGridPageSize}
              blockLoadDebounceMillis={500}
              maxBlocksInCache={1}
              suppressPaginationPanel={true}
              rowSelection={'multiple'}
              multiSortKey='ctrl'
              enableMultiRowDragging={true}
              rowDragManaged={true}
              rowDeselection={true}
              sideBar={false}
              rowModelType='serverSide'
              headerHeight={32}
              rowHeight={rowHeight || 32}
              defaultColDef={{
                editable: false,
                enableRowGroup: false,
                sortable: true,
                resizable: true,
                filter: true,
              }}
              postProcessPopup={(params) => {
                // check callback is for menu
                if (params.type !== 'columnMenu') {
                  return;
                }

                onMenuClick(params);
              }}
              // modules: AllModules,
              // EVENTS
              // Add event handlers
              onRowClicked={onRowClicked}
              onSelectionChanged={onSelectionChanged}
              onRowSelected={onRowSelected}
              // onColumnResized={(event) => {
              //   console.log('A column was resized');
              // }}
              onGridReady={onGridReadyfunction}
              isScrollLag={(event) => {
                return false;
              }}
              // onPaginationChanged={(event) => {
              //   console.log('pagi');
              // }}
              onSortChanged={onHandleSortChanged}
              onFilterChanged={onHandleFilterChanged}
              onFilterModified={() => {}} // a trick for the Apply button fire onHandleFilterChanged
              rowClassRules={rowClassRules && rowClassRules}
              onDragStopped={handleMovedColumn}
              onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
              onCellFocused={onCellFocused}
              applyColumnDefOrder={true}
              rowMultiSelectWithClick={
                false
              } /* TODO:  rowMultiSelectWithClick = true then pageSelectionItems will not clear, maybe fix after*/
              suppressFieldDotNotation={true}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
              onRowDoubleClicked={onRowDoubleClicked}
              {...gridConfigProps}
            ></AgGridReact>

            {notCheckTotalRecordAgGrid
              ? null
              : agGridTotalPagination <= 0 && (
                  <Row className='ag-grid__empty'>
                    <Empty />
                  </Row>
                )}

            <Row
              className='ag-theme-alpine__pagination'
              style={{ display: hidePagination ? 'none' : null }}
            >
              <Col
                span={gridView ? 18 : 24}
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                {!hidePagination && (
                  <>
                    <Pagination
                      current={agGridCurrentPagination}
                      total={agGridTotalPagination}
                      pageSize={agGridPageSize}
                      showSizeChanger={!minimizePagination}
                      onChange={onChangePagination}
                      onShowSizeChange={onShowSizeChange}
                      responsive
                      size={
                        ((isShowDetailOn || showAddToFolder) &&
                          window.innerWidth < 1500) ||
                        (resizePagination && window.innerWidth < 1700) ||
                        isSmallSizePagination
                          ? 'small'
                          : 'default'
                      }
                      showLessItems={
                        minimizePagination ||
                        showLessItem ||
                        isShowDetailOn ||
                        showAddToFolder
                      }
                      disabled={isFetching}
                    />
                    {minimizePagination ||
                    isShowDetailOn ||
                    showAddToFolder ? null : (
                      <>
                        <Typography.Text
                          strong={true}
                          style={{ marginLeft: 6 }}
                        >
                          <DisplayTotalItem
                            totalPagination={agGridTotalPagination}
                          />
                        </Typography.Text>
                      </>
                    )}
                  </>
                )}
              </Col>

              <SelectionLog
                ref={refSelection}
                gridView={gridView}
                gridId={gridId}
                isShowItemSelectionLog={isShowItemSelectionLog}
                onCallbackSelectedRowFromOutside={
                  onCallbackSelectedRowFromOutside
                }
              />
            </Row>

            {(gridView || !isHiddenAbbandonDialog) && (
              <AbandonDialog
                visibleSelection={visibleSelection}
                handleModalAbandon={handleModalAbandon}
                handleModalAppend={handleModalAppend}
                handleModalCancel={handleModalCancel}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </GridContext.Provider>
  );
});

AgGrid.propTypes = {
  styleGrid: PropTypes.object,
  columnDefs: PropTypes.array,
  urlGrid: PropTypes.string,
  urlGridDistinct: PropTypes.string,
  paramsGrid: PropTypes.object,
  gridView: PropTypes.bool,
  tabView: PropTypes.string,
  pathname: PropTypes.string,
  requestParams: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  callbackDataListSelection: PropTypes.func,
  hidePagination: PropTypes.bool,
  filterFromMainPage: PropTypes.array,
  rowClassRules: PropTypes.object,
  frameworkComponents: PropTypes.object,
  triggerDownload: PropTypes.bool,
  onDownloadGridHandler: PropTypes.func,
  downloadFileType: PropTypes.string,
  isDownloadAllColumns: PropTypes.bool,
  notShowHeaderCheckbox: PropTypes.bool,
  showCheckboxSelectionRender: PropTypes.bool,
};

export default AgGrid;
