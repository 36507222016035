import React from 'react';

import { Row, Col, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { formatFullTime } from 'utils/formatDate';

const { Text, Link } = Typography;

const ChatMail = (props) => {
  const { item } = props;
  const { messages, time } = item;
  return messages?.map((message, index) => (
    <div className='chat-thread__mail' key={index}>
      <Row className='chat-thread__mail-header'>
        <Col span={12}>
          <Text>From: </Text>
          <Text className='chat-thread__mail-header-from' strong>
            {message?.from}
          </Text>
        </Col>
        <Col span={12}>
          <Text className='chat-thread__mail-header-time'>
            {formatFullTime(time)}
          </Text>
        </Col>
      </Row>
      <Row className='chat-thread__mail-body'>
        <Col span={24}>{message?.content}</Col>
      </Row>
      <Row className='chat-thread__mail-footer'>
        {message?.attach?.map((attach) => (
          <Col span={24}>
            <Row className='chat-thread__mail-attachment-item'>
              <Col className='chat-thread__mail-attachment-name' flex={1}>
                <Link ellipsis href={attach?.url}>
                  <LinkOutlined className='chat-thread__mail-attachment-icon' />
                  {attach?.name}
                </Link>
              </Col>
              <Col flex='50px'>
                <Text>{attach?.size}</Text>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  ));
};

export default ChatMail;
