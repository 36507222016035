import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { notification } from 'antd';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components';

import * as selectorWorkflowTemplate from 'pages/workflow-template/controllers/selectors';

import * as workflowServices from 'services/workflow';
import * as actionsWf from 'pages/workflow-template/controllers/actions';

const DeleteWorkflow = (props) => {
  const dispatch = useDispatch();

  const selectedItems = useSelector(
    selectorWorkflowTemplate.makeSelectItemsSelected()
  );
  const isDisabled = !selectedItems || selectedItems.length !== 1;

  const handleDeleteCondition = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete selected template?',
      onOk: () => {
        workflowServices
          .deleteWorkflowService({
            workflowTemplateId: selectedItems[0]?.id,
          })
          .then((res) => {
            if (res.isSuccess) {
              dispatch(actionsWf.reloadGridWf(true));
              dispatch(actionsWf.setWfItemSelected([]));
              notification.success({
                message: 'Delete template successfully!',
              });
            } else {
              notification.error({
                message: res.message,
              });
            }
          })
          .finally(() => {
            setTimeout(() => {
              dispatch(actionsWf.reloadGridWf(false));
            });
          });
      },
    });
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label='Delete'
      onClick={() => handleDeleteCondition()}
      disabled={isDisabled}
      style={{ width: '70px' }}
    />
  );
};

export default DeleteWorkflow;
