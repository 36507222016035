import { useReducer, useCallback } from 'react';

const useMutation = (promise) => {
  const [state, setState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      status: 'idle',
    }
  );

  const mutate = useCallback(
    (params) => {
      setState({ status: 'loading' });

      promise(params)
        .then((res) => {
          if (res.isSuccess) {
            setState({ status: 'success', data: res.data });
          } else {
            setState({ status: 'error', error: res.message || res.Message });
          }
        })
        .catch((error) => {
          setState({ status: 'error', error });
        })
        .finally(() => {
          setState({ status: 'idle' });
        });
    },
    [promise]
  );

  const setDefaultState = () => setState({ status: 'idle' });

  return [state, mutate, setDefaultState];
};

export { useMutation };
