import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';
import { saveQaSpecProductContain } from 'services/product';
import * as productServices from 'services/product';
import { forwardTo } from 'utils/common/route';

export const getQaContainTabDataQueryKey = (productId) => {
  return ['qa-spec', parseInt(productId), 'qa-spec-data-product-contains'];
};

export const useGetQaProductContainTabData = ({ productId }, options) => {
  return useQuery({
    queryKey: getQaContainTabDataQueryKey(productId),
    queryFn: async () => {
      const response = await productServices.getProductContain({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    ...options,
  });
};

export const useSaveQaProductContainTab = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ productId, formData }) => {
      const params = { productId, ...formData };
      const response = await saveQaSpecProductContain(params);
      const { isSuccess, data, message } = response || {};

      if (isSuccess) {
        return Promise.resolve(data);
      } else if (message) {
        return Promise.reject(message);
      }
    },
    onSuccess: (data, { productId }) => {
      queryClient.invalidateQueries({
        queryKey: getQaContainTabDataQueryKey(productId),
      });
      CustomNotification.success('Update Product Contain data successfully!');
    },
    onError: (error) => {
      CustomNotification.error('Save Fail');
    },
  });
};
