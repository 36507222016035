// user profile
export const GET_USER_CONTACT_HISTORY =
  '/api/user-profile/get-user-profile-contact-histories';
export const GET_USER_PROFILE = '/api/user-profile/get-user-profile';
export const GET_USER_ACTIVITY_LOG = '/api/user-profile/get-user-activity-logs';
export const GET_USER_ACTIVITY_LOG_DISTINCT =
  '/api/user-profile/get-user-activity-logs/distinct';

// change password
export const CHANGE_USER_PASSWORD = '/api/User/change-password';
export const GET_USER_PASSWORD_STRENGTH_TYPE =
  '/api/User/get-user-password-strength-type';
export const GET_USER_PROFILE_LOCATIONS =
  '/api/user-profile/get-user-profile-locations';
export const GET_USER_PROFILE_LOCATIONS_DISTINCT =
  '/api/user-profile/get-user-profile-locations/distinct';

// ! get user permission grid column (2 grids)
export const GET_USER_PERMISSION_GRID_COLUMN = '/api/User/permissions';
export const GET_USER_PERMISSION_ROLE_GRID_DATA = '/api/User/role-info';

// user-profile
export const EDIT_USER_PROFILE_BIO = '/api/user-profile/edit-user-profile-bio';
export const CREATE_USER_PROFILE_IMAGE =
  '/api/user-profile/create-user-profile-image';
export const GET_SUGGESTION_USERS = '/api/user-profile/get-suggestion-users';
export const GET_PUBLIC_SPECIFICATION =
  '/api/user-profile/get-public-specification';
export const CHECK_USERNAME_AVAILABLE =
  '/api/user-profile/check-username-available';
export const GET_USER_PROFILE_HEADER =
  '/api/user-profile/get-user-profile-header';
export const EDIT_USER_PROFILE_HEADER =
  '/api/user-profile/edit-user-profile-header';
export const GET_ALL_ARTICLES = '/api/article/get-all-articles';
export const EDIT_USER_PROFILE_FULL_NAME =
  '/api/user-profile/edit-user-profile-full-name';
export const EDIT_USER_PROFILE_EMAIL =
  '/api/user-profile/edit-user-profile-email';
export const SAVE_ASSET_DOWNLOAD_PREFERENCES =
  'api/user-profile/save-asset-download-preference';

// CRUD user profile - Location
export const USER_PROFILE_UPDATE_LOCATION =
  '/api/user-profile/edit-user-location';
export const USER_PROFILE_DELETE_LOCATION =
  '/api/user-profile/delete-user-locations';
export const USER_PROFILE_ADD_LOCATION =
  '/api/user-profile/create-user-location';
export const USER_PROFILE_GET_LOCATION_DETAIL =
  '/api/user-profile/user-location-detail';
export const DOWNLOAD_USER_PROFILE_LOCATION =
  '/api/user-profile/get-user-profile-locations/download';

//* GDSN Party
export const GET_GDSN_PARTY_INFO = '/api/MemberProfile/get-gdsn-party-info';

//* user profile otp
export const SEND_OTP_TO_NEW_MFA_PHONE_NUMBER_USER_PROFILE =
  '/api/user-profile/send-otp';
export const VERIFY_OTP_AND_SAVE_NEW_MFA_PHONE_NUMBER_USER_PROFILE =
  '/api/user-profile/check-otp-and-save-mfa-number';

//* change password unlock account
export const CHANGE_PASSWORD_UNLOCK_ACCOUNT =
  'api/user/change-password-on-login';
