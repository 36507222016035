import React from 'react';

import DateRange from '../../controls/user-full/DateRange';
import DownloadTransaction from '../../controls/user-full/DownloadTransaction';

import { RibbonSection } from 'common/components';

const Locations = () => {
  return (
    <RibbonSection>
      <DateRange />
      <DownloadTransaction />
    </RibbonSection>
  );
};

export default Locations;
