import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroller';
import { Popover, Input, List, Row, Typography, Empty } from 'antd';

import { WithLoading } from 'common/components';
import SearchResultItem from './SearchResultItem';

import * as chatActions from '../../controller/actions';
import * as chatSelector from '../../controller/selectors';

import mapMesssagesWithAttachUrl from '../../mapper/mapMesssagesWithAttachUrl';

import { sleep } from 'utils/delay';
import * as chatServices from 'services/chatServices';
import './SearchResult.less';

const { Paragraph } = Typography;
const { Search } = Input;
let searchTimeout;

const ChatSearchMessage = (props) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const {
    participants,
    threadInfo,
    visible,
    setVisible,
    width = 400,
    resultWidth = 400,
    resultPosition = 'bottomLeft',
  } = props;

  const threadMessages = useSelector(chatSelector.selectThreadMessages());

  const [value, setValue] = useState('');
  const [resultOption, setResultOption] = useState([]);
  const [error, setError] = useState(null);
  const [paging, setPaging] = useState({
    currentPageIndex: 1,
    currentPageSize: 2,
    totalRecord: 1,
  });
  const [loading, setLoading] = useState(true);

  const handleSelectResult = (item) => {
    const shouldReload = !threadMessages?.find((thread) => {
      return thread.messageId === item.messageId;
    });
    if (shouldReload) {
      reloadThreadMessages(item);
      return;
    }

    dispatch(chatActions.selectSearchResult(item));
  };

  const reloadThreadMessages = (item) => {
    callApiGetMessages(item);
    // dispatch(chatActions.getThreadMessages(threadInfo.id, item.sendTime));
  };

  const callApiGetMessages = (item) => {
    const paramsUp = {
      threadId: threadInfo.id,
      timestamp: item.sendTime,
      direction: 0,
    };

    const paramsDown = {
      threadId: threadInfo.id,
      timestamp: item.sendTime + 1,
      direction: 1,
    };

    dispatch(chatActions.toggleChatSpinning(true));

    Promise.all([
      chatServices.getThreadMessage(paramsUp),
      chatServices.getThreadMessage(paramsDown),
    ])
      .then(async (response) => {
        const isSuccess =
          response.length && response.every((res) => res.isSuccess === true);

        if (!isSuccess) throw new Error('Cannot get messages');

        const data = await response.reduce(
          async (accumulator, currentResponse) => {
            const currentResData = currentResponse?.data?.threadMessages;

            const handledMessages = await mapMesssagesWithAttachUrl(
              currentResData,
              threadInfo.id
            );

            let accum = await accumulator;

            const concatedMsgs = accum.concat(handledMessages);

            return concatedMsgs;
          },
          Promise.resolve([])
        );

        const sortedData = data.sort((messageA, messageB) => {
          return messageB.sendTime - messageA.sendTime;
        });
        dispatch(chatActions.getThreadMessagesSuccess(sortedData));
        dispatch(chatActions.updateFinishLoadDown(false));
        dispatch(chatActions.selectSearchResult(item));
        dispatch(chatActions.toggleChatSpinning(false));
      })
      .catch((err) => {
        dispatch(chatActions.toggleChatSpinning(false));
        dispatch(chatActions.getThreadMessagesFail(err.message || err));
      });
  };

  const clearSelectResult = () => {
    dispatch(chatActions.selectSearchResult(null));
  };

  const clearSearchMessagePosition = () => {
    dispatch(chatActions.updateSelectedSearchResultPosition(null));
  };

  const handleHideResultSearch = async () => {
    await sleep(500); //? wait search bar close completely
    setValue('');
    clearTimeout(searchTimeout);
    clearSelectResult();
    clearSearchMessagePosition();
  };

  const debounceCallApi = (value) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    console.log('resultOption', resultOption);
    searchTimeout = setTimeout(() => {
      callSearchApi({ value, page: 1, isDebounce: true });
    }, 1000);
  };

  const callSearchApi = async ({ value, page, isDebounce }) => {
    if (!value) return;

    try {
      const params = {
        SearchText: value,
        PageIndex: page,
        PageSize: 10,
        ThreadId: threadInfo?.id,
      };
      const result = await chatServices.searchMessages(params);
      // if (!result?.isSuccess) throw new Error('Search Error');
      if (result?.data) {
        const currentResult = isDebounce ? [] : resultOption;
        setResultOption([...currentResult, ...result?.data?.gridData]);
        setPaging(result?.data?.paging);
      }
    } catch (error) {
      console.log('error: ', error);
      setError(true);
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (!value) {
      setVisible(false);
      return;
    }

    setVisible(true);
    setLoading(true);
    setResultOption([]);
    setValue(value);
    debounceCallApi(value);
  };

  const handleLoadMore = (page) => {
    callSearchApi({ value, page });
  };

  const focusInput = () => {
    if (visible && inputRef.current) inputRef.current.focus();
  };

  useEffect(() => {
    if (!visible) handleHideResultSearch();
    focusInput();
  }, [visible]);

  const renderResult = () => {
    const isHasMoreResult = paging.totalRecord > resultOption.length;

    if (loading)
      return (
        <WithLoading loading={true}>
          <div style={{ width: '100%', height: 70 }} />
        </WithLoading>
      );

    if (error)
      return (
        <Paragraph style={{ textAlign: 'center' }}>
          Cannot search message
        </Paragraph>
      );

    if (resultOption.length === 0)
      return (
        <Row justify='center'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Row>
      );

    return (
      <div className='scroller' style={{ maxHeight: 200 }}>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={handleLoadMore}
          hasMore={isHasMoreResult}
          useWindow={false}
          loader={
            <Row justify='center' key={0}>
              <WithLoading loading={true} tip='Loading...' size='small' />
            </Row>
          }
        >
          <List>
            {resultOption.map((item) => {
              return (
                <List.Item key={item.messageId}>
                  <SearchResultItem
                    item={item}
                    participants={participants}
                    onClick={handleSelectResult}
                  />
                </List.Item>
              );
            })}
          </List>
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <Popover
      trigger='click'
      visible={visible}
      placement={resultPosition}
      dropdownClassName='chat-search-message__result-options'
      overlayStyle={{ width: resultWidth }}
      content={renderResult()}
    >
      <Search
        ref={inputRef}
        value={value}
        bordered={false}
        size='large'
        style={{ width }}
        onChange={handleSearch}
      />
    </Popover>
  );
};

export default ChatSearchMessage;
