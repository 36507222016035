import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Divider } from 'antd';
import ProductOverviewContent from './ProductOverviewContent';
import ProductOverviewMetadata from './ProductOverviewMetadata';

import './ProductOverview.less';

const ProductOverview = (props) => {
  const {
    product,
    showPackageDrawer,
    productItemModules,
    productSchema,
    productEnums,
    heightPrimaryInfo,
    isHiddenQuestionIcon,
  } = props;
  const refContainer = useRef(null);
  return (
    <>
      <ProductOverviewMetadata
        refContainer={refContainer}
        product={product}
        showPackageDrawer={showPackageDrawer}
        productItemModules={productItemModules}
        productSchema={productSchema}
        productEnums={productEnums}
        isHiddenQuestionIcon={isHiddenQuestionIcon}
      />
      <Divider style={{ margin: '8px 0px' }} />
      <ProductOverviewContent
        product={product}
        heightContainer={refContainer?.current?.clientHeight}
        heightPrimaryInfo={heightPrimaryInfo}
      />
    </>
  );
};

ProductOverview.propTypes = {
  product: PropTypes.object,
  showPackageDrawer: PropTypes.func,
};

export default ProductOverview;
