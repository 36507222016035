import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { Checkbox } from 'antd';

import './CheckboxEditor.less';

export const CheckboxEditor = forwardRef((props, ref) => {
  const { value: initValue, column, node } = props;

  const [value, setValue] = useState(initValue);
  const [editing, setEditing] = useState(true);
  const refContainer = useRef(null);

  const focus = () => {
    setTimeout(() => {
      let container = ReactDOM.findDOMNode(refContainer.current);

      if (container && container?.focus) {
        container.focus();
      }
    }, [100]);
  };

  useEffect(() => {
    focus();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const handleChange = (e) => {
    setValue(e.target.checked);
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'c' || e.key === 'Control') {
      e.preventDefault();
      setValue((prev) => !prev);
    }
  };

  const width = column.getActualWidth() || 300;
  const height = node?.rowHeight || 42;

  return (
    <div
      ref={refContainer}
      className='ag-editor__checkbox'
      tabIndex={1}
      onKeyDown={handleInputKeyDown}
      style={{ width, height }}
    >
      <Checkbox checked={value} onChange={handleChange} />
    </div>
  );
});
