import React, { useMemo, useReducer, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { WithLoading } from 'common/components';

import 'common/components/ag-grid/LicenseManagerAgGrid';

import './ShowGridConfig.less';

const baseDefaultColDef = {
  // width: 150,
  sortable: false,
  filter: false,
  resizable: true,
  menuTabs: [],
};

const baseGridOptions = {
  getRowId: function (params) {
    return params.data.id;
  },
  rowDragManaged: true,
  animateRows: true,
  headerHeight: 32,
  rowHeight: 32,
  tooltipShowDelay: 100,
};

const baseColumnDefs = [
  {
    field: 'id',
    dndSource: true,
    width: 50,
    lockPosition: 'left',
    // pinned: 'left',
    lockPinned: true,
    resizable: false,
  },
];

const defaultGridOptions = {
  ...baseGridOptions,
  defaultColDef: {
    ...baseDefaultColDef,
  },
};

const ShowGridConfig = (props) => {
  const {
    gridRef,
    selectedProperties,
    statusChangeGrid,
    onColumnMoved,
    onColumnResized,
  } = props;

  const [keyForceUpdate, forceUpdate] = useReducer((x) => x + 1, 0);

  const isSyncing = statusChangeGrid === 'loading';

  useEffect(() => {
    if (isSyncing) {
      setTimeout(() => {
        forceUpdate();
      }, 500);
    }
  }, [isSyncing]);

  const columns = useMemo(
    () => mapPropertiesToCols(selectedProperties),
    [selectedProperties]
  );

  // const allColumns = baseColumnDefs.concat(columns);

  const onLeftGridReady = (params) => {
    params.api.setRowData([]);
  };

  return (
    <WithLoading loading={statusChangeGrid === 'loading'}>
      <div className='outer'>
        <div style={{ height: '100%' }} className='inner-col ag-theme-alpine'>
          <AgGridReact
            ref={gridRef}
            gridOptions={defaultGridOptions}
            columnDefs={columns}
            onGridReady={onLeftGridReady}
            suppressDragLeaveHidesColumns={true}
            key={keyForceUpdate}
            onColumnMoved={onColumnMoved}
            onColumnResized={onColumnResized}
          />
        </div>
      </div>
    </WithLoading>
  );
};

export const mapPropertiesToCols = (properties = []) => {
  let cols = [];

  if (properties.length > 0) {
    properties.forEach((property) => {
      if (property.isShow) {
        if (property?.isCustomField) {
          cols.push({
            headerName: property.displayName,
            field: `-${property.fieldName}`,
            minWidth: property.minWidth,
            width: property.width,
            headerTooltip: `${property.customFieldCompany}'s Custom Fields`,
          });
        } else {
          if (property.moduleName) {
            cols.push({
              headerName: property.displayName,
              field: `${property.moduleName}-${property.fieldName}`,
              minWidth: property.minWidth,
              width: property.width,
              headerTooltip: property.moduleDisplayName,
            });
          } else {
            if (property.fieldName === 'Id') {
              cols.push({
                headerName: property.displayName,
                field: `-${property.fieldName}`,
                width: 50,
              });
            } else {
              cols.push({
                headerName: property.displayName,
                field: `-${property.fieldName}`,
                minWidth: property.minWidth,
                width: property.width,
              });
            }
          }
        }
      }
    });
  }

  return cols;
};

export default ShowGridConfig;
