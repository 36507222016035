import React from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { ViewLayout, AdvanceStack } from './components';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { RibbonBar, RibbonDivider } from 'common/components';
import BackControl from './components/controls/home/Back';
import CreateMappingConfig from './components/controls/mapping/config/CreateMappingConfig';
import EditMappingConfig from './components/controls/mapping/config/EditMappingConfig';
import DeleteMappingConfig from './components/controls/mapping/config/DeleteMappingConfig';
import CloneMappingConfig from './components/controls/mapping/config/CloneMappingConfig';
import ShareSection from './components/sections/shared/ShareSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import gridSelectors from 'common/components/grid-view/controllers/selectors';

import * as mappingSelectors from 'pages/mapping/controllers/selectors';

const ProductMappingConfig = () => {
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const selectCurrentMappingId = useSelector(
    mappingSelectors.selectCurrentMappingId()
  );

  const selectCurrentMappingConfig = useSelector(
    mappingSelectors.selectMappingConfigProperties()
  );

  const { data, loading } = selectCurrentMappingConfig;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection disabled />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabled />
      </Can>

      <RibbonDivider />
      <ShareSection selectedItemList={selectedItemList} />
      <RibbonDivider />

      <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.PRODUCT}>
        <CreateMappingConfig mappingId={selectCurrentMappingId} />
      </Can>

      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.PRODUCT}>
        <EditMappingConfig
          selectedItemList={selectedItemList}
          mappingId={selectCurrentMappingId}
          mappingConfig={data}
          mappingConfigStatus={loading}
        />
      </Can>
      <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.PRODUCT}>
        <CloneMappingConfig
          selectedItemList={selectedItemList}
          mappingConfigId={selectedItemList[0]?.id}
          mappingConfig={data}
          mappingConfigStatus={loading}
        />
      </Can>
      <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.MEMBER}>
        <DeleteMappingConfig
          mappingId={selectCurrentMappingId}
          mappingConfig={data}
          selectedItemList={selectedItemList}
        />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <RibbonDivider />
        <AdvanceStack isDisabled />
      </Can>
      <BackControl ribbonSide='right' />
    </RibbonBar>
  );
};

export default ProductMappingConfig;
