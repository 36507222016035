import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

// import { ReactComponent as IconDeletePreview } from 'common/components/button/svg-icons/ICO_76.svg';
import { CloseCircleOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components/index';

import * as constant from 'static/Constants';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { injectIntl } from 'react-intl';
import ribbonMessages from 'i18n/messages/home';
import previewMessages from 'i18n/messages/assetPreview';

import { useCheckAllowDeleteAssets } from 'hooks';

import * as digitalAssetsService from 'services/digitalAsset';

const DeletePreview = (props) => {
  const reloadPage = useDispatchReloadPage();
  const { sectionDisabled, currentSelectedAssets, typeView, isIframe, intl } =
    props;

  const [disabled, setDisabled] = useState(true);

  const { checkAllowDeleteAssetFull, checkAllowDeleteAssetGrid } =
    useCheckAllowDeleteAssets();

  const isAllowDeleteGrid = checkAllowDeleteAssetGrid();
  const isAllowDeleteFull = checkAllowDeleteAssetFull();

  const checkButtonDisabled = (currentSelected) => {
    // const ownSelectedAssets = currentSelected?.map((asset) => {
    //   return asset?.isOwner; // before: asset.canEdit
    // });

    const isAllowDeletePreview = checkAllowDeletePreview(
      typeView,
      isAllowDeleteGrid,
      isAllowDeleteFull
    );

    // The condition for the activation of the button is
    const isActivate =
      isAllowDeletePreview &&
      // the preview section is not disabled
      !sectionDisabled &&
      // the asset is not iFrame
      !isIframe;

    if (isActivate) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const showConfirmModal = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(previewMessages.deletePreviewConfirmMessage),
      onOk: callDeletePreviewApi,
    });
  };

  const showErrorMessage = () => {
    dialogFunction({
      type: 'error',
      content: intl.formatMessage(previewMessages.deletePreviewError),
      cancelButtonProps: {
        style: {
          display: 'none',
        },
      },
    });
  };

  const callDeletePreviewApi = () => {
    //generate request data
    const requestData = currentSelectedAssets.reduce((accumulator, asset) => {
      return { ...accumulator, [asset.id]: null };
    }, {});

    const data = {
      data: requestData,
    };

    digitalAssetsService
      .updateDigitalAssetThumb(data)
      .then((response) => {
        if (response.isSuccess) {
          makePageReload();
        } else {
          showErrorMessage();
        }
      })
      .catch((err) => {
        showErrorMessage();
      });
  };

  const makePageReload = () => {
    // only reload page
    if (typeView !== constant.RIBBON_TYPES.DETAILSVIEW) {
      // reload  asset full view page
      reloadPage();
    }
  };

  useEffect(() => {
    checkButtonDisabled(currentSelectedAssets);
    //eslint-disable-next-line
  }, [currentSelectedAssets]);

  return (
    <RibbonButton
      size='small'
      className='button-item-dropdown'
      icon={<CloseCircleOutlined />}
      label={ribbonMessages.deletePreview}
      disabled={disabled}
      onClick={showConfirmModal}
    />
  );
};

const checkAllowDeletePreview = (
  typeView = '',
  isAllowDeleteGrid,
  isAllowDeleteFull
) => {
  if (typeView?.toLowerCase() === 'grid') {
    return isAllowDeleteGrid;
  }

  return isAllowDeleteFull;
};

DeletePreview.propTypes = {
  currentSelectedAssets: PropTypes.array,
  sectionDisabled: PropTypes.bool,
  typeView: PropTypes.oneOf(['grid', 'full']),
  isIframe: PropTypes.bool,
};

DeletePreview.defaultProps = {
  typeView: 'full',
};

export default injectIntl(DeletePreview);
