import React from 'react';
import { Row, Col, Typography } from 'antd';
import Messages from 'i18n/messages/product';
import { FormattedMessage } from 'react-intl';

const { Paragraph } = Typography;

const ProductSyndicationHistoryMessage = (props) => {
  const { messageContent } = props.message;
  return (
    <Row className='syndication-history-panel__message'>
      <Col span={24}>
        <Paragraph className='syndication-history-panel__message--name'>
          <FormattedMessage {...Messages.validationFail} />
        </Paragraph>
        <Paragraph className='syndication-history-panel__message--description'>
          {messageContent?.toLowerCase() === 'null' || messageContent === null
            ? ''
            : messageContent}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default ProductSyndicationHistoryMessage;
