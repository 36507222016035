import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';

import ProductCategoryForm from 'pages/company-profile/components/tabs/system/category-management/components/ProductCategoryForm';
import { ProductCategoryTree } from 'common/components';

import { getCategoryObjectWhenSelectNode } from 'pages/company-profile/components/tabs/system/category-management/utils';

import './SelectCategory.less';

const ProductCategoryHierarchy = ({
  setHierarchyFormValue,
  showEmptyCategory = false,
  nestedCategory,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const [nodeSelected, setNodeSelected] = useState(null);

  const [isBlankCategory, setIsBlankCategory] = useState(false);

  const onSelectNode = (nodeFound) => {
    const fieldsValues = getCategoryObjectWhenSelectNode(
      nestedCategory,
      nodeFound
    );
    form.setFieldsValue(fieldsValues);
    setIsBlankCategory(false);
    setNodeSelected(nodeFound);
    setHierarchyFormValue(form.getFieldsValue());
  };

  const unSelectNode = () => {
    const unSelectNode = new CustomEvent('unSelectNode', {
      detail: 'reset-category-modal',
    });
    document.dispatchEvent(unSelectNode);
  };

  const setEmptyCategory = () => {
    form.resetFields();
    unSelectNode();
    setNodeSelected(null);
    setIsBlankCategory(true);
  };

  return (
    <Row
      gutter={[24, 0]}
      style={{ height: '100%' }}
      className='Filter-category-product'
    >
      <Col span={13} style={{ height: '100%' }}>
        <Form
          autoComplete='off'
          form={form}
          className='Filter-category-product__form scroller scroller--y'
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <div style={{ paddingLeft: '25px' }}>
            <ProductCategoryForm disableForm={isBlankCategory} />
          </div>
        </Form>
      </Col>
      <Col span={11} style={{ height: '100%' }}>
        <ProductCategoryTree
          onSelect={onSelectNode}
          nestedCategory={nestedCategory}
          mode={false}
          isLoading={isLoading}
          showEmptyCategory={showEmptyCategory}
          setEmptyCategory={setEmptyCategory}
        />
      </Col>
    </Row>
  );
};

export default ProductCategoryHierarchy;
