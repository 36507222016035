import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Row, Col, Typography, Input } from 'antd';

import { CustomNotification, WithLoading, Form } from 'common/components';
import { EditContainer } from 'pages/qa-spec/components';

import EditInputCountry from 'common/components/form/edit-input-country/EditInputCountry';
import { SectionWrapper } from 'common/components';
import CountryEditorRender from '../qa-grid-cell/CountryEditorRender';

import Messages from 'i18n/messages/qa-spec';

import {
  useGetQaSpecDataContact,
  useQueryQaContactDataSnapshot,
} from './queries';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';

import { apiHandler } from 'utils/api';
import { saveQaSpecDataContact } from 'services/qaSpec';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';

import { titleQAContactSection } from 'pages/qa-spec/constant';
import { QA_SPEC_CONTACT } from 'pages/qa-spec/constant';
import {
  SnapshotInputWrapper,
  SnapshotFormItemWrapper,
} from 'pages/qa-spec/components/form';

import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import { formatCompareValue } from 'pages/qa-spec/utils';
import { isEqual, get } from 'lodash';

import './QaSpecContact.less';

const { Text } = Typography;

const QaSpecContacts = ({ id }) => {
  const intl = useIntl();

  const { qaSpecDataContact, refetchQaSpecDataContact } =
    useGetQaSpecDataContact({
      id,
      isEnabled: !!id,
    });

  const successMessage = intl.formatMessage(
    Messages.updateQASpecContactSuccess
  );
  const errorMessage = intl.formatMessage(Messages.updateQASpecContactFailed);

  const { productId, ...propertyContact } = qaSpecDataContact;

  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const useSnapshot = useCheckSnapshotForRetailer();

  const { data: qaSpecContactDataSnapshot } = useQueryQaContactDataSnapshot({
    productId: id,
    enabled: Boolean(productId && useSnapshot),
  });

  useEffect(() => {
    form.setFieldsValue(propertyContact);
  }, [form, JSON.stringify(propertyContact)]);

  const onCancel = () => {
    form.resetFields();
    form.setFieldsValue(propertyContact);
  };

  const editQaSpecDataSuccessfully = () => {
    refetchQaSpecDataContact();
    setLoading(false);
  };

  const onFinallyEditSuccessfully = (toggleEdit) => {
    toggleEdit();
  };

  const onSaveHeader = async (toggleEdit) => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const params = { ...values, productId };
      apiHandler({
        service: saveQaSpecDataContact,
        params,
        successMessage,
        errorMessage,
        successCallback: editQaSpecDataSuccessfully,
        onFinally: () => onFinallyEditSuccessfully(toggleEdit),
      });
    } catch (error) {
      if (error?.errorFields?.length > 0) {
        CustomNotification.error('Please resolve errors in the Contact tab.');
      }
      setLoading(false);
    }
  };

  const layoutCol = {
    labelCol: {
      xxl: { span: 11 },
      xl: { span: 10 },
    },
    wrapperCol: {
      xxl: { span: 13 },
      xl: { span: 15 },
    },
  };

  const isRequiredFieldsForSupplier = Boolean(isProductAssignedByRetailer);

  const getSnapshotFormItemWrapperProps = ({ fieldPath, labelComp }) => {
    const snapshotFieldValue = get(qaSpecContactDataSnapshot, fieldPath);
    const procSnapshotFieldValue = formatCompareValue(snapshotFieldValue);

    return {
      checkShowSnapshotFn: ({ value }) => {
        const procValue = formatCompareValue(value);

        const isShowSnapshot =
          !isEqual(procValue, procSnapshotFieldValue) &&
          useSnapshot &&
          qaSpecContactDataSnapshot;

        return Boolean(isShowSnapshot);
      },
      snapshotValue: (
        <span>
          <div style={{ display: 'inline-block' }}>{labelComp}</div>
          <span>
            {':'}&nbsp;{procSnapshotFieldValue}
          </span>
        </span>
      ),
    };
  };

  const checkContactFieldRequired = ({ section, fieldName }) => {
    if (!isRequiredFieldsForSupplier) return false;
    if (!requiredFields) return false;

    const sectionMap = {
      primaryBusiness: 'primaryBusinessContact',
      primaryQc: 'primaryQcContact',
      _24Hour: '_24HourContact',
    };
    const requiredFieldSection = sectionMap[section];

    if (!requiredFieldSection) return false;

    return requiredFields.includes(
      `qaSpecification.qaSpecContacts.${requiredFieldSection}.${fieldName}`
    );
  };

  const renderField = (isEdit, fields, sectionKey) => {
    const inputCollection = {
      text: <Input readOnly={!isEdit} />,
      phone: <Input readOnly={!isEdit} />,
      country: isEdit ? (
        <EditInputCountry />
      ) : (
        <CountryEditorRender
          wrapperStyle={{
            padding: 5,
            border: '1px solid #dedede',
            height: 28.4,
          }}
        />
      ),
    };

    return fields.map((field) => {
      const InputField = inputCollection[field.type];

      const label = intl.formatMessage(Messages?.[field.name]);

      const isRequired = checkContactFieldRequired({
        section: sectionKey,
        fieldName: field.name,
      });

      const fieldRule = field?.rules || [];
      const rules = [
        {
          required: isRequired,
          message: `${label ? label : 'This field'} is required`,
        },
        ...fieldRule,
      ];

      const labelComp = <FormLabel label={label} type={field.type} />;

      const FieldComponent = () => {
        if (useSnapshot) {
          return (
            <SnapshotFormItemWrapper
              {...getSnapshotFormItemWrapperProps({
                fieldPath: [sectionKey, field.name],
                labelComp,
              })}
            >
              {(inputWrapperProps) => (
                <Form.Item
                  key={`${field.name}-${sectionKey}`}
                  name={[sectionKey, field.name]}
                  label={labelComp}
                  rules={rules}
                >
                  <SnapshotInputWrapper
                    {...inputWrapperProps}
                    popoverProps={{ visible: false }}
                  >
                    {InputField}
                  </SnapshotInputWrapper>
                </Form.Item>
              )}
            </SnapshotFormItemWrapper>
          );
        }

        return (
          <Form.Item
            key={`${field.name}-${sectionKey}`}
            name={[sectionKey, field.name]}
            label={labelComp}
            rules={rules}
          >
            {InputField}
          </Form.Item>
        );
      };

      return <Col span={field.col || 24}>{FieldComponent()}</Col>;
    });
  };

  const renderSection = (isEdit, section) => {
    return (
      <SectionWrapper
        className='qa-spec-contact__info-section'
        title={titleQAContactSection[section.key]}
      >
        <Row>{renderField(isEdit, section.fields, section.key)}</Row>
      </SectionWrapper>
    );
  };

  return (
    <Row className='qa-spec-contact__container'>
      <Form {...layoutCol} form={form} style={{ width: '100%' }}>
        <EditContainer
          productId={id}
          handleCancel={onCancel}
          handleSave={onSaveHeader}
          type={EDIT_SECTION.QA_CONTACT}
          float
        >
          {(edit) => {
            return (
              <WithLoading loading={loading}>
                <EditTabSectionWrapper.CheckerWrapper
                  isEdit={edit}
                  type={EDIT_SECTION.QA_CONTACT}
                >
                  <Row
                    gutter={[8, 24]}
                    className={
                      !edit
                        ? 'qa-spec-contact__form'
                        : 'qa-spec-contact__form--edit'
                    }
                  >
                    {Object.entries(QA_SPEC_CONTACT).map(([key, value]) => {
                      return (
                        <Col xxl={8} xl={12} key={key}>
                          {renderSection(edit, QA_SPEC_CONTACT[key])}
                        </Col>
                      );
                    })}
                  </Row>
                </EditTabSectionWrapper.CheckerWrapper>
              </WithLoading>
            );
          }}
        </EditContainer>
      </Form>
    </Row>
  );
};

const FormLabel = ({ label, type }) => {
  return (
    <>
      {type === 'phone' ? (
        <div className='qa-business-contact__label'>
          <Text style={{ marginRight: '3px' }}>
            <strong>{label}</strong>
          </Text>
        </div>
      ) : (
        <Text>
          <strong>{label}</strong>
        </Text>
      )}
    </>
  );
};

export default QaSpecContacts;
