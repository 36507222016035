import React, { useState } from 'react';
import { PartitionOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  checkProductsSubscriptionShield,
  checkIsGDSNProducts,
  validateProductOwner,
  validateAllActiveProducts,
  validateSubscriptedShield,
  verifyGDSNProductShield,
} from './utils';

import messages from 'i18n/messages/home';
import PublicationSetupModal from 'common/components/product-publication-setup/PublicationSetupModal';

import userSelectors from 'redux/user/selectors';

import PublicationValidationView from 'pages/branded-products/components/publication-validation-view/PublicationValidationView';
import { RibbonButton } from 'common/components';

const GDSNPublication = ({
  products,
  isFullView = false,
  productFull = {},
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const [publishVisible, setPublishVisible] = useState(false);
  const [requestParams, setRequestParams] = useState(null);

  const checkEnabledGDSNPublishGrid = (products, isRivirGdsnParty) => {
    /* 
      Enabled Condition:
      - All owner products
      - All Products active
      - No subscribed products
      - No GDSN products
    */

    if (products?.length === 0) return false;

    const isOwner = validateProductOwner(products);
    const allActiveProducts = validateAllActiveProducts(products);
    const isRIVIRGdsnParty = Boolean(isRivirGdsnParty);
    const hasSubscribedProducts = checkProductsSubscriptionShield(products);
    const hasGDSNProduct = checkIsGDSNProducts(products);

    return (
      isOwner &&
      allActiveProducts &&
      isRIVIRGdsnParty &&
      !hasSubscribedProducts &&
      !hasGDSNProduct
    );
  };

  const checkEnableGDSNPublishFull = () => {
    const isOwner = Boolean(productFull?.isOwner);
    const isActive = Boolean(productFull?.status?.toLowerCase() === 'active');
    const isSubscribedProduct = validateSubscriptedShield(productFull);
    const isGdsnShield = verifyGDSNProductShield(productFull);

    return isOwner && isActive && !isSubscribedProduct && !isGdsnShield;
  };

  const handleCancel = () => {
    setModalVisible(false);
    setErrorList([]);
    setRequestParams([]);
  };

  const handleOk = (values) => {
    setRequestParams(values);
    setPublishVisible(true);
    setModalVisible(false);
  };

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<PartitionOutlined />}
        label={messages.GDSNPublicationSetup}
        onClick={() => setModalVisible(true)}
        disabled={
          isFullView
            ? !checkEnableGDSNPublishFull()
            : !checkEnabledGDSNPublishGrid(products, userInfo?.isRivirGdsnParty)
        }
      />

      {modalVisible && (
        <PublicationSetupModal
          visible={modalVisible}
          handleCancel={handleCancel}
          handleOk={handleOk}
          product={products?.[0]}
          errorList={errorList}
          isFullView={isFullView}
          productFull={productFull}
        />
      )}

      {publishVisible && (
        <PublicationValidationView
          visible={publishVisible}
          handleVisible={setPublishVisible}
          handleSetModalVisible={setModalVisible}
          requestParams={requestParams}
          isFullView={isFullView}
          productId={productFull?.productId}
        />
      )}
    </>
  );
};

export default GDSNPublication;
