import { useDispatch } from 'react-redux';

import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';

import { CustomNotification } from 'common/components';

import { sleep } from 'utils/delay';
import { useGetQaSpecDataHeader } from 'pages/qa-spec/query';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import {
  getQaSpecWorkflowTodoList,
  acceptOrRejectWorkflowTodo,
  submitQaSpecWorkflow,
  unlockQaSpecWorkflow,
} from 'services/qaSpecWorkflow';

import { qaToggleReloadDocumentCertificateTab } from 'pages/qa-spec/controllers/actions';

import { EVENT } from 'static/Constants';

export const getKeyQueryQaSpecWorkflowTodoList = () => {
  return ['qa-spec-workflow-todo-list'];
};
export const useGetQaSpecTodoList = (enabled = false) => {
  const queryClient = useQueryClient();

  const { data, isLoading, ...rest } = useQuery({
    queryKey: getKeyQueryQaSpecWorkflowTodoList(),
    queryFn: async () => {
      const response = await getQaSpecWorkflowTodoList({
        pageIndex: 1,
        pageSize: 9999,
        onlySubmitted: true,
      });
      return response?.data?.gridData;
    },
    staleTime: 3 * 60 * 1000,
    enabled,
  });

  const refetchQaSpecWorkflowTodoList = () => {
    queryClient.invalidateQueries({
      queryKey: getKeyQueryQaSpecWorkflowTodoList(),
    });
  };

  return {
    qaSpecWorkflowTodoList: data ?? [],
    refetchQaSpecWorkflowTodoList,
    isLoading,
    ...rest,
  };
};

export const useAcceptOrRejectQaSpecWorkflow = () => {
  return useMutation({
    mutationFn: acceptOrRejectWorkflowTodo,
  });
};

export const useMutationSubmitQaSpecWorkflow = () => {
  return useMutation({
    mutationFn: submitQaSpecWorkflow,
  });
};

export const useMutationUnlockSpecWorkflow = () => {
  return useMutation({
    mutationFn: unlockQaSpecWorkflow,
  });
};

export const useActionWorkflow = ({
  setShowModalReason,
  workflowId,
  setVisible,
}) => {
  const reloadPage = useDispatchReloadPage();

  const dispatch = useDispatch();

  const acceptOrRejectWorkflowMutation = useAcceptOrRejectQaSpecWorkflow();
  const { refetchQaSpecWorkflowTodoList } = useGetQaSpecTodoList();
  const unlockWorkflowMutation = useMutationUnlockSpecWorkflow();

  const isQaSpecWorkflowPath =
    window.location.pathname === '/qa-spec-workflows';

  const { refetchQaSpecDataHeader } = useGetQaSpecDataHeader({
    id: parseInt(workflowId),
    isEnabled: false,
  });

  const refetchWorkflowWhenSuccess = async () => {
    await sleep(1000);
    if (isQaSpecWorkflowPath) {
      reloadPage();
    }
    if (setVisible) setVisible(false);
    refetchQaSpecWorkflowTodoList();
    refetchQaSpecDataHeader();
  };

  const onApproveWorkflow = (id) => {
    const params = {
      productItemId: id,
      isReject: false,
    };
    acceptOrRejectWorkflowMutation.mutate(params, {
      onSuccess: async (response) => {
        if (response?.isSuccess) {
          CustomNotification.success('Approve Workflow Successfully!');

          await refetchWorkflowWhenSuccess();
          dispatch(qaToggleReloadDocumentCertificateTab());

          const reloadDocGrid = new CustomEvent(
            EVENT.RELOAD_DOC_GRID_WHEN_ACCEPT_WORKFLOW
          );
          document.dispatchEvent(reloadDocGrid);
        } else {
          CustomNotification.error(response?.message);
        }
      },
      onError: () => {
        CustomNotification.error('Something error occurred');
      },
    });
  };

  const onRejectWorkflow = (value) => {
    const params = {
      productItemId: workflowId,
      isReject: true,
      rejectReason: value.rejectReason,
    };
    acceptOrRejectWorkflowMutation.mutate(params, {
      onSuccess: async (response) => {
        if (response?.isSuccess) {
          setShowModalReason(false);
          await refetchWorkflowWhenSuccess();
          CustomNotification.success('Reject Workflow Successfully!');
        } else {
          CustomNotification.error(response?.message);
        }
      },
      onError: () => {
        CustomNotification.error('Something error occurred');
      },
    });
  };

  const onUnlockWorkflow = (id) => {
    const params = {
      productItemId: id,
    };
    unlockWorkflowMutation.mutate(params, {
      onSuccess: async (response) => {
        if (response?.isSuccess) {
          CustomNotification.success('Unlock Workflow Successfully!');
          await refetchWorkflowWhenSuccess();
        } else {
          CustomNotification.error(response?.message);
        }
      },
      onError: () => {
        CustomNotification.error('Something error occurred');
      },
    });
  };
  return {
    onApproveWorkflow,
    onRejectWorkflow,
    onUnlockWorkflow,
    acceptOrRejectWorkflowMutation,
    unlockWorkflowMutation,
  };
};
