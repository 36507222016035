import React, { cloneElement } from 'react';
import * as _ from 'lodash';

const newParagphConfigDetect = /\(\!p.*\)/g;

const pFontConfig = /font=(.*?)[\|\)]/;
const pColorConfig = /color=(.*?)[\|\)]/;
const pSizeConfig = /size=(.*?)[\|\)]/;
const pAlignConfig = /align=(.*?)[\|\)]/;
const pBeakAlignConfig = /breakAlign=(.*?)[\|\)]/;

const ParagraphRender = (props) => {
  const getConfig = (child) => {
    const firstValue = child.props.value || '';
    const configMatch = firstValue.match(newParagphConfigDetect);

    const colorConfig = pColorConfig.exec(configMatch);
    const fontConfig = pFontConfig.exec(configMatch);
    const alignConfig = pAlignConfig.exec(configMatch);
    const sizeConfig = pSizeConfig.exec(configMatch);
    const breakAlignConfig = pBeakAlignConfig.exec(configMatch);

    let detailColorConfig,
      detailFontConfig,
      detailAlignConfig,
      detailSizeConfig,
      detailBreakAlignConfig;

    if (colorConfig) {
      detailColorConfig = colorConfig[1].trim();
    }
    if (fontConfig) {
      detailFontConfig = fontConfig[1].trim();
    }
    if (alignConfig) {
      detailAlignConfig = alignConfig[1].trim();
    }
    if (sizeConfig) {
      detailSizeConfig = sizeConfig[1].trim();
    }
    if (breakAlignConfig) {
      detailBreakAlignConfig = breakAlignConfig[1].trim();
    }
    return {
      configMatch,
      detailColorConfig,
      detailFontConfig,
      detailAlignConfig,
      detailSizeConfig,
      detailBreakAlignConfig,
    };
  };
  let generalConfig;

  const renderChildren = () => {
    if (props?.children?.[0]?.key.includes('image')) {
      return props.children;
    }
    if (props?.children?.length === 1) {
      if (props.children?.[0]?.key.includes('image')) {
        return props.children;
      } else {
        generalConfig = getConfig(props.children?.[0]);

        let nextValue, cloneChildren;
        if (generalConfig?.configMatch) {
          nextValue = props.children?.[0]?.props?.value.replace(
            generalConfig?.configMatch[0],
            ''
          );
          cloneChildren = cloneElement(props.children[0], {
            value: nextValue,
            children: nextValue,
          });
        }

        return cloneChildren || props.children;
      }
    }
    if (props?.children?.length > 1) {
      generalConfig = getConfig(props.children?.[0]);
      let nextValue, cloneChildren;

      if (generalConfig?.configMatch) {
        nextValue = props.children?.[0]?.props?.value.replace(
          generalConfig.configMatch[0],
          ''
        );

        cloneChildren = cloneElement(props.children[0], {
          value: nextValue,
          children: nextValue,
        });
      }
      if (cloneChildren) {
        return (
          <>
            {cloneChildren && cloneChildren}
            {props.children.filter((child, idx) => {
              return idx > 0;
            })}
          </>
        );
      } else {
        return <>{props.children} </>;
      }
    }
  };

  const render = renderChildren();

  return (
    <p
      style={{
        color: generalConfig?.detailColorConfig,
        fontSize: generalConfig?.detailSizeConfig,
        fontFamily: generalConfig?.detailFontConfig,
        clear: generalConfig?.detailBreakAlignConfig === 'true' ? 'both' : '',
      }}
    >
      {render}
    </p>
  );
};

export default ParagraphRender;
