import React from 'react';

import { Divider, Space } from 'antd';

import EmailEditorFontSetting from './EmailEditorFontSetting';
import EmailEditorShowHideSettings from './EmailEditorShowHideSettings';

const EmailEditorSenderInfoSettings = (props) => {
  return (
    <Space direction='horizontal' size={0}>
      <EmailEditorFontSetting />
      <Divider type='vertical' style={{ margin: '0 4px' }} />
      <EmailEditorShowHideSettings
        options={['date', 'name', 'company', 'address']}
      />
    </Space>
  );
};

export default EmailEditorSenderInfoSettings;
