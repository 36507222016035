import { useMemo } from 'react';
import { PROCESS_STAGE_LIST_ERRORS, columnDefsDocument } from './constants';

import { manufacturingProcessCharactersLimit } from '../../constant/charactersLimit';

import { DocumentActions } from '../qa-grid-cell';
import CellActionRenderSnapshotWrapper from '../qa-grid-cell/CellActionRenderSnapshotWrapper';

const validateEmptyRows = (rows) => {
  return rows.map((row) => {
    if (!row.processStage?.trim()) {
      row = {
        ...row,
        errors: [
          ...new Set(
            row.errors.concat(PROCESS_STAGE_LIST_ERRORS.PROCESS_STAGE_EMPTY)
          ),
        ],
      };
    } else {
      row = {
        ...row,
        errors: row?.errors?.filter(
          (error) => error !== PROCESS_STAGE_LIST_ERRORS.PROCESS_STAGE_EMPTY
        ),
      };
    }
    return row;
  });
};

export const validateRowData = (rowData) => {
  return validateEmptyRows(rowData);
};

export const checkErrorInRowData = (rowData) => {
  return rowData.some((row) => row?.errors?.length > 0);
};

export const generateColumDefs = ({
  processStageCols,
  productEnums,
  isToggleSnapshot,
}) => {
  let result = [];

  processStageCols.forEach((col) => {
    if (col.field === 'processStage') {
      const newCol = {
        ...col,
        cellEditorSelector: (params) => {
          return {
            component: 'TextEditor',
            popup: true,
            params: {
              maxLength: manufacturingProcessCharactersLimit.processStage,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
      result.push(newCol);
    } else if (col.field === 'natureOfTheTests') {
      const newCol = {
        ...col,
        cellEditorSelector: (params) => {
          return {
            component: 'TextEditor',
            popup: true,
            params: {
              maxLength: manufacturingProcessCharactersLimit.natureOfTheTests,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
      result.push(newCol);
    } else if (col.field === 'targetValueAndTolerances') {
      const newCol = {
        ...col,
        cellEditorSelector: (params) => {
          return {
            component: 'TextEditor',
            popup: true,
            params: {
              maxLength:
                manufacturingProcessCharactersLimit.targetValueAndTolerances,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
      result.push(newCol);
    } else if (col.field === 'frequency') {
      const newCol = {
        ...col,
        cellEditorSelector: (params) => {
          return {
            component: 'TextEditor',
            popup: true,
            params: {
              maxLength: manufacturingProcessCharactersLimit.frequency,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
      result.push(newCol);
    } else if (col.field === 'test') {
      const newCol = {
        ...col,
        width: 300,
        flex: null,
        cellEditorSelector: () => {
          const options = productEnums?.find(
            (enumItem) => enumItem.enumName === 'ProcessTestCodeEnum'
          )?.enumProperties;

          return {
            popup: true,
            component: 'SelectionFreeText',
            params: {
              values: options.map((option) => ({
                value: option.enumDisplayName,
                displayName: option.enumDisplayName,
              })),
              overlayClassName: 'process-stage__dropdown',
              popup: true,
              maxLength: manufacturingProcessCharactersLimit.test,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
      result.push(newCol);
    } else if (col.field === 'criticalPointEstablishedInHACCP') {
      const newCol = {
        ...col,
        cellEditorSelector: (params) => {
          return {
            component: 'TextEditor',
            popup: true,
            params: {
              maxLength: manufacturingProcessCharactersLimit.frequency,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
      result.push(newCol);
    } else if (col.field === 'recognizedMethods') {
      const newCol = {
        ...col,
        cellEditorSelector: () => {
          const options = productEnums?.find(
            (enumItem) => enumItem.enumName === 'RecognizedMethodsCodeEnum'
          )?.enumProperties;

          return {
            component: 'SelectionEditor',
            params: {
              values: options.map((optionItem) => ({
                value: optionItem.enumDisplayName,
                displayName:
                  optionItem.enumDisplayName === null
                    ? ''
                    : optionItem.enumDisplayName,
              })),
              mapValue: (value) => {
                if (value === 'null' || value === undefined) {
                  return null;
                } else {
                  return value;
                }
              },
            },

            popup: true,
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
      result.push(newCol);
    } else {
      result.push({
        ...col,
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      });
    }
  });

  return result.map((fieldItem) => {
    const DEFAULT_WIDTH = 150;

    let initColSetting = {
      type: 'editableColumn',
    };

    return {
      ...initColSetting,
      ...fieldItem,
      minWidth: DEFAULT_WIDTH,
    };
  });
};

export const useGenerateColumDefsDocument = ({
  handlePreview,
  handleDownload,
  handleDelete,
  isToggleSnapshot,
}) => {
  const columnDefs = useMemo(() => {
    let result = [];

    columnDefsDocument.forEach((col, index) => {
      if (col.field === 'documentActions') {
        const newCol = {
          ...col,
          cellRenderer: (params) => {
            const linkUrl = params?.data?.linkUrl;
            const linkUrlSnapshot =
              params?.context?.snapshotGridValues?.[params?.rowIndex]?.linkUrl;
            return (
              <>
                {isToggleSnapshot ? (
                  <CellActionRenderSnapshotWrapper
                    {...params}
                    isHighlightChange={linkUrl !== linkUrlSnapshot}
                  >
                    <DocumentActions
                      onPreview={() => handlePreview(linkUrlSnapshot)}
                      onDownload={() => handleDownload(linkUrlSnapshot)}
                      onDelete={() => handleDelete(linkUrlSnapshot)}
                    />
                  </CellActionRenderSnapshotWrapper>
                ) : (
                  <DocumentActions
                    onPreview={() => handlePreview(params.data)}
                    onDownload={() => handleDownload(params.data)}
                    onDelete={() => handleDelete(params.data)}
                  />
                )}
              </>
            );
          },
        };
        result.push(newCol);
      } else {
        col = {
          ...col,
          cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
        };
        result.push(col);
      }
    });

    result = result.map((colItem) => {
      return {
        cellEditorSelector: () => {},
        ...colItem,
      };
    });

    return result;
  }, [isToggleSnapshot]);

  return columnDefs;
};

export const generateLocationColumDefs = ({ isToggleSnapshot }) => {
  return [
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      headerName: 'Location Name',
      field: 'locationName',
      cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      flex: 1,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      headerName: 'Location Description',
      field: 'locationDescription',
      cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      flex: 1,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      headerName: 'Main Address',
      field: 'mainAddress',
      cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      flex: 1,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      headerName: 'City',
      field: 'city',
      cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      flex: 1,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      headerName: 'State',
      field: 'state',
      cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      flex: 1,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      headerName: 'Zip Code',
      field: 'zipcode',
      cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      flex: 1,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'boolean',
      headerName: 'Primary Address',
      field: 'isPrimaryAddress',
      cellRenderer: isToggleSnapshot
        ? 'CheckboxRenderSnapshot'
        : 'CheckboxRender',
      flex: 1,
    },
  ];
};
