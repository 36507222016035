import React from 'react';

import { Row, Col, Typography } from 'antd';

import { InfoWithLabel } from 'common/components';

import { MEASUREMENT_UNIT } from '../../constants';

import {
  renderName,
  renderDescription,
  getNameAndItalicizeNameSupplement,
} from 'pages/product-full-view/components/product-media-area/supplement-label/utils';

const { Text } = Typography;

const renderViewMode = (props) => {
  const { item, fieldName } = props;

  const convertUom = (unit) => {
    return MEASUREMENT_UNIT[unit?.toLowerCase()] || unit;
  };

  const renderQuantity = () => {
    const convertedUom = item.quantityUom ? convertUom(item.quantityUom) : '';

    const quantity =
      item.quantity === null || item.quantity === undefined
        ? ''
        : `${Math.round(item.quantity * 100) / 100}${convertedUom}`;

    const description = renderDescription(item?.quantityDescription, false);

    return [quantity, description].filter(Boolean).join(' ');
  };

  const renderDailyValue = () => {
    return `${
      item.dailyValue !== undefined && item.dailyValue !== null
        ? item.dailyValue + '%'
        : ''
    }${item.dailySymbol ? item.dailySymbol : ''}`;
  };

  const { name, italicName } = getNameAndItalicizeNameSupplement(item, fieldName);

  return (
    <>
      <Row wrap={false} gutter={8}>
        <Col flex={1}>
          <Text
            style={{ fontWeight: 700 }}
            ellipsis={{
              tooltip: `${renderName(name, false)} ${renderDescription(
                item?.descriptor,
                false
              )}`,
            }}
          >
            {renderName(name, italicName)}
            {renderDescription(item?.descriptor, item?.italicDescriptor)}
          </Text>
        </Col>
        <Col flex='120px'>
          <Text>{renderQuantity()}</Text>
        </Col>
        <Col flex='65px' style={{ textAlign: 'right' }}>
          <Text>{renderDailyValue()}</Text>
        </Col>
      </Row>
      {item?.ingredientsPartOf ? (
        <InfoWithLabel
          showTooltip
          label='Part Of'
          info={item?.ingredientsPartOf}
          labelColProps={{ span: 4 }}
          infoColProps={{ span: 20 }}
        />
      ) : null}
    </>
  );
};

export default renderViewMode;
