import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Space, Radio } from 'antd';
import * as _ from 'lodash';
import classnames from 'classnames';

//* COMPONENTS
import { UploadOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import {
  CustomNotification,
  UploadFile,
  StyledModal,
  CropImage,
} from 'common/components';

//* SERVICES
import * as memberServices from 'services/members';

//* CONSTANTS
import { OPEN_ITEM_CLASS, UPLOAD_MAX_SIZE } from 'static/Constants';

//* UTILS
import { getBase64, cropImageWithConfig } from 'utils/image';
import * as api from 'config/axios';

//* STYLING
import './BackgroundImageEdit.less';

const defaultTemp = {
  tempConfig: undefined,
  tempPreview: undefined,
  tempSrc: undefined,
  tempCanvas: undefined,
};

const origin = window.location.origin;

const BackgroundImageEdit = (props) => {
  const {
    isEdit,
    apiUploadUrl,
    id,
    src,
    backgroundConfig,
    additionalPayload,
    setIsLoading,
    forcePreviewImage,
    heightSize,
    initHeightSize,
    setHeightSize,
    setBgInitHeight,
    setIsEdit,
    //* replace memberber service to save config
    editBackgroundService,
    additionalUploadParams,
    onSaveSuccess,
    //* product background using member config cannot adjust image without upload new image
    limitAdjustImage = false,
  } = props;

  let { instance } = props;

  const [isUpload, setIsUpload] = useState(false);
  const [isCrop, setIsCrop] = useState(false);
  const [previewImg, setPreviewImg] = useState(undefined);
  const [procSrc, setProcSrc] = useState(undefined);
  const [procConfig, setProcConfig] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [isAllowToSaveHeight, setIsAllowToSaveHeight] = useState(false);
  const [isAllowAdjust, setIsAllowAdjust] = useState(true);

  const refBackground = useRef(null);
  const refCropper = useRef();

  const refTemp = useRef(defaultTemp);

  const toggleIsUpload = () => setIsUpload(!isUpload);
  const toggleIsCrop = () => setIsCrop(!isCrop);

  const handleProccessImage = useCallback((source, config) => {
    modOriginalSrc(source);
    if (source !== '') {
      var imageObj = new Image();
      imageObj.onload = function async() {
        const result = cropImageWithConfig(imageObj, {
          offsetX: config?.left,
          offsetY: config?.top,
          cropWidth: config?.width,
          cropHeight: config?.height,
        });

        setPreviewImg(result.toDataURL());
      };
      imageObj.src = source;
    }
    _.isEqual(config, {}) && setPreviewImg(undefined);
  }, []);

  const modOriginalSrc = useCallback((value) => {
    setProcSrc(value);
  }, []);

  const modOriginalConfig = useCallback((value) => {
    setProcConfig(value);
  }, []);

  const handleUpload = useCallback(async () => {
    if (selectedFile) {
      let payload = {
        file: selectedFile?.file?.originFileObj,
      };

      return api.sendUpload({
        api: origin + apiUploadUrl,
        payload,
        additionalBodyPayload: additionalUploadParams || {},
      });
    } else {
      return true;
    }
  }, [selectedFile, apiUploadUrl, additionalUploadParams]);

  const mapToSendBackgroundConfig = (isOriginalCropConfig = false) => {
    if (!isOriginalCropConfig) {
      const canvasData = refTemp.current?.tempCanvas;
      const aspect = canvasData.naturalWidth / canvasData.width;

      return {
        backgroundConfiguration: JSON.stringify({
          cropConfig: {
            left: Math.floor((procConfig.left - canvasData.left) * aspect),
            top: Math.floor((procConfig.top - canvasData.top) * aspect),
            width: Math.floor(procConfig.width * aspect),
            height: Math.floor(procConfig.height * aspect),
          },
          heightConfig: {
            heightSize,
          },
        }),
        ...additionalPayload,
      };
    } else {
      return {
        backgroundConfiguration: JSON.stringify({
          cropConfig: backgroundConfig,
          heightConfig: {
            heightSize,
          },
        }),
        ...additionalPayload,
      };
    }
  };

  const createBgConfig = (config, canvas) => {
    const canvasData = canvas;
    const aspect = canvasData.naturalWidth / canvasData.width;

    return {
      left: Math.floor((config.left - canvasData.left) * aspect),
      top: Math.floor((config.top - canvasData.top) * aspect),
      width: Math.floor(config.width * aspect),
      height: Math.floor(config.height * aspect),
    };
  };

  const handleSave = () => {
    const apiService = editBackgroundService
      ? editBackgroundService
      : memberServices.editBackgroundConfig;

    if (isAllowToSaveHeight && !refTemp.current.tempConfig) {
      setIsLoading && setIsLoading(true);
      const payload = mapToSendBackgroundConfig(true);

      apiService(payload).then(async (updateBgConfigResponse) => {
        const { isSuccess } = updateBgConfigResponse || {};

        if (isSuccess) {
          resetVar();
          setIsAllowToSaveHeight(false);
          setBgInitHeight(undefined);
          CustomNotification.success('Updated background successfully');
          if (onSaveSuccess) {
            await onSaveSuccess();
          }
          setIsEdit && setIsEdit(false);
          setIsLoading(false);
        } else {
          CustomNotification.error('Failed to update background');
          setIsLoading(false);
        }
      });
    } else if (refCropper.current && refTemp.current.tempConfig) {
      setIsLoading && setIsLoading(true);

      handleUpload()
        .then((uploadResult) => {
          if (uploadResult?.data?.isSuccess === true || uploadResult === true) {
            setSelectedFile(undefined);

            const payload = mapToSendBackgroundConfig();

            apiService(payload).then(async (updateBgConfigResponse) => {
              const { isSuccess } = updateBgConfigResponse || {};

              if (isSuccess) {
                setBgInitHeight(undefined);
                resetVar();
                CustomNotification.success('Updated background successfully');
                if (onSaveSuccess) {
                  await onSaveSuccess({
                    isChangeBackgroundFile: !!uploadResult?.data?.isSuccess,
                  });
                }
                setIsEdit && setIsEdit(false);
                setIsLoading(false);
                if (limitAdjustImage) {
                  setIsAllowAdjust(true);
                }
              } else {
                CustomNotification.error('Failed to update background');
                setIsLoading(false);
                if (limitAdjustImage) {
                  setIsAllowAdjust(false);
                }
              }
            });
          } else {
            CustomNotification.error('Failed to update background');
            setIsLoading(false);
            if (limitAdjustImage) {
              setIsAllowAdjust(false);
            }
          }
        })
        .catch((error) => {
          CustomNotification.error('Failed to update background');
          setIsLoading(false);
          if (limitAdjustImage) {
            setIsAllowAdjust(false);
          }
        });
    }
  };

  const resetVar = () => {
    refTemp.current = defaultTemp;
  };

  const handleCancel = () => {
    modOriginalSrc(src);
    modOriginalConfig(backgroundConfig);
    handleProccessImage(src, backgroundConfig);
    resetVar();
    setSelectedFile(undefined);
    setIsAllowToSaveHeight(false);
    if (limitAdjustImage) {
      setIsAllowAdjust(false);
    }
  };

  const onHeightSizeChange = (e) => {
    setHeightSize && setHeightSize(e.target.value);
    if (e.target.value !== initHeightSize) {
      setIsAllowToSaveHeight(true);
    } else {
      setIsAllowToSaveHeight(false);
    }
  };

  const onOkCrop = () => {
    setIsCrop(false);
    refTemp.current = {
      tempPreview: previewImg,
      tempConfig: procConfig,
      tempSrc: procSrc,
      tempCanvas: refCropper.current.cropper.getCanvasData(),
    };
    if (limitAdjustImage) {
      setIsAllowAdjust(true);
    }
  };

  const onCancelCrop = () => {
    setIsCrop(false);
    if (
      !refTemp.current.tempSrc &&
      !refTemp.current.tempConfig &&
      !refTemp.current.tempPreview &&
      !refTemp.current.tempCanvas
    ) {
      modOriginalSrc(src);
      modOriginalConfig(backgroundConfig);
      handleProccessImage(src, backgroundConfig);
    } else {
      modOriginalSrc(refTemp.current.tempSrc);
      modOriginalConfig(refTemp.current.tempConfig);
      handleProccessImage(
        refTemp.current.tempSrc,
        createBgConfig(refTemp.current.tempConfig, refTemp.current.tempCanvas)
      );
    }
  };

  const onOkUpload = () => {
    setIsCrop(true);
    setIsUpload(false);
    modOriginalSrc(previewImg);
  };

  const onCancelUpload = () => {
    toggleIsUpload();
    modOriginalSrc(src);
    handleProccessImage(src, backgroundConfig);
    resetVar();
    setSelectedFile(undefined);
  };

  const onFileChange = async (fileResult) => {
    if (fileResult?.file?.status.indexOf('uploading') >= 0) {
      setPreviewImg(await getBase64(fileResult?.file?.originFileObj));
      setSelectedFile(fileResult);
      resetVar();
    } else {
      setPreviewImg(undefined);
      setSelectedFile(undefined);
    }
  };

  const getRealTimeCropper = useCallback((data, cropData) => {
    setPreviewImg(data);
    modOriginalConfig(cropData);
  }, []);

  useEffect(() => {
    if (src && backgroundConfig) {
      handleProccessImage(src, backgroundConfig);
    }
  }, [src, JSON.stringify(backgroundConfig)]);

  useEffect(() => {
    if (!src) {
      setPreviewImg(undefined);
    }
  }, [src]);

  useEffect(() => {
    return () => {
      setPreviewImg(undefined);
    };
  }, []);

  useEffect(() => {
    forcePreviewImage && setPreviewImg(forcePreviewImage);
  }, [forcePreviewImage]);

  useEffect(() => {
    if (limitAdjustImage) {
      setIsAllowAdjust(false);
    }
  }, [limitAdjustImage]);

  if (instance) {
    instance.current = {
      handleSave,
      handleCancel,
    };
  }

  return (
    <React.Fragment>
      <div
        className={classnames('background-image-edit', {
          [OPEN_ITEM_CLASS]: isEdit,
        })}
        id={id}
        ref={refBackground}
        style={{
          backgroundImage: previewImg && `url(${previewImg})`,
        }}
      />

      {isEdit && !isUpload && (
        <Row className='background-image-edit__control'>
          <Col className='background-image-edit__control-radio-group'>
            <Radio.Group onChange={onHeightSizeChange} value={heightSize}>
              <Radio value={50} disabled={!isAllowAdjust}>
                50%
              </Radio>
              <Radio value={75} disabled={!isAllowAdjust}>
                75%
              </Radio>
              <Radio value={100} disabled={!isAllowAdjust}>
                100%
              </Radio>
            </Radio.Group>
          </Col>
          <Col>
            <Space>
              <Button
                type='primary'
                className='background-image-edit__control-btn'
                onClick={toggleIsUpload}
              >
                <UploadOutlined />
                New Image
              </Button>
              <Button
                className='background-image-edit__control-btn'
                type='primary'
                onClick={toggleIsCrop}
                disabled={!isAllowAdjust}
              >
                <VerticalAlignMiddleOutlined rotate={90} />
                Adjust Image
              </Button>
            </Space>
          </Col>
        </Row>
      )}

      <StyledModal
        title='Select Background Image'
        visible={isUpload}
        onOk={onOkUpload}
        onCancel={onCancelUpload}
        okText='Use Image'
        okButtonProps={{ disabled: !selectedFile ? true : false }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className='background-image-edit__upload'>
          <UploadFile
            manualUpload={true}
            showMultiple={false}
            getFileChange={onFileChange}
            onlyAcceptImage={true}
            supportTypes={['jpeg', 'png', 'jpg']}
            apiUrl={apiUploadUrl}
            showSubmitBtn={false}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
          />
        </div>
      </StyledModal>

      {isCrop && (
        <StyledModal
          title='Adjust Background Image'
          visible={isCrop}
          onOk={onOkCrop}
          onCancel={onCancelCrop}
          okText='Ok'
          cancelText={''}
          maskClosable={false}
        >
          <div className='background-image-edit__crop'>
            <CropImage
              refCropper={refCropper}
              imgSrc={procSrc}
              getRealTimeCropper={getRealTimeCropper}
              aspectRatio={
                refBackground.current.offsetWidth /
                refBackground.current.offsetHeight
              }
              zoomable={false}
              zoomOnWheel={true}
            />
          </div>
        </StyledModal>
      )}
    </React.Fragment>
  );
};

BackgroundImageEdit.propTypes = {
  //? id - id of edit backgourn component

  id: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([undefined])]),
  //? isEdit - is In Edit Mode
  isEdit: PropTypes.bool,
  //? apiUploadUrl: url to upload image file
  apiUploadUrl: PropTypes.string,
  //? src - original image src
  src: PropTypes.string,
  //? backgroundConfig - original background config
  backgroundConfig: PropTypes.object,
  //? getControllers - get control actions for header edit
  getControlers: PropTypes.func,
  //? additionalPayload - additional body data to upload background config
  additionalPayload: PropTypes.object,
  //? setIsLoading - set loading when saving
  setIsLoading: PropTypes.func,
  //? updateBackgroundCallback - fetch background data as needed
  updateBackgroundCallback: PropTypes.func,
};

export default React.memo(BackgroundImageEdit);
