import React from 'react';

import { useSelector } from 'react-redux';

import { Can } from 'context/Can';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import FormUploadBtn from './FormUploadBtn';

import ContainerButton from 'common/components/button/ContainerButton';

import ItemButton from 'common/components/button/ItemButton';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const FormUploadSection = (props) => {
  const selectedForm = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  return (
    <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL}>
      <ContainerButton>
        <FormUploadBtn />
      </ContainerButton>
    </Can>
  );
};

export default FormUploadSection;
