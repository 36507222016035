import { API_ENDPOINT } from 'config/env';
import {
  isLocalhost,
  isQaSite,
  isTestSite,
} from 'utils/common/checkEnviroment';

import * as globalActions from 'redux/global/actions';

const initAssetUrl = () => {
  function toUrl() {
    if (!this && typeof this !== 'string') return '';

    return process.env.NODE_ENV !== 'production' && this.startsWith('/')
      ? API_ENDPOINT.concat(this)
      : this;
  }
  // eslint-disable-next-line no-extend-native
  String.prototype.toUrl = toUrl;
};

const checkEnviroment = ({ dispatch }) => {
  if (isLocalhost()) {
    return dispatch(globalActions.updateEnvironment('local'));
  }

  if (isTestSite()) {
    return dispatch(globalActions.updateEnvironment('test-portal'));
  }

  if (isQaSite()) {
    return dispatch(globalActions.updateEnvironment('qa-portal'));
  }
};

const stopAppLoader = () => {
  const element = document.getElementById('preloader-container');
  element?.remove();
};

export const initApp = ({ dispatch }) => {
  stopAppLoader();
  initAssetUrl();
  checkEnviroment({ dispatch });
};
