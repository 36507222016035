import React from 'react';
import { useHistory } from 'react-router-dom';

import { ExperimentOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import useToggleActiveRibbon from './hook';

import { useSsoProductParams } from 'hooks/useSso';

import Messages from 'i18n/messages/home';

import { useRefetchQaSpecData } from 'hooks/qa-spec/useRefetchQaSpecData';

const DetailQaSpec = ({ productData }) => {
  const history = useHistory();

  const { ssoParamUrl: ssoProductParams, productId } = useSsoProductParams();

  const checkActiveContent = () => {
    const path = history?.location?.pathname;
    const regex = /\/product\/qa-specification\/\d+$/;
    return regex.test(path);
  };

  const isPathActive = checkActiveContent();
  const isActive = useToggleActiveRibbon(isPathActive);

  const refetchQaSpecData = useRefetchQaSpecData();

  const handleClick = () => {
    if (productData?.productId !== undefined) {
      if (isActive) {
        if (ssoProductParams) {
          history.push(`/product/${productId}/?${ssoProductParams}`);
        } else {
          history.push(`/product/${productData?.productId}`);
        }
      } else {
        refetchQaSpecData();
        if (ssoProductParams) {
          history.push(
            `/product/qa-specification/${productId}?${ssoProductParams}`
          );
        } else {
          history.push(`/product/qa-specification/${productData?.productId}`);
        }
      }
    }
  };

  return (
    <RibbonButton
      icon={<ExperimentOutlined />}
      label={Messages.detailQaSpec}
      onClick={handleClick}
      disabled={!productData?.productId}
      toggle={isPathActive}
    />
  );
};
export default DetailQaSpec;
