import React, { useState, useEffect } from 'react';

import { Row, Col, InputNumber, Tooltip, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import ProductBrickCodeModal from './ProductBrickCodeModal';

const BrickCodeInput = (props) => {
  const { initialValue, updateBrickCodeData } = props;

  const [brick, setBrick] = useState();
  const [brickModalVisible, setBrickModalVisible] = useState(false);

  const handleBrickCode = (brick) => {
    const brickCodeData = {
      brickCode: brick?.BrickCode,
      brickCodeName: brick?.BrickDescription,
    };

    setBrick(brickCodeData);
    updateBrickCodeData && updateBrickCodeData(brickCodeData);

    setBrickModalVisible(false);
  };

  const onDeleteBrickCode = () => {
    setBrick({});
    updateBrickCodeData && updateBrickCodeData({});
  };

  const initValue = () => {
    setBrick(initialValue);
  };

  useEffect(() => {
    initValue();
  }, [JSON.stringify(initialValue)]);

  return (
    <Row gutter={4} align='middle'>
      <Col flex={1}>
        <InputNumber
          style={{ width: '100%' }}
          value={brick?.brickCode}
          disabled
        />
      </Col>

      <Col flex={0}>
        <Tooltip title='Choose an Brick Code'>
          <Button
            style={{ height: '28px' }}
            onClick={() => setBrickModalVisible(true)}
          >
            Select Brick
          </Button>
        </Tooltip>
        {brickModalVisible && (
          <ProductBrickCodeModal
            visible={brickModalVisible}
            handleCancel={() => setBrickModalVisible(false)}
            handleBrickCode={handleBrickCode}
            initialBrickCode={brick?.brickCode}
          />
        )}
      </Col>
      {brick?.brickCode ? (
        <Col flex={0}>
          <CloseCircleOutlined
            onClick={onDeleteBrickCode}
            style={{
              color: '#f5222d',
              fontSize: 20,
              paddingTop: 3,
            }}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default BrickCodeInput;
