import React from 'react';
import { Button } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/common';

const FormCancelButton = (props) => {
  const { text, icon, ...restProps } = props;
  return (
    <Button
      type='default'
      style={{ borderRadius: '4px' }}
      icon={icon || <StopOutlined />}
      {...restProps}
    >
      <span>{text || <FormattedMessage {...Messages.cancelBtn} />}</span>
    </Button>
  );
};

export default FormCancelButton;
