import React, { useEffect } from 'react';
import { Input, Checkbox, Select } from 'antd';
// component
import {
  Form,
  StyledModal,
  WrapperSelect,
  CustomNotification,
  SectionWrapper,
  dialogFunction,
} from 'common/components';
// hooks-query
import { useSaveTicketGroup } from 'pages/ticketing-group/hooks-query/useTicketingGroup';
// hooks
import { useCheckIsSuperAdmin } from 'hooks';
// redux
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'pages/ticketing-group/controllers/actions';
import * as selectors from 'pages/ticketing-group/controllers/selectors';
// constants
import {
  TICKET_STATUS,
  TICKET_SEVERITY,
  TICKET_PRIORITY,
} from 'static/Constants';

const SaveTicketingGroupModal = (props) => {
  const { isEdit, isModalOpen, setIsModalOpen } = props;
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  const saveTicketGroup = useSaveTicketGroup();

  const selectedGroup = useSelector(selectors.makeSelectSelectedGroup());
  const memberId = useSelector(selectors.makeSelectMemberId());
  const ticketGroupList = useSelector(selectors.makeSelectTicketGroupList());

  const isSuperAdmin = useCheckIsSuperAdmin();

  useEffect(() => {
    if (isEdit) {
      formInstance.setFieldsValue(selectedGroup);
    } else {
      formInstance.setFieldsValue({
        ticketPriority: 3,
      });
    }
  }, [isEdit, selectedGroup]);

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onOk = async () => {
    await formInstance.validateFields().then(async (value) => {
      let isSameTrigger = false;
      if (ticketGroupList && ticketGroupList?.length > 0) {
        if (isEdit) {
          isSameTrigger =
            ticketGroupList
              .filter((val) => val.id !== selectedGroup?.id)
              .findIndex(
                (val) =>
                  val?.ticketStatus === value?.ticketStatus &&
                  val?.ticketSeverity === value?.ticketSeverity &&
                  val?.ticketPriority === value?.ticketPriority
              ) > -1;
        } else {
          isSameTrigger =
            ticketGroupList.findIndex(
              (val) =>
                val?.ticketStatus === value?.ticketStatus &&
                val?.ticketSeverity === value?.ticketSeverity &&
                val?.ticketPriority === value?.ticketPriority
            ) > -1;
        }
        if (isSameTrigger) {
          dialogFunction({
            type: 'warn',
            content: `Kindly notice that creating groups with same triggered conditions, system will auto-assign tickets to the first group found.`,
            onOk: () => {
              handleSaveTicketGroup(value);
            },
          });
        } else {
          handleSaveTicketGroup(value);
        }
      } else {
        handleSaveTicketGroup(value);
      }
    });
  };

  const handleSaveTicketGroup = (value) => {
    let params = Object.assign(value, {
      memberId: memberId,
    });

    if (isEdit) {
      params.id = selectedGroup?.id;
      if (!isSuperAdmin) {
        params.isGlobalGroup = selectedGroup?.isGlobalGroup;
      }
    }
    saveTicketGroup.mutate(params, {
      onSuccess: (result) => {
        if (result?.isSuccess) {
          if (isEdit) {
            CustomNotification.success('Update Ticket Group successfully.');
            dispatch(actions.selectedGroup(value));
          } else
            CustomNotification.success('Create Ticket Group successfully.');
          formInstance.resetFields();
          setIsModalOpen(false);
        } else {
          CustomNotification.error(result?.message || 'Something went wrong!');
        }
      },
      onError: (error) => {
        CustomNotification.error('Something went wrong!');
      },
    });
  };

  return (
    <StyledModal
      visible={isModalOpen}
      title={`${isEdit ? 'Update' : 'Create'} Ticket Group`}
      maskClosable={false}
      onCancel={onCancel}
      onOk={onOk}
      okText={isEdit ? 'Update' : 'Create'}
      okButtonProps={{ loading: saveTicketGroup.isLoading }}
    >
      <Form form={formInstance}>
        <Form.Item
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          label={`Ticket group name`}
          name='name'
          rules={[
            {
              required: true,
              message: 'Please input ticket group name',
            },
          ]}
        >
          <Input placeholder='Please input ticket group name' />
        </Form.Item>
        {isSuperAdmin && (
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label={`Global Group`}
            name='isGlobalGroup'
            valuePropName='checked'
          >
            <Checkbox />
          </Form.Item>
        )}
        <SectionWrapper title={'Triggered Condition'}>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label='Ticket Status'
            name='ticketStatus'
          >
            <WrapperSelect>
              {TICKET_STATUS.sort().map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label='Ticket Severity'
            name='ticketSeverity'
          >
            <WrapperSelect>
              {TICKET_SEVERITY.map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label='Ticket Priority'
            name='ticketPriority'
          >
            <WrapperSelect>
              {TICKET_PRIORITY.sort().map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
        </SectionWrapper>
      </Form>
    </StyledModal>
  );
};

export default SaveTicketingGroupModal;
