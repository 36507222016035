import React, { useState, useEffect } from 'react';
import {
  Modal,
  Input,
  Checkbox,
  Row,
  List,
  Col,
  Avatar,
  Spin,
  Button,
  notification,
  Empty,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'common/components';
import GroupPeopleCreate from './GroupPeopleCreate';
import GroupCompanyCreate from './GroupCompanyCreate';
import GroupPeopleDelete from './GroupPeopleDelete';
import GroupCompanyDelete from './GroupCompanyDelete';
import { Typography } from 'antd';
import * as api from 'config/axios';
import { UserOutlined } from '@ant-design/icons';
import messages from 'i18n/messages/home';
import userSelectors from 'redux/user/selectors';
import * as chatSelectors from '../../controller/selectors';
import * as chatActions from '../../controller/actions';
import * as services from 'services/chatServices';
import { CHATTYPE } from 'static/Constants';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import * as videoChatServices from 'services/videoCallServices';

import './AddRemoveThreadModal.less';

const CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT = [
  {
    action: ABILITY_ACTION.CHAT,
    subject: ABILITY_SUBJECT.CHAT_COMPANY,
  },
];
const CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT = [
  {
    action: ABILITY_ACTION.CHAT,
    subject: ABILITY_SUBJECT.CHAT_PERSONAL,
  },
];

export const AddRemoveThreadModal = (props) => {
  const {
    visible,
    closeNewChat,
    userListAttend,
    companyListAttend,
    videoCall,
    groupUsers,
    info,
  } = props;
  const dispatch = useDispatch();
  const { Title } = Typography;
  const [type, setType] = useState(false);
  const [userType, setUserType] = useState('user');
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [userInitialList, setUserInitialList] = useState([]);
  const [companyInitialList, setCompanyInitialList] = useState([]);
  const [form] = Form.useForm();
  const [userDisplay, setUserDisplay] = useState(
    userListAttend ? userListAttend : []
  );
  const hasPersonalChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT
  );
  const hasCompanyChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT
  );

  const [state, setState] = useState('Remove');
  const [user, setUser] = useState('Personal');
  const [companyDisplay, setCompanyDisplay] = useState(
    companyListAttend ? companyListAttend : []
  );
  const threadMember = useSelector(chatSelectors.selectThreadInfo())?.users;

  const threadId = useSelector(chatSelectors.selectThreadInfo())?.id;
  const memberID = useSelector(userSelectors.makeSelectUserMemberId());
  const userID = useSelector(userSelectors.makeSelectUserId());
  const currentActiveThread = useSelector(
    chatSelectors.selectActiveThreadInfo()
  );

  const [fetchInitial, setFetchInitial] = useState(false);
  const threadMemberDisplay =
    threadMember &&
    threadMember
      .filter(
        (item) =>
          item.userId !== `U-${userID}` && item.userId !== `C-${memberID}`
      )
      .map((item) => item.userId);

  const checkIsCreatorRole = (groupUsers, userId, memberId) => {
    if (info?.type !== 'ChatGroup') {
      return false;
    }
    if (groupUsers?.length > 0) {
      if (userId && hasPersonalChatPermission) {
        const accountUser = groupUsers.find(
          (user) => user.userId === `U-${userId}`
        );
        if (accountUser?.role === 'Creator') {
          return true;
        }
      }
      if (memberId && hasCompanyChatPermission) {
        const accountCompany = groupUsers.find(
          (user) => user.userId === `C-${memberId}`
        );
        if (accountCompany?.role === 'Creator') {
          return true;
        }
      }
      return false;
    }
  };

  const isCreatorRole = checkIsCreatorRole(groupUsers, userID, memberID);

  const fetchMemberInfo = async () => {
    if (
      threadMemberDisplay &&
      threadMemberDisplay.length > 0 &&
      !fetchInitial
    ) {
      const { data } = await services.getChatUsersInfo(threadMemberDisplay);
      setFetchInitial(true);
      const { senders } = data;
      const sendersCompany = senders
        .filter((item) => item.senderType === CHATTYPE.COMPANY)
        .map((item) => {
          return {
            companyId: item.senderId,
            companyName: item.name,
            logo: item.avatar,
          };
        });
      const senderUser = senders
        .filter((item) => item.senderType === CHATTYPE.USER)
        .map((item) => {
          return {
            avatar: item.avatar,
            companyName: '',
            fullName: item.name,
            gender: '',
            userId: item.senderId,
          };
        });
      setCompanyInitialList(sendersCompany);
      setUserInitialList(senderUser);
    }
  };

  useEffect(() => {
    if (!fetchInitial && visible) fetchMemberInfo();
    if (!visible) setFetchInitial(false);
  }, [threadMemberDisplay, visible]);

  async function handleSearchUser(params) {
    setLoading(true);
    await api
      .sendGet(`/api/chat/search-users-for-chat?SearchText=${params}`)
      .then((response) => {
        if (response?.isSuccess) {
          setUserList(response?.data?.usersForChat);
          setUserType('user');
        }
      });
    setLoading(false);
  }

  async function removeUser(params) {
    setLoading(true);
    const submitParams = {
      threadId: threadId,
      userId: params,
    };
    await api
      .sendPost(`/api/chat/remove-user-from-group`, submitParams)
      .then((response) => {
        if (response?.isSuccess) {
          notification.success({ message: 'Remove User Success' });
          const removedUserList = userInitialList.filter(
            (item) => item.userId !== params
          );
          const removedCompanyList = companyInitialList.filter(
            (item) => item.companyId !== params
          );
          setUserInitialList(removedUserList);
          setCompanyInitialList(removedCompanyList);
          dispatch(
            chatActions.getThreadInfo(
              currentActiveThread?.threadId,
              currentActiveThread?.userId
            )
          );
        }
      });
    setLoading(false);
  }

  async function handleSearchCompany(params, loadingFunction, setFunction) {
    setLoading(true);
    await api
      .sendGet(`/api/chat/search-companies-for-chat?SearchText=${params}`)
      .then((response) => {
        if (response?.isSuccess) {
          setCompanyList(response?.data?.companiesForChat);
          setUserType('company');
        }
      });
    setLoading(false);
  }

  const submitCreateThread = async (params) => {
    if (state === 'Add') {
      const userSubmit = userDisplay.map((item) => item.userId);
      const companySubmit = companyDisplay.map((item) => item.companyId);
      const paramsSubmit = {
        threadId: threadId,
        users: userSubmit,
        companies: companySubmit,
      };
      setLoading(true);
      await api
        .sendPost('/api/chat/add-users-to-group', paramsSubmit)
        .then((response) => {
          if (response?.isSuccess) {
            dispatch(
              chatActions.getThreadInfo(
                currentActiveThread?.threadId,
                currentActiveThread?.userId
              )
            );
            closeChat();

            const shouldInviteNewUser =
              videoCall?.isVideoCallOpen &&
              videoCall?.currentCall?.roomId === currentActiveThread?.threadId;

            if (shouldInviteNewUser) {
              const invitedUsers = onConvertToUserIds(
                userSubmit,
                companySubmit
              );

              onInviteNewUsersToVideoCall(
                currentActiveThread?.threadId,
                invitedUsers
              );
            }
          }
        })
        .catch((err) => {
          console.log('err: ', err);
          setLoading(false);
        });
      setLoading(false);
    } else {
      closeChat();
    }
  };

  const onConvertToUserIds = (userList, companyList) => {
    const userIdList = userList?.map((user) => `U-${user}`);
    const companyIdList = companyList?.map((comId) => `C-${comId}`);
    return [...userIdList, ...companyIdList];
  };

  const onInviteNewUsersToVideoCall = async (threadId, invitedUsers) => {
    try {
      const params = { threadId, invitedUsers };
      await videoChatServices.inviteCall(params);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const closeChat = () => {
    form.resetFields();
    setState('Remove');
    setUserList([]);
    setCompanyList([]);
    setUserDisplay([]);
    setCompanyDisplay([]);
    setType(false);
    setUserType('user');
    closeNewChat();
    setUserInitialList([]);
    setCompanyInitialList([]);
  };

  const changeItem = (user) => {
    if (userDisplay?.filter((item) => item.userId === user.userId).length > 0)
      setUserDisplay(
        userDisplay?.filter((item) => item.userId !== user.userId)
      );
    else setUserDisplay(userDisplay.concat([user]));
  };
  const removeItem = (userId) => {
    setUserDisplay(userDisplay?.filter((item) => item.userId !== userId));
  };

  const changeCompanyItem = (company) => {
    if (
      companyDisplay?.filter((item) => item.companyId === company.companyId)
        .length > 0
    )
      setCompanyDisplay(
        companyDisplay?.filter((item) => item.companyId !== company.companyId)
      );
    else setCompanyDisplay(companyDisplay.concat([company]));
  };
  const removeCompanyItem = (id) => {
    setCompanyDisplay(companyDisplay?.filter((item) => item.companyId !== id));
  };
  return (
    <Spin spinning={loading}>
      <Modal
        title={<FormattedMessage {...messages.settingThread} />}
        visible={visible}
        destroyOnClose={true}
        onCancel={() => closeChat()}
        className={'new-thread-modal'}
        width='870px'
        centered
        onOk={() => submitCreateThread()}
      >
        {state === 'Add' ? (
          <Form
            form={form}
            name='shared'
            // onFinish={(values) => submitCreateThread(values)}
          >
            <Row justify='space-between'>
              <Form.Item
                name='findContact'
                label={
                  <FormattedMessage {...messages.findContactAddToThread} />
                }
              >
                <Checkbox
                  onChange={(values) => {
                    setType(values?.target?.checked);
                    form.resetFields(['search']);
                    setCompanyList([]);
                    setUserList([]);
                  }}
                >
                  <FormattedMessage {...messages.findMember} />
                </Checkbox>
              </Form.Item>
              <Button type='primary' onClick={() => setState('Remove')}>
                Thread members
              </Button>
            </Row>

            <Form.Item name='search'>
              <Row style={{ marginBottom: 5 }}>
                <Input.Search
                  allowClear
                  placeholder={'Search users'}
                  style={{ width: '40%' }}
                  onSearch={(item) => {
                    !type ? handleSearchUser(item) : handleSearchCompany(item);
                  }}
                />
              </Row>
            </Form.Item>

            {userDisplay && userDisplay.length > 0 && (
              <>
                <Row>
                  <Title level={5}>User list:</Title>
                </Row>
                <Row>
                  <GroupPeopleCreate
                    listPeople={userDisplay}
                    removeItem={(item) => removeItem(item)}
                  />
                </Row>
              </>
            )}
            {companyDisplay && companyDisplay.length > 0 && (
              <>
                <Row>
                  <Title level={5}>Company list:</Title>
                </Row>
                <Row>
                  <GroupCompanyCreate
                    listPeople={companyDisplay}
                    removeItem={(item) => removeCompanyItem(item)}
                  />
                </Row>
              </>
            )}
            {(userList?.length > 0 || companyList?.length > 0) && (
              <Title level={4}>Search result</Title>
            )}
            {userType === 'user' ? (
              <>
                <Spin spinning={loading}>
                  <List
                    className='new-thread-modal__user-list scroller'
                    itemLayout='horizontal'
                    dataSource={userList}
                    renderItem={(item) => (
                      <List.Item>
                        <Row style={{ width: '100%' }}>
                          <Col
                            span={1}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              onChange={() => {
                                changeItem(item);
                              }}
                              checked={
                                userDisplay?.filter(
                                  (user) => user.userId === item.userId
                                ).length > 0
                              }
                            />
                          </Col>
                          <Col span={2}>
                            <Avatar
                              src={item?.avatar && item?.avatar}
                              icon={<UserOutlined />}
                              size={50}
                            />
                          </Col>
                          <Col span={21}>
                            <Row justify='space-between'>
                              <Col>
                                <Row>
                                  <Title level={5}>{item.fullName}</Title>
                                </Row>
                                <Row>{item.companyName}</Row>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Row>{item.gender}</Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  ></List>
                </Spin>
                <Row justify='end'> {userList.length} contacts</Row>
              </>
            ) : (
              <>
                <Spin spinning={loading}>
                  <List
                    className='new-thread-modal__user-list scroller'
                    itemLayout='horizontal'
                    dataSource={companyList}
                    renderItem={(item) => (
                      <List.Item>
                        <Row style={{ width: '100%' }}>
                          <Col
                            span={1}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              onChange={() => {
                                changeCompanyItem(item);
                              }}
                              checked={
                                companyDisplay?.filter(
                                  (company) =>
                                    company.companyId === item.companyId
                                ).length > 0
                              }
                            />
                          </Col>
                          <Col span={2}>
                            <Avatar
                              src={item?.logo}
                              icon={<UserOutlined />}
                              size={50}
                            />
                          </Col>
                          <Col span={21}>
                            <Row justify='space-between'>
                              <Col>
                                <Row>
                                  <Title level={5}>{item.companyName}</Title>
                                </Row>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Row>{item.gender}</Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  ></List>
                </Spin>
                <Row justify='end'> {companyList.length} contacts</Row>
              </>
            )}
          </Form>
        ) : (
          <>
            <Row justify='space-between'>
              <Title level={2}>Contacts</Title>
              <Button type='primary' onClick={() => setState('Add')}>
                Add user to thread
              </Button>
            </Row>
            {userInitialList?.length === 0 &&
              companyInitialList?.length === 0 && (
                <Empty description='No contacts in chat group' />
              )}
            {userInitialList && userInitialList.length > 0 && (
              <>
                <Row>
                  <Title level={5}>User list:</Title>
                </Row>
                <Row>
                  <GroupPeopleDelete
                    listPeople={userInitialList}
                    removeItem={(item) => removeUser(item)}
                    isCreatorRole={isCreatorRole}
                  />
                </Row>
              </>
            )}
            {companyInitialList && companyInitialList.length > 0 && (
              <>
                <Row>
                  <Title level={5}>Company list:</Title>
                </Row>
                <Row>
                  <GroupCompanyDelete
                    listPeople={companyInitialList}
                    removeItem={(item) => removeUser(item)}
                    isCreatorRole={isCreatorRole}
                  />
                </Row>
              </>
            )}
          </>
        )}
      </Modal>
    </Spin>
  );
};

export default AddRemoveThreadModal;
