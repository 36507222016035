import * as endpoints from './endpoints';
import * as api from 'config/axios';

export const getTransitionPageShortDetail = (params) => {
  return api.sendGet(endpoints.GET_TRANSITION_PAGE_SHORT_DETAIL, { params });
};

export const getTransitionPageFullDetail = (params) => {
  return api.sendGet(endpoints.GET_TRANSITION_PAGE_FULL_DETAIL, { params });
};

export const saveTransitionPage = (params) => {
  return api.sendPost(endpoints.SAVE_TRANSITION_PAGE, params);
};
