const pathnameMember = '/members';
const pathnameDigitalMedia = '/digital-media';
const pathnameDigitalMediaNew = '/digital-media/new';
const pathnameAsset = '/digital-media/assets';
const pathnameDocument = '/digital-media/documents';
const pathnameMultiMedia = '/digital-media/multi-media';
const pathnameFolders = '/folders';
const pathnameOwnerFolder = '/folders/owned';
const pathnameSharedFolder = '/folders/shared';
const pathnameProductsForMember = '/member-products';
const pathnameAssetsForMember = '/assets/member';
const pathnameReporting = '/reportings';
const pathnameOwnerReporting = '/reportings/owned';
const pathnameSharedReporting = '/reportings/shared';
const pathnameFavoriteAssets = '/favorite/favorite-assets';
const pathnameFavoriteMembers = '/favorite/favorite-members';
const pathnameFavoriteFolders = '/favorite/favorite-folders';
const pathnameProduct = '/products';
const pathnameFavoriteProduct = '/favorite/favorite-products';

const nameGrid = {
  [pathnameMember]: 'member-detail-grid',
  [pathnameFavoriteMembers]: 'member-detail-grid',

  [pathnameDigitalMedia]: 'digital-asset-detail-grid',
  [pathnameDigitalMediaNew]: 'digital-asset-detail-grid',
  [pathnameAsset]: 'digital-asset-detail-grid',
  [pathnameDocument]: 'digital-asset-detail-grid',
  [pathnameMultiMedia]: 'digital-asset-detail-grid',
  [pathnameFavoriteAssets]: 'digital-asset-detail-grid',
  [pathnameAssetsForMember]: 'digital-asset-detail-grid',

  [pathnameFolders]: 'folder-detail-grid',
  [pathnameOwnerFolder]: 'folder-detail-grid',
  [pathnameSharedFolder]: 'folder-detail-grid',
  [pathnameFavoriteFolders]: 'folder-detail-grid',

  [pathnameReporting]: 'query-reporting-grid',
  [pathnameOwnerReporting]: 'query-reporting-grid',
  [pathnameSharedReporting]: 'query-reporting-grid',

  [pathnameProduct]: 'product-detail-grid',
  [pathnameFavoriteProduct]: 'product-detail-grid',
  [pathnameProductsForMember]: 'product-detail-grid',
};

const getGridName = (pathname) => {
  if (checkIsProdForMem(pathname)) return nameGrid[pathnameProductsForMember];

  if (checkIsAssetsForMem(pathname)) return nameGrid[pathnameAssetsForMember];

  return nameGrid[pathname];
};

const checkIsProdForMem = (pathname) =>
  pathname.includes(pathnameProductsForMember);

const checkIsAssetsForMem = (pathname) =>
  pathname.includes(pathnameAssetsForMember);

export { getGridName };
