/*
 * Product branded Messages
 *
 * This contains all the text for the branded product component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.brandedProducts';

export default defineMessages({
  lastUpdated: {
    id: `${scope}.components.items.card.lastUpdated`,
    defaultMessage: 'Last Updated:',
  },
  imageDate: {
    id: `${scope}.components.items.card.imageDate`,
    defaultMessage: 'Image Date:',
  },
  ingredientLabel: {
    id: `${scope}.components.detail.productIngredient.ingredientLabel`,
    defaultMessage: 'Ingredients',
  },
  allergensLabel: {
    id: `${scope}.components.detail.productIngredient.allergensLabel`,
    defaultMessage: 'Allergens',
  },
  certificationsLabel: {
    id: `${scope}.components.detail.productIngredient.certificationsLabel`,
    defaultMessage: 'Certifications',
  },
  config: {
    id: `${scope}.components.detail.packageLevel.config`,
    defaultMessage: 'Config',
  },
  gtin: {
    id: `${scope}.components.detail.packageLevel.gtin`,
    defaultMessage: 'GTIN',
  },
  upc12: {
    id: `${scope}.components.detail.packageLevel.upc12`,
    defaultMessage: 'UPC12',
  },
  height: {
    id: `${scope}.components.detail.packageLevel.height`,
    defaultMessage: 'Height',
  },
  width: {
    id: `${scope}.components.detail.packageLevel.width`,
    defaultMessage: 'Width',
  },
  depth: {
    id: `${scope}.components.detail.packageLevel.depth`,
    defaultMessage: 'Depth',
  },
  weight: {
    id: `${scope}.components.detail.packageLevel.weight`,
    defaultMessage: 'Weight',
  },
  cube: {
    id: `${scope}.components.detail.packageLevel.cube`,
    defaultMessage: 'Cube',
  },
  pack: {
    id: `${scope}.components.detail.packageLevel.pack`,
    defaultMessage: 'Pack',
  },
  savePublicSuccessMessage: {
    id: `${scope}.components.detail.publicValidateError.successMessage`,
    defaultMessage: 'Submit successfully',
  },
  savePublicErrorMessage: {
    id: `${scope}.components.detail.publicValidateError.errorMessage`,
    defaultMessage: 'Failed to submit',
  },
  GDSNModalTitle: {
    id: `${scope}.components.gdsn.modal.title`,
    defaultMessage: 'GDSN Received Product Preview',
  },
  showJsonButtonText: {
    id: `${scope}.components.gdsn.showButton.text`,
    defaultMessage: 'Show Full Data',
  },
  downloadJsonButtonText: {
    id: `${scope}.components.gdsn.downloadButton.text`,
    defaultMessage: 'Download JSON Data',
  },
  cicReasonTitle: {
    id: `${scope}.components.gdsn.errorList.cicReason`,
    defaultMessage: 'CIC Reasons',
  },
  validationFail: {
    id: `${scope}.components.gdsn.errorList.validationFail`,
    defaultMessage: 'Validation Fail',
  },
});
