import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { useSelector } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { CustomNotification } from 'common/components';

import { downloadAplProductList } from 'services/product';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import * as api from 'config/axios';

import Messages from 'i18n/messages/home';

const DownloadAPL = (props) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  let selectedItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const handleDownloadAPL = async () => {
    try {
      setLoading(true);
      const { isSuccess, data } = await downloadAplProductList({
        AplId: selectedItems[0].id,
      });
      if (isSuccess) {
        api.sendDownload({ url: data?.url });
        CustomNotification.success(
          intl.formatMessage(Messages.downloadSuccessMessage)
        );
      } else {
        CustomNotification.error(
          intl.formatMessage(Messages.downloadFailMessage)
        );
      }
    } catch (error) {
      CustomNotification.error(
        intl.formatMessage(Messages.downloadFailMessage)
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <RibbonButton
      icon={<DownloadOutlined style={{ height: 26 }} />}
      disabled={loading || selectedItems?.length !== 1}
      loading={loading}
      label={Messages.downloadAPLTitle}
      onClick={handleDownloadAPL}
    />
  );
};

export default DownloadAPL;
