import React, { useState } from 'react';
import { FileAddOutlined } from '@ant-design/icons';

import { ButtonSmallIcon } from 'common/components';
import CreateProductViaFormModal from 'common/components/product-add/CreateProductViaFormModal';

const AddProductFromMember = (props) => {
  const [addModalVisible, setAddModalVisible] = useState(false);

  return (
    <>
      {addModalVisible && (
        <CreateProductViaFormModal
          addModalVisible={addModalVisible}
          setAddModalVisible={setAddModalVisible}
          memberId={props.memberId}
        />
      )}

      <ButtonSmallIcon
        className='button-item-dropdown'
        icon={<FileAddOutlined />}
        label={'Create Product'}
        onClick={() => setAddModalVisible(true)}
        disabled={props.disabled}
      />
    </>
  );
};

AddProductFromMember.propTypes = {};

export default AddProductFromMember;
