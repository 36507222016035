import _ from 'lodash';

import {
  ADVANCE_MODULE,
  BASE_MODULE,
  BASIC_FIELD,
  IX_ONE_FIELD,
  TDC_PROPERTIES,
  PRODUCT_SPEC,
  LABELING,
  CLAIMS,
  PET_FOOD,
  FRESH_SEAFOOD,
  FACT_PANEL,
} from './IXONEConstants';

import { checkSpecialField } from 'pages/product-full-view/components/product-new-detail-view/utils';

export const checkNestedFieldsChange = ({ originalData, changedFields }) => {
  const TDC_FIELD = {
    ...TDC_PROPERTIES,
    ...BASE_MODULE,
    ...ADVANCE_MODULE,
    ...BASIC_FIELD,
  };

  for (const [, field] of Object.entries(changedFields)) {
    if (!TDC_FIELD?.[field?.fieldFullPath?.toLowerCase()]) continue;

    const originalValue = _.get(originalData, field?.key, null);
    const currentValue = field?.value ?? null;

    if (
      JSON.stringify(originalValue) !== JSON.stringify(currentValue) &&
      !IX_ONE_FIELD?.includes(field?.fieldFullPath?.toLowerCase())
    )
      return true;
  }

  return false;
};

export const checkFieldsChange = ({ changedFields, originalData }) => {
  const TDC_FIELD = {
    ...TDC_PROPERTIES,
    ...BASE_MODULE,
    ...ADVANCE_MODULE,
    ...BASIC_FIELD,
    ...PRODUCT_SPEC,
    ...LABELING,
    ...CLAIMS,
    ...PET_FOOD,
    ...FRESH_SEAFOOD,
  };

  const keysData = Object.keys(changedFields)?.filter(
    (key) => !IX_ONE_FIELD.includes(key?.toLowerCase())
  );

  return keysData.some((field) => {
    const originalValue = originalData?.[field] ?? null;
    const currentValue = changedFields?.[field] ?? null;
    return (
      JSON.stringify(originalValue) !== JSON.stringify(currentValue) &&
      Boolean(TDC_FIELD[field?.toLowerCase()])
    );
  });
};

export const checkFieldsChangeBulkEdit = (changedModule, originalData) => {
  const TDC_FIELD = {
    ...TDC_PROPERTIES,
    ...BASE_MODULE,
    ...ADVANCE_MODULE,
    ...BASIC_FIELD,
    ...PRODUCT_SPEC,
    ...LABELING,
    ...CLAIMS,
    ...PET_FOOD,
    ...FACT_PANEL,
  };

  let changedFields = [];
  changedModule?.forEach((module) => {
    changedFields = [...changedFields, ...module?.columns];
  });

  const removedIgnoreField = changedFields?.filter((item) => {
    return (
      !IX_ONE_FIELD?.includes(item?.toLowerCase()) && !checkSpecialField(item)
    );
  });

  const hasTdcField = removedIgnoreField.some((field) =>
    Boolean(TDC_FIELD[field?.toLowerCase()])
  );

  return hasTdcField && originalData?.length > 0;
};
