export const FOLDER_LIST_ENDPOINT = '/api/folder/folder-detail-grid';
export const FAVORITE_FOLDER_LIST_ENDPOINT =
  '/api/favorites/favorite-folders-grid-view';
export const FOLDER_DETAIL_GRID = '/api/folder/folder-detail-grid';
export const FOLDER_DETAIL_GRID_DISTINCT =
  '/api/folder/folder-detail-grid/distinct';
export const FAVORITE_FOLDER_DETAIL_GRID =
  '/api/favorites/get-favorite-folders-detail-grid';
export const FAVORITE_FOLDER_DETAIL_GRID_DISTINCT =
  '/api/favorites/get-favorite-folders-detail-grid/distinct';

export const CREATE_FOLDER = '/api/folder/create-new-folder';
export const DELETE_FOLDERS = '/api/folder/delete-folders';
export const COPY_FOLDER = '/api/folder/copy-folder';
export const MOVE_FOLDER = '/api/folder/move-folder';

export const FOLDER_GRID_VIEW = '/api/folder/folder-detail-grid';
export const ADD_ENTITY_TO_FOLDER = '/api/folder/add-entity-to-folder';
export const FIND_IN_ALL_FOLDERS = '/api/folder/find-entity-in-all-folders';
export const DELETE_IN_ALL_FOLDERS = '/api/folder/delete-entity-in-all-folders';
export const FOLDER_SHORT_DETAIL = '/api/folder/short-content-pane';
export const MEMBER_PREVIEW = '/api/MemberProfile/member-short-detail';
export const ASSET_PREVIEW = '/api/dam/asset-short-detail-view';

export const GET_DOWNLOAD_SETTING = '/api/folder/latest-download-setting';
export const FOLDER_DOWNLOAD = '/api/folder/folder-download';
export const DELETE_ENTITY_ASSOCIATION_FROM_SHORT_CONTENT =
  '/api/folder/delete-entity-association-from-short-content';

export const EDIT_FOLDER_SHORT_CONTENT = '/api/folder/edit-short-content';

export const FOLDER_OWN_BY_USER = '/api/folder/folder-own-by-user';
export const CONFIG_SCHEDULE_DOWNLOAD = '/api/folder/config-schedule-download';

export const FOLDER_HEADER_DETAIL = '/api/folder/folder-header-detail-grid';
export const FOLDER_DETAIL_GRID_THUMB = '/api/folder/folder-detail-grid';
export const FOLDER_DETAIL_GRID_THUMB_DISTINCT =
  '/api/folder/folder-detail-grid-thumb/distinct';
export const QUERY_PREVIEW = '/api/query/query-detail-pane';
export const REPORTING_PREVIEW = '/api/reporting/query-reporting-detail-pane';

export const PRODUCT_PREVIEW = '/api/pim/get-product-short-detail';
