import produce from 'immer';

import customCellComponents from 'pages/branded-products/components/custom-cell-components/customCellComponents';

export const findIndexProperty = (
  properties,
  moduleName,
  propertyName,
  isCustomField
) => {
  if (isCustomField === 'true') {
    return properties.findIndex(
      (property) =>
        property.moduleName === null && property.fieldName === propertyName
    );
  }

  if (!moduleName) {
    return properties.findIndex(
      (property) =>
        property.moduleName === null && property.fieldName === propertyName
    );
  }

  return properties.findIndex(
    (property) =>
      property.moduleName === moduleName && property.fieldName === propertyName
  );
};

export const getNewPropertiesAfterToggling = (
  properties,
  moduleName,
  toggledProperty,
  valueToggle
) => {
  const index = findIndexProperty(properties, moduleName, toggledProperty);

  const newAttachList = produce(properties, (draftFile) => {
    draftFile[index].isShow = valueToggle;
  });

  return newAttachList;
};

export const getNewPropertiesAfterResizing = (
  properties,
  moduleName,
  resizedProperty,
  width
) => {
  const index = findIndexProperty(properties, moduleName, resizedProperty);

  const newAttachList = produce(properties, (draftFile) => {
    draftFile[index].width = width;
  });

  return newAttachList;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const defaultWidthCol = 50;

export const getSelectedProperty = (
  modules = [],
  fieldFullPath,
  selectedPropertyIds
) => {
  let result = [];

  if (selectedPropertyIds?.length > 0) {
    selectedPropertyIds.forEach((id) => {
      let foundProperty = modules.find((module) => module.fieldFullPath === id);
      result.push({
        ...foundProperty,
        isShow: true,
        minWidth: defaultWidthCol,
      });
    });
  } else {
    let foundProperty = null;

    foundProperty = modules.find(
      (module) => module.fieldFullPath === fieldFullPath
    );

    // if (isCustomField === 'true') {
    //   foundProperty = modules.find(
    //     (module) => module.customFieldCompanyId === parseInt(moduleName)
    //   );
    // } else {
    //   // No Header
    //   if (!moduleName) {
    //     foundProperty = modules.find(
    //       (module) => module.moduleName === 'No Header'
    //     );
    //   } else {
    //     foundProperty = modules.find(
    //       (module) => module.propertyName === propertyName
    //     );
    //   }
    // }

    if (foundProperty) {
      result.push({
        ...foundProperty,
        isShow: true,
        minWidth: defaultWidthCol,
      });
    }
  }

  return result;

  // let foundProperties = [];

  //   return { ...foundProperty, isShow: true, minWidth: defaultWidthCol };
  // }

  // return {};
};

const getHidingProperties = (properties = [], showCols = []) => {
  let result = [];

  properties.forEach((property) => {
    if (!showCols.includes(property.fieldName)) {
      result.push(property);
    }
  });

  return result;
};

const getShowProperties = (properties = [], showCols = []) => {
  let result = [];

  showCols.forEach((col) => {
    const property = properties.find((property) => property.fieldName === col);
    if (property) {
      result.push(property);
    }
  });

  return result;
};

export const getNewPropertiesAfterSwapping = (
  properties = [],
  showCols = []
) => {
  let showProperties = getShowProperties(properties, showCols);
  let hiddenProperties = getHidingProperties(properties, showCols);

  return [...showProperties, ...hiddenProperties];
};

export const checkExistedProperty = (
  properties,
  fieldFullPath,
  selectedPropertyIds
) => {
  if (selectedPropertyIds) {
    let flag = false;

    for (const id of selectedPropertyIds) {
      const foundIndex = properties.findIndex(
        (property) => property.fieldFullPath === id
      );

      if (foundIndex !== -1) {
        return true;
      }
    }
    return flag;
  }

  return properties.find(
    (property) => property.fieldFullPath === fieldFullPath
  );
};

export const getFoundProperty = (
  properties,
  moduleName,
  propertyName,
  isCustomField
) => {
  if (isCustomField === 'true') {
    return properties.find(
      (property) =>
        property.customFieldCompanyId === parseInt(moduleName) &&
        property.fieldName === propertyName
    );
  }

  if (!moduleName) {
    return properties.find(
      (property) =>
        property.moduleName === null && property.fieldName === propertyName
    );
  }

  return properties.find(
    (property) =>
      property.moduleName === moduleName && property.fieldName === propertyName
  );
};

export const getNewPropertiesAfterDeleting = (
  properties,
  moduleName,
  propertyName,
  isCustomField
) => {
  const cloneProperties = properties.slice();
  const foundIndex = findIndexProperty(
    properties,
    moduleName,
    propertyName,
    isCustomField
  );

  if (foundIndex > -1) {
    cloneProperties.splice(foundIndex, 1);
  }

  return cloneProperties;
};

// colId = moduleName - propertyName -> moduleName of id = ''
const hasIdColumn = (cols) => {
  return cols.findIndex((col) => col.colId.toLowerCase() === '-id') > -1;
};

export const getSelectedColumns = (cols) => {
  let paramCols = [];

  cols.forEach((col) => {
    const [moduleName, fieldName] = col.colId.split('-');

    paramCols.push({
      moduleName: moduleName ? moduleName : null,
      fieldName,
      width: col.actualWidth,
    });
  });

  if (hasIdColumn(cols)) {
    return paramCols.map((col) => col.fieldName).join(',');
  }

  const newParamsCols = paramCols.concat([{ fieldName: 'Id' }]);

  return newParamsCols.map((col) => col.fieldName).join(',');
};

export const filterSelectedProperties = (properties) => {
  let result = [];

  properties.forEach((property) => {
    if (property.isShow) {
      result.push({
        ...property,
      });
    }
  });

  return result;
};

const nullableModuleName = 'No Header';
const nullableModuleDisplayName = 'Product Basic Information';

const getAllModuleNames = (properties) => {
  let modules = [];

  properties.forEach((property) => {
    if (property?.isCustomField) {
      const foundModule = modules.find(
        (item) => item.customFieldCompanyId === property.customFieldCompanyId
      );
      if (!foundModule) {
        modules.push({
          moduleName: property.moduleName,
          moduleDisplayName: `${property.customFieldCompany}'s Custom Fields`,
          customFieldCompanyId: property.customFieldCompanyId,
          customFieldCompany: property.customFieldCompany,
          isCustomField: property.isCustomField,
          moduleProperties: [],
        });
      }
    } else {
      const foundModule = modules.find(
        (item) => item.moduleName === property.moduleName
      );
      if (!foundModule) {
        const foundNullableModule = modules.find(
          (item) => item.moduleName === nullableModuleName
        );
        if (!foundModule) {
          if (!foundNullableModule && property.moduleName === null) {
            modules.push({
              moduleName: nullableModuleName,
              moduleDisplayName: nullableModuleDisplayName,
              moduleProperties: [],
            });
          } else if (property.moduleName !== null) {
            modules.push({
              moduleName: property.moduleName,
              moduleDisplayName: property.moduleDisplayName,
              moduleProperties: [],
            });
          }
        }
      }
    }
  });

  return modules;
};

export const mappingGridProperties = (properties) => {
  if (properties.length === 0) return [];

  let result = getAllModuleNames(properties);

  properties.forEach((item) => {
    if (item.isCustomField) {
      const foundIndexModule = result.findIndex(
        (module) => module.customFieldCompanyId === item.customFieldCompanyId
      );
      result[foundIndexModule].moduleProperties.push(item);
    } else {
      if (item.moduleName === null) {
        const foundIndexModule = result.findIndex(
          (module) => module.moduleName === nullableModuleName
        );
        result[foundIndexModule].moduleProperties.push(item);
      } else {
        const foundIndexModule = result.findIndex(
          (module) => module.moduleName === item.moduleName
        );
        result[foundIndexModule].moduleProperties.push(item);
      }
    }
  });

  return result.filter((item) => item.moduleProperties.length > 0);
};

export const getProperties = (moduleProperties, property) => {
  let properties = [];

  if (property.isCustomField) {
    properties =
      moduleProperties.find(
        (item) => item.customFieldCompanyId === property.customFieldCompanyId
      )?.moduleProperties ?? [];
  } else {
    if (property.moduleName) {
      properties =
        moduleProperties.find((item) => item.moduleName === property.moduleName)
          ?.moduleProperties ?? [];
    } else {
      properties =
        moduleProperties.find((item) => item.moduleName === 'No Header')
          ?.moduleProperties ?? [];
    }
  }

  return properties.map((property) => property.fieldFullPath);
};

const getMultipleSelectedProperties = (
  fieldsFullPaths,
  indexFirst,
  indexLast
) => {
  let dataFieldsMultiSelect = [];

  if (indexFirst === indexLast) {
    return;
  }
  if (indexFirst > indexLast) {
    let indexTemp = indexFirst;
    indexFirst = indexLast;
    indexLast = indexTemp;
  }

  fieldsFullPaths.forEach((val, index) => {
    if (index >= indexFirst && index <= indexLast) {
      dataFieldsMultiSelect.push(val);
    }
  });
  return dataFieldsMultiSelect;
};

export const multiSelect = (properties, selectedIds, property) => {
  const { fieldFullPath } = property;

  const foundProperties = getProperties(properties, property);

  const indexFirst = foundProperties.findIndex((val) => val === selectedIds[0]);

  const indexLast = foundProperties.findIndex((val) => val === fieldFullPath);

  const multipleProperties = getMultipleSelectedProperties(
    foundProperties,
    indexFirst,
    indexLast
  );

  return multipleProperties;
};

export const getUniqueFieldPaths = (properties, selectedPropertyIds) => {
  let result = [];

  selectedPropertyIds.forEach((id) => {
    const foundIndex = properties.findIndex(
      (property) => property.fieldFullPath === id
    );

    if (foundIndex === -1) {
      result.push(id);
    }
  });
  return result;
};

export const getUniqueProperties = (properties, uniqueFieldPaths) => {
  let result = [];

  uniqueFieldPaths.forEach((path) => {
    const foundProperty = properties.find(
      (property) => property.fieldFullPath === path
    );

    result.push({
      ...foundProperty,
      isShow: true,
      minWidth: defaultWidthCol,
    });
  });

  return result;
};

const defaultProperties = {
  customFieldCompany: null,
  customFieldCompanyId: 0,
  isShow: true,
  isCustomField: false,
  minWidth: 50,
  moduleDisplayName: null,
  moduleName: null,
};

export const defaultColumns = [
  {
    dataType: 'int32',
    displayName: 'Id',
    fieldName: 'Id',
    fieldFullPath: 'id',
    ...defaultProperties,
  },
  {
    ...defaultProperties,
    displayName: 'Product Thumbnail',
    fieldName: 'productThumbnail',
    fieldFullPath: 'productThumbnail',
    field: 'productThumbnail',
    width: 70,
    cellRenderer: customCellComponents.renderThumbnail,
    filter: false,
    resizable: false,
    allowSort: false,
    headerClass: 'header-text-hide',
    isCustomField: true,
    moduleName: null,
    customFieldCompany: 'Product thumbnail',
    dataType: 'image',
  },
  {
    ...defaultProperties,
    displayName: 'State Icons',
    fieldName: 'stateIcons',
    fieldFullPath: 'stateIcons',
    field: 'stateIcons',
    headerName: 'States',
    minWidth: 150,
    cellRenderer: customCellComponents.renderIcons,
    filter: false,
    resizable: true,
    allowSort: false,
    headerClass: 'header-text-hide',
    isCustomField: true,
    moduleName: null,
    customFieldCompany: 'Product states',
    dataType: 'icons',
  },

  {
    dataType: 'string',
    displayName: 'Product Name',
    fieldName: 'ProductName',
    fieldFullPath: 'productName',
    ...defaultProperties,
  },
  {
    dataType: 'string',
    displayName: 'Product Description',
    fieldName: 'ProductDescription',
    fieldFullPath: 'productDescription',
    ...defaultProperties,
  },
  {
    dataType: 'string',
    displayName: 'Owner Name',
    fieldName: 'OwnerName',
    fieldFullPath: 'ownerName',
    ...defaultProperties,
  },
  {
    dataType: 'boolean',
    displayName: 'Shareable',
    fieldName: 'Shareable',
    fieldFullPath: 'shareable',
    ...defaultProperties,
  },
  {
    dataType: 'string',
    displayName: 'GTIN',
    fieldName: 'Gtin',
    fieldFullPath: 'gtin',
    ...defaultProperties,
  },
  {
    dataType: 'string',
    displayName: 'UPC-12',
    fieldName: 'Upc12',
    fieldFullPath: 'upc12',
    ...defaultProperties,
  },
  {
    dataType: 'string',
    displayName: 'Status',
    fieldName: 'Status',
    fieldFullPath: 'status',
    ...defaultProperties,
  },
  {
    dataType: 'boolean',
    displayName: 'Is Owner',
    fieldName: 'isOwner',
    fieldFullPath: 'isOwner',
    ...defaultProperties,
  },
  {
    dataType: 'boolean',
    displayName: 'Is Favorited',
    fieldName: 'IsFavorited',
    fieldFullPath: 'isFavorited',
    ...defaultProperties,
  },
  {
    dataType: 'boolean',
    displayName: 'Subscription',
    fieldName: 'Subscription',
    fieldFullPath: 'subscription',
    ...defaultProperties,
  },
  {
    dataType: 'string',
    displayName: 'Brand Name',
    fieldName: 'brandName',
    fieldFullPath: 'brandName',
    ...defaultProperties,
  },
  // {
  //   dataType: 'string',
  //   displayName: 'Supplier Name',
  //   fieldName: 'sharedSupplierNames',
  //   fieldFullPath: 'sharedSupplierNames',
  //   ...defaultProperties,
  // },
  {
    dataType: 'string',
    displayName: 'Subscription Shield ',
    fieldName: 'SubscriptionShield',
    fieldFullPath: 'subscriptionShield',
    ...defaultProperties,
  },
];

export const getAnotherDefaultProperties = (selectedProperties) => {
  let result = [];

  defaultColumns.forEach((col) => {
    const foundProperty = selectedProperties.find(
      (p) => p.fieldName === col.fieldName
    );
    if (!foundProperty) {
      result.push(col);
    }
  });

  return result;
};
