import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';
import gridSelectors from 'common/components/grid-view/controllers/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import { sleep } from 'utils/delay';

export const useDispatchReloadPage = () => {
  const dispatch = useDispatch();

  const dispatchReloadPage = ({ clearSelection } = {}) => {
    dispatch(globalActions.updateReloadPage(true));

    if (!clearSelection) return;
    dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));
    dispatch(actionsGridView.updateItemsSelection([]));
  };

  return dispatchReloadPage;
};

export const useListenReloadPage = (handler) => {
  const dispatch = useDispatch();
  const reloadPage = useSelector(globalSelectors.selectReloadPage());
  useEffect(() => {
    if (reloadPage) {
      handler && handler();
      dispatch(globalActions.updateReloadPage(false));
    }
    // eslint-disable-next-line
  }, [reloadPage]);

  return reloadPage;
};

export const useHandleReloadPage = (handler) => {
  const dispatch = useDispatch();

  const reloadPage = useSelector(globalSelectors.selectReloadPage());

  const triggerReload = useCallback(async () => {
    dispatch(globalActions.updateReloadPage(true));
    await sleep(2000);
    handler && typeof handler === 'function' && handler();
    dispatch(globalActions.updateReloadPage(false));
  }, [handler]);

  return [reloadPage, triggerReload];
};

export const useUpdateSelectedData = (gridData) => {
  const dispatch = useDispatch();
  const reloadPage = useSelector(globalSelectors.selectReloadPage());
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );
  useEffect(() => {
    if (!reloadPage && gridData?.length > 0 && selectedItemList?.length > 0) {
      const newSelectedItemList = selectedItemList.map((selectedItem) => {
        const newSelectedItem = gridData.find((gridItem) => {
          return gridItem?.id === selectedItem?.id;
        });
        // return  new item if selectedItem exist in new grid data, if not, return current selectedItem
        return newSelectedItem || selectedItem;
      });

      dispatch(
        actionsGridView.updateDetailCurrentItemsSelection(newSelectedItemList)
      );
    }
    // eslint-disable-next-line
  }, [gridData]);
};
