import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsMutating,
} from '@tanstack/react-query';

import { useInjectRedux, REDUX_MODULE } from 'hooks/useRedux';

import * as ticketSystemServices from 'services/ticketingSystem';

import { INITIAL_FIELD_VALUE } from 'pages/ticket-full-view/constants';

export const KEY_TICKET_FULL_VIEW = 'ticket-full-view';

const getTicketFullView = async ({ queryKey }) => {
  const response = await ticketSystemServices.getTicketFullView(queryKey[1]);

  const { isSuccess, data } = response;

  if (isSuccess && data) return data;

  throw new Error();
};

const saveTicketDetail = async (params) => {
  const response = await ticketSystemServices.saveTicketDetail(params);

  if (response.isSuccess) return Promise.resolve(response.data);
  else throw new Error(response.message || 'Server Error');
};

export const useTicketFullView = (props = {}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { enabled = false, ...restProps } = props;

  const REDUX = REDUX_MODULE.TICKET_SYSTEM;
  const viewMode = useSelector(REDUX.SELECTORS.makeSelectFullViewMode());

  //* isCreating is deprecated, but it will not affect, keep it for further update
  const isCreating = params.id === 'create';
  const fetchTicketDetailEnabled = !isCreating && params.id && enabled;

  useInjectRedux(REDUX);

  const {
    data: ticketDetailData,
    status: fetchTicketStatus,
    isFetching,
  } = useQuery({
    queryKey: [KEY_TICKET_FULL_VIEW, { TicketId: params.id }],
    queryFn: getTicketFullView,
    enabled: !!fetchTicketDetailEnabled,
    ...restProps,
  });

  const formatTicketDetailData = () => {
    return {
      ...ticketDetailData,
      type: !ticketDetailData?.type ? 'Complaint' : ticketDetailData?.type,
    };
  };

  const ticketData = isCreating
    ? INITIAL_FIELD_VALUE
    : formatTicketDetailData() || {};

  const saveTicketMutation = useMutation({
    mutationKey: 'save-ticket',
    mutationFn: saveTicketDetail,
    onSuccess: () => {
      invalidateTicket();
    },
  });

  const isTicketSaving = useIsMutating({ mutationKey: 'save-ticket' });

  const getQueryKey = () => {
    return [KEY_TICKET_FULL_VIEW, { TicketId: params.id }];
  };

  const changeViewMode = (mode) => {
    dispatch(REDUX.ACTIONS.changeFullViewMode(mode));
  };

  const invalidateTicket = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKey(),
    });
  };

  return {
    viewMode,
    isCreating,
    ticketData,
    fetchTicketStatus,
    isFetchingTicket: isFetching,
    saveTicketMutation,
    changeViewMode,
    invalidateTicket,
    isTicketSaving,
  };
};
