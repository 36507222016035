import React from 'react';
import { useDispatch } from 'react-redux';

import { FolderAddOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import { enableCreateMode } from 'pages/communication-template/controllers/actions';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const CreateCommunicationTemplate = () => {
  const dispatch = useDispatch();

  const handleCreateTemplate = () => {
    dispatch(enableCreateMode());
    forwardTo(`/communication-templates/create`);
  };

  return (
    <RibbonButton
      size='small'
      icon={<FolderAddOutlined />}
      label={Messages.createTemplate}
      onClick={handleCreateTemplate}
    />
  );
};

export default CreateCommunicationTemplate;
