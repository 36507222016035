import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import './Control.less';
const Control = (props) => {
  const {
    children,
    cardClassName,
    cardBodyStyle,
    mode,
    textAlign,
    ...rest
  } = props;
  return (
    <Card
      style={{ textAlign: textAlign }}
      className={'ribbon-control ' + cardClassName + ' ' + mode}
      bodyStyle={cardBodyStyle}
      bordered={false}
      hoverable
      {...rest}
    >
      {children}
    </Card>
  );
};

Control.propTypes = {
  cardClassName: PropTypes.string,
  mode: PropTypes.oneOf(['', 'inline']),
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
};
Control.defaultProps = {
  textAlign: 'center',
  cardBodyStyle: {
    padding: '0px',
    margin: '0px 0px 8px 0px',
  },
};

export default Control;
