import { useEffect, useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import {
  getProductItemModules,
  getProductItemModulesByBrickCode,
  getProductCustomPropertiesData,
  getMemberProductCustomProperties,
} from 'services/product';

import { useAsync } from 'hooks/useAsync';

import { multiplePanelsAllergens } from '../product-media-area/multiple-panel/tab-allergens/queries';
import { getKeyProductIngredients } from '../product-media-area/multiple-panel/tab-ingredients/useProductIngredients';
import { getQaPackagingTabDataQueryKey } from 'pages/qa-spec/components/qa-packaging/queries';
import { qaFreshProduceKeys } from 'pages/qa-spec/components/qa-fresh-produce/hooks';
import { getKeyProductSpec } from 'pages/qa-spec/hooks/useProductSpecInfo';
import { getQaClaimsTabDataQueryKey } from 'pages/qa-spec/components/qa-claims/queries';
import { getQaFreshSeafoodTabDataQueryKey } from 'pages/qa-spec/components/qa-fresh-seafood/queries';

import { qaAllergensKeys } from 'pages/qa-spec/components/qa-allergen/hooks';
import { queryKeyQaSpecIngredients } from 'pages/qa-spec/components/qa-ingredient/hook';

const useGetProductItemModules = (id, isEnabled = true) => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    if (id && isEnabled) {
      run(getProductItemModules({ productId: id }));
    }
    return;
  }, [id, isEnabled, run]);

  const refetchProductItems = useCallback(() => {
    return run(getProductItemModules({ productId: id }));
  }, [id, run]);

  return {
    productItemModules: data,
    statusFetchProductItem: status,
    refetchProductItems,
  };
};

const useGetProductCustomProperties = (id) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (id) {
      run(getProductCustomPropertiesData({ productId: id }));
    }
    return;
  }, [id, run]);

  const refetchProductCustomPropertiesData = useCallback(() => {
    return run(getProductCustomPropertiesData({ productId: id }));
  }, [id, run]);

  return { data, refetchProductCustomPropertiesData };
};

const useGetMemberProductCustomProperties = (ownerId) => {
  const { data, error, run } = useAsync();

  useEffect(() => {
    if (ownerId) {
      run(getMemberProductCustomProperties({ memberId: ownerId }));
    }
    return;
  }, [ownerId, run]);

  return { data, error };
};

const useScrollToFoundModule = ({ foundModule, elementByClassName }) => {
  useEffect(() => {
    if (foundModule && elementByClassName?.[0]) {
      elementByClassName[0].scrollIntoView();
    }

    return;
  }, [elementByClassName, foundModule]);
};

const useSyncProductModules = () => {
  const queryClient = useQueryClient();

  const handleSyncAllergens = (productId) => {
    // overview
    queryClient.invalidateQueries({
      queryKey: multiplePanelsAllergens.productAllergens(productId),
    });
    // qa-spec
    queryClient.invalidateQueries({
      queryKey: qaAllergensKeys.list(productId),
    });
  };

  const handleSyncIngredients = (productId) => {
    // overview
    queryClient.invalidateQueries({
      queryKey: getKeyProductIngredients(productId),
    });
    // qa-spec
    queryClient.invalidateQueries({
      queryKey: queryKeyQaSpecIngredients(productId),
    });
  };

  const handleSyncQaSpec = (productId) => {
    queryClient.invalidateQueries({
      queryKey: getQaPackagingTabDataQueryKey(productId),
    });
    queryClient.invalidateQueries({
      queryKey: qaFreshProduceKeys.detail(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getKeyProductSpec(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getQaClaimsTabDataQueryKey(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getQaFreshSeafoodTabDataQueryKey(productId),
    });
  };

  return {
    handleSyncQaSpec,
    handleSyncAllergens,
    handleSyncIngredients,
  };
};

export {
  useSyncProductModules,
  useGetProductItemModules,
  useGetProductCustomProperties,
  useScrollToFoundModule,
  useGetMemberProductCustomProperties,
};
