import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Row, Pagination, Empty, Col, Typography, Tooltip, Button } from 'antd';
import { FullscreenOutlined, PlusOutlined } from '@ant-design/icons';

import PreviewableThumb from 'common/components/thumb/PreviewableThumb';
import {
  WithAbsoluteContainer,
  FormAddButton,
  CustomNotification,
} from 'common/components';
import AddAssetPimModal from '../modal/add-asset-pim-modal';

import { mapFileTypeToImage } from 'utils/fileType';
import * as getLink from 'utils/common/linkBuilder';

import Messages from 'i18n/messages/product';
import { FormattedMessage } from 'react-intl';

import * as productService from 'services/product';

import './ProductMarketingAll.less';
import { useGetProductFullView } from 'hooks';

const ProductMarketingAll = (props) => {
  const {
    itemList,
    openPreview,
    getImagery,
    getMarketingAssets,
    isAllowEdit,
    getProductDetail,
  } = props;

  const { id } = useParams();

  const [displayList, setDisPlayList] = useState(itemList?.slice(0, 9));

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const addType = 'Marketing';

  const [isLinkToNewAssetModalOpen, setIsLinkToNewAssetModalOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { productFull } = useGetProductFullView({ productId: id });

  const handleToggleLinkAssetModal = () => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
  };

  const { Text } = Typography;

  //   const itemList = itemList1?.concat(itemList1);

  useEffect(() => {
    let list = itemList?.filter(
      (item, index) =>
        index < pageIndex * pageSize && index >= (pageIndex - 1) * pageSize
    );
    setDisPlayList(list);
  }, [pageSize, pageIndex, itemList]);

  const changePageSizeHandler = (page, pageSize) => {
    setPageIndex(page);
    setPageSize(pageSize);
  };

  const handlePrimaryProduct = (productImageLinkId) => {
    setIsLoading(true);

    const params = {
      productId: productFull?.productId,
      productImageLinkId: productImageLinkId,
    };

    productService
      .setPrimaryProductImage(params)
      .then((response) => {
        if (response?.isSuccess) {
          CustomNotification.success('Change Primary Image successfully.');
          getProductDetail();
        } else {
          CustomNotification.error(response?.message);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showThumbMarketingImage = (item) => {
    return (
      item?.thumb500 ||
      item?.thumb1000 ||
      item?.thumbFull ||
      mapFileTypeToImage(item?.fileType)
    );
  };

  const showPreviewMarkingImage = (item) => {
    return (
      item?.thumbFull ||
      item?.thumb1000 ||
      item?.thumb500 ||
      mapFileTypeToImage(item?.fileType)
    );
  };

  return (
    <>
      <Row className={`product-marketing-all-wrapper__slider `} gutter={[8, 0]}>
        <Tooltip title='Show preview'>
          <Button
            onClick={() => openPreview()}
            className='product-marketing-all-wrapper__slider--open-button'
            icon={<FullscreenOutlined style={{ fontSize: '20px' }} />}
          ></Button>
        </Tooltip>
        <Col flex={0} style={{ width: '52px' }}>
          <Text className='product-marketing-all-wrapper__slider-title'>
            Marketing
          </Text>
        </Col>
        <Col
          flex={0}
          style={{ maxWidth: 'calc(100% - 60px)', width: '100%' }}
          className='product-marketing-all-wrapper__slider-container-wrapper'
        >
          {itemList && itemList.length > 0 ? (
            <>
              <Row className='product-marketing-all scroller'>
                {displayList &&
                  displayList.length &&
                  displayList.map((item, index) => {
                    const title = getLink.assetLink(
                      item?.digitalAssetId,
                      item?.assetName,
                      '_blank'
                    );
                    return (
                      <div className='product-marketing-all__item' key={index}>
                        <PreviewableThumb
                          id={item.productImageLinkId}
                          img={showThumbMarketingImage(item)}
                          link={showPreviewMarkingImage(item)}
                          title={title}
                          type='images'
                          downloadLink={item?.originalFile}
                          isDocument={false}
                          isMarketing={true}
                          isSetPrimary={isAllowEdit}
                          isLoadingBtnPrimary={isLoading}
                          handlePrimaryProduct={() =>
                            handlePrimaryProduct(item?.productImageLinkId)
                          }
                        />
                      </div>
                    );
                  })}
              </Row>
              <Row style={{ width: '100%' }}>
                <Col>
                  <Pagination
                    total={itemList.length}
                    current={pageIndex}
                    pageSize={pageSize}
                    size='small'
                    showSizeChanger={true}
                    onChange={changePageSizeHandler}
                  />
                </Col>
                <Col>
                  <div>
                    <Typography.Text strong={true} style={{ marginLeft: 8 }}>
                      {itemList ? itemList.length : ''} &nbsp;
                    </Typography.Text>
                    <FormattedMessage {...Messages.totalItems} />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Empty />
            </div>
          )}
        </Col>
        <Col span={1}></Col>

        {isAllowEdit && (
          <FormAddButton
            text={null}
            icon={<PlusOutlined />}
            style={{ position: 'absolute', bottom: 4, right: 14 }}
            onClick={handleToggleLinkAssetModal}
          />
        )}
        <WithAbsoluteContainer>
          {isLinkToNewAssetModalOpen && (
            <AddAssetPimModal
              onCloseModal={handleToggleLinkAssetModal}
              addType={addType}
              getImagery={getImagery}
              getMarketingAssets={getMarketingAssets}
            />
          )}
        </WithAbsoluteContainer>
      </Row>
    </>
  );
};
export default ProductMarketingAll;
// assetName: "00762111987709_C2N1"
// assetType: "VIDEO"
// digitalAssetId: 138676
// fileUrl: "/api/assets/dam/tif/b3be00f1-483e-4675-8e2e-892aff8ceccb/file/00762111987709_C2N1.TIF"
// isPrimary: true
// productImageLinkId: 1581
