import React, { useRef, useCallback } from 'react';

import { Row, Col, Input } from 'antd';
import { MemberThumbnailCard } from 'pages/branded-members/components';
import { SearchEntityBtn } from 'common/components';

import * as memberServices from 'services/members';

const initMemberGridParams = {
  type: 'GET_MEMBER_LIST',
  pageSize: 20,
  pageNumber: 1,
  isFavoriteRoute: false,
  pageIndex: 1,
  search: { searchText: '' },
};

const MemberSelectInput = (props) => {
  const { value, onChange, placeholder } = props;

  const refSearchEntityBtn = useRef();

  const handleClickOkSearchMemberModal = (itemList) => {
    if (onChange && typeof onChange === 'function' && itemList !== 'init') {
      onChange(itemList);
    }

    if (itemList?.length <= 0 || typeof itemList === 'string') return;

    refSearchEntityBtn.current.closeModal();
  };

  const tagResultRender = useCallback(({ itemData }) => {
    return <MemberThumbnailCard dataDetail={itemData} />;
  }, []);

  return (
    <Row>
      <Col flex={1}>
        <Input
          value={value}
          style={{ pointerEvents: 'none' }}
          placeholder={placeholder}
        />
      </Col>
      <Col>
        <SearchEntityBtn
          ref={refSearchEntityBtn}
          searchType='member'
          isMultiple={false}
          service={memberServices.getMemberList}
          dataPath='data.gridData'
          params={initMemberGridParams}
          mapSearch={(searchText) => ({ searchText })}
          tagRender={tagResultRender}
          onOk={handleClickOkSearchMemberModal}
        />
      </Col>
    </Row>
  );
};

export default MemberSelectInput;
