import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  loading: false,
  memberInfo: null,
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const memberProfileReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MEMBER_PENDING:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_MEMBER_SUCCESS:
        draft.loading = false;
        draft.memberInfo = action.data;
        draft.error = null;
        break;
      case types.GET_MEMBER_ERROR:
        draft.loading = false;
        draft.error = action.error;
        draft.memberInfo = null;
        break;
    }
  });

export default memberProfileReducer;
