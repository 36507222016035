import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { StyledModal } from 'common/components';

import { useAdvanceFilter } from './hook/hook';
import WithLoading from '../wrapper/WithLoading';
import NestedAdvanceSearch from './NestedAdvanceSearch';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import { checkPathName } from './hook/utils';
import {
  updateQueryAdvance,
  updateQueryConditions,
} from 'utils/queryCondition';
import { ADVANCED_FILTER_MODE } from 'pages/reporting/utils/constans';

const NestedAdvanceFilter = () => {
  const dispatch = useDispatch();

  const pathName = window.location.pathname;
  const entityType = checkPathName(pathName);

  const [modeData, setModeData] = useState(
    entityType === 'product'
      ? ADVANCED_FILTER_MODE.FLAT_DATA
      : ADVANCED_FILTER_MODE.NESTED_DATA
  );

  const isOpen = useSelector(selectorsGridView.makeSelectIsOpenAdvanceSearch());
  const { ...AdvancedSearchProps } = useAdvanceFilter({ modeData, entityType });

  const {
    state: { propertiesData, dataQueryConditions },
    handleUpdateQueryCondition,
  } = AdvancedSearchProps ?? {};

  const handleAdvanceFilters = () => {
    updateQueryConditions(dispatch, dataQueryConditions, pathName);
    updateQueryAdvance(dispatch, dataQueryConditions, pathName);
    dispatch(actionsGridView.updateIsOpenAdvanceSearch(false));
    dispatch(actionsGridView.myQueryDoubleClick(true));
    dispatch(actionsGridView.checkQueryCondition(true));
    dispatch(actionsGridView.updateIsOpenAdvanceSearch(false));
  };

  const handleCancel = () => {
    handleUpdateQueryCondition([]);
    dispatch(actionsGridView.updateIsOpenAdvanceSearch(false));
  };
  const isLoading = propertiesData?.length > 0;
  const hasOverloadFile =
    dataQueryConditions?.children?.length > 0 &&
    !_.isEmpty(
      dataQueryConditions?.children?.find(
        (val) => val?.acceptableValueTemp?.length > 100000
      )
    );

  return (
    <>
      <StyledModal
        title='Advanced Filter'
        visible={isOpen}
        onOk={handleAdvanceFilters}
        onCancel={() => handleCancel()}
        width='80vw'
        bodyStyle={{ height: '80vh', padding: 0 }}
        okButtonProps={{ disabled: hasOverloadFile }}
      >
        <WithLoading loading={!isLoading}>
          <NestedAdvanceSearch
            key={pathName}
            {...AdvancedSearchProps}
            modeData={modeData}
            entityType={entityType}
            setModeData={setModeData}
          />
        </WithLoading>
      </StyledModal>
    </>
  );
};

export default NestedAdvanceFilter;
