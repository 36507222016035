import React from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl, useIntl } from 'react-intl';

import RibbonButton from 'common/components/button/RibbonButton';
import { CustomNotification } from 'common/components';
import { ReactComponent as IconDeleteFolders } from 'common/components/button/svg-icons/delete-folder.svg';

import Messages from 'i18n/messages/home';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useFolderByLevel } from 'hooks/useFolderByLevel';
import { useRemoveFolder } from 'hooks/useRemoveFolder';
import { useGridView } from 'hooks/useGridView';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';
import folderMessages from 'i18n/messages/folder';

import * as brandingActions from 'redux/branding/actions';

const DeleteFolders = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const reloadPage = useDispatchReloadPage();

  //* isOwner is not correct any more
  const { isOwner, selectedItems, disabled } = props;
  const [firstSelectedItems] = selectedItems;

  const { currentBreadcrumb } = useFolderByLevel();
  const { showContentPane, clearGridSelection } = useGridView();
  const isFavoriteRoute = useCheckFavoriteRoute();

  const { showPopupConfirm } = useRemoveFolder({
    selectedItems,
    currentFolderId: currentBreadcrumb?.id,
    onDeleteFolderSuccess: () => {
      deleteSuccessCallback();
    },
    onDeleteItemSuccess: () => {
      deleteSuccessCallback();
    },
  });

  const onClick = () => {
    showPopupConfirm();
  };

  const deleteSuccessCallback = () => {
    CustomNotification.success(deleteItemsSuccessMessage);
    reloadPage({ clearSelection: true });
    showContentPane(false);
    if (isFavoriteRoute) {
      dispatch(brandingActions.getBrandingNoLoading());
    }
  };

  const allowDeleteFolder = () => {
    const isOwner = firstSelectedItems?.isOwner;
    const isEditable = firstSelectedItems?.editable;

    if (disabled) return true;

    if (!selectedItems.length) return true;

    return !isOwner && !isEditable;
  };

  const isDisable = allowDeleteFolder();

  const deleteItemsSuccessMessage = intl.formatMessage(
    folderMessages.deleteItemsSuccessMessage
  );

  return (
    <RibbonButton
      size='small'
      icon={<IconDeleteFolders />}
      label={Messages.deleteFoldersRibbon}
      disabled={isDisable}
      onClick={onClick}
    />
  );
};

export default injectIntl(DeleteFolders);
