import React from 'react';
import { useDispatch } from 'react-redux';

import { ClearOutlined } from '@ant-design/icons';

import { dialogFunction } from 'common/components/index';

import Messages from 'i18n/messages/home';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import { defaultSearchText } from 'redux/global/reducer';
import * as actionsGlobal from 'redux/global/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import {
  updateQueryConditions,
  updateQueryAdvance,
  updateIdQuery,
} from 'utils/queryCondition';

import useGetDataIdQuery from 'hooks/useGetDataIdQuery';

const ClearQuery = (props) => {
  const dispatch = useDispatch();

  const idQueryActive = useGetDataIdQuery(window.location.pathname);

  const confirmClearQuery = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to clear current query?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleClearQuery,
    });
  };

  const handleClearQuery = () => {
    dispatch(actionsGridView.chooseGridColumns([]));

    updateQueryConditions(dispatch, [], window.location.pathname);
    updateQueryAdvance(dispatch, [], window.location.pathname);
    updateIdQuery(dispatch, null, window.location.pathname);

    dispatch(actionsGridView.myQueryDoubleClick(true));
    dispatch(actionsGridView.checkQueryCondition(true));
    dispatch(actionsGlobal.resetSearchText(defaultSearchText));
  };

  return (
    <ButtonSmallIcon
      icon={<ClearOutlined />}
      label={Messages.clearConfig}
      onClick={confirmClearQuery}
      className='grid-config__button'
      disabled={idQueryActive === null}
    />
  );
};

ClearQuery.propTypes = {};

export default ClearQuery;
