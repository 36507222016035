import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RibbonSection } from 'common/components';
import ShowOverview from '../../controls/member-grid/ShowOverview';
import ShowDetails from '../../controls/member-grid/ShowDetails';
import ShowProducts from '../../controls/member-grid/ShowProducts';
import ShowAssets from '../../controls/member-grid/ShowAssets';
import OfficeFacility from '../../controls/member-full/OfficeFacility';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import * as actions from 'redux/ribbon/actions';
import { selectMemberLayout } from 'redux/ribbon/selectors';

const ShowAll = () => {
  const dispatch = useDispatch();
  const layout = useSelector(selectMemberLayout());

  const handleChangeLayout = (value) => {
    dispatch(actions.changeMemberLayout(value));
  };

  return (
    <RibbonSection>
      {layout === 'overview' ? (
        <ShowDetails handleClick={() => handleChangeLayout('details')} />
      ) : (
        <ShowOverview handleClick={() => handleChangeLayout('overview')} />
      )}
      <OfficeFacility />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ShowProducts />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.ASSET}>
        <ShowAssets />
      </Can>
    </RibbonSection>
  );
};

export default ShowAll;
