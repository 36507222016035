import React from 'react';

import { Typography, Select, Empty } from 'antd';

import classnames from 'classnames';

import { WithLoading } from 'common/components';
import { TreeFiles } from '../shared/components';

import {
  getExpandedData,
  mappingInteropItemsToTreeData,
  updateTreeData,
} from './utils';
import { sleep } from 'utils/delay';

import { GLOBAL_MEDIA_QUERIES } from 'static/MediaQuery';
import { getVirtualScrollHeight } from '../shared/utils';

import { useMedia } from 'react-media';

export const DropboxFiles = (props) => {
  const {
    idConnection,
    multipleImport,
    treeData,
    isExpanding,
    expandAll,
    selectedDropbox,
    onSetTreeData,
    onSelectItems,
    onExpandItems,
  } = props;

  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const largeScreen = matches.lg || matches.md || matches.xl;

  const { selectedKeys, expandedKeys } = selectedDropbox;

  const virtualScrollHeight = getVirtualScrollHeight(
    largeScreen,
    multipleImport
  );

  const handleSelectItems = (key, info) => {
    onSelectItems(key, info.selectedNodes);
  };

  const handleCheckItems = (key, { checkedNodes }) => {
    onSelectItems(key, checkedNodes);
  };

  const handleLoadData = async ({ key, children, data }) => {
    // Tree data is already loaded data inside.
    if (expandAll.status === 'success') return;

    const { path } = data;

    const expandedData = await getExpandedData({
      id: idConnection,
      pathInfo: path,
      getFile: true,
      getFolder: true,
      storageServiceType: 'dropbox',
    });

    return new Promise(async (resolve) => {
      if (children || expandedData === null) {
        resolve();
        return;
      }

      const mappedTreeData = mappingInteropItemsToTreeData(
        expandedData,
        multipleImport
      );

      await sleep(500);

      onSetTreeData((prevData) =>
        updateTreeData(prevData, key, mappedTreeData)
      );
      resolve();
    });
  };

  const isLoading = isExpanding || expandAll.status === 'loading';

  return (
    <WithLoading loading={isLoading}>
      <div
        className={classnames({
          'dropbox-asset__tree': true,
          'dropbox-asset__tree--multiple': Boolean(multipleImport),
        })}
      >
        {treeData?.length > 0 ? (
          <TreeFiles
            treeData={treeData}
            height={virtualScrollHeight}
            checkable={multipleImport}
            selectedKeys={selectedKeys}
            checkedKeys={selectedKeys}
            expandedKeys={expandedKeys}
            selectable={multipleImport ? false : true}
            multiple={multipleImport}
            loadData={handleLoadData}
            onSelect={handleSelectItems}
            onCheck={handleCheckItems}
            onExpand={onExpandItems}
          />
        ) : (
          <Empty
            className={classnames({
              'dropbox-asset__tree-empty': true,
              'dropbox-asset__tree-empty--multiple': Boolean(multipleImport),
            })}
          />
        )}
      </div>
    </WithLoading>
  );
};

export const DropboxSelection = ({
  valueIdSelection,
  checked,
  interoperabilities,
  onChangeIdConnection,
  // onChangeExpandAll,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        paddingBottom: 6,
      }}
    >
      <Typography.Title level={5} style={{ marginTop: 4 }}>
        Connection:
      </Typography.Title>
      <Select
        placeholder='Select a connection'
        style={{
          marginLeft: 16,
          maxWidth: 400,
          width: '100%',
          overflow: 'hidden',
        }}
        onChange={onChangeIdConnection}
        value={valueIdSelection}
      >
        {interoperabilities.map((interop) => (
          <Select.Option key={interop.id} value={interop.id}>
            {interop.storageServiceName}
          </Select.Option>
        ))}
      </Select>

      {/* <Checkbox
        style={{ marginTop: 6, marginLeft: 24, flexShrink: 0 }}
        onChange={onChangeExpandAll}
        checked={checked}
      >
        Expand All
      </Checkbox> */}
    </div>
  );
};
