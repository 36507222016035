import {
  Button,
  Checkbox,
  Col,
  Modal,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

import { ArrowDownOutlined } from '@ant-design/icons';
import { CustomNotification } from 'common/components';
import PublicationValidationErrorCheck from '../publication-validation-error-check';

import { Images } from 'config/assets';

import * as endpointsProduct from 'services/product/endpoints';
import { getPublishChecklist } from 'services/product';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import classnames from 'classnames';

import { dialogFunction } from 'common/components/index';
import ErrorModal from 'common/components/product-publication-setup/ErrorModal.js';
import * as api from 'config/axios';
import { formatMDY } from 'utils/formatDate';

import mapToPublishProductRequestParams from 'pages/branded-products/mapper/mapToPublishProductRequestParams';

import { useGetProductFullView } from 'hooks';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import './PublicationValidationView.less';

const color = [
  'beige',
  'bisque',
  'burlywood',
  'chocolate',
  'coral',
  'darkseagreen',
  'lightblue',
  'lightcyan',
  'mistyrose,',
  'pink',
  'skyblue',
  'wheat',
  'yellowgreen',
  'teal',
];

const PublicationValidationView = ({
  visible,
  handleVisible,
  handleSetModalVisible,
  requestParams,
  isFullView,
  productId,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [publishChecklist, setPublishChecklist] = useState([]);
  const [hieararchySelect, setHieararchySelect] = useState(-1);
  const [publishSelect, setPublishSelect] = useState(-1);

  const [visibleHieararchy, setVisibleHieararchy] = useState(false);
  const [rowDataHieararchy, setRowDataHieararchy] = useState([]);
  const [isErrorListModalOpen, setIsErrorListModalOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const { handleRefetchProductFullView } = useGetProductFullView({
    productId,
  });

  const clearState = () => {
    setPublishChecklist([]);
    setPublishSelect(-1);
    setHieararchySelect(-1);
  };

  const toggleErrorListModal = () => {
    setIsErrorListModalOpen((prev) => {
      prev && setPublishSelect(-1);

      return !prev;
    });
  };

  const handleClickErrorStatus = (rowIndex) => {
    setPublishSelect(rowIndex);
    toggleErrorListModal();
  };

  useEffect(() => {
    if (!requestParams) return;
    setErrorList([]);
    handleGetPublishCheckList(requestParams);
  }, [requestParams]);

  const handleGetPublishCheckList = async (params) => {
    setLoading(true);
    await getPublishChecklist(params)
      .then((response) => {
        const { message, isSuccess, data, status } = response || {};
        if (isSuccess) {
          let data;
          data = response?.data?.publishChecklist;
          setPublishChecklist(data);
        } else {
          const notificationMessage = message
            ? message
            : status === 500 && data?.title
            ? data?.title
            : 'Server Error';

          CustomNotification.error(notificationMessage);
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const renderErrorField = (params, text) => {
    let array = params.data?.errors || [];
    let check = {};
    let result = array.filter((o) => {
      var key = ['errorMessage', 'errorType'].map((k) => o[k]).join('|');
      if (!check[key]) {
        check[key] = true;
        return true;
      }
    });
    return (
      <Row className='scroller' style={{ maxHeight: '300px', width: '400px' }}>
        <Col span={24}>
          <Typography.Title level={5} style={{ color: '#fff' }}>
            {text}:
          </Typography.Title>
        </Col>
        {result &&
          result.length > 0 > 0 &&
          result.map((val) => {
            return (
              <Col span={24}>
                <Typography.Title
                  level={5}
                  style={{ marginLeft: 4, color: '#fff' }}
                >
                  - {val?.errorType}: {val?.errorMessage}
                </Typography.Title>
                {val?.errorFields &&
                  val?.errorFields.length > 0 &&
                  val?.errorFields.map((err) => {
                    return (
                      <Col span={24}>
                        <Typography.Text
                          style={{ marginLeft: 8, color: '#fff' }}
                        >
                          + ({err.packageLevel}) {err.fieldName}
                        </Typography.Text>
                      </Col>
                    );
                  })}
              </Col>
            );
          })}
      </Row>
    );
  };
  const statusRender = (params) => {
    if (params.value === 'Yellow')
      return (
        <Tooltip
          color={'#faad14'}
          title={renderErrorField(params, 'Recipient fields missing')}
          placement='right'
          overlayStyle={{ maxWidth: '450px' }}
        >
          <Button
            type='text'
            onClick={() => {
              handleClickErrorStatus(params?.rowIndex);
            }}
            style={{
              width: '100%',
              height: '100%',
              color: 'transparent',
            }}
          >
            error list
          </Button>
        </Tooltip>
      );
    if (params.value === 'Red')
      return (
        <Tooltip
          color={'#f5222d'}
          title={renderErrorField(params, 'GDSN fields missing')}
          zIndex={10000}
          placement='right'
          overlayStyle={{ maxWidth: '450px' }}
        >
          <Button
            type='text'
            onClick={() => {
              handleClickErrorStatus(params?.rowIndex);
            }}
            style={{
              width: '100%',
              height: '100%',
              color: 'transparent',
            }}
          >
            error list
          </Button>
        </Tooltip>
      );
    if (params.value === 'Green')
      return (
        <Tooltip
          className='scroller'
          color={'#389e0d'}
          title={'All fields populated'}
          zIndex={10000}
          placement='right'
          overlayStyle={{ maxWidth: '450px' }}
        >
          <Button
            type='text'
            // icon={<CheckOutlined style={{ color: 'green', fontSize: 18 }} />}
            onClick={() => {}}
            style={{ color: '#fff', width: '100%', height: '100%' }}
          >
            {'     '}
          </Button>
        </Tooltip>
      );
    return <div></div>;
  };

  const statusCellClass = (params) => {
    if (params.value === 'Green') {
      return 'rag-green';
    }
    if (params.value === 'Yellow') {
      return 'rag-amber';
    }
    if (params.value === 'Red') {
      return 'rag-red';
    }
  };

  const imgRender = (params) => {
    return (
      // <div
      //   style={{
      //     display: 'flex',
      //     justifyContent: 'center',
      //     background: '#ccc',
      //     borderRadius: 8,
      //     padding: 2,
      //   }}
      // >
      <img
        src={params?.value || Images.RIVIR_LOGO_DEFAULT}
        style={{
          display: 'block',
          height: '28px',
          verticalAlign: 'top',
          borderStyle: 'none',
          width: '28px',
          objectFit: 'contain',
          backgroundColor: '#bfbfbf',
          padding: 2,
          borderRadius: 2,

          position: 'relative',
          left: -8,
        }}
        alt=''
      />
      // </div>
    );
  };

  const countSizeArrowRender = (params) => {
    return (
      <Row>
        <Col span={20}>
          <Typography.Text ellipsis style={{ width: 'calc(100%/24*20)' }}>
            {isNaN(parseFloat(params?.data?.productPackCount)) &&
            isNaN(parseFloat(params?.data?.productSize))
              ? ''
              : isNaN(parseFloat(params?.data?.productPackCount))
              ? Math.round(parseFloat(params?.data?.productSize) * 100) / 100 +
                ' ' +
                (params?.data?.productSizeUOM || 'unknown measurement unit')
              : isNaN(parseFloat(params?.data?.productSize))
              ? params?.data?.productPackCount +
                (params?.data?.productSizeUOM || 'unknown measurement unit')
              : params?.data?.productPackCount +
                '/' +
                Math.round(parseFloat(params?.data?.productSize) * 100) / 100 +
                ' ' +
                (params?.data?.productSizeUOM || 'unknown measurement unit')}
          </Typography.Text>
        </Col>
        <Col span={4}>
          <Button
            type='text'
            icon={<ArrowDownOutlined style={{ color: 'blue', fontSize: 18 }} />}
            onClick={() => {
              setPublishSelect(params?.rowIndex);
              let hierarchyItems = params?.data?.hierarchyItems || [];
              hierarchyItems.sort(comparePackageLevel);
              hierarchyItems.sort(
                (firstItem, secondItem) =>
                  firstItem?.hierarchyId - secondItem?.hierarchyId
              );
              setRowDataHieararchy(hierarchyItems);
              setVisibleHieararchy(true);
            }}
          ></Button>
        </Col>
      </Row>
    );
  };

  const comparePackageLevel = (a, b) => {
    if (a?.productPackageLevel === 'Pallet') {
      if (b?.productPackageLevel === 'Pallet') {
        return 0;
      } else {
        return -1;
      }
    }
    if (a?.productPackageLevel === 'Master Case') {
      if (b?.productPackageLevel === 'Master Case') {
        return 0;
      } else if (b?.productPackageLevel === 'Pallet') {
        return 1;
      } else {
        return -1;
      }
    }
    if (a?.productPackageLevel === 'Case') {
      if (b?.productPackageLevel === 'Case') {
        return 0;
      } else if (
        b?.productPackageLevel === 'Pallet' ||
        b?.productPackageLevel === 'Master Case'
      ) {
        return 1;
      } else {
        return -1;
      }
    }
    if (a?.productPackageLevel === 'Inner Pack') {
      if (b?.productPackageLevel === 'Inner Pack') {
        return 0;
      } else if (
        b?.productPackageLevel === 'Pallet' ||
        b?.productPackageLevel === 'Master Case' ||
        b?.productPackageLevel === 'Case'
      ) {
        return 1;
      } else {
        return -1;
      }
    }
    if (a?.productPackageLevel === 'Unit') {
      if (b?.productPackageLevel === 'Unit') {
        return 0;
      } else {
        return 1;
      }
    }
    return 0;
  };

  const renderSize = (params) => {
    return isNaN(parseFloat(params?.value))
      ? ''
      : Math.round(parseFloat(params?.value) * 100) / 100;
  };

  const columnDefs = [
    {
      fieldNameCamelCase: 'status',
      displayName: '',
      width: 50,
      cellClass: statusCellClass,
      cellRenderer: statusRender,
    },
    {
      fieldNameCamelCase: 'productImageThumb',
      displayName: '',
      width: 50,
      cellRenderer: imgRender,
    },
    {
      dataType: 'string',
      displayName: 'Recipient',
      fieldName: 'RecipientName',
      fieldNameCamelCase: 'recipientName',
    },
    {
      dataType: 'string',
      displayName: 'Recipient GLN',
      fieldName: 'RecipientGLN',
      fieldNameCamelCase: 'recipientGLN',
    },
    {
      dataType: 'string',
      displayName: 'Product Name',
      fieldName: 'ProductName',
      fieldNameCamelCase: 'productName',
    },
    {
      dataType: 'string',
      displayName: 'Product GTIN',
      fieldName: 'ProductGTIN',
      fieldNameCamelCase: 'productGTIN',
    },
    {
      dataType: 'string',
      displayName: 'Package Level',
      fieldName: 'productPackageLevel',
      fieldNameCamelCase: 'productPackageLevel',
    },
    {
      dataType: 'string',
      displayName: 'Count/Size',
      cellRenderer: countSizeArrowRender,
    },
    {
      dataType: 'string',
      displayName: 'Brand Name',
      fieldName: 'ProductBrandName',
      fieldNameCamelCase: 'productBrandName',
    },
    {
      dataType: 'string',
      displayName: 'RIVIR ID',
      fieldName: 'ProductRivirId',
      fieldNameCamelCase: 'productRivirId',
    },
    {
      dataType: 'string',
      displayName: 'Primary Size',
      fieldName: 'ProductSize',
      fieldNameCamelCase: 'productSize',
      cellRenderer: renderSize,
    },
    {
      dataType: 'string',
      displayName: 'Primary Unit of Measure',
      fieldName: 'ProductSizeUOM',
      fieldNameCamelCase: 'productSizeUOM',
    },
  ];

  const handleModalPublish = () => {
    setErrorList([]);

    const params = mapToPublishProductRequestParams({
      publishChecklist,
      requestParams,
    });

    setLoadingPublish(true);
    api
      .sendPost(endpointsProduct.EXECUTE_PUBLISH_PRODUCTS, params)
      .then((response) => {
        const { isSuccess, data } = response;

        if (isSuccess) {
          CustomNotification.success('Publish successfully');
          clearState();
          handleVisible(false);
          handleSetModalVisible(false);
        } else {
          const errorMessages = data?.messages?.map(
            (mess) => mess?.messageContent
          );

          setErrorList(errorMessages);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingPublish(false);
      });
  };
  const handleModalCancel = () => {
    clearState();
    setLoading(false);
    handleVisible(false);

    //
    if (isFullView && productId) {
      handleRefetchProductFullView();
    }
  };

  const handleModalCancelHieararchy = () => {
    setVisibleHieararchy(false);
    setHieararchySelect(-1);
  };
  const handleModalSelect = () => {
    dialogFunction({
      type: 'warn',
      content:
        'Validation process will re-execute. Are you sure you want to select new package level for publication?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleConfirm,
      zIndex: 100000,
    });
  };

  const handleConfirm = async () => {
    let dataSelect = rowDataHieararchy[hieararchySelect];
    let dataTemp = [...publishChecklist];

    let params = {
      productIds: [dataSelect?.productId],
      recipientGLNs: [dataTemp[publishSelect]?.recipientGLN],
      sourceGLN: requestParams.sourceGLN,
    };

    setHieararchySelect(-1);
    setVisibleHieararchy(false);
    setLoading(true);

    await getPublishChecklist(params)
      .then((response) => {
        const { isSuccess, message, data, status } = response || {};

        if (isSuccess) {
          let data;
          data = response?.data?.publishChecklist;
          dataTemp[publishSelect] = (data && data.length > 0 && data[0]) || [];
          setPublishChecklist(dataTemp);
        } else {
          const notificationMessage = message
            ? message
            : status === 500 && data?.title
            ? data?.title
            : 'Server Error';

          CustomNotification.error(notificationMessage);
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const regetPublishErrorAfterSubmitErrorChecker = async () => {
    let dataSelect = publishChecklist[publishSelect];

    let dataTemp = [...publishChecklist];

    const recipientListToRecheck = publishChecklist
      .filter(
        (publishCheck) => publishCheck?.productId === dataSelect?.productId
      )
      .map((publishCheck) => publishCheck?.recipientGLN);

    let params = {
      productIds: [dataSelect?.productId],
      recipientGLNs: recipientListToRecheck,

      sourceGLN: requestParams.sourceGLN,
    };

    setLoading(true);
    await getPublishChecklist(params)
      .then((response) => {
        const { isSuccess, status, data, message } = response || {};
        if (isSuccess) {
          let data;
          data = response?.data?.publishChecklist;

          data &&
            data?.length >= 0 &&
            data.forEach((resPublishCheck) => {
              const foundCheckListIdx = dataTemp.findIndex(
                (publishCheck) =>
                  publishCheck?.productId === resPublishCheck.productId &&
                  publishCheck?.recipientGLN === resPublishCheck.recipientGLN
              );
              if (foundCheckListIdx !== -1) {
                dataTemp[foundCheckListIdx] = resPublishCheck;
              }
            });

          setPublishChecklist(dataTemp);
        } else {
          const notificationMessage = message
            ? message
            : status === 500 && data?.title
            ? data?.title
            : 'Server Error';

          CustomNotification.error(notificationMessage);
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));

    return true;
  };

  const columnGrid = (val, index) => {
    return (
      <AgGridColumn
        headerName={val?.displayName}
        field={val?.fieldNameCamelCase}
        width={val?.width}
        minWidth={50}
        flex={val?.flex}
        sortable={false}
        sortIndex={null}
        resizable={true}
        cellRendererFramework={
          val && val.dataType !== 'boolean' ? val.cellRenderer : checkboxRender
        }
        valueFormatter={val?.dataType === 'DateTime' && formatDate}
        suppressMenu={true}
        suppressMovable={true}
        key={index}
        cellClass={val?.cellClass}
      />
    );
  };
  const formatDate = (params) => {
    return formatMDY(params.value);
  };
  const checkboxRender = (params) => {
    return (
      <Checkbox
        checked={params.value}
        // onChange={(e) => {
        //   params.data[params.colDef.field] = e.targetchecked;
        //   params.api.refreshCells();
        // }}
      ></Checkbox>
    );
  };

  const setColor = (selectPackageLevel, packageLevel) => {
    if (selectPackageLevel === 'Pallet') {
      if (packageLevel === 'Pallet') {
        return 'grey';
      } else {
        return 'grey';
      }
    }
    if (selectPackageLevel === 'Master Case') {
      if (packageLevel === 'Master Case') {
        return 'grey';
      } else if (packageLevel === 'Pallet') {
        return 'green';
      } else {
        return 'grey';
      }
    }
    if (selectPackageLevel === 'Case') {
      if (packageLevel === 'Case') {
        return 'grey';
      } else if (packageLevel === 'Pallet' || packageLevel === 'Master Case') {
        return 'green';
      } else {
        return 'grey';
      }
    }
    if (selectPackageLevel === 'Inner Pack') {
      if (packageLevel === 'Inner Pack') {
        return 'grey';
      } else if (
        packageLevel === 'Pallet' ||
        packageLevel === 'Master Case' ||
        packageLevel === 'Case'
      ) {
        return 'green';
      } else {
        return 'grey';
      }
    }
    if (selectPackageLevel === 'Unit') {
      if (packageLevel === 'Unit') {
        return 'grey';
      } else {
        return 'green';
      }
    }
    return '';
  };

  return (
    <>
      <Modal
        width={'80vw'}
        bodyStyle={{ height: '70vh', zIndex: '9999999' }}
        title={'Publication'}
        visible={visible}
        closable={!loading && !loadingPublish}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={handleModalCancel}
        footer={[
          <Button
            disabled={loading || loadingPublish}
            key='cancel'
            onClick={handleModalCancel}
          >
            Cancel
          </Button>,
          <Button
            key='select'
            type='primary'
            onClick={() => {
              handleModalPublish();
            }}
            loading={loading || loadingPublish}
            disabled={
              (publishChecklist &&
                publishChecklist.length > 0 &&
                publishChecklist.filter((val) => val.status === 'Green')
                  ?.length === 0) ||
              !publishChecklist
            }
          >
            Publish
          </Button>,
        ]}
        // zIndex={9999}
      >
        {loading || loadingPublish ? (
          <Spin
            spinning={loading || loadingPublish}
            tip={
              (loading && 'Validating Data...') ||
              (loadingPublish && 'Publish...')
            }
            size='large'
            style={{ display: 'block', margin: '25% auto' }}
          ></Spin>
        ) : (
          <Row>
            {columnDefs && columnDefs.length > 0 && (
              <>
                <div
                  style={{
                    width: '100%',
                    height: 'calc(65vh - 30px)',
                  }}
                  className={classnames(
                    'ag-theme-alpine',
                    'ag-theme-alpine--with-scroller'
                  )}
                >
                  <AgGridReact
                    disableStaticMarkup={true}
                    animateRows={true}
                    // columnDefsChangeDetectionStrategy={
                    //   ChangeDetectionStrategyType.NoCheck
                    // }
                    rowData={publishChecklist}
                    // rowModelType='serverSide'
                    // onGridReady={onGridReadyfunction}
                    headerHeight={32}
                    rowHeight={32}
                  >
                    {columnDefs.map((val, index) => {
                      return columnGrid(val, index);
                    })}
                  </AgGridReact>
                  <ErrorModal errorList={errorList} />
                </div>
              </>
            )}
          </Row>
        )}
      </Modal>

      <Modal
        width={'60vw'}
        bodyStyle={{ height: '70vh', padding: 0 }}
        title={'Product Package Levels'}
        visible={visibleHieararchy}
        closable={!loading}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={handleModalCancelHieararchy}
        footer={[
          <Button
            disabled={loading}
            key='cancel'
            onClick={handleModalCancelHieararchy}
          >
            Cancel
          </Button>,
          <Button
            key='select'
            type='primary'
            onClick={() => {
              handleModalSelect();
            }}
            loading={loading}
            disabled={hieararchySelect < 0}
          >
            Select
          </Button>,
        ]}
        zIndex={99999}
      >
        <Row
          className='scroller'
          style={{ height: '70vh', padding: '24px 48px', display: 'block' }}
        >
          {rowDataHieararchy &&
            rowDataHieararchy.length > 0 &&
            rowDataHieararchy.map((val, index) => {
              return (
                <Row
                  key={index}
                  onClick={() => setHieararchySelect(index)}
                  className={
                    hieararchySelect === index ||
                    (hieararchySelect === -1 &&
                      publishChecklist[publishSelect]?.productId ===
                        val?.productId &&
                      publishChecklist[publishSelect]?.productPackageLevel ===
                        val?.productPackageLevel)
                      ? 'item-hieararchy item-hieararchy--selected'
                      : 'item-hieararchy'
                  }
                  style={{ background: color[val?.hierarchyId % 10] }}
                >
                  <Col
                    span={8}
                    style={{
                      padding: 4,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={val?.productImageThumb || Images.RIVIR_LOGO_DEFAULT}
                      style={{
                        height: '150px',
                        width: 'auto',
                        maxWidth: '100%',
                      }}
                    ></img>
                  </Col>
                  <Col span={16}>
                    <Col span={24}>
                      <Typography.Title level={5} ellipsis>
                        {val?.productGTIN} - {val?.productName}
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Typography.Text ellipsis>
                        {val?.productBrandName}
                      </Typography.Text>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 4,
                      }}
                      className={setColor(
                        publishChecklist[publishSelect]?.productPackageLevel,
                        val?.productPackageLevel
                      )}
                    >
                      <Typography.Title
                        level={5}
                        ellipsis
                        className={setColor(
                          publishChecklist[publishSelect]?.productPackageLevel,
                          val?.productPackageLevel
                        )}
                      >
                        {val?.productPackageLevel}
                      </Typography.Title>
                    </Col>
                    <Row>
                      <Col span={8}>
                        <Typography.Title level={5} ellipsis>
                          Package Count
                        </Typography.Title>
                      </Col>
                      <Col span={16}>
                        <Typography.Text ellipsis>
                          {val?.productPackCount}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Typography.Title level={5} ellipsis>
                          Size
                        </Typography.Title>
                      </Col>
                      <Col span={16}>
                        <Typography.Text ellipsis>
                          {isNaN(parseFloat(val?.productSize))
                            ? ''
                            : Math.round(parseFloat(val?.productSize) * 100) /
                              100}{' '}
                          {val?.productSizeUOM}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
        </Row>
      </Modal>
      <>
        {toggleErrorListModal && (
          <PublicationValidationErrorCheck
            validationResult={publishChecklist?.[publishSelect]}
            isErrorListModalOpen={isErrorListModalOpen}
            toggleErrorListModal={toggleErrorListModal}
            getPublishCheckList={regetPublishErrorAfterSubmitErrorChecker}
            publishCheckLoading={loading}
          />
        )}
      </>
    </>
  );
};

export default PublicationValidationView;
