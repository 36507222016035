import { defineMessages } from 'react-intl';

export const scope = 'Taco.common.components.minimumRequirement';

export default defineMessages({
  createMinimumRequirement: {
    id: `${scope}.ribbon.create`,
    defaultMessage: 'Create',
  },
  editMinimumRequirement: {
    id: `${scope}.ribbon.edit`,
    defaultMessage: 'Edit',
  },
  deleteMinimumRequirement: {
    id: `${scope}.ribbon.delete`,
    defaultMessage: 'Delete',
  },
  selectEntity: {
    id: `${scope}.modal.selectEntity`,
    defaultMessage: 'Select entity',
  },
  allMembers: {
    id: `${scope}.modal.allMembers`,
    defaultMessage: 'All Members',
  },
  targetMember: {
    id: `${scope}.modal.targetMember`,
    defaultMessage: 'Target Member',
  },
  selectRequirements: {
    id: `${scope}.modal.selectRequirements`,
    defaultMessage: 'Select Requirements',
  },
  selectEntityAndTarget: {
    id: `${scope}.modal.selectEntityAndTarget`,
    defaultMessage: 'Select Entity and Target Member',
  },
  warningMessageAllMembers: {
    id: `${scope}.modal.warningMessageAllMembers`,
    defaultMessage:
      '** Minimum requirements setting has already setup for all members',
  },
  nextButton: {
    id: `${scope}.modal.nextButton`,
    defaultMessage: 'Next',
  },
  backButton: {
    id: `${scope}.modal.backButton`,
    defaultMessage: 'Back',
  },
  submitButton: {
    id: `${scope}.modal.submitButton`,
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: `${scope}.modal.cancelButton`,
    defaultMessage: 'Cancel',
  },
  requiredProperties: {
    id: `${scope}.modal.requiredProperties`,
    defaultMessage: 'Required Properties',
  },
  additionalRequirements: {
    id: `${scope}.modal.additionalRequirements`,
    defaultMessage: 'Additional Requirements',
  },
});
