import React from 'react';
import { Row, Col, Image, Typography } from 'antd';

import reactCSS from 'reactcss';

const { Text, Paragraph } = Typography;

const SecurityLabel = (props) => {
  const {
    labelImageWrapStyle = {},
    labelImageSrc,
    labelImageProps = {},
    labelTitle,
    detailText,
    hintText,
  } = props;

  const styles = reactCSS({
    default: {
      wrapper: {},
      labelInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      labelText: {
        fontSize: 14,
      },
      labelHintText: {
        wrap: {
          marginTop: 10,
        },
        content: {
          fontSize: 15,
          marginBottom: 0,
        },
      },
      imageWrap: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
        ...labelImageWrapStyle,
      },
    },
  });

  return (
    <div style={styles.wrapper}>
      <Row>
        <Col flex='48px' style={styles.imageWrap}>
          <Image src={labelImageSrc} preview={false} {...labelImageProps} />
        </Col>
        <Col style={styles.labelInfo}>
          <div>
            <Text strong style={styles.labelText}>
              {labelTitle}
            </Text>
          </div>
          <div>
            <Text style={styles.labelText}>{detailText}</Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} style={styles.labelHintText.wrap}>
          <Paragraph style={styles.labelHintText.content}>{hintText}</Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default SecurityLabel;
