import React from 'react';

import { Button, Popover, List, Checkbox } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useNode } from '@craftjs/core';

import { useEmailEditorContext } from '../../controllers/context';
import { updateDynamicContentByTag } from 'utils/email-editor';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const EmailEditorShowHideSettings = (props) => {
  const intl = useIntl();
  const { dynamicContent, setDynamicContent } = useEmailEditorContext();
  const {
    settings,
    actions: { setProp },
  } = useNode((state) => ({
    settings: state.data.props.settings,
  }));

  const { options, type } = props;

  const onCheckOption = (option) => (e) => {
    const checked = e.target.checked;

    type === 'tag' ? showTag(option, checked) : showProps(option, checked);
  };

  const showTag = (option, checked) => {
    setDynamicContent((prevState) =>
      updateDynamicContentByTag({
        prevState,
        tags: [option],
        field: 'visible',
        value: checked,
      })
    );
  };

  const showProps = (option, checked) => {
    const currentShow = settings.show;

    const newShow = checked
      ? [...currentShow, option]
      : currentShow.filter((showItem) => showItem !== option);

    setProp((props) => (props.settings.show = newShow));
  };

  const renderOptionItem = (option) => {
    const isChecked =
      type === 'tag'
        ? dynamicContent[option]?.visible
        : settings.show?.includes(option);

    return (
      <List.Item>
        <Checkbox onChange={onCheckOption(option)} checked={isChecked}>
          {intl.formatMessage(Messages[option])}
        </Checkbox>
      </List.Item>
    );
  };

  const ShowOptionList = (
    <List
      bordered={false}
      dataSource={options}
      renderItem={renderOptionItem}
      size='small'
    ></List>
  );

  return (
    <Popover trigger='click' content={ShowOptionList}>
      <Button type='text' icon={<EyeOutlined />} />
    </Popover>
  );
};

export default EmailEditorShowHideSettings;
