import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Input, Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './FreeTextSelector.less';

export const FreeTextSelector = forwardRef((props, ref) => {
  const { values, value: initValue, overlayClassName, disabled } = props;

  const [value, setValue] = useState(initValue);
  const [editing, setEditing] = useState(true);
  const refInput = useRef(null);

  const onSelectMenu = ({ key }) => {
    setValue(key);
  };

  const focus = () => {
    window.setTimeout(() => {
      if (refInput.current) {
        refInput.current.focus({ cursor: 'all' });
      }
    });
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const optionsMenu = (
    <Menu onClick={onSelectMenu}>
      {values.map((option) => {
        return <Menu.Item key={option.value}>{option.displayName}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <div className='ag-editor__free-text-selector' tabIndex={1}>
      <Input.Group compact>
        <Input
          ref={refInput}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
        <Dropdown
          overlay={optionsMenu}
          placement='bottomRight'
          trigger='click'
          overlayClassName={overlayClassName}
        >
          <Button icon={<DownOutlined />} disabled={disabled} />
        </Dropdown>
      </Input.Group>
    </div>
  );
});
