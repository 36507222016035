import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CustomNotification } from 'common/components';

import { getKeyFactPanels } from '../../multiple-panel/facts-panel/useQueryFactsPanel';
import { getKeyQaSpecFactPanels } from 'pages/qa-spec/components/qa-nutrition-data/hook';

import * as productServices from 'services/product';

import { sleep } from 'utils/delay';

const nutritionFactsApi = {
  'qa-spec': productServices.saveQaSpecDataNutritionFacts,
  overview: productServices.updateProductFactPanel,
};

export const useAddNutritionLabel = ({ productId, typeView }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['add-label'],
    mutationFn: nutritionFactsApi[typeView],
    onSuccess: async () => {
      await sleep(1000);
      queryClient.invalidateQueries({
        queryKey: getKeyFactPanels(productId),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecFactPanels(productId),
      });
    },
    onError: (error) => {
      CustomNotification.error('Something went wrong!');
    },
  });

  return mutation;
};
