import React from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { dialogFunction } from 'common/components/index';
import { DeleteOutlined } from '@ant-design/icons';
import { RibbonButton } from 'common/components';
import { deleteAdditionalRequiredFields } from 'services/recipients-fields';

import Messages from 'i18n/messages/recipients-fields';

import * as requiredFieldsActions from 'pages/recipients-fields/controllers/actions';

const DeleteFields = ({ fieldId }) => {
  const dispatch = useDispatch();

  const handleSaveRequiredFieldsSuccess = () => {
    dispatch(requiredFieldsActions.toggleReloadGrid(true));

    setTimeout(() => {
      dispatch(requiredFieldsActions.toggleReloadGrid(false));
    }, 200);
  };
  const handleDeleteModal = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the selected Required Fields?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDeleteMapping,
    });
  };

  const handleDeleteMapping = async () => {
    try {
      const response = await deleteAdditionalRequiredFields({
        ids: fieldId,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Delete Required Fields successfully!',
        });
        handleSaveRequiredFieldsSuccess();
      } else {
        notification.error({
          message: message || 'Cannot delete Required Fields!',
        });
      }
    } catch (error) {
      notification.error({
        message: error || 'Cannot delete Required Fields!',
      });
    }
  };

  return (
    <RibbonButton
      size='small'
      onClick={handleDeleteModal}
      icon={<DeleteOutlined />}
      label={Messages.deleteFields}
      disabled={fieldId?.length === 0}
    />
  );
};

export default DeleteFields;
