import React from 'react';
import { useState, useRef, useEffect } from 'react';

import insertTextAtCursor from 'insert-text-at-cursor';

import './FormInputHtml.less';

const FormInputHTML = (props) => {
  const { htmlValue, onChange, styles, isEdit = true } = props;

  const [isInit, setIsInit] = useState(false);
  const [initValue, setInitValue] = useState(null);
  const textRef = useRef();

  const inputHandler = (ev) => {
    onChange(ev.target.innerHTML);
  };

  const onPaste = (e) => {
    var pastedText = undefined;
    if (window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData('Text');
    } else if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData('text/plain');
    }
    e.preventDefault();
    insertTextAtCursor(e.target, pastedText);
  };

  useEffect(() => {
    //* set init Value
    const initValue = () => {
      if (!isInit) {
        setInitValue(htmlValue);
        setIsInit(true);
      }
    };

    initValue();
  }, [htmlValue, isInit]);

  return (
    <>
      <div
        className='form-input-html'
        contentEditable={isEdit}
        suppressContentEditableWarning
        onInput={inputHandler}
        dangerouslySetInnerHTML={{ __html: initValue }}
        ref={textRef}
        style={styles}
        onPaste={onPaste}
      ></div>
    </>
  );
};

export default FormInputHTML;
