import React from 'react';
import reactCSS from 'reactcss';

import { Button } from 'antd';

import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const styles = reactCSS({
  default: {
    lock: {
      fontSize: 19,
      cursor: 'pointer',
      color: '#ff4d4f',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 3,
      borderRadius: 3,
      marginLeft: 8,
    },
    unlock: {
      fontSize: 19,
      cursor: 'pointer',
      color: '#389e0d',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 3,
      borderRadius: 3,
      marginLeft: 8,
    },
  },
});

const LockFormItemInput = (props) => {
  const { onChange, value, onChangeLock, dataPointId, entityId } = props;

  const handleChange = (newValue) => {
    onChange(newValue);
    onChangeLock({ dataPointId, value: newValue, thisLockEntityId: entityId });
  };

  return (
    <Button
      style={value ? styles.lock : styles.unlock}
      onClick={() => handleChange(!value)}
    >
      {value ? <LockOutlined /> : <UnlockOutlined />}
    </Button>
  );
};

export default LockFormItemInput;
