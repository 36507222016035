import React from 'react';
import PropTypes from 'prop-types';

import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import { ViewLayout, AdvanceStack } from './components';

const HomeBlank = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <AdvanceStack isDisabled />
      <RibbonDivider />
    </RibbonBar>
  );
};

HomeBlank.propTypes = {
  onSearch: PropTypes.func,
  toggleSearch: PropTypes.bool,
};

export default HomeBlank;
