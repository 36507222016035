import { useEffect } from 'react';

import { useAsync } from 'hooks';

import { getAllFormTemplates } from 'services/maintenanceForm';
import { set } from 'lodash';

export const useGetAllFormTemplates = ({ entityType, isEnabled }) => {
  const { data, status, run } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(getAllFormTemplates({ entityType }));
    }
    return;
  }, [entityType, isEnabled, run]);

  return {
    formTemplates: data?.formTemplates ?? [],
    isLoading: status === 'idle' || status === 'pending',
  };
};

export const useInitExportManualForm = ({ manualFields, formInstance }) => {
  useEffect(() => {
    //* init form
    const initManualForm = () => {
      if (!manualFields || manualFields?.length === 0) return;

      let initFormValue = {};

      manualFields.forEach((manualFieldItem) => {
        const { entityId, manualData } = manualFieldItem;

        manualData.forEach((manualDataItem) => {
          const { dataPointId, isLoop, lock } = manualDataItem;

          set(initFormValue, `[${entityId}][${dataPointId}].value`, null);
          set(initFormValue, `[${entityId}][${dataPointId}].isLoop`, isLoop);
          set(initFormValue, `[${entityId}][${dataPointId}].lock`, lock);
        });
      });

      formInstance.setFieldsValue(initFormValue);
    };

    initManualForm();
  }, [JSON.stringify(manualFields), formInstance]);
};
