export const GET_MEETING_USERS_GRID = '/api/meeting/get-meeting-users-grid';
export const GET_MEETING_USERS_GRID_DISTINCT =
  '/api/meeting/get-meeting-users-grid/distinct';

export const CREATE_NEW_MEETING = '/api/meeting/create-new-meeting';
export const UPDATE_MEETING = '/api/meeting/update-meeting';
export const CANCEL_MEETING = '/api/meeting/cancel-meeting';

export const GET_MEETING_DETAILS = '/api/meeting/get-meeting-details';

export const GET_SCHEDULE_LIST = '/api/meeting/get-meetings-list';
export const ACCEPT_OR_REJECT_MEETING = '/api/meeting/accept-or-reject-meeting';

export const GET_PROJECTS_GRID = '/api/meeting/get-projects-grid';

export const GET_MEETING_DETAIL = '/api/meeting/get-meeting-details';

export const END_MEETING = '/api/meeting/end-meeting';
export const START_MEETING = '/api/meeting/start-meeting';
export const KICK_OFF_MEETING = '/api/meeting/kick-off-meeting';
