import { put, call, all } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import * as types from './constants';
import * as actions from './actions';
import * as services from 'services/product';
import * as servicesGrid from 'services/grid';
import * as servicesMembers from 'services/members';

import { formatMDYWithParam } from 'utils/formatDate';

import { DEFAULT_SORT, DEFAULT_PRODUCT_COLUMNS } from 'static/Constants';

import customCellComponents from '../components/custom-cell-components/customCellComponents';

export function* getProductList(payload) {
  const {
    pageIndex,
    pageSize,
    search,
    filters,
    advancedSearchContainer,
    searchCategory,
    isFavoriteRoute,
    packageLevels,
    fromDate,
  } = payload;

  try {
    const params = {
      filters: filters || [],
      pageIndex: pageIndex,
      pageSize: pageSize,
      search: { searchText: search },
      advancedSearchContainer: advancedSearchContainer ?? {},
      packageLevels: packageLevels,
      searchCategory: searchCategory,
      isFavoriteRoute: isFavoriteRoute,
      ...(search ? null : { sort: DEFAULT_SORT }),
      fromDate: fromDate ? fromDate : null,
    };

    const response = yield call(services.getProductListService, params);

    if (response?.isSuccess) {
      const products = response?.data?.gridData;
      const count = response?.data?.paging?.totalRecord;
      yield put(actions.getProductListSuccess(products, count));
    }
  } catch (error) {
    yield put(actions.getProductListError(error));
  }
}

export function* getMemberProductListSaga(payload) {
  const {
    pageIndex,
    pageSize,
    search,
    filters,
    advancedSearchContainer,
    searchCategory,
    packageLevels,
    isFavoriteRoute,
    fromDate,
    memberId,
  } = payload;

  try {
    const params = {
      filters: filters || [],
      pageIndex: pageIndex,
      pageSize: pageSize,
      search: { searchText: search },
      advancedSearchContainer: advancedSearchContainer,
      packageLevels: packageLevels,
      isFavoriteRoute: isFavoriteRoute,
      searchCategory: searchCategory,
      ...(search ? null : { sort: DEFAULT_SORT }),
      fromDate,
      memberId,
    };

    const response = yield call(services.getProductListService, params);

    if (response?.isSuccess) {
      const products = response?.data?.gridData;
      const count = response?.data?.paging?.totalRecord;
      yield put(actions.getProductListSuccess(products, count));
    }
  } catch (error) {
    yield put(actions.getProductListError(error));
  }
}

export const formatProductDetailColumns = (response) => {
  let columns = [
    { field: '', checkboxSelection: true, filter: false, suppressMenu: true },
    // {
    //   field: '',
    //   cellRenderer: onFavoriteStarIconRender,
    //   filter: false,
    //   suppressMenu: true,
    //   width: 50,
    //   resizable: true,
    // },

    {
      field: 'productThumbnail',
      fieldNameCamelCase: 'productThumbnail',
      width: 70,
      cellRenderer: customCellComponents.renderThumbnail,
      filter: false,
      resizable: false,
      allowSort: false,
      headerClass: 'header-text-hide',
    },
    {
      field: 'stateIcons',
      fieldNameCamelCase: 'stateIcons',
      headerName: 'States',
      minWidth: 150,
      cellRenderer: customCellComponents.renderIcons,
      filter: false,
      resizable: true,
      allowSort: false,
      headerClass: 'header-text-hide',
    },
  ];

  if (response?.columns?.length > 0) {
    response.columns.forEach((val) => {
      if (!DEFAULT_PRODUCT_COLUMNS.includes(val.fieldNameCamelCase)) {
        return;
      }

      const { dataType, fieldNameCamelCase } = val || {};

      if (dataType === 'datetime') {
        val = {
          ...val,
          minWidth: 150,
          cellRenderer: formatMDYWithParam,
          resizable: true,
        };
      } else {
        val = {
          ...val,
          minWidth: 150,
          resizable: true,
        };
      }

      if (fieldNameCamelCase === 'productName') {
        columns.splice(3, 0, {
          ...val,
          linkTo: '/product/{id}',
          minWidth: 300,
        });

        return;
      }

      // if (fieldNameCamelCase === 'sharedSupplierNames') {
      //   val = {
      //     ...val,
      //     displayName: 'Supplier Name',
      //   };
      // }

      if (fieldNameCamelCase === 'rivirId') {
        val = {
          ...val,
          displayName: 'RIVIR ID',
        };
      }

      if (fieldNameCamelCase === 'primarySizeUOM') {
        val = {
          ...val,
          minWidth: 150,
          cellRenderer: customCellComponents.renderPrimarySizeUOM,
          resizable: true,
        };
      }

      columns.push(val);
    });
  }

  return columns;
};

export function* gridColumnInfo(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);

    const columns = formatProductDetailColumns(response);

    yield put(actions.gridColumnInfoSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnInfoError(error));
  }
}

export function* getColumnsFilter(payload) {
  const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
  // const filter = yield call(getFilter, response);
  return { response };
}

export function* getFilter(response) {
  const filter = yield all(
    response.columns &&
      response.columns.length > 0 &&
      response.columns.map((val) => {
        return call(servicesMembers.getMemberContactDistinct, {
          memberId: 11,
          fieldName: val.fieldName,
        });
      })
  );
  return filter;
}

export function* addProductSage(action) {
  try {
    const response = yield call(services.addProduct, action.params);

    if (response?.isSuccess) {
      yield put(actions.addProductSuccess(response?.data));
    } else {
      yield put(actions.addProductError(response?.message));
    }
  } catch (error) {
    yield put(actions.addProductError(error));
  }
}

// Get brand image on add product
export function* getProductBrandImageOnAddSaga(action) {
  try {
    const response = yield call(services.getProductBrandOnAdd, action.payload);
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getProductBrandImageOnAddSuccess(data));
    } else {
      yield put(actions.getProductBrandImageOnAddError(message));
    }
  } catch (error) {
    yield put(actions.getProductBrandImageOnAddError('error'));
  }
}

// Get GDSN Party List
export function* getGDSNPartyListSaga(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);
    let columns = [
      {
        filter: false,
        suppressMenu: true,
        resizable: true,
        checkboxSelection: true,
      },
    ];

    if (response?.columns?.length > 0) {
      let hiddenCol = ['partyCountryCode'];
      response.columns.forEach((val) => {
        if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
          // eslint-disable-next-line no-lone-blocks

          if (val.fieldNameCamelCase === 'PartyGln') {
            val = {
              ...val,
              width: 150,
              resizable: true,
            };
          } else {
            val = { ...val, width: 150, resizable: true };
          }
          if (val.fieldNameCamelCase === 'partyName') {
            val = {
              ...val,
              width: 150,
              resizable: true,
            };
          }
          if (val.fieldNameCamelCase === 'partyCountryName') {
            val = {
              ...val,
              minWidth: 150,
              flex: 1,
              resizable: true,
            };
          }

          columns.push(val);
        }
      });
    }

    yield put(actions.getGDSNPartyListSuccess(columns));
  } catch (error) {
    yield put(actions.getGDSNPartyListFailure(error));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchProduct() {
  yield takeLatest(types.GET_PRODUCT_LIST, getProductList);
  yield takeLatest(types.GET_MEMBER_PRODUCT_LIST, getMemberProductListSaga);
  yield takeLatest(types.PRODUCT_GRID_COLUMN_INFO, gridColumnInfo);
  yield takeLatest(types.ADD_PRODUCT, addProductSage);
  yield takeLatest(
    types.GET_PRODUCT_BRAND_ON_ADD,
    getProductBrandImageOnAddSaga
  );
  yield takeLatest(types.GET_GDSN_PARTY_LIST, getGDSNPartyListSaga);
}
