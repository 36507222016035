import { useQuery } from '@tanstack/react-query';

import * as ticketingServices from 'services/ticketingSystem';

export const useGetTicketTags = (props) => {
  const { enabled = false, ...restProps } = props;

  const getAllTicketTags = async () => {
    const response = await ticketingServices.getAllTicketTags();

    const { isSuccess, data } = response;

    return isSuccess && data ? data?.tags : [];
  };

  const query = useQuery({
    queryKey: ['all-ticket-tags'],
    queryFn: getAllTicketTags,
    enabled,
    initialData: [],
    ...restProps,
  });

  return query;
};
