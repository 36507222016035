import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CloneTaskControl from '../../controls/wf-template-full/CloneTaskControl';
import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import * as api from 'config/axios';
import { notification } from 'antd';
import * as services from 'services/wfTemplate';
import * as wfTemplateActions from 'pages/workflow-template-full/controllers/actions';

const CloneTaskSection = (props) => {
  const { stepId, wfId, id, mainWorkflowId } = props;
  const dispatch = useDispatch();
  const onCloneTask = () => {
    const params = {
      workflowTaskId: id,
      workflowTemplateId: wfId,
      workflowStepId: stepId,
    };
    services
      .cloneWorkFlowTask(params)
      .then((response) => {
        if (response?.isSuccess) {
          dispatch(wfTemplateActions.getWfTemplateVersions(mainWorkflowId));
          dispatch(wfTemplateActions.setActiveStepKey(stepId));
          notification.success({ message: 'Clone Workflow Task success' });
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        // setTimeout(() => {
        //   dispatch(actionsWf.reloadGridWf(false));
        // });
      });
  };

  return <CloneTaskControl onClick={onCloneTask} />;
};

export default CloneTaskSection;
