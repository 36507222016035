import { useSelector } from 'react-redux';

import selectorUser from 'redux/user/selectors';

const useCheckDisableWithdrawPublicationModal = (productData) => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const isNotOwner = !productData?.isOwner;
  const isNotSyndication = !productData?.syndication;
  const isNotSubscribeShield =
    productData?.subscriptionShield !== 'None' ||
    productData?.subscriptionShield === 'NULL';
  const isGdsnShield = productData?.gdsnShield !== null;

  if (!productData) return true;

  if (!userInfo?.isRivirGdsnParty) return true;

  if (isNotOwner || isNotSubscribeShield || isGdsnShield || isNotSyndication) {
    return true;
  }

  return false;
};

export { useCheckDisableWithdrawPublicationModal };
