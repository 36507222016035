import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { FileSyncOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import { toggleReplacePreview } from 'pages/home/ribbon/asset-full/controllers/actions';

import { useCheckAllowEditAssets } from 'hooks';

const ReplacePreviewFull = (props) => {
  // props
  const { sectionDisabled, isReplacing, isIframe } = props;
  // state
  const dispatch = useDispatch();

  const { checkAllowEditAssetFull } = useCheckAllowEditAssets();
  const isAllowEdit = checkAllowEditAssetFull();

  const isDisabledBtn = checkDisabledButton(
    isIframe,
    isAllowEdit,
    sectionDisabled,
    isReplacing
  );

  const onClickHandler = () => {
    dispatch(toggleReplacePreview());
  };

  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<FileSyncOutlined />}
      label={Messages.replacePreview}
      onClick={onClickHandler}
      disabled={isDisabledBtn}
    />
  );
};

const checkDisabledButton = (
  isIframe,
  isAllowEdit,
  selectionDisabled,
  isReplacing
) => {
  if (isIframe) return true;

  if (!isReplacing) {
    if (!isAllowEdit) return true;
    if (selectionDisabled) return true;
  }

  return false;
};

ReplacePreviewFull.propTypes = {
  sectionDisabled: PropTypes.bool,
  isReplacing: PropTypes.bool,
};

export default ReplacePreviewFull;
