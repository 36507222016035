import { SnapshotFormLabel } from './FormLabel';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import { formatCompareValue } from 'pages/qa-spec/utils';
import { get, isEqual } from 'lodash';

const useGetSnapshotFormItemWrapperProps = ({ snapshotValues }) => {
  const useSnapshot = useCheckSnapshotForRetailer();

  const getSnapshotFormItemWrapperProps = ({ fieldPath, label }) => {
    const snapshotFieldValue = get(snapshotValues, fieldPath);
    const procSnapshotFieldValue = formatCompareValue(snapshotFieldValue);

    return {
      snapshotValue: (
        <SnapshotFormLabel
          label={label}
          snapshotFieldValue={procSnapshotFieldValue}
        />
      ),
      checkShowSnapshotFn: ({ value }) => {
        const procValue = formatCompareValue(value);

        const isShowSnapshot =
          !isEqual(procValue, procSnapshotFieldValue) &&
          useSnapshot &&
          snapshotValues;

        return Boolean(isShowSnapshot);
      },
    };
  };

  return { getSnapshotFormItemWrapperProps };
};

export { useGetSnapshotFormItemWrapperProps };
