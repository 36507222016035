import React from 'react';
import RibbonButton from 'common/components/button/RibbonButton';

const ToggleUploadMediaBtn = ({ clickActionCallback, label, icon }) => {
  return (
    <RibbonButton icon={icon} label={label} onClick={clickActionCallback} />
  );
};

export default ToggleUploadMediaBtn;
