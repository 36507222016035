import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as globalActions from 'redux/global/actions';

export const useInitRibbonBar = (props = {}) => {
  const { option, searchCriteria } = props;

  const dispatch = useDispatch();

  const ribbonDom = document.querySelector('[data-ribbon-ref]');

  const updateRibbon = () => {
    dispatch(globalActions.changeRibbonActions({ ...option, value: 'NONE' }));
  };

  const updateSearchCriteria = () => {
    searchCriteria
      ? dispatch(globalActions.updateSearchCriteria(searchCriteria))
      : dispatch(globalActions.hideSearchBar());
  };

  useEffect(() => {
    updateRibbon();
    updateSearchCriteria();
  }, []);

  return { ribbonDom };
};
