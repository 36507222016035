import { Form, Input, Button } from 'antd';
import { StyledModal } from 'common/components';
import FormSaveButton from 'common/components/button/FormSaveButton';

import { useAcceptOrRejectQaSpecWorkflow } from 'pages/qa-spec-workflow/hook';
const { TextArea } = Input;

const QaSpecWorkflowReasonModal = ({
  onRejectTodo,
  showModalReason,
  setShowModalReason,
}) => {
  const [formInstance] = Form.useForm();

  const acceptOrRejectWorkflowMutation = useAcceptOrRejectQaSpecWorkflow();

  const onOkSubmit = () => {
    formInstance.validateFields().then((rejectReason) => {
      onRejectTodo(rejectReason);
    });
  };
  const onCancelSubmit = () => {
    setShowModalReason(false);
  };

  const modalFooter = [
    <Button
      onClick={onCancelSubmit}
      disabled={acceptOrRejectWorkflowMutation.isLoading}
    >
      Cancel
    </Button>,
    <FormSaveButton
      text='Submit'
      onClick={onOkSubmit}
      loading={acceptOrRejectWorkflowMutation.isLoading}
    />,
  ];

  return (
    <StyledModal
      title='Reject Reason Modal'
      visible={showModalReason}
      onCancel={onCancelSubmit}
      maskClosable={false}
      destroyOnClose={true}
      footer={modalFooter}
    >
      <Form form={formInstance}>
        <Form.Item
          label='Reject Reason'
          name='rejectReason'
          rules={[
            {
              required: true,
              message: 'Please input your Reject Reason!',
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default QaSpecWorkflowReasonModal;
