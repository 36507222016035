import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Input,
  Checkbox,
  Select,
  Tabs,
  Col,
  Row,
  Tag,
  InputNumber,
  DatePicker,
} from 'antd';
import classnames from 'classnames';

import {
  Form,
  dialogFunction,
  WrapperSelect,
  AssetTypeEdit,
} from 'common/components';
import AssetCreator from '../metadata-panel/AssetCreator';
import iconList from 'common/components/icon-list/assets';
import { ConfirmContentAsEdit } from 'pages/asset-full-view/components';

import * as globalSelectors from 'redux/global/selectors';
import * as actionsGlobal from 'redux/global/actions';
import userSelectors from 'redux/user/selectors';
import * as actionsRibbon from 'pages/home/ribbon/asset-full/controllers/actions';
import * as companySelectors from 'pages/company-profile/controllers/selectors';
import memberReducer from 'pages/company-profile/controllers/reducer';
import saga from 'pages/company-profile/controllers/saga';
import * as memberActions from 'pages/company-profile/controllers/actions';

import assetSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';
import * as actions from '../../controllers/actions';
import * as selectors from '../../controllers/selectors';

import * as constants from 'static/Constants';

import { FormattedMessage, injectIntl } from 'react-intl';
import Messages from 'i18n/messages/asset-full-view.js';
import messages from 'i18n/messages/assetDetail';

import sortByKey from 'utils/sortByKey';
import { exportFormDateWithoutTime, formatMDY } from 'utils/formatDate';
import { getDateFormat } from 'utils/formatDate';
import { formatSizeUnits } from 'utils/formatSizeUnits';
import { getParameterFromQuery } from 'utils/common/route';

import { useInjectSaga } from 'utils/common/injectSaga';
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useValidateStatusField } from 'hooks/useValidateStatusField';
import { useGetMemberHeaderFromMemberId } from 'common/queries/member-module';

import './AssetEdit.less';

const { RangePicker } = DatePicker;

const formItemOverView = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const AssetEdit = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useInjectReducer({ key: 'member', reducer: memberReducer });
  useInjectSaga({ key: 'member', saga });

  const [form] = Form.useForm();

  const { assetData, openItemFormData, intl } = props;
  const [typeData, setTypeData] = useState({
    assetType: assetData?.assetType,
    assetSubType: assetData?.assetSubType,
    packageLevel: assetData?.metadata?.packageLevel || assetData?.packageLevel,
    packagingDisposition:
      assetData?.metadata?.packagingDisposition ||
      assetData?.packagingDisposition,
    angle: assetData?.metadata?.angle || assetData?.angle,
    facing: assetData?.metadata?.facing || assetData?.facing,
    language: assetData?.metadata?.language || assetData?.language,
    panelType: assetData?.metadata?.panelType || assetData?.panelType,
    logoType: assetData?.metadata?.logoType || assetData?.logoType,
    certificateType:
      assetData?.metadata?.certificateType || assetData?.certificateType,
    documentType: assetData?.metadata?.documentType || assetData?.documentType,
  });
  const [tagsMode, setTagsMode] = useState('tags');
  const [tags, setTags] = useState([]);

  const saving = useSelector(assetSelectors.selectAssetSaving());
  const requiredFields = useSelector(selectors.selectRequiredFields());
  const authorizedTagsInfo = useSelector(selectors.selectAuthorizedTags());
  const isEdit = useSelector(assetSelectors.selectAssetEdit);
  const isAddingOpenItemFormData = useSelector(
    globalSelectors.selectIsAddingOpenItemFormData()
  );
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const memberInfo = useSelector(companySelectors.selectMemberProfile());

  const isCreating = Boolean(getParameterFromQuery('draft'));

  const { enforceTagRestrictions, authorizedTags } = authorizedTagsInfo;

  const { statusValidateProps } = useValidateStatusField({
    form,
    entityType: 'Asset',
    entityId: assetData?.id,
    placement: 'bottomRight',
  });

  const { data: memberHeaderOfAssetOwner } = useGetMemberHeaderFromMemberId({
    memberId: assetData?.ownerId,
    enabled: Boolean(assetData && assetData?.ownerId),
  });

  const isPrivateVisibilityAsset =
    memberHeaderOfAssetOwner?.digitalAssets?.toLowerCase() === 'private';

  useEffect(() => {
    dispatch(memberActions.getMemberProfileHeader(userInfo?.member?.id));
  }, [dispatch, userInfo?.member?.id]);

  useEffect(() => {
    let classifications = [];
    assetData?.classifications?.length > 0 &&
      assetData.classifications.forEach((val) => {
        let segmentFilter = iconList.filter(
          (item) => item.segmentId === val.segmentId
        );
        if (segmentFilter?.length > 0 && segmentFilter[0]?.segmentDescription) {
          classifications.push(segmentFilter[0].segmentDescription);
        }
      });
    let additionalSearchTags = assetData?.additionalSearchTags
      ? assetData?.additionalSearchTags
          .toString()
          .split(',')
          .filter((tag) => tag !== '')
      : [];
    let exifData = assetData?.metadata?.exifData === 'Yes' ? true : false;

    !openItemFormData &&
      form.setFieldsValue({
        assetName: assetData?.assetName,
        //TODO: DATETIME
        effectiveTime: [
          getDateFormat(assetData?.effectedDate),
          getDateFormat(assetData?.expirationDate),
        ],
        ...typeData,
        assetDescription: assetData?.assetDescription,
        additionalSearchTags: additionalSearchTags,
        status: assetData?.status,
        classifications: classifications,
        channelOutput:
          assetData?.metadata?.channelOutput || assetData?.channelOutput,
        clippingPath:
          assetData?.metadata?.clippingPath || assetData?.clippingPath,
        colorDepth: assetData?.metadata?.colorDepth || assetData?.colorDepth,
        colorModel: assetData?.metadata?.colorModel || assetData?.colorModel,
        cropRange: assetData?.metadata?.cropRange || assetData?.cropRange,
        duration: assetData?.metadata?.duration || assetData?.duration,
        exifData: assetData?.metadata?.exifData || assetData?.exifData,
        fileName: assetData?.metadata?.fileName || assetData?.fileName,
        fileSize: formatSizeUnits(
          assetData?.metadata?.fileSize || assetData?.fileSize
        ),
        fileType: assetData?.metadata?.fileType || assetData?.fileExtension,
        fps: assetData?.metadata?.fps || assetData?.fps,
        bitRate: assetData?.metadata?.bitRate || assetData?.bitRate,
        frameRate: assetData?.metadata?.frameRate || assetData?.frameRate,
        frameSize: assetData?.metadata?.frameSize || assetData?.frameSize,
        sampleRate: assetData?.metadata?.sampleRate || assetData?.sampleRate,
        chanelOutput:
          assetData?.metadata?.chanelOutput || assetData?.chanelOutput,
        height: assetData?.metadata?.height || assetData?.height,
        lastModified:
          assetData?.metadata?.lastModified || assetData?.lastModified,
        loadedDate: assetData?.metadata?.loadedDate || assetData?.loadedDate,
        mediaFormat: assetData?.metadata?.mediaFormat || assetData?.mediaFormat,
        owner: assetData?.metadata?.owner || assetData?.owner,
        pageCount: assetData?.metadata?.pageCount || assetData?.pageCount,
        resolution: assetData?.metadata?.resolution || assetData?.resolution,
        width: assetData?.metadata?.width || assetData?.width,
        isShowcase: assetData?.isShowcase,
        visibility: assetData?.visibility || constants.ASSET_VISIBILITY[2],
      });
    openItemFormData && form.setFieldsValue(openItemFormData);
  }, [assetData, openItemFormData]);

  const handleSubmitForm = useCallback(
    (formInstance) => {
      const formData = formInstance.getFieldsValue();
      const effDate = formData?.effectiveTime?.[0]?.$d;
      const expDate = formData?.effectiveTime?.[1]?.$d;

      if (effDate > new Date()) {
        dialogFunction({
          type: 'warn',
          content: <ConfirmContentAsEdit expDate={expDate} effDate={effDate} />,
          onOk: () => {
            formInstance.submit();
          },
          onCancel: () => {
            dispatch(actionsRibbon.cancelSavingAsset());
          },
          okText: 'Ok',
          width: 465,
        });
      } else {
        formInstance.submit();
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (saving) {
      handleSubmitForm(form);
    }
  }, [saving, form, handleSubmitForm, dispatch]);

  const getFormData = (form) => {
    dispatch(actionsGlobal.updateOpenItemForm(form.getFieldsValue()));
  };

  useEffect(() => {
    if (isAddingOpenItemFormData) getFormData(form);
  }, [isAddingOpenItemFormData]);

  useEffect(() => {
    if (assetData?.ownerId) {
      dispatch(actions.getAuthorizedTags(assetData?.ownerId));
    }
  }, [assetData?.ownerId, dispatch]);

  useEffect(() => {
    if (enforceTagRestrictions) {
      setTagsMode('multiple');
    }
  }, [enforceTagRestrictions]);

  useEffect(() => {
    if (enforceTagRestrictions) {
      setTags(onSetFilterTags());
    } else {
      setTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enforceTagRestrictions,
    authorizedTagsInfo,
    typeData?.assetType,
    typeData?.assetSubType,
  ]);

  const onSetFilterTags = () => {
    return (
      authorizedTags &&
      authorizedTags.filter((tag) => {
        if (tag?.assetType) {
          if (tag?.assetSubType) {
            return (
              tag.assetType === typeData?.assetType &&
              tag.assetSubType === typeData?.assetSubType
            );
          } else {
            return tag.assetType === typeData?.assetType;
          }
        } else {
          return tag;
        }
      })
    );
  };

  const onFinishFailed = (errorInfo) => {
    dispatch(actionsRibbon.cancelSavingAsset());
  };

  const onFinish = (values) => {
    // update additionalSearchTags from string to list string

    let classifications = [];
    values?.classifications?.length > 0 &&
      values.classifications.forEach((val) => {
        let segmentId = iconList.filter(
          (item) => item.segmentDescription === val
        );
        if (segmentId?.length > 0 && segmentId[0]?.segmentId) {
          classifications.push({ segmentId: segmentId[0].segmentId });
        }
      });
    let params = Object.assign(values, {
      id: assetData.id,
      draftAssetId: assetData?.pendingAssetId, // After deleting draft status, draftAssetId is got from pendingAssetId
      assetType: assetData.assetType,
      ...typeData,
    });
    params.classifications = classifications;
    //? set effective date
    if (params.effectiveTime) {
      const startTime = params.effectiveTime?.[0]?.$d;
      const endTime = params.effectiveTime?.[1]?.$d;
      // params.effectedDate = startTime ? startTime.toISOString() : null;
      // params.expirationDate = endTime ? endTime.toISOString() : null;
      params.effectedDate = startTime
        ? exportFormDateWithoutTime(startTime)
        : null;
      params.expirationDate = endTime
        ? exportFormDateWithoutTime(endTime)
        : null;
    }
    delete params.effectiveTime;
    if (assetData?.metadata?.fileSize || assetData?.fileSize) {
      params.fileSize = assetData?.metadata?.fileSize || assetData?.fileSize;
    }
    if (assetData?.isIFrame) params.isIFrame = true;
    if (history.location.search.indexOf('?replace-asset') > -1) {
      dispatch(actions.updateDigitalReplaceAsset(params));
    } else {
      dispatch(actions.updateDigitalAsset(params));
    }
  };
  useEffect(() => {
    if (requiredFields?.length) {
      const isReplaceAsset =
        history.location.search.indexOf('?replace-asset') > -1;
      const status = isReplaceAsset
        ? 'Replace'
        : isCreating
        ? 'Create'
        : 'Edit';
      validateStatusField();
      dispatch(actions.updateRequiredField([]));
    }
  }, [requiredFields, dispatch]);

  const validateStatusField = () => {
    const event = new CustomEvent(constants.EVENT.VALIDATE_STATUS_FIELD);
    document.dispatchEvent(event);
  };

  const assetStatus = isCreating
    ? constants.ASSET_CREATE_STATUS_OPTIONS
    : constants.ASSET_STATUS_OPTIONS;

  const assetOverview = (data) => {
    let assetFieldOverview;
    let type = data?.fileType || data?.fileExtension;
    if (type === 'zip' || type === 'rar' || type === '7z') {
      assetFieldOverview = constants.ASSET_FIELD_ZIP;
    } else if (
      type === 'mkv' ||
      type === 'mp4' ||
      type === 'avi' ||
      type === 'mov' ||
      type === 'flv' ||
      type === 'wav'
    ) {
      assetFieldOverview = constants.ASSET_FIELD_VIDEO;
    } else if (type === 'mp3' || type === 'ogg') {
      assetFieldOverview = constants.ASSET_FIELD_AUDIO;
    } else if (
      type === 'jpg' ||
      type === 'jpeg' ||
      type === 'png' ||
      type === 'gif' ||
      type === 'tiff' ||
      type === 'psd' ||
      type === 'bmp' ||
      type === 'ico' ||
      type === 'tga'
    ) {
      assetFieldOverview = constants.ASSET_FIELD_IMAGE;
    } else {
      assetFieldOverview = constants.ASSET_FIELD_DOCUMENT;
    }

    return (
      <>
        {assetFieldOverview?.length > 0 &&
          assetFieldOverview.map((val, i) => {
            if (val.element === 'text') {
              if (val.type === 'checkbox') {
                return (
                  <Col span={12} key={i}>
                    <Form.Item
                      {...formItemOverView}
                      name={val.field}
                      label={<FormattedMessage {...messages[val.field]} />}
                      valuePropName='checked'
                    >
                      <Checkbox disabled />
                    </Form.Item>
                  </Col>
                );
              } else {
                return (
                  <Col span={12} key={i}>
                    <Form.Item
                      {...formItemOverView}
                      name={val.field}
                      label={<FormattedMessage {...messages[val.field]} />}
                    >
                      <Input disabled></Input>
                    </Form.Item>
                  </Col>
                );
              }
            }
            if (val.element === 'input' && val.type === 'string') {
              if (val.field === 'fileName') {
                return (
                  <Col span={12} key={i}>
                    <Form.Item
                      {...formItemOverView}
                      name={val.field}
                      label={<FormattedMessage {...messages[val.field]} />}
                      rules={[
                        {
                          required: true,
                          message: 'Please input file name',
                        },
                      ]}
                    >
                      <Input disabled></Input>
                    </Form.Item>
                  </Col>
                );
              } else {
                return (
                  <Col span={12} key={i}>
                    <Form.Item
                      {...formItemOverView}
                      name={val.field}
                      label={<FormattedMessage {...messages[val.field]} />}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                );
              }
            }
            if (val.element === 'input' && val.type === 'checkbox') {
              return (
                <Col span={12} key={i}>
                  <Form.Item
                    {...formItemOverView}
                    name={val.field}
                    label={<FormattedMessage {...messages[val.field]} />}
                    valuePropName='checked'
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              );
            }
            if (val.element === 'input' && val.type === 'number') {
              return (
                <Col span={12} key={i}>
                  <Form.Item
                    {...formItemOverView}
                    name={val.field}
                    label={<FormattedMessage {...messages[val.field]} />}
                  >
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              );
            }
            if (val.element === 'textarea') {
              return (
                <Col span={12} key={i}>
                  <Form.Item
                    {...formItemOverView}
                    name={val.field}
                    label={<FormattedMessage {...messages[val.field]} />}
                  >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
                  </Form.Item>
                </Col>
              );
            }
          })}
      </>
    );
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const src = iconList.filter((val) => val.segmentDescription === value);
    return (
      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        <img
          style={{ width: 20, height: 20, marginRight: 4 }}
          src={src && src[0]?.segmentIcon}
          alt={label}
        />
      </Tag>
    );
  };

  // bp 12/13/2020 - fix data subtype is required but is not validated
  const onValuesChange = (changedValues, allValues) => {
    setTypeData(allValues);
  };

  return (
    <Form
      form={form}
      name='asset-edit'
      className={classnames('asset-edit', [constants.OPEN_ITEM_CLASS])}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      id='asset-edit'
      style={{ position: 'relative' }}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col span={15}>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name='assetName'
                label={<FormattedMessage {...messages.assetName} />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(
                      messages.assetNameRequiredMessage
                    ),
                  },
                  {
                    whitespace: true,
                    message: intl.formatMessage(
                      messages.assetNameWhiteSpaceMessage
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage(
                    messages.assetNamePlaceholder
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={9} className='asset-edit__form'>
              <AssetCreator
                name={assetData?.creatorBranding?.serviceProviderName}
                created={assetData?.creatorBranding?.serviceProviderCreated}
                loaded={assetData?.loadedDate}
                modified={assetData?.dateModified}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={15}>
              <Form.Item
                name='effectiveTime'
                label='Effective Time'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <RangePicker
                  className='asset-edit__range-picker'
                  format={constants.DATE_FORMAT.SYSTEM_FORMAT}
                  allowEmpty={[true, true]}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 14 }}
                name='isShowcase'
                valuePropName='checked'
                label={<FormattedMessage {...messages.isShowcase} />}
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Item
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
        name='status'
        label={<FormattedMessage {...messages.assetStatus} />}
        {...statusValidateProps}
      >
        <WrapperSelect
          getPopupContainer={() => document.getElementById('asset-edit')}
          allowClear={false}
        >
          {assetStatus
            ?.slice()
            .sort()
            .map((status, index) => {
              return (
                <Select.Option key={index} value={status}>
                  {status}
                </Select.Option>
              );
            })}
        </WrapperSelect>
      </Form.Item>

      <Form.Item
        name='assetDescription'
        label={<FormattedMessage {...messages.assetDescription} />}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
      >
        <Input.TextArea
          className='asset-edit__asset-des'
          autoSize={{ minRows: 8, maxRows: 8 }}
          placeholder=''
        />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name='additionalSearchTags'
        label={<FormattedMessage {...messages.additionalSearchTags} />}
      >
        <WrapperSelect
          mode={tagsMode}
          tokenSeparators={[',']}
          getPopupContainer={() => document.getElementById('asset-edit')}
        >
          {tags &&
            tags.length > 0 &&
            sortByKey(tags, 'tagName')?.map((tag, index) => {
              return (
                <Select.Option key={index} value={tag?.tagName}>
                  {tag?.tagName}
                </Select.Option>
              );
            })}
        </WrapperSelect>
      </Form.Item>

      <Tabs defaultActiveKey='overview'>
        <Tabs.TabPane tab='Overview' key='overview'>
          <Row>
            <div style={{ width: '100%' }}>
              <AssetTypeEdit
                form={form}
                typeData={typeData}
                forMemberDocument={assetData?.forMemberDocument}
                setTypeData={setTypeData}
                onValuesChange={(changedValues, allValues) =>
                  onValuesChange(changedValues, allValues)
                }
              />
            </div>
            {!assetData?.isIFrame && assetOverview(assetData)}
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                name='visibility'
                label={<FormattedMessage {...messages.visibility} />}
              >
                <WrapperSelect
                  getPopupContainer={() =>
                    document.getElementById('asset-edit')
                  }
                >
                  {isPrivateVisibilityAsset
                    ? constants.PRIVATE_VISIBILITY_ASSET?.slice()
                        .sort()
                        .map((item, index) => {
                          let visibilityValue = item === 'None' ? null : item;
                          return (
                            <Select.Option key={index} value={visibilityValue}>
                              {item}
                            </Select.Option>
                          );
                        })
                    : constants.ASSET_VISIBILITY?.slice()
                        .sort()
                        .map((item, index) => {
                          let visibilityValue = item === 'None' ? null : item;
                          return (
                            <Select.Option key={index} value={visibilityValue}>
                              {item}
                            </Select.Option>
                          );
                        })}
                </WrapperSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='end'>
            <Col span={12}>
              <Row>
                <Col span={12} className='classification-label'>
                  <FormattedMessage {...Messages.classifications} />
                </Col>
                <Col span={12}></Col>
              </Row>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name='classifications'
            label=''
          >
            <WrapperSelect
              mode='multiple'
              tagRender={tagRender}
              getPopupContainer={() => document.getElementById('asset-edit')}
            >
              {sortByKey(iconList, 'segmentDescription')?.map((val) => {
                return (
                  <Select.Option
                    key={val.segmentId}
                    value={val.segmentDescription}
                  >
                    <img
                      style={{ width: 20, height: 20, marginRight: 4 }}
                      src={val.segmentIcon}
                      alt={val.segmentDescription}
                    />
                    {val.segmentDescription}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};

export default injectIntl(AssetEdit);
