import { notification } from 'antd';

const top = 44;

const UNKNOWN_MSG = 'Unknown message';

const isString = (message) => typeof message === 'string';

const success = (message) => {
  if (!isString(message)) return;

  notification.success({
    message: message || UNKNOWN_MSG,
    top,
  });
};

const error = (message) => {
  if (!isString(message)) return;

  notification.error({
    message: message || UNKNOWN_MSG,
    top,
  });
};

const info = (message) => {
  if (!isString(message)) return;

  notification.info({
    message: message || UNKNOWN_MSG,
    top,
  });
};

const warning = (message, duration = 3) => {
  if (!isString(message)) return;

  notification.warning({
    message: message || UNKNOWN_MSG,
    top,
    duration,
  });
};

export { success, error, info, warning };
