import React, { useState, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import {
  Modal,
  Row,
  Col,
  Button,
  Tooltip,
  Space,
  Dropdown,
  Menu,
  notification,
  Typography,
} from 'antd';

import {
  VerticalAlignBottomOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import Messages from 'i18n/messages/product';

import ImageArea from './ImageArea';
import MarketingPreviewArea from './MarketingPreviewArea';
import DownloadModal from './DownloadModal';

import AddAssetPimModal from '../modal/add-asset-pim-modal';
import LinkToAssetModal from '../modal/link-to-asset-modal/LinkToAssetModal';
import LinkedAssetEditModal from './linked-asset-edit/LinkedAssetEditModal';

import { dialogFunction } from 'common/components';

import { unlinkAssetFromPIM } from 'services/product';

import { useCheckAllowEditProducts } from 'hooks';

import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

import './PreviewMarketingModal.less';

const PreviewMarketingModal = (props) => {
  const {
    visible,
    onCloseModal,
    imageList,
    getImagery,
    getMarketingAssets,
    title,
    titleModal,
    productInfo,
  } = props;
  const [imagePreview, setImagePreview] = useState([]);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [itemPreview, setItemPreview] = useState(null);

  const [modalLinkNewAsset, setModalLinkNewAsset] = useState(false);
  useState(false);
  const [modalLinkExistingAsset, setModalLinkExistingAsset] = useState(false);
  const [modalEditLinkAsset, setModalEditLinkAsset] = useState(false);

  const [statusUnlink, setStatusUnlink] = useState('idle');

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const isAllowEditProduct = checkAllowEditProductFull(productInfo);

  useEffect(() => {
    if (imageList?.length > 0) {
      setItemPreview(imageList[0]);
    } else {
      setItemPreview(null);
    }
  }, [imageList]);

  const checkImagePreview = (value) => {
    if (value === 'remove') {
      setImagePreview([]);
    } else if (value === 'all') {
      setImagePreview(imageList);
    } else {
      const isTrue = imagePreview?.filter((item) => item === value)?.length > 0;

      if (imagePreview && isTrue) {
        const imageTemp = imagePreview.filter(
          (item) => item?.productImageLinkId !== value?.productImageLinkId
        );
        setImagePreview(imageTemp);
      } else {
        setImagePreview(imagePreview.concat([value]));
      }
    }
  };

  const onClickItem = (item) => {
    setItemPreview(item);
  };

  const handleUnlinkAsset = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning text='linked asset' ixoneIgnore={ixoneIgnore}>
          Are you sure you want to delete this selected link data?
        </IXONEShieldWarning>
      ),
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        setStatusUnlink('loading');
        try {
          const productIds = imagePreview.map((product) => product?.id);
          const params = {
            productImageLinkId: productIds?.[0],
          };

          const response = await unlinkAssetFromPIM(params);
          if (response?.isSuccess) {
            notification.success({
              message: 'Unlink asset successfully!',
            });
            getImagery();
            getMarketingAssets();
            setImagePreview([]);
            setStatusUnlink('success');
            if (itemPreview?.id === productIds?.[0]) {
              setItemPreview(null);
            }
            await handleUpdateIXONEShield(ixoneIgnore);
          } else {
            notification.error({
              message: response?.message ?? 'Something went wrong!',
            });
            setStatusUnlink('error');
          }
        } catch (error) {
          setStatusUnlink('error');
        } finally {
          setTimeout(() => {
            setStatusUnlink('idle');
          }, 500);
        }
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setModalLinkNewAsset(true)}>
        <FormattedMessage {...Messages.linkToNewAsset} />
      </Menu.Item>
      <Menu.Item onClick={() => setModalLinkExistingAsset(true)}>
        <FormattedMessage {...Messages.linkToExistingAsset} />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Modal
        title={
          <Row
            className='preview-marketing-modal__title'
            justify='space-between'
          >
            <div>{titleModal ? titleModal : `${title} Image Preview`}</div>

            <Col>
              {isAllowEditProduct && (
                <Space>
                  <Dropdown
                    overlay={menu}
                    placement='bottomLeft'
                    disabled={!isAllowEditProduct}
                  >
                    <Button
                      icon={<ShareAltOutlined />}
                      style={{ borderRadius: 4 }}
                      type='primary'
                    >
                      Link
                    </Button>
                  </Dropdown>

                  <Tooltip title='Remove asset from product'>
                    <IXONECertificationWrapper
                      showModal={imagePreview[0]?.fromTdcImage}
                      type={TYPE_VIEW.IMAGE_PRODUCT}
                      onConfirm={(ixoneIgnore) => {
                        handleUnlinkAsset(ixoneIgnore);
                      }}
                    >
                      {(handleConfirmIXONE) => (
                        <Button
                          icon={<DeleteOutlined />}
                          style={{ borderRadius: 4 }}
                          type='primary'
                          onClick={handleConfirmIXONE}
                          danger
                          disabled={
                            !isAllowEditProduct ||
                            imagePreview.length === 0 ||
                            imagePreview.length > 1
                          }
                          loading={statusUnlink === 'loading'}
                        >
                          Unlink Asset
                        </Button>
                      )}
                    </IXONECertificationWrapper>
                  </Tooltip>
                  <Tooltip title={'Edit Linked Asset'}>
                    <Button
                      icon={<EditOutlined />}
                      style={{ borderRadius: 4 }}
                      type='primary'
                      disabled={
                        !isAllowEditProduct || imagePreview?.length !== 1
                      }
                      onClick={() => setModalEditLinkAsset(true)}
                    >
                      Edit
                    </Button>
                  </Tooltip>
                </Space>
              )}
              <Tooltip title='Support download image'>
                <Button
                  icon={<VerticalAlignBottomOutlined />}
                  style={{ borderRadius: 4, marginLeft: 8 }}
                  type='primary'
                  onClick={() => setTriggerDownload(true)}
                  disabled={imagePreview?.length === 0}
                >
                  Download With Custom Configuration
                </Button>
              </Tooltip>
            </Col>
          </Row>
        }
        destroyOnClose
        visible={visible}
        onCancel={() => {
          setImagePreview([]);
          onCloseModal();
        }}
        className={'preview-marketing-modal'}
      >
        <Row style={{ height: '100%' }}>
          <Col span={12} style={{ height: '100%' }}>
            <ImageArea
              imageList={imageList}
              title={title}
              imagePreview={imagePreview}
              visible={visible}
              idField={'productImageLinkId'}
              onClick={(item) => checkImagePreview(item)}
              onClickItem={onClickItem}
              itemPreview={itemPreview}
              isMarketing={true}
            />
          </Col>
          <Col span={12} style={{ height: '100%' }}>
            <MarketingPreviewArea imageInfo={itemPreview} />
          </Col>
        </Row>
      </Modal>
      <DownloadModal
        visible={triggerDownload}
        onCloseModal={() => setTriggerDownload(false)}
        imagePreview={imagePreview}
        idField={'productImageLinkId'}
      />

      {modalLinkNewAsset && (
        <AddAssetPimModal
          onCloseModal={() => setModalLinkNewAsset(false)}
          addType='Marketing'
          getImagery={getImagery}
          getMarketingAssets={getMarketingAssets}
        />
      )}

      {modalLinkExistingAsset && (
        <LinkToAssetModal
          onClose={() => setModalLinkExistingAsset(false)}
          visible={modalLinkExistingAsset}
          getImagery={getImagery}
          type={title}
          getMarketingAssets={getMarketingAssets}
          productInfo={productInfo}
        />
      )}

      {modalEditLinkAsset && (
        <LinkedAssetEditModal
          imageList={imageList}
          imagePreview={imagePreview}
          itemPreview={itemPreview}
          setItemPreview={setItemPreview}
          setImagePreview={setImagePreview}
          visible={modalEditLinkAsset}
          setIsEditLinkedAsset={setModalEditLinkAsset}
          addType='Marketing'
          getImagery={getImagery}
          getMarketingAssets={getMarketingAssets}
        />
      )}
    </>
  );
};

export default PreviewMarketingModal;
