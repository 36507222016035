import React from 'react';

import {
  useCheckMemberSharedOnly,
  useCheckIsSuperAdmin,
  useCheckIsSuperMember,
} from 'hooks';

const useCheckIsShowForSharedOnlyMember = () => {
  const isMemberSharedOnlyUser = useCheckMemberSharedOnly();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const isSuperMember = useCheckIsSuperMember();

  if (isSuperMember || isSuperAdmin) {
    return true;
  }

  if (isMemberSharedOnlyUser) {
    return false;
  }

  return true;
};

const IsShowForSharedOnlyMemberWrap = ({ children }) => {
  const isShow = useCheckIsShowForSharedOnlyMember();

  return <> {isShow ? children && children : null}</>;
};

export { useCheckIsShowForSharedOnlyMember, IsShowForSharedOnlyMemberWrap };
