import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Col, Input, Modal, Row, Space } from 'antd';
import { get } from 'lodash';

import * as memberServices from 'services/members';
import * as ssoServices from 'services/sso-approval';
import * as gridActions from 'common/components/grid-view/controllers/actions';
import * as brandingActions from 'redux/branding/actions';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import {
  SearchGrid,
  SearchGridItemButton,
} from 'common/components/modal/search-entity-modal/components';
import {
  Form,
  CustomNotification,
  dialogFunction,
  StyledModal,
} from 'common/components';
import { CancelButton, SubmitButton } from '../button/FooterButton';

const DATA_PATH = 'data.allMembers';

const AssignMemberModal = (props) => {
  const { visibleModal, setVisibleModal, selectedItem } = props;
  const [items, setItems] = useState([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [showSearchHint, setShowSearchHint] = useState(true);

  const reloadPage = useDispatchReloadPage();
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();

  const handleSearchItem = () => {
    const formValue = formInstance.getFieldValue('searchText');
    if (!formValue) return;

    fetchItemList({ searchText: formValue });
    setSelectedCompany([]);
  };

  const fetchItemList = async (params) => {
    setIsLoadingData(true);

    //* search Hint only show before first fetch
    showSearchHint && setShowSearchHint(false);
    const response = await memberServices.getAllMemberForReassign(params);

    setIsLoadingData(false);
    if (response?.isSuccess) {
      const gridData = get(response, DATA_PATH);
      setItems(gridData);
    }
  };

  const handleSelectItem = (itemData) => {
    if (itemData?.companyId === selectedCompany?.companyId) {
      setSelectedCompany({});
    } else {
      setSelectedCompany(itemData);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingButton(true);
    const params = {
      memberId: selectedItem.id,
      rivirMemberId: selectedCompany.companyId,
    };
    try {
      const response = await ssoServices.assignExistingMember(params);
      if (response?.isSuccess) {
        dispatch(gridActions.updateDetailCurrentItemsSelection([]));
        reloadPage();
        dispatch(brandingActions.getBrandingNoLoading());
        CustomNotification.success('Successfully!');
      }
    } catch (error) {
      CustomNotification.error('Assign member Fail');
    } finally {
      setIsLoadingButton(false);
      setVisibleModal(false);
    }
  };

  const handleConfirm = () => {
    dialogFunction({
      type: 'warn',
      style: { top: '30%' },
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          Are you sure you want to assign SSO member request of &nbsp;
          <strong>{selectedItem.memberName}</strong> to&nbsp;
          <strong>{selectedCompany.companyName}</strong>&nbsp;member?
        </div>
      ),
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => {
        handleSubmit();
      },
    });
  };

  const propsStyleModal = {
    visible: visibleModal,
    title: 'Assign to existing member',
    wrapClassName: 'assign-member-modal',
    width: '700px',
    onCancel: () => {
      setVisibleModal(false);
    },
    bodyStyle: {
      height: '60vh',
    },
    maskClosable: false,
    destroyOnClose: true,
    footer: [
      <CancelButton setVisible={setVisibleModal} />,
      <SubmitButton
        isLoading={isLoadingButton}
        handleSubmit={handleConfirm}
        isDisabled={!selectedCompany?.companyId}
      />,
    ],
  };

  return (
    <>
      <StyledModal {...propsStyleModal}>
        <>
          <Row>
            <Form form={formInstance}>
              <Row style={{ height: '100%' }}>
                <Col>
                  <Form.Item label='Search Text' name='searchText'>
                    <Input
                      style={{ width: 270, marginRight: 10 }}
                      onPressEnter={handleSearchItem}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Space>
                    <SearchGridItemButton
                      type='primary'
                      onClick={handleSearchItem}
                    >
                      <SearchOutlined />
                    </SearchGridItemButton>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Row>
          <Row style={{ width: '100%', height: '90%', minHeight: '300px' }}>
            <SearchGrid
              items={items}
              handleSelectItem={handleSelectItem}
              selectedItems={[selectedCompany]}
              isLoading={isLoadingData}
              searchType='member'
              itemIdName='companyId'
              showSearchHint={showSearchHint}
            />
          </Row>
        </>
      </StyledModal>
    </>
  );
};

export default AssignMemberModal;
