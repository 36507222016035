export const GET_APL_DETAIL = 'GET_APL_DETAIL';
export const GET_APL_DETAIL_SUCCESS = 'GET_APL_DETAIL_SUCCESS';
export const GET_APL_DETAIL_ERROR = 'GET_APL_DETAIL_ERROR';
export const UPDATE_APL_DETAIL_FILTER = 'UPDATE_APL_DETAIL_FILTER';

export const GET_APL_PRODUCT_LIST = 'GET_APL_PRODUCT_LIST';
export const GET_APL_PRODUCT_LIST_SUCCESS = 'GET_APL_PRODUCT_LIST_SUCCESS';
export const GET_APL_PRODUCT_LIST_ERROR = 'GET_APL_PRODUCT_LIST_ERROR';

export const APL_PRODUCT_GRID_COLUMN_INFO = 'APL_PRODUCT_GRID_COLUMN_INFO';
export const APL_PRODUCT_GRID_COLUMN_INFO_SUCCESS =
  'APL_PRODUCT_GRID_COLUMN_INFO_SUCCESS';
export const APL_PRODUCT_GRID_COLUMN_INFO_ERROR =
  'APL_PRODUCT_GRID_COLUMN_INFO_ERROR';

export const UPDATE_APL_PRODUCT_LIST_SELECT = 'UPDATE_APL_PRODUCT_LIST_SELECT';
export const UPDATE_APL_PRODUCT_DETAIL_LIST_SELECT =
  'UPDATE_APL_PRODUCT_DETAIL_LIST_SELECT';
export const UPDATE_APL_DETAIL_SEARCH_TEXT = 'UPDATE_APL_DETAIL_SEARCH_TEXT';

export const TOGGLE_RELOAD_GRID_APL_DETAIL = 'TOGGLE_RELOAD_GRID_APL_DETAIL';

export const GET_APL_COMMENT_LIST = 'GET_APL_COMMENT_LIST';
export const GET_APL_COMMENT_LIST_SUCCESS = 'GET_APL_COMMENT_LIST_SUCCESS';
export const GET_APL_COMMENT_LIST_ERROR = 'GET_APL_COMMENT_LIST_ERROR';
