import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/home';
import { CopyOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import { CloneMappingConfigModal } from 'pages/mapping/components';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const CloneMapping = () => {
  const selectedMapping = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const [modalVisible, setModalVisible] = useState(false);

  const onClick = () => {
    setModalVisible(true);
  };

  return (
    <>
      <RibbonButton
        icon={<CopyOutlined />}
        label={Messages.mappingClone}
        onClick={onClick}
        disabled={selectedMapping?.length !== 1}
        style={{ width: 80 }}
      />
      <CloneMappingConfigModal
        selectedMapping={selectedMapping}
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};

export default CloneMapping;
