import React, { useState } from 'react';

import { Menu } from 'antd';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';

import ExportProductPropertiesModal from '../../modal/bulk-edit-product/ExportProductPropertiesModal';
import ImportProductBulkEditModal from '../../modal/bulk-edit-product/ImportProductBulkEditModal';
import RibbonButton from 'common/components/button/RibbonButton';

import messagesProduct from 'i18n/messages/product';

const BulkEditProduct = (props) => {
  const {
    disabled,
    selectedProducts,
    selectedFolders,
    disabledExport,
    isProductDetail = false,
  } = props;

  const [visibleExportModal, setVisibleExportModal] = useState(false);
  const [visibleImportModal, setVisibleImportModal] = useState(false);

  const onClickExportButton = () => {
    setVisibleExportModal(true);
  };

  const onClickImportButton = () => {
    setVisibleImportModal(true);
  };

  const isDisabledExportButton =
    disabledExport || (!selectedProducts?.length && !selectedFolders?.length);

  return (
    <>
      <Menu.Item
        key='export-selected-product-properties'
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <RibbonButton
          size='small'
          className='button-item-dropdown'
          icon={<EditOutlined />}
          label={messagesProduct.exportSelectedProduct}
          onClick={onClickExportButton}
          disabled={isDisabledExportButton}
        />
      </Menu.Item>
      <Menu.Item
        key='import-product-properties'
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <RibbonButton
          size='small'
          className='button-item-dropdown'
          icon={<UploadOutlined />}
          label={messagesProduct.importBuildEdit}
          onClick={onClickImportButton}
          disabled={disabled}
        />
      </Menu.Item>
      {visibleExportModal && (
        <ExportProductPropertiesModal
          visible={visibleExportModal}
          setVisible={setVisibleExportModal}
          selectedProducts={selectedProducts}
          selectedFolders={selectedFolders}
        />
      )}
      {visibleImportModal && (
        <ImportProductBulkEditModal
          visible={visibleImportModal}
          setVisible={setVisibleImportModal}
          isProductDetail={isProductDetail}
        />
      )}
    </>
  );
};

export default BulkEditProduct;
