import React, { useState } from 'react';

import QaSpecCharacteristicGrid from './QaSpecCharacteristicGrid';

import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';
import { useOrganoleptic } from 'pages/qa-spec/hooks/useOrganoleptic';
import { useGetQaSpecCharacteristicsSnapshot } from './snapshot/hooks';

import { checkSectionRequired } from 'pages/qa-spec/utils';

import { EDIT_SECTION } from 'pages/qa-spec/components/edit-tab-checker';

const OrganolepticCharacteristic = (props) => {
  const { productId } = props;

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const isSectionRequired = checkSectionRequired(
    requiredFields,
    'organoleptic'
  );

  const [isGridEditing, setIsGridEditing] = useState(false);

  const {
    gridInst,
    analysisNotExecuted,
    rowData,
    columnDefs,
    saveData,
    cancelEdit,
    loading,
    onCellEditingStopped,
    gridTitle,
  } = useOrganoleptic({
    productId,
    isGridEditing,
    requiredFields,
    isSectionRequired,
  });

  const { organolepticCharacteristicsSnapshot } =
    useGetQaSpecCharacteristicsSnapshot();

  const onEdit = (isEditing) => {
    setIsGridEditing(isEditing);
  };

  return (
    <QaSpecCharacteristicGrid
      type={EDIT_SECTION.PROD_SPEC_ORGANOLEPTIC}
      title={gridTitle}
      loading={loading}
      gridInst={gridInst}
      columnDefs={columnDefs}
      rowData={rowData}
      handleSave={saveData}
      handleCancel={cancelEdit}
      analysisNotExecuted={analysisNotExecuted}
      isShowAnalysisNotExecuted
      productId={productId}
      onCellEditingStopped={onCellEditingStopped}
      snapshotGridValues={organolepticCharacteristicsSnapshot}
      onEditChange={onEdit}
    />
  );
};

export default OrganolepticCharacteristic;
