import React from 'react';

import classnames from 'classnames';
import { Collapse, Typography } from 'antd';
import _ from 'lodash';

import NestedGrid from './NestedGrid';

import * as utils from './utils';

const { Panel } = Collapse;
const { Text } = Typography;

const NestedArrayTypeAttribute = (props) => {
  const { data, columnData, parentPath } = props;

  const objectTypeColumns = utils.filterObjectTypeAttribute({
    parentPath,
    columnData,
  });

  return objectTypeColumns?.length ? (
    <div style={{ padding: '0px 0 0 10px' }}>
      <Collapse accordion destroyInactivePanel>
        {objectTypeColumns.map((colItemData, index) => {
          const headerName = colItemData?.displayName;

          const fieldValue = utils.getNestedGridDataFromFieldFullPath({
            data,
            parentPath,
            fieldFullPath: colItemData.fieldName,
          });
          const nestedGridColumns = utils.filterAttributeFieldColumn({
            parentPath: colItemData.fieldName,
            columnData,
          });
          const shouldShowHeader = !!nestedGridColumns.length;
          const shouldRenderDetail = colItemData?.isMasterDetail;

          return (
            <Panel
              className={classnames({
                'product-nested-detail-grid__panel': true,
                'product-nested-detail-grid__panel--module':
                  colItemData?.isModule,
              })}
              header={<Text style={{ fontWeight: 700 }}>{headerName}</Text>}
              key={index}
            >
              <NestedGrid
                columnData={columnData}
                data={fieldValue}
                parentPath={colItemData.fieldName}
                shouldRenderDetail={shouldRenderDetail}
                shouldShowHeader={shouldShowHeader}
              />
              {!shouldRenderDetail ? (
                <NestedArrayTypeAttribute
                  data={fieldValue}
                  parentPath={colItemData.fieldName}
                  columnData={columnData}
                />
              ) : null}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  ) : null;
};

export default NestedArrayTypeAttribute;
