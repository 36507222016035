import { getCookie, setCookie } from './cookies';

const TOKEN_PREFIX = 'mfa-hash--';

const getTokenName = (userName) => {
  const cname = TOKEN_PREFIX + userName;
  return cname;
};

//* polyfill for crypto.randomUUID
// reference: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid/8809472#8809472
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const generateMfaHash = (userName) => {
  if (!userName) return;
  const hashToken = crypto?.randomUUID ? crypto.randomUUID() : uuidv4();

  return hashToken;
};

export const storeMfaHash = ({ hashToken, userName }) => {
  const cname = getTokenName(userName);

  setCookie(cname, hashToken, 24);
};

export const getMfaHash = (userName) => {
  const cname = getTokenName(userName);
  const token = getCookie(cname);

  return token;
};
