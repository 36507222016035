export const GET_ARTICLE = 'GET_ARTICLE';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_ERROR = 'GET_ARTICLE_ERROR';

export const GET_ARTICLES_GRID_COLUMN = 'GET_ARTICLES_GRID_COLUMN';
export const GET_ARTICLES_GRID_COLUMN_SUCCESS =
  'GET_ARTICLES_GRID_COLUMN_SUCCESS';
export const GET_ARTICLES_GRID_COLUMN_ERROR = 'GET_ARTICLES_GRID_COLUMN_ERROR';

export const ENABLE_CREATE_ARTICLE = 'ENABLE_CREATE_ARTICLE';
export const DISABLE_CREATE_ARTICLE = 'DISABLE_CREATE_ARTICLE';
export const ENABLE_EDIT_ARTICLE = 'ENABLE_EDIT_ARTICLE';
export const DISABLE_EDIT_ARTICLE = 'DISABLE_EDIT_ARTICLE';
export const RESET_MODE_ARTICLE = 'RESET_MODE_ARTICLE';
export const CONFIRM_EDIT_ARTICLE = 'CONFIRM_EDIT_ARTICLE';
export const DISABLE_CONFIRM_EDIT_ARTICLE = 'DISABLE_CONFIRM_EDIT_ARTICLE';
export const CHANGE_STATUS_UPDATE_ARTICLE = 'CHANGE_STATUS_UPDATE_ARTICLE';
export const SAVE_ID_ARTICLE = 'SAVE_ID_ARTICLE';
