import React, { useState, useCallback } from 'react';

import { Typography, Checkbox, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import {
  StyledModal,
  FolderSelector,
} from 'common/components';

import { apiHandler } from 'utils/api';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useGridView } from 'hooks/useGridView';

import * as folderServices from 'services/folder';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/folder';

const { Title, Text } = Typography;

const MoveFolderModal = (props) => {
  const intl = useIntl();

  const {
    visible,
    closeModal,
    selectedItems,
    currentFolderId,
    onMove,
    shouldReloadPage = true,
  } = props;

  const [destinationFolder, setDestinationFolder] = useState(null);
  const [isMoveToRoot, setIsMoveToRoot] = useState(false);
  const [loading, setLoading] = useState(false);

  const reloadPage = useDispatchReloadPage();
  const { clearGridSelection } = useGridView();

  const disableItemHandler = useCallback(
    (destinationItem) => {
      //* loop through to check
      const disabled = selectedItems?.every((selectedItem) => {
        //* disable when destination is itself
        const isItself = selectedItem.id === destinationItem.id;
        //* or
        //* disable when destination is its child
        //*   - destination hierarchy contain its id
        const isChild =
          destinationItem?.hierarchy?.indexOf(selectedItem.id + '') > -1;

        return isItself || isChild;
      });

      //* disabled when moving to root
      return disabled || isMoveToRoot;
    },
    [selectedItems, isMoveToRoot]
  );

  const onSelectDestinationFolder = (folder) => {
    setDestinationFolder(folder);
  };

  const onBreadcrumbChange = (folderId, breadcrumbList) => {
    const hierarchy =
      breadcrumbList
        .map((breadcrumbItem) => breadcrumbItem.id)
        .join('/')
        .replace('home', '') + '/';

    setDestinationFolder(folderId ? { id: folderId, hierarchy } : null);
  };

  const onChangeMoveToRoot = (e) => {
    setIsMoveToRoot(e.target.checked);
  };

  const onSubmit = async () => {
    const destination = !isMoveToRoot
      ? { destinationFolderId: destinationFolder?.id }
      : {};

    const params = {
      itemSelected: getMovedItemParams(),
      ...destination,
      isMoveRootFolder: isMoveToRoot,
      currentFolderId: currentFolderId !== 'home' ? currentFolderId : 0,
    };

    const successMessage = intl.formatMessage(Messages.moveFolderSuccess);
    const errorMessage = intl.formatMessage(Messages.moveFolderError);

    setLoading(true);

    apiHandler({
      service: folderServices.moveFolder,
      params,
      successMessage,
      errorMessage,
      successCallback: moveSuccessCallback(destinationFolder),
      onFinally: moveFinally,
    });
  };

  const cancelModal = () => {
    closeModal();
    resetState();
  };

  const getMovedItemParams = () => {
    return selectedItems?.reduce((accumulator, currentSelectedItem) => {
      const { id, type } = currentSelectedItem;
      const itemMoved = { id, type };

      //* moveToRoot allow moving folders only
      const shouldIgnoreItem = isMoveToRoot && type?.toLowerCase() !== 'folder';

      return shouldIgnoreItem ? accumulator : [...accumulator, itemMoved];
    }, []);
  };

  const moveSuccessCallback = (destinationFolder) => () => {
    if (shouldReloadPage) {
      reloadPage();
      clearGridSelection();
    }

    cancelModal();

    onMove && onMove({ destinationFolder });
  };

  const moveFinally = () => {
    setLoading(false);
  };

  const resetState = () => {
    setLoading(false);
    setDestinationFolder(false);
    setIsMoveToRoot(false);
  };

  const selectedFolders = selectedItems.filter(
    (item) => item?.type?.toLowerCase() === 'folder'
  );
  const disableMoveToRoot =
    selectedFolders.length === 0 ||
    selectedItems?.every((item) => item?.level === 1);

  const disableSubmitButton =
    (!isMoveToRoot && destinationFolder?.id === 'home') ||
    (!destinationFolder && !isMoveToRoot);

  return (
    <StyledModal
      visible={visible}
      title={intl.formatMessage(Messages.moveFolderModalTitle)}
      bodyStyle={{ height: '50vh' }}
      okButtonTest={intl.formatMessage(Messages.moveFolder)}
      onCancel={cancelModal}
      onOk={onSubmit}
      okButtonProps={{ loading, disabled: disableSubmitButton }}
      destroyOnClose
    >
      <Row style={{ alignItems: 'baseline' }}>
        <Col flex='130px'>
          <Checkbox
            value={isMoveToRoot}
            onChange={onChangeMoveToRoot}
            style={{ marginBottom: 8 }}
            disabled={disableMoveToRoot}
          >
            {intl.formatMessage(Messages.moveToRoot)}
          </Checkbox>
        </Col>
        <Col>
          {isMoveToRoot ? (
            <Text type='warning'>
              <InfoCircleOutlined style={{ margin: '0 8px 0px 30px' }} />
              {intl.formatMessage(Messages.moveToRootWarningMessage)}
            </Text>
          ) : null}
        </Col>
      </Row>
      <Title level={5}>Select Destination Folder</Title>
      <div style={{ height: 'calc(100% - 60px)' }}>
        <FolderSelector
          onSelectFolder={onSelectDestinationFolder}
          onExpandFolder={onSelectDestinationFolder}
          onBreadcrumbChange={onBreadcrumbChange}
          disableItemHandler={disableItemHandler}
        />
      </div>
    </StyledModal>
  );
};

export default MoveFolderModal;
