import React, { useEffect, useState, useRef } from 'react';
import { Progress } from 'antd';
import { parseToHourMinuteSecond } from 'utils/parser';

import moment from 'moment';
import classnames from 'classnames';

import './index.less';

const CountdownTrack = React.memo((props) => {
  const {
    wrapperStyle,
    wrapperClass,
    expirationTime,
    totalTime,
    getRemainingTime,
    timeFormatter,
    noProgress = false,
    ...restProps
  } = props;

  const [remainingTime, setRemainingTime] = useState(null);

  const intervalRef = useRef();

  const getDifTime = (_expirationTime) => {
    if (expirationTime) {
      var now = new Date().toISOString();
      const duration = moment(_expirationTime).diff(
        moment(now),
        'seconds',
        true
      );
      return duration;
    }
  };

  const computePercent = (totalTime, remainingTime) => {
    return (remainingTime / totalTime) * 100;
  };

  const mapToTimeString = () => {
    if (timeFormatter && typeof timeFormatter === 'function') {
      return timeFormatter(remainingTime);
    } else {
      const parsedTime = parseToHourMinuteSecond(remainingTime);
      return `${parsedTime?.hours}:${parsedTime?.minutes}:${parsedTime?.seconds}`;
    }
  };

  const mapToStatus = (remainingTime) => {
    if (remainingTime < 900) {
      return 'exception';
    } else {
      return 'active';
    }
  };

  useEffect(() => {
    if (expirationTime) {
      const diffTime = getDifTime(expirationTime);
      if (diffTime && diffTime >= 0) {
        setRemainingTime(Math.floor(diffTime));
      }

      const interval = setInterval(() => {
        setRemainingTime((prev) => {
          if (prev > 0 && prev !== null) {
            return prev - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      intervalRef.current = interval;
    }

    if (!expirationTime) {
      setRemainingTime(0);
      intervalRef.current && clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [expirationTime]);

  useEffect(() => {
    getRemainingTime && getRemainingTime(remainingTime);
  }, [remainingTime, getRemainingTime]);

  const percentValue = computePercent(totalTime, remainingTime);

  return (
    <div
      className={classnames(wrapperClass, 'count-down-track', {
        'count-down-track--no-progress': noProgress,
      })}
      style={wrapperStyle}
    >
      <Progress
        percent={percentValue}
        status={mapToStatus(remainingTime)}
        size='small'
        format={mapToTimeString}
        strokeWidth={11}
        {...restProps}
      />
    </div>
  );
});

export default CountdownTrack;
