import { useMemo } from 'react';
import { useCheckIsSuperAdmin, useCheckIsSuperMember } from 'hooks';
import { useCheckPermissionOR } from 'hooks/useCheckPermissions';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

export const useCheckPermissionEditBrand = (memberIdBrand, userInfo) => {
  const checkPermissionOr = useCheckPermissionOR();

  const isSuperAdmin = useCheckIsSuperAdmin();
  const isSuperMember = useCheckIsSuperMember();

  const isSupplierMemberUser = useMemo(() => {
    const PERMISSION_EDIT_BRAND = [
      [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.PRODUCT],
      [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.COMPANY_PROFILE],
      [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
      [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MANAGE_MEMBER],
      [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.BRAND],
    ];
    const PERMISSION_EDIT_BRAND_EDIT_MEMBER = [
      [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
    ];

    let checkPermission = false;
    if (isSuperAdmin || isSuperMember) checkPermission = true;
    if (memberIdBrand && checkPermissionOr(PERMISSION_EDIT_BRAND_EDIT_MEMBER))
      checkPermission = true;
    if (
      memberIdBrand &&
      memberIdBrand == userInfo?.member?.id &&
      checkPermissionOr(PERMISSION_EDIT_BRAND)
    )
      checkPermission = true;
    if (!memberIdBrand && checkPermissionOr(PERMISSION_EDIT_BRAND))
      checkPermission = true;

    return checkPermission;
  }, [isSuperAdmin, memberIdBrand, userInfo?.member?.id]);

  return isSupplierMemberUser;
};
