import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMaintenanceForm = (state) => state?.maintenanceForm || initialState;

const makeSelectLoading = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.loading
  );
const makeSelectError = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.error
  );
const makeSelectSuccess = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.success
  );

const makeSelectSelectedDataPoints = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.selectedDataPoints
  );

const makeSelectFormDetail = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.formDetail
  );
const makeSelectFormData = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.formData
  );
const makeSelectModeViewForm = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.modeViewForm
  );

const makeSelectEditFormTemplate = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.editFormTemplate
  );

const makeSelectDataPoints = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.dataPoints
  );

const makeSelectRefreshDataPointList = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.refreshDataPointList
  );

const makeSelectClearDataPointList = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.clearDataPointList
  );

const makeSelectIsFormDetailFullScreen = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.isFormDetailFullScreen
  );

const makeSelectIsFormDetailFloatMode = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.isFloatMode
  );

const makeSelectSelectDataPointsData = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.selectedDataPointsData
  );

const makeSelectRefreshDataPointDetail = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.refreshDataPointDetail
  );
const makeSelectLoadSpreadJs = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.loadSpreadJs
  );

const makeSelectExportedFormShortDetail = () =>
  createSelector(
    selectMaintenanceForm,
    (maintenanceFormState) => maintenanceFormState.exportedFormShortDetail
  );

export {
  selectMaintenanceForm,
  makeSelectLoading,
  makeSelectError,
  makeSelectSuccess,
  makeSelectSelectedDataPoints,
  makeSelectFormDetail,
  makeSelectFormData,
  makeSelectModeViewForm,
  makeSelectEditFormTemplate,
  makeSelectDataPoints,
  makeSelectRefreshDataPointList,
  makeSelectClearDataPointList,
  makeSelectIsFormDetailFullScreen,
  makeSelectIsFormDetailFloatMode,
  makeSelectSelectDataPointsData,
  makeSelectRefreshDataPointDetail,
  makeSelectExportedFormShortDetail,
  makeSelectLoadSpreadJs,
};
