import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Space, Avatar } from 'antd';
import { formatMDY } from 'utils/formatDate';
import userSelectors from 'redux/user/selectors';
import './ItemMap.less';

const ItemMap = (props) => {
  const {
    item,
    selected,
    onClickItemMap,
    onMouseEnterItemMap,
    onMouseLeaveItemMap,
  } = props;
  return (
    <Row
      className={selected ? 'item-map item-map--selected' : 'item-map'}
      onClick={(e) => {
        if (typeof onClickItemMap === 'function') onClickItemMap(item, e);
      }}
      onMouseEnter={(e) => {
        if (typeof onMouseEnterItemMap === 'function')
          onMouseEnterItemMap(item, e);
      }}
      onMouseLeave={(e) => {
        if (typeof onMouseLeaveItemMap === 'function')
          onMouseLeaveItemMap(item, e);
      }}
    >
      <Col className='item-map__card'>
        <Space align='center'>
          {/* <Avatar className='item-map__image' shape='square' src={image} /> */}
          <Row className='item-map__body'>
            <Typography.Text ellipsis className='item-map__title'>
              {item?.name}
            </Typography.Text>
            <Typography.Text ellipsis className='item-map__date'>
              {item?.address}, {item?.city}
            </Typography.Text>
            <Typography.Text ellipsis className='item-map__date'>
              {item?.state}
            </Typography.Text>
            <Row justify='space-between'>
              <Typography.Text
                ellipsis
                className='item-map__date'
                style={{ flex: 1 }}
              >
                {item?.zipcode}
              </Typography.Text>
              <Typography.Text ellipsis style={{ fontSize: 14, maxWidth: 140 }}>
                {item?.country}
              </Typography.Text>
            </Row>
            <Typography.Text
              ellipsis
              className='item-map__date'
              style={{ fontStyle: 'italic' }}
            >
              {item?.description}
            </Typography.Text>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default React.memo(ItemMap);
