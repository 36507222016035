import React from 'react';
import {
  PictureOutlined,
  AlignLeftOutlined,
  DatabaseOutlined,
  ContactsOutlined,
  MailOutlined,
  MessageOutlined,
  FileTextOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, Dropdown } from 'antd';
import Messages from 'i18n/messages/home';

import {
  RIBBON_TYPES,
  pathnameProduct,
  pathnameFavProducts,
  pathnameProductsForMember,
} from 'static/Constants';
import * as actions from 'redux/ribbon/actions';
import * as globalActions from 'redux/global/actions';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';
import * as globalSelectors from 'redux/global/selectors';
import { selectRibbonType } from 'redux/ribbon/selectors';

import RibbonButton from 'common/components/button/RibbonButton';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import GridConfig from './GridConfig';
import CustomizeGrid from '../customize-grid';

import './ViewLayout.less';

const ViewLayout = (props) => {
  const dispatch = useDispatch();
  const { disabled, disabledGridQuery, callback } = props;
  const ribbonTypes = useSelector(selectRibbonType());

  const isDetailsView = useSelector(globalSelectors.selectIsOnlyDetailGrid());
  const isShowGridQuery = useSelector(globalSelectors.selectIsShowGridQuery());

  const isShowDetail = useSelector(globalSelectors.selectShowDetail());

  const offShowDetail = (value) => {
    // off show detail mode when changing to the detail view
    const shouldOffShowDetail =
      value === RIBBON_TYPES.DETAILSVIEW && isShowDetail;

    shouldOffShowDetail && dispatch(globalActions.toggleDetail());
  };

  const handleChangeView = (value) => {
    dispatch(actions.changeType(value));
    dispatch(gridViewActions.updateVisible(false));
    offShowDetail(value);

    dispatch(gridViewActions.updateVisibleSelection(false));
    dispatch(gridViewActions.updateItemCurrentSelection(null));
    dispatch(gridViewActions.updateItemsSelectionDialog(null));
    dispatch(gridViewActions.updateItemsSelection([]));
    dispatch(gridViewActions.updatePagesSelection([]));
    dispatch(gridViewActions.updateItemPageSelection([]));
    dispatch(gridViewActions.updateDetailCurrentItemsSelection([]));

    dispatch(gridViewActions.updateCountPageSelection([]));

    callback && callback(value);
  };

  // Only support in the detail view of product
  const isShowCustomizedGrid =
    ribbonTypes === RIBBON_TYPES.DETAILSVIEW &&
    [pathnameProduct, pathnameFavProducts, pathnameProductsForMember].includes(
      window.location.pathname
    );

  const menuOptions = (
    <Menu>
      <Menu.Item key='email' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<PictureOutlined />}
          label={Messages.thumbView}
          onClick={() => handleChangeView(RIBBON_TYPES.THUMBNAILS)}
          disabled={isDetailsView}
          className={classNames({
            'view-layout__dropdown-item': true,
            'view-layout__dropdown-item--active':
              ribbonTypes === RIBBON_TYPES.THUMBNAILS && !isDetailsView,
          })}
        />
      </Menu.Item>
      <Menu.Item key='text' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<AlignLeftOutlined />}
          label={Messages.tilesView}
          onClick={() => handleChangeView(RIBBON_TYPES.TILESVIEW)}
          disabled={isDetailsView}
          className={classNames({
            'view-layout__dropdown-item': true,
            'view-layout__dropdown-item--active':
              ribbonTypes === RIBBON_TYPES.TILESVIEW && !isDetailsView,
          })}
        />
      </Menu.Item>
      <Menu.Item key='message' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<DatabaseOutlined />}
          label={Messages.detailsLayout}
          onClick={() => handleChangeView(RIBBON_TYPES.DETAILSVIEW)}
          className={classNames({
            'view-layout__dropdown-item': true,
            'view-layout__dropdown-item--active':
              ribbonTypes === RIBBON_TYPES.DETAILSVIEW || isDetailsView,
          })}
        />
      </Menu.Item>
      <GridConfig disabledOption={!isShowGridQuery || disabledGridQuery} />

      {isShowCustomizedGrid && (
        <Menu.Item key='customize-grid' style={{ backgroundColor: 'inherit' }}>
          <CustomizeGrid />
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlayClassName={'view-layout__dropdown'}
      overlay={menuOptions}
      disabled={disabled}
      arrow
    >
      <RibbonButton
        icon={<EyeOutlined />}
        label={Messages.view}
        disabled={disabled}
      />
    </Dropdown>
  );
};

export default ViewLayout;
