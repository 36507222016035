import React from 'react';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import * as selectorsGlobal from 'redux/global/selectors';
import { STATUS_FILTER_TAG } from 'static/Constants';
const ToggleStatusTag = (props) => {
  const { onClickToSeeAll, customStyle } = props;
  const styleTag = { margin: '2px', fontWeight: 'bold', ...customStyle };
  const isDisplayProductNew = useSelector(
    selectorsGlobal.selectDisplayProductNew()
  );
  const isNewAssetFilter = useSelector(selectorsGlobal.selectDisplayAssetNew());
  const isDisplayMemberNew = useSelector(
    selectorsGlobal.selectDisplayMemberNew()
  );
  return (
    <Tag
      key={'new-toggle'}
      closable
      color='blue'
      onClose={onClickToSeeAll}
      style={styleTag}
    >
      {isDisplayMemberNew || isNewAssetFilter || isDisplayProductNew
        ? STATUS_FILTER_TAG.NEW
        : STATUS_FILTER_TAG.PENDING}
    </Tag>
  );
};

export default ToggleStatusTag;
