import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectHelpMaintenance = (state) => state.helpMaintenance || initialState;

const selectHelpMaintenanceLoading = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.loading);
const selectHelpMaintenanceLoadingPost = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.loadingPost);
const selectHelpMaintenancePostList = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.posts);
const selectTotalPost = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.total);
const selectPageSizePost = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.pageSize);
const selectPageNumberPost = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.pageNumber);
const selectHelpMaintenanceSearch = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.search);
const selectError = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.error);
const selectPostColumns = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.postColumns);
const selectIsDetailsView = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.isDetailsView);
const selectToggleCategoryDetail = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.detailCategory
  );
const selectPostContentDetail = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.postContentDetail
  );

const selectCategoryColumns = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.categoryColumns
  );

const selectSelectCategory = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.selectCategory
  );

const selectEnableEdit = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.enableEdit);

const selectIsEdit = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.isEdit);

const selectStartSubmit = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.startSubmit);

const selectSubmitError = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.submitError);

const selectSubmitSuccess = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.submitSuccess);

const selectHelpCategory = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.helpCategory);
const selectIsCreate = () =>
  createSelector(selectHelpMaintenance, (helpState) => helpState.isCreate);
const selectHelpMaintenancePost = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.helpMaintenancePost
  );

const selectHelpAssetFiles = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.helpAssetFiles
  );
const selectIsHelpUploadMediaOpen = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.isUploadHelpMediaOpen
  );
const selectDisableUploadMedia = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.disableUploadMedia
  );
const selectIsCloseCategoryPanel = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.isCloseCategoryPanel
  );
const selectIsVisibleCategory = () =>
  createSelector(
    selectHelpMaintenance,
    (helpState) => helpState.isVisibleCategory
  );

export {
  selectHelpMaintenance,
  selectHelpMaintenanceLoading,
  selectHelpMaintenanceLoadingPost,
  selectHelpMaintenancePostList,
  selectTotalPost,
  selectPageSizePost,
  selectPageNumberPost,
  selectHelpMaintenanceSearch,
  selectError,
  selectPostColumns,
  selectIsDetailsView,
  selectToggleCategoryDetail,
  selectPostContentDetail,
  selectCategoryColumns,
  selectSelectCategory,
  selectEnableEdit,
  selectIsEdit,
  selectStartSubmit,
  selectSubmitError,
  selectSubmitSuccess,
  selectHelpCategory,
  selectIsCreate,
  selectHelpMaintenancePost,
  selectHelpAssetFiles,
  selectIsHelpUploadMediaOpen,
  selectDisableUploadMedia,
  selectIsCloseCategoryPanel,
  selectIsVisibleCategory,
};
