import { isEmpty } from 'lodash';
export const validateRoleName = (value) => {
  const isEmptyValue = isEmpty(value);

  const isValidRequired = isEmptyValue ? false : value.trim()?.length > 0;

  const isValidLength = isEmptyValue
    ? false
    : value.trim()?.length > 0 && value.trim()?.length < 51;

  let errors = [];

  !isValidRequired && errors.push('Role Name is required');

  !isValidLength &&
    errors.push(`Name's length must be greater than 0 and smaller than 51`);

  return errors;
};
