import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from 'antd';

import PrivateRoute from './PrivateRoute';
import AnonymousRoute from './AnonymousRoute';
// import BlueprintRoute from './BlueprintRoute';

import { SIZE_LARGE, RETURN_URL_EXCEPTION } from 'static/Constants';
import DelayedFallback from 'common/components/indicator/SuspendIndicator';
import { isAuthed } from 'utils/common/session';
import {
  QuestionCircleTwoTone,
  ClockCircleTwoTone,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import Messages from 'i18n/messages/common';
import timeoutMessage from 'i18n/messages/timeout';
import ErrorBoundary from 'common/components/errorboundary/ErrorBoundary';
import * as actions from 'redux/global/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import { getData } from 'utils/storage';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import * as reportingActions from 'pages/reporting/controllers/actions';
import * as folderActions from 'pages/folders/controllers/actions';
import InteropCallbackAuthentication from 'pages/interop-callback/InteropCallbackAuthentication';
// SSO PRODUCT BP
import * as ssoProductSelectors from 'pages/sso-product/controllers/selectors';

const { lazy, Suspense } = React;
// delay import for user experience when loading too fast
const HomePage = lazy(() => import('pages/home'));
const LogIn = lazy(() => import('pages/login'));
const LoginOtp = lazy(() => import('pages/login-otp'));
const GetBasicInfo = lazy(() => import('pages/get-basic-info'));

const Register = lazy(() => import('pages/register'));
const ForgotPassword = lazy(() => import('pages/resetpassword'));
const Eula = lazy(() => import('pages/eula'));
const CreditCardEulaPage = lazy(() => import('pages/eula/CreditCardEulaPage'));
const SplashScreen = lazy(() => import('pages/waiting'));
const NotFound = lazy(() => import('pages/error/NotFound'));
const InternalSever = lazy(() => import('pages/error/InternalSever'));
const Timeout = lazy(() => import('pages/error/Timeout'));
const Personalizer = lazy(() => import('pages/personalization'));
const Notification = lazy(() => import('pages/messages'));
const LandingPage = lazy(() => import('pages/landingpage'));
const SamplePage = lazy(() => import('pages/sample'));
const MemberInformationSheet = lazy(() =>
  import('pages/member-information-sheet')
);

const ExternalMeetingRoom = lazy(() => import('pages/external-meeting'));

const SSOLanding = lazy(() => import('pages/sso-landing'));
const LoadingValidate = lazy(() => import('pages/sso-landing/LoadingValidate'));
const EulaAccepted = lazy(() => import('pages/sso-landing/EulaAccepted'));
const WelcomeTransition = lazy(() =>
  import('pages/sso-landing/WelcomeTransition')
);

const Trans = lazy(() => import('pages/transition-page-blueprint'));

const ErrorValidateRivir = lazy(() =>
  import('pages/sso-landing/ErrorValidateRivir')
);

const SsoProduct = lazy(() => import('pages/sso-product'));
const PostValidationSSOProduct = lazy(() =>
  import('pages/sso-landing/PostValidationSSOProduct ')
);

const ChangePasswordUnlock = lazy(() => import('pages/change-password-unlock'));
const PreRouting = lazy(() => import('pages/pre-routing'));
const UnlockAccount = lazy(() => import('pages/unlock-account'));

// casl
//import {AbilityContext} from 'components/casl/Can';
function Routes(props) {
  //const ability = useContext(AbilityContext);

  //const loading = useSelector(gloSel.makeGlobalLoading());
  const history = useHistory();
  const pathName = history.location.pathname;
  const queryString = history.location.search;
  const dispatch = useDispatch();

  const isBpSsoProductValidateDetail = useSelector(
    ssoProductSelectors.selectIsBpSsoProductValidateDetail()
  );
  const bpSsoProductData = useSelector(
    ssoProductSelectors.selectBpSsoProductData()
  );

  const { intl } = props;

  // prevent return to unwanted path, redirect to home
  const returnUrl = RETURN_URL_EXCEPTION.includes(pathName)
    ? '/'
    : pathName + queryString;

  // attach event listener for request-timeout notification
  const showRequestTimeoutNotification = () => {
    notification.error({
      message: intl.formatMessage(timeoutMessage.title),
      description: intl.formatMessage(timeoutMessage.subTitle),
    });
  };
  const requestTimeoutEvent = {
    name: 'requestTimeout',
    handler: showRequestTimeoutNotification,
  };
  useDocumentAttachEvent(requestTimeoutEvent);

  useEffect(() => {
    const user = getData('User');
    let logging = {
      type: 'url',
      date: new Date(),
      email: user ? user.email : '',
      browser: navigator.userAgent,
      pathName: pathName,
    };
    dispatch(actions.updateLogging(logging));

    // BP 08/30/2020: performance issue when switch hash
    dispatch(actionsGridView.updateVisibleSelection(false));
    dispatch(actionsGridView.updateItemCurrentSelection(null));
    dispatch(actionsGridView.updateItemsSelectionDialog(null));
    dispatch(actionsGridView.updateItemsSelection([]));
    dispatch(actionsGridView.updatePagesSelection([]));
    dispatch(actionsGridView.updateItemPageSelection([]));
    dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));

    dispatch(actionsGridView.updateCountPageSelection([]));
    dispatch(actionsGridView.updateIsDisplayMask(true));
    dispatch(actionsGridView.updateVisible(false));
    dispatch(actionsGridView.updateSizeCurrent([1, 0]));
    dispatch(actionsGridView.updateTypeViewCurrent(null));
    dispatch(actionsGridView.updateSearchCurrent(null));

    // update global redux
    dispatch(actions.searchAllItems(''));
    dispatch(actions.updateAddToFolder(false));

    dispatch(actions.updateIsBackFromQuery(false));
    dispatch(actions.updateIsBackFromReporting(false));
    dispatch(reportingActions.updateModeView(''));

    return history.listen((location) => {
      const user = getData('User');
      let logging = {
        type: 'url',
        date: new Date(),
        email: user ? user.email : '',
        browser: navigator.userAgent,
        pathName: location.pathname,
      };
      dispatch(actions.updateLogging(logging));

      // BP 08/30/2020: performance issue when switch hash
      dispatch(actionsGridView.updateVisibleSelection(false));
      dispatch(actionsGridView.updateItemCurrentSelection(null));
      dispatch(actionsGridView.updateItemsSelectionDialog(null));
      dispatch(actionsGridView.updateItemsSelection([]));
      dispatch(actionsGridView.updatePagesSelection([]));
      dispatch(actionsGridView.updateItemPageSelection([]));
      dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));

      dispatch(actionsGridView.updateCountPageSelection([]));
      dispatch(actionsGridView.updateIsDisplayMask(true));
      dispatch(actionsGridView.updateVisible(false));
      dispatch(actionsGridView.updateSizeCurrent([1, 0]));
      //todo - may remove this line of code - when favorite page have its own pagination data
      // dispatch(actionsGridView.resetPageCurrentMember());
      dispatch(actionsGridView.updateTypeViewCurrent(null));
      dispatch(actionsGridView.updateSearchCurrent(null));

      dispatch(actions.searchAllItems(''));
      dispatch(actions.updateAddToFolder(false));

      dispatch(actions.updateIsBackFromQuery(false));
      dispatch(actions.updateIsBackFromReporting(false));
      dispatch(reportingActions.updateModeView(''));
      dispatch(folderActions.resetFolderPaging());
    });
  }, [history, dispatch]);

  const containers = (
    <Switch>
      <Route path='/refreshing' exact render={() => null} />
      <Route path={'/login'} exact render={() => <LogIn />} />
      <Route path={'/register'} exact render={() => <Register />} />
      <Route path={'/forgot'} exact render={() => <ForgotPassword />} />
      <Route
        path={'/notfound'}
        exact
        render={() => <NotFound customIcon={<QuestionCircleTwoTone />} />}
      />
      <Route
        path={'/500-error'}
        exact
        render={() => <InternalSever customIcon={<CloseCircleOutlined />} />}
      />
      <Route path={'/sample'} exact render={() => <SamplePage />} />
      <Route
        path={'/timeout'}
        exact
        render={() => <Timeout customIcon={<ClockCircleTwoTone />} />}
      />
      <Route
        path={'/sheet/:memberId/:privateKey'}
        exact
        render={() => <MemberInformationSheet />}
      />
      <Route path={'/sso-test'} exact render={() => <Trans />} />
      <Route path={'/sso'} exact render={() => <SSOLanding />} />
      <Route
        path={'/dropbox/callback'}
        render={() => <InteropCallbackAuthentication />}
      />
      <Route path={'/validate'} exact render={() => <LoadingValidate />} />
      <Route path={'/eula-page'} exact render={() => <EulaAccepted />} />
      <Route
        path={'/error-validate-rivir'}
        exact
        render={() => (
          <ErrorValidateRivir
            title='Declined the EULA'
            content='Usage of the RIVIR application requires EULA acceptance'
          />
        )}
      />
      <Route
        path={'/error-invalid-token'}
        exact
        render={() => (
          <ErrorValidateRivir
            title='Token is expired'
            // content='You will not allow to use PIM/DAM application when you decline EULA'
          />
        )}
      />
      <Route path={'/welcome'} exact render={() => <WelcomeTransition />} />
      <Route path={'/product/createnew'} exact render={() => <SsoProduct />} />
      <Route
        path={'/post-validate-sso-product'}
        exact
        render={() => <PostValidationSSOProduct />}
      />
      <AnonymousRoute
        path='/ext-meeting/:meetingId'
        exact
        component={ExternalMeetingRoom}
      />
      <Route path={'/waiting'} render={() => <SplashScreen />} />
      <PrivateRoute
        path={'/personalization'}
        exact
        component={Personalizer}
        validateFn={isAuthed}
      />
      <PrivateRoute
        path={'/landingpage'}
        exact
        component={LandingPage}
        validateFn={isAuthed}
      />
      <Route path={'/eula'} exact render={() => <Eula />} />
      <Route path={'/cc-eula'} exact component={CreditCardEulaPage} />
      <Route path={'/login-otp'} exact render={() => <LoginOtp />} />
      <Route path={'/unlock-account'} render={() => <UnlockAccount />} />
      <Route
        path={'/get-basic-info'}
        exact
        component={GetBasicInfo}
        validateFn={isAuthed}
      />

      <PrivateRoute
        path={'/change-password'}
        component={ChangePasswordUnlock}
        validateFn={isAuthed}
      />
      <PrivateRoute
        path={'/notification'}
        exact
        component={Notification}
        validateFn={isAuthed}
      />
      <PrivateRoute
        path={'/pre-routing'}
        exact
        component={PreRouting}
        validateFn={isAuthed}
      />
      <PrivateRoute
        path={'/'}
        returnUrl={returnUrl}
        component={HomePage}
        validateFn={isAuthed}
      />
      <Redirect
        to={{
          pathname: `/login`,
        }}
      />
      {/* <Route path={"/setting"} exact render={props => (
                    ability.can("view", "Setting") ? <FieldPage {...props} /> : <Result
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access this page."
                    extra={<Button type="primary">Back Home</Button>}
                  />
                )} />

                <Route component={NoPageFound} /> */}
    </Switch>
  );
  return (
    <div className={props.cls} style={props.style}>
      <Suspense
        fallback={
          <DelayedFallback
            size={SIZE_LARGE}
            tip={<FormattedMessage {...Messages.loading} />}
          />
        }
      >
        <ErrorBoundary>{containers}</ErrorBoundary>
      </Suspense>
    </div>
  );
}

export default injectIntl(Routes);
