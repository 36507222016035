import {
  useCheckIsShowForSupplierMember,
  IsShowForSupplierMemberWrap,
} from './isShowForSupplierMember';

import {
  useCheckIsShowForSharedOnlyMember,
  IsShowForSharedOnlyMemberWrap,
} from './isShowForSharedOnlyMember';

const CanShow = {
  SupplierMember: IsShowForSupplierMemberWrap,
  SharedOnlyMember: IsShowForSharedOnlyMemberWrap,
};

const useCanShow = {
  supplierMember: useCheckIsShowForSupplierMember,
  sharedOnlyMember: useCheckIsShowForSharedOnlyMember,
};

export { CanShow, useCanShow };
