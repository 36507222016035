import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Space, Avatar } from 'antd';
import { formatMDY } from 'utils/formatDate';
import userSelectors from 'redux/user/selectors';
import { forwardTo } from 'utils/common/route';
import './ItemMap.less';

const ItemMap = (props) => {
  const {
    item,
    selected,
    onClickItemMap,
    onMouseEnterItemMap,
    onMouseLeaveItemMap,
  } = props;
  const onDoubleClickUsers = () => {
    forwardTo(`/user-profile/${item?.id}`);
  };
  return (
    <Row
      className={
        selected
          ? 'item-folder-preview item-folder-preview--selected'
          : 'item-folder-preview'
      }
      onClick={(e) => {
        if (typeof onClickItemMap === 'function')
          setTimeout(() => {
            onClickItemMap(item, e);
          }, 200);
      }}
      onDoubleClick={onDoubleClickUsers}
      onMouseEnter={(e) => {
        if (typeof onMouseEnterItemMap === 'function')
          onMouseEnterItemMap(item, e);
      }}
      onMouseLeave={(e) => {
        if (typeof onMouseLeaveItemMap === 'function')
          onMouseLeaveItemMap(item, e);
      }}
    >
      <Col className='item-folder-preview__card'>
        <Space align='center'>
          {/* <Avatar className='item-folder-preview__image' shape='square' src={image} /> */}
          <Row className='item-folder-preview__body'>
            <Typography.Text ellipsis className='item-folder-preview__title'>
              {item?.firstName + ' ' + item?.lastName}
            </Typography.Text>
            <Typography.Text ellipsis className='item-folder-preview__date'>
              {item?.address}, {item?.city}
            </Typography.Text>
            <Typography.Text ellipsis className='item-folder-preview__date'>
              {item?.state}
            </Typography.Text>
            <Row justify='space-between'>
              <Typography.Text
                ellipsis
                className='item-folder-preview__date'
                style={{ flex: 1 }}
              >
                {item?.zipcode}
              </Typography.Text>
              <Typography.Text ellipsis style={{ fontSize: 14, maxWidth: 200 }}>
                {item?.country}
              </Typography.Text>
            </Row>
            <Typography.Text
              ellipsis
              className='item-folder-preview__date'
              style={{ fontStyle: 'italic' }}
            >
              {item?.description}
            </Typography.Text>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default React.memo(ItemMap);
