import React from 'react';
import { Checkbox } from 'antd';

const CheckboxRender = (props) => {
  const { value } = props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
      }}
    >
      <Checkbox checked={value} />
    </div>
  );
};

export default CheckboxRender;
