import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getMappingSourceProperties = (params) => {
  return api.sendPost(endpoints.GET_MAPPING_SOURCE_PROPERTIES, params);
};

export const saveMappingConfigService = (params) => {
  return api.sendPost(endpoints.SAVE_MAPPING_GRID_CONFIG, params);
};

export const getMappingConfigService = (params) => {
  return api.sendGet(endpoints.GET_MAPPING_CONFIG, { params });
};

export const getMappingGridService = (params) => {
  return api.sendPost(endpoints.GET_MAPPING_GRID, params);
};

export const getSharedMemberList = (params) => {
  return api.sendPost(endpoints.GET_MEMBER_SHARED_LIST, params);
};

export const removeSupportedMember = (params) => {
  return api.sendPost(endpoints.REMOVE_SUPPORTED_MEMBER, params);
};
export const deleteMappingConfig = (params) => {
  return api.sendPost(endpoints.DELETE_MAPPING_CONFIG, params);
};

export const cloneMappingConfig = (params) => {
  return api.sendPost(endpoints.CLONE_MAPPING_CONFIG, params);
};

export const getMappingConfigProperties = (params) => {
  return api.sendGet(endpoints.GET_MAPPING_CONFIG_PROPERTIES, { params });
};

export const deleteMappingGridConfigService = (params) => {
  return api.sendPost(endpoints.DELETE_MAPPING_GRID_CONFIG, params);
};

export const cloneMappingGridConfigService = (params) => {
  return api.sendPost(endpoints.CLONE_MAPPING_GRID_CONFIG, params);
};

export const saveMappingConfigProperties = (params) => {
  return api.sendPost(endpoints.SAVE_MAPPING_CONFIG, params);
};
export const initProductMapping = (params) => {
  return api.sendPost(endpoints.INIT_PRODUCT_MAPPING, params);
};

export const downloadMappingProductSheets = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_MAPPING_PRODUCT_SHEETS, { params });
};

export const getSubMappingSubscription = (params) => {
  return api.sendPost(endpoints.GET_SUB_MAPPING_GRID, params);
};
