import React, { useState, useEffect } from 'react';

import { Modal, Radio, Spin, notification, List, Checkbox, Row } from 'antd';

import { Form } from 'common/components';
import * as api from 'config/axios';
import * as services from 'services/product/index';
import { PLANOGRAM_TYPE } from 'static/Constants';

const PLANOGRAM_PDF = 'Planogram PDF';

const DownloadModal = (props) => {
  const [form] = Form.useForm();
  const { visible, onCloseModal, imagePreview, idField, showNamingField } =
    props;

  const [loading, setLoading] = useState(false);
  const [planogramType, setPlanogramType] = useState(null);
  const [namingType, setNamingType] = useState('');

  const formatType = ['JPG', 'TIF', 'PNG'];
  const imageSize = [
    '50x50',
    '100x100',
    '300x300',
    '500x500',
    '1000x1000',
    'Full',
  ];
  const deliveryMethod = ['Direct', 'Email'];

  const handleDownload = (value) => {
    setLoading(true);
    const { includeExtension, ...restValue } = value;
    const submitData = {
      imageIds: imagePreview?.map((item) => item?.[idField]),
      includeExtension: includeExtension || !showNamingField,
      ...restValue,
    };

    services
      .downloadProductImagery(submitData)
      .then(
        (response) => {
          if (response.isSuccess) {
            if (value.deliveryMethod === 0) {
              notification.success({
                message: 'Download asset(s) successfully.',
              });
              return api.sendDownload({ url: response?.data?.imageUrl });
            } else {
              notification.success({
                message:
                  'Download asset(s) successfully. Please check your email for downloaded result.',
              });
            }
          } else {
            notification.error({ message: response.message });
          }
        },
        (err) => {
          notification.error({ message: 'Download failed. Please retry!' });
        }
      )
      .finally(() => {
        setPlanogramType(null);
        setNamingType('');
        onCloseModal();
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!visible) form.resetFields();
  }, [visible]);

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18, offset: 1 },
  };

  const renderRadioGroup = ({ value, options, useIndexValue }) => {
    return (
      <Radio.Group value={value} style={{ width: '100%' }}>
        <List
          grid={{
            gutter: [16, 4],
            column: 4,
          }}
          dataSource={options}
          renderItem={(option, index) => (
            <Radio value={useIndexValue ? index : option} key={index}>
              {option}
            </Radio>
          )}
        />
      </Radio.Group>
    );
  };

  const showImageType = showNamingField
    ? planogramType && namingType !== PLANOGRAM_PDF
    : true;

  const showImageSize = namingType !== PLANOGRAM_PDF;

  return (
    <Modal
      title={'Download configuration'}
      visible={visible}
      onCancel={() => {
        setPlanogramType(null);
        setNamingType('');
        onCloseModal();
      }}
      onOk={() => form.submit()}
      width={900}
      className={'download-modal'}
      zIndex={9999}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name='download-form'
          {...layout}
          onFinish={(value) => handleDownload(value)}
          onValuesChange={(value) => {
            if (typeof value?.includeExtension === 'boolean')
              setPlanogramType(value?.includeExtension);

            if (value?.namingType) setNamingType(value?.namingType);
          }}
        >
          {showNamingField ? (
            <>
              <Form.Item
                name={'namingType'}
                label={'Naming Type'}
                rules={[
                  {
                    required: true,
                    message: 'Please input download file name!',
                  },
                ]}
              >
                {renderRadioGroup({
                  value: PLANOGRAM_TYPE[0],
                  options: PLANOGRAM_TYPE,
                })}
              </Form.Item>
              <Form.Item
                name='includeExtension'
                label='Include Extension'
                valuePropName='checked'
              >
                <Row gutter={[16, 16]}>
                  <Checkbox />
                </Row>
              </Form.Item>
            </>
          ) : null}

          {showImageType && (
            <Form.Item
              name={'formatType'}
              label={'Image Type'}
              rules={[
                {
                  required: true,
                  message: 'Please choose format type',
                },
              ]}
            >
              {renderRadioGroup({
                value: formatType[0],
                options: formatType,
              })}
            </Form.Item>
          )}

          {showImageSize && (
            <Form.Item
              name={'imageSize'}
              label={'Image Size'}
              rules={[
                {
                  required: true,
                  message: 'Please choose image size',
                },
              ]}
            >
              {renderRadioGroup({
                value: imageSize[0],
                options: imageSize,
              })}
            </Form.Item>
          )}

          <Form.Item
            name={'deliveryMethod'}
            label={'Delivery Method'}
            rules={[
              {
                required: true,
                message: 'Please choose download method',
              },
            ]}
          >
            {renderRadioGroup({
              value: deliveryMethod[0],
              options: deliveryMethod,
              useIndexValue: true,
            })}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default DownloadModal;
