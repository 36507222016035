import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const downloadAplBlankTemplate = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_APL_BLANK_TEMPLATE, { params: params });
};

export const addProductToApl = (params) => {
  return api.sendPost(endpoints.ADD_PRODUCT_TO_APL, params);
};

export const deleteProductFromApl = (params) => {
  return api.sendPost(endpoints.DELETE_PRODUCT_FROM_APL, params);
};

export const createApl = (params) => {
  return api.sendPost(endpoints.CREATE_APL, params, {
    ignoreServerError: true,
  });
};

export const downloadAplProductList = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_APL_PRODUCT_LIST, { params: params });
};

export const editInfoMetadata = (params) => {
  return api.sendPost(endpoints.EDIT_INFO_METADATA, params);
};

export const addFoldersIntoNewAPL = (params) => {
  return api.sendPost(endpoints.ADD_FOLDERS_INTO_NEW_APL, params);
};
export const addProductsToNewApl = (params) => {
  return api.sendPost(endpoints.ADD_PRODUCTS_TO_NEW_APL, params);
};
export const addSearchProductsToApl = (params) => {
  return api.sendPost(endpoints.ADD_SEARCH_PRODUCTS_TO_APL, params);
};
export const sendQuestion = (params) => {
  return api.sendPost(endpoints.SEND_QUESTION, params);
};
export const deleteApl = (params) => {
  return api.sendPost(endpoints.DELETE_APL, params);
};

export const getSampleRequestSenderData = () => {
  return api.sendGet(endpoints.GET_SAMPLE_REQUEST_SENDER_DATA);
};

export const sendSampleRequest = (params) => {
  return api.sendPost(endpoints.SEND_SAMPLE_REQUEST, params);
};
