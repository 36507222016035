import React from 'react';
import { useSelector } from 'react-redux';

import { CheckCircleOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import dialogFunction from 'common/components/dialog-function/DialogFunction';
import { CustomNotification } from 'common/components';

import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/maintenance';
import { STATUS_REACTIVE_USER_MEMBER } from 'static/Constants';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as memberServices from 'services/members';
import selectorUser from 'redux/user/selectors';
import { checkIsSuperAdmin } from 'utils';

const UserReactive = () => {
  const reloadPage = useDispatchReloadPage();

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);

  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );

  const selectedUserOrMember = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const idsSelectedUser = selectedUserOrMember
    ?.filter((item) =>
      STATUS_REACTIVE_USER_MEMBER.includes(item?.status?.toUpperCase())
    )
    .map((item) => item?.id);

  const isActive = idsSelectedUser.length > 0;

  const handleReactivateUser = async () => {
    const params = {
      userIds: idsSelectedUser,
    };
    const response = await memberServices.reactiveUser(params);

    const { isSuccess, message } = response;
    if (isSuccess) {
      reloadPage();
      CustomNotification.success('Active deleted user(s) successfully');
    } else {
      CustomNotification.error(
        message || 'Failed to reactivate deleted user(s)'
      );
    }
  };

  const handleReactiveUser = () => {
    dialogFunction({
      type: 'warn',
      content: 'Do you want to reactivate this user?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleReactivateUser,
    });
  };

  return (
    <>
      {!isMemberGridInfoView && isSuperAdmin && (
        <RibbonButton
          onClick={handleReactiveUser}
          disabled={!isActive}
          icon={<CheckCircleOutlined />}
          label={<FormattedMessage {...Messages.reactivateUser} />}
        />
      )}
    </>
  );
};

export default UserReactive;
