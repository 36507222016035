import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ReactComponent as RemoveSharingIcon } from 'assets/shared-ribbon/RemoveShared.svg';
import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components';

import userSelector from 'redux/user/selectors';

import { removeSharing } from 'services/manageSharing';

import Messages from 'i18n/messages/share';
import { injectIntl } from 'react-intl';

const RemoveSharing = (props) => {
  const { intl, isSectionDisabled, selectedItemList } = props;

  const userId = useSelector(userSelector.makeSelectUserId());

  const checkIsOwner = () => {
    const isOwnerList = selectedItemList?.filter((selectedItem) => {
      return selectedItem.ownerId === userId;
    });
    return isOwnerList.length === selectedItemList.length;
  };

  const onClickHandler = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.removeShareConfirm),
      onOk: callRemoveSharing(),
    });
  };

  const callRemoveSharing = () => async () => {
    try {
      const listEntities = selectedItemList?.map((selectedItem) => {
        const { id, type } = selectedItem;
        return {
          sharedId: id,
          shareType: type,
        };
      });
      const response = await removeSharing(listEntities);
      const { isSuccess, data, message } = response;
      if (isSuccess) {
        handleRemoveSharingSuccess();
      } else {
        handleRemoveSharingError(data?.listErrorEntities, message);
      }
    } catch (err) {
      handleRemoveSharingError();
    }
  };

  const handleRemoveSharingSuccess = () => {
    dialogFunction({
      type: 'success',
      content: intl.formatMessage(Messages.removeShareSuccess),
      cancelButtonProps: {
        style: { display: 'none' },
      },
    });
  };

  const handleRemoveSharingError = (errorEntityList, message) => {
    let messageContent = intl.formatMessage(Messages.removeShareError);

    if (errorEntityList?.length) {
      const extraInfo = getErrorMessageExtraInfo(errorEntityList);
      messageContent = intl.formatMessage(Messages.removeShareError, {
        extra: extraInfo,
      });
    }

    if (message === 'There are no shared to remove.') {
      messageContent = intl.formatMessage(Messages.removeShareErrorResponse);
    }

    dialogFunction({
      type: 'error',
      content: messageContent,
      cancelButtonProps: {
        style: { display: 'none' },
      },
    });
  };

  const getErrorMessageExtraInfo = (errorEntityList) => {
    const entityNameField = {
      Folder: 'folderName',
      member: 'memberName',
      asset: 'assetName',
    };

    try {
      const firstErrorEntity = errorEntityList.length && errorEntityList[0];
      const { sharedId, shareType } = firstErrorEntity;

      const firstErrorEntityNameField = entityNameField[shareType];

      const firstErrorEntityData = selectedItemList.find((selectedItem) => {
        return selectedItem.type === shareType && selectedItem.id === sharedId;
      });

      const firstErrorEntityName =
        firstErrorEntityData[firstErrorEntityNameField];

      return firstErrorEntityName
        ? intl.formatMessage(Messages.removeShareErrorEntity, {
            entityName: firstErrorEntityName,
          })
        : '';
    } catch (error) {
      return '';
    }
  };

  const disabled = isSectionDisabled;

  return (
    <RibbonButton
      icon={<RemoveSharingIcon />}
      label={Messages.removeSharing}
      disabled={disabled}
      onClick={onClickHandler}
    />
  );
};

RemoveSharing.propTypes = {
  isSectionDisabled: PropTypes.bool,
  selectedItemList: PropTypes.array,
};

export default injectIntl(RemoveSharing);
