import React, { forwardRef, useImperativeHandle } from 'react';

import { Typography, Col } from 'antd';

import { FormattedMessage } from 'react-intl';
import { useAggridSelection } from 'common/components/ag-grid/hooks/SelectionHook';

import { cloneDeep } from 'lodash';

import messages from 'i18n/messages/gridView';

const SelectionLog = (props, ref) => {
  const {
    gridView,
    isShowItemSelectionLog,
    gridId,
    onCallbackSelectedRowFromOutside,
  } = props;

  const {
    itemsSelectionLog,
    pageItemsSelectionLog,
    itemsSelectionDetailLog,
    pageItemsSelectionDetailLog,
    setItemsSelectionLog,
    setPageItemsSelectionLog,
    setItemsSelectionDetailLog,
    setPageItemsSelectionDetailLog,
    setRefLastSelectedRowData,
  } = useAggridSelection({
    gridView,
    gridId,
    onCallbackSelectedRowFromOutside,
  });

  useImperativeHandle(ref, () => ({
    setItemsSelectionLog: (value) => setItemsSelectionLog(cloneDeep(value)),
    setPageItemsSelectionLog: (value) =>
      setPageItemsSelectionLog(cloneDeep(value)),
    setItemsSelectionDetailLog: (value) =>
      setItemsSelectionDetailLog(cloneDeep(value)),
    setPageItemsSelectionDetailLog: (value) =>
      setPageItemsSelectionDetailLog(cloneDeep(value)),
    setRefLastSelectedRowData,
    getItemsSelectionLog: () => itemsSelectionLog,
    getPageItemsSelectionLog: () => pageItemsSelectionLog,
    getItemsSelectionDetailLog: () => itemsSelectionDetailLog,
    getPageItemsSelectionDetailLog: () => pageItemsSelectionDetailLog,
  }));

  return (
    <>
      {gridView && (
        <Col span={6}>
          {itemsSelectionLog &&
            isShowItemSelectionLog &&
            itemsSelectionLog.length > 0 &&
            pageItemsSelectionLog &&
            pageItemsSelectionLog.length === 1 && (
              <Typography.Text type='warning' strong>
                {itemsSelectionLog.length}{' '}
                <FormattedMessage {...messages.numberItem} />
              </Typography.Text>
            )}
          {isShowItemSelectionLog &&
            pageItemsSelectionLog &&
            pageItemsSelectionLog.length > 1 && (
              <Typography.Text type='warning' strong>
                {itemsSelectionLog.length}{' '}
                <FormattedMessage {...messages.numberItemMultiPage} />
              </Typography.Text>
            )}
        </Col>
      )}
    </>
  );
};

export default forwardRef(SelectionLog);
