import React from 'react';

const WithAbsoluteContainer = (props) => {
  const { isHidden = false, children } = props;

  return (
    <>
      {isHidden ? (
        <div style={{ position: 'absolute', display: 'none' }}>{children}</div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default WithAbsoluteContainer;
