import React from 'react';

import { useSelector } from 'react-redux';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import DownloadFormBtn from './DownloadFormBtn';

import { RibbonSection } from 'common/components';

const FormItemManipulationSection = (props) => {
  const selectedForms = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const singleSelection = selectedForms?.length === 1;
  const multipleSelection = selectedForms?.length > 1;

  const allowDownloadForm = singleSelection && !multipleSelection;

  return (
    <RibbonSection>
      <DownloadFormBtn
        disabled={!allowDownloadForm}
        selectedForms={selectedForms}
      />
    </RibbonSection>
  );
};

export default FormItemManipulationSection;
