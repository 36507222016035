import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { SaveOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const SaveControl = ({ confirmEdit }) => {
  return (
    <RibbonButton
      icon={<SaveOutlined />}
      label={Messages.saveUser}
      onClick={confirmEdit}
    />
  );
};

SaveControl.propTypes = {
  confirmEdit: PropTypes.func,
};

export default SaveControl;
