import React from 'react';
import { Typography, Form, Input } from 'antd';
import { REGEX_CONTACT_PHONE } from 'static/Constants';
import reactCSS from 'reactcss';

const { Text } = Typography;

const styles = reactCSS({
  default: {
    phoneInput: {
      width: 175,
      margin: '0 auto',
    },
    phoneInfo: {
      fontSize: 16,
    },
  },
});

const PhoneInput = (props) => {
  const { onKeyDown } = props;

  return (
    <Form.Item
      className='otp-trigger__phone-input'
      name='phoneNumber'
      colon={false}
      label={false}
      rules={[
        () => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve();
            }

            if (REGEX_CONTACT_PHONE.exec(value)) {
              return Promise.resolve();
            } else {
              return Promise.reject('Invalid phone number');
            }
          },
        }),
        {
          required: true,
          message: `Phone Number is required`,
        },
      ]}
      style={styles.phoneInput}
    >
      <Input
        name='phoneNumber'
        placeholder='Please input phone number'
        onKeyDown={onKeyDown}
      />
    </Form.Item>
  );
};

const PhoneInfo = ({ mfaPhoneNumber }) => {
  return (
    <div>
      <Text strong style={styles.phoneInfo}>
        {mfaPhoneNumber && mfaPhoneNumber}
      </Text>
    </div>
  );
};

export { PhoneInput, PhoneInfo };
