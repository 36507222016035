import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getEulaList = (data) => {
  return api.sendPost(endpoints.GET_EULA_MAINTENANCE_LIST, data);
};

export const deleteEula = (data) => {
  return api.sendPost(endpoints.DELETE_EULA, data);
};

export const createEula = (params) => {
  return api.sendPost(endpoints.CREATE_EULA, params);
};

export const cloneEula = (params) => {
  return api.sendPost(endpoints.CLONE_EULA, params);
};

export const getEulaDetail = (params) => {
  return api.sendPost(endpoints.GET_EULA_DETAIL, params);
};

export const getEulaContentDetail = (data) => {
  return api.sendPost(endpoints.GET_EULA_DETAIL_CONTENT, data);
};

export const getAcceptedUserEula = (data) => {
  return api.sendPost(endpoints.GET_ACCEPTED_USER_EULA, data);
};
