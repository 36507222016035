import { useState, useMemo } from 'react';

import {
  getKeyExpanded,
  getCategoryPath,
  findCategoryNodeById,
} from 'pages/company-profile/components/tabs/system/category-management/utils';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import {
  formatTitleTreeData,
  mappingArrayObjectToArrayKey,
  findKeysOfSearch,
  getKeyExpandList,
  getTargetNodesById,
  findNodesOfSearch,
  findLeafNodeInSignleTree,
} from './utils';

const useProductCategoryTree = ({
  nestedCategory,
  onSelect,
  mode,
  bulkReassignFormName,
  setBulkReassignFormName,
}) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedKeys, setSelectedKeys] = useState(() => {
    if (mode === 'bulkReassignProducts')
      return {
        from: null,
        to: null,
      };
    return [];
  });

  const filterTree = useMemo(() => {
    if (!nestedCategory.length) return [];

    if (!searchValue)
      return formatTitleTreeData(searchValue, selectedKeys, nestedCategory);

    const keyList = findKeysOfSearch(nestedCategory, searchValue);
    //['1-2','1-2-3','4','4-5-6'] => [1,4],filter duplicate key expand and get category level 1 list
    const keyRootList = Array.from(
      new Set(keyList.map((item) => Number(item.split('-')?.[0])))
    );

    const nodeRootList = getTargetNodesById(nestedCategory, keyRootList);
    return formatTitleTreeData(searchValue, selectedKeys, nodeRootList);
  }, [selectedKeys, searchValue, nestedCategory]);

  const handleSearch = (value) => {
    const nodeList = findNodesOfSearch(nestedCategory, value);
    const keyExpand = getKeyExpandList(nodeList);

    setExpandedKeys(keyExpand);
    setSearchValue(value);
  };

  const handleExpandAll = () => {
    const newKeys = getKeyExpanded(filterTree);
    setExpandedKeys(newKeys);
  };

  const handleExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  const handleSelected = (key, info) => {
    const nodeFound =
      mode === 'bulkReassignProducts' ? info.node : info.selectedNodes;

    if (mode === 'bulkReassignProducts') {
      if (!bulkReassignFormName && nodeFound) {
        setBulkReassignFormName('from');

        setSelectedKeys({
          to: null,
          from: nodeFound.key,
        });
        onSelect(nodeFound);

        return;
      }

      const isKeyInKeySelected = Object.values(selectedKeys).includes(
        nodeFound.key
      );
      const isNodeSelected = nodeFound && !isKeyInKeySelected;

      if (
        selectedKeys[bulkReassignFormName] !== nodeFound?.key &&
        isKeyInKeySelected
      ) {
        return;
      }

      setSelectedKeys((prev) => ({
        ...prev,
        [bulkReassignFormName]: isNodeSelected ? nodeFound.key : null,
      }));

      onSelect(isNodeSelected ? nodeFound : null);

      return;
    }
    setSelectedKeys(key);

    onSelect(nodeFound.length ? nodeFound?.[0] : null, info);
  };

  const unSelectNode = (e) => {
    if (e.detail === 'reset-category-modal') {
      setSelectedKeys([]);
      onSelect(null);
    }
  };

  const selectNodeCreated = (e) => {
    if (mode === 'add') {
      const namePath = getCategoryPath(e.detail.categoryData?.[0]);
      const nodeLeaf = findLeafNodeInSignleTree(e.detail.categoryData);

      const keySelected = e.detail.categoryIds.join('-');

      setSelectedKeys([keySelected]);
      const nodeSelected = {
        ...nodeLeaf,
        isLeaf: true,
        namePath,
        key: keySelected,
        categoryLevel: nodeLeaf.level,
      };

      const keyExpand = getKeyExpandList([nodeSelected]);
      const nodeFoundMapping = findCategoryNodeById(
        nodeSelected.id,
        nestedCategory
      );
      onSelect(nodeFoundMapping);
      if (mode === 'add')
        setExpandedKeys((prev) => {
          const keyExpandFiltered = Array.from(
            new Set([...prev, ...keyExpand])
          );

          return keyExpandFiltered;
        });
    }
  };

  useDocumentAttachEvent({
    name: 'unSelectNode',
    handler: unSelectNode,
  });

  useDocumentAttachEvent({
    name: 'select-node-created',
    handler: selectNodeCreated,
  });

  const keySelectedMapping = mappingArrayObjectToArrayKey(
    mode === 'bulkReassignProducts',
    selectedKeys
  );

  return {
    handleSearch,
    filterTree,
    handleExpandAll,
    handleExpand,
    handleSelected,
    expandedKeys,
    setExpandedKeys,
    keySelectedMapping,
    setSelectedKeys,
  };
};

export default useProductCategoryTree;
