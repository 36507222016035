import { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import { CustomNotification } from 'common/components';

import * as productServices from 'services/product';
import * as qaSpecServices from 'services/qaSpec';

import { useGetProductSchema } from 'hooks';
import { useSyncProductModules } from 'pages/product-full-view/components/product-new-detail-view/hooks';
import { useInvalidateGroupDetail } from 'pages/product-full-view/components/product-media-area/shared/hooks';
import { getQaPackagingTabDataQueryKey } from 'pages/qa-spec/components/qa-packaging/queries';
import { useGetDataProductItemModules } from 'pages/product-full-view/shared/hooks';

import { forwardTo } from 'utils/common/route';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

export const qaAllergensKeys = {
  list: (productId) => ['qa-spec', parseInt(productId), 'allergens'],
};

export const filterAllergenProperties = (productSchema) => {
  if (productSchema?.length === 0) return [];

  const allergenModuleProperties =
    productSchema.find((item) => item.moduleName === 'AllergenInformation')
      .moduleProperties ?? [];

  return findAllergenProperty({
    moduleProperties: allergenModuleProperties,
    propertyName: 'Allergen',
  });
};

export const useGetAllergenProperty = () => {
  const { productSchema } = useGetProductSchema();

  return useMemo(
    () => filterAllergenProperties(productSchema),
    [productSchema]
  );
};

export const findAllergenProperty = ({ moduleProperties, propertyName }) => {
  for (let i = 0; i < moduleProperties.length; i++) {
    if (
      moduleProperties?.[i]?.propertyName?.toLowerCase() ===
      propertyName?.toLowerCase()
    ) {
      return moduleProperties[i];
    }
    if (moduleProperties[i]?.childProperties) {
      const result = findAllergenProperty({
        moduleProperties: moduleProperties[i].childProperties,
        propertyName,
      });
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const useGetSpecAllergens = (productId) => {
  const queries = useQuery({
    queryKey: qaAllergensKeys.list(productId),
    queryFn: async () => {
      const response = await productServices.getQaProductAllergen({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId,
  });
  // console.log('useGetSpecAllergens  queries:', queries);

  return {
    qaAllergenList: queries?.data ?? null,
    ...queries,
  };
};

export const useMappingQaAllergenModel = (allergenModel, allergenStatement) => {
  return useMemo(() => {
    return {
      allergenUnitedStates:
        allergenModel?.allergenUnitedStates?.map((allergenItem) => ({
          ...allergenItem,
          uuid: uuidv4(),
        })) ?? [],
      allergenWorldWide:
        allergenModel?.allergenWorldWide?.map((allergenItem) => ({
          ...allergenItem,
          uuid: uuidv4(),
        })) ?? [],
      allergenForm: {
        allergenStatement,
        allergenSpecificationAgency: allergenModel?.allergenSpecificationAgency,
        allergenSpecificationName: allergenModel?.allergenSpecificationName,
      },
    };
  }, [allergenModel, allergenStatement]);
};

export const useSaveAllergenGroup = (productId) => {
  const queryClient = useQueryClient();

  const { handleSyncAllergens } = useSyncProductModules();
  const { handleSyncAllQaDetailGroup } = useInvalidateGroupDetail(productId);
  const { refetchDataProductModules } = useGetDataProductItemModules(
    productId,
    false //* isEnabled
  );

  return useMutation({
    mutationFn: productServices.SaveQaProductAllergen,
    onSuccess: (result) => {
      if (result?.isSuccess) {
        handleSyncAllQaDetailGroup();
        handleSyncAllergens(productId);
        queryClient.invalidateQueries({
          queryKey: qaAllergensKeys.list(productId),
        });
        queryClient.invalidateQueries({
          queryKey: getQaPackagingTabDataQueryKey(productId),
        });
        refetchDataProductModules();
      } else {
        CustomNotification.error(result?.message ?? 'Something went wrong!');
      }
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });
};

export const TYPE_ALLERGEN_ACTIONS = {
  addAllergen: 'ADD_ALLERGEN',
  editAllergen: 'EDIT_ALLERGEN',
  deleteAllergen: 'DELETE_ALLERGEN',
};

export const useVisibleAllergenModal = () => {
  const [visibleModal, setVisibleModal] = useState(null);

  const handler = useMemo(
    () => ({
      onAllergenModal: (typeModal) => setVisibleModal(typeModal),
      offAllergenModal: () => setVisibleModal(null),
    }),
    []
  );

  const visibleAddAllergen = visibleModal === TYPE_ALLERGEN_ACTIONS.addAllergen;
  const visibleEditAllergen =
    visibleModal === TYPE_ALLERGEN_ACTIONS.editAllergen;
  const visibleDeleteAllergen =
    visibleModal === TYPE_ALLERGEN_ACTIONS.deleteAllergen;

  return {
    visibleAddAllergen,
    visibleEditAllergen,
    visibleDeleteAllergen,
    handler,
  };
};

export const customAllergenKeys = {
  addCustomAllergen: (productId) => [
    'add-custom-allergen',
    parseInt(productId),
  ],
};

export const useAddCustomAllergen = (productId) => {
  const queryClient = useQueryClient();

  const { handleSyncAllQaDetailGroup } = useInvalidateGroupDetail(productId);
  const { handleSyncAllergens } = useSyncProductModules();

  return useMutation({
    mutationFn: qaSpecServices.addCustomAllergen,
    mutationKey: customAllergenKeys.addCustomAllergen(productId),
    onSuccess: (result) => {
      if (result?.isSuccess) {
        handleSyncAllergens(productId);
        handleSyncAllQaDetailGroup();
        queryClient.invalidateQueries({
          queryKey: qaAllergensKeys.list(productId),
        });
      } else {
        CustomNotification.error(result?.message ?? 'Something went wrong!');
      }
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });
};

// Snapshot
export const qaAllergensSnapshotKeys = {
  list: (productId) => ['pim', 'snapshot', parseInt(productId), 'qa-allergens'],
};

export const useGetSpecAllergensSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey: qaAllergensSnapshotKeys.list(productId),
    queryFn: async () => {
      const response = await productServices.getQaAllergensSnapshot({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    qaAllergenListSnapshot: queries?.data ?? null,
    ...queries,
  };
};
