import React, { useState, useRef, useEffect } from 'react';

import { Tabs, Space, Popover } from 'antd';
// import { dialogFunction } from 'common/components';
import { dialogFunction } from 'common/components';

import QaSpecTabHeader from './QaSpecTabHeader';
import ExpandTabButton from './ExpandTabButton';
import DownloadSpecButton from './DownloadSpecButton';
import LoadTestResultButton from './LoadTestResultButton';
import { RequiredFieldsError } from '../qa-spec-header-form/RequiredFieldsError';

import { useResize } from 'hooks/useResize';
import { useTabExpand } from 'hooks/useTabExpandProduct';

import { useCheckAllowEdit } from 'pages/qa-spec/hooks';
import { useBlockRouteChange } from 'hooks/router/block-routing';
import {
  useQaTabChecker,
  EditTabSectionWrapper,
  EditTabConfirmModal,
} from 'pages/qa-spec/components/edit-tab-checker';

import { forwardTo } from 'utils/common/route';

import './QaSpecTab.less';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';
import {
  CONTAINER_EXPANDED_WIDTH,
  CONTAINER_COLLAPSED_WIDTH,
} from 'pages/qa-spec/constant';

const { TabPane } = Tabs;

const QaSpecTab = (props) => {
  const { productId, productHeaderImageSize, tabList = [] } = props;

  const { toggleTabExpand, isTabExpanded } = useTabExpand();
  const [activeTab, setActiveTab] = useState('product-specification');
  const [showEditTabConfirmModal, setShowEditTabConfirmModal] = useState();
  const tempActiveTab = useRef();
  const tabContainerRef = useRef();

  const [containerWidth] = useResize(tabContainerRef.current);
  const { editItemListArray } = useQaTabChecker();

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const toggleEditTabConfirmModal = () => {
    setShowEditTabConfirmModal((prev) => !prev);
  };

  const handleChangeActiveTab = (activeKey) => {
    tempActiveTab.current = activeKey;
    if (editItemListArray?.length > 0) {
      toggleEditTabConfirmModal();
    } else {
      setActiveTab(activeKey);
    }
  };

  const onChangeTab = () => {
    setShowEditTabConfirmModal(false);
    setActiveTab(tempActiveTab.current);
  };

  const isAllowEditQaSpec = useCheckAllowEdit(productId);

  const checkActiveTab = () =>
    tabList?.some((item) => item?.key === activeTab && !!item?.visible);
  const hasActiveTab = checkActiveTab();

  useEffect(() => {
    if (!hasActiveTab) setActiveTab('product-specification');
  }, [hasActiveTab]);

  const {
    nextUrl,
    isShowModal: isShowWarningModal,
    onConfirmRouting,
    onCancleRouting,
    watchRoutingConfirm,
  } = useBlockRouteChange({
    isPreventRouting: editItemListArray?.length > 0,
  });

  useEffect(() => {
    if (isShowWarningModal) {
      const checkAllBlockedUrl = async () => {
        const confirmYes = await watchRoutingConfirm();

        if (nextUrl && confirmYes) {
          forwardTo(nextUrl);
        }
      };

      const handleClickOk = async () => {
        onConfirmRouting();
        checkAllBlockedUrl();
      };

      dialogFunction({
        type: 'warn',
        content: `Are you sure you want to leave without saving your changes?`,
        onCancel: () => {
          onCancleRouting();
        },
        onOk: handleClickOk,
      });
    }
  }, [
    isShowWarningModal,
    nextUrl,
    onConfirmRouting,
    onCancleRouting,
    watchRoutingConfirm,
  ]);

  const extraContent = (
    <Space size={12}>
      <RequiredFieldsError productId={productId} />

      {!isQASpecLocked ? (
        <>{isAllowEditQaSpec ? <LoadTestResultButton /> : null}</>
      ) : null}

      <DownloadSpecButton productId={productId} />

      <ExpandTabButton
        onClick={toggleTabExpand}
        isTabExpanded={isTabExpanded}
      />
    </Space>
  );

  const checkShowTabActionPopover = () => {
    if (!containerWidth) return false;

    //* when expanded
    if (isTabExpanded) return containerWidth <= CONTAINER_EXPANDED_WIDTH;

    //* when not expanded
    return containerWidth <= CONTAINER_COLLAPSED_WIDTH;
  };

  const extraPopoverVisible = checkShowTabActionPopover();

  return (
    <Popover
      visible={extraPopoverVisible}
      placement='topRight'
      content={extraContent}
      zIndex={10}
    >
      <div ref={tabContainerRef} className='qa-spec-tab'>
        <div className='qa-spec-tab__absolute-area'>
          {containerWidth && !extraPopoverVisible ? extraContent : null}
        </div>

        <EditTabSectionWrapper.TabCheckerWrapper onChangeTab={setActiveTab}>
          <Tabs
            type='card'
            activeKey={activeTab}
            onChange={handleChangeActiveTab}
            destroyInactiveTabPane={false}
          >
            {tabList.map((tabItem, idx) => {
              const { key, icon, des, content, visible } = tabItem;
              const isTabActive = key === activeTab;

              return visible ? (
                <TabPane
                  tab={
                    <QaSpecTabHeader
                      icon={icon}
                      des={des}
                      idx={idx}
                      isTabExpanded={isTabExpanded}
                      productHeaderImageSize={productHeaderImageSize}
                    />
                  }
                  key={key}
                  style={{ padding: 8 }}
                >
                  {content(isTabActive)}
                </TabPane>
              ) : null;
            })}
          </Tabs>
        </EditTabSectionWrapper.TabCheckerWrapper>
        <EditTabConfirmModal
          visible={showEditTabConfirmModal}
          onCancel={toggleEditTabConfirmModal}
          onChangeTab={onChangeTab}
          tempActiveTab={tempActiveTab}
        />
      </div>
    </Popover>
  );
};

export default QaSpecTab;
