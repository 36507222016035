import React from 'react';
import reactCSS from 'reactcss';
import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';

import { useTabExpandProductDetail } from 'hooks/useTabExpandProduct';

import { Button } from 'antd';

export const COMPONENT_TYPE = {
  productMeta: 'product-meta',
  queryPropertyRight: 'product-query-property',
};

const WithProductDetailExpandBtn = (props) => {
  const { children, componentType } = props;

  const { toggleTabExpand, isTabExpanded: isExpanded } =
    useTabExpandProductDetail();

  const onClick = () => {
    toggleTabExpand();
  };

  const productExpandStyle = {
    [COMPONENT_TYPE.productMeta]: {
      right: 10,
      top: 10,
      display: isExpanded ? 'none' : 'block',
    },
    [COMPONENT_TYPE.queryPropertyRight]: {
      right: 6,
      top: -2,
      display: isExpanded ? 'block' : 'none',
    },
  };

  const styles = reactCSS({
    default: {
      expandBtn: {
        position: 'absolute',
        zIndex: 10,
        ...productExpandStyle?.[componentType],
      },
      wrapper: {
        position: 'relative',
      },
      iconWrap: {
        fontSize: 20,
      },
    },
  });

  return (
    <div style={styles.wrapper}>
      <Button
        style={styles.expandBtn}
        icon={
          <span style={styles.iconWrap}>
            {isExpanded ? <ShrinkOutlined /> : <ExpandAltOutlined />}
          </span>
        }
        onClick={onClick}
        type='text'
      />
      {children && children}
    </div>
  );
};

export default WithProductDetailExpandBtn;
