import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const validateMinimumRequirement = (params) => {
  return api.sendPost(endpoints.VALIDATE_MINIMUM_REQUIREMENT, params);
};

export const getMinimumRequirementFieldDetailService = (params) => {
  return api.sendGet(endpoints.GET_MINIMUM_FIELD_REQUIREMENT_DETAIL, {
    params: params,
  });
};
export const getAllProductFields = () => {
  return api.sendGet(endpoints.GET_ALL_PRODUCT_FIELDS);
};

export const getAllAssetFields = () => {
  return api.sendGet(endpoints.GET_ALL_ASSET_FIELDS);
};

export const getAllMemberFields = () => {
  return api.sendGet(endpoints.GET_ALL_MEMBER_FIELDS);
};

export const createMinimumRequirements = (params) => {
  return api.sendPost(endpoints.CREATE_MINIMUM_REQUIREMENTS, params);
};

export const deleteMinimumRequirement = (params) => {
  return api.sendPost(endpoints.DELETE_MINIMUM_REQUIREMENTS, params);
};

export const getEntityMinimumRequirementDetail = (params) => {
  return api.sendGet(endpoints.GET_ENTITY_MINIMUM_REQUIREMENT_DETAIL, {
    params,
  });
};

export const editMinimumRequirement = (params) => {
  return api.sendPost(endpoints.EDIT_MINIMUM_REQUIREMENTS, params);
};

export const getDetailMinimumRequirements = (params) => {
  return api.sendGet(endpoints.GET_DETAIL_MINIMUM_REQUIREMENTS, {
    params,
  });
};

export const updateUnmatchedRequirements = (params) => {
  return api.sendPost(endpoints.UPDATE_UNMATCHED_REQUIREMENTS, params);
};

export const getMinimumRequirement = (params) => {
  return api.sendPost(endpoints.GET_MINIMUM_REQUIREMENTS, params);
};
