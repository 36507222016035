import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useLocation } from 'react-router-dom';
import { Menu, Dropdown, Modal, notification, Divider } from 'antd';

import {
  SubnodeOutlined,
  DownloadOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  FileAddOutlined,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import {
  ButtonSmallIcon,
  RibbonButton,
  UploadFile,
  dialogFunction,
  DownloadFormItem,
  MinimumRequirementsValidate,
} from 'common/components';

import ProductSubscriptionModal from 'common/components/product-subscription/ProductSubscriptionModal';
import CreateProductViaFormModal from 'common/components/product-add/CreateProductViaFormModal';
import GDSNPublication from 'common/components/product-publication-setup/GDSNPublication';
import MoveProductsToMember from '../../controls/product/MoveProductsToMember';
// import AssignProductToSupplier from '../../controls/product/AssignProductToSupplier';
import WithdrawPublicationButton from '../../controls/product/WithdrawPublicationButton';
import BulkEditProduct from '../../controls/product/BulkEditProduct';
import DownloadNifFormSection from './DownloadNifFormSection';
import HistoryEntity from 'pages/home/ribbon/form-manange/components/form-history-grid/HistoryEntity';
import CopyProductPropertiesModal from 'pages/home/ribbon/components/modal/bulk-edit-product/CopyProductPropertiesModal';

import messagesProduct from 'i18n/messages/product';
import messages from 'i18n/messages/home';

import {
  getProductViaSpreadSheet,
  getTdcProductViaSpreadSheet,
  deleteProductItems,
} from 'services/product';

import {
  IMPORT_PRODUCT_VIA_SPREADSHEET,
  IMPORT_TDC_PRODUCT_VIA_SPREADSHEET,
} from 'services/product/endpoints';

import { toggleReloadGridProduct } from 'pages/branded-products/controllers/actions';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import ErrorModal from 'common/components/product-publication-setup/ErrorModal.js';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';

import userSelectors from 'redux/user/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import * as brandingActions from 'redux/branding/actions';

import { saveFile } from 'utils/saveFile';
import {
  useCheckAllowDeleteProducts,
  usePermissionEditSharedProduct,
  useCheckIsSuperAdmin,
  useCheckAllowEditProducts,
} from 'hooks';

import { CanShow } from 'common/components/wrapper/show-condition';
import AssignCategoryToProduct from './AssignCategoryToProduct';

import { Can } from 'context/Can';
import {
  ABILITY_ACTION,
  ABILITY_SUBJECT,
  PERMISSION_CREATE_PRODUCTS,
  PERMISSION_EDIT_PRODUCTS,
  PERMISSION_VIEW_NEW_ITEM_FORMS,
} from 'static/Permission';

import { UPLOAD_MAX_SIZE, ROUTE } from 'static/Constants';

import { useCheckDisableWithdrawPublicationModal } from 'hooks';

import './ActionProduct.less';
import PostToWalmart from 'common/components/product-publication-setup/PostToWalmart';
import IXOneSection from './IXOneSection';
import useCheckIXOneCapture from 'hooks/useCheckIXOneCapture';

const { SubMenu } = Menu;

const ActionProduct = (props) => {
  const { viewType, memberId } = props;

  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();

  const isShowIXOneCapture = useCheckIXOneCapture();

  //* mock - 11/24/2021
  const [enabledSubscribe, setEnabledSubscribe] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [visibleSubscriptionModal, setVisibleSubscriptionModal] =
    useState(false);
  const [cloneModalVisible, setCloneModalVisible] = useState(false);

  const [updateType, setUploadType] = useState('pim');

  let detailItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const { checkAllowEditProductFull, checkAllowBulkEditProducts } =
    useCheckAllowEditProducts();

  const isFavoriteRoute = useCheckFavoriteRoute();

  const useVisibleModal = [
    visibleSubscriptionModal,
    setVisibleSubscriptionModal,
  ];

  const isAllowBulkEditProduct = checkAllowBulkEditProducts();

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const hasPermissionEditProducts = useCheckPermissions(
    PERMISSION_EDIT_PRODUCTS
  );

  const hasPermissionCreateProducts = useCheckPermissions(
    PERMISSION_CREATE_PRODUCTS
  );

  const hasPermissionEditMember = useCheckPermissions([
    {
      action: ABILITY_ACTION.EDIT,
      subject: ABILITY_SUBJECT.MEMBER,
    },
  ]);

  const hasPermissionEditSharedProduct = usePermissionEditSharedProduct();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const { checkAllowDeleteProductGrid } = useCheckAllowDeleteProducts();

  const isAllowActionsPim =
    isSuperAdmin || hasPermissionEditProducts || hasPermissionEditSharedProduct;

  const isAllowEditFullFirstProduct = checkAllowEditProductFull(
    detailItems?.[0]
  );

  const isOneProductSelected = detailItems?.length === 1;

  const isAllowEditMinimumRequirement =
    isAllowEditFullFirstProduct && isOneProductSelected;

  const detailItemIds = detailItems.map((detailItem) => {
    return detailItem.id;
  });

  const isAllowDeleteProducts = checkAllowDeleteProductGrid();

  const isFavorited = location.pathname === '/favorite/favorite-products';

  const hasCreateMemberProduct =
    viewType === ROUTE.MEMBER_PRODUCTS &&
    memberId !== userInfo?.member?.id &&
    !hasPermissionEditMember;

  const disabledAddProduct = isFavorited || hasCreateMemberProduct;

  const checkNotMyOwner = (item) => {
    return !item.isOwner;
  };

  const isDisableWithdrawPublicationModal =
    useCheckDisableWithdrawPublicationModal(detailItems?.[0]) ||
    detailItems?.length > 1;

  const handleDownloadTemplateProduct = (type) => async () => {
    try {
      const response =
        type === 'tdc'
          ? await getTdcProductViaSpreadSheet()
          : await getProductViaSpreadSheet();

      const urlPath = type === 'tdc' ? 'fileUrl' : 'url';

      if (response.isSuccess) {
        saveFile(response.data[urlPath]);
      } else {
        notification.error({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickUploadTemplate = (type) => () => {
    setVisibleModal(true);
    setUploadType(type);
  };

  useEffect(() => {
    if (detailItems?.length === 1 && checkNotMyOwner(detailItems[0])) {
      setEnabledSubscribe(true);
    } else {
      setEnabledSubscribe(false);
    }
  }, [JSON.stringify(detailItems)]);

  const confirmDeleteProducts = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the selected product(s)?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDeleteProducts,
    });
  };

  const handleDeleteProducts = async () => {
    try {
      const productIds = detailItems.map((product) => product.id);
      const response = await deleteProductItems({
        ids: productIds,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Delete product(s) successfully!',
        });
        dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));
        setTimeout(() => {
          dispatch(toggleReloadGridProduct());
        }, 300);

        if (isFavoriteRoute) {
          dispatch(brandingActions.getBrandingNoLoading());
        }
      } else {
        notification.error({
          message: message || 'Cannot delete product(s)!',
        });
      }
    } catch (error) {
      notification.error({ message: error || 'Cannot delete product(s)!' });
    }
  };

  const viewNewItemForm = useCheckPermissions(PERMISSION_VIEW_NEW_ITEM_FORMS);

  const notSelectedItem = detailItems.length === 0;

  const isAllowToDownloadNifForm =
    (!notSelectedItem && viewNewItemForm) || (!notSelectedItem && isSuperAdmin);

  const renderAddProductMenu = (
    <>
      <Menu.Item
        key='pim-add-product'
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.PRODUCT} passThrough>
          {(allowed) => (
            <ButtonSmallIcon
              className='button-item-dropdown'
              icon={<FileAddOutlined />}
              label={messages.createProduct}
              onClick={() => setAddModalVisible(true)}
              disabled={!allowed}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item
        key='pim-download-template'
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT} passThrough>
          {(allowed) => (
            <ButtonSmallIcon
              className='button-item-dropdown'
              icon={<DownloadOutlined />}
              label={messages.downloadTemplate}
              onClick={handleDownloadTemplateProduct('pim')}
              disabled={!allowed}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item
        key='pim-upload-template'
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <ButtonSmallIcon
          className='button-item-dropdown'
          icon={<UploadOutlined />}
          label={messages.uploadTemplate}
          onClick={onClickUploadTemplate('pim')}
          disabled={!isAllowActionsPim}
        />
      </Menu.Item>
      <Menu.Item
        key='download-tdc-template'
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL} passThrough>
          {(allowed) => (
            <ButtonSmallIcon
              className='button-item-dropdown'
              icon={<DownloadOutlined />}
              label={messages.downloadTdcTemplate}
              onClick={handleDownloadTemplateProduct('tdc')}
              disabled={!allowed || viewType === ROUTE.MEMBER_PRODUCTS}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item
        key='upload-tdc-template'
        style={{
          backgroundColor: 'inherit',
        }}
        passThrough
      >
        <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL} passThrough>
          {(allowed) => (
            <ButtonSmallIcon
              className='button-item-dropdown'
              icon={<UploadOutlined />}
              label={messages.uploadTdcTemplate}
              onClick={onClickUploadTemplate('tdc')}
              disabled={!allowed || viewType === ROUTE.MEMBER_PRODUCTS}
            />
          )}
        </Can>
      </Menu.Item>
    </>
  );

  const menuOptions = (
    <Menu>
      {/* {isShowIXOneCapture ? (
        <>
          <Menu.Item>
            <IXOneSection
              size='small'
              label='Send to IX-ONE for Capture'
              width='20px'
            />
          </Menu.Item>
          <Divider style={{ margin: 0 }} />
        </>
      ) : null} */}

      {hasPermissionCreateProducts && (
        <CanShow.SharedOnlyMember>
          <SubMenu
            key='add-product'
            style={{ backgroundColor: 'inherit' }}
            title={
              <ButtonSmallIcon
                className='button-item-dropdown'
                icon={<FileAddOutlined />}
                label={messages.addProduct}
                disabled={disabledAddProduct}
              />
            }
            disabled={disabledAddProduct}
          >
            {renderAddProductMenu}
          </SubMenu>
        </CanShow.SharedOnlyMember>
      )}

      <Menu.Item
        key='pim-clone-product'
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <ButtonSmallIcon
          className='button-item-dropdown'
          icon={<CopyOutlined />}
          label={messages.productClone}
          onClick={() => setCloneModalVisible(true)}
          disabled={!isAllowEditMinimumRequirement}
        />
      </Menu.Item>

      {isAllowDeleteProducts && (
        <Menu.Item
          key='pim-delete-product'
          style={{ backgroundColor: 'inherit' }}
        >
          <ButtonSmallIcon
            className='button-item-dropdown'
            icon={<DeleteOutlined />}
            label={messages.deleteProduct}
            disabled={detailItems?.length < 1}
            onClick={confirmDeleteProducts}
          />
        </Menu.Item>
      )}

      <CanShow.SharedOnlyMember>
        <Menu.Item
          key='pim-subscribe-hierarchies'
          style={{ backgroundColor: 'inherit' }}
        >
          <ButtonSmallIcon
            className='button-item-dropdown'
            icon={<MenuUnfoldOutlined />}
            label={messages.pimSubscribe}
            onClick={() => setVisibleSubscriptionModal(true)}
            disabled={!enabledSubscribe}
          />
        </Menu.Item>

        <Menu.Item
          key='gdsn-syndication'
          style={{ backgroundColor: 'inherit' }}
        >
          <GDSNPublication products={detailItems} />
        </Menu.Item>

        {isSuperAdmin && (
          <Menu.Item
            key='post-to-walmart'
            style={{
              backgroundColor: 'inherit',
            }}
          >
            <PostToWalmart />
          </Menu.Item>
        )}

        <Menu.Item
          key='pim-delete-product-publication'
          style={{ backgroundColor: 'inherit' }}
        >
          <WithdrawPublicationButton
            products={detailItems}
            isDisabled={isDisableWithdrawPublicationModal}
          />
        </Menu.Item>
        <Menu.Item
          key='pim-download-nif-form'
          style={{
            backgroundColor: 'inherit',
          }}
          disabled={!isAllowToDownloadNifForm}
        >
          <DownloadNifFormSection
            detailItems={detailItems}
            isAllowToDownloadNifForm={isAllowToDownloadNifForm}
          />
        </Menu.Item>
        <Menu.Item
          key='move-product-to-member'
          style={{ backgroundColor: 'inherit' }}
        >
          <MoveProductsToMember disabled={!isAllowActionsPim} />
        </Menu.Item>
      </CanShow.SharedOnlyMember>

      <SubMenu
        key={'bulk-edit-product'}
        style={{ backgroundColor: 'inherit' }}
        disabled={!isAllowActionsPim}
        title={
          <RibbonButton
            size='small'
            className='button-item-dropdown'
            icon={<DownloadOutlined />}
            disabled={!isAllowActionsPim}
            label={messagesProduct.bulkEditProduct}
          />
        }
      >
        <BulkEditProduct
          disabled={!isAllowActionsPim}
          disabledExport={!isAllowBulkEditProduct}
          selectedProducts={detailItemIds}
        />
      </SubMenu>

      <CanShow.SharedOnlyMember>
        <Menu.Item
          key='download-splash-form'
          style={{ backgroundColor: 'inherit' }}
          disabled={notSelectedItem}
        >
          <DownloadFormItem
            selectedEntityIds={detailItemIds}
            disabled={notSelectedItem}
            entityType='PIM'
          />
        </Menu.Item>

        <Menu.Item key='history-entity' style={{ backgroundColor: 'inherit' }}>
          <HistoryEntity
            size='small'
            entityId={detailItems?.length > 0 && detailItems[0]?.id}
            entityType='PIM'
            disabled={detailItems?.length !== 1}
          />
        </Menu.Item>
      </CanShow.SharedOnlyMember>

      <Menu.Item key='validate-product' style={{ backgroundColor: 'inherit' }}>
        <MinimumRequirementsValidate
          entity={
            detailItems.length === 1 && {
              id: detailItems[0].id,
              name: detailItems[0].productName,
              gtin: detailItems[0].gtin,
              type: 'Product',
              data: detailItems[0],
            }
          }
          disable={!isAllowEditMinimumRequirement}
        />
      </Menu.Item>
      <Can I={ABILITY_ACTION.ASSIGN} a={ABILITY_SUBJECT.PRODUCT_CATEGORY}>
        <Menu.Item
          key='assign-category-to-product'
          style={{ backgroundColor: 'inherit' }}
        >
          <AssignCategoryToProduct isProductDetail={false} />
        </Menu.Item>
      </Can>
      {/* <Menu.Item
        key='assign-product-to-supplier'
        style={{ backgroundColor: 'inherit' }}
      >
        <AssignProductToSupplier />
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
        <RibbonButton icon={<SubnodeOutlined />} label={messages.pimActions} />
      </Dropdown>

      {visibleModal && (
        <ModalUploadTemplateProduct
          visibleModal={visibleModal}
          turnOffModal={() => setVisibleModal(false)}
          type={updateType}
          memberId={memberId}
        />
      )}

      {visibleSubscriptionModal && (
        <ProductSubscriptionModal
          useVisibleModal={useVisibleModal}
          type='Product'
          modalTitle={intl.formatMessage(messages.subscribeProduct)}
          id={detailItems?.length === 1 && detailItems[0]?.id}
        />
      )}

      {addModalVisible && (
        <CreateProductViaFormModal
          addModalVisible={addModalVisible}
          setAddModalVisible={setAddModalVisible}
          memberId={memberId}
        />
      )}

      {cloneModalVisible && (
        <CopyProductPropertiesModal
          visible={cloneModalVisible}
          setVisible={setCloneModalVisible}
          productId={detailItems[0]?.id}
        />
      )}
    </>
  );
};

const ModalUploadTemplateProduct = (props) => {
  const { visibleModal, turnOffModal, type, memberId } = props;
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    if (!visibleModal) setErrorList([]);
  }, [visibleModal]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleResponseUpload = (response) => {
    const successMessage =
      type === 'tdc'
        ? messagesProduct.uploadTdcSuccess
        : messagesProduct.createProductSuccess;

    if (response?.data) {
      if (response?.data?.isSuccess) {
        notification.success({
          message: intl.formatMessage(successMessage),
        });

        turnOffModal();

        if (type === 'tdc') return;

        dispatch(brandingActions.getBrandingNoLoading());

        setTimeout(() => {
          dispatch(toggleReloadGridProduct());
        }, 100);
      } else {
        if (response?.data?.errors) {
          setErrorList(response?.data?.errors);
        }
      }
    }
  };

  const apiUrl =
    type === 'tdc'
      ? IMPORT_TDC_PRODUCT_VIA_SPREADSHEET
      : IMPORT_PRODUCT_VIA_SPREADSHEET;

  const nameFormData = type === 'tdc' ? 'file' : 'TemplateFile';

  return (
    <Modal
      width={'50vw'}
      bodyStyle={{ height: '50vh' }}
      title='Upload Template Product'
      visible={visibleModal}
      onCancel={turnOffModal}
      closable={true}
      maskClosable={false}
      footer={null}
      destroyOnClose
      centered
    >
      <div className='action-product__modal'>
        <UploadFile
          uploadAPL={true}
          manualUpload={true}
          isBase64={false}
          showMultiple={false}
          showXlsTemplate={false}
          apiUrl={apiUrl}
          nameFormData={nameFormData}
          showUploadSuccessMsg={false}
          showServerMessage={false}
          notShowMessage={true}
          getResponse={(response) => handleResponseUpload(response)}
          maxSize={UPLOAD_MAX_SIZE.GENERAL}
          additionalBodyPayload={memberId && { memberId: memberId }}
          supportTypes={['xlsx', 'xls']}
        />
        <div className='action-product__modal--error-text'>
          {errorList && errorList.length > 0 && (
            <ErrorModal errorList={errorList} title={'APL Errors'} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ActionProduct;
