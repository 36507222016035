import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as chatSelectors from '../../controller/selectors';
import * as chatActions from '../../controller/actions';
import * as globalSelectors from 'redux/global/selectors';
import * as globalActions from 'redux/global/actions';
import userSelectors from 'redux/user/selectors';

import InfiniteScroll from 'react-infinite-scroller';
import { Spin, Row, Divider } from 'antd';
import ChatTurnList from './ChatTurnList';
import * as chatServices from 'services/chatServices';
import { MESSAGE_TYPE } from 'static/Constants';
import useClickEntity from 'hooks/useClickEntity';

import mapMesssagesWithAttachUrl from '../../mapper/mapMesssagesWithAttachUrl';

import _ from 'lodash';

import './ChatThread.less';

let scrollBottomTimeout;

const ChatThread = (props) => {
  const {
    threadId,
    participants,
    isFocusChat,
    scrollToBottom,
    toggleNewMessage,
    shouldShowScrollBtn,
    onClickMessageBox,
  } = props;

  const refChatBox = useRef(null);
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(false);
  const [isFinishLoad, setIsFinishLoad] = useState(false);

  const newMsgInfo = useSelector(globalSelectors.selectNewMsgInfo());
  const userId = useSelector(userSelectors.makeSelectUserId());
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());

  const threadMessages = useSelector(chatSelectors.selectThreadMessages());
  const unreadMsgList = useSelector(chatSelectors.selectUnreadMessages());
  const statusThreadMsg = useSelector(chatSelectors.selectStatusThreadMsg());
  const communicationList = useSelector(
    chatSelectors.selectCommunicationList()
  );

  const isScrollChatThreadBottom = useSelector(
    chatSelectors.selectIsScrollChatThreadBottom()
  );

  const isSpinning = useSelector(chatSelectors.selectIsChatSpinningOn());

  const communicationListData = communicationList?.data;

  const isClickChatBox = useClickEntity(refChatBox);
  const userSender = `U-${userId}`;
  const memberSender = `C-${memberId}`;

  const handleScrollDown = (e) => {
    const isFinishLoadDown = e.detail.isFinishLoadDown;
    if (isFinishLoadDown) return;
    clearTimeout(scrollBottomTimeout);
    scrollBottomTimeout = setTimeout(() => {
      loadMoreMessagesDown(e);
    }, 100);
  };

  const loadMoreMessagesDown = (e) => {
    const threadMessages = e.detail.threadMessages;
    if (!threadId && threadMessages.length === 0) return;

    const requestParams = {
      threadId: threadId,
      timestamp:
        threadMessages?.length > 0 ? threadMessages[0]?.sendTime + 1 : null,
      direction: 1, // 0: up; 1: down
    };

    chatServices
      .getThreadMessage(requestParams)
      .then(async (res) => {
        if (res.isSuccess) {
          const messages = res.data.threadMessages;

          const handledMessages = await mapMesssagesWithAttachUrl(
            messages,
            threadId
          );

          if (handledMessages?.length < 20) {
            dispatch(chatActions.updateFinishLoadDown(true));
          }
          if (handledMessages?.length === 0) {
            return;
          }
          dispatch(
            chatActions.getThreadMessagesSuccess([
              ...handledMessages.reverse(),
              ...threadMessages,
            ])
          );
        } else {
          setIsFinishLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.addEventListener('chatScrollBottom', handleScrollDown);
    return () => {
      document.removeEventListener('chatScrollBottom', handleScrollDown);
      clearTimeout(scrollBottomTimeout);
    };
  }, []);

  useEffect(() => {
    dispatch(globalActions.setNewMsgInfo({}));
  }, []);

  useEffect(() => {
    setIsFinishLoad(false);
  }, [threadId]);

  useEffect(() => {
    if (toggleNewMessage) {
      scrollToBottom();
      dispatch(chatActions.toggleNewMessage(false));
    }

    setTimeout(() => {
      scrollToBottom();
    }, 200);
  }, [dispatch, scrollToBottom, toggleNewMessage]);

  useEffect(() => {
    if (isScrollChatThreadBottom) {
      scrollToBottom();
      dispatch(chatActions.scrollChatThreadToBottom(false));
    }
  }, [isScrollChatThreadBottom]);

  useEffect(() => {
    if (threadMessages?.length >= 20) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [threadMessages?.length]);

  useEffect(() => {
    onClickMessageBox && onClickMessageBox(isClickChatBox);
  }, [isClickChatBox]);

  useEffect(() => {
    if (isFocusChat && unreadMsgList?.length > 0) {
      dispatch(chatActions.setClearUnreadMessages());
      onUpdateReadStatusForThread(threadId);
    }
  }, [dispatch, isFocusChat, unreadMsgList?.length]);

  // Check to receive msg with login same account
  useEffect(() => {
    if (checkValidMsg(newMsgInfo, threadId, userSender, memberSender)) {
      dispatch(chatActions.receiveSentMsgSameAccount(newMsgInfo));
      dispatch(chatActions.toggleNewMessage(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, newMsgInfo?.messageId]);

  useEffect(() => {
    /**
     * ? note: check status thread message 'success' || 'idle' to append unread message
     */
    const onSetMsgToUnreadList = () => {
      if (statusThreadMsg === 'idle' || statusThreadMsg === 'success') {
        dispatch(chatActions.filterNewMsgInThreadMsg(newMsgInfo));
        const uniqueUnreadMsg = _.uniq(
          [...unreadMsgList, newMsgInfo],
          'messageId'
        );
        dispatch(chatActions.setUnreadMessages(uniqueUnreadMsg));
      }
    };

    const handleAppendNewMessage = () => {
      if (
        !_.isEmpty(newMsgInfo) &&
        newMsgInfo?.threadId === threadId &&
        newMsgInfo?.senderId !== userSender &&
        newMsgInfo?.senderId !== memberSender
      ) {
        const msgInfo = {
          messageId: newMsgInfo?.messageId,
          sendTime: newMsgInfo?.sendTime,
          messageText: newMsgInfo?.messageText,
          messageType: MESSAGE_TYPE[`${newMsgInfo?.messageType}`],
          senderId: newMsgInfo?.senderId,
          attachmentIds: newMsgInfo?.attachmentIds,
        };

        if (isFocusChat) {
          onSetMsgToMsgList(msgInfo);
        } else {
          onSetMsgToUnreadList();
        }

        // Not scroll to bottom when user is scrolling chat
        if (!shouldShowScrollBtn) {
          setTimeout(() => {
            scrollToBottom();
          }, 100);
        }
      }
    };

    handleAppendNewMessage();
  }, [
    newMsgInfo?.messageId,
    threadId,
    statusThreadMsg,
    //todo - Duy - 11/5/2021 please check dependencies list later
  ]);

  const onSetMsgToMsgList = (msgInfo) => {
    dispatch(chatActions.setNewMsgReceived(msgInfo));
    dispatch(chatActions.setClearUnreadMessages());
  };

  const onLoadMoreThreadMsg = (page) => {
    if (!threadId) return;

    const requestParams = {
      threadId: threadId,
      timestamp:
        threadMessages?.length > 0
          ? threadMessages[threadMessages.length - 1]?.sendTime
          : null,
      direction: 0, // 0: up; 1: down
    };

    chatServices
      .getThreadMessage(requestParams)
      .then(async (res) => {
        if (res.isSuccess) {
          const messages = res.data.threadMessages;

          const handledMessages = await mapMesssagesWithAttachUrl(
            messages,
            threadId
          );

          if (handledMessages?.length === 1) {
            // end
            setIsFinishLoad(true);
            return;
          }
          if (handledMessages?.length < 20) {
            setIsFinishLoad(true);
          } else {
            setIsFinishLoad(false);
          }
          dispatch(
            chatActions.getThreadMessagesSuccess(
              threadMessages.concat(
                handledMessages?.slice(1, handledMessages.length)
              )
            )
          );
        } else {
          setIsFinishLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUpdateReadStatusForThread = (threadId) => {
    let result =
      communicationListData?.length > 0 ? [...communicationListData] : [];

    if (threadId) {
      let existedThreadIndex = result?.findIndex(
        (thread) => thread.threadId === threadId
      );
      if (existedThreadIndex > -1) {
        let threadUpdate = {
          ...communicationListData[existedThreadIndex],
          readStatus: true,
        };
        result[existedThreadIndex] = threadUpdate;

        dispatch(chatActions.updateCommunicationThreadList(result));
      }
    }
  };

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loadMore={onLoadMoreThreadMsg}
        hasMore={hasMore && !isFinishLoad}
        useWindow={false}
        isReverse={true}
        loader={
          <Row justify='center' key={0}>
            <Spin tip='Loading...' />
          </Row>
        }
      >
        <Spin tip='Loading...' spinning={isSpinning}>
          <div className='chat-thread' ref={refChatBox}>
            {!(threadMessages?.length === 0 && unreadMsgList?.length > 0) && (
              <ChatTurnList
                data={threadMessages?.slice().reverse()}
                participants={participants}
              />
            )}
            {unreadMsgList?.length > 0 && (
              <>
                <Divider>Unread Message</Divider>
                <ChatTurnList
                  data={unreadMsgList}
                  participants={participants}
                  isUnread
                />
              </>
            )}
          </div>
        </Spin>
      </InfiniteScroll>
    </>
  );
};

const checkValidMsg = (msgInfo, threadId, userSender, memberSender) => {
  if (!_.isEmpty(msgInfo) && msgInfo.threadId === threadId) {
    return (
      msgInfo?.senderId === userSender || msgInfo?.senderId === memberSender
    );
  }

  return false;
};

export default React.memo(ChatThread);
