import React from 'react';

import { Col, Typography, Select } from 'antd';
import { WrapperSelect } from 'common/components';

import { mdFontList } from 'static/Constants';
import './MdTextFont.less';

const { Text } = Typography;
const { Option } = Select;

const MdTextFont = (props) => {
  const { fontType, handleChangeFontType } = props;

  return (
    <Col>
      <Text className='md-text-font__title'>Font</Text>
      <WrapperSelect
        style={{ width: 120 }}
        defaultValue={fontType}
        onChange={handleChangeFontType}
      >
        {mdFontList.map((fontItem) => {
          return (
            <Option value={fontItem?.value} key={fontItem?.value}>
              <div style={{ fontFamily: fontItem?.value, fontSize: 15 }}>
                {fontItem?.text}
              </div>
            </Option>
          );
        })}
      </WrapperSelect>
    </Col>
  );
};

export default MdTextFont;
