export const GET_REQUEST_GRID = '/api/member-role/get-request-grid';

export const GET_REQUEST_GRID_DISTINCT =
  '/api/member-role/get-request-grid/distinct';

export const CREATE_NEW_REQUEST = '/api/member-role/create-request';

export const EDIT_REQUEST = '/api/member-role/edit-request';

export const GET_LIST_USER_TO_ASSIGN_REQUEST =
  '/api/member-role/get-list-user-to-assign-request';

export const GET_REQUEST_DETAIL_PANEL =
  '/api/member-role/get-request-detail-panel';

export const GET_REQUEST_DISCUSSION_LIST =
  '/api/member-role/get-request-discussion-list';

export const ADD_DISCUSSION = '/api/member-role/add-discussion';

export const UPDATE_LAST_TIME_REQUEST =
  '/api/member-role/update-last-time-get-new-update-on-permission-request';
