import React from 'react';

const newSpanConfigDetect = /\(\#(.*?)(color=|font=|size=)(.*?)\)/g;
const spanFontConfig = /font=(.*?)[\|\)]/;
const spanColorConfig = /color=(.*?)[\|\)]/;
const spanSizeConfig = /size=(.*?)[\|\)]/;
const spanAlignConfig = /align=(.*?)[\|\)]/;

const TextRender = (props) => {
  let originalValue = props?.value;
  let detailAlignConfig;
  let procValue = originalValue
    .replace(newSpanConfigDetect, (foundText) => {
      const colorConfig = spanColorConfig.exec(foundText);
      const fontConfig = spanFontConfig.exec(foundText);
      const sizeConfig = spanSizeConfig.exec(foundText);
      const alignConfig = spanAlignConfig.exec(foundText);
      let detailColorConfig, detailFontConfig, detailSizeConfig;

      if (colorConfig) {
        detailColorConfig = colorConfig[1].trim();
      }
      if (fontConfig) {
        detailFontConfig = fontConfig[1].trim();
      }
      if (sizeConfig) {
        detailSizeConfig = sizeConfig[1].trim();
      }
      if (alignConfig) {
        detailAlignConfig = alignConfig[1].trim();
      }
      return (
        `<span style="color:` +
        `${detailColorConfig ? detailColorConfig : ''}` +
        `; font-family:` +
        `${detailFontConfig ? detailFontConfig : ''}` +
        `; font-size:` +
        `${detailSizeConfig ? detailSizeConfig + 'px' : ''}` +
        `">`
      );
    })
    .replace(/\(~\)/g, `</span>`);
  if (procValue !== originalValue) {
    if (!detailAlignConfig) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: procValue,
          }}
        />
      );
    } else {
      return (
        <div
          style={{ textAlign: detailAlignConfig }}
          dangerouslySetInnerHTML={{
            __html: procValue,
          }}
        />
      );
    }
  }
  if (procValue === originalValue) {
    return <span>{props?.value}</span>;
  }
};

export default TextRender;
