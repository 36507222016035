import React from 'react';
import PropTypes from 'prop-types';

import { forwardTo } from 'utils/common/route';
import { ThumbnailItem } from 'common/components';
import iframe from 'assets/iframe.png';
import * as getLink from 'utils/common/linkBuilder';
/**
 * Asset thumbnail is inherit from thumbnail item
 * @param {object} props
 */
const AssetThumbnailCard = (props) => {
  const {
    dataDetail,
    selected,
    onClickItemGrid,
    onClickCheckboxItem,
    pathname,
    linkTarget,
    style,
    isShareBtn = true,
    hideCheckbox = false,
  } = props;

  const onDoubleClick = (item) => {
    if (props.onDoubleClick) {
      return props.onDoubleClick(item);
    }

    if (!pathname) return;
    const { id } = item;
    forwardTo(`${pathname}/${id}`);
  };

  // Viet - get image with version suffix to prevent cache image
  const imageThumbnail = dataDetail?.thumbSize300;
  const image = dataDetail?.isIFrame ? iframe : dataDetail && imageThumbnail;
  // Bp - add link to asset thumbnail
  const alink = getLink.assetLink(
    dataDetail?.id,
    dataDetail?.assetName,
    linkTarget
  );

  return (
    <ThumbnailItem
      dataDetail={dataDetail}
      labelThumbnail={alink}
      imageThumbnail={dataDetail && image}
      selected={selected}
      onClickItemGrid={onClickItemGrid}
      onClickCheckboxItem={onClickCheckboxItem}
      onDoubleClick={onDoubleClick}
      shareable={dataDetail?.shareable} //shareable class for asset
      isShareBtn={isShareBtn}
      style={style}
      hideCheckbox={hideCheckbox}
    />
  );
};

AssetThumbnailCard.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default React.memo(AssetThumbnailCard);
