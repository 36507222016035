import React from 'react';

import { FullWidthSpace } from 'common/components';
import { useGetEnumOptions } from 'pages/qa-spec/hooks';

import QaSpecProductInfo from './QaSpecProductInfo';
import OrganolepticCharacteristic from './OrganolepticCharacteristic';
import PhyCheCharacteristic from './PhyCheCharacteristic';
import ContaminantCharacteristic from './ContaminantCharacteristic';
import MicrobiologicalCharacteristic from './MicrobiologicalCharacteristic';
import QaSpecProductDocument from './QaSpecProductDocument';

import './QaSpecProduct.less';

const QaSpecProduct = (props) => {
  const { productId, productFull, refetchProductItems, productLoading } = props;

  const enumOptions = useGetEnumOptions();

  const phyAndCheCharacterEnums = enumOptions?.productSpec?.physicAndChem;
  const microbiologicalEnums = enumOptions?.productSpec?.microbiological;

  return (
    <FullWidthSpace size={16}>
      <QaSpecProductInfo
        productId={productId}
        productFull={productFull}
        productLoading={productLoading}
        refetchProductItems={refetchProductItems}
      />
      <OrganolepticCharacteristic productId={productId} />
      <PhyCheCharacteristic
        productId={productId}
        enumOptions={phyAndCheCharacterEnums}
      />
      <ContaminantCharacteristic productId={productId} />
      <MicrobiologicalCharacteristic
        productId={productId}
        enumOptions={microbiologicalEnums}
      />
      <QaSpecProductDocument productId={productId} />
    </FullWidthSpace>
  );
};

export default QaSpecProduct;
