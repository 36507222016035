import React from 'react';
import { Row } from 'antd';

const ContainerButton = (props) => {
  const { alignCenter, style = {} } = props;

  const propsStyle = {
    alignItems: alignCenter ? 'center' : '',
  };

  return <Row {...props} style={{ ...propsStyle, ...style }} />;
};

export default ContainerButton;
