import customCellComponents from '../components/custom-cell-components/customCellComponents';

import { formatMDYWithParam } from 'utils/formatDate';

const formatFieldsName = (jsonConfig) => {
  let fieldsName = [];

  if (jsonConfig) {
    const config = JSON.parse(jsonConfig);

    config.columns.forEach((column) => {
      fieldsName.push({
        fieldName: column.fieldFullPath,
      });
    });
  }

  return fieldsName;
};

const formatColumns = (jsonConfig) => {
  let cols = [
    {
      filter: false,
      suppressMenu: true,
      resizable: true,
      checkboxSelection: true,
    },
  ];

  if (jsonConfig) {
    const config = JSON.parse(jsonConfig);

    config.columns.forEach((column) => {
      const { fieldFullPath, dataType, displayName, minWidth, width, field } =
        column || {};

      if (fieldFullPath === 'productName') {
        cols.push({
          allowFilter: true,
          allowSort: true,
          resizable: true,
          dataType: dataType?.toLowerCase(),
          field: fieldFullPath,
          headerName: displayName,
          linkTo: '/product/{id}',
          minWidth: minWidth,
          width: width,
        });
      } else if (dataType?.toLowerCase() === 'datetime') {
        cols.push({
          field: fieldFullPath,
          headerName: displayName,
          minWidth: minWidth,
          width: width,
          cellRenderer: formatMDYWithParam,
        });
      } else if (field === 'stateIcons') {
        cols.push({
          ...column,
          field: fieldFullPath,
          headerName: 'States',
          minWidth: minWidth,
          width: width,
          cellRenderer: customCellComponents.renderIcons,
        });
      } else if (field === 'productThumbnail') {
        cols.push({
          ...column,
          dataType: dataType?.toLowerCase(),
          field: fieldFullPath,
          headerName: displayName,
          minWidth: minWidth,
          width: width,
          cellRenderer: customCellComponents.renderThumbnail,
        });
      } else {
        cols.push({
          allowFilter: true,
          allowSort: true,
          resizable: true,
          dataType: dataType?.toLowerCase(),
          field: fieldFullPath,
          headerName: displayName,
          minWidth: minWidth,
          width: width,
        });
      }
    });
  }

  return cols;
};

export { formatColumns, formatFieldsName };
