import React from 'react';

import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';

import { CustomNotification } from 'common/components';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as assetFullSelector from 'pages/asset-full-view/controllers/selectors';

import * as api from 'config/axios';
import * as endpoints from 'services/digitalAsset/endpoints';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

const EditMultipleAsset = (props) => {
  const { disabled } = props;
  const intl = useIntl();
  const selectedItemList = useSelector(gridSelector.makeSelectItemsSelection());
  const assetFull = useSelector(assetFullSelector.assetDataLoading());

  const assetIdList =
    (selectedItemList?.length && selectedItemList) ||
    [assetFull?.id].filter(Boolean);

  const handleEditMultipleAsset = () => {
    const params = { assetIds: assetIdList };
    api
      .sendPost(endpoints.EDIT_MULTIPLE_ASSET, params)
      .then((response) => {
        if (response?.isSuccess) {
          CustomNotification.success(
            intl.formatMessage(Messages.downloadAssetsTemplateMsgSuccess)
          );
          api.sendDownload({
            url: response?.data?.url || '/unknown',
          });
        } else {
          CustomNotification.error(
            intl.formatMessage(Messages.downloadAssetsTemplateMsgFailed) ||
              response?.message
          );
        }
      })
      .catch((error) => {
        CustomNotification.error(
          intl.formatMessage(Messages.downloadAssetsTemplateMsgFailed)
        );
      });
  };
  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<EditOutlined />}
      label={Messages.downloadAssetsTemplate}
      onClick={handleEditMultipleAsset}
      disabled={!assetIdList?.length || disabled}
    />
  );
};

EditMultipleAsset.propTypes = {};

export default EditMultipleAsset;
