import React from 'react';
import { Typography, List, Avatar } from 'antd';

import { Images } from 'config/assets';

import classnames from 'classnames';

import _ from 'lodash';
import './CopyProductResult.less';

const { Title } = Typography;

const CopyProductResult = (props) => {
  const { listData = [] } = props;

  return (
    <div>
      <Title level={5}>Please check list of copied products:</Title>
      <div className={classnames('copy-product-result__list', 'scroller')}>
        <List
          itemLayout='horizontal'
          dataSource={listData}
          renderItem={(item) => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <div>
                      <Avatar
                        src={Images.RIVIR_LOGO_DEFAULT}
                        width={32}
                        height={32}
                      />
                    </div>
                  }
                  title={
                    <a href={`/product/${item?.productItemId}`} target='_blank'>
                      {`RIVIR ID: ${item.rivirId}`}
                    </a>
                  }
                />
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
};

export default CopyProductResult;
