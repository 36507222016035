// GET FOLDER grid columns
export const GET_FOLDER_GRID_COLUMN = 'GET_FOLDER_GRID_COLUMN';
export const GET_FOLDER_GRID_COLUMN_SUCCESS = 'GET_FOLDER_GRID_COLUMN_SUCCESS';
export const GET_FOLDER_GRID_COLUMN_ERROR = 'GET_FOLDER_GRID_COLUMN_ERROR';
export const GET_FOLDER_LIST = 'GET_FOLDER_LIST';
export const GET_FOLDER_LIST_SUCCESS = 'GET_FOLDER_LIST_SUCCESS';
export const GET_FOLDER_LIST_ERROR = 'GET_FOLDER_LIST_ERROR';

export const TURN_ON_MODAL_CREATE = 'TURN_ON_MODAL_CREATE';
export const TURN_OFF_MODAL_CREATE = 'TURN_OFF_MODAL_CREATE';

export const TURN_ON_MODAL_COPY = 'TURN_ON_MODAL_COPY';
export const TURN_OFF_MODAL_COPY = 'TURN_OFF_MODAL_COPY';

export const CREATE_FOLDER = 'CREATE_FOLDER';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAIL = 'CREATE_FOLDER_FAIL';

export const DELETE_FOLDERS = 'DELETE_FOLDERS';
export const DELETE_FOLDERS_SUCCESS = 'DELETE_FOLDERS_SUCCESS';
export const DELETE_FOLDERS_FAIL = 'DELETE_FOLDERS_FAIL';

export const COPY_FOLDER = 'COPY_FOLDER';
export const COPY_FOLDER_SUCCESS = 'COPY_FOLDER_SUCCESS';
export const COPY_FOLDER_FAIL = 'COPY_FOLDER_FAIL';

export const GET_FOLDER_GRID_BY_LEVEL = 'GET_FOLDER_GRID_BY_LEVEL';
export const GET_FOLDER_GRID_BY_LEVEL_SUCCESS =
  'GET_FOLDER_GRID_BY_LEVEL_SUCCESS';
export const GET_FOLDER_GRID_BY_LEVEL_ERROR = 'GET_FOLDER_GRID_BY_LEVEL_ERROR';

export const UPDATE_FOLDER_BREADCRUMB = 'UPDATE_FOLDER_BREADCRUMB';
export const KEEP_BREADCRUMB = 'KEEP_BREADCRUMB';

export const GET_FOLDER_GRID = 'GET_FOLDER_GRID';
export const GET_FOLDER_GRID_SUCCESS = 'GET_FOLDER_GRID_SUCCESS';
export const GET_FOLDER_GRID_ERROR = 'GET_FOLDER_GRID_ERROR';

export const GET_FOLDER_SHORT_DETAIL = 'GET_FOLDER_SHORT_DETAIL';
export const GET_FOLDER_SHORT_DETAIL_SUCCESS =
  'GET_FOLDER_SHORT_DETAIL_SUCCESS';
export const GET_FOLDER_SHORT_DETAIL_ERROR = 'GET_FOLDER_SHORT_DETAIL_ERROR';

export const GET_MEMBER_PREVIEW = 'GET_MEMBER_PREVIEW';
export const GET_MEMBER_PREVIEW_SUCCESS = 'GET_MEMBER_PREVIEW_SUCCESS';
export const GET_MEMBER_PREVIEW_ERROR = 'GET_MEMBER_PREVIEW_ERROR';
export const GET_DIGITAL_ASSET_PREVIEW = 'GET_DIGITAL_ASSET_PREVIEW';
export const GET_DIGITAL_ASSET_PREVIEW_SUCCESS =
  'GET_DIGITAL_ASSET_PREVIEW_SUCCESS';
export const GET_DIGITAL_ASSET_PREVIEW_ERROR =
  'GET_DIGITAL_ASSET_SHORT_DETAIL_ERROR';

// GET DOWNLOAD SETTING
export const GET_DOWNLOAD_SETTING = 'GET_DOWNLOAD_SETTING';
export const GET_DOWNLOAD_SETTING_SUCCESS = 'GET_DOWNLOAD_SETTING_SUCCESS';
export const GET_DOWNLOAD_SETTING_ERROR = 'GET_DOWNLOAD_SETTING_ERROR';

// EDIT FOLDER SHORT CONTENT
export const EDIT_FOLDER_SHORT_CONTENT = 'EDIT_FOLDER_SHORT_CONTENT';
export const TURN_EDIT_SHORT_CONTENT_ON = 'TURN_EDIT_SHORT_CONTENT_ON';
export const EDIT_FOLDER_SHORT_CONTENT_SUCCESS =
  'EDIT_FOLDER_SHORT_CONTENT_SUCCESS';
export const EDIT_FOLDER_SHORT_CONTENT_ERROR =
  'EDIT_FOLDER_SHORT_CONTENT_ERROR';
export const SET_RELOAD_FOLDER_CONTENT_PANE = 'SET_RELOAD_FOLDER_CONTENT_PANE';
export const EDIT_FOLDER_SHORT_CONTENT_FINISH =
  'EDIT_FOLDER_SHORT_CONTENT_FINISH';
export const SET_RELOAD_FOLDER_PAGE = 'SET_RELOAD_FOLDER_PAGE';

export const CONFIG_SCHEDULE_DOWNLOAD = 'CONFIG_SCHEDULE_DOWNLOAD';
export const CONFIG_SCHEDULE_DOWNLOAD_SUCCESS =
  'CONFIG_SCHEDULE_DOWNLOAD_SUCCESS';
export const CONFIG_SCHEDULE_DOWNLOAD_ERROR = 'CONFIG_SCHEDULE_DOWNLOAD_ERROR';

// Leave page
export const RESET_FOLDER_PAGING = 'RESET_FOLDER_PAGING';
export const RESET_OWNED_FOLDER_PAGING = 'RESET_OWNED_FOLDER_PAGING';
export const RESET_SHARED_FOLDER_PAGING = 'RESET_SHARED_FOLDER_PAGING';
// MyFolders grid
export const GET_OWNED_FOLDER_GRID = 'GET_OWNED_FOLDER_GRID';
export const GET_OWNED_FOLDER_GRID_SUCCESS = 'GET_OWNED_FOLDER_GRID_SUCCESS';
export const GET_OWNED_FOLDER_GRID_ERROR = 'GET_OWNED_FOLDER_GRID_ERROR';
// FoldersSharedToMe grid
export const GET_SHARED_FOLDER_GRID = 'GET_SHARED_FOLDER_GRID';
export const GET_SHARED_FOLDER_GRID_SUCCESS = 'GET_SHARED_FOLDER_GRID_SUCCESS';
export const GET_SHARED_FOLDER_GRID_ERROR = 'GET_SHARED_FOLDER_GRID_ERROR';
export const GET_PUBLIC_CONTACT_FOLDER = 'GET_PUBLIC_CONTACT_FOLDER';
export const GET_PUBLIC_CONTACT_FOLDER_SUCCESS =
  'GET_PUBLIC_CONTACT_FOLDER_SUCCESS';
export const GET_PUBLIC_CONTACT_FOLDER_ERROR =
  'GET_PUBLIC_CONTACT_FOLDER_ERROR';

// Folder details
export const GET_COLUMNS_FOLDER_DETAILS = 'GET_COLUMNS_FOLDER_DETAILS';
export const GET_COLUMNS_FOLDER_DETAILS_SUCCESS =
  'GET_COLUMNS_FOLDER_DETAILS_SUCCESS';
export const GET_COLUMNS_FOLDER_DETAILS_ERROR =
  'GET_COLUMNS_FOLDER_DETAILS_ERROR';

export const GET_FOLDER_DETAILS_GRID_THUMB = 'GET_FOLDER_DETAILS_GRID_THUMB';
export const GET_FOLDER_DETAILS_GRID_THUMB_SUCCESS =
  'GET_FOLDER_DETAILS_GRID_THUMB_SUCCESS';
export const GET_FOLDER_DETAILS_GRID_THUMB_ERROR =
  'GET_FOLDER_DETAILS_GRID_THUMB_ERROR';
export const GET_QUERY_PREVIEW = 'GET_QUERY_PREVIEW';
export const GET_QUERY_PREVIEW_SUCCESS = 'GET_QUERY_PREVIEW_SUCCESS';
export const GET_QUERY_PREVIEW_ERROR = 'GET_QUERY_PREVIEW_ERROR';
export const GET_REPORTING_PREVIEW = 'GET_REPORTING_PREVIEW';
export const GET_REPORTING_PREVIEW_SUCCESS = 'GET_REPORTING_PREVIEW_SUCCESS';
export const GET_REPORTING_PREVIEW_ERROR = 'GET_REPORTING_PREVIEW_ERROR';

export const GET_PRODUCT_PREVIEW = 'GET_PRODUCT_PREVIEW';
export const GET_PRODUCT_PREVIEW_SUCCESS = 'GET_PRODUCT_PREVIEW_SUCCESS';
export const GET_PRODUCT_PREVIEW_ERROR = 'GET_PRODUCT_PREVIEW_ERROR';

export const SAVE_SELECTED_MAPPING = 'SAVE_SELECTED_MAPPING';
export const SAVE_STATUS_DOWNLOAD_FOLDER = 'SAVE_STATUS_DOWNLOAD_FOLDER';

export const UPDATE_FOLDER_DETAIL_DATA = 'UPDATE_FOLDER_DETAIL_DATA';

export const UPDATE_SHOULD_KEEP_BREADCRUMB = 'UPDATE_SHOULD_KEEP_BREADCRUMB';

export const ROOT_FOLDER = 'home';
