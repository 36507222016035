import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Space, Input, Select, Modal } from 'antd';

import { Form, WrapperSelect } from 'common/components';

import { SocialLinkComponent } from 'static/Icons';
import Messages from 'i18n/messages/messages';
import { injectIntl } from 'react-intl';

const { Option } = Select;

const AddSocialLinkModal = (props) => {
  const [form] = Form.useForm();
  const { intl, addNewVisibleHook, onSubmit } = props;
  const [addNewVisible, setAddNewVisible] = addNewVisibleHook;
  const [loading, setLoading] = useState(false);

  const createRules = () => {
    const socialMediaTypeRule = [
      {
        required: true,
        message: intl.formatMessage(Messages.addSocialTypeErrorMessage),
      },
    ];

    const urlRule = [
      {
        required: true,
        message: intl.formatMessage(Messages.addSocialUrlErrorMessage),
      },
      {
        type: 'url',
        message: intl.formatMessage(Messages.addSocialInvalidUrlErrorMessage),
      },
    ];

    return {
      type: socialMediaTypeRule,
      url: urlRule,
    };
  };

  const handleCancel = (e) => {
    setAddNewVisible(false);
    if (!loading) {
      form.resetFields();
    }
  };

  const onOkHandler = () => {
    if (!loading) {
      form
        .validateFields()
        .then((values) => {
          setLoading(true);
          onSubmit(values).finally(() => {
            setLoading(false);
            form.resetFields();
            setAddNewVisible(false);
          });
        })
        .catch(() => {});
    }
  };

  const renderTypeOptions = () => {
    return SocialLinkComponent.map((link) => {
      const { type } = link;
      if (link?.unSelectable) return null;

      return (
        <Option value={type} key={type}>
          {type}
        </Option>
      );
    });
  };

  //* render
  const rules = createRules();

  return (
    <Modal
      title={intl.formatMessage(Messages.addSocialLinkModalTitle)}
      okText={intl.formatMessage(Messages.addSocialOkText)}
      cancelText={intl.formatMessage(Messages.addSocialCancelText)}
      visible={addNewVisible}
      onCancel={handleCancel}
      onOk={onOkHandler}
      okButtonProps={{
        loading: loading,
      }}
    >
      <Form form={form}>
        <Space className='social-link__add-form'>
          <Form.Item name='socialMediaType' rules={rules.type}>
            <WrapperSelect
              className='social-link__add-field-type'
              placeholder='Select type'
              disabled={loading}
            >
              {renderTypeOptions()}
            </WrapperSelect>
          </Form.Item>
          <Form.Item name='url' rules={rules.url}>
            <Input
              className='social-link__add-field-link'
              placeholder='URL'
              disabled={loading}
            />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};
AddSocialLinkModal.propTypes = {
  onChangeNewSocial: PropTypes.func,
};

AddSocialLinkModal.defaultProps = {
  onChangeNewSocial: () => null,
};

export default injectIntl(AddSocialLinkModal);
