import React from 'react';
import Messages from 'i18n/messages/home';
import { CopyOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

const CloneStepControl = ({ onClick }) => {
  return (
    <RibbonButton
      icon={<CopyOutlined style={{ height: 26 }} />}
      label={Messages.cloneStep}
      onClick={onClick}
    />
  );
};

CloneStepControl.propTypes = {};

export default CloneStepControl;
