import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Row, Col, Typography } from 'antd';
import { StyledModal, dialogFunction } from 'common/components';
import {
  RoleList,
  RoleDescription,
  RolePermissionList,
} from 'pages/role/components';

import AddUserRoleAction from './AddUserRoleAction';

import * as roleActions from 'pages/role/controllers/actions';
import * as roleSelectors from 'pages/role/controllers/selectors';

import * as securityServices from 'services/roles-security';

import { useHandleReloadPage } from 'hooks/useReloadPage';
import { apiHandler } from 'utils/api';

import Messages from 'i18n/messages/maintenance';
import { useIntl } from 'react-intl';

import './AddRoleToUserModal.less';
import { EVENT } from 'static/Constants';

/**
 * ! MAIN COMPONENT
 * @param {*} props
 * @returns
 */
const AddRoleToUserModal = (props) => {
  const intl = useIntl();
  const { isVisible, toggleModal, selectedUser } = props;

  const dispatch = useDispatch();

  const [__, setReloadGrid] = useHandleReloadPage();

  const [assignedRoles, setAssignedRoles] = useState([]);
  const [initialAssignedRoles, setInitialAssignedRoles] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(true);

  const selectedMemberRole = useSelector(roleSelectors.selectSelectedRole());

  const roleList = useSelector(roleSelectors.selectRoleList());

  const roleListData = roleList?.data;

  const onCloseModal = () => {
    toggleModal();
    dispatch(roleActions.setSelectedRole(null));
  };

  const initAssignedRoles = () => {
    if (!isVisible) return;

    const assignRoles = getUserAssignedRole();
    setInitialAssignedRoles(assignRoles);
    setAssignedRoles(assignRoles);
  };

  const getUserAssignedRole = () => {
    const userRoles = selectedUser?.roles?.split(',');
    if (!userRoles.length) return [];
    if (!roleListData?.length) return [];

    const assignedRoleData = userRoles.reduce(
      (accumulator, currentUserRoleItem) => {
        const roleData = roleListData.find(
          (memberRoleItem) => memberRoleItem.displayName === currentUserRoleItem
        );

        if (roleData) accumulator.push({ ...roleData, isReadOnly: true });

        return accumulator;
      },
      []
    );

    return assignedRoleData;
  };

  const transformRoleListData = () => {
    if (!roleListData) return roleListData;

    const userRoles = selectedUser?.roles?.split(',');

    return roleListData.map((roleItem) => {
      const hasRoleAssigned = userRoles?.includes(roleItem.displayName);

      return {
        ...roleItem,
        isReadOnly: hasRoleAssigned,
      };
    });
  };

  const handleAddMemberToRole = async () => {
    setSubmitLoading(true);

    const assignedRolesId = assignedRoles.map((roleItem) => roleItem.id);

    const params = {
      roleIds: assignedRolesId,
      userId: selectedUser.id,
    };

    const successCallback = () => {
      onCloseModal();
      setReloadGrid();
    };

    const onFinally = () => {
      setSubmitLoading(false);
    };

    await apiHandler({
      service: securityServices.addRoleToUser,
      params,
      successMessage: intl.formatMessage(Messages.addUserRoleSuccess),
      errorMessage: intl.formatMessage(Messages.addUserRoleError),
      successCallback,
      onFinally,
    });
  };

  const confirmAddMemberToRole = () => {
    const infoContent = (
      <Typography.Paragraph>
        {intl.formatMessage(Messages.addUserRoleConfirm)}
      </Typography.Paragraph>
    );

    dialogFunction({
      type: 'warn',
      content: infoContent,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleAddMemberToRole,
    });
  };

  useEffect(() => {
    //* reset selected member role
    const resetSelectedMemberRole = () => {
      if (!isVisible) dispatch(roleActions.setSelectedMemberRole(null));
    };

    const getMemberRoleList = () => {
      if (isVisible)
        dispatch(roleActions.getRoleList({ memberId: selectedUser?.memberId }));
    };

    resetSelectedMemberRole();
    getMemberRoleList();
  }, [isVisible]);

  useEffect(() => {
    initAssignedRoles();
  }, [isVisible, roleListData]);

  const modalTitle = `Assign User Role${
    selectedUser?.firstName ? ' - ' + selectedUser.firstName : ''
  }`;

  useEffect(() => {
    if (_.isEqual(assignedRoles, initialAssignedRoles)) {
      setSubmitLoading(true);
    } else {
      setSubmitLoading(false);
    }
  }, [assignedRoles]);

  const transformedRoleListData = transformRoleListData();

  return (
    <StyledModal
      className='add-role-to-user-modal'
      title={modalTitle}
      visible={isVisible}
      okText='Assign role'
      maskClosable={false}
      destroyOnClose={true}
      onCancel={onCloseModal}
      onOk={confirmAddMemberToRole}
      width={900}
      okButtonProps={{ disabled: submitLoading }}
    >
      <AddRoleModalContent
        selectedRole={selectedMemberRole}
        roleListData={transformedRoleListData}
        assignedRoles={assignedRoles}
        setAssignedRoles={setAssignedRoles}
        memberId={selectedUser?.memberId}
      />
    </StyledModal>
  );
};

/**
 * ! SUB COMPONENT
 * @param {*} props
 * @returns
 */
const AddRoleModalContent = (props) => {
  const {
    selectedRole,
    roleListData,
    assignedRoles,
    setAssignedRoles,
    memberId,
  } = props;

  const dispatch = useDispatch();
  const onUpdateSelectedItem = (selectedRole) => {
    if (assignedRoles?.length < 5) return;
    const $activeRole = document.getElementById(
      `#assigned-role-${selectedRole?.id}`
    );
    if ($activeRole) {
      $activeRole.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  useEffect(() => {
    //* get user's member role list
    //* get all member (avail) permission
    const getAllMemberPermissionList = () => {
      dispatch(roleActions.getAvailablePermissions());
    };

    getAllMemberPermissionList();
  }, []);

  useEffect(() => {
    if (selectedRole?.id) {
      dispatch(roleActions.getRolePermissionList({ roleId: selectedRole?.id }));
    }
  }, [selectedRole?.id]);

  const viewType = 'role-management';

  const removePressEnterListener = () => {
    const event = new CustomEvent(EVENT.TOGGLE_PRESS_ENTER_EVENT_LISTENER, {
      detail: { action: 'removeEventListener' },
    });
    document.dispatchEvent(event);
  };
  const addPressEnterListener = () => {
    const event = new CustomEvent(EVENT.TOGGLE_PRESS_ENTER_EVENT_LISTENER, {
      detail: { action: 'addEventListener' },
    });
    document.dispatchEvent(event);
  };

  return (
    <div className='add-role-to-user-modal'>
      <Row gutter={[5, 5]} style={{ flex: 'auto', overflow: 'hidden' }}>
        <Col
          xs={10}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <AddUserRoleAction
            selectedRole={selectedRole}
            assignedRoles={assignedRoles}
            setAssignedRoles={setAssignedRoles}
          />
          <div className='add-role-to-user-modal__role-list'>
            <RoleList
              title='role list'
              roleListData={roleListData}
              selectedRole={selectedRole}
              viewType={viewType}
              preventUpdateSelectedRole
            />
          </div>
          <div className='add-role-to-user-modal__assigned-role-list'>
            <RoleList
              title='Assigned Roles'
              roleListData={assignedRoles}
              selectedRole={selectedRole}
              viewType={viewType}
              preventUpdateSelectedRole
              onUpdateSelectedItem={onUpdateSelectedItem}
              roleItemIdPrefix='assigned'
            />
          </div>
        </Col>
        <Col
          xs={14}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div className='add-role-to-user-modal__role-desc'>
            <RoleDescription
              title='Role Description'
              selectedRole={selectedRole}
            />
          </div>

          <div className='add-role-to-user-modal__permission-list'>
            <RolePermissionList
              title='Permission List'
              selectedRole={selectedRole}
              memberIdParams={memberId}
              showEditPage={false}
              handleFocusInputSearch={removePressEnterListener}
              handleBlurInputSearch={addPressEnterListener}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}></Col>
      </Row>
    </div>
  );
};

export default AddRoleToUserModal;
