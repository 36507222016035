/**
 * Workflow messages
 *
 * This contains all the text for the Workflow component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.workflow';

export default defineMessages({
  confirmRemoveRole: {
    id: `${scope}.components.workflow.administration.confirmRemoveRole`,
    defaultMessage: 'Are you sure to remove this role?',
  },
  confirmRemoveUsers: {
    id: `${scope}.components.workflow.administration.confirmRemoveUsers`,
    defaultMessage: 'Are you sure to remove these user(s)?',
  },
  userWorkflowRolePopupTitle: {
    id: `${scope}.components.workflow.administration.userWorkflowRolePopupTitle`,
    defaultMessage: 'Add User(s) To Workflow Role',
  },
  addNewWorkflowRolePopupTitle: {
    id: `${scope}.components.workflow.administration.addNewWorkflowRolePopupTitle`,
    defaultMessage: 'Add New Workflow Role',
  },
  editWorkflowRolePopupTitle: {
    id: `${scope}.components.workflow.administration.editWorkflowRolePopupTitle`,
    defaultMessage: 'Edit Workflow Role',
  },
  missingRoleInformation: {
    id: `${scope}.components.workflow.administration.missingRoleInformation`,
    defaultMessage: 'Role & Role Description Are Required!',
  },
  addNewUser: {
    id: `${scope}.components.workflow.role.addUser`,
    defaultMessage: 'Add new user',
  },
  addEventPipeSuccess: {
    id: `${scope}.components.workflow.progression.addEvent`,
    defaultMessage: 'Add event pipe successfully!',
  },
  cancelBtnEdit: {
    id: `${scope}.components.workflow.canbelBtnEdit`,
    defaultMessage: 'Cancel',
  },
  saveBtn: {
    id: `${scope}.components.workflow.saveBtn`,
    defaultMessage: 'Save',
  },
  expireDaysShouldBeLongerThanTimeline: {
    id: `${scope}.components.definition.expireDaysShouldBeLongerThanTimeline`,
    defaultMessage: 'Expire days should be greater than Timeline!',
  },
  timelineShouldBeLongerThanZero: {
    id: `${scope}.components.definition.timelineShouldBeLongerThanZero`,
    defaultMessage: 'Timeline should be equal to or greater than 0!',
  },
  expireDaysShouldBeLongerThanZero: {
    id: `${scope}.components.definition.timelineShouldBeLongerThanZero`,
    defaultMessage: 'Expire days should be equal to or greater than 0!',
  },
  timelineShouldBeANumber: {
    id: `${scope}.components.definition.timelineShouldBeANumber`,
    defaultMessage: 'Timeline must be a number!',
  },
  expireDaysShouldBeANumber: {
    id: `${scope}.components.definition.expireDaysShouldBeANumber`,
    defaultMessage: 'Expire days must be a number!',
  },

  saveAsNewBtn: {
    id: `${scope}.components.workflow.saveAsNewBtn`,
    defaultMessage: 'Save As New',
  },
  overrideBtn: {
    id: `${scope}.components.workflow.overrideBtn`,
    defaultMessage: 'Save',
  },
  openWorkflow: {
    id: `${scope}.component.ribbon.openWorkflow`,
    defaultMessage: 'Open workflow',
  },
  filters: {
    id: `${scope}.component.ribbon.filters`,
    defaultMessage: 'Filters',
  },
  active: {
    id: `${scope}.component.ribbon.active`,
    defaultMessage: 'Active',
  },
  history: {
    id: `${scope}.component.ribbon.history`,
    defaultMessage: 'History',
  },
  acceptStepTableTitle: {
    id: `${scope}.component.table.acceptStepTitle`,
    defaultMessage: 'Accept Steps',
  },
  approveStepTableTitle: {
    id: `${scope}.component.table.approveStepTitle`,
    defaultMessage: 'Approve Steps',
  },
  stepPreviewTitle: {
    id: `${scope}.component.title.stepPreviewTitle`,
    defaultMessage: 'Step Preview',
  },
  acceptStepBtn: {
    id: `${scope}.component.button.acceptStep`,
    defaultMessage: 'Accept',
  },
  repeatableStepBtn: {
    id: `${scope}.component.button.repeatableStepBtn`,
    defaultMessage: 'Repeat',
  },
  viewStepBtn: {
    id: `${scope}.component.button.viewStep`,
    defaultMessage: 'View',
  },
  approveStepBtn: {
    id: `${scope}.component.button.approveStep`,
    defaultMessage: 'Approve',
  },
  rejectStepBtn: {
    id: `${scope}.component.button.rejectStep`,
    defaultMessage: 'Reject',
  },
  submitTaskValueBtn: {
    id: `${scope}.component.button.submitTaskValueBtn`,
    defaultMessage: 'Submit',
  },
  addNewAnswer: {
    id: `${scope}.component.button.addNewAnswer`,
    defaultMessage: 'Add more answers',
  },
  booleanTrue: {
    id: `${scope}.component.radio.yes`,
    defaultMessage: 'Yes',
  },
  booleanFalse: {
    id: `${scope}.component.radio.no`,
    defaultMessage: 'No',
  },
  acceptStepSuccessMessage: {
    id: `${scope}.message.acceptSuccess`,
    defaultMessage: 'Accept Workflow Step Successfully',
  },
  acceptStepErrorMessage: {
    id: `${scope}.message.acceptError`,
    defaultMessage: 'Cannot accept Workflow Step',
  },
  approveStepSuccessMessage: {
    id: `${scope}.message.approveSuccess`,
    defaultMessage: 'Approve Workflow Step Successfully',
  },
  approveStepErrorMessage: {
    id: `${scope}.message.approveError`,
    defaultMessage: 'Cannot approve Workflow Step',
  },
  rejectStepSuccessMessage: {
    id: `${scope}.message.rejectSuccess`,
    defaultMessage: 'Reject Workflow Step Successfully',
  },
  rejectStepErrorMessage: {
    id: `${scope}.message.rejectError`,
    defaultMessage: 'Cannot reject Workflow Step',
  },
  acceptApproveStepSuccessMessage: {
    id: `${scope}.message.acceptApproveStepSuccess`,
    defaultMessage: 'Accept approving step successfully',
  },
  acceptApproveStepErrorMessage: {
    id: `${scope}.message.acceptApproveStepError`,
    defaultMessage: 'Cannot accept approving step',
  },
  repeatableStepSuccessMessage: {
    id: `${scope}.message.repeatableStepSuccessMessage`,
    defaultMessage: 'Repeatable step successfully',
  },
  repeatableStepErrorMessage: {
    id: `${scope}.message.repeatableStepErrorMessage`,
    defaultMessage: 'Cannot repeat step',
  },
  acceptStepNotification: {
    id: `${scope}.message.acceptStepNotification`,
    defaultMessage: 'Are you sure to accept this step?',
  },
  approveStepNotification: {
    id: `${scope}.message.approveStepNotification`,
    defaultMessage: 'Are you sure to approve this step?',
  },
  rejectStepNotification: {
    id: `${scope}.message.rejectStepNotification`,
    defaultMessage: 'Are you sure to reject this step?',
  },
  saveTasksSuccess: {
    id: `${scope}.message.saveTaskSuccess`,
    defaultMessage: 'Submit Successfully',
  },
  saveTasksError: {
    id: `${scope}.message.saveTasksError`,
    defaultMessage: 'Cannot Submit',
  },
  uploadFileSuccess: {
    id: `${scope}.message.uploadFileSuccess`,
    defaultMessage: 'Upload file(s) successfully',
  },
  uploadFileError: {
    id: `${scope}.message.uploadFileError`,
    defaultMessage: 'Cannot upload file(s)',
  },
  requireTask: {
    id: `${scope}.message.requireTask`,
    defaultMessage: 'This task is required',
  },
  numberRule: {
    id: `${scope}.message.numberRule`,
    defaultMessage: 'This task is required a number',
  },
  requireTaskTooltip: {
    id: `${scope}.tooltip.requireTaskTooltip`,
    defaultMessage: 'Require {taskName} complete',
  },
  requireTaskTooltipValue: {
    id: `${scope}.tooltip.requireTaskTooltipValue`,
    defaultMessage: 'with value is "{taskValue}"',
  },
  downloadFileConfirm: {
    id: `${scope}.message.downloadFileConfirm`,
    defaultMessage: 'Are you sure to download this file?',
  },
  noAnswer: {
    id: `${scope}.message.noAnswer`,
    defaultMessage: 'No Answer',
  },
  getTaskPreviewError: {
    id: `${scope}.message.getStepPreviewError`,
    defaultMessage: 'Cannot get step preview',
  },
  dateCreated: {
    id: `${scope}.component.contentPanel.dateCreated`,
    defaultMessage: 'Date Created',
  },
  version: {
    id: `${scope}.component.contentPanel.version`,
    defaultMessage: 'Version',
  },
});
