import CountryCode from 'static/CountryCode';
import * as _ from 'lodash';

export const searchCountryCode = (countryName) => {
  const searchIndex = _.toArray(CountryCode).indexOf(countryName);
  return searchIndex >= 0 ? Object.keys(CountryCode)[searchIndex] : null;
};

export const selectCountryFromCode = (countryName) => {
  const reverseCountryCode = {};
  for (const property in CountryCode) {
    reverseCountryCode[CountryCode[property]] = property;
  }
  return reverseCountryCode[countryName];
};
