import React from 'react';

import { RibbonSection } from 'common/components';
import AcceptSubscription from 'pages/home/ribbon/components/controls/product-full/AcceptSubscription';
import RejectSubscription from 'pages/home/ribbon/components/controls/product-full/RejectSubscription';
import RevokeSubscription from 'pages/home/ribbon/components/controls/product-full/RevokeSubscription';

const SubscriptionActionSection = (props) => {
  const {
    selectionSubscription,
    hideAccept,
    hideReject,
    activeRevoke,
    sectionDisabled,
  } = props;

  return (
    <RibbonSection>
      {!hideAccept && (
        <AcceptSubscription
          selectionSubscription={selectionSubscription}
          sectionDisabled={sectionDisabled}
        />
      )}

      {!hideReject && (
        <RejectSubscription
          selectionSubscription={selectionSubscription}
          sectionDisabled={sectionDisabled}
        />
      )}

      <RevokeSubscription
        selectionSubscription={selectionSubscription}
        activeRevoke={activeRevoke}
        sectionDisabled={sectionDisabled}
      />
    </RibbonSection>
  );
};

export default SubscriptionActionSection;
