import React from 'react';
import Messages from 'i18n/messages/home';
import { Menu, Dropdown } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as IconShowAssets } from 'common/components/button/svg-icons/ICO_10.31.svg';
import RibbonButton from 'common/components/button/RibbonButton';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import * as companySelector from 'pages/company/controllers/selectors';
import * as actionsRibbon from 'redux/ribbon/actions';

import { forwardTo } from 'utils/common/route';

import { ROUTE } from 'static/Constants';

const ShowAssets = ({ companyId: companyIdProp }) => {
  const dispatch = useDispatch();
  const companyInfo = useSelector(companySelector.setlectCompanyInfo());

  const companyId = companyIdProp ?? companyInfo?.id;

  const handleClick = () => {
    if (companyId) {
      const url = `${ROUTE.MEMBER_ASSETS}?memberId=${companyId}`;
      forwardTo(url);
    }
  };

  const menuOptions = (
    <Menu>
      <Menu.Item key='assets' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<IconShowAssets />}
          label={Messages.goShowAllAssets}
          onClick={handleClick}
        />
      </Menu.Item>
      <Menu.Item key='showcase-assets' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<AppstoreOutlined />}
          label={Messages.showcasedMedia}
          onClick={() => dispatch(actionsRibbon.changeMemberLayout('media'))}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions}>
      <RibbonButton
        icon={<IconShowAssets style={{ height: 26 }} />}
        label={Messages.showAllAssets}
        // onClick={handleClick}
      />
    </Dropdown>
  );
};

ShowAssets.propTypes = {};

export default ShowAssets;
