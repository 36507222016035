import { AT_LEAST_ONE_ENTRY_GROUPS } from './hooks';
import { requiredFieldsMap } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';

export const filterRequiredFieldsGroupData = ({
  groupsData,
  requiredFields,
}) => {
  if (!groupsData) return [];
  if (!requiredFields) return [];

  return groupsData.reduce((accumulator, currentGroupData) => {
    const isAtLeastOneEntry = AT_LEAST_ONE_ENTRY_GROUPS.includes(
      currentGroupData.groupName
    );

    const fieldsData = filterRequiredFieldsOfGroup({
      requiredFields,
      fields: currentGroupData.fields,
    });

    const childGroupData =
      currentGroupData.childGroups?.length > 0
        ? filterRequiredFieldsGroupData({
            requiredFields,
            groupsData: currentGroupData.childGroups,
          })
        : [];

    const hasContent = fieldsData.length || childGroupData.length;

    //* if group does not have any field or child group, do not add to list
    return hasContent
      ? [
          ...accumulator,
          {
            ...currentGroupData,
            tooltip: isAtLeastOneEntry ? 'At least one entry' : null,
            fields: fieldsData,
            childGroups: childGroupData,
          },
        ]
      : accumulator;
  }, []);
};

const filterRequiredFieldsOfGroup = ({ requiredFields, fields }) => {
  if (!fields) return [];

  const filteredFields = fields.reduce((accumulator, currentFieldItem) => {
    const standardFieldFullPath =
      requiredFieldsMap[currentFieldItem.fieldFullPath] ||
      currentFieldItem.fieldFullPath;

    const isRequired = requiredFields.includes(standardFieldFullPath);

    if (isRequired) {
      return [
        ...accumulator,
        { ...currentFieldItem, fieldFullPath: standardFieldFullPath },
      ];
    }

    return accumulator;
  }, []);

  return filteredFields;
};

export const getGroupRequiredFields = ({ groupRequiredFields, groupName }) => {
  return groupRequiredFields.find((groupItem) => {
    return groupItem.groupName === groupName;
  });
};
