import React, { useState, useEffect, useMemo } from 'react';
import PropTypes, { oneOfType } from 'prop-types';

import { Space, Tooltip, Dropdown, Menu, Spin } from 'antd';
import {
  PlusOutlined,
  MinusCircleOutlined,
  EllipsisOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import AddSocialLinkModal from './AddSocialLinkModal';
import { dialogFunction } from 'common/components/index';

import { useWindowSize } from 'hooks/windowSize';

import { MAX_SOCIAL_LINK } from 'static/Constants';

import Messages from 'i18n/messages/common';
import socialMessages from 'i18n/messages/messages';
import { FormattedMessage, injectIntl } from 'react-intl';

import SocialIcon from './SocialIcon';

import './SocialLink.less';

const defaultSocials = [
  {
    id: null,
    socialMediaType: 'gs1',
    socialMediaUrl: 'https://www.gs1.org/',
    socialMediaTooltip: 'https://www.gs1.org/',
    status: 'Active',
    unEditable: true,
  },

  {
    id: null,
    socialMediaType: 'ixone',
    socialMediaUrl: 'https://www.ix-one.net/',
    socialMediaTooltip: 'IX-ONE Certified',
    status: 'Active',
    unEditable: true,
  },
];

const SocialLink = React.memo((props) => {
  const {
    intl,
    socialLinks,
    editable,
    showFullIcon,
    addNewSocialLink,
    deleteSocialLink,
    maxItem,
    hasGS1SocialMedia = false,
    hasIxOneSocialMedia = false,
    showIxOneShield,
  } = props;

  const addNewVisibleHook = useState(false);
  const [, setAddNewVisible] = addNewVisibleHook;
  const [countSocial, setCountSocial] = useState(1);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [width] = useWindowSize();
  const initialMaxItem = maxItem || MAX_SOCIAL_LINK;

  useEffect(() => {
    if (socialLinks) {
      setCountSocial(
        socialLinks.length + !!hasGS1SocialMedia + !!hasIxOneSocialMedia
      );
    }
  }, [socialLinks, hasGS1SocialMedia, hasIxOneSocialMedia]);

  const showModalAddNewSocial = () => {
    setAddNewVisible(true);
  };

  const showModalDeleteSocialLink = (socialId) => () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage({
        ...socialMessages.deleteSocialLinkConfirmMessage,
      }),
      okText: intl.formatMessage({
        ...socialMessages.acceptDelete,
      }),
      cancelText: intl.formatMessage({
        ...socialMessages.cancelDelete,
      }),
      onOk: onOkDeleteHandler(socialId),
    });
  };

  const onOkDeleteHandler = (socialId) => () => {
    setDeleteLoading(socialId);
    deleteSocialLink(socialId).finally(() => {
      setDeleteLoading(false);
    });
  };

  const onDividingIconsShowAndMenu = (socials) => {
    const socialsShow = socials?.length > 3 && socials?.slice(0, 3); // if social exists and has length more than three element, slice 3 element
    const socialsMenu =
      socials?.length > 3 && socials?.slice(3, socials?.length);
    return { socialsShow, socialsMenu };
  };

  const onShowingLessSocialMedia = (socials, isEdit) => {
    const { socialsShow, socialsMenu } = onDividingIconsShowAndMenu(socials);

    return (
      <Space direction='horizontal' size='small'>
        {socialsShow &&
          socialsShow.length === 3 &&
          socialsShow.map((social, index) =>
            renderSocialItem(social, index, isEdit)
          )}
        {socialsMenu && socialsMenu.length > 0 && (
          <Dropdown
            overlay={renderDropdownMenuSocials(socialsMenu)}
            placement='topLeft'
          >
            <EllipsisOutlined />
          </Dropdown>
        )}
      </Space>
    );
  };

  const renderDropdownMenuSocials = (socials, isEdit) => {
    return (
      <Menu style={{ padding: '4px 8px' }}>
        {socials && socials.length > 0 && (
          <Space direction='horizontal' size='small'>
            {socials.map((social, index) =>
              renderSocialItem(social, index, isEdit)
            )}
          </Space>
        )}
      </Menu>
    );
  };

  const renderSocialItem = (social, index, isEdit) => {
    const type =
      social.type ||
      social.formatSocialType ||
      social.formatSocialMediaType ||
      social.socialMediaType;
    const isLoading = deleteLoading === social.id;
    const isShowDeleteIcon =
      isEdit && !deleteLoading && social?.unEditable !== true;

    const link = social?.socialMediaUrl || social?.link;
    const tooltipContent =
      social?.socialMediaTooltip || social?.socialMediaUrl || social?.link;

    return (
      <div key={index} className='social-link__wrapper'>
        <Tooltip title={tooltipContent}>
          <a href={link} target='_blank' rel='noopener noreferrer'>
            <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
              <SocialIcon type={type} />
            </Spin>
          </a>
          {isShowDeleteIcon && (
            <div className='social-link__wrapper-del-position'>
              <MinusCircleOutlined
                className='social-link__wrapper-del-icon'
                onClick={showModalDeleteSocialLink(social.id)}
              />
            </div>
          )}
        </Tooltip>
      </div>
    );
  };

  const isShowAddSocialButton =
    editable && countSocial < initialMaxItem && !deleteLoading;

  const newSocialLink = useMemo(() => {
    let nextSocialLink = [...socialLinks];
    if (hasGS1SocialMedia)
      nextSocialLink = [defaultSocials[0], ...nextSocialLink];

    if (hasIxOneSocialMedia && showIxOneShield)
      nextSocialLink = [defaultSocials[1], ...nextSocialLink];

    return nextSocialLink;
  }, [socialLinks, hasGS1SocialMedia, hasIxOneSocialMedia, showIxOneShield]);

  return (
    <div className='social-link'>
      <div className='social-link__space'>
        {(width > 1400 || newSocialLink?.length <= 3 || showFullIcon) &&
          newSocialLink?.map((social, index) =>
            renderSocialItem(social, index, editable)
          )}
        {width <= 1400 &&
          newSocialLink?.length > 3 &&
          !showFullIcon &&
          onShowingLessSocialMedia(socialLinks, editable)}
        {isShowAddSocialButton && (
          <Tooltip title={<FormattedMessage {...Messages.socialLinkTooltip} />}>
            <PlusOutlined
              className='social-link__icon social-link__icon-add'
              onClick={showModalAddNewSocial}
            />
          </Tooltip>
        )}
      </div>
      <AddSocialLinkModal
        addNewVisibleHook={addNewVisibleHook}
        onSubmit={addNewSocialLink}
      />
    </div>
  );
});

SocialLink.propTypes = {
  socialLinks: oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    PropTypes.oneOf([null]),
  ]),
  editable: PropTypes.bool,
  addNewSocialLink: PropTypes.func,
  deleteSocialLink: PropTypes.func,
  maxItem: PropTypes.number,
};

SocialLink.defaultProps = {
  editable: false,
  showFullIcon: false,
};

export default injectIntl(SocialLink);
