import React from 'react';

import { ProfileImage } from 'common/components';
import classnames from 'classnames';

import './ProductHeaderProfileImage.less';

const ProductHeaderProfileImage = (props) => {
  const { shouldHideLogo, src, isEdit, onClick } = props;
  if (shouldHideLogo) return null;

  return (
    <div
      className={classnames({
        'pd-hdr-profile-img': true,
        'img-view-mode': !isEdit,
        'img-edit-mode': isEdit,
      })}
      onClick={onClick}
    >
      <ProfileImage
        src={src}
        isDefaultSrc={true}
        style={{ width: '150px', height: '150px' }}
      />
    </div>
  );
};

export default ProductHeaderProfileImage;
