import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';
import { notification, Modal } from 'antd';
import { RibbonButton, UploadFile } from 'common/components';
import { UploadOutlined } from '@ant-design/icons';
import { toggleReloadGrid } from 'pages/product-apl-detail/controllers/actions';
import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';

import * as aplEndpoints from 'services/apl/endpoints';
import ErrorModal from 'common/components/product-publication-setup/ErrorModal.js';

import { UPLOAD_MAX_SIZE } from 'static/Constants';

const UploadProductApl = () => {
  const [visibleModal, setVisibleModal] = useState(false);

  const aplDetail = useSelector(makeSelectAplDetail());

  return (
    <>
      <RibbonButton
        size='small'
        icon={<UploadOutlined style={{ height: 26 }} />}
        className='button-item-dropdown'
        label={Messages.uploadTemplate}
        onClick={() => setVisibleModal(true)}
        disabled={!aplDetail?.isOwner}
      />
      <ModalUploadTemplateProduct
        visibleModal={visibleModal}
        aplId={aplDetail?.aplId}
        turnOffModal={() => setVisibleModal(false)}
      />
    </>
  );
};

const ModalUploadTemplateProduct = (props) => {
  const { visibleModal, turnOffModal, aplId } = props;
  const [errorList, setErrorList] = useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (visibleModal) setErrorList([]);
  }, [visibleModal]);

  const handleResponseUpload = (response) => {
    if (response?.data) {
      if (response?.data?.isSuccess) {
        notification.success({
          message: response?.data?.message,
        });
        turnOffModal();
        dispatch(toggleReloadGrid(true));
        setTimeout(() => {
          dispatch(toggleReloadGrid(false));
        }, 100);
      } else {
        if (response?.data?.errors) {
          setErrorList(response?.data?.errors);
        }
      }
    }
  };

  return (
    <Modal
      width={'50vw'}
      bodyStyle={{ height: '50vh' }}
      title='Upload Template Product'
      visible={visibleModal}
      onCancel={turnOffModal}
      closable={true}
      maskClosable={false}
      footer={null}
      destroyOnClose
      centered
    >
      <div className='action-product__modal'>
        <UploadFile
          uploadAPL={true}
          manualUpload={true}
          isBase64={false}
          showMultiple={false}
          showXlsTemplate={false}
          apiUrl={aplEndpoints.UPLOAD_APL_PRODUCT_LIST}
          additionalBodyPayload={{
            AplId: aplId,
          }}
          nameFormData='TemplateFile'
          showUploadSuccessMsg={false}
          showServerMessage={true}
          getResponse={(response) => handleResponseUpload(response)}
          maxSize={UPLOAD_MAX_SIZE.GENERAL}
          supportTypes={['xlsx', 'xls']}
        />
        <div className='action-product__modal--error-text'>
          {errorList && errorList.length > 0 && (
            <ErrorModal
              errorList={errorList}
              isApl={true}
              title={'APL Errors'}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UploadProductApl;
