import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Checkbox,
  Row,
  Col,
  Radio,
  InputNumber,
  Typography,
} from 'antd';
import { TableOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/home';

import { Form } from 'common/components';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { useSetDefaultWidth, useChangeWidth } from './hooks';
import {
  getListFieldName,
  deleteColumnCheckbox,
  getNewDefaultColumns,
  getColumnsAfterChangeWidth,
  getDefaultConfig,
} from './utils';

import './style.less';

const { Text } = Typography;

const GridColumnsControl = ({ disabled }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(0);
  const [selectedFieldName, setSelectedFieldName] = useState('');

  const { allColumns, chosenColumns, id } = useSelector(
    selectorsGridView.makeSelectGridConfig()
  );

  useSetDefaultWidth(form, chosenColumns, selectedFieldName);
  useChangeWidth(form, width);

  const handleClickField = (fieldName) => {
    const findChosenColumns = chosenColumns.find(
      (col) => col.fieldName === fieldName
    );

    if (!findChosenColumns) {
      setWidth(0);
    }

    if (findChosenColumns?.width) {
      setWidth(findChosenColumns.width);
    }

    setSelectedFieldName(fieldName);
  };

  const handleChangeWidth = (width) => {
    const result = getColumnsAfterChangeWidth(
      selectedFieldName,
      width,
      chosenColumns
    );
    dispatch(actionsGridView.changeWidthColumn(result));
  };

  const handleFilterColumnsGrid = () => {
    let result = getDefaultConfig(chosenColumns);
    dispatch(actionsGridView.chooseGridColumns(result, id));
    dispatch(actionsGridView.updateIsRefreshGrid());
    setVisible(false);
  };

  return (
    <>
      <Modal
        title='Choose Detail'
        visible={visible}
        onOk={handleFilterColumnsGrid}
        onCancel={() => setVisible(false)}
      >
        {visible && (
          <ListColumns
            form={form}
            columns={allColumns}
            width={width}
            chosenColumns={chosenColumns}
            handleClickField={handleClickField}
            handleChangeWidth={handleChangeWidth}
          />
        )}
      </Modal>
      <ButtonSmallIcon
        icon={<TableOutlined />}
        label={Messages.gridColumns}
        disabled={disabled}
        onClick={() => setVisible(true)}
        className='grid-config__button'
      />
    </>
  );
};

const ListColumns = (props) => {
  const { form, columns, chosenColumns, handleClickField, handleChangeWidth } =
    props;

  const dispatch = useDispatch();
  const defaultRadio = deleteColumnCheckbox(chosenColumns)[0]?.fieldName;

  // Remove checkbox column
  const columnsRender = deleteColumnCheckbox(columns);

  const handleChangeColumns = (checkedValues) => {
    const newColumns = getNewDefaultColumns(
      checkedValues,
      columns,
      chosenColumns
    );
    dispatch(actionsGridView.changeSelectedColumns(newColumns));
  };

  return (
    <>
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={handleChangeColumns}
        defaultValue={getListFieldName(chosenColumns)}
      >
        <Radio.Group
          defaultValue={defaultRadio}
          size='large'
          buttonStyle='solid'
          onChange={(e) => handleClickField(e.target.value)}
        >
          <Row>
            {columnsRender.map((column, index) => (
              <Col span={12} key={index} className='choose-grid__item'>
                <Checkbox
                  value={column.fieldName}
                  className='choose-grid__item--checkbox'
                />
                <Radio.Button
                  value={column.fieldName}
                  className='choose-grid__item--radio'
                >
                  {column.displayName}
                </Radio.Button>
              </Col>
            ))}
          </Row>
        </Radio.Group>

        <Row align='middle' style={{ paddingTop: 30 }}>
          <Col span={12}>
            <Text>Width of selected column (in pixels): </Text>
          </Col>
          <Col span={12}>
            <Form form={form}>
              <Form.Item name='width' noStyle>
                <InputNumber onChange={handleChangeWidth} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Checkbox.Group>
    </>
  );
};

GridColumnsControl.propTypes = {};

export default GridColumnsControl;
