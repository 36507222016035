import React from 'react';

import { Button, Space } from 'antd';

import './AnonViewWrap.less';

const AnonViewWrap = (props) => {
  const { children, handleLogout } = props;

  return (
    <div className='anon-view-wrap'>
      <div className='anon-view-wrap__setting'>
        <Space>
          <Button onClick={handleLogout}>Log out</Button>
        </Space>
      </div>
      <div className='anon-view-wrap__page'>{children}</div>
    </div>
  );
};

export default AnonViewWrap;
