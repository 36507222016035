import React from 'react';

import { Input, DatePicker, Select } from 'antd';

import { Form, StyledModal } from 'common/components';

import * as constants from 'static/Constants';

import { DOCUMENT_CERTIFICATE_LIMIT_CHARACTER } from 'pages/qa-spec/constant';

import { WrapperSelect } from 'common/components';

import * as Const from 'static/Constants';

const ModalUpdateDocumentOrCertification = ({
  type,
  formInstance,
  ...otherProps
}) => {
  const renderFormItemDocument = () => {
    return (
      <Form
        form={formInstance}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues !== currentValues
          }
        >
          {({ getFieldValue }) => {
            const options = Const.QA_SPEC_DOCUMENT_SUBTYPE.sort();

            return (
              <Form.Item
                name={'subtype'}
                label='Sub Type: '
                rules={[
                  {
                    required: true,
                    message: 'Please select asset subtype',
                  },
                ]}
              >
                <WrapperSelect>
                  {options.map((item, index) => {
                    return (
                      <Select.Option value={item} key={index}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </WrapperSelect>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item label='Document Name' name='documentName'>
          <Input />
        </Form.Item>
        {/* <Form.Item label='Document Type' name='documentType'>
          <Input
            showCount
            maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.documentType}
          />
        </Form.Item> */}
      </Form>
    );
  };

  const renderFormItemCertification = () => {
    return (
      <Form
        form={formInstance}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name='certificateType' label='Certificate type'>
          <WrapperSelect>
            {Const.DOCUMENT_CERTIFICATE_FIELD?.slice()
              .sort()
              .map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
          </WrapperSelect>
        </Form.Item>
        <Form.Item label='Certificate' name='certificate'>
          <Input />
        </Form.Item>
        <Form.Item label='Certificate Body' name='certificateBody'>
          <Input
            showCount
            maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.certificateBody}
          />
        </Form.Item>
        <Form.Item label='Certificate Expiry Date' name='certificateExpiryDate'>
          <DatePicker format={constants.DATE_FORMAT.SYSTEM_FORMAT} />
        </Form.Item>
      </Form>
    );
  };

  return (
    <StyledModal title='Update Document' {...otherProps}>
      {type === 'document'
        ? renderFormItemDocument()
        : renderFormItemCertification()}
    </StyledModal>
  );
};

export default ModalUpdateDocumentOrCertification;
