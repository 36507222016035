import React from 'react';

import ContentEditable from 'react-contenteditable';
import { useEditor } from '@craftjs/core';
import _ from 'lodash';

const EmailEditorInlineText = (props) => {
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { content = '', onChangeContent } = props;

  const isDisabled = !enabled;

  const onChange = (e) => {
    const value = e.target.value;
    onChangeContent(value);
  };

  return (
    <ContentEditable
      tagName='span'
      html={content}
      style={{ outline: 'none' }}
      onChange={onChange}
      disabled={isDisabled}
    ></ContentEditable>
  );
};

export default EmailEditorInlineText;
