export const GET_MAPPING_LIST = '/api/mapping/get-mapping-grid';
export const GET_APL_MAPPING_LIST_DISTINCT =
  '/api/mapping/get-mapping-grid/distinct';

// Mapping grid in left menu
export const GET_MAPPING_GRID = '/api/mapping/get-mapping-grid';
export const GET_MAPPING_GRID_DISTINCT =
  '/api/mapping/get-mapping-grid/distinct';

//* MAPPING CONFIG = MAPPING DETAIL

//* MAPPING PROPERTY = MAPPING CONFIG
export const SAVE_MAPPING_CONFIG = '/api/mapping/save-mapping-property';
export const DELETE_MAPPING_CONFIG = '/api/mapping/delete-mapping-property';
export const CLONE_MAPPING_CONFIG = '/api/mapping/clone-mapping-property';

export const GET_MAPPING_CONFIG_PROPERTIES =
  '/api/mapping/get-mapping-property-config';

export const GET_MAPPING_CONFIG_GRID =
  '/api/mapping/get-mapping-properties-grid';
export const GET_MAPPING_CONFIG_DISTINCT =
  '/api/mapping/get-mapping-properties-grid/distinct';
export const GET_MAPPING_SOURCE_PROPERTIES =
  '/api/mapping/get-mapping-source-properties-grid';

// Create, edit mapping in mapping grid
export const SAVE_MAPPING_GRID_CONFIG = '/api/mapping/save-mapping-config';
export const GET_MAPPING_CONFIG = '/api/mapping/get-mapping-config';

export const GET_MEMBER_SHARED_LIST = '/api/mapping/get-members-shared-grid';
export const GET_MEMBER_SHARED_LIST_DISTINCT =
  '/api/mapping/get-members-shared-grid/distinct';
export const REMOVE_SUPPORTED_MEMBER =
  '/api/mapping/remove-mapping-shared-member';
export const DELETE_MAPPING_GRID_CONFIG = '/api/mapping/delete-mapping-config';
export const CLONE_MAPPING_GRID_CONFIG = '/api/mapping/clone-mapping-config';

export const GET_MEMBERS_FOR_SHARING_GRID =
  '/api/mapping/get-members-for-sharing-grid';
export const GET_MEMBERS_FOR_SHARING_GRID_DISTINCT =
  '/api/mapping/get-members-for-sharing-grid/distinct';
export const ADD_MAPPING_SHARED_MEMBER =
  '/api/mapping/add-mapping-shared-member';

export const GET_MAPPING_SUPPORTED_MEMBER =
  '/api/mapping/get-mapping-supported-members';

export const INIT_PRODUCT_MAPPING = '/api/mapping/initiate-mapping-product';

export const DOWNLOAD_MAPPING_PRODUCT_SHEETS =
  '/api/mapping/download-mapping-product-sheet';

export const UPDATE_MAPPING_MANUAL_FIELD_VALUE =
  '/api/mapping/update-mapping-field-manual-value';

export const GET_SUB_MAPPING_GRID =
  '/api/mapping/get-sub-mapping-grid-for-subscription';
