const sortByKey = (list, key) => {
  if (list?.length > 0) {
    const tempList = [...list];
    const sortList = tempList?.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortList;
  }

  return [];
};

export const sortByKeyCallback = (key) => (a, b) => {
  let procA = key ? a?.[key] : a;
  let procB = key ? b?.[key] : b;

  return procA < procB ? -1 : procA > procB ? 1 : 0;
};

export default sortByKey;
