import { defineMessages } from 'react-intl';

export const scope = 'Taco.members';
const memberProfileMessage = defineMessages({
  makeContact: {
    id: `${scope}.components.info.makeContact`,
    defaultMessage: 'Make Contact',
  },
  memberName: {
    id: `${scope}.components.info.memberName`,
    defaultMessage: 'Name',
  },
  contact: {
    id: `${scope}.components.info.contact`,
    defaultMessage: 'Contact',
  },
  phone: {
    id: `${scope}.components.info.phone`,
    defaultMessage: 'Phone',
  },
  fax: {
    id: `${scope}.components.info.fax`,
    defaultMessage: 'Fax',
  },
  email: {
    id: `${scope}.components.info.email`,
    defaultMessage: 'Email',
  },
  cancelEditButton: {
    id: `${scope}.components.info.cancelEditButton`,
    defaultMessage: 'Cancel',
  },
  saveEditButton: {
    id: `${scope}.components.info.saveEditButton`,
    defaultMessage: 'Save',
  },
  editButtonTooltip: {
    id: `${scope}.components.info.editButtonTooltip`,
    defaultMessage: 'Edit info',
  },
  localUpload: {
    id: `${scope}.components.info.profileImage.localUpload`,
    defaultMessage: 'Choose image from local',
  },
  logoAssetUpload: {
    id: `${scope}.components.info.profileImage.logoAssetUpload`,
    defaultMessage: 'Choose image from logo assets',
  },
  daymonProperties: {
    id: `${scope}.components.tabs.overview.description.daymonProperties`,
    defaultMessage: 'Daymon Properties',
  },
  daymonFamiliarity: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.daymonFamiliarity`,
    defaultMessage: 'Daymon Familiarity',
  },
  accountOwner: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.accountOwner`,
    defaultMessage: 'Account Owner',
  },
  daymonAccountsServiced: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.daymonAccountsServiced`,
    defaultMessage: 'Daymon Accounts Serviced',
  },
  inactiveDate: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.inactiveDate`,
    defaultMessage: 'Inactive Date',
  },
  deactivationReason: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.deactivationReason`,
    defaultMessage: 'Deactivation Reason',
  },
  tradingPartnerObjectType: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.tradingPartnerObjectType`,
    defaultMessage: 'Trading Partner Object Type',
  },
  mdmid: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.mdmid`,
    defaultMessage: 'MDMID',
  },
  globalSourcingVettedFlag: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.globalSourcingVettedFlag`,
    defaultMessage: 'Global Sourcing Vetted Flag',
  },
  globalSourcingVettedDate: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.globalSourcingVettedDate`,
    defaultMessage: 'Global Sourcing Vetted Date',
  },
  exportOutsideOfDomesticFlag: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.exportOutsideOfDomesticFlag`,
    defaultMessage: 'Export Outside Of Domestic Flag',
  },
  internationalSupplierFlag: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.internationalSupplierFlag`,
    defaultMessage: 'International Supplier Flag',
  },
  controlBrandPackerLabelFlag: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.controlBrandPackerLabelFlag`,
    defaultMessage: 'Control Brand Packer Label Flag',
  },
  memberTypeLabel: {
    id: `${scope}.components.tabs.overview.memberTypeLabel`,
    defaultMessage: 'Member Type',
  },
  brandedControlBrandsProducedFlag: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.brandedControlBrandsProducedFlag`,
    defaultMessage: 'Branded Control Brands Produced Flag',
  },
  importExportType: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.importExportType`,
    defaultMessage: 'Import Export Type',
  },
  lineOfBusiness: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.lineOfBusiness`,
    defaultMessage: 'Line Of Business',
  },
  dbConfidenceCode: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.dbConfidenceCode`,
    defaultMessage: 'D&B Confidence Code',
  },
  dbMatchGrade: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.dbMatchGrade`,
    defaultMessage: 'D&B Match Grade',
  },
  dbbemfab: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.dbbemfab`,
    defaultMessage: 'D&B BEMF AB',
  },
  smallBusinessIndicatorFlag: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.smallBusinessIndicatorFlag`,
    defaultMessage: 'Small Business Indicator Flag',
  },
  jdeid: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.jdeid`,
    defaultMessage: 'JDE ID',
  },
  corporateIdentifier: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.corporateIdentifier`,
    defaultMessage: 'Corporate Identifier',
  },
  corporateIdentifierType: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.corporateIdentifierType`,
    defaultMessage: 'Corporate Identifier Type',
  },
  orderCaptureCapabilities: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.orderCaptureCapabilities`,
    defaultMessage: 'Order Capture Capabilities',
  },
  supplierMasterNumber: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.supplierMasterNumber`,
    defaultMessage: 'Supplier Master Number',
  },
  supplierAccountNumber: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.supplierAccountNumber`,
    defaultMessage: 'Supplier Account Number',
  },
  supplierLocationNumber: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.supplierLocationNumber`,
    defaultMessage: 'Supplier Location Number',
  },
  supplierLevel: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.supplierLevel`,
    defaultMessage: 'Supplier Level',
  },
  daymonStatus: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.daymonStatus`,
    defaultMessage: 'Status',
  },
  subcategoryCapabilities: {
    id: `${scope}.components.tabs.overview.description.daymonProperties.subcategoryCapabilities`,
    defaultMessage: 'Subcategory Capabilities',
  },
  systemVisibility: {
    id: `${scope}.components.tabs.system.visibility.title`,
    defaultMessage: 'Visibility',
  },
  systemVisibilityEdit: {
    id: `${scope}.components.tabs.system.visibility.edit`,
    defaultMessage: 'Edit Visibility',
  },
  companyProfile: {
    id: `${scope}.components.tabs.system.visibility.companyProfile`,
    defaultMessage: 'Company Profile',
  },
  productShared: {
    id: `${scope}.components.tabs.system.visibility.products`,
    defaultMessage: 'Products',
  },
  digitalAssetsShare: {
    id: `${scope}.components.tabs.system.visibility.digitalAssets`,
    defaultMessage: 'Digital Assets',
  },
  userAccount: {
    id: `${scope}.components.tabs.system.visibility.userAccounts`,
    defaultMessage: 'User Accounts',
  },
  visibility: {
    id: `${scope}.components.tabs.system.visibility.title`,
    defaultMessage: 'Visibility',
  },
  folders: {
    id: `${scope}.components.tabs.system.visibility.folders`,
    defaultMessage: 'Folders',
  },
  editVisibilitySuccess: {
    id: `${scope}.components.tabs.system.visibility.edit.success`,
    defaultMessage: 'Edit visibility successfully!',
  },
  editVisibilityError: {
    id: `${scope}.components.tabs.system.visibility.edit.error`,
    defaultMessage: 'Cannot edit visibility!',
  },
  editDefaultSettingSuccess: {
    id: `${scope}.components.tabs.system.default.edit.editDefaultSettingSuccess`,
    defaultMessage: 'Edit default setting successfully!',
  },
  editDefaultSettingError: {
    id: `${scope}.components.tabs.system.default.edit.editDefaultSettingError`,
    defaultMessage: 'Cannot edit default setting!',
  },
  defaults: {
    id: `${scope}.components.tabs.system.defaults.title`,
    defaultMessage: 'Defaults',
  },
  defaultsEdit: {
    id: `${scope}.components.tabs.system.defaults.edit`,
    defaultMessage: 'Edit Defaults',
  },
  passwordReset: {
    id: `${scope}.components.tabs.system.defaults.passwordReset`,
    defaultMessage: 'Password Reset',
  },
  reusePassword: {
    id: `${scope}.components.tabs.system.defaults.reusePassword`,
    defaultMessage: 'Reuse Password',
  },
  passwordStrength: {
    id: `${scope}.components.tabs.system.defaults.passwordStrength`,
    defaultMessage: 'Password Strength',
  },
  userPermission: {
    id: `${scope}.components.tabs.system.defaults.userPermission`,
    defaultMessage: 'User Permission',
  },
  userPersonalization: {
    id: `${scope}.components.tabs.system.defaults.userPersonalization`,
    defaultMessage: 'User Personalization',
  },
  landingPage: {
    id: `${scope}.components.tabs.system.defaults.landingPage`,
    defaultMessage: 'Landing Page',
  },
  requireMfa: {
    id: `${scope}.components.tabs.system.defaults.requireMfa`,
    defaultMessage: 'Require MFA',
  },
  gtinupc12: {
    id: `${scope}.components.tabs.system.defaults.type`,
    defaultMessage: 'GTIN / UPC12',
  },
  apiServices: {
    id: `${scope}.components.tabs.system.apiServices.title`,
    defaultMessage: 'API Services',
  },
  apiEdit: {
    id: `${scope}.components.tabs.system.apiServices.edit`,
    defaultMessage: 'Edit API Services',
  },
  apiEnabled: {
    id: `${scope}.components.tabs.system.apiServices.apiEnabled`,
    defaultMessage: 'API Enabled',
  },
  apiToken: {
    id: `${scope}.components.tabs.system.apiServices.apiToken`,
    defaultMessage: 'API Token',
  },
  expirationDate: {
    id: `${scope}.components.tabs.system.apiServices.expirationDate`,
    defaultMessage: 'Expiry Date',
  },
  callPayload: {
    id: `${scope}.components.tabs.system.apiServices.callPayload`,
    defaultMessage: 'Call Payload',
  },
  apiLastConnectionDate: {
    id: `${scope}.components.tabs.system.apiServices.apiLastConnection`,
    defaultMessage: 'Last Connection',
  },
  apiSendEmailSuccess: {
    id: `${scope}.components.tabs.system.apiServices.sendEmailSuccess`,
    defaultMessage: 'Send token successfully',
  },
  apiSendEmailError: {
    id: `${scope}.components.tabs.system.apiServices.sendEmailError`,
    defaultMessage: 'Cannot send token',
  },
  generateBtn: {
    id: `${scope}.components.tabs.system.apiServices.generateBtn`,
    defaultMessage: 'Generate',
  },
  apiGenerateTokenSuccess: {
    id: `${scope}.components.tabs.system.apiServices.generateTokenSuccess`,
    defaultMessage: 'Generate new token successfully',
  },
  apiGenerateTokenError: {
    id: `${scope}.components.tabs.system.apiServices.generateTokenError`,
    defaultMessage: 'Cannot generate new token',
  },
  expiredDateLabel: {
    id: `${scope}.components.tabs.system.apiServices.expiredDateLabel`,
    defaultMessage: 'Expired Date',
  },
  expiredDateRequired: {
    id: `${scope}.components.tabs.system.apiServices.expiredDateRequired`,
    defaultMessage: 'This field is required',
  },
  editApiServiceSuccess: {
    id: `${scope}.components.tabs.system.apiServices.edit.success`,
    defaultMessage: 'Edit successfully',
  },
  editApiServiceError: {
    id: `${scope}.components.tabs.system.apiServices.edit.error`,
    defaultMessage: 'Cannot edit',
  },
  ftpServices: {
    id: `${scope}.components.tabs.system.ftpServices.title`,
    defaultMessage: 'FTP Services',
  },
  ftpEdit: {
    id: `${scope}.components.tabs.system.ftpServices.edit`,
    defaultMessage: 'Edit FTP Services',
  },
  ftpEnabled: {
    id: `${scope}.components.tabs.system.ftpServices.ftpEnabled`,
    defaultMessage: 'FTP Enabled',
  },
  ftpUserName: {
    id: `${scope}.components.tabs.system.ftpServices.userName`,
    defaultMessage: 'User Name',
  },
  ftpPassword: {
    id: `${scope}.components.tabs.system.ftpServices.password`,
    defaultMessage: 'Password',
  },
  diskUsage: {
    id: `${scope}.components.tabs.system.ftpServices.diskUsage`,
    defaultMessage: 'Disk Usage',
  },
  ftpLastConnectionDate: {
    id: `${scope}.components.tabs.system.ftpServices.ftpLastConnection`,
    defaultMessage: 'Last Connection',
  },
  ftpGeneratePasswordSuccess: {
    id: `${scope}.components.tabs.system.ftpServices.generatePassword.message.success`,
    defaultMessage: 'Generate FTP Password successfully',
  },
  ftpGeneratePasswordError: {
    id: `${scope}.components.tabs.system.ftpServices.generatePassword.message.error`,
    defaultMessage: 'Cannot generate FTP Password',
  },
  enabledFtpLabel: {
    id: `${scope}.components.tabs.system.ftpServices.form.edit.label.enabledFtp`,
    defaultMessage: 'FTP Enabled',
  },
  editFtpSuccess: {
    id: `${scope}.components.tabs.system.ftpServices.form.edit.message.success`,
    defaultMessage: 'Edit Successfully',
  },
  editFtpError: {
    id: `${scope}.components.tabs.system.ftpServices.form.edit.message.error`,
    defaultMessage: 'Cannot Edit',
  },
  tooltipDownload: {
    id: `${scope}.components.tabs.system.apiServices.icon.tooltip.download`,
    defaultMessage: 'Download',
  },
  tooltipSendMail: {
    id: `${scope}.components.tabs.system.apiServices.icon.tooltip.sendMail`,
    defaultMessage: 'Send mail',
  },
  damManageTitle: {
    id: `${scope}.components.tabs.system.damManage.title`,
    defaultMessage: 'Digital Asset Management',
  },
  productManagementTitle: {
    id: `${scope}.components.tabs.system.productManagement.title`,
    defaultMessage: 'Product Management',
  },
  productCustomPropertiesTitle: {
    id: `${scope}.components.tabs.system.productCustomProperties.title`,
    defaultMessage: 'Custom Properties',
  },
  productCustomPropertiesSubmit: {
    id: `${scope}.components.tabs.system.productCustomProperties.submit`,
    defaultMessage: 'Submit',
  },
  productCustomPropertiesFieldName: {
    id: `${scope}.components.tabs.system.productCustomProperties.fieldName`,
    defaultMessage: 'Field Name',
  },
  productCustomPropertiesDisplayName: {
    id: `${scope}.components.tabs.system.productCustomProperties.displayName`,
    defaultMessage: 'Display Name',
  },
  productCustomPropertiesFieldType: {
    id: `${scope}.components.tabs.system.productCustomProperties.fieldType`,
    defaultMessage: 'Field Type',
  },
  productCustomPropertiesIsPublic: {
    id: `${scope}.components.tabs.system.productCustomProperties.isPublic`,
    defaultMessage: 'Public',
  },
  customPropertiesSuccess: {
    create: {
      id: `${scope}.components.tabs.system.productCustomProperties.createSuccess`,
      defaultMessage: 'Create custom property successfully',
    },
    edit: {
      id: `${scope}.components.tabs.system.productCustomProperties.editSuccess`,
      defaultMessage: 'Update custom property successfully',
    },
  },
  customPropertiesError: {
    create: {
      id: `${scope}.components.tabs.system.productCustomProperties.createError`,
      defaultMessage: 'Cannot create custom property',
    },
    edit: {
      id: `${scope}.components.tabs.system.productCustomProperties.editError`,
      defaultMessage: 'Cannot edit custom property',
    },
  },
  deleteCustomPropertyConfirm: {
    id: `${scope}.components.tabs.system.productCustomProperties.deleteCustomPropertyConfirm`,
    defaultMessage:
      'Delete custom property will remove all values associated with product(s). Are you sure you want to continue?',
  },
  deleteCustomPropertySuccess: {
    id: `${scope}.components.tabs.system.productCustomProperties.deleteSuccess`,
    defaultMessage: 'Delete custom property successfully',
  },
  deleteCustomPropertyError: {
    id: `${scope}.components.tabs.system.productCustomProperties.deleteError`,
    defaultMessage: 'Cannot edit custom property',
  },
  productCustomPropertiesRequired: {
    id: `${scope}.components.tabs.system.productCustomProperties.required`,
    defaultMessage: 'This field is required',
  },
  productCustomPropertiesInvalid: {
    id: `${scope}.components.tabs.system.productCustomProperties.invalid`,
    defaultMessage: 'This field is not valid',
  },
  productCustomPropertiesFieldNameError: {
    id: `${scope}.components.tabs.system.productCustomProperties.fieldNameError`,
    defaultMessage:
      'Whitespace, underscores, hyphens, or any other non-alphanumeric characters is not allowed',
  },
  productCustomPropertiesFieldNameFirstLetterError: {
    id: `${scope}.components.tabs.system.productCustomProperties.fieldNameError.firstLetter`,
    defaultMessage: 'First letter must be lowercase',
  },
  productCustomPropertiesString: {
    id: `${scope}.components.tabs.system.productCustomProperties.string`,
    defaultMessage: 'string',
  },
  productCustomPropertiesBoolean: {
    id: `${scope}.components.tabs.system.productCustomProperties.boolean`,
    defaultMessage: 'boolean',
  },
  productCustomPropertiesNumber: {
    id: `${scope}.components.tabs.system.productCustomProperties.number`,
    defaultMessage: 'number',
  },
  productCustomPropertiesDate: {
    id: `${scope}.components.tabs.system.productCustomProperties.date`,
    defaultMessage: 'date',
  },
  definedPropertiesTitle: {
    id: `${scope}.components.tabs.system.damManage.definedPropertiesTitle`,
    defaultMessage: 'Member Defined Properties',
  },
  propertiesListingTitle: {
    id: `${scope}.components.tabs.system.damManage.propertiesListing`,
    defaultMessage: 'Custom Properties',
  },
  tagListingTitle: {
    id: `${scope}.components.tabs.system.damManage.tagListingTitle`,
    defaultMessage: 'Tag Listing',
  },
  enforceTagsRestrictions: {
    id: `${scope}.components.tabs.system.damManage.enfore.tagRestriction`,
    defaultMessage: 'Enforce Tags Restrictions',
  },
  downloadTags: {
    id: `${scope}.components.tabs.system.damManage.download`,
    defaultMessage: 'Download',
  },
  downloadTagCleanup: {
    id: `${scope}.components.tabs.system.damManage.download.tagCleanup`,
    defaultMessage: 'Tag Cleanup',
  },
  downloadTagRestrictions: {
    id: `${scope}.components.tabs.system.damManage.download.tagRestrictions`,
    defaultMessage: 'Tag Restrictions',
  },
  uploadTagRestrictions: {
    id: `${scope}.components.tabs.system.damManage.upload`,
    defaultMessage: 'Upload',
  },
  copyMsg: {
    id: `${scope}.components.message.copyMsg`,
    defaultMessage: 'Copied',
  },
  contactHistory: {
    id: `${scope}.components.message.contactHistory`,
    defaultMessage: 'Contact History',
  },
  editSuccess: {
    id: `${scope}.edit.message.editHeaderSuccess`,
    defaultMessage: 'Update company successfully',
  },
  editError: {
    id: `${scope}.edit.message.editHeaderError`,
    defaultMessage: 'Cannot update company',
  },
  editNameSuccess: {
    id: `${scope}.edit.message.editNameSuccess`,
    defaultMessage: 'Update name successfully',
  },
  editNameError: {
    id: `${scope}.edit.message.editNameError`,
    defaultMessage: 'Cannot update name',
  },
  editLogoSuccess: {
    id: `${scope}.edit.message.editLogoSuccess`,
    defaultMessage: 'Update logo successfully',
  },
  uploadBrandImage: {
    id: `${scope}.edit.message.editLogoSuccess`,
    defaultMessage: 'Update brand image',
  },
  requiredLogoMessages: {
    id: `${scope}.edit.message.logoRequiredMessage`,
    defaultMessage: 'Brand logo is required',
  },
  editLogoError: {
    id: `${scope}.edit.message.editLogoError`,
    defaultMessage: 'Cannot update logo',
  },
  updatedContentSuccess: {
    id: `${scope}.edit.message.updatedContentSuccess`,
    defaultMessage: 'Updated successfully',
  },
  showLogoLabel: {
    id: `${scope}.edit.logo.show.label`,
    defaultMessage: 'Display Logo',
  },
  showAddressLabel: {
    id: `${scope}.edit.address.show.label`,
    defaultMessage: 'Display Address',
  },
  editShowingSuccess: {
    id: `${scope}.edit.showing.message.success`,
    defaultMessage: 'Edit showing options successfully.',
  },
  editShowingError: {
    id: `${scope}.edit.showing.message.failed`,
    defaultMessage: 'Cannot edit showing options.',
  },
  openMedia: {
    id: `${scope}.components.tabs.tagLine.openMedia`,
    defaultMessage: 'Open Media Source',
  },
  closeMedia: {
    id: `${scope}.components.tabs.tagLine.closeMedia`,
    defaultMessage: 'Close Media Source',
  },
  deleteMembers: {
    id: `${scope}.components.ribbon.deleteMembers`,
    defaultMessage: 'Delete',
  },
  deleteSuccess: {
    id: `${scope}.components.ribbon.deleteSuccess`,
    defaultMessage: 'Deleted successfully!',
  },
  areYouSureToDelete: {
    id: `${scope}.components.ribbon.areYouSureToDelete`,
    defaultMessage: 'Are you sure to delete?',
  },
  idLabel: {
    id: `${scope}.profile.detail.label.id`,
    defaultMessage: 'ID #',
  },
  certificationName: {
    id: `${scope}.components.details.profile.certificationName`,
    defaultMessage: 'Certification Name',
  },
  dateReceived: {
    id: `${scope}.components.details.profile.dateReceived`,
    defaultMessage: 'Date Received',
  },
  expiryDate: {
    id: `${scope}.components.details.profile.expiryDate`,
    defaultMessage: 'Expire Date',
  },
  documentBackup: {
    id: `${scope}.components.details.profile.documentBackup`,
    defaultMessage: 'Document Backup',
  },
  dbaLabel: {
    id: `${scope}.profile.detail.label.dba`,
    defaultMessage: 'DBA Name',
  },
  legalEntityTypeLabel: {
    id: `${scope}.profile.detail.label.legalEntityType`,
    defaultMessage: 'Legal Entity Type',
  },
  statusLabel: {
    id: `${scope}.profile.detail.label.status`,
    defaultMessage: 'Status',
  },
  headquartersCountryLabel: {
    id: `${scope}.profile.detail.label.headquartersCountry`,
    defaultMessage: 'Headquarters Country',
  },
  incorporationStateLabel: {
    id: `${scope}.profile.detail.label.incorporationState`,
    defaultMessage: 'State Of Incorporation',
  },
  currencyLabel: {
    id: `${scope}.profile.detail.label.currency`,
    defaultMessage: 'Currency',
  },
  languageLabel: {
    id: `${scope}.profile.detail.label.language`,
    defaultMessage: 'Primary Language',
  },
  glnLabel: {
    id: `${scope}.profile.detail.label.gln`,
    defaultMessage: 'GLN(s)',
  },
  glnRequired: {
    id: `${scope}.profile.detail.label.glnRequired`,
    defaultMessage: 'This field is required',
  },
  rivirPartyLabel: {
    id: `${scope}.profile.detail.label.rivirParty`,
    defaultMessage: 'RIVIR Party',
  },
  rivirPartyStatus: {
    id: `${scope}.profile.detail.label.rivirPartyStatus`,
    defaultMessage: 'Party status',
  },
  GDSNPartyInfoButton: {
    id: `${scope}.profile.detail.GDSNPartyInfo.openButton`,
    defaultMessage: 'GDSN Party Info',
  },
  GDSNPartyInfoTitle: {
    id: `${scope}.profile.detail.GDSNPartyInfo.title`,
    defaultMessage: 'Member GLNs',
  },
  addNewGlnTitle: {
    id: `${scope}.profile.detail.GDSNPartyInfo.AddNewGlnTitle`,
    defaultMessage: 'Add New GLN',
  },
  GDSNPartyInfoRegisterStatus: {
    id: `${scope}.profile.detail.GDSNPartyInfo.registerStatus`,
    defaultMessage: 'Register Status',
  },
  GDSNPartyInfoErrorMessage: {
    id: `${scope}.profile.detail.GDSNPartyInfo.GDSNPartyInfoErrorMessage`,
    defaultMessage: 'Error Message',
  },
  glnAction: {
    id: `${scope}.profile.detail.GDSNPartyInfo.glnAction`,
    defaultMessage: 'Action',
  },
  sendGln: {
    id: `${scope}.profile.detail.GDSNPartyInfo.sendBtn`,
    defaultMessage: 'Send',
  },
  deleteGln: {
    id: `${scope}.profile.detail.GDSNPartyInfo.deleteBtn`,
    defaultMessage: 'Delete',
  },
  unregisterGln: {
    id: `${scope}.profile.detail.GDSNPartyInfo.unregisterGln`,
    defaultMessage: 'Unregister',
  },
  GDSNPartyInfoCancelBtn: {
    id: `${scope}.profile.detail.GDSNPartyInfo.cancelBtn`,
    defaultMessage: 'Cancel',
  },
  GDSNPartyInfoSaveBtn: {
    id: `${scope}.profile.detail.GDSNPartyInfo.saveBtn`,
    defaultMessage: 'Save',
  },
  GDSNPartyInfoAddGlnBtn: {
    id: `${scope}.profile.detail.GDSNPartyInfo.addGlnBtn`,
    defaultMessage: 'Add New GLN',
  },
  GDSNPartyInfoAddBtn: {
    id: `${scope}.profile.detail.GDSNPartyInfo.addBtn`,
    defaultMessage: 'Add',
  },
  editGdsnPartyInfoSuccess: {
    id: `${scope}.profile.detail.GDSNPartyInfo.edit.successMessage`,
    defaultMessage: 'Edit successfully',
  },
  editGdsnPartyInfoError: {
    id: `${scope}.profile.detail.GDSNPartyInfo.edit.errorMessage  `,
    defaultMessage: 'Cannot edit',
  },
  sendGdsnPartyRegisterSuccess: {
    id: `${scope}.profile.detail.GDSNPartyInfo.sendRegister.successMessage`,
    defaultMessage: 'Send register successfully',
  },
  sendGdsnPartyRegisterError: {
    id: `${scope}.profile.detail.GDSNPartyInfo.sendRegister.errorMessage`,
    defaultMessage: 'Cannot send register',
  },
  unregisterPartySuccess: {
    id: `${scope}.profile.detail.GDSNPartyInfo.unregister.successMessage`,
    defaultMessage: 'Unregister successfully',
  },
  unregisterPartyError: {
    id: `${scope}.profile.detail.GDSNPartyInfo.unregister.errorMessage`,
    defaultMessage: 'Cannot Unregister',
  },
  deleteGlnConfirmMessage: {
    id: `${scope}.profile.detail.GDSNPartyInfo.deleteGln.confirmMessage`,
    defaultMessage: 'Are you sure you want to delete this GLN?',
  },
  deleteGlnUnregisConfirmMessage: {
    id: `${scope}.profile.detail.GDSNPartyInfo.deleteGlnUnregis.confirmMessage`,
    defaultMessage: 'Are you sure you want to unregister this GLN?',
  },
  GDSNPartyInfoReloadPageBtn: {
    id: `${scope}.profile.detail.GDSNPartyInfo.edit.reloadBtn`,
    defaultMessage: 'Reload',
  },
  GDSNPartyInfoCancelReloadPageBtn: {
    id: `${scope}.profile.detail.GDSNPartyInfo.edit.cancelBtn`,
    defaultMessage: 'Close without Reload Page',
  },
  GDSNPartyInfoReloadPageNotShowAgain: {
    id: `${scope}.profile.detail.GDSNPartyInfo.edit.notShowAgain`,
    defaultMessage: 'Do not show this again',
  },
  GDSNPartyInfoReloadPagePlease: {
    id: `${scope}.profile.detail.GDSNPartyInfo.edit.reloadMessage`,
    defaultMessage:
      'Member GLNs are modified. Please reload page to sync up latest data',
  },
  dunsLabel: {
    id: `${scope}.profile.detail.label.duns`,
    defaultMessage: 'DUNS #',
  },
  stockSymbolLabel: {
    id: `${scope}.profile.detail.label.stockSymbol`,
    defaultMessage: 'Stock Symbol',
  },
  sicCodeLabel: {
    id: `${scope}.profile.detail.label.sicCode`,
    defaultMessage: 'SIC Code',
  },
  naicsLabel: {
    id: `${scope}.profile.detail.label.naics`,
    defaultMessage: 'NAICS Code',
  },
  brokersLabel: {
    id: `${scope}.profile.detail.label.brokers`,
    defaultMessage: 'Brokers',
  },
  annualRevenueLabel: {
    id: `${scope}.profile.detail.label.annualRevenue`,
    defaultMessage: 'Annual Revenue',
  },
  representedSalesLabel: {
    id: `${scope}.profile.detail.label.representedSales`,
    defaultMessage: 'Represented Sales',
  },
  fiscalYearStartDateLabel: {
    id: `${scope}.profile.detail.label.fiscalYearStartDate`,
    defaultMessage: 'Fiscal Year Start Date',
  },
  minorityOwnedLabel: {
    id: `${scope}.profile.detail.label.minorityOwned`,
    defaultMessage: 'Minority Owned',
  },
  minorityOwnedTypeLabel: {
    id: `${scope}.profile.detail.label.minorityOwnedType`,
    defaultMessage: 'Minority Owned',
  },
  ownershipTypeLabel: {
    id: `${scope}.profile.detail.label.ownershipType`,
    defaultMessage: 'Ownership Type',
  },
  bCorpLabel: {
    id: `${scope}.profile.detail.label.bCorp`,
    defaultMessage: 'B-Corp',
  },
  coOpLabel: {
    id: `${scope}.profile.detail.label.coOp`,
    defaultMessage: 'Co-Op',
  },
  coOp50Label: {
    id: `${scope}.profile.detail.label.coOp50`,
    defaultMessage: '50% Co-Op',
  },
  coPackerLabel: {
    id: `${scope}.profile.detail.label.coPacker`,
    defaultMessage: 'Co-Packer',
  },
  contractPackagingLabel: {
    id: `${scope}.profile.detail.label.contractPackaging`,
    defaultMessage: 'Contract Packaging',
  },
  numberOfEmployeesLabel: {
    id: `${scope}.profile.detail.label.numberOfEmployees`,
    defaultMessage: '# Of Employees',
  },
  numberOfManufacturingPlantsLabel: {
    id: `${scope}.profile.detail.label.numberOfManufacturingPlants`,
    defaultMessage: '# Manufacturing Plants',
  },
  numberOfSalesLocationsLabel: {
    id: `${scope}.profile.detail.label.numberOfSalesLocations`,
    defaultMessage: '# Sales Locations',
  },
  numberOfCustomersLabel: {
    id: `${scope}.profile.detail.label.numberOfCustomers`,
    defaultMessage: '# Customers',
  },
  customersLabel: {
    id: `${scope}.profile.detail.label.customers`,
    defaultMessage: 'Customers',
  },
  majorIndustryClassificationsLabel: {
    id: `${scope}.profile.detail.label.majorIndustryClassifications`,
    defaultMessage: 'Major Industry Classifications',
  },
  produceNationalBrandsLabel: {
    id: `${scope}.profile.detail.label.produceNationalBrands`,
    defaultMessage: 'Produce National Brands',
  },
  productCapabilitiesLabel: {
    id: `${scope}.profile.detail.label.productCapabilities`,
    defaultMessage: 'Product Capabilities',
  },
  productionCapabilitiesLabel: {
    id: `${scope}.profile.detail.label.productionCapabilities`,
    defaultMessage: 'Production Capabilities',
  },
  marketRegionsLabel: {
    id: `${scope}.profile.detail.label.marketRegions`,
    defaultMessage: 'Market Regions',
  },
  exportRegionsLabel: {
    id: `${scope}.profile.detail.label.exportRegions`,
    defaultMessage: 'Export Regions',
  },
  exportPercentageLabel: {
    id: `${scope}.profile.detail.label.exportPercentage`,
    defaultMessage: 'Export Percentage',
  },
  exportCountriesLabel: {
    id: `${scope}.profile.detail.label.exportCountries`,
    defaultMessage: 'Export Countries',
  },
  certificationsLabel: {
    id: `${scope}.profile.detail.label.certifications`,
    defaultMessage: 'Certifications',
  },
  privateLabelLabel: {
    id: `${scope}.profile.detail.label.privateLabel`,
    defaultMessage: 'Private Label',
  },
  privateLabelBusinessPercentageLabel: {
    id: `${scope}.profile.detail.label.privateLabelBusinessPercentage`,
    defaultMessage: 'Private Label Business Percentage',
  },
  privateLabelClassificationsLabel: {
    id: `${scope}.profile.detail.label.privateLabelClassifications`,
    defaultMessage: 'Private Label Classifications',
  },
  autoSendAcceptCicLabel: {
    id: `${scope}.profile.detail.label.autoSendAcceptCic`,
    defaultMessage: 'Send CIC Accept Automatically',
  },
  countriesSuppliedLabel: {
    id: `${scope}.profile.detail.label.countriesSupplied`,
    defaultMessage: 'Countries Supplied',
  },
  brandName: {
    id: `${scope}.components.details.brand.brandName`,
    defaultMessage: 'Brand Name',
  },
  annualRevenue: {
    id: `${scope}.components.details.brand.annualRevenue`,
    defaultMessage: 'Annual Revenue',
  },
  productsSharp: {
    id: `${scope}.components.details.brand.productsSharp`,
    defaultMessage: '# Products',
  },
  marketRegions: {
    id: `${scope}.components.details.brand.marketRegions`,
    defaultMessage: 'Market regions',
  },
  primaryContact: {
    id: `${scope}.components.details.brand.primaryContact`,
    defaultMessage: 'Primary Contact',
  },
  privateLabel: {
    id: `${scope}.components.details.brand.privateLabel`,
    defaultMessage: 'Private Label',
  },
  addNewBrand: {
    id: `${scope}.components.details.brand.addNewBrand`,
    defaultMessage: 'Add New Brand',
  },
  backgroundColor: {
    id: `${scope}.profile.backgroundColor`,
    defaultMessage: 'Background color',
  },
  textColor: {
    id: `${scope}.profile.textColor`,
    defaultMessage: 'Text color',
  },
  editBrandInfoSuccess: {
    id: `${scope}.components.details.brand.editBrandInfoSuccess`,
    defaultMessage: 'Edit brand successfully!',
  },
  editBrandInfoError: {
    id: `${scope}.components.details.brand.editBrandInfoError`,
    defaultMessage: 'Cannot edit brand!',
  },
  addNewUserToRole: {
    id: `${scope}.profile.role.security.addNewUser`,
    defaultMessage: 'Add new user to role',
  },
  createCertificationSuccess: {
    id: `${scope}.components.details.certification.createCertificationSuccess`,
    defaultMessage: 'Created certification successfully!',
  },
  editCertificationSuccess: {
    id: `${scope}.components.details.certification.editCertificationSuccess`,
    defaultMessage: 'Updated certification successfully!',
  },
  deleteCertificationSuccess: {
    id: `${scope}.components.details.certification.deleteCertificationSuccess`,
    defaultMessage: 'Deleted certification successfully!',
  },
  updateLogo: {
    id: `${scope}.components.details.certification.updateLogo`,
    defaultMessage:
      'Update logo successfully, thumb is processing and available shortly!',
  },
  createBrandInfoSuccess: {
    id: `${scope}.components.details.brand.createBrandInfoSuccess`,
    defaultMessage: 'Create brand successfully!',
  },
  createBrandInfoError: {
    id: `${scope}.components.details.brand.createBrandInfoError`,
    defaultMessage: 'Cannot create brand!',
  },
  totalItems: {
    id: `${scope}.components.details.brand.totalItems`,
    defaultMessage: 'items found',
  },
  roleSecurity: {
    id: `${scope}.profile.role.security.title`,
    defaultMessage: 'Roles & Security',
  },
  workflowRole: {
    id: `${scope}.profile.role.workflow.title`,
    defaultMessage: 'Workflow Roles',
  },
  permissionTitle: {
    id: `${scope}.profile.role.security.permissionTitle`,
    defaultMessage: 'Permissions',
  },
  userTitle: {
    id: `${scope}.profile.role.security.userTitle`,
    defaultMessage: 'Users',
  },
  permissionAssigned: {
    id: `${scope}.profile.role.security.permissionAssigned`,
    defaultMessage: 'Permission(s) Assigned',
  },
  userRemoved: {
    id: `${scope}.profile.role.workflow.removeUser`,
    defaultMessage: 'Do you sure to remove this user?',
  },
  roleText: {
    id: `${scope}.profile.role.security.roleText`,
    defaultMessage: 'Role',
  },
  editRoleError: {
    id: `${scope}.profile.role.security.editRole.error`,
    defaultMessage: 'Cannot edit role',
  },
  editRoleSuccess: {
    id: `${scope}.profile.role.security.editRole.success`,
    defaultMessage: 'Edit role success',
  },
  addRoleError: {
    id: `${scope}.profile.role.security.addRole.error`,
    defaultMessage: 'Cannot add role',
  },
  addRoleSuccess: {
    id: `${scope}.profile.role.security.addRole.success`,
    defaultMessage: 'Add role success',
  },
  deleteRoleMessage: {
    id: `${scope}.profile.role.security.deleteRole.message`,
    defaultMessage: 'Are you sure to delete this custom role?',
  },
  deleteUserMessage: {
    id: `${scope}.profile.role.security.deleteRole.message`,
    defaultMessage: 'Are you sure to remove this user?',
  },
  deleteRoleError: {
    id: `${scope}.profile.role.security.deleteRole.error`,
    defaultMessage: 'Cannot delete role',
  },
  deleteRoleSuccess: {
    id: `${scope}.profile.role.security.deleteRole.success`,
    defaultMessage: 'Delete role success',
  },
  userAssigned: {
    id: `${scope}.profile.role.security.userAssigned`,
    defaultMessage: 'User(s) Assigned',
  },
  deleteUsersWarning: {
    id: `${scope}.profile.role.security.deleteUsersWarning`,
    defaultMessage: 'Are you sure to delete users from role:',
  },
  deleteUsersSuccess: {
    id: `${scope}.profile.role.security.deleteUsersSuccess`,
    defaultMessage: 'Delete successfully!',
  },
  deleteUsersFail: {
    id: `${scope}.profile.role.security.deleteUsersFail`,
    defaultMessage: 'Delete failed!',
  },
  defaultFailedText: {
    id: `${scope}.profile.role.security.defaultFailedText`,
    defaultMessage: 'Please try again!',
  },
  addNewUserSuccess: {
    id: `${scope}.profile.role.security.addNewUserSuccess`,
    defaultMessage: 'Add user(s) to role successfully!',
  },
  addNewUserFail: {
    id: `${scope}.profile.role.security.addNewUserFail`,
    defaultMessage: 'Add new user(s) to role failed!',
  },
  assignButton: {
    id: `${scope}.profile.role.security.assignButton`,
    defaultMessage: 'Assign',
  },
  saveUserRolesSuccess: {
    id: `${scope}.profile.role.security.saveUserRolesSuccess`,
    defaultMessage: 'Assign role successfully',
  },
  saveUserRolesError: {
    id: `${scope}.profile.role.security.saveUserRolesError`,
    defaultMessage: 'Cannot assign role',
  },
  definePropertiesAddBtn: {
    id: `${scope}.profile.defineProperty.table.addBtn`,
    defaultMessage: 'Add',
  },
  definePropertiesFieldName: {
    id: `${scope}.profile.defineProperty.table.fieldName`,
    defaultMessage: 'Field Name',
  },
  definePropertiesFieldType: {
    id: `${scope}.profile.defineProperty.table.fieldType`,
    defaultMessage: 'Field Type',
  },
  definePropertiesFieldNameLabel: {
    id: `${scope}.profile.defineProperty.input.label.fieldName`,
    defaultMessage: 'Field Name',
  },
  definePropertiesFieldTypeLabel: {
    id: `${scope}.profile.defineProperty.input.label.fieldType`,
    defaultMessage: 'Field Type',
  },
  definePropertiesRequiredFieldMessage: {
    id: `${scope}.profile.defineProperty.input.message.required`,
    defaultMessage: 'This field is required',
  },
  definePropertiesDuplicatedFieldMessage: {
    id: `${scope}.profile.defineProperty.input.message.duplicatedFieldName`,
    defaultMessage: 'This field name is duplicated',
  },
  definePropertiesDeleteMessage: {
    id: `${scope}.profile.defineProperty.table.delete.notification`,
    defaultMessage: 'Are you sure to delete?',
  },
  definePropertiesDeleteBtn: {
    id: `${scope}.profile.defineProperty.table.btn.delete`,
    defaultMessage: 'Delete',
  },
  definePropertiesEditBtn: {
    id: `${scope}.profile.defineProperty.table.btn.edit`,
    defaultMessage: 'Edit',
  },
  definePropertiesOkBtn: {
    id: `${scope}.profile.defineProperty.table.delete.btn.ok`,
    defaultMessage: 'OK',
  },
  definePropertiesCancelBtn: {
    id: `${scope}.profile.defineProperty.table.delete.btn.cancel`,
    defaultMessage: 'Cancel',
  },
  definePropertiesAddSuccess: {
    id: `${scope}.profile.defineProperty.notify.add.success`,
    defaultMessage: 'Add new property successfully',
  },
  definePropertiesEditSuccess: {
    id: `${scope}.profile.defineProperty.notify.edit.success`,
    defaultMessage: 'Edit new property successfully',
  },
  definePropertiesDeleteSuccess: {
    id: `${scope}.profile.defineProperty.notify.delete.success`,
    defaultMessage: 'Delete property successfully',
  },
  definePropertiesAddError: {
    id: `${scope}.profile.defineProperty.notify.add.error`,
    defaultMessage: 'Cannot add new property',
  },
  definePropertiesEditError: {
    id: `${scope}.profile.defineProperty.notify.edit.error`,
    defaultMessage: 'Cannot edit property',
  },
  definePropertiesDeleteError: {
    id: `${scope}.profile.defineProperty.notify.delete.error`,
    defaultMessage: 'Cannot delete property',
  },
  definePropertiesShowConfig: {
    id: `${scope}.profile.defineProperty.showConfig`,
    defaultMessage: 'Show',
  },
  definePropertiesHideConfig: {
    id: `${scope}.profile.defineProperty.hideConfig`,
    defaultMessage: 'Hide',
  },
  deleteItemsFromBanList: {
    id: `${scope}.ribbon.deleteItemsFromBanList`,
    defaultMessage: 'Are you sure to delete selected items from ban list?',
  },
  informationSheet: {
    id: `${scope}.components.tabs.overview.informationSheet`,
    defaultMessage: 'Sheets',
  },
  uploadInformationSheetModal: {
    id: `${scope}.components.tabs.overview.uploadInformationSheetModal`,
    defaultMessage: 'Upload Information Sheet Modal',
  },
  uploadKeepOldInformationSheet: {
    id: `${scope}.components.tabs.overview.uploadKeepOldInformationSheet`,
    defaultMessage: 'Are you sure to keep the old information sheet?',
  },
  uploadRemoveOldInformationSheet: {
    id: `${scope}.components.tabs.overview.uploadRemoveOldInformationSheet`,
    defaultMessage: 'Are you sure to remove the old information sheet?',
  },
  keepOldInformationSheet: {
    id: `${scope}.components.tabs.overview.keepOldInformationSheet`,
    defaultMessage: 'Keep old information sheet',
  },
  uploadInformationSheetSuccess: {
    id: `${scope}.components.tabs.overview.uploadInformationSheetSuccess`,
    defaultMessage: 'Upload information sheet successfully!',
  },
  deleteInformationSheetSuccess: {
    id: `${scope}.components.tabs.overview.deleteInformationSheetSuccess`,
    defaultMessage: 'Delete information sheets successfully!',
  },
  download: {
    id: `${scope}.components.tabs.overview.download`,
    defaultMessage: 'Download',
  },
  reactive: {
    id: `${scope}.components.tabs.overview.reactive`,
    defaultMessage: 'Reactive',
  },
  reGenerate: {
    id: `${scope}.components.tabs.overview.reGenerate`,
    defaultMessage: 'Re-generate',
  },
  areYouSureToReactive: {
    id: `${scope}.components.tabs.overview.areYouSureToReactive`,
    defaultMessage: 'Are you sure to reactive this information sheet?',
  },
  reactiveInformationSheetSuccess: {
    id: `${scope}.components.tabs.overview.reactiveInformationSheetSuccess`,
    defaultMessage: 'Reactive successfully!',
  },
  areYouSureToReGenerate: {
    id: `${scope}.components.tabs.overview.areYouSureToReGenerate`,
    defaultMessage: 'Are you sure to re-generate this information sheet?',
  },
  reGenerateInformationSheetSuccess: {
    id: `${scope}.components.tabs.overview.reGenerateInformationSheetSuccess`,
    defaultMessage: 'Re-generate successfully!',
  },
  downloadInformationSheetSuccess: {
    id: `${scope}.components.tabs.overview.downloadInformationSheetSuccess`,
    defaultMessage: 'Download successfully!',
  },
  downloadingInformationSheet: {
    id: `${scope}.components.tabs.overview.downloadingInformationSheet`,
    defaultMessage: 'Downloading...',
  },
  memberType: {
    id: `${scope}.components.tabs.overview.memberType`,
    defaultMessage: 'Member Type',
  },
  companyNameLabel: {
    id: `${scope}.profile.detail.label.companyName`,
    defaultMessage: 'Company Name',
  },
  brokerManagementTitle: {
    id: `${scope}.components.tabs.system.brokerManagement.title`,
    defaultMessage: 'Brokers Management',
  },
  canNotLoadDataMessage: {
    id: `${scope}.components.tabs.system.brokerManagement.canNotLoadDataMessage`,
    defaultMessage: 'Can not load data!',
  },
  interoperabilityTitle: {
    id: `${scope}.components.tabs.system.interoperability.title`,
    defaultMessage: 'Interoperability',
  },
  membershipRenewalDate: {
    id: `${scope}.components.tabs.system.membership.renewalDate`,
    defaultMessage: 'Renewal Date',
  },
  membershipMemberSince: {
    id: `${scope}.components.tabs.system.membership.memberSince`,
    defaultMessage: 'Member Since',
  },
  membershipExpirationDate: {
    id: `${scope}.components.tabs.system.membership.expirationDate`,
    defaultMessage: 'Expiration Date',
  },
  membershipDateEstablished: {
    id: `${scope}.components.tabs.system.membership.dateEstablished`,
    defaultMessage: 'Date Established',
  },
  eCommerceProductVolumeTemplate: {
    id: `${scope}.components.tabs.system.membership.eCommerceProductVolumeTemplate`,
    defaultMessage: 'Product Volume',
  },
  eCommerceProductWeightTemplate: {
    id: `${scope}.components.tabs.system.membership.eCommerceProductWeightTemplate`,
    defaultMessage: 'Product Weight',
  },
  supplierManagementTitle: {
    id: `${scope}.components.tabs.system.supplierManagement.title`,
    defaultMessage: 'Suppliers Management',
  },
  productCategotyManagementTitle: {
    id: `${scope}.components.tabs.system.productCategotyManagement.Title`,
    defaultMessage: 'Category Management',
  },
  downLoadCategoryButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.button.downloadCategory`,
    defaultMessage: 'Download Categories',
  },
  updateCategoryButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.button.updateCategory`,
    defaultMessage: 'Upload Categories',
  },
  bulkReassignProductButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.button.bulkReassignProduct`,
    defaultMessage: 'Bulk Reassign Products',
  },
  backCategoryButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.button.back`,
    defaultMessage: 'Back',
  },
  cancelCategoryButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.button.cancel`,
    defaultMessage: 'Cancel',
  },
  assignCategoryButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.button.assign`,
    defaultMessage: 'Assign',
  },
  chooseFromOrToBeforeSelectingText: {
    id: `${scope}.components.tabs.system.productCategotyManagement.tree.overlay`,
    defaultMessage:
      'Choose Source or Destination section before selecting a category. ',
  },
  collapseCategoryButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.tree.button.collapse`,
    defaultMessage: 'Collapse All',
  },
  expandCategoryButton: {
    id: `${scope}.components.tabs.system.productCategotyManagement.button.expand`,
    defaultMessage: 'Expand All',
  },
  supportMaximumLevelMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.supportMaximumLevel`,
    defaultMessage: 'We only support maximum 4 levels',
  },
  addCategorySuccess: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.addCategorySuccess`,
    defaultMessage: 'Add Category Successfully!',
  },
  addCategoryFailed: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.addCategoryFailed`,
    defaultMessage: 'Add Category Failed!',
  },
  editCategorySuccess: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.editCategorySuccess`,
    defaultMessage: 'Edit Category Successfully!',
  },
  editCategoryFailed: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.editCategoryFailed`,
    defaultMessage: 'Edit Category Failed!',
  },
  deleteCategoryConfirmMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.deleteCategoryConfirmMessage`,
    defaultMessage: 'Are you sure you want to delete this Product Category?',
  },
  deleteCategorySuccessMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.deleteCategorySuccessMessage`,
    defaultMessage: 'Delete Category Successfully!',
  },
  existCategoryNameMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.existCategoryNameMessage`,
    defaultMessage: 'Category is already exist!',
  },
  valueBlankMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.valueBlankMessage`,
    defaultMessage: 'Category Name cannot be blank!',
  },
  duplicateCategoryMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.duplicateCategoryMessage`,
    defaultMessage: 'Category name is duplicate!',
  },
  missingParentCategoryLevelMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.missingParentCategoryLevelMessage`,
    defaultMessage: 'Category level is missing!',
  },
  saveBulkReassignProductSuccessMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.saveBulkReassignProductSuccessMessage`,
    defaultMessage: 'Assign Bulk Reassign Successfully!',
  },
  saveBulkReassignProductFailedMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.saveBulkReassignProductFailedMessage`,
    defaultMessage: 'Assign Bulk Reassign failed!',
  },
  downloadCategorySuccessMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.downloadCategorySuccessMessage`,
    defaultMessage: 'Download Category successfully!',
  },
  downloadCategoryFailedMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.downloadCategoryFailedMessage`,
    defaultMessage: 'Download Category failed!',
  },
  categoryLinkProductConfirmMessage: {
    id: `${scope}.components.tabs.system.productCategotyManagement.message.categoryLinkProductConfirmMessage`,
    defaultMessage:
      'The Category is linked with Product, Are you sure you want to unlink and continue to delete?',
  },
});

export default memberProfileMessage;
