import React from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const AdvanceSearchControl = () => {
  return (
    <ButtonSmallIcon
      icon={<FileSearchOutlined />}
      label={Messages.advanceSearch}
      className='button-item-dropdown'
    />
  );
};

AdvanceSearchControl.propTypes = {};

export default AdvanceSearchControl;
