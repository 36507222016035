import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { StyledModal } from 'common/components';
import CertificationItem from './CertificationItem';

const CertificationModal = ({
  certs,
  visibleModal,
  setVisibleModal,
  ...rest
}) => {
  const modalProps = {
    title: 'Product Certifications',
    visible: visibleModal,
    footer: null,
    onCancel: () => setVisibleModal(false),
    className: 'certification__modal',
    bodyStyle: {
      height: '65vh',
    },
  };

  return (
    <StyledModal {...modalProps}>
      <Row className='certification__container'>
        {certs.map((item, index) => (
          <Col span={24} key={index}>
            <CertificationItem
              certificationData={formatDataCertification(item)}
            />
          </Col>
        ))}
      </Row>
    </StyledModal>
  );
};

// Not show checkbox, just display empty
const formatDataCertification = (object) => {
  let result = {};

  if (object) {
    for (const [key, value] of Object.entries(object)) {
      result = {
        ...result,
        [key]: typeof value === 'boolean' ? '' : value,
      };
    }
  }

  return result;
};

CertificationModal.propTypes = {};

export default CertificationModal;
