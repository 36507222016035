import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as IconOpenMember } from 'common/components/button/svg-icons/ICO_10.28.svg';
import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';
import * as actionsGlobal from 'redux/global/actions';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const OpenMemberSection = () => {
  const dispatch = useDispatch();

  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );
  const selectedMemberMaintenanceDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const memberMaintenanceId =
    selectedMemberMaintenanceDetail &&
    selectedMemberMaintenanceDetail.length > 0
      ? selectedMemberMaintenanceDetail[0].id
      : undefined;

  const openMemberMaintenanceDetail = () => {
    if (memberMaintenanceId) {
      dispatch(actionsGlobal.updateMemberMaintenanceId(memberMaintenanceId));
    }
    forwardTo(`/member-management-company/${memberMaintenanceId}`);
  };

  return (
    <RibbonButton
      icon={<IconOpenMember style={{ height: 26 }} />}
      label={Messages.openMember}
      onClick={openMemberMaintenanceDetail}
      disabled={
        memberMaintenanceId === undefined ||
        selectedMemberMaintenanceDetail.length !== 1 ||
        !isMemberGridInfoView
      }
    />
  );
};

OpenMemberSection.propTypes = {};

export default OpenMemberSection;
