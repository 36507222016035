import * as types from './constants';

export const getAPLGridColumns = (gridName) => ({
  type: types.GET_PRODUCT_APL_GRID_COLUMN,
  payload: gridName,
});

export const getAPLGridColumnsSuccess = (columns) => {
  return {
    type: types.GET_PRODUCT_APL_GRID_COLUMN_SUCCESS,
    payload: columns,
  };
};

export const getAPLGridColumnsError = (error) => ({
  type: types.GET_PRODUCT_APL_GRID_COLUMN_ERROR,
  payload: error,
});

export function setSelectedDate(payload) {
  return {
    type: types.SET_APL_SCHEDULE_SELECTED_DATE,
    payload,
  };
}

export function setScheduleEvents(payload) {
  return {
    type: types.SET_APL_SCHEDULE_EVENTS,
    payload,
  };
}

export function setScheduleIsDragStart(status) {
  return {
    type: types.SET_APL_SCHEDULE_IS_DRAG_START,
    status,
  };
}

export function toggleScheduleModal(payload) {
  return {
    type: types.TOGGLE_APL_SCHEDULE_MODAL,
    payload,
  };
}

export function toggleReloadGridApl() {
  return {
    type: types.TOGGLE_RELOAD_GRID_APL,
  };
}

export function resetReloadGridApl() {
  return {
    type: types.RESET_RELOAD_GRID_APL,
  };
}
