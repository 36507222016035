import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Row } from 'antd';
import { FileSyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import { AssetUpload } from 'pages/asset-full-view/components';
import { IframeAsset } from 'pages/asset-full-view/components';

import * as homeActions from 'pages/home/ribbon/asset-full/controllers/actions';
import * as assetActions from 'pages/asset-full-view/controllers/actions';
import * as ribbonSelector from 'redux/ribbon/selectors';
import saga from 'pages/asset-full-view/controllers/saga';
import assetReducer from 'pages/asset-full-view/controllers/reducer';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as assetSelectors from 'pages/asset-full-view/controllers/selectors';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import { forwardTo } from 'utils/common/route';

import * as endpointAsset from 'services/digitalAsset/endpoints';
import * as assetServices from 'services/digitalAsset';

import {
  RIBBON_VIEW,
  ASSET_UPLOAD_TYPE,
  TYPE_VIEW_ASSET,
} from 'static/Constants';

import Messages from 'i18n/messages/home';
import { FormattedMessage } from 'react-intl';

import {
  useCheckAllowEditAssets,
  useCheckIsSuperAdmin,
  usePermissionEditSharedAsset,
} from 'hooks';

import { UPLOAD_MAX_SIZE } from 'static/Constants';

import './ReplaceAsset.less';

const ReplaceAsset = ({ isEditting, disabled }) => {
  useInjectReducer({ key: 'digitalAsset', reducer: assetReducer });
  useInjectSaga({ key: 'digitalAsset', saga });
  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  const [visibleModel, setVisibleModel] = useState(false);
  const [uploadType, setUploadType] = useState(
    ASSET_UPLOAD_TYPE.UPLOAD_LOCAL_FILE.name
  );
  const [idSelectionDropbox, setIdSelectionDropbox] = useState(null);
  const assetData = useSelector(assetSelectors.assetDataLoading());
  const itemsSelection = useSelector(gridSelector.makeSelectItemsSelection());

  const itemCurrentSelection = useSelector(
    gridSelector.makeSelectItemCurrentSelection()
  );
  const view = useSelector(ribbonSelector.selectRibbon());

  const { checkAllowEditAssetFull } = useCheckAllowEditAssets();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const hasPermissionEditSharedAsset = usePermissionEditSharedAsset();

  const isAllowEditFull = checkAllowEditAssetFull();

  const confirmReplaceAsset = () => {
    setVisibleModel(true);
  };
  const handleModalCancel = () => {
    setVisibleModel(false);
  };
  const replaceAsset = (result) => {
    const response = result?.response;

    if (!response) return;

    if (response?.isSuccess) {
      setVisibleModel(false);
      const metaData = result.response.data;
      // const pendingAssetId = metaData?.pendingAssetId;
      const id = metaData.id;
      dispatch(assetActions.cancelUpdateSuccess());
      dispatch(assetActions.createDigitalAssetFullSuccess(metaData));
      dispatch(homeActions.editAsset());
      dispatch(homeActions.creatingAsset());

      if (pathname === '/favorite/favorite-assets') {
        forwardTo(`/asset/${id}?replace-asset&isFavorited=true`);
      } else {
        forwardTo(`/asset/${id}?replace-asset`);
      }

      /* 
        Delete draft status

        if (pathname === '/favorite/favorite-assets') {
          forwardTo(`/asset/${id}?replace-asset&draft=true&isFavorited=true`);
        } else {
          forwardTo(`/asset/${id}?replace-asset&draft=true`);
        }
      */
    }
  };
  const replaceAssetFtp = (result) => {
    if (result && result?.data) {
      setVisibleModel(false);
      const metaData = result.data;
      const id = metaData.id;
      dispatch(assetActions.cancelUpdateSuccess());
      dispatch(assetActions.createDigitalAssetFullSuccess(metaData));
      dispatch(homeActions.editAsset());
      dispatch(homeActions.creatingAsset());

      if (pathname === '/favorite/favorite-assets') {
        forwardTo(`/asset/${id}?replace-asset&isFavorited=true`);
      } else {
        forwardTo(`/asset/${id}?replace-asset`);
      }

      /* 
        Delete draft status

        if (pathname === '/favorite/favorite-assets') {
          forwardTo(`/asset/${id}?replace-asset&draft=true&isFavorited=true`);
        } else {
          forwardTo(`/asset/${id}?replace-asset&draft=true`);
        }
      */
    }
    return;
  };

  const replaceIframe = (data) => {
    if (data) {
      setVisibleModel(false);
    }
    return;
  };

  const replaceFromFtp = (selectedFile) => {
    const params = {
      id: itemCurrentSelection?.id || assetData?.id,
      ftpPath: get(selectedFile, '0.interopPath'),
    };

    return assetServices.createReplaceAsset(params);
  };

  const isAllowBtn = checkAllowReplaceAsset(
    isEditting,
    disabled,
    itemsSelection,
    itemCurrentSelection,
    isAllowEditFull,
    isSuperAdmin,
    view,
    hasPermissionEditSharedAsset
  );

  return (
    <>
      <ButtonSmallIcon
        className='button-item-dropdown'
        icon={<FileSyncOutlined />}
        label={Messages.replaceAsset}
        onClick={confirmReplaceAsset}
        disabled={!isAllowBtn}
      />
      <Modal
        className='replace-assets__modal'
        width={'80vw'}
        bodyStyle={{ height: '80vh' }}
        title={
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <ExclamationCircleOutlined
              style={{ color: '#faad14', marginRight: 16, fontSize: 24 }}
            />
            <FormattedMessage {...Messages.preplaceAssetTitle} />
          </Row>
        }
        visible={visibleModel}
        closable={true}
        maskClosable={false}
        onCancel={handleModalCancel}
        footer={false}
        destroyOnClose
      >
        <Row style={{ height: '100%' }}>
          {itemCurrentSelection?.isIFrame || assetData?.isIFrame ? (
            <IframeAsset
              onSuccess={replaceIframe}
              assetData={assetData || itemCurrentSelection}
              typeViewAsset={TYPE_VIEW_ASSET.REPLACE_ASSET}
            />
          ) : (
            <AssetUpload
              isBase64={false}
              showMultiple={false}
              apiUrl={
                endpointAsset.REPLACE_DIGITAL_ASSET +
                `/${itemCurrentSelection?.id || assetData?.id}`
              }
              ftpApiUrl={
                endpointAsset.REPLACE_DIGITAL_ASSET +
                `/${itemCurrentSelection?.id || assetData?.id}`
              }
              customRequestFtp={replaceFromFtp}
              getLocalFileResponse={replaceAsset}
              getFtpFileResponse={replaceAssetFtp}
              maxSize={UPLOAD_MAX_SIZE.GENERAL}
              showServerMessage
              uploadType={uploadType}
              setUploadType={setUploadType}
              handleCancel={handleModalCancel}
              idSelectionDropbox={idSelectionDropbox}
              setIdSelectionDropbox={setIdSelectionDropbox}
              typeViewAsset={TYPE_VIEW_ASSET.REPLACE_ASSET}
            />
          )}
        </Row>
      </Modal>
    </>
  );
};

const checkAllowReplaceAsset = (
  isEditting,
  disabled,
  itemsSelection,
  itemCurrentSelection,
  isAllowEditFull,
  isSuperAdmin,
  view,
  hasPermissionEditSharedAsset
) => {
  if (!isEditting && !disabled) {
    if (view === RIBBON_VIEW.ASSET_FULL_VIEW.NAME) {
      return isAllowEditFull;
    } else {
      if (itemsSelection?.length === 1) {
        return (
          isSuperAdmin ||
          hasPermissionEditSharedAsset ||
          itemCurrentSelection?.isOwner
        );
      }
    }
  }

  return false;
};

export default ReplaceAsset;
