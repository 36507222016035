import React from 'react';
import PropTypes from 'prop-types';

import { Space, Typography } from 'antd';
import './HistorySection.less';
const { Text } = Typography;

const HistorySection = (props) => {
  const { title, children, className } = props;
  return (
    <Space
      className={`${className} history-section`}
      style={{ width: '100%' }}
      direction='vertical'
    >
      {title && (
        <Text strong underline className='history-section__title' level={5}>
          {title}
        </Text>
      )}
      {children}
    </Space>
  );
};
HistorySection.propTypes = {
  /**
   * className of section
   */
  className: PropTypes.string,
  /**
   * className prefix/Block
   */
  clp: PropTypes.string,
  /**
   * Section Title
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default HistorySection;
