import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Select, Tree, Button, Space, Input, Empty } from 'antd';
import { Form, WrapperSelect, WithLoading } from 'common/components';

import * as globalSelectors from 'redux/global/selectors';
import debounce from 'lodash.debounce';

import { useGetFilterBrickModules } from 'common/components/product-add/hooks';
import { smoothScrollInElement } from 'utils/element';
import { sleep } from 'utils/delay';

const ProductBrickCodeHierarchy = ({
  visible,
  getBrickCode,
  initialBrickCode,
}) => {
  const productBrickCodeModulesOrigin = useSelector(
    globalSelectors.selectBrickCodeModules()
  );

  // Segments -> Family -> Class -> Brick
  const [segments, setSegments] = useState(null);
  const [family, setFamily] = useState([]);
  const [classBrick, setClassBrick] = useState([]);
  const [brick, setBrick] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [hightLightKey, setHightLightKey] = useState([]);
  const [expandLoading, setExpandLoading] = useState(false);
  const refTreeWrap = useRef();

  const initSelectTree = () => {
    handleSelectTree(brick?.BrickCode || initialBrickCode);
  };

  const handleScrollTreeToTop = () => {
    smoothScrollInElement(refTreeWrap.current).scrollTo(() => 0, 400, 500);
  };

  const {
    handleSearch,
    filteredBrickModules: productBrickCodeModules,
    searchLoading,
  } = useGetFilterBrickModules({
    productBrickCodeModulesOrigin,
    setExpandedKeys,
    initSelectTree,
    handleScrollTreeToTop,
  });

  useEffect(() => {
    if (initialBrickCode && visible) {
      handleSelectTree(initialBrickCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleSelectTree = useCallback((expandedKeys) => {
    const treeModal = { children: editTreeView };

    const pathValues = getPath(treeModal, expandedKeys);

    if (pathValues) {
      const [, segmentKey, familyKey, classesKey, brickKey] = pathValues;

      const segmentModule = productBrickCodeModulesOrigin?.find(
        (segment) => segment?.SegmentCode === segmentKey?.key
      );
      setSegments(segmentModule);
      const familyModule = segmentModule?.Families?.find(
        (family) => family?.FamilyCode === familyKey?.key
      );
      setFamily(familyModule);
      const classModule = familyModule?.Classes?.find(
        (family) => family?.ClassCode === classesKey?.key
      );
      setClassBrick(classModule);
      const brick = classModule?.Bricks?.find(
        (brick) => brick?.BrickCode === brickKey?.key
      );
      setBrick(brick);
      handleExpand([
        segmentKey?.key,
        familyKey?.key,
        classesKey?.key,
        brickKey?.key,
      ]);
      setHightLightKey([brickKey?.key]);
      getBrickCode(brick);
    }
  }, []);

  const TitleRender = ({ type, code, description }) => {
    const isCodeInDes = description?.includes('brick-code_search-text');

    return (
      <div style={{ display: 'inline-block' }}>
        <span>{type}:</span>

        {!isCodeInDes && (
          <>
            <div
              style={{ display: 'inline-block' }}
              dangerouslySetInnerHTML={{ __html: code }}
            />
            -
          </>
        )}
        <div
          style={{ display: 'inline-block' }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    );
  };

  const editTreeView = useMemo(() => {
    const mappingTreeView = productBrickCodeModules
      ?.filter((segmentItem) => segmentItem?.SegmentCode)
      .map((segment) => {
        return {
          title: (
            <TitleRender
              type='Segment'
              code={segment?.SegmentCode}
              description={segment?.SegmentDescription}
            />
          ),
          key: segment?.SegmentCode,
          children: segment?.Families?.filter(
            (familyItem) => familyItem?.FamilyCode
          ).map((family) => ({
            title: (
              <TitleRender
                type='Family'
                code={family?.FamilyCode}
                description={family?.FamilyDescription}
              />
            ),
            key: family?.FamilyCode,
            children: family?.Classes?.filter(
              (classItem) => classItem?.ClassCode
            ).map((classes) => ({
              title: (
                <TitleRender
                  type='Class'
                  code={classes?.ClassCode}
                  description={classes?.ClassDescription}
                />
              ),
              key: classes?.ClassCode,
              children: classes?.Bricks?.filter(
                (brickItem) => brickItem?.BrickCode
              ).map((brick) => ({
                title: (
                  <TitleRender
                    type='Brick'
                    code={brick?.BrickCode}
                    description={brick?.BrickDescription}
                  />
                ),
                key: brick?.BrickCode,
              })),
            })),
          })),
        };
      });
    return mappingTreeView;
  }, [productBrickCodeModules]);

  function getPath(model, key) {
    let path,
      item = { key: model.key };

    if (!model || typeof model !== 'object') return;

    if (model.key === key) return [item];
    (model.children || []).some((child) => (path = getPath(child, key)));

    return path && [item, ...path];
  }

  const { Option } = Select;

  const handleSegment = (code) => {
    const segmentModule = productBrickCodeModulesOrigin?.find(
      (segments) => segments?.SegmentCode === code
    );
    setSegments(segmentModule);
    setFamily([]);
    setClassBrick([]);
    setBrick([]);
    handleExpand([code]);
    getBrickCode(null);
  };

  const handleFamily = (code) => {
    const familyModule = segments?.Families?.find(
      (family) => family?.FamilyCode === code
    );
    setFamily(familyModule);
    handleExpand([...expandedKeys, code]);
    setClassBrick([]);
    setBrick([]);
    setHightLightKey([]);
    getBrickCode(null);
  };

  const handleClass = (code) => {
    const classModule = family?.Classes?.find(
      (family) => family?.ClassCode === code
    );
    setClassBrick(classModule);
    handleExpand([...expandedKeys, code]);
    setBrick([]);
    setHightLightKey([]);
    getBrickCode(null);
  };

  const handleBrick = (code) => {
    const brick = classBrick?.Bricks?.find(
      (brick) => brick?.BrickCode === code
    );
    setBrick(brick);
    setHightLightKey([code]);
    getBrickCode(brick);
  };

  const handleExpand = (expandedKeys, info) => {
    setExpandedKeys(expandedKeys);
    setIsExpand(false);
  };

  const getAllKeyExpand = (tree) => {
    const keyList = [];
    for (let i = 0; i < tree.length; i++) {
      if (tree[i]?.children) {
        keyList.push(tree[i].key);
        keyList.push(...getAllKeyExpand(tree[i]?.children));
      }
    }
    return keyList;
  };

  const handleExpandAll = async () => {
    setExpandLoading(true);
    const newKeys = getAllKeyExpand(editTreeView);
    await sleep(100);
    setExpandedKeys(newKeys);
    setExpandLoading(false);
  };

  const handleCollapseAll = async () => {
    setExpandLoading(true);
    await sleep(100);
    setExpandedKeys([]);

    setExpandLoading(false);
  };

  const handleSelect = (expandedKeys) => {
    handleSelectTree(expandedKeys?.[0]);
  };
  const handleDebounceSelect = debounce(handleSelect, 300);

  const handleFilter = (input, option) => {
    return (
      option?.props?.children
        ?.split('-')[1]
        ?.toLowerCase()
        ?.indexOf(input.toLowerCase()) >= 0 ||
      option?.props?.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <Row gutter={[10, 10]} style={{ height: '100%' }}>
      <Col span={12}>
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          layout='horizontal'
        >
          <Form.Item label='Segments'>
            <WrapperSelect
              allowClear
              showSearch
              onChange={handleSegment}
              style={{ width: '100%' }}
              value={segments?.SegmentCode}
              filterOption={(input, option) => handleFilter(input, option)}
              placeholder='Please select segment'
            >
              {productBrickCodeModulesOrigin?.map((segments) => (
                <Option
                  key={segments?.SegmentCode}
                  value={segments?.SegmentCode}
                >
                  {`${segments?.SegmentCode} - ${segments?.SegmentDescription}`}
                </Option>
              ))}
            </WrapperSelect>{' '}
          </Form.Item>
          <Form.Item label='Family'>
            <WrapperSelect
              allowClear
              showSearch
              onChange={handleFamily}
              style={{ width: '100%' }}
              value={family?.FamilyCode}
              filterOption={(input, option) => handleFilter(input, option)}
              placeholder=' Please select family'
            >
              {segments?.Families?.map((family) => (
                <Option key={family?.FamilyCode} value={family?.FamilyCode}>
                  {`${family?.FamilyCode} - ${family?.FamilyDescription}`}
                </Option>
              ))}
            </WrapperSelect>{' '}
          </Form.Item>
          <Form.Item label='Class'>
            <WrapperSelect
              allowClear
              showSearch
              onChange={handleClass}
              style={{ width: '100%' }}
              value={classBrick?.ClassCode}
              filterOption={(input, option) => handleFilter(input, option)}
              placeholder='Please select class'
            >
              {family?.Classes?.map((classes) => (
                <Option key={classes?.ClassCode} value={classes?.ClassCode}>
                  {`${classes?.ClassCode} - ${classes?.ClassDescription}`}
                </Option>
              ))}
            </WrapperSelect>
          </Form.Item>
          <Form.Item label='Brick'>
            <WrapperSelect
              allowClear
              showSearch
              onChange={handleBrick}
              style={{ width: '100%' }}
              value={brick?.BrickCode}
              filterOption={(input, option) => handleFilter(input, option)}
              placeholder='Please select brick code'
            >
              {classBrick?.Bricks?.map((brick) => (
                <Option key={brick?.BrickCode} value={brick?.BrickCode}>
                  {`${brick?.BrickCode} - ${brick?.BrickDescription}`}
                </Option>
              ))}
            </WrapperSelect>{' '}
          </Form.Item>
        </Form>
      </Col>
      <Col
        span={12}
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <>
          <Row style={{ marginBottom: 5 }}>
            <Col xs={24}>
              <Input.Search
                onSearch={(value) => handleSearch(value)}
                allowClear
                placeholder='Input search text'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Space>
                <Button type='primary' onClick={handleExpandAll}>
                  Expand All
                </Button>
                <Button type='primary' onClick={handleCollapseAll}>
                  Collapse All
                </Button>
              </Space>
            </Col>
          </Row>
          <Row style={{ flex: 1, overflow: 'hidden' }}>
            <WithLoading loading={searchLoading || expandLoading}>
              <Col
                ref={refTreeWrap}
                xs={24}
                className='scroller'
                style={{ height: '100%' }}
              >
                {editTreeView.length === 0 ? (
                  <Empty />
                ) : (
                  <Tree
                    showLine
                    showIcon
                    treeData={editTreeView}
                    autoExpandParent={isExpand}
                    selectedKeys={hightLightKey}
                    expandedKeys={expandedKeys}
                    onSelect={handleDebounceSelect}
                    onExpand={handleExpand}
                  />
                )}
              </Col>
            </WithLoading>
          </Row>
        </>
      </Col>
    </Row>
  );
};

export default React.memo(ProductBrickCodeHierarchy);
