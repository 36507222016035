import { defineMessages } from 'react-intl';

export const scope = 'Taco.payment';

export default defineMessages({
  creditCardChargeWarningTitle: {
    id: `${scope}.creditCard.chargeWarning.title`,
    defaultMessage: 'New Charge Warning',
  },
  creditCardChargeWarningMessage: {
    id: `${scope}.creditCard.chargeWarning.message`,
    defaultMessage:
      'This is billable action. If you select "Continue", the action will complete and a charge will be added to your account balance. Would you like to continue?',
  },
  creditCardChargeWarningContinueBtn: {
    id: `${scope}.creditCard.chargeWarning.btn.continue`,
    defaultMessage: 'Continue',
  },
  creditCardChargeWarningCancelBtn: {
    id: `${scope}.creditCard.chargeWarning.btn.cancel`,
    defaultMessage: 'Cancel',
  },
  creditCardChargeWarningNotShowAgain: {
    id: `${scope}.creditCard.chargeWarning.label.notShowAgain`,
    defaultMessage: "Don't show this pop-up again",
  },
});
