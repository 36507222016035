import React, { useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import { FileAddFilled } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import FavoriteControl from './FavoriteControl';
import UnfavoriteControl from './UnfavoriteControl';
import AddFolder from './AddFolder';
import AddFolderToNewAPL from './AddFolderToNewAPL';
import AddProductToNewApl from './AddProductToNewApl';
import AddSearchProductToNewApl from './AddSearchProductToNewApl';
import RibbonButton from 'common/components/button/RibbonButton';
import userSelector from 'redux/user/selectors';
import { useSelector } from 'react-redux';
import * as selectors from 'pages/company-profile/controllers/selectors';

import { Can } from 'context/Can';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { CanShow } from 'common/components/wrapper/show-condition';

import './Addition.less';

const Addition = ({
  type,
  selectedItemList,
  disabledFavorite,
  isDisabled,
  disabledFilterSearch,
  searchCategoriesEffected,
  searchText,
  filterProducts,
  queryConditions,
  isFavoriteView,
}) => {
  let userInfo = useSelector(userSelector.makeSelectUserInfo());
  const memberInfo = useSelector(selectors.selectMemberProfile());

  const checkAllFavoriteItem = () => {
    // return true if all item is favorite
    return !selectedItemList?.some((item) => !item?.isFavorited);
  };

  const checkAllNotFavoriteItem = () => {
    // return true if all item is not favorite
    return !selectedItemList?.some((item) => item?.isFavorited);
  };

  const shouldShowRemoveFavorite =
    (!selectedItemList && memberInfo?.isFavorited) ||
    (selectedItemList?.length > 0 && checkAllFavoriteItem());

  const isAllItemInTheSameType = selectedItemList?.every(
    (item) => item?.type?.toLowerCase() === type?.toLowerCase()
  );

  const shouldDisabledAddToFavorite =
    (!selectedItemList && memberInfo?.isFavorited) ||
    selectedItemList?.length === 0 ||
    !checkAllNotFavoriteItem() ||
    disabledFavorite ||
    !isAllItemInTheSameType;

  const viewPermission = useMemo(() => getPermissionAddToFav(type), [type]);

  const hasPermissionAddToFavorite = useCheckPermissions(viewPermission);

  const menuOptions = (
    <Menu>
      {hasPermissionAddToFavorite && (
        <Menu.Item key='favorite' style={{ backgroundColor: 'inherit' }}>
          <FavoriteControl
            type={type}
            userId={userInfo?.id}
            selectedItemList={selectedItemList}
            isDisabled={shouldDisabledAddToFavorite}
          />
        </Menu.Item>
      )}

      {hasPermissionAddToFavorite && shouldShowRemoveFavorite && (
        <Menu.Item key='unfavorite' style={{ backgroundColor: 'inherit' }}>
          <UnfavoriteControl
            type={type}
            selectedItemList={selectedItemList}
            userId={userInfo?.id}
            isFavoriteView={isFavoriteView}
          />
        </Menu.Item>
      )}
      <Menu.Item key='addToFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.FOLDER}>
          <AddFolder selectedItemList={selectedItemList} />
        </Can>
      </Menu.Item>
      {type === 'folder' && (
        <Menu.Item key='addToNewAPL' style={{ backgroundColor: 'inherit' }}>
          <AddFolderToNewAPL />
        </Menu.Item>
      )}
      {type === 'product' && (
        <CanShow.SharedOnlyMember>
          <Menu.Item key='addToApl' style={{ backgroundColor: 'inherit' }}>
            <AddProductToNewApl selectedItemList={selectedItemList} />
          </Menu.Item>
        </CanShow.SharedOnlyMember>
      )}
      {type === 'product' && (
        <CanShow.SharedOnlyMember>
          <Menu.Item
            key='addSearchProductToNewApl'
            style={{ backgroundColor: 'inherit' }}
          >
            <AddSearchProductToNewApl
              disabledFilterSearch={disabledFilterSearch}
              searchCategoriesEffected={searchCategoriesEffected}
              searchText={searchText}
              filterProducts={filterProducts}
              queryConditions={queryConditions}
            />
          </Menu.Item>
        </CanShow.SharedOnlyMember>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menuOptions}
      placement='bottomLeft'
      disabled={
        window.location.pathname === '/member-management-company' || isDisabled
      }
      arrow
    >
      <RibbonButton
        icon={<FileAddFilled />}
        disabled={
          window.location.pathname === '/member-management-company' ||
          isDisabled
        }
        label={Messages.addTo}
      />
    </Dropdown>
  );
};

const getPermissionAddToFav = (type) => {
  if (type === 'product') {
    return [
      {
        action: ABILITY_ACTION.VIEW,
        subject: ABILITY_SUBJECT.PRODUCT,
      },
    ];
  }

  if (type === 'member') {
    return [
      {
        action: ABILITY_ACTION.VIEW,
        subject: ABILITY_SUBJECT.MEMBER,
      },
    ];
  }

  if (type === 'asset') {
    return [
      {
        action: ABILITY_ACTION.VIEW,
        subject: ABILITY_SUBJECT.ASSET,
      },
    ];
  }

  if (type === 'folder') {
    return [
      {
        action: ABILITY_ACTION.VIEW,
        subject: ABILITY_SUBJECT.FOLDER,
      },
    ];
  }

  if (type === 'reporting') {
    return [
      {
        action: ABILITY_ACTION.VIEW,
        subject: ABILITY_SUBJECT.REPORTING,
      },
    ];
  }

  return [];
};

export default Addition;
