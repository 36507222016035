import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import userSelectors from 'redux/user/selectors';

import { useGetQaSpecDataHeader } from 'pages/qa-spec/query';
import { useGetProductFullView } from 'hooks';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';

import { checkIsDollarProduct } from 'pages/qa-spec/views/qa-spec-detail/utils';

import * as qasSpecServices from 'services/qas-require-field';
export const requiredFieldsMap = {
  'eCommerce.smartLabelTemplate': 'qaSpecification.smartLabelTemplate',
  //* product spec tab
  targetMarket: 'qaSpecification.qaSpecProductSpecification.targetMarket',
  'placeOfItemActivity.placeOfProductActivity.countryOfOrigin.countryCode':
    'qaSpecification.qaSpecProductSpecification.countryOfOrigin',
  'tdcProperties.codeDateExample':
    'qaSpecification.qaSpecProductSpecification.codeDateExample',
  'tdcProperties.unit_MinimumShelfLife':
    'qaSpecification.qaSpecProductSpecification.minimumShelfLife',
  'tdcProperties.case_TemperatureRangeHigh':
    'qaSpecification.qaSpecProductSpecification.caseTemperatureRangeHigh',
  'tdcProperties.case_TemperatureRangeLow':
    'qaSpecification.qaSpecProductSpecification.caseTemperatureRangeLow',
  'tdcProperties.case_ShippingTemperatureRangeHigh':
    'qaSpecification.qaSpecProductSpecification.caseShippingTemperatureHigh',
  'tdcProperties.case_ShippingTemperatureRangeLow':
    'qaSpecification.qaSpecProductSpecification.caseShippingTemperatureLow',
  //* labeling tab
  'consumerInstructions.consumerInstructions.consumerUsageInstructions.value':
    'qaSpecification.labeling.usage',
  //* ingredient tab
  'foodAndBeverageIngredient.ingredientStatement.value':
    'qaSpecification.qaSpecIngredients.ingredientStatement',
};

export const getGetQaSpecRequiredFieldsKeys = (params, userId) => {
  return ['get-qa-spec-required-fields', params, userId];
};

export const useGetQaSpecRequiredFields = (props) => {
  const { params, fieldPath, ...options } = props;

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const isDollarProduct = checkIsDollarProduct(params?.ownerId);

  const getGetQaSpecRequiredFields = async ({ queryKey }) => {
    const params = queryKey[1];

    const response = await qasSpecServices.getQaSpecRequiredFieldForProduct(
      params
    );

    if (!response.isSuccess || !response.data.requiredFields) return;

    //* map data
    const requiredFields = response.data.requiredFields
      .filter((field) => {
        //* filter out product contain field when product does not belong to dollar general

        //* if product  belongs to dollar general, accept all fields
        if (isDollarProduct) return true;

        //* if not, accept fields that is not product contain fields
        const isProductContainField = field.includes(
          'qaSpecification.qaSpecProductContains'
        );

        return !isProductContainField;
      })
      .map((field) => {
        //* some fields  in Product Spec tab have difference fieldName with required fields, format them to standard field full path for logic flow
        const formattedFields = requiredFieldsMap[field];

        let finalField = formattedFields ? formattedFields : field;

        return finalField;
      });

    return requiredFields;
  };

  return useQuery({
    queryKey: getGetQaSpecRequiredFieldsKeys(params, { userId: userInfo?.id }),
    queryFn: getGetQaSpecRequiredFields,
    ...options,
  });
};

export const useGetQaSpecRequiredFieldsInQaSpecFullView = (props) => {
  const { productId } = props;

  const { productFull } = useGetProductFullView({
    productId,
  });

  const { qaSpecDataHeader, defaultType } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: !!productId,
  });

  const productType = qaSpecDataHeader?.type || defaultType;

  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  return useGetQaSpecRequiredFields({
    params: {
      ownerId: productFull?.ownerId,
      productType,
    },
    enabled:
      !!isProductAssignedByRetailer &&
      !!productFull &&
      !!productType &&
      !!productFull?.ownerId,
  });
};
