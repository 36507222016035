import React from 'react';
import { useIntl } from 'react-intl';

import { SUPPLIER_PERMISSION_LIST } from 'static/Constants';
import Messages from 'i18n/messages/supplier-manager';
import { PermissionList } from 'common/components/list';

const SupplierPermission = ({
  supplierPermissions,
  handleUpdateSupplierPermission,
}) => {
  const intl = useIntl();

  const transformedPermissionList = SUPPLIER_PERMISSION_LIST.map(
    (PERMISSION_GROUP_ITEM) => {
      return {
        ...PERMISSION_GROUP_ITEM,
        groupName: intl.formatMessage(
          Messages.permissionGroupName[PERMISSION_GROUP_ITEM.groupName]
        ),
        permissions: PERMISSION_GROUP_ITEM.permissions.map(
          (PERMISSION_ITEM) => {
            return {
              ...PERMISSION_ITEM,
              displayName: intl.formatMessage(
                Messages.permissionGroupItem[PERMISSION_ITEM.displayName]
              ),
            };
          }
        ),
      };
    }
  );

  return (
    <div>
      <PermissionList
        permissionList={transformedPermissionList}
        initialSelectedPermission={supplierPermissions}
        onChange={handleUpdateSupplierPermission}
      />
    </div>
  );
};

export default SupplierPermission;
