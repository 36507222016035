import React, { useMemo } from 'react';
import { Row, Col, Divider } from 'antd';

import { useSelector } from 'react-redux';

import ContactCardMessagesInfo from './ContactCardMessageInfo';
import ContactCardMessageActions from './ContactCardMessageActions';

import { useSendMessageOrEmail } from 'hooks/useSendMessageOrEmail';

import { detectObjectStrContactCard } from 'utils/string';

import userSelectors from 'redux/user/selectors';

import './ContactCardMessage.less';

const ContactCardMessages = ({
  userInfo,
  isInChatThread = false,
  handleSendMessageButton,
}) => {
  const { sendEmail, sendMessage } = useSendMessageOrEmail();

  const userId = useSelector(userSelectors.makeSelectUserId());

  const contactCardId = useMemo(() => {
    return getContactCardId(userInfo);
  }, [userInfo]);

  const sameUserId = userId === contactCardId;

  const renderContactCardMemberInfo = (user, isInChatThread) => {
    if (isInChatThread) {
      let userInfo = JSON.parse(user);

      return (
        <ContactCardMessagesInfo
          fullName={userInfo?.fullName}
          email={userInfo?.email}
          phone={userInfo?.phone}
          showLocation={!isInChatThread}
          isInChatThread={isInChatThread}
          avatar={userInfo?.avatar}
        />
      );
    }

    // const primaryAddress = user?.primaryAddress;
    // const primaryContact = user?.primaryContact;

    // const address = primaryAddress?.primaryAddressMainAddress
    //   ? primaryAddress?.primaryAddressMainAddress
    //   : '';
    // const cityString = primaryAddress?.primaryAddressCity
    //   ? `, ${primaryAddress?.primaryAddressCity}`
    //   : '';
    // const stateString = primaryAddress?.primaryAddressState
    //   ? `, ${primaryAddress?.primaryAddressState}`
    //   : '';
    // const addressCountry = primaryAddress?.primaryAddressCountry
    //   ? `${primaryAddress?.primaryAddressState}`
    //   : '';

    // const addressString = `${address}${cityString}${stateString}${addressCountry}`;

    return (
      <ContactCardMessagesInfo
        fullName={userInfo?.fullName}
        location={`${userInfo?.address}, ${userInfo?.city}, ${userInfo?.state}, ${userInfo?.country}`}
        email={userInfo?.email}
        phone={userInfo?.phoneNumber}
        showLocation={!isInChatThread}
        isInChatThread={isInChatThread}
        avatar={userInfo?.image}
      />
    );
  };

  const handleSendMessage = () => {
    if (detectObjectStrContactCard(userInfo)) {
      const { userId: contactCardId } = JSON.parse(userInfo);

      if (!contactCardId) return;

      // Don't allow send message yourself
      if (userId === contactCardId) return;

      sendMessage(contactCardId);
    }
    return;
  };

  const handleSendEmail = () => {
    if (detectObjectStrContactCard(userInfo)) {
      const { userId: contactCardId, email } = JSON.parse(userInfo);

      if (!contactCardId) return;

      // Don't allow send email yourself
      if (userId === contactCardId) return;

      sendEmail(contactCardId, email);
    }
    return;
  };

  return (
    <div
      className={`${
        isInChatThread
          ? 'contact-card-messages-chat-thread'
          : 'contact-card-messages'
      }`}
    >
      {renderContactCardMemberInfo(userInfo, isInChatThread)}

      {sameUserId ? null : (
        <Row>
          <Divider className='contact-card-messages__divider' />
          <Col
            span={12}
            style={{
              marginBottom: isInChatThread ? 0 : '5px',
            }}
          >
            <ContactCardMessageActions
              text='SEND MESSAGE'
              handleClick={() => {
                if (typeof handleSendMessageButton === 'function') {
                  handleSendMessageButton(null, true);
                } else {
                  handleSendMessage();
                }
              }}
            />
          </Col>

          <Col
            span={12}
            style={{
              borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
              marginBottom: isInChatThread ? 0 : '5px',
            }}
          >
            <ContactCardMessageActions
              text='SEND EMAIL'
              handleClick={() => {
                if (typeof handleSendMessageButton === 'function') {
                  handleSendMessageButton('email', true);
                } else {
                  handleSendEmail();
                }
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

const getContactCardId = (message) => {
  if (detectObjectStrContactCard(message)) {
    const { userId: contactCardId } = JSON.parse(message);

    return contactCardId;
  }
  return null;
};

export default ContactCardMessages;
