import produce from 'immer';

import { uploadChatFile } from 'services/chatServices';
import { uploadEmailAttachment } from 'services/email';

const findAccount = (groupUsers, userId, memberId) => {
  let accountUser = null;
  let accountCompany = null;

  if (groupUsers?.length > 0) {
    for (let user of groupUsers) {
      if (user.userId === `U-${userId}`) {
        accountUser = user;
      }

      if (user.userId === `C-${memberId}`) {
        accountCompany = user;
      }
    }
  }

  return {
    accountUser,
    accountCompany,
  };
};

const getAccountInGroup = (groupUsers, userId, memberId) => {
  const { accountUser, accountCompany } = findAccount(
    groupUsers,
    userId,
    memberId
  );

  if (accountUser && accountCompany) {
    return {
      type: 'Both Accounts',
      accountUser,
      accountCompany,
    };
  }

  if (accountUser) {
    return {
      type: 'Only User',
      accountUser,
    };
  }

  if (accountCompany) {
    return {
      type: 'Only Company',
      accountCompany,
    };
  }

  return null;
};

const regexEmptyString = /^(?:&nbsp;)+$/;

const checkEmptyString = (text) => {
  const formatString = text.replace(/\s/g, '');
  const condition = regexEmptyString.test(formatString);

  return condition ? true : false;
};

const getFormData = (threadId, file, isEmail) => {
  const formData = new FormData();

  if (threadId) {
    formData.append('threadId', threadId);
  }

  if (file) {
    let name = isEmail ? 'attachment' : 'file';
    formData.append(name, file.originFileObj);
  }

  return formData;
};

const getPromisesChatFiles = (threadId, files, isEmail) => {
  let promises = [];

  if (isEmail) {
    files.forEach((file) => {
      const formData = getFormData(threadId, file, isEmail);

      promises.push(uploadEmailAttachment(formData));
    });
  } else {
    files.forEach((file) => {
      const formData = getFormData(threadId, file);

      promises.push(uploadChatFile(formData));
    });
  }

  return promises;
};

const getResponseChatFiles = async (threadId, files, isEmail) => {
  const promises = getPromisesChatFiles(threadId, files, isEmail);

  return Promise.allSettled(promises);
};

const getNewFiles = (attachList, index, status) => {
  const newAttachList = produce(attachList, (draftFile) => {
    draftFile[index].status = status;
  });

  return newAttachList;
};

const updateStatusFile = ({ attachList, index, status }) => {
  return getNewFiles(attachList, index, status);
};

const formatAttachmentFromResponse = (response, attachment) => {
  let attachmentIds = [];
  let cacheAttachment = [];
  let newAttachment = [];

  for (const [index, item] of response.entries()) {
    if (item?.value?.isSuccess) {
      attachmentIds.push(item.value.data.fileId);

      cacheAttachment.push({
        fileId: item.value.data.fileId,
        uid: attachment[index].uid,
      });

      newAttachment.push({ ...attachment[index], status: 'success' });
    } else {
      newAttachment.push({ ...attachment[index], status: 'error' });
    }
  }

  return {
    attachmentIds,
    cacheAttachment,
    newAttachment,
  };
};

const getNewStateAttachment = (cacheAttachment, fileUid) => {
  let newAttachmentIds = [];
  let newCacheAttachment = [];

  cacheAttachment.forEach((cache) => {
    if (cache.uid !== fileUid) {
      newCacheAttachment.push(cache);
      newAttachmentIds.push(cache.fileId);
    }
  });

  return {
    newAttachmentIds,
    newCacheAttachment,
  };
};

const getUniqueAttachment = (array1, array2) => {
  let result = array1.filter((item1) => {
    const foundItem = array2.find((item2) => item2.uid === item1.uid);
    return foundItem ? false : true;
  });

  return result;
};
const getUniqueFileList = (array1, array2) => {
  let result = array1.filter((item1) => {
    const foundItem = array2.find((item2) => item2.uid === item1.uid);
    return foundItem ? true : false;
  });

  return result;
};

const getDuplicateFileList = (array1, array2) => {
  let result = [];

  array1.forEach((item1) => {
    if (array2.findIndex((item2) => item2.uid === item1.uid) !== -1) {
      result.push(item1);
    }
  });

  return result;
};

const ONE_MB_TO_BYTES = Math.pow(10, 6);

const checkInvalidFile = (files) => {
  if (Array.isArray(files)) {
    return files.some((file) => file.size > 10 * ONE_MB_TO_BYTES);
  }
  return false;
};

const checkNotAllowSendFiles = (files) => {
  if (Array.isArray(files) && files.length > 0) {
    return files.some((file) => file.status !== 'success');
  }
  return false;
};

const formatAttachmentAfterAdd = (listAttachment, newAttachment) => {
  let result = [];

  listAttachment.forEach((item) => {
    const foundItem = newAttachment.find((newItem) => newItem.uid === item.uid);
    if (foundItem) {
      result.push(foundItem);
    } else {
      result.push(item);
    }
  });

  return result;
};

export {
  getAccountInGroup,
  findAccount,
  checkEmptyString,
  getFormData,
  getPromisesChatFiles,
  updateStatusFile,
  checkInvalidFile,
  getUniqueAttachment,
  getUniqueFileList,
  getDuplicateFileList,
  getNewStateAttachment,
  getResponseChatFiles,
  formatAttachmentFromResponse,
  formatAttachmentAfterAdd,
  checkNotAllowSendFiles,
};
