import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Row, Col, Input, Space, Alert, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  Form,
  StyledModal,
  CustomNotification,
  dialogFunction,
} from 'common/components';
import {
  SearchGrid,
  SearchGridItemButton,
  SectionContainer,
} from '../components';
import MoveAllOptionsCheckboxs, {
  MOVE_OPTIONS,
} from './MoveAllOptionsCheckboxs';
import MemberMapping from './MemberMapping';
import MoveResults from './MoveResults';

import { mapToSearchParams } from '../mapper/mapToSearchParams';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { useMoveAllToMember, useGetSearchedMembersForMoveAll } from './queries';

import './styles.less';

const defaultPageInfo = {
  currentPageIndex: 1,
  currentPageSize: 20,
  totalRecord: null,
};

const { Paragraph } = Typography;

const MoveAllToMemberModal = (props, ref) => {
  const {
    searchType = 'member',
    isMultiple = true,
    apiUrl,
    params,
    tagRender,
    savedItem,
    savedSelected = false,
    service,
    dataPath,
    mapSearch,
    itemIdName,
    memberId,
    productList,
    onlyFetchWithSearchText = true,
    originMember,
    ...rest
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [, setPageInfo] = useState(defaultPageInfo);

  const [showSearchHint, setShowSearchHint] = useState(true);
  const [moveOptions, setMoveOptions] = useState([]);

  const refSearchText = useRef();

  const handleMoveAllToMember = useMoveAllToMember();
  const hanleGetSearchedMembersForMoveAl = useGetSearchedMembersForMoveAll();

  const { isLoading: loadingSubmit } = handleMoveAllToMember;
  const { isLoading: loadingGetSearchMembers } =
    hanleGetSearchedMembersForMoveAl;

  const [formInstance] = Form.useForm();

  const reloadPage = useDispatchReloadPage();

  const fetchItemList = async (customParams = {}) => {
    //* search Hint only show before first fetch
    showSearchHint && onlyFetchWithSearchText && setShowSearchHint(false);

    hanleGetSearchedMembersForMoveAl.mutate(
      { ...params, ...customParams },
      {
        onSuccess: (data) => {
          setItems(data?.allMembers);
          setPageInfo(data?.paging);
        },
      }
    );
  };

  const handleSearchItem = () => {
    const searchTextValue = refSearchText.current;

    if (!searchTextValue) return;

    const procParams = mapToSearchParams(
      { searchText: searchTextValue },
      params,
      mapSearch
    );

    fetchItemList(procParams);
  };

  const handleClearSearch = () => {
    formInstance.resetFields();
    fetchItemList(params);
  };

  const handleSelectItem = (itemData) => {
    setSelectedItems((prev) => {
      let nextValue = [...prev];

      const foundItemIdx = nextValue.findIndex(
        (item) =>
          (item?.[itemIdName] || item?.id) ===
          (itemData?.[itemIdName] || itemData?.id)
      );

      if (foundItemIdx !== -1) {
        nextValue.splice(foundItemIdx, 1);
      } else {
        if (!isMultiple) {
          nextValue = [itemData];
        } else {
          nextValue.push(itemData);
        }
      }

      return nextValue;
    });
  };

  const onMoveAllToMember = async () => {
    const params = {
      sourceMemberId: originMember?.id,
      targetMemberId: selectedItems?.[0]?.companyId,
      entityTypesForMoving: moveOptions,
    };

    return handleMoveAllToMember.mutateAsync(params, {
      onSuccess: (data) => {
        dialogFunction({
          type: 'info',
          content: <MoveResults moveEntityResults={data?.moveEntityResults} />,
          onOk: () => {
            setIsModalOpen(false);
            reloadPage();
          },
          cancelButtonProps: {
            style: {
              display: 'none',
            },
          },
          okText: 'Ok',
          width: 475,
        });
      },
      onError: ({ message }) => {
        CustomNotification.error(
          message || 'Failed to move items to new member'
        );
      },
    });
  };

  const mapMoveOptionsToLabel = (moveOtpions) => {
    return moveOtpions.map((optionItemValue) => {
      const foundMoveOptions = MOVE_OPTIONS.find(
        (moveOptionItem) => moveOptionItem?.value === optionItemValue
      );

      if (foundMoveOptions?.label === 'GLNs') {
        return 'GLNs';
      } else {
        return foundMoveOptions?.label.toLowerCase();
      }
    });
  };

  const handleClickOk = async () => {
    const hasSharedEntities = moveOptions?.includes('MoveSharedEntities');

    dialogFunction({
      type: 'warn',
      content: (
        <>
          {hasSharedEntities ? (
            <>
              <Paragraph strong style={{ marginBottom: 4 }}>
                Are you sure you want to perform this action?{' '}
              </Paragraph>
              <Paragraph style={{ marginBottom: 4 }}>
                If the company types do not align, the functionality of Private
                Label item assignments will be affected.
              </Paragraph>
              <Paragraph style={{ marginBottom: 0 }}>
                Source Member type: <strong>{originMember?.memberType || originMember?.memberRoleName}</strong>
              </Paragraph>
              <Paragraph style={{ marginBottom: 0 }}>
                Target Member Type:{' '}
                <strong>{selectedItems?.[0]?.memberRoleName}</strong>
              </Paragraph>
            </>
          ) : (
            <Paragraph style={{ marginBottom: 0 }}>
              {`Are you sure you want to transfer all ${mapMoveOptionsToLabel(
                moveOptions
              ).join(', ')} to new member?`}
            </Paragraph>
          )}
        </>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onMoveAllToMember,
    });
  };

  const resetAll = () => {
    setSelectedItems([]);
    setPageInfo(defaultPageInfo);
    formInstance.resetFields();
    setItems([]);
    setMoveOptions([]);
    setShowSearchHint(true);
  };

  const onChangeSearchText = (e) => {
    refSearchText.current = e.target.value;
  };

  const onRemoveTargetMember = () => {
    setSelectedItems([]);
  };

  const onChangeMoveOptions = (value) => {
    setMoveOptions(value);
  };

  const makeGridItemConfig = (item) => {
    if (originMember?.id === item?.companyId) {
      return {
        style: {
          opacity: '0.5',
          pointerEvent: 'none',
        },
        disableClick: true,
      };
    } else return {};
  };

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setIsModalOpen(false);
    },
    openModal: () => {
      setIsModalOpen(true);
    },
  }));

  useEffect(() => {
    if (isModalOpen) {
      !onlyFetchWithSearchText && fetchItemList(params);
    } else {
      !savedSelected && resetAll();
    }
  }, [
    isModalOpen,
    JSON.stringify(params),
    savedSelected,
    onlyFetchWithSearchText,
  ]);

  return (
    <>
      <StyledModal
        visible={isModalOpen}
        className='search-grid-item-all-to-member__modal'
        title='Transfer All Entities to Member'
        onCancel={() => {
          setIsModalOpen(false);
        }}
        width={1150}
        onOk={handleClickOk}
        centered
        destroyOnClose
        maskClosable={false}
        okButtonProps={{
          disabled: !selectedItems?.length || !moveOptions?.length,
        }}
        confirmLoading={loadingSubmit}
        {...rest}
      >
        <Row>
          <Col>
            <Input
              style={{ width: 270 }}
              onPressEnter={handleSearchItem}
              allowClear
              onChange={onChangeSearchText}
            />
          </Col>
          <Col>
            <Space>
              <SearchGridItemButton type='primary' onClick={handleSearchItem}>
                <SearchOutlined />
              </SearchGridItemButton>

              {!onlyFetchWithSearchText && (
                <SearchGridItemButton
                  type='primary'
                  onClick={handleClearSearch}
                >
                  Reset
                </SearchGridItemButton>
              )}
            </Space>
          </Col>
        </Row>

        <Row
          style={{
            flex: 1,
            marginLeft: -5,
            marginRight: -5,
            overflow: 'hidden',
          }}
        >
          <Col
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <SectionContainer>
              <SearchGrid
                items={items}
                handleSelectItem={handleSelectItem}
                selectedItems={selectedItems}
                isLoading={loadingGetSearchMembers}
                searchType={searchType}
                itemIdName={itemIdName}
                showSearchHint={showSearchHint}
                makeGridItemConfig={makeGridItemConfig}
              />
            </SectionContainer>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Row>
              <Col xs={24}>
                <Col>
                  <SectionContainer>
                    <MoveAllOptionsCheckboxs
                      onChangeMoveOptions={onChangeMoveOptions}
                    />
                  </SectionContainer>
                </Col>
              </Col>
            </Row>
            <Row style={{ flex: 1 }}>
              <Col xs={24}>
                <SectionContainer>
                  <Alert
                    message='Informational Note'
                    description={
                      <>
                        <Typography.Paragraph>
                          Please be aware that transferring entities between
                          members is irreversible. Once you initiate the
                          transfer, it cannot be undone. Please proceed with
                          caution.
                        </Typography.Paragraph>
                        <Typography.Text type='danger'>
                          Kindly note that selecting 'Move Assets Only' will
                          result in the removal of all assets and documents
                          currently associated with the selected product(s).
                        </Typography.Text>
                      </>
                    }
                    type='info'
                    showIcon
                    style={{ height: '100%', paddingTop: 8 }}
                  />
                </SectionContainer>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <SectionContainer>
                  <MemberMapping
                    originMember={originMember}
                    targetMember={selectedItems?.[0]}
                    onRemoveTargetMember={onRemoveTargetMember}
                  />
                </SectionContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};

export default forwardRef(MoveAllToMemberModal);
