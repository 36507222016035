import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Input,
  Select,
  Row,
  Col,
  InputNumber,
  Button,
  Tooltip,
  Typography,
  Checkbox,
} from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';

import {
  Form,
  WrapperSelect,
  ThumbnailItem,
  Fieldset,
  FullWidthSpace,
} from 'common/components';
import BrickCodeInput from './BrickCodeInput';
import ProductEditBrandModal from 'pages/product-full-view/components/product-edit-brand/ProductEditBrandModal';

import { ProductCategory } from './ProductCategory';

import * as companySelectors from 'pages/company-profile/controllers/selectors';
import userSelectors from 'redux/user/selectors';

import { useValidateStatusField } from 'hooks/useValidateStatusField';

import { useGetMemberInfo } from './hooks';
import { useGetEnumsByEnumName } from 'hooks';
import { PACKAGE_LEVELS, STATUS_PRODUCT } from 'static/Constants';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/product';

import { useCheckPermissionEditBrand } from 'hooks/useCheckPermissionEditBrand';
import CreateSupplier from './CreateSupplier';
import { defaultType } from 'pages/qa-spec/constant';

import { useGetMemberHeaderFromMemberId } from 'common/queries/member-module';

import sortByKey from 'utils/sortByKey';
import { ERROR_FIELD_NAME_COLLECTION } from '../preview-requirements/constants';

const { Option } = Select;
const { Text } = Typography;

const CreateProductForm = (props) => {
  const intl = useIntl();

  const {
    form,
    productData,
    formMode,
    targetMarketList,
    productEnums,
    currentHierarchy,
    brick,
    updateBrickCodeData,
    handleResetBrandId,
    setBrandId,
    editBrandVisible,
    setEditBrandVisible,
    brandThumb,
    setBrandThumb,
    errorInfo,
    memberIdBrand,
    setProductCategory,
  } = props;

  const userMemberInfo =
    useSelector(companySelectors.selectMemberProfile()) ?? {};

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const { memberInfo: companyInfo } = useGetMemberInfo(memberIdBrand);
  const { data: memberHeaderOfProductOwner } = useGetMemberHeaderFromMemberId({
    memberId: productData?.ownerId,
    enabled: Boolean(productData && productData?.ownerId),
  });

  const { data: memberHeaderOfProductCreator } = useGetMemberHeaderFromMemberId(
    {
      memberId: memberIdBrand,
      enabled: !!memberIdBrand,
    }
  );

  const hasPermissionEditBrand = useCheckPermissionEditBrand(
    memberIdBrand,
    userInfo
  );

  const { statusValidateProps } = useValidateStatusField({
    form,
    entityType: 'Product',
    entityId: productData?.productId,
    placement: 'rightBottom',
  });

  const qaTypeEnums = useGetEnumsByEnumName('ProductOverviewTypeCodeEnum');

  const isEdit = formMode === 'edit';

  const handleNumberInput = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const initFieldsError = () => {
    if (!errorInfo) return;

    form.setFields(errorInfo);
  };

  const sortTargetMarket = () => {
    if (!targetMarketList) return [];
    const USMarket = targetMarketList.find((marketItem, index) => {
      if (marketItem.Numeric !== 840) return false;

      return true;
    });

    const noneUSMarketList = targetMarketList.filter((marketItem) => {
      return marketItem.Numeric !== 840;
    });

    const sortedNoneUSMarketList = noneUSMarketList.sort((a, b) => {
      return a.Country - b.Country;
    });

    return [USMarket, ...sortedNoneUSMarketList];
  };

  const productBasicUOM = productEnums?.filter(
    (type) => type.enumName === 'BasicUomEnum'
  );

  const packageLevelWarning = currentHierarchy?.productHierarchyId
    ? {
        help: intl.formatMessage(messages.packageLevelTooltip),
        validateStatus: 'warning',
        hasFeedback: true,
      }
    : {};

  const enumProperties =
    productBasicUOM?.[0]?.enumProperties?.filter((type) =>
      ['Mass', 'Count', 'Volume', 'Dimensions', 'Area'].includes(type.enumGroup)
    ) || [];

  const enumMassProperties =
    productBasicUOM?.[0]?.enumProperties?.filter(
      (type) => type.enumGroup === 'Mass'
    ) || [];

  const enumDimensionsProperties =
    productBasicUOM?.[0]?.enumProperties?.filter(
      (type) => type.enumGroup === 'Dimensions'
    ) || [];

  const selectionFilter = useCallback(
    (input, option) =>
      option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0,
    []
  );

  const isPrivateVisibilityProduct = () => {
    if (formMode === 'create') {
      if (companyInfo || memberIdBrand) {
        return (
          memberHeaderOfProductCreator?.products?.toLowerCase() === 'private'
        );
      }
    }

    if (formMode === 'edit') {
      return memberHeaderOfProductOwner?.products?.toLowerCase() === 'private';
    }

    //* default return;
    return userMemberInfo?.products?.toLowerCase() === 'private';
  };

  const sortedTargetMarket = sortTargetMarket();

  //* add product
  const showRetailerProductCreation = (() => {
    if (isEdit) return false;

    /**
     ** check permission - create product for current user
     */
    if (!memberIdBrand && userInfo?.member?.memberType) {
      if (userInfo?.member?.memberType?.toLowerCase() === 'retailer')
        return true;
    }

    /**
     ** check permission - create product for member maintenance view
     */
    if (companyInfo || memberIdBrand) {
      if (companyInfo?.memberType?.toLowerCase() === 'retailer') return true;
      else return false;
    }

    return false;
  })();

  //* permssion edit private label
  const showEditPrivateLabel = (() => {
    /**
     ** check permission - create product for current user
     */
    if (!isEdit) {
      if (!memberIdBrand && userInfo?.member?.memberType) {
        if (userInfo?.member?.memberType?.toLowerCase() === 'retailer')
          return true;
      }
    }

    /**
     ** check permission - create product for member in maintain member view
     */
    if (!isEdit) {
      if (companyInfo || memberIdBrand) {
        if (companyInfo?.memberType?.toLowerCase() === 'retailer') return true;
      }
    }
    /**
     ** check permssion - edit product
     */
    if (isEdit) {
      if (productData?.ownerRole?.toLowerCase() === 'retailer') return true;
    }

    return false;
  })();

  useEffect(() => {
    initFieldsError();
  }, []);

  useEffect(() => {
    if (!isEdit) {
      form.setFieldsValue({
        packageLevel: PACKAGE_LEVELS[0],
        isPrivateLabel: true,
      });
    }
  }, [isEdit]);

  const brandError = errorInfo?.find((errorItem) =>
    errorItem.name.includes(ERROR_FIELD_NAME_COLLECTION.BRAND)
  );

  const upcGtinError = errorInfo?.find((errorItem) =>
    errorItem.name.includes(ERROR_FIELD_NAME_COLLECTION.AT_LEAST_GTIN_UPC)
  );

  const brandErrorMessage = brandError ? brandError.errors[0] : '';

  const upcGtinErrorMessage = upcGtinError ? upcGtinError.errors[0] : '';

  const renderProductFieldset = () => {
    return (
      <Fieldset title='Product' id='product-field'>
        <Form.Item
          label='Product Name'
          name='productName'
          rules={[
            {
              required: true,
              message: intl.formatMessage(messages.productNameRequired),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Primary Size' name='primarySize'>
          <InputNumber style={styles.numericInput} min={0} max={999999} />
        </Form.Item>

        {/*//! LACK INFORMATION, NEED TO UPDATE LATER */}
        <Form.Item label='Primary Size UOM' name='primarySizeUOM'>
          <WrapperSelect
            showSearch
            filterOption={selectionFilter}
            getPopupContainer={() => document.getElementById('product-field')}
          >
            {[
              sortBy([...enumProperties], ['enumDisplayName']).map(
                (type, index) => {
                  return (
                    <Option value={type?.enumDisplayName} key={index}>
                      {type?.enumDisplayName}
                    </Option>
                  );
                }
              ),
            ]}
          </WrapperSelect>
        </Form.Item>

        <Form.Item label='Description' name='productDescription'>
          <Input.TextArea rows={7} />
        </Form.Item>
      </Fieldset>
    );
  };

  const renderClassificationFieldset = () => {
    return (
      <Fieldset title='Classification' id='classification-field'>
        {/* {showRetailerProductCreation && (
          <Form.Item label='Type' name='type'>
            <WrapperSelect
              showSearch
              filterOption={selectionFilter}
              allowClear={false}
              defaultValue={defaultType}
              getPopupContainer={(toggleEl) =>
                document.getElementById('classification-field')
              }
            >
              {sortByKey(qaTypeEnums, 'enumDisplayName')?.map((item, index) => (
                <Select.Option
                  key={index}
                  value={item.enumDisplayName ?? defaultType}
                >
                  {item.enumDisplayName ?? defaultType}
                </Select.Option>
              ))}
            </WrapperSelect>
          </Form.Item>
        )} */}

        <Form.Item
          name='packageLevel'
          label='Package Level'
          rules={[
            {
              required: true,
              message: intl.formatMessage(messages.productPackageLvRequired),
            },
          ]}
          {...packageLevelWarning}
        >
          <WrapperSelect
            placeholder='Select a package level'
            disabled={currentHierarchy?.productHierarchyId}
            allowClear
            listHeight={160}
            getPopupContainer={() =>
              document.getElementById('classification-field')
            }
          >
            {PACKAGE_LEVELS?.map((packageLevel, index) => (
              <Option value={packageLevel} key={index}>
                {packageLevel}
              </Option>
            ))}
          </WrapperSelect>
        </Form.Item>

        <Form.Item name='brick' label='Brick Code'>
          <BrickCodeInput
            updateBrickCodeData={updateBrickCodeData}
            initialValue={brick}
          />
        </Form.Item>
        <Form.Item label='Brick Code Name'>
          <Input value={brick?.brickCodeName} disabled />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage(messages.targetMarket)}
          name='targetMarket'
        >
          <WrapperSelect
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={() =>
              document.getElementById('classification-field')
            }
          >
            {targetMarketList?.length > 0 &&
              sortedTargetMarket?.map((item, index) => (
                <Select.Option key={index} value={item?.Country}>
                  {item?.Country}
                </Select.Option>
              ))}
          </WrapperSelect>
        </Form.Item>
        {/* {showRetailerProductCreation && (
          <Form.Item label='Retail Banner' name='retailBanner'>
            <Input />
          </Form.Item>
        )} */}

        {/* {showRetailerProductCreation && (
          <Form.Item
            label='USDA WIC Eligible'
            name='wicCompliant'
            valuePropName='checked'
          >
            <Checkbox />
          </Form.Item>
        )} */}
      </Fieldset>
    );
  };

  const renderGtinFieldset = () => {
    return (
      <Fieldset title='GTIN' id='gtin-field'>
        <Form.Item label='GTIN' name='gtin'>
          <Input
            min='0'
            onKeyPress={handleNumberInput}
            maxLength={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='UPC8' name='upc8'>
          <Input
            min='0'
            onKeyPress={handleNumberInput}
            maxLength={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='UPC10' name='upc10'>
          <Input
            min='0'
            onKeyPress={handleNumberInput}
            maxLength={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='UPC11' name='upc11'>
          <Input
            min='0'
            onKeyPress={handleNumberInput}
            maxLength={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='UPC12' name='upc12'>
          <Input
            min='0'
            onKeyPress={handleNumberInput}
            maxLength={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='UPC13' name='upc13'>
          <Input
            min='0'
            onKeyPress={handleNumberInput}
            maxLength={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>
        {upcGtinError ? <Text type='danger'>{upcGtinErrorMessage}</Text> : null}
      </Fieldset>
    );
  };

  const renderBrandFieldset = () => {
    return hasPermissionEditBrand ? (
      <Fieldset title='Brand / Category'>
        <Row gutter={16}>
          <Col xxl={10} xl={12}>
            <Form.Item name='brandId'>
              <Form.Item>
                <div className='add-product__thumb-wrapper'>
                  <div>
                    <CloseOutlined
                      className='add-product__thumb-wrapper-close-btn'
                      onClick={handleResetBrandId}
                    />
                  </div>
                  <ThumbnailItem
                    hideLabel={true}
                    imageThumbnail={brandThumb}
                    hideCheckbox
                    style={{ marginLeft: 0 }}
                  />
                </div>
                <Tooltip title='Choose an Brand ID'>
                  <Button onClick={() => setEditBrandVisible(true)}>
                    Link to Brand
                  </Button>
                </Tooltip>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xxl={14} xl={12}>
            <ProductCategory setProductCategory={setProductCategory} />
          </Col>
        </Row>
        {brandError ? <Text type='danger'>{brandErrorMessage}</Text> : null}
        {editBrandVisible && (
          <ProductEditBrandModal
            visible={editBrandVisible}
            setVisibleEditBrand={setEditBrandVisible}
            productDetail={productData}
            isAddProduct={true}
            isEdit={isEdit}
            setBrandId={setBrandId}
            setBrandThumb={setBrandThumb}
            memberIdFromBrand={memberIdBrand}
          />
        )}
      </Fieldset>
    ) : null;
  };

  const renderPackageWeightFieldset = () => {
    return (
      <Fieldset title='Packaging Weights and Measures' id='package-field'>
        <Form.Item label='Packaged Width' name='width'>
          <InputNumber
            style={styles.numericInput}
            min={0}
            max={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='Packaged Height' name='height'>
          <InputNumber
            style={styles.numericInput}
            min={0}
            max={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='Packaged Depth' name='depth'>
          <InputNumber
            style={styles.numericInput}
            min={0}
            max={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='Packaged Weight' name='weight'>
          <InputNumber
            style={styles.numericInput}
            min={0}
            max={MAX_NUMBER_INPUT_UPC}
          />
        </Form.Item>

        <Form.Item label='Packaged Weight UOM' name='weightUOM'>
          <WrapperSelect
            showSearch
            filterOption={selectionFilter}
            getPopupContainer={() => document.getElementById('package-field')}
          >
            {[
              sortBy([...enumMassProperties], ['enumDisplayName']).map(
                (type, index) => {
                  return (
                    <Option value={type?.enumDisplayName} key={index}>
                      {type?.enumDisplayName}
                    </Option>
                  );
                }
              ),
            ]}
          </WrapperSelect>
        </Form.Item>

        <Form.Item label='Packaged Dimension UOM' name='dimensionUOM'>
          <WrapperSelect
            showSearch
            filterOption={selectionFilter}
            getPopupContainer={() => document.getElementById('package-field')}
          >
            {[
              sortBy([...enumDimensionsProperties], ['enumDisplayName']).map(
                (type, index) => {
                  return (
                    <Option value={type?.enumDisplayName} key={index}>
                      {type?.enumDisplayName}
                    </Option>
                  );
                }
              ),
            ]}
          </WrapperSelect>
        </Form.Item>
      </Fieldset>
    );
  };

  const renderSecurityFieldset = () => {
    return (
      <Fieldset title='Security' id='security-field'>
        <Form.Item name='visibility' label='Visibility'>
          {isPrivateVisibilityProduct() ? (
            <WrapperSelect
              disabled={productData?.gdsnShield}
              getPopupContainer={() =>
                document.getElementById('security-field')
              }
            >
              <Option value='Only If Shared'>Only If Shared</Option>
              <Option value='Private'>Private</Option>
              {/* <Option value='None'>None</Option> */}
            </WrapperSelect>
          ) : (
            <WrapperSelect
              disabled={productData?.gdsnShield}
              getPopupContainer={() =>
                document.getElementById('security-field')
              }
            >
              <Option value='Only If Shared'>Only If Shared</Option>
              <Option value='Public'>Public</Option>
              <Option value='Private'>Private</Option>
              {/* <Option value='None'>None</Option> */}
            </WrapperSelect>
          )}
        </Form.Item>
        <Form.Item name='status' label='Status' {...statusValidateProps}>
          <WrapperSelect
            getPopupContainer={() => document.getElementById('security-field')}
            disabled={productData?.status?.toLowerCase() === 'exception'}
          >
            {[
              sortBy([...STATUS_PRODUCT], ['displayName']).map(
                (status, index) => {
                  return (
                    <Option value={status?.value} key={index}>
                      {status?.displayName}
                    </Option>
                  );
                }
              ),
            ]}
          </WrapperSelect>
        </Form.Item>
      </Fieldset>
    );
  };

  return (
    <Form
      form={form}
      {...formItemLayout}
      name='Add-Product'
      preserve={false}
      className='add-product__modal'
    >
      <Row gutter={16} style={{ overflowX: 'hidden' }}>
        {/* {isEdit && (
          <Col span={8}>
            <Form.Item label='RIVIR ID'>
              <Input value={productData?.rivirId} disabled />
            </Form.Item>
          </Col>
        )}
        {showRetailerProductCreation && (
          <Col span={8}>
            <CreateSupplier isCreateProductModal />
          </Col>
        )} */}
        {showEditPrivateLabel && (
          <Col span={8}>
            <Form.Item
              label='Private Label'
              name='isPrivateLabel'
              valuePropName='checked'
              colon={false}
              labelAlign='right'
              labelCol={{
                flex: '85px',
              }}
              wrapperCol={{
                flex: 1,
              }}
            >
              <Checkbox
                onChange={(e) => {
                  if (!e.target.checked)
                    form?.setFieldsValue({ supplierId: null });
                }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      <FullWidthSpace size={16}>
        <Row gutter={16}>
          <Col span={8}>{renderProductFieldset()}</Col>
          <Col span={8}>{renderGtinFieldset()}</Col>
          <Col span={8}>{renderPackageWeightFieldset()}</Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>{renderSecurityFieldset()}</Col>
          <Col span={8}>{renderBrandFieldset()}</Col>
          <Col span={8}>{renderClassificationFieldset()}</Col>
        </Row>
      </FullWidthSpace>
    </Form>
  );
};

export default CreateProductForm;

const styles = {
  numericInput: {
    width: '100%',
  },
};

const MAX_NUMBER_INPUT_UPC = 9999999999999999999;

const formItemLayout = {
  labelCol: {
    xl: {
      span: 24,
    },
    xxl: {
      span: 8,
    },
  },
  wrapperCol: {
    xl: {
      span: 24,
    },
    xxl: {
      span: 15,
    },
  },
};
