import React from 'react';

import { Row, Col, Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TooltipParagraph } from 'common/components';
import { findDimensionEnumDisplay } from '../../../product-full-view/mappers/mapToEnums';

const Loading = () => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className='product-content__loading'>
      <Spin indicator={loadingIcon} />
    </div>
  );
};

const InformationLabel = ({ children, ...otherProps }) => {
  return (
    <Col span={8} className='product-content__info-item' {...otherProps}>
      <Typography.Title level={5} ellipsis>
        {children}:
      </Typography.Title>
    </Col>
  );
};

const InformationData = ({ data, children, ...otherProps }) => {
  return (
    <Col span={16} className='product-content__info-data' {...otherProps}>
      {children}
      {data && <Typography.Text>{data}</Typography.Text>}
    </Col>
  );
};

const ListDimensionInfo = ({
  listNameDimension,
  listImperialValue,
  imperialUnit,
  listMetricValue,
  metricUnit,
  productEnums,
}) => {
  return (
    <>
      {listImperialValue.map((imperialValue, index) => (
        <DimensionInfo
          key={index}
          name={listNameDimension[index]}
          imperialValue={imperialValue}
          metricValue={listMetricValue[index]}
          imperialUnit={imperialUnit}
          metricUnit={metricUnit}
          productEnums={productEnums}
        />
      ))}
    </>
  );
};

const DimensionInfo = ({
  name,

  imperialValue,
  imperialUnit,
  metricValue,
  metricUnit,
  productEnums,
}) => {
  return (
    <Row>
      <Col span={12} style={{ display: 'flex' }}>
        <b>{name}: </b>
        <TooltipParagraph
          placement='topLeft'
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'inline',
            paddingLeft: 5,
          }}
        >
          {isNaN(parseFloat(imperialValue)) ? (
            'N/A'
          ) : (
            <>
              {Math.round(parseFloat(imperialValue) * 100) / 100}{' '}
              {findDimensionEnumDisplay(imperialUnit, productEnums)}
            </>
          )}
        </TooltipParagraph>
      </Col>
      <Col span={12} style={{ display: 'flex' }}>
        <b>{name}: </b>
        <TooltipParagraph
          placement='topLeft'
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'inline',
            paddingLeft: 5,
          }}
        >
          {isNaN(parseFloat(metricValue)) ? (
            'N/A'
          ) : (
            <>
              {Math.round(parseFloat(metricValue) * 100) / 100}{' '}
              {findDimensionEnumDisplay(metricUnit, productEnums)}
            </>
          )}
        </TooltipParagraph>
      </Col>
    </Row>
  );
};

export {
  InformationLabel,
  InformationData,
  ListDimensionInfo,
  DimensionInfo,
  Loading,
};
