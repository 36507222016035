import React from 'react';
import { Typography, Row, Col, Checkbox } from 'antd';

const { Text } = Typography;

const PageEditorSectionHeader = (props) => {
  const { text, doesHaveCheckbox, onChange, value } = props;
  return (
    <div className='page-editor-tool__section-header'>
      <Row>
        <Col>
          <Text>{text}</Text>
        </Col>
        <Col flex='auto' />
        {doesHaveCheckbox && (
          <Col>
            <Checkbox onChange={onChange} checked={value} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PageEditorSectionHeader;
