import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Typography, Row, Col } from 'antd';
import {
  PlayCircleFilled,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import classnames from 'classnames';

import * as chatActions from '../../controller/actions';

import {
  detectYoutubeVideo,
  detectUrl,
  formatMessage,
  detectObjectStrContactCard,
} from 'utils/string';
import { useCheckSenderAccount } from 'hooks/useCheckSenderAccount';

import * as chatSelectors from '../../controller/selectors';
import { getChatFileInfo } from 'services/chatServices';

import ContactCardMessage from 'common/components/contact-card/messages/ContactCardMessages';

import ChatFile from './ChatFile';

const { Link, Text } = Typography;

const ChatMessage = (props) => {
  const {
    item,
    showErrorMessage = false,
    isSelectedSearchResult,
    participant,
    isShowName,
  } = props;

  const chatThreadInfo = useSelector(chatSelectors.selectActiveThreadInfo());

  const dispatch = useDispatch();

  const [chatFileInfo] = useGetChatFileInfo(
    item?.attachmentIds,
    item?.attachmentInfos,
    chatThreadInfo?.threadId,
    dispatch
  );

  const isSender = useCheckSenderAccount(item?.senderId);

  const message = item?.messageText;
  const domRef = useRef(null);

  const youtubeVideoId = detectYoutubeVideo(message);

  const getMessageType = () => {
    if (detectUrl(message)) return 'url';
    if (detectObjectStrContactCard(message)) return 'contact-card';
    return 'text';
  };

  const updateSelectedSearchResultPosition = () => {
    if (isSelectedSearchResult) {
      const el = domRef.current;
      const listItem = el.closest('.ant-list-item');
      const position = listItem.offsetTop;
      // const itemHeight = listItem.offsetHeight;
      // const scrollDestination = position + itemHeight;
      const scrollDestination = position;
      dispatch(
        chatActions.updateSelectedSearchResultPosition(scrollDestination)
      );
    }
  };

  useEffect(() => {
    if (isSelectedSearchResult) {
      updateSelectedSearchResultPosition();
    }
  }, [isSelectedSearchResult]);

  const type = getMessageType();
  const renderMessage = () => {
    const messageComp = {
      text: (
        <div dangerouslySetInnerHTML={{ __html: formatMessage(message) }} />
      ),
      url: (
        <Link href={message} target='_blank'>
          {message}
        </Link>
      ),
      'contact-card': (
        <ContactCardMessage userInfo={message} isInChatThread={true} />
      ),
    };

    return messageComp[type];
  };

  const renderYoutubeVideo = () => {
    return (
      <div
        className='chat-thread__video-frame-container'
        style={{
          backgroundImage: `url(https://img.youtube.com/vi/${youtubeVideoId}/0.jpg)`,
        }}
      >
        <a href={message} target='blank'>
          <PlayCircleFilled className='chat-thread__video-icon' />
        </a>
      </div>
    );
  };

  const renderChatFile = () => {
    if (item?.attachmentIds?.length > 0 && item?.attachmentInfos) {
      const attachInfos = item?.attachmentInfos
        ? mapChatDataInfo(item?.attachmentInfos)
        : [];

      return <ChatFile chatFileInfo={attachInfos} isSender={isSender} />;
    }

    if (chatFileInfo)
      return <ChatFile chatFileInfo={chatFileInfo} isSender={isSender} />;
    if (item?.attachmentIds?.length > 0 && !chatFileInfo)
      return <LoadingOutlined />;
    return null;
  };

  const messageContainerClassName = classnames({
    'chat-thread__message-container':
      message && type !== 'contact-card' ? true : null,
    'chat-thread__message-container--video': youtubeVideoId,
    'chat-thread__message-container--search-highlight': isSelectedSearchResult,
    'chat-thread__message-container--contact-card':
      type === 'contact-card' ? true : null,
  });

  return (
    <div className='chat-thread__message' ref={domRef}>
      {showErrorMessage && (
        <div className='chat-thread__message--error'>
          <InfoCircleOutlined style={{ color: '#faad14', fontSize: 18 }} />
        </div>
      )}
      <div className={messageContainerClassName}>
        <Row>
          {isShowName && (
            <Col xs={24}>
              <Text className='chat-thread__message-name' ellipsis>
                {participant?.name}
              </Text>
            </Col>
          )}
          <Col xs={24} className='chat-thread__message-text'>
            {renderMessage()}
          </Col>
        </Row>
      </div>
      {youtubeVideoId ? renderYoutubeVideo() : null}
      {renderChatFile()}
    </div>
  );
};

export const imageTypes = ['.jpg', '.png', '.jpeg'];

const mapChatDataInfo = (data) => {
  let images = [];
  let files = [];

  if (data) {
    data.forEach((item) => {
      if (imageTypes.includes(item.fileType)) {
        images.push(item);
      } else {
        files.push(item);
      }
    });
  }

  return { images, files };
};

const useGetChatFileInfo = (
  attachmentIds,
  attachmentInfos,
  threadId,
  dispatch
) => {
  const [state, setState] = React.useState({});

  useEffect(() => {
    if (
      attachmentIds?.length > 0 &&
      attachmentIds?.length !== attachmentInfos?.length
    ) {
      getChatFileInfo({
        fileIds: attachmentIds,
        threadId,
      })
        .then((response) => {
          if (response?.isSuccess) {
            setState(mapChatDataInfo(response?.data?.fileInfoModels));
          } else {
            console.log('error');
          }

          dispatch(chatActions.scrollChatThreadToBottom());
        })
        .catch((e) => {
          dispatch(chatActions.scrollChatThreadToBottom());
        });
    }
  }, [attachmentIds, attachmentInfos, threadId]);

  return [state];
};

export default ChatMessage;
