import React, { useRef, useEffect } from 'react';

import { StyledModal, Form, CustomNotification } from 'common/components';

import QaFormulaDrugForm from './QaFormulaDrugForm';

import { sleep } from 'utils/delay';

const QaFormulaDrugModal = (props) => {
  const activeIngredientGridRef = useRef();
  const [form] = Form.useForm();

  const { visible, drugData, snapshotDrugData, closeModal, onSubmit, mode } =
    props;

  const initForm = () => {
    if (!visible) return;
    if (!drugData) return;

    form.setFieldsValue(drugData);
  };

  const validateActiveIngredient = (activeIngredientData) => {
    if (!activeIngredientData?.length) return false;

    return activeIngredientData.some((activeIngredientItem) => {
      return activeIngredientItem?.error?.length > 0;
    });
  };

  const onCancel = () => {
    form.resetFields();

    closeModal && closeModal();
  };

  const onOk = async () => {
    activeIngredientGridRef.current.stopEditing();

    await sleep(100);

    const formValue = await form.validateFields();

    const activeIngredientGridData =
      activeIngredientGridRef.current.getGridData();

    const activeIngredientValidateError = validateActiveIngredient(
      activeIngredientGridData
    );

    if (activeIngredientValidateError || !formValue)
      return CustomNotification.error(
        "Please resolve Active Ingredients grid's errors"
      );

    const drugFactValue = {
      ...formValue,
      drugActiveIngredients: activeIngredientGridData,
    };

    onSubmit && onSubmit(drugFactValue);

    form.resetFields();
  };

  useEffect(() => {
    initForm();
  }, [visible]);

  return (
    <StyledModal
      title={'Formula Drug Fact'}
      visible={visible}
      destroyOnClose
      maskClosable={false}
      width={1200}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} layout='vertical'>
        <QaFormulaDrugForm
          ref={activeIngredientGridRef}
          isEdit
          drugFactData={drugData}
          drugFactSnapshotData={snapshotDrugData}
          mode={mode}
        />
      </Form>
    </StyledModal>
  );
};

export default QaFormulaDrugModal;
