import React, { useMemo } from 'react';

import { Images } from 'config/assets';

import { Tooltip } from 'antd';

import { QA_SPEC_STYLE_CONST } from 'pages/qa-spec/constant';
import classnames from 'classnames';

import './ProductHeaderImage.less';

const imageContainerAni = classnames(
  'ani--slideInRight',
  'ani--fill-forward',
  'ani--iter-1',
  'ani--time-20',
  'ani--easy-in-out'
);

const { PRODUCT_HEADER_IMAGE_WRAPPER_PADDING } = QA_SPEC_STYLE_CONST;

const ProductHeaderImage = (props) => {
  const {
    productDetail,
    productHeaderImageSize,
    toggleTabExpand,
    hidden,
    toggleExpandText,
  } = props;

  const imageSrc =
    productDetail?.productPrimaryImage || Images.RIVIR_LOGO_DEFAULT;

  const styles = useMemo(() => {
    return {
      wrapperStyle: {
        width:
          productHeaderImageSize?.height / Math.sqrt(2) -
          PRODUCT_HEADER_IMAGE_WRAPPER_PADDING * 2,
        height:
          productHeaderImageSize?.height / Math.sqrt(2) -
          PRODUCT_HEADER_IMAGE_WRAPPER_PADDING * 2,
      },
    };
  }, [productHeaderImageSize?.height]);

  return (
    <Tooltip title={toggleExpandText || 'Open Full View'}>
      <div
        className={classnames(
          'product-header-image__container',
          imageContainerAni,
          {
            'product-header-image__container--hidden': hidden,
          }
        )}
        onClick={() => toggleTabExpand()}
        style={{
          width: productHeaderImageSize?.width,
          height: productHeaderImageSize?.height,
        }}
      >
        <div
          className='product-header-image__wrapper'
          style={styles.wrapperStyle}
        >
          <img className='product-header-image' src={imageSrc} />
        </div>
      </div>
    </Tooltip>
  );
};

export default ProductHeaderImage;
