import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Messages from 'i18n/messages/home';

import { NodeIndexOutlined, DeleteOutlined } from '@ant-design/icons';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as ribbonSelector from 'redux/ribbon/selectors';
import * as actionsAsset from 'pages/home/ribbon/asset-full/controllers/actions';
import assetsRibbonSelector from 'pages/home/ribbon/asset-full/controllers/selectors';
import * as linkAssetsActions from 'pages/branded-assets/components/panel/link-assets/controller/actions';
import * as assetFullViewSelector from 'pages/asset-full-view/controllers/selectors';

import { RIBBON_VIEW } from 'static/Constants';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import {
  useCheckAllowEditAssets,
  usePermissionEditSharedAsset,
  useCheckIsSuperAdmin,
} from 'hooks';

const LinkAssets = ({ sectionDisabled }) => {
  const dispatch = useDispatch();

  const selectedAssets = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const assetFullView = useSelector(
    assetFullViewSelector.makeSelectAssetData()
  );
  const modeLinkAssets = useSelector(
    assetsRibbonSelector.selectModeLinkAssets()
  );
  const modeReplacePreview = useSelector(
    assetsRibbonSelector.selectReplacePreview()
  );
  const modeEdit = useSelector(assetsRibbonSelector.selectAssetEdit());

  const visibleContentPane = useSelector(gridSelector.makeSelectVisible());
  const view = useSelector(ribbonSelector.selectRibbon());

  const isSuperAdmin = useCheckIsSuperAdmin();

  const hasPermissionEditSharedAsset = usePermissionEditSharedAsset();

  const { checkAllowEditAssetFull } = useCheckAllowEditAssets();

  const isAllowEditFull = checkAllowEditAssetFull();

  const allowClickInViewAsset = checkInAssetView(
    view,
    selectedAssets,
    visibleContentPane,
    isSuperAdmin,
    isAllowEditFull,
    hasPermissionEditSharedAsset
  );

  const disableButton = checkAllCondition(
    !allowClickInViewAsset,
    sectionDisabled,
    modeReplacePreview,
    modeEdit
  );

  useEffect(() => {
    if (visibleContentPane || modeReplacePreview || modeEdit) return;

    dispatch(actionsAsset.disableModeLinkAsset());
    dispatch(linkAssetsActions.resetLinkAsset());
  }, [dispatch, visibleContentPane, modeReplacePreview, modeEdit]);

  return (
    <>
      {modeLinkAssets ? (
        <ButtonSmallIcon
          className='button-item-dropdown'
          icon={<DeleteOutlined />}
          label={Messages.cancelLink}
          disabled={disableButton}
          onClick={() => dispatch(actionsAsset.disableModeLinkAsset())}
        />
      ) : (
        <ButtonSmallIcon
          className='button-item-dropdown'
          icon={<NodeIndexOutlined />}
          label={Messages.linkAssets}
          disabled={disableButton}
          onClick={() => dispatch(actionsAsset.enableModeLinkAsset())}
        />
      )}
    </>
  );
};

// Allow in the asset-full-view if isOwner = true, but in the asset-grid-view only allow when a chosen asset.
const checkInAssetView = (
  view,
  selectedAssets,
  visibleContentPane,
  isSuperAdmin,
  isAllowEditFull,
  hasPermissionEditSharedAsset
) => {
  if (view === RIBBON_VIEW.ASSET_FULL_VIEW.NAME) {
    return isAllowEditFull;
  }

  if (view !== RIBBON_VIEW.ASSET_FULL_VIEW.NAME) {
    const asset = selectedAssets[0];

    const isOwner = asset?.hasOwnProperty('isOwner') && asset?.isOwner;

    const hasPermissionLink =
      isSuperAdmin || hasPermissionEditSharedAsset || isOwner;

    if (selectedAssets.length === 1 && visibleContentPane) {
      if (asset && hasPermissionLink) return true;
    }
  }

  return false;
};

const checkAllCondition = (...args) => {
  return args.some((ele) => ele);
};

export default LinkAssets;
