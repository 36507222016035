export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_ERROR = 'GET_COMPANY_INFO_ERROR';
export const GET_COMPANY_INFO_SHOWCASED_MEDIA_GRID =
  'GET_COMPANY_INFO_SHOWCASED_MEDIA_GRID';
export const GET_COMPANY_INFO_OVERVIEW_SHOWCASED_MEDIA_SUCCESS =
  'GET_COMPANY_INFO_OVERVIEW_SHOWCASED_MEDIA_SUCCESS';
export const GET_COMPANY_INFO_SHOWCASED_MEDIA_GRID_ERROR =
  'GET_COMPANY_INFO_SHOWCASED_MEDIA_GRID_ERROR';
export const GET_OFFICES_AND_FACILITIES = 'GET_OFFICES_AND_FACILITIES';
export const GET_OFFICES_AND_FACILITIES_SUCCESS =
  'GET_OFFICES_AND_FACILITIES_SUCCESS';
export const GET_OFFICES_AND_FACILITIES_ERROR =
  'GET_OFFICES_AND_FACILITIES_ERROR';

// get member brands
export const GET_MEMBER_COMPANY_BRANDS = 'GET_MEMBER_COMPANY_BRANDS';
export const GET_MEMBER_COMPANY_BRANDS_SUCCESS =
  'GET_MEMBER_COMPANY_BRANDS_SUCCESS';
export const GET_MEMBER_COMPANY_BRANDS_ERROR =
  'GET_MEMBER_COMPANY_BRANDS_ERROR';

export const GET_MEMBER_ID = 'GET_MEMBER_ID';
export const RESET_COMPANY_INFO_ERROR = 'RESET_COMPANY_INFO_ERROR';
