import React from 'react';

import { Divider, Row, Col } from 'antd';
import ToggleSplashScreenSection from './components/sections/ixoneSection/ToggleSplashScreenSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import { ViewLayout, AdvanceStack } from './components';

const IxoneRibbon = (props) => {
  return (
    <Row style={{ display: 'flex', alignItems: 'center' }}>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <AdvanceStack isDisabled />
      <Divider type='vertical' style={{ height: 40, margin: '4px 8px' }} />
      <Col flex='auto' />
      <Divider type='vertical' style={{ height: 40, margin: '4px 8px' }} />
      <ToggleSplashScreenSection />
    </Row>
  );
};

export default IxoneRibbon;
