import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Tooltip } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import * as productDetailSelectors from '../../controllers/selectors';
import * as productFullViewActions from '../../controllers/actions';
import Messages from 'i18n/messages/product';
import { FormattedMessage } from 'react-intl';

const ProductOverviewQuestionButton = (props) => {
  const { modalContent, modalProps, showPackageDrawer, mode } = props;

  const dispatch = useDispatch();

  const hierarchyShortDetailsDisplayMode = useSelector(
    productDetailSelectors.makeSelectHierarchyShortDetailsDisplayMode()
  );

  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const openModal = () => {
    if (showPackageDrawer) {
      if (hierarchyShortDetailsDisplayMode !== mode) {
        dispatch(
          productFullViewActions.updateHierarchyShortDetailsDisplayMode(mode)
        );
      }
      if (mode !== 'Metric') {
        showPackageDrawer();
      }
    }
  };

  return (
    <>
      <Tooltip
        title={
          mode !== 'Metric' ? (
            <FormattedMessage {...Messages.hierarchies} />
          ) : null
        }
      >
        <Button
          className='product-overview__metadata-question-btn'
          type='link'
          onClick={openModal}
        >
          <QuestionCircleTwoTone style={{ fontSize: 16 }} />
        </Button>
      </Tooltip>

      <Modal visible={visible} onCancel={handleCancel} {...modalProps}>
        {modalContent ? modalContent : null}
      </Modal>
    </>
  );
};

export default ProductOverviewQuestionButton;
