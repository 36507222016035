import React, { useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import { ReactComponent as IconAddFolder } from 'common/components/button/svg-icons/add-folder.svg';
import RibbonButton from 'common/components/button/RibbonButton';
import CreateFolderModal from './CreateFolderModal';

import { SHARED_FOLDER_PATHNAME, FAVORITE_FOLDER_PATHNAME } from './pathname';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import Messages from 'i18n/messages/home';
import messagesFolder from 'i18n/messages/folder';

import { CustomNotification } from 'common/components';

const CreateFolder = ({ pathname, disabled, parentId, destinationFolder }) => {
  const reloadPage = useDispatchReloadPage();

  const intl = useIntl();

  // Not allow make new in the shared folder
  const isFavoriteDisabled = pathname === FAVORITE_FOLDER_PATHNAME;
  const isSharedDisabled =
    pathname === SHARED_FOLDER_PATHNAME && !destinationFolder?.editable;
  const disableButton = isFavoriteDisabled || isSharedDisabled || disabled;

  const visibleModalHook = useState(false);
  const [, setVisible] = visibleModalHook;

  const showCreateFolderModal = () => {
    setVisible(true);
  };

  const onCreateFolderSuccess = () => {
    CustomNotification.success(
      intl.formatMessage(messagesFolder.createFolderSuccess)
    );
    reloadPage();
  };

  return (
    <>
      <RibbonButton
        size='small'
        icon={<IconAddFolder />}
        label={Messages.newFolder}
        disabled={disableButton}
        onClick={showCreateFolderModal}
      />
      <CreateFolderModal
        visibleHook={visibleModalHook}
        successCallback={onCreateFolderSuccess}
        parentId={parentId}
        skipNotification={true}
      />
    </>
  );
};

CreateFolder.propTypes = {
  pathname: PropTypes.string,
};

export default injectIntl(CreateFolder);
