import React from 'react';
import Messages from 'i18n/messages/maintenance';
import { FormOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const EditUserControl = (props) => {
  return (
    <ButtonSmallIcon
      icon={<FormOutlined />}
      label={Messages.editUser}
      {...props}
    />
  );
};

export default EditUserControl;
