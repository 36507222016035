import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroller';
import { Popover, Input, List, Row, Typography, Empty } from 'antd';

import { WithLoading } from 'common/components';
import SearchEmailResultItem from './SearchEmailResultItem';

import * as emailActions from '../../controller/actions';
import * as emailSelector from '../../controller/selectors';

import * as emailServices from 'services/email';
import { sleep } from 'utils/delay';

import mapEmailsWithAttachUrl from '../../mapper/mapEmailsWithAttachUrl';

const { Paragraph } = Typography;
const { Search } = Input;
let searchTimeout;

const EmailSearchMessage = (props) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { visible, setVisible, groupUsers, threadInfo, searchBarVisible } =
    props;
  const [value, setValue] = useState('');
  const [resultOption, setResultOption] = useState([]);
  const [error, setError] = useState(null);
  const [paging, setPaging] = useState({
    currentPageIndex: 1,
    currentPageSize: 2,
    totalRecord: 1,
  });
  const [loading, setLoading] = useState(true);
  const emailThreadInfoContent = useSelector(
    emailSelector.selectEmailThreadInfoContent()
  );

  const handleSelectResult = (item) => {
    const shouldReload = !emailThreadInfoContent?.find((thread) => {
      return thread.messageId === item.messageId;
    });
    if (shouldReload) {
      reloadThreadMessages(item);
      return;
    }

    dispatch(emailActions.selectSearchResult(item));
  };

  const reloadThreadMessages = (item) => {
    callApiGetMessages(item);
    // dispatch(emailActions.getThreadMessages(threadInfo.id, item.sendTime));
  };

  const callApiGetMessages = (item) => {
    const paramsUp = {
      threadId: threadInfo.id,
      timestamp: item.sendTime,
      direction: 0,
    };

    const paramsDown = {
      threadId: threadInfo.id,
      timestamp: item.sendTime + 1,
      direction: 1,
    };

    Promise.all([
      emailServices.getEmailThreadInfoContent(paramsUp),
      emailServices.getEmailThreadInfoContent(paramsDown),
    ])
      .then(async (response) => {
        const isSuccess =
          response.length && response.every((res) => res.isSuccess === true);

        if (!isSuccess) throw new Error('Cannot get messages');

        const data = await response.reduce(
          async (accumulator, currentResponse) => {
            const currentResData = currentResponse?.data?.threadMessages;

            const handledMessages = await mapEmailsWithAttachUrl(
              currentResData,
              threadInfo.id
            );

            let accum = await accumulator;

            const concatedMsgs = accum.concat(handledMessages);

            return concatedMsgs;
          },
          Promise.resolve([])
        );

        const sortedData = data.sort((messageA, messageB) => {
          return messageB.sendTime - messageA.sendTime;
        });
        dispatch(emailActions.getEmailThreadInfoContentSuccess(sortedData));
        dispatch(emailActions.updateFinishLoadDown(false));
        dispatch(emailActions.selectSearchResult(item));
      })
      .catch((err) => {
        dispatch(
          emailActions.getEmailThreadInfoContentFail(err.message || err)
        );
      });
  };

  const clearSelectResult = () => {
    dispatch(emailActions.selectSearchResult(null));
  };

  const clearSearchMessagePosition = () => {
    dispatch(emailActions.updateSelectedSearchResultPosition(null));
  };

  const handleHideResultSearch = async () => {
    if (visible) return;

    await sleep(500); //? wait search bar close completely
    setValue('');
    setResultOption([]);
    clearTimeout(searchTimeout);
    clearSelectResult();
    clearSearchMessagePosition();
  };

  const debounceCallApi = (value) => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      callSearchApi({ value, page: 1, isDebounce: true });
    }, 1000);
  };

  const callSearchApi = async ({ value, page, isDebounce }) => {
    if (!value) return;

    try {
      const params = {
        SearchText: value,
        PageIndex: page,
        PageSize: 10,
        ThreadId: threadInfo?.id,
      };
      const result = await emailServices.searchEmailMessage(params);
      if (!result?.isSuccess) throw new Error('Search Error');
      if (result?.data) {
        const currentResult = isDebounce ? [] : resultOption;
        setResultOption([...currentResult, ...result?.data?.gridData]);
        setPaging(result?.data?.paging);
      }
    } catch (error) {
      console.log('error: ', error);
      setError(true);
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    setResultOption([]);
    const value = e.target.value;
    if (!value) {
      setVisible(false);
      return;
    }

    setVisible(true);
    setLoading(true);
    setResultOption([]);
    setValue(value);
    debounceCallApi(value);
  };

  const handleLoadMore = (page) => {
    callSearchApi({ value, page });
  };

  const focusInput = () => {
    if (inputRef.current)
      inputRef.current.focus({
        cursor: 'end',
      });
  };

  useEffect(() => {
    handleHideResultSearch();
  }, [visible]);

  useEffect(() => {
    if (searchBarVisible) focusInput();
  }, [searchBarVisible]);

  const renderResult = () => {
    const isHasMoreResult = paging.totalRecord > resultOption.length;

    if (loading)
      return (
        <WithLoading loading={true}>
          <div style={{ width: '100%', height: 70 }} />
        </WithLoading>
      );

    if (error)
      return (
        <Paragraph style={{ textAlign: 'center' }}>
          Cannot search message
        </Paragraph>
      );

    if (resultOption.length === 0)
      return (
        <Row justify='center'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Row>
      );

    return (
      <div className='scroller' style={{ maxHeight: 200 }}>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={handleLoadMore}
          hasMore={isHasMoreResult}
          useWindow={false}
          loader={
            <Row justify='center' key={0}>
              <WithLoading loading={true} tip='Loading...' size='small' />
            </Row>
          }
        >
          <List>
            {resultOption.map((item) => {
              return (
                <List.Item key={item.messageId}>
                  <SearchEmailResultItem
                    item={item}
                    groupUsers={groupUsers}
                    onClick={handleSelectResult}
                  />
                </List.Item>
              );
            })}
          </List>
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <Popover
      trigger='click'
      visible={visible}
      placement={'topLeft'}
      dropdownClassName='chat-search-message__result-options'
      overlayStyle={{ width: 290 }}
      content={renderResult()}
    >
      <Search
        value={value}
        bordered={false}
        size='large'
        style={{ width: 250 }}
        onChange={handleSearch}
        ref={inputRef}
      />
    </Popover>
  );
};

export default EmailSearchMessage;
