import React from 'react';
import { Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/common';

const FormCreateButton = (props) => {
  const { text, ...restProps } = props;
  return (
    <Button
      type='primary'
      style={{ borderRadius: '4px' }}
      icon={<FileAddOutlined />}
      {...restProps}
    >
      <span>{text || <FormattedMessage {...Messages.createBtn} />}</span>
    </Button>
  );
};

export default FormCreateButton;
