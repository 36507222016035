import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import {
  MinimumRequirementPropertiesInput,
  OpenUpdateMinimumRequirementButton,
} from 'common/components';

import * as minimumRequirementServices from 'services/minimumRequirements';

import { apiHandler } from 'utils/api';
import { sleep } from 'utils/delay';

import { getPropertiesParams } from 'utils/minimumRequirementUtils';

import { useGetBranding } from 'hooks';
import { useFetch } from 'hooks/useAsync';
import { useGetEntityProperties } from 'hooks/entityHooks';
import { useGetMinimumRequirementFieldSchema } from './useGetMinimumRequirementFieldSchema';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useFilterGrid } from 'hooks/useFilterGrid';

import { additionalRequirementData } from 'static/Constants';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/home';
import { covertNestedArrToSingleArr } from 'pages/minimum-requirements/components/modal/utils';
import { MINIMUM_REQUIREMENT_ENTITY_TYPE } from 'pages/minimum-requirements/constants';

export const useValidateMinimumRequirement = (props) => {
  const intl = useIntl();

  const { visible, closeModal, entityId, entityType, form, validateCallback } =
    props;

  const { gridFilters } = useFilterGrid({
    entityType: entityType?.toLowerCase()
      ? entityType?.toLowerCase()
      : MINIMUM_REQUIREMENT_ENTITY_TYPE.PRODUCT,
  });

  const [validateLoading, setValidateLoading] = useState(false);

  const [propertiesRequired, setPropertiesRequired] = useState([]);
  const [additionalRequirements, setAdditionalRequirements] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  //* generate field input
  useGetMinimumRequirementFieldSchema({
    propertiesRequired,
    setPropertiesRequired,
    entityType,
  });

  const { getBranding } = useGetBranding();

  const { getEntityProperties, fetchEntityProperties } = useGetEntityProperties(
    {
      entityType,
    }
  );
  const entityProperties = getEntityProperties();

  const { properties } = covertNestedArrToSingleArr(entityProperties);
  const reloadPage = useDispatchReloadPage();

  const {
    data: entityMinimumRequirement,
    run: runEntityMinimumRequirementDetail,
    status: fetchEntityMinimumRequirementStatus,
  } = useFetch();

  const entityMinimumRequirementData = entityMinimumRequirement?.data;

  const appliedTo = entityMinimumRequirementData?.appliedTo;

  const hasPropertiesRequirementError = propertiesRequired.some(
    (propertyItem) => propertyItem.checkStatus === 'error'
  );

  const fetchData = () => {
    if (!visible) return;

    fetchEntityProperties().then(() => {
      fetchEntityMinimumRequirementDetail();
    });
  };

  const fetchEntityMinimumRequirementDetail = () => {
    if (!visible) return;
    runEntityMinimumRequirementDetail(
      minimumRequirementServices.getEntityMinimumRequirementDetail({
        EntityId: entityId,
        EntityName: entityType,
      })
    );
  };

  const initRequirements = () => {
    if (fetchEntityMinimumRequirementStatus !== 'resolved') return;
    if (!entityProperties) return;
    if (!entityMinimumRequirementData) return;

    initRequiredProperties();
    initAdditionalProperties();
  };

  const initRequiredProperties = () => {
    setPropertiesRequired(
      entityMinimumRequirementData?.requiredProperties?.map((fieldName) => {
        const fieldData = properties?.find(
          (propertyItem) =>
            propertyItem.fieldFullPath === fieldName ||
            propertyItem?.fieldFullPath?.toLowerCase() ===
              fieldName?.toLowerCase()
        );

        return {
          ...fieldData,
          id: fieldName,
          fieldName,
          name: fieldData?.displayName || fieldName,
          checkStatus: null,
          errorComponent: MinimumRequirementPropertiesInput,
        };
      })
    );
  };

  const initAdditionalProperties = () => {
    const additionalRequirements = additionalRequirementData[entityType]?.map(
      (additionalRequirementItem) => {
        const value =
          entityMinimumRequirementData[additionalRequirementItem.fieldName];

        return {
          id: additionalRequirementItem.fieldName,
          name: additionalRequirementItem.fieldDisplayName,
          checkStatus: null,
          active: value !== null && value !== 0,
          value: additionalRequirementItem.isInput ? value : undefined,
          errorComponent: OpenUpdateMinimumRequirementButton,
        };
      }
    );
    setAdditionalRequirements(additionalRequirements);
  };

  const validateMinimumRequirements = async (
    { showMessage, shouldReload, shouldClose } = {
      showMessage: true,
      shouldReload: true,
      shouldClose: true,
    }
  ) => {
    if (!visible) return;
    updateValidateLoading(true);
    setSubmitDisabled(true);

    await sleep(1000);

    const params = {
      id: entityMinimumRequirementData?.id,
      idsToCheck: [entityId],
    };

    const successMessage = intl.formatMessage(
      messages.validateMinimumRequirementsSuccessMessage
    );
    const errorMessage = intl.formatMessage(
      messages.validateMinimumRequirementsErrorMessage
    );

    apiHandler({
      service: minimumRequirementServices.validateMinimumRequirement,
      params,
      successMessage,
      errorMessage,
      skipNotification: !showMessage,
      successCallback: validateSuccessCallback({ shouldReload, shouldClose }),
      onFinally: onValidateFinally,
    });
  };

  const updateSubmitDisabled = () => {
    if (hasPropertiesRequirementError) setSubmitDisabled(false);
  };

  const updateValidateLoading = (value) => {
    setValidateLoading(value);

    if (!value) return;

    const updateMinimumRequirementStateHandler =
      ({ requirementType }) =>
      (prev) => {
        return prev?.map((item) => {
          const showLoading =
            requirementType === 'properties' ||
            (requirementType === 'additional' && item.active);

          return {
            ...item,
            checkStatus: showLoading ? 'loading' : null,
          };
        });
      };

    setPropertiesRequired(
      updateMinimumRequirementStateHandler({ requirementType: 'properties' })
    );
    setAdditionalRequirements(
      updateMinimumRequirementStateHandler({ requirementType: 'additional' })
    );
  };

  const validateSuccessCallback =
    ({ shouldReload, shouldClose }) =>
    (data) => {
      const isMatched = data?.results[0]?.matched;
      const errorFields = data?.results?.[0]?.errorFields;
      setPropertiesRequired((prev) => {
        return prev?.map(updateValidateStatus({ errorFields }));
      });

      setAdditionalRequirements((prev) => {
        return prev?.map(
          updateValidateStatus({ errorFields, isAdditionalRequirements: true })
        );
      });

      validateCallback && validateCallback(data);

      if (!isMatched) return;

      if (shouldClose && closeModal) closeModal();

      if (!shouldReload) return;
      reloadData(errorFields);
    };

  const reloadData = (errorFields) => {
    if (!errorFields) return;
    if (errorFields?.length) return;
    //* re-fresh branding to update side nav
    getBranding();
    //* reload grid data
    if (gridFilters.includes('unmatched')) {
      reloadPage({ clearSelection: true });
    }
  };

  const updateValidateStatus =
    ({ errorFields, isAdditionalRequirements }) =>
    (item) => {
      const lowerCaseErrorFields = errorFields?.map((errorField) => {
        return errorField?.toLowerCase();
      });

      let itemFieldName = item?.id?.toLowerCase();

      itemFieldName =
        itemFieldName === 'member_memberbrands'
          ? 'member_brands'
          : itemFieldName;

      itemFieldName =
        itemFieldName === 'member_memberlogo' ? 'member_logo' : itemFieldName;

      const shouldValidate =
        !isAdditionalRequirements || (isAdditionalRequirements && item.active);

      return {
        ...item,
        checkStatus: shouldValidate
          ? lowerCaseErrorFields?.includes(itemFieldName)
            ? 'error'
            : 'success'
          : null,
      };
    };

  const onValidateFinally = async () => {
    updateValidateLoading(false);
  };

  const onSubmitRequiredPropertiesForm = () => {
    setSubmitLoading(true);
    form.validateFields().then((values) => {
      const entityNameCollection = {
        Product: 'PIM',
        Asset: 'DAM',
        Member: 'MEM',
      };

      const entityName = entityNameCollection[entityType];

      const params = {
        entityId,
        entityName,
        properties: getPropertiesParams(values),
      };

      const successMessage = intl.formatMessage(
        messages.updateUnmatchedRequirementSuccess
      );
      const errorMessage = intl.formatMessage(
        messages.updateUnmatchedRequirementError
      );

      apiHandler({
        service: minimumRequirementServices.updateUnmatchedRequirements,
        params,
        successMessage,
        errorMessage,
        successCallback: submitUpdateSuccessCallback,
        onFinally: onSubmitUpdateFinish,
      });
    });
  };

  const submitUpdateSuccessCallback = () => {
    form.resetFields();
    validateMinimumRequirements();
  };

  const onSubmitUpdateFinish = () => {
    setSubmitLoading(false);
  };

  //* USE EFFECT
  useEffect(() => {
    fetchData();
  }, [visible]);

  useEffect(() => {
    initRequirements();
  }, [JSON.stringify(entityMinimumRequirementData)]);

  useEffect(() => {
    if (fetchEntityMinimumRequirementStatus === 'resolved')
      validateMinimumRequirements({
        showMessage: false,
        shouldReload: false,
        shouldClose: false,
      });
  }, [fetchEntityMinimumRequirementStatus]);

  useEffect(() => {
    updateSubmitDisabled();
  }, [hasPropertiesRequirementError]);

  const hasAdditionalRequirementsError = additionalRequirements.some(
    (requirementItem) => {
      return requirementItem.checkStatus === 'error';
    }
  );

  return {
    fetchDataLoading:
      fetchEntityMinimumRequirementStatus === 'idle' ||
      fetchEntityMinimumRequirementStatus === 'pending',
    fetchEntityMinimumRequirementDetail,
    validateMinimumRequirements,
    appliedTo,
    propertiesRequired,
    additionalRequirements,
    validateLoading,
    onSubmitRequiredPropertiesForm,
    submitLoading,
    submitDisabled,
    hasAdditionalRequirementsError,
  };
};
