import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import './Icon.less';

const InfoIcon = () => {
  return (
    <div className='dialog-info-icon'>
      <div className='dialog-info-icon-success'>
        <CheckOutlined className='dialog-info-icon-success__icon' />
      </div>
    </div>
  );
};

export default InfoIcon;
