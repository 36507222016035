import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { CopyOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
const CloneQueryControl = ({ onClick }) => {
  const onClickBtn = () => {
    onClick && onClick();
  };

  return (
    <RibbonButton
      icon={<CopyOutlined />}
      label={Messages.reportingClone}
      onClick={onClickBtn}
    />
  );
};

CloneQueryControl.propTypes = {
  confirmEdit: PropTypes.func,
};

export default CloneQueryControl;
