import React from 'react';

import { RibbonBar } from "common/components"; 
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

const WorkflowAdministrationGridView = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection disabled />
      <ViewLayout disabled />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default WorkflowAdministrationGridView;
