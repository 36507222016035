import React from 'react';
import { InfoWithLabel } from 'common/components';
import { CheckCircleOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import reactCSS from 'reactcss';

const { Text } = Typography;

const styles = reactCSS({
  default: {
    wrapper: {
      textAlign: 'initial',
    },
    title: {
      textAlign: 'center',
      marginBottom: 18,
    },
    textTitle: {
      textAlign: 'center',
      fontSize: 22,
      color: '#000000d9',
    },
    errorMessage: {
      color: '#ff4d4f',
    },
    successColor: {
      color: '#1e8e3e',
    },
    errorColor: {
      color: '#ff4d4f',
    },
  },
});

const MOVE_ENTITIES_RESULT = {
  Products: 'Products',
  Users: 'Users',
  Assets: 'Assets',
  Addresses: 'Addresses',
  GLNs: 'GLNs',
  MoveSharedEntities: 'Move Shared Entities',
};

const MoveResults = (props) => {
  const { moveEntityResults } = props;

  const infoColProps = {
    infoColProps: {
      xs: 12,
    },
    labelColProps: {
      xs: 12,
    },
    labelAlign: 'right',
    infoTextStyle: {
      whiteSpace: 'break-spaces',
    },
    labelTextStyle: {
      fontWeight: 600,
    },
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.title}>
        <Text style={styles.textTitle}>
          Transferring process was completed!
        </Text>
      </div>

      {moveEntityResults?.length > 0 &&
        moveEntityResults.map((moveResultItem, index) => {
          const resultIcon = moveResultItem?.isSuccess ? (
            <div>
              <CheckCircleOutlined style={styles.successColor} />
              <Text type='success'>&nbsp;Success.</Text>
            </div>
          ) : (
            <div style={styles.errorMessage}>
              <CloseSquareOutlined style={styles.errorColor} />
              {moveResultItem?.message && (
                <Text type='danger'>&nbsp;{moveResultItem?.message}</Text>
              )}
            </div>
          );

          return (
            <InfoWithLabel
              key={index}
              info={resultIcon}
              label={
                MOVE_ENTITIES_RESULT[moveResultItem.name] || moveResultItem.name
              }
              {...infoColProps}
            />
          );
        })}
    </div>
  );
};

export default MoveResults;
