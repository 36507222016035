import {
  DAM_TO_PIM_ASSOCIATION_TYPE_FOR_IMAGE,
  DAM_TO_PIM_ASSOCIATION_TYPE_FOR_NON_IMAGE,
  PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO,
  PIM_TO_DAM_ASSOCIATION_TYPE_FOR_ADDITIONAL,
  PIM_TO_DAM_ASSOCIATION_TYPE_FOR_DOCUMENT,
  PIM_TO_DAM_ASSOCIATION_TYPE_FOR_MARKETING,
  PIM_ASSET_TYPE_DEFINE,
} from 'static/Constants';

export const mapToAssociationTypeOption = ({
  addType,
  isAssetImport,
  isAssetImportEdit,
  isImageType,
}) => {
  const [PLANO, ADD, MARKET, DOC] = PIM_ASSET_TYPE_DEFINE;

  // if (isAssetImportEdit || isAssetImport) {
    if (isImageType) {
      return DAM_TO_PIM_ASSOCIATION_TYPE_FOR_IMAGE;
    } else {
      return DAM_TO_PIM_ASSOCIATION_TYPE_FOR_NON_IMAGE;
    }
  // }

  // if (addType === PLANO) {
  //   return PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO;
  // } else if (addType === ADD) {
  //   return PIM_TO_DAM_ASSOCIATION_TYPE_FOR_ADDITIONAL;
  // } else if (addType === DOC) {
  //   return PIM_TO_DAM_ASSOCIATION_TYPE_FOR_DOCUMENT;
  // } else if (addType === MARKET) {
  //   return PIM_TO_DAM_ASSOCIATION_TYPE_FOR_MARKETING;
  // }
};
