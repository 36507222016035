import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFolderList = (state) => state.folderList || initialState;
const makeSelectLoading = () =>
  createSelector(selectFolderList, (folderState) => folderState.loading);
const makeSelectFolderList = () =>
  createSelector(selectFolderList, (folderState) => folderState.folders);
const makeSelectTotal = () =>
  createSelector(selectFolderList, (folderState) => folderState.total);
const makeSelectPageSize = () =>
  createSelector(selectFolderList, (folderState) => folderState.pageSize);
const makeSelectPageNumber = () =>
  createSelector(selectFolderList, (folderState) => folderState.pageNumber);
const makeSelectSearch = () =>
  createSelector(selectFolderList, (folderState) => folderState.search);
const makeSelectOwnedFolderPageSize = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.ownedFolderPageSize
  );
const makeSelectOwnedFolderPageNumber = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.ownedFolderPageNumber
  );
const makeSelectOwnedFolderSearch = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.ownedFolderSearch
  );
const makeSelectSharedFolderPageSize = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.sharedFolderPageSize
  );
const makeSelectSharedFolderPageNumber = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.sharedFolderPageNumber
  );
const makeSelectSharedFolderSearch = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.sharedFolderSearch
  );
const selectColumns = () =>
  createSelector(selectFolderList, (folderState) => folderState.columns);
const selectError = () =>
  createSelector(selectFolderList, (folderState) => folderState.error);

const selectDeleteFolders = () =>
  createSelector(selectFolderList, (folderState) => folderState.deleteFolders);

const selectCopyFolder = () =>
  createSelector(selectFolderList, (folderState) => folderState.copyFolder);

const selectPublicContact = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.publicContactList
  );

const selectFolderShortDetail = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.folderShortDetail
  );

const selectReloadContentPane = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.reloadContentPane
  );
const selectLoadingDetail = () =>
  createSelector(selectFolderList, (folderState) => folderState.loadingDetail);

const selectMemberLoading = () =>
  createSelector(selectFolderList, (folderState) => folderState.memberLoading);
const selectMemberPreview = () =>
  createSelector(selectFolderList, (folderState) => folderState.memberPreview);
const selectAssetLoading = () =>
  createSelector(selectFolderList, (folderState) => folderState.assetLoading);
const selectAssetPreview = () =>
  createSelector(selectFolderList, (folderState) => folderState.assetPreview);

const selectDownloadSetting = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.downloadSetting
  );

const selectIsEdit = () =>
  createSelector(selectFolderList, (folderState) => folderState.isEdit);
const selectStartEdit = () =>
  createSelector(selectFolderList, (folderState) => folderState.startEdit);
const selectEditSuccess = () =>
  createSelector(selectFolderList, (folderState) => folderState.editSuccess);
const selectEditError = () =>
  createSelector(selectFolderList, (folderState) => folderState.editError);
const selectConfigLoading = () =>
  createSelector(selectFolderList, (folderState) => folderState.configLoading);
const selectConfigError = () =>
  createSelector(selectFolderList, (folderState) => folderState.configError);

const selectFolderDetails = () =>
  createSelector(selectFolderList, (folderState) => folderState.folderDetails);
const selectQueryLoading = () =>
  createSelector(selectFolderList, (folderState) => folderState.queryLoading);
const selectQueryPreview = () =>
  createSelector(selectFolderList, (folderState) => folderState.queryPreview);
const selectReportingLoading = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.reportingLoading
  );
const selectReportingPreview = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.reportingPreview
  );

const selectProductPreview = () =>
  createSelector(selectFolderList, (folderState) => folderState.productPreview);
const selectProductLoading = () =>
  createSelector(selectFolderList, (folderState) => folderState.productLoading);

const selectSelectedMapping = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.selectedMapping
  );

const selectStatusDownloadFolder = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.statusDownloadFolder
  );

const selectFolderDetailData = () =>
  createSelector(
    selectFolderList,
    (folderState) => folderState.folderDetailData
  );

//
const selectFolderListByLevel = () =>
  createSelector(selectFolderList, (folderState) => folderState.folderByLevel);

export {
  selectFolderList,
  makeSelectLoading,
  makeSelectFolderList,
  makeSelectTotal,
  makeSelectPageSize,
  makeSelectPageNumber,
  makeSelectSearch,
  makeSelectOwnedFolderPageSize,
  makeSelectOwnedFolderPageNumber,
  makeSelectOwnedFolderSearch,
  makeSelectSharedFolderPageSize,
  makeSelectSharedFolderPageNumber,
  makeSelectSharedFolderSearch,
  selectColumns,
  selectError,
  selectDeleteFolders,
  selectFolderShortDetail,
  selectReloadContentPane,
  selectLoadingDetail,
  selectCopyFolder,
  selectMemberLoading,
  selectMemberPreview,
  selectAssetLoading,
  selectAssetPreview,
  selectIsEdit,
  selectStartEdit,
  selectEditSuccess,
  selectEditError,
  selectDownloadSetting,
  selectConfigLoading,
  selectConfigError,
  selectPublicContact,
  selectFolderDetails,
  selectQueryLoading,
  selectQueryPreview,
  selectReportingLoading,
  selectReportingPreview,
  selectProductPreview,
  selectProductLoading,
  selectSelectedMapping,
  selectStatusDownloadFolder,
  selectFolderDetailData,
  selectFolderListByLevel,
};
