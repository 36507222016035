export const mappingSelectedItems = (selectedItems, brokerList) => {
  const listSelectedId = selectedItems?.map((item) => item.id);
  const mappingBrokerList = brokerList?.map((item) => {
    if (listSelectedId?.includes(item.id)) {
      return { ...item, selected: true };
    }
    return { ...item };
  });

  return mappingBrokerList;
};

export const removeImpersonateLevelItems = (state, entity, removeCase) => {
  if (entity === 'member') return [];
  
  const { brandData, productData, selectedEntityLevel } = state;

  const selectedProductId = selectedEntityLevel.map((item) => item.id);

  const impersonateLevelData = entity === 'product' ? productData : brandData;

  const removeSelectedItems = impersonateLevelData.filter(
    (item) => !selectedProductId.includes(item.id)
  );

  return removeCase === 'all' ? [] : removeSelectedItems;
};

export const mappingId = (array) => {
  return array?.map((item) => item.id);
};
