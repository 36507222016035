import React from 'react';

import { Form, Button, Col, Row } from 'antd';

import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { FaRegCopy } from 'react-icons/fa';

import { FormAddButton, dialogFunction } from 'common/components';

import {
  HeaderGroupElement,
  GroupElementWrapper,
  FormItemList,
  FormItemContainer,
  LabelFormItem,
  FormItemText,
  FormItemNumber,
  FormItemBoolean,
  FormItemSelectSpecialEnum,
  FormItemSelectEnum,
  FormItemDatetime,
  FormItemAllergenBoolean,
  FormItemAllergenSelectEnum,
} from '../../shared/components';

import FormSingleNestedValue from './FormSingleNestedValue';

import {
  useProductDetail,
  useProductDetailDispatch,
  productDetailActionTypes,
} from './ProductDetailContext';

import {
  getIdxCopiedField,
  findProductPropertyEnums,
  checkTypeOfFormNested,
  getFormNameItem,
  generateNestedIdxFormElement,
  getFieldValueFromListIdx,
  checkIsValidObjectValues,
  checkDisabledDeleteFormField,
} from './utils';
import { sleep } from 'utils/delay';

import { NUMERIC_TYPE } from 'static/Constants';
import {
  SPECIAL_ENUMS,
  LIST_BOOLEAN_PROPERTY_NAME_NEED_TO_DISABLE,
  LIST_PROPERTY_NAME_NEED_TO_DISABLE_COPY_ACTION,
  LIST_ALLERGEN_PROPERTY_NAME_CHECKABLE,
} from 'pages/product-full-view/constants';

const FormMultipleNestedValue = ({
  properties,
  name,
  label,
  isArray,
  property,
  parentNestedIndex = [],
  productEnums = [],
  handleValidate,
  deleteFieldForm,
  ...otherProps
}) => {
  const { formInstance } = useProductDetail();
  const dispatch = useProductDetailDispatch();

  const { propertyName: parentPropertyName } = property;

  const renderItem = ({ property, indexField, parentNestedIndex }) => {
    const {
      propertyType,
      propertyName,
      propertyDisplayName,
      fieldFullPath,
      isArray,
      childProperties,
      listName,
    } = property;

    const nameFormItem = getFormNameItem(indexField, propertyName);

    const { isSingleNestedValue, isMultipleNestedValue } =
      checkTypeOfFormNested(childProperties, isArray);

    if (isSingleNestedValue) {
      const singleParentNestedIndex = parentNestedIndex.concat(propertyName);

      return (
        <FormSingleNestedValue
          name={nameFormItem}
          property={property}
          productEnums={productEnums}
          parentNestedIndex={singleParentNestedIndex}
          handleValidate={handleValidate}
          deleteFieldForm={deleteFieldForm}
        />
      );
    }

    if (isMultipleNestedValue) {
      return (
        <FormMultipleNestedValue
          properties={childProperties}
          name={nameFormItem}
          isArray={isArray}
          label={propertyDisplayName}
          property={property}
          parentNestedIndex={parentNestedIndex}
          productEnums={productEnums}
          handleValidate={handleValidate}
          deleteFieldForm={deleteFieldForm}
        />
      );
    }

    if (listName) {
      const propertyEnums = findProductPropertyEnums(listName, productEnums);

      if (SPECIAL_ENUMS.includes(listName)) {
        return (
          <FormItemSelectSpecialEnum
            property={property}
            nameFormItem={nameFormItem}
            propertyEnums={propertyEnums}
            labelCol={{ span: 6 }}
          />
        );
      }

      if (LIST_ALLERGEN_PROPERTY_NAME_CHECKABLE.includes(fieldFullPath)) {
        return (
          <FormItemAllergenSelectEnum
            labelCol={{ span: 6 }}
            property={property}
            nameFormItem={nameFormItem}
            propertyEnums={propertyEnums}
            parentNestedIndex={parentNestedIndex}
          />
        );
      }
      return (
        <FormItemSelectEnum
          property={property}
          nameFormItem={nameFormItem}
          propertyEnums={propertyEnums}
          labelCol={{ span: 6 }}
        />
      );
    }

    if (isArray) {
      if (NUMERIC_TYPE.includes(property.propertyType)) {
        return (
          <FormItemList
            property={property}
            name={nameFormItem}
            className='product-detail__form-list'
            variant='number'
          />
        );
      } else if (property.propertyType === 'datetime') {
        return (
          <FormItemList
            property={property}
            name={nameFormItem}
            className='product-detail__form-list'
            variant='datetime'
          />
        );
      } else {
        return (
          <FormItemList
            property={property}
            name={nameFormItem}
            className='product-detail__form-list'
            variant='text'
          />
        );
      }
    }

    if (propertyType === 'string') {
      return (
        <FormItemText
          property={property}
          name={nameFormItem}
          labelCol={{ span: 6 }}
        />
      );
    }

    if (NUMERIC_TYPE.includes(propertyType)) {
      return (
        <FormItemNumber
          name={nameFormItem}
          property={property}
          labelCol={{ span: 6 }}
        />
      );
    }

    if (propertyType === 'datetime') {
      return (
        <FormItemDatetime
          name={nameFormItem}
          property={property}
          labelCol={{ span: 6 }}
        />
      );
    }

    if (propertyType === 'boolean') {
      const shouldCheckDisabled =
        LIST_BOOLEAN_PROPERTY_NAME_NEED_TO_DISABLE.includes(propertyName);

      if (shouldCheckDisabled) {
        return (
          <FormItemAllergenBoolean
            parentNestedIndex={parentNestedIndex}
            name={nameFormItem}
            property={property}
            labelCol={{ span: 6 }}
          />
        );
      }

      return (
        <FormItemBoolean
          name={nameFormItem}
          property={property}
          labelCol={{ span: 6 }}
        />
      );
    }

    // Default display text input for catching bug from incomplete BE data
    return (
      <FormItemText
        property={property}
        name={nameFormItem}
        labelCol={{ span: 6 }}
      />
    );
  };

  const handleDeleteFieldForm = ({ idxFormElement, field, removeCallback }) => {
    const formValues = formInstance.getFieldsValue();
    const fieldFormValues = getFieldValueFromListIdx(
      formValues,
      idxFormElement
    );
    const isValidValues = checkIsValidObjectValues(fieldFormValues);

    // Only show confirm modal when field has any data
    if (isValidValues) {
      dialogFunction({
        type: 'warn',
        content: 'Are you sure you want to delete selected property?',
        okText: 'Delete',
        okButtonProps: {
          type: 'danger',
        },
        onOk: async () => {
          removeCallback(field.name);
          await sleep(1000);
          handleValidate(formValues);
          deleteFieldForm({ fieldPath: idxFormElement, fieldFormValues });
        },
      });
    } else {
      removeCallback(field.name);
    }
  };

  return (
    <Form.List name={name} {...otherProps}>
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map((field, index) => {
              return (
                <Row
                  style={{
                    marginTop: 20,
                    position: 'relative',
                  }}
                  wrap={false}
                  key={field.name}
                >
                  <GroupElementWrapper
                    flex='auto'
                    style={{ marginLeft: 24, marginBottom: 8 }}
                    className='product-detail__group-element'
                  >
                    <HeaderGroupElement
                      header={`${label} ${index + 1}`}
                      className='product-detail__group-header'
                    />

                    {properties.map((property) => {
                      if (property?.childProperties?.length > 0) {
                        return (
                          <FormItemContainer
                            {...field}
                            key={[field.name, property.propertyName]}
                            fieldKey={[field.fieldKey, property.propertyName]}
                          >
                            {renderItem({
                              property,
                              indexField: [field.name],
                              parentNestedIndex: generateNestedIdxFormElement({
                                parentNestedIndex,
                                propertyName: parentPropertyName,
                                field,
                              }),
                            })}
                          </FormItemContainer>
                        );
                      }

                      return (
                        <React.Fragment
                          key={[field.name, property.propertyName]}
                        >
                          {renderItem({
                            property,
                            indexField: [field.name],
                            parentNestedIndex: generateNestedIdxFormElement({
                              parentNestedIndex,
                              propertyName: parentPropertyName,
                              field,
                            }),
                          })}
                        </React.Fragment>
                      );
                    })}
                  </GroupElementWrapper>

                  {fields.length > 0 ? (
                    <Col
                      flex='0 0 auto'
                      style={{
                        marginLeft: 4,
                        marginRight: 4,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Button
                        type='danger'
                        onClick={() => {
                          handleDeleteFieldForm({
                            idxFormElement: generateNestedIdxFormElement({
                              parentNestedIndex,
                              propertyName: parentPropertyName,
                              field,
                            }),
                            field,
                            removeCallback: remove,
                          });
                        }}
                        icon={<CloseCircleOutlined />}
                        className='product-detail__btn-delete'
                        disabled={checkDisabledDeleteFormField({
                          totalFields: fields.length,
                          fieldFullPath: property.fieldFullPath,
                        })}
                      />

                      <Button
                        type='primary'
                        className='product-detail__btn-copy'
                        icon={<FaRegCopy />}
                        disabled={LIST_PROPERTY_NAME_NEED_TO_DISABLE_COPY_ACTION.includes(
                          property.propertyName
                        )}
                        onClick={() => {
                          const idxCopyField = getIdxCopiedField({
                            parentNestedIndex,
                            parentPropertyName,
                            field,
                          });
                          dispatch({
                            type: productDetailActionTypes.COPY_FIELD_FORM,
                            payload: {
                              formInstance,
                              idxCopyField,
                            },
                          });
                        }}
                      />
                    </Col>
                  ) : null}
                </Row>
              );
            })}

            <Row className='product-detail__multiple-form-container'>
              <Col
                span={6}
                style={{
                  padding: '4px 0px',
                  textAlign: 'right',
                }}
                className='product-detail__label-form'
              >
                <LabelFormItem
                  label={property.propertyDisplayName}
                  tooltip={property.propertyDescription}
                  fieldFullPath={property.fieldFullPath}
                />
              </Col>

              <FormAddButton
                type='secondary'
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={isArray ? false : fields.length > 0}
                text='Add value'
              />
            </Row>

            {errors.length > 0 && (
              <Row>
                <Col offset={6} span={18}>
                  {errors[0]}
                </Col>
              </Row>
            )}
          </>
        );
      }}
    </Form.List>
  );
};
export default FormMultipleNestedValue;
