import React from 'react';
import { useSelector } from 'react-redux';

import { VideoCameraOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import RibbonButton from 'common/components/button/RibbonButton';
import Messages from 'i18n/messages/home';
import GeneratePreview from '../../../controls/asset/GeneratePreview';
import DeletePreview from '../../../controls/asset/DeletePreview';
import ReplacePreviewGrid from '../../../controls/asset/ReplacePreviewGrid';

import GridViewSelector from 'common/components/grid-view/controllers/selectors';

import {
  useCheckIsSuperAdmin,
  usePermissionEditSharedAsset,
  usePermissionEditAsset,
} from 'hooks';

import './PreviewSection.less';

const { Text } = Typography;

const PreviewSection = () => {
  const currentSelectedAssets = useSelector(
    GridViewSelector.makeSelectDetailCurrentITemsSelection()
  );

  const hasPermissionEditSharedAsset = usePermissionEditSharedAsset();
  const hasPermissionEditAsset = usePermissionEditAsset();

  const isSuperAdmin = useCheckIsSuperAdmin();

  const isPreviewSectionActions =
    hasPermissionEditAsset || hasPermissionEditSharedAsset || isSuperAdmin;

  const isExistIframe = currentSelectedAssets?.find(
    (asset) => asset?.isIFrame === true
  )
    ? true
    : false;

  const menuOptions = (
    <Menu>
      <Menu.Item key='generatePreview' style={{ backgroundColor: 'inherit' }}>
        <GeneratePreview
          disabledPreview={isExistIframe || !isPreviewSectionActions}
          currentSelectedAssets={currentSelectedAssets}
        />
      </Menu.Item>
      <Menu.Item key='replacePreview' style={{ backgroundColor: 'inherit' }}>
        <ReplacePreviewGrid
          sectionDisabled={currentSelectedAssets?.length !== 1 || isExistIframe}
          isIframe={isExistIframe}
          digitalAssetData={
            currentSelectedAssets?.length > 0 && currentSelectedAssets[0]
          }
        />
      </Menu.Item>
      <Menu.Item key='deleteAsset' style={{ backgroundColor: 'inherit' }}>
        <DeletePreview
          isGrid={true}
          currentSelectedAssets={currentSelectedAssets}
          typeView='grid'
          sectionDisabled={
            !isPreviewSectionActions || currentSelectedAssets?.length === 0
          }
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
      <RibbonButton
        icon={<VideoCameraOutlined />}
        label={Messages.mediaPreview}
      ></RibbonButton>
    </Dropdown>
  );
};

export default PreviewSection;
