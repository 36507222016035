import moment from 'moment';

export const dateFilterComparator = (filterLocalDateAtMidnight, cellValue) => {
  var dateAsString = moment(cellValue).format('YYYY/MM/DD');

  if (dateAsString == null) {
    return 0;
  }

  var dateParts = dateAsString.split('/');
  var day = Number(dateParts[2]);
  var month = Number(dateParts[1]) - 1;
  var year = Number(dateParts[0]);
  var cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};
