import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Divider, Button, Typography } from 'antd';
import { CloseModalBtn } from 'common/components';

import { MdTextFont, MdToolTextColor } from '../../../customToolbar/components';
import './MarkdownAddParagraph.less';

const { TextArea } = Input;
const { Text } = Typography;

const newLineRegex = /\r\n|\r|\n/;
const multipleNewLineRegex = /\r\n{2}|\r{2}|\n{2}/;

const initState = {
  color: '',
  content: '',
  fontType: '',
  id: null,
};

const MarkdownAddParagraph = (props) => {
  const { onAddParagraph, onUpdateParagraph, onClose, edittingData } = props;
  const [fontType, setFontType] = useState(initState?.fontType);
  const [color, setColor] = useState(initState?.color);
  const [content, setContent] = useState(initState?.content);
  const [id, setId] = useState(initState?.id);

  const isValidContent = (text) => {
    const match = newLineRegex.exec(text);
    const multiMatch = multipleNewLineRegex.exec(text);
    if (multiMatch?.length > 0 || !content) return false;
    return true;
  };

  const onChangeContent = (e) => {
    setContent(e.target.value);
  };

  const handleClickOk = () => {
    !edittingData &&
      onAddParagraph &&
      onAddParagraph({
        font: fontType,
        color: color,
        content,
      });

    edittingData &&
      onUpdateParagraph &&
      onUpdateParagraph({
        font: fontType,
        color: color,
        content,
        id: id,
      });

    onClose && onClose();
  };

  useEffect(() => {
    if (edittingData) {
      setContent(edittingData?.content);
      setFontType(edittingData?.font);
      setColor(edittingData?.color);
      setId(edittingData?.id);
    } else {
      setContent(initState?.content);
      setFontType(initState?.fontType);
      setColor(initState?.color);
      setId(initState?.id);
    }
  }, [edittingData]);

  return (
    <div className='markdown-add-paragraph'>
      <CloseModalBtn onClick={onClose} />
      <Row>
        <MdToolTextColor textColor={color} setTextColor={setColor} />
        <Col>
          <Divider type='vertical' style={{ height: '100%' }} />
        </Col>
        <MdTextFont fontType={fontType} handleChangeFontType={setFontType} />
      </Row>
      <Row>
        <Col xs={24}>
          <TextArea
            className='markdown-add-paragraph__textarea'
            style={{
              fontFamily: fontType,
              color: color,
            }}
            value={content}
            onChange={onChangeContent}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col>
          {isValidContent(content) ? null : (
            <Text className='markdown-add-paragraph__warning'>
              * Empty/Invalid Content
            </Text>
          )}
        </Col>
        <Col flex='auto' />
        <Col>
          <Button
            className='markdown-add-paragraph__btn-ok'
            type='primary'
            disabled={!isValidContent(content)}
            onClick={handleClickOk}
            danger
          >
            Ok
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MarkdownAddParagraph;
