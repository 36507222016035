import { useMutation } from '@tanstack/react-query';
import * as memberServices from 'services/members';

import * as memberMaintainServices from 'services/memberMaintenance';

export const useMoveAllToMember = () => {
  return useMutation({
    mutationFn: async ({
      sourceMemberId,
      targetMemberId,
      entityTypesForMoving,
    }) => {
      const { isSuccess, message, data } =
        await memberMaintainServices.moveAllToMember({
          sourceMemberId,
          targetMemberId,
          entityTypesForMoving,
        });

      if (isSuccess) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(message || 'Server Error');
      }
    },
  });
};

export const useGetSearchedMembersForMoveAll = () => {
  return useMutation({
    mutationFn: async (params) => {
      const response = await memberServices.getAllMemberForReassign(params);

      const { isSuccess, message, data } = response || {};

      if (isSuccess) {
        return Promise.resolve(data || {});
      } else {
        return Promise.reject(message || 'Sever Error');
      }
    },
  });
};
