import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import FolderPanelContent from './FolderPanelContent';
import FolderPanelHeader from './FolderPanelHeader';
import FolderPanelDownload from './FolderPanelDownload';

import './FolderPanel.less';

const FolderPanel = (props) => {
  const downloadPanelActions = useRef();

  const {
    onClosePanel,
    shortDetail,
    ownerFolder,
    seePreview,
    onEditClick,
    clickEditFolderHandler,
  } = props;

  const openDownloadPanel = () => {
    downloadPanelActions.current?.openDownloadPanel();
  };

  return (
    <div className='folder-panel__wrapper'>
      {shortDetail && (
        <>
          <div className='folder-panel'>
            <FolderPanelHeader
              clickEditFolderHandler={clickEditFolderHandler}
              ownerFolder={ownerFolder}
              onClosePanel={onClosePanel}
              shortDetail={shortDetail}
              onEditClick={onEditClick}
              openDownloadPanel={openDownloadPanel}
            />
            <FolderPanelContent
              isEdit={false}
              ownerFolder={ownerFolder}
              shortDetail={shortDetail}
              seePreview={seePreview}
            />
          </div>
          <FolderPanelDownload
            ref={downloadPanelActions}
            folder={shortDetail}
          />
        </>
      )}
    </div>
  );
};

FolderPanel.propTypes = {
  onTrigger: PropTypes.func,
  onClosePanel: PropTypes.func,
  loadingDetail: PropTypes.bool,
  shortDetail: PropTypes.object,
  ownerFolder: PropTypes.bool,
  seePreview: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default FolderPanel;
