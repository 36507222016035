import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { Typography, Row, Col, Input } from 'antd';

import { WithQaGridHeader } from 'pages/qa-spec/components';
import {
  Form,
  FormAddButton,
  FormDeleteButton,
  CustomNotification,
  WithLoading,
  StyledModal,
} from 'common/components';

import { sleep } from 'utils/delay';
import { useMutationIngredients } from './hook';
import { useGetEnumOptions } from 'pages/qa-spec/hooks';
import { coverIngredientStatementsData, createIngredientParams } from './utils';
import { useTabExpand } from 'hooks/useTabExpandProduct';

import { useGetIngredientGridHandlers } from 'pages/qa-spec/hooks/useIngredient';
import {
  validateIngredientGrid,
  getIngredientPercentTotal,
  validateWholeIngredientGrid,
  mappingInitialIngredientList,
} from 'pages/qa-spec/utils/ingredient';

import { INGREDIENT_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';
import { useReloadDocumentCertificateTab } from 'hooks/useReloadDocumentCertificateTab';
import { useSyncProductModules } from 'pages/product-full-view/components/product-new-detail-view/hooks';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';
import QaIngredientGrid from './QaIngredientGrid';

import { changeBreakout } from 'pages/product-full-view/components/product-media-area/multiple-panel/tab-ingredients/utils';

import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import { REQUIRED_FIELDS_QA_INGREDIENT } from './requiredFieldsQaIngredient';

import './QaSpecIngredient.less';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const { Title, Text } = Typography;

const IngredientForm = ({ visible, setVisible, ingredientsMode, data }) => {
  const { id: productId } = useParams();
  const [total, setTotal] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const gridInst = useRef();
  const [formInst] = Form.useForm();
  const { tableStyle } = useTabExpand();

  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  const { reloadDocumentCertificateTab } = useReloadDocumentCertificateTab();

  const { handleSyncIngredients } = useSyncProductModules();
  const { mutation } = useMutationIngredients({ productId });

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const isRequiredFieldForSupplier = Boolean(isProductAssignedByRetailer);

  const ingredientData = useMemo(() => {
    const total = getIngredientPercentTotal(data?.ingredients || []);
    setTotal(total);

    return {
      ingredientList: mappingInitialIngredientList(data?.ingredients) || [],
      ingredientForm: {
        ingredientStatement: data?.currentIngredient?.ingredients,
        ingredientClaims: data?.ingredientClaims,
      },
    };
  }, [data]);

  const ingredientOptions = useGetEnumOptions()?.ingredient;

  const {
    gridRequiredFields,
    getRequiredFieldsMessage,
    onCellEditingStopped,
    handleAddChildIngredient,
    handleAddIngredient,
    handleDeleteIngredient,
  } = useGetIngredientGridHandlers(gridInst, setTotal, productId);

  const checkFieldRequired = (fieldName) => {
    if (!requiredFields) return false;

    if (!isRequiredFieldForSupplier) return false;

    return requiredFields.includes(
      `qaSpecification.qaSpecIngredients.${fieldName}`
    );
  };

  const onGridReady = (params) => {
    getRequiredFieldsMessage();
  };

  const onRowSelected = () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();
    setSelectedRows(selectedRows);
  };

  const handleCancel = async () => {
    setVisible(false);
  };

  const handleCreateParams = () => {
    const { ingredientStatement } = formInst.getFieldsValue();

    const ingredientStatements = coverIngredientStatementsData({
      data,
      currentIngredient: data?.currentIngredient,
      ingredientStatementValue: changeBreakout(ingredientStatement),
    });

    const submitValue = createIngredientParams({ formInst, gridInst });

    const params = {
      productId,
      ...submitValue,
      ingredientStatements: ingredientStatements || [
        ...data?.ingredientStatements,
      ],
    };

    return params;
  };

  const handleSave = async (ixoneIgnore) => {
    gridInst.current.api.stopEditing();

    await formInst.validateFields();

    await sleep(300);

    setSelectedRows([]);
    validateWholeIngredientGrid(gridInst, isProductAssignedByRetailer);
    const params = handleCreateParams();

    if (
      isRequiredSomeFieldsIngredientGrid &&
      params?.ingredients?.length <= 0
    ) {
      CustomNotification.error('Please add at least one row data to save');
      return;
    }

    const isGridHaveError =
      validateIngredientGrid(gridInst).checkIngredientGridHaveError();

    if (isGridHaveError) {
      CustomNotification.error(`Please resolve Ingredient grid's errors`);
      return;
    }

    mutation.mutate(params, {
      onSuccess: async (result) => {
        if (result?.isSuccess) {
          if (ingredientsMode === 'add')
            CustomNotification.success('Add Ingredients successfully');
          else
            CustomNotification.success('Update Ingredient data successfully!');
          reloadDocumentCertificateTab();
          handleSyncIngredients(productId);
          await handleUpdateIXONEShield(ixoneIgnore);
          setVisible(false);
        } else {
          CustomNotification.error(
            result?.message ?? 'Failed to update Ingredient data'
          );
        }
      },
      onError: (error) => {
        CustomNotification.error('Something went wrong!');
      },
    });
  };

  useEffect(() => {
    formInst.setFieldsValue(ingredientData?.ingredientForm);
  }, [JSON.stringify(ingredientData)]);

  const modalProps = {
    title: ingredientsMode === 'add' ? 'Add Ingredient' : 'Edit Ingredient',
    visible: visible,
    width: '60%',
    closable: true,
    maskClosable: false,
    onCancel: handleCancel,
    wrapClassName: 'ingredient-modal',
    okText: 'Submit',
  };

  const isRequiredSomeFieldsIngredientGrid =
    isRequiredFieldForSupplier && gridRequiredFields.length > 0;

  const ingredientGridTitle = (
    <Title level={5} strong style={{ marginBottom: 0 }}>
      {isRequiredSomeFieldsIngredientGrid && (
        <>
          <Text type='danger'>*</Text>{' '}
        </>
      )}
      <Text>LIST INGREDIENTS IN DECLINING ORDER OF PERCENTAGE (</Text>
      <Text italic>
        include sub-ingredients, components of ingredients, additives, etc.).
        For ingredients purchased from multiple vendors, list each vendor within
        its own row.
      </Text>
    </Title>
  );

  const ingredientStatement = Form.useWatch('ingredientStatement', formInst);

  const showConfirmIXONEModal = (() => {
    return (
      JSON.stringify(changeBreakout(ingredientStatement)) !==
      JSON.stringify(data?.currentIngredient?.ingredients)
    );
  })();

  return (
    <IXONECertificationWrapper
      showModal={showConfirmIXONEModal}
      type={TYPE_VIEW.INGREDIENTS}
      onConfirm={(ixoneIgnore) => {
        handleSave(ixoneIgnore);
      }}
    >
      {(handleConfirmIXONE) => (
        <StyledModal
          {...modalProps}
          loadingContent={{ loadingText: 'Loading' }}
          confirmLoading={mutation?.isLoading}
          onOk={handleConfirmIXONE}
        >
          <Form
            className='qa-ingredient'
            form={formInst}
            style={{ paddingRight: 5 }}
            layout='vertical'
          >
            <>
              <WithLoading loading={mutation?.isLoading}>
                <Row
                  className={classnames('qa-ingredient__input', {
                    'qa-ingredient__input--edit': true,
                  })}
                  justify='space-between'
                  style={{ marginBottom: 20 }}
                >
                  <Col flex={1}>
                    <Form.Item
                      name='ingredientStatement'
                      label={
                        <Typography.Text strong>
                          Ingredient Statement
                        </Typography.Text>
                      }
                      rules={[
                        {
                          required: checkFieldRequired('ingredientStatement'),
                          message:
                            REQUIRED_FIELDS_QA_INGREDIENT.ingredientStatements
                              .message,
                        },
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        maxLength={
                          INGREDIENT_LIMIT_CHARACTERS.ingredientStatement
                        }
                        showCount
                      />
                    </Form.Item>
                  </Col>
                  <Col flex='50px' />
                  <Col flex={1}>
                    <Form.Item
                      name='ingredientClaims'
                      label={
                        <Typography.Text strong>
                          Ingredient Claims
                        </Typography.Text>
                      }
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        maxLength={INGREDIENT_LIMIT_CHARACTERS.ingredientClaims}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ ...tableStyle }}>
                  <Col xs={24}>
                    <WithQaGridHeader
                      forceEdit={true}
                      showSaveBtn={false}
                      showCancelBtn={false}
                      showEditBtn={false}
                      title={ingredientGridTitle}
                      buttons={[
                        () => (
                          <FormAddButton
                            text='Add new ingredient'
                            onClick={handleAddIngredient}
                          />
                        ),
                        () => (
                          <FormAddButton
                            text='Add new ingredient part of'
                            onClick={handleAddChildIngredient}
                            disabled={selectedRows?.length !== 1}
                          />
                        ),
                        () => (
                          <FormDeleteButton
                            onClick={handleDeleteIngredient}
                            disabled={selectedRows?.length === 0}
                          />
                        ),
                      ]}
                    >
                      {(isEdit) => {
                        return (
                          <QaIngredientGrid
                            gridInst={gridInst}
                            data={ingredientData.ingredientList}
                            isEdit={isEdit}
                            onCellEditingStopped={onCellEditingStopped}
                            ingredientOptions={ingredientOptions}
                            onRowSelected={onRowSelected}
                            onReady={onGridReady}
                          />
                        );
                      }}
                    </WithQaGridHeader>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col>
                    <Title strong level={5}>
                      Total: {total}%
                    </Title>
                  </Col>
                </Row>
              </WithLoading>
            </>
          </Form>
        </StyledModal>
      )}
    </IXONECertificationWrapper>
  );
};

export default IngredientForm;
