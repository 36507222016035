import React from 'react';

import { Input } from 'antd';

const ValueFormItemInput = (props) => {
  const { value, onChange, onChangeValue, dataPointId, isLock } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    if (isLock && dataPointId && onChangeValue) {
      onChangeValue(dataPointId, value);
    } else {
      onChange(e);
    }
  };
  return <Input value={value} onChange={handleChange} />;
};

export default ValueFormItemInput;
