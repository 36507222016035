import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, Provider } from 'react-redux';
import * as globalActions from 'redux/global/actions';
import { Typography } from 'antd';
import { MdEditorCustomToolbar, dialogFunction } from 'common/components';
import ReactDOM from 'react-dom';
import MarkdownEditor from '@uiw/react-markdown-editor';

import MdEntityTool from './customToolbar/components/entity/MdEntityTool';
import MdInterEntityTool from './customToolbar/components/entity/MdInterEntityTool';

import store from 'index';
import * as _ from 'lodash';

import './EnhancedMarkdownEditor.less';

const interactSyntaxRegex = /\[@\[.*\]\]/;
const newSpanConfigDetect = /\(\#(.*?)(color=|font=|size=)(.*?)\)/g;
const newParagphConfigDetect = /\(\!p.*\)/g;
const newSpanConfigTailDetect = /\(~\)/g;

const { Title } = Typography;

const EnhancedMarkdownEditor = (props) => {
  const {
    height,
    //* customToolbar
    setMediaOpen,
    isMediaOpenSelector,
    markdownRefSelector,
    markdownRefUpdateAction,
    //* interact with markdown content
    markdownContentId,
    //* editor
    onChangeContent,
    value,
    toolList = ['text', 'media', 'table'],
    entityName,
    ...restProps
  } = props;
  let { mdInstance } = props;

  const [entityOpen, setEntityOpen] = useState(false);
  const [interEntityOpen, setInterEntityOpen] = useState(false);
  const [isAddedScrollHandler, setIsAddedScrollHandler] = useState(false);
  const [isEntityInsideTable, setIsEntityInsideTable] = useState(false);

  const refEnhancedMarkdownEditor = useRef(null);

  const dispatch = useDispatch();

  const __INTERNAL__ = mdInstance?.__INTERNAL__;
  const itemRef = __INTERNAL__?.itemRef;

  const refMdEditor = useRef(null);
  const tempMdTableData = useRef({});

  const addCustomToolbar = () => {
    const mdEditor = refMdEditor.current?.CodeMirror?.editor;
    if (mdEditor) {
      const $existedCustomToolbar = document.getElementById(
        'md-editor-custom-toolbar'
      );
      //?remove if existed
      if ($existedCustomToolbar) {
        $existedCustomToolbar.remove();
      }

      let $mdToolbar = document.querySelectorAll('.md-editor-toolbar')[1];
      let $customToolbar = document.createElement('div');
      $customToolbar.setAttribute('id', 'md-editor-custom-toolbar');
      $mdToolbar.appendChild($customToolbar);

      ReactDOM.render(
        <Provider store={store}>
          <MdEditorCustomToolbar
            mdEditor={refMdEditor.current?.CodeMirror?.editor}
            setMediaOpen={setMediaOpen && setMediaOpen}
            isMediaOpenSelector={isMediaOpenSelector && isMediaOpenSelector}
            markdownRefSelector={markdownRefSelector && markdownRefSelector}
            markdownRefUpdateAction={
              markdownRefUpdateAction && markdownRefUpdateAction
            }
            toolList={toolList}
            setEntityOpen={setEntityOpen}
            setInterEntityOpen={setInterEntityOpen}
            setIsEntityInsideTable={setIsEntityInsideTable}
            tempMdTableData={tempMdTableData}
          />
        </Provider>,
        document.getElementById('md-editor-custom-toolbar')
      );
      $customToolbar.classList.add('md-editor-custom-toolbar__wrapper');
    }
  };

  const handleChangeContent = (editor, __, value) => {
    onChangeContent && onChangeContent(value);
    debounceNewStyle(value);
    if (markdownContentId && refMdEditor.current) {
      const mdEditor = refMdEditor.current?.CodeMirror?.editor;
      if (mdEditor.doc.getCursor()?.line === mdEditor.doc.lineCount() - 1) {
        setTimeout(() => {
          scrollToNewLine();
        }, 400);
      }
    }
  };

  const resetMediaAssetCallback = useCallback(() => {
    dispatch(
      globalActions.getMediaAssetFileSuccess({
        data: [],
        pageNumber: null,
        totalRecord: null,
      })
    );
  }, [dispatch]);

  const scrollToNewLine = (line) => {
    if (markdownContentId) {
      const $markdownContentView = document.querySelector(
        `#${markdownContentId}`
      );
      if ($markdownContentView) {
        if (line === undefined) {
          $markdownContentView.scrollTop = $markdownContentView.scrollHeight;
        } else if (typeof line === 'number') {
          $markdownContentView.scrollTop = line;
        }
      }
    }
  };

  const linkStyle = () => {
    const $cmLink =
      refEnhancedMarkdownEditor.current.querySelectorAll('.cm-link');
    $cmLink &&
      $cmLink?.length > 0 &&
      Array.from($cmLink).forEach(($linkItem) => {
        if (interactSyntaxRegex.exec($linkItem.innerText)) {
          $linkItem.style['color'] = '#e55039';
        }
      });
  };

  const textEditStyle = () => {
    const $mirrorSpan = refEnhancedMarkdownEditor.current.querySelectorAll(
      'span[role="presentation"]'
    );
    $mirrorSpan &&
      $mirrorSpan?.length > 0 &&
      Array.from($mirrorSpan).forEach(($spanItem) => {
        if (
          $spanItem.innerText.match(newSpanConfigDetect) ||
          $spanItem.innerText.match(newSpanConfigTailDetect)
        ) {
          $spanItem.style['color'] = 'rgb(95 160 9)';
        }
        if ($spanItem.innerText.match(newParagphConfigDetect)) {
          $spanItem.style['color'] = '#1B9CFC';
        }
      });
  };

  const mapToNewStyle = () => {
    if (!refEnhancedMarkdownEditor?.current) return;

    linkStyle();
    textEditStyle();
  };

  const debounceNewStyle = useCallback(
    _.debounce((value) => mapToNewStyle(), 1000),
    []
  );

  const debounceNewStyleOnScroll = useCallback(
    _.debounce((value) => mapToNewStyle(), 200),
    []
  );

  const addScrollEvent = useCallback(() => {
    const scroll = refMdEditor.current?.CodeMirror?.editor?.display?.scroller;
    const handleScroll = (event) => {
      debounceNewStyleOnScroll(event?.timeStamp);
    };

    if (!isAddedScrollHandler) {
      if (scroll) {
        scroll.addEventListener('scroll', handleScroll);
        setIsAddedScrollHandler(true);
      }
    }
  }, [isAddedScrollHandler, debounceNewStyleOnScroll]);

  useEffect(() => {
    itemRef(refMdEditor?.current);
    addScrollEvent();
  }, [itemRef, addScrollEvent]);

  useEffect(() => {
    addCustomToolbar();
    resetMediaAssetCallback();
    const mdEditor = refMdEditor.current?.CodeMirror?.editor;
    mdEditor.getWrapperElement().style['font-size'] = '19px';
    mdEditor.getWrapperElement().style['font-family'] = 'roboto';
    mdEditor.refresh();
  }, []);

  useEffect(() => {
    addCustomToolbar();
  }, [toolList]);

  useEffect(() => {
    if (entityOpen && !entityName) {
      setEntityOpen(false);
      dialogFunction({
        type: 'warn',
        content: <Title level={5}>Please select entity type!</Title>,
        cancelButtonProps: { style: { display: 'none' } },
      });
    }
  }, [entityName, entityOpen]);

  return (
    <div
      className='enhanced-markdown-editor'
      ref={refEnhancedMarkdownEditor}
      style={{ height: height || '100%' }}
    >
      <MarkdownEditor
        ref={refMdEditor}
        onChange={handleChangeContent}
        value={value}
        visible={false}
        {...restProps}
      />
      <>
        {entityOpen && entityName && (
          <MdEntityTool
            mdEditor={mdInstance?.editor}
            entityName={entityName}
            setEntityOpen={setEntityOpen}
            entityOpen={entityOpen}
            isEntityInsideTable={isEntityInsideTable}
          />
        )}
        {interEntityOpen && (
          <MdInterEntityTool
            mdEditor={mdInstance?.editor}
            setInterEntityOpen={setInterEntityOpen}
            interEntityOpen={interEntityOpen}
            isEntityInsideTable={isEntityInsideTable}
            markdownRefSelector={markdownRefSelector}
            tempMdTableData={tempMdTableData}
          />
        )}
      </>
    </div>
  );
};

EnhancedMarkdownEditor.propTypes = {
  //? height - height of wrapper if needed
  height: PropTypes.number,
  //? setMediaOpen - function to toggle markdown media window
  setMediaOpen: PropTypes.func,
  //? isMediaOpenSelector - saga selector to select open/close media list
  isMediaOpenSelector: PropTypes.func,
  //? markdownRefSelector - saga selector to select reference
  markdownRefSelector: PropTypes.func,
  //? markdownRefUpdateAction - saga action to update references
  markdownRefUpdateAction: PropTypes.func,
  //? markdownContentId - id of markdown conentent if needed
  markdownContentId: PropTypes.string,
  //? onChangeContent - handle change content
  onChangeContent: PropTypes.func,
  //? value - init value of markdown editor
  value: PropTypes.string,
};

export default EnhancedMarkdownEditor;
