import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as ticketingServices from 'services/ticketingSystem';

export const useGetTicketAsset = (props = {}) => {
  const queryClient = useQueryClient();

  const { params, enabled = false, ...restProps } = props;

  const getQueryKey = () => {
    return ['get-ticket-assets', params];
  };

  const getTicketAssets = async ({ queryKey }) => {
    const response = await ticketingServices.getTicketAsset(queryKey[1]);

    const { isSuccess, data } = response;

    return isSuccess && data ? data.gridData : [];
  };

  const query = useQuery({
    queryKey: getQueryKey(),
    queryFn: getTicketAssets,
    enabled,
    ...restProps,
  });

  const invalidateTicketAsset = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKey(),
    });
  };

  return { ...query, invalidateTicketAsset };
};
