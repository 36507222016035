import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components/index';
import { CustomNotification } from 'common/components';

import { rejectSubscription } from 'services/subscription';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import Messages from 'i18n/messages/home';

const RejectSubscription = (props) => {
  const reloadPage = useDispatchReloadPage();
  const { selectionSubscription, sectionDisabled } = props;

  const handleBtnlick = () => {
    dialogFunction({
      type: 'info',
      content:
        'Are you sure you want to reject selecting subscription request?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleOkClick,
    });
  };

  const handleOkClick = () => {
    const params = {
      subId: selectionSubscription.id,
    };

    rejectSubscription(params)
      .then((res) => {
        if (res?.isSuccess) {
          CustomNotification.success('Successfully');
          reloadPage();
        } else {
          CustomNotification.error(
            res?.message || 'Reject failed! Please try!'
          );
        }
      })
      .catch((error) => {
        CustomNotification.error('Reject failed! Something go wrong!');
      });
  };

  const checkDisabled = () => {
    let disabled = false;

    disabled = selectionSubscription?.status !== 'Pending';

    return disabled || sectionDisabled;
  };

  return (
    <RibbonButton
      icon={<CloseOutlined style={{ height: 26 }} />}
      label={Messages.rejectSubscription}
      onClick={handleBtnlick}
      disabled={checkDisabled()}
    />
  );
};

RejectSubscription.propTypes = {};

export default RejectSubscription;
