import { useState, useRef, useEffect, useCallback } from 'react';

import { toUniqueList } from 'utils';

import { isEqual } from 'lodash';
import usePrevious from 'hooks/usePrevious';

import * as productServices from 'services/product';

const useFetchDataForErrorList = (errorList, reloadOneError, errorIndex) => {
  const [productDetailList, setProductDetailList] = useState([]);
  const [productModuleList, setProductModuleList] = useState([]);

  const refProductDetailIdList = useRef([]);
  const refProductModuleIdList = useRef([]);

  const prevReloadOneError = usePrevious(reloadOneError);

  const getUniqueListCallback = useCallback(({ errorList }) => {
    let productDetailIdList = [];
    let productModuleIdList = [];
    errorList.forEach((errorGroup) => {
      errorGroup.errorFields.forEach((errorItem) => {
        const isRivirField = !errorItem?.moduleName;

        if (!isRivirField) {
          productModuleIdList.push(errorItem?.productId);
        }

        //* get all product's detail
        productDetailIdList.push(errorItem?.productId);
      });
    });

    return {
      productDetailIdList: toUniqueList(productDetailIdList),
      productModuleIdList: toUniqueList(productModuleIdList),
    };
  }, []);

  const fetchProductDataCallback = useCallback(
    async ({ uniqueList }, isRefetch) => {
      const { productDetailIdList, productModuleIdList } = uniqueList;

      if (
        !isEqual(productDetailIdList, refProductDetailIdList.current) ||
        isRefetch
      ) {
        refProductDetailIdList.current = productDetailIdList;
        const productDetailServiceList = productDetailIdList.map(
          (productId) => {
            const params = { productItemId: productId };
            return productServices.getProductFullView(params);
          }
        );

        await Promise.all(productDetailServiceList).then(async (response) => {
          const isSuccess =
            response.length && response.every((res) => res.isSuccess === true);

          if (isSuccess) {
            setProductDetailList(response.map((res) => res?.data));
          }
        });
      }

      if (
        !isEqual(productModuleIdList, refProductModuleIdList.current) ||
        isRefetch
      ) {
        refProductModuleIdList.current = productModuleIdList;
        const productModuleServiceList = productModuleIdList.map(
          (productId) => {
            const params = { productId };
            return productServices.getProductItemModules(params);
          }
        );

        await Promise.all(productModuleServiceList).then(async (response) => {
          const isSuccess =
            response.length && response.every((res) => res.isSuccess === true);

          if (isSuccess) {
            setProductModuleList(response.map((res) => res?.data));
          }
        });
      }
    },
    []
  );

  const refetchProductData = async () => {
    try {
      const uniqueList = getUniqueListCallback({ errorList });
      await fetchProductDataCallback({ uniqueList }, true);

      return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (errorList) {
      const uniqueList = getUniqueListCallback({ errorList });
      fetchProductDataCallback({ uniqueList });
    }
  }, [errorList, fetchProductDataCallback, getUniqueListCallback]);

  useEffect(() => {
    const reFetchOneError = async () => {
      const uniqueList = getUniqueListCallback({ errorList });
      const { productDetailIdList, productModuleIdList } = uniqueList;

      const productId = errorIndex;

      const foundIndexDetail = productDetailIdList.findIndex(
        (idItem) => idItem === productId
      );

      if (foundIndexDetail) {
        const params = { productId };
        const res = await productServices.getProductFullView(params);
        if (res?.isSuccess) {
          setProductDetailList((prev) => {
            let nextValue = [...prev];
            nextValue[foundIndexDetail] = res?.data;
            return nextValue;
          });
        }
      }

      const foundIndexModule = productModuleIdList.findIndex(
        (idItem) => idItem === productId
      );

      if (foundIndexModule) {
        const params = { productId: productId };
        const res = await productServices.getProductItemModules(params);
        if (res?.isSuccess) {
          setProductModuleList((prev) => {
            let nextValue = [...prev];
            nextValue[foundIndexDetail] = res?.data;
            return nextValue;
          });
        }
      }
    };

    if (reloadOneError && prevReloadOneError !== reloadOneError) {
      reFetchOneError();
    }
  }, [
    reloadOneError,
    prevReloadOneError,
    errorIndex,
    errorList,
    getUniqueListCallback,
  ]);

  return {
    data: {
      productDetailList,
      productModuleList,
    },
    refetchProductData,
  };
};

export default useFetchDataForErrorList;
