import React, { useState, useEffect, useRef } from 'react';
import videoThumbnail from 'assets/asset-icons/video-thumbnail.png';

const MdYoutube = (props) => {
  const {
    src,
    width,
    height,
    mapToPaddingStyle,
    alignConfig,
    spaceConfig,
  } = props;

  const [calHeight, setCalHeight] = useState(0);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (refVideo?.current) {
        setReady(true);
      }
    }, 1300);
  }, []);

  const ratio = 16 / 9;

  const refVideo = useRef();

  const calculateHeight = (orgScale, width, height, curWidth) => {
    const curScale = orgScale * (width / height);
    return curWidth / curScale;
  };

  const convertYoutubeToEmbedSrc = (src) => {
    if (!src) return '';
    if (src.includes('embed')) return src;
    const videoId = src.split('?v=')?.[1]?.split('&')?.[0];
    return 'https://www.youtube.com/embed/' + videoId;
  };

  useEffect(() => {
    if (refVideo?.current) {
      setTimeout(() => {
        const curWidth = refVideo?.current?.offsetWidth;
        const newHeight = calculateHeight(ratio, width, height, curWidth);
        setCalHeight(newHeight);
      }, 200);
    }
  });

  if (!ready) {
    return (
      <div
        ref={refVideo}
        style={{
          float: alignConfig,
          width: `${width}%`,
          height: calHeight ? `${calHeight}px` : 'auto',
          // border: 'solid 1px rgba(0, 0, 0, 0.35)',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          transition: 'all 0.2s',
          ...mapToPaddingStyle(alignConfig, spaceConfig),
        }}
      >
        <img
          src={videoThumbnail}
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
          }}
          alt='loading-poster'
        />
      </div>
    );
  } else {
    return (
      <iframe
        ref={refVideo}
        title='unknown'
        src={convertYoutubeToEmbedSrc(src)}
        frameborder='0'
        width='100%'
        style={{
          float: alignConfig,
          width: `${width}%`,
          height: calHeight ? `${calHeight}px` : 'auto',
          transition: 'all 0.2s',
          ...mapToPaddingStyle(alignConfig, spaceConfig),
        }}
      />
    );
  }
};

export default MdYoutube;
