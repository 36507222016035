import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Input,
  Space,
  Typography,
  Tag,
  notification,
  Empty,
} from 'antd';

import {
  Form,
  StyledModal,
  FormAddButton,
  AgGrid,
  dialogFunction,
  DisplayErrorsGlns,
  WithLoading,
  FormDeleteButton,
} from 'common/components';
import FormFields from './FormFields';

import { GRID_ID } from 'common/components/grid-view/utils';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';

import reducerProduct from 'pages/branded-products/controllers/reducer';
import sagaProduct from 'pages/branded-products/controllers/saga';
import * as productActions from 'pages/branded-products/controllers/actions';
import * as productSelectors from 'pages/branded-products/controllers/selectors';
import * as requiredFieldsActions from 'pages/recipients-fields/controllers/actions';
import reducerProductFullView from 'pages/product-full-view/controllers/reducer';
import sagaProductFullView from 'pages/product-full-view/controllers/saga';

import * as endpointsProduct from 'services/product/endpoints';
import { saveRequiredFields } from 'services/recipients-fields';

import { useGetProductSchema } from 'hooks';

import {
  getColorTag,
  filterFields,
  distinguishFieldsFromEditedFields,
} from './utils';

import './index.less';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const key = 'product';
const keyProductFullView = 'productFullView';

const SaveRequiredFields = ({
  visible,
  loading,
  onCancel,
  editedData,
  isEdit,
}) => {
  useInjectReducer({
    key: keyProductFullView,
    reducer: reducerProductFullView,
  });
  useInjectSaga({ key: keyProductFullView, saga: sagaProductFullView });

  const [formInstance] = Form.useForm();
  const fieldNameList = Form.useWatch('fields', formInstance);
  const selectedGlns = Form.useWatch('targetGLNs', formInstance);

  const gridApiRef = useRef();
  const [errors, setErrors] = useState([]);

  const [gridVisible, setGridVisible] = useState(false);

  const dispatch = useDispatch();

  const { productSchema } = useGetProductSchema();

  useEffect(() => {
    if (visible && editedData) {
      const { name, fields, targetGLNs } = editedData;
      const newFields = distinguishFieldsFromEditedFields(fields);

      formInstance.setFieldsValue({
        name,
        fields: newFields,
        targetGLNs,
      });
    }
  }, [editedData, formInstance, visible]);

  const handleAddGlns = () => {
    if (gridApiRef?.current) {
      const glns = gridApiRef.current.getSelectedRows().map((row) => row.id);
      const currentSelectedGlns = selectedGlns || [];

      const newSelectedGlns = Array.from(
        new Set([...currentSelectedGlns, ...glns])
      );

      formInstance.setFieldsValue({
        targetGLNs: newSelectedGlns,
      });
    }
  };

  const handleDeleteGln = (filteredGln) => {
    if (gridApiRef?.current) {
      gridApiRef.current.forEachNode(function (node) {
        if (node?.data?.partyGln === filteredGln) {
          node.setSelected(false);
        }
      });
    }

    const filteredGlns = selectedGlns.filter((gln) => gln !== filteredGln);

    formInstance.setFieldsValue({ targetGLNs: filteredGlns });
  };

  const handleDeleteExistedGlns = () => {
    const glnsErrors = errors.map((error) => error.split(' ')[0]);
    const filteredGlns = selectedGlns.filter(
      (gln) => !glnsErrors.includes(gln)
    );

    if (gridApiRef?.current) {
      gridApiRef.current.forEachNode(function (node) {
        if (!filteredGlns.includes(node?.data?.partyGln)) {
          node.setSelected(false);
        }
      });
    }

    formInstance.setFieldsValue({ targetGLNs: filteredGlns });
    setErrors([]);
  };

  const handleSaveRequiredFieldsSuccess = () => {
    onCancel();
    dispatch(requiredFieldsActions.toggleReloadGrid(true));

    setTimeout(() => {
      dispatch(requiredFieldsActions.toggleReloadGrid(false));
    }, 200);
  };

  const handleSaveRequiredFields = async () => {
    try {
      const { fields, ...otherValues } = await formInstance.validateFields();

      const params = {
        ...otherValues,
        id: isEdit ? editedData.id : null,
        targetGLNs: selectedGlns,
        fields: filterFields(fields) ?? [],
      };

      if (selectedGlns.length === 0) return;

      saveRequiredFields(params).then((response) => {
        if (response?.isSuccess) {
          notification.success({
            message: 'Save required field successfully!',
          });
          handleSaveRequiredFieldsSuccess();
        } else {
          setErrors(response?.data?.errors ?? []);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      onOk={handleSaveRequiredFields}
      bodyStyle={{
        minHeight: 300,
        height: '80vh',
      }}
      centered
      width='1096px'
      title='Save Required Fields'
    >
      {loading ? (
        <div className='fields-form__loading-wrapper'>
          <WithLoading loading />
        </div>
      ) : (
        <Form className='fields-form' {...layout} form={formInstance}>
          <Row style={{ height: '100%' }}>
            <Col span={10} style={{ height: '100%', paddingRight: 8 }}>
              <Form.Item
                name='name'
                label='Name'
                rules={[{ required: true, message: 'Please input name' }]}
              >
                <Input />
              </Form.Item>

              <FormFields
                formInstance={formInstance}
                fieldNameList={fieldNameList}
                productSchema={productSchema}
              />
            </Col>

            <Col span={14} style={{ height: '100%' }}>
              <div className='fields-form__gln'>
                <Form.Item
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  name='targetGLNs'
                  label='Target GLNs'
                  help={!gridVisible && selectedGlns?.length ? '' : undefined}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Space size={16}>
                    <FormAddButton
                      text='Select GLN'
                      style={{ borderRadius: 'none' }}
                      onClick={() => setGridVisible(true)}
                    />
                    {!gridVisible && selectedGlns?.length ? (
                      <Typography.Text>
                        <QuestionCircleOutlined /> Click select GLN button to
                        display the recipient list
                      </Typography.Text>
                    ) : null}
                  </Space>
                </Form.Item>

                {selectedGlns?.length > 0 && (
                  <DisplayGlns
                    glns={selectedGlns}
                    handleDeleteGln={handleDeleteGln}
                    selectedGlns={selectedGlns}
                    errors={errors}
                    deleteAll={handleDeleteExistedGlns}
                  />
                )}
                {errors.length > 0 && <DisplayErrorsGlns />}

                {gridVisible ? (
                  <GridSelectGln
                    gridApiRef={gridApiRef}
                    handleAddGlns={handleAddGlns}
                    setGridVisible={setGridVisible}
                  />
                ) : !selectedGlns?.length ? (
                  <div className='fields-form__gln-empty'>
                    <Empty
                      description={
                        <span>
                          No selected GLN.
                          <br /> Click{' '}
                          <Typography.Text strong>
                            Select GLN
                          </Typography.Text>{' '}
                          button to display the recipient list
                        </span>
                      }
                    />
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </StyledModal>
  );
};

const GridSelectGln = ({ gridApiRef, handleAddGlns, setGridVisible }) => {
  useInjectReducer({ key, reducer: reducerProduct });
  useInjectSaga({ key, saga: sagaProduct });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productActions.getGDSNPartyList('all-party-info-grid'));
  }, [dispatch]);

  const [searchText, setSearchText] = useState('');

  const GDSNList = useSelector(productSelectors.makeSelectGDSNPartyList());
  const columns = GDSNList?.data ?? [];

  return (
    <div className='fields-form__gln-grid-wrapper'>
      <Row>
        <Col span={16}>
          <Typography.Title level={5}>Recipients</Typography.Title>
        </Col>

        <Col span={8} style={{ textAlign: 'right' }}>
          <CloseOutlined
            style={{
              fontSize: 17,
              paddingTop: 2,
              paddingLeft: 5,
              color: '#ff4d4f',
            }}
            onClick={() => setGridVisible(false)}
          />
        </Col>
      </Row>
      <div style={{ paddingBottom: '5px', display: 'flex' }}>
        <Input.Search
          placeholder='Search GLN'
          allowClear
          onSearch={(value) => setSearchText(value)}
        />
        <FormAddButton
          text='Add'
          style={{ marginLeft: 10, borderRadius: 'none' }}
          onClick={handleAddGlns}
        />
      </div>
      <div className='fields-form__gln-grid'>
        {columns.length > 0 && (
          <AgGrid
            columnDefs={columns}
            urlGrid={endpointsProduct.GET_PARTY_INFO_GRID}
            urlGridDistinct={endpointsProduct.GET_PARTY_INFO_GRID_DISTINCT}
            getGridApi={(gridApiParams) => {
              gridApiRef.current = gridApiParams;
            }}
            gridId={GRID_ID.PARTY_GDSN_LIST}
            responseParams='data'
            paramsGrid={{
              search: {
                searchText,
              },
            }}
            gridConfigProps={{
              rowSelection: 'multiple',
              rowMultiSelectWithClick: true,
            }}
            mapId={(item) => ({
              ...item,
              id: `${item?.partyGln}`,
            })}
            isSmallSizePagination={true}
          />
        )}
      </div>
    </div>
  );
};

const DisplayGlns = ({
  glns,
  selectedGlns,
  errors,
  handleDeleteGln,
  deleteAll,
}) => {
  const getListGlns = (errors, selectedGlns) => {
    if (errors?.length > 0) {
      const glnsErrors = errors.map((error) => error.split(' ')[0]);
      return glnsErrors.filter((gln) => selectedGlns.includes(gln));
    }
    return [];
  };

  const existedGlns = useMemo(
    () => getListGlns(errors, selectedGlns),
    [errors, selectedGlns]
  );

  return !!glns?.length ? (
    <>
      <Row style={{ marginBottom: 8, maxHeight: 250 }} className='scroller'>
        {glns.map((gln) => (
          <Tag
            color={getColorTag(gln)}
            key={gln}
            style={{
              marginBottom: 6,
            }}
            closable
            onClose={(event) => {
              event.preventDefault();
              dialogFunction({
                type: 'warn',
                content: 'Are you sure want to delete the GLN?',
                onOk: () => {
                  handleDeleteGln(gln);
                },
              });
            }}
          >
            GLN {gln}
          </Tag>
        ))}
      </Row>
      {existedGlns.length > 0 && (
        <>
          <Row style={{ marginBottom: 8 }} wrap={false}>
            <Col flex={1}>
              <Typography.Text strong style={{ color: '#ff4d4f' }}>
                There are GLNs already been linked
              </Typography.Text>
            </Col>
            <Col flex={0}>
              <FormDeleteButton
                type='text'
                text='Clear existed GLN(s)'
                size='small'
                danger
                style={{ borderRadius: 0 }}
                onClick={deleteAll}
              />
            </Col>
          </Row>
          <div
            className='scroller--y'
            style={{ maxHeight: 250, marginBottom: 6 }}
          >
            {existedGlns.map((gln) => (
              <Tag
                color={getColorTag(gln)}
                key={gln}
                style={{
                  marginBottom: 6,
                }}
              >
                GLN {gln}
              </Tag>
            ))}
          </div>
        </>
      )}
    </>
  ) : null;
};

export { SaveRequiredFields };
