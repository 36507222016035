import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

//? COMPONENT
import { Form, Row, Col, Modal, Steps } from 'antd';

import {
  StyledModal,
  StepForm,
  WithAbsoluteContainer,
} from 'common/components';
import CreateMSDSDocumentForm from 'pages/product-full-view/components/modal/add-document-msds/CreateMSDSDocumentForm';
import UploadPimAsset from 'pages/product-full-view/components/modal/add-asset-pim-modal/UploadPimAsset';

//? REDUX
import { useGetProductFullView } from 'hooks';

const currentStepDefine = [0, 1];
const stepDefault = currentStepDefine[0];

const assetIdDefine = [null, '', Number];
const assetIdDefault = assetIdDefine[0];

const { Step } = Steps;

const CreateMSDSDocumentModal = (props) => {
  const { onCloseModal, getDocument } = props;

  const [preview, setPreview] = useState(null);
  const [assetId, setAssetId] = useState(assetIdDefault);
  const [currentStep, setCurrentStep] = useState(stepDefault);
  const [isAllowedUpload, setIsAllowedUpload] = useState(false);

  const { id: productId } = useParams();

  const { productFull } = useGetProductFullView({ productId });

  const productInfo = props.productDetail || productFull;

  const [formInstance] = Form.useForm();

  const onSelectAttachFile = (fileList) => {
    if (!fileList) return;
    const file = fileList.slice(-1).pop();
    if (!file) return;

    setAssetId(assetIdDefault);

    if (file?.status === 'error.fileType') {
      setIsAllowedUpload(false);
      return;
    }

    setIsAllowedUpload(true);
  };

  const handleCancelPreview = () => setPreview({ previewVisible: false });

  const handleBackToCreateAsset = () => setCurrentStep(0);

  const handleMoveToLinkAsset = () => setCurrentStep(1);

  const handleUploadSuccess = (res) => {
    if (!res?.data) return;

    const { data } = res?.data;

    if (!assetId) {
      setAssetId(data?.assetId);
    }
    handleMoveToLinkAsset();
    setIsAllowedUpload(false);
  };

  //!- RENDER
  const stepDefine = [
    {
      step: 0,
      component: (
        <UploadPimAsset
          formInstance={formInstance}
          onSelectAttachFile={onSelectAttachFile}
          addType={'Document'}
          assetId={assetId}
          handleMoveToLinkAsset={handleMoveToLinkAsset}
          isAllowedUpload={isAllowedUpload}
          handleUploadSuccess={handleUploadSuccess}
          productInfo={productInfo}
        />
      ),
    },
    {
      step: 1,
      component: (
        <CreateMSDSDocumentForm
          assetId={assetId}
          productInfo={productInfo}
          handleBackToCreateAsset={handleBackToCreateAsset}
          onReloadPage={() => {
            getDocument && getDocument();
          }}
        />
      ),
    },
  ];

  return (
    <StyledModal
      title={'Create MSDS Document'}
      onCancel={onCloseModal}
      onOk={() => setCurrentStep((prev) => prev + 1)}
      maskClosable={false}
      footer={null}
      visible
      destroyOnClose
    >
      <Row style={{ marginBottom: 20 }}>
        <Col xs={24}>
          <Steps current={currentStep}>
            <Step title='Create an asset' />
            <Step title={'Link to QA Specification'} />
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <StepForm currentStep={currentStep} stepDefine={stepDefine} />
        </Col>
      </Row>
      <WithAbsoluteContainer>
        <Modal
          visible={preview?.previewVisible}
          title={preview?.previewTitle}
          footer={null}
          onCancel={handleCancelPreview}
        >
          <img
            alt='example'
            style={{ width: '100%' }}
            src={preview?.previewImage}
          />
        </Modal>
      </WithAbsoluteContainer>
    </StyledModal>
  );
};

CreateMSDSDocumentModal.propTypes = {
  addType: PropTypes.string,
  onCloseModal: PropTypes.func,
  getImagery: PropTypes.func,
};

export default CreateMSDSDocumentModal;
