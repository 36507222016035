import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { forwardTo } from 'utils/common/route';

import { CustomNotification } from 'common/components';

import { saveQaSpecDataPackaging } from 'services/product';
import { qaFreshProduceKeys } from '../qa-fresh-produce/hooks';
import { getKeyProductSpec } from 'pages/qa-spec/hooks/useProductSpecInfo';
import { getKeyProductIngredients } from 'pages/product-full-view/components/product-media-area/multiple-panel/tab-ingredients/useProductIngredients';
import { productFullViewKeys } from 'hooks/product-full/hooks';
import { qaAllergensKeys } from '../qa-allergen/hooks';
import { groupProductData } from 'pages/product-full-view/components/product-media-area/shared/hooks';
import { queryKeyQaSpecIngredients } from 'pages/qa-spec/components/qa-ingredient/hook';
import { keyGetProductItemModules } from 'pages/product-full-view/shared/hooks';
import * as productServices from 'services/product';
import { getQaClaimsTabDataQueryKey } from 'pages/qa-spec/components/qa-claims/queries';

export const getQaPackagingTabDataQueryKey = (productId) => {
  return ['qa-spec', parseInt(productId), 'qa-spec-data-packing'];
};

export const useGetQaPackagingTabData = ({ productId }, options) => {
  return useQuery({
    queryKey: getQaPackagingTabDataQueryKey(productId),
    queryFn: async () => {
      const response = await productServices.getQaPacking({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    ...options,
  });
};

export const useSaveQaPackagingTab = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ productId, formData }) => {
      const params = { productId, ...formData };
      const response = await saveQaSpecDataPackaging(params);

      const { isSuccess, data, message } = response || {};

      if (!!isSuccess) {
        return Promise.resolve(data);
      } else if (message) {
        return Promise.reject(message);
      }
    },
    onSuccess: async (data, { productId }) => {
      queryClient.invalidateQueries({
        queryKey: getQaPackagingTabDataQueryKey(productId),
      });
      queryClient.invalidateQueries({
        queryKey: qaFreshProduceKeys.detail(productId),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyProductSpec(productId),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyProductIngredients(productId),
      });
      queryClient.invalidateQueries({
        queryKey: productFullViewKeys.itemData(productId),
      });
      queryClient.invalidateQueries({
        queryKey: qaAllergensKeys.list(productId),
      });
      queryClient.invalidateQueries({
        queryKey: groupProductData.getAllGroups(productId),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeyQaSpecIngredients(productId),
      });
      // ADVANCED MODULES
      queryClient.invalidateQueries({
        queryKey: keyGetProductItemModules(productId),
      });
      //* sync to qa claim
      queryClient.invalidateQueries({
        queryKey: getQaClaimsTabDataQueryKey(productId),
      });

      CustomNotification.success('Update Labeling data successfully!');
    },
    onError: (error) => {
      CustomNotification.error(error || 'Server Error');
    },
  });
};
