import { Images } from 'config/assets';

import Reporting from 'assets/reporting/Reporting.png';
import AssetReport from 'assets/reporting/AssetReport.png';
import ProductReport from 'assets/reporting/ProductReport.png';
import MemberReport from 'assets/reporting/MemberReport.png';

export const getThumbnailImage = (dataDetail, type) => {
  const totalCount =
    dataDetail?.subFolderCount +
    dataDetail?.contentsCount +
    dataDetail?.productCount +
    dataDetail?.assetCount +
    dataDetail?.reportingCount +
    dataDetail?.queryCount +
    dataDetail?.memberCount;

  const reportingImage =
    (dataDetail?.advType === 'DigitalMedia' && AssetReport) ||
    (dataDetail?.advType === 'Member' && MemberReport) ||
    (dataDetail?.advType === 'ProductItem' && ProductReport);

  const imageCollection = {
    folder: totalCount > 0 ? Images.folder : Images.emptyFolder,
    reporting: reportingImage,
    query: Reporting,
  };

  const image = imageCollection[type] || dataDetail?.thumbnail;

  return image;
};
