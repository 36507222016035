import React from 'react';

import { Row, Col, Typography } from 'antd';
import {
  MobileFilled,
  MailOutlined,
  PhoneFilled,
  PrinterFilled,
} from '@ant-design/icons';

import ContactCardContactItem from './ContactCardContactItem';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/user-profile';

const { Text } = Typography;

const ContactCardContactInfo = (props) => {
  const intl = useIntl();

  const { phoneNumber, mobileNumber, faxNumber, email } = props;

  //* render
  const noInfoMessage = intl.formatMessage(Messages.noInfoMessage);

  const renderMobilePhone = () => {
    return (
      <ContactCardContactItem icon={<MobileFilled />} backgroundColor='#faad14'>
        <Text>{mobileNumber ? mobileNumber : noInfoMessage}</Text>
      </ContactCardContactItem>
    );
  };

  const renderPhoneNumber = () => {
    return (
      <ContactCardContactItem icon={<PhoneFilled />} backgroundColor='#f5222d'>
        {<Text>{phoneNumber ? phoneNumber : noInfoMessage}</Text>}
      </ContactCardContactItem>
    );
  };

  const renderFaxNumber = () => {
    return (
      <ContactCardContactItem
        icon={<PrinterFilled />}
        backgroundColor='#1890ff'
      >
        <Text>{faxNumber ? faxNumber : noInfoMessage}</Text>
      </ContactCardContactItem>
    );
  };

  const renderEmail = () => {
    return (
      <ContactCardContactItem icon={<MailOutlined />} backgroundColor='#fadb14'>
        <Text>{email ? email : noInfoMessage}</Text>
      </ContactCardContactItem>
    );
  };

  const colProps = {
    flex: '50%',
    style: {
      maxWidth: '50%',
    },
  };

  return (
    <Row gutter={[15, 8]}>
      <Col {...colProps}>{renderMobilePhone()}</Col>
      <Col {...colProps}>{renderEmail()}</Col>
      <Col {...colProps}>{renderPhoneNumber()}</Col>
      <Col {...colProps}>{renderFaxNumber()}</Col>
    </Row>
  );
};

export default ContactCardContactInfo;
