import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { qaToggleTabExpand } from 'pages/qa-spec/controllers/actions';
import { selectIsTabExpanded } from 'pages/qa-spec/controllers/selectors';

import { toggleProductDetailExpand } from 'pages/product-full-view/controllers/actions';
import { selectIsProductDetailExpand } from 'pages/product-full-view/controllers/selectors';

import {
  QA_SPEC_TABLE_HEIGHT,
  QA_SPEC_EXPAND_STYLE,
  PRODUCT_DETAIL_EXPAND_STYLE,
} from 'static/Constants';

import { useResize } from 'hooks/useResize';

/**
 * ? calculate product primary image when page collape
 * @param {*} headerId
 * @param {*} refresh
 * @returns
 */
const useGetProductHeaderImageSize = ({ headerId, viewType, refresh } = {}) => {
  // eslint-disable-next-line no-unused-vars
  const [headerWidth, headerHeight] = useResize(headerId, refresh);

  const EXPAND_STYLE = {
    qaSpecDetail: QA_SPEC_EXPAND_STYLE,
    productDetail: PRODUCT_DETAIL_EXPAND_STYLE,
  };

  const { TAB_HEIGHT, GRID_GAP } = EXPAND_STYLE?.[viewType] || {};

  const productHeaderImageSize = {
    width: headerHeight + GRID_GAP + TAB_HEIGHT,
    height: headerHeight + GRID_GAP + TAB_HEIGHT,
  };

  if (!headerId) return null;

  return productHeaderImageSize;
};

/**
 * ? expand/collapse tab for qa spec detail page
 * @param {} refresh
 * @returns
 */
const useTabExpand = (refresh) => {
  const dispatch = useDispatch();
  const isTabExpanded = useSelector(selectIsTabExpanded());

  const productHeaderImageSize = useGetProductHeaderImageSize({
    headerId: 'qa-spec-header-wrap',
    viewType: 'qaSpecDetail',
    refresh,
  });

  const toggleTabExpand = () => {
    dispatch(qaToggleTabExpand(!isTabExpanded));
  };

  const shrinkTab = () => {
    dispatch(qaToggleTabExpand(false));
  };

  const expandTab = () => {
    dispatch(qaToggleTabExpand(true));
  };

  const tableStyle = useMemo(() => {
    const initTableStyle = {
      transition: 'height 0.4s',
    };
    return isTabExpanded
      ? { ...initTableStyle, height: QA_SPEC_TABLE_HEIGHT.EXPAND }
      : { ...initTableStyle, height: QA_SPEC_TABLE_HEIGHT.NON_EXPAND };
  }, [isTabExpanded]);

  return {
    toggleTabExpand,
    isTabExpanded,
    tableStyle,
    shrinkTab,
    expandTab,
    productHeaderImageSize,
  };
};

/**
 * ? expand/collape product detail view
 * @param {*} refresh
 * @returns
 */
const useTabExpandProductDetail = (refresh, isFullView) => {
  const dispatch = useDispatch();
  const isTabExpanded = useSelector(selectIsProductDetailExpand());

  const productHeaderImageSize = useGetProductHeaderImageSize({
    headerId: 'product-detail-primary-container',
    viewType: 'productDetail',
    refresh,
  });

  const toggleTabExpand = (value) => {
    if (value === undefined) {
      dispatch(toggleProductDetailExpand(!isTabExpanded));
    } else {
      dispatch(toggleProductDetailExpand(value));
    }
  };

  const shrinkTab = () => {
    dispatch(toggleProductDetailExpand(false));
  };

  const expandTab = () => {
    dispatch(toggleProductDetailExpand(true));
  };

  return {
    toggleTabExpand,
    isTabExpanded,
    shrinkTab,
    expandTab,
    productHeaderImageSize,
  };
};

export {
  useTabExpand,
  useGetProductHeaderImageSize,
  useTabExpandProductDetail,
};
