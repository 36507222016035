const mapToSearchParams = (formValue, defaultOptions, mapSearch) => {
  const searchText = formValue?.searchText;

  const sortConfig = searchText
    ? { sort: [] }
    : { sort: [{ fieldName: 'id', isAscending: false }] };

  if (mapSearch) {
    const nextOptions = mapSearch(searchText);

    return {
      ...defaultOptions,
      ...nextOptions,
      ...sortConfig,
    };
  }

  return { ...defaultOptions, search: { searchText } };
};

export { mapToSearchParams };
