import React, { useState, useContext, useMemo } from 'react';

import { sleep } from 'utils/delay';

export const QaEditTabCheckerContext = React.createContext({});

export const QaEditTabCheckerProvider = (props) => {
  const { children } = props;

  const [editItemList, setEditItemList] = useState(() => new Map([]));
  const [manipulateItem, setManipulateItem] = useState(null);

  const editItemListArray = useMemo(() => {
    return Array.from(editItemList);
  }, [editItemList]);

  const editItemCountByTab = useMemo(() => {
    let nextEditItemCountByTab = {};

    if (editItemListArray?.length > 0) {
      editItemListArray.forEach((editItem) => {
        const { tab } = editItem?.[1];

        if (nextEditItemCountByTab?.[tab]) {
          nextEditItemCountByTab[tab] += 1;
        } else {
          nextEditItemCountByTab[tab] = 1;
        }
      });
    }
    return nextEditItemCountByTab;
  }, [editItemListArray]);

  const addEditItem = (item) => {
    setEditItemList((prev) => new Map(prev).set(item?.id, item));
  };

  const removeEditItem = (item) => {
    setEditItemList((prev) => {
      const next = new Map(prev);
      next.delete(item?.id);
      return next;
    });
  };

  const forceSaveItem = async (itemId) => {
    setManipulateItem({ id: itemId, action: 'save' });
    await sleep(50);
    setManipulateItem(null);
  };

  const forceCancelItem = async (itemId) => {
    setManipulateItem({ id: itemId, action: 'cancel' });
    await sleep(50);
    setManipulateItem(null);
  };

  const forceChangeTab = async (itemId, tab) => {
    setManipulateItem({ id: itemId, tab, action: 'view' });
    await sleep(50);
    setManipulateItem(null);
  };

  // remove Editing Item(s) when change type
  const removeSpecialTab = (type) => {
    if (editItemListArray?.some((item) => item[0] === 'qa-components')) {
      if (type === 'Food') {
        removeEditItem({ id: 'qa-components' });
      }
    }
    if (editItemListArray?.some((item) => item[0] === 'qa-ingredient')) {
      if (type === 'HBC / OTC' || type === 'Non-Food') {
        removeEditItem({ id: 'qa-ingredient' });
      }
    }
    if (editItemListArray?.some((item) => item[0] === 'qa-fresh-meat')) {
      if (type !== 'Fresh - Meat') {
        removeEditItem({ id: 'qa-fresh-meat' });
      }
    }
    if (editItemListArray?.some((item) => item[0] === 'qa-fresh-seafood')) {
      if (type !== 'Fresh - Seafood') {
        removeEditItem({ id: 'qa-fresh-seafood' });
      }
    }
    if (
      editItemListArray?.some((item) => item[0] === 'qa-spec-fresh-produce')
    ) {
      if (type !== 'Fresh - Produce') {
        removeEditItem({ id: 'qa-spec-fresh-produce' });
      }
    }
    if (editItemListArray?.some((item) => item[0] === 'qa-spec-fresh-deli')) {
      if (type !== 'Fresh - Deli') {
        removeEditItem({ id: 'qa-spec-fresh-deli' });
      }
    }
  };

  return (
    <QaEditTabCheckerContext.Provider
      value={{
        editItemList,
        addEditItem,
        removeEditItem,
        forceSaveItem,
        forceCancelItem,
        removeSpecialTab,
        manipulateItem,
        editItemListArray,
        forceChangeTab,
        editItemCountByTab,
      }}
    >
      {children && children}
    </QaEditTabCheckerContext.Provider>
  );
};

export const useQaTabChecker = () => {
  const contextValue = useContext(QaEditTabCheckerContext);

  return contextValue;
};
