import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/subscription';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  Tooltip,
  Typography,
} from 'antd';

import { Form, WrapperSelect } from 'common/components';

import { SearchOutlined, CopyOutlined } from '@ant-design/icons';
import { ProductBrickCode } from 'pages/branded-products/components';
import AgGrid from 'common/components/ag-grid/AgGrid';

import * as selectorGlobal from 'redux/global/selectors';
import { NEW_GDSN_SUBSCRIPTION } from 'static/Constants';
import * as endpoints from 'services/product/endpoints';
import { getUserSourceGLN } from 'services/product';
import sortByKey from 'utils/sortByKey';

import './NewGdsnSubscriptionForm.less';

const { Text, Paragraph } = Typography;
const { Search } = Input;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 17,
  },
};

const NewGdsnSubscriptionForm = (props) => {
  const {
    form,
    brick,
    setBrick,
    setIsDisabledSubmit,
    setIsInvalidField,
    showGlnGrid,
    setShowGlnGrid,
  } = props;
  const intl = useIntl();
  const targetMarketList = useSelector(
    selectorGlobal.selectProductTargetMarket()
  );

  const [brickModalVisible, setBrickModalVisible] = useState(false);
  const [gln, setGln] = useState('');
  const [gtin, setGtin] = useState('');
  const [gtin14Format, setGtin14Format] = useState('');
  const [suggestDigit, setSuggestDigit] = useState('');
  const [gtinError, setGtinError] = useState('');
  const [gtinInfo, setGtinInfo] = useState('');

  const [searchText, setSearchText] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [sourceGLNList, setSourceGLNList] = useState([]);

  const columns = [
    {
      checkboxSelection: true,
      filter: false,
      resizable: true,
      suppressMenu: true,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      displayName: 'Party GLN',
      fieldName: 'PartyGln',
      fieldNameCamelCase: 'partyGln',
      resizable: true,
      width: 150,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      displayName: 'Party Name',
      fieldName: 'PartyName',
      fieldNameCamelCase: 'partyName',
      resizable: true,
      flex: 1,
      width: 200,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      displayName: 'Country Name',
      fieldName: 'PartyCountryName',
      fieldNameCamelCase: 'partyCountryName',
      resizable: true,
      width: 250,
    },
  ];

  useEffect(() => {
    if (gtin?.length > 0 && isNumeric(gtin)) {
      if (gtin.length <= 7 || (gtin.length >= 9 && gtin.length <= 11)) {
        setGtinError(NEW_GDSN_SUBSCRIPTION.ERROR);
        setGtinInfo('');
      } else if (!validateGtin(gtin)) {
        setGtinError('');
        setGtinInfo(NEW_GDSN_SUBSCRIPTION.WRONG_FORMAT);
      } else if (validateGtin(gtin)) {
        setGtinError('');
        setGtinInfo('');
      }
    } else if (gtin?.length === 0) {
      setGtinError('');
      setGtinInfo('');
    }
  }, [gtin]);

  useEffect(() => {
    setIsDisabledSubmit(gtinError !== '' || gtinInfo !== '');
  }, [gtinError, gtinInfo]);

  useEffect(() => {
    getUserSourceGLN().then((res) => {
      if (res?.isSuccess) {
        setSourceGLNList(res?.data?.memberGLNs);
      }
    });
  }, []);

  useEffect(() => {
    if (setSourceGLNList) {
      form.setFieldsValue({ fromGln: sourceGLNList?.[0] });
    }
  }, [sourceGLNList]);

  const validateGtin = (value) => {
    let barcode = value.substring(0, value.length - 1);
    let checksum = parseInt(value.substring(value.length - 1), 10);
    let calcSum = 0;
    let calcChecksum = 0;

    barcode.split('').map((number, index) => {
      number = parseInt(number, 10);
      if (value.length % 2 === 0) {
        index += 1;
      }
      if (index % 2 === 0) {
        calcSum += number;
      } else {
        calcSum += number * 3;
      }
    });

    calcSum %= 10;
    calcChecksum = calcSum === 0 ? 0 : 10 - calcSum;
    setSuggestDigit(calcChecksum);
    if (calcChecksum !== checksum) {
      return false;
    }

    return true;
  };

  // Brick code
  const handleBrickCode = (brick) => {
    setBrick({
      brickCode: brick?.BrickCode,
      brickCodeName: brick?.BrickDescription,
    });
    setBrickModalVisible(false);
    setGtin('');
    setGtin14Format('');
    setGtinError('');
    setGtinInfo('');
    form.setFieldsValue({ targetGtin: '' });
    setIsInvalidField(false);
  };

  const onHandleSearchGln = () => {
    setShowGlnGrid(!showGlnGrid);
  };

  const onChangeGtin = (event) => {
    setGtin(event.target.value);
    setGtin14Format('');
    setBrick('');
  };

  const onChangeGln = (event) => {
    setGln(event.target.value);
  };

  const handleSourceGLN = (value) => {
    form.setFieldsValue({ fromGln: value });
  };

  const onValuesChange = (changedValues, allValues) => {
    setIsInvalidField(
      !allValues?.targetGln &&
        !allValues?.targetGtin &&
        !allValues?.targetMarket &&
        !brick
    );
  };

  const isNumeric = (value) => {
    const numberRegex = new RegExp(/^[0-9]*$/);
    return value && numberRegex.test(value);
  };

  const showGtin14Format = () => {
    setGtin14Format(formatGtin14(gtin.substring(0, gtin.length - 1)));
  };

  const hideGtin14Format = () => {
    setGtin14Format('');
  };

  const copyGtin = () => {
    const gtinCopy =
      gtin?.length !== 14 && gtin14Format === ''
        ? gtin.substring(0, gtin.length - 1).concat(suggestDigit.toString())
        : gtin14Format
            .substring(0, gtin14Format)
            .concat(suggestDigit.toString());
    return gtinCopy;
  };

  const formatGtin14 = (value) => {
    const zeroNumber = '000000';
    // showGtin14: gin.length - 1, so return slice (-14 + 1)
    return (zeroNumber + value).slice(-14 + 1);
  };

  const handleNumberInput = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onSetSelectedRows = (selectedNodesDetail) => {
    const fisrtSelectPartyDetail = selectedNodesDetail?.[0];
    form.setFieldsValue({
      targetGln: fisrtSelectPartyDetail?.partyGln,
    });
    setGln(fisrtSelectPartyDetail?.partyGln);
    setIsInvalidField(false);
  };

  const mapId = (item) => ({
    ...item,
    id: `${item?.partyGln}`,
  });

  return (
    <Row>
      <Col span={showGlnGrid ? 10 : 24}>
        <Form
          {...layout}
          form={form}
          onValuesChange={onValuesChange}
          className='new-gdsn-subscription-form'
        >
          <Form.Item
            name='fromGln'
            label={intl.formatMessage(Messages.recipientGln)}
            rules={[
              {
                required: true,
                message: intl.formatMessage(Messages.recipientGlnIsRequired),
              },
            ]}
          >
            <WrapperSelect
              showSearch
              onChange={handleSourceGLN}
              placeholder='Please choose a Recipient GLN'
              filterOption={(input, option) =>
                option.props.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) >= 0
              }
            >
              {sourceGLNList
                ?.slice()
                .sort()
                .map((item, idx) => (
                  <Select.Option value={item} key={idx}>
                    {item}
                  </Select.Option>
                ))}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            name='targetGln'
            label={intl.formatMessage(Messages.publisherGln)}
          >
            <Row gutter={4}>
              <Col flex='calc(100% - 55px)'>
                <Input
                  onChange={onChangeGln}
                  value={gln}
                  onKeyPress={handleNumberInput}
                />
              </Col>
              <Col flex='50px'>
                <Tooltip title={intl.formatMessage(Messages.search)}>
                  <Button
                    type='primary'
                    style={{ width: 50 }}
                    icon={<SearchOutlined />}
                    onClick={onHandleSearchGln}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            name='targetGtin'
            label={intl.formatMessage(Messages.gtin)}
          >
            <Input
              maxLength={14}
              minLength={8}
              onChange={onChangeGtin}
              onKeyPress={handleNumberInput}
            />
          </Form.Item>

          {isNumeric(gtin) && (gtinError || gtinInfo) && (
            <Row className='gdsn-subscription-gtin'>
              <Col offset={6}></Col>
              <Col span={17}>
                {gtinError && (
                  <Row className='gdsn-subscription-gtin__error'>
                    <Col>{gtinError}</Col>
                  </Row>
                )}
                {gtinInfo && (
                  <Row className='gdsn-subscription-gtin__info'>
                    <Col span={showGlnGrid ? 24 : 15}>
                      <Text className='big-number white-text'>
                        {gtin?.length !== 14 && gtin14Format === ''
                          ? gtin.substring(0, gtin.length - 1)
                          : gtin14Format}
                        {}
                      </Text>{' '}
                      <Text
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                      >
                        <Text className='big-number highlight-text'>
                          {suggestDigit}{' '}
                        </Text>{' '}
                        <Text className='highlight-text'>
                          <FormattedMessage {...Messages.yourCheckDigit} />
                        </Text>
                      </Text>
                      <CopyToClipboard text={copyGtin()}>
                        <Paragraph className='copy-key'>
                          <CopyOutlined />{' '}
                          <FormattedMessage {...Messages.copyThisKey} />
                        </Paragraph>
                      </CopyToClipboard>
                      {gtin?.length !== 14 && gtin14Format === '' && (
                        <Paragraph
                          className='convert-format'
                          onClick={showGtin14Format}
                        >
                          <FormattedMessage {...Messages.seeGTIN14Format} />
                        </Paragraph>
                      )}
                      {gtin14Format !== '' && (
                        <Paragraph
                          className='convert-format'
                          onClick={hideGtin14Format}
                        >
                          <FormattedMessage {...Messages.seeOriginalFormat} />
                        </Paragraph>
                      )}
                    </Col>
                    <Col
                      span={showGlnGrid ? 24 : 9}
                      style={{ paddingTop: showGlnGrid ? '0px' : '5px' }}
                    >
                      <FormattedMessage {...Messages.correctKeyGtinFormat} />
                      {gtin14Format ? 14 : gtin?.length}{' '}
                      <FormattedMessage {...Messages.format} />
                    </Col>
                  </Row>
                )}
              </Col>

              <Col offset={1}></Col>
            </Row>
          )}

          <Form.Item label={intl.formatMessage(Messages.gpc)} name='targetGpc'>
            <Row gutter={4}>
              <Col flex='calc(100% - 55px)'>
                <InputNumber
                  style={{ width: '100%' }}
                  value={brick?.brickCode}
                  disabled
                />
              </Col>
              <Col flex='50px'>
                <Tooltip title={intl.formatMessage(Messages.chooseBrickCode)}>
                  <Button
                    type='primary'
                    style={{ height: '28px', width: '50px' }}
                    onClick={() => setBrickModalVisible(true)}
                  >
                    <FormattedMessage {...Messages.select} />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(Messages.targetMarket)}
            name='targetMarket'
          >
            <WrapperSelect
              allowClear
              showSearch
              placeholder={intl.formatMessage(Messages.selectTargetMarket)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {targetMarketList?.length > 0 &&
                sortByKey(targetMarketList, 'Country')?.map((item, index) => (
                  <Select.Option key={index} value={item?.Country}>
                    {item?.Country}
                  </Select.Option>
                ))}
            </WrapperSelect>
          </Form.Item>

          <ProductBrickCode
            visible={brickModalVisible}
            handleCancel={() => setBrickModalVisible(false)}
            handleBrickCode={handleBrickCode}
            initialBrickCode={brick?.brickCode}
          />
        </Form>
      </Col>
      {showGlnGrid && (
        <Col span={14}>
          <Row>
            <Col span={24} style={{ minHeight: '48vh', paddingBottom: '20px' }}>
              <div style={{ paddingBottom: '5px', width: '100%' }}>
                <Search
                  placeholder='Search'
                  allowClear
                  onSearch={(value) => setSearchText(value)}
                />
              </div>
              {columns?.length > 0 && (
                <AgGrid
                  columnDefs={columns}
                  urlGrid={endpoints.GET_PARTY_INFO_GRID}
                  urlGridDistinct={endpoints.GET_PARTY_INFO_GRID_DISTINCT}
                  getGridApi={(gridApiParams) => {
                    setGridApi(gridApiParams);
                  }}
                  onHandleSelectedRowCustom={onSetSelectedRows}
                  responseParams='data'
                  paramsGrid={{
                    search: {
                      searchText,
                    },
                  }}
                  gridConfigProps={{
                    rowSelection: 'single',
                  }}
                  mapId={mapId}
                  isSmallSizePagination={true}
                />
              )}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default NewGdsnSubscriptionForm;
