import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { dialogFunction } from 'common/components';

// import { ReactComponent as IconDownloadAsset } from 'common/components/button/svg-icons/ICO_70.svg';
import { DownloadOutlined } from '@ant-design/icons';
import DigitalRight from 'assets/DRM.png';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import { useIntl } from 'react-intl';

const DownloadAsset = ({ downloadAsset, disabled, isDRM, viewType }) => {
  const intl = useIntl();
  function handleDownload() {
    dialogFunction({
      type: 'warn',
      content: (
        <div style={{ fontSize: 13 }}>
          <img
            src={DigitalRight}
            alt='digital-right'
            height={20}
            width={20}
            style={{ marginRight: 5 }}
          />
          {isDRM === 'single'
            ? intl.formatMessage(Messages.drmSingle)
            : intl.formatMessage(Messages.drmMultiple)}
        </div>
      ),

      okText: 'Cancel',
      onCancel: downloadAsset,
      cancelText: 'Download',
    });
  }
  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<DownloadOutlined />}
      label={
        viewType === 'assetFullView'
          ? Messages.downloadAsset
          : Messages.downloadAssets
      }
      onClick={() => {
        isDRM === 'single' || isDRM === 'multi'
          ? handleDownload()
          : downloadAsset();
      }}
      disabled={disabled}
    />
  );
};

DownloadAsset.propTypes = {
  downloadAsset: PropTypes.func,
};

export default DownloadAsset;
