import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconShared } from 'assets/shared-ribbon/ManageShared.svg';
import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/share';
import { injectIntl } from 'react-intl';

const ManageSharing = (props) => {
  const history = useHistory();
  const { isSectionDisabled } = props;

  const onClickHandler = () => {
    const redirectUrl = getFilterByTypeUrl();
    !isActive && history.push(redirectUrl);
  };

  const currentLocation = history.location.pathname;

  const getEntityType = () => {
    // const currentLocation = history.location.pathname;
    // const entityTypeUrl = currentLocation.split('/')[1];

    // get correct entity type to put on query string for filtering
    const entityTypeChanger = {
      '/folders': 'Folder',
      '/digital-media': 'Asset',
      '/reportings': 'Reporting',
      '/favorite/queries': 'Query',
      '/products': 'Product',
    };

    return entityTypeChanger[currentLocation];
  };

  const getFilterByTypeUrl = () => {
    const entityType = getEntityType();

    const redirectUrl = entityType
      ? `/manage-shared?type=${entityType}`
      : `/manage-shared`;

    return redirectUrl;
  };

  const checkIsActive = () => {
    const currentLocation = history.location.pathname;
    const view = currentLocation.split('/')[1];
    return view === 'manage-shared';
  };

  const isActive = checkIsActive();
  const entityType = getEntityType();
  const disabled = isSectionDisabled || !entityType;

  return (
    <>
      <RibbonButton
        icon={<IconShared style={{ height: 26 }} />}
        label={Messages.manageSharing}
        disabled={currentLocation === '/manage-shared' ? undefined : disabled}
        onClick={onClickHandler}
        toggle={isActive}
      />
    </>
  );
};

ManageSharing.propTypes = {
  isSectionDisabled: PropTypes.bool,
};

export default injectIntl(ManageSharing);
