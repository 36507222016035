import React from 'react';

import { Typography } from 'antd';
import { RoleEmptySection } from '../';
import MemberRoleDescriptionFormItem from './MemberRoleDescriptionFormItem';

import './styles.less';

const { Text } = Typography;

const MemberRoleDescription = (props) => {
  const { selectedRole, formRole, isEdit } = props;

  const roleDescription = isEdit
    ? formRole?.values?.roleDescription
    : selectedRole?.roleDescription;

  return (
    <div className='member-role-desc-section'>
      {selectedRole ? (
        <>
          {!isEdit ? (
            <>
              {roleDescription ? (
                <Text>{roleDescription}</Text>
              ) : (
                <RoleEmptySection />
              )}
            </>
          ) : (
            <MemberRoleDescriptionFormItem
              roleDescription={roleDescription}
              formRole={formRole}
              selectedRole={selectedRole}
            />
          )}
        </>
      ) : (
        <RoleEmptySection />
      )}
    </div>
  );
};

export default MemberRoleDescription;
