import React from 'react';
import Messages from 'i18n/messages/maintenance';
import { DeliveredProcedureOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const Members = () => {
  const handleMoveToExecutionTemplateView = () => {};

  return (
    <RibbonButton
      icon={<DeliveredProcedureOutlined />}
      label={Messages.executeTemplate}
      onClick={handleMoveToExecutionTemplateView()}
    />
  );
};

export default Members;
