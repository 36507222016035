import React from 'react';

import { Tooltip } from 'antd';
import { get } from 'lodash';

import { FolderTileBody, FolderTileFooter, FolderTileHeader } from '../index';

import useDoubleClick from 'hooks/useDoubleClick';

import { forwardTo } from 'utils/common/route';
import './FolderTile.less';

const FolderTile = (props) => {
  const { dataDetail, pathname, disableEvent } = props;
  const { onClickItemGrid, onDoubleClick } = props?.clickEvents || {};

  const folderName = get(dataDetail, 'folderName', '');

  const divRef = React.useRef();

  useDoubleClick({
    onSingleClick: (e) => {
      if (disableEvent) return;
      if (onClickItemGrid) onClickItemGrid(dataDetail, e);
    },
    onDoubleClick: (e) => {
      if (disableEvent) return;
      if (onDoubleClick) {
        forwardTo(`${pathname}/${dataDetail?.id}`);
      }
    },
    ref: divRef,
    latency: 250,
    isEnabled: !disableEvent,
  });

  return (
    <Tooltip title={folderName}>
      <div className='folder-tile'>
        {disableEvent ? null : (
          <div
            style={{
              backgroundColor: 'transparent',
              position: 'absolute',
              zIndex: 30,
              width: '100%',
              height: '100%',
            }}
            ref={divRef}
          />
        )}
        <FolderTileHeader dataDetail={dataDetail} />
        <FolderTileBody dataDetail={dataDetail} />
        <FolderTileFooter dataDetail={dataDetail} />
      </div>
    </Tooltip>
  );
};

export default FolderTile;
