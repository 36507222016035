import { useQuery } from '@tanstack/react-query';

import { useFormatMessage } from 'hooks';

import * as ticketServices from 'services/ticketingSystem';

import {
  CHANGELOG_CONTACT_PROPERTIES,
  CHANGELOG_DETAIL_PROPERTIES,
} from 'pages/ticket-full-view/constants';

import Messages from 'i18n/messages/ticket-system';

export const useGetTicketChangeLog = (props) => {
  const messages = useFormatMessage(Messages);
  const {
    changelogListEnabled = false,
    changelogDetailEnabled = false,
    ticketId,
    changelogId,
  } = props;

  const getTicketChangelogList = async ({ queryKey }) => {
    const response = await ticketServices.getTicketChangeLogList(queryKey[1]);

    const { isSuccess, data } = response;

    return isSuccess ? data?.ticketHistories : [];
  };

  const getTicketChangelogDetail = async ({ queryKey }) => {
    const response = await ticketServices.getTicketChangeLogDetail(queryKey[1]);

    const { isSuccess, data } = response;

    return isSuccess ? data : null;
  };

  const changelogListQuery = useQuery({
    queryKey: ['ticket-changelog-list', { ticketId }],
    queryFn: getTicketChangelogList,
    enabled: changelogListEnabled,
  });

  const changelogDetailQuery = useQuery({
    queryKey: [
      'ticket-changelog-detail',
      { TicketTrackingGroupId: changelogId },
    ],
    queryFn: getTicketChangelogDetail,
    enabled: changelogDetailEnabled,
    staleTime: Infinity,
  });

  const changelogDetailData = changelogDetailQuery?.data;

  const transformChangelogData = (rawChangelogData) => {
    if (!rawChangelogData) return [];

    //* row data is missing contact - generate contact from detail
    const { ticketHistoryDetails, ...restData } = rawChangelogData;

    const ticketHistoryPureDetails =
      generateDetailProperty(ticketHistoryDetails);
    const ticketHistoryContacts = generateContactProperty(ticketHistoryDetails);

    const readyTransformData = {
      ticketHistoryDetails: ticketHistoryPureDetails,
      ticketHistoryContacts,
      ...restData,
    };

    const changelogGeneratorMap = {
      ticketHistoryAssets: generateAssetProperty,
      ticketHistoryAttachments: generateAttachmentProperty,
      ticketHistoryProduct: generateProductProperty,
      ticketHistoryDescription: generateDescriptionProperty,
      ticketHistoryComments: generateCommentsProperty,
    };

    //* transform
    return Object.keys(readyTransformData)
      ?.filter((changelogKey) => {
        return (
          (changelogKey === 'ticketHistoryDescription' &&
            readyTransformData[changelogKey]) || //* ticketHistoryDescription is object
          readyTransformData[changelogKey]?.length > 0
        ); //* filter no data
      })
      .map((changelogKey) => {
        const changelogDataValue =
          changelogKey === 'ticketHistoryDescription'
            ? [readyTransformData[changelogKey]] //* parse object to array
            : readyTransformData[changelogKey];

        const title = messages.changelogTitle[changelogKey];
        const generator = changelogGeneratorMap[changelogKey];

        const properties = generator
          ? generator()
          : //* default generator
            changelogDataValue.map((changelogItem) => {
              return {
                layout: { span: 24 },
                info: {
                  label: messages.changelogLabel[changelogItem.fieldName],
                  value: generateChangelogListValue(
                    changelogKey,
                    changelogItem
                  ),
                },
              };
            });

        return {
          title,
          properties,
        };
      });
  };

  const generateChangelogListValue = (key, value) => {
    const oldDataList = value?.oldData?.split(',');
    const newDataList = value?.newData?.split(',');

    return {
      previous: oldDataList,
      current: newDataList,
    };
  };

  const generateDetailProperty = (ticketHistoryDetails) => {
    return ticketHistoryDetails
      .filter((detailPropertyItem) => {
        return CHANGELOG_DETAIL_PROPERTIES.includes(
          detailPropertyItem.fieldName
        );
      })
      .sort((a, b) => {
        return (
          CHANGELOG_DETAIL_PROPERTIES.indexOf(a.fieldName) -
          CHANGELOG_DETAIL_PROPERTIES.indexOf(b.fieldName)
        );
      });
  };

  const generateContactProperty = (ticketHistoryDetails) => {
    return ticketHistoryDetails
      .filter((detailPropertyItem) => {
        return CHANGELOG_CONTACT_PROPERTIES.includes(
          detailPropertyItem.fieldName
        );
      })
      .sort((a, b) => {
        return (
          CHANGELOG_CONTACT_PROPERTIES.indexOf(a.fieldName) -
          CHANGELOG_CONTACT_PROPERTIES.indexOf(b.fieldName)
        );
      });
  };

  const generateAssetProperty = () => {
    const assetPropertyValue = changelogDetailData?.ticketHistoryAssets;

    return [
      {
        layout: { span: 24 },
        info: {
          label: messages.changelogLabel['name'],
          value: {
            previous: generateValueBaseOnType({
              valueList: assetPropertyValue,
              type: 'removed',
              fieldName: 'name',
            }),
            current: generateValueBaseOnType({
              valueList: assetPropertyValue,
              type: 'added',
              fieldName: 'name',
            }),
          },
        },
      },
    ];
  };

  const generateProductProperty = () => {
    const productPropertyValue = changelogDetailData?.ticketHistoryProduct;

    return [
      {
        layout: { span: 24 },
        info: {
          label: messages.changelogLabel['name'],
          value: {
            previous: generateValueBaseOnType({
              valueList: productPropertyValue,
              type: 'removed',
              fieldName: 'name',
            }),
            current: generateValueBaseOnType({
              valueList: productPropertyValue,
              type: 'added',
              fieldName: 'name',
            }),
          },
        },
      },
    ];
  };

  const generateAttachmentProperty = () => {
    const attachmentPropertyValue =
      changelogDetailData?.ticketHistoryAttachments;

    return [
      {
        layout: { span: 24 },
        info: {
          label: messages.changelogLabel['name'],
          value: {
            previous: generateValueBaseOnType({
              valueList: attachmentPropertyValue,
              type: 'removed',
              fieldName: 'name',
            }),
            current: generateValueBaseOnType({
              valueList: attachmentPropertyValue,
              type: 'added',
              fieldName: 'name',
            }),
          },
        },
      },
    ];
  };

  const generateDescriptionProperty = () => {
    const descriptionPropertyValue =
      changelogDetailData?.ticketHistoryDescription;

    return [
      {
        layout: { span: 24 },
        info: {
          block: true,
          contentType: 'html',
          value: {
            previous: descriptionPropertyValue.oldData
              ? [descriptionPropertyValue.oldData]
              : null,
            current: descriptionPropertyValue.newData
              ? [descriptionPropertyValue.newData]
              : null,
          },
        },
      },
    ];
  };

  const generateCommentsProperty = () => {
    const commentPropertyValue = changelogDetailData?.ticketHistoryComments;

    return [
      {
        layout: { span: 24 },
        info: {
          block: true,
          contentType: 'html',
          value: {
            previous: generateValueBaseOnType({
              valueList: commentPropertyValue,
              type: 'removed',
              fieldName: 'oldComment',
            }),
            current: generateValueBaseOnType({
              valueList: commentPropertyValue,
              type: 'added',
              fieldName: 'newComment',
            }),
          },
        },
      },
    ];
  };

  const generateValueBaseOnType = ({ valueList, type, fieldName }) => {
    return valueList
      .filter(
        (assetItem) =>
          assetItem.description === type || assetItem.description === 'edited'
      )
      .map((assetItem) => assetItem[fieldName]);
  };

  const changelogRenderData = transformChangelogData(changelogDetailData);

  return { changelogListQuery, changelogDetailQuery, changelogRenderData };
};
