import { useSelector } from 'react-redux';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import {
  pathnameFavMember,
  pathnameFavAssets,
  pathnameFavFolders,
  pathnameFavProducts,
  pathnameProduct,
  pathnameMember,
  pathnameDigitalMedia,
  pathnameDigitalMediaNew,
  pathnameAssets,
  pathnameDocument,
  pathnameMultiMedia,
  pathnameReportings,
  pathnameReportingsOwned,
  pathnameReportingsShared,
  pathnameFolders,
  pathnameFoldersOwned,
  pathnameFoldersShared,
  pathnameAssetsForMember,
} from 'static/Constants';

const useGetDataIdQuery = (pathname) => {
  let data = [];
  const favMembersIdQuery = useSelector(
    gridViewSelectors.makeSelectFavMembersIdQuery()
  );
  const favAssetsIdQuery = useSelector(
    gridViewSelectors.makeSelectFavAssetsIdQuery()
  );
  const favFoldersIdQuery = useSelector(
    gridViewSelectors.makeSelectFavFoldersIdQuery()
  );
  const favProductsIdQuery = useSelector(
    gridViewSelectors.makeSelectFavProductsIdQuery()
  );
  const productsIdQuery = useSelector(
    gridViewSelectors.makeSelectProductsIdQuery()
  );
  const membersIdQuery = useSelector(
    gridViewSelectors.makeSelectMembersIdQuery()
  );
  const digitalMediaIdQuery = useSelector(
    gridViewSelectors.makeSelectDigitalMediaIdQuery()
  );
  const digitalMediaIdQueryNew = useSelector(
    gridViewSelectors.makeSelectDigitalMediaIdQueryNew()
  );
  const assetsIdQuery = useSelector(
    gridViewSelectors.makeSelectAssetsIdQuery()
  );
  const documentsIdQuery = useSelector(
    gridViewSelectors.makeSelectDocumentsIdQuery()
  );
  const multiMediaIdQuery = useSelector(
    gridViewSelectors.makeSelectMultiMediaIdQuery()
  );
  const reportingsIdQuery = useSelector(
    gridViewSelectors.makeSelectReportingsIdQuery()
  );
  const myReportsIdQuery = useSelector(
    gridViewSelectors.makeSelectMyReportsIdQuery()
  );
  const reportsSharedToMeIdQuery = useSelector(
    gridViewSelectors.makeSelectReportsSharedToMeIdQuery()
  );
  const foldersIdQuery = useSelector(
    gridViewSelectors.makeSelectFoldersIdQuery()
  );
  const myFoldersIdQuery = useSelector(
    gridViewSelectors.makeSelectMyFoldersIdQuery()
  );
  const foldersSharedToMeIdQuery = useSelector(
    gridViewSelectors.makeSelectFoldersSharedToMeIdQuery()
  );
  const assetsForMemberIdQuery = useSelector(
    gridViewSelectors.makeSelectAssetsForMemberIdQuery()
  );

  switch (pathname) {
    case pathnameFavMember:
      data = favMembersIdQuery;
      break;
    case pathnameFavAssets:
      data = favAssetsIdQuery;
      break;
    case pathnameFavFolders:
      data = favFoldersIdQuery;
      break;
    case pathnameFavProducts:
      data = favProductsIdQuery;
      break;
    case pathnameProduct:
      data = productsIdQuery;
      break;
    case pathnameMember:
      data = membersIdQuery;
      break;
    case pathnameDigitalMedia:
      data = digitalMediaIdQuery;
      break;
    case pathnameDigitalMediaNew:
      data = digitalMediaIdQueryNew;
      break;
    case pathnameAssets:
      data = assetsIdQuery;
      break;
    case pathnameDocument:
      data = documentsIdQuery;
      break;
    case pathnameMultiMedia:
      data = multiMediaIdQuery;
      break;
    case pathnameReportings:
      data = reportingsIdQuery;
      break;
    case pathnameReportingsOwned:
      data = myReportsIdQuery;
      break;
    case pathnameReportingsShared:
      data = reportsSharedToMeIdQuery;
      break;
    case pathnameFolders:
      data = foldersIdQuery;
      break;
    case pathnameFoldersOwned:
      data = myFoldersIdQuery;
      break;
    case pathnameFoldersShared:
      data = foldersSharedToMeIdQuery;
      break;
  }
  if (pathname.includes(pathnameAssetsForMember)) {
    data = assetsForMemberIdQuery;
  }
  return data;
};

export default useGetDataIdQuery;
