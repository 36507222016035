import React, { useRef } from 'react';

import { Space, Typography } from 'antd';

import {
  CarouselSection,
  CarouselNext,
  CarouselPrevious,
} from 'common/components';

import {
  EmptyGroup,
  GroupAllergens,
  GroupFactsPanelType,
  GroupIngredients,
  PartStatements,
} from '../multiple-panel/components';

import { getComponentFactsPanelType } from '../multiple-panel/components/utils';

import '../multiple-panel/components/GroupLayout.less';

const MAX_SLIDES_TO_SHOW = 2;

export const GroupAssignIngredients = ({
  allIngredientGroups,
  additionalIngredientGroups = [],
}) => {
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Typography.Text
        strong
        style={{ textTransform: 'uppercase', fontSize: 14 }}
      >
        Ingredients:
      </Typography.Text>

      {allIngredientGroups?.length > 0 ? (
        allIngredientGroups.map((ingredientGroup, index) => {
          const isAdditionPart =
            ingredientGroup?.index === additionalIngredientGroups?.[0]?.index;

          return (
            <GroupIngredients
              checked={isAdditionPart ? true : false}
              className='group-layout__assign-additional'
            >
              <PartStatements
                key={ingredientGroup?.index}
                className='scroller'
                title={`Ingredient ${index + 1}`}
                statements={ingredientGroup?.ingredients}
              />
            </GroupIngredients>
          );
        })
      ) : (
        <EmptyGroup />
      )}
    </Space>
  );
};

export const GroupAssignAllergens = ({
  allAllergenGroups,
  additionalAllergenGroups,
}) => {
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Typography.Text
        strong
        style={{ textTransform: 'uppercase', fontSize: 14 }}
      >
        Contains:
      </Typography.Text>

      {allAllergenGroups?.length > 0 ? (
        allAllergenGroups.map((allergenGroup, index) => {
          const isAdditionPart =
            allergenGroup?.index === additionalAllergenGroups?.[0]?.index;

          return (
            <GroupAllergens
              checked={isAdditionPart ? true : false}
              className='group-layout__assign-additional'
            >
              <PartStatements
                key={index}
                className='scroller'
                title={`Allergen ${index + 1}`}
                statements={allergenGroup?.allergens?.map(
                  (allergen) => allergen.allergenType
                )}
              />
            </GroupAllergens>
          );
        })
      ) : (
        <EmptyGroup />
      )}
    </Space>
  );
};

export const GroupAssignFactsPanels = ({
  factsPanels = [],
  additionalDrugFacts = [],
  additionalNutritionFacts = [],
  additionalSupplementFacts = [],
  additionalPetFood = [],
}) => {
  const ref = useRef();
  const disabledCarouselBtn = factsPanels?.length <= MAX_SLIDES_TO_SHOW;

  const additionalPanel = [
    ...additionalDrugFacts,
    ...additionalNutritionFacts,
    ...additionalSupplementFacts,
    ...additionalPetFood,
  ];

  return (
    <>
      {disabledCarouselBtn ? null : (
        <Space>
          <CarouselPrevious
            onClick={() => {
              if (ref?.current) ref.current.prev();
            }}
            disabled={disabledCarouselBtn} // need to check permission in here
          />
          <CarouselNext
            onClick={() => {
              if (ref?.current) ref.current.next();
            }}
            disabled={disabledCarouselBtn} // need to check permission in here
          />
        </Space>
      )}

      <div style={{ marginTop: 12 }}>
        <CarouselSection
          ref={ref}
          slidesToShow={MAX_SLIDES_TO_SHOW}
          infinite={false}
          variableWidth
        >
          {factsPanels?.length > 0 ? (
            factsPanels?.map((factsPanel, index) => {
              const FactsPanelType = getComponentFactsPanelType(factsPanel);

              const isAdditionPart =
                factsPanel?.id === additionalPanel?.[0]?.id;

              return (
                <Space key={factsPanel.id}>
                  <GroupFactsPanelType
                    checked={isAdditionPart ? true : false}
                    className='group-layout__assign-additional group-layout__assign-additional--facts-panel'
                  >
                    <div className='group-layout__assign-fact-panel'>
                      <FactsPanelType key={index} label={factsPanel} />
                    </div>
                  </GroupFactsPanelType>
                </Space>
              );
            })
          ) : (
            <EmptyGroup />
          )}
        </CarouselSection>
      </div>
    </>
  );
};
