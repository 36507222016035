import React from 'react';
import PropTypes from 'prop-types';
import { SafetyCertificateFilled } from '@ant-design/icons';

import './ProductOverviewSubscriptionShield.less';

const ProductOverviewSubscriptionShield = ({ product, ...rest }) => {
  const color = product?.subscriptionShield;

  return (
    <>
      <div className={`subscription-shield`}>
        <SafetyCertificateFilled
          className={`${
            !color ? '' : 'subscription-shield--' + color?.toLowerCase()
          }`}
        />
      </div>
    </>
  );
};

ProductOverviewSubscriptionShield.propTypes = {
  product: PropTypes.object,
};

export default ProductOverviewSubscriptionShield;
