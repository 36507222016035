import { put, all, delay } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import * as types from './constants';
import * as actions from './actions';

export function* toggleReloadDocumentCertificateTab() {
  yield put(actions.qaToggleReloadDocumentCertificateTabSuccess(true));
  yield delay(200);
  yield put(actions.qaToggleReloadDocumentCertificateTabSuccess(false));
}

export function* toggleReloadDocumentWhenDelete() {
  yield put(actions.qaToggleReloadDocumentWhenDeleteSuccess(true));
  yield delay(200);
  yield put(actions.qaToggleReloadDocumentWhenDeleteSuccess(false));
}

export function* qaSpecEditBulk() {
  yield put(actions.qaSpecEditBulkSuccess(true));
  yield delay(200);
  yield put(actions.qaSpecEditBulkSuccess(false));
}

export default function* watchMaintenceForm() {
  yield all([
    yield takeLatest(
      types.QA_TOGGLE_RELOAD_DOCUMENT_CERTIFICATE_TAB,
      toggleReloadDocumentCertificateTab
    ),
    takeLatest(
      types.QA_TOGGLE_RELOAD_DOCUMENT_WHEN_DELETE,
      toggleReloadDocumentWhenDelete
    ),
    takeLatest(types.QA_SPEC_EDIT_BULK, qaSpecEditBulk),
  ]);
}
