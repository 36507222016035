import { useState } from 'react';

import {
  COLOR_PASSWORD,
  STRENGTH_PASSWORD,
  VALIDATION_RULES,
} from 'static/Constants';

import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/user-profile';

export const useChangeUserPassword = ({ mode }) => {
  const validationRules = VALIDATION_RULES[mode];

  const strongRegex = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[^\s]{8,}$/
  );

  const mediumRegex = new RegExp(
    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))[^\s]{6,}$/
  );

  const weakRegex = new RegExp(/[^\s]{6,}$/);
  const anyRegex = new RegExp(/[^\s]{1,}$/);

  const [newPassword, setNewPassword] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordColor, setPasswordColor] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(
    STRENGTH_PASSWORD.WEAK
  );
  const [validatedNewPass, setValidatedNewPass] = useState(false);

  const validatePassword = (password) => {
    setValidatedNewPass(validateNewPassword(password));
    setPasswordScore(calculateScore(password));
  };

  const validateNewPassword = (password) => {
    const passwordRegexCollection = {
      [STRENGTH_PASSWORD.STRONG]: strongRegex,
      [STRENGTH_PASSWORD.MEDIUM]: mediumRegex,
      [STRENGTH_PASSWORD.WEAK]: weakRegex,
    };

    const passwordRegex = passwordRegexCollection[mode] || anyRegex;

    return passwordRegex.test(password);
  };

  const calculateScore = (password) => {
    const lowerRegex = new RegExp('[a-z]');
    const upperRegex = new RegExp('[A-Z]');
    const numberRegex = new RegExp('[0-9]');
    const specialCharRegex = new RegExp('[!@#$%^&*]');
    if (password.length < 6) {
      setPasswordColor(COLOR_PASSWORD.WEAK);
      setPasswordStrength(STRENGTH_PASSWORD.WEAK);
      return password.length * 2;
    }
    let score = 0;
    if (password.length >= 6) {
      score += 10;
    }
    if (password.length >= 8) {
      score += 10;
    }
    if (lowerRegex.test(password)) {
      score += 15;
    }
    if (upperRegex.test(password)) {
      score += 15;
    }
    if (numberRegex.test(password)) {
      score += 15;
    }
    if (specialCharRegex.test(password)) {
      score += 15;
    }
    if (score >= 80) {
      setPasswordColor(COLOR_PASSWORD.STRONG);
      setPasswordStrength(STRENGTH_PASSWORD.STRONG);
    } else if (score >= 40 && score < 80) {
      setPasswordColor(COLOR_PASSWORD.MEDIUM);
      setPasswordStrength(STRENGTH_PASSWORD.MEDIUM);
    } else if (score < 40) {
      setPasswordColor(COLOR_PASSWORD.WEAK);
      setPasswordStrength(STRENGTH_PASSWORD.WEAK);
    }
    return score;
  };

  const passwordRuleValidator = () => ({
    validator(_, value) {
      if (validateNewPassword(value) || value === '') {
        return Promise.resolve();
      }
      return Promise.reject(
        <FormattedMessage {...Messages.passwordNotComplex} />
      );
    },
  });

  return {
    newPassword,
    setNewPassword,
    validatedNewPass,
    validatePassword,
    passwordRuleValidator,
  };
};
