import React from 'react';
import PropTypes from 'prop-types';
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Space, Row, Col, Typography } from 'antd';
import { saveFile } from 'utils/saveFile';

const PreviewableModalHeader = (props) => {
  const {
    currentIndex,
    title,
    fileName,
    link,
    previewListLength,
    onSetPreviousPreview,
    onSetNextPreview,
    isCarousel,
    isDownload,
    type,
  } = props;

  return (
    <Row className='previewable-modal--title-wrapper'>
      <Col
        span={8}
        className='previewable-modal--title-name'
        style={{ textAlign: 'left', minHeight: 22 }}
      >
        {title || 'Untitled'}
      </Col>
      <Col span={8} style={{ textAlign: 'center' }}>
        {isCarousel && (
          <Space>
            <Button
              icon={<LeftOutlined />}
              onClick={onSetPreviousPreview}
              disabled={currentIndex <= 0}
            />
            <Typography.Text strong style={{ color: '#000' }}>
              {currentIndex + 1}
            </Typography.Text>
            <Typography.Text>of</Typography.Text>
            <Typography.Text strong style={{ color: '#000' }}>
              {previewListLength}
            </Typography.Text>
            <Button
              icon={<RightOutlined />}
              onClick={onSetNextPreview}
              disabled={currentIndex >= previewListLength - 1}
            />
          </Space>
        )}
      </Col>
      <Col span={8} style={{ textAlign: 'right' }}>
        {isDownload && type && type?.toLowerCase() !== 'iframe' && (
          <Button
            className='preview-modal--title-download'
            onClick={() => saveFile(link, fileName)}
            disabled={!link}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        )}
      </Col>
    </Row>
  );
};

PreviewableModalHeader.propTypes = {
  currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  fileName: PropTypes.string,
  link: PropTypes.string,
  previewListLength: PropTypes.number,
  onSetNextPreview: PropTypes.func,
  onSetPreviousPreview: PropTypes.func,
  isCarousel: PropTypes.bool,
  isDownload: PropTypes.bool,
};

PreviewableModalHeader.defaultProps = {
  isCarousel: false,
  isDownload: true,
};

export default PreviewableModalHeader;
