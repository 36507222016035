/**
 * * check if routing is being blocked or not
 * @returns
 */
export const checkRouteBlocked = (blockId) => {
  if (blockId) {
    return window.urlBlocked?.[blockId]?.blocked;
  }

  const blockedObject = window.urlBlocked;

  return Object.values(blockedObject).some(
    (blockIdItem) => blockIdItem.blocked === true
  );
};

export const checkRouteBlockConfirmStatus = (blockId) => {
  if (blockId) {
    return window.urlBlocked?.[blockId]?.confirmStatus;
  }

  const blockedObject = window.urlBlocked;

  const allConfirmYes = Object.values(blockedObject).every(
    (blockIdItem) => blockIdItem.confirmStatus === true
  );

  const didSomeConfirmCancel = Object.values(blockedObject).some(
    (blockIdItem) => blockIdItem.confirmStatus === false
  );

  if (allConfirmYes) {
    return true;
  }

  if (didSomeConfirmCancel) return false;

  if (!allConfirmYes && !didSomeConfirmCancel) return undefined;
};
