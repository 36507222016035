import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import userSelectors from 'redux/user/selectors';

import * as workflowServices from 'services/workflow';

import * as actionsWf from 'pages/workflow-template/controllers/actions';

import { Input, Select, Modal, notification } from 'antd';

import { Form, WrapperSelect } from 'common/components';

import './CreateWorkFlowModal.less';
import sortByKey from 'utils/sortByKey';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const inputTypesOption = [
  // { label: 'Product', value: 'Product' },
  { label: 'Digital Asset', value: 'DigitalAsset' },
  { label: 'Member', value: 'Member' },
];

const CreateWorkFlowModal = (props) => {
  const { visible, closeModal } = props;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());

  const workflowRoles = useGetWfRoles(1, 50, memberId);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const handleCreateWf = () => {
    form.validateFields().then((values) => {
      const params = {
        ...values,
        memberId,
        fromWorkflowTemplateId: 0,
      };

      workflowServices
        .createWorkflowService(params)
        .then((res) => {
          if (res.isSuccess) {
            notification.success({ message: 'Create workflow successfully' });
            dispatch(actionsWf.reloadGridWf(true));
            closeModal();
          } else {
            notification.success({ message: res.message });
          }
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(actionsWf.reloadGridWf(false));
          }, 500);
        });
    });
  };

  return (
    <Modal
      visible={visible}
      className='create-workflow-modal'
      title={'Create Workflow'}
      onCancel={() => closeModal()}
      onOk={handleCreateWf}
    >
      <Form
        form={form}
        {...layout}
        initialValues={{ bootstrapRole: 'Initiator' }}
      >
        <Form.Item
          name='name'
          label='Name:'
          colon={false}
          rules={[
            {
              required: true,
              message: 'Please input your name',
            },
          ]}
        >
          <Input placeholder='Please input your name' />
        </Form.Item>
        <Form.Item
          name='bootstrapRole'
          label='Role:'
          colon={false}
          rules={[
            {
              required: true,
              message: 'Please select your role',
            },
          ]}
        >
          <WrapperSelect placeholder='Please select your role'>
            {sortByKey(workflowRoles, 'roleName')?.map((option) => (
              <Select.Option value={option.roleName}>
                {option.roleName}
              </Select.Option>
            ))}
          </WrapperSelect>
        </Form.Item>
        <Form.Item
          name='inputType'
          label='Type:'
          colon={false}
          rules={[
            {
              required: true,
              message: 'Please select your type',
            },
          ]}
        >
          <WrapperSelect placeholder='Please select your type'>
            {sortByKey(inputTypesOption, 'label')?.map((option) => (
              <Select.Option value={option.value}>{option.label}</Select.Option>
            ))}
          </WrapperSelect>
        </Form.Item>
        <Form.Item
          name='description'
          label='Description:'
          colon={false}
          rules={[
            {
              required: true,
              message: 'Please input your description',
            },
          ]}
        >
          <Input.TextArea placeholder='Please select your description' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const useGetWfRoles = (pageIndex, pageSize, memberId) => {
  const [state, setState] = React.useState([]);

  useEffect(() => {
    if (!memberId) return;

    workflowServices
      .getAllWfRoles({ pageIndex, pageSize, memberId })
      .then((res) => {
        if (res.isSuccess) {
          setState(res.data.gridData);
        }
      });
  }, [memberId, pageIndex, pageSize]);

  return state;
};

export default CreateWorkFlowModal;
