import React from 'react';

import { FolderAddOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const CreateArticle = () => {
  const handleCreateArticle = () => {
    // dispatch(maintenanceActions.enableCreatePost());
    forwardTo(`/maintenance/help/articles/create`);
  };

  return (
    <RibbonButton
      size='small'
      icon={<FolderAddOutlined />}
      label={Messages.createArticle}
      onClick={handleCreateArticle}
    />
  );
};

export default CreateArticle;
