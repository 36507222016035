import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';
import {
  Fieldset,
  StyledModal,
  Form,
  CustomNotification,
  FlagCountrySelectionFieldInput,
} from 'common/components';
import CreateProductModalFooter from './CreateProductModalFooter';
import { useSelector } from 'react-redux';

import * as ssoProductSelectors from 'pages/sso-product/controllers/selectors';
import { createSupplier } from 'services/memberMaintenance';

import CountryCode from 'static/CountryCode';

import { validateEmail } from 'utils';
import { REGEX_CONTACT_PHONE } from 'static/Constants';

const oneColLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const twoColLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CreateSupplierModal = (props) => {
  const { visible, setVisible, setSupplierList } = props;

  const [loading, setLoading] = useState(false);
  const addProductForm = Form.useFormInstance();
  const [form] = Form.useForm();

  const {
    newSupplier,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    supplierName,
  } = useSelector(ssoProductSelectors.selectBpSsoProductData()) ?? {};

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCreateSupplier = async (params) => {
    setLoading(true);
    try {
      const { isSuccess, message, data } = await createSupplier({
        ...params,
        supplierName: params.supplierName?.trim(),
      });
      if (isSuccess) {
        setVisible(false);
        form.resetFields();
        addProductForm.setFieldsValue({ supplierId: data?.supplierId });
        setSupplierList((pre) => {
          return [
            ...pre,
            {
              supplierId: data?.supplierId,
              supplierName: params.supplierName?.trim(),
            },
          ];
        });
        CustomNotification.success('Create supplier successfully');
      } else {
        CustomNotification.error(message ?? 'Something went wrong');
      }
    } catch (error) {
      CustomNotification.error(error.message ?? 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      handleCreateSupplier(values);
    });
  };

  useEffect(() => {
    const supplierInfo =
      newSupplier === '1'
        ? {
            saleContactFirstName: contactFirstName,
            saleContactLastName: contactLastName,
            saleContactEmail: contactEmail,
            saleContactPhone: contactPhoneNumber,
            supplierName: supplierName,
          }
        : {};

    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...supplierInfo,
      headquarterCountry: 'United States',
    });
  }, []);

  const modalProps = {
    title: 'New Supplier',
    visible: visible,
    width: '800px',
    closable: true,
    maskClosable: false,
    wrapClassName: 'document-modal',
    onCancel: handleCancel,
    footer: (
      <CreateProductModalFooter
        onCancel={handleCancel}
        onOk={handleSubmit}
        okLoading={loading}
        okText='Add new supplier'
      />
    ),
  };

  return (
    <>
      <StyledModal {...modalProps} centered>
        <Form {...oneColLayout} form={form}>
          <Fieldset title='New Supplier Creation' style={{ marginBottom: 10 }}>
            <Row>
              <Col flex={1} gutter={8}>
                <Form.Item
                  label='Company Name'
                  name='supplierName'
                  rules={[
                    {
                      required: true,
                      message: 'Company Name is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label='DBA' name='dba'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Fieldset>

          <Row gutter={8}>
            <Col span={12} gutter={8}>
              <Fieldset title='Sales Contact' style={{ marginBottom: 10 }}>
                <Form.Item
                  {...twoColLayout}
                  label='First Name'
                  name='saleContactFirstName'
                  rules={[
                    {
                      required: true,
                      message: 'First Name is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Last Name'
                  name='saleContactLastName'
                  rules={[
                    {
                      required: true,
                      message: 'Last Name is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Title'
                  name='saleContactTitle'
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Phone'
                  name='saleContactPhone'
                  rules={[
                    {
                      required: true,
                      message: 'Phone is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Email'
                  name='saleContactEmail'
                  rules={[
                    {
                      required: true,
                      message: 'Email is required',
                    },
                    { type: 'email', message: 'Invalid Email' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          getFieldValue('qualityContactEmail') === value &&
                          validateEmail(value)
                        ) {
                          return Promise.reject('Email is duplicate');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Fieldset>
            </Col>

            <Col span={12} gutter={8}>
              <Fieldset title='Quality Contact' style={{ marginBottom: 10 }}>
                <Form.Item
                  {...twoColLayout}
                  label='First Name'
                  name='qualityContactFirstName'
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Last Name'
                  name='qualityContactLastName'
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Title'
                  name='qualityContactTitle'
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Phone'
                  name='qualityContactPhone'
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...twoColLayout}
                  label='Email'
                  name='qualityContactEmail'
                  rules={[
                    { type: 'email', message: 'Invalid Email' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          getFieldValue('saleContactEmail') === value &&
                          validateEmail(value)
                        ) {
                          return Promise.reject('Email is duplicate');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Fieldset>
            </Col>
          </Row>
          <Fieldset title='Corporate Headquarters'>
            <Row gutter={8}>
              <Col span={24} gutter={8}>
                <Form.Item
                  label='Address Line 1'
                  name='headquarterAddressLine1'
                  rules={[
                    {
                      required: true,
                      message: 'Address is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='Address Line 2'
                  name='headquarterAddressLine2'
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='City'
                  name='headquarterCity'
                  rules={[
                    {
                      required: true,
                      message: 'City is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='State/Province'
                  name='headquarterState'
                  rules={[
                    {
                      required: true,
                      message: 'State is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='Zip Code'
                  name='headquarterZipCode'
                  rules={[
                    {
                      required: true,
                      message: 'Zip Code is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='Country'
                  name='headquarterCountry'
                  rules={[
                    {
                      required: true,
                      message: 'Country is required',
                    },
                  ]}
                >
                  <FlagCountrySelectionFieldInput
                    searchable={true}
                    className='company-contact-form__country-input'
                    placeholder={'Please input country'}
                    onSelect={(value) => {
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        headquarterCountry: CountryCode[value],
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Fieldset>
        </Form>
      </StyledModal>
    </>
  );
};

export default CreateSupplierModal;
