import {
  VerticalLabel,
  LinearLabel,
  VerticalLabelWithMicronutrient,
} from '../facts-panel';

import SupplementStandardLabel from '../../supplement-label';
import DrugStandardLabel from '../../drug-label';
import PetNutritionFacts from '../../nutrition-label/add-label/pet-nutrition-label/PetNutritionFacts';

const LABEL_TYPE_COMPONENT = {
  US_FDA_NFP_2020_STANDARD_NUTRITIONFACTSPANEL: VerticalLabel,
  US_FDA_NFP_2020_STANDARD_SIDE_BY_SIDE_NUTRITIONFACTSPANEL:
    VerticalLabelWithMicronutrient,
  US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES_NUTRITIONFACTSPANEL: LinearLabel,
  US_FDA_NFP_2020_STANDARD_SUPPLEMENTFACTSPANEL: SupplementStandardLabel,
  US_FDA_NFP_2020_STANDARD_DRUGFACTSPANEL: DrugStandardLabel,
  US_FDA_NFP_2020_STANDARD_PETNUTRITIONFACTSPANEL: PetNutritionFacts,
};

export const getLabelType = (factsPanel) => {
  const labelType = `${
    factsPanel?.nutrientFormatType
  }_${factsPanel?.factsPanelType?.toUpperCase()}`;
  return labelType;
};

export const getComponentFactsPanelType = (factsPanel) => {
  const labelType = getLabelType(factsPanel);

  const FactsPanelType = LABEL_TYPE_COMPONENT[labelType] ?? VerticalLabel;

  return FactsPanelType;
};

const NUTRITION_FACTS_PANEL = 'NutritionFactsPanel';
const SUPPLEMENT_FACTS_PANEL = 'SupplementFactsPanel';
const DRUG_FACTS_PANEL = 'DrugFactsPanel';
const PET_FOOD_PANEL = 'PetNutritionFactsPanel';

export const getTypeOfFactsPanel = (factsPanelType) => {
  return {
    isNutrition: factsPanelType === NUTRITION_FACTS_PANEL,
    isSupplement: factsPanelType === SUPPLEMENT_FACTS_PANEL,
    isDrugFacts: factsPanelType === DRUG_FACTS_PANEL,
    isPetFood: factsPanelType === PET_FOOD_PANEL,
  };
};

export const separateSupplementAndNutritionIds = (
  factsPanel = [],
  factsPanelIds = []
) => {
  let nutritionFactIds = [];
  let supplementFactIds = [];
  let drugFactIds = [];
  let petNutritionFactIds = [];

  if (factsPanelIds) {
    factsPanelIds.forEach((id) => {
      const foundFactsPanel = factsPanel.find(
        (panel) => parseInt(panel.id) === parseInt(id)
      );

      const { isNutrition, isSupplement, isDrugFacts, isPetFood } =
        getTypeOfFactsPanel(foundFactsPanel?.factsPanelType);

      if (isNutrition) {
        nutritionFactIds.push(id);
      } else if (isSupplement) {
        supplementFactIds.push(id);
      } else if (isDrugFacts) {
        drugFactIds.push(id);
      } else if (isPetFood) {
        petNutritionFactIds.push(id);
      }
    });
  }

  return {
    petNutritionFactIds,
    drugFactIds,
    nutritionFactIds,
    supplementFactIds,
  };
};

export const TREE_NUTS_ALLERGEN = 'Tree nuts (Nuts) and its derivatives';

export const getDisplayAllergen = (allergenType) => {
  switch (allergenType) {
    case TREE_NUTS_ALLERGEN:
      return 'Tree nuts';
    default:
      return allergenType?.split(' ')?.[0];
  }
};

export const LEVEL_OF_CONTAINMENT_TO_PREVIEW = [
  'Contains',
  'May Contain',
  'Derived From',
];

export const filterPreviewAllergens = (allergenGroups) => {
  let result = [];

  if (!allergenGroups || allergenGroups.length === 0) return [];

  allergenGroups.forEach((allergenGroup) => {
    let filterAllergenGroup = {
      ...allergenGroup,
      allergens: allergenGroup.allergens.filter((allergen) =>
        LEVEL_OF_CONTAINMENT_TO_PREVIEW.includes(allergen.levelOfContainment)
      ),
    };
    result.push(filterAllergenGroup);
  });

  return result;
};

export const mappingPreviewAllergens = (allergenGroups) => {
  let result = [];

  if (allergenGroups) {
    allergenGroups.forEach((allergenGroup) => {
      const newContainsAllergens = allergenGroup?.allergens
        ?.filter((allergen) => allergen.levelOfContainment === 'Contains')
        .map((allergen) => getDisplayAllergen(allergen?.allergenType));

      const newMayContainAllergens = allergenGroup?.allergens
        ?.filter((allergen) => allergen.levelOfContainment === 'May Contain')
        .map((allergen) => getDisplayAllergen(allergen?.allergenType));

      const newDerivedFromAllergens = allergenGroup?.allergens
        ?.filter((allergen) => allergen.levelOfContainment === 'Derived From')
        .map((allergen) => getDisplayAllergen(allergen?.allergenType));

      const newAllergenGroup = {
        ...allergenGroup,
        containsAllergens: newContainsAllergens,
        mayContainAllergens: newMayContainAllergens,
        derivedFromAllergens: newDerivedFromAllergens,
      };
      result.push(newAllergenGroup);
    });
  }

  return result;
};

const SPECIAL_CONTAINER_DESCRIPTOR = {
  Approximately: 'Approx.',
};

export const renderContainerDescriptor = (servingPerContainerDescriptor) => {
  return (
    SPECIAL_CONTAINER_DESCRIPTOR[servingPerContainerDescriptor] ||
    servingPerContainerDescriptor
  );
};
