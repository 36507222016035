// Constants
import { FORMAT_TYPE } from 'static/Constants';
import {
  VIDEO_TYPES_PREVIEW,
  IMAGE_TYPES_PREVIEW,
} from 'static/FileType';

const FILE_TYPE = ['ppt', 'pptx', 'doc', 'docx', 'xlsx', 'xls', 'pdf', 'rar', 'zip'];

export const getAssetDownloadTypes = () => {
  const assetDownloadTypes = {
    [FORMAT_TYPE.IMAGE_TYPES]: IMAGE_TYPES_PREVIEW,
    [FORMAT_TYPE.VIDEOS_TYPES]: VIDEO_TYPES_PREVIEW,
    [FORMAT_TYPE.FILE]: FILE_TYPE,
  };
  return assetDownloadTypes;
};

export const getCategoryOfFormatDownload = (downloadFormat) => {
  const assetDownloadTypes = getAssetDownloadTypes();
  for (const [key, value] of Object.entries(assetDownloadTypes)) {
    const assetFormatType = downloadFormat;
    const checkType = value.includes(assetFormatType);
    if (checkType) {
      return key;
    }
  }
  return;
};

export const renderResolutionNameByNumber = (number) => {
  switch (number) {
    case 72:
      return 'DPI - Low Resolution';

    case 300:
      return 'DPI - High Resolution';

    default:
      break;
  }
};
