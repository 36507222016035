import { isEmpty } from 'lodash';
import { isAuthed } from 'utils/common/session';

export const checkUserAuth = (userInfo) => {
  return !isEmpty(userInfo) && !userInfo?.isAnonymous;
};

export const checkUserToken = () => {
  return isAuthed();
};
