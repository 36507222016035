import { useQuery, useQueryClient } from '@tanstack/react-query';
import { options } from 'less';

const memberModuleKeys = {
  allMemberShortDetail: ['memberProfile', 'member-short-detail'],
  memberShortDetail: (memberId) => [
    'memberProfile',
    'member-short-detail',
    { params: { id: parseInt(memberId) } },
  ],
  memberHeader: (memberId) => [
    'memberProfile',
    'header',
    { params: { MemberId: parseInt(memberId) } },
  ],
};

/**
 ** GET - api/memberProfile/member-short-detail
 */
export const useGetMemberShortDetail = ({ memberId }, options) => {
  return useQuery({
    queryKey: memberModuleKeys.memberShortDetail(memberId),
    ...options,
  });
};

/**
 ** GET - api/memberProfile/member-short-detail
 * @return { invalidateMemberShortDetailQueries }
 */
export const useInvalidateMemberShortDetailQueries = () => {
  const queryClient = useQueryClient();

  const invalidateMemberShortDetailQueries = ({ memberId }) => {
    const keys = memberId
      ? memberModuleKeys.memberShortDetail(memberId)
      : memberModuleKeys.allMemberShortDetail;
    queryClient.invalidateQueries({
      queryKey: keys,
    });
  };
  return { invalidateMemberShortDetailQueries };
};

/**
 ** GET - api/memberProfile/header
 */
export const useGetMemberHeaderFromMemberId = ({
  memberId,
  ...options
} = {}) => {
  return useQuery({
    queryKey: memberModuleKeys.memberHeader(memberId),
    ...options,
  });
};
