export const checkPathName = (pathName) => {
  let url;
  let entityType;
  if (pathName === '/members' || pathName === '/favorite/favorite-members') {
    // url = '/api/reporting/member-searchable-fields';
    entityType = 'member';
  }
  if (
    pathName === '/digital-media' ||
    pathName === '/digital-media/new' ||
    pathName === '/digital-media/assets' ||
    pathName === '/digital-media/documents' ||
    pathName === '/digital-media/multi-media' ||
    pathName.includes('/member-assets') ||
    pathName === '/favorite/favorite-assets'
  ) {
    // url = '/api/reporting/data-asset-searchable-fields';
    entityType = 'asset';
  }
  if (
    pathName === '/reportings' ||
    pathName === '/reportings/owned' ||
    pathName === '/reportings/shared'
  ) {
    // url = '/api/reporting/reporting-searchable-fields';
    entityType = 'reporting';
  }
  if (
    pathName === '/folders' ||
    pathName === '/folders/owned' ||
    pathName === '/folders/shared' ||
    pathName === '/favorite/favorite-folders'
  ) {
    // url = '/api/reporting/folder-searchable-fields';
    entityType = 'folder';
  }
  if (
    pathName === '/products' ||
    pathName.includes('/member-products') ||
    pathName === '/favorite/favorite-products'
  ) {
    // url = '/api/reporting/product-searchable-fields';
    entityType = 'product';
  }
  return entityType;
};
