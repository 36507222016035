import React from 'react';
import { Space, Tooltip } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const OverviewLocationsIcon = (props) => {
  const { value } = props;
  return (
    <Space>
      {value ? (
        <Tooltip title='Primary Address'>
          <CheckCircleOutlined style={{ fontSize: 20, color: '#52c41a' }} />
        </Tooltip>
      ) : null}
    </Space>
  );
};

export default OverviewLocationsIcon;
