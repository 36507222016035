export const DEFAULT_PET_NUTRITION_FACT = [
  {
    nutrientName: 'Crude protein',
    displayName: 'Protein',
    index: 1,
  },
  {
    nutrientName: 'Crude fat',
    displayName: 'Fat',
    index: 2,
  },
  {
    nutrientName: 'Total Carbohydrate',
    displayName: 'Total Carbohydrate',
    index: 3,
  },
  {
    nutrientName: 'Crude fiber',
    displayName: 'Dietary Fiber',
    index: 4,
  },
  {
    nutrientName: 'Dietary starch',
    displayName: 'Dietary Starch',
    index: 5,
  },
  {
    nutrientName: 'Sugars',
    index: 6,
  },
  {
    nutrientName: 'Moisture',
    index: 7,
  },
  {
    nutrientName: 'Ash',
    index: 8,
  },
];

export const SPECIAL_CARBOHYDRATE = ['crudefiber', 'dietarystarch', 'sugars'];
