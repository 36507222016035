import React from 'react';

import {
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  AutoComplete,
  Checkbox,
} from 'antd';

import { Form } from 'common/components';

import { useGetSupplementPartOf } from '../../hook/useGetSupplementPartOf';

import {
  DAILY_SYMBOL_SUGGESTIONS,
  SUPPLEMENT_NUTRIENT_UOM,
} from '../../constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

const formItemStyle = { marginBottom: 4 };

const SupplementIngredientForm = (props) => {
  const intl = useIntl();

  const { fieldName, namePath, children } = props;

  const { partOfList } = useGetSupplementPartOf({ fieldName, namePath });

  return (
    <div className='supplement-form__add-nutrient-form'>
      <Form.Item
        name={[...namePath, 'ingredientName']}
        rules={[
          {
            required: true,
            message: 'Name is required',
          },
        ]}
        style={formItemStyle}
      >
        <Input.TextArea
          placeholder={intl.formatMessage(Messages.supplementIngredientName)}
        />
      </Form.Item>
      <Form.Item
        name={[...namePath, 'ingredientsPartOf']}
        style={formItemStyle}
      >
        <Select
          allowClear
          placeholder={intl.formatMessage(Messages.supplementPartOf)}
          getPopupContainer={(triggerNode) => {
            return triggerNode;
          }}
        >
          {partOfList.map((partOfItem) => {
            return (
              <Select.Option key={partOfItem} value={partOfItem}>
                {partOfItem}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item name={[...namePath, 'descriptor']} style={formItemStyle}>
        <Input.TextArea
          placeholder={intl.formatMessage(Messages.supplementDescriptor)}
        />
      </Form.Item>

      <Row wrap={false}>
        <Col span={14}>
          <Form.Item name={[...namePath, 'quantity']} style={formItemStyle}>
            <InputNumber
              min={0}
              placeholder={intl.formatMessage(Messages.supplementQuantity)}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name={[...namePath, 'quantityUom']} style={formItemStyle}>
            <Select
              allowClear
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            >
              {SUPPLEMENT_NUTRIENT_UOM.map((UNIT) => {
                return (
                  <Select.Option key={UNIT} value={UNIT}>
                    {UNIT}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name={[...namePath, 'quantityDescription']}
        style={formItemStyle}
      >
        <Input
          placeholder={intl.formatMessage(
            Messages.supplementQuantityDescription
          )}
        />
      </Form.Item>

      <Form.Item name={[...namePath, 'dailySymbol']} style={formItemStyle}>
        <AutoComplete
          allowClear
          options={DAILY_SYMBOL_SUGGESTIONS}
          placeholder={intl.formatMessage(Messages.supplementDailySymbol)}
          getPopupContainer={(triggerNode) => {
            return triggerNode;
          }}
        />
      </Form.Item>
      <Row>
        <Col span={8}>
          <Form.Item
            name={[...namePath, 'italicDietary']}
            valuePropName='checked'
            style={formItemStyle}
          >
            <Checkbox>
              {intl.formatMessage(Messages.supplementItalicName)}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name={[...namePath, 'italicDescriptor']}
            valuePropName='checked'
            style={formItemStyle}
          >
            <Checkbox>
              {intl.formatMessage(Messages.supplementItalicDescriptor)}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {children}
    </div>
  );
};

export default SupplementIngredientForm;
