import React from 'react';
import Messages from 'i18n/messages/home';
import { useSelector } from 'react-redux';

import { ReactComponent as IconOpenMember } from 'common/components/button/svg-icons/ICO_10.28.svg';
import RibbonButton from 'common/components/button/RibbonButton';

import { forwardTo } from 'utils/common/route';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const OpenMember = () => {
  const selectedAssetDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const memberId =
    selectedAssetDetail && selectedAssetDetail.length > 0
      ? selectedAssetDetail[0].id
      : undefined;

  return (
    <RibbonButton
      icon={<IconOpenMember style={{ height: 26 }} />}
      label={Messages.openMember}
      onClick={() => forwardTo(`/company/${memberId}`)}
      disabled={memberId === undefined || selectedAssetDetail.length !== 1}
    />
  );
};

OpenMember.propTypes = {};

export default OpenMember;
