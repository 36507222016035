import React from 'react';

import { Col, Typography, Select } from 'antd';
import { PlusSquareTwoTone } from '@ant-design/icons';
import classnames from 'classnames';
import _ from 'lodash';
import { produce } from 'immer';

import * as utils from 'utils/productHierarchy';
import { HIERARCHY_LEVEL } from 'static/Constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

const { Text } = Typography;

const HierarchyPackageLevel = (props) => {
  const intl = useIntl();
  const { level, setHierarchyLevelList, initQuantityForm } = props;

  const onClickAddChildren = (level) => () => {
    insertChildrenLevel(level);
  };

  const onChangePackageLevel = (value) => {
    setHierarchyLevelList((prevState) => {
      const newState = produce(prevState, (draft) => {
        const PACKAGE_LEVEL = Object.values(HIERARCHY_LEVEL).find(
          (LEVEL) => LEVEL.KEY === value
        );

        if (!PACKAGE_LEVEL) return;

        draft[level?.index].packageLevel = PACKAGE_LEVEL.NAME;
        draft[level?.index].key = PACKAGE_LEVEL.KEY;
      });

      return newState;
    });
  };

  const insertChildrenLevel = (level) => {
    if (!level) return;

    const appendLevel = utils.getAppendLevels(level);

    setHierarchyLevelList((prevState) => {
      const cloneState = _.cloneDeep(prevState);
      // const insertPosition = utils.getInsertPosition({
      //   levelList: prevState,
      //   level,
      // });
      const insertPosition = level.index + 1;

      cloneState.splice(
        insertPosition > -1 ? insertPosition : prevState.length,
        0,
        ...appendLevel
      );

      const reIndexNewState = utils.reIndexLevelList(cloneState);

      initQuantityForm({ levelList: reIndexNewState });

      return reIndexNewState;
    });
  };

  const shouldShowAddChildren =
    !!level.productItemId && level.packageLevel !== HIERARCHY_LEVEL.UNIT.NAME;

  return (
    <>
      <Col flex='40px' className='add-hierarchy__level-add-more-col'>
        {shouldShowAddChildren ? (
          <PlusSquareTwoTone onClick={onClickAddChildren(level)} />
        ) : null}
      </Col>
      <Col
        className={classnames({
          'add-hierarchy__level-label-col': true,
          [`add-hierarchy__level-label-col--${level.key}`]: true,
        })}
      >
        {level.equalWith && !level.productItemId ? (
          <Select
            value={level.packageLevel}
            onChange={onChangePackageLevel}
            style={{ minWidth: 120 }}
          >
            {level?.equalWith?.map((equalValueKey) => {
              const HIERARCHY_LEVEL_ITEM = Object.values(HIERARCHY_LEVEL).find(
                (HIERARCHY_LEVEL_ITEM_DATA) => {
                  return HIERARCHY_LEVEL_ITEM_DATA.KEY === equalValueKey;
                }
              );

              return HIERARCHY_LEVEL_ITEM ? (
                <Select.Option key={equalValueKey} value={equalValueKey}>
                  {HIERARCHY_LEVEL_ITEM.NAME}
                </Select.Option>
              ) : null;
            })}
          </Select>
        ) : (
          <Text strong>
            {Messages[level.key]
              ? intl.formatMessage(Messages[level.key])
              : level.packageLevel}
          </Text>
        )}
      </Col>
    </>
  );
};

export default HierarchyPackageLevel;
