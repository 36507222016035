import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/mapping';
import { FolderAddOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import { SaveMappingConfig } from 'pages/mapping/components';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const CreateMapping = () => {
  const selectedMapping = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const handleCreateMapping = () => {
    setVisible(true);
  };

  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        size='small'
        icon={<FolderAddOutlined />}
        label={Messages.createMapping}
        onClick={handleCreateMapping}
      />
      <SaveMappingConfig
        visible={visible}
        setVisible={setVisible}
        selectedMapping={selectedMapping}
        mode='create'
      />
    </>
  );
};

export default CreateMapping;
