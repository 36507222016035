import { Form } from 'common/components';
import { getNameAndItalicizeNameSupplement } from 'pages/product-full-view/components/product-media-area/supplement-label/utils';

export const useGetSupplementPartOf = (props) => {
  const form = Form.useFormInstance();
  const { fieldName, namePath } = props;
  const nutrientList = Form.useWatch(fieldName, form);

  const currentNutrientDataNamePath = namePath.includes('_add')
    ? namePath
    : [fieldName, ...namePath];
  const currentNutrientData = Form.useWatch(currentNutrientDataNamePath, form);

  const getPartOf = () => {
    if (!nutrientList?.length) return [];
    if (!currentNutrientData) return [];

    const { name } = getNameAndItalicizeNameSupplement(currentNutrientData, fieldName);

    return nutrientList.reduce((accumulator, nutrientItem) => {

      const { name: currentName } = getNameAndItalicizeNameSupplement(
        nutrientItem,
        fieldName
      );

      const isDuplicatedName = currentName === name;

      const isPartOf =
        nutrientItem?.ingredientsPartOf &&
        nutrientItem?.ingredientsPartOf === name;

      if (isDuplicatedName) return accumulator;

      if (isPartOf) return accumulator;

      return [...accumulator, currentName];
    }, []);
  };
  const partOfList = getPartOf();

  return { partOfList, currentNutrientData };
};
