import produce from 'immer';
import * as types from './constants';

import { mapToCommonFetchState } from 'pages/maintenance-form/mappers';

import { BOOLEAN } from 'static/Constants';

const INIT_FETCH_STATE = {
  error: null,
  loading: false,
  data: null,
};

// initial state
export const initialState = {
  loading: false,
  error: null,
  success: false,
  formDetail: INIT_FETCH_STATE,
  selectedDataPoints: [],
  selectedDataPointsData: [],
  formData: null,
  modeViewForm: false,
  editFormTemplate: INIT_FETCH_STATE,
  dataPoints: [],
  refreshDataPointList: false,
  clearDataPointList: false,
  isFormDetailFullScreen: false,
  isFloatMode: false,
  refreshDataPointDetail: false,
  exportedFormShortDetail: INIT_FETCH_STATE,
  loadSpreadJs: false,
};

/* eslint-disable default-case, no-param-reassign */
const maintenanceFormReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_NEW_FORM_TEMPLATE:
        draft.loading = true;
        draft.error = null;
        draft.success = null;
        break;
      case types.UPDATE_NEW_FORM_TEMPLATE_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.UPDATE_NEW_FORM_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.success = true;
        break;
      case types.GET_FORM_DETAIL:
      case types.GET_FORM_DETAIL_SUCCESS:
      case types.GET_FORM_DETAIL_FAIL:
        mapToCommonFetchState(action, draft, 'formDetail', true);
        break;
      case types.FORM_MANAGE_TOGGLE_VIEW_LOADING:
        draft.loading = action?.boolean ?? !state?.loading;
        break;
      case types.UPDATE_SELECTED_DATA_POINT:
        draft.selectedDataPoints = action.payload;
        break;
      case types.GET_FORM_TEMPLATE_DETAIL:
        draft.loading = true;
        draft.error = null;
        draft.success = null;
        break;
      case types.GET_FORM_TEMPLATE_DETAIL_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.GET_FORM_TEMPLATE_DETAIL_SUCCESS:
        draft.loading = false;
        draft.formData = action.formData;
        break;
      case types.EDIT_FORM_TEMPLATE:
      case types.EDIT_FORM_TEMPLATE_FAIL:
      case types.EDIT_FORM_TEMPLATE_SUCCESS:
        mapToCommonFetchState(action, draft, 'editFormTemplate', true);
        break;
      case types.UPDATE_UNMOUTE_SUCCESS:
        draft.success = false;
        break;
      case types.UPDATE_MODE_VIEW_MAINTENANCE_FORM:
        draft.modeViewForm = action.modeViewForm;
        break;
      case types.FORM_MANAGE_TOGGLE_REFRESH_DATA_POINT_LIST_SUCCESS:
        draft.refreshDataPointList = action?.boolean;
        break;
      case types.TOGGLE_CLEAR_DATA_POINT_LIST:
        draft.clearDataPointList = action?.boolean;
        break;
      case types.TOGGLE_FORM_DETAIL_FULL_SCREEN:
        draft.isFormDetailFullScreen = BOOLEAN.includes(action?.boolean)
          ? action?.boolean
          : !draft.isFormDetailFullScreen;
        break;
      case types.TOGGLE_FORM_DETAIL_FLOAT_MODE:
        draft.isFloatMode = BOOLEAN.includes(action?.boolean)
          ? action?.boolean
          : !draft.isFloatMode;
        break;
      case types.UPDATE_SELECTED_DATA_POINT_DATA:
        draft.selectedDataPointsData = action.payload;
        break;
      case types.REFRESH_DATA_POINT_DETAIL_SUCCESS:
        draft.refreshDataPointDetail = action.boolean;
        break;
      case types.FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL:
      case types.FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_SUCCESS:
      case types.FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_FAIL:
        mapToCommonFetchState(action, draft, 'exportedFormShortDetail', true);
        break;

      case types.UPDATE_LOAD_SCRIPT_SPREADJS:
        draft.loadSpreadJs = true;
        break;
      default:
        break;
    }
  });

export default maintenanceFormReducer;
