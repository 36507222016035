export const GET_APL_DETAIL = '/api/apl/get-apl-item-details';
export const EDIT_APL_NOTE = '/api/apl/edit-apl-info-note';

export const ADD_APL_COMMENT = '/api/apl/add-apl-comment';

export const GET_APL_PRODUCT_LIST = '/api/apl/get-apl-product-list';
export const GET_APL_PRODUCT_LIST_DISTINCT =
  '/api/apl/get-apl-product-list/distinct';
export const GET_APL_COMMENT_LIST = '/api/apl/get-apl-comment-grid';

export const EXECUTE_MATCHING_APL = '/api/apl/manual-matching-apl';

export const EDIT_APL_PRODUCT_CONTACT = '/api/apl/edit-apl-product-contact';
