import React from 'react';
import Messages from 'i18n/messages/home';
import { Menu, Dropdown } from 'antd';

import { useSelector } from 'react-redux';

import { ReactComponent as IconAllProducts } from 'common/components/button/svg-icons/ICO_10.30.svg';
import RibbonButton from 'common/components/button/RibbonButton';

import * as companySelector from 'pages/company/controllers/selectors';

import { forwardTo } from 'utils/common/route';
import { ROUTE } from 'static/Constants';

const ShowProducts = ({ memberId }) => {
  const companyInfo = useSelector(companySelector.setlectCompanyInfo());

  const handleClick = () => {
    if (companyInfo?.id) {
      forwardTo(ROUTE.MEMBER_PRODUCTS + `?memberId=${companyInfo.id}`);
    } else if (memberId) {
      forwardTo(ROUTE.MEMBER_PRODUCTS + `?memberId=${memberId}`);
    }
  };

  const menuOptions = (
    <Menu>
      <Menu.Item key='products' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<IconAllProducts />}
          label={Messages.goShowAllProducts}
          onClick={handleClick}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions}>
      <RibbonButton
        icon={<IconAllProducts style={{ height: 26 }} />}
        label={Messages.products}
        // onClick={handleClick}
      />
    </Dropdown>
  );
};

ShowProducts.propTypes = {};

export default ShowProducts;
