import React from 'react';

import { Row, Divider } from 'antd';

import {
  PropertyBoolean,
  PropertyDatetime,
  PropertyList,
  PropertyNumber,
  PropertyText,
  PropertyEnum,
  GroupElementWrapper,
  HeaderGroupElement,
} from '../../shared/components';

import {
  mappingSpecialEnumToDisplay,
  formatSpecialEnum,
} from '../../shared/utils';

import {
  checkIsValidObjectValues,
  checkNestedPropertyHasValidValues,
} from './utils';
import { findProductPropertyEnums } from '../product-detail-view/utils';

import { NUMERIC_TYPE } from 'static/Constants';
import { SPECIAL_ENUMS } from '../../constants';

const ProductPropertyItem = ({ property, data, productEnums }) => {
  const {
    childProperties,
    isArray,
    propertyType,
    propertyDisplayName,
    fieldFullPath,
    listName,
  } = property;

  const isNestedProperty = childProperties?.length > 0;

  if (isNestedProperty) {
    const values = data[fieldFullPath] ?? [];

    const isValidValues = checkNestedPropertyHasValidValues(values);

    return (
      <>
        {isValidValues && (
          <Row style={{ marginTop: 12, marginRight: 12 }}>
            <GroupElementWrapper
              span={24}
              style={{ padding: '8px 0px', marginTop: 8 }}
            >
              <HeaderGroupElement
                className='product-detail__group-header'
                header={propertyDisplayName}
              />
              {values.map((value, index) => {
                const lastIndex = index === values.length - 1;
                const isValidObject = checkIsValidObjectValues(value);

                return (
                  <React.Fragment key={index}>
                    <PropertyNested
                      properties={childProperties}
                      data={value}
                      productEnums={productEnums}
                    />
                    {lastIndex || !isValidObject ? null : (
                      <Divider
                        style={{
                          margin: '12px 8px',
                          minWidth: '96%',
                          width: '96%',
                          borderTopColor: '#f0f0f0',
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </GroupElementWrapper>
          </Row>
        )}
      </>
    );
  } else {
    if (listName) {
      const propertyEnums = findProductPropertyEnums(listName, productEnums);
      const isSpecialEnum = SPECIAL_ENUMS.includes(listName);

      if (isArray) {
        const value = data[fieldFullPath] ?? [];

        if (value.length === 0) return null;

        if (isSpecialEnum) {
          const mappedValues = mappingSpecialEnumToDisplay(
            value,
            propertyEnums
          );

          return (
            <PropertyEnum
              propertyDisplayName={propertyDisplayName}
              value={mappedValues.join(', ')}
            />
          );
        }
        return (
          <PropertyEnum
            propertyDisplayName={propertyDisplayName}
            value={value.join(', ')}
          />
        );
      } else {
        const value = data[fieldFullPath];

        if (!value) return null;

        if (isSpecialEnum) {
          const mappedValue = propertyEnums.find(
            (enumVal) => enumVal.enumCode === value
          );
          return (
            <PropertyEnum
              propertyDisplayName={propertyDisplayName}
              value={formatSpecialEnum(mappedValue)}
            />
          );
        }

        return (
          <PropertyEnum
            propertyDisplayName={propertyDisplayName}
            value={value}
          />
        );
      }
    }

    if (isArray) return <PropertyList property={property} data={data} />;

    if (propertyType === 'string')
      return <PropertyText property={property} data={data} />;

    if (NUMERIC_TYPE.includes(propertyType)) {
      return <PropertyNumber property={property} data={data} />;
    }

    if (propertyType === 'datetime')
      return <PropertyDatetime property={property} data={data} />;

    if (propertyType === 'boolean')
      return <PropertyBoolean property={property} data={data} />;

    return <PropertyText property={property} data={data} />;
  }
};

const PropertyNested = ({ properties, data, productEnums }) => {
  const renderElement = ({ childProperty, data }) => {
    const {
      propertyType,
      childProperties,
      isArray,
      propertyDisplayName,
      fieldFullPath,
      listName,
    } = childProperty;

    if (childProperties?.length > 0) {
      const values = data[fieldFullPath] ?? [];
      const isValidValues = checkNestedPropertyHasValidValues(values);

      return (
        <>
          {isValidValues && (
            <Row style={{ marginTop: 12, marginRight: 12 }}>
              <GroupElementWrapper
                span={24}
                style={{ padding: '6px 4px', marginTop: 12 }}
              >
                <HeaderGroupElement
                  className='product-detail__group-header'
                  header={propertyDisplayName}
                />
                {values.map((value, index) => {
                  const lastIndex = index === values.length - 1;
                  return (
                    <React.Fragment key={index}>
                      <PropertyNested
                        properties={childProperties}
                        data={value}
                        productEnums={productEnums}
                      />
                      {lastIndex ? null : (
                        <Divider
                          style={{
                            margin: '12px 8px',
                            minWidth: '96%',
                            width: '96%',
                            borderTopColor: '#f0f0f0',
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </GroupElementWrapper>
            </Row>
          )}
        </>
      );
    } else {
      if (listName) {
        const propertyEnums = findProductPropertyEnums(listName, productEnums);
        const isSpecialEnum = SPECIAL_ENUMS.includes(listName);

        if (isArray) {
          const value = data[fieldFullPath] ?? [];

          if (value.length === 0) return null;

          if (isSpecialEnum) {
            const mappedValues =
              mappingSpecialEnumToDisplay(value, propertyEnums) ?? [];
            return (
              <PropertyEnum
                propertyDisplayName={propertyDisplayName}
                value={mappedValues.join(', ')}
              />
            );
          }

          return (
            <PropertyEnum
              propertyDisplayName={propertyDisplayName}
              value={value.join(', ')}
            />
          );
        } else {
          const value = data[fieldFullPath];

          if (!value) return null;

          if (isSpecialEnum) {
            const mappedValue = propertyEnums.find(
              (enumVal) => enumVal.enumCode === value
            );
            return (
              <PropertyEnum
                propertyDisplayName={propertyDisplayName}
                value={formatSpecialEnum(mappedValue)}
              />
            );
          }

          return (
            <PropertyEnum
              propertyDisplayName={propertyDisplayName}
              value={value}
            />
          );
        }
      }

      if (isArray) return <PropertyList property={childProperty} data={data} />;

      if (propertyType === 'string') {
        return <PropertyText property={childProperty} data={data} />;
      }

      if (NUMERIC_TYPE.includes(propertyType))
        return <PropertyNumber property={childProperty} data={data} />;

      if (propertyType === 'datetime')
        return <PropertyDatetime property={childProperty} data={data} />;

      if (propertyType === 'boolean')
        return <PropertyBoolean property={childProperty} data={data} />;

      return <PropertyText property={childProperty} data={data} />;
    }
  };

  return (
    <>
      {properties.map((childProperty) => {
        return (
          <div
            style={{ marginTop: 6, marginLeft: 12 }}
            key={childProperty.fieldFullPath}
          >
            {renderElement({
              childProperty,
              data,
              productEnums,
            })}
          </div>
        );
      })}
    </>
  );
};

export default ProductPropertyItem;
