import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Messages from 'i18n/messages/chat';
import { notification } from 'antd';
import UploadFile from 'common/components/uploadFile/UploadFile';
import { StyledModal } from 'common/components';
import * as endpointChat from 'services/chatServices/endpoints';
import * as chatActions from '../../controller/actions';
import * as chatSelectors from '../../controller/selectors';
import { UPLOAD_MAX_SIZE } from 'static/Constants';

const UpdateAvatarModal = (props) => {
  const { info, visible, setVisible, intl } = props;
  const currentActiveThread = useSelector(
    chatSelectors.selectActiveThreadInfo()
  );

  const dispatch = useDispatch();

  const handleChangeAvatar = (result) => {
    const response = result?.response;

    if (!response) return;

    if (response?.isSuccess) {
      dispatch(
        chatActions.getThreadInfo(
          currentActiveThread?.threadId,
          currentActiveThread?.userId
        )
      );
      setVisible(false);
      showSuccessMessageDialog();
    } else {
      callApiErrorHandler(response?.message);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.updateAvatarSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <StyledModal
      title={<FormattedMessage {...Messages.updateAvatarModal} />}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      maskClosable={false}
      footer={null}
    >
      {visible ? (
        <UploadFile
          isBase64={false}
          showMultiple={false}
          accept={'image/*'}
          additionalBodyPayload={{ ThreadId: info?.id }}
          apiUrl={endpointChat.UPDATE_GROUP_AVATAR}
          getResponse={(result) => handleChangeAvatar(result)}
          manualUpload={true}
          showUploadSuccessMsg={false}
          maxSize={UPLOAD_MAX_SIZE.GENERAL}
        />
      ) : null}
    </StyledModal>
  );
};

export default injectIntl(UpdateAvatarModal);
