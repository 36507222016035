import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconDay } from 'assets/dashboard/day.svg';

import Messages from 'i18n/messages/dashboard';

import { RibbonButton } from 'common/components';
import * as dashboardSelectors from 'pages/dashboard/controllers/selectors';
import * as dashboardActions from 'pages/dashboard/controllers/actions';

const MonthView = () => {
  const dispatch = useDispatch();
  const calendarView = useSelector(dashboardSelectors.makeSelectCalendarView());
  return (
    <RibbonButton
      icon={<IconDay />}
      label={Messages.monthView}
      onClick={() => dispatch(dashboardActions.calendarMonthView('month'))}
      toggle={calendarView === 'month'}
      style={{ padding: '0px 14px' }}
    />
  );
};

export default MonthView;
