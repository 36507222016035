import React, { useState, useEffect } from 'react';
import './ScheduleConfiguration.less';
import {
  Radio,
  Checkbox,
  Row,
  Col,
  TimePicker,
  InputNumber,
  Collapse,
  Divider,
  Button,
  notification,
  Select,
} from 'antd';
import PropTypes, { oneOfType } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { WEEK_DAY, MONTH_LIST, DAY_LIST, ORDER_LIST } from 'static/Constants';
import { TIME_ZONES } from 'static/TimeZones';
import moment from 'moment';
import { configScheduleDownload } from 'services/folder';

import * as folderSelectors from '../../controllers/selectors';
import * as folderActions from '../../controllers/actions';

import { Form, WrapperSelect } from 'common/components';

const { Panel } = Collapse;
const { Option } = Select;

const ScheduleConfiguration = (props) => {
  const { downloadConfig, folderId, onSetLoading } = props;
  const [type, setType] = useState(null);
  const [disableSpecific, setDisableSpecific] = useState(false);
  const [disableOrder, setDisableOrder] = useState(false);
  const [disableMonth, setDisableMonth] = useState(false);
  const [disableDays, setDisableDays] = useState(false);
  const [activeKey, setActiveKey] = useState(['month']);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const selectedMapping = useSelector(folderSelectors.selectSelectedMapping());
  const downloadSetting = useSelector(folderSelectors.selectDownloadSetting());

  useEffect(() => {
    setActiveKey(['month']);
    setDisableDays(false);
  }, [type]);

  useEffect(() => {
    form.setFieldsValue({
      timezone: TIME_ZONES[16].utc[0],
    });
  }, [form]);

  const openNotify = (result, description) => {
    notification[result ? 'success' : 'error']({
      message: 'Config schedule download',
      description: description,
    });
  };

  function onFinish(params) {
    const test = new Date(params.runAt.$d);
    const newDate = new Date(test.getTime() - test.getTimezoneOffset() * 60000);
    const submitForm = {
      ...downloadConfig,
      ...params,
      interval: !params.interval ? 'Daily' : params.interval,
      folderId: folderId,
      runEvery: params.runEvery > 0 ? params.runEvery : 0,
      runAt: newDate.toISOString(),
      specificScheduleOrdinalNumeral:
        params.specificScheduleOrdinalNumeral &&
        !Array.isArray(params.specificScheduleOrdinalNumeral)
          ? [params.specificScheduleOrdinalNumeral]
          : [],
      specificScheduleDaysOfWeek:
        params.specificScheduleDaysOfWeek &&
        !Array.isArray(params.specificScheduleDaysOfWeek)
          ? [params.specificScheduleDaysOfWeek]
          : params.specificScheduleDaysOfWeek
          ? params.specificScheduleDaysOfWeek
          : [],
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    onSetSchedule(submitForm);
  }

  async function onSetSchedule(values) {
    dispatch(folderActions.saveStatusDownloadFolder('loading'));
    const mappingId = selectedMapping?.id ? selectedMapping?.id : null;

    const newFormValues = {
      ...values,
    };

    onSetLoading(true);
    let result = await configScheduleDownload({ ...newFormValues, mappingId });
    if (result && result.isSuccess) {
      openNotify(true, result.message);
      setType('Daily');
      form.resetFields();
    } else {
      openNotify(false, result.message);
    }
    onSetLoading(false);
  }

  const monthDaysProps = {
    form,
    disableDays,
    setDisableSpecific,
    setActiveKey,
  };

  const specificScheduleProps = {
    form,
    disableSpecific,
    setDisableDays,
  };

  return (
    <Form
      form={form}
      name='schedule-download-form'
      className='schedule-configuration'
      onFinish={onFinish}
      id='schedule-download-form'
    >
      <Row justify='center'>
        Use the information below to setup an automation schedule
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col span={4}>
          <div className='schedule-configuration__title'>Run at:</div>
        </Col>
        <Col span={8}>
          <Form.Item
            name='runAt'
            rules={[
              {
                required: true,
                message: 'Please choose time',
              },
            ]}
          >
            <TimePicker
              defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
              format={'HH:mm:ss'}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='timezone'
            rules={[
              {
                required: true,
                message: 'Please choose timezone',
              },
            ]}
          >
            <WrapperSelect
              showSearch
              placeholder='Please choose timezone'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              // defaultValue={TIME_ZONES[16].utc[0]}
            >
              {TIME_ZONES.map((zone) => (
                <Option key={zone.text} value={zone.utc[0]}>
                  {zone.text}
                </Option>
              ))}
            </WrapperSelect>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <div className='schedule-configuration__title'>Interval:</div>
      </Row>
      <Row>
        <Col span={20} offset={4}>
          <Form.Item name='interval' defaultValue={'Daily'}>
            <Radio.Group
              style={{ width: '100%' }}
              defaultValue={'Daily'}
              onChange={(params) => {
                setType(params.target.value);
                setDisableOrder(false);
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  runEvery: 0,
                  onDate: [],
                  specificScheduleOrdinalNumeral: [],
                  atDays: [],
                  atMonth: [],
                  specificScheduleDaysOfWeek: [],
                });
                setDisableMonth(false);
                setDisableSpecific(false);
              }}
            >
              <Row>
                <Col span={8}>
                  <Radio value={'Daily'}>Daily</Radio>
                </Col>
                <Col span={8}>
                  <Radio value={'Weekly'}>Weekly</Radio>
                </Col>
                <Col span={8}>
                  <Radio value={'Monthly'}>Monthly</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      {type === 'Weekly' ? (
        <>
          <Divider className='schedule-configuration__divider' />
          <Row>
            <Col span={4}>
              <div className='schedule-configuration__title'>Every:</div>
            </Col>
            <Col span={6}>
              <Form.Item
                name='runEvery'
                rules={[
                  {
                    required: type === 'Weekly',
                    message: 'Please choose number of week',
                  },
                ]}
              >
                <InputNumber
                  disabled={type !== 'Weekly'}
                  defaultValue={0}
                  min={0}
                />
              </Form.Item>
            </Col>
            <div>weeks</div>
          </Row>
          <Row>
            <Row>
              <Col span={4}>
                <div className='schedule-configuration__title'>On:</div>
              </Col>
              <Col span={20}>
                <Form.Item
                  name='onDate'
                  rules={[
                    {
                      required: type === 'Weekly',
                      message: 'Please choose weekday',
                    },
                  ]}
                >
                  <Checkbox.Group
                    disabled={type !== 'Weekly'}
                    style={{ width: '100%' }}
                  >
                    <Row>
                      {WEEK_DAY.map((option, index) => {
                        return (
                          <Col span={12}>
                            <Checkbox value={option} key={index}>
                              {option}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </>
      ) : type === 'Monthly' ? (
        <>
          <Divider className='schedule-configuration__divider' />
          <Collapse
            className='schedule-configuration__collapse'
            expandIconPosition='right'
            onChange={(key) => setActiveKey(key)}
            activeKey={activeKey}
            ghost
          >
            <Panel header='Month & Days' key='month'>
              <MonthDaysSection {...monthDaysProps} />
            </Panel>
            <Panel header='Specific Schedule' key='specific'>
              <SpecificScheduleSection {...specificScheduleProps} />
            </Panel>
            <Button
              htmlType='button'
              onClick={() => {
                setDisableMonth(true);
                setDisableOrder(true);
                setDisableSpecific(true);
                setType('Daily');
                form.resetFields();
              }}
              style={{ display: 'none' }}
              id='schedule-configuration__reset-button'
            >
              Reset
            </Button>
          </Collapse>
        </>
      ) : null}
    </Form>
  );
};

const MonthDaysSection = (props) => {
  const { form, disableDays, setActiveKey, setDisableSpecific } = props;

  const handleChangeDays = () => {
    const { atDays } = form.getFieldValue();

    if (atDays?.length > 0) {
      setDisableSpecific(true);
      setActiveKey(['month']);
    } else {
      setDisableSpecific(false);
    }
  };

  return (
    <>
      <Row>
        <Col span={20} offset={4}>
          <Form.Item name='atMonth'>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                {MONTH_LIST.map((option, index) => {
                  return (
                    <Col span={8}>
                      <Checkbox value={option} key={index}>
                        {option}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <div className='schedule-configuration__title'>Days:</div>
        </Col>
        <Col span={20}>
          <Form.Item name='atDays'>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={handleChangeDays}
              disabled={disableDays}
            >
              <Row>
                {DAY_LIST.map((option, index) => {
                  return (
                    <Col span={4}>
                      <Checkbox value={option} key={index}>
                        {option}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const SpecificScheduleSection = (props) => {
  const { form, disableSpecific, setDisableDays } = props;
  const [checkAll, setCheckAll] = useState(false);
  const [disableCheckAll, setDisableCheckAll] = useState(false);

  const { specificScheduleOrdinalNumeral } = form.getFieldValue();

  const handleCheckAllDays = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        ...form.getFieldValue(),
        specificScheduleDaysOfWeek: WEEK_DAY,
      });

      setCheckAll(true);
    } else {
      form.setFieldsValue({
        ...form.getFieldValue(),
        specificScheduleDaysOfWeek: [],
      });

      setCheckAll(false);
    }
  };

  const handleChangeOrder = () => {
    const { specificScheduleOrdinalNumeral } = form.getFieldValue();
    if (specificScheduleOrdinalNumeral.length > 0) {
      setDisableDays(true);
      setDisableCheckAll(true);
    }
  };

  const handleChangeWeekDay = () => {
    const { specificScheduleDaysOfWeek, specificScheduleOrdinalNumeral } =
      form.getFieldValue();

    if (specificScheduleDaysOfWeek.length > 0) {
      setDisableDays(true);
    }

    if (
      specificScheduleDaysOfWeek.length === 0 &&
      specificScheduleOrdinalNumeral.length === 0
    ) {
      setDisableDays(false);
    }
  };

  return (
    <Row>
      <Col span={10} offset={4}>
        <Form.Item name='specificScheduleOrdinalNumeral'>
          <Radio.Group
            style={{ width: '100%' }}
            disabled={disableSpecific}
            onChange={handleChangeOrder}
          >
            <Row>
              {ORDER_LIST.map((option, index) => {
                return (
                  <Col span={24}>
                    <Radio value={option} key={index}>
                      {option}
                    </Radio>
                  </Col>
                );
              })}
            </Row>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span='10'>
        <Checkbox
          checked={checkAll}
          onChange={handleCheckAllDays}
          disabled={disableCheckAll || disableSpecific}
        >
          All
        </Checkbox>
        <Form.Item name='specificScheduleDaysOfWeek'>
          {specificScheduleOrdinalNumeral?.length > 0 ? (
            <Radio.Group>
              <Row>
                {WEEK_DAY.map((option, index) => {
                  return (
                    <Col span={24}>
                      <Radio value={option} key={index}>
                        {option}
                      </Radio>
                    </Col>
                  );
                })}
              </Row>
            </Radio.Group>
          ) : (
            <Checkbox.Group
              disabled={disableSpecific}
              onChange={handleChangeWeekDay}
            >
              <Row>
                {WEEK_DAY.map((option, index) => {
                  return (
                    <Col span={24}>
                      <Checkbox value={option} key={index}>
                        {option}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

ScheduleConfiguration.propTypes = {
  downloadConfig: oneOfType([PropTypes.array, PropTypes.object]),
  folderId: PropTypes.number,
  onSetLoading: PropTypes.func,
};

export default ScheduleConfiguration;
