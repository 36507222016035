import React from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/common';

const FormAddButton = ({ text, style, ...otherProps }) => {
  return (
    <Button
      type='primary'
      style={{ borderRadius: '4px', ...style }}
      icon={<PlusCircleOutlined />}
      {...otherProps}
    >
      {text === null ? null : (
        <span>{text || <FormattedMessage {...Messages.addBtn} />}</span>
      )}
    </Button>
  );
};

export default FormAddButton;
