import { useDispatch } from 'react-redux';
import axios from 'axios';

import * as services from 'services/login';
import * as userActions from 'redux/user/actions';

import { clearToken } from 'utils/common/session';
import { forwardTo } from 'utils/common/route';

export const useUpdateUserInfo = () => {
  const dispatch = useDispatch();

  const updateUserInfo = () => {
    services.getUserInfo().then((resp) => {
      if (resp?.data) {
        dispatch(userActions.loginSuccess(resp?.data));
      } else {
        logoutHandler();
      }
    });
  };

  return {
    updateUserInfo,
  };
};

const logoutHandler = () => {
  // clear session on local before logout
  const refreshToken = localStorage.getItem('RefreshToken');
  services.revokeToken({ token: refreshToken });
  delete axios.defaults.headers.common['Authorization'];
  clearToken();
  // redirect to login page
  forwardTo('/error-invalid-token');
};
