import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { CopyOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import CloneMappingConfigModal from 'pages/mapping/components/mapping-config/CloneMappingConfigModal';

const CloneMappingConfig = (props) => {
  const {
    selectedItemList,
    mappingConfigId,
    mappingConfig,
    mappingConfigStatus,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const isDisabled = selectedItemList.length !== 1 || !mappingConfig?.isOwner;

  const onClick = () => {
    setModalVisible(true);
  };

  return (
    <>
      <RibbonButton
        icon={<CopyOutlined />}
        label={Messages.mappingClone}
        onClick={onClick}
        disabled={isDisabled}
      />
      {modalVisible && (
        <CloneMappingConfigModal
          mappingConfigId={mappingConfigId}
          visible={modalVisible}
          setVisible={setModalVisible}
          mappingConfig={mappingConfig}
          mappingConfigStatus={mappingConfigStatus}
        />
      )}
    </>
  );
};

CloneMappingConfig.propTypes = {
  selectedItemList: PropTypes.array,
};

export default CloneMappingConfig;
