import {
  useState,
  useRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { InputOTP } from 'antd-input-otp';

import { sleep } from 'utils/delay';
import { isOtpNumeric } from './utils';

const AntdOtp = (props, ref) => {
  const { onChange, otp, inputType, length = 5, ...restProps } = props;

  const [reloadOtpInput, setReloadOtpInput] = useState(false);
  const [focusIndex, setFocusIndex] = useState(null);
  const [inputReady, setInputReady] = useState(false);

  const refInput = useRef();
  const refTempOtp = useRef();

  const handleReloadOtpInput = useCallback(async () => {
    setReloadOtpInput(true);
    await sleep(200);
    setReloadOtpInput(false);
    await sleep(200);
    // handleFocus(0);
    return Promise.resolve(true);
  }, []);

  useEffect(() => {
    const resetOtpInput = async () => {
      if (otp?.join('')?.length === 0) {
        handleReloadOtpInput();
      }
    };

    const setTempOtpValue = () => {
      refTempOtp.current = otp;
    };

    resetOtpInput();
    setTempOtpValue();
  }, [otp, handleReloadOtpInput]);

  const onChangeOtp = (value) => {
    if (value?.join('')?.length > length) {
      return;
    }
    if (inputType === 'numeric') {
      const isOtpNumberOnly = isOtpNumeric(value);

      if (isOtpNumberOnly) {
        onChange(value);
      } else {
        handleReloadOtpInput();
        setTimeout(() => {
          onChange(refTempOtp.current);
        }, 1000);
      }
    } else {
      onChange(value);
    }
  };

  const handleFocus = (index) => {
    setFocusIndex(index);
  };

  const additionalConfig = {
    ...(inputType === 'numeric' ? { type: 'number' } : {}),
    ...restProps,
  };

  useEffect(() => {
    const handleFocusInput = async () => {
      const $wrapper = refInput.current;

      const $allInput = $wrapper.querySelectorAll('input');

      if ($allInput?.length > 0) {
        $allInput?.[focusIndex].focus();
      }
      setFocusIndex(null);
    };

    if (
      !reloadOtpInput &&
      inputReady &&
      refInput.current &&
      focusIndex !== null
    ) {
      handleFocusInput();
    }

    if (reloadOtpInput) {
      setInputReady(false);
    }
  }, [reloadOtpInput, focusIndex, inputReady]);

  useImperativeHandle(ref, () => ({
    focusInput: handleFocus,
  }));

  return (
    <>
      {reloadOtpInput && (
        <InputOTP
          value={[]}
          inputType={inputType}
          length={length}
          {...additionalConfig}
        />
      )}
      {!reloadOtpInput && (
        <InputOTP
          ref={async (ref) => {
            refInput.current = ref;
            await sleep(200);
            setInputReady(true);
          }}
          onChange={onChangeOtp}
          value={otp}
          inputType={inputType}
          length={length}
          {...additionalConfig}
        />
      )}
    </>
  );
};

export default forwardRef(AntdOtp);
