import React from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/home';

import { ReactComponent as IconAddToBan } from 'common/components/button/svg-icons/ICO_10.37.svg';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import { RibbonButton } from 'common/components';

const AddToBan = (props) => {
  const { handleClick } = props;
  
  const selectedMember = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  return (
    <RibbonButton
      disabled={selectedMember?.length <= 0}
      size='small'
      icon={<IconAddToBan />}
      label={Messages.banMember}
      onClick={() => handleClick()}
    />
  );
};

AddToBan.propTypes = {};

export default AddToBan;
