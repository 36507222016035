import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Typography,
  Checkbox,
  Input,
  Select,
  Tag,
  AutoComplete,
  notification,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/user-profile.js';
import { formatMDY } from 'utils/formatDate';
import * as userProfileService from 'services/user';
import * as permissionService from 'services/roles-security';
import * as memberMaintenanceServices from 'services/memberMaintenance';
import { USER_PROFILE_STATUS } from 'static/Constants';
import * as IconListArray from 'common/components/icon-list/assets';

import { Form, WrapperSelect } from 'common/components';

import {
  QuestionCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import userSelectors from 'redux/user/selectors';
import * as action from 'redux/user/actions';
import * as _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;
const IconListName = IconListArray.default;

const entitiesPermisiionList = [
  { permission: 'VIEW_MEMBERS', type: 'Members' },
  { permission: 'VIEW_PRODUCTS', type: 'Products' },
  { permission: 'VIEW_ASSETS', type: 'Digital-media' },
  { permission: 'VIEW_FOLDERS', type: 'Folders' },
  { permission: 'VIEW_REPORTING', type: 'Reportings' },
];

const memberPermisiionList = [
  { permission: 'accessMember', value: 'Members' },
  { permission: 'accessProducts', value: 'Products' },
  { permission: 'accessDigitalAssets', value: 'Digital-Media' },
  { permission: 'accessFolders', value: 'Folders' },
  { permission: 'accessReporting', value: 'Reportings' },
];

const GET_PUBLIC_SPECIFICATION = [
  { action: ABILITY_ACTION.VIEW, subject: ABILITY_SUBJECT.MEMBER },
  { action: ABILITY_ACTION.VIEW, subject: ABILITY_SUBJECT.ASSET },
];

const LANDING_PAGE = {
  'digital-media': 'Digital-Media',
  folders: 'Folders',
  members: 'Members',
  products: 'Products',
  reportings: 'Reportings',
};

const EditOverviewInfo = (props) => {
  const { overviewInfo, setMode, handleGetUserProfile, setLoading, memberId } =
    props;
  const dispatch = useDispatch();

  const [reportToList, setReportToList] = useState([]);
  const [reportToId, setReportToId] = useState(null);
  const [publicSpec, setPublicSpec] = useState([]);
  const [validUsername, setValidUsername] = useState('valid');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [landingPageList, setLandingPageList] = useState([]);
  const [form] = Form.useForm();
  const layout = { labelCol: { span: 9 }, wrapperCol: { span: 14 } };
  const rightLayout = { labelCol: { span: 16 }, wrapperCol: { span: 8 } };

  const hasGetPublicSpecification = useCheckPermissions(
    GET_PUBLIC_SPECIFICATION
  );
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const resetData = () => {
    form.setFieldsValue({
      companyName: overviewInfo?.companyName,
      userName: overviewInfo?.userName,
      created: formatMDY(overviewInfo?.created),
      title: overviewInfo?.title,
      position: overviewInfo?.position,
      department: overviewInfo?.department,
      reportTo: overviewInfo?.reportToUserId || overviewInfo?.reportTo,
      lastActivity: formatMDY(overviewInfo?.lastActivity),
      searchCategories: overviewInfo?.searchCategories,
      receiveCompanyCommunications: overviewInfo?.receiveCompanyCommunications,
      status: overviewInfo?.status,
      landingPage: overviewInfo?.landingPage,
      availableForContact: overviewInfo?.availableForContact,
      accountExpires: formatMDY(overviewInfo?.accountExpires),
      passwordExpiration: overviewInfo?.passwordExpiration,
      optOutEmail: overviewInfo?.optOutEmail,
      optOutText: overviewInfo?.optOutText,
    });
  };

  useEffect(() => {
    resetData();
    userProfileService
      .getPublicSpecification({ MemberId: memberId })
      .then((res) => {
        if (res?.isSuccess && res?.data) {
          setPublicSpec(res?.data[0].specification);
        }
      });
    userProfileService.getSuggestionUsers({ SearchText: '' }).then((res) => {
      if (res?.isSuccess) {
        setReportToList(res?.data?.suggestionUsers);
      }
    });

    if (overviewInfo?.reportToUserId)
      userProfileService
        .getUserProfileHeader({ UserId: overviewInfo?.reportToUserId })
        .then((res) => {
          if (res?.isSuccess) {
            form.setFieldsValue({ reportTo: res?.data?.fullName });
          }
        });
    // TODO BP 11/30/2021 - review permissions
    permissionService.getUserRoles({ UserId: overviewInfo?.id }).then((res) => {
      let landingPageOption = [];
      if (res?.isSuccess) {
        const arr = res?.data?.permissions;
        entitiesPermisiionList.forEach((item) => {
          if (arr.includes(item.permission)) {
            landingPageOption.push(LANDING_PAGE[item?.type?.toLowerCase()]);
          }
        });
      }

      memberMaintenanceServices
        .getMemberSecurityAccess({ memberId: memberId })
        .then((res) => {
          if (res?.isSuccess && landingPageOption?.length > 0) {
            let landingPageOptionWithMember = [];
            const memberPermission = res?.data;
            memberPermisiionList.forEach((item) => {
              if (memberPermission[item.permission] === true) {
                const findLandingPage = landingPageOption?.find(
                  (val) => val === item?.value
                );
                if (!_.isEmpty(findLandingPage))
                  landingPageOptionWithMember.push(findLandingPage);
              }
            });
            const landingPageOptionSorted = [
              ...landingPageOptionWithMember,
            ].sort();
            setLandingPageList(landingPageOptionSorted);
          } else {
            const landingPageOptionSorted = [...landingPageOption].sort();
            setLandingPageList(landingPageOptionSorted);
          }
        });
    });
  }, [overviewInfo]);

  const onFinish = (values) => {
    if (validUsername === 'valid') {
      setLoading(true);
      userProfileService
        .editUserProfileHeader({
          ...values,
          userId: overviewInfo?.id,
          reportToUserId: reportToId,
        })
        .then((res) => {
          setLoading(false);
          if (res?.isSuccess) {
            setMode();
            handleGetUserProfile();
            onUpdateSearchCategoryInUserInfoObject(values?.searchCategories);
            notification.success({
              message: 'User profile Updated Successfully!',
            });
          } else {
            const message =
              res?.message ||
              res?.Status.join(', ') ||
              'User profile update failed!';
            notification.error({ message });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else notification.error({ message: 'User Name Is Not Valid!' });
  };

  const onSearchReportTo = (val) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(function () {
        userProfileService
          .getSuggestionUsers({ SearchText: val })
          .then((res) => {
            if (res?.isSuccess) {
              setReportToList(res?.data?.suggestionUsers);
            }
          });
      }, 300)
    );
  };

  const onUpdateSearchCategoryInUserInfoObject = (categories) => {
    let categoriesTemp = new Array(categories);
    let categoriesOnUserInfo = new Array(userInfo?.searchCategories);
    if (!_.isEqual(categoriesTemp.sort(), categoriesOnUserInfo.sort())) {
      let userInfoUpdated = { ...userInfo, searchCategories: categories };
      dispatch(action.loginSuccess(userInfoUpdated));
    }
  };

  const onSelectReportTo = (val) => {
    userProfileService.getUserProfileHeader({ UserId: val }).then((res) => {
      if (res?.isSuccess) {
        setReportToId(val);
        form.setFieldsValue({ reportTo: res?.data?.fullName });
      }
    });
  };

  const onChangeReportTo = () => setReportToId(null);

  const tagRender = (props) => {
    const { value, closable, onClose } = props;
    const src = IconListName.filter(
      (icon) =>
        icon.segmentDescription.toLowerCase().trim() ===
        value.toLowerCase().trim()
    );
    return (
      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {src && src[0]?.segmentIcon ? (
          <img
            style={{ width: 20, height: 20, marginRight: 4 }}
            src={src[0]?.segmentIcon}
          />
        ) : (
          <QuestionCircleOutlined />
        )}
      </Tag>
    );
  };

  const onUsernameChange = (event) => {
    if (typingTimeout) {
      setValidUsername('loading');
      clearTimeout(typingTimeout);
    }

    let data = event.target.value;
    if (data && data.length >= 5) {
      setTypingTimeout(
        setTimeout(function () {
          userProfileService
            .checkUsernameAvailable({
              UserId: overviewInfo?.id,
              UserName: data,
            })
            .then((res) => {
              if (res?.isSuccess) setValidUsername('valid');
              else setValidUsername('error');
            });
        }, 300)
      );
    } else setValidUsername('error');
  };

  return (
    <Form
      form={form}
      {...layout}
      name='overview-info-form'
      id='overview-info-form'
      onFinish={onFinish}
      style={{ position: 'relative' }}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            label={<FormattedMessage {...Messages.companyName} />}
            name='companyName'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.userName} />}
            name='userName'
            validateStatus={validUsername === 'error' && 'error'}
          >
            <Input
              onChange={onUsernameChange}
              suffix={
                validUsername === 'valid' ? (
                  <CheckCircleTwoTone twoToneColor='#52c41a' />
                ) : validUsername === 'error' ? (
                  <CloseCircleTwoTone twoToneColor='#eb2f96' />
                ) : (
                  validUsername === 'loading' && <LoadingOutlined />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.created} />}
            name='created'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.title} />}
            name='title'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.position} />}
            name='position'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.department} />}
            name='department'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.reportsTo} />}
            name='reportTo'
          >
            <AutoComplete
              onSearch={onSearchReportTo}
              onSelect={onSelectReportTo}
              onChange={onChangeReportTo}
              placeholder='Search Available Users'
              getPopupContainer={() =>
                document.getElementById('overview-info-form')
              }
              notFoundContent='Not Found'
            >
              {reportToList?.length > 0 &&
                reportToList.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.firstName} {item.lastName}
                    </Option>
                  );
                })}
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.lastActivity} />}
            name='lastActivity'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.searchCategories} />}
            name='searchCategories'
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('overview-info-form')
              }
              tagRender={tagRender}
              mode='multiple'
            >
              {publicSpec?.length > 0 &&
                publicSpec.map((item, index) => {
                  return (
                    <Option key={index} value={item.segmentDescription}>
                      {item.segmentDescription}
                    </Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormattedMessage {...Messages.receiveCompanyCommunications} />
            }
            name='receiveCompanyCommunications'
            valuePropName='checked'
            {...rightLayout}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.status} />}
            name='status'
            rules={[{ required: true, message: 'Please select your status!' }]}
            {...rightLayout}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('overview-info-form')
              }
              allowClear={false}
            >
              {USER_PROFILE_STATUS.map((item, index) => {
                return (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.landingPage} />}
            name='landingPage'
            {...rightLayout}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('overview-info-form')
              }
              className='user-profile-overview__info-landing-page'
            >
              <Option key={-1} value={null}>
                None
              </Option>
              {landingPageList?.length > 0 &&
                landingPageList.map((item, index) => {
                  return (
                    <Option
                      key={index}
                      value={item?.toLowerCase()}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item}
                    </Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.availableForContact} />}
            name='availableForContact'
            valuePropName='checked'
            {...rightLayout}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.accountExpires} />}
            name='accountExpires'
            {...rightLayout}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.passwordExpiration} />}
            name='passwordExpiration'
            {...rightLayout}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage {...Messages.optOut} />}
            name='optOutEmail'
            valuePropName='checked'
            {...rightLayout}
          >
            <Checkbox>Email</Checkbox>
          </Form.Item>
          <Form.Item
            label='&nbsp;'
            colon={false}
            name='optOutText'
            valuePropName='checked'
            {...rightLayout}
          >
            <Checkbox>Text</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

EditOverviewInfo.propTypes = {
  overviewInfo: PropTypes.object,
  showModal: PropTypes.func,
  setMode: PropTypes.func,
  handleGetUserProfile: PropTypes.func,
  setLoading: PropTypes.func,
};

export default EditOverviewInfo;
