import React from 'react';

import { Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FormAddButton } from 'common/components';

const { Text } = Typography;

const WarningMessage = ({ toggleProductSearchDrawer }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Text>
      Product Id was not given please search for product item and fix its error
      to pass your publication,
    </Text>
    <FormAddButton
      text='Search product item'
      icon={<SearchOutlined />}
      onClick={toggleProductSearchDrawer && toggleProductSearchDrawer}
    />
  </div>
);

export const mapToPublishErrorAlert = (error, toggleProductSearchDrawer) => {
  return error && error?.errorProductId
    ? {
        message: 'Please fix error in the product item to pass your publication',
        status: 'error',
      }
    : {
        message: (
          <WarningMessage
            toggleProductSearchDrawer={toggleProductSearchDrawer}
          />
        ),
        status: 'warning',
      };
};
