import React, { useState, useEffect, useMemo, useRef } from 'react';

import { useSelector } from 'react-redux';

import PropTypes, { oneOfType } from 'prop-types';
import {
  Typography,
  Checkbox,
  Row,
  Col,
  Input,
  Empty,
  Tooltip,
  Select,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';
import messagesMapping from 'i18n/messages/mapping';

import {
  Form,
  StyledModal,
  CustomNotification,
  FormAddButton,
  FormDeleteButton,
  dialogFunction,
  WrapperSelect,
} from 'common/components';
import AgGrid from 'common/components/ag-grid/AgGrid';

import {
  InformationLabel,
  InformationData,
  Loading,
} from 'pages/branded-products/components/content-pane/SharedComponents';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { SYNDICATION_METHOD } from 'static/Constants';
import {
  subscribeProductOrMember,
  editSubscription,
} from 'services/subscription';

import reducerMapping from 'pages/mapping/controllers/reducer';
import sagaMapping from 'pages/mapping/controllers/saga';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import userSelectors from 'redux/user/selectors';
import * as endpoints from 'services/mapping/endpoints';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import {
  useGetMappingConfig,
  useGetAllMemberGlns,
  checkRenderMethod,
  getMethodsSubscription,
  getSyncParams,
  mappingColumns,
} from './utils';

import { useDeepCompareEffect } from 'hooks';

import './ProductSubscriptionModal.less';

const { Title } = Typography;

const ProductSubscriptionModal = ({
  useVisibleModal,
  modalTitle,
  type,
  selectedSubscription,
  id,
  isEdit,
  ...rest
}) => {
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();
  const [form] = Form.useForm();

  const [visibleModal, setVisibleModal] = useVisibleModal;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [methods, setMethods] = useState([]);
  const [selectedMapping, setSelectedMapping] = useState(null);

  const { data } = useGetMappingConfig({
    mappingId: selectedSubscription?.mappingId,
    isEnabled: Boolean(selectedSubscription?.mappingId) && isEdit,
  });

  const { sourceGlns } = useGetAllMemberGlns();

  const { isRivirGdsnParty } = useSelector(userSelectors.makeSelectUserInfo());

  const itemCurrentSelection = useSelector(
    selectorsGridView.makeSelectItemCurrentSelection()
  );

  const currentSelectedMemberId = itemCurrentSelection?.id;

  const displayMappingGrid = useMemo(() => {
    return methods.includes(SYNDICATION_METHOD.enableRivirPull);
  }, [methods]);

  const enableRivirAlert = useMemo(() => {
    return methods.includes(SYNDICATION_METHOD.enableRivirAlert);
  }, [methods]);

  useDeepCompareEffect(() => {
    if (visibleModal) {
      const methods = getMethodsSubscription(selectedSubscription);
      setMethods(methods);
      form.setFieldsValue({
        methods,
      });
    }
  }, [form, selectedSubscription, visibleModal]);

  useEffect(() => {
    if (isEdit) {
      setSelectedMapping(data);
    }
  }, [data, isEdit]);

  const submitSubscribeProductOrMember = async () => {
    try {
      const { methods, fromGln } = await form.validateFields();

      const syncParams = getSyncParams(methods);

      if (!id && !isEdit) {
        CustomNotification.error('Something went wrong!');
        return;
      }

      setSubmitLoading(true);

      const params = {
        subId: isEdit ? selectedSubscription?.id : undefined,
        subType: type, // Member or Product
        productId: type === 'Product' ? id : 0,
        memberId: type === 'Member' ? currentSelectedMemberId : 0,
        mappingId:
          displayMappingGrid && selectedMapping ? selectedMapping?.id : 0,
        fromGln: null,
        ...syncParams,
      };

      const service = isEdit ? editSubscription : subscribeProductOrMember;
      const successMessage = intl.formatMessage(
        Messages[isEdit ? 'editSubscriptionSuccess' : 'subscribeSuccess']
      );
      const errorMessage = intl.formatMessage(
        Messages[isEdit ? 'editSubscriptionError' : 'subscribeError']
      );

      service(params)
        .then((res) => {
          if (res.isSuccess) {
            CustomNotification.success(successMessage);
            reloadGrid();
          } else {
            CustomNotification.error(res?.message || errorMessage);
          }
        })
        .catch((err) => {
          CustomNotification.error(errorMessage);
        })
        .finally(() => {
          setTimeout(() => {
            setVisibleModal(false);
            setSubmitLoading(false);
            setSelectedMapping(null);
            setMethods([]);
          }, 100);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const reloadGrid = () => {
    if (!isEdit) return;
    reloadPage();
  };

  const handleSelectMethod = (methods) => {
    setMethods(methods);
    form.setFieldsValue({
      methods: methods,
    });
  };

  const handleDeleteMapping = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the selected mapping?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
        disabled: methods === [],
      },
      onOk: () => setSelectedMapping(null),
    });
  };

  const modalProps = {
    visible: visibleModal,
    title: modalTitle,
    className: 'product-subscription-modal',
    onOk: submitSubscribeProductOrMember,
    onCancel: () => {
      setVisibleModal(false);
      setSelectedMapping(null);
      setMethods([]);
    },
    okButtonProps: {
      loading: submitLoading,
    },
    maskClosable: false,
    width: '1000px',
    destroyOnClose: true,
  };

  return (
    <>
      {visibleModal && (
        <StyledModal {...modalProps}>
          <Row>
            <Col span={12}>
              <Title level={5}>
                <FormattedMessage {...Messages.pickupSyndication} />
              </Title>
              <Form form={form}>
                <Form.Item name='methods' valuePropName='checked'>
                  <Checkbox.Group onChange={handleSelectMethod} value={methods}>
                    <Row>
                      {Object.entries(SYNDICATION_METHOD).map(
                        ([key, value]) =>
                          checkRenderMethod(value, isRivirGdsnParty) && (
                            <Col span={24} key={key}>
                              <Checkbox
                                value={value}
                                style={{ lineHeight: '32px' }}
                              >
                                {value}
                              </Checkbox>
                            </Col>
                          )
                      )}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Form>

              {selectedMapping && displayMappingGrid && (
                <Row style={{ marginTop: 12 }}>
                  <Col span={24} style={{ marginBottom: 6 }}>
                    <Row>
                      <Col span={18}>
                        <Title level={5}>Selected Mapping:</Title>
                      </Col>
                      <Col span={6}>
                        <FormDeleteButton onClick={handleDeleteMapping} />
                      </Col>
                    </Row>
                  </Col>

                  <InformationLabel>
                    {intl.formatMessage(messagesMapping.mappingName)}
                  </InformationLabel>
                  <InformationData data={selectedMapping?.mappingName} />

                  <InformationLabel>
                    {intl.formatMessage(messagesMapping.mappingDescription)}
                  </InformationLabel>
                  <InformationData data={selectedMapping?.mappingDescription} />

                  <InformationLabel>
                    {intl.formatMessage(messagesMapping.deliveryMethods)}
                  </InformationLabel>
                  <InformationData
                    data={selectedMapping?.deliveryMethods?.join(', ')}
                  />

                  <InformationLabel>
                    {intl.formatMessage(messagesMapping.formatTypes)}
                  </InformationLabel>
                  <InformationData
                    data={selectedMapping?.formatTypes?.join(', ')}
                  />
                </Row>
              )}
            </Col>
            <Col span={12} style={{ height: 460 }}>
              {displayMappingGrid ? (
                <MappingGrid
                  isEdit={isEdit}
                  setSelectedMapping={setSelectedMapping}
                  methods={methods}
                  selectedMapping={selectedMapping}
                />
              ) : enableRivirAlert ? (
                <>
                  <Title level={4}>
                    <FormattedMessage {...Messages.alertSynd} />
                  </Title>
                  <Row
                    justify='center'
                    className='product-subscription-modal__mapping-description'
                  >
                    <FormattedMessage {...Messages.alertSyndDescription} />
                  </Row>
                </>
              ) : (
                <>
                  <Title level={4}>
                    <FormattedMessage {...Messages.mappingSection} />
                  </Title>
                  <Row
                    justify='center'
                    className='product-subscription-modal__mapping-description-empty'
                  >
                    <Empty />
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </StyledModal>
      )}
    </>
  );
};

const key = 'mappings';

const MappingGrid = ({ setSelectedMapping, methods, selectedMapping }) => {
  const methodsSyndication = {
    enableRivirPull: methods.indexOf('RIVIR PULL') > -1,
  };
  useInjectReducer({ key, reducer: reducerMapping });
  useInjectSaga({ key, saga: sagaMapping });

  const [searchText, setSearchText] = useState('');
  const gridApi = useRef(null);

  const handleAddConfig = () => {
    if (gridApi?.current?.getSelectedRows().length > 0) {
      const selectedRow = gridApi.current.getSelectedRows()[0];
      setSelectedMapping(selectedRow);
    }
  };

  return (
    <div style={{ height: '430px', marginBottom: 40 }}>
      {mappingColumns?.length === 0 ? (
        <Loading />
      ) : (
        <>
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <Input.Search
              placeholder='Search mapping'
              allowClear
              onSearch={(value) => setSearchText(value)}
            />
            <Tooltip title='Only add one mapping'>
              <FormAddButton
                text='Add'
                style={{ marginLeft: 10 }}
                onClick={handleAddConfig}
                disabled={selectedMapping}
              />
            </Tooltip>
          </div>
          <AgGrid
            columnDefs={mappingColumns}
            urlGrid={endpoints.GET_SUB_MAPPING_GRID}
            responseParams='data'
            gridConfigProps={{
              rowSelection: 'single',
            }}
            getGridApi={(api) => {
              gridApi.current = api;
            }}
            paramsGrid={{
              search: {
                searchText,
              },
              ...methodsSyndication,
            }}
          />
        </>
      )}
    </div>
  );
};

ProductSubscriptionModal.propTypes = {
  useVisibleModal: PropTypes.array,
  modalTitle: PropTypes.string,
  type: PropTypes.oneOf(['Product', 'Member']),
  id: oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

export default ProductSubscriptionModal;
