import React from 'react';

const DefaultCellRender = (params) => {
  const { value } = params;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div style={{ maxWidth: '100%' }}>{value && value}</div>
    </div>
  );
};

export default DefaultCellRender;
