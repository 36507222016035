import React, { useState, useEffect } from 'react';

import { Spin, Row, Col, Result, Empty, Pagination, Typography } from 'antd';
import { WithLoading, ThumbnailItem } from '../index';

import { useIntl, FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/logoAsset';

import './LogoAssetGrid.less';

const LogoAssetGrid = (props) => {
  const intl = useIntl();

  const { visible, getLogoAssetRequest, onSelectLogoAsset, responseParams } =
    props;

  //* state
  const [loading, setLoading] = useState(false);
  const [logoAssetList, setLogoAssetList] = useState(false);
  const [error, setError] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [total, setTotal] = useState(0);

  //* functions
  const getLogoAssetList = () => {
    setLoading(true);

    const params = {
      pageIndex,
      pageSize,
    };

    getLogoAssetRequest &&
      getLogoAssetRequest(params)
        .then((response) => {
          let data;
          if (responseParams) {
            data = response[responseParams];
          } else {
            data = response;
          }
          const {
            gridData,
            paging: { totalRecord },
          } = data;
          if (gridData) {
            setLogoAssetList(gridData);
            setTotal(totalRecord);
          } else {
            getLogoAssetErrorHandler();
          }
        })
        .catch(() => {
          getLogoAssetErrorHandler();
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const getLogoAssetErrorHandler = () => {
    setError(true);
  };

  const selectLogoAssetItem = (item) => {
    if (selectedLogo?.uniqueId !== item?.uniqueId) {
      setSelectedLogo(item);
    } else {
      setSelectedLogo(false);
    }
  };

  const resetLogoAssetList = () => {
    setLogoAssetList(false);
    setSelectedLogo(false);
  };

  const changePageSizeHandler = (page, pageSize) => {
    setPageIndex(page);
    setPageSize(pageSize);
  };

  //* did update
  useEffect(() => {
    visible ? getLogoAssetList() : resetLogoAssetList();
  }, [visible, pageIndex, pageSize]);

  useEffect(() => {
    onSelectLogoAsset &&
      onSelectLogoAsset(
        selectedLogo?.uniqueId,
        selectedLogo?.thumbnail300 ||
          selectedLogo?.thumbnail100 ||
          selectedLogo?.thumb100 ||
          selectedLogo?.url ||
          selectedLogo?.fileUrl,
        selectedLogo
      );
  }, [selectedLogo]);

  //* render
  const renderLogoAssetList = () => {
    const emptyMessage = intl.formatMessage(Messages.emptyMessage);

    if (logoAssetList?.length === 0)
      return <Empty className='logo-asset__empty' description={emptyMessage} />;

    return (
      logoAssetList &&
      logoAssetList.map((asset) => {
        const { uniqueId, url } = asset;
        const isSelected = selectedLogo.uniqueId === uniqueId;

        return (
          <Col flex={0} key={uniqueId}>
            <ThumbnailItem
              hideLabel={true}
              imageThumbnail={
                url ||
                asset?.fileUrl ||
                asset?.thumbnail300 ||
                asset?.thumbnail100 ||
                asset?.thumbSize100 ||
                asset?.thumb100
              }
              dataDetail={asset}
              onClickItemGrid={selectLogoAssetItem}
              onClickCheckboxItem={selectLogoAssetItem}
              selected={isSelected}
            />
          </Col>
        );
      })
    );
  };

  const errorMessage = intl.formatMessage(Messages.errorMessage);

  const showShowPagination = logoAssetList?.length > 0 && !error;

  return (
    <WithLoading loading={loading}>
      <Row className='logo-asset__container scroller'>
        {error ? (
          <Result
            className='logo-asset__error-message'
            status='error'
            title={errorMessage}
          />
        ) : (
          renderLogoAssetList()
        )}
      </Row>
      {showShowPagination ? (
        <Row className='logo-asset__pagination'>
          <Col
            span={24}
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <Pagination
              total={total}
              current={pageIndex}
              pageSize={pageSize}
              showSizeChanger
              onChange={changePageSizeHandler}
            />
            <Typography.Text strong={true} style={{ marginLeft: 8 }}>
              {total ? total : ''} &nbsp;
            </Typography.Text>
            <FormattedMessage {...Messages.totalItems} />
          </Col>
        </Row>
      ) : null}
    </WithLoading>
  );
};

export default LogoAssetGrid;
