// Roles and Security
export const GET_ROLES_ENDPOINT = '/api/authentication/get-roles-by-member';
export const GET_AVAILABLE_PERMISSIONS_ENDPOINT =
  '/api/authentication/get-available-permissions';
export const GET_ROLE_PERMISSIONS_ENDPOINT =
  '/api/authentication/get-list-permissions-in-role';
export const USERS_HAVE_NOT_ROLE =
  '/api/authentication/get-users-have-not-role';
export const USERS_HAVE_NOT_ROLE_DISTINCT =
  '/api/authentication/get-users-have-not-role/distinct';
export const GET_USERS_HAVE_ROLE_ENDPOINT =
  '/api/authentication/get-users-have-role';
export const GET_USERS_HAVE_ROLE__DISTINCT_ENDPOINT =
  '/api/authentication/get-users-have-role/distinct';
export const ADD_USERS_TO_ROLE = '/api/authentication/add-users-to-role';
export const EDIT_ROLE = '/api/authentication/save-role';
export const REMOVE_USERS_FROM_ROLE =
  '/api/authentication/remove-users-from-role';
export const DELETE_ROLE = '/api/authentication/delete-role';
export const GET_USER_ROLES =
  '/api/authentication/get-user-roles-and-permissions';
export const SAVE_USER_ROLES = '/api/authentication/save-user-role';
export const GET_WORKFLOW_ROLES = '/api/workflow/workflow-roles-grid';
export const SAVE_WORKFLOW_ROLES = '/api/workflow/save-workflow-role';
export const REMOVE_WORKFLOW_ROLES = '/api/workflow/remove-workflow-role';
export const GET_MEMBER_ROLES = '/api/member-role/get-member-role-list';
export const GET_ALL_MEMBER_PERMISSION_LIST =
  '/api/member-role/get-all-permission-list';
export const SAVE_MEMBER_ROLE = '/api/member-role/save-member-role';
export const DELETE_MEMBER_ROLE = '/api/member-role/delete-member-role';
export const GET_MEMBERS_HAVE_ROLE_ENDPOINT =
  '/api/member-role/get-member-list-in-role';
export const REMOVE_MEMBERS_FROM_ROLE =
  '/api/member-role/remove-members-from-role';
export const MEMBERS_NOT_HAVE_ROLE =
  '/api/member-role/get-member-list-not-in-role';
export const MEMBERS_HAVE_NOT_ROLE_DISTINCT =
  '/api/member-role/get-member-list-not-in-role/distinct';
export const ADD_MEMBERS_TO_ROLE = '/api/member-role/add-members-to-role';

export const ADD_ROLES_TO_USER = '/api/authentication/add-roles-to-user';
export const REMOVE_ROLES_FROM_USER =
  '/api/authentication/remove-roles-from-user';
