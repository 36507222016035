import React from 'react';

import {
  Button,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Select,
  DatePicker,
  Space,
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

import {
  WrapperSelect,
  NumberTextInput,
  BrickCodeInput,
} from 'common/components';

import { useGetTargetMarketList } from 'hooks/createProductHooks';

import sortByKey from 'utils/sortByKey';

const MinimumRequirementPropertiesInput = (props) => {
  const { item, updateFormValue, hasBrickCode } = props;
  const inputSchema = item?.inputSchema;

  const targetMarketList = useGetTargetMarketList();

  const numberFormat = () => {
    return {
      formatter: (value) =>
        `${value}${inputSchema.format ? inputSchema.format : ''}`,
      parser: (value) => value.replace(inputSchema.format, ''),
    };
  };

  const renderSelectInput = () => {
    return (
      <WrapperSelect
        mode={inputSchema.mode}
        tagRender={inputSchema.tagRender}
        showSearch={true}
        getPopupContainer={() =>
          document.getElementById('validate-minimum-requirement')
        }
      >
        {sortByKey(inputSchema.options, 'label')?.map((option, index) => {
          const { value, label, icon } = option;
          return (
            <Select.Option key={inputSchema.name + index} value={value}>
              {option.icon && (
                <img
                  style={{ width: 20, height: 20, marginRight: 4 }}
                  src={icon}
                  alt={label}
                />
              )}
              {label}
            </Select.Option>
          );
        })}
      </WrapperSelect>
    );
  };

  const renderListInput = (inputFieldData) => {
    return (
      <Form.List name={inputFieldData.name}>
        {(fields, { add, remove }) => {
          return (
            <>
              <div>
                {fields.map((field) => (
                  <Space key={field.key} align='baseline'>
                    <Form.Item {...field}>
                      {inputType[inputFieldData.type]}
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}
              </div>
              <div>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add value
                </Button>
              </div>
            </>
          );
        }}
      </Form.List>
    );
  };

  const renderInput = () => {
    return <Input />;
  };

  const renderCustomInput = () => {
    const customInputComponents = {
      targetMarket: renderTargetMarketInput(targetMarketList),
      brickCode: renderBrickCode(updateFormValue),
      brickCodeName: renderBrickCodeName({ updateFormValue, hasBrickCode }),
      StateOfIncorporation: renderInput,
    };

    return (
      customInputComponents[inputSchema.name] &&
      customInputComponents[inputSchema.name]()
    );
  };

  const inputType = inputSchema
    ? {
        text: <Input />,
        checkbox: <Checkbox />,
        number: <InputNumber {...numberFormat()} min={inputSchema.min} />,
        select: renderSelectInput(),
        datePicker: (
          <DatePicker
            format={inputSchema.dateFormat}
            getPopupContainer={() =>
              document.getElementById('validate-minimum-requirement')
            }
          />
        ),
        numberText: <NumberTextInput />,
        custom: renderCustomInput(),
        selectEnum: renderSelectInput(),
      }
    : {};

  return inputSchema ? (
    <Form.Item
      name={inputSchema.name}
      valuePropName={inputSchema.type === 'checkbox' ? 'checked' : undefined}
    >
      {inputSchema.isList
        ? renderListInput(inputSchema)
        : inputType[inputSchema.type]}
    </Form.Item>
  ) : null;
};

const renderTargetMarketInput = (targetMarketList) => () => {
  const sortTargetMarket = () => {
    if (!targetMarketList) return [];
    const USMarket = targetMarketList.find((marketItem, index) => {
      if (marketItem.Numeric !== 840) return false;

      return true;
    });

    const noneUSMarketList = targetMarketList.filter((marketItem) => {
      return marketItem.Numeric !== 840;
    });

    const sortedNoneUSMarketList = noneUSMarketList.sort((a, b) => {
      return a.Country - b.Country;
    });

    return [USMarket, ...sortedNoneUSMarketList];
  };

  const sortedTargetMarket = sortTargetMarket();

  return (
    <WrapperSelect
      getPopupContainer={() =>
        document.getElementById('validate-minimum-requirement')
      }
      allowClear
      showSearch
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {targetMarketList?.length > 0 &&
        sortedTargetMarket?.map((item, index) => (
          <Select.Option key={index} value={item?.Country}>
            {item?.Country}
          </Select.Option>
        ))}
    </WrapperSelect>
  );
};

const renderBrickCode = (updateFormValue) => () => {
  const updateBrickCodeData = (brick) => {
    updateFormValue({
      brickCode: brick.brickCode || null,
      brickCodeName: brick.brickCodeName || null,
    });
  };

  return <BrickCodeInput updateBrickCodeData={updateBrickCodeData} />;
};

const renderBrickCodeName =
  ({ updateFormValue, hasBrickCode }) =>
  () => {
    return hasBrickCode ? (
      <Input disabled />
    ) : (
      <>
        <Form.Item name='brickCode' hidden>
          render hidden input to collect brick code
        </Form.Item>
        {renderBrickCode(updateFormValue)()}
      </>
    );
  };

export default MinimumRequirementPropertiesInput;
