import { Button } from 'antd';
import React from 'react';
import Messages from 'i18n/messages/minimum-requirement';
import { FormattedMessage } from 'react-intl';

const SubmitButton = ({ handleSubmit, isLoading, isDisabled }) => {
  return (
    <Button
      type='primary'
      onClick={handleSubmit}
      loading={isLoading}
      disabled={isDisabled}
    >
      <FormattedMessage {...Messages.submitButton} />
    </Button>
  );
};

const CancelButton = ({ setVisible }) => {
  return (
    <Button
      onClick={() => {
        setVisible(false);
      }}
    >
      <FormattedMessage {...Messages.cancelButton} />
    </Button>
  );
};

export { SubmitButton, CancelButton };
