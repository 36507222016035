import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import PropertyAdvancedFilter from './PropertyAdvancedFilter';
import QueryConditions from './QueryConditions';
import { checkPathName } from 'common/components/nested-advance-Search/hook/utils';
import { useAdvanceFilter } from 'common/components/nested-advance-Search/hook/hook';

import './AdvanceFilterPanel.less';

const AdvanceFilterPanel = ({ onClosePanel, queryConditions }) => {
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const entityType = checkPathName(pathName);
  const [propertiesData, setPropertiesData] = useState();

  const { fetchEntityAttribute } = useAdvanceFilter({});
  const emptyFunc = () => {};

  const handleGetEntity = async () => {
    try {
      const data = await fetchEntityAttribute(entityType);
      if (data) {
        setPropertiesData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!propertiesData) handleGetEntity();
  }, [entityType, propertiesData]);

  const handleOpenAdvancedFilter = () => {
    dispatch(actionsGridView.updateIsOpenAdvanceSearch(true));
  };

  return (
    <>
      <Row className='advanced-filer-pane__header'>
        <Col flex='auto' />
        <Row className='advanced-filter-content-pane__button'>
          <Button
            className='advanced-filter-content-pane__minimize-btn'
            onClick={onClosePanel}
          >
            <CloseOutlined />
          </Button>
        </Row>
      </Row>
      <Row className='advanced-filter-panel_content'>
        <Tooltip
          placement='topLeft'
          title='Edit Advanced Query'
          arrowPointAtCenter
        >
          <Button
            className='advanced-filter-panel_content-button'
            onClick={handleOpenAdvancedFilter}
          >
            <EditOutlined />
          </Button>
        </Tooltip>

        <QueryConditions
          viewMode='view'
          queryConditions={queryConditions}
          entityType={entityType}
          draggingTaskId={null}
          propertiesData={propertiesData}
          handleConjunction={emptyFunc}
          handleQueryCondition={emptyFunc}
          handleRemoveQueryConditions={emptyFunc}
          PropertyAdvancedFilter={PropertyAdvancedFilter}
        />
      </Row>
    </>
  );
};
export default AdvanceFilterPanel;
