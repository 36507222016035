import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const createQasRequiredFields = (params) => {
  return api.sendPost(endpoints.CREATE_QAS_REQUIRE_FIELD_GRID, params);
};

export const editQasRequiredFields = (params) => {
  return api.sendPost(endpoints.EDIT_QAS_REQUIRE_FIELD_GRID, params);
};

export const deleteQasRequiredFields = (params) => {
  return api.sendPost(endpoints.DELETE_QAS_REQUIRE_FIELD_GRID, params);
};

export const getQasRequiredFields = (data) => {
  return api.sendGet(endpoints.GET_QAS_REQUIRE_FIELD, { params: data });
};

export const getAllTargetMembers = (data) => {
  return api.sendGet(endpoints.GET_ALL_TERGET_MEMBERS, { params: data });
};

export const getAllProductFields = (data) => {
  return api.sendGet(endpoints.GET_ALL_PRODUCT_FIELDS, { params: data });
};

export const getQaSpecRequiredFieldForProduct = (params) => {
  return api.sendPost(
    endpoints.GET_QA_SPEC_REQUIRED_FIELDS_FOR_PRODUCT,
    params
  );
};
