import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Typography, Row, Col, Input, Space, Menu } from 'antd';

import classnames from 'classnames';

import { cloneDeep } from 'lodash';

import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { WithQaGridHeader } from 'pages/qa-spec/components';
import {
  WithLoading,
  CarouselNext,
  CarouselPrevious,
  useCarousel,
  CarouselSection,
  dialogFunction,
  CustomNotification,
  FormAddButton as AddButton,
  FormEditButton as EditButton,
  FormDeleteButton as DeleteButton,
} from 'common/components';

import IngredientForm from './IngredientForm';
import QaIngredientGrid from './QaIngredientGrid';

import {
  GroupActions,
  MenuItem,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/components';

import {
  QaGroupAddition,
  QaGroupAssign,
  QaGroupDeletion,
  QaGroupEdit,
  QaGroupPreview,
} from '../group-data';

import {
  useCheckAllowEdit,
  useCheckSnapshotForRetailer,
  useGetEnumOptions,
} from 'pages/qa-spec/hooks';
import { useTabExpand } from 'hooks/useTabExpandProduct';
import { useCheckAllowManageGroupData } from 'hooks';
import {
  TYPE_GROUPS,
  useGetAllGroupProduct,
  useVisibleGroupModal,
} from 'pages/product-full-view/components/product-media-area/shared/hooks';

import {
  queryKeyQaSpecIngredients,
  useGetIngredients,
  useGetIngredientsSnapshot,
  useMutationIngredients,
} from './hook';

import {
  getIngredientPercentTotal,
  mappingInitialIngredientList,
} from 'pages/qa-spec/utils/ingredient';

import { INGREDIENT_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';
import { useReloadDocumentCertificateTab } from 'hooks/useReloadDocumentCertificateTab';

import { GroupLayoutProvider } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/GroupLayoutProvider';

import { getCurrentSlide } from 'utils';
import { GroupPartName } from 'pages/product-full-view/components/product-media-area/multiple-panel/components';
import {
  getSelectedItemGroup,
  transformToTextGroupName,
} from 'pages/product-full-view/components/product-media-area/shared/utils';

import { filterProductGroups } from 'pages/product-full-view/components/product-media-area/multiple-panel/tab-allergens/utils';

import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';
import {
  HighlightContent,
  PrimaryFieldSnapshot,
  RetailerSnapshot,
} from '../qa-prod-spec/snapshot/RetailerSnapshot';
import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

import './QaSpecIngredient.less';

const { Title, Text } = Typography;

const QaSpecIngredient = () => {
  const { id: productId } = useParams();

  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [ingredientsMode, setIngredientsMode] = useState('add');
  const [currentSlide, setCurrentSlide] = useState(0);

  const gridInst = useRef();
  const { tableStyle } = useTabExpand();
  const queryClient = useQueryClient();
  const { reloadDocumentWhenDelete } = useReloadDocumentCertificateTab();

  const { isLoading, data } = useGetIngredients(productId);
  const { qaIngredientsSnapshot } = useGetIngredientsSnapshot();
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const { productGroups } = useGetAllGroupProduct(productId);
  const { carouselRef, onPrevCarousel, onNextCarousel } = useCarousel();
  const ingredientOptions = useGetEnumOptions()?.ingredient;

  const ingredientStatements = data?.ingredientStatements?.length
    ? data?.ingredientStatements
    : [{}];

  const ingredientData = useMemo(() => {
    const total = getIngredientPercentTotal(data?.ingredients || []);
    setTotal(total);

    return {
      ingredientList: mappingInitialIngredientList(data?.ingredients) || [],
    };
  }, [data]);

  const ingredientDataSnapshot = useMemo(() => {
    if (qaIngredientsSnapshot?.length === 0) return [];

    const total = getIngredientPercentTotal(
      qaIngredientsSnapshot?.ingredients || []
    );
    setTotal(total);

    return {
      ingredientList:
        mappingInitialIngredientList(qaIngredientsSnapshot?.ingredients) || [],
    };
  }, [qaIngredientsSnapshot]);

  const getCurrentIngredient = () => {
    return ingredientsMode === 'add'
      ? { index: null, isRemoved: false }
      : ingredientStatements?.[currentSlide];
  };

  /* Group Actions */
  const {
    visibleModalAddGroup,
    visibleModalEditGroup,
    visibleModalPreviewGroup,
    visibleModalAssignGroup,
    visibleModalDeleteGroup,
    handler: { onGroupModal, offGroupModal },
  } = useVisibleGroupModal();

  const hasManageGroupPermission = useCheckAllowManageGroupData();

  const selectedIngredientGroupIds =
    ingredientStatements?.[currentSlide]?.groupIds ?? [];

  const selectedIngredientsStatement =
    ingredientStatements?.[currentSlide] ?? {};

  const { defaultGroup, selectedProductGroups } = getSelectedItemGroup(
    selectedIngredientGroupIds,
    productGroups
  );
  const filteredProductGroups = filterProductGroups(
    selectedIngredientGroupIds,
    productGroups
  );
  /* End Groups  */

  const { mutation } = useMutationIngredients({ productId, setVisible });

  const confirmDelete = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning
          text='Ingredient Statement'
          ixoneIgnore={ixoneIgnore}
        >
          Are you sure you want to delete current Ingredient Statement?
        </IXONEShieldWarning>
      ),
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        const cloneData = cloneDeep(data);

        const ingredientStatements = cloneData?.ingredientStatements;
        ingredientStatements[getCurrentSlide(carouselRef)] = {
          ...data?.ingredientStatements?.[getCurrentSlide(carouselRef)],
          isRemoved: true,
        };

        const params = {
          ...data,
          ingredientStatements,
        };
        await mutation.mutateAsync(params, {
          onSuccess: async (result) => {
            if (result?.isSuccess) {
              CustomNotification.success('Delete Ingredients successfully.');
              await handleUpdateIXONEShield(ixoneIgnore);
            } else {
              CustomNotification.error(result?.message);
            }
          },
          onError: () => {
            CustomNotification.error('Delete failed!');
          },
        });
      },
    });
  };

  useEffect(() => {
    if (reloadDocumentWhenDelete) {
      queryClient.invalidateQueries({
        queryKey: queryKeyQaSpecIngredients(productId),
      });
    }
  }, [reloadDocumentWhenDelete]);

  const isAllowEditQaSpec = useCheckAllowEdit();

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const totalIngredientStatement = data?.ingredientStatements?.length || 0;

  return (
    <>
      <Row style={{ marginBottom: 12 }} justify='space-between'>
        <Col>
          <Space>
            <CarouselPrevious
              disabled={currentSlide === 0}
              onClick={() => {
                onPrevCarousel();
              }}
            />
            <CarouselNext
              disabled={
                totalIngredientStatement === 0 ||
                currentSlide === totalIngredientStatement - 1
              }
              onClick={() => {
                onNextCarousel();
              }}
            />
            <Typography.Text strong>
              Total: {totalIngredientStatement} Ingredient(s)
            </Typography.Text>
          </Space>
        </Col>
        <Col>
          {!isQASpecLocked ? (
            <Space>
              <AddButton
                onClick={() => {
                  setVisible(true);
                  setIngredientsMode('add');
                }}
                disabled={!isAllowEditQaSpec}
              />
              <EditButton
                onClick={() => {
                  setVisible(true);
                  setIngredientsMode('edit');
                }}
                disabled={totalIngredientStatement === 0 || !isAllowEditQaSpec}
              />
              <IXONECertificationWrapper
                showModal
                type={TYPE_VIEW.INGREDIENTS}
                onConfirm={(ixoneIgnore) => {
                  confirmDelete(ixoneIgnore);
                }}
              >
                {(handleConfirmIXONE) => (
                  <DeleteButton
                    disabled={
                      ingredientStatements?.length === 1 || !isAllowEditQaSpec
                    }
                    onClick={handleConfirmIXONE}
                  />
                )}
              </IXONECertificationWrapper>

              <GroupActions
                overlay={() => (
                  <Space>
                    <Menu>
                      <MenuItem>
                        <MenuItem.AddGroup
                          disabled={!hasManageGroupPermission}
                          onClick={() => onGroupModal(TYPE_GROUPS.addGroup)}
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.PreviewGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.previewGroup)}
                          disabled={selectedProductGroups?.length === 0}
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.AssignGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.assignGroup)}
                          disabled={
                            filteredProductGroups?.length === 0 ||
                            !hasManageGroupPermission
                          }
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.EditGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.editGroup)}
                          disabled={
                            selectedProductGroups?.length === 0 ||
                            !hasManageGroupPermission
                          }
                        />
                      </MenuItem>
                      <MenuItem>
                        <MenuItem.DeleteGroup
                          onClick={() => onGroupModal(TYPE_GROUPS.deleteGroup)}
                          disabled={
                            selectedProductGroups?.length === 0 ||
                            !hasManageGroupPermission
                          }
                        />
                      </MenuItem>
                    </Menu>
                  </Space>
                )}
              >
                <GroupActions.ButtonWrapper />
              </GroupActions>
            </Space>
          ) : null}
        </Col>
      </Row>
      <CarouselSection
        ref={carouselRef}
        fade
        afterChange={(e) => {
          setCurrentSlide(e);
        }}
      >
        {ingredientStatements?.map((item, index) => {
          const title = transformToTextGroupName(item?.groupIds, productGroups);

          const ingredientStatementSnapshot =
            qaIngredientsSnapshot?.ingredientStatements?.[index];

          return (
            <WithLoading loading={isLoading}>
              <GroupPartName title={title}>
                <Row
                  className={classnames('qa-ingredient__input')}
                  justify='space-between'
                  style={{ marginBottom: 20 }}
                >
                  <Col span={12}>
                    <React.Fragment key={index}>
                      <Typography style={{ marginBottom: 8, fontWeight: 600 }}>
                        Ingredient Statement
                      </Typography>

                      <QaIngredientSnapshot
                        supplierModifiedValue={item?.ingredients}
                        snapshotValue={ingredientStatementSnapshot?.ingredients}
                        highlightContent={
                          <HighlightContent
                            label=' Ingredient Statement:'
                            value={ingredientStatementSnapshot?.ingredients?.join(
                              ', '
                            )}
                          />
                        }
                      >
                        <PrimaryFieldSnapshot
                          value={item?.ingredients}
                          autoSize={{ minRows: 3, maxRows: 3 }}
                          maxLength={
                            INGREDIENT_LIMIT_CHARACTERS.ingredientStatement
                          }
                          showCount
                          readOnly
                          variant='textarea'
                        />
                      </QaIngredientSnapshot>
                    </React.Fragment>
                  </Col>
                  <Col flex='50px' />
                  <Col flex={12}>
                    <Typography style={{ marginBottom: 8, fontWeight: 600 }}>
                      Ingredient Claims
                    </Typography>

                    <QaIngredientSnapshot
                      supplierModifiedValue={data?.ingredientClaims}
                      snapshotValue={qaIngredientsSnapshot?.ingredientClaims}
                      highlightContent={
                        <HighlightContent
                          label='Ingredient Claims:'
                          value={qaIngredientsSnapshot?.ingredientClaims}
                        />
                      }
                    >
                      <PrimaryFieldSnapshot
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        maxLength={INGREDIENT_LIMIT_CHARACTERS.ingredientClaims}
                        showCount
                        value={data?.ingredientClaims}
                        readOnly
                        variant='textarea'
                      />
                    </QaIngredientSnapshot>
                  </Col>
                </Row>
                <Row style={{ ...tableStyle }}>
                  <Col xs={24}>
                    <WithQaGridHeader
                      showSaveBtn={false}
                      showCancelBtn={false}
                      showEditBtn={false}
                      title={
                        <Text>
                          LIST INGREDIENTS IN DECLINING ORDER OF PERCENTAGE (
                          <Text italic>
                            include sub-ingredients, components of ingredients,
                            additives, etc.). For ingredients purchased from
                            multiple vendors, list each vendor within its own
                            row.
                          </Text>
                        </Text>
                      }
                    >
                      {(isEdit) => {
                        return (
                          <QaIngredientGrid
                            gridInst={gridInst}
                            data={ingredientData.ingredientList}
                            isEdit={isEdit}
                            ingredientOptions={ingredientOptions}
                            snapshotGridValues={
                              ingredientDataSnapshot?.ingredientList
                            }
                          />
                        );
                      }}
                    </WithQaGridHeader>
                  </Col>
                </Row>
                <Row justify='center'>
                  <Col>
                    <Title strong level={5}>
                      Total: {total}%
                    </Title>
                  </Col>
                </Row>
              </GroupPartName>
            </WithLoading>
          );
        })}
      </CarouselSection>

      {visible && (
        <IngredientForm
          data={{
            ...data,
            currentIngredient: getCurrentIngredient(),
          }}
          visible={visible}
          setVisible={setVisible}
          ingredientsMode={ingredientsMode}
        />
      )}

      {/* Group Data */}
      {visibleModalAddGroup && (
        <QaGroupAddition
          visible={visibleModalAddGroup}
          onCancel={offGroupModal}
          productId={productId}
        />
      )}

      {visibleModalEditGroup && (
        <GroupLayoutProvider>
          <QaGroupEdit
            visible={visibleModalEditGroup}
            onCancel={offGroupModal}
            defaultGroupId={defaultGroup?.groupId}
            productGroups={selectedProductGroups}
            productId={productId}
          />
        </GroupLayoutProvider>
      )}

      {visibleModalAssignGroup && (
        <QaGroupAssign
          visible={visibleModalAssignGroup}
          onCancel={offGroupModal}
          defaultGroupId={filteredProductGroups?.[0]?.groupId}
          productGroups={filteredProductGroups}
          additionalIngredientsGroups={[selectedIngredientsStatement]}
          selectedGroupIds={selectedIngredientGroupIds}
          productId={productId}
        />
      )}

      {visibleModalPreviewGroup && (
        <QaGroupPreview
          visible={visibleModalPreviewGroup}
          onCancel={offGroupModal}
          defaultGroupId={defaultGroup?.groupId}
          productGroups={selectedProductGroups}
          productId={productId}
        />
      )}

      {visibleModalDeleteGroup && (
        <QaGroupDeletion
          visible={visibleModalDeleteGroup}
          onCancel={offGroupModal}
          productGroups={selectedProductGroups}
          productId={productId}
        />
      )}
      {/* End Group Data */}
    </>
  );
};

const QaIngredientSnapshot = (props) => {
  const { isSuccess } = useGetIngredientsSnapshot();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  if (isSuccess && isToggleSnapshot) {
    return <RetailerSnapshot {...props} />;
  }

  return <>{props.children}</>;
};

export default QaSpecIngredient;
