import _ from 'lodash';
import React, { useState } from 'react';
import { Col, Divider, Input, Row, Space, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Form, SectionWrapper } from 'common/components';
import { AddNewItem, UpdateValueButton } from './DrugFactsButton';

const OtherInformation = () => {
  const form = Form.useFormInstance();
  const [mode, setMode] = useState();

  const otherInformation = Form.useWatch('otherInformation', form);

  const onClickAddNew = () => {
    form.resetFields(['otherInformationItem']);
    setMode('add');
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    form
      .validateFields(['otherInformationItem'])
      .then((value) => {
        form.setFieldsValue({
          otherInformation: [
            ...(otherInformation || []),
            {
              otherInformation: value?.otherInformationItem,
            },
          ],
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ minHeight: '19vh' }}>
      <OtherInformationList setMode={setMode} mode={mode} />
      {mode === 'add' && <AddOtherInformationForm />}
      <AddNewItem
        mode={mode}
        onClickAdd={onClickAdd}
        onClickCancel={onClickCancel}
        onClickAddNew={onClickAddNew}
      />
    </div>
  );
};

export default OtherInformation;

const OtherInformationList = ({ setMode, mode }) => {
  const form = Form.useFormInstance();
  const otherInformationList = Form.useWatch('otherInformation', form);

  const onClickUpdate = (index) => {
    const cloneOtherInformationList = _.cloneDeep(otherInformationList);

    form
      .validateFields(['otherInformationItem'])
      .then((value) => {
        cloneOtherInformationList[index] = {
          otherInformation: value?.otherInformationItem,
        };

        form.setFieldsValue({
          otherInformation: cloneOtherInformationList,
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickDelete = (index) => {
    const cloneOtherInformationList = _.cloneDeep(otherInformationList);
    cloneOtherInformationList.splice(index, 1);

    form.setFieldsValue({
      otherInformation: cloneOtherInformationList,
    });
  };

  const onClickEdit = (index, otherInformationItem) => {
    setMode(`edit-${index}`);
    form.setFieldsValue({ otherInformationItem });
  };

  const onCancel = () => {
    setMode('view');
  };
  return (
    <>
      <Form.Item name='otherInformation' hidden />
      {otherInformationList?.map(({ otherInformation }, index) => {
        const isEdit = mode === `edit-${index}`;

        return (
          <div key={index}>
            {isEdit ? (
              <>
                <AddOtherInformationForm />
                <UpdateValueButton
                  onCancel={onCancel}
                  onClickUpdate={() => onClickUpdate(index)}
                />
              </>
            ) : (
              <SectionWrapper
                title={
                  <Space>
                    <EditOutlined
                      onClick={() => onClickEdit(index, otherInformation)}
                    />
                    <DeleteOutlined onClick={() => onClickDelete(index)} />
                  </Space>
                }
                placement='right'
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <Row>
                  <Col span={24}>
                    <Typography.Text ellipsis={{ tooltip: otherInformation }}>
                      ■ {otherInformation}
                    </Typography.Text>
                  </Col>
                </Row>
              </SectionWrapper>
            )}
          </div>
        );
      })}
    </>
  );
};

const AddOtherInformationForm = () => {
  return (
    <>
      <Divider />
      <Row gutter={4}>
        <Col span={24}>
          <Form.Item
            name='otherInformationItem'
            label='Other Information'
            rules={[
              {
                required: true,
                message: 'Other Information is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
