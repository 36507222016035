// Action Types
export const SHOW_LOADING = 'SHOW_LOADING';

export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_MEMBER_LIST_SUCCESS = 'GET_MEMBER_LIST_SUCCESS';
export const GET_MEMBER_LIST_ERROR = 'GET_MEMBER_LIST_ERROR';

export const MEMBER_GRID_COLUMN_INFO = 'MEMBER_GRID_COLUMN_INFO';
export const MEMBER_GRID_COLUMN_INFO_SUCCESS =
  'MEMBER_GRID_COLUMN_INFO_SUCCESS';
export const MEMBER_GRID_COLUMN_INFO_ERROR = 'MEMBER_GRID_COLUMN_INFO_ERROR';
export const GET_MEMBER_SHORT_DETAIL = 'GET_MEMBER_SHORT_DETAIL';
export const GET_MEMBER_SHORT_DETAIL_SUCCESS =
  'GET_MEMBER_SHORT_DETAIL_SUCCESS';
export const GET_MEMBER_SHORT_DETAIL_ERROR = 'GET_MEMBER_SHORT_DETAIL_ERROR';

// Products for members
export const GET_PRODUCTS_FOR_MEMBER = 'GET_PRODUCTS_FOR_MEMBER';
export const GET_PRODUCTS_FOR_MEMBER_SUCCESS =
  'GET_PRODUCTS_FOR_MEMBER_SUCCESS';
export const GET_PRODUCTS_FOR_MEMBER_ERROR = 'GET_PRODUCTS_FOR_MEMBER_ERROR';
export const RESET_PRODUCT_FOR_MEMBER_STATE = 'RESET_PRODUCT_FOR_MEMBER_STATE';

export const GET_COLUMNS_PRODUCTS_FOR_MEMBER =
  'GET_COLUMNS_PRODUCTS_FOR_MEMBER';
export const GET_COLUMNS_PRODUCTS_FOR_MEMBER_SUCCESS =
  'GET_COLUMNS_PRODUCTS_FOR_MEMBER_SUCCESS';
export const GET_COLUMNS_PRODUCTS_FOR_MEMBER_ERROR =
  'GET_COLUMNS_PRODUCTS_FOR_MEMBER_ERROR';

export const SAVE_ID_MEMBER = 'SAVE_ID_MEMBER';

export const GET_PUBLIC_CONTACT_MEMBER = 'GET_PUBLIC_CONTACT_MEMBER';
export const GET_PUBLIC_CONTACT_MEMBER_SUCCESS =
  'GET_PUBLIC_CONTACT_MEMBER_SUCCESS';
export const GET_PUBLIC_CONTACT_MEMBER_ERROR = 'GET_PUBLIC_CONTACT_ERROR';
export const BAN_LIST_GRID_COLUMN_INFO = 'BAN_LIST_GRID_COLUMN_INFO';
export const BAN_LIST_GRID_COLUMN_INFO_SUCCESS =
  'BAN_LIST_GRID_COLUMN_INFO_SUCCESS';
export const BAN_LIST_GRID_COLUMN_INFO_ERROR =
  'BAN_LIST_GRID_COLUMN_INFO_ERROR';
export const REMOVE_BAN_LIST = 'REMOVE_BAN_LIST';
export const REMOVE_BAN_LIST_SUCCESS = 'REMOVE_BAN_LIST_SUCCESS';
export const REMOVE_BAN_LIST_ERROR = 'REMOVE_BAN_LIST_ERROR';

