import { useMutation } from '@tanstack/react-query';

import * as ticketSystemServices from 'services/ticketingSystem';

const updateFollowing = async (params) => {
  const response = await ticketSystemServices.updateFollowTicket(params);

  const { isSuccess } = response;

  if (isSuccess) return Promise.resolve();
  else return Promise.reject();
};

export const useFollowTicket = () => {
  const key = 'follow-ticket';
  const followTicketMutation = useMutation({
    mutationKey: key,
    mutationFn: updateFollowing,
  });

  return { followTicketMutation, key };
};
