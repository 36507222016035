import { Badge, Row } from 'antd';
import classnames from 'classnames';

import ModuleDisplayName from './ModuleDisplayName';

import './EntityProperty.less';
import PropertiesItems from 'pages/reporting/components/PropertiesItems';

const primaryButton = 0;

const EntityProperty = ({
  onClick,
  index,
  snapshot,
  property,
  searchText,
  isDragDisabled,
  selectedTaskIds,
  onToggleSelection,
  onToggleSelectionInGroup,
}) => {
  const handleClickProperty = (event) => {
    if (hasChildFields) {
      onClick && onClick(property, index);
      return;
    }

    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    event.preventDefault();

    if (wasToggleInSelectionGroupKeyUsed(event)) {
      onToggleSelectionInGroup(property.fieldName + '_entity');
      return;
    }

    //* just notice this
    onToggleSelection(property.fieldName + '_entity');
  };

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  const countBadge =
    snapshot.isDragging && selectedTaskIds.length > 1
      ? selectedTaskIds.length
      : null;

  const hasSearchText =
    searchText?.length > 0 &&
    property.displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const hasChildFields =
    property?.childFields?.length > 0 || property?.isParent;
  const paddingLeft = calculatorLevel({ level: property?.level });
  const isSelected = selectedTaskIds.includes(property.fieldName + '_entity');

  return (
    <div>
      <Badge count={countBadge} className='property-item__badge'>
        <Row
          gutter={4}
          align='middle'
          className={classnames({
            'property-item': true,
            'property-item__isDragging': snapshot.isDragging,
            'property-item__isSelected': isSelected,
          })}
          onClick={(event) => handleClickProperty(event)}
          style={{ ...paddingLeft }}
        >
          {hasChildFields ? (
            <ModuleDisplayName
              isActive={property?.isActive}
              searchText={searchText}
              displayName={property.displayName}
            />
          ) : (
            <PropertiesItems
              isDragDisabled={isDragDisabled}
              searchText={searchText}
              displayName={property.displayName}
              dataType={property.dataType}
            />
          )}
        </Row>
      </Badge>
    </div>
  );
};

const calculatorLevel = ({ level }) => {
  const space = level ? level * 20 : 0;
  const withSpacing = {
    paddingLeft: space,
  };
  return withSpacing;
};

export default EntityProperty;
