import { useEffect } from 'react';
import { useAsync } from 'hooks';

import * as nifServices from 'services/nif';

const useGetNifList = (isOpen) => {
  const { run, data, status } = useAsync();

  useEffect(() => {
    if (isOpen && !data) {
      run(nifServices.getNifList());
    }
  }, [isOpen]);

  return {
    data: data?.items,
    loading: status === 'pending',
  };
};

export { useGetNifList };
