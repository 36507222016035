import React from 'react';
import { sortBy } from 'lodash';

import {
  Row,
  Col,
  AutoComplete,
  Input,
  InputNumber,
  Select,
  Tooltip,
} from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { Form, SectionWrapper, WrapperSelect } from 'common/components';

import { useGetProductEnums } from 'hooks';
import { SUPPLEMENT_SERVING_UMO } from '../../constants';

import Messages from 'i18n/messages/product';
import { SCREEN_WIDTH, useWindowSize } from 'hooks/windowSize';
import { DEFAULT_FOOTER_NOTE_SUPPLEMENT } from '../../DailyValueConstants';

const FORM_ITEM_GUTTER = 8;

const SupplementForm = () => {
  const intl = useIntl();
  const form = Form.useFormInstance();

  const { productEnums } = useGetProductEnums();

  const [screenWidth, _] = useWindowSize();

  const labelTypeOptions = [
    {
      label: intl.formatMessage(Messages.supplementLabelTypeStandard),
      value: 'US_FDA_NFP_2020_STANDARD',
    },
  ];

  const getServingUmoOptions = () => {
    const productBasicUOM = productEnums?.find(
      (type) => type.enumName === 'BasicUomEnum'
    );

    const enums = productBasicUOM.enumProperties
      .filter((type) => ['Mass', 'Volume'].includes(type.enumGroup))
      ?.map((item, index) => ({
        label: item.enumDisplayName,
        value: item.enumDisplayName,
      }));

    return sortBy([...enums, ...SUPPLEMENT_SERVING_UMO], 'label');
  };

  const handleFooterNote = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      fdaLabelFootNote: DEFAULT_FOOTER_NOTE_SUPPLEMENT,
    });
  };

  const servingUmoOptions = getServingUmoOptions();

  const languageCodeEnum = productEnums?.filter(
    (type) => type.enumName === 'LanguageCodeEnum'
  );
  
  const servingPerContainerDescriptorEnum = productEnums?.find(
    (type) => type.enumName === 'ServingPerContainerDescriptorEnum'
  );

  return (
    <div style={{ padding: '8px 0px' }}>
      <Form.Item
        name='nutrientFormatType'
        label={intl.formatMessage(Messages.supplementLabelTypeLabel)}
      >
        <Select
          options={labelTypeOptions}
          getPopupContainer={(triggerNode) => {
            return triggerNode;
          }}
        />
      </Form.Item>

      <SectionWrapper
        title={intl.formatMessage(Messages.supplementServingSize)}
        style={{ marginBottom: 10 }}
      >
        <Row wrap={false} gutter={FORM_ITEM_GUTTER}>
          <Col flex={1}>
            <Form.Item
              name='servingSize'
              label={intl.formatMessage(
                Messages.supplementServingSizeValueLabel
              )}
              rules={[
                {
                  required: true,
                  message: 'Value is required',
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name='servingSizeMeasurement'
              label={intl.formatMessage(Messages.supplementServingSizeUmoLabel)}
              rules={[
                {
                  required: true,
                  message: 'UOM is required',
                },
              ]}
            >
              <AutoComplete
                getPopupContainer={(triggerNode) => {
                  return triggerNode;
                }}
                options={servingUmoOptions}
                filterOption={(inputValue, option) => {
                  return (
                    option?.label
                      ?.toLowerCase()
                      ?.indexOf(inputValue?.toLowerCase()) > -1
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='servingSizeDescription'
          label={intl.formatMessage(
            Messages.supplementServingSizeDescriptionLabel
          )}
        >
          <Input />
        </Form.Item>
      </SectionWrapper>

      <Row gutter={FORM_ITEM_GUTTER}>
        <Col span={12}>
          <Form.Item
            label={
              screenWidth > SCREEN_WIDTH.EXTRA_LARGE
                ? 'Servings Per Container Descriptor'
                : 'S/C Descriptor'
            }
            name='servingPerContainerDescriptor'
          >
            <WrapperSelect
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            >
              {sortBy(servingPerContainerDescriptorEnum?.enumProperties, [
                'enumDisplayName',
                'enumGroup',
              ]).map((item, index) => (
                <Select.Option value={item?.enumDisplayName} key={index}>
                  {item?.enumDisplayName}
                </Select.Option>
              ))}
            </WrapperSelect>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='numberOfServingsPerContainer'
            label={
              screenWidth > SCREEN_WIDTH.EXTRA_LARGE
                ? 'Servings Per Container'
                : 'S/C'
            }
            rules={[
              {
                required: true,
                message: 'Serving Per Container is required',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (Number(value) > 1000) {
                    return Promise.reject([
                      'Servings Per Container should be smaller than 1000.',
                    ]);
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <InputNumber
              min={0}
              max={1000}
              formatter={(value) => {
                if (+value > 0)
                  return (+value)?.toFixed(4)?.replace(/(\.0+|0+)$/, '');
                return value;
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap={false} gutter={FORM_ITEM_GUTTER}>
        <Col flex={1}>
          <Form.Item
            name='calories'
            label={intl.formatMessage(Messages.supplementCalories)}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item shouldUpdate>
            {(formInst) => {
              const calories = formInst.getFieldValue('calories');

              return (
                <Form.Item
                  name='caloriesFromFat'
                  label={intl.formatMessage(Messages.supplementCaloriesFat)}
                >
                  <InputNumber min={0} disabled={!calories} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name='fdaLabelFootNote'
        label={
          <span>
            Footer Note
            <Tooltip title={intl.formatMessage(Messages.supplementFooterNote)}>
              <FileTextOutlined
                onClick={handleFooterNote}
                style={{ marginLeft: '5px' }}
              />
            </Tooltip>
          </span>
        }
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item
        name='otherIngredients'
        label={intl.formatMessage(Messages.supplementOtherIngredients)}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name='languageCode' label='Language Code'>
        <WrapperSelect
          showSearch
          filterOption={(input, option) =>
            option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          optionFilterProp='children'
          getPopupContainer={(triggerNode) => {
            return triggerNode;
          }}
        >
          {sortBy(languageCodeEnum?.[0]?.enumProperties, [
            'enumDisplayName',
            'enumGroup',
          ])?.map((item, index) => (
            <Select.Option value={item?.enumCode} key={index}>
              {`${item.enumDescription} - (${item.enumCode.toUpperCase()})`}
            </Select.Option>
          ))}
        </WrapperSelect>
      </Form.Item>
    </div>
  );
};

export default SupplementForm;
