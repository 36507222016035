export const GET_CATEGORY_TREE_VIEW =
  '/api/categories/get-categories-tree-view';

export const CREATE_CATEGORY = '/api/categories/create-category';

export const EDIT_CATEGORY = '/api/categories/edit-category';

export const REMOVE_CATEGORY = '/api/categories/remove-category';

export const VALIDATE_UPLOAD_CATEGORY =
  '/api/categories/validate-upload-categories';

export const DOWNLOAD_CATEGORIES_FORM =
  '/api/categories/download-categories-form';

export const ASSIGN_CATEGORY_TO_PRODUCTS = '/api/categories/assign-products';

export const SAVE_UPLOAD_CATEGORIES = '/api/categories/save-upload-categories';

export const VALIDATE_BULK_REASSIGN_PRODUCTS =
  '/api/categories/validate-bulk-reassign-products';

export const SAVE_BULK_REASSIGN_PRODUCTS =
  '/api/categories/save-bulk-reassign-products';

export const GET_PRODUCT_CATEGORY = '/api/categories/get-product-categories';
