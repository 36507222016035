import { v4 as uuidv4 } from 'uuid';

import { IconFolderAsset, NodeTree } from './FolderTree';

import folderIcon from 'assets/folder/folder.png';

import { entityTypeIcon } from 'utils/entityTypeIcon';
import { sortFolderToTop } from 'pages/folders/shared/utils';

import { getItemsOfFolder } from 'services/new-folder';
import { getFolderShortDetail } from 'services/folder';

export const filterParams = {
  folder: [
    {
      fieldName: 'type',
      filterType: 'Equal',
      value: 'folder', //
    },
  ],
  all: [],
};

const getUuidKey = () => uuidv4();

export const handleOpenDetailItem = (type, id) => {
  const openInNewTab = (url) => {
    window.open(url).focus();
  };

  switch (type.toLowerCase()) {
    case 'folder':
      return openInNewTab(`/folder/${id}`);

    case 'product':
      return openInNewTab(`/product/${id}`);

    case 'asset':
      return openInNewTab(`/asset/${id}`);

    case 'member':
      return openInNewTab(`/company/${id}`);

    case 'reporting':
      return openInNewTab(`/reporting/${id}`);

    case 'query':
      return openInNewTab(`/execute-query/${id}`);

    default:
      return null;
  }
};

export const getNodeTreeData = ({ node, folderParam = {}, fileParam = {} }) => {
  if (node.type.toLowerCase() === 'folder') {
    const { renderFolder = () => {} } = folderParam;
    return {
      key: getUuidKey(),
      title: <NodeTree title={node.folderName ?? node.description ?? ''} />,
      icon: <IconFolderAsset src={folderIcon} />,
      data: node,
      selectable: true,
      checkable: false,
      ...renderFolder(node),
    };
  }

  const { renderFile = () => {} } = fileParam;
  return {
    key: getUuidKey(),
    title: <NodeTree title={node.folderName ?? node.description ?? ''} />,
    icon: entityTypeIcon(node.type.toLowerCase()),
    data: node,
    selectable: true,
    checkable: false,
    isLeaf: true,
    ...renderFile(node),
  };
};

export const updateTreeData = (list, key, children) => {
  return list.map((node) => {
    if (node.key === key) {
      return { ...node, children };
    }

    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }

    return node;
  });
};

export const mappingFolderToTreeData = (folders, folderParam, fileParam) => {
  let treeData = [];

  folders.forEach((item) => {
    if (item.type.toLowerCase() === 'folder') {
      let node = getNodeTreeData({ node: item, folderParam, fileParam });
      treeData.push(node);
    } else {
      let node = getNodeTreeData({ node: item, folderParam, fileParam });
      treeData.push(node);
    }
  });

  return treeData;
};

export const fetchChildNodeFolder = async (params) => {
  const response = await getItemsOfFolder(params);
  if (response?.isSuccess) {
    if (response?.data?.gridData) {
      return response?.data?.gridData;
    }
  }
  return null;
};

const getExpandedNodeData = async ({
  folderId,
  folderParam,
  filterType = 'folder',
}) => {
  const items = await fetchChildNodeFolder({
    folderId,
    pageIndex: 1, // change later
    pageSize: 9999, // change later
    filters: filterParams[filterType],
    sort: [
      {
        fieldName: 'id',
        isAscending: false,
      },
    ], // new first,
  });
  if (items) {
    const sortedItems = items.sort(sortFolderToTop);
    return mappingFolderToTreeData(sortedItems, folderParam);
  }

  return [];
};

export const generateListChildNode = async ({
  childNode,
  childIds,
  folderParam,
  filterType,
}) => {
  let result = [];

  for (const node of childNode) {
    const { id } = node.data;

    if (childIds.includes(parseInt(id))) {
      const respContent = await getExpandedNodeData({
        folderId: id,
        folderParam,
        filterType,
      });

      const newNode = {
        ...node,
        children: await generateListChildNode({
          childNode: respContent,
          childIds: childIds.slice(1),
          folderParam,
          filterType,
        }),
      };
      result.push(newNode);
    } else {
      result.push(node);
    }
  }

  return result;
};

export const buildHierarchyTreeFolder = async ({
  listIds,
  folderParam,
  filterType,
}) => {
  const [rootFolderId, ...childIds] = listIds;

  /* Need to check error in here, sometimes one of two responses is fail.
     Show error and back to grid folder??
     Use Promise.allSettle instead of using Promise.all to don't crash system
   */
  const [rootFolderDetailResp, rootContentFolderResp] = await Promise.all([
    getFolderShortDetail({
      id: rootFolderId,
      IsLoadContent: false,
    }),
    getExpandedNodeData({ folderId: rootFolderId, folderParam, filterType }),
  ]);

  const hierarchyNodes = await generateListChildNode({
    childNode: rootContentFolderResp,
    childIds: childIds.map((id) => parseInt(id)),
    folderParam,
    filterType,
  });

  let parentNode = {
    ...getNodeTreeData({
      node: { ...rootFolderDetailResp?.data, type: 'folder' }, // folder doesn't have type value !!!
      folderParam,
    }),
    children: hierarchyNodes,
  };

  return [parentNode];
};

export const getHierarchyExpandedKeys = (children, listIds) => {
  let result = [];

  for (const child of children) {
    if (listIds.includes(parseInt(child.data.id))) {
      result.push(child.key);
    }
    if (child.children) {
      result = result.concat(getHierarchyExpandedKeys(child.children, listIds));
    }
  }

  return result;
};

export const findNodeTreeById = (treeData = [], id) => {
  for (let i = 0; i < treeData.length; i++) {
    if (parseInt(treeData[i]?.data?.id) === parseInt(id)) {
      return treeData[i];
    }
    if (treeData[i]?.children) {
      const result = findNodeTreeById(treeData[i].children, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
};
