import { CustomNotification } from 'common/components';
import { useState, useEffect } from 'react';

import { getInteropListItems } from 'services/interoperability';

import { mappingInteropItemsToTreeData } from './utils';

export const useGetTreeData = ({ id, multipleImport, storageServiceType }) => {
  const [treeData, setTreeData] = useState([]);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    if (id) {
      setStatus('loading');

      getInteropListItems({
        id,
        pathInfo: null,
        storageServiceType,
        getFile: true,
        getFolder: true,
      })
        .then((res) => {
          if (res.isSuccess) {
            setStatus('success');

            const mappedTreeData = mappingInteropItemsToTreeData(
              res?.data?.interopItems,
              multipleImport
            );
            setTreeData(mappedTreeData);
          } else {
            setStatus('error');
            setTreeData([]);
            CustomNotification.error(res.message ?? 'Something went wrong');
          }
        })
        .catch((err) => {
          setStatus('error');
          setTreeData([]);
          CustomNotification.error(err.message ?? 'Something went wrong');
        });
    }
  }, [id, multipleImport, storageServiceType]);

  const handleSetTreeData = (treeData) => setTreeData(treeData);

  return {
    treeData,
    handleSetTreeData,
    setTreeData,
    isExpanding: status === 'loading',
  };
};
