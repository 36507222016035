import React from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ClockCircleOutlined } from '@ant-design/icons';

import { isEmpty } from 'lodash';

import RibbonButton from 'common/components/button/RibbonButton';
import Messages from 'i18n/messages/home';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import { useGetProductFullView } from 'hooks';
import useToggleActiveRibbon from '../product-full/hook';

const HistoryControl = () => {
  const { id: productId } = useParams();

  const selectedItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const { productFull: currentProduct } = useGetProductFullView({ productId });

  const disableButton = selectedItems?.length !== 1 && isEmpty(currentProduct);

  const id = selectedItems?.[0]?.id || currentProduct?.productId;

  const history = useHistory();

  const checkActiveContent = () => {
    const path = history?.location?.pathname;
    const regex = /\/product\/versions\/\d+$/;
    return regex.test(path);
  };

  const isPathActive = checkActiveContent();
  const isActive = useToggleActiveRibbon(isPathActive);

  const handleClick = () => {
    if (id !== undefined) {
      if (isActive) {
        history.push(`/product/${id}`);
      } else {
        history.push(`/product/versions/${id}`);
      }
    }
  };

  return (
    <RibbonButton
      icon={<ClockCircleOutlined />}
      label={Messages.versions}
      onClick={handleClick}
      disabled={disableButton}
      toggle={isPathActive}
    />
  );
};

HistoryControl.propTypes = {};

export default HistoryControl;
