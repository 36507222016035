import {
  handleCallApiReducer,
  handleCallApiReducerForLoadMore,
} from 'utils/api';

export const mapToCommunicationDetail = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'communicationDetail');
};

export const mapToCommunicationList = (action, _draft, state) => {
  handleCallApiReducerForLoadMore(action, _draft, state, 'communicationList');
};
