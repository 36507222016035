

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/vi';

import { LOCALE_DEFAULT } from 'static/Localization';
const enTranslationMessages = require('./translations/en.json');
const viTranslationMessages = require('./translations/vi.json');


export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== LOCALE_DEFAULT
      ? formatTranslationMessages(LOCALE_DEFAULT, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== LOCALE_DEFAULT
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  vi: formatTranslationMessages('vi', viTranslationMessages),
};
