import React from 'react';

import { Modal, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './StyledModal.less';

const { Text } = Typography;

function StyledModal(props) {
  const { wrapClassName, isLoading, loadingContent, ...rest } = props;

  const mapToLoadingContent = () => {
    if (!loadingContent) return {};

    const RenderLoading = (
      <React.Fragment>
        <LoadingOutlined />
        <Text style={{ color: 'white' }}>{loadingContent?.loadingText}</Text>
      </React.Fragment>
    );

    const RenderIdle = loadingContent?.originalText;

    return isLoading
      ? {
          okText: RenderLoading,
        }
      : {
          okText: RenderIdle,
        };
  };

  return (
    <Modal
      wrapClassName={`styled-modal ${wrapClassName ? wrapClassName : ''}`}
      style={isLoading ? { pointerEvents: 'none !important' } : {}}
      {...rest}
      {...mapToLoadingContent()}
    >
      {props?.children && props.children}
    </Modal>
  );
}

export default StyledModal;
