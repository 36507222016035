import React from 'react';

import { Divider } from 'antd';
import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import OpenWorkflow from './components/sections/workflow-maintain/OpenWorkflow';
import CreateWorkflow from './components/sections/workflow-maintain/CreateWorkflow';
import DeleteWorkflow from './components/sections/workflow-maintain/DeleteWorkflow';
import CloneWorkflow from './components/sections/workflow-maintain/CloneWorkflow';
import { ViewLayout, AdvanceStack } from './components';

const WorkflowMaintainRibbon = (props) => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection disabled />
      <ViewLayout disabled />
      <RibbonDivider />
      <OpenWorkflow />
      <CreateWorkflow />
      <DeleteWorkflow />
      <CloneWorkflow />
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default WorkflowMaintainRibbon;
