import React, { useState } from 'react';
import './style.less';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import ScheduleInformation from './ScheduleInformation';
import ScheduleConfiguration from './ScheduleConfiguration';
import { Divider, Button } from 'antd';
import PropTypes from 'prop-types';

const SchedulePanel = (props) => {
  const {
    shortDetail,
    ownerFolder,
    onClosePanel,
    downloadConfig,
    onBackDownloadSetup,
    intl,
  } = props;
  const [loading, setLoading] = useState(false);

  return (
    <div className='schedule-panel'>
      <div className='schedule-panel__button'>
        <Button
          type='link'
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            onClosePanel && onClosePanel();
            document.getElementById('schedule-configuration__reset-button') &&
              document
                .getElementById('schedule-configuration__reset-button')
                .click();
            onBackDownloadSetup && onBackDownloadSetup();
          }}
        >
          {intl.formatMessage({
            id: 'Taco.folder.download.backDownload',
          })}
        </Button>
        <Button
          form='schedule-download-form'
          htmlType='submit'
          type='link'
          loading={loading}
          // onClick={() => {
          //   document
          //     .getElementById('schedule-configuration__submit-button')
          //     .click();
          // }}>
        >
          {intl.formatMessage({
            id: 'Taco.folder.download.run',
          })}
          <ArrowRightOutlined style={{ marginLeft: 8 }} />
        </Button>
      </div>
      <ScheduleInformation
        scheduleSetting={shortDetail}
        ownerFolder={ownerFolder}
      />
      <Divider className='schedule-panel__divider' />
      <ScheduleConfiguration
        onSetLoading={setLoading}
        downloadConfig={downloadConfig}
        folderId={shortDetail && shortDetail.id}
      />
    </div>
  );
};

SchedulePanel.propTypes = {
  shortDetail: PropTypes.object,
  ownerFolder: PropTypes.bool,
  onClosePanel: PropTypes.func,
  downloadConfig: PropTypes.object,
};

export default injectIntl(SchedulePanel);
