import { useState, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import * as _ from 'lodash';

export const useAggridSelection = ({
  gridId,
  gridView,
  onCallbackSelectedRowFromOutside,
}) => {
  const [itemsSelectionLog, setItemsSelectionLog] = useState([]);
  const [pageItemsSelectionLog, setPageItemsSelectionLog] = useState([]);
  const [itemsSelectionDetailLog, setItemsSelectionDetailLog] = useState([]);
  const [pageItemsSelectionDetailLog, setPageItemsSelectionDetailLog] =
    useState([]);

  const refLastSelectedRowData = useRef();

  const setRefLastSelectedRowData = (value) => {
    refLastSelectedRowData.current = value;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (gridView) {
      dispatch(
        actionsGridView.updateDetailCurrentItemsSelection(
          _.cloneDeep(itemsSelectionDetailLog)
        )
      );
      dispatch(actionsGridView.updateActiveGrid(gridId));
    }

    onCallbackSelectedRowFromOutside(refLastSelectedRowData.current);
  }, [
    dispatch,
    itemsSelectionDetailLog,
    itemsSelectionDetailLog.length,
    gridId,
  ]);

  useEffect(() => {
    if (gridView) {
      dispatch(
        actionsGridView.updateItemsSelection(_.cloneDeep(itemsSelectionLog))
      );

      dispatch(
        actionsGridView.updateItemsSelection(_.clone(itemsSelectionLog))
      );
    }
  }, [dispatch, itemsSelectionLog, itemsSelectionLog.length, gridView]);

  return {
    itemsSelectionLog,
    pageItemsSelectionLog,
    itemsSelectionDetailLog,
    pageItemsSelectionDetailLog,
    setItemsSelectionLog,
    setPageItemsSelectionLog,
    setItemsSelectionDetailLog,
    setPageItemsSelectionDetailLog,
    setRefLastSelectedRowData,
  };
};
