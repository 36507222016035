import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CustomNotification } from 'common/components';
import AgGrid from 'common/components/ag-grid/AgGrid';
import CompanyLocationForm from 'pages/company-profile/components/tabs/contacts/companyLocation/CompanyLocationForm';

import { useMemberLocation } from 'hooks';
import { useSaveManufacturingLocation } from './hooks';

import * as endpoints from 'services/qaSpec/endpoints';

const QaSpecFacilityGrid = (props) => {
  const { id: productId } = useParams();

  const { ownerId, isAddLocation, setIsAddLocation, setRowSelected } = props;

  const { locationColumns } = useMemberLocation();

  const saveLocation = useSaveManufacturingLocation();

  const [gridApi, setGridApi] = useState(null);

  const onSetSelectedRows = (selectedNodesDetail) => {
    setRowSelected(selectedNodesDetail);
  };

  const onAddFacility = async ({ formValue, setIsLoading }) => {
    try {
      setIsLoading(true);

      const params = {
        ...formValue,
        productId: productId,
      };

      await saveLocation.mutateAsync(params);

      //* reload grid
      gridApi?.rivirPurgeServerSideCache();

      CustomNotification.success('Save Manufacturing Facility successfully');

      setIsAddLocation(false);
    } catch (error) {
      CustomNotification.error('Cannot save Manufacturing Facility');
    }

    setIsLoading(false);
  };

  return (
    <div style={{ width: '100%', height: 'calc(100% - 40px)' }}>
      <AgGrid
        columnDefs={locationColumns}
        urlGrid={endpoints.GET_QA_SPEC_COMPANY_LOCATION}
        responseParams='data'
        paramsGrid={{
          productId: productId,
        }}
        styleGrid={{ width: '100%', height: '100%' }}
        getGridApi={(gridApi) => setGridApi(gridApi)}
        isShowItemSelectionLog={false}
        onHandleSelectedRowCustom={onSetSelectedRows}
        rowClassRules={{ 'ag-hidden-row': 'true' }}
      />

      <CompanyLocationForm
        type={'ProductionFacility'}
        isAddNew={isAddLocation}
        setIsOpen={setIsAddLocation}
        gridApi={gridApi}
        memberId={ownerId}
        setIsAddNew={setIsAddLocation}
        onSubmit={onAddFacility}
      />
    </div>
  );
};

export default QaSpecFacilityGrid;
