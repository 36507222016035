import React from 'react';

import { Menu } from 'antd';
import {
  UserOutlined,
  CommentOutlined,
  FontSizeOutlined,
  CodeSandboxOutlined,
  EnvironmentOutlined,
  InfoOutlined,
  HeartOutlined,
} from '@ant-design/icons';
import { useEditor } from '@craftjs/core';

import EmailEditorGreeting from './EmailEditorGreeting';
import EmailEditorProductRequested from './EmailEditorProductRequested';
import EmailEditorSenderInfo from './EmailEditorSenderInfo';
import EmailEditorShippingTo from './EmailEditorShippingTo';
import EmailEditorSigningOff from './EmailEditorSigningOff';
import EmailEditorAdditionalInfo from './EmailEditorAdditionalInfo';
import EmailEditorContent from './EmailEditorContent';

import { useEmailEditorContext } from '../../controllers/context';
import { updateDynamicContentByTag } from 'utils/email-editor';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const EmailEditorSectionMenu = (props) => {
  const intl = useIntl();
  const { setDynamicContent } = useEmailEditorContext();
  const { nodes, actions, query } = useEditor((state) => ({
    nodes: state.nodes,
    enabled: state.options.enabled,
  }));

  const { parentId, nodeId, className } = props;

  const sectionList = [
    {
      key: 'senderInfo',
      comp: EmailEditorSenderInfo,
      icon: <UserOutlined />,
    },
    {
      key: 'greeting',
      comp: EmailEditorGreeting,
      icon: <CommentOutlined />,
      tags: ['recipientName'],
    },
    {
      key: 'content',
      comp: EmailEditorContent,
      icon: <FontSizeOutlined />,
      tags: ['projectName', 'numberSample'],
    },
    {
      key: 'productRequest',
      comp: EmailEditorProductRequested,
      icon: <CodeSandboxOutlined />,
    },
    {
      key: 'shippingTo',
      comp: EmailEditorShippingTo,
      icon: <EnvironmentOutlined />,
      tags: [
        'shippingName',
        'shippingCompany',
        'shippingAddress1',
        'shippingAddress2',
      ],
    },
    {
      key: 'additionalInfo',
      comp: EmailEditorAdditionalInfo,
      icon: <InfoOutlined />,
      tags: ['additionalInfo'],
    },
    {
      key: 'signingOff',
      comp: EmailEditorSigningOff,
      icon: <HeartOutlined />,
    },
  ];

  const onClickAddMenu = ({ key }) => {
    addNewSection(key);
  };

  const addNewSection = (key) => {
    const currentSectionIndex =
      parentId === 'ROOT' && !nodeId
        ? 1
        : nodes[parentId]?.data?.nodes?.findIndex((node) => node === nodeId);

    const section = sectionList.find((section) => section.key === key);
    const comp = section?.comp;

    const freshNode = {
      data: {
        type: comp,
        custom: { tags: section?.tags },
      },
    };

    const node = query.parseFreshNode(freshNode).toNode();
    actions.add(node, parentId, currentSectionIndex + 1);
    setTimeout(() => {
      actions.selectNode(node.id);
    }, 100);

    showDynamicContent(section);
  };

  const showDynamicContent = (section) => {
    if (!section?.tags) return;

    setDynamicContent((prevState) => {
      return updateDynamicContentByTag({
        prevState,
        tags: section?.tags,
        field: 'show',
      });
    });
  };

  const checkDisabled = (section) => {
    return !!Object.values(nodes).find(
      (node) => node.data.type === section.comp
    );
  };

  return (
    <Menu className={className} onClick={onClickAddMenu} selectedKeys={[]}>
      {sectionList.map((section) => {
        return (
          <Menu.Item
            key={section.key}
            icon={section.icon}
            disabled={checkDisabled(section)}
          >
            {intl.formatMessage(Messages[section.key])}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default EmailEditorSectionMenu;
