import React from 'react';

import {
  Row,
  Col,
  Input,
  InputNumber,
  AutoComplete,
  Select,
  Checkbox,
} from 'antd';
import { Form, WrapperSelect } from 'common/components';

import { useGetSupplementPartOf } from '../../hook/useGetSupplementPartOf';

import {
  SUPPLEMENT_NUTRIENTS,
  DAILY_SYMBOL_SUGGESTIONS,
  SUPPLEMENT_NUTRIENT_UOM,
} from '../../constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

const formItemStyle = { marginBottom: 4 };

const SupplementNutrientForm = (props) => {
  const intl = useIntl();

  const { children, fieldName, namePath = [] } = props;

  const { partOfList, currentNutrientData } = useGetSupplementPartOf({
    fieldName,
    namePath,
  });

  const getNutrientSuggestions = () => {
    return SUPPLEMENT_NUTRIENTS.reduce((accumulator, nutrient) => {
      if (nutrient === 'Calories') return accumulator;
      if (nutrient === 'Calories from Saturated Fat') return accumulator;

      return [...accumulator, { label: nutrient, value: nutrient }];
    }, []);
  };

  const nutrientSuggestions = getNutrientSuggestions();

  return (
    <div className='supplement-form__add-nutrient-form'>
      <Form.Item
        name={[...namePath, 'nutritionName']}
        style={formItemStyle}
        rules={[
          {
            required: true,
            message: 'Name is required',
          },
        ]}
      >
        <AutoComplete
          options={nutrientSuggestions}
          filterOption={(inputValue, option) => {
            return (
              option?.label?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) >
              -1
            );
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode;
          }}
        >
          <Input.TextArea
            placeholder={intl.formatMessage(Messages.supplementNutrientName)}
          />
        </AutoComplete>
      </Form.Item>
      <Form.Item
        name={[...namePath, 'ingredientsPartOf']}
        style={formItemStyle}
      >
        <WrapperSelect
          placeholder={intl.formatMessage(Messages.supplementPartOf)}
          getPopupContainer={(triggerNode) => {
            return triggerNode;
          }}
        >
          {partOfList.map((partOfItem) => {
            return (
              <Select.Option value={partOfItem}>{partOfItem}</Select.Option>
            );
          })}
        </WrapperSelect>
      </Form.Item>

      <Form.Item name={[...namePath, 'descriptor']} style={formItemStyle}>
        <Input.TextArea
          placeholder={intl.formatMessage(Messages.supplementDescriptor)}
        />
      </Form.Item>

      <Row wrap={false}>
        <Col span={14}>
          <Form.Item
            name={[...namePath, 'quantity']}
            style={formItemStyle}
            rules={[
              {
                required: true,
                message: 'Quantity is required',
              },
            ]}
          >
            <InputNumber
              min={0}
              placeholder={intl.formatMessage(Messages.supplementQuantity)}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name={[...namePath, 'quantityUom']} style={formItemStyle}>
            <Select
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            >
              {SUPPLEMENT_NUTRIENT_UOM.map((UNIT) => {
                return (
                  <Select.Option key={UNIT} value={UNIT}>
                    {UNIT}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name={[...namePath, 'quantityDescription']}
        style={formItemStyle}
      >
        <Input
          placeholder={intl.formatMessage(
            Messages.supplementQuantityDescription
          )}
        />
      </Form.Item>

      <Row>
        <Col span={14}>
          <Form.Item
            name={[...namePath, 'dailyValue']}
            style={formItemStyle}
            rules={[
              {
                required: !currentNutrientData?.ingredientsPartOf,
                message: 'Daily Value is required',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              placeholder={intl.formatMessage(Messages.supplementDailyValue)}
              addonAfter={'%'}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name={[...namePath, 'dailySymbol']} style={formItemStyle}>
            <AutoComplete
              allowClear
              options={DAILY_SYMBOL_SUGGESTIONS}
              placeholder={intl.formatMessage(Messages.supplementDailySymbol)}
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={[...namePath, 'italicNutrient']}
            valuePropName='checked'
            style={formItemStyle}
          >
            <Checkbox>
              {intl.formatMessage(Messages.supplementItalicName)}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name={[...namePath, 'italicDescriptor']}
            valuePropName='checked'
            style={formItemStyle}
          >
            <Checkbox>
              {intl.formatMessage(Messages.supplementItalicDescriptor)}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {children && children}
    </div>
  );
};

export default SupplementNutrientForm;
