import React, { useMemo, useState, useEffect } from 'react';
import { Dropdown, Input, Menu, Row, Empty } from 'antd';
import {
  CheckSquareOutlined,
  BorderOutlined,
  FilterOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons';
// redux
import { useDispatch, useSelector } from 'react-redux';
import userSelectors from 'redux/user/selectors';
// common
import { RibbonButton } from 'common/components';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
// hooks
import { useFilterGrid } from 'hooks/useFilterGrid';
import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';
import { useGetRolesListByMember } from 'hooks/roles/useRoles';

const FilterUserRole = () => {
  const dispatch = useDispatch();
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());

  const { data: roleList } = useGetRolesListByMember({ memberId });

  const [roleListSearch, setRoleListSearch] = useState();
  const [visible, setVisible] = useState();

  const { gridFilters, updateGridFilter } = useFilterGrid({
    entityType: 'userMaintenance',
  });

  const filterRoleList = useMemo(() => {
    return roleList?.map((val) => ({
      value: val?.displayName,
      label: val?.displayName,
      icon: <SecurityScanOutlined />,
    }));
  }, [roleList]);

  const { label, icon, toggle } = useDisplayFilterButton({
    defaultButton: {
      label: 'Roles',
      icon: <SecurityScanOutlined />,
    },
    filterOptions: filterRoleList,
    filters: gridFilters.filter((filterItem) => {
      return filterRoleList.find(
        (FILTER_ITEM) => FILTER_ITEM.value === filterItem
      );
    }),
  });

  useEffect(() => {
    setRoleListSearch(roleList);
  }, [roleList]);

  const handleFilter = (filter) => {
    dispatch(actionsGridView.resetPageCurrent());

    if (gridFilters.indexOf(filter) > -1) {
      updateGridFilter(gridFilters.filter((val) => val !== filter));
    } else {
      let newFilter = [...gridFilters];

      newFilter.push(filter);

      updateGridFilter(newFilter);
    }
  };

  const handleSearch = (value) => {
    setRoleListSearch(
      roleList.filter((r) =>
        r?.displayName?.toLocaleLowerCase().includes(value?.toLocaleLowerCase())
      )
    );
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const filterMenu = (
    <Menu>
      <Row style={{ padding: '5px 6px' }}>
        <Input.Search
          placeholder='Search roles'
          size='large'
          onSearch={handleSearch}
        />
      </Row>
      {roleListSearch && roleListSearch?.length > 0 ? (
        roleListSearch.map((val, index) => (
          <Menu.Item
            style={{
              backgroundColor: 'inherit',
            }}
            key={`${val?.id}-${index}}`}
          >
            <RibbonButton
              size='small'
              label={val?.displayName}
              icon={
                gridFilters?.includes(val?.displayName) ? (
                  <CheckSquareOutlined />
                ) : (
                  <BorderOutlined />
                )
              }
              toggle={gridFilters?.includes(val?.displayName)}
              onClick={() => handleFilter(val?.displayName)}
            />
          </Menu.Item>
        ))
      ) : (
        <Empty />
      )}
    </Menu>
  );
  return (
    <Dropdown
      overlay={filterMenu}
      arrow
      overlayClassName='scroller'
      overlayStyle={{ maxHeight: '50vh' }}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <RibbonButton
        icon={icon}
        label={label}
        toggle={toggle}
        id='filterUserRoles'
      />
    </Dropdown>
  );
};

export default FilterUserRole;
