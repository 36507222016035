export const GET_QAS_REQUIRE_FIELD_GRID =
  '/api/qas/get-qas-required-fields-grid';
export const GET_QAS_REQUIRE_FIELD_DISTINCT =
  '/api/qas/get-qas-required-fields-grid/distinct';
export const CREATE_QAS_REQUIRE_FIELD_GRID =
  '/api/qas/create-qas-required-fields';
export const EDIT_QAS_REQUIRE_FIELD_GRID = '/api/qas/edit-qas-required-fields';
export const DELETE_QAS_REQUIRE_FIELD_GRID =
  '/api/qas/delete-qas-required-fields';
export const GET_QAS_REQUIRE_FIELD = '/api/qas/get-qas-required-fields';
export const GET_ALL_TERGET_MEMBERS = '/api/qas/get-all-target-members';
export const GET_ALL_PRODUCT_FIELDS = '/api/qas/get-all-product-fields';
export const GET_QA_SPEC_REQUIRED_FIELDS_FOR_PRODUCT =
  '/api/qas/get-required-field-for-product';
