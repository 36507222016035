/**
 * Asset Full View message
 *
 * This contains all the text for the Asset full view component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.asset-full-view';

export default defineMessages({
  noAssetData: {
    id: `${scope}.components.asset.tooltips.noAssetData`,
    defaultMessage: 'There are some problems when loading the asset!',
  },
  goAssets: {
    id: `${scope}.components.asset.tooltips.goAssets`,
    defaultMessage: 'Go Assets',
  },
  downloadAsset: {
    id: `${scope}.components.asset.tooltips.download`,
    defaultMessage: 'Download',
  },
  previewAsset: {
    id: `${scope}.components.asset.tooltips.preview`,
    defaultMessage: 'View Original File',
  },
  noPreviewAsset: {
    id: `${scope}.components.asset.tooltips.noPreviewAsset`,
    defaultMessage: 'No Preview',
  },
  noDownloadAsset: {
    id: `${scope}.components.asset.tooltips.noDownloadAsset`,
    defaultMessage: 'No Download',
  },
  replacePreviewSuccess: {
    id: `${scope}.message.replacePreviewSuccess`,
    defaultMessage: 'Update asset thumbnail successfully',
  },
  replacePreviewError: {
    id: `${scope}.message.replacePreviewError`,
    defaultMessage: 'Cannot update asset thumbnail',
  },
  classifications: {
    id: `${scope}.components.asset-edit.classifications`,
    defaultMessage: 'Classifications',
  },
  emailSelection: {
    id: `${scope}.components.asset.emailSelection`,
    defaultMessage: 'Email Selection',
  },
  zoomIn: {
    id: `${scope}.components.asset.tooltips.zoomIn`,
    defaultMessage: 'Zoom In',
  },
  zoomOut: {
    id: `${scope}.components.asset.tooltips.zoomOut`,
    defaultMessage: 'Zoom out',
  },
  resetZoom: {
    id: `${scope}.components.asset.tooltips.resetZoom`,
    defaultMessage: 'Reset',
  },
  rotateLeft: {
    id: `${scope}.components.asset.tooltips.rotateLeft`,
    defaultMessage: 'Rotate left',
  },
  rotateRight: {
    id: `${scope}.components.asset.tooltips.rotateRight`,
    defaultMessage: 'Rotate Right',
  },
  editAsset: {
    id: `${scope}.components.asset.editAsset`,
    defaultMessage: 'Edit asset',
  },
  editMemberDefinedPropertiesValueError: {
    id: `${scope}.memberDefinedProperties.editValue.error`,
    defaultMessage: 'Cannot edit value',
  },
  editMemberDefinedPropertiesValueSuccess: {
    id: `${scope}.memberDefinedProperties.editValue.success`,
    defaultMessage: 'Edit value successfully',
  },
  memberDefinedPropertiesWrongFiledTypeNoticeText: {
    id: `${scope}.memberDefinedProperties.wrongFieldType.text`,
    defaultMessage: 'The current value is {value}',
  },
  activeDRM: {
    id: `${scope}.components.asset.activeDRM`,
    defaultMessage: 'Active Digital Rights protections apply to this asset',
  },
  expiredDRM: {
    id: `${scope}.components.asset.expiredDRM`,
    defaultMessage: 'Expired Digital Rights protections apply to this asset',
  },
  noDRM: {
    id: `${scope}.components.asset.noDRM`,
    defaultMessage: 'No Digital Rights protections apply to this asset',
  },
  DRA: {
    id: `${scope}.components.asset.noDRM`,
    defaultMessage: 'Digital Right Asssignment',
  },
  expired: {
    id: `${scope}.components.asset.noDRM`,
    defaultMessage: 'Expired',
  },
  deleteDRM: {
    id: `${scope}.components.asset.deleteDRM`,
    defaultMessage: 'Do you want to delete this DRM?',
  },
  warningDRM: {
    id: `${scope}.components.asset.warningDRM`,
    defaultMessage:
      'The Digital Asset you have selected for download is covered under a Digital Rights Restriction. Please ensure that you have reviewed the Digital Rights Restrictions associated with the Digital Asset conform with your inteded usage.',
  },
});
