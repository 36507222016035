import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import {
  Button,
  Typography,
  Row,
  Col,
  Modal,
  notification,
  Result,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { FullWidthSpace, WithLoading } from 'common/components';

import { CustomizeTransfer } from 'common/components';
import ExportPropertiesTree from './ExportPropertiesTree';
import ExportProductToolbar from './ExportProductToolbar';

import * as productServices from 'services/product';
import { useGetProductPropertiesToExport } from 'hooks/exportProductPropertiesHooks';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import * as api from 'config/axios';
import * as globalSelectors from 'redux/global/selectors';
import { CustomNotification } from 'common/components';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

import './BulkEditProduct.less';

const { Title } = Typography;

const ExportProductPropertiesModal = (props) => {
  const intl = useIntl();
  const addedKeysRef = useRef([]);

  const { visible, setVisible, selectedProducts, selectedFolders } = props;

  const [leftExpandedKeys, setLeftExpandedKeys] = useState([]);
  const [isShowSelection, setIsShowSelection] = useState(false);
  const searchHook = useState('');
  const [searchText, setSearchText] = searchHook;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isExportProperties, setIsExportProperties] = useState(false);

  const { sourceData, rootData, loadingStatus } =
    useGetProductPropertiesToExport(visible);

  const upDownProgressData = useSelector(
    globalSelectors.selectUploadDownloadProgress()
  );

  const onOk = () => {
    setSubmitLoading(true);
    setIsExportProperties(true);

    const exportProperties = getExportedPropertyFieldName({
      data: sourceData,
      addedKeys: addedKeysRef.current,
    });

    callApiExportProperties(exportProperties);
  };

  const closeExportModal = () => {
    if (!isExportProperties) return;

    const isDownLoadSuccess = upDownProgressData
      ?.filter((item) => item?.type === 'download')
      ?.every((item) => item?.status === 'done');

    if (isDownLoadSuccess) {
      setSubmitLoading(false);
      setVisible(false);
      setIsExportProperties(false);
    }
  };

  useEffect(() => {
    closeExportModal();
  }, [upDownProgressData, setVisible]);

  const onCancel = () => {
    setVisible(false);
  };

  const onClickDownloadManual = () => {
    callApiDownloadManual();
  };

  const onTransferChange = (addedKeys) => {
    addedKeysRef.current = addedKeys;
    setSubmitDisabled(!addedKeys.length);
  };

  const callApiDownloadManual = () => {
    productServices
      .downloadManualEditingBulkProduct()
      .then((response) => {
        const { isSuccess, data } = response;
        if (isSuccess && data) api.sendDownload({ url: response?.data?.url });
        else handleDownloadManualError(response);
      })
      .catch(() => {
        setSubmitLoading(false);
        handleDownloadManualError();
      });
  };

  const handleDownloadManualError = (response) => {
    notification.error({
      message:
        response?.message ||
        response?.data?.message ||
        intl.formatMessage(Messages.exportPropertiesDownloadManualError),
    });
  };

  const callApiExportProperties = (exportProperties) => {
    const params = {
      productIds: selectedProducts,
      folderIds: selectedFolders,
      selectedColumns: exportProperties,
    };

    productServices
      .exportProductProperties(params)
      .then((response) => {
        const { isSuccess, data } = response;

        if (isSuccess && data) {
          api.sendDownload({ url: response?.data?.url });
          CustomNotification.success('Download successfully');
        } else {
          notification.error({
            message:
              response?.message ||
              response?.data?.message ||
              intl.formatMessage(Messages.exportProductPropertiesError),
          });
        }
      })
      .catch((e) => {
        notification.error({
          message: intl.formatMessage(Messages.exportProductPropertiesError),
        });
      });
  };

  const getExportedPropertyFieldName = ({ data, addedKeys }) => {
    return addedKeys.reduce((accumulator, currentAddedKeys) => {
      const addedPropertyData = data.find((propertyItem) => {
        return propertyItem.key === currentAddedKeys;
      });
      const fieldFullPath = addedPropertyData?.data?.fieldFullPath;

      if (addedPropertyData && fieldFullPath) accumulator.push(fieldFullPath);

      return accumulator;
    }, []);
  };

  const resetState = () => {
    if (visible) return;

    setIsShowSelection(false);
    setLeftExpandedKeys([]);
    setSearchText('');
  };

  useEffect(() => {
    resetState();
  }, [visible]);

  const renderModalTitle = () => {
    const title = intl.formatMessage(Messages.exportProperties);

    const DownloadManualBtn = Button;

    return (
      <Row style={{ paddingRight: 30 }}>
        <Col flex={0}>
          <Title level={5} style={{ marginBottom: 0 }}>
            {title}
          </Title>
        </Col>
        <Col flex={1}></Col>
        <Col flex={0}>
          <Tooltip
            title={intl.formatMessage(
              Messages.exportPropertiesDownloadManualTitleToolTip
            )}
          >
            <DownloadManualBtn
              type='text'
              icon={<QuestionCircleOutlined />}
              onClick={onClickDownloadManual}
            />
          </Tooltip>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title={renderModalTitle()}
      visible={visible}
      bodyStyle={{
        height: '80vh',
        maxHeight: 600,
      }}
      onOk={onOk}
      okButtonText={intl.formatMessage(Messages.exportPropertiesSubmit)}
      okButtonProps={{
        loading: submitLoading,
        disabled: submitDisabled,
      }}
      onCancel={onCancel}
      width={1300}
      destroyOnClose
      centered
    >
      <ExportProductToolbar
        visible={visible}
        dataSource={sourceData}
        leftExpandedKeys={leftExpandedKeys}
        setLeftExpandedKeys={setLeftExpandedKeys}
        isShowSelection={isShowSelection}
        setIsShowSelection={setIsShowSelection}
        searchHook={searchHook}
      />
      {loadingStatus === 'loading' ? (
        <div className='export-properties__loading-container'>
          <WithLoading
            loading
            className='export-properties__loading-spinning'
          />
        </div>
      ) : null}
      {loadingStatus === 'error' ? (
        <div className='export-properties__error-container'>
          <Result
            status='error'
            title={intl.formatMessage(Messages.exportPropertiesGetDataError)}
            className='export-properties__error-result'
          />
        </div>
      ) : null}
      {loadingStatus === 'success' ? (
        <div className='export-properties__transfer-container'>
          <CustomizeTransfer
            visible={visible}
            dataSource={sourceData}
            leftExpandedKeys={leftExpandedKeys}
            setLeftExpandedKeys={setLeftExpandedKeys}
            isShowSelection={isShowSelection}
            searchText={searchText}
            onChange={onTransferChange}
          >
            {(listProps) => (
              <ExportPropertiesTree
                {...listProps}
                propertyTitle='exportProperties'
                rootData={rootData}
              />
            )}
          </CustomizeTransfer>
        </div>
      ) : null}
    </Modal>
  );
};

export default ExportProductPropertiesModal;
