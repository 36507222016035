import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import moment from 'moment';

const { Text } = Typography;

const AssetItemCardBottom = (props) => {
  const { imageUpdateDate, lastUpdateDate } = props;

  return (
    <Row className='item-card__bottom-detail'>
      <Col xs={24}>
        <Text>
          <span>Last Updated: </span>

          <span>
            {
              //TODO: DATETIME
              moment(lastUpdateDate).format('MM/DD/YYYY')
            }
          </span>
        </Text>
      </Col>
      <Col xs={24}>
        <span>image Updated: </span>
        <span>
          {
            //TODO: DATETIME
            moment(imageUpdateDate).format('MM/DD/YYYY')
          }
        </span>
      </Col>
    </Row>
  );
};

AssetItemCardBottom.propTypes = {
  //? imageUpdateDate - iso date
  imageUpdateDate: PropTypes.string,
  //? lastUpdateDate - iso date
  lastUpdateDate: PropTypes.string,
};

export default React.memo(AssetItemCardBottom);
