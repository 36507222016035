import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import messages from 'i18n/messages/home';

import { Dropdown, Menu } from 'antd';

import RibbonButton from 'common/components/button/RibbonButton';
import { ReactComponent as IconShared } from 'assets/shared-ribbon/Share.svg';

import selectorUser from 'redux/user/selectors';

import EmailControl from '../../controls/shared/EmailControl';
import SendToFTP from '../../controls/shared/SendToFTP';
import SendToMessage from '../../controls/shared/SendToMessage';
import { ShareMappingModal } from 'common/components';

import Messages from 'i18n/messages/home';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const ShareControl = (props) => {
  const { detailCurrentItemsSelection, type } = props;

  const {
    isSuperMember,
    member: { id: memberId },
  } = useSelector(selectorUser.makeSelectUserInfo()); // TDC/Daymon

  const visibleModalHook = useState(false);
  const [visibleModel, setVisibleModel] = visibleModalHook;

  const confirmShare = () => {
    setVisibleModel(true);
  };

  const menuOptions = (
    <Menu>
      <Menu.Item key='share'>
        <ButtonSmallIcon
          icon={<IconShared />}
          label={Messages.share}
          onClick={confirmShare}
          className='share-dropdown-ribbon__item share-dropdown__item--email'
        />
      </Menu.Item>
      <Menu.Item key='email'>
        <EmailControl disabled />
      </Menu.Item>
      <Menu.Item key='sendToFTP'>
        <SendToFTP disabled />
      </Menu.Item>
      <Menu.Item key='sendToMessage'>
        <SendToMessage disabled />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menuOptions}
        arrow
        disabled={checkDisabledShareMapping(
          isSuperMember,
          memberId,
          detailCurrentItemsSelection
        )}
      >
        <RibbonButton
          icon={<IconShared style={{ height: 26 }} />}
          label={messages.share}
          // onClick={confirmShare}
        />
      </Dropdown>
      <ShareMappingModal
        visibleHook={visibleModalHook}
        detailCurrentItemsSelection={detailCurrentItemsSelection}
      />
    </>
  );
};

const checkDisabledShareMapping = (
  isSuperMember,
  memberId,
  selectedMapping
) => {
  if (!isSuperMember) return true;

  if (selectedMapping?.length === 1) {
    if (memberId === selectedMapping[0].ownerCompanyId) return false;
    return true;
  }

  if (selectedMapping?.length !== 1) return true;

  return false;
};

ShareControl.propTypes = {
  detailCurrentItemsSelection: PropTypes.array,
};

export default ShareControl;
