import { Space } from 'antd';

import { AgGridIcons } from 'common/components';

import { renderPathname } from 'pages/folders/folder-details/utils';

import { TooltipParagraph } from 'common/components';

import { Images } from 'config/assets';
import { ITEM_TYPE } from 'static/Constants';

import reactCSS from 'reactcss';

export const QueryLink = (props) => {
  const { value, data } = props;

  const { id } = data || {};

  const onClickLink = (e) => {
    const url = `/execute-query/${id}`;

    window.open(url).focus();
    //* query execution is handled in execute-query/index
  };

  return (
    <a className='ag-grid__link-to' onClick={onClickLink}>
      {value}
    </a>
  );
};

const NormalDetailLink = (props) => {
  const { data, value } = props;
  const pathname = renderPathname(data?.type);

  const url = pathname ? `/${pathname}/${data?.id}` : window.location.pathname;

  const onClickLink = () => {
    window.open(url, '_blank').focus();
  };

  return (
    <a className='ag-grid__link-to' onClick={onClickLink}>
      <TooltipParagraph
        style={{ color: '#0070cc', marginBottom: 0 }}
      >
        {value}
      </TooltipParagraph>
    </a>
  );
};

const renderLink = (params) => {
  const { data } = params;

  const { type } = data || {};

  if (ITEM_TYPE.query.includes(type.toLowerCase())) {
    return <QueryLink {...params} />;
  }

  return <NormalDetailLink {...params} />;
};

const renderFavSharePackIcons = (params) => {
  const data = params?.data;

  return (
    <AgGridIcons data={data} isFavorite={data?.isFavorited} isShared={true} />
  );
};

const renderFolderOptionIcon = (params) => {
  const { isOwner, items } = params?.data || {};

  const styles = reactCSS({
    default: {
      img: { width: 'auto', height: 'auto', maxWidth: 24, maxHeight: 24 },
    },
  });

  return (
    <Space direction='horizontal' className='folder-option-icon-renderer'>
      {isOwner ? (
        items > 0 ? (
          <img style={styles.img} src={Images.folder} alt='owner' />
        ) : (
          <img style={styles.img} src={Images.emptyFolder} alt='owner' />
        )
      ) : (
        <img style={styles.img} src={Images.sharedFolder} alt='shared' />
      )}
    </Space>
  );
};

const customCellComponents = {
  renderLink,
  renderFavSharePackIcons,
  renderFolderOptionIcon,
};

export default customCellComponents;
