import * as types from './constants';

//* GET BRANDING INFO
export function getBranding() {
  return {
    type: types.GET_BRANDING,
  };
}

/**
 * * get branding data success
 * ? payload is everything BE return
 * @param  {object} payload
 *
 */
export function getBrandingSuccess(payload) {
  return {
    type: types.GET_BRANDING_SUCCESS,
    payload,
  };
}

export function getBrandingFail(err) {
  return {
    type: types.GET_BRANDING_FAIL,
    err,
  };
}

export function clearBranding(err) {
  return {
    type: types.CLEAR_BRANDING,
    err,
  };
}

export function updateLastGetNewMember() {
  return {
    type: types.UPDATE_LAST_GET_NEW_MEMBER,
  };
}

export function updateLastGetNewMemberSuccess() {
  return {
    type: types.UPDATE_LAST_GET_NEW_MEMBER_SUCCESS,
  };
}

export function updateLastGetNewMemberError(error) {
  return {
    type: types.UPDATE_LAST_GET_NEW_MEMBER_ERROR,
    error,
  };
}

//* GET BRANDING INFO
export function getBrandingNoLoading() {
  return {
    type: types.GET_BRANDING_NO_LOADING,
  };
}

/**
 * * get branding data success
 * ? payload is everything BE return
 * @param  {object} payload
 *
 */
export function getBrandingNoLoadingSuccess(payload) {
  return {
    type: types.GET_BRANDING_NO_LOADING_SUCCESS,
    payload,
  };
}

export function getBrandingNoLoadingFail(err) {
  return {
    type: types.GET_BRANDING_NO_LOADING_FAIL,
    err,
  };
}

export function updateLastGetNewProduct() {
  return {
    type: types.UPDATE_LAST_GET_NEW_PRODUCT,
  };
}
export function updateLastGetNewProductSuccess() {
  return {
    type: types.UPDATE_LAST_GET_NEW_PRODUCT_SUCCESS,
  };
}
export function updateLastGetNewProductError(error) {
  return {
    type: types.UPDATE_LAST_GET_NEW_PRODUCT_ERROR,
    payload: error,
  };
}

export function updateLastGetNewAssets() {
  return {
    type: types.UPDATE_LAST_GET_NEW_ASSET,
  };
}
export function updateLastGetNewAssetsSuccess() {
  return {
    type: types.UPDATE_LAST_GET_NEW_ASSET_SUCCESS,
  };
}
export function updateLastGetNewAssetsError(error) {
  return {
    type: types.UPDATE_LAST_GET_NEW_ASSET_ERROR,
    payload: error,
  };
}

export function updateLastGetUnmatchMember() {
  return {
    type: types.UPDATE_LAST_GET_UNMATCH_MEMBER,
  };
}

export function updateLastGetUnmatchMemberSuccess() {
  return {
    type: types.UPDATE_LAST_GET_UNMATCH_MEMBER_SUCCESS,
  };
}

export function updateLastGetUnmatchMemberError(error) {
  return {
    type: types.UPDATE_LAST_GET_UNMATCH_MEMBER_ERROR,
    error,
  };
}

export function updateLastGetUnmatchAssets() {
  return {
    type: types.UPDATE_LAST_GET_UNMATCH_ASSET,
  };
}
export function updateLastGetUnmatchAssetsSuccess() {
  return {
    type: types.UPDATE_LAST_GET_UNMATCH_ASSET_SUCCESS,
  };
}
export function updateLastGetUnmatchAssetsError(error) {
  return {
    type: types.UPDATE_LAST_GET_UNMATCH_ASSET_ERROR,
    payload: error,
  };
}
