import React, { useRef, memo } from 'react';

import { Row, Avatar, Col, Typography, Divider } from 'antd';
import classnames from 'classnames';

import { RightOutlined } from '@ant-design/icons';
import EmptyFolder from 'assets/folder/empty-folder.png';
import hasItemsFolder from 'assets/folder/folder.png';

import DropWrap from '../drapDropWrap/drap-and-drop/DropWrap';

import useDoubleClick from 'hooks/useDoubleClick';

import { formatMDY } from 'utils/formatDate';

export const FolderItemContainer = (props) => {
  const ref = useRef();

  const { onClick, children, onDoubleClick, ...rest } = props;

  useDoubleClick({
    onSingleClick: (event) => {
      onClick?.(event);
    },
    onDoubleClick: () => onDoubleClick?.(),
    ref,
    latency: 200,
  });

  return (
    <Row ref={ref} align='middle' justify='space-between' {...rest}>
      {children}
    </Row>
  );
};

// Edit component for complex info
export const FolderInfo = (props) => {
  const { title, style, dateCreated, children, ...rest } = props;
  return (
    <Row
      style={{ marginLeft: 12, alignItems: 'center', width: '100%', ...style }}
      {...rest}
    >
      <Col span={24}>
        <Typography.Text strong ellipsis>
          {title}
        </Typography.Text>
      </Col>
      {children}
    </Row>
  );
};

export const FolderIcon = ({ src, style, ...rest }) => {
  return (
    <Avatar
      shape='square'
      style={{ objectFit: 'contain', ...style }}
      src={src}
      {...rest}
    />
  );
};

export const FolderAction = (props) => {
  return (
    <div {...props}>
      <RightOutlined />
    </div>
  );
};

export const FolderItem = memo((props) => {
  const {
    item,
    onExpand,
    onDrop,
    handleSelectFolder,
    selectedFolderId,
    disableItemHandler,
  } = props;
  const { id, subFolderCount, dateCreated, folderName, description } =
    item ?? {};

  const folderSrc = subFolderCount > 0 ? hasItemsFolder : EmptyFolder;
  const title = folderName ?? description ?? '';

  const disabled = disableItemHandler ? disableItemHandler(item) : false;

  return (
    <FolderItemContainer
      key={id}
      className={classnames({
        'folder-item': true,
        'folder-item__selected': id === selectedFolderId,
        'folder-item--disabled': disabled,
      })}
      onDoubleClick={() => {
        if (item?.subFolderCount) onExpand(item, title);
      }}
      onClick={() => handleSelectFolder(item)}
    >
      <DropWrap
        dropClassName='items-drop'
        className='drop-wrapper-folder'
        onDropData={(dropData) => onDrop(dropData, item)}
      >
        <Col
          span={20}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FolderIcon src={folderSrc} className='folder-item__icon' />
          <FolderInfo title={title}>
            <Typography.Text ellipsis>
              Date created: {formatMDY(dateCreated)}
            </Typography.Text>
          </FolderInfo>
        </Col>
        <Col className='folder-item_expand-icon'>
          {item?.subFolderCount ? (
            <FolderAction onClick={() => onExpand(item, title)} />
          ) : null}
        </Col>
      </DropWrap>
      <Divider style={{ margin: 0 }} />
    </FolderItemContainer>
  );
});
