import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TextSlickSlider.less';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <RightOutlined style={{ fontSize: '32px', color: 'grey' }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <LeftOutlined style={{ fontSize: '32px', color: 'grey' }} />
    </div>
  );
}

export default function SlickSlider(props) {
  const settings = {
    className: 'center',
    infinite: true,
    centerPadding: '0',
    slidesToShow: 2,
    speed: 500,
    dots: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const { children } = props;
  return (
    <div className='text-slick-slider'>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
SlickSlider.propTypes = {
  children: PropTypes.array.isRequired,
};
