export const GET_EULA_MAINTENANCE_GRID_COLUMNS = '';

export const CREATE_EULA = '/api/User/create-eula';
export const DELETE_EULA = '/api/User/delete-eula';
export const CLONE_EULA = '/api/User/clone-eula';

export const GET_EULA_DETAIL = '/api/User/get-eula-short-detail';
export const GET_EULA_MAINTENANCE_LIST = '/api/User/get-eula-detail-grid';
export const POST_DETAIL_GRID_DISTINCT =
  '/api/User/get-eula-detail-grid/distinct';
export const GET_EULA_DETAIL_CONTENT = '/api/User/get-eula-short-detail';
//Accepted eula
export const GET_ACCEPTED_USER_EULA = '/api/User/eula-accepted-user-grid';
export const ACCEPTED_USER_EULA_GRID_DISTINCT =
  '/api/User/eula-accepted-user-grid/distinct';
