import { defineMessages } from 'react-intl';

export const scope = 'Taco.digitalAsset.preview';

export default defineMessages({
  generateLoading: {
    id: `${scope}.generate.loading`,
    defaultMessage: 'Generating Thumbnail...',
  },
  generatePreviewSuccess: {
    id: `${scope}.generate.success`,
    defaultMessage: 'Generate Thumbnail success',
  },
  generatePreviewError: {
    id: `${scope}.generate.error`,
    defaultMessage: 'Cannot generate Thumbnail',
  },
  generatePreviewUpdateThumbError: {
    id: `${scope}.generate.updateError`,
    defaultMessage: 'Cannot update preview thumbnail',
  },
  generatePreviewRevertThumbError: {
    id: `${scope}.generate.revertError`,
    defaultMessage: 'Cannot revert preview thumbnail',
  },
  generatePreviewBackgroundConfirmMessage: {
    id: `${scope}.generate.background.confirmMessage`,
    defaultMessage: 'Would you like to run background generation?',
  },
  generatePreviewSubmit: {
    id: `${scope}.generate.submit`,
    defaultMessage: 'Submit',
  },
  generatePreviewItemUploadBtn: {
    id: `${scope}.generate.item.btn.upload`,
    defaultMessage: 'Upload',
  },
  generatePreviewItemRevertBtn: {
    id: `${scope}.generate.item.btn.revert`,
    defaultMessage: 'Revert',
  },
  generatePreviewLoad: {
    id: `${scope}.generate.item.btn.revert`,
    defaultMessage: 'Revert',
  },
  generateLoadImageError: {
    id: `${scope}.generate.item.image.loadError`,
    defaultMessage: 'Cannot load image',
  },
  generateLoadImageBrowserError: {
    id: `${scope}.generate.item.image.browserError`,
    defaultMessage: 'Browser does not support this image type',
  },
  generateConfirmYesBtn: {
    id: `${scope}.generate.confirm.btn.yes`,
    defaultMessage: 'Yes',
  },
  generateConfirmNoBtn: {
    id: `${scope}.generate.confirm.btn.no`,
    defaultMessage: 'No',
  },
  generateConfirmCancelBtn: {
    id: `${scope}.generate.confirm.btn.cancel`,
    defaultMessage: 'Cancel',
  },
  deletePreviewConfirmMessage: {
    id: `${scope}.deletePreview.confirmMessage`,
    defaultMessage: 'Are you sure to delete?',
  },
  deletePreviewSuccess: {
    id: `${scope}.deletePreview.success`,
    defaultMessage: 'Delete preview success',
  },
  deletePreviewError: {
    id: `${scope}.deletePreview.error`,
    defaultMessage: 'Cannot delete preview',
  },
});
