import React from 'react';

import { RibbonSection } from 'common/components';

import OpenReporting from 'pages/home/ribbon/components/controls/reporting/OpenReporting';
import CreateReporting from 'pages/home/ribbon/components/controls/reporting/CreateReporting';
import CloneReporting from 'pages/home/ribbon/components/controls/reporting/CloneReporting';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const OpenSection = (props) => {
  const { selectedItemList } = props;

  return (
    <RibbonSection>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.REPORTING}>
        <OpenReporting />
      </Can>
      <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.REPORTING}>
        <CreateReporting />
      </Can>
      <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.REPORTING}>
        <CloneReporting selectedItemList={selectedItemList} />
      </Can>
    </RibbonSection>
  );
};

export default OpenSection;
