import * as api from 'config/axios';
import * as endpoints from './endpoints';

/**
 * digital asset service
 * @param {object} data asset daya
 */

export const updateDigitalAsset = (payload) => {
  return api.sendPost(endpoints.DIGITAL_ASSET_EDIT, payload);
};

// Get Digital Asset Full View
export const getAssetData = (assetId) => {
  return api.sendGet(`${endpoints.GET_DIGITAL_ASSET}?Id=${assetId}`);
};

// Download Digital Asset
export const downloadDigitalAsset = (params) => {
  return api.sendPost(`${endpoints.DIGITAL_ASSET_DOWNLOAD}`, params);
};

export const downloadDigitalAssetByEmail = (params) => {
  return api.sendPost(`${endpoints.DIGITAL_ASSET_DOWNLOAD}`, params);
};

//* Get ftp file list
export const serviceToGetFtpFileList = (options) => {
  return api.sendGet(endpoints.GET_FTP_FILE_LIST, options);
};

//* get generate thumbnail
export const getGeneratedPreview = (params) => {
  return api.sendPost(endpoints.GENERATE_PREVIEW, params);
};

//* Update degital asset thumbnail
export const updateDigitalAssetThumb = (params) => {
  return api.sendPost(endpoints.UPDATE_DIGITAL_ASSET_THUMB, params);
};

//* Update degital asset thumbnail by adv fpt
export const updateDigitalAssetThumbByAdvFpt = (params) => {
  return api.sendPost(endpoints.REPLACE_ASSET_PREVIEW_ADV_FTP, params);
};

//* create digital asset by ftp
export const createDigitalAssetByFtp = (params) => {
  return api.sendPost(endpoints.CREATE_DIGITAL_ASSET_FTP, params);
};

//* background preview generation
export const generatePreviewBackground = (params) => {
  return api.sendPost(endpoints.GENERATE_PREVIEW_BACKGROUND, params);
};

//* Post FTP FILE
export const serviceToPostFtpFile = (payload) => {
  return api.sendGet(payload.apiUrl + `/${payload.path}`);
};

//* Create Digital Asset
export const createDigitalAsset = (data) => {
  return api.sendPost(endpoints.CREATE_DIGITAL_ASSET, {
    params: data,
  });
};

//* Replace Digital Asset
export const createReplaceAsset = ({ id, ftpPath }) => {
  let formData = new FormData();
  formData.append('ftpPath', ftpPath);

  return api.sendPost(endpoints.REPLACE_DIGITAL_ASSET + `/${id}`, formData);
};

// Download Specific Information
export const downloadSpecInfo = (payload) => {
  return api.sendGet(endpoints.DOWNLOAD_SPEC_INFO, {
    params: payload,
  });
};
export const updateDigitalAssetService = (params) => {
  return api.sendPost(endpoints.UPDATE_DIGITAL_ASSET, params);
};
export const updateDigitalReplaceAssetService = (params) => {
  return api.sendPost(endpoints.UPDATE_DIGITAL_REPLACE_ASSET, params);
};

// Update Digital Asset
export const updateDigitalAssetFull = (data) => {
  return api.sendPost(endpoints.UPDATE_DIGITAL_ASSET, data);
};

// Download Selected Digital Asset
export const downloadDigitalAssetSelectedId = (data) => {
  return api.sendPost(endpoints.DIGITAL_ASSET_DOWNLOAD_SELECTED_ID, data);
};

//* get last modified date
export const getLastModifiedDateAsset = async (params) => {
  return api.sendGet(endpoints.GET_LATEST_MODIFIED_DATE_ASSET, params);
};

// Create Iframe
export const createIFrame = (data) => {
  return api.sendPost(endpoints.CREATE_IFRAME, data);
};

// Edit Iframe
export const editIFrame = (data) => {
  return api.sendPost(endpoints.EDIT_IFRAME, data);
};

export const updateAssetType = (data) => {
  return api.sendPost(endpoints.UPDATE_ASSET_TYPE, data);
};

export const getLinkDownloadAsset = (id, format) => {
  return api.sendGet(
    `${endpoints.MULTIPLE_DOWNLOAD_ASSET}?AssetId=${id}&DownloadFormat=${format}`
  );
};

// Create/Modify GS1
export const saveGS1 = (data) => {
  return api.sendPost(endpoints.SAVE_GS1, data);
};

// Create/Modify GS1
export const linkAssetToPIM = (params, associationType) => {
  return associationType === 'DOCUMENT'
    ? api.sendPost(endpoints.LINK_ASSET_TO_PIM_DOCUMENT, params)
    : api.sendPost(endpoints.LINK_ASSET_TO_PIM, params);
};

// Delete GS1
export const deleteGS1 = (data) => {
  return api.sendPost(endpoints.DELETE_GS1, data);
};

export const unlinkAssetToPIM = (params, associationType) => {
  return associationType === 'DOCUMENT'
    ? api.sendPost(endpoints.UNLINK_ASSET_TO_PIM_DOCUMENT, params)
    : api.sendPost(endpoints.UNLINK_ASSET_TO_PIM, params);
};

export const updateLinkAssetToPIM = (params) => {
  return api.sendPost(endpoints.UPDATE_LINK_ASSET_TO_PIM, params);
};

export const editMemberDefinedPropertiesValue = (params) => {
  return api.sendPost(endpoints.EDIT_MEMBER_DEFINED_PROPERTIES_VALUE, params);
};
// Generate GS1 Compliant Name
export const generateGS1CompliantName = (data) => {
  return api.sendPost(endpoints.GENERATE_GS1_COMPLIANT_NAME, data);
};

export const getPreviousVersionsOfAsset = (params) => {
  return api.sendGet(endpoints.GET_PREVIOUS_VERSIONS_OF_ASSET, {
    params: params,
  });
};

export const getDetailPreviousVersionOfAsset = (params) => {
  return api.sendGet(endpoints.GET_DETAIL_PREVIOUS_VERSION_OF_ASSET, {
    params: params,
  });
};

export const downloadGS1File = (payload) => {
  return api.sendGet(endpoints.DOWNLOAD_GS1_FILE, { params: payload });
};

export const downloadAuthorizedTags = (params) => {
  return api.sendPost(endpoints.DOWNLOAD_AUTHORIZED_TAGS, params);
};

export const moveAssetToMember = (payload) => {
  return api.sendPost(endpoints.MOVE_ASSET_TO_MEMBER, payload);
};

export const replaceDigitalAssetsThumbnails = (payload) => {
  return api.sendPost(endpoints.REPLACE_DIGITAL_ASSETS_THUMBNAILS, payload);
};
