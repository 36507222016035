import React from 'react';
import { Image } from 'antd';

import reactCSS from 'reactcss';

const OtpHintImage = (props) => {
  const { src } = props;

  const styles = reactCSS({
    default: {
      image: {
        borderRadius: '100%',
      },
      imageWrap: {
        padding: 10,
      },
    },
  });

  return (
    <div style={styles.imageWrap}>
      <Image
        src={src}
        width={170}
        height={170}
        size
        preview={false}
        style={styles.image}
      />
    </div>
  );
};

export default OtpHintImage;
