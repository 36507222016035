import React from 'react';

import { Input, Typography } from 'antd';

import { Form } from 'common/components';

import Messages from 'i18n/messages/apl';
import { useIntl } from 'react-intl';

const { Text } = Typography;

const AplSampleRequestEmailForm = (props) => {
  const intl = useIntl();

  const { form, contactEmail } = props;

  const layout = { labelCol: { span: 3 }, wrapperCol: { span: 21 } };

  return (
    <Form form={form} {...layout}>
      <Form.Item
        name='email'
        label={intl.formatMessage(Messages.contactEmail)}
        rules={[
          {
            required: true,
            message: intl.formatMessage(Messages.emailRequired),
          },
          {
            type: 'email',
            message: intl.formatMessage(Messages.emailInvalid),
          },
        ]}
        style={{ height: 45, marginBottom: 0 }}
      >
        {contactEmail ? (
          <Text strong className='apl-request-sample__email'>
            {contactEmail}
          </Text>
        ) : (
          <Input />
        )}
      </Form.Item>
      <Form.Item
        name='emailTitle'
        label={intl.formatMessage(Messages.subject)}
        rules={[
          {
            required: true,
            message: intl.formatMessage(Messages.subjectRequired),
          },
        ]}
        style={{ height: 45, marginBottom: 0 }}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default AplSampleRequestEmailForm;
