import React from 'react';

import { Button, Typography } from 'antd';
import mfaSrc from 'assets/security/mfa.png';
import { useOtpVerify } from 'common/components/otp';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditOutlined,
} from '@ant-design/icons';
import SecurityLabel from './SecurityLabel';
import { OtpVerifyChangeMfaPhone } from 'common/components/otp';

import reactCSS from 'reactcss';

const { Text } = Typography;

const MultifactorLabel = (props) => {
  const { mfaPhoneNumber } = props;

  return (
    <SecurityLabel
      labelImageSrc={mfaSrc}
      labelTitle='Phone Verification'
      detailText={mfaPhoneNumber || 'N/A'}
      hintText='MFA function only work if required by member.'
    />
  );
};

const MultifactorControl = (props) => {
  const { isAllowToChangeMfaPhoneNumber } = props;

  const {
    onOpen: openChangeMfaPhoneModal,
    onClose: closeChangeMfaPhoneModal,
    visible: isChangeMfaPhoneModalVisible,
  } = useOtpVerify();

  return (
    <div>
      <Button
        type='primary'
        onClick={openChangeMfaPhoneModal}
        icon={<EditOutlined />}
        disabled={!isAllowToChangeMfaPhoneNumber}
      >
        Change MFA phone number
      </Button>

      <OtpVerifyChangeMfaPhone
        visible={isChangeMfaPhoneModalVisible}
        onClose={closeChangeMfaPhoneModal}
      />
    </div>
  );
};

const MultifactorStatus = (props) => {
  const { requireMfa } = props;

  const styles = reactCSS({
    default: {
      icon: {
        check: {
          color: '#389e0d',
          fontSize: 18,
          marginRight: 5,
        },
        close: {
          color: '#ffa940',
          fontSize: 18,
          marginRight: 5,
        },
        wrap: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  });

  return (
    <>
      {requireMfa ? (
        <span style={styles.icon.wrap}>
          <CheckCircleFilled style={styles.icon.check} />
          <Text>MFA turn on</Text>
        </span>
      ) : (
        <span style={styles.icon.wrap}>
          <CloseCircleFilled style={styles.icon.close} />
          <Text> MFA turn off </Text>
        </span>
      )}
    </>
  );
};

const Multifactor = {
  Label: MultifactorLabel,
  Control: MultifactorControl,
  Status: MultifactorStatus,
};

export default Multifactor;
