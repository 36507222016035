import React from 'react';

import { getUomTypesAbbreviations } from './constants';
import { useGetBasicUomEnum } from 'hooks';
import { parseNumber } from 'utils/parser';
import SeparatorBar from 'pages/product-full-view/components/product-media-area/nutrition-label/components/SeparatorBar';
import { MEASUREMENT_OPERATOR } from './Constant';

function NutrientRowNew({ nutrient, labelItalic }) {
  const {
    dailyValueIntakePercent,
    percentBold,
    indentBar,
    indent,
    labelBold,
    quantity,
    quantityUom,
    bottomBar,
    nutritionName,
    bottomBarHeight,
    topBarHeight,
    topBar,
    hightLightText,
    measurementPrecisionCode,
  } = nutrient ?? {};

  const { basicUomProperties } = useGetBasicUomEnum();

  const showAbbr = (quantityUom, defaultUom = 'g') => {
    return (
      getUomTypesAbbreviations(basicUomProperties, quantityUom) ?? defaultUom
    );
  };

  const percent = parseNumber(dailyValueIntakePercent);

  const gram =
    parseNumber(quantity) !== ''
      ? `${parseNumber(quantity)}${showAbbr(quantityUom, 'g')}`
      : null;

  const indentLabel = indent ? `${indent}px` : '0';
  const labelWeight = labelBold ? 900 : 400;
  const percentWeight = percentBold ? 900 : 400;
  const indentDiv = indentBar ? `${indentBar}px` : indentLabel;

  const styles = {
    div: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: indentDiv,
    },
    label: {
      fontWeight: labelWeight,
      fontStyle: labelItalic ? 'italic' : '',
    },
    percent: {
      fontWeight: percentWeight,
    },
    gram: {
      paddingLeft: '2px',
    },
    measurementPrecision: {
      paddingLeft: '2px',
      fontWeight: 400,
    },
    indent: {
      backgroundColor: hightLightText,
    },
  };

  const renderSymbols = (measurementPrecisionCode) => {
    return MEASUREMENT_OPERATOR?.[measurementPrecisionCode] || '';
  };

  const measurementPrecision = renderSymbols(measurementPrecisionCode);

  return (
    <div className='nutrition-facts__nutrition-row' style={styles.indent}>
      {topBar ? null : (
        <SeparatorBar indentBar={indentBar || 0} height={topBarHeight || 0} />
      )}
      <div style={styles.div}>
        <div className='nutrition-facts__nutrition-row_name'>
          <div style={styles.label}>{nutritionName}</div>
          {gram ? (
            <>
              <div style={styles.measurementPrecision}>
                {measurementPrecision}
              </div>
              <div style={styles.gram}>{gram}</div>
            </>
          ) : null}
        </div>
        <div style={styles.percent}>
          {percent === 0 || percent > 0 ? `${percent}%` : null}
        </div>
      </div>

      {bottomBar && (
        <SeparatorBar
          indentBar={indentBar || 0}
          height={bottomBarHeight || 0}
        />
      )}
    </div>
  );
}

export default NutrientRowNew;
