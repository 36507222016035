import React, { memo } from 'react';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { QasField } from '../components';
import { getHighlightedText } from 'common/components/reporting/entity-and-attributes/utils';
import { random } from 'lodash';

const DraggableField = (props) => {
  const {
    item,
    selectedId,
    dragDisabled,
    parentGroupMatched,
    index,
    level,
    searchText,
    requiredProperties,
    handleRemove,
    onSelectMulti,
  } = props;

  if (dragDisabled && !item?.matched && !parentGroupMatched) {
    return;
  }
  if (!dragDisabled && !item?.matched) {
    return;
  }
  const highlightSearchText = getHighlightedText(item?.displayName, searchText);

  const isDisabled = dragDisabled
    ? false
    : requiredProperties.indexOf(item?.fieldFullPath) > -1;

  if (dragDisabled) {
    return (
      <div
        key={`${index}_${`${item?.fieldFullPath}`}`}
        style={{ display: 'flex' }}
      >
        <div
          style={{
            marginLeft: level * 8,
          }}
        />
        <div
          className={classnames({
            'qas-spec-draggable__card': true,
          })}
        >
          <QasField
            item={item}
            shouldShowSelection={false}
            selectedId={selectedId}
            dragDisabled={dragDisabled}
            highlightSearchText={highlightSearchText}
            handleRemove={handleRemove}
          />
        </div>
      </div>
    );
  }

  return (
    <Draggable
      draggableId={`${item?.fieldFullPath}`}
      key={`${index}_${`${item?.fieldFullPath}`}`}
      index={index}
      isDragDisabled={dragDisabled || isDisabled}
    >
      {(provided, snapshot) => {
        const isSelected = dragDisabled
          ? false
          : selectedId.indexOf(item?.fieldFullPath) > -1;

        const shouldShowSelection =
          snapshot.isDragging && selectedId?.length > 1;

        return (
          <div style={{ display: 'flex' }}>
            {!snapshot.isDragging && (
              <div
                style={{
                  marginLeft: level * 8,
                }}
              ></div>
            )}
            <div
              className={classnames({
                'qas-spec-draggable__card': true,
                'qas-spec-draggable__card--selected': isSelected,
                'qas-spec-draggable__card--ghosting': isDisabled,
                'qas-spec-draggable__card--drag': snapshot.isDragging,
              })}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={(e) => {
                if (!dragDisabled) {
                  onSelectMulti(e, item);
                }
              }}
            >
              <QasField
                item={item}
                shouldShowSelection={shouldShowSelection}
                selectedId={selectedId}
                dragDisabled={dragDisabled}
                highlightSearchText={highlightSearchText}
                handleRemove={handleRemove}
              />
              {provided.placeholder}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default memo(DraggableField);
