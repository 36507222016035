import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Image, Typography } from 'antd';

import AssetReport from 'assets/reporting/AssetReport.png';
import MemberReport from 'assets/reporting/MemberReport.png';
import ProductReport from 'assets/reporting/ProductReport.png';

import { InfoWithLabel } from 'common/components';
import { formatMDY } from 'utils/formatDate';

import AdvancedSearchTitle from 'common/components/advanced-search/AdvancedSearchTitle';
import { DragDropContext } from 'react-beautiful-dnd';

import './ReportingPanel.less';
import PropertyAdvancedFilter from 'common/components/grid-view/components/content-pane/advancedFilter/PropertyAdvancedFilter';
import QueryConditions from 'common/components/grid-view/components/content-pane/advancedFilter/QueryConditions';
import DataColumns from 'pages/reporting/components/DataColumns';
import {
  changeValueDataType,
  mappingPrimaryToFieldsName,
} from 'pages/reporting/utils';
import { useAdvanceFilter } from 'common/components/nested-advance-Search/hook/hook';

const infoColProps = {
  infoColProps: {
    flex: 'auto',
  },
  labelColProps: {
    flex: '130px',
  },
  boldInfo: true,
  labelAlign: 'right',
};

const ReportingPanel = ({ dataDetail }) => {
  const image =
    (dataDetail?.advType === 'DigitalMedia' && AssetReport) ||
    (dataDetail?.advType === 'Member' && MemberReport) ||
    (dataDetail?.advType === 'ProductItem' && ProductReport);

  const [propertiesData, setPropertiesData] = useState();
  const { fetchEntityAttribute } = useAdvanceFilter({});
  const emptyFunc = () => {};

  const decodeJson = dataDetail?.query ? JSON.parse(dataDetail?.query) : {};

  const query = mappingPrimaryToFieldsName(decodeJson);

  const entityType = changeValueDataType(dataDetail?.advType);

  const handleGetEntity = async () => {
    try {
      const data = await fetchEntityAttribute(entityType);
      if (data) {
        setPropertiesData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!propertiesData) handleGetEntity();
  }, [entityType, propertiesData]);

  return (
    <Row className='reporting-panel' style={{ margin: 8 }}>
      <Row
        align='bottom'
        className='reporting-panel__main'
        style={{ width: '100%', marginTop: 37 }}
      >
        <Col span={7}>
          <Image
            src={image}
            width={100}
            height={100}
            style={{ pointerEvents: 'none' }}
          ></Image>
        </Col>
        <Col span={17}>
          <Typography.Title
            style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}
          >
            {/* <FavoriteStarIconRender
              isFavorited={shortDetail?.isFavorited}
              style={{ marginRight: '6px' }}
            /> */}
            {dataDetail?.name}
          </Typography.Title>
        </Col>
      </Row>
      <Row className='reporting-panel__des'>
        <Col span={24}>
          <InfoWithLabel
            info={dataDetail?.description}
            label={'Description'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <Row className='reporting-panel__body'>
        <Col span={12}>
          <InfoWithLabel
            info={dataDetail?.ownerFullName}
            label={'Owner'}
            {...infoColProps}
          />
        </Col>
        <Col span={12}>
          <InfoWithLabel
            info={dataDetail?.created && formatMDY(dataDetail?.created)}
            label={'Date Created'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <Row className='reporting-panel__body'>
        <Col span={12}>
          <InfoWithLabel
            info={!dataDetail?.editable ? 'No' : 'Yes'}
            label={'Editable'}
            {...infoColProps}
          />
        </Col>
        <Col span={12}>
          <InfoWithLabel
            info={
              dataDetail?.lastModified && formatMDY(dataDetail?.lastModified)
            }
            label={'Last Updated'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <Row className='reporting-panel__body'>
        <Col span={12}>
          <InfoWithLabel
            info={!dataDetail?.shareable ? 'No' : 'Yes'}
            label={'Shareable'}
            {...infoColProps}
          />
        </Col>
        <Col span={12}>
          <InfoWithLabel
            info={dataDetail?.dateShared && formatMDY(dataDetail?.dateShared)}
            label={'Date Shared'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <DragDropContext>
        <Row className='reporting-panel__body' style={{ marginTop: 8 }}>
          <Col
            span={24}
            className='reporting-panel__list'
            style={{ marginBottom: 8 }}
          >
            <AdvancedSearchTitle title={'Data Columns'} />
            <DataColumns
              viewMode='view'
              entityType={entityType}
              dataColumns={query?.columns}
              propertiesData={propertiesData}
              draggingTaskId={{}}
              selectedPropertyIds={[]}
              toggleSelection={() => {}}
              toggleSelectionInGroup={() => {}}
              handleRemoveDataColumns={() => {}}
            />
          </Col>
          <Col span={24} className='reporting-panel__list'>
            <AdvancedSearchTitle title={'Query Conditions'} />
            <QueryConditions
              viewMode='view'
              entityType={entityType}
              propertiesData={propertiesData}
              queryConditions={query?.advancedSearchContainer}
              handleConjunction={emptyFunc}
              handleQueryCondition={emptyFunc}
              handleRemoveQueryConditions={emptyFunc}
              PropertyAdvancedFilter={PropertyAdvancedFilter}
              queryConditionsId='panel-reporting'
            />
          </Col>
        </Row>
      </DragDropContext>
    </Row>
  );
};

ReportingPanel.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ReportingPanel;
