import React, { useState } from 'react';
import ReactCSS from 'reactcss';

import { Row, Col, Button, Typography, Space, Empty } from 'antd';

import {
  FormCancelButton,
  FormSaveButton,
  StyledModal,
  WithLoading,
} from 'common/components';

import { useQaTabChecker } from './EditTabCheckerContext';

import { sleep } from 'utils/delay';
import { mapTabKeyToName } from './utils';

const { Text } = Typography;

const styles = ReactCSS({
  default: {
    editItem: {
      borderRadius: 4,
      boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px`,
      padding: '4px 6px',
      marginBottom: 6,
    },
    viewBtn: {
      borderRadius: 3,
    },
    empty: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

function EditTabConfirmModal(props) {
  const { onChangeTab, onCancel, tempActiveTab, ...restProps } = props;

  const [loading, setLoading] = useState(false);

  const {
    editItemListArray,
    forceSaveItem,
    forceCancelItem,
    forceChangeTab,
  } = useQaTabChecker();

  const focusEditItem = async (itemId, tabName) => {
    forceChangeTab(itemId, tabName);
    await sleep(200);
    const $item = document.getElementById(itemId);

    if ($item) {
      $item.scrollIntoView();
    }

    onCancel();
  };

  const modalProps = {
    title: 'Review Editing Item(s)',
    bodyStyle: {
      height: 255,
    },
    width: 700,
    onCancel,
    ...restProps,
  };

  const handleSaveEditItem = async (itemId) => {
    if (!itemId) return;
    await forceSaveItem(itemId);
  };

  const handleCancelEditItem = (itemId) => {
    if (!itemId) return;
    forceCancelItem(itemId);
  };

  const cancelEachtem = async (editItem) => {
    const { id } = editItem?.[1];
    await sleep(200);
    handleCancelEditItem(id);
    await sleep(200);
  };

  const onCancelAll = async () => {
    setLoading(true);

    for (const editItem of editItemListArray) {
      await cancelEachtem(editItem);
    }

    setLoading(false);
  };

  const footerRender = (
    <div>
      <Space>
        {editItemListArray?.length > 0 && (
          <Button onClick={onCancelAll} danger loading={loading}>
            Cancel All
          </Button>
        )}
        <Button type='primary' onClick={onChangeTab}>
          Switch to '{mapTabKeyToName(tempActiveTab.current)}' Tab
        </Button>
      </Space>
    </div>
  );

  return (
    <StyledModal {...modalProps} footer={footerRender}>
      <WithLoading loading={loading}>
        {editItemListArray?.length === 0 ? (
          <Empty style={styles.empty} />
        ) : (
          <>
            {editItemListArray.map((item) => {
              const itemData = item?.[1] || {};
              const { name, id, tab } = itemData;

              return (
                <Row style={styles.editItem} align='middle'>
                  <Col flex='1'>
                    <Text strong>{name}</Text>
                  </Col>
                  <Col>
                    <Space>
                      <Button
                        style={styles.viewBtn}
                        type='danger'
                        onClick={() => focusEditItem(id, tab)}
                      >
                        View
                      </Button>
                      {!itemData?.hideSaveBtn && (
                        <FormSaveButton
                          onClick={() => handleSaveEditItem(id)}
                        />
                      )}
                      <FormCancelButton
                        onClick={() => handleCancelEditItem(id)}
                      />
                    </Space>
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </WithLoading>
    </StyledModal>
  );
}

export default EditTabConfirmModal;
