import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getDataFormula, getSnapshotDataFormula } from 'services/product';

export const queryQaFormulaKeys = {
  all: ({ productId }) => ['qa-spec', parseInt(productId), 'qa-formula'],
  detail: ({ productId, productType }) => {
    return productType
      ? ['qa-spec', parseInt(productId), 'qa-formula', productType]
      : ['qa-spec', parseInt(productId), 'qa-formula'];
  },
  snapshot: ({ productId, productType }) =>
    productType
      ? ['pim', 'snapshot', parseInt(productId), 'qa-formula', productType]
      : ['pim', 'snapshot', parseInt(productId), 'qa-formula'],
};

const useQueryQaFormulaData = ({ productId, productType, ...options } = {}) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: queryQaFormulaKeys.detail({ productId, productType }),
    queryFn: async () => {
      const response = await getDataFormula({ productId });
      const { isSuccess, data, message } = response || {};
      if (isSuccess) {
        return data;
      } else {
        return Promise.reject(message || 'Server Error');
      }
    },
    ...options,
  });

  const invalidateQaFormulaData = ({ productId, productType } = {}) => {
    const key = productId
      ? queryQaFormulaKeys.detail({ productId, productType })
      : queryQaFormulaKeys.all({ productId });
    queryClient.invalidateQueries({
      queryKey: key,
    });
  };

  return { ...query, invalidateQaFormulaData };
};

const useQueryQaFormulaSnapshotData = ({
  productId,
  productType,
  ...options
} = {}) => {
  return useQuery({
    queryKey: queryQaFormulaKeys.snapshot({ productId, productType }),
    queryFn: async () => {
      const response = await getSnapshotDataFormula({ productId });
      const { isSuccess, data, message } = response || {};
      if (isSuccess) {
        return data;
      } else {
        return Promise.reject(message || 'Server Error');
      }
    },
    ...options,
  });
};

export { useQueryQaFormulaData, useQueryQaFormulaSnapshotData };
