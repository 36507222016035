import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';

import ProductCategoryHierarchy from './ProductCategoryHierarchy';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { StyledModal, WithLoading } from 'common/components';
import { useFilterGrid } from 'hooks/useFilterGrid';
import { useGetProductCategoryTreeView } from 'pages/company-profile/components/tabs/system/category-management/hooks/useProductCategoryManagement';

import { useUserInfo } from 'hooks';
import ProductBrickCodeHierarchy from './ProductBrickCodeHierarchy';

const FilterProductCategoryModal = ({ entityType, visible, setVisible }) => {
  const dispatch = useDispatch();

  const [hierarchyFormValue, setHierarchyFormValue] = useState(null);
  const [isPrefetching, setIsPrefetching] = useState(true);

  const { gridFilters, updateGridFilter } = useFilterGrid({
    entityType,
  });

  const { userInfo } = useUserInfo();
  const currentMemberId = userInfo?.member.id;

  const { nestedCategory, isLoading } = useGetProductCategoryTreeView(
    currentMemberId,
    visible,
    isPrefetching,
    setIsPrefetching
  );

  const handleFilterProducts = (filter) => {
    dispatch(actionsGridView.resetPageCurrent());

    if (gridFilters.indexOf(filter) > -1) {
      updateGridFilter(gridFilters.filter((val) => val !== filter));
    } else {
      let newFilterProducts = [...gridFilters];

      newFilterProducts.push(filter);

      updateGridFilter(newFilterProducts);
    }
  };

  const getAndFilterBrickCode = (value) => {
    const filter = [];
    const params = {
      FieldName: 'brickCode',
      filterType: 'In',
      values: [value.BrickCode],
    };
    filter.push(params);
    handleFilterProducts('brickCodeHierarchy');
    _.set(window, ['brickCodeHierarchy'], filter);
  };

  const getAndFilterCategory = (categoryValue) => {
    const filter = [];
    for (const [key, value] of Object.entries(categoryValue)) {
      if (!value) break;
      const params = {
        FieldName: `${key}Name`,
        filterType: 'Equal',
        value,
      };
      filter.push(params);
    }

    handleFilterProducts('productCategory');
    _.set(window, ['productCategory'], filter);
  };

  const shouldRenderProductCategory = nestedCategory?.length > 0;

  const filterProductHierarchy = () => {
    if (shouldRenderProductCategory) getAndFilterCategory(hierarchyFormValue);
    else getAndFilterBrickCode(hierarchyFormValue);
    setVisible(false);
  };

  const cancelModal = () => {
    setVisible(false);
    setHierarchyFormValue(null);
  };

  let title = shouldRenderProductCategory
    ? 'Select Category'
    : 'Select Brick Code';

  const modalProps = {
    title,
    visible,
    onCancel: cancelModal,
    width: '1150px',
    bodyStyle: { maxHeight: '670px', height: '66vh' },
    forceRender: true,
    destroyOnClose: true,
    footer: (
      <>
        <Button onClick={cancelModal}>Cancel</Button>
        <Button
          type='primary'
          disabled={!hierarchyFormValue}
          onClick={filterProductHierarchy}
        >
          OK
        </Button>
      </>
    ),
  };

  return (
    <StyledModal {...modalProps}>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <WithLoading loading />
        </div>
      ) : (
        <>
          {shouldRenderProductCategory ? (
            <ProductCategoryHierarchy
              setHierarchyFormValue={setHierarchyFormValue}
              nestedCategory={nestedCategory}
              isLoading={isLoading}
              key={visible}
            />
          ) : (
            <ProductBrickCodeHierarchy
              visible={visible}
              getBrickCode={setHierarchyFormValue}
              initialBrickCode={hierarchyFormValue?.brickCode}
              key={visible}
            />
          )}
        </>
      )}
    </StyledModal>
  );
};

export default FilterProductCategoryModal;
