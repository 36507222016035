import React from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/common';

const FormButton = ({ text, style, type, ...otherProps }) => {
  return (
    <Button
      type={type || 'primary'}
      style={{ borderRadius: '4px', ...style }}
      icon={<PlusCircleOutlined />}
      {...otherProps}
    >
      {text === null ? null : (
        <span className='form-btn__text'>
          {text || <FormattedMessage {...Messages.addBtn} />}
        </span>
      )}
    </Button>
  );
};

export default FormButton;
