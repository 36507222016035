import React from 'react';

import { Divider, Space } from 'antd';

import EmailEditorFontSetting from './EmailEditorFontSetting';
import EmailEditorShowHideSettings from './EmailEditorShowHideSettings';

const EmailEditorShippingInfoSettings = () => {
  return (
    <Space direction='horizontal' size={0}>
      <EmailEditorFontSetting />
      <Divider type='vertical' style={{ margin: '0 4px' }} />
      <EmailEditorShowHideSettings
        type='tag'
        options={[
          'shippingName',
          'shippingCompany',
          'shippingAddress1',
          'shippingAddress2',
        ]}
      />
    </Space>
  );
};

export default EmailEditorShippingInfoSettings;
