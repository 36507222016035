import React from 'react';

import classnames from 'classnames';

import './styles.less';

const RoleSectionWrapper = (props) => {
  const { children, title, className, ...restProps } = props;
  return (
    <div
      className={classnames('role-section__wrapper', className)}
      {...restProps}
    >
      {title && <div className='role-section__header'>{title && title}</div>}
      {children}
    </div>
  );
};

export default RoleSectionWrapper;
