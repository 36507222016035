import { useQuery } from '@tanstack/react-query';

export const useGetProductHeaderBackground = ({ productId }) => {
  return useQuery({
    queryKey: [
      'pim',
      'get-product-background-header',
      { params: { productId } },
    ],
  });
};
