import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as qaSpecServices from 'services/qaSpec';

export const getContaminantCharacteristicsKey = (productId) => {
  return ['qa-spec', parseInt(productId), 'qa-contaminant-characteristics'];
};

export const useContaminantCharacteristicsQuery = ({ productId }) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery({
    queryKey: getContaminantCharacteristicsKey(productId),
    queryFn: async () => {
      return await qaSpecServices.getQaSpecContaminantCharacteristic({
        productId,
      });
    },
    enabled: Boolean(productId),
  });

  const handleRefetchContaminantCharacteristics = () => {
    queryClient.invalidateQueries({
      queryKey: getContaminantCharacteristicsKey(productId),
    });
  };

  return {
    contaminantCharacteristicsData: data?.data,
    handleRefetchContaminantCharacteristics,
    ...rest,
  };
};
