import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useGetProductEnums, useGetProductFullView } from 'hooks';

import * as productFullViewSelectors from 'pages/product-full-view/controllers/selectors';

import {
  ProductOverviewMetadata,
  PackageContent,
} from 'pages/product-full-view/components';

import { useGetProductSchema } from 'hooks';

import './qaSpecDetail.less';

const QaSpecDetailSection = ({ productItemModules, productId }) => {
  const refContainer = useRef(null);

  const [visible, setVisible] = useState(false);

  const currentHierarchyShortDetails = useSelector(
    productFullViewSelectors.makeSelectCurrentHierarchyShortDetails()
  );

  const productItems = currentHierarchyShortDetails?.data?.productItems;

  const { productFull } = useGetProductFullView({ productId });
  const { productSchema } = useGetProductSchema();
  const { productEnums } = useGetProductEnums();

  return (
    <>
      <div className='qa-spec__info'>
        <ProductOverviewMetadata
          refContainer={refContainer}
          product={productFull}
          productItemModules={productItemModules}
          productSchema={productSchema}
          productEnums={productEnums}
          showPackageDrawer={() => setVisible(true)}
        />

        {visible && (
          <PackageContent
            detail={currentHierarchyShortDetails?.data}
            onClose={() => setVisible(false)}
            visible={visible}
            productItems={productItems}
          />
        )}
      </div>
    </>
  );
};

export default QaSpecDetailSection;
