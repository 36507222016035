/*
 * Login Messages
 *
 * This contains all the text for the login component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.recipients-fields';

export default defineMessages({
  createField: {
    id: `${scope}.components.createField`,
    defaultMessage: 'Create',
  },
  editField: {
    id: `${scope}.components.editField`,
    defaultMessage: 'Edit',
  },
  deleteFields: {
    id: `${scope}.components.deleteFields`,
    defaultMessage: 'Delete',
  },
});
