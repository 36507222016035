import React from 'react';
import { Divider } from 'antd';

import { useSelector } from 'react-redux';

import { RibbonBar, RibbonDivider } from 'common/components';
import OpenSection from './components/sections/reporting/grid/OpenSection';
import DeleteReportSection from './components/sections/reporting/grid/DeleteReportSection';

import Addition from './components/sections/home-grid/AdditionSection';
import ShareSection from './components/sections/shared/ShareSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import gridSelectors from 'common/components/grid-view/controllers/selectors';
import BackControl from './components/controls/home/Back';

import * as ribbonSelectors from 'redux/ribbon/selectors';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

// const productsForMember = 'products/member';
// const assetsForMember = 'assets/member';

const Reportings = () => {
  // const pathname = window.location.pathname;
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );
  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  // const isBackToMemberProfile =
  //   pathname.includes(productsForMember) || pathname.includes(assetsForMember);
  // const isReportingsBack = pathname.includes('/reporting');

  const isDisabledCloseDetail =
    window.location.search !== '?query=1' ||
    closeDetailStack?.name !== 'query' ||
    closeDetailStack?.stack.length < 2;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.REPORTING}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.REPORTING}>
        <ViewLayout />
      </Can>

      <RibbonDivider />
      <Addition
        selectedItemList={selectedItemList}
        disabledFavorite={true}
        type='reporting'
      />
      <ShareSection selectedItemList={selectedItemList} />
      <RibbonDivider />
      <OpenSection selectedItemList={selectedItemList} />
      <RibbonDivider />
      <DeleteReportSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.REPORTING}>
        <AdvanceStack isNested selectedItemList={selectedItemList} />
      </Can>
      <BackControl
        ribbonSide='right'
        isDisabled={isDisabledCloseDetail}
        // isBackToMemberProfile={isBackToMemberProfile}
        // isReportingsBack={isReportingsBack}
      />
    </RibbonBar>
  );
};

export default Reportings;
