import React, { useEffect, useMemo, useState } from 'react';
import { Tree } from 'antd';
import classnames from 'classnames';
import { DownOutlined } from '@ant-design/icons';

import useCustomScroll from 'hooks/useCustomScroll';
import { useRenderTree } from 'pages/reporting/hook/renderTree';

const nameClass = 'advanced-filter';

const QueryConditions = React.memo((props) => {
  useCustomScroll(`.${nameClass}`);
  const {
    viewMode,
    draggingTaskId,
    queryConditions,
    propertiesData,
    queryConditionsId = 'advanced-filter',
    entityType,
    ...restProp
  } = props;

  const [keyCollapse, setKeyCollapse] = useState([]);

  const { mappingTreeTitle } = useRenderTree({
    propertiesData,
    entityType,
  });

  const mappingTreeData = useMemo(
    () =>
      mappingTreeTitle({
        viewMode,
        queryConditionsId,
        queryConditions,
        ...restProp,
      }),
    [JSON.stringify(queryConditions), JSON.stringify(propertiesData), viewMode]
  );

  const onExpand = (key, { node }) => {
    let cloneKeyCollapse = [...(keyCollapse ?? [])];

    if (cloneKeyCollapse.includes(node?.key)) {
      cloneKeyCollapse = cloneKeyCollapse.filter((item) => item !== node?.key);
    } else {
      cloneKeyCollapse.push(node?.key);
    }
    setKeyCollapse(cloneKeyCollapse);
  };

  useEffect(() => {
    if (mappingTreeData) setKeyCollapse(mappingTreeData?.collapseKey);
  }, [JSON.stringify(queryConditions), mappingTreeData]);

  return (
    <>
      <div
        className={classnames({
          'query-conditions scroller-extend': true,
        })}
        id={'advanced-filter'}
      >
        {queryConditions?.children?.length > 0 && (
          <Tree
            showLine={{ showLeafIcon: false }}
            switcherIcon={<DownOutlined />}
            treeData={[mappingTreeData].slice()}
            expandedKeys={keyCollapse}
            onExpand={onExpand}
            selectable={false}
          />
        )}
      </div>
    </>
  );
});

export default QueryConditions;
