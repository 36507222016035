import React from 'react';

import { Badge, Image } from 'antd';

import classnames from 'classnames';

import SandClockImage from 'assets/navigation/sand-clock.png';

export const FilterIndicator = ({
  onClickAction,
  isGdsnReceivedProducts,
  absoluteRight,
  absoluteTop,
  actionData,
  children,
}) => {
  return (
    <div
      className='home-nav-action-group'
      style={{ right: absoluteRight || 0, top: absoluteTop || 0 }}
    >
      {/* <Link className='home-nav-action-group__link' to={link + '/new'}> */}
      <span
        className={classnames({
          'home-nav-action-group__link': true,
          'home-nav-action-group__link--pending': isGdsnReceivedProducts,
        })}
        onClick={onClickAction}
      >
        <Badge
          count={typeof actionData === 'number' ? actionData : 0}
          offset={[5, 4]}
          overflowCount={99}
          size='small'
        >
          {isGdsnReceivedProducts ? (
            <Image
              className={classnames(
                'home-nav-action-group__link-tag',
                'home-nav-action-group__link-tag--pending'
              )}
              src={SandClockImage}
              width={21}
              preview={false}
            />
          ) : (
            <>{children}</>
          )}
        </Badge>
      </span>
    </div>
  );
};
