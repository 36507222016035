import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RibbonButton } from 'common/components';
import { FilterOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';

const ToggleSsoApprovalUser = ({ approvalSubject }) => {
  const dispatch = useDispatch();
  const subjectInfo = {
    user: {
      toggleAction: 'toggleFilterPendingSsoUser',
      selector: 'selectToggleSsoUserPending',
    },
    member: {
      toggleAction: 'toggleFilterPendingSsoMember',
      selector: 'selectToggleSsoMemberPending',
    },
  };
  const toggleSsoApproval = useSelector(
    globalSelectors[subjectInfo[approvalSubject].selector]()
  );
  const label =
    toggleSsoApproval?.length > 0
      ? Messages.allRequests
      : Messages.pendingRequests;

  const isToggle = toggleSsoApproval?.length > 0 ? true : false;

  const togglePending = () => {
    let params = [];
    if (!isToggle) {
      params = [
        {
          fieldName: 'poolStatus',
          values: ['Pending'],
          filterType: 'In',
        },
      ];
    }
    dispatch(
      globalActions[subjectInfo[approvalSubject].toggleAction](params)
    );
  };
  return (
    <>
      <RibbonButton
        icon={<FilterOutlined />}
        label={label}
        onClick={() => togglePending()}
      />
    </>
  );
};

export default ToggleSsoApprovalUser;
