import React from 'react';

import { useEditor, useNode } from '@craftjs/core';
import classnames from 'classnames';

const EmailEditorContentElement = (props) => {
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { selected } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const { freeTyping, content, style, editable = true } = props;

  const isEditable = enabled && freeTyping;

  return (
    <div
      className={classnames({
        'email-editor__section-not-editable': selected && !editable,
      })}
      contentEditable={isEditable}
      style={{ marginBottom: 4, outline: 'none', ...style }}
      //* hide warning
      suppressContentEditableWarning={true}
    >
      {content}
    </div>
  );
};

export default EmailEditorContentElement;
