import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ChangePasswordForm from '../form/ChangePasswordForm';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/user-profile.js';

import './ChangePasswordModal.less';

const ChangePasswordModal = (props) => {
  const {
    visible,
    handleOk,
    handleCancel,
    form,
    loadingModal,
    strengthTypeMode,
    errorMessage,
  } = props;

  return (
    <Modal
      bodyStyle={{ paddingBottom: 0 }}
      title={<FormattedMessage {...Messages.changePassword} />}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      className='change-password-modal'
      maskClosable={false}
    >
      <ChangePasswordForm
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        loadingModal={loadingModal}
        strengthTypeMode={strengthTypeMode}
        errorMessage={errorMessage}
      />
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  // visible: show or hide modal
  visible: PropTypes.bool.isRequired,
  // loadingModal: to set loading button
  loadingModal: PropTypes.bool.isRequired,
  // strengthTypeMode: strength password type: any, weak, medium, strong
  strengthTypeMode: PropTypes.string.isRequired,
};

export default ChangePasswordModal;
