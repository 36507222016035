import React from 'react';

import {
  Button,
  Dropdown,
  Menu,
  Typography,
  Image,
  Col,
  Tag,
  Space,
  Result,
} from 'antd';

import unitImage from 'assets/product/unit.png';
import innerpackImage from 'assets/product/innerpack.png';
import caseImage from 'assets/product/case.png';
import masterCaseImage from 'assets/product/master-case.png';
import palletImage from 'assets/product/pallet.png';
import { QuestionCircleFilled, WarningOutlined } from '@ant-design/icons';
import * as getLink from 'utils/common/linkBuilder';

import './ProductPackLevel.less';

const { Title, Text } = Typography;

const packageDef = [
  { name: 'unit', image: unitImage, imgWidth: 25 },
  { name: 'inner pack', image: innerpackImage, imgWidth: 80 },
  { name: 'case', image: caseImage, imgWidth: 80 },
  { name: 'display shipper', image: caseImage, imgWidth: 80 },
  { name: 'master case', image: masterCaseImage, imgWidth: 62, imgHeight: 62 },
  { name: 'pallet', image: palletImage, imgWidth: 80 },
];

const ProductPackLevel = React.memo((props) => {
  const { packLevel, id } = props;
  const DropdownContentRender = () => {
    return (
      <Menu className='pd-pack-lvl__drop-down' style={{ padding: '4px' }}>
        <Menu.Item>
          <div className='pd-pack-lvl__drop-down-header'>
            {packLevel ? (
              <Space>
                <Title level={5} style={{ fontSize: '13px', margin: 0 }}>
                  Package Level:
                </Title>

                <Tag color='#108ee9'>
                  {packLevel ? packLevel.toUpperCase() : ''}
                </Tag>
              </Space>
            ) : (
              <Result status='error' subTitle='Package Not found' />
            )}
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const getPackLevelDef = (packLevel) => {
    if (!packLevel) return;
    const foundPackage = packageDef.find(
      (packItem) => packItem?.name === packLevel.toLowerCase()
    );

    if (foundPackage) return foundPackage;
    return { icon: QuestionCircleFilled };
  };

  const foundPackLevelDef = getPackLevelDef(packLevel);

  return (
    <div className='pd-pack-lvl'>
      <Dropdown overlay={DropdownContentRender} placement='bottomRight'>
        <Button className='pd-pack-lvl__btn'>
          {foundPackLevelDef && foundPackLevelDef?.image ? (
            getLink.productLink(
              id,
              <Image
                className='pd-pack-lvl__btn-icon'
                src={foundPackLevelDef?.image}
                preview={false}
              />
            )
          ) : (
            <WarningOutlined />
          )}
        </Button>
      </Dropdown>
    </div>
  );
});

export default ProductPackLevel;
