import React, { useEffect, useState } from 'react';

import { useNode } from '@craftjs/core';

import EmailEditorPlaceholder from './EmailEditorPlaceholder';

import { useEmailEditorContext } from '../../controllers/context';

import { EVENT } from 'static/Constants';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const EmailEditorTag = (props) => {
  const intl = useIntl();
  const { dynamicContent, setInvalidTag } = useEmailEditorContext();
  const { selected } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const { tag } = props;

  const [isError, setIsError] = useState(false);

  const tagData = dynamicContent[tag];
  const value = tagData?.value;
  const isRequired = tagData?.required;
  const isVisible = tagData?.visible;
  const isShow = tagData?.show;
  const label = intl.formatMessage(Messages[tag]);

  const validateTagValue = () => {
    if (!isRequired) return;
    setInvalidTag((prevState) => {
      let newState = [...prevState];

      if (value || !isVisible || !isShow)
        newState = newState.filter((tagItem) => tagItem !== tag);
      else newState.push(tag);

      return newState;
    });
  };

  const onErrorTag = (e) => {
    const invalidTag = e.detail;

    if (invalidTag.includes(tag)) setIsError(true);
  };

  useEffect(() => {
    validateTagValue();
  }, [value, isVisible, isShow]);

  useDocumentAttachEvent({
    name: EVENT.EMAIL_EDITOR_HIGHLIGHT_ERROR_TAG,
    handler: onErrorTag,
  });

  //* return nothing when hidden
  if (!isVisible) return null;

  return value ? (
    <span
      contentEditable={false}
      style={selected ? { backgroundColor: '#e6f7ff' } : null}
    >
      {' '}
      {value}
    </span>
  ) : (
    <EmailEditorPlaceholder what={label} isInvalidTag={isError} />
  );
};

export default EmailEditorTag;
