import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

import RibbonButton from 'common/components/button/RibbonButton';
import ProductSubscriptionModal from 'common/components/product-subscription/ProductSubscriptionModal';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import userSelectors from 'redux/user/selectors';

const SubscriptionControl = ({entityType}) => {
  const intl = useIntl();
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [visibleSubscriptionModal, setVisibleSubscriptionModal] = useState(
    false
  );
  const useVisibleModal = [
    visibleSubscriptionModal,
    setVisibleSubscriptionModal,
  ];

  const myMemberId = useSelector(userSelectors.makeSelectUserMemberId());
  let memberIds = useSelector(gridSelector.makeSelectItemsSelection());

  useEffect(() => {
    if (memberIds?.length === 1 && checkNotMyMember(memberIds[0])) {
      setEnabledBtn(true);
    } else {
      setEnabledBtn(false);
    }
  }, [JSON.stringify(memberIds)]);

  const checkNotMyMember = (id) => !(id === myMemberId);

  return (
    <>
      <RibbonButton
        size={ entityType === 'MEM' ? 'small' : 'large' }
        icon={<MenuUnfoldOutlined style={{ height: 26 }} />}
        label={Messages.pimSubscribe}
        onClick={() => setVisibleSubscriptionModal(true)}
        disabled={!enabledBtn}
      />

      {visibleSubscriptionModal && (
        <ProductSubscriptionModal
          useVisibleModal={useVisibleModal}
          type='Member'
          modalTitle={intl.formatMessage(Messages.subscribeMember)}
          id={memberIds?.length === 1 && memberIds[0]}
        />
      )}
    </>
  );
};

SubscriptionControl.propTypes = {};

export default SubscriptionControl;
