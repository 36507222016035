import React from 'react';
import { Divider } from 'antd';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import { RibbonBar, RibbonDivider } from 'common/components';
import EditInfoMetadata from './components/controls/product-apl/EditInfoMetadata';
import ExecuteMatching from './components/controls/product-apl/ExecuteMatching';
import MatchPercent from './components/controls/product-apl/MatchPercent';
import BackControl from './components/controls/home/Back';

import AplDetailActions from './components/sections/product-apl/AplDetailActions';
import AplDetailTemplate from './components/sections/product-apl/AplDetailTemplate';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const ProductAplDetail = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabled />
      </Can>

      <RibbonDivider />
      <AplDetailActions />

      <RibbonDivider />
      <EditInfoMetadata />
      <RibbonDivider />
      <ExecuteMatching />

      <RibbonDivider />
      <AplDetailTemplate />

      <RibbonDivider />
      <MatchPercent />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <AdvanceStack isDisabled={true} />
      </Can>
      <BackControl ribbonSide='right' />
    </RibbonBar>
  );
};

export default ProductAplDetail;
