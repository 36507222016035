/* eslint-disable default-case */
import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  error: false,
  folderOption: null,
  folders: [],
  total: 0,
  pageSize: 20,
  pageNumber: 1,
  search: '',
  ownedFolderPageSize: 20,
  ownedFolderPageNumber: 1,
  ownFolderSearch: '',
  sharedFolderPageSize: 20,
  sharedFolderPageNumber: 1,
  sharedFolderSearch: '',
  columns: [],
  gridName: '',
  deleteFolders: {
    statusDelete: 'idle',
    errorDelete: null,
  },

  copyFolder: {
    statusCopy: 'idle',
    errorCopy: null,
    visibleModal: false,
  },

  folderShortDetail: null,
  loadingDetail: false,
  reloadContentPane: false,
  memberPreview: null,
  memberLoading: false,
  assetLoading: false,
  assetPreview: null,
  downloadSetting: null,
  isEdit: false,
  startEdit: 'idle',
  editSuccess: 'idle',
  editError: null,
  configLoading: false,
  configError: null,
  publicContactList: null,

  folderDetails: {
    columnsGrid: [],
    pageSize: 20,
    pageNumber: 1,
    total: 0,
    loading: false,
    data: [],
  },
  queryLoading: false,
  queryPreview: false,
  reportingLoading: false,
  reportingPreview: false,

  productLoading: false,
  productPreview: false,

  selectedMapping: null,
  statusDownloadFolder: 'idle',

  folderDetailData: null,

  //
  folderByLevel: {
    loading: false,
    error: null,
    folders: [],
    myFolders: [],
    sharedFolders: [],
    folderBreadcrumb: [
      {
        id: types.ROOT_FOLDER,
        folderName: 'Home',
      },
    ],
    keepBreadcrumb: false,
    shouldKeepFolderBreadcrumb: false,
  }, // maybe cache in here
};

const folderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_FOLDER_GRID_COLUMN:
        draft.loading = true;
        draft.columns = [];
        break;
      case types.GET_FOLDER_GRID_COLUMN_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;
      case types.GET_FOLDER_GRID_COLUMN_ERROR:
        draft.loading = false;
        draft.columns = [];
        draft.error = action.error;
        break;
      case types.GET_FOLDER_GRID:
        draft.loading = true;
        draft.error = false;
        draft.folderOption = action.folderOption;
        draft.pageSize = action.pageSize;
        draft.pageNumber = action.pageIndex;
        draft.search = action['Search.SearchText'];

        draft['deleteFolders'] = {
          statusDelete: 'idle',
          errorDelete: null,
        };
        draft['copyFolder'] = {
          name: '',
          statusCopy: 'idle',
          errorCopy: null,
          visibleModal: false,
        };
        break;
      case types.GET_FOLDER_GRID_SUCCESS:
        draft.loading = false;
        draft.folders = action.folders;
        draft.total = action.total;
        break;
      case types.GET_FOLDER_GRID_ERROR:
        draft.loading = false;
        draft.total = 0;
        draft.folders = [];
        draft.error = action.error;
        break;

      case types.DELETE_FOLDERS:
        draft.loading = true;
        // draft['deleteFolders'].statusDelete = 'loading';
        break;
      case types.DELETE_FOLDERS_SUCCESS:
        draft['deleteFolders'].statusDelete = 'success';
        draft.folderShortDetail = null;
        break;
      case types.DELETE_FOLDERS_FAIL:
        draft['deleteFolders'].statusDelete = 'fail';
        draft['deleteFolders'].errorDelete = action.error;
        draft.loading = false;
        break;

      case types.TURN_ON_MODAL_COPY:
        draft['copyFolder'].statusCopy = 'idle';
        draft['copyFolder'].visibleModal = true;
        break;
      case types.TURN_OFF_MODAL_COPY:
        draft['copyFolder'].statusCopy = 'idle';
        draft['copyFolder'].visibleModal = false;
        break;

      case types.COPY_FOLDER:
        draft['copyFolder'].statusCopy = 'loading';
        break;
      case types.COPY_FOLDER_SUCCESS:
        draft['copyFolder'].statusCopy = 'success';
        break;
      case types.COPY_FOLDER_FAIL:
        draft['copyFolder'].statusCopy = 'fail';
        draft['copyFolder'].errorCopy = action.error;
        break;

      case types.GET_FOLDER_SHORT_DETAIL:
        draft.loadingDetail = true;
        draft.error = false;
        break;
      case types.GET_FOLDER_SHORT_DETAIL_SUCCESS:
        draft.loadingDetail = false;
        draft.folderShortDetail = action.data;
        break;
      case types.GET_FOLDER_SHORT_DETAIL_ERROR:
        draft.loadingDetail = false;
        draft.error = action.error;
        break;
      case types.GET_MEMBER_PREVIEW:
        draft.memberLoading = true;
        draft.error = false;
        break;
      case types.GET_MEMBER_PREVIEW_SUCCESS:
        draft.memberLoading = false;
        draft.memberPreview = action.data;
        break;
      case types.GET_MEMBER_PREVIEW_ERROR:
        draft.memberLoading = false;
        draft.error = action.error;
        break;
      case types.GET_DIGITAL_ASSET_PREVIEW:
        draft.assetLoading = true;
        draft.error = false;
        break;
      case types.GET_DIGITAL_ASSET_PREVIEW_SUCCESS:
        draft.assetLoading = false;
        draft.assetPreview = action.data;
        break;
      case types.GET_DIGITAL_ASSET_PREVIEW_ERROR:
        draft.assetLoading = false;
        draft.error = action.error;
        break;
      // Download Setting
      case types.GET_DOWNLOAD_SETTING:
        draft.loadingDownloadSetting = true;
        draft.error = false;
        draft.downloadSetting = null;
        break;
      case types.GET_DOWNLOAD_SETTING_SUCCESS:
        draft.loadingDownloadSetting = false;
        draft.error = false;
        draft.downloadSetting = action.data;
        break;
      case types.GET_DOWNLOAD_SETTING_ERROR:
        draft.loadingDownloadSetting = false;
        draft.error = action.error;
        draft.downloadSetting = null;
        break;
      case types.TURN_EDIT_SHORT_CONTENT_ON:
        draft.isEdit = action.value;
        break;
      case types.EDIT_FOLDER_SHORT_CONTENT:
        draft.startEdit = 'starting';
        draft.editSuccess = 'idle';
        draft.editError = null;
        break;
      case types.EDIT_FOLDER_SHORT_CONTENT_SUCCESS:
        draft.startEdit = 'finish';
        draft.editSuccess = 'success';
        draft.reloadContentPane = true;
        break;
      case types.EDIT_FOLDER_SHORT_CONTENT_ERROR:
        draft.startEdit = 'finish';
        draft.editSuccess = 'error';
        draft.editError = action.error;
        break;
      case types.SET_RELOAD_FOLDER_CONTENT_PANE:
        draft.reloadContentPane = action.value;
        break;
      case types.EDIT_FOLDER_SHORT_CONTENT_FINISH:
        draft.startEdit = 'idle';
        draft.editSuccess = 'idle';
        draft.editError = null;
        break;
      case types.CONFIG_SCHEDULE_DOWNLOAD:
        draft.configLoading = true;
        break;
      case types.CONFIG_SCHEDULE_DOWNLOAD_SUCCESS:
        draft.configLoading = false;
        break;
      case types.CONFIG_SCHEDULE_DOWNLOAD_ERROR:
        draft.configLoading = false;
        draft.editError = action.error;
        break;
      case types.RESET_FOLDER_PAGING:
        draft.pageSize = 20;
        draft.pageNumber = 1;
        draft.search = '';
        break;
      case types.RESET_OWNED_FOLDER_PAGING:
        draft.ownedFolderPageSize = 20;
        draft.ownedFolderPageNumber = 1;
        draft.ownedFolderSearch = '';
        break;
      case types.RESET_SHARED_FOLDER_PAGING:
        draft.sharedFolderPageSize = 20;
        draft.sharedFolderPageNumber = 1;
        draft.sharedFolderSearch = '';
        break;
      case types.GET_OWNED_FOLDER_GRID:
        draft.loading = true;
        draft.error = false;
        draft.folderOption = action.folderOption;
        draft.ownedFolderPageSize = action.pageSize;
        draft.ownedFolderPageNumber = action.pageIndex;
        draft.ownedFolderSearch = action.search;

        draft['deleteFolders'] = {
          statusDelete: 'idle',
          errorDelete: null,
        };
        draft['copyFolder'] = {
          name: '',
          statusCopy: 'idle',
          errorCopy: null,
          visibleModal: false,
        };
        break;
      case types.GET_OWNED_FOLDER_GRID_SUCCESS:
        draft.loading = false;
        draft.folders = action.folders;
        draft.total = action.total;
        break;
      case types.GET_OWNED_FOLDER_GRID_ERROR:
        draft.loading = false;
        draft.total = 0;
        draft.folders = [];
        draft.error = action.error;
        break;
      case types.GET_SHARED_FOLDER_GRID:
        draft.loading = true;
        draft.error = false;
        draft.folderOption = action.folderOption;
        draft.sharedFolderPageSize = action.pageSize;
        draft.sharedFolderPageNumber = action.pageIndex;
        draft.sharedFolderSearch = action.search;

        // reset object
        draft['deleteFolders'] = {
          statusDelete: 'idle',
          errorDelete: null,
        };
        draft['copyFolder'] = {
          name: '',
          statusCopy: 'idle',
          errorCopy: null,
          visibleModal: false,
        };
        break;
      case types.GET_SHARED_FOLDER_GRID_SUCCESS:
        draft.loading = false;
        draft.folders = action.folders;
        draft.total = action.total;
        break;
      case types.GET_SHARED_FOLDER_GRID_ERROR:
        draft.loading = false;
        draft.total = 0;
        draft.folders = [];
        draft.error = action.error;
        break;
      case types.GET_PUBLIC_CONTACT_FOLDER:
        draft.loadingDetail = true;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_FOLDER_SUCCESS:
        draft.loadingDetail = false;
        draft.publicContactList = action.data;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_FOLDER_ERROR:
        draft.loadingDetail = false;
        draft.publicContactList = null;
        draft.error = action.error;
        break;

      case types.GET_COLUMNS_FOLDER_DETAILS_SUCCESS:
        draft['folderDetails'].columnsGrid = action.columns;
        break;

      case types.GET_FOLDER_DETAILS_GRID_THUMB:
        draft['folderDetails'].loading = true;
        break;

      case types.GET_FOLDER_DETAILS_GRID_THUMB_SUCCESS:
        draft['folderDetails'].loading = false;
        draft['folderDetails'].data = action.data.gridData;
        draft['folderDetails'].pageNumber = action.data.paging.currentPageIndex;
        draft['folderDetails'].pageSize = action.data.paging.currentPageSize;
        draft['folderDetails'].total = action.data.paging.totalRecord;
        break;

      case types.GET_QUERY_PREVIEW:
        draft.queryLoading = true;
        draft.error = false;
        break;
      case types.GET_QUERY_PREVIEW_SUCCESS:
        draft.queryLoading = false;
        draft.queryPreview = action.data;
        break;
      case types.GET_QUERY_PREVIEW_ERROR:
        draft.queryLoading = false;
        draft.error = action.error;
        break;
      case types.GET_REPORTING_PREVIEW:
        draft.reportingLoading = true;
        draft.error = false;
        break;
      case types.GET_REPORTING_PREVIEW_SUCCESS:
        draft.reportingLoading = false;
        draft.reportingPreview = action.data;
        break;
      case types.GET_REPORTING_PREVIEW_ERROR:
        draft.reportingLoading = false;
        draft.error = action.error;
        break;

      case types.GET_PRODUCT_PREVIEW:
        draft.productLoading = true;
        draft.error = false;
        break;
      case types.GET_PRODUCT_PREVIEW_SUCCESS:
        draft.productLoading = false;
        draft.productPreview = action.data;
        break;
      case types.GET_PRODUCT_PREVIEW_ERROR:
        draft.productLoading = false;
        draft.error = action.error;
        break;

      case types.SAVE_SELECTED_MAPPING:
        draft.selectedMapping = action.data;
        break;

      case types.SAVE_STATUS_DOWNLOAD_FOLDER:
        draft.statusDownloadFolder = action.data;
        break;

      case types.UPDATE_FOLDER_DETAIL_DATA:
        draft.folderDetailData = action.data;
        break;

      //
      case types.GET_FOLDER_GRID_BY_LEVEL:
        draft.folderByLevel.loading = true;
        draft.folderByLevel.error = null;
        // draft.folderByLevel.folderBreadcrumb = [
        //   {
        //     id: 'home',
        //     folderName: 'Home',
        //   },
        // ];
        break;

      case types.GET_FOLDER_GRID_BY_LEVEL_SUCCESS:
        const { folderOption, data, loading } = action;

        draft.folderByLevel.loading = loading ?? false;
        draft.folderByLevel.error = null;

        if (folderOption?.toLowerCase() === 'folders') {
          draft.folderByLevel.folders = data;
        } else if (folderOption?.toLowerCase() === 'myfolders') {
          draft.folderByLevel.myFolders = data;
        } else if (folderOption?.toLowerCase() === 'folderssharedtome') {
          draft.folderByLevel.sharedFolders = data;
        }
        break;

      case types.UPDATE_FOLDER_BREADCRUMB:
        const { breadcrumb } = action;
        draft.folderByLevel.folderBreadcrumb = breadcrumb;
        break;

      case types.KEEP_BREADCRUMB:
        draft.folderByLevel.keepBreadcrumb = action.payload;
        break;

      case types.UPDATE_SHOULD_KEEP_BREADCRUMB:
        draft.folderByLevel.shouldKeepFolderBreadcrumb = action.payload;
        break;

      default:
        break;
    }
  });

export default folderReducer;
