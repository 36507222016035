import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Typography, Input } from 'antd';
import { StyledModal, dialogFunction } from 'common/components';
import { CustomNotification } from 'common/components';

import * as globalActions from 'redux/global/actions';

import './MdEditInterEntity.less';

const { Title, Text } = Typography;

const MdEditInterEntity = (props) => {
  const {
    setEditItem,
    editItem,
    mdEditor,
    getPlaceholderList,
    isEntityInsideTable,
  } = props;
  const [text, setText] = useState('');

  const dispatch = useDispatch();

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const handleReplaceInterEntity = () => {
    if (!isEntityInsideTable) {
      if (!mdEditor) return;

      var anchor = { line: 0, ch: 0 };
      var head = { line: mdEditor.doc.lineCount() - 1, ch: null };
      var rangeSelect = [{ anchor, head }];
      mdEditor.setSelections(rangeSelect);
      mdEditor.doc.replaceSelection(
        mdEditor.doc.getValue().replaceAll(editItem, `[@[${text}]]`)
      );
      getPlaceholderList(mdEditor);
      CustomNotification.success('Change interactive data successfully');
    } else {
      dispatch(
        globalActions.updateMdTableAction({
          type: 'REPLACE',
          payload: {
            original: editItem,
            replacement: `[@[${text}]]`,
          },
        })
      );
    }
    setEditItem(null);
  };

  const handleReplaceInteractEntity = () => {
    dialogFunction({
      type: 'warn',
      content: (
        <div>
          <Title level={5}>Do you want to change this placeholder ?</Title>
          <Row>
            <Col xs={13} className='md-edit-inter-entity__confirm-left'>
              Original placeholder:
            </Col>
            <Col xs={11} className='md-edit-inter-entity__confirm-right'>
              <Text strong>{editItem}</Text>
            </Col>
          </Row>
          <Row>
            <Col xs={13} className='md-edit-inter-entity__confirm-left'>
              New placeholder:
            </Col>
            <Col xs={11} className='md-edit-inter-entity__confirm-right'>
              <Text strong>{`[@[${text}]]`}</Text>
            </Col>
          </Row>
        </div>
      ),
      okText: 'Replace',
      cancelText: 'Cancel',
      onOk: handleReplaceInterEntity,
    });
  };

  return (
    <StyledModal
      className='md-edit-inter-entity'
      title={'Edit Interact Entity Data'}
      visible={!!editItem}
      onCancel={() => {
        setEditItem && setEditItem(null);
      }}
      onOk={handleReplaceInteractEntity}
      okButtonProps={text ? { disabled: false } : { disabled: true }}
      cancelText={'Cancel'}
      maskClosable={false}
      width={600}
      destroyOnClose
    >
      <Row gutter={[10, 10]}>
        <Col flex='auto'>
          <Input onChange={handleChangeText} />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default MdEditInterEntity;
