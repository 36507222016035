import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getQaSpecDataContact,
  getQaSpecDataContactSnapshot,
} from 'services/qaSpec';

const qaSpecContactKeys = {
  detail: (id) => ['qa-spec', Number(id), 'qa-spec-contact'],
  snapshot: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-spec-contact',
  ],
};

export const getQaSpecContactKey = (id) => {
  return qaSpecContactKeys.detail(id);
};

const useGetQaSpecDataContact = ({ id, isEnabled }) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: getQaSpecContactKey(id),
    queryFn: async () => {
      const response = await getQaSpecDataContact({ productId: Number(id) });
      return response?.data;
    },
    enabled: isEnabled,
  });

  const refetchQaSpecDataContact = () => {
    queryClient.invalidateQueries({
      queryKey: qaSpecContactKeys.detail(id),
    });
  };

  return {
    qaSpecDataContact: data ?? {},
    loading: isLoading,
    refetchQaSpecDataContact,
  };
};

const useQueryQaContactDataSnapshot = ({ productId, ...options } = {}) => {
  return useQuery({
    queryKey: qaSpecContactKeys.snapshot(productId),
    queryFn: async () => {
      const response = await getQaSpecDataContactSnapshot({ productId });
      const { isSuccess, data, message } = response || {};
      if (isSuccess) {
        return data;
      } else {
        return Promise.reject(message || 'Server Error');
      }
    },
    ...options,
  });
};

export { useQueryQaContactDataSnapshot, useGetQaSpecDataContact };
