import React from 'react';
import { useDispatch } from 'react-redux';

import MemberSocialLink from 'common/components/social-link/SocialLink';
import { CustomNotification } from 'common/components';

import * as productServices from 'services/product';
import * as productActions from 'pages/product-full-view/controllers/actions';

import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/messages';

import * as _ from 'lodash';
import classnames from 'classnames';

import './ProductHeaderSocial.less';

const ProductHeaderSocial = (props) => {
  const {
    socialLink,
    heightSize,
    hasGS1SocialMedia,
    hasIxOneSocialMedia,
    showIxOneShield,
    isEdit,
    productId,
    setIsEdit,
    isDisplaySocialIconInline,
    intl,
  } = props;

  const dispatch = useDispatch();

  const showAddNewSuccessMessage = () => {
    CustomNotification.success(
      intl.formatMessage(Messages.addSocialLinkSuccessMessage)
    );
  };

  const showDeleteSuccessMessage = () => {
    CustomNotification.success(
      intl.formatMessage(Messages.deleteSocialLinkSuccessMessage)
    );
  };

  const callApiAddNewSocial = (newSocialLink) => {
    const data = { ...newSocialLink, productId };

    return productServices.addProductSocialMedia(data).then((response) => {
      if (response?.isSuccess) {
        showAddNewSuccessMessage();
        dispatch(productActions.getProductSocialMedia(productId));
        setIsEdit && setIsEdit(false);
      }
    });
  };

  const callApiDeleteSocialLink = (socialMediaId) => {
    const data = { socialMediaId, productId };

    return productServices.deleteProductSocialMedia(data).then((response) => {
      if (response?.isSuccess) {
        showDeleteSuccessMessage();
        dispatch(productActions.getProductSocialMedia(productId));
        setIsEdit && setIsEdit(false);
      }
    });
  };

  return (
    <div
      className={classnames('pd-hdr-socials', {
        'pd-hdr-socials--50': heightSize === 50,
        'pd-hdr-socials--hide':
          !isEdit &&
          _.isEmpty(socialLink) &&
          !hasGS1SocialMedia &&
          (!hasIxOneSocialMedia || !showIxOneShield),
        'social-icon-view-mode': !isEdit && !isDisplaySocialIconInline,
        'social-icon-view-mode-inline': !isEdit && isDisplaySocialIconInline,
        'social-icon-view-mode-inline--50':
          !isEdit && isDisplaySocialIconInline && heightSize === 50,
        'social-icon-edit-mode': isEdit,
      })}
    >
      <MemberSocialLink
        socialLinks={socialLink}
        showFullIcon={true}
        maxItem={7}
        hasGS1SocialMedia={hasGS1SocialMedia}
        hasIxOneSocialMedia={hasIxOneSocialMedia}
        editable={isEdit}
        addNewSocialLink={callApiAddNewSocial}
        deleteSocialLink={callApiDeleteSocialLink}
        showIxOneShield={showIxOneShield}
      />
    </div>
  );
};

export default injectIntl(ProductHeaderSocial);
