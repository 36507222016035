import React from 'react';

import { Modal } from 'antd';

import CreateProductForm from 'common/components/product-add/CreateProductForm';
import CreateProductModalFooter from 'common/components/product-add/CreateProductModalFooter';

import useCreateProduct from 'hooks/createProductHooks';

import { useIntl } from 'react-intl';
import messages from 'i18n/messages/product';
import { ERROR_FIELD_NAME_COLLECTION } from './constants';

const UpdateMinimumRequirementProductField = (props) => {
  const intl = useIntl();

  const { item, visible, setVisible, productDetail } = props;

  const reloadPage = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const { submitForm, formLoading, resetFormState, ...createProductFormProps } =
    useCreateProduct({
      modalVisible: visible,
      productDetail,
      reloadPage,
      isEdit: productDetail?.productId,
      callApiCallback: handleCancel,
      productId: productDetail?.productId,
    });

  const handleOk = () => {
    submitForm();
  };

  const errorFieldNameCollection = {
    product_Brand: ERROR_FIELD_NAME_COLLECTION.BRAND,
    product_UpcGtin: ERROR_FIELD_NAME_COLLECTION.AT_LEAST_GTIN_UPC,
  };

  const errorInfo = [
    {
      name: [errorFieldNameCollection[item.id]],
      errors: [intl.formatMessage(messages.minimumRequirementMessage)],
    },
  ];

  return productDetail?.productId ? (
    <Modal
      visible={visible}
      width={'85vw'}
      centered
      wrapClassName='styled-modal document-modal'
      title='Minimum Requirement'
      onCancel={handleCancel}
      footer={
        <CreateProductModalFooter
          onCancel={handleCancel}
          onOk={handleOk}
          okLoading={formLoading}
        />
      }
    >
      <CreateProductForm {...createProductFormProps} errorInfo={errorInfo} />
    </Modal>
  ) : null;
};

export default UpdateMinimumRequirementProductField;
