import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { Row, Col, Tooltip, Button, Tabs, Typography } from 'antd';
import { get } from 'lodash';
import { EditOutlined, StarFilled } from '@ant-design/icons';

import { ProfileAddress } from 'common/components';
import UserContactInfo from './UserContactInfo';
import { injectIntl } from 'react-intl';
import IconList from 'common/components/icon-list/IconList';
import MemberPanelContact from './MemberPanelContact';
import ProductSubscription from 'common/components/thumb/product/ProductSubscription';
import Image from 'common/components/image/Image';
import { formatMDY } from 'utils/formatDate';
import { Images } from 'config/assets';

import MemberTagLine from './MemberTagLine';
import './MemberPanel.less';
import { BackgroundEdit } from 'common/components';
import MemberPanelGdsnList from './MemberPanelGdsnList';
import gs1Img from 'assets/gs1.png';

import GoogleMapViewModal from 'common/components/google-map-modal/GoogleMapViewModal';
import classnames from 'classnames';

import { useWindowSize } from 'hooks/windowSize';

import { useCheckEditkMemberFullAndMyCompany } from 'hooks/useCheckAllowHooks';

import { parseJSON } from 'utils';

const { Text } = Typography;

const MemberPanel = (props) => {
  const {
    intl,
    memberShortDetail,
    style,
    changeEditType,
    isPreview,
    mdTagline,
    previewBg,
    editType,
    publicContactList,
    bgrPreviewColor,
    textPreviewColor,
    isEditColor,
    refSource,
    setBackgroundRatio,
  } = props;

  const memberPreviewBackground = useMemo(() => {
    const {
      alternativeBackgroundConfiguration,
      alternativeCompanyBackgroundUrl,
    } = memberShortDetail || {};

    return {
      memberPreviewBackgroundConfig: alternativeBackgroundConfiguration
        ? parseJSON(alternativeBackgroundConfiguration)
        : null,
      memberPreviewBackgroundUrl: alternativeCompanyBackgroundUrl,
    };
  }, [memberShortDetail]);

  const { memberPreviewBackgroundUrl, memberPreviewBackgroundConfig } =
    memberPreviewBackground;

  const [screenWidth, screenHeight] = useWindowSize();

  const refPanelHeader = useRef();

  const { isAllowEdit: hasPermissionEditPreview } =
    useCheckEditkMemberFullAndMyCompany();

  const userAddress = {
    address1: get(
      memberShortDetail,
      'primaryAddress.primaryAddressMainAddress',
      ''
    ),
    state: get(memberShortDetail, 'primaryAddress.primaryAddressState', ''),
    city: get(memberShortDetail, 'primaryAddress.primaryAddressCity', ''),
    country: get(memberShortDetail, 'primaryAddress.primaryAddressCountry', ''),
    zipcode: get(memberShortDetail, 'primaryAddress.primaryAddressZipcode', ''),
  };

  const iconList = get(memberShortDetail, 'classifications', []);

  const contactFullList = get(memberShortDetail, 'contactInformation', []);

  const primaryContact =
    contactFullList.filter((item) => item.contactInfoIsPrimary).length > 0
      ? contactFullList.filter((item) => item.contactInfoIsPrimary)[0]
      : [];
  const notPrimaryContact =
    contactFullList.filter(
      (item) => item.contactInfoEmail !== primaryContact.contactInfoEmail
    ).length > 3
      ? contactFullList
          .filter(
            (item) => item.contactInfoEmail !== primaryContact.contactInfoEmail
          )
          .slice(0, 3)
      : contactFullList.filter(
          (item) => item.contactInfoEmail !== primaryContact.contactInfoEmail
        ).length > 0
      ? contactFullList.filter(
          (item) => item.contactInfoEmail !== primaryContact.contactInfoEmail
        )
      : [];
  const contactList = [primaryContact].concat(notPrimaryContact);

  const styleBgrColorWithBackground = () => {
    if (memberPreviewBackgroundConfig) {
      return {
        background: isEditColor
          ? bgrPreviewColor
          : memberPreviewBackgroundConfig?.textBackgroundColor,
        color: isEditColor
          ? textPreviewColor
          : memberPreviewBackgroundConfig?.textColor,
      };
    }
  };

  const styleTextColor = () => {
    if (memberPreviewBackgroundConfig) {
      return {
        color: isEditColor
          ? textPreviewColor
          : memberPreviewBackgroundConfig.textColor,
      };
    }
  };

  const setClassNameWithBackground = (position) => {
    if (memberPreviewBackgroundConfig) {
      switch (position) {
        case 'address-info':
          return 'member-panel-info-col--black';
        case 'categories':
          return 'member-panel__categories-custom';
        default:
          return;
      }
    }
  };

  const userContact = [
    {
      label: 'Phone',
      info: get(
        memberShortDetail,
        'primaryAddress.primaryAddressPhoneNumber',
        ''
      ),
    },
    {
      label: 'Mobile',
      info: get(
        memberShortDetail,
        'primaryAddress.primaryAddressMobileNumber',
        ''
      ),
    },
    {
      label: 'Fax',
      info: get(
        memberShortDetail,
        'primaryAddress.primaryAddressFaxNumber',
        ''
      ),
    },
    {
      label: 'GLN(s)',
      info: (
        <MemberPanelGdsnList
          memberId={memberShortDetail?.id}
          textColor={styleTextColor()?.color}
          isEdit={editType === 'background'}
        />
      ),
    },
  ];

  const getDate = useCallback((date) => {
    return date ? formatMDY(date, '') : 'N/A';
  }, []);

  useEffect(() => {
    const setBackgroundRatioToKnowCropperSize = () => {
      if (
        screenWidth &&
        screenWidth &&
        refPanelHeader.current &&
        setBackgroundRatio
      ) {
        const ratio =
          refPanelHeader.current?.offsetWidth /
          refPanelHeader.current.offsetHeight;

        setBackgroundRatio(ratio || 1);
      }
    };

    setBackgroundRatioToKnowCropperSize();
  }, [screenWidth, screenHeight, setBackgroundRatio]);

  return (
    <div
      className={classnames({
        'member-panel__wrapper': true,
        'member-panel__wrapper__edit': isPreview,
      })}
      style={style && style}
    >
      <Row className={`member-panel`} gutter={[6, 0]} ref={refPanelHeader}>
        {isPreview && hasPermissionEditPreview && (
          <Tooltip title='Edit background'>
            <Button
              className={classnames('member-panel__btn-edit-background', {
                'member-panel__btn-edit-background--edit':
                  editType === 'background',
              })}
              icon={<EditOutlined />}
              type='primary'
              onClick={() => changeEditType('background')}
            />
          </Tooltip>
        )}
        <BackgroundEdit
          src={memberPreviewBackgroundUrl || ''}
          backgroundConfig={memberPreviewBackgroundConfig}
          forcePreviewImage={previewBg}
        />

        <Col span={16}>
          <Row className={`member-panel__info-row`}>
            <Col className={`member-panel-image-col`} span={10}>
              <div className='member-panel__image-wrap'>
                <img
                  className='member-panel__image-profile'
                  src={
                    memberShortDetail?.thumb300 ||
                    memberShortDetail?.thumb ||
                    Images.RIVIR_LOGO_DEFAULT
                  }
                  alt='logo'
                />
              </div>
            </Col>
            <Col
              className={`member-panel-info-col ${setClassNameWithBackground(
                'address-info'
              )}`}
              flex='auto'
              span={14}
              style={styleBgrColorWithBackground()}
            >
              {memberShortDetail?.memberName && (
                <Row justify='center' align='middle'>
                  <Col span={3}>
                    {memberShortDetail?.isFavorited && (
                      <StarFilled
                        style={{ fontSize: '18px', color: '#fec810' }}
                      />
                    )}
                  </Col>
                  <Col span={memberShortDetail?.isFavorited ? 20 : 23}>
                    <Row>
                      <Text
                        className='member-panel__member-name'
                        style={styleTextColor()}
                      >
                        {memberShortDetail?.memberName}
                      </Text>
                      {memberShortDetail?.subscription && (
                        <div style={{ marginLeft: 5, marginTop: -1 }}>
                          <ProductSubscription
                            color={memberShortDetail?.subscriptionShield}
                          />
                        </div>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}
              <ProfileAddress
                className={`member-panel-address`}
                address={get(memberShortDetail, 'primaryAddress', '')}
                style={styleTextColor()}
              />
            </Col>
          </Row>
          <Row
            className={`member-panel__address-row ${setClassNameWithBackground(
              'address-info'
            )}`}
            style={styleBgrColorWithBackground()}
          >
            <UserContactInfo
              className={`member-panel__address-row--contact-info`}
              contactInfo={userContact}
              style={styleTextColor()}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row justify='space-around'>
            {get(memberShortDetail, 'ixOneId') && (
              <Image
                className={`member-panel__membership-logo`}
                src={Images.ixone}
              />
            )}
            {get(memberShortDetail, 'gln') && (
              <Image
                className={`member-panel__membership-logo member-panel__membership-logo--width`}
                src={gs1Img}
              />
            )}
          </Row>

          <div
            className={`member-panel__membership-info ${setClassNameWithBackground(
              'address-info'
            )}`}
            style={styleBgrColorWithBackground()}
          >
            <div>
              <strong>
                {intl.formatMessage({
                  id: 'Taco.common.components.member.pane.dateEstablished',
                })}
                :
              </strong>
            </div>
            <div>{getDate(memberShortDetail?.dateEstablished)}</div>
          </div>
          <div
            className={`member-panel__membership-info ${setClassNameWithBackground(
              'address-info'
            )}`}
            style={styleBgrColorWithBackground()}
          >
            <div>
              <strong>
                {intl.formatMessage({
                  id: 'Taco.common.components.member.pane.memberSince',
                })}
                :
              </strong>
            </div>
            <div>{getDate(memberShortDetail?.memberSince)}</div>
          </div>
          <div
            className={`member-panel__membership-info ${setClassNameWithBackground(
              'address-info'
            )}`}
            style={styleBgrColorWithBackground()}
          >
            <div>
              <strong>
                {intl.formatMessage({
                  id: 'Taco.common.components.member.pane.lastModified',
                })}
                :
              </strong>
            </div>
            <div>{getDate(memberShortDetail?.lastModified)}</div>
          </div>
        </Col>
        {iconList && iconList.length > 0 && (
          <Row
            className={`member-panel__category ${setClassNameWithBackground(
              'categories'
            )}`}
            style={styleTextColor()}
          >
            <Col span={5} className={`member-panel__categories-info`}>
              <strong>
                {intl.formatMessage({
                  id: 'Taco.common.components.member.pane.categories',
                })}
                :
              </strong>
            </Col>
            <Col className='member-panel__categories-icons' span={19}>
              <IconList
                numberOfShowedItems={40}
                itemList={iconList}
                size='default'
                tooltipPosition='top'
              />
            </Col>
          </Row>
        )}
      </Row>
      <Row className={`member-panel__overview-row`} flex='auto'>
        <Tabs
          defaultActiveKey='tagline'
          onChange={() => {
            if (changeEditType) {
              changeEditType(null);
            }
          }}
        >
          <Tabs.TabPane
            tab={intl.formatMessage({
              id: 'Taco.common.components.member.pane.tagLine',
            })}
            key='tagline'
          >
            {isPreview && hasPermissionEditPreview && (
              <Tooltip title='Edit tagline'>
                <Button
                  className={classnames('member-panel__btn-edit-tab', {
                    'member-panel__btn-edit-tab--edit': editType === 'tagline',
                  })}
                  icon={<EditOutlined />}
                  type='link'
                  onClick={() => changeEditType('tagline')}
                />
              </Tooltip>
            )}
            <Col span={24}>
              <MemberTagLine
                content={mdTagline}
                containerSize={[500, null]}
                refSource={refSource}
              />
            </Col>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={intl.formatMessage({
              id: 'Taco.common.components.member.pane.contacts',
            })}
            key='contacts'
          >
            {isPreview && hasPermissionEditPreview && (
              <Tooltip title='Edit contacts'>
                <Button
                  className={classnames('member-panel__btn-edit-tab', {
                    'member-panel__btn-edit-tab--edit': editType === 'contacts',
                  })}
                  icon={<EditOutlined />}
                  type='link'
                  onClick={() => changeEditType('contacts')}
                />
              </Tooltip>
            )}
            <Col span={24}>
              <GoogleMapViewModal
                officeList={publicContactList ? publicContactList : []}
                totalOffice={5}
                memberId={'1'}
              />
            </Col>
          </Tabs.TabPane>
        </Tabs>
      </Row>
    </div>
  );
};

export default React.memo(injectIntl(MemberPanel));
