import { useSelector } from 'react-redux';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import * as assetDetailSelectors from 'pages/asset-full-view/controllers/selectors';

import selectorUser from 'redux/user/selectors';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks';
import {
  checkIsSuperAdmin,
  checkAllOwnerItems,
  checkOwnerOneItem,
} from 'utils';

/* 
  Note: Permission SHARED_PRODUCT/ASSET: isSuperMember + have permission edit/asset

  If you want to allow edit/delete asset, you must have one of three conditions:
    1. You must be a SuperAdmin
    2. You have SHARED permission
    3. You own product/asset and have permission
*/

const PERMISSION_EDIT_SHARED_PRODUCT = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.SHARED_PRODUCT,
  },
];

const PERMISSION_DELETE_SHARED_PRODUCT = [
  {
    action: ABILITY_ACTION.DELETE,
    subject: ABILITY_SUBJECT.SHARED_PRODUCT,
  },
];

const PERMISSION_EDIT_PRODUCT = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.PRODUCT,
  },
];

const PERMISSION_EDIT_SUPPLIER_ASSIGNED_PRODUCT = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.SUPPLIER_ASSIGNED_PRODUCT,
  },
];

const PERMISSION_EDIT_SHARED_ONLY_MEMBER_PRODUCT = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.SHARED_ONLY_ASSIGNED_PRODUCT,
  },
];

const PERMISSION_DELETE_PRODUCT = [
  {
    action: ABILITY_ACTION.DELETE,
    subject: ABILITY_SUBJECT.PRODUCT,
  },
];

const PERMISSION_DELETE_SHARED_ASSET = [
  {
    action: ABILITY_ACTION.DELETE,
    subject: ABILITY_SUBJECT.SHARED_ASSET,
  },
];

const PERMISSION_EDIT_SHARED_ASSET = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.SHARED_ASSET,
  },
];

const PERMISSION_EDIT_ASSET = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.ASSET,
  },
];

const PERMISSION_DELETE_ASSET = [
  {
    action: ABILITY_ACTION.DELETE,
    subject: ABILITY_SUBJECT.ASSET,
  },
];

export const useCheckIsSuperAdmin = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  return checkIsSuperAdmin(userInfo?.roles);
};
export const useCheckIsSuperMember = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  return userInfo?.isSuperMember;
};

// Product
export const usePermissionEditSharedProduct = () => {
  return useCheckPermissions(PERMISSION_EDIT_SHARED_PRODUCT);
};

export const usePermissionDeleteSharedProduct = () => {
  return useCheckPermissions(PERMISSION_DELETE_SHARED_PRODUCT);
};

export const usePermissionEditProduct = () => {
  return useCheckPermissions(PERMISSION_EDIT_PRODUCT);
};

export const usePermissionEditSupplierAssignedProduct = () => {
  return useCheckPermissions(PERMISSION_EDIT_SUPPLIER_ASSIGNED_PRODUCT);
};

export const usePermissionEditSharedOnlyMemberProduct = () => {
  return useCheckPermissions(PERMISSION_EDIT_SHARED_ONLY_MEMBER_PRODUCT);
};

export const usePermissionDeleteProduct = () => {
  return useCheckPermissions(PERMISSION_DELETE_PRODUCT);
};

// Asset

export const usePermissionEditSharedAsset = () => {
  return useCheckPermissions(PERMISSION_EDIT_SHARED_ASSET);
};

export const usePermissionDeleteSharedAsset = () => {
  return useCheckPermissions(PERMISSION_DELETE_SHARED_ASSET);
};

export const usePermissionEditAsset = () => {
  return useCheckPermissions(PERMISSION_EDIT_ASSET);
};

export const usePermissionDeleteAsset = () => {
  return useCheckPermissions(PERMISSION_DELETE_ASSET);
};

export const useCheckIsCompanyAsset = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const assetFull = useSelector(assetDetailSelectors.assetDataLoading());

  return assetFull?.ownerId === userInfo?.member?.id;
};

export const useCheckAllowEditProducts = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);

  const hasPermissionEditSharedProduct = usePermissionEditSharedProduct();
  const hasPermissionEditProduct = usePermissionEditProduct();
  const hasPermissionEditSupplierAssignedProduct =
    usePermissionEditSupplierAssignedProduct();

  const hasPermissionEditSharedOnlyMemberProduct =
    usePermissionEditSharedOnlyMemberProduct();

  const productGrid = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const checkAllowEditProductGrid = () => {
    const isOwnerGrid = checkAllOwnerItems(productGrid);

    if (productGrid?.length > 0) {
      if (isSuperAdmin) return true;

      if (hasPermissionEditSharedProduct) return true;

      if (hasPermissionEditProduct && isOwnerGrid) return true;
    }

    return false;
  };

  const checkAllowBulkEditProducts = () => {
    if (isSuperAdmin) return true;
    if (hasPermissionEditSharedProduct) return true;

    const isAllProductAllowEdit =
      productGrid
        .map((productItem = {}) => {
          const { isOwner, editable } = productItem;
          if (isOwner) return true;
          if (
            editable &&
            (hasPermissionEditSupplierAssignedProduct ||
              hasPermissionEditSharedOnlyMemberProduct)
          )
            return true;
        })
        .every(Boolean) && productGrid?.length > 0;

    return isAllProductAllowEdit;
  };

  const checkAllowEditProductFull = (productFull) => {
    const isOwnerFull = checkOwnerOneItem(productFull);

    const isEditableProperty = productFull?.editable;

    if (isSuperAdmin) return true;

    if (hasPermissionEditSharedProduct) return true;

    if (hasPermissionEditProduct && isOwnerFull) return true;

    if (
      isEditableProperty &&
      (hasPermissionEditSupplierAssignedProduct ||
        hasPermissionEditSharedOnlyMemberProduct)
    )
      return true;

    return false;
  };

  return {
    checkAllowEditProductGrid,
    checkAllowEditProductFull,
    checkAllowBulkEditProducts,
  };
};

export const useCheckAllowDeleteProducts = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);

  const hasPermissionDeleteSharedProduct = usePermissionDeleteSharedProduct();
  const hasPermissionDeleteProduct = usePermissionDeleteProduct();

  const productGrid = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const checkAllowDeleteProductGrid = () => {
    const isOwnerGrid = checkAllOwnerItems(productGrid);

    if (productGrid?.length > 0) {
      if (isSuperAdmin) return true;

      if (hasPermissionDeleteSharedProduct) return true;

      if (hasPermissionDeleteProduct && isOwnerGrid) return true;
    }

    return false;
  };

  return {
    checkAllowDeleteProductGrid,
  };
};

export const useCheckAllowEditAssets = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);

  const hasPermissionEditSharedAsset = usePermissionEditSharedAsset();
  const hasPermissionEditAsset = usePermissionEditAsset();

  const assetGrid = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const assetFull = useSelector(assetDetailSelectors.assetDataLoading());

  const checkAllowEditAssetGrid = () => {
    const isOwnerGrid = checkAllOwnerItems(assetGrid);

    if (assetGrid?.length > 0) {
      if (isSuperAdmin) return true;

      if (hasPermissionEditSharedAsset) return true;

      if (hasPermissionEditAsset && isOwnerGrid) return true;
    }

    return false;
  };

  const checkAllowEditAssetFull = () => {
    const isOwnerFull = checkOwnerOneItem(assetFull);

    if (isSuperAdmin) return true;

    if (hasPermissionEditSharedAsset) return true;

    if (assetFull && isOwnerFull && hasPermissionEditAsset) return true;

    return false;
  };

  return {
    checkAllowEditAssetGrid,
    checkAllowEditAssetFull,
  };
};

export const useCheckAllowDeleteAssets = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);

  const hasPermissionDeleteSharedAsset = usePermissionDeleteSharedAsset();
  const hasPermissionDeleteAsset = usePermissionDeleteAsset();

  const assetGrid = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const assetFull = useSelector(assetDetailSelectors.assetDataLoading());

  const checkAllowDeleteAssetGrid = () => {
    const isOwnerGrid = checkAllOwnerItems(assetGrid);

    if (assetGrid?.length > 0) {
      if (isSuperAdmin) return true;

      if (hasPermissionDeleteSharedAsset) return true;

      if (hasPermissionDeleteAsset && isOwnerGrid) return true;
    }

    return false;
  };

  const checkAllowDeleteAssetFull = () => {
    const isOwnerFull = checkOwnerOneItem(assetFull);

    if (isSuperAdmin) return true;

    if (hasPermissionDeleteSharedAsset) return true;

    if (hasPermissionDeleteAsset && isOwnerFull) return true;

    return false;
  };

  return {
    checkAllowDeleteAssetGrid,
    checkAllowDeleteAssetFull,
  };
};
