//* get grid columns
export const GET_MARKUP_MAINTENANCE_GRID_COLUMNS =
  'GET_MARKUP_MAINTENANCE_GRID_COLUMN';
export const GET_MARKUP_MAINTENANCE_GRID_COLUMNS_SUCCESS =
  'GET_MARKUP_MAINTENANCE_GRID_COLUMNS_SUCCESS';
export const GET_MARKUP_MAINTENANCE_GRID_COLUMNS_FAILURE =
  'GET_MARKUP_MAINTENANCE_GRID_COLUMNS_FAILURE';

export const CREATE_MARKUP = 'CREATE_MARKUP';
export const CREATE_MARKUP_SUCCESS = 'CREATE_MARKUP_SUCCESS';
export const CREATE_MARKUP_ERROR = 'CREATE_MARKUP_ERROR';

export const CLONE_MARKUP = 'CLONE_CREATE';
export const CLONE_MARKUP_SUCCESS = 'CLONE_MARKUP_SUCCESS';
export const CLONE_MARKUP_ERROR = 'CLONE_MARKUP_ERROR';

export const RESET_SAVE_MARKUP = 'RESET_SAVE_MARKUP';

export const ENABLE_MARKUP_MAINTENANCE_EDIT =
  'ENABLE_MARKUP_MAINTENANCE_EDIT_POST';
export const DISABLE_MARKUP_MAINTENANCE_EDIT =
  'DISABLE_MARKUP_MAINTENANCE_EDIT_POST';

export const ENABLE_MARKUP_MAINTENANCE_CREATE =
  'ENABLE_MARKUP_MAINTENANCE_EDIT_CREATE';
export const DISABLE_MARKUP_MAINTENANCE_CREATE =
  'ENABLE_MARKUP_MAINTENANCE_EDIT_CREATE';

export const CONFIRM_CLONE_MARKUP_MAINTENANCE =
  'CONFIRM_CLONE_MARKUP_MAINTENANCE';

export const SAVE_ID_MARKUP = 'SAVE_ID_MARKUP';

//* get data list
export const GET_MARKUP_MAINTENANCE_LIST = 'GET_MARKUP_MAINTENANCE_LIST';
export const GET_MARKUP_MAINTENANCE_LIST_SUCCESS =
  'GET_MARKUP_MAINTENANCE_LIST_SUCCESS';
export const GET_MARKUP_MAINTENANCE_LIST_FAILURE =
  'GET_MARKUP_MAINTENANCE_LIST_FAILURE';

// Only MARKUP Detail View
export const MARKUP_DISABLED_VIEW_MODE = 'MARKUP_DISABLED_VIEW_MODE';
//* get detail data
export const GET_MARKUP_CONTENT_DETAIL = 'GET_MARKUP_CONTENT_DETAIL';
export const GET_MARKUP_CONTENT_DETAIL_SUCCESS =
  'GET_MARKUP_CONTENT_DETAIL_SUCCESS';
export const GET_MARKUP_CONTENT_DETAIL_ERROR =
  'GET_MARKUP_CONTENT_DETAIL_ERROR';

export const TOGGLE_MARKUP_UPLOAD_MEDIA = 'TOGGLE_MARKUP_UPLOAD_MEDIA';

// Curated Query Markup
export const GET_CURATED_QUERY_GRID_COLUMNS = 'GET_CURATED_QUERY_GRID_COLUMNS';
export const GET_CURATED_QUERY_GRID_COLUMNS_SUCCESS =
  'GET_CURATED_QUERY_GRID_COLUMNS_SUCCESS';
export const GET_CURATED_QUERY_GRID_COLUMNS_ERROR =
  'GET_CURATED_QUERY_GRID_COLUMNS_ERROR';
//
export const GET_CURATED_QUERY_LIST = 'GET_CURATED_QUERY_LIST';
export const GET_CURATED_QUERY_LIST_SUCCESS = 'GET_CURATED_QUERY_LIST_SUCCESS';
export const GET_CURATED_QUERY_LIST_ERROR = 'GET_CURATED_QUERY_LIST_ERROR';
// Curated Query Short Details
export const GET_CURATED_QUERY_SHORT_DETAIL = 'GET_CURATED_QUERY_SHORT_DETAIL';
export const GET_CURATED_QUERY_SHORT_DETAIL_SUCCESS =
  'GET_CURATED_QUERY_SHORT_DETAIL_SUCCESS';
export const GET_CURATED_QUERY_SHORT_DETAIL_ERROR =
  'GET_CURATED_QUERY_SHORT_DETAIL_ERROR';
