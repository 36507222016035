import React, { useRef } from 'react';

import { Editor, Frame, useEditor } from '@craftjs/core';

import {
  EmailEditorSigningOff,
  EmailEditorShippingTo,
  EmailEditorSenderInfo,
  EmailEditorGreeting,
  EmailEditorAdditionalInfo,
  EmailEditorProductRequested,
  EmailEditorFreeText,
} from '../../components';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import { EVENT } from 'static/Constants';

const EmailEditorGetHtml = (props) => {
  const htmlElRef = useRef(null);

  return (
    <Editor
      resolver={{
        EmailEditorSigningOff,
        EmailEditorShippingTo,
        EmailEditorSenderInfo,
        EmailEditorGreeting,
        EmailEditorAdditionalInfo,
        EmailEditorProductRequested,
        EmailEditorFreeText,
      }}
      enabled={false}
    >
      <div ref={htmlElRef} style={{ display: 'none' }}>
        <Frame></Frame>
      </div>
      <AplSampleRequestGetHtmlInner htmlElRef={htmlElRef} />
    </Editor>
  );
};

const AplSampleRequestGetHtmlInner = (props) => {
  const { actions } = useEditor();

  const { htmlElRef } = props;

  const getHtml = (e) => {
    const json = e.detail;

    actions.deserialize(json);

    setTimeout(() => {
      const el = htmlElRef?.current;
      const html = el.innerHTML;

      if (html) getHtmlSuccess(html);
    });
  };

  const getHtmlSuccess = (html) => {
    const event = new CustomEvent(EVENT.EMAIL_EDITOR_GET_HTML_SUCCESS, {
      detail: html,
    });

    document.dispatchEvent(event);
  };

  useDocumentAttachEvent({
    name: EVENT.EMAIL_EDITOR_GET_HTML,
    handler: getHtml,
  });

  return null;
};

export default EmailEditorGetHtml;
