import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectTicketingSystem = (state) => state.ticketingSystem || initialState;

const makeSelectLoadingExport = () =>
  createSelector(
    selectTicketingSystem,
    (ticketingSystem) => ticketingSystem.loadingExport
  );

const makeSelectFilterStatus = () =>
  createSelector(
    selectTicketingSystem,
    (ticketingSystem) => ticketingSystem.filterStatus
  );

const makeSelectFilterTags = () =>
  createSelector(
    selectTicketingSystem,
    (ticketingSystem) => ticketingSystem.filterTags
  );

const makeSelectFullViewMode = () =>
  createSelector(
    selectTicketingSystem,
    (ticketingSystem) => ticketingSystem.fullViewMode
  );

export {
  selectTicketingSystem,
  makeSelectFilterStatus,
  makeSelectFilterTags,
  makeSelectFullViewMode,
  makeSelectLoadingExport,
};
