import { formatMDY } from './formatDate';
import moment from 'moment';
import lz from 'lzutf8';

export const parseToString = (value) => {
  if (!value) return value;
  return value && String(value);
};

export const parseToNumber = (value) => {
  if (!value) return value;
  return value && Number(value);
};

export const parseToBoolean = (value) => {
  if (!value) return false;

  const valueDownCase = String(value)?.toLowerCase();

  if (valueDownCase === 'false' || valueDownCase === 'no') return false;

  return Boolean(value);
};

export const parseToDate = (value) => {
  if (!value) return value;
  return value && formatMDY(value);
};

export const parseToDateObject = (value) => {
  if (!value) return value;
  return value && moment(value);
};

export const parseToHourMinuteSecond = (totalSeconds) => {
  if (!totalSeconds) {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const mapToTwoDigit = (number) => (number < 10 ? `0${number}` : `${number}`);

  return {
    hours: mapToTwoDigit(hours),
    minutes: mapToTwoDigit(minutes),
    seconds: mapToTwoDigit(seconds),
  };
};

/**
 *
 * @param {*} fileList : Array of file (remember to get original file from antd upload)
 */
export const parseFileToBinary = (fileList = []) => {
  const readFileJobs = [];
  fileList.forEach((file) => {
    const readFilePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });

    readFileJobs.push(readFilePromise);
  });

  return Promise.all(readFileJobs);
};

export const encodeJson = (json) => {
  if (!json) return;

  return lz.compress(JSON.stringify(json), {
    outputEncoding: 'Base64',
  });
};

export const decodeJson = (code) => {
  if (!code) return;

  return JSON.parse(
    lz.decompress(code, {
      inputEncoding: 'Base64',
    })
  );
};

export const parseNumber = (value) => {
  if (isNaN(parseInt(value))) {
    return '';
  }
  return value;
};
