import React from 'react';
import reactCSS from 'reactcss';

import { Row, Col, Typography, Empty } from 'antd';
import SectionWrapper from './SectionWrapper';
import SelectedMemberList from './SelectedMemberList';

const { Text } = Typography;

const TargetMemberList = (props) => {
  const { selectedItems, items, handleSelectItem, tagRender } = props;

  const styles = reactCSS({
    default: {
      targetMember: {
        padding: '8px 0 3px 8px',
      },
    },
  });

  return (
    <div>
      <Row>
        <Col xs={24} style={styles.targetMember}>
          <Text strong>Target Member</Text>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {selectedItems?.length === 0 ? (
            <Empty description='No Selected Member' />
          ) : (
            <SelectedMemberList
              selectedItems={selectedItems}
              items={items}
              handleSelectItem={handleSelectItem}
              tagRender={tagRender}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TargetMemberList;
