//* get grid columns
export const GET_EULA_MAINTENANCE_GRID_COLUMNS =
  'GET_EULA_MAINTENANCE_GRID_COLUMN';
export const GET_EULA_MAINTENANCE_GRID_COLUMNS_SUCCESS =
  'GET_EULA_MAINTENANCE_GRID_COLUMNS_SUCCESS';
export const GET_EULA_MAINTENANCE_GRID_COLUMNS_FAILURE =
  'GET_EULA_MAINTENANCE_GRID_COLUMNS_FAILURE';

export const CREATE_EULA = 'CREATE_EULA';
export const CREATE_EULA_SUCCESS = 'CREATE_EULA_SUCCESS';
export const CREATE_EULA_ERROR = 'CREATE_EULA_ERROR';

export const CLONE_EULA = 'CLONE_CREATE';
export const CLONE_EULA_SUCCESS = 'CLONE_EULA_SUCCESS';
export const CLONE_EULA_ERROR = 'CLONE_EULA_ERROR';

export const RESET_SAVE_EULA = 'RESET_SAVE_EULA';

export const ENABLE_EULA_MAINTENANCE_EDIT = 'ENABLE_EULA_MAINTENANCE_EDIT_POST';
export const DISABLE_EULA_MAINTENANCE_EDIT =
  'DISABLE_EULA_MAINTENANCE_EDIT_POST';

export const ENABLE_EULA_MAINTENANCE_CREATE =
  'ENABLE_EULA_MAINTENANCE_EDIT_CREATE';
export const DISABLE_EULA_MAINTENANCE_CREATE =
  'ENABLE_EULA_MAINTENANCE_EDIT_CREATE';

export const CONFIRM_CLONE_EULA_MAINTENANCE = 'CONFIRM_CLONE_EULA_MAINTENANCE';
export const RESET_CONFIRM_CLONE_EULA_MAINTENANCE = 'RESET_CONFIRM_CLONE_EULA_MAINTENANCE';


export const SAVE_ID_EULA = 'SAVE_ID_EULA';

//* get data list
export const GET_EULA_MAINTENANCE_LIST = 'GET_EULA_MAINTENANCE_LIST';
export const GET_EULA_MAINTENANCE_LIST_SUCCESS =
  'GET_EULA_MAINTENANCE_LIST_SUCCESS';
export const GET_EULA_MAINTENANCE_LIST_FAILURE =
  'GET_EULA_MAINTENANCE_LIST_FAILURE';

// Only Eula Detail View
export const EULA_DISABLED_VIEW_MODE = 'EULA_DISABLED_VIEW_MODE';
//* get detail data
export const GET_EULA_CONTENT_DETAIL = 'GET_EULA_CONTENT_DETAIL';
export const GET_EULA_CONTENT_DETAIL_SUCCESS =
  'GET_EULA_CONTENT_DETAIL_SUCCESS';
export const GET_EULA_CONTENT_DETAIL_ERROR = 'GET_EULA_CONTENT_DETAIL_ERROR';

export const TOGGLE_EULA_UPLOAD_MEDIA = 'TOGGLE_EULA_UPLOAD_MEDIA';

// Accepted user eula grid columns
export const GET_ACCEPTED_USER_EULA_GRID_COLUMNS =
  'GET_ACCEPTED_USER_EULA_GRID_COLUMNS';
export const GET_ACCEPTED_USER_EULA_GRID_COLUMNS_SUCCESS =
  'GET_ACCEPTED_USER_EULA_GRID_COLUMNS_SUCCESS';
export const GET_ACCEPTED_USER_EULA_GRID_COLUMNS_ERROR =
  'GET_ACCEPTED_USER_EULA_GRID_COLUMNS_ERROR';
// Accepted user eula list
export const GET_ACCEPTED_USER_EULA_LIST = 'GET_ACCEPTED_USER_EULA_LIST';
export const GET_ACCEPTED_USER_EULA_LIST_SUCCESS =
  'GET_ACCEPTED_USER_EULA_LIST_SUCCESS';
export const GET_ACCEPTED_USER_EULA_LIST_ERROR =
  'GET_ACCEPTED_USER_EULA_LIST_ERROR';
