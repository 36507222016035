const findDimensionEnumDisplay = (dimensionUOM, productEnumsList) => {
  if (productEnumsList?.length > 0) {
    const baseUOM = productEnumsList.find(
      (item) => item?.enumName === 'BasicUomEnum'
    );
    const baseUOMProperty =
      baseUOM?.enumProperties?.length > 0
        ? baseUOM.enumProperties?.find(
            (enumItem) =>
              enumItem?.enumCode?.toLowerCase() ===
                dimensionUOM?.toLowerCase() ||
              enumItem?.enumDisplayName?.toLowerCase() ===
                dimensionUOM?.toLowerCase()
          )
        : null;
    return baseUOMProperty?.enumDisplayName;
  }
  return null;
};

export { findDimensionEnumDisplay };
