import { useMemo, useState } from 'react';

export const useVisibleModal = () => {
  const [visible, setVisible] = useState(false);

  const handler = useMemo(
    () => ({
      onModal: () => setVisible(true),
      offModal: () => setVisible(false),
      toggle: () => setVisible((modal) => !modal),
    }),
    []
  );

  return [visible, handler];
};
