import React from 'react';

import { Radio, Tooltip } from 'antd';

const PageEditorToolBtn = (props) => {
  const { icon, value, tooltip } = props;

  const ComponentIcon = icon;

  return (
    <Tooltip placement='left' title={tooltip}>
      <Radio.Button value={value}>
        <div>{ComponentIcon && ComponentIcon}</div>
      </Radio.Button>
    </Tooltip>
  );
};

export default PageEditorToolBtn;
