import React from 'react';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import RibbonButton from 'common/components/button/RibbonButton';

const SearchControl = ({ onSearch }) => {
  return (
    <RibbonButton
      icon={<SearchOutlined />}
      label={Messages.searchText}
      onClick={onSearch}
    />
  );
};

SearchControl.propTypes = {
  onSearch: PropTypes.func,
};

export default SearchControl;
