import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-040843 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Global Vertical Innovations, LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 11 May 2024 )____[v2]_MTcxNTM4MjAwMDAwMA==d035e6c32ae72202e23beccd712ab105'
);

const AgGridClient = (props) => {
  const { containerStyle, refGrid, ...restProps } = props;

  const defaultColDef = {
    width: 170,
    sortable: true,
    filter: true,
    resizable: true,
  };

  const AgGridProps = {
    defaultColDef,
    headerHeight: 32,
    rowHeight: 32,
    ...restProps,
  };

  return (
    <div
      className='ag-theme-alpine ag-theme-alpine--with-scroller'
      style={containerStyle}
    >
      <AgGridReact ref={refGrid} {...AgGridProps} />
    </div>
  );
};

AgGridClient.propTypes = {
  containerStyle: PropTypes.object,
};

AgGridClient.defaultProps = {
  containerStyle: {
    height: '100%',
    width: '100%',
  },
};

export default AgGridClient;
