import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const bpSsoCreateProductDetailsService = (params) => {
  return api.sendPost(endpoints.BP_SSO_CREATE_NEW_PRODUCT, params);
};

export const bpSsoGetProductDetailsService = (params) => {
  return api.sendGet(endpoints.BP_SSO_GET_PRODUCT_DETAILS, { params });
};

export const bpSsoUpdateProductDetailsService = (params) => {
  return api.sendPost(endpoints.BP_SSO_UPDATE_PRODUCT_DETAILS, params);
};

export const bpSsoGetAddProductInfo = (params) => {
  return api.sendGet(
    endpoints.BP_SSO_GET_BP_ADD_PRODUCT_INFO + `/${params?.id}`,
    { params }
  );
};

export const bpSsoCreateSupplier = (params) => {
  return api.sendPost(endpoints.BP_SSO_CREATE_SUPPLIER, params);
};
