import classNames from 'classnames';
import { Typography } from 'antd';
const { Text } = Typography;

const DrugFactText = ({ className, styles, children, ...otherProps }) => {
  return (
    <Text
      className={classNames({
        'drug-fact__text': true,
        [className]: !!className,
      })}
      {...otherProps}
      style={styles}
    >
      {children}
    </Text>
  );
};

export default DrugFactText;
