import * as types from './constants';
import { getPreviousViewStyle } from '../../pages/home/utils';
/**
 * change current ribbon view
 * @param {string} view
 */
export function change(view) {
  return {
    type: types.CHANGE_RIBBON_VIEW,
    view: view,
  };
}

/**
 * change current ribbon view success
 * @param {string} view
 */
export function changeSuccess(view) {
  return {
    type: types.CHANGE_RIBBON_VIEW_SUCCESS,
    view: view,
  };
}

/**
 * change current ribbon type
 * @param {string} typeView
 */
export function changeType(typeView) {
  const { localViewType, routeUrl } = getPreviousViewStyle(typeView);
  if (routeUrl) {
    localStorage.setItem('view', JSON.stringify(localViewType));
  }

  return {
    type: types.CHANGE_RIBBON_TYPE,
    typeView: typeView,
  };
}
/**
 * change current ribbon type
 * @param {string} typeView
 */
export function changeHeight(heightRibbon) {
  return {
    type: types.CHANGE_RIBBON_HEIGHT,
    heightRibbon: heightRibbon,
  };
}

export function saveClick(saveClick) {
  return {
    type: types.SAVE_CLICK,
    saveClick: saveClick,
  };
}

/**
 * set can back touter
 */
export function setCanBackRoute(saveClick) {
  return {
    type: types.SET_CAN_BACK_ROUTE,
  };
}

export function changeMemberLayout(layout) {
  return {
    type: types.CHANGE_RIBBON_MEMBER_LAYOUT,
    layout: layout,
  };
}

export function toggleBanList() {
  return {
    type: types.TOGGLE_BAN_LIST,
  };
}

export function addToBanList(data, page, isFavoriteRoute) {
  return {
    type: types.ADD_TO_BAN_LIST,
    data,
    page,
    isFavoriteRoute,
  };
}

export function createCloseDetailStack(name, pathname) {
  return {
    type: types.CREATE_CLOSE_DETAIL_STACK,
    name,
    pathname,
  };
}

export function updateCloseDetailStack(stack) {
  return {
    type: types.UPDATE_CLOSE_DETAIL_STACK,
    stack,
  };
}

export function storeCurrentStackToSwitch(stack) {
  return {
    type: types.STORE_CURRENT_CLOSE_DETAIL_STACK_TO_SWITCH_STACK,
    stack,
  };
}

export function updateStoredStack(stack) {
  return {
    type: types.UPDATE_STORED_STACK,
    stack,
  };
}

export function updateRibbonBarDom(ribbonDom) {
  return {
    type: types.UPDATE_RIBBON_DOM,
    ribbonDom,
  };
}
