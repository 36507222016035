import React from 'react';

import { useSelector } from 'react-redux';

import { Can } from 'context/Can';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import CloneFormBtn from './CloneFormBtn';
import DeleteFormBtn from './DeleteFormBtn';
import UploadFormBtn from './UploadFormBtn';
import EditFormBtn from './EditFormBtn';

import { RibbonSection } from 'common/components';
import * as maintenanceFormSelectors from 'pages/maintenance-form/controllers/selectors';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const FormItemManipulationSection = (props) => {
  const selectedForms = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const formDetailInfo = useSelector(
    maintenanceFormSelectors.makeSelectFormDetail()
  );
  const formDetailLoading = formDetailInfo?.loading;

  const singleSelection = selectedForms?.length === 1;
  const multipleSelection = selectedForms?.length > 1;

  const allow_edit_clone =
    singleSelection && !multipleSelection && !formDetailLoading;
  const allow_delete =
    singleSelection && multipleSelection && !formDetailLoading;

  return (
    <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL}>
      <RibbonSection>
        <CloneFormBtn
          disabled={!allow_edit_clone}
          selectedForms={selectedForms}
        />
        <EditFormBtn
          disabled={!allow_edit_clone}
          selectedForms={selectedForms}
          loading={formDetailLoading}
        />
        <UploadFormBtn isSmallBtn disabled={formDetailLoading} />
        <DeleteFormBtn
          isSmallBtn
          disabled={allow_delete}
          selectedForms={selectedForms}
        />
      </RibbonSection>
    </Can>
  );
};

export default FormItemManipulationSection;
