// The % DV for nutrient is calculated by:
// -  dividing the amount of a nutrient in a serving size by its daily value.
// -  then x100
// => https://www.canada.ca/en/health-canada/services/understanding-food-labels/percent-daily-value.html
// => https://www.fda.gov/food/new-nutrition-facts-label/daily-value-new-nutrition-and-supplement-facts-labels

//  Unit of Measure Key
//   - g = grams
//   - mg = milligrams
//   - mcg = micrograms
//   - mg NE = milligrams of niacin equivalent
//   - mcg DFE = micrograms of dietary folate equivalent
//   - mcg RAE = milligrams of retinol activity equivalent
//   - IU = international units

// UPDATE 2020
// https://www.fda.gov/media/135301/download

import React from 'react';

export const FAT = 65; // g
export const SATURATED = 20; // g
export const TRANS = 20;
export const CHOLESTEROL = 300; // mg
export const SODIUM = 2300; // mg
export const CARBOHYDRATE = 275; // g
export const DIETARY_FIBRE = 28; // g
export const SUGARS = 0; // g
export const ADDED_SUGARS = 50; // g
export const PROTEIN = 50; // g

export const CALCIUM = 1300; // mg
export const IRON = 18; // mg
export const MAGNESIUM = 420; // mg
export const MANGANESE = 2.3; // mg
export const PHOSPHORUS = 1250; // mg
export const POTASSIUM = 4700; // mg

export const VITAMIN_A = 900; // mcg
export const VITAMIN_C = 90; // mg
export const VITAMIN_D = 20; // mcg
export const VITAMIN_K = 120; // mcg
export const VITAMIN_B6 = 1.2; // mg
export const VITAMIN_B12 = 2.4; // mcg
export const VITAMIN_E = 15; // mg

export const BIOTIN = 30; // mcg
export const CHLORIDE = 2300; // mg
export const COPPER = 0.9; // mg
export const FOLATE = 400; // mcg
export const FOLIC = 400; // mcg
export const MOLYBDENUM = 45; // mcg
export const NIACIN = 15; // mg NE
export const PANTOTHENIC_ACID = 5; // mg
export const RIBOFLAVIN = 1.3; // mg
export const SELENIUM = 55; // mcg
export const ZINC = 11; // mg
export const CHOLINE = 550; // mg
export const THIAMIN = 1.2; // mg
export const IODINE = 150; // mcg
export const CHROMIUM = 35; // mcg

// Formula
export const calcPercentByGram = (a, b) => Math.floor((a / b) * 100);
export const calcGramByPercent = (a, b) => Math.floor((a / 100) * b);

export const calcPercentPerServingByGram = (a, b, c) =>
  Math.round(calcPercentByGram(a, b) / c);
export const calcGramPerServingByPercent = (a, b, c) =>
  Math.round(calcGramByPercent(a, b) / c);

// Allergen
export const allergensContent = `<div>The Food Allergen Labeling and Consumer Protection Act (FALCPA) requires that foods are labelled to identify
 the eight major food allergens. The eight major allergens are: milk, egg, fish, crustacean shellfish, tree nuts, wheat, peanuts and soybeans. The law also requires that
 the following be on the food label:
 <div>• The specific type of nut (e.g. almond, pecans, walnut, coconut).</div>
 <div>• The specific type of fish (e.g. bass, founder, Pollack).</div>
 <div>• The specific type of crustacean shellfish (e.g. lobster, shrimp, crab).</div>
 Molluscan shellfish do not have to be labeled in this manner. Mollusks include oysters, clams, mussels, or scallops.
 They are not considered a major food allergen.</div>
 `;

export const allergenFishContent = `When a fish allergen is present, you must specify which type of fish it is.
   We've included a few common fish that you can check off `;
export const allergenShellfishContent = `When a shellfish allergen is present, you must specify which type of shellfish it is. We've included a few common shellfish that you can check off`;
export const allergenTreeContent = `When a tree nut allergen is present, you must specify which type of tree nut it is. We've included a few common nuts that you can check off `;

export const SUPP_DV_KEY = {
  'TOTAL FAT': 78,
  'SATURATED FAT': 20,
  CHOLESTEROL: 300,
  'TOTAL CARBOHYDRATE': 275,
  'DIETARY FIBRE': 28,
  'ADDED SUGARS': 50,
  PROTEIN: 50,
  'VITAMIN A': 900,
  'VITAMIN C': 90,
  'VITAMIN D': 20,
  'VITAMIN E': 15,
  'VITAMIN K': 120,
  THIAMIN: 1.2,
  RIBOFLAVIN: 1.3,
  NIACIN: 16,
  'VITAMIN B6': 1.7,
  FOLATE: 400,
  'VITAMIN B12': 2.4,
  BIOTIN: 30,
  'PANTOTHENIC ACID': 5,
  CHOLINE: 550,
  CALCIUM: 1300,
  IRON: 18,
  PHOSPHORUS: 1250,
  IODINE: 150,
  MAGNESIUM: 420,
  ZINC: 11,
  SELENIUM: 55,
  COPPER: 0.9,
  MANGANESE: 2.3,
  CHROMIUM: 35,
  MOLYBDENUM: 45,
  CHLORIDE: 2300,
  SODIUM: 2300,
  POTASSIUM: 4700,
};

// OPTIONS & LIST RENDER ORDER
export const SUPP_DV_OPTIONS = [
  'Calories',
  'Calories from Saturated Fat',
  'Total Fat',
  'Saturated Fat',
  'Trans Fat',
  'Polyunsaturated Fat',
  'Monounsaturated Fat',
  'Cholesterol',
  'Sodium',
  'Total Carbohydrate',
  'Dietary Fiber',
  'Soluble Fiber',
  'Insoluble Fiber',
  'Total Sugars',
  'Added Sugars',
  'Sugar Alcohol',
  'Protein',
  'Vitamin A',
  'Vitamin C',
  'Vitamin D',
  'Vitamin E',
  'Vitamin K',
  'Thiamin',
  'Riboflavin',
  'Niacin',
  'Vitamin B6',
  'Folate',
  'Vitamin B12',
  'Biotin',
  'Pantothenic Acid',
  'Choline',
  'Calcium',
  'Iron',
  'Phosphorus',
  'Iodine',
  'Magnesium',
  'Zinc',
  'Selenium',
  'Copper',
  'Manganese',
  'Chromium',
  'Molybdenum',
  'Chloride',
  'Potassium',
  'Fluoride',
];

export const NUTRITION = [
  { nutritionName: 'Fat', nutritionTypeCode: 'FAT' },
  { nutritionName: 'Saturated Fat', nutritionTypeCode: 'SATURATED_FAT' },
  { nutritionName: 'Trans fat', nutritionTypeCode: 'TRANS_FAT' },
  {
    nutritionName: 'Polyunsaturated Fat',
    nutritionTypeCode: 'POLYUNSATURATED_FAT',
  },
  {
    nutritionName: 'Monounsaturated Fat',
    nutritionTypeCode: 'MONO_UNSATURATED_FAT',
  },
  { nutritionName: 'Cholesterol', nutritionTypeCode: 'CHOLESTEROL' },
  { nutritionName: 'Sodium', nutritionTypeCode: 'NA' },
  { nutritionName: 'Total Carbohydrate', nutritionTypeCode: 'CARBOHYDRATES' },
  { nutritionName: 'Dietary Fiber', nutritionTypeCode: 'DIETARY_FIBRE' },
  { nutritionName: 'Total Sugars', nutritionTypeCode: 'SUGAR' },
  { nutritionName: 'Added Sugars', nutritionTypeCode: 'SUGAD' },
  // Sugar Alcohol are missing in NutritionUEMCode
  { nutritionName: 'Protein', nutritionTypeCode: 'PROTEIN' },
  { nutritionName: 'Vitamin D', nutritionTypeCode: 'VITAMIN_D' },
  { nutritionName: 'Calcium', nutritionTypeCode: 'CALCIUM' },
  { nutritionName: 'Iron', nutritionTypeCode: 'IRON' },
  { nutritionName: 'Potassium', nutritionTypeCode: 'POTASSIUM' },
  { nutritionName: 'Vitamin A', nutritionTypeCode: 'VITAMIN_A' },
  { nutritionName: 'Vitamin C', nutritionTypeCode: 'VITAMIN_C' },
  { nutritionName: 'Vitamin E', nutritionTypeCode: 'VITAMIN_E' },
  { nutritionName: 'Vitamin K', nutritionTypeCode: 'VITAMIN_K' },
  { nutritionName: 'Thiamin', nutritionTypeCode: 'THIAMIN' },
  { nutritionName: 'Riboflavin', nutritionTypeCode: 'RIBOFLAVIN' },
  { nutritionName: 'Niacin', nutritionTypeCode: 'NIACIN' },
  { nutritionName: 'Folic Acid', nutritionTypeCode: 'FOLAC' },
  { nutritionName: 'Vitamin B6', nutritionTypeCode: 'VITAMIN_B6' },
  { nutritionName: 'Folate', nutritionTypeCode: 'FOLATE' },
  { nutritionName: 'Vitamin B12', nutritionTypeCode: 'VITAMIN_B12' },
  { nutritionName: 'Biotin', nutritionTypeCode: 'BIOTIN' },
  { nutritionName: 'Pantothenic Acid', nutritionTypeCode: 'PANTOTHENIC_ACID' },
  { nutritionName: 'Phosphorus', nutritionTypeCode: 'PHOSPHORUS' },
  { nutritionName: 'Magnesium', nutritionTypeCode: 'MAGNESIUM' },
  { nutritionName: 'Zinc', nutritionTypeCode: 'ZINC' },
  { nutritionName: 'Selenium', nutritionTypeCode: 'SELENIUM' },
  { nutritionName: 'Phosphorus', nutritionTypeCode: 'PHOSPHORUS' },
  { nutritionName: 'Copper', nutritionTypeCode: 'COPPER' },
  { nutritionName: 'Manganese', nutritionTypeCode: 'MANGANESE' },
];

const getDisplayNameUom = (enumProperties, code) => {
  if (enumProperties?.length > 0 && code) {
    const foundEnum = enumProperties.find(
      (item) => item?.enumDisplayName === code
    );
    return foundEnum?.enumDisplayName ?? code;
  }
  return code;
};

const gramUnits = [
  'gram',
  'milligram',
  'microgram',
  'international unit per litre [iu/l]',
  'international unit per millilitre [iu/ml]',
];
const checkIsTypeOfGramUnits = (type) => {
  return gramUnits.includes(type);
};

const getGramUnitsAbbreviations = (type) => {
  if (type === 'gram') return 'g';
  if (type === 'milligram') return 'mg';
  if (type === 'microgram') return 'mcg';
  if (type === 'international unit per litre [iu/l]') return 'IU/L';
  if (type === 'international unit per millilitre [iu/ml]') return 'IU/ML';
};

// Abbreviation
export const getUomTypesAbbreviations = (enumProperties, types = '') => {
  if (checkIsTypeOfGramUnits(types?.toLowerCase())) {
    return getGramUnitsAbbreviations(types?.toLowerCase());
  } else {
    switch (types) {
      // Maybe later unit bellow will change
      case 'ONZ':
        return 'oz';
      case 'KGM':
        return 'kg';
      case 'LBR':
        return 'lb';
      case 'TNE':
        return 't';
      case 'X_NGM':
        return 'ng';
      case '_23':
        return 'g/m³';
      case '_4G':
        return 'µL';

      // case '_58':
      //   return 'NAN';
      case 'c':
        return 'DWT';
      case 'APZ':
        return 'oz t';
      case 'BFT':
        return 'FBM';
      case 'BLL':
        return 'bbl';
      case 'BP':
        return 'BDFT';
      case 'BUA':
        return 'bsh.';
      case 'BUI':
        return 'bsh.';
      case 'C18':
        return 'mmol';
      case 'C34':
        return 'mol';
      case 'CGM':
        return 'cg';
      case 'CLT':
        return 'cl';
      case 'CMQ':
        return 'cc';
      case 'CTM':
        return 'ct';
      case 'CWA':
        return 'CWT';
      case 'CWI':
        return 'CWT';
      case 'D40':
        return 'TL';
      case 'D43':
        return 'AMU';
      case 'DG':
        return 'dg';
      case 'DLT':
        return 'dl';
      case 'DMQ':
        return 'DM3';
      // case 'DRA':
      //   return 'NAN';
      // case 'DRI':
      //   return 'NAN';
      case 'E4':
        return 'GKG';
      case 'FH':
        return 'μmol';
      case 'FTQ':
        return 'cu ft';
      // case 'G21':
      //   return 'NAN';
      case 'G23':
        return 'pk';
      case 'G24':
        return 'tbsp';
      case 'G25':
        return 'tsp.';
      case 'GFI':
        return 'NAN';
      case 'GL':
        return 'g/L';
      case 'GLI':
        return 'gal';
      case 'GLL':
        return 'gal';
      case 'GRN':
        return 'gr';
      case 'H83':
        return 'L/Kg';
      case 'HGM':
        return 'hg';
      case 'HLT':
        return 'hl';
      case 'INQ':
        return 'cu in';
      case 'K6':
        return 'kl';
      case 'KDW':
        return 'KDW';
      // case 'KHY':
      //   return 'NAN';
      // case 'KMA':
      //   return 'NAN';
      // case 'KNI':
      //   return 'NAN';
      // case KPH
      // : return NAN"
      // case KPO
      // : return NAN"
      // case KPP
      // : return NAN"
      // case KSD
      // : return NAN"
      // case KSH
      // : return NAN"
      // case KUR
      // : return NAN"
      // case LPA
      // : return NAN"
      case 'LTN':
        return 't';
      case 'LTR':
        return 'L';
      case 'MLT':
        return 'ml';
      case 'MMQ':
        return 'mm3';
      case 'MTQ':
        return 'm³';
      case 'OZA':
        return 'fl. oz.';
      case 'OZI':
        return 'fl oz';
      case 'PTD':
        return 'pt';
      case 'PTI':
        return 'pt';
      case 'PTL':
        return 'NAN';
      case 'Q32':
        return 'fL';
      case 'Q33':
        return 'NAN';
      case 'Q34':
        return 'nL';
      // case QTD
      // : return NAN"
      // case QTL
      // : return NAN"
      case 'STN':
        return 't';
      case 'X_DWT':
        return 'dwt';

      default:
        return getDisplayNameUom(enumProperties, types);
    }
  }
};

export const DEFAULT_FOOTER_NOTE =
  'The % Daily Value(DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2, 000 calories a day is used for general nutrition advice.';
export const DEFAULT_FOOTER_NOTE_SUPPLEMENT =
  '* Percent Daily Values (DV) are based on a 2,000 calorie diet.\n† Daily Value (DV) not established.';
//? Fake data maybe can be used later
// const nutritionLabel = {
//   labelType: 'vertical',
//   //
//   productId: '',
//   productNutritionFactId: 0,
//   numberOfServingsPerPackage: '',
//   servingSize: '',
//   servingSizeMeasurement: '',
//   servingSizeDescription: '',
//   calories: '',
//   //

// serving: {
//   perContainer: 2,
//   sizeUnit: 'g',
//   quantity: 2,
//   units: 'Cookie',
//   size: 200,
//   calories: 400,
//   servingPer: 'Tablet',
// },

//   nutritionFactProperties: [
//     {
//       nutritionName: 'Total Fat',
//       quantity: '90',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Saturated Fat',
//       quantity: '15',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Trans Fat',
//       quantity: '20',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Cholesterol',
//       quantity: '200',
//       source: null,
//       quantityUom: 'mg',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Total Carbohydrate',
//       quantity: '30',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Dietary Fiber',
//       quantity: '30',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Total Sugars',
//       quantity: '30',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Protein',
//       quantity: '30',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },

//     {
//       nutritionName: 'Vitamin D',
//       quantity: '30',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Vitamin E',
//       quantity: '30',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Thiamin',
//       quantity: '30',
//       source: null,
//       quantityUom: 'g',
//       dailyValueIntakePercent: null,
//     },
//     {
//       nutritionName: 'Sodium',
//       quantity: '2.4',
//       source: null,
//       quantityUom: 'mg',
//       dailyValueIntakePercent: null,
//     },
//   ],

//   ingredientNotice: '',
//   fdaLabelFootNote: '',

//   ingredients: ['EGG', 'CREAM', 'SALT', 'POWDER'],
//   allergens: ['milk', 'wheat', 'egg', 'fish', 'cod'],
// };

// const supplementLabel = {
//   labelType: 'dual-vertical',
//   serving: {
//     perContainer: 2,
//     sizeUnit: 'g',
//     quantity: 2,
//     units: 'Cookie',
//     size: 200,
//     calories: 400,
//     servingPer: 'Tablet',
//   },

//   dailyValues2: [
//     { name: 'Vitamin C', value: '90', source: null, unit: 'g' },
//     { name: 'Vitamin E', value: '15', source: null, unit: 'mg' },
//     { name: 'Vitamin D', value: '20', source: '800 IU', unit: 'mcg' },
//     { name: 'Thiamin', value: '1.2', source: null, unit: 'mg' },
//     { name: 'Vitamin B12', value: '2.4', source: null, unit: 'mcg' },
//     { name: 'Biotin', value: '30', source: null, unit: 'mcg' },
//   ],
//   nonDailyValues: [
//     {
//       name: 'Omega-3',
//       value: '0.5',
//       source: 'fatty acids',
//       unit: 'g',
//     },
//   ],

//   allergens: ['milk', 'wheat', 'egg', 'fish', 'cod'],
// };
