import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Row, Col, Input, Space } from 'antd';
import AgGrid from 'common/components/ag-grid/AgGrid';
import { RoleEmptySection } from '../';
import {
  dialogFunction,
  FormDeleteButton,
  FormAddButton,
  WithAbsoluteContainer,
} from 'common/components';

import NewMemberModal from './NewMemberModal';

import * as roleSelectors from 'pages/role/controllers/selectors';
import * as roleActions from 'pages/role/controllers/actions';

import * as securityEndpoints from 'services/roles-security/endpoints';

import { useStatePrevious } from 'hooks/usePrevious';
import { useHandleReloadPage } from 'hooks/useReloadPage';
import usePrevious from 'hooks/usePrevious';
import { isEqual } from 'lodash';

import './styles.less';

const { Text } = Typography;
const { Search } = Input;

const rowStyle = {
  paddingBottom: 5,
};

const MemberRoleMemberList = (props) => {
  const { selectedRole } = props;

  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRow, refSelectedRow] = useStatePrevious([]);
  const [gridApi, setGridApi, refGridApi] = useStatePrevious(null);
  const [isAddNewMemberModalOpen, setIsAddNewMemberModalOpen] = useState(false);

  const dispatch = useDispatch();

  const refSearch = useRef();

  const roleMemberColDef = useSelector(roleSelectors.selectMemberRoleColDef());
  const memberNotInRoleColDef = useSelector(
    roleSelectors.selectMemberNotInRoleColDef()
  );

  const [reloadGrid, setReloadGrid] = useHandleReloadPage();

  const memberRoleGridColumns = roleMemberColDef?.data;
  const newMemberRoleGridColumns = memberNotInRoleColDef?.data;

  const roleId = selectedRole?.roleId;

  const prevRoleId = usePrevious(roleId);

  const toggleIsAddNewMemberModalOpen = () => {
    setIsAddNewMemberModalOpen((prev) => !prev);
  };

  const onSearch = (value) => {
    setSearchText(value);
    setSelectedRow([]);
  };

  const handleSelectRows = (selectedNodesDetail) => {
    const selectedMemberIdList = selectedNodesDetail.map(
      (nodeDetail) => nodeDetail?.id
    );
    setSelectedRow(selectedMemberIdList);
  };

  const resetSearch = () => {
    refSearch?.current?.handleReset &&
      refSearch.current.handleReset({
        type: 'click',
        target: refSearch.current.Input,
      });

    setSearchText('');
  };

  const handleDeleteMembersOfRole = async () => {
    const params = {
      memberIds: selectedRows,
    };

    const successCallback = () => {
      setReloadGrid();
    };

    dispatch(roleActions.removeMemberFromRole({ params, successCallback }));
  };

  const confirmClickDeleteBtn = () => {
    dialogFunction({
      type: 'warn',
      content: 'Do you want to remove these member(s) from role?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleDeleteMembersOfRole,
    });
  };

  const isRoleIdChange = !isEqual(roleId, prevRoleId);

  useEffect(() => {
    dispatch(
      roleActions.gridColumnRoleMembers({ gridName: 'members-in-role' })
    );
    dispatch(
      roleActions.gridColumnMembersNotInRole({
        gridName: 'member-list-for-add-role',
      })
    );
  }, []);

  useEffect(() => {
    if (isRoleIdChange) {
      resetSearch();
    }
  }, [isRoleIdChange]);

  useEffect(() => {
    refGridApi.current && refGridApi.current.sizeColumnsToFit();
  }, [gridApi]);

  const procSearchText = isRoleIdChange ? '' : searchText;

  const paramsGrid = {
    roleId,
    search: { searchText: procSearchText },
  };

  const mapId = (item) => ({
    ...item,
    id: `${item?.memberId}`,
  });

  return (
    <div className='member-role-member-list-section__wrap'>
      {memberRoleGridColumns && roleId && roleId !== 'new' ? (
        <>
          <Row gutter={[5, 10]} style={rowStyle}>
            <Col style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Text style={{ color: '#F3705A' }}>
                Add members to selected role or remove member(s) from selected
                role
              </Text>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Space>
                <FormAddButton
                  text='Add'
                  onClick={toggleIsAddNewMemberModalOpen}
                />
                <FormDeleteButton
                  text='Remove'
                  onClick={confirmClickDeleteBtn}
                  disabled={selectedRows?.length <= 0}
                />
                <Search
                  ref={refSearch}
                  placeholder='Search members...'
                  allowClear
                  onSearch={onSearch}
                  style={{ width: 160 }}
                  enterButton
                />
              </Space>
            </Col>
          </Row>
          <Row style={{ flex: 1 }}>
            <Col xs={24}>
              <AgGrid
                columnDefs={memberRoleGridColumns}
                urlGrid={securityEndpoints.GET_MEMBERS_HAVE_ROLE_ENDPOINT}
                paramsGrid={paramsGrid}
                getGridApi={(gridApi) => {
                  setGridApi(gridApi);
                }}
                isShowItemSelectionLog={false}
                onHandleSelectedRowCustom={handleSelectRows}
                responseParams={'data'}
                resizePagination={true}
                reloadGrid={reloadGrid}
                mapId={mapId}
              />
            </Col>
          </Row>
        </>
      ) : (
        <RoleEmptySection />
      )}
      <WithAbsoluteContainer>
        <NewMemberModal
          newMemberRoleGridColumns={newMemberRoleGridColumns}
          visible={isAddNewMemberModalOpen}
          onClose={toggleIsAddNewMemberModalOpen}
          selectedRole={selectedRole}
          mapId={mapId}
        />
      </WithAbsoluteContainer>
    </div>
  );
};

export default MemberRoleMemberList;
