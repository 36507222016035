import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  FilterOutlined,
  ClockCircleOutlined,
  PictureOutlined,
  ProfileOutlined,
  VideoCameraOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import classNames from 'classnames';

import RibbonButton from 'common/components/button/RibbonButton';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { useFilterGrid } from 'hooks/useFilterGrid';
import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';

import Messages from 'i18n/messages/home';

import { ROUTE, FILTER_TYPE_ASSETS } from 'static/Constants';

import './FilterSection.less';

const FilterSection = (props) => {
  const { viewType } = props;

  let { pathname } = useLocation();
  const dispatch = useDispatch();

  const { gridFilters, updateGridFilter } = useFilterGrid({
    entityType: 'asset',
  });

  const { label, icon, toggle } = useDisplayFilterButton({
    filterOptions: FILTER_TYPE_ASSETS,
    filters: gridFilters,
  });

  const handleFilterAssets = (filter) => {
    dispatch(actionsGridView.resetPageCurrent());
    if (gridFilters.indexOf(filter) > -1) {
      updateGridFilter(gridFilters.filter((val) => val !== filter));
    } else {
      let newFilterAssets = [...gridFilters];
      newFilterAssets.push(filter);
      updateGridFilter(newFilterAssets);
    }
  };

  const menuOptions = (
    <Menu>
      {viewType !== ROUTE.MEMBER_ASSETS && (
        <Menu.Item key='myAssets' style={{ backgroundColor: 'inherit' }}>
          <RibbonButton
            size='small'
            icon={<FilterOutlined />}
            label={Messages.ownerAsset}
            onClick={() => handleFilterAssets('owner')}
            toggle={gridFilters.indexOf('owner') > -1}
          />
        </Menu.Item>
      )}
      <Menu.Item key='pendingAsset' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<ClockCircleOutlined />}
          label={Messages.statusPendingAsset}
          onClick={() => handleFilterAssets('pending')}
          toggle={gridFilters.indexOf('pending') > -1}
        />
      </Menu.Item>
      {pathname === '/digital-media' ||
      pathname === '/favorite/favorite-assets' ? (
        <>
          <Menu.Item key='asset' style={{ backgroundColor: 'inherit' }}>
            <RibbonButton
              size='small'
              icon={<PictureOutlined />}
              label={Messages.showAllAssets}
              onClick={() => handleFilterAssets('asset')}
              className={classNames({
                'filter-section__filter-button--active':
                  gridFilters.indexOf('asset') > -1,
              })}
            />
          </Menu.Item>
          <Menu.Item key='document' style={{ backgroundColor: 'inherit' }}>
            <RibbonButton
              size='small'
              icon={<ProfileOutlined />}
              label={Messages.docAssets}
              onClick={() => handleFilterAssets('document')}
              className={classNames({
                'filter-section__filter-button--active':
                  gridFilters.indexOf('document') > -1,
              })}
            />
          </Menu.Item>
          <Menu.Item key='multimedia' style={{ backgroundColor: 'inherit' }}>
            <RibbonButton
              size='small'
              icon={<VideoCameraOutlined />}
              label={Messages.multiMedia}
              onClick={() => handleFilterAssets('multimedia')}
              className={classNames({
                'filter-section__filter-button--active':
                  gridFilters.indexOf('multimedia') > -1,
              })}
            />
          </Menu.Item>
        </>
      ) : null}
      <Menu.Item key='unmatched' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<WarningOutlined />}
          label={Messages.unmatched}
          onClick={() => handleFilterAssets('unmatched')}
          className={classNames({
            'filter-section__filter-button--active':
              gridFilters.indexOf('unmatched') > -1,
          })}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions} arrow>
      <RibbonButton icon={icon} label={label} toggle={toggle} />
    </Dropdown>
  );
};

export default FilterSection;
