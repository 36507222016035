import { useEffect, useState } from 'react';

import { getCategoryTreeView } from 'services/categoryManagement';
import { sortCategories } from 'common/components/product-category-tree/utils';
import { mapDataToTree } from '../utils';

export const useGetProductCategoryTreeView = (
  memberId,
  visible,
  isPrefetching,
  setIsPrefetching
) => {
  const [nestedCategory, setNestedCategory] = useState([]);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    if (!isPrefetching) return;

    if (memberId && visible) {
      setStatus('loading');

      getCategoryTreeView({ memberId })
        .then((res) => {
          if (res.isSuccess) {
            setStatus('success');

            const sortedTreeData = sortCategories(res.data.categories);
            setNestedCategory(
              sortedTreeData.map((categoryItem) =>
                mapDataToTree(categoryItem, null, null)
              )
            );
            setIsPrefetching && setIsPrefetching(false);
          } else {
            setStatus('error');
            setNestedCategory([]);
          }
        })
        .catch((err) => {
          setStatus('error');
          setNestedCategory([]);
          setIsPrefetching && setIsPrefetching(false);
        });
    }
  }, [memberId, visible, setIsPrefetching, isPrefetching]);

  return {
    nestedCategory,
    isLoading: status === 'loading',
    setNestedCategory,
  };
};
