/*
 * Login Messages
 *
 * This contains all the text for the login component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.login';

export default defineMessages({
  userName: {
    id: `${scope}.components.form.login.username`,
    defaultMessage: 'Username',
  },
  userNameWithColon: {
    id: `${scope}.components.form.login.userNameWithColon`,
    defaultMessage: 'Username:',
  },
  passwordWithColon: {
    id: `${scope}.components.form.login.passwordWithColon`,
    defaultMessage: 'Password:',
  },
  usernameRequired: {
    id: `${scope}.components.form.login.username.required`,
    defaultMessage: 'Please input your username!',
  },
  emailInvalid: {
    id: `${scope}.components.form.login.email.invalid`,
    defaultMessage: 'The input is not valid E-mail!',
  },
  password: {
    id: `${scope}.components.form.login.password`,
    defaultMessage: 'Password',
  },
  passwordRequired: {
    id: `${scope}.components.form.login.password.required`,
    defaultMessage: 'Please input your password!',
  },
  remember: {
    id: `${scope}.components.form.login.remember`,
    defaultMessage: 'Remember me',
  },
  forgot: {
    id: `${scope}.components.form.login.forgot`,
    defaultMessage: 'Forgot password',
  },
  login: {
    id: `${scope}.components.form.login.loginBtn`,
    defaultMessage: 'Sign In',
  },
  loginAsGuest: {
    id: `${scope}.components.form.login.loginAsGuest`,
    defaultMessage: 'Login As Guest',
  },
  loginError: {
    id: `${scope}.components.form.login.loginError`,
    defaultMessage: 'Cannot login',
  },
  getUserInfoError: {
    id: `${scope}.components.form.login.getUserInfoError`,
    defaultMessage: 'Cannot get user info',
  },
  powering: {
    id: `${scope}.components.layout.intro.powering`,
    defaultMessage: 'Powering',
  },
  slogan: {
    id: `${scope}.components.layout.intro.slogan`,
    defaultMessage: 'Any Content – Delivered Anywhere',
  },
  register: {
    id: `${scope}.components.layout.intro.register`,
    defaultMessage: 'Create Account',
  },
  loading: {
    id: `${scope}.components.layout.intro.loading`,
    defaultMessage: 'Submit',
  },
  introFirst: {
    id: `${scope}.components.layout.intro.first`,
    defaultMessage: 'Across CPG & ADV-wide',
  },
  introSecond: {
    id: `${scope}.components.layout.intro.second`,
    defaultMessage: 'GDSN Data Pool',
  },
  introThird: {
    id: `${scope}.components.layout.intro.third`,
    defaultMessage: 'Content Enhancements to Upsell and Bundle',
  },
  introFourth: {
    id: `${scope}.components.layout.intro.fourth`,
    defaultMessage: 'PIM, DAM & Content Health',
  },
  anonyIntro: {
    id: `${scope}.components.layout.intro.anony`,
    defaultMessage:
      'Guess accounts can access their scheduled meeting video call and some other features',
  },
});
