import * as types from './constants';

export function getMemberMaintenanceList(pageSize, pageNumber, search) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_GRID,
    pageSize: pageSize,
    pageIndex: pageNumber,
    'Search.SearchText': search,
  };
}

export function getMemberMaintenanceListSuccess(memberMaintenanceData, paging) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_GRID_SUCCESS,
    memberMaintenanceData,
    paging,
  };
}

export function getMemberMaintenanceListError(error) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_GRID_ERROR,
    error,
  };
}

//* -----------------
export function getUserMaintenanceList(pageSize, pageNumber, search) {
  return {
    type: types.GET_USER_MAINTENANCE_GRID,
    pageSize: pageSize,
    pageIndex: pageNumber,
    'Search.SearchText': search,
  };
}

export function getUserMaintenanceListSuccess(UserMaintenanceData, paging) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_GRID_SUCCESS,
    UserMaintenanceData,
    paging,
  };
}

export function getUserMaintenanceListError(error) {
  return {
    type: types.GET_USER_MAINTENANCE_GRID_ERROR,
    error,
  };
}
//* -------------------

export const getMemberMaintenanceGridColumns = (gridName) => ({
  type: types.GET_MEMBER_MAINTENANCE_GRID_COLUMN,
  gridName,
});

export const getMemberMaintenanceGridColumnsSuccess = (columns) => {
  return {
    type: types.GET_MEMBER_MAINTENANCE_GRID_COLUMN_SUCCESS,
    columns,
  };
};

export function getMemberMaintenanceGridColumnsError(error) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_GRID_COLUMN_ERROR,
    error,
  };
}

export function changeMemberGridInfoView(isMemberGridInfoView) {
  return {
    type: types.CHANGE_MEMBER_GRID_INFO_VIEW,
    isMemberGridInfoView,
  };
}

export function getAllCommunicationTemplatesList() {
  return {
    type: types.GET_ALL_COMMUNICATION_TEMPLATES_LIST,
  };
}

export function getAllCommunicationTemplatesListSuccess(
  communicationTemplates
) {
  return {
    type: types.GET_ALL_COMMUNICATION_TEMPLATES_LIST_SUCCESS,
    communicationTemplates,
  };
}

export function getAllCommunicationTemplatesListError(error) {
  return {
    type: types.GET_ALL_COMMUNICATION_TEMPLATES_LIST_ERROR,
    error,
  };
}

export function getMemberMaintenanceShortDetail(id) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_SHORT_DETAIL,
    id,
  };
}

export function getMemberMaintenanceShortDetailSuccess(data) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_SHORT_DETAIL_SUCCESS,
    data,
  };
}

export function getMemberMaintenanceShortDetailError(error) {
  return {
    type: types.GET_MEMBER_MAINTENANCE_SHORT_DETAIL_ERROR,
    error,
  };
}

export function getMemberMaintenancePublicContact(memberId) {
  return {
    type: types.GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE,
    memberId,
  };
}

export function getMemberMaintenancePublicContactSuccess(data) {
  return {
    type: types.GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_SUCCESS,
    data,
  };
}

export function getMemberMaintenancePublicContactError(error) {
  return {
    type: types.GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_ERROR,
    error,
  };
}

export const showExportLoading = () => {
  return {
    type: types.SHOW_EXPORT_LOADING,
  };
};

export const hideExportLoading = () => {
  return {
    type: types.HIDE_EXPORT_LOADING,
  };
};

export function setCurrentExecutionTemplate(payload) {
  return {
    type: types.MEMBER_MAINTAIN_SET_CURRENT_EXECUTION_TEMPLATE,
    payload,
  };
}

export function setTemplateValidation(payload) {
  return {
    type: types.MEMBER_MAINTAIN_SET_TEMPLATE_VALIDATION,
    payload,
  };
}

export const toggleAddNewUser = (isAddNewUser) => ({
  type: types.TOGGLE_ADD_NEW_USER,
  isAddNewUser,
});

export const toggleEditUser = (isEditUser) => ({
  type: types.TOGGLE_EDIT_USER,
  isEditUser,
});
