export const jsonChecker = (text) => {
  if (!text) return false;
  if (
    /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          ']'
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  ) {
    return 1;
  } else {
    return 0;
  }
};

// * check if valid json => convert to object markdown
export const mapJSONToMarkdownObject = (text) => {
  try {
    const result = JSON.parse(text);
    return result;
  } catch (e) {
    return {
      content: text,
      reference: {
        table: [],
        totalTable: 0,
      },
    };
  }
};

//* check valid json
export const MapJsonStringToObject = (string, returnOriginal = false) => {
  try {
    const result = JSON.parse(string);
    return result;
  } catch (e) {
    if (returnOriginal) return { data: string };
    return {};
  }
};
