import { get } from 'lodash';

export const getUserAddress = (info) => {
  const mainAddress = get(info, 'mainAddress', '');
  const address2 = get(info, 'address2', '');
  const address3 = get(info, 'address3', '');
  const state = get(info, 'state', '');
  const city = get(info, 'city', '');
  const country = get(info, 'country', '');
  const zipCode = get(info, 'zipcode', '');
  return {
    address1: mainAddress,
    address2: address2,
    address3: address3,
    state,
    city,
    country,
    zipCode,
  };
};

export const getUserAddressMemberProfile = (info) => {
  const mainAddress = get(info, 'primaryAddressMainAddress', '');
  const address2 = get(info, 'address2', '');
  const address3 = get(info, 'address3', '');
  const state = get(info, 'primaryAddressState', '');
  const city = get(info, 'primaryAddressCity', '');
  const country = get(info, 'primaryAddressCountry', '');
  const zipCode = get(info, 'primaryAddressZipcode', '');
  return {
    address1: mainAddress,
    address2: address2,
    address3: address3,
    state,
    city,
    country,
    zipCode,
  };
};

export const getUserContact = ({
  phoneNumber: phone,
  mobileNumber: mobile,
  faxNumber: fax,
  email,
}) => {
  return [
    {
      label: 'Phone',
      info: phone,
    },
    {
      label: 'Mobile',
      info: mobile,
    },
    {
      label: 'Fax',
      info: fax,
    },
    {
      label: 'Email',
      info: email,
    },
  ];
};

export const getMemberContactInfomation = (info) => {
  const contact = get(info, 'contact', false);
  const phone = get(info, 'phone', false);
  const fax = get(info, 'fax', false);
  const email = get(info, 'email', false);

  return [
    contact && {
      label: 'Contact',
      info: contact,
    },
    {
      label: 'Phone',
      info: phone,
    },
    {
      label: 'Fax',
      info: fax,
    },
    {
      label: 'Email',
      info: email,
    },
  ];
};

export const getContactCardInfo = (info) => {
  return {
    fullName: `${info?.firstName} ${info.lastName}`,
    image: info?.imageUrl,
    position: info?.position,
    address: info?.primaryAddress?.mainAddress,
    phoneNumber: info?.primaryAddress?.phoneNumber,
    mobileNumber: info?.primaryAddress?.mobileNumber,
    faxNumber: info?.primaryAddress?.faxNumber,
    country: info?.primaryAddress?.country,
    city: info?.primaryAddress?.city,
    state: info?.primaryAddress?.state,
    email: info?.email,
  };
};
