import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal } from 'antd';
import { Form, CustomNotification } from 'common/components';
import * as userServices from 'services/user';
import * as action from 'redux/user/actions';
import userSelectors from 'redux/user/selectors';

import './EditUserName.less';

const EditUserName = (props) => {
  const dispatch = useDispatch();
  const { userId, visible, onClose, firstName, lastName } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const loginUserInfo = useSelector(userSelectors.makeSelectUserInfo());

  const isEditUserIsCurrentUser = loginUserInfo?.id === userId;

  useEffect(() => {
    form.setFieldsValue({
      firstName: firstName,
      lastName: lastName,
    });
  }, [firstName, lastName, visible]);

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const response = await userServices.editUserProfileFullName({
          ...values,
          userId: userId,
        });
        if (response.isSuccess) {
          onCloseModal();
          dispatch(action.getUserProfileHeader(userId));
          dispatch(action.getUserProfile());

          if (isEditUserIsCurrentUser) {
            dispatch(action.updateUserInfo({ ...loginUserInfo, ...values }));
          }
          CustomNotification.success('Updated successfully');
        } else {
          CustomNotification.error('Updated Fail!');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const onCloseModal = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title='Edit Username'
      onCancel={onCloseModal}
      okText='Save'
      onOk={onFinish}
      maskClosable={false}
      confirmLoading={loading}
    >
      <Form name='username-edit' form={form} className='edit-username'>
        <Form.Item
          name='firstName'
          label='First name'
          rules={[
            {
              required: true,
              message: 'First name is required',
            },
          ]}
        >
          <Input placeholder={'First name'} allowClear />
        </Form.Item>
        <Form.Item
          name='lastName'
          label='Last name'
          rules={[
            {
              required: true,
              message: 'Last name is required',
            },
          ]}
        >
          <Input allowClear placeholder={'Last name'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditUserName.propTypes = {};

export default EditUserName;
