import React from 'react';

import { useNode, useEditor } from '@craftjs/core';
import classnames from 'classnames';

import EmailEditorSection from './EmailEditorSection';
import EmailEditorInlineText from './EmailEditorInlineText';
import EmailEditorContentElement from './EmailEditorContentElement';
import {
  EmailEditorSectionSetting,
  EmailEditorProductRequestSettings,
} from '../settings';

import { useEmailEditorContext } from '../../controllers/context';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const EmailEditorProductRequested = (props) => {
  const intl = useIntl();
  const { productData } = useEmailEditorContext();
  const { id, selected } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const { actions } = useEditor();

  const { text, settings } = props;

  const onChangeContent = (value) => {
    actions.history.throttle().setProp(id, (props) => (props.text = value));
  };

  const renderProductTable = () => {
    const cols = [
      'gtin',
      'upc12',
      'name',
      'brand',
      'description',
      'size',
      'sizeUom',
    ];
    const widths = {
      upc12: 120,
      gtin: 120,
      name: 150,
      brand: 100,
      description: 200,
      size: 50,
      sizeUom: 80,
    };

    const headingStyle = {
      fontWeight: 600,
      backgroundColor: '#f0f0f0',
      color: '#333',
      wordBreak: 'keep-all',
    };

    const colStyle = {
      textAlign: 'left',
      padding: 4,
      border: '1px solid #f0f0f0',
      wordBreak: 'break-all',
      verticalAlign: 'top',
      color: settings.color,
      fontWeight: settings.bold ? 600 : 400,
      textDecoration: settings.underline ? 'underline' : 'none',
      fontStyle: settings?.italic ? 'italic' : 'normal',
      fontSize: `${settings?.fontSize}px`,
    };

    return productData ? (
      <div
        className={classnames({
          'email-editor__section-not-editable': selected,
        })}
        style={{ minWidth: '100%', overflowY: 'hidden', overflowX: 'auto' }}
      >
        <table
          width='100%'
          style={{
            minWidth: '100%',
            width: 'auto',
            borderCollapse: 'collapse',
          }}
        >
          <thead>
            <tr>
              {cols.map((col) => (
                <th
                  key={col}
                  style={{
                    ...colStyle,
                    ...headingStyle,
                  }}
                >
                  {intl.formatMessage(Messages[col])}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {cols.map((col) => (
                <td
                  key={col}
                  style={{
                    ...colStyle,
                    minWidth: widths[col],
                  }}
                >
                  {productData[col] || ''}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    ) : null;
  };

  return (
    <EmailEditorSectionSetting settings={<EmailEditorProductRequestSettings />}>
      <EmailEditorSection>
        <EmailEditorContentElement
          content={
            <EmailEditorInlineText
              content={text}
              onChangeContent={onChangeContent}
            />
          }
          style={{ marginBottom: 12 }}
        />
        <EmailEditorContentElement content={renderProductTable()} />
      </EmailEditorSection>
    </EmailEditorSectionSetting>
  );
};

EmailEditorProductRequested.craft = {
  displayName: 'Product Requested',
  props: {
    text: 'Products Requested:',
    settings: {
      bold: false,
      italic: false,
      underLine: false,
      fontSize: 14,
      color: '#333',
    },
  },
};

export default EmailEditorProductRequested;
