import produce from 'immer';
import * as types from './constants';

import {
  mapToProductSocialMedia,
  mapToMappingMetadata,
} from './mapper/mapToReducerState';

const initFetchState = {
  loading: false,
  error: null,
  data: null,
};

// initial state
export const initialState = {
  loading: false,
  productDetailHeader: null,
  error: false,

  fullViewLoading: false,
  productFull: null,
  productFullError: null,
  gridName: null,
  documents: {
    loading: false,
    error: false,
    documentList: [],
    totalItems: 0,
    pageSize: 20,
    pageIndex: 1,
  },
  imageryLoading: false,
  imageryStatus: 'idle',
  planogramImages: [],
  additionalImages: [],
  imageryError: null,
  isImageryOwner: false,
  activeContent: 'overview',
  selectedHierarchy: null,
  hierarchyEndpoint: null,

  brandImage: {
    loading: false,
    error: false,
    data: [],
    totalItems: 0,
    pageSize: 20,
    pageIndex: 1,
  },

  productCompanyInfo: initFetchState,
  socialMedia: initFetchState,
  marketingAsset: [],
  marketingAssetPrimary: [],
  marketingAssetError: null,

  dataPropertiesColumn: [],
  supportedMembersColumn: [],

  //* Product Facts Panel
  factsPanel: null,
  factsPanelLoading: false,
  updateFactsPanelStatus: false,
  updateFactsPanelErrorMessage: null,
  //*
  currentHierarchy: null,
  currentHierarchyShortDetails: {
    loading: false,
    error: false,
    data: {},
  },
  hierarchyShortDetailsDisplayMode: 'Metric',

  updateProductStatus: false,
  updateProductError: null,
  columnSubscriptionProduct: false,
  columnMapping: [],
  columnMappingSupportMem: [],
  selectionSubscription: false,
  mappingMetadata: initFetchState,
  selectedMappingSupportMem: [],
  reloadMappingSupportMemGrid: false,
  selectedMapping: null,

  productModules: {
    statusFetchingSchema: 'idle',
    schema: [],
  },

  columnsSyndicationHistory: [],
  productSyndicationHistoryShortDetails: {
    status: 'idle',
    data: {},
    error: null,
  },
  isProductDetailExpand: false,
};

/* eslint-disable default-case, no-param-reassign */
const productFullViewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // get product hierachy short detail
      case types.GET_PRODUCT_HIERARCHY_SHORT_DETAIL:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_PRODUCT_HIERARCHY_SHORT_DETAIL_SUCCESS:
        draft.loading = false;
        draft.productDetailHeader = action.payload;
        break;
      case types.RESET_PRODUCT_HIERARCHY_SHORT_DETAIL:
        draft.loading = false;
        draft.productDetailHeader = [];
        break;
      case types.GET_PRODUCT_HIERARCHY_SHORT_DETAIL_ERROR:
        draft.loading = false;
        draft.error = action.payload;
        break;

      //get marketing asset
      case types.GET_MARKETING_ASSETS:
        // draft.fullViewLoading = true;
        draft.marketingAsset = [];
        break;
      case types.GET_MARKETING_ASSETS_SUCCESS:
        draft.fullViewLoading = false;
        draft.marketingAsset = action.payload;
        break;

      case types.GET_MARKETING_ASSETS_FAIL:
        draft.fullViewLoading = false;
        draft.marketingAsset = action.payload;
        break;
      case types.GET_MARKETING_ASSETS_PRIMARY:
        // draft.fullViewLoading = true;
        draft.marketingAssetPrimary = null;
        break;
      case types.GET_MARKETING_ASSETS_PRIMARY_SUCCESS:
        draft.fullViewLoading = false;
        draft.marketingAssetPrimary = action.payload;
        break;
      case types.GET_MARKETING_ASSETS_PRIMARY_FAIL:
        draft.fullViewLoading = false;
        draft.marketingAssetPrimary = action.payload;
        break;
      // get product documents
      case types.GET_PRODUCT_DOCUMENTS:
        draft.documents['loading'] = true;
        break;
      // TODO: update response after available api
      case types.GET_PRODUCT_DOCUMENTS_SUCCESS:
        draft.documents['loading'] = false;
        draft.documents['documentList'] = action?.data?.gridData || [];
        draft.documents['pageIndex'] =
          action?.data?.paging?.currentPageIndex ||
          initialState.documents.pageIndex;
        draft.documents['pageSize'] =
          action?.data?.paging?.currentPageSize ||
          initialState.documents.pageSize;
        draft.documents['totalItems'] =
          action?.data?.paging?.totalRecord ||
          initialState.documents.totalItems;
        break;
      case types.GET_PRODUCT_DOCUMENTS_ERROR:
        draft.documents['loading'] = false;
        break;
      case types.UPDATE_ACTIVE_CONTENT:
        draft.activeContent = action?.payload;
        break;
      case types.UPDATE_SELECTED_HIERARCHY:
        draft.selectedHierarchy = action?.payload;
        break;
      case types.GET_PRODUCT_IMAGERY:
        draft.imageryLoading = true;
        draft.imageryStatus = 'fetching';
        draft.imageryError = null;
        draft.additionalImages = [];
        draft.planogramImages = [];
        break;
      case types.GET_PRODUCT_IMAGERY_SUCCESS:
        draft.imageryLoading = false;
        draft.imageryStatus = 'success';
        draft.additionalImages = action.payload.additionalImages;
        draft.planogramImages = action.payload.planogramImages;
        break;
      case types.GET_PRODUCT_IMAGERY_FAIL:
        draft.imageryLoading = false;
        draft.imageryStatus = 'error';
        draft.imageryError = action.error;
        draft.additionalImages = [];
        draft.planogramImages = [];
        break;
      case types.RESET_PRODUCT_IMAGERY:
        draft.imageryLoading = false;
        draft.imageryStatus = 'idle';
        draft.imageryError = null;
        draft.additionalImages = [];
        draft.planogramImages = [];
        break;
      case types.SET_CURRENT_HIERARCHY:
        draft.currentHierarchy = action.payload;
        break;
      case types.UPDATE_HIERARCHY_ENDPOINT:
        draft.hierarchyEndpoint = action.payload;
        break;
      case types.GET_PRODUCT_BRAND_IMAGE:
        draft.brandImage['loading'] = true;
        break;
      case types.GET_PRODUCT_BRAND_IMAGE_SUCCESS:
        draft.brandImage['loading'] = false;
        draft.brandImage['data'] = action?.data?.gridData || [];
        draft.brandImage['pageIndex'] =
          action?.data?.paging?.currentPageIndex ||
          initialState.brandImage.pageIndex;
        draft.brandImage['pageSize'] =
          action?.data?.paging?.currentPageSize ||
          initialState.brandImage.pageSize;
        draft.brandImage['totalItems'] =
          action?.data?.paging?.totalRecord ||
          initialState.brandImage.totalItems;
        break;
      case types.GET_PRODUCT_BRAND_IMAGE_FAIL:
        draft.brandImage['loading'] = false;
        break;

      case types.GET_PRODUCT_SOCIAL_MEDIA:
      case types.GET_PRODUCT_SOCIAL_MEDIA_SUCCESS:
      case types.GET_PRODUCT_SOCIAL_MEDIA_FAIL:
        mapToProductSocialMedia(action, draft);
        break;
      //* Facts Panel
      case types.GET_PRODUCT_FACTS_PANEL:
        draft.factsPanel = null;
        draft.factsPanelLoading = true;
        break;
      case types.GET_PRODUCT_FACTS_PANEL_SUCCESS:
        draft.factsPanel = action?.data;
        draft.factsPanelLoading = false;
        break;
      case types.GET_PRODUCT_FACTS_PANEL_ERROR:
        draft.factsPanelLoading = false;
        break;

      case types.UPDATE_PRODUCT_FACTS_PANEL:
        draft.updateFactsPanelStatus = true;
        break;
      case types.UPDATE_PRODUCT_FACTS_PANEL_SUCCESS:
        draft.updateFactsPanelStatus = false;
        break;
      case types.UPDATE_PRODUCT_FACTS_PANEL_ERROR:
        draft.updateFactsPanelStatus = false;
        draft.updateFactsPanelErrorMessage = 'Something went wrong!';
        break;
      //* End Facts Panel

      // Product hierarchy short details to show drawer
      case types.GET_PRODUCT_HIERARCHY_DETAILS:
        draft.currentHierarchyShortDetails['loading'] = true;
        draft.error = null;
        break;
      case types.GET_PRODUCT_HIERARCHY_DETAILS_SUCCESS:
        draft.currentHierarchyShortDetails['loading'] = false;
        draft.currentHierarchyShortDetails['data'] = action?.data;
        break;
      case types.GET_PRODUCT_HIERARCHY_DETAILS_ERROR:
        draft.currentHierarchyShortDetails['loading'] = false;
        draft.error = action.error;
        break;
      case types.RESET_PRODUCT_HIERARCHY_DETAILS:
        draft.currentHierarchyShortDetails['loading'] = false;
        draft.currentHierarchyShortDetails['error'] = false;
        draft.currentHierarchyShortDetails['data'] = {};
        break;
      // update hierarchy short details mode to show drawer
      case types.UPDATE_HIERARCHY_SHORT_DETAILS_DISPLAY_MODE:
        draft.hierarchyShortDetailsDisplayMode =
          action.hierarchyShortDetailsDisplayMode;
        break;
      case types.EDIT_PRODUCT_DETAIL:
        draft.updateProductStatus = true;
        draft.updateProductError = null;
        break;
      case types.EDIT_PRODUCT_DETAIL_SUCCESS:
        draft.updateProductStatus = false;

        break;
      case types.EDIT_PRODUCT_DETAIL_FAIL:
        draft.updateProductStatus = false;
        draft.updateProductError = true;
        break;
      case types.SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO:
        draft.loadingColumnSubscriptionProduct = true;
        draft.columnSubscriptionProduct = false;
        draft.errorColumnSubscriptionProduct = false;
        break;
      case types.SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_SUCCESS:
        draft.loadingColumnSubscriptionProduct = false;
        draft.columnSubscriptionProduct = action.columnSubscriptionProduct;
        break;
      case types.SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_ERROR:
        draft.errorColumnSubscriptionProduct = action.error;
        draft.loadingColumnSubscriptionProduct = false;
        break;

      //* mapping grid column
      case types.GET_MAPPING_GRID_COLUMN:
        draft.columnMapping = [];
        break;
      case types.GET_MAPPING_GRID_COLUMN_SUCCESS:
        draft.columnMapping = action.payload;
        break;

      //* mapping support mem grid column
      case types.GET_MAPPING_SUPPORT_MEM_COLUMN:
        draft.columnMappingSupportMem = [];
        break;
      case types.GET_MAPPING_SUPPORT_MEM_COLUMN_SUCCESS:
        draft.columnMappingSupportMem = action.payload;
        break;

      case types.UPDATE_SELECTION_SUBSCRIPTION:
        draft.selectionSubscription = action.selectionSubscription;
        break;

      case types.GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN:
        draft.loading = true;
        draft.error = false;
        draft.gridName = action.gridName;
        break;
      case types.GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_SUCCESS:
        draft.loading = false;
        draft.dataPropertiesColumn = action.columns;
        break;
      case types.GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_ERROR:
        // draft.loading = false;
        break;

      //* mapping metadata
      case types.GET_MAPPING_METADATA:
      case types.GET_MAPPING_METADATA_SUCCESS:
      case types.GET_MAPPING_METADATA_FAIL:
        mapToMappingMetadata(action, draft, 'mappingMetadata');
        break;

      //* selected mapping support member
      case types.SET_SELECTED_MAPPING_SUPPORT_MEM:
        draft.selectedMappingSupportMem = action.payload;
        break;

      //* reload mapping support member grid
      case types.RELOAD_MAPPING_SUPPORTED_MEMBER_GRID_SUCCESS:
        draft.reloadMappingSupportMemGrid = action.status;
        break;

      case types.SAVE_SELECTED_MAPPING:
        draft.selectedMapping = action.id;
        break;

      case types.GET_SYNDICATION_HISTORY_GRID_COLUMNS:
        draft.loading = true;
        break;
      case types.GET_SYNDICATION_HISTORY_GRID_COLUMNS_SUCCESS:
        draft.loading = false;
        draft.columnsSyndicationHistory = action.columnsSyndicationHistory;
        break;
      case types.GET_SYNDICATION_HISTORY_GRID_COLUMNS_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.GET_SYNDICATION_HISTORY_SHORT_DETAILS:
        draft.productSyndicationHistoryShortDetails['status'] = 'loading';
        draft.error = false;
        break;
      case types.GET_SYNDICATION_HISTORY_SHORT_DETAILS_SUCCESS:
        draft.productSyndicationHistoryShortDetails['status'] = 'success';
        draft.productSyndicationHistoryShortDetails['data'] = action.data;
        break;
      case types.GET_SYNDICATION_HISTORY_SHORT_DETAILS_ERROR:
        draft.productSyndicationHistoryShortDetails['status'] = 'error';
        draft.error = action.payload;
        break;
      case types.TOGGLE_PRODUCT_DETAIL_EXPAND:
        draft.isProductDetailExpand = action.status;
        break;
    }
  });

export default productFullViewReducer;
