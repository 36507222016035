import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as qaSpecServices from 'services/qaSpec';

export const useGetMicrobiologicalCharacteristicsQuery = ({ productId }) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery({
    queryKey: getMicrobiologicalCharacteristics(productId),
    queryFn: async () => {
      return await qaSpecServices.getQaSpecMicrobiologicalCharacteristic({
        productId,
      });
    },
    enabled: Boolean(productId),
  });

  const handleRefetchMicrobiologicalCharacteristics = () => {
    queryClient.invalidateQueries({
      queryKey: getMicrobiologicalCharacteristics(productId),
    });
  };

  return {
    microbiologicalCharacteristicData: data?.data,
    handleRefetchMicrobiologicalCharacteristics,
    ...rest,
  };
};

export const getMicrobiologicalCharacteristics = (productId) => {
  return ['qa-spec', parseInt(productId), 'qa-microbiological-characteristics'];
};
