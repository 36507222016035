import React from 'react';

import classnames from 'classnames';
import { Collapse, Typography } from 'antd';

import NestedGrid from './NestedGrid';
import NestedArrayTypeAttribute from './NestedArrayTypeAttribute';

import * as utils from './utils';

import './NestedDetailCellRenderer.less';

const { Panel } = Collapse;
const { Text } = Typography;

const NestedDetailCellRenderer = (props) => {
  const { data } = props;

  const customProps = data?._customProps;

  const filterRenderData = () => {
    const columnData = customProps?.columnData;
    const parentPath = customProps?.parentPath || '';
    const parentPathArr = parentPath ? parentPath.split('.') : [];

    const childrenColumns = columnData.filter((columnItem) => {
      const pathArray = columnItem?.fieldName
        ? columnItem?.fieldName.split('.')
        : [];
      const isChildPath =
        columnItem.fieldName.indexOf(parentPath) > -1 &&
        pathArray.length === parentPathArr.length + 1;

      const isObject = columnItem?.dataType === 'object';
      const isSecondaryModule = columnItem?.isModule
        ? !columnItem?.isPrimary
        : true;

      return isChildPath && isObject && isSecondaryModule;
    });

    return childrenColumns;
  };

  const renderFields = filterRenderData();

  return (
    <div
      className='scroller nested-detail-grid'
      style={{ height: '100%', padding: '0px 0px 0 25px' }}
    >
      <Collapse accordion destroyInactivePanel>
        {renderFields?.map((fieldColumnData, index) => {
          const columnData = data?._customProps?.columnData;

          const fieldNameArr = fieldColumnData.fieldName.split('.');
          const dataKey = fieldNameArr[fieldNameArr.length - 1];

          const dataValue = utils.getNestedGridData({
            data,
            fieldName: dataKey,
          });
          const nestedGridColumns = utils.filterAttributeFieldColumn({
            parentPath: fieldColumnData.fieldName,
            columnData,
          });
          const shouldShowHeader = !!nestedGridColumns.length;
          const shouldRenderDetail = fieldColumnData?.isMasterDetail;

          return (
            <Panel
              className={classnames({
                'product-nested-detail-grid__panel': true,
                'product-nested-detail-grid__panel--module':
                  fieldColumnData?.isModule,
              })}
              header={
                <Text style={{ fontWeight: 700 }}>
                  {fieldColumnData?.displayName}
                </Text>
              }
              key={index}
            >
              <NestedGrid
                data={dataValue}
                columnData={columnData}
                parentPath={fieldColumnData.fieldName}
                shouldRenderDetail={shouldRenderDetail}
                shouldShowHeader={shouldShowHeader}
              />
              {!shouldRenderDetail ? (
                <NestedArrayTypeAttribute
                  data={dataValue}
                  columnData={columnData}
                  parentPath={fieldColumnData.fieldName}
                />
              ) : null}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default NestedDetailCellRenderer;
