import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components/index';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as eulaMaintenanceActions from 'pages/eula-maintenance/controllers/actions';
import * as eulaMaintenanceSelectors from 'pages/eula-maintenance/controllers/selectors';
import * as eulaMaintenanceService from 'services/eulaMaintenance';
import * as selectorsGlobal from 'redux/global/selectors';

import { useFilterGrid } from 'hooks/useFilterGrid';

import Messages from 'i18n/messages/maintenance';
import { injectIntl } from 'react-intl';

const DeleteEulaGridView = ({ intl }) => {
  const dispatch = useDispatch();

  const pageSizePagination = useSelector(
    eulaMaintenanceSelectors.selectEulaGridPageSize()
  );
  const pageNumberPagination = useSelector(
    eulaMaintenanceSelectors.selectEulaGridPageNumber()
  );
  const searchAllItems = useSelector(selectorsGlobal.selectSearchAllItems());

  useFilterGrid({
    clearOtherFilter: true,
  });

  const selectedEulaDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  const callApiDelete = async () => {
    const eulaIds = selectedEulaDetail.map((eula) => {
      return eula.id;
    });

    try {
      const response = await eulaMaintenanceService.deleteEula({
        eulaIds,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        dispatch(
          eulaMaintenanceActions.getEulaList({
            pageSizePagination,
            pageNumberPagination,
            searchAllItems,
          })
        );
        showSuccessMessageDialog();
      } else {
        showErrorMessageDialog(message);
      }
    } catch (error) {
      showErrorMessageDialog(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const showErrorMessageDialog = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <RibbonButton
      size='small'
      icon={<DeleteOutlined />}
      label={Messages.deleteEula}
      onClick={confirmDelete}
      disabled={checkDisableDeleteEula(selectedEulaDetail)}
    />
  );
};

const checkDisableDeleteEula = (selectedItems) => {
  const notPendingItem = selectedItems.some(
    (item) => item.status === 'Archived' || item.status === 'Active'
  );
  if (selectedItems.length === 0) return true;

  if (notPendingItem) return true;

  return false;
};

export default injectIntl(DeleteEulaGridView);
