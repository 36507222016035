import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Select, Row, Col, Space } from 'antd';
import {
  Form,
  UploadFile,
  FormCancelButton,
  WrapperSelect,
} from 'common/components';

import { ArrowRightOutlined } from '@ant-design/icons';

import {
  PIM_ASSET_TYPE_DEFINE,
  ASSET_TYPE,
  ASSET_SUBTYPE,
  DOCUMENT_SUBTYPE,
  UPLOAD_MAX_SIZE,
  SUPPORT_IMAGES_TYPES,
} from 'static/Constants';

import { mapTypeToAssetSubType } from 'pages/product-full-view/mappers/mapTypeToAssetSubType';

import * as productEndpoints from 'services/product/endpoints';

import './UploadPimAsset.less';

const SUPPORT_COMMON_TYPES = [
  'jpeg',
  'png',
  'jpg',
  'tiff',
  'tif',
  'mp4',
  'mov',
  'avi',
  'mp3',
  'zip',
  'rar',
  'txt',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'bmp',
  'gif',
  'webm',
  'csv',
];

const SUPPORT_ADD_TYPES = [
  'jpg',
  'jpeg',
  'jfif',
  'png',
  'gif',
  'webp',
  'tiff',
  'tif',
  'psd',
  'bmp',
  'svg',
  'ai',
  'ico',
];

const SUPPORT_LOAD_TEST = ['doc', 'docx', 'xls', 'xlsx'];

const UploadPimAsset = (props) => {
  const {
    formInstance,
    onSelectAttachFile,
    addType,
    assetId,
    handleMoveToLinkAsset,
    isAllowedUpload,
    handleUploadSuccess,
    productInfo,
    isLoadTestResults,
  } = props;

  const [PLANO, ADD, MARKET, DOC, CER] = PIM_ASSET_TYPE_DEFINE;

  const DEFAULT_PLANO_FORM = {
    assetType: ASSET_TYPE[0],
    assetSubType: ASSET_SUBTYPE.find((subtype) => subtype === 'Planogram'),
  };

  // Marketing asset will be asset type and ecommerce subtype
  const DEFAULT_MARKET_FORM = {
    assetType: ASSET_TYPE[0],
    assetSubType: ASSET_SUBTYPE.find((subtype) => subtype === 'Ecommerce'),
  };

  const DEFAULT_DOC_FORM = {
    assetType: ASSET_TYPE[2],
    assetSubType: DOCUMENT_SUBTYPE[2],
  };

  const DEFAULT_ADD_FORM = {
    assetType: ASSET_TYPE[0],
    assetSubType: ASSET_SUBTYPE[1],
  };

  const DEFAULT_CERTIFICATE_FORM = {
    assetType: ASSET_TYPE[2],
    assetSubType: DOCUMENT_SUBTYPE[0],
  };

  const [subTypeOption, setSubTypeOption] = useState([]);

  const currentProduct = productInfo;

  const onValuesChange = (changedValues) => {
    const assetType = changedValues?.assetType;

    if (assetType) {
      const extractedSubType = mapTypeToAssetSubType(assetType, addType);

      const firstSubType = extractedSubType?.[0]?.value || null;

      setSubTypeOption(extractedSubType);
      formInstance.setFieldsValue({
        assetSubType: firstSubType,
      });
    }
  };

  useEffect(() => {
    if (addType === PLANO) {
      formInstance.setFieldsValue(DEFAULT_PLANO_FORM);
    } else if (addType === MARKET) {
      formInstance.setFieldsValue(DEFAULT_MARKET_FORM);
    } else if (addType === ADD) {
      formInstance.setFieldsValue(DEFAULT_ADD_FORM);
      setSubTypeOption(mapTypeToAssetSubType(ASSET_TYPE?.[0], addType));
    } else if (addType === DOC) {
      formInstance.setFieldsValue(DEFAULT_DOC_FORM);
      setSubTypeOption(mapTypeToAssetSubType(ASSET_TYPE?.[2], addType));
    } else if (addType === DOCUMENT_SUBTYPE[0]) {
      formInstance.setFieldsValue(DEFAULT_CERTIFICATE_FORM);
    }
  }, [addType]);

  const isAssetOptionDisabled = (type) => {
    if (addType === ADD) {
      if (type === ASSET_TYPE[2]) {
        return true;
      }
    }

    if (addType === MARKET) {
      //* != COMPANY => DISABLE
      if (type !== ASSET_TYPE[1]) {
        return true;
      }
    }

    return false;
  };

  const supportTypes = () => {
    if (isLoadTestResults) return SUPPORT_LOAD_TEST;

    switch (addType) {
      case PLANO:
      case MARKET:
        return SUPPORT_IMAGES_TYPES;

      case DOC:
      case CER:
        return SUPPORT_COMMON_TYPES;

      case ADD:
        return SUPPORT_ADD_TYPES;

      default:
        return [];
    }
  };

  return (
    <>
      <Form
        form={formInstance}
        id='upload-pim-asset-form'
        onValuesChange={onValuesChange}
      >
        <Form.Item
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          name={'assetType'}
          label='Asset Type'
          hidden
        >
          <WrapperSelect
            getPopupContainer={() =>
              document.getElementById('upload-pim-asset-form')
            }
            listHeight={140}
            disabled={addType || !!assetId}
          >
            {ASSET_TYPE?.slice()
              .sort()
              .map((item, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={item}
                    disabled={isAssetOptionDisabled(item)}
                  >
                    {item}
                  </Select.Option>
                );
              })}
          </WrapperSelect>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          name={'assetSubType'}
          label='Asset Sub Type'
          hidden
        >
          <WrapperSelect
            getPopupContainer={() =>
              document.getElementById('upload-pim-asset-form')
            }
            listHeight={140}
            disabled={addType || !!assetId}
          >
            {subTypeOption.map((item, index) => {
              return (
                <Select.Option
                  key={index}
                  value={item?.value}
                  disabled={item?.disabled}
                >
                  {item?.value}
                </Select.Option>
              );
            })}
          </WrapperSelect>
        </Form.Item>

        <div style={{ height: 350, padding: '0 20px' }}>
          <UploadFile
            showList
            showMultiple={false}
            showSubmitBtn
            onFileChange={onSelectAttachFile}
            supportTypes={supportTypes()}
            manualUpload
            showPreview
            apiUrl={
              assetId
                ? productEndpoints.REPLACE_PIM_ASSET
                : productEndpoints.UPLOAD_PIM_ASSET
            }
            additionalBodyPayload={async () => {
              const uploadFormValue = formInstance.getFieldsValue();

              const { assetType, assetSubType } = uploadFormValue;
              const ownerId = currentProduct?.ownerId;
              const productId = currentProduct?.productId;

              const additionalBodyPayload = assetId
                ? {
                    assetId,
                  }
                : { assetType, assetSubType, ownerId, productId };

              return additionalBodyPayload;
            }}
            getResponse={handleUploadSuccess}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
            showServerMessage
            forceDisableManudalUpload={!isAllowedUpload}
          />
        </div>

        <Col xs={10} />
      </Form>

      <Row style={{ margin: '20px 0' }}>
        <Col flex='auto' />
        <Col>
          <Space>
            {!isAllowedUpload && assetId && (
              <FormCancelButton
                icon={<ArrowRightOutlined />}
                text='Move to link step'
                disabled={isAllowedUpload}
                onClick={handleMoveToLinkAsset}
              />
            )}
          </Space>
        </Col>
        <Col flex='20px' />
      </Row>
    </>
  );
};

UploadPimAsset.propTypes = {
  formInstance: PropTypes.any,
  refUpload: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onSelectAttachFile: PropTypes.func,
  handleUploadNewAsset: PropTypes.func,
  addType: PropTypes.string,
  handleAttachFile: PropTypes.func,
  handlePreview: PropTypes.func,
  assetId: PropTypes.oneOf([PropTypes.number, null, PropTypes.string]),
  isUploadLoading: PropTypes.bool,
  handleMoveToLinkAsset: PropTypes.func,
  isAllowedUpload: PropTypes.bool,
};

export default UploadPimAsset;
