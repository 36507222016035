import DrugFactSection from './DrugFactSection';
import DrugFactHeading from './DrugFactHeading';
import DrugFactText from './DrugFactText';
import { SnapshotHighlightText } from '../../WrapperNutrient';

const DrugFactInactiveIngredients = ({ property, snapshotValue }) => {
  const { inactiveIngredients } = property;

  if (!inactiveIngredients) return null;

  return (
    <DrugFactSection className='drug-fact__inactive-ingredient'>
      <DrugFactHeading heading='Inactive ingredients' />
      <SnapshotHighlightText
        label={['inactiveIngredients']}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        <DrugFactText>{inactiveIngredients}</DrugFactText>
      </SnapshotHighlightText>
    </DrugFactSection>
  );
};

export default DrugFactInactiveIngredients;
