import { useSelector } from 'react-redux';
import userSelector from 'redux/user/selectors';

import * as memberSelectors from 'pages/company-profile/controllers/selectors';

export const useCheckPrivateMember = () => {
  const selectedMemberProfile = useSelector(
    memberSelectors.selectMemberProfile()
  );
  const isPrivateMember =
    selectedMemberProfile?.memberType?.toLowerCase() === 'private member' ||
    selectedMemberProfile?.memberType?.toLowerCase() === 'shared only';

  return isPrivateMember;
};

export const useCheckSharedOnlyMyCompany = () => {
  let userInfo = useSelector(userSelector.makeSelectUserInfo());
  const isSharedOnlyMember =
    userInfo?.member?.memberType?.toLowerCase() === 'manufacturer' ||
    userInfo?.member?.memberType?.toLowerCase() === 'shared only';
  return isSharedOnlyMember;
};
