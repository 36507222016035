import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Form, notification } from 'antd';

import { StyledModal, dialogFunction } from 'common/components';
import AplSendSampleRequestEditor from './AplSendSampleRequestEditor';
import AplSendSampleRequestTemplateList from './AplSendSampleRequestTemplateList';

import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';
import { toggleReloadGrid } from 'pages/product-apl-detail/controllers/actions';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import {
  EVENT,
  EMAIL_HTML_TEMPLATE,
  EMAIL_CONTENT_SHORT_CODE,
  APL_SAMPLE_REQUEST_TEMPLATE_LIST,
  APL_SAMPLE_REQUEST_DYNAMIC_CONTENT,
} from 'static/Constants';

import * as aplServices from 'services/apl';
import { editAplProductContactService } from 'services/aplDetail';
import { apiHandler } from 'utils/api';

import Messages from 'i18n/messages/apl';
import { useIntl } from 'react-intl';

import './AplSendSampleRequestModal.less';

const AplSendSampleRequestModal = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const dataRef = useRef();
  const productRef = useRef();
  const aplRef = useRef();

  const [emailForm] = Form.useForm();

  const { visible, setVisible, productData } = props;

  const [template, setTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);

  const [contactEmail, setContactEmail] = useState(null);
  const [invalidTag, setInvalidTag] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const [senderInfo, setEnderInfo] = useState();
  const [dynamicContent, setDynamicContent] = useState(
    APL_SAMPLE_REQUEST_DYNAMIC_CONTENT
  );

  const aplDetail = useSelector(makeSelectAplDetail());

  const emailTitle = `${
    productData?.gtin
      ? productData.gtin + ' - '
      : productData?.upc12
      ? productData?.upc12 + ' - '
      : ''
  }SAMPLE REQUEST`;

  const onCancel = () => {
    if (template) showCloseNotification();
    else closeModal();
  };

  const onSend = () => {
    const isTagValid = validateTag();

    if (!isTagValid) return;

    getEmailHtml();
    //* sending email flow will be continue in getHtmlSuccessCallback()
  };

  const closeModal = () => {
    setTemplate(null);
    setInvalidTag([]);
    setSendLoading(false);
    setVisible(false);
    setDynamicContent(APL_SAMPLE_REQUEST_DYNAMIC_CONTENT);
    dispatchClearSectionSelectionEvent();
  };

  const showCloseNotification = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.closeMessage),
      onOk: closeModal,
    });
  };

  const validateTag = () => {
    const isError = !!invalidTag.length;
    if (isError) triggerHighlightInvalidTag();

    return !isError;
  };

  const triggerHighlightInvalidTag = () => {
    const event = new CustomEvent(EVENT.EMAIL_EDITOR_HIGHLIGHT_ERROR_TAG, {
      detail: invalidTag,
    });

    document.dispatchEvent(event);
  };

  const getEmailHtml = () => {
    const event = new CustomEvent(EVENT.EMAIL_EDITOR_GET_HTML, {
      detail: dataRef.current,
    });

    document.dispatchEvent(event);
  };

  const getHtmlSuccessCallback = (e) => {
    //* continue to send email
    const html = e.detail;

    const emailContent = EMAIL_HTML_TEMPLATE.replace(
      EMAIL_CONTENT_SHORT_CODE,
      html
    );

    callApiSendSampleRequest(emailContent);
  };

  const getSenderInfo = () => {
    if (!visible) return;

    aplServices.getSampleRequestSenderData().then((response) => {
      const { isSuccess, data } = response;
      if (isSuccess && data) setEnderInfo(data);
    });
  };

  const callApiSendSampleRequest = async (emailContent) => {
    const emailFormValue = await emailForm.validateFields();

    if (!emailFormValue.email) return;

    setSendLoading(true);

    const params = {
      mailContents: [
        {
          email: emailFormValue.email,
          emailTitle: emailFormValue.emailTitle,
          emailContent,
        },
      ],
    };

    const successMessage = intl.formatMessage(
      Messages.sendSampleRequestSuccess
    );
    const errorMessage = intl.formatMessage(Messages.sendSampleRequestError);

    apiHandler({
      service: aplServices.sendSampleRequest,
      params,
      successMessage,
      errorMessage,
      successCallback: sendSampleRequestSuccessCallback,
      onFinally: onSendFinally,
    });
  };

  const sendSampleRequestSuccessCallback = () => {
    const { isOwner } = aplRef?.current;

    if (!isOwner) return;
    if (productRef?.current?.contactEmail) return;

    const email = emailForm.getFieldValue('email');
    const params = {
      aplProductId: productRef?.current?.id,
      contactEmail: email,
    };

    editAplProductContactService(params)
      .then((response) => {
        if (response?.isSuccess) {
          notification.success({
            message: intl.formatMessage(Messages.updateEmailSuccess),
          });
          dispatch(toggleReloadGrid(true));
          setContactEmail(email);
        } else {
          notification.error({
            message:
              response?.message ??
              intl.formatMessage(Messages.updateEmailError),
          });
        }
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage(Messages.updateEmailError),
        });
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(toggleReloadGrid(false));
        }, 200);
      });
  };

  const onSendFinally = () => {
    closeModal();
  };

  const getTemplateList = () => {
    setTemplateList(APL_SAMPLE_REQUEST_TEMPLATE_LIST);
  };

  const initEmailForm = () => {
    if (!visible) return;

    emailForm.setFieldsValue({
      emailTitle,
      email: productData?.contactEmail,
    });

    setContactEmail(productData?.contactEmail);
  };

  const updateProductAplRef = () => {
    if (!visible) return;
    productRef.current = productData;
    aplRef.current = aplDetail;
  };

  const dispatchClearSectionSelectionEvent = () => {
    const event = new Event(EVENT.EMAIL_EDITOR_CLEAR_SELECTED_SECTION);

    document.dispatchEvent(event);
  };

  useEffect(() => {
    getSenderInfo();
    getTemplateList();
    initEmailForm();
    updateProductAplRef();
  }, [visible]);

  useDocumentAttachEvent({
    name: EVENT.EMAIL_EDITOR_GET_HTML_SUCCESS,
    handler: getHtmlSuccessCallback,
  });

  return (
    <StyledModal
      title={intl.formatMessage(Messages.requestSample)}
      width={1240}
      bodyStyle={{ height: '80vh' }}
      visible={visible}
      maskClosable={false}
      onCancel={onCancel}
      onOk={onSend}
      okText={intl.formatMessage(Messages.send)}
      okButtonProps={{ loading: sendLoading }}
    >
      {template ? (
        <AplSendSampleRequestEditor
          invalidTag={invalidTag}
          setInvalidTag={setInvalidTag}
          productData={productData}
          dataRef={dataRef}
          emailTitle={emailTitle}
          contactEmail={contactEmail}
          emailForm={emailForm}
          template={template}
          dynamicContent={dynamicContent}
          setDynamicContent={setDynamicContent}
          senderInfo={senderInfo}
        />
      ) : (
        <AplSendSampleRequestTemplateList
          templateList={templateList}
          setTemplate={setTemplate}
        />
      )}
    </StyledModal>
  );
};

export default AplSendSampleRequestModal;
