import React, { useState, useEffect, useRef } from 'react';

const MdImage = (props) => {
  const {
    src,
    width,
    height,
    mapToPaddingStyle,
    alignConfig,
    spaceConfig,
  } = props;

  const [calHeight, setCalHeight] = useState(0);

  const refImg = useRef();

  const calculateHeight = (orgScale, width, height, curWidth) => {
    const curScale = orgScale * (width / height);
    return curWidth / curScale;
  };

  useEffect(() => {
    if (refImg?.current) {
      setTimeout(() => {
        const orgHeight = refImg?.current?.naturalHeight;
        const orgWidth = refImg?.current?.naturalWidth;

        const curWidth = refImg?.current?.offsetWidth;
        const orgScale = orgWidth / orgHeight;
        const newHeight = calculateHeight(orgScale, width, height, curWidth);

        if (newHeight !== 0 && newHeight !== calHeight) setCalHeight(newHeight);
      }, 100);
    }
  });

  return (
    <>
      <img
        ref={refImg}
        src={src}
        style={{
          width: `${width}%`,
          maxWidth: '100%',
          height: calHeight ? `${calHeight}px` : 'auto',
          transition: 'all 0.2s',
          ...mapToPaddingStyle(alignConfig, spaceConfig),
        }}
        align={alignConfig}
        alt='content-img'
      />
    </>
  );
};

export default MdImage;
