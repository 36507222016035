export const ADD_ASSET_TO_FAVORITE = '/api/favorites/add-assets-to-favorites';
export const ADD_MEMBER_TO_FAVORITE = '/api/favorites/add-members-to-favorites';
export const ADD_FOLDER_TO_FAVORITE = '/api/favorites/add-folders-to-favorites';
export const ADD_QUERY_TO_FAVORITE = '/api/favorites/add-query-to-favorites';
export const ADD_PRODUCT_TO_FAVORITE =
  '/api/favorites/add-products-to-favorites';

export const REMOVE_ASSET_FROM_FAVORITE =
  '/api/favorites/remove-assets-from-favorites';
export const REMOVE_MEMBER_FROM_FAVORITE =
  '/api/favorites/remove-members-from-favorites';
export const REMOVE_FOLDER_FROM_FAVORITE =
  '/api/favorites/remove-folders-from-favorites';
export const REMOVE_QUERY_FROM_FAVORITE =
  '/api/favorites/remove-query-from-favorites';
export const REMOVE_PRODUCT_FROM_FAVORITE =
  '/api/favorites/remove-products-from-favorites';
