import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PhoneOutlined,
  ContactsOutlined,
  MailOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import { concat, pick, get } from 'lodash';

import './ProfileContactHistory.less';

const ProfileContactHistory = (props) => {
  const { data, dataField, dataKey, typeField, arrowField, loading } = props;

  const iconSet = {
    Email: MailOutlined,
    Phone: PhoneOutlined,
    Message: MessageOutlined,
    Contact: ContactsOutlined,
  };

  // generate more columns
  const generateColumns = () => {
    return dataField && !!dataField.length
      ? dataField.map((field) => {
          const { key, options } = field;
          return {
            title: key,
            dataIndex: key,
            key: key,
            ...options,
          };
        })
      : [];
  };

  // generate data source from dataField, typeField and arrowField
  const generateDataSource = () => {
    const dataFieldKey =
      dataField &&
      !!dataField.length &&
      dataField.map((field) => {
        return field.key;
      });
    return data && !!data.length && true
      ? data.map((item) => {
          const modifiedItem = pick(item, dataFieldKey);
          modifiedItem.key = item[dataKey];
          modifiedItem.type = item[typeField];
          modifiedItem.arrow = item[arrowField];
          return modifiedItem;
        })
      : [];
  };

  // define the two first columns of contact history table
  const initColumns = [
    // the first column is in-out arrow icon
    {
      title: 'arrow',
      dataIndex: 'arrow',
      key: 'arrow',
      width: 30,
      render: (text) => {
        return text ? (
          <ArrowDownOutlined className='profile-contact-history__icon' />
        ) : (
          <ArrowUpOutlined className='profile-contact-history__icon' />
        );
      },
    },
    // the second column is type icon
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 30,
      render: (text) => {
        const TypeIcon = get(iconSet, text, ContactsOutlined);
        return <TypeIcon className='profile-contact-history__icon' />;
      },
    },
  ];
  // define more column
  const dataColumns = generateColumns();
  // merge initColumns with more columns
  const columns = concat(initColumns, dataColumns);
  // define data source
  const dataSource = generateDataSource();

  return (
    <Table
      className='profile-contact-history'
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      showHeader={false}
      loading={loading}
    />
  );
};

ProfileContactHistory.propTypes = {
  /**
   * Contact history data
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * Array of fields will show in table
   */
  dataField: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * key name of field
       */
      key: PropTypes.string,
      /**
       * Column props of Ant Table use for this field
       */
      options: PropTypes.object,
    })
  ),
  /**
   * Key for each item of dataSource of Ant Table
   */
  dataKey: PropTypes.string,
  /**
   * Key for selecting value of Type Icon column
   */
  typeField: PropTypes.string,
  /**
   * Key for selecting value of Arrow Icon column
   */
  arrowField: PropTypes.string,
};

export default ProfileContactHistory;
