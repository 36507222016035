import * as endpoints from './endpoints';
import * as api from 'config/axios';

export const getTicketGrid = (params) => {
  return api.sendPost(endpoints.GET_TICKETING_SYSTEM_GRID, params);
};

export const getTicketingSystemShortDetail = (params) => {
  return api.sendGet(endpoints.GET_TICKETING_SYSTEM_SHORT_DETAIL, { params });
};

export const uploadTicketAttachments = (params) => {
  return api.sendPost(endpoints.UPLOAD_TICKET_ATTACHMENTS, params);
};

export const getTicketAttachments = (params) => {
  return api.sendGet(endpoints.GET_TICKET_ATTACHMENTS, { params });
};

export const saveTicketAttachments = (params) => {
  return api.sendPost(endpoints.SAVE_TICKET_ATTACHMENTS, params);
};

export const uploadTicketImage = (params) => {
  return api.sendPost(endpoints.UPLOAD_TICKET_IMAGE, params);
};
export const deleteTicketingSystem = (params) => {
  return api.sendPost(endpoints.DELETE_TICKETING_SYSTEM, params);
};
export const getAllTicketTags = () => {
  return api.sendGet(endpoints.GET_ALL_TICKET_TAGS);
};

export const getTicketAssignment = (params) => {
  return api.sendGet(endpoints.GET_TICKET_ASSIGNMENT, { params });
};

export const saveTicketDetail = (params) => {
  return api.sendPost(endpoints.SAVE_TICKET_DETAIL, params);
};

export const getTicketSuppliers = () => {
  return api.sendGet(endpoints.GET_TICKET_SUPPLIERS);
};

export const getTicketFullView = (params) => {
  return api.sendGet(endpoints.GET_TICKET_FULL_VIEW, { params });
};

export const getTicketProduct = (params) => {
  return api.sendPost(endpoints.GET_TICKET_PRODUCT, params);
};

export const getTicketAsset = (params) => {
  return api.sendPost(endpoints.GET_TICKET_ASSET, params);
};

export const searchTicketProduct = (params) => {
  return api.sendPost(endpoints.SEARCH_TICKET_PRODUCT, params);
};
export const searchTicketAsset = (params) => {
  return api.sendPost(endpoints.SEARCH_TICKET_ASSET, params);
};

export const updateLastTimeGetNewTicket = () => {
  return api.sendPost(endpoints.UPDATE_LAST_TIME_GET_NEW_TICKET);
};

export const getListTicketGroup = (memberId) => {
  return api.sendGet(endpoints.GET_LIST_TICKET_GROUP, {
    params: { memberId: memberId },
  });
};

export const saveTicketGroup = (params) => {
  return api.sendPost(endpoints.SAVE_TICKET_GROUP, params);
};
export const deleteTicketGroup = (params) => {
  return api.sendPost(endpoints.DELETE_TICKET_GROUP, params);
};
export const getUsersInTicketGroup = (params) => {
  return api.sendPost(endpoints.GET_USER_IN_TICKET_GROUP, params);
};
export const getUsersNotInTicketGroup = (params) => {
  return api.sendPost(endpoints.GET_USER_NOT_IN_TICKET_GROUP, params);
};
export const addUserToTicketGroup = (params) => {
  return api.sendPost(endpoints.ADD_USER_IN_TICKET_GROUP, params);
};
export const removeUserFormTicketGroup = (params) => {
  return api.sendPost(endpoints.REMOVE_USER_FROM_TICKET_GROUP, params);
};
export const updateFollowTicket = (params) => {
  return api.sendPost(endpoints.UPDATE_FOLLOW_TICKET, params);
};

export const getTicketChangeLogList = (params) => {
  return api.sendGet(endpoints.GET_TICKET_CHANGELOG_LIST, { params });
};
export const getTicketChangeLogDetail = (params) => {
  return api.sendGet(endpoints.GET_TICKET_CHANGELOG_DETAIL, { params });
};

export const getTicketComment = (params) => {
  return api.sendGet(endpoints.GET_TICKET_COMMENTS, { params });
};

export const saveTicketComment = (params) => {
  return api.sendPost(endpoints.SAVE_TICKET_COMMENT, params);
};

export const deleteTicketComment = (params) => {
  return api.sendPost(endpoints.DELETE_TICKET_COMMENT, params);
};
