import React from 'react';
import { useDispatch } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { DeleteOutlined } from '@ant-design/icons';

import * as maintenanceFormActions from 'pages/maintenance-form/controllers/actions';

const DeleteFormBtn = (props) => {
  const { formId, ...restProps } = props;

  const dispatch = useDispatch();

  const onClickDelete = () => {
    dispatch(maintenanceFormActions.deleteFormItem([formId]));
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label='Delete'
      onClick={onClickDelete}
      {...restProps}
    />
  );
};

export default DeleteFormBtn;
