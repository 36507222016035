import React from 'react';
import { RibbonBar, RibbonDivider } from 'common/components';
import {
  ManageSharingSection,
  DetailSection,
  ViewLayout,
  AdvanceStack,
} from 'pages/home/ribbon/components';
import {
  CreateQasRequireField,
  EditQasRequireField,
  DeleteQasRequireField,
  CopyQasRequireFields
} from 'pages/home/ribbon/qas-require-field/components';

const QasRequireFieldRibbon = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <CreateQasRequireField />
      <EditQasRequireField />
      <DeleteQasRequireField />
      <RibbonDivider />
      <CopyQasRequireFields />
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default QasRequireFieldRibbon;
