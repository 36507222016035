import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const anonLoginForMeeting = (params) => {
  return api.sendPost(endpoints.ANON_LOGIN_FOR_MEETING, params);
};

export const anonGetMeetingDetails = (params) => {
  return api.sendGet(endpoints.ANON_GET_MEETING_DETAIL, { params });
};
