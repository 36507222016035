import { useSelector } from 'react-redux';

import userSelectors from 'redux/user/selectors';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as folderSelectors from 'pages/folders/controllers/selectors';

const useCheckIsFolderOwner = () => {
  const selectedFolders = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const shortDetail = useSelector(folderSelectors.selectFolderShortDetail()); //TODO: this is deprecated, try using selected folder to check
  let isOwner = false;
  if (shortDetail?.isOwner) {
    return true;
  }
  for (const asset of selectedFolders) {
    if (asset?.isOwner) return true;
  }

  return isOwner;
};

export default useCheckIsFolderOwner;
