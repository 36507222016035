import React from 'react';

import { Editor } from '@craftjs/core';

import {
  EmailEditorSigningOff,
  EmailEditorShippingTo,
  EmailEditorSenderInfo,
  EmailEditorGreeting,
  EmailEditorAdditionalInfo,
  EmailEditorProductRequested,
  EmailEditorFreeText,
  EditorToolbar,
  EmailEditorFrame,
  EmailEditorGetHtml,
} from './components';

import { EmailEditorProvider } from './controllers/context';

import './style.less';

const EmailEditor = (props) => {
  const {
    dynamicContent,
    productData,
    setDynamicContent,
    invalidTag,
    setInvalidTag,
    dataRef,
    emailTitle,
    senderInfo,
    template,
  } = props;

  const onNodeChange = (query) => {
    const json = query.serialize();
    dataRef.current = json;
  };

  return (
    <EmailEditorProvider
      dynamicContent={dynamicContent}
      setDynamicContent={setDynamicContent}
      productData={productData}
      invalidTag={invalidTag}
      setInvalidTag={setInvalidTag}
      senderInfo={senderInfo}
      template={template}
    >
      <Editor
        resolver={{
          EmailEditorSigningOff,
          EmailEditorShippingTo,
          EmailEditorSenderInfo,
          EmailEditorGreeting,
          EmailEditorAdditionalInfo,
          EmailEditorProductRequested,
          EmailEditorFreeText,
        }}
        onNodesChange={onNodeChange}
      >
        <EditorToolbar />
        <EmailEditorFrame subject={emailTitle} />
      </Editor>
      <EmailEditorGetHtml />
    </EmailEditorProvider>
  );
};

export default EmailEditor;
