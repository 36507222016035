import Messages from 'i18n/messages/member-profile';
import * as categoryManagement from 'services/categoryManagement';

export const CATEGORY_LEVEL_1 = 1;
export const CATEGORY_LEVEL_2 = 2;
export const CATEGORY_LEVEL_3 = 3;
export const CATEGORY_LEVEL_4 = 4;

export const BULK_REASSIGN_PRODUCTS = {
  from: {
    key: 'from',
    title: 'Source',
  },
  to: {
    key: 'to',
    title: 'Destination',
  },
};

export const PRODUCT_CATEGORY_LEVEL = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
};

export const CATEGORY_FORM_ITEM_INPUT = [
  {
    label: 'Category I',
    name: 'category1',
    level: CATEGORY_LEVEL_1,
  },
  {
    label: 'Category II',
    name: 'category2',
    level: CATEGORY_LEVEL_2,
  },
  {
    label: 'Category III',
    name: 'category3',
    level: CATEGORY_LEVEL_3,
  },
  {
    label: 'Category IV',
    name: 'category4',
    level: CATEGORY_LEVEL_4,
  },
];

export const MODE_SAVE_PRODUCT_CATEGORY_FORM = {
  add: {
    successMessage: Messages.addCategorySuccess,
    errorMessage: Messages.addCategoryFailed,
    apiService: categoryManagement.createCategory,
  },
  edit: {
    successMessage: Messages.editCategorySuccess,
    errorMessage: Messages.editCategoryFailed,
    apiService: categoryManagement.editCategory,
  },
};

export const LOADING_TYPE = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  download: 'download',
  'bulk-reassign': 'bulk-reassign',
};
