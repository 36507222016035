import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes, { oneOfType } from 'prop-types';
import {
  Row,
  Col,
  Radio,
  Select,
  Button,
  notification,
  Tooltip,
  Typography,
  Divider,
  InputNumber,
} from 'antd';
import { Form, TypePicker, ColorPicker } from 'common/components';
import { PlusOutlined } from '@ant-design/icons';
import {
  RESOLUTION_OPTIONS,
  CROP_SIZE_OPTIONS,
  IMAGE_SIZE_OPTIONS,
  BACKGROUND_OPTIONS,
  IMAGE_SIZE_OPTIONS_SINGLE_DIGIT,
} from 'static/Constants';
import {
  downloadDigitalAsset,
  downloadDigitalAssetByEmail,
} from 'services/digitalAsset';
import { dialogFunction, WrapperSelect } from 'common/components';
import DigitalRight from 'assets/DRM.png';
import { DIGITAL_ASSET_DOWNLOAD } from 'services/digitalAsset/endpoints';
import { setAssetType } from 'utils/fileType';
import { injectIntl } from 'react-intl';
import * as api from 'config/axios';
import { EmailSelection } from 'common/components';
import userSelectors from 'redux/user/selectors';
import * as companySelectors from 'pages/company-profile/controllers/selectors';
import * as memberActions from 'pages/company-profile/controllers/actions';

import * as _ from 'lodash';

import './AssetDownload.less';

const { Option } = Select;
const { Title } = Typography;

const formItemFormatLayout = { labelCol: { span: 24 } };

const formItemConfigLayout = {
  labelCol: { xxl: { span: 8 }, xl: { span: 9 } },
  wrapperCol: { xxl: { span: 15 }, xl: { span: 13 } },
};

const addButtonStyle = {
  flex: 'none',
  padding: '8px',
  display: 'block',
  cursor: 'pointer',
};

const customSetupStyle = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  padding: 8,
};

const AssetDownload = (props) => {
  const {
    assetId,
    metadata,
    downloadFormats: listFormatType,
    intl,
    drm,
  } = props;

  const dispatch = useDispatch();

  const alternativeEmail = useSelector(
    userSelectors.makeSelectUserAlternativeEmail()
  );

  const assetDownloadPreference = useSelector(
    userSelectors.makeSelectAssetDownloadPreference()
  );

  const primaryEmail = useSelector(userSelectors.makeSelectUserEmail());
  const [form] = Form.useForm();
  const [downloadType, setDownloadType] = useState('');
  const [visibleEmailSelection, setVisibleEmailSelection] = useState(false);
  const [resolutionOptions, setResolution] = useState(RESOLUTION_OPTIONS);
  const [cropSizeOptions, setCropSize] = useState(CROP_SIZE_OPTIONS);
  const [imageSizeOptions, setImageSize] = useState(IMAGE_SIZE_OPTIONS);
  const [backgroundOptions, setBackground] = useState(BACKGROUND_OPTIONS);
  const [customResolution, setCustomResolution] = useState('');
  const [customCropSize, setCustomCropSize] = useState('');
  const [customImageSize, setCustomImageSize] = useState('');
  const [allowAdding, setAllowAdding] = useState(true);
  const [downloadFormat, setDownloadFormat] = useState('');

  const downloadFormats = [
    ...new Set([...(listFormatType ?? []), metadata?.fileType?.toUpperCase()]),
  ].sort();

  const memberInfo = useSelector(companySelectors.selectMemberProfile());

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  useEffect(() => {
    if (_.isEmpty(memberInfo)) {
      dispatch(memberActions.getMemberProfileHeader(userInfo?.member?.id));
    }
  }, [dispatch, userInfo?.member?.id]);

  const onOptionChange = (type, value) => {
    switch (type) {
      case 'resolution':
        setCustomResolution(value);

        break;
      case 'cropSize':
        setCustomCropSize(value);
        break;
      case 'imageSize':
        setCustomImageSize(value);
        break;
      default:
        break;
    }
  };

  const addResolutionItem = () => {
    if (!customResolution)
      openNotification(
        'error',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.resolution.customize',
        }),
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.resolution.missing',
        })
      );
    else if (resolutionOptions.includes(customResolution))
      openNotification(
        'error',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.resolution.customize',
        }),
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.resolution.duplicated',
        })
      );
    else setResolution([...resolutionOptions, customResolution]);
  };

  const addCropSizeItem = () => {
    if (!customCropSize)
      openNotification(
        'error',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.cropSize.customize',
        }),
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.cropSize.missing',
        })
      );
    else if (cropSizeOptions.includes(customCropSize))
      openNotification(
        'error',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.cropSize.customize',
        }),
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.cropSize.duplicated',
        })
      );
    else setCropSize([...cropSizeOptions, customCropSize]);
  };

  const confirmRender = (key) => {
    return (
      <div className='asset-download__confirm-render'>
        {intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.imageSize.pixilation',
        })}
        <div className='asset-download__confirm-render-btn'>
          <Button
            type='primary'
            onClick={() => {
              notification.close(key);
              setImageSize([...imageSizeOptions, key]);
              setAllowAdding(true);
            }}
          >
            Continue
          </Button>
          <Button
            type='secondary'
            onClick={() => {
              notification.close(key);
              setAllowAdding(true);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const addImageSizeItem = () => {
    if (!customImageSize)
      openNotification(
        'error',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.imageSize.customize',
        }),
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.imageSize.missing',
        })
      );
    else if (
      imageSizeOptions.includes(customImageSize) ||
      IMAGE_SIZE_OPTIONS_SINGLE_DIGIT.includes(customImageSize)
    )
      openNotification(
        'error',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.imageSize.customize',
        }),
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.imageSize.duplicated',
        })
      );
    else if (
      metadata?.width < customImageSize ||
      metadata?.height < customImageSize
    ) {
      openNotification(
        'warning',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.imageSize.customize.warning',
        }),
        confirmRender(customImageSize),
        customImageSize,
        () => setAllowAdding(true)
      );
      setAllowAdding(false);
    } else setImageSize([...imageSizeOptions, customImageSize]);
  };

  const addBackgroundItem = (color) => {
    if (!backgroundOptions.includes(color)) {
      setBackground([...backgroundOptions, color]);
    } else {
      openNotification(
        'error',
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.background.customize',
        }),
        intl.formatMessage({
          id: 'Taco.digitalAsset.assetDownload.background.duplicated',
        })
      );
    }
  };

  const openNotification = (result, message, description, key, close) => {
    let type;
    switch (result) {
      case true:
        type = 'success';
        break;
      case false:
        type = 'error';
        break;
      default:
        type = result;
        break;
    }
    type
      ? notification[type]({
          message: message,
          description: description,
          duration: type === 'warning' ? 0 : 4.5,
          key: key,
          onClose: close,
        })
      : notification.error({
          message: intl.formatMessage({
            id: 'Taco.digitalAsset.assetDownload.notification.oops',
          }),
          description: intl.formatMessage({
            id: 'Taco.digitalAsset.assetDownload.notification.fail',
          }),
          duration: type === 'warning' ? 0 : 4.5,
        });
  };

  async function onAssetDownload(type) {
    setDownloadType(type);
    const paramsConfig = onConfigParams(type);
    if (type === 'direct') {
      await onAssetDownloadDirect(paramsConfig);
    } else if (type === 'ftp') {
      await onAssetDownloadFTP(paramsConfig);
    } else {
      await onAssetDownloadEmail(paramsConfig, primaryEmail);
    }
    setDownloadType('');
  }

  const onConfigParams = (type) => {
    const formData = form.getFieldsValue();
    return {
      AssetId: assetId,
      Resolution: formData.resolution,
      ImageSize: formData.imageSize,
      CropSize: formData.cropSize,
      Background: formData.background,
      DownloadFormat: formData.format,
      DownloadMethod: type,
    };
  };

  const onAssetDownloadDirect = async (params) => {
    await api
      .requestToDownload({
        method: 'POST',
        apiEndpoint: DIGITAL_ASSET_DOWNLOAD,
        payload: params,
      })
      .then((response) => {
        openNotification(
          response?.isSuccess,
          'Direct Download',
          response?.message || 'Something went wrong! Please try again'
        );
      });
  };

  function handleDownload(type) {
    dialogFunction({
      type: 'warn',
      content: (
        <div style={{ fontSize: 13 }}>
          <img
            src={DigitalRight}
            alt='digital-right'
            height={20}
            width={20}
            style={{ marginRight: 5 }}
          />
          {intl.formatMessage({
            id: `Taco.home.ribbon.dialogDeleteDRMsingle`,
          })}
        </div>
      ),

      okText: 'Cancel',
      onCancel: () => {
        if (type === 'direct') {
          onAssetDownload('direct');
        } else if (type === 'email') {
          setFuncForSendEmail();
        } else onAssetDownload('ftp');
      },
      cancelText: 'Download',
    });
  }

  const onAssetDownloadFTP = async (params) => {
    await downloadDigitalAsset(params).then((response) => {
      openNotification(response?.isSuccess, 'FTP Download', response?.message);
    });
  };

  const onAssetDownloadEmail = async (params, emails) => {
    let submitEmails = Array.isArray(emails) ? emails : [emails];
    await downloadDigitalAssetByEmail({ ...params, Emails: submitEmails }).then(
      (response) => {
        openNotification(
          response?.isSuccess,
          'Email Download',
          response?.message
        );
      }
    );
  };

  const onSendMultiEmail = async (emails) => {
    setDownloadType('email');
    const paramsConfig = onConfigParams('email');
    setVisibleEmailSelection(false);
    await onAssetDownloadEmail(paramsConfig, emails);
    setDownloadType('');
  };

  const setFuncForSendEmail = () => {
    if (alternativeEmail && alternativeEmail.length > 0) {
      setVisibleEmailSelection(true);
    } else {
      onAssetDownload('email'); // default sending for primary email if alter email is empty
    }
  };

  const setDefaultValuesDownloadForm = () => {
    let background = null;
    let cropSize = null;
    const defaultDownloadFormat = assetDownloadPreference?.downloadFormat;

    const format = downloadFormats?.includes(
      defaultDownloadFormat?.toUpperCase()
    )
      ? defaultDownloadFormat
      : metadata?.fileType?.toLowerCase();

    if (metadata?.clippingPath) {
      background = assetDownloadPreference?.background
        ? assetDownloadPreference?.background
        : 'Original';
      cropSize = assetDownloadPreference?.cropSize;
    } else {
      background = 'Original';
    }

    const mappingAssetDownloadPreference = {
      ...assetDownloadPreference,
      format,
      background,
      cropSize,
      resolution: assetDownloadPreference?.resolution ?? null,
    };
    form.setFieldsValue(mappingAssetDownloadPreference);
  };

  useEffect(() => {
    setDefaultValuesDownloadForm();
    setDownloadFormat(metadata?.fileType);
  }, [metadata]);

  const imageSizeCustomRender = (value) => {
    if (IMAGE_SIZE_OPTIONS.includes(value)) return value;
    else return `${value}x${value}`;
  };

  const checkDisableImageSize = (value) => {
    // If value == Full-size
    if (value === IMAGE_SIZE_OPTIONS[IMAGE_SIZE_OPTIONS.length - 1])
      return false;

    const size = typeof value === 'string' ? value?.split('x')[0] : value;

    if (+size > +metadata?.width && +size > +metadata?.height) {
      return true;
    }

    return false;
  };

  const renderResolutionNameByNumber = (number) => {
    switch (number) {
      case 72:
        return 'DPI - Low Resolution';

      case 300:
        return 'DPI - High Resolution';

      default:
        break;
    }
  };

  return (
    <Form
      form={form}
      name='asset-download-form'
      className='asset-download'
      id='form-id'
    >
      <Row className='asset-download__format'>
        <Col span={24}>
          <Title level={5}>
            {intl.formatMessage({
              id: `Taco.digitalAsset.assetDownload.step1`,
            })}
          </Title>
        </Col>
        <Col span={24}>
          <Form.Item {...formItemFormatLayout} name='format'>
            <Radio.Group>
              {downloadFormats?.length > 0 &&
                [...downloadFormats]?.map((option) => {
                  return (
                    <Tooltip title={option?.toUpperCase()} key={option}>
                      <Radio.Button
                        className='asset-download__format-type asset-download__format-type--radio'
                        value={option?.toLowerCase()}
                        onChange={(e) => {
                          setDownloadFormat(e?.target?.value);
                        }}
                      >
                        <TypePicker group='file-type' type={option} />
                      </Radio.Button>
                    </Tooltip>
                  );
                })}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      {setAssetType(metadata?.fileType) === 'Image' && (
        <Row className='asset-download__config'>
          <Col span={24} style={{ marginBottom: 5 }}>
            <Title level={5}>
              {intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.step2`,
              })}
            </Title>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemConfigLayout}
              name='resolution'
              label={intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.resolution`,
              })}
            >
              <WrapperSelect
                style={{ minWidth: '160px' }}
                getPopupContainer={() => document.getElementById('form-id')}
              >
                {resolutionOptions.map((option, index) => {
                  return (
                    <Option key={index} value={option}>
                      <span style={{ fontSize: '12px' }}>
                        {option === null
                          ? 'Original'
                          : option + ' ' + renderResolutionNameByNumber(option)}
                      </span>
                    </Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Tooltip
              title={
                !metadata?.clippingPath &&
                intl.formatMessage({
                  id: 'Taco.digitalAsset.assetDownload.cropSize.disabled',
                })
              }
            >
              <Form.Item
                {...formItemConfigLayout}
                name='cropSize'
                label={intl.formatMessage({
                  id: `Taco.digitalAsset.assetDownload.cropSize`,
                })}
              >
                {/* crop size input */}
                <WrapperSelect
                  getPopupContainer={() => document.getElementById('form-id')}
                  disabled={!metadata?.clippingPath}
                >
                  {cropSizeOptions.map((option, index) => {
                    return (
                      <Option key={index} value={option}>
                        <span>
                          {option || option === 0
                            ? `${option} Pixels`
                            : 'No Crop'}
                        </span>
                      </Option>
                    );
                  })}
                </WrapperSelect>
              </Form.Item>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemConfigLayout}
              name='imageSize'
              label={intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.imageSize`,
              })}
            >
              <WrapperSelect
                style={{ minWidth: '160px' }}
                getPopupContainer={() => document.getElementById('form-id')}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={customSetupStyle}>
                      <div>
                        <InputNumber
                          style={{ width: '100%', marginBottom: 5 }}
                          min={10}
                          max={2500}
                          onChange={(e) => onOptionChange('imageSize', e)}
                        />
                      </div>
                      <a
                        style={addButtonStyle}
                        disabled={!allowAdding}
                        onClick={addImageSizeItem}
                      >
                        <PlusOutlined />
                      </a>
                    </div>
                  </div>
                )}
              >
                {imageSizeOptions.map((option, index) => {
                  return (
                    <Option
                      key={index}
                      value={option}
                      disabled={checkDisableImageSize(option)}
                    >
                      {imageSizeCustomRender(option)}
                    </Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Tooltip
              title={
                !metadata?.clippingPath &&
                intl.formatMessage({
                  id: 'Taco.digitalAsset.assetDownload.background.disabled',
                })
              }
            >
              <Form.Item
                {...formItemConfigLayout}
                name='background'
                label={intl.formatMessage({
                  id: `Taco.digitalAsset.assetDownload.background`,
                })}
              >
                <WrapperSelect
                  getPopupContainer={() => document.getElementById('form-id')}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={customSetupStyle}>
                        <ColorPicker
                          initialColor='#000'
                          callbackFunc={addBackgroundItem}
                        />
                      </div>
                    </div>
                  )}
                  disabled={!metadata?.clippingPath}
                >
                  {backgroundOptions.map((option, index) => {
                    return (
                      <Option
                        key={index}
                        value={option}
                        disabled={
                          !['png', 'tiff', 'tif'].includes(
                            downloadFormat?.toLowerCase()
                          ) && option?.toLowerCase() === 'transparent'
                        }
                      >
                        {option}
                      </Option>
                    );
                  })}
                </WrapperSelect>
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
      )}
      <Row className='asset-download__format'>
        <Col span={24}>
          {setAssetType(metadata?.fileType) === 'Image' ? (
            <Title level={5}>
              {intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.step3`,
              })}
            </Title>
          ) : (
            <Title level={5}>
              {intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.step2method`,
              })}
            </Title>
          )}
        </Col>
        <Col span={24} id='download-method'>
          <Form.Item {...formItemFormatLayout} name='downloadMethod'>
            <Radio.Group style={{ display: 'flex' }}>
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById('download-method')
                }
                title={intl.formatMessage({
                  id: `Taco.digitalAsset.assetDownload.downloadMethod.direct`,
                })}
              >
                <div>
                  <Radio.Button
                    className='asset-download__format-type asset-download__format-method'
                    htmlType='submit'
                    onClick={() =>
                      drm ? handleDownload('direct') : onAssetDownload('direct')
                    }
                    disabled={['email', 'ftp'].includes(downloadType)}
                    value='direct'
                  >
                    <TypePicker
                      group='download-method'
                      type='direct'
                      loading={downloadType === 'direct'}
                    />
                  </Radio.Button>
                </div>
              </Tooltip>
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById('download-method')
                }
                title={intl.formatMessage({
                  id: `Taco.digitalAsset.assetDownload.downloadMethod.email`,
                })}
              >
                <div>
                  <Radio.Button
                    className='asset-download__format-type asset-download__format-method'
                    disabled={['direct', 'ftp'].includes(downloadType)}
                    onClick={() =>
                      drm ? handleDownload('email') : setFuncForSendEmail()
                    }
                    value='email'
                  >
                    <TypePicker
                      group='download-method'
                      type='email'
                      loading={downloadType === 'email'}
                    />
                  </Radio.Button>
                  <EmailSelection
                    onClose={() => setVisibleEmailSelection(false)}
                    visible={visibleEmailSelection}
                    onSendEmail={onSendMultiEmail}
                    primaryEmail={primaryEmail}
                    alternativeEmail={alternativeEmail}
                  ></EmailSelection>
                </div>
              </Tooltip>
              {memberInfo?.ftpEnabled && (
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById('download-method')
                  }
                  title={intl.formatMessage({
                    id: `Taco.digitalAsset.assetDownload.downloadMethod.ftp`,
                  })}
                >
                  <div>
                    <Radio.Button
                      className='asset-download__format-type asset-download__format-method'
                      htmlType='submit'
                      onClick={() =>
                        drm ? handleDownload('ftp') : onAssetDownload('ftp')
                      }
                      disabled={['direct', 'email'].includes(downloadType)}
                      value='ftp'
                    >
                      <TypePicker
                        group='download-method'
                        type='ftp'
                        loading={downloadType === 'ftp'}
                      />
                    </Radio.Button>
                  </div>
                </Tooltip>
              )}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

AssetDownload.propTypes = {
  assetId: oneOfType([PropTypes.number, PropTypes.string]),
  metadata: PropTypes.object,
  downloadFormats: oneOfType([PropTypes.array, PropTypes.object]),
};

export default injectIntl(AssetDownload);
