import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';

const ThreeStateCheckboxFieldInput = (props) => {
  const { checked, onChange, children } = props;

  const [inter, setInter] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const handleChange = (e) => {
    if (checked === null || checked === undefined) {
      setInter(false);
      setCheckAll(true);
      onChange(true);
    }

    if (checked === true) {
      setInter(false);
      setCheckAll(false);
      onChange(false);
    }

    if (checked === false) {
      setInter(true);
      setCheckAll(false);
      onChange(null);
    }
  };

  useEffect(() => {
    if (checked === null || checked === undefined) {
      setInter(true);
      setCheckAll(false);
      return;
    }

    if (checked === true) {
      setInter(false);
      setCheckAll(true);
      return;
    }

    if (checked === false) {
      setInter(false);
      setCheckAll(false);
      return;
    }
  }, [checked]);

  return (
    <Checkbox indeterminate={inter} checked={checkAll} onChange={handleChange}>
      {children && children}
    </Checkbox>
  );
};

export default ThreeStateCheckboxFieldInput;
