import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Pagination, Col, Typography, Empty } from 'antd';

// redux
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import { forwardTo } from 'utils/common/route';
import reducer from './controllers/reducer';
import saga from './controllers/saga';

import useCustomScroll from 'hooks/useCustomScroll';

import selectorsGridView from './controllers/selectors';
import * as actionsGridView from './controllers/actions';

import * as selectorsGlobal from 'redux/global/selectors';
import * as actionGlobal from 'redux/global/actions';

import * as constant from 'static/Constants';

import mapToDragColumndef from './mappers/mapToDragColumndef';

import {
  ThumbnailItem,
  ContentPane,
  AbandonDialog,
  ListGrid,
  TileGrid,
  AddToFolder,
  SearchCategories,
} from './components';

import DisplayTotalItem from './DisplayTotalItem';

import { DragWrap, DragDropWrapUtils } from 'common/components';
import AgGrid from 'common/components/ag-grid/AgGrid';
import { FormattedMessage } from 'react-intl';
import messages from 'i18n/messages/gridView';
import { updateSizeCurrent } from './utils';
import { useCheckAllowEditProducts } from 'hooks';

import { useDefaultSearchText } from 'pages/home/utils';
import './GridView.less';
import RenderAdvancedFilter from './components/content-pane/advancedFilter/RenderAdvancedFilter';
import AdvanceFilterPanel from './components/content-pane/advancedFilter/AdvanceFilterPanel';

const key = 'gridView';

const GridView = (props) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const {
    dataList,
    getDataList,
    totalPagination,
    pageSizePagination,
    pageNumberPagination,
    thumbnailGridChildren,
    listGridChildren,
    tileGridChildren,
    // tileGridComponentHeader,
    // tileGridComponentBody,
    // tileGridComponentFooter,
    tileGridBody,
    columnDefs,
    urlGrid,
    urlGridDistinct,
    panelDetail,
    responseParams,
    pathname,
    requestParams,
    disableItemEvent,
    isInModal,
    typeView,
    hidePagination,
    filterFromMainPage,
    hideAgSetColumnFilter,
    rowClassRules,
    frameworkComponents = {},
    shouldReloadDetailGrid,
    showAdvanceFilter,
    queryConditions,
    triggerDownload,
    onDownloadGridHandler,
    downloadFileType,
    urlDownload,
    isDownloadAllColumns,
    type,
    hideSearchBar = false,
    enableDragToFolder = false,
    reloadGrid = false,
    getGridApi,
    isDisplaySearchCategories = false,
    notShowHeaderCheckbox,
    rowMultiSelectWithClick = false,
    isCacheEditMode,
    gridId,
    mapId,
    rowSelection,
    applyDefaultSort,
    gridConfigProps,
    shouldShowAdvanceFilterTags = true,
    sortDataHandler,
  } = props;

  const refGridView = useRef(null);
  const [showAdvanceFilterPanel, setShowAdvanceFilterPanel] = useState(false);

  const dispatch = useDispatch();

  const visibleSelection = useSelector(
    selectorsGridView.makeSelectVisibleSelection()
  );
  const itemCurrentSelection = useSelector(
    selectorsGridView.makeSelectItemCurrentSelection()
  );
  const itemSelectionDialog = useSelector(
    selectorsGridView.makeSelectItemsSelectionDialog()
  );
  const itemsSelection = useSelector(
    selectorsGridView.makeSelectItemsSelection()
  );
  const pagesSelection = useSelector(
    selectorsGridView.makeSelectPagesSelection()
  );
  const itemPageSelection = useSelector(
    selectorsGridView.makeSelectItemPageSelection()
  );
  const countPageSelection = useSelector(
    selectorsGridView.makeSelectCountPageSelection()
  );
  const visible = useSelector(selectorsGridView.makeSelectVisible());

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const isShowDetailOn = useSelector(selectorsGlobal.selectShowDetail());
  const showAddToFolder = useSelector(selectorsGlobal.selectShowAddToFolder());
  const searchAllItems = useSelector(selectorsGlobal.selectSearchAllItems());

  const { getDefaultSearchText } = useDefaultSearchText();

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  useCustomScroll('.grid-view--with-scroller', {
    typeView,
  });

  useCustomScroll('.ant-drawer-body', { visible });

  useEffect(() => {
    if (hideSearchBar) {
      dispatch(actionGlobal.hideSearchBar());
    } else {
      dispatch(actionGlobal.unHideSearchBar());
    }
  }, [dispatch, hideSearchBar]);

  // useEffect(() => {
  //   dispatch(actionGlobal.updateIsOnlyDetailGird(false));
  // }, [dispatch]);

  useEffect(() => {
    if (refGridView.current) refGridView.current.scrollTop = 0;
    if (typeView === constant.RIBBON_TYPES.DETAILSVIEW) {
      dispatch(actionsGridView.updateVisibleSelection(false));
      dispatch(actionsGridView.updateItemCurrentSelection(null));
      dispatch(actionsGridView.updateItemsSelectionDialog(null));
      dispatch(actionsGridView.updateItemsSelection([]));
      dispatch(actionsGridView.updatePagesSelection([]));
      dispatch(actionsGridView.updateItemPageSelection([]));
      dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));
      dispatch(actionsGridView.updateCountPageSelection([]));
    } else {
      dispatch(actionsGridView.updateAgGridItemsSelection(0));
      dispatch(actionsGridView.updateItemCurrentSelection(null));
    }
  }, [dispatch, typeView]);

  useEffect(() => {
    if (refGridView.current) refGridView.current.scrollTop = 0;
  }, [searchAllItems]);

  const updateDataListSearch = (page, pageSize) => {
    const searchText = getDefaultSearchText(window.location.pathname);

    if (!getDataList) return;

    if (typeof getDataList === 'function' && filterFromMainPage?.length > 0) {
      getDataList(pageSize, page, searchText, filterFromMainPage);
    } else {
      getDataList(pageSize, page, searchText);
    }
  };

  const onChangePagination = (page, pageSize) => {
    updateSizeCurrent(dispatch, page, pageSize, window.location.pathname);
    updateDataListSearch(page, pageSize);
    //todo : might need to be changed - Ask anh Hien
    isShowDetailOn && dispatch(actionsGridView.updateVisible(false));
  };

  const onShowSizeChange = (current, size) => {
    dispatch(actionsGridView.updateItemsSelection([]));
    dispatch(actionsGridView.updatePagesSelection([]));
    dispatch(actionsGridView.updateItemPageSelection([]));
    dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));
    //todo : might need to be changed - Ask anh Hien
    isShowDetailOn && dispatch(actionsGridView.updateVisible(false));
  };

  const onClickItemGrid = (item, e) => {
    if (e.shiftKey) {
      handleShiftSelect(item);
    } else if (e.ctrlKey) {
      if (itemsSelection.indexOf(item.id) > -1) {
        handleUnselect(item);
      } else {
        handleSelect(item);
      }
    } else {
      if (
        (pagesSelection.indexOf(pageNumberPagination) > -1 &&
          pagesSelection.length === 1) ||
        pagesSelection.length === 0
      ) {
        handleSingleSelect(item);
      } else {
        dispatch(actionsGridView.updateVisibleSelection(true));
        dispatch(actionsGridView.updateItemsSelectionDialog(item));
      }
    }

    if (itemsSelection.indexOf(item.id) > -1) {
      handleUnselect(item);
    }
  };

  const handleShiftSelect = (item) => {
    if (pagesSelection && pagesSelection.indexOf(pageNumberPagination) === -1) {
      handleSelect(item);
    } else {
      let itemPage = [];
      let arrItemSelection = [];
      let countPage = 0;
      let indexTempSelected = dataList && dataList.length;
      let arrDetailItemsSelection = [];

      itemPageSelection &&
        itemPageSelection.length > 0 &&
        itemPageSelection.forEach((val) => {
          if (val.page === pageNumberPagination) {
            // if (arrItemSelection.indexOf(val.item) > -1) {
            //   arrItemSelection.splice(arrItemSelection.indexOf(val.item), 1);
            // }
            // if (arrDetailItemsSelection.indexOf(val) > -1) {
            //   arrDetailItemsSelection.splice(
            //     arrDetailItemsSelection.indexOf(val),
            //     1
            //   );
            // }
          } else {
            const foundDetailItem = detailCurrentItemsSelection.find(
              (detailItem) => detailItem?.id === val.item
            );
            itemPage.push(val);
            arrItemSelection.push(val.item);
            arrDetailItemsSelection.push(foundDetailItem);
          }
        });

      let findIndex = dataList.findIndex((val) => val.id === item.id);
      let findIndexCurrentSelection = dataList?.findIndex(
        (val) => val.id === itemCurrentSelection?.id
      );
      // if (item.id > itemCurrentSelection.id) {
      if (findIndex > findIndexCurrentSelection) {
        dataList &&
          dataList.length > 0 &&
          dataList.forEach((value, index) => {
            if (findIndexCurrentSelection === index) {
              itemPage.push({ item: value.id, page: pageNumberPagination });
              arrItemSelection.push(value.id);
              arrDetailItemsSelection.push(value);
              countPage++;
              indexTempSelected = index;
            }
            if (findIndex >= index && index > indexTempSelected) {
              itemPage.push({ item: value.id, page: pageNumberPagination });
              arrItemSelection.push(value.id);
              arrDetailItemsSelection.push(value);
              countPage++;
            }
          });
      } else {
        dataList &&
          dataList.length > 0 &&
          dataList.forEach((value, index) => {
            if (findIndex === index) {
              itemPage.push({ item: value.id, page: pageNumberPagination });
              arrItemSelection.push(value.id);
              arrDetailItemsSelection.push(value);
              countPage++;
              indexTempSelected = index;
            }
            if (
              findIndexCurrentSelection >= index &&
              index > indexTempSelected
            ) {
              itemPage.push({ item: value.id, page: pageNumberPagination });
              arrItemSelection.push(value.id);
              arrDetailItemsSelection.push(value);
              countPage++;
            }
          });
      }

      let updateCountPage = [];
      countPageSelection.forEach((value) => {
        let temp = { ...value };
        if (temp.page === pageNumberPagination) {
          temp.count = countPage;
        }
        updateCountPage.push(temp);
      });

      dispatch(actionsGridView.updateCountPageSelection(updateCountPage));
      dispatch(actionsGridView.updateItemPageSelection(itemPage));
      dispatch(actionsGridView.updateItemsSelection(arrItemSelection));
      dispatch(
        actionsGridView.updateDetailCurrentItemsSelection(
          arrDetailItemsSelection
        )
      );
    }
  };

  const handleUnselect = (item) => {
    let itemsSelectionTemp = itemsSelection.filter(
      (value) => value !== item.id
    );
    let detailCurrentItemsSelectionTemp = detailCurrentItemsSelection.filter(
      (value) => value.id !== item.id
    );
    dispatch(actionsGridView.updateItemsSelection(itemsSelectionTemp));
    dispatch(
      actionsGridView.updateDetailCurrentItemsSelection(
        detailCurrentItemsSelectionTemp
      )
    );
    let itemPageSelectionTemp = itemPageSelection.filter(
      (value) => value.item !== item.id
    );
    dispatch(actionsGridView.updateItemPageSelection(itemPageSelectionTemp));

    let checkCountPage = false;
    let updateCountPage = [];
    countPageSelection.forEach((value) => {
      let temp = { ...value };
      if (temp.page === pageNumberPagination) {
        temp.count--;
        if (temp.count === 0) {
          checkCountPage = true;
        }
      }
      updateCountPage.push(temp);
    });
    dispatch(actionsGridView.updateCountPageSelection(updateCountPage));
    if (checkCountPage) {
      let pagesSelectionTemp = pagesSelection.filter(
        (value) => value !== pageNumberPagination
      );
      dispatch(actionsGridView.updatePagesSelection(pagesSelectionTemp));
    }
  };

  const handleSelect = (item) => {
    let itemsSelectionTemp = [...itemsSelection];
    let detailCurrentItemsSelectionTemp = [...detailCurrentItemsSelection];
    itemsSelectionTemp.push(item.id);
    detailCurrentItemsSelectionTemp.push(item);
    dispatch(actionsGridView.updateItemsSelection(itemsSelectionTemp));
    dispatch(
      // update detail current items selection
      actionsGridView.updateDetailCurrentItemsSelection(
        detailCurrentItemsSelectionTemp
      )
    );

    if (pagesSelection.indexOf(pageNumberPagination) === -1) {
      let pagesSelectionTemp = [...pagesSelection];
      pagesSelectionTemp.push(pageNumberPagination);
      dispatch(actionsGridView.updatePagesSelection(pagesSelectionTemp));

      dispatch(
        actionsGridView.updateCountPageSelection([
          { page: pageNumberPagination, count: 1 },
        ])
      );
    } else {
      let updateCountPage = [];
      countPageSelection.forEach((value) => {
        let temp = { ...value };
        if (temp.page === pageNumberPagination) {
          temp.count++;
        }
        updateCountPage.push(temp);
      });
      dispatch(actionsGridView.updateCountPageSelection(updateCountPage));
    }
    let itemPageSelectionTemp = [...itemPageSelection];
    itemPageSelectionTemp.push({ item: item.id, page: pageNumberPagination });
    dispatch(actionsGridView.updateItemPageSelection(itemPageSelectionTemp));
    dispatch(actionsGridView.updateItemCurrentSelection(item));
  };
  const handleSingleSelect = (item) => {
    dispatch(actionsGridView.updateItemsSelection([item.id]));
    dispatch(actionsGridView.updateDetailCurrentItemsSelection([item]));
    dispatch(actionsGridView.updatePagesSelection([pageNumberPagination]));
    dispatch(
      actionsGridView.updateItemPageSelection([
        { item: item.id, page: pageNumberPagination },
      ])
    );

    dispatch(
      actionsGridView.updateCountPageSelection([
        { page: pageNumberPagination, count: 1 },
      ])
    );
    dispatch(actionsGridView.updateItemCurrentSelection(item));
    isShowDetailOn && dispatch(actionsGridView.updateVisible(true));
  };
  const onClickCheckboxItem = (item, e) => {
    if (e.target.checked) {
      handleSelect(item);
    } else {
      handleUnselect(item);
    }
  };

  const onDoubleClick = (item) => {
    const { id } = item;

    if (!pathname || !id) return;

    forwardTo(`${pathname}/${id}`);
  };

  const handleModalAbandon = () => {
    dispatch(actionsGridView.updateVisibleSelection(false));
    handleSingleSelect(itemSelectionDialog);
  };
  const handleModalAppend = () => {
    dispatch(actionsGridView.updateVisibleSelection(false));
    handleSelect(itemSelectionDialog);
  };
  const handleModalCancel = () => {
    dispatch(actionsGridView.updateVisibleSelection(false));
  };
  const itemProduct = (item = {}, index) => {
    let selected = false;
    if (itemsSelection.indexOf(item.id) > -1) {
      selected = true;
    }

    let configProps = {
      dataDetail: item,
      imageThumbnail: (item.imageUrl || '').toUrl(),
      labelThumbnail: item.productName,
      key: (item && item.id) || index,
      selected: selected,
      shareable:
        item?.isOwner && item?.visibility?.toLowerCase() === 'only if shared',
      type: type,
      tileBody: tileGridBody && tileGridBody,
      onClickItemGrid: onClickItemGrid,
      onClickCheckboxItem: onClickCheckboxItem,
      onDoubleClick: onDoubleClick,
      disableEvent: disableItemEvent,
    };

    const { type: itemType } = item;

    if (itemType === 'Product') {
      configProps = {
        ...configProps,
        isEditable: checkAllowEditProductFull(configProps?.dataDetail),
      };
    }

    const renderItemProduct = () => {
      if (typeView === constant.RIBBON_TYPES.THUMBNAILS) {
        if (typeof thumbnailGridChildren === 'function') {
          return thumbnailGridChildren(configProps);
        } else {
          return <ThumbnailItem {...configProps} />;
        }
      }
      if (typeView === constant.RIBBON_TYPES.LISTVIEW) {
        if (typeof listGridChildren === 'function') {
          return listGridChildren(configProps);
        } else {
          return <ListGrid {...configProps} />;
        }
      }
      if (typeView === constant.RIBBON_TYPES.TILESVIEW) {
        if (typeof tileGridChildren === 'function') {
          return tileGridChildren(configProps);
        } else {
          return <TileGrid {...configProps} />;
        }
      }
    };

    return (
      <DragWrap
        dragListData={detailCurrentItemsSelection}
        dragData={item}
        dragNamePath={DragDropWrapUtils.mapItemDataToDragNamePath(item)}
        icon={DragDropWrapUtils.mapItemDataToDragIcon(item)}
        style={{ display: 'inline-block' }}
        key={(item && item.id) || index}
      >
        {renderItemProduct()}
      </DragWrap>
    );
  };
  const onClose = () => {
    dispatch(actionsGridView.updateVisible(false));
    showAddToFolder && dispatch(actionGlobal.toggleAddToFolder());
    dispatch(actionGlobal.removeCurrentFolder());
  };

  //* render
  const isShowContentPane =
    (visible && panelDetail) || showAddToFolder || showAdvanceFilterPanel;

  const openAdvancedFilterPanel = () => {
    onClose();
    setShowAdvanceFilterPanel(true);
  };

  const onClosePanel = useCallback(() => setShowAdvanceFilterPanel(false), []);

  const RenderDrawer = () => {
    if (visible && panelDetail) {
      return panelDetail;
    }

    if (showAddToFolder) {
      return <AddToFolder />;
    }

    if (showAdvanceFilterPanel) {
      return (
        <AdvanceFilterPanel
          queryConditions={queryConditions}
          onClosePanel={onClosePanel}
        />
      );
    }

    return <Row>Content Pane</Row>;
  };

  return (
    <Row
      style={{
        height: '100%',
      }}
    >
      {typeView === constant.RIBBON_TYPES.DETAILSVIEW &&
        columnDefs &&
        columnDefs.length > 0 && (
          <Row
            className='grid-view--with-scroller'
            ref={refGridView}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            {queryConditions?.children?.length > 0 &&
            shouldShowAdvanceFilterTags ? (
              <RenderAdvancedFilter
                openAdvancedFilterPanel={openAdvancedFilterPanel}
                onClosePanel={onClosePanel}
              />
            ) : null}
            {isDisplaySearchCategories && <SearchCategories />}

            <AgGrid
              styleGrid={{
                width: `calc(100% - ${
                  isShowContentPane && !showAdvanceFilter
                    ? constant.WIDTH_CONTANT_PANE
                    : 0
                }px)`,
                height: `calc(100% - ${hidePagination ? '0px' : '50px'})`,
              }}
              columnDefs={
                enableDragToFolder ? mapToDragColumndef(columnDefs) : columnDefs
              }
              urlGrid={urlGrid}
              urlGridDistinct={urlGridDistinct}
              urlDownload={urlDownload}
              paramsGrid={{
                search: {
                  searchText: getDefaultSearchText(window.location.pathname),
                },
              }}
              responseParams={responseParams && responseParams}
              requestParams={requestParams && requestParams}
              gridView={true}
              pathname={pathname}
              hidePagination={hidePagination}
              filterFromMainPage={filterFromMainPage}
              hideAgSetColumnFilter={hideAgSetColumnFilter}
              pageNumberPagination={pageNumberPagination}
              pageSizePagination={pageSizePagination}
              rowClassRules={rowClassRules}
              frameworkComponents={frameworkComponents}
              shouldReloadDetailGrid={shouldReloadDetailGrid}
              // totalPagination={totalPagination}
              triggerDownload={triggerDownload}
              onDownloadGridHandler={onDownloadGridHandler}
              downloadFileType={downloadFileType}
              isDownloadAllColumns={isDownloadAllColumns}
              // itemsSelectionParent={itemsSelection}
              // pageItemsSelectionParent={itemPageSelection}
              // updateItemsSelectionParent={updateItemsSelectionParent}
              // updatePageItemsSelectionParent={updatePageItemsSelectionParent}
              // currentPaginationParent={pageNumberPagination}
              // pageSizeParent={pageSizePagination}
              // agGridOnChangePagination={onChangePagination}
              // agGridOnShowSizeChange={onShowSizeChange}
              reloadGrid={reloadGrid}
              getGridApi={getGridApi}
              notShowHeaderCheckbox={notShowHeaderCheckbox}
              rowMultiSelectWithClick={rowMultiSelectWithClick}
              isCacheEditMode={isCacheEditMode}
              gridId={gridId}
              mapId={mapId}
              rowSelection={rowSelection}
              applyDefaultSort={applyDefaultSort}
              gridConfigProps={gridConfigProps}
              sortDataHandler={sortDataHandler}
            />
          </Row>
        )}
      {typeView !== constant.RIBBON_TYPES.DETAILSVIEW && (
        <>
          <Row className='noselect' style={{ width: '100%', height: '100%' }}>
            <Col
              ref={refGridView}
              className='grid-view--with-scroller'
              style={{
                width: `calc(100% - ${
                  isShowContentPane && !showAdvanceFilter
                    ? constant.WIDTH_CONTANT_PANE
                    : 0
                }px)`,
                height: `calc(100% - ${hidePagination ? '0px' : '50px'})`,
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
              }}
            >
              {queryConditions?.children?.length > 0 &&
              shouldShowAdvanceFilterTags ? (
                <RenderAdvancedFilter
                  onClosePanel={onClosePanel}
                  openAdvancedFilterPanel={openAdvancedFilterPanel}
                />
              ) : null}
              {isDisplaySearchCategories && <SearchCategories />}
              {dataList &&
                dataList.length > 0 &&
                dataList.map((item, index) => {
                  return itemProduct(item, index);
                })}
              {(!dataList || dataList.length === 0) && (
                <Row className='grid-view__empty'>
                  <Empty />
                </Row>
              )}
            </Col>
          </Row>

          {!hidePagination && (
            <Row
              className='grid-view__pagination'
              style={{
                width: showAdvanceFilter
                  ? 'calc(100% - 753px)'
                  : 'calc(100% - 240px)',
              }}
            >
              <Col
                span={18}
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <Pagination
                  current={pageNumberPagination}
                  total={totalPagination}
                  pageSize={pageSizePagination}
                  showSizeChanger={true}
                  onChange={onChangePagination}
                  onShowSizeChange={onShowSizeChange}
                />
                <DisplayTotalItem totalPagination={totalPagination} />
              </Col>
              <Col span={6}>
                {itemsSelection &&
                  itemsSelection.length > 0 &&
                  pagesSelection &&
                  pagesSelection.length === 1 && (
                    <Typography.Text type='warning' strong>
                      {itemsSelection.length}{' '}
                      <FormattedMessage {...messages.numberItem} />
                    </Typography.Text>
                  )}
                {pagesSelection && pagesSelection.length > 1 && (
                  <Typography.Text type='warning' strong>
                    {itemsSelection.length ? (
                      <>
                        {itemsSelection.length}{' '}
                        <FormattedMessage {...messages.numberItemMultiPage} />
                      </>
                    ) : null}
                  </Typography.Text>
                )}
              </Col>
            </Row>
          )}
        </>
      )}
      <AbandonDialog
        visibleSelection={visibleSelection}
        handleModalAbandon={handleModalAbandon}
        handleModalAppend={handleModalAppend}
        handleModalCancel={handleModalCancel}
      />
      {/* {itemCurrentSelection && ( */}
      {!isInModal && panelDetail && !showAdvanceFilter ? (
        <ContentPane
          className={classnames({
            'content-pane': true,
            'content-pane-folder': showAddToFolder,
          })}
          onClose={onClose}
          visible={isShowContentPane}
          widthDrawer={constant.WIDTH_CONTANT_PANE}
          showDetail={isShowDetailOn}
          contentDrawer={RenderDrawer()}
          // footer={
          //   showAddToFolder && (
          //     <AddToFolderActions
          //       loading={addEntityToFolderLoading}
          //       disableSubmitButton={
          //         !folderItem || (itemsSelection && itemsSelection.length === 0)
          //       }
          //       onCancel={onCloseAddToFolder}
          //       onSubmit={onSubmitAddToFolder(folderItem)}
          //       onCreateFolderSuccessCallback={addToFolder}
          //     />
          //   )
          // }
        />
      ) : null}
    </Row>
  );
};

GridView.propTypes = {
  dataList: PropTypes.array,
  getDataList: PropTypes.func,
  totalPagination: PropTypes.number,
  pageSizePagination: PropTypes.number,
  pageNumberPagination: PropTypes.number,
  thumbnailGridChildren: PropTypes.func,
  listGridChildren: PropTypes.func,
  tileGridChildren: PropTypes.func,
  tileGridBody: PropTypes.func,
  columnDefs: PropTypes.array,
  urlGrid: PropTypes.string,
  urlGridDistinct: PropTypes.string,
  panelDetail: PropTypes.element,
  responseParams: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  requestParams: PropTypes.object,
  disableItemEvent: PropTypes.bool,
  isInModal: PropTypes.bool,
  typeView: PropTypes.string,
  hidePagination: PropTypes.bool,
  filterFromMainPage: PropTypes.array,
  hideAgSetColumnFilter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  frameworkComponents: PropTypes.object,
  shouldReloadDetailGrid: PropTypes.bool,
  showAdvanceFilter: PropTypes.bool,
  queryConditions: PropTypes.array,
  triggerDownload: PropTypes.bool,
  onDownloadGridHandler: PropTypes.func,
  downloadFileType: PropTypes.string,
  urlDownload: PropTypes.string,
  isDownloadAllColumns: PropTypes.bool,
};

export default GridView;
