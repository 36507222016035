// Action Types
export const GET_MEMBER_PENDING = 'GET_MEMBER_PENDING';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_ERROR = 'GET_MEMBER_ERROR';

export const FETCH_MEMBER_OVERVIEW_DOCUMENT = 'FETCH_MEMBER_OVERVIEW_DOCUMENT';
export const FETCH_MEMBER_OVERVIEW_DOCUMENT_SUCCESS =
  'FETCH_MEMBER_OVERVIEW_DOCUMENT_SUCCESS';
export const FETCH_MEMBER_OVERVIEW_DOCUMENT_ERROR =
  'FETCH_MEMBER_OVERVIEW_DOCUMENT_ERROR';
export const GET_MEMBER_CONTACT_HISTORY = 'GET_MEMBER_CONTACT_HISTORY';
export const GET_MEMBER_CONTACT_HISTORY_SUCCESS =
  'GET_MEMBER_CONTACT_HISTORY_SUCCESS';
export const GET_MEMBER_CONTACT_HISTORY_ERROR =
  'GET_MEMBER_CONTACT_HISTORY_ERROR';

/**********************************************************************/
/********************** Grid MEMBER COLUMN CONSTANTS ******************/
/**********************************************************************/
export const GET_MEMBER_CONTACT_GRID = 'GET_MEMBER_CONTACT_GRID';
export const GET_MEMBER_CONTACT_GRID_SUCCESS =
  'GET_MEMBER_CONTACT_GRID_SUCCESS';
export const GET_MEMBER_CONTACT_GRID_ERROR = 'GET_MEMBER_CONTACT_GRID_ERROR';
export const GET_CONTACT_LOCATION_GRID = 'GET_CONTACT_LOCATION_GRID';
export const GET_CONTACT_LOCATION_GRID_SUCCESS =
  'GET_CONTACT_LOCATION_GRID_SUCCESS';
export const GET_CONTACT_LOCATION_GRID_ERROR =
  'GET_CONTACT_LOCATION_GRID_ERROR';
export const GET_MEMBER_BILLING_GRID = 'GET_MEMBER_BILLING_GRID';
export const GET_MEMBER_BILLING_GRID_SUCCESS =
  'GET_MEMBER_BILLING_GRID_SUCCESS';
export const GET_MEMBER_BILLING_GRID_ERROR = 'GET_MEMBER_BILLING_GRID_ERROR';
export const GET_MEMBER_ACTIVITY_LOG_GRID = 'GET_MEMBER_ACTIVITY_LOG_GRID';
export const GET_MEMBER_ACTIVITY_LOG_GRID_SUCCESS =
  'GET_MEMBER_ACTIVITY_LOG_GRID_SUCCESS';
export const GET_MEMBER_ACTIVITY_LOG_GRID_ERROR =
  'GET_MEMBER_ACTIVITY_LOG_GRID_ERROR';
export const GET_MEMBER_CONTACT_HISTORY_GRID =
  'GET_MEMBER_CONTACT_HISTORY_GRID';
export const GET_MEMBER_CONTACT_HISTORY_GRID_SUCCESS =
  'GET_MEMBER_CONTACT_HISTORY_GRID_SUCCESS';
export const GET_MEMBER_CONTACT_HISTORY_GRID_ERROR =
  'GET_MEMBER_CONTACT_HISTORY_GRID_ERROR';

// tag line upload media
export const TOGGLE_TAG_LINE_UPLOAD_MEDIA = 'TOGGLE_TAG_LINE_UPLOAD_MEDIA';
export const TOGGLE_PREVIEW_TAG_LINE_UPLOAD_MEDIA =
  'TOGGLE_PREVIEW_TAG_LINE_UPLOAD_MEDIA';
export const IS_SHOW_LINE_UPLOAD_MEDIA = 'IS_SHOW_LINE_UPLOAD_MEDIA';
export const GET_MEMBER_SHORT_DETAIL_PREVIEW =
  'GET_MEMBER_SHORT_DETAIL_PREVIEW';
export const GET_MEMBER_SHORT_DETAIL_PREVIEW_SUCCESS =
  'GET_MEMBER_SHORT_DETAIL_PREVIEW_SUCCESS';
export const GET_MEMBER_SHORT_DETAIL_PREVIEW_ERROR =
  'GET_MEMBER_SHORT_DETAIL_PREVIEW_ERROR';
export const GET_PUBLIC_CONTACT_COLUMN = 'GET_PUBLIC_CONTACT_COLUMN';
export const GET_PUBLIC_CONTACT_COLUMN_SUCCESS =
  'GET_PUBLIC_CONTACT_COLUMN_SUCCESS';
export const GET_PUBLIC_CONTACT_COLUMN_ERROR =
  'GET_MEMBER_SHORT_DETAIL_PREVIEW_ERROR';
export const GET_PUBLIC_CONTACT = 'GET_PUBLIC_CONTACT';
export const GET_PUBLIC_CONTACT_SUCCESS = 'GET_PUBLIC_CONTACT_SUCCESS';
export const GET_PUBLIC_CONTACT_ERROR = 'GET_PUBLIC_CONTACT_ERROR';
export const UPDATE_PUBLIC_CONTACT = 'UPDATE_PUBLIC_CONTACT';

// get contacts by memberId
export const GET_MEMBER_CONTACTS = 'GET_MEMBER_CONTACTS';
export const GET_MEMBER_CONTACTS_SUCCESS = 'GET_MEMBER_CONTACTS_SUCCESS';
export const GET_MEMBER_CONTACTS_ERROR = 'GET_MEMBER_CONTACTS_ERROR';

// get member brands
export const GET_MEMBER_OVERVIEW_BRANDS = 'GET_MEMBER_OVERVIEW_BRANDS';
export const GET_MEMBER_OVERVIEW_BRANDS_SUCCESS =
  'GET_MEMBER_OVERVIEW_BRANDS_SUCCESS';
export const GET_MEMBER_OVERVIEW_BRANDS_ERROR =
  'GET_MEMBER_OVERVIEW_BRANDS_ERROR';

export const DOCUMENT_DETAIL_COLUMN_INFO = 'DOCUMENT_DETAIL_COLUMN_INFO';
export const DOCUMENT_DETAIL_COLUMN_INFO_SUCCESS =
  'DOCUMENT_DETAIL_COLUMN_INFO_SUCCESS';
export const DOCUMENT_DETAIL_COLUMN_INFO_ERROR =
  'DOCUMENT_DETAIL_COLUMN_INFO_ERROR';
export const GET_DOCUMENT_DETAIL = 'GET_DOCUMENT_DETAIL';
export const GET_DOCUMENT_DETAIL_SUCCESS = 'GET_DOCUMENT_DETAIL_SUCCESS';
export const GET_DOCUMENT_DETAIL_ERROR = 'GET_DOCUMENT_DETAIL_ERROR';
export const DELETE_DOCUMENT_DETAIL = 'DELETE_DOCUMENT_DETAIL';
export const DELETE_DOCUMENT_DETAIL_ERROR = 'DELETE_DOCUMENT_DETAIL_ERROR';
// Information sheet
export const GET_MEMBER_INFORMATION_SHEET = 'GET_MEMBER_INFORMATION_SHEET';
export const GET_MEMBER_INFORMATION_SHEET_SUCCESS =
  'GET_MEMBER_INFORMATION_SHEET_SUCCESS';
export const GET_MEMBER_INFORMATION_SHEET_ERROR =
  'GET_MEMBER_INFORMATION_SHEET_ERROR';
