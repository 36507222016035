import React from 'react';

import { Row, Col } from 'antd';

const VerticalSplitFormRow = (props) => {
  const { formRender, style, ...restProps } = props;

  return (
    <Row style={style && style} {...restProps}>
      {formRender?.map((formItem, idx) => {
        let sizeStyle = { width: formItem?.width };

        if (formItem?.width === undefined) {
          sizeStyle = { flex: formItem?.flex || 1 };
        }

        return (
          <Col style={sizeStyle} key={formItem?.key || idx}>
            {formItem?.render}
          </Col>
        );
      })}
    </Row>
  );
};

export default VerticalSplitFormRow;
