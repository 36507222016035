import React from 'react';

import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';

import helpMaintenanceReducer from 'pages/maintenance/controllers/reducer';
import helpMaintenanceSaga from 'pages/maintenance/controllers/saga';

import { RibbonBar, RibbonDivider } from 'common/components';
import EditPost from './components/controls/maintenance/help/EditPost';
import HelpPostCreateView from './HelpPostCreateView';
import DeletePost from './components/controls/maintenance/help/DeletePost';
import BackToPost from './components/controls/maintenance/help/BackToPost';
import { ToggleHelpUploadMediaSection } from './components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import * as helpMaintenanceActions from 'pages/maintenance/controllers/actions';
import * as helpMaintenanceSelectors from 'pages/maintenance/controllers/selectors';

const key = 'helpMaintenance';

const HelpPostFullView = () => {
  useInjectReducer({ key, reducer: helpMaintenanceReducer });
  useInjectSaga({ key, saga: helpMaintenanceSaga });

  const dispatch = useDispatch();

  const { statusCreate, isCreate, isEdit } = useSelector(
    helpMaintenanceSelectors.selectHelpMaintenancePost()
  );

  const disabledEditAndDelete = isCreate;

  const enableModeEdit = () => {
    dispatch(helpMaintenanceActions.enableEditPost());
  };

  const disableModeEdit = () =>
    dispatch(helpMaintenanceActions.disableEditPost());

  const handleSavePost = () => {
    dispatch(helpMaintenanceActions.confirmChangeHelpPost());

    setTimeout(() => {
      dispatch(helpMaintenanceActions.disableEditPost());
    }, 2000);
  };

  if (isEdit) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <HelpPostCreateView
          onClick={handleSavePost}
          statusCreate={statusCreate}
          disableModeEdit={disableModeEdit}
        />
      </div>
    );
  }

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.HELP_POST}>
        <EditPost
          enableModeEdit={enableModeEdit}
          disabled={disabledEditAndDelete}
        />
        <RibbonDivider />
        <DeletePost disabled={disabledEditAndDelete} />
        <RibbonDivider />
      </Can>
      <ToggleHelpUploadMediaSection />
      <RibbonDivider />
      <AdvanceStack isDisabled />
      <BackToPost ribbonSide='right' />
    </RibbonBar>
  );
};

export default HelpPostFullView;
