import React from 'react';
import PropTypes from 'prop-types';

import { StarOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import { useFavorite } from 'hooks/useFavorite';

import Messages from 'i18n/messages/home';

const FavoriteControl = (props) => {
  const { type, selectedItemList, isDisabled } = props;

  const { callToggleFavoriteApi } = useFavorite({
    entityList: selectedItemList,
    type,
  });

  //* functions
  const onClickHandler = async () => {
    callToggleFavoriteApi(true);
  };

  return (
    <ButtonSmallIcon
      icon={<StarOutlined />}
      label={Messages.addFavorite}
      className='button-item-dropdown'
      onClick={onClickHandler}
      disabled={isDisabled}
    />
  );
};

FavoriteControl.propTypes = {
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default FavoriteControl;
