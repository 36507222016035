import React from 'react';

import { Button } from 'antd';

const BoldTextButton = (props) => {
  const { text, ...rest } = props;
  return (
    <Button
      type='link'
      style={{
        border: '0px',
        fontSize: '15px',
        fontWeight: 600,
        color: '#006ee0',
        outline: 'none',
        boxShadow: 'none',
        borderRadius: '10px',
      }}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default BoldTextButton;
