import React, { useState } from 'react';
import { PlusSquareOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';
// component
import RibbonButton from 'common/components/button/RibbonButton';
import { RibbonSection } from 'common/components';
// sections
import SaveTicketingGroupModal from './SaveTicketingGroupModal';

const CreateTicketingGroup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleClick = () => toggleModal();

  return (
    <RibbonSection>
      <RibbonButton
        icon={<PlusSquareOutlined />}
        label={Messages.createTiketingGroup}
        onClick={handleClick}
      />
      {isModalOpen && (
        <SaveTicketingGroupModal
          isEdit={false}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </RibbonSection>
  );
};

export default CreateTicketingGroup;
