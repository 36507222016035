import React, { useEffect, useRef, useMemo } from 'react';

import { Row, Col, Space, Checkbox, Input } from 'antd';
import _ from 'lodash';

import {
  getAllExpandKeys,
  getTreeData,
  compareExpandKey,
} from 'utils/exportProductProperties';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

const { Search } = Input;

const CopyProductToolbar = (props) => {
  const intl = useIntl();
  const expandKeysRef = useRef([]);

  const {
    visible,
    dataSource,
    leftExpandedKeys,
    setLeftExpandedKeys,
    setIsShowSelection,
    isSameSize,
    onChangeSameSize,
    isSameStyleFlavor,
    onChangeSameStyleFlavor,
    isHeaderOnly,
    onChangeHeaderOnly,
    isCopyAllPopulated,
    setIsCopyAllPopulated,
    searchHook,
    disabled,
  } = props;

  const [searchText, setSearchText] = searchHook;

  const isExpandedAll = useMemo(
    () => compareExpandKey(leftExpandedKeys, expandKeysRef.current, visible),
    [leftExpandedKeys, visible]
  );

  const onSearch = (value) => {
    setSearchText(value);
  };

  const onChangeExpandAll = (e) => {
    const checked = e.target.checked;
    if (checked) setLeftExpandedKeys(expandKeysRef.current);
    else setLeftExpandedKeys([]);
  };

  const onChangeShowSelection = (e) => {
    const checked = e.target.checked;
    setIsShowSelection(checked);
  };

  const onChangeCopyAllPopulated = (e) => {
    const checked = e.target.checked;
    setIsCopyAllPopulated(checked);
  };

  const initExpandKeys = () => {
    const treeData = getTreeData(dataSource);
    const allExpandKeys = getAllExpandKeys(treeData);
    expandKeysRef.current = allExpandKeys;
  };

  useEffect(() => {
    initExpandKeys();
  }, [dataSource]);

  return (
    <Row style={{ marginBottom: 10 }}>
      <Col span={24} style={{ display: 'flex' }}>
        <Col flex={0}>
          <Search
            allowClear
            enterButton
            onSearch={onSearch}
            style={{ width: 300 }}
            placeholder={intl.formatMessage(Messages.exportPropertiesSearch)}
          />
        </Col>

        <Col flex={1}></Col>

        <Col flex={0}>
          <Space size={16}>
            <Checkbox checked={isExpandedAll} onChange={onChangeExpandAll}>
              {intl.formatMessage(Messages.exportPropertiesExpandAll)}
            </Checkbox>
          </Space>
        </Col>
      </Col>
      <Col span={24} style={{ display: 'flex' }}>
        <Col flex={1} />
        <Col flex={0}>
          <Space size={16}>
            <Checkbox
              checked={isHeaderOnly}
              onChange={onChangeHeaderOnly}
              disabled={disabled}
            >
              Header Only
            </Checkbox>
            <Checkbox
              checked={isSameSize}
              onChange={onChangeSameSize}
              disabled={disabled}
            >
              Same Size
            </Checkbox>
            <Checkbox
              checked={isSameStyleFlavor}
              onChange={onChangeSameStyleFlavor}
              disabled={disabled}
            >
              Same Style/Flavor
            </Checkbox>
            <Checkbox
              checked={isCopyAllPopulated}
              onChange={onChangeCopyAllPopulated}
            >
              Copy All Populated
            </Checkbox>
          </Space>
        </Col>
      </Col>
    </Row>
  );
};

export default CopyProductToolbar;
