import * as types from './constants';

/**
 * Get member profile data
 *
 * @param {object} data member profile
 */
export function getMemberProfileHeader(MemberId) {
  return {
    type: types.GET_MEMBER_PENDING,
    MemberId,
  };
}

/**
 * Loading member profile data to store
 *
 * @param  {object} data member profile
 *
 */
export function loadMemberProfile(data) {
  return {
    type: types.GET_MEMBER_SUCCESS,
    data,
  };
}

/**
 * Set member profile data error
 *
 * @param  {object} data member profile
 *
 */
export function errorMemberProfile(error) {
  return {
    type: types.GET_MEMBER_ERROR,
    error,
  };
}

/**
 * Get member contact history data
 *
 * @param {object} data member contact history
 */
export function getMemberContactHistory(payload) {
  return {
    type: types.GET_MEMBER_CONTACT_HISTORY,
    payload,
  };
}

/**
 * Loading member contact history data to store
 *
 * @param  {object} data member contact history success
 *
 */
export function getMemberContactHistorySuccess(data) {
  return {
    type: types.GET_MEMBER_CONTACT_HISTORY_SUCCESS,
    data,
  };
}

/**
 * Set member contact history data error
 *
 * @param  {object} data member contact history error
 *
 */
export function getMemberContactHistoryError(error) {
  return {
    type: types.GET_MEMBER_CONTACT_HISTORY_ERROR,
    error,
  };
}

export function fetchMemberDocument(payload) {
  return {
    type: types.FETCH_MEMBER_OVERVIEW_DOCUMENT,
    payload,
  };
}

export function fetchMemberDocumentSuccess(data) {
  return {
    type: types.FETCH_MEMBER_OVERVIEW_DOCUMENT_SUCCESS,
    data,
  };
}

export function fetchMemberDocumentError(error) {
  return {
    type: types.FETCH_MEMBER_OVERVIEW_DOCUMENT_ERROR,
    error,
  };
}

/******************************************************************** */
/* *******************  Grid Columns Actions ************************ */
/******************************************************************** */
export function gridColumnMemberContact(gridName, MemberId) {
  return {
    type: types.GET_MEMBER_CONTACT_GRID,
    gridName,
    MemberId,
  };
}

export function gridColumnMemberContactSuccess(columns) {
  return {
    type: types.GET_MEMBER_CONTACT_GRID_SUCCESS,
    columns,
  };
}

export function gridColumnMemberContactError(error) {
  return {
    type: types.GET_MEMBER_CONTACT_GRID_ERROR,
    error,
  };
}

export function gridColumnContactLocation(gridName, MemberId) {
  return {
    type: types.GET_CONTACT_LOCATION_GRID,
    gridName,
    MemberId,
  };
}

export function gridColumnContactLocationSuccess(columns) {
  return {
    type: types.GET_CONTACT_LOCATION_GRID_SUCCESS,
    columns,
  };
}

export function gridColumnContactLocationError(error) {
  return {
    type: types.GET_CONTACT_LOCATION_GRID_ERROR,
    error,
  };
}

export function gridColumnMemberBilling(gridName, MemberId) {
  return {
    type: types.GET_MEMBER_BILLING_GRID,
    gridName,
    MemberId,
  };
}

export function gridColumnMemberBillingSuccess(columns) {
  return {
    type: types.GET_MEMBER_BILLING_GRID_SUCCESS,
    columns,
  };
}

export function gridColumnMemberBillingError(error) {
  return {
    type: types.GET_MEMBER_BILLING_GRID_ERROR,
    error,
  };
}

export function gridColumnActivityLog(gridName, MemberId) {
  return {
    type: types.GET_MEMBER_ACTIVITY_LOG_GRID,
    gridName,
    MemberId,
  };
}

export function gridColumnActivityLogSuccess(columns) {
  return {
    type: types.GET_MEMBER_ACTIVITY_LOG_GRID_SUCCESS,
    columns,
  };
}

export function gridColumnActivityLogError(error) {
  return {
    type: types.GET_MEMBER_ACTIVITY_LOG_GRID_ERROR,
    error,
  };
}

export function gridColumnMemberContactHistory(gridName) {
  return {
    type: types.GET_MEMBER_CONTACT_HISTORY_GRID,
    gridName,
  };
}

export function gridColumnMemberContactHistorySuccess(columns) {
  return {
    type: types.GET_MEMBER_CONTACT_HISTORY_GRID_SUCCESS,
    columns,
  };
}

export function gridColumnMemberContactHistoryError(error) {
  return {
    type: types.GET_MEMBER_CONTACT_HISTORY_GRID_ERROR,
    error,
  };
}

//* toggle upload media box
export function toggleUploadTagLineMedia(status) {
  return {
    type: types.TOGGLE_TAG_LINE_UPLOAD_MEDIA,
    status,
  };
}

//* toggle upload media box PREVIEW
export function togglePreviewUploadTagLineMedia(status) {
  return {
    type: types.TOGGLE_PREVIEW_TAG_LINE_UPLOAD_MEDIA,
    status,
  };
}
//* show upload media box
export function showUploadTagLineMedia(status) {
  return {
    type: types.IS_SHOW_LINE_UPLOAD_MEDIA,
    status,
  };
}

export function getMemberShortDetailPreview(id) {
  return {
    type: types.GET_MEMBER_SHORT_DETAIL_PREVIEW,
    id,
  };
}

export function getMemberShortDetailPreviewSuccess(data) {
  return {
    type: types.GET_MEMBER_SHORT_DETAIL_PREVIEW_SUCCESS,
    data,
  };
}

export function getMemberShortDetailPreviewError(error) {
  return {
    type: types.GET_MEMBER_SHORT_DETAIL_PREVIEW_ERROR,
    error,
  };
}

export function gridColumnPublicContact(gridName) {
  return {
    type: types.GET_PUBLIC_CONTACT_COLUMN,
    gridName,
  };
}

export function gridColumnPublicContactSuccess(columns) {
  return {
    type: types.GET_PUBLIC_CONTACT_COLUMN_SUCCESS,
    columns,
  };
}

export function gridColumnPublicContactError(error) {
  return {
    type: types.GET_PUBLIC_CONTACT_COLUMN_ERROR,
    error,
  };
}

export function getPublicContact(memberId) {
  return {
    type: types.GET_PUBLIC_CONTACT,
    memberId,
  };
}

export function getPublicContactSuccess(data) {
  return {
    type: types.GET_PUBLIC_CONTACT_SUCCESS,
    data,
  };
}

export function getPublicContactError(error) {
  return {
    type: types.GET_PUBLIC_CONTACT_ERROR,
    error,
  };
}

export function updatePublicContact(data) {
  return {
    type: types.UPDATE_PUBLIC_CONTACT,
    data,
  };
}

export function getMemberContacts(params) {
  return {
    type: types.GET_MEMBER_CONTACTS,
    params,
  };
}

export function getMemberContactsSuccess(data) {
  return {
    type: types.GET_MEMBER_CONTACTS_SUCCESS,
    data,
  };
}

export function getMemberContactsError(error) {
  return {
    type: types.GET_MEMBER_CONTACTS_ERROR,
    error,
  };
}

// get member brands

export function getMemberBrands(params) {
  return {
    type: types.GET_MEMBER_OVERVIEW_BRANDS,
    params,
  };
}

export function getMemberBrandsSuccess(data) {
  return {
    type: types.GET_MEMBER_OVERVIEW_BRANDS_SUCCESS,
    data,
  };
}

export function getMemberBrandsError(error) {
  return {
    type: types.GET_MEMBER_OVERVIEW_BRANDS_ERROR,
    error,
  };
}

// get information sheet
export function getMemberInformationSheet(params) {
  return {
    type: types.GET_MEMBER_INFORMATION_SHEET,
    params,
  };
}

export function getMemberInformationSheetSuccess(data) {
  return {
    type: types.GET_MEMBER_INFORMATION_SHEET_SUCCESS,
    data,
  };
}

export function getMemberInformationSheetError(error) {
  return {
    type: types.GET_MEMBER_INFORMATION_SHEET_ERROR,
    error,
  };
}

export function documentDetailColumnInfo(gridName) {
  return {
    type: types.DOCUMENT_DETAIL_COLUMN_INFO,
    gridName,
  };
}

export function documentDetailColumnInfoSuccess(columns) {
  return {
    type: types.DOCUMENT_DETAIL_COLUMN_INFO_SUCCESS,
    columns,
  };
}

export function documentDetailColumnInfoError(error) {
  return {
    type: types.DOCUMENT_DETAIL_COLUMN_INFO_ERROR,
  };
}

export function getDocumentDetail(dataParams) {
  return {
    type: types.GET_DOCUMENT_DETAIL,
    dataParams,
  };
}

export function getDocumentDetailSuccess(data) {
  return {
    type: types.GET_DOCUMENT_DETAIL_SUCCESS,
    data,
  };
}

export function getDocumentDetailError(error) {
  return {
    type: types.GET_DOCUMENT_DETAIL_ERROR,
  };
}

export function deleteDocumentDetail(dataParams) {
  return {
    type: types.DELETE_DOCUMENT_DETAIL,
    dataParams,
  };
}

export function deleteDocumentDetailError(error) {
  return {
    type: types.DELETE_DOCUMENT_DETAIL_ERROR,
  };
}
