import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import reportingReducer from 'pages/reporting/controllers/reducer';
import * as reportingActions from 'pages/reporting/controllers/actions';
import * as reportingSelectors from 'pages/reporting/controllers/selectors';

import { useInjectReducer } from 'utils/common/injectedReducers';

export const useOpenReportingResult = (props = {}) => {
  const dispatch = useDispatch();
  const shouldKeepSettingRef = useRef(false);

  useInjectReducer({ key: 'reportingFullView', reducer: reportingReducer });

  const { form, setQueryConditions, setDataColumns, setType } = props;

  const shouldKeepSetting = useSelector(
    reportingSelectors.makeSelectKeepSetting()
  );
  const setting = useSelector(reportingSelectors.makeSelectSetting());

  const resultGridPagination = useSelector(
    reportingSelectors.makeSelectResultGridPagination()
  );
  const shouldSetPrevResultPagination = useSelector(
    reportingSelectors.makeSelectKeepResultGridPagination()
  );
  const shouldSetPrevSetting = setting && shouldKeepSetting;

  const updateSetting = (setting) => {
    dispatch(reportingActions.updateSetting(setting));
  };

  const updateResultGridPagination = (pagination) => {
    dispatch(reportingActions.updateResultGridPagination(pagination));
  };

  const keepSetting = () => {
    if (setting) dispatch(reportingActions.keepSetting(true));
  };

  const keepResultGridPagination = (value) => {
    dispatch(reportingActions.keepResultGridPagination(value));
  };

  const clearCreatingSetting = () => {
    //* always set keepSetting to false on unmount
    dispatch(reportingActions.keepSetting(false));

    if (shouldKeepSettingRef.current) return;

    updateSetting(null);
  };

  const initSetting = ({ doRunReporting } = {}) => {
    if (!setting) return;

    form.setFieldsValue(setting.formValues);
    setQueryConditions && setQueryConditions(setting?.dataQueryConditions);
    setDataColumns && setDataColumns(setting?.dataColumns);
    setType && setType(setting.type);

    doRunReporting &&
      setTimeout(
        () =>
          doRunReporting(
            setting?.dataColumns,
            setting?.dataQueryConditions,
            setting.type
          ),
        1000
      );
  };

  useEffect(() => {
    shouldKeepSettingRef.current = shouldKeepSetting;
  }, [shouldKeepSetting]);

  return {
    setting,
    keepSetting,
    clearCreatingSetting,
    shouldSetPrevSetting,
    initSetting,
    updateSetting,
    resultGridPagination,
    updateResultGridPagination,
    shouldSetPrevResultPagination,
    keepResultGridPagination,
  };
};
