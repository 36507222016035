import * as types from './constants';

const getProductHierarchyShortDetail = (productId) => ({
  type: types.GET_PRODUCT_HIERARCHY_SHORT_DETAIL,
  payload: { productId },
});
const getProductHierarchyShortDetailSuccess = (data) => ({
  type: types.GET_PRODUCT_HIERARCHY_SHORT_DETAIL_SUCCESS,
  payload: data,
});
const getProductHierarchyShortDetailError = (error) => ({
  type: types.GET_PRODUCT_HIERARCHY_SHORT_DETAIL_ERROR,
  payload: error,
});
const resetProductHierarchyShortDetail = () => ({
  type: types.RESET_PRODUCT_HIERARCHY_SHORT_DETAIL,
});

// get product documents
export function getProductDocuments(params) {
  return {
    type: types.GET_PRODUCT_DOCUMENTS,
    params,
  };
}

export function getProductDocumentsSuccess(data) {
  return {
    type: types.GET_PRODUCT_DOCUMENTS_SUCCESS,
    data,
  };
}

export function getProductDocumentsError(error) {
  return {
    type: types.GET_PRODUCT_DOCUMENTS_ERROR,
    error,
  };
}

export function updateActiveContent(payload) {
  return {
    type: types.UPDATE_ACTIVE_CONTENT,
    payload,
  };
}

const updateSelectedHierarchy = (payload) => {
  return {
    type: types.UPDATE_SELECTED_HIERARCHY,
    payload,
  };
};

//* product headers
export const getProductBrandImage = (payload) => ({
  type: types.GET_PRODUCT_BRAND_IMAGE,
  payload,
});
export const getProductBrandImageSuccess = (data) => ({
  type: types.GET_PRODUCT_BRAND_IMAGE_SUCCESS,
  data,
});
export const getProductBrandImageFail = (error) => ({
  type: types.GET_PRODUCT_BRAND_IMAGE_FAIL,
  error,
});

export const getProductSocialMedia = (payload) => ({
  type: types.GET_PRODUCT_SOCIAL_MEDIA,
  payload,
});
export const getProductSocialMediaSuccess = (payload) => ({
  type: types.GET_PRODUCT_SOCIAL_MEDIA_SUCCESS,
  payload,
});
export const getProductSocialMediaFail = (payload) => ({
  type: types.GET_PRODUCT_SOCIAL_MEDIA_FAIL,
  payload,
});

export const getMarketingAssets = (payload) => ({
  type: types.GET_MARKETING_ASSETS,
  payload,
});
export const getMarketingAssetsSuccess = (payload) => ({
  type: types.GET_MARKETING_ASSETS_SUCCESS,
  payload,
});

export const getMarketingAssetsPrimary = (payload) => ({
  type: types.GET_MARKETING_ASSETS_PRIMARY,
  payload,
});

export const getMarketingAssetsPrimarySuccess = (payload) => ({
  type: types.GET_MARKETING_ASSETS_PRIMARY_SUCCESS,
  payload,
});

export const getMarketingAssetsPrimaryFail = (payload) => ({
  type: types.GET_MARKETING_ASSETS_PRIMARY_FAIL,
});

export const getMarketingAssetsFail = (payload) => ({
  type: types.GET_MARKETING_ASSETS_FAIL,
});
// Get product fact panel
export function getProductFactPanel(params) {
  return {
    type: types.GET_PRODUCT_FACTS_PANEL,
    params: { ProductItemId: params },
  };
}

export function getProductFactPanelSuccess(data) {
  return {
    type: types.GET_PRODUCT_FACTS_PANEL_SUCCESS,
    data,
  };
}

export function getProductFactPanelError(params) {
  return {
    type: types.GET_PRODUCT_FACTS_PANEL_ERROR,
    params,
  };
}

// Update product fact panel
export function updateProductFactPanel(params) {
  return {
    type: types.UPDATE_PRODUCT_FACTS_PANEL,
    params,
  };
}

export function updateProductFactPanelSuccess(data) {
  return {
    type: types.UPDATE_PRODUCT_FACTS_PANEL_SUCCESS,
    data,
  };
}

export function updateProductFactPanelError(params) {
  return {
    type: types.UPDATE_PRODUCT_FACTS_PANEL_ERROR,
    params,
  };
}

export const getProductImagery = (payload) => ({
  type: types.GET_PRODUCT_IMAGERY,
  payload,
});
export const getProductImagerySuccess = (payload) => ({
  type: types.GET_PRODUCT_IMAGERY_SUCCESS,
  payload,
});
export const getProductImageryFail = (payload) => ({
  type: types.GET_PRODUCT_IMAGERY_FAIL,
  payload,
});
export const resetProductImagery = () => ({
  type: types.RESET_PRODUCT_IMAGERY,
});

export const setCurrentHierarchy = (payload) => ({
  type: types.SET_CURRENT_HIERARCHY,
  payload,
});

// Product hierarchy short details to show drawer
const getProductHierarchyShortDetails = (params) => ({
  type: types.GET_PRODUCT_HIERARCHY_DETAILS,
  params,
});
const getProductHierarchyShortDetailsSuccess = (data) => ({
  type: types.GET_PRODUCT_HIERARCHY_DETAILS_SUCCESS,
  data,
});
const getProductHierarchyShortDetailsError = (error) => ({
  type: types.GET_PRODUCT_HIERARCHY_DETAILS_ERROR,
  error,
});
const resetProductHierarchyShortDetails = () => ({
  type: types.RESET_PRODUCT_HIERARCHY_DETAILS,
});

// update hierarchy short details mode to show drawer
const updateHierarchyShortDetailsDisplayMode = (
  hierarchyShortDetailsDisplayMode
) => ({
  type: types.UPDATE_HIERARCHY_SHORT_DETAILS_DISPLAY_MODE,
  hierarchyShortDetailsDisplayMode,
});

const updateHierarchyEndpoint = (payload) => ({
  type: types.UPDATE_HIERARCHY_ENDPOINT,
  payload,
});

export const updateProductDetail = (params) => ({
  type: types.EDIT_PRODUCT_DETAIL,
  params,
});

export const updateProductDetailSuccess = (data) => ({
  type: types.EDIT_PRODUCT_DETAIL_SUCCESS,
  data,
});
export const updateProductDetailError = (error) => ({
  type: types.EDIT_PRODUCT_DETAIL_FAIL,
  error,
});

export function getSubscriptionProductList(params) {
  return {
    type: types.GET_SUBSCRIPTION_PRODUCT_LIST,
    params,
  };
}
export function getSubscriptionProductListSuccess(subscriptionProductList) {
  return {
    type: types.GET_SUBSCRIPTION_PRODUCT_LIST_SUCCESS,
    subscriptionProductList,
  };
}
export function getSubscriptionProductListError(error) {
  return {
    type: types.GET_SUBSCRIPTION_PRODUCT_LIST_ERROR,
    error,
  };
}

export function subscriptionProductGridColumnInfo(gridName) {
  return {
    type: types.SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO,
    gridName,
  };
}
export function subscriptionProductGridColumnInfoSuccess(
  columnSubscriptionProduct
) {
  return {
    type: types.SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_SUCCESS,
    columnSubscriptionProduct,
  };
}
export function subscriptionProductGridColumnInfoError(error) {
  return {
    type: types.SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_ERROR,
    error,
  };
}

export const getMappingColumnInfo = () => ({
  type: types.GET_MAPPING_GRID_COLUMN,
});

export const getMappingColumnInfoSucces = (payload) => ({
  type: types.GET_MAPPING_GRID_COLUMN_SUCCESS,
  payload,
});

export function updateSelectionSubscription(selectionSubscription) {
  return {
    type: types.UPDATE_SELECTION_SUBSCRIPTION,
    selectionSubscription,
  };
}

export const getMappingMetadata = (payload) => ({
  type: types.GET_MAPPING_METADATA,
  payload,
});

export const getMappingMetadataSuccess = (payload) => ({
  type: types.GET_MAPPING_METADATA_SUCCESS,
  payload,
});

export const getMappingMetadataFail = (payload) => ({
  type: types.GET_MAPPING_METADATA_FAIL,
  payload,
});

export function gridColumnMappingDataProperties(gridName) {
  return {
    type: types.GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN,
    gridName,
  };
}

export function gridColumnMappingDataPropertiesSuccess(columns) {
  return {
    type: types.GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_SUCCESS,
    columns,
  };
}

export function gridColumnMappingDataPropertiesError(error) {
  return {
    type: types.GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_ERROR,
    error,
  };
}

export const getMappingSupportMemColumnInfo = () => ({
  type: types.GET_MAPPING_SUPPORT_MEM_COLUMN,
});

export const getMappingSupportMemColumnInfoSucces = (payload) => ({
  type: types.GET_MAPPING_SUPPORT_MEM_COLUMN_SUCCESS,
  payload,
});

export const setSelectedMappingSupportMem = (payload) => ({
  type: types.SET_SELECTED_MAPPING_SUPPORT_MEM,
  payload,
});

export const reloadMappingSupportMemGrid = () => ({
  type: types.RELOAD_MAPPING_SUPPORTED_MEMBER_GRID,
});

export const reloadMappingSupportMemGridSuccess = (status) => ({
  type: types.RELOAD_MAPPING_SUPPORTED_MEMBER_GRID_SUCCESS,
  status,
});

export const saveSelectedMapping = (id) => ({
  type: types.SAVE_SELECTED_MAPPING,
  id,
});

export const getSyndicationHistoryGridColumns = (gridName) => ({
  type: types.GET_SYNDICATION_HISTORY_GRID_COLUMNS,
  gridName,
});

export const getSyndicationHistoryGridColumnsSuccess = (
  columnsSyndicationHistory
) => ({
  type: types.GET_SYNDICATION_HISTORY_GRID_COLUMNS_SUCCESS,
  columnsSyndicationHistory,
});

export const getSyndicationHistoryGridColumnsFailure = () => ({
  type: types.GET_SYNDICATION_HISTORY_GRID_COLUMNS_FAILURE,
});

export const getSyndicationHistoryShortDetails = (id) => ({
  type: types.GET_SYNDICATION_HISTORY_SHORT_DETAILS,
  id,
});

export const getSyndicationHistoryShortDetailsSuccess = (data) => ({
  type: types.GET_SYNDICATION_HISTORY_SHORT_DETAILS_SUCCESS,
  data,
});

export const getSyndicationHistoryShortDetailsError = (error) => ({
  type: types.GET_SYNDICATION_HISTORY_SHORT_DETAILS_ERROR,
  error,
});

export const toggleProductDetailExpand = (status) => ({
  type: types.TOGGLE_PRODUCT_DETAIL_EXPAND,
  status,
});

export {
  getProductHierarchyShortDetail,
  getProductHierarchyShortDetailSuccess,
  getProductHierarchyShortDetailError,
  getProductHierarchyShortDetails,
  getProductHierarchyShortDetailsSuccess,
  getProductHierarchyShortDetailsError,
  updateHierarchyShortDetailsDisplayMode,
  updateSelectedHierarchy,
  updateHierarchyEndpoint,
  resetProductHierarchyShortDetails,
  resetProductHierarchyShortDetail,
};
