import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image, Row, Col } from 'antd';
import { EnhancedMarkdown } from 'common/components';
import { useResize } from 'hooks/useResize';
import { Images } from 'config/assets';

const markdownContentId = 'pim-description-markdown-content';

const ProductOverviewContent = ({
  product,
  heightContainer,
  heightPrimaryInfo,
  ...rest
}) => {
  const [textOverview, setTextOverview] = useState('');
  const wrapperSize = useResize(markdownContentId);

  useEffect(() => {
    setTextOverview(renderContentTextOverview());
  }, [JSON.stringify(product)]);

  const renderContentTextOverview = () => {
    return (
      product?.rivirMarketingDescription ||
      product?.eCommerceProductDescription ||
      product?.eCommerceLongDescriptionA ||
      product?.eCommerceLongDescriptionB ||
      product?.eCommerceShelfDescription ||
      product?.formatFoodServiceProductDescription ||
      ''
    );
  };

  const renderImageOverview = () => {
    return product?.productMarketingImage || Images.RIVIR_LOGO_DEFAULT; // first image of additional
  };

  return (
    <div
      className='product-overview__content scroller scroller--y'
      style={{
        height: `calc(100vh - (164px + ${heightContainer || 0}px + ${
          heightPrimaryInfo || 0
        }px))`,
      }}
    >
      <Row>
        <Col span={12}>
          <EnhancedMarkdown
            source={textOverview}
            containerSize={wrapperSize}
            // refSource={refSource}
          />
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <Image
            className='product-overview__content-marketing-image'
            src={renderImageOverview()}
            preview={false}
          />
        </Col>
      </Row>
    </div>
  );
};

ProductOverviewContent.propTypes = {
  product: PropTypes.object,
};

export default ProductOverviewContent;
