import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';

import classnames from 'classnames';
import {
  Row,
  Col,
  Button,
  Typography,
  Space,
  Select,
  Tooltip,
  Input,
} from 'antd';
import {
  PlusOutlined,
  MinusCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { isEmpty, sortBy } from 'lodash';

import {
  Form,
  SectionWrapper,
  WithLoading,
  TargetMarketInput,
  WrapperSelect,
  CustomNotification,
} from 'common/components';
import { EditContainer, CountryEditorRender } from 'pages/qa-spec/components';
import AddAssetPimModal from 'pages/product-full-view/components/modal/add-asset-pim-modal';
import DietaryCertificateUploadBtn from './DietaryCertificateUploadBtn';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';
import EditInputCountry from 'common/components/form/edit-input-country/EditInputCountry';

import { qaToggleReloadDocumentCertificateTab } from 'pages/qa-spec/controllers/actions';

import {
  useProductSpecInfo,
  useAutoFocusAddedField,
  useMutationUploadCertificate,
  checkIsInvalidCountryOfOrigin,
} from 'pages/qa-spec/hooks/useProductSpecInfo';
import { findProductPropertyEnums } from 'pages/product-full-view/components/product-detail-view/utils';

import { useGetEnumsByEnumName, useGetProductEnums } from 'hooks';

import {
  DAIRY_SECTION_TEXT_AREA,
  DAIRY_SECTION_TEXT_AREA_2,
  MINIMUM_INPUT_FIELD,
  PRODUCT_SPEC_FORM_SECTION_SCHEMA,
} from '../../constant';

import Messages from 'i18n/messages/qa-spec';
import CreateMSDSDocumentModal from 'pages/product-full-view/components/modal/add-document-msds/CreateMSDSDocumentModal';
import {
  HighlightContent,
  PrimaryFieldSnapshot,
  RetailerSnapshot,
} from './snapshot/RetailerSnapshot';

import UploadCertificate from './UploadCertificate';
import sortByKey from 'utils/sortByKey';

import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import { useGetQaSpecSnapshot } from './snapshot/hooks';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';
import { useGetEnumOptions } from 'pages/qa-spec/hooks';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';

const { Text } = Typography;

const { Option } = Select;

const { TextArea } = Input;

const QaSpecProductInfo = (props) => {
  const { productLoading, refetchProductItems, productId, productFull } = props;
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const editContainerRef = useRef();

  const [isLinkToNewAssetModalOpen, setIsLinkToNewAssetModalOpen] =
    useState(false);

  const { dataQaSpecSnapshot } = useGetQaSpecSnapshot();

  const [visible, setVisible] = useState(false);
  const [isAddDocumentForMSDS, setIsAddDocumentForMSDS] = useState(false);
  const [certificateType, setCertificateType] = useState();

  const { mutationUploadCertificate } = useMutationUploadCertificate({
    id: productId,
  });

  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  const isRequiredCountryOfOrigin =
    isProductAssignedByRetailer &&
    requiredFields?.includes(
      'qaSpecification.qaSpecProductSpecification.countryOfOrigin'
    );

  const {
    dataProductSpec,
    loading,
    isSubmitted,
    countryOfOrigin,
    setCountryOfOrigin,
    nonfoodIngredientStatement,
    setNonfoodIngredientStatement,
    onSubmit,
    onValuesChange,
    disabledCertifier,
    checkRequiredField,
    onCancel,
  } = useProductSpecInfo({
    productId,
    form,
    productLoading,
    refetchProductItems,
    isRequiredCountryOfOrigin,
  });

  const { productEnums } = useGetProductEnums();

  const countryCodeEnum = productEnums?.filter(
    (type) => type.enumName === 'CountryCodeEnum'
  );

  const countrySubDivisionEnum = productEnums?.filter(
    (type) => type.enumName === 'CountrySubDivisionCodeISO3166_2Enum'
  );

  const languageCodeEnum = productEnums?.filter(
    (type) => type.enumName === 'LanguageCodeEnum'
  );

  const enumOptions = useGetEnumOptions()?.productSpec;

  const kosherCertifier = Form.useWatch('kosherCertifier', form) || [];
  const organicCertifier = Form.useWatch('organicCertifier', form) || [];
  const halalCertifier = Form.useWatch('halalCertifier', form) || [];
  const nonGMOCertifier = Form.useWatch('nonGMOCertifier', form) || [];
  const ISOCertifier = Form.useWatch('ISOCertifier', form) || [];
  const BRCCertifier = Form.useWatch('BRCCertifier', form) || [];
  const IFSCertifier = Form.useWatch('IFSCertifier', form) || [];
  const selectedCertifier = [
    ...kosherCertifier,
    ...organicCertifier,
    ...halalCertifier,
    ...nonGMOCertifier,
    ...ISOCertifier,
    ...BRCCertifier,
    ...IFSCertifier,
  ];

  const layoutCol = {
    labelCol: { span: 12 },
    wrapperCol: {
      span: 12,
    },
  };

  const { inputRef, onAddField } = useAutoFocusAddedField();

  const msdsWatch = Form.useWatch('msds', form);

  const handleAddCountryOfOrigin = () => {
    if (countryOfOrigin) {
      setCountryOfOrigin((s) => s.concat({}));
    } else {
      setCountryOfOrigin([{}]);
    }
  };

  const handleRemoveCountryOfOrigin = (index) => {
    setCountryOfOrigin(countryOfOrigin.filter((val, i) => index !== i));
  };
  const handleAddNIStatement = () => {
    if (nonfoodIngredientStatement) {
      setNonfoodIngredientStatement((s) => s.concat({}));
    } else {
      setNonfoodIngredientStatement([{}]);
    }
  };

  const handleRemoveNIStatement = (index) => {
    setNonfoodIngredientStatement(
      nonfoodIngredientStatement.filter((val, i) => index !== i)
    );
  };
  const openModalToUploadCertificate = (_certificateType) => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
    setCertificateType(_certificateType);
  };

  const handleCloseModalUploadCertificate = () => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
    setCertificateType(null);
    dispatch(qaToggleReloadDocumentCertificateTab());
  };

  const handCloseModalUploadDocMSDS = () => {
    setIsAddDocumentForMSDS((prev) => !prev);
    dispatch(qaToggleReloadDocumentCertificateTab());
    setCertificateType(null);
  };

  const handleOpenModalMSDSDocument = () => {
    setIsAddDocumentForMSDS(true);
  };

  const eggsGradingList = useGetEnumsByEnumName('EggsGradingEnum');
  const usdaGradingList = useGetEnumsByEnumName('UsdaGradingEnum');

  const renderSection = (isEdit, section) => {
    return (
      <SectionWrapper
        className='qa-spec-product-spec__info-section'
        title={intl.formatMessage(Messages[section.key].title)}
      >
        <Row>{renderField(isEdit, section.fields, section.key)}</Row>
      </SectionWrapper>
    );
  };

  const renderField = (isEdit, fields, sectionKey) => {
    const minimumInputNumber = MINIMUM_INPUT_FIELD.includes(sectionKey)
      ? 0
      : undefined;

    const inputCollection = {
      text: <PrimaryFieldSnapshot variant='text' readOnly={!isEdit} />,
      number: (
        <PrimaryFieldSnapshot
          variant='number'
          min={minimumInputNumber}
          readOnly={!isEdit}
          style={{ width: '100%' }}
        />
      ),
      checkbox: <PrimaryFieldSnapshot variant='checkbox' disabled={!isEdit} />,
      targetMarket: isEdit ? (
        <TargetMarketInput disabled={!isEdit} />
      ) : (
        <PrimaryFieldSnapshot variant='text' readOnly={true} />
      ),
      country: isEdit ? (
        <EditInputCountry
          getPopupContainer={(trigger) => trigger.parentNode}
          mode='multiple'
        />
      ) : (
        <CountryEditorRender
          wrapperStyle={{
            padding: 5,
            border: '1px solid #dedede',
            height: 28.4,
          }}
        />
      ),
      select: (params) => {
        const { options: optionConfig } = params || {};

        const options =
          typeof optionConfig === 'function'
            ? optionConfig(enumOptions)
            : optionConfig;

        return isEdit ? (
          <PrimaryFieldSnapshot variant='select'>
            {options?.map((option) => (
              <Option
                key={option.enumDisplayName}
                value={option.enumDisplayName}
              >
                {option?.enumDisplayName}
              </Option>
            ))}
          </PrimaryFieldSnapshot>
        ) : (
          <PrimaryFieldSnapshot variant='text' readOnly />
        );
      },
      textarea: (params) => {
        const { inputProps } = params || {};

        return (
          <PrimaryFieldSnapshot
            variant='textarea'
            {...inputProps}
            readOnly={!isEdit}
          />
        );
      },
      productContainsBioengineeredIngredients: (
        <PrimaryFieldSnapshot variant='select' disabled={!isEdit}>
          {['Yes', 'No'].map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </PrimaryFieldSnapshot>
      ),
    };

    return fields.map((field) => {
      const label = intl.formatMessage(Messages?.[sectionKey]?.[field.name]);

      const { inputRender } = field;

      const requiredFieldForSupplier = checkRequiredField(
        requiredFields,
        field?.name
      );

      const isAWGOwnerRequired =
        field?.name === 'productContainsBioengineeredIngredients' &&
        productFull?.ownerId === +process.env.REACT_APP_AWG_ID;

      const rules =
        (requiredFieldForSupplier && isProductAssignedByRetailer) ||
        isAWGOwnerRequired
          ? [
              {
                required: true,
                message: `${label || 'This field'} is required`,
              },
            ]
          : null;

      const InputField = inputRender || inputCollection[field.type];

      const valuePropName = {
        valuePropName: field.type === 'checkbox' ? 'checked' : undefined,
      };

      const snapshotValue = dataQaSpecSnapshot?.[`${field?.name}`];

      const formItemProps = field?.formItemProps || {};

      return (
        <Col span={field.col || 24}>
          {field.isList ? (
            renderFormList(isEdit, field, sectionKey)
          ) : (
            <QaSpecProductSnapshot
              supplierModifiedValue={dataProductSpec?.[`${field?.name}`]}
              snapshotValue={snapshotValue}
              highlightContent={
                <HighlightContent
                  label={`${label}:`}
                  value={snapshotValue}
                  variant={field?.type}
                />
              }
            >
              <Form.Item
                key={field.name}
                name={field.name}
                label={
                  <Text ellipsis={{ tooltip: <strong>{label}</strong> }}>
                    <strong>{label}</strong>
                  </Text>
                }
                rules={rules}
                {...valuePropName}
                {...formItemProps}
              >
                {typeof InputField === 'function'
                  ? InputField({ ...field, isEdit })
                  : InputField}
              </Form.Item>
            </QaSpecProductSnapshot>
          )}
        </Col>
      );
    });
  };

  const renderFormList = (isEdit, fieldSchema, sectionKey) => {
    const disabled = disabledCertifier[fieldSchema.name];

    const dataList = dataProductSpec?.[`${fieldSchema.name}`] ?? [];
    const dataListSnapshot = dataQaSpecSnapshot?.[`${fieldSchema.name}`] ?? [];

    return (
      <Form.List name={fieldSchema.name}>
        {(fields, { add, remove }) => {
          const handleAddField = () => {
            add();
            onAddField(fields?.length);
          };

          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => {
                const options = fieldSchema.enum
                  ? findProductPropertyEnums(fieldSchema.enum, productEnums)
                  : [];

                return (
                  <Row key={key} align='middle' style={{ flexWrap: 'nowrap' }}>
                    <Col flex={1}>
                      <QaSpecProductSnapshot
                        supplierModifiedValue={dataList[idx]}
                        snapshotValue={dataListSnapshot[idx]}
                        highlightContent={
                          <HighlightContent
                            label={null}
                            value={dataListSnapshot[idx]}
                            gutter={0}
                          />
                        }
                        key={`${key}-${idx}`}
                      >
                        <Form.Item
                          {...restField}
                          {...fieldSchema.layout}
                          name={name}
                          label={intl.formatMessage(
                            Messages[sectionKey][fieldSchema.name]
                          )}
                        >
                          {fieldSchema.type === 'select' &&
                          isEdit &&
                          !disabled ? (
                            <WrapperSelect style={{ width: 235 }}>
                              {options.map((option) =>
                                option ? (
                                  <Option
                                    key={option.enumDisplayName}
                                    value={option.enumDisplayName}
                                    disabled={selectedCertifier?.includes(
                                      option.enumDisplayName
                                    )}
                                  >
                                    {option.enumDisplayName}
                                  </Option>
                                ) : null
                              )}
                            </WrapperSelect>
                          ) : (
                            <PrimaryFieldSnapshot
                              ref={(ref) => inputRef(ref, idx)}
                              disabled={disabled}
                              readOnly={!isEdit}
                              variant='text'
                            />
                          )}
                        </Form.Item>
                      </QaSpecProductSnapshot>
                    </Col>
                    <Col flex={'30px'} style={{ paddingLeft: 8 }}>
                      <Form.Item>
                        <CloseOutlined
                          className='form-list-remove-btn'
                          onClick={() => remove(name)}
                          style={{ color: 'red' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
              <Row className='form-list-add-btn' gutter={[5, 5]}>
                <Space>
                  <Button
                    block
                    onClick={() => handleAddField()}
                    icon={<PlusOutlined />}
                    disabled={disabled}
                    type='primary'
                  >
                    Add field
                  </Button>
                  <DietaryCertificateUploadBtn
                    fieldSchema={fieldSchema}
                    disabled={disabled}
                    openModalToUploadCertificate={openModalToUploadCertificate}
                  />
                </Space>
              </Row>
            </>
          );
        }}
      </Form.List>
    );
  };

  const renderLocation = (isEdit, section) => {
    return (
      <SectionWrapper
        className='qa-spec-product-spec__info-section'
        title={intl.formatMessage(Messages[section.key].title)}
      >
        <Row>{renderField(isEdit, section.fields, section.key)}</Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <Text>
                  <strong>Country of Origin</strong>
                </Text>
              }
              required={isRequiredCountryOfOrigin}
            >
              {isEdit && (
                <Row
                  className={classnames({
                    'form-list-add-btn': true,
                    'form-list-add-btn--required-origin-of-country':
                      isRequiredCountryOfOrigin,
                  })}
                >
                  <Button
                    type='dashed'
                    block
                    onClick={() => handleAddCountryOfOrigin()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>

                  {isRequiredCountryOfOrigin &&
                    isSubmitted &&
                    checkIsInvalidCountryOfOrigin(countryOfOrigin) && (
                      <Typography.Text type='danger'>
                        Country of Origin is required
                      </Typography.Text>
                    )}
                </Row>
              )}
            </Form.Item>
            {countryOfOrigin?.length > 0 &&
              countryOfOrigin.map((val, index) => {
                const snapshotCountryOfOrigin =
                  dataQaSpecSnapshot?.countryOfOrigin ?? [];
                const snapshotCountryCode =
                  snapshotCountryOfOrigin?.[`${index}`]?.countryCode;
                const snapshotCountrySubdivisionString =
                  snapshotCountryOfOrigin?.[
                    `${index}`
                  ]?.countrySubdivisionCode?.join(', ');

                const countrySubdivisionString =
                  val?.countrySubdivisionCode?.join(', ');

                return (
                  <Row
                    key={index}
                    align='middle'
                    style={{ flexWrap: 'nowrap' }}
                  >
                    <Col flex={'30px'} style={{ paddingRight: 8 }}>
                      <MinusCircleOutlined
                        className='form-list-remove-btn'
                        onClick={() => handleRemoveCountryOfOrigin(index)}
                      />
                    </Col>
                    <Col flex={1}>
                      <SectionWrapper
                        className='qa-spec-product-spec__info-section'
                        title={`Country of Origin ${index + 1}`}
                      >
                        <QaSpecProductSnapshot
                          supplierModifiedValue={val?.countryCode}
                          snapshotValue={snapshotCountryCode}
                          highlightContent={
                            <HighlightContent
                              label='Country Code:'
                              value={snapshotCountryCode}
                            />
                          }
                        >
                          <Form.Item label={'Country Code'}>
                            <PrimaryFieldSnapshot
                              disabled={!isEdit}
                              variant='select'
                              value={val?.countryCode}
                              showSearch
                              filterOption={(input, option) =>
                                option?.value
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) >= 0
                              }
                              onChange={(value) => {
                                const temp = [...countryOfOrigin];
                                temp[index].countryCode = value;
                                setCountryOfOrigin(temp);
                              }}
                            >
                              {sortBy(countryCodeEnum?.[0]?.enumProperties, [
                                'enumDisplayName',
                                'enumGroup',
                              ])?.map((item, index) => (
                                <Option
                                  value={item?.enumDisplayName}
                                  key={index}
                                  readOnly={!isEdit}
                                >
                                  {item?.enumDisplayName}
                                </Option>
                              ))}
                            </PrimaryFieldSnapshot>
                          </Form.Item>
                        </QaSpecProductSnapshot>

                        <QaSpecProductSnapshot
                          supplierModifiedValue={countrySubdivisionString}
                          snapshotValue={snapshotCountrySubdivisionString}
                          highlightContent={
                            <HighlightContent
                              label='Country Subdivision Code:'
                              value={snapshotCountrySubdivisionString}
                            />
                          }
                        >
                          <Form.Item label={'Country Subdivision Code'}>
                            {isEdit ? (
                              <WrapperSelect
                                value={val?.countrySubdivisionCode}
                                mode='multiple'
                                showSearch
                                filterOption={(input, option) =>
                                  option?.value
                                    ?.toLowerCase()
                                    .indexOf(input?.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                  const temp = [...countryOfOrigin];
                                  temp[index].countrySubdivisionCode = value;
                                  setCountryOfOrigin(temp);
                                }}
                              >
                                {sortBy(
                                  countrySubDivisionEnum?.[0]?.enumProperties,
                                  ['enumDisplayName', 'enumGroup']
                                )?.map((item, index) => (
                                  <Option
                                    value={item?.enumDisplayName}
                                    key={index}
                                    readOnly={!isEdit}
                                  >
                                    {item?.enumDisplayName}
                                  </Option>
                                ))}
                              </WrapperSelect>
                            ) : (
                              <Tooltip title={countrySubdivisionString}>
                                <PrimaryFieldSnapshot
                                  readOnly
                                  variant='text'
                                  value={countrySubdivisionString}
                                />
                              </Tooltip>
                            )}
                          </Form.Item>
                        </QaSpecProductSnapshot>
                      </SectionWrapper>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </SectionWrapper>
    );
  };

  const renderStatement = (isEdit, section) => {
    return (
      <SectionWrapper
        className='qa-spec-product-spec__info-section'
        title={intl.formatMessage(Messages[section.key].title)}
      >
        <Row>{renderField(isEdit, section.fields, section.key)}</Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <Text>
                  <strong>Nonfood Ingredient Statement</strong>
                </Text>
              }
            >
              {isEdit && (
                <Row className='form-list-add-btn'>
                  <Button
                    type='dashed'
                    block
                    onClick={() => handleAddNIStatement()}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Row>
              )}
            </Form.Item>
            {nonfoodIngredientStatement?.length > 0 &&
              nonfoodIngredientStatement.map((val, index) => {
                const nonfoodIngredientStatementSnapshot =
                  dataQaSpecSnapshot?.nonfoodIngredientStatement ?? [];

                const nonfoodIngredientStatementItemSnapshot =
                  nonfoodIngredientStatementSnapshot?.[`${index}`] ?? {};

                const {
                  value: valueSnapshot,
                  languageCode: languageCodeSnapshot,
                  codeListVersion: codeListVersionSnapshot,
                  formattingPattern: formattingPatternSnapshot,
                  sequenceNumber: sequenceNumberSnapshot,
                } = nonfoodIngredientStatementItemSnapshot;

                return (
                  <Row
                    key={index}
                    align='middle'
                    style={{ flexWrap: 'nowrap' }}
                  >
                    <Col flex={'30px'} style={{ paddingRight: 8 }}>
                      <MinusCircleOutlined
                        className='form-list-remove-btn'
                        onClick={() => handleRemoveNIStatement(index)}
                      />
                    </Col>
                    <Col flex={1}>
                      <SectionWrapper
                        className='qa-spec-product-spec__info-section'
                        title={`Nonfood Ingredient Statement ${index + 1}`}
                      >
                        <Form.Item label={'Value'}>
                          <QaSpecProductSnapshot
                            supplierModifiedValue={val?.value}
                            snapshotValue={valueSnapshot}
                            highlightContent={
                              <HighlightContent
                                label='Value:'
                                value={valueSnapshot}
                              />
                            }
                          >
                            <PrimaryFieldSnapshot
                              variant='text'
                              readOnly={!isEdit}
                              value={val?.value}
                              onChange={(e) => {
                                const temp = [...nonfoodIngredientStatement];
                                temp[index].value = e.target.value;
                                setNonfoodIngredientStatement(temp);
                              }}
                            />
                          </QaSpecProductSnapshot>
                        </Form.Item>
                        <Form.Item label={'Language Code'}>
                          <QaSpecProductSnapshot
                            supplierModifiedValue={val?.languageCode}
                            snapshotValue={languageCodeSnapshot}
                            highlightContent={
                              <HighlightContent
                                label='Language Code:'
                                value={languageCodeSnapshot}
                              />
                            }
                          >
                            <PrimaryFieldSnapshot
                              variant='select'
                              disabled={!isEdit}
                              value={val?.languageCode}
                              showSearch
                              filterOption={(input, option) =>
                                option?.value
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) >= 0
                              }
                              onChange={(value) => {
                                const temp = [...nonfoodIngredientStatement];
                                temp[index].languageCode = value;
                                setNonfoodIngredientStatement(temp);
                              }}
                            >
                              {sortBy(languageCodeEnum?.[0]?.enumProperties, [
                                'enumDisplayName',
                                'enumGroup',
                              ])?.map((item, index) => (
                                <Option
                                  value={item?.enumCode}
                                  key={index}
                                  readOnly={!isEdit}
                                >
                                  {`${
                                    item.enumDescription
                                  } - (${item.enumCode.toUpperCase()})`}
                                </Option>
                              ))}
                            </PrimaryFieldSnapshot>
                          </QaSpecProductSnapshot>
                        </Form.Item>
                        <Form.Item label={'Code List Version'}>
                          <QaSpecProductSnapshot
                            supplierModifiedValue={val?.codeListVersion}
                            snapshotValue={codeListVersionSnapshot}
                            highlightContent={
                              <HighlightContent
                                label='Code List Version:'
                                value={codeListVersionSnapshot}
                              />
                            }
                          >
                            <PrimaryFieldSnapshot
                              variant='text'
                              readOnly={!isEdit}
                              value={val?.codeListVersion}
                              onChange={(e) => {
                                const temp = [...nonfoodIngredientStatement];
                                temp[index].codeListVersion = e.target.value;
                                setNonfoodIngredientStatement(temp);
                              }}
                            />
                          </QaSpecProductSnapshot>
                        </Form.Item>
                        <Form.Item label={'Formatting Pattern'}>
                          <QaSpecProductSnapshot
                            supplierModifiedValue={val?.formattingPattern}
                            snapshotValue={formattingPatternSnapshot}
                            highlightContent={
                              <HighlightContent
                                label='Formatting Pattern:'
                                value={formattingPatternSnapshot}
                              />
                            }
                          >
                            <PrimaryFieldSnapshot
                              variant='text'
                              readOnly={!isEdit}
                              value={val?.formattingPattern}
                              onChange={(e) => {
                                const temp = [...nonfoodIngredientStatement];
                                temp[index].formattingPattern = e.target.value;
                                setNonfoodIngredientStatement(temp);
                              }}
                            />
                          </QaSpecProductSnapshot>
                        </Form.Item>
                        <Form.Item label={'Sequence Number'}>
                          <QaSpecProductSnapshot
                            supplierModifiedValue={val?.sequenceNumber}
                            snapshotValue={sequenceNumberSnapshot}
                            highlightContent={
                              <HighlightContent
                                label='Sequence Number:'
                                value={sequenceNumberSnapshot}
                              />
                            }
                          >
                            <PrimaryFieldSnapshot
                              variant='number'
                              readOnly={!isEdit}
                              value={val?.sequenceNumber}
                              onChange={(value) => {
                                const temp = [...nonfoodIngredientStatement];
                                temp[index].sequenceNumber = value;
                                setNonfoodIngredientStatement(temp);
                              }}
                            />
                          </QaSpecProductSnapshot>
                        </Form.Item>
                      </SectionWrapper>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </SectionWrapper>
    );
  };

  const renderMeatFishField = (isEdit) => {
    const { usdaGrade, usdaShieldId, eggsGrading } = dataProductSpec ?? {};

    const {
      usdaGrade: snapshotUsdaGrade,
      usdaShieldId: snapshotUsdaShieldId,
      eggsGrading: snapshotEggsGrading,
    } = dataQaSpecSnapshot ?? {};

    const addDocument = ({ successCallback, ...payload }) => {
      const params = {
        productItemId: productId,
        linkType: 'CERTIFICATE',
        ...payload,
      };
      mutationUploadCertificate.mutate(params, {
        onSuccess: () => {
          setVisible(false);
          successCallback();
          CustomNotification.success(
            'Update certificate document successfully.'
          );
        },
        onError: () => {
          setVisible(false);
          CustomNotification.error('Update failed.');
        },
      });
    };

    return (
      <Row
        id='meat-fish-field'
        gutter={[16, 0]}
        // style={{ marginTop: 24, pointerEvents: isEdit ? 'auto' : 'none' }}
        style={{ marginTop: 24 }}
      >
        <Col span={24}>
          <QaSpecProductSnapshot
            supplierModifiedValue={usdaGrade}
            snapshotValue={snapshotUsdaGrade}
            highlightContent={
              <HighlightContent
                label='USDA / Canadian Grading:'
                value={snapshotUsdaGrade}
              />
            }
          >
            <Form.Item
              name='usdaGrade'
              label={
                <Typography.Text strong>
                  USDA / Canadian Grading
                </Typography.Text>
              }
            >
              <PrimaryFieldSnapshot
                variant='select'
                allowClear
                showSearch
                getPopupContainer={() =>
                  document.getElementById('meat-fish-field')
                }
                disabled={!isEdit}
              >
                {sortByKey(usdaGradingList, 'enumDisplayName')?.map(
                  (property, index) => (
                    <Select.Option
                      value={property.enumDisplayName}
                      key={property.enumDisplayName + index}
                    >
                      {property.enumDisplayName
                        ? property.enumDisplayName
                        : property.enumDescription}
                    </Select.Option>
                  )
                )}
              </PrimaryFieldSnapshot>
            </Form.Item>
          </QaSpecProductSnapshot>
        </Col>
        <Col span={24}>
          <QaSpecProductSnapshot
            supplierModifiedValue={usdaShieldId}
            snapshotValue={snapshotUsdaShieldId}
            highlightContent={
              <HighlightContent
                label='USDA Shield ID'
                value={snapshotUsdaShieldId}
              />
            }
          >
            <Form.Item
              name='usdaShieldId'
              label={<Typography.Text strong>USDA Shield ID</Typography.Text>}
            >
              <PrimaryFieldSnapshot variant='text' />
            </Form.Item>
          </QaSpecProductSnapshot>
        </Col>
        <Col span={24}>
          <Form.Item
            label={
              <Typography.Text strong>
                Certificate Badge or Other Certifications
              </Typography.Text>
            }
          >
            <Button
              icon={<UploadOutlined />}
              danger
              disabled={!isEdit}
              onClick={() => setVisible(true)}
            >
              Upload certificate document
            </Button>
          </Form.Item>
        </Col>
        <Col span={24}>
          <QaSpecProductSnapshot
            supplierModifiedValue={eggsGrading}
            snapshotValue={snapshotEggsGrading}
            highlightContent={
              <HighlightContent
                label='Eggs Grading:'
                value={snapshotEggsGrading}
              />
            }
          >
            <Form.Item
              name='eggsGrading'
              label={<Typography.Text strong>Eggs Grading</Typography.Text>}
            >
              <PrimaryFieldSnapshot
                variant='select'
                allowClear
                getPopupContainer={() =>
                  document.getElementById('meat-fish-field')
                }
                disabled={!isEdit}
              >
                {sortByKey(eggsGradingList, 'enumDisplayName')?.map(
                  (property, index) => (
                    <Select.Option
                      value={property.enumDisplayName}
                      key={property.enumDisplayName + index}
                    >
                      {property.enumDisplayName
                        ? property.enumDisplayName
                        : property.enumDescription}
                    </Select.Option>
                  )
                )}
              </PrimaryFieldSnapshot>
            </Form.Item>
          </QaSpecProductSnapshot>
        </Col>
        {visible && (
          <UploadCertificate
            visible={visible}
            setVisible={setVisible}
            addDocument={addDocument}
            productId={productId}
          />
        )}
      </Row>
    );
  };

  // const TextAreaField = ({ readOnly, item }) => {
  //   const { displayName, fieldName, limitCharacters } = item;
  //   return (
  //     <Form.Item
  //       name={fieldName}
  //       label={<Typography.Text strong>{displayName}</Typography.Text>}
  //       labelCol={{ span: 24 }}
  //       wrapperCol={{ span: 24 }}
  //     >
  //       <Input.TextArea
  //         maxLength={limitCharacters}
  //         showCount
  //         readOnly={readOnly}
  //       />
  //     </Form.Item>
  //   );
  // };

  const TextAreaField = ({ readOnly, item }) => {
    const { displayName, fieldName, limitCharacters } = item;
    const supplierModifiedValue = dataProductSpec?.[`${fieldName}`];
    const snapshotValue = dataQaSpecSnapshot?.[`${fieldName}`];

    return (
      <QaSpecProductSnapshot
        supplierModifiedValue={supplierModifiedValue}
        snapshotValue={snapshotValue}
        highlightContent={
          <HighlightContent label={`${displayName}:`} value={snapshotValue} />
        }
      >
        <Form.Item
          name={fieldName}
          label={<Typography.Text strong>{displayName}</Typography.Text>}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <PrimaryFieldSnapshot
            variant='textarea'
            maxLength={limitCharacters}
            showCount
            readOnly={readOnly}
          />
        </Form.Item>
      </QaSpecProductSnapshot>
    );
  };

  return (
    <WithLoading loading={loading}>
      <div className='qa-spec-product-spec__info'>
        <IXONECertificationWrapper
          onConfirm={(ixoneIgnore) => {
            const toggleEditMode = editContainerRef?.current?.onToggleEditMode;
            onSubmit(toggleEditMode, ixoneIgnore);
          }}
          showModal
          type={TYPE_VIEW.EDIT_PRODUCT}
        >
          {(handleConfirmIXONE) => (
            <EditContainer
              ref={editContainerRef}
              type={EDIT_SECTION.PROD_SPEC_INFO}
              productId={productId}
              handleSave={async (toggleEditMode) => {
                handleConfirmIXONE({
                  originalData: dataProductSpec,
                  changedFields: await form.validateFields(),
                });
              }}
              handleCancel={onCancel}
              float
            >
              {(isEdit) => {
                return (
                  <Form
                    className={classnames('qa-spec-product-spec__info-form', {
                      'qa-spec-product-spec__info-form--edit': isEdit,
                    })}
                    form={form}
                    {...layoutCol}
                    autoComplete='off'
                    onValuesChange={onValuesChange}
                  >
                    <EditTabSectionWrapper.CheckerWrapper
                      isEdit={isEdit}
                      type={EDIT_SECTION.PROD_SPEC_INFO}
                      onSubmit={async (toggleEditMode) => {
                        handleConfirmIXONE({
                          originalData: dataProductSpec,
                          changedFields: await form.validateFields(),
                        });
                      }}
                    >
                      <Row
                        gutter={[16, 12]}
                        // style={{ pointerEvents: isEdit ? 'auto' : 'none' }}
                      >
                        <Col span={12} xxl={8}>
                          {renderSection(
                            isEdit,
                            PRODUCT_SPEC_FORM_SECTION_SCHEMA.DATE_CODING
                          )}
                          {renderStatement(
                            isEdit,
                            PRODUCT_SPEC_FORM_SECTION_SCHEMA.STATEMENT
                          )}
                          {renderLocation(
                            isEdit,
                            PRODUCT_SPEC_FORM_SECTION_SCHEMA.LOCATION
                          )}
                          <div style={{ padding: '23px 10px 10px 10px' }}>
                            <QaSpecProductSnapshot
                              supplierModifiedValue={
                                dataProductSpec?.wicCompliant
                              }
                              snapshotValue={dataQaSpecSnapshot?.wicCompliant}
                              highlightContent={
                                <HighlightContent
                                  label={intl.formatMessage(
                                    Messages.USDA_WIC_eligible_label
                                  )}
                                  value={dataQaSpecSnapshot?.wicCompliant}
                                  variant='checkbox'
                                />
                              }
                            >
                              <Form.Item
                                name={'wicCompliant'}
                                label={
                                  <Text>
                                    <strong>
                                      {intl.formatMessage(
                                        Messages.USDA_WIC_eligible_label
                                      )}
                                    </strong>
                                  </Text>
                                }
                                valuePropName='checked'
                              >
                                <PrimaryFieldSnapshot
                                  variant='checkbox'
                                  disabled={!isEdit}
                                />
                              </Form.Item>
                            </QaSpecProductSnapshot>
                            <div style={{ position: 'relative' }}>
                              <QaSpecProductSnapshot
                                supplierModifiedValue={dataProductSpec?.msds}
                                snapshotValue={dataQaSpecSnapshot?.msds}
                                highlightContent={
                                  <HighlightContent
                                    label={intl.formatMessage(
                                      Messages.MSDSRequired
                                    )}
                                    value={dataQaSpecSnapshot?.msds}
                                    variant='checkbox'
                                  />
                                }
                              >
                                <Form.Item
                                  name={'msds'}
                                  label={
                                    <Text>
                                      <strong>
                                        {intl.formatMessage(
                                          Messages.MSDSRequired
                                        )}
                                      </strong>
                                    </Text>
                                  }
                                  valuePropName='checked'
                                >
                                  <MsdsRequiredField
                                    isEdit={isEdit}
                                    msdsWatch={msdsWatch}
                                    handleOpenModalMSDSDocument={
                                      handleOpenModalMSDSDocument
                                    }
                                  />
                                </Form.Item>
                              </QaSpecProductSnapshot>
                            </div>
                          </div>
                        </Col>

                        <Col span={12} xxl={8}>
                          {renderSection(
                            isEdit,
                            PRODUCT_SPEC_FORM_SECTION_SCHEMA.SHELF_LIFE
                          )}
                          {renderSection(
                            isEdit,
                            PRODUCT_SPEC_FORM_SECTION_SCHEMA.DIETARY
                          )}
                          {renderSection(
                            isEdit,
                            PRODUCT_SPEC_FORM_SECTION_SCHEMA.CASE_TEMPERATURE
                          )}
                          {renderSection(
                            isEdit,
                            PRODUCT_SPEC_FORM_SECTION_SCHEMA.POLYFLUOROALKYL_SUBSTANCES
                          )}
                        </Col>
                        <Col span={12} xxl={8}>
                          {DAIRY_SECTION_TEXT_AREA.map((item, index) => {
                            return (
                              <TextAreaField
                                key={index}
                                readOnly={!isEdit}
                                item={item}
                              />
                            );
                          })}
                          <QaSpecProductSnapshot
                            supplierModifiedValue={
                              dataProductSpec?.glutenFreeClaim
                            }
                            snapshotValue={dataQaSpecSnapshot?.glutenFreeClaim}
                            highlightContent={
                              <HighlightContent
                                label='Gluten Free Claim:'
                                value={dataQaSpecSnapshot?.glutenFreeClaim}
                                variant='checkbox'
                              />
                            }
                          >
                            <Form.Item
                              name='glutenFreeClaim'
                              label={
                                <Typography.Text strong>
                                  Gluten Free Claim
                                </Typography.Text>
                              }
                              valuePropName='checked'
                              labelCol={{ flex: '120px' }}
                            >
                              <PrimaryFieldSnapshot
                                variant='checkbox'
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </QaSpecProductSnapshot>
                          {DAIRY_SECTION_TEXT_AREA_2.map((item, index) => {
                            return (
                              <TextAreaField
                                key={index}
                                readOnly={!isEdit}
                                item={item}
                              />
                            );
                          })}
                          {renderMeatFishField(isEdit)}
                        </Col>
                      </Row>
                    </EditTabSectionWrapper.CheckerWrapper>
                  </Form>
                );
              }}
            </EditContainer>
          )}
        </IXONECertificationWrapper>
      </div>
      {isLinkToNewAssetModalOpen && (
        <AddAssetPimModal
          onCloseModal={handleCloseModalUploadCertificate}
          addType={'Certificate'}
          certificateType={certificateType}
          getImagery={[]}
          getDocument={() => {
            handleCloseModalUploadCertificate();
          }}
          qaSpec
        />
      )}
      {isAddDocumentForMSDS && (
        <CreateMSDSDocumentModal
          onCloseModal={handCloseModalUploadDocMSDS}
          certificateType={certificateType}
          getDocument={() => {
            handCloseModalUploadDocMSDS();
          }}
        />
      )}
    </WithLoading>
  );
};

const MsdsRequiredField = (props) => {
  const { isEdit, msdsWatch, handleOpenModalMSDSDocument, ...restProps } =
    props;
  return (
    <div>
      <PrimaryFieldSnapshot
        variant='checkbox'
        disabled={!isEdit}
        {...restProps}
      />
      {msdsWatch && (
        <Button
          style={{ marginLeft: 20 }}
          type='primary'
          onClick={handleOpenModalMSDSDocument}
          disabled={!isEdit}
        >
          Load
        </Button>
      )}
    </div>
  );
};

const QaSpecProductSnapshot = (props) => {
  const { isSuccess, dataQaSpecSnapshot } = useGetQaSpecSnapshot();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  if (isSuccess && isToggleSnapshot && dataQaSpecSnapshot) {
    return <RetailerSnapshot {...props} />;
  }

  return <>{props.children}</>;
};

export default QaSpecProductInfo;
