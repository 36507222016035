import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Divider } from 'antd';
import * as ProductHeader from './header/index';
import './ProductInfo.less';

const ProductInfo = (props) => {
  const { dataDetail } = props;
  return (
    <Row className='product-info'>
      <Col span={24}>
        <Row>
          <Col span={7} className='product-info__logo'>
            <ProductHeader.ProductLogo dataDetail={dataDetail} />
          </Col>
          <Col className='product-info__info-detail' span={10}>
            <ProductHeader.ProductData dataDetail={dataDetail} />
          </Col>
          <Col className='product-info__info-modify' span={7}>
            <ProductHeader.ProductRightData dataDetail={dataDetail} />
          </Col>
        </Row>
        <ProductHeader.ProductCategory dataDetail={dataDetail} />
        <ProductHeader.ProductIdentification dataDetail={dataDetail} />

        <Row>
          <Col span={16} offset={4}>
            <Divider style={{ margin: '10px 0', borderColor: '#666666' }} />
          </Col>
        </Row>
        <ProductHeader.ProductDescription dataDetail={dataDetail} />
      </Col>
    </Row>
  );
};

ProductInfo.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductInfo;
