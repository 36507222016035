import React, { useMemo } from 'react';

import { Tag } from 'antd';
import { isObject } from 'lodash';

import './EntityStatusTag.less';

const COLORS = [
  {
    name: 'Pending',
    color: '#d9d9d9',
  },
  {
    name: 'Exception',
    color: 'rgba(255, 85, 0, 0.6)',
  },
  {
    name: 'Active',
    color: 'rgb(255, 214, 102)',
  },
];

const EntityStatusTag = (props) => {
  const { children, status, position = 'absolute' } = props;

  const visible = !!status && !isObject(status);

  const tagColor = useMemo(() => {
    const foundStatusIdx = COLORS.findIndex(
      (colorSettingItem) => colorSettingItem?.name === status
    );

    return COLORS?.[foundStatusIdx]?.color || '#d9d9d9';
  }, [status]);

  return (
    <div className='entity-status-tag__container'>
      <Tag
        className='entity-status-tag__tag'
        style={{ position, top: 0, right: 0 }}
        visible={visible}
        color={tagColor}
      >
        {visible ? status : null}
      </Tag>
      {children}
    </div>
  );
};

export default EntityStatusTag;
