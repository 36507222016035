import React from 'react';
import Messages from 'i18n/messages/home';

import { ReactComponent as IconEmail } from 'common/components/button/svg-icons/ICO_10.13.svg';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import './styles.less';

const EmailControl = ({ ...otherProps }) => {
  return (
    <ButtonSmallIcon
      icon={<IconEmail />}
      label={Messages.emailMember}
      className='button-item-dropdown'
      {...otherProps}
    />
  );
};

EmailControl.propTypes = {};

export default EmailControl;
