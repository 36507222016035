import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import './Action.less';

const Action = (props) => {
  const { count, offset, icon, iconStyle, onClick } = props;
  const Icon = icon;
  return (
    <Badge count={count} offset={offset} className='badge badge--custom'>
      <Icon style={iconStyle} onClick={onClick} />
    </Badge>
  );
};

Action.propTypes = {
  count: PropTypes.number,
  offset: PropTypes.array,
  icon: PropTypes.any,
};

export default Action;
