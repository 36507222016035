import React from 'react';

import { Dropdown, Menu, Typography } from 'antd';

import reactCSS from 'reactcss';
import { ReactComponent as PrivateLabel } from 'assets/svg-icons/private-label.svg';

const { Text } = Typography;

const PrivateLabelIcon = (props) => {
  const { shape = 'round', style = {} } = props;

  const styles = reactCSS({
    default: {
      icon: {
        color: 'white',
        width: 19,
        height: 19,
        background: '#08979c',
        padding: 3,
        fontSize: 12,
        fill: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 30,
        position: 'relative',
      },
      iconRound: {
        borderRadius: 100,
        ...style,
      },
      iconSquare: {
        borderRadius: 2,
        ...style,
      },
      overlay: {
        padding: 4,
        fontWeight: 900,
      },
      svg: {
        fill: 'white',
        width: '85%',
        height: '85%',
      },
    },
  });

  const iconWrapperStyle = {
    round: { ...styles.icon, ...styles.iconRound },
    square: { ...styles.icon, ...styles.iconSquare },
  };

  const overLay = (
    <Menu style={styles.overlay}>
      <Menu.Item>
        <Text strong>Private Label</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={overLay} placement='bottomRight'>
      <div style={iconWrapperStyle[shape]}>
        <PrivateLabel style={styles.svg} />
      </div>
    </Dropdown>
  );
};

export default PrivateLabelIcon;
