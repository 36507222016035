import React from 'react';

import classnames from 'classnames';

const ProductDetailGridItemContainer = (props) => {
  const { className, children, gridItemType, style = {} } = props;

  const styles = {
    gridArea: `${gridItemType}`,
    ...style,
  };

  return (
    <div
      className={classnames(
        'product-detail-view__grid-item',
        `product-detail-view__${gridItemType}`,
        className
      )}
      style={styles}
    >
      {children && children}
    </div>
  );
};

export default ProductDetailGridItemContainer;
