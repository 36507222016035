/*
 * User Messages
 *
 * This contains all the text for user
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.user';

export default defineMessages({
  getProfileError: {
    id: `${scope}.profile.getProfileError`,
    defaultMessage: 'Cannot get user profile',
  },
  getProfileLocationsError: {
    id: `${scope}.profile.getProfileLocationsError`,
    defaultMessage: 'Cannot get user profile locations.',
  },
  makeContact: {
    id: `${scope}.profile.components.makeContact`,
    defaultMessage: 'Make Contact',
  },
  contactHistory: {
    id: `${scope}.profile.components.contactHistory`,
    defaultMessage: 'Contact History',
  },
  date: {
    id: `${scope}.profile.components.userActivityLog.date`,
    defaultMessage: 'Date',
  },
  service: {
    id: `${scope}.profile.components.userActivityLog.service`,
    defaultMessage: 'Service',
  },
  method: {
    id: `${scope}.profile.components.userActivityLog.method`,
    defaultMessage: 'Method',
  },
  duration: {
    id: `${scope}.profile.components.userActivityLog.method`,
    defaultMessage: 'Duration',
  },
  code: {
    id: `${scope}.profile.components.userActivityLog.code`,
    defaultMessage: 'Code',
  },
  location: {
    id: `${scope}.profile.components.userActivityLog.location`,
    defaultMessage: 'Location',
  },
  ipAddress: {
    id: `${scope}.profile.components.userActivityLog.location`,
    defaultMessage: 'IP',
  },
  browser: {
    id: `${scope}.profile.components.userActivityLog.browser`,
    defaultMessage: 'Browser',
  },
  os: {
    id: `${scope}.profile.components.userActivityLog.os`,
    defaultMessage: 'OS',
  },
  environment: {
    id: `${scope}.profile.components.userActivityLog.environment`,
    defaultMessage: 'Environment',
  },
  getUserActivityError: {
    id: `${scope}.profile.components.userActivityLog.getUserActivityError`,
    defaultMessage: 'Cannot get user activity detail info',
  },
  firstLogin: {
    id: `${scope}.profile.components.userActivityLog.firstLogin`,
    defaultMessage: 'First Login',
  },
  lastLogin: {
    id: `${scope}.profile.components.userActivityLog.lastLogin`,
    defaultMessage: 'Last Login',
  },
  ytdLogin: {
    id: `${scope}.profile.components.userActivityLog.ytdLogin`,
    defaultMessage: 'YTD Login',
  },
  totalLogin: {
    id: `${scope}.profile.components.userActivityLog.totalLogin`,
    defaultMessage: 'Total Login',
  },
  ytdTransactions: {
    id: `${scope}.profile.components.userActivityLog.ytdTransactions`,
    defaultMessage: 'YTD Transactions',
  },
  totalTransactions: {
    id: `${scope}.profile.components.userActivityLog.totalTransactions`,
    defaultMessage: 'Total Transactions',
  },
});
