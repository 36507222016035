import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Radio, Row, Space, Typography } from 'antd';

import { StyledModal } from 'common/components';
import WarnIcon from 'common/components/dialog-function/WarnIcon';
import {
  checkFieldsChange,
  checkFieldsChangeBulkEdit,
  checkNestedFieldsChange,
} from './utils';

import { useGetProductFullView } from 'hooks';
import {
  SUPPLEMENT_TYPE,
  NUTRITION_TYPE,
  PET_NUTRITION_TYPE,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/constants';

export const TYPE_VIEW = {
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  FACT_PANEL: 'FACT_PANEL',
  INGREDIENTS: 'INGREDIENTS',
  ALLERGENS: 'ALLERGENS',
  IMAGE_PRODUCT: 'IMAGE_PRODUCT',
  BULK_EDIT: 'BULK_EDIT',
};

export const IXONECertificationWrapper = (props) => {
  const [options, setOptions] = useState('ignore');
  const [visible, setVisible] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const { onConfirm, type, onCancel, showModal, children } = props;

  const { id } = useParams();

  const pathName = window.location.pathname;
  const productId = pathName?.includes('product') ? id : null;

  const { productFull } = useGetProductFullView({ productId });

  const { ixOneCertifiedStatus, fromTdcProduct, ixOneId } = productFull;

  const handleConfirmIXONE = ({ originalData, changedFields }) => {
    let hasChangedField = false;

    const hasIXONEShield =
      ixOneId && ixOneCertifiedStatus !== 'Removed' && fromTdcProduct;

    const showConfirmModal = id ? hasIXONEShield && showModal : showModal;

    if (!showConfirmModal) {
      onConfirm(null);
      return;
    }

    if (type === TYPE_VIEW.PRODUCT_DETAIL) {
      hasChangedField = checkNestedFieldsChange({
        originalData,
        changedFields,
      });
    }

    if (type === TYPE_VIEW.EDIT_PRODUCT) {
      hasChangedField = checkFieldsChange({
        originalData,
        changedFields,
      });
    }

    if (type === TYPE_VIEW.ALLERGENS) {
      hasChangedField = changedFields;
    }

    if (type === TYPE_VIEW.INGREDIENTS || type === TYPE_VIEW.IMAGE_PRODUCT) {
      hasChangedField = true;
    }

    if (type === TYPE_VIEW.FACT_PANEL) {
      hasChangedField =
        changedFields === NUTRITION_TYPE ||
        changedFields === SUPPLEMENT_TYPE ||
        changedFields === PET_NUTRITION_TYPE;
    }

    if (type === TYPE_VIEW.BULK_EDIT) {
      hasChangedField = checkFieldsChangeBulkEdit(changedFields, originalData);
    }

    if (hasChangedField) setModalConfirm(hasChangedField);
    else onConfirm(options);
  };

  const handleCancel = () => {
    setOptions('ignore');
    setModalConfirm(false);
  };

  const handleOk = () => {
    if (options === 'continue') {
      setVisible(true);
    }

    if (options === 'ignore') {
      setModalConfirm(false);
      onCancel && onCancel();
    }
  };

  return (
    <>
      <StyledModal
        zIndex={9999}
        title={null}
        width={420}
        bodyStyle={{ paddingTop: 24 }}
        visible={modalConfirm}
        onCancel={handleCancel}
        footer={[
          <Row justify='end'>
            <Col>
              <Space>
                <Button type='primary' disabled={!options} onClick={handleOk}>
                  Yes
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <WarnIcon />
        <div style={{ textAlign: 'center', marginTop: 4 }}>
          <Typography.Text
            style={{
              color: '#6c757d',
              fontSize: 16,
            }}
          >
            <Row justify='center' style={{ padding: '10px 0' }}>
              <Col>
                You have edited a field which was collected during an IX-ONE
                capture event. Many of our retailer and distributor partners
                will only accept IX-ONE Certified content. Removing this
                certification may cause one or more of your partners to reject
                your product information. Would you like to continue?
              </Col>
              <Col>
                <Radio.Group
                  onChange={(e) => {
                    setOptions(e.target.value);
                  }}
                  value={options}
                >
                  <Radio value='continue'>Continue</Radio>
                  <Radio value='ignore'>Cancel my edit(s)</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Typography.Text>
        </div>
      </StyledModal>
      <ConfirmModal
        onCancel={() => {
          setOptions(null);
          setModalConfirm(false);
          onCancel && onCancel();
        }}
        visible={visible}
        setVisible={setVisible}
        setModalConfirm={setModalConfirm}
        onConfirm={onConfirm}
      />
      {children(handleConfirmIXONE)}
    </>
  );
};

const ConfirmModal = ({
  onCancel,
  visible,
  setVisible,
  onConfirm,
  setModalConfirm,
}) => {
  const [options, setOptions] = useState('ignore');

  const handleOk = () => {
    if (options === 'continue') {
      onConfirm(options);
      setModalConfirm(false);
    }

    if (options === 'ignore') {
      onCancel();
    }

    setVisible(false);
    setOptions('ignore');
  };

  return (
    <StyledModal
      zIndex={10000}
      title={null}
      width={420}
      bodyStyle={{ paddingTop: 24 }}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[
        <Row justify='end'>
          <Col>
            <Space>
              <Button type='primary' disabled={!options} onClick={handleOk}>
                Yes
              </Button>
              <Button
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>,
      ]}
    >
      <WarnIcon />
      <div style={{ textAlign: 'center', marginTop: 4 }}>
        <Typography.Text
          style={{
            color: '#6c757d',
            fontSize: 16,
          }}
        >
          <Row justify='center' style={{ padding: '10px 0' }}>
            <Col>
              If you continue, this product will lose its IX-ONE Certified
              status. To restore IX-ONE Certified status, a recapture of your
              product will be required. Are you sure you wish to proceed and
              remove your IX-ONE certification?
            </Col>
            <Col>
              <Radio.Group
                onChange={(e) => {
                  setOptions(e.target.value);
                }}
                value={options}
              >
                <Radio value='continue'>
                  Yes, remove my IX-ONE certification
                </Radio>
                <Radio value='ignore'>No, cancel my edit(s)</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Typography.Text>
      </div>
    </StyledModal>
  );
};

export const IXONEShieldWarning = ({
  text = 'product',
  ixoneIgnore,
  children,
}) => {
  return (
    <>
      {ixoneIgnore ? (
        <Typography.Text>
          The {text} will be deleted by choosing the&nbsp;
          <Typography.Text style={{ textTransform: 'capitalize' }} strong>
            {ixoneIgnore}
          </Typography.Text>
          &nbsp;option. Are you sure you want to proceed with the deletion?
        </Typography.Text>
      ) : (
        <Typography.Text>{children}</Typography.Text>
      )}
    </>
  );
};
