import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, Button } from 'antd';
import Messages from 'i18n/messages/login';
import MyLayout from 'common/components/layout/Content';

import IntroductionImage from 'assets/login-introduction.png';

import './styles.less';

const { Text, Paragraph } = Typography;
/**
 * Login amd register introduction component
 */
function Intro(props) {
  const { usercls, toSignIn, isHaveSignUpBtn, ...rest } = props;

  return (
    <MyLayout className={'intro ' + usercls} {...rest}>
      <div className='intro__wrapper'>
        <Paragraph className='intro__context'>
          Through a suite of business enablement tools, RIVIR solves
          manufacturer challenges and meets the needs of retailers.
        </Paragraph>

        {isHaveSignUpBtn && (
          <Button
            type='danger'
            shape='round'
            htmlType='submit'
            className='intro__btn intro__btn-signup'
            onClick={toSignIn}
          >
            <FormattedMessage {...Messages.register} />
          </Button>
        )}
        <div
          className='intro__background'
          style={{ backgroundImage: `url(${IntroductionImage})` }}
        />
      </div>
    </MyLayout>
  );
}

Intro.propTypes = {
  /**
   * custom class for intro component
   */
  className: PropTypes.string,
  /**
   * back to sign in page
   */
  toSignIn: PropTypes.func.isRequired,
};
export default Intro;
