import React from 'react';
import { Button, Form, Input, Select, Row, Col, Tooltip } from 'antd';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import AddStatement from '../add-statement/AddStatement';
import classnames from 'classnames';
import { PACKAGE_LEVELS, MAPPING_TYPE } from 'static/Constants';
import { PlusOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { formatMDY } from 'utils/formatDate';
import { TextTooltip, WrapperSelect } from 'common/components';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
const { Option } = Select;

const columnFields = [
  {
    key: '0',
    field: 'statementType',
    headerName: 'Statement',
    suppressMenu: true,
    filter: false,
    width: 100,
  },
  {
    key: '1',
    field: 'compareFieldPath',
    headerName: 'Field',
    suppressMenu: true,
    filter: false,
    // width: 300,
    minWidth: 120,
  },
  {
    key: '2',
    field: 'condition',
    headerName: 'Condition',
    suppressMenu: true,
    filter: false,
    width: 110,
  },
  {
    key: '3',
    field: 'compareToValue',
    headerName: 'Condition Value',
    suppressMenu: true,
    filter: false,
    minWidth: 120,
    cellRendererFramework: (params) => {
      const isDateTime = params.data?.dataType?.toLowerCase() === 'datetime';
      const isBetweenCondition =
        params.data?.condition?.toLowerCase() === 'between';

      if (isDateTime) {
        if (isBetweenCondition)
          return `${formatMDY(params?.data?.compareToValue)} - ${formatMDY(
            params?.data?.compareToOtherValue
          )}`;

        return formatMDY(params?.data?.compareToValue);
      }

      if (isBetweenCondition)
        return `${params.data?.compareToValue ?? 'N/A'} - ${
          params.data?.compareToOtherValue ?? 'N/A'
        }`;

      return params.data?.compareToValue ?? 'N/A';
    },
  },
  {
    key: '4',
    field: 'setParameters',
    headerName: 'Value',
    suppressMenu: true,
    filter: false,
    width: 200,
    maxWidth: 660,

    cellRendererFramework: (params) => {
      const { setParameters, setType } = params?.data;

      if (setParameters?.length >= 1) {
        const valueText = setParameters?.map((value) => {
          if (value?.setByFieldPath && value?.setByFieldPath !== '') {
            return `<${value?.setByFieldPackageLevel}><${value?.setByFieldPath}>`;
          } else {
            return value?.setByValue;
          }
        });

        const displayValue =
          setType === 'StringConcate'
            ? valueText.join(' ')
            : valueText.join(` ${setType} `);

        return (
          <TextTooltip
            text={displayValue}
            tooltipStyle={{ maxWidth: '550px' }}
          />
        );
      } else {
        return null;
      }
    },
  },
];

function MappingType({
  mapType,
  onRowSelected,
  rowSelected,
  rowData,
  handleClickAddStatement,
  setVisibleAddStatement,
  handleDelete,
  handleEdit,
  visibleAddStatement,
  submitAddStatement,
  dataSelectedStatement,
  modeStatement,
  onGridReady,
  autoSizeColumns,
}) {
  switch (mapType) {
    case MAPPING_TYPE[0].value: {
      return (
        <Form.Item name='packageLevel' label='Package Level'>
          <WrapperSelect placeholder='Select a package level' allowClear>
            {PACKAGE_LEVELS.map((packageLevel, index) => (
              <Option value={packageLevel} key={index}>
                {packageLevel}
              </Option>
            ))}
          </WrapperSelect>
        </Form.Item>
      );
    }

    case MAPPING_TYPE[1].value: {
      return (
        <Form.Item
          label='Value'
          name='staticValue'
          rules={[
            { required: true, message: 'Please enter your static value!' },
          ]}
        >
          <Input />
        </Form.Item>
      );
    }

    // * Expression
    case MAPPING_TYPE[2].value: {
      return (
        <Form.Item name='mappingPropertyExpressions' label='Expression'>
          <Row>
            <Col span={24}>
              <div
                style={{
                  // width: '100%',
                  height: '30vh',
                }}
                className={classnames(
                  'ag-theme-alpine',
                  'ag-theme-alpine--with-scroller'
                )}
              >
                <AgGridReact
                  rowSelection='single'
                  onRowSelected={onRowSelected}
                  rowData={rowData}
                  onGridReady={onGridReady}
                  onFirstDataRendered={autoSizeColumns}
                  headerHeight={32}
                  rowHeight={32}
                >
                  {columnFields?.map((columnProps) => {
                    return <AgGridColumn {...columnProps} />;
                  })}
                </AgGridReact>
              </div>
            </Col>
            <Col span={24}>
              <Row style={{ justifyContent: 'flex-end' }}>
                <Tooltip title='Add'>
                  <Button
                    type='text'
                    icon={<PlusOutlined />}
                    onClick={handleClickAddStatement}
                  />
                </Tooltip>
                <Tooltip title='Edit'>
                  <Button
                    type='text'
                    icon={<FormOutlined />}
                    onClick={handleEdit}
                    disabled={rowSelected === null}
                  />
                </Tooltip>
                <Tooltip title='Delete'>
                  <Button
                    type='text'
                    icon={<DeleteOutlined />}
                    onClick={handleDelete}
                    disabled={rowSelected === null}
                  />
                </Tooltip>
              </Row>
            </Col>
          </Row>
          <AddStatement
            rowData={rowData}
            visible={visibleAddStatement}
            setVisibleAddStatement={setVisibleAddStatement}
            submitAddStatement={submitAddStatement}
            mapType={mapType}
            dataSelectedStatement={dataSelectedStatement}
            modeStatement={modeStatement}
            rowSelected={rowSelected}
          />
        </Form.Item>
      );
    }

    default:
      return null;
  }
}

export default MappingType;
