import React, { useState, useEffect, useRef } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Space, Row, Col, Button } from 'antd';

import { EVENT } from 'static/Constants';

import { isArray } from 'lodash';

import './CustomTransfer.less';

const CustomTransfer = (props) => {
  const addedKeyRef = useRef([]);
  const { children, onChange, addRightKey, ...rest } = props;

  const addKeysHook = useState([]);
  const leftSelectKeysHook = useState([]);
  const rightSelectKeysHook = useState([]);

  const [addedKeys, setAddedKeys] = addKeysHook;
  const [leftSelectedKeys, setLeftSelectedKeys] = leftSelectKeysHook;
  const [rightSelectedKeys, setRightSelectedKeys] = rightSelectKeysHook;

  const onClickMoveToRight = () => {
    const newAddedKeys = [...addedKeys, ...leftSelectedKeys];
    setAddedKeys(newAddedKeys);
    addedKeyRef.current = newAddedKeys;
    setLeftSelectedKeys([]);
    // setRightSelectedKeys([]);
  };

  const onClickMoveToLeft = () => {
    setAddedKeys((prevState) => {
      const newState = prevState.filter(
        (key) => !rightSelectedKeys.includes(key)
      );
      dispatchCallbackEvent('toLeft', newState);

      setAddedKeys(newState);
      return newState;
    });
    setRightSelectedKeys([]);
  };

  const dispatchCallbackEvent = (direction, data) => {
    const eventName = new CustomEvent(
      EVENT.EXPORT_PRODUCT_PROPERTIES_TRANSFER[direction],
      { detail: { addedKeys, rightSelectedKeys } }
    );

    document.dispatchEvent(eventName);
  };

  useEffect(() => {
    onChange && onChange(addedKeys);
  }, [addedKeys]);

  useEffect(() => {
    if (isArray(addRightKey)) {
      setAddedKeys(addRightKey);
    }
  }, [addRightKey]);

  return (
    <Row className='custom-transfer scroller--y' gutter={[8, 0]}>
      <Col className='custom-transfer__col' flex={1}>
        {children({
          ...rest,
          addKeysHook,
          selectKeysHook: leftSelectKeysHook,
          direction: 'left',
        })}
      </Col>
      <Col flex='none' className='custom-transfer__action-col'>
        <Space
          direction='vertical'
          className='custom-transfer__action-container'
        >
          <Button
            type='primary'
            icon={<RightOutlined />}
            onClick={onClickMoveToRight}
            disabled={!leftSelectedKeys.length}
          />
          <Button
            type='primary'
            icon={<LeftOutlined />}
            onClick={onClickMoveToLeft}
            disabled={!rightSelectedKeys.length}
          />
        </Space>
      </Col>
      <Col className='custom-transfer__col' flex={1}>
        {children({
          ...rest,
          addKeysHook,
          addedKeyRef,
          selectKeysHook: rightSelectKeysHook,
          direction: 'right',
        })}
      </Col>
    </Row>
  );
};

export default CustomTransfer;
