import { useEffect, useState } from 'react';
import { Space, Typography } from 'antd';
import { ShowMoreOrLess } from 'common/components/button';

import { useQueryGdsnPartyInfo } from './queries';

const { Text } = Typography;

const MAX_SHOW_LESS_GDSN_LIST = 6;

const MemberPanelGdsnList = (props) => {
  const { memberId, textColor, isEdit } = props;
  const { data: gdsnPartyInfo } = useQueryGdsnPartyInfo({
    memberId,
    enabled: !!memberId,
  });

  const [glnList, setGlnList] = useState([]);

  const { listGlnInfo } = gdsnPartyInfo || {};

  useEffect(() => {
    if (listGlnInfo) {
      setGlnList(listGlnInfo.slice(0, MAX_SHOW_LESS_GDSN_LIST));
    }
  }, [listGlnInfo, isEdit]);

  return (
    <Space direction='vertical' size={0}>
      {glnList
        ? glnList.map((glnItem) => {
            return (
              <Text style={{ color: textColor }}>
                {glnItem?.gln}
                {glnItem.partyRole && ` - ${glnItem.partyRole}`}
              </Text>
            );
          })
        : null}
      <ShowMoreOrLess
        fullList={listGlnInfo}
        showList={glnList}
        style={{
          text: {
            color: textColor,
          },
          wrapper: {
            pointerEvents: isEdit ? 'none' : undefined,
          },
          button: {
            border: 'none',
            color: textColor,
            padding: 0,
            width: 12,
            height: 12,
          },
        }}
        setShowList={setGlnList}
        maxShowLess={MAX_SHOW_LESS_GDSN_LIST}
      />
    </Space>
  );
};

export default MemberPanelGdsnList;
