import { defineMessages } from 'react-intl';

export const scope = 'Taco.ticketSystem';

export default defineMessages({
  createTicketTitle: {
    id: `${scope}.ribbon.createTicket.title`,
    defaultMessage: 'Create Ticket',
  },
  ticketTitle: {
    id: `${scope}.ticket.title`,
    defaultMessage: 'Title',
  },
  statusLabel: {
    id: `${scope}.label.status`,
    defaultMessage: 'Status',
  },
  severityLabel: {
    id: `${scope}.label.severity`,
    defaultMessage: 'Severity',
  },
  priorityLabel: {
    id: `${scope}.label.priority`,
    defaultMessage: 'Priority',
  },
  categoryLabel: {
    id: `${scope}.label.category`,
    defaultMessage: 'Category',
  },
  requireFieldMessage: {
    id: `${scope}.message.requiredField`,
    defaultMessage: 'This field is required',
  },
  modifiedBy: {
    id: `${scope}.fullView.modifyBy`,
    defaultMessage: 'Modified By',
  },
  modifiedAt: {
    id: `${scope}.fullView.modifyAt`,
    defaultMessage: 'Modified At',
  },
  modified: {
    id: `${scope}.fullView.modified`,
    defaultMessage: 'Modified',
  },
  detailTab: {
    id: `${scope}.fullView.detailTab`,
    defaultMessage: 'Details',
  },
  contactTab: {
    id: `${scope}.fullView.contactTab`,
    defaultMessage: 'Contacts',
  },
  attachmentTab: {
    id: `${scope}.fullView.attachmentTab`,
    defaultMessage: 'Attachments',
  },
  changelogTab: {
    id: `${scope}.fullView.changelogTab`,
    defaultMessage: 'Changelogs',
  },
  changelogHistory: {
    id: `${scope}.fullView.changelogHistory`,
    defaultMessage: 'History',
  },
  customerContact: {
    id: `${scope}.fullView.contact.customer`,
    defaultMessage: 'Customer Contact Information',
  },
  supplierContact: {
    id: `${scope}.fullView.contact.supplier`,
    defaultMessage: 'Supplier Contact Information',
  },
  customerFirstNameLabel: {
    id: `${scope}.fullView.contact.customer.label.firstName`,
    defaultMessage: 'First Name',
  },
  customerLastNameLabel: {
    id: `${scope}.fullView.contact.customer.label.lastName`,
    defaultMessage: 'Last Name',
  },
  customerEmailLabel: {
    id: `${scope}.fullView.contact.customer.label.email`,
    defaultMessage: 'Email',
  },
  customerAddressLabel: {
    id: `${scope}.fullView.contact.customer.label.address`,
    defaultMessage: 'Address',
  },
  customerCityLabel: {
    id: `${scope}.fullView.contact.customer.label.city`,
    defaultMessage: 'City',
  },
  customerStateLabel: {
    id: `${scope}.fullView.contact.customer.label.state`,
    defaultMessage: 'State',
  },
  customerZipCodeLabel: {
    id: `${scope}.fullView.contact.customer.label.zipCode`,
    defaultMessage: 'Zip Code',
  },
  supplierContactLabel: {
    id: `${scope}.fullView.contact.supplier.label.contact`,
    defaultMessage: 'Supplier Contact',
  },
  supplierNameLabel: {
    id: `${scope}.fullView.contact.supplier.label.name`,
    defaultMessage: 'Supplier Name',
  },
  supplierResponseLabel: {
    id: `${scope}.fullView.contact.supplier.label.response`,
    defaultMessage: 'Supplier Response',
  },
  addAttachmentButton: {
    id: `${scope}.fullView.attachment.button.addAttachment`,
    defaultMessage: 'Add Attachment',
  },
  thumbViewTooltip: {
    id: `${scope}.fullView.attachment.button.tooltip.thumbView`,
    defaultMessage: 'Thumbnail View',
  },
  listViewTooltip: {
    id: `${scope}.fullView.attachment.button.tooltip.listView`,
    defaultMessage: 'List View',
  },
  saveButton: {
    id: `${scope}.fullView.button.save`,
    defaultMessage: 'Save',
  },
  editButton: {
    id: `${scope}.fullView.button.edit`,
    defaultMessage: 'Edit',
  },
  deleteButton: {
    id: `${scope}.fullView.button.delete`,
    defaultMessage: 'Delete',
  },
  cancelButton: {
    id: `${scope}.fullView.button.cancel`,
    defaultMessage: 'Cancel',
  },
  newTicket: {
    id: `${scope}.fullView.label.newTicket`,
    defaultMessage: 'New Ticket',
  },
  assignment: {
    group: {
      id: `${scope}.fullView.label.assignment.group`,
      defaultMessage: 'GROUP',
    },
    user: {
      id: `${scope}.fullView.label.assignment.user`,
      defaultMessage: 'USER',
    },
  },
  assignmentPlaceholder: {
    id: `${scope}.fullView.assignment.placeholder`,
    defaultMessage: 'Search group/user to assign',
  },
  ticketDetail: {
    ticketNumber: {
      id: `${scope}.fullView.detail.ticketNumber`,
      defaultMessage: 'Ticket Number',
    },
    createdDate: {
      id: `${scope}.fullView.detail.createdDate`,
      defaultMessage: 'Created Date',
    },
    closedDate: {
      id: `${scope}.fullView.detail.closedDate`,
      defaultMessage: 'Closed Date',
    },
    createdBy: {
      id: `${scope}.fullView.detail.createdBy`,
      defaultMessage: 'Created By',
    },
    dayOpen: {
      id: `${scope}.fullView.detail.dayOpen`,
      defaultMessage: 'Days Open',
    },
    ccEmail: {
      id: `${scope}.fullView.detail.ccEmail`,
      defaultMessage: 'CC Email',
    },
    emailRule: {
      id: `${scope}.fullView.detail.emailRule.message`,
      defaultMessage: 'Invalid email',
    },
    emailExistedRule: {
      id: `${scope}.fullView.detail.emailExistedRule.message`,
      defaultMessage: 'This email has already existed',
    },
  },
  saveTicketSuccess: {
    id: `${scope}.fullView.save.message.success`,
    defaultMessage: 'Save Ticket Successfully.',
  },
  saveTicketError: {
    id: `${scope}.fullView.save.message.error`,
    defaultMessage: 'Save Ticket Fail.',
  },
  deleteTicketSuccess: {
    id: `${scope}.fullView.delete.message.success`,
    defaultMessage: 'Delete Ticket Successfully.',
  },
  deleteTicketError: {
    id: `${scope}.fullView.delete.message.error`,
    defaultMessage: 'Delete Ticket Fail.',
  },
  imageGalleryButton: {
    id: `${scope}.fullView.button.imageGallery`,
    defaultMessage: 'Image Gallery',
  },
  fetchTicketError: {
    id: `${scope}.fullView.message.fetchTicketError`,
    defaultMessage: 'Ticket not found.',
  },
  followTicketSuccess: {
    id: `${scope}.fullView.message.follow.success`,
    defaultMessage: 'Follow Ticket Successfully.',
  },
  followTicketError: {
    id: `${scope}.fullView.message.follow.error`,
    defaultMessage: 'Follow Ticket Fail.',
  },
  unfollowTicketSuccess: {
    id: `${scope}.fullView.message.unfollow.success`,
    defaultMessage: 'Unfollow Ticket Successfully.',
  },
  unfollowTicketError: {
    id: `${scope}.fullView.message.unfollow.error`,
    defaultMessage: 'Unfollow Ticket Fail.',
  },
  changelogCollapseTitle: {
    0: {
      id: `${scope}.fullView.changelog.collapseTitle.today`,
      defaultMessage: 'Today',
    },
    1: {
      id: `${scope}.fullView.changelog.collapseTitle.yesterday`,
      defaultMessage: 'Yesterday',
    },
    7: {
      id: `${scope}.fullView.changelog.collapseTitle.7days`,
      defaultMessage: 'Last 7 days',
    },
    30: {
      id: `${scope}.fullView.changelog.collapseTitle.lastMonth`,
      defaultMessage: 'Last month',
    },
    older: {
      id: `${scope}.fullView.changelog.collapseTitle.older`,
      defaultMessage: 'Older',
    },
  },
  changelogTitle: {
    ticketHistoryAssets: {
      id: `${scope}.fullView.changelog.changelogTitle.assets`,
      defaultMessage: 'Assets',
    },
    ticketHistoryProduct: {
      id: `${scope}.fullView.changelog.changelogTitle.products`,
      defaultMessage: 'Products',
    },
    ticketHistoryAttachments: {
      id: `${scope}.fullView.changelog.changelogTitle.attachments`,
      defaultMessage: 'Attachments',
    },
    ticketHistoryDescription: {
      id: `${scope}.fullView.changelog.changelogTitle.description`,
      defaultMessage: 'Description',
    },
    ticketHistoryDetails: {
      id: `${scope}.fullView.changelog.changelogTitle.detail`,
      defaultMessage: 'Details',
    },
    ticketHistoryContacts: {
      id: `${scope}.fullView.changelog.changelogTitle.detail`,
      defaultMessage: 'Contacts',
    },
    ticketHistoryComments: {
      id: `${scope}.fullView.changelog.changelogTitle.comment`,
      defaultMessage: 'Comments',
    },
  },
  changelogLabel: {
    Title: {
      id: `${scope}.fullView.changelog.changelogLabel.title`,
      defaultMessage: 'Title',
    },
    Status: {
      id: `${scope}.fullView.changelog.changelogLabel.status`,
      defaultMessage: 'Status',
    },
    Priority: {
      id: `${scope}.fullView.changelog.changelogLabel.priority`,
      defaultMessage: 'Priority',
    },
    Severity: {
      id: `${scope}.fullView.changelog.changelogLabel.severity`,
      defaultMessage: 'Severity',
    },
    Category: {
      id: `${scope}.fullView.changelog.changelogLabel.category`,
      defaultMessage: 'Category',
    },
    Type: {
      id: `${scope}.fullView.changelog.changelogLabel.type`,
      defaultMessage: 'Type',
    },
    Subtype: {
      id: `${scope}.fullView.changelog.changelogLabel.subtype`,
      defaultMessage: 'Subtype',
    },
    ContactMethod: {
      id: `${scope}.fullView.changelog.changelogLabel.contactMethod`,
      defaultMessage: 'Contact Method',
    },
    CustomerFirstName: {
      id: `${scope}.fullView.changelog.changelogLabel.customerFirstName`,
      defaultMessage: 'Customer First Name',
    },
    CustomerLastName: {
      id: `${scope}.fullView.changelog.changelogLabel.customerLastName`,
      defaultMessage: 'Customer Last Name',
    },
    CustomerEmailAddress: {
      id: `${scope}.fullView.changelog.changelogLabel.customerEmailAddress`,
      defaultMessage: 'Email',
    },
    CustomerStreetAddress: {
      id: `${scope}.fullView.changelog.changelogLabel.customerStreetAddress`,
      defaultMessage: 'Address',
    },
    CustomerCity: {
      id: `${scope}.fullView.changelog.changelogLabel.customerCity`,
      defaultMessage: 'City',
    },
    CustomerState: {
      id: `${scope}.fullView.changelog.changelogLabel.customerState`,
      defaultMessage: 'State',
    },
    CustomerZipCode: {
      id: `${scope}.fullView.changelog.changelogLabel.customerZipCode`,
      defaultMessage: 'Zip Code',
    },
    CustomerPhoneNumber: {
      id: `${scope}.fullView.changelog.changelogLabel.customerPhoneNumber`,
      defaultMessage: 'Phone Number',
    },
    IsSupplierContact: {
      id: `${scope}.fullView.changelog.changelogLabel.isSupplierContact`,
      defaultMessage: 'Is Supplier Contact',
    },
    SupplierContactName: {
      id: `${scope}.fullView.changelog.changelogLabel.supplierContactName`,
      defaultMessage: 'Supplier Name',
    },
    SaleContactName: {
      id: `${scope}.fullView.changelog.changelogLabel.saleContactName`,
      defaultMessage: 'Sale Contact Name',
    },
    SupplierResponse: {
      id: `${scope}.fullView.changelog.changelogLabel.supplierResponse`,
      defaultMessage: 'Supplier Response',
    },
    Tags: {
      id: `${scope}.fullView.changelog.changelogLabel.tags`,
      defaultMessage: 'Tags',
    },
    CcEmails: {
      id: `${scope}.fullView.changelog.changelogLabel.ccEmail`,
      defaultMessage: 'CC Emails',
    },
    AssignedType: {
      id: `${scope}.fullView.changelog.changelogLabel.assignmentType`,
      defaultMessage: 'Assignment Type',
    },
    AssignedTo: {
      id: `${scope}.fullView.changelog.changelogLabel.assignmentTo`,
      defaultMessage: 'Assigned To',
    },
    name: {
      id: `${scope}.fullView.changelog.changelogLabel.assetName`,
      defaultMessage: 'Name',
    },
    gtin: {
      id: `${scope}.fullView.changelog.changelogLabel.gtin`,
      defaultMessage: 'GTIN',
    },
    LotCode: {
      id: `${scope}.fullView.changelog.changelogLabel.lotCode`,
      defaultMessage: 'Lot Code',
    },
    Provided: {
      id: `${scope}.fullView.changelog.changelogLabel.provided`,
      defaultMessage: 'Coupon Provided?',
    },
    DollarValue: {
      id: `${scope}.fullView.changelog.changelogLabel.dollarValue`,
      defaultMessage: 'Dollar Value',
    },
  },
  unknownUser: {
    id: `${scope}.fullView.assignTo.unknownUser`,
    defaultMessage: 'Unknown User',
  },
  deleteTicketDetailConfirm: {
    id: `${scope}.fullView.delete.confirm`,
    defaultMessage: 'Are you sure you want to delete this ticket?',
  },
  autoAssignToFirstGroup: {
    id: `${scope}.fullView.delete.confirm`,
    defaultMessage: ' Ticket will be assigned to group',
  },
  customerPhoneNumberLabel: {
    id: `${scope}.fullView.contact.customer.label.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  saleContactNameLabel: {
    id: `${scope}.fullView.contact.customer.label.saleContactName`,
    defaultMessage: 'Sale Contact Name',
  },
});
