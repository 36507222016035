import React from 'react';

import { Row, Col } from 'antd';

import { SearchItemSelectList } from '../components';

const SelectedMemberList = (props) => {
  const { selectedItems, items, handleSelectItem, tagRender } = props;

  return (
    <Row>
      <Col xs={24}>
        <SearchItemSelectList
          selectedItems={selectedItems}
          items={items}
          handleSelectItem={handleSelectItem}
          tagRender={tagRender}
          style={{ padding: 8 }}
        />
      </Col>
    </Row>
  );
};

export default SelectedMemberList;
