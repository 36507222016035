import React from 'react';
import { Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import './AdvancedSearchTitle.less';

const AdvancedSearchTitle = (props) => {
  const { title } = props;
  return (
    <Row className='advanced-search-title'>
      <Typography.Text className='advanced-search-title__text'>
        {title}
      </Typography.Text>
    </Row>
  );
};
AdvancedSearchTitle.propTypes = {
  title: PropTypes.string,
};
export default React.memo(AdvancedSearchTitle);
