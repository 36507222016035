import React, { useState, useEffect } from 'react';

import classnames from 'classnames';

import { Button, Avatar, Typography, Image, Progress } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { mapFileTypeToPreviewFile } from '../../mapper/mapFileTypeToPreviewFile';

import './AttachFile.less';

const { Text } = Typography;

const AttachFile = (props) => {
  const { fileItem, refUpload, handleRemoveFileId } = props;

  const [previewSrc, setPreviewSrc] = useState('');

  useEffect(() => {
    if (fileItem) {
      getPreviewImage(fileItem);
    }
  }, [fileItem]);

  const getPreviewImage = async (fileItem) => {
    const src = await mapFileTypeToPreviewFile(fileItem);
    setPreviewSrc(src);
  };

  const handleRemoveFile = () => {
    handleRemoveFileId && handleRemoveFileId(fileItem?.uid);
    refUpload.current && refUpload.current.handleRemove(fileItem?.uid);
  };

  const PreviewIcon = typeof previewSrc !== 'string' ? previewSrc : null;

  const renderProgressFile = () => {
    return (
      <>
        {fileItem.status === 'loading' ? (
          <Progress percent={100} status='active' showInfo={false} />
        ) : fileItem.status === 'success' ? (
          <Progress percent={100} />
        ) : fileItem.status === 'error' ? (
          <div>
            <Progress percent={100} status='exception' />
            <Text type='danger' style={{ fontSize: 12 }}>
              Upload Failed
            </Text>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div
      className={classnames({
        'attach-file': true,
        'attach-file__fail': fileItem.status === 'error',
      })}
    >
      {!PreviewIcon ? (
        <>
          <Image src={previewSrc} style={{ width: '100%', height: '100%' }} />

          {renderProgressFile()}
        </>
      ) : (
        <>
          <div className='attach-file__icon-preview'>
            <PreviewIcon />
            <div style={{ width: '100%' }}>
              <Text className='attach-file__icon-name' ellipsis>
                {fileItem?.name}
              </Text>
            </div>
          </div>

          {renderProgressFile()}
        </>
      )}
      <Button
        className='attach-file__btn-remove'
        onClick={handleRemoveFile}
        type='primary'
      >
        <CloseCircleOutlined />
      </Button>
    </div>
  );
};

export default AttachFile;
