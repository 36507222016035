import React from 'react';

import { List, Card, Typography, Avatar } from 'antd';
import { ReconciliationOutlined, PlusOutlined } from '@ant-design/icons';
import classnames from 'classnames';

const { Title } = Typography;

const AplSendSampleRequestTemplateList = (props) => {
  const { templateList, setTemplate } = props;

  const onClickItem = (item) => () => {
    setTemplate(item);
  };

  const renderItem = (item) => {
    const Icon = item.type === 'blank' ? PlusOutlined : ReconciliationOutlined;
    return (
      <List.Item>
        <Card
          className={classnames({
            'apl-request-sample__template-list-item': true,
            'apl-request-sample__template-list-item--disabled': item.disabled,
          })}
          hoverable={!item.disabled}
          bordered={false}
          size='small'
          onClick={onClickItem(item)}
          cover={
            <Avatar
              size={100}
              icon={
                <Icon
                  style={{
                    color: '#d9d9d9',
                    fontSize: 80,
                    backgroundColor: 'transform',
                  }}
                />
              }
              shape='square'
            />
          }
        >
          <Card.Meta
            title={
              <Title style={{ textAlign: 'center' }} level={5}>
                {item.name}
              </Title>
            }
          />
        </Card>
      </List.Item>
    );
  };

  return (
    <List
      className='apl-request-sample__template-list'
      grid={{ gutter: 16, column: 5 }}
      dataSource={templateList}
      rowKey='id'
      renderItem={renderItem}
    ></List>
  );
};

export default AplSendSampleRequestTemplateList;
