import { useMemo } from 'react';

import { useIntl } from 'react-intl';

export const useFormatMessage = (Messages) => {
  const intl = useIntl();

  const transformMessage = (Message) => {
    return Object.keys(Message).reduce((accumulator, currentMessage) => {
      const value = Message[currentMessage];

      const isMessageObject =
        value.hasOwnProperty('id') && value.hasOwnProperty('defaultMessage');

      accumulator[currentMessage] = isMessageObject
        ? intl.formatMessage(value)
        : transformMessage(value); //* nested

      return accumulator;
    }, {});
  };

  const transformedMessages = useMemo(() => transformMessage(Messages), []);

  return transformedMessages;
};
