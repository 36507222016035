import React from 'react';

import { Row, Col, Typography, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import LocationIcon from 'assets/contact/location.png';
import EmailIcon from 'assets/contact/email.png';
import PhoneIcon from 'assets/contact/phone.png';

import ContactCardMessageItem from './ContactCardMessageItem';

const { Title, Paragraph } = Typography;

const ContactCardMessagesInfo = (props) => {
  const {
    fullName,
    location,
    email,
    phone,
    showLocation,
    avatar,
    isInChatThread,
  } = props;

  const renderContactLocation = () =>
    location && (
      <ContactCardMessageItem icon={LocationIcon}>
        <Paragraph ellipsis style={styles.paragraph}>
          {location}
        </Paragraph>
      </ContactCardMessageItem>
    );

  const renderContactEmail = () =>
    email && (
      <ContactCardMessageItem icon={EmailIcon}>
        <Paragraph ellipsis style={styles.paragraph}>
          {email}
        </Paragraph>
      </ContactCardMessageItem>
    );

  const renderContactPhone = () =>
    phone && (
      <ContactCardMessageItem icon={PhoneIcon}>
        <Paragraph ellipsis style={styles.paragraph}>
          {phone}
        </Paragraph>
      </ContactCardMessageItem>
    );

  return (
    <Row
      className='contact-card-messages__user-info'
      align='middle'
      gutter={isInChatThread ? 30 : 0}
    >
      <Col
        span={7}
        style={{ textAlign: 'center', paddingRight: 0, paddingLeft: '5px' }}
      >
        <Avatar
          className='contact-card-messages__user-avatar'
          icon={<UserOutlined />}
          src={avatar}
        />
      </Col>
      <Col span={17} style={{ paddingRight: 0 }}>
        <Title
          className='contact-card-messages__user-name'
          level={3}
          style={{ marginBottom: 6 }}
        >
          {fullName}
        </Title>
        {renderContactPhone()}
        {renderContactEmail()}
        {showLocation && renderContactLocation()}
      </Col>
    </Row>
  );
};

export default ContactCardMessagesInfo;

const styles = {
  paragraph: { marginBottom: 0 },
};
