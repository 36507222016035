// Action Types
export const EDIT_ASSET = 'EDIT_ASSET';
export const CANCEL_EDIT_ASSET = 'CANCEL_EDIT_ASSET';
export const SAVING_ASSET_FULL = 'SAVING_ASSET_FULL';
export const SAVE_ASSET_FULL = 'SAVE_ASSET_FULL';
export const SAVE_ASSET_FULL_SUCCESS = 'SAVE_ASSET_FULL_SUCCESS';
export const SAVE_ASSET_FULL_ERROR = 'SAVE_ASSET_FULL_ERROR';
export const FINISH_EDIT_ASSET = 'FINISH_EDIT_ASSET';
export const FINISH_EDIT_ASSET_SUCCESS = 'FINISH_EDIT_ASSET_SUCCESS';
export const FINISH_EDIT_ASSET_ERROR = 'FINISH_EDIT_ASSET_ERROR';
export const GET_ASSET_ID = 'GET_ASSET_ID';
export const CREATING_ASSET = 'CREATING_ASSET';
export const CANCEL_CREATE_ASSET = 'CANCEL_CREATE_ASSET';
export const CANCEL_SAVING_ASSET = 'CANCEL_SAVING_ASSET';
// Asset-grid
export const ENABLE_MODE_LINK_ASSETS = 'ENABLE_MODE_LINK_ASSETS';
export const DISABLE_MODE_LINK_ASSETS = 'DISABLE_MODE_LINK_ASSETS';
export const REPLACE_PREVIEW = 'REPLACE_PREVIEW';
export const TOGGLE_REPLACE_PREVIEW = 'TOGGLE_REPLACE_PREVIEW';
export const CANCEL_REPLACE_PREVIEW = 'CANCEL_REPLACE_PREVIEW';
export const GET_UNIQUE_ID = 'GET_UNIQUE_ID';
export const CLEAR_UNIQUE_ID = 'CLEAR_UNIQUE_ID';
export const GET_CAN_EDIT = 'GET_CAN_EDIT';
