import React from 'react';
import PropTypes from 'prop-types';
import { AdditionControl, DetailControl } from '../../index';
import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

const Favorite = ({ detailClick, addToFolderClick }) => {
  return (
    <ContainerButton>
      <ItemButton span={9} style={{ marginBottom: 14 }}>
        <AdditionControl addToFolderClick={addToFolderClick} />
      </ItemButton>
      <ItemButton span={15}>
        <DetailControl detailClick={detailClick} />
      </ItemButton>
    </ContainerButton>
  );
};

Favorite.propTypes = {
  detailClick: PropTypes.func,
};

export default Favorite;
