import React, { useState } from 'react';

import { InfoWithLabel, ProfileAddress, EditButton } from 'common/components';
import ProductHeaderContactInfoEditModal from './ProductHeaderContactInfoEditModal';

import classnames from 'classnames';

import * as _ from 'lodash';

import './ProductHeaderContactInfo.less';

const infoColProps = {
  labelColProps: { flex: '60px' },
  boldInfo: true,
  labelAlign: 'right',
};

const ProductHeaderContactInfo = (props) => {
  const {
    address,
    contact,
    shouldHideAddress,
    heightSize,
    isEdit,
    handleCancelEditHeader,
  } = props;
  const [edittingLocation, setEdittingLocation] = useState(false);

  const isValidAddress = checkValidAddress(address);
  const isValidContact = checkValidContact(contact);
  const isDisplayContact = isValidAddress || isValidContact;

  if (shouldHideAddress && heightSize <= 50) return null;

  return (
    <>
      {!shouldHideAddress && heightSize > 50 && isDisplayContact ? (
        <div
          className={classnames('pd-hdr-contact-info', {
            'pd-hdr-contact-info--hide':
              _.isEmpty(address) && _.isEmpty(contact),
            'contact-info-view-mode': !isEdit,
            'contact-info-edit-mode': isEdit,
          })}
        >
          <ProfileAddress address={address} style={{ color: 'white' }} />

          {contact?.map((item, index) => {
            const { info, label } = item;
            return (
              <InfoWithLabel
                key={index}
                info={info}
                label={label}
                {...infoColProps}
              />
            );
          })}
          {isEdit && (
            <EditButton
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
                color: 'white',
                borderRadius: 3,
                zIndex: 101,
              }}
              type='primary'
              title='Edit Product Company Location'
              tooltipProps={{ mouseLeaveDelay: 0 }}
              onClick={() => setEdittingLocation(true)}
            />
          )}
        </div>
      ) : null}
      <ProductHeaderContactInfoEditModal
        key={'product-header-contact-info-edit-modal-' + edittingLocation}
        visible={edittingLocation}
        onCancel={() => setEdittingLocation(false)}
        handleCancelEditHeader={handleCancelEditHeader}
      />
    </>
  );
};

const checkValidAddress = (address) => {
  if (typeof address === 'object') {
    const values = Object.values(address);

    return (
      values.length > 0 &&
      values.some((value) => value !== null && value !== undefined)
    );
  }
  return false;
};

const checkValidContact = (contact) => {
  return contact.filter((field) => field.info).length;
};

export default ProductHeaderContactInfo;
