import { getNodeTreeData } from 'common/components/folder/utils';

export const transformSelectedItemToNodeTree = (node) => {
  return getNodeTreeData({
    node,
  });
};

export const getCurrentFolderId = ({ treeData, selectedItem }) => {
  if (!selectedItem) return null;

  const node = transformSelectedItemToNodeTree(selectedItem);
  const ancestorNodes = findParentFolderOfNode(treeData, node); // list of ancestorNodes

  return ancestorNodes?.data?.id;
};

export const findParentFolderOfNode = (treeData, childNode) => {
  let parentNode;

  for (let i = 0; i < treeData?.length; i++) {
    const node = treeData[i];
    if (node?.children) {
      if (
        node?.children?.some((item) => item?.data?.id === childNode?.data?.id)
      ) {
        parentNode = node;
      } else if (findParentFolderOfNode(node.children, childNode)) {
        parentNode = findParentFolderOfNode(node.children, childNode);
      }
    }
  }
  return parentNode;
};
