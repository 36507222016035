import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Row, Button, Col, message, Spin, Input } from 'antd';

import { dialogOkFunction } from 'common/components';

import * as selectorGlobal from 'redux/global/selectors';
import * as actionsGlobal from 'redux/global/actions';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import { GRID_ID } from 'common/components/grid-view/utils';
import * as endpoints from 'services/mapping/endpoints';
import * as api from 'config/axios';

import messages from 'i18n/messages/home';
import { useIntl, FormattedMessage } from 'react-intl';
import { AgGrid } from 'common/components';

import './styles.less';

const ShareMappingModal = (props) => {
  const { visibleHook, detailCurrentItemsSelection } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [visible, setVisible] = visibleHook;
  const [showGrid, setShowGrid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataListSelection, setDataListSelection] = useState([]);
  const [dataGrid, setDataGrid] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [gridApi, setGridApi] = useState(null);

  const onCancelHandler = () => {
    setLoading(false);
    setVisible(false);
    setDataListSelection([]);
  };

  const columnsMember = useSelector(selectorGlobal.selectColumnsMember());

  const [pageNumber] = useSelector(gridViewSelectors.makeSelectPageCurrent())[
    GRID_ID.SHARE_MODAL
  ];

  const acceptedShare = () => {
    if (
      (detailCurrentItemsSelection &&
        detailCurrentItemsSelection.length === 0) ||
      (dataListSelection && dataListSelection.length === 0)
    ) {
      dialogOkFunction({
        type: 'error',
        content: (
          <Row>
            {!dataListSelection && dataListSelection.length === 0 && (
              <Col span={24}>
                <FormattedMessage {...messages.shareSelectList} />
              </Col>
            )}
            {detailCurrentItemsSelection &&
              detailCurrentItemsSelection.length === 0 && (
                <Col span={24}>
                  <FormattedMessage {...messages.shareSelectGrid} />
                </Col>
              )}
          </Row>
        ),
      });
    } else {
      setLoading(true);
      let params = {
        mappingId:
          detailCurrentItemsSelection &&
          detailCurrentItemsSelection.length === 1
            ? detailCurrentItemsSelection[0]?.id
            : 0,
        memberIds: dataListSelection,
      };

      api
        .sendPost(endpoints.ADD_MAPPING_SHARED_MEMBER, params)
        .then((response) => {
          if (response.isSuccess) {
            messageSuccess(response.message);
          } else {
            messageError(response.message);
          }
          setVisible(false);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setVisible(false);
          messageError('Fail to Shared');
        });
    }
  };

  const getDataFromGrid = (data) => {
    setDataGrid(data);
  };

  const messageError = (text) => {
    message.error(text);
  };

  const messageSuccess = (text) => {
    message.success(text);
  };

  useEffect(() => {
    if (visible) {
      dispatch(
        actionsGlobal.memberForSharingColumnInfo(
          'member-for-sharing-mapping-grid'
        )
      );
      // delay show ag grid to avoid item selection bug
      setTimeout(() => {
        setShowGrid(true);
      }, 100);
    } else {
      setShowGrid(false);
    }
  }, [dispatch, visible]);

  const requestParams = {
    search: { searchText: searchValue },
    mappingId:
      detailCurrentItemsSelection && detailCurrentItemsSelection.length === 1
        ? detailCurrentItemsSelection[0]?.id
        : 0,
  };

  const onSearchHandler = (value) => {
    setSearchValue(value);
  };

  const onSetSelectedRows = (selectedNodesDetail) => {
    const selectedIdList = selectedNodesDetail.map(
      (nodeDetail) => nodeDetail?.id
    );
    setDataListSelection(selectedIdList);
  };

  return (
    <Modal
      width={'80vw'}
      bodyStyle={{ height: '70vh' }}
      title={'Share'}
      visible={visible}
      closable={!loading}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={onCancelHandler}
      footer={[
        <Button disabled={loading} key='cancel' onClick={onCancelHandler}>
          <FormattedMessage {...messages.shareCancel} />
        </Button>,
        <Button
          disabled={
            (detailCurrentItemsSelection &&
              detailCurrentItemsSelection.length === 0) ||
            (dataListSelection && dataListSelection.length === 0)
          }
          key='shared'
          type='primary'
          onClick={acceptedShare}
          loading={loading}
        >
          <FormattedMessage {...messages.shareShared} />
        </Button>,
      ]}
    >
      <Spin spinning={loading} tip='Share...' size='large'>
        <Input.Search
          placeholder={intl.formatMessage(
            messages.shareSearchPlaceholderMember
          )}
          onSearch={onSearchHandler}
          style={{ maxWidth: 500, marginBottom: 8 }}
        />
        {showGrid && columnsMember && columnsMember.length > 0 && (
          <Row style={{ height: 'calc(70vh - 68px)' }}>
            <AgGrid
              columnDefs={columnsMember}
              urlGrid={endpoints.GET_MEMBERS_FOR_SHARING_GRID}
              urlGridDistinct={endpoints.GET_MEMBERS_FOR_SHARING_GRID_DISTINCT}
              onHandleSelectedRowCustom={onSetSelectedRows}
              getGridApi={(gridApi) => setGridApi(gridApi)}
              getDataFromGrid={getDataFromGrid}
              responseParams='data'
              requestParams={requestParams}
              gridId={GRID_ID.SHARE_MODAL}
              pageNumberPagination={pageNumber}
              rowMultiSelectWithClick={true}
            />
          </Row>
        )}
      </Spin>
    </Modal>
  );
};

export default ShareMappingModal;
