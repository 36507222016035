import React, { useMemo } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import classnames from 'classnames';

import { CUSTOM_LABELS, ENABLE_COUNTRY } from 'static/Constants';
import CountryCode from 'static/CountryCode';

import './FlagCountrySelectionFieldInput.less';

const FlagCountrySelectionFieldInput = (props) => {
  const {
    value,
    className,
    defaultCountry,
    isLanguage = false,
    ...restProps
  } = props;

  const procValue = useMemo(() => {
    if (!value) return defaultCountry || null;

    if (value && value?.length > 2) {
      const labelDefine = isLanguage ? CUSTOM_LABELS : CountryCode;
      return Object.keys(labelDefine).find(
        (labelKey) => labelDefine[labelKey] === value
      );
    }

    return value;
  }, [value, defaultCountry, isLanguage]);

  const countryConfig = isLanguage
    ? {
        countries: ENABLE_COUNTRY,
        customLabels: CUSTOM_LABELS,
      }
    : {};

  return (
    <ReactFlagsSelect
      className={classnames('flag-country-selection-field-input', className)}
      selected={procValue}
      {...countryConfig}
      {...restProps}
    />
  );
};

export default FlagCountrySelectionFieldInput;
