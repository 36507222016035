import React, { useEffect, useRef, useMemo } from 'react';

import { Row, Col, Space, Checkbox, Input } from 'antd';
import _ from 'lodash';

import {
  getAllExpandKeys,
  getTreeData,
  compareExpandKey,
} from 'utils/exportProductProperties';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

const { Search } = Input;

const ExportProductToolbar = (props) => {
  const intl = useIntl();
  const expandKeysRef = useRef([]);

  const {
    visible,
    dataSource,
    leftExpandedKeys,
    setLeftExpandedKeys,
    isShowSelection,
    setIsShowSelection,
    searchHook,
  } = props;

  const [searchText, setSearchText] = searchHook;

  const isExpandedAll = useMemo(
    () => compareExpandKey(leftExpandedKeys, expandKeysRef.current, visible),
    [leftExpandedKeys, visible]
  );

  const onSearch = (value) => {
    setSearchText(value);
  };

  const onChangeExpandAll = (e) => {
    const checked = e.target.checked;
    if (checked) setLeftExpandedKeys(expandKeysRef.current);
    else setLeftExpandedKeys([]);
  };

  const onChangeShowSelection = (e) => {
    const checked = e.target.checked;
    setIsShowSelection(checked);
  };

  const initExpandKeys = () => {
    const treeData = getTreeData(dataSource);
    const allExpandKeys = getAllExpandKeys(treeData);
    expandKeysRef.current = allExpandKeys;
  };

  useEffect(() => {
    initExpandKeys();
  }, [dataSource]);

  return (
    <Row style={{ marginBottom: 10 }}>
      <Col flex={0}>
        <Search
          allowClear
          enterButton
          onSearch={onSearch}
          style={{ width: 300 }}
          placeholder={intl.formatMessage(Messages.exportPropertiesSearch)}
        />
      </Col>

      <Col flex={1}></Col>

      <Col flex={0}>
        <Space size={16}>
          <Checkbox checked={isExpandedAll} onChange={onChangeExpandAll}>
            {intl.formatMessage(Messages.exportPropertiesExpandAll)}
          </Checkbox>
          <Checkbox checked={isShowSelection} onChange={onChangeShowSelection}>
            {intl.formatMessage(Messages.exportPropertiesShowSelection)}
          </Checkbox>
        </Space>
      </Col>
    </Row>
  );
};

export default ExportProductToolbar;
