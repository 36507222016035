import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * take ribbon asset full state from redux
 * @param {object} state
 */
const assetFull = (state) => (state && state.asset) || initialState;

/**
 * Select member edit mode
 */
const selectAssetEdit = () => createSelector(assetFull, (state) => state.edit);

/**
 * Select member edit mode
 */
const selectAssetSaving = () =>
  createSelector(assetFull, (state) => state.saving);

/**
 * Select asset id mode
 */
const selectAssetId = () => createSelector(assetFull, (state) => state.assetId);

const selectAssetCreating = () =>
  createSelector(assetFull, (state) => state.creating);

const selectAssetUpdating = () =>
  createSelector(assetFull, (state) => state.updating);

const selectModeLinkAssets = () =>
  createSelector(assetFull, ({ modeLinkAssets }) => modeLinkAssets);
/**
 * Select replace preview
 */
const selectReplacePreview = () =>
  createSelector(assetFull, (state) => state.replacePreview);

const selectAssetUniqueId = () =>
  createSelector(assetFull, (state) => state.uniqueId);

const selectCanEditAsset = () =>
  createSelector(assetFull, (state) => state.canEdit);

export default {
  selectAssetEdit,
  selectAssetSaving,
  selectAssetId,
  selectAssetCreating,
  selectAssetUpdating,
  selectModeLinkAssets,
  selectReplacePreview,
  selectAssetUniqueId,
  selectCanEditAsset,
};
