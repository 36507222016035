import produce from 'immer';
import * as types from './constants';
import { mapToCommonFetchState } from './mapper/mapToReducerState';

const initData = {
  data: null,
  loading: false,
  error: null,
};

export const initialState = {
  data: initData,
  roleList: initData,
  rolePermissionList: initData,
  availPermissionList: initData,
  roleUserColDef: initData,
  selectedRole: null,

  memberRoleList: initData,
  availMemberPermissionList: initData,
  selectedMemberRole: null,
  roleMemberColDef: initData,
  roleMemberNotInRoleColDef: initData,
};

const memberMaintenanceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_ROLE_LIST:
      case types.GET_ROLE_LIST_SUCCESS:
      case types.GET_ROLE_LIST_FAIL:
        mapToCommonFetchState(action, draft, 'roleList', true);
        break;

      case types.GET_ROLE_PERMISSION_LIST:
      case types.GET_ROLE_PERMISSION_LIST_SUCCESS:
      case types.GET_ROLE_PERMISSION_LIST_FAIL:
        mapToCommonFetchState(action, draft, 'rolePermissionList');
        break;

      case types.GET_AVAILABLE_PERMISSIONS:
      case types.GET_AVAILABLE_PERMISSIONS_SUCCESS:
      case types.GET_AVAILABLE_PERMISSIONS_FAIL:
        mapToCommonFetchState(action, draft, 'availPermissionList');
        break;

      case types.GET_COLUMNS_GRID_ROLE_USERS_SUCCESS:
      case types.GET_COLUMNS_GRID_ROLE_USERS_FAIL:
        mapToCommonFetchState(action, draft, 'roleUserColDef');
        break;

      case types.SET_SELECTED_ROLE:
        draft.selectedRole = action.payload;
        break;

      case types.CLEAR_ROLE_STATE:
        return initialState;

      case types.GET_MEMBER_ROLE_LIST_SUCCESS:
      case types.GET_MEMBER_ROLE_LIST_FAIL:
        mapToCommonFetchState(action, draft, 'memberRoleList', true);
        break;

      case types.GET_ALL_MEMBER_PERMISSION_LIST_SUCCESS:
      case types.GET_ALL_MEMBER_PERMISSION_LIST_FAIL:
        mapToCommonFetchState(action, draft, 'availMemberPermissionList', true);
        break;

      case types.SET_SELECTED_MEMBER_ROLE:
        draft.selectedMemberRole = action.payload;
        break;

      case types.GET_COLUMNS_GRID_ROLE_MEMBERS_SUCCESS:
      case types.GET_COLUMNS_GRID_ROLE_MEMBERS_FAIL:
        mapToCommonFetchState(action, draft, 'roleMemberColDef');
        break;

      case types.GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_SUCCESS:
      case types.GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_FAIL:
        mapToCommonFetchState(action, draft, 'roleMemberNotInRoleColDef');
        break;

      default:
        break;
    }
  });

export default memberMaintenanceReducer;
