import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components';

import * as dashboardSelectors from 'pages/dashboard/controllers/selectors';
import * as dashboardActions from 'pages/dashboard/controllers/actions';

import { EVENT } from 'static/Constants';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';

import './DashboardLayout.less';

const DashboardLayout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isEditingLayout = useSelector(
    dashboardSelectors.makeSelectEditingLayout()
  );

  const isLayoutChanged = useSelector(
    dashboardSelectors.makeSelectLayoutChanged()
  );

  const Icon = isEditingLayout ? UnlockOutlined : LockOutlined;

  const onClickBtn = () => {
    toggleEditingLayout();

    isLayoutChanged && showSaveConfirm();
    dispatch(dashboardActions.updateLayoutChanged(false));
  };

  const toggleEditingLayout = () => {
    dispatch(dashboardActions.toggleEditLayout(!isEditingLayout));
  };

  const showSaveConfirm = () => {
    if (isEditingLayout)
      dialogFunction({
        type: 'warn',
        content: intl.formatMessage(Messages.dashboardSaveConfirmMessage),
        okText: intl.formatMessage(Messages.dashboardLayoutOk),
        cancelText: intl.formatMessage(Messages.dashboardLayoutCancel),
        onOk: onOkConfirm,
        onCancel: onCancelConfirm,
      });
  };

  const dispatchLayoutEvent = (type) => {
    const event = new CustomEvent(EVENT.DASHBOARD_LAYOUT, {
      detail: type,
    });

    document.dispatchEvent(event);
  };

  const onOkConfirm = () => {
    dispatchLayoutEvent('ok');
  };

  const onCancelConfirm = () => {
    dispatchLayoutEvent('cancel');
  };

  return (
    <RibbonButton
      icon={<Icon />}
      label={Messages.dashboardLayout}
      toggle={isEditingLayout}
      style={{ padding: '0px 10px' }}
      onClick={onClickBtn}
    />
  );
};

export default DashboardLayout;
