import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { Input } from 'antd';

import { sleep } from 'utils/delay';

import './TextEditor.less';

export const TextEditor = forwardRef((props, ref) => {
  const { value: initValue, maxLength, column, ...restProps } = props;

  const [value, setValue] = useState(initValue);
  const [editing, setEditing] = useState(true);
  const refContainer = useRef(null);
  const refInput = useRef(null);

  const focus = async () => {
    await sleep(50);
    if (refContainer.current && refContainer.current?.focus) {
      refContainer.current.focus();
    }
    await sleep(150);
    if (refInput.current && refInput.current?.focus) {
      refInput.current.focus();
    }
  };

  useEffect(() => {
    focus();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleEnter = () => {
    setEditing(false);
  };

  // const isRenderTextarea = maxLength >= MAX_LENGTH_RENDER_TEXT_AREA;
  const isRenderTextarea = false;

  const width = column.getActualWidth() || 300;

  return (
    <div
      ref={refContainer}
      className='ag-editor__text'
      tabIndex={1}
      style={{ width }}
    >
      {isRenderTextarea ? (
        <Input.TextArea
          ref={refInput}
          value={value}
          onChange={handleChange}
          style={{ width: '100%' }}
          onEnter={handleEnter}
          onPressEnter={handleEnter}
          maxLength={maxLength}
          showCount
          rows={4}
          {...restProps}
        />
      ) : (
        <Input
          ref={refInput}
          value={value}
          onChange={handleChange}
          style={{ width: '100%' }}
          onEnter={handleEnter}
          onPressEnter={handleEnter}
          showCount
          maxLength={maxLength}
          {...restProps}
        />
      )}
    </div>
  );
});
