import { createSelector } from 'reselect';
import { initialState } from './reducers';

/**
 * take digital asset full state from redux
 * @param {object} state
 */

const selectLinkAssetsData = (state) => state?.linkAssets || initialState;

const makeSelectLinkAssets = () =>
  createSelector(
    selectLinkAssetsData,
    ({ linkAssetsState }) => linkAssetsState
  );

const makeSelectGridAssets = () =>
  createSelector(selectLinkAssetsData, ({ assetsGrid }) => assetsGrid);

export { makeSelectLinkAssets, makeSelectGridAssets };
