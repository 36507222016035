import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Button, Row, Col, Space, notification } from 'antd';
import {
  ControlOutlined,
  ShareAltOutlined,
  FolderAddOutlined,
  HeartOutlined,
  HeartFilled,
} from '@ant-design/icons';

import {
  FormEditButton,
  FormDeleteButton,
  dialogFunction,
  ModalAddToFolder,
} from 'common/components';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import GridQueryTable from './GridQueryTable';
import ShareQueryModal from './ShareQueryModal';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as brandingActions from 'redux/branding/actions';
import * as actionGlobal from 'redux/global/actions';
import * as selectorsGlobal from 'redux/global/selectors';

import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { useMutation } from 'hooks';

import * as favoriteServices from 'services/favorite';
import { deleteQuery } from 'services/query';
import { EVENT } from 'static/Constants';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';
import favoriteMessages from 'i18n/messages/favorite';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const PERMISSION_ADD_TO_FOLDER = [
  { action: ABILITY_ACTION.EDIT, subject: ABILITY_SUBJECT.FOLDER },
];

const ManageGridQuery = (props) => {
  const { defaultVisible, setIsMounted, resetDefaultVisible } = props;

  const dispatch = useDispatch();

  const showAddToFolder = useSelector(selectorsGlobal.selectShowAddToFolder());

  const [selectedQuery, setSelectedQuery] = useState(null);
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffect(() => {
    setVisible(defaultVisible);
  }, [defaultVisible]);

  useEffect(() => {
    if (visible) setSelectedQuery(null);
  }, [visible]);

  const openModalManageQueries = () => {
    if (showAddToFolder) dispatch(actionGlobal.toggleAddToFolder());
    setVisible(true);
  };
  return (
    <>
      <Modal
        title='Manage Queries'
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => {
          setVisible(false);
          resetDefaultVisible();
        }}
        width='80vw'
        height='80vh'
        footer={null}
        className='manage-grid__modal'
        destroyOnClose
      >
        {visible ? (
          <>
            <GridQueryAction
              selectedQuery={selectedQuery}
              setSelectedQuery={setSelectedQuery}
              setEditModalVisible={setEditModalVisible}
            />
            <GridQueryTable
              turnOffModal={() => setVisible(false)}
              setIsMounted={setIsMounted}
              selectedQuery={selectedQuery}
              setSelectedQuery={setSelectedQuery}
              editModalVisible={editModalVisible}
              setEditModalVisible={setEditModalVisible}
            />
          </>
        ) : null}
      </Modal>

      <ButtonSmallIcon
        icon={<ControlOutlined />}
        label={Messages.manageConfig}
        onClick={openModalManageQueries}
        className='grid-config__button'
      />
    </>
  );
};

const GridQueryAction = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const hasPermissionAddToFolder = useCheckPermissions(
    PERMISSION_ADD_TO_FOLDER
  );

  const { selectedQuery, setSelectedQuery, setEditModalVisible } = props;
  const [shareModalVisible, setShareModalVisible] = useState(false);

  const { gridQuery } = useSelector(selectorsGridView.makeSelectGridConfig());

  const [
    { status: statusDelete, error: errorDelete },
    deleteConfig,
  ] = useMutation(deleteQuery);

  const onEdit = () => {
    setEditModalVisible(true);
  };

  const onDelete = () => {
    showDeleteConfirmDialog();
  };

  const onShare = () => {
    setShareModalVisible(true);
  };

  const onAddToFolder = () => {
    dispatch(actionGlobal.toggleAddToFolder());
  };

  const onFavorite = () => {
    handleAddToFavorite();
  };

  const onUnfavorite = () => {
    handleRemoveFavorite();
  };

  const reloadQueryTable = () => {
    // gridApi.purgeServerSideCache();
    const event = new Event(EVENT.RELOAD_MANAGE_QUERY_TABLE);

    document.dispatchEvent(event);

    setSelectedQuery(null);
  };

  const handleAddToFavorite = async () => {
    try {
      // add to favorite
      const addToFavoriteResponse = await favoriteServices.addToFavorite(
        [selectedQuery?.id],
        selectedQuery?.type
      );
      const { isSuccess, message } = addToFavoriteResponse;
      if (isSuccess) {
        notification.success({
          message: intl.formatMessage(favoriteMessages.addToFavoriteSuccess),
        });
        // update navigation info (favorite total number)
        try {
          // await dispatchUpdateNavigationEvent();
          dispatch(brandingActions.getBrandingNoLoading());
        } catch (error) {
          console.error(
            'get branding info error after add item to favorite: ',
            error
          );
        }
        reloadQueryTable();
      } else {
        addToFavoriteErrorHandler(message);
      }
    } catch (error) {
      addToFavoriteErrorHandler();
    }
  };

  const addToFavoriteErrorHandler = (message) => {
    notification.error({
      message: message
        ? message
        : intl.formatMessage(favoriteMessages.addToFavoriteError),
    });
  };

  const handleRemoveFavorite = async () => {
    try {
      const removeFavorite = await favoriteServices.removeFromFavorite(
        [selectedQuery?.id],
        selectedQuery?.type
      );
      const { isSuccess, message } = removeFavorite;
      if (isSuccess) {
        notification.success({
          message: intl.formatMessage(
            favoriteMessages.removeFromFavoriteSuccess
          ),
        });
        // update navigation info (favorite total number)
        try {
          dispatch(brandingActions.getBrandingNoLoading());
        } catch (error) {
          console.error(
            'get branding info error after add item to favorite: ',
            error
          );
        }
        reloadQueryTable();
      } else {
        removeFromFavoriteErrorHandler(message);
      }
    } catch (error) {
      removeFromFavoriteErrorHandler();
    }
  };

  const removeFromFavoriteErrorHandler = (message) => {
    notification.error({
      message: message
        ? message
        : intl.formatMessage(favoriteMessages.removeFromFavoriteError),
    });
  };

  const showDeleteConfirmDialog = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.deleteQueryConfirm),
      onOk: handleDeleteGridConfig,
    });
  };

  const handleDeleteGridConfig = () => {
    deleteConfig({ listQueries: [selectedQuery.id] });
  };

  useEffect(() => {
    if (statusDelete === 'success') {
      notification.success({ message: 'Deleted successfully' });

      setTimeout(() => {
        reloadQueryTable();
      }, 500);
    }

    if (statusDelete === 'error') {
      notification.success({ message: errorDelete });
    }

    return;
  }, [errorDelete, statusDelete]);

  const ShareButton = Button;
  const AddToFolderButton = Button;
  const FavoriteButton = Button;
  const UnfavoriteButton = Button;

  const disabledShareBtn = !selectedQuery;
  const disabledAddToFolderBtn = !selectedQuery;
  const disabledFavoriteBtn = !selectedQuery || !selectedQuery?.shareable;
  const disabledUnfavoriteBtn = !selectedQuery || !selectedQuery?.shareable;
  const disabledEditBtn = !selectedQuery || !selectedQuery.editable;
  const disabledDeleteBtn = !selectedQuery || !selectedQuery.editable;

  const buttonStyle = { borderRadius: 4 };

  return (
    <Row justify='end' style={{ marginBottom: 10 }}>
      {gridQuery.length === 0 ? null : (
        <>
          <Col flex={0}>
            <Space size={4}>
              <ShareButton
                type='primary'
                icon={<ShareAltOutlined />}
                style={buttonStyle}
                onClick={onShare}
                disabled={disabledShareBtn}
              >
                {intl.formatMessage(Messages.share)}
              </ShareButton>
              
              {hasPermissionAddToFolder && (
                <AddToFolderButton
                  type='primary'
                  icon={<FolderAddOutlined />}
                  style={buttonStyle}
                  onClick={onAddToFolder}
                  disabled={disabledAddToFolderBtn}
                >
                  {intl.formatMessage(Messages.configAddToFolder)}
                </AddToFolderButton>
              )}

              {selectedQuery?.isFavorited ? (
                <UnfavoriteButton
                  type='primary'
                  danger
                  icon={<HeartFilled />}
                  style={buttonStyle}
                  onClick={onUnfavorite}
                  disabled={disabledUnfavoriteBtn}
                >
                  {intl.formatMessage(favoriteMessages.removeFromFavorite)}
                </UnfavoriteButton>
              ) : (
                <FavoriteButton
                  type='primary'
                  icon={<HeartOutlined />}
                  style={buttonStyle}
                  onClick={onFavorite}
                  disabled={disabledFavoriteBtn}
                >
                  {intl.formatMessage(favoriteMessages.addToFavorite)}
                </FavoriteButton>
              )}
            </Space>
          </Col>
          <Col flex={1}></Col>
          <Col flex={0}>
            <Space size={4}>
              <FormEditButton onClick={onEdit} disabled={disabledEditBtn} />
              <FormDeleteButton
                onClick={onDelete}
                disabled={disabledDeleteBtn}
              />
            </Space>
          </Col>
        </>
      )}
      <ShareQueryModal
        data={selectedQuery}
        visible={shareModalVisible}
        setVisible={setShareModalVisible}
      />
      <ModalAddToFolder
        dataItem={selectedQuery}
        dataType='query'
        hasPermissionAddToFolder={hasPermissionAddToFolder}
      />
    </Row>
  );
};

export default ManageGridQuery;
