import React from 'react';
import { Input, DatePicker, Spin } from 'antd';

import { Form } from 'common/components';
import { OPEN_ITEM_CLASS } from 'static/Constants';

import * as constants from 'static/Constants';

import classnames from 'classnames';

const { RangePicker } = DatePicker;

const AddDRMForm = (props) => {
  const { form, handleAddDRM, loading } = props;
  return (
    <>
      <Spin spinning={loading}>
        <Form
          form={form}
          name='add-drm-form'
          className={classnames('add-drm-form', [OPEN_ITEM_CLASS])}
          onFinish={(value) => handleAddDRM(value)}
        >
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name='digitalRightsName'
            label={'Digital Right Restriction: '}
            rules={[
              {
                required: true,
                message: 'Need input Digital Right Restriction',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name='dateEffective'
            label={'Date Effective: '}
            rules={[
              {
                required: true,
                message: 'Need input Date effective',
              },
            ]}
          >
            <RangePicker
              style={{ width: '100%' }}
              format={constants.DATE_FORMAT.SYSTEM_FORMAT}
            />
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddDRMForm;
