import React from 'react';
import reactCSS from 'reactcss';

import { Button, Dropdown, Menu } from 'antd';

import { LockOutlined, UnlockOutlined, MoreOutlined } from '@ant-design/icons';

import './ToggleLockAllFormItemInput.less';

const styles = reactCSS({
  default: {
    lock: {
      fontSize: 19,
      cursor: 'pointer',
      color: '#ff4d4f',
      border: 'none',
    },
    unlock: {
      fontSize: 19,
      cursor: 'pointer',
      color: '#389e0d',
    },
    contextIconWrap: {
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
    },
    contextIcon: {
      cursor: 'pointer',
    },
  },
});

const ToggleLockAllFormItemInput = (props) => {
  const { entityId, dataPointId, onLockAll, onUnlockAll } = props;

  const handleLockAll = () => {
    onLockAll(dataPointId, entityId);
  };

  const handleUnlockAll = () => {
    onUnlockAll(dataPointId);
  };

  return (
    <Dropdown
      overlay={contextMenu({
        handleUnlockAll,
        handleLockAll,
      })}
      style={styles.contextIconWrap}
    >
      <MoreOutlined style={styles.contextIcon} />
    </Dropdown>
  );
};

const contextMenu =
  ({ handleLockAll, handleUnlockAll }) =>
  () => {
    return (
      <div className='toggle-lock-all-item-input__context'>
        <Menu.Item
          className='toggle-lock-all-item-input__context-button'
          key='lock-all'
          onClick={handleLockAll}
        >
          <LockOutlined style={styles.lock} /> Lock All
        </Menu.Item>
        <Menu.Item
          className='toggle-lock-all-item-input__context-button'
          key='unlock-all'
          onClick={handleUnlockAll}
        >
          <UnlockOutlined style={styles.unlock} /> Unlock All
        </Menu.Item>
      </div>
    );
  };

export default ToggleLockAllFormItemInput;
