import * as types from './constants';

export const setWorkflowTemplateView = (
  type,
  versionId,
  step,
  taskId,
  progression
) => ({
  type: types.SET_WORKFLOW_TEMPLATE_VIEW,
  templateView: {
    type: type,
    versionId: versionId,
    step,
    taskId: taskId,
    progression: progression,
  },
});

export const getWfTemplateDetail = (WorkflowTemplateId) => ({
  type: types.GET_WORKFLOW_TEMPLATE_DETAIL,
  WorkflowTemplateId,
});
export const getWfTemplateDetailSuccess = (detailWfTemplate) => ({
  type: types.GET_WORKFLOW_TEMPLATE_DETAIL_SUCCESS,
  detailWfTemplate,
});
export const getWfTemplateDetailError = (error) => ({
  type: types.GET_WORKFLOW_TEMPLATE_DETAIL_ERROR,
  error,
});
export const getWfStepInfo = (StepId) => ({
  type: types.GET_WORKFLOW_STEP_INFO,
  StepId,
});
export const getWfStepInfoSuccess = (stepInfo) => ({
  type: types.GET_WORKFLOW_STEP_INFO_SUCCESS,
  stepInfo,
});
export const getWfStepInfoError = (error) => ({
  type: types.GET_WORKFLOW_STEP_INFO_ERROR,
  error,
});
export const toggleAddNewStep = (status) => ({
  type: types.TOGGLE_NEW_STEP,
  status,
});

export const toggleAddNewTask = (status, stepId) => ({
  type: types.TOGGLE_NEW_TASK,
  status,
  stepId,
});

export const createWorkflowStep = (params) => ({
  type: types.CREATE_WORKFLOW_STEP,
  params,
});
export const createWorkflowStepSuccess = () => ({
  type: types.CREATE_WORKFLOW_STEP_SUCCESS,
});
export const createWorkflowStepError = (error) => ({
  type: types.CREATE_WORKFLOW_STEP_ERROR,
  error,
});

export const editWorkflowStep = (params) => ({
  type: types.EDIT_WORKFLOW_STEP,
  params,
});
export const editWorkflowStepSuccess = (stepInfo) => ({
  type: types.EDIT_WORKFLOW_STEP_SUCCESS,
  stepInfo,
});
export const editWorkflowStepError = (error) => ({
  type: types.EDIT_WORKFLOW_STEP_ERROR,
  error,
});

export const getWorkflowTemplateDetailInfo = (WorkflowTemplateId) => ({
  type: types.GET_WORKFLOW_TEMPLATE_DETAIL_INFO,
  WorkflowTemplateId,
});
export const getWorkflowTemplateDetailInfoSuccess = (
  workflowTemplateDetail
) => ({
  type: types.GET_WORKFLOW_TEMPLATE_DETAIL_INFO_SUCCESS,
  workflowTemplateDetail,
});
export const getWorkflowTemplateDetailInfoError = (error) => ({
  type: types.GET_WORKFLOW_TEMPLATE_DETAIL_INFO_ERROR,
  error,
});

export const setTree = (trees) => ({
  type: types.SET_TREE,
  trees,
});

export const setActiveStepKey = (id) => ({
  type: types.SET_ACTIVE_STEP_KEY,
  id,
});
export const getWfTemplateVersions = (mainWorkflowId) => ({
  type: types.GET_WORKFLOW_TEMPLATE_VERSIONS,
  mainWorkflowId,
});
export const getWfTemplateVersionsSuccess = (versions) => ({
  type: types.GET_WORKFLOW_TEMPLATE_VERSIONS_SUCCESS,
  versions,
});
export const getWfTemplateVersionsError = (error) => ({
  type: types.GET_WORKFLOW_TEMPLATE_VERSIONS_ERROR,
  error,
});

export const setWorkflowTemplateDetail = (workflowTemplateDetail) => ({
  type: types.SET_WORKFLOW_TEMPLATE_DETAIL,
  workflowTemplateDetail,
});

export const editWorkflowTemplate = (params) => ({
  type: types.EDIT_WORKFLOW_TEMPLATE,
  params,
});
export const editWorkflowTemplateSuccess = (workflowTemplateDetail) => ({
  type: types.EDIT_WORKFLOW_TEMPLATE_SUCCESS,
  workflowTemplateDetail,
});
export const editWorkflowTemplateError = (error) => ({
  type: types.EDIT_WORKFLOW_TEMPLATE_ERROR,
  error,
});

export const getUserWorkflowRoles = (pageIndex, pageSize, memberId) => ({
  type: types.GET_USER_WORKFLOW_ROLES_DETAIL,
  pageIndex,
  pageSize,
  memberId,
});
export const getUserWorkflowRolesSuccess = (userWorkflowRoles) => ({
  type: types.GET_USER_WORKFLOW_ROLES_DETAIL_SUCCESS,
  userWorkflowRoles,
});
export const getUserWorkflowRolesError = (error) => ({
  type: types.GET_USER_WORKFLOW_ROLES_DETAIL_ERROR,
  error,
});

export const getWorkflowTaskInfo = (taskId) => ({
  type: types.GET_WORKFLOW_TASK_INFO,
  taskId,
});
export const getWorkflowTaskInfoSuccess = (taskInfo) => ({
  type: types.GET_WORKFLOW_TASK_INFO_SUCCESS,
  taskInfo,
});
export const getWorkflowTaskInfoError = (error) => ({
  type: types.GET_WORKFLOW_TASK_INFO_ERROR,
  error,
});

export const editWorkflowTask = (params) => ({
  type: types.EDIT_WORKFLOW_TASK,
  params,
});
export const editWorkflowTaskSuccess = (taskInfo) => ({
  type: types.EDIT_WORKFLOW_TASK_SUCCESS,
  taskInfo,
});
export const editWorkflowTaskError = (error) => ({
  type: types.EDIT_WORKFLOW_TASK_ERROR,
  error,
});

export const setWorkflowStepInfo = (stepInfo) => ({
  type: types.SET_WORKFLOW_STEP_INFO,
  stepInfo,
});

export const createWorkflowTask = (params) => ({
  type: types.CREATE_WORKFLOW_TASK,
  params,
});
export const createWorkflowTaskSuccess = () => ({
  type: types.CREATE_WORKFLOW_TASK_SUCCESS,
});
export const createWorkflowTaskError = (error) => ({
  type: types.CREATE_WORKFLOW_TASK_ERROR,
  error,
});
export const getWorkflowMetric = (id) => ({
  type: types.GET_WORKFLOW_METRIC,
  id,
});
export const getWorkflowMetricSuccess = (workflowMetric) => ({
  type: types.GET_WORKFLOW_METRIC_SUCCESS,
  workflowMetric,
});
export const getWorkflowMetricError = (error) => ({
  type: types.GET_WORKFLOW_METRIC_ERROR,
  error,
});
export const setStatusWorkflowClick = (statusWorkflow) => ({
  type: types.SET_STATUS_WORKFLOW_CLICK,
  statusWorkflow,
});

export const getRequiredTask = (workflowId, workflowStepId) => ({
  type: types.GET_REQUIRED_TASK,
  workflowId,
  workflowStepId,
});
export const getRequiredTaskSuccess = (requiredTask) => ({
  type: types.GET_REQUIRED_TASK_SUCCESS,
  requiredTask,
});
export const getRequiredTaskError = (error) => ({
  type: types.GET_REQUIRED_TASK_ERROR,
  error,
});
export const getWorkflowStepMetric = (workflowStepId) => ({
  type: types.GET_WORKFLOW_STEP_METRIC,
  workflowStepId,
});
export const getWorkflowStepMetricSuccess = (workflowStepMetric) => ({
  type: types.GET_WORKFLOW_STEP_METRIC_SUCCESS,
  workflowStepMetric,
});
export const getWorkflowStepMetricError = (error) => ({
  type: types.GET_WORKFLOW_STEP_METRIC_ERROR,
  error,
});
export const updateMessageActions = (msg) => ({
  type: types.UPDATE_MESSAGE_ACTIONS,
  msg,
});

export const triggerCloneStep = () => ({
  type: types.TRIGGER_CLONE_STEP,
});

export const triggerCloneTask = () => ({
  type: types.TRIGGER_CLONE_TASK,
});

export const hideCloneStep = () => ({
  type: types.HIDE_CLONE_STEP,
});

export const hideCloneTask = () => ({
  type: types.HIDE_CLONE_TASK,
});
