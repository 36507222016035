import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { ExpandOutlined } from '@ant-design/icons';

import * as maintenanceFormActions from 'pages/maintenance-form/controllers/actions';
import * as maintenanceFormSelectors from 'pages/maintenance-form/controllers/selectors';

import useFullScreen from 'hooks/useFullScreen';

const ToggleFullScreenFormBtn = (props) => {
  const { formId, ...restProps } = props;

  const dispatch = useDispatch();

  const [fullScreenInstance] = useFullScreen();

  const isFormDetailFullScreen = useSelector(
    maintenanceFormSelectors.makeSelectIsFormDetailFullScreen()
  );

  const onClickToggleFull = () => {
    dispatch(maintenanceFormActions.toggleFormDetailFullScreen());
  };

  useEffect(() => {
    //* toggle full screen
    const toggleFullScreen = () => {
      if (isFormDetailFullScreen) {
        fullScreenInstance.current.goFullScreen();
      } else {
        fullScreenInstance.current.exitFullScreen();
      }
    };

    toggleFullScreen();
  }, [isFormDetailFullScreen]);

  return (
    <RibbonButton
      icon={<ExpandOutlined />}
      label={isFormDetailFullScreen ? 'Exit Full Screen' : 'Open Full Screen'}
      onClick={onClickToggleFull}
      style={{ width: 106 }}
      {...restProps}
    />
  );
};

export default ToggleFullScreenFormBtn;
