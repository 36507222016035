import * as types from './constants';

export function getEmailThreadList(params) {
  return {
    type: types.GET_EMAIL_THREAD_LIST,
    params,
  };
}

export function getEmailThreadListSuccess(payload) {
  return {
    type: types.GET_EMAIL_THREAD_LIST_SUCCESS,
    payload,
  };
}

export function getEmailThreadListFail(payload) {
  return {
    type: types.GET_EMAIL_THREAD_LIST_FAIL,
    payload,
  };
}

export function getEmailThreadListMore(payload) {
  return {
    type: types.GET_EMAIL_THREAD_LIST_MORE,
    payload,
  };
}

export function getEmailThreadListMoreSuccess(payload) {
  return {
    type: types.GET_EMAIL_THREAD_LIST_MORE_SUCCESS,
    payload,
  };
}

export function getEmailThreadListMoreFail(payload) {
  return {
    type: types.GET_EMAIL_THREAD_LIST_MORE_FAIL,
    payload,
  };
}

export function getEmailThreadInfo(payload) {
  return {
    type: types.GET_EMAIL_THREAD_INFO,
    payload,
  };
}

export function getEmailThreadInfoSuccess(payload) {
  return {
    type: types.GET_EMAIL_THREAD_INFO_SUCCESS,
    payload,
  };
}

export function getEmailThreadInfoFail(payload) {
  return {
    type: types.GET_EMAIL_THREAD_INFO_FAIL,
    payload,
  };
}

export function getEmailThreadInfoContent(threadId, timestamp, direction = 0) {
  return {
    type: types.GET_EMAIL_THREAD_INFO_CONTENT,
    threadId,
    timestamp,
    direction,
  };
}

export function getEmailThreadInfoContentSuccess(payload) {
  return {
    type: types.GET_EMAIL_THREAD_INFO_CONTENT_SUCCESS,
    payload,
  };
}

export function getEmailThreadInfoContentFail(payload) {
  return {
    type: types.GET_EMAIL_THREAD_INFO_CONTENT_FAIL,
    payload,
  };
}

export function getEmailThreadInfoFiles(params) {
  return { type: types.GET_EMAIL_THREAD_INFO_FILES, params };
}

export function getEmailThreadInfoFilesSuccess(payload) {
  return { type: types.GET_EMAIL_THREAD_INFO_FILES_SUCCESS, payload };
}

export function getEmailThreadInfoFilesFail(payload) {
  return { type: types.GET_EMAIL_THREAD_INFO_FILES_FAIL, payload };
}

export function updateEmailThreadList(payload) {
  return {
    type: types.UPDATE_EMAIL_THREAD_LIST,
    payload,
  };
}

export function setNewEmailReceived(emailInfo) {
  return {
    type: types.SET_NEW_EMAIL_MESSAGE,
    emailInfo,
  };
}

export function sendEmailMessage(payload) {
  return {
    type: types.SEND_EMAIL,
    payload,
  };
}

export function sendEmailMessageSuccess(payload) {
  return {
    type: types.SEND_EMAIL_SUCCESS,
    payload,
  };
}

export function sendEmailMessageFail(payload) {
  return {
    type: types.SEND_EMAIL_FAIL,
    payload,
  };
}

export function saveActiveEmailThreadInfo(threadInfo) {
  return {
    type: types.ACTIVE_EMAIL_THREAD_INFO,
    threadInfo,
  };
}

export function saveAccountMail(payload) {
  return {
    type: types.SAVE_ACCOUNT_MAIL,
    payload,
  };
}

export function toggleNewMailMessage(value) {
  return {
    type: types.TOGGLE_NEW_MAIL_MESSAGE,
    value,
  };
}
export function selectSearchResult(item) {
  return {
    type: types.EMAIL_SELECT_SEARCH_RESULT,
    payload: item,
  };
}

export function updateFinishLoadDown(payload) {
  return {
    type: types.EMAIL_UPDATE_FINISH_LOAD_DOWN,
    payload,
  };
}

export function updateSelectedSearchResultPosition(payload) {
  return {
    type: types.EMAIL_UPDATE_SEARCH_RESULT_POSITION,
    payload,
  };
}

export function updateLastMessage(payload) {
  return {
    type: types.EMAIL_UPDATE_LAST_MESSAGE,
    payload,
  };
}
