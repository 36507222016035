import produce from 'immer';
import * as types from './constants';

export const initialState = {
  gridLoading: false,
  gridColumns: [],
  eulaList: [],

  eulaForm: {
    id: null,
    statusSubmit: 'idle',
    statusClone: 'idle',
    error: null,

    isEdit: false,
    isCreate: false,
    isClone: false,
  },
  pageSize: 20,
  pageNumber: 1,
  gridTotal: 0,
  detailData: {},
  detailError: null,
  isUploadEulaMediaOpen: false,
  acceptedEulaLoading: false,
  acceptedEulaColumns: [],
  acceptedUserEula: [],
  acceptedEulaPageSize: 20,
  acceptedEulaPageNumber: 1,
  acceptedEulaTotal: 0,
  acceptedEulaError: false,
  acceptedSearch: '',
  reloadAcceptedEulaPage: false,
};

/* eslint-disable default-case, no-param-reassign */
const helpMaintenanceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_EULA_MAINTENANCE_GRID_COLUMNS:
        draft.gridLoading = true;
        break;
      case types.GET_EULA_MAINTENANCE_GRID_COLUMNS_SUCCESS:
        draft.gridLoading = false;
        draft.gridColumns = action.payload;
        break;
      case types.GET_EULA_MAINTENANCE_GRID_COLUMNS_FAILURE:
        draft.gridLoading = false;
        break;

      case types.CREATE_EULA:
        draft['eulaForm'].statusSubmit = 'loading';
        draft['eulaForm'].statusClone = 'idle';
        draft['eulaForm'].isClone = false;
        draft['eulaForm'].isCreate = false;
        break;
      case types.CREATE_EULA_SUCCESS:
        draft['eulaForm'].statusSubmit = 'success';
        draft['eulaForm'].id = action.id;
        break;
      case types.CREATE_EULA_ERROR:
        draft['eulaForm'].statusSubmit = 'error';
        draft['eulaForm'].error = action.error;
        break;

      case types.CLONE_EULA:
        draft['eulaForm'].statusClone = 'loading';
        break;
      case types.CLONE_EULA_SUCCESS:
        draft['eulaForm'].statusClone = 'success';
        break;
      case types.CLONE_EULA_ERROR:
        draft['eulaForm'].statusClone = 'error';
        break;

      case types.RESET_SAVE_EULA:
        draft['eulaForm'].statusSubmit = 'idle';
        draft['eulaForm'].statusClone = 'idle';
        draft['eulaForm'].isClone = false;
        draft['eulaForm'].isCreate = false;
        break;

      case types.ENABLE_EULA_MAINTENANCE_EDIT:
        draft['eulaForm'].isCreate = false;
        draft['eulaForm'].isEdit = true;
        break;

      case types.DISABLE_EULA_MAINTENANCE_EDIT:
        draft['eulaForm'].isEdit = false;
        break;

      case types.ENABLE_EULA_MAINTENANCE_CREATE:
        draft['eulaForm'].isCreate = true;
        draft['eulaForm'].isEdit = false;
        break;

      case types.CONFIRM_CLONE_EULA_MAINTENANCE:
        draft['eulaForm'].isClone = true;
        break;

        
      case types.RESET_CONFIRM_CLONE_EULA_MAINTENANCE:
        draft['eulaForm'].isClone = false;
        break;


      case types.SAVE_ID_EULA:
        draft['eulaForm'].id = action.data;
        break;

      case types.GET_EULA_MAINTENANCE_LIST:
        draft.gridLoading = true;
        draft.pageSize = action.pageSize;
        draft.pageNumber = action.pageIndex;
        break;
      case types.GET_EULA_MAINTENANCE_LIST_SUCCESS:
        draft.gridLoading = false;
        draft.eulaList = action.payload;
        draft.gridTotal = action.total;
        break;
      case types.GET_EULA_MAINTENANCE_LIST_FAILURE:
        draft.gridLoading = false;
        break;
      case types.EULA_DISABLED_VIEW_MODE:
        draft.isDetailsView = action.isDetailsView;
        break;
      case types.GET_EULA_CONTENT_DETAIL:
        draft.detailLoading = true;
        draft.detailData = null;
        draft.detailError = null;
        break;
      case types.GET_EULA_CONTENT_DETAIL_SUCCESS:
        draft.detailLoading = false;
        draft.detailData = action.data;
        break;
      case types.GET_EULA_CONTENT_DETAIL_ERROR:
        draft.detailLoading = false;
        draft.detailData = null;
        draft.detailError = action.error;
        break;
      case types.TOGGLE_EULA_UPLOAD_MEDIA:
        draft.isUploadEulaMediaOpen = action.status;
        break;
      // Accepted user eula
      case types.GET_ACCEPTED_USER_EULA_GRID_COLUMNS:
        draft.acceptedEulaLoading = true;
        draft.acceptedEulaColumns = [];
        break;
      case types.GET_ACCEPTED_USER_EULA_GRID_COLUMNS_SUCCESS:
        draft.acceptedEulaLoading = false;
        draft.acceptedEulaColumns = action.columns;
        break;
      case types.GET_ACCEPTED_USER_EULA_GRID_COLUMNS_ERROR:
        draft.acceptedEulaLoading = false;
        draft.acceptedEulaColumns = [];
        draft.acceptedEulaError = action.error;
        break;
      case types.GET_ACCEPTED_USER_EULA_LIST:
        draft.acceptedEulaLoading = true;
        draft.acceptedEulaError = false;
        draft.acceptedEulaPageSize = action.pageSize;
        draft.acceptedEulaPageNumber = action.pageIndex;
        draft.acceptedSearch = action['Search.SearchText'];
        draft.reloadAcceptedEulaPage = false;

        break;
      case types.GET_ACCEPTED_USER_EULA_LIST_SUCCESS:
        draft.acceptedEulaLoading = false;
        draft.acceptedUserEula = action.acceptedUserEula;
        draft.acceptedEulaTotal = action.total;
        break;
      case types.GET_ACCEPTED_USER_EULA_LIST_ERROR:
        draft.acceptedEulaLoading = false;
        draft.acceptedEulaTotal = 0;
        draft.acceptedUserEula = [];
        draft.acceptedEulaError = action.error;
        break;
      default:
        break;
    }
  });

export default helpMaintenanceReducer;
