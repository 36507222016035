import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col, Checkbox, Typography, notification } from 'antd';

import { ProfileImage, StyledModal } from 'common/components';
import AssetGS1Edit from 'pages/asset-full-view/components/add-link-product/AssetGS1Edit';
import noImage from 'assets/product-no-image.jpg';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/product';
import * as productServices from 'services/product';

import { useGetProductFullView } from 'hooks';

import './LinkedAssetEditModal.less';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const { Text } = Typography;

const LinkedAssetEditModal = (props) => {
  const { id } = useParams();

  const {
    imageList,
    imagePreview,
    itemPreview,
    setItemPreview,
    setImagePreview,
    visible,
    setIsEditLinkedAsset,
    addType,
    getImagery,
    getMarketingAssets,
    ...rest
  } = props;

  const { productFull } = useGetProductFullView({ productId: id });
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const [params, setParams] = useState();
  const [isReplaceAsset, setIsReplaceAsset] = useState(false);

  const [editLogoLoading, setEditLogoLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const imagePreviewThumb =
    imagePreview?.length > 0
      ? imagePreview[0]?.thumb ||
        imagePreview[0]?.thumb300 ||
        imagePreview[0]?.thumb500 ||
        imagePreview[0]?.thumb1000 ||
        imagePreview[0]?.thumbFull
      : null;

  const profileImage = uploadImage
    ? uploadImage
    : imagePreviewThumb
    ? imagePreviewThumb
    : noImage;

  const indexImagePreview =
    imagePreview?.length > 0 &&
    imageList.findIndex((item) => item.id === imagePreview[0]?.id);

  useEffect(() => {
    if (imageList && imageList[indexImagePreview]) {
      setItemPreview({ ...imageList[indexImagePreview] });
      setImagePreview([
        { ...imagePreview[0], thumb: imageList[indexImagePreview]?.thumb },
      ]);
    }
  }, [imageList[indexImagePreview]?.thumb]);

  const onCancel = () => {
    // setUploadImage(false);
    setIsEditLinkedAsset(false);
  };

  const onUploadImageHandler = async (ixoneIgnore) => {
    setEditLogoLoading(true);
    const { imageSrc } = params;
    const payload = {
      file: params?.imageFile,
    };
    const additionalBodyPayload = {
      assetId: imagePreview[0]?.digitalAssetId,
    };

    const { replacePimAsset } = productServices;

    const response = await replacePimAsset(payload, additionalBodyPayload);

    const { isSuccess, message } = response?.data;

    if (isSuccess) {
      getImagery();
      notification.success({ message: 'Replace asset successfully!' });
      await handleUpdateIXONEShield(ixoneIgnore);
      setTimeout(() => {
        setUploadImage(imageSrc);
        setEditLogoLoading(false);
      }, 100);
    } else {
      notification.error({ message: message || 'Error!' });
      setEditLogoLoading(false);
    }
  };

  const onChange = (event) => {
    setIsReplaceAsset(event.target.checked);
  };

  const modalProps = {
    title: 'Edit Linked Digital Media Modal',
    visible,
    width: 600,
    bodyStyle: { paddingTop: 30, paddingBottom: 36 },
    closable: true,
    maskClosable: false,
    onCancel: onCancel,
    footer: null,
    wrapClassName: 'linked-asset-edit-modal',
    ...rest,
  };

  return (
    <StyledModal {...modalProps}>
      <Row className='linked-asset-edit'>
        <Col Col flex='160px' className='linked-asset-edit__image'>
          <Checkbox checked={isReplaceAsset} onChange={onChange}>
            <Text strong>
              <FormattedMessage {...Messages.replaceAsset} />
            </Text>
          </Checkbox>
          <IXONECertificationWrapper
            showModal={imagePreview[0]?.fromTdcImage}
            type={TYPE_VIEW.IMAGE_PRODUCT}
            onConfirm={(ixoneIgnore) => {
              onUploadImageHandler(ixoneIgnore);
            }}
          >
            {(handleConfirmIXONE) => (
              <ProfileImage
                src={profileImage}
                isEditMode={isReplaceAsset}
                loading={editLogoLoading}
                isDefaultSrc={true}
                style={{ width: '100px', height: '100px' }}
                onSubmit={(params) => {
                  setParams(params);
                  handleConfirmIXONE({ originalData: {}, changedFields: {} });
                }}
              />
            )}
          </IXONECertificationWrapper>
        </Col>
        <Col flex='calc(100% - 170px)' className='linked-asset-edit__info'>
          {visible && (
            <AssetGS1Edit
              isPimImport
              showConfirmIXONEShield={imagePreview[0]?.fromTdcImage}
              isEditLinkedAsset={true}
              pimId={id}
              assetId={imagePreview[0]?.digitalAssetId}
              imagePreview={imagePreview}
              isDisabledGtin={true}
              setImagePreview={setImagePreview}
              productInfo={productFull}
              addType={addType}
              onReloadPage={() => {
                if (
                  getMarketingAssets &&
                  typeof getMarketingAssets === 'function'
                ) {
                  getMarketingAssets();
                }
                if (getImagery && typeof getImagery === 'function') {
                  getImagery();
                }

                onCancel();
              }}
            />
          )}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default LinkedAssetEditModal;
