import React, { useEffect, useState } from 'react';

import { Row, Col, Typography, Checkbox } from 'antd';

import SelectedMemberList from './SelectedMemberList';

import './GLNFormMove.less';

const { Text } = Typography;

const GLNFormMove = (props) => {
  const {
    moveForm,
    glnList,
    selectedItems,
    items,
    handleSelectItem,
    tagRender,
  } = props;

  const [GLNoptions, setGLNOptions] = useState([]);

  const onChangeGLNList = (checkedValues) => {
    const event = {
      target: {
        name: 'glns',
        value: checkedValues,
      },
    };
    moveForm.onChange(event);
  };

  useEffect(() => {
    //* extract GLN options
    const extractGLNOptions = () => {
      let nextGlnOptions;

      if (!glnList?.length) {
        nextGlnOptions = [];
      } else {
        nextGlnOptions = glnList.map((glnValue) => ({
          label: glnValue,
          value: glnValue,
        }));
      }

      setGLNOptions(nextGlnOptions);
    };

    extractGLNOptions();
  }, [glnList]);

  return (
    <div className='reassign-gln-form-move'>
      <Row>
        <Col xs={24} style={{ height: 50 }}>
          <SelectedMemberList
            selectedItems={selectedItems}
            items={items}
            handleSelectItem={handleSelectItem}
            tagRender={tagRender}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Text strong>GLN list</Text>
        </Col>
      </Row>
      <Row style={{ flex: 1, overflow: 'hidden' }}>
        <Col className='scroller reassign-gln-form-move__gln-list' xs={24}>
          <Checkbox.Group options={GLNoptions} onChange={onChangeGLNList} />
        </Col>
      </Row>
    </div>
  );
};

export default GLNFormMove;
