import React from 'react';

import { Col, Row } from 'antd';
import { InfoWithLabel } from 'common/components';

const SelectedUserInfo = (props) => {
  const { selectedUserInfo } = props;

  const data = [
    { label: 'Email', info: selectedUserInfo?.email },
    { label: 'First Name', info: selectedUserInfo?.firstName },
    { label: 'Last Name', info: selectedUserInfo?.lastName },
    { label: 'Username', info: selectedUserInfo?.userName },
  ];

  const infoColProps = {
    infoColProps: {
      xs: 14,
    },
    labelColProps: {
      xs: 10,
    },
    boldInfo: true,
    labelAlign: 'right',
    infoTextStyle: {
      whiteSpace: 'break-spaces',
    },
  };

  return (
    <div style={{ height: 130 }}>
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          return (
            <InfoWithLabel
              key={index}
              info={item.info}
              label={item.label}
              {...infoColProps}
            />
          );
        })}
    </div>
  );
};

export default SelectedUserInfo;
