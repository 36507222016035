import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import './Icon.less';

const InfoIcon = () => {
  return (
    <div className='dialog-info-icon'>
      <div className='dialog-info-icon-error'>
        <CloseOutlined className='dialog-info-icon-error__icon' />
      </div>
    </div>
  );
};

export default InfoIcon;
