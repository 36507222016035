import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import './ButtonWithIcon.less';

const { Text } = Typography;

const ButtonLargeIcon = ({
  icon,
  label,
  toggle = false,
  className,
  intl,
  ...otherProps
}) => {
  const mapToLabelContent = () => {
    let newLabel = '';
    try {
      newLabel = intl.formatMessage(label);
    } catch (e) {
      newLabel = label;
    }
    return newLabel;
  };

  //! COMPONENT DEPRECATED
  //! use RibbonButton instead
  return (
    <Button
      className={classNames({
        'button-large-icon': true,
        'button-large-icon--toggle': toggle,
        [className]: className ? true : false,
      })}
      // icon={icon}
      {...otherProps}
    >
      <br />
      <Text
        className={classNames({
          'button-large-icon__message': true,
          'button-large-icon__message--toggle': toggle,
          'button-large-icon__message--disabled': otherProps.disabled,
        })}
      >
        {mapToLabelContent()}
      </Text>
    </Button>
  );
};

ButtonLargeIcon.prototype = {
  icon: PropTypes.element,
  label: PropTypes.object,
  onClick: PropTypes.func,
  toggle: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default injectIntl(ButtonLargeIcon);
