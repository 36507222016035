import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { EditUserName } from '../top-content-edit';
import { EditButtonsSection } from 'common/components';
import './UserName.less';

const { Text } = Typography;

const UserName = (props) => {
  const { userId, firstName, lastName, isEditMode, ...rest } = props;
  const [visible, setVisible] = useState(false);
  const fullName = `${firstName}${
    firstName && firstName ? ' ' : ''
  }${lastName}`;

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Row
        className='user-name'
        style={{
          flexWrap: 'nowrap',
          alignItems: 'center',
        }}
      >
        <Text
          className='user-profile__username'
          strong
          level={3}
          {...rest}
          style={{ marginRight: 10 }}
        >
          {fullName}
        </Text>
        {isEditMode && <EditOutlined onClick={() => setVisible(true)} />}
      </Row>
      <EditUserName
        visible={visible}
        onClose={onClose}
        // onFinishEdit={onFinishEdit}
        firstName={firstName}
        lastName={lastName}
        userId={userId}
      />
    </>
  );
};

UserName.propTypes = {
  /**
   * User first name
   */
  firstName: PropTypes.string,
  /**
   * User last name
   */
  lastName: PropTypes.string,
};

export default UserName;
