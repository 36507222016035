import { isEqual } from 'lodash';

import { deleteObjectField } from 'utils';
/**
 * @param {*} multipleFormValues
 * @returns //? proccessed value for /api/syndication/save-product-data-for-publishing
 */
export const mapToFormValuePublicationInstantFix = (multipleFormValues) => {
  let submitValue = (multipleFormValues || []).map((formValue) => {
    if (!formValue) return {};

    let nextFormValue = {
      ...formValue,
      value: formValue[formValue?.fieldName],
    };

    deleteObjectField(nextFormValue, [formValue?.fieldName]);
    return nextFormValue;
  });

  if (submitValue.find((formValue) => !formValue?.productId)) {
    return false;
  }

  if (submitValue?.length <= 0) return [];

  //* filter all empty brickcode
  submitValue = submitValue.filter(
    (submitItem) =>
      (submitItem.fieldName || '').toLowerCase() !== 'brickcode' ||
      ((submitItem.fieldName || '').toLowerCase() === 'brickcode' &&
        submitItem?.value?.brickCodeName)
  );

  //* create two submit items for each brick code form
  let nextSubmitValue = [...submitValue];

  let brickCodeSubmitList = [];

  nextSubmitValue.forEach((itemValue, idx) => {
    if ((itemValue.fieldName || '').toLowerCase() === 'brickcode') {
      brickCodeSubmitList.push(idx);
    }
  });

  //* add brickcode value form
  brickCodeSubmitList.forEach((brickcodeSubmitIdx) => {
    const brickCodeData = nextSubmitValue[brickcodeSubmitIdx];

    const code = brickCodeData?.value?.brickCode;
    const brickCodeName = brickCodeData?.value?.brickCodeName;

    const defaultBrickCodeValue = {
      productId: brickCodeData?.productId,
      moduleName: '',
    };

    nextSubmitValue = [
      ...nextSubmitValue,
      {
        ...defaultBrickCodeValue,
        fieldName: 'BrickCode',
        value: code,
      },
      {
        ...defaultBrickCodeValue,
        fieldName: 'BrickCodeName',
        value: brickCodeName,
      },
    ];
  });

  //* remove all original brickcode form
  nextSubmitValue = nextSubmitValue.filter(
    (submitValueItem, idx) => !brickCodeSubmitList.includes(idx)
  );

  return nextSubmitValue;
};
