import { useQuery } from '@tanstack/react-query';

import * as ticketingServices from 'services/ticketingSystem';

export const KEY_GET_TICKET_ASSIGNMENTS = 'all-ticket-assignments';

export const useGetTicketAssignTo = (props) => {
  const { params, enabled = false, ...restProps } = props;

  const getTicketAssignment = async ({ queryKey }) => {
    const response = await ticketingServices.getTicketAssignment(queryKey[1]);

    const { isSuccess, data } = response;

    return isSuccess && data
      ? {
          group: data?.ticketGroupsAssignment,
          user: data?.usersAssignment,
        }
      : { group: [], user: [] };
  };

  const query = useQuery({
    queryKey: [KEY_GET_TICKET_ASSIGNMENTS, params],
    queryFn: getTicketAssignment,
    enabled,
    ...restProps,
  });

  return query;
};
