import * as types from './constants';

export function toggleEditLayout(payload) {
  return {
    type: types.TOGGLE_EDIT_LAYOUT,
    payload,
  };
}

export function updateLayoutChanged(payload) {
  return {
    type: types.UPDATE_LAYOUT_CHANGED,
    payload,
  };
}

export function calendarDayView(calendarView) {
  return {
    type: types.CALENDAR_DAY_VIEW,
    calendarView,
  };
}

export function calendarWeekView(calendarView) {
  return {
    type: types.CALENDAR_WEEK_VIEW,
    calendarView,
  };
}

export function calendarMonthView(calendarView) {
  return {
    type: types.CALENDAR_MONTH_VIEW,
    calendarView,
  };
}

export function calendarScheduleView(calendarView) {
  return {
    type: types.CALENDAR_SCHEDULE_VIEW,
    calendarView,
  };
}

export function getMeetingList(fromDate, toDate) {
  return {
    type: types.GET_MEETING_LIST,
    fromDate,
    toDate,
  };
}

export function getMeetingListSuccess(meetingList) {
  return {
    type: types.GET_MEETING_LIST_SUCCESS,
    meetingList,
  };
}

export function getMeetingListError(error) {
  return {
    type: types.GET_MEETING_LIST_ERROR,
    error,
  };
}

export function updateCalendarDate(calendarDate) {
  return {
    type: types.UPDATE_CALENDAR_DATE,
    calendarDate,
  };
}

export const getScheduleList = (params) => ({
  type: types.GET_SCHEDULE_LIST,
  payload: params,
});

export const getScheduleListSuccess = (params) => {
  return {
    type: types.GET_SCHEDULE_LIST_SUCCESS,
    payload: params,
  };
};

export const getScheduleListError = (error) => ({
  type: types.GET_SCHEDULE_LIST_ERROR,
  payload: error,
});

export const getProjectsGrid = () => ({
  type: types.GET_PROJECTS_GRID,
});

export const getProjectsGridSuccess = (data) => {
  return {
    type: types.GET_PROJECTS_GRID_SUCCESS,
    data,
  };
};

export const getProjectsGridError = (error) => ({
  type: types.GET_PROJECTS_GRID_ERROR,
  error,
});

export const saveIdMeeting = (meetingId) => ({
  type: types.SAVE_ID_MEETING,
  meetingId,
});

export const getMeetingDetails = (params) => ({
  type: types.GET_MEETING_DETAILS,
  params,
});

export const getMeetingDetailsSuccess = (params) => {
  return {
    type: types.GET_MEETING_DETAILS_SUCCESS,
    payload: params,
  };
};

export const getMeetingDetailsError = (error) => ({
  type: types.GET_MEETING_DETAILS_ERROR,
  payload: error,
});

export const resetMeetingDetails = (params) => ({
  type: types.RESET_MEETING_DETAILS,
  payload: params,
});
