import React from 'react';
import { useDispatch } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { CopyOutlined } from '@ant-design/icons';

import * as maintenanceFormActions from 'pages/maintenance-form/controllers/actions';

const CloneFormBtn = (props) => {
  const { selectedForms, disabled } = props;

  const dispatch = useDispatch();

  const onClickCloneBtn = () => {
    const params = {
      formId: selectedForms?.[0]?.formId,
    };
    dispatch(maintenanceFormActions.cloneFormItem(params));
  };

  return (
    <RibbonButton
      icon={<CopyOutlined />}
      label={'Clone'}
      disabled={disabled}
      onClick={onClickCloneBtn}
    />
  );
};

export default CloneFormBtn;
