import classnames from 'classnames';
import { PRODUCT_CATEGORY_LEVEL } from 'pages/company-profile/components/tabs/system/category-management/constant';

const getHighlightedText = (categoryLevel, searchText, text) => {
  const parts = text.split(new RegExp(`(${searchText})`, 'gi'));

  return (
    <span>
      <span style={{ fontWeight: '700', marginRight: '6px' }}>
        Category {PRODUCT_CATEGORY_LEVEL[categoryLevel]}:
      </span>
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === searchText.toLowerCase()
                ? { color: '#f50', fontWeight: '600' }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    </span>
  );
};

export const formatTitleTreeData = (searchText, selectedKeys, data) => {
  return data.map((item) => {
    const { key, titleValue, categoryLevel, children, isLeaf } = item;

    const isSelectTo = selectedKeys?.to === key;
    const isSelectFrom = selectedKeys?.from === key;

    const titleNode = getHighlightedText(categoryLevel, searchText, titleValue);

    return {
      ...item,
      title: (
        <>
          <span
            className={classnames({
              'category-tree__selected': true,
              'category-tree__selected--from': isSelectFrom,
              'category-tree__selected--to': isSelectTo,
            })}
          >
            {titleNode}
          </span>
        </>
      ),
      switcherIcon: isLeaf ? <i className='fa-solid fa-tags'></i> : null,
      children: children
        ? formatTitleTreeData(searchText, selectedKeys, children)
        : null,
    };
  });
};

export const mappingArrayObjectToArrayKey = (
  bulkAssignProducts,
  selectedKeys
) => {
  if (bulkAssignProducts) {
    return Object.values(selectedKeys)
      .filter((item) => {
        return item !== null;
      })
      .map((item) => item);
  }
  return selectedKeys;
};

export const findKeysOfSearch = (tree, searchValue) => {
  let keyList = [];

  for (const node of tree) {
    if (node.titleValue.toLowerCase().includes(searchValue.toLowerCase())) {
      keyList.push(node.key);
    }

    if (node.children?.length) {
      const value = findKeysOfSearch(node.children, searchValue);
      keyList = keyList.concat(value);
    }
  }
  return keyList;
};

export const findNodesOfSearch = (tree, searchValue) => {
  let nodeList = [];
  for (const node of tree) {
    const isTitleContainSearchText = node.titleValue
      .toLowerCase()
      .includes(searchValue.toLowerCase());

    if (isTitleContainSearchText) {
      nodeList.push(node);
    }

    if (node.children?.length) {
      const value = findNodesOfSearch(node.children, searchValue);
      nodeList = nodeList.concat(value);
    }
  }

  return nodeList;
};

export const getTargetNodesById = (nestedData, ids) => {
  let nodeList = [];

  for (let i = 0; i < nestedData.length; i++) {
    const currentNode = nestedData[i];

    if (ids.includes(currentNode.id)) {
      nodeList.push(currentNode);
    }

    if (currentNode.hasChildren) {
      nodeList = nodeList.concat(getTargetNodesById(currentNode.children, ids));
    }
  }

  return nodeList;
};

export const getKeyExpandList = (nodeList) => {
  const result = [];

  for (const { key, isLeaf } of nodeList) {
    const parts = key.toString().split('-');
    let current = '';

    for (const part of parts) {
      current = current ? `${current}-${part}` : part;

      if (current === key.toString() && isLeaf) {
        continue;
      }

      if (!result.includes(current)) {
        result.push(current);
      }
    }
  }
  return result;
};

export const sortCategories = (categories) => {
  categories.sort((nodePrev, nodeNext) => {
    if (nodePrev.hasChildren === nodeNext.hasChildren) {
      return nodePrev.categoryName.localeCompare(nodeNext.categoryName);
    }

    return nodePrev.hasChildren ? -1 : 1;
  });

  for (const category of categories) {
    if (category.hasChildren) {
      sortCategories(category.children);
    }
  }

  return categories;
};

export const findLeafNodeInSignleTree = (tree) => {
  let categoryItem = null;

  for (let i = 0; i < tree.length; i++) {
    if (!tree[i].hasChildren) {
      categoryItem = tree[i];
      break;
    } else if (tree[i]?.children?.length > 0) {
      categoryItem = findLeafNodeInSignleTree(tree[i]?.children);
    }

    if (categoryItem) {
      return categoryItem;
    }
  }
  return categoryItem;
};
