import React, { useRef } from 'react';

import { CheckOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components';

import { apiHandler } from 'utils/api';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import * as ssoServices from 'services/sso-approval';
import * as brandingActions from 'redux/branding/actions';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const AcceptSsoApproval = (props) => {
  const reloadPage = useDispatchReloadPage();
  const intl = useIntl();
  const confirmModalRef = useRef(null);

  const dispatch = useDispatch();

  const { selectedItem, sectionDisabled, onClearItemGrid, approvalSubject } =
    props;

  const onClickBtn = () => {
    confirmModalRef.current = dialogFunction({
      type: 'info',
      content: intl.formatMessage(
        Messages.ssoApprovalAcceptConfirm[approvalSubject]
      ),
      okText: intl.formatMessage(Messages.ssoApprovalConfirmYes),
      cancelText: intl.formatMessage(Messages.ssoApprovalConfirmNo),
      onOk: callApiAcceptApproval,
    });
  };

  const updateModalLoading = (value) => {
    const modal = confirmModalRef.current;
    if (modal)
      modal.update({
        okButtonProps: {
          loading: value,
        },
      });
  };

  const callApiAcceptApproval = (close) => {
    updateModalLoading(true);
    const idPath = approvalSubject === 'user' ? 'userId' : 'memberId';
    const service =
      approvalSubject === 'user'
        ? ssoServices.acceptOrRejectUser
        : ssoServices.acceptOrRejectMember;

    const params = {
      [idPath]: selectedItem?.id,
      rivirMemberId: selectedItem?.rivirMemberId,
      memberName: selectedItem?.memberName,
      email: selectedItem?.email,
      firstName: selectedItem?.firstName,
      lastName: selectedItem?.lastName,
      phoneNumber: selectedItem?.phoneNumber,
      addBy: selectedItem?.addedBy,
    };

    const successMessage = intl.formatMessage(
      Messages.ssoApprovalAcceptSuccess[approvalSubject],
      {
        name:
          approvalSubject === 'member' ? selectedItem?.memberName : undefined,
      }
    );
    const errorMessage = intl.formatMessage(Messages.ssoApprovalAcceptError);

    apiHandler({
      service,
      params,
      successMessage,
      errorMessage,
      successCallback: successCallback(close),
      onFinally,
    });
  };

  const successCallback = (close) => () => {
    reloadPage();
    onClearItemGrid();
    close();
    dispatch(brandingActions.getBrandingNoLoading());
  };

  const onFinally = () => {
    updateModalLoading(false);
  };

  const checkDisabled = () => {
    const status = selectedItem?.poolStatus;

    const statusDisabled = status && status?.toLowerCase() !== 'pending';

    return sectionDisabled || statusDisabled;
  };

  return (
    <RibbonButton
      icon={<CheckOutlined />}
      label={Messages.approveSso}
      onClick={onClickBtn}
      disabled={checkDisabled()}
    />
  );
};

export default AcceptSsoApproval;
