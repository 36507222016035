import React from 'react';
import PropTypes from 'prop-types';

import { Space } from 'antd';

import { UserActivitySummary, UserActivityDetailGrid } from '../index';

import './UserActivityLogTab.less';

const UserActivityLogTab = () => {
  const classNamePrefix = 'user-activity-log';
  // eslint-disable-next-line
  return (
    <Space
      className={`${classNamePrefix}__wrapper`}
      direction='vertical'
      size={30}
    >
      <UserActivitySummary classNamePrefix={classNamePrefix} />
      <UserActivityDetailGrid classNamePrefix={classNamePrefix} />
    </Space>
  );
};

UserActivityLogTab.propTypes = {};

export default UserActivityLogTab;
