import React from 'react';
import PropTypes from 'prop-types';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/home';

const EditQueryControl = ({ onClick }) => {
  const onClickBtn = () => {
    onClick && onClick();
  };

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editUser}
      onClick={onClickBtn}
    />
  );
};

EditQueryControl.propTypes = {
  confirmEdit: PropTypes.func,
};

export default EditQueryControl;
