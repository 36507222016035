import React from 'react';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { dialogFunction } from 'common/components/index';
import { CustomNotification } from 'common/components';
import { deleteMappingConfig } from 'services/mapping';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import RibbonButton from 'common/components/button/RibbonButton';
import Messages from 'i18n/messages/home';

const DeleteMappingConfig = ({
  mappingId,
  mappingConfig,
  selectedItemList,
}) => {
  const reloadPage = useDispatchReloadPage();
  const selectedDetailItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const isDisabledDelete = checkDisabledDeleteBtn(mappingConfig);

  const handleDeleteModal = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the selected mapping config?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleDeleteMapping,
    });
  };

  const handleDeleteMapping = () => {
    const params = {
      mappingId: mappingId,
      mappingPropertyId: selectedDetailItems[0]?.id,
    };

    deleteMappingConfig(params)
      .then((res) => {
        if (res?.isSuccess) {
          CustomNotification.success('Delete successfully');
          reloadPage();
        } else {
          CustomNotification.error(
            res?.message || 'Delete failed! Please try!'
          );
        }
      })
      .catch((error) => {
        CustomNotification.error('Delete failed! Something go wrong!');
      });
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.deleteMapping}
      onClick={handleDeleteModal}
      disabled={
        isDisabledDelete ||
        selectedItemList?.length > 1 ||
        selectedItemList?.length === 0
      }
    />
  );
};

const checkDisabledDeleteBtn = (items) => {
  if (items === null) return true;
  if (!items?.isOwner) return true;

  return false;
};

DeleteMappingConfig.propTypes = {};

export default DeleteMappingConfig;
