import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Button, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FormSaveButton, FormCancelButton } from 'common/components';
import ViewOverviewInfo from './ViewOverviewInfo';
import EditOverviewInfo from './EditOverviewInfo';
import GoogleMapViewUserLocation from 'common/components/google-map-user-location/GoogleMapViewUserLocation';

import userSelectors from 'redux/user/selectors';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';

const OverviewInfo = (props) => {
  const {
    centerData,
    officeList,
    overviewInfo,
    handleGetUserProfile,
    editable,
    memberId,
  } = props;

  const [isEditMode, setMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const isEditUser = useSelector(userSelectors.makeSelectIsEditMode());

  useEffect(() => {
    setMode(isEditUser);
  }, [isEditUser]);

  const EDIT_MY_PROFILE = [
    { action: ABILITY_ACTION.CREATE, subject: ABILITY_SUBJECT.USER },
    { action: ABILITY_ACTION.EDIT, subject: ABILITY_SUBJECT.USER },
  ];

  const hasEditMyProfilePermission = useCheckPermissions(EDIT_MY_PROFILE);

  return (
    <Row className='user-profile-overview__info'>
      <Col xxl={12} xl={15}>
        {hasEditMyProfilePermission && (
          <Row justify='end' style={{ marginBottom: 5 }}>
            <Col>
              {!isEditMode ? (
                editable && (
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => setMode(true)}
                    type='link'
                    className='user-profile-overview__info-button'
                  />
                )
              ) : (
                <Space size={4} className='user-profile-overview__info-button'>
                  <FormSaveButton
                    htmlType='submit'
                    form='overview-info-form'
                    loading={loading}
                  />
                  <FormCancelButton onClick={() => setMode(false)} />
                </Space>
              )}
            </Col>
          </Row>
        )}
        {!isEditMode || !hasEditMyProfilePermission ? (
          <ViewOverviewInfo overviewInfo={overviewInfo} />
        ) : (
          <EditOverviewInfo
            overviewInfo={overviewInfo}
            setMode={() => setMode(false)}
            handleGetUserProfile={handleGetUserProfile}
            setLoading={setLoading}
            memberId={memberId}
          />
        )}
      </Col>
      <Col xxl={12} xl={9} style={{ paddingLeft: 10, height: 400 }}>
        <GoogleMapViewUserLocation
          officeList={officeList}
          centerData={centerData}
        />
      </Col>
    </Row>
  );
};

OverviewInfo.propTypes = {
  overviewInfo: PropTypes.object,
};

export default OverviewInfo;
