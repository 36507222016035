import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as actionsGlobal from 'redux/global/actions';
import * as selectorsGlobal from 'redux/global/selectors';
import { defaultSearchText } from 'redux/global/reducer';

export const useClearSearchText = (dispatch, type, pathname) => {
  const {
    digitalMediaText,
    assetText,
    documentText,
    multiMediaText,
    reportingsText,
    reportingsOwnedText,
    reportingsSharedText,
    memberText,
    memberMaintenanceText,
    memberMaintenanceUserText,
    userMaintenanceText,
    favMemberText,
    favAssetText,
    favProductText,
    folderText,
    folderOwnedText,
    folderSharedText,
    favFolderText,
    productText,
    favoriteQueriesText,
    productForMemberText,
    assetForMemberText,
    ssoApprovalMemberText,
    eulaCardText,
    dataSyncTimeText,
    qcTimeText,
    verificationLogText,
    ticketingSystemText,
    qaSpecWorkflowText,
  } = useSelector(selectorsGlobal.selectSelectSearchText());

  useEffect(() => {
    const isQuerying = window.location.search === '?query=1';

    switch (type) {
      case 'Assets':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            assetText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'Multimedia':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            multiMediaText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'favAsset':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            favAssetText,
          })
        );
        break;

      case 'favProduct':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            favProductText,
          })
        );
        break;

      case 'Documents':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            documentText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'ownerReporting':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            reportingsOwnedText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'sharedReporting':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            reportingsSharedText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'normalReporting':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            reportingsText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'member':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            memberText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'memberMaintenance':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            memberMaintenanceText,
          })
        );
        break;

      case 'memberMaintenanceUser':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            memberMaintenanceUserText,
          })
        );
        break;

      case 'userMaintenance':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            userMaintenanceText,
          })
        );
        break;

      case 'favMember':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            favMemberText,
          })
        );
        break;

      case 'folder':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            folderText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'ownedFolder':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            folderOwnedText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;

      case 'sharedFolder':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            folderSharedText,
          })
        );
        break;

      case 'favFolder':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            favFolderText,
          })
        );
        break;
      case 'product':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            productText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;
      case 'favQueries':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            favoriteQueriesText,
          })
        );
        break;
      case 'productForMember':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            productForMemberText,
          })
        );
        break;
      case 'assetForMember':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            assetForMemberText,
          })
        );
        break;
      case 'ssoApprovalMember':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            ssoApprovalMemberText,
          })
        );
        break;
      case 'eulaCard':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            eulaCardText,
          })
        );
        break;
      case 'dataSyncTimeTracking':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            dataSyncTimeText,
          })
        );
        break;
      case 'qcTimeTracking':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            qcTimeText,
          })
        );
        break;

      case 'pathnameVerificationLog':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            verificationLogText,
          })
        );
        break;
      case 'pathnameTicketingSystem':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            ticketingSystemText,
          })
        );

      case 'qaSpecWorkflow':
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            qaSpecWorkflowText: '',
          })
        );
        break;
      default:
        dispatch(
          actionsGlobal.resetSearchText({
            ...defaultSearchText,
            digitalMediaText,
            favoriteQueriesText: isQuerying ? favoriteQueriesText : '',
          })
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pathname]);
};
