import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectDashboard = (state) => state.dashboard || initialState;

const makeSelectEditingLayout = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.editingLayout
  );

const makeSelectLayoutChanged = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.isLayoutChanged
  );
const makeSelectCalendarView = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.calendarView
  );
const makeSelectCalendarDate = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.calendarDate
  );
const makeSelectMeetingList = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.meetingList
  );
const makeSelectScheduleList = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.scheduleList
  );

const makeSelectProjectsList = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.projectList
  );

const makeSelectMeetingId = () =>
  createSelector(selectDashboard, (dashboardState) => dashboardState.meetingId);

const makeSelectedDate = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.selectedDate
  );

const makeSelectLoadingCalendar = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.loadingCalendar
  );

const makeSelectMeetingDetails = () =>
  createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.meetingDetails
  );

export {
  makeSelectEditingLayout,
  makeSelectLayoutChanged,
  makeSelectScheduleList,
  makeSelectProjectsList,
  makeSelectCalendarView,
  makeSelectMeetingList,
  makeSelectCalendarDate,
  makeSelectMeetingId,
  makeSelectedDate,
  makeSelectLoadingCalendar,
  makeSelectMeetingDetails,
};
