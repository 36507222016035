import React from 'react';

import { PageEditor } from 'common/components';

const PageEditorPreview = (props) => {
  const { initPageData, ...restProps } = props;
  return (
    <PageEditor initPageData={initPageData} isEdit={false} {...restProps} />
  );
};

export default PageEditorPreview;
