import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Menu, Dropdown, Form } from 'antd';
import { SubnodeOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';

import SendMeetingInvite from '../../controls/product-apl/send-invite/SendMeetingInvite';
import SendQuestions from '../../controls/product-apl/SendQuestions';
import AddProductToApl from '../../controls/product-apl/AddProductToApl';
import DeleteProductApl from '../../controls/product-apl/DeleteProductApl';
import AplSendSomethingModal from '../../modal/product-apl/AplSendSomethingModal';
import RequestSample from '../../controls/product-apl/RequestSample';
import SendAward from '../../controls/product-apl/SendAward';
import EditProductContact from '../../controls/product-apl/EditProductContact';
import AplSendSampleRequestModal from '../../modal/product-apl/AplSendSampleRequestModal';

import * as aplSelectors from 'pages/product-apl-detail/controllers/selectors';

import Messages from 'i18n/messages/apl';

const AplDetailActions = () => {
  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [sampleRequestVisible, setSampleRequestVisible] = useState(false);
  const [sendType, setSendType] = useState(null);

  const productList = useSelector(
    aplSelectors.makeSelectAplProductDetailListSelect()
  );

  const menuOptions = (
    <Menu>
      <Menu.Item
        key='add-product-to-apl'
        style={{ backgroundColor: 'inherit' }}
      >
        <AddProductToApl />
      </Menu.Item>
      <Menu.Item
        key='delete-product-to-apl'
        style={{ backgroundColor: 'inherit' }}
      >
        <DeleteProductApl />
      </Menu.Item>
      <Menu.Item key='edit-contact' style={{ backgroundColor: 'inherit' }}>
        <EditProductContact productList={productList} />
      </Menu.Item>
      <Menu.Item key='meeting-invite' style={{ backgroundColor: 'inherit' }}>
        <SendMeetingInvite
          setSendType={setSendType}
          setSendModalVisible={setSendModalVisible}
          productList={productList}
        />
      </Menu.Item>
      <Menu.Item key='questions' style={{ backgroundColor: 'inherit' }}>
        <SendQuestions
          setSendType={setSendType}
          setSendModalVisible={setSendModalVisible}
          productList={productList}
        />
      </Menu.Item>
      <Menu.Item key='sample' style={{ backgroundColor: 'inherit' }}>
        <RequestSample
          productList={productList}
          setModalVisible={setSampleRequestVisible}
        />
      </Menu.Item>

      <Menu.Item key='award' style={{ backgroundColor: 'inherit' }}>
        <SendAward />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
        <RibbonButton
          icon={<SubnodeOutlined />}
          label={Messages.aplDetailActions}
        />
      </Dropdown>

      {sendModalVisible && (
        <AplSendSomethingModal
          visible={sendModalVisible}
          setVisible={setSendModalVisible}
          sendType={sendType}
          productList={productList}
        />
      )}
      <AplSendSampleRequestModal
        visible={sampleRequestVisible}
        setVisible={setSampleRequestVisible}
        productData={productList[0]}
      />
    </>
  );
};

export default AplDetailActions;
