import React from 'react';

import { Space } from 'antd';

const FullWidthSpace = (props) => {
  const { children, style, ...rest } = props;
  return (
    <Space direction='vertical' style={{ width: '100%', ...style }} {...rest}>
      {children}
    </Space>
  );
};

export default FullWidthSpace;
