import React from 'react';
import {
  SearchOutlined,
  SettingOutlined,
  EditOutlined,
  DownloadOutlined,
  CodeSandboxOutlined,
  PlaySquareOutlined,
  UserOutlined,
  QuestionOutlined,
  FolderOpenOutlined,
  TeamOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  InboxOutlined,
  BookOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { ReactComponent as GoogleSvg } from 'assets/google.svg';
import { ReactComponent as LinkedInSvg } from 'assets/LinkedIn.svg';
// import { ReactComponent as PinterestSvg } from 'assets/Pinterest.svg';
// import { ReactComponent as SnapchatSvg } from 'assets/Snapchat.svg';
import { ReactComponent as FacebookSvg } from 'assets/social-facebook.svg';
import { ReactComponent as InstagramSvg } from 'assets/instagram.svg';
// import { ReactComponent as RedditSvg } from 'assets/social-reddit.svg';
// import { ReactComponent as TumblrSvg } from 'assets/tumblr.svg';
import { ReactComponent as TwitterSvg } from 'assets/twitter.svg';
// import { ReactComponent as WebxSvg } from 'assets/webx.svg';
// import { ReactComponent as WhatsappSvg } from 'assets/whatsapp.svg';
import { ReactComponent as WebsiteSvg } from 'assets/website.svg';
import gs1Img from 'assets/gs1.png';
import ixoneImg from 'assets/ixone.png';

export const SocialLinkComponent = [
  {
    type: 'Website',
    component: WebsiteSvg,
  },
  {
    type: 'Facebook',
    component: FacebookSvg,
  },
  {
    type: 'Twitter',
    component: TwitterSvg,
  },
  {
    type: 'Google',
    component: GoogleSvg,
  },
  {
    type: 'LinkedIn',
    component: LinkedInSvg,
  },
  {
    type: 'Instagram',
    component: InstagramSvg,
  },
  {
    type: 'gs1',
    imageUrl: gs1Img,
    unSelectable: true,
  },
  {
    type: 'ixone',
    imageUrl: ixoneImg,
    unSelectable: true,
  },
  // {
  //   type: 'Pinterest',
  //   component: PinterestSvg,
  // },
  // {
  //   type: 'Snapchat',
  //   component: SnapchatSvg,
  // },
  // {
  //   type: 'Reddit',
  //   component: RedditSvg,
  // },
  // {
  //   type: 'Tumblr',
  //   component: TumblrSvg,
  // },
  // {
  //   type: 'Webx',
  //   component: WebxSvg,
  // },
  // {
  //   type: 'Whatsapp',
  //   component: WhatsappSvg,
  // },
];

const SearchOutlinedIcon = () => {
  return <SearchOutlined style={{ fontSize: 20 }} />;
};
const EditOutlinedIcon = () => {
  return <EditOutlined style={{ fontSize: 20 }} />;
};
const CloudDownloadOutlinedIcon = () => {
  return <DownloadOutlined style={{ fontSize: 20 }} />;
};
const SettingOutlinedIcon = () => {
  return <SettingOutlined style={{ fontSize: 20 }} />;
};

export const activityTypeIcon = [
  {
    type: 'Search',
    component: SearchOutlinedIcon,
  },
  {
    type: 'Edit',
    component: EditOutlinedIcon,
  },
  {
    type: 'Download',
    component: CloudDownloadOutlinedIcon,
  },
  {
    type: 'default',
    component: SettingOutlinedIcon,
  },
];

export const IconNotDefinedType = WebsiteSvg;

export const entityTypeIconRenderer = (type, iconProps) => {
  switch (type) {
    case 'product':
      return <CodeSandboxOutlined {...iconProps} />;
    case 'asset':
      return <PlaySquareOutlined {...iconProps} />;
    case 'member':
      return <UserOutlined {...iconProps} />;
    case 'query':
      return <SearchOutlined {...iconProps} />;
    case 'folder':
      return <FolderOpenOutlined {...iconProps} />;
    case 'reporting':
      return <RightCircleOutlined {...iconProps} />;
    default:
      return <QuestionOutlined {...iconProps} />;
  }
};

export const entityPostTypeIconRenderer = (type, iconProps) => {
  switch (type) {
    case 'UserOutlined':
      return <UserOutlined {...iconProps} />;
    case 'TeamOutlined':
      return <TeamOutlined {...iconProps} />;
    case 'PictureOutlined':
      return <PictureOutlined {...iconProps} />;
    case 'VideoCameraOutlined':
      return <VideoCameraOutlined {...iconProps} />;
    case 'FileTextOutlined':
      return <FileTextOutlined {...iconProps} />;
    case 'InboxOutlined':
      return <InboxOutlined {...iconProps} />;
    case 'FolderOpenOutlined':
      return <FolderOpenOutlined {...iconProps} />;
    case 'BookOutlined':
      return <BookOutlined {...iconProps} />;
    default:
      return <QuestionOutlined {...iconProps} />;
  }
};
