import { useEffect } from 'react';

import { useRoutingConfirm } from './useRoutingConfirm';

const KEY_CODES = {
  F5: 'F5',
  KeyR: 'KeyR',
};

const useHandleReloadPageKeyboardEvent = ({ blockId }) => {
  const { triggerAndWatchRoutingConfirm } = useRoutingConfirm();

  /**
   ** waiting until all blocking modals get confirm 'Yes' or 'No' from user.
   * @param {*} event
   */
  const handleBlockRoutingByKeyboard = async (event) => {
    event.preventDefault();
    const isConfirmRouting = await triggerAndWatchRoutingConfirm();

    if (isConfirmRouting) {
      window.location.reload();
    }
  };
  /**
   ** handle when routing blocked and user press F5 or Ctrl + F5 or Ctrl + Shift + F5
   * @param {*} event
   */
  const refreshHanlder = (event) => {
    const isBlocked = window.urlBlocked?.[blockId]?.blocked;

    if (!!isBlocked && event.code === KEY_CODES.F5) {
      handleBlockRoutingByKeyboard(event);
    }

    if (!!isBlocked && event.code === KEY_CODES.KeyR && event.ctrlKey) {
      handleBlockRoutingByKeyboard(event);
    }

    if (
      !!isBlocked &&
      event.code === KEY_CODES.KeyR &&
      event.ctrlKey &&
      event.shiftKey
    ) {
      handleBlockRoutingByKeyboard(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', refreshHanlder);

    return () => {
      window.removeEventListener('keydown', refreshHanlder);
    };
  }, []);
};

export { useHandleReloadPageKeyboardEvent };
