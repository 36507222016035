import React, { useMemo } from 'react';

import { UNLOCK_METHOD, OTP_ACTION, OTP_TYPE } from '../../constants';

import OtpCheck from '../otp-check/OtpCheck';

const OtpCheckUnlockAccount = (props) => {
  const {
    unlockMethod,
    otp,
    setOtp,
    onResendOtp,
    step,
    timeAllowResendHook,
    allowResendHook,
    otpCheckError,
    setOtpCheckError,
    mfaSession,
  } = props;

  const otpCheckProps = {
    otp,
    setOtp,
    onResendOtp,
    step,
    timeAllowResendHook,
    allowResendHook,
    otpCheckError,
    setOtpCheckError,
    otpAction: OTP_ACTION.unlockAccount,
    otpType:
      unlockMethod === UNLOCK_METHOD.phone
        ? OTP_TYPE.phone
        : unlockMethod === UNLOCK_METHOD.email
        ? OTP_TYPE.email
        : null,
    addNew: true,
    mfaSession,
  };

  return <OtpCheck {...otpCheckProps} />;
};

export default OtpCheckUnlockAccount;
