import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { Row, Button, Avatar, List, Typography } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';

import {
  CustomNotification,
  StyledModal,
  dialogFunction,
} from 'common/components';

import RibbonButton from '../button/RibbonButton';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import * as productServices from 'services/product';

import { Images } from 'config/assets';

const { Paragraph, Title } = Typography;

const PostToWalmart = ({ isFullView = false }) => {
  const mutation = usePostToWalmart();

  const [modalInvalidProducts, setModalInvalidProducts] = useState(false);
  const [invalidProducts, setInvalidProducts] = useState([]);

  const selectProductGrid = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const selectedIdProductGrid =
    selectProductGrid?.map((product) => product?.id) ?? [];

  const { id: productId } = useParams();

  const handlePostToWalmart = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to post selected product(s) to Walmart?',
      onOk: async () => {
        const params = {
          productIds: isFullView ? [productId] : selectedIdProductGrid,
        };
        const response = await mutation.mutateAsync(params);

        if (response?.data?.invalidProducts?.length > 0) {
          setModalInvalidProducts(true);
          setInvalidProducts(response.data.invalidProducts);
        }
      },
    });
  };

  const disabledButton = isFullView
    ? !productId
    : selectProductGrid?.length === 0;

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<DeploymentUnitOutlined />}
        label='Post to Walmart'
        onClick={handlePostToWalmart}
        disabled={disabledButton}
      />

      <StyledModal
        title='Invalid Products'
        visible={modalInvalidProducts}
        width={540}
        bodyStyle={{
          maxHeight: 'min(70vh, 600px)',
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText='Close'
        onCancel={() => setModalInvalidProducts(false)}
      >
        <ProductsProcessing invalidProducts={invalidProducts} />
      </StyledModal>
    </>
  );
};

const ProductsProcessing = ({ invalidProducts }) => {
  return (
    <List
      size='small'
      itemLayout='vertical'
      dataSource={invalidProducts}
      renderItem={(item) => (
        <List.Item
          key={item.productItemId}
          extra={
            <Row align='middle' style={{ height: '70px' }}>
              <Button
                type='primary'
                style={{ borderRadius: 8 }}
                onClick={() => {
                  window.open(`/product/${item.productItemId}`, '_blank');
                }}
              >
                Open
              </Button>
            </Row>
          }
        >
          <List.Item.Meta
            avatar={
              <Avatar
                size={70}
                src={item.productPrimaryImage || Images.RIVIR_LOGO_DEFAULT}
              />
            }
            title={
              <Title level={5} style={{ paddingLeft: 20, marginTop: 8 }}>
                {item.productName}
              </Title>
            }
            description={
              <Paragraph
                type='danger'
                style={{ paddingLeft: 20, marginBottom: 0 }}
              >
                {item.reason}
              </Paragraph>
            }
          />
        </List.Item>
      )}
    />
  );
};

const usePostToWalmart = () => {
  return useMutation({
    mutationFn: productServices.postToWalmart,
    onSuccess: (response) => {
      if (response?.isSuccess) {
        CustomNotification.success('Post to Walmart successfully');
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
      }
    },
    onError: () => {
      CustomNotification.error('Server Error');
    },
  });
};

export default PostToWalmart;
