import React, { useState, useRef, useMemo, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import classnames from 'classnames';

import NestedDetailFramework from './NestedDetailFramework';

import * as utils from './utils';

const NestedGrid = (props) => {
  const MIN_HEIGHT = 130;
  const MAX_HEIGHT = 300;
  const HEADER_HEIGHT = 30;
  const SCROLL_HEIGHT = 22;

  const gridRef = useRef();

  const { data, columnData, parentPath, shouldRenderDetail, shouldShowHeader } =
    props;

  const containerStyle = useMemo(() => {
    const dynamicHeight =
      (data?.length ? data?.length * 26 : 26) + HEADER_HEIGHT;
    const containerHeight =
      dynamicHeight > MAX_HEIGHT ? MAX_HEIGHT : dynamicHeight + SCROLL_HEIGHT;

    return {
      width: '100%',
      height:
        !data || data.length === 0
          ? MIN_HEIGHT
          : shouldRenderDetail
          ? MAX_HEIGHT
          : containerHeight,
      paddingLeft: 10,
    };
  }, []);

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 200,
      suppressMenu: true,
      sortable: true,
      resizable: true,
    };
  }, []);

  const onGridReady = (params) => {
    updateRowData(params);
  };

  const updateRowData = (params) => {
    if (data && data.length !== 0) {
      const rowDataList = data?.length ? data : [data];

      //* map custom props when render custom detail
      const rowDataListWithCustomProps = mapRowDataWithCustomProps(rowDataList);
      const indexedData = indexData(rowDataListWithCustomProps);

      setRowData(indexedData);

      return;
    }

    //* show no row data message
    params?.api?.showNoRowsOverlay();
  };

  const indexData = (data) => {
    return data.map((rowItem, index) => {
      return { ...rowItem, row_id: `${parentPath}_${index}` };
    });
  };

  const generateAttributeGridColumns = () => {
    const columns = utils.filterAttributeFieldColumn({
      parentPath,
      columnData,
    });

    return formatColumns(columns);
  };

  const formatColumns = (columnData) => {
    const formattedColumns = columnData.reduce(
      (accumulator, currentColumn, index) => {
        const { fieldName, displayName } = currentColumn;

        const fieldNameArr = fieldName.split('.');

        const mainFieldName = fieldNameArr[fieldNameArr.length - 1];
        const formattedColumns = {
          field: mainFieldName,
          headerName: displayName,
          cellRenderer: utils.getColumnRenderer({
            columnData: currentColumn,
            isArray: currentColumn?.isMultiple,
          }),
          listName: currentColumn.listName,
          dataType: currentColumn.dataType,
        };

        accumulator.push(formattedColumns);

        return accumulator;
      },
      []
    );

    const expandIconColumn = shouldRenderDetail
      ? [
          {
            field: 'row_id',
            headerName: '',
            flex: columnData.length ? false : 1,
            width: 30,
            minWidth: 0,
            resizable: false,
            valueFormatter: () => ' ',
            cellRenderer: 'agGroupCellRenderer',
          },
        ]
      : [];

    return [...expandIconColumn, ...formattedColumns];
  };

  const mapRowDataWithCustomProps = (dataList) => {
    return dataList.map((dataItem) => {
      return shouldRenderDetail
        ? {
            ...dataItem,
            _customProps: {
              columnData,
              parentPath,
            },
          }
        : dataItem;
    });
  };

  const getRowNodeId = (data) => {
    return data.row_id;
  };

  useEffect(() => {
    updateRowData();
  }, [JSON.stringify(data)]);

  const columnDefs = generateAttributeGridColumns();

  return columnDefs.length ? (
    <div style={containerStyle}>
      <div
        style={gridStyle}
        className={classnames([
          'ag-theme-alpine',
          { 'nested-grid--no-header': !shouldShowHeader },
        ])}
      >
        <AgGridReact
          id={data?.fieldName}
          ref={gridRef}
          rowData={rowData}
          getRowNodeId={getRowNodeId}
          rowHeight={25}
          headerHeight={shouldShowHeader ? HEADER_HEIGHT : 0}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={shouldRenderDetail}
          detailRowHeight={MAX_HEIGHT}
          frameworkComponents={NestedDetailFramework}
          detailCellRenderer={'nestedGrid'}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
  ) : null;
};

export default NestedGrid;
