import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Divider, Input, Row, Space, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Form, SectionWrapper } from 'common/components';
import { AddNewItem, UpdateValueButton } from './DrugFactsButton';

const Uses = () => {
  const form = Form.useFormInstance();
  const [mode, setMode] = useState('view');

  const useDetail = Form.useWatch('useDetail', form);

  const onClickAddNew = () => {
    form.resetFields(['use']);
    setMode('add');
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    form
      .validateFields(['use'])
      .then((value) => {
        form.setFieldsValue({
          useDetail: [...(useDetail || []), { useDetail: value?.use }],
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ minHeight: '19vh' }}>
      <Form.Item name='useText' label='Text'>
        <Input placeholder='Uses Text' />
      </Form.Item>
      <UsesList setMode={setMode} mode={mode} />
      {mode === 'add' && <AddUsesForm />}
      <AddNewItem
        mode={mode}
        onClickAdd={onClickAdd}
        onClickCancel={onClickCancel}
        onClickAddNew={onClickAddNew}
      />
    </div>
  );
};

export default Uses;

const UsesList = ({ setMode, mode }) => {
  const form = Form.useFormInstance();
  const usesDetailList = Form.useWatch('useDetail', form);

  const onClickUpdate = (index) => {
    const cloneUsesList = _.cloneDeep(usesDetailList);

    form
      .validateFields(['use'])
      .then((value) => {
        cloneUsesList[index] = { useDetail: value?.use };

        form.setFieldsValue({
          useDetail: cloneUsesList,
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickDelete = (index) => {
    const cloneUsesList = _.cloneDeep(usesDetailList);
    cloneUsesList.splice(index, 1);

    form.setFieldsValue({
      useDetail: cloneUsesList,
    });
  };

  const onClickEdit = (index, useDetail) => {
    setMode(`edit-${index}`);
    form.setFieldsValue({ use: useDetail });
  };

  const onCancel = () => {
    setMode('view');
  };
  return (
    <>
      <Form.Item name='useDetail' hidden />
      {usesDetailList?.map(({ useDetail }, index) => {
        const isEdit = mode === `edit-${index}`;
        return (
          <div key={index}>
            {isEdit ? (
              <>
                <AddUsesForm />
                <UpdateValueButton
                  onCancel={onCancel}
                  onClickUpdate={() => onClickUpdate(index)}
                />
              </>
            ) : (
              <SectionWrapper
                title={
                  <Space>
                    <EditOutlined
                      onClick={() => onClickEdit(index, useDetail)}
                    />
                    <DeleteOutlined onClick={() => onClickDelete(index)} />
                  </Space>
                }
                placement='right'
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <Row>
                  <Col span={24}>
                    <Typography.Text ellipsis={{ tooltip: useDetail }}>
                      ■ {useDetail}
                    </Typography.Text>
                  </Col>
                </Row>
              </SectionWrapper>
            )}
          </div>
        );
      })}
    </>
  );
};

const AddUsesForm = () => {
  const form = Form.useFormInstance();
  const usesDetailList = Form.useWatch('useDetail', form);
  return (
    <>
      {usesDetailList?.length > 0 && <Divider />}
      <Row gutter={4}>
        <Col span={24}>
          <Form.Item
            name='use'
            rules={[
              {
                required: true,
                message: 'Use is required',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
