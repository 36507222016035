import React from 'react';
import SeparatorBar from './SeparatorBar';
import { MEASUREMENT_OPERATOR } from 'pages/qa-spec/components/qa-nutrition-data/Constant';
import { parseNumber } from 'utils/parser';
import { getUomTypesAbbreviations } from '../DailyValueConstants';
import { useGetBasicUomEnum } from 'hooks';

function NutrientRow({
  hideBar = false,
  measurementPrecisionCode,
  indent,
  label,
  labelBold,
  nutrient,
  percent,
  percentBold,
  indentBar,
  bottomBar,
  bottomBarHeight,
  labelItalic,
}) {
  const { quantity, quantityUom } = nutrient ?? {};
  const { basicUomProperties } = useGetBasicUomEnum();

  const showAbbr = (quantityUom, defaultUom = 'g') => {
    return (
      getUomTypesAbbreviations(basicUomProperties, quantityUom) ?? defaultUom
    );
  };

  const gram =
    parseNumber(quantity) !== ''
      ? `${parseNumber(quantity)}${showAbbr(quantityUom, 'g')}`
      : '';

  const indentLabel = indent ? `${indent}px` : '0';
  const labelWeight = labelBold ? 900 : 400;
  const percentWeight = percentBold ? 900 : 400;

  const indentDiv = indentBar ? `${indentBar}px` : indentLabel;

  const styles = {
    div: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: indentDiv,
    },
    label: {
      fontWeight: labelWeight,
      fontStyle: labelItalic ? 'italic' : '',
    },
    percent: {
      fontWeight: percentWeight,
    },
    gram: {
      paddingLeft: '2px',
    },
    measurementPrecision: {
      paddingLeft: '2px',
      fontWeight: 400,
    },
  };

  const renderSymbols = (measurementPrecisionCode) => {
    return MEASUREMENT_OPERATOR?.[measurementPrecisionCode] || '';
  };

  const measurementPrecision = renderSymbols(measurementPrecisionCode);

  return (
    <div className='nutrition-facts__nutrition-row' style={styles.indent}>
      {hideBar ? null : <SeparatorBar indentBar={indentBar || 0} />}
      <div style={styles.div}>
        <div className='nutrition-facts__nutrition-row_name'>
          <div style={styles.label}>{label}</div>
          {gram ? (
            <>
              <div style={styles.measurementPrecision}>
                {measurementPrecision}
              </div>
              <div style={styles.gram}>{gram}</div>
            </>
          ) : null}
        </div>
        <div style={styles.percent}>
          {percent === 0 || percent > 0 ? `${percent}%` : null}
        </div>
      </div>
      {bottomBar && (
        <SeparatorBar
          indentBar={indentBar || 0}
          height={bottomBarHeight || 0}
        />
      )}
    </div>
  );
}

export default NutrientRow;
