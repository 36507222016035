import { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

let FileViewerComponent;

const FileViewer = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadedFileViewer = () =>
      new Promise(async (resolve, reject) => {
        if (window.FileViewer) {
          resolve({ isSuccess: true, component: window.FileViewer });
        } else {
          const head = document.getElementsByTagName('head')[0];
          const script = document.createElement('script');

          script.setAttribute('type', 'text/javascript');
          script.setAttribute(
            'src',
            process.env.PUBLIC_URL + '/lib/file.viewer.min.js'
          );

          script.addEventListener(
            'load',
            function (event) {
              if (event.target.nodeName === 'SCRIPT') {
                resolve({ isSuccess: true, component: window.FileViewer });
              }
            },
            true
          );

          script.onerror = (err) => {
            reject({ error: 'cannot get file viewer!' });
          };

          head.appendChild(script);
        }
      });

    loadedFileViewer().then(async (result) => {
      if (result?.isSuccess) {
        FileViewerComponent = result?.component;
        setLoading(false);
      }
    });
  }, []);

  return loading ? <LoadingOutlined /> : <FileViewerComponent {...props} />;
};

export default FileViewer;
