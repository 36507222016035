import React, { memo, useState, useMemo } from 'react';
import classnames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { union } from 'lodash';
import { Link } from 'react-router-dom';
// antd
import { Row, Col, Typography, Input, Skeleton } from 'antd';
import { DragOutlined } from '@ant-design/icons';
// component
import { SectionWrapper } from 'common/components';
import { getHighlightedText } from 'common/components/reporting/entity-and-attributes/utils';
import { DraggableField, QasGroup } from '../components';
// hooks
import { useGetAllProductFields } from 'hooks/qas-require-field/useQasRequireField';

import './ModalFormQasRequireField.less';

const primaryButton = 0;

const SectionQasRequireField = (props) => {
  const { requiredProperties, setRequiredProperties, formData, targetMember } =
    props;

  const [expandList, setExpandList] = useState([]);
  const [collapseList, setCollapseList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [draggingTaskId, setDraggingTaskId] = useState();
  const [searchText, setSearchText] = useState('');

  const { data: productFieldGroups, isLoading: productLoading } =
    useGetAllProductFields();

  const handleData = (tree, query, arrQuery) => {
    const handleTree = (node, query, arrQuery, parentNode) => {
      const matchedChilGroup =
        node?.childGroups?.length > 0 &&
        node.childGroups.map((child) =>
          handleTree(child, query, arrQuery, node)
        );
      const matchedField =
        node?.fields?.length > 0 &&
        node.fields.map((child) => handleTree(child, query, arrQuery, node));

      const isMatchGroup = node?.groupName
        ? arrQuery
          ? arrQuery.includes(node?.keyNode)
          : node?.groupName.toLowerCase().includes(query.toLowerCase())
        : false;
      const isMatchField = node?.fieldFullPath
        ? arrQuery
          ? arrQuery.includes(node?.fieldFullPath)
          : node?.displayName.toLowerCase().includes(query.toLowerCase())
        : false;

      const hasMatchedChildGroup =
        matchedChilGroup?.length > 0 &&
        matchedChilGroup.some(
          (child) =>
            child.matched || child.hasMatchedChildGroup || child.hasMatchedField
        );

      const hasMatchedField =
        matchedField?.length > 0 &&
        matchedField.some((child) => child.matched || child.hasMatchedField);

      return {
        ...node,
        childGroups: matchedChilGroup,
        fields: matchedField,
        matched: !!isMatchGroup || !!isMatchField,
        hasMatchedChildGroup: !!hasMatchedChildGroup,
        hasMatchedField: !!hasMatchedField,
        keyNode:
          node?.fieldFullPath || `${parentNode?.groupName}_${node?.groupName}`,
      };
    };

    return tree.map((root) => handleTree(root, query, arrQuery));
  };

  const productFieldData = useMemo(() => {
    let newProductFieldGroups =
      productFieldGroups?.length > 0 ? productFieldGroups : [];
    if (
      formData?.productType === 'HBC / OTC' ||
      formData?.productType === 'Non-Food'
    ) {
      newProductFieldGroups = newProductFieldGroups.filter(
        (val) => val?.groupName !== 'Ingredients'
      );
    }
    if (formData?.productType === 'Pet') {
      newProductFieldGroups = newProductFieldGroups.filter(
        (val) => val?.groupName !== 'Labeling'
      );
    }
    if (
      targetMember &&
      targetMember?.length > 0 &&
      targetMember[0]?.companyId != process.env.REACT_APP_DOLLAR_GENERAL_ID
    ) {
      newProductFieldGroups = newProductFieldGroups.filter(
        (val) => val?.groupName !== 'Product Contains'
      );
    }
    return newProductFieldGroups?.length > 0
      ? handleData([...newProductFieldGroups], '')
      : [];
  }, [productFieldGroups, formData]);

  const productFieldBySearch = useMemo(() => {
    return handleData([...productFieldData], searchText);
  }, [productFieldData, searchText]);

  const dataRequire = useMemo(() => {
    if (requiredProperties?.length > 0 && productFieldData?.length > 0) {
      return handleData([...productFieldData], null, requiredProperties);
    }
    return [];
  }, [requiredProperties, productFieldData]);

  const onDragStart = (start) => {
    const id = start.draggableId;
    setDraggingTaskId(id);

    const selected = selectedId.find((val) => val === id);
    if (!selected) {
      setSelectedId([]);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId, combine } = result;
    // nothing to do
    if (!destination || result.reason === 'CANCEL') {
      if (!combine) {
        setDraggingTaskId();
        return;
      } else {
        setDraggingTaskId();
        setSelectedId([]);
        return;
      }
    }
    let newRequireProperties = [];
    if (selectedId?.length > 0 && selectedId.includes(draggableId)) {
      newRequireProperties = selectedId;
    } else {
      newRequireProperties = getAllFields(productFieldData, draggableId);
    }

    const unionRequire = union(newRequireProperties, requiredProperties);
    setRequiredProperties(unionRequire);
    setDraggingTaskId();
    setSelectedId([]);
  };

  const getAllFields = (tree, draggableId) => {
    let arrFields = [];
    const handleTreeGetFields = (node, draggableId, groupMatched = false) => {
      node?.childGroups?.length > 0 &&
        node.childGroups.map((child) =>
          handleTreeGetFields(
            child,
            draggableId,
            child?.keyNode === draggableId || groupMatched
          )
        );
      if (groupMatched) {
        node?.fields?.length > 0 &&
          node.fields.map((child) => {
            arrFields.push(child?.fieldFullPath);
          });
      } else {
        node?.fields?.length > 0 &&
          node.fields.map((child) => {
            if (child?.fieldFullPath === draggableId)
              arrFields.push(child?.fieldFullPath);
          });
      }
    };
    tree.forEach((val) => {
      handleTreeGetFields(val, draggableId, val?.keyNode === draggableId);
    });
    return arrFields;
  };

  const handleRemove = (keyValue) => {
    const fieldRemove = getAllFields(productFieldData, keyValue);
    setRequiredProperties(
      requiredProperties.filter((val) => !fieldRemove.includes(val))
    );
  };

  const handleExpand = (item, dragDisabled) => {
    if (dragDisabled) {
      setCollapseList(collapseList.filter((val) => val !== item));
    } else {
      setExpandList([...expandList, item]);
    }
  };
  const handleCollapse = (item, dragDisabled) => {
    if (dragDisabled) {
      setCollapseList([...collapseList, item]);
    } else {
      setExpandList(expandList.filter((val) => val !== item));
    }
  };

  const wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event) => event.shiftKey;

  const performAction = (event, item) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      multiSelectTo(item?.fieldFullPath);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(item?.fieldFullPath);
      return;
    }

    toggleSelection(item?.fieldFullPath);
  };
  const onSelectMulti = (event, item) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    event.preventDefault();

    performAction(event, item);
  };

  const toggleSelection = (taskId) => {
    const wasSelected = selectedId.includes(taskId);

    const newTaskIds = (() => {
      if (!wasSelected) {
        return [taskId];
      }

      if (selectedId.length > 1) {
        return [taskId];
      }
      return [];
    })();
    setSelectedId(newTaskIds);
  };

  const multiSelectTo = (taskId) => {
    const wasSelected = selectedId.includes(taskId);
    const newTaskIds = (() => {
      if (!wasSelected) {
        return [...selectedId, taskId];
      } else {
        return selectedId.filter((val) => val !== taskId);
      }
    })();
    setSelectedId(newTaskIds);
  };

  const renderGroupProperties = (
    group,
    index,
    level,
    dragDisabled = false,
    parentGroupMatched
  ) => {
    if (
      dragDisabled &&
      !group?.matched &&
      !group?.hasMatchedChildGroup &&
      !group?.hasMatchedField &&
      !parentGroupMatched
    ) {
      return;
    }
    if (
      !dragDisabled &&
      !group?.matched &&
      !group?.hasMatchedChildGroup &&
      !group?.hasMatchedField
    ) {
      return;
    }
    const highlightSearchText = getHighlightedText(
      group?.groupName,
      searchText
    );

    return (
      <Draggable
        draggableId={`${group?.keyNode}`}
        key={`${index}_${`${group?.keyNode}`}`}
        index={index}
        isDragDisabled={dragDisabled}
      >
        {(provided, snapshot) => {
          const expand = dragDisabled
            ? collapseList.indexOf(group?.keyNode) === -1
            : expandList.indexOf(group?.keyNode) > -1;
          const collapse = dragDisabled
            ? collapseList.indexOf(group?.keyNode) > -1
            : expandList.indexOf(group?.keyNode) === -1;
          return (
            <div style={{ display: 'flex' }}>
              {!snapshot.isDragging && (
                <div
                  style={{
                    marginLeft: level * 8,
                  }}
                ></div>
              )}
              <div
                className={classnames({
                  'qas-spec-properties__card': true,
                  'qas-spec-properties__card--drag': snapshot.isDragging,
                })}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Row>
                  <Col span={24}>
                    <QasGroup
                      group={group}
                      dragDisabled={dragDisabled}
                      collapse={collapse}
                      highlightSearchText={highlightSearchText}
                      handleExpand={handleExpand}
                      handleCollapse={handleCollapse}
                      handleRemove={handleRemove}
                    />
                  </Col>
                  {expand && (
                    <Col span={24}>
                      {group?.fields?.length > 0 &&
                        group?.fields?.map((item, idx) => (
                          <DraggableField
                            key={idx}
                            item={item}
                            selectedId={selectedId}
                            dragDisabled={dragDisabled}
                            parentGroupMatched={
                              parentGroupMatched || group?.matched
                            }
                            index={index + idx}
                            level={level + 1}
                            searchText={searchText}
                            requiredProperties={requiredProperties}
                            handleRemove={handleRemove}
                            onSelectMulti={onSelectMulti}
                          />
                        ))}
                    </Col>
                  )}
                  {expand && (
                    <Col span={24}>
                      {group?.childGroups?.length > 0 &&
                        group?.childGroups?.map((val, idx) =>
                          renderGroupProperties(
                            val,
                            index + idx,
                            level + 1,
                            dragDisabled,
                            parentGroupMatched || group?.matched
                          )
                        )}
                    </Col>
                  )}
                </Row>
                {provided.placeholder}
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  };

  const renderGroupRequired = (
    group,
    index,
    level,
    dragDisabled = false,
    parentGroupMatched
  ) => {
    if (
      dragDisabled &&
      !group?.matched &&
      !group?.hasMatchedChildGroup &&
      !group?.hasMatchedField &&
      !parentGroupMatched
    ) {
      return;
    }
    if (
      !dragDisabled &&
      !group?.matched &&
      !group?.hasMatchedChildGroup &&
      !group?.hasMatchedField
    ) {
      return;
    }
    const highlightSearchText = getHighlightedText(
      group?.groupName,
      searchText
    );

    const expand = dragDisabled
      ? collapseList.indexOf(group?.keyNode) === -1
      : expandList.indexOf(group?.keyNode) > -1;
    const collapse = dragDisabled
      ? collapseList.indexOf(group?.keyNode) > -1
      : expandList.indexOf(group?.keyNode) === -1;
    return (
      <div style={{ display: 'flex' }} key={index}>
        <div
          style={{
            marginLeft: level * 8,
          }}
        />
        <div
          className={classnames({
            'qas-spec-properties__card': true,
          })}
        >
          <Row>
            <Col span={24}>
              <QasGroup
                group={group}
                dragDisabled={dragDisabled}
                collapse={collapse}
                highlightSearchText={highlightSearchText}
                handleExpand={handleExpand}
                handleCollapse={handleCollapse}
                handleRemove={handleRemove}
              />
            </Col>
            {expand && (
              <Col span={24}>
                {group?.fields?.length > 0 &&
                  group?.fields?.map((item, idx) => (
                    <DraggableField
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      dragDisabled={dragDisabled}
                      parentGroupMatched={parentGroupMatched || group?.matched}
                      index={index + idx}
                      level={level + 1}
                      searchText={searchText}
                      requiredProperties={requiredProperties}
                      handleRemove={handleRemove}
                      onSelectMulti={onSelectMulti}
                    />
                  ))}
              </Col>
            )}
            {expand && (
              <Col span={24}>
                {group?.childGroups?.length > 0 &&
                  group?.childGroups?.map((val, idx) =>
                    renderGroupRequired(
                      val,
                      index + idx,
                      level + 1,
                      dragDisabled,
                      parentGroupMatched || group?.matched
                    )
                  )}
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  };

  return (
    <Skeleton active loading={productLoading}>
      <Row gutter={[8, 8]} style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Row>
            <Col flex={'100px'} style={{ textAlign: 'end', marginRight: 4 }}>
              <Typography.Text strong>Member Name: </Typography.Text>
            </Col>
            <Col flex={1}>
              <Typography.Text strong>
                <Link
                  target='_blank'
                  to={`/company/${
                    targetMember &&
                    targetMember?.length > 0 &&
                    targetMember[0]?.companyId
                  }`}
                >
                  {targetMember &&
                    targetMember?.length > 0 &&
                    targetMember[0]?.companyName}
                </Link>
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col flex={'100px'} style={{ textAlign: 'end', marginRight: 4 }}>
              <Typography.Text strong>Product Type: </Typography.Text>
            </Col>
            <Col flex={1}>
              <Typography.Text strong>{formData?.productType}</Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <SectionWrapper title={'QA Specification - Properties'}>
              <Row>
                <Input.Search
                  placeholder='Input search QA Specification Properties'
                  enterButton
                  size='large'
                  allowClear
                  onSearch={(value) => {
                    setSearchText(value);
                    setExpandList([]);
                  }}
                  style={{ margin: 4 }}
                />
              </Row>
              <Droppable droppableId={'qas-properties'} isDropDisabled={true}>
                {(provided, { isDraggingOver }) => (
                  <div
                    ref={provided.innerRef}
                    className={'qas-spec-properties scroller-extend'}
                  >
                    {productFieldBySearch?.length > 0 &&
                      productFieldBySearch.map((item, index) => {
                        return (
                          <div key={index}>
                            {renderGroupProperties(item, index, 0)}
                          </div>
                        );
                      })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </SectionWrapper>
          </Col>
          <Col span={12}>
            <SectionWrapper title={'Required Properties'}>
              <Droppable droppableId={'required-properties'}>
                {(provided, { isDraggingOver }) => (
                  <div
                    ref={provided.innerRef}
                    className={classnames({
                      'qas-require-properties': true,
                      'scroller-extend': !isDraggingOver,
                      'qas-require-properties--dragging-over': isDraggingOver,
                    })}
                  >
                    <div>
                      {!isDraggingOver &&
                        dataRequire &&
                        dataRequire.length > 0 &&
                        dataRequire.map((item, index) => {
                          return (
                            <div key={index}>
                              {renderGroupRequired(item, index, 0, true)}
                            </div>
                          );
                        })}
                    </div>
                    {(isDraggingOver || requiredProperties?.length === 0) && (
                      <Row className='qas-require-properties__none-data'>
                        <DragOutlined className='qas-require-properties__none-data-icon' />
                        <Typography.Title level={5}>
                          Drag Attributes to this area for required properties
                        </Typography.Title>
                      </Row>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </SectionWrapper>
          </Col>
        </Row>
      </DragDropContext>
    </Skeleton>
  );
};

export default memo(SectionQasRequireField);
