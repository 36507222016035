import React, { useState, useMemo, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import reactCSS from 'reactcss';

import { useIntl } from 'react-intl';

import classNames from 'classnames';

import ProductDetailGridItemContainer from './layout/ProductDetailGridItemContainer';
import WithProductDetailExpandBtnfrom, {
  COMPONENT_TYPE,
} from './layout/WithProductDetailExpandBtn';

import ProductHeaderImage from 'common/components/product-header-image/ProductHeaderImage';

import { useTabExpandProductDetail } from 'hooks/useTabExpandProduct';

import {
  Form,
  Divider,
  notification,
  Col,
  Row,
  Drawer,
  Typography,
} from 'antd';

import {
  PackageHeaderDrawer,
  PackageContentDrawer,
  ProductPrimaryInfo,
  ProductOverviewMetadata,
} from 'pages/product-full-view/components';

import { CustomNotification } from 'common/components';

import { ModalForm } from './ShareComponents';
import { NewFormEditProperties } from './FormEditProperties';
import CollapseViewInfo from './CollapseViewInfo';
import ProductCustomProperties from './ProductCustomProperties';
import QueryProperties from './QueryProperties';
import { FormContextProvider, useFormContext } from './FormContext';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { useScrollToFoundModule, useGetProductItemModules } from './hooks';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { useGetProductEnums, useGetProductFullView } from 'hooks';
import { useGetProductItemModulesBrickCode } from 'pages/product-full-view/shared/hooks';

import {
  findProductModule,
  formatRequestParams,
  filterModuleProductSchema,
  filterModuleBrickCode,
  getParamsSyncDataAllergen,
  getParamsSyncDataFoodIngredients,
} from './utils';
import { checkIsOwner } from 'utils/checkIsOwner';

import userSelectors from 'redux/user/selectors';

import * as productFullViewSelectors from 'pages/product-full-view/controllers/selectors';

import { sleep } from 'utils/delay';

import { getNewActivePanels, getSchemaHaveValues } from './utils';

import {
  saveProductItemModules,
  syncFromAllergenModuleToNutritionFact,
  syncFromIngredientModuleToNutritionFact,
} from 'services/product';

import messages from 'i18n/messages/product';

import './index.less';

const PERMISSION_EDIT_PROPERTIES = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.PRODUCT,
  },
];

const ProductDetailView = ({
  product,
  errorInfo,
  loadingSchema,
  getPublishCheckList,
  setReloadOneError,
  productSchema,
}) => {
  const intl = useIntl();

  const [visible, setVisible] = useState(false);

  const { productId, brickCode, ownerId } = product;

  const userId = useSelector(userSelectors.makeSelectUserId());

  const currentHierarchyShortDetails = useSelector(
    productFullViewSelectors.makeSelectCurrentHierarchyShortDetails()
  );
  const productItems = currentHierarchyShortDetails?.data?.productItems;

  const [formInstance] = Form.useForm();

  const { productEnums } = useGetProductEnums();
  const { modulesBrickCode } = useGetProductItemModulesBrickCode(brickCode, {
    enabled: !!brickCode,
  });
  const productItemQuery = useGetProductItemModules(productId, true);

  const { handleRefetchProductFullView } = useGetProductFullView({ productId });

  const { productItemModules, refetchProductItems, statusFetchProductItem } =
    productItemQuery;

  const [editableModal, setEditableModal] = useState(false);
  const [statusSubmit, setStatusSubmit] = useState('idle');
  const [activeKeyPanels, setActivePanels] = useState([]);

  const [editableModule, setEditableModule] = useState({
    moduleName: null,
    moduleDisplayName: null,
  });

  const [queryLeftProperties, setQueryLeftProperties] = useState({
    expandAll: false,
    onlyShowValues: false,
    filteredSchema: [],
  });

  const [queryRightProperties, setQueryRightProperties] = useState({
    search: '',
    expandAll: false,
    onlyShowValues: false,
    filteredSchema: [],
    foundModule: null,
  });

  const { foundModule } = queryRightProperties;

  useScrollToFoundModule({
    foundModule,
    elementByClassName: document.getElementsByClassName('found-module-scroll'),
  });

  const {
    isTabExpanded: isExpanded,
    productHeaderImageSize,
    shrinkTab,
    toggleTabExpand,
  } = useTabExpandProductDetail(false);

  const { leftProductSchema, rightProductSchema } = useMemo(
    () => filterModuleProductSchema(productSchema),
    [productSchema]
  );

  const brickCodeSchema = useMemo(
    () => filterModuleBrickCode(rightProductSchema, modulesBrickCode),
    [modulesBrickCode, rightProductSchema]
  );

  const { nonGroupModule } = useMemo(() => {
    return findProductModule(editableModule?.moduleName, productSchema);
  }, [editableModule?.moduleName, productSchema]);

  const turnOffModal = () => setEditableModal(false);

  const handleCollapseView = (value) => {
    setActivePanels(value);
  };

  const callbackSubmitSuccess = () => {
    turnOffModal();
    notification.success({
      message: 'Update product successfully',
    });
    setTimeout(() => {
      refetchProductItems();
      handleRefetchProductFullView();
    }, 200);
    formInstance.resetFields();
  };

  const callbackSubmitError = (message) => {
    turnOffModal();
    notification.error({
      message,
    });
  };

  const handleSyncAllergenToNutrition = async ({
    id: productId,
    moduleName,
    data,
  }) => {
    if (moduleName === 'AllergenInformation') {
      const params = getParamsSyncDataAllergen(productId, data);

      try {
        const response = await syncFromAllergenModuleToNutritionFact(params);

        if (response?.isSuccess) {
          notification.success({
            message: intl.formatMessage(messages.syncAllergenToNutrition),
          });
        } else {
          notification.error({
            message: response?.message ?? 'Something went wrong!',
          });
        }
      } catch (error) {}
    }
    return;
  };

  const handleSyncFoodIngredientsToNutrition = async ({
    id: productId,
    moduleName,
    data,
  }) => {
    if (moduleName === 'FoodAndBeverageIngredient') {
      const params = getParamsSyncDataFoodIngredients(productId, data);

      try {
        const response = await syncFromIngredientModuleToNutritionFact(params);

        if (response?.isSuccess) {
          notification.success({
            message: intl.formatMessage(messages.syncIngredientToNutrition),
          });
        } else {
          notification.error({
            message: response?.message ?? 'Something went wrong!',
          });
        }
      } catch (error) {}
    }
    return;
  };

  const addPublishError = () => {
    if (errorInfo) {
      formInstance.setFields([
        {
          name: errorInfo?.fieldName,
          errors: [`[Publish Product Error]-${errorInfo?.errorMessage}`],
        },
      ]);
    }
  };

  const handleSubmitForm = async () => {
    formInstance
      .validateFields()
      .then((value) => {
        const data = formatRequestParams(value);

        const params = {
          id: productId,
          moduleName: editableModule.moduleName,
          data,
        };
        setStatusSubmit('loading');

        saveProductItemModules(params).then(async (response) => {
          if (response.isSuccess) {
            setStatusSubmit('success');
            callbackSubmitSuccess();

            if (errorInfo) {
              getPublishCheckList &&
                typeof getPublishCheckList === 'function' &&
                getPublishCheckList();

              if (setReloadOneError) {
                setReloadOneError(true);
                await sleep(1000);
                setReloadOneError(false);
              }
            }
          } else {
            callbackSubmitError(response?.message);
          }
        });

        handleSyncAllergenToNutrition(params);
        handleSyncFoodIngredientsToNutrition(params);
      })
      .catch((error) => {
        if (error?.errorFields) {
          formInstance.scrollToField(error?.errorFields[0].name[0]);
          CustomNotification.error('Please resolve error(s)');
        }
        addPublishError();
      });

    return;
  };

  const handleClickEditBtn = (moduleName, moduleDisplayName) => {
    setEditableModal(true);
    setEditableModule({
      moduleName,
      moduleDisplayName,
    });
  };

  const handleSearchProperties = (optionSearch) => {
    if (!optionSearch) {
      setQueryRightProperties((prevQuery) => ({
        ...prevQuery,
        search: optionSearch.value,
        foundModule: null,
      }));
    }

    if (typeof optionSearch === 'object') {
      setQueryRightProperties((prevQuery) => ({
        ...prevQuery,
        search: optionSearch.value,
        foundModule: optionSearch.moduleName,
      }));

      // prevent duplicate active panel
      if (!activeKeyPanels.includes(optionSearch.moduleName)) {
        setActivePanels((prevPanels) =>
          prevPanels.concat(optionSearch.moduleName)
        );
      }
    }
  };

  const handleExpandAll = (expand, side) => {
    if (side === 'left') {
      const newActivePanels = getNewActivePanels(
        expand,
        leftProductSchema,
        activeKeyPanels
      );
      setActivePanels(newActivePanels);

      setQueryLeftProperties((prevQuery) => ({
        ...prevQuery,
        expandAll: expand,
      }));
    } else {
      const newActivePanels = getNewActivePanels(
        expand,
        brickCodeSchema,
        activeKeyPanels
      );
      setActivePanels(newActivePanels);

      setQueryRightProperties((prevQuery) => ({
        ...prevQuery,
        expandAll: expand,
      }));
    }
  };

  const handleOnlyShowValues = (showValues, side) => {
    if (side === 'left') {
      const newSchema = getSchemaHaveValues(
        showValues,
        productItemModules,
        leftProductSchema
      );

      setQueryLeftProperties((prevQuery) => ({
        ...prevQuery,
        onlyShowValues: showValues,
        filteredSchema: newSchema,
      }));
    } else {
      const newSchema = getSchemaHaveValues(
        showValues,
        productItemModules,
        brickCodeSchema
      );

      setQueryRightProperties((prevQuery) => ({
        ...prevQuery,
        onlyShowValues: showValues,
        filteredSchema: newSchema,
      }));
    }
  };

  useEffect(() => {
    return () => {
      //? turn off expand when leave page
      shrinkTab();
    };
  }, []);

  const isLoading =
    statusFetchProductItem === 'idle' || statusFetchProductItem === 'pending';

  const isLoadingSchema =
    loadingSchema === 'idle' || loadingSchema === 'loading';

  const isOwnerProduct = checkIsOwner(product, userId);
  const hasPermissionEditProperties = useCheckPermissions(
    PERMISSION_EDIT_PROPERTIES
  );

  const productHeaderIndent = `${productHeaderImageSize?.width + 10}px`;

  const styles = reactCSS({
    default: {
      propertyQueryLeft: {
        borderRadius: isExpanded ? 0 : 3,
        background: '#f2f2f2',
        padding: '8px 0 4px 0',
        height: 46,
      },
      propertyQueryRight: {
        borderRadius: isExpanded ? 0 : 3,
        background: !brickCode ? (isExpanded ? '#f2f2f2' : 'none') : '#f2f2f2',
        padding: '8px 0 4px 0',
      },
      productPrimaryWrap: {
        flexFlow: 'nowrap',
      },
      productHeaderImageWrap: {
        width: productHeaderIndent,
        position: 'absolute',
      },
      productPrimary: {
        paddingLeft: isExpanded ? productHeaderIndent : 0,
        transition: 'all 0.3s',
        flex: 1,
      },
    },
  });

  return (
    <>
      <div
        className={classNames('product-detail-view', {
          'product-detail-view--expanded': isExpanded,
        })}
      >
        <ProductDetailGridItemContainer gridItemType='product-primary-info'>
          <Row style={styles.productPrimaryWrap}>
            {isExpanded && (
              <div style={styles.productHeaderImageWrap}>
                <ProductHeaderImage
                  productDetail={product}
                  productHeaderImageSize={productHeaderImageSize}
                  hidden={!isExpanded}
                  toggleTabExpand={toggleTabExpand}
                />
              </div>
            )}
            <Col
              id='product-detail-primary-container'
              style={styles.productPrimary}
            >
              <ProductPrimaryInfo product={product} />
            </Col>
          </Row>
          <Divider style={{ margin: '0px 0px' }} />
        </ProductDetailGridItemContainer>

        <ProductDetailGridItemContainer gridItemType='product-meta'>
          <WithProductDetailExpandBtnfrom
            componentType={COMPONENT_TYPE.productMeta}
          >
            <ProductOverviewMetadata
              product={product}
              productItemModules={productItemModules}
              productSchema={productSchema}
              productEnums={productEnums}
              showPackageDrawer={() => setVisible(true)}
            />
          </WithProductDetailExpandBtnfrom>
          <Divider style={{ margin: '4px 0px' }} />
        </ProductDetailGridItemContainer>

        <ProductDetailGridItemContainer
          gridItemType='query-property-left'
          style={styles.propertyQueryLeft}
        >
          <QueryProperties
            sideProperties='left'
            onSearch={handleSearchProperties}
            onExpandAll={handleExpandAll}
            onOnlyShowValues={handleOnlyShowValues}
          />
        </ProductDetailGridItemContainer>

        <ProductDetailGridItemContainer
          gridItemType='product-detail-left-module'
          style={{
            padding: '8px 0',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <div className='scroller'>
            <CollapseViewInfo
              isLoading={isLoading || isLoadingSchema}
              isOwnerProduct={isOwnerProduct}
              hasPermissionEditProperties={hasPermissionEditProperties}
              productSchema={
                queryLeftProperties?.onlyShowValues
                  ? queryLeftProperties?.filteredSchema
                  : leftProductSchema
              }
              productItemModules={productItemModules}
              activeKeyPanels={activeKeyPanels}
              handleClickEditBtn={handleClickEditBtn}
              handleCollapseView={handleCollapseView}
              errorInfo={errorInfo}
              productEnums={productEnums}
              productId={productId}
            />
            {!isLoadingSchema && (
              <ProductCustomProperties
                productId={productId}
                ownerId={ownerId}
                isOwnerProduct={isOwnerProduct}
                hasPermissionEditProperties={hasPermissionEditProperties}
                queryLeftProperties={queryLeftProperties}
              />
            )}
          </div>
        </ProductDetailGridItemContainer>
        <ProductDetailGridItemContainer
          gridItemType='query-property-right'
          style={styles.propertyQueryRight}
        >
          <WithProductDetailExpandBtnfrom
            componentType={COMPONENT_TYPE.queryPropertyRight}
          >
            <QueryProperties
              showSearch={!!brickCode}
              showCheckbox={!!brickCode}
              sideProperties='right'
              productSchema={brickCodeSchema}
              onSearch={handleSearchProperties}
              onExpandAll={handleExpandAll}
              onOnlyShowValues={handleOnlyShowValues}
              isExpanded={isExpanded}
            />
          </WithProductDetailExpandBtnfrom>
        </ProductDetailGridItemContainer>

        <ProductDetailGridItemContainer
          gridItemType='product-brickcode-detail'
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {brickCode ? (
            <>
              <div className='scroller' style={{ marginTop: 5 }}>
                <CollapseViewInfo
                  isLoading={isLoading || isLoadingSchema}
                  isOwnerProduct={isOwnerProduct}
                  hasPermissionEditProperties={hasPermissionEditProperties}
                  productSchema={
                    queryRightProperties?.onlyShowValues
                      ? queryRightProperties?.filteredSchema
                      : brickCodeSchema
                  }
                  productItemModules={productItemModules}
                  activeKeyPanels={activeKeyPanels}
                  handleClickEditBtn={handleClickEditBtn}
                  handleCollapseView={handleCollapseView}
                  productEnums={productEnums}
                  errorInfo={errorInfo}
                  foundModuleName={queryRightProperties?.foundModule}
                  productId={productId}
                />
              </div>
            </>
          ) : null}
        </ProductDetailGridItemContainer>
        {isExpanded && (
          <ProductDetailGridItemContainer
            gridItemType='product-primary-info-space'
            className='product-primary-info-space'
          />
        )}
      </div>

      {editableModal && (
        <FormContextProvider>
          <ModalEditForm
            editableModal={editableModal}
            turnOffModal={turnOffModal}
            handleSubmitForm={handleSubmitForm}
            editableModule={editableModule}
            statusSubmit={statusSubmit}
            nonGroupModule={nonGroupModule}
          >
            <NewFormEditProperties
              productSchema={productSchema ?? []}
              editableModuleName={editableModule.moduleName}
              formInstance={formInstance}
              productEnums={productEnums}
              productItemModules={productItemModules}
              errorInfo={errorInfo}
            />
          </ModalEditForm>
        </FormContextProvider>
      )}

      {visible && (
        <PackageContent
          detail={currentHierarchyShortDetails?.data}
          onClose={() => setVisible(false)}
          visible={visible}
          productItems={productItems}
        />
      )}
    </>
  );
};

const widthDrawer = 845;

const ModalEditForm = ({
  editableModal,
  turnOffModal,
  handleSubmitForm,
  editableModule,
  statusSubmit,
  nonGroupModule,
  children,
}) => {
  const { handleSearch } = useFormContext();

  const handleSearchProperties = (optionSearch) => {
    if (!optionSearch) {
      handleSearch(null);
    }

    if (typeof optionSearch === 'object') {
      handleSearch(optionSearch.value);
    }
  };

  return (
    <ModalForm
      visible={editableModal}
      onCancel={turnOffModal}
      onOk={handleSubmitForm}
      title={
        <Row justify='space-between'>
          <Col span={10}>
            <Typography.Text strong>
              Edit Properties Of {editableModule.moduleDisplayName}
            </Typography.Text>
          </Col>
          <Col span={14} style={{ paddingRight: 24 }}>
            <QueryProperties
              isEdit
              showSearch
              showCheckbox={false}
              productSchema={nonGroupModule}
              onSearch={handleSearchProperties}
            />
          </Col>
        </Row>
      }
      className='product-detail-view__modal'
      okButtonProps={{
        loading: statusSubmit === 'loading',
      }}
    >
      {children}
    </ModalForm>
  );
};

const PackageContent = ({ detail, onClose, visible, productItems }) => {
  return (
    <Drawer
      title={<PackageHeaderDrawer detail={detail} />}
      placement='right'
      onClose={onClose}
      visible={visible}
      key='right'
      mask={false}
      getContainer={false}
      style={{
        height: 'calc(100vh - 306px)',
        position: 'fixed',
      }}
      width={widthDrawer}
      closable
    >
      <PackageContentDrawer productItems={productItems} />
    </Drawer>
  );
};

export default ProductDetailView;
