import React from 'react';
import { Empty } from 'antd';

import './styles.less';

const RoleEmptySection = () => (
  <Empty className='role-empty-section' image={Empty.PRESENTED_IMAGE_SIMPLE} />
);

export default RoleEmptySection;
