import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { ProfileOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

const ShowDetails = ({ handleClick }) => {
  return (
    <RibbonButton
      icon={<ProfileOutlined style={{ height: 26 }} />}
      label={Messages.detailsLayout}
      onClick={handleClick}
    />
  );
};

ShowDetails.propTypes = { handleClick: PropTypes.func };

export default ShowDetails;
