import { Typography } from 'antd';

const FormLabel = ({ label }) => {
  return <Typography.Text strong>{label && label}</Typography.Text>;
};

const SnapshotFormLabel = ({ label, snapshotFieldValue }) => {
  return (
    <span>
      <FormLabel label={label} />:<span> &nbsp;{snapshotFieldValue}</span>
    </span>
  );
};

export { FormLabel, SnapshotFormLabel };
