import React from 'react';
import { Button } from 'antd';
import { FileFilled } from '@ant-design/icons';

const RenderBtnUploadFile = (props) => {
  const {
    property,
    possibleOperator,
    handleUploadAdvancedFile,
    handleOpenModalUploadFile,
  } = props;

  const hasAcceptableValue = property?.acceptableValueTemp?.length > 0;
  return (
    <div style={{ marginRight: 4 }}>
      {property?.dataType === 'string' ? (
        <Button
          type={hasAcceptableValue ? 'primary' : 'default'}
          disabled={possibleOperator?.length === 1}
          icon={<FileFilled />}
          onClick={() => {
            if (!hasAcceptableValue) {
              handleOpenModalUploadFile();
            } else {
              handleUploadAdvancedFile(null, property?.valuePath, 'value');
            }
          }}
        />
      ) : (
        <div style={{ width: 26 }}></div>
      )}
    </div>
  );
};

export default RenderBtnUploadFile;
