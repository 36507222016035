import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as globalSelectors from 'redux/global/selectors';
import * as globalActions from 'redux/global/actions';

import { MEMBER_LAYOUT, ROUTE } from 'static/Constants';

export const useUpdateMemberAdditionalRequirements = (props = {}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const errorView = query.get('view');

  const openErrorUrl = ({ fieldName, entityId }) => {
    //* update member tab view
    const memberAdditionalRequirements = {
      member_PrimaryContact: MEMBER_LAYOUT.CONTACTS,
      member_PrimaryAddress: MEMBER_LAYOUT.CONTACTS,
      member_MemberLogo: MEMBER_LAYOUT.OVERVIEW,
      member_MemberBrands: MEMBER_LAYOUT.DETAILS,
      member_Glns: MEMBER_LAYOUT.DETAILS,
    };

    const shouldOpen = Object.keys(memberAdditionalRequirements).includes(
      fieldName
    );

    if (!shouldOpen) return;

    const errorParams = {
      view: memberAdditionalRequirements[fieldName],
    };

    const errorQueryString = new URLSearchParams(errorParams).toString();

    const url =
      window.location.origin +
      ROUTE.MEMBER_MAINTENANCE +
      `/${entityId}?${errorQueryString}`;

    window.open(url);
  };

  return {
    openErrorUrl,
    errorView,
  };
};
