import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from './actions';

const useGetThreadAndMsgInfo = (props) => {
  const { threadId, userId } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState([]);

  useEffect(() => {
    if (threadId) {
      const params = { threadId, userId };
      dispatch(actions.getEmailThreadInfo(params));
      dispatch(actions.getEmailThreadInfoContent(threadId));
      // dispatch(actions.getEmailThreadInfoFiles(props));
    }
  }, [threadId]);

  return;
};

export default useGetThreadAndMsgInfo;
