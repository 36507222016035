import React from 'react';
import { useGetBasicUomEnum } from 'hooks';

import { getUomTypesAbbreviations } from '../constants';
import LinearVitaminLabel from 'pages/product-full-view/components/product-media-area/nutrition-label/components/LinearVitaminLabel';
import { HighlightTextLinear } from '../WrapperNutrient';

const highlightLabelName = [
  'FAT',
  'CHOLESTEROL',
  'NA',
  'CHOLESTEROL',
  'CARBOHYDRATES',
  'PROTEIN',
  'FD',
];

function LinearLabel({ property, nutrients, snapshotValue }) {
  const { basicUomProperties } = useGetBasicUomEnum();

  const checkHighlightName = (typeCode) =>
    highlightLabelName.includes(typeCode);

  return (
    <div className='nutrition-facts__label--linear'>
      <span className='nutrition-facts__label--linear-label'>
        Nutrition Facts
      </span>
      {property?.servingsPerContainer ? (
        <HighlightTextLinear
          label={['servingsPerContainer']}
          currentValue={property}
          snapshotValue={snapshotValue}
        >
          <span>Servings: {property?.servingsPerContainer},</span>
        </HighlightTextLinear>
      ) : (
        <span>,</span>
      )}
      <HighlightTextLinear
        label={[
          'servingSizeDescription',
          'servingSize',
          'servingSizeMeasurement',
        ]}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        <span className='nutrition-facts__label--linear__servingsize-bold'>
          Serv. Size:{' '}
        </span>
        <span className='nutrition-facts__label--linear__servingsize-bold'>
          {property?.servingSizeDescription}{' '}
          {property?.servingSize &&
            `(${property?.servingSize} ${getUomTypesAbbreviations(
              basicUomProperties,
              property?.servingSizeMeasurement
            )}),`}
        </span>
      </HighlightTextLinear>
      <HighlightTextLinear
        label={['calories']}
        currentValue={property}
        snapshotValue={snapshotValue}
      >
        <span>Amount Per Serving:</span>
        <span className='nutrition-facts__label--linear__servingsize-bold'>
          {`Calories ${property?.calories ? property?.calories : 0},`}
        </span>
      </HighlightTextLinear>
      <>
        {nutrients?.map((ingredient, idx) => (
          <LinearVitaminLabel
            key={idx}
            labelBold={checkHighlightName(ingredient?.nutritionTypeCode)}
            ingredient={ingredient}
          />
        ))}
      </>
    </div>
  );
}

export default LinearLabel;
