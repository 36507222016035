import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import Messages from 'i18n/messages/apl';
import { EditOutlined } from '@ant-design/icons';
import { RibbonButton } from 'common/components';

import ProductContactModal from 'pages/product-apl-detail/components/product-contact-modal/ProductContactModal';
import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';

const EditProductContact = (props) => {
  const { productList } = props;
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const aplDetail = useSelector(makeSelectAplDetail());

  const editProductContact = () => {
    setVisible(true);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      <RibbonButton
        size='small'
        icon={<EditOutlined />}
        label={Messages.editContact}
        onClick={editProductContact}
        disabled={productList?.length !== 1 || !aplDetail?.isOwner}
        style={{ backgroundColor: 'inherit' }}
      />
      {visible && (
        <ProductContactModal
          visible={visible}
          productList={productList}
          onCancel={onCancel}
          form={form}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

export default EditProductContact;
