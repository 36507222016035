import * as types from './constants';

// FLOW SSO PRODUCT
export const bpSsoProductValidate = (payload) => ({
  type: types.BP_SSO_PRODUCT_VALIDATE,
  payload,
});
export const bpSsoProductValidateDetail = (payload) => ({
  type: types.BP_SSO_PRODUCT_VALIDATE_DETAIL,
  payload,
});
export const bpSsoProductUpdateData = (payload) => ({
  type: types.BP_SSO_PRODUCT_UPDATE_DATA,
  payload,
});
// CREATE SSO PRODUCT
export const createSsoProduct = (params) => ({
  type: types.BP_SSO_CREATE_NEW_PRODUCT,
  params,
});
export const createSsoProductSuccess = (payload) => ({
  type: types.BP_SSO_CREATE_NEW_PRODUCT_SUCCESS,
  payload,
});
export const createSsoProductError = (payload) => ({
  type: types.BP_SSO_CREATE_NEW_PRODUCT_ERROR,
  payload,
});
export const redirectPage = (payload) => ({
  type: types.BP_SSO_REDIRECT_PAGE,
  payload,
});

// GET SSO PRODUCT
export const getSsoProduct = (payload) => ({
  type: types.BP_SSO_GET_PRODUCT,
  payload,
});
export const getSsoProductSuccess = (payload) => ({
  type: types.BP_SSO_GET_PRODUCT_SUCCESS,
  payload,
});
export const getSsoProductError = (payload) => ({
  type: types.BP_SSO_GET_PRODUCT_ERROR,
  payload,
});
// UPDATE SSO PRODUCT
export const updateSsoProduct = (payload) => ({
  type: types.BP_SSO_UPDATE_PRODUCT,
  payload,
});
export const updateSsoProductSuccess = (payload) => ({
  type: types.BP_SSO_UPDATE_PRODUCT_SUCCESS,
  payload,
});
export const updateSsoProductError = (payload) => ({
  type: types.BP_SSO_UPDATE_PRODUCT_ERROR,
  payload,
});
// GET SSO BP ADD PRODUCT INFO
export const getSsoProductInfo = (payload) => ({
  type: types.BP_SSO_GET_PRODUCT_INFO,
  payload,
});
export const getSsoProductInfoSuccess = (payload) => ({
  type: types.BP_SSO_GET_PRODUCT_INFO_SUCCESS,
  payload,
});
export const getSsoProductInfoError = (payload) => ({
  type: types.BP_SSO_GET_PRODUCT_INFO_ERROR,
  payload,
});
