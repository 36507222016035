import { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

const useClickOuterMultipleDrag = (handleClickOutside, eleId, options) => {
  const refWrapper = useRef();

  const disableClickouter = options?.disableClickouter;

  useEffect(
    (handler = handleClickOutside, stopClickOuter = disableClickouter) => {
      function handleClickOutside(event) {
        if (!refWrapper.current) {
          refWrapper.current = document.getElementById(eleId);
        }
        const $target = event.target;

        const $wrapper = refWrapper.current;
        if ($wrapper && !$wrapper.contains($target)) {
          if (eleId) {
            const currentId = document.body.dataset.outerClick;
            if (currentId !== eleId) {
              return;
            }
          }
          !stopClickOuter && handler && handler();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    [handleClickOutside, eleId, options]
  );
};

const selectedHookDefine = {
  id: [null, PropTypes.string],
  element: [null, PropTypes.element],
};

const selectedHookDefault = {
  id: selectedHookDefine.id[0],
  element: selectedHookDefine.element[0],
};

const useClickOuterSelectedDrag = () => {
  const selectedHook = useState(selectedHookDefault);

  const [selected, setSelected] = selectedHook;

  const makeSelect = useCallback((callback, dataset = undefined) => {
    if (typeof callback === 'function') {
      setSelected(callback);
      document.body.dataset.outerClick = dataset?.id;
    } else {
      setSelected(callback);
      document.body.dataset.outerClick = callback?.id;
    }
  }, []);

  const clearSelect = useCallback(() => {
    setSelected(selectedHookDefault);
    document.body.dataset.outerClick = '';
  }, []);

  return [selected, makeSelect, clearSelect];
};

const type = {
  selected: selectedHookDefine,
  makeSelect: PropTypes.func,
  clearSelect: PropTypes.func,
};

export {
  useClickOuterMultipleDrag,
  useClickOuterSelectedDrag,
  type as useClickOuterMultipleType,
};
