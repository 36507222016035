import React, { useMemo } from 'react';
import { Col } from 'antd';

const ItemButton = (props) => {
  const { noneSpan, isVertical, style = {}, ...restProps } = props;

  const spanConfig = useMemo(() => {
    return noneSpan ? {} : { span: 12 };
  }, [noneSpan]);

  return (
    <Col
      {...spanConfig}
      style={
        isVertical
          ? { display: 'flex', flexDirection: 'column', ...style }
          : style
      }
      {...restProps}
    />
  );
};

export default ItemButton;
