import { useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import * as productServices from 'services/product/index';
import { createAllergenColDef } from 'pages/qa-spec/utils/gridColumnUtils';
import { useAsync } from 'hooks';

import { validateAllergenGrid } from 'pages/qa-spec/utils/allergen';
import useCheckSnapshotForRetailer from './useCheckSnapshotForRetailer';

export const useGetAllergenGridHandlers = (gridInst) => {
  const currentEditCell = useRef();

  const onCellEditingStopped = (event) => {
    if (!event) return;

    const colId = event.column.colId;

    let rowData = event.data;

    let nextDataItem = { ...rowData };

    if (colId === 'isPresent') {
      if (rowData?.isPresent === false) {
        nextDataItem = {
          ...nextDataItem,
          levelOfContainment: null,
          containmentSource: null,
        };
        gridInst.current.api.applyTransaction({
          update: [nextDataItem],
        });

        validateAllergenGrid(gridInst).validateLevelOfContainmentRequired(
          event
        );
      } else {
        validateAllergenGrid(gridInst).validateLevelOfContainmentRequired(
          event
        );
      }
    }

    if (colId === 'levelOfContainment') {
      const newValue = event.value;

      if (newValue !== 'May Contain') {
        nextDataItem = { ...nextDataItem, containmentSource: null };
        gridInst.current.api.applyTransaction({
          update: [nextDataItem],
        });
      } else {
      }

      validateAllergenGrid(gridInst).validateLevelOfContainmentRequired(event);

      validateAllergenGrid(gridInst).validateContainmentSourceRequired(event);
    }

    if (colId === 'containmentSource') {
      const newValue = event.value;

      if (newValue !== 'May Contain') {
        nextDataItem = { ...nextDataItem, ConstantSourceNode: null };
        gridInst.current.api.applyTransaction({
          update: [nextDataItem],
        });
      } else {
      }

      validateAllergenGrid(gridInst).validateLevelOfContainmentRequired(event);
      validateAllergenGrid(gridInst).validateContainmentSourceRequired(event);
    }
  };

  const onCellEditingStarted = (e) => {
    currentEditCell.current = e;
  };

  return {
    onCellEditingStopped,
    onCellEditingStarted,
    currentEditCell,
  };
};

export const useGetAllergenColDefs = (isEdit, ingredientOptions) => {
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const allergenColDef = useMemo(() => {
    return createAllergenColDef(isEdit, ingredientOptions, isToggleSnapshot);
  }, [isEdit, ingredientOptions, isToggleSnapshot]);

  return allergenColDef;
};

export const useGetAllergenData = () => {
  const { data, setData, run, status } = useAsync();
  const { id: productId } = useParams();

  const fetchAllergenData = useCallback(
    (productId) => {
      run(productServices.getQaProductAllergen({ productId }));
    },
    [run]
  );

  useEffect(() => {
    if (productId) {
      fetchAllergenData(productId);
    }
    return;
  }, [productId, run, fetchAllergenData]);

  return {
    data,
    setData,
    fetchAllergenData,
    isLoadingGrid: status === 'idle' || status === 'pending',
  };
};
