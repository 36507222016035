import React from 'react';
import { Typography, Space, Row, Tag } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/folder';
import { formatSizeUnits } from 'utils/formatSizeUnits';

const { Paragraph, Title, Text } = Typography;

const QueryTileBody = (props) => {
  const { dataDetail } = props;

  const name = get(dataDetail, 'name', '');
  // const folderSize = get(dataDetail, 'folderSize', 0);
  // const productCount = get(dataDetail, 'productCount', 0);
  // const assetCount = get(dataDetail, 'assetCount', 0);
  const ownerName = get(dataDetail, 'ownerFullName', '');
  // const memberName = get(dataDetail, 'memberName', '');

  const infoText = [
    {
      field: 'name',
      value: name,
    },
    {
      field: 'ownerName',
      value: ownerName,
    },
    // {
    //   field: 'memberName',
    //   value: memberName,
    // },
    // {
    //   field: 'folderSize',
    //   value: folderSize,
    // },
    // {
    //   field: 'productCount',
    //   value: productCount,
    // },
    // {
    //   field: 'assetCount',
    //   value: assetCount,
    // },
  ];

  const renderQueryBodyInfo = (infoText) => {
    return infoText.map(({ field, value }, index) => {
      if (field === 'name') {
        return (
          <Title key={index} className='query-tile__body--title'>
            {value}
          </Title>
        );
      } else if (field === 'folderSize') {
        return (
          <Paragraph
            key={field}
            className={`query-tile__body--element ${field}`}
          >
            {formatSizeUnits(value)}
          </Paragraph>
        );
      } else if (field === 'productCount' || field === 'assetCount') {
        let text;
        if (field === 'productCount') {
          text =
            value > 0 ? (
              <FormattedMessage {...Messages.products} />
            ) : (
              <FormattedMessage {...Messages.product} />
            );
        } else {
          text =
            value > 0 ? (
              <FormattedMessage {...Messages.assets} />
            ) : (
              <FormattedMessage {...Messages.asset} />
            );
        }
        return value > 0 ? (
          <Paragraph
            key={field}
            className={`query-tile__body--element ${field}`}
          >
            {value}&nbsp;{text}
          </Paragraph>
        ) : null;
      } else {
        return (
          value && (
            <Paragraph
              key={field}
              className={`query-tile__body--element ${field}`}
            >
              {value}
            </Paragraph>
          )
        );
      }
    });
  };

  return (
    <div className='query-tile__body'>
      <Row justify='center' className='query-tile__body--info-row'>
        <Space direction='vertical' size={0}>
          {renderQueryBodyInfo(infoText)}
        </Space>
      </Row>
    </div>
  );
};

QueryTileBody.propTypes = {
  dataDetail: PropTypes.object,
};

export default QueryTileBody;
