import React from 'react';
import { Switch } from 'antd';

import './SwitchButton.less';

const SwitchButton = (props) => {
  return <Switch className='styled-switch-btn' {...props} />;
};

export default SwitchButton;
