import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Menu, Dropdown } from 'antd';

import {
  SecurityScanOutlined,
  ClearOutlined,
  ProjectOutlined,
  SafetyOutlined,
} from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { WithAbsoluteContainer } from 'common/components';
import AddRoleToUserModal from '../../modal/user-management/AddRoleToUserModal';
import RemoveRoleFromUserModal from '../../modal/user-management/RemoveRoleFromUserModal';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';
import { useIntl } from 'react-intl';

const iconStyle = { fontSize: 18 };

const UserEditRoleSecuritySection = (props) => {
  const intl = useIntl();

  const [isAddRoleToMemberModalOpen, setIsAddRoleToMemberModalOpen] =
    useState(false);
  const [visibleRemoveRoleModal, setVisibleRemoveRoleModal] = useState(false);

  const selectedUsers = useSelector(
    gridViewSelectors.makeSelectItemsSelection()
  );

  const selectedDetailUser = useSelector(
    gridViewSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const toggleAddRoleToUserModal = () => {
    setIsAddRoleToMemberModalOpen((prev) => !prev);
  };

  const toggleRemoveRoleToUserModal = () => {
    setVisibleRemoveRoleModal((prev) => !prev);
  };

  const onClickRemoveRoleFromUser = () => {
    setVisibleRemoveRoleModal(true);
  };

  const openRoleSecurityView = () => {
    const userInfo = selectedDetailUser?.[0];
    const memberId = userInfo?.memberId;
    forwardTo(`/security/member/${memberId}`);
  };

  const disabledSection = selectedUsers?.length !== 1;

  const menuOptions = (
    <Menu className='member-communicate-section__menu'>
      <Menu.Item
        key='open-user-role-management'
        icon={<ProjectOutlined style={iconStyle} />}
        onClick={openRoleSecurityView}
      >
        {intl.formatMessage(Messages.manageUserRole)}
      </Menu.Item>
      <Menu.Item
        key='remove-role-form-user'
        icon={<ClearOutlined style={iconStyle} />}
        onClick={onClickRemoveRoleFromUser}
      >
        {intl.formatMessage(Messages.removeUserRole)}
      </Menu.Item>
      <Menu.Item
        key='add-role-to-user'
        icon={<SafetyOutlined style={iconStyle} />}
        onClick={toggleAddRoleToUserModal}
      >
        {intl.formatMessage(Messages.addUserRole)}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuOptions} arrow={false} disabled={disabledSection}>
        <RibbonButton
          icon={<SecurityScanOutlined style={{ height: 26 }} />}
          label={'Role & security'}
          disabled={disabledSection}
        />
      </Dropdown>
      <WithAbsoluteContainer>
        <AddRoleToUserModal
          isVisible={isAddRoleToMemberModalOpen}
          toggleModal={toggleAddRoleToUserModal}
          selectedUser={selectedDetailUser[0]}
        />
      </WithAbsoluteContainer>
      <WithAbsoluteContainer>
        <RemoveRoleFromUserModal
          isVisible={visibleRemoveRoleModal}
          toggleModal={toggleRemoveRoleToUserModal}
          selectedUser={selectedDetailUser[0]}
        />
      </WithAbsoluteContainer>
    </>
  );
};

export default UserEditRoleSecuritySection;
