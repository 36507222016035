import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Space, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import { ThumbnailItem } from 'common/components';

import {
  ACCEPT_UPLOAD_IMAGE_FILE_TYPE,
  BROWSER_NOT_SUPPORTED_IMAGE_TYPES,
} from 'static/Constants';
import { detectBrowser } from 'utils/browser';

import Messages from 'i18n/messages/assetPreview';
import { FormattedMessage } from 'react-intl';

const GeneratePreviewItem = (props) => {
  const {
    selected,
    item,
    handleToggleSelectItem,
    updateRootData,
    revertRootData,
    revertable,
  } = props;
  const { data, assetId, assetName } = item;

  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  // function
  const handleClickUploadButton = (info) => {
    let imageFile = get(info, 'file.originFileObj', false);

    // parse to base64
    const fileReader = new FileReader();
    fileReader.onloadstart = () => {
      // show loading
      setImageError(false);
      setImageLoading(true);
    };
    fileReader.onloadend = (e) => {
      const imageSrc = e.target.result;
      const image = new Image();
      image.src = imageSrc;

      updateRootData(assetId, imageSrc);

      image.onload = onImageLoadHandler;

      image.onerror = onImageErrorHandler(imageFile);
    };
    fileReader.readAsDataURL(imageFile);
  };

  const onImageLoadHandler = () => {
    setImageLoading(false);
  };

  const onImageErrorHandler = (imageFile) => () => {
    setImageErrorMessage(imageFile);
    setImageLoading(false);
  };

  const setImageErrorMessage = (imageFile) => {
    const fileType = imageFile.type;
    const browser = detectBrowser();
    const notSupportedImageType =
      browser && BROWSER_NOT_SUPPORTED_IMAGE_TYPES[browser];

    let errorMessage = Messages.generateLoadImageError;
    if (browser && notSupportedImageType?.includes(fileType)) {
      errorMessage = Messages.generateLoadImageBrowserError;
    }

    setImageError(errorMessage);
  };

  const handleClickRevertButton = () => {
    setImageError(false);
    revertRootData(assetId);
  };
  // variable
  const classNamePrefix = 'generate-preview__item';
  return (
    <Space direction='vertical' className={`${classNamePrefix}-container`}>
      <ThumbnailItem
        labelThumbnail={assetName}
        imageThumbnail={data}
        selected={selected}
        onClickCheckboxItem={handleToggleSelectItem(assetId)}
        errorMessage={imageError}
        loading={imageLoading}
      />
      <Space className={`${classNamePrefix}-action`}>
        <Upload
          onChange={handleClickUploadButton}
          accept={ACCEPT_UPLOAD_IMAGE_FILE_TYPE}
          showUploadList={false}
          customRequest={() => {}}
        >
          <Button icon={<UploadOutlined />} type='primary'>
            {' '}
            <FormattedMessage {...Messages.generatePreviewItemUploadBtn} />
          </Button>
        </Upload>
        <Button onClick={handleClickRevertButton} disabled={!revertable}>
          <FormattedMessage {...Messages.generatePreviewItemRevertBtn} />
        </Button>
      </Space>
    </Space>
  );
};

GeneratePreviewItem.propTypes = {
  /**
   * is selected item
   */
  selected: PropTypes.bool,
  /**
   * item data
   */
  data: PropTypes.object,
  /**
   * handle toggle select item
   */
  handleToggleSelectItem: PropTypes.func,
  /**
   * handle update root data when item update new image
   */
  updateRootData: PropTypes.func,
  /**
   * handle update root data when item revert image
   */ revertRootData: PropTypes.func,
  /**
   * enable revert button
   */
  revertable: PropTypes.bool,
};

export default GeneratePreviewItem;
