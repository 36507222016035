import React, { useState, useEffect, useRef } from 'react';

import { Modal, Input } from 'antd';

import { Form } from 'common/components';

import { apiHandler } from 'utils/api';
import { cloneMappingGridConfigService } from 'services/mapping';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/mapping';

const CloneMappingConfigModal = (props) => {
  const intl = useIntl();
  const inputRef = useRef(null);

  const reloadPage = useDispatchReloadPage();
  const [form] = Form.useForm();

  const { selectedMapping, visible, setVisible } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onOk = () => {
    callApiCloneMapping();
  };

  const onCancel = () => {
    setVisible(false);
  };

  const successCallback = () => {
    setVisible(false);
    reloadPage();
  };

  const onFinally = () => {
    setSubmitLoading(false);
  };

  const onValuesChange = (_, allValues) => {
    setSubmitDisabled(allValues?.mappingName ? false : true);
  };

  const callApiCloneMapping = async () => {
    setSubmitLoading(true);

    const params = await form.validateFields();
    if (!params.mappingName) return;

    const successMessage = intl.formatMessage(Messages.cloneSuccess);
    const errorMessage = intl.formatMessage(Messages.cloneError);

    apiHandler({
      service: cloneMappingGridConfigService,
      params: { ...params, mappingId: selectedMapping[0]?.id },
      successMessage,
      errorMessage,
      successCallback,
      onFinally,
    });
  };

  const resetForm = () => {
    if (visible) form.resetFields();
  };

  const focusOnInput = () => {
    if (visible)
      setTimeout(() => {
        const input = inputRef.current;
        input.focus();
        input.select();
      }, 100);
  };

  useEffect(() => {
    form.setFieldsValue({ mappingName: selectedMapping[0]?.mappingName });
  }, [visible]);

  useEffect(() => {
    focusOnInput();
  }, [visible]);

  return (
    <Modal
      layout='vertical'
      visible={visible}
      destroyOnClose
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ loading: submitLoading, disabled: submitDisabled }}
    >
      <Form form={form} layout='vertical' onValuesChange={onValuesChange}>
        <Form.Item
          label={intl.formatMessage(Messages.mappingName)}
          name='mappingName'
          rules={[
            {
              required: true,
              message: intl.formatMessage(Messages.mappingNameIsRequired),
            },
          ]}
        >
          <Input ref={inputRef} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CloneMappingConfigModal;
