import { Typography } from 'antd';
import classNames from 'classnames';
const { Text } = Typography;

const DrugFactHeading = ({
  heading,
  styles,
  headingDescription = null,
  className,
  ...otherProps
}) => {
  return (
    <Text
      className={classNames({
        'drug-fact__heading': true,
        [className]: !!className,
      })}
      style={styles}
      {...otherProps}
    >
      {heading} {headingDescription ? `(${headingDescription})` : null}
    </Text>
  );
};

export default DrugFactHeading;
