import React from 'react';
import { useDispatch } from 'react-redux';

import { List, Button } from 'antd';

import * as globalActions from 'redux/global/actions';

import { forwardTo } from 'utils/common/route';
import { formatMDY } from 'utils/formatDate';

const WorkflowAlertItem = (props) => {
  const dispatch = useDispatch();
  const { item, viewAlert } = props;

  const onClickView = (e) => {
    e.stopPropagation();
    viewAlert && viewAlert(item?.id, onViewSuccess);
  };

  const onViewSuccess = () => {
    dispatch(globalActions.changeHierarchyAlertId(item?.hierarchyId));
    forwardTo(`/product/${item?.productId}`);
  };

  return (
    <List.Item
      className='rivir-alert__popover-item'
      extra={
        <div>
          <Button
            style={{ borderRadius: '4px' }}
            onClick={onClickView}
            type='primary'
          >
            View
          </Button>
        </div>
      }
    >
      <List.Item.Meta
        title={`Product ${item?.productGln ? item?.productGln + ' - ' : ''}  ${
          item?.productName || ''
        } has been received`}
        description={formatMDY(item?.created)}
      />
    </List.Item>
  );
};

export default WorkflowAlertItem;
