// Action Types
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_MEMBER_PRODUCT_LIST = 'GET_MEMBER_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR';
export const PRODUCT_GRID_COLUMN_INFO = 'PRODUCT_GRID_COLUMN_INFO';
export const PRODUCT_GRID_COLUMN_INFO_SUCCESS =
  'PRODUCT_GRID_COLUMN_INFO_SUCCESS';
export const PRODUCT_GRID_COLUMN_INFO_ERROR = 'PRODUCT_GRID_COLUMN_INFO_ERROR';

export const TOGGLE_RELOAD_GRID_PRODUCT = 'TOGGLE_RELOAD_GRID_PRODUCT';
export const SAVE_GRID_CONFIG = 'SAVE_GRID_CONFIG';
export const APPLY_GRID_CONFIG = 'APPLY_GRID_CONFIG';
// Add Product via Form
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';

// Get Brand Image on Add
export const GET_PRODUCT_BRAND_ON_ADD = 'GET_PRODUCT_BRAND_ON_ADD';
export const GET_PRODUCT_BRAND_ON_ADD_SUCCESS =
  'GET_PRODUCT_BRAND_ON_ADD_SUCCESS';
export const GET_PRODUCT_BRAND_ON_ADD_ERROR = 'GET_PRODUCT_BRAND_ON_ADD_ERROR';
export const TOGGLE_PRODUCT_NEW = 'TOGGLE_PRODUCT_NEW';

// GDSN Party List
export const GET_GDSN_PARTY_LIST = 'GET_GDSN_PARTY_LIST';
export const GET_GDSN_PARTY_LIST_SUCCESS = 'GET_GDSN_PARTY_LIST_SUCCESS';
export const GET_GDSN_PARTY_LIST_FAILURE = 'GET_GDSN_PARTY_LIST_FAILURE';
export const TOGGLE_PRODUCT_UNMATCH = 'TOGGLE_PRODUCT_UNMATCH';
