import React from 'react';
import { useDispatch } from 'react-redux';

import { StyledModal } from 'common/components';

import * as chatActions from '../../controller/actions';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/chat';

const NewChatWithCompanyModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { visible } = props;

  const onCancel = () => {
    dispatch(chatActions.showCreateNewChatWithCompany(false));
  };

  const modalProps = {
    visible,
    title: intl.formatMessage(Messages.createNewChatCompany),
    onCancel,
  };

  return <StyledModal {...modalProps}></StyledModal>;
};

export default NewChatWithCompanyModal;
