import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';

import { parseNumber } from 'utils/parser';
import { useGetBasicUomEnum } from 'hooks';

import NutrientRow from '../../nutrition-label/components/NutrientRow';
import NutritionBreakdown from '../../nutrition-label/components/utils/NutritionBreakdown';
import SeparatorBar from '../../nutrition-label/components/SeparatorBar';
import NutrientSideBySide from '../../nutrition-label/components/NutrientSideBySide';

import { getUomTypesAbbreviations } from '../../nutrition-label/DailyValueConstants';
import { getNutritionNameByTypeCode } from '../../nutrition-label/constants';

function VerticalLabelWithMicronutrient(props) {
  const { label } = props;
  const [primaryNutrition, setPrimaryNutrition] = useState([]);
  const [secondaryNutrition, setSecondaryNutrition] = useState([]);

  const { basicUomProperties } = useGetBasicUomEnum();

  useEffect(() => {
    async function getData() {
      const nutrition_breakdown = await NutritionBreakdown(
        label?.nutritionFactProperties
      );
      setPrimaryNutrition(nutrition_breakdown?.primaryNutrition);
      setSecondaryNutrition(nutrition_breakdown?.secondaryNutrition);
    }
    if (label) {
      getData();
    }
  }, [label]);

  const filteredSecondaryNutrient = Object.values(secondaryNutrition)?.filter(
    (i) => i !== null
  );

  const showAbbr = (quantityUom, defaultUom = 'g') => {
    return (
      getUomTypesAbbreviations(basicUomProperties, quantityUom) ?? defaultUom
    );
  };

  return (
    <div class='nutrition-facts scroller'>
      <h1 className='nutrition-facts__label'>Nutrition Facts</h1>
      <SeparatorBar />
      <div className='nutrition-facts__servingsize'>
        {Boolean(label?.servingsPerContainer) && (
          <div className='nutrition-facts__servings-per-container'>
            {label?.servingsPerContainer} serving per container
          </div>
        )}

        <div className='nutrition-facts__servingsize-bold'>
          <div>Serving size</div>
          <div>
            {label?.servingSizeDescription}{' '}
            {label?.servingSize &&
              `(${label?.servingSize} ${getUomTypesAbbreviations(
                basicUomProperties,
                label?.servingSizeMeasurement
              )})`}
          </div>
        </div>
      </div>
      <SeparatorBar height={7} />
      <div className='nutrition-facts__calories-container'>
        <div>
          <div className='nutrition-facts__calories'>Amount Per Serving</div>
          <div className='nutrition-facts__calories_new-calories'>Calories</div>
        </div>

        <div className='nutrition-facts__calories_numeric'>
          {label?.calories}
        </div>
      </div>
      <SeparatorBar height={3} />
      <div className='nutrition-facts__dv-heading'>% Daily Value*</div>
      <NutrientRow
        label='Total Fat'
        gram={`${parseNumber(primaryNutrition?.totalFat?.quantity)}${showAbbr(
          primaryNutrition?.totalFat?.quantityUom,
          'g'
        )}`}
        labelBold={true}
        percentBold={true}
        percent={parseNumber(
          primaryNutrition?.totalFat?.dailyValueIntakePercent
        )}
      />
      <NutrientRow
        label={getNutritionNameByTypeCode(
          primaryNutrition?.saturatedFat?.nutritionTypeCode
        )}
        gram={`${parseNumber(
          primaryNutrition?.saturatedFat?.quantity
        )}${showAbbr(primaryNutrition?.saturatedFat?.quantityUom, 'g')}`}
        indent={10}
        percentBold={true}
        percent={parseNumber(
          primaryNutrition?.saturatedFat?.dailyValueIntakePercent
        )}
      />
      <NutrientRow
        label={getNutritionNameByTypeCode(
          primaryNutrition?.transFat?.nutritionTypeCode
        )}
        gram={`${parseNumber(primaryNutrition?.transFat?.quantity)}${showAbbr(
          primaryNutrition?.transFat?.quantityUom,
          'g'
        )}`}
        indent={10}
        percent={parseNumber(
          primaryNutrition?.transFat?.dailyValueIntakePercent
        )}
        labelItalic={true}
      />
      {primaryNutrition?.polyunsaturatedFat && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.polyunsaturatedFat?.nutritionTypeCode
          )}
          gram={`${parseNumber(
            primaryNutrition?.polyunsaturatedFat?.quantity
          )}${showAbbr(
            primaryNutrition?.polyunsaturatedFat?.quantityUom,
            'g'
          )}`}
          percent={parseNumber(
            primaryNutrition?.polyunsaturatedFat?.dailyValueIntakePercent
          )}
          indent={10}
        />
      )}{' '}
      {primaryNutrition?.monounsaturatedFat && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.monounsaturatedFat?.nutritionTypeCode
          )}
          gram={`${parseNumber(
            primaryNutrition?.monounsaturatedFat?.quantity
          )}${showAbbr(
            primaryNutrition?.monounsaturatedFat?.quantityUom,
            'g'
          )}`}
          percent={parseNumber(
            primaryNutrition?.monounsaturatedFat?.dailyValueIntakePercent
          )}
          indent={10}
        />
      )}
      {primaryNutrition?.cholesterol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.cholesterol?.nutritionTypeCode
          )}
          gram={`${parseNumber(
            primaryNutrition?.cholesterol?.quantity
          )}${showAbbr(primaryNutrition?.cholesterol?.quantityUom, 'mg')}`}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.cholesterol?.dailyValueIntakePercent
          )}
        />
      )}
      {primaryNutrition?.sodium && (
        <NutrientRow
          label='Sodium'
          gram={`${parseNumber(primaryNutrition?.sodium?.quantity)}${showAbbr(
            primaryNutrition?.sodium?.quantityUom,
            'mg'
          )}`}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.sodium?.dailyValueIntakePercent
          )}
        />
      )}
      {primaryNutrition?.fluoride && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.fluoride?.nutritionTypeCode
          )}
          gram={`${parseNumber(primaryNutrition?.fluoride?.quantity)}${showAbbr(
            primaryNutrition?.fluoride?.quantityUom,
            'mg'
          )}`}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.fluoride?.dailyValueIntakePercent
          )}
        />
      )}
      <NutrientRow
        label={getNutritionNameByTypeCode(
          primaryNutrition?.totalCarbohydrate?.nutritionTypeCode
        )}
        gram={`${parseNumber(
          primaryNutrition?.totalCarbohydrate?.quantity
        )}${showAbbr(primaryNutrition?.totalCarbohydrate?.quantityUom, 'g')}`}
        labelBold={true}
        percentBold={true}
        percent={parseNumber(
          primaryNutrition?.totalCarbohydrate?.dailyValueIntakePercent
        )}
      />
      {primaryNutrition?.xylitol && (
        <NutrientRow
          label={primaryNutrition?.xylitol?.nutritionTypeCode}
          gram={`${parseNumber(primaryNutrition?.xylitol?.quantity)}${showAbbr(
            primaryNutrition?.xylitol?.quantityUom,
            'g'
          )}`}
          indent={10}
          percent={parseNumber(
            primaryNutrition?.xylitol?.dailyValueIntakePercent
          )}
        />
      )}
      <NutrientRow
        label={getNutritionNameByTypeCode(
          primaryNutrition?.dietaryFiber?.nutritionTypeCode
        )}
        gram={`${parseNumber(
          primaryNutrition?.dietaryFiber?.quantity
        )}${showAbbr(primaryNutrition?.dietaryFiber?.quantityUom, 'g')}`}
        indent={10}
        percentBold={true}
        percent={parseNumber(
          primaryNutrition?.dietaryFiber?.dailyValueIntakePercent
        )}
      />
      {primaryNutrition?.fibreWaterSoluble && (
        <NutrientRow
          label='Soluble Fiber'
          gram={`${parseNumber(
            primaryNutrition?.fibreWaterSoluble?.quantity
          )}${showAbbr(primaryNutrition?.fibreWaterSoluble?.quantityUom, 'g')}`}
          indent={15}
          indentBar={15}
          percent={parseNumber(
            primaryNutrition?.fibreWaterSoluble?.dailyValueIntakePercent
          )}
        />
      )}
      {primaryNutrition?.fibreWaterInsoluble && (
        <NutrientRow
          label='Insoluble Fiber'
          gram={`${parseNumber(
            primaryNutrition?.fibreWaterInsoluble?.quantity
          )}${showAbbr(
            primaryNutrition?.fibreWaterInsoluble?.quantityUom,
            'g'
          )}`}
          indent={15}
          indentBar={15}
          percent={parseNumber(
            primaryNutrition?.fibreWaterInsoluble?.dailyValueIntakePercent
          )}
        />
      )}
      <NutrientRow
        label={'Total Sugars'}
        gram={`${parseNumber(
          primaryNutrition?.totalSugars?.quantity
        )}${showAbbr(primaryNutrition?.totalSugars?.quantityUom, 'g')}`}
        percent={parseNumber(
          primaryNutrition?.totalSugars?.dailyValueIntakePercent
        )}
        indent={10}
      />
      <NutrientRow
        label={`Includes ${parseNumber(
          primaryNutrition?.addedSugars?.quantity
        )}${showAbbr(
          primaryNutrition?.addedSugars?.quantityUom,
          'g'
        )} Added Sugars`}
        indent={15}
        indentBar={15}
        percentBold={true}
        percent={parseNumber(
          primaryNutrition?.addedSugars?.dailyValueIntakePercent
        )}
      />
      {primaryNutrition?.sugarAlcohol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.sugarAlcohol?.nutritionTypeCode
          )}
          gram={`${parseNumber(
            primaryNutrition?.sugarAlcohol?.quantity
          )}${showAbbr(primaryNutrition?.sugarAlcohol?.quantityUom, 'g')}`}
          percent={parseNumber(
            primaryNutrition?.sugarAlcohol?.dailyValueIntakePercent
          )}
          indent={10}
        />
      )}
      {primaryNutrition?.sorbitol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.sorbitol?.nutritionTypeCode
          )}
          gram={`${parseNumber(primaryNutrition?.sorbitol?.quantity)}${showAbbr(
            primaryNutrition?.sorbitol?.quantityUom,
            'g'
          )}`}
          percent={parseNumber(
            primaryNutrition?.sorbitol?.dailyValueIntakePercent
          )}
          indent={10}
        />
      )}
      {primaryNutrition?.maltitol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.maltitol?.nutritionTypeCode
          )}
          gram={`${parseNumber(primaryNutrition?.maltitol?.quantity)}${showAbbr(
            primaryNutrition?.maltitol?.quantityUom,
            'g'
          )}`}
          percent={parseNumber(
            primaryNutrition?.maltitol?.dailyValueIntakePercent
          )}
          indent={10}
        />
      )}
      {primaryNutrition?.erythritol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.erythritol?.nutritionTypeCode
          )}
          gram={`${parseNumber(
            primaryNutrition?.erythritol?.quantity
          )}${showAbbr(primaryNutrition?.erythritol?.quantityUom, 'g')}`}
          percent={parseNumber(
            primaryNutrition?.erythritol?.dailyValueIntakePercent
          )}
          indent={10}
        />
      )}
      <NutrientRow
        label={getNutritionNameByTypeCode(
          primaryNutrition?.protein?.nutritionTypeCode
        )}
        gram={`${parseNumber(primaryNutrition?.protein?.quantity)}${showAbbr(
          primaryNutrition?.protein?.quantityUom,
          'g'
        )}`}
        labelBold={true}
        percentBold={true}
        percent={parseNumber(
          primaryNutrition?.protein?.dailyValueIntakePercent
        )}
      />
      <SeparatorBar height={7} />
      <Row>
        {filteredSecondaryNutrient?.map((vit, idx) => (
          <Col span={12} key={idx}>
            <NutrientSideBySide
              showDot={idx % 2 !== 0}
              //* Maybe it need a refactor later.
              hideBar={
                idx - filteredSecondaryNutrient.length === -1 && idx % 2 === 0
              }
              label={getNutritionNameByTypeCode(vit?.nutritionTypeCode)}
              gram={`${parseNumber(vit?.quantity)}${getUomTypesAbbreviations(
                basicUomProperties,
                vit?.quantityUom
              )}`}
              percent={parseNumber(vit?.dailyValueIntakePercent)}
            />
          </Col>
        ))}
      </Row>
      <SeparatorBar height={3} />
      <div className='nutrition-facts__footnote'>
        <span>*</span>
        {label?.fdaLabelFootNote}
      </div>
    </div>
  );
}

export default VerticalLabelWithMicronutrient;
