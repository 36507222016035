import { useState, useCallback } from 'react';
import { set, isEmpty } from 'lodash';
import { useStatePrevious } from 'hooks/usePrevious';
import { deleteObjectField } from 'utils';

const useFormHook = (initValues) => {
  const [values, setFieldsValue] = useState(initValues);
  const [errors, setFieldsError, refFieldsError] = useStatePrevious({});

  const onChange = useCallback((e) => {
    const { value, name } = e.target;

    let nextFieldsError = refFieldsError.current;

    if (nextFieldsError.hasOwnProperty(name)) {
      deleteObjectField(nextFieldsError, [name]);
      setFieldsError(nextFieldsError);
    }

    setFieldsValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const setFieldValue = useCallback((path, value) => {
    if (typeof path === 'string') {
      setFieldsValue((prev) => {
        let nextValue = { ...prev };
        set(nextValue, path, value);
        return nextValue;
      });
    }
  }, []);

  /**
   * @param {}
   * ?example {displayName: validateFunc, permissions: validateFunc}
   */
  const validateFields = useCallback(
    async (validateObject) => {
      let nextError = {};

      const validationPair = Object.entries(validateObject);

      await Promise.all(
        validationPair.map(async ([name, validateFunc]) => {
          const errorsArray = await validateFunc(values[name]);
          if (!isEmpty(errorsArray)) {
            nextError[name] = errorsArray;
          }
        })
      );

      setFieldsError(nextError);
      return nextError;
    },
    [values]
  );

  const resetAll = useCallback(() => {
    setFieldsValue({});
    setFieldsError({});
  }, []);

  return {
    values,
    errors,
    setFieldsValue,
    setFieldValue,
    validateFields,
    onChange,
    resetAll,
  };
};

export default useFormHook;
