import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';

const IconItem = (props) => {
  const { mappedIcon, desc, style } = props;
  if (!mappedIcon) return <QuestionCircleOutlined />;

  if (mappedIcon?.type === 'image') {
    return <img src={mappedIcon?.src} alt={desc} style={style} />;
  }

  if (mappedIcon?.type === 'antIcon') {
    const Component = mappedIcon?.src;
    return <Component style={style} />;
  }

  return null;
};

export default IconItem;
