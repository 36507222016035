import { Checkbox, Typography } from 'antd';
import { formatMDY } from 'utils/formatDate';

import NestedDetailCellRenderer from './NestedDetailCellRenderer';

import { useGetProductEnums } from 'hooks';
import * as utils from './utils';

const CheckboxCellRenderer = (params) => {
  const value = params?.value === 'true' || params?.value === true;

  return params?.value !== null ? <Checkbox checked={value} /> : null;
};

const DatetimeCellRenderer = (params) => {
  return formatMDY(params.value);
};

const ListNameCellRenderer = (params) => {
  const { productEnums } = useGetProductEnums();

  const listName = params?.colDef?.listName;
  const isLanguageCode = listName === 'LanguageCodeEnum';

  const enumData = productEnums.find((enumItem) => {
    return enumItem.enumName === listName;
  });

  const enumPropertyData = enumData?.enumProperties?.find(
    (enumPropertyItem) => {
      return enumPropertyItem.enumCode === params.value;
    }
  );

  const languageDisplayText =
    isLanguageCode &&
    `${
      enumPropertyData?.enumDescription ? enumPropertyData?.enumDescription : ''
    }${
      enumPropertyData?.enumDescription && enumPropertyData?.enumCode
        ? ' - '
        : ''
    }${
      enumPropertyData?.enumCode
        ? `(${enumPropertyData?.enumCode?.toUpperCase()})`
        : ''
    }`;

  return languageDisplayText
    ? languageDisplayText
    : enumPropertyData?.displayName
    ? enumPropertyData?.displayName
    : params.value
    ? params.value
    : null;
};

const ArrayCellRenderer = (params) => {
  const values = params?.value;
  const colDef = params?.colDef;

  const renderer = utils.getColumnRenderer({
    columnData: colDef,
    isArray: false,
  });

  const renderItem = (value, index) => {
    const RendererComp = nestedDetailFramework[renderer];

    const isLastItem = index === values.length - 1;

    return (
      <>
        {RendererComp ? <RendererComp {...params} value={value} /> : value}
        {!isLastItem ? ', ' : null}
      </>
    );
  };

  return values?.length ? values.map(renderItem) : null;
};

const CustomHeader = (params) => {
  return (
    <Typography.Text
      className='product-nested-grid-heading'
      ellipsis={{ tooltip: params.displayName }}
    >
      {params.displayName}
    </Typography.Text>
  );
};

const nestedDetailFramework = {
  nestedGrid: NestedDetailCellRenderer,
  listNameCell: ListNameCellRenderer,
  checkboxCell: CheckboxCellRenderer,
  datetimeCell: DatetimeCellRenderer,
  arrayCell: ArrayCellRenderer,
  agColumnHeader: CustomHeader,
};

export default nestedDetailFramework;
