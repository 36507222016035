import React from 'react';
import { CommentOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const SendToMessage = ({ ...otherProps }) => {
  return (
    <ButtonSmallIcon
      icon={<CommentOutlined />}
      label={Messages.sendViaText}
      className='button-item-dropdown'
      {...otherProps}
    />
  );
};

SendToMessage.propTypes = {};

export default SendToMessage;
