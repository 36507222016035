export const MODULE_ALLOW_NESTED_ADVANCED_FILTER = [
  'productInformation',
  'oNIXPublicationFileInformation',
  'alcoholInformation',
  'allergenInformation',
  'animalFeeding',
  'apparelInformation',
  'audienceOrPlayerInformation',
  'audioVisualMediaContentInformation',
  'audioVisualMediaProductDescriptionInformation',
  'audioVisualMediaProductionInformation',
  'awardPrize',
  'batteryInformation',
  'certificationInformation',
  'chemicalRegulationInformation',
  'childNutritionInformation',
  'consumerInstructions',
  'controlledSubstance',
  'copyrightInformation',
  'dairyFishMeatPoultryItem',
  'dangerousSubstanceInformation',
  'deliveryPurchasingInformation',
  'dietInformation',
  'durableGoodsCharacteristics',
  'dutyFeeTaxInformation',
  'electronicDeviceCharacteristicsInformation',
  'farmingAndProcessingInformation',
  'foodAndBeverageIngredient',
  'foodAndBeveragePreparationServing',
  'foodAndBeveragePropertiesInformation',
  'healthcareItemInformation',
  'healthRelatedInformation',
  'healthWellnessPackagingMarking',
  'lightingDevice',
  'marketingInformation',
  'medicalDeviceTradeItem',
  'movieRevenueInformation',
  'nonfoodIngredient',
  'nonGTINLogisticsUnitInformation',
  'organismClassification',
  'packagingInformation',
  'packagingMarking',
  'packagingSustainability',
  'sustainability',
  'pharmaceuticalItemInformation',
  'physicalResourceUsageInformation',
  'placeOfItemActivity',
  'plumbingHVACPipeInformation',
  'productCharacteristics',
  'productFormulationStatement',
  'promotionalItemInformation',
  'propellantInformation',
  'publicationTitleRating',
  'referencedFileDetailInformation',
  'regulatedTradeItem',
  'safetyDataSheet',
  'salesInformation',
  'securityTagInformation',
  'softwareSystemRequirements',
  'textileMaterial',
  'tradeItemDataCarrierAndIdentification',
  'tradeItemDescription',
  'tradeItemDisposalInformation',
  'tradeItemHandling',
  'tradeItemHumidityInformation',
  'tradeItemLicensing',
  'tradeItemLifespan',
  'tradeItemMeasurements',
  'tradeItemSize',
  'tradeItemTemperatureInformation',
  'transportationHazardousClassification',
  'variableTradeItemInformation',
  'videoDisplayDeviceInformation',
  'warrantyInformation',
  'opticsDeviceInformation',
  'tradeItemHierarchy',
  'nutritionalInformation',
  'qaSpecification',
  'supplementInformation',
  'drugInformation',
];

export const MAXIMUM_QUERY_CONDITIONS = 50;
export const MAXIMUM_DATA_COLUMNS = 200;

export const DEFAULT_OPERATION = 'OR';

export const ADVANCED_FILTER_MODE = {
  NESTED_DATA: 'nested-data',
  FLAT_DATA: 'flat-data',
};

export const ADD_QUERY = {
  FILTER: 'FILTER',
  GROUP: 'GROUP',
};

export const UPDATE_COLUMNS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  UPDATE: 'UPDATE',
  CLEAR: 'CLEAR',
  UP: 'UP',
  DOWN: 'DOWN',
};
