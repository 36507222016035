import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown, notification, Empty } from 'antd';
import { ProjectOutlined, UnorderedListOutlined } from '@ant-design/icons';

import ContainerButton from 'common/components/button/ContainerButton';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/home';
import { injectIntl } from 'react-intl';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import userSelectors from 'redux/user/selectors';
import * as userActions from 'redux/user/actions';
import * as workflowService from 'services/workflow';

import * as globalActions from 'redux/global/actions';

import './Execute.less';

const onclickTemplate = async (selectedEntityIds, id, intl, updateTodoList) => {
  try {
    const response = await workflowService.startWorkflowService({
      workflowId: id,
      entityIds: selectedEntityIds,
    });
    const { isSuccess, message } = response;

    if (isSuccess) {
      notification.success({
        message: intl.formatMessage(Messages.executeWorkflowSuccess),
      });
      updateTodoList();
    } else {
      notification.error({
        message: message,
      });
    }
  } catch (error) {
    notification.error({
      message: 'Server Error',
    });
  }
};

const menuOptions = (
  workflowTemplates,
  selectedEntityIds,
  intl,
  updateTodoList
) => {
  return (
    <Menu className='execute-workflow-dropdown__menu scroller'>
      {workflowTemplates && workflowTemplates?.length > 0 ? (
        workflowTemplates.map((workflowTemplate, index) => {
          return (
            <Menu.Item key={index} style={{ backgroundColor: 'inherit' }}>
              <ButtonSmallIcon
                icon={<UnorderedListOutlined />}
                textDisplay={workflowTemplate?.name}
                onClick={() =>
                  onclickTemplate(
                    selectedEntityIds,
                    workflowTemplate?.id,
                    intl,
                    updateTodoList
                  )
                }
              />
            </Menu.Item>
          );
        })
      ) : (
        <Empty />
      )}
    </Menu>
  );
};

const ExecuteWorkflow = ({ entityType, intl }) => {
  const dispatch = useDispatch();

  const selectedEntity = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  
  const selectedEntityIds = selectedEntity?.map((entity) => entity?.id);
  const workflowTemplates = useSelector(
    userSelectors.makeSelectMemberWorkflowTemplates()
  );

  const updateTodoList = () => {
    dispatch(globalActions.getTodoList());
  };

  const getAllWorkflowTemplates = async (entityType) => {
    try {
      const response = await workflowService.getAllWorkflowTemplatesService({
        EntityType: entityType,
      });
      const { isSuccess, data, message } = response;
      if (isSuccess) {
        dispatch(
          userActions.getMemberWorkflowTemplateSuccess(data?.templateModels)
        );
      } else {
        notification.error({
          message: message,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Server Error',
      });
    }
  };

  useEffect(() => {
    getAllWorkflowTemplates(entityType);
  }, []);

  const allItemsOwned = selectedEntity?.every((entityItem) => {
    if (entityItem?.type?.toLowerCase() !== 'member') return entityItem?.isOwner;
    else return true;
  });

  const hasProductItem = selectedEntity?.some(
    (entityItem) => entityItem?.type?.toLowerCase() === 'product'
  );

  const disabled =
    selectedEntity.length === 0 || !allItemsOwned || hasProductItem;

  return (
    <>
      <Dropdown
        className='execute-workflow-dropdown'
        overlay={menuOptions(
          workflowTemplates,
          selectedEntityIds,
          intl,
          updateTodoList
        )}
        placement='bottomLeft'
        disabled={disabled}
        arrow
      >
        <RibbonButton
          icon={<ProjectOutlined style={{ height: 26 }} />}
          label={Messages.executeWorkflow}
        ></RibbonButton>
      </Dropdown>
    </>
  );
};

export default injectIntl(ExecuteWorkflow);
