import React from 'react';
import { put, call, takeLatest, all, delay } from 'redux-saga/effects';

import * as types from './constants';
import * as actions from './actions';
import * as services from 'services/assetProduct';
import * as servicesGrid from 'services/grid';
import * as servicesMember from 'services/members';

import { AgGridThumbnail, AgGridIcons } from 'common/components';

import { formatMDYWithParam } from 'utils/formatDate';
import { formatSizeUnitByParam } from 'utils/formatSizeUnits';
import { notification } from 'antd';
import { FavoriteStarIconRender } from 'common/components';
import { DEFAULT_SORT } from 'static/Constants';
import * as actionsBranding from 'redux/branding/actions';
import { Images } from 'config/assets';

export function* getDigitalAssetList(payload) {
  try {
    let params = {
      type: payload.type,
      PageSize: payload.pageSize,
      PageIndex: payload.pageIndex,
      search: { searchText: payload.search },
      Filters: payload.filters,
      isFavoriteRoute: payload.isFavoriteRoute,
      ...(payload?.search ? null : { sort: DEFAULT_SORT }),
      advancedSearchContainer: payload.advancedSearchContainer,
      searchCategory: payload.searchCategory,
      fromDate: payload.fromDate,
    };
    if (payload.mediaType) params.DigitalMediaType = payload.mediaType;
    const response = yield call(services.getAssetList, params);
    yield put(
      actions.getDigitalAssetListSuccess(
        response.data.gridData,
        response.data.paging.totalRecord
      )
    );
  } catch (error) {
    yield put(actions.getDigitalAssetListError(error));
  }
}

export function* getDigitalAssetForMemmberListSaga(payload) {
  try {
    let params = {
      type: payload.type,
      PageSize: payload.pageSize,
      PageIndex: payload.pageIndex,
      search: { searchText: payload.search },
      Filters: payload.filters,
      isFavoriteRoute: payload.isFavoriteRoute,
      ...(payload?.search ? null : { sort: DEFAULT_SORT }),
      advancedSearchContainer: payload.advancedSearchContainer,
      searchCategory: payload.searchCategory,
      fromDate: payload.fromDate,
    };
    if (payload.mediaType) params.DigitalMediaType = payload.mediaType;
    const response = yield call(services.getAssetList, params);
    yield put(
      actions.getDigitalAssetListSuccess(
        response.data.gridData,
        response.data.paging.totalRecord
      )
    );
  } catch (error) {
    yield put(actions.getDigitalAssetListError(error));
  }
}

export function* getDigitalAssetShortDetail(payload) {
  try {
    const response = yield call(services.getDigitalAssetShortDetail, {
      Id: payload.id,
    });
    yield put(actions.getDigitalAssetShortDetailSuccess(response.data));
  } catch (error) {
    yield put(actions.getDigitalAssetShortDetailError(error));
  }
}

const onFavoriteStarIconRender = (param) => {
  return <FavoriteStarIconRender {...param?.data} />;
};

const renderFavSharePackIcons = (params) => {
  const data = params?.data;

  return (
    <AgGridIcons data={data} isFavorite={data?.isFavorited} isShared={true} />
  );
};

const renderThumbnail = (params) => {
  const thumbnail =
    params?.data?.thumbSize100 ||
    params?.data?.thumbSize300 ||
    Images.RIVIR_LOGO_DEFAULT;

  return <AgGridThumbnail thumbnail={thumbnail} />;
};

const formatAssetDetailColumns = (response) => {
  let columns = [
    { field: '', checkboxSelection: true, filter: false, suppressMenu: true },
    {
      field: 'stateIcons',
      headerName: 'States',
      width: 85,
      cellRenderer: renderFavSharePackIcons,
      filter: false,
      resizable: false,
      headerClass: 'header-text-hide',
    },
    // {
    //   field: '',
    //   width: 70,
    //   cellRenderer: renderThumbnail,
    //   filter: false,
    //   suppressMenu: true,
    // },
  ];

  if (response?.columns?.length > 0) {
    let hiddenCol = [
      'id',
      'uniqueId',
      'canEdit',
      'isOwner',
      'type',
      'fileName',
    ];
    response.columns.forEach((val) => {
      if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
        // eslint-disable-next-line no-lone-blocks
        {
          if (val.fieldNameCamelCase === 'sharedByName') {
            val = { ...val, flex: 1, minWidth: 180, resizable: true };
          } else if (val.fieldNameCamelCase === 'originalFileName') {
            val = {
              ...val,
              minWidth: 180,
              resizable: true,
              headerName: 'File Name',
            };
          } else if (val.fieldNameCamelCase === 'additionalSearchTags') {
            val = { ...val, minWidth: 200, resizable: true };
          } else if (val.dataType === 'datetime') {
            val = {
              ...val,
              minWidth: 150,
              cellRenderer: formatMDYWithParam,
              resizable: true,
            };
          } else if (
            val.fieldNameCamelCase === 'assetName' ||
            val.fieldNameCamelCase === 'ownerName'
          ) {
            val = { ...val, minWidth: 140, resizable: true };
          } else if (val.fieldNameCamelCase === 'assetType') {
            val = { ...val, minWidth: 120, resizable: true };
          } else if (val.fieldNameCamelCase === 'fileSize') {
            val = {
              ...val,
              minWidth: 120,
              resizable: true,
              cellRenderer: formatSizeUnitByParam,
            };
          } else {
            val = { ...val, minWidth: 150, resizable: true };
          }

          if (val.fieldNameCamelCase === 'assetName')
            val = { ...val, linkTo: '/asset/{id}', minWidth: 300 };

          columns.push(val);
        }
      }
    });
  }

  return columns;
};

export function* gridDigitalAssetColumnInfo(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);

    const columns = formatAssetDetailColumns(response);

    yield put(actions.gridDigitalAssetColumnInfoSuccess(columns));
  } catch (error) {
    yield put(actions.gridDigitalAssetColumnInfoError(error));
  }
}

export function* getColumnsFilter(payload) {
  const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
  return { response };
}

export function* deleteAssets(action) {
  try {
    const { assetIds } = action.payload;
    yield delay(1000);
    const response = yield call(services.deleteAssets, {
      listAssets: assetIds,
    });
    if (response.isSuccess) {
      yield put(actions.deleteAssetsSuccess(types.DELETED_ASSETS_SUCCESS));
    } else {
      yield put(actions.deleteAssetsError(types.DELETED_ASSETS_ERROR));
    }
  } catch (error) {
    yield put(actions.deleteAssetsError(error));
  }
}

export function* updateAssetData(payload) {
  try {
    const response = yield call(services.updateAssetType, payload.params);
    if (response.isSuccess) {
      yield put(actions.updateAssetTypePaneSuccess());
      notification.success({
        message: 'Update Asset Type Success',
      });
      yield put(actions.getDigitalAssetShortDetail(payload.params.id));
    }
  } catch (error) {
    yield put(actions.updateAssetTypePaneError(error));
  }
}

export function* getAssetsForMemberSaga(action) {
  try {
    const { isSuccess, data } = yield call(
      servicesMember.getAssetsForMember,
      action.params
    );
    if (isSuccess) {
      yield put(actions.getAssetsForMemberSuccess(data));
    }
  } catch (error) {
    yield put(actions.getAssetsForMemberError(error));
  }
}

export function* getColumnsAssetsForMembersSaga(action) {
  try {
    const { response } = yield call(getColumnsFilter, action);

    const columns = formatAssetDetailColumns(response);

    yield put(actions.getColumnsAssetsForMemberSuccess(columns));
  } catch (error) {
    yield put(actions.getColumnsAssetsForMemberError(error));
  }
}

function* updateLatestGetNewAsset() {
  const updateLastGetNewDamResponse = yield call(services.updateLastGetNewDam);
  if (updateLastGetNewDamResponse.isSuccess)
    yield put(actionsBranding.getBrandingNoLoading());
}

function* watchAll() {
  yield all([
    takeLatest(
      types.GET_DIGITAL_ASSET_SHORT_DETAIL,
      getDigitalAssetShortDetail
    ),
    takeLatest(types.GET_DIGITAL_ASSET_LIST, getDigitalAssetList),
    takeLatest(
      types.DIGITAL_ASSET_GRID_COLUMN_INFO,
      gridDigitalAssetColumnInfo
    ),
    takeLatest(types.DELETE_ASSETS, deleteAssets),
    takeLatest(types.UPDATE_ASSET_TYPE_PANE, updateAssetData),
    takeLatest(types.GET_ASSETS_FOR_MEMBER, getAssetsForMemberSaga),
    takeLatest(
      types.GET_COLUMNS_ASSETS_FOR_MEMBER,
      getColumnsAssetsForMembersSaga
    ),
    takeLatest(types.UPDATE_LATEST_GET_NEW_ASSET, updateLatestGetNewAsset),
    takeLatest(
      types.GET_DIGITAL_ASSET_FOR_MEMBER_LIST,
      getDigitalAssetForMemmberListSaga
    ),
  ]);
}

export default watchAll;
