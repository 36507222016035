import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const CloseModalBtn = (props) => {
  const { onClick, style = {} } = props;
  return (
    <Button
      className='close-modal-btn'
      onClick={onClick && onClick}
      style={{ position: 'absolute', right: 5, border: 'none', ...style }}
    >
      <CloseOutlined />
    </Button>
  );
};

export default CloseModalBtn;
