import React, { useCallback, useEffect, useState } from 'react';

import { Popover } from 'antd';

import classnames from 'classnames';

import './SnapshotWrapper.less';

const SnapshotInputWrapper = (props) => {
  const {
    children, //* input render
    snapshotValue,
    snapshotValueRenderFn,
    showSnapshot = true,
    checkShowSnapshotFn,
    style = {},
    wrapperStyle = {},
    popoverProps = {},
    getInputValue,
    ...formItemToInputProps
  } = props;

  const { valuePropName = 'value' } = formItemToInputProps || {};

  const inputValue = props?.[valuePropName];

  const renderChildren = () => {
    return children
      ? React.Children.map(children, (childInput) => {
          return React.cloneElement(childInput, {
            [valuePropName]: inputValue,
            ...formItemToInputProps,
            ...childInput.props,
          });
        })
      : null;
  };

  const { isShowSnapshot, snapshotValueRender } = useSnapshotWrapper({
    checkShowSnapshotFn,
    inputValue,
    showSnapshot,
    snapshotValueRenderFn,
    snapshotValue,
  });

  useEffect(() => {
    getInputValue && getInputValue(inputValue);
  }, [inputValue, getInputValue]);

  return (
    <div
      className={classnames({ 'form-item-snapshot__border': isShowSnapshot })}
      style={wrapperStyle}
    >
      <SnapshotValuePopover
        snapshotValueRender={snapshotValueRender}
        isShowSnapshot={isShowSnapshot}
        popoverProps={popoverProps}
      >
        <div style={style}>{renderChildren()}</div>
      </SnapshotValuePopover>
    </div>
  );
};

const SnapshotValuePopover = (props) => {
  const { children, snapshotValueRender, isShowSnapshot, popoverProps } = props;

  return (
    <Popover
      content={snapshotValueRender}
      title='Previous Version'
      overlayStyle={{ maxWidth: 500 }}
      style={{ maxWidth: '100%' }}
      overlayInnerStyle={{ minWidth: 400 }}
      visible={!isShowSnapshot ? false : undefined}
      {...popoverProps}
    >
      <div className={classnames('snapshot-value-item')}>
        {children && children}
      </div>
    </Popover>
  );
};

const SnapshotFormItemWrapper = (props) => {
  const {
    children,
    snapshotValueRenderFn,
    checkShowSnapshotFn,
    snapshotValue,
    showOverlay,
  } = props;
  const [inputValue, setInputValue] = useState();

  const getInputValue = useCallback((value) => {
    setInputValue(value);
  }, []);

  const { isShowSnapshot, snapshotValueRender } = useSnapshotWrapper({
    checkShowSnapshotFn,
    inputValue,
    showOverlay,
    snapshotValueRenderFn,
    snapshotValue,
  });

  if (typeof children !== 'function') return null;

  return (
    <Popover
      content={snapshotValueRender}
      title='Previous Version'
      overlayStyle={{ maxWidth: 500 }}
      style={{ maxWidth: '100%' }}
      overlayInnerStyle={{ minWidth: 400 }}
      visible={!isShowSnapshot ? false : undefined}
    >
      {children({ getInputValue, showSnapshot: isShowSnapshot })}
    </Popover>
  );
};

const useSnapshotWrapper = ({
  checkShowSnapshotFn,
  inputValue,
  showSnapshot,
  snapshotValueRenderFn,
  snapshotValue,
}) => {
  const isShowSnapshot = checkShowSnapshotFn
    ? checkShowSnapshotFn({ value: inputValue })
    : showSnapshot;

  const snapshotValueRender = snapshotValueRenderFn
    ? snapshotValueRenderFn({ value: inputValue })
    : snapshotValue;

  return { isShowSnapshot, snapshotValueRender };
};

export { SnapshotInputWrapper, SnapshotValuePopover, SnapshotFormItemWrapper };
