import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { FolderAddOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const CreateAsset = ({ createAsset, isCreating, isEditting, disabled }) => {
  const pathname = window.location.pathname;
  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<FolderAddOutlined />}
      label={Messages.createAsset}
      onClick={createAsset}
      disabled={
        pathname === '/asset/create-iframe' ||
        pathname === '/favorite/favorite-assets' ||
        isCreating ||
        isEditting ||
        disabled
      }
    />
  );
};

CreateAsset.propTypes = {
  createAsset: PropTypes.func,
};

export default CreateAsset;
