export const GET_MAIN_PAGE_INFO = 'GET_MAIN_PAGE_INFO';
export const GET_MAIN_PAGE_INFO_SUCCESS = 'GET_MAIN_PAGE_INFO_SUCCESS';
export const GET_MAIN_PAGE_INFO_FAIL = 'GET_MAIN_PAGE_INFO_FAIL';

export const GET_CURATED_QUERIES_GRID = 'GET_CURATED_QUERIES_GRID';
export const GET_CURATED_QUERIES_GRID_SUCCESS =
  'GET_CURATED_QUERIES_GRID_SUCCESS';
export const CLEAR_MAIN_PAGE_INFO = 'CLEAR_MAIN_PAGE_INFO';

export const GET_MARKUP_DETAIL = 'GET_MARKUP_DETAIL';
export const GET_MARKUP_DETAIL_SUCCESS = 'GET_MARKUP_DETAIL_SUCCESS';
export const GET_MARKUP_DETAIL_FAIL = 'GET_MARKUP_DETAIL_FAIL';

//* grid
export const RELOAD_MARKUP_GRID = 'RELOAD_MARKUP_GRID';
export const RELOAD_MARKUP_GRID_SUCCESS = 'RELOAD_MARKUP_GRID_SUCCES';
