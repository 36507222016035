import { useQuery } from '@tanstack/react-query';

import * as gridServices from 'services/grid';

const fetchGridColumns = async (context) => {
  if (!context.queryKey[1]) return;

  const response = await gridServices.gridColumnInfo(context.queryKey[1]);

  return response.columns;
};

export const useGridColumns = ({ gridName }) => {
  const query = useQuery({
    queryKey: ['grid-column-info', gridName],
    queryFn: fetchGridColumns,
    staleTime: Infinity,
  });

  return query;
};
