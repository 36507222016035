import { useEffect } from 'react';
import { gridColumnInfo } from 'services/grid';
import { useFetch } from 'hooks';

export const useGetColumnsGrid = (gridName) => {
  const { data, run } = useFetch();

  useEffect(() => {
    if (gridName) run(gridColumnInfo(gridName));
  }, [gridName, run]);

  return data?.columns ?? [];
};
