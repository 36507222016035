import React, { useEffect, useRef } from 'react';

import { Row, Col, Space } from 'antd';
import { FormAddButton, FormDeleteButton } from 'common/components';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import { EVENT } from 'static/Constants';

const AddUserRoleAction = (props) => {
  const disabledAddBtnRef = useRef(true);
  const selectedRoleRef = useRef(null);
  const { selectedRole, assignedRoles, setAssignedRoles } = props;

  const onClickAddBtn = () => {
    setAssignedRoles((prevState) => {
      return [...prevState, selectedRole];
    });
  };

  const onClickRemoveBtn = () => {
    setAssignedRoles((prevState) => {
      return prevState.filter((roleItem) => roleItem.id !== selectedRole?.id);
    });
  };

  const onKeyDown = (e) => {
    const eKey = e.key;

    if (eKey === 'Enter') onEnterPress();
  };

  const onEnterPress = () => {
    if (disabledAddBtnRef.current) return;

    const newRole = selectedRoleRef.current;

    setAssignedRoles((prevState) => {
      return [...prevState, newRole];
    });
  };

  const checkDisabledRemoveBtn = () => {
    if (!assignedRoles?.length) return true;
    if (!selectedRole) return true;

    const isSelectedRoleStillNotAssigned = assignedRoles.every((roleItem) => {
      return roleItem.id !== selectedRole?.id;
    });

    const selectedRoleInAssignedRoles = assignedRoles.find(
      (roleItem) => roleItem.id === selectedRole?.id
    );

    const isSelectedRoleRemovable = !selectedRoleInAssignedRoles?.isReadOnly;

    return isSelectedRoleStillNotAssigned || !isSelectedRoleRemovable;
  };

  const checkDisabledAddBtn = () => {
    if (!selectedRole) return true;

    const isSelectedAssigned = assignedRoles.some((roleItem) => {
      return roleItem.id === selectedRole?.id;
    });

    return isSelectedAssigned;
  };

  const updateRef = () => {
    disabledAddBtnRef.current = checkDisabledAddBtn();
    selectedRoleRef.current = selectedRole;
  };

  useDocumentAttachEvent({ name: 'keydown', handler: onKeyDown });

  const handleEventKeyDown = (e) => {
    const actionEventListener = e.detail.action;
    document[actionEventListener]("keydown", onKeyDown);
  }

  useEffect(() => {
    document.addEventListener(EVENT.TOGGLE_PRESS_ENTER_EVENT_LISTENER, handleEventKeyDown);
    return () => {
      document.removeEventListener(EVENT.TOGGLE_PRESS_ENTER_EVENT_LISTENER, handleEventKeyDown);
    }
  }, [])

  useEffect(() => {
    updateRef();
  }, [selectedRole]);

  const disabledRemoveBtn = checkDisabledRemoveBtn();
  const disabledAddBtn = checkDisabledAddBtn();

  return (
    <Row>
      <Col flex={1}></Col>
      <Col flex={0}>
        <Space>
          <FormAddButton
            disabled={disabledAddBtn}
            onClick={onClickAddBtn}
            onKeyDown={onClickAddBtn}
          />
          <FormDeleteButton
            text='Remove'
            disabled={disabledRemoveBtn}
            onClick={onClickRemoveBtn}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default AddUserRoleAction;
