import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormButton } from 'common/components';

import Messages from 'i18n/messages/qa-spec';
import { useIntl } from 'react-intl';

import AddAssetPimModal from 'pages/product-full-view/components/modal/add-asset-pim-modal';

import { qaToggleReloadDocumentCertificateTab } from 'pages/qa-spec/controllers/actions';

import './LoadTestResultButton.less';

const LoadTestResultButton = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { disabled } = props;

  const [isLinkToNewAssetModalOpen, setIsLinkToNewAssetModalOpen] =
    useState(false);

  const toggleModalToUploadTestResult = () => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
  };

  const onFinishUpload = () => {
    toggleModalToUploadTestResult();
    dispatch(qaToggleReloadDocumentCertificateTab());
  };

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title={intl.formatMessage(Messages.loadTestResults)}>
        <FormButton
          className='qa-spec__btn-load-test-result'
          text={intl.formatMessage(Messages.loadTestResults)}
          icon={<UploadOutlined />}
          onClick={toggleModalToUploadTestResult}
          disabled={disabled}
        ></FormButton>
      </Tooltip>
      {isLinkToNewAssetModalOpen && (
        <AddAssetPimModal
          onCloseModal={toggleModalToUploadTestResult}
          addType='Document'
          getDocument={onFinishUpload}
          qaSpec
          isLoadTestResults
        />
      )}
    </div>
  );
};
export default LoadTestResultButton;
