import React from 'react';

import { Button, Row, Col, Divider } from 'antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { ColorPicker } from 'common/components';

import {
  PageEditorToolHeader,
  PageEditorSectionHeader,
  PageEditorInput,
} from '.';

import { PageEditorAddNew } from '../page-editor-tool__add-new';

import { EDIT_TYPE } from '../../constants';

import classnames from 'classnames';

import './styles.less';

const PageEditorDragImageEdit = (props) => {
  const {
    wrapId,
    wrapKey,
    imageHandlers,
    closeToolDraw,
    showDetailComponentData,
    selectedHook,
    detailShowTargetHook,
    componentHandlers,
    scale,
  } = props;

  const [detailShowTarget] = detailShowTargetHook;

  const detailData = Object.values(showDetailComponentData)?.[0];

  return (
    <div
      className={classnames(
        'page-editor-tool__wrap',
        'page-editor-tool__image'
      )}
    >
      <PageEditorToolHeader
        text='Edit image component'
        closeToolDraw={closeToolDraw}
      />
      <PageEditorSectionHeader text='Add new image' />
      <PageEditorAddNew
        wrapId={wrapId}
        wrapKey={wrapKey}
        editType={EDIT_TYPE.IMG}
        selectedHook={selectedHook}
        detailShowTargetHook={detailShowTargetHook}
        showDetailComponentData={showDetailComponentData}
        componentHandlers={componentHandlers}
        scale={scale}
      />
      {detailShowTarget && (
        <>
          <PageEditorSectionHeader text='Position' />
          <div className='page-editor-tool__markup-pos'>
            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--up'
              )}
              onClick={imageHandlers.moveUp}
            >
              <CaretUpOutlined />
            </Button>
            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--down'
              )}
              onClick={imageHandlers.moveDown}
            >
              <CaretDownOutlined />
            </Button>

            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--left'
              )}
              onClick={imageHandlers.moveLeft}
            >
              <CaretLeftOutlined />
            </Button>
            <Button
              className={classnames(
                'page-editor-tool__btn',
                'page-editor-tool__btn--right'
              )}
              onClick={imageHandlers.moveRight}
            >
              <CaretRightOutlined />
            </Button>
          </div>
          <Divider style={{ margin: '3px 0' }} />
          <PageEditorSectionHeader text='Choose Image' />
          <Row>
            <Col xs={24} style={{ padding: '3px 3px' }}>
              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--file'
                )}
                label='File Image'
              >
                <Button
                  onClick={imageHandlers.handleToggleMedia}
                  style={{ width: '100%' }}
                >
                  <PlusOutlined />
                </Button>
              </PageEditorInput>
            </Col>
          </Row>
          <PageEditorSectionHeader text='Style' />
          <Row>
            <Col xs={24} style={{ padding: '3px 3px' }}>
              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--radius'
                )}
                value={detailData?.radius}
                onChange={imageHandlers.setRadius}
                label='Border Radius'
                type='number'
                min={0}
              />
              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--border-thick'
                )}
                value={detailData?.borderWidth}
                onChange={imageHandlers.setBorderWidth}
                label='Border Thick (px)'
                type='number'
                min={0}
              />

              <PageEditorInput
                wrapClassName={classnames(
                  'page-editor-tool__input',
                  'page-editor-tool__input--color'
                )}
                label='Border Color'
              >
                <ColorPicker
                  wrapClassName='page-editor-tool__color-picker'
                  onChange={imageHandlers?.setBorderColor}
                  isShowAddBtn={false}
                  initialColor={detailData?.borderColor}
                />
              </PageEditorInput>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default PageEditorDragImageEdit;
