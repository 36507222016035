import React, { useEffect, useState, useRef } from 'react';

import { Button, Row, Col, Divider, Typography } from 'antd';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { CustomNotification } from 'common/components';

import KeyboardHint from './KeyboardHint';

import {
  PageEditorToolHeader,
  PageEditorSectionHeader,
  PageEditorInput,
} from '.';

import classnames from 'classnames';

import './styles.less';

const PageEditorBackgroundEdit = (props) => {
  const { backgroundHandlers, selectedHook, closeToolDraw, componentKey } =
    props;

  const [selected, setSelected, clearSelect] = selectedHook;

  const showKeyboardHint = selected?.id === componentKey;

  const onCheckSelect = (e) => {
    const value = e.target.checked;
    if (value === true) {
      setSelected({ id: componentKey, element: null });
      CustomNotification.success(
        `Selected background! now you can set background's position with arrow key`
      );
    } else {
      clearSelect();
    }
  };

  useEffect(() => {
    return () => {
      if (showKeyboardHint) {
        clearSelect();
      }
    };
  }, [showKeyboardHint]);

  return (
    <div
      className={classnames('page-editor-tool__wrap', 'page-editor-tool__bg')}
    >
      <PageEditorToolHeader
        text='Edit Background'
        closeToolDraw={closeToolDraw}
      />
      <PageEditorSectionHeader
        text='Position'
        doesHaveCheckbox
        onChange={onCheckSelect}
        value={showKeyboardHint}
      />
      <div>
        <Button
          className={classnames(
            'page-editor-tool__btn',
            'page-editor-tool__btn--up'
          )}
          onClick={backgroundHandlers.moveUp}
        >
          <CaretUpOutlined />
        </Button>
        <Button
          className={classnames(
            'page-editor-tool__btn',
            'page-editor-tool__btn--down'
          )}
          onClick={backgroundHandlers.moveDown}
        >
          <CaretDownOutlined />
        </Button>
        <Button
          className={classnames(
            'page-editor-tool__btn',
            'page-editor-tool__btn--left'
          )}
          onClick={backgroundHandlers.moveLeft}
        >
          <CaretLeftOutlined />
        </Button>
        <Button
          className={classnames(
            'page-editor-tool__btn',
            'page-editor-tool__btn--right'
          )}
          onClick={backgroundHandlers.moveRight}
        >
          <CaretRightOutlined />
        </Button>

        <Button
          className='page-editor-tool__btn--zoom-in'
          onClick={backgroundHandlers.zoomIn}
        >
          <ZoomInOutlined />
        </Button>
        <Button
          className='page-editor-tool__btn--zoom-out'
          onClick={backgroundHandlers.zoomOut}
        >
          <ZoomOutOutlined />
        </Button>
      </div>
      <Divider style={{ margin: '3px 0' }} />
      <KeyboardHint isActive={showKeyboardHint} />

      <PageEditorSectionHeader text='Choose Background' />
      <Row>
        <Col xs={24} style={{ padding: '3px 0px' }}>
          <PageEditorInput
            wrapClassName={classnames(
              'page-editor-tool__input',
              'page-editor-tool__input--file'
            )}
            label='File Image'
          >
            <Button
              onClick={backgroundHandlers.handleToggleMedia}
              style={{ width: '100%' }}
            >
              <PlusOutlined />
            </Button>
          </PageEditorInput>
        </Col>
      </Row>
    </div>
  );
};

export default PageEditorBackgroundEdit;
