import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import {
  Checkbox,
  Input,
  Upload,
  Button,
  Select,
  notification,
  Typography,
  Spin,
} from 'antd';
import { SisternodeOutlined, UploadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';
import { SPREADSHEET_FILE_TYPE, APL_VISIBILITY } from 'static/Constants';

import {
  Form,
  StyledModal,
  ButtonSmallIcon,
  WrapperSelect,
} from 'common/components';
import ErrorModal from 'common/components/product-publication-setup/ErrorModal.js';

import { createApl } from 'services/apl';
import {
  toggleReloadGridApl,
  resetReloadGridApl,
} from 'pages/branded-products-apl/controllers/actions';

import './CreateAplControl.less';

const CreateAplControl = () => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <ButtonSmallIcon
        icon={<SisternodeOutlined />}
        label={Messages.createApl}
        onClick={() => setVisibleModal(true)}
      />
      {visibleModal && (
        <ModalFormCreateApl
          visibleModal={visibleModal}
          turnOffModal={() => setVisibleModal(false)}
        />
      )}
    </>
  );
};

const layoutForm = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const ModalFormCreateApl = ({ visibleModal, turnOffModal }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formInstance] = Form.useForm();
  const [errorList, setErrorList] = useState([]);
  const intl = useIntl();

  const dispatch = useDispatch();

  useEffect(() => {
    if (visibleModal) {
      formInstance.setFieldsValue({
        visibility: 'Company',
      });
    }
  }, [visibleModal, formInstance]);

  const getFormData = (data) => {
    let fromData = new FormData();

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === 'enableScheduleMatching') {
          fromData.append(key, value ?? false);
        } else if (key === 'templateFile') {
          value && fromData.append(key, value?.file);
        } else {
          value && fromData.append(key, value);
        }
      }
    }

    return fromData;
  };

  const handleSubmitForm = () => {
    formInstance.validateFields().then(() => {
      setLoading(true);
      const params = getFormData(formInstance.getFieldsValue());

      createApl(params)
        .then((resp) => {
          const { isSuccess, status, data, message } = resp;

          if (isSuccess) {
            notification.success({
              message: intl.formatMessage(messagesProduct.createAPLSuccess),
            });

            // TODOS: Call product apl grid again after have api
            // reloadPage();
            dispatch(toggleReloadGridApl());
            turnOffModal();
          } else {
            const notificationMessage = message
              ? message
              : status === 500 && data?.title
              ? data?.title
              : 'Server Error';

            notification.error({
              message: notificationMessage,
            });
            if (resp?.data?.errors) {
              setErrorList(resp?.data?.errors);
            }
          }
        })
        .catch((error) => {
          notification.error({
            message: error?.data?.TemplateFile?.[0] || 'Server Error',
          });
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            dispatch(resetReloadGridApl());
          }, 200);
        });
    });
  };

  const modalProps = {
    title: intl.formatMessage(messagesProduct.createNewAPLLabel),
    bodyStyle: { height: '45vh' },
    width: '30vw',
    accept: '.xlsx, .xls, .csv',
    destroyOnClose: true,
    visible: visibleModal,
    onCancel: turnOffModal,
    onOk: handleSubmitForm,
    okButtonProps: { disabled: loading },
  };

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      let newFile = file;
      if (file.type !== SPREADSHEET_FILE_TYPE) {
        newFile = {
          name: file?.name,
          uid: file?.uid,
          originFile: file,
          status: 'error',
        };
      }
      setFileList([newFile]);
      return false;
    },
    fileList,
  };

  return (
    <StyledModal {...modalProps}>
      <Spin spinning={loading}>
        <Form {...layoutForm} form={formInstance} name='formCreateApl'>
          <Form.Item
            name='aplName'
            label='Name'
            rules={[{ required: true, message: 'Please input APL name.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='aplDescription' label='Description'>
            <Input />
          </Form.Item>
          <Form.Item name='aplLongDescription' label='More Description'>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='notes' label='Note'>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='visibility' label='Visibility'>
            <WrapperSelect>
              {APL_VISIBILITY.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            name='enableScheduleMatching'
            label='Schedule Matching'
            valuePropName='checked'
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name='templateFile'
            label='APL Items'
            className='form-item__upload-file'
          >
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Select File</Button>
              <Typography.Text className='form-item__hint-upload'>
                (*) Only support types: .xlsx, .xls, .csv
              </Typography.Text>
            </Upload>
          </Form.Item>
        </Form>
        {errorList && errorList.length > 0 && (
          <ErrorModal errorList={errorList} title={'APL Errors'} />
        )}
      </Spin>
    </StyledModal>
  );
};

export default CreateAplControl;
