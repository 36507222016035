import { useContext, useCallback } from 'react';
import { AbilityContext } from 'context/Can';

// Example: [{ action: 'VIEW', subject: 'FOLDER' }, { action: 'EDIT', subject: 'FOLDER', }]
export const useCheckPermissions = (permissions) => {
  const ability = useContext(AbilityContext);
  for (const permission of permissions) {
    if (ability.can(`${permission.action}`, `${permission.subject}`)) {
      return true;
    }
  }
  return false;
};

export const useCheckPermissionOR = () => {
  const ability = useContext(AbilityContext);

  const isValidPermissionOR = useCallback(
    (permissions) => {
      if (permissions) {
        if (Array.isArray(permissions?.[0])) {
          for (const permission of permissions) {
            if (ability.can(permission?.[0], permission?.[1])) {
              return true;
            }
          }
        } else {
          return ability.can(permissions?.[0], permissions?.[1]);
        }
      }
      return false;
    },
    [ability]
  );

  return isValidPermissionOR;
};

export const useCheckPermissionAnd = () => {
  const ability = useContext(AbilityContext);

  const isValidPermissionAND = useCallback(
    (permissions) => {
      let allowed = true;

      if (permissions) {
        if (Array.isArray(permissions?.[0])) {
          for (const permission of permissions) {
            if (ability.can(permission?.[0], permission?.[1])) {
              allowed *= true;
            } else {
              allowed *= false;
            }
          }
        } else {
          return ability.can(permissions?.[0], permissions?.[1]);
        }
      }

      return allowed ? true : false;
    },
    [ability]
  );

  return isValidPermissionAND;
};
