import React, { useState } from 'react';

import { Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import classnames from 'classnames';
import _ from 'lodash';

import { Form } from 'common/components';
import { EVENT } from 'static/Constants';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import { updateDynamicContentByTag } from 'utils/email-editor';

import Messages from 'i18n/messages/apl';
import { useIntl } from 'react-intl';

const AplSampleRequestDynamicContentForm = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { setDynamicContent, dynamicContent, invalidTag } = props;

  const [formValidated, setFormValidated] = useState(false);

  const onClickEyeIcon = (field) => () => {
    setDynamicContent((prevState) =>
      updateDynamicContentByTag({
        prevState,
        tags: [field],
        field: 'visible',
        value: !dynamicContent[field]?.visible,
      })
    );
  };

  const onValueChange = (fieldChange) => {
    const field = Object.keys(fieldChange)[0];
    const value = fieldChange[field];

    const currentField = dynamicContent[field];
    const newCurrentField = { ...currentField, value: value };
    const newDynamicContent = { ...dynamicContent, [field]: newCurrentField };

    _.debounce(() => setDynamicContent(newDynamicContent), 300)();
  };

  const onErrorTag = () => {
    setFormValidated(true);
  };

  const clearValueWhenSectionRemoved = (e) => {
    const tags = e.detail.tags;

    const setValue = tags.reduce((accumulator, currentTag) => {
      accumulator[currentTag] = '';
      return accumulator;
    }, {});

    form.setFieldsValue(setValue);
  };

  useDocumentAttachEvent({
    name: EVENT.EMAIL_EDITOR_HIGHLIGHT_ERROR_TAG,
    handler: onErrorTag,
  });

  useDocumentAttachEvent({
    name: EVENT.EMAIL_EDITOR_REMOVE_SECTION,
    handler: clearValueWhenSectionRemoved,
  });

  const getFormItemClassName = (field) =>
    classnames({
      'apl-request-sample__dynamic-content-form-item': true,
      'apl-request-sample__dynamic-content-form-item--highlight':
        field.highlight,
    });

  return dynamicContent ? (
    <Form
      className='scroller apl-request-sample__dynamic-content-form'
      layout='vertical'
      form={form}
      onValuesChange={onValueChange}
    >
      {Object.keys(dynamicContent)?.map((field) => {
        const fieldData = dynamicContent[field];
        const isError =
          formValidated && invalidTag?.includes(field) && fieldData?.visible;

        const status = isError ? 'error' : undefined;
        const message = isError
          ? intl.formatMessage(Messages.requiredMessage)
          : undefined;

        const EyeIcon = fieldData?.visible ? EyeOutlined : EyeInvisibleOutlined;

        return fieldData.show ? (
          <div className='apl-request-sample__dynamic-content-form-item-wrapper'>
            <Form.Item
              className={getFormItemClassName(fieldData)}
              label={intl.formatMessage(Messages[`${field}Label`])}
              name={field}
              key={field}
              required={fieldData.required}
              validateStatus={status}
              help={message}
            >
              {field === 'additionalInfo' ? (
                <Input.TextArea
                  value={dynamicContent.additionalInfo.value}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                />
              ) : (
                <Input
                  value={dynamicContent[fieldData?.value]}
                  disabled={!fieldData?.visible}
                />
              )}
            </Form.Item>
            {fieldData?.eye ? (
              <EyeIcon
                className='apl-request-sample__dynamic-content-form-show-hide-icon'
                onClick={onClickEyeIcon(field)}
              />
            ) : null}
          </div>
        ) : null;
      })}
    </Form>
  ) : null;
};

export default AplSampleRequestDynamicContentForm;
