import React from 'react';

import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import CloneEula from './components/controls/maintenance/eula/CloneEula';
import CreateEula from './components/controls/maintenance/eula/CreateEula';
import DeleteEulaGridView from './components/controls/maintenance/eula/DeleteEulaGridView';
import ViewAcceptance from './components/controls/maintenance/eula/ViewAcceptance';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const MaintenanceEulaGridView = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.EULA}>
        <CloneEula />
        <CreateEula isSmallBtn />
        <DeleteEulaGridView isSmallBtn />
        <RibbonDivider />
      </Can>
      <ViewAcceptance />
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default MaintenanceEulaGridView;
