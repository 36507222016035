import { useDispatch } from 'react-redux';

import * as action from 'redux/user/actions';

const useUpdateContactCard = () => {
  const dispatch = useDispatch();

  const updateContactCard = (userProfile) => {
    const fullName = `${userProfile?.firstName} ${userProfile?.lastName}`;

    const contactCardInfo = {
      userId: userProfile?.id,
      memberId: userProfile?.memberId,
      fullName: userProfile?.fullName || fullName,
      image: userProfile?.imageUrl,
      position: userProfile?.position,
      address: userProfile?.primaryAddress?.mainAddress,
      phoneNumber: userProfile?.primaryAddress?.phoneNumber,
      mobileNumber: userProfile?.primaryAddress?.mobileNumber,
      faxNumber: userProfile?.primaryAddress?.faxNumber,
      country: userProfile?.primaryAddress?.country,
      city: userProfile?.primaryAddress?.city,
      state: userProfile?.primaryAddress?.state,
      email: userProfile?.email,
    };

    dispatch(action.updateContactCard(contactCardInfo));
  };

  return updateContactCard;
};

export default useUpdateContactCard;
