import React from 'react';

import RibbonButton from 'common/components/button/RibbonButton';
import { HistoryOutlined } from '@ant-design/icons';
import { forwardTo } from 'utils/common/route';

const HistoryEntity = (props) => {
  const { entityId, entityType, size, disabled } = props;

  const handleHistoryEntity = () => {
    forwardTo((window.href = `/history-splash-form/${entityType}/${entityId}`));
  };
  return (
    <RibbonButton
      size={size}
      icon={<HistoryOutlined />}
      label={'History SPLASH Form Entity'}
      onClick={handleHistoryEntity}
      disabled={disabled}
    />
  );
};

export default HistoryEntity;
