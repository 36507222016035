import { useSelector } from 'react-redux';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import {
  pathnameFavMember,
  pathnameFavAssets,
  pathnameFavFolders,
  pathnameFavProducts,
  pathnameProduct,
  pathnameMember,
  pathnameDigitalMedia,
  pathnameDigitalMediaNew,
  pathnameAssets,
  pathnameDocument,
  pathnameMultiMedia,
  pathnameReportings,
  pathnameReportingsOwned,
  pathnameReportingsShared,
  pathnameFolders,
  pathnameFoldersOwned,
  pathnameFoldersShared,
  pathnameAssetsForMember,
  pathnameProductsForMember,
} from 'static/Constants';

const useGetDataQueryConditions = (pathname) => {
  let data = [];
  const favMembersQuery = useSelector(
    gridViewSelectors.makeSelectFavMembersQuery()
  );
  const favAssetsQuery = useSelector(
    gridViewSelectors.makeSelectFavAssetsQuery()
  );
  const favFoldersQuery = useSelector(
    gridViewSelectors.makeSelectFavFoldersQuery()
  );
  const favProductQuery = useSelector(
    gridViewSelectors.makeSelectFavProductsQuery()
  );
  const productsQuery = useSelector(
    gridViewSelectors.makeSelectProductsQuery()
  );
  const productsForMemberQuery = useSelector(
    gridViewSelectors.makeSelectProductsForMemberQuery()
  );

  const membersQuery = useSelector(gridViewSelectors.makeSelectMembersQuery());
  const digitalMediaQuery = useSelector(
    gridViewSelectors.makeSelectDigitalMediaQuery()
  );
  const digitalMediaQueryNew = useSelector(
    gridViewSelectors.makeSelectDigitalMediaQueryNew()
  );
  const assetsQuery = useSelector(gridViewSelectors.makeSelectAssetsQuery());
  const documentsQuery = useSelector(
    gridViewSelectors.makeSelectDocumentsQuery()
  );
  const multiMediaQuery = useSelector(
    gridViewSelectors.makeSelectMultiMediaQuery()
  );
  const reportingsQuery = useSelector(
    gridViewSelectors.makeSelectReportingsQuery()
  );
  const myReportsQuery = useSelector(
    gridViewSelectors.makeSelectMyReportsQuery()
  );
  const reportsSharedToMeQuery = useSelector(
    gridViewSelectors.makeSelectReportsSharedToMeQuery()
  );
  const foldersQuery = useSelector(gridViewSelectors.makeSelectFoldersQuery());
  const myFoldersQuery = useSelector(
    gridViewSelectors.makeSelectMyFoldersQuery()
  );
  const foldersSharedToMeQuery = useSelector(
    gridViewSelectors.makeSelectFoldersSharedToMeQuery()
  );
  const assetsForMemberQuery = useSelector(
    gridViewSelectors.makeSelectAssetsForMemberQuery()
  );

  switch (pathname) {
    case pathnameFavMember:
      data = favMembersQuery;
      break;
    case pathnameFavAssets:
      data = favAssetsQuery;
      break;
    case pathnameFavFolders:
      data = favFoldersQuery;
      break;
    case pathnameFavProducts:
      data = favProductQuery;
      break;
    case pathnameProduct:
      data = productsQuery;
      break;
    case pathnameMember:
      data = membersQuery;
      break;
    case pathnameDigitalMedia:
      data = digitalMediaQuery;
      break;
    case pathnameDigitalMediaNew:
      data = digitalMediaQueryNew;
      break;
    case pathnameAssets:
      data = assetsQuery;
      break;
    case pathnameDocument:
      data = documentsQuery;
      break;
    case pathnameMultiMedia:
      data = multiMediaQuery;
      break;
    case pathnameReportings:
      data = reportingsQuery;
      break;
    case pathnameReportingsOwned:
      data = myReportsQuery;
      break;
    case pathnameReportingsShared:
      data = reportsSharedToMeQuery;
      break;
    case pathnameFolders:
      data = foldersQuery;
      break;
    case pathnameFoldersOwned:
      data = myFoldersQuery;
      break;
    case pathnameFoldersShared:
      data = foldersSharedToMeQuery;
      break;
    case pathnameProductsForMember:
      data = productsForMemberQuery;
      break;
    default:
      data = {};
  }
  if (pathname.includes(pathnameAssetsForMember)) {
    data = assetsForMemberQuery;
  }
  return data;
};

export default useGetDataQueryConditions;
