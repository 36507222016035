import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'antd';

import ProductCategoryForm from 'pages/company-profile/components/tabs/system/category-management/components/ProductCategoryForm';
import { ProductCategoryTree } from 'common/components';
import { StyledModal } from 'common/components';

import { useGetProductCategoryTreeView } from 'pages/company-profile/components/tabs/system/category-management/hooks/useProductCategoryManagement';
import { getCategoryObjectWhenSelectNode } from 'pages/company-profile/components/tabs/system/category-management/utils';
import { useUserInfo } from 'hooks';

import './SelectCategory.less';

const SelectCategory = ({
  visible,
  setVisible,
  onClick,
  showEmptyCategory = false,
}) => {
  const [form] = Form.useForm();

  const [nodeSelected, setNodeSelected] = useState(null);
  const [isPrefetching, setIsPrefetching] = useState(true);
  const [isBlankCategory, setIsBlankCategory] = useState(false);

  const { userInfo } = useUserInfo();
  const currentMemberId = userInfo?.member.id;

  const { nestedCategory, isLoading } = useGetProductCategoryTreeView(
    currentMemberId,
    visible,
    isPrefetching,
    setIsPrefetching
  );

  const onSelectNode = (nodeFound) => {
    const fieldsValues = getCategoryObjectWhenSelectNode(
      nestedCategory,
      nodeFound
    );
    form.setFieldsValue(fieldsValues);
    setIsBlankCategory(false);
    setNodeSelected(nodeFound);
  };

  const createParamsFilter = () => {
    const categoryValue = form.getFieldsValue();
    onClick(nodeSelected, categoryValue);
    setVisible(false);
  };

  const unSelectNode = () => {
    const unSelectNode = new CustomEvent('unSelectNode', {
      detail: 'reset-category-modal',
    });
    document.dispatchEvent(unSelectNode);
  };

  const setEmptyCategory = () => {
    form.resetFields();
    unSelectNode();
    setNodeSelected(null);
    setIsBlankCategory(true);
  };

  const modalProps = {
    title: 'Select Category',
    visible,
    onCancel: () => setVisible(false),
    width: '1150px',
    bodyStyle: { maxHeight: '670px', height: '66vh' },
    forceRender: true,
    footer: (
      <>
        <Button onClick={() => setVisible(false)} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type='primary'
          onClick={createParamsFilter}
          loading={isLoading}
          disabled={!nodeSelected && !isBlankCategory}
        >
          OK
        </Button>
      </>
    ),
  };

  return (
    <StyledModal {...modalProps}>
      <Row
        gutter={[24, 0]}
        style={{ height: '100%' }}
        className='Filter-category-product'
      >
        <Col span={13} style={{ height: '100%' }}>
          <Form
            autoComplete='off'
            form={form}
            className='Filter-category-product__form scroller scroller--y'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <div style={{ paddingLeft: '25px' }}>
              <ProductCategoryForm disableForm={isBlankCategory} />
            </div>
          </Form>
        </Col>
        <Col span={11} style={{ height: '100%' }}>
          <ProductCategoryTree
            onSelect={onSelectNode}
            nestedCategory={nestedCategory}
            mode={false}
            isLoading={isLoading}
            showEmptyCategory={showEmptyCategory}
            setEmptyCategory={setEmptyCategory}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

export default SelectCategory;
