import React from 'react';
import Messages from 'i18n/messages/home';
import { BorderInnerOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

const NewStepControl = ({ onClick }) => {
  return (
    <RibbonButton
      icon={<BorderInnerOutlined style={{ height: 26 }} />}
      label={Messages.newStep}
      onClick={onClick}
    />
  );
};

NewStepControl.propTypes = {};

export default NewStepControl;
