import React, { createContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from 'antd';

import { CustomNotification } from 'common/components';
import {
  FolderBreadcrumb,
  FolderItemContainer,
} from 'common/components/folder';

import * as globalActions from 'redux/global/actions';
import { defaultSearchText } from 'redux/global/reducer';
import * as folderSelectors from '../../controllers/selectors';
import * as folderActions from '../../controllers/actions';

import { getFolderDetailGrid } from 'services/folder';

import { buildHierarchyBreadcrumb, sortFolderToTop } from '../../shared/utils';
import { filterParams } from 'common/components/folder/utils';

import {
  createDataByBlockSize,
  createServerSideDatasource,
} from '../../hooks/useAgGridFolder';

import { useCallbackRef } from 'hooks';
import { useGridView } from 'hooks/useGridView';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';
import 'pages/folders/style.less';
import { forwardTo } from 'utils/common/route';

const FolderGridDetailViewContext = createContext({});

export const FolderGridDetailViewProvider = ({ children }) => {
  const gridApi = useRef();

  return (
    <FolderGridDetailViewContext.Provider value={gridApi}>
      {children}
    </FolderGridDetailViewContext.Provider>
  );
};

export const useFolderGridDetailView = () => {
  const context = React.useContext(FolderGridDetailViewContext);

  if (context === undefined) {
    throw new Error(
      'useFolderGridDetailView must be used within a FormContextProvider'
    );
  }
  return context;
};

export const FolderGridDetailViewSection = ({
  folderOption,
  children,
  ...rest
}) => {
  const { folderBreadcrumb } = useSelector(
    folderSelectors.selectFolderListByLevel()
  );

  const dispatch = useDispatch();

  const gridApi = useFolderGridDetailView();

  const handleGetItemByBreadcrumb = useCallbackRef(async (idBreadcrumb) => {
    const currentBreadcrumb = folderBreadcrumb[folderBreadcrumb.length - 1];
    if (currentBreadcrumb?.id === idBreadcrumb) return;

    const { id } =
      folderBreadcrumb.find((item) => item?.id === idBreadcrumb) ?? {};

    const isRoot = id === 'home';

    if (isRoot) {
      const folderParams = {
        pageSize: 9999,
        pageIndex: 1,
        sort: [
          {
            fieldName: 'id',
            isAscending: false,
          },
        ],
        filters: [
          {
            fieldName: 'level',
            filterType: 'Equal',
            value: '1', //
          },
        ],
        folderOption,
        isAdvanceSearch: false,
        advCriteria: [],
        search: {
          searchText: '',
        },
      };
      getFolderDetailGrid(folderParams).then((resp) => {
        if (resp?.data?.gridData) {
          const data = resp.data.gridData;

          const blockSizeData = createDataByBlockSize(data);
          const datasource = createServerSideDatasource(blockSizeData);

          if (gridApi?.current) {
            gridApi.current.setServerSideDatasource(datasource);
          }
          dispatch(
            folderActions.updateFolderBreadcrumb([
              {
                id: 'home',
                folderName: 'Home',
              },
            ])
          );
        }
      });
    } else {
      const params = {
        folderId: id,
        pageIndex: 1,
        pageSize: 9999,
        filters: [],
        sort: [
          {
            fieldName: 'id',
            isAscending: false,
          },
        ],
        isFavoriteRoute: false,
      };
      const foundIdxBreadcrumb = folderBreadcrumb.findIndex(
        (item) => parseInt(item.id) === parseInt(id)
      );
      const newBreadcrumbs = folderBreadcrumb.slice(0, foundIdxBreadcrumb + 1);
      const response = await getFolderDetailGrid(params);
      try {
        if (response?.isSuccess) {
          const gridData = response?.data?.gridData ?? [];
          const item = gridData.map((folder) => ({
            ...folder,
            folderName: folder?.description,
          }));

          const blockSizeData = createDataByBlockSize(item);
          const datasource = createServerSideDatasource(blockSizeData);

          if (gridApi?.current) {
            gridApi.current.setServerSideDatasource(datasource);
          }

          dispatch(folderActions.updateFolderBreadcrumb(newBreadcrumbs));
        } else {
          CustomNotification.error('Something went wrong');
        }
      } catch (error) {}
    }
  });

  return (
    <div className='folder-grid-view' {...rest}>
      <FolderBreadcrumb
        maxItems={10}
        items={folderBreadcrumb}
        onClick={(breadcrumb) => handleGetItemByBreadcrumb(breadcrumb)}
      />
      {children}
    </div>
  );
};

export const FolderGridItemContainer = ({ params, children }) => {

  const handleOpenDetail = async () => {
    const { data } = params;
    forwardTo(`/folder/${data?.id}`)
  };

  return (
    <Tooltip placement='left' title='Double click to open folder' color='#108ee9'>
      <FolderItemContainer
        style={{ cursor: 'pointer' }}
        onDoubleClick={handleOpenDetail}
        // onClick={() => {
        //   dispatch(globalActions.updateToggleDetail(false));
        // }}
      >
        {children}
      </FolderItemContainer>
    </Tooltip>
  );
};
