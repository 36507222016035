import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider } from 'antd';

import { Can } from 'context/Can';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import { OneToOneOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { REDUX_MODULE } from 'hooks/useRedux';

import { TRANSITION_PAGE } from 'static/Constants';

const ScreenSizeSelectionSection = (props) => {
  const previewSize = useSelector(
    REDUX_MODULE.TRANSITION_PAGE.SELECTORS.selectTransitionPagePreviewSize()
  );

  const dispatch = useDispatch();

  const changeScreenPreviewSize = (sizeItem) => {
    dispatch(
      REDUX_MODULE.TRANSITION_PAGE.ACTIONS.changeTransitionPagePreviewSize(
        sizeItem
      )
    );
  };

  const { PREVIEW_SIZE_LIST } = TRANSITION_PAGE;

  return (
    <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL}>
      <ContainerButton>
        {PREVIEW_SIZE_LIST.map((sizeItem, idx) => {
          console.log('item', { sizeItem, previewSize });
          const isSelected = sizeItem?.ID === previewSize?.ID;

          return (
            <>
              <ItemButton
                style={{ justifyContent: 'space-evenly' }}
                noneSpan
                isVertical
              >
                <RibbonButton
                  icon={<OneToOneOutlined />}
                  label={`${sizeItem?.WIDTH}x${sizeItem?.HEIGHT}`}
                  onClick={() => changeScreenPreviewSize(sizeItem)}
                  toggle={isSelected}
                />
              </ItemButton>
              {idx !== PREVIEW_SIZE_LIST.length - 1 && (
                <Divider
                  type='vertical'
                  style={{ height: 40, margin: '4px 8px' }}
                />
              )}
            </>
          );
        })}
      </ContainerButton>
    </Can>
  );
};

export default ScreenSizeSelectionSection;
