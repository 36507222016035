import TextRender from '../components/qa-grid-cell/TextRender';

import {
  QA_ALLERGENS_COLUMNS,
  QA_INGREDIENT_COLUMNS,
  customWidthFormulaColumns,
  QA_FORMULA_COLUMNS,
  QA_FORMULA_ACTIVE_INGREDIENT_COLUMNS,
  INGREDIENT_LIMIT_CHARACTERS,
} from 'pages/qa-spec/constant';

import * as productEndpoints from 'services/product/endpoints';

import { Typography } from 'antd';

import { QA_SPEC_DOCUMENT_FILE_TYPE } from 'pages/qa-spec/constant';
import { getAllRows } from './index';

import sortByKey from 'utils/sortByKey';
import { transformValueIngredientsPartOf } from './ingredient';
import TextRenderSnapshot from '../components/qa-grid-cell/TextRenderSnapshot';

const { Text } = Typography;

const minWidthSetting = {
  minWidth: 200,
};

let initColSetting = {
  ...minWidthSetting,
  type: 'editableColumn',
};

const SELECTION_RENDER_CONFIG = {
  valueGetter: (options, fieldName) => {
    return (params) => {
      const value = params.data?.[fieldName];

      const formatValue = options[fieldName]?.find(
        (option) => option.enumDisplayName === value
      )?.enumDisplayName;

      return formatValue;
    };
  },
  params: {
    mapValue: (value) => {
      if (value === 'null' || value === undefined) {
        return null;
      } else {
        return value;
      }
    },
    values: (options, fieldName, config = { sort: false }) => {
      const { sort } = config;

      const nextOptions = options[fieldName].map((option) => ({
        value: option.enumDisplayName,
        displayName: option.enumDisplayName,
      }));

      if (sort) {
        return sortByKey(nextOptions, 'displayName');
      }

      return nextOptions;
    },
  },
};

const TEXT_RENDER_CONFIG = {
  params: { maxLength: (maxLength) => maxLength },
};

const CUSTOM_CONFIG = {
  percentInFinalProduct: {
    minWidth: 250,
  },
  percentAddedOnProduction: {
    minWidth: 250,
  },
};

const getCustomConfig = (fieldName) => {
  if (CUSTOM_CONFIG?.[fieldName]) {
    return CUSTOM_CONFIG;
  } else {
    return {};
  }
};

/**
 * ? create ingredient col def
 * @param {*} isEdit
 * @param {*} ingredientOptions
 * @returns
 */

const createIngredientColDef = (
  isEdit,
  ingredientOptions = {},
  productOwnerId,
  productId,
  _isToggleSnapshot
) => {
  const isToggleSnapshot = !isEdit && _isToggleSnapshot;

  let columns = QA_INGREDIENT_COLUMNS.columns;
  if (!columns || columns?.length === 0) return [];

  let nextCols = columns.map((fieldItem) => {
    const { field } = fieldItem || {};
    let col = {};

    if (
      ['nameOfIngredients', 'function', 'percentAddedOnProduction'].includes(
        field
      )
    ) {
      col = {
        cellEditorSelector: (params) => {
          return {
            component: 'TextEditor',
            params: {
              maxLength: TEXT_RENDER_CONFIG.params.maxLength(
                INGREDIENT_LIMIT_CHARACTERS[field]
              ),
              showCount: true,
            },
            popup: true,
          };
        },
        ...getCustomConfig(field),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
    }

    if (field === 'documents') {
      col = {
        minWidth: 350,
        cellRenderer: isToggleSnapshot
          ? 'FileUploadRenderSnapshot'
          : 'FileUploadRender',

        cellEditorSelector: (params) => {
          return {
            component: 'FileUploadEditor',
            params: {
              values: ingredientOptions.origin.map((ingredientItem) => ({
                value: ingredientItem.enumDisplayName,
                displayName: ingredientItem.enumDisplayName,
              })),
              apiUrl: productEndpoints.UPLOAD_PIM_ASSET,
              additionalBodyPayload: async () => {
                const additionalBodyPayload = {
                  assetType: 'Document',
                  assetSubType: 'General',
                  OwnerId: productOwnerId,
                  productId,
                };

                return additionalBodyPayload;
              },
              supportTypes: QA_SPEC_DOCUMENT_FILE_TYPE,
              confirmDeleteMessage: 'Are you sure you want to delete the file?',
            },

            popup: true,
          };
        },
      };
    }

    if (field === 'ingredientsPartOf') {
      col = {
        minWidth: 200,

        cellEditorSelector: (params) => {
          const options = getValueOptionsIngredientsPartsOf(params);

          return {
            component: 'SelectionEditor',
            params: {
              values: options.map((optionItem) => ({
                value: optionItem?.value,
                displayName:
                  optionItem?.displayName === null
                    ? ''
                    : optionItem?.displayName,
              })),
              mapValue: SELECTION_RENDER_CONFIG.params.mapValue,
            },

            popup: true,
          };
        },
        cellRenderer: (params) => {
          const options = getValueOptionsIngredientsPartsOf(params);

          const value =
            options?.find((option) => option?.value === params?.value)
              ?.displayName ?? '';

          if (isToggleSnapshot) {
            const { column, rowIndex, context } = params;

            const { snapshotGridValues } = context || {};

            const cellSnapshotIngredientsPartOf =
              snapshotGridValues?.[rowIndex]?.[column.colId];

            // remove uuid from ingredientPartOf ("result.uuid")
            const filteredIngredientsPartOf = cellSnapshotIngredientsPartOf
              ? cellSnapshotIngredientsPartOf?.split('.')?.[0]
              : 'N/A';

            return (
              <TextRenderSnapshot
                {...params}
                snapshotValueRender={filteredIngredientsPartOf}
                value={value}
              />
            );
          }

          return <TextRender value={value} />;
        },
      };
    }

    if (field === 'countryOfOrigin') {
      col = {
        minWidth: 300,
        cellRenderer: isToggleSnapshot
          ? 'CountryEditorRenderSnapshot'
          : 'CountryEditorRender',

        cellEditorSelector: (params) => {
          return {
            component: 'CountryEditor',
            popup: true,
          };
        },
      };
    }

    if (
      [
        'ionized',
        'allergens',
        'gmo',
        'religiousClaim',
        'origin',
        'bioengineered',
      ].includes(field)
    ) {
      col = {
        minWidth: 200,
        valueGetter: SELECTION_RENDER_CONFIG.valueGetter(
          ingredientOptions,
          field
        ),

        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: SELECTION_RENDER_CONFIG.params.values(
                ingredientOptions,
                field,
                { sort: ['origin'].includes(field) ? true : false }
              ),
              mapValue: SELECTION_RENDER_CONFIG.params.mapValue,
            },
            popup: true,
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
    }

    if (field === 'percentInFinalProduct') {
      col = {
        minWidth: 250,

        cellEditorSelector: (params) => {
          return {
            component: 'NumericEditor',
            params: {
              max: INGREDIENT_LIMIT_CHARACTERS.percentInFinalProduct,
              min: 0,
            },
            popup: true,
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
    }

    if (field === 'ingredientSupplier') {
      col = {
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
    }

    col = {
      ...initColSetting,
      ...fieldItem,
      ...col,
    };

    return col;
  });

  //* add hierachy level idx

  const statusField = {
    ...minWidthSetting,
    field: 'error',
    displayName: 'Edit status',
    minWidth: 120,
    cellRenderer: 'StatusRender',
  };

  nextCols = [
    {
      ...minWidthSetting,
      field: 'levelIdx',
      displayName: 'Level',
      minWidth: 120,
      valueGetter: function (params) {
        const value = params.data?.levelIdx;
        return (
          <Text ellipsis style={{ fontWeight: 700 }}>
            {value}
          </Text>
        );
      },
    },
    ...nextCols,
  ];

  if (isEdit) {
    nextCols = [statusField, ...nextCols];
  }

  return nextCols;
};

/**
 * ? create allergen col default
 * @param {*} isEdit
 * @param {*} ingredientOptions
 * @returns
 */

const createAllergenColDef = (isEdit, options = {}, _isToggleSnapshot) => {
  const isToggleSnapshot = !isEdit && _isToggleSnapshot;

  let columns = QA_ALLERGENS_COLUMNS.columns;
  if (!columns || columns?.length === 0) return [];

  let nextCols = columns.map((fieldItem) => {
    const { field } = fieldItem || {};
    let col = {};

    if (field === 'isPresent') {
      col = {
        maxWidth: 120,
        cellRenderer: 'CheckboxRender',

        cellEditorSelector: (params) => {
          return {
            component: 'CheckboxEditor',
            popup: true,
          };
        },
      };
    }

    if (field === 'levelOfContainment') {
      col = {
        minWidth: 200,

        valueGetter: SELECTION_RENDER_CONFIG.valueGetter(options, field),

        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: SELECTION_RENDER_CONFIG.params.values(options, field, {
                sort: true,
              }),
              disabled: params.data.isPresent === false,
              mapValue: SELECTION_RENDER_CONFIG.params.mapValue,
            },
            popup: true,
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
    }

    if (field === 'containmentSource') {
      col = {
        minWidth: 200,

        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: SELECTION_RENDER_CONFIG.params.values(options, field, {
                sort: true,
              }),
              disabled: params.data.levelOfContainment !== 'May Contain',
              mapValue: SELECTION_RENDER_CONFIG.mapValue,
            },
            popup: true,
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      };
    }

    col = { ...initColSetting, ...fieldItem, ...col };

    return col;
  });

  const statusField = {
    ...minWidthSetting,
    field: 'error',
    displayName: 'Edit status',
    minWidth: 120,
    maxWidth: 120,
    cellRenderer: 'StatusRender',
  };

  if (isEdit) {
    nextCols = [statusField, ...nextCols];
  }

  return nextCols;
};

const createFormulaColDef = ({
  productEnums,
  productOwnerId,
  productId,
  options,
}) => {
  let columns = QA_FORMULA_COLUMNS({
    productEnums,
    productOwnerId,
    productId,
    options,
  }).columns;
  if (!columns || columns?.length === 0) return [];

  let initColSetting = {
    type: 'editableColumn',
  };

  let nextCols = columns.map((fieldItem) => {
    const DEFAULT_WIDTH = 200;

    return {
      ...initColSetting,
      ...fieldItem,
      minWidth: customWidthFormulaColumns[fieldItem?.field] ?? DEFAULT_WIDTH,
    };
  });

  return nextCols;
};

const createFormulaActiveIngredientColDef = (options = {}) => {
  let columns = QA_FORMULA_ACTIVE_INGREDIENT_COLUMNS(options).columns;
  if (!columns || columns?.length === 0) return [];

  let initColSetting = {
    type: 'editableColumn',
  };

  let nextCols = columns.map((fieldItem) => {
    return {
      ...initColSetting,
      ...fieldItem,
    };
  });

  return nextCols;
};

const createTextEditorRenderer =
  (maxLength = 150) =>
  () => {
    return {
      component: 'TextEditor',
      popup: true,
      params: {
        maxLength: maxLength,
      },
    };
  };

const createNumericEditorRenderer =
  ({ max, min }) =>
  () => {
    return {
      component: 'NumericEditor',
      popup: true,
      params: {
        max,
        min,
      },
    };
  };

const createSelectionEditorRenderer = (selectionParams) => () => {
  return {
    popup: true,
    component: 'SelectionEditor',
    params: {
      mapValue: (value) => {
        if (value === 'null' || value === undefined) {
          return null;
        } else {
          return value;
        }
      },
      ...selectionParams,
    },
  };
};

const getValueOptionsIngredientsPartsOf = (params) => {
  const allRowData = getAllRows({ current: params });

  const options = allRowData
    ?.map((itemData) => {
      return {
        id: itemData.id,
        value: transformValueIngredientsPartOf(itemData),
        nameOfIngredients: itemData?.nameOfIngredients,
        displayName: `${itemData?.nameOfIngredients} - Level ${itemData?.levelIdx}`,
      };
    })
    .filter(
      (optionItem) =>
        optionItem?.nameOfIngredients && optionItem?.id !== params?.data?.id
    );

  return options;
};

export {
  createIngredientColDef,
  createAllergenColDef,
  createFormulaColDef,
  createTextEditorRenderer,
  createNumericEditorRenderer,
  createSelectionEditorRenderer,
  createFormulaActiveIngredientColDef,
};
