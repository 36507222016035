import { ORGANOLEPTIC_CHARACTERISTICS_ERRORS } from 'pages/qa-spec/constant';

export const ORGANOLEPTIC_CHARACTERISTICS_REQUIRED_FIELDS = {
  appearance: {
    specification: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.SPECIFICATION,
    },
    tolerance: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.TOLERANCE,
    },
    frequency: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.FREQUENCY },
    releaseCriteria: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.RELEASE },
  },
  color: {
    specification: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.SPECIFICATION,
    },
    tolerance: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.TOLERANCE,
    },
    frequency: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.FREQUENCY },
    releaseCriteria: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.RELEASE },
  },
  'aroma/odor': {
    specification: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.SPECIFICATION,
    },
    tolerance: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.TOLERANCE,
    },
    frequency: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.FREQUENCY },
    releaseCriteria: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.RELEASE },
  },
  'flavor/taste': {
    specification: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.SPECIFICATION,
    },
    tolerance: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.TOLERANCE,
    },
    frequency: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.FREQUENCY },
    releaseCriteria: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.RELEASE },
  },
  texture: {
    specification: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.SPECIFICATION,
    },
    tolerance: {
      message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.TOLERANCE,
    },
    frequency: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.FREQUENCY },
    releaseCriteria: { message: ORGANOLEPTIC_CHARACTERISTICS_ERRORS.RELEASE },
  },
};
