import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Form } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import {
  CustomNotification,
  dialogFunction,
  FormCancelButton,
  PreviewableModal,
  FormAddButton,
  FormEditButton,
  FormButton,
} from 'common/components';
import { EditSection } from './QaSpecProcessStage';
import { LinkAssetToQaSpec } from '../qa-grid-cell';

import QaSpecDocumentGrid from './QaSpecDocumentGrid';
import ModalUpdateDocument from './ModalUpdateDocument';
import { FormDocumentName, FormUploadAsset } from './QaSpecDocumentForm';

import { sendDownload } from 'config/axios';
import * as qaSpecServices from 'services/qaSpec';

import {
  useManufacturingStageDocuments,
  useQaManufacturingDocumentsSnapshot,
} from './hooks';

import { useGenerateColumDefsDocument } from './utils';
import { checkShowPdfPreview } from 'utils/checkShowPdfPreview';

import { ASSET_TYPE, DOCUMENT_SUBTYPE } from 'static/Constants';
import {
  useCheckSnapshotForRetailer,
  useSelectProductFullDetailData,
} from 'pages/qa-spec/hooks';

import { useReloadDocumentCertificateTab } from 'hooks/useReloadDocumentCertificateTab';

import Messages from 'i18n/messages/qa-spec';

import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

import './QaSpecManufacturingProcess.less';

const QaSpecDocument = ({ tableStyle }) => {
  const DEFAULT_DOC_FORM = {
    assetType: ASSET_TYPE[2],
    assetSubType: DOCUMENT_SUBTYPE[2],
  };

  const { id: productId } = useParams();
  const intl = useIntl();

  const [visibleModal, setVisibleModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [togglePreview, setToggleReview] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [statusLink, setStatusLink] = useState('idle');
  const [step, setStep] = useState(0);
  const [assetId, setAssetId] = useState(null);

  const [toggleEditDocument, setToggleEditDocument] = useState(false);

  const [formInstance] = Form.useForm();

  const { documents, refetchManufacturingStageDocuments } =
    useManufacturingStageDocuments(productId);

  const { qaManufacturingDocumentsSnapshot } =
    useQaManufacturingDocumentsSnapshot();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const gridRef = useRef();

  const productDetail = useSelectProductFullDetailData({ productId });
  const { reloadDocumentWhenDelete, reloadDocumentCertificateTab } =
    useReloadDocumentCertificateTab();

  const { linkPreview, extensionFile } = checkShowPdfPreview(
    selectedRow?.pdfPreview,
    selectedRow?.linkUrl,
    selectedRow?.fileType
  );

  const onClickMoveToConfig = () => {
    setStep(1);
  };

  const onFileChange = () => {
    setAssetId(null);
  };

  const resetState = () => {
    setStep(0);
    setAssetId(null);
  };

  const handleReset = () => {
    setVisibleModal(false);
    setToggleEditDocument(false);
    setAssetId(null);
    setSelectedRow(null);
    setStep(0);
    formInstance.resetFields();
  };

  const handleLinkDocument = async () => {
    try {
      setStatusLink('loading');

      const documentName = formInstance.getFieldValue('documentName');

      const params = {
        productId,
        digitalAssetId: assetId,
        documentName,
      };

      const response =
        await qaSpecServices.linkQaSpecDataManufacturingProcessDocument(params);

      if (response?.isSuccess) {
        setStatusLink('success');
        CustomNotification.success(
          intl.formatMessage(Messages.qaSpecDocLinkMessageSuccess)
        );
        refetchManufacturingStageDocuments();

        handleReset();
        reloadDocumentCertificateTab();
      } else {
        setStatusLink('error');
        CustomNotification.error(response?.message ?? 'Something went wrong!');
      }
    } catch (error) {
      setStatusLink('error');
      CustomNotification.error(error?.message ?? 'Something went wrong!');
    }
  };

  const handleUnlinkDocument = (rowData) => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          const response =
            await qaSpecServices.unlinkQaSpecDataManufacturingProcessDocument({
              linkId: rowData.linkId,
            });
          if (response?.isSuccess) {
            CustomNotification.success(
              intl.formatMessage(Messages.qaSpecDocRemoveMessageSuccess)
            );
            setSelectedRow(null);
            refetchManufacturingStageDocuments();
            reloadDocumentCertificateTab();
          } else {
            CustomNotification.error(
              response?.message ?? 'Something went wrong!'
            );
          }
        } catch (error) {}
      },
    });
  };

  const handleDownloadDocument = (rowData) => {
    if (!rowData?.linkUrl) {
      CustomNotification.success('Could not download file!');
    } else {
      sendDownload({ url: rowData.linkUrl });
    }
  };

  const handleUploadFile = (response) => {
    if (response?.data?.data?.assetId) {
      setAssetId(response.data.data.assetId);
      setStep(1);
    }
    return;
  };

  const handleChangeDocumentName = async () => {
    try {
      const params = {
        linkId: selectedRow?.linkId,
        documentName: formInstance.getFieldValue('documentName'),
      };

      const response = await qaSpecServices.updateManufacturingProcessDocument(
        params
      );

      if (response?.isSuccess) {
        CustomNotification.success(
          intl.formatMessage(Messages.qaSpecDocUpdateMessageSuccess)
        );
        refetchManufacturingStageDocuments();
        handleReset();
        reloadDocumentCertificateTab();
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Something went wrong!');
    }
  };

  const onCancel = () => {
    setIsEditMode(false);
    setSelectedRow(null);
    gridRef.current.api.setRowData(documents);
  };

  const columnDefsDocument = useGenerateColumDefsDocument({
    handlePreview: (rowData) => {
      setToggleReview(true);
      setSelectedRow(rowData);
    },
    handleDownload: (rowData) => handleDownloadDocument(rowData),
    handleDelete: (rowData) => {
      handleUnlinkDocument(rowData);
    },
    isToggleSnapshot,
  });

  useEffect(() => {
    !visibleModal && resetState();
  }, [visibleModal]);

  useEffect(() => {
    if (reloadDocumentWhenDelete) {
      refetchManufacturingStageDocuments();
    }
  }, [reloadDocumentWhenDelete]);

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const styleEditSection = {
    height: 30,
    width: '100%',
    marginTop: 12,
    marginBottom: 6,
  };

  return (
    <div className='manufacturing-process-document'>
      <Row style={{ width: '100%' }} align='middle'>
        <Col span={6}>
          <Typography.Title level={5} strong style={{ marginBottom: 0 }}>
            Documents
          </Typography.Title>
        </Col>
        <Col
          span={18}
          style={{
            ...styleEditSection,
          }}
        >
          {!isQASpecLocked ? (
            <EditSection
              productId={productId}
              isEditMode={isEditMode}
              onEditBtnClick={() => setIsEditMode(true)}
              style={{ height: '100%' }}
            >
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 6,
                }}
              >
                <FormAddButton
                  text='New Document'
                  style={{ marginRight: 6 }}
                  onClick={() => {
                    setVisibleModal(true);
                    formInstance.resetFields();
                  }}
                />

                <FormEditButton
                  text='Update Document'
                  onClick={() => {
                    setToggleEditDocument(true);
                    formInstance.setFieldsValue({
                      documentName: selectedRow?.documentName,
                    });
                  }}
                  disabled={!selectedRow?.linkId}
                />
                <FormCancelButton onClick={onCancel} />
              </Col>
            </EditSection>
          ) : null}
        </Col>
      </Row>
      <QaSpecDocumentGrid
        rowData={documents}
        tableStyle={tableStyle}
        gridRef={gridRef}
        isEdit={isEditMode}
        onRowClicked={(event) => {
          setSelectedRow(event.data);
        }}
        columnsDef={columnDefsDocument}
        snapshotGridValues={qaManufacturingDocumentsSnapshot}
      />
      {visibleModal && (
        <LinkAssetToQaSpec
          modalProps={{
            title: 'Link Document of Manufacturing Process to QA Specification',
            visible: visibleModal,
            onCancel: () => setVisibleModal(false),
            width: 460,
            bodyStyle: {
              minHeight: '380px',
            },
          }}
          stepProps={{
            currentStep: 0,
            items: [
              {
                key: 0,
                title: (
                  <Typography.Text strong style={{ fontSize: 16 }}>
                    Create an asset
                  </Typography.Text>
                ),
              },
              {
                key: 1,
                title: (
                  <Typography.Text strong style={{ fontSize: 16 }}>
                    Link to QA Specification
                  </Typography.Text>
                ),
              },
            ],
          }}
        >
          <div style={{ display: step === 0 ? 'block' : 'none' }}>
            <FormUploadAsset
              getResponse={handleUploadFile}
              additionalBodyPayload={{
                ...DEFAULT_DOC_FORM,
                productId,
                ownerId: productDetail?.ownerId,
              }}
              forceDisableManudalUpload={assetId}
              onFileChange={onFileChange}
            />
            {assetId ? (
              <Row justify='end' style={{ marginTop: 12 }}>
                <FormButton
                  type='default'
                  icon={<ArrowRightOutlined />}
                  text={intl.formatMessage(Messages.moveToConfig)}
                  onClick={onClickMoveToConfig}
                />
              </Row>
            ) : null}
          </div>
          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <FormDocumentName
              onBack={() => setStep(0)}
              formInstance={formInstance}
              onSubmitForm={handleLinkDocument}
              isSubmitting={statusLink === 'loading'}
            />
          </div>
        </LinkAssetToQaSpec>
      )}

      {togglePreview && (
        <PreviewableModal
          id={selectedRow?.linkId}
          title={selectedRow?.documentName}
          link={linkPreview}
          visible={togglePreview}
          cancelModal={() => setToggleReview(false)}
          fileName={selectedRow?.documentName}
          isDocument={true}
          isDownload={false}
          fileType={extensionFile}
          downloadLink={selectedRow?.linkUrl}
          fileSize={selectedRow?.fileSize}
        />
      )}

      {toggleEditDocument && (
        <ModalUpdateDocument
          formInstance={formInstance}
          visible={toggleEditDocument}
          onCancel={() => setToggleEditDocument(false)}
          onOk={handleChangeDocumentName}
        />
      )}
    </div>
  );
};

export default QaSpecDocument;
