import * as endpoints from './endpoints';
import * as api from 'config/axios';

export const getTodoList = (params) => {
  return api.sendPost(endpoints.GET_TODO_LIST, params);
};

export const resolveTodoList = (params) => {
  return api.sendPost(endpoints.RESOLVE_TODO_LIST, params);
};

export const getAwaitingList = (params) => {
  return api.sendGet(endpoints.GET_AWAITING_LIST);
};
