import React from 'react';

import { Row, Col, Typography } from 'antd';

import { SearchItemSelectList } from '../components';

const { Text } = Typography;

const SelectedMemberList = (props) => {
  const { selectedItems, items, handleSelectItem, tagRender } = props;

  return (
    <Col xs={24}>
      <Row>
        <Col xs={24}>
          <Text strong>Target member</Text>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <SearchItemSelectList
            selectedItems={selectedItems}
            items={items}
            handleSelectItem={handleSelectItem}
            tagRender={tagRender}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default SelectedMemberList;
