import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Row, Col } from 'antd';
import { get, set } from 'lodash';

import userSelectors from 'redux/user/selectors';

import { InfoWithLabel } from 'common/components';

import { formatMDY } from 'utils/formatDate';

import Message from 'i18n/messages/user';
import { FormattedMessage } from 'react-intl';

const UserActivitySummary = (props) => {
  // props
  const {} = props;

  // selector
  const summaryData = useSelector(
    userSelectors.makeSelectUserSummaryActivity()
  );
  const firstLogin = get(summaryData, 'firstLogin', false);
  const lastLogin = get(summaryData, 'lastLogin', false);
  const ytdLogin = get(summaryData, 'ytdLogins', false);
  const totalLogin = get(summaryData, 'totalLogin', false);
  const ytdTransactions = get(summaryData, 'ytdTransactions', false);
  const totalTransactions = get(summaryData, 'totalTransactions', false);

  // state

  // function
  // render grid columns
  const renderGridCol = (data) => {
    return (
      data &&
      data.map((col, colIndex) => {
        return (
          <Col
            span={24}
            lg={12}
            xl={6}
            className={`${classNamePrefix}-list-col`}
            key={colIndex}
          >
            {renderSummaryItem(col)}
          </Col>
        );
      })
    );
  };
  // render item in grid column
  const renderSummaryItem = (list) => {
    return (
      list &&
      list.map((item, index) => {
        const { info, label, labelWidth } = item;

        const labelColProps = {
          flex: labelWidth,
        };

        return (
          <InfoWithLabel
            key={index}
            className={`${classNamePrefix}-item`}
            label={label}
            info={info}
            labelColProps={labelColProps}
            labelAlign='right'
          />
        );
      })
    );
  };
  // variables
  const classNamePrefix = `${props.classNamePrefix}__summary`;
  const firstLoginFormatted = firstLogin && formatMDY(firstLogin);
  const lastLoginFormatted = lastLogin && formatMDY(lastLogin);

  const summaryRenderData = [
    [
      {
        label: <FormattedMessage {...Message.firstLogin} />,
        info: firstLoginFormatted,
        labelWidth: '80px',
      },
      {
        label: <FormattedMessage {...Message.lastLogin} />,
        info: lastLoginFormatted,
        labelWidth: '80px',
      },
    ],
    [
      {
        label: <FormattedMessage {...Message.ytdLogin} />,
        info: ytdLogin,
        labelWidth: '90px',
      },
      {
        label: <FormattedMessage {...Message.totalLogin} />,
        info: totalLogin,
        labelWidth: '90px',
      },
    ],
    [
      {
        label: <FormattedMessage {...Message.ytdTransactions} />,
        info: ytdTransactions,
        labelWidth: '120px',
      },
      {
        label: <FormattedMessage {...Message.totalTransactions} />,
        info: totalTransactions,
        labelWidth: '120px',
      },
    ],
  ];
  return (
    <Row className={`${classNamePrefix}-container`}>
      <Col span={24} className={`${classNamePrefix}-inner`}>
        <Row className={`${classNamePrefix}-list`}>
          {renderGridCol(summaryRenderData)}
        </Row>
      </Col>
    </Row>
  );
};

UserActivitySummary.propTypes = {
  /**
   * ClassName prefix
   */
  classNamePrefix: PropTypes.string,
};

export default UserActivitySummary;
