import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';

//* COMPONENT
import { dialogFunction } from 'common/components/index';

//* SAGA
import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';

//* DEFINE
import { OPEN_ITEM, OPEN_ITEM_CLASS } from 'static/Constants';

//* STYLING
import './OpenItemFullView.less';

const OpenItemFullView = (props) => {
  const {
    config,
    children,
    //* data
    assetData,
    folderData,
    memberInfo,
    ribbonLayout,
    reportingData,
    productInfo,
  } = props;

  const itemData = useMemo(() => {
    const openItemType = _.get(config, 'type');
    // eslint-disable-next-line no-throw-literal
    if (!openItemType) throw 'please add open item type in config';

    switch (openItemType) {
      case OPEN_ITEM.TYPE.ASSET_FULL_VIEW:
        return assetData;

      case OPEN_ITEM.TYPE.MEMBER_FULL_VIEW:
        return { memberInfo, ribbonLayout };

      case OPEN_ITEM.TYPE.REPORTING_FULL_VIEW:
        return reportingData;

      case OPEN_ITEM.TYPE.PRODUCT_FULL_VIEW:
        return productInfo;

      case OPEN_ITEM.TYPE.QA_SPECIFICATION:
        return productInfo;

      case OPEN_ITEM.TYPE.FOLDER_FULL_VIEW:
        return folderData;

      default:
        break;
    }
  }, [config, assetData, memberInfo, ribbonLayout, reportingData, folderData]);

  const openItemUid = uuidv4().toString();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTab = useSelector(globalSelectors.selectCurrentTab());
  const isAdding = useSelector(globalSelectors.selectIsAddingOpenItem());
  const openItemType = _.get(config, 'type');
  const openItemMode = _.get(config, 'mode');
  //! constructor end

  const configActionOpenItems = {};

  const handleSetConfigActionItems = (data) => {
    for (const property in data) {
      configActionOpenItems[property] = data[property];
    }
  };

  const getActionCallBack = useCallback(() => {
    if (
      openItemType === OPEN_ITEM.TYPE.ASSET_FULL_VIEW &&
      openItemMode === OPEN_ITEM.MODE.VIEW
    ) {
      configActionOpenItems.key = OPEN_ITEM.KEY.ASSET_VIEW;
    }
    if (
      openItemType === OPEN_ITEM.TYPE.MEMBER_FULL_VIEW &&
      openItemMode === OPEN_ITEM.MODE.VIEW
    ) {
      configActionOpenItems.key = OPEN_ITEM.KEY.MEMBER_FULL_VIEW;
    }
    if (
      openItemType === OPEN_ITEM.TYPE.REPORTING_FULL_VIEW &&
      openItemMode === OPEN_ITEM.MODE.VIEW
    ) {
      configActionOpenItems.key = OPEN_ITEM.KEY.REPORTING_VIEW;
    }
    if (
      openItemType === OPEN_ITEM.TYPE.PRODUCT_FULL_VIEW &&
      openItemMode === OPEN_ITEM.MODE.VIEW
    ) {
      configActionOpenItems.key = OPEN_ITEM.KEY.PRODUCT_VIEW;
    }
    if (
      openItemType === OPEN_ITEM.TYPE.QA_SPECIFICATION &&
      openItemMode === OPEN_ITEM.MODE.VIEW
    ) {
      configActionOpenItems.key = OPEN_ITEM.KEY.PRODUCT_VIEW;
    }
    if (
      openItemType === OPEN_ITEM.TYPE.FOLDER_FULL_VIEW &&
      openItemMode === OPEN_ITEM.MODE.VIEW
    ) {
      configActionOpenItems.key = OPEN_ITEM.KEY.FOLDER_VIEW;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, itemData, openItemUid]);

  const checkEditToAddOpenItem = (_isAdding) => {
    if (!_isAdding) return;
    const $editEles = document.querySelectorAll('.' + OPEN_ITEM_CLASS);

    if ($editEles?.length > 0) {
      dialogFunction({
        type: 'warn',
        content: 'Please save your edit or leave without saving edited content',
        okText: 'Leave Without Saving',
        cancelText: 'Cancel',
        onOk: () => {
          handleAddingOpenItem();
        },
        onCancel: () => {
          dispatch(globalActions.toggleIsAddingOpenItem(false));
        },
      });
    } else {
      handleAddingOpenItem();
    }
  };

  const updateAddingOpenItem = (openInfo) => {
    dispatch(globalActions.updateOpenItem(openInfo));
    dispatch(globalActions.toggleIsAddingOpenItem(false));
    message.success({
      content: 'Add Open Item successfully!',
      key: 'openItemFullView',
      duration: 2,
    });
    history.goBack();
  };

  const handleAddingOpenItem = () => {
    getActionCallBack();

    const data = {
      ...configActionOpenItems,
      config,
      openItemUid,
    };
    handleSetConfigActionItems(data);

    let openItemInfo = {
      uid: openItemUid,
      type: _.get(config, 'type'),
      mode: _.get(config, 'mode'),
      url: _.get(config, 'url'),
      name: _.get(config, 'name'),
      itemData,
      tabData: currentTab,
      activated: false,
      configActionOpenItems,
      createdTime: Date.now(),
    };
    if (isAdding === true) {
      message.loading({
        content: 'Adding!',
        key: 'openItemFullView',
      });

      if (openItemMode === OPEN_ITEM.MODE.VIEW) {
        switch (openItemType) {
          case OPEN_ITEM.TYPE.ASSET_FULL_VIEW:
          case OPEN_ITEM.TYPE.MEMBER_FULL_VIEW:
          case OPEN_ITEM.TYPE.REPORTING_FULL_VIEW:
          case OPEN_ITEM.TYPE.PRODUCT_FULL_VIEW:
          case OPEN_ITEM.TYPE.FOLDER_FULL_VIEW:
          case OPEN_ITEM.TYPE.QA_SPECIFICATION:
            updateAddingOpenItem(openItemInfo);
            break;
          default:
            break;
        }
        return;
      }

      if (openItemMode === OPEN_ITEM.MODE.EDIT) {
        switch (openItemType) {
          case OPEN_ITEM.TYPE.ASSET_FULL_VIEW:
            dispatch(globalActions.updateOpenItem(openItemInfo));
            dispatch(globalActions.toggleIsAddingOpenItemFormData(true));
            break;
          default:
            break;
        }
        return;
      }
    }
  };

  useEffect(() => {
    checkEditToAddOpenItem(isAdding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdding]);

  return <React.Fragment>{children}</React.Fragment>;
};

OpenItemFullView.propTypes = {
  //? config - open item config
  config: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  assetData: PropTypes.object,
};

export default React.memo(OpenItemFullView);
