import React from 'react';

import { List, Row, Col } from 'antd';
import _ from 'lodash';
import classnames from 'classnames';
import { produce } from 'immer';

import { Form } from 'common/components';
import HierarchyLevelProductItem from './HierarchyLevelProductItem';
import HierarchyPackageLevel from './HierarchyPackageLevel';
import HierarchyChangeProductMessage from './HierarchyChangeProductMessage';
import HierarchyAddProductButton from './HierarchyAddProductButton';

import * as utils from 'utils/productHierarchy';

import { HIERARCHY_LEVEL } from 'static/Constants';

const HierarchyLevelList = (props) => {
  const {
    hierarchyLevelList,
    setHierarchyLevelList,
    currentProduct,
    form,
    setSelectedLevel,
    hierarchyProductList,
    setHierarchyProductList,
    isCopy,
    checkHighestLevelProduct,
    selectedHierarchy,
    initQuantityForm,
  } = props;

  const onFormChange = (changedField) => {
    const index = Object.keys(changedField)[0];
    setHierarchyLevelList((prevState) => {
      const cloneState = _.cloneDeep(prevState);

      _.set(cloneState, `${index}.quantity`, changedField[index]);

      return cloneState;
    });
  };

  const onClickLevelButton = (level) => () => {
    setSelectedLevel(level);
  };

  const onClickRevertBtn = (level) => () => {
    if (!level.revertProduct) return;
    revertLevel(level);
    revertProduct(level);
    revertQuantity(level);
  };

  const revertLevel = (level) => {
    setHierarchyLevelList((prevState) => {
      const nextState = produce(prevState, (draft) => {
        //* revert product
        draft[level.index].productItemId = level.revertProduct;

        //* revert package level base on product package level - in case change display shipper to case then revert
        const revertProductData = hierarchyProductList.find(
          (productItem) => productItem.productItemId === level.revertProduct
        );

        const productPackageLevel = revertProductData?.packageLevel;

        if (!productPackageLevel) return;

        const PACKAGE_LEVEL = Object.values(HIERARCHY_LEVEL).find((LEVEL) => {
          return LEVEL.NAME === productPackageLevel;
        });

        draft[level.index].key = PACKAGE_LEVEL.KEY;
        draft[level.index].packageLevel = PACKAGE_LEVEL.NAME;
      });

      return nextState;
    });
  };

  const revertProduct = (level) => {
    const revertProductData = hierarchyProductList.find(
      (productItem) => productItem.productItemId === level.revertProduct
    );

    const productPackageLevel = revertProductData?.packageLevel;

    const originalProduct = utils.getOriginalProduct({
      selectedHierarchy,
      productPackageLevel,
    });

    setHierarchyProductList((prevState) => {
      const cloneState = _.cloneDeep(prevState);

      cloneState.push(originalProduct);

      return cloneState;
    });
  };

  const revertQuantity = (level) => {
    const revertProductData = hierarchyProductList.find(
      (productItem) => productItem.productItemId === level.revertProduct
    );

    const productPackageLevel = revertProductData?.packageLevel;

    const originalProduct = utils.getOriginalProduct({
      selectedHierarchy,
      productPackageLevel,
    });

    form.setFieldsValue({ [level.index]: originalProduct?.quantity });
  };

  //* render
  const highestLevel = utils.getHighestLevel(hierarchyLevelList);

  return (
    <Form form={form} onValuesChange={onFormChange}>
      <List>
        {hierarchyLevelList.map((level) => {
          const product = utils.getLevelProduct({
            level,
            productList: hierarchyProductList,
          });

          const shouldShowChangeProductMessage =
            checkHighestLevelProduct() && level.key === highestLevel;

          const isCurrentProduct =
            product?.productItemId === currentProduct.productId;

          return (
            <List.Item
              key={level.index}
              className={classnames({
                'add-hierarchy__level-item-container': true,
                'add-hierarchy__level-item-container--danger':
                  shouldShowChangeProductMessage,
                'add-hierarchy__level-item-container--highlight':
                  isCurrentProduct,
              })}
            >
              <Row style={{ width: '100%' }}>
                <Col span={24}>
                  <Row className='add-hierarchy__level-item' align='middle'>
                    <HierarchyPackageLevel
                      level={level}
                      setHierarchyLevelList={setHierarchyLevelList}
                      initQuantityForm={initQuantityForm}
                    />
                    <Col flex={1} style={{ minWidth: 0 }}>
                      <Row
                        className='add-hierarchy__level-info'
                        gutter={[8, 0]}
                      >
                        {product ? (
                          <HierarchyLevelProductItem
                            level={level}
                            form={form}
                            product={product}
                            hierarchyProductList={hierarchyProductList}
                            hierarchyLevelList={hierarchyLevelList}
                            setHierarchyLevelList={setHierarchyLevelList}
                            currentProduct={currentProduct}
                            highestLevel={highestLevel}
                            isCopy={isCopy}
                            onClickLevelButton={onClickLevelButton}
                            onClickRevertBtn={onClickRevertBtn}
                          />
                        ) : (
                          <HierarchyAddProductButton
                            level={level}
                            hierarchyProductList={hierarchyProductList}
                            onClickLevelButton={onClickLevelButton}
                            onClickRevertBtn={onClickRevertBtn}
                          />
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <HierarchyChangeProductMessage
                  shouldShow={shouldShowChangeProductMessage}
                />
              </Row>
            </List.Item>
          );
        })}
      </List>
    </Form>
  );
};

export default HierarchyLevelList;
