import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { notification } from 'antd';

import { forwardTo } from 'utils/common/route';
import { ThumbnailItem } from 'common/components';

import AssetQuery from 'assets/query/AssetQuery.png';
import ProductQuery from 'assets/query/ProductQuery.png';
import MemberQuery from 'assets/query/MemberQuery.png';
import FolderQuery from 'assets/query/FolderQuery.png';
import ReportQuery from 'assets/query/ReportQuery.png';

import { RIBBON_TYPES } from 'static/Constants';
import * as actionsRibbon from 'redux/ribbon/actions';
import * as ribbonSelectors from 'redux/ribbon/selectors';
import * as actionsGlobal from 'redux/global/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import { updateSearchText } from 'pages/home/utils';

import {
  updateQueryConditions,
  updateQueryAdvance,
  updateIdQuery,
} from 'utils/queryCondition';
import { Images } from 'config/assets';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

const QueryThumbnail = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { dataDetail, selected, onClickItemGrid, onClickCheckboxItem } = props;

  const { queryConditions, columns, ribbonTypes, urlForwardTo, searchText } =
    JSON.parse(dataDetail?.query);

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  const onDoubleClick = (item) => {
    dispatch(actionsGridView.myQueryDoubleClick(true));

    const invalidQuery = !urlForwardTo;

    if (invalidQuery) {
      showErrorMessage();
      return;
    }

    pushCloseDetailStack(urlForwardTo);
    forwardTo(`${urlForwardTo}?query=1`);

    if (ribbonTypes === RIBBON_TYPES.DETAILSVIEW) {
      dispatch(actionsGridView.chooseGridColumns(columns, dataDetail?.id));
    } else {
      dispatch(actionsGridView.chooseGridColumns([], dataDetail?.id));
    }
    updateQueryConditions(dispatch, queryConditions, urlForwardTo, true);
    updateQueryAdvance(dispatch, queryConditions, urlForwardTo, true);
    updateIdQuery(dispatch, dataDetail?.id, urlForwardTo, true);

    dispatch(actionsRibbon.changeType(ribbonTypes));
    dispatch(actionsGridView.updateVisible(false));
    dispatch(actionsGlobal.updateIsBackFromQuery(true));
    updateSearchText(dispatch, searchText, urlForwardTo);
    dispatch(actionsGridView.checkQueryCondition(true));

    setTimeout(() => {
      dispatch(actionsGridView.toggleRefreshQuery(true));
      dispatch(actionsGridView.updatePrevPathnameQuery(urlForwardTo));
    }, 200);
  };

  const showErrorMessage = () => {
    notification.error({
      message: intl.formatMessage(Messages.queryOpenError),
    });
  };

  const pushCloseDetailStack = (urlForwardTo) => {
    const { name, stack } = closeDetailStack;
    dispatch(
      actionsRibbon.updateCloseDetailStack({
        name,
        stack: [...stack, urlForwardTo],
      })
    );
  };

  let image =
    dataDetail?.gridName === 'member-detail-grid'
      ? MemberQuery
      : dataDetail?.gridName === 'digital-asset-detail-grid'
      ? AssetQuery
      : dataDetail?.gridName === 'folder-detail-grid'
      ? FolderQuery
      : dataDetail?.gridName === 'products-for-member' ||
        dataDetail?.gridName === 'product-detail-grid'
      ? ProductQuery
      : dataDetail?.gridName === 'assets-for-member'
      ? AssetQuery
      : dataDetail?.gridName === 'query-reporting-grid'
      ? ReportQuery
      : Images.RIVIR_LOGO_DEFAULT;

  return (
    <ThumbnailItem
      dataDetail={dataDetail}
      labelThumbnail={dataDetail && dataDetail.name}
      imageThumbnail={image}
      selected={selected}
      onClickItemGrid={onClickItemGrid}
      onClickCheckboxItem={onClickCheckboxItem}
      onDoubleClick={onDoubleClick}
      isShareBtn={true}
    />
  );
};

QueryThumbnail.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default React.memo(QueryThumbnail);
