import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Input } from 'antd';

const SearchProperties = ({ handleUpdateSearchEntity, entityType }, ref) => {
  const [inputSearchEntity, setInputSearchEntity] = useState('');

  const handleClearSearchText = () => setInputSearchEntity('');

  useImperativeHandle(ref, () => ({
    onClearSearchText: handleClearSearchText,
  }));

  useEffect(() => {
    handleClearSearchText();
  }, [entityType]);

  return (
    <>
      <Input.Search
        allowClear
        value={inputSearchEntity}
        placeholder='Input search Entities and Attributes'
        size='large'
        className='advanced-reporting__reporting-entities'
        onChange={(e) => setInputSearchEntity(e.target.value)}
        onSearch={handleUpdateSearchEntity}
      />
    </>
  );
};

export default forwardRef(SearchProperties);
