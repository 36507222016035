import React, { useState, useCallback, useRef } from 'react';

import { useParams } from 'react-router-dom';
import {
  StyledModal,
  AgGridClient,
  CustomNotification,
} from 'common/components';

import {
  useQueryMemberAddressFromProductId,
  useMutateProductLinkedAddress,
} from './queries';

import { useQueryProductCompanyInfo } from 'common/queries/product-module';

import './ProductHeaderContactInfoEditModal.less';

const columns = [
  {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    width: 60,
  },
  {
    field: 'city',
    displayName: 'City',
    headerCheckboxSelection: true,
  },
  {
    field: 'country',
    displayName: 'Country',
    headerCheckboxSelection: true,
  },
  {
    field: 'faxNumber',
    displayName: 'faxNumber',
    headerCheckboxSelection: true,
  },
  {
    field: 'mainAddress',
    displayName: 'Main Address',
    headerCheckboxSelection: true,
  },
  {
    field: 'state',
    displayName: 'State',
    headerCheckboxSelection: true,
  },
  { field: 'zipcode', displayName: 'Zip Code', headerCheckboxSelection: true },
];

const ProductHeaderContactInfoEditModal = (props) => {
  const { visible, onCancel, handleCancelEditHeader } = props;
  const { id } = useParams();

  const [selectedRow, setSelectedRow] = useState();

  const { data } = useQueryMemberAddressFromProductId({ productId: +id });
  const { isLoading: isSubmitting, mutateAsync: mutateProductLinkedAddress } =
    useMutateProductLinkedAddress();

  const { invalidateProductCompanyInfo } = useQueryProductCompanyInfo();

  const { memberAddresses: memberAddressList } = data || {};

  const refGrid = useRef();

  const onSubmit = () => {
    mutateProductLinkedAddress({
      productId: +id,
      addressId: selectedRow?.[0]?.id,
    })
      .then(() => {
        CustomNotification.success('Edit Successfully');
        invalidateProductCompanyInfo(+id);
        handleCancelEditHeader();
        onCancel();
      })
      .catch((error) => CustomNotification.error(error));
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = refGrid.current.api.getSelectedRows();

    setSelectedRow(selectedRows);
  }, []);

  const modalProps = {
    visible,
    title: 'Edit Product Header Contact Info',
    onCancel,
    width: 1000,
    okText: 'Submit',
    onOk: onSubmit,
    confirmLoading: isSubmitting,
    destroyOnClose: true,
    okButtonProps: {
      disabled: !selectedRow?.length,
    },
  };

  return (
    <StyledModal {...modalProps}>
      <ModalContent
        rowData={memberAddressList}
        refGrid={refGrid}
        onSelectionChanged={onSelectionChanged}
      />
    </StyledModal>
  );
};

const ModalContent = (props) => {
  const { rowData, refGrid, onSelectionChanged } = props;

  return (
    <div className='ag-member-address__wrapper'>
      <AgGridClient
        rowData={rowData}
        refGrid={refGrid}
        columnDefs={columns}
        containerStyle={{ height: 400 }}
        rowSelection={'single'}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  );
};

export default ProductHeaderContactInfoEditModal;
