import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Input, notification, Spin } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import {
  Form,
  StyledModal,
  ButtonSmallIcon,
  ContainerButton,
  ItemButton,
} from 'common/components';

import { addSearchProductsToApl } from 'services/apl';
import { ROUTE } from 'static/Constants';
import { useGetQuery } from 'hooks/useQuery';

import _ from 'lodash';

import { handlePayloadParamsQuery } from 'utils/handlePayloadParamsQuery';

import './AddProductToNewApl.less';
import { handleCreateSearchContainer } from 'pages/reporting/utils';

const AddSearchProductToNewApl = ({
  disabledFilterSearch,
  searchCategoriesEffected,
  searchText,
  filterProducts,
  queryConditions,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <ContainerButton>
        <ItemButton>
          <ButtonSmallIcon
            icon={<AppstoreAddOutlined style={{ height: 26 }} />}
            label={Messages.addSearchProductToNewApl}
            onClick={() => setVisibleModal(true)}
            className='button-item-dropdown'
            disabled={disabledFilterSearch}
          />
        </ItemButton>
      </ContainerButton>

      {visibleModal && (
        <ModalFormCreateApl
          visibleModal={visibleModal}
          turnOffModal={() => setVisibleModal(false)}
          searchCategoriesEffected={searchCategoriesEffected}
          searchText={searchText}
          filterProducts={filterProducts}
          queryConditions={queryConditions}
        />
      )}
    </>
  );
};

const layoutForm = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 },
};
const ModalFormCreateApl = ({
  visibleModal,
  turnOffModal,
  searchCategoriesEffected,
  searchText,
  filterProducts,
  queryConditions,
}) => {
  const [formInstance] = Form.useForm();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const query = useGetQuery();
  const memberId = query.get('memberId');

  const isMemberProductView = ROUTE.MEMBER_PRODUCTS === pathname;
  const handleSubmitForm = () => {
    formInstance.validateFields().then(() => {
      setLoading(true);
      let params = formInstance.getFieldsValue();
      params.search = { searchText: searchText };
      let filters = [];
      let arrValue = [];

      //* Add filter by member for member product view
      if (isMemberProductView && memberId) {
        filters.push({
          fieldName: 'ownerId',
          value: parseInt(memberId),
          filterType: 'Equal',
        });
      }

      if (filterProducts.indexOf('subscription') > -1) {
        filters.push({
          fieldName: 'subscription',
          value: true,
          filterType: 'Equal',
        });
      }
      if (filterProducts.indexOf('syndication') > -1) {
        filters.push({
          fieldName: 'syndication',
          value: true,
          filterType: 'Equal',
        });
      }
      if (filterProducts.indexOf('owner') > -1) {
        filters.push({
          fieldName: 'isOwner',
          value: true,
          filterType: 'Equal',
        });
      }
      if (filterProducts.indexOf('pending') > -1) {
        filters.push({
          fieldName: 'status',
          value: 'Pending',
          filterType: 'Equal',
        });
      }
      if (
        filterProducts.indexOf('Pallet') > -1 ||
        filterProducts.indexOf('Master Case') > -1 ||
        filterProducts.indexOf('Case') > -1 ||
        filterProducts.indexOf('Inner Pack') > -1 ||
        filterProducts.indexOf('Unit') > -1
      ) {
        if (filterProducts.indexOf('Pallet') > -1) {
          arrValue.push('Pallet');
        }
        if (filterProducts.indexOf('Master Case') > -1) {
          arrValue.push('Master Case');
        }
        if (filterProducts.indexOf('Case') > -1) {
          arrValue.push('Case');
        }
        if (filterProducts.indexOf('Inner Pack') > -1) {
          arrValue.push('Inner Pack');
        }
        if (filterProducts.indexOf('Unit') > -1) {
          arrValue.push('Unit');
        }
      }
      params.filters = filters;

      const advancedSearch = handleCreateSearchContainer(queryConditions);

      params.advancedSearchContainer = advancedSearch;
      params.searchCategory = searchCategoriesEffected;

      addSearchProductsToApl(params)
        .then((resp) => {
          if (resp.isSuccess) {
            notification.success({
              message: intl.formatMessage(
                messagesProduct.addSearchProductToAplSuccess
              ),
            });

            turnOffModal();
          } else {
            notification.error({
              message: resp?.message,
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: error,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const modalProps = {
    title: intl.formatMessage(messagesProduct.addSearchProductToAplLabel),
    accept: '.xlsx, .xls, .csv',
    destroyOnClose: true,
    visible: visibleModal,
    onCancel: turnOffModal,
    onOk: handleSubmitForm,
    okButtonProps: { loading: loading },
    cancelButtonProps: { disabled: loading },
    maskClosable: false,
    closable: !loading,
  };

  return (
    <StyledModal {...modalProps}>
      <Spin
        spinning={loading}
        tip='Add search results to new APL...'
        size='large'
      >
        <Form
          {...layoutForm}
          form={formInstance}
          name='formAddSearchProductToNewApl'
        >
          <Form.Item
            name='aplName'
            label='Name:'
            colon={false}
            rules={[{ required: true, message: 'Please input APL name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='aplDescription' label='Description:' colon={false}>
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </StyledModal>
  );
};

export default AddSearchProductToNewApl;
