import { types } from '@babel/core';
import * as type from './constants';

/**
 * call login saga, send request data (username, password) to saga to call api
 * @param {object} user
 */
export function login(user) {
  return {
    type: type.LOGIN,
    user: user,
  };
}
/**
 * apply user information when user has logged in successfully
 * @param {object} user
 */
export function loginSuccess(user) {
  return {
    type: type.LOGIN_SUCCESS,
    user: { ...user },
  };
}
/**
 * update error message when user has logged in fail
 * @param {string} message
 */
export function loginFailure(message) {
  return {
    type: type.LOGIN_ERROR,
    message,
  };
}

/**
 * call login saga, send request data (username, password) to saga to call api
 * @param {object} user
 */
export function loginAnon(payload) {
  return {
    type: type.ANON_LOGIN,
    payload,
  };
}

/**
 * reset login
 */
export function reset() {
  return {
    type: type.RESET_LOGIN,
  };
}

/**
 * call userProfile saga to get user profile information
 */
export const getUserProfile = () => ({
  type: type.GET_USER_PROFILE,
});

/**
 * update user profile data when api success
 */
export const getUserProfileSuccess = (data) => ({
  type: type.GET_USER_PROFILE_SUCCESS,
  payload: data,
});
/**
 * update error data when api fail
 */
export const getUserProfileFailure = (err) => ({
  type: type.GET_USER_PROFILE_FAILURE,
  payload: err,
});

/**
 * call userProfile saga to get user profile information
 */
export const getUserProfileActivityLogs = () => ({
  type: type.GET_USER_PROFILE_ACTIVITY_LOGS,
});

/**
 * update user profile data when api success
 */
export const getUserProfileActivityLogsSuccess = (data) => ({
  type: type.GET_USER_PROFILE_ACTIVITY_LOGS_SUCCESS,
  payload: data,
});
/**
 * update error data when api fail
 */
export const getUserProfileActivityLogsFailure = (err) => ({
  type: type.GET_USER_PROFILE_ACTIVITY_LOGS_FAILURE,
  payload: err,
});

/**
 * call userProfile locations saga to get user profile locations
 */

export const getUserProfileLocations = (userId, pageIndex, pageSize) => ({
  type: type.GET_USER_PROFILE_LOCATIONS,
  userId,
  pageIndex,
  pageSize,
});

/**
 * update user profile locations data when api success
 */
export const getUserProfileLocationsSuccess = (data) => ({
  type: type.GET_USER_PROFILE_LOCATIONS_SUCCESS,
  payload: data,
});

/**
 * update error data when api fail
 */
export const getUserProfileLocationsFailure = (err) => ({
  type: type.GET_USER_PROFILE_LOCATIONS_FAILURE,
  payload: err,
});

export function userProfileLocationsGridColumnInfo(gridName) {
  return {
    type: type.USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO,
    gridName,
  };
}

export function userProfileLocationsGridColumnInfoSuccess(columns) {
  return {
    type: type.USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO_SUCCESS,
    columns,
  };
}

export function userProfileLocationsGridColumnInfoError(error) {
  return {
    type: type.USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO_ERROR,
    error,
  };
}

export function userProfileActivityLogsGridColumnInfo(gridNameActivityLogs) {
  return {
    type: type.USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO,
    gridNameActivityLogs,
  };
}

export function userProfileActivityLogsGridColumnInfoSuccess(
  columnsActivityLogs
) {
  return {
    type: type.USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_SUCCESS,
    columnsActivityLogs,
  };
}

export function userProfileActivityLogsGridColumnInfoError(error) {
  return {
    type: type.USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_ERROR,
    error,
  };
}

// Update activity-log tab
export const updateIsActivityLogTab = (isActivityLogTab) => ({
  type: type.UPDATE_IS_ACTIVITY_LOG_TAB,
  isActivityLogTab,
});

export const toggleEditUserMode = (isEditMode) => ({
  type: type.TOGGLE_EDIT_USER_MODE,
  isEditMode,
});

//* GET USER ARTICLE
export const getUserArticle = (payload) => ({
  type: type.GET_ALL_USER_ARTICLE,
  payload,
});

export const getUserArticleSuccess = (payload) => ({
  type: type.GET_ALL_USER_ARTICLE_SUCCESS,
  payload,
});

export const getUserArticleFail = (payload) => ({
  type: type.GET_ALL_USER_ARTICLE_FAILURE,
  payload,
});

//* TOGGLE_SPLASH_SCREEN
export const toggleSplashScreen = (status) => ({
  type: type.TOGGLE_SPLASH_SCREEN,
  status,
});

//* CONTACT CARD
export const updateContactCard = (payload) => ({
  type: type.UPDATE_CONTACT_CARD,
  payload,
});
// Get User profile header
export const getUserProfileHeader = (userId) => ({
  type: type.GET_USER_PROFILE_HEADER,
  userId,
});

export const getUserProfileHeaderSuccess = (data) => ({
  type: type.GET_USER_PROFILE_HEADER_SUCCESS,
  payload: data,
});

export const getUserProfileHeaderFailure = (error) => ({
  type: type.GET_USER_PROFILE_HEADER_FAILURE,
  payload: error,
});

export const getMemberId = (memberId) => ({
  type: type.GET_MEMBER_ID,
  memberId,
});

// get user workflow roles
export const getUserWorkflowRoles = () => ({
  type: type.GET_USER_WORKFLOW_ROLES,
});

export const getUserWorkflowRolesSuccess = (data) => ({
  type: type.GET_USER_WORKFLOW_ROLES_SUCCESS,
  data,
});

export const getUserWorkflowRolesError = (error) => ({
  type: type.GET_USER_WORKFLOW_ROLES_ERROR,
  error,
});

// get all workflow templates to start workflow
export const getProductWorkflowTemplateSuccess = (data) => ({
  type: type.GET_ALL_PRODUCT_WORKFLOW_TEMPLATES,
  data,
});

export const getMemberWorkflowTemplateSuccess = (data) => ({
  type: type.GET_ALL_MEMBER_WORKFLOW_TEMPLATES,
  data,
});

export const getDigitalMediaWorkflowTemplateSuccess = (data) => ({
  type: type.GET_ALL_DIGITAL_MEDIA_WORKFLOW_TEMPLATES,
  data,
});

export const updateUserInfo = (userInfo) => ({
  type: type.UPDATE_USER_INFO,
  userInfo,
});
export const updateUserProfile = (userProfile) => ({
  type: type.UPDATE_USER_PROFILE,
  userProfile,
});

export const updateMfaLoginInfo = (payload) => ({
  type: type.UPDATE_MFA_LOGIN_INFO,
  payload,
});

export const updateUnlockAccountLoginInfo = (payload) => ({
  type: type.UPDATE_UNLOCK_ACCOUNT_LOGIN_INFO,
  payload,
});

export const toggleLoading = (boolean) => ({
  type: type.TOGGLE_LOADING,
  boolean,
});

export const loggingWithOtp = (payload) => ({
  type: type.lOGIN_WITH_OTP,
  payload,
});

export const loggingWithUnlockAccount = (payload) => ({
  type: type.lOGIN_WITH_UNLOCK_ACCOUNT,
  payload,
});

export const startFlowOfUnlockAccount = (payload) => ({
  type: type.START_FLOW_OF_UNLOCK_ACCOUNT,
  payload,
});
