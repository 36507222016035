import { handleCallApiReducer } from 'utils/api';

const initColSetting = {
  minWidth: 200,
};

export const mapToCommonFetchState = (
  action,
  _draft,
  field,
  isKeepPrevData
) => {
  handleCallApiReducer(action, _draft, field, isKeepPrevData);
};

export const mapToIngredientColDef = (columns, nameOfIngredientList) => {
  if (!columns || columns?.length === 0) return [];

  const hiddenFields = [
    'IngredientStatement',
    'OrganicOrBio',
    'IngredientsProductClaims',
  ];
  const extendedFields = [
    'PercentAddedOnProduction',
    'IngredientsCountryOfOrigin',
  ];

  let nextCols = columns.map((fieldItem) => {
    let initColSetting = {
      field: fieldItem?.field,
      minWidth: 200,
      type: 'editableColumn',
    };

    if (fieldItem?.field === 'IngredientsPartOf') {
      initColSetting = {
        ...initColSetting,
        ...fieldItem,
        minWidth: 200,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['', ...nameOfIngredientList],
        },
      };
    }

    if (fieldItem?.field === 'Declare') {
      initColSetting = {
        ...initColSetting,
        ...fieldItem,
        minWidth: 110,
        cellRenderer: 'CheckboxRender',
      };
    }

    if (extendedFields.includes(fieldItem?.field)) {
      initColSetting = {
        ...initColSetting,
        ...fieldItem,
        minWidth: 300,
      };
    }

    if (hiddenFields.indexOf(fieldItem?.field) > -1) {
      initColSetting = {
        ...initColSetting,
        ...fieldItem,
        hide: true,
        suppressToolPanel: true,
      };
    }

    return { ...initColSetting, ...fieldItem };
  });

  //* add hierachy level idx
  nextCols = [
    {
      ...initColSetting,
      field: 'error',
      displayName: 'Edit status',
      minWidth: 120,
      cellRenderer: 'StatusRender',
    },
    {
      ...initColSetting,
      field: 'levelIdx',
      displayName: 'Level',
      minWidth: 120,
    },
    ...nextCols,
  ];

  return nextCols;
};

export const mapToFormulaColDef = (columns) => {
  if (!columns || columns?.length === 0) return [];
  const hiddenFields = ['FormulaProductClaims', 'FormulaPartOf'];
  const extendedFields = [
    'TechnicalDesignation_IUPAC_INCI',
    'ClassificationAndRiskPhrases',
    'PercentOfRawMaterialSubstance',
    'PercentOfIncorporatedRawMaterial',
    'PercentOfFinishedProductSubstance',
  ];

  return columns.map((fieldItem) => {
    let initColSetting = {
      field: fieldItem?.field,
      minWidth: 200,
    };

    if (fieldItem?.field === 'Declare') {
      initColSetting = {
        ...initColSetting,
        ...fieldItem,
        minWidth: 110,
        cellRenderer: 'CheckboxRender',
      };
    }

    if (extendedFields.includes(fieldItem?.field)) {
      initColSetting = {
        ...initColSetting,
        ...fieldItem,
        minWidth: 300,
      };
    }

    if (hiddenFields.indexOf(fieldItem?.field) > -1) {
      initColSetting = {
        ...initColSetting,
        ...fieldItem,
        hide: true,
        suppressToolPanel: true,
      };
    }

    return { ...initColSetting, ...fieldItem };
  });
};

export const mapToAllergenColDef = (columns) => {
  if (!columns || columns?.length === 0) return [];

  const valueCols = [
    'IntentionallyPresentAsIngredient',
    'PresentAsResultPotentialContamination',
  ];

  return columns.map((fieldItem) => {
    fieldItem = { ...fieldItem, ...initColSetting };

    if (valueCols.includes(fieldItem?.field)) {
      fieldItem = { ...fieldItem, maxWidth: 310 };
    } else {
      fieldItem = {
        ...fieldItem,
        cellRenderer: 'StringTooltipRender',
      };
    }

    return fieldItem;
  });
};

export const mapIngredientGridTo = () => {};
