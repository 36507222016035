const NutritionBreakdown = (nutrition) => {
  // const nullObject = { nutritionName: 'null', quantity: 0, quantityUom: 'GRM' };

  let totalFat =
    nutrition?.find((o) => o.nutritionTypeCode === 'Fat, Total (fat, total)') !=
    null
      ? {
          ...nutrition?.find(
            (o) => o.nutritionTypeCode === 'Fat, Total (fat, total)'
          ),
          nutritionName: 'Total Fat',
          nutritionTypeCode: 'FAT',
        }
      : null;

  let saturatedFat =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode === 'Fat, Saturated (fatty acids, total saturated)'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Fat, Saturated (fatty acids, total saturated)'
          ),
          nutritionName: 'Saturated Fat',
          nutritionTypeCode: 'FASAT',
        }
      : null;

  let transFat =
    nutrition?.find(
      (o) => o.nutritionTypeCode === 'Fat, Trans (fatty acids, total trans)'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode === 'Fat, Trans (fatty acids, total trans)'
          ),
          nutritionName: 'Trans fat',
          nutritionTypeCode: 'FATRN',
        }
      : null;

  let polyunsaturatedFat =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Fat, Polyunsaturated (fatty acids, total polyunsaturated)'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Fat, Polyunsaturated (fatty acids, total polyunsaturated)'
          ),
          nutritionName: 'Polyunsaturated Fat',
          nutritionTypeCode: 'FAPU',
        }
      : null;

  let monounsaturatedFat =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Fat, Monounsaturated  (fatty acids, total monounsaturated)'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Fat, Monounsaturated  (fatty acids, total monounsaturated)'
          ),
          nutritionName: 'Monounsaturated Fat',
          nutritionTypeCode: 'FAMS',
        }
      : null;

  let cholesterol =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Cholesterol; Method Of Determination Unknown Or Variable'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Cholesterol; Method Of Determination Unknown Or Variable'
          ),
          nutritionName: 'Cholesterol',
          nutritionTypeCode: 'CHOL_',
        }
      : null; //{ nutritionName: 'Cholesterol', quantity: 0, quantityUom: 'MGM' };

  let sodium =
    nutrition?.find((o) => o.nutritionTypeCode === 'Sodium') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Sodium'),
          nutritionName: 'Sodium',
          nutritionTypeCode: 'NA',
        }
      : null;

  let totalCarbohydrate =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Carbohydrate, Total; Method Of Determination Unknown Or Variable'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Carbohydrate, Total; Method Of Determination Unknown Or Variable'
          ),
          nutritionName: 'Carbohydrates',
          nutritionTypeCode: 'CHO_',
        }
      : null;

  let dietaryFiber =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Dietary Fibre, total (fibre, total dietary; Wenlock modification)'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Dietary Fibre, total (fibre, total dietary; Wenlock modification)'
          ),
          nutritionName: 'Dietary Fibre',
          nutritionTypeCode: 'FIBTG',
        }
      : null;

  let totalSugars =
    nutrition?.find((o) => o.nutritionTypeCode === 'Sugars, Total') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Sugars, Total'),
          nutritionName: 'Total Sugars',
          nutritionTypeCode: 'SUGAR',
        }
      : null;

  let addedSugars =
    nutrition?.find((o) => o.nutritionTypeCode === 'Sugar, Added') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Sugar, Added'),
          nutritionName: 'Added Sugars',
          nutritionTypeCode: 'SUGAD',
        }
      : null;

  let sugarAlcohol =
    nutrition?.find((o) => o.nutritionTypeCode === 'Sugar Alcohol') != null
      ? nutrition?.find((o) => o.nutritionTypeCode === 'Sugar Alcohol')
      : null;

  let protein =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Protein, Total; Method Of Determination Unknown Or Variable'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Protein, Total; Method Of Determination Unknown Or Variable'
          ),
          nutritionName: 'Protein',
          nutritionTypeCode: 'PRO_',
        }
      : null;

  let vitaminD =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Vitamin D (vitamin D; calculated by summation of ergocalciferol and cholecalciferol)'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Vitamin D (vitamin D; calculated by summation of ergocalciferol and cholecalciferol)'
          ),
          nutritionName: 'Vitamin D',
          nutritionTypeCode: 'VITD',
        }
      : null;

  let calcium =
    nutrition?.find((o) => o.nutritionTypeCode === 'Calcium') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Calcium'),
          nutritionName: 'Calcium',
          nutritionTypeCode: 'CA',
        }
      : null;

  let iron =
    nutrition?.find((o) => o.nutritionTypeCode === 'Iron (iron, total)') != null
      ? {
          ...nutrition?.find(
            (o) => o.nutritionTypeCode === 'Iron (iron, total)'
          ),
          nutritionName: 'Iron',
          nutritionTypeCode: 'FE',
        }
      : null;

  let potassium =
    nutrition?.find((o) => o.nutritionTypeCode === 'Potassium') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Potassium'),
          nutritionName: 'Potassium',
          nutritionTypeCode: 'K',
        }
      : null;

  let vitaminA =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Vitamin A; Calculated By Summation Of The Vitamin A Activities Of Retinol And The Active Carotenoids'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Vitamin A; Calculated By Summation Of The Vitamin A Activities Of Retinol And The Active Carotenoids'
          ),
          nutritionName: 'Vitamin A',
          nutritionTypeCode: 'VITA',
        }
      : null;

  let VitaminC =
    nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin C') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin C'),
          nutritionName: 'Vitamin C',
          nutritionTypeCode: 'VITC',
        }
      : null;

  let VitaminE =
    nutrition?.find(
      (o) =>
        o.nutritionTypeCode ===
        'Vitamin E; Calculated By Summation Of The Vitamin E Activities Of The Active Tocopherols And Tocotrienols; Expressed As Alpha-Tocopherol Equivalents'
    ) != null
      ? {
          ...nutrition?.find(
            (o) =>
              o.nutritionTypeCode ===
              'Vitamin E; Calculated By Summation Of The Vitamin E Activities Of The Active Tocopherols And Tocotrienols; Expressed As Alpha-Tocopherol Equivalents'
          ),
          nutritionName: 'Vitamin E',
          nutritionTypeCode: 'VITE',
        }
      : null;

  let VitaminK =
    nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin K') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin K'),
          nutritionName: 'Vitamin K',
          nutritionTypeCode: 'VITK',
        }
      : null;

  let thiamin =
    nutrition?.find((o) => o.nutritionTypeCode === 'Thiamin') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Thiamin'),
          nutritionName: 'Thiamin',
          nutritionTypeCode: 'THIA',
        }
      : null;

  let riboflavin =
    nutrition?.find((o) => o.nutritionTypeCode === 'Riboflavin') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Riboflavin'),
          nutritionName: 'Riboflavin',
          nutritionTypeCode: 'RIBF',
        }
      : null;

  let folicAcid =
    nutrition?.find(
      (o) => o.nutritionTypeCode === 'Folic Acid, Synthetic Folic Acid'
    ) != null
      ? {
          ...nutrition?.find(
            (o) => o.nutritionTypeCode === 'Folic Acid, Synthetic Folic Acid'
          ),
          nutritionName: 'Folic Acid',
          nutritionTypeCode: 'FOLAC',
        }
      : null;

  let niacin =
    nutrition?.find((o) => o.nutritionTypeCode === 'Niacin, Preformed') != null
      ? {
          ...nutrition?.find(
            (o) => o.nutritionTypeCode === 'Niacin, Preformed'
          ),
          nutritionName: 'Niacin',
          nutritionTypeCode: 'NIA',
        }
      : null;

  let vitaminB6 =
    nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin B6 Unk.') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin B6 Unk.'),
          nutritionName: 'Vitamin B6',
          nutritionTypeCode: 'VITB6_',
        }
      : null;

  let folate =
    nutrition?.find((o) => o.nutritionTypeCode === 'Folate, Total') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Folate, Total'),
          nutritionName: 'Folate',
          nutritionTypeCode: 'FOL',
        }
      : null;

  let vitaminB12 =
    nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin B-12') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Vitamin B-12'),
          nutritionName: 'Vitamin B12',
          nutritionTypeCode: 'VITB12',
        }
      : null;

  let biotin =
    nutrition?.find((o) => o.nutritionTypeCode === 'Biotin') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Biotin'),
          nutritionName: 'Biotin',
          nutritionTypeCode: 'BIOT',
        }
      : null;

  let pantothenicAcid =
    nutrition?.find((o) => o.nutritionTypeCode === 'Pantothenic Acid') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Pantothenic Acid'),
          nutritionName: 'Pantothenic Acid',
          nutritionTypeCode: 'PANTAC',
        }
      : null;

  let phosphorus =
    nutrition?.find((o) => o.nutritionTypeCode === 'Phosphorus') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Phosphorus'),
          nutritionName: 'Phosphorus',
          nutritionTypeCode: 'P',
        }
      : null;
  let magnesium =
    nutrition?.find((o) => o.nutritionTypeCode === 'Magnesium') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Magnesium'),
          nutritionName: 'Magnesium',
          nutritionTypeCode: 'MG',
        }
      : null;

  let zinc =
    nutrition?.find((o) => o.nutritionTypeCode === 'Zinc') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Zinc'),
          nutritionName: 'Zinc',
          nutritionTypeCode: 'ZN',
        }
      : null;

  let selenium =
    nutrition?.find((o) => o.nutritionTypeCode === 'Selenium, Total') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Selenium, Total'),
          nutritionName: 'Selenium',
          nutritionTypeCode: 'SE',
        }
      : null;

  let copper =
    nutrition?.find((o) => o.nutritionTypeCode === 'Copper') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Copper'),
          nutritionName: 'Copper',
          nutritionTypeCode: 'CU',
        }
      : null;

  let manganese =
    nutrition?.find((o) => o.nutritionTypeCode === 'Manganese') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Manganese'),
          nutritionName: 'Manganese',
          nutritionTypeCode: 'MN',
        }
      : null;

  let caffeine =
    nutrition?.find((o) => o.nutritionTypeCode === 'Caffeine') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Caffeine'),
          nutritionName: 'Caffeine',
          nutritionTypeCode: 'CAFFN',
        }
      : null;

  let chromium =
    nutrition?.find((o) => o.nutritionTypeCode === 'Chromium') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Chromium'),
          nutritionName: 'Chromium',
          nutritionTypeCode: 'CR',
        }
      : null;

  let erythritol =
    nutrition?.find((o) => o.nutritionTypeCode === 'Erythritol') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Erythritol'),
          nutritionName: 'Erythritol',
          nutritionTypeCode: 'ERYTHL',
        }
      : null;

  let fluoride =
    nutrition?.find((o) => o.nutritionTypeCode === 'Fluoride') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Fluoride'),
          nutritionName: 'Fluoride',
          nutritionTypeCode: 'FD',
        }
      : null;

  let fibreWaterInsoluble =
    nutrition?.find((o) => o.nutritionTypeCode === 'Fibre, Water-Insoluble') !=
    null
      ? {
          ...nutrition?.find(
            (o) => o.nutritionTypeCode === 'Fibre, Water-Insoluble'
          ),
          nutritionName: 'Insoluble Fibre',
          nutritionTypeCode: 'FIBINS',
        }
      : null;

  let fibreWaterSoluble =
    nutrition?.find((o) => o.nutritionTypeCode === 'Fibre, Water-Soluble') !=
    null
      ? {
          ...nutrition?.find(
            (o) => o.nutritionTypeCode === 'Fibre, Water-Soluble'
          ),
          nutritionName: 'Soluble Fibre',
          nutritionTypeCode: 'FIBSOL',
        }
      : null;

  //* Iron already exist
  // let IronHeam =
  //   nutrition?.find((o) => o.nutritionTypeCode === 'HAEM') != null
  //     ? nutrition?.find((o) => o.nutritionTypeCode === 'HAEM')
  //     : null;

  let iodide =
    nutrition?.find((o) => o.nutritionTypeCode === 'Iodide') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Iodide'),
          nutritionName: 'Iodine',
          nutritionTypeCode: 'ID',
        }
      : null;

  let maltitol =
    nutrition?.find((o) => o.nutritionTypeCode === 'Maltitol') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Maltitol'),
          nutritionName: 'Maltitol',
          nutritionTypeCode: 'MALTL',
        }
      : null;

  let sorbitol =
    nutrition?.find((o) => o.nutritionTypeCode === 'Sorbitol') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Sorbitol'),
          nutritionName: 'Sorbitol',
          nutritionTypeCode: 'SORTL',
        }
      : null;

  let xylitol =
    nutrition?.find((o) => o.nutritionTypeCode === 'Xylitol') != null
      ? {
          ...nutrition?.find((o) => o.nutritionTypeCode === 'Xylitol'),
          nutritionName: 'Xylitol',
          nutritionTypeCode: 'XYLTL',
        }
      : null;

  const primaryNutrition = {
    totalFat,
    saturatedFat,
    transFat,
    polyunsaturatedFat,
    monounsaturatedFat,
    cholesterol,
    sodium,
    fluoride,
    totalCarbohydrate,
    xylitol,
    dietaryFiber,
    fibreWaterInsoluble,
    fibreWaterSoluble,
    totalSugars,
    addedSugars,
    sugarAlcohol,
    sorbitol,
    maltitol,
    erythritol,
    protein,
  };
  const secondaryNutrition = {
    iodide,
    vitaminD,
    calcium,
    iron,
    potassium,
    vitaminA,
    VitaminC,
    VitaminE,
    VitaminK,
    thiamin,
    riboflavin,
    folicAcid,
    niacin,
    vitaminB6,
    folate,
    vitaminB12,
    biotin,
    pantothenicAcid,
    phosphorus,
    magnesium,
    zinc,
    selenium,
    copper,
    manganese,
    chromium,

    caffeine,
  };

  return {
    primaryNutrition,
    secondaryNutrition,
  };
};

export default NutritionBreakdown;
