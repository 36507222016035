import _ from 'lodash';
import React from 'react';
import { Col, Row } from 'antd';

import SeparatorBar from '../../components/SeparatorBar';
import { addDefaultNutrient } from './utils';
import sortByKey from 'utils/sortByKey';

import './PetNutritionFacts.less';

const PetNutritionFacts = ({ label: property }) => {
  const {
    fat,
    protein,
    carbohydrate,
    caloriesPerCup,
    calorieContent,
    measurementPerCup,
    measurementUomPerCup,
    enzymesAndMicroorganisms,
    nutritionalAdequacyStatement,
    nutrients,
  } = property;

  const sortedNutrient = addDefaultNutrient({ nutrients });
  const hasCaloriesStatement =
    fat || protein || carbohydrate || calorieContent || caloriesPerCup;

  const renderFrom = [
    ...(protein ? [`Protein ${protein}`] : []),
    ...(fat ? [`Fat ${fat}`] : []),
    ...(carbohydrate ? [`Carbohydrate ${carbohydrate}`] : []),
  ];

  return (
    <div class='pet-nutrition-facts'>
      <h1 className='pet-nutrition-facts__label'>Pet Nutrition Facts</h1>
      <SeparatorBar />
      <div className='pet-nutrition-facts__measurement'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>1 cup (8 oz standard measuring cup) =</span>
          <span>
            {measurementPerCup}
            {measurementUomPerCup}
          </span>
        </div>
      </div>
      <SeparatorBar height={7} />
      {hasCaloriesStatement && (
        <>
          <div className='pet-nutrition-facts__calories'>
            <div className='pet-nutrition-facts__calories-content'>
              <p>
                {calorieContent} {caloriesPerCup}
              </p>
            </div>
            {(protein || carbohydrate || fat) && (
              <p style={{ paddingLeft: 12 }}>From: {renderFrom?.join(', ')}</p>
            )}
          </div>
          <SeparatorBar height={3} />
        </>
      )}
      <div className='pet-nutrition-facts__nutrient'>
        <Row style={{ borderBottom: '1px solid' }}>
          <Col span={12} style={{ paddingLeft: 4, fontWeight: 700 }}>
            Nutrients
          </Col>
          <Col
            span={7}
            style={{ textAlign: 'right', paddingRight: 4, fontWeight: 700 }}
          >
            Guaranteed
          </Col>
          <Col
            span={5}
            style={{
              textAlign: 'right',
              borderLeft: '1px solid',
              paddingRight: 4,
              fontWeight: 700,
            }}
          >
            Per cup
          </Col>
        </Row>
        <NutrientsLabel nutrients={sortedNutrient} />
      </div>
      {(enzymesAndMicroorganisms || property?.fdaLabelFootNote) && (
        <>
          <SeparatorBar height={3} />{' '}
          <div className='pet-nutrition-facts__footnote'>
            {!!property?.fdaLabelFootNote && (
              <p>{property?.fdaLabelFootNote}</p>
            )}
            <p>{enzymesAndMicroorganisms}</p>
          </div>
        </>
      )}

      {nutritionalAdequacyStatement && (
        <>
          <SeparatorBar height={3} />
          <div className='pet-nutrition-facts__adequacy-statement'>
            <p>{nutritionalAdequacyStatement}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default PetNutritionFacts;

const NutrientsLabel = ({ nutrients }) => {
  return (
    <>
      {sortByKey(nutrients, 'index')?.map((nutrient) => {
        const {
          limit,
          offset,
          quantity,
          guaranteed,
          quantityUom,
          displayName,
          nutrientName,
          guaranteedUom,
        } = nutrient;

        return (
          <Row style={{ borderBottom: '1px solid' }}>
            <Col span={12} style={{ paddingLeft: offset || 4 }}>
              {displayName || nutrientName}{' '}
              <span>{limit ? `(${limit})` : ''}</span>
            </Col>
            <Col
              span={7}
              style={{
                textAlign: 'right',
                paddingRight: 4,
              }}
            >
              <p style={{ wordBreak: 'break-word' }}>
                {guaranteed}
                <span style={{ wordBreak: 'normal' }}>{guaranteedUom}</span>
              </p>
            </Col>
            <Col
              span={5}
              style={{
                textAlign: 'right',
                paddingRight: 4,
                borderLeft: '1px solid',
              }}
            >
              <p style={{ wordBreak: 'break-word' }}>
                {quantity ? (
                  <>
                    {quantity}
                    <span style={{ wordBreak: 'normal' }}>{quantityUom}</span>
                  </>
                ) : null}
              </p>
            </Col>
          </Row>
        );
      })}
    </>
  );
};
