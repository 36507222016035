import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Typography } from 'antd';
import { IconList } from 'common/components';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import './SearchCategories.less';

const { Title } = Typography;

const SearchCategories = (props) => {
  const searchCategoriesEffected = useSelector(
    selectorsGridView.makeSelectSearchCategoriesList()
  );

  return (
    <>
      {searchCategoriesEffected?.length > 0 && (
        <>
          <Row className='search-categories'>
            <Title className='search-categories__ele-text' level={5}>
              Categories:
            </Title>
            <IconList
              numberOfShowedItems={40}
              itemList={searchCategoriesEffected}
              size='default'
              tooltipPosition='top'
              noId
            />
          </Row>
        </>
      )}
    </>
  );
};

SearchCategories.propTypes = {};

export default SearchCategories;
