import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { CustomNotification } from 'common/components';

import {
  groupProductData,
  useInvalidateFactsPanel,
  useInvalidateGroupDetail,
} from 'pages/product-full-view/components/product-media-area/shared/hooks';

import * as productServices from 'services/product';
import { forwardTo } from 'utils/common/route';

import {
  useQueryDrugFactsPanel,
  useQueryFactsPanel,
  useQueryPetFoodFactsPanel,
  useQuerySupplementFactsPanel,
} from '../qa-nutrition-data/hook';

export const qaGroupProductData = {
  getQaDetailGroup: (productId, groupId) => [
    'group-product-data/get-qa-group-detail',
    parseInt(productId),
    parseInt(groupId),
  ],
  getAllProductQaDetailGroup: (productId) => [
    'group-product-data/get-qa-group-detail',
    parseInt(productId),
  ],
};

export const useQaFactsPanelsGroup = (productId) => {
  const { listFactsPanel = [] } = useQueryFactsPanel({
    id: productId,
  });

  const { listSupplementFactsPanel = [] } = useQuerySupplementFactsPanel({
    id: productId,
  });

  const { listDrugFactsPanel = [] } = useQueryDrugFactsPanel({
    id: productId,
  });

  const { listPetNutritionFacts = [] } = useQueryPetFoodFactsPanel({
    id: productId,
  });

  return {
    factsPanelsGroup: [
      ...listDrugFactsPanel,
      ...listFactsPanel,
      ...listSupplementFactsPanel,
      ...listPetNutritionFacts,
    ],
  };
};

export const useSaveQaGroupProduct = (productId, groupId) => {
  const queryClient = useQueryClient();

  const { handleSyncAllTabPanels } = useInvalidateFactsPanel(productId);
  const { handleSyncSingleDetailGroup } = useInvalidateGroupDetail(
    productId,
    groupId
  );

  return useMutation({
    mutationFn: productServices.saveQaGroupProductData,
    onSuccess: async (result) => {
      if (result?.isSuccess) {
        queryClient.invalidateQueries({
          queryKey: groupProductData.getAllGroups(productId),
        });
        handleSyncAllTabPanels();
        handleSyncSingleDetailGroup();
      } else {
        CustomNotification.error(result?.message ?? 'Something went wrong!');
      }
    },
    onError: (error) => {
      CustomNotification.error(error?.message ?? 'Something went wrong!');
    },
  });
};

export const useAssignQaGroupProduct = (productId, groupId) => {
  const queryClient = useQueryClient();

  const { handleSyncAllTabPanels } = useInvalidateFactsPanel(productId);
  const { handleSyncSingleQaDetailGroup } = useInvalidateGroupDetail(
    productId,
    groupId
  );

  return useMutation({
    mutationFn: productServices.assignQaGroupProductData,
    onSuccess: async (result) => {
      if (result?.isSuccess) {
        queryClient.invalidateQueries({
          queryKey: groupProductData.getAllGroups(productId),
        });
        handleSyncAllTabPanels();
        handleSyncSingleQaDetailGroup();
      } else {
        CustomNotification.error(result?.message ?? 'Something went wrong!');
      }
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });
};

export const useGetQaGroupDetail = ({ productId, groupId }) => {
  const queries = useQuery({
    queryKey: qaGroupProductData.getQaDetailGroup(productId, groupId),
    queryFn: async () => {
      const response = await productServices.getQaGroupDataDetail({
        productItemId: productId,
        groupId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && !!groupId,
  });

  return {
    groupDetail: queries?.data ?? null,
    ...queries,
  };
};

export const useDeleteQaGroupProduct = (productId) => {
  const queryClient = useQueryClient();

  const { handleSyncAllTabPanels } = useInvalidateFactsPanel(productId);

  return useMutation({
    mutationFn: productServices.deleteQaGroupProductData,
    onSuccess: async (result) => {
      if (result?.isSuccess) {
        queryClient.invalidateQueries({
          queryKey: groupProductData.getAllGroups(productId),
        });
        CustomNotification.success('Delete group successfully!');
        handleSyncAllTabPanels();
      } else {
        CustomNotification.error(result?.message ?? 'Something went wrong!');
      }
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });
};
