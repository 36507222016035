import { useState } from 'react';

const WithReloadInternalData = (props) => {
  //* use key to reset initial data
  const { initialData, reloadDataHandler, children } = props;

  const [data, setData] = useState(initialData);

  const reloadData = async (callback) => {
    if (!reloadDataHandler) return;
    const reloadedData = await reloadDataHandler();

    setData(reloadedData);

    callback && callback();
  };

  return children ? children(data, reloadData) : null;
};

export default WithReloadInternalData;
