/**
 * Workflow messages
 *
 * This contains all the text for the Workflow component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.subscription';

export default defineMessages({
  newGdsnSubscription: {
    id: `${scope}.mySubscription.ribbon.newGdsnSubscription`,
    defaultMessage: 'New GDSN Subscription',
  },
  gln: {
    id: `${scope}.mySubscription.components.gln`,
    defaultMessage: 'GLN',
  },
  glnIsRequired: {
    id: `${scope}.mySubscription.components.glnIsRequired`,
    defaultMessage: 'GLN is required!',
  },
  glnIsRequiredNumber: {
    id: `${scope}.mySubscription.components.glnIsRequiredNumber`,
    defaultMessage: 'GLN is required a number!',
  },
  gtin: {
    id: `${scope}.mySubscription.components.gtin`,
    defaultMessage: 'GTIN',
  },
  gtinIsRequired: {
    id: `${scope}.mySubscription.components.gtinIsRequired`,
    defaultMessage: 'GTIN is required!',
  },
  gtinIsRequiredNumber: {
    id: `${scope}.mySubscription.components.gtinIsRequiredNumber`,
    defaultMessage: 'GTIN is required a number!',
  },
  sourceGln: {
    id: `${scope}.mySubscription.components.sourceGln`,
    defaultMessage: 'Source GLN',
  },
  sourceGlnIsRequired: {
    id: `${scope}.mySubscription.components.sourceGlnIsRequired`,
    defaultMessage: 'Source GLN is required!',
  },
  recipientGln: {
    id: `${scope}.mySubscription.components.recipientGln`,
    defaultMessage: 'Recipient GLN',
  },
  recipientGlnIsRequired: {
    id: `${scope}.mySubscription.components.recipientGlnIsRequired`,
    defaultMessage: 'Recipient GLN is required!',
  },
  targeGln: {
    id: `${scope}.mySubscription.components.targeGln`,
    defaultMessage: 'Target GLN',
  },
  publisherGln: {
    id: `${scope}.mySubscription.components.publisherGln`,
    defaultMessage: 'Publisher GLN',
  },
  targetMarket: {
    id: `${scope}.mySubscription.components.targetMarket`,
    defaultMessage: 'Target market',
  },
  selectTargetMarket: {
    id: `${scope}.mySubscription.components.selectTargetMarket`,
    defaultMessage: 'Select target market',
  },
  targetMarketIsRequired: {
    id: `${scope}.mySubscription.components.targetMarketIsRequired`,
    defaultMessage: 'Target market is required!',
  },
  gpc: {
    id: `${scope}.mySubscription.components.gpc`,
    defaultMessage: 'GPC',
  },
  gpcIsRequired: {
    id: `${scope}.mySubscription.components.gpcIsRequired`,
    defaultMessage: 'GPC is required!',
  },
  search: {
    id: `${scope}.mySubscription.components.search`,
    defaultMessage: 'Search GLN',
  },
  select: {
    id: `${scope}.mySubscription.components.select`,
    defaultMessage: 'Select',
  },
  chooseBrickCode: {
    id: `${scope}.mySubscription.components.chooseBrickCode`,
    defaultMessage: 'Choose an Brick Code',
  },
  yourCheckDigit: {
    id: `${scope}.mySubscription.components.yourCheckDigit`,
    defaultMessage: 'Your check digit',
  },
  copyThisKey: {
    id: `${scope}.mySubscription.components.copyThisKey`,
    defaultMessage: 'Copy this key',
  },
  seeGTIN14Format: {
    id: `${scope}.mySubscription.components.seeGTIN14Format`,
    defaultMessage: 'See this in GTIN-14 format',
  },
  seeOriginalFormat: {
    id: `${scope}.mySubscription.components.seeOriginalFormat`,
    defaultMessage: 'See this in original format',
  },
  correctKeyGtinFormat: {
    id: `${scope}.mySubscription.components.correctKeyGtinFormat`,
    defaultMessage: 'This is the correct key with the GTIN-',
  },
  format: {
    id: `${scope}.mySubscription.components.format`,
    defaultMessage: 'format',
  },
});
