import { useRef } from 'react';
import { dialogFunction } from 'common/components';

import { Row, Col, Checkbox, Button, Typography, Divider } from 'antd';

import { useCreditCardEula } from './useCreditCardEula';
import * as paymentServices from 'services/payment';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/payment';

const { Title, Paragraph } = Typography;

export const useCreditCardChargeWarning = (props = {}) => {
  const intl = useIntl();
  const modalRef = useRef();
  const notShowAgainRef = useRef();

  const { onOkCallback } = props;

  const { fetchEula, shouldShowChargeWarning } = useCreditCardEula({
    context: 'charge-warning',
  });

  const onCancel = () => {
    modalRef.current?.destroy();
  };

  const onOk = async () => {
    closeModal();

    const notShowAgainResponse = notShowAgainRef.current
      ? await callApiNotShowAgain()
      : {};

    if (notShowAgainResponse?.isSuccess) fetchEula();

    onOkCallback && onOkCallback();
  };

  const onChangeNotShowAgain = (e) => {
    const checked = e.target?.checked;
    notShowAgainRef.current = checked;
  };

  const callApiNotShowAgain = () => {
    return paymentServices.disableChargeWarning();
  };

  const closeModal = () => {
    modalRef.current?.destroy();
  };

  const content = (
    <>
      <Title level={4}>
        {intl.formatMessage(Messages.creditCardChargeWarningTitle)}
      </Title>
      <Paragraph>
        {intl.formatMessage(Messages.creditCardChargeWarningMessage)}
      </Paragraph>

      <Divider style={{ margin: '12px 0px' }} />

      <Row>
        <Col>
          <Button onClick={onCancel}>
            {intl.formatMessage(Messages.creditCardChargeWarningCancelBtn)}
          </Button>
        </Col>
        <Col flex={1}></Col>
        <Col>
          <Button type='primary' onClick={onOk}>
            {intl.formatMessage(Messages.creditCardChargeWarningContinueBtn)}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col flex={1}></Col>
        <Col>
          <Checkbox onChange={onChangeNotShowAgain}>
            {intl.formatMessage(Messages.creditCardChargeWarningNotShowAgain)}
          </Checkbox>
        </Col>
      </Row>
    </>
  );

  const showCreditCardChargeWarning = () => {
    if (!shouldShowChargeWarning) {
      onOkCallback && onOkCallback();
      return;
    }

    modalRef.current = dialogFunction({
      type: 'warn',
      width: 500,
      className: 'credit-card__charge-warning',
      content,
    });
  };

  return { showCreditCardChargeWarning };
};
