import React from 'react';
import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import EditMarkupGridView from './components/controls/maintenance/markup/EditMarkupGridView';
import CreateMarkup from './components/controls/maintenance/markup/CreateMarkup';
import DeleteMarkupGridView from './components/controls/maintenance/markup/DeleteMarkupGridView';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const MaintenanceMarkupGridView = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.MARKUP}>
        <EditMarkupGridView />
        <CreateMarkup isSmallBtn />
        <DeleteMarkupGridView isSmallBtn />
        <RibbonDivider />
      </Can>
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default MaintenanceMarkupGridView;
