import React from 'react';
import PropTypes from 'prop-types';
// import { forwardTo } from 'utils/common/route';
import { useHistory } from 'react-router';
import ProductTileHeader from './ProductTileHeader';
import ProductTileBody from './ProductTileBody';
import ProductTileFooter from './ProductTileFooter';
import useDoubleClick from 'hooks/useDoubleClick';

import './ProductTile.less';

/**
 * A component displays product information under card layout
 * @param {object} props
 */
const ProductTile = (props) => {
  const { dataDetail } = props;
  const { onClickItemGrid, onDoubleClick } = props.clickEvents;
  const history = useHistory();

  const divRef = React.useRef();

  useDoubleClick({
    onSingleClick: (e) => {
      if (onClickItemGrid) onClickItemGrid(dataDetail, e);
    },
    onDoubleClick: (e) => {
      if (onDoubleClick) {
        history.push(`/product/${dataDetail?.id}`);
        // forwardTo(`${pathname}/${dataDetail?.id}`);
      }
    },
    ref: divRef,
    latency: 250,
  });

  return (
    <div className='product-tile'>
      {/*Placeholder for click events*/}
      <div
        style={{
          backgroundColor: 'transparent',
          position: 'absolute',
          zIndex: 30,
          width: '100%',
          height: '100%',
        }}
        ref={divRef}
      />
      <ProductTileHeader {...dataDetail} />
      <ProductTileBody {...dataDetail} />
      <ProductTileFooter {...dataDetail} />
    </div>
  );
};

ProductTile.propTypes = {
  /**
   * product data detail
   */
  dataDetail: PropTypes.object.isRequired,
};

export default ProductTile;
