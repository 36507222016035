import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as ProductComponent from '../../components/index';

const ProductDetail = (props) => {
  const { dataDetail } = props;
  return (
    <Fragment>
      <ProductComponent.ProductInfo dataDetail={dataDetail} />
      <ProductComponent.ProductPackageLevel dataDetail={dataDetail} />
      <ProductComponent.ProductIngredient dataDetail={dataDetail} />
      <ProductComponent.ProductAssets dataDetail={dataDetail} />
    </Fragment>
  );
};

ProductDetail.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductDetail;
