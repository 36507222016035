import React, { useMemo } from 'react';
import { areEqual } from 'react-window';

import { useInfiniteScroll } from './hook';
import DropZone from './DropZone';
import EntityProperty from './EntityProperty';
import RowDragging, { DragItem } from './RowDragging';
import { createMapFieldNameFromDisplayName } from 'pages/reporting/utils';
import WrapperCalculatorSize from 'common/components/wrapper/WrapperCalculatorSize';

import './EntitiesAndAttributes.less';

const RowAttributes = React.memo(RowDragging, areEqual);

export const EntitiesAndAttributes = ({
  dataSearch,
  searchText,
  propertiesData,
  isDragDisabled,
  selectedTaskIds,
  toggleSelection,
  toggleSelectionInGroup,
}) => {
  const { data, getDraggableId, onClickAttributes } = useInfiniteScroll({
    dataSearch,
    propertiesData,
  });

  const itemData = useMemo(() => {
    return {
      isDragDisabled,
      getDraggableId,
      children: ({ index, snapshot }) => {
        if (!data?.length || index == null) return;

        const item = data[index];
        const fieldName = item?.fieldName
          ? item?.fieldName
          : createMapFieldNameFromDisplayName(item?.displayName);

        return (
          <EntityProperty
            property={{ ...item, fieldName }}
            index={index}
            snapshot={snapshot}
            searchText={searchText}
            onClick={onClickAttributes}
            isDragDisabled={isDragDisabled}
            selectedTaskIds={selectedTaskIds}
            onToggleSelection={toggleSelection}
            onToggleSelectionInGroup={toggleSelectionInGroup}
          />
        );
      },
    };
  }, [JSON.stringify(data), JSON.stringify(selectedTaskIds)]);

  const renderClone = (provided, snapshot, rubric) => {
    const item = data[rubric?.source.index];
    return (
      <DragItem provided={provided} snapshot={snapshot}>
        <EntityProperty
          property={{ ...item, level: null }}
          snapshot={snapshot}
          selectedTaskIds={selectedTaskIds}
        />
      </DragItem>
    );
  };

  return (
    <WrapperCalculatorSize>
      {({ height }) => (
        <DropZone
          isDropDisabled={true}
          className='attributes_entity'
          droppableId='attributes_entity'
          renderClone={renderClone}
          height={height}
          width={'100%'}
          itemSize={35}
          itemCount={data?.length}
          itemData={itemData || []}
        >
          {RowAttributes}
        </DropZone>
      )}
    </WrapperCalculatorSize>
  );
};

export default EntitiesAndAttributes;
