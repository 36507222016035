import React, { useState, useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {
  Row,
  Col,
  Typography,
  DatePicker,
  TimePicker,
  Button,
  notification,
  Input,
} from 'antd';
import { SendOutlined, VideoCameraOutlined } from '@ant-design/icons';

import { Form } from 'common/components';
import { DescriptionMeeting } from 'pages/home/ribbon/components/controls/dashboard/ModalCreateMeeting';

import {
  concatenateTime,
  getInitialFormValues,
} from 'pages/home/ribbon/components/controls/dashboard/utils';

import { createNewMeeting } from 'services/dashboard';
import { editAplProductContactService } from 'services/aplDetail';

import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';
import { toggleReloadGrid } from 'pages/product-apl-detail/controllers/actions';

import { validateEmail } from 'utils';
import { disabledPreviousDays } from 'utils/timezone';

import { DATE_FORMAT } from 'static/Constants';

const Invite = (props) => {
  const {
    selectedProduct = {},
    sentProducts,
    addedEmail,
    cachedForm,
    setCachedForm,
    onUpdateSentProducts,
    onSaveUpdatedEmail,
    onUpdateProductList,
  } = props;

  const { id, description: descriptionProduct, contactEmail } = selectedProduct;
  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();

  const [description, setDescription] = useState('');
  const [statusSubmit, setStatusSubmit] = useState('idle');
  const [email, setEmail] = useState(null);

  const { status, dataForm, meetingId } =
    sentProducts.find((product) => product.id === id) ?? {};

  const foundCachedData = useMemo(() => cachedForm[id] ?? {}, [cachedForm, id]);

  const isDisabledSend = !validateEmail(email);

  const aplDetail = useSelector(makeSelectAplDetail());

  useEffect(() => {
    if (contactEmail) {
      setEmail(contactEmail);
    }
  }, [contactEmail]);

  useEffect(() => {
    if (id && !cachedForm[id]) {
      setCachedForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...getInitialFormValues(),
          title: descriptionProduct,
          email: contactEmail,
          description,
        },
      }));
    }
  }, [
    id,
    descriptionProduct,
    contactEmail,
    description,
    setCachedForm,
    cachedForm,
  ]);

  useEffect(() => {
    if (descriptionProduct) {
      formInstance.setFieldsValue({
        titleProduct: descriptionProduct,
      });
    }

    if (!isEmpty(dataForm)) {
      const { startTime, endTime, description, titleProduct } = dataForm;

      const formValues = {
        titleProduct,
        startDate: moment(startTime),
        startTime: moment(startTime),
        endDate: moment(endTime),
        endTime: moment(endTime),
      };

      formInstance.setFieldsValue(formValues);

      setDescription(description);
    }

    if (isEmpty(dataForm)) {
      const { email, title, description, ...otherData } = foundCachedData;

      formInstance.setFieldsValue({
        titleProduct: title,
        ...otherData,
      });

      setDescription(description);
      setEmail(email);
    }
  }, [dataForm, formInstance, descriptionProduct, foundCachedData, id]);

  const handleOpenMeetingView = () => {
    if (window && meetingId) {
      const { origin } = window.location;
      const url = `${origin}/meeting/${meetingId}`;
      window.open(url);
    }
  };

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);

    if (validateEmail(email)) {
      onSaveUpdatedEmail(email);

      let cacheData = cachedForm[id];
      cacheData = {
        ...cacheData,
        email,
      };

      setCachedForm((prevForm) => ({
        ...prevForm,
        [id]: cacheData,
      }));
    }
  };

  const handleSendMeeting = async () => {
    try {
      const { titleProduct, startDate, startTime, endDate, endTime } =
        await formInstance.validateFields();

      setStatusSubmit('loading');

      const newStartTime = concatenateTime(startDate, startTime);
      const newEndTime = concatenateTime(endDate, endTime);

      const params = {
        title: titleProduct.trim(),
        startTime: newStartTime,
        endTime: newEndTime,
        isStartMeetingNow: false,
        description,
        externalEmails: [email],
      };

      const response = await createNewMeeting(params);

      if (response?.isSuccess) {
        notification.success({
          message: 'Sent the invite successfully',
        });

        setStatusSubmit('success');
        onUpdateSentProducts({
          id,
          meetingId: response?.data?.id,
          status: 'success',
          dataForm: {
            startTime: newStartTime,
            endTime: newEndTime,
            description,
            titleProduct: titleProduct.trim(),
          },
        });

        onUpdateProductList(email);
        updateContactEmail();
      } else {
        notification.error({
          message: response?.message ?? 'Something went wrong!',
        });
        setStatusSubmit('error');
      }
    } catch (error) {}
  };

  const updateContactEmail = () => {
    const { isOwner } = aplDetail;
    const { id, contactName, contactPhone, category } = selectedProduct;
    const { isDraft } = addedEmail;

    if (!isOwner || !isDraft) return;

    const params = {
      aplProductId: id,
      contactEmail: email,
      contactName,
      contactPhone,
      category,
    };

    editAplProductContactService(params)
      .then((response) => {
        if (response?.isSuccess) {
          notification.success({
            message: 'Update contact email successfully!',
          });
          dispatch(toggleReloadGrid(true));
        } else {
          notification.error({
            message: response?.message ?? 'Something went wrong!',
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(toggleReloadGrid(false));
        }, 200);
      });
  };

  const onChangeField = (_, allValues) => {
    const { titleProduct, ...otherData } = allValues;

    let cacheData = cachedForm[id];
    cacheData = {
      ...cacheData,
      title: titleProduct,
      ...otherData,
    };

    setCachedForm((prevForm) => ({
      ...prevForm,
      [id]: cacheData,
    }));
  };

  const handleChangeDescription = (description) => {
    let cacheData = cachedForm[id];
    cacheData = {
      ...cacheData,
      description,
    };

    setCachedForm((prevForm) => ({
      ...prevForm,
      [id]: cacheData,
    }));

    setDescription(description);
  };

  return (
    <Col span={18}>
      <div className='send-meeting'>
        <HeaderMeeting
          email={email}
          status={status}
          onOpenMeetingView={handleOpenMeetingView}
          onChangeEmail={handleChangeEmail}
          selectedProduct={selectedProduct}
          setCachedForm={setCachedForm}
          foundCachedData={foundCachedData}
        />

        <FormSendMeeting
          formInstance={formInstance}
          onChangeField={onChangeField}
        />
        <DescriptionMeeting
          description={description}
          handleChangeDescription={handleChangeDescription}
          autoSize={{ minRows: 20, maxRows: 25 }}
        />
        <ButtonSendMeeting
          status={status}
          onSendMeeting={handleSendMeeting}
          disabled={isDisabledSend}
          isLoading={statusSubmit === 'loading'}
        />
      </div>
    </Col>
  );
};

const HeaderMeeting = (props) => {
  const { email, status, onOpenMeetingView, onChangeEmail, selectedProduct } =
    props;

  const [formInstance] = Form.useForm();

  const hasContactEmail = Boolean(selectedProduct?.contactEmail);

  const displayInputEmail = !status && !hasContactEmail;
  const displayTextEmail = hasContactEmail;

  useEffect(() => {
    formInstance.setFieldsValue({
      email,
    });
  }, [email, formInstance]);

  return (
    <Row style={{ marginBottom: 6, height: 45 }}>
      <Col span={3} style={{ marginTop: 4 }}>
        <Typography.Text
          style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 13 }}
          className='header-meeting__email'
        >
          Contact Email:
        </Typography.Text>
      </Col>
      <Col span={21}>
        <Row style={{ minHeight: 30 }}>
          <Col
            span={status === 'success' ? 20 : 24}
            style={{ paddingLeft: 14 }}
          >
            {displayInputEmail && (
              <Form
                form={formInstance}
                initialValues={{
                  email: email,
                }}
              >
                <Form.Item
                  name='email'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Email is not valid!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (validateEmail(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject();
                        }
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder='Input email'
                    style={{ width: '100%' }}
                    allowClear
                    enterButton
                    onChange={onChangeEmail}
                  />
                </Form.Item>
              </Form>
            )}

            {displayTextEmail && (
              <Typography.Text
                strong
                ellipsis
                style={{ color: '#1890ff', marginTop: 4 }}
              >
                {email}
              </Typography.Text>
            )}
          </Col>

          {status === 'success' && (
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button
                icon={<VideoCameraOutlined />}
                size='middle'
                type='primary'
                onClick={onOpenMeetingView}
              >
                <Typography.Text style={{ color: '#FFF' }}>
                  Go Meeting
                </Typography.Text>
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const ButtonSendMeeting = ({ onSendMeeting, disabled, isLoading, status }) => {
  const hiddenButton = status === 'success';

  return (
    <>
      {hiddenButton ? null : (
        <div style={{ textAlign: 'right', marginTop: 20, marginBottom: 10 }}>
          <Button
            icon={<SendOutlined />}
            size='middle'
            type='primary'
            onClick={onSendMeeting}
            loading={isLoading}
            disabled={disabled}
            className='button-send-meeting'
          >
            <Typography.Text style={{ color: '#FFF' }}>Send</Typography.Text>
          </Button>
        </div>
      )}
    </>
  );
};

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 24 },
};

const FormSendMeeting = ({ formInstance, onChangeField }) => {
  const handleChangeStartDate = () => {
    formInstance.setFieldsValue({
      ...formInstance.getFieldsValue(),
      endDate: null,
      endTime: null,
    });
  };

  const handleChangeEndDate = () => {
    formInstance.setFieldsValue({
      ...formInstance.getFieldsValue(),
      endTime: null,
    });
  };

  return (
    <Form {...layout} form={formInstance} onValuesChange={onChangeField}>
      <Row>
        <Col span={24}>
          <Form.Item
            label='Title'
            name='titleProduct'
            wrapperCol={{ span: 21 }}
            labelCol={{ span: 3 }}
            labelAlign='left'
            className='form-item'
            rules={[
              {
                required: true,
                message: 'Please input title',
              },
              () => ({
                validator(_, value) {
                  if (!value.trim()) {
                    return Promise.reject('Title should not be empty');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              style={{ marginLeft: 14, width: '98%' }}
              placeholder='Input title'
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={14}>
              <Form.Item
                label='Start Time'
                name='startDate'
                wrapperCol={{ span: 17 }}
                labelCol={{ span: 5 }}
                className='form-item'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Please input start date',
                  },
                ]}
              >
                <CustomDatePicker
                  picker='date'
                  disabledDate={disabledPreviousDays}
                  onChange={handleChangeStartDate}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item
                label={null}
                name='startTime'
                labelCol={{ span: 4 }}
                rules={[
                  {
                    required: true,
                    message: 'Please input start time',
                  },

                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const endTime = getFieldValue('endTime');
                      const endDate = getFieldValue('endDate');
                      const startDate = getFieldValue('startDate');

                      if (!value || !endTime || !startDate || !endDate) {
                        return Promise.resolve();
                      } else {
                        const newStartTime = concatenateTime(startDate, value);

                        const newEndTime = concatenateTime(endDate, endTime);

                        if (
                          newEndTime === newStartTime ||
                          newStartTime > newEndTime
                        ) {
                          return Promise.reject([
                            'Start time should be smaller than end time.',
                          ]);
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <TimePicker
                  use12Hours
                  format='h:mm a'
                  style={{ width: '100%' }}
                  showNow={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Form.Item
                label='End Time'
                name='endDate'
                wrapperCol={{ span: 17 }}
                labelCol={{ span: 5 }}
                className='form-item'
                labelAlign='left'
                rules={[
                  {
                    required: true,
                    message: 'Please input end date',
                  },
                ]}
              >
                <CustomDatePicker
                  picker='date'
                  disabledDate={disabledPreviousDays}
                  onChange={handleChangeEndDate}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item
                label={null}
                name='endTime'
                labelCol={{ span: 4 }}
                rules={[
                  {
                    required: true,
                    message: 'Please input end time',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const startTime = getFieldValue('startTime');
                      const startDate = getFieldValue('startDate');
                      const endDate = getFieldValue('endDate');

                      if (!value || !startTime || !startDate || !endDate) {
                        return Promise.resolve();
                      } else {
                        const newStartTime = concatenateTime(
                          startDate,
                          startTime
                        );
                        const newEndTime = concatenateTime(endDate, value);
                        if (newStartTime === newEndTime) {
                          return Promise.reject([
                            'End time should be greater than start time.',
                          ]);
                        }
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <TimePicker
                  use12Hours
                  format='h:mm a'
                  style={{ width: '100%' }}
                  showNow={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const CustomDatePicker = ({ onChange, ...rest }) => {
  return (
    <DatePicker
      picker='date'
      format={DATE_FORMAT.SYSTEM_FORMAT}
      style={{ width: 300, marginLeft: 16 }}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Invite;
