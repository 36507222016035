import React from 'react';

import classnames from 'classnames';

import './Containers.less';

/**
 * ! GENERAL COMPONENT - section wrapper
 * @param {*} props
 * @returns
 */
const QaSpecSectionContainer = (props) => {
  const { children, sectionName } = props;

  return (
    <div
      className={classnames(
        `qa-spec-detail-section__${sectionName}`,
        'qa-spec-detail-section'
      )}
    >
      {children && children}
    </div>
  );
};

/**
 * ! GENERAL COMPONENT - full cantainer
 * @param {*} props
 * @returns
 */
export const QaSpecContainer = (props) => {
  const { children, classNames, isTabExpanded } = props;

  return (
    <div
      className={classnames(
        `qa-spec-detail`,
        {
          'qa-spec-detail--expanded': isTabExpanded,
        },
        classNames
      )}
    >
      {children && children}
    </div>
  );
};

export const QaHeaderContainer = (props) => (
  <QaSpecSectionContainer {...props} sectionName='qa-header' />
);

export const QaHeaderDetailContainer = (props) => (
  <QaSpecSectionContainer {...props} sectionName='qa-header-detail' />
);

export const QaSkuContainer = (props) => (
  <QaSpecSectionContainer {...props} sectionName='qa-sku' />
);

export const QaSpecification = (props) => (
  <QaSpecSectionContainer {...props} sectionName='qa-specification' />
);
