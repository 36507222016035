import React from 'react';
import Messages from 'i18n/messages/home';
import { useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import { ShoppingOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

import { forwardTo } from 'utils/common/route';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const OpenProduct = () => {
  const selectedProductDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const productId =
    selectedProductDetail && selectedProductDetail.length > 0
      ? selectedProductDetail[0].id
      : undefined;

  return (
    <RibbonButton
      icon={<ShoppingOutlined style={{ height: 26 }} />}
      label={Messages.openProduct}
      onClick={() => forwardTo(`/product/${productId}`)}
      disabled={productId === undefined || selectedProductDetail.length !== 1}
    />
  );
};

OpenProduct.propTypes = {};

export default OpenProduct;
