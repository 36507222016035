import React from 'react';

import { Space, Typography } from 'antd';

const { Text } = Typography;

const MemberGlnList = (props) => {
  const { glns, color } = props;

  return (
    <Space direction='vertical' size={0}>
      {glns?.length
        ? glns.map((gln) => {
            return <Text style={{ color }}>{gln}</Text>;
          })
        : null}
    </Space>
  );
};

export default MemberGlnList;
