import React from 'react';
import { useDispatch } from 'react-redux';

import { notification } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/dashboard';

import { RibbonButton, dialogFunction } from 'common/components';

import { cancelMeeting } from 'services/dashboard';
import {
  saveIdMeeting,
  getMeetingDetailsSuccess,
} from 'pages/dashboard/controllers/actions';

import './NewMeeting.less';

const CancelMeeting = ({
  meetingDetail,
  disabled,
  onRefetchMeetingCalendar,
}) => {
  const dispatch = useDispatch();
  const handleCancelMeeting = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to cancel the meeting?',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        try {
          const response = await cancelMeeting({
            id: meetingDetail.meetingId,
          });
          const { isSuccess, message } = response;
          if (isSuccess) {
            notification.success({
              message: 'Cancel the meeting successfully!',
            });

            onRefetchMeetingCalendar();

            dispatch(saveIdMeeting(null));
            dispatch(getMeetingDetailsSuccess(null));
          } else {
            notification.error({
              message: message || 'Cannot cancel the meeting',
            });
          }
        } catch (error) {
          notification.error({
            message: error?.message || 'Cannot cancel the meeting',
          });
        }
      },
    });
  };

  return (
    <RibbonButton
      icon={<CloseCircleOutlined />}
      label={Messages.cancelMeeting}
      disabled={disabled}
      onClick={handleCancelMeeting}
    />
  );
};

export default CancelMeeting;
