import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as selectors from 'pages/company-profile/controllers/selectors';
import * as memberActions from 'pages/company-profile/controllers/actions';
import userSelectors from 'redux/user/selectors';

import { WrapperSelect } from 'common/components';

import { ASSET_UPLOAD_TYPE, TYPE_VIEW_ASSET } from 'static/Constants';

import { useCheckIsSuperAdmin } from 'hooks';

const { Option } = Select;

const AssetUploadOption = (props) => {
  const {
    setUploadType,
    uploadType,
    methodList = [],
    isMultipleImport = false,
    typeViewAsset,
  } = props;

  const dispatch = useDispatch();
  const isSuperAdmin = useCheckIsSuperAdmin();

  const memberInfo = useSelector(selectors.selectMemberProfile());

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const itemCurrentSelection = useSelector(
    gridSelector.makeSelectItemCurrentSelection()
  );
  useEffect(() => {
    if (_.isEmpty(memberInfo)) {
      dispatch(memberActions.getMemberProfileHeader(userInfo?.member?.id));
    }
  }, [dispatch, userInfo?.member?.id]);

  const disabledBtnUpload = ({
    itemCurrentSelection,
    isMultipleImport,
    uploadOption,
    memberInfo,
    methodList,
    typeViewAsset,
  }) => {
    if (!methodList.includes(uploadOption.name)) return true;

    if (
      uploadOption.name === 'UPLOAD_URL' &&
      (typeViewAsset === TYPE_VIEW_ASSET.REPLACE_ASSET ||
        typeViewAsset === TYPE_VIEW_ASSET.REPLACE_THUMBNAIL) &&
      (isMultipleImport || !itemCurrentSelection?.isIFrame)
    )
      return true;

    if (uploadOption.disabled) return true;

    if (
      uploadOption.name === 'UPLOAD_ADV_FTP_FILE' &&
      !memberInfo?.ftpEnabled &&
      !isSuperAdmin
    )
      return true;

    if (
      uploadOption.name === 'UPLOAD_DROP_BOX_FILE' &&
      !memberInfo?.canAccessInterops &&
      !isSuperAdmin
    )
      return true;

    return false;
  };

  return (
    <WrapperSelect
      className='asset-upload__dialog-method-dropdown'
      defaultValue='Select a Local File'
      onChange={(value) => {
        setUploadType(value);
      }}
      value={uploadType}
      allowClear={false}
    >
      {_.toArray(ASSET_UPLOAD_TYPE).map((uploadOption) => {
        return (
          <Option
            value={uploadOption.name}
            key={uploadOption.name}
            disabled={disabledBtnUpload({
              itemCurrentSelection,
              isMultipleImport,
              uploadOption,
              memberInfo,
              methodList,
              typeViewAsset,
            })}
          >
            {uploadOption.optionName}
          </Option>
        );
      })}
    </WrapperSelect>
  );
};

AssetUploadOption.propTypes = {
  setUploadType: PropTypes.func,
  uploadType: PropTypes.string,
  disableOptions: PropTypes.arrayOf(PropTypes.string),
};

export default AssetUploadOption;
