import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { FormOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
const EditControl = ({ confirmEdit, canEdit }) => {
  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editUser}
      onClick={confirmEdit}
      disabled={
        ['/asset/create', '/asset/create-iframe'].includes(
          window.location.pathname
        ) || !canEdit
      }
    />
  );
};

EditControl.propTypes = {
  confirmEdit: PropTypes.func,
};

export default EditControl;
