import React from 'react';

import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import EditPostGridView from './components/controls/maintenance/help/EditPostGridView';
import CreatePost from './components/controls/maintenance/help/CreatePost';
import DeletePosts from './components/controls/maintenance/help/DeletePosts';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const HelpPostGridView = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.HELP_POST}>
        <EditPostGridView />
        <CreatePost isSmallBtn />
        <DeletePosts isSmallBtn />
      </Can>
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default HelpPostGridView;
