export const DOCUMENT_METADATA = {
  filetypes: ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'log'],
  metaParams: ['fileName', 'owner', 'fileSize', 'fileType', 'pageCount'],
};

export const ZIPRAR_METADATA = {
  filetypes: ['zip', 'rar'],
  metaParams: ['fileName', 'owner', 'fileSize', 'fileType'],
};

export const EMAIL_METADATA = {
  filetypes: ['pst', 'ost'],
  metaParams: ['fileName', 'owner', 'fileSize', 'fileType'],
};

export const VIDEO_METADATA = {
  filetypes: ['mp4', 'mov', 'avi'],
  metaParams: [
    'fileName',
    'owner',
    'fileSize',
    'fileType',
    'bitRate',
    'frameRate',
    'frameSize',
    'colorModel',
    'fps',
    'mediaFormat',
    'duration',
  ],
};

export const AUDIO_METADATA = {
  filetypes: ['mp3'],
  metaParams: [
    'fileName',
    'owner',
    'fileSize',
    'fileType',
    'bitRate',
    'sampleRate',
    'channelOutput',
    'mediaFormat',
    'duration',
  ],
};

export const IMAGE_METADATA = {
  filetypes: ['jpeg', 'png', 'gif', 'tiff', 'jpg'],
  metaParams: [
    'fileName',
    'owner',
    'fileSize',
    'fileType',
    'width',
    'height',
    'clippingPath',
    'colorDepth',
    'resolution',
    'exifData',
  ],
};

export const OTHER_METADATA = {
  metaParams: ['fileName', 'owner'],
};

export const TYPEGROUP = [
  'DOCUMENT_METADATA',
  'ZIPRAR_METADATA',
  'EMAIL_METADATA',
  'VIDEO_METADATA',
  'AUDIO_METADATA',
  'IMAGE_METADATA',
];
