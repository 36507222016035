import React, { useRef } from 'react';

import { Typography, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Form, dialogFunction, RibbonButton } from 'common/components/index';

import { apiHandler } from 'utils/api';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import * as ssoServices from 'services/sso-approval';
import * as brandingActions from 'redux/branding/actions';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const { Paragraph } = Typography;

const RejectApprovalMember = (props) => {
  const reloadPage = useDispatchReloadPage();
  const intl = useIntl();
  const confirmModalRef = useRef(null);
  const inputRef = useRef(null);
  const [reasonForm] = Form.useForm();

  const { selectedItem, sectionDisabled, onClearItemGrid, approvalSubject } =
    props;

  const dispatch = useDispatch();

  const onClickBtn = () => {
    confirmModalRef.current = dialogFunction({
      type: 'warn',
      content: renderReasonInput(),
      okText: intl.formatMessage(Messages.ssoApprovalConfirmYes),
      cancelText: intl.formatMessage(Messages.ssoApprovalConfirmNo),
      okButtonProps: {
        disabled: true,
      },
      onOk: callApiAcceptSsoApproval,
      afterClose: onCloseModal,
    });

    setTimeout(() => {
      inputRef.current.focus({
        cursor: 'start',
      });
    }, 300);
  };

  const onReasonFormChange = (value) => {
    if (value.reason) updateActiveBtn(false);
  };

  const onCloseModal = () => {
    reasonForm.resetFields();
  };

  const updateActiveBtn = (value) => {
    const modal = confirmModalRef.current;
    if (modal)
      modal.update({
        okButtonProps: {
          disabled: value,
        },
      });
  };

  const updateModalLoading = (value) => {
    const modal = confirmModalRef.current;
    if (modal)
      modal.update({
        okButtonProps: {
          loading: value,
        },
      });
  };

  const callApiAcceptSsoApproval = async (close) => {
    const reasonFormValue = await reasonForm.validateFields();

    if (!reasonFormValue?.reason) return;
    updateModalLoading(true);

    const idPath = approvalSubject === 'user' ? 'userId' : 'memberId';
    const service =
      approvalSubject === 'user'
        ? ssoServices.acceptOrRejectUser
        : ssoServices.acceptOrRejectMember;

    const params = {
      [idPath]: selectedItem?.id,
      isReject: true,
      rejectReason: reasonFormValue?.reason,
      rivirMemberId: selectedItem?.rivirMemberId,
      memberName: selectedItem?.memberName,
      email: selectedItem?.email,
      firstName: selectedItem?.firstName,
      lastName: selectedItem?.lastName,
      phoneNumber: selectedItem?.phoneNumber,
      addBy: selectedItem?.addedBy,
    };

    const successMessage = intl.formatMessage(
      Messages.ssoApprovalRejectSuccess[approvalSubject],
      {
        name:
          approvalSubject === 'member' ? selectedItem?.memberName : undefined,
      }
    );
    const errorMessage = intl.formatMessage(Messages.ssoApprovalRejectError);

    apiHandler({
      service,
      params,
      successMessage,
      errorMessage,
      successCallback: successCallback(close),
      onFinally,
    });
  };

  const onFinally = () => {
    updateModalLoading(false);
  };

  const successCallback = (close) => () => {
    reloadPage();
    onClearItemGrid();
    close();
    dispatch(brandingActions.getBrandingNoLoading());
  };

  const checkDisabled = () => {
    const status = selectedItem?.poolStatus;

    const statusDisabled = status && status?.toLowerCase() !== 'pending';

    return sectionDisabled || statusDisabled;
  };

  const renderReasonInput = () => {
    return (
      <div>
        <Paragraph>
          {intl.formatMessage(
            Messages.ssoApprovalRejectConfirm[approvalSubject]
          )}
        </Paragraph>
        <Form form={reasonForm} onValuesChange={onReasonFormChange}>
          <Form.Item
            name='reason'
            rules={[
              {
                required: true,
                message: intl.formatMessage(Messages.reasonRequired),
              },
            ]}
          >
            <Input
              ref={inputRef}
              placeholder={intl.formatMessage(Messages.reasonPlaceholder)}
            />
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <RibbonButton
      icon={<CloseOutlined />}
      label={Messages.rejectSubscription}
      onClick={onClickBtn}
      disabled={checkDisabled()}
    />
  );
};

export default RejectApprovalMember;
