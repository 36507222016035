import React, { useState, useEffect } from 'react';

import { Row, Col, Form, notification } from 'antd';

import { useSelector, useDispatch } from 'react-redux';

import { StyledModal, dialogFunction } from 'common/components';
import AplSendSomethingProductList from './AplSendSomethingProductList';
import AplSendQuestion from './AplSendQuestion';

import { apiHandler } from 'utils/api';
import * as aplServices from 'services/apl';

import Messages from 'i18n/messages/apl';
import { useIntl } from 'react-intl';

import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';
import { toggleReloadGrid } from 'pages/product-apl-detail/controllers/actions';

import {
  getListApiUpdateEmail,
  getListIdsNeedToUpdateEmail,
  getListParamsUpdateEmail,
  getListProductReadySend,
  getListProductNotReadySend,
  disabledSendQuestions,
} from './utils';

import './AplSendSomethingModal.less';

const AplSendSomethingModal = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { visible, setVisible, sendType, productList } = props;

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSendingStatusList, setProductSendingStatusList] = useState({});

  const [questionList, setQuestionList] = useState({});
  const [sendLoading, setSendLoading] = useState(false);
  const [notReadyProducts, setNotReadyProducts] = useState([]); // product in productSendingStatusList has status !== success

  const isSendQuestion = sendType === 'question';

  const aplDetail = useSelector(makeSelectAplDetail());

  useEffect(() => {
    if (selectedProduct?.id) {
      const { id } = selectedProduct;
      const question = questionList[id];

      if (question) {
        setSelectedProduct((product) => ({
          ...product,
          contactEmail: question.email,
          isDraft: question.isDraft,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct?.id, questionList]);

  const handleSubmitQuestion = () => {
    sendQuestion();
    updateContactEmailGrid();
  };

  const handleHighlightProductNotReady = () => {
    const products = getListProductNotReadySend(productSendingStatusList);
    setNotReadyProducts(products);

    setTimeout(() => {
      setNotReadyProducts([]);
    }, 3000);
  };

  const onOk = () => {
    const readySendProducts = getListProductReadySend(productSendingStatusList)
      .length;
    const allProducts = productList.length;

    if (readySendProducts < allProducts) {
      dialogFunction({
        type: 'warn',
        content: `Only ${readySendProducts} / ${allProducts} have questions content email. 
                  Do you want to send emails anyway?`,
        onOk: handleSubmitQuestion,
        onCancel: handleHighlightProductNotReady,
      });
    } else {
      handleSubmitQuestion();
    }
  };

  const onCancel = () => {
    setVisible(false);
    setSendLoading(false);
  };

  const sendQuestion = () => {
    form.validateFields().then((values) => {
      const params = getSendQuestionParams();
      const successMessage = intl.formatMessage(Messages.sendQuestionSuccess);
      const errorMessage = intl.formatMessage(Messages.sendQuestionError);
      setSendLoading(true);
      apiHandler({
        service: aplServices.sendQuestion,
        params,
        successMessage,
        errorMessage,
        onFinally: onCancel,
      });
    });
  };

  const updateContactEmailGrid = async () => {
    const { isOwner } = aplDetail;

    if (!isOwner) return;

    const updatedIdsEmail = getListIdsNeedToUpdateEmail(
      questionList,
      productSendingStatusList
    );
    const listParams = getListParamsUpdateEmail(
      productList,
      updatedIdsEmail,
      questionList
    );

    const listApis = getListApiUpdateEmail(listParams);

    if (listApis.length === 0) return;

    try {
      const [response] = await Promise.all(listApis);
      if (response?.isSuccess) {
        notification.success({
          message: 'Update contact email successfully!',
        });
        dispatch(toggleReloadGrid(true));
      } else {
        notification.error({
          message: response?.message ?? 'Something went wrong!',
        });
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        dispatch(toggleReloadGrid(false));
      }, 200);
    }
  };

  const getSendQuestionParams = () => {
    const validQuestionList = Object.keys(questionList)
      .reduce((accumulator, currentProductId) => {
        const question = questionList[currentProductId];
        const productSendingStatus = productSendingStatusList[currentProductId];

        if (productSendingStatus === 'success')
          return [...accumulator, question];

        return accumulator;
      }, [])
      .map((questionList) => {
        return {
          ...questionList,
          emailContent: questionList?.emailContent?.replaceAll('\n', '<br/>'),
        };
      });

    return { mailContents: validQuestionList };
  };

  const initProductSendingStatusList = () => {
    if (!visible) return;
    if (!productList?.length) return;

    const initialList = productList.reduce((accumulator, productItem) => {
      const { id, contactEmail } = productItem;

      return { ...accumulator, [id]: !contactEmail ? 'error' : null };
    }, {});

    setProductSendingStatusList(initialList);
  };

  const initSelectedProduct = () => {
    if (!visible) return;
    if (productList?.length) setSelectedProduct(productList[0]);
  };

  useEffect(() => {
    initSelectedProduct();
    initProductSendingStatusList();
  }, [visible, JSON.stringify(productList)]);

  const handleUpdateDraftEmail = (email) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      contactEmail: email,
      isDraft: true,
    }));

    // Update email in question list to cache email when switch between product upc
    if (selectedProduct) {
      const { id } = selectedProduct;
      const question = questionList[id];
      const newQuestionList = {
        ...questionList,
        [id]: {
          ...question,
          email,
          isDraft: true,
        },
      };

      setQuestionList(newQuestionList);
    }
  };

  const title = isSendQuestion
    ? intl.formatMessage(Messages.sendQuestion)
    : null;

  return (
    <StyledModal
      visible={visible}
      title={title}
      okButtonProps={{
        loading: sendLoading,
        disabled: disabledSendQuestions(productSendingStatusList),
      }}
      onOk={onOk}
      okText={'Send'}
      onCancel={onCancel}
      width={800}
      bodyStyle={{
        height: 500,
      }}
    >
      <Row className='apl-send-modal__container' gutter={[8, 8]}>
        <Col span={6} className='scroller'>
          <AplSendSomethingProductList
            productList={productList}
            productSendingStatusList={productSendingStatusList}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            notReadyProducts={notReadyProducts}
          />
        </Col>
        <Col span={18} style={{ overflowY: 'hidden' }} className='scroller'>
          {isSendQuestion ? (
            <AplSendQuestion
              visible={visible}
              selectedProduct={selectedProduct}
              questionList={questionList}
              setQuestionList={setQuestionList}
              setProductSendingStatusList={setProductSendingStatusList}
              form={form}
              onUpdateEmail={handleUpdateDraftEmail}
              key={selectedProduct?.id}
            />
          ) : null}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default AplSendSomethingModal;
