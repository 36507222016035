import {
  DrugFactActiveIngredient,
  DrugFactUses,
  DrugFactWarnings,
  DrugFactDirections,
  DrugFactOtherInformation,
  DrugFactInactiveIngredients,
  DrugFactSection,
} from './component';

import './DrugFact.less';

const DrugStandardLabel = ({ property, snapshotValue }) => {
  const { uses, warnings, directions, otherInformation } = property || {};

  return (
    <div className='drug-fact scroller scroller--y'>
      <DrugFactSection className='drug-fact__container'>
        <h1 className='drug-fact__title'>Drug Facts</h1>
        <DrugFactActiveIngredient
          property={property}
          snapshotValue={snapshotValue}
        />
        <DrugFactUses uses={uses} />
        <DrugFactWarnings warnings={warnings} />
        <DrugFactDirections directions={directions} />
        <DrugFactOtherInformation otherInformation={otherInformation} />
        <DrugFactInactiveIngredients
          property={property}
          snapshotValue={snapshotValue}
        />
      </DrugFactSection>
    </div>
  );
};

export default DrugStandardLabel;
