import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const startVideoMeeting = (params) => {
  //*	Input: threadId, senderId
  //* output
  // todo - {roomId, jitsi url, ringing users}
  // ?•	roomId: string, an unique string to make sure we will not use the same room with other call
  // ?•	jitsi url: https://meet.jit.si, we will use our domain later
  // ?•	ringing users: the list of user online and rigging. e.g: [U-123, C-124]
  // ?•	the response will be success = false if no one online

  return api.sendPost(endpoints.START_MEETING, params);
};

export const rejectVideoMeeting = (params) => {
  //*	Input: threadId, senderId
  //* output
  //? bool, it is always true if no technical error
  return api.sendPost(endpoints.REJECT_CALL, params);
};

export const acceptVideoMeeting = (params) => {
  //* Input: threadId, senderId
  //* output
  //todo - {roomId, jitsi url}
  return api.sendPost(endpoints.ACCEPT_CALL, params);
};

export const endVideoMeeting = (params) => {
  //* Input: threadId
  //* output
  return api.sendPost(endpoints.END_CALL, params);
};

export const inviteCall = (params) => {
  //* Input: threadId, userIds( e.g: [ U-123, C-123 ])
  //* output
  return api.sendPost(endpoints.INVITE_CALL, params);
};
