import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';

//* COMPONENT
import { Indicator } from 'common/components';

//* SAGA
import * as globalActions from 'redux/global/actions';
// import * as ribbonAssetFullActions from 'pages/home/ribbon/asset-full/controllers/actions';
import * as globalSelectors from 'redux/global/selectors';

//* UTILS
import { getParameterFromQuery } from 'utils/common/route';

//* STYLING
import './index.less';

const OpenItemAntdTab = (props) => {
  const { tabId, children } = props;
  const dispatch = useDispatch();
  const openItemData = useSelector(globalSelectors.selectOpenItem());
  const currentTab = useSelector(globalSelectors.selectCurrentTab());

  const openItemUid = getParameterFromQuery('openItem');

  const getDefaultTabName = () => {
    if (!openItemUid) {
      // const searchedTab = _.find(currentTab?.items, { tabId: tabId });
      // return searchedTab?.value;
      return '';
    }
    const theOpenItem = _.find(openItemData, { uid: openItemUid });
    const openItemTabs = theOpenItem?.tabData?.items;
    const tabValue = _.find(openItemTabs, { tabId: tabId });
    return tabValue?.value;
  };

  const updateCurrentTab = useCallback(
    (value) => {
      dispatch(
        globalActions.updateCurrentTab({
          tabId,
          value,
        })
      );
    },
    [tabId]
  );

  const getConfigProps = (child) => {
    let configProps = {
      onChangeTab: (activeKey) => {
        _.get(child, 'props.onChangeTab') && child.props.onChangeTab(activeKey);
        updateCurrentTab(activeKey);
      },
      defaultActiveTab: getDefaultTabName(),
    };
    const configResult = _.pickBy(configProps, _.identity);
    return configResult;
  };

  const mapDefaultTabToSavedTab = () => {
    let elements = React.Children.toArray(children);
    elements.forEach((child, idx) => {
      const configProps = getConfigProps(child);
      elements[idx] = React.cloneElement(child, {
        ...child.props,
        ...configProps,
      });
    });
    return elements;
  };

  useEffect(() => {
    if (!children.props?.defaultActiveTab) {
      throw 'Please add defaultActiveTab for tab common component please';
    }
    updateCurrentTab(children.props?.defaultActiveTab);
  }, []);

  const processElement = mapDefaultTabToSavedTab();

  return <React.Fragment>{processElement}</React.Fragment>;
};

OpenItemAntdTab.propTypes = {
  //? config - open item config
  tabId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default React.memo(OpenItemAntdTab);
