import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Modal, Button, Input, Row, Col, Select, DatePicker } from 'antd';

import { ReactComponent as IconCreateRequest } from 'assets/security-request/create-request.svg';

import {
  Form,
  RibbonButton,
  CustomNotification,
  WrapperSelect,
} from 'common/components';

import { useGetListUserToAssignRequest } from './hooks';

import { createNewRequest } from 'services/request-grid';

import { getBrandingNoLoading } from 'redux/branding/actions';
import { toggleReloadGrid } from 'common/components/grid-view/controllers/actions';
import { disabledPreviousDays } from 'utils/timezone';

import { DATE_FORMAT } from 'static/Constants';

const NewRequest = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        icon={<IconCreateRequest />}
        label='New Request'
        onClick={() => setVisible(true)}
      />

      {visible && (
        <ModalCreateRequest
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      )}
    </>
  );
};

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 17,
  },
};

const { Option } = Select;

export const ModalCreateRequest = ({ visible, onCancel, onFinish }) => {
  const [formInstance] = Form.useForm();

  const dispatch = useDispatch();

  const [statusSubmit, setStatusSubmit] = useState('idle');

  const { assignedUsers } = useGetListUserToAssignRequest({
    isEnabled: true,
  });

  const handleCreateRequest = async () => {
    try {
      const formValues = await formInstance.validateFields();
      const { assignedTo, ...otherValues } = formValues;

      let params = {
        ...otherValues,
      };

      if (assignedTo) {
        const [userId, userName] = assignedTo.split('-');

        params = {
          ...otherValues,
          assignedTo: parseInt(userId),
          assignedToName: userName,
        };
      }

      setStatusSubmit('loading');

      const response = await createNewRequest(params);

      if (response?.isSuccess) {
        setStatusSubmit('success');

        dispatch(getBrandingNoLoading());
        dispatch(toggleReloadGrid(true));

        CustomNotification.success('Create a request successfully!');

        onCancel();

        onFinish && onFinish();
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        setStatusSubmit('error');
      }
    } catch (error) {
      setStatusSubmit('error');
    } finally {
      setTimeout(() => {
        dispatch(toggleReloadGrid(false));
      }, 300);
    }
  };

  useEffect(() => {
    const resetFormWhenCloseModal = () => {
      if (!visible) {
        formInstance.resetFields();
      }
    };

    resetFormWhenCloseModal();
  }, [formInstance, visible]);

  return (
    <Modal
      title='Create New Request'
      visible={visible}
      width={600}
      onCancel={onCancel}
      footer={[
        <Button
          type='primary'
          onClick={handleCreateRequest}
          loading={statusSubmit === 'loading'}
        >
          Submit
        </Button>,
        <Button onClick={onCancel}>Cancel</Button>,
      ]}
    >
      <Form {...layout} form={formInstance}>
        <Form.Item
          name='title'
          label='Title'
          rules={[
            { required: true, message: 'Please input title' },

            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }

                if (value.length > 0 && !value.trim()) {
                  return Promise.reject('Please input title');
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name='description' label='Description'>
          <Input.TextArea rows={4} />
        </Form.Item>

        <Row>
          <Col span={12}>
            <Form.Item
              name='assignedTo'
              label='Assigned to'
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <WrapperSelect
                placeholder='Select a user'
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {assignedUsers.map(({ userId, fullName }) => (
                  <Option key={userId} value={`${userId}-${fullName}`}>
                    {fullName}
                  </Option>
                ))}
              </WrapperSelect>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='expired' label='Expired' labelCol={{ span: 12 }}>
              <DatePicker
                format={DATE_FORMAT.SYSTEM_FORMAT}
                disabledDate={disabledPreviousDays}
                style={{ width: '84%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewRequest;
