import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import SubscriptionActionSection from './components/sections/product/SubscriptionActionSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const SubscriptionToGridView = () => {
  const selectedSubscription = useSelector(
    gridViewSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const disabled = selectedSubscription?.length !== 1;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection disabled />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabled />
      </Can>
      <RibbonDivider />
      <SubscriptionActionSection
        selectionSubscription={selectedSubscription[0]}
        sectionDisabled={disabled}
      />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <RibbonDivider />
        <AdvanceStack isDisabled={true} />
      </Can>
    </RibbonBar>
  );
};

export default SubscriptionToGridView;
