import * as types from './constants';

export const showLoading = () => {
  return {
    type: types.SHOW_LOADING,
  };
};

export function getDigitalAssetList(
  pageSize,
  pageNumber,
  search,
  filters,
  mediaType,
  isFavoriteRoute,
  advancedSearchContainer,
  searchCategory,
  fromDate
) {
  return {
    type: types.GET_DIGITAL_ASSET_LIST,
    pageSize: pageSize,
    pageIndex: pageNumber,
    search,
    filters,
    mediaType,
    isFavoriteRoute,
    advancedSearchContainer,
    searchCategory,
    fromDate,
  };
}

export function getDigitalAssetForMemberList({
  pageSize,
  pageNumber,
  search,
  filters,
  mediaType,
  isFavoriteRoute,
  advancedSearchContainer,
  searchCategory,
  fromDate,
}) {
  return {
    type: types.GET_DIGITAL_ASSET_LIST,
    pageSize: pageSize,
    pageIndex: pageNumber,
    search,
    filters,
    mediaType,
    isFavoriteRoute,
    advancedSearchContainer,
    searchCategory,
    fromDate,
  };
}

export function getDigitalAssetListSuccess(assets, total) {
  return {
    type: types.GET_DIGITAL_ASSET_LIST_SUCCESS,
    assets,
    total,
  };
}

export function getDigitalAssetListError(error) {
  return {
    type: types.GET_DIGITAL_ASSET_LIST_ERROR,
    error,
  };
}

export function gridDigitalAssetColumnInfo(gridName) {
  return {
    type: types.DIGITAL_ASSET_GRID_COLUMN_INFO,
    gridName,
  };
}

export function gridDigitalAssetColumnInfoSuccess(columns) {
  return {
    type: types.DIGITAL_ASSET_GRID_COLUMN_INFO_SUCCESS,
    columns,
  };
}

export function gridDigitalAssetColumnInfoError(error) {
  return {
    type: types.DIGITAL_ASSET_GRID_COLUMN_INFO_ERROR,
  };
}

export function getDigitalAssetShortDetail(id) {
  return {
    type: types.GET_DIGITAL_ASSET_SHORT_DETAIL,
    id,
  };
}

export function getDigitalAssetShortDetailSuccess(data) {
  return {
    type: types.GET_DIGITAL_ASSET_SHORT_DETAIL_SUCCESS,
    data,
  };
}

export function getDigitalAssetShortDetailError(error) {
  return {
    type: types.GET_DIGITAL_ASSET_SHORT_DETAIL_ERROR,
    error,
  };
}

export const updateCacheImage = (newCache) => {
  return {
    type: types.UPDATE_CACHE_IMAGE,
    payload: newCache,
  };
};

export const deleteAssets = (assetIds) => {
  return {
    type: types.DELETE_ASSETS,
    payload: {
      assetIds,
    },
  };
};

export const deleteAssetsSuccess = () => {
  return {
    type: types.DELETED_ASSETS_SUCCESS,
  };
};

export const deleteAssetsError = (message) => {
  return {
    type: types.DELETED_ASSETS_ERROR,
    payload: {
      error: message,
    },
  };
};

export const resetDeleteAssets = () => {
  return { type: types.RESET_DELETE_ASSETS };
};

export function updateAssetTypePane(params) {
  return {
    type: types.UPDATE_ASSET_TYPE_PANE,
    params,
  };
}

export function updateAssetTypePaneSuccess() {
  return {
    type: types.UPDATE_ASSET_TYPE_PANE_SUCCESS,
  };
}

export function updateAssetTypePaneError(error) {
  return {
    type: types.UPDATE_ASSET_TYPE_PANE_ERROR,
    error,
  };
}

// Assets for member
export function getAssetsForMember(params) {
  return {
    type: types.GET_ASSETS_FOR_MEMBER,
    params,
  };
}

export function getAssetsForMemberSuccess(data) {
  return {
    type: types.GET_ASSETS_FOR_MEMBER_SUCCESS,
    data,
  };
}

export function getAssetsForMemberError(error) {
  return {
    type: types.GET_ASSETS_FOR_MEMBER_ERROR,
    error,
  };
}

export function getColumnsAssetsForMember(gridName) {
  return {
    type: types.GET_COLUMNS_ASSETS_FOR_MEMBER,
    gridName,
  };
}

export function getColumnsAssetsForMemberSuccess(columns) {
  return {
    type: types.GET_COLUMNS_ASSETS_FOR_MEMBER_SUCCESS,
    columns,
  };
}

export function getColumnsAssetsForMemberError(error) {
  return {
    type: types.GET_COLUMNS_ASSETS_FOR_MEMBER_ERROR,
    error,
  };
}

export function saveIdMember(id) {
  return {
    type: types.SAVE_ID_MEMBER,
    id,
  };
}

export const toggleAssetNew = (status) => ({
  type: types.TOGGLE_ASSET_NEW,
  isDisplayAssetNew: status,
});

export const cacheFromDate = (fromDate) => ({
  type: types.CACHE_ASSET_FROM_DATE,
  fromDate,
});

export const updateLatestGetNewAsset = () => ({
  type: types.UPDATE_LATEST_GET_NEW_ASSET,
});

export const updateLatestGetUnmatchAsset = () => ({
  type: types.UPDATE_LATEST_GET_UNMATCH_ASSET,
});

export const toggleAssetUnmatch = (status) => ({
  type: types.TOGGLE_ASSET_UNMATCH,
  isDisplayAssetNew: status,
});
