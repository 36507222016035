import React from 'react';
import { useSelector } from 'react-redux';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const EditArticlesGridView = () => {
  const selectedArticles = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const articleId =
    selectedArticles && selectedArticles.length > 0
      ? selectedArticles[0].id
      : undefined;

  const handleEditArticle = () => {
    forwardTo(`/maintenance/help/articles/${articleId}`);
  };

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editArticle}
      onClick={handleEditArticle}
      disabled={articleId === undefined || selectedArticles.length !== 1}
    />
  );
};

export default EditArticlesGridView;
