export const DOWNLOAD_APL_BLANK_TEMPLATE = '/api/apl/get-apl-blank-template';
export const ADD_PRODUCT_TO_APL = '/api/apl/add-product-to-apl';
export const DELETE_PRODUCT_FROM_APL = '/api/apl/remove-product-from-apl';
export const CREATE_APL = '/api/apl/create-apl-by-spreadsheet';
export const DOWNLOAD_APL_PRODUCT_LIST = '/api/apl/download-apl-product-list';
export const EDIT_INFO_METADATA = '/api/apl/edit-apl-info-metadata';
export const UPLOAD_APL_PRODUCT_LIST =
  '/api/apl/update-apl-product-list-by-spreadsheet';
export const ADD_FOLDERS_INTO_NEW_APL = '/api/apl/add-folders-into-new-apl';
export const ADD_PRODUCTS_TO_NEW_APL =
  '/api/apl/add-selected-products-into-new-apl';
export const ADD_SEARCH_PRODUCTS_TO_APL =
  '/api/apl/add-all-search-product-results-into-new-apl';
export const SEND_QUESTION = '/api/apl/send-product-question';
export const DELETE_APL = '/api/apl/delete-apl';

export const GET_SAMPLE_REQUEST_SENDER_DATA =
  '/api/apl/get-sample-request-pre-populate-data';
export const SEND_SAMPLE_REQUEST = '/api/apl/send-sample-request';
