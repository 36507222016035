import { dialogFunction, CustomNotification } from 'common/components';

import { apiHandler } from 'utils/api';

import {
  deleteFolders,
  deleteEntityAssocicationFromShortContent,
} from 'services/folder';

import { useIntl } from 'react-intl';
import folderMessages from 'i18n/messages/folder';
import { sleep } from 'utils/delay';

export const useRemoveFolder = (props) => {

  const {
    selectedItems,
    currentFolderId,
    onDeleteFolderSuccess,
    onDeleteItemSuccess,
  } = props;

  const intl = useIntl();

  const showPopupConfirm = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(folderMessages.confirmDeleteItemMessage),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => onOkDeleteFolder(),
    });
  };

  const onOkDeleteFolder = async () => {
    const folderIds = selectedItems
      .filter((item) => item.type.toLowerCase() === 'folder')
      .map((item) => item.id);

    const entities = selectedItems
      .filter((item) => item.type.toLowerCase() !== 'folder')
      .map((item) => ({ id: item.id, type: item.type }));

    if (folderIds.length) {
      await apiHandler({
        service: deleteFolders,
        params: { deleteFolderIds: folderIds },
        skipNotification: true,
        failCallback: (message) => {
          CustomNotification.error(message || deleteItemsFailedMessage);
        },
        successCallback: deleteFolderSuccessCallback(folderIds),
      });
    }

    if (entities.length) {
      await apiHandler({
        service: deleteEntityAssocicationFromShortContent,
        params: {
          folderId: currentFolderId,
          itemList: entities,
        },
        skipNotification: folderIds.length,
        successMessage: deleteItemsSuccessMessage,
        errorMessage: deleteItemsFailedMessage,
        failCallback: (message) => {
          CustomNotification.error(message || deleteItemsFailedMessage);
        },
        successCallback: deleteEntitiesSuccessCallback,
      });
    }
  };

  const deleteFolderSuccessCallback = (folderIds) => async () => {
    await sleep(500);
    
    onDeleteFolderSuccess && onDeleteFolderSuccess(folderIds);
  };

  const deleteEntitiesSuccessCallback = () => {
    onDeleteItemSuccess && onDeleteItemSuccess();
  };

  const deleteItemsSuccessMessage = intl.formatMessage(
    folderMessages.deleteItemsSuccessMessage
  );
  const deleteItemsFailedMessage = intl.formatMessage(
    folderMessages.deleteItemsFailedMessage
  );

  return { showPopupConfirm };
};
