import React from 'react';

import SignalrMeetingView from './meeting-view';

const SignalrHandler = (props) => {
  return (
    <>
      <SignalrMeetingView />
    </>
  );
};

export default SignalrHandler;
