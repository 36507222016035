export const GET_WORKFLOW_DETAIL_ACCEPT_STEPS =
  '/api/workflow/get-accept-steps';
export const GET_WORKFLOW_DETAIL_ACCEPT_STEPS_DISTINCT =
  '/api/dam/digital-asset-detail-grid/distinct';

export const GET_WORKFLOW_DETAIL_APPROVE_STEPS =
  '/api/workflow/get-approve-steps';
export const GET_WORKFLOW_DETAIL_APPROVE_STEPS_DISTINCT =
  '/api/dam/digital-asset-detail-grid/distinct';
export const GET_WORKFLOW_ROLES_GRID = '/api/workflow/workflow-roles-grid';
export const GET_WORKFLOW_ROLES_GRID_DISTINCT =
  '/api/workflow/workflow-roles-grid/distinct';
export const SAVE_WORKFLOW_ROLE = '/api/workflow/save-workflow-role';
export const REMOVE_WORKFLOW_ROLE = '/api/workflow/remove-workflow-role';
export const GET_USERS_HAVE_WORKFLOW_ROLE =
  '/api/workflow/get-users-have-workflow-role';
export const GET_USERS_HAVE_WORKFLOW_ROLE_DISTINCT =
  '/api/workflow/get-users-have-workflow-role/distinct';
export const GET_USERS_HAVE_NO_WORKFLOW_ROLE =
  '/api/workflow/get-users-have-no-workflow-role';
export const GET_USERS_HAVE_NO_WORKFLOW_ROLE_DISTINCT =
  '/api/workflow/get-users-have-no-workflow-role/distinct';
export const ADD_USERS_TO_WORKFLOW_ROLE =
  '/api/workflow/add-users-to-workflow-role';
export const REMOVE_USERS_TO_WORKFLOW_ROLE =
  '/api/workflow/remove-users-from-workflow-role';
export const GET_ALL_WORKFLOW_STEPS = '/api/workflow/get-all-workflow-steps';

// get user workflow roles
export const GET_USER_WORKFLOW_ROLES_ENDPOINT =
  '/api/workflow/get-user-workflow-roles';
// workflow grid
export const GET_WORKFLOW_GRID_LIST =
  '/api/workflow/get-workflow-implementations';
export const GET_WORKFLOW_CONTENT_PANE =
  '/api/workflow/get-workflow-implementation-detail';
export const GET_WORKFLOW_GRID_LIST_DISTINCT =
  '/api/workflow/get-workflow-implementations/distinct';

export const ACCEPT_WORKFLOW_STEP = '/api/workflow/accept-or-reject-todo-list';
export const APPROVE_WORKFLOW_STEP =
  '/api/workflow/approve-workflow-implementation-step';
export const ACCEPT_APPROVE_WORKFLOW_STEP =
  '/api/workflow/accept-waiting-for-approve';
export const CREATE_WORKFLOW_TEMPLATE =
  '/api/workflow/create-workflow-template';
export const DELETE_WORKFLOW_TEMPLATE =
  '/api/workflow/delete-workflow-template';
// get all workflow templates to start workflow
export const GET_ALL_WORKFLOW_TEMPLATES =
  '/api/workflow/get-all-workflow-templates';
// start workflow
export const START_WORKFLOW = '/api/workflow/start-workflow';
//? workflow step info
export const GET_WORKFLOW_STEP_INFO = '/api/workflow/get-workflow-step-info';

//? workflow impement step info
export const GET_WORKFLOW_IMPL_STEP_INFO =
  '/api/workflow/get-workflow-implementation-step-detail';

//? get workflow implement steps
export const GET_WF_IMPL_STEPS =
  '/api/workflow/get-list-workflow-implementation-steps';

//? get all tasks
export const GET_WORKFLOW_IMPLEMENT_TASKS =
  '/api/workflow/get-workflow-implementation-tasks-value';

//? get workflow implement info
export const GET_WF_IMPL_INFO =
  '/api/workflow/get-workflow-implementation-info';

export const SAVE_TASK_VALUE = '/api/workflow/save-tasks-value';
export const ADD_TASK_VALUE = '/api/workflow/add-value-for-task';
export const REPEAT_WORKFLOW_IMPLEMENTATION_STEP =
  '/api/workflow/repeat-workflow-implementation-step';
