import { useSelector } from 'react-redux';

import userSelectors from 'redux/user/selectors';

const useCheckSenderAccount = (senderId) => {
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());

  const isSender =
    senderId === `U-${userInfo?.id}` || senderId === `C-${memberId}`;

  return isSender;
};

export { useCheckSenderAccount };
