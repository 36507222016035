import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import { enableEditMode } from 'pages/communication-template/controllers/actions';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const EditCommunicationTemplate = () => {
  const dispatch = useDispatch();

  const selectedTemplate = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const templateId =
    selectedTemplate.length > 0 ? selectedTemplate[0].id : undefined;

  const handleEditTemplate = () => {
    dispatch(enableEditMode());
    forwardTo(`/communication-templates/${templateId}`);
  };

  const isDisable = !templateId || selectedTemplate.length !== 1;

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editTemplate}
      onClick={handleEditTemplate}
      disabled={isDisable}
    />
  );
};

export default EditCommunicationTemplate;
