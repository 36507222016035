import React from 'react';

import { notification } from 'antd';

import Messages from 'i18n/messages/home';

import { DownloadOutlined } from '@ant-design/icons';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import { downloadAplBlankTemplate } from 'services/apl';

import { saveFile } from 'utils/saveFile';

const DownloadAplTemplate = () => {
  const handleDownloadTemplate = () => {
    downloadAplBlankTemplate().then((resp) => {
      if (resp.isSuccess) {
        saveFile(resp?.data?.url);
      } else {
        notification.error({
          message: resp?.message,
        });
      }
    });
  };

  return (
    <ContainerButton>
      <ItemButton>
        <ButtonSmallIcon
          icon={<DownloadOutlined style={{ height: 26 }} />}
          label={Messages.downloadAplTemplate}
          onClick={handleDownloadTemplate}
        />
      </ItemButton>
    </ContainerButton>
  );
};

export default DownloadAplTemplate;
