import React from 'react';

import { PublicationCommonErrorItem } from './PublicationErrorItem';

const PublicationCommonErrorList = (props) => {
  const {
    errorGroup,
    groupIdx,
    status,
    setErrorIndex,
    setProductId,
    toggleCheckErrorModal,
    productEnums,
    productSchema,
    productDetailList,
    productModuleList,
  } = props;
  return (
    <>
      {errorGroup?.errorFields.map((errorItem, itemIdx) => {
        const isErrorItem = !!errorItem?.productId;

        const errorInfo = {
          errorProductId: errorItem?.productId,
          errorType: errorGroup?.errorType,
          errorMessage: errorGroup?.errorMessage,
          moduleName: errorItem?.moduleName,
          fieldName: errorItem?.fieldName,
        };

        const itemErrorKey = `${groupIdx}-${itemIdx}`;

        const isRivirField = errorItem.fieldName.split('.').length === 1; // fieldName = 'ModuleName.propertyName'

        return (
          <PublicationCommonErrorItem
            key={itemErrorKey}
            errorItem={errorItem}
            isErrorItem={isErrorItem}
            isRivirField={isRivirField}
            status={status}
            setErrorIndex={setErrorIndex}
            setProductId={setProductId}
            toggleCheckErrorModal={toggleCheckErrorModal}
            productEnums={productEnums}
            productSchema={productSchema}
            errorInfo={errorInfo}
            groupIdx={groupIdx}
            itemIdx={itemIdx}
            productDetailList={productDetailList}
            productModuleList={productModuleList}
          />
        );
      })}
    </>
  );
};

export default PublicationCommonErrorList;
