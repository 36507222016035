import React, { useState, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  UserOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';

import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  DatePicker,
  Popover,
  Tag,
  List,
  Avatar,
  Spin,
  Empty,
} from 'antd';

import {
  Form,
  FormAddButton,
  dialogOkFunction,
  TextTooltip,
  InputTimeOutLook,
} from 'common/components';

import {
  useGetAttendeesMeeting,
  useGetTopAttendeesMeeting,
  checkAttendeeIsSelected,
  getInitialFormValues,
  groupAttendees,
  displayFullName,
} from './utils';

import isEmpty from 'lodash/isEmpty';

import './NewMeeting.less';
import moment from 'moment';
import { disabledPreviousDays } from 'utils/timezone';

import { DATE_FORMAT } from 'static/Constants';

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 24 },
};

export const SearchAttendee = React.memo(
  ({ selectedAttendees, handleAddAttendees }) => {
    const [searchAttendee, setSearchAttendee] = useState({
      value: null,
      touched: false,
    });

    const { attendees, isLoadingSearchAttendees } = useGetAttendeesMeeting({
      searchText: searchAttendee.value,
      isEnabled: Boolean(searchAttendee.touched),
    });

    const { topAttendees, isLoadingTopAttendees } = useGetTopAttendeesMeeting();

    const isSnipping = isLoadingSearchAttendees || isLoadingTopAttendees;

    const groupedAttendees = useMemo(() => {
      if (searchAttendee.touched) {
        return groupAttendees(attendees);
      } else {
        return groupAttendees(topAttendees);
      }
    }, [attendees, searchAttendee.touched, topAttendees]);

    const attendeeIds = useMemo(
      () => selectedAttendees.map((attendee) => attendee.id),
      [selectedAttendees]
    );

    return (
      <Row style={{ padding: 12 }}>
        <Col span={24} style={{ marginBottom: 6 }}>
          <Input.Search
            placeholder='Input search text'
            allowClear
            enterButton
            style={{ width: '100%' }}
            onSearch={(value) =>
              setSearchAttendee((prevValue) => ({
                ...prevValue,
                value,
                touched: true,
              }))
            }
          />
        </Col>

        <Col span={24}>
          <Spin spinning={isSnipping}>
            {isEmpty(groupedAttendees) ? (
              <Empty />
            ) : (
              <List itemLayout='horizontal' className='list-attendee scroller'>
                {Object.entries(groupedAttendees).map((item, index) => {
                  return (
                    <Row key={index}>
                      <Col span={24} style={{ marginTop: 24, marginBottom: 4 }}>
                        <Typography.Title level={5}>{item[0]}</Typography.Title>
                      </Col>

                      <Col span={24}>
                        <GroupAttendees
                          attendees={item[1]}
                          attendeeIds={attendeeIds}
                          handleAddAttendees={handleAddAttendees}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </List>
            )}
          </Spin>
        </Col>
      </Row>
    );
  }
);

const AttendeeItem = React.memo(({ attendee, children }) => {
  return (
    <List.Item key={attendee.id}>
      <List.Item.Meta
        avatar={
          <Avatar src={attendee?.avatar} icon={<UserOutlined />} size={50} />
        }
        title={displayFullName(attendee)}
        description={attendee.email}
      />
      {children}
    </List.Item>
  );
});

const GroupAttendees = React.memo(
  ({ attendees, attendeeIds, handleAddAttendees, children }) => {
    return (
      <>
        {attendees.map((attendee) => (
          <AttendeeItem
            key={attendee.id}
            attendee={attendee}
            attendeeIds={attendeeIds}
          >
            <div>
              {checkAttendeeIsSelected(attendeeIds, attendee.id) ? (
                <CheckCircleFilled
                  color='#73d13d'
                  className='list-attendee__added-icon'
                />
              ) : (
                <Button
                  type='primary'
                  icon={<PlusOutlined />}
                  style={{ borderRadius: 4 }}
                  onClick={() => handleAddAttendees(attendee)}
                />
              )}
            </div>
            {children}
          </AttendeeItem>
        ))}
      </>
    );
  }
);

const GroupAnotherAttendees = React.memo(
  ({ attendees, attendeeIds, handleDeleteAttendees }) => {
    return (
      <>
        {attendees.map((attendee) => (
          <AttendeeItem
            key={attendee.id}
            attendee={attendee}
            attendeeIds={attendeeIds}
          >
            <div key={attendee.id}>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ borderRadius: 4 }}
                onClick={() => handleDeleteAttendees(attendee.id)}
              />
            </div>
          </AttendeeItem>
        ))}
      </>
    );
  }
);

export const DisplayAnotherAttendees = React.memo(
  ({ selectedAttendees, handleDeleteAttendees }) => {
    const attendeeIds = useMemo(
      () => selectedAttendees.map((attendee) => attendee.id),
      [selectedAttendees]
    );

    const groupedAttendees = useMemo(
      () => groupAttendees(selectedAttendees?.slice(4)),
      [selectedAttendees]
    );

    return (
      <Row style={{ padding: 4 }}>
        <List itemLayout='horizontal' className='list-attendee scroller'>
          {Object.entries(groupedAttendees).map((item, index) => {
            return (
              <Row key={index}>
                <Col span={24} style={{ marginTop: 12, marginBottom: 4 }}>
                  <Typography.Title level={5}>{item[0]}</Typography.Title>
                </Col>

                <Col span={24}>
                  <GroupAnotherAttendees
                    attendees={item[1]}
                    attendeeIds={attendeeIds}
                    handleDeleteAttendees={handleDeleteAttendees}
                  />
                </Col>
              </Row>
            );
          })}
        </List>
      </Row>
    );
  }
);

export const DisplaySomeAttendees = ({
  selectedAttendees,
  handleDeleteAttendees,
  isExternalAttendees = false,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [visible, setVisible] = useState(false);

  const displayPopover =
    selectedAttendees?.length > 4 && showMore === true && visible;

  useEffect(() => {
    if (selectedAttendees?.length <= 4) {
      setShowMore(false);
    }
  }, [selectedAttendees]);

  return (
    <>
      <Row style={{ marginTop: 8 }}>
        <Col offset={5} span={19}>
          <div style={{ height: 22, display: 'flex' }}>
            {selectedAttendees?.slice(0, 4).map((attendee, idx) => (
              <Tag
                color={isExternalAttendees ? '#FF9300' : '#2a9d8f'}
                key={idx}
                style={{
                  fontSize: 13,
                  padding: '1px 4px 0px 6px',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '165px',
                }}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleDeleteAttendees(attendee.id);
                }}
              >
                <TextTooltip
                  text={attendee?.email}
                  style={{ paddingTop: '2px' }}
                />
              </Tag>
            ))}
            <Popover
              placement='bottom'
              trigger='click'
              destroyTooltipOnHide
              title={null}
              visible={displayPopover}
              onVisibleChange={(visible) => setVisible(visible)}
              content={
                isExternalAttendees ? (
                  <DisplayExternalAttendees
                    externalAttendees={selectedAttendees}
                    handleDeleteExternalAttendees={handleDeleteAttendees}
                  />
                ) : (
                  <DisplayAnotherAttendees
                    selectedAttendees={selectedAttendees}
                    handleDeleteAttendees={handleDeleteAttendees}
                  />
                )
              }
              overlayClassName='modal-meeting__popover'
            >
              {selectedAttendees?.length > 4 && (
                <Button
                  type='link'
                  style={{ width: 0 }}
                  onClick={() => setShowMore(true)}
                >
                  More...
                </Button>
              )}
            </Popover>
          </div>
        </Col>
      </Row>
    </>
  );
};

export const AdditionAttendee = React.memo(
  ({ selectedAttendees, handleAddAttendees }) => {
    return (
      <Row style={{ marginTop: 8 }}>
        <Col span={19} offset={5}>
          <Popover
            placement='rightTop'
            trigger='click'
            title={null}
            destroyTooltipOnHide
            content={
              <SearchAttendee
                selectedAttendees={selectedAttendees}
                handleAddAttendees={handleAddAttendees}
              />
            }
            overlayClassName='modal-meeting__popover'
          >
            <FormAddButton
              text='Add Attendees'
              style={{ borderRadius: 'none' }}
            />
          </Popover>
        </Col>
      </Row>
    );
  }
);

export const DescriptionMeeting = React.memo(
  ({ description, handleChangeDescription, ...rest }) => {
    return (
      <Row style={{ marginTop: 6, marginBottom: 6 }}>
        <Input.TextArea
          autoSize={{ minRows: 8, maxRows: 8 }}
          onChange={(event) => handleChangeDescription(event.target.value)}
          placeholder='Meeting agenda...'
          value={description}
          {...rest}
        />
      </Row>
    );
  }
);

export const FormCreateMeeting = React.memo(
  ({
    formInstance,
    isMeetingNow,
    children,
    startTimeInput,
    setStartTimeInput,
    endTimeInput,
    setEndTimeInput,
    startTimeChange,
    setStartTimeChange,
    endTimeChange,
    setEndTimeChange,
  }) => {
    const handleChangeStartDate = (value) => {
      if (value === null) {
        return;
      }
      const currentDate = new Date();
      const { startDate, endDate, startTime, endTime } =
        formInstance.getFieldsValue();
      const newStartTime = moment(startTime, 'hh:mm A');
      const checkNewValue = moment(value)
        .hour(newStartTime.hour())
        .minute(newStartTime.minute());

      if (moment(checkNewValue).isBefore(currentDate)) {
        dialogOkFunction({
          type: 'warn',
          content: (
            <Row>
              <Col span={24}>
                The start time you entered occurs before the current time.
              </Col>
            </Row>
          ),
        });

        const date = new Date();
        const newStartDate = moment(date).minute(0).second(0).add(1, 'h');
        let newEndDate = moment(newStartDate).add(30, 'm');
        if (moment(newStartDate).isBefore(endDate)) {
          newEndDate = endDate;
        }

        formInstance.setFieldsValue({
          ...formInstance.getFieldsValue(),
          startDate: newStartDate,
          endDate: newEndDate,
          startTime: moment(newStartDate, 'hh:mm:ss').format('hh:mm A'),
          endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
        });

        setStartTimeInput(newStartDate.format('hh:mm A'));
        setStartTimeChange(false);
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(false);
      } else {
        let newEndDate = moment(checkNewValue).add(30, 'm');
        if (moment(checkNewValue).isBefore(endDate)) {
          newEndDate = endDate;
        }
        formInstance.setFieldsValue({
          ...formInstance.getFieldsValue(),
          endDate: newEndDate,
        });
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(true);
        setTimeout(() => {
          setEndTimeChange(false);
        }, 500);
      }
    };

    const handleChangeEndDate = (value) => {
      if (value === null) {
        return;
      }

      const { startDate, endDate, startTime, endTime } =
        formInstance.getFieldsValue();

      const newEndTime = moment(endTime, 'hh:mm A');
      const checkNewValue = moment(value)
        .hour(newEndTime.hour())
        .minute(newEndTime.minute());

      if (moment(checkNewValue).isBefore(startDate)) {
        dialogOkFunction({
          type: 'warn',
          content: (
            <Row>
              <Col span={24}>
                The end time you entered occurs before the start time.
              </Col>
            </Row>
          ),
        });

        const newEndDate = moment(startDate).minute(0).second(0).add(30, 'm');

        formInstance.setFieldsValue({
          ...formInstance.getFieldsValue(),
          endDate: newEndDate,
          endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
        });
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(false);
      } else {
        setEndTimeChange(true);
        setTimeout(() => {
          setEndTimeChange(false);
        }, 500);
      }
    };

    const handleChangeStartTime = (value) => {
      const formattedValue = moment(value, 'hh:mm A');
      const hour = formattedValue.hour();
      const minute = formattedValue.minute();

      const { startDate, endDate } = formInstance.getFieldsValue();

      const currentDate = new Date();
      const newStartTime = moment(value, 'hh:mm A');
      const checkNewValue = moment(startDate)
        .hour(newStartTime.hour())
        .minute(newStartTime.minute());

      if (moment(checkNewValue).isBefore(currentDate)) {
        dialogOkFunction({
          type: 'warn',
          content: (
            <Row>
              <Col span={24}>
                The start time you entered occurs before the current time.
              </Col>
            </Row>
          ),
        });

        const date = new Date();
        const newStartDate = moment(date).minute(0).second(0).add(1, 'h');
        let newEndDate = moment(newStartDate).add(30, 'm');
        if (moment(newStartDate).isBefore(endDate)) {
          newEndDate = endDate;
        }

        formInstance.setFieldsValue({
          ...formInstance.getFieldsValue(),
          startDate: newStartDate,
          endDate: newEndDate,
          startTime: moment(newStartDate, 'hh:mm:ss').format('hh:mm A'),
          endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
        });

        setStartTimeInput(newStartDate.format('hh:mm A'));
        setStartTimeChange(false);
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(false);
      } else {
        const newStartDate = startDate.hour(hour).minute(minute);
        let newEndDate = newStartDate.add(30, 'm');

        if (moment(checkNewValue).isBefore(endDate)) {
          newEndDate = endDate;
        }
        formInstance.setFieldsValue({
          ...formInstance.getFieldsValue(),
          startDate: newStartDate,
          endDate: newEndDate,
          startTime: moment(newStartDate, 'hh:mm:ss').format('hh:mm A'),
          endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
        });

        setStartTimeInput(newStartDate.format('hh:mm A'));
        setStartTimeChange(false);
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(false);
      }
    };

    const handleChangeEndTime = (value, checkAdd) => {
      const formattedValue = moment(value, 'hh:mm A');
      const hour = formattedValue.hour();
      const minute = formattedValue.minute();
      const { startDate, endDate, startTime } = formInstance.getFieldsValue();
      let newStartTime = moment(startTime, 'hh:mm A');

      let startDateTemp = startDate;
      if (isMeetingNow) {
        startDateTemp = moment(new Date());
        newStartTime = moment(moment(new Date()).format('hh:mm A'), 'hh:mm A');
      }

      if (checkAdd) {
        let newEndDate = startDateTemp.add(hour, 'h').add(minute, 'm');
        if (isMeetingNow) {
          formInstance.setFieldsValue({
            ...formInstance.getFieldsValue(),
            startDate: startDateTemp,
            startTime: newStartTime,
            endDate: newEndDate,
            endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
          });
        } else {
          formInstance.setFieldsValue({
            ...formInstance.getFieldsValue(),
            endDate: newEndDate,
            endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
          });
        }
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(false);
        return;
      }

      let newEndDate = endDate.hour(hour).minute(minute);
      if (startDateTemp.isBefore(newEndDate)) {
        if (isMeetingNow) {
          formInstance.setFieldsValue({
            ...formInstance.getFieldsValue(),
            startDate: startDateTemp,
            startTime: newStartTime,
            endDate: newEndDate,
            endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
          });
        } else {
          formInstance.setFieldsValue({
            ...formInstance.getFieldsValue(),
            endDate: newEndDate,
            endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
          });
        }
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(false);
      } else {
        dialogOkFunction({
          type: 'warn',
          content: (
            <Row>
              <Col span={24}>
                The end time you entered occurs before the start time.
              </Col>
            </Row>
          ),
        });

        newEndDate = endDate
          .hour(newStartTime.hour())
          .minute(newStartTime.minute())
          .add(30, 'm');

        if (isMeetingNow) {
          formInstance.setFieldsValue({
            ...formInstance.getFieldsValue(),
            startDate: startDateTemp,
            startTime: newStartTime,
            endDate: newEndDate,
            endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
          });
        } else {
          formInstance.setFieldsValue({
            ...formInstance.getFieldsValue(),
            endDate: newEndDate,
            endTime: moment(newEndDate, 'hh:mm:ss').format('hh:mm A'),
          });
        }
        setEndTimeInput(newEndDate.format('hh:mm A'));
        setEndTimeChange(false);
      }
    };

    const handleDisabledEndDate = (currentDate) => {
      const { startDate } = formInstance.getFieldsValue();

      return currentDate && currentDate < moment(startDate).startOf('day');
    };

    return (
      <Form
        {...layout}
        form={formInstance}
        // initialValues={getInitialFormValues()}
      >
        <Row>
          <Col span={3} style={{ textAlign: 'center' }}>
            {children}
          </Col>
          <Col span={20}>
            <Form.Item
              label='Title'
              className='form-item-title'
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Please input title',
                },
              ]}
            >
              <Input placeholder='Input title' />
            </Form.Item>
            <Row>
              {isMeetingNow ? (
                <Col span={12}>
                  <Row
                    align='middle'
                    style={{ marginBottom: 14, marginTop: -2 }}
                  >
                    <Col span={5}>
                      <Typography.Text className='form-item-time-now'>
                        Start Time :
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text strong style={{ color: '#1890ff' }}>
                        NOW
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <>
                  <Col span={12}>
                    <Form.Item
                      label='Start Time'
                      name='startDate'
                      wrapperCol={{ span: 17 }}
                      labelCol={{ span: 5 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input start date',
                        },
                      ]}
                    >
                      <DatePicker
                        picker='date'
                        format={DATE_FORMAT.SYSTEM_FORMAT}
                        style={{ width: 300, height: 34 }}
                        disabledDate={disabledPreviousDays}
                        onChange={handleChangeStartDate}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={null}
                      name='startTime'
                      labelCol={{ span: 4 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input start time',
                        },

                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            const endTime = getFieldValue('endTime');
                            const endDate = getFieldValue('endDate');
                            const startDate = getFieldValue('startDate');

                            if (!value || !endTime || !startDate || !endDate) {
                              return Promise.resolve();
                            } else {
                              const currentDate = moment();
                              if (moment(endDate).isBefore(startDate)) {
                                return Promise.reject([
                                  'Start time should be smaller than end time.',
                                ]);
                              }
                              if (moment(startDate).isBefore(currentDate)) {
                                return Promise.reject([
                                  'Start time should be greater than current time.',
                                ]);
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {/* <TimePicker
                      use12Hours
                      format='h:mm a'
                      style={{ width: '100%' }}
                      showNow={false}
                      onChange={handleChangeStartTime}
                      minuteStep={10}
                    /> */}
                      <InputTimeOutLook
                        idKey='start'
                        formInstance={formInstance}
                        handleChangeTime={handleChangeStartTime}
                        timeInput={startTimeInput}
                        setTimeInput={setStartTimeInput}
                        timeChange={startTimeChange}
                        setTimeChange={setStartTimeChange}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label='End Time'
                  name='endDate'
                  wrapperCol={{ span: 17 }}
                  labelCol={{ span: 5 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input end date',
                    },
                  ]}
                >
                  <DatePicker
                    picker='date'
                    format={DATE_FORMAT.SYSTEM_FORMAT}
                    style={{ width: 300, height: 34 }}
                    disabledDate={handleDisabledEndDate}
                    onChange={handleChangeEndDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={null}
                  name='endTime'
                  labelCol={{ span: 4 }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input end time',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const startTime = getFieldValue('startTime');
                        const startDate = getFieldValue('startDate');
                        const endDate = getFieldValue('endDate');

                        if (!value || !startTime || !startDate || !endDate) {
                          return Promise.resolve();
                        } else {
                          if (moment(endDate).isBefore(startDate)) {
                            return Promise.reject([
                              'End time should be greater than start time.',
                            ]);
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  {/* <TimePicker
                  use12Hours
                  format='h:mm a'
                  style={{ width: '100%' }}
                  showNow={false}
                  minuteStep={10}
                /> */}
                  <InputTimeOutLook
                    formInstance={formInstance}
                    idKey='end'
                    handleChangeTime={handleChangeEndTime}
                    timeInput={endTimeInput}
                    setTimeInput={setEndTimeInput}
                    timeChange={endTimeChange}
                    setTimeChange={setEndTimeChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
);

export const ExternalAttendee = React.memo(
  ({
    externalAttendees,
    handleAddExternalAttendees,
    handleDeleteExternalAttendees,
  }) => {
    return (
      <Row style={{ marginTop: 8 }}>
        <Col span={19} offset={5}>
          <Popover
            placement='rightTop'
            trigger='click'
            title={null}
            destroyTooltipOnHide
            content={
              <ExternalEmailForm
                externalAttendees={externalAttendees}
                handleAddExternalAttendees={handleAddExternalAttendees}
                handleDeleteExternalAttendees={handleDeleteExternalAttendees}
              />
            }
            overlayClassName='modal-meeting__popover'
          >
            <FormAddButton
              text='Add External Emails'
              style={{ borderRadius: 'none' }}
            />
          </Popover>
        </Col>
      </Row>
    );
  }
);

export const ExternalEmailForm = React.memo(
  ({
    externalAttendees,
    handleAddExternalAttendees,
    handleDeleteExternalAttendees,
  }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
      const params = { ...values, id: uuidv4() };
      handleAddExternalAttendees(params);
      form.resetFields();
    };

    return (
      <Form
        {...layout}
        name='external-attendee'
        form={form}
        onFinish={onFinish}
      >
        <Row style={{ padding: 12 }}>
          <Col span={20} style={{ marginBottom: 6 }}>
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Email is not valid!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (externalAttendees?.length !== 0) {
                      if (
                        externalAttendees?.findIndex(
                          (attendee) => attendee?.email === value
                        ) !== -1
                      ) {
                        return Promise.reject(['Email already exist.']);
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                placeholder='Email'
                allowClear
                enterButton
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <FormAddButton
              text='Add'
              style={{ borderRadius: 'none', margin: '2px 0px 0px 10px' }}
              htmlType='submit'
            />
          </Col>
        </Row>
        <DisplayExternalAttendees
          externalAttendees={externalAttendees}
          handleDeleteExternalAttendees={handleDeleteExternalAttendees}
          isSliceAttendees={true}
        />
      </Form>
    );
  }
);

const DisplayExternalAttendees = React.memo(
  ({
    externalAttendees,
    handleDeleteExternalAttendees,
    isSliceAttendees = false,
  }) => {
    const externalAttendeesList = isSliceAttendees
      ? externalAttendees
      : externalAttendees?.slice(4);

    return (
      <>
        <Typography.Title level={5}>External Emails</Typography.Title>

        <div className='list--external-attendee scroller'>
          {externalAttendees?.length !== 0 ? (
            <List
              dataSource={externalAttendeesList}
              renderItem={(attendee, index) => (
                <List.Item key={attendee.id}>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={attendee?.avatar}
                        icon={<UserOutlined />}
                        size={50}
                      />
                    }
                    title={`Email ${++index}`}
                    description={
                      <div style={{ maxWidth: '350px' }}>
                        <TextTooltip text={attendee.email} />
                      </div>
                    }
                  />
                  <div key={attendee.id}>
                    <Button
                      type='primary'
                      danger
                      icon={<DeleteOutlined />}
                      style={{ borderRadius: 4 }}
                      onClick={() => handleDeleteExternalAttendees(attendee.id)}
                    />
                  </div>
                </List.Item>
              )}
            />
          ) : (
            <Empty />
          )}
        </div>
      </>
    );
  }
);
