import React from 'react';

import { Menu, Typography, Row, Col } from 'antd';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

const { Text } = Typography;

const AplSendSomethingProductList = (props) => {
  const {
    productList,
    selectedProduct,
    setSelectedProduct,
    productSendingStatusList,
    notReadyProducts,
  } = props;

  const onClickProductItem = ({ key }) => {
    const product = productList.find((productItem) => productItem.id === +key);

    setSelectedProduct(product);
  };

  const renderProductItem = (product) => {
    const iconStyle = {
      fontSize: '18px',
      marginRight: 0,
    };

    const statusIcons = {
      success: <CheckCircleTwoTone style={iconStyle} twoToneColor='#52c41a' />,
      error: <CloseCircleTwoTone style={iconStyle} twoToneColor='#ff4d4f' />,
    };

    const productSendingStatus = productSendingStatusList[product?.id];

    const icon = statusIcons[productSendingStatus] || null;

    const classNameItem = notReadyProducts.includes(product.id)
      ? 'apl-send-question__item-highlight'
      : '';

    return (
      <Menu.Item key={product.id} className={classNameItem}>
        <Row justify='space-between' align='middle'>
          <Col flex={1} style={{ maxWidth: '137px' }}>
            <Text ellipsis style={{ width: '100%' }} strong>
              {product?.gtin || product?.upc12 || product.name}
            </Text>
          </Col>
          {icon}
        </Row>
      </Menu.Item>
    );
  };

  return (
    <Menu
      className='apl-send-modal__product-list'
      selectedKeys={[`${selectedProduct?.id}`]}
      onClick={onClickProductItem}
    >
      {productList?.map(renderProductItem)}
    </Menu>
  );
};

export default AplSendSomethingProductList;
