import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  error: false,
  products: [],
  total: 0,
  pageSize: 20,
  pageIndex: 1,
  search: '',
  columns: [],
  gridName: '',
  reloadGrid: false,
  gridConfig: {
    allConfigFieldsName: null,
    selectedColumns: null,
    defaultColumnsGrid: [],
    isConfigEmpty: true,
    isHaveDefaultConfig: false,
  },
  applyGridConfig: {
    configId: null,
    configName: null,
    id: null,
    isDefault: null,
    jsonConfig: null,
    lastModified: null,
    selectedColumns: null,
    isApply: false,
  },
  // ENUM
  enums: null,
  //
  addProductStatus: false,
  addProductError: false,

  brandImageOnAdd: {
    loading: false,
    error: false,
    data: [],
    totalItems: 0,
    pageSize: 20,
    pageIndex: 1,
  },
  isDisplayProductNew: false,

  GDSNPartyList: {
    loading: false,
    error: false,
    data: [],
  },
};

const formatFieldsName = (jsonConfig) => {
  let fieldsName = [];

  if (jsonConfig) {
    const config = JSON.parse(jsonConfig);

    config.columns.forEach((column) => {
      fieldsName.push({
        fieldName: column.fieldFullPath,
      });
    });
  }

  return fieldsName;
};

/* eslint-disable default-case, no-param-reassign */
const productReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PRODUCT_LIST:
        draft.loading = true;
        draft.error = false;
        draft.pageSize = action.pageSize;
        draft.pageIndex = action.pageIndex;
        draft.search = action.search;
        break;
      case types.GET_MEMBER_PRODUCT_LIST:
        draft.loading = true;
        draft.error = false;
        break;
      case types.GET_PRODUCT_LIST_SUCCESS:
        draft.loading = false;
        draft.products = action.products;
        draft.total = action.total;
        draft.reloadGrid = false;
        break;
      case types.GET_PRODUCT_LIST_ERROR:
        draft.loading = false;
        draft.total = 0;
        draft.products = [];
        draft.error = action.error;
        break;
      case types.PRODUCT_GRID_COLUMN_INFO:
        draft.loading = true;
        draft.error = false;
        draft.gridName = action.gridName;
        break;
      case types.PRODUCT_GRID_COLUMN_INFO_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;
      case types.PRODUCT_GRID_COLUMN_INFO_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;

      case types.TOGGLE_RELOAD_GRID_PRODUCT:
        draft.reloadGrid = action.payload;
        break;

      case types.SAVE_GRID_CONFIG:
        draft.gridConfig = {
          ...draft.gridConfig,
          ...action.payload,
        };
        break;

      case types.APPLY_GRID_CONFIG:
        draft.applyGridConfig = action.payload;
        break;

      case types.ADD_PRODUCT:
        draft.addProductStatus = true;
        draft.addProductError = false;
        break;
      case types.ADD_PRODUCT_SUCCESS:
        draft.addProductStatus = false;
        break;
      case types.ADD_PRODUCT_ERROR:
        draft.addProductStatus = false;
        draft.addProductError = true;
        break;

      case types.GET_PRODUCT_BRAND_ON_ADD:
        draft.brandImageOnAdd['loading'] = true;
        break;
      case types.GET_PRODUCT_BRAND_ON_ADD_SUCCESS:
        draft.brandImageOnAdd['loading'] = false;
        draft.brandImageOnAdd['data'] = action?.payload?.gridData || [];
        draft.brandImageOnAdd['pageIndex'] =
          action?.payload?.paging?.currentPageIndex ||
          initialState.brandImageOnAdd.pageIndex;
        draft.brandImageOnAdd['pageSize'] =
          action?.payload?.paging?.currentPageSize ||
          initialState.brandImageOnAdd.pageSize;
        draft.brandImageOnAdd['totalItems'] =
          action?.payload?.paging?.totalRecord ||
          initialState.brandImageOnAdd.totalItems;
        break;
      case types.GET_PRODUCT_BRAND_ON_ADD_ERROR:
        draft.brandImageOnAdd['loading'] = false;
        break;
      case types.TOGGLE_PRODUCT_NEW:
        draft.isDisplayProductNew = action.payload;
        break;

      case types.GET_GDSN_PARTY_LIST:
        draft.GDSNPartyList['loading'] = true;
        break;
      case types.GET_GDSN_PARTY_LIST_SUCCESS:
        draft.GDSNPartyList['loading'] = false;
        draft.GDSNPartyList['data'] = action?.columns || [];

        break;
      case types.GET_GDSN_PARTY_LIST_FAILURE:
        draft.GDSNPartyList['loading'] = false;
        break;
    }
  });

export default productReducer;
