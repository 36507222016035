import React, { useState } from 'react';

import {
  Row,
  Col,
  Collapse,
  Dropdown,
  Menu,
  notification,
  Typography,
  Checkbox,
  Tag,
  Button,
  Space,
} from 'antd';

import {
  LabelValueWrapper,
  dialogFunction,
  WithAbsoluteContainer,
  FormAddButton,
} from 'common/components';

import LinkAssetToPimModal from './LinkAssetToPimModal';

import { EditOutlined, CloseOutlined } from '@ant-design/icons';

import { injectIntl } from 'react-intl';

import DigitalRight from 'assets/DRM.png';

import messages from 'i18n/messages/asset-full-view';
import * as constants from 'static/Constants';
import { DOWNLOAD_GS1_FILE } from 'services/digitalAsset/endpoints';
import { unlinkAssetToPIM } from 'services/digitalAsset';

import * as api from 'config/axios';

import './AssetGS1Preview.less';
import { sleep } from 'utils/delay';

const { Panel } = Collapse;

const { Text } = Typography;

const AssetGS1Preview = (props) => {
  const { activeGS1, canEdit, gs1ImageData, assetId, onReloadPage, assetData } =
    props;

  const isShowCreateLink = canEdit && !assetData?.forMemberDocument; // because member document will not link to product

  const [selectedEditLink, setSelectedEditLink] = useState(null);

  const openNotification = (result, message) => {
    notification[result ? result : 'error']({ message: message });
  };

  const gs1Header = (obj) => {
    return (
      <div className='asset-overview__gs1-header'>
        <Row>
          <Col
            className='asset-overview__gs1-header-package-level'
            flex='100px'
          >
            <Text>{obj?.packageLevel}</Text>
          </Col>
          <Col
            className='asset-overview__gs1-header-gtin'
            style={{ flex: 1, overflow: 'hidden' }}
          >
            <Text ellipsis>GTIN - {obj?.gtin || 'n/a'}</Text>
          </Col>
          <Col className='asset-overview__gs1-header-association' flex='120px'>
            <Text ellipsis>{obj?.associateType || 'n/a'}</Text>
          </Col>
        </Row>
        {obj?.compliantFileName && (
          <Row>
            <Col xs={24} className='asset-overview__gs1-header-compliant-name'>
              <Text ellipsis>{obj?.compliantFileName}</Text>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const gs1Filter = (obj) => {
    const newObj = Object.create(Object.getPrototypeOf(obj));

    newObj.associationType = obj?.associateType;
    newObj.packageLevel = obj?.packageLevel;

    if (newObj.associationType === constants.ASSOCIATION_TYPE.DOCUMENT) {
      newObj.subtype = obj?.subtype;
    }

    //* if association type is PLANOGRAM or MARKETING image type field will be shown
    if (
      newObj.associationType === constants.ASSOCIATION_TYPE.PLANOGRAM &&
      newObj.associationType === constants.ASSOCIATION_TYPE.MARKETING
    ) {
      newObj.imageType = constants.IMAGE_TYPE.filter(
        (item) => item.value === obj?.imageType
      )[0]?.label;
    }

    //* if association type is PLANOGRAM, we should include these fields in preview
    if (newObj.associationType === constants.ASSOCIATION_TYPE.PLANOGRAM) {
      newObj.compliantFileName = obj?.compliantFileName;

      newObj.packagingDisposition = constants.PACKAGING_DISPOSITION.filter(
        (item) => item.value === obj?.packagingDisposition
      )[0]?.label;
      newObj.angle = constants.ANGLE.filter(
        (item) => item.value === obj?.angle
      )[0]?.label;
      newObj.facing = constants.FACING.filter(
        (item) => item.value === obj?.facing
      )[0]?.label;
    }
    newObj.gtin = obj?.gtin;

    return newObj;
  };

  const removeGS1 = async (item) => {
    const associationType = item?.associateType;
    const params =
      associationType === 'DOCUMENT'
        ? {
            linkId: item.id,
          }
        : {
            productImageLinkId: item.id,
          };

    const response = await unlinkAssetToPIM(params, associationType);

    //* BE dose not update link data instantly
    await sleep(1000);

    if (response.isSuccess) {
      openNotification('success', 'Remove link data successfully!');
      onReloadPage && onReloadPage();
    } else openNotification('error', 'Remove link data failed!');
  };

  const handleClickEditLink = (item) => {
    setSelectedEditLink(item);
  };

  const handleClickAddNewLink = () => setSelectedEditLink('new');

  const handleClickUnlink = (item) => {
    dialogFunction({
      type: 'warn',
      content: 'Do you want to remove this item?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: async () => {
        await removeGS1(item);
      },
    });
  };

  const handleCloseLinkAssetModal = () => {
    setSelectedEditLink(null);
  };

  if (!activeGS1) return null;

  return (
    <Row className='asset-gs1-preview'>
      <Col flex='10px' />
      <Col>
        <Collapse
          className='asset-overview__collapse'
          ghost
          expandIconPosition='right'
          defaultActiveKey={
            activeGS1?.length > 1
              ? []
              : activeGS1?.length !== 0 && activeGS1[0]?.id
          }
        >
          {activeGS1?.map((item) => {
            return (
              <Panel header={gs1Header(item)} key={item.id} forceRender>
                <Row>
                  <Col style={{ flex: 1 }}>
                    <LabelValueWrapper
                      object={gs1Filter(item)}
                      parentScoop='digitalAsset.overview'
                      colspan={12}
                    />
                  </Col>
                  {canEdit && (
                    <Col style={{ width: 77 }}>
                      <Space>
                        <Button
                          icon={<EditOutlined />}
                          type='primary'
                          style={{ borderRadius: 4 }}
                          onClick={() => handleClickEditLink(item)}
                        />
                        <Button
                          icon={<CloseOutlined />}
                          type='primary'
                          danger
                          style={{ borderRadius: 4 }}
                          onClick={() => handleClickUnlink(item)}
                        />
                      </Space>
                    </Col>
                  )}
                </Row>
              </Panel>
            );
          })}
        </Collapse>
      </Col>
      <WithAbsoluteContainer>
        {selectedEditLink && (
          <LinkAssetToPimModal
            gs1ImageData={gs1ImageData}
            assetId={assetId}
            onCloseModal={handleCloseLinkAssetModal}
            isAssetImport={selectedEditLink === 'new'}
            isAssetImportEdit={Boolean(
              selectedEditLink !== 'new' && !!selectedEditLink
            )}
            assetData={assetData}
            selectedEditLink={selectedEditLink}
            onReloadPage={onReloadPage}
          />
        )}

        {isShowCreateLink && (
          <FormAddButton
            className='asset-gs1-preview__btn-create-link'
            text='Create New Link'
            onClick={handleClickAddNewLink}
          />
        )}
      </WithAbsoluteContainer>
    </Row>
  );
};

export default injectIntl(AssetGS1Preview);
