import * as types from './constants';

/**
 * ? GET ROLE LIST
 * @param {*} payload
 * @returns action;
 */
export const getRoleList = (payload) => ({
  type: types.GET_ROLE_LIST,
  payload,
});
export const getRoleListSuccess = (payload) => ({
  type: types.GET_ROLE_LIST_SUCCESS,
  payload,
});
export const getRoleListFail = (payload) => ({
  type: types.GET_ROLE_LIST_FAIL,
  payload,
});

/**
 * ? GET ALL PERMISSION
 * @param {*} payload
 * @returns action
 */
export const getAvailablePermissions = () => ({
  type: types.GET_AVAILABLE_PERMISSIONS,
});
export const getAvailablePermissionSuccess = (payload) => ({
  type: types.GET_AVAILABLE_PERMISSIONS_SUCCESS,
  payload,
});
export const getAvailablePermissionError = (payload) => ({
  type: types.GET_AVAILABLE_PERMISSIONS_FAIL,
  payload,
});

/**
 * ? GET ROLE USER LIST COLUMN
 * @param {*} payload
 * @returns action
 */
export const gridColumnRoleUsers = (payload) => ({
  type: types.GET_COLUMNS_GRID_ROLE_USERS,
  payload,
});
export const gridColumnRoleUsersSuccess = (payload) => ({
  type: types.GET_COLUMNS_GRID_ROLE_USERS_SUCCESS,
  payload,
});
export const gridColumnRoleUsersFail = (payload) => ({
  type: types.GET_COLUMNS_GRID_ROLE_USERS_FAIL,
  payload,
});

/**
 * ? GET ROLE PERMISSION LIST
 * @param {*} payload (roleId)
 * @returns action
 */
export const getRolePermissionList = (payload) => ({
  type: types.GET_ROLE_PERMISSION_LIST,
  payload,
});
export const getRolePermissionListSuccess = (payload) => ({
  type: types.GET_ROLE_PERMISSION_LIST_SUCCESS,
  payload,
});
export const getRolePermissionListFail = (payload) => ({
  type: types.GET_ROLE_PERMISSION_LIST_FAIL,
  payload,
});

/**
 * ? SET SELECTED ROLE
 * @param {*} payload (roleId)
 * @returns action
 */
export const setSelectedRole = (payload) => ({
  type: types.SET_SELECTED_ROLE,
  payload,
});

/**
 * ? CLEAR ROLE STATE
 * @param
 * @returns action
 */
export const clearRoleState = () => ({
  type: types.CLEAR_ROLE_STATE,
});

//* security matrix action
/**
 * ? GET MEMBER ROLE LIST
 * @param {*} payload
 * @returns action;
 */
export const getMemberRoleList = (payload) => ({
  type: types.GET_MEMBER_ROLE_LIST,
  payload,
});
export const getMemberRoleListSuccess = (payload) => ({
  type: types.GET_MEMBER_ROLE_LIST_SUCCESS,
  payload,
});
export const getMemberRoleListFail = (payload) => ({
  type: types.GET_MEMBER_ROLE_LIST_FAIL,
  payload,
});

/**
 * ? SET SELECTED MEMBER ROLE
 * @param {*} payload (roleId)
 * @returns action
 */
export const setSelectedMemberRole = (payload) => ({
  type: types.SET_SELECTED_MEMBER_ROLE,
  payload,
});

/**
 * ? GET ALL MEMBER PERMISSION LIST
 * @param {*} payload
 * @returns action;
 */
export const getAllMemberPermissionList = (payload) => ({
  type: types.GET_ALL_MEMBER_PERMISSION_LIST,
  payload,
});
export const getAllMemberPermissionListSuccess = (payload) => ({
  type: types.GET_ALL_MEMBER_PERMISSION_LIST_SUCCESS,
  payload,
});
export const getAllMemberPermissionListFail = (payload) => ({
  type: types.GET_ALL_MEMBER_PERMISSION_LIST_FAIL,
  payload,
});

/**
 * ? HANDLE SAVE MEMBER ROLE FOR EDIT AND CREATE NEW ROLE
 * @param {*} payload
 * @returns action;
 */
export const saveMemberRole = (payload) => ({
  type: types.SAVE_MEMBER_ROLE,
  payload,
});

/**
 * ? HANDLE DELETE MEMBER ROLE
 * @param {*} payload
 * @returns action;
 */
export const deleteMemberRole = (payload) => ({
  type: types.DELETE_MEMBER_ROLE,
  payload,
});

/**
 * ? GET ROLE MEMBER LIST COLUMN
 * @param {*} payload
 * @returns action
 */
export const gridColumnRoleMembers = (payload) => ({
  type: types.GET_COLUMNS_GRID_ROLE_MEMBERS,
  payload,
});
export const gridColumnRoleMembersSuccess = (payload) => ({
  type: types.GET_COLUMNS_GRID_ROLE_MEMBERS_SUCCESS,
  payload,
});
export const gridColumnRoleMembersFail = (payload) => ({
  type: types.GET_COLUMNS_GRID_ROLE_MEMBERS_FAIL,
  payload,
});

/**
 * ? GET ROLE MEMBER NOT IN ROLE
 * @param {*} payload
 * @returns action
 */
export const gridColumnMembersNotInRole = (payload) => ({
  type: types.GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE,
  payload,
});
export const gridColumnMembersNotInRoleSuccess = (payload) => ({
  type: types.GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_SUCCESS,
  payload,
});
export const gridColumnMembersNotInRoleFail = (payload) => ({
  type: types.GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_FAIL,
  payload,
});

/**
 * ? REMOVE MEMBER FROM ROLE
 * @param {*} payload
 * @returns action
 */
export const removeMemberFromRole = (payload) => ({
  type: types.REMOVE_MEMBER_FROM_ROLE,
  payload,
});
