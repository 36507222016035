import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as markupMaintenanceActions from 'pages/markup-maintenance/controllers/actions';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const EditMarkupGridView = () => {
  const dispatch = useDispatch();
  const selectedMarkupDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const markupId =
    selectedMarkupDetail && selectedMarkupDetail.length > 0
      ? selectedMarkupDetail[0].id
      : undefined;

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editMarkup}
      onClick={() => {
        dispatch(markupMaintenanceActions.enableEditMarkup());
        forwardTo(`/maintenance/markup/list/${markupId}`);
      }}
      disabled={markupId === undefined || selectedMarkupDetail.length !== 1}
    />
  );
};

export default EditMarkupGridView;
