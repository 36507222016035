import React, { useState, useEffect } from 'react';
import { Button, Col, Input, Row } from 'antd';

import { CustomNotification, Form } from 'common/components';
import ModalAction from 'common/components/modal-action/ModalAction';

import * as folderServices from 'services/folder';
import { apiHandler } from 'utils/api';

import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/folder';
import { sleep } from 'utils/delay';

const CreateFolderModal = (props) => {
  const {
    intl,
    visibleHook,
    successCallback,
    handleReloadFolderList,
    currentFolderId,
    parentId,
    onReloadWhenSaveSuccess,
    additionalButton,
    skipNotification = false,
  } = props;
  const [visible, setVisible] = visibleHook;
  const [loading, setLoading] = useState(false);
  const [formInst] = Form.useForm();

  const closeModal = () => {
    !loading && setVisible(false);
  };

  const handleCreateFolder = async (value) => {
    formInst
      .validateFields()
      .then((values) => {
        const { name } = values;
        const trimmedName = name?.trim();
        if (name) {
          const params = {
            folderName: trimmedName,
            parentFolderId: parentId,
          };

          setLoading(true);
          apiHandler({
            service: folderServices.createFolder,
            params: params,
            successMessage: intl.formatMessage(Messages.createFolderSuccess),
            errorMessage: intl.formatMessage(Messages.createFolderError),
            successCallback: createFolderSuccessCallback,
            failCallback: createFolderFailureCallback,
            onFinally: createFolderFinally,
            skipNotification,
          });
        }
      })
      .catch((error) => {});
  };

  const createFolderSuccessCallback = async (responseData) => {
    await sleep(1000);

    !!successCallback && successCallback(responseData);
    !!onReloadWhenSaveSuccess &&
      onReloadWhenSaveSuccess(responseData.id, 'create');
  };

  const createFolderFailureCallback = (message) => {
    message && CustomNotification.error(message);
  };

  const createFolderFinally = async () => {
    if (handleReloadFolderList) {
      await sleep(3000);
      handleReloadFolderList(currentFolderId);
    }

    setVisible(false);
    setLoading(false);
  };

  useEffect(() => {
    if (!visible) {
      formInst.resetFields();
    }
  }, [visible, formInst]);

  return (
    <ModalAction
      visible={visible}
      title={intl.formatMessage(Messages.createModalTitle)}
      onCancel={closeModal}
      wrapClassName='folder-modal'
      footer={null}
      destroyOnClose={true}
    >
      <Form layout='vertical' form={formInst}>
        <Form.Item
          name='name'
          className='folder-modal__form-item'
          label={intl.formatMessage(Messages.createModalNameLabel)}
          required
          rules={[
            {
              required: true,
              message: intl.formatMessage(Messages.nameRequiredMessage),
            },
            {
              whitespace: true,
              message: intl.formatMessage(Messages.nameRequiredMessage),
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage(
              Messages.createModalNamePlaceholder
            )}
          />
        </Form.Item>

        <Form.Item className='folder-modal__button-container'>
          <Row justify={additionalButton ? 'space-between' : 'end'}>
            {additionalButton && additionalButton()}
            <Col>
              <Button type='default' onClick={closeModal}>
                {intl.formatMessage(Messages.createModalCancelButton)}
              </Button>
              <Button
                type='primary'
                onClick={handleCreateFolder}
                loading={loading}
              >
                {intl.formatMessage(Messages.createModalCreateButton)}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </ModalAction>
  );
};

export default injectIntl(CreateFolderModal);
