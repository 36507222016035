import React from 'react';

import {
  Collapse,
  Tooltip,
  Typography,
  Modal,
  Row,
  Col,
  Spin,
  Form,
  Input,
  DatePicker,
  Button,
  InputNumber,
  Empty,
  Select,
  Alert,
  Radio,
} from 'antd';

import classnames from 'classnames';

import {
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { WrapperSelect } from 'common/components';

import { useProductDetail } from '../components/product-new-detail-view/ProductDetailContext';

import {
  checkDisabledFormField,
  getDataByFieldFullPath,
  checkRequiredFormField,
  checkSpecialField,
} from './utils';

import sortByKey from 'utils/sortByKey';

import {
  checkIsUSRegion,
  getFormValuesAfterUpdatingValueField,
} from '../components/product-new-detail-view/allergenUtils';

import { DATE_FORMAT } from 'static/Constants';
import {
  DEFAULT_US_ALLERGENS,
  DEFAULT_WW_ALLERGENS,
} from 'static/AllergenConstants';

export const ButtonEditModuleProperties = ({
  moduleName,
  moduleDisplayName,
  onClick,
}) => {
  return (
    <>
      <Tooltip placement='left' title={`Edit ${moduleDisplayName}`}>
        <EditOutlined
          className='product-detail-view__edit-icon'
          onClick={(event) => {
            event.stopPropagation();
            onClick(moduleName, moduleDisplayName);
          }}
        />
      </Tooltip>
    </>
  );
};

export const CollapseView = ({
  children,
  headerPanel,
  keyPanel,
  extraPanel,
  ...otherProps
}) => {
  return (
    <Collapse
      bordered={false}
      ghost
      expandIconPosition='right'
      className='product-detail-view__collapse'
      {...otherProps}
    >
      <Collapse.Panel
        header={headerPanel}
        key={keyPanel}
        extra={typeof extraPanel === 'function' && extraPanel()}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export const ModalForm = ({ children, ...otherProps }) => {
  return (
    <Modal
      title='Edit Information'
      width={'70vw'}
      bodyStyle={{ maxHeight: '80vh' }}
      closable={true}
      maskClosable={false}
      destroyOnClose
      centered
      {...otherProps}
    >
      {children}
    </Modal>
  );
};

export const ModalEditForm = ({ children, ...otherProps }) => {
  return <ModalForm {...otherProps}>{children}</ModalForm>;
};

export const HeaderGroupElement = ({ header, style, ...otherProps }) => {
  return (
    <div
      style={{
        marginTop: -12,
        zIndex: 20,
        ...style,
      }}
    >
      {header && (
        <div className='product-detail__group-header' {...otherProps}>
          {header}
        </div>
      )}
    </div>
  );
};

export const GroupElementWrapper = ({
  children,
  title,
  style,
  ...otherProps
}) => {
  return (
    <div
      className='product-detail-view__group-element-wrapper'
      style={{
        border: '1px solid #c2cfe0',
        boxShadow: '0px 4px 10px rgb(0 0 0 / 6%)',
        borderRadius: '4px',
        width: '100%',
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export const PropertyLabel = ({ children, className, ...otherProps }) => {
  return (
    <Typography.Text
      strong
      ellipsis
      className={classnames(
        'product-detail__text-info product-detail__text-info--label',
        className
      )}
      {...otherProps}
    >
      {children}
    </Typography.Text>
  );
};

export const PropertyInfo = ({ children, className, ...otherProps }) => {
  return (
    <Typography.Text
      className={classnames('product-detail__text-info', className)}
      {...otherProps}
    >
      {children}
    </Typography.Text>
  );
};

export const PropertyText = ({ property, data }) => {
  const { fieldFullPath, propertyDisplayName } = property;

  const value = getDataByFieldFullPath(data, fieldFullPath);

  return (
    <>
      {value && (
        <Row>
          <Col span={12}>
            <PropertyLabel>{propertyDisplayName}:</PropertyLabel>
          </Col>
          <Col span={12}>
            <PropertyInfo>{value}</PropertyInfo>
          </Col>
        </Row>
      )}
    </>
  );
};

export const PropertyNumber = ({ property, data }) => {
  const { propertyDisplayName, fieldFullPath } = property;

  const value = getDataByFieldFullPath(data, fieldFullPath);
  const isValidNumber = value !== null && value !== undefined;

  return (
    <>
      {isValidNumber && (
        <Row>
          <Col span={12}>
            <PropertyLabel>{propertyDisplayName}:</PropertyLabel>
          </Col>
          <Col span={12}>
            <PropertyInfo>{value}</PropertyInfo>
          </Col>
        </Row>
      )}
    </>
  );
};

export const PropertyDatetime = ({ property, data }) => {
  const { fieldFullPath, propertyDisplayName } = property;

  const value = getDataByFieldFullPath(data, fieldFullPath);

  return (
    <>
      {value && (
        <Row>
          <Col span={12}>
            <PropertyLabel>{propertyDisplayName}:</PropertyLabel>
          </Col>
          <Col span={12}>
            <PropertyInfo>
              {value.format(DATE_FORMAT.SYSTEM_FORMAT)}
            </PropertyInfo>
          </Col>
        </Row>
      )}
    </>
  );
};

export const PropertyBoolean = ({ property, data }) => {
  const { propertyDisplayName, fieldFullPath } = property;

  const value = getDataByFieldFullPath(data, fieldFullPath);

  const isBooleanType = typeof value === 'boolean';

  // The customer request specified by ticket ID 7832.
  const hasSpecialField = checkSpecialField(fieldFullPath);

  return (
    <>
      {isBooleanType && (
        <Row>
          <Col span={12}>
            <PropertyLabel>{propertyDisplayName}:</PropertyLabel>
          </Col>
          <Col span={12}>
            <Radio.Group value={value}>
              <Radio style={{ marginLeft: 8 }} value={true}>
                {hasSpecialField ? 'Yes' : 'True'}
              </Radio>
              <Radio style={{ marginLeft: 8 }} value={false}>
                {hasSpecialField ? 'No' : 'False'}
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

export const PropertyList = ({ property, data }) => {
  const { fieldFullPath, propertyDisplayName, propertyType } = property;

  const value = getDataByFieldFullPath(data, fieldFullPath) ?? [];

  const filteredValue = value
    .filter((val) => val !== null && val !== undefined)
    .map((item) =>
      propertyType === 'datetime'
        ? item.format(DATE_FORMAT.SYSTEM_FORMAT)
        : item
    );

  return (
    <>
      {filteredValue.length > 0 && (
        <Row>
          <Col span={12}>
            <PropertyLabel>{propertyDisplayName}:</PropertyLabel>
          </Col>
          <Col span={12}>
            <PropertyInfo>{filteredValue.join(', ')}</PropertyInfo>
          </Col>
        </Row>
      )}
    </>
  );
};

export const PropertyEnum = ({ propertyDisplayName, value }) => {
  return (
    <>
      {value && (
        <Row>
          <Col span={12}>
            <PropertyLabel>{propertyDisplayName}:</PropertyLabel>
          </Col>
          <Col span={12}>
            <PropertyInfo>{value}</PropertyInfo>
          </Col>
        </Row>
      )}
    </>
  );
};

export const Loading = ({ style, ...otherProps }) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      {...otherProps}
    >
      <Spin indicator={loadingIcon} />
    </div>
  );
};

// Form

const getPropsFormItem = (property) => {
  if (!property) return null;

  return {
    name: property.propertyName,
    label: property.propertyDisplayName ? (
      <LabelFormItem
        label={property.propertyDisplayName}
        tooltip={property.propertyDescription}
        fieldFullPath={property.fieldFullPath}
      />
    ) : null,
  };
};

export const LabelFormItem = ({ label, fieldFullPath, tooltip }) => {
  const {
    advancedModule: { foundFieldFullPath: advancedFoundFieldFullPath },
    baseModule: { foundFieldFullPath: baseFoundFieldFullPath },
  } = useProductDetail();

  return (
    <>
      <Tooltip title={label}>
        <Typography.Text
          ellipsis
          className={classnames({
            'product-detail-view__label-form-item': true,
            [`product-detail-view__label-form-item--${fieldFullPath}`]: true,
            'product-detail-view__label-form-item--found-field':
              fieldFullPath?.toLowerCase() ===
                baseFoundFieldFullPath?.toLowerCase() ||
              fieldFullPath?.toLowerCase() ===
                advancedFoundFieldFullPath?.toLowerCase(),
          })}
          strong
        >
          {label}
        </Typography.Text>
      </Tooltip>

      {tooltip && (
        <Tooltip title={tooltip} color='#238dd9'>
          <QuestionCircleOutlined className='product-detail-view__hint-button' />
        </Tooltip>
      )}
    </>
  );
};

export const FormItemContainer = ({ property, children, ...otherProps }) => {
  return (
    <Form.Item {...getPropsFormItem(property)} {...otherProps}>
      {children}
    </Form.Item>
  );
};

export const FormItemText = ({
  property,
  placeholder,
  maxLength,
  ...otherProps
}) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      <Input
        placeholder={
          placeholder
            ? placeholder
            : `Please Input ${property.propertyDisplayName}`
        }
        showCount={maxLength ? true : false}
        maxLength={maxLength}
      />
    </FormItemContainer>
  );
};

/* True, False, Null */
export const FormItemBoolean = ({
  property,
  onChange,
  disabled,
  ...otherProps
}) => {
  const { fieldFullPath } = property;

  // The customer request specified by ticket ID 7832.
  const hasSpecialField = checkSpecialField(fieldFullPath);

  return (
    <FormItemContainer property={property} {...otherProps}>
      <Radio.Group disabled={disabled}>
        <Radio value={true}>{hasSpecialField ? 'Yes' : 'True'}</Radio>
        <Radio value={false}>{hasSpecialField ? 'No' : 'False'}</Radio>
      </Radio.Group>
    </FormItemContainer>
  );
};

/* True, False, Null */
export const FormItemAllergenBoolean = ({
  property,
  parentNestedIndex,
  onChange,
  disabled,
  ...otherProps
}) => {
  const { formInstance } = useProductDetail();

  const indexAllergenTypeCode = parentNestedIndex.concat('AllergenTypeCode');
  const indexRegion = parentNestedIndex.concat('Regional');

  const allergenTypeCode = Form.useWatch(indexAllergenTypeCode, formInstance);
  const region = Form.useWatch(indexRegion, formInstance);

  const isUS = checkIsUSRegion(region);

  const isDefaultField = isUS
    ? DEFAULT_US_ALLERGENS.includes(allergenTypeCode?.toLowerCase())
    : DEFAULT_WW_ALLERGENS.includes(allergenTypeCode?.toLowerCase());

  const isDisabled = Boolean(isDefaultField); // The default field cannot be published, while the custom field is optional.

  return (
    <FormItemBoolean
      property={property}
      onChange={onChange}
      disabled={isDisabled}
      {...otherProps}
    />
  );
};

export const FormItemDatetime = ({ property, ...otherProps }) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      <DatePicker format='MM-DD-YYYY' />
    </FormItemContainer>
  );
};

export const FormItemNumber = ({
  property,
  placeholder,
  maxLength,
  ...otherProps
}) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      {maxLength ? (
        <InputNumber
          placeholder={
            placeholder
              ? placeholder
              : `Please Input ${property.propertyDisplayName}`
          }
          step='0.1'
          stringMode
        />
      ) : (
        <InputNumber
          placeholder={
            placeholder
              ? placeholder
              : `Please Input ${property.propertyDisplayName}`
          }
        />
      )}
    </FormItemContainer>
  );
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
  },
};

const renderInputFieldByVariant = (variant, displayName) => {
  switch (variant) {
    case 'number':
      return (
        <InputNumber
          placeholder={`Please Input ${displayName}`}
          autoFocus
          // style={{ width: '100%' }}
        />
      );
    case 'datetime':
      return <DatePicker format='MM-DD-YYYY' />;

    default:
      return (
        <Input
          placeholder={`Please Input ${displayName}`}
          autoFocus
          style={{ width: '100%' }}
        />
      );
  }
};

export const FormItemList = ({
  property,
  name,
  className,
  variant = 'text',
  ...otherProps
}) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }, formListParams) => {
        const errors = formListParams?.errors;
        return (
          <Row className={className}>
            <FormItemContainer
              property={property}
              labelCol={{ span: 6 }}
              {...otherProps}
            >
              <Button
                type='default'
                onClick={() => add()}
                icon={<PlusOutlined />}
                style={{ borderRadius: 4 }}
              >
                Add value
              </Button>
            </FormItemContainer>

            {errors.length > 0 && (
              <Row style={{ width: '100%' }}>
                <Col offset={6} span={18}>
                  {errors[0]}
                </Col>
              </Row>
            )}

            <Col xs={6} />
            <Col flex={1}>
              <GroupElementWrapper
                style={{
                  display: fields.length === 0 ? 'none' : null,
                  marginRight: 40,
                  marginBottom: 12,
                  padding: '4px 12px 8px',
                  width: '96%',
                }}
              >
                {fields.map((field, index) => {
                  return (
                    <Row key={field.key}>
                      <Col flex='auto'>
                        <FormItemContainer
                          {...field}
                          {...(index === 0
                            ? formItemLayout
                            : formItemLayoutWithOutLabel)}
                        >
                          {renderInputFieldByVariant(
                            variant,
                            property.propertyDisplayName
                          )}
                        </FormItemContainer>
                      </Col>
                      {fields.length > 0 ? (
                        <Col flex='30px' style={{ textAlign: 'right' }}>
                          <Button
                            type='danger'
                            onClick={() => {
                              remove(field.name);
                            }}
                            icon={<CloseCircleOutlined />}
                            style={{
                              marginLeft: 4,
                              marginTop: 6,
                              borderColor: '#ff4d4f',
                              backgroundColor: '#ff4d4f',
                              borderRadius: 4,
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  );
                })}
              </GroupElementWrapper>
            </Col>
          </Row>
        );
      }}
    </Form.List>
  );
};

export const FormItemSelectEnum = ({
  property,
  nameFormItem,
  propertyEnums,
  ...otherProps
}) => {
  const { propertyDisplayName, isArray } = property;

  return (
    <FormItemContainer property={property} name={nameFormItem} {...otherProps}>
      <WrapperSelect
        showSearch
        listHeight={140}
        mode={isArray ? 'multiple' : ''}
        placeholder={`Please Select ${propertyDisplayName}`}
        filterOption={(input, option) =>
          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
      >
        {sortByKey(propertyEnums, 'enumDisplayName')?.map((property, index) => (
          <Select.Option
            value={property.enumDisplayName}
            key={property.enumDisplayName + index}
          >
            {property.enumDisplayName
              ? property.enumDisplayName
              : property.enumDescription}
          </Select.Option>
        ))}
      </WrapperSelect>
    </FormItemContainer>
  );
};

export const FormItemAllergenSelectEnum = ({
  property = {},
  nameFormItem,
  propertyEnums,
  parentNestedIndex,
  ...otherProps
}) => {
  const productDetailForm = Form.useFormInstance();

  const { propertyDisplayName, isArray, propertyName } = property;

  const indexLevelOfCode = parentNestedIndex.concat('LevelOfContainmentCode');
  const levelOfCodeValue = Form.useWatch(indexLevelOfCode, productDetailForm);

  const disabled = checkDisabledFormField({
    propertyName,
    levelOfCode: levelOfCodeValue,
  });

  const requiredFields = checkRequiredFormField(levelOfCodeValue);

  return (
    <FormItemContainer
      property={property}
      name={nameFormItem}
      rules={[
        {
          required: requiredFields[propertyName],
          message: 'Please select your value!',
        },
      ]}
      {...otherProps}
    >
      <WrapperSelect
        showSearch
        listHeight={140}
        mode={isArray ? 'multiple' : ''}
        placeholder={`Please Select ${propertyDisplayName}`}
        filterOption={(input, option) =>
          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        disabled={disabled}
        onChange={(value) => {
          if (
            propertyName === 'LevelOfContainmentCode' &&
            value?.toLowerCase() !== 'may contain'
          ) {
            const newFormValues = getFormValuesAfterUpdatingValueField({
              formValues: productDetailForm.getFieldsValue(),
              nestedIndex: parentNestedIndex.concat('ContainmentSource'),
              propertyName: 'ContainmentSource',
              value: null,
            });
            productDetailForm.setFieldsValue(newFormValues);
          }
        }}
      >
        {sortByKey(propertyEnums, 'enumDisplayName')?.map((property, index) => (
          <Select.Option
            value={property.enumDisplayName}
            key={property.enumDisplayName + index}
          >
            {property.enumDisplayName
              ? property.enumDisplayName
              : property.enumDescription}
          </Select.Option>
        ))}
      </WrapperSelect>
    </FormItemContainer>
  );
};

export const FormItemSelectSpecialEnum = ({
  property,
  nameFormItem,
  propertyEnums,
  ...otherProps
}) => {
  const { propertyDisplayName, isArray } = property;

  return (
    <FormItemContainer property={property} name={nameFormItem} {...otherProps}>
      <WrapperSelect
        listHeight={140}
        showSearch
        mode={isArray ? 'multiple' : ''}
        placeholder={`Please Select ${propertyDisplayName}`}
        filterOption={(input, option) =>
          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
      >
        {sortByKey(propertyEnums, 'enumCode')?.map((property, index) => (
          <Select.Option
            value={property.enumCode}
            key={property.enumCode + index}
          >
            {`${
              property.enumDescription
            } - (${property.enumCode.toUpperCase()})`}
          </Select.Option>
        ))}
      </WrapperSelect>
    </FormItemContainer>
  );
};

export const EmptyDataProduct = () => {
  return (
    <div className='product-detail-view__list-info product-detail-view__list-info--empty'>
      <Empty />
    </div>
  );
};

export const ErrorMessageProperty = ({ errors = [], ...rest }) => {
  return (
    <Alert
      className='product-detail-view__error-publication'
      message={errors[0]}
      type='error'
      // showIcon
      {...rest}
    />
  );
};
