import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';

import { useParams } from 'react-router-dom';

import { getKeyProductIngredients } from 'pages/product-full-view/components/product-media-area/multiple-panel/tab-ingredients/useProductIngredients';
import { useInvalidateGroupDetail } from 'pages/product-full-view/components/product-media-area/shared/hooks';
import { getQaPackagingTabDataQueryKey } from 'pages/qa-spec/components/qa-packaging/queries';

import {
  getQaProductIngredient,
  saveQaProductIngredient,
  getQaIngredientsSnapshot,
} from 'services/product';

import { sleep } from 'utils/delay';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

export const queryKeyQaSpecIngredients = (productId) => [
  'qa-spec',
  parseInt(productId),
  'ingredients',
];

export const useGetIngredients = (productId, option = { enabled: true }) => {
  const queries = useQuery({
    queryKey: queryKeyQaSpecIngredients(productId),
    queryFn: async () => {
      const response = await getQaProductIngredient({ productId });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
      }
    },
    cacheTime: 5 * 60 * 1000, // 30s
    enabled: !!productId && option?.enabled,
    ...option,
  });

  return {
    data: queries?.data ?? [],
    ...queries,
  };
};

export const useMutationIngredients = ({ productId }) => {
  const queryClient = useQueryClient();

  const { handleSyncAllQaDetailGroup } = useInvalidateGroupDetail(productId);

  const mutation = useMutation({
    mutationFn: async (payload) => {
      return saveQaProductIngredient(payload);
    },
    onSuccess: async (response) => {
      await sleep();
      if (response?.isSuccess) {
        handleSyncAllQaDetailGroup();
        queryClient.invalidateQueries({
          queryKey: queryKeyQaSpecIngredients(productId),
        });
        queryClient.invalidateQueries({
          queryKey: getKeyProductIngredients(productId),
        });
        queryClient.invalidateQueries({
          queryKey: getQaPackagingTabDataQueryKey(productId),
        });
      }
    },
  });
  return { mutation };
};

export const queryKeyQaSpecIngredientsSnapshot = (productId) => [
  'pim',
  'snapshot',
  parseInt(productId),
  'qa-spec-ingredients',
];

export const useGetIngredientsSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey: queryKeyQaSpecIngredientsSnapshot(productId),
    queryFn: async () => {
      const response = await getQaIngredientsSnapshot({ productId });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
      }
    },
    cacheTime: 5 * 60 * 1000, // 30s
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    qaIngredientsSnapshot: queries?.data ?? [],
    ...queries,
  };
};
