import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectArticles = (state) => state.articles || initialState;

const selectLoading = () =>
  createSelector(selectArticles, (articlesState) => articlesState.loading);

const selectColumns = () =>
  createSelector(selectArticles, (articlesState) => articlesState.columns);

const selectTotalArticle = () =>
  createSelector(selectArticles, (articlesState) => articlesState.total);

const selectPageSizeArticle = () =>
  createSelector(selectArticles, (articlesState) => articlesState.pageSize);

const selectPageNumberArticle = () =>
  createSelector(selectArticles, (articlesState) => articlesState.pageNumber);

const selectArticleList = () =>
  createSelector(selectArticles, (articlesState) => articlesState.articles);

const selectIsCreateArticle = () =>
  createSelector(selectArticles, (articlesState) => articlesState.isCreate);

const selectIsEditArticle = () =>
  createSelector(selectArticles, (articlesState) => articlesState.isEdit);

const selectIsConfirmEdit = () =>
  createSelector(
    selectArticles,
    (articlesState) => articlesState.isConfirmEdit
  );

const selectStatusUpdateArticle = () =>
  createSelector(selectArticles, (articlesState) => articlesState.statusUpdate);

const selectIdArticle = () =>
  createSelector(selectArticles, (articlesState) => articlesState.idArticle);

export {
  selectArticles,
  selectLoading,
  selectColumns,
  selectTotalArticle,
  selectPageSizeArticle,
  selectPageNumberArticle,
  selectArticleList,
  selectIsCreateArticle,
  selectIsEditArticle,
  selectIsConfirmEdit,
  selectStatusUpdateArticle,
  selectIdArticle,
};
