import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable, useResizeColumns, useBlockLayout } from 'react-table';

import { v4 as uuidv4 } from 'uuid';

import { Input, Button, Space } from 'antd';
import { StyledModal, CustomNotification } from 'common/components';

import * as globalSelectors from 'redux/global/selectors';
import * as globalActions from 'redux/global/actions';

import MdTableEditToolbar from './MdTableEditToolbar';

import { getInputSelection, replaceRange } from 'utils/input';
import { traditionalReplaceAll } from 'utils';

import classnames from 'classnames';

import './MdTableEdit.less';

const CellEdit = {
  view: (props) => <div>{props?.value} </div>,
  edit: (props) => (
    <Input.TextArea
      id={props?.id}
      value={props?.value}
      onChange={props?.onChange}
      onFocus={props?.onFocus}
      autoSize
      className='md-table-edit__textarea'
    />
  ),
};

const CellControl = {
  view: (props) => (
    <Button onClick={props?.onEdit} className='md-table-edit__btn-edit'>
      Edit
    </Button>
  ),
  edit: (props) => (
    <Space>
      <Button type='primary' onClick={props?.onDone}>
        Done
      </Button>

      <Button onClick={props?.onCancel}> Cancel </Button>
    </Space>
  ),
};

const MdTableEdit = (props) => {
  const {
    toggleIsEditTableOpen,
    visible,
    modalTitle,
    //todo - create new
    initTable,
    saveNewTable,
    //todo - edit table
    editData,
    saveEditTable,
    //? entity,
    setInterEntityOpen,
    setEntityOpen,
    tempMdTableData,
    toolList,
  } = props;

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headFillText, setHeadFillText] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [tableDisplayName, setTableDisplayName] = useState('');
  const [isAllowToClickHead, setIsAllowToClickHead] = useState(true);
  const [needToSetTempMdTable, setNeedToSetTempMdTable] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(0);

  const dispatch = useDispatch();

  let tempData = useRef(undefined);
  let selectItem = useRef(undefined);
  let selectedHead = useRef(undefined);

  const mdTableAction = useSelector(globalSelectors.selectMdTableAction());

  const update = () => {
    setForceUpdate((prev) => prev + 1);
  };

  const setTempMdTableData = (data, column) => {
    tempMdTableData.current = { data, column };
  };

  const handleChangeHead = (event) => {
    setHeadFillText({ head: selectedHead?.current, value: event.target.value });
  };

  const handleSaveChangeHead = (event) => {
    if (headFillText?.head) {
      const colInd = headFillText?.head.columnInd;
      setColumns((prevColumns) => {
        let nextColumns = [...prevColumns];
        nextColumns[colInd - 1] = {
          ...nextColumns[colInd - 1],
          Header: headFillText?.value,
        };
        return nextColumns;
      });
    }
    selectedHead.current = null;
    setHeadFillText(null);
    update();
  };

  const handleSelectCell = (row, rowId, cell, cellId) => {
    setSelectedCell({ row, rowId, cell, cellId });
    selectedHead.current = null;
    update();
  };

  const handleFocusTextarea = (event) => {
    setTimeout(() => {
      const $textarea = event.target;
      if ($textarea && $textarea.value !== undefined) {
        $textarea.focus();
        $textarea.setSelectionRange(0, $textarea.value.length);
      }
    }, 250);
  };

  const handleSelectHead = (column, columnInd, event) => {
    if (!isAllowToClickHead) return;
    selectedHead.current = { column, columnInd };
    update();
    handleFocusTextarea(event);
  };

  const setColWidth = (colInd, columnApi, cols) => {
    if (colInd === 0) return;
    const widthPx = columnApi?.getHeaderProps()?.style?.width;
    const width = widthPx ? widthPx.split('px')[0] : null;

    if (width && width !== cols[colInd]?.width) {
      let newColumns = [...columns];
      const newColItem = {
        ...columns[colInd - 1],
        width: width.split('px')[0],
      };
      newColumns[colInd - 1] = newColItem;
      setColumns(newColumns);
    }
  };

  const handleAddRowAbove = () => {
    if (selectedCell) {
      let cloneData = { ...selectedCell?.row.original };
      Object.keys(cloneData).forEach((keyField) => (cloneData[keyField] = ''));
      selectItem.current = null;
      setData((prevData) => {
        let nextData = [...prevData];
        nextData.splice(selectedCell?.rowId, 0, cloneData);
        return nextData;
      });
      setSelectedCell((prevCell) => {
        return {
          ...prevCell,
          rowId: prevCell.rowId + 1,
        };
      });
    }
  };

  const handleAddRowBellow = () => {
    if (selectedCell) {
      let cloneData = { ...selectedCell?.row.original };
      Object.keys(cloneData).forEach((keyField) => (cloneData[keyField] = ''));
      setData((prevData) => {
        let nextData = [...prevData];
        nextData.splice(selectedCell?.rowId + 1, 0, cloneData);
        return nextData;
      });
    }
  };

  const handleDeleteRow = () => {
    if (selectedCell) {
      selectItem.current = null;
      setData((prevData) => {
        let nextData = [...prevData];
        nextData.splice(selectedCell?.rowId, 1);
        return nextData;
      });
    }
  };

  const handleDeleteColumn = () => {
    if (selectedCell) {
      selectItem.current = null;
      setColumns((prevColums) => {
        let nextColumns = [...prevColums];
        nextColumns.splice(selectedCell?.cellId - 1, 1);
        return nextColumns;
      });
    }
  };

  const handleAddColumnToRight = () => {
    if (selectedCell) {
      const newAccessor = `col${uuidv4().toString()}`;
      setColumns((prevColumns) => {
        let nextColumns = [...prevColumns];
        nextColumns.splice(selectedCell?.cellId, 0, {
          Header: `New column`,
          accessor: newAccessor,
        });
        return nextColumns;
      });
    }
  };

  const handleAddColumnToLeft = () => {
    if (selectedCell) {
      setColumns((prevColumns) => {
        let nextColumns = [...prevColumns];
        nextColumns.splice(selectedCell?.cellId - 1, 0, {
          Header: `New column`,
          accessor: `col${uuidv4().toString()}`,
        });
        return nextColumns;
      });
    }
  };

  const insertEntityToTable = (value) => {
    const rowIndex = selectedCell?.cell?.row?.index;
    const columnId = selectedCell?.cell?.column?.id;

    const $textArea = document.getElementById(
      `md-cell-edit-row_${rowIndex}-col_${columnId}`
    );

    if (!selectedCell) return;

    let nextValue = '';

    if (!$textArea) {
      nextValue = value;
    } else {
      const inputSelection = getInputSelection($textArea);

      const replaceText = replaceRange(
        $textArea?.value,
        inputSelection?.start,
        inputSelection?.end,
        value
      );

      nextValue = replaceText;
    }

    setData((prevData) => {
      const newItemData = {
        ...prevData[rowIndex],
        [columnId]: nextValue,
      };
      let newData = [...prevData];
      newData[rowIndex] = newItemData;
      return newData;
    });
    CustomNotification.success('Insert Successfully!');
  };

  const replaceNewInterEntity = (tableActionPayload) => {
    const { original, replacement } = tableActionPayload;
    setData((prevData) => {
      const prevDataStr = JSON.stringify(prevData);
      const replacedData = traditionalReplaceAll(
        prevDataStr,
        original,
        replacement
      );
      return JSON.parse(replacedData);
    });

    setColumns((prevCol) => {
      const prevColStr = JSON.stringify(prevCol);
      const replacedCol = traditionalReplaceAll(
        prevColStr,
        original,
        replacement
      );
      return JSON.parse(replacedCol);
    });

    setNeedToSetTempMdTable(true);
    CustomNotification.success('Change interactive data successfully');
  };

  const handleAddEntityData = (tableAction) => {
    //* 1 - handle insert entity and interact entity
    if (tableAction?.type === 'INSERT') {
      insertEntityToTable(tableAction?.payload);
      return;
    }
    //* 2 - handle edit interact entity data
    if (tableAction?.type === 'REPLACE') {
      replaceNewInterEntity(tableAction?.payload);
    }
  };

  const isInvalidHead = (columnDefine) => {
    return !!columnDefine.find((column) => column?.Header === '');
  };

  //* save table
  const saveTable = () => {
    if (initTable) {
      if (isInvalidHead(columns)) {
        CustomNotification.error('Column Header cannot be empty!');
        return;
      }
      saveNewTable &&
        saveNewTable({
          displayName: tableDisplayName,
          data,
          columns,
        });

      toggleIsEditTableOpen && toggleIsEditTableOpen();
      CustomNotification.success('Create Table Successfully!');
    } else {
      if (isInvalidHead(columns)) {
        CustomNotification.error('Column Header cannot be empty!');
        return;
      }
      saveEditTable &&
        saveEditTable({
          name: editData?.name,
          displayName: tableDisplayName,
          data,
          columns,
        });
      toggleIsEditTableOpen && toggleIsEditTableOpen();
      CustomNotification.success('Edit Table Successfully!');
    }
  };

  //* create a new table from initTable
  const createNewTable = useCallback((initTable) => {
    const colNo = initTable[0];
    const rowNo = initTable[1];
    const createdColumn = Array.from(Array(colNo)).map((item, idx) => ({
      // Header: `Column ${idx + 1}`,
      Header: `New Column`,
      accessor: `col${idx + 1}`,
      width: 150,
    }));

    let initRowData = {};
    createdColumn.forEach((col, idx) => {
      initRowData[col.accessor] = '';
    });

    const createdData = Array.from(Array(rowNo)).map(() => initRowData);
    setColumns(createdColumn);
    setData(createdData);
    setTableDisplayName('');
  }, []);

  const createEditTableData = useCallback((editData) => {
    setColumns(editData?.columns);
    setData(editData?.data);
    setTableDisplayName(editData?.displayName || '');
  }, []);

  const handleChangeDisplayName = (value) => {
    setTableDisplayName(value);
  };

  const mapToResizerProps = (column) => {
    let resizerProps = column.getResizerProps();
    let newResizerProps = { ...resizerProps };

    newResizerProps.onMouseDown = (event) => {
      isAllowToClickHead && setIsAllowToClickHead(false);
      resizerProps?.onMouseDown && resizerProps.onMouseDown(event);
    };
    newResizerProps.onTouchStart = (event) => {
      isAllowToClickHead && setIsAllowToClickHead(false);
      resizerProps?.onMouseUp && resizerProps.onTouchStart(event);
    };

    return newResizerProps;
  };

  const onMouseUpHead = () => {
    setTimeout(() => {
      !isAllowToClickHead && setIsAllowToClickHead(true);
    }, 300);
  };

  const updatePlaceholderListAtEntityTool = () => {
    setNeedToSetTempMdTable(false);
    dispatch(globalActions.toggleIsGettingPlaceholderList(true));
  };

  const resetComponent = () => {
    selectItem.current = null;
    setTempMdTableData({});
  };

  useEffect(() => {
    setTempMdTableData(data, columns);
  }, [data, columns]);

  useEffect(() => {
    handleAddEntityData(mdTableAction);
  }, [mdTableAction]);

  useEffect(() => {
    if (!editData) return;
    createEditTableData(editData);
  }, [editData, createEditTableData]);

  useEffect(() => {
    if (!initTable) return;
    createNewTable(initTable);
  }, [initTable, createNewTable]);

  useEffect(() => {
    if (needToSetTempMdTable) {
      updatePlaceholderListAtEntityTool();
    }
  }, [needToSetTempMdTable]);

  useEffect(() => {
    return () => resetComponent();
  }, []);

  const mapColumToProcColumn = useCallback(() => {
    if (columns.length <= 0) return [];
    return [
      {
        Header: 'Control',
        accessor: 'ColControl',
        Cell: (instance) => {
          return selectItem.current === instance.cell.row.index ? (
            <CellControl.edit
              onCancel={() => {
                selectItem.current = null;
                tempData.current !== null && setData(tempData.current);
                tempData.current = null;
                update();
              }}
              onDone={() => {
                selectItem.current = null;
                tempData.current = null;
                update();
              }}
            />
          ) : (
            <CellControl.view
              onEdit={() => {
                selectItem.current = instance.cell.row.index;
                tempData.current = instance.data;
                update();
              }}
            />
          );
        },
      },
      ...columns.map((columnItem) => ({
        ...columnItem,
        Cell: (instance) => {
          console.log('select ---', selectItem.current);
          return selectItem.current === instance.cell.row.index ? (
            <CellEdit.edit
              id={`md-cell-edit-row_${selectItem.current}-col_${instance.column.id}`}
              value={instance.cell.value}
              onChange={(event) => {
                const value = event.target.value;
                setData((prevData) => {
                  const newItemData = {
                    ...prevData[selectItem.current],
                    [instance.column.id]: value,
                  };
                  let newData = [...prevData];
                  newData[selectItem.current] = newItemData;
                  return newData;
                });
              }}
              onFocus={(event) => {
                console.log('event', event);
              }}
            />
          ) : (
            <CellEdit.view value={instance.cell.value} />
          );
        },
      })),
    ];
  }, [columns]);

  const procColumns = useMemo(mapColumToProcColumn, [columns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: procColumns, data }, useResizeColumns, useBlockLayout);

  return (
    <React.Fragment>
      {visible && (
        <StyledModal
          className='md-table-edit__modal'
          title={modalTitle || ''}
          visible={visible}
          onOk={() => saveTable()}
          onCancel={() => {
            toggleIsEditTableOpen && toggleIsEditTableOpen();
          }}
          cancelButtonProps={{ style: { color: 'white' } }}
          okText={'OK'}
          cancelText={'Cancel'}
          maskClosable={false}
          width={1000}
        >
          <MdTableEditToolbar
            handleAddRowBellow={handleAddRowBellow}
            handleAddRowAbove={handleAddRowAbove}
            handleAddColumnToLeft={handleAddColumnToLeft}
            handleAddColumnToRight={handleAddColumnToRight}
            handleDeleteRow={handleDeleteRow}
            handleDeleteColumn={handleDeleteColumn}
            tableDisplayName={tableDisplayName}
            handleChangeDisplayName={handleChangeDisplayName}
            setInterEntityOpen={setInterEntityOpen}
            setEntityOpen={setEntityOpen}
            toolList={toolList}
          />
          <div className='md-table-edit__wrapper scroller'>
            <div className='react-table-edit md-table-edit'>
              <div {...getTableProps()} className='table'>
                <div className='tr__wrapper'>
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className='tr'>
                      {headerGroup.headers.map((column, idx) => {
                        !column.isResizing &&
                          setColWidth(idx, column, procColumns);

                        return (
                          <div
                            {...column.getHeaderProps()}
                            className='th'
                            onMouseUp={onMouseUpHead}
                            onClick={(event) =>
                              handleSelectHead(column, idx, event)
                            }
                          >
                            {selectedHead.current?.columnInd === idx &&
                            idx > 0 ? (
                              <Input.TextArea
                                className='md-table-edit__textarea'
                                value={
                                  headFillText?.head
                                    ? headFillText?.value
                                    : column?.Header
                                }
                                onChange={handleChangeHead}
                                onBlur={handleSaveChangeHead}
                                autoSize
                              />
                            ) : (
                              column.render('Header')
                            )}
                            <div
                              {...mapToResizerProps(column)}
                              className={classnames('resizer', {
                                isResizing: column.isResizing,
                              })}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>

                <div {...getTableBodyProps()} className='tb'>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className='tr tr--select'>
                        {row.cells.map((cell, colId) => {
                          return (
                            <div
                              {...cell.getCellProps()}
                              className={classnames('td', {
                                'td--selected':
                                  selectedCell?.rowId === i &&
                                  selectedCell?.cellId === colId &&
                                  selectedCell?.cellId > 0,
                              })}
                              onClick={() => {
                                handleSelectCell(row, i, cell, colId);
                              }}
                            >
                              {cell.render('Cell')}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </StyledModal>
      )}
    </React.Fragment>
  );
};

export default MdTableEdit;
