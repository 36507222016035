import IX_One_Logo2285 from 'assets/IX_One_Logo2285.png';
import { RibbonButton } from 'common/components';

const IXOneSection = ({ width, ...otherProps }) => {
  return (
    <RibbonButton
      icon={
        <img
          src={IX_One_Logo2285}
          alt='IX-ONE Capture'
          style={{ width, background: '#ffffff' }}
        />
      }
      {...otherProps}
    />
  );
};

export default IXOneSection;
