import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import * as constant from 'static/Constants';
import './ContentPane.less';

const ContentPane = (props) => {
  const { visible, contentDrawer, onClose, title, footer, ...res } = props;
  const widthDrawer = props.widthDrawer
    ? props.widthDrawer
    : constant.WIDTH_CONTANT_PANE;
  const checkWidth = !visible ? '0%' : widthDrawer;
  return (
    <Drawer
      title={title}
      placement='right'
      closable={false}
      onClose={onClose}
      visible={visible}
      getContainer={false}
      style={{
        position: 'absolute',
        width: checkWidth,
      }}
      width={widthDrawer}
      footer={footer}
      mask={false}
      {...res}
    >
      {contentDrawer}
    </Drawer>
  );
};
ContentPane.propTypes = {
  title: PropTypes.node,
  footer: PropTypes.node,
  visible: PropTypes.bool.isRequired,
  contentDrawer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ContentPane;
