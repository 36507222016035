import React from 'react';

import { InfoWithLabel } from 'common/components';

const UserContactInfo = (props) => {
  const { className, contactInfo, style } = props;
  const infoColProps = {
    className,
    infoColProps: {
      flex: 'auto',
    },
    labelColProps: {
      flex: '60px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };
  return (
    contactInfo &&
    !!contactInfo.length &&
    contactInfo.map((item, index) => {
      const { info, label } = item;
      return (
        <InfoWithLabel
          info={info}
          label={label}
          {...infoColProps}
          infoTextStyle={style}
          key={index}
        />
      );
    })
  );
};

export default UserContactInfo;
