import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Space, Typography } from 'antd';
import classnames from 'classnames';

import { useCheckPermissionOR } from 'hooks/useCheckPermissions';
import { SECURE_CONFIG } from 'utils/SecureRoute';

import './HeaderFilter.less';

const { Text } = Typography;

const HeaderFilter = (props) => {
  const { onChangeFilter, showFilter, filter } = props;
  const checkPermission = useCheckPermissionOR();

  const canChatPersonal = checkPermission(SECURE_CONFIG.CHAT_PERSONAL.PASS);
  const canChatCompany = checkPermission(SECURE_CONFIG.CHAT_COMPANY.PASS);

  const handleClickFilter = (filterType) => {
    if (!filterType) return;
    const searchIdx = filter.findIndex(
      (filterName) => filterName === filterType
    );
    let temp = [...filter];

    if (searchIdx === -1) {
      temp = [...temp, filterType];
      onChangeFilter(temp);
      return;
    }

    temp.splice(searchIdx, 1);
    onChangeFilter(temp);
  };

  const isActive = (type) => {
    return filter?.find((item) => item === type) ? 'primary' : '';
  };

  return canChatCompany && canChatPersonal ? (
    <Row
      className={classnames({
        'header-filter': true,
        'header-filter--show': showFilter,
      })}
    >
      <Col xs={24}>
        <Space>
          <Button
            className='header-filter__btn'
            type='primary'
            onClick={() => handleClickFilter('member')}
            danger={isActive('member')}
          >
            Member
          </Button>
          <Button
            className='header-filter__btn'
            type='primary'
            onClick={() => handleClickFilter('user')}
            danger={isActive('user')}
          >
            Personal
          </Button>
        </Space>
      </Col>
    </Row>
  ) : null;
};

export default HeaderFilter;
