import React from 'react';

import { Row, Col, Space } from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const MinimumRequirementsRow = ({
  status,
  cols = [15, 9],
  children,
  errorComponent,
  item,
  entityData,
  productDetail,
  updateFormValue,
  hasBrickCode,
}) => {
  const ErrorComponent = errorComponent;

  const checkStatusComponents = {
    loading: (
      <LoadingOutlined className='minimum-requirement__row-icon minimum-requirement__row-icon--blue' />
    ),
    success: (
      <CheckOutlined className='minimum-requirement__row-icon minimum-requirement__row-icon--green' />
    ),
    error: (
      <CloseOutlined className='minimum-requirement__row-icon minimum-requirement__row-icon--red' />
    ),
  };

  return (
    <div className='minimum-requirement__row'>
      <Row align='middle' gutter={[8, 0]}>
        <Col span={cols[0]}>{children}</Col>
        <Col span={cols[1]}>
          <Space style={{ alignItems: 'center' }}>
            {status && checkStatusComponents[status]
              ? checkStatusComponents[status]
              : null}
            {status === 'error' && errorComponent ? (
              <ErrorComponent
                item={item}
                entityData={entityData}
                productDetail={productDetail}
                updateFormValue={updateFormValue}
                hasBrickCode={hasBrickCode}
              />
            ) : null}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default MinimumRequirementsRow;
