import React from 'react';

import { Space, Button, Input, Row, Col, Typography } from 'antd';

import {
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  InsertRowRightOutlined,
  InsertRowLeftOutlined,
} from '@ant-design/icons';

import './MdTableEditToolbar.less';

const { Text } = Typography;

const MdTableEditToolbar = (props) => {
  const {
    handleAddRowBellow,
    handleAddRowAbove,
    handleAddColumnToLeft,
    handleAddColumnToRight,
    handleDeleteRow,
    handleDeleteColumn,
    tableDisplayName,
    handleChangeDisplayName,
    setInterEntityOpen,
    setEntityOpen,
    toolList,
  } = props;

  const onChangeTableDisplayName = (event) => {
    handleChangeDisplayName && handleChangeDisplayName(event.target.value);
  };

  return (
    <div className='md-table-edit-toolbar'>
      <Row>
        <Col xs={24}>
          <Space style={{ display: 'flex' }}>
            <Button
              className='md-table-edit-toolbar__btn'
              onClick={() => handleAddRowBellow()}
            >
              <InsertRowBelowOutlined /> Add row bellow
            </Button>
            <Button
              className='md-table-edit-toolbar__btn'
              onClick={() => handleAddRowAbove()}
            >
              <InsertRowAboveOutlined /> Add row above
            </Button>
            <Button
              className='md-table-edit-toolbar__btn'
              onClick={() => handleAddColumnToLeft()}
            >
              <InsertRowLeftOutlined /> Add column to left
            </Button>
            <Button
              className='md-table-edit-toolbar__btn'
              onClick={() => handleAddColumnToRight()}
            >
              <InsertRowRightOutlined /> Add column to right
            </Button>
            {toolList.includes('entity') && (
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Button
                  className='md-table-edit-toolbar__btn'
                  onClick={() => setEntityOpen((prev) => !prev)}
                  style={{ marginRight: '8px' }}
                >
                  {`[[E]] Entity Data`}
                </Button>
                <Button
                  className='md-table-edit-toolbar__btn'
                  onClick={() => setInterEntityOpen((prev) => !prev)}
                >
                  {`[@[E]] Interact Entity Data`}
                </Button>
              </div>
            )}
          </Space>
        </Col>
      </Row>
      <Row className='md-table-edit-toolbar__bottom' align='middle'>
        <Col className='md-table-edit-toolbar__group' flex='auto'>
          <Row>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 7px',
                fontWeight: 600,
              }}
            >
              <Text>Edit Table Name</Text>
            </Col>
            <Col flex='auto'>
              <Input
                value={tableDisplayName}
                onChange={onChangeTableDisplayName}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Space>
            <Button
              type='primary'
              danger
              className='md-table-edit-toolbar__btn'
              onClick={() => handleDeleteRow()}
            >
              Delete Row
            </Button>
            <Button
              type='primary'
              danger
              className='md-table-edit-toolbar__btn'
              onClick={() => handleDeleteColumn()}
            >
              Delete Column
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default MdTableEditToolbar;
