import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import ReactDOM from 'react-dom';

import * as _ from 'lodash';
import './DragWrap.less';

const { Text } = Typography;

const DrapWrap = (props) => {
  const { children, dragListData, dragData, dragNamePath, icon, style } = props;

  const refShadow = useRef();

  const MultiDragRender = (
    <div className='drag-wrap__shadow-list'>
      <div className='drag-wrap__shadow-inner'>
        {dragListData?.length > 0 &&
          dragListData.map((item, idx) => {
            if (idx > 4) return null;
            if (idx === 4)
              return (
                <Text className='drag-wrap__shadow-title' key={idx}>
                  and {dragListData.length - 4} more items
                </Text>
              );

            return (
              <div className='drag-wrap__shadow-item' key={idx}>
                {icon && icon}
                <Text className='drag-wrap__shadow-title' ellipsis>
                  {item?.[dragNamePath] || 'N/A'}
                </Text>
              </div>
            );
          })}
        {dragListData?.length === 0 && (
          <div className='drag-wrap__shadow-item'>
            {icon && icon}
            <Text className='drag-wrap__shadow-title' ellipsis>
              {dragData?.[dragNamePath] || 'N/A'}
            </Text>
          </div>
        )}
        <Text className='drag-wrap__shadow-count'>
          {dragListData?.length || 1}
        </Text>
      </div>
    </div>
  );

  const createDragImage = (e) => {
    refShadow.current = document.createElement('div');
    refShadow.current.classList.add('drag-wrap__shadow-ele');
    document.body.appendChild(refShadow.current);
    ReactDOM.render(MultiDragRender, refShadow.current);
    return refShadow.current;
  };

  const removeShadow = () => {
    document.body.removeChild(refShadow.current);
  };

  const onDragStart = (e) => {
    const parsedData = JSON.stringify(
      dragListData?.length > 0 ? dragListData : [dragData]
    );
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('dragData', parsedData);

    const dragImage = createDragImage(e);
    e.dataTransfer.setDragImage(dragImage, 0, 20);
  };

  const onDragEnd = (e) => {
    removeShadow();
  };

  const isDraggable = () => {
    if (dragListData?.length === 0 && dragData) return 'true';
    if (dragListData?.length > 0) {
      if (
        dragListData.findIndex((itemData) => _.isEqual(itemData, dragData)) >= 0
      )
        return 'true';
    }
    return 'false';
  };

  return (
    <div
      draggable={isDraggable()}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      style={{ ...(style || {}), cursor: 'pointer' }}
    >
      {children}
    </div>
  );
};

DrapWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  dragListData: PropTypes.array,
  dragData: PropTypes.any,
  dragNamePath: PropTypes.string,
  icon: PropTypes.any,
  dragIconPath: PropTypes.string,
  style: PropTypes.object,
};

export default DrapWrap;
