import React from 'react';
import { Drawer } from 'antd';

const PageEditorForm = (props) => {
  const { formComponent, isOpen, handleCloseForm, formInstance } = props;

  return (
    <Drawer
      title={'Page Editor Form '}
      placement='right'
      closable={false}
      onClose={handleCloseForm}
      visible={isOpen}
      getContainer={false}
      style={{ position: 'absolute' }}
      extra={formComponent?.extra(formInstance)}
      width={formComponent?.width}
    >
      {formComponent?.render && formComponent?.render(formInstance, isOpen)}
    </Drawer>
  );
};

export default PageEditorForm;
