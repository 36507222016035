import React, { useState } from 'react';
import { Input, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import useCustomScroll from 'hooks/useCustomScroll';

import './TextAreaGrid.less';
const { TextArea } = Input;
const { Text } = Typography;

const TextAreaGrid = (props) => {
  const [isMouseInside, setMouseInside] = useState(false);
  const { title, content, minRows, maxRows, underlineTitle } = props;

  return (
    <Row
      className={`text-area-grid ${isMouseInside && 'text-area-grid--scroll'}`}
      onMouseEnter={() => setMouseInside(true)}
      onMouseLeave={() => setMouseInside(false)}
      style={{ width: '100%' }}
    >
      <Text className='text-area-grid__title' underline={underlineTitle}>
        {title}
      </Text>
      <TextArea
        autoSize={{ minRows, maxRows }}
        disabled
        value={content}
      ></TextArea>
    </Row>
  );
};

TextAreaGrid.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  underlineTitle: PropTypes.bool,
};

TextAreaGrid.defaultProps = {
  minRows: 4,
  maxRows: 4,
  underlineTitle: false,
};

export default TextAreaGrid;
