import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row, Select, Tooltip, Spin } from 'antd';

import { CustomNotification, Form, WrapperSelect } from 'common/components';
import CreateSupplierModal from './CreateSupplierModal';
import { useGetMemberSupplier } from 'hooks/useGetMemberSupplier';
import * as ssoProductSelectors from 'pages/sso-product/controllers/selectors';

import sortByKey from 'utils/sortByKey';

import './CreateSupplier.less';

const CreateSupplier = ({ isCreateProductModal = false, rivirSupplierId }) => {
  const form = Form.useFormInstance();

  const { newSupplier } =
    useSelector(ssoProductSelectors.selectBpSsoProductData()) ?? {};

  const { memberSupplier, isLoading } = useGetMemberSupplier();

  const [visible, setVisible] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    if (memberSupplier?.length) {
      setSupplierList(memberSupplier);

      if (rivirSupplierId) {
        const hasSupplierId = memberSupplier?.some(
          // compare value ignoring value type(string or number)
          (item) => item?.supplierId == rivirSupplierId
        );

        if (hasSupplierId)
          form.setFieldsValue({
            ...form.getFieldsValue(),
            supplierId: +rivirSupplierId,
          });
      }
    }
    if (newSupplier === '1' && !isCreateProductModal) setVisible(true);
  }, [JSON.stringify(memberSupplier)]);

  const sortBySupplierName = sortByKey(supplierList, 'supplierName');

  return (
    <div className='create_supplier' id={'create-supplier'}>
      <Form.Item
        label={'Supplier'}
        colon={false}
        labelAlign='right'
        labelCol={{ span: 4 }}
        wrapperCol={{
          span: 20,
        }}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues?.isPrivateLabel !== currentValues?.isPrivateLabel
        }
      >
        {({ getFieldValue }) => {
          const isPrivateLabel = getFieldValue('isPrivateLabel');
          return (
            <Row>
              <Col className='create_supplier-wrapper'>
                <Form.Item name='supplierId' style={{ marginBottom: 0 }}>
                  {isLoading ? (
                    <Spin size='small' />
                  ) : (
                    <WrapperSelect
                      className='create_supplier-select'
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={() =>
                        document.getElementById('classification-field')
                      }
                      disabled={!isPrivateLabel}
                    >
                      {sortBySupplierName?.map((item) => {
                        const value = item?.supplierId;
                        return (
                          <Select.Option key={value} value={value}>
                            {item?.supplierName}
                          </Select.Option>
                        );
                      })}
                    </WrapperSelect>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Tooltip title='Add new supplier'>
                  <Button
                    className='create_supplier-button'
                    type='primary'
                    onClick={() => {
                      setVisible(true);
                    }}
                    disabled={!isPrivateLabel}
                  >
                    Add new supplier
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          );
        }}
      </Form.Item>

      <CreateSupplierModal
        visible={visible}
        setVisible={setVisible}
        setSupplierList={setSupplierList}
      />
    </div>
  );
};

export default CreateSupplier;
