import React from 'react';

import { Button } from 'antd';

const SupplementFormButton = (props) => {
  const { text, color, ...restProps } = props;

  return (
    <Button
      style={{
        width: '100%',
        borderColor: color,
        color: color,
        height: 35,
      }}
      {...restProps}
    >
      {text}
    </Button>
  );
};

export default SupplementFormButton;
