import React from 'react';
import PropTypes from 'prop-types';
import { EnhancedMarkdownEditor } from 'common/components';
import { useMdInstance } from 'hooks/useMdInstance';

const markdownContentId = 'user-bio-markdown-content';

const EditUserBio = (props) => {
  const { content, handleChangeContent } = props;
  const [mdInstance] = useMdInstance(null);

  return (
    <EnhancedMarkdownEditor
      mdInstance={mdInstance}
      markdownContentId={markdownContentId}
      value={content}
      onChangeContent={handleChangeContent}
      toolList={['text']}
    />
  );
};

EditUserBio.propTypes = {
  content: PropTypes.string,
  handleChangeContent: PropTypes.func,
};

export default EditUserBio;
