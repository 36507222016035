import React, { useState } from 'react';

import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import { RibbonButton, CustomNotification } from 'common/components';

import { downloadAcceptedFields } from 'services/maintenanceForm';

const AcceptedFieldListing = () => {
  const [statusDownload, setStatusDownload] = useState('idle');

  const handleDownloadAcceptedFields = async () => {
    try {
      setStatusDownload('loading');
      const response = await downloadAcceptedFields();
      if (response?.isSuccess) {
        setStatusDownload('success');
        CustomNotification.success('Download accepted fields successfully!');
      } else {
        setStatusDownload('error');
        CustomNotification.error(response?.message ?? 'Something went wrong!');
      }
    } catch (error) {
      setStatusDownload('error');
      CustomNotification.error('Something went wrong!');
    }
  };

  const isDownloading = statusDownload === 'loading';

  return (
    <RibbonButton
      icon={isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
      label='Accepted Fields'
      onClick={handleDownloadAcceptedFields}
    />
  );
};

export default AcceptedFieldListing;
