import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as userProfileService from 'services/user';
import { CustomNotification } from 'common/components';
import { FormDeleteButton } from 'common/components';
import selectorUser from 'redux/user/selectors';
import * as userActions from 'redux/user/actions';

import {
  Row,
  Col,
  Radio,
  Select,
  Button,
  Tooltip,
  Typography,
  Divider,
  Space,
} from 'antd';

// Constants
import {
  RESOLUTION_OPTIONS,
  CROP_SIZE_OPTIONS,
  IMAGE_SIZE_OPTIONS,
  BACKGROUND_OPTIONS,
  FORMAT_TYPE,
} from 'static/Constants';
import { IMAGE_TYPES_PREVIEW } from 'static/FileType';

import {
  Form,
  WrapperSelect,
  TypePicker,
  ColorPicker,
} from 'common/components';
import { useIntl } from 'react-intl';

// redux
import userSelectors from 'redux/user/selectors';
import { useParams } from 'react-router-dom';
import { useUpdateUserInfo } from 'pages/user-profile/components/hook/useUpdateUserInfo';

import {
  getAssetDownloadTypes,
  getCategoryOfFormatDownload,
  renderResolutionNameByNumber,
} from './utils';

import './AssetDownloadPreferencesForm.less';

const { Option } = Select;
const { Title } = Typography;
const formItemFormatLayout = { labelCol: { span: 24 } };
const formItemConfigLayout = {
  labelCol: { xxl: { span: 8 }, xl: { span: 9 } },
  wrapperCol: { xxl: { span: 15 }, xl: { span: 13 } },
};

const customSetupStyle = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  padding: 8,
};

const AssetDownloadPreferencesForm = (props) => {
  const { closeModal } = props;

  const [form] = Form.useForm();
  const intl = useIntl();

  const [downloadFormat, setDownloadFormat] = useState(IMAGE_TYPES_PREVIEW);
  const [backgroundOptions, setBackground] = useState(BACKGROUND_OPTIONS);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isDisabledImageConfig, setIsDisableImageConfig] = useState(false);

  const userId = useSelector(userSelectors.makeSelectUserId());
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const userProfileHeader = useSelector(
    selectorUser.makeSelectUserProfileHeader()
  );

  const { id } = useParams();
  const dispatch = useDispatch();
  const { updateUserInfo } = useUpdateUserInfo();

  const imageSizeCustomRender = (value) => {
    if (IMAGE_SIZE_OPTIONS.includes(value)) return value;
    else return `${value}x${value}`;
  };

  const addBackgroundItem = (color) => {
    if (!backgroundOptions.includes(color)) {
      setBackground([...backgroundOptions, color]);
    }
  };

  const handleSubmit = () => {
    const { categoryFormat, id: idForm, ...formData } = form.getFieldValue();

    const data = {
      userId: id ?? userId,
      ...formData,
    };
    saveAssetDownloadedPreference(data);
  };

  const saveAssetDownloadedPreference = async (params) => {
    setLoadingButton(true);
    try {
      const data = await userProfileService.saveAssetDownloadPreferences(
        params
      );
      if (data.isSuccess) {
        CustomNotification.success(
          'Save Asset Download Preferences successfully'
        );
        id ? dispatch(userActions.getUserProfileHeader(id)) : updateUserInfo();
      } else {
        CustomNotification.error('Cannot save Asset Download Preferences');
      }
    } catch (error) {
      CustomNotification.error('Cannot save Asset Download Preferences');
    } finally {
      setLoadingButton(false);
      closeModal(false);
    }
  };

  const handleChangeFormatDownload = (e) => {
    const downloadType = e.target.value;
    if (!downloadType) return;
    const assetDownloadTypes = getAssetDownloadTypes();
    const isDisabledImageConfig = FORMAT_TYPE.IMAGE_TYPES !== downloadType;
    setIsDisableImageConfig(isDisabledImageConfig);
    if (isDisabledImageConfig) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        background: null,
        resolution: null,
        cropSize: null,
        imageSize: null,
      });
    }
    if (assetDownloadTypes) setDownloadFormat(assetDownloadTypes[downloadType]);
  };

  const handleRemoveAssetDownload = () => {
    const { categoryFormat, id, ...formData } = form.getFieldValue();
    form.resetFields(Object.keys(formData));
  };

  useEffect(() => {
    if (!userInfo && !userProfileHeader) return;

    const assetDownloadPreference = id
      ? userProfileHeader?.assetDownloadPreference
      : userInfo?.assetDownloadPreference;
    const assetDownloadTypes = getAssetDownloadTypes();
    const downloadFormat = assetDownloadPreference?.downloadFormat;
    const categoryFormat = getCategoryOfFormatDownload(downloadFormat);

    const downloadFormatData =
      assetDownloadTypes[categoryFormat] ?? IMAGE_TYPES_PREVIEW;
    setDownloadFormat(downloadFormatData);

    form.setFieldsValue({
      ...assetDownloadPreference,
      categoryFormat: categoryFormat ?? FORMAT_TYPE.IMAGE_TYPES,
      resolution: assetDownloadPreference?.resolution ?? null,
      cropSize: assetDownloadPreference?.cropSize ?? null,
    });
  }, [userInfo, form]);

  return (
    <>
      <Form form={form} name='asset-download-form' className='asset-download'>
        <Row className='asset-download__format'>
          <Col span={24}>
            <Title level={5}>
              {intl.formatMessage({
                id: `Taco.digitalAsset.assetDownloadPreferences.step1`,
              })}
            </Title>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemFormatLayout} name='namingFormat'>
              <Radio.Group>
                <Radio value={'Compliant'}>Compliant</Radio>
                <Radio value={'Plano'}>Plano</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row className='asset-download__format'>
          <Col span={24}>
            <Title level={5}>
              {intl.formatMessage({
                id: `Taco.digitalAsset.assetDownloadPreferences.step2`,
              })}
            </Title>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemFormatLayout} name='categoryFormat'>
              <Radio.Group onChange={handleChangeFormatDownload}>
                <Radio value={FORMAT_TYPE.IMAGE_TYPES}>Image</Radio>
                <Radio value={FORMAT_TYPE.VIDEOS_TYPES}>Video</Radio>
                <Radio value={FORMAT_TYPE.FILE}>File</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemFormatLayout} name='downloadFormat'>
              <Radio.Group style={{ display: 'flex', flexWrap: 'wrap' }}>
                {downloadFormat?.length > 0 &&
                  [...downloadFormat]?.map((option) => {
                    return (
                      <Tooltip title={option.toUpperCase()} key={option}>
                        <Radio.Button
                          className='asset-download__format-type asset-download__format-type--radio'
                          value={option?.toLowerCase()}
                        >
                          <TypePicker group='file-type' type={option} />
                        </Radio.Button>
                      </Tooltip>
                    );
                  })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row className='asset-download__config'>
          <Col span={24} style={{ marginBottom: 5 }}>
            <Title level={5}>
              {intl.formatMessage({
                id: `Taco.digitalAsset.assetDownloadPreferences.step3`,
              })}
            </Title>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemConfigLayout}
              name='resolution'
              label={intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.resolution`,
              })}
            >
              <WrapperSelect
                style={{ minWidth: '160px' }}
                disabled={isDisabledImageConfig}
              >
                {RESOLUTION_OPTIONS.map((option, index) => {
                  return (
                    <Option key={index} value={option}>
                      <span style={{ fontSize: '12px' }}>
                        {option === null
                          ? 'Original'
                          : option + ' ' + renderResolutionNameByNumber(option)}
                      </span>
                    </Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemConfigLayout}
              name='cropSize'
              label={intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.cropSize`,
              })}
            >
              {/* crop size input */}
              <WrapperSelect disabled={isDisabledImageConfig}>
                {CROP_SIZE_OPTIONS.map((option, index) => {
                  return (
                    <Option key={index} value={option}>
                      <span>
                        {option || option === 0
                          ? `${option} Pixels`
                          : 'No Crop'}
                      </span>
                    </Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemConfigLayout}
              name='imageSize'
              label={intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.imageSize`,
              })}
            >
              <WrapperSelect
                style={{ minWidth: '160px' }}
                disabled={isDisabledImageConfig}
              >
                {IMAGE_SIZE_OPTIONS.map((option, index) => {
                  return (
                    <Option key={index} value={option}>
                      {imageSizeCustomRender(option)}
                    </Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemConfigLayout}
              name='background'
              label={intl.formatMessage({
                id: `Taco.digitalAsset.assetDownload.background`,
              })}
            >
              <WrapperSelect
                disabled={isDisabledImageConfig}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={customSetupStyle}>
                      <ColorPicker
                        initialColor='#000'
                        callbackFunc={addBackgroundItem}
                      />
                    </div>
                  </div>
                )}
              >
                {backgroundOptions.map((option, index) => {
                  return (
                    <Option key={index} value={option}>
                      {option}
                    </Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
          </Col>
        </Row>

        <Row className='asset-download__format'>
          <Col span={24}>
            <Title level={5}>
              {intl.formatMessage({
                id: `Taco.digitalAsset.assetDownloadPreferences.step4`,
              })}
            </Title>
          </Col>
          <Col span={24} id='download-method'>
            <Form.Item {...formItemFormatLayout} name='downloadMethod'>
              <Radio.Group style={{ display: 'flex' }}>
                <Tooltip
                  title={intl.formatMessage({
                    id: `Taco.digitalAsset.assetDownload.downloadMethod.direct`,
                  })}
                >
                  <div>
                    <Radio.Button
                      className='asset-download-preferences__format-type asset-download__format-type'
                      htmlType='submit'
                      value='direct'
                    >
                      <TypePicker group='download-method' type='direct' />
                    </Radio.Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={intl.formatMessage({
                    id: `Taco.digitalAsset.assetDownload.downloadMethod.email`,
                  })}
                >
                  <div>
                    <Radio.Button
                      className='asset-download-preferences__format-type asset-download__format-type'
                      value='email'
                    >
                      <TypePicker group='download-method' type='email' />
                    </Radio.Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={intl.formatMessage({
                    id: `Taco.digitalAsset.assetDownload.downloadMethod.ftp`,
                  })}
                >
                  <div>
                    <Radio.Button
                      className='asset-download__format-type asset-download-preferences__format-type'
                      htmlType='submit'
                      value='ftp'
                    >
                      <TypePicker group='download-method' type='ftp' />
                    </Radio.Button>
                  </div>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        <Row className='change-password-form__footer asset-download-preferences__btn-clear'>
          <Space>
            <FormDeleteButton
              text='Clear'
              onClick={handleRemoveAssetDownload}
              style={{ borderRadius: 0 }}
            />
            <Button
              type='primary'
              htmlType='submit'
              loading={loadingButton}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button onClick={() => closeModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};

export default AssetDownloadPreferencesForm;
