import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import messages from 'i18n/messages/home';

import { Dropdown, Menu } from 'antd';

import RibbonButton from 'common/components/button/RibbonButton';
import { ReactComponent as IconShared } from 'assets/shared-ribbon/Share.svg';

import EmailControl from '../../controls/shared/EmailControl';
import SendToFTP from '../../controls/shared/SendToFTP';
import SendToMessage from '../../controls/shared/SendToMessage';
import { ModalShare } from 'common/components';
import {
  checkDisabledShareButton,
  checkDisabledShareButtonForAPL,
} from './utils';
import Messages from 'i18n/messages/home';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import * as memberActions from 'pages/company-profile/controllers/actions';

import * as companySelectors from 'pages/company-profile/controllers/selectors';
import userSelectors from 'redux/user/selectors';
import './styles.less';

const ShareControl = (props) => {
  const { detailCurrentItemsSelection, type } = props;

  const dispatch = useDispatch();
  const visibleModalHook = useState(false);
  const [visibleModal, setVisibleModel] = visibleModalHook;

  const memberInfo = useSelector(companySelectors.selectMemberProfile());
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());

  const confirmShare = () => {
    setVisibleModel(true);
  };

  const isAPL = type === 'APL';

  const disabledShareButton = isAPL
    ? checkDisabledShareButtonForAPL(detailCurrentItemsSelection)
    : checkDisabledShareButton(detailCurrentItemsSelection);

  useEffect(() => {
    if (!memberInfo) dispatch(memberActions.getMemberProfileHeader(memberId));
  }, [JSON.stringify(memberInfo), memberId, dispatch]);

  const menuOptions = (
    <Menu>
      <Menu.Item key='share' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<IconShared />}
          label={Messages.share}
          onClick={confirmShare}
          className='button-item-dropdown'
        />
      </Menu.Item>
      <Menu.Item key='email' style={{ backgroundColor: 'inherit' }}>
        <EmailControl disabled />
      </Menu.Item>
      <Menu.Item key='sendToFTP' style={{ backgroundColor: 'inherit' }}>
        <SendToFTP disabled />
      </Menu.Item>
      <Menu.Item key='sendToMessage' style={{ backgroundColor: 'inherit' }}>
        <SendToMessage disabled />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuOptions} arrow disabled={disabledShareButton}>
        <RibbonButton
          icon={<IconShared style={{ height: 26 }} />}
          label={messages.share}
          // onClick={confirmShare}
        />
      </Dropdown>

      {visibleModal && (
        <ModalShare
          visibleHook={visibleModalHook}
          detailCurrentItemsSelection={detailCurrentItemsSelection}
          disabledEditableOption={isAPL}
        />
      )}
    </>
  );
};

ShareControl.propTypes = {
  detailCurrentItemsSelection: PropTypes.array,
};

export default ShareControl;
