/*
 * Login Messages
 *
 * This contains all the text for the login component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.dashboard';

export default defineMessages({
  dayView: {
    id: `${scope}.components.dayView`,
    defaultMessage: 'Day',
  },
  weekView: {
    id: `${scope}.components.weekView`,
    defaultMessage: 'Week',
  },
  monthView: {
    id: `${scope}.components.monthView`,
    defaultMessage: 'Month',
  },
  scheduleView: {
    id: `${scope}.components.scheduleView`,
    defaultMessage: 'Schedule View',
  },
  newMeeting: {
    id: `${scope}.components.newMeeting`,
    defaultMessage: 'New Meeting',
  },
  meetingNow: {
    id: `${scope}.components.meetingNow`,
    defaultMessage: 'Meeting Now',
  },
  cancelMeeting: {
    id: `${scope}.components.cancelMeeting`,
    defaultMessage: 'Cancel Meeting',
  },
  editMeeting: {
    id: `${scope}.components.editMeeting`,
    defaultMessage: 'Edit Meeting',
  },
  attendeesErrorMessage: {
    id: `${scope}.components.attendeesErrorMessage`,
    defaultMessage: 'Please add at least one attendees',
  },
});
