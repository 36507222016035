import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectProduct = (state) => state.product || initialState;

const makeSelectLoading = () =>
  createSelector(selectProduct, (productState) => productState.loading);
const makeSelectProducts = () =>
  createSelector(selectProduct, (productState) => productState.products);
const makeSelectTotal = () =>
  createSelector(selectProduct, (productState) => productState.total);
const makeSelectPageSize = () =>
  createSelector(selectProduct, (productState) => productState.pageSize);
const makeSelectPageIndex = () =>
  createSelector(selectProduct, (productState) => productState.pageIndex);
const makeSelectSearch = () =>
  createSelector(selectProduct, (productState) => productState.search);
const makeSelectColumns = () =>
  createSelector(selectProduct, (productState) => productState.columns);
const makeSelectReloadGrid = () =>
  createSelector(selectProduct, (productState) => productState.reloadGrid);

const makeSelectGridConfig = () =>
  createSelector(selectProduct, (productState) => productState.gridConfig);
const makeSelectApplyGridConfig = () =>
  createSelector(selectProduct, (productState) => productState.applyGridConfig);

const makeSelectAddProductStatus = () =>
  createSelector(
    selectProduct,
    (productState) => productState.addProductStatus
  );
const makeSelectAddProductError = () =>
  createSelector(selectProduct, (productState) => productState.addProductError);
const makeSelectBrandImageOnAdd = () =>
  createSelector(selectProduct, (productState) => productState.brandImageOnAdd);
const makeSelectIsDisplayProductNew = () =>
  createSelector(
    selectProduct,
    (productState) => productState.isDisplayProductNew
  );

const makeSelectGDSNPartyList = () =>
  createSelector(selectProduct, (productState) => productState.GDSNPartyList);

export {
  selectProduct,
  makeSelectLoading,
  makeSelectProducts,
  makeSelectTotal,
  makeSelectPageSize,
  makeSelectPageIndex,
  makeSelectSearch,
  makeSelectColumns,
  makeSelectReloadGrid,
  makeSelectAddProductStatus,
  makeSelectAddProductError,
  makeSelectBrandImageOnAdd,
  makeSelectIsDisplayProductNew,
  makeSelectGDSNPartyList,
  makeSelectGridConfig,
  makeSelectApplyGridConfig,
};
