import React from 'react';
import { Steps } from 'antd';

import { StyledModal } from 'common/components';

const { Step } = Steps;

const LinkAssetToQaSpec = ({ modalProps, stepProps, children }) => {
  const { items, currentStep } = stepProps;

  return (
    <StyledModal
      maskClosable={false}
      footer={null}
      destroyOnClose
      {...modalProps}
    >
      <Steps current={currentStep}>
        {items.map((item) => (
          <Step {...item} />
        ))}
      </Steps>
      {children}
    </StyledModal>
  );
};

export default LinkAssetToQaSpec;
