import * as types from './constants';

export const getCommunicationTemplates = (pageSize, pageNumber, search) => {
  return {
    type: types.GET_COMMUNICATION_TEMPLATES,
    pageSize: pageSize,
    pageIndex: pageNumber,
    'Search.SearchText': search,
  };
};

export const getCommunicationTemplatesSuccess = (templates, paging) => {
  return {
    type: types.GET_COMMUNICATION_TEMPLATES_SUCCESS,
    templates,
    paging,
  };
};

export const getCommunicationTemplatesError = (error) => {
  return {
    type: types.GET_COMMUNICATION_TEMPLATES_ERROR,
    error,
  };
};

export const getCommunicationTemplatesGridColumns = (gridName) => ({
  type: types.GET_COMMUNICATION_TEMPLATES_COLUMNS,
  gridName,
});

export const getCommunicationTemplatesGridColumnsSuccess = (columns) => ({
  type: types.GET_COMMUNICATION_TEMPLATES_COLUMNS_SUCCESS,
  columns,
});

export const getCommunicationTemplatesGridColumnsError = (error) => ({
  type: types.GET_COMMUNICATION_TEMPLATES_COLUMNS_ERROR,
  error,
});

export const enableCreateMode = () => {
  return {
    type: types.ENABLE_CREATE_TEMPLATE,
  };
};

export const enableEditMode = () => {
  return {
    type: types.ENABLE_EDIT_TEMPLATE,
  };
};

export const resetTemplate = () => {
  return {
    type: types.RESET_TEMPLATE,
  };
};

export const saveIdTemplate = (id) => {
  return {
    type: types.SAVE_ID_TEMPLATE,
    id,
  };
};

export const enableSaveTemplate = () => {
  return {
    type: types.ENABLE_SAVE_TEMPLATE,
  };
};

export const unableSaveTemplate = () => {
  return {
    type: types.UNABLE_SAVE_TEMPLATE,
  };
};

export const disableUploadMedia = (status) => {
  return {
    type: types.DISABLE_UPLOAD_MEDIA,
    status,
  };
};
