import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { useGetEnumOptions } from 'pages/qa-spec/hooks';
import { sortBy } from 'lodash';

import { getSnapshotQaSpecClaims } from 'services/product';
import { renderPopoverContent } from 'pages/qa-spec/utils';

export const useGetQaClaimsFormDefine = ({
  supplierModifiedData,
  snapshotData,
}) => {
  const claimsEnumOptions = useGetEnumOptions({
    optionMapping: useMemo(
      () => (option) => ({
        value: option.enumDisplayName,
        displayName: option.enumDisplayName,
      }),
      []
    ),
  })?.claims;

  const formDefine = [
    [
      { rowConfig: { gutter: [15, 15] } },
      [
        {
          colProps: {
            style: { flex: 1 },
          },
        },
        {
          name: 'onePercentForThePlanetClaim',
          label: '1% For the Planet',
          type: 'checkbox',
          snapshotData: snapshotData?.['onePercentForThePlanetClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['onePercentForThePlanetClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            '1% For the Planet',
            snapshotData?.['onePercentForThePlanetClaim'] ?? false,
            supplierModifiedData?.['onePercentForThePlanetClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'adaSealofAcceptanceClaim',
          label: 'ADA Seal of Acceptence',
          type: 'checkbox',
          snapshotData: snapshotData?.['adaSealofAcceptanceClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['adaSealofAcceptanceClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'ADA Seal of Acceptence',
            snapshotData?.['adaSealofAcceptanceClaim'] ?? false,
            supplierModifiedData?.['adaSealofAcceptanceClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'beeBetterClaim',
          label: 'Bee Friendly',
          type: 'checkbox',
          snapshotData: snapshotData?.['beeBetterClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['beeBetterClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Bee Friendly',
            snapshotData?.['beeBetterClaim'] ?? false,
            supplierModifiedData?.['beeBetterClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'bioBasedYN',
          label: 'Bio-Based',
          type: 'checkbox',
          snapshotData: snapshotData?.['bioBasedYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['bioBasedYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Bio-Based',
            snapshotData?.['bioBasedYN'] ?? false,
            supplierModifiedData?.['bioBasedYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'biodegradable',
          label: 'Biodegradable - Packaging',
          type: 'checkbox',
          snapshotData: snapshotData?.['biodegradable'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['biodegradable'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Biodegradable - Packaging',
            snapshotData?.['biodegradable'] ?? false,
            supplierModifiedData?.['biodegradable'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'productIsBiodegradable',
          label: 'Biodegradable - Product',
          type: 'checkbox',
          snapshotData: snapshotData?.['productIsBiodegradable'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['productIsBiodegradable'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Biodegradable - Product',
            snapshotData?.['productIsBiodegradable'] ?? false,
            supplierModifiedData?.['productIsBiodegradable'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'bioDynamicYN',
          label: 'Bio - Dynamic',
          type: 'checkbox',
          snapshotData: snapshotData?.['bioDynamicYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['bioDynamicYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Bio - Dynamic',
            snapshotData?.['bioDynamicYN'] ?? false,
            supplierModifiedData?.['bioDynamicYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'bioengineeredYN',
          label: 'Bioengineered',
          type: 'checkbox',
          snapshotData: snapshotData?.['bioengineeredYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['bioengineeredYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Bioengineered',
            snapshotData?.['bioengineeredYN'] ?? false,
            supplierModifiedData?.['bioengineeredYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'containsBPA',
          label: 'BPA - Contains',
          type: 'checkbox',
          snapshotData: snapshotData?.['containsBPA'] ?? false,
          supplierModifiedData: supplierModifiedData?.['containsBPA'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'BPA - Contains',
            snapshotData?.['containsBPA'] ?? false,
            supplierModifiedData?.['containsBPA'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'bpaFreeLiner',
          label: 'BPA Free Liner',
          type: 'checkbox',
          snapshotData: snapshotData?.['bpaFreeLiner'] ?? false,
          supplierModifiedData: supplierModifiedData?.['bpaFreeLiner'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'BPA Free Liner',
            snapshotData?.['bpaFreeLiner'] ?? false,
            supplierModifiedData?.['bpaFreeLiner'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'bpaFreePackaging',
          label: 'BPA Free Packaging',
          type: 'checkbox',
          snapshotData: snapshotData?.['bpaFreePackaging'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['bpaFreePackaging'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'BPA Free Packaging',
            snapshotData?.['bpaFreePackaging'] ?? false,
            supplierModifiedData?.['bpaFreePackaging'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'carbonFootprintYN',
          label: 'Carbon Footprint',
          type: 'checkbox',
          snapshotData: snapshotData?.['carbonFootprintYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['carbonFootprintYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Carbon Footprint',
            snapshotData?.['carbonFootprintYN'] ?? false,
            supplierModifiedData?.['carbonFootprintYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'cradleToCradleYN',
          label: 'Cradle To Cradle',
          type: 'checkbox',
          snapshotData: snapshotData?.['cradleToCradleYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['cradleToCradleYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Cradle To Cradle',
            snapshotData?.['cradleToCradleYN'] ?? false,
            supplierModifiedData?.['cradleToCradleYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'crueltyFreeYN',
          label: 'Cruelty Free',
          type: 'checkbox',
          snapshotData: snapshotData?.['crueltyFreeYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['crueltyFreeYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Cruelty Free',
            snapshotData?.['crueltyFreeYN'] ?? false,
            supplierModifiedData?.['crueltyFreeYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'diabeticFriendlyYN',
          label: 'Diabetic Friendly',
          type: 'checkbox',
          snapshotData: snapshotData?.['diabeticFriendlyYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['diabeticFriendlyYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Diabetic Friendly',
            snapshotData?.['diabeticFriendlyYN'] ?? false,
            supplierModifiedData?.['diabeticFriendlyYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'dolphinFriendly',
          label: 'Dolphin Friendly',
          type: 'checkbox',
          snapshotData: snapshotData?.['dolphinFriendly'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['dolphinFriendly'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Dolphin Friendly',
            snapshotData?.['dolphinFriendly'] ?? false,
            supplierModifiedData?.['dolphinFriendly'] ?? false,
            null,
            'checkbox'
          ),
        },
      ],
      [
        {
          colProps: {
            style: { flex: 1 },
          },
        },
        {
          name: 'ecoFisheryYN',
          label: 'Eco Fishery Claim',
          type: 'checkbox',
          snapshotData: snapshotData?.['ecoFisheryYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['ecoFisheryYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Eco Fishery Claim',
            snapshotData?.['ecoFisheryYN'] ?? false,
            supplierModifiedData?.['ecoFisheryYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'fairTradeYN',
          label: 'Fair Trade',
          type: 'checkbox',
          snapshotData: snapshotData?.['fairTradeYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['fairTradeYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Fair Trade',
            snapshotData?.['fairTradeYN'] ?? false,
            supplierModifiedData?.['fairTradeYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'forLifeYN',
          label: 'For Life',
          type: 'checkbox',
          snapshotData: snapshotData?.['forLifeYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['forLifeYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'For Life',
            snapshotData?.['forLifeYN'] ?? false,
            supplierModifiedData?.['forLifeYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'glyphosateResidueFreeClaim',
          label: 'Glyphosate Residue Free',
          type: 'checkbox',
          snapshotData: snapshotData?.['glyphosateResidueFreeClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['glyphosateResidueFreeClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Glyphosate Residue Free',
            snapshotData?.['glyphosateResidueFreeClaim'] ?? false,
            supplierModifiedData?.['glyphosateResidueFreeClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'gmpyn',
          label: 'GMP Claim',
          type: 'checkbox',
          snapshotData: snapshotData?.['gmpyn'] ?? false,
          supplierModifiedData: supplierModifiedData?.['gmpyn'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'GMP Claim',
            snapshotData?.['gmpyn'] ?? false,
            supplierModifiedData?.['gmpyn'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'grassFedYN',
          label: 'Grass Fed',
          type: 'checkbox',
          snapshotData: snapshotData?.['grassFedYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['grassFedYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Grass Fed',
            snapshotData?.['grassFedYN'] ?? false,
            supplierModifiedData?.['grassFedYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'heartHealthyYN',
          label: 'Heart Healthy',
          type: 'checkbox',
          snapshotData: snapshotData?.['heartHealthyYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['heartHealthyYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Heart Healthy',
            snapshotData?.['heartHealthyYN'] ?? false,
            supplierModifiedData?.['heartHealthyYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'highPotency',
          label: 'High Potency',
          type: 'checkbox',
          snapshotData: snapshotData?.['highPotency'] ?? false,
          supplierModifiedData: supplierModifiedData?.['highPotency'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'High Potency',
            snapshotData?.['highPotency'] ?? false,
            supplierModifiedData?.['highPotency'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'highPressureCertifiedClaim',
          label: 'High Pressure Certified',
          type: 'checkbox',
          snapshotData: snapshotData?.['highPressureCertifiedClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['highPressureCertifiedClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'High Pressure Certified',
            snapshotData?.['highPressureCertifiedClaim'] ?? false,
            supplierModifiedData?.['highPressureCertifiedClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'ingredientsChina',
          label: 'Ingredients Sourced from China',
          type: 'checkbox',
          snapshotData: snapshotData?.['ingredientsChina'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['ingredientsChina'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Ingredients Sourced from China',
            snapshotData?.['ingredientsChina'] ?? false,
            supplierModifiedData?.['ingredientsChina'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'italCertifiedSealClaim',
          label: 'ITAL Certified Seal',
          type: 'checkbox',
          snapshotData: snapshotData?.['italCertifiedSealClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['italCertifiedSealClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'ITAL Certified Seal',
            snapshotData?.['italCertifiedSealClaim'] ?? false,
            supplierModifiedData?.['italCertifiedSealClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'italConsciousSealClaim',
          label: 'ITAL Conscious Seal',
          type: 'checkbox',
          snapshotData: snapshotData?.['italConsciousSealClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['italConsciousSealClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'ITAL Conscious Seal',
            snapshotData?.['italConsciousSealClaim'] ?? false,
            supplierModifiedData?.['italConsciousSealClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'italSacramentSealClaim',
          label: 'ITAL Sacrament Seal',
          type: 'checkbox',
          snapshotData: snapshotData?.['italSacramentSealClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['italSacramentSealClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'ITAL Sacrament Seal',
            snapshotData?.['italSacramentSealClaim'] ?? false,
            supplierModifiedData?.['italSacramentSealClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'ketoYN',
          label: 'Keto / Ketogenic',
          type: 'checkbox',
          snapshotData: snapshotData?.['ketoYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['ketoYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Keto / Ketogenic',
            snapshotData?.['ketoYN'] ?? false,
            supplierModifiedData?.['ketoYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'liveAndActiveCulture',
          label: 'Live & Active Cultures',
          type: 'checkbox',
          snapshotData: snapshotData?.['liveAndActiveCulture'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['liveAndActiveCulture'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Live & Active Cultures',
            snapshotData?.['liveAndActiveCulture'] ?? false,
            supplierModifiedData?.['liveAndActiveCulture'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'lowGlycemicYN',
          label: 'Low Glycemic',
          type: 'checkbox',
          snapshotData: snapshotData?.['lowGlycemicYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['lowGlycemicYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Low Glycemic',
            snapshotData?.['lowGlycemicYN'] ?? false,
            supplierModifiedData?.['lowGlycemicYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'organicIngredientsMadeWith',
          label: 'Made with Organic Ingredients',
          type: 'checkbox',
          snapshotData: snapshotData?.['organicIngredientsMadeWith'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['organicIngredientsMadeWith'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Made with Organic Ingredients',
            snapshotData?.['organicIngredientsMadeWith'] ?? false,
            supplierModifiedData?.['organicIngredientsMadeWith'] ?? false,
            null,
            'checkbox'
          ),
        },
      ],
      [
        {
          colProps: {
            style: { flex: 1 },
          },
        },
        {
          name: 'nonIrradiatedIngredients',
          label: 'Non-irradiated Ingredients',
          type: 'checkbox',
          snapshotData: snapshotData?.['nonIrradiatedIngredients'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['nonIrradiatedIngredients'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Non-irradiated Ingredients',
            snapshotData?.['nonIrradiatedIngredients'] ?? false,
            supplierModifiedData?.['nonIrradiatedIngredients'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'npayn',
          label: 'NPA',
          type: 'checkbox',
          snapshotData: snapshotData?.['npayn'] ?? false,
          supplierModifiedData: supplierModifiedData?.['npayn'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'NPA',
            snapshotData?.['npayn'] ?? false,
            supplierModifiedData?.['npayn'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'pacayn',
          label: 'PACA Compliant',
          type: 'checkbox',
          snapshotData: snapshotData?.['pacayn'] ?? false,
          supplierModifiedData: supplierModifiedData?.['pacayn'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'PACA Compliant',
            snapshotData?.['pacayn'] ?? false,
            supplierModifiedData?.['pacayn'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'paleoYN',
          label: 'Paleo',
          type: 'checkbox',
          snapshotData: snapshotData?.['paleoYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['paleoYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Paleo',
            snapshotData?.['paleoYN'] ?? false,
            supplierModifiedData?.['paleoYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'pasayn',
          label: 'PASA Compliant',
          type: 'checkbox',
          snapshotData: snapshotData?.['pasayn'] ?? false,
          supplierModifiedData: supplierModifiedData?.['pasayn'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'PASA Compliant',
            snapshotData?.['pasayn'] ?? false,
            supplierModifiedData?.['pasayn'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'plantBasedDerivedClaim',
          label: 'Plant Based / Derived',
          type: 'checkbox',
          snapshotData: snapshotData?.['plantBasedDerivedClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['plantBasedDerivedClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Plant Based / Derived',
            snapshotData?.['plantBasedDerivedClaim'] ?? false,
            supplierModifiedData?.['plantBasedDerivedClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'rainForestAllianceYN',
          label: 'Rain Forest Alliance',
          type: 'checkbox',
          snapshotData: snapshotData?.['rainForestAllianceYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['rainForestAllianceYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Rain Forest Alliance',
            snapshotData?.['rainForestAllianceYN'] ?? false,
            supplierModifiedData?.['rainForestAllianceYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'realCertifiedClaim',
          label: 'REAL Certified',
          type: 'checkbox',
          snapshotData: snapshotData?.['realCertifiedClaim'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['realCertifiedClaim'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'REAL Certified',
            snapshotData?.['realCertifiedClaim'] ?? false,
            supplierModifiedData?.['realCertifiedClaim'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'realSealYN',
          label: 'Real Dairy Seal',
          type: 'checkbox',
          snapshotData: snapshotData?.['realSealYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['realSealYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Real Dairy Seal',
            snapshotData?.['realSealYN'] ?? false,
            supplierModifiedData?.['realSealYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'veganYN',
          label: 'Vegan',
          type: 'checkbox',
          snapshotData: snapshotData?.['veganYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['veganYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Vegan',
            snapshotData?.['veganYN'] ?? false,
            supplierModifiedData?.['veganYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'vegetarianYN',
          label: 'Vegetarian',
          type: 'checkbox',
          snapshotData: snapshotData?.['vegetarianYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['vegetarianYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Vegetarian',
            snapshotData?.['vegetarianYN'] ?? false,
            supplierModifiedData?.['vegetarianYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'viticultureYN',
          label: 'Vitaculture',
          type: 'checkbox',
          snapshotData: snapshotData?.['viticultureYN'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['viticultureYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Vitaculture',
            snapshotData?.['viticultureYN'] ?? false,
            supplierModifiedData?.['viticultureYN'] ?? false,
            null,
            'checkbox'
          ),
        },
      ],
      [
        {
          colProps: {
            style: { flex: 1.5 },
          },
        },
        {
          name: 'waterQualityAssociationCertified',
          label: 'Water Quality Association',
          type: 'checkbox',
          snapshotData:
            snapshotData?.['waterQualityAssociationCertified'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['waterQualityAssociationCertified'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Water Quality Association',
            snapshotData?.['waterQualityAssociationCertified'] ?? false,
            supplierModifiedData?.['waterQualityAssociationCertified'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'wercsRegistered',
          label: 'WERCS Registered',
          type: 'checkbox',
          snapshotData: snapshotData?.['wercsRegistered'] ?? false,
          supplierModifiedData:
            supplierModifiedData?.['wercsRegistered'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'WERCS Registered',
            snapshotData?.['wercsRegistered'] ?? false,
            supplierModifiedData?.['wercsRegistered'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'wholeGrainYN',
          label: 'Whole Grain',
          type: 'checkbox',
          snapshotData: snapshotData?.['wholeGrainYN'] ?? false,
          supplierModifiedData: supplierModifiedData?.['wholeGrainYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Whole Grain',
            snapshotData?.['wholeGrainYN'] ?? false,
            supplierModifiedData?.['wholeGrainYN'] ?? false,
            null,
            'checkbox'
          ),
        },
        {
          name: 'contains',
          label: 'Contains',
          type: 'select',
          selectProps: {
            inputProps: {
              mode: 'multiple',
              allowClear: true,
            },
            options: sortBy(claimsEnumOptions.contains, ['displayName']),
          },
          snapshotData: snapshotData?.['contains'],
          supplierModifiedData: supplierModifiedData?.['contains'],
          renderPopoverContent: renderPopoverContent(
            'Contains',
            snapshotData?.['contains'],
            supplierModifiedData?.['contains']
          ),
        },
        {
          name: 'doesNotContain',
          label: 'Does Not Contain',
          type: 'select',
          selectProps: {
            inputProps: {
              mode: 'multiple',
              allowClear: true,
            },
            options: sortBy(claimsEnumOptions.doesNotContain, ['displayName']),
          },
          snapshotData: snapshotData?.['doesNotContain'],
          supplierModifiedData: supplierModifiedData?.['doesNotContain'],
          renderPopoverContent: renderPopoverContent(
            'Does Not Contain',
            snapshotData?.['doesNotContain'],
            supplierModifiedData?.['doesNotContain']
          ),
        },
      ],
    ],
  ];
  return formDefine;
};

const getQueryKeyQaClaims = (productId) => {
  return ['pim', 'snapshot', parseInt(productId), 'qa-claim'];
};

export const useGetSnapshotQaClaims = ({
  enabled,
  productId,
  ...restProps
}) => {
  const queryClient = useQueryClient();

  const getSnapshotQaClaimsData = async () => {
    const response = await getSnapshotQaSpecClaims({ productId });
    const { isSuccess, data } = response;
    if (!isSuccess) return null;

    const snapshotData = {
      ...data,
      contains: data?.contains || [],
      doesNotContain: data?.doesNotContain || [],
    };
    return snapshotData;
  };

  const query = useQuery({
    queryKey: getQueryKeyQaClaims(productId),
    queryFn: getSnapshotQaClaimsData,
    enabled,
    ...restProps,
  });

  const invalidateSnapshotQaSpecClaims = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyQaClaims(productId),
    });
  };

  return { ...query, invalidateSnapshotQaSpecClaims };
};
