import React, { useState, useEffect } from 'react';

import { Typography, Checkbox } from 'antd';
import classnames from 'classnames';

import './PermissionList.less';

const { Text } = Typography;

const PermissionList = (props) => {
  const {
    permissionList,
    checkable = true,
    initialSelectedPermission = [],
    onChange,
  } = props;

  const [selectedPermission, setSelectedPermission] = useState([]);

  const onCheckPermission = (permission) => (e) => {
    if (!checkable) return;

    const checked = e.target.checked;

    const newSelectedPermission = checked
      ? [...selectedPermission, permission]
      : selectedPermission.filter((selectedPermissionItem) => {
          return selectedPermissionItem !== permission;
        });

    setSelectedPermission(newSelectedPermission);

    onChange && onChange(newSelectedPermission);
  };

  const initSelectedPermission = () => {
    initialSelectedPermission?.length &&
      setSelectedPermission(initialSelectedPermission);
  };

  useEffect(() => {
    initSelectedPermission();
    //eslint-disable-next-line
  }, [initialSelectedPermission]);

  const renderPermissionList = (permissionItem) => {
    const isChecked = selectedPermission.includes(permissionItem.value);
    return (
      <div
        key={permissionItem?.value}
        className={classnames('permission-list__permission-item')}
      >
        <Checkbox
          checked={isChecked}
          onChange={onCheckPermission(permissionItem.value)}
          disabled={permissionItem?.disabled}
        >
          {permissionItem?.displayName}
        </Checkbox>
      </div>
    );
  };

  const renderPermissionGroup = (permissionGroup) => {
    const { groupName, permissions } = permissionGroup;
    return (
      <div className={classnames('permission-list__group')} key={groupName}>
        <div className='permission-list__group-title'>
          <Text ellipsis>{groupName}</Text>
        </div>
        <div className='permission-list__permission-list'>
          {permissions?.length > 0 && permissions.map(renderPermissionList)}
        </div>
      </div>
    );
  };

  return (
    <div className='permission-list'>
      {permissionList?.map(renderPermissionGroup)}
    </div>
  );
};

export default PermissionList;
