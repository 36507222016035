import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  loading: false,
  memberContactHistory: null,
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const memberContactHistoryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MEMBER_CONTACT_HISTORY:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_MEMBER_CONTACT_HISTORY_SUCCESS:
        draft.loading = false;
        draft.memberContactHistory = action.data;
        draft.error = null;
        break;
      case types.GET_MEMBER_CONTACT_HISTORY_ERROR:
        draft.loading = false;
        draft.error = action.error;
        draft.memberContactHistory = null;
        break;
    }
  });

export default memberContactHistoryReducer;
