import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';

const { Paragraph } = Typography;

const ProductRightData = (props) => {
  const { dataDetail } = props;

  return (
    <Row className='product-info__info-modify-height-100' align='middle'>
      <Col span={24}>
        <Paragraph ellipsis='true'>Last Modified:</Paragraph>
        <Paragraph ellipsis='true'>
          {
            //TODO: DATETIME
            dataDetail && dataDetail.lastModifiedDate
          }
        </Paragraph>
      </Col>
    </Row>
  );
};

ProductRightData.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductRightData;
