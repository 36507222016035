import { useEffect, useRef } from 'react';

import * as constant from 'static/Constants';
import * as actionsMember from '../../controllers/actions';
import * as actionsGlobal from 'redux/global/actions';
import { useGetQuery } from 'hooks/useQuery';

// TODOS: Khang - 09/12/2020 - This file will be changed after the Product View implement

const useColumnsGrid = (dispatch, typeView) => {
  useEffect(() => {
    if (typeView === constant.RIBBON_TYPES.DETAILSVIEW)
      dispatch(
        actionsMember.getColumnsProductsForMember('products-for-member')
      );
  }, [dispatch, typeView]);
};

const useProductsForMember = (
  dispatch,
  pageIndex,
  pageSize,
  memberId,
  searchText
) => {
  useEffect(() => {
    const params = {
      pageIndex,
      pageSize,
      memberId,
      search: { searchText },
      sort: [{ isAscending: false, fieldName: 'id' }],
    };

    dispatch(actionsMember.getProductsForMember(params));
  }, [dispatch, memberId, searchText]);

  useEffect(() => {
    return () => {
      dispatch(actionsMember.resetProductsForMemberState());
    };
  }, []);
};

const useSaveIdMember = (dispatch, memberId) => {
  useEffect(() => {
    dispatch(actionsMember.saveIdMember(memberId));
  }, [dispatch, memberId]);
};

const useToggleProdForMember = (dispatch) => {
  useEffect(() => {
    dispatch(actionsGlobal.toggleProdToMember());
  }, [dispatch]);
};

const useGetMemberId = () => {
  const refMemberId = useRef();
  const query = useGetQuery();
  const memberId = query.get('memberId');

  useEffect(() => {
    refMemberId.current = memberId;
  }, [memberId]);

  return { memberId, refMemberId };
};

export {
  useColumnsGrid,
  useProductsForMember,
  useSaveIdMember,
  useToggleProdForMember,
  useGetMemberId,
};
