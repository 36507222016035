import React from 'react';
import Messages from 'i18n/messages/home';
import { BorderInnerOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

const NewTaskControl = ({ onClick }) => {
  return (
    <RibbonButton
      icon={<BorderInnerOutlined style={{ height: 26 }} />}
      label={Messages.newTask}
      onClick={onClick}
    />
  );
};

NewTaskControl.propTypes = {};

export default NewTaskControl;
