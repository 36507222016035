import classNames from 'classnames';

const DrugFactSection = ({ className, children }) => {
  return (
    <div
      className={classNames({
        'drug-fact__section': true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export default DrugFactSection;
