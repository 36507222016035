import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGridView = (state) => state?.gridView || initialState;

const makeSelectVisibleSelection = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.visibleSelection
  );
const makeSelectItemCurrentSelection = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.itemCurrentSelection
  );
const makeSelectItemsSelectionDialog = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.itemSelectionDialog
  );
const makeSelectItemsSelection = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.itemsSelection
  );
const makeSelectPagesSelection = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.pagesSelection
  );
const makeSelectItemPageSelection = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.itemPageSelection
  );
const makeSelectCountPageSelection = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.countPageSelection
  );
const makeSelectIsDisplayMask = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.isDisplayMask
  );
const makeSelectVisible = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.visible);
const makeSelectSizeCurrent = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.sizeCurrent);

const makeSelectPageCurrent = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.pageCurrent);

const makeSelectSizeCurrentMember = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.sizeCurrentMember
  );
const makeSelectSizeCurrentDigitalMedia = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.sizeCurrentDigital
  );
const makeSelectTypeViewCurrent = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.typeViewCurrent
  );
const makeSelectSearchCurrent = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.searchCurrent
  );
const makeSelectDetailCurrentITemsSelection = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.detailCurrentItemsSelection
  );
// const makeSelectAgGridItemsSelection = () =>
//   createSelector(
//     selectGridView,
//     (gridViewState) => gridViewState.agGridItemsSelection
//   );
const makeSelectFolderLoading = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.folderLoading
  );
const makeSelectFolderList = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.folderList);
const makeSelectFolderItem = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.folderItem);
const makeSelectAddEntityToFolderLoading = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.addEntityToFolderLoading
  );
const makeSelectAddEntityToFolderError = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.addEntityToFolderError
  );
const makeSelectAddEntityToFolderSuccess = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.addEntityToFolderSuccess
  );

const makeSelectColumnsGrid = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.columnsGrid);

const makeSelectColumnsFilteredGrid = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.filteredColumns
  );

const makeSelectToggleGrid = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.isToggleGrid);

const makeSelectIdConfig = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.idConfig);

const makeSelectStatusCreate = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.statusCreate);

const makeSelectGridConfig = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.gridConfig);

const makeSelecActiveGrid = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.activeGrid);

const makeSelectFolderQueryLoading = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.folderQueryLoading
  );
const makeSelectFolderQueryList = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.folderQueryList
  );

const makeSelectCheckQueryCondition = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.checkQueryCondition
  );
const makeSelectMyQueryDoubleClick = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.myQueryDoubleClick
  );

const makeSelectSearchCategoriesList = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.searchCategoriesEffected
  );
const makeSelectFavMembersQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favMembersQuery
  );
const makeSelectFavAssetsQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favAssetsQuery
  );
const makeSelectFavFoldersQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favFoldersQuery
  );
const makeSelectFavProductsQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favProductsQuery
  );
const makeSelectProductsQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.productsQuery
  );

const makeSelectMembersQuery = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.membersQuery);
const makeSelectDigitalMediaQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.digitalMediaQuery
  );
const makeSelectDigitalMediaQueryNew = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.digitalMediaNewQuery
  );
const makeSelectAssetsQuery = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.assetsQuery);
const makeSelectDocumentsQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.documentsQuery
  );
const makeSelectMultiMediaQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.multiMediaQuery
  );
const makeSelectReportingsQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.reportingsQuery
  );
const makeSelectMyReportsQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.myReportsQuery
  );
const makeSelectReportsSharedToMeQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.reportsSharedToMeQuery
  );
const makeSelectFoldersQuery = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.foldersQuery);
const makeSelectMyFoldersQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.myFoldersQuery
  );
const makeSelectFoldersSharedToMeQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.foldersSharedToMeQuery
  );
const makeSelectAssetsForMemberQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.assetsForMemberQuery
  );

const makeSelectProductsForMemberQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.productsForMemberQuery
  );
const makeSelectFavMembersQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favMembersQueryAdvance
  );
const makeSelectFavAssetsQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favAssetsQueryAdvance
  );
const makeSelectFavFoldersQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favFoldersQueryAdvance
  );
const makeSelectFavProductsQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favProductsQueryAdvance
  );
const makeSelectProductsQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.productsQueryAdvance
  );
const makeSelectMembersQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.membersQueryAdvance
  );
const makeSelectDigitalMediaQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.digitalMediaQueryAdvance
  );
const makeSelectDigitalMediaNewQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.digitalMediaNewQueryAdvance
  );
const makeSelectAssetsQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.assetsQueryAdvance
  );
const makeSelectDocumentsQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.documentsQueryAdvance
  );
const makeSelectMultiMediaQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.multiMediaQueryAdvance
  );
const makeSelectReportingsQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.reportingsQueryAdvance
  );
const makeSelectMyReportsQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.myReportsQueryAdvance
  );

const makeSelectReportsSharedToMeQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.reportsSharedToMeQueryAdvance
  );

const makeSelectFoldersQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.foldersQueryAdvance
  );

const makeSelectMyFoldersQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.myFoldersQueryAdvance
  );

const makeSelectFoldersSharedToMeQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.foldersSharedToMeQueryAdvance
  );

const makeSelectAssetsForMemberQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.assetsForMemberQueryAdvance
  );

const makeSelectProductsForMemberQueryAdvance = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.productsForMemberQueryAdvance
  );

const makeSelectFavMembersIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favMembersIdQuery
  );

const makeSelectFavAssetsIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favAssetsIdQuery
  );

const makeSelectFavFoldersIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favFoldersIdQuery
  );
const makeSelectFavProductsIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.favProductsIdQuery
  );
const makeSelectProductsIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.productsIdQuery
  );
const makeSelectMembersIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.membersIdQuery
  );

const makeSelectDigitalMediaIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.digitalMediaIdQuery
  );

const makeSelectDigitalMediaIdQueryNew = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.digitalMediaIdQueryNew
  );

const makeSelectAssetsIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.assetsIdQuery
  );

const makeSelectDocumentsIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.documentsIdQuery
  );

const makeSelectMultiMediaIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.multiMediaIdQuery
  );

const makeSelectReportingsIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.reportingsIdQuery
  );

const makeSelectMyReportsIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.myReportsIdQuery
  );

const makeSelectReportsSharedToMeIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.reportsSharedToMeIdQuery
  );

const makeSelectFoldersIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.foldersIdQuery
  );
const makeSelectMyFoldersIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.myFoldersIdQuery
  );
const makeSelectFoldersSharedToMeIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.foldersSharedToMeIdQuery
  );
const makeSelectAssetsForMemberIdQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.assetsForMemberIdQuery
  );
const makeSelectDigitalMediaNewQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.digitalMediaNewQuery
  );

const makeSelectReloadGrid = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.reloadGrid);

const makeSelectParamsGridView = () =>
  createSelector(selectGridView, (gridViewState) => gridViewState.paramsGrid);

const makeSelectPrevPathnameQuery = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.prevPathnameQuery
  );

const makeSelectPreventCallApiGridView = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.preventCallApiGridView
  );

const makeSelectIsOpenAdvanceSearch = () =>
  createSelector(
    selectGridView,
    (gridViewState) => gridViewState.isOpenAdvanceSearch
  );

export default {
  selectGridView,
  makeSelectVisibleSelection,
  makeSelectItemCurrentSelection,
  makeSelectItemsSelectionDialog,
  makeSelectItemsSelection,
  makeSelectPagesSelection,
  makeSelectItemPageSelection,
  makeSelectCountPageSelection,
  makeSelectIsDisplayMask,
  makeSelectVisible,
  makeSelectSizeCurrent,
  makeSelectTypeViewCurrent,
  makeSelectSearchCurrent,
  makeSelectDetailCurrentITemsSelection,
  // makeSelectAgGridItemsSelection,
  makeSelectFolderLoading,
  makeSelectFolderList,
  makeSelectFolderItem,
  makeSelectAddEntityToFolderLoading,
  makeSelectAddEntityToFolderError,
  makeSelectAddEntityToFolderSuccess,
  makeSelectSizeCurrentMember,
  makeSelectSizeCurrentDigitalMedia,
  makeSelectPageCurrent,
  makeSelectColumnsGrid,
  makeSelectColumnsFilteredGrid,
  makeSelectToggleGrid,
  makeSelectIdConfig,
  makeSelectStatusCreate,
  makeSelectGridConfig,
  makeSelecActiveGrid,
  makeSelectFolderQueryLoading,
  makeSelectFolderQueryList,
  makeSelectCheckQueryCondition,
  makeSelectMyQueryDoubleClick,
  makeSelectSearchCategoriesList,
  makeSelectFavMembersQuery,
  makeSelectFavAssetsQuery,
  makeSelectFavFoldersQuery,
  makeSelectFavProductsQuery,
  makeSelectMembersQuery,
  makeSelectDigitalMediaQuery,
  makeSelectAssetsQuery,
  makeSelectDocumentsQuery,
  makeSelectMultiMediaQuery,
  makeSelectReportingsQuery,
  makeSelectMyReportsQuery,
  makeSelectReportsSharedToMeQuery,
  makeSelectFoldersQuery,
  makeSelectMyFoldersQuery,
  makeSelectFoldersSharedToMeQuery,
  makeSelectFavMembersQueryAdvance,
  makeSelectFavAssetsQueryAdvance,
  makeSelectFavFoldersQueryAdvance,
  makeSelectFavProductsQueryAdvance,
  makeSelectMembersQueryAdvance,
  makeSelectDigitalMediaQueryAdvance,
  makeSelectAssetsQueryAdvance,
  makeSelectDocumentsQueryAdvance,
  makeSelectMultiMediaQueryAdvance,
  makeSelectReportingsQueryAdvance,
  makeSelectMyReportsQueryAdvance,
  makeSelectReportsSharedToMeQueryAdvance,
  makeSelectFoldersQueryAdvance,
  makeSelectMyFoldersQueryAdvance,
  makeSelectFoldersSharedToMeQueryAdvance,
  makeSelectFavMembersIdQuery,
  makeSelectFavAssetsIdQuery,
  makeSelectFavFoldersIdQuery,
  makeSelectFavProductsIdQuery,
  makeSelectMembersIdQuery,
  makeSelectDigitalMediaIdQuery,
  makeSelectAssetsIdQuery,
  makeSelectDocumentsIdQuery,
  makeSelectMultiMediaIdQuery,
  makeSelectReportingsIdQuery,
  makeSelectMyReportsIdQuery,
  makeSelectReportsSharedToMeIdQuery,
  makeSelectFoldersIdQuery,
  makeSelectMyFoldersIdQuery,
  makeSelectFoldersSharedToMeIdQuery,
  makeSelectAssetsForMemberQuery,
  makeSelectProductsForMemberQuery,
  makeSelectAssetsForMemberQueryAdvance,
  makeSelectProductsForMemberQueryAdvance,
  makeSelectProductsForMemberQueryAdvance,
  makeSelectAssetsForMemberIdQuery,
  makeSelectDigitalMediaNewQuery,
  makeSelectDigitalMediaNewQueryAdvance,
  makeSelectDigitalMediaIdQueryNew,
  makeSelectDigitalMediaQueryNew,
  makeSelectProductsQuery,
  makeSelectProductsQueryAdvance,
  makeSelectProductsIdQuery,
  makeSelectReloadGrid,
  makeSelectParamsGridView,
  makeSelectPrevPathnameQuery,
  makeSelectPreventCallApiGridView,
  makeSelectIsOpenAdvanceSearch,
};
