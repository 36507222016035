import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import * as globalSelectors from 'redux/global/selectors';

import {
  getProductItemModules,
  getProductItemModulesByBrickCode,
  getProductCustomPropertiesData,
  getMemberProductCustomProperties,
} from 'services/product';

import { useAsync } from 'hooks/useAsync';

const useGetProductItemModules = (id, isEnabled = true) => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    if (id && isEnabled) {
      run(getProductItemModules({ productId: id }));
    }
    return;
  }, [id, isEnabled, run]);

  const refetchProductItems = useCallback(() => {
    return run(getProductItemModules({ productId: id }));
  }, [id, run]);

  return {
    productItemModules: data,
    statusFetchProductItem: status,
    refetchProductItems,
  };
};

const useGetProductCustomProperties = (id) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (id) {
      run(getProductCustomPropertiesData({ productId: id }));
    }
    return;
  }, [id, run]);

  const refetchProductCustomPropertiesData = useCallback(() => {
    return run(getProductCustomPropertiesData({ productId: id }));
  }, [id, run]);

  return { data, refetchProductCustomPropertiesData };
};

const useGetMemberProductCustomProperties = (ownerId) => {
  const { data, error, run } = useAsync();

  useEffect(() => {
    if (ownerId) {
      run(getMemberProductCustomProperties({ memberId: ownerId }));
    }
    return;
  }, [ownerId, run]);

  return { data, error };
};

const useScrollToFoundModule = ({ foundModule, elementByClassName }) => {
  useEffect(() => {
    if (foundModule && elementByClassName?.[0]) {
      elementByClassName[0].scrollIntoView();
    }

    return;
  }, [elementByClassName, foundModule]);
};

export {
  useGetProductItemModules,
  useGetProductCustomProperties,
  useScrollToFoundModule,
  useGetMemberProductCustomProperties,
};
