export const getPartGroupNames = (groupIds = [], productGroups = []) => {
  let result = [];

  groupIds?.forEach((groupId) => {
    const foundName = productGroups.find((group) => group?.groupId === groupId);
    if (foundName) {
      result.push(foundName);
    }
  });

  return result;
};

export const getSelectedItemGroup = (
  groupIds = [],
  productGroupsProps = []
) => {
  const productGroups = getPartGroupNames(groupIds, productGroupsProps);
  const defaultGroup = productGroups?.length >= 1 ? productGroups?.[0] : null;

  return {
    selectedProductGroups: productGroups,
    defaultGroup,
  };
};

export const getShowGroupNames = (groupNames = []) =>
  groupNames.map((group) => group?.groupName) ?? [];

export const transformToTextGroupName = (groupIds = [], productGroups = []) => {
  if (!groupIds || groupIds.length === 0) return 'No Group';

  const groupPartNames = getPartGroupNames(groupIds, productGroups) ?? [];
  const showGroupNames = getShowGroupNames(groupPartNames);

  return showGroupNames.join(', ');
};

export const appendDataGroup = (array1, array2, array3, array4) => {
  return [
    ...(array1 ?? []),
    ...(array2 ?? []),
    ...(array3 ?? []),
    ...(array4 ?? []),
  ];
};
