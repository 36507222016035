import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Row, Col, Tooltip, Collapse, Typography, Divider } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import Messages from 'i18n/messages/product';

import { FavoriteStarIconRender, Img } from 'common/components';
import {
  InformationLabel,
  InformationData,
  ListDimensionInfo,
  DimensionInfo,
  Loading,
} from './SharedComponents';

import { Images } from 'config/assets';

import useDoubleClick from 'hooks/useDoubleClick';
import { forwardTo } from 'utils/common/route';

import { getProductShortDetail } from 'services/product';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { useGetProductEnums } from 'hooks';

import './style.less';

/**
 * Use for Content Pane
 * Use for Right section of Product History
 */
const ProductContentPanel = (props) => {
  const {
    historyPage = false,
    productShortDetailHistory,
    historyProductId,
    productId,
    forceVisible = false,
    getProductDetail,
  } = props;

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const idProduct = detailCurrentItemsSelection[0]?.id || productId;

  const visibleContentPanel = useSelector(
    selectorsGridView.makeSelectVisible()
  );

  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (!productId) setProduct(null);

    if (
      historyPage ||
      (forceVisible === false && visibleContentPanel === false) ||
      !idProduct
    )
      return;

    getProductShortDetail({ id: idProduct }).then((resp) => {
      if (resp?.isSuccess) {
        setProduct(resp.data);
      }
    });
  }, [historyPage, idProduct, visibleContentPanel]);

  useEffect(() => {
    if (historyPage && !isEmpty(productShortDetailHistory)) {
      setProduct(productShortDetailHistory);
    }
  }, [JSON.stringify(productShortDetailHistory)]);

  useEffect(() => {
    if (product && getProductDetail) {
      getProductDetail(product);
    }
  }, [product, getProductDetail]);

  return (
    <>
      {product ? (
        <ProductContent>
          <ProductActions id={!historyPage ? idProduct : historyProductId} />
          <ProductLogoThumbnail id={idProduct} logo={product.productImage} />
          <ProductInformation product={product} historyPage={historyPage} />
        </ProductContent>
      ) : (
        <Loading />
      )}
    </>
  );
};

export const ProductContent = ({ children }) => {
  return <Row className='product-content'>{children}</Row>;
};

export const ProductActions = ({ id }) => {
  return (
    <Row
      align='top'
      justify='space-between'
      style={{ width: '100%', padding: '0 5px', marginBottom: 20 }}
    >
      <Col span={12}>
        <Tooltip title='View detail' placement='right'>
          <EyeOutlined
            className='product-content__action'
            onClick={() => forwardTo(`/product/${id}`)}
          />
        </Tooltip>
      </Col>
      {/* <Col>
        <Tooltip title='Download product' placement='right'>
          <DownloadOutlined
            className='product-content__action'
            onClick={() => console.log('Download API does not has yet')}
          />
        </Tooltip>
      </Col> */}
    </Row>
  );
};

export const ProductLogoThumbnail = ({ id, logo }) => {
  const logoRef = useRef();

  useDoubleClick({
    onDoubleClick: () => {
      forwardTo(`/product/${id}`);
    },
    ref: logoRef,
    latency: 250,
  });

  return (
    <Row className='product-content__thumbnail' ref={logoRef}>
      <Img
        src={logo ? logo : Images.RIVIR_LOGO_DEFAULT}
        className='product-content__thumbnail--logo'
      />
    </Row>
  );
};

export const ProductInformation = ({ product, historyPage = false }) => {
  const {
    productName,
    productDescription,
    rivirId,
    brandName = 'N/A',
    brickCode = 'N/A',
    brickCodeName = 'N/A',
    productType,
    gtin,
    imperialHeight,
    height: metricHeight,
    imperialWidth,
    width: metricWidth,
    imperialDepth,
    depth: metricDepth,
    imperialWeight,
    weight: metricWeight,
    imperialDimensionUOM,
    dimensionUOM: metricDimensionUOM,
    imperialWeightUOM,
    weightUOM: metricWeightUOM,
    isFavorited,
  } = product;

  const intl = useIntl();

  // H: Height, W: Width, D: Depth
  const listNameDimension = ['H', 'W', 'D'];

  const listImperialValue = [imperialHeight, imperialWidth, imperialDepth];
  const listMetricValue = [metricHeight, metricWidth, metricDepth];
  const { productEnums } = useGetProductEnums();

  return (
    <Row className='product-content__info-container'>
      <Row style={{ width: '100%', textAlign: 'center' }}>
        <Typography.Title level={4} style={{ width: '100%' }}>
          <FavoriteStarIconRender
            isFavorited={isFavorited}
            style={{ marginRight: 8 }}
          />
          {productName}
        </Typography.Title>
      </Row>

      <Divider className='product-content__divider' />

      <Row style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
        <InformationLabel>
          {intl.formatMessage(Messages.description)}
        </InformationLabel>
        <InformationData
          data={productDescription}
          style={{ textAlign: 'left' }}
        />
      </Row>

      <Row>
        <InformationLabel>
          {intl.formatMessage(Messages.rivirID)}
        </InformationLabel>
        <InformationData data={rivirId} />

        <InformationLabel>
          {intl.formatMessage(Messages.brandName)}
        </InformationLabel>
        <InformationData data={brandName} />

        <InformationLabel>
          {intl.formatMessage(Messages.barcode)}
        </InformationLabel>
        <InformationData data={gtin} />

        <InformationLabel>
          {intl.formatMessage(Messages.bricCode)}
        </InformationLabel>
        <InformationData
          data={`${brickCode || 'N/A'} - ${brickCodeName || 'N/A'}`}
        >
          {/* <IconList itemList={categories} noId /> TODOS: Applied after*/}
        </InformationData>

        <InformationLabel>
          {intl.formatMessage(Messages.dimensions)}
        </InformationLabel>
        <InformationData>
          <ListDimensionInfo
            listNameDimension={listNameDimension}
            listImperialValue={listImperialValue}
            imperialUnit={imperialDimensionUOM}
            listMetricValue={listMetricValue}
            metricUnit={metricDimensionUOM}
            productEnums={productEnums}
          />

          <DimensionInfo
            name={'Weight'}
            imperialValue={imperialWeight}
            imperialUnit={imperialWeightUOM}
            metricValue={metricWeight}
            metricUnit={metricWeightUOM}
            productEnums={productEnums}
          />
        </InformationData>
      </Row>
    </Row>
  );
};

export default ProductContentPanel;
