import React from 'react';
import PropTypes from 'prop-types';
import { TweenOneGroup } from 'rc-tween-one';

import HelpCenterTagItem from './HelpCenterTagItem';

import './HelpCenterTag.less';

const HelpCenterTag = (props) => {
  const { tags, customText, onClickTag, style } = props;

  const tagsRender =
    Array.isArray(tags) && tags?.length > 0
      ? tags.map((tagData, idx) => (
          <HelpCenterTagItem
            key={idx}
            tag={tagData}
            customText={customText}
            onClickTag={onClickTag}
            style={style}
          />
        ))
      : null;

  return (
    <>
      <TweenOneGroup
        enter={{
          scale: 0.8,
          opacity: 0,
          type: 'from',
          duration: 100,
          onComplete: (e) => {
            e.target.style = '';
          },
        }}
        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
        appear={false}
        style={{ width: '100%' }}
      >
        {tagsRender}
      </TweenOneGroup>
    </>
  );
};

HelpCenterTag.propTypes = {
  tags: PropTypes.array,
  customText: PropTypes.func,
  onClickTag: PropTypes.func,
  style: PropTypes.object,
};

export default HelpCenterTag;
