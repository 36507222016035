import React from 'react';

import { Divider } from 'antd';

import { ViewLayout, AdvanceStack } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import EditCommunicationTemplate from './components/controls/maintenance/communication/grid-view/EditCommunicationTemplate';
import CreateCommunicationTemplate from './components/controls/maintenance/communication/grid-view/CreateCommunicationTemplate';
import DeleteCommunicationTemplate from './components/controls/maintenance/communication/grid-view/DeleteCommunicationTemplate';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const CommunicationGridView = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can
        I={ABILITY_ACTION.MAINTAIN}
        a={ABILITY_SUBJECT.COMMUNICATION_TEMPLATE}
      >
        <EditCommunicationTemplate />
      </Can>
      <RibbonDivider />
      <Can
        I={ABILITY_ACTION.MAINTAIN}
        a={ABILITY_SUBJECT.COMMUNICATION_TEMPLATE}
      >
        <CreateCommunicationTemplate isSmallBtn />
      </Can>
      <Can
        I={ABILITY_ACTION.MAINTAIN}
        a={ABILITY_SUBJECT.COMMUNICATION_TEMPLATE}
      >
        <DeleteCommunicationTemplate isSmallBtn />
      </Can>
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default CommunicationGridView;
