import React from 'react';
import { Drawer } from 'antd';
import PackageHeaderDrawer from './PackageHeaderDrawer';
import PackageContentDrawer from './PackageContentDrawer';
const widthDrawer = 845;

const PackageContent = ({ detail, onClose, visible, productItems }) => {
  return (
    <Drawer
      title={<PackageHeaderDrawer detail={detail} />}
      placement='right'
      onClose={onClose}
      visible={visible}
      key='right'
      mask={false}
      getContainer={false}
      style={{
        height: 'calc(100vh - 306px)',
        position: 'fixed',
      }}
      width={widthDrawer}
      closable
    >
      <PackageContentDrawer productItems={productItems} />
    </Drawer>
  );
};
export default PackageContent;
