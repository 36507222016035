import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Form, notification, Button, Typography } from 'antd';

import * as globalSelectors from 'redux/global/selectors';
import * as productFullViewSelector from 'pages/product-full-view/controllers/selectors';
import memberReducer from 'pages/company-profile/controllers/reducer';
import saga from 'pages/company-profile/controllers/saga';

import { useInjectSaga } from 'utils/common/injectSaga';
import { useInjectReducer } from 'utils/common/injectedReducers';

import {
  useGetBranding,
  useGetProductEnums,
  useGetProductFullView,
} from 'hooks';

import * as productServices from 'services/product';
import * as ssoProductServices from 'services/sso-product';
import * as ssoProductSelectors from 'pages/sso-product/controllers/selectors';
import { useIntl } from 'react-intl';
import messages from 'i18n/messages/product';
import {
  getPropertyByFieldFullPath,
  mappingProperties,
} from 'pages/minimum-requirements/components/modal/utils';
import { getAdditionalFieldData } from 'utils/minimumRequirementUtils';
import { useGetEntityProperties } from './entityHooks';
import { useSsoProductParams } from 'hooks/useSso';
import { defaultType } from 'pages/qa-spec/constant';
import { getQaPackagingTabDataQueryKey } from 'pages/qa-spec/components/qa-packaging/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryProductCompanyInfo } from 'common/queries/product-module';
import { keyGetProductItemModules } from 'pages/product-full-view/shared/hooks';
import { useGetQaSpecDataHeader } from 'pages/qa-spec/query/useGetQaSpecDataHeader';
import { useGetProductVersionList } from 'pages/product-history/hook';

const { Paragraph } = Typography;

export const useGetTargetMarketList = () => {
  const targetMarketList = useSelector(
    globalSelectors.selectProductTargetMarket()
  );

  return targetMarketList;
};

const useCreateProduct = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  useInjectReducer({ key: 'member', reducer: memberReducer });
  useInjectSaga({ key: 'member', saga });

  const {
    modalVisible,
    productDetail,
    isEdit,
    reloadPage,
    callApiCallback,
    memberId,
    productId,
  } = props;

  const [productData, setProductData] = useState(productDetail);

  const { handleRefetchProductFullView } = useGetProductFullView({ productId });
  const { refetchQaSpecDataHeader } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: false,
  });

  const [editBrandVisible, setEditBrandVisible] = useState(false);
  const [brandThumb, setBrandThumb] = useState();
  const [brandId, setBrandId] = useState(null);

  const [brick, setBrick] = useState();

  const [productCategory, setProductCategory] = useState(null);

  const [formMode, setFormMode] = useState(isEdit ? 'edit' : 'create');
  const [formLoading, setFormLoading] = useState(false);

  const { productEnums } = useGetProductEnums();
  const currentHierarchy = useSelector(
    productFullViewSelector.makeSelectCurrentHierarchy()
  );
  const targetMarketList = useSelector(
    globalSelectors.selectProductTargetMarket()
  );

  const { data: companyInfoState, refetch: refetchProductCompanyInfoState } =
    useQueryProductCompanyInfo({
      productId: +productId,
    });

  const {
    newSupplier,
    retailerId,
    supplierId,
    supplierName,
    projectId,
    productId: ssoProductId,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
  } = useSelector(ssoProductSelectors.selectBpSsoProductData()) ?? {};

  const queryClient = useQueryClient();
  const { getBranding } = useGetBranding();
  const { getEntityProperties } = useGetEntityProperties({
    entityType: 'Product',
    fetchOnMount: modalVisible,
  });

  const activeStatusWarningKey = 'status-active-warning';
  const currentBrandImgSrc = companyInfoState?.brandImage?.fileUrl;
  const brandImageId = companyInfoState?.brandImage?.id;

  const productTypeEnums = productEnums?.filter(
    (type) => type.enumName === 'ProductTypeEnum'
  );

  const { handleRefetchProductVersioning } = useGetProductVersionList({
    enabled: false,
  });

  const { isSsoProductView } = useSsoProductParams();

  const updateBrickCodeData = (brickCode) => {
    setBrick(brickCode);
  };

  const notifyError = (msg) => {
    notification.error({
      message: msg ? msg : 'Something went wrong!',
    });
  };

  const callApiAddProduct = (values) => {
    const brickCode = brick?.brickCode;
    const brickCodeName = brick?.brickCodeName;
    const visibility = values?.visibility ?? 'Private';
    const type = values?.type ?? defaultType;
    setFormLoading(true);

    const payload = {
      ...values,
      ...productCategory,
      brandId,
      brickCode,
      brickCodeName,
      visibility,
      memberId,
      type,
    };
    productServices
      .addProduct(payload)
      .then((res) => {
        const { isSuccess, data, message } = res;

        if (isSuccess) {
          setProductData(data);
          setTimeout(() => {
            reloadPage();
          }, 300);

          callApiSuccessCallback(data?.errorFields);
        } else {
          notifyError(message);
        }
      })
      .catch((err) => {
        notifyError();
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const callApiEditProduct = (ixoneIgnore) => {
    const values = form.getFieldsValue();

    const brickCode = brick?.brickCode;
    const brickCodeName = brick?.brickCodeName;

    const id = productData?.productId;
    const brandImageId = brandId ? brandId : values?.brandId;
    const visibility = values?.visibility ?? productData.visibility;
    const status = values?.status ?? productData.status;

    let payload = {
      ...values,
      ...productCategory,
      brandId: brandImageId,
      id,
      brickCode,
      brickCodeName,
      visibility,
      status,
      ixOneIgnore: ixoneIgnore,
    };

    setFormLoading(true);

    if (isSsoProductView) {
      payload = Object.assign(payload, {
        retailerId: parseInt(retailerId),
        supplierId: parseInt(supplierId),
        supplierName,
        projectId,
        productId: +ssoProductId || id,
      });
      if (newSupplier === '1') {
        payload = Object.assign(payload, {
          newSupplier: true,
          contactEmail,
          contactFirstName,
          contactLastName,
          contactPhoneNumber,
        });
      }
      ssoProductServices
        .bpSsoUpdateProductDetailsService(payload)
        .then(async (res) => {
          const { isSuccess, message, data } = res;
          if (isSuccess) {
            reloadPage();
            setFormLoading(false);
            callApiSuccessCallback(data?.errorFields);
          } else {
            notifyError(message);
            setFormLoading(false);
          }
        })
        .catch((err) => {
          notifyError();
          setFormLoading(false);
        });
    } else {
      productServices
        .updateProductDetail(payload)
        .then(async (res) => {
          const { isSuccess, message, data } = res;
          if (isSuccess) {
            reloadPage();
            queryClient.invalidateQueries({
              queryKey: getQaPackagingTabDataQueryKey(id),
            });
            queryClient.invalidateQueries({
              queryKey: keyGetProductItemModules(id),
            });
            refetchQaSpecDataHeader();
            handleRefetchProductVersioning();
            callApiSuccessCallback(data?.errorFields);
          } else {
            notifyError(message);
            resetFormState();
          }
        })
        .catch((err) => {
          notifyError();
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  const submitForm = (ixoneIgnore) => {
    form.validateFields().then((values) => {
      if (formMode === 'edit') {
        callApiEditProduct(ixoneIgnore);
      } else callApiAddProduct(values);
    });
  };

  const callApiSuccessCallback = (errorFields) => {
    const successMessage =
      formMode === 'edit' ? 'updateProductSuccess' : 'createProductSuccess';
    notification.success({
      message: intl.formatMessage(messages[successMessage]),
    });

    getBranding();
    handleResetBrandId();
    formMode === 'edit' && refetchProductCompanyInfoState();
    callApiCallback();

    handleRefetchProductFullView();
    if (errorFields?.length > 0) showActiveStatusWarning(errorFields);
  };

  const handleResetBrandId = () => {
    setBrandThumb('');
    setBrandId('');
  };

  const initFormState = () => {
    if (formMode === 'edit') {
      const { category1, category2, category3, category4 } = productData ?? {};

      const formValues = {
        ...productData,
        category1Id: category1?.categoryName,
        category2Id: category2?.categoryName,
        category3Id: category3?.categoryName,
        category4Id: category4?.categoryName,
      };

      form.setFieldsValue(formValues);

      setBrandThumb(currentBrandImgSrc);
      setBrandId(brandImageId);
      setBrick({
        brickCode: productData?.brickCode,
        brickCodeName: productData?.brickCodeName,
      });
      setProductCategory({
        category1Id: category1?.categoryId,
        category2Id: category2?.categoryId,
        category3Id: category3?.categoryId,
        category4Id: category4?.categoryId,
      });

      return;
    }

    //* create product
    form.setFieldsValue({ status: 'Pending', visibility: 'Only If Shared' });
  };

  const resetFormState = () => {
    form.resetFields();
    handleResetBrandId();
    setBrick('');
  };

  const updateFormMode = () => {
    setFormMode(isEdit ? 'edit' : 'create');
  };

  const updateProductData = () => {
    productDetail && setProductData(productDetail);
  };

  const showActiveStatusWarning = (errorFields) => {
    const entityProperties = getEntityProperties();

    const successMessage =
      formMode === 'edit' ? 'updateProductSuccess' : 'createProductSuccess';

    const warningContent = (
      <Paragraph>
        {intl.formatMessage(messages[successMessage])}{' '}
        {intl.formatMessage(messages.updateProductExceptStatus)}:
      </Paragraph>
    );

    const propertiesErrorField = errorFields.map((errorField, index) => {
      const additionalField = getAdditionalFieldData(errorField, 'Product');
      const displayName = additionalField
        ? {
            ...additionalField,
            moduleName: 'additionalData',
            moduleDisplayName: 'Additional Data',
            displayName: additionalField?.fieldDisplayName,
          }
        : getPropertyByFieldFullPath({
            properties: entityProperties,
            fieldFullPath: errorField,
          });

      return displayName;
    });

    const groupRequiredField = mappingProperties(propertiesErrorField);

    const FieldList = (
      <ul className='add-product__active-status-waring-list scroller'>
        {groupRequiredField.map((properties, index) => {
          return (
            <div key={index}>
              <p className='active-status-waring-list__title'>
                {properties.moduleDisplayName}:
              </p>
              {properties?.moduleProperties.map((item, index) => {
                return (
                  <li
                    style={{
                      paddingLeft: 10,
                      fontWeight: 600,
                      lineHeight: 1.25,
                      marginBottom: 5,
                    }}
                    key={index}
                  >
                    {item?.fullPathDisplayName || item?.displayName}
                  </li>
                );
              })}
            </div>
          );
        })}
      </ul>
    );

    const CloseBtn = (
      <Button
        type='primary'
        onClick={() => notification.close(activeStatusWarningKey)}
      >
        OK
      </Button>
    );
    notification.warn({
      message: warningContent,
      description: FieldList,
      duration: 0,
      key: activeStatusWarningKey,
      btn: CloseBtn,
    });

    form.setFields([
      {
        name: 'status',
        errors: [intl.formatMessage(messages.updateStatusActiveError)],
      },
    ]);
  };

  useEffect(() => {
    initFormState();
  }, [modalVisible]);

  useEffect(() => {
    updateFormMode();
  }, [isEdit]);

  useEffect(() => {
    updateProductData();
  }, [JSON.stringify(productDetail)]);

  useEffect(() => {
    //* After editing product brand by creating new brand or editing current brand
    //* productFullViewActions.getProductCompanyInfo are called to reload product brand info
    //* this effect will update brandThumb with new product brand info thumb
    if (formMode === 'edit') {
      setBrandThumb(currentBrandImgSrc);
    }
  }, [currentBrandImgSrc, formMode]);

  return {
    submitForm,
    resetFormState,
    formLoading,
    form,
    productData,
    formMode,
    targetMarketList,
    productEnums,
    currentHierarchy,
    brick,
    updateBrickCodeData,
    handleResetBrandId,
    setBrandId,
    editBrandVisible,
    setEditBrandVisible,
    brandThumb,
    setBrandThumb,
    productTypeEnums,
    productCategory,
    setProductCategory,
  };
};

export default useCreateProduct;
