export const GET_MEMBER_MAINTENANCE = '/api/member-manager/member-manager-grid';
export const GET_MEMBER_MAINTENANCE_DISTINCT =
  '/api/member-manager/member-manager-grid/distinct';
export const GET_USER_INFO_GRID = '/api/member-manager/user-info-grid';
export const GET_USER_INFO_GRID_DISTINCT =
  '/api/member-manager/user-info-grid/distinct';
export const GET_ALL_COMMUNICATION_TEMPLATES =
  '/api/member-manager/get-all-communication-templates';

export const GET_MEMBER_MAINTENANCE_SHORT_DETAIL =
  '/api/member-manager/member-manager-short-detail';
export const GET_MEMBER_MAINTENANCE_PUBLIC_CONTACT =
  '/api/MemberProfile/get-member-contacts-preview';
export const EDIT_MEMBER_MANAGEMENT =
  '/api/member-manager/edit-member-components-setting';
export const MEMBER_MAINTENANCE_DOWNLOAD =
  '/api/member-manager/member-manager-grid/download';
export const USER_MAINTENANCE_DOWNLOAD =
  '/api/member-manager/user-info-grid/download';

//? execution template
export const GET_PREVIEW_TEMPLATE_FOR_EXECUTION =
  '/api/member-manager/get-preview-execution-template';
export const EXECUTE_COMMUNICATION_TEMPLATE =
  '/api/member-manager/execute-communication-template';

export const GET_TEMPLATE_CREATE_MEMBER =
  '/api/member-manager/template-create-members';
export const GET_TEMPLATE_EDIT_MEMBER =
  '/api/member-manager/template-edit-members';
export const GET_TEMPLATE_CREATE_USER =
  '/api/member-manager/template-create-users';
export const GET_TEMPLATE_EDIT_USER = '/api/member-manager/template-edit-users';

export const ADD_NEW_USER = '/api/member-manager/add-new-user';
export const DELETE_USERS = '/api/member-manager/delete-users';
export const GET_USER_DETAIL = '/api/member-manager/get-user-detail';
export const EDIT_USER = '/api/member-manager/edit-user';
export const RESET_USER_PASSWORD = '/api/member-manager/reset-user-password';

export const MEMBER_MAINTAIN_VALIDATE_BULK_TEMPLATE =
  '/api/member-manager/validate-member-bulk-load-sheets';
export const MEMBER_MAINTAIN_EXECUTE_BULK_TEMPLATE =
  '/api/member-manager/execute-member-bulk-load-sheets';

export const MOVE_GLN_TO_MEMBER = '/api/MemberProfile/move-glns-to-member';

export const GET_MEMBER_SECURITY_ACCESS =
  '/api/member-manager/get-member-security-access';
export const MOVE_USER_TO_MEMBER = '/api/member-manager/move-user-to-member';

export const CREATE_SUPPLIER =
  '/api/member-manager/create-supplier-for-product-creation';
export const GET_SUPPLIER_FOR_PRODUCT_CREATION =
  '/api/member-manager/get-suppliers-for-product-creation';
export const UNLOCK_USERS = '/api/member-manager/unlock-user-account';
export const MOVE_ALL_TO_MEMBER = '/api/member-manager/move-entities-to-member';
