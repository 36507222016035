import React, { useState, useEffect } from 'react';

import { Typography } from 'antd';
import { CustomNotification, Form, WithLoading } from 'common/components';
import { BasicFormItemList } from 'common/components/form/basic-form';
import { EditContainer } from 'pages/qa-spec/components';
import AddAssetPimModal from 'pages/product-full-view/components/modal/add-asset-pim-modal';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useAllowRefetchQuery } from 'hooks';
import {
  useGetQaProductContainFormDefine,
  useGetSnapshotQaProductContains,
} from './hooks';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';
import {
  useGetQaProductContainTabData,
  useSaveQaProductContainTab,
} from './queries';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import './QaSpecProductContain.less';

const PALM_OIL_CERTIFICATE_TYPE = 'Palm Oil';

const QaSpecProductContain = (props) => {
  const [formInst] = Form.useForm();

  const { productId } = props;

  const [uploadDocumentModalVisible, setUploadDocumentModalVisible] =
    useState(false);
  const [uploadModalTitle, setUploadModalTitle] = useState('');

  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedToSupplier = checkAssignProductToSupplier();
  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const handleCheckRequireFields = (fieldName) => {
    if (!isProductAssignedToSupplier) return false;

    return requiredFields?.includes(
      `qaSpecification.qaSpecProductContains.${fieldName}`
    );
  };

  const { allowRefetch, handleSetAllowRefetch } = useAllowRefetchQuery();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const {
    data,
    refetch: fetchData,
    isLoading: loading,
  } = useGetQaProductContainTabData(
    { productId },
    { enabled: allowRefetch, retry: false }
  );

  const { data: snapshotData, isLoading: isLoadingSnapshotProductContains } =
    useGetSnapshotQaProductContains({
      enabled: !!productId && isToggleSnapshot,
      productId,
    });

  const supplierModifiedData = {
    ...data,
    contains: data?.contains || [],
    doesNotContain: data?.doesNotContain || [],
  };

  const formDefine = useGetQaProductContainFormDefine({
    handleToggleUploadDocumentModal: () => {
      handleToggleUploadDocumentModal();
    },
    handleCheckRequireFields,
    setUploadModalTitle,
    snapshotData,
    supplierModifiedData,
  });

  const handleSaveQaProductContainTab = useSaveQaProductContainTab();
  const { isLoading: saveLoading } = handleSaveQaProductContainTab;

  const handleToggleUploadDocumentModal = () => {
    setUploadDocumentModalVisible((prev) => !prev);
  };

  const handleSave = async (toggleEditMode) => {
    try {
      const formData = await formInst.validateFields();

      handleSaveQaProductContainTab.mutate(
        {
          productId,
          formData,
        },
        { onSuccess: () => toggleEditMode() }
      );
    } catch (error) {
      const message = error?.errorFields?.length
        ? 'Please resolve errors in the Product Contains tab.'
        : 'Failed to save';

      CustomNotification.error(message);
    }
  };

  const handleCancel = () => {
    formInst.resetFields();
    formInst.setFieldsValue(data);
  };

  const resetPalmOilCertified = (allFieldsValue) => {
    const palmOilValue = allFieldsValue.palmOil;

    if (!palmOilValue || palmOilValue === 'No')
      formInst.setFieldsValue({ palmOilCertified: null });
  };

  const onFormChange = (changedValues, allFieldsValue) => {
    resetPalmOilCertified(allFieldsValue);
  };

  const onUploadSuccess = () => {
    handleToggleUploadDocumentModal();
    formInst.setFieldsValue({ isAssetLink: true });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data) {
      formInst.setFieldsValue(supplierModifiedData);
      const newData = {
        ...data,
        contains: data?.contains || [],
        doesNotContain: data?.doesNotContain || [],
        isAssetLink: data?.isAssetLink ? data?.isAssetLink : null,
      };

      formInst.setFieldsValue(newData);
    }
  }, [JSON.stringify(data)]);

  return (
    <>
      <Form
        className='qa-product-contain'
        form={formInst}
        style={{ paddingRight: 5 }}
        layout='horizontal'
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        onValuesChange={onFormChange}
      >
        <WithLoading loading={loading || saveLoading}>
          <EditContainer
            type={EDIT_SECTION.QA_PRODUCT_CONTAINS}
            productId={productId}
            handleCancel={handleCancel}
            handleSave={handleSave}
            float
          >
            {(isEdit) => {
              handleSetAllowRefetch(isEdit);

              return (
                <EditTabSectionWrapper.CheckerWrapper
                  isEdit={isEdit}
                  type={EDIT_SECTION.QA_PRODUCT_CONTAINS}
                >
                  <div>
                    <BasicFormItemList
                      allowEdit={isEdit}
                      formDefine={formDefine}
                      labelFn={labelFn}
                      isLoadingSnapshot={isLoadingSnapshotProductContains}
                    />
                  </div>
                </EditTabSectionWrapper.CheckerWrapper>
              );
            }}
          </EditContainer>
        </WithLoading>
      </Form>
      {uploadDocumentModalVisible && (
        <AddAssetPimModal
          onCloseModal={handleToggleUploadDocumentModal}
          addType={'Certificate'}
          certificateType={PALM_OIL_CERTIFICATE_TYPE}
          getImagery={[]}
          qaSpec={true}
          getDocument={onUploadSuccess}
          title={uploadModalTitle}
          noCapitalize={true}
        />
      )}
    </>
  );
};

const labelFn = (labelText) => {
  return (
    <Typography.Text strong ellipsis={{ tooltip: labelText }}>
      {labelText && labelText}
    </Typography.Text>
  );
};

export default QaSpecProductContain;
