import moment from 'moment';
import { LAST_SEVEN_DAY, LAST_THIRTY_DAY, YESTERDAY } from './constant';
import { formatMDY, formattedFullTime } from 'utils/formatDate';

export const removeEmptyHistory = (data) => {
  const removeEmptyFieldChanges = data?.map((item) => {
    return {
      ...item,
      entitySnapshotChanges: item?.entitySnapshotChanges?.filter(
        ({ changes }) => changes?.length > 0
      ),
    };
  });

  const removedEmptyHistory = removeEmptyFieldChanges.filter((item) => {
    return item?.entitySnapshotChanges?.length > 0;
  });
  return removedEmptyHistory;
};

export const getMomentLocalDay = (dateCreated) => {
  const fullTimeFormatted = formattedFullTime(dateCreated);
  const localDate = formatMDY(fullTimeFormatted);

  return moment(localDate);
};

export const generateDateRanges = () => {
  const now = moment();

  const today = now.clone().startOf('day');

  const yesterday = now.clone().subtract(YESTERDAY, 'days');

  const sevenDayAgo = now
    .clone()
    .subtract(LAST_SEVEN_DAY, 'days')
    .startOf('day');

  const thirtyDayAgo = now.clone().subtract(LAST_THIRTY_DAY, 'days');

  return {
    now,
    today,
    yesterday,
    sevenDayAgo,
    thirtyDayAgo,
  };
};

export const isDateNow = (momentDate) => {
  const { today } = generateDateRanges();

  return momentDate.isSame(today, 'd');
};

export const isTheDayBefore = (momentDate) => {
  const { yesterday } = generateDateRanges();

  return momentDate.isSame(yesterday, 'd');
};

export const isWithinSevenDay = (momentDate) => {
  const { sevenDayAgo } = generateDateRanges();

  return momentDate.isSameOrAfter(sevenDayAgo);
};

export const isWithinThirtyDay = (momentDate) => {
  const { thirtyDayAgo } = generateDateRanges();

  return momentDate.isSameOrAfter(thirtyDayAgo);
};

export const convertArrayToString = (list) => {
  if (Array.isArray(list) && list.length > 0) {
    return list.join(', ');
  }
  return null;
};

const checkSearchMatchNodeValue = (node, searchValue) => {
  return (
    node?.data?.propertyDisplayName
      ?.toLowerCase()
      .includes(searchValue?.toLowerCase()) ||
    node.data?.fieldFullPath?.toLowerCase()?.includes(searchValue.toLowerCase())
  );
};

export const filterTreeData = (nodes, searchValue) => {
  return nodes
    .map((node) => {
      if (node.children.length) {
        const filteredChildren = filterTreeData(node.children, searchValue);

        return {
          ...node,
          children: filteredChildren,
        };
      } else if (checkSearchMatchNodeValue(node, searchValue)) {
        return node;
      }
      return null;
    })
    .filter(Boolean);
};

export const getParentKeys = (tree) => {
  let parentKeys = [];
  const checkIsParent = (node) => {
    if (node.children && node.children.length > 0) {
      parentKeys.push(node.key);
      node.children.forEach((child) => checkIsParent(child));
    }
  };
  tree.forEach((node) => checkIsParent(node));
  return parentKeys;
};

export const filterTreeNode = (inputValue, treeNode) => {
  const inputValueSearch = inputValue.toLowerCase().trim();

  const isFieldNameMatched = treeNode.data?.fieldFullPath
    ?.toLowerCase()
    ?.includes(inputValueSearch);
  const isDisplayNameMatched = treeNode.data?.propertyDisplayName
    ?.toLowerCase()
    ?.includes(inputValueSearch);

  return isFieldNameMatched || isDisplayNameMatched;
};
