import React from "react";

import { Typography, Avatar, Row, Col } from "antd";

import classnames from "classnames";

import "./InputAutoHintList.less";

const { Text } = Typography;

const InputAutoHintList = (props) => {
  const { hintList = [], selectedHint, handleClickHintItem } = props;

  const HintItem = ({ suggestData, idx }) => (
    <Row
      className="input-auto-hint-list__item"
      className={classnames("input-auto-hint-list__item", {
        "input-auto-hint-list__item--selected": selectedHint === idx,
      })}
      onClick={() => handleClickHintItem && handleClickHintItem(idx)}
    >
      <Col flex="35px">
        <Avatar src={suggestData?.avatar} />
      </Col>
      <Col className="input-auto-hint-list__item-text">
        <Text strong>{suggestData?.name}</Text>
        <Text ellipsis>{suggestData?.email}</Text>
      </Col>
    </Row>
  );

  return (
    <div className="input-auto-hint-list">
      {hintList.map((itemData, idx) => (
        <>
          <HintItem suggestData={itemData} idx={idx} />
          {/* {idx === hintList.length - 1 && <LoadingOutlined />} */}
        </>
      ))}
    </div>
  );
};

export default InputAutoHintList;
