import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { UploadOutlined } from '@ant-design/icons';
import { notification, Modal } from 'antd';

import { ButtonSmallIcon, UploadFile } from 'common/components';
import ErrorModal from 'common/components/product-publication-setup/ErrorModal.js';

import {
  usePermissionEditSharedProduct,
  useCheckIsSuperAdmin,
  useCheckPermissions,
} from 'hooks';

import { PERMISSION_EDIT_PRODUCTS } from 'static/Permission';
import { UPLOAD_MAX_SIZE } from 'static/Constants';

import {
  IMPORT_PRODUCT_VIA_SPREADSHEET,
  IMPORT_TDC_PRODUCT_VIA_SPREADSHEET,
} from 'services/product/endpoints';

import * as brandingActions from 'redux/branding/actions';

import messagesProduct from 'i18n/messages/product';

const UploadProductTemplateFromMember = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [updateType, setUploadType] = useState('pim');

  const isSuperAdmin = useCheckIsSuperAdmin();

  const hasPermissionEditProducts = useCheckPermissions(
    PERMISSION_EDIT_PRODUCTS
  );

  const hasPermissionEditSharedProduct = usePermissionEditSharedProduct();

  const isAllowActionsPim =
    isSuperAdmin || hasPermissionEditProducts || hasPermissionEditSharedProduct;

  const onClickUploadTemplate = (type) => () => {
    setVisibleModal(true);
    setUploadType(type);
  };

  return (
    <>
      {visibleModal && (
        <ModalUploadTemplateProduct
          visibleModal={visibleModal}
          turnOffModal={() => setVisibleModal(false)}
          type={updateType}
          memberId={props.memberId}
        />
      )}
      <ButtonSmallIcon
        className='button-item-dropdown'
        icon={<UploadOutlined />}
        label={'Upload Template'}
        onClick={onClickUploadTemplate('pim')}
        disabled={!isAllowActionsPim}
      />
    </>
  );
};

const ModalUploadTemplateProduct = (props) => {
  const { visibleModal, turnOffModal, type, memberId } = props;
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    if (!visibleModal) setErrorList([]);
  }, [visibleModal]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleResponseUpload = (response) => {
    const successMessage =
      type === 'tdc'
        ? messagesProduct.uploadTdcSuccess
        : messagesProduct.createProductSuccess;

    if (response?.data) {
      if (response?.data?.isSuccess) {
        notification.success({
          message: intl.formatMessage(successMessage),
        });

        turnOffModal();

        if (type === 'tdc') return;

        dispatch(brandingActions.getBrandingNoLoading());
      } else {
        if (response?.data?.errors) {
          setErrorList(response?.data?.errors);
        }
      }
    }
  };

  const apiUrl =
    type === 'tdc'
      ? IMPORT_TDC_PRODUCT_VIA_SPREADSHEET
      : IMPORT_PRODUCT_VIA_SPREADSHEET;

  const nameFormData = type === 'tdc' ? 'file' : 'TemplateFile';

  return (
    <Modal
      width={'50vw'}
      bodyStyle={{ height: '50vh' }}
      title='Upload Template Product'
      visible={visibleModal}
      onCancel={turnOffModal}
      closable={true}
      maskClosable={false}
      footer={null}
      destroyOnClose
      centered
    >
      <div className='action-product__modal'>
        <UploadFile
          uploadAPL={true}
          manualUpload={true}
          isBase64={false}
          showMultiple={false}
          showXlsTemplate={false}
          apiUrl={apiUrl}
          nameFormData={nameFormData}
          showUploadSuccessMsg={false}
          showServerMessage={false}
          notShowMessage={true}
          getResponse={(response) => handleResponseUpload(response)}
          maxSize={UPLOAD_MAX_SIZE.GENERAL}
          additionalBodyPayload={{
            memberId: memberId,
          }}
          supportTypes={['xlsx', 'xls']}
        />
        <div className='action-product__modal--error-text'>
          {errorList && errorList.length > 0 && (
            <ErrorModal errorList={errorList} title={'APL Errors'} />
          )}
        </div>
      </div>
    </Modal>
  );
};

UploadProductTemplateFromMember.propTypes = {};

export default UploadProductTemplateFromMember;
