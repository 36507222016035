import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Avatar } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import { CustomNotification } from 'common/components';
import VideoToolBtnWrap from '../button/VideoToolBtnWrap';

import soundSrc from 'assets/sound/caller_ringsound.mp3';

import classnames from 'classnames';

import './CallerRington.less';

const timeMaxOut = 25000;

const CallerRington = (props, ref) => {
  const { isCallAccepted, hangup, callData } = props;

  const refRing = useRef();

  const refTimeDial = useRef(timeMaxOut);
  const refInterval = useRef();

  const threadItem = callData?.threadItem;

  const handleCallTimeout = () => {
    hangup && hangup();
    refRing.current && refRing.current.pause();
    clearInterval(refInterval.current);
    refTimeDial.current = timeMaxOut;
    CustomNotification.info('All users are busy!');
  };

  const endRing = () => {
    if (refTimeDial.current < timeMaxOut - 2000) {
      hangup && hangup();
      stopCallerDial();
    }
  };

  const stopCallerDial = () => {
    refRing.current && refRing.current.pause();
    clearInterval(refInterval.current);
    refTimeDial.current = timeMaxOut;
  };

  const startCallerDial = () => {
    refRing.current.loop = true;
    refRing.current.play();

    refInterval.current = setInterval(function () {
      if (refTimeDial.current <= 0) {
        handleCallTimeout();
      } else {
        refTimeDial.current = refTimeDial.current - 1000;
      }
    }, 1000);
  };

  const handleCallerRington = (accepted) => {
    if (!accepted) {
      startCallerDial();
    } else {
      stopCallerDial();
    }
  };

  useEffect(() => {
    handleCallerRington(isCallAccepted);
  }, [isCallAccepted]);

  useEffect(() => {
    return () => {
      clearInterval(refInterval.current);
      refTimeDial.current = timeMaxOut;
      refRing.current && refRing.current.pause();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    stopCallerDial,
  }));

  if (isCallAccepted) return null;

  return (
    <div className='caller-rington'>
      <Row className='caller-rington__top'>
        <Col xs={24}>
          <div
            className={classnames(
              'caller-rington__top-avatar-wrapper',
              'ani--ringing',
              'ani--iter-inf',
              'ani--easy-in-out',
              'ani--fill-forward'
            )}
          >
            <Avatar
              className={classnames('caller-rington__top-avatar')}
              src={threadItem?.avatarUrl}
            >
              {threadItem?.name?.charAt(0)}
            </Avatar>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className='caller-rington__btn-wrapper'>
          <VideoToolBtnWrap
            className={classnames(
              'caller-rington__btn',
              'caller-rington__btn--hang'
            )}
            onClick={endRing}
          >
            <PhoneOutlined />
          </VideoToolBtnWrap>
        </Col>
      </Row>
      <audio ref={refRing} src={soundSrc} />
    </div>
  );
};

CallerRington.propTypes = {
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isCallAccepted: PropTypes.bool,
  hangup: PropTypes.func,
};

export default forwardRef(CallerRington);
