import React, { useEffect, useState } from 'react';
import { List, Avatar } from 'antd';
import { StyledModal } from 'common/components';

const ModalMoveProductResult = (props) => {
  const {
    visible,
    moveFailProductList,
    productList,
    closeMoveProductResultModal,
  } = props;

  const [failProductList, setFailProductList] = useState([]);

  useEffect(() => {
    //* get fail product list only
    const getFailProductList = () => {
      if (productList.length > 0 && moveFailProductList?.length > 0) {
        const nextFailProductList = productList.filter((productItem) =>
          moveFailProductList.includes(productItem?.id)
        );

        if (nextFailProductList?.length > 0) {
          setFailProductList(nextFailProductList);
        }
      }
    };

    getFailProductList();
  }, [productList, moveFailProductList]);

  return (
    <StyledModal
      title='Failed moving product(s) list'
      visible={visible}
      onCancel={closeMoveProductResultModal}
      onOk={closeMoveProductResultModal}
      cancelButtonProps={{ hidden: true }}
      centered
      zIndex={1200}
    >
      <List
        dataSource={failProductList}
        renderItem={(productItem) => {
          const productGtinOrUpc12Content = productItem?.gtin
            ? `GTIN - ${productItem?.gtin}`
            : productItem?.upc12
            ? `UPC12 - ${productItem?.upc12}`
            : 'GTIN/UPC12 is not available';

          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={productItem?.thumbnail} />}
                title={
                  <a href={'/product/' + productItem?.id} target='_blank'>
                    {productItem?.productName}
                  </a>
                }
                description={productGtinOrUpc12Content}
              />
            </List.Item>
          );
        }}
      ></List>
    </StyledModal>
  );
};

export default ModalMoveProductResult;
