import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SisternodeOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import * as actions from 'redux/global/actions';

import Messages from 'i18n/messages/home';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';

const AddFolderToNewAPL = () => {
  const dispatch = useDispatch();

  const addToAPLClick = () => {
    dispatch(actions.toggleAddFolderToAPL());
  };

  const folderList = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const isFolderHaveProduct =
    folderList.filter((item) => item.productCount > 0).length === 0;

  return (
    <ButtonSmallIcon
      icon={<SisternodeOutlined style={{ height: 26, marginTop: 4 }} />}
      label={Messages.addAPL}
      className='button-item-dropdown'
      disabled={isFolderHaveProduct}
      onClick={addToAPLClick}
    />
  );
};

export default AddFolderToNewAPL;
