import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Input, Skeleton, Progress, Popover } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useSendEmailMessage } from './utils';
import { UploadFile } from 'common/components';
import { v4 as uuidv4 } from 'uuid';
import { AttachList } from '../../../message/components';

import userSelectors from 'redux/user/selectors';
import classnames from 'classnames';

import * as globalSelectors from 'redux/global/selectors';
import * as emailActions from '../../controller/actions';
import * as emailSelector from '../../controller/selectors';

import { SwitchAccount } from '../../../message/components/fields/SwitchAccount';
import {
  attachmentActions,
  useUploadChatFiles,
} from '../../../message/components/fields/hooks';
import { useSaveAccountChat } from '../../../message/components/fields/hooks';
import { checkNotAllowSendFiles } from '../../../message/components/fields/utils';
import { mapInnerHtmlToText } from '../../mapper/mapInnerHtmlToText';
import usePrevious from 'hooks/usePrevious';

import useExitDelay from 'hooks/useExitDelay';
import { MAXIMUM_CHAT_MESSAGE_LENGTH, UPLOAD_MAX_SIZE } from 'static/Constants';

import './EmailInputBar.less';

const EmailInputBar = (props) => {
  const {
    value: emailText,
    onChangeMailInput,
    getAttachFile,
    attachList,
    disabledFile,
    show,
    threadId,
    groupUsers,
    uploadProgress,
    isUploading,
    fileId,
    attachmentState,
    attachmentDispatch,
    warningRef,
    setAttachList,
    warningMessage,
  } = props;

  const [showAttachList, setShowAttachList] = useState(false);
  const [inputHeight, setInputHeight] = useState(false);

  const [isHaveMaxFileWarning, kickoffWarning] = useExitDelay(3000);

  const refUpload = useRef();
  const refInput = useRef();

  const dispatch = useDispatch();

  const hubConnection = useSelector(globalSelectors.selectSignalRConnection());
  const accountSend = useSelector(emailSelector.selectEmailAccount());
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const handleChangeInput = (e) => {
    const innerText = e.target.innerText;
    const innerHTML = e.target.innerHTML;
    const textEmail =
      innerHTML?.length <= MAXIMUM_CHAT_MESSAGE_LENGTH
        ? innerHTML
        : innerText.substring(0, MAXIMUM_CHAT_MESSAGE_LENGTH);
    onChangeMailInput && onChangeMailInput(textEmail);
  };

  useSendEmailMessage(hubConnection, dispatch);

  const [senderId, setSenderId] = useState(null);

  const handleRemoveFileId = (fileUid) => {
    attachmentDispatch({
      type: attachmentActions.REMOVE_ATTACHMENT,
      fileUid,
    });
  };

  const clearAttachment = () => {
    refUpload.current && refUpload.current.removeAllFile();
    setAttachList([]);
    attachmentDispatch({ type: attachmentActions.RESET });
  };

  useSaveAccountChat({
    isInEmail: true,
    groupUsers,
    dispatch,
    setSenderId,
    userId: userInfo?.id,
    memberId: userInfo?.member?.id,
    isEnabled: true, // fix cache data from redux, maybe it effect email chat
    // isEnabled: !accountSend, // prevent first run not allow when accountSend has data,
  });

  const prevAttachList = usePrevious(attachmentState.attachment);

  //? Need to update later
  useUploadChatFiles({
    threadId: threadId,
    attachList: attachmentState.attachment,
    prevAttachList,
    attachmentState,
    dispatch: attachmentDispatch,
    isEmail: true,
  });

  const handleClickSend = () => {
    const procText = mapInnerHtmlToText(emailText);
    const { attachmentIds, attachment } = attachmentState;

    // 0: text, 1: email, 2: file, 3: image, 4: rating
    const messageType = 1;

    if (!procText.trim() && attachment.length === 0) return;

    if (attachment.length > 6) {
      kickoffWarning();
      return;
    }

    if (attachment.length > 0 && procText.length !== 0 && !procText.trim()) {
      return;
    }

    if (hubConnection) {
      onChangeMailInput('');
      refInput.current.innerHTML = '';
      setInputHeight(false);

      const request = {
        uniqueId: uuidv4(),
        threadId: threadId,
        senderId: accountSend?.senderId,
        messageType,
        messageText: procText,
        attachmentIds,
      };

      try {
        dispatch(
          emailActions.sendEmailMessage({
            ...request,
            sendTime: Date.now(),
            status: 'loading',
          })
        );
        hubConnection.invoke('SendMessage', request);
      } catch (error) {
        console.log(error);
      }
      refUpload.current.removeAllFile();
    }
    clearAttachment();
  };

  const handleSendMyContact = async (messageText) => {
    if (hubConnection) {
      const request = {
        uniqueId: uuidv4(),
        threadId: threadId,
        senderId: accountSend?.senderId,
        messageType: 2,
        messageText: messageText,
      };

      try {
        dispatch(
          emailActions.sendEmailMessage({
            ...request,
            sendTime: Date.now(),
            status: 'loading',
          })
        );
        hubConnection.invoke('SendMessage', request);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAttachFile = (e) => {
    if (refUpload.current) {
      refUpload.current.handleClickUpload();
    }
  };

  const onSelectAttachFile = (uniqueFilesState) => {
    getAttachFile && getAttachFile(uniqueFilesState);
  };

  const handleDropFileTo = (e) => {
    e.stopPropagation();
    e.preventDefault();
    var files = e.dataTransfer.files;
    refUpload.current && refUpload.current.handleAddFileOuter(files);
  };

  const handleDragFileOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const isAttachBtnAvail = () => {
    if (emailText === '') return true;
    if (emailText !== '' && attachList?.length === 0) return false;
    if (attachList?.length > 0) return true;
    return false;
  };

  const toggleAttachList = useCallback(() => {
    setShowAttachList((state) => !state);
  }, []);

  const toggleInputHeight = useCallback(() => {
    setInputHeight((state) => !state);
  }, []);

  useEffect(() => {
    if (emailText === '') {
      setShowAttachList(false);
    }
  }, [emailText]);

  const styles = {
    icon: {
      fontSize: '19px',
    },
    showMoreIcon: {
      fontSize: '10px',
      lineHeight: '0.3cm',
    },
  };

  const content = <div className='warning-text'>{warningMessage}</div>;
  const notAllowSendFiles = checkNotAllowSendFiles(attachmentState.attachment);

  return (
    <>
      {show && (
        <>
          <div>
            <Popover
              content={content}
              trigger='click'
              ref={warningRef}
              overlayClassName='warning-message'
            >
              <Col span={24} />
            </Popover>
            <AttachList
              fileList={attachmentState?.attachment}
              refUpload={refUpload}
              handleRemoveFileId={handleRemoveFileId}
              onDrop={handleDropFileTo}
              isHaveMaxFileWarning={isHaveMaxFileWarning}
              isSocial={true}
            />
          </div>

          <Row className='email-input-bar' gutter={8}>
            <Col>
              {!disabledFile && (
                <>
                  <Button
                    className={classnames(
                      'btn-shadow input-btn',
                      'btn-shadow--green'
                    )}
                    onClick={handleAttachFile}
                  >
                    <LinkOutlined style={styles.icon} />
                  </Button>
                  <div style={{ visibility: 'hidden', position: 'absolute' }}>
                    <UploadFile
                      ref={refUpload}
                      onFileChange={onSelectAttachFile}
                      manualUpload
                      multiple
                      returnUniqueFiles
                    />
                  </div>
                </>
              )}
            </Col>

            <Col
              span={18}
              style={{ position: 'relative' }}
              onDragOver={handleDragFileOver}
            >
              <div
                className={classnames(
                  'email-input-bar__input-wrapper',
                  'scroller'
                )}
              >
                {emailText === '' && (
                  <div className='email-input-bar__placeholder'>
                    Type a message here
                  </div>
                )}
                <div
                  className={classnames('email-input-bar__input')}
                  ref={refInput}
                  contentEditable={true}
                  onInput={handleChangeInput}
                  onDrop={handleDropFileTo}
                ></div>
              </div>
            </Col>
            <Col style={{ float: 'right' }}>
              {accountSend ? (
                <span style={{ cursor: 'pointer' }}>
                  <SwitchAccount
                    handleClickSend={handleClickSend}
                    chatText={emailText}
                    groupUsers={groupUsers}
                    isInEmail={true}
                    disabled={isUploading}
                    notAllowSendFiles={notAllowSendFiles}
                    handleSendMyContact={handleSendMyContact}
                  />
                </span>
              ) : (
                <Skeleton.Avatar size={35} />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default React.memo(EmailInputBar);
