import { useQuery } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sleep } from 'utils/delay';
import { CustomNotification } from 'common/components';
import { getProductIngredients } from 'services/product';
import { saveProductIngredient } from 'services/product';
import { useInvalidateGroupDetail } from '../../shared/hooks';
import { queryKeyQaSpecIngredients } from 'pages/qa-spec/components/qa-ingredient/hook';

export const getKeyProductIngredients = (id) => {
  return ['overview/multiple-panels/get-ingredients', parseInt(id)];
};

export const useQueryProductIngredients = ({ id }) => {
  const { data, ...rest } = useQuery({
    queryKey: getKeyProductIngredients(id),
    queryFn: async () => {
      const { data } = await getProductIngredients({ productId: id });
      return data;
    },
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });

  return {
    data: data?.ingredientGroups || [],
    ...rest,
  };
};

export const useMutationIngredients = ({ id }) => {
  const queryClient = useQueryClient();
  const { handleSyncAllProductDetailGroup } = useInvalidateGroupDetail(id);

  const mutation = useMutation({
    mutationFn: saveProductIngredient,
    onSuccess: async () => {
      await sleep(1000);
      handleSyncAllProductDetailGroup();
      queryClient.invalidateQueries({
        queryKey: getKeyProductIngredients(id),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeyQaSpecIngredients(id),
      });
    },
  });

  return mutation;
};
