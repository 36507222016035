import React from 'react';

import { Input, InputNumber, Switch, Typography, Select } from 'antd';

import { WrapperSelect } from 'common/components';

import classnames from 'classnames';

import './PageEditorInput.less';

const typeDefine = ['text', 'number', 'switch'];
const typeDefault = [typeDefine];

const { Text } = Typography;
const { Option } = Select;

const PageEditorInput = (props) => {
  const {
    wrapClassName,
    label,
    children,
    type = typeDefault,
    className = '',
    ...restProps
  } = props;

  const mapToClassName = (defaultClass) => classnames(defaultClass, className);

  const InputRender = () => {
    switch (type) {
      case 'text':
        return <Input className={className} {...restProps} />;
      case 'number':
        return <InputNumber className={className} {...restProps} />;
      case 'switch':
        return (
          <Switch
            className={mapToClassName('page-editor-tool-input__switch')}
            {...restProps}
          />
        );
      case 'selection':
        const { options, ...selectionProps } = restProps;
        return (
          <WrapperSelect
            className={mapToClassName('page-editor-tool-input__selection')}
            {...selectionProps}
          >
            {options.map((option) => (
              <Option value={option?.value} key={option?.label}>
                {option?.label}
              </Option>
            ))}
          </WrapperSelect>
        );
      default:
        return null;
    }
  };
  return (
    <div className={`page-editor-tool-input ${wrapClassName}`}>
      {label && <Text className='page-editor-tool-input__label'>{label}</Text>}
      <div className='page-editor-tool-input__input-wrap'>
        {children ? children : InputRender()}
      </div>
    </div>
  );
};

export default PageEditorInput;
