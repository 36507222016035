import React, { useContext } from 'react';
import { GridContext } from '../../context/gridContext';

const HeaderCheckboxRender = (props) => {
  const gridContext = useContext(GridContext);

  const { urlGrid } = props;

  const handleClick = (e) => {
    const { onHeaderCheckboxClick } = gridContext;

    const firstRow = props.api.getDisplayedRowAtIndex(0);
    if (firstRow?.data === undefined) return;

    onHeaderCheckboxClick(e, props);
  };

  return (
    <span className={'ant-checkbox ' + urlGrid} onClick={handleClick}>
      <input type='checkbox' className='ant-checkbox-input ' value='' />
      <span className='ant-checkbox-inner'></span>
    </span>
  );
};

export default HeaderCheckboxRender;
