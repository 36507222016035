import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components';

import * as helpMaintenanceActions from 'pages/maintenance/controllers/actions';
import * as helpMaintenanceSelectors from 'pages/maintenance/controllers/selectors';

import * as helpMaintenanceService from 'services/helpMaintenance';

import Messages from 'i18n/messages/maintenance';
import { injectIntl } from 'react-intl';

const DeleteCategory = ({ selectedCategory, intl, isCreating }) => {
  const dispatch = useDispatch();

  const selectedCategories = useSelector(
    helpMaintenanceSelectors.selectSelectCategory()
  );

  const confirmDelete = () => {
    const isAllowDelete = checkAllowDelete(selectedCategories);

    if (isAllowDelete) {
      dialogFunction({
        type: 'warn',
        content: intl.formatMessage(Messages.areYouSureToDelete),
        onOk: callApiDelete,
      });
    } else {
      notification.warning({
        message: intl.formatMessage(Messages.notificationDelete),
      });
    }
  };

  const closePreviewCategoryPanel = () => {
    dispatch(helpMaintenanceActions.updateCategoryPanel(false));
  };

  const callApiDelete = async () => {
    const categoryIds = selectedCategory.map((category) => {
      return category.id;
    });

    try {
      const response = await helpMaintenanceService.deleteHelpCategories({
        categoryIds,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        dispatch(helpMaintenanceActions.changeSelectCategory([]));
        dispatch(helpMaintenanceActions.closeCategoryPanel());
        dispatch(
          helpMaintenanceActions.getCategoryGrid({
            pageIndex: 1,
            pageSize: 1000,
          })
        );
        showSuccessMessageDialog();
        closePreviewCategoryPanel();
      } else {
        callApiErrorHandler(message);
      }
    } catch (error) {
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  const isDisabled = selectedCategory.length === 0 || isCreating;

  return (
    <RibbonButton
      size='small'
      className={classnames({
        'help-category__ribbon-button--active': !isDisabled,
      })}
      icon={<DeleteOutlined />}
      label={Messages.deleteCategory}
      onClick={confirmDelete}
      disabled={isDisabled}
    />
  );
};

const checkAllowDelete = (categories) => {
  let isAllow = true;

  for (const category of categories) {
    if (category.totalOfPosts > 0) return false;
  }

  return isAllow;
};

DeleteCategory.propTypes = {
  selectedCategory: PropTypes.array,
  isCreating: PropTypes.bool,
};

export default injectIntl(DeleteCategory);
