import React from 'react';
import { Input, Select, Row, Col, Typography } from 'antd';
import { CodeSandboxOutlined } from '@ant-design/icons';

import { WrapperSelect } from 'common/components';

const { Paragraph } = Typography;
const { Option } = Select;
const options = [1, 2, 3, 4, 5];

const ProductPackageLevelInfo = (props) => {
  const {
    gtin,
    upc12,
    height,
    width,
    depth,
    weight,
    cube,
    pack,
    packageLevel,
  } = props.packageLevelInformation;
  return (
    <Row className='product-package-level__slick-slider-text'>
      <Col style={{ paddingLeft: '5px', paddingRight: '5px' }} span={24}>
        <Paragraph style={{ height: '30px', textAlign: 'center' }}>
          <CodeSandboxOutlined style={{ fontSize: '20px' }} />
        </Paragraph>
        <Input.Group compact>
          <WrapperSelect
            defaultValue={packageLevel}
            style={{ width: '100%', maxWidth: '140px', fontSize: '12px' }}
          >
            {options &&
              options.map((item, index) => {
                return (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                );
              })}
          </WrapperSelect>
        </Input.Group>
        <Paragraph ellipsis='true'>{gtin}&nbsp;</Paragraph>
        <Paragraph ellipsis='true'>{upc12}&nbsp;</Paragraph>
        <Paragraph ellipsis='true'>{height}&nbsp;</Paragraph>
        <Paragraph ellipsis='true'>{width}&nbsp;</Paragraph>
        <Paragraph ellipsis='true'>{depth}&nbsp;</Paragraph>
        <Paragraph ellipsis='true'>{weight}&nbsp;</Paragraph>
        <Paragraph ellipsis='true'>{cube}&nbsp;</Paragraph>
        <Paragraph ellipsis='true'>{pack}&nbsp;</Paragraph>
      </Col>
    </Row>
  );
};

export default ProductPackageLevelInfo;
