import * as types from './constants';

//* get grid columns
export const getEulaGridColumns = (gridName) => ({
  type: types.GET_EULA_MAINTENANCE_GRID_COLUMNS,
  gridName,
});

export const getEulaGridColumnsSuccess = (data) => ({
  type: types.GET_EULA_MAINTENANCE_GRID_COLUMNS_SUCCESS,
  payload: data,
});

export const getEulaGridColumnsFailure = () => ({
  type: types.GET_EULA_MAINTENANCE_GRID_COLUMNS_FAILURE,
});

export const createEula = (params) => ({
  type: types.CREATE_EULA,
  payload: params,
});

export const createEulaSuccess = (id) => ({
  type: types.CREATE_EULA_SUCCESS,
  id,
});

export const createEulaError = (error) => ({
  type: types.CREATE_EULA_ERROR,
  error,
});

export const cloneEula = (params) => ({
  type: types.CLONE_EULA,
  payload: params,
});

export const cloneEulaSuccess = (id) => ({
  type: types.CLONE_EULA_SUCCESS,
  id,
});

export const cloneEulaError = (error) => ({
  type: types.CLONE_EULA_ERROR,
  error,
});

export const resetSaveEula = () => ({
  type: types.RESET_SAVE_EULA,
});

export const enableEditEula = () => ({
  type: types.ENABLE_EULA_MAINTENANCE_EDIT,
});

export const disableEditEula = () => ({
  type: types.DISABLE_EULA_MAINTENANCE_EDIT,
});

export const enableCreateEula = () => ({
  type: types.ENABLE_EULA_MAINTENANCE_CREATE,
});

export const disableCreateEula = () => ({
  type: types.DISABLE_EULA_MAINTENANCE_CREATE,
});

export const confirmCloneEula = () => ({
  type: types.CONFIRM_CLONE_EULA_MAINTENANCE,
});


export const resetConfirmCloneEula = () => ({
  type: types.RESET_CONFIRM_CLONE_EULA_MAINTENANCE,
});



export const saveIdEula = (id) => ({
  type: types.SAVE_ID_EULA,
  data: id,
});

//* get grid data
export const getEulaList = ({ pageSize, pageNumber, search }) => ({
  type: types.GET_EULA_MAINTENANCE_LIST,
  pageSize: pageSize,
  pageIndex: pageNumber,
  search,
});

export const getEulaListSuccess = (data, total) => ({
  type: types.GET_EULA_MAINTENANCE_LIST_SUCCESS,
  payload: data,
  total,
});

export const getEulaListFailure = () => ({
  type: types.GET_EULA_MAINTENANCE_LIST_FAILURE,
});
export function updateIsDetailsViewEula(isDetailsView) {
  return {
    type: types.EULA_DISABLED_VIEW_MODE,
    isDetailsView,
  };
}

export const getEulaContentDetail = (params) => {
  return {
    type: types.GET_EULA_CONTENT_DETAIL,
    params,
  };
};
export function getEulaContentDetailSuccess(data) {
  return {
    type: types.GET_EULA_CONTENT_DETAIL_SUCCESS,
    data,
  };
}

export function getEulaContentDetailError(error) {
  return {
    type: types.GET_EULA_CONTENT_DETAIL_ERROR,
    error,
  };
}

//* toggle upload media box
export function toggleUploadEulaMedia(status) {
  return {
    type: types.TOGGLE_EULA_UPLOAD_MEDIA,
    status,
  };
}

//* get accepted user eula grid columns
export const getAcceptedUserEulaGridColumns = (gridName) => ({
  type: types.GET_ACCEPTED_USER_EULA_GRID_COLUMNS,
  gridName,
});

export const getAcceptedUserEulaGridColumnsSuccess = (columns) => ({
  type: types.GET_ACCEPTED_USER_EULA_GRID_COLUMNS_SUCCESS,
  columns,
});

export const getAcceptedUserEulaGridColumnsError = (error) => ({
  type: types.GET_ACCEPTED_USER_EULA_GRID_COLUMNS_ERROR,
  error,
});

export function getAcceptedUserEulaList(pageNumber, pageSize, search) {
  return {
    type: types.GET_ACCEPTED_USER_EULA_LIST,
    pageIndex: pageNumber,
    pageSize,
    'Search.SearchText': search,
  };
}

export function getAcceptedUserEulaListSuccess(acceptedUserEula, total) {
  return {
    type: types.GET_ACCEPTED_USER_EULA_LIST_SUCCESS,
    acceptedUserEula,
    total,
  };
}

export function getAcceptedUserEulaListError(error) {
  return {
    type: types.GET_ACCEPTED_USER_EULA_LIST_ERROR,
    error,
  };
}
