import { DrugFactHeading } from '.';
import DrugFactWarningItem from './DrugFactWarningItem';
import DrugFactSection from './DrugFactSection';

const DrugFactWarnings = ({ warnings }) => {
  if (!warnings?.length) return null;

  return (
    <DrugFactSection>
      <DrugFactHeading heading='Warnings' />
      {warnings.map((warningItem, index) => {
        const isLastWarningItem = index === warnings.length - 1;
        return (
          <div
            style={{
              backgroundColor:
                warningItem.hightLightText && warningItem.hightLightText,
            }}
          >
            <DrugFactWarningItem
              key={index}
              warningItem={warningItem}
              isLastWarningItem={isLastWarningItem}
            />
          </div>
        );
      })}
    </DrugFactSection>
  );
};

export default DrugFactWarnings;
