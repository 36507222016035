import React from 'react';

import { DoubleRightOutlined } from '@ant-design/icons';
import { RibbonButton } from 'common/components';

import Messages from 'i18n/messages/home';
import { injectIntl } from 'react-intl';

const MoveFolder = ({ selectedItems, disabled, onClick }) => {
  const isDisable = selectedItems?.length === 0 || disabled;

  return (
    <RibbonButton
      size='small'
      icon={<DoubleRightOutlined />}
      label={Messages.moveFolder}
      disabled={isDisable}
      onClick={onClick}
    />
  );
};

export default injectIntl(MoveFolder);
