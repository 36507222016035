import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './CropImage.less';

export const CropImage = (props) => {
  const {
    imgSrc,
    getRealTimeCropper,
    aspectRatio,
    wrapperStyle,
    cropperHeight,
    refCropper,
    ...restProps
  } = props;

  const [cropper, setCropper] = useState();

  const getCropDataCallback = useCallback(() => {
    if (!refCropper.current) return;

    const canvasData = refCropper.current.cropper.getCroppedCanvas();

    if (canvasData && getRealTimeCropper) {
      getRealTimeCropper(
        canvasData.toDataURL(),
        refCropper.current.cropper.getCropBoxData()
      );
    }
  }, []);

  const onCrop = (e) => {
    getCropDataCallback();
  };

  const onReady = (e) => {
    getCropDataCallback();
  };

  useEffect(() => {
    if (cropper) {
      getCropDataCallback();
    }
  }, [cropper, getCropDataCallback]);

  return (
    <div className='crop-image' style={wrapperStyle || {}}>
      <Cropper
        style={{ height: cropperHeight || 400, width: '100%' }}
        aspectRatio={aspectRatio}
        preview='.img-preview'
        src={imgSrc}
        viewMode={1}
        guides={true}
        minCropBoxHeight={100}
        minCropBoxWidth={100}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        cropend={onCrop}
        ref={refCropper}
        ready={onReady}
        {...restProps}
      />
    </div>
  );
};

Cropper.propTypes = {
  imgSrc: PropTypes.string,
  getCropperInstance: PropTypes.func,
  getRealTimeCropper: PropTypes.func,
  aspectRatio: PropTypes.number,
};

export default CropImage;
