import React from 'react';
import { RibbonButton } from 'common/components';
import { UserAddOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';
import { useState } from 'react';
import AssignMemberModal from 'pages/sso-approval/modal/AssignMemberModal';

const AssignMemberSsoApproval = ({ selectedItem, sectionDisabled }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const checkDisabled = () => {
    const status = selectedItem?.poolStatus;

    const statusDisabled = status && status?.toLowerCase() !== 'pending';

    return sectionDisabled || statusDisabled;
  };

  return (
    <>
      <RibbonButton
        icon={<UserAddOutlined />}
        label={Messages.assignMember}
        onClick={() => setVisibleModal(true)}
        disabled={checkDisabled()}
      />
      {visibleModal && (
        <AssignMemberModal
          selectedItem={selectedItem}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      )}
    </>
  );
};

export default AssignMemberSsoApproval;
