import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/maintenance';
import { FormOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

import { AddCuratedQueryControl } from 'pages/home/ribbon/components';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import { useCheckIsSuperMember } from 'hooks';

const EditCuratedQuery = () => {
  const [visible, setVisible] = useState(false);
  const selectedQueryDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const isSuperMember = useCheckIsSuperMember();

  const isExpiredQuery =
    selectedQueryDetail?.length > 0 &&
    selectedQueryDetail[0]?.curatedQueryShareStatus === 'Expired';

  const handleEditMarkup = () => {
    selectedQueryDetail?.length === 1 && setVisible(true);
  };



  return (
    <>
      <RibbonButton
        size='small'
        icon={<FormOutlined />}
        label={Messages.editCuratedQuery}
        onClick={handleEditMarkup}
        disabled={!isSuperMember || selectedQueryDetail?.length !== 1 || isExpiredQuery}
      />
      <AddCuratedQueryControl
        visible={visible}
        setVisible={setVisible}
        selectedQueryDetail={selectedQueryDetail}
        mode='edit'
      />
    </>
  );
};

export default EditCuratedQuery;
