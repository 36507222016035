import { Button, Col, Row } from 'antd';
import React from 'react';
import {
  PlusOutlined,
  PlusCircleOutlined,
  StopOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';

export const AddNewItem = (props) => {
  const {
    mode,
    text = 'Add New',
    onClickCancel,
    onClickAdd,
    onClickAddNew,
  } = props;

  const disabled = mode === 'edit';

  return (
    <div style={{ marginTop: 8 }}>
      {mode === 'add' ? (
        <Row gutter={4}>
          <Col span={12}>
            <Button
              style={{
                width: '100%',
                borderColor: '#f5222d',
                color: '#f5222d',
                height: 35,
              }}
              icon={<StopOutlined />}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type='primary'
              style={{
                width: '100%',
                height: 35,
              }}
              icon={<PlusCircleOutlined />}
              onClick={onClickAdd}
            >
              Add
            </Button>
          </Col>
        </Row>
      ) : (
        <Button
          icon={<PlusOutlined />}
          style={{
            width: '100%',
            borderColor: !disabled && '#0070cc',
            color: !disabled && '#0070cc',
            height: 35,
          }}
          disabled={disabled}
          onClick={onClickAddNew}
        >
          {text}
        </Button>
      )}
    </div>
  );
};

export const UpdateValueButton = ({ onCancel, onClickUpdate }) => {
  return (
    <Row gutter={4}>
      <Col span={12}>
        <Button
          style={{
            width: '100%',
            borderColor: '#f5222d',
            color: '#f5222d',
            height: 35,
          }}
          icon={<StopOutlined />}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type='primary'
          style={{
            width: '100%',
            height: 35,
          }}
          icon={<PlusCircleOutlined />}
          onClick={onClickUpdate}
        >
          Update
        </Button>
      </Col>
    </Row>
  );
};

export const AddWarningDetailButton = ({ onClickCancel, onClickOk }) => {
  return (
    <Row gutter={4}>
      <Col span={12}>
        <Button
          style={{
            borderColor: '#f5222d',
            color: '#f5222d',
            height: 28,
            width: 31,
            borderRadius: 4,
          }}
          icon={<CloseOutlined />}
          onClick={onClickCancel}
        />
      </Col>
      <Col span={12}>
        <Button
          type='primary'
          ghost
          style={{
            height: 28,
            width: 31,
            borderColor: '#389e0d',
            color: '#389e0d',
            borderRadius: 4,
          }}
          icon={<CheckOutlined />}
          onClick={onClickOk}
        />
      </Col>
    </Row>
  );
};
