// eslint-disable-next-line
import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  all,
  delay,
} from 'redux-saga/effects';

import * as types from './constants';
import * as globalActions from './actions';
import * as actions from './actions';
import mapMesssagesWithAttachUrl from '../mapper/mapMesssagesWithAttachUrl';

import * as _ from 'lodash';
import * as servicesChat from 'services/chatServices';
import * as extChatServices from 'services/ext-chat';

import { getAnonToken } from 'utils/common/session';

export function* getCommunicationDetailSaga(action) {
  try {
    const response = yield all({
      participants: call(servicesChat.getCommunicationParticipant, {
        communicationId: action.payload?.id,
      }),
      messages: call(servicesChat.getCommunicationMessage, {
        communicationId: action.payload?.id,
      }),
    });

    if (response?.participants?.isSuccess && response?.messages?.isSuccess) {
      yield put(
        actions.getCommunicationDetailSuccess({
          info: action.payload,
          messages: response?.messages?.data,
          participants: response?.participants?.data,
        })
      );
    } else {
      yield put(actions.getCommunicationDetailFail('fail'));
    }
  } catch (error) {
    yield put(globalActions.getCommunicationDetailFail(error));
  }
}

export function* getCommunicationListSaga(action) {
  try {
    const response = yield call(servicesChat.getThreadList, action.payload);

    if (response?.isSuccess) {
      yield put(actions.getCommunicationListSuccess(response?.data));
    } else {
      yield put(actions.getCommunicationListFail(response?.message));
    }
  } catch (error) {
    yield put(globalActions.getCommunicationListFail(error));
  }
}
export function* getCommunicationListMoreSaga(action) {
  try {
    const response = yield call(servicesChat.getThreadList, action.payload);

    if (response?.isSuccess) {
      yield put(actions.getCommunicationListMoreSuccess(response?.data));
    } else {
      yield put(actions.getCommunicationListMoreFail(response?.message));
    }
  } catch (error) {
    yield put(globalActions.getCommunicationListMoreFail(error));
  }
}

export function* getThreadMessage(payload) {
  try {
    const anonToken = getAnonToken();

    const getThreadMessageService = anonToken
      ? extChatServices.anonGetThreadMessage
      : servicesChat.getThreadMessage;

    const response = yield call(getThreadMessageService, payload);

    if (response?.isSuccess) {
      const threadMessages = response?.data?.threadMessages;

      const handledThreadMessages = yield call(
        mapMesssagesWithAttachUrl,
        threadMessages,
        payload?.threadId
      );

      yield put(actions.getThreadMessagesSuccess(handledThreadMessages));
      //* get latest message
      if (!payload.timestamp && handledThreadMessages.length) {
        yield put(
          actions.updateLastMessage(handledThreadMessages[0].messageId)
        );
      }

      yield put(actions.scrollChatThreadToBottom(true));
    } else {
      yield put(actions.getThreadMessagesFail(response?.message));
    }
  } catch (error) {
    yield put(globalActions.getThreadMessagesFail(error));
  }
}

export function* getThreadInfo(action) {
  try {
    const params = {
      ThreadId: action?.threadId,
      UserId: action?.userId,
    };

    const anonToken = getAnonToken();

    const getThreadInfoService = anonToken
      ? extChatServices.anonGetThreadInfo
      : servicesChat.getThreadInfo;

    const response = yield call(getThreadInfoService, params);

    if (response?.isSuccess) {
      yield put(actions.getThreadInfoSuccess(response?.data));
    } else {
      yield put(actions.getThreadInfoError(response?.message, action.threadId));
    }
  } catch (error) {
    yield put(actions.getThreadInfoError(error, action.threadId));
  }
}

export function* getThreadListForSupporterSaga(action) {
  try {
    const response = yield call(
      servicesChat.getThreadListForSupporter,
      action.payload
    );
    if (response?.isSuccess) {
      yield put(actions.getThreadListForSupporterSuccess(response?.data));
    } else {
      yield put(actions.getThreadListForSupporterFail('fail'));
    }
  } catch (error) {
    yield put(globalActions.getThreadListForSupporterFail(error));
  }
}

export function* getChatContentDetailSaga(action) {
  try {
    const response = yield call(
      servicesChat.getChatContentDetail,
      action.payload
    );
    if (response?.isSuccess) {
      yield put(actions.getChatContentDetailSuccess(response?.data));
    } else {
      yield put(actions.getChatContentDetailFail('fail'));
    }
  } catch (error) {
    yield put(globalActions.getChatContentDetailFail(error));
  }
}

export default function* watchAll() {
  yield all([
    takeLatest(types.GET_COMMUNICATION_DETAIL_ITEM, getCommunicationDetailSaga),
    takeLatest(types.GET_COMMUNICATION_LIST, getCommunicationListSaga),
    takeLatest(types.GET_COMMUNICATION_LIST_MORE, getCommunicationListMoreSaga),
    takeLatest(types.GET_THREAD_MESSAGES, getThreadMessage),
    takeLatest(types.GET_THREAD_INFO, getThreadInfo),
    takeLatest(
      types.GET_THREAD_LIST_FOR_SUPPORTER,
      getThreadListForSupporterSaga
    ),
    takeLatest(types.GET_CHAT_CONTENT_DETAIL, getChatContentDetailSaga),
  ]);
}
