import React from 'react';

import { ViewLayout, AdvanceStack } from './components';
import { RibbonSection, RibbonBar, RibbonDivider } from 'common/components';
import CreateCuratedQuery from './components/controls/maintenance/curated-query/CreateCuratedQuery';
import EditCuratedQuery from './components/controls/maintenance/curated-query/EditCuratedQuery';
import RevokeCuratedQuery from './components/controls/maintenance/curated-query/RevokeCuratedQuery';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const CuratedQueryGridView = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.CURATED_QUERY}>
        <RibbonDivider />
        <RibbonSection>
          <CreateCuratedQuery isSmallBtn />
          <EditCuratedQuery isSmallBtn />
          <RevokeCuratedQuery />
        </RibbonSection>
      </Can>
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default CuratedQueryGridView;
