import React, { useMemo, useRef, useState } from 'react';

import { Row, Col, Checkbox, Typography, TreeSelect } from 'antd';

import {
  useProductDetail,
  useProductDetailDispatch,
  productDetailActionTypes,
} from './ProductDetailContext';

import { generateModuleProductToTree } from '../../shared/utils';

import reactCSS from 'reactcss';
import classnames from 'classnames';

const styles = reactCSS({
  default: {
    wrapper: {
      paddingRight: 10,
      height: 36,
      flexFlow: 'nowrap',
      whiteSpace: 'nowrap',
    },
    inputSearchWrap: {
      paddingLeft: 20,
    },
  },
});

const QueryProperties = (props) => {
  const {
    moduleType,
    moduleSchema = [],
    isExpanded,
    isFull,
    searchAdvanceModuleInFullModeIndent,
  } = props;

  const {
    formInstance,
    advancedModule: { searchText: advancedSearchText },
    baseModule: { searchText: baseSearchText },
  } = useProductDetail();
  const dispatch = useProductDetailDispatch();

  const isBaseModule = moduleType === 'base';

  const treeData = useMemo(
    () => generateModuleProductToTree(moduleSchema),
    [moduleSchema]
  );

  return (
    <>
      {isBaseModule ? (
        <Row align='middle' justify='end' style={styles.wrapper}>
          <Col
            className={classnames({
              'product-detail__search': true,
              'product-detail__search--expanded': Boolean(isExpanded),
            })}
          >
            <SearchProductProperties
              treeData={treeData}
              onChange={(searchText) => {
                dispatch({
                  type: productDetailActionTypes.SEARCH_BASE_PROPERTIES,
                  payload: {
                    searchText,
                    moduleSchema,
                    modeView: 'view',
                    formInstance,
                  },
                });
              }}
              value={baseSearchText}
              key={baseSearchText}
            />
          </Col>
          <Col>
            <Checkbox
              onChange={(event) =>
                dispatch({
                  type: productDetailActionTypes.EXPAND_ALL_MODULES,
                  payload: {
                    moduleType,
                    moduleSchema,
                    value: event.target.checked,
                  },
                })
              }
            >
              <Typography.Text strong>Expand All</Typography.Text>
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              onChange={(event) =>
                dispatch({
                  type: productDetailActionTypes.SHOW_MODULE_HAS_VALUE,
                  payload: {
                    moduleType,
                    value: event.target.checked,
                  },
                })
              }
            >
              <Typography.Text strong>Show Values Only</Typography.Text>
            </Checkbox>
          </Col>
        </Row>
      ) : (
        <Row align='middle' style={styles.wrapper}>
          {isFull && <Col flex={searchAdvanceModuleInFullModeIndent} />}
          <Col className='product-detail__search'>
            <SearchProductProperties
              treeData={treeData}
              onChange={(searchText) => {
                dispatch({
                  type: productDetailActionTypes.SEARCH_PROPERTIES,
                  payload: {
                    searchText,
                    moduleSchema,
                    modeView: 'view',
                    formInstance,
                  },
                });
              }}
              value={advancedSearchText}
              key={advancedSearchText}
            />
          </Col>
          {isFull && <Col flex='auto' />}

          {isExpanded && (
            <Col>
              <Checkbox
                onChange={(event) => {
                  const value = event.target.checked;
                  dispatch({
                    type: productDetailActionTypes.TOGGLE_FULL_VIEW,
                    payload: {
                      value,
                    },
                  });
                }}
              >
                <Typography.Text strong>Show Full</Typography.Text>
              </Checkbox>
            </Col>
          )}
          <Col>
            <Checkbox
              onChange={(event) => {
                dispatch({
                  type: productDetailActionTypes.EXPAND_ALL_MODULES,
                  payload: {
                    moduleType,
                    moduleSchema,
                    value: event.target.checked,
                  },
                });
              }}
            >
              <Typography.Text strong>Expand All</Typography.Text>
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              onChange={(event) => {
                dispatch({
                  type: productDetailActionTypes.SHOW_MODULE_HAS_VALUE,
                  payload: {
                    moduleType,
                    value: event.target.checked,
                  },
                });
              }}
            >
              <Typography.Text strong>Show Values Only</Typography.Text>
            </Checkbox>
          </Col>
          {isExpanded && <Col style={{ width: 35 }} />}
        </Row>
      )}
    </>
  );
};

export const SearchProductProperties = ({ treeData, ...otherProps }) => {
  const selectedValueRef = useRef(null);

  const [searchValue, setSearchValue] = useState(otherProps?.value);

  const onSearch = (value) => {
    const selectedValue = selectedValueRef.current;

    if (selectedValue) {
      setSearchValue(selectedValue);
      otherProps?.onSearch?.(selectedValue);

      selectedValueRef.current = null; //* reset ref value

      return;
    }

    setSearchValue(value);
    otherProps?.onSearch?.(value);
  };

  const onSelect = (...params) => {
    const node = params?.[1];

    const selectedText = node?.data?.propertyDisplayName;
    selectedValueRef.current = selectedText;
    setSearchValue(selectedText);

    otherProps?.onSelect?.(...params);
  };

  return (
    <TreeSelect
      treeData={treeData}
      treeLine={{
        showLine: true,
        showLeafIcon: false,
      }}
      treeDefaultExpandAll
      showSearch
      searchValue={searchValue}
      allowClear
      style={{
        width: '100%',
      }}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
        minWidth: 400,
      }}
      placeholder='Input to search property...'
      filterTreeNode={(inputValue, treeNode) => {
        const inputValueSearch = inputValue.toLowerCase().trim();

        const isFieldNameMatched = treeNode.data?.fieldFullPath
          ?.toLowerCase()
          ?.includes(inputValueSearch);
        const isDisplayNameMatched = treeNode.data?.propertyDisplayName
          ?.toLowerCase()
          ?.includes(inputValueSearch);

        return isFieldNameMatched || isDisplayNameMatched;
      }}
      onSearch={onSearch}
      onSelect={onSelect}
      {...otherProps}
    />
  );
};

export default QueryProperties;
