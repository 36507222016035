import { $id } from 'utils/element';

/**
 *! component details by component type
 * @param {*} pageData
 * @param {*} type
 * @returns
 */
const mapToComponentByType = (pageData, type) => {
  const componentDetails = pageData?.componentDetails;

  let nextComponentKeyList = [];
  let nextComponentDetails = {};

  if (componentDetails) {
    for (const [componentKey, componentData] of Object.entries(
      componentDetails
    )) {
      if (componentData?.type === type) {
        nextComponentDetails[componentKey] = componentData;
        nextComponentKeyList.push(componentKey);
      }
    }
  }

  return {
    components: nextComponentKeyList,
    componentDetails: nextComponentDetails,
  };
};

/**
 *! component detail by component key
 * @param {*} pageData
 * @param {*} key
 * @returns
 */
const mapToComponentByKey = (pageData, key) => {
  let nextComponentDetail = {};

  if (!pageData?.components || !key) return nextComponentDetail;

  const componentDetails = pageData?.componentDetails;

  for (const [componentKey, componentData] of Object.entries(
    componentDetails
  )) {
    if (componentKey === key) {
      nextComponentDetail = { [componentKey]: componentData };
      return nextComponentDetail;
    }
  }

  return nextComponentDetail;
};

/**
 * ! map pixel to percent by wrap component
 * @param {*} param0
 * @returns
 */
const mapPixelToPercentByWrap = ({ width, height, wrapId }) => {
  if (!wrapId) return;

  const $wrap = $id(wrapId);
  const wrapWidth = $wrap?.clientWidth;
  const wrapHeight = $wrap?.clientHeight;

  let result = {};

  if (width !== undefined) {
    const nextWidth = (width / wrapWidth) * 100;
    result.width = nextWidth;
  }

  if (height !== undefined) {
    const nextHeight = (height / wrapHeight) * 100;
    result.height = nextHeight;
  }

  return result;
};

/**
 * ! map percent to pixel by center
 * @param {*} param0
 * @returns
 */
const mapPercentToPixelByWrap = ({ width, height, wrapId }) => {
  if (!wrapId) return;

  const $wrap = $id(wrapId);
  const wrapWidth = $wrap?.clientWidth;
  const wrapHeight = $wrap?.clientHeight;

  let result = {};

  if (width !== undefined) {
    const nextWidth = wrapWidth * (width / 100);
    result.width = nextWidth;
  }

  if (height !== undefined) {
    const nextHeight = wrapHeight * (height / 100);
    result.height = nextHeight;
  }

  return result;
};

/**
 * ! get resize image box height
 * @param {*} param0
 * @returns
 */
const mapToResizeBoxImageHeight = ({ componentData, ratio, wrapId }) => {
  const sizeBorder = componentData?.borderWidth * 2;

  const pixelWidth = mapPercentToPixelByWrap({
    width: componentData?.width,
    wrapId,
  })?.width;

  return ratio !== null ? (pixelWidth - sizeBorder) / ratio + sizeBorder : 0;
};

export {
  mapToComponentByType,
  mapToComponentByKey,
  mapPercentToPixelByWrap,
  mapPixelToPercentByWrap,
  mapToResizeBoxImageHeight,
};
