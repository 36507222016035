import _ from 'lodash';

import { useGetQaSpecDataHeader } from 'pages/qa-spec/query';

export const useCheckDisableInitialWorkflowSpec = ({
  productList = [],
  productId,
}) => {
  const { qaSpecDataHeader } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: !!productId,
  });

  const disableInitialWorkflowProductFull = () => {
    return qaSpecDataHeader?.assignTo;
  };

  const disableInitialWorkflowProductGrid = () => {
    if (!Array.isArray(productList)) return true;

    if (productList?.length <= 0) return true;

    const existAssignedSupplier = _.some(
      productList,
      (product) => product?.assignTo
    );

    return existAssignedSupplier;
  };

  const isDisableInitialQaSpecWorkflow = productId
    ? disableInitialWorkflowProductFull()
    : disableInitialWorkflowProductGrid();

  return isDisableInitialQaSpecWorkflow;
};
