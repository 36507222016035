import React from 'react';

import { FolderItem } from './FolderItem';

export const FolderList = (props) => {
  const {
    onExpand,
    folderData,
    handleDropItem,
    selectedFolderId,
    onSelectFolder,
    disableItemHandler,
  } = props;

  const handleSelectFolder = (currentFolder) => {
    onSelectFolder && onSelectFolder(currentFolder);
  };

  const onDrop = (dropData, folder) => {
    if (!dropData) return;

    handleDropItem && handleDropItem(dropData, folder);
  };

  return (
    <div className='drop-warp-list_folder'>
      {folderData.map((item) => {
        const itemProps = {
          item,
          onExpand,
          onDrop,
          handleSelectFolder,
          selectedFolderId,
          disableItemHandler,
        };

        return <FolderItem key={item.id} {...itemProps} />;
      })}
    </div>
  );
};

export default FolderList;
