import { useDispatch, useSelector } from 'react-redux';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { useDispatchReloadPage } from 'hooks/useReloadPage';

export const useGridView = () => {
  const dispatch = useDispatch();

  const reloadPage = useDispatchReloadPage();

  let detailItems = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const clearGridSelection = () => {
    dispatch(actionsGridView.deleteCurrentItemsSelection());
    dispatch(actionsGridView.deleteItemsSelection());
  };

  const togglePreventCallApiGridView = (value = false) => {
    dispatch(actionsGridView.togglePreventCallApiGridView(value));
  };

  const showContentPane = (value) => {
    dispatch(actionsGridView.updateVisible(false));
  };

  const updateDetailCurrentItemsSelection = () => {
    dispatch(actionsGridView.updateDetailCurrentItemsSelection([]));
  };

  return {
    detailItems,
    reloadPage,
    clearGridSelection,
    togglePreventCallApiGridView,
    showContentPane,
    updateDetailCurrentItemsSelection,
  };
};
