import * as types from './constants';

export const getArticles = (pageSize, pageNumber, search) => {
  return {
    type: types.GET_ARTICLE,
    pageSize: pageSize,
    pageIndex: pageNumber,
    'Search.SearchText': search,
  };
};

export const getArticlesSuccess = (posts, paging) => {
  return {
    type: types.GET_ARTICLE_SUCCESS,
    posts,
    paging,
  };
};

export const getArticlesError = (error) => {
  return {
    type: types.GET_ARTICLE_ERROR,
    error,
  };
};

export const enableCreateArticle = () => {
  return {
    type: types.ENABLE_CREATE_ARTICLE,
  };
};

export const enableEditArticle = () => {
  return {
    type: types.ENABLE_EDIT_ARTICLE,
  };
};

export const disableCreateArticle = () => {
  return {
    type: types.DISABLE_CREATE_ARTICLE,
  };
};

export const disableEditArticle = () => {
  return {
    type: types.DISABLE_EDIT_ARTICLE,
  };
};

export const resetModeArticle = () => {
  return {
    type: types.RESET_MODE_ARTICLE,
  };
};

export const confirmEditArticle = () => {
  return { type: types.CONFIRM_EDIT_ARTICLE };
};

export const disableConfirmEditArticle = () => {
  return { type: types.DISABLE_CONFIRM_EDIT_ARTICLE };
};

export const changeUpdateStatus = (status) => {
  return { type: types.CHANGE_STATUS_UPDATE_ARTICLE, status };
};

export const saveIdArticle = (id) => {
  return { type: types.SAVE_ID_ARTICLE, id };
};

export const getArticlesGridColumns = (gridName) => ({
  type: types.GET_ARTICLES_GRID_COLUMN,
  gridName,
});

export const getArticlesGridColumnsSuccess = (columns) => ({
  type: types.GET_ARTICLES_GRID_COLUMN_SUCCESS,
  columns,
});

export const getArticlesGridColumnsError = (error) => ({
  type: types.GET_ARTICLES_GRID_COLUMN_ERROR,
  error,
});
