import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectWorkflow = (state) => state.workflow || initialState;

const selectWfSelectedStep = () =>
  createSelector(selectWorkflow, (state) => state.wfSelectedStep);

const selectWorkflowGrid = () =>
  createSelector(selectWorkflow, (state) => state.workflows);

const selectWorkflowGridColumns = () =>
  createSelector(selectWorkflow, (state) => state.workflowColumns);

const selectWorkflowFilters = () =>
  createSelector(selectWorkflow, (state) => state.workflowFilters);

const selectWorkflowTasks = () =>
  createSelector(selectWorkflow, (state) => state.workflowTasks);

const selectWfImplInfo = () =>
  createSelector(selectWorkflow, (state) => state.wfImplInfo);

const selectWfImplSteps = () =>
  createSelector(selectWorkflow, (state) => state.wfImplSteps);

const selectWfImplCurrentStep = () =>
  createSelector(selectWorkflow, (state) => state.wfCurrentImplStep);

const selectAnsweredTasks = () =>
  createSelector(selectWorkflow, (state) => state.answeredTask);

const selectCurrentStepDetail = () =>
  createSelector(selectWorkflow, (state) => state.currentStep);

const selectIsUpdateApproveStepGrid = () =>
  createSelector(selectWorkflow, (state) => state.isUpdateApproveStepGrid);

const selectIsUpdateAcceptStepGrid = () =>
  createSelector(selectWorkflow, (state) => state.isUpdateAcceptStepGrid);

export {
  selectWorkflowGrid,
  selectWorkflowGridColumns,
  selectWfSelectedStep,
  selectWorkflowFilters,
  selectWorkflowTasks,
  selectWfImplInfo,
  selectWfImplSteps,
  selectWfImplCurrentStep,
  selectAnsweredTasks,
  selectCurrentStepDetail,
  selectIsUpdateApproveStepGrid,
  selectIsUpdateAcceptStepGrid,
};
