import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as qaSpecServices from 'services/qaSpec';

export const getPhysicalChemicalKey = (productId) => {
  return [
    'qa-spec',
    parseInt(productId),
    'qa-physical-chemical-characteristics',
  ];
};

export const useGetPhysicalChemicalQuery = ({ productId }) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery({
    queryKey: getPhysicalChemicalKey(productId),
    queryFn: async () => {
      return await qaSpecServices.getQaSpecPhyCheCharacteristic({
        productId,
      });
    },
    enabled: Boolean(productId),
  });

  const handleRefetchPhysicalChemical = () => {
    queryClient.invalidateQueries({
      queryKey: getPhysicalChemicalKey(productId),
    });
  };

  return {
    physicalChemicalData: data?.data,
    handleRefetchPhysicalChemical,
    ...rest,
  };
};
