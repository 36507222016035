import React from 'react';

const ProductItemManagementSection = (props) => {
  const { children } = props;

  return (
    <div
      className='supplier-management-modal__product-item-manage-section'
      style={{ height: '100%' }}
    >
      {children}
    </div>
  );
};

export default ProductItemManagementSection;
