import React from 'react';
import { Row, Col } from 'antd';
import './MemberPanelContact.less';
import { ContactActions } from 'common/components';

const MemberPanelContact = (props) => {
  const { contactList, address } = props;

  return (
    <>
      {contactList.map((item, index) => {
        return (
          <div className='member-panel-contact' key={index}>
            <Row>
              <Col span={8} className='member-panel-contact__left-col'>
                <strong>{item.contactInfoFullName}</strong>
              </Col>
              <Col span={8} className='member-panel-contact__center-col'>
                <strong>{address}</strong>
              </Col>
              <Col span={8} className='member-panel-contact__right-col'>
                <ContactActions className='member-panel-contact__contact-action' />
              </Col>
            </Row>
            <Row>
              <Col span={8} className='member-panel-contact__left-col'>
                <strong>{item.contactInfoDepartment}</strong>
              </Col>
              <Col span={8} className='member-panel-contact__center-col'>
                <strong>{item.contactInfoPhoneNumber}</strong>
              </Col>
              <Col span={8} className='member-panel-contact__right-col'>
                <strong>{item.contactInfoEmail}</strong>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default MemberPanelContact;
