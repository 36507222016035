import React from 'react';

import { Button, Tooltip } from 'antd';
import { PlusOutlined, UndoOutlined } from '@ant-design/icons';

import * as utils from 'utils/productHierarchy';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

const HierarchyAddProductButton = (props) => {
  const intl = useIntl();
  const { level, hierarchyProductList, onClickLevelButton, onClickRevertBtn } =
    props;

  const isRevertable = utils.checkRevertable({
    productList: hierarchyProductList,
    level,
  });

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        type='primary'
        onClick={onClickLevelButton(level)}
      ></Button>
      {isRevertable ? (
        <Tooltip
          title={intl.formatMessage(Messages.revertTooltip)}
          onClick={onClickRevertBtn(level)}
        >
          <UndoOutlined className='add-hierarchy__level-undo-btn' />
        </Tooltip>
      ) : null}
    </>
  );
};

export default HierarchyAddProductButton;
