import React from 'react';

import {
  Col,
  Typography,
  Button,
  InputNumber,
  Form,
  Tooltip,
  Space,
} from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { DeleteButton, dialogFunction } from 'common/components';

import * as utils from 'utils/productHierarchy';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

const { Text, Link } = Typography;

const HierarchyLevelProductItem = (props) => {
  const intl = useIntl();
  const {
    form,
    hierarchyProductList,
    currentProduct,
    product,
    level,
    highestLevel,
    isCopy,
    hierarchyLevelList,
    setHierarchyLevelList,
    onClickLevelButton,
    onClickRevertBtn,
  } = props;

  const onDeleteProduct = (level) => () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.deletePackageLevelMessage),
      onOk: deletePackageLevel(level),
      okText: intl.formatMessage(Messages.deletePackageLevelYes),
    });
  };

  const deletePackageLevel = (level) => () => {
    removeLevel(level);
  };

  const removeLevel = (deletedLevel) => {
    setHierarchyLevelList((prevState) => {
      const cloneState = _.cloneDeep(prevState);

      _.set(cloneState, `[${deletedLevel.index}].productItemId`, null);
      _.set(cloneState, `[${deletedLevel.index}].parentProductItemId`, null);

      const removedState = utils.getRemovedLevelResultList({
        deletedLevel,
        levelList: cloneState,
      });

      const reIndexState = utils.reIndexLevelList(removedState);

      removeParentProductItem({ level: deletedLevel, levelList: reIndexState });

      reSetHighestLevelQuantity({ levelList: reIndexState });

      return reIndexState;
    });
  };

  const removeParentProductItem = ({ level, levelList }) => {
    const childrenLevelList = levelList.filter((levelItem) => {
      return levelItem.parentProductItemId === level.productItemId;
    });

    childrenLevelList.forEach((levelItem) => {
      _.set(
        levelList,
        `${levelItem.index}.parentProductItemId`,
        level.parentProductItemId
      );
    });
  };

  const reSetHighestLevelQuantity = ({ levelList }) => {
    const highestLevel = utils.getHighestLevel(levelList);

    const highestLevelList = levelList.filter(
      (levelItem) => levelItem.packageLevel === highestLevel
    );

    highestLevelList.forEach((levelItem) => {
      _.set(levelList, `${levelItem.index}.quantity`, 1);

      form.setFieldsValue({
        [levelItem.index]: 1,
      });
    });
  };

  const isCurrentProduct = product.productItemId === currentProduct.productId;
  const currentProductCount = utils.countCurrentProduct({
    currentProduct,
    levelList: hierarchyLevelList,
  });
  const shouldShowDeleteBtn = !isCurrentProduct || currentProductCount > 1;

  const isHighestLevel = level.packageLevel === highestLevel;
  const shouldChangeProduct = !isCurrentProduct && isCopy && isHighestLevel;

  const productInfoText =
    product.productName ||
    product.productDescription ||
    product.gtin ||
    product.upc12 ||
    product.rivirId ||
    intl.formatMessage(Messages.hierarchyProductNoInfo);

  const isRevertable = utils.checkRevertable({
    productList: hierarchyProductList,
    level,
    shouldChangeProduct,
  });

  const quantityRule = [
    { required: true, message: intl.formatMessage(Messages.required) },
  ];

  return (
    <>
      <Col flex={'48px'}>
        <div
          className='add-hierarchy__level-product-thumb'
          style={{
            backgroundImage: `url(${product.thumbnail})`,
          }}
        ></div>
      </Col>
      <Col flex={1} style={{ minWidth: 0 }}>
        {!isCurrentProduct ? (
          <Link
            ellipsis={{ tooltip: product?.productName }}
            href={`/product/${product.productItemId}`}
            target='_blank'
            className='add-hierarchy__level-product-name'
          >
            {productInfoText}
          </Link>
        ) : (
          <Text
            strong
            ellipsis={{ tooltip: product?.productName }}
            className='add-hierarchy__level-product-name'
          >
            {product?.productName}
          </Text>
        )}
      </Col>
      {shouldChangeProduct ? (
        <Col flex='126px'>
          <Space size={4} align='center'>
            <Button type='primary' onClick={onClickLevelButton(level)}>
              {intl.formatMessage(Messages.changeProduct)}
            </Button>
            {isRevertable ? (
              <Tooltip title={intl.formatMessage(Messages.revertTooltip)}>
                <UndoOutlined
                  className='add-hierarchy__level-undo-btn'
                  style={{ position: 'relative', top: 2 }}
                  onClick={onClickRevertBtn(level)}
                />
              </Tooltip>
            ) : null}
          </Space>
          <Form.Item name={level.index} hidden={true} initialValue={1}>
            <InputNumber />
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col flex={'98px'}>
            <Form.Item
              name={level.index}
              rules={quantityRule}
              hidden={isHighestLevel}
              initialValue={isHighestLevel ? 1 : null}
            >
              <InputNumber
                size='small'
                min={1}
                placeholder={intl.formatMessage(Messages.quantityPlaceholder)}
              />
            </Form.Item>
          </Col>
          <Col flex={'28px'}>
            {shouldShowDeleteBtn ? (
              <DeleteButton onClick={onDeleteProduct(level)} />
            ) : null}
          </Col>
        </>
      )}
    </>
  );
};

export default HierarchyLevelProductItem;
