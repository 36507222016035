import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { CopyOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import CloneReportingModal from '../../modal/reporting/CloneReportingModal';

const CloneReporting = (props) => {
  const { selectedItemList } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const isDisabled = selectedItemList.length !== 1;

  const onClick = () => {
    setModalVisible(true);
  };

  return (
    <>
      <RibbonButton
        icon={<CopyOutlined />}
        label={Messages.reportingClone}
        onClick={onClick}
        disabled={isDisabled}
      />
      <CloneReportingModal
        selectedReport={selectedItemList[0]}
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};

CloneReporting.propTypes = {
  selectedItemList: PropTypes.array,
};

export default CloneReporting;
