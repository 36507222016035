import { CustomNotification } from 'common/components';

export const DataToMsOffice = () => {
  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {}

    document.body.removeChild(textArea);
  };

  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text);
    CustomNotification.success('Copied Successfully!');
  };

  const copyTextWithStyleToWord = (content) => {
    const type = 'text/html';
    const blob = new Blob([content], { type });
    const data = [new window.ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
      () => {
        CustomNotification.success('Copied Successfully');
      },
      (error) => {
        CustomNotification.fail(error);
      }
    );
  };

  const copy2DArrayClipboardToExcel = (array) => {
    let csv = '',
      row,
      cell;
    for (row = 0; row < array.length; row++) {
      for (cell = 0; cell < array[row].length; cell++) {
        csv += (array[row][cell] + '').replace(/[\n\t]+/g, ' ');
        if (cell + 1 < array[row].length) csv += '\t';
      }
      if (row + 1 < array.length) csv += '\n';
    }
    copyTextToClipboard(csv);
  };

  return {
    copy2DArrayClipboardToExcel,
    copyTextToClipboard,
    copyTextWithStyleToWord,
  };
};
