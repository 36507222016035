import React from 'react';
import { Button, Tooltip, Space } from 'antd';
import { EditOutlined, StopOutlined, SaveOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import './HeaderEdit.less';

import {
  useCheckIsSuperAdmin,
  usePermissionEditProduct,
  usePermissionEditSharedProduct,
} from 'hooks';

const HeaderEdit = ({
  editHeader,
  setIsEditHeader,
  loading,
  onSaveClick,
  bgInstance,
  handleCancel,
  handleSave,
}) => {
  const hasPermissionEditSharedProduct = usePermissionEditSharedProduct();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const hasPermissionEditProduct = usePermissionEditProduct();

  if (editHeader) {
    return (
      <Space>
        <Button
          icon={<SaveOutlined />}
          onClick={handleSave}
          type='primary'
          className={classnames(
            'pd-header-edit__btn-save',
            'pd-header-edit__btn'
          )}
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
        <Button
          icon={<StopOutlined />}
          onClick={handleCancel}
          type='default'
          className={classnames(
            'pd-header-edit__btn-cancel',
            'pd-header-edit__btn'
          )}
          disabled={loading}
        >
          Cancel
        </Button>
      </Space>
    );
  }

  const showTooltip =
    isSuperAdmin || hasPermissionEditProduct || hasPermissionEditSharedProduct;

  return (
    <>
      {showTooltip && (
        <Tooltip title='Edit background' placement='topLeft'>
          <Button
            icon={<EditOutlined />}
            onClick={() => setIsEditHeader(true)}
            type='link'
            className={classnames(
              'pd-header-edit__btn-edit',
              'pd-header-edit__btn'
            )}
          />
        </Tooltip>
      )}
    </>
  );
};

export default HeaderEdit;
