import React from 'react';
import PropTypes from 'prop-types';
import AdvanceStack from '../../controls/home/AdvanceStack';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

const Folder = ({ onSearch, toggleSearch }) => {
  return (
    <ContainerButton>
      <ItemButton>
        <AdvanceStack />
      </ItemButton>
    </ContainerButton>
  );
};

Folder.propTypes = {
  onSearch: PropTypes.func,
  toggleSearch: PropTypes.func,
};

export default Folder;
