import ReactDOM from 'react-dom';

import { useInitRibbonBar } from 'hooks/ribbonBarHooks';

const RibbonPortal = (props) => {
  const { option, searchCriteria, children } = props;

  const { ribbonDom } = useInitRibbonBar({ searchCriteria, option });

  return ribbonDom && ReactDOM.createPortal(children, ribbonDom);
};

export default RibbonPortal;
