import { additionalRequirementData } from 'static/Constants';
import { MINIMUM_REQUIREMENT_ENTITY_TYPE } from 'pages/minimum-requirements/constants';

export const generateInitialStateForm = (properties) => {
  let stateForm = {};
  properties.forEach((item) => {
    stateForm[item.fieldName] = {
      checkbox: item.isCheck,
      value: '',
    };
  });
  return properties;
};

export const generateAdditional = (additionalData) => {
  let listValue = {};
  for (const [key, properties] of Object.entries(additionalData)) {
    if (properties.checkbox) {
      listValue[key] = properties?.value ?? properties?.checkbox;
    } else {
      listValue[key] = null;
    }
  }
  return listValue;
};
export const generateAdditionalValue = (data) => {
  let listValue = {};
  const entityName = data.entityName;
  const additionalDefault = additionalRequirementData[entityName] ?? [];

  additionalDefault.forEach((properties) => {
    const fieldName = properties.fieldName;
    if (properties.isInput) {
      listValue[fieldName] = {
        checkbox: Boolean(data[fieldName]),
        value: data[fieldName] > 0 ? data[fieldName] : 1,
      };
    } else {
      listValue[fieldName] = {
        checkbox: Boolean(data[fieldName]),
      };
    }
  });
  return listValue;
};

const nullableModuleName = 'No Header';
const nullableModuleDisplayName = 'Product Basic Information';

const getAllModuleNames = (properties) => {
  let modules = [];

  properties.forEach((property) => {
    const foundModule = modules.find(
      (item) => item.moduleName === property.moduleName
    );
    if (!foundModule) {
      const foundNullableModule = modules.find(
        (item) => item.moduleName === nullableModuleName
      );
      if (!foundModule) {
        if (!foundNullableModule && property.moduleName === null) {
          modules.push({
            moduleName: nullableModuleName,
            moduleDisplayName: nullableModuleDisplayName,
            moduleProperties: [],
          });
        } else if (property.moduleName !== null) {
          modules.push({
            moduleName: property.moduleName,
            moduleDisplayName: property.moduleDisplayName,
            moduleProperties: [],
          });
        }
      }
    }
  });

  return modules;
};

export const getSelectedPropertiesEditData = (data, propertiesRequired) => {
  let selectedProperties = [];
  if (propertiesRequired && data.requiredProperties) {
    data.requiredProperties.forEach((properties) => {
      const required = propertiesRequired.find(
        (item) => item.fieldFullPath === properties
      );
      if (required) selectedProperties.push(required);
    });
  }
  return selectedProperties;
};

export const mappingGridProperties = (properties, entity) => {
  if (entity !== 'Product') {
    let allProperties = {
      moduleName: entity,
      moduleDisplayName: entity,
      moduleProperties: [],
    };

    properties.forEach((item) => {
      allProperties.moduleProperties.push(item);
    });

    return [{ ...allProperties }];
  }

  if (properties.length === 0) return [];

  let result = getAllModuleNames(properties);

  properties.forEach((item) => {
    if (item.moduleName === null) {
      const foundIndexModule = result.findIndex(
        (module) => module.moduleName === nullableModuleName
      );
      result[foundIndexModule].moduleProperties.push(item);
    } else {
      const foundIndexModule = result.findIndex(
        (module) => module.moduleName === item.moduleName
      );
      result[foundIndexModule].moduleProperties.push(item);
    }
  });
  return result.filter((item) => item.moduleProperties.length > 0);
};

export const getProperties = (moduleProperties, property) => {
  let properties = [];

  if (property.moduleName) {
    properties =
      moduleProperties.find((item) => item.moduleName === property.moduleName)
        ?.moduleProperties ?? [];
  } else {
    properties =
      moduleProperties.find((item) => item.moduleName === 'No Header')
        ?.moduleProperties ?? [];
  }

  return properties.map((property) => property.fieldFullPath);
};

export const getNewPropertiesAfterDeleting = (
  properties,
  moduleName,
  propertyName,
  isCustomField
) => {
  const cloneProperties = properties.slice();
  const foundIndex = findIndexProperty(properties, moduleName, propertyName);

  if (foundIndex > -1) {
    cloneProperties.splice(foundIndex, 1);
  }

  return cloneProperties;
};

export const findIndexProperty = (properties, moduleName, propertyName) => {
  if (!moduleName) {
    return properties.findIndex(
      (property) => property.fieldName === propertyName
    );
  }

  return properties.findIndex(
    (property) =>
      property.moduleName === moduleName && property.fieldName === propertyName
  );
};

export const generateInteger = (inputValue) => {
  let listValue = {};
  for (const [key, properties] of Object.entries(inputValue)) {
    const value = properties?.value
      ? parseInt(properties.value || '1', 10)
      : properties.checkbox;

    listValue[key] = { ...properties, value };
  }
  return listValue;
};

export const countNestedModule = (dataQuery) => {
  let count = 0;

  const loopCountQuery = (dataQuery) => {
    dataQuery?.forEach((item) => {
      const childFields = item?.properties;
      if (childFields?.length > 0) {
        loopCountQuery(childFields);
      } else {
        count++;
      }
    });
  };

  loopCountQuery(dataQuery);
  return count;
};

export const getDropProperty = (data, selectedPropertyIds, draggableId) => {
  let property = [];

  const recursionProperty = (arr) => {
    const arrMapping = arr.properties ?? arr;
    arrMapping.forEach((field) => {
      const nestedProperty = field?.fieldFullPath ?? field?.moduleName;
      const hasProperty =
        selectedPropertyIds?.includes(nestedProperty + '_entity') ||
        nestedProperty + '_entity' === draggableId;

      if (hasProperty) {
        property.push(field);
      } else {
        if (field?.properties) recursionProperty(field?.properties);
      }
    });
  };
  recursionProperty(data);
  const { fieldFullPath } = covertNestedArrToSingleArr(property);
  return fieldFullPath;
};

export const covertNestedArrToSingleArr = (data) => {
  let fieldFullPath = [];
  let properties = [];

  const recursionProperty = ({
    nestedArr,
    parentModule,
    parentDisplayName = '',
    isRoot = false,
  }) => {
    const arrMapping = nestedArr?.properties ?? nestedArr;
    arrMapping?.forEach((field) => {
      const module = parentModule || {
        moduleName: field?.moduleName,
        moduleDisplayName: field?.displayName,
      };

      const fullPathDisplayName = `${parentDisplayName}${
        parentDisplayName && field.displayName ? ' - ' : ''
      }${field.displayName}`;

      if (field?.properties?.length > 0) {
        recursionProperty({
          nestedArr: field,
          parentModule: module,
          parentDisplayName: !isRoot ? fullPathDisplayName : '',
        });
      } else {
        fieldFullPath.push(field?.fieldFullPath);
        properties.push({
          ...field,
          ...module,
          fullPathDisplayName,
        });
      }
    });
  };
  recursionProperty({ nestedArr: data, isRoot: true });
  return { properties, fieldFullPath };
};

export const getPropertyByFieldFullPath = ({
  properties,
  fieldFullPath,
  parentDisplayName = '',
}) => {
  return properties?.reduce((accumulator, currentProperty, index) => {
    if (accumulator?.fieldFullPath || accumulator?.moduleName)
      return accumulator; //* stop recursive when data has been matched

    const { properties, ...rest } = currentProperty;

    const fullPathDisplayName = `${parentDisplayName}${
      parentDisplayName && rest.displayName ? ' - ' : ''
    }${rest.displayName}`;

    const matched =
      currentProperty?.fieldFullPath === fieldFullPath ||
      currentProperty?.moduleName === fieldFullPath;

    if (matched) {
      return {
        ...rest,
        fullPathDisplayName,
      };
    }

    if (properties?.length)
      return getPropertyByFieldFullPath({
        properties: properties,
        fieldFullPath,
        parentDisplayName: fullPathDisplayName,
      });

    return accumulator;
  }, {});
};

export const reorderMultipleItem = (
  dataColumns,
  selectedPropertyIds,
  destination
) => {
  let data = [];
  let dataColumnsTemp = [...dataColumns];
  dataColumnsTemp = dataColumnsTemp.filter(
    (fieldFullPath) => !selectedPropertyIds.includes(fieldFullPath + '_column')
  );

  dataColumns?.forEach((fieldFullPath, i) => {
    if (selectedPropertyIds.includes(fieldFullPath + '_column')) {
      data.push(fieldFullPath);
    }
  });

  data?.forEach((val, i) => {
    dataColumnsTemp.splice(destination?.index + i, 0, val);
  });

  return dataColumnsTemp;
};

export const mappingProperties = (data) => {
  let additionalData = [];
  let requiredField = [];

  data.forEach((item) => {
    if (item?.moduleName === 'additionalData') {
      additionalData.push(item);
    } else {
      requiredField.push(item);
    }
  });

  return [
    {
      moduleDisplayName: 'Required Properties',
      moduleProperties: requiredField,
    },
    {
      moduleDisplayName: 'Additional Data',
      moduleProperties: additionalData,
    },
  ];
};

export const filterFields = (dataEntity, searchValue) => {
  let dataFilter = [];

  const recursionFilter = (data) => {
    let result = [];
    data?.forEach((field) => {
      if (
        field?.displayName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        result.push(field);
      } else {
        if (field?.properties?.length > 0) {
          const properties = recursionFilter(field.properties);
          if (properties?.length) {
            result.push({
              ...field,
              properties: properties,
            });
          }
        }
      }
    });
    return result;
  };

  dataEntity.forEach((field) => {
    const properties = recursionFilter([field]);
    if (properties?.length) dataFilter.push(...properties);
  });

  return dataFilter;
};

export const showWarningMsgForAllMember = ({
  currentEntity,
  isPrivateLabel,
  requirementFilterList,
  entityFilterList,
}) => {
  if (
    currentEntity?.toLowerCase() !== MINIMUM_REQUIREMENT_ENTITY_TYPE.PRODUCT
  ) {
    return entityFilterList?.includes(currentEntity);
  }

  const requirementInfo = requirementFilterList?.find((item) => {
    const privateLabel = item?.isPrivateLabel ?? false;
    return (
      privateLabel === isPrivateLabel &&
      item?.entityName.toLowerCase() === MINIMUM_REQUIREMENT_ENTITY_TYPE.PRODUCT
    );
  });

  return !!requirementInfo;
};
