import { useQueryClient, useQuery } from '@tanstack/react-query';

import * as productEndpoints from 'services/product/endpoints';

const productCompanyInfo = {
  url: productEndpoints.GET_PRODUCT_COMPANY_INFO,
  all: ['pim', 'get-product-company-information'],
  one: (productId) => [...productCompanyInfo.all, { params: { productId } }],
};

const keys = {
  productCompanyInfo,
};

export const useInvalidProductHeaderBackground = () => {
  const queryClient = useQueryClient();

  const invalidateProductHeaderBackgroundQueries = () => {
    queryClient.invalidateQueries({
      queryKey: ['pim', 'get-product-background-header'],
    });
  };

  return { invalidateProductHeaderBackgroundQueries };
};

export const useQueryProductCompanyInfo = ({ productId, ...options } = {}) => {
  const queryClient = useQueryClient();

  const queryObject = useQuery({
    queryKey: keys.productCompanyInfo.one(productId),
    enabled: Boolean(productId),
    ...options,
  });

  const invalidateProductCompanyInfo = (productId) => {
    queryClient.invalidateQueries({
      queryKey: productId
        ? keys.productCompanyInfo.one(productId)
        : keys.productCompanyInfo.all,
    });
  };

  return {
    ...queryObject,
    invalidateProductCompanyInfo,
  };
};
