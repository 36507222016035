import React from 'react';
import classnames from 'classnames';
import { Col, Row, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { getHighlightedText } from './utils';

import './ModuleDisplayName.less';

const { Title } = Typography;

const ModuleDisplayName = React.memo(
  ({ displayName, isActive = false, searchText, children }) => {
    const highlightSearchText = getHighlightedText(displayName, searchText);
    return (
      <Row className='module-name'>
        <Col className='module-name_icon'>
          <span
            className={classnames({
              'module-name_icon-active': isActive,
              'module-name_icon-inactive': !isActive,
            })}
          >
            <RightOutlined />
          </span>
        </Col>
        <Col className='module-name_text'>
          <Title
            strong={true}
            level={5}
            ellipsis={{ tooltip: displayName }}
            style={{ marginBottom: 0 }}
          >
            {highlightSearchText}
          </Title>
          {children}
        </Col>
      </Row>
    );
  }
);

export default ModuleDisplayName;
