import React from 'react';
import { useSelector } from 'react-redux';

import { Row } from 'antd';

import { FavoriteStarIconRender } from 'common/components';
import ProductPackLevel from 'common/components/thumb/product/ProductPackLevel';
import GdsnShieldIcon from 'common/components/thumb/product/GdsnShieldIcon';
import ProductSubscription from 'common/components/thumb/product/ProductSubscription';
import SharedIcon from 'common/components/thumb/share/SharedIcon';
import EditableIcon from 'common/components/thumb/product/EditableIcon';

import { useCheckAllowEditProducts } from 'hooks';

import userSelectors from 'redux/user/selectors';

import { checkIsOwner } from 'utils/checkIsOwner';

import 'common/components/thumb/ThumbnailItem.less';

const AgGridIcons = (props) => {
  const {
    isFavorite,
    isShared,
    packageLevel,
    data = {},
    gdsnShield,
    subscriptionShield,
  } = props;

  const userId = useSelector(userSelectors.makeSelectUserId());

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const { type } = data;

  const renderEditableIcon = () => {
    if (type === 'Product') {
      const showEditableProductIcon = checkAllowEditProductFull(data);

      return showEditableProductIcon ? <EditableIcon /> : null;
    }
  };

  const renderFavoriteIcon = () => {
    return isFavorite ? (
      <div className='ag-grid__favorite-icon'>
        <FavoriteStarIconRender isFavorited={isFavorite} />
      </div>
    ) : null;
  };

  const renderSharedIcon = () => {
    return isShared && !checkIsOwner(data, userId) ? (
      <SharedIcon dataDetail={data} />
    ) : null;
  };

  const renderPackageLevelIcon = () => {
    return packageLevel ? (
      <ProductPackLevel packLevel={packageLevel} id={data?.id} />
    ) : null;
  };

  const renderSubscriptionShieldIcon = () => {
    return subscriptionShield ? (
      <ProductSubscription color={subscriptionShield} />
    ) : null;
  };

  const renderGdsnShieldIcon = () => {
    return gdsnShield ? <GdsnShieldIcon gdsnShield={gdsnShield} /> : null;
  };

  return (
    <Row align='middle' className='ag-grid__icon-list'>
      {renderEditableIcon()}
      {renderFavoriteIcon()}
      {renderSharedIcon()}
      {renderPackageLevelIcon()}
      {renderSubscriptionShieldIcon()}
      {renderGdsnShieldIcon()}
    </Row>
  );
};

export default AgGridIcons;
