import React from 'react';

import { useSelector } from 'react-redux';

import { OtherLayout, ViewLayout, AdvanceStack } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import ActionsRibbonBar from 'common/components/contact-action/ActionsRibbonBar';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import Addition from './components/sections/home-grid/AdditionSection';
import ShowAll from './components/sections/member-grid/ShowAll';
import Download from './components/sections/member-grid/Download';
import MemberFullAction from './components/sections/member-full/MemberFullAction';

import * as companySelectors from 'pages/company/controllers/selectors';

const MemberCompanyFull = () => {
  const companyInfo = useSelector(companySelectors.setlectCompanyInfo());
  const companyDetail = { ...companyInfo, type: 'member' };
  const selectedItemList = [companyDetail];

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Addition type='member' selectedItemList={selectedItemList} />
      <ActionsRibbonBar view='member' selectedItemList={selectedItemList} />
      <RibbonDivider />
      <ShowAll />
      <Download companyInfo={companyInfo} />
      <RibbonDivider />
      <MemberFullAction companyInfo={companyInfo} />
      <RibbonDivider />
      <AdvanceStack disabledAdvanceFilter selectedItemList={selectedItemList} />
      <OtherLayout ribbonSide='right' ribbonType='members' />
    </RibbonBar>
  );
};

export default MemberCompanyFull;
