import React, { useState } from 'react';

import { List, Button, Typography } from 'antd';

import { forwardTo } from 'utils/common/route';

import classnames from 'classnames';
import QaSpecWorkflowReasonModal from './QaSpecWorkflowReasonModal';
import { useActionWorkflow } from 'pages/qa-spec-workflow/hook';

import { useCheckPermissions } from 'hooks';
import {
  PERMISSION_MANAGE_QA_SPEC_WORKFLOW,
  PERMISSION_VIEW_QA_SPECIFICATION,
} from 'static/Permission';

const { Title } = Typography;

const QaSpecWorkflowItem = ({ workflowItem, setVisible }) => {
  const name = workflowItem?.upc12 || workflowItem?.gtin;
  const qaSpecNameApproval = `${name ?? ''} QA Spec Approval`;

  const [showModalReason, setShowModalReason] = useState(false);

  const hasPermissionManageQaSpecWorkflow = useCheckPermissions(
    PERMISSION_MANAGE_QA_SPEC_WORKFLOW
  );

  const hasPermissionViewQaSpec = useCheckPermissions(
    PERMISSION_VIEW_QA_SPECIFICATION
  );

  const onClickViewItem = (workflowId) => {
    forwardTo(`/product/qa-specification/${workflowId}`);
    setVisible(false);
  };

  const { onApproveWorkflow, onRejectWorkflow } = useActionWorkflow({
    setShowModalReason,
    workflowId: workflowItem?.id,
    setVisible,
  });

  const renderButtonQASpecWorkflow = (status) => {
    if (!hasPermissionManageQaSpecWorkflow) return null;

    if (status?.toLowerCase() === 'submitted') {
      return (
        <>
          {hasPermissionViewQaSpec && (
            <Button
              style={{ borderRadius: '4px', marginRight: '10px' }}
              onClick={(event) => {
                onClickViewItem(workflowItem?.id);
              }}
              type='primary'
            >
              View
            </Button>
          )}

          <Button
            onClick={() => onApproveWorkflow(workflowItem?.id)}
            style={{ borderRadius: '4px' }}
            type='primary'
            className='header-task__popover-item--accept-button'
          >
            Approve
          </Button>
          <Button
            style={{ borderRadius: '4px' }}
            onClick={(event) => {
              setShowModalReason(true);
            }}
            danger
          >
            Reject
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            style={{ borderRadius: '4px' }}
            onClick={(event) => {
              onClickViewItem(workflowItem);
            }}
            type='primary'
            className='header-task__popover-item--view-button'
          >
            View
          </Button>
          <span>
            {status?.toLowerCase() === 'approved' ? 'Approved' : 'Rejected'}
          </span>
        </>
      );
    }
  };

  const renderNameQaSpecWorkflow = (title) => {
    return (
      <Title
        ellipsis={{ tooltip: title }}
        level={5}
        style={{
          fontSize: '13px',
          color: 'rgba(0, 0, 0, 0.65)',
        }}
      >
        {title}
      </Title>
    );
  };

  return (
    <>
      <List.Item
        className='header-task__popover-item'
        extra={renderButtonQASpecWorkflow(workflowItem?.status)}
      >
        <List.Item.Meta
          title={renderNameQaSpecWorkflow(qaSpecNameApproval)}
          description={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                className={classnames('header-task__popover-item--status-dot', {
                  'accepted-dot':
                    workflowItem?.status?.toLowerCase() === 'accepted',
                  'waiting-dot':
                    workflowItem?.status?.toLowerCase() === 'submitted',
                })}
              ></div>
              {workflowItem?.status}
            </div>
          }
        />
      </List.Item>
      {showModalReason && (
        <QaSpecWorkflowReasonModal
          onRejectTodo={onRejectWorkflow}
          showModalReason={showModalReason}
          setShowModalReason={setShowModalReason}
        />
      )}
    </>
  );
};

export default QaSpecWorkflowItem;
