import React, { useState, useRef } from 'react';

import { Input, Dropdown, Menu, Card, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { WithLoading } from 'common/components';
import SearchResultItem from './SearchResultItem';

import * as chatServices from 'services/chatServices';
import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/chat';

const { Text } = Typography;

const NewChatSearchBar = () => {
  const intl = useIntl();
  const searchResultRef = useRef(null);

  const [searchState, setSearchState] = useState(null);
  const [searchResult, setSearchResult] = useState([]);

  const menuCardProps = {
    size: 'small',
    bordered: false,
    hoverable: false,
    bodyStyle: {
      backgroundColor: 'transparent',
      textAlign: 'center',
    },
    style: {
      backgroundColor: 'transparent',
    },
  };

  const onSearchHandler = (value) => {
    setSearchState('loading');

    const request = {
      GetCompany: true,
      GetPersonal: true,
      PageIndex: 1,
      PageSize: 20,
    };

    chatServices
      .getThreadList(request)
      .then((response) => {
        const { isSuccess, data } = response;
        if (isSuccess && data.length > 0) {
          searchState('success');
          setSearchResult(data);
          return;
        }
        setSearchState('error');
      })
      .catch(() => setSearchState('error'));
  };

  const getSearchResultComponent = () => {
    const searchComponents = {
      error: renderSearchError,
      success: renderSearchResultList,
      loading: renderSearchLoading,
    };

    return <Menu>{searchState ? searchComponents[searchState]() : null}</Menu>;
  };

  const hideSearchResult = (e) => {
    console.log('e: ', e.target);
    const searchResultEl = searchResultRef.current;
    console.log('searchResultEl: ', searchResultEl);
  };

  useDocumentAttachEvent({
    name: 'click',
    handler: hideSearchResult,
  });

  const renderSearchLoading = () => {
    const loading = searchState === 'loading';
    return (
      <Menu.Item>
        <Card {...menuCardProps}>
          <WithLoading loading={loading} />
        </Card>
      </Menu.Item>
    );
  };

  const renderSearchError = () => {
    return (
      <Menu.Item>
        <Card {...menuCardProps}>
          <Text type='secondary' style={{ textAlign: 'center' }}>
            {intl.formatMessage(Messages.cannotSearch)}
          </Text>
        </Card>
      </Menu.Item>
    );
  };

  const renderSearchResultList = () => {
    return searchResult?.map((result) => {
      return (
        <Menu.Item key={result.id}>
          <SearchResultItem result={result} />
        </Menu.Item>
      );
    });
  };

  const searchResultComponent = getSearchResultComponent();

  return (
    <Dropdown overlay={searchResultComponent} visible={searchState}>
      <Input.Search
        className='new-chat__search'
        prefix={<SearchOutlined />}
        onSearch={onSearchHandler}
      />
    </Dropdown>
  );
};

export default NewChatSearchBar;
