import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  loading: false,
  memberContact: null,
  contactLocation: null,
  memberBilling: null,
  activityLog: null,
  contactHistory: null,
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const memberColumnGridReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MEMBER_CONTACT_GRID:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_MEMBER_CONTACT_GRID_SUCCESS:
        draft.loading = false;
        draft.memberContact = action.columns;
        draft.error = null;
        break;
      case types.GET_MEMBER_CONTACT_GRID_ERROR:
        draft.loading = false;
        draft.memberContact = null;
        draft.error = action.error;
        break;
      case types.GET_CONTACT_LOCATION_GRID:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_CONTACT_LOCATION_GRID_SUCCESS:
        draft.loading = false;
        draft.contactLocation = action.columns;
        draft.error = null;
        break;
      case types.GET_CONTACT_LOCATION_GRID_ERROR:
        draft.loading = false;
        draft.contactLocation = null;
        draft.error = action.error;
        break;
      case types.GET_MEMBER_BILLING_GRID:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_MEMBER_BILLING_GRID_SUCCESS:
        draft.loading = false;
        draft.memberBilling = action.columns;
        draft.error = null;
        break;
      case types.GET_MEMBER_BILLING_GRID_ERROR:
        draft.loading = false;
        draft.memberBilling = null;
        draft.error = action.error;
        break;
      case types.GET_MEMBER_ACTIVITY_LOG_GRID:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_MEMBER_ACTIVITY_LOG_GRID_SUCCESS:
        draft.loading = false;
        draft.activityLog = action.columns;
        draft.error = null;
        break;
      case types.GET_MEMBER_ACTIVITY_LOG_GRID_ERROR:
        draft.loading = false;
        draft.activityLog = null;
        draft.error = action.error;
        break;
      case types.GET_MEMBER_CONTACT_HISTORY_GRID:
        draft.loading = true;
        draft.error = null;
        break;
      case types.GET_MEMBER_CONTACT_HISTORY_GRID_SUCCESS:
        draft.loading = false;
        draft.contactHistory = action.columns;
        draft.error = null;
        break;
      case types.GET_MEMBER_CONTACT_HISTORY_GRID_ERROR:
        draft.loading = false;
        draft.contactHistory = null;
        draft.error = action.error;
        break;
    }
  });

export default memberColumnGridReducer;
