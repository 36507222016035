import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { DoubleRightOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import gridSelector from 'common/components/grid-view/controllers/selectors';

import SupplierManagerModal from 'pages/company-profile/components/tabs/system/supplier-management/components/SupplierManagerModal';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useInitialWorkflowQaSpec } from 'hooks/useInitialWorkflowQaSpec';
import { useCheckDisableInitialWorkflowSpec } from 'hooks/useCheckDisableInitialWorkflowSpec';

const AssignProductToSupplier = (props) => {
  const { productData, isProductDetail } = props;
  const reloadPage = useDispatchReloadPage();

  const [visible, setVisible] = useState(false);

  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const productId = productData?.productId ?? null;

  const { isInitialWorkflowToProduct } = useInitialWorkflowQaSpec();
  const isDisableInitialQaSpecWorkflow = useCheckDisableInitialWorkflowSpec({
    productList: selectedItemDetailList,
    productId,
  });

  const handleSetVisible = () => {
    setVisible(true);
  };

  const handleReloadPage = () => {
    reloadPage();
  };

  return (
    <>
      {isInitialWorkflowToProduct ? (
        <ButtonSmallIcon
          className='button-item-dropdown'
          icon={<DoubleRightOutlined />}
          textDisplay='Initiate QA Specification Workflow'
          onClick={handleSetVisible}
          disabled={isDisableInitialQaSpecWorkflow}
        />
      ) : null}

      {visible && (
        <SupplierManagerModal
          isEdit={false}
          visible={visible}
          setVisible={setVisible}
          title='Initiate QA Specification Workflow'
          isPIMAction={true}
          productSelected={
            isProductDetail
              ? [{ ...productData, id: productData?.productId }]
              : selectedItemDetailList
          }
          isProduct={true}
          isProductDetail={isProductDetail}
          setReloadSupplierList={handleReloadPage}
          isWorkflowModal={true}
          productId={productId}
        />
      )}
    </>
  );
};

AssignProductToSupplier.propTypes = {};

export default AssignProductToSupplier;
