import * as api from 'config/axios';
import * as endpoints from './endpoints';

// Get all folder list
export const getFolderList = (params) => {
  return api.sendPost(endpoints.GET_FOLDER_GRID_VIEW, params);
};

//
export const getItemsOfFolder = (params) => {
  return api.sendPost(endpoints.GET_FOLDER_DETAIL_GRID_THUMB, params);
};

// Get all folder list
export const getFavoriteFolderList = (params) => {
  return api.sendPost(endpoints.FAVORITE_FOLDER_LIST_ENDPOINT, params);
};
