import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/maintenance';
import RibbonButton from 'common/components/button/RibbonButton';

import * as eulaMaintenanceActions from 'pages/eula-maintenance/controllers/actions';
import gridSelector from 'common/components/grid-view/controllers/selectors';

import { forwardTo } from 'utils/common/route';

const CloneEula = () => {
  const dispatch = useDispatch();

  const selectedEulaDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const eulaId =
    selectedEulaDetail?.length > 0 ? selectedEulaDetail[0].id : undefined;

  const handleClickClone = () => {
    dispatch(eulaMaintenanceActions.enableEditEula());
    forwardTo(`/maintenance/eula/clone/${eulaId}`);
  };

  return (
    <RibbonButton
      icon={<CopyOutlined />}
      label={Messages.cloneEula}
      onClick={handleClickClone}
      disabled={eulaId === undefined || selectedEulaDetail.length !== 1}
    />
  );
};

export default CloneEula;
