import React from 'react';

import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { RibbonBar, RibbonButton, RibbonDivider } from 'common/components';
import { Can } from 'context/Can';
import {
  AdvanceStack,
  DetailSection,
  ManageSharingSection,
  ViewLayout,
} from 'pages/home/ribbon/components';

import Messages from 'i18n/messages/maintenance';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import BackToEula from 'pages/home/ribbon/components/controls/maintenance/eula/BackToEula';
import { forwardTo } from 'utils/common/route';

const EulaRibbon = (props) => {
  const { loading, disabled, onSubmit } = props;

  const handleCancelEula = () => {
    forwardTo('/maintenance/eula/list');
  };

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.EULA}>
        <RibbonButton
          icon={<SaveOutlined />}
          label={Messages.saveEula}
          onClick={onSubmit}
          loading={loading}
        />
        <RibbonButton
          icon={<CloseCircleOutlined />}
          label={Messages.cancelEula}
          onClick={handleCancelEula}
        />
        <RibbonDivider />
      </Can>
      <AdvanceStack isDisabled />
      <BackToEula ribbonSide='right' />
    </RibbonBar>
  );
};

export default EulaRibbon;
