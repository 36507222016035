import React from 'react';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as types from './constants';
import * as actions from './actions';
import { DEFAULT_SORT } from 'static/Constants';
import { Button } from 'antd';

import {
  getMemberProfileHeader,
  getMemberOverviewAsset,
  getMemberOverviewDocument,
  getMemberOverviewBrand,
  getMemberContactHistory,
  getMemberShortDetail,
  getPublicContact,
  editMemberShowingOptions,
  getMemberDocument,
  deleteMemberDocument,
} from 'services/members';
import * as services from 'services/members';
import * as servicesGrid from 'services/grid';
import * as memberFulltypes from 'pages/home/ribbon/member-full/controllers/constants';
import moment from 'moment';
import IconRenderer from '../components/tabs/crm/activity/IconRenderer';
import {
  MemberBillingIconRenderer,
  MemberContactIconRenderer,
  MemberLocationIconRenderer,
} from 'common/components/icon-renderer/IconRenderer';
import * as filterService from './filterService';
import { formatMDYWithParam } from 'utils/formatDate';

export function* memberProfileHeaderSaga(action) {
  try {
    const response = yield call(getMemberProfileHeader, {
      MemberId: action.MemberId,
    });

    if (response.isSuccess && response.data) {
      yield put(actions.loadMemberProfile(response.data));
      return;
    }

    yield put({ type: types.GET_MEMBER_ERROR, error: 'no-data' });
  } catch (error) {
    yield put({ type: types.GET_MEMBER_ERROR, error });
  }
}

// BP 08/31/2020: TODO need to review more carefully
export function* memberContactHistorySaga(payload) {
  try {
    const response = yield call(getMemberContactHistory, { ...payload });
    yield put(actions.getMemberContactHistorySuccess(response));
  } catch (error) {
    yield put({ type: types.GET_MEMBER_CONTACT_HISTORY_ERROR, error });
  }
}

export function* fetchMemberDocument(payload) {
  try {
    const props = payload.payload;
    const response = yield call(getMemberOverviewDocument, {
      MemberId: props.memberId,
      PageIndex: props.currentPage,
      PageSize: props.pageSize,
    });
    yield put({
      type: types.FETCH_MEMBER_OVERVIEW_DOCUMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_MEMBER_OVERVIEW_DOCUMENT_ERROR,
      error,
    });
  }
}

export function* fetchPublicContact(payload) {
  try {
    const response = yield call(getPublicContact, {
      memberId: Number(payload.memberId),
      pageIndex: 1,
      pageSize: 10,
    });
    yield put({
      type: types.GET_PUBLIC_CONTACT_SUCCESS,
      data: response?.data?.gridData,
    });
  } catch (error) {
    yield put({
      type: types.GET_PUBLIC_CONTACT_ERROR,
      error,
    });
  }
}

// get contacts by memberId
export function* getContactMemberSaga(payload) {
  try {
    const response = yield call(services.getMemberContact, {
      ...payload.params,
      memberId: Number(payload.params.memberId),
    });
    yield put(actions.getMemberContactsSuccess(response.data));
  } catch (error) {
    yield put(actions.getMemberContactsError(error));
  }
}

export function* getMemberBrandsSaga(payload) {
  try {
    const response = yield call(services.getMemberOverviewBrands, {
      ...payload.params,
      memberId: Number(payload.params.memberId),
      sort: DEFAULT_SORT,
    });
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getMemberBrandsSuccess(data));
    } else {
      yield put(actions.getMemberBrandsError(message));
    }
  } catch (error) {
    yield put(actions.getMemberBrandsError(error));
  }
}

export function* getMemberInformationSheetSaga(payload) {
  try {
    const response = yield call(services.getMemberInformationSheetGrid, {
      ...payload.params,
      memberId: Number(payload.params.memberId),
      sort: DEFAULT_SORT,
    });
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getMemberInformationSheetSuccess(data));
    } else {
      yield put(actions.getMemberInformationSheetError(message));
    }
  } catch (error) {
    yield put(actions.getMemberInformationSheetError(error));
  }
}

// Date Formatter
function dateFormatter(params) {
  return moment(params.value).format('MM/DD/YYYY');
}

// GET MEMBER BILLING GRID
const renderIconBilling = (params) => {
  return <MemberBillingIconRenderer item={params} />;
};

export function* gridColumnMemberBilling(payload) {
  try {
    const response = yield call(
      filterService.getColumnAndFilterGridTable,
      servicesGrid.gridColumnInfo,
      payload
    );

    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 50,
        resizable: true,
      },
    ];

    if (response?.columns?.length > 0) {
      response.columns.forEach((col) => {
        if (col.fieldNameCamelCase === 'id') {
          col = { ...col, width: 120 };
        } else if (col.dataType === 'datetime') {
          col = {
            ...col,
            flex: 1,
            cellRenderer: dateFormatter,
            sort: col.displayName === 'TransactionDate' ? 'desc' : '',
            minWidth: col.displayName === 'TransactionDate' && 200,
          };
        } else if (col.fieldNameCamelCase === 'type') {
          col = {
            ...col,
            headerName: '',
            cellRenderer: renderIconBilling,
            width: 100,
          };
        } else if (col.fieldNameCamelCase === 'balance') {
          col = { ...col, width: 150 };
        } else {
          col = { ...col, flex: 1 };
        }
        columns.push({ ...col, resizable: true });
      });

      [columns[1], columns[2]] = [columns[2], columns[1]];
    }
    yield put(actions.gridColumnMemberBillingSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnMemberBillingError(error));
  }
}

export function* getColumnDocumentDetailList(payload) {
  try {
    const { response } = yield call(
      servicesGrid.gridColumnInfo,
      payload.gridName
    );

    const checkboxColumn = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        resizable: true,
      },
    ];

    let result = [];

    if (response && response.columns && response.columns.length > 0) {
      response.columns.forEach((val, index) => {
        if (
          val.fieldNameCamelCase === 'banByMemberName' ||
          val.fieldNameCamelCase === 'bannedMemberName'
        ) {
          result.push({ ...val, resizable: true, width: 240 });
        } else if (val.fieldNameCamelCase === 'banByUserId') {
          result.push({
            ...val,
            resizable: true,
            width: 100,
            headerName: 'Owner',
          });
        }
      });
    }
    yield put(
      actions.documentDetailColumnInfoSuccess([...checkboxColumn, ...result])
    );
  } catch (error) {
    yield put(actions.documentDetailColumnInfoError(error));
  }
}

// GET MEMBER CONTACT GRID
const renderIconContact = (params) => {
  return <MemberContactIconRenderer item={params} />;
};

const renderCityState = (params) => {
  if (params?.data?.city || params?.data?.state)
    return (
      <>
        {params.data.city}, {params.data.state}
      </>
    );
  else return <></>;
};

export function* gridColumnMemberContact(payload) {
  try {
    const response = yield call(
      filterService.getColumnAndFilterGridTable,
      servicesGrid.gridColumnInfo,
      payload
    );
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 50,
        resizable: true,
      },
      {
        field: '',
        separateFieldName: ['status', 'isPrimaryContact'],
        isShowFilter: 'none',
        width: 100,
        cellRenderer: renderIconContact,
        resizable: true,
      },
    ];
    if (response?.columns?.length > 0) {
      const hiddenCol = ['id', 'isPublic', 'status', 'isPrimaryContact'];
      response.columns.forEach((col, index) => {
        if (!hiddenCol.includes(col.fieldNameCamelCase)) {
          if ('accountExpires' === col?.fieldNameCamelCase)
            col = { ...col, minWidth: 250, cellRenderer: formatMDYWithParam };
          columns.push({ ...col, flex: 1, resizable: true });
        }
      });
    }
    [columns[2], columns[3], columns[4], columns[5], columns[6], columns[7]] = [
      columns[2],
      columns[3],
      columns[4],
      columns[6],
      columns[7],
      columns[5],
    ];
    yield put(actions.gridColumnMemberContactSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnMemberContactError(error));
  }
}

// GET MEMBER LOCATION GRID
const renderIconLocation = (params) => {
  return <MemberLocationIconRenderer item={params} />;
};

export function* gridColumnMemberCompanyLocation(payload) {
  try {
    const response = yield call(
      filterService.getColumnAndFilterGridTable,
      servicesGrid.gridColumnInfo,
      payload
    );

    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 50,
        resizable: true,
      },
      {
        field: '',
        width: 120,
        isShowFilter: 'none',
        separateFieldName: ['isPrimaryAddress', 'status', 'addressType'],
        cellRenderer: renderIconLocation,
        resizable: true,
      },
    ];

    if (response?.columns?.length > 0) {
      // BP 12/28/2020 - add latitude, longitude into removed columns
      let hiddenCol = [
        'id',
        'isPrimaryAddress',
        'status',
        'addressType',
        'latitude',
        'longitude',
      ];
      response.columns.forEach((col, index) => {
        if (hiddenCol.indexOf(col.fieldNameCamelCase) === -1) {
          col = { ...col, flex: 1, resizable: true };
          columns.push(col);
        }
      });
    }

    yield put(actions.gridColumnContactLocationSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnContactLocationError(error));
  }
}

// GET MEMBER ACTIVITY LOG GRID
const renderIcon = (params) => {
  return <IconRenderer item={params} />;
};

export function* gridColumnMemberActivityLog(payload) {
  try {
    const response = yield call(
      filterService.getColumnAndFilterGridTable,
      servicesGrid.gridColumnInfo,
      payload
    );
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 50,
        resizable: true,
      },
      {
        field: '',
        cellRenderer: renderIcon,
        width: 100,
        resizable: true,
      },
    ];

    if (response && response.columns && response.columns.length > 0) {
      response.columns.forEach((val, index) => {
        if (
          val.fieldNameCamelCase !== 'id' &&
          val.fieldNameCamelCase !== 'isInBound' &&
          val.fieldNameCamelCase !== 'originatorUserId' &&
          val.fieldNameCamelCase !== 'mostRecentUserId'
        ) {
          if (
            val.fieldNameCamelCase === 'messageDate' ||
            val.fieldNameCamelCase === 'lastActivity'
          ) {
            if (val.fieldNameCamelCase === 'messageDate') {
              val = { ...val, fieldNameCamelCase: 'date' };
            }
            val = { ...val, cellRenderer: dateFormatter, width: 130 };
          } else if (val.fieldNameCamelCase === 'description') {
            val = { ...val };
          } else if (val.fieldNameCamelCase === 'member') {
            val = { ...val, headerName: 'Company', minWidth: 150, flex: 1 };
          } else if (val.fieldNameCamelCase === 'originatorUser') {
            val = { ...val, headerName: 'Originator' };
          } else if (val.fieldNameCamelCase === 'mostRecentUser') {
            val = { ...val, headerName: 'Most Recent' };
          } else if (val.fieldName === 'Type') {
            val = { ...val, width: 100 };
          }
          columns.push({ ...val, resizable: true });
        }
      });
      [columns[2], columns[3], columns[4]] = [
        columns[3],
        columns[4],
        columns[2],
      ];
    }
    yield put(actions.gridColumnActivityLogSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnActivityLogError(error));
  }
}

// GET MEMBER CONTACT HISTORY
export function* gridColumnMemberContactHistory(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
    let columns = [];

    if (response.columns && response.columns.length > 0) {
      let columns = [];
      response.columns.forEach((val) => {
        columns.push({ fieldName: val.fieldName });
      });
    }
    yield put(actions.gridColumnMemberContactHistorySuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnMemberContactHistoryError(error));
  }
}

export function* gridColumnPublicContact(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 50,
        resizable: true,
      },
    ];
    if (response.columns && response.columns.length > 0) {
      response.columns.forEach((val) => {
        if (
          val.fieldNameCamelCase !== 'visibility' &&
          val.fieldNameCamelCase !== 'showInMemberPreview' &&
          val.fieldNameCamelCase !== 'id' &&
          val.fieldNameCamelCase !== 'name' &&
          val.fieldNameCamelCase !== 'latitude' &&
          val.fieldNameCamelCase !== 'longitude'
        ) {
          // if (val.fieldNameCamelCase !== 'showInMemberPreview') {
          //   columns.push({ ...val });
          // } else
          columns.push({ ...val, filter: false, suppressMenu: true });
        }
      });
    }

    yield put(actions.gridColumnPublicContactSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnPublicContactError(error));
  }
}

export function* getMemberShortDetailPreview(payload) {
  try {
    const response = yield call(getMemberShortDetail, {
      Id: payload.id,
    });
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getMemberShortDetailPreviewSuccess(data));
    } else {
      yield put(actions.getMemberShortDetailPreviewError(message));
    }
  } catch (error) {
    yield put(actions.getMemberShortDetailPreviewError(error));
  }
}

export function* getMemberDocumentDetail(payload) {
  try {
    const { dataParams } = payload;
    const response = yield call(getMemberDocument, {
      pageIndex: dataParams?.pageIndex,
      pageSize: dataParams?.pageSize,
      memberId: dataParams?.memberId,
    });
    const { data, isSuccess } = response;
    if (isSuccess) {
      yield put(actions.getDocumentDetailSuccess(data));
    } else {
      yield put(actions.getDocumentDetailError('error'));
    }
  } catch (error) {
    yield put(actions.getDocumentDetailError(error));
  }
}

export function* deleteMemberDocumentDetail(payload) {
  try {
    const { dataParams } = payload;
    const response = yield call(deleteMemberDocument, {
      ids: dataParams?.idList,
      isRemovedFromShowcase: dataParams?.isRemove,
      memberId: dataParams?.memberId,
      forMemberDocument: dataParams?.forMemberDocument,
    });
    const { data, isSuccess } = response;
    if (isSuccess) {
      yield put(
        actions.getDocumentDetail({
          memberId: dataParams.memberId,
          pageIndex: dataParams.currentPage,
          pageSize: dataParams.pageSize,
        })
      );
    } else {
      yield put(actions.deleteDocumentDetailError('error'));
    }
  } catch (error) {
    yield put(actions.deleteDocumentDetailError(error));
  }
}

function* watchAll() {
  yield all([
    takeLatest(types.GET_MEMBER_CONTACT_HISTORY, memberContactHistorySaga),
    takeLatest(types.GET_MEMBER_PENDING, memberProfileHeaderSaga),
    takeLatest(memberFulltypes.FINISH_EDIT_MEMBER, memberProfileHeaderSaga),
    takeLatest(types.GET_MEMBER_CONTACT_GRID, gridColumnMemberContact),
    takeLatest(types.GET_MEMBER_BILLING_GRID, gridColumnMemberBilling),
    takeLatest(
      types.GET_CONTACT_LOCATION_GRID,
      gridColumnMemberCompanyLocation
    ),
    takeLatest(types.GET_MEMBER_ACTIVITY_LOG_GRID, gridColumnMemberActivityLog),
    takeLatest(
      types.GET_MEMBER_CONTACT_HISTORY_GRID,
      gridColumnMemberContactHistory
    ),
    takeLatest(types.GET_PUBLIC_CONTACT_COLUMN, gridColumnPublicContact),
    takeLatest(
      types.GET_MEMBER_SHORT_DETAIL_PREVIEW,
      getMemberShortDetailPreview
    ),
    takeLatest(types.GET_PUBLIC_CONTACT, fetchPublicContact),
    takeLatest(types.GET_MEMBER_CONTACTS, getContactMemberSaga),
    takeLatest(types.GET_MEMBER_OVERVIEW_BRANDS, getMemberBrandsSaga),
    takeLatest(
      types.GET_MEMBER_INFORMATION_SHEET,
      getMemberInformationSheetSaga
    ),
    takeLatest(types.DOCUMENT_DETAIL_COLUMN_INFO, getColumnDocumentDetailList),
    takeLatest(types.GET_DOCUMENT_DETAIL, getMemberDocumentDetail),
    takeLatest(types.DELETE_DOCUMENT_DETAIL, deleteMemberDocumentDetail),
  ]);
}

export default watchAll;
