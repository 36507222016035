import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Checkbox, Row, Col } from 'antd';

import { Form, StyledModal } from 'common/components';

import { memberManagementFeatures } from 'static/Constants';
import { apiHandler } from 'utils/api';
import * as memberManagementService from 'services/memberMaintenance';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import * as memberActions from 'pages/company-profile/controllers/actions';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/maintenance';

const MemberManagementEditModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();

  const { selectedItemList, setVisible, visible } = props;

  const [loading, setLoading] = useState(false);

  const onCancelHandler = () => {
    !loading && setVisible(false);
  };

  const onOkHandler = () => {
    form.validateFields().then((values) => {
      callApiEditMember(values);
    });
  };

  const callApiEditMember = (values) => {
    setLoading(true);

    const params = {
      memberId: selectedItemList?.id,
      ...values,
    };

    const successMessage = intl.formatMessage(Messages.editMemberSuccess);
    const errorMessage = intl.formatMessage(Messages.editMemberError);

    apiHandler({
      service: memberManagementService.editMemberManagement,
      params,
      successMessage,
      errorMessage,
      successCallback: editSuccessCallback,
      onFinally: hideLoading,
    });
  };

  const editSuccessCallback = () => {
    dispatch(memberActions.getMemberProfileHeader(selectedItemList?.id));
    reloadPage();
    setVisible(false);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  const initializeFormValues = () => {
    form.setFieldsValue(selectedItemList);
  };

  useEffect(() => {
    if (visible) initializeFormValues();
    //eslint-disable-next-line
  }, [visible]);

  //* render
  const renderFields = () => {
    return (
      <Row gutter={[16, 8]}>
        {memberManagementFeatures.map((field) => {
          return (
            <Col span={12} key={field}>
              <Form.Item
                name={field}
                valuePropName='checked'
                style={{ marginBottom: 0 }}
              >
                <Checkbox>{intl.formatMessage(Messages[field])}</Checkbox>
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    );
  };

  const okBtnProp = { loading };
  const cancelBtnProp = { disabled: loading };

  return (
    <StyledModal
      visible={visible}
      closeable={false}
      onCancel={onCancelHandler}
      onOk={onOkHandler}
      okButtonProps={okBtnProp}
      cancelButtonProps={cancelBtnProp}
      okText={intl.formatMessage(Messages.editMemberModalOkBtn)}
      title={intl.formatMessage(Messages.editMemberModalTitle)}
    >
      <Form form={form} layout='vertical'>
        {renderFields}
      </Form>
    </StyledModal>
  );
};

export default MemberManagementEditModal;
