import React, { useState, useMemo } from 'react';
import ProductHeader from './ProductHeader';
import ProductMediaContent from './ProductMediaContent';
import './ProductMediaArea.less';

const ProductMediaArea = (props) => {
  const {
    productDetail,
    getImagery,
    getProductNutritionFacts,
    getProductDetail,
  } = props;

  const isOwner = productDetail?.isOwner;

  return (
    <>
      <div className='product-media-area'>
        <ProductHeader isOwner={isOwner} productDetail={productDetail} />
        <ProductMediaContent
          getImagery={getImagery}
          getProductNutritionFacts={getProductNutritionFacts}
          isOwner={isOwner}
          getProductDetail={getProductDetail}
        />
      </div>
    </>
  );
};
export default ProductMediaArea;
