import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const saveRequiredFields = (params) => {
  return api.sendPost(endpoints.SAVE_REQUIRED_FIELDS, params);
};

export const getRequiredFields = (params) => {
  return api.sendPost(endpoints.GET_ADDITIONAL_REQUIRED_FIELDS_GRID, params);
};

export const getRequiredFieldShortDetail = (params) => {
  return api.sendGet(endpoints.GET_REQUIRED_FIELD_SHORT_DETAIL, { params });
};

// Publish
export const getRequiredFieldShortPanel = (params) => {
  return api.sendGet(endpoints.GET_REQUIRED_FIELD_SHORT_DETAIL, { params });
};
export const deleteAdditionalRequiredFields = (data) => {
  return api.sendPost(endpoints.DELETE_ADDITIONAL_REQUIRED_FIELDS, data);
};
