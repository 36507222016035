import { useQuery } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';

import * as services from 'services/roles-security';

export const getListByMemberKey = {
  itemData: (memberId) => ['get-roles-by-member', parseInt(memberId)],
};

export const useGetRolesListByMember = ({ memberId, ...otherOptions } = {}) =>
  useQuery({
    queryKey: getListByMemberKey.itemData(memberId),
    queryFn: async () => {
      const response = await services.getRoleList({
        memberId: memberId,
      });

      if (response?.isSuccess) {
        return response?.data?.roleInfos;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
    },
    enabled: Boolean(memberId),
    ...otherOptions,
  });
