import React, { cloneElement } from 'react';

const newSpanConfigDetect = /\(\#(.*?)(color=|font=|size=)(.*?)\)/g;
const spanFontConfig = /font=(.*?)[\|\)]/;
const spanColorConfig = /color=(.*?)[\|\)]/;
const spanSizeConfig = /size=(.*?)[\|\)]/;
const spanAlignConfig = /align=(.*?)[\|\)]/;
const spanBreakAlignConfig = /breakAlign=(.*?)[\|\)]/;

const getConfig = (child) => {
  const firstValue = child.props.value || '';
  const configMatch = firstValue.match(newSpanConfigDetect);

  const colorConfig = spanColorConfig.exec(configMatch);
  const fontConfig = spanFontConfig.exec(configMatch);
  const alignConfig = spanAlignConfig.exec(configMatch);
  const sizeConfig = spanSizeConfig.exec(configMatch);
  const breakAlignConfig = spanBreakAlignConfig.exec(configMatch);

  let detailColorConfig,
    detailFontConfig,
    detailAlignConfig,
    detailSizeConfig,
    detailBreakAlignConfig;

  if (colorConfig) {
    detailColorConfig = colorConfig[1].trim();
  }
  if (fontConfig) {
    detailFontConfig = fontConfig[1].trim();
  }
  if (alignConfig) {
    detailAlignConfig = alignConfig[1].trim();
  }
  if (sizeConfig) {
    detailSizeConfig = sizeConfig[1].trim();
  }
  if (breakAlignConfig) {
    detailBreakAlignConfig = breakAlignConfig[1].trim();
  }
  return {
    configMatch,
    detailColorConfig,
    detailFontConfig,
    detailAlignConfig,
    detailSizeConfig,
    detailBreakAlignConfig,
  };
};

const HeadingRender = (props) => {
  const { level, children } = props;
  let generalConfig;

  const HeadLevelRender = (level, children, style = {}) => {
    switch (level) {
      case 1:
        return <h1 style={style}>{children && children}</h1>;
      case 2:
        return <h2 style={style}>{children && children}</h2>;
      case 3:
        return <h3 style={style}>{children && children}</h3>;
      case 4:
        return <h4 style={style}>{children && children}</h4>;
      case 5:
        return <h5 style={style}>{children && children}</h5>;
      case 6:
        return <h6 style={style}>{children && children}</h6>;
      default:
        return <h6 style={style}>{children && children}</h6>;
    }
  };

  const renderChildren = () => {
    if (children?.length === 1) {
      generalConfig = getConfig(props.children?.[0]);

      let nextValue, cloneChildren;
      if (generalConfig?.configMatch) {
        nextValue = props.children?.[0]?.props?.value.replace(
          generalConfig?.configMatch[0],
          ''
        );
        cloneChildren = cloneElement(props.children[0], {
          value: nextValue,
          children: nextValue,
        });

        const cusStyle = {
          color: generalConfig?.detailColorConfig,
          fontFamily: generalConfig?.detailFontConfig,
          fontSize: `${generalConfig?.detailSizeConfig}px`,
          textAlign: generalConfig?.detailAlignConfig,
          clear: generalConfig?.detailBreakAlignConfig === 'true' ? 'both' : '',
        };
        return (
          <>{HeadLevelRender(level, cloneChildren || children, cusStyle)}</>
        );
      }
    }

    if (children?.length > 1) {
      generalConfig = getConfig(props.children?.[0]);

      let nextValue, cloneChildren;
      if (generalConfig?.configMatch) {
        nextValue = props.children?.[0]?.props?.value.replace(
          generalConfig?.configMatch[0],
          ''
        );
        cloneChildren = cloneElement(props.children[0], {
          value: nextValue,
          children: nextValue,
        });

        const cusStyle = {
          color: generalConfig?.detailColorConfig,
          fontFamily: generalConfig?.detailFontConfig,
          fontSize: `${generalConfig?.detailSizeConfig}px`,
          textAlign: generalConfig?.detailAlignConfig,
          clear: generalConfig?.detailBreakAlignConfig === 'true' ? 'both' : '',
        };
        return (
          <>
            {HeadLevelRender(
              level,
              (
                <>
                  {cloneChildren}
                  {children.map((child, idx) => {
                    if (idx === 0) return null;
                    return child;
                  })}
                </>
              ) || children,
              cusStyle
            )}
          </>
        );
      }
    }
    return HeadLevelRender(level, children);
  };

  return <> {renderChildren()}</>;
};

export default HeadingRender;
