import React from 'react';
import { RibbonBar, RibbonDivider } from 'common/components';
import {
  ManageSharingSection,
  DetailSection,
  ViewLayout,
  AdvanceStack,
} from 'pages/home/ribbon/components';
import {
  OpenTicketingSystem,
  CreateTicketingSystem,
  DeleteTicketingSystem,
  FilterTicketingSystem,
  ExportTicketingSystem,
} from 'pages/home/ribbon/ticketing-system/components';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import './TicketingSystemRibbon.less';

const TicketingSystemRibbon = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <OpenTicketingSystem />
      <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.TICKET_SYSTEM}>
        <CreateTicketingSystem />
      </Can>
      <Can I={ABILITY_ACTION.DELETE} a={ABILITY_SUBJECT.TICKET_SYSTEM}>
        <DeleteTicketingSystem />
      </Can>
      <RibbonDivider />
      <FilterTicketingSystem />
      <ExportTicketingSystem />
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default TicketingSystemRibbon;
