export const MULTIPLE_PANEL_ALLERGENS = [
  'Milk and its derivatives',
  'Eggs and its derivatives',
  'Fish and its derivatives',
  'Shellfish and its derivatives',
  'Tree nuts (Nuts) and its derivatives',
  'Wheat and its derivatives',
  'Peanuts and its derivatives',
  'Soybean and its derivatives',
  'Sesame Seeds and its derivatives',
  'Crustaceans and its derivatives',
  'Tuna and its derivatives',
  'Cod and its derivatives',
  'Salmon and its derivatives',
  'Bass and its derivatives',
  'Crab and its derivatives',
  'Lobster and its derivatives',
  'Shrimp and its derivatives',
  'Almonds and its derivatives',
  'Cashews and its derivatives',
  'Walnuts and its derivatives',
  'Pecan Nuts and its derivatives',
  'Coconuts and its derivatives',
];

export const MULTIPLE_PANEL_ALLERGEN_FIELDS = [
  {
    fieldName: 'allergenType',
    displayName: 'Allergen',
    type: 'string',
    disabled: true,
    required: true,
  },
  {
    fieldName: 'isPresent',
    displayName: 'Is Present',
    type: 'boolean',
  },
  {
    fieldName: 'levelOfContainment',
    displayName: 'Level Of Containment',
    type: 'string',
    listName: 'LevelOfContainmentCodeEnum',
    // required: true,
  },
  {
    fieldName: 'containmentSource',
    displayName: 'Containment Source',
    type: 'string',
    listName: 'ContainmentSourceEnum',
  },
];

export const SHOW_FIELDS = [
  'allergenType',
  'levelOfContainment',
  'isPresent',
  'containmentSource',
];

export const MAX_ALLERGENS_PER_VIEW = 2;
