import produce from 'immer';
import * as types from './constants';
import _ from 'lodash';

import { getData, setData } from 'utils/sesionStorage';
import { DEFAULT_OPTIONS } from 'static/Constants';

// initial state

export const defaultSearchText = {
  memberText: '',
  digitalMediaText: '',
  digitalMediaNewText: '',
  assetText: '',
  documentText: '',
  multiMediaText: '',
  reportingsText: '',
  reportingsOwnedText: '',
  reportingsSharedText: '',
  favoriteQueriesText: '',
  memberMaintenanceText: '',
  memberMaintenanceUserText: '',
  userMaintenanceText: '',
  favMemberText: '',
  favAssetText: '',
  favProductText: '',
  favFolderText: '',
  folderText: '',
  folderOwnedText: '',
  folderSharedText: '',
  wfDefinitionText: '',
  todoList: '',
  awaitingList: '',
  meetingList: '',
  productText: '',
  productForMemberText: '',
  transitionPageText: '',
  ssoApprovalMemberText: '',
  maintenanceFormText: '',
  eulaCardText: '',
  dataSyncTimeText: '',
  qcTimeText: '',
  ticketingSystemText: '',
  qaSpecWorkflowText: '',
};

interface mdTableActionDefine {
  type: 'INSERT' | 'REPLACE' | null;
  payload: string;
  count: number;
}

const defaultMdTableAction: mdTableActionDefine = {
  type: null,
  payload: '',
  count: 0,
};

export const openItemsDefault = {
  items: [],
  isAdding: false,
  isAddingFormData: false,
  currentTab: {
    items: [],
    urlPath: null,
  },
  currentOpenItem: null,
};

export const initialState = {
  loading: false,
  eula: false,
  environment: '',
  acceptedEula: false,
  personalization: false,
  criticalMessage: false,
  landingPage: false,
  controlOptions: [],
  searchCriteria: 'all',

  selectedKey: null,
  favorites: {
    queries: 0,
    sharedQueries: 0,
    products: 0,
    assets: 0,
    members: 0,
  },
  detail: false,
  inContainerDetail: false,
  quickView: false,

  logging: getData('logging') || [],
  showDetail: false,
  showSearchBar: true,
  showRibbonBar: true,
  searchAllItems: '',
  searchText: {
    memberText: '',
    favMemberText: '',
    favAssetText: '',
    digitalMediaText: '',
    digitalMediaNewText: '',
    assetText: '',
    documentText: '',
    multiMediaText: '',
    reportingsText: '',
    reportingsOwnedText: '',
    reportingsSharedText: '',
    favoriteQueriesText: '',
    memberMaintenanceText: '',
    memberMaintenanceUserText: '',
    userMaintenanceText: '',
    assetForMemberText: '',
    folderText: '',
    folderOwnedText: '',
    folderSharedText: '',
    favFolderText: '',
    productText: '',
    productForMemberText: '',
    transitionPageText: '',
    ssoApprovalMemberText: '',
    maintenanceFormText: '',
    eulaCardText: '',
    dataSyncTimeText: '',
    qcTimeText: '',
    ticketingSystemText: '',
    qaSpecWorkflowText: '',
  },
  uploadDownloadProgress: [],
  minimizedProgressBar: false,
  hiddenProgressBar: true,

  showManageShare: false,
  showAddToFolder: false,
  showAddFolderToAPL: false,
  columnsMember: [],
  columnsUsers: [],
  openItem: {
    items: [],
    isAdding: false,
    isAddingFormData: false,
    currentTab: {
      items: [],
      urlPath: null,
    },
    currentOpenItem: null,
  },
  isHelpCenterOpen: false,
  optionSearchHelp: [],
  filterAssets: [],
  filterProducts: [],
  filterMembers: [],
  filterMemberProducts: [],
  filterUserMaintenance: [],
  filterTicketingSystem: [],
  filterNone: [],
  filterSsoUserPending: [
    {
      fieldName: 'poolStatus',
      values: ['Pending'],
      filterType: 'In',
    },
  ],
  filterSsoMemberPending: [
    {
      fieldName: 'poolStatus',
      values: ['Pending'],
      filterType: 'In',
    },
  ],
  //* media asset
  mediaAssetFiles: {
    data: [],
    pageNumber: 0,
    totalRecord: null,
  },
  isFinishLoadMoreMedia: false,
  //*
  currentFolderId: null,
  brandingData: null,
  shouldReloadPage: false,
  isOnlyDetailGrid: false,

  isProductsToMember: false,
  isAssetsToMember: false,
  showAdvanceFilter: false,
  markdownRef: {
    table: [],
    totalTable: 0,
  },
  markdownHHelpRef: {
    table: [],
    totalTable: 0,
  },
  articleShowcaseManagement: null,
  memberMaintenanceId: null,
  isBackFromQuery: false,
  isBackFromReporting: false,
  folderIdPanel: false,
  isShowGridQuery: true,

  memberInfomationSheet: false,
  memberInfomationSheetError: false,
  todoList: [],
  awaitingList: [],
  awaitingListError: null,
  meetingList: [],
  totalMeetingList: 0,
  meetingListError: null,
  todoListError: null,
  totalTodoList: null,
  //* chat
  isChatViewOpen: false,

  //* Email
  isEmailViewOpen: false,
  newEmailInfo: null,

  signalRConnection: null,
  signalRConnectionErr: null,
  newMsgInfo: null,

  //* Video Call
  videoCall: {
    isVideoCallOpen: false,
    isMeeting: false,
    currentCall: null,
    error: null,
    loading: false,
    isReceivingCall: false,
    isCallAccepted: false,
    threadItem: null,
  },

  //* Email & Message Notifications
  totalMessageUnread: null,
  totalEmailUnread: null,
  notifyError: null,
  makeThreadAsReadError: null,

  //* md table and entity action
  mdTableAction: defaultMdTableAction,
  isGettingPlaceholderList: false,

  //* Product Brick Code
  brickCodeModules: null,

  //* Rivir alert
  productTargeMarket: null,
  hierarchyAlertId: null,
  checkGetListAfterSendMessage: false,
  checkGetListAfterSendEmail: false,

  newSyndicationHistory: false,
  filterPendingPublication: false,

  isDisplayProductNew: false,
  isDisplayMemberNew: false,
  isDisplayAssetNew: false,
  isDisplayRequestNew: false,

  isDisplayProductUnmatch: false,
  isDisplayMemberUnmatch: false,
  isDisplayAssetUnmatch: false,
  isDisplayRequestUnmatch: false,

  selectedPermissionRequest: null,

  creditCardEula: {
    modalVisible: false,
    isAccepted: null,
    showChargeWarning: true,
    when: 'login',
  },

  exportLoading: false,
  newTicketingSystem: false,
};

/* eslint-disable default-case, no-param-reassign */
const globalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_GLOBAL_LOADING:
        draft.loading = action.loading;
        break;
      case types.VALIDATE_EULA:
        draft.eula = true;
        break;
      case types.VALIDATE_ACCEPTED_EULA:
        draft.acceptedEula = action.payload;
        break;
      case types.VALIDATE_PERSONALIZATION:
        draft.personalization = true;
        break;
      case types.VALIDATE_CRITICAL_MESSAGE:
        draft.criticalMessage = true;
        break;
      case types.VALIDATE_LANDING_PAGE:
        draft.landingPage = true;
        break;
      case types.CHANGE_RIBBON_ACTIONS:
        var arrOptions = _.toArray(DEFAULT_OPTIONS);
        if (!_.isEmpty(action.option)) {
          draft.controlOptions = [action.option];
        } else draft.controlOptions = arrOptions;
        break;
      case types.SEARCH_CRITERIA:
        draft.searchCriteria = action.searchCriteria;
        break;

      case types.FAVORITE_QUERIES:
        draft.favorites.queries = action.queries;
        break;
      case types.FAVORITE_SHARED_QUERIES:
        draft.favorites.sharedQueries = action.sharedQueries;
        break;
      case types.FAVORITE_PRODUCTS:
        draft.favorites.products = action.products;
        break;
      case types.FAVORITE_ASSETS:
        draft.favorites.assets = action.assets;
        break;
      case types.FAVORITE_MEMBERS:
        draft.favorites.members = action.members;
        break;
      case types.SELECTED_KEY:
        draft.selectedKey = action.key;
        break;
      case types.TOGGLE_DETAIL:
        // draft.detail = !state.detail;
        // if (!draft.detail) {
        //   draft.inContainerDetail = false;
        // }
        draft.showDetail = !state.showDetail;
        break;
      case types.UPDATE_TOGGLE_DETAIL:
        // draft.detail = !state.detail;
        // if (!draft.detail) {
        //   draft.inContainerDetail = false;
        // }
        draft.showDetail = action.showDetail;
        break;
      case types.HIDE_DETAIL:
        draft.showDetail = false;
        break;

      case types.INCONTAINER_DETAIL:
        draft.inContainerDetail = !state.inContainerDetail;
        break;
      case types.TOGGLE_QUICKVIEW:
        draft.quickView = !state.quickView;
        break;
      case types.UPDATE_LOGGING:
        draft.logging.push(action.logging);
        setData(draft.logging, 'logging');
        break;
      case types.LOGOUT:
        draft = initialState;
        break;
      case types.SEARCH_ALL_ITEMS:
        draft.searchAllItems = action.searchAllItems;
        break;
      case types.HIDE_SEARCH_BAR:
        draft.showSearchBar = false;
        break;
      case types.UNHIDE_SEARCH_BAR:
        draft.showSearchBar = true;
        break;
      //* toggle ribbon bar
      case types.TOGGLE_RIBBON_BAR:
        break;
      case types.TOGGLE_RIBBON_BAR_SUCCESS:
        draft.showRibbonBar = action.status;
        break;
      case types.TOGGLE_RIBBON_BAR_FAIL:
        break;
      //* update download/upload file progress bar
      case types.UPDATE_PROGRESS_BAR_SUCCESS:
        draft.uploadDownloadProgress = action.payload;
        break;
      case types.TOGGLE_MINIMIZED_PROGRESS_BAR:
        draft.minimizedProgressBar = action.status;
        break;
      case types.TOGGLE_HIDDEN_PROGRESS_BAR:
        draft.hiddenProgressBar = action.status;
        break;
      //* manage share
      case types.TOGGLE_MANAGE_SHARE:
        draft.showManageShare = !state.showManageShare;
        break;
      case types.TOGGLE_ADD_TO_FOLDER:
        draft.showAddToFolder = !state.showAddToFolder;
        break;
      case types.UPDATE_ADD_TO_FOLDER:
        draft.showAddToFolder = action.showAddToFolder;
        break;
      case types.TOGGLE_ADD_FOLDER_TO_APL:
        draft.showAddFolderToAPL = !state.showAddFolderToAPL;
        break;
      case types.UPDATE_ADD_FOLDER_TO_APL:
        draft.showAddFolderToAPL = action.showAddFolderToAPL;
        break;
      case types.MEMBER_FOR_SHARING_COLUMN_INFO:
        break;
      case types.MEMBER_FOR_SHARING_COLUMN_INFO_SUCCESS:
        draft.columnsMember = action.columnsMember;
        break;
      case types.MEMBER_FOR_SHARING_COLUMN_INFO_ERROR:
        break;
      case types.USER_FOR_SHARING_COLUMN_INFO:
        break;
      case types.USER_FOR_SHARING_COLUMN_INFO_SUCCESS:
        draft.columnsUsers = action.columnsUsers;
        break;
      case types.USER_FOR_SHARING_COLUMN_INFO_ERROR:
        break;
      //* init open item
      case types.INIT_OPEN_ITEM:
        draft.openItem = action.payload;
        break;
      case types.CLEAR_OPEN_ITEMS:
        draft.openItem = openItemsDefault;
        break;
      //* update open item
      case types.UPDATE_OPEN_ITEM_SUCCESS:
        draft.openItem.items = action.payload;
        break;
      //* toggle isAdding open item
      case types.TOGGLE_IS_ADDING_OPEM_ITEM:
        draft.openItem.isAdding = action.status;
        break;
      //* toggle isAdding open item
      case types.TOGGLE_IS_ADDING_OPEM_ITEM_FORM_DATA:
        draft.openItem.isAddingFormData = action.status;
        break;
      //* update current tab
      case types.UPDATE_CURRENT_TAB_SUCCESS:
        draft.openItem.currentTab = action.payload;
        break;
      //* update current open item
      case types.UPDATE_CURRENT_OPEN_ITEM:
        draft.openItem.currentOpenItem = action.payload;
        break;
      //* delete activated open item
      case types.DELETE_ACTIVATED_OPEN_ITEM_SUCCESS:
        draft.openItem.items = action.payload;
        break;
      //* delete open item
      case types.DELETE_OPEN_ITEM:
        const dataDeleteOpenItem = draft.openItem.items.filter(
          (openItem) => action.payload !== openItem.uid
        );
        draft.openItem.items = dataDeleteOpenItem;
        break;
      case types.TOGGLE_HELP_CENTER:
        draft.isHelpCenterOpen = action.status;
        break;
      case types.SEARCH_HELP_POST_SUCCESS:
        draft.optionSearchHelp = action.optionSearchHelp;
        break;
      case types.SEARCH_HELP_POST_ERROR:
        draft.optionSearchHelp = action.optionSearchHelp;
        break;
      case types.UPDATE_FILTER_ASSETS:
        draft.filterAssets = action.filterAssets;
        break;
      case types.UPDATE_FILTER_USER_MAINTENANCE:
        draft.filterUserMaintenance = action.filterUserMaintenance;
        break;
      case types.UPDATE_FILTER_TICKETING_SYSTEM:
        draft.filterTicketingSystem = action.filterTicketingSystem;
        break;
      case types.UPDATE_FILTER_PRODUCTS:
        draft.filterProducts = action.filterProducts;
        break;
      case types.UPDATE_FILTER_MEMBER_PRODUCT:
        draft.filterMemberProducts = action.filterMemberProducts;
        break;
      case types.UPDATE_FILTER_MEMBERS:
        draft.filterMembers = action.filterMembers;
        break;
      case types.SET_CURRENT_FOLDER:
        draft.currentFolderId = action.id;
        break;
      case types.REMOVE_CURRENT_FOLDER:
        draft.currentFolderId = null;
        break;
      case types.ADD_BRANDING_INFO:
        draft.brandingData = action.data;
        break;
      case types.GET_MEDIA_ASSETS_SUCCESS:
        draft.mediaAssetFiles = action.payload;
        break;
      case types.TOGGLE_FINISH_LOAD_MORE_MEDIA:
        draft.isFinishLoadMoreMedia = action.status;
        break;

      case types.UPDATE_SEARCH_TEXT_MEMBER:
        draft.searchText['memberText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_FAV_MEMBER:
        draft.searchText['favMemberText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_FAV_ASSET:
        draft.searchText['favAssetText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_FAV_PRODUCT:
        draft.searchText['favProductText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_TEXT_DIGITAL_MEDIA:
        draft.searchText['digitalMediaText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_TEXT_DIGITAL_MEDIA_NEW:
        draft.searchText['digitalMediaNewText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_TEXT_ASSET:
        draft.searchText['assetText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_TEXT_DOCUMENTS:
        draft.searchText['documentText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_TEXT_MULTI_MEDIA:
        draft.searchText['multiMediaText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_REPORTINGS:
        draft.searchText['reportingsText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_REPORTINGS_OWNED:
        draft.searchText['reportingsOwnedText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_REPORTINGS_SHARED:
        draft.searchText['reportingsSharedText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_FAVORITE_QUERIES:
        draft.searchText['favoriteQueriesText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_MAINTENANCE:
        draft.searchText['memberMaintenanceText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_MEMBER_MAINTENANCE_USER:
        draft.searchText['memberMaintenanceUserText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_USER_MAINTENANCE:
        draft.searchText['userMaintenanceText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_ASSET_FOR_MEMBER:
        draft.searchText['assetForMemberText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_FOLDER_TEXT:
        draft.searchText['folderText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_FOLDER_OWNED_TEXT:
        draft.searchText['folderOwnedText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_FOLDER_SHARED_TEXT:
        draft.searchText['folderSharedText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_FAV_FOLDER:
        draft.searchText['favFolderText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_WF_MAINTENANCE_DEFINITION:
        draft.searchText['wfDefinitionText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_PRODUCT:
        draft.searchText['productText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_PRODUCT_FOR_MEMBER:
        draft.searchText['productForMemberText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_TEXT_TRANSITION_PAGE:
        draft.searchText['transitionPageText'] = action.payload;
        break;

      case types.UPDATE_SEARCH_TEXT_SSO_APPROVAL_MEMBER:
        draft.searchText['ssoApprovalMemberText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_MAINTENANCE_FORM:
        draft.searchText['maintenanceFormText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_EULA_CARD:
        draft.searchText['eulaCardText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_TICKETING_SYSTEM:
        draft.searchText['ticketingSystemText'] = action.payload;
        break;
      case types.RESET_SEARCH_TEXT:
        draft.searchText = action.searchText;
        break;

      case types.UPDATE_RELOAD_PAGE:
        draft.shouldReloadPage = action.value;
        break;
      case types.UPDATE_IS_ONLY_DETAIL_GRID:
        draft.isOnlyDetailGrid = action.value;
        break;

      case types.TOGGLE_PROD_TO_MEM:
        draft.isProductsToMember = true;
        break;

      case types.TOGGLE_ASSETS_TO_MEM:
        draft.isAssetsToMember = true;
        break;

      case types.RESET_PROD_AND_ASSETS_TO_MEMBER:
        draft.isProductsToMember = false;
        draft.isAssetsToMember = false;
        break;
      case types.UPDATE_SHOW_ADVANCE_FILTER:
        draft.showAdvanceFilter = action.showAdvanceFilter;
        break;
      case types.TOGGLE_ADVANCE_FILTER:
        draft.showAdvanceFilter = !state.showAdvanceFilter;
        break;
      case types.UPDATE_MARKDOWN_REFERENCE:
        draft.markdownRef = action.payload;
        break;
      case types.UPDATE_HELP_CENTER_MARKDOWN_REFERENCE:
        draft.markdownHHelpRef = action.payload;
        break;
      case types.GET_ALL_ARTICLES_SUCCESS:
        draft.articleShowcaseManagement = action.articleShowcaseManagement;
        break;
      case types.GET_ALL_ARTICLES_ERROR:
        draft.articleShowcaseManagement = null;
        break;
      case types.UPDATE_MEMBER_MAINTENANCE_ID:
        draft.memberMaintenanceId = action.id;
        break;
      case types.UPDATE_IS_BACK_FROM_QUERY:
        draft.isBackFromQuery = action.isBackFromQuery;
        break;
      case types.UPDATE_IS_BACK_FROM_REPORTING:
        draft.isBackFromReporting = action.isBackFromReporting;
        break;
      case types.UPDATE_FOLDER_ID_PANEL:
        draft.folderIdPanel = action.folderIdPanel;
        break;
      case types.IS_DISPLAY_SHOW_PREVIEW:
        draft.isDisableShowPreview = action.isDisableShowPreview;
        break;
      case types.IS_SHOW_GRID_QUERY:
        draft.isShowGridQuery = action.isShowGridQuery;
        break;

      case types.CLEAR_SEARCH_TEXT_DAM:
        draft.searchText['digitalMediaText'] = '';
        draft.searchText['digitalMediaNewText'] = '';
        draft.searchText['assetText'] = '';
        draft.searchText['documentText'] = '';
        draft.searchText['multiMediaText'] = '';
        break;

      case types.GET_MEMBER_INFO_SHEET:
        draft.loading = true;
        draft.memberInfomationSheetError = false;
        break;
      case types.GET_MEMBER_INFO_SHEET_SUCCESS:
        draft.loading = false;
        draft.memberInfomationSheet = action.memberInfomationSheet;
        break;
      case types.GET_MEMBER_INFO_SHEET_ERROR:
        draft.loading = false;
        draft.memberInfomationSheetError = action.memberInfomationSheetError;
        draft.memberInfomationSheet = null;
        break;
      case types.GET_TODO_LIST:
        draft.loading = true;
        break;
      case types.GET_TODO_LIST_SUCCESS:
        draft.loading = false;
        draft.todoList = action.todoList;
        draft.totalTodoList = action.total;
        break;
      case types.GET_TODO_LIST_ERROR:
        draft.loading = false;
        draft.todoListError = action.error;
        break;
      case types.GET_MEETING_NOTIFICATION:
        draft.loading = true;
        break;
      case types.GET_MEETING_NOTIFICATION_SUCCESS:
        draft.loading = false;
        draft.meetingList = action.meetingList;
        draft.totalMeetingList = action.total;
        break;
      case types.GET_MEETING_NOTIFICATION_ERROR:
        draft.loading = false;
        draft.meetingListError = action.error;
        break;

      case types.GET_AWAITING_LIST:
        draft.loading = true;
        break;
      case types.GET_AWAITING_LIST_SUCCESS:
        draft.loading = false;
        draft.awaitingList = action.awaitingList;
        break;
      case types.GET_AWAITING_LIST_ERROR:
        draft.loading = false;
        draft.awaitingListError = action.error;
        break;
      case types.SET_SIGNALR_CONNECTION:
        draft.signalRConnection = null;
        draft.signalRConnectionErr = null;
        break;
      case types.SET_SIGNALR_CONNECTION_SUCCESS:
        draft.signalRConnection = action.connection;
        draft.signalRConnectionErr = null;
        break;
      case types.SET_SIGNALR_CONNECTION_FAIL:
        draft.signalRConnection = null;
        draft.signalRConnectionErr = action.err;
        break;
      case types.TOGGLE_CHAT_VIEW_SUCCESS:
        draft.isChatViewOpen = action.status;
        break;
      case types.NEW_MSG_RECEIVE_INFO:
        draft.newMsgInfo = action.newMsgInfo;
        break;
      case types.NEW_EMAIL_RECEIVE_INFO:
        draft.newEmailInfo = action.newEmailInfo;
        break;
      case types.RESET_NEW_EMAIL_INFO:
        draft.newEmailInfo = null;
        break;
      case types.TOGGLE_EMAIL_VIEW:
        draft.isEmailViewOpen = action.status;
        break;

      //* video call
      case types.TOGGLE_VIDEO_CALL:
        draft.videoCall.loading = true;
        break;

      case types.TOGGLE_VIDEO_CALL_SUCCESS:
        draft.videoCall.loading = false;
        draft.videoCall.error = null;
        draft.videoCall.isVideoCallOpen = action.payload.status;
        draft.videoCall.currentCall = action.payload.currentCall;
        if (action.payload.status === false) {
          if (draft.videoCall.isMeeting) {
            draft.videoCall.isMeeting = false;
          }
        } else {
          draft.videoCall.isMeeting = action.payload.isMeeting || false;
        }
        draft.videoCall.threadItem = action.payload.threadItem;
        break;

      case types.TOGGLE_VIDEO_CALL_FAIL:
        draft.videoCall.loading = false;
        draft.videoCall.currentCall = null;
        draft.videoCall.error = action.payload;
        break;

      case types.TOGGLE_IS_RECEIVING_CALL:
        draft.videoCall.isReceivingCall = action.status;
        break;

      case types.TOGGLE_IS_CALL_ACCEPTED:
        draft.videoCall.isCallAccepted = action.status;
        break;

      //* Email & Message Notify
      case types.GET_EMAIL_AND_MESSAGE_NOTIFY:
        draft.loading = true;
        break;
      case types.GET_EMAIL_NOTIFY_SUCCESS:
        draft.loading = false;
        draft.totalEmailUnread = action.totalEmailUnread;
        break;
      case types.GET_MESSAGE_NOTIFY_SUCCESS:
        draft.loading = false;
        draft.totalMessageUnread = action.totalChatUnread;
        break;
      case types.GET_EMAIL_AND_MESSAGE_NOTIFY_ERROR:
        draft.loading = false;
        draft.notifyError = action.error;
        break;
      case types.MAKE_A_THREAD_AS_READ_ERROR:
        draft.makeThreadAsReadError = action.error;
        break;
      //* markdown table and entity
      case types.UPDATE_MD_TABLE_ACTION:
        draft.mdTableAction = {
          ...action.payload,
          count: draft.mdTableAction.count + 1,
        };
        break;

      case types.TOGGLE_IS_GETTING_PLACEHOLDER_LIST:
        draft.isGettingPlaceholderList = action.status;
        break;

      case types.LOAD_PRODUCT_BRICK_CODE:
        draft.brickCodeModules = action?.params;
        break;

      case types.LOAD_PRODUCT_TARGET_MARKET:
        draft.productTargeMarket = action?.params;
        break;

      case types.CHANGE_HIERARCHY_ALERT_ID:
        draft.hierarchyAlertId = action?.params;
        break;
      case types.REMOVE_HIERARCHY_ALERT_ID:
        draft.hierarchyAlertId = null;
        break;

      case types.UPDATE_LIST_AFTER_SEND_MESSAGE:
        draft.checkGetListAfterSendMessage =
          action.checkGetListAfterSendMessage;
        break;
      case types.UPDATE_LIST_AFTER_SEND_EMAIL:
        draft.checkGetListAfterSendEmail = action.checkGetListAfterSendEmail;
        break;

      case types.TOGGLE_NEW_SYNDICATION_HISTORY:
        draft.newSyndicationHistory = action.payload;
        break;

      case types.TOGGLE_FILTER_PENDING_PUBLICATION:
        draft.filterPendingPublication = action.payload;
        break;

      case types.TOGGLE_PRODUCT_NEW:
        draft.isDisplayProductNew = action.payload;
        break;

      case types.TOGGLE_MEMBER_NEW:
        draft.isDisplayMemberNew = action.payload;
        break;

      case types.TOGGLE_ASSET_NEW:
        draft.isDisplayAssetNew = action.payload;
        break;

      case types.TOGGLE_REQUEST_NEW:
        draft.isDisplayRequestNew = action.payload;
        break;

      case types.SAVE_CURRENT_SELECTED_PERMISSION_REQUEST:
        draft.selectedPermissionRequest = action.payload;
        break;

      case types.UPDATE_ENVIRONEMENT:
        draft.environment = action.environmentName;
        break;

      case types.TOGGLE_PRODUCT_UNMATCH:
        draft.isDisplayProductUnmatch = action.payload;
        break;

      case types.TOGGLE_MEMBER_UNMATCH:
        draft.isDisplayMemberUnmatch = action.payload;
        break;

      case types.TOGGLE_ASSET_UNMATCH:
        draft.isDisplayAssetUnmatch = action.payload;
        break;

      case types.TOGGLE_REQUEST_UNMATCH:
        draft.isDisplayRequestUnmatch = action.payload;
        break;
      case types.FILTER_SSO_USER_PENDING:
        draft.filterSsoUserPending = action.payload;
        break;
      case types.FILTER_SSO_MEMBER_PENDING:
        draft.filterSsoMemberPending = action.payload;
        break;
      case types.TOGGLE_CREDIT_CARD_EULA_MODAL:
        draft.creditCardEula.modalVisible = action.payload;
        break;
      case types.UPDATE_CREDIT_CARD_EULA_ACCEPTED:
        draft.creditCardEula.isAccepted = action.payload;
        break;
      case types.UPDATE_SHOW_CHARGE_WARNING:
        draft.creditCardEula.showChargeWarning = action.payload;
        break;
      case types.UPDATE_SHOW_CREDIT_CARD_MODAL_WHEN:
        draft.creditCardEula.when = action.payload;
        break;
      case types.SHOW_EXPORT_LOADING_RIBBON:
        draft.exportLoading = true;
        break;
      case types.CANCEL_EXPORT_LOADING_RIBBON:
        draft.exportLoading = false;
        break;
      case types.UPDATE_SEARCH_TEXT_DATA_SYNC_TIME_TRACKING:
        draft.searchText['dataSyncTimeText'] = action.payload;
        break;
      case types.UPDATE_SEARCH_TEXT_QC_TIME_TRACKING:
        draft.searchText['qcTimeText'] = action.payload;
        break;
      case types.TOGGLE_NEW_TICKETING_SYSTEM:
        draft.newTicketingSystem = action.payload;
      case types.UPDATE_SEARCH_TEXT_QA_SPEC_WORKFLOW:
        draft.searchText['qaSpecWorkflowText'] = action.payload;
        break;
    }
  });

export default globalReducer;
