import { useState } from 'react';

const useOtpVerify = () => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const onOpen = () => {
    setVisible(true);
  };

  const toggleVisible = () => {
    setVisible((prev) => !prev);
  };

  return { onClose, onOpen, toggleVisible, visible };
};

export { useOtpVerify };
