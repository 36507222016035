import React from 'react';
import { Spin, Divider } from 'antd';
import { useSelector } from 'react-redux';
import assetsRibbonSelector from 'pages/home/ribbon/asset-full/controllers/selectors';
import { AssetPanelAssociation, AssetPanelThumbnailInfo } from '../index';
import LinkAssets from './link-assets/LinkAssets';
import AssetOverview from 'pages/asset-full-view/components/metadata-panel/AssetOverview';
import './AssetPanel.less';
import AssetDownload from 'pages/asset-full-view/components/metadata-panel/AssetDownload';

const AssetPanel = (props) => {
  const { assetData, loadingDetail, style } = props;
  const modeLinkAssets = useSelector(
    assetsRibbonSelector.selectModeLinkAssets()
  );

  const typeData = {
    assetType: assetData?.assetType,
    assetSubType: assetData?.assetSubType,
    packageLevel: assetData?.metadata?.packageLevel,
    packagingDisposition: assetData?.metadata?.packagingDisposition,
    angle: assetData?.metadata?.angle,
    facing: assetData?.metadata?.facing,
    language: assetData?.metadata?.language,
    panelType: assetData?.metadata?.panelType,
    logoType: assetData?.metadata?.logoType,
    certificateType: assetData?.metadata?.certificateType,
    documentType: assetData?.metadata?.documentType,
  };

  return (
    <div className='asset-panel' style={style && style}>
      {loadingDetail ? (
        <div className='asset-panel__area'>
          <Spin />
        </div>
      ) : (
        assetData && (
          <div className='asset-panel__info'>
            <AssetPanelThumbnailInfo assetData={assetData} />
            <Divider className='asset-panel__info-divider' />
            <div className='asset-panel__info'>
              <AssetOverview
                assetData={assetData}
                classifications={assetData?.classifications}
                gs1ImageData={assetData?.productImageLinks}
                assetId={assetData?.id}
                typeData={typeData}
                isHiddenEditSection={true}
              />
            </div>
            {modeLinkAssets ? null : (
              <AssetPanelAssociation assetData={assetData} />
            )}
            {modeLinkAssets ? <LinkAssets /> : null}
            {!assetData?.isIFrame && (
              <div style={{ marginTop: 15 }}>
                <AssetDownload
                  assetId={assetData?.id}
                  metadata={assetData?.metadata}
                  drm={assetData?.drm}
                  downloadFormats={assetData?.downloadFormats}
                />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};
export default AssetPanel;
