import React, { useState } from 'react';

import EditControl from '../../controls/member-full/EditControl';
import SaveControl from '../../controls/member-full/SaveControl';
import CancelEdit from '../../controls/member-full/CancelEdit';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

const EditMember = () => {
  const [isEdit, setIsEdit] = useState(false);

  if (!isEdit) {
    return (
      <ContainerButton>
        <EditControl confirmEdit={() => setIsEdit(true)} />
      </ContainerButton>
    );
  }

  return (
    <ContainerButton>
      <ItemButton>
        <SaveControl confirmEdit={() => setIsEdit(false)} />
      </ItemButton>
      <ItemButton>
        <CancelEdit confirmEdit={() => setIsEdit(false)} />
      </ItemButton>
    </ContainerButton>
  );
};

export default EditMember;
