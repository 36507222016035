import * as types from './constants';

/**
 * Post login process: validate user eula agreements or new revised eula acceptance
 */
export const validateEula = () => {
  return {
    type: types.VALIDATE_EULA,
  };
};

/**
 * Post login process: validate user eula agreements or new revised eula acceptance
 */
export const validateAcceptedEula = (payload) => {
  return {
    type: types.VALIDATE_ACCEPTED_EULA,
    payload,
  };
};

/**
 * Post login process: validate personalization for current logged in user
 */
export const validatePersonalization = () => {
  return {
    type: types.VALIDATE_PERSONALIZATION,
  };
};

/**
 * Post login process: validate critical message for current logged in user
 */
export const validateCriticalMessage = () => {
  return {
    type: types.VALIDATE_CRITICAL_MESSAGE,
  };
};

/**
 * Post login process: validate landing page setup for current logged in user
 */
export const validateLandingPage = () => {
  return {
    type: types.VALIDATE_LANDING_PAGE,
  };
};

/**
 * Update ribbon bar action items
 * @param {object} option
 */
export function changeRibbonActions(option) {
  return {
    type: types.CHANGE_RIBBON_ACTIONS,
    option: option,
  };
}

/**
 * Update search criteria
 * @param {string} searchCriteria
 */
export function updateSearchCriteria(searchCriteria) {
  return {
    type: types.SEARCH_CRITERIA,
    searchCriteria: searchCriteria,
  };
}

/**
 * Update queries number
 * @param {number} queries
 */
export function favoriteQueries(queries) {
  return {
    type: types.FAVORITE_QUERIES,
    queries: queries,
  };
}

/**
 * Update shared queries number
 * @param {number} sharedQueries
 */
export function favoriteSharedQueries(sharedQueries) {
  return {
    type: types.FAVORITE_SHARED_QUERIES,
    sharedQueries: sharedQueries,
  };
}

/**
 * Update product favorite number
 * @param {number} products
 */
export function favoriteProducts(products) {
  return {
    type: types.FAVORITE_PRODUCTS,
    products: products,
  };
}

/**
 * Update assets favorite number
 * @param {number} assets
 */
export function favoriteAssets(assets) {
  return {
    type: types.FAVORITE_ASSETS,
    assets: assets,
  };
}

/**
 * Update members favorite number
 * @param {number} members
 */
export function favoriteMembers(members) {
  return {
    type: types.FAVORITE_MEMBERS,
    members: members,
  };
}

/**
 * Update members favorite number
 * @param {number} members
 */
export function selectedKey(key) {
  return {
    type: types.SELECTED_KEY,
    key: key,
  };
}

/**
 * toggle detail visibility
 */
export function toggleDetail() {
  return {
    type: types.TOGGLE_DETAIL,
  };
}

export function updateToggleDetail(showDetail) {
  return {
    type: types.UPDATE_TOGGLE_DETAIL,
    showDetail,
  };
}

export function hideDetail() {
  return {
    type: types.HIDE_DETAIL,
  };
}

/**
 * display detail content in application pane
 */
export function inContainerDetail() {
  return {
    type: types.INCONTAINER_DETAIL,
  };
}

/**
 * toggle quickview visibility
 */
export function toggleQuickView() {
  return {
    type: types.TOGGLE_QUICKVIEW,
  };
}
/**
 * Update logging
 * @param {string} logging
 */
export function updateLogging(logging) {
  return {
    type: types.UPDATE_LOGGING,
    logging: logging,
  };
}
/**
 * change footer height
 * @param {number} heightFooter
 */
export function searchAllItems(searchAllItems) {
  return {
    type: types.SEARCH_ALL_ITEMS,
    searchAllItems: searchAllItems,
  };
}

export function hideSearchBar() {
  return { type: types.HIDE_SEARCH_BAR };
}

export function unHideSearchBar() {
  return { type: types.UNHIDE_SEARCH_BAR };
}

//* UPDATE PROGRESS BAR
export function updateProgressBar(payload) {
  return { type: types.UPDATE_PROGRESS_BAR, payload };
}
export function updateProgressBarSuccess(payload) {
  return { type: types.UPDATE_PROGRESS_BAR_SUCCESS, payload };
}

//* REMOVE PROGRESS BAR ITEM
//? @param {payload} - item uuid to remove one and null to remove all
export function removeProgressBarItem(payload) {
  return { type: types.REMOVE_PROGRESS_BAR_ITEM, payload };
}

//* TOGGLE + MINIMIZE PROGRESS BAR
export function toggleMinimizedProgressBar(status) {
  return {
    type: types.TOGGLE_MINIMIZED_PROGRESS_BAR,
    status,
  };
}
export function toggleHiddenProgressBar(status) {
  return {
    type: types.TOGGLE_HIDDEN_PROGRESS_BAR,
    status,
  };
}

export const toggleManageShare = () => {
  return { type: types.TOGGLE_MANAGE_SHARE };
};
/**
 * toggle detail visibility
 */
export function toggleAddToFolder() {
  return {
    type: types.TOGGLE_ADD_TO_FOLDER,
  };
}
export function updateAddToFolder(showAddToFolder) {
  return {
    type: types.UPDATE_ADD_TO_FOLDER,
    showAddToFolder,
  };
}

export function toggleAddFolderToAPL() {
  return {
    type: types.TOGGLE_ADD_FOLDER_TO_APL,
  };
}
export function updateAddFolderToAPL(showAddToAPL) {
  return {
    type: types.UPDATE_ADD_FOLDER_TO_APL,
    showAddToAPL,
  };
}

export function memberForSharingColumnInfo(gridName) {
  return {
    type: types.MEMBER_FOR_SHARING_COLUMN_INFO,
    gridName,
  };
}

export function memberForSharingColumnInfoSuccess(columnsMember) {
  return {
    type: types.MEMBER_FOR_SHARING_COLUMN_INFO_SUCCESS,
    columnsMember,
  };
}

export function memberForSharingColumnInfoError(error) {
  return {
    type: types.MEMBER_FOR_SHARING_COLUMN_INFO_ERROR,
    error,
  };
}

export function userForSharingColumnInfo(gridName) {
  return {
    type: types.USER_FOR_SHARING_COLUMN_INFO,
    gridName,
  };
}

export function userForSharingColumnInfoSuccess(columnsUsers) {
  return {
    type: types.USER_FOR_SHARING_COLUMN_INFO_SUCCESS,
    columnsUsers,
  };
}

export function userForSharingColumnInfoError(error) {
  return {
    type: types.USER_FOR_SHARING_COLUMN_INFO_ERROR,
    error,
  };
}
//* INIT OPEN ITEM
export function initOpenItem(payload) {
  return { type: types.INIT_OPEN_ITEM, payload };
}
export function clearOpenItemsRedux() {
  return { type: types.CLEAR_OPEN_ITEMS };
}
//* UPDATE OPEN ITEM
export function updateOpenItem(payload) {
  return { type: types.UPDATE_OPEN_ITEM, payload };
}
export function updateOpenItemSuccess(payload) {
  return { type: types.UPDATE_OPEN_ITEM_SUCCESS, payload };
}
//* UPDATE OPEN ITEM FORM
export function updateOpenItemForm(payload) {
  return { type: types.UPDATE_OPEN_ITEM_FORM, payload };
}
export function updateOpenItemFormSuccess(payload) {
  return { type: types.UPDATE_OPEN_ITEM_FORM_SUCCESS, payload };
}
//* IS_ADDING FULL VIEW OPEN ITEM
export function toggleIsAddingOpenItem(status) {
  return { type: types.TOGGLE_IS_ADDING_OPEM_ITEM, status };
}
export function toggleIsAddingOpenItemSuccess(status) {
  return { type: types.TOGGLE_IS_ADDING_OPEM_ITEM_SUCCESS, status };
}
//* IS_ADDING FULL VIEW OPEN ITEM FORM DATA
export function toggleIsAddingOpenItemFormData(status) {
  return { type: types.TOGGLE_IS_ADDING_OPEM_ITEM_FORM_DATA, status };
}
//* IS_ADDING FULL VIEW OPEN ITEM FORM DATA
export function updateCurrentTab(payload) {
  return { type: types.UPDATE_CURRENT_TAB, payload };
}
export function updateCurrentTabSuccess(payload) {
  return { type: types.UPDATE_CURRENT_TAB_SUCCESS, payload };
}
//* UPDATE CURRENT OPEN ITEM
export function updateCurrentOpenItem(payload) {
  return { type: types.UPDATE_CURRENT_OPEN_ITEM, payload };
}
//* DELETE ACTIVATED OPEN ITEM
export function deleteActivatedOpenItem(payload) {
  return { type: types.DELETE_ACTIVATED_OPEN_ITEM, payload };
}
export function deleteActivatedOpenItemSuccess(payload) {
  return { type: types.DELETE_ACTIVATED_OPEN_ITEM_SUCCESS, payload };
}
//* DELETE OPEN ITEM
export function deleteOpenItem(payload) {
  return { type: types.DELETE_OPEN_ITEM, payload };
}
//* TOGGLE HELP CENTER
export function toggleHelpCenter(status) {
  return { type: types.TOGGLE_HELP_CENTER, status };
}

export function searchHelpPost(payload) {
  return {
    type: types.SEARCH_HELP_POST,
    payload,
  };
}

export function searchHelpPostSuccess(optionSearchHelp) {
  return { type: types.SEARCH_HELP_POST_SUCCESS, optionSearchHelp };
}
export function searchHelpPostError(optionSearchHelp) {
  return { type: types.SEARCH_HELP_POST_ERROR, optionSearchHelp };
}
export function updateFilterAssets(filterAssets) {
  return { type: types.UPDATE_FILTER_ASSETS, filterAssets };
}
//* get media assets
export function getMediaAssetFile(payload) {
  return {
    type: types.GET_MEDIA_ASSETS,
    payload,
  };
}

export function getMediaAssetFileSuccess(payload) {
  return {
    type: types.GET_MEDIA_ASSETS_SUCCESS,
    payload,
  };
}

export const setCurrentFolder = (id) => ({
  type: types.SET_CURRENT_FOLDER,
  id,
});

export const removeCurrentFolder = (id) => ({
  type: types.REMOVE_CURRENT_FOLDER,
});

export const updateSearchTextProduct = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_PRODUCT,
  payload: text,
});

export const updateSearchTextProductForMember = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_PRODUCT_FOR_MEMBER,
  payload: text,
});

export const updateSearchTextMember = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_MEMBER,
  payload: text,
});

export const updateSearchFavMember = (text) => ({
  type: types.UPDATE_SEARCH_FAV_MEMBER,
  payload: text,
});

export const updateSearchFavAsset = (text) => ({
  type: types.UPDATE_SEARCH_FAV_ASSET,
  payload: text,
});

export const updateSearchFavProduct = (text) => ({
  type: types.UPDATE_SEARCH_FAV_PRODUCT,
  payload: text,
});

export const updateSearchTextDigitalMedia = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_DIGITAL_MEDIA,
  payload: text,
});

export const updateSearchTextDigitalMediaNew = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_DIGITAL_MEDIA_NEW,
  payload: text,
});

export const updateSearchTextAsset = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_ASSET,
  payload: text,
});

export const updateSearchTextDocuments = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_DOCUMENTS,
  payload: text,
});

export const updateSearchTextMultiMedia = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_MULTI_MEDIA,
  payload: text,
});
export const updateSearchTextReportings = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_REPORTINGS,
  payload: text,
});
export const updateSearchTextReportingsOwned = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_REPORTINGS_OWNED,
  payload: text,
});
export const updateSearchTextReportingsShared = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_REPORTINGS_SHARED,
  payload: text,
});
export const updateSearchTextFavoriteQueries = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_FAVORITE_QUERIES,
  payload: text,
});
export const updateSearchTextMaintenance = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_MAINTENANCE,
  payload: text,
});
export const updateSearchTextMemberMaintenanceUser = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_MEMBER_MAINTENANCE_USER,
  payload: text,
});
export const updateSearchTextUserMemberMaintenance = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_USER_MAINTENANCE,
  payload: text,
});
export const updateSearchTextSsoApprovalMember = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_SSO_APPROVAL_MEMBER,
  payload: text,
});
export const updateSearchTextMaintenanceForm = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_MAINTENANCE_FORM,
  payload: text,
});

export const updateSearchTextEulaCard = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_EULA_CARD,
  payload: text,
});

export const updateSearchTextTicketingSystem = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_TICKETING_SYSTEM,
  payload: text,
});

export const addBrandingInfo = (data) => ({
  type: types.ADD_BRANDING_INFO,
  data,
});

export const updateReloadPage = (value) => ({
  type: types.UPDATE_RELOAD_PAGE,
  value,
});
export function updateIsOnlyDetailGird(value) {
  return {
    type: types.UPDATE_IS_ONLY_DETAIL_GRID,
    value,
  };
}

// Back to url
export function toggleProdToMember() {
  return {
    type: types.TOGGLE_PROD_TO_MEM,
  };
}

export function toggleAssetsToMember() {
  return {
    type: types.TOGGLE_ASSETS_TO_MEM,
  };
}

export function resetProdAssetsToMember() {
  return { type: types.RESET_PROD_AND_ASSETS_TO_MEMBER };
}

export function resetSearchText(searchText) {
  return { type: types.RESET_SEARCH_TEXT, searchText };
}

export function updateShowAdvanceFilter(showAdvanceFilter) {
  return {
    type: types.UPDATE_SHOW_ADVANCE_FILTER,
    showAdvanceFilter,
  };
}
export function toggleAdvanceFilter() {
  return {
    type: types.TOGGLE_ADVANCE_FILTER,
  };
}

//* update/delete/add markdown reference
export function updateMarkdownReference(payload) {
  return {
    type: types.UPDATE_MARKDOWN_REFERENCE,
    payload,
  };
}

export function updateHelpCenterMarkdownReference(payload) {
  return {
    type: types.UPDATE_HELP_CENTER_MARKDOWN_REFERENCE,
    payload,
  };
}

export function getAllArticles(payload) {
  return {
    type: types.GET_ALL_ARTICLES,
  };
}

export function getAllArticlesSuccess(articleShowcaseManagement) {
  return {
    type: types.GET_ALL_ARTICLES_SUCCESS,
    articleShowcaseManagement,
  };
}

export function getAllArticlesError() {
  return {
    type: types.GET_ALL_ARTICLES_ERROR,
  };
}

export function updateMemberMaintenanceId(id) {
  return {
    type: types.UPDATE_MEMBER_MAINTENANCE_ID,
    id,
  };
}

export function updateIsBackFromQuery(isBackFromQuery) {
  return {
    type: types.UPDATE_IS_BACK_FROM_QUERY,
    isBackFromQuery,
  };
}
export function updateIsBackFromReporting(isBackFromReporting) {
  return {
    type: types.UPDATE_IS_BACK_FROM_REPORTING,
    isBackFromReporting,
  };
}
export function updateFolderIdPanel(folderIdPanel) {
  return {
    type: types.UPDATE_FOLDER_ID_PANEL,
    folderIdPanel,
  };
}

export function disableShowPreview(isDisableShowPreview) {
  return {
    type: types.IS_DISPLAY_SHOW_PREVIEW,
    isDisableShowPreview,
  };
}

export function setShowGridQuery(isShowGridQuery) {
  return {
    type: types.IS_SHOW_GRID_QUERY,
    isShowGridQuery,
  };
}

export function clearSearchTextDAM() {
  return {
    type: types.CLEAR_SEARCH_TEXT_DAM,
  };
}

export function updateAssetForMemberSearch(text) {
  return {
    type: types.UPDATE_SEARCH_ASSET_FOR_MEMBER,
    payload: text,
  };
}

export function updateSearchTextFolder(text) {
  return {
    type: types.UPDATE_SEARCH_FOLDER_TEXT,
    payload: text,
  };
}

export function updateSearchTextFolderOwned(text) {
  return {
    type: types.UPDATE_SEARCH_FOLDER_OWNED_TEXT,
    payload: text,
  };
}

export function updateSearchTextFolderShared(text) {
  return {
    type: types.UPDATE_SEARCH_FOLDER_SHARED_TEXT,
    payload: text,
  };
}

export function updateSearchTextFolderFav(text) {
  return {
    type: types.UPDATE_SEARCH_FAV_FOLDER,
    payload: text,
  };
}

export function updateSearchTextTransitionPage(text) {
  return {
    type: types.UPDATE_SEARCH_TEXT_TRANSITION_PAGE,
    payload: text,
  };
}

export function updateSearchTextWfMaintenanceDefinition(text) {
  return {
    type: types.UPDATE_SEARCH_WF_MAINTENANCE_DEFINITION,
    payload: text,
  };
}

export const getMemberInfoSheet = (memberId, privateKey) => {
  return {
    type: types.GET_MEMBER_INFO_SHEET,
    memberId,
    privateKey,
  };
};
export const getMemberInfoSheetSuccess = (memberInfomationSheet) => {
  return {
    type: types.GET_MEMBER_INFO_SHEET_SUCCESS,
    memberInfomationSheet,
  };
};
export const getMemberInfoSheetError = (memberInfomationSheetError) => {
  return {
    type: types.GET_MEMBER_INFO_SHEET_ERROR,
    memberInfomationSheetError,
  };
};

export const getTodoList = (params) => {
  return {
    type: types.GET_TODO_LIST,
    params,
  };
};
export const getTodoListSuccess = (todoList, total) => {
  return {
    type: types.GET_TODO_LIST_SUCCESS,
    todoList,
    total,
  };
};
export const getTodoListError = (error) => {
  return {
    type: types.GET_TODO_LIST_ERROR,
    error,
  };
};

export const getMeetingNotification = (params) => {
  return {
    type: types.GET_MEETING_NOTIFICATION,
    params,
  };
};
export const getMeetingNotificationSuccess = (meetingList, total) => {
  return {
    type: types.GET_MEETING_NOTIFICATION_SUCCESS,
    meetingList,
    total,
  };
};
export const getMeetingNotificationError = (error) => {
  return {
    type: types.GET_MEETING_NOTIFICATION_ERROR,
    error,
  };
};

export const getAwaitingList = (params) => {
  return {
    type: types.GET_AWAITING_LIST,
  };
};
export const getAwaitingListSuccess = (awaitingList) => {
  return {
    type: types.GET_AWAITING_LIST_SUCCESS,
    awaitingList,
  };
};

export const getAwaitingListError = (error) => {
  return {
    type: types.GET_AWAITING_LIST_ERROR,
    error,
  };
};

export const resolveTodoList = (params) => {
  return {
    type: types.RESOLVE_TODO_LIST,
    params,
  };
};
export const resolveTodoListSuccess = (todoList) => {
  return {
    type: types.RESOLVE_TODO_LIST_SUCCESS,
    todoList,
  };
};
export const resolveTodoListError = (error) => {
  return {
    type: types.RESOLVE_TODO_LIST_ERROR,
    error,
  };
};

//* toggle chat view
export const toggleChatView = (status) => {
  return {
    type: types.TOGGLE_CHAT_VIEW,
    status,
  };
};

export const toggleChatViewSuccess = (status) => {
  return {
    type: types.TOGGLE_CHAT_VIEW_SUCCESS,
    status,
  };
};

//* Toggle Email View
export const toggleEmailView = (status) => {
  return {
    type: types.TOGGLE_EMAIL_VIEW,
    status,
  };
};

export const setSignalRConnection = (url) => {
  return {
    type: types.SET_SIGNALR_CONNECTION,
    url,
  };
};

export const setSignalRConnectionSuccess = (connection) => {
  return {
    type: types.SET_SIGNALR_CONNECTION_SUCCESS,
    connection,
  };
};
export const setSignalRConnectionFail = (err) => {
  return {
    type: types.SET_SIGNALR_CONNECTION_FAIL,
    err,
  };
};

export const setNewMsgInfo = (newMsgInfo) => {
  return {
    type: types.NEW_MSG_RECEIVE_INFO,
    newMsgInfo,
  };
};

export const setNewEmailInfo = (newEmailInfo) => {
  return {
    type: types.NEW_EMAIL_RECEIVE_INFO,
    newEmailInfo,
  };
};

export const resetNewEmailInfo = (payload) => {
  return { type: types.RESET_NEW_EMAIL_INFO, payload };
};

//* VIDEO CALL
export const toggleVideoCall = (payload) => {
  return { type: types.TOGGLE_VIDEO_CALL, payload };
};

export const toggleVideoCallSuccess = (payload) => {
  return { type: types.TOGGLE_VIDEO_CALL_SUCCESS, payload };
};

export const toggleVideoCallFail = (payload) => {
  return { type: types.TOGGLE_VIDEO_CALL_FAIL, payload };
};

export const toggleIsReceivingCall = (status) => ({
  type: types.TOGGLE_IS_RECEIVING_CALL,
  status,
});

export const toggleIsCallAccepted = (status) => {
  return { type: types.TOGGLE_IS_CALL_ACCEPTED, status };
};
//* MESSAGE NOTIFICATION
export const getEmailAndMessageNotify = (params) => {
  return {
    type: types.GET_EMAIL_AND_MESSAGE_NOTIFY,
    params,
  };
};

export const getMessageNotifySuccess = (totalChatUnread) => {
  return {
    type: types.GET_MESSAGE_NOTIFY_SUCCESS,
    totalChatUnread,
  };
};

export const getEmailNotifySuccess = (totalEmailUnread) => {
  return {
    type: types.GET_EMAIL_NOTIFY_SUCCESS,
    totalEmailUnread,
  };
};

export const getEmailAndMessageNotifyFail = (error) => {
  return {
    type: types.GET_EMAIL_AND_MESSAGE_NOTIFY_ERROR,
    error,
  };
};

export const makeAThreadAsRead = (params) => {
  return {
    type: types.MAKE_A_THREAD_AS_READ,
    params,
  };
};

export const makeAThreadAsReadSuccess = (message) => {
  return {
    type: types.MAKE_A_THREAD_AS_READ_SUCCESS,
    message,
  };
};

export const makeAThreadAsReadError = (error) => {
  return {
    type: types.MAKE_A_THREAD_AS_READ_ERROR,
    error,
  };
};

//* markdown table and entity action
export const updateMdTableAction = (payload) => {
  return {
    type: types.UPDATE_MD_TABLE_ACTION,
    payload,
  };
};

export const toggleIsGettingPlaceholderList = (status) => {
  return {
    type: types.TOGGLE_IS_GETTING_PLACEHOLDER_LIST,
    status,
  };
};

export function updateFilterProducts(filterProducts) {
  return { type: types.UPDATE_FILTER_PRODUCTS, filterProducts };
}

export function updateFilterMemberProduct(filterMemberProducts) {
  return { type: types.UPDATE_FILTER_MEMBER_PRODUCT, filterMemberProducts };
}

export function loadProductBrickCode(params) {
  return {
    type: types.LOAD_PRODUCT_BRICK_CODE,
    params,
  };
}

export const toggleFinishLoadMoreMediaAsset = (status) => ({
  type: types.TOGGLE_FINISH_LOAD_MORE_MEDIA,
  status,
});

export function loadProductTargetMarket(params) {
  return {
    type: types.LOAD_PRODUCT_TARGET_MARKET,
    params,
  };
}

export function removeHierarchyAlertId() {
  return {
    type: types.REMOVE_HIERARCHY_ALERT_ID,
  };
}

export function changeHierarchyAlertId(params) {
  return {
    type: types.CHANGE_HIERARCHY_ALERT_ID,
    params,
  };
}

export function updateListAfterSendMessage(checkGetListAfterSendMessage) {
  return {
    type: types.UPDATE_LIST_AFTER_SEND_MESSAGE,
    checkGetListAfterSendMessage,
  };
}
export function updateListAfterSendEmail(checkGetListAfterSendEmail) {
  return {
    type: types.UPDATE_LIST_AFTER_SEND_EMAIL,
    checkGetListAfterSendEmail,
  };
}

export const toggleNewSyndicationHistory = (value) => {
  return {
    type: types.TOGGLE_NEW_SYNDICATION_HISTORY,
    payload: value,
  };
};

export const toggleFilterPendingPublication = (value) => {
  return {
    type: types.TOGGLE_FILTER_PENDING_PUBLICATION,
    payload: value,
  };
};

export const toggleProductNew = (value) => {
  return {
    type: types.TOGGLE_PRODUCT_NEW,
    payload: value,
  };
};

export const toggleMemberNew = (value) => {
  return {
    type: types.TOGGLE_MEMBER_NEW,
    payload: value,
  };
};

export const toggleAssetNew = (value) => {
  return {
    type: types.TOGGLE_ASSET_NEW,
    payload: value,
  };
};

export const toggleRequestNew = (value) => {
  return {
    type: types.TOGGLE_REQUEST_NEW,
    payload: value,
  };
};

export const saveCurrentSelectedPermissionRequest = (value) => {
  return {
    type: types.SAVE_CURRENT_SELECTED_PERMISSION_REQUEST,
    payload: value,
  };
};

export const updateEnvironment = (environmentName) => ({
  type: types.UPDATE_ENVIRONEMENT,
  environmentName,
});

export const toggleProductUnmatch = (value) => {
  return {
    type: types.TOGGLE_PRODUCT_UNMATCH,
    payload: value,
  };
};

export const toggleMemberUnmatch = (value) => {
  return {
    type: types.TOGGLE_MEMBER_UNMATCH,
    payload: value,
  };
};

export const toggleAssetUnmatch = (value) => {
  return {
    type: types.TOGGLE_ASSET_UNMATCH,
    payload: value,
  };
};

export const toggleRequestUnmatch = (value) => {
  return {
    type: types.TOGGLE_REQUEST_UNMATCH,
    payload: value,
  };
};

export function updateFilterMembers(filterMembers) {
  return { type: types.UPDATE_FILTER_MEMBERS, filterMembers };
}

export const toggleFilterPendingSsoUser = (value) => {
  return {
    type: types.FILTER_SSO_USER_PENDING,
    payload: value,
  };
};

export const toggleFilterPendingSsoMember = (value) => {
  return {
    type: types.FILTER_SSO_MEMBER_PENDING,
    payload: value,
  };
};

export const updateCreditCardEulaModal = (value) => {
  return {
    type: types.TOGGLE_CREDIT_CARD_EULA_MODAL,
    payload: value,
  };
};

export const updateCreditCardEulaAccepted = (value) => {
  return {
    type: types.UPDATE_CREDIT_CARD_EULA_ACCEPTED,
    payload: value,
  };
};

export const updateShowChargeWarning = (value) => {
  return {
    type: types.UPDATE_SHOW_CHARGE_WARNING,
    payload: value,
  };
};

export const updateShowCardEulaModalWhen = (value) => {
  return {
    type: types.UPDATE_SHOW_CREDIT_CARD_MODAL_WHEN,
    payload: value,
  };
};
export const showExportLoading = () => {
  return {
    type: types.SHOW_EXPORT_LOADING_RIBBON,
  };
};
export const cancelExportLoading = () => {
  return {
    type: types.CANCEL_EXPORT_LOADING_RIBBON,
  };
};

export const updateSearchTextDataSyncTimeTracking = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_DATA_SYNC_TIME_TRACKING,
  payload: text,
});
export const updateSearchTextQcTimeTracking = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_QC_TIME_TRACKING,
  payload: text,
});
export const handleLogout = () => ({
  type: types.LOGOUT,
});

export function updateFilterUserMaintenance(filterUserMaintenance) {
  return { type: types.UPDATE_FILTER_USER_MAINTENANCE, filterUserMaintenance };
}
export function updateFilterTicketingSystem(filterTicketingSystem) {
  return { type: types.UPDATE_FILTER_TICKETING_SYSTEM, filterTicketingSystem };
}
export const toggleNewTicketingSystem = (value) => {
  return {
    type: types.TOGGLE_NEW_TICKETING_SYSTEM,
    payload: value,
  };
};

export const updateSearchTextQaSpecWorkflow = (text) => ({
  type: types.UPDATE_SEARCH_TEXT_QA_SPEC_WORKFLOW,
  payload: text,
});

export const resetReduxState = () => ({
  type: types.RESET_REDUX_STATE,
});
