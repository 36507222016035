import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from 'antd';

/**
 * Basic footer which inherits from footer layout of ant design
 * @author Brian Pick
 */
const Footer = (props) => {
    const { 
        children, 
        ...rest 
    } = props;
    return (
        <Layout.Footer {...rest}>
            {children}
        </Layout.Footer>
    );
}

Footer.propTypes = {
    /** 
     * list children will render within footer component
     */
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ])
}

export default Footer;