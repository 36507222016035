const mapToDragColumndef = (columnDefs) => {
  let nextColDef;
  if (columnDefs?.length > 0) {
    nextColDef = [...columnDefs];
    nextColDef = [
      {
        field: 'dragColumn',
        width: 50,
        filter: false,
      },
      ...columnDefs,
    ];
  }
  return nextColDef;
};

export default mapToDragColumndef;
