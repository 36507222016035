import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  Modal,
  Button,
  Input,
  Radio,
  Row,
  Col,
  Tooltip,
  Badge,
  Typography,
  List,
  notification,
} from 'antd';
import { useIntl } from 'react-intl';
import {
  SearchOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import MappingType from '../add-statement/MappingType';
import {
  PACKAGE_LEVELS,
  MAPPING_TYPE,
  MAPPING_REQUIRED,
} from 'static/Constants';
import { Form, SourceField, dialogFunction } from 'common/components';
import { TextTooltip } from 'common/components';

import messages from 'i18n/messages/product';
import * as mappingService from 'services/mapping';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { v4 as uuidv4 } from 'uuid';

import './MappingConfig.less';

const MappingConfig = ({
  isEdit = false,
  visible,
  onCancel,
  mappingId,
  mappingConfig,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const gridApi = useRef(null);

  const [formValues, setFormValues] = useState({
    mapType: MAPPING_TYPE[0].value,
    required: MAPPING_REQUIRED[0].value,
    packageLevel: PACKAGE_LEVELS[0],
  });
  const [displaySourceField, setDisplaySourceField] = useState(false);
  const [sourceField, setSourceField] = useState('');

  const [visibleAddStatement, setVisibleAddStatement] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [rowSelected, setRowSelected] = useState(null);
  const [modeStatement, setModeStatement] = useState(null);
  const [dataSelectedStatement, setDataSelectedStatement] = useState({});
  const [formItemFieldName, setFormItemFieldName] = useState({
    value: '',
    touched: false,
  });

  const [errorsForm, setErrorsForm] = useState([]);

  const isEmptyFieldName = useMemo(() => {
    if (errorsForm.length === 0) return false;

    if (errorsForm[0] && errorsForm[0].name.includes('targetField')) {
      return true;
    }
    return false;
  }, [errorsForm]);

  const intl = useIntl();
  const [form] = Form.useForm();
  const reloadPage = useDispatchReloadPage();

  const invalidFieldName = useMemo(
    () => checkFieldName(formItemFieldName.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formItemFieldName.value]
  );

  const isShowValidateFieldName =
    formItemFieldName.value !== '' &&
    !invalidFieldName &&
    formItemFieldName.touched;

  //* Wait for Edit
  const initialFormValues = isEdit ? mappingConfig : formValues;
  const mapType = formValues?.mapType;

  //* Handle Edit Config
  useEffect(() => {
    if (isEdit) {
      setSourceField(mappingConfig?.sourceField);
      setFormValues({ ...formValues, mapType: mappingConfig?.mapType });
      setFormItemFieldName({
        ...formItemFieldName,
        value: mappingConfig?.fieldName,
      });
      setRowData(mappingConfig?.mappingPropertyExpressions);
    }
  }, [visible, mappingConfig, isEdit]);

  const onGridReady = (params) => {
    gridApi.current = params.api;
    params.api.sizeColumnsToFit();
  };
  const autoSizeColumns = (params) => {
    const colIds = params.columnApi
      .getAllDisplayedColumns()
      .map((col) => col.getColId());

    params.columnApi.autoSizeColumns(colIds);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const onHandleSuccess = () => {
    reloadPage();
    onCancel();
  };

  const handleCancelAndNotify = (mes) => {
    if (isEdit) {
      notification.error({
        message: mes ? mes : 'Cannot Update Mapping Config',
      });
    } else {
      notification.error({
        message: mes ? mes : 'Cannot Create Mapping Config',
      });
    }

    handleCancel();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!invalidFieldName) return;

        setSubmitLoading(true);

        if (isEdit) {
          values.id = mappingConfig?.mappingPropertyId;
        }

        values.mappingId = mappingId;
        values.mappingPropertyExpressions = filterRowData(rowData);
        values.sourceField = sourceField;

        mappingService
          .saveMappingConfigProperties(values)
          .then(async (res) => {
            setSubmitLoading(true);
            const { isSuccess, message } = res;

            if (isSuccess) {
              if (isEdit) {
                notification.success({
                  message: 'Update Mapping Config Success',
                });
              } else {
                notification.success({
                  message: 'Create Mapping Config Success',
                });
              }

              onHandleSuccess();
            } else {
              setSubmitLoading(false);
              handleCancelAndNotify(message);
            }
          })
          .catch((err) => {
            setSubmitLoading(false);
            handleCancelAndNotify();
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      })
      .catch((error) => {
        setErrorsForm(error?.errorFields ?? []);
      });
  };

  const onFinish = (values) => {};

  const handleFormValues = (value) => {
    setFormValues({ ...formValues, ...value });
  };

  // * Reset Expression Data
  // * Hide Source Field
  const handleChangeMapType = (event) => {
    const { value } = event.target;
    setRowData([]);
    setDisplaySourceField(false);
    setFormValues({ ...formValues, mapType: value });
  };

  const handleGetSourceField = useCallback((sourceField) => {
    if (!sourceField) {
      setSourceField('');
    } else {
      const dataSourceField =
        sourceField?.moduleName !== 'NoHeader'
          ? `${sourceField?.moduleName}.${sourceField?.fieldName}`
          : `${sourceField?.fieldName}`;

      setSourceField(dataSourceField);
    }
  }, []);

  const submitAddStatement = (value) => {
    if (modeStatement === 'add') {
      setRowData([...rowData, { ...value, id: uuidv4() }]);
    } else {
      const newRowData = [
        ...rowData.slice(0, rowSelected),
        value,
        ...rowData.slice(rowSelected + 1),
      ];

      setRowData(newRowData);
    }
  };

  const onRowSelected = () => {
    if (gridApi?.current?.getSelectedRows().length > 0) {
      const selectedRow = gridApi.current.getSelectedRows()[0];

      const index = rowData.findIndex(
        (element) => element.id === selectedRow.id
      );
      setRowSelected(index);
      setDataSelectedStatement(selectedRow);
    }
  };

  const handleDelete = () => {
    if (rowSelected === 0 && rowData?.[1]?.statementType === 'Else') {
      dialogFunction({
        type: 'warn',
        content: 'Else Statement cannot be the first Expression!',
        okText: 'OK',
        okButtonProps: {
          type: 'warning',
        },
        cancelButtonProps: {
          style: { display: 'none' },
        },
      });
    } else {
      dialogFunction({
        type: 'warn',
        content: 'Are you sure to delete the selected statement?',
        okText: 'Delete',
        okButtonProps: {
          type: 'danger',
        },
        onOk: () => {
          let arr = [...rowData];
          arr.splice(rowSelected, 1);
          setRowData(arr);
          setRowSelected(null);
        },
      });
    }
  };

  const handleClickAddStatement = () => {
    setVisibleAddStatement(true);
    setModeStatement('add');
  };

  const handleEdit = () => {
    setModeStatement('edit');
    setVisibleAddStatement(true);
  };

  return (
    <>
      {visible && (
        <Modal
          title={
            isEdit
              ? intl.formatMessage(messages.editMappingConfig)
              : intl.formatMessage(messages.mappingConfig)
          }
          visible={visible}
          width={1100}
          maskClosable={false}
          onCancel={handleCancel}
          // bodyStyle={{ height: '60vh' }}
          footer={[
            <Button
              key='submit'
              type='primary'
              onClick={handleSubmit}
              loading={submitLoading}
            >
              Save
            </Button>,
            <Button key='back' onClick={handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <Row gutter={[16, 0]}>
            <Col span={displaySourceField ? 12 : 24}>
              <Form
                name='mappingConfig'
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={initialFormValues}
                onValuesChange={(values) => handleFormValues(values)}
                onFinish={onFinish}
                labelAlign='left'
                style={styles.form}
                preserve={false}
              >
                <Form.Item
                  label='Field Name'
                  name='targetField'
                  validateStatus={
                    (formItemFieldName.touched && !invalidFieldName) ||
                    isEmptyFieldName
                      ? 'error'
                      : 'validating'
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please input your field name',
                    },
                  ]}
                  className='mapping-config__field-name'
                >
                  <Input
                    onChange={(event) => {
                      setFormItemFieldName({
                        ...formItemFieldName,
                        value: event.target.value,
                        touched: true,
                      });
                      setErrorsForm([]);
                    }}
                  />
                </Form.Item>

                {isShowValidateFieldName && <ValidateFieldNameMapping />}

                <Form.Item label='Description' name='description'>
                  <Input.TextArea rows={4} maxLength={300} />
                </Form.Item>

                <Form.Item name='mapType' label='Map type'>
                  <Radio.Group
                    style={styles.radioGroup}
                    onChange={handleChangeMapType}
                  >
                    <Row>
                      {MAPPING_TYPE.map((type) => (
                        <Col span={8} key={type.value}>
                          <Radio value={type.value}>{type.label}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name='required' label='Required'>
                  <Radio.Group style={styles.radioGroup}>
                    <Row>
                      {MAPPING_REQUIRED.map((type) => (
                        <Col span={8} key={type.value}>
                          <Radio value={type.value}>{type.label}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>

                <MappingType
                  mapType={mapType}
                  rowSelected={rowSelected}
                  rowData={rowData}
                  visibleAddStatement={visibleAddStatement}
                  handleClickAddStatement={handleClickAddStatement}
                  onRowSelected={onRowSelected}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  setVisibleAddStatement={setVisibleAddStatement}
                  submitAddStatement={submitAddStatement}
                  dataSelectedStatement={dataSelectedStatement}
                  modeStatement={modeStatement}
                  onGridReady={onGridReady}
                  autoSizeColumns={autoSizeColumns}
                />

                {mapType !== MAPPING_TYPE[1].value &&
                  mapType !== MAPPING_TYPE[2].value && (
                    <Form.Item name='sourceField' label='Source Field'>
                      <Row justify='space-between' align='middle'>
                        <Tooltip title={sourceField && sourceField}>
                          <Col span={22}>
                            <Input
                              style={{ width: '100%' }}
                              value={sourceField}
                              disabled
                              className='mapping-config__input-display'
                            />
                          </Col>
                        </Tooltip>
                        <Col span={2} style={{ textAlign: 'right' }}>
                          <Tooltip title='Search field'>
                            <Button
                              type='primary'
                              icon={<SearchOutlined />}
                              style={{ marginLeft: 6, borderRadius: 4 }}
                              onClick={() => setDisplaySourceField(true)}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Form.Item>
                  )}
              </Form>
            </Col>

            {displaySourceField &&
              mapType !== MAPPING_TYPE[1].value &&
              mapType !== MAPPING_TYPE[2].value && (
                <Col span={12}>
                  <Badge
                    className='mapping-config__badge'
                    count={
                      <span onClick={() => setDisplaySourceField(false)}>
                        <CloseOutlined style={{ padding: 3 }} />
                      </span>
                    }
                  >
                    <SourceField
                      className='mapping-config__source-field'
                      handleGetSourceField={handleGetSourceField}
                      handleResetNameRenderSourceField={() =>
                        setDisplaySourceField(false)
                      }
                    />
                  </Badge>
                </Col>
              )}
          </Row>
        </Modal>
      )}
    </>
  );
};

const messagesError = [
  {
    title: 'Alphabet characters',
  },
  {
    title: 'Number characters',
  },
  {
    title: 'Underscore ( _ ) ',
  },
  {
    title: 'Dash ( - )',
  },
];

const ValidateFieldNameMapping = () => {
  return (
    <Col offset={6}>
      <Typography.Text strong style={{ color: '#ff4d4f' }}>
        Your field name should be allowed:
      </Typography.Text>
      <List
        className='mapping-config__list'
        itemLayout='horizontal'
        bordered={false}
        dataSource={messagesError}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<InfoCircleOutlined />}
              title={<Typography.Text>{item.title}</Typography.Text>}
            />
          </List.Item>
        )}
      />
    </Col>
  );
};

export default MappingConfig;

const styles = {
  form: {
    // padding: '0 30px',
  },
  radioGroup: {
    width: '100%',
  },
};

const checkFieldName = (fieldNameMapping) => {
  const regex = new RegExp(/^[A-Za-z0-9\-_]+$/i);
  const validString = regex.test(fieldNameMapping);

  return validString;
};

// Not add id that is generated by UUID to params
const filterRowData = (rowData) => {
  let result = [];

  if (rowData) {
    rowData.forEach((row) => {
      if (typeof row?.id === 'string') {
        const { id, ...otherData } = row;
        result.push(otherData);
      } else {
        result.push(row);
      }
    });
  }

  return result;
};
