import { Badge } from 'antd';

import {
  QaSpecIngredient,
  QaSpecDocsAndCertificates,
  QaSpecProduct,
  QaSpecManufacturingProcess,
  QaFormula,
  QaAllergen,
  QaSpecComponent,
  QaSpecNutritionData,
  QaSpecContacts,
  QaFreshSeafood,
  QaFreshMeat,
  QaPackaging,
  QaClaims,
  QaFreshProduce,
  QaFreshDeli,
  QaSpecProductContain,
  // QaPetNonFood,
  QaPetFood,
} from 'pages/qa-spec/components';

import { useQaTabChecker } from 'pages/qa-spec/components/edit-tab-checker';

import { ReactComponent as NonPetFoodIcon } from 'assets/svg-icons/pet-toy.svg';

const EditItemCount = ({ tabKey, children }) => {
  const { editItemCountByTab } = useQaTabChecker();

  return (
    <Badge count={editItemCountByTab?.[`${tabKey}`]} offset={[15, 4]}>
      {children && children}
    </Badge>
  );
};

const qaPackagingRender = ({ type, productFull }) => ({
  key: 'labeling',
  icon: (
    <EditItemCount tabKey='labeling'>
      <i class='fa-solid fa-box-open'></i>
    </EditItemCount>
  ),
  des: 'Labeling',
  content: (isTabActive) => (
    <QaPackaging productFull={productFull} isTabActive={isTabActive} />
  ),
  visible: type !== 'Pet',
});

const qaFreshMeatRender = {
  key: 'fresh-meat',
  icon: (
    <EditItemCount tabKey='fresh-meat'>
      <i class='fa-solid fa-cow'></i>
    </EditItemCount>
  ),
  des: 'Fresh - Meat',
  content: <QaFreshMeat />,
};

const qaClaimsRender = (type) => ({
  key: 'claims',
  icon: (
    <EditItemCount tabKey='claims'>
      <i class='fa-solid fa-file-circle-check'></i>
    </EditItemCount>
  ),
  des: 'Claims',
  content: (isTabActive) => <QaClaims isTabActive={isTabActive} />,
  visible: type !== 'Pet',
});

export const getTabListByQaType = ({
  type,
  refetchProductItems,
  productLoading,
  productId,
  productFull,
  productEnums,
  productItemModules,
  ownerId,
}) => {
  return [
    {
      key: 'product-specification',
      icon: (
        <EditItemCount tabKey='product-specification'>
          <i class='fa-solid fa-clipboard-list'></i>
        </EditItemCount>
      ),
      des: 'Product Specification',
      content: (isTabActive) => (
        <QaSpecProduct
          refetchProductItems={refetchProductItems}
          productLoading={productLoading}
          productId={productId}
          productFull={productFull}
        />
      ),
      visible: true,
    },
    {
      key: 'fresh-seafood',
      icon: (
        <EditItemCount tabKey='fresh-seafood'>
          <i class='fa-solid fa-fish-fins'></i>
        </EditItemCount>
      ),
      des: 'Fresh - Seafood',
      content: (isTabActive) => <QaFreshSeafood isTabActive={isTabActive} />,
      visible: type === 'Fresh - Seafood',
    },
    {
      key: 'fresh-meat',
      icon: (
        <EditItemCount tabKey='fresh-meat'>
          <i class='fa-solid fa-cow'></i>
        </EditItemCount>
      ),
      des: 'Fresh - Meat',
      content: (isTabActive) => <QaFreshMeat isTabActive={isTabActive} />,
      visible: type === 'Fresh - Meat',
    },
    {
      key: 'fresh-produce',
      icon: (
        <EditItemCount tabKey='fresh-produce'>
          <i class='fa-solid fa-apple-whole'></i>
        </EditItemCount>
      ),
      des: 'Fresh - Produce',
      content: (isTabActive) => <QaFreshProduce />,
      visible: type === 'Fresh - Produce',
    },

    {
      key: 'fresh-deli',
      icon: (
        <EditItemCount tabKey='fresh-deli'>
          <i class='fa-solid fa-cheese'></i>
        </EditItemCount>
      ),
      des: 'Fresh - Deli',
      content: (isTabActive) => <QaFreshDeli />,
      visible: type === 'Fresh - Deli',
    },
    {
      key: 'ingredients',
      icon: (
        <EditItemCount tabKey='ingredients'>
          <i class='fa-solid fa-leaf'></i>
        </EditItemCount>
      ),
      des: 'Ingredients',
      content: (isTabActive) => (
        <>{isTabActive && <QaSpecIngredient productId={productId} />}</>
      ),
      visible: type !== 'HBC / OTC' && type !== 'Non-Food',
    },
    {
      key: 'allergens',
      icon: (
        <EditItemCount tabKey='allergens'>
          <i class='fa-solid fa-person-dots-from-line'></i>
        </EditItemCount>
      ),
      des: 'Allergens',
      content: (isTabActive) => (
        <>
          {isTabActive && (
            <QaAllergen
              productEnums={productEnums}
              productItemModules={productItemModules}
              isTabActive={isTabActive}
            />
          )}
        </>
      ),
      visible: true,
    },
    {
      key: 'components',
      icon: (
        <EditItemCount tabKey='components'>
          <i class='fa-solid fa-table-columns'></i>
        </EditItemCount>
      ),
      des: 'Components',
      content: (isTabActive) => <QaSpecComponent productId={productId} />,
      visible: type === 'HBC / OTC' || type === 'Non-Food' || type === 'Pet',
    },
    {
      key: 'facts-panel',
      icon: (
        <EditItemCount tabKey='facts-panel'>
          <i class='fa-solid fa-table'></i>
        </EditItemCount>
      ),
      des: 'Facts Panel',
      content: (isTabActive) => <QaSpecNutritionData productId={productId} />,
      visible: type !== 'Non-Food',
    },
    qaClaimsRender(type),
    {
      key: 'pet-food',
      icon: (
        <EditItemCount tabKey='pet-food'>
          <i class='fa-solid fa-bone'></i>
        </EditItemCount>
      ),
      des: 'Pet Food',
      content: (isTabActive) => <QaPetFood />,
      visible: type === 'Pet',
    },
    // {
    //   key: 'pet-non-food',
    //   icon: (
    //     <EditItemCount tabKey='pet-non-food'>
    //       <NonPetFoodIcon />
    //     </EditItemCount>
    //   ),
    //   des: 'Pet Non-Food',
    //   content: (activeTab) => {
    //     if (activeTab === 'pet-non-food')
    //       return <QaPetNonFood productId={productId} />;
    //   },
    //   visible: type === 'Pet',
    // },
    {
      key: 'formula',
      icon: (
        <EditItemCount tabKey='formula'>
          <i class='fa-solid fa-book'></i>
        </EditItemCount>
      ),
      des: 'Formula',
      content: (activeTab) => {
        return <QaFormula productEnums={productEnums} type={type} />;
      },
      visible: true,
    },
    {
      key: 'manufacturing-process',
      icon: (
        <EditItemCount tabKey='manufacturing-process'>
          <i class='fa-solid fa-industry'></i>
        </EditItemCount>
      ),
      des: 'Manufacturing Process',
      content: (isTabActive) => (
        <QaSpecManufacturingProcess
          productItemModules={productItemModules}
          productEnums={productEnums}
          ownerId={ownerId}
        />
      ),
      visible: true,
    },
    qaPackagingRender({ type, productFull }),
    {
      key: 'contacts',
      icon: (
        <EditItemCount tabKey='contacts'>
          <i class='fa-solid fa-address-book'></i>
        </EditItemCount>
      ),
      des: 'Contacts',
      content: (isTabActive) => <QaSpecContacts id={productId} />,
      visible: true,
    },
    {
      key: 'documents-certificates',
      icon: (
        <EditItemCount tabKey='documents-certificates'>
          <i class='fa-solid fa-certificate'></i>
        </EditItemCount>
      ),
      des: 'Documents/ Certificates',
      content: (isTabActive) => (
        <>
          {isTabActive && <QaSpecDocsAndCertificates productId={productId} />}
        </>
      ),
      visible: true,
    },
    {
      key: 'product-contains',
      icon: (
        <EditItemCount tabKey='product-contains'>
          <i class='fa-solid fa-circle-dot'></i>
        </EditItemCount>
      ),
      des: 'Product Contains',
      content: (activeTab) => <QaSpecProductContain productId={productId} />,
      visible: checkIsDollarProduct(ownerId),
    },
  ];
};

export const checkIsDollarProduct = (ownerId) => {
  return ownerId === +process.env.REACT_APP_DOLLAR_GENERAL_ID;
};
