import React, { useState, useEffect, useContext } from 'react';

import { Image } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { useDragDropPosHooks } from 'common/components';
import { DragContext } from 'common/components/drapDropWrap/drag-drop-pos/context/DragContext';

import DefaultBackgroundImage from 'assets/login-introduction.png';
import {
  useGetNewComponentPosition,
  useTogglePageWrapLayer,
  useRemoveNewComponentOutSizeContainer,
} from '../../hooks';
import { COMPONENT_TYPE, CREATE_NEW_STATUS } from '../../constants';

import './PageEditorToolAddNewImage.less';

const PageEditorToolAddNewImage = (props) => {
  const {
    wrapId,
    wrapKey,
    selectedHook,
    detailShowTargetHook,
    showDetailComponentData,
    componentHandlers,
    scale,
  } = props;

  const [selected, setSelected, clearSelect] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const startDragHook = useState();
  const [startDrag, setStartDrag] = startDragHook;
  const [newKey, setNewKey] = useState(null);
  const createStatusHook = useState(CREATE_NEW_STATUS.DONE);
  const [createStatus, setCreateStatus] = createStatusHook;

  const { onCreateNewComponent, onChangeComponentValue, onDragNewComponent } =
    componentHandlers;

  const addNewImageKey = `${wrapKey}-new-image`;

  const [dragPosInst, dragStyle, refDragTag] = useDragDropPosHooks([
    clearSelect,
    addNewImageKey,
  ]);

  const togglePageWrapLayer = useTogglePageWrapLayer(wrapKey);

  const newPosition = useGetNewComponentPosition(
    wrapKey,
    COMPONENT_TYPE.IMAGE,
    scale
  );

  const CreateNewImage = () => {
    const newComponentKey = onCreateNewComponent({
      componentType: COMPONENT_TYPE.IMAGE,
      values: {
        top: newPosition?.top,
        left: newPosition?.left,
      },
    });

    setNewKey(newComponentKey);
    setDetailShowTarget(newComponentKey);
  };

  const onDragNewImage = async () => {
    CreateNewImage();
    setStartDrag(true);
    setCreateStatus(CREATE_NEW_STATUS.START);
  };

  const stopDragNewImage = () => {
    // setNewKey(false);
  };

  const isDragging = useContext(DragContext);

  useRemoveNewComponentOutSizeContainer(
    createStatusHook,
    isDragging,
    newKey,
    componentHandlers
  );

  useEffect(() => {
    //* start handle move new component
    const startMoveNewComponent = () => {
      if (startDrag && newKey) {
        onDragNewComponent({
          showDetailComponentData,
          key: newKey,
          fieldNameX: 'left',
          fieldNameY: 'top',
          createStatusHook,
          startDragHook,
          wrapId,
          dragPosInst,
        });
      }
    };

    startMoveNewComponent();
  }, [startDrag, showDetailComponentData, newKey]);

  return (
    <div className='page-editor-add-new-image'>
      <div onMouseDown={onDragNewImage} onMouseLeave={stopDragNewImage}>
        <PictureOutlined id={addNewImageKey} ref={refDragTag} />
      </div>
    </div>
  );
};

export default PageEditorToolAddNewImage;
