import React from 'react';
import PropTypes from 'prop-types';

const JitsiFrame = (props) => {
  const { jitsiRef, loading } = props;
  return (
    <div
      id='react-jitsi-frame'
      ref={jitsiRef}
      style={{
        width: '100%',
        height: '100%',
        display: loading ? 'none' : 'block',
      }}
    />
  );
};

JitsiFrame.propTypes = {
  jitsiRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  loading: PropTypes.bool,
};

export default JitsiFrame;
