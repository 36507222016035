import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import userSelectors from 'redux/user/selectors';
import * as action from 'redux/user/actions';
import { Row, notification, Button, Dropdown } from 'antd';
import Messages from 'i18n/messages/user-profile.js';
import { injectIntl } from 'react-intl';
import AgGrid from 'common/components/ag-grid/AgGrid';
import {
  WithLoading,
  WithAggridHeaderEdit,
  AgGridDownloadOptions,
  FormEditButton,
  FormAddButton,
  FormDeleteButton,
} from 'common/components';
import { DownloadOutlined } from '@ant-design/icons';
import dialogFunction from 'common/components/dialog-function/DialogFunction';
import OverviewLocationsForm from './OverviewLocationsForm';
import * as _ from 'lodash';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import reducer from 'common/components/grid-view/controllers/reducer';
import saga from 'common/components/grid-view/controllers/saga';
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';

import { GRID_ID } from 'common/components/grid-view/utils';
import * as userServices from 'services/user';
import * as endpoints from 'services/user/endpoints';
import * as userActions from 'redux/user/actions';

import { v4 as uuidv4 } from 'uuid';

const key = 'gridView';

const OverviewLocations = (props) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const { changeRow, editable, userProfile, intl } = props;
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isEditLocation, setIsEditLocation] = useState(false);
  const [isEditLocationDetail, setIsEditLocationDetail] = useState(false);
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [isMouseEnterLocationSection, setIsMouseEnterLocationSection] =
    useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsSelected, setRowSelected] = useState([]);
  const [rowsSelectedValue, setRowSelectedValue] = useState([]);
  const [downloadLocationLoading, setDownloadLocationLoading] = useState(false);
  const [downloadFileType, setDownloadFileType] = useState('');

  const [keyGrid, setKeyGrid] = useState(null);

  const loginProfile = useSelector(userSelectors.makeSelectUserProfile());
  const userId = id ? id : loginProfile?.data?.id;

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const userProfileLocations = useSelector(
    userSelectors.makeSelectUserProfileLocations()
  );

  const totalPagination = userProfileLocations?.data?.paging?.totalRecord
    ? userProfileLocations?.data?.paging?.totalRecord
    : 0;

  const columns = useSelector(userSelectors.makeSelectColumnsLocations());

  useEffect(() => {
    dispatch(action.userProfileLocationsGridColumnInfo('user-addresses'));
  }, [dispatch]);

  const [notiTime, setNotiTime] = useState(false);

  useEffect(() => {
    onSetSelectedRows([], null);
  }, [isEditLocation]);

  const clickEditLocation = () => {
    setIsEditLocationDetail(true);
  };

  const clickDeleteLocation = async () => {
    return await userServices.deleteUserProfileLocation({
      listIds: [rowsSelectedValue[0]?.id],
    });
  };

  const handleToggleIsEditLocation = () => {
    setIsEditLocation(!isEditLocation);
  };

  const handleToggleIsAddLocation = () => {
    setIsAddLocation(true);
  };

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureDelete),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleDeleteCompanyLocations,
    });
  };

  const handleDeleteCompanyLocations = async () => {
    try {
      if (rowsSelected.length > 0) {
        setIsLoading(true);
        const isPrimaryAddress = rowsSelectedValue.find(
          (location) => location.isPrimaryAddress === true
        );
        const res = await userServices.deleteUserProfileLocation({
          listIds: rowsSelected,
          userId: userProfile?.id,
        });
        setIsLoading(false);
        if (res?.isSuccess) {
          notification.success({
            message: intl.formatMessage(Messages.deletedSuccess),
            top: 50,
          });

          setKeyGrid(uuidv4());
          setRowSelected([]);
          setRowSelectedValue([]);

          if (isPrimaryAddress) {
            dispatch(userActions.getUserProfileHeader(userId));
          }
        } else {
          notification.error({
            message: intl.formatMessage(Messages.deletedFail),
            top: 50,
          });
        }
      } else {
        notification.error({
          message: intl.formatMessage(Messages.shouldChooseItemDelete),
          top: 50,
        });
      }
    } catch (error) {}
  };

  const onSetSelectedRows = (selectedNodesDetail, currentRowData) => {
    let list = [];
    let listValue = [];
    selectedNodesDetail.forEach((nodeDetail) => {
      list.push(nodeDetail?.id);
      listValue.push(nodeDetail);
    });

    setRowSelected(list);
    setRowSelectedValue(listValue);
    if (!notiTime && currentRowData) {
      changeRow(currentRowData);
      setNotiTime(true);
      setTimeout(() => {
        setNotiTime(false);
      }, 1000);
    }
  };

  const handleDownloadLocation = (fileType) => {
    setDownloadLocationLoading(true);
    setDownloadFileType(fileType);
  };

  const onDownloadGridHandler = (downloadService) => {
    downloadService
      .catch(() => {
        notification.error({
          message: intl.formatMessage(Messages.downloadLocationError),
        });
      })
      .finally(() => {
        setDownloadLocationLoading(false);
      });
  };

  const selectedOneRow = rowsSelected?.length === 1;
  const selectedMultiRow = rowsSelected?.length > 1;
  const containDeletedLocation = rowsSelectedValue.find(
    (rowItem) => rowItem.status === 'Deleted'
  );

  const allowDeleteLocation =
    (selectedOneRow || selectedMultiRow) && !containDeletedLocation;

  const allowEditLocation =
    selectedOneRow && !selectedMultiRow && !containDeletedLocation;

  return (
    <Row
      className='user-profile-overview__location'
      onMouseEnter={() => {
        if (!isEditLocation && editable) {
          setIsMouseEnterLocationSection(true);
        }
      }}
      onMouseLeave={() => {
        if (!isEditLocation && editable) {
          setIsMouseEnterLocationSection(false);
        }
      }}
    >
      <WithLoading loading={isLoading}>
        <WithAggridHeaderEdit
          allowEdit={editable}
          title={intl.formatMessage(Messages.locations)}
          buttons={[
            <FormDeleteButton
              onClick={confirmDelete}
              disabled={!allowDeleteLocation}
            />,
            <FormEditButton
              onClick={clickEditLocation}
              disabled={!allowEditLocation}
            />,
            <FormAddButton onClick={handleToggleIsAddLocation} />,
          ]}
          extraButtons={[
            <Dropdown
              overlay={
                <AgGridDownloadOptions
                  handleDownload={handleDownloadLocation}
                  loading={downloadLocationLoading}
                />
              }
              placement='bottomRight'
              trigger='click'
            >
              <Button type='link' icon={<DownloadOutlined />} />
            </Dropdown>,
          ]}
        >
          <div className='user-profile-overview__location-grid'>
            <AgGrid
              columnDefs={columns}
              urlGrid={endpoints.GET_USER_PROFILE_LOCATIONS}
              urlGridDistinct={endpoints.GET_USER_PROFILE_LOCATIONS_DISTINCT}
              urlDownload={endpoints.DOWNLOAD_USER_PROFILE_LOCATION}
              styleGrid={{ width: '100%', height: '100%' }}
              gridView={true}
              getGridApi={(gridApi) => setGridApi(gridApi)}
              isShowItemSelectionLog={false}
              isCacheEditMode={true}
              gridId={GRID_ID.USER_PROFILE_LOCATION}
              onHandleSelectedRowCustom={onSetSelectedRows}
              triggerDownload={downloadLocationLoading}
              onDownloadGridHandler={onDownloadGridHandler}
              downloadFileType={downloadFileType}
              rowClassRules={{ 'ag-hidden-row': 'true' }}
              totalPagination={totalPagination}
              responseParams='data'
              requestParams={{ userId: userId }}
              key={keyGrid}
            />
          </div>
        </WithAggridHeaderEdit>
        <OverviewLocationsForm
          isOpen={isEditLocationDetail}
          isAddNew={isAddLocation}
          setIsOpen={
            !isAddLocation ? setIsEditLocationDetail : setIsAddLocation
          }
          itemData={detailCurrentItemsSelection[0]}
          gridApi={gridApi}
          userId={userId}
          setIsAddNew={setIsAddLocation}
          userProfile={userProfile}
        />
      </WithLoading>
    </Row>
  );
};

export default injectIntl(OverviewLocations);
