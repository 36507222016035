import React from 'react';
import PropTypes from 'prop-types';
import { forwardTo } from 'utils/common/route';
import { ThumbnailItem } from 'common/components';
import { Images } from 'config/assets';
import * as getLink from 'utils/common/linkBuilder';

/**
 * Member thumbnail is inherit from thumbnail item
 * @param {object} props
 */
const MemberThumbnailCard = (props) => {
  const {
    dataDetail,
    selected,
    onClickItemGrid,
    onClickCheckboxItem,
    hideCheckbox,
    linkTarget,
    isShareBtn = true,
    style,
  } = props;

  const onDoubleClick = (item) => {
    const { id } = item;
    forwardTo(`/company/${id}`);
  };

  const alink = getLink.memberLink(
    dataDetail?.id,
    dataDetail.memberName,
    linkTarget
  );
  return (
    <ThumbnailItem
      dataDetail={dataDetail}
      labelThumbnail={alink}
      imageThumbnail={
        dataDetail.thumb300 || dataDetail.thumb || Images.RIVIR_LOGO_DEFAULT
      }
      selected={selected}
      onClickItemGrid={onClickItemGrid}
      onClickCheckboxItem={onClickCheckboxItem}
      onDoubleClick={onDoubleClick}
      hideCheckbox={hideCheckbox}
      isMemberThumbnail={true}
      isShareBtn={isShareBtn}
      style={style}
    />
  );
};

MemberThumbnailCard.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default React.memo(MemberThumbnailCard);
