import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Checkbox } from 'antd';
import { formatMDY } from 'utils/formatDate';
import { injectIntl } from 'react-intl';
import './AssetCreator.less';

const AssetHeaderInformation = (props) => {
  const { loaded, modified, isShowcase, intl } = props;
  return (
    <Row className='asset-creator'>
      <Col className='asset-creator__label' span={12}>
        {intl.formatMessage({
          id: `Taco.digitalAsset.headerInformation.loaded`,
        })}
      </Col>
      <Col className='asset-creator__value' span={12}>
        {loaded && formatMDY(loaded)}
      </Col>
      <Col className='asset-creator__label' span={12}>
        {intl.formatMessage({
          id: `Taco.digitalAsset.headerInformation.modified`,
        })}
      </Col>
      <Col className='asset-creator__value' span={12}>
        {modified && formatMDY(modified)}
      </Col>
      {isShowcase && (
        <>
          <Col className='asset-creator__label' span={12}>
            {intl.formatMessage({
              id: `Taco.digitalAsset.headerInformation.showcased`,
            })}
          </Col>
          <Col className='asset-creator__value' span={12}>
            <Checkbox checked={isShowcase} style={{ paddingLeft: '2px' }} />
          </Col>
        </>
      )}
    </Row>
  );
};

AssetHeaderInformation.propTypes = {
  loaded: PropTypes.string,
  modified: PropTypes.string,
  isShowcase: PropTypes.bool,
};

export default injectIntl(AssetHeaderInformation);
