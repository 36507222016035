import moment from 'moment';
import produce from 'immer';

import { v4 as uuidv4 } from 'uuid';

import { checkUtcIsoFormat, addUtcForIsoString } from 'utils/timezone';

import { formatMDY } from 'utils/formatDate';

import { ALL_ALLERGENS } from 'static/Constants';

import isEmpty from 'lodash/isEmpty';

// Special case, example: "Test 12", "12". I don't know why moment in our code base doesn't work with those case.
// Maybe find a good solution after, though!
const checkSpecialCaseDate = (value) => {
  if (value && typeof value === 'string') {
    const parsedValue = parseInt(value);

    if (isNaN(parsedValue)) {
      const [value1, value2] = value.split(' ');
      if (value1?.length === 2 && typeof parseInt(value1) === 'number')
        return true;

      if (value2?.length === 2 && typeof parseInt(value2) === 'number')
        return true;
    }

    return true;
  }
};

const checkDateTime = (value) => {
  const { iso, utc } = checkUtcIsoFormat(value);

  if (iso) {
    return moment(value, moment.ISO_8601, true).isValid();
  }

  if (utc) {
    const coveredValue = addUtcForIsoString(value);
    return moment(coveredValue, moment.ISO_8601, true).isValid();
  }

  if (checkSpecialCaseDate(value)) return false;

  return false;
};

// modules on left side
const modulesNameHeader = ['Header', 'ECommerce', 'Certification'];
const keywordForRenderTextarea = ['Description', 'Features', 'Introduction'];

const formatInfo = (info) => {
  if (Array.isArray(info)) {
    return info.join(', ');
  }

  if (typeof info === 'boolean') {
    return info.toString();
  }
  if (typeof info === 'number') {
    return info;
  }

  if (checkDateTime(info)) {
    return formatMDY(info);
  }
  return info ?? 'N/A';
};

const orderModuleProperties = (moduleProperties = {}) => {
  const orderedKeys = Object.keys(moduleProperties).sort();

  let orderedModuleProperties = {};

  orderedKeys.forEach((key) => {
    orderedModuleProperties = {
      ...orderedModuleProperties,
      [key]: moduleProperties[key],
    };
  });

  return orderedModuleProperties;
};

const groupProductModuleProperties = (module) => {
  let newModuleProperties = {};

  for (const { propertyGroup, ...otherProps } of module?.moduleProperties) {
    if (!newModuleProperties[propertyGroup]) {
      newModuleProperties[propertyGroup] = [].concat(otherProps);
    } else {
      newModuleProperties[propertyGroup] =
        newModuleProperties[propertyGroup].concat(otherProps);
    }
  }

  return {
    ...module,
    moduleProperties: orderModuleProperties(newModuleProperties),
  };
};

const isLeftModule = (moduleName) => {
  return modulesNameHeader.includes(moduleName);
};

const findProductModule = (moduleName, data) => {
  const foundModule = data?.find((item) => item.moduleName === moduleName);

  if (foundModule) {
    return {
      nonGroupModule: foundModule,
      groupModule: groupProductModuleProperties(foundModule),
    };
  }
  return {};
};

const formatObjectToCheckValueDatetime = (object) => {
  let result = {};

  if (object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === 'boolean') {
        result = {
          ...result,
          [key]: value,
        };
      } else if (typeof value === 'number') {
        result = {
          ...result,
          [key]: value,
        };
      } else if (checkDateTime(value)) {
        result = {
          ...result,
          [key]: value ? window.moment.utc(value).local() : '',
        };
      } else {
        result = {
          ...result,
          [key]: value,
        };
      }
    }
  }

  return result;
};

const findDataProperties = (moduleName, data) => {
  const foundDataProperties = data.productItemModules.find(
    (item) => item.moduleName === moduleName
  );
  if (foundDataProperties) {
    return foundDataProperties?.data;
  }

  return null;
};

const getInfoProductItem = (
  propertyName,
  dataProperties,
  listName,
  productEnums,
  isArray
) => {
  if (dataProperties) {
    const valueDisplay = dataProperties[`${propertyName}`];

    if (listName && !isArray) {
      const foundEnums = productEnums.find(
        (item) => item.enumName === listName
      );
      const foundEnum = foundEnums?.enumProperties.find(
        (item) => item.enumDisplayName === valueDisplay
      );

      if (!foundEnum) return '';

      const enumDisplay = foundEnum?.enumDisplayName
        ? foundEnum?.enumDisplayName
        : foundEnum?.enumDescription;

      return enumDisplay;
    }

    return valueDisplay;
  }
  return null;
};

const findProductPropertyEnums = (propertyName, dataEnums) => {
  const foundEnums = dataEnums?.find((item) => item.enumName === propertyName);
  if (foundEnums) {
    return foundEnums.enumProperties;
  }

  return [];
};

const formatRequestParams = (params) => {
  let result = {};

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      const isDatetime = moment(value, 'MM/DD/YYYY', true).isValid();

      if (value === undefined) {
        result = {
          ...result,
          [key]: null,
        };
      } else if (value && isDatetime) {
        result = {
          ...result,
          [key]: moment(value).toISOString(),
        };
      } else if (Array.isArray(value)) {
        result = { ...result, [key]: value.filter(Boolean) };
      } else {
        result = {
          ...result,
          [key]: value,
        };
      }
    }
  }

  return result;
};

const orderByDisplayName = (properties) => {
  let result = [];
  if (properties?.length > 0) {
    const orderedKey = properties
      .map((item) => item.propertyDisplayName)
      .sort();

    orderedKey.forEach((key) => {
      const property = properties.find((p) => p.propertyDisplayName === key);
      result.push(property);
    });
  }

  return result ?? properties;
};

const getOrderedByDisplayNameProperties = (productSchema) => {
  let result = [];

  if (productSchema?.length > 0) {
    productSchema.forEach((schema) => {
      if (schema.moduleName === 'ECommerce') {
        result.push({
          ...schema,
          moduleProperties: orderByDisplayName(schema.moduleProperties),
        });
      } else {
        result.push({
          ...schema,
        });
      }
    });
  }
  return result ?? productSchema;
};

const filterModuleProductSchema = (productSchema) => {
  const leftProductSchema = [];
  const rightProductSchema = [];

  if (productSchema) {
    productSchema.forEach((schema) => {
      if (modulesNameHeader.includes(schema.moduleName)) {
        leftProductSchema.push(schema);
      } else {
        rightProductSchema.push(schema);
      }
    });
  }

  return {
    leftProductSchema: getOrderedByDisplayNameProperties(leftProductSchema),
    rightProductSchema,
  };
};

const filterModuleBrickCode = (rightSchema, modulesBrickCode) => {
  const lowerCaseBrickCode = modulesBrickCode.map((item) => item.toLowerCase());
  return rightSchema.filter((schema) =>
    lowerCaseBrickCode.includes(schema.moduleName.toLowerCase())
  );
};

const getNewPrivateProperties = (data, index, fieldType) => {
  const result = produce(data, (draftFile) => {
    draftFile[index].fieldType = fieldType;
  });

  return result;
};

const setValueFieldInPrivateProperties = (data, index, value) => {
  const result = produce(data, (draftFile) => {
    draftFile[index].value = value;
  });

  return result;
};

const formatPrivatePropertiesDatetime = (productPrivateProperties) => {
  return productPrivateProperties.map((property) => {
    if (property.fieldType === 'datetime') {
      return {
        ...property,
        value: property.value ? moment(property.value) : null,
      };
    } else {
      return property;
    }
  });
};

const LENGTH_TEXT_AREA = 200;
const checkRenderTextArea = (displayName, maxLength = 0) => {
  if (maxLength >= LENGTH_TEXT_AREA) return true;

  for (const keyword of keywordForRenderTextarea) {
    if (displayName.includes(keyword)) return true;
  }
  return false;
};

const getModulesName = (modules) => {
  return modules?.map((schema) => schema?.moduleName) ?? [];
};

const getNewActivePanels = (expand, modules, activePanels) => {
  const modulesName = getModulesName(modules);

  if (expand) {
    return activePanels.concat(modulesName);
  } else {
    return activePanels.filter((panel) => !modulesName.includes(panel));
  }
};

// Array doesn't include [], null, undefined. Boolean value is valid because item render is checkbox
const checkArrayHaveValidValues = (object) => {
  if (object) {
    return Object.values(object).some((item) => {
      if (typeof item === 'number') return true;

      if (typeof item === 'boolean' && Boolean(item)) return true;

      return typeof item !== 'boolean' && !isEmpty(item);
    });
  }
  return false;
};

const getSchemaHaveValues = (showValues, dataProduct, modules) => {
  let schema = [];

  if (showValues && dataProduct) {
    const productItemModules = dataProduct?.productItemModules ?? [];

    modules.forEach((m) => {
      const foundDataProperties = productItemModules.find(
        (item) => item.moduleName === m.moduleName
      );

      if (
        !isEmpty(foundDataProperties?.data) &&
        checkArrayHaveValidValues(foundDataProperties?.data)
      ) {
        schema.push(m);
      }
    });

    return schema;
  }
  return [];
};

const searchFields = (module, value) => {
  if (module && value) {
    const lowercaseSearch = value.toLowerCase();
    const lowercasePropertyName = module.propertyName.toLowerCase();
    const lowercaseDisplayName = module.propertyDisplayName.toLowerCase();

    return (
      lowercasePropertyName.includes(lowercaseSearch) ||
      lowercaseDisplayName.includes(lowercaseSearch)
    );
  }
  return false;
};

const getFieldsSuggestion = (searchText, productSchema) => {
  let result = [];

  productSchema.forEach((schema) => {
    schema.moduleProperties.forEach((m) => {
      if (searchFields(m, searchText)) {
        result.push({
          key: uuidv4(),
          moduleName: schema.moduleName,
          value: `${schema.moduleDisplayName} - ${m.propertyDisplayName}`,
          label: `${schema.moduleDisplayName} - ${m.propertyDisplayName}`,
        });
      }
    });
  });

  return result;
};

const getFieldsEditedSuggestion = (searchText, productSchema) => {
  let result = [];

  productSchema.moduleProperties.forEach((m) => {
    if (searchFields(m, searchText)) {
      result.push({
        key: uuidv4(),
        value: m.propertyDisplayName,
        label: m.propertyDisplayName,
      });
    }
  });

  return result;
};

const filterNullOrUndefinedValue = (object) => {
  let result = {};

  if (!isEmpty(object)) {
    Object.entries(object).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        result[key] = value;
      }
      if (value !== null && value !== undefined) {
        result[key] = value;
      }
    });
  }
  return result;
};

const getParamsSyncDataFoodIngredients = (productId, data = {}) => {
  let params = {
    productId,
  };

  if (!isEmpty(data)) {
    const ingredientStatement = data['IngredientStatement'] ?? [];

    params = {
      ...params,
      ingredientStatement,
    };
  }

  return params;
};

const firstText = 'LevelOfContainmentCode';

const getParamsSyncDataAllergen = (productId, data = {}) => {
  let params = {
    productId,
  };

  if (!isEmpty(data)) {
    Object.entries(data).forEach(([key, value]) => {
      if (key?.toLowerCase() === 'LevelOfContainmentCode_Soy'.toLowerCase()) {
        params['soybeans'] = value;
      } else if (
        key?.toLowerCase() === 'LevelOfContainmentCode_Eggs'.toLowerCase()
      ) {
        params['egg'] = value;
      } else {
        ALL_ALLERGENS.forEach((allergen) => {
          if (key?.toLowerCase() === `${firstText}_${allergen}`.toLowerCase()) {
            params[allergen] = value;
          }
        });
      }
    });
  }

  return params;
};

const syncAllergens = [
  'Allergen_Eggs',
  'Allergen_Soy',
  'Allergen_Wheat',
  'Allergen_Milk',
  'Allergen_Fish',
  'Allergen_Crustaceans',
  'Allergen_Sesame',
  'Allergen_Peanuts',
  'Allergen_TreeNuts',
  'Allergen_Shellfish',
  'Allergen_Corn',
  'Allergen_Oats',
  'Allergen_Seeds',
];

const syncCodeAllergens = [
  'LevelOfContainmentCode_Eggs',
  'LevelOfContainmentCode_Soy',
  'LevelOfContainmentCode_Wheat',
  'LevelOfContainmentCode_Milk',
  'LevelOfContainmentCode_Fish',
  'LevelOfContainmentCode_Crustaceans',
  'LevelOfContainmentCode_Sesame',
  'LevelOfContainmentCode_Peanuts',
  'LevelOfContainmentCode_TreeNuts',
  'LevelOfContainmentCode_Shellfish',
  'LevelOfContainmentCode_Corn',
  'LevelOfContainmentCode_Oats',
  'LevelOfContainmentCode_Seeds',
];

const containmentSourceAllergens = [
  'ContainmentSource_Eggs',
  'ContainmentSource_Soy',
  'ContainmentSource_Wheat',
  'ContainmentSource_Milk',
  'ContainmentSource_Fish',
  'ContainmentSource_Crustaceans',
  'ContainmentSource_Sesame',
  'ContainmentSource_Peanuts',
  'ContainmentSource_TreeNuts',
  'ContainmentSource_Shellfish',
  'ContainmentSource_Corn',
  'ContainmentSource_Oats',
  'ContainmentSource_Seeds',
];

const allPropertiesAllergens = [
  ...syncAllergens,
  ...syncCodeAllergens,
  ...containmentSourceAllergens,
];

const getInitialStateAllergens = () => {
  let result = {};

  allPropertiesAllergens.forEach((key) => {
    if (syncAllergens.includes(key)) {
      result = {
        ...result,
        [key]: false,
      };
    } else {
      result = {
        ...result,
        [key]: null,
      };
    }
  });

  return result;
};

const allergen = 'Allergen';
const levelCode = 'LevelOfContainmentCode';

const splitStringByUnderscore = (text) => {
  return text.split('_');
};

const disabledFormSelect = (propertyName, syncData) => {
  if (allPropertiesAllergens.includes(propertyName)) {
    const [, lastText] = splitStringByUnderscore(propertyName);
    const allergenProperty = `${allergen}_${lastText}`;
    const codeProperty = `LevelOfContainmentCode_${lastText}`;

    if (propertyName.includes('LevelOfContainmentCode')) {
      if (syncData[allergenProperty] === false) return true;
      return false;
    }

    if (propertyName.includes('ContainmentSource')) {
      if (
        syncData[allergenProperty] === false ||
        !syncData[codeProperty] ||
        (syncData[codeProperty] !== 'May Contain' && syncData[codeProperty])
      ) {
        return true;
      }
      return false;
    }

    return false;
  }

  return false;
};

const getFormItemRule = (property) => {
  if (property?.propertyName?.includes('ContainmentSource_')) {
    const [, lastText] = splitStringByUnderscore(property?.propertyName);

    const levelOfContainmentKey = `LevelOfContainmentCode_${lastText}`;

    return [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const levelOfContainmentValue = getFieldValue(levelOfContainmentKey);

          if (!value && levelOfContainmentValue === 'May Contain') {
            return Promise.reject(
              new Error('Containment source cannot be empty')
            );
          }
          return Promise.resolve();
        },
      }),
    ];
  }

  if (property?.propertyName?.includes('LevelOfContainmentCode_')) {
    const [, lastText] = splitStringByUnderscore(property?.propertyName);

    const allergenProperty = `Allergen_${lastText}`;

    return [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const allergenValue = getFieldValue(allergenProperty);

          if (!value && allergenValue === true) {
            return Promise.reject(
              new Error('Level of containment cannot be empty')
            );
          }
          return Promise.resolve();
        },
      }),
    ];
  }

  return [];
};

const getNewAllergenForm = (formData) => {
  let result = {};

  if (formData) {
    Object.entries(formData).forEach(([key, value]) => {
      if (syncAllergens.includes(key)) {
        const [, lastText] = splitStringByUnderscore(key);
        const allergenLevelCode = `${levelCode}_${lastText}`;
        if (value === false) {
          result = {
            ...result,
            [allergenLevelCode]: null,
          };
        } else {
          result = {
            ...result,
            [key]: value,
          };
        }
      } else {
        result = {
          ...result,
          [key]: value,
        };
      }
    });
  }

  return result;
};

const getGroupPropertiesHaveData = (dataProperties, properties = []) => {
  let results = [];

  properties.forEach((property) => {
    const value = dataProperties[`${property.propertyName}`];
    const isArrayValue = Array.isArray(value);

    if (isArrayValue) {
      if (value.length > 0) {
        results.push(value);
      }
    } else {
      if (value !== undefined && value !== null && value !== '') {
        results.push(value);
      }
    }
  });

  return results;
};

const checkGroupPropertiesHaveData = (dataProperties, properties = []) => {
  let results = getGroupPropertiesHaveData(dataProperties, properties);

  return results.length > 0;
};

const getNumberOfDisplayedProperties = (dataProperties, properties) => {
  let results = getGroupPropertiesHaveData(dataProperties, properties);

  return results.length;
};

const getTitleGroupName = (groupName, isGroupPropertiesHaveData) => {
  if (isGroupPropertiesHaveData) {
    if (groupName === 'null') return 'Others';
    return groupName;
  }
  return groupName;
};

export {
  formatInfo,
  getInfoProductItem,
  findProductModule,
  findDataProperties,
  findProductPropertyEnums,
  formatRequestParams,
  formatObjectToCheckValueDatetime,
  filterModuleProductSchema,
  getNewPrivateProperties,
  setValueFieldInPrivateProperties,
  formatPrivatePropertiesDatetime,
  filterModuleBrickCode,
  isLeftModule,
  checkRenderTextArea,
  getFieldsSuggestion,
  getFieldsEditedSuggestion,
  getSchemaHaveValues,
  getNewActivePanels,
  filterNullOrUndefinedValue,
  getParamsSyncDataAllergen,
  getInitialStateAllergens,
  disabledFormSelect,
  splitStringByUnderscore,
  getNewAllergenForm,
  getParamsSyncDataFoodIngredients,
  groupProductModuleProperties,
  checkGroupPropertiesHaveData,
  getTitleGroupName,
  getNumberOfDisplayedProperties,
  getFormItemRule,
};
