import React from 'react';
import { useIntl } from 'react-intl';

import RibbonButton from 'common/components/button/RibbonButton';
import { ReactComponent as IconDeleteFolders } from 'common/components/button/svg-icons/delete-folder.svg';

import Messages from 'i18n/messages/home';
import { dialogFunction, CustomNotification } from 'common/components';
import { apiHandler } from 'utils/api';
import { deleteFolders } from 'services/folder';
import folderMessages from 'i18n/messages/folder';

const DeleteFolderDetail = ({
  selectedFolders,
  onReloadWhenSaveSuccess,
  disabled,
}) => {
  const intl = useIntl();

  const handleDeleteFolder = () => {
    const params = {
      deleteFolderIds: [selectedFolders.id],
    };

    apiHandler({
      service: deleteFolders,
      params,
      skipNotification: true,
      failCallback: (message) => {
        CustomNotification.error(message);
      },
      successCallback: () => {
        onReloadWhenSaveSuccess &&
          onReloadWhenSaveSuccess(params.deleteFolderIds, 'delete');
      },
    });
  };

  const showModalConfirm = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(folderMessages.confirmDeleteFolderMessage),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleDeleteFolder(),
    });
  };
  return (
    <>
      <RibbonButton
        size='small'
        icon={<IconDeleteFolders />}
        label={Messages.deleteFolderDetailRibbon}
        onClick={showModalConfirm}
        disabled={disabled}
      />
    </>
  );
};

export default DeleteFolderDetail;
