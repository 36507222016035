import React from 'react';
import { useSelector } from 'react-redux';

import {  StarFilled} from '@ant-design/icons';
import PropTypes from 'prop-types';
import ProductSubscription from 'common/components/thumb/product/ProductSubscription';
import EditableIcon from 'common/components/thumb/product/EditableIcon';

import { Card, Checkbox, Row, Menu, Typography, Divider, Button } from 'antd';

// import TileGridInfo from './items/TileGridInfo';
// import TileGridIdentification from './items/TileGridIdentification';
// import TileGridFooter from './items/TileGridFooter';

import userSelectors from 'redux/user/selectors';

import { formatMDY } from 'utils/formatDate';
import { checkIsOwner } from 'utils/checkIsOwner';
import DigitalRightIcon from 'common/components/digital-right-icon/DigitalRightIcon';
import SharedIcon from 'common/components/thumb/share/SharedIcon';

import './TileGrid.less';

const { Paragraph, Title } = Typography;

/**
 * A component displays product information under card layout
 * @param {object} props
 */
const TileGrid = (props) => {
  const {
    dataDetail,
    selected,
    onClickItemGrid,
    onClickCheckboxItem,
    onDoubleClick,
    tileWidth,
    tileBody,
    disableEvent,
    type,
    shareable,
    isEditable,
  } = props;
  const userId = useSelector(userSelectors.makeSelectUserId());

  const isOwner = checkIsOwner(dataDetail, userId);

  /**
   * public asset when visibility equals public
   * @param {string} visibility
   */
  const publicAsset = (visibility) => {
    if (!visibility) return false;
    return visibility?.toLowerCase() === 'public';
  };

  const menu = () => {
    const nameOfSharer = dataDetail?.companyName || dataDetail?.ownerName;

    return (
      <Menu className='thumbnail-item__dropdown' style={{ padding: '4px' }}>
        <Menu.Item>
          {dataDetail && publicAsset(dataDetail?.visibility) ? (
            <>
              <Title level={5} style={{ fontSize: '13px' }}>
                Public
              </Title>
              <Divider />
              {nameOfSharer && <Paragraph>{nameOfSharer} (Public)</Paragraph>}
            </>
          ) : (
            <>
              <Title level={5} style={{ fontSize: '13px' }}>
                Direct Share
              </Title>
              <Divider />
              {nameOfSharer && <Paragraph>Shared by: {nameOfSharer}</Paragraph>}
              {dataDetail?.dateShared && (
                <Paragraph>
                  Date shared: {formatMDY(dataDetail.dateShared)}
                </Paragraph>
              )}
            </>
          )}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Row
      key={dataDetail.id}
      className={{
        'tile-grid': true,
        'tile-grid--selected': selected,
        'tile-grid--shareable': shareable,
      }}
      style={{ width: tileWidth, position: 'relative' }}
    >
      {!disableEvent && (
        <Checkbox
          className='tile-grid__checkbox'
          checked={selected}
          onChange={(e) => {
            if (onClickCheckboxItem) onClickCheckboxItem(dataDetail, e);
          }}
        />
      )}

      <Card bordered={false}>
        {tileBody({
          dataDetail,
          clickEvents: {
            onClickItemGrid,
            onDoubleClick,
          },
          disableEvent,
        })}
      </Card>

      <div className='tile-grid__float-top-right-icons'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {isEditable && <EditableIcon />}
          {!isOwner && <SharedIcon dataDetail={dataDetail} />}

          {dataDetail?.drm && (
            <DigitalRightIcon
              width={21}
              type={type}
              style={{ borderRadius: 2 }}
            />
          )}
        </div>
      </div>

      {dataDetail?.isFavorited &&
        dataDetail?.type !== 'Product' &&
        dataDetail?.type !== 'member' && (
          <Button className='tile-grid__favorite-btn'>
            <StarFilled style={{ fontSize: '20px', color: '#fec810' }} />
          </Button>
        )}
    </Row>
  );
};

TileGrid.propTypes = {
  /**
   * product data detail
   */
  dataDetail: PropTypes.object,
  selected: PropTypes.bool,
  onClickItemGrid: PropTypes.func,
  onClickCheckboxItem: PropTypes.func,
  onDoubleClick: PropTypes.func,
  tileHeader: PropTypes.func,
  tileBody: PropTypes.func,
  tileFooter: PropTypes.func,
  tileWidth: PropTypes.number,
};

TileGrid.defaultProps = {
  tileWidth: 180,
};

export default TileGrid;
