import React, { useState } from 'react';

import { Form, Row, Col, Space, Progress, Popover, Typography } from 'antd';
import { QuestionCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

import {
  COLOR_PASSWORD,
  STRENGTH_PASSWORD,
  PASS_STRENGTH_RANGE,
  VALIDATION_RULES,
} from 'static/Constants';

import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/user-profile';

const { Text, Paragraph } = Typography;

const NewPasswordFormItem = (props) => {
  const { children, mode, layout, rules, ...restProps } = props;

  const validationRules = VALIDATION_RULES[mode];

  const [passwordScore, setPasswordScore] = useState(0);

  const onChange = (e) => {
    const password = e.target.value;

    validatePassword(password);
  };

  const validatePassword = (password) => {
    setPasswordScore(calculateScore(password));
  };

  const calculateScore = (password) => {
    const lowerRegex = new RegExp('[a-z]');
    const upperRegex = new RegExp('[A-Z]');
    const numberRegex = new RegExp('[0-9]');
    const specialCharRegex = new RegExp('[!@#$%^&*]');

    let score = 0;

    if (password.length < 6) {
      score = password.length * 2;
    }
    if (password.length >= 6) {
      score += 10;
    }
    if (password.length >= 8) {
      score += 10;
    }
    if (lowerRegex.test(password)) {
      score += 15;
    }
    if (upperRegex.test(password)) {
      score += 15;
    }
    if (numberRegex.test(password)) {
      score += 15;
    }
    if (specialCharRegex.test(password)) {
      score += 15;
    }

    return score;
  };

  const validationContent = () => {
    switch (mode) {
      case STRENGTH_PASSWORD.STRONG:
      case STRENGTH_PASSWORD.WEAK:
        return (
          <Row style={{ width: 285 }}>
            <Col span={24}>
              <Paragraph strong={true} style={{ marginBottom: 0 }}>
                <FormattedMessage {...Messages.yourPasswordMust} />
              </Paragraph>
              {validationRules.map((rule, index) => {
                return (
                  <Space key={index}>
                    <CheckCircleOutlined />
                    <Paragraph style={{ marginBottom: 0 }}>{rule}</Paragraph>
                  </Space>
                );
              })}
            </Col>
          </Row>
        );
      case STRENGTH_PASSWORD.MEDIUM:
        return (
          <Row style={{ width: 285 }}>
            <Col span={24}>
              <Paragraph strong={true} style={{ marginBottom: 0 }}>
                {validationRules[0]}
              </Paragraph>
              {[...validationRules].slice(1).map((rule, index) => {
                return (
                  <Space key={index}>
                    <CheckCircleOutlined />
                    <Paragraph style={{ marginBottom: 0 }}>{rule}</Paragraph>
                  </Space>
                );
              })}
            </Col>
          </Row>
        );
      default:
        return <Row></Row>;
    }
  };

  const getValueHByScore = (values) => {
    const scoreRange = Object.keys(values);

    return scoreRange.reduce((accumulator, currentRange) => {
      if (passwordScore >= currentRange) return values[currentRange];

      return accumulator;
    }, null);
  };

  const validatePasswordStrength = () => {
    const strengthRange = Object.values(STRENGTH_PASSWORD);

    const modeLevel = strengthRange.indexOf(mode);
    const passwordStrengthLevel = strengthRange.indexOf(passwordStrength);

    return passwordStrengthLevel >= modeLevel;
  };

  const passwordColor = getValueHByScore(COLOR_PASSWORD);
  const passwordStrength = getValueHByScore(PASS_STRENGTH_RANGE);

  const isMeetRequirement =
    !mode ||
    mode === STRENGTH_PASSWORD.ANY ||
    !passwordScore ||
    validatePasswordStrength();

  const passwordScoreLayout =
    layout === 'vertical'
      ? { span: 24, offset: 0 }
      : { span: layout?.wrapperCol?.span, offset: layout?.labelCol?.span || 0 };

  return (
    <div className='new-password-form-item'>
      <Form.Item
        {...restProps}
        onChange={onChange}
        help={
          isMeetRequirement ? null : (
            <Text
              className='new-password-form-item__strength-error-message'
              type='danger'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FormattedMessage {...Messages.passwordNotComplex} />
              {mode !== STRENGTH_PASSWORD.ANY && (
                <Popover content={validationContent} placement='bottomRight'>
                  <QuestionCircleOutlined
                    style={{
                      color: '#ff4d4f',
                    }}
                  />
                </Popover>
              )}
            </Text>
          )
        }
        validateStatus={isMeetRequirement ? undefined : 'error'}
        rules={[
          {
            validator: (_, value) => {
              return isMeetRequirement
                ? Promise.resolve()
                : Promise.reject('Password is not sufficiently complex!');
            },
          },
          ...rules,
        ]}
      >
        {children}
      </Form.Item>
      {passwordScore ? (
        <Row style={{ marginBottom: 12 }}>
          <Col {...passwordScoreLayout}>
            <Row gutter={12} wrap={false}>
              <Col flex={1}>
                <Progress
                  percent={passwordScore}
                  strokeColor={passwordColor}
                  showInfo={false}
                />
              </Col>
              <Col
                style={{
                  color: passwordColor,
                  textAlign: 'right',
                }}
              >
                {passwordStrength}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default NewPasswordFormItem;
