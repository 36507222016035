import { put, call, takeLatest, all } from 'redux-saga/effects';

import * as types from './constants';
import * as actions from './actions';

import * as services from 'services/assetProduct';

export function* searchItemsLinkAssets(payload) {
  try {
    const params = {
      type: payload.type,
      PageSize: payload.pageSize,
      PageIndex: payload.pageIndex,
      search: payload.search,
    };

    const response = yield call(services.getAssetList, params);
    yield put(
      actions.searchItemsLinkAssetSuccess(
        response.data.gridData,
        response.data.paging.totalRecord
      )
    );
  } catch (error) {
    yield put(actions.searchItemsLinkAssetFail(error));
  }
}

export function* fetchAssetsGrid(payload) {
  try {
    const response = yield call(services.getAssetAssociation, payload.id);
    if (response.isSuccess) {
      yield put(actions.fetchAssetsGridSuccess(response.data));
    } else {
      yield put(actions.fetchAssetsGridFail(response.message));
    }
  } catch (error) {
    yield put(actions.fetchAssetsGridFail(error));
  }
}

export function* updateLinkAsset(action) {
  try {
    const response = yield call(
      services.updateLinkAsset,
      action.payload.params
    );
    if (response.isSuccess) {
      yield put(actions.updateLinkAssetSuccess());
    } else {
      yield put(actions.updateLinkAssetFail(response.message));
    }
  } catch (error) {
    yield put(actions.updateLinkAssetFail(error));
  }
}

function* watchAll() {
  yield all([
    takeLatest(types.SEARCH_ITEMS_LINK_ASSETS, searchItemsLinkAssets),
    takeLatest(types.FETCH_ASSETS_GRID, fetchAssetsGrid),
    takeLatest(types.UPDATE_LINK_ASSET, updateLinkAsset),
  ]);
}

export default watchAll;
