import { put, call, all } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import * as types from './constants';
import * as actions from './actions';
import * as services from 'services/folder';
import * as servicesQuery from 'services/query';
import * as servicesMembers from 'services/members';

export function* getFolderList(payload) {
  try {
    const response = yield call(services.getFolderOwnByUser, payload);
    yield put(actions.getFolderListSuccess(response?.data?.folderList));
  } catch (error) {
    yield put(actions.getFolderListError(error));
  }
}

export function* getFolderQueryList(payload) {
  try {
    const response = yield call(services.getFolderOwnByUser, payload);
    yield put(actions.getFolderQueryListSuccess(response?.data?.folderList));
  } catch (error) {
    yield put(actions.getFolderQueryListError(error));
  }
}

export function* addEntityToFolder(payload) {
  try {
    const response = yield call(services.addEntityToFolder, payload.params);

    if (response.isSuccess || response.Success) {
      yield put(actions.getEntityToFolderSuccess(true));
    } else {
      yield put(
        actions.getEntityToFolderSuccess(response.message || response.Message)
      );
    }
  } catch (error) {
    yield put(actions.getEntityToFolderError(error));
  }
}

export function* getGridQuerySaga(payload) {
  try {
    const response = yield call(servicesQuery.getQueryGridView, payload.params);
    if (response.isSuccess) {
      yield put(actions.getGridQuerySuccess(response.data.gridData));
    } else {
      yield put(actions.getGridQueryError(response.message));
    }
  } catch (error) {
    yield put(actions.getGridQueryError(error));
  }
}

// export function* saveConfigColumnsSaga(action) {
//   try {
//     const { isSuccess, data, message } = yield call(
//       servicesGrid.getDefaultGridConfig,
//       action.gridName
//     );
//     if (isSuccess) {
//       yield put(actions.saveConfigColumnsSuccess(data));
//     }
//     if (!isSuccess && message === 'NotFound') {
//       yield put(actions.saveConfigColumnsSuccess(null));
//     }
//   } catch (error) {
//     yield put(actions.saveConfigColumnsError(error));
//   }
// }

// export function* createGridConfigSaga(action) {
//   try {
//     const { data, isSuccess, message } = yield call(
//       servicesGrid.saveGridConfig,
//       action.params
//     );
//     if (isSuccess) {
//       yield put(actions.createGridConfigSuccess(data.id));
//     } else {
//       yield put(actions.createGridConfigError(message));
//     }
//   } catch (error) {
//     yield put(actions.createGridConfigError(error));
//   }
// }

export function* updateGridViewPaginationSaga({ payload }) {
  yield put(
    actions.updateGridViewPaginationSuccess({
      gridId: payload?.gridId,
      pageSize: payload?.pageSize,
      pageNumber: payload?.pageNumber,
    })
  );
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* watchGridView() {
  yield takeLatest(types.GET_FOLDER_LIST, getFolderList);
  yield takeLatest(types.GET_FOLDER_LIST_QUERY, getFolderQueryList);

  yield takeLatest(types.ADD_ENTITY_TO_FOLDER, addEntityToFolder);
  yield takeLatest(types.GET_GRID_QUERY, getGridQuerySaga);
  // yield takeLatest(types.SAVE_CONFIG_COLUMNS, saveConfigColumnsSaga);
  // yield takeLatest(types.CREATE_GRID_CONFIG, createGridConfigSaga);
  yield takeLatest(
    types.UPDATE_GRID_VIEW_PAGINATION,
    updateGridViewPaginationSaga
  );
}
