import React from 'react';
import { Row, Col, Typography, Space, Avatar } from 'antd';
import { Images } from 'config/assets';
import { formatMDY } from 'utils/formatDate';
import './ItemFolder.less';

const ItemFolder = (props) => {
  const { item, selected, onClickItemFolder } = props;
  const image = !item?.isOwner
    ? Images.sharedFolder
    : (item && item.assetCount) > 0 || (item && item.productCount) > 0
    ? Images.folder
    : Images.emptyFolder;

  return (
    <Row
      className={selected ? 'item-folder item-folder--selected' : 'item-folder'}
      onClick={(e) => {
        if (typeof onClickItemFolder === 'function') onClickItemFolder(item, e);
      }}
    >
      <Col className='item-folder__card'>
        <Space align='center'>
          <Avatar className='item-folder__image' shape='square' src={image} />
          <Row className='item-folder__body'>
            <Col xs={24}>
              <Typography.Text strong ellipsis className='item-folder__title'>
                {item?.folderName}
              </Typography.Text>
            </Col>
            <Col xs={24}>
              {item?.lastModified ? (
                <Typography.Text ellipsis className='item-folder__date'>
                  Date modified: {formatMDY(item?.lastModified)}
                </Typography.Text>
              ) : (
                <Typography.Text ellipsis className='item-folder__date'>
                  Date created: {formatMDY(item?.dateCreated)}
                </Typography.Text>
              )}
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default React.memo(ItemFolder);
