import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAplDetail = (state) => state.aplDetail || initialState;

const makeSelectLoading = () =>
  createSelector(selectAplDetail, (selectAplDetail) => selectAplDetail.loading);
const makeSelectError = () =>
  createSelector(selectAplDetail, (selectAplDetail) => selectAplDetail.error);
const makeSelectAplDetail = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.aplDetail
  );
const makeSelectAplDetailFilter = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.aplDetailFilter
  );
const makeSelectColumnProductList = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.columnProductList
  );
const makeSelectAplProductListSelect = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.aplProductListSelect
  );

const makeSelectAplProductDetailListSelect = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.aplProductDetailListSelect
  );

const makeSelectAplDetailSearchText = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.aplDetailSearchText
  );

const makeSelectReloadGrid = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.reloadGrid
  );
const makeSelectAplCommentList = () =>
  createSelector(
    selectAplDetail,
    (selectAplDetail) => selectAplDetail.aplCommentList
  );
export {
  selectAplDetail,
  makeSelectLoading,
  makeSelectError,
  makeSelectAplDetail,
  makeSelectAplDetailFilter,
  makeSelectColumnProductList,
  makeSelectAplProductListSelect,
  makeSelectAplDetailSearchText,
  makeSelectReloadGrid,
  makeSelectAplCommentList,
  makeSelectAplProductDetailListSelect,
};
