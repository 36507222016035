import React, { useRef, useState, useEffect } from 'react';
import { ReassignAssetToMemberModal } from 'common/components';
import { useSelector } from 'react-redux';

import { DoubleRightOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import gridSelector from 'common/components/grid-view/controllers/selectors';

import selectorUser from 'redux/user/selectors';

import * as assetFullSelectors from 'pages/asset-full-view/controllers/selectors';

import * as memberServices from 'services/members';

import { checkIsSuperAdmin } from 'utils';

const defaultSearchParams = {
  searchText: '',
};

const MoveAssetsToMember = (props) => {
  const { disabled, viewType } = props;

  const [isSatisfyMoveRequirement, setIsSatisfyMoveRequirement] =
    useState(false);

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);

  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const assetData = useSelector(assetFullSelectors.assetDataLoading());

  const refSearchGridItemModal = useRef();

  const openModalMoveAssetToMember = () => {
    refSearchGridItemModal.current.openModal();
  };

  useEffect(() => {
    //* check if item list meet condition to be moved to new member
    const checkMoveProductRequirement = () => {
      if (!selectedItemDetailList?.length && !assetData) {
        setIsSatisfyMoveRequirement(false);
        return;
      }

      if (userInfo?.isSuperMember || isSuperAdmin) {
        setIsSatisfyMoveRequirement(true);
      } else {
        setIsSatisfyMoveRequirement(false);
      }
    };

    checkMoveProductRequirement();
  }, [selectedItemDetailList, userInfo, isSuperAdmin, assetData]);

  const assetList =
    selectedItemDetailList?.length > 0
      ? selectedItemDetailList
      : assetData
      ? [assetData]
      : [];

  return (
    <>
      <ButtonSmallIcon
        className='button-item-dropdown'
        icon={<DoubleRightOutlined />}
        textDisplay={
          viewType === 'assetFullView'
            ? 'Move Asset to Member'
            : 'Move Assets to member'
        }
        onClick={openModalMoveAssetToMember}
        disabled={!isSatisfyMoveRequirement || disabled}
      />
      <ReassignAssetToMemberModal
        title='Assign Asset(s) to Member'
        ref={refSearchGridItemModal}
        isMultiple={false}
        service={memberServices.getAllMemberForReassign}
        dataPath='data.allMembers'
        params={defaultSearchParams}
        mapSearch={(searchText) => ({ searchText })}
        tagRender={{ value: (itemData) => itemData?.companyName }}
        itemIdName='companyId'
        assetList={assetList}
        memberId={userInfo?.member?.id}
      />
    </>
  );
};

MoveAssetsToMember.propTypes = {};

export default MoveAssetsToMember;
