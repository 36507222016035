import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Row, Form, Input, Spin, notification } from 'antd';

import AgGrid from 'common/components/ag-grid/AgGrid';

import * as selectorGlobal from 'redux/global/selectors';

import { filterColumnsGrid } from 'common/components/modal-share/utils';
import { useMutation } from 'hooks/useMutation';

import * as endpointsManageSharing from 'services/manageSharing/endpoints';
import { shareDataApi } from 'services/manageSharing';

import { FormattedMessage, useIntl } from 'react-intl';
import homeMessages from 'i18n/messages/home';

const ShareQueryModal = (props) => {
  const intl = useIntl();

  const FIELD_USER_LIST = ['fullName', 'memberName'];

  const { data, visible, setVisible } = props;

  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');

  const columnsUser = filterColumnsGrid(
    useSelector(selectorGlobal.selectColumnsUser()),
    FIELD_USER_LIST
  ).map((ele) => ({ ...ele, suppressMenu: true }));

  const [{ status: statusShare, error: errorShare }, shareConfig] =
    useMutation(shareDataApi);

  const handleCallbackDataListSelection = (dataList) => {
    setUsers(dataList);
  };

  const handleShareConfig = () => {
    const params = {
      selectedEntities: [{ id: data?.id, type: data?.type }],
      sharedUserIdList: users,
      isShareable: data?.shareable,
    };

    shareConfig(params);
    return;
  };

  useEffect(() => {
    if (statusShare === 'success') {
      notification.success({
        message: intl.formatMessage(homeMessages.configShareSuccess),
      });
      setVisible(false);
    }

    if (statusShare === 'error') {
      notification.error({ message: errorShare });
    }
  }, [errorShare, intl, statusShare]);

  const requestParams = {
    search: { searchText },
    shareId: data?.id,
    shareType: data?.type,
  };

  return (
    <Modal
      title={<FormattedMessage {...homeMessages.configShareQuery} />}
      width={'50vw'}
      bodyStyle={{ height: '60vh' }}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleShareConfig}
      okText={<FormattedMessage {...homeMessages.share} />}
      okButtonProps={{
        disabled: users.length === 0,
        loading: statusShare === 'loading',
      }}
      destroyOnClose
    >
      {visible && (
        <>
          <Row>
            <Form.Item>
              <Input.Search
                placeholder={intl.formatMessage(
                  homeMessages.configInputSearchPlaceholder
                )}
                onSearch={(value) => setSearchText(value)}
                style={{ minWidth: 500 }}
              />
            </Form.Item>
          </Row>
          <Spin
            spinning={statusShare === 'loading'}
            tip={<FormattedMessage {...homeMessages.configShared} />}
            size='large'
          >
            <Row style={{ height: 'calc(70vh - 160px)' }}>
              <AgGrid
                columnDefs={columnsUser}
                urlGrid={endpointsManageSharing.GET_AVAILABLE_USERS_FOR_SHARING}
                urlGridDistinct={
                  endpointsManageSharing.GET_AVAILABLE_USERS_FOR_SHARING_DISTINCT
                }
                callbackDataListSelection={handleCallbackDataListSelection}
                responseParams='data'
                requestParams={requestParams}
              />
            </Row>
          </Spin>
        </>
      )}
    </Modal>
  );
};

export default ShareQueryModal;
