import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThumbnailItem } from 'common/components';
import { Row } from 'antd';
import PreviewableModal from '../previewable-modal/PreviewableModal';
import classNames from 'classnames';
import './PreviewableThumb.less';

const PreviewableThumb = (props) => {
  const {
    id,
    img,
    title,
    isDocument,
    type,
    link,
    responsive,
    fileName,
    isCarousel,
    previewList,
    hideLabel,
    downloadLink,
    isMarketing = false,
    isSetPrimary = false,
    isLoadingBtnPrimary = false,
    handlePrimaryProduct,
  } = props;

  const [visible, setVisible] = useState(false);
  const className = classNames({
    'document-preview': isDocument,
    'asset-preview': !isDocument,
  });

  const onClick = () => {
    setVisible(true);
  };

  return (
    <>
      {visible && (
        <PreviewableModal
          id={id}
          type={type}
          isDocument={isDocument}
          link={link}
          title={title}
          visible={visible}
          fileName={fileName}
          cancelModal={() => setVisible(false)}
          isCarousel={isCarousel}
          previewList={previewList}
          downloadLink={downloadLink}
        />
      )}
      {isMarketing ? (
        <ThumbnailItem
          labelThumbnail={title}
          imageThumbnail={img}
          hideCheckbox
          hideLabel={hideLabel}
          responsive={responsive}
          isMarketing={isMarketing}
          isSetPrimary={isSetPrimary}
          isLoadingBtnPrimary={isLoadingBtnPrimary}
          handlePrimaryProduct={handlePrimaryProduct}
          onClickItemGrid={onClick}
        />
      ) : (
        <Row
          style={{ float: 'left' }}
          className={className}
          title='Click to preview'
        >
          <ThumbnailItem
            labelThumbnail={title}
            imageThumbnail={img}
            onClickItemGrid={onClick}
            hideCheckbox
            hideLabel={hideLabel}
            responsive={responsive}
          />
        </Row>
      )}
    </>
  );
};

PreviewableThumb.propTypes = {
  isDocument: PropTypes.bool,
  img: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
  classNameProps: PropTypes.string,
};

export default PreviewableThumb;
