import React from 'react';
import { Typography, Space, Row, Tag } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EntityStatusTag from 'common/components/tag/entity-status-tag/EntityStatusTag';
import Messages from 'i18n/messages/folder';
import { formatSizeUnits } from 'utils/formatSizeUnits';
// import { aDetailsLink } from 'utils/common/linkBuilder';

const { Paragraph, Title, Text } = Typography;

const ReportingTileBody = (props) => {
  const { dataDetail } = props;

  const name = get(dataDetail, 'name', '');
  const ownerName = get(dataDetail, 'ownerFullName', '');

  const infoText = [
    {
      field: 'name',
      value: name,
    },
    {
      field: 'ownerName',
      value: ownerName,
    },
  ];
  // const detailLink = aDetailsLink(
  //   'reporting',
  //   dataDetail?.id,
  //   dataDetail?.name
  // );
  const renderReportingBodyInfo = (infoText) => {
    return infoText.map(({ field, value }, index) => {
      if (field === 'name') {
        return (
          <Title key={index} className='reporting-tile__body--title'>
            {dataDetail?.name}
          </Title>
        );
      } else if (field === 'folderSize') {
        return (
          <Paragraph
            key={field}
            className={`reporting-tile__body--element ${field}`}
          >
            {formatSizeUnits(value)}
          </Paragraph>
        );
      } else if (field === 'productCount' || field === 'assetCount') {
        let text;
        if (field === 'productCount') {
          text =
            value > 0 ? (
              <FormattedMessage {...Messages.products} />
            ) : (
              <FormattedMessage {...Messages.product} />
            );
        } else {
          text =
            value > 0 ? (
              <FormattedMessage {...Messages.assets} />
            ) : (
              <FormattedMessage {...Messages.asset} />
            );
        }
        return value > 0 ? (
          <Paragraph
            key={field}
            className={`reporting-tile__body--element ${field}`}
          >
            {value}&nbsp;{text}
          </Paragraph>
        ) : null;
      } else {
        return (
          value && (
            <Paragraph
              key={field}
              className={`reporting-tile__body--element ${field}`}
            >
              {value}
            </Paragraph>
          )
        );
      }
    });
  };

  return (
    <div className='reporting-tile__body'>
      <Row justify='center' className='reporting-tile__body--info-row'>
        <Space direction='vertical' size={0}>
          {renderReportingBodyInfo(infoText)}
        </Space>
      </Row>
      <Row justify='center' className='reporting-tile__body--status-row'>
        <EntityStatusTag
          status={dataDetail?.status}
          position='static'
          exceptions={['Active']}
        />
      </Row>
    </div>
  );
};

ReportingTileBody.propTypes = {
  dataDetail: PropTypes.object,
};

export default ReportingTileBody;
