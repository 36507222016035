import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Space, Col, Row, Empty, Button, message, Typography } from 'antd';
import {
  StyledModal,
  CustomNotification,
  WithLoading,
} from 'common/components';

import { sendGetAvailEntityDateField } from 'services/templateMarkdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as globalActions from 'redux/global/actions';

import './MdEntityTool.less';

const { Text } = Typography;

const MdEntityTool = (props) => {
  const {
    entityName,
    setEntityOpen,
    entityOpen,
    isEntityInsideTable,
    mdEditor,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [entityPropertyList, setEntityPropertyList] = useState([]);

  const dispatch = useDispatch();

  const fetchAllAvailEntityFields = useCallback(async () => {
    setIsLoading(true);
    let response;
    try {
      response = await sendGetAvailEntityDateField();
    } catch (err) {}
    setIsLoading(false);

    if (response?.isSuccess && entityName) {
      setEntityPropertyList(
        response.data.availableEntityDataFields.filter(
          (fieldItem) => fieldItem?.entityType === entityName
        )
      );
    }
  }, [entityName]);

  useEffect(() => {
    if (!entityOpen) return;
    fetchAllAvailEntityFields();
  }, [entityOpen, fetchAllAvailEntityFields]);

  const hanldeMessageCopySyntax = () => {
    message.success({
      content: <Text>Copied Successfully</Text>,
    });
  };

  const handleInsertEntityData = (fieldItem) => {
    const insertContent = `[[${fieldItem?.entityType}.${fieldItem?.fieldName}]]`;
    if (!isEntityInsideTable) {
      if (!mdEditor) return;
      mdEditor.doc.replaceSelection(insertContent);
      CustomNotification.success('Insert Successfully!');
    } else {
      dispatch(
        globalActions.updateMdTableAction({
          type: 'INSERT',
          payload: insertContent,
        })
      );
    }
    setEntityOpen(false);
  };

  return (
    <StyledModal
      className='md-entity-tool-list'
      title={`${entityName}'s Entity Properties`}
      visible={entityOpen}
      onCancel={() => {
        setEntityOpen && setEntityOpen(false);
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={'Cancel'}
      maskClosable={false}
      width={650}
      height={500}
    >
      <WithLoading loading={isLoading}>
        {entityPropertyList && entityPropertyList.length > 0 ? (
          <div className='md-table-list'>
            <Row className='md-table-list__header'>
              <Col xs={10}> Display Name</Col>
              <Col xs={9}> Field</Col>
              <Col flex='auto'>Control</Col>
            </Row>
            <React.Fragment>
              {entityPropertyList.map((entityField, idx) => (
                <Row className='md-table-list__item' key={'field-item-' + idx}>
                  <Col xs={10}>{entityField?.displayName}</Col>
                  <Col xs={9}>{entityField?.fieldName}</Col>
                  <Col>
                    <Space>
                      <Button
                        type='primary'
                        onClick={() => handleInsertEntityData(entityField)}
                      >
                        Insert
                      </Button>

                      <CopyToClipboard
                        text={`[[${entityField?.entityType}.${entityField?.fieldName}]]`}
                        onCopy={hanldeMessageCopySyntax}
                      >
                        <Button danger>Copy</Button>
                      </CopyToClipboard>
                    </Space>
                  </Col>
                </Row>
              ))}
            </React.Fragment>
          </div>
        ) : (
          <Empty className='md-entity-tool-list__empty' />
        )}
      </WithLoading>
    </StyledModal>
  );
};

MdEntityTool.propTypes = {
  entityName: PropTypes.oneOf(['Member', 'User']),
};

export default MdEntityTool;
