import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { HomeOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

const ShowOverview = ({ handleClick }) => {
  return (
    <RibbonButton
      icon={<HomeOutlined style={{ height: 26 }} />}
      label={Messages.overviewLayout}
      onClick={handleClick}
    />
  );
};

ShowOverview.propTypes = { handleClick: PropTypes.func };

export default ShowOverview;
