import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
const InfoWindow = ({ place, onCloseInfoWindow }) => {
  return (
    <div
      style={{
        position: 'relative',
        bottom: 70,
        left: '-115px',
        width: 250,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
      }}>
      <div style={{ fontSize: 16 }}>
        {place.firstName + ' ' + place.lastName}
      </div>
      {/* <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>{place.rating}</span>
        <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
        </span>
      </div> */}
      <div style={{ fontSize: 14, color: 'lightgrey' }}>{place?.type}</div>
      {/* <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(place.price_level)}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div> */}
      <Button
        onClick={onCloseInfoWindow}
        type='text'
        style={{ position: 'absolute', right: 0, top: 0 }}
        icon={<CloseOutlined />}></Button>
    </div>
  );
};

export default InfoWindow;
