import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SYNDICATION_METHOD } from 'static/Constants';

import * as mappingSelectors from 'pages/mapping/controllers/selectors';
import * as mappingActions from 'pages/mapping/controllers/actions';
import { getMappingConfigService } from 'services/mapping';
import { getUserSourceGLN } from 'services/product';

import { useAsync } from 'hooks';

export const useColumnsMappingGrid = () => {
  const dispatch = useDispatch();
  const columns = useSelector(mappingSelectors.selectColumns());

  useEffect(() => {
    if (columns.length === 0) {
      dispatch(mappingActions.getMappingGridColumns('mapping-grid'));
    }
    return;
  }, [columns.length, dispatch]);

  return {
    columns,
  };
};

export const useGetMappingConfig = ({ mappingId, isEnabled }) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(getMappingConfigService({ mappingId }));
    }
    return;
  }, [isEnabled, mappingId, run]);

  return {
    data,
  };
};

export const useGetAllMemberGlns = () => {
  const { data, run } = useAsync();

  useEffect(() => {
    run(getUserSourceGLN());
  }, [run]);

  return {
    sourceGlns: data?.memberGLNs ?? [],
  };
};

export const mappingColumns = [
  {
    checkboxSelection: true,
    field: '',
    filter: false,
    resizable: true,
    suppressMenu: true,
  },
  {
    allowFilter: false,
    allowSort: false,
    dataType: 'string',
    displayName: 'Mapping Name',
    fieldName: 'MappingName',
    fieldNameCamelCase: 'mappingName',
    minWidth: 200,
    resizable: true,
  },
  {
    allowSort: false,
    dataType: 'string',
    displayName: 'Mapping Description',
    fieldName: 'MappingDescription',
    fieldNameCamelCase: 'mappingDescription',
    resizable: true,
    flex: 1,
    width: 200,
  },
];

export const checkDisabledMethod = (value, methods) => {
  return (
    value === SYNDICATION_METHOD.enableRivirPull &&
    methods.includes(SYNDICATION_METHOD.enableGDSN)
  );
};

export const checkRenderMethod = (value, isRivirGdsnParty) => {
  if (value === SYNDICATION_METHOD.enableGDSN && !isRivirGdsnParty)
    return false;

  return true;
};

export const getSyncParams = (methods) => {
  let syncParams = {};

  for (const [key, value] of Object.entries(SYNDICATION_METHOD)) {
    if (methods.includes(value)) {
      syncParams = {
        ...syncParams,
        [key]: true,
      };
    } else {
      syncParams = {
        ...syncParams,
        [key]: false,
      };
    }
  }
  return syncParams;
};

export const checkDisabledSubmitModal = (methods, mapping) => {
  if (methods.length === 0) return true;

  if (methods.includes(SYNDICATION_METHOD.enableRivirPull) && !mapping)
    return true;

  return false;
};

export const getMethodsSubscription = (subscription) => {
  let methods = [];
  if (subscription) {
    const { enableRivirPull, enableRivirAlert, enableGDSN } = subscription;

    if (enableRivirPull) {
      methods.push(SYNDICATION_METHOD.enableRivirPull);
    }
    if (enableRivirAlert) {
      methods.push(SYNDICATION_METHOD.enableRivirAlert);
    }
    // if (enableGDSN) {
    //   methods.push(SYNDICATION_METHOD.enableGDSN);
    // }
  }
  return methods;
};
