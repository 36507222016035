import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StyledModal, FolderSelector } from 'common/components';
import AddToFolderActions from 'common/components/grid-view/components/add-to-folder/AddToFolderActions';

import * as selectorsGlobal from 'redux/global/selectors';
import * as actionGlobal from 'redux/global/actions';

import { useAddToFolder } from 'hooks/useAddToFolder';

import './ModalAddToFolder.less';

const ModalAddToFolder = (props) => {
  const dispatch = useDispatch();

  const { dataItem, dataType, hasPermissionAddToFolder } = props;

  const showAddToFolder = useSelector(selectorsGlobal.selectShowAddToFolder());

  const {
    folderSelectorRef,
    onSelectFolder,
    onBreadcrumbChange,
    onExpandFolder,
    handleSubmit,
    handleReloadFolderList,
    selectedFolder,
    currentFolderId,
    addToFolderLoading,
  } = useAddToFolder({
    shouldShowConfirm: false, //* hide confirmation in detail, because of missing name
    close: () => {
      closeModal();
    },
  });

  const closeModal = () => {
    dispatch(actionGlobal.toggleAddToFolder());
  };

  const handleSuccessCallback = (folder) => {
    handleSubmit([{ id: dataItem?.id, type: dataType }], folder);
  };

  const folderIdToAdd = selectedFolder?.id || currentFolderId;

  const selectedFolderId =
    selectedFolder?.id !== 'home' ? selectedFolder?.id : null;

  return (
    <StyledModal
      title={'Add to Folder'}
      visible={showAddToFolder}
      bodyStyle={{ height: '70vh' }}
      maskClosable={false}
      destroyOnClose
      onCancel={closeModal}
      footer={
        <AddToFolderActions
          loading={addToFolderLoading}
          parentId={selectedFolderId || currentFolderId}
          currentFolderId={currentFolderId}
          disableSubmitButton={!folderIdToAdd || !hasPermissionAddToFolder}
          onCancel={closeModal}
          onSubmit={() =>
            handleSubmit([{ id: dataItem?.id, type: dataType }], selectedFolder)
          }
          successCallback={handleSuccessCallback}
        />
      }
    >
      <FolderSelector
        ref={folderSelectorRef}
        onSelectFolder={onSelectFolder}
        onBreadcrumbChange={onBreadcrumbChange}
        onExpandFolder={onExpandFolder}
      />
    </StyledModal>
  );
};
export default ModalAddToFolder;
