import React from 'react';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import ShareControl from '../../controls/shared/ShareControl';
import EmailControl from '../../controls/shared/EmailControl';

const CategoryShare = () => {
  return (
    <ContainerButton>
      <ItemButton>
        <ShareControl />
      </ItemButton>
      <ItemButton>
        <EmailControl />
      </ItemButton>
    </ContainerButton>
  );
};

export default CategoryShare;
