import React, { useMemo } from 'react';

import { Row, Col, Typography, Tooltip } from 'antd';
import {
  CloseCircleOutlined,
  WarningOutlined,
  CaretRightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { Form, FormDeleteButton } from 'common/components';

import InstantFixForm from './InstantFixForm';
import InstantFixFormRivirField from './InstantFixFormRivirField';

import classnames from 'classnames';
import { startCase } from 'lodash';
import { ROUTE } from 'static/Constants';

const { Title } = Typography;

/**
 * ? COMPONENT - error item for validation rule
 * @param {*} props
 * @returns
 */
export const PublicationErrorItemValidationRule = (props) => {
  const { productItemId } = props;

  const [formInstance] = Form.useForm();

  const handleOpenProductNewTab = () => {
    if (productItemId) {
      const url = window.location.origin + ROUTE.PRODUCT + `/${productItemId}`;
      productItemId && window.open(url);
    }
  };
  return (
    <Form form={formInstance} id='form-group'>
      <Row
        className={classnames(
          'publication-error-list__group-item',
          'publication-error-list__group-item--warning'
        )}
        align='middle'
      >
        <Col>
          <div className='publication-error-list__group-item-icon'>
            <WarningOutlined />
          </div>
        </Col>
        <Col>
          <Title className='publication-error-list__group-item-title' level={5}>
            Failed GS1 validation
          </Title>
        </Col>

        <Col style={{ flex: 1 }} />
        <Col>
          <FormDeleteButton
            text='Open product'
            icon={<CaretRightOutlined />}
            onClick={handleOpenProductNewTab}
            style={{ marginRight: 5 }}
          />
        </Col>
      </Row>
    </Form>
  );
};

/**
 * ? COMPONENT - error item for system required and additional required
 * @param {*} props
 * @returns
 */

const getNestedFieldName = (fieldNames = []) => {
  let result = '';

  fieldNames.forEach((name, index) => {
    console.log(startCase(name), index);
    if (index === 0) {
      result = startCase(name);
    } else {
      result = result + ' / ' + startCase(name);
    }
  });

  return result;
};

export const PublicationCommonErrorItem = (props) => {
  const {
    errorItem,
    isErrorItem,
    isRivirField,
    status,
    setErrorIndex,
    setProductId,
    toggleCheckErrorModal,
    productEnums,
    productSchema,
    errorInfo,
    groupIdx,
    itemIdx,
    productDetailList,
    productModuleList,
  } = props;

  const productDetail = useMemo(() => {
    return (
      productDetailList &&
      productDetailList.find(
        (detail) => detail?.productId === errorItem?.productId
      )
    );
  }, [productDetailList, errorItem?.productId]);

  const errorTitle = useMemo(() => {
    const productIdText = productDetail?.gtin
      ? `Product GTIN - ${productDetail?.gtin}`
      : productDetail?.upc12
      ? `Product UPC12 - ${productDetail?.upc12}`
      : `Product ID - ${errorItem?.productId}`;

    const productIdPart = (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={
          window.location.origin + ROUTE.PRODUCT + `/${errorItem?.productId}`
        }
      >
        {productIdText}
      </a>
    );

    const rivirFieldText = 'RIVIR Field';
    const fieldNameText = startCase(errorItem?.fieldName || '');

    if (isRivirField) {
      return {
        renderTitle: (
          <>
            {productIdPart} &nbsp;/&nbsp; {rivirFieldText}&nbsp;/&nbsp;{' '}
            {fieldNameText}
          </>
        ),
      };
    } else {
      const [moduleName, ...fieldName] = errorItem.fieldName.split('.');
      const nestedFieldNameText = getNestedFieldName(fieldName);

      return {
        renderTitle: (
          <>
            {productIdPart} &nbsp;/&nbsp; {startCase(moduleName)} &nbsp;/&nbsp;{' '}
            {nestedFieldNameText}
          </>
        ),
      };
    }
  }, [productDetail, errorItem, isRivirField]);

  return (
    <Row
      className={classnames('publication-error-list__group-item', {
        'publication-error-list__group-item--warning': !errorItem?.productId,
        'publication-error-list__group-item--error': errorItem?.productId,
      })}
      align='middle'
    >
      <Col style={{ flex: 1 }}>
        <Row align='middle' style={{ marginBottom: 8 }}>
          <Col>
            <div className='publication-error-list__group-item-icon'>
              {isErrorItem ? <CloseCircleOutlined /> : <WarningOutlined />}
            </div>
          </Col>
          <Col style={{ width: 'calc(100% - 150px)' }}>
            <Tooltip
              title={errorTitle?.tooltipText}
              overlayInnerStyle={{ whiteSpace: 'nowrap' }}
            >
              <Title
                className='publication-error-list__group-item-title'
                level={5}
              >
                {errorTitle?.renderTitle}
              </Title>
            </Tooltip>
          </Col>
          <Col>
            {status === 'loading' && (
              <LoadingOutlined
                style={{
                  fontSize: 15,
                  color: '#096dd9',
                  padding: '0 10px',
                }}
              />
            )}
          </Col>
          <Col flex='auto' />

          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormDeleteButton
              text='Detail'
              icon={<CaretRightOutlined />}
              onClick={() => {
                const activeProductId = errorItem?.productId;
                setErrorIndex(errorInfo?.errorProductId);
                setProductId(activeProductId);
                toggleCheckErrorModal(activeProductId, errorInfo);
              }}
              disabled={isRivirField}
              style={{ marginRight: 5 }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {isErrorItem && !isRivirField && (
              <InstantFixForm
                errorInfo={errorInfo}
                productId={errorItem?.productId}
                productBrickCode={errorItem?.brickCode || '10000664'}
                productEnums={productEnums}
                productSchema={productSchema}
                productItemModules={
                  productModuleList &&
                  productModuleList.find(
                    (modules) => modules?.productId === errorItem?.productId
                  )
                }
                // formName={`form-${groupIdx}-${itemIdx}`}
              />
            )}
            {isErrorItem && isRivirField && (
              <InstantFixFormRivirField
                errorInfo={errorInfo}
                productId={errorItem?.productId}
                productEnums={productEnums}
                productDetail={productDetail}
                formName={`form-${groupIdx}-${itemIdx}`}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
