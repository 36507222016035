import React from 'react';

import classnames from 'classnames';

import MinimumRequirementsTitle from './MinimumRequirementsTitle';

import './MinimumRequirements.less';

const MinimumRequirementsWrapperContent = ({ className, children, title }) => {
  return (
    <div
      className={classnames({
        'minimum-requirement__wrapper': true,
        [className]: !!className,
      })}
    >
      <MinimumRequirementsTitle title={title} />
      <div className='minimum-requirement__content'>{children}</div>
    </div>
  );
};

export default MinimumRequirementsWrapperContent;
