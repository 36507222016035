import { Input, Radio, notification } from 'antd';
import Messages from 'i18n/messages/product';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useGetProductFullView } from 'hooks';

import { Form } from 'common/components';
import * as memberServices from 'services/members';
import * as productServices from 'services/product';

//* QUERIES
import { useQueryProductCompanyInfo } from 'common/queries/product-module';

import * as _ from 'lodash';

const layout = {
  labelCol: { xl: 9, xxl: 8 },
  wrapperCol: { xl: 15, xxl: 16 },
};

const ProductBrandEditForm = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    form,
    onCancel,
    setSubmitLoading,
    fileForm,
    productBrandMode,
    isAddProduct,
    isEdit,
    setBrandId,
    setProductBrandMode,
    newBrandLogoId,
    memberId,
    setBrandThumb,
    uploadThumb,
    setShowRequireLogoMessage,
    editCallback,
    productId: productIdProp,
  } = props;

  const { productFull } = useGetProductFullView({ productId: productIdProp });

  const { data: companyInfoState, refetch: refetchProductCompanyInfo } =
    useQueryProductCompanyInfo({
      productId: +productIdProp,
    });

  useEffect(() => {
    if (productBrandMode === 'EditCurrentBrand') {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        brandName: companyInfoState?.brandImage?.brandName,
      });
    } else if (productBrandMode === 'CreateNewBrand') {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        brandName: '',
      });
    }
  }, [productBrandMode]);

  const onFinish = (values) => {
    if (isEdit) {
      callApiEditBrandInfo(values);
      return;
    }
    //* when creating new product
    callApiCreateNewBrand(values);
  };

  const callApiEditBrandInfo = (values) => {
    if (productBrandMode === 'CreateNewBrand' && !fileForm) {
      setShowRequireLogoMessage(true);
      return;
    }
    const imageFile = fileForm?.imageFile;
    const formData = new FormData();

    // Will support getting productIdProp
    if (productIdProp) {
      formData.append('ProductId', productIdProp);
    } else {
      productFull?.productId &&
        formData.append('ProductId', productFull?.productId);
    }

    values?.brandName && formData.append('BrandName', values?.brandName);
    values?.editBrandMode &&
      formData.append('EditBrandMode', values?.editBrandMode);
    imageFile && formData.append('File', imageFile);
    setSubmitLoading(true);
    productServices
      .editBrandImageByProduct(formData)
      .then((response) => {
        const { isSuccess, message, data } = response;

        if (isSuccess) {
          notification.success({
            message: intl.formatMessage(Messages.editProductBrandSuccess),
          });
          if (isAddProduct || isEdit) {
            setBrandId && setBrandId(data?.brandId);
          }
          //* update product brand thumb by updated image after linking to new brand
          if (productBrandMode === 'CreateNewBrand') {
            setBrandThumb && uploadThumb && setBrandThumb(uploadThumb);
          }

          refetchProductCompanyInfo();
          editCallback && editCallback();

          onCancel();
        } else {
          editBrandHandler(message);
        }
      })
      .catch(() => {
        editBrandHandler();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const callApiCreateNewBrand = (values) => {
    if (productBrandMode === 'CreateNewBrand' && !newBrandLogoId) {
      setShowRequireLogoMessage(true);
      return;
    }
    const params = { ...values, id: newBrandLogoId, memberId };
    setSubmitLoading(true);
    memberServices
      .createBrandInfo(params)
      .then((response) => {
        const { isSuccess, data, message } = response;
        if (isSuccess) {
          setBrandId && setBrandId(data?.id);
          setBrandThumb && setBrandThumb(uploadThumb);

          onCancel();
        } else {
          createBrandErrorHandler(message);
        }
      })
      .catch((e) => {
        createBrandErrorHandler();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const createBrandErrorHandler = (message) => {
    notification.error({
      message: message || intl.formatMessage(Messages.createBrandError),
    });
  };

  const editBrandHandler = (message) => {
    notification.error({
      message: message || intl.formatMessage(Messages.serverError),
    });
  };

  const handleChangeEditMode = (value) => {
    setProductBrandMode(value);
  };

  return (
    <Form
      id='product-brand-edit-form'
      name='product-brand-edit-form'
      form={form}
      {...layout}
      onFinish={onFinish}
      initialValues={{ editBrandMode: productBrandMode }}
    >
      <Form.Item
        name='editBrandMode'
        label={<FormattedMessage {...Messages.editBrandMode} />}
        required
      >
        <Radio.Group
          value={productBrandMode}
          onChange={(e) => handleChangeEditMode(e.target.value)}
        >
          <Radio value={'EditCurrentBrand'} disabled={!productFull?.brandId}>
            <FormattedMessage {...Messages.currentBrand} />
          </Radio>
          <Radio value={'CreateNewBrand'}>
            <FormattedMessage {...Messages.newBrand} />
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name='brandName'
        label={<FormattedMessage {...Messages.brandName} />}
        rules={[
          {
            required: true,
            message: intl.formatMessage(Messages.brandNameIsRequired),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

ProductBrandEditForm.propTypes = {
  value: PropTypes.string,
};

export default injectIntl(ProductBrandEditForm);
