import React, { useEffect, useState } from 'react';

import { Form, Input, Modal } from 'antd';
import { CustomNotification } from 'common/components';
import * as userServices from 'services/user';

import { useRefetchUserProfile } from 'pages/user-profile/components/hook/useFetchUserProfile';

import './EditUserEmail.less';
import { useUserInfo } from 'hooks';

//here
const EditUserEmail = (props) => {
  const { userId, visible, onClose, onFinishEdit, email } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { fetch: fetchUserProfile } = useRefetchUserProfile();
  const { updateUserInfo } = useUserInfo();

  useEffect(() => {
    form.setFieldsValue({
      email: email,
    });
  }, [email, visible]);

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const response = await userServices.editUserProfileEmail({
          ...values,
          userId: userId,
        });
        if (response.isSuccess) {
          CustomNotification.success('Updated successfully');

          fetchUserProfile();
          onFinishEdit(true);
          updateUserInfo();
          onCloseModal();
        } else {
          CustomNotification.error(response.message || 'Updated Fail!');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const onCloseModal = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title='Edit Email'
      onCancel={onCloseModal}
      okText='Save'
      onOk={onFinish}
      confirmLoading={loading}
    >
      <Form
        layout='inline'
        name='email-edit'
        form={form}
        className='edit-user-email'
      >
        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
            { type: 'email', message: 'Invalid Email' },
          ]}
        >
          <Input placeholder={'email'} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditUserEmail.propTypes = {};

export default EditUserEmail;
