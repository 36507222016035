import React from 'react';

import { activityTypeIcon } from 'static/Icons';

const UserActivityIcon = (props) => {
  // props
  const { value } = props;

  const Icon = matchIconType(value);
  return <Icon />;
};

const matchIconType = (type) => {
  const isValidIconList = activityTypeIcon && activityTypeIcon.length;
  // get default icon from activityTypeIcon list
  const defaultIconObj =
    isValidIconList &&
    activityTypeIcon.find((icon) => {
      return icon.type === 'default';
    });
  // find icon by type and return its component
  const matchIconObj =
    (isValidIconList &&
      activityTypeIcon.find((icon) => {
        return icon.type === type;
      })) ||
    defaultIconObj;

  return matchIconObj.component;
};

export default UserActivityIcon;
