import React from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { helpIcon } from 'static/HelpIcon';

const HelpCategoryGridViewIcon = (props) => {
  const { data } = props;

  const iconName = data?.iconName;
  const iconObj = helpIcon.find((icon) => {
    return icon.type === iconName;
  });

  const Icon = iconObj?.icon;

  return Icon ? <Icon /> : <QuestionOutlined />;
};

export default HelpCategoryGridViewIcon;
