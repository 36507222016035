import React from 'react';

import { useSelector } from 'react-redux';

import { ReactComponent as IconDownloadSheet } from 'common/components/button/svg-icons/ICO_10.46.svg';
import { CloudDownloadOutlined } from '@ant-design/icons';

import * as productSelectors from 'pages/product-full-view/controllers/selectors';

import { forwardTo } from 'utils/common/route';
import { saveFile } from 'utils/saveFile';

import { downloadMappingProductSheets } from 'services/mapping';

import messages from 'i18n/messages/home';

import {
  CustomNotification,
  ItemButton,
  ContainerButton,
  RibbonButton,
} from 'common/components';

const ProductSheets = ({ productId, disabled }) => {
  return (
    <RibbonButton
      size='small'
      icon={<IconDownloadSheet style={{ height: 26 }} />}
      label={messages.downloadSheet}
      onClick={() => forwardTo(`/product/sheets/${productId}`)}
      disabled={disabled}
      className='button-item-dropdown'
    />
  );
};

export const DownloadMappingProductSheets = () => {
  const selectedMapping = useSelector(
    productSelectors.makeSelectSelectedMapping()
  );
  const currentHierarchy = useSelector(
    productSelectors.makeSelectCurrentHierarchy()
  );

  const handleDownloadMappingProductSheets = () => {
    downloadMappingProductSheets({
      mappingId: parseInt(selectedMapping?.mappingId),
      hierarchyId: parseInt(currentHierarchy?.productHierarchyId),
    })
      .then((response) => {
        if (response.isSuccess) {
          saveFile(response?.data?.url);
        } else {
          CustomNotification.error(response?.message);
        }
      })
      .catch(() => {
        CustomNotification.error('Something went wrong. Try again');
      });
  };

  return (
    <ContainerButton>
      <ItemButton>
        <RibbonButton
          icon={<CloudDownloadOutlined style={{ height: 26 }} />}
          label={messages.download}
          disabled={!selectedMapping || !currentHierarchy}
          onClick={handleDownloadMappingProductSheets}
        />
      </ItemButton>
    </ContainerButton>
  );
};

export default ProductSheets;
