import React from 'react';
import { Typography } from 'antd';

import { ReactComponent as PencilSvg } from 'assets/svg-icons/pencil.svg';
import { mapToGridItemClass } from 'pages/role-member/mappers';

import './styles.less';

const { Text } = Typography;

const MemberRoleSectionWrapper = (props) => {
  const { children, title, className, isEdit, haveNoExtraPaddingTop, style } =
    props;

  const procClassName = haveNoExtraPaddingTop
    ? ['role-member-management__grid-item--no-extra-pt', className]
    : className;

  return (
    <div className={mapToGridItemClass(procClassName)} style={style}>
      {title && (
        <div className='member-role-management__section-header'>
          <Text style={{ marginRight: 5, textTransform: 'uppercase' }}>
            {title}
          </Text>
          {isEdit && <PencilSvg style={{ width: 13, height: 13 }} />}
        </div>
      )}
      <div className='member-role-management__section-content'>{children}</div>
    </div>
  );
};

export default MemberRoleSectionWrapper;
