import React from 'react';
import dropbox from 'assets/system/dropbox.png';

import {
  CloudSyncOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import * as interoperabilityServices from 'services/interoperability';

export const filterImage = (type) => {
  switch (type?.toLowerCase()) {
    case 'dropbox':
      return dropbox;
    default:
      return '';
  }
};

// export const openNewTabUrl = (url) => window.open(url, '_blank');
export const openNewTabUrl = (url) =>
  window.open(
    url,
    'popUpWindow',
    'height=700,width=700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=yes,directories=no,status=yes'
  );

export const AUTH_DATA_KEY = 'interop_auth_data';

export const saveAuthDataIntoLocalStorage = (authDatStringify, state) => {
  const parseAuthData = JSON.parse(authDatStringify);

  localStorage.setItem(
    AUTH_DATA_KEY,
    JSON.stringify({ authData: parseAuthData, state })
  );
};

export const getInteropAuthDataFromLocalStorage = () => {
  const stringifyAuthData = localStorage.getItem(AUTH_DATA_KEY);

  if (!stringifyAuthData) {
    return {
      authData: null,
      state: null,
    };
  }
  return JSON.parse(stringifyAuthData);
};

export const removeInteropLocalStorage = () => {
  localStorage.removeItem(AUTH_DATA_KEY);
  localStorage.removeItem(INTEROP_KEY);
};

export const getParamsDropboxCallback = async (code, state) => {
  try {
    const response = await interoperabilityServices.getDropboxCallbackService({
      code,
      state,
    });

    if (response?.isSuccess && response?.data) {
      return await response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const INTEROP_KEY = 'interop_key';

export const getParamsDropboxCallbackFromLocalStorage = () => {
  const stringifyInteropParams = localStorage.getItem(INTEROP_KEY);

  if (!stringifyInteropParams) return {};

  const { code, state, status } = JSON.parse(stringifyInteropParams) ?? {};

  return {
    code,
    stateInterop: state,
    status,
  };
};

const getAuthInfo = async (id) => {
  return await interoperabilityServices.getDetailInteropConnection({ id });
};

const checkStatusConnection = async (params) => {
  return await interoperabilityServices.testInteropConnection(params);
};

const getListPromiseStatusConnection = (data) => {
  let promises = [];

  data.forEach((authInfo) => {
    promises.push(
      checkStatusConnection({
        id: authInfo.id,
        storageServiceType: authInfo.storageServiceType,
        authData: authInfo.authData,
      })
    );
  });

  return promises;
};

const getListPromiseAuthInfo = (authInfoList) => {
  let promises = [];

  authInfoList.forEach((id) => {
    promises.push(getAuthInfo(id));
  });

  return promises;
};

export const getListAuthInfoInterop = async (ids) => {
  const promisesAuthInfo = getListPromiseAuthInfo(ids);

  return await Promise.allSettled(promisesAuthInfo);
};

export const getListStatusInterop = async (interop) => {
  const ids = interop.map((item) => item.id);

  const response = await getListAuthInfoInterop(ids);

  const authInfoList = response?.map((result) => result.value.data);

  const promisesStatusInterop = getListPromiseStatusConnection(authInfoList);

  return await Promise.allSettled(promisesStatusInterop);
};

export const getIconTestConnection = (status) => {
  if (status === 'loading') return <LoadingOutlined />;
  if (status === 'connected') return <CheckCircleOutlined />;
  if (status === 'disconnected') return <WarningOutlined />;
  return <CloudSyncOutlined />;
};

export const getTextTestConnection = (status) => {
  if (status === 'loading') return 'Loading...';
  if (status === 'disconnected') return 'Fail connection';
  if (status === 'connected') return 'Connected';
  return 'Test Connection';
};

export const getInitFormValues = ({
  isEditMode,
  selectedInterop,
  authData,
}) => {
  if (isEditMode && selectedInterop) {
    const {
      status,
      storageServiceName: connectionName,
      storageServiceType: connectionType,
    } = selectedInterop;

    const { AccessToken: accessToken } =
      JSON.parse(selectedInterop?.authData) ?? {};

    return {
      connectionName,
      connectionType,
      accessToken,
      status,
    };
  } else {
    // Not authenticated
    if (!authData) return {};

    const { AccessToken: accessToken } = JSON.parse(authData);
    return {
      accessToken,
      status: 'Connected',
    };
  }
};
