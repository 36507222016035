import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Select, Typography, Divider, Radio } from 'antd';

import { WrapperSelect } from 'common/components';

import { mdTextTypeList } from 'static/Constants';
import MdToolTextColor from './MdToolTextColor';
import MdTextFont from './MdTextFont';
import MdTextSize from './MdTextSize';

import './MdToolTypeAndFont.less';

const { Option } = Select;
const { Text } = Typography;

const MdToolTypeAndFont = (props) => {
  const {
    fontType,
    setFontType,
    fontSize,
    setFontSize,
    textType,
    setTextType,
    sideView,
    setSideView,
    radioOption,
    textColor,
    handleChangeColor,
  } = props;

  const handleTypeChange = (value) => {
    setTextType && setTextType(value);
  };

  const handleChangeRightView = (event) => {
    setSideView && setSideView(event.target.value);
  };

  const handleChangeFontType = (value) => {
    setFontType && setFontType(value);
  };

  const handleChangeFontSize = (value) => {
    setFontSize && setFontSize(value);
  };

  return (
    <Row className='md-tool-type-and-font'>
      <Col>
        <Text className='md-tool-type-and-font__title'>Content Type</Text>
        <WrapperSelect
          style={{ width: 80 }}
          defaultValue={textType}
          onChange={handleTypeChange}
        >
          {mdTextTypeList.map((typeItem) => {
            return (
              <Option value={typeItem?.value} key={typeItem?.value}>
                <div style={{ fontFamily: typeItem?.value, fontSize: 15 }}>
                  {typeItem?.text}
                </div>
              </Option>
            );
          })}
        </WrapperSelect>
      </Col>
      <Col>
        <Divider type='vertical' style={{ height: '100%' }} />
      </Col>
      <MdToolTextColor textColor={textColor} setTextColor={handleChangeColor} />
      <Col>
        <Divider type='vertical' style={{ height: '100%' }} />
      </Col>
      <MdTextFont
        fontType={fontType}
        handleChangeFontType={handleChangeFontType}
      />
      <Col>
        <Divider type='vertical' style={{ height: '100%' }} />
      </Col>
      <MdTextSize
        fontSize={fontSize}
        handleChangeFontSize={handleChangeFontSize}
      />
      <Col
        flex='auto'
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          paddingBottom: 3,
        }}
      >
        <Radio.Group
          options={radioOption}
          onChange={handleChangeRightView}
          value={sideView}
          optionType='button'
          buttonStyle='solid'
        />
      </Col>
    </Row>
  );
};

MdToolTypeAndFont.propTypes = {
  fontType: PropTypes.string,
  setFontType: PropTypes.func,
  textType: PropTypes.string,
  setTextType: PropTypes.func,
  sideView: PropTypes.string,
  setSideView: PropTypes.func,
  radioOption: PropTypes.object,
};

export default MdToolTypeAndFont;
