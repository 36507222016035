import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';
import { saveQaSpecDataFreshMeat } from 'services/product';
import { getQaFreshSeafoodTabDataQueryKey } from '../qa-fresh-seafood/queries';
import * as productServices from 'services/product';
import { forwardTo } from 'utils/common/route';

export const getQaFreshMeatTabDataQueryKey = (productId) => {
  return ['qa-spec', parseInt(productId), 'qa-spec-data-fresh-meat'];
};

export const useGetQaFreshMeatTabData = ({ productId }, options) => {
  return useQuery({
    queryKey: getQaFreshMeatTabDataQueryKey(productId),
    queryFn: async () => {
      const response = await productServices.getFreshMeat({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    ...options,
  });
};

export const useSaveQaFreshMeatTab = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ productId, qaFreshMeatFormData }) => {
      const params = { productId, ...qaFreshMeatFormData };
      const response = await saveQaSpecDataFreshMeat(params);
      const { isSuccess, data, message } = response || {};

      if (isSuccess) {
        return Promise.resolve(data);
      } else if (message) {
        return Promise.reject(message);
      }
    },
    onSuccess: (data, { productId }) => {
      queryClient.invalidateQueries({
        queryKey: getQaFreshMeatTabDataQueryKey(productId),
      });
      queryClient.invalidateQueries({
        queryKey: getQaFreshSeafoodTabDataQueryKey(productId),
      });

      CustomNotification.success('Update Fresh-Meat data successfully!');
    },
    onError: (error) => {
      CustomNotification.error(error || 'Server Error');
    },
  });
};
