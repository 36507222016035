export const weightProperties = [
  {
    label: 'Net Weight in Pounds',
    key: 'netWeightInPounds',
  },
  {
    label: 'Net Weight in Ounces',
    key: 'netWeightInOunces',
  },
  {
    label: 'Net Weight in Kilograms',
    key: 'netWeightInKilograms',
  },
  {
    label: 'Net Weight in Grams',
    key: 'netWeightInGrams',
    className: "calculated-property-content_latest-value",
  },
];

export const volumeProperties = [
  {
    label: 'Volume in Gallons',
    key: 'volumeInGallons',
  },
  {
    label: 'Volume in Fluid Ounces',
    key: 'volumeInFluidOunces',
  },
  {
    label: 'Volume in Liters',
    key: 'volumeInLiters',
  },
  {
    label: 'Volume in Milliliters',
    key: 'volumeInMilliliters',
    className: "calculated-property-content_latest-value",
  },
];
