import React from 'react';
import { useDispatch } from 'react-redux';

import { FolderAddOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import * as markupActions from 'pages/markup-maintenance/controllers/actions';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/maintenance';

const CreateMarkup = () => {
  const dispatch = useDispatch();

  const handleCreateMarkup = () => {
    dispatch(markupActions.enableCreateMarkup());
    forwardTo(`/maintenance/markup/create`);
  };

  return (
    <RibbonButton
      size='small'
      icon={<FolderAddOutlined />}
      label={Messages.createMarkup}
      onClick={handleCreateMarkup}
    />
  );
};

export default CreateMarkup;
