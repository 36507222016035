import React from 'react';

import { Popover, Button } from 'antd';

import './PopoverBtn.less';

const PopoverBtn = (props) => {
  const { placement, btnList, trigger = 'click', children } = props;

  const content = (
    <div className='popover-btn__wrapper'>
      {btnList.map((btnItem, idx) => {
        const Icon = btnItem?.icon;
        return (
          <Button className='popover-btn__button' key={idx}>
            {Icon && <Icon />}
            {btnItem?.name}
          </Button>
        );
      })}
    </div>
  );

  return (
    <Popover
      placement={placement}
      title={false}
      content={content}
      trigger={trigger}
      overlayClassName='popover-btn'
    >
      {children && children}
    </Popover>
  );
};

export default PopoverBtn;
