export const GET_MY_SUBSCRIPTION_DETAIL_GRID =
  '/api/sub/get-subscription-subscriber-detail-grid';
export const GET_SUBSCRIPTION_TO_DETAIL_GRID =
  '/api/sub/get-subscription-product-owner-detail-grid';
export const GET_MY_SUBSCRIPTION_DETAIL_GRID_DISTINCT =
  '/api/sub/get-subscription-subscriber-detail-grid/distinct';
export const GET_SUBSCRIPTION_TO_DETAIL_GRID_DISTINCT =
  '/api/sub/get-subscription-product-owner-detail-grid/distinct';

export const UNSUBSCRIBE = '/api/sub/unsubscribe-product-or-member';
export const SUBSCRIBE_PRODUCT_OR_MEMBER =
  '/api/sub/subscribe-product-or-member';
export const EDIT_SUBSCRIPTION = '/api/sub/edit-subscription';
export const GET_PRODUCT_DETAIL_SUBSCRIPTIONS_GRID =
  '/api/sub/get-product-detail-subscriptions-grid';
export const GET_PRODUCT_DETAIL_SUBSCRIPTIONS_GRID_DISTINCT =
  '/api/sub/get-product-detail-subscriptions-grid/distinct';
export const ACCEPT_SUBSCRIPTION = '/api/sub/accept-subscription';
export const REJECT_SUBSCRIPTION = '/api/sub/reject-subscription';
export const REVOKE_SUBSCRIPTION = '/api/sub/revoke-subscription';
export const SEND_GDSN_SUBSCRIPTION_TO_OUTSIDE =
  '/api/sub/send-gdsn-subscription-to-outside';
