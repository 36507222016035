import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';

import { Form, Button, Skeleton, Row, Col } from 'antd';

import {
  StyledModal,
  CustomNotification,
  WithLoading,
} from 'common/components';

import { PublicationErrorItemValidationRule } from './PublicationErrorItem';

import PublicationCommonErrorList from './PubliccationCommonErrorList';
import PackageItemInfo from './PackageItemInfo';

import { useGetProductEnums } from 'hooks';

import { saveQuickPublicationFixForm } from 'services/product';

import { mapToFormValuePublicationInstantFix } from 'pages/branded-products/mapper/mapToFormValuePublicationInstantFix';
import { apiHandler } from 'utils/api';
import classnames from 'classnames';
import { startCase, range } from 'lodash';
import { sleep } from 'utils/delay';

import useFetchDataForErrorList from 'pages/branded-products/hooks/useFetchDataForErrorList';
import { useGetProductSchema } from 'hooks';

import messages from 'i18n/messages/brandedProduct';

import './PublicationErrorChecker.less';

const PublicationErrorChecker = (props) => {
  const {
    isErrorListModalOpen,
    errorList,
    setProductId,
    toggleCheckErrorModal,
    toggleErrorListModal,
    selectedErrorIndexHook,
    reloadOneError,
    getPublishCheckList,
    publishCheckLoading,
    packageLevelItem,
  } = props;

  const intl = useIntl();

  const productItemId = packageLevelItem?.productId;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [reloadAfterSubmit, setReloadAfterSubmit] = useState(false);
  const [errorIndex, setErrorIndex] = selectedErrorIndexHook;

  const { productEnums } = useGetProductEnums();

  const { productSchema, isLoading } = useGetProductSchema();

  const {
    data: { productDetailList, productModuleList },
    refetchProductData,
  } = useFetchDataForErrorList(errorList, reloadOneError, errorIndex);

  const handleReloadValueAfterSubmit = async () => {
    const isGetCheckListEnd = await getPublishCheckList();
    if (isGetCheckListEnd) {
      setReloadAfterSubmit(true);
      await sleep(1500); // wait for error list to be update

      const isLoadProductDataEnd = await refetchProductData();

      if (!isLoadProductDataEnd) {
        CustomNotification.error(`Failed to product's data`);
      }
      setReloadAfterSubmit(false);
    }
  };

  const handleSubmitForm = async (name, { values, forms }) => {
    if (!forms) return;
    let originalFormValues = [];

    await Promise.all(
      Object.keys(forms).map(async (formName) => {
        const values = await forms[formName].getFieldsValue();
        originalFormValues.push(values);
      })
    );

    const submitValue = mapToFormValuePublicationInstantFix(originalFormValues);

    if (submitValue === false) {
      return CustomNotification.info(
        'Please try again, we are preparing form data'
      );
    }

    const params = { data: submitValue };

    setSubmitLoading(true);
    await apiHandler({
      service: saveQuickPublicationFixForm,
      params,
      successMessage: intl.formatMessage(messages.savePublicSuccessMessage),
      errorMessage: intl.formatMessage(messages.savePublicErrorMessage),
      successCallback: () => {
        handleReloadValueAfterSubmit();
      },
      onFinally: async () => {
        setSubmitLoading(false);
      },
    });
  };

  useEffect(() => {
    if (errorList?.length === 0 && isErrorListModalOpen) {
      CustomNotification.success('All errors resolved!');
      toggleErrorListModal();
    }
  }, [errorList, isErrorListModalOpen]);

  const modalFooter = [
    <Button onClick={toggleErrorListModal} disabled={submitLoading}>
      Cancel
    </Button>,
    <Button
      form='form-group'
      htmlType='submit'
      type='primary'
      loading={submitLoading}
      disabled={isLoading}
    >
      Submit
    </Button>,
  ];

  return (
    <StyledModal
      title='Publication Error List'
      visible={isErrorListModalOpen}
      onCancel={toggleErrorListModal}
      okButtonProps={{ htmlType: 'submit' }}
      maskClosable={false}
      bodyStyle={{
        height: '70vh',
        maxHeight: '70vh',
        minHeight: '450px',
        overflow: 'hidden',
      }}
      width='800px'
      centered
      destroyOnClose
      footer={modalFooter}
    >
      <WithLoading
        loading={publishCheckLoading}
        tip='Getting publication errors...'
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <PackageItemInfo packageLevelItem={packageLevelItem} />
          <Row
            className='publication-error-list'
            style={{ flex: 1, padding: '0 5px', overflow: 'hidden' }}
          >
            <Col xs={24} className='scroller' style={{ height: '100%' }}>
              {reloadAfterSubmit ? (
                range(3).map(() => <Skeleton avatar paragraph={{ rows: 2 }} />)
              ) : (
                <Form.Provider onFormFinish={handleSubmitForm}>
                  {errorList.map((errorGroup, groupIdx) => {
                    const isEmptyFields = errorGroup?.errorFields?.length <= 0;
                    return (
                      <div
                        className={classnames('publication-error-list__group', {
                          'publication-error-list__group--empty': isEmptyFields,
                        })}
                      >
                        <div>
                          Error type: {startCase(errorGroup?.errorType || '')}
                        </div>
                        <div>Error message: {errorGroup?.errorMessage}</div>

                        {isEmptyFields ? (
                          <PublicationErrorItemValidationRule
                            productItemId={productItemId}
                          />
                        ) : (
                          <PublicationCommonErrorList
                            errorGroup={errorGroup}
                            groupIdx={groupIdx}
                            status={isLoading ? 'loading' : 'success'}
                            setErrorIndex={setErrorIndex}
                            setProductId={setProductId}
                            toggleCheckErrorModal={toggleCheckErrorModal}
                            productEnums={productEnums}
                            productSchema={productSchema}
                            productDetailList={productDetailList}
                            productModuleList={productModuleList}
                          />
                        )}
                      </div>
                    );
                  })}
                </Form.Provider>
              )}
            </Col>
          </Row>
        </div>
      </WithLoading>
    </StyledModal>
  );
};

export default PublicationErrorChecker;
