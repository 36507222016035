// user api
export const USER_LOGIN_ENDPOINT = '/api/token/login';
export const ANON_LOGIN = '/api/anon-token/login';
export const GET_REFRESH_TOKEN = '/api/token/refresh';
export const REVOKE_TOKEN = '/api/token/revoke';
export const USER_GET_INFO = '/api/User/user-info';
// end user api
export const LOGIN_SEND_OTP = '/api/token/login/send-otp';
export const LOGIN_CHECK_OTP = '/api/token/login/check-otp';

export const UNLOCK_ACCOUNT_SEND_OTP_PHONE =
  '/api/token/unlock-account-via-phone';
export const UNLOCK_ACCOUNT_SEND_OTP_EMAIL =
  '/api/token/unlock-account-via-email';

export const UNLOCK_ACCOUNT_CHECK_OTP = '/api/token/check-otp-unlock-account';
