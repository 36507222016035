import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Modal, Row, Input, Typography } from 'antd';
import AgGrid from 'common/components/ag-grid/AgGrid';
import { CustomNotification, dialogFunction } from 'common/components';

import * as services from 'services/roles-security';
import * as endpoints from 'services/roles-security/endpoints';

import { useStatePrevious } from 'hooks/usePrevious';
import { useHandleReloadPage } from 'hooks/useReloadPage';

import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Messages from 'i18n/messages/member-profile';

import './NewUserModal.less';

const { Paragraph } = Typography;
const NewUserModal = (props) => {
  const { role, newUserColumns, visible, onClose, memberId } = props;

  const intl = useIntl();

  const [search, setSearch] = useState('');
  const [__, setReloadGrid] = useHandleReloadPage();
  const [selectedRows, setSelectedRow, refSelectedRow] = useStatePrevious([]);

  const refGridApi = useRef();

  const roleId = role?.id;

  const handleAdd = async () => {
    const params = {
      roleId: role?.id,
      addUserIds: selectedRows,
    };

    const notifyFail = () =>
      CustomNotification.error(intl.formatMessage(Messages.addNewUserFail));

    await services
      .addUsersToRole(params)
      .then((res) => {
        if (res.isSuccess) {
          onClose();
          CustomNotification.success(
            intl.formatMessage(Messages.addNewUserSuccess)
          );
          setReloadGrid();
        } else {
          notifyFail();
        }
      })
      .catch((err) => notifyFail());
  };

  const confirmAdd = () => {
    dialogFunction({
      type: 'warn',
      content: `Do you want to add the selected users to the role ${role?.displayName}`,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleAdd,
    });
  };

  const handleCancel = () => onClose();

  const onSetSelectedRows = (selectedNodesDetail) => {
    const selectedUserIdList = selectedNodesDetail.map(
      (nodeDetail) => nodeDetail?.id
    );
    setSelectedRow(selectedUserIdList);
  };

  const paramsGrid = { roleId, memberId, search: { searchText: search } };

  return (
    <Modal
      title={<FormattedMessage {...Messages.addNewUserToRole} />}
      visible={visible}
      onOk={confirmAdd}
      onCancel={handleCancel}
      okButtonProps={{ disabled: selectedRows.length <= 0 }}
      width={'50%'}
      height={'80%'}
      okText='Add'
      centered
      maskClosable={false}
      className='new-user-modal'
    >
      <Row className='new-user-modal__content'>
        <Paragraph className='new-user-modal__text' strong>
          {<FormattedMessage {...Messages.roleText} />}: {role?.displayName}
        </Paragraph>
        <Input.Search
          className='new-user-modal__search'
          placeholder='Search user...'
          onSearch={(value) => setSearch(value)}
          enterButton
        />
        <Row style={{ height: '100%', width: '100%' }}>
          {newUserColumns && (
            <AgGrid
              columnDefs={newUserColumns}
              urlGrid={endpoints.USERS_HAVE_NOT_ROLE}
              urlGridDistinct={endpoints.USERS_HAVE_NOT_ROLE_DISTINCT}
              responseParams='data'
              paramsGrid={paramsGrid}
              isShowItemSelectionLog={false}
              onHandleSelectedRowCustom={onSetSelectedRows}
              getGridApi={(gridApi) => {
                refGridApi.current = gridApi;
              }}
            />
          )}
        </Row>
      </Row>
    </Modal>
  );
};

NewUserModal.propTypes = {
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  role: PropTypes.object,
  visible: PropTypes.bool,
  memberId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NewUserModal;
