import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as qaSpecServices from 'services/qaSpec';

export const getQaSpecComponentKey = (productId) => {
  return ['qa-spec', parseInt(productId), 'qa-spec-component'];
};

export const useGetQaSpecComponentQuery = ({ productId, isGridEditing }) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery({
    queryKey: getQaSpecComponentKey(productId),
    queryFn: async () => {
      return await qaSpecServices.getQaSpecComponents({
        productId,
      });
    },
    enabled: Boolean(productId) && !isGridEditing,
  });

  const handleRefetchQaSpecComponent = () => {
    queryClient.invalidateQueries({
      queryKey: getQaSpecComponentKey(productId),
    });
  };

  return {
    componentData: data?.data,
    handleRefetchQaSpecComponent,
    ...rest,
  };
};
