import React, { useState, useRef } from 'react';

import { Button, Space, Image } from 'antd';
import { ResizableBox } from 'react-resizable';
import {
  DragOutlined,
  SettingOutlined,
  CloseOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { useDragDropPosHooks } from 'common/components/drapDropWrap/drag-drop-pos';

import youtubeIcon from 'assets/landing-page/youtube-icon.png';

import { checkComponentInside } from 'utils/element';
import {
  useAdjustPositionWhenSizeChange,
  useGet$wrapAnd$component,
} from '../../hooks';

import { sleep } from 'utils/delay';
import { EDIT_TYPE, TOLERANCE_SIZE } from '../../constants';

import classnames from 'classnames';

import './PageEditorDragYoutube.less';

const YOUTUBE_RATIO = 16 / 9;

const PageEditorDragYoutube = (props) => {
  const {
    wrapId,
    componentKey,
    selectedHook,
    editTypeHook,
    isEdit,
    componentData,
    componentHandlers,
    detailShowTargetHook,
  } = props;
  const { onDeleteComponent, onChangeComponentValue, onDragComponent } =
    componentHandlers;

  const [editType, setEditType] = editTypeHook;
  const [selected, setSelected, clearSelect] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;

  const [playableMode, setPlayableMode] = useState(false);

  const refIframe = useRef();
  const refResize = useRef();
  const refPrevSize = useRef();

  const [dragPosInst, dragStyle, refDragTag] = useDragDropPosHooks([
    clearSelect,
    componentKey,
    { disableClickouter: detailShowTarget === componentKey },
  ]);

  useAdjustPositionWhenSizeChange({
    wrapId,
    componentId: componentKey,
    onChangeComponentValue,
    fieldNameX: 'left',
    fieldNameY: 'top',
    deps: [componentData?.borderWidth, componentData?.src],
  });

  const [refWrap, refComponent] = useGet$wrapAnd$component(
    wrapId,
    componentKey
  );

  const handleClickComponent = (event) => {
    const target = event.currentTarget;
    setSelected({ id: target?.id, element: target });
  };

  const onClickDragIcon = () => {
    onDragComponent({
      componentData,
      componentKey,
      fieldNameX: 'left',
      fieldNameY: 'top',
      refWrap,
      refComponent,
      dragPosInst,
    });
  };

  const handleResize = (size) => {
    if (size.width) {
      onChangeComponentValue({
        key: componentKey,
        fieldName: 'width',
        value: size.width,
      });
    }
  };

  const onResizeStart = () => {
    refPrevSize.current = {
      width: componentData?.width,
      height: componentData?.width / YOUTUBE_RATIO,
    };
  };

  const onResizeStop = () => {
    const checkInsideResultArray = checkComponentInside({
      elements: [refComponent.current],
      elementCover: refWrap.current,
      tolerance: TOLERANCE_SIZE,
    });

    const { isInside } = checkInsideResultArray?.[0];

    if (!isInside) {
      onChangeComponentValue({
        key: componentKey,
        fieldName: 'width',
        value: refPrevSize?.current?.width,
      });
      refResize.current.setState(refPrevSize.current);
    }
  };

  const handleToggleEditDetailYoutube = () => {
    setEditType(EDIT_TYPE.YOUTUBE);
    setDetailShowTarget(componentKey);
  };

  const handleRemoveComponent = async () => {
    if (isSelected) {
      await sleep(200);

      onDeleteComponent({ key: componentKey });
      clearSelect();
    }
  };

  const handleTogglePlayMode = () => {
    setPlayableMode((prev) => !prev);
  };

  const convertYoutubeToEmbedSrc = (src) => {
    if (!src) return '';
    if (src.includes('embed')) return src;
    const videoId = src.split('?v=')?.[1]?.split('&')?.[0];
    return 'https://www.youtube.com/embed/' + videoId;
  };

  const isSelected = selected?.id === componentKey && isEdit;

  return (
    <div>
      <ResizableBox
        ref={refResize}
        width={componentData?.width}
        height={(1 / YOUTUBE_RATIO) * componentData?.width}
        top={componentData?.top}
        lockAspectRatio={true}
        onResize={(event, { element, size, handle }) => handleResize(size)}
        style={{
          top: componentData?.top,
          left: componentData?.left,
          position: 'absolute',
          overflow: 'visible',
          ...dragStyle(isSelected),
        }}
        id={componentKey}
        className={classnames('page-editor', {
          'highlight-selected': isSelected || playableMode,
          'highlight-selected--play-mode': playableMode,
        })}
        onClick={handleClickComponent}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
      >
        <div style={{ height: '100%', width: '100%' }}>
          {componentData?.src ? (
            <iframe
              ref={refIframe}
              title='unknown'
              src={convertYoutubeToEmbedSrc(componentData?.src)}
              frameborder='0'
              width='100%'
              height='100%'
              style={{
                width: '100%',
                height: '100%',
                transition: 'all 0.2s',
                borderRadius: `${componentData?.radius}px`,
                border: `solid ${componentData?.borderWidth}px ${componentData?.borderColor}`,
              }}
            />
          ) : (
            <div
              style={{
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: `${componentData?.radius}px`,
                transition: 'all 0.2s',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Image src={youtubeIcon} height={70} preview={false} />
            </div>
          )}
          {isEdit && (
            <div
              className={classnames('page-editor-drag-youtube__mask', {
                'page-editor-drag-youtube__mask--show': !playableMode,
              })}
            ></div>
          )}

          <div
            className={classnames('page-editor__drag', {
              'page-editor__drag--show': isSelected || playableMode,
            })}
          >
            <Space size={3}>
              <Button
                className={classnames(
                  'page-editor__btn',
                  'page-editor__btn--clear'
                )}
                onClick={handleRemoveComponent}
              >
                <CloseOutlined />
              </Button>
              <Button
                className={classnames('page-editor__btn')}
                onClick={handleToggleEditDetailYoutube}
              >
                <SettingOutlined />
              </Button>

              <Button
                className={classnames('page-editor__btn', {
                  'page-editor__btn--clear': playableMode,
                })}
                onClick={handleTogglePlayMode}
              >
                {playableMode ? <UnlockOutlined /> : <LockOutlined />}
              </Button>
              <Button
                className={classnames('page-editor__btn')}
                ref={refDragTag}
                onMouseDown={onClickDragIcon}
              >
                <DragOutlined />
              </Button>
            </Space>
          </div>
        </div>
      </ResizableBox>
    </div>
  );
};

export default PageEditorDragYoutube;
