import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';

import * as userActions from 'redux/user/actions';
import userSelectors from 'redux/user/selectors';

import ContainerButton from 'common/components/button/ContainerButton';
import RibbonButton from 'common/components/button/RibbonButton';

const ToggleSplashScreenSection = (props) => {
  const dispatch = useDispatch();
  const showSplashScreen = useSelector(
    userSelectors.makeSelectShowIxoneSplashScreen()
  );

  const handleToggleShowSplash = () => {
    dispatch(userActions.toggleSplashScreen(!showSplashScreen));
  };

  return (
    <ContainerButton style={{ width: 122 }}>
      {!showSplashScreen ? (
        <RibbonButton
          icon={<CheckOutlined />}
          label='Show Splash Screen'
          onClick={handleToggleShowSplash}
        />
      ) : (
        <RibbonButton
          icon={<StopOutlined />}
          label='Hide Splash Screen'
          onClick={handleToggleShowSplash}
        />
      )}
    </ContainerButton>
  );
};

export default ToggleSplashScreenSection;
