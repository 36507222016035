import React, { useMemo } from 'react';

import { Select } from 'antd';
import WrapperSelect from 'common/components/select';
import { findProductPropertyEnums } from 'pages/product-full-view/components/product-detail-view/utils';

import { useGetProductEnums } from 'hooks';

const { Option } = Select;

const EditInputCountry = (props) => {
  const { mode = 'single', value } = props;

  const country = useMemo(() => {
    if (Array.isArray(value)) {
      return value;
    } else if (!value) {
      return mode === 'single' ? value : [];
    } else {
      return value;
    }
  }, [value, mode]);

  const { productEnums } = useGetProductEnums();
  const countryCodeEnum = findProductPropertyEnums(
    'CountryCodeEnum',
    productEnums
  );

  return (
    <WrapperSelect
      showSearch
      allowClear
      style={{ width: '100%' }}
      mode={mode}
      filterOption={(input, option) =>
        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
      }
      value={country}
      {...props}
    >
      {countryCodeEnum.map((countryItem) => {
        return (
          <Option
            key={countryItem?.enumDisplayName}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span
              className='option-item-value'
              value={countryItem.enumDisplayName}
            >
              {countryItem.enumDisplayName}
            </span>
          </Option>
        );
      })}
    </WrapperSelect>
  );
};

export default EditInputCountry;
