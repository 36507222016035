import { Popover } from 'antd';

const SnapshotCellWrapper = (props) => {
  const { children, snapshotValueRender, isHighlightChange } = props;

  return (
    <div
      className='qa-spec-cell-wrapper'
      style={{
        height: '100%',
        background: isHighlightChange ? '#91caff' : '',
        borderRadius: 5,
        padding: 1,
      }}
    >
      <div
        style={{
          border: isHighlightChange
            ? 'solid 2px white'
            : 'solid 2px transparent',
          height: '100%',
          borderRadius: 5,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <div style={{ flex: 1, overflow: 'hidden' }}>
          {children && children}
        </div>
        {isHighlightChange && (
          <OriginValuePopover snapshotValueRender={snapshotValueRender}>
            <div
              style={{
                padding: 1,
                borderRadius: 3,
                background: 'rgba(0, 112, 204, 0.8)',
                height: 16,
                width: 16,
                lineHeight: 0.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: '0 9px 0 3px',
              }}
            >
              <i
                className='fa-solid fa-pen'
                style={{ color: 'white', fontSize: 8 }}
              ></i>
            </div>
          </OriginValuePopover>
        )}
      </div>
    </div>
  );
};

const OriginValuePopover = (props) => {
  const { snapshotValueRender, children } = props;

  return (
    <Popover
      title={'Previous Version'}
      content={snapshotValueRender}
      trigger='hover'
    >
      {children && children}
    </Popover>
  );
};

export default SnapshotCellWrapper;
