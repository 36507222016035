export const GET_PRODUCT_APL_GRID_COLUMN = 'GET_PRODUCT_APL_GRID_COLUMN';
export const GET_PRODUCT_APL_GRID_COLUMN_SUCCESS =
  'GET_PRODUCT_APL_GRID_COLUMN_SUCCESS';
export const GET_PRODUCT_APL_GRID_COLUMN_ERROR =
  'GET_PRODUCT_APL_GRID_COLUMN_ERROR';

export const SET_APL_SCHEDULE_SELECTED_DATE = 'SET_APL_SCHEDULE_SELECTED_DATE';
export const SET_APL_SCHEDULE_EVENTS = 'SET_APL_SCHEDULE_EVENTS';
export const SET_APL_SCHEDULE_IS_DRAG_START = 'SET_APL_SCHEDULE_IS_DRAG_START';
export const TOGGLE_APL_SCHEDULE_MODAL = 'TOGGLE_APL_SCHEDULE_MODAL';

export const TOGGLE_RELOAD_GRID_APL = 'TOGGLE_RELOAD_GRID_APL';
export const RESET_RELOAD_GRID_APL = 'RESET_RELOAD_GRID_APL';
