import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAssetList = (state) => state.assetList || initialState;

const makeSelectLoading = () =>
  createSelector(selectAssetList, (assetState) => assetState.loading);
const makeSelectAssetList = () =>
  createSelector(selectAssetList, (assetState) => assetState.assets);
const makeSelectTotal = () =>
  createSelector(selectAssetList, (assetState) => assetState.total);
const makeSelectPageSize = () =>
  createSelector(selectAssetList, (assetState) => assetState.pageSize);
const makeSelectPageNumber = () =>
  createSelector(selectAssetList, (assetState) => assetState.pageNumber);
const makeSelectSearch = () =>
  createSelector(selectAssetList, (assetState) => assetState.search);
const makeSelectColumns = () =>
  createSelector(selectAssetList, (assetState) => assetState.columns);
const makeSelectLoadingDetail = () =>
  createSelector(selectAssetList, (assetState) => assetState.loadingDetail);
const makeSelectAssetData = () =>
  createSelector(selectAssetList, (assetState) => assetState.assetData);

const makeSelectStatusDelete = () =>
  createSelector(selectAssetList, (assetState) => assetState.statusDelete);

const makeSelectCachedImages = () =>
  createSelector(
    selectAssetList,
    (assetState) => assetState.cachedImagesVersion
  );

const makeSelectAssetsForMember = () =>
  createSelector(selectAssetList, (assetState) => assetState.assetsForMember);

const selectEditTypeLoading = () =>
  createSelector(selectAssetList, (assetState) => assetState.editTypeLoading);

const makeSelectIsDisplayAssetNew = () =>
  createSelector(selectAssetList, (assetState) => assetState.isDisplayAssetNew);

const makeSelectCachedFromDate = () =>
  createSelector(selectAssetList, (assetState) => assetState.cachedFromDate);

export {
  selectAssetList,
  makeSelectLoading,
  makeSelectAssetList,
  makeSelectTotal,
  makeSelectPageSize,
  makeSelectPageNumber,
  makeSelectSearch,
  makeSelectColumns,
  makeSelectLoadingDetail,
  makeSelectAssetData,
  makeSelectStatusDelete,
  makeSelectCachedImages,
  makeSelectAssetsForMember,
  selectEditTypeLoading,
  makeSelectIsDisplayAssetNew,
  makeSelectCachedFromDate,
};
