import _ from 'lodash';
import { NUMERIC_TYPE } from 'static/Constants';

export const getTreeData = (dataSource) => {
  const sortedDataSource = _sortDataSource(_.cloneDeep(dataSource));

  const treeData = [];

  sortedDataSource.forEach((dataItem, index) => {
    const paths = _getPaths(dataItem.key);
    if (paths.length === 1) {
      treeData.push({
        ...dataItem,
      });
      return;
    }

    _addToParentData({ item: dataItem, data: treeData });
  });

  // const mappedKeyData = _mapKeyToTreeData(treeData);

  return treeData;
};

export const getAllExpandKeys = (treeData) => {
  const allExpandKeys = [];
  _getParentKey({ data: treeData, result: allExpandKeys });
  return allExpandKeys;
};

export const compareExpandKey = (expandKeys, allExpandKeys) => {
  if (!expandKeys?.length || !allExpandKeys) return;
  const uniqExpandKeys = _.uniqWith(expandKeys, (a, b) => {
    return a === b;
  });

  return _.isEqual(uniqExpandKeys.sort(), allExpandKeys.sort());
};

export const flatData = ({ data, result }) => {
  if (!data) return;
  data.forEach((currentData) => {
    const currentDataInAccumulator = result.find(
      (dataItem) => dataItem.key === currentData.key
    );

    if (!currentDataInAccumulator)
      result.push({ ...currentData, children: undefined });

    if (currentData.children?.length)
      flatData({ data: currentData.children, result });
  });
};

export const semiFlatData = ({ data, result }) => {
  if (!data) return;
  data.forEach((currentData) => {
    const currentDataInAccumulator = result.find(
      (dataItem) => dataItem.key === currentData.key
    );

    if (!currentDataInAccumulator) result.push(currentData);

    if (currentData.children?.length)
      semiFlatData({ data: currentData.children, result });
  });
};

export const mapDisabledData = (data, addedKeys) => {
  if (!data?.length) return [];

  return data.map((dataItem) => {
    const shouldDisableTreeNode = checkDisableTreeNode(dataItem, addedKeys);
    return {
      ...dataItem,
      disableCheckbox: shouldDisableTreeNode,
      children: mapDisabledData(dataItem.children, addedKeys),
    };
  });
};

export const createFilterData = (data, checkedKeys, addedKeys = []) => {
  const filterOutAddedKeys = checkedKeys.filter(
    (checkKeyItem) => !addedKeys.includes(checkKeyItem)
  );

  return data.reduce((accumulator, currentData) => {
    const clonedItem = _.cloneDeep(currentData);

    const isItemChecked =
      !clonedItem.children.length &&
      filterOutAddedKeys?.includes(clonedItem.key);

    const isChildrenChecked =
      clonedItem.children.length &&
      checkChildrenCheckedDeep(clonedItem, filterOutAddedKeys);

    if (clonedItem.children.length && isChildrenChecked)
      clonedItem.children = createFilterData(
        clonedItem.children,
        filterOutAddedKeys
      );

    if (isItemChecked || isChildrenChecked) accumulator.push(clonedItem);

    return accumulator;
  }, []);
};

export const getTotalItemSelected = ({ data, selectedKeys, addedKeys }) => {
  const selectedItems = data?.filter((dataItem) => {
    return !dataItem?.children?.length && selectedKeys.includes(dataItem.key);
  });
  //* filter out selected item which is not added
  const addableItems = selectedItems.filter((dataItem) => {
    return !addedKeys?.includes(dataItem.key);
  });

  return addedKeys?.length ? addableItems.length : selectedItems.length;
};

export const getPropertiesFieldNameFromAddedKey = ({
  addedKeys,
  semiFlattenData,
}) => {
  return addedKeys.reduce((accumulator, currentAddedKeyItem) => {
    const currentKeyData = semiFlattenData?.find((treeDataItem) => {
      return treeDataItem.key === currentAddedKeyItem;
    });

    if (currentKeyData.data) accumulator.push(currentKeyData.data.fieldName);

    return accumulator;
  }, []);
};

export const getExpandKeysOnEvent = (leftExpandedKeys, e) => {
  const isNodeExpanded = e.node.expanded;
  const childrenNodes = e.node.children;

  if (!childrenNodes.length) return leftExpandedKeys;

  const cloneExpandedKeys = _.cloneDeep(leftExpandedKeys);
  const needUpdateKeys = childrenNodes.reduce(
    (accumulator, currentNode) => {
      if (currentNode.children.length) return accumulator;
      return [...accumulator, currentNode.key];
    },
    [e.node.key]
  );

  const newExpandedKeys = isNodeExpanded
    ? cloneExpandedKeys.filter((keyItem) => {
        return !needUpdateKeys.includes(keyItem);
      })
    : [...cloneExpandedKeys, ...needUpdateKeys];

  return newExpandedKeys;
};

export const getAllChildrenKeyDeep = (data, result) => {
  if (data?.length) {
    data.forEach((nodeChild) => {
      result.push(nodeChild.key);

      if (nodeChild?.children?.length)
        getAllChildrenKeyDeep(nodeChild.children, result);
    });
  }
};

//* FILTER
export const filterAddedKey = (data, addedKeys) => {
  if (!data) return [];

  const addableKeys = getAddableKeysFromAddedKey(addedKeys);

  return data.reduce((accumulator, currentDataItem) => {
    const isActive = addableKeys.find(checkKeyActive(currentDataItem));

    if (isActive) {
      accumulator.push({
        ...currentDataItem,
        children: filterAddedKey(currentDataItem.children, addedKeys),
      });
    }

    return accumulator;
  }, []);
};

export const searchItems = (treeData, searchText) => {
  const preprocTreeData = { name: 'base', children: treeData };

  const filterTree = (node, searchText) => {
    const foundChildren = node.children
      .map((child) => filterTree(child, searchText))
      .filter((child) => !!child);

    if (findItem(node, searchText) || !!foundChildren.length) {
      return {
        ...node,
        children: foundChildren,
      };
    }

    return null;
  };

  const findItem = (node, searchText) => {
    return node?.data?.displayName
      .toLowerCase()
      .includes(searchText.toLowerCase());
  };

  return filterTree(preprocTreeData, searchText)?.children;
};

//* CHECKING
export const checkAllChildrenAddedDeep = (dataItem, addedKeys) => {
  let result = { added: true };
  _checkChildrenAdded(dataItem, result, addedKeys);

  return result.added;
};

export const checkDisableTreeNode = (dataItem, addedKeys) => {
  let result = { disabled: true };
  _checkChildrenAdded(dataItem, result, addedKeys);

  return result.disabled;
};

const checkChildrenCheckedDeep = (dataItem, checkedKeys) => {
  let result = { checked: false };
  checkChildrenChecked(dataItem, result, checkedKeys);

  return result.checked;
};

const checkChildrenChecked = (data, result, checkedKeys) => {
  const resultKey = Object.keys(result)[0];

  if (result[resultKey]) return;

  if (!data?.children?.length) {
    result[resultKey] = checkedKeys?.includes(data?.key);
    return;
  }

  data.children.forEach((childDataItem) => {
    checkChildrenChecked(childDataItem, result, checkedKeys);
  });
};

export const checkAllChildrenCheckedDeep = (dataItem, checkedKeys) => {
  let result = { checked: true };
  checkAllChildrenChecked(dataItem, result, checkedKeys);

  return result.checked;
};

const checkAllChildrenChecked = (data, result, checkedKeys) => {
  const resultKey = Object.keys(result)[0];

  if (!result[resultKey]) return;

  if (!data?.children?.length) {
    result[resultKey] = checkedKeys?.includes(data?.key);
    return;
  }

  data.children.forEach((childDataItem) => {
    checkAllChildrenChecked(childDataItem, result, checkedKeys);
  });
};

const getAddableKeysFromAddedKey = (addedKeys) => {
  const result = addedKeys.reduce((accumulator, currentKey) => {
    const keyPaths = currentKey.split('.');

    let addableKeyAccumulator = '';

    keyPaths.forEach((path) => {
      addableKeyAccumulator += addableKeyAccumulator ? '.' + path : path;
      accumulator.push(addableKeyAccumulator);
    });

    return accumulator;
  }, []);

  return result;
};
const checkKeyActive = (currentDataItem) => (keyItem) => {
  return currentDataItem.key === keyItem;
};

//* INTERNAL FUNCTION
const _sortDataSource = (dataSource) => {
  return dataSource.sort((a, b) => {
    return _getPaths(a.key).length - _getPaths(b.key).length;
  });
};

const _getPaths = (path) => {
  if (path) return path.split('.');
};

const _addToParentData = ({ data, item }) => {
  const paths = _getPaths(item.key);
  //* remove last item
  paths.pop();

  const parent = _findParent(paths, data);
  parent.children = parent.children ? [...parent.children, item] : [item];
};

const _findParent = (paths, data) => {
  let parent = {};
  let accumulator = { data: data, findPath: paths[0] };

  paths.forEach((currentPath, index) => {
    const matchPathData = accumulator.data.find(
      (dataItem) => dataItem.key === accumulator.findPath
    );
    if (matchPathData) parent = matchPathData;

    if (matchPathData?.children) {
      accumulator = {
        data: matchPathData?.children,
        findPath: accumulator.findPath + '.' + paths[index + 1],
      };
    }
  });

  return parent;
};

const _getParentKey = ({ data, result }) => {
  if (!data) return data;
  data.forEach((dataItem) => {
    if (!dataItem.children?.length) return;
    result.push(dataItem.key);
    _getParentKey({ data: dataItem.children, result });
  });
};

const _checkChildrenAdded = (data, result, addedKeys) => {
  const resultKey = Object.keys(result)[0];

  if (!result[resultKey]) return;

  if (!data?.children?.length) {
    result[resultKey] = addedKeys?.includes(data?.key);
    return;
  }

  data.children.forEach((childDataItem) => {
    _checkChildrenAdded(childDataItem, result, addedKeys);
  });
};

export const generateSourceData = (data) => {
  const moduleData = generateModuleData(data);
  const propertyItemData = generatePropertyItemData(moduleData, data);
  const objectTypePropertyData =
    generateOjectTypePropertiesData(propertyItemData);

  return [...moduleData, ...objectTypePropertyData, ...propertyItemData];
};

const generateModuleData = (data) => {
  if (!data) return [];
  const moduleData = data.map((propertyItem) => {
    return {
      key: propertyItem.moduleName || 'noHeader',
      title: propertyItem.moduleDisplayName || 'Product Basic Information',
      type: 'group',
    };
  });

  const uniqData = _.uniqBy(moduleData, 'key');

  return uniqData;
};

const generatePropertyItemData = (moduleData, data) => {
  if (!moduleData || !data) return;

  const formattedPropertiesData = data.map((propertyItem) => {
    const { dataType, displayName, fieldFullPath, fieldName } = propertyItem;

    let changeInt;
    if (NUMERIC_TYPE.includes(dataType?.toLowerCase())) changeInt = 'Number';

    const propertyModuleName =
      propertyItem.moduleDisplayName || 'Product Basic Information';

    const modulePath = moduleData.find((moduleItem) => {
      return moduleItem.title === propertyModuleName;
    })?.key;
    const propertyPathArray = fieldFullPath.split('.');
    //* remove the first path as it is module path
    if (propertyItem.moduleDisplayName) propertyPathArray.shift();

    const realPropertyPath = propertyPathArray.join('.');
    const formattedPropertyPath = modulePath + '.' + realPropertyPath;

    return {
      key: formattedPropertyPath,
      data: {
        displayName,
        fieldName,
        fieldFullPath,
        dataType: changeInt ?? dataType,
      },
    };
  });
  return formattedPropertiesData;
};

const generateOjectTypePropertiesData = (propertiesData) => {
  if (!propertiesData) return [];
  const objectTypePropertyData = [];
  propertiesData.forEach((propertyItem) => {
    const paths = propertyItem.key.split('.');
    if (paths.length <= 2) return;
    let pathString = '';

    for (let i = 0; i < paths.length - 1; i++) {
      pathString += paths[i];

      const isPathStringAdded = objectTypePropertyData.find(
        (propertyObjectItem) => propertyObjectItem.key === pathString
      );

      if (!isPathStringAdded && pathString.includes('.')) {
        objectTypePropertyData.push({
          key: pathString,
          fieldName: paths[i],
          title: _.startCase(paths[i]),
          type: 'object',
        });
      }
      pathString += '.';
    }
  });
  return objectTypePropertyData;
};
