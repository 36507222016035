import React from 'react';

import { useSelector } from 'react-redux';

import { Can } from 'context/Can';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import DeleteFormBtn from './DeleteFormBtn';
import ReplaceFormBtn from './ReplaceFormBtn';
import EditPreviewBtn from './EditPreviewBtn';
import ToggleFullScreenFormBtn from './ToggleFullScreenFormBtn';
import ToggleFloatModeFormBtn from './ToggleFloatModeFormBtn';
import SelectedDataPointInfo from './SelectedDataPointInfo';

import { RibbonSection } from 'common/components';
import * as maintenanceFormSelectors from 'pages/maintenance-form/controllers/selectors';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const FormDetailManipulationSection = (props) => {
  const selectedForms = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const formDetailInfo = useSelector(
    maintenanceFormSelectors.makeSelectFormDetail()
  );
  const formDetailData = formDetailInfo?.data;

  return (
    <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL}>
      <RibbonSection>
        <DeleteFormBtn formId={formDetailData?.formId} />
        <ToggleFullScreenFormBtn />
        <ToggleFloatModeFormBtn />
        <SelectedDataPointInfo />
      </RibbonSection>
    </Can>
  );
};

export default FormDetailManipulationSection;
