import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Dropdown, Button, Checkbox, Row, Space, Empty } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import messages from 'i18n/messages/home';
import RibbonButton from 'common/components/button/RibbonButton';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';
import iconList from 'common/components/icon-list/assets';
import useCustomScroll from 'hooks/useCustomScroll';

import './CategoriesControl.less';

const CategoryListRender = (props) => {
  const { searchCategories, searchCategoriesEffected } = props;
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  useCustomScroll('.category-list-render');

  useEffect(() => {
    setCategoryList(searchCategoriesEffected);
  }, [searchCategoriesEffected, searchCategories]);

  const handleSelectSearchCategories = (lists) => {
    dispatch(gridViewActions.resetPageCurrent());
    dispatch(gridViewActions.setUserSearchCategoriesEffected(lists));
  };

  const handleSelectAll = () => {
    dispatch(gridViewActions.resetPageCurrent());
    dispatch(gridViewActions.setUserSearchCategoriesEffected(searchCategories));
  };

  const handleDeselectAll = () => {
    dispatch(gridViewActions.resetPageCurrent());
    dispatch(gridViewActions.setUserSearchCategoriesEffected([]));
  };

  const onFindCategoryImg = (category) => {
    return iconList.filter(
      (item) => item?.segmentDescription?.trim() === category?.trim()
    )[0]?.segmentIcon;
  };

  const checkSelectAllDisabled = (type) => {
    if (type === 'select-all')
      return categoryList?.length === searchCategories?.length;

    if (type === 'deselect-all') return categoryList?.length === 0;
  };

  const selectAllDisabled = checkSelectAllDisabled('select-all');
  const deSelectAllDisabled = checkSelectAllDisabled('deselect-all');

  return (
    <Row className='category-list-render'>
      {searchCategories?.length > 0 ? (
        <Checkbox.Group
          value={categoryList}
          onChange={handleSelectSearchCategories}
        >
          {searchCategories.map((category, index) => (
            <Row key={index}>
              <Checkbox value={category}>
                <img
                  src={onFindCategoryImg(category)}
                  alt='icon'
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 4,
                    marginBottom: 2,
                  }}
                />
                {category}
              </Checkbox>
            </Row>
          ))}
        </Checkbox.Group>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      <Row style={{ marginTop: 8 }}>
        <Space>
          <Button
            type='primary'
            onClick={handleSelectAll}
            disabled={selectAllDisabled}
          >
            Select All
          </Button>
          <Button
            type='primary'
            onClick={handleDeselectAll}
            disabled={deSelectAllDisabled}
          >
            Deselect All
          </Button>
        </Space>
      </Row>
    </Row>
  );
};

const CategoriesControl = ({ ...rest }) => {
  const { searchCategoriesEffected } = rest;
  const toggleButton = searchCategoriesEffected?.length > 0;

  return (
    <Dropdown
      className='categories-control'
      overlay={<CategoryListRender {...rest} />}
      placement='bottomLeft'
      arrow
    >
      <RibbonButton
        icon={<AppstoreAddOutlined />}
        label={messages.categories}
        toggle={toggleButton}
      ></RibbonButton>
    </Dropdown>
  );
};

CategoriesControl.propTypes = {};

export default CategoriesControl;
