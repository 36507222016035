import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import './LabelValueWrapper.less';

const LabelValueWrapper = (props) => {
  const { object, parentScoop, colspan, labelSuffix, intl, valueStyle } = props;
  let initialCol = colspan || 8;

  const modifyData = (data) => {
    if (typeof data === 'boolean') {
      switch (data) {
        case true:
          return 'Yes';
        case false:
          return 'No';
      }
    } else return data;
  };

  return (
    <>
      {object &&
        Object.entries(object).map(([key, value]) => {
          return (
            <Row className='label-value-wrapper' key={key}>
              <Col className='label-value-wrapper__label' span={initialCol}>
                {intl.formatMessage({
                  id: `Taco.${parentScoop}.${key}`,
                })}
                {labelSuffix}
              </Col>
              <Col
                className={classnames({
                  'label-value-wrapper__value': true,
                  'label-value-wrapper__value--unset': key === 'download',
                })}
                span={23 - initialCol}
                style={valueStyle}
              >
                {modifyData(value)}
              </Col>
            </Row>
          );
        })}
    </>
  );
};

LabelValueWrapper.propsTypes = {
  object: oneOfType([PropTypes.array, PropTypes.object]),
  parentScoop: PropTypes.string,
  colspan: PropTypes.number,
  labelSuffix: PropTypes.string,
};

export default injectIntl(LabelValueWrapper);
