import React from 'react';
import { useSelector } from 'react-redux';

import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import OpenAPL from './components/sections/product-apl/OpenAPL';

import { RibbonBar, RibbonDivider } from 'common/components';
import AplGridActions from './components/sections/product-apl/AplGridActions';
import ExecuteMatchingGrid from './components/controls/product-apl/ExecuteMatchingGrid';
import DownloadAPL from './components/controls/product-apl/DownloadAPL';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import ShareSection from './components/sections/shared/ShareSection';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

const ProductAPLGrid = () => {
  const selectedItemList = useSelector(
    gridViewSelectors.makeSelectDetailCurrentITemsSelection()
  );
  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabled />
      </Can>

      <RibbonDivider />
      <ShareSection type='APL' selectedItemList={selectedItemList} />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <OpenAPL />
      </Can>

      {/* <OpenAPLSchedule /> */}
      <RibbonDivider />
      <AplGridActions />
      <RibbonDivider />
      <ExecuteMatchingGrid />
      <RibbonDivider />
      <DownloadAPL />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <AdvanceStack isDisabled={true} />
      </Can>
    </RibbonBar>
  );
};

export default ProductAPLGrid;
