import { Row } from 'antd';
import { useRef } from 'react';

import { ADVANCED_FILTER_MODE } from 'pages/reporting/utils/constans';
import DataSwitchButton from 'pages/reporting/components/DataSwitchButton';
import TitleSection from 'pages/reporting/components/TitleSection';
import SearchProperties from 'pages/reporting/components/SearchProperties';

const HeaderEntityAttributes = ({
  type,
  children,
  viewMode,
  modeData,
  onClick,
  handleUpdateSearchEntity,
}) => {
  const refSearchText = useRef();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <TitleSection title={'Entities and Attributes'}>
        <div style={{ pointerEvents: viewMode ? 'none' : 'auto' }}>
          {type === 'product' && (
            <DataSwitchButton
              checked={modeData === ADVANCED_FILTER_MODE.FLAT_DATA}
              onClick={() => onClick(refSearchText)}
            />
          )}
        </div>
      </TitleSection>
      <Row className='advanced-reporting__header-reporting'>
        <SearchProperties
          entityType={type}
          ref={refSearchText}
          handleUpdateSearchEntity={handleUpdateSearchEntity}
        />
      </Row>
      <div style={{ height: 'calc(100% - 88px)' }}>{children}</div>
    </div>
  );
};

export default HeaderEntityAttributes;
