import { ALLERGEN_LIST_ERRORS } from 'pages/qa-spec/constant';
import { getAllRows } from 'pages/qa-spec/utils';

/**
 * ? validate allergen grid
 * @param {*} gridInst
 * @returns
 */
export const validateAllergenGrid = (gridInst) => {
  const allRowData = getAllRows(gridInst);

  const addErrors = (rowItem, errors) => {
    return { ...rowItem, error: [...errors, ...(rowItem?.error || [])] };
  };

  const removeErrors = (rowItem, errors) => {
    const newError = (rowItem?.error || []).filter(
      (errorItem) => !errors.includes(errorItem)
    );
    return { ...rowItem, error: newError };
  };

  const validateLevelOfContainmentRequired = (event, idx) => {
    const rowIndex = event?.rowIndex ?? idx;

    const rowDataItem = allRowData[rowIndex] ?? {};

    const { levelOfContainment, isPresent } = rowDataItem;

    let nextRowDataItem = { ...rowDataItem };

    let nextRowData = [...getAllRows(gridInst)];

    if (
      isPresent === true &&
      (!levelOfContainment || levelOfContainment === '')
    ) {
      nextRowDataItem = addErrors(nextRowDataItem, [
        ALLERGEN_LIST_ERRORS.LEVEL_OF_CONTAINMENT_EMPTY,
      ]);

      nextRowData[rowIndex] = nextRowDataItem;
    } else {
      nextRowDataItem = removeErrors(nextRowDataItem, [
        ALLERGEN_LIST_ERRORS.LEVEL_OF_CONTAINMENT_EMPTY,
      ]);

      nextRowData[rowIndex] = nextRowDataItem;
    }

    gridInst.current.api.applyTransaction({
      update: nextRowData,
    });
  };

  const validateContainmentSourceRequired = (event, idx) => {
    const rowIndex = event?.rowIndex ?? idx;

    const rowDataItem = allRowData[rowIndex] ?? {};

    const { levelOfContainment, containmentSource, isPresent } = rowDataItem;

    let nextRowDataItem = { ...rowDataItem };

    let nextRowData = [...getAllRows(gridInst)];

    if (
      isPresent === true &&
      levelOfContainment === 'May Contain' &&
      !containmentSource
    ) {
      nextRowDataItem = addErrors(nextRowDataItem, [
        ALLERGEN_LIST_ERRORS.CONTAINMENT_SOURCE_EMPTY,
      ]);

      nextRowData[rowIndex] = nextRowDataItem;
    } else {
      nextRowDataItem = removeErrors(nextRowDataItem, [
        ALLERGEN_LIST_ERRORS.CONTAINMENT_SOURCE_EMPTY,
      ]);

      nextRowData[rowIndex] = nextRowDataItem;
    }

    gridInst.current.api.applyTransaction({
      update: nextRowData,
    });
  };

  const checkAllergensGridHaveError = () => {
    const allRowData = getAllRows(gridInst);

    return allRowData.some((rowDataItem) => rowDataItem?.error?.length > 0);
  };

  return {
    validateLevelOfContainmentRequired,
    validateContainmentSourceRequired,
    checkAllergensGridHaveError,
  };
};

export const validateWholeAllergenGrid = (gridInst) => {
  const allRowData = getAllRows(gridInst);

  allRowData.forEach((rowDataItem, idx) => {
    validateAllergenGrid(gridInst).validateLevelOfContainmentRequired(
      null,
      idx
    );
    validateAllergenGrid(gridInst).validateContainmentSourceRequired(null, idx);
  });
};
