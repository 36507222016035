// react import
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMedia } from 'react-media';
import { useHistory } from 'react-router-dom';

// static
import { THEME, THEME_KEY } from './static/Theme';
import { LOCALE_DEFAULT, TIMEZONE_DEFAULT } from './static/Localization';
import { LOCALE_KEY, TIMEZONE_KEY, USER_KEY } from './static/Constants';

// hooks
import { useLocalStorage } from './hooks/localStorage';

// context
import { ThemeContext } from 'context/Theme';
import { LocaleContext } from 'context/Locale';
import { TimezoneContext } from 'context/Timezone';
import { AbilityContext } from 'context/Can';

import LanguageProvider from 'common/provider/Language';
import { defineRulesFor, defineRulesForWorkflow } from 'common/casl/ability';

import { DrapDropPosWrap } from 'common/components';

// redux

import { GLOBAL_MEDIA_QUERIES } from 'static/MediaQuery';

import selectorUser from 'redux/user/selectors';
import * as selectorNav from 'redux/branding/selectors';

// utils
import { initApp } from 'utils/common/init';
import useCloseDetail from 'hooks/useCloseDetail';
import { useInitApp } from 'hooks/useInitApp';

import Routes from 'routes/Routes';
import './App.less';
import 'styles/media.less';
import 'styles/scroller.less';
import 'styles/agGridClient.less';
import 'styles/animation.less';
import 'themes/index';

function App(props) {
  const { messages } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { closeDetailStackingProcess } = useCloseDetail();

  useEffect(() => {
    history.listen((nextLocation) => {
      closeDetailStackingProcess(nextLocation);
    });
  }, []);

  // theme support
  const [theme, setTheme] = useLocalStorage(THEME_KEY, THEME.LIGHT);
  function toggleTheme(newTheme) {
    setTheme(newTheme);
  }

  // locale support
  const [locale, setLocale] = useLocalStorage(LOCALE_KEY, LOCALE_DEFAULT);
  const changeLocale = (newLocale) => {
    setLocale(newLocale);
  };

  // multiple timezone support
  const [timezone, setTimezone] = useLocalStorage(
    TIMEZONE_KEY,
    TIMEZONE_DEFAULT
  );

  const changeTimezone = (newTimezone) => {
    setTimezone(newTimezone);
  };

  const ability = useContext(AbilityContext);

  // eslint-disable-next-line
  const [user, setUser] = useLocalStorage(USER_KEY);

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const workflowRoles = useSelector(selectorUser.makeSelectWorkflowRoles());
  const navigationData = useSelector(selectorNav.getBranding());

  useEffect(() => {
    // validateBrandingCallback();
    if (!userInfo || !navigationData) return;
    const permissionRules = defineRulesFor(userInfo, navigationData);
    const workflowRules = defineRulesForWorkflow(
      userInfo,
      workflowRoles,
      navigationData
    );
    const rules = [...permissionRules, ...workflowRules];
    ability.update(rules);
  }, [
    userInfo,
    ability,
    workflowRoles,
    navigationData,
    // validateBrandingCallback
  ]);

  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  useEffect(() => {
    if (matches.xs || matches.md || matches.lg) {
    }
  });

  useEffect(() => {
    initApp({ dispatch });
  }, [dispatch]);

  useInitApp();

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <LocaleContext.Provider value={{ locale, changeLocale }}>
        <LanguageProvider messages={messages}>
          <TimezoneContext.Provider value={{ timezone, changeTimezone }}>
            <DrapDropPosWrap>
              <Routes />
            </DrapDropPosWrap>
          </TimezoneContext.Provider>
        </LanguageProvider>
      </LocaleContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
