import React from 'react';
import { useDispatch } from 'react-redux';

import { Menu } from 'antd';
import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import * as chatActions from '../../controller/actions';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/chat';

const NewChatOptions = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onClickMenu = ({ key }) => {
    const actionByKey = {
      user: chatActions.showCreateNewChatWithUser,
      company: chatActions.showCreateNewChatWithCompany,
    };

    dispatch(actionByKey[key](true));
  };

  return (
    <Menu onClick={onClickMenu}>
      <Menu.Item key='user' icon={<UserAddOutlined />}>
        {intl.formatMessage(Messages.createNewChatUser)}
      </Menu.Item>
      <Menu.Item key='company' icon={<UsergroupAddOutlined />}>
        {intl.formatMessage(Messages.createNewChatCompany)}
      </Menu.Item>
    </Menu>
  );
};

export default NewChatOptions;
