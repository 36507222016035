import React from 'react';

import { Row, Col, Pagination } from 'antd';

import classnames from 'classnames';

import './SearchGridItemPagination.less';

const PAGE_SIZE = 20;

const SearchGridItemPagination = (props) => {
  const { pageInfo, onChangePagination, isLoading } = props;

  return (
    <Row>
      <Col
        className={classnames('search-grid-item-pagination', {
          'search-grid-item-pagination--loading': isLoading,
        })}
      >
        <Pagination
          current={pageInfo?.currentPageIndex}
          total={pageInfo?.totalRecord}
          pageSize={PAGE_SIZE}
          showSizeChanger={false}
          onChange={onChangePagination}
          disabled={isLoading}
        />
        <div className='search-grid-item-pagination__number-record'>
          {pageInfo?.totalRecord} <span>items found</span>
        </div>
      </Col>
    </Row>
  );
};

export default SearchGridItemPagination;
