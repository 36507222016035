import React, { useMemo, useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import classnames from 'classnames';
import { Select, Divider, Typography, Badge, Tooltip } from 'antd';
import { Form, WithLoading, WrapperSelect } from 'common/components';

import {
  FormItemBoolean,
  FormItemNumber,
  FormItemList,
  FormItemText,
  FormItemDatetime,
  FormItemContainer,
  FormItemTextArea,
} from './ShareComponents';

import { useFormContext } from './FormContext';

import { useScrollToFoundModule } from './hooks';

import {
  findProductModule,
  findDataProperties,
  findProductPropertyEnums,
  formatObjectToCheckValueDatetime,
  checkRenderTextArea,
  filterNullOrUndefinedValue,
  disabledFormSelect,
  splitStringByUnderscore,
  getNewAllergenForm,
  getFormItemRule,
} from './utils';

import sortByKey from 'utils/sortByKey';

import { CHARACTERS_LIMIT } from '../../constants';

const layoutForm = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const NewFormEditProperties = React.memo((props) => {
  const {
    editableModuleName,
    productSchema,
    productEnums,
    formInstance,
    productItemModules,
    errorInfo,
  } = props;

  const refScroll = useRef();

  const [isLoadingError, setIsLoadingError] = useState(false);

  const { searchModuleName, setSyncAllergenData } = useFormContext();

  useScrollToFoundModule({
    foundModule: searchModuleName,
    elementByClassName: document.getElementsByClassName(
      'product-detail-view__label-form-item--found-field'
    ),
  });

  const { groupModule } = useMemo(() => {
    return findProductModule(editableModuleName, productSchema);
  }, [editableModuleName, productSchema]);

  const foundDataProperties = useMemo(() => {
    return findDataProperties(editableModuleName, productItemModules);
  }, [editableModuleName, productItemModules]);

  const handleScrollToError = (fieldErrorName) => {
    if (!fieldErrorName) return setIsLoadingError(false);

    const scroll = refScroll.current;
    const viewHeight = scroll.offsetHeight;

    const $fieldError = document.getElementById(
      'form-edit-properties_' + fieldErrorName
    );

    const $fieldListError = document.getElementById(
      '#form-list__' + fieldErrorName
    );

    const $fieldErrorExist = $fieldError || $fieldListError;

    if (!$fieldErrorExist) return setIsLoadingError(false);

    const $fieldErrorRect = $fieldErrorExist.getBoundingClientRect();
    const $refScrollRect = refScroll.current.getBoundingClientRect();

    scroll.scrollTop =
      $fieldErrorRect.top - $refScrollRect.top - viewHeight / 2;

    setIsLoadingError(false);
  };

  useEffect(() => {
    const validateFields = async () => {
      if (!errorInfo) return;

      await formInstance.setFields([
        {
          name: errorInfo?.fieldName,
          errors: [`[Publish Product Error] - ${errorInfo?.errorMessage}`],
        },
      ]);

      const allFieldsError = await formInstance.getFieldsError();

      const fieldError = allFieldsError.filter(
        (field) => field?.errors?.length > 0
      );
      const fieldErrorName = fieldError?.[0]?.name?.[0];

      handleScrollToError(fieldErrorName);
    };

    if (errorInfo && errorInfo?.moduleName === editableModuleName) {
      const DELAY_TIME_FORM = 2000;

      setIsLoadingError(true);

      setTimeout(() => {
        validateFields();
      }, DELAY_TIME_FORM);
    }
  }, [editableModuleName, errorInfo, formInstance]);

  useEffect(() => {
    if (editableModuleName) {
      const newFormatData =
        formatObjectToCheckValueDatetime(foundDataProperties);

      if (isEmpty(newFormatData)) return;

      let filteredFormData = filterNullOrUndefinedValue(newFormatData);

      if (editableModuleName === 'AllergenInformation') {
        setSyncAllergenData(filteredFormData);

        const newAllerForm = getNewAllergenForm(filteredFormData);
        formInstance.setFieldsValue(newAllerForm);
      } else {
        formInstance.setFieldsValue(filteredFormData);
      }
    }
  }, [
    editableModuleName,
    formInstance,
    foundDataProperties,
    setSyncAllergenData,
  ]);

  const handleChangeValueAllergen = (value, propertyName) => {
    if (editableModuleName !== 'AllergenInformation') return;

    const [, lastText] = splitStringByUnderscore(propertyName);
    const allergenProperty = `Allergen_${lastText}`;
    const codeProperty = `LevelOfContainmentCode_${lastText}`;
    const containmentSource = `ContainmentSource_${lastText}`;

    if (propertyName === allergenProperty) {
      const allergenValues = {
        [propertyName]: value,
        [codeProperty]: null,
        [containmentSource]: null,
      };

      if (value === false) {
        setSyncAllergenData((prevData) => ({
          ...prevData,
          ...allergenValues,
        }));

        formInstance.setFieldsValue({
          ...formInstance.getFieldsValue(),
          ...allergenValues,
        });
      } else if (value === true) {
        setSyncAllergenData((prevData) => ({
          ...prevData,
          ...allergenValues,
        }));
        formInstance.setFieldsValue({
          ...formInstance.getFieldsValue(),
          ...allergenValues,
        });
      }
    }

    if (propertyName === codeProperty) {
      let newValues = {
        [codeProperty]: value,
      };

      if (value !== 'May Contain') {
        newValues = { ...newValues, [containmentSource]: undefined };
      }

      setSyncAllergenData((prevData) => ({
        ...prevData,
        ...newValues,
      }));
      formInstance.setFieldsValue({
        ...formInstance.getFieldsValue(),
        ...newValues,
      });
    }

    if (propertyName === containmentSource) {
      setSyncAllergenData((prevData) => ({
        ...prevData,
        [containmentSource]: value,
      }));
      formInstance.setFieldsValue({
        ...formInstance.getFieldsValue(),
        [containmentSource]: value,
      });
    }
  };

  return (
    <WithLoading loading={isLoadingError} tip='Search for error field ...'>
      <div
        ref={refScroll}
        className={classnames(
          'product-detail-view__form-container',
          'scroller'
        )}
      >
        <Form
          {...layoutForm}
          form={formInstance}
          name='form-edit-properties'
          className={classnames('product-detail-view__form-item')}
          scrollToFirstError
        >
          <GroupFormItem
            moduleProperties={groupModule?.moduleProperties}
            productEnums={productEnums}
            onChangeValueAllergen={handleChangeValueAllergen}
          />
        </Form>
      </div>
    </WithLoading>
  );
});

export const GroupFormItem = ({
  moduleProperties = {},
  productEnums,
  onChangeValueAllergen,
}) => {
  const { syncAllergenData } = useFormContext();

  const keyModuleProperties = Object.keys(moduleProperties) ?? [];
  // ['null']
  const isNotGroupModule =
    keyModuleProperties.length === 1 &&
    keyModuleProperties.every((value) => value === 'null');

  return (
    <>
      {Object.entries(moduleProperties)?.map(([titleGroup, listProperties]) => (
        <NewFormItemContainer
          title={titleGroup === 'null' ? 'Other' : titleGroup}
          key={titleGroup}
          numberOfFields={listProperties?.length ?? 0}
          isNotGroupModule={isNotGroupModule}
        >
          {listProperties.map((property) => (
            <RenderFormItem
              property={property}
              productEnums={productEnums}
              onChangeValueAllergen={onChangeValueAllergen}
              disabledSelect={disabledFormSelect(
                property.propertyName,
                syncAllergenData
              )}
              rules={getFormItemRule(property)}
            />
          ))}
        </NewFormItemContainer>
      ))}
    </>
  );
};

export const NonGroupFormItem = ({
  moduleProperties,
  productEnums,
  onChangeValueAllergen,
}) => {
  const { syncAllergenData } = useFormContext();

  return (
    <>
      {moduleProperties?.map((property) => (
        <RenderFormItem
          property={property}
          productEnums={productEnums}
          onChangeValueAllergen={onChangeValueAllergen}
          disabledSelect={disabledFormSelect(
            property.propertyName,
            syncAllergenData
          )}
        />
      ))}
    </>
  );
};

export const NewFormItemContainer = ({
  title,
  numberOfFields,
  isNotGroupModule,
  children,
}) => {
  return (
    <>
      <div style={{ padding: '10px 10px 5px 60px' }}>
        {isNotGroupModule ? null : (
          <>
            <Tooltip title='Total of properties' placement='top'>
              <Badge
                count={numberOfFields}
                className='product-detail-view__badge'
              >
                <Typography.Title level={5}>{title}</Typography.Title>
              </Badge>
            </Tooltip>
            <Divider style={{ margin: '5px 0' }} />
          </>
        )}
      </div>
      {children}
    </>
  );
};

export const RenderNewFormItem = ({
  property,
  productEnums,
  onChangeValueAllergen = () => {},
  disabledSelect = false,
}) => {
  if (property.listName) {
    const propertyEnums = findProductPropertyEnums(
      property.listName,
      productEnums
    );

    return (
      <FormItemContainer property={property} key={property.propertyName}>
        <WrapperSelect
          showSearch
          mode={property.isArray ? 'multiple' : ''}
          placeholder={`Please Select ${property.propertyDisplayName}`}
          filterOption={(input, option) =>
            option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          onChange={(value) =>
            onChangeValueAllergen(value, property.propertyName)
          }
          disabled={disabledSelect}
        >
          {sortByKey(propertyEnums, 'enumDisplayName')?.map(
            (property, index) => (
              <Select.Option
                value={property.enumDisplayName}
                key={property.enumDisplayName + index}
              >
                {property.enumDisplayName
                  ? property.enumDisplayName
                  : property.enumDescription}
              </Select.Option>
            )
          )}
        </WrapperSelect>
      </FormItemContainer>
    );
  }

  if (property.isArray) {
    if (numericType.includes(property.propertyType)) {
      return <FormItemList property={property} variant='number' />;
    }
    return <FormItemList property={property} key={property.propertyName} />;
  }

  if (property.propertyType === 'string') {
    const maxLength =
      CHARACTERS_LIMIT[`${property.propertyName}`]?.maxLength ?? null;

    if (checkRenderTextArea(property.propertyDisplayName, maxLength)) {
      return (
        <FormItemTextArea
          maxLength={maxLength}
          property={property}
          key={property.propertyName}
        />
      );
    }

    return (
      <FormItemText
        maxLength={maxLength}
        property={property}
        key={property.propertyName}
      />
    );
  }

  if (property.propertyType === 'boolean') {
    return (
      <FormItemBoolean
        property={property}
        valuePropName='checked'
        key={property.propertyName}
      />
    );
  }

  if (numericType.includes(property.propertyType)) {
    return <FormItemNumber property={property} key={property.propertyName} />;
  }

  if (property.propertyType === 'datetime') {
    return <FormItemDatetime property={property} key={property.propertyName} />;
  }

  return null;
};

const FormEditProperties = (props) => {
  const {
    editableModuleName,
    productSchema,
    productEnums,
    formInstance,
    productItemModules,
  } = props;

  const foundDataModule = useMemo(() => {
    return findProductModule(editableModuleName, productSchema);
  }, [editableModuleName, productSchema]);

  const foundDataProperties = useMemo(() => {
    return findDataProperties(editableModuleName, productItemModules);
  }, [editableModuleName, productItemModules]);

  useEffect(() => {
    if (editableModuleName) {
      const newFormatData =
        formatObjectToCheckValueDatetime(foundDataProperties);

      if (isEmpty(newFormatData)) return;

      let filteredFormData = filterNullOrUndefinedValue(newFormatData);
      formInstance.setFieldsValue(filteredFormData);
    }
  }, [editableModuleName, formInstance, foundDataProperties]);

  return (
    <div
      className={classnames('scroller', 'product-detail-view__form-container')}
    >
      <Form
        {...layoutForm}
        form={formInstance}
        name='form-edit-properties'
        id='form-edit-properties'
        className='product-detail-view__form-item'
      >
        {foundDataModule?.moduleProperties?.map((property) => (
          <RenderFormItem property={property} productEnums={productEnums} />
        ))}
      </Form>
    </div>
  );
};

const numericType = ['int32', 'double', 'decimal'];

export const RenderFormItem = ({
  property = {},
  productEnums,
  onChangeValueAllergen = () => {},
  disabledSelect = false,
  rules,
}) => {
  if (property.listName) {
    const propertyEnums = findProductPropertyEnums(
      property.listName,
      productEnums
    );

    return (
      <FormItemContainer
        property={property}
        key={property.propertyName}
        rules={rules}
      >
        <WrapperSelect
          getPopupContainer={() =>
            document.getElementById('form-edit-properties')
          }
          showSearch
          mode={property.isArray ? 'multiple' : ''}
          placeholder={`Please Select ${property.propertyDisplayName}`}
          filterOption={(input, option) =>
            option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          onChange={(value) =>
            onChangeValueAllergen(value, property.propertyName)
          }
          disabled={disabledSelect}
        >
          {sortByKey(propertyEnums, 'enumDisplayName')?.map(
            (property, index) => (
              <Select.Option
                value={property.enumDisplayName}
                key={property.enumDisplayName + index}
              >
                {property.enumDisplayName
                  ? property.enumDisplayName
                  : property.enumDescription}
              </Select.Option>
            )
          )}
        </WrapperSelect>
      </FormItemContainer>
    );
  }

  if (property.isArray) {
    if (numericType.includes(property.propertyType)) {
      return <FormItemList property={property} variant='number' />;
    }
    return <FormItemList property={property} key={property.propertyName} />;
  }

  if (property.propertyType === 'string') {
    const maxLength =
      CHARACTERS_LIMIT[`${property.propertyName}`]?.maxLength ?? null;

    if (checkRenderTextArea(property.propertyDisplayName, maxLength)) {
      return (
        <FormItemTextArea
          maxLength={maxLength}
          property={property}
          key={property.propertyName}
        />
      );
    }

    return (
      <FormItemText
        maxLength={maxLength}
        property={property}
        key={property.propertyName}
      />
    );
  }

  if (property.propertyType === 'boolean') {
    return (
      <FormItemBoolean
        property={property}
        valuePropName='checked'
        key={property.propertyName}
        onChange={(event) =>
          onChangeValueAllergen(event.target.checked, property.propertyName)
        }
      />
    );
  }

  if (numericType.includes(property.propertyType)) {
    const maxLength =
      CHARACTERS_LIMIT[`${property.propertyName}`]?.maxLength ?? null;

    return (
      <FormItemNumber
        property={property}
        key={property.propertyName}
        maxLength={maxLength}
      />
    );
  }

  if (property.propertyType === 'datetime') {
    return <FormItemDatetime property={property} key={property.propertyName} />;
  }

  return null;
};

export default FormEditProperties;
