import React from 'react';
import { Row, Col, Input, Space, Button, Tree, Empty } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

import classnames from 'classnames';

import { WithLoading } from 'common/components';
import useProductCategoryTree from './useProductCategoryTree';
import './ProductCategoryTree.less';

const ProductCategoryTree = ({
  mode,
  onSelect,
  isLoading,
  nestedCategory,
  setEmptyCategory,
  bulkReassignFormName,
  showEmptyCategory = false,
  setBulkReassignFormName
}) => {
  const {
    handleSearch,
    filterTree,
    handleExpandAll,
    handleExpand,
    handleSelected,
    expandedKeys,
    setExpandedKeys,
    keySelectedMapping,
  } = useProductCategoryTree({
    nestedCategory,
    onSelect,
    mode,
    bulkReassignFormName,
    setBulkReassignFormName
  });

  return (
    <Row gutter={[4, 0]} align='top' style={{ height: '100%' }}>
      <Col span={24} style={{ marginBottom: '5px' }}>
        <Input.Search
          onSearch={(value) => handleSearch(value)}
          allowClear
          placeholder='Input search text'
        />
      </Col>
      <Col span={24}>
        <Space>
          <Button type='primary' onClick={handleExpandAll}>
            Expand All
          </Button>
          <Button type='primary' onClick={() => setExpandedKeys([])}>
            Collapse All
          </Button>
        </Space>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: '8px',
          height: 'calc(100% - 90px)',
          overflow: 'hidden',
        }}
      >
        <div style={{ height: '100%' }} className='scroller scroller--y'>
          <WithLoading loading={isLoading}>
            {showEmptyCategory && (
              <div className='category-tree_blank'>
                <Button
                  icon={<ClearOutlined />}
                  size='small'
                  type='link'
                  onClick={setEmptyCategory}
                >
                  Empty Category
                </Button>
              </div>
            )}

            {nestedCategory && filterTree?.length > 0 ? (
              <Tree
                showLine={true}
                onSelect={handleSelected}
                expandedKeys={expandedKeys}
                onExpand={handleExpand}
                treeData={filterTree}
                selectedKeys={keySelectedMapping}
                className={classnames({
                  'category-tree': true,
                  'category-tree__bulk-assign': mode === 'bulkReassignProducts',
                })}
              />
            ) : (
              <Empty className='category-tree__empty' />
            )}
          </WithLoading>
        </div>
      </Col>
    </Row>
  );
};

export default ProductCategoryTree;
