import React from 'react';
import { Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const SelectedProductList = ({ productList, handleRemoveActiveProduct }) => {
  const mappingListUpload = productList;

  const COLUMNS_TABLE = [
    {
      title: 'Product Name',
      dataIndex: 'productName',
      render: (productName, record) => {
        return (
          <>
            <a
              href={`/product/${record?.id || record?.productId}`}
              target='_blank'
            >
              {productName}
            </a>
          </>
        );
      },
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
    },
    {
      title: 'RIVIR ID',
      dataIndex: 'rivirId',
    },
    {
      title: 'Category I',
      dataIndex: 'category1Name',
    },
    {
      title: 'Category II',
      dataIndex: 'category2Name',
    },
    {
      title: 'Category III',
      dataIndex: 'category3Name',
    },
    {
      title: 'Category IV',
      dataIndex: 'category4Name',
    },
    {
      title: '',
      render: (_, record) => (
        <span
          onClick={() => handleRemoveActiveProduct(record)}
          style={{ cursor: 'pointer', color: '#ff4d4f' }}
        >
          <DeleteOutlined />
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={COLUMNS_TABLE}
        dataSource={mappingListUpload}
        pagination={false}
      />
    </div>
  );
};

export default SelectedProductList;
