import React, { useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import SelectCategory from 'pages/home/ribbon/components/sections/product/SelectCategory';
import { CATEGORY_PRODUCT_LIST } from 'pages/qa-spec/constant';

import { generateIdsBySelectedKey } from 'pages/company-profile/components/tabs/system/category-management/utils';

import { useCheckPermissions } from 'hooks';

import { SHOULD_ASSIGN_PRODUCT_CATEGORY } from 'static/Permission';

export const ProductCategory = ({ setProductCategory }) => {
  const form = Form.useFormInstance();

  const [visibleProductCategory, setVisibleProductCategory] = useState();

  const hasPermissionAssignCategory = useCheckPermissions(
    SHOULD_ASSIGN_PRODUCT_CATEGORY
  );

  const handleSelectedCategory = (node, formValue) => {
    const {
      category1: category1Id,
      category2: category2Id,
      category3: category3Id,
      category4: category4Id,
    } = formValue;

    let params = {};

    if (node) {
      const categoryList = generateIdsBySelectedKey(node?.key);
      for (let i = 0; i < categoryList.length; i++) {
        params[`category${i + 1}Id`] = categoryList[i];
      }
    } else {
      for (let i = 0; i < 4; i++) {
        params[`category${i + 1}Id`] = null;
      }
    }

    setProductCategory(params);

    form.setFieldsValue({
      ...form.getFieldsValue(),
      category1Id,
      category2Id,
      category3Id,
      category4Id,
    });
  };

  return (
    <div>
      {CATEGORY_PRODUCT_LIST.map((item) => {
        return (
          <Form.Item
            key={item.id}
            label={item.label}
            style={{ marginBottom: '6px' }}
            name={item.value}
          >
            <Input disabled />
          </Form.Item>
        );
      })}

      <Row>
        <Col offset={8}>
          <Button
            size='default'
            onClick={() => setVisibleProductCategory(true)}
            disabled={!hasPermissionAssignCategory}
          >
            Select Category
          </Button>
        </Col>
      </Row>

      <SelectCategory
        showEmptyCategory
        visible={visibleProductCategory}
        setVisible={setVisibleProductCategory}
        onClick={handleSelectedCategory}
      />
    </div>
  );
};
