import React from 'react';
import { useSelector } from 'react-redux';

import { FolderOpenOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as folderSelectors from 'pages/folders/controllers/selectors';

import { forwardTo } from 'utils/common/route';

import Messages from 'i18n/messages/home';

const OpenFolder = () => {
  const selectedItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const selectedFolder =
    selectedItems.length === 1 &&
    selectedItems[0].type.toLowerCase() === 'folder'
      ? selectedItems[0]
      : null;

  const { folderBreadcrumb } = useSelector(
    folderSelectors.selectFolderListByLevel()
  );

  const onClickOpenFolderDetail = () => {
    forwardTo(`/folder/${folderId}`);
  };

  const getFolderId = () => {
    //* no select anything
    if (!selectedItems.length) {
      return currentFolder?.id === 'home' ? null : currentFolder?.id;
    }

    if (selectedFolder)
      //* select folder
      return selectedFolder.id;

    //* select another item type, prevent opening
    if (selectedItems.length > 0 && !selectedFolder) return null;
  };

  const currentFolder = folderBreadcrumb[folderBreadcrumb?.length - 1];
  const folderId = getFolderId();

  return (
    <RibbonButton
      icon={<FolderOpenOutlined />}
      label={Messages.openFolder}
      onClick={onClickOpenFolderDetail}
      disabled={!folderId}
    />
  );
};

export default OpenFolder;
