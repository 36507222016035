import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  error: false,
  assets: [],
  total: 0,
  pageSize: 20,
  pageNumber: 1,
  search: '',
  columns: [],
  gridName: '',
  assetData: null,
  loadingDetail: false,
  statusDelete: 'idle',
  cachedImagesVersion: {},

  assetsForMember: {
    loading: false,
    pageIndex: 1,
    pageSize: 20,
    totalItems: 0,
    data: [],
    columns: [],
    memberId: null,
  },
  editTypeLoading: false,
  isDisplayAssetNew: false,
  cachedFromDate: null,
};

/* eslint-disable default-case, no-param-reassign */
const assetReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SHOW_LOADING:
        draft.loading = true;
        break;
      case types.GET_DIGITAL_ASSET_LIST:
        draft.loading = true;
        draft.error = false;
        draft.pageSize = action.pageSize;
        draft.pageNumber = action.pageIndex;
        draft.search = action.search;
        break;
      case types.GET_DIGITAL_ASSET_LIST_SUCCESS:
        draft.loading = false;
        draft.assets = action.assets;
        draft.total = action.total;
        draft.statusDelete = 'idle';
        break;
      case types.GET_DIGITAL_ASSET_LIST_ERROR:
        draft.loading = false;
        draft.total = 0;
        draft.assets = [];
        draft.error = action.error;
        draft.statusDelete = 'idle';
        break;
      case types.DIGITAL_ASSET_GRID_COLUMN_INFO:
        draft.loading = true;
        draft.error = false;
        draft.gridName = action.gridName;
        break;
      case types.DIGITAL_ASSET_GRID_COLUMN_INFO_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;
      case types.DIGITAL_ASSET_GRID_COLUMN_INFO_ERROR:
        draft.loading = false;
        break;
      case types.GET_DIGITAL_ASSET_SHORT_DETAIL:
        draft.loadingDetail = true;
        draft.error = false;
        break;
      case types.GET_DIGITAL_ASSET_SHORT_DETAIL_SUCCESS:
        draft.loadingDetail = false;
        draft.assetData = action.data;
        break;
      case types.GET_DIGITAL_ASSET_SHORT_DETAIL_ERROR:
        draft.loadingDetail = false;
        draft.error = action.error;
        break;
      case types.UPDATE_CACHE_IMAGE:
        draft.cachedImagesVersion = action.payload;
        break;
      case types.DELETE_ASSETS:
        draft.statusDelete = 'loading';
        break;
      case types.DELETED_ASSETS_SUCCESS:
        draft.statusDelete = 'success';
        draft.assetData = null;
        break;
      case types.DELETED_ASSETS_ERROR:
        draft.statusDelete = 'error';
        break;
      case types.RESET_DELETE_ASSETS:
        draft.statusDelete = 'idle';
        break;

      case types.GET_ASSETS_FOR_MEMBER:
        draft.assetsForMember['loading'] = true;
        break;

      case types.GET_ASSETS_FOR_MEMBER_SUCCESS:
        draft.assetsForMember['loading'] = false;
        draft.assetsForMember['data'] = action.data.gridData;
        draft.assetsForMember['pageIndex'] =
          action.data.paging.currentPageIndex;
        draft.assetsForMember['pageSize'] = action.data.paging.currentPageSize;
        draft.assetsForMember['totalItems'] = action.data.paging.totalRecord;
        break;

      case types.GET_COLUMNS_ASSETS_FOR_MEMBER_SUCCESS:
        draft.assetsForMember['columns'] = action.columns;
        break;

      case types.SAVE_ID_MEMBER:
        draft.assetsForMember['memberId'] = action.id;
        break;
      case types.UPDATE_ASSET_TYPE_PANE:
        draft.editTypeLoading = true;
        break;
      case types.UPDATE_ASSET_TYPE_PANE_SUCCESS:
        draft.editTypeLoading = false;
        break;
      case types.UPDATE_ASSET_TYPE_PANE_ERROR:
        draft.editTypeLoading = false;
        break;
      case types.TOGGLE_ASSET_NEW:
        draft.isDisplayAssetNew = action.isDisplayAssetNew;
        break;
      case types.CACHE_ASSET_FROM_DATE:
        draft.cachedFromDate = action.fromDate;
        break;
    }
  });

export default assetReducer;
