import { defineMessages } from 'react-intl';

export const scope = 'Taco.qaSpec';

export default defineMessages({
  productSpecDateCoding: {
    title: {
      id: `${scope}.components.productSpec.dateCoding.title`,
      defaultMessage: 'Date  Coding',
    },
    codeDateStamp: {
      id: `${scope}.components.productSpec.dateCoding.label.openClose`,
      defaultMessage: 'Date Code – Open / Closed',
    },
    codeDateType: {
      id: `${scope}.components.productSpec.dateCoding.label.type`,
      defaultMessage: 'Date Code Type',
    },
    codeDateFormula: {
      id: `${scope}.components.productSpec.dateCoding.label.formula`,
      defaultMessage: 'Date Code Formula',
    },
    codeDateExample: {
      id: `${scope}.components.productSpec.dateCoding.label.example`,
      defaultMessage: 'Date Code Example',
    },
    batchCodeExample: {
      id: `${scope}.components.productSpec.dateCoding.label.batchCodeExample`,
      defaultMessage: 'Batch Code Example',
    },
    lotCodeExample: {
      id: `${scope}.components.productSpec.dateCoding.label.lotCodeExample`,
      defaultMessage: 'Lot Code Example',
    },
  },
  productSpecCaseTemperature: {
    title: {
      id: `${scope}.components.productSpec.caseTemperature.title`,
      defaultMessage: 'Case Temperatures',
    },
    caseTemperatureRangeHigh: {
      id: `${scope}.components.productSpec.caseTemperature.label.rangeHigh`,
      defaultMessage: 'Case Temperature Range High',
    },
    caseTemperatureRangeLow: {
      id: `${scope}.components.productSpec.caseTemperature.label.rangeLow`,
      defaultMessage: 'Case Temperature Range Low',
    },
    caseShippingTemperatureHigh: {
      id: `${scope}.components.productSpec.caseTemperature.label.shippingHigh`,
      defaultMessage: 'Case Shipping Temperature High',
    },
    caseShippingTemperatureLow: {
      id: `${scope}.components.productSpec.caseTemperature.label.shippingLow`,
      defaultMessage: 'Case Shipping Temperature Low',
    },
  },
  polyfluoroalkylSubstances: {
    title: {
      id: `${scope}.components.productSpec.polyfluoroalkylSubstances.title`,
      defaultMessage: 'polyfluoroalkyl substances (PFAS)',
    },
    productContainPfas: {
      id: `${scope}.components.productSpec.polyfluoroalkylSubstances.title.productContainPfas`,
      defaultMessage: `Does this Product Intentionally Contain PFAS?`,
    },
    productPackagingContainPfas: {
      id: `${scope}.components.productSpec.polyfluoroalkylSubstances.label.productPackagingContainPfas`,
      defaultMessage: `Does this Product's Packaging Intentionally Contain PFAS?`,
    },
    pfasTested: {
      id: `${scope}.components.productSpec.polyfluoroalkylSubstances.label.pfasTested`,
      defaultMessage: 'PFAS Tested?',
    },
    pfasStatement: {
      id: `${scope}.components.productSpec.polyfluoroalkylSubstances.label.pfasStatement`,
      defaultMessage: 'PFAS Statement if Appropriate',
    },
    supportingDocumentation: {
      id: `${scope}.components.productSpec.polyfluoroalkylSubstances.label.supportingDocumentation`,
      defaultMessage: 'Supporting Documentation',
    },
  },
  productSpecShelfLife: {
    title: {
      id: `${scope}.components.productSpec.shelfLife.title`,
      defaultMessage: 'Shelf Life',
    },
    minimumShelfLife: {
      id: `${scope}.components.productSpec.shelfLife.label.minimumShelfLife`,
      defaultMessage: 'Min Shelf Life At Production, In Days',
    },
    maximumShelfLife: {
      id: `${scope}.components.productSpec.shelfLife.label.maximumShelfLife`,
      defaultMessage: 'Max Shelf Life At Production, In Days',
    },
    shelfLifeAfterOpening: {
      id: `${scope}.components.productSpec.shelfLife.label.shelfLifeAfterOpening`,
      defaultMessage: 'Shelf Life After Opening, In Days',
    },
  },
  productSpecDietaryCertifications: {
    title: {
      id: `${scope}.components.productSpec.dietaryCertifications.title`,
      defaultMessage: 'Dietary Certifications',
    },
    kosherYN: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.kosherYN`,
      defaultMessage: 'Kosher',
    },
    kosherCertifier: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.kosherCertifier`,
      defaultMessage: 'Certifier',
    },
    organicYN: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.organicYN`,
      defaultMessage: 'Organic',
    },
    organicCertifier: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.organicCertifier`,
      defaultMessage: 'Certifier',
    },
    halalYN: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.halalYN`,
      defaultMessage: 'Halal',
    },
    halalCertifier: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.halalCertifier`,
      defaultMessage: 'Certifier',
    },
    nonGMOYN: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.nonGMOYN`,
      defaultMessage: 'Non-GMO',
    },
    nonGMOCertifier: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.nonGMOCertifier`,
      defaultMessage: 'Certifier',
    },
    isoyn: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.isoyn`,
      defaultMessage: 'ISO',
    },
    isoCertifier: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.isoCertifier`,
      defaultMessage: 'Certifier',
    },
    brcyn: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.brcyn`,
      defaultMessage: 'BRC',
    },
    brcCertifier: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.brcCertifier`,
      defaultMessage: 'Certifier',
    },
    ifsyn: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.ifsyn`,
      defaultMessage: 'IFS',
    },
    ifsCertifier: {
      id: `${scope}.components.productSpec.dietaryCertifications.label.ifsCertifier`,
      defaultMessage: 'Certifier',
    },
  },
  productSpecStatement: {
    title: {
      id: `${scope}.components.productSpec.statement.title`,
      defaultMessage: 'Statement',
    },
    gmoStatement: {
      id: `${scope}.components.productSpec.statement.label.gmoStatement`,
      defaultMessage: 'GMO Statement',
    },
    nonGmoStatement: {
      id: `${scope}.components.productSpec.statement.label.nonGmoStatement`,
      defaultMessage: 'Non-GMO Statement',
    },
    productContainsBioengineeredIngredients: {
      id: `${scope}.components.productSpec.statement.label.productContainsBioengineeredIngredients`,
      defaultMessage: 'Product Contains Bioengineered Ingredients',
    },
    bioengineeredStatement: {
      id: `${scope}.components.productSpec.statement.label.bioengineeredStatement`,
      defaultMessage: 'Bioengineered Ingredients Statement',
    },
    nonfoodIngredientStatement: {
      id: `${scope}.components.productSpec.statement.label.nonfoodIngredientStatement`,
      defaultMessage: 'Nonfood Ingredient Statement',
    },
    rbghBstStatement: {
      id: `${scope}.components.productSpec.statement.label.rbghBstStatement`,
      defaultMessage: 'RBGH/BST Statement',
    },
    californiaProp65WarningStatement: {
      id: `${scope}.components.productSpec.statement.label.californiaProp65WarningStatement`,
      defaultMessage: 'California Prop 65 Warning Statement',
    },
    fairTradeStatement: {
      id: `${scope}.components.productSpec.statement.label.fairTradeStatement`,
      defaultMessage: 'Fair Trade Statement',
    },
    usdaStatement: {
      id: `${scope}.components.productSpec.statement.label.usdaStatement`,
      defaultMessage: 'USDA Statement',
    },
  },
  productSpecLocation: {
    title: {
      id: `${scope}.components.productSpec.location.title`,
      defaultMessage: 'Locations',
    },
    countryOfOrigin: {
      id: `${scope}.components.productSpec.location.label.countryOfOrigin`,
      defaultMessage: 'Country of Origin',
    },
    targetMarket: {
      id: `${scope}.components.productSpec.location.label.targetMarket`,
      defaultMessage: 'Target Market',
    },
  },
  productSpecSaveInfoSuccess: {
    id: `${scope}.components.productSpec.message.saveInfo.success`,
    defaultMessage: 'Update Product Specification data successfully!',
  },
  productSpecSaveInfoError: {
    id: `${scope}.components.productSpec.message.saveInfo.error`,
    defaultMessage: 'Cannot update Product Specification',
  },
  countryOfOriginLabel: {
    id: `${scope}.components.productSpec.label.countryOfOrigin`,
    defaultMessage: 'Country of Origin',
  },
  USDA_WIC_eligible_label: {
    id: `${scope}.components.productSpec.label.USDA_WIC_eligible_label`,
    defaultMessage: 'USDA WIC Eligible',
  },
  productSpecSaveOrganolepticSuccess: {
    id: `${scope}.components.productSpec.message.saveOrganoleptic.success`,
    defaultMessage: 'Update Organoleptic Characteristic data successfully!',
  },
  productSpecSaveOrganolepticError: {
    id: `${scope}.components.productSpec.message.saveOrganoleptic.error`,
    defaultMessage: 'Cannot update Organoleptic Characteristic',
  },
  productSpecSaveContaminantSuccess: {
    id: `${scope}.components.productSpec.message.saveOrganoleptic.success`,
    defaultMessage: 'Update Contaminant Characteristic data successfully!',
  },
  productSpecSaveContaminantError: {
    id: `${scope}.components.productSpec.message.saveOrganoleptic.error`,
    defaultMessage: 'Cannot update Contaminant Characteristic',
  },
  productSpecSavePhyCheSuccess: {
    id: `${scope}.components.productSpec.message.savePhyChe.success`,
    defaultMessage:
      'Update Physical and Chemical Characteristic data successfully!',
  },
  productSpecSavePhyCheError: {
    id: `${scope}.components.productSpec.message.savePhyChe.error`,
    defaultMessage:
      'Cannot update Physical and Chemical Characteristic Characteristic',
  },
  productSpecSaveMicrobiologicalSuccess: {
    id: `${scope}.components.productSpec.message.saveMicrobiological.success`,
    defaultMessage: 'Update Microbiological Characteristic data successfully!',
  },
  productSpecSaveMicrobiologicalError: {
    id: `${scope}.components.productSpec.message.saveMicrobiological.error`,
    defaultMessage:
      'Cannot update Microbiological Characteristic Characteristic',
  },
  productSpecAddDocumentSuccess: {
    id: `${scope}.components.productSpec.message.addDocument.success`,
    defaultMessage: 'Link data has been updated successfully!',
  },
  productSpecAddDocumentError: {
    id: `${scope}.components.productSpec.message.addDocument.error`,
    defaultMessage: 'Cannot add Document!',
  },
  productSpecUpdateDocumentSuccess: {
    id: `${scope}.components.productSpec.message.update.success`,
    defaultMessage: 'Update Document successfully!',
  },
  productSpecUpdateDocumentError: {
    id: `${scope}.components.productSpec.message.update.error`,
    defaultMessage: 'Cannot update Document!',
  },
  productSpecRemoveDocumentSuccess: {
    id: `${scope}.components.productSpec.message.removeDocument.success`,
    defaultMessage: 'Remove Document successfully!',
  },
  productSpecRemoveDocumentError: {
    id: `${scope}.components.productSpec.message.removeDocument.error`,
    defaultMessage: 'Cannot remove Document!',
  },
  productSpecAddNewDocumentButton: {
    id: `${scope}.components.productSpec.button.addNewDocument`,
    defaultMessage: 'New Document',
  },
  productSpecCharacteristicType: {
    id: `${scope}.components.productSpec.label.characteristicType`,
    defaultMessage: 'Characteristic Type',
  },
  productSpecDocumentName: {
    id: `${scope}.components.productSpec.label.documentName`,
    defaultMessage: 'Document Name',
  },
  deleteDocumentConfirmMessage: {
    id: `${scope}.components.productSpec.message.deleteDocumentConfirm`,
    defaultMessage: 'Are you sure you want to delete the selected item?',
  },
  name: {
    id: `${scope}.QaSpecContact.label.name`,
    defaultMessage: 'Name',
  },
  addressLine1: {
    id: `${scope}.QaSpecContact.label.addressLine1`,
    defaultMessage: 'Address Line 1',
  },
  addressLine2: {
    id: `${scope}.QaSpecContact.label.addressLine2`,
    defaultMessage: 'Address Line 2',
  },
  country: {
    id: `${scope}.QaSpecContact.label.country`,
    defaultMessage: 'Country',
  },
  phone: {
    id: `${scope}.QaSpecContact.label.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  email: {
    id: `${scope}.QaSpecContact.label.email`,
    defaultMessage: 'Email',
  },
  faxNumber: {
    id: `${scope}.QaSpecContact.label.faxNumber`,
    defaultMessage: 'Fax Number',
  },
  facilityName: {
    id: `${scope}.QaSpecContact.label.facilityName`,
    defaultMessage: 'Facility Name',
  },
  facilityPlantManagerEmail: {
    id: `${scope}.QaSpecContact.label.facilityPlantManagerEmail`,
    defaultMessage: 'Facility Plant Manager Email',
  },
  facilityQaEmail: {
    id: `${scope}.QaSpecContact.label.facilityQAEmail`,
    defaultMessage: 'Facility QA Email',
  },
  facilityQaName: {
    id: `${scope}.QaSpecContact.label.facilityQAName`,
    defaultMessage: 'Facility QA Name',
  },
  facilityPlantManagerName: {
    id: `${scope}.QaSpecContact.label.facilityPlantManagerName`,
    defaultMessage: 'Facility Plant Manager Name',
  },
  facilityQaPhone: {
    id: `${scope}.QaSpecContact.label.facilityQAPhone`,
    defaultMessage: 'Facility QA Phone',
  },
  facilityPlantManagerPhone: {
    id: `${scope}.QaSpecContact.label.facilityPlantManagerPhone`,
    defaultMessage: 'Facility Plant Manager Phone',
  },
  facilityAddressLine1: {
    id: `${scope}.QaSpecContact.label.facilityAddressLine1`,
    defaultMessage: 'Facility Address Line 1',
  },
  facilityAddressLine2: {
    id: `${scope}.QaSpecContact.label.facilityAddressLine2`,
    defaultMessage: 'Facility Address Line 2',
  },
  updateQASpecContactSuccess: {
    id: `${scope}.QaSpecContact.message.success`,
    defaultMessage: 'Update Contact data successfully!',
  },
  updateQASpecContactFailed: {
    id: `${scope}.QaSpecContact.message.failed`,
    defaultMessage: 'Update Contact data failed!',
  },
  inValidFieldMessage: {
    id: `${scope}.QaSpecContact.invalidFieldMessage.failed`,
    defaultMessage: 'Please check invalid input fields!',
  },
  finishSetupButton: {
    id: `${scope}.components.button.finishSetup`,
    defaultMessage: 'Complete Setup',
  },
  moveToConfig: {
    id: `${scope}.components.button.moveToConfig`,
    defaultMessage: 'Move to next step',
  },
  backToUploadFileBtn: {
    id: `${scope}.components.button.backToUploadFile`,
    defaultMessage: 'Back to asset creation',
  },
  requiredErrorMessage: {
    id: `${scope}.components.messages.requiredErrorMessage`,
    defaultMessage: 'This field is required',
  },
  downloadQaSpecBtn: {
    id: `${scope}.components.downloadQaSpec.button`,
    defaultMessage: 'Download Specs',
  },
  loadTestResults: {
    id: `${scope}.components.loadTestResults.button`,
    defaultMessage: 'Load Test Results',
  },
  downloadQaSpecError: {
    id: `${scope}.components.messages.downloadQaSpec.error`,
    defaultMessage: 'Cannot download QA specification',
  },
  qaSpecDocsAndCerRemoveMessageSuccess: {
    id: `${scope}.components.qaSpecDocsAndCer.message.removeDocument.success`,
    defaultMessage: 'Remove {name} successfully!',
  },
  qaSpecDocsAndCerUpdateMessageSuccess: {
    id: `${scope}.components.qaSpecDocsAndCer.message.updateDocument.success`,
    defaultMessage: 'Update {name} successfully!',
  },
  qaSpecDocUpdateMessageSuccess: {
    id: `${scope}.components.qaSpecDoc.message.updateDocument.success`,
    defaultMessage: 'Update Document successfully!',
  },
  qaSpecDocRemoveMessageSuccess: {
    id: `${scope}.components.qaSpecDoc.message.removeDocument.success`,
    defaultMessage: 'Remove Document successfully!',
  },
  qaSpecDocLinkMessageSuccess: {
    id: `${scope}.components.qaSpecDoc.message.linkDocument.success`,
    defaultMessage: 'Link data has been updated successfully!',
  },
  qaSpecUpdateComponentsSuccess: {
    id: `${scope}.components.components.message.update.success`,
    defaultMessage: 'Update Component data successfully!',
  },
  qaSpecUpdateComponentsError: {
    id: `${scope}.components.components.message.update.success`,
    defaultMessage: 'Cannot update Components',
  },
  MSDSRequired: {
    id: `${scope}.components.productSpec.label.MSDSRequired`,
    defaultMessage: 'MSDS Required?',
  },
});
