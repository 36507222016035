import React, { useState, useImperativeHandle, forwardRef } from 'react';

import { clone } from 'lodash';
import classnames from 'classnames';

import DownloadPanel from '../download-panel';
import SchedulePanel from '../schedule-panel';

import { sleep } from 'utils/delay';

import {
  WIDTH_CONTANT_PANE,
  DOWNLOAD_FOLDER_PANEL_STEP,
} from 'static/Constants';

import 'styles/animation.less';

const FolderPanelDownload = forwardRef((props, ref) => {
  const { folder } = props;

  const [visibleStep, setVisibleStep] = useState(
    DOWNLOAD_FOLDER_PANEL_STEP.NONE
  );
  const [animationTrigger, setAnimationTrigger] = useState(
    DOWNLOAD_FOLDER_PANEL_STEP.NONE
  );

  const [downloadConfig, setDownloadConfig] = useState(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        openDownloadPanel: () => {
          openPanel(DOWNLOAD_FOLDER_PANEL_STEP.DIRECT);
        },
      };
    },
    []
  );

  const openPanel = async (panel) => {
    setVisibleStep(panel);
    await sleep(100);
    setAnimationTrigger(panel);
  };

  const closePanel = async (panel) => {
    setAnimationTrigger(panel);

    await sleep(500);

    setVisibleStep(panel);
  };

  const closeDownloadPanel = () => {
    closePanel(DOWNLOAD_FOLDER_PANEL_STEP.NONE);
  };

  const openScheduledDownload = (params) => {
    openPanel(DOWNLOAD_FOLDER_PANEL_STEP.SCHEDULED);

    setDownloadConfig(params);
  };

  const closeScheduledDownload = async () => {
    closePanel(DOWNLOAD_FOLDER_PANEL_STEP.DIRECT);
  };

  return (
    <>
      {visibleStep !== DOWNLOAD_FOLDER_PANEL_STEP.NONE ? (
        <div
          className={classnames('folder-panel__download', {
            'folder-panel__download--open':
              animationTrigger !== DOWNLOAD_FOLDER_PANEL_STEP.NONE,
          })}
          style={{ width: WIDTH_CONTANT_PANE - 10 }}
        >
          <DownloadPanel
            continueSchedule={openScheduledDownload}
            ownerFolder={folder?.isOwner}
            id={folder?.id}
            onClosePanel={closeDownloadPanel}
            memberOwnerId={folder?.ownerId}
          />
          {visibleStep === DOWNLOAD_FOLDER_PANEL_STEP.SCHEDULED ? (
            <div
              className={classnames('folder-panel__download-schedule', {
                'folder-panel__download-schedule--open':
                  animationTrigger === DOWNLOAD_FOLDER_PANEL_STEP.SCHEDULED,
              })}
            >
              <SchedulePanel
                shortDetail={folder}
                downloadConfig={downloadConfig}
                // downloadSetting={downloadSetting}
                ownerFolder={folder?.isOwner}
                onClosePanel={closeScheduledDownload}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
});

export default FolderPanelDownload;
