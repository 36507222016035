import React, { useEffect, useRef, useState } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import { Button, Input, Row, Space, Typography } from 'antd';

import { WithLoading } from 'common/components';
import HierarchyProductList from './HierarchyProductList';

import * as productServices from 'services/product';
import { HIERARCHY_LEVEL } from 'static/Constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

import { useGetProductFullView } from 'hooks';

const { Text } = Typography;

const AddProductHierarchy = (props) => {
  const intl = useIntl();
  const searchInputRef = useRef(null);

  const {
    selectedLevel,
    setSelectedProduct,
    selectedProduct,
    goBackToSelectLevelView,
    productId,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [totalProduct, setTotalProduct] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);

  const [searchProductData, setSearchProductData] = useState([]);
  const [searchProductError, setSearchProductError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const { productFull: currentProduct } = useGetProductFullView({ productId });

  const pageSize = 5;

  const onClickBackButton = () => {
    goBackToSelectLevelView();
  };

  const onSearchProduct = (value) => {
    setSearchText(value);
    callApiSearchProduct({ page: 1, search: value });
  };

  const callApiSearchProduct = ({ page, search }) => {
    setSearchLoading(true);
    setSearchProductData([]);
    setSearchProductError(false);

    const selectedLevelParams = Object.values(HIERARCHY_LEVEL).find(
      (LEVEL) => LEVEL.KEY === selectedLevel.key
    )?.NAME;

    const params = {
      packageLevels: [selectedLevelParams],
      search: {
        searchText: typeof search === 'string' ? search : searchText,
      },
      pageIndex: page,
      pageSize,
      ownerId: currentProduct?.ownerId,
    };

    productServices
      .searchProductForAddHierarchy(params)
      .then((response) => {
        const { isSuccess, data } = response;

        if (isSuccess && data?.gridData?.length) {
          setSearchProductData(data.gridData);
          setTotalProduct(data.paging?.totalRecord);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        setSearchProductError(true);
      })
      .finally(() => {
        setSearchLoading(false);
      });
  };

  const focusSearchInput = () => {
    if (!searchInputRef.current) return;
    searchInputRef.current.focus({
      cursor: 'start',
    });
  };

  useEffect(() => {
    focusSearchInput();
  }, []);

  return (
    <>
      <div className='add-hierarchy__search-product-section'>
        <Space>
          <Button
            className='add-hierarchy__search-product-back-btn'
            icon={<LeftOutlined />}
            onClick={onClickBackButton}
            size='small'
            shape='circle'
          />
          <Input.Search
            ref={searchInputRef}
            placeholder={intl.formatMessage(Messages.searchProductPlaceholder)}
            onSearch={onSearchProduct}
            style={{ width: '300px' }}
          />
        </Space>
        <Row justify='center'>
          {searchLoading ? (
            <WithLoading
              loading={searchLoading}
              style={{ marginTop: '100px' }}
            />
          ) : null}
          {searchProductError ? (
            <Text type='secondary' style={{ marginTop: '100px' }}>
              {intl.formatMessage(Messages.searchProductError)}
            </Text>
          ) : null}
          {searchProductData.length ? (
            <HierarchyProductList
              productList={searchProductData}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              selectedLevel={selectedLevel}
              getProduct={callApiSearchProduct}
              totalProduct={totalProduct}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          ) : null}
        </Row>
      </div>
    </>
  );
};

export default AddProductHierarchy;
