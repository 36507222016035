import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Checkbox, Col, Row, Spin, DatePicker, Select } from 'antd';

import { formatMDY } from 'utils/formatDate';
import Message from 'i18n/messages/folder';
import memberReducer from 'pages/company-profile/controllers/reducer';
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import saga from 'pages/company-profile/controllers/saga';
import userSelectors from 'redux/user/selectors';
import moment from 'moment';
import * as memberActions from 'pages/company-profile/controllers/actions';
import { FormattedMessage } from 'react-intl';
import { Form, WrapperSelect } from 'common/components';
import { getDateFormat } from 'utils/formatDate';

import {
  PRIVATE_FOLDER_VISIBILITY,
  FOLDER_VISIBILITY,
  DATE_FORMAT,
} from 'static/Constants';

import { useGetMemberHeaderFromMemberId } from 'common/queries/member-module';

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const FolderEditDetailContent = (props) => {
  useInjectReducer({ key: 'member', reducer: memberReducer });
  useInjectSaga({ key: 'member', saga });

  const dispatch = useDispatch();

  const { folderInfo } = props;

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  useEffect(() => {
    dispatch(memberActions.getMemberProfileHeader(userInfo?.member?.id));
  }, [dispatch, userInfo?.member?.id]);

  const { data: memberHeaderOfFolderOwner } = useGetMemberHeaderFromMemberId({
    memberId: folderInfo?.ownerId,
    enabled: Boolean(folderInfo && folderInfo?.ownerId),
  });

  const isPrivateVisibilityFolder =
    memberHeaderOfFolderOwner?.folders?.toLowerCase() === 'private';

  const isRootFolder = folderInfo?.level === 1;

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  const FieldItemInputDisabled = ({ data }) => {
    return (
      <Form.Item
        {...formItemLayout}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
      >
        <Input disabled />
      </Form.Item>
    );
  };

  const FieldItemCheckboxDisabled = ({ data }) => {
    return (
      <Form.Item
        {...formItemLayout}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
        valuePropName='checked'
      >
        <Checkbox disabled />
      </Form.Item>
    );
  };

  const FieldItemCheckbox = ({ data }) => {
    return (
      <Form.Item
        {...formItemLayout}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
        valuePropName='checked'
      >
        <Checkbox disabled={!isRootFolder} />
      </Form.Item>
    );
  };

  const FieldItemRangePicker = ({ data }) => {
    return (
      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
      >
        <DatePicker
          format={DATE_FORMAT.SYSTEM_FORMAT}
          disabledDate={disabledDate}
          disabled={!isRootFolder}
        />
      </Form.Item>
    );
  };
  const FieldItemRangePickerDisabled = ({ data }) => {
    return (
      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
      >
        <DatePicker format={DATE_FORMAT.SYSTEM_FORMAT} disabled={true} />
      </Form.Item>
    );
  };
  return (
    <>
      <Spin spinning={false}>
        <Row gutter={[8, 0]}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              name='folderName'
              label={<FormattedMessage {...Message.folderNameEdit} />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage {...Message.inputRequireTextEdit} />
                  ),
                },
                {
                  whitespace: true,
                  message: (
                    <FormattedMessage {...Message.inputOnlyWhitespace} />
                  ),
                },
              ]}
              className='folder-panel-edit-content__folder-name'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FieldItemInputDisabled
              data={{
                label: Message.ownEdit,
                value: folderInfo?.ownerName,
                fieldValue: 'ownerName',
              }}
            />
            <FieldItemRangePickerDisabled
              data={{
                label: Message.dateCreatedEdit,
                value: formatMDY(folderInfo?.dateCreated),
                fieldValue: 'dateCreated',
              }}
            />
            <FieldItemRangePickerDisabled
              data={{
                label: Message.dateSharedEdit,
                value: formatMDY(folderInfo?.dateShared),
                fieldValue: 'dateShared',
              }}
            />
            <FieldItemCheckboxDisabled
              data={{
                label: Message.editableEdit,
                value: folderInfo?.editable,
                fieldValue: 'editable',
              }}
            />
            <FieldItemCheckboxDisabled
              data={{
                label: Message.shareableEdit,
                value: folderInfo?.shareable,
                fieldValue: 'shareable',
              }}
            />
            <FieldItemCheckbox
              data={{
                label: Message.specialPurposeEdit,
                fieldValue: 'specialPurpose',
              }}
            />
            <FieldItemRangePicker
              data={{
                label: Message.expirationDate,
                fieldValue: 'expirationDate',
                value: getDateFormat(folderInfo?.expirationDate),
              }}
            />
          </Col>
          <Col span={12}>
            <FieldItemInputDisabled
              data={{
                label: Message.itemsEdit,
                value: folderInfo?.items,
                fieldValue: 'items',
              }}
            />

            <FieldItemRangePickerDisabled
              data={{
                label: Message.lastUpdatedEdit,
                value: formatMDY(folderInfo?.lastUpdated),
                fieldValue: 'lastUpdated',
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.lastUpdatedByEdit,
                value: folderInfo?.lastUpdatedBy,
                fieldValue: 'lastUpdatedBy',
              }}
            />
            <FieldItemRangePickerDisabled
              data={{
                label: Message.lastDownloadEdit,
                value: formatMDY(folderInfo?.lastDownload),
                fieldValue: 'lastDownload',
              }}
            />
            <FieldItemRangePickerDisabled
              data={{
                label: Message.lastScheduleEdit,
                value: formatMDY(folderInfo?.lastSchedule),
                fieldValue: 'lastSchedule',
              }}
            />
            <Form.Item name='visibility' label='Visibility' {...formItemLayout}>
              <WrapperSelect name='visibility' disabled={!isRootFolder}>
                {isPrivateVisibilityFolder
                  ? PRIVATE_FOLDER_VISIBILITY?.slice()
                      .sort()
                      .map((visibility) => (
                        <Select.Option key={visibility} value={visibility}>
                          {visibility}
                        </Select.Option>
                      ))
                  : FOLDER_VISIBILITY?.slice()
                      .sort()
                      .map((visibility) => (
                        <Select.Option key={visibility} value={visibility}>
                          {visibility}
                        </Select.Option>
                      ))}
              </WrapperSelect>
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default FolderEditDetailContent;
