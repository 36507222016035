import React, { useState, useEffect } from 'react';

import Grid from '../components/Grid';

import { StyledModal } from 'common/components';

import * as folderServices from 'services/folder';

import { RIBBON_TYPES } from 'static/Constants';

import FindEntityInFolderResultToolbar from './FindEntityInFolderResultToolbar';
import FindEntityInFolderResultFooter from './FindEntityInFolderResultFooter';

import { sleep } from 'utils/delay';
import { forwardTo } from 'utils/common/route';

const FindInAllFolderModal = (props) => {
  const { showResultHook, selectedItemList } = props;

  const [visible, setVisible] = showResultHook;
  const typeViewHook = useState(RIBBON_TYPES.THUMBNAILS);
  const [currentView, setCurrentView] = typeViewHook;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [result, setResult] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const onCloseDialog = () => {
    setVisible(false);
  };

  const onChangePage = (newPage) => {
    setPageIndex(newPage);
  };

  const onShowSizeChange = (current, size) => {
    setPageSize(size);
  };

  const callApiFindEntityInFolder = () => {
    const itemList = generateEntityList();
    const params = {
      itemList,
      pageSize,
      pageIndex,
    };

    setLoading(true);

    folderServices
      .findInAllFolders(params)
      .then((response) => {
        const { isSuccess, data } = response;
        if (isSuccess) {
          const procGridData = data?.gridData?.map((dataItem) => ({
            ...dataItem,
            type: 'folder',
          }));
          setResult(procGridData);
          setTotalResult(data?.paging?.totalRecord);
        } else {
          callApiFindEntityInFolderErrorHandler();
        }
      })
      .catch((err) => callApiFindEntityInFolderErrorHandler())
      .finally(() => setLoading(false));
  };

  const generateEntityList = () => {
    return selectedItemList.map((item) => {
      const { id, type } = item;
      return {
        id,
        type,
      };
    });
  };

  const callApiFindEntityInFolderErrorHandler = () => {
    setError(true);
  };

  const resetModalState = () => {
    setResult([]);
    setPageIndex(1);
    setCurrentView(RIBBON_TYPES.THUMBNAILS);
    setLoading(true);
    setTotalResult(0);
    setError(false);
  };

  const handleDoubleClick = (gridItemProps) => {
    const folderId = gridItemProps?.dataDetail?.id;
    if (!folderId) return;

    forwardTo(`/folder/${folderId}`);
    onCloseDialog();
  };

  useEffect(() => {
    if (visible) {
      callApiFindEntityInFolder();
    } else {
      resetModalState();
    }
    // eslint-disable-next-line
  }, [visible, pageIndex, pageSize]);

  useEffect(() => {
    const onChangeViewType = async () => {
      setLoading(true);
      await sleep(500);
      setLoading(false);
    };

    onChangeViewType();
  }, [currentView]);

  const modalConfig = {
    visible,
    destroyOnClose: true,
    title: <FindEntityInFolderResultToolbar typeViewHook={typeViewHook} />,
    footer: (
      <FindEntityInFolderResultFooter
        totalResult={totalResult}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onShowSizeChange={onShowSizeChange}
      />
    ),
    centered: true,
    width: 1019,
    style: {
      overflow: 'hidden',
    },
    bodyStyle: {
      height: '80vh',
    },
    onCancel: onCloseDialog,
  };

  return (
    <StyledModal {...modalConfig}>
      <Grid
        items={result}
        searchType='folder'
        viewType={currentView}
        isLoading={loading}
        handleDoubleClick={handleDoubleClick}
      />
    </StyledModal>
  );
};

export default FindInAllFolderModal;
