import React from 'react';
import { useSelector } from 'react-redux';

import { SubnodeOutlined } from '@ant-design/icons';

import { Dropdown, Menu } from 'antd';

import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import MoveGlnToMember from './MoveGlnToMember';
import MoveAllToMember from 'pages/home/ribbon/components/sections/shared/MoveAllToMember';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const MemberActions = () => {
  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const menuOptions = (
    <Menu>
      <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL}>
        <Menu.Item>
          <MoveAllToMember
            disabled={selectedItemDetailList?.length !== 1}
            selectedMember={selectedItemDetailList?.[0]}
          />
        </Menu.Item>
      </Can>
      <Menu.Item>
        <MoveGlnToMember entityType='MEM' />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
        <RibbonButton icon={<SubnodeOutlined />} label='Member Actions' />
      </Dropdown>
    </>
  );
};

export default MemberActions;
