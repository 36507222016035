export const OPERATOR = {
  In: 'In',
  Equal: '=',
  'Not In': 'Not In',
  'Not Equal': '!=',
  Between: 'Between',
  'Not Between': 'Not Between',
  Contains: 'Contains',
  'Does Not Contain': 'Does Not Contain',
  'Is Null': 'Is Null',
  'Is Not Null': 'Is Not Null',
  'Less Than': '<',
  'Greater Than': '>',
  'Greater Than Or Equal': '>=',
  'Less Than Or Equal': '<=',
};

export const NULL_VALUE = ['Is Null', 'Is Not Null'];

export const COLOR = {
  string: 'cyan',
  DateTime: 'grey',
  bool: 'green',
  int: 'blue',
  double: 'blue',
  long: 'blue',
  decimal: 'blue',
};

export const generateOperator = (operator) => {
  return operator ? OPERATOR[operator] : operator;
};

export const generateColor = (type) => {
  return type ? COLOR[type] : 'blue';
};
