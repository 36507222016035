import React from 'react';

import { Radio, Space, Row, Col, Typography, Popover } from 'antd';

import SelectedMemberList from './SelectedMemberList';

import './ProductFormMove.less';

const { Text, Paragraph } = Typography;

const ProductFormMove = (props) => {
  const { moveForm, selectedItems, items, handleSelectItem, tagRender } = props;

  // const [moveMethod, setMoveMethod] = methodHook;

  // const onChangeMoveMethod = (e) => {
  //   setMoveMethod && setMoveMethod(e.target.value);
  // };

  return (
    <div className='reassign-product-form-move'>
      <Row>
        <Col xs={24} style={{ height: 50 }}>
          <SelectedMemberList
            selectedItems={selectedItems}
            items={items}
            handleSelectItem={handleSelectItem}
            tagRender={tagRender}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Popover
            content={
              <Row>
                <Typography>
                  <Paragraph style={{ margin: 0 }}>
                    Move Entire Hierarchy: Move all product items within
                    hierarchy of selected product item.
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    Move Product Only: Move selected product items only, all
                    associated data in current member will be removed.
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    Duplicate: Create a copy of hierarchy of selected product
                    item for target member.
                  </Paragraph>
                </Typography>
              </Row>
            }
            title='Hint'
          >
            <Text strong>Product reassignment options</Text>
          </Popover>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Radio.Group
            name='productOption'
            onChange={moveForm?.onChange}
            value={moveForm?.values?.productOption}
          >
            <Radio value='moveEntireHierarchy'>Move Entire Hierarchy</Radio>
            <Radio value='moveProductItemOnly'>Move Product Only</Radio>
            <Radio value='makeCopy'>Duplicate</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row style={{ margin: '5px 0' }}>
        <Col xs={24}>
          <Popover
            title='Hint'
            content={
              <Row>
                <Typography>
                  <Paragraph style={{ margin: 0 }}>
                    Move: selected product(s) to new members, all associated
                    data in current member will be removed.
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    Duplicate: create a copy version of selected product(s) for
                    new member.
                  </Paragraph>
                </Typography>
              </Row>
            }
          >
            <Text strong>Asset links options</Text>
          </Popover>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Radio.Group
            name='assetOption'
            onChange={moveForm?.onChange}
            value={moveForm?.values?.assetOption}
          >
            <Radio value='move'>Move</Radio>
            <Radio value='makeCopy'>Duplicate</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ProductFormMove;
