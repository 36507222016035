import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { formatMDY } from 'utils/formatDate';
import { Input, Checkbox, Col, Row, Spin, DatePicker, Select } from 'antd';

import { Form, WrapperSelect } from 'common/components';

import { FormattedMessage } from 'react-intl';
import Message from 'i18n/messages/folder';
import { Images } from 'config/assets';
import moment from 'moment';
import { getDateFormat } from 'utils/formatDate';

import * as companySelectors from 'pages/company-profile/controllers/selectors';

import userSelectors from 'redux/user/selectors';

import * as memberActions from 'pages/company-profile/controllers/actions';

import memberReducer from 'pages/company-profile/controllers/reducer';
import saga from 'pages/company-profile/controllers/saga';

import { useInjectSaga } from 'utils/common/injectSaga';
import { useInjectReducer } from 'utils/common/injectedReducers';

import {
  PRIVATE_FOLDER_VISIBILITY,
  FOLDER_VISIBILITY,
  DATE_FORMAT,
} from 'static/Constants';

import './FolderPanelEditContent.less';

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const dateFormat = 'YYYY/MM/DD';

const FolderPanelEditContent = (props) => {
  useInjectReducer({ key: 'member', reducer: memberReducer });
  useInjectSaga({ key: 'member', saga });

  const dispatch = useDispatch();

  const {
    folderInfo,
    onFinish,
    onFinishFailed,
    onValuesChange,
    form,
    ownerFolder,
    loading,
  } = props;

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  useEffect(() => {
    dispatch(memberActions.getMemberProfileHeader(userInfo?.member?.id));
  }, [dispatch, userInfo?.member?.id]);

  const memberInfo = useSelector(companySelectors.selectMemberProfile());

  const isPrivateVisibilityFolder = memberInfo?.folders === 'Private';

  const RenderFolderImg = () => {
    let img = !ownerFolder
      ? Images.sharedFolder
      : folderInfo?.items > 0
      ? Images.folder
      : Images.emptyFolder;

    return (
      <img src={img} style={{ width: '100px', height: 'auto' }} alt='folder' />
    );
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  const FieldItemInputDisabled = ({ data }) => {
    return (
      <Form.Item
        {...formItemLayout}
        label={<FormattedMessage {...data.label} />}
      >
        <Input value={data?.value} disabled />
      </Form.Item>
    );
  };

  const FieldItemInputCanEdit = ({ data }) => {
    return (
      <Form.Item
        {...formItemLayout}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
      >
        <Input
          placeholder={<FormattedMessage {...Message.inputRequireTextEdit} />}
        />
      </Form.Item>
    );
  };

  const FieldItemCheckboxDisabled = ({ data }) => {
    return (
      <Form.Item
        {...formItemLayout}
        // name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
        valuePropName='checked'
      >
        <Checkbox checked={data.value} disabled />
      </Form.Item>
    );
  };

  const FieldItemCheckbox = ({ data }) => {
    return (
      <Form.Item
        {...formItemLayout}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
        valuePropName='checked'
      >
        <Checkbox />
      </Form.Item>
    );
  };
  const FieldItemRangePicker = ({ data }) => {
    return (
      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={data.fieldValue}
        label={<FormattedMessage {...data.label} />}
      >
        <DatePicker
          format={DATE_FORMAT.SYSTEM_FORMAT}
          defaultValue={data.value}
          disabledDate={disabledDate}
        />
      </Form.Item>
    );
  };
  return (
    <Form
      form={form}
      name='short-folder-edit'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      id='short-folder-edit'
      className='folder-panel-edit-content'
      onValuesChange={onValuesChange}
    >
      <Row
        style={{ marginBottom: '16px' }}
        justify='center'
        className='folder-panel-header__thumbail-info'
      >
        <RenderFolderImg />
      </Row>
      <Spin spinning={loading}>
        <Row gutter={[8, 0]}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              name='folderName'
              label={<FormattedMessage {...Message.folderNameEdit} />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage {...Message.inputRequireTextEdit} />
                  ),
                },
                {
                  whitespace: true,
                  message: (
                    <FormattedMessage {...Message.inputOnlyWhitespace} />
                  ),
                },
              ]}
              className='folder-panel-edit-content__folder-name'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FieldItemInputDisabled
              data={{
                label: Message.ownEdit,
                value: folderInfo?.ownerName,
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.dateCreatedEdit,
                value: formatMDY(folderInfo?.dateCreated),
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.dateSharedEdit,
                value: formatMDY(folderInfo?.dateShared),
              }}
            />
            <FieldItemCheckboxDisabled
              data={{
                label: Message.editableEdit,
                value: folderInfo?.editable,
              }}
            />
            <FieldItemCheckboxDisabled
              data={{
                label: Message.shareableEdit,
                value: folderInfo?.shareable,
              }}
            />
            <FieldItemCheckbox
              data={{
                label: Message.specialPurposeEdit,
                fieldValue: 'specialPurpose',
              }}
            />
          </Col>
          <Col span={12}>
            <FieldItemInputDisabled
              data={{
                label: Message.itemsEdit,
                value: folderInfo?.items,
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.estSizeEdit,
                value: folderInfo?.estSize,
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.lastUpdatedEdit,
                value: formatMDY(folderInfo?.lastUpdated),
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.lastUpdatedByEdit,
                value: folderInfo?.lastUpdatedBy,
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.lastDownloadEdit,
                value: formatMDY(folderInfo?.lastDownload),
              }}
            />
            <FieldItemInputDisabled
              data={{
                label: Message.lastScheduleEdit,
                value: formatMDY(folderInfo?.lastSchedule),
              }}
            />
          </Col>
          <Col span={24}>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <FieldItemRangePicker
                  data={{
                    label: Message.expirationDate,
                    fieldValue: 'expirationDate',
                    value: getDateFormat(folderInfo?.expirationDate),
                  }}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name='visibility'
                  label='Visibility'
                  {...formItemLayout}
                >
                  <WrapperSelect name='visibility'>
                    {isPrivateVisibilityFolder
                      ? PRIVATE_FOLDER_VISIBILITY?.slice()
                          .sort()
                          .map((visibility) => (
                            <Select.Option key={visibility} value={visibility}>
                              {visibility}
                            </Select.Option>
                          ))
                      : FOLDER_VISIBILITY?.slice()
                          .sort()
                          .map((visibility) => (
                            <Select.Option key={visibility} value={visibility}>
                              {visibility}
                            </Select.Option>
                          ))}
                  </WrapperSelect>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

FolderPanelEditContent.propTypes = {
  folderInfo: PropTypes.object,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  onValuesChange: PropTypes.func,
  form: PropTypes.object,
  ownerFolder: PropTypes.bool,
};

export default FolderPanelEditContent;
