import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CustomNotification } from 'common/components';
import { NodeTree } from 'common/components/folder/FolderTree';
import { IconFolderAsset } from '../shared/components';

import * as selectorNav from 'redux/branding/selectors';

import {
  getDestinationFolderParams,
  mappingInteropItemsToTreeData,
} from './utils';
import {
  mappingFolderToTreeData,
  filterParams,
} from 'common/components/folder/utils';
import { updateTreeData } from '../shared/utils';
import { sleep } from 'utils/delay';

import { useCheckPermissions, useUserInfo } from 'hooks';

import { getInteropListItems } from 'services/interoperability';
import { getFolderList, getItemsOfFolder } from 'services/new-folder';
import { getFolderShortDetail } from 'services/folder';

import emptyFolderIcon from 'assets/folder/empty-folder.png';
import folderIcon from 'assets/folder/folder.png';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const fileParam = {
  renderFile: () => {
    return {
      checkable: true,
      selectable: false,
    };
  },
};

const folderParam = {
  renderFolder: (node) => {
    const { subFolderCount } = node ?? {};

    const isEmptyContents = subFolderCount === 0;

    if (isEmptyContents) {
      return {
        title: (
          <NodeTree
            title={
              <span
                style={{
                  whiteSpace: 'break-spaces',
                  overflowWrap: 'break-word',
                }}
              >
                {node.folderName ?? node.description ?? ''}
              </span>
            }
          />
        ),
        icon: <IconFolderAsset src={emptyFolderIcon} />,
        isLeaf: true,
        selectable: true,
      };
    }
    return {
      title: (
        <NodeTree
          title={
            <span
              style={{ whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}
            >
              {node.folderName ?? node.description ?? ''}
            </span>
          }
        />
      ),
      icon: <IconFolderAsset src={folderIcon} />,
      selectable: true,
    };
  },
};

export const useGetTreeData = ({ multipleImport, storageServiceType }) => {
  const [treeData, setTreeData] = useState([]);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    setStatus('loading');
    getInteropListItems({
      pathInfo: null,
      storageServiceType,
    })
      .then((res) => {
        if (res.isSuccess) {
          setStatus('success');

          const mappedTreeData = mappingInteropItemsToTreeData(
            sortInteropData(res?.data?.interopItems),
            multipleImport
          );
          setTreeData(mappedTreeData);
        } else {
          setStatus('error');
        }
      })
      .catch((err) => {
        setStatus('error');
        CustomNotification.error(err?.message ?? 'Something went wrong');
      });
  }, [multipleImport, storageServiceType]);

  const handleSetTreeData = (treeData) => setTreeData(treeData);

  return {
    treeData,
    handleSetTreeData,
    isExpanding: status === 'loading',
  };
};

export const useImportToFolder = ({ isEnabled: isEnabledProp, searchText }) => {
  const { userInfo } = useUserInfo();

  const [folder, setFolder] = useState({
    data: [],
    status: 'idle',
  });

  const [importFolder, setImportFolder] = useState({
    isEnabled: false,
    selectedFolder: null,
    importedFolderParams: {},
  });

  const [isCreateSubfolder, setIsCreateSubfolder] = useState(true);

  const removeSelectedFolder = () => {
    setImportFolder((prevVal) => ({
      ...prevVal,
      selectedFolder: null,
      importedFolderParams: {},
    }));
  };

  useEffect(() => {
    if (isEnabledProp && importFolder?.isEnabled) {
      setFolder((prevVal) => ({
        ...prevVal,
        status: 'loading',
      }));

      const params = {
        pageIndex: 1,
        pageSize: 9999,
        sort: [{ fieldName: 'id', isAscending: false }],
        search: { searchText },
        filters: searchText
          ? [
              {
                fieldName: 'ownerId',
                filterType: 'Equal',
                value: userInfo?.companyId,
              },
            ]
          : [
              {
                fieldName: 'ownerId',
                filterType: 'Equal',
                value: userInfo?.companyId,
              },
              {
                fieldName: 'level',
                filterType: 'Equal',
                value: '1',
              },
            ],
      };

      getFolderList(params)
        .then((res) => {
          if (res.isSuccess) {
            const mappedTreeData = mappingFolderToTreeData(
              res?.data?.gridData,
              folderParam,
              fileParam
            );
            setFolder((prevVal) => ({
              ...prevVal,
              status: 'success',
              data: mappedTreeData,
            }));
          } else {
            setFolder((prevVal) => ({
              ...prevVal,
              status: 'error',
              data: [],
            }));
            CustomNotification.error(res.message ?? 'Something went wrong');
          }
        })
        .catch((err) => {
          setFolder((prevVal) => ({
            ...prevVal,
            status: 'error',
            data: [],
          }));
          CustomNotification.error(err.message ?? 'Something went wrong');
        });
    }
  }, [isEnabledProp, searchText, userInfo?.companyId, importFolder?.isEnabled]);

  const handleUpdateFolder = async (_, info) => {
    if (!info?.selected) {
      setImportFolder((prevVal) => ({
        ...prevVal,
        selectedFolder: null,
        importedFolderParams: {},
      }));
    } else {
      const importedFolderParams = await getDestinationFolderParams(
        info?.node?.data
      );
      setImportFolder((prevVal) => ({
        ...prevVal,
        selectedFolder: info?.node?.data,
        importedFolderParams,
      }));
    }
  };

  const handleToggleImportFolder = (value) => {
    if (!value) {
      setImportFolder((prevVal) => ({
        ...prevVal,
        isEnabled: value,
        selectedFolder: null,
        importedFolderParams: {},
      }));
    } else {
      setImportFolder((prevVal) => ({
        ...prevVal,
        isEnabled: value,
      }));
    }
  };

  const handleLoadMoreItem = async ({ key, children, data }) => {
    // if (loadedKeys.includes(key)) return;

    const { id: folderId } = data;

    const params = {
      folderId,
      pageIndex: 1, // change later
      pageSize: 9999,
      filters: filterParams['folder'],
      sort: [
        {
          fieldName: 'id',
          isAscending: false,
        },
      ], // new first,
    };

    const response = await getItemsOfFolder(params);

    return new Promise(async (resolve) => {
      if (children || response === null) {
        resolve();
        return;
      }

      const mappedTreeData = mappingFolderToTreeData(
        response?.data?.gridData,
        folderParam,
        fileParam
      );
      const newData = updateTreeData(folder?.data, key, mappedTreeData);
      await sleep(500); // sleep a little bit to update tree (get idea from antd :))

      setFolder((prevVal) => ({
        ...prevVal,
        data: newData,
      }));

      resolve();
    });
  };

  const handleToggleCreateSubfolder = (value) => setIsCreateSubfolder(value);

  /**
   *
   * @param {} nodeId
   * @param {*} currentTree
   * @param {*} modData
   * @returns //! return new tree after modify one node
   */
  const modifyNodeOnTree = (nodeId, currentTree, modData) => {
    const { data, children, isLeaf } = modData || {};

    return currentTree.map((currentNode) => {
      const {
        data: currentData,
        children: currentChildren,
        isLeaf: currentIsLeaf,
      } = currentNode;

      const { id: currentId } = currentData || {};

      if (currentId === nodeId) {
        return {
          ...currentNode,
          data: data ? data(currentData) : currentData,
          children: children ? children(currentChildren) : currentChildren,
          isLeaf: isLeaf ? isLeaf(currentIsLeaf) : currentIsLeaf,
          icon: <IconFolderAsset src={folderIcon} />,
        };
      } else if (currentChildren && currentChildren.length > 0) {
        return {
          ...currentNode,
          children: modifyNodeOnTree(nodeId, currentChildren, modData),
        };
      }
      return currentNode;
    });
  };

  const onLoadingCreateFolder = async (ms) => {
    setFolder((prevVal) => ({
      ...prevVal,
      status: 'loading',
    }));

    await sleep(ms);
  };

  const onCreateNewFolderSuccess = async (nodeData) => {
    await onLoadingCreateFolder(3000);

    const { id: folderId } = nodeData || {};

    let params = {};
    let itemList = [];
    let shortDetailFolder = [];
    let newTree;

    if (folderId) {
      params = {
        folderId,
        pageIndex: 1, // change later
        pageSize: 9999,
        filters: filterParams['folder'],
        sort: [
          {
            fieldName: 'id',
            isAscending: false,
          },
        ], // new first,
      };
    } else {
      params = {
        pageIndex: 1,
        pageSize: 9999,
        sort: [{ fieldName: 'id', isAscending: false }],
        search: { searchText },
        filters: searchText
          ? []
          : [
              {
                fieldName: 'level',
                filterType: 'Equal',
                value: '1',
              },
            ],
      };
    }

    itemList = await getFolderList(params);
    if (folderId) {
      shortDetailFolder = await getFolderShortDetail({
        id: folderId,
        IsLoadContent: false,
      });
    }

    const mappedListItemToTreeFormat = mappingFolderToTreeData(
      itemList?.data?.gridData,
      folderParam,
      fileParam
    );

    if (!folderId) {
      const rootListHaveChildren = folder?.data.filter(
        (dataItem) => dataItem?.children
      );

      if (rootListHaveChildren?.length > 0) {
        newTree = mappedListItemToTreeFormat.map((dataItem) => {
          let newDataItem = dataItem;

          for (const rootListItem in rootListHaveChildren) {
            if (
              dataItem?.data?.id &&
              rootListItem?.data?.id === dataItem?.data?.id
            ) {
              newDataItem = rootListItem;
              break;
            }
          }
          return newDataItem;
        });
      } else {
        newTree = mappedListItemToTreeFormat;
      }

      setFolder((prevVal) => ({
        ...prevVal,
        data: newTree,
        status: 'success',
      }));
    } else {
      newTree = modifyNodeOnTree(folderId, folder?.data, {
        data: (nodeData) => ({
          ...nodeData,
          subFolderCount: shortDetailFolder?.data?.subFolderCount,
        }),
        children: () => mappedListItemToTreeFormat,
        isLeaf: () => false,
      });
      await sleep(500); // sleep a little bit to update tree (get idea from antd :))

      setFolder((prevVal) => ({
        ...prevVal,
        data: newTree,
        status: 'success',
      }));
    }
  };

  return {
    folderData: folder?.data ?? [],
    isEnabledImportFolder: Boolean(importFolder?.isEnabled),
    importedFolderParams: {
      ...importFolder.importedFolderParams,
    },
    isCreateSubfolder,
    selectedFolder: importFolder?.selectedFolder,
    isLoading: folder?.status === 'idle' || folder?.status === 'loading',
    handleLoadMoreItem,
    handleUpdateFolder,
    handleToggleCreateSubfolder,
    handleToggleImportFolder,
    onCreateNewFolderSuccess,
    removeSelectedFolder,
  };
};

export const useCheckAllowImportToFolder = () => {
  const PERMISSION_VIEW_FOLDER = [
    {
      action: ABILITY_ACTION.VIEW,
      subject: ABILITY_SUBJECT.FOLDER,
    },
  ];

  const hasPermissionViewFolder = useCheckPermissions(PERMISSION_VIEW_FOLDER);

  const { canAccessFolders } = useSelector(selectorNav.getBranding()) ?? {};

  return hasPermissionViewFolder && canAccessFolders;
};

export const useCheckAllowCreateFolder = () => {
  const PERMISSION_CREATE_FOLDER = [
    {
      action: ABILITY_ACTION.CREATE,
      subject: ABILITY_SUBJECT.FOLDER,
    },
  ];

  const hasPermissionCreateFolder = useCheckPermissions(
    PERMISSION_CREATE_FOLDER
  );

  const { canAccessFolders } = useSelector(selectorNav.getBranding()) ?? {};

  return hasPermissionCreateFolder && canAccessFolders;
};

// move folders to top
const sortInteropData = (data = []) => {
  const folders = data.filter((item) => item.type === 'folder');
  const files = data.filter((item) => item.type === 'file');
  return folders.concat(files);
};
