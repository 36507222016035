import React from 'react';

import RibbonButton from 'common/components/button/RibbonButton';

const ToggleUploadMediaBtn = ({
  clickActionCallback,
  label,
  icon,
  disabled,
}) => {
  return (
    <RibbonButton
      icon={icon}
      label={label}
      onClick={clickActionCallback}
      disabled={disabled}
    />
  );
};

// AddOpenItemBtn.propTypes = {
//   clickActionCallback: PropTypes.func,
// };

export default ToggleUploadMediaBtn;
