import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as ticketingServices from 'services/ticketingSystem';

export const useGetTicketProduct = (props = {}) => {
  const queryClient = useQueryClient();

  const { params, enabled = false, ...restProps } = props;

  const getQueryKey = () => {
    return ['get-ticket-product', params];
  };

  const getTicketProducts = async ({ queryKey }) => {
    const response = await ticketingServices.getTicketProduct(queryKey[1]);

    const { isSuccess, data } = response;

    return isSuccess && data ? data.gridData : [];
  };

  const query = useQuery({
    queryKey: getQueryKey(),
    queryFn: getTicketProducts,
    enabled,
    ...restProps,
  });

  const invalidateTicketProduct = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKey(),
    });
  };

  return { ...query, invalidateTicketProduct };
};
