import { defineMessages } from 'react-intl';

export const scope = 'Taco.chat';

export default defineMessages({
  createNewChatUser: {
    id: `${scope}.components.createNew.options.user`,
    defaultMessage: 'Chat with user',
  },
  createNewChatCompany: {
    id: `${scope}.components.createNew.options.company`,
    defaultMessage: 'Chat with company',
  },
  cannotSearch: {
    id: `${scope}.components.createNew.options.company`,
    defaultMessage: 'Cannot search any result',
  },
  satisfiedWithExperience: {
    id: `${scope}.components.endChat.satisfiedWithExperience`,
    defaultMessage: 'Are you satisfied with this experience?',
  },
  commentPlaceholder: {
    id: `${scope}.components.endChat.commentPlaceholder`,
    defaultMessage:
      'Please tell us the reason for your vote above so that we can improve our service',
  },
  submit: {
    id: `${scope}.components.endChat.submit`,
    defaultMessage: 'Submit',
  },
  updateAvatarModal: {
    id: `${scope}.components.chat.changeAvatarModal`,
    defaultMessage: 'Update Avatar Modal',
  },
  updateAvatarSuccess: {
    id: `${scope}.components.chat.changeAvatarSuccess`,
    defaultMessage: 'Update successfully!',
  },
  updateThreadNameSuccess: {
    id: `${scope}.components.chat.updateThreadNameSuccess`,
    defaultMessage: 'Update successfully!',
  },
});
