import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as qaSpecServices from 'services/qaSpec';

export const getProductSpecOrganolepticKey = (productId) => {
  return [
    'qa-spec',
    parseInt(productId),
    'product-spec-organoleptic-characteristics',
  ];
};

export const useGetOrganolepticQuery = ({ productId }) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery({
    queryKey: getProductSpecOrganolepticKey(productId),
    queryFn: async () => {
      return await qaSpecServices.getQaSpecOrganolepticCharacteristic({
        productId,
      });
    },
    enabled: Boolean(productId),
  });

  const handleRefetchOrganoleptic = () => {
    queryClient.invalidateQueries({
      queryKey: getProductSpecOrganolepticKey(productId),
    });
  };

  return {
    organolepticData: data?.data,
    handleRefetchOrganoleptic,
    ...rest,
  };
};
