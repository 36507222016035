import _ from 'lodash';

const contactDefault = [
  {
    label: 'Phone',
    field: 'primaryAddress.primaryAddressPhoneNumber',
  },
  {
    label: 'Email',
    field: 'primaryAddress.primaryAddressEmail',
  },
  {
    label: 'Fax',
    field: 'primaryAddress.primaryAddressFaxNumber',
  },
];

export const mapToProductContact = (data) => {
  if (!data) return [];
  return contactDefault.map((lableItem) => ({
    label: lableItem?.label,
    info: _.get(data, lableItem.field),
  }));
};
