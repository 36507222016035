import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Row, Col, Input, Space } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import {
  Form,
  StyledModal,
  CustomNotification,
  useFormHook,
} from 'common/components';

import {
  SearchGrid,
  SearchGridItemButton,
  SectionContainer,
} from '../components';

import GLNFormMove from './GLNFormMove';

import { mapToSearchParams } from '../mapper/mapToSearchParams';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import * as memberMaintenanceServices from 'services/memberMaintenance';

import * as api from 'config/axios';

import { get } from 'lodash';

import './styles.less';
import Section from 'pages/company-profile/components/tabs/tag-line/Section';

const defaultPageInfo = {
  currentPageIndex: 1,
  currentPageSize: 20,
  totalRecord: null,
};

const selectedItemsDefine = ['init', undefined, Array];

const initFormValue = {
  glns: [],
};

const SearchGridItemModal = (props, ref) => {
  const {
    searchType = 'member',
    isMultiple = true,
    apiUrl,
    params,
    title,
    tagRender,
    savedItem,
    savedSelected = false,
    service,
    dataPath,
    mapSearch,
    itemIdName,
    glnList,
    memberId,
    onlyFetchWithSearchText = true,
    ...rest
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(selectedItemsDefine[0]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [showSearchHint, setShowSearchHint] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const moveForm = useFormHook(initFormValue);

  const refSelectedItems = useRef([]);

  const [formInstance] = Form.useForm();

  const reloadPage = useDispatchReloadPage();
  const getServices = (params) => {
    if (service) return service(params);

    const endpoint = apiUrl;
    return api.sendPost(endpoint, params);
  };

  const fetchItemList = async (customParams = {}) => {
    setIsLoading(true);

    //* search Hint only show before first fetch
    showSearchHint && onlyFetchWithSearchText && setShowSearchHint(false);

    const response = await getServices({
      ...params,
      ...customParams,
    });
    setIsLoading(false);
    if (response?.isSuccess) {
      const gridData = dataPath
        ? get(response, dataPath)
        : response?.data?.gridData;
      const paging = response?.data?.paging;
      setItems(gridData);
      setPageInfo(paging);
    }
  };

  const handleSearchItem = () => {
    const formValue = formInstance.getFieldsValue();

    const searchTextValue = formValue?.searchText;

    if (!searchTextValue) return;

    const procParams = mapToSearchParams(formValue, params, mapSearch);

    fetchItemList(procParams);
  };

  const handleClearSearch = () => {
    formInstance.resetFields();
    fetchItemList(params);
  };

  const handleSelectItem = (itemData) => {
    // todo - 21/1/2022 - move gln to same member - waiting for future change
    //* if same member Id does not allow to select
    // if (itemData?.[itemIdName] === memberId) return;

    setSelectedItems((prev) => {
      let nextValue;
      if (prev !== 'init' && prev !== undefined) {
        nextValue = [...prev];
      } else {
        nextValue = [];
      }

      const foundItemIdx = nextValue.findIndex(
        (item) =>
          (item?.[itemIdName] || item?.id) ===
          (itemData?.[itemIdName] || itemData?.id)
      );

      if (foundItemIdx !== -1) {
        nextValue.splice(foundItemIdx, 1);
      } else {
        if (!isMultiple) {
          nextValue = [itemData];
        } else {
          nextValue.push(itemData);
        }
      }

      return nextValue;
    });
  };

  const handleClickOk = async () => {
    refSelectedItems.current = selectedItems;

    setSubmitLoading(true);

    const response = await memberMaintenanceServices.moveGLNtoMember({
      sourceMemberId: memberId,
      targetMemberId: selectedItems?.[0]?.companyId,
      glns: moveForm?.values?.glns,
    });

    const { isSuccess } = response;

    if (isSuccess) {
      const successMsg = 'Move GLN(s) to selected member successfully';

      CustomNotification.success(successMsg);
      setIsModalOpen(false);
      reloadPage();
    } else {
      CustomNotification.error(response?.message || 'Failed to move GLN(s)');
    }

    setSubmitLoading(false);
  };

  const resetAll = () => {
    setSelectedItems(selectedItemsDefine[0]);
    setPageInfo(defaultPageInfo);
    formInstance.resetFields();
    moveForm.resetAll();
    setItems([]);
    setShowSearchHint(true);
  };

  // const makeDisableSameMember = (id) => {
  //   if (memberId === id) {
  //     return {
  //       opacity: '0.5',
  //       pointerEvent: 'none',
  //     };
  //   } else return {};
  // };

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setIsModalOpen(false);
    },
    openModal: () => {
      setIsModalOpen(true);
    },
  }));

  useEffect(() => {
    if (isModalOpen) {
      !onlyFetchWithSearchText && fetchItemList(params);
    } else {
      !savedSelected && resetAll();
    }
  }, [
    isModalOpen,
    JSON.stringify(params),
    savedSelected,
    onlyFetchWithSearchText,
  ]);

  const isDisabledOkBtn =
    !selectedItems?.length ||
    typeof selectedItems === 'string' ||
    !moveForm?.values?.glns?.length ||
    submitLoading;

  return (
    <StyledModal
      visible={isModalOpen}
      className='search-grid-item-gln-to-member__modal'
      title={title}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      onOk={handleClickOk}
      centered
      destroyOnClose
      maskClosable={false}
      okButtonProps={{
        disabled: isDisabledOkBtn,
      }}
      confirmLoading={submitLoading}
      {...rest}
    >
      <Form form={formInstance}>
        <Row style={{ height: '100%' }}>
          <Col>
            <Form.Item label='Search Text' name='searchText'>
              <Input
                style={{ width: 200 }}
                onPressEnter={handleSearchItem}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col>
            <Space>
              <SearchGridItemButton type='primary' onClick={handleSearchItem}>
                <SearchOutlined />
              </SearchGridItemButton>

              {!onlyFetchWithSearchText && (
                <SearchGridItemButton
                  type='primary'
                  onClick={handleClearSearch}
                >
                  Reset
                </SearchGridItemButton>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
      <Row className='search-grid-item-gln-to-member__modal-grid-n-preview'>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Row
            className='search-grid-item-gln-to-member__search-grid-wrap'
            style={{ flex: 1, overflow: 'hidden' }}
          >
            <SectionContainer>
              <SearchGrid
                items={items}
                handleSelectItem={handleSelectItem}
                selectedItems={selectedItems}
                isLoading={isLoading}
                searchType={searchType}
                itemIdName={itemIdName}
                showSearchHint={showSearchHint}
              />
            </SectionContainer>
          </Row>
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <SectionContainer>
            <GLNFormMove
              moveForm={moveForm}
              selectedItems={selectedItems}
              items={items}
              handleSelectItem={handleSelectItem}
              tagRender={tagRender}
              glnList={glnList}
            />
          </SectionContainer>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default forwardRef(SearchGridItemModal);
