import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Row, Col, message, Typography } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MdToolTypeAndFont from './MdToolTypeAndFont';
import { mdTextTypeList } from 'static/Constants';

import MdToolTextGuideAndResult from './MdToolTextGuideAndResult';
import classnames from 'classnames';

import { useWindowSize } from 'hooks/windowSize';

import './MdToolText.less';

const { TextArea } = Input;
const { Text } = Typography;

const newLineRegex = /\r\n|\r|\n/;
const multipleNewLineRegex = /\r\n{2}|\r{2}|\n{2}/;

const radioOption = [
  { label: 'Result', value: 'result' },
  { label: 'Sample', value: 'sample' },
];

const MdToolText = (props) => {
  const {
    onChangeColor,
    mdEditor,
    isColorPickerOpen,
    toggleTextEditTool,
  } = props;

  const [textColor, setTextColor] = useState('#000000A6');
  const [textValue, setTextValue] = useState('');
  const [fontType, setFontType] = useState('');
  const [fontSize, setFontSize] = useState(16);
  const [align, setAlign] = useState('');
  const [textType, setTextType] = useState('span');
  const [sideView, setSideView] = useState(radioOption[0].value);
  const [result, setResult] = useState('');

  const [screenWidth, screenHeight] = useWindowSize();
  const refToolText = useRef();
  const refToolTextPos = useRef();

  const mapFontAndColorToSuitableSyntax = useCallback(
    ({ text, textType, color, font, size, align, lineBreak }) => {
      if (textType === mdTextTypeList[1].value) {
        return (
          `${lineBreak?.head ? '\n' : ''}` +
          '(#' +
          `${color ? 'color=' + color : ''}` +
          `${font ? ' | ' : ''}` +
          `${font ? 'font=' + font : ''}` +
          `${size ? ' | ' : ''}` +
          `${size ? 'size=' + size : ''}` +
          `${align ? ' | ' : ''}` +
          `${align ? 'align=' + align : ''}` +
          ')' +
          `${lineBreak?.middle ? '\n' : ''}` +
          text +
          `${lineBreak?.middle ? '\n' : ''}` +
          '(~)' +
          `${lineBreak?.tail ? '\n' : ''}`
        );
      }
      if (textType === mdTextTypeList[0].value) {
        return (
          `${lineBreak?.head ? '\n' : ''}` +
          '(!p|' +
          `${color ? 'color=' + color : ''}` +
          `${font ? ' | ' : ''}` +
          `${font ? 'font=' + font : ''}` +
          `${size ? ' | ' : ''}` +
          `${size ? 'size=' + size : ''}` +
          `${align ? ' | ' : ''}` +
          `${align ? 'align=' + align : ''}` +
          ')' +
          `${text ? `\n` : ''}` +
          text +
          `${lineBreak?.tail ? '\n\n' : ''}`
        );
      }
    },
    []
  );

  const getLineBreakConfig = (text, textType) => {
    const match = newLineRegex.exec(text);
    const multiMatch = multipleNewLineRegex.exec(text);

    if (textType === mdTextTypeList[1].value) {
      return {
        head: match ? true : false,
        middle: match && !multiMatch ? true : false,
        tail: true,
      };
    }

    //* paragraph
    if (textType === mdTextTypeList[0].value) {
      return {
        head: false,
        middle: undefined,
        tail: true,
      };
    }
  };

  const generateTextWithColor = useCallback(
    ({ text, color, font, size, align, textType }) => {
      if (textType === mdTextTypeList[1].value) {
        const match = newLineRegex.exec(text);
        const multiMatch = multipleNewLineRegex.exec(text);

        if (match && multiMatch) {
          return {
            error:
              'There are more than two paragraphs in text which is not allow',
          };
        }

        if (!color && !font)
          return {
            error: 'No config Found',
          };
        if (text === '')
          return {
            error: 'No text found',
          };
      } else {
        if (!color && !font)
          return {
            error: 'No config Found',
          };
      }

      return mapFontAndColorToSuitableSyntax({
        text,
        textType,
        color,
        font,
        size,
        align,
        lineBreak: getLineBreakConfig(text, textType),
      });
    },
    [mapFontAndColorToSuitableSyntax, mdEditor]
  );

  const changeTextColor = useCallback(
    (value) => {
      setTextColor(value);
      setSideView(radioOption[0].value);
      onChangeColor && onChangeColor(value);
    },
    [onChangeColor]
  );

  const changeTextFont = useCallback((value) => {
    setFontType(value);
    setSideView(radioOption[0].value);
  }, []);

  const changeTextSize = useCallback((value) => {
    setFontSize(value);
    setSideView(radioOption[0].value);
  }, []);

  const insertTextWithColor = () => {
    if (mdEditor) {
      let insertText = generateTextWithColor({
        text: textValue,
        color: textColor,
        font: fontType,
        size: fontSize,
        align,
        textType,
      });

      if (!insertText?.error) {
        // insertText += '\n';
        mdEditor.doc.replaceSelection(insertText);
      } else {
        message.error({
          content: <Text>{insertText?.error}</Text>,
        });
      }
    }
  };

  const hanldeMessageColorPicker = (text) => {
    if (text?.error) {
      message.error({
        content: <Text>{text?.error}</Text>,
      });
    } else {
      message.success({
        content: <Text>Copied Successfully</Text>,
      });
    }
  };

  const clearTextValue = () => {
    setTextValue('');
  };

  const setSelectionText = (mdEditor) => {
    const selectedText = mdEditor.doc.getSelection();
    if (selectedText) {
      setTextValue(selectedText);
    }
  };

  const isParagraph = (text) => {
    const multiMatch = multipleNewLineRegex.exec(text);
    return multiMatch?.length > 0 ? true : false;
  };

  const mapToToolPos = () => {
    let adjustTop, adjustLeft;

    if (refToolTextPos.current && screenHeight && screenWidth) {
      const toolTextHeight = 244;
      const toolTextWidth = 550;
      adjustTop = screenHeight - refToolTextPos.current?.top - toolTextHeight;
      adjustLeft = screenWidth - refToolTextPos.current?.left - toolTextWidth;
    }

    return { adjustLeft, adjustTop };
  };

  useEffect(() => {
    if (!refToolTextPos.current && refToolText.current) {
      refToolTextPos.current = {
        top: refToolText.current.getBoundingClientRect().top,
        left: refToolText.current.getBoundingClientRect().left,
      };
    }
  }, []);

  useEffect(() => {
    setResult(
      generateTextWithColor({
        text: textValue,
        color: textColor,
        font: fontType,
        size: fontSize,
        align: align,
        textType,
      })
    );
  }, [
    textValue,
    textColor,
    fontType,
    textType,
    fontSize,
    align,
    generateTextWithColor,
  ]);

  useEffect(() => {
    if (isColorPickerOpen) {
      setSelectionText(mdEditor);
    }
  }, [isColorPickerOpen, mdEditor]);

  const { adjustLeft, adjustTop } = mapToToolPos();

  return (
    <div
      ref={refToolText}
      className={classnames('md-tool-text', {
        'md-tool-text--hidden': !screenWidth || !screenHeight,
      })}
      style={{
        top: `calc(28px + ${adjustTop < 0 ? adjustTop - 7 : 0}px)`,
        left: `calc(-162px + ${adjustLeft < 0 ? adjustLeft - 7 : 0}px)`,
      }}
    >
      <div className='md-tool-text__toolbar'>
        <Button
          className={classnames(
            'md-tool-text__feat-btn',
            'md-tool-text__feat-btn--close'
          )}
          type='primary'
          onClick={() => {
            toggleTextEditTool && toggleTextEditTool();
          }}
        >
          Close
        </Button>

        <MdToolTypeAndFont
          selectedFont={fontType}
          fontType={fontType}
          setFontType={changeTextFont}
          fontSize={fontSize}
          setFontSize={changeTextSize}
          textType={textType}
          setTextType={setTextType}
          sideView={sideView}
          setSideView={setSideView}
          radioOption={radioOption}
          textColor={textColor}
          handleChangeColor={changeTextColor}
        />
      </div>
      <div className='md-tool-text__text-edit'>
        <Row gutter={[4, 4]}>
          <Col xs={10}>
            {textType === mdTextTypeList[1].value ? (
              <TextArea
                className={classnames('md-tool-text__text-input')}
                style={{ color: textColor || 'rgb(0,0,0, 0.65)' }}
                value={textValue}
                onChange={(event) => {
                  setTextValue(event.target.value);
                  setSideView(radioOption[0].value);
                }}
              />
            ) : (
              <TextArea
                className={classnames('md-tool-text__text-input')}
                style={{
                  color: textColor || 'rgb(0,0,0, 0.65)',
                  background: isParagraph(textValue),
                }}
                value={textValue}
                onChange={(event) => {
                  setTextValue(event.target.value);
                  setSideView(radioOption[0].value);
                }}
              />
            )}
            <Row>
              <Col flex='auto' />
              <Col>
                <Button
                  className={classnames(
                    'md-tool-text__feat-btn',
                    'md-tool-text__feat-btn--copy'
                  )}
                  type='primary'
                  onClick={() => {
                    insertTextWithColor();
                  }}
                >
                  Insert
                </Button>
              </Col>
              <Col>
                <CopyToClipboard
                  text={generateTextWithColor({
                    text: textValue,
                    color: textColor,
                    font: fontType,
                    size: fontSize,
                    align,
                    textType,
                  })}
                  // text, color, font, size, align, textType;

                  onCopy={(text, result) => {
                    hanldeMessageColorPicker(text);
                  }}
                >
                  <Button
                    className={classnames(
                      'md-tool-text__feat-btn',
                      'md-tool-text__feat-btn--copy'
                    )}
                    type='primary'
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </Col>

              <Col>
                <Button
                  className={classnames(
                    'md-tool-text__feat-btn',
                    'md-tool-text__feat-btn--clear'
                  )}
                  onClick={() => {
                    clearTextValue();
                  }}
                  danger
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
          <MdToolTextGuideAndResult
            textType={textType}
            sideView={sideView}
            radioOption={radioOption}
            result={result}
          />
        </Row>
      </div>
    </div>
  );
};

MdToolText.propTypes = {
  onChangeColor: PropTypes.func,
  mdEditor: PropTypes.object,
  isColorPickerOpen: PropTypes.bool,
  setIsColorPickerOpen: PropTypes.func,
};

export default MdToolText;
