import React, { useLayoutEffect, useRef, useState } from 'react';

const WrapperCalculatorSize = ({ children }) => {
  const ref = useRef();
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({
        width: ref?.current.clientWidth,
        height: ref?.current?.clientHeight,
      });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={ref}>
      {children(size)}
    </div>
  );
};

export default WrapperCalculatorSize;
