import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Space, Input } from 'antd';

import classnames from 'classnames';
import _ from 'lodash';

import {
  Form,
  FormAddButton,
  FormDeleteButton,
  WithLoading,
} from 'common/components';
import { QaGridEdit, EditContainer } from 'pages/qa-spec/components';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useTabExpand } from 'hooks/useTabExpandProduct';
import {
  transformQaComponentGrid,
  useQaSpecComponents,
} from 'pages/qa-spec/hooks/useQaSpecComponents';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import {
  SnapshotInputWrapper,
  SnapshotFormItemWrapper,
  FormLabel,
} from 'pages/qa-spec/components/form';
import { useGetSnapshotFormItemWrapperProps } from 'pages/qa-spec/components/form/hooks';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import { useQueryQaSpecComponentSnapshot } from './queries';

const QaSpecComponent = (props) => {
  const [form] = Form.useForm();

  const { productId } = props;

  const [isGridEditing, setIsGridEditing] = useState(false);

  const { tableStyle } = useTabExpand();

  const {
    gridInst,
    rowData,
    columnDefs,
    selectedRow,
    selectRow,
    addRow,
    deleteRow,
    onSave,
    onCancel,
    onCellEditingStopped,
  } = useQaSpecComponents({
    productId,
    isGridEditing,
    formInst: form,
  });

  const useSnapshot = useCheckSnapshotForRetailer();
  const { data: componentSnapshotData } = useQueryQaSpecComponentSnapshot({
    productId,
    enabled: Boolean(productId && useSnapshot),
  });

  // transform for display the partOf field in the grid
  const transformRowData = useMemo(
    () => transformQaComponentGrid(rowData),
    [rowData]
  );

  const onClickAddBtn = () => {
    addRow();
  };

  const onClickDeleteBtn = () => {
    deleteRow();
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const rowClassRules = useMemo(
    () => ({
      'ag-row--error': (params) => {
        return !!params?.data?.error?.length > 0;
      },
    }),
    []
  );

  const { getSnapshotFormItemWrapperProps } =
    useGetSnapshotFormItemWrapperProps({
      snapshotValues: componentSnapshotData,
    });

  const onEdit = (isEditing) => {
    setIsGridEditing(isEditing);
  };

  return (
    <EditContainer
      type={EDIT_SECTION.QA_COMPONENTS}
      productId={productId}
      handleCancel={onCancel}
      handleSave={onSave}
      buttons={[
        <FormAddButton onClick={onClickAddBtn} />,
        <FormDeleteButton disabled={!selectedRow} onClick={onClickDeleteBtn} />,
      ]}
      onEditChange={onEdit}
    >
      {(isEdit) => {
        return (
          <WithLoading loading={false}>
            <EditTabSectionWrapper.CheckerWrapper
              isEdit={isEdit}
              type={EDIT_SECTION.QA_COMPONENTS}
            >
              <Row>
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Row style={{ width: '100%' }}>
                    <Col span={24}>
                      <Row style={{ width: '100%' }}>
                        {columnDefs && columnDefs.length > 0 && (
                          <div
                            style={{
                              ...tableStyle,
                              width: '100%',
                            }}
                            className={classnames(
                              'ag-theme-alpine',
                              'ag-theme-alpine--with-scroller'
                            )}
                          >
                            <QaGridEdit
                              gridInst={gridInst}
                              gridData={_.cloneDeep(transformRowData)}
                              fields={columnDefs}
                              isEdit={isEdit}
                              getRowNodeId={(data) => {
                                return data?.['uuid'];
                              }}
                              selectedRow={selectedRow}
                              onRowSelected={selectRow}
                              rowSelection='single'
                              rowClassRules={rowClassRules}
                              onCellEditingStopped={onCellEditingStopped}
                              snapshotGridValues={
                                componentSnapshotData?.components
                              }
                            />
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Form
                    form={form}
                    {...formItemLayout}
                    name='QA Spec Component'
                    style={{ width: '100%' }}
                  >
                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'productClaims',
                        label: 'Product Claims',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          label={<FormLabel label='Product Claims' />}
                          name='productClaims'
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              style={{
                                pointerEvents: isEdit ? 'auto' : 'none',
                              }}
                              rows={4}
                              maxLength={500}
                              showCount
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>
                  </Form>
                </Space>
              </Row>
            </EditTabSectionWrapper.CheckerWrapper>
          </WithLoading>
        );
      }}
    </EditContainer>
  );
};

QaSpecComponent.propTypes = {
  productItemModules: PropTypes.object,
};
export default QaSpecComponent;
