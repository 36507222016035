import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getSnapshotQaSpecHeader } from 'services/product';

const getQueryKeyQaSpecDataHeader = (productId) => {
  return ['pim', 'snapshot', parseInt(productId), 'spec-data-header'];
};

export const useGetSnapshotQaDataHeader = ({
  enabled,
  productId,
  ...restProps
}) => {
  const queryClient = useQueryClient();

  const getSnapshotQaSpecDataHeader = async () => {
    const response = await getSnapshotQaSpecHeader({ productId });
    const { isSuccess, data } = response;
    if (!isSuccess) return null;

    return data;
  };

  const query = useQuery({
    queryKey: getQueryKeyQaSpecDataHeader(productId),
    queryFn: getSnapshotQaSpecDataHeader,
    enabled,
    ...restProps,
  });

  const invalidateSnapshotQaSpecDataHeader = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyQaSpecDataHeader(productId),
    });
  };

  return { ...query, invalidateSnapshotQaSpecDataHeader };
};
