import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getCategoryTreeView = (params) => {
  return api.sendGet(endpoints.GET_CATEGORY_TREE_VIEW, { params });
};

export const createCategory = (data) => {
  return api.sendPost(endpoints.CREATE_CATEGORY, data);
};

export const editCategory = (data) => {
  return api.sendPost(endpoints.EDIT_CATEGORY, data);
};

export const removeCategory = (data) => {
  return api.sendPost(endpoints.REMOVE_CATEGORY, data);
};

export const validateUploadCategory = (data) => {
  return api.sendPost(endpoints.VALIDATE_UPLOAD_CATEGORY, data);
};

export const downloadCategoryForm = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_CATEGORIES_FORM, { params });
};

export const saveUploadCategory = (data) => {
  return api.sendPost(endpoints.SAVE_UPLOAD_CATEGORIES, data);
};

export const assignCategoryToProduct = (data) => {
  return api.sendPost(endpoints.ASSIGN_CATEGORY_TO_PRODUCTS, data);
};

export const validateBulkReassignProducts = (data) => {
  return api.sendPost(endpoints.VALIDATE_BULK_REASSIGN_PRODUCTS, data);
};

export const saveBulkReassignProducts = (data) => {
  return api.sendPost(endpoints.SAVE_BULK_REASSIGN_PRODUCTS, data);
};

export const getProductCategory = (params) => {
  return api.sendGet(endpoints.GET_PRODUCT_CATEGORY, { params });
};
