import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { StopOutlined } from '@ant-design/icons';

import { dialogFunction } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';

import userSelectors from 'redux/user/selectors';

import { apiHandler } from 'utils/api';
import * as subscriptionServices from 'services/subscription';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';

const Unsubscribe = (props) => {
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();

  const { disabled, selectedSubscription } = props;

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const { isRivirGdsnParty } = userInfo;

  const [submitLoading, setSubmitLoading] = useState(false);

  const subscriptionType = selectedSubscription?.subscriptionType;

  const isActiveSubscription = selectedSubscription?.status === 'Active';
  const isPendingSubscription = selectedSubscription?.status === 'Pending';
  const canUnsubscribe =
    subscriptionType === 'RIVIR'
      ? isActiveSubscription || isPendingSubscription
      : isRivirGdsnParty && (isActiveSubscription || isPendingSubscription);

  const onClickHandler = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.unsubscribeConfirmMessage),
      onOk: callApiUnsubscribe,
      okButtonProps: {
        loading: submitLoading,
      },
    });
  };

  const callApiUnsubscribe = () => {
    setSubmitLoading(true);

    const params = {
      subId: selectedSubscription?.id,
    };

    const successMessage = intl.formatMessage(Messages.unsubscribeSuccess);
    const errorMessage = intl.formatMessage(Messages.unsubscribeError);

    apiHandler({
      service: subscriptionServices.unsubscribe,
      params,
      successMessage,
      errorMessage,
      successCallback,
      onFinally,
    });
  };

  const successCallback = () => {
    reloadPage();
  };

  const onFinally = () => {
    setSubmitLoading(false);
  };

  const isDisabled = disabled || !canUnsubscribe;

  return (
    <RibbonButton
      icon={<StopOutlined style={{ height: 26 }} />}
      label={Messages.subscriptionUnsubscribe}
      onClick={onClickHandler}
      disabled={isDisabled}
    />
  );
};

export default Unsubscribe;
