import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getAllCateGories = async () => {
  return api.sendGet(endpoints.GET_HELP_ALL_CATEGORY);
};

export const getCategoryDetail = async (id) => {
  const options = {
    params: {
      id,
    },
  };
  return api.sendGet(endpoints.GET__HELP_CATEGORY_DETAIL, options);
};

export const getPostDetail = async (postId) => {
  const options = {
    params: {
      PostId: postId,
    },
  };
  return api.sendGet(endpoints.GET_HELP_POST_DETAIL, options);
};

export const searchHelpPosts = (params) => {
  return api.sendPost(endpoints.SEARCH_HELP_POSTS, params);
};

export const searchHelpAutoComplete = async (text) => {
  const options = {
    params: {
      searchText: text,
    },
  };
  return api.sendGet(endpoints.SEARCH_HELP_AUTO_COMPLETE, options);
};
