import * as types from './constants';

export function getCommunicationDetail(payload) {
  return {
    type: types.GET_COMMUNICATION_DETAIL_ITEM,
    payload,
  };
}

export function getCommunicationDetailSuccess(payload) {
  return {
    type: types.GET_COMMUNICATION_DETAIL_ITEM_SUCCESS,
    payload,
  };
}

export function getCommunicationDetailFail(payload) {
  return {
    type: types.GET_COMMUNICATION_DETAIL_ITEM_FAIL,
    payload,
  };
}

export function getCommunicationList(payload) {
  return {
    type: types.GET_COMMUNICATION_LIST,
    payload,
  };
}

export function getCommunicationListSuccess(payload) {
  return {
    type: types.GET_COMMUNICATION_LIST_SUCCESS,
    payload,
  };
}

export function getCommunicationListFail(payload) {
  return {
    type: types.GET_COMMUNICATION_LIST_FAIL,
    payload,
  };
}

export function getCommunicationListMore(payload) {
  return {
    type: types.GET_COMMUNICATION_LIST_MORE,
    payload,
  };
}

export function getCommunicationListMoreSuccess(payload) {
  return {
    type: types.GET_COMMUNICATION_LIST_MORE_SUCCESS,
    payload,
  };
}

export function getCommunicationListMoreFail(payload) {
  return {
    type: types.GET_COMMUNICATION_LIST_MORE_FAIL,
    payload,
  };
}
export function toggleChatFullView(payload) {
  return {
    type: types.TOGGLE_CHAT_FULL_VIEW,
    payload,
  };
}

export function minimizeChat(payload) {
  return {
    type: types.MINIMIZE_CHAT,
  };
}

export function toggleChatBox(payload) {
  return {
    type: types.TOGGLE_CHAT_BOX,
  };
}

export function minimizeChatThread(payload) {
  return {
    type: types.MINIMIZE_CHAT_THREAD,
    payload,
  };
}

export function showCreateNewChatWithUser(payload) {
  return {
    type: types.SHOW_NEW_CHAT_WITH_USER,
    payload,
  };
}

export function showCreateNewChatWithCompany(payload) {
  return {
    type: types.SHOW_NEW_CHAT_WITH_COMPANY,
    payload,
  };
}

export const setNewMsgReceived = (msgInfo) => {
  return {
    type: types.SET_NEW_MSG,
    msgInfo,
  };
};

export const getThreadInfo = (threadId, userId) => {
  return {
    type: types.GET_THREAD_INFO,
    threadId,
    userId,
  };
};
export const getThreadInfoSuccess = (threadInfo) => {
  return {
    type: types.GET_THREAD_INFO_SUCCESS,
    threadInfo,
  };
};
export const getThreadInfoError = (error, id) => {
  return {
    type: types.GET_THREAD_INFO_ERROR,
    error,
    id,
  };
};

export const removeThread = (id) => {
  return {
    type: types.REMOVE_THREAD,
    id,
  };
};
export function getThreadListForSupporter(payload) {
  return {
    type: types.GET_THREAD_LIST_FOR_SUPPORTER,
    payload,
  };
}

export function getThreadListForSupporterSuccess(payload) {
  return {
    type: types.GET_THREAD_LIST_FOR_SUPPORTER_SUCCESS,
    payload,
  };
}

export function getThreadListForSupporterFail(payload) {
  return {
    type: types.GET_THREAD_LIST_FOR_SUPPORTER_FAIL,
    payload,
  };
}

export function getChatContentDetail(payload) {
  return {
    type: types.GET_CHAT_CONTENT_DETAIL,
    payload,
  };
}

export function getThreadMessages(threadId, timestamp, direction = 0) {
  return {
    type: types.GET_THREAD_MESSAGES,
    threadId,
    timestamp,
    direction,
  };
}

export function getThreadMessagesSuccess(messages) {
  return {
    type: types.GET_THREAD_MESSAGES_SUCCESS,
    messages,
  };
}

export function getThreadMessagesFail(payload) {
  return {
    type: types.GET_THREAD_MESSAGES_FAIL,
  };
}

export function getChatContentDetailSuccess(payload) {
  return {
    type: types.GET_CHAT_CONTENT_DETAIL_SUCCESS,
    payload,
  };
}

export function getChatContentDetailFail(payload) {
  return {
    type: types.GET_CHAT_CONTENT_DETAIL_FAIL,
    payload,
  };
}

export function sendMessageChat(payload) {
  return {
    type: types.SEND_MESSAGE,
    payload,
  };
}

export function sendMessageChatSuccess(payload) {
  return {
    type: types.SEND_MESSAGE_SUCCESS,
    payload,
  };
}

export function sendMessageChatError(payload) {
  return {
    type: types.SEND_MESSAGE_ERROR,
    payload,
  };
}

export function toggleNewMessage(value) {
  return {
    type: types.TOGGLE_NEW_MESSAGE,
    value,
  };
}

export function saveAccountChat(payload) {
  return {
    type: types.SAVE_ACCOUNT_CHAT,
    payload,
  };
}

export function setUnreadMessages(messages) {
  return {
    type: types.SET_UNREAD_MESSAGES,
    messages,
  };
}

export function setClearUnreadMessages() {
  return {
    type: types.SET_CLEAR_UNREAD_MESSAGES,
  };
}

export function filterNewMsgInThreadMsg(newMsg) {
  return {
    type: types.FILTER_NEW_MSG_IN_THREAD_MSG,
    newMsg,
  };
}

export function updateCommunicationThreadList(payload) {
  return {
    type: types.UPDATE_COMMUNICATION_THREAD_LIST,
    payload,
  };
}

export function resetStatusThreadInfo() {
  return {
    type: types.RESET_STATUS_THREAD_INFO,
  };
}

export function receiveSentMsgSameAccount(newMsg) {
  return {
    type: types.RECEIVE_SENT_MESSAGE_SAME_ACCOUNT,
    newMsg,
  };
}

export function resetStatusMsgThread() {
  return {
    type: types.RESET_STATUS_MSG_THREAD,
  };
}

export function saveActiveThreadInfo(threadInfo) {
  return {
    type: types.SAVE_ACTIVE_THREAD_INFO,
    threadInfo,
  };
}

export function saveIndexChatFile(index) {
  return {
    type: types.SAVE_INDEX_CHAT_FILE,
    index,
  };
}

export function selectSearchResult(item) {
  return {
    type: types.SELECT_SEARCH_RESULT,
    payload: item,
  };
}

export function updateFinishLoadDown(payload) {
  return {
    type: types.UPDATE_FINISH_LOAD_DOWN,
    payload,
  };
}

export function updateSelectedSearchResultPosition(payload) {
  return {
    type: types.UPDATE_SEARCH_RESULT_POSITION,
    payload,
  };
}

export function updateLastMessage(payload) {
  return {
    type: types.UPDATE_LAST_MESSAGE,
    payload,
  };
}
export function updateShowThreadWarningModal(value) {
  return {
    type: types.UPDATE_SHOW_THREAD_WARNING_MODAL,
    value,
  };
}

export function updateThreadInfoError() {
  return {
    type: types.UPDATE_THREAD_INFO_ERROR,
  };
}

//? scroll chat thread to bottom
export function scrollChatThreadToBottom(status) {
  return { type: types.SCROLL_CHAT_THREAD_BOTTOM, status };
}

//? toggle chat spinning
export function toggleChatSpinning(status) {
  return { type: types.TOGGLE_CHAT_SPINNING, status };
}

export function resetChatView(payload) {
  return { type: types.RESET_CHAT_VIEW };
}

export function setChatView(payload) {
  return { type: types.SET_CHAT_VIEW, payload };
}

export function setIsFocusChat(status) {
  return { type: types.SET_IS_FOCUS_CHAT, status };
}

export function resetThreadInfo() {
  return { type: types.RESET_THREAD_INFO };
}
