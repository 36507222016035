export const GET_PRODUCT_HIERARCHY_SHORT_DETAIL =
  'GET_PRODUCT_HIERARCHY_SHORT_DETAIL';
export const GET_PRODUCT_HIERARCHY_SHORT_DETAIL_SUCCESS =
  'GET_PRODUCT_HIERARCHY_SHORT_DETAIL_SUCCESS';
export const GET_PRODUCT_HIERARCHY_SHORT_DETAIL_ERROR =
  'GET_PRODUCT_HIERARCHY_SHORT_DETAIL_ERROR';
export const RESET_PRODUCT_HIERARCHY_SHORT_DETAIL =
  'RESET_PRODUCT_HIERARCHY_SHORT_DETAIL';

// get product documents
export const GET_PRODUCT_DOCUMENTS = 'GET_PRODUCT_DOCUMENTS';
export const GET_PRODUCT_DOCUMENTS_SUCCESS = 'GET_PRODUCT_DOCUMENTS_SUCCESS';
export const GET_PRODUCT_DOCUMENTS_ERROR = 'GET_PRODUCT_DOCUMENTS_ERROR';
export const UPDATE_ACTIVE_CONTENT = 'UPDATE_ACTIVE_CONTENT';
export const UPDATE_SELECTED_HIERARCHY = 'UPDATE_SELECTED_HIERARCHY';

// Product Shields
export const SHIELD_NOT_SUBSCRIBED = 'not subscribed';
export const SHIELD_SUBSCRIBED_WITHOUT_SYNDICATION =
  'subscribed without syndication';
export const SHIELD_SUBSCRIBED_AND_SYNDICATION = 'subscribed and syndication';
export const SHIELD_SUBSCRIBED_AND_SYNDICATION_NOT_OCCURRED =
  'subscribed and syndication has not occurred';
export const SHIELD_SUBSCRIPTED_AND_SYNDICATION_OUT_OF_DATE =
  'subscribed and syndication is out of date';

//* product header
export const GET_PRODUCT_BRAND_IMAGE = 'GET_PRODUCT_BRAND_IMAGE';
export const GET_PRODUCT_BRAND_IMAGE_SUCCESS =
  'GET_PRODUCT_BRAND_IMAGE_SUCCESS';
export const GET_PRODUCT_BRAND_IMAGE_FAIL = 'GET_PRODUCT_BRAND_IMAGE_FAIL';

export const GET_PRODUCT_COMPANY_INFO = 'GET_PRODUCT_COMPANY_INFO';
export const GET_PRODUCT_COMPANY_INFO_SUCCESS =
  'GET_PRODUCT_COMPANY_INFO_SUCCESS';
export const GET_PRODUCT_COMPANY_INFO_FAIL = 'GET_PRODUCT_COMPANY_INFO_FAIL';

export const GET_PRODUCT_SOCIAL_MEDIA = 'GET_PRODUCT_SOCIAL_MEDIA';
export const GET_PRODUCT_SOCIAL_MEDIA_SUCCESS =
  'GET_PRODUCT_SOCIAL_MEDIA_SUCCESS';
export const GET_PRODUCT_SOCIAL_MEDIA_FAIL = 'GET_PRODUCT_SOCIAL_MEDIA_FAIL';
export const GET_MARKETING_ASSETS = 'GET_MARKETING_ASSETS';
export const GET_MARKETING_ASSETS_SUCCESS = 'GET_MARKETING_ASSETS_SUCCESS';
export const GET_MARKETING_ASSETS_FAIL = 'GET_MARKETING_ASSETS_FAIL';
export const GET_MARKETING_ASSETS_PRIMARY_SUCCESS =
  'GET_MARKETING_ASSETS_PRIMARY_SUCCESS';
export const GET_MARKETING_ASSETS_PRIMARY = 'GET_MARKETING_ASSETS_PRIMARY';
export const GET_MARKETING_ASSETS_PRIMARY_FAIL =
  'GET_MARKETING_ASSETS_PRIMARY_FAIL';

export const GET_PRODUCT_FACTS_PANEL = 'GET_PRODUCT_FACTS_PANEL';
export const GET_PRODUCT_FACTS_PANEL_SUCCESS =
  'GET_PRODUCT_FACTS_PANEL_SUCCESS';
export const GET_PRODUCT_FACTS_PANEL_ERROR = 'GET_PRODUCT_FACTS_PANEL_ERROR';

export const UPDATE_PRODUCT_FACTS_PANEL = 'UPDATE_PRODUCT_FACTS_PANEL';
export const UPDATE_PRODUCT_FACTS_PANEL_SUCCESS =
  'UPDATE_PRODUCT_FACTS_PANEL_SUCCESS';
export const UPDATE_PRODUCT_FACTS_PANEL_ERROR =
  'UPDATE_PRODUCT_FACTS_PANEL_ERROR';

export const GET_PRODUCT_IMAGERY = 'GET_PRODUCT_IMAGERY';
export const GET_PRODUCT_IMAGERY_SUCCESS = 'GET_PRODUCT_IMAGERY_SUCCESS';
export const GET_PRODUCT_IMAGERY_FAIL = 'GET_PRODUCT_IMAGERY_FAIL';
export const RESET_PRODUCT_IMAGERY = 'RESET_PRODUCT_IMAGERY';
export const SET_CURRENT_HIERARCHY = 'SET_CURRENT_HIERARCHY';

// Product hierarchy short details to show drawer
export const GET_PRODUCT_HIERARCHY_DETAILS = 'GET_PRODUCT_HIERARCHY_DETAILS';
export const GET_PRODUCT_HIERARCHY_DETAILS_SUCCESS =
  'GET_PRODUCT_HIERARCHY_DETAILS_SUCCESS';
export const GET_PRODUCT_HIERARCHY_DETAILS_ERROR =
  'GET_PRODUCT_HIERARCHY_DETAILS_ERROR';
export const RESET_PRODUCT_HIERARCHY_DETAILS =
  'RESET_PRODUCT_HIERARCHY_DETAILS';
// update hierarchy short details mode to show drawer
export const UPDATE_HIERARCHY_SHORT_DETAILS_DISPLAY_MODE =
  'UPDATE_HIERARCHY_SHORT_DETAILS_DISPLAY_MODE';
export const UPDATE_HIERARCHY_ENDPOINT = 'UPDATE_HIERARCHY_ENDPOINT';

export const EDIT_PRODUCT_DETAIL = 'EDIT_PRODUCT_DETAIL';
export const EDIT_PRODUCT_DETAIL_SUCCESS = 'EDIT_PRODUCT_DETAIL_SUCCESS';
export const EDIT_PRODUCT_DETAIL_FAIL = 'EDIT_PRODUCT_DETAIL_FAIL';

export const GET_SUBSCRIPTION_PRODUCT_LIST = 'GET_SUBSCRIPTION_PRODUCT_LIST';
export const GET_SUBSCRIPTION_PRODUCT_LIST_SUCCESS =
  'GET_SUBSCRIPTION_PRODUCT_LIST_SUCCESS';
export const GET_SUBSCRIPTION_PRODUCT_LIST_ERROR =
  'GET_SUBSCRIPTION_PRODUCT_LIST_ERROR';

export const SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO =
  'SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO';
export const SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_SUCCESS =
  'SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_SUCCESS';
export const SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_ERROR =
  'SUBSCRIPTION_PRODUCT_GRID_COLUMN_INFO_ERROR';

export const GET_MAPPING_GRID_COLUMN = 'GET_MAPPING_GRID_COLUMN';
export const GET_MAPPING_GRID_COLUMN_SUCCESS =
  'GET_MAPPING_GRID_COLUMN_SUCCESS';

export const UPDATE_SELECTION_SUBSCRIPTION = 'UPDATE_SELECTION_SUBSCRIPTION';

export const GET_MAPPING_METADATA = 'GET_MAPPING_METADATA';
export const GET_MAPPING_METADATA_SUCCESS = 'GET_MAPPING_METADATA_SUCCESS';
export const GET_MAPPING_METADATA_FAIL = 'GET_MAPPING_METADATA_FAIL';

export const GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN =
  'GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN';

export const GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_SUCCESS =
  'GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_SUCCESS';

export const GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_ERROR =
  'GET_MAPPING_DATA_PROPERTIES_GRID_COLUMN_ERROR';

export const GET_MAPPING_SUPPORT_MEM_COLUMN = 'GET_MAPPING_SUPPORT_MEM_COLUMN';
export const GET_MAPPING_SUPPORT_MEM_COLUMN_SUCCESS =
  'GET_MAPPING_SUPPORT_MEM_COLUMN_SUCCESS';

export const SET_SELECTED_MAPPING_SUPPORT_MEM =
  'SET_SELECTED_MAPPING_SUPPORT_MEM';

export const RELOAD_MAPPING_SUPPORTED_MEMBER_GRID =
  'RELOAD_MAPPING_SUPPORTED_MEMBER_GRID';

export const RELOAD_MAPPING_SUPPORTED_MEMBER_GRID_SUCCESS =
  'RELOAD_MAPPING_SUPPORTED_MEMBER_GRID_SUCCESS';

export const SAVE_SELECTED_MAPPING = 'SAVE_SELECTED_MAPPING';

export const GET_SYNDICATION_HISTORY_GRID_COLUMNS =
  'GET_SYNDICATION_HISTORY_GRID_COLUMNS';
export const GET_SYNDICATION_HISTORY_GRID_COLUMNS_SUCCESS =
  'GET_SYNDICATION_HISTORY_GRID_COLUMNS_SUCCESS';
export const GET_SYNDICATION_HISTORY_GRID_COLUMNS_FAILURE =
  'GET_SYNDICATION_HISTORY_GRID_COLUMNS_FAILURE';

export const GET_SYNDICATION_HISTORY_SHORT_DETAILS =
  'GET_SYNDICATION_HISTORY_SHORT_DETAILS';
export const GET_SYNDICATION_HISTORY_SHORT_DETAILS_SUCCESS =
  'GET_SYNDICATION_HISTORY_SHORT_DETAILS_SUCCESS';
export const GET_SYNDICATION_HISTORY_SHORT_DETAILS_ERROR =
  'GET_SYNDICATION_HISTORY_SHORT_DETAILS_ERROR';

export const TOGGLE_PRODUCT_DETAIL_EXPAND = 'TOGGLE_PRODUCT_DETAIL_EXPAND';
