import { useGetProductFullView } from './product-full/hooks';

export const useProductFullView = (props = {}) => {
  const { productId } = props;

  const { productFull, handleRefetchProductFullView } = useGetProductFullView({
    productId,
  });

  const reloadProductFullView = () => {
    if (!productId) return;
    handleRefetchProductFullView();
  };

  return { reloadProductFullView, productFull };
};
