import React, { useEffect, useState } from 'react';

import * as _ from 'lodash';

import NutrientRow from '../../nutrition-label/components/NutrientRow';
import SeparatorBar from '../../nutrition-label/components/SeparatorBar';
import NutritionBreakdown from '../../nutrition-label/components/utils/NutritionBreakdown';

import { useGetBasicUomEnum } from 'hooks';
import { parseNumber } from 'utils/parser';
import { getUomTypesAbbreviations } from '../../nutrition-label/DailyValueConstants';
import { getNutritionNameByTypeCode } from '../../nutrition-label/constants';

import './VerticalLabel.less';
import { renderContainerDescriptor } from '../components/utils';

function VerticalLabel(props) {
  const { label } = props;

  const [nutrient, setNutrient] = useState([]);

  const { basicUomProperties } = useGetBasicUomEnum();

  useEffect(() => {
    async function getData() {
      const nb = await NutritionBreakdown(label?.nutritionFactProperties);
      setNutrient(nb);
    }
    if (label) {
      getData();
    }
  }, [label]);

  const { primaryNutrition, secondaryNutrition } = nutrient;

  const showAbbr = (quantityUom, defaultUom = 'g') => {
    return (
      getUomTypesAbbreviations(basicUomProperties, quantityUom) ?? defaultUom
    );
  };

  const servingPerContainerDescriptor = renderContainerDescriptor(
    label?.servingPerContainerDescriptor
  );

  return (
    <div class='nutrition-facts scroller'>
      <h1 className='nutrition-facts__label'>Nutrition Facts</h1>
      <SeparatorBar />
      <div className='nutrition-facts__servingsize'>
        {label?.numberOfServingsPerPackage != null ? (
          <div className='nutrition-facts__servings-per-container'>
            <p style={{ textTransform: 'capitalize', marginBottom: 0 }}>
              {servingPerContainerDescriptor ? (
                <span>{servingPerContainerDescriptor}&nbsp;</span>
              ) : null}
              {label?.numberOfServingsPerPackage} servings per container
            </p>
          </div>
        ) : null}
        <div className='nutrition-facts__servingsize-bold'>
          <div>Serving size</div>
          <div>
            {label?.servingSizeDescription}{' '}
            {label?.servingSize &&
              `(${label?.servingSize} ${getUomTypesAbbreviations(
                basicUomProperties,
                label?.servingSizeMeasurement
              )})`}
          </div>
        </div>
      </div>
      <SeparatorBar height={7} />
      <div className='nutrition-facts__calories-container'>
        <div>
          <div className='nutrition-facts__calories'>Amount Per Serving</div>
          <div className='nutrition-facts__calories_new-calories'>Calories</div>
        </div>

        <div className='nutrition-facts__calories_numeric'>
          {label?.calories}
        </div>
      </div>
      <SeparatorBar height={3} />
      <div className='nutrition-facts__dv-heading'>% Daily Value*</div>
      {primaryNutrition?.totalFat && (
        <NutrientRow
          label='Total Fat'
          nutrient={primaryNutrition?.totalFat}
          measurementPrecisionCode={
            primaryNutrition?.totalFat?.measurementPrecisionCode
          }
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.totalFat?.dailyValueIntakePercent
          )}
        />
      )}
      {primaryNutrition?.saturatedFat && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.saturatedFat?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.saturatedFat}
          indent={10}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.saturatedFat?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.saturatedFat?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.transFat && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.transFat?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.transFat}
          indent={10}
          percent={parseNumber(
            primaryNutrition?.transFat?.dailyValueIntakePercent
          )}
          labelItalic={true}
          measurementPrecisionCode={
            primaryNutrition?.transFat?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.polyunsaturatedFat && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.polyunsaturatedFat?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.polyunsaturatedFat}
          percent={parseNumber(
            primaryNutrition?.polyunsaturatedFat?.dailyValueIntakePercent
          )}
          indent={10}
          measurementPrecisionCode={
            primaryNutrition?.polyunsaturatedFat?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.monounsaturatedFat && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.monounsaturatedFat?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.monounsaturatedFat}
          percent={parseNumber(
            primaryNutrition?.monounsaturatedFat?.dailyValueIntakePercent
          )}
          indent={10}
          measurementPrecisionCode={
            primaryNutrition?.monounsaturatedFat?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.cholesterol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.cholesterol?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.cholesterol}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.cholesterol?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.cholesterol?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.sodium && (
        <NutrientRow
          label='Sodium'
          nutrient={primaryNutrition?.sodium}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.sodium?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.sodium?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.fluoride && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.fluoride?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.fluoride}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.fluoride?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.fluoride?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.totalCarbohydrate && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.totalCarbohydrate?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.totalCarbohydrate}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.totalCarbohydrate?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.totalCarbohydrate?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.xylitol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.xylitol?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.xylitol}
          indent={10}
          percent={parseNumber(
            primaryNutrition?.xylitol?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.xylitol?.measurementPrecisionCode
          }
        />
      )}
      <NutrientRow
        label={getNutritionNameByTypeCode(
          primaryNutrition?.dietaryFiber?.nutritionTypeCode
        )}
        nutrient={primaryNutrition?.dietaryFiber}
        indent={10}
        percentBold={true}
        percent={parseNumber(
          primaryNutrition?.dietaryFiber?.dailyValueIntakePercent
        )}
        measurementPrecisionCode={
          primaryNutrition?.dietaryFiber?.measurementPrecisionCode
        }
      />
      {primaryNutrition?.fibreWaterSoluble && (
        <NutrientRow
          label='Soluble Fiber'
          nutrient={primaryNutrition?.fibreWaterSoluble}
          indent={15}
          indentBar={15}
          percent={parseNumber(
            primaryNutrition?.fibreWaterSoluble?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.fibreWaterSoluble?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.fibreWaterInsoluble && (
        <NutrientRow
          label='Insoluble Fiber'
          nutrient={primaryNutrition?.fibreWaterInsoluble}
          indent={15}
          indentBar={15}
          percent={parseNumber(
            primaryNutrition?.fibreWaterInsoluble?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.fibreWaterInsoluble?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.totalSugars && (
        <NutrientRow
          label={'Total Sugars'}
          nutrient={primaryNutrition?.totalSugars}
          indent={10}
          measurementPrecisionCode={
            primaryNutrition?.totalSugars?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.addedSugars && (
        <NutrientRow
          label={`Includes ${
            primaryNutrition?.addedSugars?.quantity >= 0
              ? parseNumber(primaryNutrition?.addedSugars?.quantity)
              : ''
          }${showAbbr(
            primaryNutrition?.addedSugars?.quantityUom,
            'g'
          )} Added Sugars`}
          indent={15}
          indentBar={15}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.addedSugars?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.addedSugars?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.sugarAlcohol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.sugarAlcohol?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.sugarAlcohol}
          indent={10}
          percent={parseNumber(
            primaryNutrition?.sugarAlcohol?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.sugarAlcohol?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.sorbitol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.sorbitol?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.sorbitol}
          indent={10}
          percent={parseNumber(
            primaryNutrition?.sorbitol?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.sorbitol?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.maltitol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.maltitol?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.maltitol}
          indent={10}
          percent={parseNumber(
            primaryNutrition?.maltitol?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.maltitol?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.erythritol && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.erythritol?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.erythritol}
          percent={parseNumber(
            primaryNutrition?.erythritol?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.erythritol?.measurementPrecisionCode
          }
        />
      )}
      {primaryNutrition?.protein && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            primaryNutrition?.protein?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.protein}
          labelBold={true}
          percentBold={true}
          percent={parseNumber(
            primaryNutrition?.protein?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            primaryNutrition?.protein?.measurementPrecisionCode
          }
        />
      )}
      <SeparatorBar height={7} />
      {secondaryNutrition?.iodide && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.iodide?.nutritionTypeCode
          )}
          nutrient={primaryNutrition?.iodide}
          percent={parseNumber(
            secondaryNutrition?.iodide?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.iodide?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.vitaminD && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.vitaminD?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.vitaminD}
          percent={parseNumber(
            secondaryNutrition?.vitaminD?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.vitaminD?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.calcium && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.calcium?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.calcium}
          percent={parseNumber(
            secondaryNutrition?.calcium?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.calcium?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.iron && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.iron?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.iron}
          percent={parseNumber(
            secondaryNutrition?.iron?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.iron?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.potassium && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.potassium?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.potassium}
          percent={parseNumber(
            secondaryNutrition?.potassium?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.potassium?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.vitaminA && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.vitaminA?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.vitaminA}
          percent={parseNumber(
            secondaryNutrition?.vitaminA?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.vitaminA?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.VitaminC && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.VitaminC?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.VitaminC}
          percent={parseNumber(
            secondaryNutrition?.VitaminC?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.VitaminC?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.VitaminE && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.VitaminE?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.VitaminE}
          percent={parseNumber(
            secondaryNutrition?.VitaminE?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.VitaminE?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.VitaminK && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.VitaminK?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.VitaminK}
          percent={parseNumber(
            secondaryNutrition?.VitaminK?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.VitaminK?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.thiamin && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.thiamin?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.thiamin}
          percent={parseNumber(
            secondaryNutrition?.thiamin?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.thiamin?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.riboflavin && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.riboflavin?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.riboflavin}
          percent={parseNumber(
            secondaryNutrition?.riboflavin?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.riboflavin?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.niacin && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.niacin?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.niacin}
          percent={parseNumber(
            secondaryNutrition?.niacin?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.niacin?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.vitaminB6 && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.vitaminB6?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.vitaminB6}
          percent={parseNumber(
            secondaryNutrition?.vitaminB6?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.vitaminB6?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.folicAcid && (
        <NutrientRow
          label={'Folic Acid'}
          nutrient={secondaryNutrition?.folicAcid}
          percent={parseNumber(
            secondaryNutrition?.folicAcid?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.folicAcid?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.folate && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.folate?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.folate}
          percent={parseNumber(
            secondaryNutrition?.folate?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.folate?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.vitaminB12 && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.vitaminB12?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.vitaminB12}
          percent={parseNumber(
            secondaryNutrition?.vitaminB12?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.vitaminB12?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.biotin && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.biotin?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.biotin}
          percent={parseNumber(
            secondaryNutrition?.biotin?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.biotin?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.pantothenicAcid && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.pantothenicAcid?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.pantothenicAcid}
          percent={parseNumber(
            secondaryNutrition?.pantothenicAcid?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.pantothenicAcid?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.phosphorus && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.phosphorus?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.phosphorus}
          percent={parseNumber(
            secondaryNutrition?.phosphorus?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.phosphorus?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.magnesium && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.magnesium?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.magnesium}
          percent={parseNumber(
            secondaryNutrition?.magnesium?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.magnesium?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.zinc && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.zinc?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.zinc}
          percent={parseNumber(
            secondaryNutrition?.zinc?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.zinc?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.selenium && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.selenium?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.selenium}
          percent={parseNumber(
            secondaryNutrition?.selenium?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.selenium?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.copper && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.copper?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.copper}
          percent={parseNumber(
            secondaryNutrition?.copper?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.copper?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.manganese && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.manganese?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.manganese}
          percent={parseNumber(
            secondaryNutrition?.manganese?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.manganese?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.chromium && (
        <NutrientRow
          label={getNutritionNameByTypeCode(
            secondaryNutrition?.chromium?.nutritionTypeCode
          )}
          nutrient={secondaryNutrition?.chromium}
          percent={parseNumber(
            secondaryNutrition?.chromium?.dailyValueIntakePercent
          )}
          measurementPrecisionCode={
            secondaryNutrition?.chromium?.measurementPrecisionCode
          }
        />
      )}
      {secondaryNutrition?.caffeine && (
        <>
          <SeparatorBar height={3} />
          <NutrientRow
            label={getNutritionNameByTypeCode(
              secondaryNutrition?.caffeine?.nutritionTypeCode
            )}
            nutrient={secondaryNutrition?.caffeine}
            percent={parseNumber(
              secondaryNutrition?.caffeine?.dailyValueIntakePercent
            )}
            measurementPrecisionCode={
              secondaryNutrition?.caffeine?.measurementPrecisionCode
            }
          />
        </>
      )}
      {label?.ingredientNotice && (
        <>
          <SeparatorBar height={3} />
          <div className='nutrition-facts__ingredient-notice'>
            <p>{label?.ingredientNotice}</p>
          </div>
        </>
      )}
      <SeparatorBar height={3} />
      <div className='nutrition-facts__footnote'>
        {!!label?.fdaLabelFootNote && (
          <p>
            <span>* {label?.fdaLabelFootNote}</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default VerticalLabel;
