import React from 'react';
import { Row, Typography, Col } from 'antd';

import classnames from 'classnames';

import './ProductThumbFooter.less';

const { Text } = Typography;

const ProductThumbFooter = React.memo((props) => {
  const { gtin, upc12, rivirId } = props;

  return (
    <div className='pd-thumb-foot'>
      {upc12 || gtin ? (
        <>
          <Row style={{ width: '100%' }}>
            <Col xs={24} className='pd-thumb-foot__content'>
              <Text className='pd-thumb-foot__label' strong>
                GTIN:
              </Text>
              <Text className='pd-thumb-foot__text' ellipsis={true}>
                {gtin || 'n/a'}
              </Text>
            </Col>
            <Col xs={24} className='pd-thumb-foot__content'>
              <Text className='pd-thumb-foot__label' strong>
                UPC12:
              </Text>
              <Text className='pd-thumb-foot__text' ellipsis={true}>
                {upc12 || 'n/a'}
              </Text>
            </Col>
          </Row>
        </>
      ) : (
        <Row style={{ width: '100%' }}>
          <Col
            xs={24}
            className={classnames(
              'pd-thumb-foot__content',
              'pd-thumb-foot__content--rivir-id'
            )}
          >
            <Text className='pd-thumb-foot__label' strong>
              RIVIR ID:
            </Text>
            <Text className='pd-thumb-foot__text' ellipsis={true}>
              {rivirId || 'n/a'}
            </Text>
          </Col>
        </Row>
      )}
    </div>
  );
});

export default ProductThumbFooter;
