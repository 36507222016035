import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import Messages from 'i18n/messages/home';
import { Modal } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { AdvancedSearch, WithLoading } from 'common/components/';

import * as api from 'config/axios';

import {
  updateQueryConditions,
  updateQueryAdvance,
} from 'utils/queryCondition';

import useGetDataQueryConditions from 'hooks/useGetDataQueryConditions';
import useGetDataAdvanceFilter from 'hooks/useGetDataAdvanceFilter';
import { useGetProductEnums } from 'hooks';

const AdvanceFilter = (props) => {
  const dispatch = useDispatch();
  const { disabled } = props;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const pathName = window.location.pathname;

  const queryConditions = useGetDataQueryConditions(pathName);
  const queryAdvanceFilter = useGetDataAdvanceFilter(pathName);

  const [advancedSearch, setAdvancedSearch] = useState([]);
  const [advancedSearchShow, setAdvancedSearchShow] = useState([]);
  const [advancedSearchFields, setAdvancedSearchFields] = useState([]);
  const [queryConditionsAdv, setQueryConditionsAdv] = useState([]);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [draggingTaskId, setDraggingTaskId] = useState(null);
  const [currentAreaDrop, setCurrentAreaDrop] = useState(null);
  const [queryChildHidden, setQueryChildHidden] = useState([]);
  const [collapseList, setCollapseList] = useState([]);
  const [expandedList, setExpandedList] = useState([]);

  const { productEnums } = useGetProductEnums();

  useEffect(() => {
    setQueryConditionsAdv(queryAdvanceFilter);
  }, [JSON.stringify(queryAdvanceFilter), queryAdvanceFilter?.length]);

  useEffect(() => {
    if (!visible || !_.isEmpty(advancedSearch)) return;
    let url;
    if (pathName === '/members' || pathName === '/favorite/favorite-members') {
      url = '/api/reporting/member-searchable-fields';
    }
    if (
      pathName === '/digital-media' ||
      pathName === '/digital-media/new' ||
      pathName === '/digital-media/assets' ||
      pathName === '/digital-media/documents' ||
      pathName === '/digital-media/multi-media' ||
      pathName.includes('/member-assets') ||
      pathName === '/favorite/favorite-assets'
    ) {
      url = '/api/reporting/data-asset-searchable-fields';
    }
    if (
      pathName === '/reportings' ||
      pathName === '/reportings/owned' ||
      pathName === '/reportings/shared'
    ) {
      url = '/api/reporting/reporting-searchable-fields';
    }
    if (
      pathName === '/folders' ||
      pathName === '/folders/owned' ||
      pathName === '/folders/shared' ||
      pathName === '/favorite/favorite-folders'
    ) {
      url = '/api/reporting/folder-searchable-fields';
    }
    if (
      pathName === '/products' ||
      pathName.includes('/member-products') ||
      pathName === '/favorite/favorite-products'
    ) {
      url = '/api/reporting/product-searchable-fields';
    }
    api
      .sendGet(url)
      .then((response) => {
        if (response?.isSuccess && response?.data?.advanceSearch) {
          setAdvancedSearch(response.data.advanceSearch);
          setAdvancedSearchShow(response.data.advanceSearch);
          let dataFields = [];
          response.data.advanceSearch &&
            response.data.advanceSearch.length > 0 &&
            response.data.advanceSearch.map((item) => {
              item &&
                item.fields &&
                item.fields.length > 0 &&
                item.fields.map((val) => {
                  val.parentDisplayName = item?.displayName;
                  dataFields.push(val);
                });
            });
          setAdvancedSearchFields(dataFields);
        } else {
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [visible]);

  const handleAdvanceFilter = () => {
    // let result = getDefaultConfig(chosenColumns);
    updateQueryConditions(dispatch, queryConditionsAdv, pathName);
    updateQueryAdvance(dispatch, queryConditionsAdv, pathName);
    dispatch(actionsGridView.myQueryDoubleClick(true));
    dispatch(actionsGridView.checkQueryCondition(true));
    setVisible(false);
    dispatch(actionsGridView.updateVisible(false));
  };

  const handleCancel = () => {
    updateQueryAdvance(dispatch, queryConditions, pathName);
    setVisible(false);
    setQueryConditionsAdv(queryConditions);
  };

  return (
    <>
      <Modal
        title='Advanced Filter'
        visible={visible}
        onOk={handleAdvanceFilter}
        onCancel={() => handleCancel()}
        width='80vw'
        height='80vh'
        okButtonProps={{
          disabled: !queryConditionsAdv?.every?.((val) => !val?.fileOverload),
        }}
      >
        {visible && (
          <WithLoading loading={loading}>
            <AdvancedSearch
              advancedSearch={advancedSearch}
              advancedSearchShow={advancedSearchShow}
              advancedSearchFields={advancedSearchFields}
              queryConditions={queryConditionsAdv}
              selectedTaskIds={selectedTaskIds}
              draggingTaskId={draggingTaskId}
              currentAreaDrop={currentAreaDrop}
              queryChildHidden={queryChildHidden}
              collapseList={collapseList}
              expandedList={expandedList}
              productEnums={productEnums}
              // handleSetAdvancedSearch={(value) => {
              //   setAdvancedSearch(value);
              // }}
              handleSetAdvancedSearchShow={(value) => {
                setAdvancedSearchShow(value);
              }}
              // handleSetAdvancedSearchFields={(value) => {
              //   setAdvancedSearchFields(value);
              // }}
              handleSetQueryConditions={(value) => {
                setQueryConditionsAdv(value);
              }}
              handleSetSelectedTaskIds={(value) => {
                setSelectedTaskIds(value);
              }}
              handleSetDraggingTaskId={(value) => {
                setDraggingTaskId(value);
              }}
              handleSetCurrentAreaDrop={(value) => {
                setCurrentAreaDrop(value);
              }}
              handleSetQueryChildHidden={(value) => {
                setQueryChildHidden(value);
              }}
              handleSetCollapseList={(value) => {
                setCollapseList(value);
              }}
              handleSetExpandedList={(value) => {
                setExpandedList(value);
              }}
            />
          </WithLoading>
        )}
      </Modal>

      <ButtonSmallIcon
        icon={<FilterOutlined />}
        label={Messages.advanceFilter}
        disabled={disabled}
        // onClick={() => {
        //   dispatch(actions.toggleAdvanceFilter());
        //   dispatch(actionsGridView.updateVisible(false));
        //   dispatch(actions.updateToggleDetail(false));
        // }}
        // toggle={showAdvanceFilter}
        onClick={() => setVisible(true)}
        className='grid-config__button'
      />
    </>
  );
};

AdvanceFilter.propTypes = {};

export default AdvanceFilter;
