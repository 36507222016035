import React, { useState, useEffect } from 'react';
import { Form, Select, Row } from 'antd';

import {
  WrapperSelect,
  FlagCountrySelectionFieldInput,
} from 'common/components';

import * as Const from 'static/Constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from 'i18n/messages/assetDetail';
import { DownOutlined } from '@ant-design/icons';
import { customLabels } from './constants';

import { sortBy } from 'lodash';

import './style.less';

const initialForm = {
  assetType: null,
  assetSubType: null,
  packageLevel: null,
  packagingDisposition: null,
  angle: null,
  facing: null,
  language: null,
  panelType: null,
  logoType: null,
  certificateType: null,
  documentType: null,
};

const AssetTypeEdit = ({ typeData, setTypeData, form, forMemberDocument }) => {
  const [type, setType] = useState(typeData?.assetType);
  const [subType, setSubType] = useState(typeData?.assetSubType);
  const { Option } = Select;

  const getCodeByCountry = (values) => {
    return Object.keys(customLabels).find(
      (key) => customLabels[key] === values
    );
  };

  const applyAssetTypeValues = (varlueObj) => {
    setTypeData({
      ...typeData,
      ...{
        ...varlueObj,
      },
    });
    form.setFieldsValue(varlueObj);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...typeData,
      facing: typeData?.facing,
      angle: typeData?.angle,
      packagingDisposition: typeData?.packagingDisposition,
    });
    setType(typeData?.assetType);
    setSubType(typeData?.assetSubType);
  }, [typeData]);

  return (
    <div name='asset-type-form' id='asset-type-form'>
      <Form.Item
        name='assetType'
        label='Type: '
        rules={[
          {
            required: true,
            message: 'Please choose asset type',
          },
        ]}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
      >
        <WrapperSelect
          disabled={forMemberDocument}
          className='asset-type-form__select-type'
          onChange={(value) => {
            setType(value);
            setSubType(null);
            setTypeData({ ...initialForm, assetType: value });
            form.setFieldsValue({ ...initialForm, assetType: value });
          }}
        >
          {Const.ASSET_TYPE?.slice()
            .sort()
            .map((item, index) => {
              return (
                <Option value={item} key={index}>
                  {item}
                </Option>
              );
            })}
        </WrapperSelect>
      </Form.Item>
      {type && (
        <Form.Item
          name='assetSubType'
          label='Sub type: '
          rules={[
            {
              required: true,
              message: 'Please choose asset subtype',
            },
          ]}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
        >
          <WrapperSelect
            onChange={(value) => {
              setSubType(value);
              setTypeData({
                ...initialForm,
                assetSubType: value,
                assetType: type,
              });
              form.setFieldsValue({
                ...initialForm,
                assetSubType: value,
                assetType: type,
              });
            }}
          >
            {type === 'Asset'
              ? Const.ASSET_SUBTYPE?.slice()
                  .sort()
                  .map((item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    );
                  })
              : type === 'Company'
              ? Const.COMPANY_SUBTYPE.sort().map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  );
                })
              : type === 'Document'
              ? Const.DOCUMENT_SUBTYPE.sort().map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  );
                })
              : type === 'Multimedia'
              ? Const.MULTIMEDIA_SUBTYPE.sort().map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  );
                })
              : null}
          </WrapperSelect>
        </Form.Item>
      )}

      {subType === 'Planogram' ? (
        <>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='packageLevel'
            label={<FormattedMessage {...messages.gsPackageLevel} />}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
              onChange={(value) => {
                applyAssetTypeValues({
                  packageLevel: value,
                });
              }}
            >
              {sortBy(Const.PACKAGE_LEVEL, 'label').map((item, index) => {
                return (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='packagingDisposition'
            label={<FormattedMessage {...messages.gsPackagingDisposition} />}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
              onChange={(value) => {
                applyAssetTypeValues({
                  packagingDisposition: value,
                });
              }}
            >
              {Const.PACKAGING_DISPOSITION?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='angle'
            label={<FormattedMessage {...messages.gsAngle} />}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
              onChange={(value) => {
                applyAssetTypeValues({
                  angle: value,
                });
              }}
            >
              {Const.ANGLE?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='facing'
            label={<FormattedMessage {...messages.gsFacing} />}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
              onChange={(value) => {
                applyAssetTypeValues({
                  facing: value,
                });
              }}
            >
              {Const.FACING?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='language'
            label={<FormattedMessage {...messages.gsLanguage} />}
          >
            <FlagCountrySelectionFieldInput
              searchable={true}
              className={'asset-type-form__country-form'}
              placeholder=''
              onSelect={(value) => {
                setTypeData({
                  ...form.getFieldValue(),
                  language: customLabels[value],
                });
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  language: customLabels[value],
                });
              }}
              defaultCountry={getCodeByCountry(typeData?.language)}
              isLanguage
            />
            <DownOutlined className='asset-type-form__country-form--icon' />
          </Form.Item>
        </>
      ) : subType === 'Panel' ? (
        <>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name='panelType'
            label={'Panel type:'}
          >
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById('asset-type-form')
              }
              onChange={(value) => {
                applyAssetTypeValues({ panelType: value });
              }}
            >
              {Const.ASSET_PANEL_FIELD?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
        </>
      ) : subType === 'Logo' ? (
        <Form.Item
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          name='logoType'
          label={'Logo type:'}
        >
          <WrapperSelect
            getPopupContainer={() => document.getElementById('asset-type-form')}
            onChange={(value) => {
              applyAssetTypeValues({ logoType: value });
            }}
          >
            {Const.COMPANY_LOGO_FIELD?.slice()
              .sort()
              .map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
          </WrapperSelect>
        </Form.Item>
      ) : subType === 'Certificate' ? (
        <Form.Item
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          name='certificateType'
          label={'Certificate type:'}
        >
          <WrapperSelect
            getPopupContainer={() => document.getElementById('asset-type-form')}
            onChange={(value) => {
              applyAssetTypeValues({
                certificateType: value,
              });
            }}
          >
            {Const.DOCUMENT_CERTIFICATE_FIELD?.slice()
              .sort()
              .map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
          </WrapperSelect>
        </Form.Item>
      ) : subType === 'Company' ? (
        <Form.Item
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          name='documentType'
          label={'Document type:'}
        >
          <WrapperSelect
            getPopupContainer={() => document.getElementById('asset-type-form')}
            onChange={(value) => {
              applyAssetTypeValues({
                documentType: value,
              });
            }}
          >
            {Const.DOCUMENT_COMPANY_FIELD?.slice()
              .sort()
              .map((item, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
          </WrapperSelect>
        </Form.Item>
      ) : null}
    </div>
  );
};

export default injectIntl(AssetTypeEdit);
