import React from 'react';

function SeparatorBar(props) {
  const { height, color, indentBar, ...restProps } = props;
  const divHeight = height ? `${height}pt` : '1px';
  const divColor = color ? color : '#000';
  const divMargin = indentBar ? `${indentBar}px` : 0;

  return (
    <div
      style={{
        height: divHeight,
        backgroundColor: divColor,
        marginLeft: divMargin,
        ...restProps,
      }}
    ></div>
  );
}

export default SeparatorBar;
