import React from 'react';

import { useHistory } from 'react-router-dom';

import { message } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import Messages from 'i18n/messages/home';
import { dialogFunction } from 'common/components/index';

import { DeleteOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as actionsGrid from 'common/components/grid-view/controllers/actions';

import { RIBBON_VIEW } from 'static/Constants';

import * as actions from 'pages/branded-assets/controllers/actions';
import * as brandingActions from 'redux/branding/actions';
import * as ribbonSelector from 'redux/ribbon/selectors';

import * as assetFullViewSelector from 'pages/asset-full-view/controllers/selectors';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { useCheckAllowDeleteAssets } from 'hooks';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';

import * as services from 'services/assetProduct';

const DeleteAssets = ({ isEditting, disabled }) => {
  const dispatch = useDispatch();
  const selectedIds = useSelector(gridSelector.makeSelectItemsSelection());

  const history = useHistory();

  const assetFullView = useSelector(
    assetFullViewSelector.makeSelectAssetData()
  );
  const view = useSelector(ribbonSelector.selectRibbon());

  const { checkAllowDeleteAssetFull, checkAllowDeleteAssetGrid } =
    useCheckAllowDeleteAssets();

  const isAllowDeleteGrid = checkAllowDeleteAssetGrid();
  const isAllowDeleteFull = checkAllowDeleteAssetFull();

  const isAllowDelete = allowDeleteAssets(
    view,
    isAllowDeleteGrid,
    isAllowDeleteFull
  );
  const isFullView = view === RIBBON_VIEW.ASSET_FULL_VIEW.NAME;

  const reloadPage = useDispatchReloadPage();

  const isFavoriteRoute = useCheckFavoriteRoute();

  const handleDelete = async () => {
    // params of idAsset is array
    const listAssets = isFullView ? [assetFullView.id] : selectedIds;
    try {
      const response = await services.deleteAssets({
        listAssets,
      });

      if (response.isSuccess) {
        if (isFullView) {
          history.goBack();
        }

        message.success('Delete successfully');
        dispatch(actionsGrid.deleteItemsSelection());
        dispatch(actions.resetDeleteAssets());
        if (isFavoriteRoute) {
          dispatch(brandingActions.getBrandingNoLoading());
        }
        reloadPage();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleDelete,
    });
  };

  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<DeleteOutlined />}
      label={Messages.deleteAssets}
      onClick={confirmDelete}
      disabled={!isAllowDelete || isEditting || disabled}
    />
  );
};

const allowDeleteAssets = (view, isAllowDeleteGrid, isAllowDeleteFull) => {
  if (view === RIBBON_VIEW.ASSET_FULL_VIEW.NAME) {
    return isAllowDeleteFull;
  }

  return isAllowDeleteGrid;
};

export default DeleteAssets;
