import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Row, Col, Space } from 'antd';

import { dialogFunction } from 'common/components';
import { updateAcceptOrRejectMeeting } from 'services/dashboard';

import { ROUTE } from 'static/Constants';

import useHubFilter from 'hooks/useHubFilter';
import { forwardTo } from 'utils/common/route';
import * as globalActions from 'redux/global/actions';

import './styles.less';

const SignalrMeetingView = (props) => {
  const [newSignal] = useHubFilter();
  const dispatch = useDispatch();

  const pathname = window.location.pathname;

  const meetingId = pathname.includes('/meeting/')
    ? pathname.split('/meeting/')?.[1]
    : null;

  const openMeetingView = (meetingId) => {
    if (!meetingId) return;

    forwardTo(ROUTE.MEETING + `/${meetingId}`);
  };

  const openMeetingViewNewTab = (meetingId) => {
    if (!meetingId) return;

    const url = window.location.origin + ROUTE.MEETING + `/${meetingId}`;
    window.open(url);
  };

  const handleMeetingRequest = useCallback((meetingId, isAccept) => {
    const params = { meetingId, isAccept };
    updateAcceptOrRejectMeeting(params).then((res) => {
      dispatch(globalActions.getMeetingNotification());
    });
  }, []);

  useEffect(() => {
    const openMeetingModal = () => {
      if (
        newSignal?.signalType === 'KickOffMeeting' &&
        meetingId !== `${newSignal?.meetingId}`
      ) {
        const modal = dialogFunction({
          type: 'warn',
          content: (
            <div>
              <Row style={{ padding: '10px 0' }}>
                <Col xs={24}>
                  A scheduled meeting is waiting for you to join. You can choose
                  to open the meeting view in another tab to keep the current
                  page.
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs={24}>
                  <Space>
                    <Button
                      type='primary'
                      onClick={() => {
                        openMeetingView(newSignal?.meetingId);
                        handleMeetingRequest(newSignal?.meetingId, true);
                        modal.destroy();
                      }}
                    >
                      Open meeting
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => {
                        openMeetingViewNewTab(newSignal?.meetingId);
                        handleMeetingRequest(newSignal?.meetingId, true);
                        modal.destroy();
                      }}
                    >
                      Open meeting in another tab
                    </Button>
                    <Button
                      onClick={() => {
                        handleMeetingRequest(newSignal?.meetingId, false);
                        modal.destroy();
                      }}
                    >
                      {' '}
                      Ignore
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          ),
          className: 'meeting-notice-modal',
        });
      }
    };

    openMeetingModal();
  }, [newSignal, meetingId]);

  return null;
};

export default SignalrMeetingView;
