import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import noImage from 'assets/product-no-image.jpg';

import './ImageUnit.less';

const ImageUnit = (props) => {
  const { imageThumbnail } = props;

  const renderImgAvatar = () => {
    return imageThumbnail ? imageThumbnail : noImage;
  };

  return (
    <Avatar
      className='image-unit__image'
      shape='square'
      size={100}
      src={renderImgAvatar()}
    />
  );
};

ImageUnit.propTypes = {
  imageThumbnail: PropTypes.string,
};

export default React.memo(ImageUnit);