import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'antd';
import { PictureOutlined, AlignLeftOutlined } from '@ant-design/icons';
import { RIBBON_TYPES } from 'static/Constants';

import reactCSS from 'reactcss';

const styles = reactCSS({
  default: {
    button: {
      height: '37px',
      fontSize: '18px',
      lineHeight: '37px',
      padding: '0 12px',
    },
  },
});

const FindEntityInFolderResultToolbar = (props) => {
  const { typeViewHook } = props;
  const [currentView, setCurrentView] = typeViewHook;

  const onChangeView = (e) => {
    setCurrentView(e.target.value);
  };

  return (
    <Radio.Group
      onChange={onChangeView}
      defaultValue={currentView}
      size='large'
    >
      <Radio.Button style={styles.button} value={RIBBON_TYPES.THUMBNAILS}>
        <PictureOutlined />
      </Radio.Button>
      <Radio.Button style={styles.button} value={RIBBON_TYPES.TILESVIEW}>
        <AlignLeftOutlined />
      </Radio.Button>
    </Radio.Group>
  );
};

FindEntityInFolderResultToolbar.propTypes = {
  typeViewHook: PropTypes.array,
};

export default FindEntityInFolderResultToolbar;
