import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';
// components
import RibbonButton from 'common/components/button/RibbonButton';
import { RibbonSection } from 'common/components';
// sections
import SaveTicketingGroupModal from './SaveTicketingGroupModal';
// redux
import { useSelector } from 'react-redux';
import * as selectors from 'pages/ticketing-group/controllers/selectors';
// lodash
import { isEmpty } from 'lodash';

const EditTicketingGroup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedGroup = useSelector(selectors.makeSelectSelectedGroup());

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleClick = () => toggleModal();

  return (
    <RibbonSection>
      <RibbonButton
        icon={<EditOutlined />}
        label={Messages.editTiketingGroup}
        onClick={handleClick}
        disabled={isEmpty(selectedGroup)}
      />
      {isModalOpen && (
        <SaveTicketingGroupModal
          isEdit={true}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </RibbonSection>
  );
};

export default EditTicketingGroup;
