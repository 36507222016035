import produce from 'immer';
import * as types from './constants';

export const initialState = {
  loading: false,
  error: false,
  columns: [],

  configGridColumns: [],
  currentMappingId: null,

  mappingSourceProperties: {
    status: 'idle',
    error: null,
    data: null,
  },
  mappingList: {
    loading: false,
    error: false,
    data: [],
    totalItems: 0,
    pageSize: 20,
    pageIndex: 1,
  },

  mappingConfigProperties: {
    data: null,
    loading: false,
    error: null,
  },

  mappingDetail: {
    data: null,
    loading: false,
    error: null,
  },
  formatTypeFilter: [],
};

const mappingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MAPPING_GRID_COLUMNS:
        draft.loading = true;
        break;
      case types.GET_MAPPING_GRID_COLUMNS_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;
      case types.GET_MAPPING_GRID_COLUMNS_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;

      case types.GET_MAPPING_CONFIG_GRID_COLUMNS:
        draft.loading = true;
        break;

      case types.GET_MAPPING_CONFIG_GRID_COLUMNS_SUCCESS:
        draft.loading = false;
        draft.configGridColumns = action.columns;
        break;

      case types.GET_MAPPING_CONFIG_GRID_COLUMNS_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;

      case types.GET_MAPPING_SOURCE_PROPERTIES:
        draft['mappingSourceProperties'].status = 'loading';
        break;

      case types.GET_MAPPING_SOURCE_PROPERTIES_SUCCESS:
        draft['mappingSourceProperties'].status = 'success';
        draft['mappingSourceProperties'].data = action.properties;
        draft['mappingSourceProperties'].error = null;
        break;

      case types.GET_MAPPING_SOURCE_PROPERTIES_ERROR:
        draft['mappingSourceProperties'].status = 'error';
        draft['mappingSourceProperties'].error = action.error;
        break;

      case types.SET_CURRENT_MAPPING_ID:
        draft.currentMappingId = action.id;
        break;

      case types.GET_MAPPING_CONFIG_PROPERTIES:
        draft['mappingConfigProperties'].loading = true;
        break;

      case types.GET_MAPPING_CONFIG_PROPERTIES_SUCCESS:
        draft['mappingConfigProperties'].loading = false;
        draft['mappingConfigProperties'].data = action.data;
        draft['mappingConfigProperties'].error = null;
        break;

      case types.GET_MAPPING_CONFIG_PROPERTIES_FAILURE:
        draft['mappingConfigProperties'].loading = false;
        draft['mappingConfigProperties'].error = action.error;
        break;
      case types.UPDATE_MAPPING_FORMAT_TYPE_FILTER:
        draft.formatTypeFilter = action.formatTypeFilter;
        break;

      case types.GET_MAPPING_CONFIG:
        draft['mappingDetail'].loading = true;
        break;

      case types.GET_MAPPING_CONFIG_SUCCESS:
        draft['mappingDetail'].loading = false;
        draft['mappingDetail'].data = action.data;
        draft['mappingDetail'].error = null;
        break;

      case types.GET_MAPPING_CONFIG_FAILURE:
        draft['mappingDetail'].loading = false;
        draft['mappingDetail'].error = action.error;
        break;

      default:
        break;
    }
  });

export default mappingReducer;
