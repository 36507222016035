export const SET_WORKFLOW_TEMPLATE_VIEW = 'SET_WORKFLOW_TEMPLATE_VIEW';
export const GET_WORKFLOW_TEMPLATE_DETAIL = 'GET_WORKFLOW_TEMPLATE_DETAIL';
export const GET_WORKFLOW_TEMPLATE_DETAIL_SUCCESS =
  'GET_WORKFLOW_TEMPLATE_DETAIL_SUCCESS';
export const GET_WORKFLOW_TEMPLATE_DETAIL_ERROR =
  'GET_WORKFLOW_TEMPLATE_DETAIL_ERROR';
export const GET_WORKFLOW_STEP_INFO = 'GET_WORKFLOW_STEP_INFO';
export const GET_WORKFLOW_STEP_INFO_SUCCESS = 'GET_WORKFLOW_STEP_INFO_SUCCESS';
export const GET_WORKFLOW_STEP_INFO_ERROR = 'GET_WORKFLOW_STEP_INFO_ERROR';
export const TOGGLE_NEW_STEP = 'TOGGLE_NEW_STEP';

export const CREATE_WORKFLOW_STEP = 'CREATE_WORKFLOW_STEP';
export const CREATE_WORKFLOW_STEP_SUCCESS = 'CREATE_WORKFLOW_STEP_SUCCESS';
export const CREATE_WORKFLOW_STEP_ERROR = 'CREATE_WORKFLOW_STEP_ERROR';

export const EDIT_WORKFLOW_STEP = 'EDIT_WORKFLOW_STEP';
export const EDIT_WORKFLOW_STEP_SUCCESS = 'EDIT_WORKFLOW_STEP_SUCCESS';
export const EDIT_WORKFLOW_STEP_ERROR = 'EDIT_WORKFLOW_STEP_ERROR';

export const GET_WORKFLOW_TASK_INFO = 'GET_WORKFLOW_TASK_INFO';
export const GET_WORKFLOW_TASK_INFO_SUCCESS = 'GET_WORKFLOW_TASK_INFO_SUCCESS';
export const GET_WORKFLOW_TASK_INFO_ERROR = 'GET_WORKFLOW_TASK_INFO_ERROR';

export const EDIT_WORKFLOW_TASK = 'EDIT_WORKFLOW_TASK';
export const EDIT_WORKFLOW_TASK_SUCCESS = 'EDIT_WORKFLOW_TASK_SUCCESS';
export const EDIT_WORKFLOW_TASK_ERROR = 'EDIT_WORKFLOW_TASK_ERROR';

export const GET_WORKFLOW_TEMPLATE_DETAIL_INFO =
  'GET_WORKFLOW_TEMPLATE_DETAIL_INFO';
export const GET_WORKFLOW_TEMPLATE_DETAIL_INFO_SUCCESS =
  'GET_WORKFLOW_TEMPLATE_DETAIL_INFO_SUCCESS';
export const GET_WORKFLOW_TEMPLATE_DETAIL_INFO_ERROR =
  'GET_WORKFLOW_TEMPLATE_DETAIL_INFO_ERROR';

export const SET_TREE = 'SET_TREE';
export const SET_ACTIVE_STEP_KEY = 'SET_ACTIVE_STEP_KEY';

export const GET_WORKFLOW_TEMPLATE_VERSIONS = 'GET_WORKFLOW_TEMPLATE_VERSIONS';
export const GET_WORKFLOW_TEMPLATE_VERSIONS_SUCCESS =
  'GET_WORKFLOW_TEMPLATE_VERSIONS_SUCCESS';
export const GET_WORKFLOW_TEMPLATE_VERSIONS_ERROR =
  'GET_WORKFLOW_TEMPLATE_VERSIONS_ERROR';

export const SET_WORKFLOW_TEMPLATE_DETAIL = 'SET_WORKFLOW_TEMPLATE_DETAIL';

export const EDIT_WORKFLOW_TEMPLATE = 'EDIT_WORKFLOW_TEMPLATE';
export const EDIT_WORKFLOW_TEMPLATE_SUCCESS = 'EDIT_WORKFLOW_TEMPLATE_SUCCESS';
export const EDIT_WORKFLOW_TEMPLATE_ERROR = 'EDIT_WORKFLOW_TEMPLATE_ERROR';

export const GET_USER_WORKFLOW_ROLES_DETAIL = 'GET_USER_WORKFLOW_ROLES_DETAIL';
export const GET_USER_WORKFLOW_ROLES_DETAIL_SUCCESS =
  'GET_USER_WORKFLOW_ROLES_DETAIL_SUCCESS';
export const GET_USER_WORKFLOW_ROLES_DETAIL_ERROR =
  'GET_USER_WORKFLOW_ROLES_DETAIL_ERROR';

export const SET_WORKFLOW_STEP_INFO = 'SET_WORKFLOW_STEP_INFO';
export const TOGGLE_NEW_TASK = 'TOGGLE_NEW_TASK';

export const CREATE_WORKFLOW_TASK = 'CREATE_WORKFLOW_TASK';
export const CREATE_WORKFLOW_TASK_SUCCESS = 'CREATE_WORKFLOW_TASK_SUCCESS';
export const CREATE_WORKFLOW_TASK_ERROR = 'CREATE_WORKFLOW_TASK_ERROR';

export const GET_WORKFLOW_METRIC = 'GET_WORKFLOW_METRIC';
export const GET_WORKFLOW_METRIC_SUCCESS = 'GET_WORKFLOW_METRIC_SUCCESS';
export const GET_WORKFLOW_METRIC_ERROR = 'GET_WORKFLOW_METRIC_ERROR';

export const SET_STATUS_WORKFLOW_CLICK = 'SET_STATUS_WORKFLOW_CLICK';

export const GET_REQUIRED_TASK = 'GET_REQUIRED_TASK';
export const GET_REQUIRED_TASK_SUCCESS = 'GET_REQUIRED_TAS_SUCCESSK';
export const GET_REQUIRED_TASK_ERROR = 'GET_REQUIRED_TASK_ERROR';

export const GET_WORKFLOW_STEP_METRIC = 'GET_WORKFLOW_STEP_METRIC';
export const GET_WORKFLOW_STEP_METRIC_SUCCESS =
  'GET_WORKFLOW_STEP_METRIC_SUCCESS';
export const GET_WORKFLOW_STEP_METRIC_ERROR = 'GET_WORKFLOW_STEP_METRIC_ERROR';

export const UPDATE_MESSAGE_ACTIONS = 'UPDATE_MESSAGE_ACTIONS';

export const TRIGGER_CLONE_STEP = 'TRIGGER_CLONE_STEP';
export const TRIGGER_CLONE_TASK = 'TRIGGER_CLONE_TASK';
export const HIDE_CLONE_STEP = 'HIDE_CLONE_STEP';
export const HIDE_CLONE_TASK = 'HIDE_CLONE_TASK';
