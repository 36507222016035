import React, { useState } from 'react';

import RunQueryControl from '../../controls/advanced-search/RunQueryControl';
import ExportQueryControl from '../../controls/advanced-search/ExportQueryControl';

import { RibbonSection } from 'common/components';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const QuerySection = (props) => {
  const { disableRun, onClickRibbonBtn, disabledExportQuery } = props;
  const [loading, setLoading] = useState();

  const onClickRunBtn = () => {
    onClickRibbonBtn('run');
  };

  const onClickExportBtn = () => {
    setLoading(true);
    onClickRibbonBtn('export', setLoading);
  };

  return (
    <RibbonSection>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.REPORTING}>
        <RunQueryControl disabled={disableRun} onClick={onClickRunBtn} />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.REPORTING}>
        <ExportQueryControl
          loading={loading}
          disabled={disabledExportQuery}
          onClick={onClickExportBtn}
        />
      </Can>
    </RibbonSection>
  );
};

export default QuerySection;
