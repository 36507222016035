import { useState } from 'react';

export const useAllowRefetchQuery = () => {
  const [allowRefetch, setAllowRefetch] = useState(true);

  const handleSetAllowRefetch = (isEdit) => {
    if (isEdit === allowRefetch) {
      setAllowRefetch(!isEdit);
    }
  };

  return { allowRefetch, handleSetAllowRefetch };
};
