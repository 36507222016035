import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import {
  UploadOutlined,
  LoadingOutlined,
  DeleteFilled,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import PreviewableModal from 'common/components/previewable-modal/PreviewableModal';
import { UploadFile, dialogFunction } from 'common/components';

import { Button, Row, Col, Image, Typography, Space } from 'antd';

import { UPLOAD_MAX_SIZE } from 'static/Constants';

import { mapOriginalFileTypeToImage } from 'utils/fileType';
import { checkShowPdfPreview } from 'utils/checkShowPdfPreview';
import * as api from 'config/axios';

import './FileUploadEditor.less';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

const { Text } = Typography;

export const FileUploadEditor = forwardRef((props, ref) => {
  const {
    value: initValue,
    apiUrl,
    additionalBodyPayload = {},
    supportTypes = [],
    confirmDeleteMessage,
    column,
  } = props;

  const [value, setValue] = useState(initValue);
  const [editing, setEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const refContainer = useRef(null);
  const refUpload = useRef();

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const { linkPreview, extensionFile } = checkShowPdfPreview(
    value?.pdfPreview,
    value?.linkUrl,
    value?.fileType
  );

  useEffect(() => {
    focus();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const focus = () => {
    window.setTimeout(() => {
      let container = ReactDOM.findDOMNode(refContainer.current);
      if (container) {
        container.focus();
      }
    });
  };

  const handleUploadSuccess = (res) => {
    if (!res?.data) return;

    try {
      const { isSuccess, data } = res?.data;

      if (isSuccess) {
        setValue({
          documentName: fileInfo?.fileName,
          thumb: mapOriginalFileTypeToImage(fileInfo?.fileName),
          linkUrl: null,
          fileType: null,
          digitalAssetId: data?.assetId,
        });
      }
    } catch (e) {}
  };

  const onFileChange = (fileList) => {
    if (!fileList) return;
    const file = fileList.slice(-1).pop();
    if (!file) return;

    const status = file?.status;

    if (status === 'uploading') {
      setLoading(true);
      setFileInfo({ fileName: file?.name });
    }

    if (status === 'done') {
      setLoading(false);
      setFileInfo({ fileName: file?.name });
    }
  };

  const onRemoveFile = () => {
    setValue(null);
  };

  const handleRemoveFile = () => {
    confirmDeleteMessage
      ? dialogFunction({
          type: 'warn',
          content: confirmDeleteMessage,
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: () => {
            onRemoveFile();
          },
        })
      : onRemoveFile();
  };

  const handleDownloadFile = () => {
    value?.linkUrl &&
      api.sendDownload({
        url: value?.linkUrl,
      });
  };

  const width = column.getActualWidth() || 300;

  return (
    <div
      ref={refContainer}
      className='ag-editor__file-upload'
      tabIndex={1}
      style={{ width }}
    >
      <Row>
        <Col style={{ flex: 1, overflow: 'hidden' }}>
          {value?.documentName ? <FileViewer data={value} /> : <>No File</>}
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Space>
            <Button
              type='link'
              icon={<EyeOutlined />}
              onClick={() => setIsPreviewOpen(true)}
              style={{ border: 'none' }}
              disabled={!value?.linkUrl || !value?.fileType}
            />
            <Button
              type='link'
              icon={<DownloadOutlined />}
              onClick={handleDownloadFile}
              style={{ border: 'none' }}
              disabled={!value?.linkUrl}
            />

            {loading ? (
              <LoadingOutlined />
            ) : (
              <Button
                type='link'
                icon={<UploadOutlined />}
                onClick={() => {
                  refUpload.current.handleClickUpload();
                }}
                style={{ border: 'none' }}
              />
            )}
            {!isQASpecLocked ? (
              <Button
                danger
                type='link'
                icon={<DeleteFilled />}
                onClick={handleRemoveFile}
                style={{ border: 'none' }}
                disabled={!value}
              />
            ) : null}
          </Space>
        </Col>
      </Row>
      <div style={{ visibility: 'hidden', position: 'absolute' }}>
        <UploadFile
          ref={refUpload}
          showList
          showMultiple={false}
          showSubmitBtn
          onFileChange={onFileChange}
          supportTypes={supportTypes}
          apiUrl={apiUrl}
          additionalBodyPayload={additionalBodyPayload}
          getResponse={handleUploadSuccess}
          maxSize={UPLOAD_MAX_SIZE.GENERAL}
          showServerMessage
        />
      </div>
      {isPreviewOpen && (
        <PreviewableModal
          id={value?.documentName}
          fileType={value?.fileType}
          type={extensionFile}
          link={linkPreview}
          title={value?.documentName}
          visible={isPreviewOpen}
          fileName={value?.documentName}
          cancelModal={() => setIsPreviewOpen(false)}
          isDownload={false}
          isDocument={true}
          downloadLink={value?.linkUrl}
        />
      )}
    </div>
  );
});

export const FileViewer = ({ data }) => {
  return (
    <Row align='middle' style={{ flexFlow: 'row', maxWidth: '100%', gap: 3 }}>
      <Col style={{ display: 'flex', alignItems: 'center' }}>
        <Image
          src={data?.thumb || mapOriginalFileTypeToImage(`.${data?.fileType}`)}
          preview={false}
          style={{ objectFit: 'contain' }}
          wrapperStyle={{ width: 24 }}
        />
      </Col>
      <Col style={{ overflow: 'hidden' }}>
        <Text ellipsis>{data?.documentName}</Text>
      </Col>
    </Row>
  );
};
