import React from 'react';
import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack, OtherLayout } from './components';

const WorkflowDetailRibbon = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <AdvanceStack isDisabled={true} />
      <RibbonDivider />

      <OtherLayout
        ribbonSide='right'
        ribbonType='workflows'
        isHaveAddOpenItem={false}
      />
    </RibbonBar>
  );
};

export default WorkflowDetailRibbon;
