import { useDispatch, useSelector } from 'react-redux';

import userSelectors from 'redux/user/selectors';
import * as actionsUser from 'redux/user/actions';

import * as services from 'services/login';

export const useUserInfo = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const updateUserInfo = () => {
    services.getUserInfo().then((resp) => {
      if (resp?.data) {
        dispatch(actionsUser.loginSuccess(resp?.data));
      }
    });
  };

  return { updateUserInfo, userInfo };
};
