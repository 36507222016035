export const getChildNodes = (node) => {
  if (!node) return [];

  const children = [];
  for (let child in node.childNodes) {
    const childNode = node.childNodes[child];
    const nodeType = node.childNodes[child].nodeType;
    if (nodeType === 1) {
      children.push(childNode);
    }
  }

  return children;
};
