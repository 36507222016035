import React, { memo } from 'react';
// antd
import { Row, Col, Typography, Tooltip, Button } from 'antd';
import { RightOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';

import './ModalFormQasRequireField.less';

const QasGroup = (props) => {
  const {
    group,
    dragDisabled,
    collapse,
    highlightSearchText,
    handleExpand,
    handleCollapse,
    handleRemove,
  } = props;
  return (
    <Row className='qas-spec-properties__group'>
      <Col
        className='qas-spec-properties__group--row'
        flex={1}
        onClick={() => {
          if (collapse) {
            handleExpand(group?.keyNode, dragDisabled);
          } else {
            handleCollapse(group?.keyNode, dragDisabled);
          }
        }}
      >
        <Button type='text' style={{ fontSize: 14, margin: 4 }}>
          {collapse ? <RightOutlined /> : <DownOutlined />}
        </Button>
        <Typography.Text
          strong
          style={{ fontSize: 14 }}
          ellipsis={{ tooltip: group?.groupName }}
        >
          {dragDisabled ? group?.groupName : highlightSearchText}
        </Typography.Text>
      </Col>
      {dragDisabled && (
        <Tooltip
          title={`Remove group ${group?.groupName}`}
          className='qas-spec-properties__remove'
        >
          <Button
            danger
            type='text'
            icon={<CloseOutlined />}
            style={{ fontSize: 14, margin: 4 }}
            onClick={() => {
              handleRemove(group?.keyNode);
            }}
          />
        </Tooltip>
      )}
    </Row>
  );
};
export default memo(QasGroup);
