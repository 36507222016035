

import {
  DrugFactActiveIngredient,
  DrugFactUses,
  DrugFactWarnings,
  DrugFactDirections,
  DrugFactOtherInformation,
  DrugFactInactiveIngredients,
  DrugFactSection,
} from './component';

import './DrugFact.less';

const DrugStandardLabel = ({ label }) => {
  const {
    activeIngredients,
    uses,
    warnings,
    directions,
    otherInformation,
    inactiveIngredients,
    dosageUnit
  } = label || {};

  return (
    <div className='drug-fact scroller scroller--y'>
      <DrugFactSection className='drug-fact__container'>
        <h1 className='drug-fact__title'>Drug Facts</h1>
        <DrugFactActiveIngredient activeIngredients={activeIngredients} dosageUnit={dosageUnit}/>
        <DrugFactUses uses={uses} />
        <DrugFactWarnings warnings={warnings} />
        <DrugFactDirections directions={directions} />
        <DrugFactOtherInformation otherInformation={otherInformation} />
        <DrugFactInactiveIngredients
          inactiveIngredients={inactiveIngredients}
        />
      </DrugFactSection>
    </div>
  );
};

export default DrugStandardLabel;
