import React, { useState } from 'react';

import { notification, Checkbox, Space } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import UploadFile from 'common/components/uploadFile/UploadFile';
import { StyledModal } from 'common/components';

import * as endpointAsset from 'services/helpMaintenance/endpoints';

import { FormattedMessage, injectIntl } from 'react-intl';
import Messages from 'i18n/messages/maintenance';

const Restore = ({ intl }) => {
  const [visible, setVisible] = useState(false);
  const [isOverwrite, setIsOverwrite] = useState(false);

  const handleRestore = () => {
    setVisible(true);
  };

  const handleRestoreUpload = (result) => {
    if (result && result.response && result.response.isSuccess) {
      showSuccessMessageDialog();
      setVisible(false);
      setIsOverwrite(false);
    }
    // success and error response is different
    if (result && result.response && !result.response.isSuccess) {
      callApiErrorHandler(result?.response?.message);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.restoreSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  const onChangeOverWrite = (event) => {
    setIsOverwrite(event.target.checked);
  };

  return (
    <>
      <RibbonButton
        icon={<RetweetOutlined />}
        label={Messages.restore}
        onClick={handleRestore}
      />
      <StyledModal
        title={<FormattedMessage {...Messages.restoreModal} />}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setIsOverwrite(false);
        }}
        maskClosable={false}
        footer={null}
      >
        <Space style={{ paddingBottom: 10 }}>
          <FormattedMessage {...Messages.overwrite} />
          <Checkbox
            checked={isOverwrite}
            onChange={(event) => onChangeOverWrite(event)}
          />
        </Space>
        {visible ? (
          <div style={{ height: 270 }}>
            <UploadFile
              isBase64={false}
              showMultiple={false}
              additionalBodyPayload={{ Overwrite: isOverwrite }}
              apiUrl={endpointAsset.RESTORE_HELP_CENTER}
              getResponse={(result) => handleRestoreUpload(result)}
              manualUpload={true}
              showUploadSuccessMsg={false}
              confirmMessage={
                isOverwrite
                  ? intl.formatMessage(Messages.restoreOverwrite)
                  : intl.formatMessage(Messages.restoreNoOverwrite)
              }
            />
          </div>
        ) : null}
      </StyledModal>
    </>
  );
};

export default injectIntl(Restore);
