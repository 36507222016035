import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getEmailThreadList = async (params) => {
  return api.sendGet(endpoints.GET_EMAIL_THREAD_LIST, { params: params });
};

export const getEmailThreadInfo = async (params) => {
  const { userId, threadId } = params;
  return api.sendGet(
    `${endpoints.GET_EMAIL_THREAD_INFO}/?ThreadId=${threadId}&UserId=${userId}`
  );
};

export const getEmailThreadInfoContent = async (params) => {
  const requestParams = {
    threadId: params?.threadId,
    timestamp: params?.timestamp,
    direction: params?.direction,
  };

  return api.sendGet(endpoints.GET_EMAIL_THREAD_INFO_CONTENT, {
    params: requestParams,
  });
};

export const getEmailThreadUsersInfo = (params) => {
  return api.sendPost(`${endpoints.GET_EMAIL_THREAD_USERS_INFO}`, {
    senderIds: params,
  });
};

export const getEmailThreadInfoFiles = (params) => {
  return api.sendGet(endpoints.GET_EMAIL_THREAD_INFO_FILES, { params: params });
};

export const initEmailCommunication = (params) => {
  return api.sendPost(endpoints.INIT_EMAIL_COMMUNICATION, params);
};

export const uploadEmailAttachment = (params, options) => {
  return api.sendPost(endpoints.UPLOAD_EMAIL_ATTACHMENT, params, options);
};

export const getEmailAttachment = (params) => {
  return api.sendPost(endpoints.GET_EMAIL_ATTACHMENT, params);
};

export const searchEmailMessage = (params) => {
  const options = { params };
  return api.sendGet(endpoints.SEARCH_MESSAGES, options);
};
