export const DIGITAL_ASSET_EDIT = '/api/v1/asset/edit';
export const GET_DIGITAL_ASSET = '/api/dam/digital-asset-full-view';
export const DIGITAL_ASSET_DOWNLOAD = '/api/dam/data-asset-download';
export const GET_FTP_FILE_LIST = '/api/dam/interoperable-assets';
export const GENERATE_PREVIEW = '/api/dam/generate-digital-assets-thumbnails';
export const GENERATE_PREVIEW_BACKGROUND =
  '/api/dam/generate-digital-assets-thumbnails-background';
export const UPDATE_DIGITAL_ASSET_THUMB =
  '/api/dam/update-digital-assets-thumbnails';
export const POST_FTP_FILE = '/api/asset/post-ftp-file';
export const DOWNLOAD_SPEC_INFO = '/api/dam/download-specific-information';
export const UPDATE_DIGITAL_ASSET = '/api/dam/update-digital-asset';
export const UPDATE_DIGITAL_REPLACE_ASSET = '/api/dam/replace-asset';
export const GET_ASSET_ASSOCIATION_GRID = '/api/dam/asset-association-grid';
export const GET_ASSET_ASSOCIATION_GRID_DISTINCT =
  '/api/dam/asset-association-grid/distinct';
export const CREATE_DIGITAL_ASSET = '/api/dam/create-asset';
export const DIGITAL_ASSET_DOWNLOAD_SELECTED_ID =
  '/api/dam/digital-asset-download';
export const UPDATE_DIGITAL_ASSET_THUMBNAIL =
  '/api/dam/update-digital-assets-thumbnails';
export const REPLACE_DIGITAL_ASSET = '/api/dam/create-asset-replace';
export const CREATE_DIGITAL_ASSET_FTP = '/api/dam/create-asset-ftp';
export const REPLACE_DIGITAL_ASSET_FTP = '/api/dam/replace-asset-ftp';
export const GET_LATEST_MODIFIED_DATE_ASSET =
  '/api/dam/get-asset-latest-modified';

// Iframe Related API
export const CREATE_IFRAME = '/api/dam/create-iframe-link';
export const EDIT_IFRAME = '/api/dam/edit-iframe-link';

export const POST_IMPORT_ASSET = '/api/dam/import-assets';
export const GET_IMPORT_ASSET_TEMPLATE = '/api/assets/dam/template-import';

// Import to folder
export const IMPORT_ASSETS_FROM_INTEROP =
  '/api/storage-service/import-assets-from-interop';

export const MULTIPLE_DOWNLOAD_ASSET =
  '/api/dam/data-asset-download-in-short-detail';

export const SAVE_GS1 = '/api/dam/save-non-derived-gs1';
export const DELETE_GS1 = '/api/dam/delete-non-derived-gs1';

export const LINK_ASSET_TO_PIM = '/api/dam/link-product-image';
export const UNLINK_ASSET_TO_PIM = '/api/dam/unlink-product-image';
export const LINK_ASSET_TO_PIM_DOCUMENT = '/api/dam/link-product-document';
export const UNLINK_ASSET_TO_PIM_DOCUMENT = '/api/dam/unlink-product-document';

export const UPDATE_LINK_ASSET_TO_PIM =
  '/api/dam/update-product-image-link-metadata';

export const UPDATE_ASSET_TYPE = '/api/dam/change-asset-type-variable-fields';

export const EDIT_MEMBER_DEFINED_PROPERTIES_VALUE =
  '/api/dam/change-member-defined-properties-value';
export const GET_PREVIOUS_VERSIONS_OF_ASSET =
  '/api/dam/get-previous-versions-of-asset';
export const GET_DETAIL_PREVIOUS_VERSION_OF_ASSET =
  '/api/dam/get-detail-previous-version-of-asset';

export const GENERATE_GS1_COMPLIANT_NAME =
  '/api/dam/generate-gs1-compliant-name';
export const ADD_DRM_ASSET = '/api/dam/add-digital-rights';
export const DELETE_DRM_ASSET = '/api/dam/remove-digital-rights';

export const DOWNLOAD_GS1_FILE = '/api/dam/download-gs1-file';

export const REPLACE_ASSET_PREVIEW_ADV_FTP =
  '/api/dam/update-digital-asset-thumbnails-ftp';

export const EDIT_MULTIPLE_ASSET = '/api/dam/get-edit-asset-template';
export const UPDATE_MULTIPLE_ASSET = '/api/dam/upload-edit-asset-bulk-template';
export const DOWNLOAD_AUTHORIZED_TAGS = '/api/dam/download-authorized-tags';

export const MOVE_ASSET_TO_MEMBER = '/api/dam/move-assets-to-member';

export const REPLACE_DIGITAL_ASSETS_THUMBNAILS =
  '/api/dam/replace-digital-assets-thumbnails';
