import React from 'react';

import { useNode, useEditor } from '@craftjs/core';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const EmailEditorPlaceholder = (props) => {
  const intl = useIntl();
  const { selected } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { what = '', isInvalidTag } = props;

  const placeholder = intl.formatMessage(Messages.placeholder) + what;

  return enabled ? (
    <span
      className='email-editor__placeholder'
      contentEditable={false}
      style={{
        backgroundColor: selected
          ? '#e6f7ff'
          : isInvalidTag
          ? '#fff1f0'
          : undefined,
      }}
    >
      {placeholder}
    </span>
  ) : null;
};

export default EmailEditorPlaceholder;
