import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { FolderAddOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import NewGdsnSubscriptionModal from 'pages/subscription/components/new-gdsn-subscription/NewGdsnSubscriptionModal';

import userSelectors from 'redux/user/selectors';

import Messages from 'i18n/messages/subscription';

const NewGdsnSubscription = () => {
  const { isRivirGdsnParty } = useSelector(userSelectors.makeSelectUserInfo());

  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        icon={<FolderAddOutlined />}
        label={Messages.newGdsnSubscription}
        onClick={() => setVisible(true)}
        disabled={!isRivirGdsnParty}
      />
      <NewGdsnSubscriptionModal visible={visible} setVisible={setVisible} />
    </>
  );
};

export default NewGdsnSubscription;
