import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/maintenance';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import { dialogFunction, RibbonButton } from 'common/components';

import * as articleSelectors from 'pages/articles/controllers/selectors';
import * as articleActions from 'pages/articles/controllers/actions';
import * as selectorsGlobal from 'redux/global/selectors';

import { deleteArticlesService } from 'services/helpMaintenance';

import { useFilterGrid } from 'hooks/useFilterGrid';

const DeleteArticles = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const pageSizePagination = useSelector(
    articleSelectors.selectPageSizeArticle()
  );
  const pageNumberPagination = useSelector(
    articleSelectors.selectPageNumberArticle()
  );
  const searchAllItems = useSelector(selectorsGlobal.selectSearchAllItems());

  useFilterGrid({
    clearOtherFilter: true,
  });

  const articleIds = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  ).map((article) => article.id);

  const callApiDelete = async () => {
    try {
      const response = await deleteArticlesService({ articleIds });
      const { isSuccess, message } = response;
      if (isSuccess) {
        dispatch(
          articleActions.getArticles(
            pageSizePagination,
            pageNumberPagination,
            searchAllItems
          )
        );
        showSuccessMessageDialog();
      } else {
        callApiErrorHandler(message);
      }
    } catch (error) {
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  const handleDeleteArticles = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  return (
    <RibbonButton
      size='small'
      icon={<DeleteOutlined />}
      label={Messages.deleteArticles}
      onClick={handleDeleteArticles}
      disabled={articleIds.length === 0}
    />
  );
};

export default DeleteArticles;
