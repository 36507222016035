import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import * as productServices from 'services/product';
import { CustomNotification } from 'common/components';
import { forwardTo } from 'utils/common/route';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

export const qaSpecSnapshotKeys = {
  getQaSpecSnapshot: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-spec-snapshot',
  ],
};

export const useGetQaSpecSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey: qaSpecSnapshotKeys.getQaSpecSnapshot(productId),
    queryFn: async () => {
      const response = await productServices.getQaProductSpecSnapshot({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    dataQaSpecSnapshot: queries?.data ?? null,
    ...queries,
  };
};

export const qaOrganolepticCharacteristicsSnapshotKeys = {
  getQaProductOrganolepticCharacteristics: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-product-organoleptic-characteristics',
  ],
};

export const useGetQaSpecCharacteristicsSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey:
      qaOrganolepticCharacteristicsSnapshotKeys.getQaProductOrganolepticCharacteristics(
        productId
      ),
    queryFn: async () => {
      const response =
        await productServices.getQaProductOrganolepticCharacteristicsSnapshot({
          productId,
        });

      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    organolepticCharacteristicsSnapshot:
      queries?.data?.organolepticCharacteristics ?? [],
    ...queries,
  };
};

export const qaPhysicalChemicalSnapshotKeys = {
  getQaPhysicalChemical: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-physical-chemical',
  ],
};

export const useGetQaPhysicalChemicalSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey: qaPhysicalChemicalSnapshotKeys.getQaPhysicalChemical(productId),
    queryFn: async () => {
      const response =
        await productServices.getQaProductPhysicalAndChemicalSnapshot({
          productId,
        });

      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    physicalChemicalSnapshot:
      queries?.data?.physicalAndChemicalCharacteristics ?? [],
    ...queries,
  };
};

export const qaContaminantCharacteristicsSnapshotKeys = {
  contaminantCharacteristics: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-contaminant-characteristics',
  ],
};

export const useGetQaContaminantCharacteristicsSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey:
      qaContaminantCharacteristicsSnapshotKeys.contaminantCharacteristics(
        productId
      ),
    queryFn: async () => {
      const response =
        await productServices.getQaProductContaminantCharacteristicsSnapshot({
          productId,
        });

      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    contaminantCharacteristicsSnapshot:
      queries?.data?.contaminantCharacteristics ?? [],
    ...queries,
  };
};

export const qaMicrobiologicalCharacteristicsSnapshotKeys = {
  microbiologicalCharacteristics: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-microbiological-characteristics',
  ],
};

export const useGetQaMicrobiologicalCharacteristicsSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey:
      qaMicrobiologicalCharacteristicsSnapshotKeys.microbiologicalCharacteristics(
        productId
      ),
    queryFn: async () => {
      const response =
        await productServices.getQaProductMicrobiologicalCharacteristicsSnapshot(
          {
            productId,
          }
        );

      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    microbiologicalCharacteristicsSnapshot:
      queries?.data?.microbiologicalCharacteristics ?? [],
    ...queries,
  };
};

export const qaProductSpecDocumentsSnapshotKeys = {
  getQaProductDocuments: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-product-spec-documents',
  ],
};

export const useGetQaProductSpecDocumentsSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey:
      qaProductSpecDocumentsSnapshotKeys.getQaProductDocuments(productId),
    queryFn: async () => {
      const response = await productServices.getQaProductSpecDocumentsSnapshot({
        productId,
      });

      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        forwardTo('/products');
      }
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    qaProductSpecDocumentsSnapshot: queries?.data?.documents ?? [],
    ...queries,
  };
};
