import React from 'react';

import { Menu, Dropdown, Button, Typography } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

import ClearColumnsControl from '../../controls/advanced-search/ClearColumnsControl';
import ClearQueryControl from '../../controls/advanced-search/ClearQueryControl';

import Messages from 'i18n/messages/home';
import { FormattedMessage } from 'react-intl';

const ClearSection = (props) => {
  const { onClickRibbonBtn } = props;

  const onClickClearColumns = () => {
    onClickRibbonBtn('clearColumn');
  };

  const onClickClearCondition = () => {
    onClickRibbonBtn('clearCondition');
  };

  const menuOptions = (
    <Menu>
      <Menu.Item key='favorite' style={{ backgroundColor: 'inherit' }}>
        <ClearColumnsControl onClick={onClickClearColumns} />
      </Menu.Item>
      <Menu.Item key='addToFolder' style={{ backgroundColor: 'inherit' }}>
        <ClearQueryControl onClick={onClickClearCondition} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
      <Button
        className='grid-home__button-dropdown deleted-border'
        icon={<ClearOutlined />}
      >
        <br />
        <Typography.Text className='grid-home__label'>
          <FormattedMessage {...Messages.clearReporting} />
        </Typography.Text>
      </Button>
    </Dropdown>
  );
};

export default ClearSection;
