import React from 'react';

import { useParams } from 'react-router-dom';

import { Button, Col, Row, notification } from 'antd';

import Messages from 'i18n/messages/product';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { DownloadOutlined } from '@ant-design/icons';

import { TooltipParagraph } from 'common/components';

import FileSaver from 'file-saver';
import { useGetProductFullView } from 'hooks';
import * as productServices from 'services/product';

import './PackageHeaderDrawer.less';

const PackageHeaderDrawer = (props) => {
  const { detail } = props;
  const intl = useIntl();

  const { id: productId } = useParams();

  const { productFull } = useGetProductFullView({ productId });

  const downloadHierarchyShortDetails = async () => {
    try {
      const response =
        await productServices.downloadProductHierarchyDetailsService({
          hierarchyId: detail?.hierarchyId,
        });
      const { isSuccess, data, message } = response;

      if (isSuccess) {
        notification.success({
          message: intl.formatMessage(Messages.downloadHierarchySuccess),
        });
        FileSaver.saveAs(data?.url);
      } else {
        notification.error({
          message: message || intl.formatMessage(Messages.serverError),
        });
      }
    } catch (error) {
      notification.error({
        message: intl.formatMessage(Messages.serverError),
      });
    }
  };
  return (
    <Row className='package-header-drawer'>
      <Col flex='calc(100% - 80px)'>
        <TooltipParagraph placement='topLeft'>
          {detail?.gtin || productFull?.productName} -{' '}
          {productFull?.productName}
        </TooltipParagraph>
        <TooltipParagraph
          className='package-header-drawer__hierarchy'
          placement='topLeft'
        >
          {intl.formatMessage(Messages.hierarchyTitle)}:{' '}
          {detail?.packDescription}
        </TooltipParagraph>
      </Col>
      <Col
        flex='70px'
        style={{
          display: ' flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <Button
          type='link'
          icon={<DownloadOutlined />}
          className='download-brand-btn'
          onClick={() => downloadHierarchyShortDetails()}
          disabled={detail?.data?.productItems?.length < 1}
        />
      </Col>
    </Row>
  );
};

PackageHeaderDrawer.propTypes = {
  imageThumbnail: PropTypes.string,
};

export default PackageHeaderDrawer;
