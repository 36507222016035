import React from 'react';
import PropTypes from 'prop-types';

import { Menu } from 'antd';

import { WithLoading } from 'common/components';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/message-ag-grid';

import './AgGridDownloadOptions.less';

const AgGridDownloadOptions = (props) => {
  const intl = useIntl();
  const { handleDownload, loading } = props;

  return (
    <WithLoading loading={loading} wrapperClassName='ag-grid-download-options'>
      <Menu
        onClick={({ key }) => {
          //* send file type to parent component
          handleDownload && handleDownload(key);
        }}
        style={{ width: 180 }}
      >
        <Menu.ItemGroup
          key='fileType'
          title={intl.formatMessage(Messages.chooseDownloadFileType)}
        >
          <Menu.Item key='Xlsx'>XLSX</Menu.Item>
          <Menu.Item key='Xls'>XLS</Menu.Item>
          <Menu.Item key='Csv'>CSV</Menu.Item>
          <Menu.Item key='Xml'>XML </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </WithLoading>
  );
};

AgGridDownloadOptions.defaultProps = {
  loading: false,
};

AgGridDownloadOptions.propType = {
  loading: PropTypes.bool,
  handleDownload: PropTypes.func,
};

export default AgGridDownloadOptions;
