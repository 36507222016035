import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import userSelector from 'redux/user/selectors';

import { useGetProductFullView } from 'hooks';

import { MEMBER_TYPE_RETAILER } from 'static/Constants';

import { useGetQaSpecDataHeader } from 'pages/qa-spec/query';

const useCheckSnapshotForRetailer = () => {
  const { id: productId } = useParams();

  const { productFull } = useGetProductFullView({
    productId,
  });

  const { qaSpecDataHeader } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: !!productId,
  });

  let userInfo = useSelector(userSelector.makeSelectUserInfo());

  const checkSnapshot = () => {
    const isProductOwner = productFull?.ownerId === userInfo?.member?.id;

    const isRetailer =
      userInfo?.member?.memberType?.toLowerCase() ===
      MEMBER_TYPE_RETAILER.toLowerCase();

    const isSubmittedQaSpec =
      qaSpecDataHeader?.status?.toLowerCase() === 'submitted';

    return isRetailer && isProductOwner && isSubmittedQaSpec;
  };

  return checkSnapshot();
};

export default useCheckSnapshotForRetailer;
