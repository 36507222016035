import React, { useState, useMemo } from 'react';

import { Dropdown, Menu } from 'antd';

const SuggestionInput = (props) => {
  const {
    suggestions = [],
    Input,
    onChange,
    getValue,
    onSelect,
    ...restProps
  } = props;

  const [value, setValue] = useState('');

  const getSuggestions = () => {
    if (!suggestions?.length) return [];

    const suggestionList = mapToSelection(0);

    return filterSuggestions(suggestionList);
  };

  const mapToSelection = () => {
    return suggestions.map((suggestionItem, index) => {
      return {
        key: index + '',
        label: suggestionItem,
      };
    });
  };

  const filterSuggestions = (suggestions) => {
    return suggestions.filter((suggestionItem) => {
      return (
        suggestionItem?.label?.toLowerCase()?.indexOf(value.toLowerCase()) > -1
      );
    });
  };

  const onInputChange = (e) => {
    const value = getValue ? getValue(e) : e.target.value;

    setValue(value);

    onChange && onChange(e);
  };

  const onSelectSuggestions = ({ key }) => {
    const selection = selections.find((selectionItem) => {
      return selectionItem.key === key;
    });

    onSelect && onSelect(selection);
  };

  const selections = useMemo(() => {
    return getSuggestions();
  }, [value]);

  const Suggestions = (
    <Menu items={selections} onClick={onSelectSuggestions}></Menu>
  );

  return (
    <Dropdown overlay={Suggestions}>
      <Input onChange={onInputChange} {...restProps} />
    </Dropdown>
  );
};

export default SuggestionInput;
