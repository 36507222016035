import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Spin } from 'antd';

import AgGrid from 'common/components/ag-grid/AgGrid';

import userSelectors from 'redux/user/selectors';

import * as endpointUser from 'services/user/endpoints';
import * as action from 'redux/user/actions';

const UserActivityDetailGrid = (props) => {
  const dispatch = useDispatch();
  // cancel api flag
  const PAGE_SIZE = 20;

  const columns =
    useSelector(userSelectors.makeSelectColumnsActivityLogs()) || [];

  // generate data source

  useEffect(() => {
    dispatch(
      action.userProfileActivityLogsGridColumnInfo('user-activity-logs')
    );
    dispatch(action.getUserProfileActivityLogs(1, PAGE_SIZE));
  }, [dispatch]);

  // variables
  const classNamePrefix = `${props.classNamePrefix}__detail-grid`;

  return (
    <Row className={`${classNamePrefix}`}>
      <Col
        span={24}
        className={`${classNamePrefix}-container`}
        style={{ width: '100%', height: 390 }}
      >
        {columns.length === 0 ? (
          <Spin />
        ) : (
          <AgGrid
            columnDefs={columns}
            urlGrid={endpointUser.GET_USER_ACTIVITY_LOG}
            urlGridDistinct={endpointUser.GET_USER_ACTIVITY_LOG_DISTINCT}
            responseParams='data'
          />
        )}
      </Col>
    </Row>
  );
};

export default UserActivityDetailGrid;
