import _ from 'lodash';
import React from 'react';
import { Empty, Popover, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import {
  addMissingDefaultValue,
  compareOriginNutrients,
  getNutrientList,
} from './utils';

import DrugStandardLabel from './qa-spec-fact-panel/DrugStandardLabel';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import VerticalLabel from './qa-spec-fact-panel/VerticalLabel';
import LinearLabel from './qa-spec-fact-panel/LinearLabel';
import SupplementStandardLabel from './qa-spec-fact-panel/SupplementStandardLabel';
import VerticalLabelWithMicronutrient from './qa-spec-fact-panel/VerticalLabelWithMicronutrient';
import PetNutritionFacts from './qa-spec-fact-panel/PetNutritionFacts';

export const WrapperPopover = ({ content, children, ...restProps }) => {
  if (!content) return <div>{children}</div>;

  return (
    <Popover content={content} {...restProps}>
      <div>{children}</div>
    </Popover>
  );
};

const WrapperFactsPanelItem = ({ property, children, ...restProps }) => {
  const isSnapshot = useCheckSnapshotForRetailer();

  const { snapshotData } = property ?? {};

  if (!isSnapshot) return <div>{children}</div>;

  const panelFactsType = snapshotData?.factsPanelType;

  const FactsPanel = {
    SupplementFactsPanel: RenderSupplement,
    NutritionFactsPanel: RenderNutritionPanel,
    DrugFactsPanel: RenderDrugFacts,
    PetNutritionFactsPanel: RenderPetFood,
  };

  const FactPanelItem = FactsPanel?.[panelFactsType];
  const content = FactPanelItem ? (
    <FactPanelItem property={property} />
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 245,
        height: 300,
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );

  return (
    <WrapperPopover
      content={content}
      title={
        <span
          style={{
            fontSize: 18,
            backgroundColor: '#55acee',
            borderRadius: 4,
            padding: '0px 10px',
            color: 'white',
          }}
        >
          Previous Version
        </span>
      }
      trigger='hover'
      placement='left'
      {...restProps}
    >
      <div style={{ position: 'relative' }}>
        <div>{children}</div>
        {property?.isDeleted && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '100%',
              border: '2px solid #f5222d',
            }}
          >
            <Tag
              style={{ position: 'absolute', top: 4, right: -2 }}
              color='error'
            >
              Deleted
            </Tag>
          </div>
        )}
      </div>
    </WrapperPopover>
  );
};
export default WrapperFactsPanelItem;

const RenderNutritionPanel = ({ property }) => {
  const { nutritionFactProperties = [], snapshotData, ...restLabel } = property;

  const labelType = {
    US_FDA_NFP_2020_STANDARD: VerticalLabel,
    US_FDA_NFP_2020_STANDARD_SIDE_BY_SIDE: VerticalLabelWithMicronutrient,
    US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES: LinearLabel,
  };

  const nutrientFormatType = snapshotData?.nutrientFormatType;
  const FactPanel = labelType?.[nutrientFormatType] || VerticalLabel;

  const { snapshotValue, currentValue } = addMissingDefaultValue({
    snapshotValue: snapshotData?.nutritionFactProperties || [],
    currentValue: nutritionFactProperties || [],
  });

  const snapshotNutrients = compareOriginNutrients({
    snapshotValue,
    currentValue,
    fieldName: 'nutritionTypeCode',
  });

  const nutrients = getNutrientList(snapshotNutrients);

  return (
    <FactPanel
      property={snapshotData}
      nutrients={nutrients}
      snapshotValue={restLabel}
    />
  );
};

const RenderSupplement = ({ property }) => {
  const { snapshotData, nutrients, dietaryIngredients, ...restLabel } =
    property ?? {};

  const snapshotNutrients = compareOriginNutrients({
    snapshotValue: snapshotData?.nutrients || [],
    currentValue: nutrients,
    fieldName: 'nutritionName',
  });

  const snapshotDietaryIngredients = compareOriginNutrients({
    snapshotValue: snapshotData?.dietaryIngredients || [],
    currentValue: dietaryIngredients,
    fieldName: 'ingredientName',
  });

  return (
    <SupplementStandardLabel
      property={snapshotData}
      snapshotValue={restLabel}
      nutrients={snapshotNutrients}
      dietaryIngredients={snapshotDietaryIngredients}
    />
  );
};

const RenderDrugFacts = ({ property }) => {
  const { snapshotData, ...rest } = property;
  const addHightTextDugFacts = _.cloneDeep(snapshotData);

  const drugFactsPath = [
    { path: 'otherInformation', fieldName: 'otherInformation' },
    { path: 'activeIngredients', fieldName: 'ingredientName' },
    { path: 'directions', fieldName: 'ageGroup' },
    { path: 'uses.useDetail', fieldName: 'useDetail' },
    { path: 'warnings', fieldName: 'warningText' },
  ];

  drugFactsPath.forEach(({ path, fieldName }) => {
    const snapshotValue = compareOriginNutrients({
      snapshotValue: _.get(snapshotData, path) || [],
      currentValue: _.get(property, path) || [],
      fieldName,
    });

    _.set(addHightTextDugFacts, path, snapshotValue);
  });

  return (
    <DrugStandardLabel property={addHightTextDugFacts} snapshotValue={rest} />
  );
};

const RenderPetFood = ({ property }) => {
  const { snapshotData, nutrients, ...rest } = property;

  const snapshotNutrients = compareOriginNutrients({
    snapshotValue: snapshotData?.nutrients || [],
    currentValue: nutrients,
    fieldName: 'nutrientName',
  });

  return (
    <PetNutritionFacts
      previousData={rest}
      currentData={snapshotData}
      nutrients={snapshotNutrients}
    />
  );
};
