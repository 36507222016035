import { useCallback, useEffect } from 'react';
import { Modal } from 'antd';
import { dispatchCancelRouting, CANCEL_ROUTING_EVENT_NAME } from './event';

const useHandleCancelRoutingEvent = ({
  setBlockItem,
  blockId,
  setIsShowModal,
}) => {
  const handleCancelRoutingEvent = () => {
    const blockItem = window.urlBlocked[blockId];
    if (blockItem?.isPreventRouting) {
      setBlockItem({
        blocked: true,
        confirmStatus: false,
        isPreventRouting: blockItem?.isPreventRouting,
      });
      setIsShowModal(false);
    }
  };

  const onCancleRouting = useCallback(async () => {
    dispatchCancelRouting();

    //* destroy all routing confirm modal when one  cancel
    Modal.destroyAll();
  }, []);

  useEffect(() => {
    window.addEventListener(
      CANCEL_ROUTING_EVENT_NAME,
      handleCancelRoutingEvent
    );

    return () => {
      window.removeEventListener(
        CANCEL_ROUTING_EVENT_NAME,
        handleCancelRoutingEvent
      );
    };
  }, []);

  return { onCancleRouting };
};

export { useHandleCancelRoutingEvent };
