import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

const ProductDescription = (props) => {
  const { dataDetail } = props;

  return (
    <Row>
      <Col className='product-info__description' span={24}>
        <Text strong='true'>Extended Description:</Text>
      </Col>
      <Col span={20} offset={4}>
        {dataDetail && dataDetail.productDescription}
      </Col>
    </Row>
  );
};

ProductDescription.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductDescription;
