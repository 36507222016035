import React from 'react';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import { forwardTo } from 'utils/common/route';

import { ROUTE } from 'static/Constants';

const EditTransitionPageButton = (props) => {
  const { disabled, selectedTransitionPageIds } = props;

  const handleOpenEditTransitionPage = () => {
    forwardTo(
      ROUTE.TRANSITION_PAGE_MANAGE + `/${selectedTransitionPageIds?.[0]}`
    );
  };

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={'Edit Transition Page'}
      disabled={disabled}
      onClick={handleOpenEditTransitionPage}
    />
  );
};

export default EditTransitionPageButton;
