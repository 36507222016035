/* eslint-disable react-hooks/rules-of-hooks */
import { useInjectSaga } from 'utils/common/injectSaga';
import { useInjectReducer } from 'utils/common/injectedReducers';

import transitionPageReducer from 'pages/transition-page/controllers/reducer';
import transitionPageSaga from 'pages/transition-page/controllers/saga';
import * as transitionPageActions from 'pages/transition-page/controllers/actions';
import * as transitionPageSelectors from 'pages/transition-page/controllers/selectors';

import maintenanceFormReducer from 'pages/transition-page/controllers/reducer';
import maintenanceFormSaga from 'pages/transition-page/controllers/saga';
import * as maintenanceFormActions from 'pages/transition-page/controllers/actions';
import * as maintenanceFormSelectors from 'pages/transition-page/controllers/selectors';

import productFullViewSaga from 'pages/product-full-view/controllers/saga';
import productFullViewReducer from 'pages/product-full-view/controllers/reducer';
import * as productFullViewSelectors from 'pages/product-full-view/controllers/selectors';
import * as productFullViewActions from 'pages/product-full-view/controllers/actions';

import qaSpecSaga from 'pages/qa-spec/controllers/saga';
import qaSpecReducer from 'pages/qa-spec/controllers/reducer';
import * as qaSpecSelectors from 'pages/qa-spec/controllers/selectors';
import * as qaSpecActions from 'pages/qa-spec/controllers/actions';

import ticketSystemSaga from 'pages/ticketing-system/controllers/saga';
import ticketSystemReducer from 'pages/ticketing-system/controllers/reducer';
import * as ticketSystemSelectors from 'pages/ticketing-system/controllers/selectors';
import * as ticketSystemActions from 'pages/ticketing-system/controllers/actions';

import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';

const REDUX_MODULE = {
  ALL_MODULE: ['GLOBAL', 'TRANSITION_PAGE'],
  GLOBAL: {
    KEY: 'global',
    ACTIONS: globalActions,
    SELECTORS: globalSelectors,
  },
  TRANSITION_PAGE: {
    KEY: 'transitionPage',
    REDUCER: transitionPageReducer,
    SAGA: transitionPageSaga,
    ACTIONS: transitionPageActions,
    SELECTORS: transitionPageSelectors,
  },
  MAINTAINANCE_FORM: {
    KEY: 'maintenanceForm',
    REDUCER: maintenanceFormReducer,
    SAGA: maintenanceFormSaga,
    ACTIONS: maintenanceFormActions,
    SELECTORS: maintenanceFormSelectors,
  },
  PRODUCT_FULL_VIEW: {
    KEY: 'productFullView',
    REDUCER: productFullViewReducer,
    SAGA: productFullViewSaga,
    ACTIONS: productFullViewActions,
    SELECTORS: productFullViewSelectors,
  },
  QA_SPEC: {
    KEY: 'qaSpec',
    REDUCER: qaSpecReducer,
    SAGA: qaSpecSaga,
    ACTIONS: qaSpecActions,
    SELECTORS: qaSpecSelectors,
  },
  TICKET_SYSTEM: {
    KEY: 'ticketingSystem',
    REDUCER: ticketSystemReducer,
    SAGA: ticketSystemSaga,
    ACTIONS: ticketSystemActions,
    SELECTORS: ticketSystemSelectors,
  },
};

const useInjectRedux = (module) => {
  if (!module) return;

  const { KEY, REDUCER, SAGA } = module;

  useInjectReducer({ key: KEY, reducer: REDUCER });
  useInjectSaga({ key: KEY, saga: SAGA });
};

export { REDUX_MODULE, useInjectRedux };
