import React from 'react';

import { StyledModal, DataDisplay } from 'common/components';
import PublicationTable from './PublicationTable';

import { useGridView } from 'hooks';
import { useWithdrawPublication } from 'hooks/useWithdrawPublication';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';

const WithdrawPublicationModal = (props) => {
  const intl = useIntl();

  const { visible, closeModal, products } = props;

  const { reloadPage } = useGridView();

  const {
    publicationsQuery,
    selectedPublications,
    selectPublications,
    confirmDeleteProductsPublication,
    shouldReload,
  } = useWithdrawPublication({
    products,
    enabled: visible,
  });

  const { data, isLoading, error } = publicationsQuery;
  const publicationList = data?.publicationListModels;

  const onOk = () => {
    confirmDeleteProductsPublication();
  };

  const onCancel = () => {
    closeModal && closeModal();
    if (shouldReload) {
      setTimeout(() => {
        reloadPage({ clearSelection: true });
      }, 300);
    }
  };

  const errorMessage = intl.formatMessage(Messages.getPublicationErrorMessage);

  return (
    <StyledModal
      title={intl.formatMessage(Messages.withdrawPublicationModalTitle)}
      visible={visible}
      width={1200}
      maskClosable={false}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{
        disabled: !selectedPublications.length,
      }}
      destroyOnClose
    >
      <DataDisplay
        loading={isLoading}
        data={publicationList}
        error={error && errorMessage}
      >
        <PublicationTable
          data={publicationList}
          onSelect={selectPublications}
        />
      </DataDisplay>
    </StyledModal>
  );
};

export default WithdrawPublicationModal;
