import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Popconfirm, Typography, Upload, Modal } from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { mapFileTypeToPreviewFile } from '../../mapper/mapFileTypeToPreviewFile';

import usePrevious from 'hooks/usePrevious';
import AttachFile from './AttachFile';

import classnames from 'classnames';

import './AttachList.less';

const { Text } = Typography;

const AttachList = (props) => {
  const {
    fileList,
    refUpload,
    onDrop,
    disabledAdd,
    handleRemoveFileId,
    isHaveMaxFileWarning,
    isSocial,
  } = props;

  const [previewSrc, setPreviewSrc] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const prevFileList = usePrevious(fileList);

  const refAttachList = useRef();

  const handleAddNewFile = () => {
    if (refUpload.current && refAttachList.current) {
      refUpload.current.handleClickUpload();
    }
  };

  const handleRemoveAllFile = () => {
    refUpload.current && refUpload.current.removeAllFile();
  };

  useEffect(() => {
    if (fileList?.length > prevFileList?.length) {
      refAttachList.current.scrollTop = refAttachList.current.scrollHeight;
    }
  }, [fileList, prevFileList]);

  useEffect(() => {
    if (isHaveMaxFileWarning) {
      refAttachList.current.scrollTop = refAttachList.current.scrollHeight;
    }
  }, [isHaveMaxFileWarning]);

  const getPreviewImage = async (fileItem) => {
    const src = await mapFileTypeToPreviewFile(fileItem);

    setPreviewSrc(src);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const renderAddAndRemoveBtn = () => (
    <div className='attach-list__upload-btn'>
      <div className='attach-list__btn-group'>
        <Button
          className={classnames('attach-list__btn', 'attach-list__btn--add')}
          onClick={handleAddNewFile}
          disabled={disabledAdd}
        >
          <PlusOutlined />
        </Button>
      </div>
      <div>
        <Popconfirm
          placement='topRight'
          title='Do you want to remove all attached file?'
          onConfirm={handleRemoveAllFile}
          okText='Yes'
          cancelText='No'
        >
          <Button
            className={classnames(
              'attach-list__btn',
              'attach-list__btn--close'
            )}
          >
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </div>
    </div>
  );

  const renderUploadFile = () => {
    return (
      <>
        {renderAddAndRemoveBtn()}
        <Upload
          showUploadList={{
            showRemoveIcon: true,
            removeIcon: <DeleteOutlined />,
          }}
          fileList={fileList}
          listType='picture-card'
          onPreview={getPreviewImage}
          onRemove={(file) => handleRemoveFileId(file?.uid)}
        />

        <Modal
          visible={previewVisible}
          title={null}
          footer={null}
          onCancel={handleCancel}
          className={'attach-list_modal-preview'}
        >
          <img alt='example' style={{ width: '100%' }} src={previewSrc} />
        </Modal>
      </>
    );
  };

  return (
    <>
      {fileList?.length > 0 && (
        <Row
          className='attach-list scroller'
          ref={refAttachList}
          onDrop={onDrop}
        >
          <Col xs={24} className='attach-list__list'>
            {isSocial ? (
              renderUploadFile()
            ) : (
              <>
                {fileList.map((fileItem) => (
                  <AttachFile
                    fileItem={fileItem}
                    refUpload={refUpload}
                    handleRemoveFileId={handleRemoveFileId}
                    key={fileItem?.uid}
                  />
                ))}
                <div className='attach-list__btn-group'>
                  <Button
                    className={classnames(
                      'attach-list__btn',
                      'attach-list__btn--add'
                    )}
                    onClick={handleAddNewFile}
                    disabled={disabledAdd}
                  >
                    <PlusOutlined />
                  </Button>
                  <Popconfirm
                    placement='topRight'
                    title='Do you want to remove all attached file?'
                    onConfirm={handleRemoveAllFile}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button
                      className={classnames(
                        'attach-list__btn',
                        'attach-list__btn--close'
                      )}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </>
            )}

            {isHaveMaxFileWarning && (
              <div style={{ width: '100%' }}>
                <Text type='danger'>
                  Only upload maximum of 6 files at a time
                </Text>
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default AttachList;
