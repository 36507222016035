import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { notification } from 'antd';
import { AuditOutlined } from '@ant-design/icons';

import { RibbonButton, dialogFunction } from 'common/components';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import { executeMatchingApl } from 'services/aplDetail';
import {
  toggleReloadGridApl,
  resetReloadGridApl,
} from 'pages/branded-products-apl/controllers/actions';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import userSelectors from 'redux/user/selectors';

const ExecuteMatchingGrid = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const memberID = useSelector(userSelectors.makeSelectUserMemberId());

  const selectedApl = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  //* If visibility = Company (Member).
  //* Only users who belong to the company owner could execute matching.
  //* If visibility = Personal.
  //* oNLY users who created the APL could execute matching.
  const isDisabledExecuteMatching = (apl, currentMemberId) => {
    if (apl?.length !== 1) {
      return true;
    }
    if (apl?.[0]?.visibility === 'Personal') {
      return !apl?.[0]?.isOwner;
    }
    if (apl?.[0]?.visibility === 'Company') {
      return !(apl?.[0]?.ownerCompanyId === currentMemberId);
    }
    return true;
  };

  const handleExecuteMatching = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(messagesProduct.confirmExecuteMatchingApl),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          const response = await executeMatchingApl({
            aplId: selectedApl[0]?.id,
          });

          if (response?.isSuccess) {
            notification.success({
              message:
                response?.message ??
                intl.formatMessage(messagesProduct.executeMatchingAplSuccess),
            });
            dispatch(toggleReloadGridApl());
          } else {
            notification.error({
              message: response?.message,
            });
          }
        } catch (error) {
          throw new Error(error);
        } finally {
          setTimeout(() => {
            dispatch(resetReloadGridApl());
          });
        }
      },
    });
  };

  return (
    <RibbonButton
      icon={<AuditOutlined style={{ height: 26 }} />}
      label={Messages.executeMatchingApl}
      onClick={handleExecuteMatching}
      disabled={isDisabledExecuteMatching(selectedApl, memberID)}
    />
  );
};

export default ExecuteMatchingGrid;
