import { useReducer, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAsync } from 'hooks';

import { sleep } from 'utils/delay';

import {
  getNewPropertiesAfterResizing,
  getNewPropertiesAfterToggling,
  reorder,
  getSelectedProperty,
  checkExistedProperty,
  getNewPropertiesAfterDeleting,
  findIndexProperty,
  getUniqueFieldPaths,
  getUniqueProperties,
  getAnotherDefaultProperties,
} from './utils';

import { searchModuleProperties } from 'common/components/mapping-properties/utils';
import * as selectorProduct from 'pages/branded-products/controllers/selectors';

import { getPropertiesCustomizedGrid } from 'services/product';
import { getListGridConfigs } from 'services/grid';

const initialGrid = {
  moduleProperties: [],
  searchModuleProperties: [],
  defaultSelectedProperties: [],
  selectedProperties: [],
  selectedPropertyIds: [],
  draggingPropertyId: null,
  statusChangeGrid: 'idle',
  statusChangeProperty: 'idle',
  fullPreviewGrid: false,
  searchText: '',
  keyCollapse: [],
};

const actionsConfig = {
  SET_MODULE_PROPERTIES: 'SET_MODULE_PROPERTIES',
  SET_SEARCH_MODULE_PROPERTIES: 'SET_SEARCH_MODULE_PROPERTIES',
  SET_SELECTED_PROPERTIES: 'SET_SELECTED_PROPERTIES',
  SET_DEFAULT_PROPERTIES: 'SET_DEFAULT_PROPERTIES',
  ADD_NEW_PROPERTY: 'ADD_NEW_PROPERTY',
  UPDATE_STATUS_GRID: 'UPDATE_STATUS_GRID',
  UPDATE_STATUS_PROPERTY: 'UPDATE_STATUS_PROPERTY',
  CHANGE_SIZE_COLUMN: 'CHANGE_SIZE_COLUMN',
  TOGGLE_PROPERTY: 'TOGGLE_PROPERTY',
  MOVE_COLUMN: 'MOVE_COLUMN',
  DELETE_PROPERTY: 'DELETE_PROPERTY',
  EXPAND_GRID: 'EXPAND_GRID',
  COLLAPSE_GRID: 'COLLAPSE_GRID',
  UPDATE_SEARCH_TEXT: 'UPDATE_SEARCH_TEXT',
  UPDATE_PROPERTY_IDS: 'UPDATE_PROPERTY_IDS',
  SET_DRAGGING_PROPERTY_ID: 'SET_DRAGGING_PROPERTY_ID',
  RESET_DEFAULT_SELECTED_PROPERTIES: 'RESET_DEFAULT_SELECTED_PROPERTIES',
  UPDATE_KEY_COLLAPSE: 'UPDATE_KEY_COLLAPSE',
};

const configReducer = (state, action) => {
  switch (action.type) {
    case actionsConfig.SET_MODULE_PROPERTIES: {
      return {
        ...state,
        moduleProperties: action.payload,
        searchModuleProperties: action.payload,
      };
    }

    case actionsConfig.SET_SEARCH_MODULE_PROPERTIES: {
      return {
        ...state,
        searchModuleProperties: action.payload,
      };
    }

    case actionsConfig.SET_SELECTED_PROPERTIES: {
      return {
        ...state,
        selectedProperties: action.payload,
      };
    }

    case actionsConfig.SET_DEFAULT_PROPERTIES: {
      return {
        ...state,
        defaultSelectedProperties: action.payload,
      };
    }

    case actionsConfig.ADD_NEW_PROPERTY: {
      const { selectedProperties, position } = action.payload;

      const newProperties = [
        ...state.selectedProperties.slice(0, position),
        ...selectedProperties,
        ...state.selectedProperties.slice(
          position,
          state.selectedProperties.length
        ),
      ];

      return {
        ...state,
        selectedProperties: newProperties,
      };
    }

    case actionsConfig.TOGGLE_PROPERTY: {
      const { isShow, fieldName, moduleName } = action.payload;
      const { selectedProperties } = state;

      let newProperties = [];

      if (isShow) {
        newProperties = getNewPropertiesAfterToggling(
          selectedProperties,
          moduleName,
          fieldName,
          false
        );
      } else {
        newProperties = getNewPropertiesAfterToggling(
          selectedProperties,
          moduleName,
          fieldName,
          true
        );
      }

      return {
        ...state,
        selectedProperties: newProperties,
      };
    }

    case actionsConfig.MOVE_COLUMN: {
      const { moduleName, fieldName, toIndex } = action.payload;
      const { selectedProperties } = state;

      const fromIndex = findIndexProperty(
        selectedProperties,
        moduleName,
        fieldName
      );

      const newProperties = reorder(selectedProperties, fromIndex, toIndex);

      return {
        ...state,
        selectedProperties: newProperties,
      };
    }

    case actionsConfig.UPDATE_STATUS_GRID: {
      return {
        ...state,
        statusChangeGrid: action.payload,
      };
    }

    case actionsConfig.UPDATE_STATUS_PROPERTY: {
      return {
        ...state,
        statusChangeProperty: action.payload,
      };
    }

    case actionsConfig.CHANGE_SIZE_COLUMN: {
      const { moduleName, fieldName, width } = action.payload;

      const newProperties = getNewPropertiesAfterResizing(
        state.selectedProperties,
        moduleName,
        fieldName,
        width
      );

      return {
        ...state,
        selectedProperties: newProperties,
      };
    }

    case actionsConfig.EXPAND_GRID: {
      return {
        ...state,
        fullPreviewGrid: true,
      };
    }

    case actionsConfig.COLLAPSE_GRID: {
      return {
        ...state,
        fullPreviewGrid: false,
      };
    }

    case actionsConfig.UPDATE_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
      };
    }

    case actionsConfig.UPDATE_PROPERTY_IDS: {
      return {
        ...state,
        selectedPropertyIds: action.payload,
      };
    }

    case actionsConfig.SET_DRAGGING_PROPERTY_ID: {
      return {
        ...state,
        draggingPropertyId: action.payload,
      };
    }

    case actionsConfig.UPDATE_PREVIOUS_SELECTED_PROPERTIES: {
      return {
        ...state,
        previousSelectedProperties: action.payload,
      };
    }
    case actionsConfig.UPDATE_KEY_COLLAPSE: {
      return {
        ...state,
        keyCollapse: action.payload,
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

export const useGridConfig = () => {
  const [stateConfig, dispatchConfig] = useReducer(configReducer, initialGrid);

  const handleUpdateModuleProperties = useCallback((modulesValue) => {
    dispatchConfig({
      type: actionsConfig.SET_MODULE_PROPERTIES,
      payload: modulesValue,
    });
  }, []);

  const handleUpdateStatusGrid = (value) => {
    dispatchConfig({
      type: actionsConfig.UPDATE_STATUS_GRID,
      payload: value,
    });
  };

  const handleUpdateStatusProperty = (value) => {
    dispatchConfig({
      type: actionsConfig.UPDATE_STATUS_PROPERTY,
      payload: value,
    });
  };

  const handleUpdateKeyCollapse = (key) => {
    dispatchConfig({ type: actionsConfig.UPDATE_KEY_COLLAPSE, payload: key });
  };

  const handleAddNewProperty = (
    modulesNameMapping,
    fieldFullPath,
    indexDestination,
    selectedPropertyIds
  ) => {
    if (
      checkExistedProperty(
        stateConfig.selectedProperties,
        fieldFullPath,
        selectedPropertyIds
      )
    ) {
      if (selectedPropertyIds.length === 0) return;

      const uniqueFieldPaths = getUniqueFieldPaths(
        stateConfig.selectedProperties,
        selectedPropertyIds
      );

      const uniqueProperties = getUniqueProperties(
        modulesNameMapping,
        uniqueFieldPaths
      );

      dispatchConfig({
        type: actionsConfig.ADD_NEW_PROPERTY,
        payload: {
          selectedProperties: uniqueProperties,
          position: indexDestination,
        },
      });
    } else {
      const selectedProperties = getSelectedProperty(
        modulesNameMapping,
        fieldFullPath,
        selectedPropertyIds
      );

      dispatchConfig({
        type: actionsConfig.ADD_NEW_PROPERTY,
        payload: { selectedProperties, position: indexDestination },
      });
    }
  };

  const handleToggleProperty = async (fieldName, moduleName, isShow) => {
    handleUpdateStatusGrid('loading');
    await sleep(500);

    dispatchConfig({
      type: actionsConfig.TOGGLE_PROPERTY,
      payload: {
        isShow,
        fieldName,
        moduleName,
      },
    });

    await sleep(500);
    handleUpdateStatusGrid('success');
  };

  const handleColumnResized = (event) => {
    const {
      actualWidth,
      colId,
      colDef: { minWidth },
    } = event.column;

    const [moduleName, fieldName] = colId.split('-');

    if (actualWidth === minWidth) return;

    dispatchConfig({
      type: actionsConfig.CHANGE_SIZE_COLUMN,
      payload: { moduleName, fieldName, width: actualWidth },
    });
  };

  const handleColumnMoved = async (event) => {
    // TODO: Sync selected properties when moving cols
    handleUpdateStatusProperty('loading');

    await sleep(500);

    if (!event.column?.colId) return;

    const [moduleName, fieldName] = event.column.colId.split('-');
    const toIndex = event.toIndex;

    dispatchConfig({
      type: actionsConfig.MOVE_COLUMN,
      payload: { moduleName, fieldName, toIndex },
    });

    await sleep(1000);
    handleUpdateStatusProperty('success');
  };

  const handleDeleteProperty = (moduleName, fieldName, isCustomField) => {
    const newProperties = getNewPropertiesAfterDeleting(
      stateConfig.selectedProperties,
      moduleName,
      fieldName,
      isCustomField
    );

    dispatchConfig({
      type: actionsConfig.SET_SELECTED_PROPERTIES,
      payload: newProperties,
    });
  };

  const handleResetColumns = async () => {
    handleUpdateStatusGrid('loading');
    handleUpdateStatusProperty('loading');

    await sleep(500);

    dispatchConfig({
      type: actionsConfig.SET_SELECTED_PROPERTIES,
      payload: stateConfig.defaultSelectedProperties,
    });

    await sleep(500);
    handleUpdateStatusGrid('success');
    handleUpdateStatusProperty('success');
  };

  const handleSetDefaultProperties = () => {
    dispatchConfig({
      type: actionsConfig.SET_DEFAULT_PROPERTIES,
      payload: stateConfig.selectedProperties,
    });
  };

  const handleSwapProperty = async (fromIndex, toIndex) => {
    const orderedProperties = reorder(
      stateConfig.selectedProperties,
      fromIndex,
      toIndex
    );

    dispatchConfig({
      type: actionsConfig.SET_SELECTED_PROPERTIES,
      payload: orderedProperties,
    });

    await sleep(500);

    handleUpdateStatusGrid('success');
    handleUpdateStatusProperty('success');
  };

  const handleExpandGrid = () =>
    dispatchConfig({ type: actionsConfig.EXPAND_GRID });

  const handleCollapseGrid = () =>
    dispatchConfig({ type: actionsConfig.COLLAPSE_GRID });

  const handleUpdateSearchText = (value) => {
    const { searchText, moduleProperties } = stateConfig;

    if (value === searchText) return;

    // clearing search text will back to default module properties
    if (value === '') {
      dispatchConfig({
        type: actionsConfig.SET_SEARCH_MODULE_PROPERTIES,
        payload: moduleProperties,
      });
    }

    dispatchConfig({
      type: actionsConfig.UPDATE_SEARCH_TEXT,
      payload: value,
    });

    const newModuleProperties = searchModuleProperties(moduleProperties, value);

    dispatchConfig({
      type: actionsConfig.UPDATE_KEY_COLLAPSE,
      payload: [newModuleProperties[0]?.moduleName],
    });

    dispatchConfig({
      type: actionsConfig.SET_SEARCH_MODULE_PROPERTIES,
      payload: newModuleProperties,
    });
  };

  const handleUpdateSelectedProperties = useCallback((properties) => {
    dispatchConfig({
      type: actionsConfig.SET_SELECTED_PROPERTIES,
      payload: properties,
    });
  }, []);

  const handleUpdatePropertyIds = useCallback((ids) => {
    dispatchConfig({
      type: actionsConfig.UPDATE_PROPERTY_IDS,
      payload: ids,
    });
  }, []);

  const handleUpdateDraggingId = (id) => {
    dispatchConfig({
      type: actionsConfig.SET_DRAGGING_PROPERTY_ID,
      payload: id,
    });
  };

  const handleResetDefaultSelectedProperties = () => {
    const { selectedProperties } = stateConfig;

    const anotherDefaultProperties =
      getAnotherDefaultProperties(selectedProperties);

    const newProperties = [...selectedProperties, ...anotherDefaultProperties];

    dispatchConfig({
      type: actionsConfig.SET_SELECTED_PROPERTIES,
      payload: newProperties,
    });
  };

  return {
    stateConfig,
    handleUpdateStatusGrid,
    handleUpdateStatusProperty,
    handleAddNewProperty,
    handleSetDefaultProperties,
    handleToggleProperty,
    handleColumnResized,
    handleColumnMoved,
    handleDeleteProperty,
    handleResetColumns,
    handleSwapProperty,
    handleExpandGrid,
    handleCollapseGrid,
    handleUpdateSearchText,
    handleUpdateModuleProperties,
    handleUpdateSelectedProperties,
    handleUpdatePropertyIds,
    handleUpdateDraggingId,
    handleResetDefaultSelectedProperties,
    handleUpdateKeyCollapse,
  };
};

export const useGetPropertiesCustomizedGrid = ({ isEnabled }) => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(getPropertiesCustomizedGrid());
    }
  }, [isEnabled, run]);

  return {
    propertiesCustomizedGrid: data?.properties ?? [],
    loading: status === 'idle' || status === 'pending',
    status,
  };
};

const params = {
  pageIndex: 1,
  pageSize: 999999,
  gridName: 'product-detail-grid',
};

export const useGetListGridConfigs = ({ isEnabled }) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(getListGridConfigs(params));
    }
  }, [isEnabled, run]);

  const fetchListGridConfigs = () => {
    run(getListGridConfigs(params));
  };

  return {
    configs: data?.gridData ?? [],
    fetchListGridConfigs,
  };
};

export const useApplyPreviewGridConfig = () => {
  const applyGridConfig = useSelector(
    selectorProduct.makeSelectApplyGridConfig()
  );

  return {
    applyGridConfig,
    isPreviewGridConfigAcive: applyGridConfig?.isApply,
  };
};
