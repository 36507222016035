import React from 'react';
import { Row, Typography, Avatar } from 'antd';
import InfoWindow from './InfoWindow';
import classNames from 'classnames';
import './MarkerMap.less';
const MarkerMap = ({ show, hover, place, zoomMap, onCloseInfoWindow }) => {
  return (
    <>
      <Row
        className={classNames({
          'marker-map': true,
          'marker-map--active': show || hover,
        })}
      >
        <Avatar
          className='marker-map__imgage'
          src='https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red.png'
        ></Avatar>
        {zoomMap > 10 && (
          <Typography.Title level={5} className='marker-map__label'>
            {place?.locationName
              ? place?.locationName
              : `${place?.city}. ${place?.state}, ${place?.country}`}
          </Typography.Title>
        )}
      </Row>
      {show && (
        <InfoWindow place={place} onCloseInfoWindow={onCloseInfoWindow} />
      )}
    </>
  );
};
export default MarkerMap;
