import {
  ASSET_TYPE,
  ASSET_SUBTYPE,
  COMPANY_SUBTYPE,
  DOCUMENT_SUBTYPE,
  MULTIMEDIA_SUBTYPE,
} from 'static/Constants';

import sortByKey from 'utils/sortByKey';

import { PIM_ASSET_TYPE_DEFINE } from 'static/Constants';

const mapToObjectField = (item, disabled) => {
  return { value: item, disabled };
};

const mapTypeToAssetSubType = (assetType, addType, assetSubTypeOptions) => {
  const [PLANO, ADD, MARKET, DOCUMENT] = PIM_ASSET_TYPE_DEFINE;

  let subTypeOptions = [];

  if (!assetType) return [];
  switch (assetType) {
    case ASSET_TYPE?.[0]: //? asset
      subTypeOptions =
        addType === PLANO
          ? ASSET_SUBTYPE.map((item) => mapToObjectField(item, false))
          : ASSET_SUBTYPE.map((item) => {
              if (item === 'Planogram') {
                return mapToObjectField(item, true);
              } else {
                return mapToObjectField(item, false);
              }
            });
      break;

    case ASSET_TYPE?.[1]: //? company
      subTypeOptions =
        addType === MARKET
          ? COMPANY_SUBTYPE.map((item) => {
              if (item === 'Marketing') {
                return mapToObjectField(item, false);
              } else {
                return mapToObjectField(item, true);
              }
            })
          : addType === ADD
          ? COMPANY_SUBTYPE.map((item) => {
              if (item === 'Marketing') {
                return mapToObjectField(item, true);
              } else {
                return mapToObjectField(item, false);
              }
            })
          : COMPANY_SUBTYPE.map((item) => mapToObjectField(item, false));
      break;

    case ASSET_TYPE?.[2]: //? document
      subTypeOptions = DOCUMENT_SUBTYPE.map((item) =>
        mapToObjectField(item, false)
      );
      break;

    case ASSET_TYPE?.[3]: //? multimedia
      subTypeOptions = MULTIMEDIA_SUBTYPE.map((item) =>
        mapToObjectField(item, false)
      );
      break;
    default:
      subTypeOptions = [];
      break;
  }

  return sortByKey(subTypeOptions, 'value');
};

export { mapTypeToAssetSubType };
