import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, Typography, message } from 'antd';
import {
  BarsOutlined,
  CheckCircleTwoTone,
  DoubleRightOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { useIntl } from 'react-intl';

import MessagesGridView from 'i18n/messages/gridView';

import Messages from 'i18n/messages/home';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { getGridName } from 'utils/getGridName';
import { updateSearchText } from 'pages/home/utils';

import { useGetSavedConfig } from './hooks';

import { RIBBON_TYPES } from 'static/Constants';
import * as actionsRibbon from 'redux/ribbon/actions';

import {
  updateQueryConditions,
  updateQueryAdvance,
  updateIdQuery,
} from 'utils/queryCondition';
import useGetDataIdQuery from 'hooks/useGetDataIdQuery';

const { SubMenu } = Menu;
const { Text } = Typography;

const SavedConfigurations = ({ disabled, setVisible, isMounted }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const pathname = window.location.pathname;

  const idQueryActive = useGetDataIdQuery(window.location.pathname);

  const gridName = getGridName(pathname);

  const data = useGetSavedConfig(gridName, isMounted);

  const handleSetDefault = (item) => {
    const { query, id } = item;
    const { queryConditions, columns, ribbonTypes, searchText } =
      JSON.parse(query);
    dispatch(actionsGridView.myQueryDoubleClick(true));
    updateSearchText(dispatch, searchText, pathname);
    dispatch(actionsGridView.checkQueryCondition(true));
    message.success(intl.formatMessage(MessagesGridView.applyConfig));

    if (ribbonTypes === RIBBON_TYPES.DETAILSVIEW) {
      dispatch(actionsGridView.chooseGridColumns(columns, id));
    } else {
      dispatch(actionsGridView.chooseGridColumns([], id));
    }

    updateQueryConditions(dispatch, queryConditions, window.location.pathname);
    updateQueryAdvance(dispatch, queryConditions, window.location.pathname);
    updateIdQuery(dispatch, id, window.location.pathname);

    dispatch(actionsRibbon.changeType(ribbonTypes));
    dispatch(actionsGridView.updateVisible(false));
    dispatch(actionsGridView.toggleRefreshQuery(true));
  };

  const checkApplyConfig = (item) => item.id === idQueryActive;

  const checkIsSharedConfig = (item) => item.isOwner === false;

  const data10 = data?.gridData?.slice(0, 10);

  return (
    <Menu className='grid-config__menu'>
      <SubMenu
        key='savedConfig'
        title={
          <ButtonSmallIcon
            icon={<BarsOutlined />}
            label={Messages.savedConfig}
            disabled={disabled}
            className='grid-config__button'
          />
        }
      >
        {data10?.map((item) => (
          <Menu.Item
            key={item.id}
            onClick={() => handleSetDefault(item)}
            className='grid-config__menu--item'
          >
            <Text>{item.name}</Text>
            <Text>
              {checkApplyConfig(item) ? (
                <CheckCircleTwoTone twoToneColor='#52c41a' />
              ) : null}
            </Text>
            <Text>
              {checkIsSharedConfig(item) ? <ShareAltOutlined /> : null}
            </Text>
          </Menu.Item>
        ))}

        {data?.paging.totalRecord > 10 && (
          <Menu.Item
            className='grid-config__menu--item grid-config__menu--more'
            onClick={() => setVisible(true)}
          >
            <Text>
              <DoubleRightOutlined style={{ transform: 'rotate(90deg)' }} />
            </Text>
          </Menu.Item>
        )}
      </SubMenu>
    </Menu>
  );
};

export default SavedConfigurations;
