import React from 'react';

import { Radio, Space, Row, Col, Typography, Popover } from 'antd';

import SelectedMemberList from './SelectedMemberList';

import './FormMove.less';

const { Text, Paragraph } = Typography;

const FormMove = (props) => {
  const { methodHook, selectedItems, items, handleSelectItem, tagRender } =
    props;

  const [moveMethod, setMoveMethod] = methodHook;

  const onChangeMoveMethod = (e) => {
    setMoveMethod && setMoveMethod(e.target.value);
  };

  return (
    <div className='reassign-asset-form-move'>
      <Row>
        <Col xs={24} style={{ height: 50 }}>
          <SelectedMemberList
            selectedItems={selectedItems}
            items={items}
            handleSelectItem={handleSelectItem}
            tagRender={tagRender}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Popover
            content={
              <Row style={{ height: 90 }}>
                <Typography>
                  <Paragraph style={{ margin: 0 }}>
                    Move: selected Asset(s) to new members, all associated data
                    in current member will be removed
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    Duplicate: create a copy version of selected asset(s) for
                    new member
                  </Paragraph>
                </Typography>
              </Row>
            }
            title='Hint'
          >
            <Text strong>Reassignment options</Text>
          </Popover>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Radio.Group onChange={onChangeMoveMethod} value={moveMethod}>
            <Space>
              <Radio value='move'>Move</Radio>
              <Radio value='makeCopy'>Duplicate</Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default FormMove;
