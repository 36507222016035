import { createSelector } from 'reselect';
import {
  initialStateMember,
  initialStateProfile,
  initialStateOverview,
  initialContactHistory,
  initialStateMemberColumnGridReducer,
  initialStateTagLineReducer,
  initialStatePreviewReducer,
  initialStateRoleSecurity,
} from './reducer';

const member = (state) => {
  return state.member || initialStateMember;
};

const profile = (state) => {
  if (state && state.member) {
    return state.member.profile || initialStateProfile;
  } else {
    return initialStateProfile;
  }
};

const contactHistory = (state) => {
  if (state && state.member) {
    return state.member.contactHistory || initialContactHistory;
  } else {
    return initialContactHistory;
  }
};

const overview = (state) => {
  if (state && state.member) {
    return state.member.overview || initialStateOverview;
  } else {
    return initialStateOverview;
  }
};

const gridColumn = (state) => {
  if (state && state.member) {
    return state.member.gridColumn || initialStateMemberColumnGridReducer;
  } else {
    return initialStateMemberColumnGridReducer;
  }
};

const tagLine = (state) => {
  if (state && state.member) {
    return state.member.tagLine || initialStatePreviewReducer;
  } else {
    return initialStatePreviewReducer;
  }
};

const preview = (state) => {
  if (state && state.member) {
    return state.member.preview || initialStateTagLineReducer;
  } else {
    return initialStateTagLineReducer;
  }
};

// Member Profile
const selectMemberProfile = () =>
  createSelector(profile, (memberState) => {
    return memberState.memberInfo;
  });

const selectMemberError = () =>
  createSelector(member, (memberState) => memberState.profile.error);

const selectMemberProfileLoading = () =>
  createSelector(profile, (memberState) => memberState.loading);

// Member Contact history
const selectMemberContactHistory = () =>
  createSelector(contactHistory, (memberState) => {
    return memberState.memberContactHistory;
  });

const selectMemberContactHistoryError = () =>
  createSelector(
    contactHistory,
    (memberState) => memberState.contactHistory.error
  );

const selectMemberContactHistoryLoading = () =>
  createSelector(
    contactHistory,
    (memberState) => memberState.contactHistory.loading
  );

const selectMemberContact = () => {
  return createSelector(overview, (memberState) => memberState.memberContact);
};

const selectBrands = () => {
  return createSelector(overview, (memberState) => memberState.brands);
};

const selectInformationSheet = () => {
  return createSelector(
    overview,
    (memberState) => memberState.informationSheet
  );
};

const selectBrand = () => {
  return createSelector(overview, (memberState) => memberState.brand);
};

const selectAsset = () => {
  return createSelector(overview, (memberState) => memberState.asset);
};

const selectDocument = () => {
  return createSelector(overview, (memberState) => memberState.document);
};

const selectDocumentDetail = () => {
  return createSelector(overview, (memberState) => memberState.documentDetail);
};

const selectLoadingDocumentGrid = () => {
  return createSelector(
    overview,
    (memberState) => memberState.loadingDocumentGrid
  );
};
const selectLoadingGetDocument = () => {
  return createSelector(
    overview,
    (memberState) => memberState.loadingGetDocument
  );
};
const selectLoadingPreview = () => {
  return createSelector(preview, (memberState) => memberState.loading);
};

const selectErrorPreview = () => {
  return createSelector(preview, (memberState) => memberState.error);
};

const selectShortDetailPreview = () => {
  return createSelector(
    preview,
    (memberState) => memberState.memberShortDetail
  );
};

const selectPublicContactColumn = () => {
  return createSelector(preview, (memberState) => memberState.publicContact);
};

const selectPublicContactList = () => {
  return createSelector(
    preview,
    (memberState) => memberState.publicContactList
  );
};

const selectInitialPublicContactList = () => {
  return createSelector(
    preview,
    (memberState) => memberState.initialPublicContact
  );
};

/**********************************************************************/
/************************ Grid Selectors *******************************/
/**********************************************************************/
const selectMemberContactColumns = () =>
  createSelector(gridColumn, (memColState) => memColState.memberContact);

const selectContactLocationColumns = () =>
  createSelector(gridColumn, (memColState) => memColState.contactLocation);

const selectMemberBillingColumns = () =>
  createSelector(gridColumn, (memColState) => memColState.memberBilling);

const selectMemberActivityLogColumns = () =>
  createSelector(gridColumn, (memColState) => memColState.activityLog);

const selectMemberContactHistoryColumns = () =>
  createSelector(gridColumn, (memColState) => memColState.contactHistory);

// tag line
const selectMemberIsTagLineUploadMediaOpen = () =>
  createSelector(
    tagLine,
    (tagLineState) => tagLineState.isUploadTagLineMediaOpen
  );

const selectMemberIsPreviewTagLineUploadMediaOpen = () =>
  createSelector(
    tagLine,
    (tagLineState) => tagLineState.isShowPreviewUploadTagLineMedia
  );

const selectMemberIsShowUploadTagLineMedia = () =>
  createSelector(
    tagLine,
    (tagLineState) => tagLineState.isShowUploadTagLineMedia
  );

export {
  selectMemberProfile,
  selectMemberError,
  selectMemberProfileLoading,
  selectMemberContact,
  selectBrands,
  selectBrand,
  selectDocument,
  selectInformationSheet,
  selectAsset,
  selectMemberContactHistory,
  selectMemberContactHistoryError,
  selectMemberContactHistoryLoading,
  selectMemberContactColumns,
  selectContactLocationColumns,
  selectMemberBillingColumns,
  selectMemberActivityLogColumns,
  selectMemberContactHistoryColumns,
  selectMemberIsTagLineUploadMediaOpen,
  selectMemberIsShowUploadTagLineMedia,
  selectErrorPreview,
  selectLoadingPreview,
  selectShortDetailPreview,
  selectPublicContactColumn,
  selectMemberIsPreviewTagLineUploadMediaOpen,
  selectPublicContactList,
  selectInitialPublicContactList,
  selectDocumentDetail,
  selectLoadingDocumentGrid,
  selectLoadingGetDocument,
};
