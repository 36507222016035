import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getAllTicketTags,
  getTicketingSystemShortDetail,
  updateLastTimeGetNewTicket,
} from 'services/ticketingSystem/index';
import { CustomNotification } from 'common/components';

export const KEY_TICKET_SYSTEM = {
  TICKET_TAG_LIST: ['ticket', 'get-all-ticket-tags'],
  TICKET_SHORT_DETAIL: ['ticket', 'get-ticket-short-detail'],
};

export const getKeyTicketShortDetail = (ticketId) => {
  return [...KEY_TICKET_SYSTEM.TICKET_SHORT_DETAIL, ticketId];
};

export const useGetTicketShortDetail = ({ ticketId, enabled }) => {
  const queryClient = useQueryClient();

  const queries = useQuery({
    enabled,
    queryKey: getKeyTicketShortDetail(ticketId),
    queryFn: async () => {
      const response = await getTicketingSystemShortDetail({ ticketId });
      if (response?.isSuccess) {
        return response?.data;
      } else {
        CustomNotification.error(response?.message || 'Ticket item not found');
        return;
      }
    },
  });

  const invalidateTicketShortDetail = () => {
    queryClient.invalidateQueries({
      queryKey: getKeyTicketShortDetail(ticketId),
    });
  };

  return {
    ticketDetail: queries?.data,
    invalidateTicketShortDetail,
    ...queries,
  };
};
export const useUpdateLastTimeGetNewTicket = () => {
  return useMutation({
    mutationFn: updateLastTimeGetNewTicket,
  });
};
