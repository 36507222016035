import React, { useState, useEffect, useMemo, useRef } from 'react';

import {
  Modal,
  Input,
  Radio,
  Row,
  Col,
  Skeleton,
  Typography,
  List,
  Button,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { Form } from 'common/components';

import { apiHandler } from 'utils/api';
import * as mappingService from 'services/mapping';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';
import { MAPPING_REQUIRED } from 'static/Constants';

const CloneMappingConfigModal = (props) => {
  const intl = useIntl();
  const inputNameRef = useRef(null);

  const reloadPage = useDispatchReloadPage();
  const [form] = Form.useForm();

  const {
    mappingConfigId,
    visible,
    setVisible,
    mappingConfig,
    mappingConfigStatus,
  } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [formItemFieldName, setFormItemFieldName] = useState({
    value: '',
    touched: false,
  });

  const checkFieldName = (fieldNameMapping) => {
    const regex = new RegExp(/^[A-Za-z0-9\-_]+$/i);
    const validString = regex.test(fieldNameMapping);

    return validString;
  };

  const invalidFieldName = useMemo(
    () => checkFieldName(formItemFieldName.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formItemFieldName.value]
  );

  const isShowValidateFieldName =
    formItemFieldName.value !== '' &&
    !invalidFieldName &&
    formItemFieldName.touched;

  const messagesError = [
    {
      title: 'Alphabet characters',
    },
    {
      title: 'Number characters',
    },
    {
      title: 'Underscore ( _ ) ',
    },
    {
      title: 'Dash ( - )',
    },
  ];

  const ValidateFieldNameMapping = () => {
    return (
      <Col>
        <Typography.Text strong style={{ color: '#ff4d4f' }}>
          Your field name should be allowed:
        </Typography.Text>
        <List
          className='mapping-config__list'
          itemLayout='horizontal'
          bordered={false}
          dataSource={messagesError}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<InfoCircleOutlined />}
                title={<Typography.Text>{item.title}</Typography.Text>}
              />
            </List.Item>
          )}
        />
      </Col>
    );
  };

  const onOk = () => {
    console.log('im here tho');
    callApiCloneReporting();
  };

  const onCancel = () => {
    setVisible(false);
  };

  const successCallback = () => {
    setVisible(false);
    reloadPage();
  };

  const onFinally = () => {
    setSubmitLoading(false);
  };

  const callApiCloneReporting = async () => {
    const params = await form.validateFields();

    setSubmitLoading(true);
    if (!params.targetField) return;

    params.mappingPropertyId = mappingConfigId;

    const successMessage = intl.formatMessage(
      Messages.mappingConfigCloneSuccess
    );
    const errorMessage = intl.formatMessage(Messages.mappingConfigCloneError);

    apiHandler({
      service: mappingService.cloneMappingConfig,
      params,
      successMessage,
      errorMessage,
      successCallback,
      onFinally,
    });
  };

  const resetForm = () => {
    if (!visible) form.resetFields();
  };

  const focusOnName = () => {
    if (visible)
      setTimeout(() => {
        const inputName = inputNameRef.current;

        if (!inputName) return;

        inputName.focus();
        inputName.select();
      }, 100);
  };

  useEffect(() => {
    resetForm();
    focusOnName();
  }, [visible]);

  return (
    <Modal
      title={intl.formatMessage(Messages.mappingCloneProperty)}
      layout='vertical'
      visible={visible}
      destroyOnClose
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ loading: submitLoading }}
      className='clone-mapping-config-modal'
      footer={[
        <Button onClick={onCancel}>Cancel</Button>,
        <Button
          shouldUpdate
          key='submit'
          type='primary'
          onClick={onOk}
          disabled={formItemFieldName.touched && !invalidFieldName}
        >
          OK
        </Button>,
      ]}
    >
      <Skeleton active={true} loading={mappingConfigStatus}>
        <Form form={form} layout='vertical' initialValues={mappingConfig}>
          <Form.Item
            label={intl.formatMessage(Messages.mappingCloneFieldName)}
            name='targetField'
            validateStatus={
              formItemFieldName.touched && !invalidFieldName
                ? 'error'
                : 'validating'
            }
            rules={[
              {
                required: true,
                message: 'Please input your field name',
              },
            ]}
            className='clone-mapping-config-modal__field-name'
          >
            <Input
              ref={inputNameRef}
              onChange={(event) =>
                setFormItemFieldName({
                  ...formItemFieldName,
                  value: event.target.value,
                  touched: true,
                })
              }
            />
          </Form.Item>
          {isShowValidateFieldName && <ValidateFieldNameMapping />}

          <Form.Item label='Description' name='description'>
            <Input.TextArea
              autoSize={{
                minRows: 3,
                maxRows: 6,
              }}
            />
          </Form.Item>

          <Form.Item name='required' label='Required'>
            <Radio.Group style={styles.radioGroup}>
              <Row>
                {MAPPING_REQUIRED.map((type) => (
                  <Col span={8} key={type.value}>
                    <Radio value={type.value}>{type.label}</Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Skeleton>
    </Modal>
  );
};

export default CloneMappingConfigModal;

const styles = {
  radioGroup: {
    width: '100%',
  },
};
