import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { List, Typography, Input } from 'antd';
import { dialogFunction, EmptyList } from 'common/components';

import * as roleActions from 'pages/role/controllers/actions';

import { sleep } from 'utils/delay';

import classnames from 'classnames';
import './styles.less';

const { Text } = Typography;

const MemberRoleList = (props) => {
  const {
    roleListData,
    selectedRole,
    isChanged,
    formRole,
    isEdit,
    isAddNew,
    refRoleList,
    toggleCreateNewMode,
  } = props;

  const refInput = useRef();
  const dispatch = useDispatch();

  const handleClickRoleItem = async (roleData) => {
    if (isEdit) {
      formRole.resetAll();
    }

    if (isAddNew) {
      toggleCreateNewMode && toggleCreateNewMode();
    }

    dispatch(roleActions.setSelectedMemberRole(roleData));
    await sleep(1000);
    refInput.current && refInput.current.focus();
  };

  const confirmClickMemberRoleItem = async (roleData) => {
    if (selectedRole?.roleId === roleData?.roleId) return;

    const waringText = isAddNew
      ? 'Do you want to cancel creating new role and start editing another role?'
      : 'Do you want to cancel your edit content and start editing another role?';

    isChanged || isAddNew
      ? dialogFunction({
          type: 'warn',
          content: waringText,
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: () => handleClickRoleItem(roleData),
        })
      : handleClickRoleItem(roleData);
  };

  return (
    <div
      ref={refRoleList}
      className='member-role-list-section scroller'
      style={{ height: '100%' }}
    >
      {roleListData && roleListData.length > 0 ? (
        <List
          itemLayout='horizontal'
          dataSource={roleListData || []}
          renderItem={(item) => {
            const isSelectedRole = item?.roleId === selectedRole?.roleId;
            const isNewRole = item?.role === 'new';

            const roleName =
              isSelectedRole && isEdit
                ? formRole?.values?.roleName
                : item?.roleName;

            return (
              <List.Item
                className={classnames('member-role-list-section__item', {
                  'member-role-list-section__item--selected': isSelectedRole,
                  'member-role-list-section__item--is-edit':
                    isSelectedRole && isEdit,
                  'member-role-list-section__item--new': isNewRole,
                })}
                onClick={() => confirmClickMemberRoleItem(item)}
                id={`#role-${item?.id || 'new'}`}
                key={item?.roleId}
              >
                <List.Item.Meta
                  avatar={
                    <div
                      className={classnames(
                        'member-role-list-section__item-node'
                      )}
                    />
                  }
                  title={
                    isSelectedRole && isEdit ? (
                      <div className='member-role-list-section__item-input-wrap'>
                        <Input
                          name='roleName'
                          className='member-role-list-section__item-input'
                          value={roleName}
                          onChange={formRole.onChange}
                          ref={refInput}
                          placeholder='Input member role name'
                        />
                        <Text type='danger'>
                          {formRole?.errors?.roleName?.[0]}
                        </Text>
                      </div>
                    ) : (
                      <Text ellipsis style={{ width: '100%' }}>
                        {roleName}
                      </Text>
                    )
                  }
                />
              </List.Item>
            );
          }}
        />
      ) : (
        <EmptyList />
      )}
    </div>
  );
};

export default MemberRoleList;
