import React from 'react';

import { Col, Row, Form, Input, Checkbox, Select } from 'antd';

import { LabelFormItem } from 'pages/qa-spec/components/qa-allergen/FormAllergen';

import {
  GroupElementWrapper as AllergenWrapper,
  HeaderGroupElement as AllergenHeader,
} from 'pages/product-full-view/shared/components';

import { WrapperSelect } from 'common/components';

import { useGetProductEnums } from 'hooks';
import { findProductEnums } from 'hooks/useProductEnums';

import sortByKey from 'utils/sortByKey';

import {
  checkDisabledFormField,
  checkRequiredFormField,
  getFormAllergenFields,
  resetFormAllergensAfterUncheckPresent,
  updateAllergenFormValues,
} from './utils';

import { MULTIPLE_PANEL_ALLERGENS } from './constants';

export const CreateFormAllergens = ({ formInstance }) => {
  const formFields = getFormAllergenFields();

  const { flatProductEnums } = useGetProductEnums();

  const handleFieldsChange = (changeFields) => {
    const changeField = changeFields[0];
    const { name, value } = changeField; // name = ['allergens', 0, 'isPresent']
    const field = name[name.length - 1]; // isPresent
    const index = name[1];

    if (field === 'isPresent' && value === false) {
      const newFormValues = resetFormAllergensAfterUncheckPresent({
        formValues: formInstance.getFieldsValue(),
        index,
      });
      formInstance.setFieldsValue(newFormValues);
    } else if (
      field === 'levelOfContainment' &&
      value?.toLowerCase() !== 'may contain'
    ) {
      const newFormValues = updateAllergenFormValues({
        formValues: formInstance.getFieldsValue(),
        index,
        fieldName: 'containmentSource',
        value: null,
      });
      formInstance.setFieldsValue(newFormValues);
    }

    return;
  };

  return (
    <Form
      form={formInstance}
      onFieldsChange={handleFieldsChange}
      scrollToFirstError
    >
      <Row gutter={[12, 24]}>
        {MULTIPLE_PANEL_ALLERGENS.map((allergen, index) => (
          <Col span={12} key={index}>
            <AllergenWrapper key={allergen} style={{ padding: '0 12px 8px' }}>
              <AllergenHeader
                header={`Allergen ${index + 1}`}
                style={{ marginTop: 0 }}
              />
              {formFields.map((field) => {
                const listName = field?.listName;
                const propertyEnums = findProductEnums(
                  flatProductEnums,
                  listName
                );
                return (
                  <FormItemType
                    key={field.fieldName}
                    {...field}
                    propertyEnums={propertyEnums}
                    indexField={['allergens', index]}
                  />
                );
              })}
            </AllergenWrapper>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export const FormItemContainer = ({
  required,
  name,
  displayName,
  children,
  ...rest
}) => {
  return (
    <Form.Item
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      label={<LabelFormItem label={displayName} />}
      style={{ marginBottom: 8 }}
      rules={[
        {
          required,
          message: 'Please input your value!',
        },
      ]}
      name={name}
      {...rest}
    >
      {children}
    </Form.Item>
  );
};

const FormItemType = (props) => {
  const {
    indexField,
    disabled,
    type,
    listName,
    displayName,
    required,
    fieldName,
    propertyEnums,
  } = props;

  const formInstance = Form.useFormInstance();
  const nameFormItem = indexField.concat(fieldName);

  const isPresent = Form.useWatch(indexField.concat('isPresent'), formInstance);
  const levelOfContainment = Form.useWatch(
    indexField.concat('levelOfContainment'),
    formInstance
  );

  if (listName) {
    const disabledSelectField = checkDisabledFormField({
      isPresent,
      levelOfContainment,
    });
    const requiredSelectField = checkRequiredFormField({
      isPresent,
      levelOfContainment,
    });

    return (
      <FormItemContainer
        displayName={displayName}
        required={requiredSelectField[fieldName]}
        name={nameFormItem}
      >
        <WrapperSelect
          showSearch
          listHeight={140}
          placeholder={`Please Select ${displayName}`}
          filterOption={(input, option) =>
            option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          disabled={disabledSelectField[fieldName]}
        >
          {sortByKey(propertyEnums, 'enumDisplayName')?.map(
            (property, index) => (
              <Select.Option
                value={property.enumDisplayName}
                key={property.enumDisplayName + index}
              >
                {property.enumDisplayName
                  ? property.enumDisplayName
                  : property.enumDescription}
              </Select.Option>
            )
          )}
        </WrapperSelect>
      </FormItemContainer>
    );
  }

  if (type === 'string') {
    return (
      <FormItemContainer
        displayName={displayName}
        required={required}
        name={nameFormItem}
        className={`multiple-panel__allergen-form--${displayName}`}
      >
        <Input disabled={disabled} />
      </FormItemContainer>
    );
  }

  if (type === 'boolean') {
    return (
      <FormItemContainer
        displayName={displayName}
        required={required}
        name={nameFormItem}
        valuePropName='checked'
      >
        <Checkbox />
      </FormItemContainer>
    );
  }

  return null;
};
