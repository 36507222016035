import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import { CloseSquareOutlined, FolderOpenOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/maintenance';

import * as globalActions from 'redux/global/actions';
import * as eulaMaintenanceActions from 'pages/eula-maintenance/controllers/actions';
import * as eulaMaintenanceSelectors from 'pages/eula-maintenance/controllers/selectors';

import ToggleUploadMediaBtn from '../../controls/maintenance/eula/ToggleUploadMediaBtn';

const ToggleEulaUploadMediaSection = () => {
  const dispatch = useDispatch();

  const isEulaUploadMediaOpen = useSelector(
    eulaMaintenanceSelectors.selectIsEulaUploadMediaOpen()
  );

  const mapStatusToIcon = () => {
    if (isEulaUploadMediaOpen) {
      return <CloseSquareOutlined />;
    } else {
      return <FolderOpenOutlined />;
    }
  };

  const mapStatusToText = () => {
    if (isEulaUploadMediaOpen) {
      return Messages.closeMedia;
    } else {
      return Messages.openMedia;
    }
  };

  const toggleIsEulaMediaOpen = () => {
    console.log('In');
    dispatch(
      eulaMaintenanceActions.toggleUploadEulaMedia(!isEulaUploadMediaOpen)
    );
  };

  return (
    <ContainerButton
      style={{ display: 'flex', maxWidth: 250, marginBottom: '10px' }}
    >
      <ItemButton span={12}>
        <ToggleUploadMediaBtn
          icon={mapStatusToIcon()}
          label={mapStatusToText()}
          clickActionCallback={() => {
            toggleIsEulaMediaOpen();
          }}
        />
      </ItemButton>
    </ContainerButton>
  );
};

export default ToggleEulaUploadMediaSection;
