import React, { useState } from 'react';

import { notification, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { FormButton } from 'common/components';

import Messages from 'i18n/messages/qa-spec';
import { useIntl } from 'react-intl';

import * as api from 'config/axios';
import * as qaSpecServices from 'services/qaSpec';

import './DownloadSpecButton.less';

const DownloadSpecButton = (props) => {
  const intl = useIntl();

  const { productId } = props;

  const [loading, setLoading, message] = useState(false);

  const downloadSpec = () => {
    setLoading(true);

    qaSpecServices
      .getDownloadQaSpecUrl({
        productId,
      })
      .then((response) => {
        const { isSuccess, data } = response;

        if (isSuccess && data?.fileUrl)
          api.sendDownload({ url: data?.fileUrl });
        else notifyError(message);
      })
      .catch(() => notifyError())
      .finally(() => {
        setLoading(false);
      });
  };

  const notifyError = (message) => {
    notification.error({
      message: message || intl.formatMessage(Messages.downloadQaSpecError),
    });
  };

  return (
    <Tooltip title={intl.formatMessage(Messages.downloadQaSpecBtn)}>
      <FormButton
        className='qa-spec__btn-download-spec'
        text={intl.formatMessage(Messages.downloadQaSpecBtn)}
        icon={<DownloadOutlined />}
        onClick={downloadSpec}
        loading={loading}
      ></FormButton>
    </Tooltip>
  );
};
export default DownloadSpecButton;
