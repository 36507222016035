import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Menu,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { useState } from 'react';
import { ADD_QUERY } from '../utils/constans';
import { checkNestedContainer } from '../utils';

let searchTimeout;

const RenderTitleQueryCondition = ({
  property,
  viewMode,
  handleConjunction,
}) => {
  const [value, setValue] = useState(property?.operation === 'AND');

  const debounceUpdateValue = (checked) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleConjunction(property?.valuePath, checked);
    }, 300);
  };

  const handleUpdateValue = (checked) => {
    setValue(checked);
    debounceUpdateValue(checked);
  };

  return (
    <Col
      style={{
        display: 'flex',
        alignItems: 'center',
        minHeight: 24,
        backgroundColor: '#ffa39e',
        borderRadius: 4,
        padding: '0 8px',
      }}
    >
      <Switch
        disabled={Boolean(viewMode)}
        size='small'
        checkedChildren={
          <Typography.Text className='query-condition__switch-button-active'>
            AND
          </Typography.Text>
        }
        unCheckedChildren={
          <Typography.Text className='query-condition__switch-button-inactivate'>
            OR
          </Typography.Text>
        }
        checked={value}
        onClick={(checked) => {
          handleUpdateValue(checked);
        }}
        style={{
          width: 44,
          color: value ? '#fff' : 'rgba(0, 0, 0, 0.65)',
          background: value ? '#2684ff' : '#f5f5f5',
          display: 'flex',
          flexShrink: '0',
        }}
      />
      <span
        style={{
          marginLeft: 5,
          fontWeight: 500,
          fontSize: 14,
          display: 'flex',
          width: '100%',
        }}
      >
        <span>Group Query Conditions</span>
      </span>
    </Col>
  );
};

export default RenderTitleQueryCondition;

export const AddQueryButton = ({ addQueryCondition, property, isRoot }) => {
  const { valuePath } = property;

  const addQuery = (type) => {
    const path = valuePath?.join('.');

    addQueryCondition({
      path,
      type,
    });
  };

  const menu = (
    <Menu>
      <Menu.Item
        key={ADD_QUERY.FILTER}
        onClick={() => {
          addQuery(ADD_QUERY.FILTER);
        }}
      >
        Add filter
      </Menu.Item>
      <Menu.Item
        key={ADD_QUERY.GROUP}
        onClick={() => {
          addQuery(ADD_QUERY.GROUP);
        }}
      >
        Add group
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      trigger='click'
      overlay={menu}
      overlayClassName='query-condition__add-button'
      getPopupContainer={() => document.getElementById('query-conditions')}
    >
      {isRoot ? (
        <Button
          ghost
          type='primary'
          shape='round'
          icon={<PlusCircleOutlined />}
        >
          Add Query Condition
        </Button>
      ) : (
        <Button type='text' icon={<PlusCircleOutlined />} />
      )}
    </Dropdown>
  );
};

export const RenderStrictMode = ({ queryConditions, handleQueryCondition }) => {
  const isShowStrictMode = checkNestedContainer(queryConditions?.children);

  const onChange = (e) => {
    const value = e.target.checked;
    handleQueryCondition(queryConditions?.valuePath ?? [], value, 'strictMode');
  };

  return (
    <>
      {isShowStrictMode && (
        <Tooltip title='When the nested mode is enabled, only matching record data with the same parent entity will be considered.'>
          <Checkbox
            style={{ flex: 1, justifyContent: 'end' }}
            onChange={(e) => onChange(e)}
            checked={queryConditions?.strictMode}
          >
            Nested Mode
          </Checkbox>
        </Tooltip>
      )}
    </>
  );
};
