import React from 'react';
import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import './RenderAdvancedFilter.less';
import {
  updateQueryAdvance,
  updateQueryConditions,
} from 'utils/queryCondition';

const { Title } = Typography;

const RenderAdvancedFilter = ({ openAdvancedFilterPanel, onClosePanel }) => {
  const pathName = window.location.pathname;

  const dispatch = useDispatch();

  const handleRemoveAllQuery = () => {
    onClosePanel();
    updateQueryConditions(dispatch, [], pathName);
    updateQueryAdvance(dispatch, [], pathName);
    dispatch(actionsGridView.updateIsOpenAdvanceSearch(false));
    dispatch(actionsGridView.myQueryDoubleClick(true));
    dispatch(actionsGridView.checkQueryCondition(true));
  };

  return (
    <Row
      className='search-categories'
      style={{
        display: 'flex',
        alignItems: 'baseline',
        marginLeft: 10,
        height: 28,
        marginBottom: 8,
      }}
    >
      <Title level={5} style={{ marginBottom: 0 }}>
        Filters:
      </Title>

      <Tag
        style={{ margin: 2, cursor: 'pointer' }}
        color='green'
        className='search-tag'
      >
        <Tooltip
          placement='topLeft'
          title='Click to open detailed filter'
          arrowPointAtCenter
        >
          <span style={{ height: '100%' }} onClick={openAdvancedFilterPanel}>
            Show Advanced Query
          </span>
        </Tooltip>
        <Button className='close-button' onClick={handleRemoveAllQuery}>
          <CloseOutlined />
        </Button>
      </Tag>
    </Row>
  );
};

export default RenderAdvancedFilter;
