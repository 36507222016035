import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useCheckPermissions } from 'hooks';
import { PERMISSION_VIEW_QA_SPECIFICATION } from 'static/Permission';
import { defaultType } from '../constant';

import * as qaSpecServices from 'services/qaSpec';

export const getQaSpecHeaderKey = (id) => {
  return ['qa-spec', Number(id), 'qa-spec-data-header'];
};

export const useGetQaSpecDataHeader = ({ id, isEnabled }) => {
  const queryClient = useQueryClient();

  const hasPermissionViewQaSpec = useCheckPermissions(
    PERMISSION_VIEW_QA_SPECIFICATION
  );

  const { data, isLoading } = useQuery({
    queryKey: getQaSpecHeaderKey(id),
    queryFn: async () => {
      const response = await qaSpecServices.getQaSpecDataHeader({
        productId: Number(id),
      });
      return response?.data;
    },
    enabled: isEnabled && hasPermissionViewQaSpec,
    //* staleTime: 0 make this api duplicated in product detail view, Tin said staleTime 0 for fixing bug but he don't know what bug
    // staleTime: 0,
  });

  const refetchQaSpecDataHeader = () => {
    queryClient.invalidateQueries({
      queryKey: getQaSpecHeaderKey(id),
    });
  };

  return {
    qaSpecDataHeader: data ?? {},
    loading: isLoading,
    refetchQaSpecDataHeader,
    defaultType,
  };
};
