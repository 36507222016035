import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/mapping';
import { FormOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import { SaveMappingConfig } from 'pages/mapping/components';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const EditMapping = ({ isDisabled }) => {
  const selectedMapping = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const [visible, setVisible] = useState(false);

  const handleEditMapping = () => {
    setVisible(true);
  };

  return (
    <>
      <RibbonButton
        size='small'
        icon={<FormOutlined />}
        label={Messages.editMapping}
        onClick={handleEditMapping}
        disabled={selectedMapping?.length !== 1 || isDisabled}
      />
      <SaveMappingConfig
        visible={visible}
        setVisible={setVisible}
        selectedMapping={selectedMapping}
        mode='edit'
      />
    </>
  );
};

export default EditMapping;
