import React from 'react';

import { UserDeleteOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/maintenance';

const DeleteUserControl = (props) => {
  return (
    <RibbonButton
      size='small'
      icon={<UserDeleteOutlined />}
      label={Messages.deleteUser}
      {...props}
    />
  );
};

export default DeleteUserControl;
