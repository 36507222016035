import React from 'react';
import { Row } from 'antd';

import { useParams } from 'react-router-dom';

import QaSpecProcessStage from './QaSpecProcessStage';
import QaSpecDocument from './QaSpecDocument';
import QaSpecManufacturingLocation from './QaSpecManufacturingLocation';

import { useTabExpand } from 'hooks/useTabExpandProduct';

import { useManufacturingStageData } from './hooks';

const QaSpecManufacturingProcess = ({ productEnums, ownerId }) => {
  const { tableStyle } = useTabExpand();

  const { id: productId } = useParams();

  const manufacturingStageQuery = useManufacturingStageData(productId);

  return (
    <Row style={{ padding: '0 6px' }}>
      <QaSpecManufacturingLocation
        tableStyle={tableStyle}
        productEnums={productEnums}
        productId={productId}
        ownerId={ownerId}
      />
      <QaSpecProcessStage
        tableStyle={tableStyle}
        productEnums={productEnums}
        manufacturingStageQuery={manufacturingStageQuery}
        productId={productId}
      />
      <QaSpecDocument tableStyle={tableStyle} />
    </Row>
  );
};

export default QaSpecManufacturingProcess;
