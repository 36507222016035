import React, { useMemo, useState } from 'react';

import classnames from 'classnames';

import {
  Row,
  Col,
  Typography,
  Input,
  Avatar,
  Select,
  Tooltip,
  Button,
} from 'antd';

import { WrapperSelect } from 'common/components';

import {
  FieldStringOutlined,
  CalendarOutlined,
  CheckOutlined,
  NumberOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
  ProfileOutlined,
  FileImageOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';

import {
  CollapseView,
  Loading,
} from 'pages/product-full-view/components/product-detail-view/ShareComponents';

import { PACKAGE_LEVEL, NUMERIC_TYPE } from 'static/Constants';

import {
  formatMappingSourceProperties,
  searchModuleProperties,
  findProperty,
  checkDisplayAddButton,
} from './utils';

import { useGetMappingSourceProperties, useDeepCompareEffect } from './hooks';

import './index.less';

const { Title, Text } = Typography;
const { Option } = Select;

export const SourceField = (props) => {
  const {
    className,
    handleGetSourceField,
    children,
    valueFilter = 'all',
    handleResetNameRenderSourceField,
    nameRenderSourceField,
  } = props;

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [foundModulesName, setFoundModulesName] = useState([]);

  const { mappingProperties, status } = useGetMappingSourceProperties();

  const modulesNameMapping = useMemo(
    () => formatMappingSourceProperties(mappingProperties, valueFilter),
    [mappingProperties, valueFilter]
  );

  useDeepCompareEffect(() => {
    setFoundModulesName(modulesNameMapping);
  }, [modulesNameMapping, valueFilter]);

  useDeepCompareEffect(() => {
    handleGetSourceField && handleGetSourceField(selectedProperty);
  }, [handleGetSourceField, selectedProperty]);

  const handleAddProperty = (property) => {
    const { fieldName: propertyName, moduleName } = property;

    const foundProperty = findProperty(
      modulesNameMapping,
      propertyName,
      moduleName
    );

    setSelectedProperty(foundProperty);

    //* Close source field after select & reset filter source field
    handleResetNameRenderSourceField &&
      setTimeout(() => {
        handleResetNameRenderSourceField();
      }, 100);
  };

  const handleSearchProperties = (searchText) => {
    if (!searchText) {
      setFoundModulesName(modulesNameMapping);
    } else {
      const result = searchModuleProperties(modulesNameMapping, searchText);
      setFoundModulesName(result);
    }
  };

  const getSourceFieldDisplayName = (name) => {
    if (name === 'compareFieldPath') return 'Compare Field';
    if (name === 'compareToFieldPath') return 'Field';
    if (name === 'compareToOtherFieldPath') return 'Other Field';
    if (name && name?.indexOf('parameterSourceField') !== -1)
      return 'Values - Other Field';
    return 'Source Field';
  };

  return (
    <Row className={className}>
      <ModuleProperties>
        <PropertiesTitle>
          {getSourceFieldDisplayName(nameRenderSourceField)}
        </PropertiesTitle>
        {children}
        <ModulePropertiesSearch
          handleSearchProperties={handleSearchProperties}
        />
        <ModulePropertiesList
          status={status}
          modulesNameMapping={foundModulesName}
          selectedProperty={selectedProperty}
          handleAddProperty={handleAddProperty}
        />
      </ModuleProperties>
    </Row>
  );
};

const dataTypes = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'string',
    label: 'Text',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'datetime',
    label: 'Date',
  },
  {
    value: 'boolean',
    label: 'Boolean',
  },
];

export const PropertiesFilterType = ({
  valueFilter,
  handleFilterProperties,
  compareType,
  isSetParameter,
  compareFieldPath,
}) => {
  return (
    <Row>
      <WrapperSelect
        value={valueFilter}
        onChange={handleFilterProperties}
        style={{ width: '100%', marginRight: 8 }}
      >
        {dataTypes.map((type) => (
          <Option
            key={type.value}
            value={type.value}
            disabled={
              compareFieldPath
                ? false
                : isSetParameter && valueFilter === 'all'
                ? false
                : compareType === 'OtherField' && valueFilter !== type.value
                ? valueFilter !== type.value
                : isSetParameter && valueFilter !== type.value
                ? valueFilter !== type.value
                : false
            }
          >
            {type.label}
          </Option>
        ))}
      </WrapperSelect>
    </Row>
  );
};

export const SourceFieldComplexType = ({
  className,
  packageLevel,
  valueFilter,
  handleSelectPackageLevel,
  handleGetSourceField,
  handleFilterProperties,
  keyName,
  compareType,
  handleResetNameRenderSourceField,
  nameRenderSourceField,
  isSetParameter,
  compareFieldPath,
}) => {
  return (
    <SourceField
      className={className}
      handleGetSourceField={handleGetSourceField}
      valueFilter={valueFilter}
      keyName={keyName}
      handleResetNameRenderSourceField={handleResetNameRenderSourceField}
      nameRenderSourceField={nameRenderSourceField}
      handleFilterProperties={handleFilterProperties}
    >
      <Row style={{ backgroundColor: ' #f2f2f2' }}>
        <Col span={12}>
          <ModulePackageLevel
            packageLevel={packageLevel}
            handleSelectPackageLevel={handleSelectPackageLevel}
          />
        </Col>
        <Col span={12}>
          <PropertiesFilterType
            valueFilter={valueFilter}
            handleFilterProperties={handleFilterProperties}
            compareType={compareType}
            isSetParameter={isSetParameter}
            compareFieldPath={compareFieldPath}
          />
        </Col>
      </Row>
    </SourceField>
  );
};

const ModuleProperties = ({ children }) => {
  return (
    <Col span={24} className='module-properties'>
      {children}
    </Col>
  );
};

const PropertiesTitle = ({ children }) => {
  return (
    <Row className='module-properties-header'>
      <Title level={5}>{children}</Title>
    </Row>
  );
};

const ModulePropertiesSearch = ({ handleSearchProperties }) => {
  return (
    <Row className='module-properties-search'>
      <Input.Search
        allowClear
        size='middle'
        placeholder='Input search properties'
        onSearch={(searchText) => handleSearchProperties(searchText)}
      />
    </Row>
  );
};

export const ModulePackageLevel = React.memo(
  ({ packageLevel, handleSelectPackageLevel }) => {
    return (
      <Row className='module-package-level'>
        <WrapperSelect
          placeholder='Select package level'
          style={{ width: '100%' }}
          size='medium'
          value={packageLevel}
          onChange={handleSelectPackageLevel}
        >
          {PACKAGE_LEVEL.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </WrapperSelect>
      </Row>
    );
  }
);

export const ModulePropertiesList = (props) => {
  const { modulesNameMapping, status, selectedProperty, handleAddProperty } =
    props;

  const isLoading = status === 'idle' || status === 'pending';

  return (
    <Row className='module-properties-list scroller'>
      {isLoading ? (
        <Loading style={{ height: '55vh', width: '100%' }} />
      ) : (
        <Col span={24}>
          {modulesNameMapping.map((module) => (
            <CollapseView
              className='module-properties-list__collapse'
              collapsible='header'
              headerPanel={
                <Typography.Title level={5}>
                  {module.moduleDisplayName}
                </Typography.Title>
              }
              keyPanel={module.moduleName}
              expandIconPosition='left'
              key={module.moduleName}
              destroyInactivePanel
            >
              <ModulePropertiesItem
                properties={module.moduleProperties}
                selectedProperty={selectedProperty}
                handleAddProperty={handleAddProperty}
              />
            </CollapseView>
          ))}
        </Col>
      )}
    </Row>
  );
};

export const ModulePropertiesItem = React.memo(
  ({ properties, handleAddProperty, selectedProperty }) => {
    return (
      <>
        {properties.map((property) => (
          <Row
            key={property.fieldName}
            gutter={4}
            align='middle'
            className={classnames({
              'module-properties-item': true,
            })}
          >
            <Col style={{ padding: 0 }}>
              <Avatar
                shape='square'
                className={classnames({
                  'module-properties-item__icon': true,
                })}
                icon={<AvatarPropertiesItem propertyType={property.dataType} />}
              />
            </Col>
            <Col span={20} md={18}>
              <Text
                className={classnames({
                  'module-properties-item__name': true,
                })}
                strong
                ellipsis
              >
                {property.displayName}
              </Text>
            </Col>

            <Col span={2} md={2}>
              {checkDisplayAddButton(selectedProperty, property) ? (
                <Tooltip title='Add field'>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    style={{ borderRadius: 4 }}
                    onClick={() => handleAddProperty(property)}
                  />
                </Tooltip>
              ) : (
                <CheckCircleTwoTone
                  twoToneColor='#73d13d'
                  className='module-properties-item__added-icon'
                />
              )}
            </Col>
          </Row>
        ))}
      </>
    );
  }
);

export const AvatarPropertiesItem = ({ propertyType }) => {
  let lowercaseDataType = propertyType?.toLowerCase();

  if (NUMERIC_TYPE.includes(lowercaseDataType)) {
    return <NumberOutlined />;
  }

  if (lowercaseDataType === 'string') {
    return <FieldStringOutlined />;
  }

  if (lowercaseDataType === 'datetime') {
    return <CalendarOutlined />;
  }
  if (lowercaseDataType === 'boolean') {
    return <CheckOutlined />;
  }

  if(lowercaseDataType === 'image') {
    return <FileImageOutlined />
  }
  
  if(lowercaseDataType === 'icons') {
    return <EllipsisOutlined />
  }

  return <ProfileOutlined />;
};
