import * as types from './constants';

export const getFolderGridColumns = (gridName) => ({
  type: types.GET_FOLDER_GRID_COLUMN,
  gridName,
});

export const getFolderGridColumnsSuccess = (columns) => {
  return {
    type: types.GET_FOLDER_GRID_COLUMN_SUCCESS,
    columns,
  };
};

export const getFolderGridColumnsError = (error) => ({
  type: types.GET_FOLDER_GRID_COLUMN_ERROR,
  error,
});

export function getFolderList(
  folderOption,
  pageNumber,
  pageSize,
  search,
  isFavoriteRoute,
  advancedSearchContainer
) {
  return {
    type: types.GET_FOLDER_GRID,
    folderOption,
    pageIndex: pageNumber,
    pageSize,
    search,
    isFavoriteRoute,
    advancedSearchContainer,
  };
}

export const getFolderListByLevel = (params) => {
  return {
    type: types.GET_FOLDER_GRID_BY_LEVEL,
    ...params,
  };
};

export const getFolderListByLevelSuccess = ({
  data,
  folderOption,
  ...rest
}) => {
  return {
    type: types.GET_FOLDER_GRID_BY_LEVEL_SUCCESS,
    data,
    folderOption,
    ...rest,
  };
};

export const updateFolderBreadcrumb = (breadcrumb) => {
  return {
    type: types.UPDATE_FOLDER_BREADCRUMB,
    breadcrumb,
  };
};

export const keepBreadcrumb = (payload) => {
  return {
    type: types.KEEP_BREADCRUMB,
    payload,
  };
};

export const updateShouldKeepBreadcrumb = (payload) => {
  return {
    type: types.UPDATE_SHOULD_KEEP_BREADCRUMB,
    payload,
  };
};

export const getFolderListByLevelError = (error) => {
  return {
    type: types.GET_FOLDER_GRID_BY_LEVEL_ERROR,
    error,
  };
};

export function getFolderListSuccess(folders, total) {
  return {
    type: types.GET_FOLDER_GRID_SUCCESS,
    folders,
    total,
  };
}

export function getFolderListError(error) {
  return {
    type: types.GET_FOLDER_GRID_ERROR,
    error,
  };
}

export const turnOnModalCreate = () => {
  return { type: types.TURN_ON_MODAL_CREATE };
};

export const turnOffModalCreate = () => {
  return { type: types.TURN_OFF_MODAL_CREATE };
};

export const turnOnModalCopy = () => {
  return { type: types.TURN_ON_MODAL_COPY };
};

export const turnOffModalCopy = () => {
  return { type: types.TURN_OFF_MODAL_COPY };
};

export const deleteFolders = (ids) => {
  return { type: types.DELETE_FOLDERS, payload: ids };
};

export const deleteFoldersSuccess = () => {
  return { type: types.DELETE_FOLDERS_SUCCESS };
};

export const deleteFoldersFail = (error) => {
  return { type: types.DELETE_FOLDERS_FAIL, error };
};

export const copyFolder = (params) => {
  return { type: types.COPY_FOLDER, payload: params };
};

export const copyFolderSuccess = () => {
  return { type: types.COPY_FOLDER_SUCCESS };
};

export const copyFolderFail = (error) => {
  return { type: types.COPY_FOLDER_FAIL, error };
};

export function getFolderShortDetail(id) {
  return {
    type: types.GET_FOLDER_SHORT_DETAIL,
    id,
  };
}

export function getFolderShortDetailSuccess(data) {
  return {
    type: types.GET_FOLDER_SHORT_DETAIL_SUCCESS,
    data,
  };
}

export function getFolderShortDetailError(error) {
  return {
    type: types.GET_FOLDER_SHORT_DETAIL_ERROR,
    error,
  };
}

export function getDigitalAssetPreview(id) {
  return {
    type: types.GET_DIGITAL_ASSET_PREVIEW,
    id,
  };
}

export function getDigitalAssetPreviewSuccess(data) {
  return {
    type: types.GET_DIGITAL_ASSET_PREVIEW_SUCCESS,
    data,
  };
}

export function getDigitalAssetPreviewError(error) {
  return {
    type: types.GET_DIGITAL_ASSET_PREVIEW_ERROR,
    error,
  };
}

export function getMemberPreview(id) {
  return {
    type: types.GET_MEMBER_PREVIEW,
    id,
  };
}

export function getMemberPreviewSuccess(data) {
  return {
    type: types.GET_MEMBER_PREVIEW_SUCCESS,
    data,
  };
}

export function getMemberPreviewError(error) {
  return {
    type: types.GET_MEMBER_PREVIEW_ERROR,
    error,
  };
}

// GET DOWNLOAD SETTING
export function getDownloadSetting(id) {
  return {
    type: types.GET_DOWNLOAD_SETTING,
    id,
  };
}

export function getDownloadSettingSuccess(data) {
  return {
    type: types.GET_DOWNLOAD_SETTING_SUCCESS,
    data,
  };
}

export function getDownloadSettingError(error) {
  return {
    type: types.GET_DOWNLOAD_SETTING_ERROR,
    error,
  };
}

// edit folder short content
export const turnEditShortContentOn = (value) => {
  return {
    type: types.TURN_EDIT_SHORT_CONTENT_ON,
    value,
  };
};

export const editShortContent = (params) => {
  return {
    type: types.EDIT_FOLDER_SHORT_CONTENT,
    params,
  };
};

export const editShortContentSuccess = () => ({
  type: types.EDIT_FOLDER_SHORT_CONTENT_SUCCESS,
});

export const editShortContentError = (error) => ({
  type: types.EDIT_FOLDER_SHORT_CONTENT_ERROR,
  error,
});

export const setReloadContentPane = (value) => ({
  type: types.SET_RELOAD_FOLDER_CONTENT_PANE,
  value,
});

export const editShortContentFinish = () => ({
  type: types.EDIT_FOLDER_SHORT_CONTENT_FINISH,
});

export const setReloadFolderPage = () => ({
  type: types.SET_RELOAD_FOLDER_PAGE,
});

export const configDownloadSchedule = (params) => {
  return {
    type: types.CONFIG_SCHEDULE_DOWNLOAD,
    params,
  };
};

export const configDownloadScheduleSuccess = () => ({
  type: types.CONFIG_SCHEDULE_DOWNLOAD_SUCCESS,
});

export const configDownloadScheduleError = (error) => ({
  type: types.CONFIG_SCHEDULE_DOWNLOAD_ERROR,
  error,
});

// Reset Folders Paging
export const resetFolderPaging = () => ({
  type: types.RESET_FOLDER_PAGING,
});
// Reset MyFolders Paging
export const resetOwnedFolderPaging = () => ({
  type: types.RESET_OWNED_FOLDER_PAGING,
});
// Reset FoldersSharedToMe Paging
export const resetSharedFolderPaging = () => ({
  type: types.RESET_SHARED_FOLDER_PAGING,
});

// Get MyFolders
export function getOwnedFolderList(
  folderOption,
  pageNumber,
  pageSize,
  search,
  advancedSearchContainer
) {
  return {
    type: types.GET_OWNED_FOLDER_GRID,
    folderOption,
    pageIndex: pageNumber,
    pageSize,
    search,
    advancedSearchContainer,
  };
}
export function getOwnedFolderListSuccess(folders, total) {
  return {
    type: types.GET_OWNED_FOLDER_GRID_SUCCESS,
    folders,
    total,
  };
}
export function getOwnedFolderListError(error) {
  return {
    type: types.GET_OWNED_FOLDER_GRID_ERROR,
    error,
  };
}

// Get FoldersSharedToMe
export function getSharedFolderList(
  folderOption,
  pageNumber,
  pageSize,
  search,
  advancedSearchContainer
) {
  return {
    type: types.GET_SHARED_FOLDER_GRID,
    folderOption,
    pageIndex: pageNumber,
    pageSize,
    search,
    advancedSearchContainer,
  };
}
export function getSharedFolderListSuccess(folders, total) {
  return {
    type: types.GET_SHARED_FOLDER_GRID_SUCCESS,
    folders,
    total,
  };
}
export function getSharedFolderListError(error) {
  return {
    type: types.GET_SHARED_FOLDER_GRID_ERROR,
    error,
  };
}

export function getPublicContact(memberId) {
  return {
    type: types.GET_PUBLIC_CONTACT_FOLDER,
    memberId,
  };
}

export function getPublicContactSuccess(data) {
  return {
    type: types.GET_PUBLIC_CONTACT_FOLDER_SUCCESS,
    data,
  };
}

export function getPublicContactError(error) {
  return {
    type: types.GET_PUBLIC_CONTACT_FOLDER_ERROR,
    error,
  };
}

// Folder details
export function getColumnsFolderDetails(gridName) {
  return {
    type: types.GET_COLUMNS_FOLDER_DETAILS,
    gridName,
  };
}

export function getColumnsFolderDetailsSuccess(columns) {
  return {
    type: types.GET_COLUMNS_FOLDER_DETAILS_SUCCESS,
    columns,
  };
}

export function getColumnsFolderDetailsError(error) {
  return {
    type: types.GET_COLUMNS_FOLDER_DETAILS_ERROR,
    error,
  };
}

export function getFolderDetailsGridThumb(params) {
  return {
    type: types.GET_FOLDER_DETAILS_GRID_THUMB,
    params,
  };
}

export function getFolderDetailsGridThumbSuccess(data) {
  return {
    type: types.GET_FOLDER_DETAILS_GRID_THUMB_SUCCESS,
    data,
  };
}

export function getQueryPreview(id) {
  return {
    type: types.GET_QUERY_PREVIEW,
    id,
  };
}

export function getQueryPreviewSuccess(data) {
  return {
    type: types.GET_QUERY_PREVIEW_SUCCESS,
    data,
  };
}

export function getFolderDetailsGridThumbError(error) {
  return {
    type: types.GET_COLUMNS_FOLDER_DETAILS_ERROR,
  };
}

export function getQueryPreviewError(error) {
  return {
    type: types.GET_QUERY_PREVIEW_ERROR,
    error,
  };
}

export function getReportingPreview(id) {
  return {
    type: types.GET_REPORTING_PREVIEW,
    id,
  };
}

export function getReportingPreviewSuccess(data) {
  return {
    type: types.GET_REPORTING_PREVIEW_SUCCESS,
    data,
  };
}

export function getReportingPreviewError(error) {
  return {
    type: types.GET_REPORTING_PREVIEW_ERROR,
    error,
  };
}

export function getProductPreview(id) {
  return {
    type: types.GET_PRODUCT_PREVIEW,
    id,
  };
}

export function getProductPreviewSuccess(data) {
  return {
    type: types.GET_PRODUCT_PREVIEW_SUCCESS,
    data,
  };
}

export function getProductPreviewError(error) {
  return {
    type: types.GET_PRODUCT_PREVIEW_ERROR,
    error,
  };
}

export function saveSelectedMapping(data) {
  return {
    type: types.SAVE_SELECTED_MAPPING,
    data,
  };
}

export function saveStatusDownloadFolder(data) {
  return {
    type: types.SAVE_STATUS_DOWNLOAD_FOLDER,
    data,
  };
}

export function updateFolderDetailData(data) {
  return {
    type: types.UPDATE_FOLDER_DETAIL_DATA,
    data,
  };
}
