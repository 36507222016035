import { useDispatch } from 'react-redux';
import { CustomNotification } from 'common/components';

import { loggingWithOtp, loggingWithUnlockAccount } from 'redux/user/actions';

import { useRefetchUserProfile } from 'pages/user-profile/components/hook/useFetchUserProfile';

import { mapOtp } from '../utils/utils';
import { sleep } from 'utils/delay';
import { getParameterFromQuery } from 'utils/common/route';
import { generateMfaHash, storeMfaHash } from 'utils/mfa';

import { LOGIN_SESSION_EXP_MESSAGE_FROM_BE } from '../constants';

import {
  useVerifyOtpForLogin,
  useVerifyOtpForNewMfaPhoneUserProfile,
  useVerifyOtpForUnlockAccount,
} from '../queries';

export const useOtpCheck = ({
  setOtpCheckError,
  onClose,
  changeMfaPhoneSession,
  otp,
  setOtp,
  setIsSessionExpired,
  loginSession,
  mfaSession,
  dontChallengeMe,
  mfaLoginInfo,
  handleFocusOtpInput,
}) => {
  const returnUrl = getParameterFromQuery('returnUrl') || '/';
  const { fetch: fetchUserProfile } = useRefetchUserProfile();

  const verifyOtpForLogin = useVerifyOtpForLogin();
  const verifyOtpForNewMfaPhoneUserProfile =
    useVerifyOtpForNewMfaPhoneUserProfile();
  const verifyOtpForUnlockAccount = useVerifyOtpForUnlockAccount();

  const { isLoading: loadingVerifyOtpForLogin } = verifyOtpForLogin;
  const { isLoading: loadingVerifyOtpForNewMfaPhoneUserProfile } =
    verifyOtpForNewMfaPhoneUserProfile;
  const { isLoading: loadingVerifyOtpForUnlockAccount } =
    verifyOtpForUnlockAccount;

  const isLoading =
    loadingVerifyOtpForLogin ||
    loadingVerifyOtpForNewMfaPhoneUserProfile ||
    loadingVerifyOtpForUnlockAccount;

  const showError = (errorMessage) => {
    if (typeof errorMessage === 'string') {
      setOtpCheckError(errorMessage);
    }
    if (errorMessage === LOGIN_SESSION_EXP_MESSAGE_FROM_BE) {
      setIsSessionExpired && setIsSessionExpired(true);
    }
  };

  const dispatch = useDispatch();

  const handleLoggingWithOtp = (data) => {
    dispatch(loggingWithOtp(data));
  };

  const handleLoggingWithUnlockAccount = (data) => {
    dispatch(loggingWithUnlockAccount(data));
  };

  const handleError = (error) => {
    setOtp([]);
    showError(error);
    handleFocusOtpInput(0);
  };

  const onVerifyOtpForNewMfaPhoneUserProfile = () => {
    setOtpCheckError('');

    const params = {
      verificationCode: mapOtp(otp),
      mfaSession: changeMfaPhoneSession?.mfaSession,
    };

    verifyOtpForNewMfaPhoneUserProfile
      .mutateAsync(params)
      .then(async () => {
        CustomNotification.success('Save MFA phone number successfully');
        onClose && onClose();
        await sleep(1500);
        fetchUserProfile();
      })
      .catch((e) => handleError(e));
  };

  const onVerifyOtpForLoginAllowAddMfaPhone = (_otp) => {
    setOtpCheckError('');

    const hashToken = dontChallengeMe
      ? generateMfaHash(mfaLoginInfo?.userName)
      : undefined;

    const params = {
      loginSession,
      verificationCode: _otp,
      browserHash: hashToken,
    };

    verifyOtpForLogin
      .mutateAsync(params)
      .then(async (data) => {
        const res = { data };

        //* store otp hash
        if (hashToken)
          storeMfaHash({
            hashToken: hashToken,
            userName: mfaLoginInfo?.userName,
          });

        handleLoggingWithOtp({ response: res, returnUrl });
      })
      .catch((e) => handleError(e));
  };

  const onVerifyOtpForLogin = (_otp) => {
    setOtpCheckError('');

    const hashToken = dontChallengeMe
      ? generateMfaHash(mfaLoginInfo?.userName)
      : undefined;

    const params = {
      loginSession,
      verificationCode: _otp,
      rememberMe: dontChallengeMe,
      browserHash: hashToken,
    };

    verifyOtpForLogin
      .mutateAsync(params)
      .then(async (data) => {
        const res = { data };
        //* store otp hash
        if (hashToken)
          storeMfaHash({
            hashToken: hashToken,
            userName: mfaLoginInfo?.userName,
          });

        handleLoggingWithOtp({ response: res, returnUrl });
      })
      .catch((e) => handleError(e));
  };

  const onVerifyOtpForUnlockAccount = (_otp) => {
    setOtpCheckError('');

    const params = {
      mfaSession: mfaSession?.mfaSession,
      verificationCode: _otp,
    };

    verifyOtpForUnlockAccount
      .mutateAsync(params)
      .then(async (data) => {
        const res = { data };
        handleLoggingWithUnlockAccount({ response: res, returnUrl });
      })
      .catch((e) => handleError(e));
  };

  return {
    onVerifyOtpForNewMfaPhoneUserProfile,
    onVerifyOtpForLoginAllowAddMfaPhone,
    onVerifyOtpForLogin,
    onVerifyOtpForUnlockAccount,
    isLoading,
  };
};
