import * as api from 'config/axios';
import * as endpoints from './endpoints';

//* Role and Security
export const getRoleList = (params) => {
  return api.sendGet(endpoints.GET_ROLES_ENDPOINT, { params: params });
};

export const getAvailablePermissions = () => {
  return api.sendGet(endpoints.GET_AVAILABLE_PERMISSIONS_ENDPOINT);
};

export const getRolePermissions = (params) => {
  return api.sendGet(endpoints.GET_ROLE_PERMISSIONS_ENDPOINT, {
    params: params,
  });
};

export const addUsersToRole = (params) => {
  return api.sendPost(endpoints.ADD_USERS_TO_ROLE, params);
};

export const editRole = (params) => {
  return api.sendPost(endpoints.EDIT_ROLE, params);
};

export const removeUsersFromRole = (params) => {
  return api.sendPost(endpoints.REMOVE_USERS_FROM_ROLE, params);
};

export const deleteRole = (params) => {
  return api.sendPost(endpoints.DELETE_ROLE, params);
};

export const getUserRoles = (params) => {
  return api.sendGet(endpoints.GET_USER_ROLES, { params });
};

export const saveUserRoles = (params) => {
  return api.sendPost(endpoints.SAVE_USER_ROLES, params);
};

export const getWorkflowRoles = (params) => {
  return api.sendPost(endpoints.GET_WORKFLOW_ROLES, params);
};

export const saveWorkflowRoles = (params) => {
  return api.sendPost(endpoints.SAVE_WORKFLOW_ROLES, params);
};

export const removeWorkflowRoles = (params) => {
  return api.sendPost(endpoints.REMOVE_WORKFLOW_ROLES, params);
};

export const getMemberRoleList = () => {
  return api.sendGet(endpoints.GET_MEMBER_ROLES);
};

export const getAllMemberPermissionList = () => {
  return api.sendGet(endpoints.GET_ALL_MEMBER_PERMISSION_LIST);
};

export const saveMemberRole = (params) => {
  return api.sendPost(endpoints.SAVE_MEMBER_ROLE, params);
};

export const deleteMemberRole = (params) => {
  return api.sendPost(endpoints.DELETE_MEMBER_ROLE, params);
};

export const removeMembersFromRole = (params) => {
  return api.sendPost(endpoints.REMOVE_MEMBERS_FROM_ROLE, params);
};

export const addMembersToRole = (params) => {
  return api.sendPost(endpoints.ADD_MEMBERS_TO_ROLE, params);
};

export const addRoleToUser = (params) => {
  return api.sendPost(endpoints.ADD_ROLES_TO_USER, params);
};
export const removeRolesFromUser = (params) => {
  return api.sendPost(endpoints.REMOVE_ROLES_FROM_USER, params);
};
