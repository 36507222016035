import React, { useState } from 'react';
import { Checkbox, Modal, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/asset-full-view';
import './EmailSelection.less';

const CheckboxGroup = Checkbox.Group;

const EmailSelection = (props) => {
  const {
    visible,
    onSendEmail,
    onClose,
    primaryEmail,
    alternativeEmail,
  } = props;
  const [checkedEmails, setCheckedList] = useState([]);
  const [checkedPrimary, setCheckedPrimary] = useState(true);

  const onChange = (list) => {
    setCheckedList(list);
  };

  const onFinishModal = () => {
    let emails;
    if (checkedPrimary) {
      emails = [primaryEmail, ...checkedEmails];
    } else {
      emails = checkedEmails;
    }
    onSendEmail(emails);
    onResetModal();
  };

  const onCloseModal = () => {
    onResetModal();
    onClose();
  };

  const onResetModal = () => {
    setCheckedList([]);
    setCheckedPrimary(true);
  };

  return (
    <Modal
      title={<FormattedMessage {...Messages.emailSelection} />}
      visible={visible}
      onOk={onFinishModal}
      onCancel={onCloseModal}
      centered
      okText='Send'
      className='email-selection'
    >
      <Checkbox
        className='email-selection__primary-wrap'
        checked={checkedPrimary}
        onChange={(e) => setCheckedPrimary(e?.target.checked)}
      >
        {primaryEmail}{' '}
        <span className='email-selection__primary'>(Primary Email)</span>
      </Checkbox>
      <Divider dashed={true} style={{ margin: '8px 0px' }} />
      <CheckboxGroup
        options={alternativeEmail}
        value={checkedEmails}
        onChange={onChange}
      />
    </Modal>
  );
};

export default EmailSelection;
