import * as actions from './constants';

export function updateModeView(modeView) {
  return {
    type: actions.UPDATE_MODE_VIEW,
    modeView,
  };
}

export function selectResult(payload) {
  return {
    type: actions.SELECT_RESULT,
    payload,
  };
}

export function updateSetting(payload) {
  return {
    type: actions.UPDATE_SETTING,
    payload,
  };
}

export function keepSetting(payload) {
  return {
    type: actions.KEEP_SETTING,
    payload,
  };
}

export function updateResultGridPagination(payload) {
  return {
    type: actions.UPDATE_RESULT_GRID_PAGINATION,
    payload,
  };
}

export function keepResultGridPagination(payload) {
  return {
    type: actions.KEEP_RESULT_GRID_PAGINATION,
    payload,
  };
}
