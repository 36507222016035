import { useQuery } from '@tanstack/react-query';

import * as productServices from 'services/product';

export const useQueryProductGrid = (props) => {
  const { params, enabled = false, ...restProps } = props;

  const getProductGrid = async ({ queryKey }) => {
    const response = await productServices.getProductListService(queryKey[1]);

    const { isSuccess, data } = response;

    return isSuccess && data ? data : [];
  };

  const query = useQuery({
    queryKey: ['product-grid', params],
    queryFn: getProductGrid,
    enabled,
    ...restProps,
  });

  return query;
};
