import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { Images } from 'config/assets';

const ProductLogo = (props) => {
  const { dataDetail } = props;

  return (
    <Avatar
      shape='square'
      className='product-info__logo-image'
      src={(dataDetail && dataDetail.imageUrl) || Images.RIVIR_LOGO_DEFAULT}
      alt={dataDetail && dataDetail.productName}
    />
  );
};

ProductLogo.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductLogo;
