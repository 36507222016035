import history from 'utils/common/history';
import * as actions from 'redux/global/actions';
import _ from 'lodash';

import { TYPE_VIEW_ASSET, ROUTE } from 'static/Constants';

/**
 * forward user to location
 * @param {string} path route location
 */
export const forwardTo = (path) => {
  history.push(path);

  //! REMOVE it after demo
  const $mainApplication = document.querySelectorAll('.main__application')[0];
  if (!!$mainApplication) $mainApplication.scrollTop = 0;
};

export const forwardToAndReplace = (path) => {
  history.replace(path);
};

export const currentHistory = history;

export const getParameterFromQuery = (name, location) => {
  if (!location) location = history.location;
  name = name.replace(/[[\]]/g, '\\$&');
  // let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  let regex = new RegExp('[?&]' + name + '=(([^&#].*$))'),
    results = regex.exec(location.search);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getQueryParamsObject = (location) => {
  const params = {};
  const queryString = location.hash.substring(1);
  const regex = /([^&=]+)=([^&]*)/g;
  let match;

  while ((match = regex.exec(queryString))) {
    params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
  }

  return params;
};

export const getParamsFromUrl = (location) => {
  const params = {};
  const queryString = location.search.substring(1);
  const regex = /([^&=]+)=([^&]*)/g;
  let match;

  while ((match = regex.exec(queryString))) {
    params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
  }

  return params;
};

export const getParamsObj = (queryString) => {
  const params = Object.fromEntries(new URLSearchParams(queryString));
  return params;
};

// post login process flow
const postFlow = [
  {
    type: 'waiting',
  },
  {
    type: 'eula-for-mfa',
    route: '/eula',
    action: actions.validateEula,
    isForwardToAndReplace: true,
  },
  {
    type: 'login-otp',
    route: '/login-otp',
    isForwardToAndReplace: true,
  },
  {
    type: 'eula',
    route: '/eula',
    action: actions.validateEula,
    isForwardToAndReplace: true,
  },
  // {
  //   type: 'credit-card-eula',
  //   route: '/cc-eula',
  //   action: actions.validateEula,
  // },
  {
    type: 'get-basic-info',
    route: '/get-basic-info',
    isForwardToAndReplace: true,
  },

  {
    type: 'change-password-unlock',
    route: '/change-password',
    isForwardToAndReplace: true,
  },
  {
    type: 'personalization',
    route: '/personalization',
    action: actions.validatePersonalization,
    isForwardToAndReplace: true,
  },
  {
    type: 'notification',
    route: '/notification',
    action: actions.validateCriticalMessage,
    isForwardToAndReplace: true,
  },
  {
    type: 'prerouting',
    route: '/pre-routing',
    isForwardToAndReplace: true,
  },
  {
    type: 'landingpage',
    route: '/landingPage',
    action: actions.validateLandingPage,
    isForwardToAndReplace: true,
  },
];

export const postFlowRedirect = (type, returnUrl, dispatch, getVerifiedUrl) => {
  let flowIndex = 0,
    flow;

  if (!returnUrl) {
    returnUrl = '/';
  }
  if (type) {
    flow = _.find(postFlow, { type: type });
    flowIndex = _.indexOf(postFlow, flow);
  }
  if (type !== 'waiting') {
    let { action } = postFlow?.[flowIndex] || {};
    // update flow
    if (dispatch && action) {
      dispatch(action());
    }
  }

  // get next flow
  if (flowIndex < postFlow.length - 1) {
    flowIndex = flowIndex + 1;
    // get next route
    const flowItem = postFlow[flowIndex];
    const { route: nextRoute, isForwardToAndReplace } = flowItem || {};

    // move to next
    const nextUrl = `${nextRoute}?returnUrl=${returnUrl}`;
    if (isForwardToAndReplace) {
      forwardToAndReplace(nextUrl);
    } else {
      forwardTo(nextUrl);
    }
  } else {
    returnUrl =
      getVerifiedUrl && typeof getVerifiedUrl === 'function'
        ? getVerifiedUrl(returnUrl, { isLogin: true })
        : returnUrl;

    forwardToAndReplace(`${returnUrl}`);
  }
};

const pathnameAssets = [
  '/digital-media/assets',
  '/digital-media/documents',
  '/digital-media/multi-media',
  '/asset/create',
  '/favorite/favorite-assets',
];

export const navigateToRouteAsset = ({
  typeViewAsset,
  id,
  // isMultipleImport = false,
}) => {
  const pathname = window.location.pathname;

  const isFavoriteRoute = pathname === '/favorite/favorite-assets';

  const isAssetView =
    pathnameAssets.includes(pathname) ||
    isFavoriteRoute ||
    pathname === `/asset/${id}`;

  if (!isAssetView) return;

  if (typeViewAsset === TYPE_VIEW_ASSET.REPLACE_ASSET) {
    if (isFavoriteRoute) {
      forwardTo(`/asset/${id}?replace-asset&isFavorited=true`);
    } else {
      forwardTo(`/asset/${id}?replace-asset`);
    }
  } else if (typeViewAsset === TYPE_VIEW_ASSET.CREATE_ASSET) {
    forwardTo(`/asset/${id}`);
  } else {
    forwardTo(ROUTE.DIGITAL_MEDIA);
  }

  // if (isMultipleImport) {
  //   forwardTo(ROUTE.DIGITAL_MEDIA);
  // } else {
  //   if (typeViewAsset === TYPE_VIEW_ASSET.CREATE_ASSET) {
  //     forwardTo(`/asset/${id}`);
  //   } else if (typeViewAsset === TYPE_VIEW_ASSET.REPLACE_ASSET) {
  //     if (isFavoriteRoute) {
  //       forwardTo(`/asset/${id}?replace-asset&isFavorited=true`);
  //     } else {
  //       forwardTo(`/asset/${id}?replace-asset`);
  //     }
  //   } else {
  //     return;
  //   }
  // }
};
