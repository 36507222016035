import React from 'react';
import { Checkbox, Row, Col, Popover, Typography } from 'antd';

export const MOVE_OPTIONS = [
  { label: 'Products', value: 'Product' },
  { label: 'Users', value: 'User' },
  { label: 'Assets', value: 'Asset' },
  { label: 'Addresses', value: 'Address' },
  { label: 'GLNs', value: 'GLN' },
  { label: 'Shared Entities', value: 'MoveSharedEntities' },
];

const MoveAllOptionsCheckboxs = (props) => {
  const { onChangeMoveOptions } = props;

  return (
    <div style={{ padding: 10 }}>
      <Checkbox.Group onChange={onChangeMoveOptions}>
        <Row>
          <Col span={8}>
            <Hint
              hintText={
                'This option moves all products owned by the source company, excluding deleted products, to the target member. The Move Products option will include assets that belong to the moving products.'
              }
            >
              <Checkbox value='Product'>Products</Checkbox>
            </Hint>
          </Col>
          <Col span={8}>
            <Hint
              hintText={
                'This option moves all users associated with the source company, excluding deleted items, to the target member.'
              }
            >
              <Checkbox value='User'>Users</Checkbox>
            </Hint>
          </Col>
          <Col span={8}>
            <Hint
              hintText={
                'This option moves all assets owned by the source company, excluding deleted assets, to the target member. The Move Assets option without checked Products will remove the associated link between the Product and the moving assets.'
              }
            >
              <Checkbox value='Asset'>Assets</Checkbox>
            </Hint>
          </Col>
          <Col span={8}>
            <Hint
              hintText={
                'This option moves all addresses associated with the source company, excluding deleted items, to the target member.'
              }
            >
              <Checkbox value='Address'>Addresses</Checkbox>
            </Hint>
          </Col>
          <Col span={8}>
            <Hint
              hintText={
                'This option moves all GLNs associated with the source company to the target member.'
              }
            >
              <Checkbox value='GLN'>GLNs</Checkbox>
            </Hint>
          </Col>
          <Col span={8}>
            <Hint
              hintText={
                'This option moves all shared entities, including shared products, assets, folders, and reports, to the target member.'
              }
            >
              <Checkbox value='MoveSharedEntities'>Shared Entities</Checkbox>
            </Hint>
          </Col>
        </Row>
      </Checkbox.Group>
    </div>
  );
};

const Hint = ({ children, hintText }) => {
  return (
    <Popover
      content={hintText}
      title='Hint'
      overlayStyle={{ maxWidth: 500 }}
      mouseEnterDelay={0.75}
      mouseLeaveDelay={0}
    >
      {children}
    </Popover>
  );
};

export default MoveAllOptionsCheckboxs;
