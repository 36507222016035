import React from 'react';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import OpenMapping from 'pages/home/ribbon/components/controls/mapping/OpenMapping';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const OpenSection = (props) => {
  const { selectedItemList, disabled } = props;

  return (
    <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
      <OpenMapping disabled={disabled} />
    </Can>
  );
};

export default OpenSection;
