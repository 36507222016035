import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Space, Typography } from 'antd';
import classnames from 'classnames';

import { useCheckPermissionOR } from 'hooks/useCheckPermissions';
import { SECURE_CONFIG } from 'utils/SecureRoute';

import './EmailHeader.less';

function EmailFilter(props) {
  const checkPermission = useCheckPermissionOR();
  const { onChangeEmailFilter, showFilter } = props;

  const [filter, setFilter] = useState(['Personal', 'Company']);

  const canChatPersonal = checkPermission(SECURE_CONFIG.CHAT_PERSONAL.PASS);
  const canChatCompany = checkPermission(SECURE_CONFIG.CHAT_COMPANY.PASS);

  const handleClickFilter = (filterType) => {
    if (!filterType) return;
    const searchIdx = filter.findIndex(
      (filterName) => filterName === filterType
    );
    let temp = [...filter];

    if (searchIdx === -1) {
      temp = [...temp, filterType];
      setFilter((prevFilter) => [...prevFilter, filterType]);
      onChangeEmailFilter(temp);
      return;
    }

    temp.splice(searchIdx, 1);
    onChangeEmailFilter(temp);
    setFilter((prevFilter) => {
      const nextFilter = [...prevFilter];
      nextFilter.splice(searchIdx, 1);
      return nextFilter;
    });
  };

  const isActive = (type) => {
    return filter.find((item) => item === type) ? 'primary' : '';
  };

  return canChatCompany && canChatPersonal ? (
    <Row
      className={classnames({
        'email-filter': true,
        'email-filter--show': showFilter,
      })}
    >
      <Col xs={24}>
        <Space>
          <Button
            className={`email-filter__btn ${
              isActive('Personal') ? 'active' : ''
            }`}
            type='primary'
            onClick={() => handleClickFilter('Personal')}
            shape='round'
            danger={isActive('Personal')}
          >
            Personal
          </Button>
          <Button
            className={`email-filter__btn ${
              isActive('Company') ? 'active' : ''
            }`}
            type='primary'
            onClick={() => handleClickFilter('Company')}
            shape='round'
            danger={isActive('Company')}
          >
            Company
          </Button>
        </Space>
      </Col>
    </Row>
  ) : null;
}

export default EmailFilter;
