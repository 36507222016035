import { useEffect, useReducer } from 'react';

import { useAsync } from 'hooks/useAsync';
import { useGetMemberId } from 'hooks/useGetMemberId';
import { actionConfig, configReducer, initState } from './supplierReducer';

import { getSupplierDetail, getAllSuppliers } from 'services/supplier-manager';

export const useSupplierManager = () => {
  const [state, dispatchSupplier] = useReducer(configReducer, initState);

  const { memberId } = useGetMemberId();

  const handleUpdateSupplierPermission = (permissionList) => {
    dispatchSupplier({
      type: actionConfig.UPDATE_SUPPLIER_PERMISSIONS,
      payload: permissionList,
    });
  };

  const handleUpdateSelectedItems = (selectedItem) => {
    const selectedSupplier =
      selectedItem?.id !== state?.selectedSupplier?.id ? selectedItem : {};

    dispatchSupplier({
      type: actionConfig.UPDATE_SELECTED_ITEMS,
      payload: selectedSupplier,
    });
  };

  const handleUpdateSelectedLevel = (item) => {
    let selectedItems = [];
    const selectedProducts = state.selectedEntityLevel.map((item) => item.id);
    const haveSelectedProduct = selectedProducts.includes(item.id);

    if (haveSelectedProduct) {
      selectedItems = state.selectedEntityLevel.filter(
        (property) => property?.id !== item?.id
      );
    } else {
      selectedItems = [...state.selectedEntityLevel, item];
    }

    dispatchSupplier({
      type: actionConfig.UPDATE_SELECTED_PRODUCT_FROM_SELECTED_PROUCT_GRID,
      payload: selectedItems,
    });
  };

  const handleAddEntityData = (data, entity = 'product') => {
    const initData = state.productData;

    updateEntityLevel(entity, [...initData, ...data]);
  };

  const handleRemoveEntityData = (removeCase, entity) => {
    const { productData, selectedEntityLevel } = state;

    const selectedProductId = selectedEntityLevel.map((item) => item.id);

    const removeSelectedItems = productData.filter(
      (item) => !selectedProductId.includes(item.id)
    );

    const entityData = removeCase === 'all' ? [] : removeSelectedItems;

    dispatchSupplier({
      type: actionConfig.UPDATE_SELECTED_PRODUCT_FROM_SELECTED_PROUCT_GRID,
      payload: [],
    });
    updateEntityLevel(entity, entityData);
  };

  const updateEntityLevel = (entity = 'product', data) => {
    dispatchSupplier({
      type: actionConfig.UPDATE_PRODUCT_DATA,
      payload: data,
    });
  };

  const handleUpdateSupplierData = (brokerDetail) => {
    const { permissions, selectedEntities } = brokerDetail;

    handleUpdateSupplierPermission(permissions);

    handleAddEntityData(selectedEntities ?? []);
  };

  const handleResetDataProduct = (data) => {
    updateEntityLevel('product', data);
  };

  return {
    state,
    memberId,
    handleAddEntityData,
    handleUpdateSupplierData,
    handleRemoveEntityData,
    handleUpdateSelectedLevel,
    handleUpdateSelectedItems,
    handleUpdateSupplierPermission,
    handleResetDataProduct,
  };
};

export const useGetSupplierGrid = ({ isPIMAction }) => {
  const { data, status, run } = useAsync();

  const { memberId } = useGetMemberId();

  const fetchSupplierList = ({ pageIndex, searchText, filters }) => {
    const params = {
      memberId: memberId,
      pageIndex: pageIndex,
      pageSize: 20,
      search: { searchText: searchText },
      filters,
      isExcludedAssignedSupplier: !isPIMAction,
    };

    run(getAllSuppliers(params));
  };

  useEffect(() => {
    //* init fetch suppliers
    fetchSupplierList({ pageIndex: 1, searchText: '', filters: [] });
  }, []);

  return {
    supplierList: data?.gridData ?? [],
    paging: data?.paging,
    loading: status === 'pending',
    showDefaultText: status === 'idle',
    fetchSupplierList,
  };
};

export const useGetSupplierDetail = (id, isEdit) => {
  const { data, status, run } = useAsync();

  useEffect(() => {
    if (isEdit) run(getSupplierDetail({ id }));
  }, [id, isEdit]);

  return {
    supplierDetail: data ?? [],
    loading: status === 'idle' || status === 'pending',
  };
};
