import React from 'react';

import { Link } from 'react-router-dom';

const CellRenderLink = (params) => {
  const { colDef, data, value } = params;

  const href = createHref(colDef.linkTo, data);

  return (
    <Link className='ag-grid__link-to' to={href}>
      {value}
    </Link>
  );
};

const createHref = (linkTo, data) => {
  if (!linkTo) return linkTo;

  let href = linkTo;
  const regex = new RegExp(/(?:\{)([^{}]+)(?:\})/gs); // match every character in {}

  const params = linkTo.match(regex);
  params?.length &&
    params.forEach((match) => {
      let removeCurly = match.replace(/[{}]/g, '');
      href = href.replace(match, data?.[removeCurly]);
    });

  return href;
};

export default CellRenderLink;
