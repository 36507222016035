/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useRef } from 'react';
import videoThumbnail from 'assets/asset-icons/video-thumbnail.png';

const MdVideo = (props) => {
  const { src, width, mapToPaddingStyle, alignConfig, spaceConfig } = props;

  const [ready, setReady] = useState(false);

  const refVideo = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (refVideo?.current) {
        setReady(true);
      }
    }, 1300);
  }, []);

  if (!ready) {
    return (
      <div
        ref={refVideo}
        style={{
          float: alignConfig,
          width: `${width}%`,
          height: '100%',
          maxWidth: '100%',
          // border: 'solid 1px rgba(0, 0, 0, 0.35)',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          transition: 'all 0.2s',
          ...mapToPaddingStyle(alignConfig, spaceConfig),
        }}
      >
        <img
          src={videoThumbnail}
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
          }}
          alt='loading-poster'
        />
      </div>
    );
  } else {
    return (
      <span ref={refVideo}>
        <video
          width='100%'
          style={{
            outline: 'none',
            controls: 'true',
            width: `${width}%`,
            height: '100%',
            maxWidth: '100%',
            float: alignConfig,
            transition: 'all 0.2s',
            ...mapToPaddingStyle(alignConfig, spaceConfig),
          }}
          preload='none'
          controls={true}
          poster={videoThumbnail}
          disablePictureInPicture
          controlsList='nodownload'
        >
          <source src={src} type='video/mp4' />
        </video>
      </span>
    );
  }
};

export default MdVideo;
