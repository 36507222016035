import { Typography, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ShowMoreOrLess = (props) => {
  const {
    fullList,
    showList,
    style = {},
    setShowList,
    maxShowLess = 6,
  } = props;

  const type =
    fullList?.length === showList?.length ? 'showing full' : 'showing less';

  const content = type === 'showing full' ? 'View less' : 'View more';
  const icon =
    type === 'showing full' ? <CaretUpOutlined /> : <CaretDownOutlined />;

  const onClickShowMoreOrLessButton = () => {
    if (type === 'showing full') {
      setShowList(fullList.slice(0, maxShowLess));
      return;
    }

    if (type === 'showing less') {
      setShowList(fullList);
      return;
    }
  };

  const {
    text: textStyle = {},
    wrapper: wrapperStyle = {},
    button: buttonStyle = {},
  } = style;

  if (!fullList || fullList?.length <= maxShowLess) return null;

  return (
    <span onClick={onClickShowMoreOrLessButton} style={wrapperStyle}>
      <Button
        shape='circle'
        icon={icon}
        style={{
          marginRight: 4,
          width: 22,
          height: 22,
          minWidth: 'unset',
          ...buttonStyle,
        }}
      />
      <Text style={{ cursor: 'pointer', ...textStyle }} strong>
        {content}
      </Text>
    </span>
  );
};

export default ShowMoreOrLess;
