import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getMemberMaintenanceService = (params) => {
  return api.sendPost(endpoints.GET_MEMBER_MAINTENANCE, params);
};

export const getUserInfoGridService = (params) => {
  return api.sendPost(endpoints.GET_USER_INFO_GRID, params);
};

export const getAllCommunicationTemplatesService = () => {
  return api.sendGet(endpoints.GET_ALL_COMMUNICATION_TEMPLATES);
};

export const getMemberMaintenanceShortDetailService = (params) => {
  return api.sendGet(endpoints.GET_MEMBER_MAINTENANCE_SHORT_DETAIL, {
    params: params,
  });
};

export const getPublicContactService = (params) => {
  return api.sendPost(endpoints.GET_MEMBER_MAINTENANCE_PUBLIC_CONTACT, params);
};

export const editMemberManagement = (params) => {
  return api.sendPost(endpoints.EDIT_MEMBER_MANAGEMENT, params);
};

//* execute communication template
export const getPreviewTemplateExecution = (params) => {
  return api.sendPost(endpoints.GET_PREVIEW_TEMPLATE_FOR_EXECUTION, params);
};
export const executeCommunicationTemplate = (params) => {
  return api.sendPost(endpoints.EXECUTE_COMMUNICATION_TEMPLATE, params);
};

export const getCreateMemberTemplate = async () => {
  return await api.sendGet(endpoints.GET_TEMPLATE_CREATE_MEMBER);
};

export const getEditMemberTemplate = async () => {
  return await api.sendGet(endpoints.GET_TEMPLATE_EDIT_MEMBER);
};

export const getCreateUserTemplate = async () => {
  return await api.sendGet(endpoints.GET_TEMPLATE_CREATE_USER);
};

export const getEditUserTemplate = async (params) => {
  return await api.sendGet(endpoints.GET_TEMPLATE_EDIT_USER);
};

export const addNewUser = (params) => {
  return api.sendPost(endpoints.ADD_NEW_USER, params);
};

export const deleteUsers = (params) => {
  return api.sendPost(endpoints.DELETE_USERS, params);
};

export const getDetailUser = (params) => {
  return api.sendGet(`${endpoints.GET_USER_DETAIL}?Id=${params}`);
};

export const editUser = (params) => {
  return api.sendPost(endpoints.EDIT_USER, params);
};

export const resetUserPassword = (params) => {
  return api.sendPost(endpoints.RESET_USER_PASSWORD, params);
};

export const executeBulkMemberTemplate = (params) => {
  return api.sendPost(endpoints.MEMBER_MAINTAIN_EXECUTE_BULK_TEMPLATE, params);
};

export const moveGLNtoMember = (params) =>
  api.sendPost(endpoints.MOVE_GLN_TO_MEMBER, params);

export const getMemberSecurityAccess = (params) => {
  return api.sendGet(endpoints.GET_MEMBER_SECURITY_ACCESS, { params });
};

export const moveUsertoMember = (params) =>
  api.sendPost(endpoints.MOVE_USER_TO_MEMBER, params);

export const createSupplier = (params) => {
  return api.sendPost(endpoints.CREATE_SUPPLIER, params);
};

export const getMemberSupplier = () => {
  return api.sendGet(endpoints.GET_SUPPLIER_FOR_PRODUCT_CREATION);
};

export const unlockUsers = (params) => {
  return api.sendPost(endpoints.UNLOCK_USERS, params);
};
export const moveAllToMember = (params) => {
  return api.sendPost(endpoints.MOVE_ALL_TO_MEMBER, params);
};
