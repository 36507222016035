import React, { useState } from 'react';

import classnames from 'classnames';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

import { ReactComponent as IconActiveRequest } from 'assets/security-request/active-request.svg';
import { ReactComponent as IconCreatedByMeRequest } from 'assets/security-request/created-by-me.svg';
import { ReactComponent as IconAssignToRequest } from 'assets/security-request/assign-to.svg';

import selectorUser from 'redux/user/selectors';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import { RibbonButton, RibbonSection } from 'common/components';
import { updateParamsGridView } from 'common/components/grid-view/controllers/actions';

import './FilterRequest.less';

const FilterRequest = () => {
  const dispatch = useDispatch();

  const [{ active, createdByMe, assignedToMe }, setFilters] = useState({
    active: false,
    createdByMe: false,
    assignedToMe: false,
  });

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const filters =
    useSelector(gridViewSelectors.makeSelectParamsGridView())?.filters ?? [];

  const updateNewParamsGridView = (filters) => {
    dispatch(updateParamsGridView(filters));
  };

  const handleFilterActiveRequest = () => {
    if (active) {
      setFilters((prevState) => ({
        ...prevState,
        active: false,
      }));

      const newFilters = filters.filter((f) => f.fieldName !== 'status');
      updateNewParamsGridView(newFilters);
    } else {
      setFilters((prevState) => ({
        ...prevState,
        active: true,
      }));

      const filter = [
        {
          fieldName: 'status',
          filterType: 'In',
          values: ['New', 'Developing', 'Review'],
        },
      ];
      updateNewParamsGridView(filters.concat(filter));
    }
  };

  const handleFilterCreatedByMe = () => {
    if (createdByMe) {
      setFilters((prevState) => ({
        ...prevState,
        createdByMe: false,
      }));

      const newFilters = filters.filter((f) => f.fieldName !== 'createdBy');
      updateNewParamsGridView(newFilters);
    } else {
      setFilters((prevState) => ({
        ...prevState,
        createdByMe: true,
      }));

      const filter = [
        {
          fieldName: 'createdBy',
          filterType: 'Equal',
          value: userInfo?.id,
        },
      ];
      updateNewParamsGridView(filters.concat(filter));
    }
  };

  const handleFilterAssignedToMe = () => {
    if (assignedToMe) {
      setFilters((prevState) => ({
        ...prevState,
        assignedToMe: false,
      }));
      const newFilters = filters.filter((f) => f.fieldName !== 'assignedTo');
      updateNewParamsGridView(newFilters);
    } else {
      setFilters((prevState) => ({
        ...prevState,
        assignedToMe: true,
      }));

      const filter = [
        {
          fieldName: 'assignedTo',
          filterType: 'Equal',
          value: userInfo?.id,
        },
      ];
      updateNewParamsGridView(filters.concat(filter));
    }
  };

  return (
    <RibbonSection>
      <RibbonButton
        icon={<IconActiveRequest />}
        label='Active Request'
        className={classnames({
          'button-item-dropdown': true,
          'button-unfocused': active === false,
        })}
        toggle={active}
        onClick={handleFilterActiveRequest}
      />
      <RibbonButton
        icon={<IconCreatedByMeRequest />}
        label='Created by me'
        className={classnames({
          'button-item-dropdown': true,
          'button-unfocused': createdByMe === false,
        })}
        toggle={createdByMe}
        onClick={handleFilterCreatedByMe}
      />
      <RibbonButton
        icon={<IconAssignToRequest />}
        label='Assigned to me'
        className={classnames({
          'button-item-dropdown': true,
          'button-unfocused': assignedToMe === false,
        })}
        toggle={assignedToMe}
        onClick={handleFilterAssignedToMe}
      />
    </RibbonSection>
  );
};

export default FilterRequest;
