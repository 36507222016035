import React from 'react';

import { FolderAddOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';

import { forwardTo } from 'utils/common/route';

import { ROUTE } from 'static/Constants';

const CreateTransitionPageButton = () => {
  const handleGoToCreateNewTransitionPage = () => {
    forwardTo(ROUTE.TRANSITION_PAGE_MANAGE + '/new');
  };

  return (
    <RibbonButton
      icon={<FolderAddOutlined />}
      label='Create Transition Page'
      onClick={handleGoToCreateNewTransitionPage}
    />
  );
};

export default CreateTransitionPageButton;
