import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Row, Col, Typography } from 'antd';

import { EditContainer } from 'pages/qa-spec/components';
import { Form, WithLoading } from 'common/components';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useParams } from 'react-router-dom';

import {
  useGetQaFreshSeafoodTabData,
  useSaveQaFreshSeafoodTab,
} from './queries';

import { BasicFormItemList } from 'common/components/form/basic-form';

import { useGetEnumOptions } from 'pages/qa-spec/hooks';

import { FRESH_SEAFOOD_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';

import { useGetSnapshotQaFreshSeafood } from './hook';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import {
  getFormattedValue,
  renderPopoverContent,
} from 'pages/qa-spec/utils/index';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const QaFreshSeafood = (props) => {
  const { isTabActive } = props;

  const editHook = useState(false);
  const [isEdit] = editHook;

  const refEditContainer = useRef();
  const { id: productId } = useParams();
  const [formInst] = Form.useForm();

  const isToggleSnapshot = useCheckSnapshotForRetailer();
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const freshSeafoodEnumOptions = useGetEnumOptions({
    optionMapping: useMemo(
      () => (option) => ({
        value: option.enumDisplayName,
        displayName: option.enumDisplayName,
      }),
      []
    ),
  })?.freshSeafood;

  const {
    data,
    isLoading: loading,
    refetch: fetchQaFreshSeafoodData,
  } = useGetQaFreshSeafoodTabData(
    { productId },
    { enabled: false, retry: false }
  );

  const { data: snapshotData, isLoading: isLoadingSnapshotFreshSeafood } =
    useGetSnapshotQaFreshSeafood({
      enabled: !!productId && isToggleSnapshot,
      productId,
    });
  useEffect(() => {
    if (isTabActive && !isEdit) {
      fetchQaFreshSeafoodData();
    }
  }, [isTabActive, isEdit, fetchQaFreshSeafoodData]);

  const handleSaveQafreshSeafoodTab = useSaveQaFreshSeafoodTab();
  const { isLoading: saveLoading } = handleSaveQafreshSeafoodTab;

  useEffect(() => {
    if (data) {
      formInst.setFieldsValue(data);
    }
  }, [JSON.stringify(data)]);

  const handleSave = async (toggleEditMode, ixoneIgnore) => {
    formInst
      .validateFields()
      .then(async (values) => {
        handleSaveQafreshSeafoodTab.mutate(
          {
            productId,
            qaFreshSeaFoodFormData: values,
          },
          {
            onSuccess: async () => {
              toggleEditMode();
              await handleUpdateIXONEShield(ixoneIgnore);
            },
          }
        );
      })
      .catch((e) => {
        console.log('e', e);
      });
  };

  const handleCancel = () => {
    formInst.setFieldsValue(data);
  };

  const enumType = {
    source: freshSeafoodEnumOptions.source,
    peeledDeveined: freshSeafoodEnumOptions.feeledDeveined,
    tailOffOrOn: freshSeafoodEnumOptions.tailOffOrOn,
  };

  const formDefineLeft = [
    {
      name: 'genus',
      label: 'Genus',
      snapshotData: snapshotData?.['genus']?.trim() ?? '',
      supplierModifiedData: data?.['genus']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Genus',
        snapshotData?.['genus']?.trim() ?? '',
        data?.['genus']?.trim() ?? ''
      ),
    },
    {
      name: 'species',
      label: 'Species',
      snapshotData: snapshotData?.['species']?.trim() ?? '',
      supplierModifiedData: data?.['species']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Species',
        snapshotData?.['species']?.trim() ?? '',
        data?.['species']?.trim() ?? ''
      ),
    },
    {
      name: 'typeOfCutOrTrimSpec',
      label: 'Type of Cut or Trim Spec',
      type: 'textarea',

      inputProps: {
        autoSize: { minRows: 3, maxRows: 3 },
        maxLength: FRESH_SEAFOOD_LIMIT_CHARACTERS.typeOfCutOrTrimSpec,
        showCount: true,
      },

      snapshotData: snapshotData?.['typeOfCutOrTrimSpec'] ?? '',
      supplierModifiedData: data?.['typeOfCutOrTrimSpec'] ?? '',
      renderPopoverContent: renderPopoverContent(
        'Type of Cut or Trim Spec',
        snapshotData?.['typeOfCutOrTrimSpec'] ?? '',
        data?.['typeOfCutOrTrimSpec'] ?? ''
      ),
    },
    {
      name: 'methodOfProduction',
      label: 'Method of Production',
      type: 'textarea',
      inputProps: {
        autoSize: { minRows: 3, maxRows: 3 },
        maxLength: FRESH_SEAFOOD_LIMIT_CHARACTERS.methodOfProduction,
        showCount: true,
      },

      snapshotData: snapshotData?.['methodOfProduction'] ?? '',
      supplierModifiedData: data?.['methodOfProduction'] ?? '',
      renderPopoverContent: renderPopoverContent(
        'Method of Production',
        snapshotData?.['methodOfProduction'] ?? '',
        data?.['methodOfProduction'] ?? ''
      ),
    },
    {
      name: 'methodOfCapture',
      label: 'Method of Capture',
      type: 'textarea',
      inputProps: {
        autoSize: { minRows: 3, maxRows: 3 },
        maxLength: FRESH_SEAFOOD_LIMIT_CHARACTERS.methodOfCapture,
        showCount: true,
      },

      snapshotData: snapshotData?.['methodOfCapture'] ?? '',
      supplierModifiedData: data?.['methodOfCapture'] ?? '',
      renderPopoverContent: renderPopoverContent(
        'Method of Capture',
        snapshotData?.['methodOfCapture'] ?? '',
        data?.['methodOfCapture'] ?? ''
      ),
    },
  ];

  const formDefineRight = [
    {
      name: 'source',
      label: 'Source',
      type: 'select',
      selectProps: {
        options: freshSeafoodEnumOptions.source,
        inputProps: {
          allowClear: true,
        },
      },
      formattedValue: getFormattedValue(
        snapshotData?.['source'],
        'source',
        enumType
      ),

      snapshotData: snapshotData?.['source'],
      supplierModifiedData: data?.['source'],
      renderPopoverContent: renderPopoverContent(
        'Source',
        snapshotData?.['source'],
        data?.['source'],
        getFormattedValue(snapshotData?.['source'], 'source', enumType)
      ),
    },
    [
      {
        rowConfig: {
          gutter: [15, 15],
        },
      },
      {
        name: 'fishEstNo',
        label: 'Fish Est #',
        type: 'numeric',
        colProps: { xs: 12 },
        inputProps: {
          style: { width: '100%' },
        },
        snapshotData: snapshotData?.['fishEstNo'],
        supplierModifiedData: data?.['fishEstNo'],
        renderPopoverContent: renderPopoverContent(
          'Fish Est #',
          snapshotData?.['fishEstNo'],
          data?.['fishEstNo']
        ),
      },
      {
        name: 'fbcLogoNo',
        label: 'FBC Logo #',
        type: 'numeric',
        colProps: { xs: 12 },
        inputProps: {
          style: { width: '100%' },
        },
        snapshotData: snapshotData?.['fbcLogoNo'],
        supplierModifiedData: data?.['fbcLogoNo'],
        renderPopoverContent: renderPopoverContent(
          'Fish Est #',
          snapshotData?.['fbcLogoNo'],
          data?.['fbcLogoNo']
        ),
      },
    ],
    {
      name: 'identificationCode',
      label: 'Identification Code(1-7)',
      type: 'numeric',
      inputProps: {
        style: { width: '100%' },
        max: 7,
        min: 1,
      },
      snapshotData: snapshotData?.['identificationCode'],
      supplierModifiedData: data?.['identificationCode'],
      renderPopoverContent: renderPopoverContent(
        'Identification Code(1-7)',
        snapshotData?.['identificationCode'],
        data?.['identificationCode']
      ),
    },
    {
      name: 'productionSystem',
      label: 'Production System - Applicable to Aquaculture',
      type: 'textarea',
      inputProps: {
        autoSize: { minRows: 3, maxRows: 3 },
        maxLength: FRESH_SEAFOOD_LIMIT_CHARACTERS.productionSystem,
        showCount: true,
      },
      snapshotData: snapshotData?.['productionSystem'] ?? '',
      supplierModifiedData: data?.['productionSystem'] ?? '',
      renderPopoverContent: renderPopoverContent(
        'Production System - Applicable to Aquaculture',
        snapshotData?.['productionSystem'] ?? '',
        data?.['productionSystem'] ?? ''
      ),
    },
    [
      {
        rowConfig: {
          gutter: [15, 15],
        },
      },
      {
        name: 'seafoodRiskCategory',
        label: 'Seafood Risk Category',
        colProps: { xs: 12 },
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: FRESH_SEAFOOD_LIMIT_CHARACTERS.seafoodRiskCategory,
          showCount: true,
        },
        snapshotData: snapshotData?.['seafoodRiskCategory'] ?? '',
        supplierModifiedData: data?.['seafoodRiskCategory'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Seafood Risk Category',
          snapshotData?.['seafoodRiskCategory'] ?? '',
          data?.['seafoodRiskCategory'] ?? ''
        ),
      },
      {
        name: 'safeHandlingInstructions',
        label: 'Safe Handling Instructions',
        colProps: { xs: 12 },
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: FRESH_SEAFOOD_LIMIT_CHARACTERS.safeHandlingInstructions,
          showCount: true,
        },
        snapshotData: snapshotData?.['safeHandlingInstructions'] ?? '',
        supplierModifiedData: data?.['safeHandlingInstructions'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Safe Handling Instructions',
          snapshotData?.['safeHandlingInstructions'] ?? '',
          data?.['safeHandlingInstructions'] ?? ''
        ),
      },
    ],
    [
      {
        rowConfig: {
          gutter: [15, 15],
        },
      },
      {
        name: 'peeledDeveined',
        label: 'Peeled, Deveined (Shrimp)',
        type: 'select',
        colProps: { xs: 12 },
        selectProps: {
          options: freshSeafoodEnumOptions.feeledDeveined,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['peeledDeveined'],
          'peeledDeveined',
          enumType
        ),
        snapshotData: snapshotData?.['peeledDeveined'],
        supplierModifiedData: data?.['peeledDeveined'],
        renderPopoverContent: renderPopoverContent(
          'Peeled, Deveined (Shrimp)',
          snapshotData?.['peeledDeveined'],
          data?.['peeledDeveined'],
          getFormattedValue(
            snapshotData?.['peeledDeveined'],
            'peeledDeveined',
            enumType
          )
        ),
      },
      {
        name: 'tailOffOrOn',
        label: 'Tail off or On (Shrimp)',
        type: 'select',
        colProps: { xs: 12 },
        selectProps: {
          options: freshSeafoodEnumOptions.tailOffOrOn,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['tailOffOrOn'],
          'tailOffOrOn',
          enumType
        ),
        snapshotData: snapshotData?.['tailOffOrOn'],
        supplierModifiedData: data?.['tailOffOrOn'],
        renderPopoverContent: renderPopoverContent(
          'Tail off or On (Shrimp)',
          snapshotData?.['tailOffOrOn'],
          data?.['tailOffOrOn'],
          getFormattedValue(
            snapshotData?.['tailOffOrOn'],
            'tailOffOrOn',
            enumType
          )
        ),
      },
    ],
  ];

  return (
    <IXONECertificationWrapper
      onConfirm={(ixoneIgnore) => {
        const toggleEditMode = refEditContainer?.current?.onToggleEditMode;
        handleSave(toggleEditMode, ixoneIgnore);
      }}
      showModal
      type={TYPE_VIEW.EDIT_PRODUCT}
    >
      {(handleConfirmIXONE) => (
        <Form
          className='qa-fresh-seafood'
          form={formInst}
          style={{ paddingRight: 5 }}
          layout='vertical'
        >
          <WithLoading loading={loading || saveLoading}>
            <EditContainer
              type={EDIT_SECTION.QA_FRESH_SEAFOOD}
              productId={productId}
              handleCancel={handleCancel}
              handleSave={async () => {
                handleConfirmIXONE({
                  originalData: data,
                  changedFields: await formInst.validateFields(),
                });
              }}
              float
              ref={refEditContainer}
            >
              {(isEdit) => {
                return (
                  <EditTabSectionWrapper.CheckerWrapper
                    isEdit={isEdit}
                    type={EDIT_SECTION.QA_FRESH_SEAFOOD}
                  >
                    <Row>
                      <Col style={{ flex: 1 }}>
                        <BasicFormItemList
                          allowEdit={isEdit}
                          formDefine={formDefineLeft}
                          labelFn={labelFn}
                          isLoadingSnapshot={isLoadingSnapshotFreshSeafood}
                        />
                      </Col>
                      <Col flex='50px' />
                      <Col style={{ flex: 1 }}>
                        <BasicFormItemList
                          allowEdit={isEdit}
                          formDefine={formDefineRight}
                          labelFn={labelFn}
                          isLoadingSnapshot={isLoadingSnapshotFreshSeafood}
                        />
                      </Col>
                    </Row>
                  </EditTabSectionWrapper.CheckerWrapper>
                );
              }}
            </EditContainer>
          </WithLoading>
        </Form>
      )}
    </IXONECertificationWrapper>
  );
};

const labelFn = (labelText) => {
  return <Typography.Text strong>{labelText && labelText}</Typography.Text>;
};

export default QaFreshSeafood;
