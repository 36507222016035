import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

const filterColumns = (columns, config) => {
  const removedCheckboxColumns = columns.slice(2);

  let defaultColumns = config.reduce((result, col) => {
    const findItem = columns.find((item) => item.fieldName === col.fieldName);
    if (findItem && col?.width >= 0) {
      result.push({ ...findItem, width: col.width });
    }

    if (findItem && !col?.width) {
      result.push(findItem);
    }
    return result;
  }, []);

  // grid folders
  if (removedCheckboxColumns[0]?.field === 'type') {
    defaultColumns = [...columns.slice(0, 3), ...defaultColumns];
  } else {
    defaultColumns = [...columns.slice(0, 2), ...defaultColumns];
  }
  return defaultColumns;
};

const useFilterDefaultColumns = (columns) => {
  const { defaultColumns: defaultConfig } = useSelector(
    selectorsGridView.makeSelectGridConfig()
  );

  return React.useMemo(() => filterColumns(columns, defaultConfig), [
    columns,
    defaultConfig,
  ]);
};

const useSaveConfigColumns = (gridName) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actionsGridView.saveConfigColumns(gridName));
  }, [dispatch, gridName]);
};

const useSaveAllColumns = (columns) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actionsGridView.saveGridColumns(columns));
  }, [columns, dispatch, window.location.pathname]);
};

const useSaveChosenColumns = (columns) => {
  const dispatch = useDispatch();
  const chosenColumns = useFilterDefaultColumns(columns);

  React.useEffect(() => {
    dispatch(actionsGridView.saveChosenColumns(chosenColumns));
  }, [dispatch, chosenColumns]);
};

export {
  useSaveConfigColumns,
  useFilterDefaultColumns,
  useSaveAllColumns,
  useSaveChosenColumns,
};
