import DrugFactSection from './DrugFactSection';
import DrugFactHeading from './DrugFactHeading';
import DrugFactDetailList from './DrugFactDetailList';

const DrugFactOtherInformation = ({ otherInformation }) => {
  if (!otherInformation?.length) return null;

  const otherInformationMapping = otherInformation?.map(
    (item) => item.otherInformation
  );
  return (
    <DrugFactSection>
      <DrugFactHeading heading='Other information' />
      {otherInformationMapping?.length > 0 ? (
        <DrugFactDetailList list={otherInformationMapping} />
      ) : null}
    </DrugFactSection>
  );
};

export default DrugFactOtherInformation;
