import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateMainPage = () => {
  const queryClient = useQueryClient();

  const invalidateMainPage = () => {
    queryClient.invalidateQueries({
      queryKey: ['main-page', 'get-main-page-info'],
    });
  };
  return { invalidateMainPage };
};
