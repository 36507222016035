import { createMapFieldNameFromDisplayName } from 'pages/reporting/utils';
import { useEffect, useState } from 'react';
import { handleAddFields, handleRemoveFields } from './utils';

export const useInfiniteScroll = ({ dataSearch, propertiesData }) => {
  const [data, setData] = useState([]);

  const getDraggableId = (index) => {
    if (index == null) return index;
    const item = data[index];
    const fieldName = item?.fieldName
      ? item?.fieldName
      : createMapFieldNameFromDisplayName(item?.displayName);

    return `${fieldName}_entity`;
  };

  const onClickAttributes = (property, index) => {
    if (property?.isActive) {
      const fields = handleRemoveFields({ property, index, data });
      setData(fields);
    } else {
      const fields = handleAddFields({ index, property, data });
      setData(fields);
    }
  };

  useEffect(() => {
    const dataRender = dataSearch !== null ? dataSearch : propertiesData;
    setData(dataRender);
  }, [dataSearch, propertiesData]);

  return {
    data: data,
    getDraggableId,
    onClickAttributes,
  };
};
