import React from 'react';

import EmailTurnItem from './EmailTurnItem';

import { List } from 'antd';

const EmailTurnList = ({ data, participants }) => {
  return (
    <List
      className='chat-thread__list'
      dataSource={data || []}
      bordered={false}
      renderItem={(item) => (
        <List.Item key={item.messageId}>
          <EmailTurnItem item={item} participants={participants} />
        </List.Item>
      )}
    ></List>
  );
};

export default EmailTurnList;
