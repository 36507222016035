import * as types from './constants';

export function updateVisibleSelection(visibleSelection) {
  return {
    type: types.UPDATE_VISIBLE_SELECTION,
    visibleSelection,
  };
}

export function updateItemCurrentSelection(itemCurrentSelection) {
  return {
    type: types.UPDATE_ITEM_CURRENT_SELECTION,
    itemCurrentSelection,
  };
}

export function updateItemsSelectionDialog(itemSelectionDialog) {
  return {
    type: types.UPDATE_ITEMS_SELECTION_DIALOG,
    itemSelectionDialog,
  };
}
export function updateItemsSelection(itemsSelection) {
  return {
    type: types.UPDATE_ITEMS_SELECTION,
    itemsSelection,
  };
}

export function updatePagesSelection(pagesSelection) {
  return {
    type: types.UPDATE_PAGES_SELECTION,
    pagesSelection,
  };
}

export function updateItemPageSelection(itemPageSelection) {
  return {
    type: types.UPDATE_ITEM_PAGE_SELECTION,
    itemPageSelection,
  };
}

export function updateCountPageSelection(countPageSelection) {
  return {
    type: types.UPDATE_COUNT_PAGE_SELECTION,
    countPageSelection,
  };
}

export function updateIsDisplayMask(isDisplayMask) {
  return {
    type: types.UPDATE_IS_DISPLAY_MASK,
    isDisplayMask,
  };
}

export function updateVisible(visible) {
  return {
    type: types.UPDATE_VISIBLE,
    visible,
  };
}

export function updateSizeCurrent(sizeCurrent) {
  return {
    type: types.UPDATE_SIZE_CURRENT,
    sizeCurrent,
  };
}

export function updatePageCurrentMember(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_MEMBER,
    pageCurrent,
  };
}

export function updatePageCurrentFolder(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_FOLDER,
    pageCurrent,
  };
}

export function updatePageCurrentOwnedFolder(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_OWNED_FOLDER,
    pageCurrent,
  };
}

export function updatePageCurrentSharedFolder(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_SHARED_FOLDER,
    pageCurrent,
  };
}

// * REPORTING
export function updatePageCurrentReporting(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_REPORTING,
    pageCurrent,
  };
}

export function updatePageCurrentOwnedReporting(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_OWNED_REPORTING,
    pageCurrent,
  };
}

export function updatePageCurrentSharedReporting(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_SHARED_REPORTING,
    pageCurrent,
  };
}

export function updatePageCurrentDigitalMedia(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_DIGITAL_MEDIA,
    pageCurrent,
  };
}

export function updatePageCurrentDigitalMediaNew(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_DIGITAL_MEDIA_NEW,
    pageCurrent,
  };
}

export function updatePageCurrentAsset(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_ASSET,
    pageCurrent,
  };
}

export function updatePageCurrentAssetForMember(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_ASSET_FOR_MEMBER,
    pageCurrent,
  };
}

export function updatePageCurrentDocument(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_DOCUMENT,
    pageCurrent,
  };
}

export function updatePageCurrentMultiMedia(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_MULTI_MEDIA,
    pageCurrent,
  };
}

export function updatePageCurrentShareModal(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_SHARE_MODAL,
    pageCurrent,
  };
}

export function updatePageCurrentFavoriteAsset(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_FAVORITE_ASSET,
    pageCurrent,
  };
}

export function updatePageCurrentFavoriteProduct(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_FAVORITE_PRODUCT,
    pageCurrent,
  };
}

export function updatePageCurrentFavoriteMember(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_FAVORITE_MEMBER,
    pageCurrent,
  };
}

export function updatePageCurrentFavoriteFolder(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_FAVORITE_FOLDER,
    pageCurrent,
  };
}

export function updatePageCurrentWorkflowAcceptStep(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_WORKFLOW_ACCEPT_STEP,
    pageCurrent,
  };
}

export function updatePageCurrentWorkflowApproveStep(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_WORKFLOW_APPROVE_STEP,
    pageCurrent,
  };
}

export function updatePageCurrentMappingSharedMembers(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_MAPPING_SHARED_MEMBERS,
    pageCurrent,
  };
}

export function updatePageCurrentProduct(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_PRODUCT,
    pageCurrent,
  };
}

export function updatePageCurrentProductForMember(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_PRODUCT_FOR_MEMBER,
    pageCurrent,
  };
}

export function resetPageCurrent() {
  return {
    type: types.RESET_PAGE_CURRENT,
  };
}

export function resetPageCurrentKeepPageSize() {
  return {
    type: types.RESET_PAGE_CURRENT_KEEP_PAGE_SIZE,
  };
}

export function resetPageCurrentMember() {
  return {
    type: types.RESET_PAGE_CURRENT_MEMBER,
  };
}

export function resetPageCurrentMedia() {
  return {
    type: types.RESET_PAGE_CURRENT_MEDIA,
  };
}

export function resetPageCurrentProduct() {
  return {
    type: types.RESET_PAGE_CURRENT_PRODUCTS,
  };
}

export function updateTypeViewCurrent(typeViewCurrent) {
  return {
    type: types.UPDATE_TYPE_VIEW_CURRENT,
    typeViewCurrent,
  };
}

export function updateSearchCurrent(searchCurrent) {
  return {
    type: types.UPDATE_SEARCH_CURRENT,
    searchCurrent,
  };
}

export function updateDetailCurrentItemsSelection(detailCurrentItemsSelection) {
  return {
    type: types.UPDATE_DETAIL_CURRENT_ITEMS_SELECTION,
    detailCurrentItemsSelection,
  };
}

export function deleteItemsSelection() {
  return { type: types.DELETE_ITEMS_SELECTION };
}

export function deleteCurrentItemsSelection() {
  return { type: types.DELETE_CURRENT_ITEMS_SELECTION };
}

export function updateAgGridItemsSelection(agGridItemsSelection) {
  return {
    type: types.UPDATE_AG_GRID_ITEMS_SELECTION,
    agGridItemsSelection,
  };
}
export function getFolderList(search) {
  return {
    type: types.GET_FOLDER_LIST,
    search,
  };
}
export function getFolderListSuccess(folderList) {
  return {
    type: types.GET_FOLDER_LIST_SUCCESS,
    folderList,
  };
}

export function getFolderListError(error) {
  return {
    type: types.GET_FOLDER_LIST_ERROR,
    error,
  };
}

export function updateItemFolderSelectd(folderItem) {
  return {
    type: types.ITEM_FOLDER_SELECTED,
    folderItem,
  };
}
export function getEntityToFolder(params) {
  return {
    type: types.ADD_ENTITY_TO_FOLDER,
    params,
  };
}
export function getEntityToFolderSuccess(success) {
  return {
    type: types.ADD_ENTITY_TO_FOLDER_SUCCESS,
    success,
  };
}

export function getEntityToFolderError(error) {
  return {
    type: types.ADD_ENTITY_TO_FOLDER_ERROR,
    error,
  };
}
export function updateMessageAddEntityToFolder() {
  return {
    type: types.UPDATE_MESSAGE_ADD_ENTITY_TO_FOLDER,
  };
}

// Choose grid columns
export function saveGridColumns(columns) {
  return { type: types.SAVE_GRID_COLUMNS, columns };
}

export function chooseGridColumns(columns, id) {
  return { type: types.CHOOSE_GRID_COLUMNS, payload: { columns, id } };
}

export function getDefaultGridColumns(gridName) {
  return { type: types.GET_DEFAULT_GRID_COLUMNS, gridName };
}

export function getDefaultGridColumnsSuccess(data) {
  return { type: types.GET_DEFAULT_GRID_COLUMNS_SUCCESS, data };
}

export function getDefaultGridColumnsError() {
  return { type: types.GET_DEFAULT_GRID_COLUMNS_ERROR };
}

export function saveConfigColumns(gridName) {
  return { type: types.SAVE_CONFIG_COLUMNS, gridName };
}

export function saveConfigColumnsSuccess(data) {
  return { type: types.SAVE_CONFIG_COLUMNS_SUCCESS, data };
}

export function saveConfigColumnsError(error) {
  return { type: types.SAVE_CONFIG_COLUMNS_ERROR, error };
}

export function saveChosenColumns(columns) {
  return { type: types.SAVE_CHOSEN_COLUMNS, columns };
}

export function toggleChangeGridConfig(isToggle) {
  return { type: types.TOGGLE_CHANGE_GRID_CONFIG, isToggle };
}

export function createGridConfig(params) {
  return { type: types.CREATE_GRID_CONFIG, params };
}

export function createGridConfigSuccess(id) {
  return { type: types.CREATE_GRID_CONFIG_SUCCESS, id };
}

export function createGridConfigError(error) {
  return { type: types.CREATE_GRID_CONFIG_ERROR, error };
}

export function saveIdDefaultGrid(id) {
  return { type: types.SAVE_ID_DEFAULT_CONFIG, id };
}

export function changeWidthColumn(columns) {
  return { type: types.CHANGE_WIDTH_COLUMN, columns };
}

export function changeSelectedColumns(selectedColumns) {
  return { type: types.CHANGE_SELECTED_COLUMNS, selectedColumns };
}

export function changePositionColumns(columns) {
  return { type: types.CHANGE_POSITION_COLUMNS, columns };
}

export function changeDisplayedColumns(columns) {
  return { type: types.CHANGE_DISPLAYED_COLUMNS, columns };
}

//TODO UPDATE GRIDVIEW PAGINATION
export function updateGridViewPagination(payload) {
  return {
    type: types.UPDATE_GRID_VIEW_PAGINATION,
    payload,
  };
}

export function updateGridViewPaginationSuccess(payload) {
  return {
    type: types.UPDATE_GRID_VIEW_PAGINATION_SUCCESS,
    payload,
  };
}

export function resetPageCurrentAssets(pageCurrent) {
  return { type: types.RESET_PAGE_CURRENT_ASSETS, pageCurrent };
}

/**
 * BP 30/12/2020 - update active grid id when selecting a row
 * @param {string} gridId
 */
export function updateActiveGrid(gridId) {
  return {
    type: types.UPDATE_ACTIVE_GRID_NAME,
    gridId,
  };
}

export function updateIsRefreshGrid() {
  return {
    type: types.UPDATE_IS_REFRESH_GRID,
  };
}

export function clearGridConfig() {
  return {
    type: types.CLEAR_GRID_CONFIG,
  };
}

export function getGridQuery(params) {
  return {
    type: types.GET_GRID_QUERY,
    params,
  };
}

export function getGridQuerySuccess(data) {
  return {
    type: types.GET_GRID_QUERY_SUCCESS,
    data,
  };
}

export function getGridQueryError(error) {
  return {
    type: types.GET_GRID_QUERY_ERROR,
    error,
  };
}

export function getFolderQueryList(search) {
  return {
    type: types.GET_FOLDER_LIST_QUERY,
    search,
  };
}
export function getFolderQueryListSuccess(folderListQuery) {
  return {
    type: types.GET_FOLDER_LIST_QUERY_SUCCESS,
    folderListQuery,
  };
}

export function getFolderQueryListError(error) {
  return {
    type: types.GET_FOLDER_LIST_QUERY_ERROR,
    error,
  };
}

export function toggleRefreshQuery(value) {
  return {
    type: types.TOGGLE_REFRESH_QUERY,
    value,
  };
}

export function checkQueryCondition(checkQueryCondition) {
  return {
    type: types.CHECK_QUERY_CONDITION,
    checkQueryCondition,
  };
}
export function myQueryDoubleClick(myQueryDoubleClick) {
  return {
    type: types.MY_QUERY_DOUBLE_CLICK,
    myQueryDoubleClick,
  };
}

export function updateQueryFavMember(favMembersQuery) {
  return {
    type: types.UPDATE_QUERY_FAV_MEMBER,
    favMembersQuery,
  };
}
export function updateQueryFavAsset(favAssetsQuery) {
  return {
    type: types.UPDATE_QUERY_FAV_ASSET,
    favAssetsQuery,
  };
}
export function updateQueryFavFolder(favFoldersQuery) {
  return {
    type: types.UPDATE_QUERY_FAV_FOLDER,
    favFoldersQuery,
  };
}
export function updateQueryFavProduct(favProductsQuery) {
  return {
    type: types.UPDATE_QUERY_FAV_PRODUCT,
    favProductsQuery,
  };
}
export function updateQueryProduct(productsQuery) {
  return {
    type: types.UPDATE_QUERY_PRODUCT,
    productsQuery,
  };
}
export function updateQueryMember(membersQuery) {
  return {
    type: types.UPDATE_QUERY_MEMBER,
    membersQuery,
  };
}
export function updateQueryDigitalMedia(digitalMediaQuery) {
  return {
    type: types.UPDATE_QUERY_DIGITAL_MEDIA,
    digitalMediaQuery,
  };
}
export function updateQueryDigitalMediaNew(digitalMediaQuery) {
  return {
    type: types.UPDATE_QUERY_DIGITAL_MEDIA_NEW,
    digitalMediaQuery,
  };
}
export function updateQueryAsset(assetsQuery) {
  return {
    type: types.UPDATE_QUERY_ASSET,
    assetsQuery,
  };
}
export function updateQueryDocument(documentsQuery) {
  return {
    type: types.UPDATE_QUERY_DOCUMENT,
    documentsQuery,
  };
}
export function updateQueryMultiMedia(multiMediaQuery) {
  return {
    type: types.UPDATE_QUERY_MULTI_MEDIA,
    multiMediaQuery,
  };
}
export function updateQueryReporting(reportingsQuery) {
  return {
    type: types.UPDATE_QUERY_REPORTING,
    reportingsQuery,
  };
}
export function updateQueryReportingOwned(myReportsQuery) {
  return {
    type: types.UPDATE_QUERY_REPORTING_OWNED,
    myReportsQuery,
  };
}
export function updateQueryReportingShared(reportsSharedToMeQuery) {
  return {
    type: types.UPDATE_QUERY_REPORTING_SHARED,
    reportsSharedToMeQuery,
  };
}
export function updateQueryFolder(foldersQuery) {
  return {
    type: types.UPDATE_QUERY_FOLDER,
    foldersQuery,
  };
}
export function updateQueryFolderOwned(myFoldersQuery) {
  return {
    type: types.UPDATE_QUERY_FOLDER_OWNED,
    myFoldersQuery,
  };
}
export function updateQueryFolderShared(foldersSharedToMeQuery) {
  return {
    type: types.UPDATE_QUERY_FOLDER_SHARED,
    foldersSharedToMeQuery,
  };
}
export function updateQueryAssetsForMember(assetsForMemberQuery) {
  return {
    type: types.UPDATE_QUERY_ASSET_FOR_MEMBER,
    assetsForMemberQuery,
  };
}

export function updateQueryProductsForMember(productsForMemberQuery) {
  return {
    type: types.UPDATE_QUERY_PRODUCT_FOR_MEMBER,
    productsForMemberQuery,
  };
}

export function updateQueryAdvanceFavMember(favMembersQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_FAV_MEMBER,
    favMembersQueryAdvance,
  };
}
export function updateQueryAdvanceFavAsset(favAssetsQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_FAV_ASSET,
    favAssetsQueryAdvance,
  };
}
export function updateQueryAdvanceFavFolder(favFoldersQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_FAV_FOLDER,
    favFoldersQueryAdvance,
  };
}
export function updateQueryAdvanceFavProduct(favProductsQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_FAV_PRODUCT,
    favProductsQueryAdvance,
  };
}
export function updateQueryAdvanceProduct(productsQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_PRODUCT,
    productsQueryAdvance,
  };
}
export function updateQueryAdvanceMember(membersQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_MEMBER,
    membersQueryAdvance,
  };
}
export function updateQueryAdvanceDigitalMedia(digitalMediaQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA,
    digitalMediaQueryAdvance,
  };
}
export function updateQueryAdvanceDigitalMediaNew(digitalMediaQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA_NEW,
    digitalMediaQueryAdvance,
  };
}
export function updateQueryAdvanceAsset(assetsQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_ASSET,
    assetsQueryAdvance,
  };
}
export function updateQueryAdvanceDocument(documentsQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_DOCUMENT,
    documentsQueryAdvance,
  };
}
export function updateQueryAdvanceMultiMedia(multiMediaQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_MULTI_MEDIA,
    multiMediaQueryAdvance,
  };
}
export function updateQueryAdvanceReporting(reportingsQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_REPORTING,
    reportingsQueryAdvance,
  };
}
export function updateQueryAdvanceReportingOwned(myReportsQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_REPORTING_OWNED,
    myReportsQueryAdvance,
  };
}
export function updateQueryAdvanceReportingShared(
  reportsSharedToMeQueryAdvance
) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_REPORTING_SHARED,
    reportsSharedToMeQueryAdvance,
  };
}
export function updateQueryAdvanceFolder(foldersQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_FOLDER,
    foldersQueryAdvance,
  };
}
export function updateQueryAdvanceFolderOwned(myFoldersQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_FOLDER_OWNED,
    myFoldersQueryAdvance,
  };
}
export function updateQueryAdvanceFolderShared(foldersSharedToMeQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_FOLDER_SHARED,
    foldersSharedToMeQueryAdvance,
  };
}
export function updateQueryAdvanceAssetsForMember(assetsForMemberQueryAdvance) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_ASSET_FOR_MEMBER,
    assetsForMemberQueryAdvance,
  };
}

export function updateQueryAdvanceProductsForMember(
  productsForMemberQueryAdvance
) {
  return {
    type: types.UPDATE_QUERY_ADVANCE_PRODUCT_FOR_MEMBER,
    productsForMemberQueryAdvance,
  };
}
export function updateIdQueryFavMember(favMembersIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_FAV_MEMBER,
    favMembersIdQuery,
  };
}
export function updateIdQueryFavAsset(favAssetsIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_FAV_ASSET,
    favAssetsIdQuery,
  };
}

export function updateIdQueryFavFolder(favFoldersIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_FAV_FOLDER,
    favFoldersIdQuery,
  };
}
export function updateIdQueryFavProduct(favProductsIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_FAV_PRODUCT,
    favProductsIdQuery,
  };
}
export function updateIdQueryProduct(productsIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_PRODUCT,
    productsIdQuery,
  };
}
export function updateIdQueryMember(membersIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_MEMBER,
    membersIdQuery,
  };
}
export function updateIdQueryDigitalMedia(digitalMediaIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_DIGITAL_MEDIA,
    digitalMediaIdQuery,
  };
}
export function updateIdQueryDigitalMediaNew(digitalMediaIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_DIGITAL_MEDIA_NEW,
    digitalMediaIdQuery,
  };
}
export function updateIdQueryAsset(assetsIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_ASSET,
    assetsIdQuery,
  };
}
export function updateIdQueryDocument(documentsIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_DOCUMENT,
    documentsIdQuery,
  };
}
export function updateIdQueryMultiMedia(multiMediaIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_MULTI_MEDIA,
    multiMediaIdQuery,
  };
}
export function updateIdQueryReporting(reportingsIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_REPORTING,
    reportingsIdQuery,
  };
}
export function updateIdQueryReportingOwned(myReportsIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_REPORTING_OWNED,
    myReportsIdQuery,
  };
}
export function updateIdQueryReportingShared(reportsSharedToMeIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_REPORTING_SHARED,
    reportsSharedToMeIdQuery,
  };
}
export function updateIdQueryFolder(foldersIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_FOLDER,
    foldersIdQuery,
  };
}
export function updateIdQueryFolderOwned(myFoldersIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_FOLDER_OWNED,
    myFoldersIdQuery,
  };
}
export function updateIdQueryFolderShared(foldersSharedToMeIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_FOLDER_SHARED,
    foldersSharedToMeIdQuery,
  };
}

export function updateIdQueryAssetsForMember(assetsForMemberIdQuery) {
  return {
    type: types.UPDATE_ID_QUERY_ASSET_FOR_MEMBER,
    assetsForMemberIdQuery,
  };
}

export const setUserSearchCategoriesEffected = (searchCategoriesEffected) => ({
  type: types.SET_USER_SEARCH_CATEGORIES_EFFECTED,
  searchCategoriesEffected,
});

export function updatePageCurrentPartyGDSNList(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_PARTY_GDSN,
    pageCurrent,
  };
}

export function updatePageCurrentMemberMaintainUser(pageCurrent) {
  return {
    type: types.UPDATE_PAGE_CURRENT_MEMBER_MAINTAIN_USER,
    pageCurrent,
  };
}

export const toggleReloadGrid = (value) => {
  return {
    type: types.TOGGLE_RELOAD_GRID,
    reloadGrid: value,
  };
};

export const updateParamsGridView = (filters) => {
  return { type: types.UPDATE_PARAMS_GRID_VIEW, filters };
};

export const clearFilterParamsGridView = () => {
  return { type: types.UPDATE_PARAMS_GRID_VIEW };
};

export const updatePageCurrentRequestGrid = (pageCurrent) => {
  return { type: types.UPDATE_PAGE_CURRENT_REQUEST_GRID, pageCurrent };
};

export const updatePageCurrentTransition = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_TRANSITION,
    pageCurrent,
  };
};

export const updatePageCurrentSsoApprovalMember = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_SSO_APPROVAL_MEMBER,
    pageCurrent,
  };
};

export const updatePageCurrentFormMaintenance = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_FORM_MANANGE,
    pageCurrent,
  };
};

export const updatePageCurrentHistoryEntity = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_HISTORY_ENTITY,
    pageCurrent,
  };
};

export const updatePageCurrentSharableMemberList = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_SHAREABLE_MEMBER_LIST,
    pageCurrent,
  };
};

export const updatePageCurrentSharableUserList = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_SHAREABLE_USER_LIST,
    pageCurrent,
  };
};

export const updatePageCurrentManageQuery = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_MANAGE_QUERY,
    pageCurrent,
  };
};

export const updatePageCurrentPublication = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_PUBLICATION,
    pageCurrent,
  };
};

export const updatePageCurrentQasRequireField = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_QAS_REQUIRE_FIELD,
    pageCurrent,
  };
};

export function updatePrevPathnameQuery(prevPathnameQuery) {
  return {
    type: types.UPDATE_PREV_PATHNAME_QUERY,
    prevPathnameQuery,
  };
}

// Folder grid view
export const togglePreventCallApiGridView = (value) => {
  return {
    type: types.TOGGLE_PREVENT_CALL_API_GRID_VIEW,
    value,
  };
};

export const updateIsOpenAdvanceSearch = (payload) => {
  return {
    type: types.UPDATE_IS_OPEN_ADVANCE_SEARCH,
    isOpenAdvanceSearch: payload,
  };
};

export const updatePageCurrentTicketingSystem = (pageCurrent) => {
  return {
    type: types.UPDATE_PAGE_CURRENT_TICKETING_SYSTEM,
    pageCurrent,
  };
};