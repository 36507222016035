export const DEFAULT_US_ALLERGENS = [
  'eggs and its derivatives',
  'soybean and its derivatives',
  'wheat and its derivatives',
  'milk and its derivatives',
  'fish and its derivatives',
  'crustaceans and its derivatives',
  'sesame seeds and its derivatives',
  'peanuts and its derivatives',
  'tree nuts (nuts) and its derivatives',
  'oats and its derivatives',
  'seed products and its derivatives',
  'corn and its derivatives',
  'shellfish and its derivatives',
];

export const DEFAULT_WW_ALLERGENS = [
  'crustaceans and its derivatives',
  'eggs and its derivatives',
  'fish and its derivatives',
  'peanuts and its derivatives',
  'soybean and its derivatives',
  'milk and its derivatives',
  'sesame seeds and its derivatives',
  'sulfur dioxide and sulfits',
  'celery and its derivatives',
  'mustard and its derivatives',
  'lupine and its derivatives',
  'molluscs and its derivatives',
  'tree nuts (nuts) and its derivatives',
  'royal jelly, bee pollen and propolis',
  'cereals and its derivatives',
];
