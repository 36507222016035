/*
 * Login Messages
 *
 * This contains all the text for the login component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.branded-assets-products';

export default defineMessages({
  gridTitle: {
    id: `${scope}.constants.assetGridView.gridTitle`,
    defaultMessage: 'PRODUCT ASSET',
  },
  ucp12: {
    id: `${scope}.constants.assetGridView.upc12`,
    defaultMessage: 'UPC12',
  },
  manufacturer: {
    id: `${scope}.constants.assetGridView.manufacturer`,
    defaultMessage: 'MANUFACTURER',
  },
  brand: {
    id: `${scope}.constants.assetGridView.brand`,
    defaultMessage: 'BRAND',
  },
  description: {
    id: `${scope}.constants.assetGridView.description`,
    defaultMessage: 'DESCRIPTION',
  },
  size: {
    id: `${scope}.constants.assetGridView.size`,
    defaultMessage: 'SIZE',
  },
  status: {
    id: `${scope}.constants.assetGridView.status`,
    defaultMessage: 'STATUS',
  },
  lastModified: {
    id: `${scope}.constants.assetGridView.lastModified`,
    defaultMessage: 'LAST MODIFIED',
  },
  editedBy: {
    id: `${scope}.constants.assetGridView.editedBy`,
    defaultMessage: 'EDITED BY',
  },
  ixoneId: {
    id: `${scope}.constants.assetGridView.ixoneId`,
    defaultMessage: 'IXONE ID',
  },
  ixoneCertifiateDate: {
    id: `${scope}.constants.assetGridView.ixoneCertifiateDate`,
    defaultMessage: 'IXONE CERTIFICATION DATE',
  },
  syncReady: {
    id: `${scope}.constants.assetGridView.syncReady`,
    defaultMessage: 'SYNC READY',
  },
  viewDetail: {
    id: `${scope}.constants.assetGridView.viewDetail`,
    defaultMessage: 'VIEW DETAIL',
  },
  hintGridAsset: {
    id: `${scope}.constants.assetGridView.hintGridAsset`,
    defaultMessage:
      'You can drag-drop items in the grid after you search successfully.',
  },
});
