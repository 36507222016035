import { useParams } from 'react-router-dom';
import { useGetQaSpecDataHeader } from 'pages/qa-spec/query';

export const useCheckQaSpecLocked = () => {
  const { id: productId } = useParams();

  const { qaSpecDataHeader } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: !!productId,
  });

  const checkQASpecLocked = () => {
    const unlockedStatus = ['submitted', 'approved'];

    return unlockedStatus.includes(qaSpecDataHeader?.status?.toLowerCase());
  };
  const isQASpecLocked = checkQASpecLocked();

  return {
    isQASpecLocked,
  };
};
