import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Paragraph } = Typography;

const ProductData = (props) => {
  const { dataDetail } = props;

  return (
    <Fragment>
      <Paragraph strong='true' ellipsis='true'>
        {dataDetail && dataDetail.productName}
      </Paragraph>
      <Paragraph strong='true' ellipsis='true'>
        {dataDetail && dataDetail.brandName}
      </Paragraph>
      <Paragraph strong='true' ellipsis='true'>
        {dataDetail &&
          dataDetail.packageLevelInformation &&
          dataDetail.packageLevelInformation.length > 0 &&
          dataDetail.packageLevelInformation[0].gtin}
      </Paragraph>
      <Paragraph strong='true' ellipsis='true'>
        {dataDetail && dataDetail.memberName}
      </Paragraph>
      <Paragraph strong='true' ellipsis='true'>
        {dataDetail && dataDetail.primarySize}&nbsp;
        {dataDetail && dataDetail.primarySizeUom}
      </Paragraph>
    </Fragment>
  );
};

ProductData.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductData;
