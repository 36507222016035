import React, { useState } from 'react';

import { Row, Col, Button } from 'antd';

import {
  PlusOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import { dialogFunction, FormAddButton } from 'common/components';

import FormMultipleNestedValue from './FormMultipleNestedValue';

import {
  LabelFormItem,
  GroupElementWrapper,
  HeaderGroupElement,
  FormItemText,
  FormItemNumber,
  FormItemDatetime,
  FormItemBoolean,
  FormItemList,
  FormItemSelectSpecialEnum,
  FormItemSelectEnum,
  FormItemContainer,
} from '../../shared/components';

import { useProductDetail } from './ProductDetailContext';

import {
  checkTypeOfFormNested,
  findProductPropertyEnums,
  getFormNameItem,
  getValuesAfterDeletingSingleNestedForm,
  checkIsValidObjectValues,
  getFieldValueFromListIdx,
} from './utils';

import { NUMERIC_TYPE } from 'static/Constants';

import {
  LIST_BOOLEAN_PROPERTY_NAME_NEED_TO_DISABLE,
  SPECIAL_ENUMS,
} from 'pages/product-full-view/constants';

import { sleep } from 'utils/delay';

const FormSingleNestedValue = ({
  name,
  property,
  parentNestedIndex = [],
  productEnums,
  handleValidate,
  deleteFieldForm,
}) => {
  const [action, setAction] = useState('idle');

  const { formInstance } = useProductDetail();

  const initialFormValues = formInstance.getFieldValue(parentNestedIndex); // use parentNestedIndex to get values because values of form is nested object

  const hasInitialValues = Boolean(initialFormValues);

  const isAddedValuesProperty = action === 'add' || hasInitialValues;
  const isDisabledBtn = isAddedValuesProperty && action !== 'delete';

  const {
    propertyName,
    propertyDisplayName,
    propertyDescription,
    fieldFullPath,
    childProperties,
  } = property;

  const handleDeleteFieldForm = async () => {
    setAction('delete');

    const newFormValues = getValuesAfterDeletingSingleNestedForm({
      formValues: formInstance.getFieldsValue(),
      parentNestedIndex,
      propertyName,
    });
    formInstance.setFieldsValue(newFormValues);
  };

  const confirmDeleteFieldForm = () => {
    const formValues = formInstance.getFieldsValue();
    const fieldFormValues = getFieldValueFromListIdx(
      formValues,
      parentNestedIndex
    );
    const isValidValues = checkIsValidObjectValues(fieldFormValues);

    if (isValidValues) {
      dialogFunction({
        type: 'warn',
        content: 'Are you sure you want to delete selected property?',
        okText: 'Delete',
        okButtonProps: {
          type: 'danger',
        },
        onOk: async () => {
          handleDeleteFieldForm();
          await sleep(1000);
          handleValidate(formValues);
          deleteFieldForm({ fieldFormValues, fieldPath: parentNestedIndex });
        },
      });
    } else {
      handleDeleteFieldForm();
    }
  };

  const renderChildElement = ({ property, name }) => {
    const {
      propertyType,
      propertyName,
      propertyDisplayName,
      isArray,
      childProperties,
      listName,
    } = property;

    const nameFormItem = getFormNameItem(name, propertyName);

    const { isSingleNestedValue, isMultipleNestedValue } =
      checkTypeOfFormNested(childProperties, isArray);

    if (isSingleNestedValue) {
      const singleParentNestedIndex = parentNestedIndex.concat(propertyName);

      return (
        <FormSingleNestedValue
          name={nameFormItem}
          property={property}
          productEnums={productEnums}
          parentNestedIndex={singleParentNestedIndex}
          handleValidate={handleValidate}
          deleteFieldForm={deleteFieldForm}
        />
      );
    }

    if (isMultipleNestedValue) {
      return (
        <FormMultipleNestedValue
          properties={childProperties}
          isArray={isArray}
          name={nameFormItem}
          label={propertyDisplayName}
          property={property}
          productEnums={productEnums}
          parentNestedIndex={parentNestedIndex}
          handleValidate={handleValidate}
          deleteFieldForm={deleteFieldForm}
        />
      );
    }

    if (listName) {
      const propertyEnums = findProductPropertyEnums(listName, productEnums);

      if (SPECIAL_ENUMS.includes(listName)) {
        return (
          <FormItemSelectSpecialEnum
            property={property}
            nameFormItem={nameFormItem}
            propertyEnums={propertyEnums}
            labelCol={{ span: 6 }}
          />
        );
      }

      return (
        <FormItemSelectEnum
          property={property}
          nameFormItem={nameFormItem}
          propertyEnums={propertyEnums}
          labelCol={{ span: 6 }}
        />
      );
    }

    if (isArray) {
      if (NUMERIC_TYPE.includes(property.propertyType)) {
        return (
          <FormItemList
            property={property}
            name={nameFormItem}
            className='product-detail__form-list'
            variant='number'
          />
        );
      } else if (property.propertyType === 'datetime') {
        return (
          <FormItemList
            property={property}
            name={nameFormItem}
            className='product-detail__form-list'
            variant='datetime'
          />
        );
      } else {
        return (
          <FormItemList
            property={property}
            name={nameFormItem}
            className='product-detail__form-list'
            variant='text'
          />
        );
      }
    }

    if (propertyType === 'string')
      return (
        <FormItemText
          property={property}
          name={nameFormItem}
          labelCol={{ span: 6 }}
        />
      );

    if (NUMERIC_TYPE.includes(propertyType))
      return (
        <FormItemNumber
          name={nameFormItem}
          property={property}
          labelCol={{ span: 6 }}
        />
      );

    if (propertyType === 'datetime')
      return (
        <FormItemDatetime
          name={nameFormItem}
          property={property}
          labelCol={{ span: 6 }}
        />
      );

    if (propertyType === 'boolean') {
      const isDisabled =
        LIST_BOOLEAN_PROPERTY_NAME_NEED_TO_DISABLE.includes(propertyName);

      return (
        <FormItemBoolean
          name={nameFormItem}
          property={property}
          labelCol={{ span: 6 }}
          disabled={isDisabled}
        />
      );
    }

    // Default display text input for catching bug from incomplete BE data
    return (
      <FormItemText
        property={property}
        name={nameFormItem}
        labelCol={{ span: 6 }}
      />
    );
  };

  return (
    <>
      <FormItemContainer name={name}>
        <Row style={{ marginBottom: 6 }}>
          <Col
            span={6}
            style={{
              padding: '4px 0px',
              textAlign: 'right',
            }}
            className='product-detail__label-form'
          >
            <LabelFormItem
              label={propertyDisplayName}
              tooltip={propertyDescription}
              fieldFullPath={fieldFullPath}
            />
          </Col>
          <Col span={18}>
            <FormAddButton
              type='secondary'
              icon={<PlusOutlined />}
              text='Add value'
              onClick={() => {
                setAction('add');
              }}
              disabled={isDisabledBtn}
            />
          </Col>
        </Row>
      </FormItemContainer>
      {isAddedValuesProperty ? (
        <Row
          style={{
            position: 'relative',
            marginTop: 15,
          }}
          wrap={false}
        >
          <GroupElementWrapper
            flex='auto'
            style={{ marginLeft: 24, marginBottom: 6 }}
            className='product-detail__group-element'
          >
            <HeaderGroupElement header={propertyDisplayName} />

            {childProperties.map((childProperty) => {
              return (
                <React.Fragment key={childProperty.fieldFullPath}>
                  {renderChildElement({
                    property: childProperty,
                    name,
                  })}
                </React.Fragment>
              );
            })}
          </GroupElementWrapper>

          <Col flex='24px' style={{ marginLeft: 4, marginRight: 4 }}>
            <Button
              type='danger'
              icon={<CloseCircleOutlined />}
              style={{
                marginBottom: 4,
                borderColor: '#ff4d4f',
                backgroundColor: '#ff4d4f',
                borderRadius: 4,
              }}
              onClick={() => confirmDeleteFieldForm()}
            />

            <Button
              type='primary'
              className='product-detail__btn-copy'
              icon={<CopyOutlined />}
              disabled={true}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default FormSingleNestedValue;
