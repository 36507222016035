import React, { useMemo, useState } from 'react';

import { Row, Col } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import { FormButton } from 'common/components';
import MoveFolderModal from 'pages/home/ribbon/components/controls/folders/MoveFolderModal';

import { useGridView } from 'hooks/useGridView';

import { getCurrentFolderId } from './utils';

const FolderPanelActions = (props) => {
  const { treeData, shortDetail, selectedItem, handleBuildHierarchyTree } =
    props;

  const [moveFolderModalVisible, setMoveFolderModalVisible] = useState(false);

  const { reloadPage } = useGridView();

  const onClickMove = () => {
    setMoveFolderModalVisible(true);
  };

  const closeMoveModal = () => {
    setMoveFolderModalVisible(false);
  };

  const moveSubFolderSuccessCallback = ({ destinationFolder }) => {
    handleBuildHierarchyTree(`/${shortDetail?.id}`);

    reloadPage(); //* 4555: check reload page condition may cause bug;
  };

  const checkDisabledMoveBtn = () => {
    const isSharedFolder = !selectedFolder?.isOwned;
    const isEditableFolder = selectedFolder?.editable;
    const isSelfSelected = selectedItem?.id === shortDetail?.id;

    if (!shortDetail?.isOwned && !shortDetail?.editable) return true;

    if (!selectedItem?.id) return true;

    //* preventing self-moving as it may cause issues
    if (isSelfSelected) return true;

    if (isSharedFolder && !isEditableFolder) return true;

    return false;
  };

  const currentFolderId = useMemo(
    () =>
      getCurrentFolderId({
        treeData,
        selectedItem,
      }),
    [selectedItem, treeData]
  );

  const selectedFolder =
    selectedItem?.type?.toLowerCase() === 'folder' && selectedItem;

  const disabled = checkDisabledMoveBtn();

  return (
    <Row wrap={false}>
      <Col flex={1}></Col>
      <Col flex={0}>
        <FormButton
          type='default'
          text={'Move'}
          icon={<DoubleRightOutlined />}
          disabled={disabled}
          onClick={onClickMove}
        />
      </Col>
      <MoveFolderModal
        visible={moveFolderModalVisible}
        closeModal={closeMoveModal}
        selectedItems={[selectedItem]}
        currentFolderId={currentFolderId}
        onMove={moveSubFolderSuccessCallback}
        shouldReloadPage={false}
      />
    </Row>
  );
};

export default FolderPanelActions;
