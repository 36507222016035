import React from 'react';
import { Row, Col, Avatar, Typography } from 'antd';
import { FormDeleteButton } from 'common/components';

import { CaretRightOutlined } from '@ant-design/icons';

import { Images } from 'config/assets';

import { ROUTE } from 'static/Constants';

import './PackageItemInfo.less';

const { Text, Title } = Typography;

const PackageItemInfo = (props) => {
  const { packageLevelItem } = props;

  const handleOpenProductNewTab = () => {
    const productId = packageLevelItem?.productId;
    if (productId) {
      const url = window.location.origin + ROUTE.PRODUCT + `/${productId}`;
      productId && window.open(url);
    }
  };

  return (
    <Row className='publication-error-package-item-info' align='middle'>
      <Col style={{ padding: '0 10px 0 5px' }}>
        <Avatar
          src={packageLevelItem?.productImageThumb || Images.RIVIR_LOGO_DEFAULT}
          style={{
            alignItems: 'center',
            border: 'solid 2px',
            background: '#f8f8f8',
          }}
          size={70}
        />
      </Col>
      <Col flex={1}>
        <Title level={5}>{packageLevelItem?.productName}</Title>
        <div style={{ marginBottom: 4 }}>
          <Text className='publication-error-package-item-info__package-level'>
            {packageLevelItem?.productPackageLevel}
          </Text>
        </div>
        <div>
          <Text className='publication-error-package-item-info__size'>
            {isNaN(packageLevelItem?.productSize)
              ? 'Unknown product size'
              : Math.round(packageLevelItem?.productSize * 100) / 100 ||
                'Unknown product size'}
            &nbsp;(
            {packageLevelItem?.productSizeUOM || 'Unknown measurement unit'})
          </Text>
        </div>
      </Col>
      <Col>
        <FormDeleteButton
          icon={<CaretRightOutlined />}
          text='Open product'
          onClick={handleOpenProductNewTab}
          style={{ margin: 5 }}
        />
      </Col>
    </Row>
  );
};

export default PackageItemInfo;
