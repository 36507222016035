import produce from 'immer';
import * as types from './constants';

export const initialState = {
  loading: false,
  columns: [],
  articles: [],
  error: false,
  pageSize: 20,
  pageNumber: 1,
  isCreate: false,
  isEdit: false,
  isConfirmEdit: false,
  statusUpdate: 'idle',
  idArticle: null,
};

/* eslint-disable default-case, no-param-reassign */
const articlesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_ARTICLE:
        draft.loading = true;
        draft.error = false;
        break;
      case types.GET_ARTICLE_SUCCESS:
        draft.loading = false;
        draft.articles = action.articles;
        draft.total = action.paging.totalRecord;
        draft.pageSize = action.paging.currentPageSize;
        draft.pageNumber = action.paging.currentPageIndex;
        break;
      case types.GET_ARTICLE_ERROR:
        draft.loading = false;
        draft.total = 0;
        draft.articles = [];
        draft.error = action.error;
        break;

      case types.GET_ARTICLES_GRID_COLUMN:
        draft.loading = true;
        draft.columns = [];
        break;
      case types.GET_ARTICLES_GRID_COLUMN_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;
      case types.GET_ARTICLES_GRID_COLUMN_ERROR:
        draft.loading = false;
        draft.columns = [];
        draft.error = action.error;
        break;

      case types.ENABLE_CREATE_ARTICLE:
        draft.isCreate = true;
        draft.isEdit = false;
        break;

      case types.DISABLE_CREATE_ARTICLE:
        draft.isCreate = false;
        break;

      case types.DISABLE_EDIT_ARTICLE:
        draft.isEdit = false;
        break;

      case types.ENABLE_EDIT_ARTICLE:
        draft.isEdit = true;
        draft.isCreate = false;
        break;

      case types.RESET_MODE_ARTICLE:
        draft.isCreate = false;
        draft.isEdit = false;
        break;

      case types.CONFIRM_EDIT_ARTICLE:
        draft.isConfirmEdit = true;
        break;

      case types.DISABLE_CONFIRM_EDIT_ARTICLE:
        draft.isConfirmEdit = false;
        break;

      case types.CHANGE_STATUS_UPDATE_ARTICLE:
        draft.statusUpdate = action.status;
        break;

      case types.SAVE_ID_ARTICLE:
        draft.idArticle = action.id;
        break;

      default:
        break;
    }
  });

export default articlesReducer;
