import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import ModalFormQasRequireField from './ModalFormQasRequireField';
// redux
import { useSelector } from 'react-redux';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const EditQasRequireField = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const id = selectedDetail?.length === 1 ? selectedDetail[0].id : undefined;

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const handleClick = () => toggleModal();

  return (
    <>
      <RibbonButton
        icon={<EditOutlined />}
        label={'Edit'}
        disabled={id === undefined || selectedDetail.length !== 1}
        onClick={handleClick}
      />
      {isModalOpen && (
        <ModalFormQasRequireField
          isEdit={true}
          id={id}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default EditQasRequireField;
