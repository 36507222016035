import React, { useState } from 'react';

import {
  CaretLeftOutlined,
  CaretRightOutlined,
  FullscreenOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Empty, Row, Tooltip, Typography } from 'antd';
import {
  CustomNotification,
  FormAddButton,
  ThumbnailItem,
  WithAbsoluteContainer,
} from 'common/components';

import Slider from 'react-slick';
import AddAssetPimModal from './modal/add-asset-pim-modal';

import { PIM_ASSET_TYPE_DEFINE } from 'static/Constants';

import * as getLink from 'utils/common/linkBuilder';
import { mapFileTypeToImage } from 'utils/fileType';

import { MAX_SLIDER_SHOW, MAX_SLIDER_SHOW_1366 } from '../constants';

import * as productService from 'services/product';

import { useGetProductFullView } from 'hooks';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const { Text } = Typography;

const ProductImageSlider = (props) => {
  const {
    imageList,
    title,
    openPreview,
    sliderClass,
    getMarketingAssets,
    getImagery,
    isAllowEdit,
    getProductDetail,
    productId,
  } = props;

  const [isLinkToNewAssetModalOpen, setIsLinkToNewAssetModalOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { productFull } = useGetProductFullView({ productId });

  const handleToggleLinkAssetModal = () => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
  };

  const handlePrimaryProduct = (productImageLinkId) => {
    setIsLoading(true);

    const params = {
      productId: productFull?.productId,
      productImageLinkId: productImageLinkId,
    };

    productService
      .setPrimaryProductImage(params)
      .then((response) => {
        if (response?.isSuccess) {
          CustomNotification.success('Change Primary Image successfully.');
          getProductDetail();
        } else {
          CustomNotification.error(response?.message);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showPreviewMarkingImage = (item) => {
    return (
      item?.thumbFull ||
      item?.thumb1000 ||
      item?.thumb500 ||
      mapFileTypeToImage(item?.fileType)
    );
  };

  const renderArrow = (type) => {
    const icons = {
      prev: CaretLeftOutlined,
      next: CaretRightOutlined,
    };
    const Icon = icons[type];
    return (
      <Card
        size='small'
        hoverable
        bodyStyle={{
          width: 40,
          height: 80,
        }}
      >
        <Icon
          className='product-gallery__slider-button-icon'
          style={{ fontSize: 20 }}
        />
      </Card>
    );
  };

  const sliderConfigs = {
    dots: false,
    infinite: true,
    speed: 500,
    draggable: false,
    slidesToShow:
      imageList.length < MAX_SLIDER_SHOW ? imageList.length : MAX_SLIDER_SHOW,
    slidesToScroll:
      imageList.length < MAX_SLIDER_SHOW ? imageList.length : MAX_SLIDER_SHOW,
    prevArrow: renderArrow('prev'),
    nextArrow: renderArrow('next'),
    responsive: [
      {
        breakpoint: 1520,
        settings: {
          slidesToShow:
            imageList.length < MAX_SLIDER_SHOW_1366
              ? imageList.length
              : MAX_SLIDER_SHOW_1366,
          slidesToScroll:
            imageList.length < MAX_SLIDER_SHOW_1366
              ? imageList.length
              : MAX_SLIDER_SHOW_1366,
        },
      },
    ],
  };

  const addType =
    PIM_ASSET_TYPE_DEFINE.find((type) => type.toLocaleLowerCase() === title) ||
    '';

  return (
    <Row className={`product-gallery__slider ${sliderClass}`} gutter={[8, 0]}>
      <Tooltip title='Show preview'>
        <Button
          onClick={() => openPreview(true)}
          className='product-gallery__slider--open-button'
          icon={<FullscreenOutlined style={{ fontSize: '20px' }} />}
        ></Button>
      </Tooltip>
      <Col
        flex={0}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 52,
        }}
      >
        <Text className='product-gallery__slider-title'>{title}</Text>
      </Col>
      <Col
        flex={0}
        style={{ maxWidth: 'calc(100% - 60px)', width: '100%' }}
        className='product-gallery__slider-container-wrapper'
      >
        <div className='product-gallery__slider-container'>
          {imageList && imageList.length > 0 ? (
            <Slider {...sliderConfigs}>
              {imageList?.map((image, index) => {
                const title = getLink.assetLink(
                  image?.digitalAssetId,
                  image?.imageName,
                  '_blank'
                );
                const thumb = showPreviewMarkingImage(image);

                return (
                  <div className='product-gallery__slider-item' key={index}>
                    <div className='product-gallery__slider-item-image'>
                      <ThumbnailItem
                        labelThumbnail={title}
                        imageThumbnail={thumb}
                        hideCheckbox
                        isSetPrimary={isAllowEdit}
                        isLoadingBtnPrimary={isLoading}
                        handlePrimaryProduct={() =>
                          handlePrimaryProduct(image?.id)
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <Empty description={'No image'} />
          )}
        </div>
      </Col>

      {isAllowEdit && (
        <FormAddButton
          text={null}
          icon={<PlusOutlined />}
          style={{ position: 'absolute', bottom: 19, right: 14 }}
          onClick={handleToggleLinkAssetModal}
        />
      )}

      <WithAbsoluteContainer>
        {isLinkToNewAssetModalOpen && (
          <AddAssetPimModal
            onCloseModal={handleToggleLinkAssetModal}
            addType={addType}
            getImagery={getImagery}
            getMarketingAssets={getMarketingAssets}
          />
        )}
      </WithAbsoluteContainer>
    </Row>
  );
};

export default ProductImageSlider;
