import React from 'react';
import Messages from 'i18n/messages/home';
import { CustomNotification } from 'common/components';

// import { ReactComponent as IconDownloadAsset } from 'common/components/button/svg-icons/ICO_70.svg';
import { UploadOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import { useIntl } from 'react-intl';

import * as api from 'config/axios';
import * as endpoints from 'services/digitalAsset/endpoints';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { notification } from 'antd';

const UpdateMultipleAsset = (props) => {
  const { disabled } = props;
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();
  const renderErrorListMsg = (stringError, title) => {
    return (
      <div>
        <span style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.85)' }}>
          {title}
        </span>
        {stringError?.length > 0 &&
          stringError.map((val, index) => <div key={index}>{val}</div>)}
        {stringError?.length === 0 && (
          <span>{Messages.uploadAssetsTemplateMsgFailed}</span>
        )}
      </div>
    );
  };
  const handleUpdateMultipleAsset = () => {
    const $input = document.createElement('input');
    $input.setAttribute('type', 'file');
    const handleSelectFile = (event) => {
      let file = event.target.files[0];
      if (file) {
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          const payload = {
            file: file,
          };

          return api
            .sendUpload({
              api: endpoints.UPDATE_MULTIPLE_ASSET,
              payload,
            })
            .then((response) => {
              if (response?.data?.isSuccess) {
                CustomNotification.success(
                  intl.formatMessage(Messages.uploadAssetsTemplateMsgSuccess)
                );
                reloadPage();
              } else {
                if (response?.data?.data === null) {
                  CustomNotification.error(response.data?.message);
                  return;
                }
                let stringError = [];
                let arrayError =
                  response?.data?.data?.errorMessages &&
                  response?.data?.data?.errorMessages?.length > 0 &&
                  response?.data?.data?.errorMessages;
                arrayError &&
                  arrayError.length > 0 &&
                  arrayError.forEach((val) => {
                    stringError.push(
                      `Row: ${val?.rowIndex} - Column Name: ${val?.columnName} - ${val?.message}`
                    );
                  });
                notification.error({
                  description: renderErrorListMsg(
                    stringError,
                    response?.data?.message
                  ),
                });
              }
            })
            .catch((error) => {
              CustomNotification.error(
                error || Messages.uploadAssetsTemplateMsgFailed
              );
            });
        } else {
          CustomNotification.error('Only accept xls file');
        }
      }
    };
    $input.onchange = handleSelectFile;
    $input.click();
  };
  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<UploadOutlined />}
      label={Messages.uploadAssetsTemplate}
      onClick={handleUpdateMultipleAsset}
      disabled={disabled}
    />
  );
};

UpdateMultipleAsset.propTypes = {};

export default UpdateMultipleAsset;
