import React from 'react';

import { Menu, Dropdown } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';
import Messages from 'i18n/messages/apl';

import DownloadProductApl from '../../controls/product-apl/DownloadProductApl';
import UploadProductApl from '../../controls/product-apl/UploadProductApl';

const AplDetailTemplate = () => {
  const menuOptions = (
    <Menu>
      <Menu.Item
        key='download-product-apl'
        style={{ backgroundColor: 'inherit' }}
      >
        <DownloadProductApl />
      </Menu.Item>
      <Menu.Item
        key='upload-product-apl'
        style={{ backgroundColor: 'inherit' }}
      >
        <UploadProductApl />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
      <RibbonButton
        icon={<FileTextOutlined />}
        label={Messages.aplDetailTemplate}
      />
    </Dropdown>
  );
};

export default AplDetailTemplate;
