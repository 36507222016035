import React from 'react';

import { Typography } from 'antd';
import classnames from 'classnames';

const { Title } = Typography;

const MinimumRequirementsTitle = ({ title, className, ...restProps }) => {
  return (
    <Title
      ellipsis={{ tooltip: title }}
      level={4}
      className={classnames({
        'minimum-requirement__title': true,
        [className]: !!className,
      })}
      {...restProps}
    >
      {title}
    </Title>
  );
};

export default MinimumRequirementsTitle;
