import * as api from 'config/axios';
import * as endpoints from './endpoints';
import axios from 'axios';

import { getToken } from 'utils/common/session';

/**
 * User login service
 * @param {object} data user login information
 */
export const loginService = (data) => {
  return api.sendPost(endpoints.USER_LOGIN_ENDPOINT, data, {});
};

export const anonLoginService = (params) => {
  return api.sendPost(endpoints.ANON_LOGIN, params);
};

export const getRefreshToken = (params) => {
  return api.sendGet(endpoints.GET_REFRESH_TOKEN, { params });
};

export const revokeToken = (params) => {
  return api.sendGet(endpoints.REVOKE_TOKEN, { params });
};

export const getUserInfo = () => {
  const accessToken = getToken();
  // update axios header
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
  return api.sendGet(endpoints.USER_GET_INFO);
};

export const loginSendOtp = (params) => {
  return api.sendPost(endpoints.LOGIN_SEND_OTP, params);
};

export const loginCheckOtp = (params) => {
  return api.sendPost(endpoints.LOGIN_CHECK_OTP, params);
};

export const unlockAccountSendOtpPhone = (params) => {
  return api.sendPost(endpoints.UNLOCK_ACCOUNT_SEND_OTP_PHONE, params);
};
export const unlockAccountSendOtpEmail = (params) => {
  return api.sendPost(endpoints.UNLOCK_ACCOUNT_SEND_OTP_EMAIL, params);
};

export const unlockAccountCheckOtp = (params) => {
  return api.sendPost(endpoints.UNLOCK_ACCOUNT_CHECK_OTP, params);
};
