import React from 'react';
import { useDispatch } from 'react-redux';

import { FolderAddOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import * as actions from 'redux/global/actions';

import Messages from 'i18n/messages/home';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';

const AddFolderControl = ({ selectedItemList }) => {
  const dispatch = useDispatch();

  const addToFolderClick = () => {
    dispatch(actions.toggleAddToFolder());
  };

  const allowViewFolder = useCheckPermissions([
    { action: ABILITY_ACTION.VIEW, subject: ABILITY_SUBJECT.FOLDER },
  ]);

  return (
    <ButtonSmallIcon
      icon={<FolderAddOutlined />}
      label={Messages.addFolder}
      className='button-item-dropdown'
      disabled={
        !allowViewFolder ||
        disableButton(window.location.pathname) ||
        (selectedItemList && selectedItemList.length === 0)
      }
      onClick={addToFolderClick}
    />
  );
};

const urlFolders = [
  '/folders',
  '/folders/shared',
  '/folders/owned',
  '/favorite/favorite-folders',
];

const disableButton = (pathname) => {
  return urlFolders.includes(pathname);
};

AddFolderControl.propTypes = {};

export default AddFolderControl;
