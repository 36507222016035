import * as constant from 'static/Constants';

export const formatAcceptFileType = (supportTypes) => {
  let acceptFileType;
  if (supportTypes.length > 0) {
    acceptFileType = supportTypes.map((type) => {
      switch (type) {
        case constant.PPT_ACCEPT_TYPE:
          return constant.PPT_ACCEPT_TYPE_NAME;
        case constant.PPTX_ACCEPT_TYPE:
          return constant.PPTX_ACCEPT_TYPE_NAME;
        default:
          return type;
      }
    });
  }
  return acceptFileType;
};
