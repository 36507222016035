import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getQaSpecWorkflowTodoList = (params) => {
  return api.sendPost(endpoints.GET_QA_SPEC_WORKFLOW_GRID, params);
};

export const acceptOrRejectWorkflowTodo = (params) => {
  return api.sendPost(endpoints.APPROVE_OR_REJECT_QA_SPEC_WORKFLOW, params);
};

export const submitQaSpecWorkflow = (params) => {
  return api.sendPost(endpoints.SUBMIT_QA_SPEC_WORKFLOW, params);
};

export const unlockQaSpecWorkflow = (params) => {
  return api.sendPost(endpoints.UNLOCK_QA_SPEC_WORKFLOW, params);
};
