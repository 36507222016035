import React from 'react';
import './VideoCallRecommend.less';

const VideoCallRecommend = (props) => {
  if (navigator.userAgent.indexOf('Firefox') === -1) return null;
  return (
    <div className='video-call-recommend'>
      Call meeting works better on Chrome
    </div>
  );
};

export default VideoCallRecommend;
