import { put } from 'redux-saga/effects';

import * as types from './constants';
import { takeLatest, delay } from 'redux-saga/effects';
import * as actions from './actions';
import { DEFAULT_WAIT } from 'static/Constants';

/**
 * Ribbon button view has updated
 * @param {object} payload
 */
export function* ribbonSaga(payload) {
  try {
    yield delay(DEFAULT_WAIT);
    yield put(actions.changeSuccess(payload.view));
  } catch (error) {
    let message = error;
    if (typeof error === 'object') message = error.message;
    console.log(message);
  }
}

export default function* watchRibbonView() {
  yield takeLatest(types.CHANGE_RIBBON_VIEW, ribbonSaga);
}
