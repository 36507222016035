import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { Typography, Space } from 'antd';
import { RoleSectionWrapper } from '../';
import {
  FormDeleteButton,
  FormSaveButton,
  FormCancelButton,
  FormCreateButton,
  dialogFunction,
  CustomNotification,
} from 'common/components';

import * as roleActions from 'pages/role/controllers/actions';

import * as rolesServices from 'services/roles-security';

import { apiHandler } from 'utils/api';
import { forwardTo } from 'utils/common/route';
import { deleteObjectField } from 'utils';
import useCloseDetail from 'hooks/useCloseDetail';

import './styles.less';

const { Text } = Typography;

const RoleEditControl = (props) => {
  const {
    isChanged,
    selectedRole,
    memberIdParams,
    memberId,
    formRole,
    isCreateNew,
    validateFormRole,
    refEnableAutoScroll,
  } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const { goBack, closeDetailStack } = useCloseDetail();

  const isInUserManagementCloseDetailStack =
    closeDetailStack?.name === 'userMaintenance';

  const confirmDeleteRole = () => {
    const handleDeleteRole = async () => {
      const params = { roleId: selectedRole?.id };

      const successCallback = () => {
        // dispatch(
        //   roleActions.getRoleList({ memberId: memberIdParams || memberId })
        // );
        // dispatch(roleActions.getRolePermissionListSuccess([]));
        // dispatch(roleActions.setSelectedRole(null));
        confirmCancelRoleEdit(true);
      };

      await apiHandler({
        service: rolesServices.deleteRole,
        params,
        successMessage: 'Delete role successfully',
        errorMessage: 'Failed to delete role',
        successCallback,
      });
    };

    dialogFunction({
      type: 'warn',
      content: (
        <div>
          <h4> Role: {selectedRole?.displayName}</h4>
          <Text>Do you want to delete the selected role?</Text>
        </div>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleDeleteRole,
    });
  };

  const confirmCancelRoleEdit = (isDelete = false) => {
    const handleCancelRoleEdit = async () => {
      let redirectLink = '';

      if (memberIdParams) {
        redirectLink = `/security/member/${memberIdParams}`;
      } else {
        redirectLink = `/security`;
      }

      // * click cancel without delete role
      if (!isDelete) {
        //* if role in the list (not in creating new role mode)
        if (!isCreateNew) {
          refEnableAutoScroll.current = true;
        } else {
          //*in create new role mode
          dispatch(roleActions.setSelectedRole(null));
        }
      } else {
        //* click cancel when delete role
        dispatch(roleActions.setSelectedRole(null));
      }
      //* if click cancel when jump in from user management, go back to previous view like clicking Close Detail Button
      if (isInUserManagementCloseDetailStack) {
        goBack();
        return;
      }
      history.replace(redirectLink);
    };

    isChanged
      ? dialogFunction({
          type: 'warn',
          content:
            'Do you want to cancel your edit content and go back to role management view?',
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: handleCancelRoleEdit,
        })
      : handleCancelRoleEdit();
  };

  const confirmCreateNewRole = () => {
    const handleStartCreateNewRole = () => {
      let redirectLink = '';

      if (memberIdParams) {
        redirectLink = `/security/role/new/member/${memberIdParams}/edit`;
      } else {
        redirectLink = `/security/role/new/edit`;
      }
      forwardTo(redirectLink);
    };

    isChanged
      ? dialogFunction({
          type: 'warn',
          content:
            'Do you want to cancel your edit content and start to create new role',
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: handleStartCreateNewRole,
        })
      : handleStartCreateNewRole();
  };

  const confirmSaveRoleEdit = async () => {
    const handleSaveRoleEdit = async () => {
      let params = {
        roleId: selectedRole?.id,
        displayName: formRole?.values?.displayName.trim(),
        description: formRole?.values?.description.trim(),
        permissions: formRole?.values?.permissions.map(
          (permit) => permit?.permissionName
        ),
        memberId: memberIdParams || memberId,
      };

      isCreateNew && deleteObjectField(params, ['roleId']);

      const successMessage = isCreateNew
        ? 'Create new role successfully'
        : 'Saved successfully!';

      const errorMessage = isCreateNew
        ? 'Failed to create new role'
        : 'Failed to save role';

      const saveRoleCallback = (data) => {
        const roleId = selectedRole?.id;

        if (roleId === 'new') {
          const responseRoleId = data?.roleId;
          //* after create role
          let redirectLink = '';

          dispatch(
            roleActions.getRoleList({ memberId: memberIdParams || memberId })
          );

          if (memberIdParams) {
            redirectLink = `/security/role/${responseRoleId}/member/${memberIdParams}/edit`;
          } else {
            redirectLink = `/security/role/${responseRoleId}/edit`;
          }
          history.replace(redirectLink);
        } else {
          //*after edit role
          dispatch(roleActions.getRolePermissionList({ roleId }));
          dispatch(
            roleActions.getRoleList({ memberId: memberIdParams || memberId })
          );
        }
      };

      await apiHandler({
        service: rolesServices.editRole,
        params,
        successMessage,
        errorMessage,
        successCallback: saveRoleCallback,
      });
    };

    const errors = await validateFormRole();
    if (!isEmpty(errors)) {
      CustomNotification.error(`Please check role's name error`);
      return;
    }

    dialogFunction({
      type: 'warn',
      content: (
        <div>
          <h4>
            {isCreateNew
              ? `New role: ${formRole?.values?.displayName}`
              : `Role: ${selectedRole?.displayName}`}
          </h4>
          <Text>
            {isCreateNew
              ? 'Do you want to create this new role?'
              : 'Do you want to save your change?'}
          </Text>
        </div>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleSaveRoleEdit,
    });
  };

  return (
    <RoleSectionWrapper
      className='role-edit-control-section__wrap'
      style={{ height: '100%' }}
    >
      <div className='role-edit-control-section' style={{ height: '100%' }}>
        <Text className='role-edit-control-section__guide'>
          Save change to one role only at a time. Please save your change before
          edit another role
        </Text>
        <Space className='role-edit-control-section__btns'>
          <FormCreateButton
            text='Create a new role'
            disabled={!selectedRole || isCreateNew}
            onClick={confirmCreateNewRole}
          />
          <FormDeleteButton
            text='Delete the role'
            disabled={
              !selectedRole ||
              selectedRole?.isStandardRole ||
              selectedRole?.isSuperRole ||
              isCreateNew
            }
            onClick={confirmDeleteRole}
          />
          <FormCancelButton onClick={() => confirmCancelRoleEdit()} />
          <FormSaveButton
            disabled={
              !isChanged ||
              !selectedRole ||
              selectedRole?.isStandardRole ||
              selectedRole?.isSuperRole
            }
            onClick={confirmSaveRoleEdit}
          />
        </Space>
      </div>
    </RoleSectionWrapper>
  );
};

export default RoleEditControl;
