import React from 'react';

import { UploadOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import { forwardTo } from 'utils/common/route';

const UploadFormBtn = (props) => {
  const { disabled } = props;

  const onClickUploadBtn = () => {
    forwardTo('/maintenance/form/upload');
  };

  return (
    <ButtonSmallIcon
      icon={<UploadOutlined />}
      label={'Create'}
      onClick={onClickUploadBtn}
      disabled={disabled}
    />
  );
};

export default UploadFormBtn;
