import { useQuery } from '@tanstack/react-query';

const keys = {
  gdsnPartyInfo: (memberId) => [
    'MemberProfile',
    'get-gdsn-party-info',
    { params: { memberId: parseInt(memberId) } },
  ],
};

/**
 ** GET - api/memberProfile/get-gdsn-party-info
 */
export const useQueryGdsnPartyInfo = ({ memberId, ...options }) => {
  return useQuery({
    queryKey: keys.gdsnPartyInfo(memberId),
    ...options,
    staleTime: 0,
  });
};
