import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import MemberManagementEditModal from '../../../modal/member-management/MemberManagementEditModal';

import gridSelectors from 'common/components/grid-view/controllers/selectors';
import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';

import Messages from 'i18n/messages/maintenance';

const MemberManagementEdit = () => {
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );

  const [visibleEditModal, setVisibleEditModal] = useState(false);

  const onClickHandler = () => {
    isMemberGridInfoView && setVisibleEditModal(true);
  };

  const disabled = !isMemberGridInfoView || selectedItemList?.length !== 1;

  return (
    <>
      <RibbonButton
        style={{ width: 52 }}
        icon={<FormOutlined />}
        label={Messages.editMember}
        onClick={onClickHandler}
        disabled={disabled}
      />
      {isMemberGridInfoView ? (
        <MemberManagementEditModal
          visible={visibleEditModal}
          setVisible={setVisibleEditModal}
          selectedItemList={selectedItemList[0]}
        />
      ) : null}
    </>
  );
};

export default MemberManagementEdit;
