import produce from 'immer';
import * as types from './constants';

export const initialState = {
  linkAssetsState: {
    status: 'idle',
    pageNumber: 1,
    pageSize: 4,
    assetsSearch: [],
    totalItems: 0,
    error: null,
  },
  assetsGrid: {
    status: 'idle',
    error: null,
    parentGridAssets: [],
    childGridAssets: [],
    statusUpdate: 'idle',
    errorUpdate: null,
    /*
     * This is the container hold assets that delete and addition in initial grid asset.
     * To know what asset in initial grid asset is change.
     */
    additionParentAssets: [],
    additionChildAssets: [],
    deletedParentAssets: [],
    deletedChildAssets: [],
  },
};

/* eslint-disable default-case, no-param-reassign */
const assetReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SEARCH_ITEMS_LINK_ASSETS:
      case types.FETCH_ASSETS_CHANGE_PAGINATION:
        draft['linkAssetsState'].status = 'loading';
        draft['linkAssetsState'].pageNumber = action.pageIndex;
        draft['linkAssetsState'].pageSize = action.pageSize;
        draft['linkAssetsState'].assetsSearch = [];
        draft['assetsGrid'].statusUpdate = 'idle';
        break;

      case types.SEARCH_ITEMS_LINK_ASSETS_SUCCESS:
      case types.FETCH_ASSETS_CHANGE_PAGINATION_SUCCESS:
        draft['linkAssetsState'].status = 'success';
        draft['linkAssetsState'].assetsSearch = action.assets;
        draft['linkAssetsState'].totalItems = action.total;
        draft['linkAssetsState'].error = null;
        draft['assetsGrid'].statusUpdate = 'idle';
        break;

      case types.SEARCH_ITEMS_LINK_ASSETS_FAIL:
      case types.FETCH_ASSETS_CHANGE_PAGINATION_FAIL:
        draft['assetsGrid'].status = 'loading';
        draft['linkAssetsState'].status = 'error';
        draft['linkAssetsState'].error = action.error;
        draft['assetsGrid'].statusUpdate = 'idle';

        break;

      case types.FETCH_ASSETS_GRID:
        draft['assetsGrid'].statusUpdate = 'idle';
        break;

      case types.FETCH_ASSETS_GRID_SUCCESS:
        draft['assetsGrid'].status = 'success';
        draft['assetsGrid'].statusUpdate = 'idle';
        draft['assetsGrid'].parentGridAssets = action.payload.containViewModel;
        draft['assetsGrid'].childGridAssets =
          action.payload.includedInViewModel;
        draft['assetsGrid'].additionParentAssets = [];
        draft['assetsGrid'].additionChildAssets = [];
        draft['assetsGrid'].deletedParentAssets = [];
        draft['assetsGrid'].deletedChildAssets = [];

        break;

      case types.FETCH_ASSETS_GRID_ERROR:
        draft['assetsGrid'].status = 'error';
        draft['assetsGrid'].error = action.error;
        draft['assetsGrid'].additionParentAssets = [];
        draft['assetsGrid'].additionChildAssets = [];
        draft['assetsGrid'].deletedParentAssets = [];
        draft['assetsGrid'].deletedChildAssets = [];
        break;

      case types.DRAG_DROP_ASSETS:
        draft['linkAssetsState'].assetsSearch = action.payload.assets;
        break;

      case types.MOVE_TO_PARENT_GRID:
        draft['assetsGrid'].parentGridAssets.push(action.payload.movedAsset);
        draft['assetsGrid'].additionParentAssets.push(
          action.payload.movedAsset
        );
        break;

      case types.MOVE_TO_CHILD_GRID:
        draft['assetsGrid'].childGridAssets.push(action.payload.movedAsset);
        draft['assetsGrid'].additionChildAssets.push(action.payload.movedAsset);
        break;

      case types.DELETE_ASSET_IN_PARENT: {
        const index = draft['assetsGrid'].parentGridAssets.findIndex(
          (asset) => asset.assetId === action.payload.id
        );
        const indexAddition = draft[
          'assetsGrid'
        ].additionParentAssets.findIndex(
          (asset) => asset.assetId === action.payload.id
        );
        let asset = draft['assetsGrid'].parentGridAssets.find(
          (asset) => asset.assetId === action.payload.id
        );
        asset = { ...asset, associationType: 1, state: 'Deleted' };

        // not find asset in additionAsset
        if (indexAddition === -1) {
          draft['assetsGrid'].deletedParentAssets.push(asset);
        }

        if (indexAddition !== -1) {
          draft['assetsGrid'].additionParentAssets.splice(indexAddition, 1);
        }

        draft['assetsGrid'].parentGridAssets.splice(index, 1);
        break;
      }

      case types.DELETE_ASSET_IN_CHILD: {
        const index = draft['assetsGrid'].childGridAssets.findIndex(
          (asset) => asset.assetId === action.payload.id
        );
        const indexAddition = draft['assetsGrid'].additionChildAssets.findIndex(
          (asset) => asset.assetId === action.payload.id
        );
        let asset = draft['assetsGrid'].childGridAssets.find(
          (asset) => asset.assetId === action.payload.id
        );
        asset = { ...asset, associationType: 2, state: 'Deleted' };

        if (indexAddition === -1) {
          draft['assetsGrid'].deletedChildAssets.push(asset);
        }

        if (indexAddition !== -1) {
          draft['assetsGrid'].additionChildAssets.splice(indexAddition, 1);
        }

        draft['assetsGrid'].childGridAssets.splice(index, 1);
        break;
      }

      case types.UPDATE_LINK_ASSET:
        // draft['linkAssetsState'].status = 'success';
        draft['assetsGrid'].statusUpdate = 'loading';
        break;

      case types.UPDATE_LINK_ASSET_SUCCESS:
        draft['assetsGrid'].statusUpdate = 'success';
        draft['assetsGrid'].additionParentAssets = [];
        draft['assetsGrid'].additionChildAssets = [];
        draft['assetsGrid'].deletedParentAssets = [];
        draft['assetsGrid'].deletedChildAssets = [];
        break;

      case types.UPDATE_LINK_ASSET_FAIL:
        draft['assetsGrid'].statusUpdate = 'error';
        draft['assetsGrid'].errorUpdate = action.error;
        draft['assetsGrid'].additionParentAssets = [];
        draft['assetsGrid'].additionChildAssets = [];
        draft['assetsGrid'].deletedParentAssets = [];
        draft['assetsGrid'].deletedChildAssets = [];
        break;

      case types.RESET_LINK_ASSET:
        draft['linkAssetsState'].assetsSearch = [];
        draft['linkAssetsState'].status = 'idle';
        draft['assetsGrid'].statusUpdate = 'idle';
        draft['assetsGrid'].additionParentAssets = [];
        draft['assetsGrid'].additionChildAssets = [];
        draft['assetsGrid'].deletedParentAssets = [];
        draft['assetsGrid'].deletedChildAssets = [];
        break;
    }
  });

export default assetReducer;
