import React, { useRef } from 'react';

import { Image, Skeleton } from 'antd';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';

const ChatMessageFile = (props) => {
  const imageRef = useRef(null);
  const { image, handleDownloadFile, fallback } = props;

  const onClickPreview = () => {
    const image = imageRef.current.querySelector('.ant-image');

    image.click();
  };

  const onClickDownload = () => {
    handleDownloadFile(image.fileUrl);
  };

  return (
    <div className='chat-thread__image-container' ref={imageRef}>
      <Image
        src={image?.fileUrl}
        width={60}
        height={60}
        placeholder={<PlaceholderImage />}
        fallback={fallback}
      />
      <div className='chat-thread__image-mask'>
        <div className='chat-thread__image-action'>
          <EyeOutlined onClick={onClickPreview} />
          <DownloadOutlined onClick={onClickDownload} />
        </div>
      </div>
    </div>
  );
};

const PlaceholderImage = () => {
  return <Skeleton.Avatar size={60} shape={'square'} />;
};

export default ChatMessageFile;
