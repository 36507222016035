import React from 'react';

import { Divider } from 'antd';

import { Form } from 'common/components';
import SupplementNutrientItem from './SupplementNutrientItem';

const SupplementNutrientList = (props) => {
  const form = Form.useFormInstance();

  const { fieldName, remove, mode, setMode } = props;

  const nutrientList = Form.useWatch(fieldName, form);

  const getNutrientRendererList = () => {
    return nutrientList?.map((nutrient, index) => {
      return {
        ...nutrient,
        isEdit: mode === `edit-${index}`,
      };
    });
  };

  const nutrientRendererList = getNutrientRendererList();

  return nutrientRendererList?.length ? (
    <>
      {nutrientRendererList?.map((nutrientItem, index) => {
        return (
          <SupplementNutrientItem
            fieldName={fieldName}
            data={nutrientItem}
            setMode={setMode}
            remove={remove}
            key={index}
            index={index}
          />
        );
      })}
      <Divider style={{ margin: '8px 0px 0px' }} />
    </>
  ) : null;
};

export default SupplementNutrientList;
