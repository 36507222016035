import React from 'react';

import { Divider, Col } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import { ViewLayout, AdvanceStack, OtherLayout } from './components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import { DownloadMappingProductSheets } from './components/controls/product-full/ProductSheets';

const ProductFullViewSheets = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <RibbonDivider />
      <DownloadMappingProductSheets />
      <RibbonDivider />
      <AdvanceStack isDisabled={true} />
      <Col flex='auto' />
      <OtherLayout ribbonType='products' isHaveAddOpenItem={false} />
    </RibbonBar>
  );
};

export default ProductFullViewSheets;
