import { CustomNotification, dialogFunction } from 'common/components';

import { useMutation } from '@tanstack/react-query';
import { deleteTicketingSystem } from 'services/ticketingSystem';

export const useDeleteTicket = () => {
  const deleteTicketMutation = useMutation({
    mutationFn: deleteTicketingSystem,
    onSuccess: async (result) => {
      if (result?.isSuccess) {
        CustomNotification.success('Delete ticket successfully.');
      } else {
        CustomNotification.error(result?.message || 'Something went wrong!');
      }
    },
    onError: () => {
      CustomNotification.error({ message: 'Something went wrong!' });
    },
  });

  return { deleteTicketMutation };
};
