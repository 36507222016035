import React, { useState } from 'react';
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Input, Row, Col } from 'antd';

import './EmailSearch.less';

function EmailSearch({ value, onChange }) {
  const handleChange = (event) => {
    onChange && onChange(event);
  };

  return (
    <Row style={{ marginTop: 10 }}>
      <Col xs={24}>
        <div className='email-search-input'>
          <Input
            style={{ outline: 'none' }}
            allowClear
            value={value}
            onChange={handleChange}
            prefix={<SearchOutlined />}
            // placeholder='Search communication'
          />
        </div>
      </Col>
    </Row>
  );
}

export default EmailSearch;
