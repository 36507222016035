/**
 * The global state selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectChat = (state) => (state && state.chat) || initialState;

const selectCommunicationDetail = () =>
  createSelector(selectChat, (subState) => subState.communicationDetail);

const selectShowMinimizedThreadList = () =>
  createSelector(selectChat, (subState) => subState.showMinimizedThreadList);

const selectToggleChatBox = () =>
  createSelector(selectChat, (subState) => subState.toggleChatBox);

const selectMinimizedChatThreadList = () =>
  createSelector(selectChat, (subState) => subState.minimizedChatThreadList);

const selectCommunicationList = () =>
  createSelector(selectChat, (subState) => subState.communicationList);

const selectCommunicationListLoading = () =>
  createSelector(selectChat, (subState) => subState.communicationListLoading);

const selectThreadListForSupporter = () =>
  createSelector(selectChat, (subState) => subState.threadList);

const selectChatContentDetail = () =>
  createSelector(selectChat, (subState) => subState.chatContentDetail);
const selectThreadMessages = () =>
  createSelector(selectChat, (subState) => subState.chatThreadMessages);

const selectThreadMessagesLoading = () =>
  createSelector(selectChat, (subState) => subState.chatThreadMessagesLoading);

const selectNewMsgReceived = () =>
  createSelector(selectChat, (subState) => subState.newMsg);

const selectThreadInfo = () =>
  createSelector(selectChat, (subState) => subState.threadInfo);

const selectThreadInfoError = () =>
  createSelector(selectChat, (subState) => subState.threadInfoError);

const selectIsShowWarningModal = () =>
  createSelector(selectChat, (subState) => subState.isShowWarningModal);

const selectThreadId = () =>
  createSelector(selectChat, (subState) => subState.threadInfoId);
const selectStatusThreadInfo = () =>
  createSelector(selectChat, (subState) => subState.statusThreadInfo);

const selectToggleNewMessage = () =>
  createSelector(selectChat, (subState) => subState.toggleNewMessage);

const selectAccountSend = () =>
  createSelector(selectChat, (subState) => subState.accountSend);

const selectUnreadMessages = () =>
  createSelector(selectChat, (subState) => subState.unreadMessages);

const selectStatusThreadMsg = () =>
  createSelector(selectChat, (subState) => subState.statusThreadMsg);

const selectActiveThreadInfo = () =>
  createSelector(selectChat, (subState) => subState.activeThreadInfo);

const selectSelectedSearchResult = () =>
  createSelector(selectChat, (subState) => subState.selectedSearchResult);

const selectFinishLoadDown = () =>
  createSelector(selectChat, (subState) => subState.isFinishLoadDown);

const selectSelectedSearchResultPosition = () =>
  createSelector(
    selectChat,
    (subState) => subState.selectedSearchResultPosition
  );

const selectLastMessage = () =>
  createSelector(selectChat, (subState) => subState.lastMessage);

const selectIsScrollChatThreadBottom = () =>
  createSelector(selectChat, (subState) => subState.isScrollChatThreadBottom);

const selectIsChatSpinningOn = () =>
  createSelector(selectChat, (subState) => subState.isChatSpinningOn);

const selectChatView = () =>
  createSelector(selectChat, (subState) => subState.chatView);

const selectIsFocusChat = () =>
  createSelector(selectChat, (subState) => subState.isFocusChat);

export {
  selectCommunicationDetail,
  selectCommunicationList,
  selectCommunicationListLoading,
  selectChatContentDetail,
  selectShowMinimizedThreadList,
  selectToggleChatBox,
  selectMinimizedChatThreadList,
  selectNewMsgReceived,
  selectThreadListForSupporter,
  selectThreadInfo,
  selectThreadInfoError,
  selectIsShowWarningModal,
  selectStatusThreadInfo,
  selectThreadMessages,
  selectThreadMessagesLoading,
  selectToggleNewMessage,
  selectAccountSend,
  selectUnreadMessages,
  selectThreadId,
  selectStatusThreadMsg,
  selectActiveThreadInfo,
  selectSelectedSearchResult,
  selectSelectedSearchResultPosition,
  selectFinishLoadDown,
  selectLastMessage,
  selectIsScrollChatThreadBottom,
  selectIsChatSpinningOn,
  selectChatView,
  selectIsFocusChat,
};
