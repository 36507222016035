import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { InfoWithLabel } from 'common/components';
import { Images } from 'config/assets';
import * as _ from 'lodash';

const CertificationItem = ({ certificationData }) => {
  const data = [
    {
      label: 'Certification Name',
      info: certificationData.propertyDisplayName,
    },
    {
      label: 'Certification Type',
      info: certificationData?.type,
    },
    {
      label: 'Description',
      info: certificationData.propertyDescription,
    },
  ];

  const infoColProps = {
    infoTextStyle: { maxWidth: '250px' },
    infoColProps: {
      flex: 'auto',
    },
    labelColProps: {
      flex: '140px',
      style: {
        padding: 0,
      },
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  return (
    <Row className='certification-item-render' wrap={false} align='middle'>
      <Col className='certification-item-render__item' flex='none'>
        <img
          className='certification-item-render__item-img'
          src={
            !_.isEmpty(certificationData?.certImage)
              ? certificationData?.certImage
              : Images.RIVIR_LOGO_DEFAULT
          }
          alt=''
        />
      </Col>
      <Col flex='auto' style={{ height: 120 }}>
        {data?.map((item, index) => {
          return (
            <InfoWithLabel
              className='certification__info-container'
              info={item.info}
              label={item.label}
              {...infoColProps}
              key={index}
            />
          );
        })}
      </Col>
    </Row>
  );
};

CertificationItem.propTypes = {};

export default CertificationItem;
