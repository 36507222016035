export const addLockValueToManualLoop = (exportedFormManualDetail) => {
  let nextExportedFormManualDetail = { ...exportedFormManualDetail };
  let nextNoLoopManualDataPointList = [];
  const isSingleExport = exportedFormManualDetail?.exportIntoASingleFile;

  nextExportedFormManualDetail.manualFields =
    nextExportedFormManualDetail.manualFields.map((manualFieldItem) => {
      let nextManualData = manualFieldItem?.manualData;
      nextManualData = nextManualData.map((manualDataItem) => {
        if (manualDataItem?.isLoop) {
          return { ...manualDataItem, lock: false };
        } else {
          if (
            !nextNoLoopManualDataPointList.find(
              (noLoopDataPointItem) =>
                noLoopDataPointItem?.dataPointId === manualDataItem?.dataPointId
            )
          )
            nextNoLoopManualDataPointList.push(manualDataItem);

          return isSingleExport
            ? { ...manualDataItem, lock: true }
            : { ...manualDataItem, lock: false };
        }
      });

      return { ...manualFieldItem, manualData: nextManualData };
    });

  nextExportedFormManualDetail = {
    ...nextExportedFormManualDetail,
    noLoopManualDataPointList: nextNoLoopManualDataPointList,
  };

  return nextExportedFormManualDetail;
};

export const initExportManualForm = ({ manualFields }) => {
  if (!manualFields) return {};
  let initFormValue = {};

  manualFields.forEach((manualFieldItem) => {
    const { entityId, manualData } = manualFieldItem;

    manualData.forEach((manualDataItem) => {
      const { dataPointId, lock, isLoop } = manualDataItem;

      initFormValue[entityId][dataPointId].value = '';
      initFormValue[entityId][dataPointId].isLoop = isLoop;
      if (isLoop) {
        initFormValue[entityId]['lock'] = true;
      }
    });
  });
};
