import { useState, useEffect, useCallback } from 'react';
// import PerfectScrollbar from 'perfect-scrollbar';
// import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';

/**
 * todo: this feature might need more improment in future
 * ? If you guys see any serious bug please let me known
 * @author Duy Nguyen
 * @param {Array<string>} selectorNames
 * ? string could be id or classname of element
 * @param {Object} scrollOptions
 * @param {Object} dependencies - //! what make hook to callback custom scroll
 */

function useCustomScroll(selectorName, dependencies) {
  const [scrolls, setScrolls] = useState([]);

  const $ = useCallback((selectorName) => {
    if (!selectorName) return;
    if (selectorName.charAt(0) === '.') {
      return document.querySelectorAll(selectorName);
    }
    if (selectorName.charAt(0) === '#') {
      return document.querySelector(selectorName);
    }
  }, []);

  useEffect(
    () => {
      const $elements = $(selectorName);
      if ($elements?.length) {
        $elements.forEach(($eachElement, __) => {
          $eachElement.classList.add('scroller');
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dependencies]
  );

  return scrolls;
}

export default useCustomScroll;
