import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Dropdown, Menu } from 'antd';
import { FolderOutlined, DownloadOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import CreateFolder from '../../controls/folders/CreateFolder';
import DeleteFolders from '../../controls/folders/DeleteFolders';
import CopyFolder from '../../controls/folders/CopyFolder';
import MoveFolder from '../../controls/folders/MoveFolder';
import EditFolder from '../../controls/folders/EditFolder';
import BulkEditProduct from '../../controls/product/BulkEditProduct';
import CopyFolderModal from '../../controls/folders/CopyFolderModal';
import MoveFolderModal from '../../controls/folders/MoveFolderModal';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import { useCheckPermissions } from 'hooks/useCheckPermissions';
import useCheckIsFolderOwner from 'hooks/useCheckIsFolderOwner';

import { useFolderByLevel } from 'hooks/useFolderByLevel';

import { usePermissionEditSharedProduct, useCheckIsSuperAdmin } from 'hooks';
import { Can } from 'context/Can';
import {
  ABILITY_ACTION,
  ABILITY_SUBJECT,
  PERMISSION_EDIT_PRODUCTS,
} from 'static/Permission';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import './FolderSection.less';
import { ROOT_FOLDER } from 'pages/folders/controllers/constants';

const { SubMenu } = Menu;

const FolderSection = (props) => {
  const { pathname } = useLocation();

  const { selectedItems } = props;

  const [copyFolderModalVisible, setCopyFolderVisible] = useState(false);
  const [moveFolderModalVisible, setMoveFolderVisible] = useState(false);

  const checkDisabledBulkEditProductBtn = () => {
    const isAllowActionsPim =
      isSuperAdmin ||
      hasPermissionEditProducts ||
      hasPermissionEditSharedProduct;

    return !isAllowActionsPim;
  };

  const checkDisableExportBulkProduct = () => {
    const isFolderContainProduct =
      selectedFolders.length > 0 &&
      selectedFolders?.every((folderItem) => {
        return !!folderItem.productCount;
      });
    const isProductsSelected = selectedProductIds.length > 0;

    const isValidItemSelected = isFolderContainProduct || isProductsSelected;

    //* checking for enable cases is easier
    const shouldEnable = isValidItemSelected;

    return !shouldEnable;
  };

  const checkDisableCopyFolder = () => {
    //* only support coping single folder
    if (selectedFolders?.length !== 1) return true;

    return false;
  };

  const checkDisableMoveFolder = () => {
    const disabled = selectedFolders.some((folder) => {
      const isSharedFolder = !folder.isOwner;
      const isEditable = folder.editable;

      //* disable when there is a folder that shared and editable = false
      return isSharedFolder && !isEditable;
    });

    return disabled;
  };

  const closeCopyModal = () => {
    setCopyFolderVisible(false);
  };

  const closeMoveModal = () => {
    setMoveFolderVisible(false);
  };

  const getSelectedFolders = () => {
    return selectedItems.filter((item) => item.type.toLowerCase() === 'folder');
  };
  const getSelectedFolderIds = () => {
    return selectedFolders.map((item) => item.id);
  };
  const getSelectedProductIds = () => {
    return selectedItems.reduce((accumulator, currentItem) => {
      const isProduct = currentItem.type.toLowerCase() === 'product';

      if (!isProduct) return accumulator;

      return [...accumulator, currentItem.id];
    }, []);
  };

  const selectedFolders = getSelectedFolders();
  const selectedFolder = selectedItems?.length === 1 && selectedFolders[0];
  const selectedFolderIds = getSelectedFolderIds();
  const selectedProductIds = getSelectedProductIds();

  const isOwner = useCheckIsFolderOwner();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const hasPermissionEditSharedProduct = usePermissionEditSharedProduct();
  const hasPermissionEditProducts = useCheckPermissions(
    PERMISSION_EDIT_PRODUCTS
  );

  const { currentBreadcrumb } = useFolderByLevel();

  const destinationFolder = selectedFolder
    ? selectedFolder
    : currentBreadcrumb?.id !== ROOT_FOLDER
    ? currentBreadcrumb
    : null;

  const isDisabledBulkEditProductBtn = checkDisabledBulkEditProductBtn();
  const isDisabledExportBulkEditProduct = checkDisableExportBulkProduct();
  const isDisabledCopyFolder = checkDisableCopyFolder();
  const isDisabledMoveFolder = checkDisableMoveFolder();

  const menuOptions = (
    <Menu className='folder-section__menu'>
      <Menu.Item key='editFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <EditFolder disabled={!allowed} selectedFolders={selectedFolders} />
          )}
        </Can>
      </Menu.Item>

      <Menu.Item key='favorite' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => {
            return (
              <CreateFolder
                disabled={!allowed}
                pathname={pathname}
                parentId={destinationFolder?.id}
                destinationFolder={destinationFolder}
              />
            );
          }}
        </Can>
      </Menu.Item>

      <Menu.Item key='addToFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.DELETE} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <DeleteFolders
              disabled={!allowed}
              pathname={pathname}
              isOwner={isOwner}
              selectedItems={selectedItems}
            />
          )}
        </Can>
      </Menu.Item>

      <Menu.Item key='copyFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.COPY} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <CopyFolder
              disabled={!allowed || isDisabledCopyFolder}
              pathname={pathname}
              selectedFolders={selectedFolders}
              onClick={() => setCopyFolderVisible(true)}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item key='moveFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.COPY} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <MoveFolder
              disabled={!allowed || isDisabledMoveFolder}
              pathname={pathname}
              selectedItems={selectedItems}
              onClick={() => setMoveFolderVisible(true)}
            />
          )}
        </Can>
      </Menu.Item>
      <SubMenu
        key={'bulk-edit-product'}
        style={{ backgroundColor: 'inherit' }}
        disabled={isDisabledBulkEditProductBtn}
        title={
          <RibbonButton
            size='small'
            className='button-item-dropdown'
            icon={<DownloadOutlined />}
            disabled={isDisabledBulkEditProductBtn}
            label={messagesProduct.bulkEditProduct}
          />
        }
      >
        <BulkEditProduct
          disabled={isDisabledBulkEditProductBtn}
          disabledExport={isDisabledExportBulkEditProduct}
          selectedFolders={selectedFolderIds}
          selectedProducts={selectedProductIds}
        />
      </SubMenu>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
        <RibbonButton
          icon={<FolderOutlined />}
          label={Messages.folderActions}
        />
      </Dropdown>
      <CopyFolderModal
        visible={copyFolderModalVisible}
        closeModal={closeCopyModal}
        selectedFolder={selectedFolder}
      />
      <MoveFolderModal
        visible={moveFolderModalVisible}
        closeModal={closeMoveModal}
        selectedItems={selectedItems}
        currentFolderId={currentBreadcrumb?.id}
      />
    </>
  );
};

export default FolderSection;
