import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Avatar, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const ContactCardUserInfo = (props) => {
  const { fullName, image, position, address, country, city, state } = props;

  const cityString = city ? `, ${city}` : '';
  const stateString = state ? `, ${state}` : '';
  const countryString = country && country !== 'USA' ? `, ${country}` : '';

  const addressString = `${address}${cityString}${stateString}${countryString}`;

  return (
    <Row className='contact-card__user-info' align='middle' gutter={30}>
      <Col flex={0}>
        <Avatar size={120} icon={<UserOutlined />} src={image} />
      </Col>
      <Col flex={1}>
        <Title
          className='contact-card__user-name'
          level={3}
          style={{ color: '#fff', marginBottom: 6 }}
        >
          {fullName}
        </Title>
        {addressString && (
          <Paragraph style={{ color: '#fff', marginBottom: 0 }}>
            {addressString}
          </Paragraph>
        )}
      </Col>
    </Row>
  );
};

ContactCardUserInfo.propTypes = {
  fullName: PropTypes.string,
  image: PropTypes.string,
  position: PropTypes.string,
};

ContactCardUserInfo.defaultProps = {
  fullName: '',
  address: '',
  country: '',
  city: '',
  state: '',
};

export default ContactCardUserInfo;
