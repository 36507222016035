import { FilterOutlined } from '@ant-design/icons';

export const useDisplayFilterButton = (props) => {
  const { defaultButton, filterOptions, filters } = props;

  const defaultFilter = defaultButton || {
    label: 'Filter',
    icon: <FilterOutlined />,
  };

  const getDisplayingFilter = () => {
    const filterData = filterOptions.find((filterItem) => {
      return filterItem.value === filters[0];
    });

    return filterData || defaultFilter;
  };

  const getLabelButton = (label) => {
    if (filters.length >= 2) {
      return `${label} (+${filters.length - 1})`;
    }
    return label;
  };

  const displayingFilter = filters?.length
    ? getDisplayingFilter()
    : defaultFilter;

  const Icon = displayingFilter?.icon;

  const isToggle = !!filters.length;

  return {
    label: getLabelButton(displayingFilter.label),
    icon: Icon,
    toggle: isToggle,
  };
};
