import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Space } from 'antd';

import Messages from 'i18n/messages/messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as contactUtil from 'utils/contact';
import { ProfileImage, ProfileAddress } from 'common/components';
import { UserName, UserContactInfo, UserSocialLink } from '../index';
import { CustomNotification } from 'common/components';
import * as userServices from 'services/user';

import userSelectors from 'redux/user/selectors';
import * as action from 'redux/user/actions';
import { cloneDeep } from 'lodash';

import useUpdateContactCard from 'hooks/useUpdateContactCard';

const UserPrimaryInfo = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [imgSrc, setImgSrc] = useState('');
  const [editLogoLoading, setEditLogoLoading] = useState(false);

  const { classNamePrefix, primaryInfo = {}, userId, isEditMode } = props;

  const userAddress = contactUtil.getUserAddress(
    primaryInfo.primaryAddress || {}
  );

  const { firstName, lastName, imageUrl, userSocial, email } = primaryInfo;
  const { phoneNumber, mobileNumber, faxNumber } =
    primaryInfo?.primaryAddress || {};

  const contactInfo = { phoneNumber, mobileNumber, faxNumber, email };

  const { data: userProfile } = useSelector(
    userSelectors.makeSelectUserProfile()
  );
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const createFormData = (id, params) => {
    const { imageFile } = params;
    const formData = new FormData();
    id && formData.append('UserId', id);
    imageFile && formData.append('ImageFile', imageFile);
    return formData;
  };

  useEffect(() => {
    setImgSrc(imageUrl);
  }, [imageUrl]);

  const onUploadImageHandler = (params) => {
    const { imageSrc } = params;

    const isEditUserIsCurrentUser = userInfo?.id === userId;

    setEditLogoLoading(true);
    const formData = createFormData(userId, params);
    userServices
      .createUserProfileImage(formData)
      .then(
        (res) => {
          if (res?.isSuccess) {
            CustomNotification.success(
              intl.formatMessage(Messages.updateLogoSuccessMsg)
            );
            setImgSrc(imageSrc);

            //* check if we are editting login user
            if (isEditUserIsCurrentUser) {
              updateUserInfo(imageSrc);
            }

            updateContactCardInfo(imageSrc);
          } else {
            CustomNotification.error(
              res?.message || intl.formatMessage(Messages.updateFailMsg)
            );
          }
        },
        (err) => {
          CustomNotification.error(intl.formatMessage(Messages.updateFailMsg));
        }
      )
      .finally(() => {
        setEditLogoLoading(false);
      });
  };

  const updateUserInfo = (imageSrc) => {
    const cloneData = cloneDeep(userInfo);
    //* add memberId to data if on my profile view
    if (cloneData) {
      cloneData.avatarUrl = imageSrc;
    }
    dispatch(action.updateUserInfo(cloneData));
  };

  const updateContactCardInfo = (imageSrc) => {
    const cloneData = cloneDeep(userProfile);
    //* add memberId to data if on my profile view
    if (cloneData) {
      cloneData.imageUrl = imageSrc;
    }

    dispatch(action.updateUserProfile(cloneData));
  };

  return (
    <>
      <Row className={`${classNamePrefix}-container`} gutter={[10, 0]}>
        <Col className={`${classNamePrefix}-image-col`} xl={10} span={8}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <div className={`${classNamePrefix}-profile-image`}>
              <ProfileImage
                src={imgSrc}
                // responsive
                title={null}
                // enableCustom
                isEditMode={isEditMode}
                loading={editLogoLoading}
                onSubmit={onUploadImageHandler}
              />
            </div>

            {userSocial?.length > 0 ? (
              <UserSocialLink
                userSocial={userSocial}
                classNamePrefix={classNamePrefix + '__social-link'}
              />
            ) : null}
          </Space>
        </Col>
        <Col
          className={`${classNamePrefix}-info-col`}
          flex='auto'
          xl={14}
          span={16}
        >
          <Space direction='vertical' style={{ width: '100%' }} size={8}>
            <UserName
              className={`${classNamePrefix}-name`}
              firstName={firstName}
              lastName={lastName}
              style={{ fontSize: '16px' }}
              isEditMode={isEditMode}
              userId={userId}
            />
            <ProfileAddress
              className={`${classNamePrefix}-address`}
              address={userAddress}
            />
            <UserContactInfo
              className={`${classNamePrefix}-contact-info`}
              contactInfo={contactInfo}
              isEditMode={isEditMode}
              userId={userId}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
};

UserPrimaryInfo.propTypes = {
  classNamePrefix: PropTypes.string,
  primaryInfo: PropTypes.object,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditMode: PropTypes.bool,
};

export default UserPrimaryInfo;
