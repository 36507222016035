import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const DeleteTransitionPageButton = (props) => {
  const { disabled } = props;

  return (
    <RibbonButton
      size='small'
      icon={<DeleteOutlined />}
      label='Delete Transition Page'
      // onClick={handleCreateMarkup}
      disabled={disabled}
    />
  );
};

export default DeleteTransitionPageButton;
