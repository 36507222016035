import React from 'react';
import { useDispatch } from 'react-redux';

import { StyledModal } from 'common/components';
import NewChatSearchBar from './NewChatSearchBar';

import * as chatActions from '../../controller/actions';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/chat';

import './NewChat.less';

const NewChatWithUserModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { visible } = props;

  const onCancel = () => {
    dispatch(chatActions.showCreateNewChatWithUser(false));
  };

  const modalProps = {
    visible,
    title: intl.formatMessage(Messages.createNewChatUser),
    onCancel,
    destroyOnClose: true,
  };

  return (
    <StyledModal {...modalProps}>
      <NewChatSearchBar />
    </StyledModal>
  );
};

export default NewChatWithUserModal;
