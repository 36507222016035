import React from 'react';
import { Row, Col } from 'antd';
import { InfoWithLabel } from 'common/components';

const AssetPanelSpecificInfo = (props) => {
  const {
    assetData: { metadata },
  } = props;

  const {
    panelType,
    packageLevel,
    packagingDisposition,
    angle,
    facing,
    language,
  } = metadata;

  const dataInfo = [
    {
      label: 'Type',
      info: panelType,
    },
    {
      label: 'Package Level',
      info: packageLevel,
    },
    {
      label: 'Packaging Disposition',
      info: packagingDisposition,
    },
    {
      label: 'Angle',
      info: angle,
    },
    {
      label: 'Facing',
      info: facing,
    },
    {
      label: 'Language',
      info: language,
    },
  ];

  const infoColProps = {
    infoColProps: {
      flex: 'flex',
    },
    labelColProps: {
      flex: '150px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  return (
    <Row className='asset-panel-specific'>
      <Col span={16} offset={4}>
        {dataInfo.length > 0 &&
          dataInfo.map((item, index) => {
            return (
              <InfoWithLabel
                key={index}
                info={item.info}
                label={item.label}
                {...infoColProps}
              />
            );
          })}
      </Col>
    </Row>
  );
};

export default AssetPanelSpecificInfo;
