import { createSelector } from 'reselect';
import { initialState } from './reducer';

const companyState = (state) => state.company || initialState;

const setlectCompanyLoading = () =>
  createSelector(companyState, (state) => state.loadingCompanyInfo);
const setlectCompanyInfo = () =>
  createSelector(companyState, (state) => state.companyInfo);
const setlectCompanyError = () =>
  createSelector(companyState, (state) => state.errorCompanyInfo);

const selectShowcasedAssetLoading = () =>
  createSelector(companyState, (state) => state.loadingShowcasedAsset);

const selectOverviewShowcasedAsset = () =>
  createSelector(companyState, (state) => state.overviewShowcasedAssets);

const selectShowcasedAssetError = () =>
  createSelector(companyState, (state) => state.errorShowcasedAssets);
const selectLoadingOffice = () =>
  createSelector(companyState, (state) => state.errorShowcasedAssets);
const selectOfficeList = () =>
  createSelector(companyState, (state) => state.officesList);
const selectTotalOffice = () =>
  createSelector(companyState, (state) => state.totalOffice);
// select member Brands
const selectBrands = () => {
  return createSelector(companyState, (state) => state.brands);
};
const makeSelectMemberId = () =>
  createSelector(companyState, (state) => state.memberId);

export {
  setlectCompanyLoading,
  setlectCompanyInfo,
  setlectCompanyError,
  selectShowcasedAssetLoading,
  selectOverviewShowcasedAsset,
  selectShowcasedAssetError,
  selectLoadingOffice,
  selectOfficeList,
  selectTotalOffice,
  selectBrands,
  makeSelectMemberId,
};
