import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import ModalFormQasRequireField from './ModalFormQasRequireField';

const CreateQasRequireField = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const handleClick = () => toggleModal();

  return (
    <>
      <RibbonButton
        icon={<PlusOutlined />}
        label={'Create'}
        onClick={handleClick}
      />
      {isModalOpen && (
        <ModalFormQasRequireField
          isEdit={false}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default CreateQasRequireField;
