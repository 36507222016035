import _ from 'lodash';

import { useParams } from 'react-router-dom';

import React, { useState } from 'react';
import { Col, Input, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import {
  CustomNotification,
  StyledModal,
  WrapperSelect,
} from 'common/components';
import { changeBreakout } from './utils';
import { useMutationIngredients } from './useProductIngredients';

function IngredientForm({
  visible,
  onReset,
  setVisible,
  ingredientsMode,
  currentIngredient,
}) {
  const { id } = useParams();

  const { index, ingredients, ingredientStatement } = currentIngredient ?? {};
  const [statement, setStatement] = useState(
    ingredients || ingredientStatement
  );

  const mutation = useMutationIngredients({ id });

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    if (!statement?.length) {
      CustomNotification.error('Please specify ingredients statement value');
      return;
    }

    const params = {
      productId: id,
      ingredientGroups: [
        {
          ingredients: breakout,
          index: index ?? null,
          isRemoved: false,
        },
      ],
    };

    mutation.mutate(params, {
      onSuccess: async (result) => {
        if (result?.isSuccess) {
          if (ingredientsMode === 'add')
            CustomNotification.success('Add Ingredients successfully');
          else
            CustomNotification.success('Update Ingredient data successfully!');
          onReset();
          setVisible(false);
        } else {
          CustomNotification.error(result?.message || 'Something went wrong!');
        }
      },
      onError: (error) => {
        CustomNotification.error('Something went wrong!');
      },
    });
  };

  const handleRemoveIngredients = (value) => {
    const removeIngredient = breakout?.filter(
      (ingredient) => ingredient !== value
    );

    setStatement(removeIngredient);
  };

  const breakout = changeBreakout(statement);

  const modalProps = {
    title: ingredientsMode === 'add' ? 'Add Ingredient' : 'Edit Ingredient',
    visible: visible,
    width: '60%',
    closable: true,
    maskClosable: false,
    onCancel: handleCancel,
    wrapClassName: 'ingredient-modal',

    okText: 'Submit',
  };

  return (
    <StyledModal
      {...modalProps}
      loadingContent={{ loadingText: 'Loading' }}
      confirmLoading={mutation.isLoading}
      onOk={onFinish}
    >
      <Col span={24}>
        <p className='title-section'>Ingredients Breakout</p>
        <WrapperSelect
          className='select-ingredients'
          mode='tags'
          style={{ width: '100%' }}
          placeholder='Ingredients'
          name='ingredients'
          listItemHeight={10}
          listHeight={250}
          value={breakout}
          onDeselect={handleRemoveIngredients}
          showSearch={false}
          showArrow={false}
          dropdownStyle={{ display: 'none' }}
        />
      </Col>
      <Col span={24}>
        <p className='title-section'>
          Ingredients Statement
          <Tooltip title='Use ; or , to separating items'>
            <InfoCircleFilled style={{ marginLeft: '5px' }} />
          </Tooltip>
        </p>
        <Input.TextArea
          rows={5}
          maxLength={3000}
          onChange={(e) => {
            setStatement(e.target.value);
          }}
          value={statement}
        />
      </Col>
    </StyledModal>
  );
}

export default IngredientForm;
