import { defineMessages } from 'react-intl';

export const scope = 'Taco.maintenance';

export default defineMessages({
  editCategory: {
    id: `${scope}.help.ribbon.editCategory`,
    defaultMessage: 'Edit',
  },
  newCategory: {
    id: `${scope}.help.ribbon.newCategory`,
    defaultMessage: 'Create',
  },
  deleteCategory: {
    id: `${scope}.help.ribbon.deleteCategory`,
    defaultMessage: 'Delete',
  },
  editPost: {
    id: `${scope}.help.ribbon.editPost`,
    defaultMessage: 'Edit',
  },
  savePost: {
    id: `${scope}.help.ribbon.savePost`,
    defaultMessage: 'Save',
  },
  cancelPost: {
    id: `${scope}.help.ribbon.cancelPost`,
    defaultMessage: 'Cancel',
  },
  deletePost: {
    id: `${scope}.help.ribbon.deletePost`,
    defaultMessage: 'Delete',
  },
  openPost: {
    id: `${scope}.help.ribbon.openPost`,
    defaultMessage: 'Open Post',
  },
  createPost: {
    id: `${scope}.help.ribbon.createPost`,
    defaultMessage: 'Create',
  },
  deletePosts: {
    id: `${scope}.help.ribbon.deletePosts`,
    defaultMessage: 'Delete',
  },
  postList: {
    id: `${scope}.help.category.postList`,
    defaultMessage: 'Post List',
  },
  openMedia: {
    id: `${scope}.help.ribbon.openMeida`,
    defaultMessage: 'Open Media Source',
  },
  closeMedia: {
    id: `${scope}.help.ribbon.closeMedia`,
    defaultMessage: 'Close Media Source',
  },
  backToPost: {
    id: `${scope}.help.ribbon.backToPost`,
    defaultMessage: 'Close Post',
  },
  areYouSureToDelete: {
    id: `${scope}.component.help.post.areYouSureToDelete`,
    defaultMessage: 'Are you sure to delete?',
  },
  deleteSuccess: {
    id: `${scope}.component.help.post.deleteSuccess`,
    defaultMessage: 'Deleted successfully!',
  },
  backupSuccess: {
    id: `${scope}.component.help.article.backupSuccess`,
    defaultMessage: 'Backup successfully!',
  },
  restoreSuccess: {
    id: `${scope}.component.help.article.restoreSuccess`,
    defaultMessage: 'Restore successfully!',
  },
  overwrite: {
    id: `${scope}.component.help.article.overwrite`,
    defaultMessage: 'Overwrite',
  },
  restoreOverwrite: {
    id: `${scope}.component.help.article.restoreOverwrite`,
    defaultMessage: 'Are you sure to restore data with overwriting?',
  },
  restoreNoOverwrite: {
    id: `${scope}.component.help.article.restoreNoOverwrite`,
    defaultMessage: 'Are you sure to restore data without overwriting?',
  },
  fetchingPosts: {
    id: `${scope}.component.help.post.fetchingPosts`,
    defaultMessage: 'Loading posts...',
  },
  reindexLoadingMessage: {
    id: `${scope}.component.help.reindex.reindexLoadingMessage`,
    defaultMessage: 'Re-indexing...',
  },
  reindexErrorMessage: {
    id: `${scope}.component.help.reindex.reindexErrorMessage`,
    defaultMessage: 'Cannot re-index',
  },
  notificationDelete: {
    id: `${scope}.component.help.post.notificationDelete`,
    defaultMessage:
      'Have one or more posts associated with the selected categories. Please delete the individual posts first or move them to another category. ',
  },
  editEula: {
    id: `${scope}.component.eula.ribbon.editEula`,
    defaultMessage: 'Edit',
  },
  createEula: {
    id: `${scope}.component.eula.ribbon.createEula`,
    defaultMessage: 'Create',
  },
  deleteEula: {
    id: `${scope}.component.eula.ribbon.deleteEula`,
    defaultMessage: 'Delete',
  },
  eulaVersion: {
    id: `${scope}.component.eula.version`,
    defaultMessage: 'Version',
  },
  effectedDate: {
    id: `${scope}.component.eula.effectedDate`,
    defaultMessage: 'Effected on',
  },
  created: {
    id: `${scope}.component.eula.created`,
    defaultMessage: 'Created',
  },
  createOn: {
    id: `${scope}.component.eula.createOn`,
    defaultMessage: 'on',
  },
  createBy: {
    id: `${scope}.component.eula.createBy`,
    defaultMessage: 'by',
  },
  saveEula: {
    id: `${scope}.component.eula.ribbon.saveEula`,
    defaultMessage: 'Save',
  },
  cancelEula: {
    id: `${scope}.component.eula.ribbon.cancelEula`,
    defaultMessage: 'Cancel',
  },
  backToEula: {
    id: `${scope}.component.eula.ribbon.backToEula`,
    defaultMessage: 'Close EULA',
  },
  cloneEula: {
    id: `${scope}.component.eula.ribbon.cloneEula`,
    defaultMessage: 'Clone',
  },
  editArticle: {
    id: `${scope}.help.ribbon.editArticle`,
    defaultMessage: 'Edit',
  },
  deleteArticles: {
    id: `${scope}.help.ribbon.deleteArticles`,
    defaultMessage: 'Delete',
  },
  deleteArticle: {
    id: `${scope}.help.ribbon.deleteArticle`,
    defaultMessage: 'Delete',
  },
  createArticle: {
    id: `${scope}.help.ribbon.createArticle`,
    defaultMessage: 'Create',
  },
  backToArticle: {
    id: `${scope}.help.ribbon.backToArticle`,
    defaultMessage: 'Close Article',
  },
  backup: {
    id: `${scope}.component.help.ribbon.backup`,
    defaultMessage: 'Backup',
  },
  restore: {
    id: `${scope}.component.help.ribbon.restore`,
    defaultMessage: 'Restore',
  },
  restoreModal: {
    id: `${scope}.component.help.ribbon.restoreModal`,
    defaultMessage: 'Restore Modal',
  },
  template: {
    id: `${scope}.member.ribbon.template`,
    defaultMessage: 'Template',
  },
  editTemplate: {
    id: `${scope}.member.ribbon.editTemplate`,
    defaultMessage: 'Edit',
  },
  createTemplate: {
    id: `${scope}.member.ribbon.createTemplate`,
    defaultMessage: 'Create Template',
  },
  deleteTemplate: {
    id: `${scope}.member.ribbon.deleteTemplate`,
    defaultMessage: 'Delete Template',
  },
  saveTemplate: {
    id: `${scope}.member.ribbon.saveTemplate`,
    defaultMessage: 'Save',
  },
  cancelTemplate: {
    id: `${scope}.member.ribbon.cancelTemplate`,
    defaultMessage: 'Cancel',
  },
  backToTemplate: {
    id: `${scope}.member.ribbon.backToTemplate`,
    defaultMessage: 'Close Template',
  },
  editMember: {
    id: `${scope}.member.ribbon.editMember`,
    defaultMessage: 'Edit',
  },
  exportMember: {
    id: `${scope}.member.ribbon.exportMember`,
    defaultMessage: 'Export',
  },
  exportError: {
    id: `${scope}.member.ribbon.export.error`,
    defaultMessage: 'Cannot export data',
  },
  members: {
    id: `${scope}.member.ribbon.members`,
    defaultMessage: 'Members',
  },
  users: {
    id: `${scope}.member.ribbon.users`,
    defaultMessage: 'Users',
  },
  newUser: {
    id: `${scope}.member.ribbon.newUser`,
    defaultMessage: 'New User',
  },
  deleteUser: {
    id: `${scope}.member.ribbon.deleteUser`,
    defaultMessage: 'Delete User',
  },
  editUser: {
    id: `${scope}.member.ribbon.editUser`,
    defaultMessage: 'Edit User',
  },
  resetPassword: {
    id: `${scope}.member.ribbon.resetPassword`,
    defaultMessage: 'Reset Password',
  },
  lockedUser: {
    id: `${scope}.member.ribbon.lockedUser`,
    defaultMessage: 'Locked User',
  },
  addNewUserTitle: {
    id: `${scope}.member.user.addNewUserTitle`,
    defaultMessage: 'Add New User',
  },
  editUserTitle: {
    id: `${scope}.member.user.editUserTitle`,
    defaultMessage: 'Edit User',
  },
  communicate: {
    id: `${scope}.member.ribbon.communicate`,
    defaultMessage: 'Communicate',
  },
  reactivateUser: {
    id: `${scope}.member.ribbon.reactivateUser`,
    defaultMessage: 'Reactivate User',
  },
  text: {
    id: `${scope}.member.ribbon.text`,
    defaultMessage: 'Text',
  },
  email: {
    id: `${scope}.member.ribbon.email`,
    defaultMessage: 'Email',
  },
  chat: {
    id: `${scope}.member.ribbon.chat`,
    defaultMessage: 'Chat',
  },
  videoMessage: {
    id: `${scope}.member.ribbon.videoMessage`,
    defaultMessage: 'Video Message',
  },
  templateListing: {
    id: `${scope}.member.ribbon.templateListing`,
    defaultMessage: 'Template Listing',
  },
  accessMember: {
    id: `${scope}.member.feature.accessMember`,
    defaultMessage: 'Access Members',
  },
  accessProducts: {
    id: `${scope}.member.feature.accessProducts`,
    defaultMessage: 'Access Products',
  },
  accessDigitalAssets: {
    id: `${scope}.member.feature.accessDigitalAssets`,
    defaultMessage: 'Access Digital Assets',
  },
  accessQaSpec: {
    id: `${scope}.member.feature.accessQaSpec`,
    defaultMessage: 'Access QA Specification',
  },
  accessWorkflow: {
    id: `${scope}.member.feature.accessWorkflow`,
    defaultMessage: 'Access Workflow',
  },
  accessTicketSystem: {
    id: `${scope}.member.feature.accessTicketSystem`,
    defaultMessage: 'Access Ticket System',
  },
  accessFolders: {
    id: `${scope}.member.feature.accessFolders`,
    defaultMessage: 'Access Folders',
  },
  accessReporting: {
    id: `${scope}.member.feature.accessReporting`,
    defaultMessage: 'Access Reporting',
  },
  accessGDSN: {
    id: `${scope}.member.feature.accessGDSN`,
    defaultMessage: 'Access GDSN',
  },
  accessFTP: {
    id: `${scope}.member.feature.accessFTP`,
    defaultMessage: 'Access FTP',
  },
  accessInterops: {
    id: `${scope}.member.feature.accessInterops`,
    defaultMessage: 'Access Interop',
  },
  accessSyndication: {
    id: `${scope}.member.feature.accessSyndication`,
    defaultMessage: 'Access Syndication',
  },
  accessChat: {
    id: `${scope}.member.feature.accessChat`,
    defaultMessage: 'Access Chat',
  },
  accessVideoConf: {
    id: `${scope}.member.feature.accessVideoConf`,
    defaultMessage: 'Access Video Config',
  },
  accessVideoStreaming: {
    id: `${scope}.member.feature.accessVideoStreaming`,
    defaultMessage: 'Access Video Streaming',
  },
  accessThirdPartyAPI: {
    id: `${scope}.member.feature.accessThirdPartyAPI`,
    defaultMessage: 'Access Third Party API',
  },
  accessHelpManagement: {
    id: `${scope}.member.feature.accessHelpManagement`,
    defaultMessage: 'Access Help Management',
  },
  editMemberModalTitle: {
    id: `${scope}.member.modal.edit.title`,
    defaultMessage: 'Edit Member',
  },
  editMemberModalOkBtn: {
    id: `${scope}.member.modal.edit.okBtn`,
    defaultMessage: 'Submit',
  },
  editMemberSuccess: {
    id: `${scope}.member.modal.edit.message.success`,
    defaultMessage: 'Edit member successfully',
  },
  editMemberError: {
    id: `${scope}.member.modal.edit.message.error`,
    defaultMessage: 'Cannot edit member',
  },
  executeTemplate: {
    id: `${scope}.member.management.execute`,
    defaultMessage: 'Execute Template',
  },
  openMember: {
    id: `${scope}.member.ribbon.openMember`,
    defaultMessage: 'Open Member',
  },
  backToMarkup: {
    id: `${scope}.component.eula.ribbon.backToMarkup`,
    defaultMessage: 'Close Markup',
  },
  cloneMarkup: {
    id: `${scope}.component.eula.ribbon.cloneMarkup`,
    defaultMessage: 'Clone',
  },
  editMarkup: {
    id: `${scope}.component.eula.ribbon.editMarkup`,
    defaultMessage: 'Edit',
  },
  createMarkup: {
    id: `${scope}.component.eula.ribbon.createMarkup`,
    defaultMessage: 'Create',
  },
  deleteMarkup: {
    id: `${scope}.component.eula.ribbon.deleteMarkup`,
    defaultMessage: 'Delete',
  },
  saveMarkup: {
    id: `${scope}.component.eula.ribbon.saveMarkup`,
    defaultMessage: 'Save',
  },
  cancelMarkup: {
    id: `${scope}.component.eula.ribbon.cancelMarkup`,
    defaultMessage: 'Cancel',
  },
  createCuratedQuery: {
    id: `${scope}.component.ribbon.createCuratedQuery`,
    defaultMessage: 'Create',
  },
  editCuratedQuery: {
    id: `${scope}.component.ribbon.editCuratedQuery`,
    defaultMessage: 'Edit',
  },
  revokeCuratedQuery: {
    id: `${scope}.component.ribbon.revokeCuratedQuery`,
    defaultMessage: 'Revoke',
  },
  confirmRevokeCuratedQuery: {
    id: `${scope}.component.ribbon.confirmRevokeCuratedQuery`,
    defaultMessage: 'Are you sure to revoke selecting curated query?',
  },
  manageUserRole: {
    id: `${scope}.component.ribbon.manageUserRole`,
    defaultMessage: 'Manage user role & permission',
  },
  removeUserRole: {
    id: `${scope}.component.ribbon.removeUserRole`,
    defaultMessage: 'Remove role from user',
  },
  addUserRole: {
    id: `${scope}.component.ribbon.addUserRole`,
    defaultMessage: 'Add user role',
  },
  addUserRoleSuccess: {
    id: `${scope}.component.ribbon.addUserRoleSuccess`,
    defaultMessage: 'Assign role to selected user successfully',
  },
  addUserRoleError: {
    id: `${scope}.component.ribbon.addUserRoleError`,
    defaultMessage: 'Failed to assign role to selected user',
  },
  addUserRoleConfirm: {
    id: `${scope}.component.ribbon.addUserRoleConfirm`,
    defaultMessage: 'Do you want to assign role(s) to the selected user?',
  },
  removeUserRoleSuccess: {
    id: `${scope}.component.ribbon.removeUserRoleSuccess`,
    defaultMessage: 'Remove role to selected user successfully',
  },
  removeUserRoleError: {
    id: `${scope}.component.ribbon.removeUserRoleError`,
    defaultMessage: 'Failed to remove role to selected user',
  },
  removeUserRoleConfirm: {
    id: `${scope}.component.ribbon.removeUserRoleConfirm`,
    defaultMessage: 'Do you want to remove role from the selected user?',
  },
  viewAcceptance: {
    id: `${scope}.component.ribbon.viewAcceptance`,
    defaultMessage: 'View Acceptance',
  },
  communicationTemplateCreateSuccess: {
    id: `${scope}.communication-template.createSuccess`,
    defaultMessage: 'Create successfully',
  },
  communicationTemplateCreateError: {
    id: `${scope}.communication-template.createError`,
    defaultMessage: 'Create error',
  },
  communicationTemplateUpdateSuccess: {
    id: `${scope}.communication-template.updateSuccess`,
    defaultMessage: 'Update successfully',
  },
  communicationTemplateUpdateError: {
    id: `${scope}.communication-template.updateError`,
    defaultMessage: 'Update error',
  },
  communicationTemplateFieldRule: {
    id: `${scope}.communication-template.fieldRule`,
    defaultMessage: 'Please input your field',
  },
  communicationTemplateSelectRule: {
    id: `${scope}.communication-template.selectRule`,
    defaultMessage: 'Please select your field',
  },
  communicationTemplateVideoMessage: {
    id: `${scope}.communication-template.video.message`,
    defaultMessage: 'Please insert  video message',
  },
  communicationTemplateVideo: {
    id: `${scope}.communication-template.video`,
    defaultMessage: 'Please insert a video',
  },
  unlockUser: {
    id: `${scope}.userMaintenance.unlockUser`,
    defaultMessage: 'Unlock',
  },
  unlockUserSuccess: {
    id: `${scope}.userMaintenance.unlockUserSuccess`,
    defaultMessage: 'Unlock user(s) successfully',
  },
  unlockUserError: {
    id: `${scope}.userMaintenance.unlockUserError`,
    defaultMessage: 'Cannot unlock user(s)',
  },
  unlockUserConfirmMessage: {
    id: `${scope}.userMaintenance.unlockUser.confirmMessage`,
    defaultMessage: 'Are you sure you want to unlock the following user(s)?',
  },
});
