import React, { useState, useRef, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Col, Divider, Row, Space, Typography, Form, Menu } from 'antd';

import { CarouselSection, CarouselNext, CarouselPrevious } from '../Carousel';

import { CreateFormAllergens } from './FormAllergens';
import { AllergenContent, AllergenItem, AllergenList } from './AllergenElement';

import {
  GroupActions,
  MenuItem,
  GroupPreview,
  GroupAssign,
  GroupAddition,
  GroupEdit,
  GroupDeletion,
  EmptyGroup,
} from '../components';

import {
  FormAddButton as AddButton,
  FormEditButton as EditButton,
  FormDeleteButton as DeleteButton,
  StyledModal,
  WithLoading,
  CustomNotification,
  dialogFunction,
} from 'common/components';

import {
  useGetAllGroupProduct,
  useVisibleGroupModal,
  TYPE_GROUPS,
  useInvalidateGroupDetail,
} from '../../shared/hooks';

import {
  useVisibleModal,
  useCheckAllowManageGroupData,
  useCheckAllowEditProducts,
  useGetProductFullView,
} from 'hooks';
import { useCheckAllowEdit } from 'pages/qa-spec/hooks';

import { useGetProductAllergens, useSaveProductAllergens } from './queries';

import { GroupLayoutProvider } from '../components/GroupLayoutProvider';

import {
  getSelectedItemGroup,
  transformToTextGroupName,
} from '../../shared/utils';

import {
  filterIsPresentAllergens,
  filterProductGroups,
  generateAddingAllergenFormValues,
  getAllergensByIndex,
  verifyEmptyPrimaryAllergens,
} from './utils';

import {
  MULTIPLE_PANEL_ALLERGEN_FIELDS,
  SHOW_FIELDS,
  MAX_ALLERGENS_PER_VIEW,
} from './constants';

import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const TabAllergens = () => {
  const ref = useRef();

  const [formInstance] = Form.useForm();
  const { id: productId } = useParams();
  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  /* Allergens */
  const [idSelectedAllergen, setIdSelectedAllergen] = useState(null);
  const [modeAction, setModeAction] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [
    visibleAllergen,
    { onModal: onModalAllergen, offModal: offModalAllergen },
  ] = useVisibleModal();

  const { allergenGroups, isLoading } = useGetProductAllergens(productId);
  const allergenMutation = useSaveProductAllergens(productId);
  const { productFull } = useGetProductFullView({
    productId,
  });

  const isOnlyOneAllergen = allergenGroups?.length === 1;

  const selectedAllergenGroup = allergenGroups?.find(
    (allergenGroup) => allergenGroup?.index === idSelectedAllergen
  );

  const displayFields = MULTIPLE_PANEL_ALLERGEN_FIELDS.filter((allergen) =>
    SHOW_FIELDS.includes(allergen.fieldName)
  );
  const presentAllergenGroups = useMemo(
    () => filterIsPresentAllergens(allergenGroups),
    [allergenGroups]
  );
  /* End Allergens */

  /* Group Actions */
  const {
    visibleModalAddGroup,
    visibleModalEditGroup,
    visibleModalPreviewGroup,
    visibleModalAssignGroup,
    visibleModalDeleteGroup,
    handler: { onGroupModal, offGroupModal },
  } = useVisibleGroupModal();

  const { handleUpdateIXONEShield } = useUpdateIXONEShield();
  const { productGroups } = useGetAllGroupProduct(productId);
  const { handleSyncAllProductDetailGroup } =
    useInvalidateGroupDetail(productId);

  const selectedAllergenGroupIds = selectedAllergenGroup?.groupIds ?? [];

  const { defaultGroup, selectedProductGroups } = getSelectedItemGroup(
    selectedAllergenGroupIds,
    productGroups
  );
  const filteredProductGroups = filterProductGroups(
    selectedAllergenGroupIds,
    productGroups
  );
  /* End Groups */

  const hasEditPermissionQaSpec = useCheckAllowEdit();
  const hasManageGroupPermission = useCheckAllowManageGroupData();
  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  const handleAddAllergen = () => {
    onModalAllergen();
    setModeAction('create');

    formInstance.setFieldsValue({
      allergens: generateAddingAllergenFormValues(),
    });
  };

  const handleEditAllergen = () => {
    onModalAllergen();
    setModeAction('edit');

    const foundAllergens = getAllergensByIndex(
      allergenGroups,
      idSelectedAllergen
    );
    formInstance.setFieldsValue({
      allergens: foundAllergens,
    });
  };

  const handleSaveProductAllergens = async () => {
    const formValues = await formInstance.validateFields();

    if (isOnlyOneAllergen && verifyEmptyPrimaryAllergens(formValues)) {
      CustomNotification.error(
        'Please ensure that at least one allergen remains checked and presented'
      );
      return;
    }

    if (modeAction === 'create') {
      const params = {
        productId,
        allergenGroups: [
          {
            index: null,
            allergens: formValues.allergens,
          },
        ],
      };
      allergenMutation.mutate(params, {
        onSuccess: async (result) => {
          if (result?.isSuccess) {
            CustomNotification.success('Add Allergens successfully.');
          }
          formInstance.resetFields();
          offModalAllergen();
        },
      });
    } else if (modeAction === 'edit') {
      const params = {
        productId,
        allergenGroups: [
          {
            index: idSelectedAllergen,
            allergens: formValues.allergens,
          },
        ],
      };

      allergenMutation.mutate(params, {
        onSuccess: async (result) => {
          if (result?.isSuccess) {
            CustomNotification.success('Update Allergens successfully.');
          }
          formInstance.resetFields();
          setIdSelectedAllergen(null);
          offModalAllergen();
          handleSyncAllProductDetailGroup();
        },
      });
    }
    return;
  };

  const handleDeleteAllergen = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning text='selected Allergen' ixoneIgnore={ixoneIgnore}>
          Are you sure you want to delete the selected Allergen?
        </IXONEShieldWarning>
      ),
      onOk: async () => {
        const foundAllergens = getAllergensByIndex(
          allergenGroups,
          idSelectedAllergen
        );
        const params = {
          productId,
          allergenGroups: [
            {
              index: idSelectedAllergen,
              isRemoved: true,
              allergens: foundAllergens,
            },
          ],
        };
        await allergenMutation.mutateAsync(params, {
          onSuccess: async (result) => {
            if (result?.isSuccess) {
              CustomNotification.success('Delete Allergens successfully.');
              await handleUpdateIXONEShield(ixoneIgnore);
            }
            setIdSelectedAllergen(null);
            handleSyncAllProductDetailGroup();
          },
        });
      },
    });
  };

  const handleSelectAllergen = (allergenGroup) => {
    if (allergenGroup.index === idSelectedAllergen) {
      setIdSelectedAllergen(null);
    } else {
      setIdSelectedAllergen(allergenGroup.index);
    }
  };

  const modalProps = {
    title: modeAction === 'edit' ? 'Edit Allergen' : 'Add Allergen',
    visible: visibleAllergen,
    destroyOnClose: true,
    width: 1200,
    maskClosable: false,
  };

  return (
    <>
      <AllergenHeaderActions
        left={
          <>
            <Space>
              <CarouselPrevious
                onClick={() => {
                  if (ref?.current) ref.current.prev();
                  setIdSelectedAllergen(null);
                }}
                disabled={
                  allergenGroups.length <= MAX_ALLERGENS_PER_VIEW ||
                  currentSlide === 0
                }
              />
              <CarouselNext
                onClick={() => {
                  if (ref?.current) ref.current.next();
                  setIdSelectedAllergen(null);
                }}
                disabled={
                  allergenGroups.length <= MAX_ALLERGENS_PER_VIEW ||
                  currentSlide ===
                    presentAllergenGroups?.length - MAX_ALLERGENS_PER_VIEW
                }
              />
            </Space>
            <TotalAllergens total={allergenGroups.length} />
          </>
        }
        right={
          <>
            <AddButton
              onClick={handleAddAllergen}
              // onClick={onModalGroup}
              disabled={!hasEditPermissionQaSpec}
            />

            <EditButton
              disabled={idSelectedAllergen === null || !hasEditPermissionQaSpec}
              onClick={handleEditAllergen}
            />
            <IXONECertificationWrapper
              showModal
              type={TYPE_VIEW.ALLERGENS}
              onConfirm={(ixoneIgnore) => {
                handleDeleteAllergen(ixoneIgnore);
              }}
            >
              {(handleConfirmIXONE) => (
                <DeleteButton
                  disabled={
                    !isAllowEditProduct ||
                    idSelectedAllergen === null ||
                    isOnlyOneAllergen
                  }
                  onClick={() => {
                    handleConfirmIXONE({
                      originalData: {},
                      changedFields: true,
                    });
                  }}
                />
              )}
            </IXONECertificationWrapper>

            <GroupActions
              overlay={() => (
                <Space>
                  <Menu>
                    <MenuItem>
                      <MenuItem.AddGroup
                        disabled={!hasManageGroupPermission}
                        onClick={() => onGroupModal(TYPE_GROUPS.addGroup)}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.PreviewGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.previewGroup)}
                        disabled={selectedProductGroups?.length === 0}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.AssignGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.assignGroup)}
                        disabled={
                          idSelectedAllergen === null ||
                          filteredProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        } // idSelectedAllergen maybe equal = 0
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.EditGroup
                        disabled={
                          idSelectedAllergen === null ||
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        } // idSelectedAllergen maybe equal = 0
                        onClick={() => onGroupModal(TYPE_GROUPS.editGroup)}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.DeleteGroup
                        onClick={() => {
                          onGroupModal(TYPE_GROUPS.deleteGroup);
                        }}
                        disabled={
                          idSelectedAllergen === null ||
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        } // idSelectedAllergen maybe equal = 0
                      />
                    </MenuItem>
                  </Menu>
                </Space>
              )}
            >
              <GroupActions.ButtonWrapper />
            </GroupActions>
          </>
        }
      />
      <WithLoading loading={isLoading}>
        <CarouselSection
          ref={ref}
          slidesToShow={MAX_ALLERGENS_PER_VIEW}
          infinite={false}
          afterChange={(e) => {
            setCurrentSlide(e);
          }}
        >
          {presentAllergenGroups.map((allergenGroup, index) => {
            const title = transformToTextGroupName(
              allergenGroup?.groupIds,
              productGroups
            );

            const isEmptyAllergens = allergenGroup?.allergens?.length === 0;

            if (isEmptyAllergens)
              return (
                <AllergenList>
                  <AllergenItem
                    title={title}
                    onClick={() => handleSelectAllergen(allergenGroup)}
                  >
                    <EmptyGroup description='No allergens found' />
                  </AllergenItem>
                </AllergenList>
              );

            return (
              <AllergenList key={index}>
                <AllergenItem
                  title={title}
                  className={
                    allergenGroup.index === idSelectedAllergen
                      ? 'multiple-panel__allergen-wrapper--selected'
                      : null
                  }
                  onClick={() => handleSelectAllergen(allergenGroup)}
                >
                  {allergenGroup.allergens.map((allergen, index) => {
                    const lastIndex =
                      index === allergenGroup.allergens.length - 1;

                    return (
                      <React.Fragment key={allergen.allergenType}>
                        {displayFields.map((field) => (
                          <AllergenContent
                            key={field.fieldName}
                            label={field.displayName}
                            value={allergen[field.fieldName]}
                            type={field.type}
                          />
                        ))}
                        {lastIndex ? null : (
                          <Divider
                            style={{
                              margin: '6px',
                              width: '96%',
                              minWidth: '96%',
                            }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </AllergenItem>
              </AllergenList>
            );
          })}
        </CarouselSection>
      </WithLoading>

      {visibleAllergen && (
        <StyledModal
          {...modalProps}
          onCancel={offModalAllergen}
          onOk={handleSaveProductAllergens}
          okButtonProps={{
            loading: allergenMutation.isLoading,
          }}
        >
          <CreateFormAllergens formInstance={formInstance} />
        </StyledModal>
      )}

      {visibleModalPreviewGroup && (
        <GroupPreview
          visible={visibleModalPreviewGroup}
          onCancel={offGroupModal}
          defaultGroupId={defaultGroup?.groupId}
          productGroups={selectedProductGroups}
        />
      )}

      {visibleModalAddGroup && (
        <GroupAddition
          title='Create Group'
          visible={visibleModalAddGroup}
          onCancel={offGroupModal}
        />
      )}

      {visibleModalEditGroup && (
        <GroupLayoutProvider>
          <GroupEdit
            title='Edit Group'
            visible={visibleModalEditGroup}
            onCancel={offGroupModal}
            defaultGroupId={defaultGroup?.groupId}
            productGroups={selectedProductGroups}
          />
        </GroupLayoutProvider>
      )}

      {visibleModalAssignGroup && (
        <GroupAssign
          visible={visibleModalAssignGroup}
          onCancel={offGroupModal}
          defaultGroupId={filteredProductGroups?.[0]?.groupId}
          productGroups={filteredProductGroups}
          additionalAllergenGroups={[selectedAllergenGroup]}
          selectedGroupIds={selectedAllergenGroupIds}
        />
      )}

      {visibleModalDeleteGroup && (
        <GroupDeletion
          visible={visibleModalDeleteGroup}
          onCancel={offGroupModal}
          productGroups={selectedProductGroups}
          productId={productId}
        />
      )}
    </>
  );
};

const AllergenHeaderActions = ({ left, right }) => {
  return (
    <Row justify='space-between'>
      <Col>
        <Space>
          <Space>{left}</Space>
        </Space>
      </Col>
      <Col>
        <Space>{right}</Space>
      </Col>
    </Row>
  );
};

const TotalAllergens = ({ total }) => {
  return <Typography.Text strong>Total: {total} Allergen(s)</Typography.Text>;
};

export default TabAllergens;
