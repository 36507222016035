import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { ModalHeader, ModalBody } from './items';
import './PreviewableModal.less';

const PreviewableModal = (props) => {
  const {
    id,
    title,
    fileName,
    fileType,
    link,
    visible,
    cancelModal,
    previewList,
    isDownload,
    isDocument,
    isCarousel,
    type,
    className,
    downloadLink,
    fileSize,
  } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPreview, setCurrentPreview] = useState({});
  const [nextPreview, setNextPreview] = useState({});
  const [previousPreview, setPreviousPreview] = useState({});

  useEffect(() => {
    if (isCarousel && previewList && previewList.length > 0) {
      onFindingIndexOfPreviewList(id, previewList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewList, id, isCarousel]);

  const onFindingIndexOfPreviewList = (id, list) => {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        onSetPreviewsByCurrentIndex(i, list);
        return;
      }
    }
  };

  function stopPreview() {
    let assetAudio = document.getElementsByTagName('audio')[0];
    if (assetAudio) {
      assetAudio.pause();
      assetAudio.currentTime = 0;
    }

    let assetVideo = document.getElementsByTagName('video')[0];
    if (assetVideo) {
      assetVideo.pause();
      assetVideo.currentTime = 0;
    }
  }

  const onSetPreviewsByCurrentIndex = (curr, list) => {
    if (curr >= 0 && curr < list.length) {
      if (curr >= previewList.length - 1) {
        setCurrentIndex(curr);
        setCurrentPreview(previewList[curr]);
        setPreviousPreview(previewList[curr - 1]);
        setNextPreview({});
      } else if (curr <= 0) {
        setCurrentIndex(curr);
        setCurrentPreview(previewList[curr]);
        setPreviousPreview({});
        setNextPreview(previewList[curr + 1]);
      } else {
        setCurrentIndex(curr);
        setCurrentPreview(previewList[curr]);
        setPreviousPreview(previewList[curr - 1]);
        setNextPreview(previewList[curr + 1]);
      }
    }
  };

  const onSetNextPreview = () => {
    stopPreview();
    if (currentIndex >= previewList.length - 1) {
    } else {
      let nextId = currentIndex + 1;
      onSetPreviewsByCurrentIndex(nextId, previewList);
    }
  };

  const onSetPreviousPreview = () => {
    stopPreview();
    if (currentIndex <= 0) {
    } else {
      let previousIndex = currentIndex - 1;
      onSetPreviewsByCurrentIndex(previousIndex, previewList);
    }
  };

  const imageExtension = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];

  return (
    <Modal
      title={
        <ModalHeader
          onSetNextPreview={onSetNextPreview}
          onSetPreviousPreview={onSetPreviousPreview}
          isDownload={isDownload}
          isCarousel={isCarousel}
          type={currentPreview.fileExtension || type}
          previewListLength={previewList?.length}
          link={currentPreview?.url || downloadLink || link}
          title={currentPreview?.title || title}
          fileName={currentPreview?.fileName || fileName}
          currentIndex={currentIndex}
        />
      }
      visible={visible}
      className={classnames('previewable-modal', className)}
      onCancel={() => {
        stopPreview();
        cancelModal();
      }}
    >
      {isCarousel ? (
        <ModalBody
          isDocument={!imageExtension.includes(currentPreview?.fileExtension)}
          pdfPreview={currentPreview?.pdfPreview}
          mediaType={currentPreview?.mediaType}
          fileName={currentPreview?.fileName}
          fileType={currentPreview?.fileExtension}
          fileSize={currentPreview?.fileSize}
          link={currentPreview?.url}
          originLink={currentPreview?.originUrl}
        />
      ) : (
        <ModalBody
          isDocument={isDocument}
          fileName={fileName}
          fileType={type || fileType}
          fileSize={fileSize}
          link={link}
          originLink={downloadLink}
        />
      )}
    </Modal>
  );
};

PreviewableModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  link: PropTypes.string,
  visible: PropTypes.bool,
  cancelModal: PropTypes.func,
  previewList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isDownload: PropTypes.bool,
  isDocument: PropTypes.bool,
  isCarousel: PropTypes.bool,
};

PreviewableModal.defaultProps = {
  isDownload: true,
  isCarousel: false,
  isDocument: false,
};

export default PreviewableModal;
