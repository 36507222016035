import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { notification } from 'antd';

import userSelector from 'redux/user/selectors';

import { useFetch } from 'hooks/useAsync';
import { useCheckPermissions } from 'hooks/useCheckPermissions';

import * as rivirAlertServices from 'services/rivirAlert';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

export const useRivirAlert = () => {
  const userInfo = useSelector(userSelector.makeSelectUserInfo());

  const [loading, setLoading] = useState(false);

  const hasPermissionManageCard = useCheckPermissions([
    {
      action: ABILITY_ACTION.MANAGE,
      subject: ABILITY_SUBJECT.CREDIT_CARD,
    },
  ]);

  const memberId = userInfo?.member?.id;
  const isAlertCreditCard =
    userInfo?.canAddCreditCard && hasPermissionManageCard;

  const creditCardAlert = isAlertCreditCard ? [{ type: 'creditCard' }] : [];

  const { data = [], run: runFetchData } = useFetch();

  const fetchData = data?.data?.gridData ? data?.data?.gridData : [];
  const alertList = [...creditCardAlert, ...fetchData];

  const fetchRivirAlerts = () => {
    const params = {
      pageIndex: 0,
      pageSize: 999,
      memberId: memberId,
      filters: [
        {
          fieldName: 'isRead',
          filterType: 'Equal',
          value: false,
        },
      ],
    };

    runFetchData(rivirAlertServices.getNewRivirAlert(params));
  };

  const reloadAlertList = () => {
    fetchRivirAlerts();
  };

  const viewAlert = (id, callback) => {
    setLoading(true);
    const params = {
      alertId: id,
    };

    rivirAlertServices
      .markRivirAlertAsRead(params)
      .then((response) => {
        if (response.isSuccess) {
          reloadAlertList();
          callback && callback();
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRivirAlerts();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchRivirAlerts();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return {
    loading: loading,
    alertList,
    reloadAlertList,
    viewAlert,
  };
};
