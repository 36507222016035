import React, { useEffect, useRef, useState } from 'react';
import { Form, Typography } from 'antd';

import { EditContainer } from 'pages/qa-spec/components';
import { CustomNotification, WithLoading } from 'common/components';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useParams } from 'react-router-dom';

import { BasicFormItemList } from 'common/components/form/basic-form';

import { useGetQaClaimsTabData, useSaveQaClaimsTab } from './queries';
import { useGetQaClaimsFormDefine, useGetSnapshotQaClaims } from './hooks';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const QaClaims = (props) => {

  const editHook = useState(false);
  const [isEdit] = editHook;

  const { id: productId } = useParams();

  const [formInst] = Form.useForm();

  const refEditContainer = useRef();

  const isToggleSnapshot = useCheckSnapshotForRetailer();
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const { data: snapshotData, isLoading: isLoadingSnapshotQaClaims } =
    useGetSnapshotQaClaims({
      enabled: !!productId && isToggleSnapshot,
      productId,
    });
  const { data, isLoading: loading } = useGetQaClaimsTabData(
    { productId },
    { enabled: !!productId, retry: false }
  );

  const handleSaveQaClaimsTab = useSaveQaClaimsTab();
  const { isLoading: saveLoading } = handleSaveQaClaimsTab;

  const formDefine = useGetQaClaimsFormDefine({
    snapshotData,
    supplierModifiedData: {
      ...data,
      contains: data?.contains || [],
      doesNotContain: data?.doesNotContain || [],
    },
  });

  const newData = {
    ...data,
    contains: data?.contains || [],
    doesNotContain: data?.doesNotContain || [],
  };

  useEffect(() => {
    if (data) {
      formInst.setFieldsValue(newData);
    }
  }, [data, formInst]);

  const handleSave = async (toggleEditMode, ixoneIgnore) => {
    const formData = formInst.getFieldsValue();

    handleSaveQaClaimsTab.mutate(
      {
        productId,
        formData,
      },
      {
        onSuccess: async () => {
          toggleEditMode();
          await handleUpdateIXONEShield(ixoneIgnore);
        },
      }
    );
  };

  const handleCancel = () => {
    formInst.setFieldsValue(data);
  };

  return (
    <IXONECertificationWrapper
      onConfirm={(ixoneIgnore) => {
        const toggleEditMode = refEditContainer?.current?.onToggleEditMode;
        handleSave(toggleEditMode, ixoneIgnore);
      }}
      showModal
      type={TYPE_VIEW.EDIT_PRODUCT}
    >
      {(handleConfirmIXONE) => (
        <Form
          className='qa-claims'
          form={formInst}
          style={{ paddingRight: 5 }}
          layout='horizontal'
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        >
          <WithLoading loading={loading || saveLoading}>
            <EditContainer
              ref={refEditContainer}
              type={EDIT_SECTION.QA_CLAIMS}
              productId={productId}
              handleCancel={handleCancel}
              handleSave={async () => {
                handleConfirmIXONE({
                  originalData: newData,
                  changedFields: await formInst.validateFields(),
                });
              }}
              float
            >
              {(isEdit) => {
                return (
                  <EditTabSectionWrapper.CheckerWrapper
                    isEdit={isEdit}
                    type={EDIT_SECTION.QA_CLAIMS}
                  >
                    <div>
                      <BasicFormItemList
                        allowEdit={isEdit}
                        formDefine={formDefine}
                        labelFn={labelFn}
                        isLoadingSnapshot={isLoadingSnapshotQaClaims}
                      />
                    </div>
                  </EditTabSectionWrapper.CheckerWrapper>
                );
              }}
            </EditContainer>
          </WithLoading>
        </Form>
      )}
    </IXONECertificationWrapper>
  );
};

const labelFn = (labelText) => {
  return (
    <Typography.Text ellipsis={{ tooltip: labelText }} strong>
      {labelText && labelText}
    </Typography.Text>
  );
};

export default QaClaims;
