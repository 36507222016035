import classNames from 'classnames';
import DrugFactBullet from './DrugFactBullet';
import DrugFactText from './DrugFactText';

const DrugFactCase = ({
  italic = false,
  strong = false,
  isRenderBullet = true,
  text,
}) => {
  return (
    <div className='drug-fact__case'>
      {isRenderBullet ? <DrugFactBullet /> : null}
      <DrugFactText
        italic={italic}
        className={classNames({
          'drug-fact__text--bold': strong,
        })}
      >
        {text}
      </DrugFactText>
    </div>
  );
};

export default DrugFactCase;
