import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Tag, Input, Row, Col, Typography } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import './TagInput.less';

const { Text } = Typography;

const TagInput = forwardRef((props, ref) => {
  const {
    addBtnText,
    value: valueProps,
    regexValidators,
    onChange,
    isCloseable = true,
    disabled,
  } = props;

  const refInput = useRef(null);
  const refTagInput = useRef(null);

  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputvalue] = useState('');
  const [error, setError] = useState([]);
  const [isInputFocus, setIsInputFocus] = useState(false);

  const handleClose = (removedTag) => {
    const remainingTags = tags.filter((tag) => tag !== removedTag);
    setTags(remainingTags);
    onChange && onChange(remainingTags.join(','));
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputvalue(e.target.value);
    error?.length > 0 && setError([]);
  };

  const insertNewTag = () => {
    const newTagList = [...tags, inputValue];
    setTags(newTagList);
    setInputVisible(false);
    setInputvalue('');
    onChange && onChange(newTagList.join(','));
    setIsInputFocus(false);
  };

  const handleInputConfirm = async (e) => {
    e.preventDefault();
    if (inputValue) {
      if (tags.indexOf(inputValue) !== -1) {
        setError(['Item Already exist!']);
        return;
      }

      if (regexValidators && regexValidators?.length > 0) {
        Promise.all(
          regexValidators.map(
            (validator) =>
              new Promise((resolve, reject) => {
                resolve(validator(inputValue));
              })
          )
        ).then((result) => {
          const errorArray = result
            .filter((item) => item.result !== true)
            .map((item) => item?.message);
          if (errorArray.length > 0) {
            setError(errorArray);
            return;
          }
          insertNewTag();
        });
        return;
      }
      insertNewTag();
    }
  };

  const handleInputFocus = () => {
    setIsInputFocus(true);
  };

  const handleInputBlur = () => {
    setIsInputFocus(false);
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable={isCloseable}
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
        style={{ marginBottom: 4 }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  useEffect(() => {
    if (valueProps) {
      setTags(valueProps.split(','));
    }
  }, [valueProps, onChange]);

  useEffect(() => {
    if (disabled && inputVisible) {
      setInputVisible(false);
      setInputvalue('');
    }
  }, [disabled, inputVisible]);

  useEffect(() => {
    if (inputVisible) {
      refInput.current.focus();
    }
  }, [inputVisible]);

  const tagChild = tags.map(forMap);

  return (
    <Row
      className='tag-input'
      ref={refTagInput}
      style={{
        border: isInputFocus ? 'solid 1px rgba(0, 0, 0, 0.6)' : null,
        pointerEvents: disabled ? 'none' : '',
      }}
    >
      <Col xs={24}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
            onComplete: (e) => {
              e.target.style = '';
            },
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
          style={{ width: '100%' }}
        >
          {tagChild}
        </TweenOneGroup>
      </Col>

      <Col
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
        xs={24}
      >
        {!inputVisible && (
          <Button
            className={classnames('tag-input__btn', 'tag-input__btn-add')}
            onClick={showInput}
            danger
            style={{
              cursor: disabled ? 'no-allowed' : '',
            }}
          >
            <PlusOutlined /> {addBtnText || 'Add New'}
          </Button>
        )}
        {inputVisible && (
          <Row style={{ width: '100%' }} gutter={[4, 0]}>
            <Col flex='auto'>
              <Input
                ref={refInput}
                type='text'
                size='small'
                style={{
                  width: '100%',
                  borderColor: error?.length > 0 ? '#d93026' : null,
                }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onPressEnter={handleInputConfirm}
                onFocus={handleInputFocus}
              />
              {error && <Text type='danger'>{error.join(', ')} </Text>}
            </Col>
            <Col>
              <Button
                className={classnames(
                  'tag-input__btn',
                  'tag-input__btn-cancel'
                )}
                type='primary'
                onClick={handleInputConfirm}
                disabled={!inputValue}
              >
                Ok
              </Button>
            </Col>
            <Col>
              <Button
                className={classnames(
                  'tag-input__btn',
                  'tag-input__btn-cancel'
                )}
                danger
                onClick={() => setInputVisible(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
});

TagInput.propTypes = {
  addBtnText: PropTypes.string,
  value: PropTypes.string,
  regexValidators: PropTypes.array,
  onChange: PropTypes.func,
};

export default TagInput;
