import { AgGridIcons, AgGridThumbnail } from 'common/components';
import { Link } from 'react-router-dom';
import { Images } from 'config/assets';

import { PrimarySizeUOM } from '../';

const renderIcons = (params) => {
  const data = params?.data;

  return (
    <AgGridIcons
      data={data}
      isFavorite={data?.isFavorited}
      isShared={true}
      packageLevel={data?.packageLevel}
      subscriptionShield={data?.subscription && data?.subscriptionShield}
      gdsnShield={data?.gdsnShield}
    />
  );
};

const renderThumbnail = (params) => {
  const thumbnail300 = params?.data?.thumbnail || Images.RIVIR_LOGO_DEFAULT;
  const thumbnail500 = params?.data?.thumb500 || Images.RIVIR_LOGO_DEFAULT;
  const thumbnail1000 = params?.data?.thumb1000 || Images.RIVIR_LOGO_DEFAULT;

  return (
    <Link to={`/product/${params.data.id}`}>
      <AgGridThumbnail
        thumbnail={thumbnail300}
        thumbnail500={thumbnail500}
        thumbnail1000={thumbnail1000}
      />
    </Link>
  );
};

const renderPrimarySizeUOM = (params) => {
  return <PrimarySizeUOM params={params} />;
};

const customCellComponents = {
  renderThumbnail,
  renderIcons,
  renderPrimarySizeUOM,
};

export default customCellComponents;
