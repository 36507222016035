import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Indicator from '../indicator/Indicator';

/**
 * A simple wrapper indicator component which supports i18n loading message
 * Causion: That is using React.createElement
 * @author Brian Pick
 */
const WithIndicator = (props) => {
    const {
        component, 
        loading, 
        delay, 
        intl, 
        ...rest
    } = props;

    return (
        <Indicator 
            loading={loading} 
            delay={delay} 
            tip={intl.formatMessage({ id: 'Taco.common.components.indicator.loading' })}
        >
            {React.createElement(component, Object.assign({}, rest))}
        </Indicator>
    )
}

WithIndicator.propTypes = {
    /**
     * React component
     */
    component: PropTypes.func.isRequired,
    /**
     * spinner loading state
     */
    loading: PropTypes.bool,
    /**
     * delay milliseconds before showing spinner
     */
    delay: PropTypes.number,
    /**
     * A prop from react-intl by injectIntl
     */
    intl: PropTypes.object
}

export default injectIntl(WithIndicator);
