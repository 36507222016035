import React, { useState } from 'react';
import { Row, Col, Button, notification } from 'antd';
import AgGrid from 'common/components/ag-grid/AgGrid';
import * as endpoints from 'services/members/endpoints';
import './BanListModal.less';
import { WithLoading } from 'common/components';
import classnames from 'classnames';
import * as services from 'services/members';
import dialogFunction from 'common/components/dialog-function/DialogFunction';
import { EditOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import Message from 'i18n/messages/member-profile';
import { useIntl } from 'react-intl';

const BanListModal = (props) => {
  const intl = useIntl();
  const { columns, removeBanList, changeBanList } = props;
  const [gridApi, setGridApi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rowsSelected, setRowSelected] = useState([]);

  const onSetSelectedRows = (selectedNodesDetail) => {
    const bannedMemmberIdList = selectedNodesDetail.map(
      (nodeDetail) => nodeDetail?.bannedMemberId
    );
    setRowSelected(bannedMemmberIdList);
  };

  async function toggleRemoveBanList() {
    if (rowsSelected.length > 0) {
      setIsLoading(true);
      const res = await services.removeToBanList({ memberIds: rowsSelected });
      setIsLoading(false);
      if (res?.isSuccess) {
        notification.success({
          message: 'Remove items from ban list successfully!',
          top: 50,
        });
        refreshGrid();
        changeBanList();
        setRowSelected([]);
      } else {
        notification.error({
          message: 'Failed to remove items from ban list!',
          top: 50,
        });
      }
    } else {
      notification.error({
        message: 'Should choose item to remove!',
        top: 50,
      });
    }
  }

  function refreshGrid() {
    if (gridApi) gridApi.rivirPurgeServerSideCache();
  }

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Message.deleteItemsFromBanList),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: toggleRemoveBanList,
    });
  };

  return (
    <>
      <Row justify='space-between'>
        <Col></Col>
        <Col className='ban-list-modal__header'>
          {!isEdit && (
            <Button
              className={classnames('ban-list-modal__edit-button')}
              // className='agGrid-edit-header__section-btn-edit'
              type='link'
              icon={<EditOutlined />}
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            />
          )}
          {isEdit && (
            <Button
              className={classnames('ban-list-modal__delete-button')}
              disabled={rowsSelected?.length < 1}
              type='danger'
              onClick={() => {
                confirmDelete();
              }}
            >
              <DeleteOutlined />
              Delete
            </Button>
          )}
          {isEdit && (
            <Button
              className={classnames('ban-list-modal__cancel-button')}
              type='default'
              style={{ borderRadius: '4px' }}
              icon={<StopOutlined />}
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
          )}
        </Col>
      </Row>
      <WithLoading loading={isLoading}>
        <Row style={{ height: 'calc(70vh - 160px)' }}>
          <AgGrid
            columnDefs={columns}
            urlGrid={endpoints.GET_BAN_LIST}
            urlGridDistinct={endpoints.GET_BAN_LIST_DISTINCT}
            responseParams='data'
            getGridApi={(gridApi) => {
              setGridApi(gridApi);
            }}
            onHandleSelectedRowCustom={onSetSelectedRows}
            // requestParams={requestParams}
          />
        </Row>
      </WithLoading>
      <button
        id='ban-list-modal__refresh-button'
        style={{ display: 'none' }}
        onClick={() => refreshGrid()}
      ></button>
      <button
        id='ban-list-modal__close-edit-button'
        style={{ display: 'none' }}
        onClick={() => setIsEdit(false)}
      ></button>
    </>
  );
};

export default BanListModal;
