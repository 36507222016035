import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    useMemo,
  } from 'react';
  import ReactDOM from 'react-dom';
  
  import { Select } from 'antd';
  
  import { isArray } from 'lodash';
  
  import CountryCodes from 'static/CountryCode';
  
  import * as CountryFlagList from 'react-flags-select';
  
  import './CountryEditor.less';
  
  const { Option } = Select;
  
  export const CountryEditorSingle = forwardRef((props, ref) => {
    const processValue = (value) => {
      if (isArray(value)) {
        return value.join(', ');
      } else if (value) {
        return value?.split(', ') || [];
      } else {
        return [];
      }
    };
  
    const [country, setCountry] = useState(processValue(props.value));
    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);
  
    useEffect(() => {
      focus();
    }, []);
  
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return country;
        },
      };
    });
  
    useEffect(() => {
      if (!editing) {
        props.stopEditing();
      }
    }, [editing]);
  
    const focus = () => {
      window.setTimeout(() => {
        let container = ReactDOM.findDOMNode(refContainer.current);
        if (container) {
          container.focus();
        }
      });
    };
  
    const handleChange = (value) => {
      setCountry(value);
    };
  
    const CountryList = useMemo(() => {
      let countryListObject = {};
      let countryListArray = [];
      for (const [key, value] of Object.entries(CountryCodes)) {
        const flagKey = `${key.slice(0, 1)}${key
          .slice(1, 2)
          .toLocaleLowerCase()}`;
        const newValue = { name: value, flag: CountryFlagList[flagKey]() };
        countryListObject[key] = newValue;
        countryListArray.push(newValue);
      }
  
      return { countryListObject, countryListArray };
    }, []);
  
    return (
      <div ref={refContainer} className='ag-editor__country' tabIndex={1}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder='Please select'
          onChange={handleChange}
          value={country}
        >
          {CountryList.countryListArray.map((countryItem) => {
            return (
              <Option key={countryItem?.name}>
                {countryItem.flag} {countryItem.name}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  });
  