import React from 'react';
import classnames from 'classnames';

import './VideoToolBtnWrap.less';

const VideoToolBarBtnWrap = (props) => {
  const { className, children, isActive, isHidden, ...restProps } = props;
  return (
    <div
      className={classnames('video-toolbar-btn', className, {
        'video-toolbar-btn--active': isActive,
        'video-toolbar-btn--hidden': isHidden,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default VideoToolBarBtnWrap;
