import React from 'react';

import EmailEditorSection from './EmailEditorSection';
import EmailEditorContentElement from './EmailEditorContentElement';
import EmailEditorTag from './EmailEditorTag';
import {
  EmailEditorSectionSetting,
  EmailEditorAdditionalInfoSettings,
} from '../settings';

const EmailEditorAdditionalInfo = (props) => {
  const additionalInfo = <EmailEditorTag tag='additionalInfo' />;

  return (
    <EmailEditorSectionSetting settings={<EmailEditorAdditionalInfoSettings />}>
      <EmailEditorSection tags={['additionalInfo']}>
        <EmailEditorContentElement content={additionalInfo} />
      </EmailEditorSection>
    </EmailEditorSectionSetting>
  );
};

EmailEditorAdditionalInfo.craft = {
  displayName: 'Additional Information',
  props: {
    settings: {
      bold: false,
      italic: false,
      underLine: false,
      fontSize: 14,
      color: '#333',
    },
  },
  custom: { tags: ['additionalInfo'] },
};

export default EmailEditorAdditionalInfo;
