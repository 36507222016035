import React, { useState } from 'react';

import { Button, Row, Col, Input, List, Typography, Tooltip } from 'antd';

import {
  DownloadOutlined,
  FilePdfTwoTone,
  FileExcelTwoTone,
  FileWordTwoTone,
  LoadingOutlined,
  FilePptTwoTone,
} from '@ant-design/icons';

import {
  CustomNotification,
  StyledModal,
  WithLoading,
} from 'common/components';
import ManualFieldFilling, {
  ManualFillingModalContent,
} from './ManualFieldFilling';

import {
  prepareExportFormData,
  exportFormForSelectedEntities,
} from 'services/maintenanceForm';

import { useGetAllFormTemplates } from './hooks';

import { addLockValueToManualLoop } from './utils';

const FILE_TYPES = {
  EXCEL: ['xlsx', 'xlsm', 'xls', 'xlsb'],
  DOCS: ['docx', 'docm', 'doc', 'doc'],
  PDF: ['pdf'],
  PPT: ['ppt', 'pptx', 'pptm'],
};

const ModalFormItem = ({
  entityType,
  selectedEntityIds = [],
  visible,
  onCancel,
}) => {
  const [searchText, setSearchText] = useState('');

  const { formTemplates, isLoading } = useGetAllFormTemplates({
    entityType,
    isEnabled: Boolean(visible),
  });

  const foundFormTemplates = formTemplates.filter((template) =>
    template.formName?.toLowerCase().includes(searchText.toLowerCase())
  );

  const modalProps = {
    title: 'Download SPLASH Form',
    visible,
    onCancel,
    destroyOnClose: true,
    width: 600,
    bodyStyle: {
      minHeight: '60vh',
    },
    maskClosable: false,
    footer: (
      <Button key='Close' type='primary' onClick={onCancel}>
        Close
      </Button>
    ),
  };

  return (
    <StyledModal {...modalProps}>
      <WithLoading loading={isLoading}>
        <Row>
          <Col span={24} style={{ marginBottom: 6 }}>
            <Input.Search
              placeholder='Input search text'
              enterButton
              allowClear
              onSearch={(value) => setSearchText(value.trim())}
            />
          </Col>

          <Col xs={24} style={{ height: '100%', width: '100%' }}>
            <List
              dataSource={searchText ? foundFormTemplates : formTemplates}
              renderItem={(item) => (
                <FormFieldItem key={item.formId}>
                  <FormItemType fileType={item.fileType} />
                  <FormItemName formName={item.formName} />
                  <FormItemDownload
                    formId={item.formId}
                    containManualField={item.containManualField}
                    entityType={entityType}
                    selectedEntityIds={selectedEntityIds}
                  />
                </FormFieldItem>
              )}
            />
          </Col>
        </Row>
      </WithLoading>
    </StyledModal>
  );
};

const FormFieldItem = ({ children }) => {
  return <Row className='download-form__item'>{children}</Row>;
};

const getFileType = (fileType) => {
  for (const [type, formatTypes] of Object.entries(FILE_TYPES)) {
    if (formatTypes?.includes(fileType?.toLowerCase())) {
      return type;
    }
  }
  return null;
};

const FormItemType = ({ fileType: formatType }) => {
  const fileType = getFileType(formatType);

  let icon = null;

  if (fileType?.toLowerCase() === 'excel') {
    icon = <FileExcelTwoTone twoToneColor='#73d13d' />;
  } else if (fileType?.toLowerCase() === 'pdf') {
    icon = <FilePdfTwoTone twoToneColor='#ff7875' />;
  } else if (fileType?.toLowerCase() === 'docs') {
    icon = <FileWordTwoTone twoToneColor='#40a9ff' />;
  } else if (fileType?.toLowerCase() === 'ppt') {
    icon = <FilePptTwoTone twoToneColor='#ff7875' />;
  }

  return (
    <Col flex='30px' className='download-form__format-type'>
      <Tooltip title={fileType} zIndex='99999'>
        {icon}
      </Tooltip>
    </Col>
  );
};

const FormItemName = ({ formName }) => {
  return (
    <Col flex='1'>
      <Typography.Text strong ellipsis>
        {formName}
      </Typography.Text>
    </Col>
  );
};

const FormItemDownload = ({
  formId,
  containManualField,
  selectedEntityIds,
  entityType,
}) => {
  const [visible, setVisible] = useState(false);
  const [prepareFormData, setPrepareFormData] = useState({});
  const [statusDownload, setStatusDownload] = useState('idle');

  const {
    entityData = [],
    manualFields = [],
    exportIntoASingleFile,
    noLoopManualDataPointList,
  } = prepareFormData;

  const getManualFields = async () => {
    return await prepareExportFormData({
      formId,
      entityType,
      selectedEntityIds,
    });
  };

  const handleDownloadFormField = async () => {
    if (containManualField) {
      const response = await getManualFields();

      // Have to input field data before exporting
      if (response?.isSuccess) {
        const nextPreparedFormData = addLockValueToManualLoop(response.data);

        setPrepareFormData(nextPreparedFormData);

        setVisible(true);
      }
    } else {
      // Direct download
      try {
        const params = {
          formId,
          entityType,
          selectedEntityIds,
        };

        setStatusDownload('loading');

        const response = await exportFormForSelectedEntities(params);
        if (response?.isSuccess) {
          setStatusDownload('success');
          CustomNotification.success('Download SPLASH form successfully!');
        } else {
          setStatusDownload('error');
          CustomNotification.error(
            response?.message ?? 'Something went wrong!'
          );
        }
      } catch (e) {
        setStatusDownload('error');
        CustomNotification.error('Something went wrong!');
      }
    }
  };

  const isDownloading = statusDownload === 'loading';

  return (
    <>
      <Col flex='30px' style={{ textAlign: 'center' }}>
        <Tooltip
          title={isDownloading ? 'Downloading' : 'Download'}
          zIndex='99999'
        >
          {isDownloading ? (
            <LoadingOutlined className='download-form__icon' />
          ) : (
            <DownloadOutlined
              className='download-form__icon'
              onClick={handleDownloadFormField}
            />
          )}
        </Tooltip>
      </Col>

      {visible && (
        <ManualFieldFilling
          visible={visible}
          onCancel={() => setVisible(false)}
          prepareFormData={prepareFormData}
        >
          {(formInstance) => (
            <ManualFillingModalContent
              formInstance={formInstance}
              entityType={entityType}
              manualFields={manualFields}
              entityData={entityData}
              exportIntoASingleFile={exportIntoASingleFile}
              noLoopManualDataPointList={noLoopManualDataPointList}
            />
          )}
        </ManualFieldFilling>
      )}
    </>
  );
};

export default ModalFormItem;
