import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  error: null,
  userInfo: null,
  userProfile: {
    loading: false,
    data: null,
    err: null,
  },
  userProfileError: null,
  userProfileHeader: null,
  userProfileHeaderLoading: false,
  userProfileHeaderError: false,
  userProfileLocations: null,
  userProfileLocationsError: null,
  userProfileActivityLogs: null,
  userProfileActivityLogsError: null,
  gridNameActivityLogs: null,
  columnsActivityLogs: [],
  isActivityLogTab: false,
  isEditMode: false,
  allArticles: {
    loading: false,
    data: null,
    err: null,
  },
  showIxoneSplashScreen: true,
  contactCard: {},
  memberId: null,
  workflowRoles: [],
  workflowRolesLoading: false,
  productWorkflowTemplates: [],
  memberWorkflowTemplates: [],
  digitalMediaWorkflowTemplates: [],
  mfaLoginInfo: null,
  unlockAccountInfo: null,
};

/* eslint-disable default-case, no-param-reassign */
const userReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // login reducer
      case types.TOGGLE_LOADING:
        draft.loading = action.boolean;
        break;
      case types.LOGIN:
        draft.loading = true;
        break;
      case types.LOGIN_SUCCESS:
        draft.loading = false;
        draft.userInfo = action.user;
        draft.error = null;
        break;
      case types.LOGIN_ERROR:
        draft.loading = false;
        draft.error = action.message;
        break;
      // user profile
      case types.GET_USER_PROFILE:
        draft.userProfile.loading = true;
        break;
      case types.GET_USER_PROFILE_SUCCESS:
        draft.userProfile.loading = false;
        draft.userProfile.data = action.payload;
        break;
      case types.GET_USER_PROFILE_FAILURE:
        draft.userProfile.loading = false;
        draft.userProfile.err = action.payload;
        break;
      // user profile header
      case types.GET_USER_PROFILE_HEADER:
        draft.userProfileHeaderLoading = true;
        break;
      case types.GET_USER_PROFILE_HEADER_SUCCESS:
        draft.userProfileHeaderLoading = false;
        draft.userProfileHeader = action.payload;
        break;
      case types.GET_USER_PROFILE_HEADER_FAILURE:
        draft.userProfileHeaderLoading = false;
        draft.userProfileHeaderError = action.payload;
        break;
      // user profile activity logs
      case types.GET_USER_PROFILE_ACTIVITY_LOGS:
        break;
      case types.GET_USER_PROFILE_ACTIVITY_LOGS_SUCCESS:
        draft.userProfileActivityLogs = action.payload;
        break;
      case types.GET_USER_PROFILE_ACTIVITY_LOGS_FAILURE:
        draft.userProfileActivityLogsError = action.payload;
        break;
      // user profile locations
      case types.GET_USER_PROFILE_LOCATIONS:
        break;
      case types.GET_USER_PROFILE_LOCATIONS_SUCCESS:
        draft.userProfileLocations = action.payload;
        break;
      case types.GET_USER_PROFILE_LOCATIONS_FAILURE:
        draft.userProfileLocationsError = action.payload;
        break;
      // grid columns locations info
      case types.USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO:
        draft.error = false;
        draft.gridName = action.gridName;
        break;
      case types.USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO_SUCCESS:
        draft.columns = action.columns;
        break;
      case types.USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO_ERROR:
        draft.error = action.error;
        break;
      // grid columns locations info
      case types.USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO:
        draft.error = false;
        draft.gridNameActivityLogs = action.gridNameActivityLogs;
        break;
      case types.USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_SUCCESS:
        draft.columnsActivityLogs = action.columnsActivityLogs;
        break;
      case types.USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_ERROR:
        draft.error = action.error;
        break;
      case types.UPDATE_IS_ACTIVITY_LOG_TAB:
        draft.isActivityLogTab = action.isActivityLogTab;
        break;
      case types.TOGGLE_EDIT_USER_MODE:
        draft.isEditMode = action.isEditMode;
        break;
      //* get article
      case types.GET_ALL_USER_ARTICLE:
        draft.allArticles.data = null;
        draft.allArticles.loading = true;
        draft.allArticles.err = null;
        break;
      case types.GET_ALL_USER_ARTICLE_SUCCESS:
        draft.allArticles.data = action.payload;
        draft.allArticles.loading = false;
        draft.allArticles.err = null;
        break;
      case types.GET_ALL_USER_ARTICLE_FAILURE:
        draft.allArticles.data = null;
        draft.allArticles.loading = false;
        draft.allArticles.err = null;
        break;
      //* toggle splash screen
      case types.TOGGLE_SPLASH_SCREEN:
        draft.showIxoneSplashScreen = action.status;
        break;
      case types.UPDATE_CONTACT_CARD:
        draft.contactCard = action.payload;
        break;
      case types.GET_MEMBER_ID:
        draft.memberId = action.memberId;
        break;
      // get user workflow roles
      case types.GET_USER_WORKFLOW_ROLES:
        draft.workflowRolesLoading = true;
        break;
      case types.GET_USER_WORKFLOW_ROLES_SUCCESS:
        draft.workflowRolesLoading = false;
        draft.workflowRoles = action?.data?.workflowRoles || [];
        break;
      case types.GET_USER_WORKFLOW_ROLES_ERROR:
        draft.workflowRolesLoading = false;
        break;
      // get all workflow templates to start workflow
      case types.GET_ALL_PRODUCT_WORKFLOW_TEMPLATES:
        draft.productWorkflowTemplates = action.data;
        break;
      case types.GET_ALL_MEMBER_WORKFLOW_TEMPLATES:
        draft.memberWorkflowTemplates = action.data;
        break;
      case types.GET_ALL_DIGITAL_MEDIA_WORKFLOW_TEMPLATES:
        draft.digitalMediaWorkflowTemplates = action.data;
        break;
      case types.UPDATE_USER_INFO:
        draft.userInfo = action.userInfo;
        break;
      case types.UPDATE_USER_PROFILE:
        draft.userProfile.data = action.userProfile;
        break;
      case types.UPDATE_MFA_LOGIN_INFO:
        draft.mfaLoginInfo = action.payload;
        break;
      case types.UPDATE_UNLOCK_ACCOUNT_LOGIN_INFO:
        draft.unlockAccountInfo = action.payload;
        break;

      default:
        break;
    }
  });

export default userReducer;
