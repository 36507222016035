import { useDispatch, useSelector } from 'react-redux';

import * as brandingActions from 'redux/branding/actions';

const useGetBranding = () => {
  const dispatch = useDispatch();

  const getBranding = ({ showLoading } = {}) => {
    const action = showLoading ? 'getBranding' : 'getBrandingNoLoading';

    dispatch(brandingActions[action]());
  };

  return { getBranding };
};

export default useGetBranding;
