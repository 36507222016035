import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'antd';
import { StyledModal } from 'common/components';
import FolderEditDetailContent from 'pages/folders/folder-details/FolderEditDetailContent';
import _ from 'lodash';
import { editFolderShortContent } from 'services/folder';
import { apiHandler } from 'utils/api';
import moment from 'moment';
import { sleep } from 'utils/delay';

export const WIDTH_CONTANT_PANE = 500;

const FolderEditModal = ({
  visible,
  onCancel,
  folderInfo,
  idSelected,
  onSaveSuccess,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const folderName = Form.useWatch('folderName', form);

  const isFolderNameEmpty = folderName?.trim() === '';

  const submitUpdateSuccessCallback = () => {
    onSaveSuccess && onSaveSuccess();
    setLoading(false);
  };

  const saveFolder = async () => {
    setLoading(true);
    const formValue = form.getFieldsValue();
    const params = _.pick(formValue, [
      'id',
      'folderName',
      'specialPurpose',
      'expirationDate',
      'visibility',
    ]);
    await sleep(1000);
    apiHandler({
      service: editFolderShortContent,
      params: { ...params, id: idSelected },
      successMessage: 'Edit Folder Successfully !',
      errorMessage: 'Edit Folder Failed !',
      successCallback: submitUpdateSuccessCallback,
      onFinally: () => {
        setLoading(false);
      },
    });
  };

  const renderModalFooter = () => {
    const CancelBtn = Button;
    const SaveBtn = Button;

    return (
      <Row>
        <Col flex={1}></Col>
        <Col flex={0}>
          <CancelBtn onClick={onCancel} disabled={loading}>
            Cancel
          </CancelBtn>
          <SaveBtn
            type='primary'
            onClick={saveFolder}
            loading={loading}
            disabled={isFolderNameEmpty}
          >
            Save
          </SaveBtn>
        </Col>
      </Row>
    );
  };

  const modalProps = {
    title: 'Edit Folder',
    visible,
    onCancel,

    width: 700,
    bodyStyle: {
      minHeight: '40vh',
    },
    maskClosable: false,
    okText: 'Save',
    onOk: saveFolder,
    footer: renderModalFooter(),
  };

  useEffect(() => {
    if (folderInfo) {
      const {
        dateCreated,
        dateShared,
        lastUpdated,
        lastDownload,
        lastSchedule,
        expirationDate,
        ...rest
      } = folderInfo;
      const paramsForm = {
        ...rest,
        dateCreated: dateCreated ? moment(dateCreated) : null,
        lastUpdated: lastUpdated ? moment(lastUpdated) : null,
        dateShared: dateShared ? moment(dateShared) : null,
        lastDownload: lastDownload ? moment(lastDownload) : null,
        lastSchedule: lastSchedule ? moment(lastSchedule) : null,
        expirationDate: expirationDate ? moment(expirationDate) : null,
      };

      form.setFieldsValue(paramsForm);
    }
  }, [folderInfo]);

  return (
    <>
      <StyledModal {...modalProps}>
        <Form
          form={form}
          name='short-folder-edit'
          id='short-folder-edit'
          className='folder-panel-edit-content'
        >
          <FolderEditDetailContent folderInfo={folderInfo} />
        </Form>
      </StyledModal>
    </>
  );
};

export default FolderEditModal;
