import React, { useRef } from 'react';

import { Typography, Space, Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { SearchEntityBtn } from 'common/components';

import { useGetMemberId } from 'hooks/useGetMemberId';
import { GET_SUPPLIER_PRODUCT_LIST } from 'services/supplier-manager/endpoints';

import { useIntl } from 'react-intl';
import { mappingId } from 'utils/brokerManagements';
import Messages from 'i18n/messages/supplier-manager';

import classnames from 'classnames';

const { Text } = Typography;

const defaultProductParams = {
  filters: [],
  pageIndex: 1,
  pageSize: 20,
  search: { searchText: '' },
  sort: [{ fieldName: 'id', isAscending: false }],
};

const API_URL = GET_SUPPLIER_PRODUCT_LIST;

const SupplierSelectEntity = (props) => {
  const intl = useIntl();
  const refSelectedItems = useRef();

  const { handleAddEntityData, productData } = props;

  const { memberId } = useGetMemberId();

  const onOk = (selectedItems) => {
    handleAddEntityData(selectedItems, 'product');
    refSelectedItems.current.closeModal();
  };

  const RemoveEntityBtn = ({ onClose }) => {
    return (
      <CloseOutlined
        className='supplier-management__remove-entity-btn'
        onClick={onClose}
      />
    );
  };

  const tagTextStyle = { maxWidth: '100%', display: 'block', color: 'white' };

  const renderProductTag = ({ itemData, onClose }) => {
    return (
      <div
        className={classnames({
          'search-product-form__tag-result': true,
          'search-product-form__tag-result--assignTo': Boolean(
            itemData?.assignTo
          ),
        })}
        style={{ position: 'relative' }}
      >
        <Row>
          <Col xs={22}>
            <Text ellipsis style={tagTextStyle}>
              Product Name: {itemData?.productName}
            </Text>
            <Text ellipsis style={tagTextStyle}>
              GTIN: {itemData?.gtin || 'n/a'}
            </Text>
            <Text ellipsis style={tagTextStyle}>
              UPC12: {itemData?.upc12 || 'n/a'}
            </Text>
          </Col>
          <Col xs={2}>
            <RemoveEntityBtn onClose={onClose} />
          </Col>
        </Row>
      </div>
    );
  };

  const selectedEntityIds = mappingId(productData);

  const filtersSelectedItems = [
    {
      fieldName: 'id',
      filterType: 'NotIn',
      values: selectedEntityIds,
    },
  ];

  const params = {
    ...defaultProductParams,
    filters: selectedEntityIds?.length !== 0 ? filtersSelectedItems : [],
    memberId,
  };

  return (
    <Space size={25} style={{ padding: '8px 0' }}>
      <Text strong>
        {intl.formatMessage(Messages.selectEntityLabel.product)}
      </Text>

      <SearchEntityBtn
        ref={refSelectedItems}
        searchType={'product'}
        apiUrl={API_URL}
        params={params}
        tagRender={renderProductTag}
        onOk={onOk}
        savedSelected={false}
        isMultiple={true}
        mapSearch={(searchText) => ({ search: { searchText } })}
        allowSelectAll={true}
      />
    </Space>
  );
};

export default SupplierSelectEntity;
