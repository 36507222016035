import FileSaver from 'file-saver';

const getFileNameDownload = (link) => {
  if (
    link.match(/\/([^\/?#]+)[^\/]*$/) &&
    link.match(/\/([^\/?#]+)[^\/]*$/)[1]
  ) {
    return link.match(/\/([^\/?#]+)[^\/]*$/)[1];
  } else {
    return null;
  }
};

export const saveFile = (link, fileName) => {
  FileSaver.saveAs(link, getFileNameDownload(link));
};
