import classNames from 'classnames';
import DrugFactText from './DrugFactText';
import DrugFactSection from './DrugFactSection';
import DrugFactDetailList from './DrugFactDetailList';

const DrugFactWarningItem = ({ warningItem, isLastWarningItem }) => {
  const warningDetailMapping = warningItem?.warningDetail?.map(
    (warning) => warning.warningDetail
  );

  return (
    <DrugFactSection
      className='drug-fact__warning-item'
      className={classNames({
        'drug-fact__warning-item': true,
        'drug-fact__warning-item--last': isLastWarningItem,
      })}
    >
      <DrugFactText
        styles={{ color: 'black' }}
        className={classNames({
          'drug-fact__text--bold': warningItem?.boldText,
        })}
      >
        {warningItem?.warningText}
      </DrugFactText>

      {warningDetailMapping?.length === 1 ? (
        <>
          {warningDetailMapping.map((text) => (
            <DrugFactText
              className={classNames({
                'drug-fact__text--bold': warningItem?.boldDetail,
              })}
            >
              {' '}
              {text}
            </DrugFactText>
          ))}
        </>
      ) : null}

      {warningDetailMapping?.length > 1 ? (
        <DrugFactDetailList
          strong={warningItem?.boldDetail}
          list={warningDetailMapping}
        />
      ) : null}
    </DrugFactSection>
  );
};

export default DrugFactWarningItem;
