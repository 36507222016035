import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Text } = Typography;

const ProductOverviewBricCode = ({
  brickCode,
  brickCodeName,
  categories,
  ...rest
}) => {
  return (
    <div className='product-overview__bric-code'>
      <Text className='product-overview__bric-code-name'>
        {brickCode || 'N/A'} - {brickCodeName || 'N/A'}
      </Text>
    </div>
  );
};

ProductOverviewBricCode.propTypes = {
  bricCode: PropTypes.string,
  bricCodeName: PropTypes.string,
  categories: PropTypes.array,
};

export default ProductOverviewBricCode;
