import React from 'react';

import { Tooltip, Typography } from 'antd';

import MemberGlnList from '../member-grid/MemberGlnList';

const { Text } = Typography;

const GlnTooltip = (props) => {
  const { glns } = props;

  const renderTitle = () => {
    return <MemberGlnList glns={glns} color='#fff' />;
  };

  const renderGlnText = () => {
    return glns?.length ? (
      <Text ellipsis style={{ width: '100%' }}>
        {glns.join(', ')}
      </Text>
    ) : null;
  };

  return <Tooltip title={renderTitle}>{renderGlnText()}</Tooltip>;
};

export default GlnTooltip;
