import React from 'react';

import PropTypes from 'prop-types';

import { LogoutOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import Messages from 'i18n/messages/home';

import useCloseDetail from 'hooks/useCloseDetail';

import { DISABLED_CLOSE_DETAIL_BUTTON } from 'static/Constants';

const BackControl = (props) => {
  const { isDisabled, closeDetailCallback, onClick } = props;

  const { goBack } = useCloseDetail();

  const handleClick = () => {
    if (onClick) return onClick(goBack);

    goBack();
    closeDetailCallback && closeDetailCallback();
  };

  return (
    <RibbonButton
      icon={<LogoutOutlined style={{ transform: 'rotate(180deg)' }} />}
      label={Messages.backText}
      onClick={handleClick}
      disabled={
        DISABLED_CLOSE_DETAIL_BUTTON.includes(window.location.pathname) ||
        isDisabled
      }
    />
  );
};

BackControl.propTypes = {
  /**
   * to detect type of ribbon view
   */
  ribbonType: PropTypes.oneOf([
    'members',
    'assets',
    'reportings',
    'workflows',
    'products',
  ]),
};

export default BackControl;
