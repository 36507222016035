import React, { useState, useRef } from 'react';
import { Button } from 'antd';
import { dialogFunction } from 'common/components';
import useClickOuter from 'hooks/useClickOuter';

import {
  TableOutlined,
  PlusOutlined,
  EditOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';

import classnames from 'classnames';

import './MdToolbarTable.less';

const cellGridSize = 200;
const cellSize = cellGridSize / 10;

const MdToolbarTable = (props) => {
  const {
    toggleEditTable,
    toggleCreateTable,
    toggleTableList,
    setInitTable,
    searchedTableIndexInList,
    closeEditView,
    isAllowToCloseTool,
  } = props;

  const [mousePos, setMousePos] = useState([-1, -1]);

  const refTableTool = useRef(null);

  const handleClickOutside = () => {
    if (isAllowToCloseTool) {
      closeEditView && closeEditView();
    }
  };

  useClickOuter(refTableTool, handleClickOutside);

  const handleMouseMove = (event) => {
    var rect = event.currentTarget.getBoundingClientRect();
    const mouse = [event.clientX - rect.left, event.clientY - rect.top];
    setMousePos([
      mouse[0] > 0 ? mouse[0] / cellSize : -1,
      mouse[1] > 0 ? mouse[1] / cellSize : -1,
    ]);
  };

  const handleMouseLeave = (event) => {
    setMousePos([-1, -1]);
  };

  const mapIndexToDistance = (cellIndex) => {
    const col = Math.floor(cellIndex % 10);
    const row = Math.floor(cellIndex / 10);

    return {
      col,
      row,
    };
  };

  const handleClickCreateTable = () => {
    if (mousePos[0] === -1 && mousePos[1] === -1) return;
    toggleCreateTable && toggleCreateTable();
    setInitTable([Math.floor(mousePos[0]) + 1, Math.floor(mousePos[1]) + 1]);
  };

  const handleClickEditTable = () => {
    const searchedTableIdx = searchedTableIndexInList();

    if (searchedTableIdx >= 0) {
      toggleEditTable && toggleEditTable();
    } else {
      dialogFunction({
        type: 'warn',
        content: 'Could not found the table!',
        okText: 'Ok',
        cancelText: 'Cancel',
      });
    }
  };

  return (
    <div className='md-toolbar-table' ref={refTableTool}>
      <Button
        className={classnames(
          'md-toolbar-table__btn md-toolbar-table__btn--arrow'
        )}
        onClick={handleClickCreateTable}
      >
        <TableOutlined
          className={classnames(
            'md-toolbar-table__btn-icon',
            'md-toolbar-table__btn-icon--1'
          )}
        />
        <PlusOutlined
          className={classnames(
            'md-toolbar-table__btn-icon',
            'md-toolbar-table__btn-icon--2'
          )}
        />
        New
        <div className='md-toolbar-table__create-view'>
          <div
            className='md-toolbar-table__create-view-grid'
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{ width: cellGridSize, height: cellGridSize }}
          >
            {Array.from(Array(100)).map((cell, idx) => {
              const { col, row } = mapIndexToDistance(idx);
              return (
                <div
                  className={classnames('md-toolbar-table__create-view-cell', {
                    'md-toolbar-table__create-view-cell--selected':
                      mousePos[0] > col && mousePos[1] > row,
                  })}
                  key={'cell-' + idx}
                />
              );
            })}
          </div>
          <div className='md-toolbar-table__create-view-result'>
            {mousePos[0] != -1 && mousePos[1] != -1
              ? `${Math.floor(mousePos[0]) + 1} x ${
                  Math.floor(mousePos[1]) + 1
                }`
              : `0 x 0`}
          </div>
        </div>
      </Button>
      <Button
        className='md-toolbar-table__btn'
        onClick={handleClickEditTable && handleClickEditTable}
      >
        <TableOutlined
          className={classnames(
            'md-toolbar-table__btn-icon',
            'md-toolbar-table__btn-icon--1'
          )}
        />
        <EditOutlined
          className={classnames(
            'md-toolbar-table__btn-icon',
            'md-toolbar-table__btn-icon--3'
          )}
        />
        Edit
      </Button>
      <Button
        className='md-toolbar-table__btn'
        onClick={toggleTableList && toggleTableList}
      >
        <TableOutlined
          className={classnames(
            'md-toolbar-table__btn-icon',
            'md-toolbar-table__btn-icon--1'
          )}
        />
        <PaperClipOutlined
          className={classnames(
            'md-toolbar-table__btn-icon',
            'md-toolbar-table__btn-icon--3'
          )}
        />
        Table List
      </Button>
    </div>
  );
};

export default MdToolbarTable;
