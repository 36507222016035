import { createSelector } from 'reselect';
import { initialState } from './reducers';

const userState = (state) => state.user || initialState;

const makeSelectUserInfo = () =>
  createSelector(userState, (state) => state.userInfo);

const makeSelectUserPermisstion = () =>
  createSelector(userState, (state) => state.userInfo?.permissions);
const makeSelectAssetDownloadPreference = () =>
  createSelector(userState, (state) => state.userInfo?.assetDownloadPreference);

const selectUser = () => createSelector(userState, (state) => state.user);

const selectUserError = () => createSelector(userState, (state) => state.error);

const selectUserLoading = () =>
  createSelector(userState, (state) => state.loading);

const selectAcceptedEula = () =>
  createSelector(userState, (state) => state.user.accepted_eula);

const makeSelectUserId = () =>
  createSelector(userState, (state) => state.userInfo?.id);

const makeSelectPersonalization = () =>
  createSelector(userState, (state) => state.userInfo?.personalInfo);

const makeSelectLandingPage = () =>
  createSelector(userState, (state) => state.userInfo?.landingPage);
const makeSelectMemberLandingPage = () =>
  createSelector(userState, (state) => state.userInfo?.memberLandingPage);

const makeSelectMessages = () =>
  createSelector(userState, (state) => state.userInfo?.messages);

const makeSelectAlert = () =>
  createSelector(userState, (state) => state.userInfo?.alerts);

const makeSelectUserFirstName = () =>
  createSelector(userState, (state) => state.userInfo?.firstName);

const makeSelectUserEmail = () =>
  createSelector(userState, (state) => state.userInfo?.email);

const makeSelectUserAlternativeEmail = () =>
  createSelector(userState, (state) => state.userInfo?.alternativeEmails);

const searchCategory = [
  'Automotive',
  'Beauty/Personal Care/Hygiene',
  'Camping',
  'Building Products',
];
const makeSelectUserSearchCategories = () =>
  createSelector(
    userState,
    (state) => state.userInfo?.searchCategories || searchCategory
  );

// user profile activity logs
const makeSelectUserProfileActivityLogs = () =>
  createSelector(userState, (state) => state.userProfileActivityLogs);

const makeSelectUserProfileActivityLogsError = () =>
  createSelector(userState, (state) => state.userProfileActivityLogsError);
// user profile
const makeSelectUserProfile = () =>
  createSelector(userState, (state) => state.userProfile);

// user profile header
const makeSelectUserProfileHeader = () =>
  createSelector(userState, (state) => state.userProfileHeader);
const makeSelectUserProfileHeaderLoading = () =>
  createSelector(userState, (state) => state.userProfileHeaderLoading);
const makeSelectUserProfileHeaderError = () =>
  createSelector(userState, (state) => state.userProfileHeaderError);

const makeSelectUserSummaryActivity = () =>
  createSelector(userState, (state) => {
    return {
      firstLogin: state.userProfile?.firstLogin,
      lastLogin: state.userProfile?.lastLogin,
      ytdLogins: state.userProfile?.ytdLogins,
      totalLogin: state.userProfile?.totalLogin,
      ytdTransactions: state.userProfile?.ytdTransactions,
      totalTransactions: state.userProfile?.totalTransactions,
    };
  });
const makeSelectUserProfileLocations = () =>
  createSelector(userState, (state) => state.userProfileLocations);

const makeSelectUserProfileLocationsError = () =>
  createSelector(userState, (state) => state.userProfileLocationsError);
const makeSelectColumnsLocations = () =>
  createSelector(userState, (state) => state.columns);
const makeSelectColumnsActivityLogs = () =>
  createSelector(userState, (state) => state.columnsActivityLogs);

const makeSelectUserMemberId = () =>
  createSelector(userState, (state) => state.userInfo?.member?.id);

const makeSelectUserDefinedProperties = () =>
  createSelector(userState, (state) => state.userInfo?.memberDefinedProperties);

const makeSelectIsActivityLogTab = () =>
  createSelector(userState, (state) => state.isActivityLogTab);

const makeSelectIsEditMode = () =>
  createSelector(userState, (state) => state.isEditMode);

const makeSelectUserArticle = () =>
  createSelector(userState, (state) => state.allArticles.data);

const makeSelectShowIxoneSplashScreen = () =>
  createSelector(userState, (state) => state.showIxoneSplashScreen);

const makeSelectContactCardInfo = () =>
  createSelector(userState, (state) => state.contactCard);

const makeSelectMemberId = () =>
  createSelector(userState, (state) => state.memberId);

const makeSelectWorkflowRoles = () =>
  createSelector(userState, (state) => state.workflowRoles);

// get all workflow templates to start workflow
const makeSelectProductWorkflowTemplates = () =>
  createSelector(userState, (state) => state.productWorkflowTemplates);
const makeSelectMemberWorkflowTemplates = () =>
  createSelector(userState, (state) => state.memberWorkflowTemplates);
const makeSelectDigitalMediaWorkflowTemplates = () =>
  createSelector(userState, (state) => state.digitalMediaWorkflowTemplates);
const makeSelectReceiveCompanyCommunicate = () =>
  createSelector(
    userState,
    (state) => state.userInfo?.receiveCompanyCommunications
  );

const makeSelectUserGDSNPartyInfo = () =>
  createSelector(userState, (state) => state.userGDSNPatyInfo);

const makeSelectMfaLoginInfo = () =>
  createSelector(userState, (state) => state.mfaLoginInfo);

const makeSelectUnlockAccountInfo = () =>
  createSelector(userState, (state) => state.unlockAccountInfo);

const userSelecters = {
  selectUser,
  selectUserLoading,
  selectUserError,
  selectAcceptedEula,
  makeSelectUserInfo,
  makeSelectUserPermisstion,
  makeSelectUserId,
  makeSelectPersonalization,
  makeSelectLandingPage,
  makeSelectMemberLandingPage,
  makeSelectMessages,
  makeSelectAlert,
  makeSelectUserFirstName,
  makeSelectUserEmail,
  makeSelectUserProfile,
  makeSelectUserProfileHeader,
  makeSelectUserProfileHeaderError,
  makeSelectUserProfileHeaderLoading,
  makeSelectUserSummaryActivity,
  makeSelectUserProfileLocations,
  makeSelectUserProfileLocationsError,
  makeSelectColumnsLocations,
  makeSelectColumnsActivityLogs,
  makeSelectUserProfileActivityLogs,
  makeSelectUserProfileActivityLogsError,
  makeSelectUserAlternativeEmail,
  makeSelectUserMemberId,
  makeSelectUserDefinedProperties,
  makeSelectIsActivityLogTab,
  makeSelectIsEditMode,
  makeSelectUserArticle,
  makeSelectShowIxoneSplashScreen,
  makeSelectUserSearchCategories,
  makeSelectContactCardInfo,
  makeSelectMemberId,
  makeSelectWorkflowRoles,
  makeSelectProductWorkflowTemplates,
  makeSelectMemberWorkflowTemplates,
  makeSelectDigitalMediaWorkflowTemplates,
  makeSelectReceiveCompanyCommunicate,
  makeSelectUserGDSNPartyInfo,
  makeSelectAssetDownloadPreference,
  makeSelectMfaLoginInfo,
  makeSelectUnlockAccountInfo,
};

export default userSelecters;
