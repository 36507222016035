import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { get } from 'lodash';
// redux
import * as types from './constants';
import * as actions from './actions';
import * as selectors from './selectors';
// services
import * as ssoProductServices from 'services/sso-product';
import { EVENT } from 'static/Constants';
import { forwardTo } from 'utils/common/route';

export function* createSsoProduct(payload) {
  try {
    const { params } = payload;
    const response = yield call(
      ssoProductServices.bpSsoCreateProductDetailsService,
      params
    );

    const { isSuccess, message, data } = response;

    if (isSuccess) {
      yield put(actions.createSsoProductSuccess(data));
      yield put(actions.redirectPage(true));
    } else {
      yield put(actions.createSsoProductError(message));
    }
  } catch (err) {
    yield put(actions.createSsoProductError(err));
  }
}

export function* getSsoProduct(payload) {
  try {
    const response = yield call(
      ssoProductServices.bpSsoGetProductDetailsService,
      payload
    );
    const { isSuccess, message } = response;

    if (isSuccess) {
      yield put(actions.getSsoProductSuccess());
    } else {
      yield put(actions.getSsoProductError(message));
    }
  } catch (err) {
    yield put(actions.getSsoProductError(err));
  }
}

export function* updateSsoProduct(payload) {
  try {
    const response = yield call(
      ssoProductServices.bpSsoUpdateProductDetailsService,
      payload.payload
    );
    const { isSuccess, message } = response;

    if (isSuccess) {
      yield put(actions.updateSsoProductSuccess());
    } else {
      yield put(actions.updateSsoProductError(message));
    }
  } catch (err) {
    yield put(actions.updateSsoProductError(err));
  }
}

export function* getSsoProductInfo(payload) {
  try {
    const response = yield call(
      ssoProductServices.bpSsoGetAddProductInfo,
      payload.payload
    );
    const { isSuccess, data } = response;

    if (isSuccess) {
      yield put(actions.getSsoProductInfoSuccess(data?.rivirRetailerId));
    } else {
      forwardTo(`/products`);
    }
  } catch (err) {
    yield put(actions.getSsoProductInfoError(err));
  }
}
function* watchAll() {
  yield all([
    takeLatest(types.BP_SSO_CREATE_NEW_PRODUCT, createSsoProduct),
    takeLatest(types.BP_SSO_GET_PRODUCT, getSsoProduct),
    takeLatest(types.BP_SSO_UPDATE_PRODUCT, updateSsoProduct),
    takeLatest(types.BP_SSO_GET_PRODUCT_INFO, getSsoProductInfo),
  ]);
}

export default watchAll;
