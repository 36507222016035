import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getSsoApprovalMemberList = (params) => {
  return api.sendPost(endpoints.GET_SSO_APPROVAL_MEMBER, params);
};

export const getSsoApprovalUserList = (params) => {
  return api.sendPost(endpoints.GET_SSO_APPROVAL_USER, params);
};

export const acceptOrRejectUser = (params) => {
  return api.sendPost(endpoints.ACCEPT_OR_REJECT_USER, params);
};

export const acceptOrRejectMember = (params) => {
  return api.sendPost(endpoints.ACCEPT_OR_REJECT_MEMBER, params);
};

export const assignExistingUser = (params) => {
  return api.sendPost(endpoints.ASSIGN_EXISTING_USER, params);
};

export const assignExistingMember = (params) => {
  return api.sendPost(endpoints.ASSIGN_EXISTING_MEMBER, params);
};

export const getSupplierApprovalGrid = (params) => {
  return api.sendPost(endpoints.GET_SUPPLIER_APPROVAL_GRID, params);
};

export const getSupplierApprovalDistinct = (params) => {
  return api.sendPost(endpoints.GET_SUPPLIER_APPROVAL_DISTINCT, params);
};

export const approveOrRejectSupplier = (params) => {
  return api.sendPost(endpoints.APPROVAL_OF_REJECT_SUPPLIER, params);
};

export const getSupplierApprovalDetail = (params) => {
  return api.sendGet(endpoints.GET_SUPPLIER_APPROVAL_DETAIL, { params });
};

export const getSupplierAssignList = (params) => {
  return api.sendGet(endpoints.GET_SUPPLIER_ASSIGN_LIST, { params });
};

export const assignSupplier = (params) => {
  return api.sendPost(endpoints.ASSIGN_SUPPLIER, params);
};
