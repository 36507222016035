import React from 'react';
import PropTypes from 'prop-types';

import { Button, Tooltip } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/common';

const ReGenerateButton = (props) => {
  const intl = useIntl();

  return (
    <Tooltip
      placement='top'
      title={intl.formatMessage(Messages?.tooltipReGenerate)}
    >
      <Button type='link' icon={<RetweetOutlined />} {...props}></Button>
    </Tooltip>
  );
};

ReGenerateButton.propTypes = {
  onClick: PropTypes.func,
};

export default ReGenerateButton;
