import * as _ from 'lodash';
import {
  LAYOUT_ADVANCED_SEARCH,
  MEMBER_TYPE_MANUFACTURER,
  MEMBER_TYPE_SHARED_ONLY,
} from 'static/Constants';

import { PERMISSION } from 'static/Permission';

import mime from 'mime-types';

import { isEqual, uniq } from 'lodash';

export const deleteObjectField = (obj, fields = [], isReturnNew = false) => {
  if (!fields) return { ...obj };
  const cloneObj = { ...obj };
  let index = -1;
  while (index++ < fields.length) {
    const prop = fields[index];
    if (isReturnNew === true) {
      if (cloneObj.hasOwnProperty(prop)) delete cloneObj[prop];
    } else {
      if (obj.hasOwnProperty(prop)) delete obj[prop];
    }
  }
  if (isReturnNew) return cloneObj;
};

export const searchTextByPatterm = (string, pattern) => {
  if (string === null || string === undefined || !pattern) return false;
  return string.match(pattern);
};

export const toUniqueList = (replaceArray) => {
  return [...new Set(replaceArray)];
};

export const replaceAll = (str, mapObj) => {
  if (_.isEqual(mapObj, {})) return str;
  var re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

export const traditionalReplaceAll = (
  str,
  target,
  replacement,
  isIgnoreCaseLevel
) => {
  return str.split(target).join(replacement);
};

export const ellipsis = (str, length) => {
  return _.truncate(str, {
    length,
  });
};

export const filterFieldByKeys = (obj, keys = []) => {
  const filtered = {};
  keys.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      filtered[key] = obj[key];
    }
  });
  return filtered;
};

export const filterKeysAndMapValue = (obj, list = []) => {
  const filtered = {};

  list.forEach((listItem) => {
    const listItemIsKey = typeof listItem === 'string';
    const key = listItemIsKey ? listItem : listItem.key;
    const newKey = listItem?.newKey;
    const mapFunction = listItem?.map;

    if (obj.hasOwnProperty(key)) {
      filtered[newKey || key] = mapFunction ? mapFunction(obj[key]) : obj[key];
    }
  });

  return filtered;
};

export const convertObjectValueToLabelInfo = (obj) => {
  return Object.entries(obj || {}).reduce((accum, [key, value]) => {
    return [...accum, { label: key, info: value }];
  }, []);
};

export const getUniqueByUidData = (array1, array2) => {
  let result = [];

  array1.forEach((item1) => {
    const foundItem = array2.find((item2) => item2?.uid === item1?.uid);
    if (!foundItem) {
      result.push(item1);
    }
  });

  return result;
};

export const parseJSON = (jsonString) => {
  let parsedObject = {};
  try {
    let parsedObject = JSON.parse(jsonString);
    return parsedObject;
  } catch (err) {
    return parsedObject;
  }
};

//todo - utils/index file should only be used for enhanced login functions
const getFileType = (file) => {
  // if (file?.type) return file.type;
  // const fileType = file?.name?.substr(file?.name?.indexOf('.') + 1);
  // if (fileType === 'rar') return 'rar';
  // if (fileType === '7z') return '7z';

  const fileTypeName = mime.lookup(file.name);

  return fileTypeName;
};

//todo - utils/index file should only be used for enhanced login functions
export const formatUploadAttachment = (files) => {
  const formatFileList = files.map((file) => ({
    //TODO: DATETIME
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    name: file.name,
    size: file.size,
    type: getFileType(file),
    uid: file.uid,
    originFileObj: file,
  }));

  return formatFileList;
};

export const checkToggleButton = (layout, permissions) => {
  const isSuperAdmin = permissions.includes(PERMISSION.SUPER_ADMIN);
  const isPermissionMember = permissions.includes(PERMISSION.VIEW_MEMBERS);
  const isPermissionAsset = permissions.includes(PERMISSION.VIEW_ASSETS);
  const isPermissionProduct = permissions.includes(PERMISSION.VIEW_PRODUCTS);
  // set default layout
  if (!layout) {
    if (isPermissionProduct || isSuperAdmin)
      return LAYOUT_ADVANCED_SEARCH.PRODUCT;

    if (isPermissionMember) return LAYOUT_ADVANCED_SEARCH.MEMBER;

    if (isPermissionAsset) return LAYOUT_ADVANCED_SEARCH.ASSET;
  }
  // toggle layout
  else {
    if (isSuperAdmin) return layout;

    if (layout === LAYOUT_ADVANCED_SEARCH.MEMBER && isPermissionMember)
      return LAYOUT_ADVANCED_SEARCH.MEMBER;

    if (layout === LAYOUT_ADVANCED_SEARCH.ASSET && isPermissionAsset)
      return LAYOUT_ADVANCED_SEARCH.ASSET;

    if (layout === LAYOUT_ADVANCED_SEARCH.PRODUCT && isPermissionProduct)
      return LAYOUT_ADVANCED_SEARCH.PRODUCT;
  }
};

export const formatObjectFormData = (object) => {
  let result = {};

  if (object) {
    for (const [key, value] of Object.entries(object)) {
      result = {
        ...result,
        [key]: value ?? null,
      };
    }
  }

  return result;
};

export const findInObject = ({ target, value, needLowercase = false }) => {
  return Object.keys(target).some((key) => {
    if (
      Array.isArray(target[key]) ||
      (typeof target[key] === 'object' && target[key] !== null)
    ) {
      return findInObject({ target: target[key], value, needLowercase });
    }

    let keyValue = `${target[key]}`;

    return needLowercase
      ? keyValue.toLocaleLowerCase().includes(`${value}`.toLocaleLowerCase())
      : keyValue.includes(`${value}`);
  });
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const checkIsSuperAdmin = (roles = []) => roles.includes('Super Admin');

export const checkAllOwnerItems = (dataGrid = []) => {
  const owners = dataGrid?.map((item) => item?.isOwner);

  return owners.length > 0 && owners.every((owner) => Boolean(owner));
};

export const checkOwnerOneItem = (dataDetail) => {
  return dataDetail?.isOwner;
};

export const checkIsSupplierMemberUser = (userInfo) => {
  return userInfo?.member?.memberType === MEMBER_TYPE_MANUFACTURER;
};
export const checkIsSharedOnlyMemberUser = (userInfo) => {
  return userInfo?.member?.memberType === MEMBER_TYPE_SHARED_ONLY;
};

// edit product / asset(s)
export const checkAllowEdit = (isOwner, isAllowEdit) => {
  return isOwner || isAllowEdit;
};

export const isStringHaveWhiteSpace = (s, trim) => {
  if (!s || typeof s !== 'string') return false;
  return trim ? s.trim().indexOf(' ') >= 0 : s.indexOf(' ') >= 0;
};

export const searchTextInString = (
  text,
  string,
  trimLength = 0,
  foundArray = []
) => {
  const searched = string.search(text);

  if (searched !== -1) {
    const subLength = searched + text.length;
    const realSearchPos = trimLength + searched;
    foundArray.push(realSearchPos);
    trimLength += subLength;
    const newString = string.substring(subLength);
    return searchTextInString(text, newString, trimLength, foundArray);
  } else {
    return foundArray;
  }
};

export const replaceAllTextInString = (
  text,
  string,
  replacement,
  searchArray = [],
  stringIdx = 0,
  replaceArray = []
) => {
  if (!string) {
    return { searchArray, replaceArray, result: replaceArray.join('') };
  }

  const currentSearchIdx = string.toLowerCase().indexOf(text.toLowerCase());

  if (currentSearchIdx !== -1) {
    const sliceRange = currentSearchIdx + text.length;
    const nextString = string.slice(sliceRange);
    const cutPart = string.slice(0, sliceRange);

    const originalText = string.slice(
      currentSearchIdx,
      currentSearchIdx + text.length
    );

    const replaceText =
      typeof replacement === 'function'
        ? replacement(originalText)
        : replacement;
    const replacedCutPart = cutPart.replace(originalText, replaceText);

    const nextArrayReplace = [...replaceArray, replacedCutPart];

    const nextStringIdx = stringIdx + currentSearchIdx + text?.length;
    const nextSearchArray = [...searchArray, stringIdx + currentSearchIdx];

    return replaceAllTextInString(
      text,
      nextString,
      replacement,
      nextSearchArray,
      nextStringIdx,
      nextArrayReplace
    );
  } else {
    const nextReplaceArray = [...replaceArray, string];
    return { searchArray, replaceArray, result: nextReplaceArray.join('') };
  }
};

export const getUpdatedKeyAndValue = (oldData, newData) => {
  const data = uniq([...Object.keys(oldData), ...Object.keys(newData)]);

  let diff = {};

  for (const key of data) {
    if (!isEqual(oldData[key], newData[key])) {
      diff = { ...diff, [key]: [oldData[key], newData[key]] };
    }
  }

  return diff;
};

export const getCurrentSlide = (ref) => {
  const { slideCount, currentSlide } = ref.current?.innerSlider?.state ?? {};
  return slideCount === currentSlide ? 0 : currentSlide;
};
