import React from 'react';

import { useDispatch } from 'react-redux';

import { Divider } from 'antd';
import { RibbonSection } from 'common/components';
import AcceptPublication from 'pages/home/ribbon/components/controls/publication/AcceptPublication';
import RejectPublication from 'pages/home/ribbon/components/controls/publication/RejectPublication';
import ReviewPublication from 'pages/home/ribbon/components/controls/publication/ReviewPublication';

import * as brandingActions from 'redux/branding/actions';
import * as gridActions from 'common/components/grid-view/controllers/actions';

const PublicationSection = (props) => {
  const { selectionPublication, sectionDisabled } = props;

  const dispatch = useDispatch();

  const handleRefetchNavigationMenu = () => {
    dispatch(brandingActions.getBrandingNoLoading());
  };

  const handleClearItemGrid = () => {
    dispatch(gridActions.updateDetailCurrentItemsSelection([]));
  };

  return (
    <RibbonSection>
      <Divider type='vertical' style={{ height: 40, margin: '4px 8px  ' }} />
      <AcceptPublication
        selectionPublication={selectionPublication}
        sectionDisabled={sectionDisabled}
        onRefetchNavigationMenu={handleRefetchNavigationMenu}
        onClearItemGrid={handleClearItemGrid}
      />

      <ReviewPublication
        isSmallBtn
        selectionPublication={selectionPublication}
        sectionDisabled={sectionDisabled}
        onRefetchNavigationMenu={handleRefetchNavigationMenu}
        onClearItemGrid={handleClearItemGrid}
      />
      <RejectPublication
        isSmallBtn
        selectionPublication={selectionPublication}
        sectionDisabled={sectionDisabled}
        onRefetchNavigationMenu={handleRefetchNavigationMenu}
        onClearItemGrid={handleClearItemGrid}
      />
      <Divider type='vertical' style={{ height: 40, margin: '4px 8px  ' }} />
    </RibbonSection>
  );
};

export default PublicationSection;
