import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  getKeyFactPanels,
  getKeySupplementPanels,
  getKeyDrugPanels,
  getPetFoodPanels,
} from './useQueryFactsPanel';

import { CustomNotification } from 'common/components';

import { sleep } from 'utils/delay';

import {
  deleteProductNutritionPanel,
  deleteProductSupplementPanel,
  deleteProductDrugFacts,
  deletePetFoodFactsPanel,
} from 'services/product';
import {
  getKeyQaSpecDrugFactPanels,
  getKeyQaSpecFactPanels,
  getKeyQaSpecPetFoodPanels,
  getKeyQaSpecSupplementPanels,
} from 'pages/qa-spec/components/qa-nutrition-data/hook';
import { useQueryQaFormulaData } from 'pages/qa-spec/components/qa-formula/queries';

export const useMutationDeleteFactsPanel = ({ id }) => {
  const queryClient = useQueryClient();

  const { invalidateQaFormulaData } = useQueryQaFormulaData({
    enabled: false,
  });

  const nutritionDeleteMutation = useMutation({
    mutationFn: deleteProductNutritionPanel,
    onSuccess: async () => {
      await sleep(500);
      queryClient.invalidateQueries({
        queryKey: getKeyFactPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecFactPanels(id),
      });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  const supplementDeleteMutation = useMutation({
    mutationFn: deleteProductSupplementPanel,
    onSuccess: async () => {
      await sleep(500);
      queryClient.invalidateQueries({
        queryKey: getKeySupplementPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecSupplementPanels(id),
      });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  const drugFactDeleteMutation = useMutation({
    mutationFn: deleteProductDrugFacts,
    onSuccess: async () => {
      await sleep(500);
      queryClient.invalidateQueries({
        queryKey: getKeyDrugPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecDrugFactPanels(id),
      });
      invalidateQaFormulaData({ productId: id });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  const petFoodDeleteMutation = useMutation({
    mutationFn: deletePetFoodFactsPanel,
    onSuccess: async () => {
      await sleep(500);
      queryClient.invalidateQueries({
        queryKey: getPetFoodPanels(id),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecPetFoodPanels(id),
      });
    },
  });

  return {
    nutritionDeleteMutation,
    supplementDeleteMutation,
    drugFactDeleteMutation,
    petFoodDeleteMutation,
  };
};
