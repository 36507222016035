import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Messages from 'i18n/messages/gridView';
import { useIntl } from 'react-intl';

import { CheckCircleTwoTone, FileDoneOutlined } from '@ant-design/icons';
import { Button, Form, message, Row, Empty, notification } from 'antd';

import 'common/components/ag-grid/LicenseManagerAgGrid';
import AgGrid from 'common/components/ag-grid/AgGrid';

import MutationGrid from './MutationGrid';
import ModalFormEdit from './ModalFormEdit';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import * as actionsGlobal from 'redux/global/actions';
import * as actionsRibbon from 'redux/ribbon/actions';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import * as endpoints from 'services/query/endpoints';
import { editQuery } from 'services/query';

import { EVENT, RIBBON_TYPES } from 'static/Constants';

import {
  defaultColumns,
  actionFieldColumn,
  isDefaultFieldColumn,
  shareFieldColumn,
} from './utils';

import { updateSearchText } from 'pages/home/utils';
import { getGridName } from 'utils/getGridName';
import {
  updateQueryConditions,
  updateQueryAdvance,
  updateIdQuery,
} from 'utils/queryCondition';

import { GRID_ID } from 'common/components/grid-view/utils';
import useGetDataIdQuery from 'hooks/useGetDataIdQuery';
import { useMutation } from 'hooks/useMutation';

import './style.less';

const GridQueryTable = (props) => {
  const {
    turnOffModal,
    setIsMounted,
    selectedQuery,
    setSelectedQuery,
    editModalVisible,
    setEditModalVisible,
  } = props;

  const gridApiRef = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [gridApi, setGridApi] = useState(null);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const { gridQuery } = useSelector(selectorsGridView.makeSelectGridConfig());
  const {
    [GRID_ID.SPLASH_FORM_HISTORY_BY_ENTITY]: [pageNumber, pageSize],
  } = useSelector(selectorsGridView.makeSelectPageCurrent());

  const pathname = window.location.pathname;

  const gridName = getGridName(pathname);

  const [{ status: statusUpdate, error }, editConfig, setDefaultState] =
    useMutation(editQuery);

  useEffect(() => {
    if (statusUpdate === 'success') {
      notification.success({
        message: intl.formatMessage(Messages.updateConfigSuccess),
      });
      gridApi.rivirPurgeServerSideCache();
      setEditModalVisible(false);
      setIsMounted((c) => !c);
      setDefaultState();
    }

    if (statusUpdate === 'error') {
      notification.error({
        message: intl.formatMessage(Messages.updateConfigError),
      });
      turnOffModal();
      setDefaultState();
    }
  }, [statusUpdate]);

  useEffect(() => {
    return () => {
      setIsMounted((c) => !c);
    };
  }, [setIsMounted]);

  useEffect(() => {
    dispatch(actionsGlobal.userForSharingColumnInfo('user-for-sharing'));
    dispatch(
      actionsGridView.getGridQuery({
        gridName,
        pageIndex: 1,
        pageSize: 20,
      })
    );
  }, [dispatch, gridName]);

  const newColumns = [
    ...defaultColumns,
    {
      ...isDefaultFieldColumn,
      cellRenderer: ({ data }) => <IsDefaultIcon data={data} />,
    },
  ];

  const handleCallbackDataListSelection = () => {
    if (!gridApi) return;

    const data = gridApi.getSelectedRows()[0];

    setSelectedQuery(data);
  };

  const handleSetDefault = () => {
    const { query, id } = selectedQuery;
    const { queryConditions, columns, ribbonTypes, searchText } =
      JSON.parse(query);

    message.success(intl.formatMessage(Messages.applyConfig));
    turnOffModal();
    dispatch(actionsGridView.myQueryDoubleClick(true));
    if (ribbonTypes === RIBBON_TYPES.DETAILSVIEW) {
      dispatch(actionsGridView.chooseGridColumns(columns, id));
    }

    updateQueryConditions(dispatch, queryConditions, window.location.pathname);
    updateQueryAdvance(dispatch, queryConditions, window.location.pathname);
    updateIdQuery(dispatch, id, window.location.pathname);

    dispatch(actionsGridView.checkQueryCondition(true));
    dispatch(actionsRibbon.changeType(ribbonTypes));
    dispatch(actionsGridView.updateVisible(false));
    dispatch(actionsGridView.toggleRefreshQuery(true));

    updateSearchText(dispatch, searchText, pathname);
  };

  const onPaginationChanged = (params) => {
    const data = params.api.getSelectedRows()[0];

    setSelectedQuery(data);
  };

  const onReloadGrid = () => {
    setShouldReloadGrid(true);
  };

  useDocumentAttachEvent({
    name: EVENT.RELOAD_MANAGE_QUERY_TABLE,
    handler: onReloadGrid,
  });

  const onReloadGridCallback = () => {
    setShouldReloadGrid(false);
  };

  const isEmptyGridQuery = gridQuery.length === 0;

  return (
    <>
      <ModalFormEdit
        editedData={selectedQuery || {}}
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        editConfig={editConfig}
        setIsMounted={setIsMounted}
        statusUpdate={statusUpdate}
      />
      <div
        style={{
          width: '100%',
          height: 'calc(80vh - 100px)',
          marginTop: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isEmptyGridQuery ? (
          <Empty />
        ) : (
          <div id='my-grid-link-asset' className='ag-theme-alpine'>
            <AgGrid
              columnDefs={newColumns}
              urlGrid={endpoints.QUERY_DETAIL_GRID}
              paramsGrid={{ gridName }}
              responseParams='data'
              callbackDataListSelection={handleCallbackDataListSelection}
              getGridApi={(gridApi) => {
                setGridApi(gridApi);
                gridApiRef.current = gridApi;
              }}
              gridConfigProps={{
                rowSelection: 'single',
              }}
              gridId={GRID_ID.MANAGE_QUERY}
              pageSizePagination={pageSize}
              pageNumberPagination={pageNumber}
              onPaginationChanged={onPaginationChanged}
              shouldReloadDetailGrid={shouldReloadGrid}
              onReloadGridCallback={onReloadGridCallback}
              notShowHeaderCheckbox
              // showCheckboxSelectionRender={false}
            />
          </div>
        )}
      </div>
      <Row justify='end' style={{ marginTop: 5 }}>
        {isEmptyGridQuery ? null : (
          <Button
            type='primary'
            disabled={!selectedQuery || isEmptyGridQuery}
            onClick={handleSetDefault}
            icon={<FileDoneOutlined />}
            className='grid-configuration__btn'
          >
            Apply
          </Button>
        )}
      </Row>
    </>
  );
};

const IsDefaultIcon = ({ data }) => {
  const idQueryActive = useGetDataIdQuery(window.location.pathname);

  return data?.id === idQueryActive ? (
    <CheckCircleTwoTone twoToneColor='#52c41a' style={{ fontSize: 20 }} />
  ) : null;
};

export default GridQueryTable;
