import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Badge, Typography, Avatar } from 'antd';
import {
  FieldStringOutlined,
  CalendarOutlined,
  CheckOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import classnames from 'classnames';
import './EntityDraggable.less';
const primaryButton = 0;

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

const EntityDraggable = (props) => {
  const {
    task,
    index,
    isSelected,
    selectionCount,
    isGhosting,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    checkShow,
  } = props;

  const onKeyDown = (event, provided, snapshot) => {
    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }

    // we are using the event for selection
    event.preventDefault();

    performAction(event);
  };

  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    performAction(event);
  };

  const onTouchEnd = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    event.preventDefault();
    toggleSelectionInGroup(task?.mapFieldName + '_entity');
  };

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event) => event.shiftKey;

  const performAction = (event) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(task?.mapFieldName + '_entity', 'entity');
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(task?.mapFieldName + '_entity', 'entity');
      return;
    }

    toggleSelection(task?.mapFieldName + '_entity', 'entity');
  };

  return (
    <div style={{ display: checkShow ? 'none' : 'block' }}>
      <Draggable draggableId={task?.mapFieldName + '_entity'} index={index}>
        {(provided, snapshot) => {
          const shouldShowSelection = snapshot.isDragging && selectionCount > 1;

          return (
            <div style={{ display: 'flex' }}>
              {!snapshot.isDragging && (
                <div
                  style={{
                    marginLeft: 32,
                  }}
                ></div>
              )}

              <div
                className={classnames({
                  'entities-draggable__card': true,
                  'entities-draggable__card--selected': isSelected,
                  'entities-draggable__card--ghosting': isGhosting,
                  'entities-draggable__card--drag': snapshot.isDragging,
                })}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                onClick={onClick}
                onTouchEnd={onTouchEnd}
                onKeyDown={(event) => onKeyDown(event, provided, snapshot)}
              >
                <Badge
                  className='entities-draggable__badge'
                  count={shouldShowSelection ? selectionCount : null}
                >
                  {task?.dataType === 'string' && (
                    <Avatar
                      className='entities-draggable__data-type'
                      shape='square'
                      icon={<FieldStringOutlined />}
                    />
                  )}
                  {task?.dataType === 'datetime' && (
                    <Avatar
                      className='entities-draggable__data-type'
                      shape='square'
                      icon={<CalendarOutlined />}
                    />
                  )}
                  {task?.dataType === 'bool' && (
                    <Avatar
                      className='entities-draggable__data-type'
                      shape='square'
                      icon={<CheckOutlined />}
                    />
                  )}
                  {(task?.dataType === 'int' ||
                    task?.dataType === 'double' ||
                    task?.dataType === 'double?' ||
                    task?.dataType === 'long' ||
                    task?.dataType === 'decimal') && (
                    <Avatar
                      className='entities-draggable__data-type'
                      shape='square'
                      icon={<NumberOutlined />}
                    />
                  )}
                  <Typography.Text
                    className='entities-draggable__label'
                    ellipsis
                  >
                    {task?.displayName}
                  </Typography.Text>
                </Badge>
              </div>
              {provided.placeholder}
            </div>
          );
        }}
      </Draggable>
    </div>
  );
};

EntityDraggable.propTypes = {
  task: PropTypes.object,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  selectionCount: PropTypes.number,
  isGhosting: PropTypes.bool,
  toggleSelection: PropTypes.func,
  toggleSelectionInGroup: PropTypes.func,
  multiSelectTo: PropTypes.func,
};

export default React.memo(EntityDraggable);
