import React, { useState, useEffect, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Steps, Typography, Row, Col, Input, Checkbox } from 'antd';
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  UndoOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  SaveOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import {
  FormAddButton as ActionButton,
  WithLoading,
  CustomNotification,
  dialogFunction,
} from 'common/components';
import { MemberRoleSectionWrapper as ConfigSectionWrapper } from 'pages/role/components';
import { DEFAULT_FIELDS_GRID_PRODUCTS as defaultFieldsProduct } from 'static/Constants';

import {
  PropertiesList,
  DragDropContextWrapper,
  SelectedProperties,
  PropertiesSearch,
} from 'common/components/mapping-properties/CustomizeGrid';

import { saveGridConfig as saveGridConfigAction } from 'pages/branded-products/controllers/actions';
import { makeSelectApplyGridConfig } from 'pages/branded-products/controllers/selectors';

import ShowGridConfig from 'common/components/mapping-properties/ShowGridConfig';

import { saveGridConfig } from 'services/grid';

import { useGridConfig, useGetPropertiesCustomizedGrid } from './hooks';

import {
  getFoundProperty,
  getSelectedColumns,
  filterSelectedProperties,
  mappingGridProperties,
  multiSelect,
  defaultColumns,
} from './utils';

import './GridConfiguration.less';

const { Step } = Steps;

const DROPPABLE_ID_SELECTED_PROPERTIES = 'selected-properties';

const GridConfiguration = ({
  selectedConfig,
  visible,
  onCancel,
  onReloadGrid,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formGrid, setFromGrid] = useState({
    name: '',
    isDefault: false,
  });
  const [statusSubmitConfig, setStatusSubmitConfig] = useState('idle');
  const [errorInput, setErrorInput] = useState(false);

  const applyGridConfigData = useSelector(makeSelectApplyGridConfig());

  const dispatch = useDispatch();

  const gridRef = useRef();

  const isSetupStep = currentStep === 1;

  const {
    stateConfig,
    handleUpdateStatusGrid,
    handleAddNewProperty,
    handleToggleProperty,
    handleColumnResized,
    handleColumnMoved,
    handleDeleteProperty,
    handleSetDefaultProperties,
    handleResetColumns,
    handleSwapProperty,
    handleExpandGrid,
    handleCollapseGrid,
    handleUpdateSearchText,
    handleUpdateModuleProperties,
    handleUpdateSelectedProperties,
    handleUpdatePropertyIds,
    handleUpdateDraggingId,
    handleResetDefaultSelectedProperties,
    handleUpdateKeyCollapse,
  } = useGridConfig();

  const {
    searchModuleProperties,
    selectedProperties,
    selectedPropertyIds,
    draggingPropertyId,
    statusChangeGrid,
    statusChangeProperty,
    fullPreviewGrid,
    keyCollapse,
  } = stateConfig;

  const { propertiesCustomizedGrid, loading } = useGetPropertiesCustomizedGrid({
    isEnabled: Boolean(visible),
  });

  const mappingProperties = useMemo(
    () => mappingGridProperties(propertiesCustomizedGrid),
    [propertiesCustomizedGrid]
  );

  useEffect(() => {
    const onWindowClick = (event) => {
      if (event.defaultPrevented) {
        return;
      }
      handleUpdatePropertyIds([]);
    };

    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, [handleUpdatePropertyIds]);

  useEffect(() => {
    // Create
    if (!selectedConfig) {
      handleUpdateSelectedProperties(defaultColumns);
    } else {
      const { configName, isDefault, jsonConfig } = selectedConfig;

      const selectedProperties = JSON.parse(jsonConfig);

      setFromGrid({
        ...formGrid,
        name: configName,
        isDefault: isDefault,
      });

      if (selectedProperties?.columns) {
        handleUpdateSelectedProperties(selectedProperties.columns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpdateSelectedProperties, JSON.stringify(selectedConfig)]);

  useEffect(() => {
    handleUpdateModuleProperties(mappingProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpdateModuleProperties, JSON.stringify(mappingProperties)]);

  const handleToggleSelection = (propertyId) => {
    const wasSelected = selectedPropertyIds.includes(propertyId);

    const newPropertyIds = (() => {
      // Property was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [propertyId];
      }

      // Property was part of a selected group
      // will now become the only selected item
      if (selectedPropertyIds.length > 1) {
        return [propertyId];
      }

      // Property was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    handleUpdatePropertyIds(newPropertyIds);
  };

  const handleToggleSelectionInGroup = (propertyId) => {
    const index = selectedPropertyIds.indexOf(propertyId);

    // if not selected - add it to the selected items
    if (index === -1) {
      const newSelectedIds = [...selectedPropertyIds, propertyId];
      handleUpdatePropertyIds(newSelectedIds);
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedPropertyIds];
    shallow.splice(index, 1);
    handleUpdatePropertyIds(shallow);
  };

  const handleMultiSelectTo = (property) => {
    const updated = multiSelect(
      searchModuleProperties,
      selectedPropertyIds,
      property
    );

    if (!updated) {
      return;
    }

    handleUpdatePropertyIds(updated);
  };

  const handleDragStart = (start) => {
    const id = start.draggableId;

    const fieldFullPath = id.split('-')?.[3];

    if (!fieldFullPath) return;

    const selected = selectedPropertyIds.find((id) => id === fieldFullPath);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      handleUpdatePropertyIds([]);
    }

    handleUpdateDraggingId(fieldFullPath);
  };

  const handleDragEnd = (valueEnd) => {
    const { source, destination, draggableId } = valueEnd;
    const { droppableId: droppableIdSource } = source;

    // nothing to do
    if (!destination || valueEnd?.reason === 'CANCEL') {
      handleUpdateDraggingId(null);
      handleUpdatePropertyIds([]);
      return;
    }

    if (droppableIdSource === DROPPABLE_ID_SELECTED_PROPERTIES) {
      // Delete a property
      if (destination.droppableId === DROPPABLE_ID_SELECTED_PROPERTIES) {
        // Sync property and column
        const [moduleName, fieldName, isCustomField] = draggableId.split('-');

        const newProperty = getFoundProperty(
          selectedProperties,
          moduleName,
          fieldName,
          isCustomField
        );

        if (
          parseInt(currentStep) === 1 &&
          newProperty.isShow &&
          destination?.index !== source.index
        ) {
          handleUpdateStatusGrid('loading');
        }

        const { droppableId: droppableIdDestination, index: indexDestination } =
          destination;

        if (droppableIdSource === droppableIdDestination) {
          handleSwapProperty(source.index, indexDestination);
          handleUpdateDraggingId(null);
          return;
        }
      }
      return;
    } else {
      if (!destination) return;

      if (destination?.droppableId === source?.droppableId) return;

      const { index: indexDestination } = destination;
      const [, , , fieldFullPath] = draggableId.split('-');

      handleAddNewProperty(
        propertiesCustomizedGrid,
        fieldFullPath,
        indexDestination,
        selectedPropertyIds
      );

      handleUpdateDraggingId(null);
      handleUpdatePropertyIds([]);
    }
  };

  const handleSubmitConfiguration = async (params) => {
    setStatusSubmitConfig('loading');

    try {
      const response = await saveGridConfig(params);

      if (response?.isSuccess) {
        if (params?.configId) {
          CustomNotification.success('Edit configuration successfully!');
        } else {
          CustomNotification.success('Create configuration successfully!');
        }

        if (params?.isDefault && !applyGridConfigData?.isApply) {
          dispatch(
            saveGridConfigAction({
              jsonConfig: params.jsonConfig,
              selectedColumns: params.selectedColumns,
            })
          );
        } else if (
          // if selected config is default config, but after setting default = false. Will reset columns
          params?.isDefault === false &&
          selectedConfig?.isDefault === true &&
          !applyGridConfigData?.isApply
        ) {
          dispatch(
            saveGridConfigAction({
              jsonConfig: null,
              selectedColumns: null,
            })
          );
        }
        setStatusSubmitConfig('success');
        onCancel();
        onReloadGrid();
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        setStatusSubmitConfig('error');
      }
    } catch (error) {
      console.error('Error', error);
    } finally {
      setTimeout(() => {
        setStatusSubmitConfig('idle');
      }, 1000);
    }
  };

  const confirmSubmitConfiguration = async () => {
    const { name, isDefault } = formGrid;

    if (!name.trim()) {
      setErrorInput(true);
      CustomNotification.error('Please enter a name configuration!');
      return;
    }

    const filetedProperties = filterSelectedProperties(selectedProperties);

    const cols = gridRef.current.columnApi.getAllGridColumns();
    const selectedCols = getSelectedColumns(cols);

    const params = {
      configId: selectedConfig ? selectedConfig.configId : 0,
      configName: name,
      gridName: 'product-detail-grid',
      isDefault,
      jsonConfig: JSON.stringify({
        columns: filetedProperties,
      }),
      selectedColumns: selectedCols,
    };

    if (filetedProperties.length === 0) {
      dialogFunction({
        type: 'warn',
        content: 'Do you want to create this configuration without columns?',
        onOk: () => handleSubmitConfiguration(params),
      });
    } else {
      handleSubmitConfiguration(params);
    }
  };

  return (
    <>
      <Steps current={currentStep} className='grid-configuration__steps'>
        <Step
          key={0}
          title={
            <Typography.Text strong style={{ fontSize: 16 }}>
              Choose fields
            </Typography.Text>
          }
        />
        <Step
          key={1}
          title={
            <Typography.Text strong style={{ fontSize: 16 }}>
              Setup grid
            </Typography.Text>
          }
        />
      </Steps>

      <Row gutter={[32, 0]} className='grid-configuration__list'>
        {currentStep === 0 ? (
          <DragDropContextWrapper
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Col span={12} style={{ height: '100%' }}>
              <ConfigSectionWrapper
                title='All fields'
                className='grid-configuration__wrapper'
              >
                <WithLoading loading={loading}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <PropertiesSearch onSearch={handleUpdateSearchText} />
                    <PropertiesList
                      activeKeyCollapse={keyCollapse}
                      defaultFieldsProduct={defaultFieldsProduct}
                      modulesNameMapping={searchModuleProperties}
                      selectedProperties={selectedProperties}
                      draggingPropertyId={draggingPropertyId}
                      selectedPropertyIds={selectedPropertyIds}
                      handleChangeKeyCollapse={handleUpdateKeyCollapse}
                      onToggleSelection={handleToggleSelection}
                      onToggleSelectionInGroup={handleToggleSelectionInGroup}
                      onMultiSelectTo={handleMultiSelectTo}
                    />
                  </div>
                </WithLoading>
              </ConfigSectionWrapper>
            </Col>

            <Col span={12} style={{ height: '100%' }}>
              <ConfigSectionWrapper
                title='Selected fields'
                className='grid-configuration__wrapper'
              >
                <div
                  className='grid-configuration__reset-container'
                  style={{ textAlign: 'right' }}
                >
                  <ActionButton
                    text='Reset Default Fields'
                    icon={<UndoOutlined />}
                    onClick={handleResetDefaultSelectedProperties}
                  />
                </div>

                <SelectedProperties
                  selectedProperties={selectedProperties}
                  onDeleteProperty={handleDeleteProperty}
                />
              </ConfigSectionWrapper>
            </Col>
          </DragDropContextWrapper>
        ) : (
          <>
            {fullPreviewGrid === false && (
              <Col span={12} style={{ height: '100%' }}>
                <DragDropContextWrapper
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                >
                  <WithLoading loading={statusChangeProperty === 'loading'}>
                    <ConfigSectionWrapper
                      title='Selected fields'
                      className='grid-configuration__wrapper'
                    >
                      {selectedProperties.length > 0 ? (
                        <div
                          className='grid-configuration__reset-container'
                          style={{ textAlign: 'right' }}
                        >
                          <ActionButton
                            text='Reset'
                            icon={<UndoOutlined />}
                            onClick={handleResetColumns}
                          />
                        </div>
                      ) : null}

                      <SelectedProperties
                        isSetupStep={isSetupStep}
                        onToggleProperty={handleToggleProperty}
                        selectedProperties={selectedProperties}
                      />

                      {/* How to pass a function to children ??? */}
                      {/* <SelectedProperties selectedProperties={selectedProperties}>
                      <Col
                        flex='40px'
                        style={{
                          cursor: 'pointer',
                          textAlign: 'center',
                          zIndex: 99,
                        }}
                      >
                        <EyeOutlined
                          style={{
                            fontSize: 16,
                            color: '#8c8c8c',
                          }}
                        />
                      </Col>
                    </SelectedProperties> */}
                    </ConfigSectionWrapper>
                  </WithLoading>
                </DragDropContextWrapper>
              </Col>
            )}

            <Col
              span={fullPreviewGrid ? 24 : 12}
              style={{ height: '100%' }}
              className={
                fullPreviewGrid ? 'grid-configuration__preview-full-grid' : ''
              }
            >
              <ConfigSectionWrapper
                title='Preview grid'
                className='grid-configuration__wrapper'
              >
                <Row
                  align='middle'
                  justify='space-between'
                  className='grid-configuration__name-container'
                >
                  <Col span={20}>
                    <Input
                      className={
                        errorInput
                          ? 'grid-configuration__input grid-configuration__input--error'
                          : 'grid-configuration__input'
                      }
                      allowClear
                      placeholder='Input name configuration'
                      value={formGrid.name}
                      onChange={(event) => {
                        if (event.target.value.trim()) {
                          setErrorInput(false);
                        }
                        setFromGrid({
                          ...formGrid,
                          name: event.target.value,
                        });
                      }}
                    />
                    <Checkbox
                      checked={formGrid.isDefault}
                      onChange={(event) => {
                        setFromGrid({
                          ...formGrid,
                          isDefault: event.target.checked,
                        });
                      }}
                    >
                      Default Grid
                    </Checkbox>
                  </Col>

                  <Col span={4} style={{ textAlign: 'right' }}>
                    {fullPreviewGrid ? (
                      <ActionButton
                        text='Collapse'
                        type='default'
                        icon={<FullscreenExitOutlined />}
                        onClick={handleCollapseGrid}
                      />
                    ) : (
                      <ActionButton
                        text='Expand'
                        type='default'
                        icon={<FullscreenOutlined />}
                        onClick={handleExpandGrid}
                      />
                    )}
                  </Col>
                </Row>

                <ShowGridConfig
                  gridRef={gridRef}
                  selectedProperties={selectedProperties}
                  statusChangeGrid={statusChangeGrid}
                  onColumnMoved={(event) => handleColumnMoved(event, gridRef)}
                  onColumnResized={handleColumnResized}
                />
              </ConfigSectionWrapper>
            </Col>
          </>
        )}
      </Row>

      <Row
        style={{ marginTop: 12 }}
        justify={currentStep === 1 ? 'end' : 'space-between'}
        align='center'
      >
        {currentStep === 1 ? null : (
          <Col>
            <Typography.Text strong type='warning'>
              <WarningOutlined style={{ marginRight: 4 }} />
              Drag fields to the right area to add new columns
            </Typography.Text>
          </Col>
        )}

        <Col>
          {currentStep === 0 ? (
            <ActionButton
              text='Next'
              icon={<ArrowRightOutlined />}
              onClick={() => {
                setCurrentStep(currentStep + 1);
                handleSetDefaultProperties();
                handleUpdateSearchText('');
              }}
            />
          ) : (
            <>
              <ActionButton
                text='Back to fields'
                icon={<ArrowLeftOutlined />}
                type='default'
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                  handleCollapseGrid();
                }}
              />
              <ActionButton
                icon={<SaveOutlined />}
                text='Save'
                style={{ marginLeft: 12 }}
                onClick={confirmSubmitConfiguration}
                loading={statusSubmitConfig === 'loading'}
                disabled={formGrid?.name === ''}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default GridConfiguration;
