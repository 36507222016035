import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { SaveOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

const SaveQueryControl = ({ onClick, disabled }) => {
  const onClickBtn = () => {
    onClick && onClick();
  };

  return (
    <RibbonButton
      icon={<SaveOutlined />}
      label={Messages.saveQuery}
      onClick={onClickBtn}
      disabled={disabled}
    />
  );
};

SaveQueryControl.propTypes = {
  confirmSaveQuery: PropTypes.func,
};

export default SaveQueryControl;
