import * as actions from './constants';

export function getAplDetail(params) {
  return {
    type: actions.GET_APL_DETAIL,
    params,
  };
}
export function getAplDetailSuccess(aplDetail) {
  return {
    type: actions.GET_APL_DETAIL_SUCCESS,
    aplDetail,
  };
}
export function getAplDetailError(error) {
  return {
    type: actions.GET_APL_DETAIL_ERROR,
    error,
  };
}

export function updateAplDetailFilter(aplDetailFilter) {
  return {
    type: actions.UPDATE_APL_DETAIL_FILTER,
    aplDetailFilter,
  };
}

export function getAplProductList(params) {
  return {
    type: actions.GET_APL_PRODUCT_LIST,
    params,
  };
}
export function getAplProductListSuccess(aplProductList) {
  return {
    type: actions.GET_APL_PRODUCT_LIST_SUCCESS,
    aplProductList,
  };
}
export function getAplProductListError(error) {
  return {
    type: actions.GET_APL_PRODUCT_LIST_ERROR,
    error,
  };
}

export function aplProductGridColumnInfo(gridName) {
  return {
    type: actions.APL_PRODUCT_GRID_COLUMN_INFO,
    gridName,
  };
}
export function aplProductGridColumnInfoSuccess(columnProductList) {
  return {
    type: actions.APL_PRODUCT_GRID_COLUMN_INFO_SUCCESS,
    columnProductList,
  };
}
export function aplProductGridColumnInfoError(error) {
  return {
    type: actions.APL_PRODUCT_GRID_COLUMN_INFO_ERROR,
    error,
  };
}

export function updateAplProductListSelect(aplProductListSelect) {
  return {
    type: actions.UPDATE_APL_PRODUCT_LIST_SELECT,
    aplProductListSelect,
  };
}

export function updateAplProductDetailListSelect(aplProductDetailListSelect) {
  return {
    type: actions.UPDATE_APL_PRODUCT_DETAIL_LIST_SELECT,
    aplProductDetailListSelect,
  };
}

export function updateAplDetailSearchText(aplDetailSearchText) {
  return {
    type: actions.UPDATE_APL_DETAIL_SEARCH_TEXT,
    aplDetailSearchText,
  };
}

export function toggleReloadGrid(value) {
  return {
    type: actions.TOGGLE_RELOAD_GRID_APL_DETAIL,
    value,
  };
}

export function getAplCommentList(params) {
  return {
    type: actions.GET_APL_COMMENT_LIST,
    params,
  };
}
export function getAplCommentListSuccess(aplCommentList) {
  return {
    type: actions.GET_APL_COMMENT_LIST_SUCCESS,
    aplCommentList,
  };
}
export function getAplCommentListError(error) {
  return {
    type: actions.GET_APL_COMMENT_LIST_ERROR,
    error,
  };
}
