import { useState, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { useFetch } from 'hooks/useAsync';

import { getAllRows } from 'pages/qa-spec/utils';
import { sleep } from 'utils/delay';

export const useQaSpecGrid = (props = {}) => {
  const {
    gridData = [],
    gridInst,
    isGridEditing,
    customInitRowData = (gridData) => gridData,
    resetGridCallback,
  } = props;

  const [rowData, setRowData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const initRowData = () => {
    if (isGridEditing) return;

    const transformedRowData = customInitRowData(gridData);

    setRowData(transformedRowData || []);

    mapUidData();
  };

  const mapUidData = () => {
    setRowData((prevState) =>
      prevState?.map((dataItem) => {
        const newUuid = uuidv4();

        return { ...dataItem, uuid: dataItem?.id ?? newUuid };
      })
    );
  };

  const selectGridRow = (row) => {
    const selectedRows = gridInst.current.api.getSelectedNodes();

    //* clear selected if row === null
    setSelectedRow(row === null ? null : selectedRows[0]);
  };

  const addGridRow = (initialRowData = {}) => {
    const newUuid = uuidv4();

    const allRowData = getAllRows(gridInst);

    const newRowIndex = allRowData?.length;

    gridInst.current.api.applyTransaction({
      add: [{ uuid: newUuid, ...initialRowData }],
      addIndex: newRowIndex,
    });

    gridInst.current.api.ensureIndexVisible(newRowIndex);

    const newRow = gridInst.current.api.getRowNode(newUuid);
    newRow.setSelected(true, true);
  };

  const deleteSelectedGridRow = () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();

    const removeList = selectedRows.map(
      (selectedRowItem) => selectedRowItem?.data
    );

    gridInst.current.api.applyTransaction({
      remove: removeList,
    });

    setSelectedRow(null);
  };

  const resetGridData = async () => {
    gridInst.current.api.stopEditing();

    gridInst.current.api.setRowData(_.cloneDeep(rowData));

    setSelectedRow(null);
    await sleep(1000);
    resetGridCallback && resetGridCallback();
  };

  useEffect(() => {
    initRowData();
  }, [JSON.stringify(gridData)]);

  return {
    gridInst,
    rowData,
    setRowData,
    selectedRow,
    setSelectedRow,
    selectGridRow,
    addGridRow,
    deleteSelectedGridRow,
    resetGridData,
    mapUidData,
  };
};
