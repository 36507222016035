import React from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import classnames from 'classnames';

import {
  Row,
  Col,
  Typography,
  Avatar,
  Input,
  Empty,
  Tooltip,
  Button,
  Badge,
} from 'antd';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  DragOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import { CollapseView } from 'pages/product-full-view/components/product-detail-view/ShareComponents';

import { AvatarPropertiesItem } from './MappingSourceProperties';

import { DEFAULT_FIELDS_GRID_PRODUCTS } from 'static/Constants';

import './CustomizeGrid.less';

const { Text } = Typography;
const { Search } = Input;

export const DragDropContextWrapper = ({
  onDragStart,
  onDragEnd,
  children,
}) => {
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {children}
    </DragDropContext>
  );
};

export const PropertiesSearch = ({ onSearch }) => {
  return (
    <Search
      allowClear
      placeholder='Input search text...'
      style={{ marginBottom: 8 }}
      onSearch={onSearch}
    />
  );
};

const getKeyCollapseView = (module) => {
  return module.isCustomField ? module.customFieldCompanyId : module.moduleName;
};

export const PropertiesList = (props) => {
  const {
    activeKeyCollapse,
    modulesNameMapping,
    selectedProperties,
    selectedPropertyIds,
    draggingPropertyId = [],
    defaultFieldsProduct,
    handleChangeKeyCollapse,
    onMultiSelectTo = () => {},
    onToggleSelection = () => {},
    onToggleSelectionInGroup = () => {},
  } = props;
  return (
    <Row className='module-properties-list scroller'>
      <Col span={24}>
        {modulesNameMapping.map((module, index) => (
          <CollapseView
            className='module-properties-list__collapse'
            collapsible='header'
            headerPanel={
              <Typography.Title level={5}>
                {module.moduleDisplayName}
              </Typography.Title>
            }
            keyPanel={getKeyCollapseView(module)}
            expandIconPosition='left'
            key={getKeyCollapseView(module)}
            onChange={handleChangeKeyCollapse}
            activeKey={activeKeyCollapse}
            destroyInactivePanel
          >
            <Droppable
              key={index}
              index={index}
              droppableId={`list-properties-${index}`}
              style={{ paddingLeft: 24, paddingRight: 8 }}
              isDropDisabled={true}
            >
              {(dropProvided, dropSnapshot) => (
                <div
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                >
                  {dropProvided.placeholder}
                  <PropertiesItem
                    defaultFieldsProduct={defaultFieldsProduct}
                    properties={module.moduleProperties}
                    selectedPropertyIds={selectedPropertyIds}
                    selectedProperties={selectedProperties}
                    draggingPropertyId={draggingPropertyId}
                    onToggleSelection={onToggleSelection}
                    onToggleSelectionInGroup={onToggleSelectionInGroup}
                    onMultiSelectTo={onMultiSelectTo}
                  />
                </div>
              )}
            </Droppable>
          </CollapseView>
        ))}
      </Col>
    </Row>
  );
};

const getKeyDraggable = (property) => {
  if (property.isCustomField) {
    return `${property.customFieldCompanyId}-${property.fieldName}-${property.isCustomField}-${property.fieldFullPath}`;
  }

  if (property.moduleName) {
    return `${property.moduleName}-${property.fieldName}-${property.isCustomField}-${property.fieldFullPath}`;
  }

  return `-${property.fieldName}-${property.isCustomField}-${property.fieldFullPath}`;
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const checkIsDefaultProperties = (selectedProperties, fieldName) => {
  const fieldsName = selectedProperties.map((p) => p.fieldName);

  if (
    fieldsName.includes(fieldName) &&
    DEFAULT_FIELDS_GRID_PRODUCTS.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

export const PropertiesItem = React.memo((props) => {
  const {
    properties,
    selectedProperties,
    selectedPropertyIds,
    draggingPropertyId,
    onToggleSelection,
    onToggleSelectionInGroup,
    defaultFieldsProduct,
    onMultiSelectTo,
  } = props;

  const handleClickProperty = (event, property) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    performAction(event, property);
  };

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event) => event.shiftKey;

  const performAction = (event, property) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      onToggleSelectionInGroup(property.fieldFullPath);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      onMultiSelectTo(property);
      return;
    }

    onToggleSelection(property.fieldFullPath);
  };

  return (
    <>
      {properties.map((property, index) => {
        const wasChosen =
          selectedProperties.findIndex(
            (p) => p.fieldFullPath === property.fieldFullPath
          ) !== -1;

        const isSelected = selectedPropertyIds?.includes(
          property.fieldFullPath
        );

        const isGhosting =
          isSelected &&
          Boolean(draggingPropertyId) &&
          draggingPropertyId !== property.fieldFullPath;

        const isSelectedDefaultField = checkIsDefaultProperties(
          selectedProperties,
          property.fieldName
        );
        const isDisplayDefault = defaultFieldsProduct?.includes(
          property.fieldFullPath
        );

        return (
          <Draggable
            key={getKeyDraggable(property)}
            draggableId={getKeyDraggable(property)}
            index={index}
            isDragDisabled={wasChosen || isSelectedDefaultField}
          >
            {(dragProvided, dragSnapshot) => {
              const shouldShowSelection =
                dragSnapshot.isDragging && selectedPropertyIds?.length > 1;

              return (
                <Row
                  ref={dragProvided.innerRef}
                  gutter={4}
                  align='middle'
                  className={classnames({
                    'customize-fields-item': true,
                    'customize-fields-item__isDragging':
                      dragSnapshot.isDragging,
                    'customize-fields-item__isSelected': isSelected,
                    'customize-fields-item__isGhosting': isGhosting,
                    'customize-fields-item__wasChosen': wasChosen,
                  })}
                  onClick={(event) => handleClickProperty(event, property)}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                >
                  {dragProvided.placeholder}
                  <Badge
                    count={
                      shouldShowSelection ? selectedPropertyIds.length : null
                    }
                    className='customize-fields-item__badge'
                  >
                    <PropertyItem property={property}>
                      {isDisplayDefault ? (
                        <>
                          {property.displayName}
                          <Badge
                            count={'Default'}
                            style={{
                              backgroundColor: '#13c2c2',
                              fontSize: 13,
                              marginBottom: 4,
                              marginLeft: 6,
                            }}
                          />
                        </>
                      ) : (
                        property.displayName
                      )}
                    </PropertyItem>
                  </Badge>
                </Row>
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
});

const DeleteButtonProperty = ({ property, onDeleteProperty }) => {
  return (
    <>
      <Col span={2} style={{ textAlign: 'right' }}>
        <Tooltip title='Remove property'>
          <Button
            danger
            type='text'
            icon={<CloseOutlined />}
            style={{ margin: 2 }}
            onClick={() => {
              onDeleteProperty(
                property.moduleName,
                property.fieldName,
                property.isCustomField
              );
            }}
          />
        </Tooltip>
      </Col>
    </>
  );
};

const ToggleSelectedProperty = ({
  fieldName,
  moduleName,
  isShow,
  onToggleProperty,
}) => {
  return (
    <Col
      span={2}
      flex='40px'
      style={{
        cursor: 'pointer',
        textAlign: 'right',
        zIndex: 99,
      }}
    >
      {isShow ? (
        <EyeOutlined
          style={{
            fontSize: 16,
            color: '#8c8c8c',
          }}
          onClick={() => onToggleProperty(fieldName, moduleName, isShow)}
        />
      ) : (
        <EyeInvisibleOutlined
          style={{
            fontSize: 16,
            color: '#8c8c8c',
          }}
          onClick={() => onToggleProperty(fieldName, moduleName, isShow)}
        />
      )}
    </Col>
  );
};

const getPropertyDisplayName = (property) => {
  if (property.moduleName) {
    return `${property.displayName} - ${property.moduleDisplayName}`;
  }

  return `${property.displayName}`;
};

export const SelectedProperties = (props) => {
  const {
    selectedProperties = [],
    isSetupStep = false,
    onToggleProperty = () => null,
    onDeleteProperty = () => null,
  } = props;

  return (
    <Droppable
      droppableId='selected-properties'
      style={{ paddingLeft: 24, paddingRight: 8, height: '100%' }}
    >
      {(dropProvided, dropSnapshot) => (
        <div
          className={classnames({
            'module-properties-list scroller': true,
            'module-properties-list__isDraggingOver':
              dropSnapshot.isDraggingOver,
          })}
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
        >
          {selectedProperties.map((property, index) => (
            <Draggable
              key={getKeyDraggable(property) + '-selected'}
              draggableId={getKeyDraggable(property) + '-selected'}
              index={index}
            >
              {(dragProvided, dragSnapshot) => {
                return (
                  <Row
                    key={getKeyDraggable(property) + '-selected'}
                    align='middle'
                    className={classnames({
                      'customize-fields-item': true,
                      'customize-fields-item__isDragging':
                        dragSnapshot.isDragging,
                    })}
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                  >
                    <PropertyItem property={property}>
                      {getPropertyDisplayName(property)}
                    </PropertyItem>

                    {isSetupStep ? (
                      <ToggleSelectedProperty
                        fieldName={property.fieldName}
                        isShow={property.isShow}
                        onToggleProperty={onToggleProperty}
                        moduleName={property.moduleName}
                      />
                    ) : (
                      <DeleteButtonProperty
                        property={property}
                        onDeleteProperty={onDeleteProperty}
                      />
                    )}
                  </Row>
                );
              }}
            </Draggable>
          ))}

          {/* {selectedProperties.length === 0 ? (
            isSetupStep ? (
              <DisplayEmptyInfo />
            ) : (
              <EmptySelectedProperties
                isDraggingOver={dropSnapshot.isDraggingOver}
              />
            )
          ) : null} */}

          {(() => {
            if (selectedProperties.length === 0) {
              if (isSetupStep) return <DisplayEmptyInfo />;
              return (
                <EmptySelectedProperties
                  isDraggingOver={dropSnapshot.isDraggingOver}
                />
              );
            }
            return null;
          })()}

          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const PropertyItem = React.memo(({ property, children }) => {
  return (
    <>
      <Col span={2} flex='40px' style={{ paddingLeft: 0 }}>
        <Avatar
          shape='square'
          className='module-properties-item__icon'
          icon={<AvatarPropertiesItem propertyType={property.dataType} />}
        />
      </Col>
      <Col span={20} flex='auto'>
        <Text className='module-properties-item__name' strong ellipsis>
          {children}
        </Text>
      </Col>
    </>
  );
});

const EmptySelectedProperties = ({ isDraggingOver }) => {
  return (
    <div
      className={classnames({
        'grid-configuration__empty': true,
        'grid-configuration__empty--isDraggingOver': isDraggingOver,
      })}
    >
      <DragOutlined style={{ fontSize: 48, marginBottom: 6 }} />
      <Typography.Title level={5}>
        Drag fields to this area to add new columns
      </Typography.Title>
    </div>
  );
};

const DisplayEmptyInfo = () => {
  return (
    <div
      className={classnames({
        'grid-configuration__empty': true,
      })}
    >
      <Empty />
    </div>
  );
};
