import React, { useMemo } from 'react';

import { Form, Space } from 'antd';

import { FormAddButton } from 'common/components';

import FormFieldItem from './FormFieldItem';

import {
  getFullFieldDisplayName,
  getRivirFieldDisplayName,
  getListModuleNames,
  updateModuleName,
} from './utils';

import './index.less';

const FormFields = (props) => {
  const { formInstance, fieldNameList, productSchema } = props;

  const moduleNameOptions = useMemo(
    () => getListModuleNames(productSchema),
    [productSchema]
  );

  const handleChangeModuleName = (value, fieldKey) => {
    const newFields = updateModuleName({
      key: fieldKey,
      moduleName: value,
      fieldList: fieldNameList,
    });

    formInstance.setFieldsValue({
      fields: newFields,
    });
  };

  return (
    <Form.List name='fields'>
      {(fields, { add, remove }) => {
        return (
          <>
            <div className='scroller--y fields-form__list'>
              <Space direction='vertical' style={{ width: '100%' }}>
                {fields.map((field) => {
                  const fieldValue = fieldNameList?.[field?.name];
                  const fieldName = fieldValue?.fieldName;
                  const fieldType = fieldValue?.type;
                  const fieldDisplayName =
                    fieldType === 'RIVIR'
                      ? getRivirFieldDisplayName(fieldName)
                      : getFullFieldDisplayName({
                          fieldName,
                          productSchema,
                        });
                  const moduleName = fieldValue?.moduleName;
                  const packageLevel = fieldValue?.packageLevel;

                  return (
                    <FormFieldItem
                      key={field.key}
                      productSchema={productSchema}
                      fieldName={field.name}
                      fieldDisplayName={fieldDisplayName}
                      fieldType={fieldType}
                      moduleName={moduleName}
                      moduleNameOptions={moduleNameOptions}
                      packageLevel={packageLevel}
                      onAddItem={() => {
                        add({ ...fieldValue });
                      }}
                      onRemoveItem={() => {
                        remove(field.name);
                      }}
                      onChangeModuleName={handleChangeModuleName}
                      fieldNameList={fieldNameList}
                    />
                  );
                })}
              </Space>
            </div>
            <Form.Item label='Fields' className='fields-form__add-buttons'>
              <FormAddButton
                text={'Add Field'}
                style={{ borderRadius: 'none' }}
                onClick={() => {
                  add({ fieldName: null });
                }}
              />
              <FormAddButton
                text={'Add RIVIR Field'}
                style={{ borderRadius: 'none', marginLeft: 16 }}
                onClick={() => {
                  add({ fieldName: null, type: 'RIVIR' });
                }}
              />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

export default FormFields;
