import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes, { oneOfType } from 'prop-types';

import { useDispatch } from 'react-redux';

import { Row, Col, Select, Input, notification, Space, DatePicker } from 'antd';

import {
  Form,
  FormSaveButton,
  FormCancelButton,
  FormCreateButton,
  WrapperSelect,
} from 'common/components';

import sortByKey from 'utils/sortByKey';

import { FormattedMessage, injectIntl } from 'react-intl';
import { ArrowLeftOutlined } from '@ant-design/icons';

import messages from 'i18n/messages/assetDetail';
import {
  generateGS1CompliantName,
  linkAssetToPIM,
  updateLinkAssetToPIM,
} from 'services/digitalAsset';

import {
  linkAssetFromPIM,
  updateLinkAssetFromPIM,
  qaSpecLinkToDocCer,
} from 'services/product';
import * as constants from 'static/Constants';
import { sleep } from 'utils/delay';
import './AssetGS1Edit.less';

import { mapToAssociationTypeOption } from 'pages/asset-full-view/mapper/mapToAssetGs1EditOption';
import { setAssetType } from 'utils/fileType';

import { DOCUMENT_CERTIFICATE_LIMIT_CHARACTER } from 'pages/qa-spec/constant';

import * as brandingActions from 'redux/branding/actions';

import * as Const from 'static/Constants';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const modeDefinde = ['', 'create', 'modify'];

const AssetGS1Edit = (props) => {
  const {
    gs1ImageData,
    assetId,
    onReloadPage,
    setEditGS1,
    //* import pim props
    isPimImport = false,
    //* link asset to product
    isAssetImport = false,
    //* edit link asset
    isAssetImportEdit = false,
    //* link pim to existed asset
    isPimImportExistAsset,
    productInfo,
    handleBackToCreateAsset,
    setParentLoading,
    unSetParentLoading,
    isEditLinkedAsset = false,
    imagePreview,
    setImagePreview,
    isDisabledGtin = false,
    addType,
    assetData,
    linkInfo,
    selectedProduct,
    isLinkMultiple,
    qaSpec = false,
    certificateType,
    associationTypeDisabledOnEdit,
    isAwaitingListLinkAsset = false,
    handleSubmitAwaitingList,
    isImageTypeFromLinkProduct = false,
    showConfirmIXONEShield,
  } = props;

  const [PLANO, ADD, MARKET, DOC] = constants.PIM_ASSET_TYPE_DEFINE;

  const [loading, setLoading] = useState('');
  const [nonDerivedSection, setNonDerivedSection] = useState([]);
  const [mode, setMode] = useState(modeDefinde[1]);
  const [imageTypeGen, setImageTypeGen] = useState('');
  const [associationType, setAssociationType] = useState('');

  const [editProduct, setEditProduct] = useState();
  const [isChangeProduct, setIsChangeProduct] = useState(false);

  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const [gs1Form] = Form.useForm();
  const fileType =
    assetData?.fileType ||
    (imagePreview?.length > 0 && imagePreview[0]?.fileType);
  const isImageType =
    isImageTypeFromLinkProduct || setAssetType(fileType) === 'Image';

  const dispatch = useDispatch();

  useEffect(() => {
    setNonDerivedSection(
      gs1ImageData?.filter((item) => item.status?.toLowerCase() !== 'deleted')
    );
    const initialSection = gs1ImageData?.filter(
      (item) => item.status?.toLowerCase() !== 'deleted'
    );
    if (!initialSection?.length > 0) setMode('create');
  }, [gs1ImageData]);

  useEffect(() => {
    if (!['create', 'modify'].includes(mode) && gs1ImageData?.length > 0) {
      gs1Form.setFieldsValue({
        gsActiveSection: nonDerivedSection[0]?.compliantFileName,
        gs1ImageData: {
          gsAssociationType: nonDerivedSection[0]?.associateType,

          gsId: nonDerivedSection[0]?.id,
          gsAngle: nonDerivedSection[0]?.angle,
          gsFacing: nonDerivedSection[0]?.facing,
          gsImageType: nonDerivedSection[0]?.imageType,
          gsPackageLevel: nonDerivedSection[0]?.packageLevel,
          gsPackagingDisposition: nonDerivedSection[0]?.packagingDisposition,
          gsGTIN: nonDerivedSection[0]?.gtin,
        },
        productId: nonDerivedSection[0]?.productItemId,
      });
      setImageTypeGen(nonDerivedSection[0]?.imageType);
    }
  }, [nonDerivedSection]);

  useEffect(() => {
    //* add facing field data
    const addFacingFieldData = (
      emptySection,
      isEditAsset,
      initialData = {} //* 4203 :init data metadata instead of empty data
    ) => {
      emptySection.gs1ImageData.gsPackagingDisposition = isEditAsset
        ? imagePreview[0]?.packagingDisposition
        : initialData.packagingDisposition
        ? initialData.packagingDisposition
        : constants.PACKAGING_DISPOSITION[0].value;

      emptySection.gs1ImageData.gsFacing = isEditAsset
        ? imagePreview[0]?.facing
        : initialData.facing
        ? initialData.facing
        : constants.FACING[0].value;

      emptySection.gs1ImageData.gsAngle = isEditAsset
        ? imagePreview[0]?.angle
        : initialData.angle
        ? initialData.angle
        : constants.ANGLE[0].value;
    };

    const setInitFormFieldDataForNewItem = () => {
      const isEditAsset = isEditLinkedAsset && imagePreview?.length > 0;

      let emptySection = {
        productId: '',
        gsActiveSection: isEditAsset
          ? imagePreview[0]?.compliantFileName
          : 'new-item',
        gs1ImageData: {
          gsId: isEditAsset ? imagePreview[0]?.id : '',

          gsImageType: isEditAsset
            ? imagePreview[0]?.imageType
            : constants.IMAGE_TYPE[0].value,

          gsGTIN: isEditAsset ? imagePreview[0]?.gtin : '',
          gsAssociationType: isEditAsset
            ? imagePreview[0]?.associateType
            : constants.DAM_TO_PIM_ASSOCIATION_TYPE[0].value,
          gsPackageLevel: isEditAsset ? imagePreview[0]?.packageLevel : null,
          type: qaSpec
            ? addType === 'Document' || addType === 'Certificate'
              ? 'Document'
              : ''
            : '',
          subtype: qaSpec
            ? addType === 'Certificate'
              ? 'Certificate'
              : addType === 'Document'
              ? 'Product Audit'
              : ''
            : '',
          certificateType:
            qaSpec && addType === 'Certificate' && certificateType
              ? certificateType
              : '',
        },
      };

      if ((isPimImport || isAssetImport) && productInfo) {
        emptySection.productId = productInfo.productId;
        emptySection.gs1ImageData.gsGTIN = productInfo?.gtin;
        emptySection.gs1ImageData.gsPackageLevel = productInfo?.packageLevel;
      }

      const [PLANO, ADD, MARKET, DOC] = constants.PIM_ASSET_TYPE_DEFINE;

      if (isPimImport) {
        if (qaSpec && addType === 'Certificate') {
          emptySection.gs1ImageData.gsAssociationType = 'CERTIFICATE';
        } else if (qaSpec && addType === 'Document') {
          emptySection.gs1ImageData.gsAssociationType = 'DOCUMENT';
        } else if (addType === PLANO) {
          emptySection.gs1ImageData.gsAssociationType =
            constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO[0].value;
          addFacingFieldData(emptySection, isEditAsset);
        } else if (addType === ADD) {
          if (isEditAsset) {
            emptySection.gs1ImageData.gsImageType = imagePreview[0]?.imageType;
            emptySection.gs1ImageData.gsAssociationType =
              imagePreview[0]?.associateType;
          } else {
            emptySection.gs1ImageData.gsAssociationType =
              constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_ADDITIONAL[0].value;
            emptySection.gs1ImageData.gsImageType = undefined;
          }
        } else if (addType === DOC) {
          emptySection.gs1ImageData.gsAssociationType =
            constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_DOCUMENT[0].value;

          emptySection.gs1ImageData.gsImageType = undefined;
        } else if (addType === MARKET) {
          emptySection.gs1ImageData.gsAssociationType =
            constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_MARKETING[0].value;
        }
      }

      if (isAssetImport || isPimImportExistAsset) {
        if (isImageType) {
          if (!addType || addType === PLANO) {
            emptySection.gs1ImageData.gsAssociationType =
              constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO[0].value;

            setAssociationType(
              constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO[0].value
            );
          } else if (addType === 'unknown') {
            emptySection.gs1ImageData.gsAssociationType =
              constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO[0].value;

            setAssociationType(
              constants.PIM_TO_DAM_ASSOCIATION_TYPE_FOR_PLANO[0].value
            );
          }

          const metadata = assetData?.metadata;
          const initialData = metadata
            ? {
                angle: metadata?.angle,
                facing: metadata?.facing,
                packagingDisposition: metadata?.packagingDisposition,
              }
            : {};

          addFacingFieldData(emptySection, isEditAsset, initialData);
        } else {
          emptySection.gs1ImageData.gsAssociationType =
            constants.DAM_TO_PIM_ASSOCIATION_TYPE_FOR_NON_IMAGE[0].value;

          setAssociationType(
            constants.DAM_TO_PIM_ASSOCIATION_TYPE_FOR_NON_IMAGE[0].value
          );
        }
      }

      if (isEditAsset) {
        addFacingFieldData(emptySection, isEditAsset);
      }

      if (isAwaitingListLinkAsset) {
        emptySection.gs1ImageData.gsImageType = assetData?.imageType;
        emptySection.gs1ImageData.gsAssociationType = assetData?.associateType;
        emptySection.gs1ImageData.gsFacing = assetData?.facing;
        emptySection.gs1ImageData.gsAngle = assetData?.angle;
        emptySection.gs1ImageData.gsPackageLevel = assetData?.packageLevel;
        emptySection.gs1ImageData.gsPackagingDisposition =
          assetData?.packagingDisposition;
      }

      if (emptySection.gs1ImageData.gsImageType) {
        setImageTypeGen(emptySection.gs1ImageData.gsImageType);
      }

      if (emptySection.gs1ImageData.gsAssociationType) {
        setAssociationType(emptySection.gs1ImageData.gsAssociationType);
      }

      if (mode === 'create') {
        gs1Form.setFieldsValue({ ...emptySection });
        return;
      }

      if (mode === 'modify') {
        if (isEditLinkedAsset) {
          gs1Form.setFieldsValue({ ...emptySection });

          setImageTypeGen(emptySection?.gs1ImageData?.gsImageType);
          return;
        }
      }
    };

    setInitFormFieldDataForNewItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    isPimImport,
    productInfo,
    addType,
    isEditLinkedAsset,
    isImageType,
    isAssetImport,
    isAssetImportEdit,
    assetData?.id, //* 4203: update form value when changing link image
  ]);

  useEffect(() => {
    if (isEditLinkedAsset) {
      setMode('modify');
    }
  }, [isEditLinkedAsset]);

  useEffect(() => {
    //* get link detail
    const getLinkDetail = () => {
      let activeGS1 = nonDerivedSection?.filter(
        (item) => item.id === linkInfo?.id
      )[0];
      gs1Form.setFieldsValue({
        gs1ImageData: {
          gsId: activeGS1?.id,
          gsAssociationType: activeGS1?.associateType,
          gsAngle: activeGS1?.angle,
          gsFacing: activeGS1?.facing,
          gsImageType: activeGS1?.imageType,
          gsPackageLevel: activeGS1?.packageLevel,
          gsPackagingDisposition: activeGS1?.packagingDisposition,
          gsGTIN: activeGS1?.gtin,
          subtype: activeGS1?.subtype,
        },
        productId: activeGS1?.productItemId,
      });

      setImageTypeGen(activeGS1?.imageType);
      setAssociationType(activeGS1?.associateType);

      if (linkInfo === 'new') setMode('create');
      else setMode('modify');
      return;
    };

    if (linkInfo) {
      getLinkDetail(linkInfo);
    }
  }, [linkInfo, nonDerivedSection, gs1Form]);

  const onChangeImageType = (value) => {
    const imageTypeRestriction = ['OptimisedHeroImage'];
    setImageTypeGen(value);
    if (imageTypeRestriction.includes(value))
      gs1Form.setFieldsValue({ gs1ImageData: { gsGTIN: '' } });
    else return false;
  };

  const onChangeAssociationType = (value) => {
    const imageRestriction = ['PLANOGRAM'];
    const imageTypeRestriction = ['PLANOGRAM', 'MARKETING', 'INFORMATIONAL'];

    setAssociationType(value);
    //* remove facting field
    if (!imageRestriction.includes(value)) {
      gs1Form.setFieldsValue({
        gs1ImageData: {
          gsFacing: undefined,
          gsAngle: undefined,
          gsPackagingDisposition: undefined,
        },
      });
    }

    //* remove image type field valuePropName='checked'
    if (!imageTypeRestriction.includes(value)) {
      gs1Form.setFieldsValue({
        gs1ImageData: {
          gsImageType: 'None',
        },
      });
    }

    if (imageTypeRestriction.includes(value)) {
      const gsImageType =
        value === 'INFORMATIONAL'
          ? constants.IMAGE_TYPE[14].value
          : constants.IMAGE_TYPE[0].value;
      setImageTypeGen(gsImageType);
      if (isAssetImport) {
        const metadata = assetData?.metadata;
        gs1Form.setFieldsValue({
          gs1ImageData: {
            gsImageType: gsImageType,
            gsFacing: metadata?.angle,
            gsAngle: metadata?.facing,
            gsPackagingDisposition: metadata?.packagingDisposition,
          },
        });
      } else {
        const gsImageType =
          value === 'INFORMATIONAL'
            ? constants.IMAGE_TYPE[14].value
            : constants.IMAGE_TYPE[0].value;
        gs1Form.setFieldsValue({
          gs1ImageData: {
            gsImageType: gsImageType,
          },
        });
      }
    }
  };

  const getRemoveSpaceTypeGen = useCallback(() => {
    return imageTypeGen && typeof imageTypeGen === 'string'
      ? imageTypeGen.split(' ').join('')
      : '';
  }, [imageTypeGen]);

  const handleFacing = () => {
    const curFacing = gs1Form.getFieldValue(['gs1ImageData', 'gsFacing']);

    const removedSpaceTypeGen = getRemoveSpaceTypeGen();

    switch (removedSpaceTypeGen) {
      case 'ProductImageWeb':
      case 'ProductImageWithSupportingElementsWeb':
      case 'ProductImageHighResolution':
      case 'ProductImageWithSupportingElementsHighResolution':
      case 'OptimisedHeroImage':
        // if (checkProps(constants.FACING_ABCDU, curFacing))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsFacing: constants.FACING_ABCDU[0]?.value },
        //   });
        return constants.FACING_ABCDU;

      case 'ProductImage360D3D':
      case 'DetailTechnologyImage':
      case 'ContentTexture':
      case 'SamplePrototypeMockupImage':
      case 'SocialMedia':
      case 'Application':
      case 'SizeComparison':
      case 'AmbienceMood':
      case 'SidekickImage':
      case '_3DRendered':
        // if (checkProps(constants.FACING_EFS3DR, curFacing))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsFacing: constants.FACING_EFS3DR[0]?.value },
        //   });

        return constants.FACING_EFS3DR;

      case 'MobileReadyHeroImage':
        // if (checkProps(constants.FACING_H, curFacing)) {
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsFacing: constants.FACING_H[0]?.value },
        //   });
        // }
        return constants.FACING_H;

      case 'MontageCompositeImage':
        // if (checkProps(constants.FACING_M, curFacing))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsFacing: constants.FACING_M[0]?.value },
        //   });
        return constants.FACING_M;

      case 'ProductPackagingLabelInformation':
        // if (checkProps(constants.FACING_L, curFacing))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsFacing: constants.FACING_L[0]?.value },
        //   });
        return constants.FACING_L;

      default:
        // if (checkProps(constants.FACING, curFacing))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsFacing: constants.FACING[0]?.value },
        //   });
        return constants.FACING;
    }
  };

  const handleAngle = () => {
    const curAngle = gs1Form.getFieldValue(['gs1ImageData', 'gsAngle']);

    const removedSpaceTypeGen = getRemoveSpaceTypeGen();

    switch (removedSpaceTypeGen) {
      case 'ProductImageWeb':
      case 'ProductImageWithSupportingElementsWeb':
      case 'ProductImageHighResolution':
      case 'ProductImageWithSupportingElementsHighResolution':
      case 'MobileReadyHeroImage':
      case 'OptimisedHeroImage':
        // if (checkProps(constants.ANGLE_ABCDHU, curAngle))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsAngle: constants.ANGLE_ABCDHU[0]?.value },
        //   });
        return constants.ANGLE_ABCDHU;

      case 'DetailTechnologyImage':
      case 'ContentTexture':
      case 'SamplePrototypeMockupImage':
      case 'SocialMedia':
      case 'Application':
      case 'SizeComparison':
      case 'AmbienceMood':
      case 'MontageCompositeImage':
        // if (checkProps(constants.ANGLE_FM, curAngle))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsAngle: constants.ANGLE_FM[0]?.value },
        //   });
        return constants.ANGLE_FM;

      case 'ProductImage360D3D':
      case 'ProductPackagingLabelInformation':
      case 'SidekickImage':
      case '_3DRendered':
        // if (checkProps(constants.ANGLE_ELS3DR, curAngle))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsAngle: constants.ANGLE_ELS3DR[0]?.value },
        //   });
        return constants.ANGLE_ELS3DR;

      default:
        // if (checkProps(constants.ANGLE, curAngle))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: { gsAngle: constants.ANGLE[0]?.value },
        //   });
        return constants.ANGLE;
    }
  };

  const handleState = () => {
    let stagedOption = [];
    const associationTypeFieldValue = gs1Form.getFieldValue([
      'gs1ImageData',
      'gsAssociationType',
    ]);

    if (associationTypeFieldValue === constants.ASSOCIATION_TYPE.MARKETING) {
      stagedOption = [{ value: 'Staged', label: 'Staged' }];
    }

    const removedSpaceTypeGen = getRemoveSpaceTypeGen();

    switch (removedSpaceTypeGen) {
      case 'ProductImageWeb':
      case 'ProductImageHighResolution':
        // if (checkProps(constants.PACKAGING_DISPOSITION_AC, curState))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: {
        //       gsPackagingDisposition:
        //         constants.PACKAGING_DISPOSITION_AC[0]?.value,
        //     },
        //   });
        return [...constants.PACKAGING_DISPOSITION_AC, ...stagedOption];

      case 'ProductImage360D3D':
      case 'DetailTechnologyImage':
      case 'ContentTexture':
      case 'SamplePrototypeMockupImage':
      case 'SocialMedia':
      case 'Application':
      case 'SizeComparison':
      case 'AmbienceMood':
      case 'MontageCompositeImage':
      case 'ProductPackagingLabelInformation':
      case 'SidekickImage':
      case '_3DRendered':
        // if (checkProps(constants.PACKAGING_DISPOSITION_EFMLS3DR, curState))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: {
        //       gsPackagingDisposition:
        //         constants.PACKAGING_DISPOSITION_EFMLS3DR[0]?.value,
        //     },
        //   });
        return [...constants.PACKAGING_DISPOSITION_EFMLS3DR, ...stagedOption];

      case 'MobileReadyHeroImage':
        // if (checkProps(constants.PACKAGING_DISPOSITION_H, curState))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: {
        //       gsPackagingDisposition:
        //         constants.PACKAGING_DISPOSITION_H[0]?.value,
        //     },
        //   });
        return [...constants.PACKAGING_DISPOSITION_H, ...stagedOption];

      default:
        // if (checkProps(constants.PACKAGING_DISPOSITION, curState))
        //   gs1Form.setFieldsValue({
        //     gs1ImageData: {
        //       gsPackagingDisposition: constants.PACKAGING_DISPOSITION[0]?.value,
        //     },
        //   });
        return constants.PACKAGING_DISPOSITION;
    }
  };

  const isShowImageTypeField = useMemo(() => {
    if (isPimImport) {
      if (
        associationType === constants.ASSOCIATION_TYPE.PLANOGRAM ||
        associationType === constants.ASSOCIATION_TYPE.MARKETING ||
        associationType === constants.ASSOCIATION_TYPE.INFORMATIONAL
      ) {
        return true;
      }
    }

    if (isAssetImport || isAssetImportEdit) {
      if (
        associationType === constants.ASSOCIATION_TYPE.PLANOGRAM ||
        associationType === constants.ASSOCIATION_TYPE.MARKETING ||
        associationType === constants.ASSOCIATION_TYPE.INFORMATIONAL
      ) {
        return true;
      }
    }
    return false;
  }, [isPimImport, isAssetImport, isAssetImportEdit, associationType]);

  const checkShowPlanogramField = () => {
    if (isPimImport) {
      return (
        associationType === constants.ASSOCIATION_TYPE.PLANOGRAM ||
        associationType === constants.ASSOCIATION_TYPE.MARKETING ||
        associationType === constants.ASSOCIATION_TYPE.INFORMATIONAL
      );
    } else if (isAssetImport || isAssetImportEdit) {
      const isMarketingOrPlanogram =
        associationType === constants.ASSOCIATION_TYPE.PLANOGRAM ||
        associationType === constants.ASSOCIATION_TYPE.MARKETING ||
        associationType === constants.ASSOCIATION_TYPE.INFORMATIONAL;

      return isImageType && isMarketingOrPlanogram;
    }
    return true;
  };

  const isShowPlanogramField = checkShowPlanogramField();

  const [defaultImageTypeOption, ...restImageTypeOptions] =
    constants.IMAGE_TYPE;

  const procImageTypeOptions = [
    defaultImageTypeOption,
    ...sortByKey(restImageTypeOptions, 'label'),
  ];

  const specificInformationSectionView = () => {
    const associationTypeDisabled =
      (addType && isPimImport === true) ||
      (isAssetImportEdit && associationTypeDisabledOnEdit);
    return (
      <Col span={24}>
        {mode !== 'create' && (
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            name={['gs1ImageData', 'gsId']}
            label={<FormattedMessage {...messages.gsId} />}
            style={{ display: 'none' }}
          >
            <Input disabled />
          </Form.Item>
        )}
        {isShowImageTypeField && (
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            name={['gs1ImageData', 'gsImageType']}
            label={<FormattedMessage {...messages.gsImageType} />}
          >
            <WrapperSelect
              onChange={(value) => onChangeImageType(value)}
              getPopupContainer={() => document.getElementById('gs1-form')}
              listHeight={140}
              allowClear={false}
            >
              {procImageTypeOptions?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
        )}

        <Form.Item
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          name={['gs1ImageData', 'gsAssociationType']}
          label={<FormattedMessage {...messages.gsAssociationType} />}
          rules={[
            {
              required: true,
              message: 'Association Type is required',
            },
          ]}
          hidden={qaSpec}
        >
          <WrapperSelect
            getPopupContainer={() => document.getElementById('gs1-form')}
            listHeight={140}
            // disabled={associationTypeDisabled}
            onChange={(value) => onChangeAssociationType(value)}
          >
            {sortByKey(
              mapToAssociationTypeOption({
                addType,
                isAssetImport,
                isAssetImportEdit,
                isImageType,
              }),
              'label'
            ).map((item, index) => {
              return (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </WrapperSelect>
        </Form.Item>
        {!qaSpec && (isAssetImport || isAssetImportEdit || isPimImport) ? (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues !== currentValues
            }
          >
            {({ getFieldValue }) => {
              const type = getFieldValue('gs1ImageData')?.gsAssociationType;

              let options = [];

              if (type === 'ASSET') {
                options = Const.ASSET_SUBTYPE.sort();
              }

              if (type === 'DOCUMENT') {
                options = Const.DOCUMENT_SUBTYPE.sort();
              }

              if (type === 'COMPANY') {
                options = Const.COMPANY_SUBTYPE.sort();
              }

              if (type === 'MULTIMEDIA') {
                options = Const.MULTIMEDIA_SUBTYPE.sort();
              }

              return (
                <Form.Item
                  name={['gs1ImageData', 'subtype']}
                  label='Sub Type: '
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 12 }}
                  hidden={!options?.length}
                >
                  <WrapperSelect>
                    {options.map((item, index) => {
                      return (
                        <Select.Option value={item} key={index}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </WrapperSelect>
                </Form.Item>
              );
            }}
          </Form.Item>
        ) : null}

        {qaSpec && (addType === 'Document' || addType === 'Certificate') && (
          <>
            <Form.Item
              name={['gs1ImageData', 'type']}
              label='Type: '
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: 'Please select asset type',
                },
              ]}
            >
              <WrapperSelect
                disabled={
                  qaSpec &&
                  (addType === 'Document' || addType === 'Certificate')
                }
              >
                {Const.ASSET_TYPE?.slice()
                  .sort()
                  .map((item, index) => {
                    return (
                      <Select.Option value={item} key={index}>
                        {item}
                      </Select.Option>
                    );
                  })}
              </WrapperSelect>
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues !== currentValues
              }
            >
              {({ getFieldValue }) => {
                const type = getFieldValue('gs1ImageData')?.type;

                let options = [];

                if (type === 'Asset') {
                  options = Const.ASSET_SUBTYPE.sort();
                }

                if (type === 'Document') {
                  options = Const.QA_SPEC_DOCUMENT_SUBTYPE.sort();
                }

                if (type === 'Company') {
                  options = Const.COMPANY_SUBTYPE.sort();
                }

                if (type === 'Multimedia') {
                  options = Const.MULTIMEDIA_SUBTYPE.sort();
                }

                return (
                  <Form.Item
                    name={['gs1ImageData', 'subtype']}
                    label='Sub Type: '
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 12 }}
                    rules={[
                      {
                        required: true,
                        message: 'Please select asset subtype',
                      },
                    ]}
                  >
                    <WrapperSelect
                      disabled={qaSpec && addType === 'Certificate'}
                    >
                      {options.map((item, index) => {
                        return (
                          <Select.Option value={item} key={index}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </WrapperSelect>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </>
        )}

        {qaSpec && addType === 'Certificate' && (
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            name={['gs1ImageData', 'certificateType']}
            label='Certificate Type'
          >
            <WrapperSelect disabled={certificateType}>
              {Const.DOCUMENT_CERTIFICATE_FIELD?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>
        )}

        {qaSpec && addType === 'Document' && (
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            name={['gs1ImageData', 'documentName']}
            label='Document Name'
            hidden={!qaSpec || addType !== 'Document'}
          >
            <Input
              maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.documentType}
            />
          </Form.Item>
        )}
        <Form.Item
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          name={['gs1ImageData', 'documentType']}
          label='Document Type'
          hidden
        >
          <Input
            maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.documentType}
            showCount
          />
        </Form.Item>
        {qaSpec && addType === 'Certificate' && (
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            name={['gs1ImageData', 'certificate']}
            label='Certificate'
            hidden={!qaSpec || addType !== 'Certificate'}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          name={['gs1ImageData', 'certificateBody']}
          label='Certificate Body'
          hidden={!qaSpec || addType !== 'Certificate'}
        >
          <Input
            maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.certificateBody}
            showCount
          />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          name={['gs1ImageData', 'certificateExpiryDate']}
          label='Certificate Expiry Date'
          hidden={!qaSpec || addType !== 'Certificate'}
        >
          <DatePicker format={constants.DATE_FORMAT.SYSTEM_FORMAT} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          name='productId'
          label='product Id'
          rules={[
            {
              required: true,
              message: 'Please select a product',
            },
          ]}
          style={{ display: 'none' }}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          name={['gs1ImageData', 'gsGTIN']}
          label={'Product'}
          hidden
        >
          <Input
            disabled={isDisabledGtin}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            maxLength={0}
          />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 12 }}
          name={['gs1ImageData', 'gsPackageLevel']}
          label={<FormattedMessage {...messages.gsPackageLevel} />}
          disabled={true}
          hidden={isPimImport || isAssetImport || isAssetImportEdit}
        >
          <Input maxLength={14} disabled={true} />
        </Form.Item>
        {isShowPlanogramField && (
          <>
            <Form.Item
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 12 }}
              name={['gs1ImageData', 'gsFacing']}
              label={<FormattedMessage {...messages.gsFacing} />}
            >
              <WrapperSelect
                getPopupContainer={() => document.getElementById('gs1-form')}
                listHeight={140}
              >
                {sortByKey(handleFacing(), 'label')?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 12 }}
              name={['gs1ImageData', 'gsAngle']}
              label={<FormattedMessage {...messages.gsAngle} />}
            >
              <WrapperSelect
                getPopupContainer={() => document.getElementById('gs1-form')}
                listHeight={140}
              >
                {sortByKey(handleAngle(), 'label')?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 12 }}
              name={['gs1ImageData', 'gsPackagingDisposition']}
              label={<FormattedMessage {...messages.gsPackagingDisposition} />}
            >
              <WrapperSelect
                getPopupContainer={() => document.getElementById('gs1-form')}
                listHeight={140}
              >
                {sortByKey(handleState(), 'label')?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  );
                })}
              </WrapperSelect>
            </Form.Item>
          </>
        )}
      </Col>
    );
  };

  const openNotification = (type, message, description) => {
    notification[type]({ message: message, description: description });
  };

  const createGS1 = () => {
    gs1Form
      .validateFields()
      .then(async () => {
        let gs1Data = gs1Form.getFieldValue('gs1ImageData');

        let productId = gs1Form.getFieldValue('productId');

        setLoading('add');
        setParentLoading && setParentLoading();

        const response =
          gs1Data?.gsAssociationType === constants.ASSOCIATION_TYPE.PLANOGRAM
            ? await generateGS1CompliantName({
                gtin: gs1Data.gsGTIN,
                imageType: gs1Data.gsImageType,
                facing: gs1Data.gsFacing,
                angle: gs1Data.gsAngle,
                state: gs1Data.gsPackagingDisposition,
              })
            : true;

        if ((response.isSuccess && response.data?.fileName) || response) {
          const addedSection = {
            associateType: gs1Data?.gsAssociationType,
            gtin: gs1Data.gsGTIN,
            compliantFileName: response?.data?.fileName,
            imageType: gs1Data?.gsImageType,
            packageLevel: gs1Data?.gsPackageLevel,
            packagingDisposition: gs1Data?.gsPackagingDisposition,
            angle: gs1Data?.gsAngle,
            facing: gs1Data?.gsFacing,
            certificate: gs1Data?.certificate,
            certificateBody: gs1Data?.certificateBody,
            certificateExpiryDate: gs1Data?.certificateExpiryDate,
            linkType: gs1Data?.gsAssociationType,
            documentName: gs1Data?.documentName,
            documentType: gs1Data?.documentType,
          };

          const qaSpecDocumentParams = {
            type: gs1Data?.type,
            subtype: gs1Data?.subtype,
          };

          const qaSpecCertificateParams = {
            ...qaSpecDocumentParams,
            certificateType: gs1Data?.certificateType,
          };

          const productItemIds = isLinkMultiple
            ? selectedProduct.map((productItem) => {
                return productItem.id;
              })
            : null;

          const linkItemParams = {
            digitalAssetId: assetId,
            productItemId: isLinkMultiple ? 0 : productId,
            productItemIds,
            subtype: gs1Data?.subtype,
          };

          const params = { ...linkItemParams, ...addedSection };
          const qaSpecLinkDocumentParams = {
            ...params,
            ...qaSpecDocumentParams,
          };

          const qaSpecLinkCertificateParams = {
            ...params,
            ...qaSpecCertificateParams,
          };

          const associationType = gs1Data?.gsAssociationType;

          const linkResponse =
            qaSpec && addType === 'Document'
              ? await qaSpecLinkToDocCer(qaSpecLinkDocumentParams)
              : qaSpec && addType === 'Certificate'
              ? await qaSpecLinkToDocCer(qaSpecLinkCertificateParams)
              : isPimImport
              ? await linkAssetFromPIM(params, associationType)
              : await linkAssetToPIM(params, associationType);

          //* BE doest not update link data instantly
          await sleep(1000);

          if (linkResponse.isSuccess) {
            openNotification(
              'success',
              'Link data has been updated successfully!'
            );
            onReloadPage && onReloadPage();
          } else {
            openNotification('error', 'Link data has been updated failed!');
          }
          setLoading('');
          unSetParentLoading && unSetParentLoading();
          setEditGS1 && setEditGS1(false);

          dispatch(brandingActions.getBrandingNoLoading());
        }
      })
      .catch((e) => {
        if (e?.errorFields?.length > 0) {
          openNotification('error', 'Please fill out all required fields');
        } else {
          openNotification('error', 'Failed to submit form');
        }
      });
  };

  const modifyGS1 = (ixoneIgnore) => {
    gs1Form
      .validateFields()
      .then(async () => {
        let gs1Data = gs1Form.getFieldValue('gs1ImageData');

        setLoading('mod');

        const response =
          gs1Data?.gsAssociationType === constants.ASSOCIATION_TYPE.PLANOGRAM ||
          gs1Data?.gsAssociationType === constants.ASSOCIATION_TYPE.MARKETING
            ? await generateGS1CompliantName({
                gtin: gs1Data.gsGTIN,
                imageType: gs1Data.gsImageType,
                facing: gs1Data.gsFacing,
                angle: gs1Data.gsAngle,
                state: gs1Data.gsPackagingDisposition,
              })
            : true;

        const newCompliantFileName = response?.data?.fileName;

        if ((response?.isSuccess && newCompliantFileName) || response) {
          const commonUpdate = {
            compliantFileName: newCompliantFileName,
            associateType: gs1Data?.gsAssociationType,
            gtin: gs1Data.gsGTIN,
            packagingDisposition: gs1Data?.gsPackagingDisposition,
            subtype: gs1Data?.subtype,
          };

          const updatedSection = {
            imageType: gs1Data?.gsImageType,
            packageLevel: gs1Data?.gsPackageLevel,
            angle: gs1Data?.gsAngle,
            facing: gs1Data?.gsFacing,
          };

          const updateDocumentParams = {
            imageType: null,
            angle: null,
            facing: null,
            packageLevel: gs1Data?.gsPackageLevel,
            packagingDisposition: gs1Data?.gsPackagingDisposition,
            linkType: isAssetImportEdit
              ? gs1ImageData?.length > 0 && gs1ImageData[0]?.linkType
              : gs1Data?.gsAssociationType,
          };

          const linkItemParams = {
            id: isEditLinkedAsset
              ? imagePreview?.length > 0 && imagePreview[0]?.id
              : parseInt(gs1Data.gsId),
            digitalAssetId: assetId,
            linkType: isAssetImportEdit
              ? gs1ImageData?.length > 0 && gs1ImageData[0]?.linkType
              : null,
          };

          const associationType = gs1Data?.gsAssociationType;

          const commonParams = {
            ...commonUpdate,
            ...linkItemParams,
            ...updatedSection,
          };

          let params =
            associationType === 'Document'
              ? { ...commonParams, ...updateDocumentParams }
              : commonParams;

          const updateLinkService = isPimImport
            ? updateLinkAssetFromPIM
            : updateLinkAssetToPIM;

          const linkResponse = await updateLinkService(params);

          //* BE does not update link data instantly
          await sleep(500);

          if (linkResponse.isSuccess) {
            openNotification(
              'success',
              'Link data has been updated successfully!'
            );

            await handleUpdateIXONEShield(ixoneIgnore);

            gs1Form.setFieldsValue({
              gsActiveSection: newCompliantFileName,
            });

            setMode('modify');

            onReloadPage && onReloadPage();

            if (isEditLinkedAsset) {
              if (imagePreview[0].associateType === params.associateType) {
                setImagePreview([{ ...imagePreview[0], ...params }]);
              } else {
                setImagePreview([]);
              }
            }
          } else {
            openNotification('error', 'Link data has been updated failed!');
          }

          setLoading('');
        }
      })
      .catch((e) => {
        if (e?.errorFields?.length > 0) {
          openNotification('error', 'Please fill out all required fields');
        } else {
          openNotification('error', 'Failed to submit form');
        }
      });
  };

  const handleAddLinkWaiting = () => {
    gs1Form
      .validateFields()
      .then(() => {
        let gs1Data = gs1Form.getFieldValue('gs1ImageData');
        let awaitingList = Object.assign(assetData, {
          imageType: gs1Data?.gsImageType,
          associateType: gs1Data?.gsAssociationType,
          facing: gs1Data?.gsFacing,
          angle: gs1Data?.gsAngle,
          packageLevel: gs1Data?.gsPackageLevel,
          packagingDisposition: gs1Data?.gsPackagingDisposition,
          GTIN: gs1Data.gsGTIN,
        });
        handleSubmitAwaitingList(awaitingList);
      })
      .catch((e) => {
        if (e?.errorFields?.length > 0) {
          openNotification('error', 'Please fill out all required fields');
        } else {
          openNotification('error', 'Failed to submit form');
        }
      });
  };

  return (
    <IXONECertificationWrapper
      showModal={showConfirmIXONEShield}
      type={TYPE_VIEW.IMAGE_PRODUCT}
      onConfirm={(ixoneIgnore) => {
        modifyGS1(ixoneIgnore);
      }}
    >
      {(handleConfirmIXONE) => (
        <Form
          form={gs1Form}
          name='gs1-form'
          id='gs1-form'
          style={{ position: 'relative' }}
          className={[constants.OPEN_ITEM_CLASS]}
        >
          <Row className='asset-gs1-edit' gutter={8}>
            {specificInformationSectionView()}
          </Row>

          {mode === 'create' &&
            (isPimImport === true || isAssetImport === true) &&
            !isEditLinkedAsset && (
              <Row>
                <Col flex='auto' />
                <Col className='asset-gs1-edit__action'>
                  <Space>
                    {!isPimImportExistAsset && (
                      <FormCancelButton
                        text={
                          isPimImport
                            ? 'Back to asset creation'
                            : 'Back to product selection'
                        }
                        icon={<ArrowLeftOutlined />}
                        onClick={() =>
                          handleBackToCreateAsset && handleBackToCreateAsset()
                        }
                        disabled={loading}
                      />
                    )}
                    {isPimImportExistAsset && (
                      <FormSaveButton
                        text='Submit'
                        onClick={handleAddLinkWaiting}
                      />
                    )}

                    <FormCreateButton
                      text='Complete setup'
                      onClick={createGS1}
                      loading={loading === 'add'}
                      className={
                        isPimImportExistAsset && 'asset-gs1-edit__link-button'
                      }
                    />
                  </Space>
                </Col>
                <Col xs={2} />
              </Row>
            )}
          {(isEditLinkedAsset || isAssetImportEdit) && (
            <Row>
              <Col flex='auto' />
              <Col className='asset-gs1-edit__action'>
                <FormSaveButton
                  text='Save'
                  onClick={handleConfirmIXONE}
                  loading={loading === 'mod'}
                />
              </Col>
              <Col xs={2} />
            </Row>
          )}
        </Form>
      )}
    </IXONECertificationWrapper>
  );
};

AssetGS1Edit.propTypes = {
  gs1ImageData: oneOfType([PropTypes.array, PropTypes.object]),
  assetId: oneOfType([PropTypes.string, PropTypes.number]),
  setReloadData: PropTypes.func,
  isPimImport: PropTypes.bool,
  productInfo: PropTypes.object,
  handleBackToCreateAsset: PropTypes.func,
  isAssetImportEdit: PropTypes.bool,
  isAssetImport: PropTypes.bool,
};

export default injectIntl(AssetGS1Edit);
