import React from 'react';

import { Divider } from 'antd';

import {
  ManageSharingSection,
  DetailSection,
  ViewLayout,
  AdvanceStack,
  OtherLayout,
} from '../components';
import { FormDetailManipulationSection } from './components';

import { RibbonBar, RibbonDivider } from 'common/components';

const FormMangeDetail = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <AdvanceStack isDisabled />
      <RibbonDivider />
      <FormDetailManipulationSection />
      <OtherLayout ribbonSide='right' isHaveAddOpenItem={false} />
    </RibbonBar>
  );
};

export default FormMangeDetail;
