import React, { useEffect, useRef } from 'react';

import lz from 'lzutf8';
import classnames from 'classnames';
import { Frame, useEditor } from '@craftjs/core';
import { Typography } from 'antd';

import EmailEditorSectionMenu from './EmailEditorSectionMenu';

import { updateDynamicContentByTag } from 'utils/email-editor';
import { useEmailEditorContext } from '../../controllers/context';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import { EVENT } from 'static/Constants';

import Messages from 'i18n/messages/email-editor';
import { useIntl } from 'react-intl';

const { Title } = Typography;

const EmailEditorFrame = (props) => {
  const intl = useIntl();
  const frameRef = useRef(null);
  const { setDynamicContent, template } = useEmailEditorContext();
  const { nodes, actions, enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
    nodes: state.nodes,
  }));

  const nodesLength = Object.keys(nodes).length;

  const loadTemplate = () => {
    if (!template) return;

    const json = lz.decompress(lz.decodeBase64(template.temp));
    actions.deserialize(json);

    showDynamicContentField(JSON.parse(json));
  };

  const showDynamicContentField = (data) => {
    const tags = getTags(data);
    setDynamicContent((prevState) =>
      updateDynamicContentByTag({ prevState, tags, field: 'show' })
    );
  };

  const getTags = (data) => {
    return Object.values(data).reduce((accumulator, currentNode) => {
      const nodeTags = currentNode?.custom?.tags || [];

      return [...accumulator, ...nodeTags];
    }, []);
  };

  const deselectSection = () => {
    actions.selectNode(null);
  };

  useEffect(() => {
    loadTemplate();
    deselectSection();
  }, [template?.id]);

  useDocumentAttachEvent({
    name: EVENT.EMAIL_EDITOR_CLEAR_SELECTED_SECTION,
    handler: deselectSection,
  });

  return (
    <div
      ref={frameRef}
      className={classnames({ 'email-editor__frame': enabled, scroller: true })}
    >
      {nodesLength === 1 ? (
        <div>
          <Title level={4} style={{ color: '#595959', marginBottom: 30 }}>
            {intl.formatMessage(Messages.startingMessages)}
          </Title>
          <EmailEditorSectionMenu
            parentId='ROOT'
            className='email-editor__starting-menu'
          />
        </div>
      ) : null}
      <Frame></Frame>
    </div>
  );
};

export default EmailEditorFrame;
