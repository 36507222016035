import React, { useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';

import ModalFormItem from './ModalFormItem';

import messages from 'i18n/messages/home';

import './DownloadFormItem.less';

const DownloadFormItem = ({
  entityType,
  sizeBtn,
  selectedEntityIds,
  disabled,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        size={sizeBtn ? sizeBtn : 'small'}
        className='button-item-dropdown'
        icon={<DownloadOutlined />}
        label={messages.downloadSplashFormItem}
        onClick={() => setVisible(true)}
        disabled={disabled}
      />

      {visible && (
        <ModalFormItem
          visible={visible}
          onCancel={() => setVisible(false)}
          selectedEntityIds={selectedEntityIds}
          entityType={entityType}
        />
      )}
    </>
  );
};

export default DownloadFormItem;
