import React, { useState } from 'react';

import { StarFilled, LoadingOutlined } from '@ant-design/icons';

import { useControllableState } from 'hooks/useControllableState';

const FavoriteStarIconRender = (props) => {
  const { initialValue, isFavorited, isAlwaysShow, onToggle, reloadData } =
    props;

  const [loading, setLoading] = useState(false);

  const [glowing, setGlowing] = useControllableState({
    defaultProp: initialValue,
    prop: isFavorited,
    onChange: (value) => {
      onChange(value);
    },
  });

  const onClick = () => {
    if (!onToggle) return;
    setGlowing((prevState) => !prevState);
  };

  const onChange = async (value) => {
    setLoading(true);
    const isToggleSuccess = await onToggle(value);

    if (!isToggleSuccess) return setLoading(false); //* skip reload data

    reloadData && (await reloadData());

    setLoading(false);
  };

  if (loading)
    return (
      <LoadingOutlined
        style={{
          fontSize: '18px',
          userSelect: 'none',
          ...props.style,
        }}
      />
    );

  return isAlwaysShow || isFavorited ? (
    <StarFilled
      onClick={onClick}
      style={{
        fontSize: '18px',
        color: glowing ? '#fec810' : '#d9d9d9',
        userSelect: 'none',
        ...props.style,
      }}
    />
  ) : null;
};

export default FavoriteStarIconRender;
