import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useGetQuery } from 'hooks/useQuery';

import * as productFullViewSelectors from 'pages/product-full-view/controllers/selectors';

import * as ssoProductSelectors from 'pages/sso-product/controllers/selectors';

const useSsoProductParamsUrlForValidation = (bpSsoProductData) => {
  const bpSsoProductSuccess = useSelector(
    ssoProductSelectors.selectpSsoProductSuccess()
  );

  const productId =
    +bpSsoProductData?.productId || bpSsoProductSuccess?.productId;

  const ssoProductParams = `projectId=${bpSsoProductData?.projectId}&supplierId=${bpSsoProductData?.supplierId}&retailerId=${bpSsoProductData?.retailerId}
&sso=true`;

  return { ssoProductParams, productId };
};

const useSsoProductParams = () => {
  const urlQuery = useGetQuery();

  const { id: productId } = useParams();

  const ssoParams = useMemo(() => {
    const paramsRedirectUrl = Object.fromEntries(urlQuery);

    return {
      redirectUrl: paramsRedirectUrl?.redirectPath,
      projectId: paramsRedirectUrl?.projectId,
      productId,
      retailerId: paramsRedirectUrl?.retailerId,
      callbackSuccess: paramsRedirectUrl?.callbackSuccess,
      callbackFail: paramsRedirectUrl?.callbackFail,
      supplierId: paramsRedirectUrl?.supplierId,
      supplierName: paramsRedirectUrl?.supplierName,
      newSupplier: paramsRedirectUrl?.newSupplier,
      contactEmail: paramsRedirectUrl?.contactEmail,
      contactFirstName: paramsRedirectUrl?.contactFirstName,
      contactLastName: paramsRedirectUrl?.contactLastName,
      contactPhoneNumber: paramsRedirectUrl?.contactPhoneNumber,
      sso: paramsRedirectUrl?.sso,
    };
  }, [Object.fromEntries(urlQuery)]);

  const ssoParamUrl = useMemo(() => {
    return ssoParams.sso === 'true'
      ? `projectId=${ssoParams?.projectId}&supplierId=${ssoParams?.supplierId}&retailerId=${ssoParams?.retailerId}&sso=true`
      : '';
  }, [ssoParams]);

  return {
    ssoParams,
    ssoParamUrl,
    productId: ssoParams?.productId,
    isSsoProductView: ssoParams?.sso,
  };
};

export { useSsoProductParams, useSsoProductParamsUrlForValidation };
