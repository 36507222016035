import React, { memo, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Row, Tree, Typography } from 'antd';
import { Droppable } from 'react-beautiful-dnd';
import { DownOutlined, DragOutlined } from '@ant-design/icons';

import useCustomScroll from 'hooks/useCustomScroll';
import { useRenderTree } from '../hook/renderTree';
import './QueryCondition.less';
import { handleRemoveTitle } from '../utils';
import WrapperCalculatorSize from 'common/components/wrapper/WrapperCalculatorSize';

const nameClass = 'query-conditions';

const QueryConditions = (props) => {
  useCustomScroll(`.${nameClass}`);
  const {
    viewMode,
    queryConditions,
    propertiesData,
    fetchEntityAttribute,
    queryConditionsId = 'query-conditions',
    entityType,
    ...restProp
  } = props;

  const { mappingTreeTitle } = useRenderTree({
    propertiesData,
    entityType,
  });

  let mappingTreeData = useMemo(
    () =>
      mappingTreeTitle({
        viewMode,
        queryConditionsId,
        queryConditions,
        ...restProp,
      }),
    [JSON.stringify(queryConditions), JSON.stringify(propertiesData), viewMode]
  );

  return (
    <>
      <Droppable droppableId={'query_conditions'} isCombineEnabled={true}>
        {(provided, { isDraggingOver }) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classnames({
              'query-conditions scroller-extend': true,
              'query-conditions__is-dragging-over': !isDraggingOver,
            })}
            id={queryConditionsId}
          >
            {!!queryConditions ? (
              <RenderTree treeData={mappingTreeData} />
            ) : (
              <Row className='data-columns__none-data'>
                <DragOutlined className='data-columns__none-data-icon' />
                <Typography.Title level={5}>
                  Drag Attributes to this area to Query Conditions
                </Typography.Title>
              </Row>
            )}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default QueryConditions;

const RenderTree = memo(({ treeData }) => {
  const [keyCollapse, setKeyCollapse] = useState([]);

  const onExpand = (key, { node }) => {
    let cloneKeyCollapse = [...keyCollapse];

    if (cloneKeyCollapse.includes(node?.key)) {
      cloneKeyCollapse = cloneKeyCollapse.filter((item) => item !== node?.key);
    } else {
      cloneKeyCollapse.push(node?.key);
    }
    setKeyCollapse(cloneKeyCollapse);
  };

  const removedTitle = handleRemoveTitle(treeData);

  useEffect(() => {
    if (treeData) setKeyCollapse(treeData?.collapseKey);
  }, [JSON.stringify(removedTitle)]);

  return (
    <WrapperCalculatorSize>
      {({ height }) => (
        <Tree
          showLine={{ showLeafIcon: false }}
          switcherIcon={<DownOutlined />}
          treeData={[treeData].slice()}
          expandedKeys={keyCollapse}
          onExpand={onExpand}
          selectable={false}
          virtual
          height={height}
        />
      )}
    </WrapperCalculatorSize>
  );
});
