import React from 'react';
import { Form, Select } from 'antd';
import { WrapperSelect } from 'common/components';

import {
  HeaderGroupElement,
  GroupElementWrapper,
  FormItemList,
  FormItemText,
  FormItemNumber,
  FormItemDatetime,
  FormItemContainer,
  FormItemBoolean,
} from '../../shared/components';

import FormSingleNestedValue from './FormSingleNestedValue';
import FormMultipleNestedValue from './FormMultipleNestedValue';

import {
  useProductDetail,
  useProductDetailDispatch,
  productDetailActionTypes,
} from './ProductDetailContext';

import { useScrollToFoundModule } from '../../shared/hooks';

import {
  checkAllergenModuleName,
  checkAllergenProperty,
  getChangedField,
} from './allergenUtils';

import {
  findProductPropertyEnums,
  checkTypeOfFormNested,
  getKeyNestedObj,
  checkSpecialField,
} from './utils';
import { filterModuleProperties } from './filterModuleUtils';
import sortByKey from 'utils/sortByKey';

import { NUMERIC_TYPE } from 'static/Constants';
import { useMemo } from 'react';

const ProductDetailEdit = ({
  isGroup,
  ixoneChangedFieldRef,
  moduleNameProperties,
  productEnums,
  isEditBasedModule,
  handleValidate,
}) => {
  const filteredModuleNameProperties = useMemo(
    () => filterModuleProperties(moduleNameProperties),
    [moduleNameProperties]
  );

  const { moduleProperties: listProperties = [], moduleGroupProperties = [] } =
    filteredModuleNameProperties;

  const {
    formInstance,
    advancedModule: { foundFieldFullPath: advancedFoundFieldFullPath },
    baseModule: { foundFieldFullPath: baseFoundFieldFullPath },
  } = useProductDetail();

  const dispatch = useProductDetailDispatch();

  useScrollToFoundModule({
    elementByClassName: document.getElementsByClassName(
      `product-detail-view__label-form-item--${
        isEditBasedModule ? baseFoundFieldFullPath : advancedFoundFieldFullPath
      }`
    ),
  });

  const handleSetIXONEFieldChange = (changedFields) => {
    const value = changedFields?.[0]?.value ?? null;
    const indexField = changedFields?.[0]?.name;

    // Filter the components of the field path to contain only strings.
    // fieldPathWithoutNumbers will have a structure similar to fieldFullPath of the BE.
    const fieldPathWithoutNumbers = indexField?.filter(
      (path) => typeof path === 'string' || path instanceof String
    );

    const moduleName = moduleNameProperties?.moduleName;

    const fieldFullPath = isEditBasedModule
      ? fieldPathWithoutNumbers?.join('.')
      : moduleName + '.' + fieldPathWithoutNumbers?.join('.');

    const specialModule = checkSpecialField(fieldFullPath);
    if (specialModule) return;

    // Assign the value and key of the changed field to an object
    ixoneChangedFieldRef.current[indexField?.join('.')] = {
      value,
      key: indexField,
      fieldFullPath,
    };
  };

  const handleDeleteFieldForm = ({ fieldFormValues, fieldPath }) => {
    const pathList = getKeyNestedObj(fieldFormValues, fieldPath?.join('.'));

    pathList?.forEach((path) => {
      const fieldPathWithoutNumbers = path?.split('.')?.filter((path) => {
        const indexOfPath = +path;
        return !(indexOfPath >= 0);
      });

      const moduleName = moduleNameProperties?.moduleName;

      const fieldFullPath = isEditBasedModule
        ? fieldPathWithoutNumbers?.join('.')
        : moduleName + '.' + fieldPathWithoutNumbers?.join('.');

      // Assign the value and key of the changed field to an object
      ixoneChangedFieldRef.current[path] = {
        value: null,
        key: path,
        fieldFullPath,
      };
    });
  };

  if (isGroup) {
    return (
      <div style={{ height: '80vh', padding: '6px 10px' }} className='scroller'>
        <Form
          form={formInstance}
          labelCol={{
            span: 6,
          }}
          className='product-detail__form'
          onFieldsChange={(changedFields, allFields) => {
            handleSetIXONEFieldChange(changedFields, allFields);
          }}
        >
          {moduleGroupProperties.map((groupItem) => {
            if (groupItem?.groupProperties?.length === 0) return null;

            return (
              <GroupElementWrapper
                key={groupItem.nameGroup}
                style={{ padding: 12, margin: '18px 0px' }}
              >
                <HeaderGroupElement
                  header={groupItem.nameGroup}
                  style={{ left: 0 }}
                />

                {groupItem.groupProperties.map((item) => {
                  return (
                    <FormElement
                      key={item.propertyName}
                      property={item}
                      productEnums={productEnums}
                      handleValidate={handleValidate}
                      deleteFieldForm={handleDeleteFieldForm}
                    />
                  );
                })}
              </GroupElementWrapper>
            );
          })}
        </Form>
      </div>
    );
  }

  return (
    <div style={{ height: '80vh', padding: '6px 10px' }} className='scroller'>
      <Form
        form={formInstance}
        labelCol={{
          span: 6,
        }}
        className='product-detail__form'
        name='nested-messages'
        onFieldsChange={(changedFields) => {
          handleSetIXONEFieldChange(changedFields);

          if (checkAllergenModuleName(moduleNameProperties?.moduleName)) {
            // The 'allFields' value is an array, and we have updated one of the fields.
            // Therefore, we can retrieve the first field from the array.

            const indexField = changedFields?.[0]?.name;
            const changedField = getChangedField(indexField);

            // We have only validated each field (AllergenTypeCode, Region) in the Allergen section.
            // While deleting a field in the Allergen section will have an impact here.
            if (
              checkAllergenProperty(indexField) &&
              changedField !== 'Allergen'
            ) {
              const changedValueField = changedFields?.[0]?.value;

              dispatch({
                type: productDetailActionTypes.VALIDATE_VALUE_ALLERGEN_FIELD,
                payload: {
                  formInstance,
                  indexField,
                  changedValueField,
                },
              });
            }
          }
          return;
        }}
      >
        {listProperties.map((property) => {
          return (
            <FormElement
              key={property.propertyName}
              property={property}
              productEnums={productEnums}
              handleValidate={handleValidate}
              deleteFieldForm={handleDeleteFieldForm}
            />
          );
        })}
      </Form>
    </div>
  );
};
/* 
  - Single nested value: childProperties.length > 0 && isArray = false;
    * A value of property is {{...}}

  
  - Multiple nested values: childProperties.length > 0 && isArray = true;
    * A value of property is [{...}, {...}]
    
*/

const FormElement = ({
  property,
  productEnums,
  handleValidate,
  deleteFieldForm,
}) => {
  const {
    childProperties,
    isArray,
    propertyType,
    propertyName,
    propertyDisplayName,
    listName,
  } = property;

  const { isSingleNestedValue, isMultipleNestedValue } = checkTypeOfFormNested(
    childProperties,
    isArray
  );

  if (isSingleNestedValue) {
    return (
      <FormSingleNestedValue
        name={property.propertyName}
        property={property}
        productEnums={productEnums}
        parentNestedIndex={[propertyName]}
        handleValidate={handleValidate}
        deleteFieldForm={deleteFieldForm}
      />
    );
  }

  if (isMultipleNestedValue) {
    return (
      <FormMultipleNestedValue
        properties={childProperties}
        isArray={isArray}
        name={propertyName}
        label={propertyDisplayName}
        property={property}
        productEnums={productEnums}
        parentNestedIndex={[]}
        handleValidate={handleValidate}
        deleteFieldForm={deleteFieldForm}
      />
    );
  } else {
    if (listName) {
      const propertyEnums = findProductPropertyEnums(listName, productEnums);

      return (
        <FormItemContainer property={property} key={propertyName}>
          <WrapperSelect
            showSearch
            mode={isArray ? 'multiple' : ''}
            placeholder={`Please Select ${propertyDisplayName}`}
            filterOption={(input, option) =>
              option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
          >
            {sortByKey(propertyEnums, 'enumDisplayName')?.map(
              (property, index) => (
                <Select.Option
                  value={property.enumDisplayName}
                  key={property.enumDisplayName + index}
                >
                  {property.enumDisplayName
                    ? property.enumDisplayName
                    : property.enumDescription}
                </Select.Option>
              )
            )}
          </WrapperSelect>
        </FormItemContainer>
      );
    }

    if (isArray) {
      if (NUMERIC_TYPE.includes(property.propertyType)) {
        return (
          <FormItemList
            property={property}
            name={property.propertyName}
            className='product-detail__form-list--non-nested'
            variant='number'
          />
        );
      } else if (property.propertyType === 'datetime') {
        return (
          <FormItemList
            property={property}
            name={property.propertyName}
            className='product-detail__form-list--non-nested'
            variant='datetime'
          />
        );
      } else {
        return (
          <FormItemList
            property={property}
            name={property.propertyName}
            className='product-detail__form-list--non-nested'
            variant='text'
          />
        );
      }
    }

    if (propertyType === 'string')
      return <FormItemText property={property} labelCol={{ span: 6 }} />;

    if (NUMERIC_TYPE.includes(propertyType))
      return <FormItemNumber property={property} labelCol={{ span: 6 }} />;

    if (propertyType === 'datetime')
      return <FormItemDatetime property={property} labelCol={{ span: 6 }} />;

    if (propertyType === 'boolean') {
      return <FormItemBoolean property={property} labelCol={{ span: 6 }} />;
    }

    // Default display text input for catching bug from incomplete BE data
    return <FormItemText property={property} labelCol={{ span: 6 }} />;
  }
};

export default ProductDetailEdit;
