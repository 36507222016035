import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import * as nestedDetailGridUtils from 'common/components/product-nested-detail-grid/utils';

import {
  ADD_QUERY,
  ADVANCED_FILTER_MODE,
  DEFAULT_OPERATION,
  MAXIMUM_DATA_COLUMNS,
  MAXIMUM_QUERY_CONDITIONS,
  MODULE_ALLOW_NESTED_ADVANCED_FILTER,
} from './constans';
import { formatSpecialEnum } from 'pages/product-full-view/shared/utils';

const PRIMARY_MODULE_REGEX = /^(primary|product)\./;

export const createMapFieldNameFromDisplayName = (displayName) => {
  displayName = displayName.split(' ');
  displayName[0] = displayName[0].toLowerCase();
  return displayName.join('');
};

export const covertNestedArrToSingleArr = (data) => {
  let property = [];
  const recursionProperty = (nestedArr) => {
    const arrMapping = nestedArr.childFields ?? nestedArr;
    arrMapping.forEach((field) => {
      let nestedProperty = field;

      if (!field.nestedDisplayName) {
        nestedProperty = createNestedProperty(nestedArr, field);
      }

      if (field?.childFields?.length > 0) {
        recursionProperty(field);
      } else {
        property.push(nestedProperty);
      }
    });
  };
  recursionProperty(data);
  return property;
};

const createNestedProperty = (parentProperty, property) => {
  if (!property.fieldName) {
    property['fieldName'] = createMapFieldNameFromDisplayName(
      property.displayName
    );
  }

  property['childFields'] = property?.childFields ?? property?.fields;

  return property;
};

export const getDropProperty = (data, selectedPropertyIds, draggableId) => {
  let property = [];
  const recursionProperty = (arr) => {
    const arrMapping = arr.childFields ?? arr;
    arrMapping.forEach((field) => {
      const nestedProperty = createNestedProperty(arr, field);
      const hasProperty =
        selectedPropertyIds?.includes(nestedProperty.fieldName + '_entity') ||
        nestedProperty.fieldName + '_entity' === draggableId;

      if (hasProperty) {
        property.push(nestedProperty);
      } else {
        if (nestedProperty?.childFields) recursionProperty(nestedProperty);
      }
    });
  };
  recursionProperty(data);
  return covertNestedArrToSingleArr(property);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = _.cloneDeep(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderMultipleItem = (
  dataColumns,
  selectedPropertyIds,
  destination
) => {
  let data = [];
  let dataColumnsTemp = [...dataColumns];

  dataColumnsTemp = dataColumnsTemp.filter(
    (field) => !selectedPropertyIds.includes(field.fieldName + '_column')
  );

  dataColumns?.forEach((field, i) => {
    if (selectedPropertyIds.includes(field.fieldName + '_column')) {
      data.push(field);
    }
  });

  data?.forEach((val, i) => {
    dataColumnsTemp.splice(destination?.index + i, 0, val);
  });

  return dataColumnsTemp;
};

export const handleAddItemToArr = ({ addArray, sourceArray, index }) => {
  let clonedArr = _.clone(sourceArray);

  clonedArr.push(...addArray);

  clonedArr = _.uniq(clonedArr);

  return clonedArr;
};

export const filterFields = (dataEntity, searchValue) => {
  let dataFilter = [];

  const recursionFilter = (data) => {
    let result = [];
    data?.forEach((field) => {
      if (
        field?.displayName?.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        result.push(field);
      } else {
        if (field?.childFields?.length > 0) {
          const childFields = recursionFilter(field.childFields);
          if (childFields?.length) {
            result.push({
              ...field,
              childFields: childFields,
            });
          }
        }
      }
    });
    return result;
  };

  dataEntity.forEach((field) => {
    const childFields = recursionFilter([field]);
    if (childFields?.length) dataFilter.push(...childFields);
  });

  return dataFilter;
};

export const mappingQueryCondition = (listDropItems, currentQueryCondition) => {
  let cloneQuery = _.cloneDeep(currentQueryCondition);

  let children = cloneQuery?.children?.length > 0 ? cloneQuery?.children : [];

  listDropItems.forEach((property) => {
    const { dataType, operator, possibleOperator } = property;
    const isBool = dataType === 'boolean' || dataType === 'bool';

    const fieldName = addPrimaryToFieldName(property?.fieldName);

    const items = {
      id: uuidv4(),
      fieldName,
      operator: operator || (possibleOperator?.length && possibleOperator[0]),
      value: isBool ? false : null,
    };

    children.push(items);
  });

  return {
    operation: cloneQuery?.operation ?? DEFAULT_OPERATION,
    isNestedContainer: cloneQuery?.isNestedContainer ?? false,
    children: children,
    strictMode: cloneQuery?.strictMode ?? false,
  };
};

export const createQueryContainer = (dropEntityData, queryItem) => {
  const droppedQuery = dropEntityData.map((dropItem) => {
    const { dataType, operator, possibleOperator } = dropItem;
    const isBool = dataType === 'boolean' || dataType === 'bool';

    const fieldName = addPrimaryToFieldName(dropItem.fieldName);

    return {
      fieldName,
      id: uuidv4(),
      operator: operator || (possibleOperator?.length && possibleOperator[0]),
      value: isBool ? false : null,
    };
  });

  const children =
    queryItem.children?.length > 0
      ? [...queryItem.children, ...droppedQuery]
      : [...(queryItem?.id ? [queryItem] : []), ...droppedQuery];

  return {
    operation: queryItem?.operation ?? DEFAULT_OPERATION,
    isNestedContainer: false,
    children,
    strictMode: queryItem?.strictMode ?? false,
  };
};

export const combineQueryCondition = (
  dropEntityData,
  queryConditions,
  { draggableId }
) => {
  const recursionCombine = (queryList, parentPath) => {
    return queryList.reduce((accumulator, currentQueryItem, index) => {
      const valuePath = [...parentPath, index + ''];
      const queryItemKey = valuePath.join('.');
      if (draggableId === queryItemKey) {
        const queryContainer = createQueryContainer(
          dropEntityData,
          currentQueryItem
        );
        accumulator.push(queryContainer);
      } else {
        accumulator.push({
          ...currentQueryItem,
          children:
            currentQueryItem.children?.length > 0
              ? recursionCombine(currentQueryItem.children, [
                  ...valuePath,
                  'children',
                ])
              : undefined,
        });
      }

      return accumulator;
    }, []);
  };

  const combinedQueryConditions = {
    ...queryConditions,
    children: recursionCombine(queryConditions.children, ['children']),
  };

  return combinedQueryConditions;
};

const filterGroupEmptyData = (dataQueryConditions, originArr, indexOrigin) => {
  dataQueryConditions?.forEach((item, index) => {
    if (item?.children?.length === 0) {
      if (originArr?.length > 0) {
        originArr?.splice(indexOrigin, 1);
      } else {
        dataQueryConditions?.splice(index, 1);
      }
    } else if (item?.children?.length === 1) {
      const data = originArr ?? dataQueryConditions;
      const indexData = indexOrigin ?? index;
      filterGroupEmptyData(item?.children, data, indexData);
    } else if (item?.children?.length > 1) {
      filterGroupEmptyData(item?.children);
    }
  });
};

const recursionRemoveQuery = (dataQueryConditions, fieldName) => {
  dataQueryConditions?.forEach((item, index) => {
    if (item === 'remove') {
      dataQueryConditions?.splice(index, 1);
    } else if (item?.children?.length > 0) {
      recursionRemoveQuery(item?.children, fieldName);
    }
  });
};

export const removeQueryCondition = (dataQueryConditions, valuePath) => {
  const cloneDataQueryConditions = _.cloneDeep(dataQueryConditions);
  _.set(cloneDataQueryConditions, valuePath, 'remove');

  recursionRemoveQuery(cloneDataQueryConditions?.children);
  filterGroupEmptyData(cloneDataQueryConditions?.children);

  return cloneDataQueryConditions;
};

export const handleUpdateConjunction = (
  dataQueryCondition,
  valuePath,
  checked
) => {
  const cloneQueryCondition = _.cloneDeep(dataQueryCondition);

  _.set(
    cloneQueryCondition,
    [...valuePath, 'operation'],
    checked ? 'AND' : 'OR'
  );

  return cloneQueryCondition;
};

export const handleUpdateOperator = (
  dataQueryCondition,
  valuePath,
  value,
  keyObject
) => {
  _.set(dataQueryCondition, [...valuePath, keyObject], value);
  return dataQueryCondition;
};

export const handleUpdateBetweenTime = (
  dataQueryCondition,
  valuePath,
  value
) => {
  _.set(dataQueryCondition, [...valuePath, 'value'], value[0]);
  _.set(dataQueryCondition, [...valuePath, 'otherValue'], value[1]);
  return dataQueryCondition;
};

export const changeValueDataType = (type) => {
  return (
    (type === 'DigitalMedia' && 'asset') ||
    (type === 'Member' && 'member') ||
    (type === 'ProductItem' && 'product')
  );
};

export const handleUpdateColumnDefs = (dataColumns) => {
  let columnDefsArray = [];
  dataColumns.forEach((val) => {
    let valTemp = Object.assign({}, val);
    let splitField = val.fieldName.split('.');
    if (splitField.length > 1) {
      valTemp.headerName = val.displayName;
      valTemp = {
        ...valTemp,
        cellRenderer: true,
      };
    }

    columnDefsArray.push(valTemp);
  });
  return columnDefsArray;
};

export const checkNestedContainer = (field) => {
  let isNestedContainer = false;
  let key = null;

  const recursionFunc = (field, keyModule) => {
    key = keyModule;
    field?.forEach((item) => {
      key = key ? key : item?.fieldName ? item?.fieldName?.split('.')[0] : null;
    });

    if (
      !MODULE_ALLOW_NESTED_ADVANCED_FILTER.includes(key) &&
      typeof key === 'string'
    ) {
      isNestedContainer = false;
      return;
    }

    field?.forEach((item) => {
      if (item?.children?.length > 0) {
        recursionFunc(item?.children, key);
      }
    });

    field?.every((item) => {
      if (item?.fieldName?.split('.')[0] === key) {
        isNestedContainer = true;
        return true;
      } else {
        isNestedContainer = false;
        return false;
      }
    });
  };

  recursionFunc(field);
  return isNestedContainer;
};

export const removeModule = (fieldName) => {
  const arr = fieldName?.split('.');
  const checkFieldName = arr.includes('primary') || arr.includes('product');
  if (!checkFieldName) return fieldName;
  arr.shift();
  return arr.join('.');
};

const removeSpace = (text) => {
  return text?.split(' ').join('');
};

export const handleCreateSearchContainer = (queryConditions) => {
  const handleRecursion = (queryConditions) => {
    const fieldList = [];
    const childContainerList = [];
    const { strictMode } = queryConditions ?? {};
    queryConditions?.children?.forEach((property) => {
      if (!Boolean(property?.children?.length)) {
        fieldList.push(property);
      } else {
        childContainerList.push(property);
      }
    });

    const fields = [];
    fieldList.forEach((property) => {
      if (property?.fieldName) {
        fields.push({
          operator: removeSpace(property?.operator),
          fieldName: removeModule(property?.fieldName),
          otherValue: property?.otherValue ?? null,
          value: property?.value ?? null,
          values: property?.values ?? null,
        });
      }
    });

    const isNestedContainer = checkNestedContainer(queryConditions?.children);

    return {
      operation: queryConditions?.operation ?? 'OR',
      isNestedContainer: strictMode ? isNestedContainer : false,
      fields,
      childSearchContainers: childContainerList.map((item) =>
        handleRecursion(item)
      ),
    };
  };

  const data =
    queryConditions?.children?.length > 0
      ? handleRecursion(queryConditions)
      : {};

  return data;
};

export const handleCheckStrictMode = (queryConditions) => {
  let strickMode = false;
  const handleRecursion = (queryConditions) => {
    const childContainerList = [];

    queryConditions?.children?.forEach((property) => {
      if (Boolean(property?.children?.length)) {
        childContainerList.push(property);
      }
    });

    strickMode = checkNestedContainer(queryConditions?.children);

    if (childContainerList.length > 0 && !strickMode) {
      childContainerList.forEach((item) => {
        handleRecursion(item);
      });
    }

    return strickMode;
  };
  return handleRecursion(queryConditions);
};

export const getPropertyData = ({
  properties,
  fieldName,
  parentDisplayName = '',
}) => {
  return properties?.reduce((accumulator, currentProperty, index) => {
    if (accumulator?.fieldName) return accumulator; //* stop recursive when data has been matched

    const { childFields, fields, ...rest } = currentProperty;

    const fullPathDisplayName = `${parentDisplayName}${
      parentDisplayName && rest.displayName ? ' - ' : ''
    }${rest.displayName}`;

    //* remove primary/product module of currentProperty and fieldName to prevent old data bug
    const isCurrentPrimaryModule = checkPrimaryModule(
      currentProperty?.fieldName
    );
    const currentFieldName = isCurrentPrimaryModule
      ? getColumnDataFieldName(currentProperty?.fieldName)
      : currentProperty?.fieldName;

    const isPrimaryModule = checkPrimaryModule(fieldName);
    const checkedFieldName = isPrimaryModule
      ? getColumnDataFieldName(fieldName)
      : fieldName;

    // const matched = currentProperty?.fieldName === fieldName;
    const matched = currentFieldName === checkedFieldName;

    if (matched) {
      return {
        ...rest,
        fullPathDisplayName,
      };
    }

    const childList = fields || childFields;
    if (childList?.length)
      return getPropertyData({
        properties: childList,
        fieldName,
        parentDisplayName: fullPathDisplayName,
      });

    return accumulator;
  }, {});
};

export const generateResultColumnDefs = ({
  dataColumns,
  entityType,
  modeData,
}) => {
  let shouldShowDetail = false;

  const properties = window.advancedSearch?.entityAttributes?.[entityType];

  const dataColumnsProperties = dataColumns.reduce(
    (accumulator, currentColumnField) => {
      const isPrimaryModuleField = checkPrimaryModule(currentColumnField);
      const isFlatDataModuleField =
        modeData === 'flat-data' && checkFlatDataModule(currentColumnField);

      if (!isPrimaryModuleField && !isFlatDataModuleField) {
        shouldShowDetail = true;
        return accumulator;
      }

      const dataDetail = getPropertyData({
        properties,
        fieldName: currentColumnField,
      });

      accumulator.push(dataDetail);

      return accumulator;
    },
    []
  );
  const columnDefs = mapPropertyToColDef(dataColumnsProperties, modeData);

  const defaultCol = shouldShowDetail
    ? [
        {
          field: 'id',
          headerName: '',
          flex: dataColumnsProperties.length ? false : 1,
          minWidth: 0,
          width: 30,
          resizable: false,
          cellRenderer: 'agGroupCellRenderer',
          valueFormatter: () => ' ',
        },
      ]
    : [];

  return [...defaultCol, ...columnDefs];
};

const mapPropertyToColDef = (dataColumnsProperties, modeData) => {
  if (!dataColumnsProperties) return dataColumnsProperties;

  return dataColumnsProperties.map((propertyData) => {
    const isFlatDataField =
      propertyData.fieldName?.includes('flatDataModule') &&
      modeData === ADVANCED_FILTER_MODE.FLAT_DATA;

    const isFieldNameContainModuleName = propertyData.fieldName?.includes('.');
    //* remove module name product
    const fieldName = isFlatDataField //* keep original fieldName for flatDataModule to get value
      ? propertyData.fieldName
      : isFieldNameContainModuleName
      ? propertyData.fieldName?.split('.')[1]
      : propertyData.fieldName;

    return {
      field: fieldName,
      headerName: propertyData.displayName,
      cellRenderer: nestedDetailGridUtils.getColumnRenderer({
        columnData: propertyData,
        isArray: propertyData.isMultiple || propertyData.isMultipleValue,
      }),
      dataType: propertyData.dataType,
    };
  });
};

export const getColumnData = ({ dataColumns, entityType }) => {
  const properties = window.advancedSearch?.entityAttributes?.[entityType];

  if (!properties) return [];

  const flatEntityProperties = flattenEntityProperties({
    properties,
    dataColumns,
  });

  const showingColumnData = filterShowColumnData({
    dataColumns,
    allColumnsData: flatEntityProperties,
  });

  return showingColumnData;
};

const flattenEntityProperties = ({ properties, dataColumns }) => {
  return properties.reduce((accumulator, currentProperty) => {
    const isModule = currentProperty.isParent && !currentProperty.fieldName;
    if (isModule) {
      accumulator.push({
        fieldName: getModuleNameFromChildren(currentProperty),
        displayName: currentProperty.displayName,
        dataType: 'object',
        isModule: true,
        isPrimary: currentProperty.isPrimary,
        isMasterDetail: checkMasterDetailCol({
          property: currentProperty,
          isModule: true,
          dataColumns,
        }),
      });
    } else {
      //* normal properties
      accumulator.push({
        fieldName: getColumnDataFieldName(currentProperty.fieldName),
        displayName: currentProperty.displayName,
        dataType: getColumnDataType(currentProperty.dataType),
        isMultiple: currentProperty.isMultipleValue,
        moduleName: getModuleNameFromFieldName(currentProperty),
        listName: currentProperty.listName,
        isMasterDetail: checkMasterDetailCol({
          property: currentProperty,
          isModule: false,
          dataColumns,
        }),
      });
    }

    const children = currentProperty.fields || currentProperty.childFields;
    if (children?.length) {
      const flatChildren = flattenEntityProperties({
        properties: children,
        dataColumns,
      });

      accumulator.push(...flatChildren);
    }

    return accumulator;
  }, []);
};

const checkMasterDetailCol = ({ property, isModule, dataColumns }) => {
  if (isModule) {
    const isArrayTypeModule = property?.isListType;
    //* module need one of its child fields is array object
    const hasObjectChild = checkObjectChild(property?.fields);
    const isChildShow = checkChildColumnShow({
      childFields: property.fields,
      dataColumns,
    });

    return isArrayTypeModule && hasObjectChild && isChildShow;
  }

  const dataType = getColumnDataType(property.dataType);
  const isObjectArrayType = dataType === 'object' && property.isMultipleValue;
  const hasObjectChild = checkObjectChild(property.childFields);
  const isChildShow = checkChildColumnShow({
    childFields: property.childFields,
    dataColumns,
  });

  return isObjectArrayType && hasObjectChild && isChildShow;
};

const checkObjectChild = (childFields) => {
  if (!childFields?.length) return false;

  return childFields.some((childProperty) => {
    const dataType = getColumnDataType(childProperty.dataType);

    return dataType === 'object';
  });
};

const checkChildColumnShow = ({ childFields, dataColumns }) => {
  if (!childFields?.length) return false;
  if (!dataColumns?.length) return false;

  return childFields.some((childProperty) => {
    const isInColumnData = dataColumns.some((columnFieldName) => {
      return columnFieldName.indexOf(childProperty.fieldName + '.') > -1;
    });

    return isInColumnData;
  });
};

const filterShowColumnData = ({ dataColumns, allColumnsData }) => {
  const expandedDataColumns = expandDataColumns(dataColumns);

  return allColumnsData.filter((colDataItem) => {
    return expandedDataColumns.includes(colDataItem.fieldName);
  });
};

const expandDataColumns = (dataColumns) => {
  return dataColumns.reduce((accumulator, currentFieldName) => {
    const fieldNameArr = currentFieldName.split('.');

    let previousFieldPath = false;

    fieldNameArr.forEach((fieldPath) => {
      const currentFieldPath = [previousFieldPath, fieldPath]
        .filter(Boolean)
        .join('.');
      const isIncluded = accumulator.includes(currentFieldPath);

      if (!isIncluded) accumulator.push(currentFieldPath);

      previousFieldPath = currentFieldPath;
    });

    return accumulator;
  }, []);
};

const getModuleNameFromChildren = (property) => {
  const { fields } = property;

  if (!fields.length) return;

  return fields[0].fieldName?.split('.')[0];
};

const getModuleNameFromFieldName = (property) => {
  const { fieldName } = property;

  if (!fieldName) return;

  return fieldName.split('.')[0];
};

export const getColumnDataFieldName = (fieldName) => {
  if (!fieldName) return fieldName;
  //* remove primary module name
  //* Bug 4122: forgot why should remove primary module name
  // return fieldName.replace(PRIMARY_MODULE_REGEX, '');

  return fieldName;
};

export const checkPrimaryModule = (fieldName) => {
  if (!fieldName) return false;

  const isSingleFieldName = fieldName?.indexOf('.') === -1;
  const isPrimaryFieldName = PRIMARY_MODULE_REGEX.test(fieldName); //* for old data

  return isSingleFieldName || isPrimaryFieldName;
};

export const checkFlatDataModule = (fieldName) => {
  if (!fieldName) return false;

  const isFlatDataModule = fieldName?.indexOf('flatDataModule') !== -1;

  return isFlatDataModule;
};

const getColumnDataType = (dataType) => {
  const primitiveType = [
    'string',
    'number',
    'boolean',
    'decimal',
    'int',
    'double',
    'datetime',
  ];

  const isPrimitive = primitiveType.includes(dataType);

  return isPrimitive ? dataType : 'object';
};

export const generateRequestColumns = (dataColumns) => {
  return dataColumns.map((colFieldName) => {
    const isPrimaryModule = checkPrimaryModule(colFieldName);
    //* remove module name product.

    const fieldName = isPrimaryModule
      ? colFieldName.split('.').slice(1).join('.')
      : colFieldName;

    return {
      fieldName,
    };
  });
};

export const generateColumnDataDownload = (dataColumns, entityType) => {
  const properties = window.advancedSearch?.entityAttributes?.[entityType];

  const data = dataColumns?.filter((item) => !!item);

  return data.map((colFieldName) => {
    const isPrimaryModule = checkPrimaryModule(colFieldName);
    //* remove module name product.
    const fieldName = isPrimaryModule
      ? colFieldName.split('.').slice(1).join('.')
      : colFieldName;

    const property = getPropertyData({
      properties: properties,
      fieldName: colFieldName,
    });

    return {
      fieldName,
      displayName: property?.displayName,
    };
  });
};

export const countNestedModule = (dataQuery) => {
  let count = 0;

  const loopCountQuery = (dataQuery) => {
    dataQuery?.forEach((item) => {
      const childFields = item?.children || item?.childFields;
      if (childFields?.length > 0) {
        loopCountQuery(childFields);
      } else {
        count++;
      }
    });
  };

  loopCountQuery(dataQuery);
  return count;
};

export const checkTotalQueryConditions = (dataQuery) => {
  const count = countNestedModule(dataQuery?.children);
  return MAXIMUM_QUERY_CONDITIONS >= count;
};

export const checkTotalDataColumns = (dataColumns) => {
  const count = dataColumns?.length;
  return MAXIMUM_DATA_COLUMNS >= count;
};

export const PART_STRING_SPECIAL_FIELD = ['languageCode', 'secondaryLanguage'];

export const checkSpecialField = (fieldName) => {
  for (const partString of PART_STRING_SPECIAL_FIELD) {
    if (fieldName?.indexOf(partString) > -1) return true;
  }

  return false;
};

export const specialField = (property, enumValue) => {
  const isNutrition = property?.fieldName.indexOf('nutritionTypeCode') > -1;

  const isSpecialField = checkSpecialField(property?.fieldName);

  if (isNutrition) {
    return (
      enumValue?.enumDisplayName +
      `(${enumValue?.enumCode ? enumValue?.enumCode : ''})`
    );
  }
  if (isSpecialField) {
    return formatSpecialEnum(enumValue);
  }
  return null;
};

const mappingPrimaryToQueryConditions = (query) => {
  return {
    ...query,
    fieldName: query?.fieldName
      ? addPrimaryToFieldName(query?.fieldName)
      : null,
    children: query?.children
      ? query?.children.map((items) => {
          return mappingPrimaryToQueryConditions(items);
        })
      : null,
  };
};

export const addPrimaryToFieldName = (fieldName) => {
  if (fieldName?.split('.').length === 1) {
    return `primary.${fieldName}`;
  } else {
    return fieldName;
  }
};

export const mappingPrimaryToFieldsName = (query) => {
  const advancedSearchContainer =
    query?.advancedSearchContainer ?? query?.queryConditions;
  const columns = query?.columns ?? query?.dataColumns;

  const mappingDataColumns = columns?.map((column) => {
    return addPrimaryToFieldName(column?.fieldName ?? column);
  });

  const mappingDataConditions = mappingPrimaryToQueryConditions(
    advancedSearchContainer
  );

  return {
    columns: mappingDataColumns,
    advancedSearchContainer: mappingDataConditions,
  };
};

const removePrimaryQuery = (query) => {
  return {
    ...query,
    fieldName: query?.fieldName ? removeModule(query?.fieldName) : null,
    children: query?.children
      ? query?.children.map((items) => {
          return removePrimaryQuery(items);
        })
      : null,
  };
};

export const getSavedQueryObject = (query, type) => {
  const { queryConditions, dataColumns } = query;
  const mappingDataColumns = generateColumnDataDownload(dataColumns, type);
  const mappingDataConditions = removePrimaryQuery(queryConditions);

  return {
    columns: mappingDataColumns,
    advancedSearchContainer: mappingDataConditions,
  };
};

const removeLastFieldName = (fieldName) => {
  let key = fieldName?.split('.');
  key?.pop();
  return key?.join('.');
};

export const getContainerDisplayName = (queryData, getPropertyData) => {
  const emptyChildFields =
    queryData?.children?.length === 0 || !queryData?.children?.length;
  if (emptyChildFields) return '';
  let sameParentPath = '';

  queryData?.children?.forEach((item) => {
    if (!item?.fieldName) return;

    if (!sameParentPath) sameParentPath = item.fieldName;
    const isLonger =
      sameParentPath?.split('.')?.length > item?.fieldName?.split('.')?.length;
    if (isLonger) {
      sameParentPath = item.fieldName;
    }
  });
  if (!sameParentPath) return '';

  const key = removeLastFieldName(sameParentPath);
  const isNested = queryData?.children.every((item) => {
    let parentFieldName = removeLastFieldName(item?.fieldName);
    return parentFieldName && parentFieldName.includes(key);
  });

  if (!isNested) return '';
  const property = getPropertyData(sameParentPath);
  const displayNameArray = property?.fullPathDisplayName?.split('-');
  displayNameArray?.pop();
  return displayNameArray?.join(' - ');
};

export const handleCoverModeModule = (entityAttrs, modeData) => {
  if (!modeData) return entityAttrs;

  const flatModule = handleGroupFlatModule(entityAttrs);

  const data =
    modeData === ADVANCED_FILTER_MODE.FLAT_DATA ? flatModule : entityAttrs;

  return data;
};

const handleGroupFlatModule = (entityAttrs) => {
  const flatModule = entityAttrs?.find(
    (module) => module?.displayName === 'Flat Data'
  );

  let groupModule = [];
  let groupByDisplayName = {};

  flatModule?.childFields?.forEach((property) => {
    if (groupByDisplayName[property?.group]) {
      groupByDisplayName[property?.group] = [
        ...groupByDisplayName[property?.group],
        property,
      ];
    } else {
      groupByDisplayName[property?.group] = [property];
    }
  });

  for (const [key, value] of Object.entries(groupByDisplayName)) {
    groupModule = [
      ...groupModule,
      {
        displayName: key,
        childFields: value,
        isPrimary: false,
      },
    ];
  }

  return groupModule;
};

export const handleAddQueryConditions = (queryConditions, path, type) => {
  const recursionCombine = (queryList, parentPath) => {
    return queryList.reduce((accumulator, currentQueryItem, index) => {
      const valuePath = [...parentPath, index + ''];

      const queryItemKey = valuePath.join('.');

      if (path === queryItemKey) {
        const query = createQuery(type, currentQueryItem);
        accumulator.push(query);
      } else {
        accumulator.push({
          ...currentQueryItem,
          children:
            currentQueryItem.children?.length > 0
              ? recursionCombine(currentQueryItem.children, [
                  ...valuePath,
                  'children',
                ])
              : undefined,
        });
      }

      return accumulator;
    }, []);
  };

  const combinedQueryConditions = {
    ...queryConditions,
    children: recursionCombine(queryConditions.children, ['children']),
  };

  return combinedQueryConditions;
};

export const createQuery = (type, dataQueryConditions) => {
  let query = {};
  if (type === ADD_QUERY.FILTER)
    query = createQueryContainer([{}], dataQueryConditions);

  if (type === ADD_QUERY.GROUP) {
    const add_group = createQueryContainer([{}], {});
    query = {
      ...(dataQueryConditions || []),
      children: [...(dataQueryConditions?.children || []), add_group],
    };
  }

  return query;
};

export const handleRemoveTitle = (treeData) => {
  const recursionFunc = (module) => {
    const treeData = [];
    module?.forEach((property) => {
      const hasChildField = property?.children?.length > 0;
      const { title, ...rest } = property;

      if (hasChildField) {
        treeData?.push({
          ...rest,
          children: recursionFunc(property?.children, property?.displayName),
        });
      } else {
        treeData?.push({ ...rest });
      }
    });
    return treeData;
  };

  return recursionFunc(treeData?.children);
};
