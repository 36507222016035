import React from 'react';

import { Select, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { WrapperSelect, Form } from 'common/components';

import { useGetAllergenProperty } from './hooks';

import sortByKey from 'utils/sortByKey';
import { findProductPropertyEnums } from 'pages/product-full-view/components/product-detail-view/utils';
import { checkDisabledFormField, checkRequiredFormField } from './utils';

import {
  DEFAULT_US_ALLERGENS,
  DEFAULT_WW_ALLERGENS,
} from 'static/AllergenConstants';

const FormAllergen = ({ productEnums, isUSRegion, isWWRegion }) => {
  const allergenProperty = useGetAllergenProperty();

  const customAllergenForm = Form.useFormInstance();
  const levelOfCodeValue = Form.useWatch(
    'LevelOfContainmentCode',
    customAllergenForm
  );

  // Not show regional field in form
  const filteredProperties =
    allergenProperty?.childProperties?.filter(
      (property) => property?.propertyName?.toLowerCase() !== 'regional'
    ) ?? [];

  const renderChildElement = ({ property }) => {
    const { propertyName, listName } = property;

    if (listName) {
      const allPropertyEnums = findProductPropertyEnums(listName, productEnums);
      const regionalPropertyEnums = getRegionalProductEnums({
        productEnums: allPropertyEnums,
        isUSRegion,
        isWWRegion,
      });

      const requiredFields = checkRequiredFormField(levelOfCodeValue);
      const disabled = checkDisabledFormField({
        propertyName,
        levelOfCode: levelOfCodeValue,
      });

      return (
        <FormItemSelectEnum
          property={property}
          nameFormItem={propertyName}
          propertyEnums={regionalPropertyEnums}
          rules={[
            {
              required: requiredFields[propertyName],
              message: 'Please select your value!',
            },
          ]}
          disabled={disabled}
          onChange={(value, propertyName) => {
            if (
              propertyName === 'LevelOfContainmentCode' &&
              value?.toLowerCase() !== 'may contain'
            ) {
              customAllergenForm.setFieldsValue({
                ...customAllergenForm.getFieldsValue(),
                ContainmentSource: null,
              });
            }
          }}
        />
      );
    }

    return null;
  };

  return (
    <>
      {filteredProperties.map((childProperty) => {
        return (
          <React.Fragment key={childProperty.fieldFullPath}>
            {renderChildElement({
              property: childProperty,
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

const getRegionalProductEnums = ({ productEnums, isUSRegion, isWWRegion }) => {
  if (isUSRegion) {
    return productEnums.filter(
      (enumItem) =>
        !DEFAULT_US_ALLERGENS.includes(enumItem.enumDisplayName.toLowerCase())
    );
  } else if (isWWRegion) {
    return productEnums.filter(
      (enumItem) =>
        !DEFAULT_WW_ALLERGENS.includes(enumItem.enumDisplayName.toLowerCase())
    );
  }

  return productEnums;
};

export const LabelFormItem = ({ label, tooltip }) => {
  return (
    <>
      <Tooltip title={label}>
        <Typography.Text ellipsis strong>
          {label}
        </Typography.Text>
      </Tooltip>

      {tooltip && (
        <Tooltip title={tooltip} color='#238dd9'>
          <QuestionCircleOutlined style={{ marginLeft: 4, color: '#595959' }} />
        </Tooltip>
      )}
    </>
  );
};

export const getPropsFormItem = (property) => {
  if (!property) return null;

  return {
    name: property.propertyName,
    label: property.propertyDisplayName ? (
      <LabelFormItem
        label={property.propertyDisplayName}
        tooltip={property.propertyDescription}
        fieldFullPath={property.fieldFullPath}
      />
    ) : null,
  };
};

export const FormItemContainer = ({ property, children, ...otherProps }) => {
  return (
    <Form.Item
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      {...getPropsFormItem(property)}
      {...otherProps}
    >
      {children}
    </Form.Item>
  );
};

export const FormItemSelectEnum = ({
  property,
  nameFormItem,
  propertyEnums,
  onChange = () => {},
  disabled,
  ...otherProps
}) => {
  const { propertyName, propertyDisplayName, isArray } = property;

  return (
    <FormItemContainer property={property} name={nameFormItem} {...otherProps}>
      <WrapperSelect
        showSearch
        mode={isArray ? 'multiple' : ''}
        placeholder={`Please Select ${propertyDisplayName}`}
        filterOption={(input, option) =>
          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        style={{ width: '96%' }}
        disabled={disabled}
        onChange={(value) => onChange(value, propertyName)}
      >
        {sortByKey(propertyEnums, 'enumDisplayName')?.map((property, index) => (
          <Select.Option
            value={property.enumDisplayName}
            key={property.enumDisplayName + index}
          >
            {property.enumDisplayName
              ? property.enumDisplayName
              : property.enumDescription}
          </Select.Option>
        ))}
      </WrapperSelect>
    </FormItemContainer>
  );
};

export default FormAllergen;
