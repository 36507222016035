import React from 'react';
import { AssetTileBody, AssetTileFooter, AssetTileHeader } from '../index';
import './AssetTile.less';
import { get } from 'lodash';
import useDoubleClick from 'hooks/useDoubleClick';

const AssetTile = (props) => {
  const { dataDetail } = props;
  const { onClickItemGrid, onDoubleClick } = props.clickEvents;
  const assetName = get(dataDetail, 'assetName', '');

  const divRef = React.useRef();
  useDoubleClick({
    onSingleClick: (e) => {
      if (onClickItemGrid) onClickItemGrid(dataDetail, e);
    },
    onDoubleClick: (e) => {
      if (onDoubleClick) onDoubleClick(dataDetail);
    },
    ref: divRef,
    latency: 250,
  });

  return (
    <div className='asset-tile'>
      {/*Placeholder for click events*/}
      <div
        style={{
          backgroundColor: 'transparent',
          position: 'absolute',
          zIndex: 30,
          width: '100%',
          height: '100%',
        }}
        ref={divRef}
      />
      <AssetTileHeader dataDetail={dataDetail} />
      <AssetTileBody dataDetail={dataDetail} />
      <AssetTileFooter dataDetail={dataDetail} />
    </div>
  );
};

export default AssetTile;
