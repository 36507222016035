import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as actionsProduct from '../../controllers/actions';
import * as selectorsProduct from '../../controllers/selectors';

import * as endpoints from 'services/subscription/endpoints';

import AgGrid from 'common/components/ag-grid/AgGrid';

import { RibbonPortal, WithLoading } from 'common/components';
import { RIBBON_OPTIONS } from 'static/Constants';

import { useInjectSaga } from 'utils/common/injectSaga';
import { useInjectReducer } from 'utils/common/injectedReducers';
import productFullViewReducer from '../../controllers/reducer';
import productFullViewSaga from '../../controllers/saga';

import * as globalActions from 'redux/global/actions';
import * as selectorsGlobal from 'redux/global/selectors';

import { Col, Row } from 'antd';

import { ProductPrimaryInfo } from '../';

import { useListenReloadPage } from 'hooks/useReloadPage';

import { useGetProductFullView } from 'hooks';

import ProductFullViewRibbon from 'pages/home/ribbon/ProductFullViewRibbon';

const Subscription = () => {
  const option = RIBBON_OPTIONS.PRODUCT_FULL_VIEW;
  const key = 'productFullView';

  useInjectReducer({ key, reducer: productFullViewReducer });
  useInjectSaga({ key, saga: productFullViewSaga });

  const { id } = useParams();

  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState(null);

  const columnSubscriptionProduct = useSelector(
    selectorsProduct.makeSelectColumnSubscriptionProduct()
  );

  const { productFull, isLoading: productLoading } = useGetProductFullView({
    productId: id,
  });
  const reloadPage = useSelector(selectorsGlobal.selectReloadPage());

  useListenReloadPage(() => {
    dispatch(actionsProduct.updateSelectionSubscription(false));
    if (!gridApi) return;

    gridApi.deselectAll();
  });

  useEffect(() => {
    // dispatch(globalActions.changeRibbonActions(option));
    dispatch(globalActions.hideSearchBar());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      actionsProduct.subscriptionProductGridColumnInfo(
        'product-detail-subscriptions-grid'
      )
    );
  }, [id]);

  const handleCallbackDataListSelection = () => {
    if (!gridApi) return;

    // response data is array
    const data = gridApi.getSelectedRows()[0];

    dispatch(actionsProduct.updateSelectionSubscription(data));
  };

  const mapId = (item) => ({
    ...item,
    id: `${item.subscriptionId}_${item.hierarchyGtin}_${item.hierarchyPackDescription}`,
  });

  return (
    <>
      <RibbonPortal option={option}>
        <ProductFullViewRibbon isHaveAddOpenItem={false} productId={id} />
      </RibbonPortal>
      <WithLoading loading={!productFull || productLoading}>
        <Row>
          <Col flex='auto' style={{ maxWidth: 600 }}>
            {productFull && (
              <ProductPrimaryInfo product={productFull} statusWidth={180} />
            )}
          </Col>
          <Col span={24}>
            <div style={{ height: 'calc(100vh - 175px)' }}>
              {columnSubscriptionProduct &&
                columnSubscriptionProduct.length > 0 && (
                  <AgGrid
                    columnDefs={columnSubscriptionProduct}
                    urlGrid={endpoints.GET_PRODUCT_DETAIL_SUBSCRIPTIONS_GRID}
                    urlGridDistinct={
                      endpoints.GET_PRODUCT_DETAIL_SUBSCRIPTIONS_GRID_DISTINCT
                    }
                    requestParams={{
                      productItemId: productFull?.productId,
                    }}
                    callbackDataListSelection={handleCallbackDataListSelection}
                    getGridApi={(gridApi) => setGridApi(gridApi)}
                    gridConfigProps={{
                      rowSelection: 'single',
                    }}
                    responseParams='data'
                    notShowHeaderCheckbox={true}
                    showCheckboxSelectionRender={false}
                    reloadGrid={reloadPage}
                    mapId={mapId}
                  />
                )}
            </div>
          </Col>
        </Row>
      </WithLoading>
    </>
  );
};

export default Subscription;
