import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

import './VideoLoading.less';

const VideoLoading = (props) => {
  const { loading } = props;

  return (
    <>
      {loading ? (
        <div className='video-call-loading'>
          <LoadingOutlined />
        </div>
      ) : null}
    </>
  );
};

VideoLoading.propTypes = {
  loading: PropTypes.bool,
};

export default VideoLoading;
