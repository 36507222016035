import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { EnhancedMarkdown } from 'common/components';
import { useResize } from 'hooks/useResize';
import useCustomScroll from 'hooks/useCustomScroll';

import './OverviewBio.less';

const markdownContentId = 'user-bio-markdown-content';

const OverviewBio = (props) => {
  const { content } = props;
  const wrapperSize = useResize(markdownContentId);
  useCustomScroll('.overview-bio');

  return (
    <Row className='user-profile-overview__bio overview-bio'>
      <EnhancedMarkdown source={content || ''} containerSize={wrapperSize} />
    </Row>
  );
};

OverviewBio.propTypes = {
  content: PropTypes.string,
};

export default OverviewBio;
