/*
 * Login Messages
 *
 * This contains all the text for the login component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.home';

export default defineMessages({
  searchPlaceholder: {
    id: `${scope}.components.layout.search.placeholder`,
    defaultMessage: 'Select [Entity type] items...',
  },
  addFavorite: {
    id: `${scope}.components.layout.favorite.add`,
    defaultMessage: 'Add To Favorites',
  },
  removeFavorite: {
    id: `${scope}.components.layout.favorite.remove`,
    defaultMessage: 'Remove From Favorites',
  },
  addTo: {
    id: `${scope}.components.layout.addition`,
    defaultMessage: 'Add To',
  },
  mediaActions: {
    id: `${scope}.ribbon.digitalMedia.mediaActions`,
    defaultMessage: 'Media Actions',
  },
  mediaPreview: {
    id: `${scope}.ribbon.digitalMedia.mediaPreview`,
    defaultMessage: 'Media Thumbnail',
  },
  toggleDetail: {
    id: `${scope}.components.layout.favorite.toggledetail`,
    defaultMessage: 'Show Preview',
  },
  quickView: {
    id: `${scope}.components.layout.favorite.quickview`,
    defaultMessage: 'Quick View',
  },
  thumbView: {
    id: `${scope}.components.layout.favorite.thumbivew`,
    defaultMessage: 'Thumbnails',
  },
  listView: {
    id: `${scope}.components.layout.favorite.listview`,
    defaultMessage: 'List View',
  },
  tilesView: {
    id: `${scope}.components.layout.favorite.tilesview`,
    defaultMessage: 'Tiles',
  },
  detailsView: {
    id: `${scope}.components.layout.favorite.detailsview`,
    defaultMessage: 'Details View',
  },
  addFolder: {
    id: `${scope}.components.layout.folder.addfolder`,
    defaultMessage: 'Add To Folder',
  },
  addAPL: {
    id: `${scope}.components.layout.folder.addAPL`,
    defaultMessage: 'Add To APL',
  },
  searchFolders: {
    id: `${scope}.components.layout.folder.searchFolders`,
    defaultMessage: 'Find In all Folders',
  },
  searchEntityInAllFoldersTotal: {
    id: `${scope}.components.layout.folder.searchEntityInAllFoldersTotal`,
    defaultMessage: 'Total results',
  },
  searchEntityInAllFoldersError: {
    id: `${scope}.components.layout.folder.searchEntityInAllFoldersError`,
    defaultMessage: 'Cannot find any folders',
  },
  searchEntityInAllFoldersNoData: {
    id: `${scope}.components.layout.folder.searchEntityInAllFoldersNoData`,
    defaultMessage: 'Cannot find any folders',
  },
  deleteFromFolders: {
    id: `${scope}.components.layout.folder.deleteFromFolders`,
    defaultMessage: 'Delete From all Folders',
  },
  deleteFromFoldersConfirm: {
    id: `${scope}.components.layout.folder.deleteFromFoldersConfirm`,
    defaultMessage: 'Are you sure to delete?',
  },
  deleteFromFoldersSuccess: {
    id: `${scope}.components.layout.folder.deleteFromFoldersSuccess`,
    defaultMessage: 'Delete successfully',
  },
  deleteFromFoldersError: {
    id: `${scope}.components.layout.folder.deleteFromFoldersError`,
    defaultMessage: 'Cannot delete',
  },
  searchText: {
    id: `${scope}.components.layout.search.text`,
    defaultMessage: 'Search',
  },
  backText: {
    id: `${scope}.components.layout.back.text`,
    defaultMessage: 'Close Detail',
  },
  backMemberText: {
    id: `${scope}..text`,
    defaultMessage: 'Return to Member',
  },
  backQueryText: {
    id: `${scope}.components.layout.back.query`,
    defaultMessage: 'Return to Query',
  },
  backReportingText: {
    id: `${scope}.components.layout.back.reporting`,
    defaultMessage: 'Return to Folders',
  },
  advanceStack: {
    id: `${scope}.components.layout.search.advanceStack`,
    defaultMessage: 'Advanced',
  },
  advanceSearch: {
    id: `${scope}.components.layout.search.advance`,
    defaultMessage: 'Advance Search',
  },
  gridConfig: {
    id: `${scope}.components.layout.grid.config`,
    defaultMessage: 'Grid Query',
  },
  savedConfig: {
    id: `${scope}.components.layout.grid.saved`,
    defaultMessage: 'Saved Queries',
  },
  gridColumns: {
    id: `${scope}.components.layout.grid.columns`,
    defaultMessage: 'Choose Columns',
  },
  saveConfig: {
    id: `${scope}.components.layout.grid.save`,
    defaultMessage: 'Save Current Query',
  },
  addCuratedConfig: {
    id: `${scope}.components.layout.grid.addCuratedConfig`,
    defaultMessage: 'Add Curated Query',
  },
  manageConfig: {
    id: `${scope}.components.layout.grid.manage`,
    defaultMessage: 'Manage Queries',
  },
  clearConfig: {
    id: `${scope}.components.layout.grid.clear`,
    defaultMessage: 'Clear Current Query',
  },
  profile: {
    id: `${scope}.components.layout.header.profile`,
    defaultMessage: 'My Profile',
  },
  company: {
    id: `${scope}.components.layout.header.company`,
    defaultMessage: 'My Company',
  },
  approveSites: {
    id: `${scope}.components.layout.header.approve.sites`,
    defaultMessage: 'Approved Sites',
  },
  scheduleMeeting: {
    id: `${scope}.components.layout.header.scheduleMeeting`,
    defaultMessage: 'Schedule Meeting',
  },
  impersonate: {
    id: `${scope}.components.layout.header.impersonate`,
    defaultMessage: 'Impersonate',
  },
  helpcenter: {
    id: `${scope}.components.layout.header.helpcenter`,
    defaultMessage: 'Help Center',
  },
  helpMaintenance: {
    id: `${scope}.components.layout.header.helpMaintenance`,
    defaultMessage: 'Help Maintenance',
  },
  help: {
    id: `${scope}.components.layout.header.help`,
    defaultMessage: 'Help',
  },
  logout: {
    id: `${scope}.components.layout.header.logout`,
    defaultMessage: 'Logout',
  },
  dashboard: {
    id: `${scope}.navigation.dashboard`,
    defaultMessage: 'Dashboard',
  },

  favorites: {
    id: `${scope}.navigation.favorites`,
    defaultMessage: 'FAVORITES',
  },
  queries: {
    id: `${scope}.navigation.favorite.queries`,
    defaultMessage: 'Queries',
  },
  sharedQueries: {
    id: `${scope}.navigation.favorite.sharedqueries`,
    defaultMessage: 'Shared Queries',
  },
  favoriteProducts: {
    id: `${scope}.navigation.favorite.products`,
    defaultMessage: 'Favorite Products',
  },
  favoriteAssets: {
    id: `${scope}.navigation.favorite.assets`,
    defaultMessage: 'Favorite Digital Media',
  },
  favoriteMembers: {
    id: `${scope}.navigation.favorite.members`,
    defaultMessage: 'Favorite Members',
  },
  favoriteFolders: {
    id: `${scope}.navigation.favorite.folders`,
    defaultMessage: 'Favorite Folders',
  },

  roleSecurity: {
    id: `${scope}.navigation.role.roleSecurity`,
    defaultMessage: 'Role & Security',
  },

  products: {
    id: `${scope}.navigation.products`,
    defaultMessage: 'Products',
  },
  newItems: {
    id: `${scope}.navigation.product.newitems`,
    defaultMessage: 'New Items',
  },
  apl: {
    id: `${scope}.navigation.product.apl`,
    defaultMessage: 'APL',
  },
  form: {
    id: `${scope}.navigation.maintenance.form`,
    defaultMessage: 'Form Management',
  },
  syndication: {
    id: `${scope}.navigation.product.syndication`,
    defaultMessage: 'Syndication',
  },
  syndicationHistory: {
    id: `${scope}.navigation.product.syndicationHistory`,
    defaultMessage: 'Syndication History',
  },
  subscriptions: {
    id: `${scope}.navigation.subscriptions`,
    defaultMessage: 'Subscriptions',
  },
  mySubscriptions: {
    id: `${scope}.navigation.product.mySubscription`,
    defaultMessage: 'My Subscriptions',
  },
  subscribers: {
    id: `${scope}.navigation.product.subscribers`,
    defaultMessage: 'Subscribers',
  },
  subscription: {
    id: `${scope}.navigation.product.subscription`,
    defaultMessage: 'Subscription',
  },
  Syndication: {
    id: `${scope}.navigation.product.syndication`,
    defaultMessage: 'Syndication',
  },
  mapping: {
    id: `${scope}.navigation.product.mapping`,
    defaultMessage: 'Mapping',
  },
  publications: {
    id: `${scope}.navigation.product.publications`,
    defaultMessage: 'GDSN Received Product',
  },
  myCompanyProducts: {
    id: `${scope}.navigation.product.myCompanyProducts`,
    defaultMessage: "My Company's Products",
  },
  productShareToMe: {
    id: `${scope}.navigation.product.productShareToMe`,
    defaultMessage: 'Product Shared To Me',
  },
  pendingProducts: {
    id: `${scope}.navigation.product.pendingProducts`,
    defaultMessage: 'Pending Products',
  },
  members: {
    id: `${scope}.navigation.members`,
    defaultMessage: 'Members',
  },
  openMember: {
    id: `${scope}.navigation.members.openMember`,
    defaultMessage: 'Open Member',
  },
  openProduct: {
    id: `${scope}.ribbon.product.openProduct`,
    defaultMessage: 'Open Product',
  },
  productHierarchy: {
    id: `${scope}.ribbon.product.productHierarchy`,
    defaultMessage: 'Hierarchy',
  },
  productHierarchyActions: {
    id: `${scope}.ribbon.product.productHierarchyActions`,
    defaultMessage: 'Hierarchy Actions',
  },
  addHierarchy: {
    id: `${scope}.ribbon.product.addHierarchy`,
    defaultMessage: 'Add',
  },
  copyHierarchy: {
    id: `${scope}.ribbon.product.copyHierarchy`,
    defaultMessage: 'Copy',
  },
  deleteHierarchy: {
    id: `${scope}.ribbon.product.deleteHierarchy`,
    defaultMessage: 'Delete',
  },
  editHierarchy: {
    id: `${scope}.ribbon.product.editHierarchy`,
    defaultMessage: 'Edit',
  },
  GdsnShield: {
    id: `${scope}.ribbon.product.GdsnShield`,
    defaultMessage: 'GDSN Shield',
  },
  deleteHierarchyMessage: {
    id: `${scope}.ribbon.product.message.deleteHierarchy`,
    defaultMessage: 'Are you sure to delete this hierarchy?',
  },
  deleteHierarchySuccess: {
    id: `${scope}.ribbon.product.message.deleteSuccess`,
    defaultMessage: 'Delete hierarchy successfully',
  },
  deleteHierarchyError: {
    id: `${scope}.ribbon.product.message.deleteError`,
    defaultMessage: 'Cannot delete hierarchy',
  },
  openAPL: {
    id: `${scope}.ribbon.product.apl.open`,
    defaultMessage: 'Open APL',
  },
  openAPLSchedule: {
    id: `${scope}.ribbon.product.apl.openSchedule`,
    defaultMessage: 'Open Schedule',
  },
  addProductToApl: {
    id: `${scope}.ribbon.product.apl.addProductToApl`,
    defaultMessage: 'Add Item',
  },
  addProductToNewApl: {
    id: `${scope}.ribbon.product.apl.addProductToApl`,
    defaultMessage: 'Add to APL',
  },

  addSearchProductToNewApl: {
    id: `${scope}.ribbon.product.apl.addSearchProductToNewApl`,
    defaultMessage: 'Add Search Results To New APL',
  },
  deleteProductApl: {
    id: `${scope}.ribbon.product.apl.deleteProductApl`,
    defaultMessage: 'Delete Item',
  },
  deleteProductPublication: {
    id: `${scope}.ribbon.pim.deleteProductPublication`,
    defaultMessage: 'Delete Product(s) Publication/Registration',
  },
  withDrawProductPublicationFull: {
    id: `${scope}.ribbon.pim.withDrawProductPublicationFull`,
    defaultMessage: 'Withdraw Product Publication/Registration',
  },
  publishActions: {
    id: `${scope}.ribbon.pim.publishActions`,
    defaultMessage: 'Publish Actions',
  },
  productActions: {
    id: `${scope}.ribbon.pim.productActions`,
    defaultMessage: 'Product Actions',
  },
  deleteProductRegistration: {
    id: `${scope}.ribbon.pim.deleteProductRegistration`,
    defaultMessage: 'Delete Product(s) Registration',
  },
  executeMatchingApl: {
    id: `${scope}.ribbon.product.apl.executeMatchingApl`,
    defaultMessage: 'Execute Matching',
  },
  createApl: {
    id: `${scope}.ribbon.product.apl.createApl`,
    defaultMessage: 'Create APL',
  },
  editAplMetadata: {
    id: `${scope}.ribbon.product.apl.editAplMetadata`,
    defaultMessage: 'Edit Info',
  },
  moreButton: {
    id: `${scope}.ribbon.moreButton`,
    defaultMessage: 'More Button',
  },
  moreButtonBack: {
    id: `${scope}.ribbon.moreButtonBack`,
    defaultMessage: 'Back',
  },
  filterMember: {
    id: `${scope}.navigation.members.filterMember`,
    defaultMessage: 'Ban Manage',
  },
  showAllProducts: {
    id: `${scope}.navigation.members.showAllProducts`,
    defaultMessage: 'Products',
  },
  showAllAssets: {
    id: `${scope}.navigation.members.showAllAssets`,
    defaultMessage: 'Assets',
  },
  goShowAllProducts: {
    id: `${scope}.navigation.members.goShowAllProducts`,
    defaultMessage: "Company's Products",
  },
  goShowAllAssets: {
    id: `${scope}.navigation.members.goShowAllAssets`,
    defaultMessage: "Company's Assets",
  },
  downloadLogo: {
    id: `${scope}.navigation.members.downloadLogo`,
    defaultMessage: 'Logo',
  },
  downloadSheet: {
    id: `${scope}.navigation.members.downloadSheet`,
    defaultMessage: 'Sheets',
  },
  categories: {
    id: `${scope}.navigation.members.categories`,
    defaultMessage: 'Categories',
  },
  emailMember: {
    id: `${scope}.navigation.members.emailMember`,
    defaultMessage: 'Email',
  },
  textMember: {
    id: `${scope}.navigation.members.textMember`,
    defaultMessage: 'Text',
  },
  messageMember: {
    id: `${scope}.navigation.members.messageMember`,
    defaultMessage: 'Message',
  },
  cardMember: {
    id: `${scope}.navigation.members.cardMember`,
    defaultMessage: 'Card',
  },
  cardMemberFull: {
    id: `${scope}.navigation.members.cardMemberFull`,
    defaultMessage: 'Send Contact',
  },
  emailMemberFull: {
    id: `${scope}.navigation.members.emailMemberFull`,
    defaultMessage: 'Send Email',
  },
  messageMemberFull: {
    id: `${scope}.navigation.members.messageMemberFull`,
    defaultMessage: 'Send Message',
  },
  hideMember: {
    id: `${scope}.navigation.members.hideMember`,
    defaultMessage: 'Hide',
  },
  unhideMember: {
    id: `${scope}.navigation.members.unhideMember`,
    defaultMessage: 'Unhide',
  },
  banMember: {
    id: `${scope}.navigation.members.banMember`,
    defaultMessage: 'Add To Ban List',
  },
  listBan: {
    id: `${scope}.navigation.members.listBan`,
    defaultMessage: 'Ban List',
  },
  addMembership: {
    id: `${scope}.navigation.members.addMembership`,
    defaultMessage: 'Add Membership',
  },
  deleteMembership: {
    id: `${scope}.navigation.members.deleteMembership`,
    defaultMessage: 'Delete Membership',
  },
  addBrand: {
    id: `${scope}.navigation.members.addBrand`,
    defaultMessage: 'Add Brand Image',
  },
  deleteBrand: {
    id: `${scope}.navigation.members.deleteBrand`,
    defaultMessage: 'Delete Brand Image',
  },
  downloadBrand: {
    id: `${scope}.navigation.members.downloadBrand`,
    defaultMessage: 'Download Brand Image',
  },
  addContact: {
    id: `${scope}.navigation.members.addContact`,
    defaultMessage: 'Add Contact',
  },
  deleteContact: {
    id: `${scope}.navigation.members.deleteContact`,
    defaultMessage: 'Delete Contact',
  },
  deleteAsset: {
    id: `${scope}.navigation.members.deleteAsset`,
    defaultMessage: 'Delete Asset',
  },
  addAsset: {
    id: `${scope}.navigation.members.addAsset`,
    defaultMessage: 'Add Asset',
  },
  downloadAsset: {
    id: `${scope}.navigation.members.downloadAsset`,
    defaultMessage: 'Download Asset',
  },
  downloadAssets: {
    id: `${scope}.navigation.members.downloadAssets`,
    defaultMessage: 'Download Assets',
  },
  editMultipleAsset: {
    id: `${scope}.navigation.members.editMultipleAsset`,
    defaultMessage: 'Edit Multiple Asset',
  },
  updateMultipleAsset: {
    id: `${scope}.navigation.members.updateMultipleAsset`,
    defaultMessage: 'Update Multiple Asset',
  },
  view: {
    id: `${scope}.navigation.members.view`,
    defaultMessage: 'Display',
  },
  share: {
    id: `${scope}.navigation.share.share`,
    defaultMessage: 'Share',
  },
  download: {
    id: `${scope}.navigation.share.download`,
    defaultMessage: 'Download',
  },
  sendToFTP: {
    id: `${scope}.navigation.share.sendToFTP`,
    defaultMessage: 'Send to FTP',
  },
  sendViaText: {
    id: `${scope}.navigation.share.sendViaText`,
    defaultMessage: 'Send via Text',
  },
  editUser: {
    id: `${scope}.navigation.user.editUser`,
    defaultMessage: 'Edit',
  },
  saveUser: {
    id: `${scope}.navigation.user.saveUser`,
    defaultMessage: 'Save',
  },
  cancelEditUser: {
    id: `${scope}.navigation.user.cancelEditUser`,
    defaultMessage: 'Cancel',
  },
  deleteUser: {
    id: `${scope}.navigation.user.delete`,
    defaultMessage: 'Delete',
  },
  changePassword: {
    id: `${scope}.navigation.user.changePassword`,
    defaultMessage: 'Change Password',
  },
  changeName: {
    id: `${scope}.navigation.user.changeName`,
    defaultMessage: 'Change Username',
  },
  addLocation: {
    id: `${scope}.navigation.user.addLocation`,
    defaultMessage: 'Add Location',
  },
  deleteLocation: {
    id: `${scope}.navigation.user.deleteLocation`,
    defaultMessage: 'Delete Location',
  },
  downloadTransaction: {
    id: `${scope}.navigation.user.downloadTransaction`,
    defaultMessage: 'Download Transaction',
  },
  downloadStatement: {
    id: `${scope}.navigation.user.downloadStatement`,
    defaultMessage: 'Download Statement',
  },
  dateRange: {
    id: `${scope}.navigation.user.dateRange`,
    defaultMessage: 'Set Range',
  },
  openAsset: {
    id: `${scope}.navigation.asset.openAsset`,
    defaultMessage: 'Open Asset',
  },
  linkAssets: {
    id: `${scope}.navigation.asset.linkAssets`,
    defaultMessage: 'Link Assets',
  },
  cancelLink: {
    id: `${scope}.navigation.asset.cancelLink`,
    defaultMessage: 'Cancel Link',
  },
  importAssets: {
    id: `${scope}.navigation.asset.importAssets`,
    defaultMessage: 'Import Assets',
  },
  createAsset: {
    id: `${scope}.navigation.asset.createAsset`,
    defaultMessage: 'Create Asset',
  },
  createIframeAsset: {
    id: `${scope}.navigation.asset.createIframeAsset`,
    defaultMessage: 'Create iFrame',
  },
  replaceAsset: {
    id: `${scope}.navigation.asset.replaceAsset`,
    defaultMessage: 'Replace Asset',
  },
  deleteAssets: {
    id: `${scope}.navigation.asset.deleteAssets`,
    defaultMessage: 'Delete Assets',
  },
  deletePreview: {
    id: `${scope}.navigation.asset.deletePreview`,
    defaultMessage: 'Delete Thumbnail',
  },
  generateView: {
    id: `${scope}.navigation.asset.generateView`,
    defaultMessage: 'Generate Thumbnail',
  },
  generatePreview: {
    id: `${scope}.navigation.asset.generateView`,
    defaultMessage: 'Generate Thumbnail',
  },
  replacePreview: {
    id: `${scope}.navigation.asset.replacePreview`,
    defaultMessage: 'Replace Thumbnail',
  },
  replacePreviewSuccess: {
    id: `${scope}.navigation.asset.replacePreviewSuccess`,
    defaultMessage: 'Replace preview successfully',
  },
  replacePreviewFail: {
    id: `${scope}.navigation.asset.replacePreviewFail`,
    defaultMessage: 'Failed to replace preview',
  },
  assets: {
    id: `${scope}.navigation.assets`,
    defaultMessage: 'Digital Media',
  },
  productAssets: {
    id: `${scope}.navigation.asset.product`,
    defaultMessage: 'Assets',
  },
  docAssets: {
    id: `${scope}.navigation.asset.docAssets`,
    defaultMessage: 'Documents',
  },
  multiMedia: {
    id: `${scope}.navigation.asset.multiMedia`,
    defaultMessage: 'Multi Media',
  },
  versions: {
    id: `${scope}.navigation.asset.versions`,
    defaultMessage: 'Versions',
  },
  reporting: {
    id: `${scope}.navigation.reporting`,
    defaultMessage: 'Reporting',
  },
  reportingOwned: {
    id: `${scope}.navigation.reportingOwned`,
    defaultMessage: 'My Reports',
  },
  reportingShared: {
    id: `${scope}.navigation.reportingShared`,
    defaultMessage: 'Reports Shared to Me',
  },
  reportingCreate: {
    id: `${scope}.navigation.reportingCreate`,
    defaultMessage: 'Create Report',
  },
  deleteReport: {
    id: `${scope}.navigation.deleteReport`,
    defaultMessage: 'Delete Reports',
  },
  reportingOpen: {
    id: `${scope}.navigation.reportingOpen`,
    defaultMessage: 'Execute Report',
  },
  reportingClone: {
    id: `${scope}.navigation.reportingClone`,
    defaultMessage: 'Clone',
  },
  reportingCloneName: {
    id: `${scope}.navigation.reportingCloneName`,
    defaultMessage: 'Name',
  },
  reportingCloneDescription: {
    id: `${scope}.navigation.reportingCloneDescription`,
    defaultMessage: 'Description',
  },
  reportingCloneRequired: {
    id: `${scope}.navigation.reportingCloneRequired`,
    defaultMessage: 'This field is required',
  },
  reportingCloneSuccess: {
    id: `${scope}.navigation.reportingCloneSuccess`,
    defaultMessage: 'Clone reporting successfully',
  },
  reportingCloneError: {
    id: `${scope}.navigation.reportingCloneError`,
    defaultMessage: 'Cannot clone reporting',
  },
  programs: {
    id: `${scope}.navigation.programs`,
    defaultMessage: 'Special Programs',
  },
  curatedQuery: {
    id: `${scope}.navigation.curatedQuery`,
    defaultMessage: 'Curated Query',
  },
  recipientsFields: {
    id: `${scope}.navigation.recipientsFields`,
    defaultMessage: 'Recipients Fields',
  },
  splashDownloadHistory: {
    id: `${scope}.navigation.splashDownloadHistory`,
    defaultMessage: 'SPLASH Download History',
  },
  folders: {
    id: `${scope}.navigation.folders`,
    defaultMessage: 'FOLDERS',
  },
  myFolders: {
    id: `${scope}.navigation.folder.owned`,
    defaultMessage: 'My Folders',
  },
  sharedFolders: {
    id: `${scope}.navigation.folder.shared`,
    defaultMessage: 'Shared Folders',
  },
  openFolder: {
    id: `${scope}.navigation.folder.openFolder`,
    defaultMessage: 'Open Folder',
  },
  removeFromFolder: {
    id: `${scope}.ribbon.folder.removeFromFolder`,
    defaultMessage: 'Remove',
  },
  //* open item
  openItems: {
    id: `${scope}.navigation.openitems`,
    defaultMessage: 'Open Items',
  },
  addOpenItem: {
    id: `${scope}.navigation.asset.addOpenItem`,
    defaultMessage: 'Add to Open Item',
  },
  // Workflow
  workflow: {
    id: `${scope}.navigation.workflow`,
    defaultMessage: 'Workflow',
  },
  workflowMaintenance: {
    id: `${scope}.navigation.workflowMaintenance`,
    defaultMessage: 'Workflow Maintenance',
  },
  qaSpecWorkFlow: {
    id: `${scope}.navigation.qaSpecWorkFlow`,
    defaultMessage: 'QA Spec Workflow',
  },
  administration: {
    id: `${scope}.navigation.administration`,
    defaultMessage: 'Administration',
  },
  definition: {
    id: `${scope}.navigation.definition`,
    defaultMessage: 'Definition',
  },
  // Maintenance
  maintenance: {
    id: `${scope}.navigation.maintenance`,
    defaultMessage: 'MAINTENANCE',
  },
  memberRole: {
    id: `${scope}.navigation.memberRole`,
    defaultMessage: 'Member Role',
  },
  roleManagement: {
    id: `${scope}.navigation.roleManagement`,
    defaultMessage: 'Role Management',
  },
  permissionRequest: {
    id: `${scope}.navigation.roleManagement`,
    defaultMessage: 'Permission Request',
  },
  helpSystem: {
    id: `${scope}.navigation.helpcenter`,
    defaultMessage: 'Help Center',
  },
  categoryHelp: {
    id: `${scope}.navigation.category`,
    defaultMessage: 'Categories',
  },
  postHelp: {
    id: `${scope}.navigation.postHelp`,
    defaultMessage: 'Posts',
  },
  articles: {
    id: `${scope}.navigation.articles`,
    defaultMessage: 'Articles',
  },
  eula: {
    id: `${scope}.navigation.eulaRivir`,
    defaultMessage: 'RIVIR EULA',
  },
  eulaCard: {
    id: `${scope}.navigation.eulaCard`,
    defaultMessage: 'Credit Card T&C',
  },
  eulaCenter: {
    id: `${scope}.navigation.eulaCenter`,
    defaultMessage: 'EULA Center',
  },
  usersManagement: {
    id: `${scope}.navigation.userManagement`,
    defaultMessage: 'User Management',
  },
  memberManagement: {
    id: `${scope}.navigation.memberManagement`,
    defaultMessage: 'Member Management',
  },
  acceptedEula: {
    id: `${scope}.navigation.acceptedEula`,
    defaultMessage: 'Accepted EULA',
  },
  dataManagement: {
    id: `${scope}.navigation.dataManagement`,
    defaultMessage: 'Data Management',
  },
  communication: {
    id: `${scope}.navigation.communication`,
    defaultMessage: 'Communication Templates',
  },
  transitionPage: {
    id: `${scope}.navigation.transitionPage`,
    defaultMessage: 'Transition Page',
  },
  markup: {
    id: `${scope}.navigation.markup`,
    defaultMessage: 'Markup',
  },
  mainPageCenter: {
    id: `${scope}.navigation.mainPageCenter`,
    defaultMessage: 'Main Page Center',
  },
  transitionPage: {
    id: `${scope}.navigation.transitionPage`,
    defaultMessage: 'Transition Page',
  },
  acceptedMarkup: {
    id: `${scope}.navigation.acceptedMarkup`,
    defaultMessage: 'Accepted Markup',
  },
  curatedQuery: {
    id: `${scope}.navigation.curatedQuery`,
    defaultMessage: 'Curated Query',
  },

  //! File ProgressBar
  clearAll: {
    id: `${scope}.filProgressBar.clearBtn`,
    defaultMessage: 'Clear All',
  },
  // folder-ribbon
  folderActions: {
    id: `${scope}.ribbon.folder.folderActions`,
    defaultMessage: 'Folder Actions',
  },
  newFolder: {
    id: `${scope}.ribbon.folder.newFolder`,
    defaultMessage: 'New Folder',
  },
  copyFolder: {
    id: `${scope}.ribbon.folder.copyFolder`,
    defaultMessage: 'Copy Folder',
  },
  moveFolder: {
    id: `${scope}.ribbon.folder.moveFolder`,
    defaultMessage: 'Move',
  },

  deleteFoldersRibbon: {
    id: `${scope}.ribbon.folder.deleteFoldersRibbon`,
    defaultMessage: 'Delete',
  },
  editFoldersRibbon: {
    id: `${scope}.ribbon.folder.editFoldersRibbon`,
    defaultMessage: 'Edit Folder',
  },
  deleteFolderDetailRibbon: {
    id: `${scope}.ribbon.folder.deleteFolderDetailRibbon`,
    defaultMessage: 'Delete Folder',
  },
  shareTitle: {
    id: `${scope}.ribbon.share.title`,
    defaultMessage: 'Share',
  },
  shareEditable: {
    id: `${scope}.ribbon.share.editable`,
    defaultMessage: 'Editable',
  },
  shareEditableTooltip: {
    id: `${scope}.ribbon.share.editable.tooltip`,
    defaultMessage:
      'Providing a user Editable access to the folder will allow them to edit the folder contents, and share the folder with other user.',
  },
  shareEditableAssetTooltip: {
    id: `${scope}.ribbon.share.editable.shareEditableAssetTooltip`,
    defaultMessage:
      'Providing a user Editable access to the digital-media will allow them to edit the digital-media contents, and share the digital-media with other user.',
  },
  shareChooseList: {
    id: `${scope}.ribbon.share.chooseList`,
    defaultMessage: 'Choose List',
  },
  shareMemberList: {
    id: `${scope}.ribbon.share.memberList`,
    defaultMessage: 'Member List',
  },
  shareUserList: {
    id: `${scope}.ribbon.share.userList`,
    defaultMessage: 'User List',
  },
  shareSearchPlaceholderUser: {
    id: `${scope}.ribbon.share.search.placeholder.user`,
    defaultMessage: 'Search user',
  },
  shareSearchPlaceholderMember: {
    id: `${scope}.ribbon.share.search.placeholder.member`,
    defaultMessage: 'Search member',
  },
  shareCancel: {
    id: `${scope}.ribbon.share.cancel`,
    defaultMessage: 'Cancel',
  },
  shareShared: {
    id: `${scope}.ribbon.share.shared`,
    defaultMessage: 'Share',
  },
  shareSelectList: {
    id: `${scope}.ribbon.share.selectList`,
    defaultMessage: 'Please select item in grid list',
  },
  shareSelectGrid: {
    id: `${scope}.ribbon.share.selectGrid`,
    defaultMessage: 'Please select item(s) in grid view',
  },
  importCancel: {
    id: `${scope}.ribbon.import.cancel`,
    defaultMessage: 'Cancel',
  },
  importTitle: {
    id: `${scope}.ribbon.import.title`,
    defaultMessage: 'Import Assets',
  },
  importImport: {
    id: `${scope}.ribbon.import.import`,
    defaultMessage: 'Import',
  },
  importTitleSpin: {
    id: `${scope}.ribbon.import.titleSpin`,
    defaultMessage: 'Import...',
  },
  importDefaultStatus: {
    id: `${scope}.ribbon.import.defaultStatus`,
    defaultMessage: 'Default Status',
  },
  importCreateThumbs: {
    id: `${scope}.ribbon.import.createThumbs`,
    defaultMessage: 'Create Thumbs',
  },
  importAutoApplyReplaceOnProduct: {
    id: `${scope}.ribbon.import.autoApplyReplaceOnProduct`,
    defaultMessage: 'Auto Apply/Replace on Product',
  },
  preplaceAssetCancel: {
    id: `${scope}.ribbon.preplaceAsset.cancel`,
    defaultMessage: 'Import',
  },
  preplaceAssetTitle: {
    id: `${scope}.ribbon.preplaceAsset.title`,
    defaultMessage: 'Replace Asset',
  },
  ownerAsset: {
    id: `${scope}.ribbon.ownerAsset`,
    defaultMessage: `My Company's Assets`,
  },
  filters: {
    id: `${scope}.ribbon.filters`,
    defaultMessage: 'Filters',
  },
  packageLevelsFilters: {
    id: `${scope}.ribbon.packageLevelsFilters`,
    defaultMessage: 'Package Levels',
  },
  notSharedAsset: {
    id: `${scope}.ribbon.notSharedAsset`,
    defaultMessage: 'Not Shared',
  },
  statusActiveAsset: {
    id: `${scope}.ribbon.statusActiveAsset`,
    defaultMessage: 'Active',
  },
  statusPendingAsset: {
    id: `${scope}.ribbon.statusPendingAsset`,
    defaultMessage: 'Pending Assets',
  },
  runQuery: {
    id: `${scope}.ribbon.advancedSearch.runQuery`,
    defaultMessage: 'Run Query',
  },
  query: {
    id: `${scope}.ribbon.advancedSearch.query`,
    defaultMessage: 'Query',
  },
  resultsQuery: {
    id: `${scope}.ribbon.advancedSearch.resultsQuery`,
    defaultMessage: 'Results',
  },
  viewQuery: {
    asset: {
      id: `${scope}.ribbon.advancedSearch.viewQueryAsset`,
      defaultMessage: 'Digital Asset',
    },
    product: {
      id: `${scope}.ribbon.advancedSearch.viewQueryProduct`,
      defaultMessage: 'Product',
    },
    member: {
      id: `${scope}.ribbon.advancedSearch.viewQueryMember`,
      defaultMessage: 'Member',
    },
  },
  changeViewQueryConfirm: {
    asset: {
      id: `${scope}.ribbon.advancedSearch.changeViewQueryConfirm.asset`,
      defaultMessage: 'Are you sure to switch Asset?',
    },
    product: {
      id: `${scope}.ribbon.advancedSearch.changeViewQueryConfirm.product`,
      defaultMessage: 'Are you sure to switch Product?',
    },
    member: {
      id: `${scope}.ribbon.advancedSearch.changeViewQueryConfirm.member`,
      defaultMessage: 'Are you sure to switch Member?',
    },
  },
  clearQuery: {
    id: `${scope}.ribbon.advancedSearch.clearQuery`,
    defaultMessage: 'Clear Query Conditions',
  },
  clearColumnsQuery: {
    id: `${scope}.ribbon.advancedSearch.clearColumnsQuery`,
    defaultMessage: 'Clear Data Columns',
  },
  clearReporting: {
    id: `${scope}.ribbon.advancedSearch.clearReporting`,
    defaultMessage: 'Clear',
  },
  saveQuery: {
    id: `${scope}.ribbon.advancedSearch.saveQuery`,
    defaultMessage: 'Save',
  },
  shareQuery: {
    id: `${scope}.ribbon.advancedSearch.shareQuery`,
    defaultMessage: 'Share',
  },
  exportQuery: {
    id: `${scope}.ribbon.advancedSearch.exportQuery`,
    defaultMessage: 'Export',
  },
  openResult: {
    id: `${scope}.ribbon.advancedSearch.openResult`,
    defaultMessage: 'Open',
  },
  messageMemberPermissionsShare: {
    id: `${scope}.ribbon.shareControl.messageMember`,
    defaultMessage: 'Do you want to share selected folder(s)?',
  },
  messageUserPermissionsShare: {
    id: `${scope}.ribbon.shareControl.messageUser`,
    defaultMessage: 'Do you want to share selected folder(s)?',
  },

  // MemberLayout
  overviewLayout: {
    id: `${scope}.navigation.members.overviewLayout`,
    defaultMessage: 'Overview',
  },
  detailsLayout: {
    id: `${scope}.navigation.members.detailsLayout`,
    defaultMessage: 'Details',
  },
  contactsLayout: {
    id: `${scope}.navigation.members.contactsLayout`,
    defaultMessage: 'Contacts',
  },
  billingLayout: {
    id: `${scope}.navigation.members.billingLayout`,
    defaultMessage: 'Billing',
  },
  systemLayout: {
    id: `${scope}.navigation.members.systemLayout`,
    defaultMessage: 'System',
  },
  previewLayout: {
    id: `${scope}.navigation.members.previewLayout`,
    defaultMessage: 'Preview',
  },
  contactControl: {
    id: `${scope}.ribbon.members.contactControl`,
    defaultMessage: 'Contact',
  },
  contactControlTooltip: {
    id: `${scope}.ribbon.members.contactControlTooltip`,
    defaultMessage: 'Show Contact Card',
  },
  sendText: {
    id: `${scope}.ribbon.members.sendText`,
    defaultMessage: 'Send Text',
  },

  officeFacility: {
    id: `${scope}.ribbon.members.officeFacility`,
    defaultMessage: 'Offices',
  },
  showcasedMedia: {
    id: `${scope}.ribbon.members.showcasedMedia`,
    defaultMessage: 'Showcased Media',
  },
  virtualBooth: {
    id: `${scope}.ribbon.members.virtualBooth`,
    defaultMessage: 'Virtual Booth',
  },
  drmSingle: {
    id: `${scope}.ribbon.dialogDeleteDRMsingle`,
    defaultMessage:
      'The Digital Asset you have selected for download is covered under a Digital Rights Restriction. Please ensure that you have reviewed the Digital Rights Restrictions associated with the Digital Asset to confirm with your inteded usage.',
  },
  drmMultiple: {
    id: `${scope}.ribbon.dialogDeleteDRMmultiple`,
    defaultMessage:
      'At least one of the Digital Assets you have selected for download is covered under a Digital Rights Restricstion. Please ensure that you have reviewed the Digital Rights Restricstions associated with the Digital Assets to confirm with your intended usage.',
  },
  advanceFilter: {
    id: `${scope}.ribbon.advanceFilter`,
    defaultMessage: 'Advanced Filter',
  },
  drmSingleShare: {
    id: `${scope}.ribbon.dialogDeleteDRMSingleShare`,
    defaultMessage:
      'The Digital Asset you have selected for sharing is covered under a Digital Rights Restriction. Please ensure that you have reviewed the Digital Rights Restrictions associated with the Digital Asset to confirm your ability to share this Digital Asset with other Members.',
  },
  drmMultipleShare: {
    id: `${scope}.ribbon.dialogDeleteDRMmultipleShare`,
    defaultMessage:
      'At least one of the Digital Assets you have selected for sharing is covered under a Digital Rights Restriction. Please ensure that you have reviewed the Digital Rights Restrictions associated with the Digital Assets to confirm your ability to share these Digital Assets with other Members.',
  },
  queryOpen: {
    id: `${scope}.navigation.queryOpen`,
    defaultMessage: 'Execute Query',
  },
  queryOpenError: {
    id: `${scope}.navigation.queryOpen.error`,
    defaultMessage: 'Cannot execute query',
  },
  configAddToFolder: {
    id: `${scope}.ribbon.gridShareConfig.addToFolder`,
    defaultMessage: 'Add to Folder',
  },
  configShareQuery: {
    id: `${scope}.ribbon.gridShareConfig.shareQuery`,
    defaultMessage: 'Share Query',
  },
  configInputSearchPlaceholder: {
    id: `${scope}.ribbon.gridShareConfig.inputSearchPlaceholder`,
    defaultMessage: 'Type input for searching...',
  },
  configShared: {
    id: `${scope}.ribbon.gridShareConfig.shared`,
    defaultMessage: 'Share...',
  },
  configSubmit: {
    id: `${scope}.ribbon.gridShareConfig.submit`,
    defaultMessage: 'Submit',
  },
  configAddToFolderSpin: {
    id: `${scope}.ribbon.gridShareConfig.addToFolderSpin`,
    defaultMessage: 'Add to Folder...',
  },
  configInputSearchFolderPlaceholder: {
    id: `${scope}.ribbon.gridShareConfig.inputSearchFolderPlaceholder`,
    defaultMessage: 'Search Folder items...',
  },
  configNodata: {
    id: `${scope}.ribbon.gridShareConfig.noData`,
    defaultMessage: 'No data',
  },
  configShareSuccess: {
    id: `${scope}.ribbon.gridShareConfig.shareSuccess`,
    defaultMessage: 'Shared successfully!',
  },
  configFolderSuccess: {
    id: `${scope}.ribbon.gridShareConfig.folderSuccess`,
    defaultMessage: 'Add to folder successfully!',
  },

  // Workflow template
  newStep: {
    id: `${scope}.ribbon.workflow-template.full.newStep`,
    defaultMessage: 'New Step',
  },
  newTask: {
    id: `${scope}.ribbon.workflow-template.full.newTask`,
    defaultMessage: 'New Task',
  },
  cloneStep: {
    id: `${scope}.ribbon.workflow-template.full.cloneStep`,
    defaultMessage: 'Clone Step',
  },
  cloneTask: {
    id: `${scope}.ribbon.workflow-template.full.cloneTask`,
    defaultMessage: 'Clone Task',
  },
  executeWorkflow: {
    id: `${scope}.ribbon.girdView.executeWorkflow`,
    defaultMessage: 'Workflow',
  },
  executeWorkflowSuccess: {
    id: `${scope}.ribbon.girdView.executeWorkflowSuccess`,
    defaultMessage: 'Execute Successfully!',
  },
  newThread: {
    id: `${scope}.createThread.newThread`,
    defaultMessage: 'New Thread',
  },
  settingThread: {
    id: `${scope}.createThread.newThread`,
    defaultMessage: 'Add/remove thread members.',
  },
  newPrivateChat: {
    id: `${scope}.createThread.newThread`,
    defaultMessage: 'New Private Chat',
  },
  addEmail: {
    id: `${scope}.createThread.addEmail`,
    defaultMessage: 'Add Email',
  },
  threadName: {
    id: `${scope}.createThread.threadName`,
    defaultMessage: 'Thread Name',
  },
  emailThreadName: {
    id: `${scope}.createThread.emailThreadName`,
    defaultMessage: 'Email',
  },
  emailThread: {
    id: `${scope}.createThread.emailThread`,
    defaultMessage: 'New Email Thread',
  },
  findContactAddToThread: {
    id: `${scope}.createThread.newThread`,
    defaultMessage: 'Find contacts add to thread',
  },
  findMember: {
    id: `${scope}.createThread.findMember`,
    defaultMessage: 'Find member',
  },
  personalTilte: {
    id: `${scope}.createThread.personal`,
    defaultMessage: 'Personal',
  },
  companyTitle: {
    id: `${scope}.createThread.company`,
    defaultMessage: 'Company',
  },

  downloadTemplate: {
    id: `${scope}.ribbon.pim.downloadTemplate`,
    defaultMessage: 'Download Template',
  },
  uploadTemplate: {
    id: `${scope}.ribbon.pim.uploadTemplate`,
    defaultMessage: 'Upload Template',
  },

  downloadTdcTemplate: {
    id: `${scope}.ribbon.pim.downloadTdcTemplate`,
    defaultMessage: 'Download TDC Template',
  },
  uploadTdcTemplate: {
    id: `${scope}.ribbon.pim.uploadTdcTemplate`,
    defaultMessage: 'Upload TDC Template',
  },

  pimActions: {
    id: `${scope}.ribbon.pim.pimActions`,
    defaultMessage: 'PIM Actions',
  },

  aplActions: {
    id: `${scope}.ribbon.pim.aplActions`,
    defaultMessage: 'APL Actions',
  },

  pimPallet: {
    id: `${scope}.ribbon.pim.pimPallet`,
    defaultMessage: 'Pallet',
  },
  pimMasterCase: {
    id: `${scope}.ribbon.pim.pimMasterCase`,
    defaultMessage: 'Master Case',
  },
  pimCase: {
    id: `${scope}.ribbon.pim.pimCase`,
    defaultMessage: 'Case',
  },
  pimDisplayShipper: {
    id: `${scope}.ribbon.pim.pimDisplayShipper`,
    defaultMessage: 'Display Shipper',
  },
  pimInnerPack: {
    id: `${scope}.ribbon.pim.pimInnerPack`,
    defaultMessage: 'Inner Pack',
  },
  pimUnit: {
    id: `${scope}.ribbon.pim.pimUnit`,
    defaultMessage: 'Unit',
  },
  pimSubscribe: {
    id: `${scope}.ribbon.pim.pimSubscribe`,
    defaultMessage: 'Subscribe',
  },
  pimUnsubscribe: {
    id: `${scope}.ribbon.pim.pimUnsubscribe`,
    defaultMessage: 'Unsubscribe',
  },
  addProduct: {
    id: `${scope}.ribbon.pim.addProduct`,
    defaultMessage: 'Add Product',
  },
  downloadNewItemForm: {
    id: `${scope}.ribbon.pim.downloadNewItemForm`,
    defaultMessage: 'Download New Item Form',
  },
  createProduct: {
    id: `${scope}.ribbon.pim.createProduct`,
    defaultMessage: 'Create Product',
  },
  deleteProduct: {
    id: `${scope}.ribbon.pim.deleteProduct`,
    defaultMessage: 'Delete Product(s)',
  },
  productDetailView: {
    id: `${scope}.ribbon.pim.productDetailView`,
    defaultMessage: 'Detail',
  },
  downloadAplTemplate: {
    id: `${scope}.ribbon.pim.downloadAplTemplate`,
    defaultMessage: 'Download Template for Creating',
  },
  productEdit: {
    id: `${scope}.ribbon.pim.productEditForm`,
    defaultMessage: 'Edit Product',
  },
  productClone: {
    id: `${scope}.ribbon.pim.productCloneForm`,
    defaultMessage: 'Copy Product',
  },
  subscriptionEdit: {
    id: `${scope}.ribbon.subscription.edit`,
    defaultMessage: 'Edit',
  },
  subscriptionUnsubscribe: {
    id: `${scope}.ribbon.subscription.unsubscribe`,
    defaultMessage: 'Unsubscribe',
  },
  unsubscribeConfirmMessage: {
    id: `${scope}.ribbon.subscription.unsubscribeConfirmMessage`,
    defaultMessage: 'Are you sure you will unsubscribe selecting subscription?',
  },
  unsubscribeSuccess: {
    id: `${scope}.ribbon.subscription.unsubscribeSuccess`,
    defaultMessage: 'Unsubscribe successfully',
  },
  unsubscribeError: {
    id: `${scope}.ribbon.subscription.unsubscribeConfirmMessage`,
    defaultMessage: 'Cannot unsubscribe',
  },
  subscribeMember: {
    id: `${scope}.ribbon.member.subscribeMember`,
    defaultMessage: 'Subscribe Member',
  },
  subscribeProduct: {
    id: `${scope}.ribbon.pim.subscribeProduct`,
    defaultMessage: 'Subscribe Product',
  },
  pickupSyndication: {
    id: `${scope}.ribbon.pim.pickupSyndication`,
    defaultMessage: 'Pickup syndication method:',
  },
  mappingSection: {
    id: `${scope}.ribbon.pim.mappingSection`,
    defaultMessage: 'Mapping section:',
  },
  alertSynd: {
    id: `${scope}.ribbon.pim.alertSynd`,
    defaultMessage: 'Syndication: Alert',
  },
  GDSNSynd: {
    id: `${scope}.ribbon.pim.GDSNSynd`,
    defaultMessage: 'Syndication: GDSN',
  },
  alertSyndDescription: {
    id: `${scope}.ribbon.pim.alertSyndDescription`,
    defaultMessage:
      ' RIVIR Alert will help subscribers receive notification every time product goes to ACTIVE status or any update ACTIVE product’s data.',
  },
  GDSNSyndDescription: {
    id: `${scope}.ribbon.pim.GDSNSyndDescription`,
    defaultMessage:
      'GDSN makes it possible for any company, in any market, to share high-quality product information seamlessly. Because companies of all sizes need the same thing—timely and reliable product information—to ultimately benefit consumers and patients.',
  },
  subscribeSuccess: {
    id: `${scope}.ribbon.pim.subscribeSuccess`,
    defaultMessage: 'Subscribe successfully',
  },
  subscribeError: {
    id: `${scope}.ribbon.pim.subscribeSuccess`,
    defaultMessage: 'Subscribe failed',
  },
  editSubscriptionSuccess: {
    id: `${scope}.ribbon.pim.editSubscriptionSuccess`,
    defaultMessage: 'Edit subscription successfully',
  },
  editSubscriptionError: {
    id: `${scope}.ribbon.pim.editSubscriptionError`,
    defaultMessage: 'Cannot edit subscription',
  },
  acceptSubscription: {
    id: `${scope}.ribbon.pim.acceptSubscription`,
    defaultMessage: 'Accept',
  },
  rejectSubscription: {
    id: `${scope}.ribbon.pim.rejectSubscription`,
    defaultMessage: 'Reject',
  },
  reviewSubscription: {
    id: `${scope}.ribbon.pim.reviewSubscription`,
    defaultMessage: 'Review',
  },
  revokeSubscription: {
    id: `${scope}.ribbon.pim.revokeSubscription`,
    defaultMessage: 'Revoke',
  },
  dashboardLayout: {
    id: `${scope}.ribbon.dashboard.layout`,
    defaultMessage: 'Layout',
  },
  dashboardLayoutReset: {
    id: `${scope}.ribbon.dashboard.reset`,
    defaultMessage: 'Reset',
  },
  dashboardLayoutOk: {
    id: `${scope}.ribbon.dashboard.ok`,
    defaultMessage: 'Yes',
  },
  dashboardLayoutCancel: {
    id: `${scope}.ribbon.dashboard.cancel`,
    defaultMessage: 'No',
  },
  dashboardSaveConfirmMessage: {
    id: `${scope}.ribbon.dashboard.message.confirm`,
    defaultMessage: 'Are you sure you want to apply current layout?',
  },
  dashboardResetConfirmMessage: {
    id: `${scope}.ribbon.dashboard.message.confirmReset`,
    defaultMessage: 'Are you sure you want to reset layout to default?',
  },
  mappingCreate: {
    id: `${scope}.navigation.mappingCreate`,
    defaultMessage: 'Create Mapping',
  },
  deleteMapping: {
    id: `${scope}.navigation.mappingDelete`,
    defaultMessage: 'Delete',
  },
  mappingOpen: {
    id: `${scope}.navigation.mappingOpen`,
    defaultMessage: 'Open Mapping',
  },
  mappingClone: {
    id: `${scope}.navigation.mappingClone`,
    defaultMessage: 'Clone',
  },
  mappingCloneProperty: {
    id: `${scope}.navigation.mappingCloneProperty`,
    defaultMessage: 'Clone Mapping Property',
  },
  mappingCloneFieldName: {
    id: `${scope}.navigation.mappingCloneFieldName`,
    defaultMessage: 'Field Name',
  },
  mappingConfigCloneSuccess: {
    id: `${scope}.navigation.mappingConfigCloneSuccess`,
    defaultMessage: 'Clone mapping config successfully',
  },
  mappingConfigCloneError: {
    id: `${scope}.navigation.mappingConfigCloneError`,
    defaultMessage: 'Cannot clone mapping config',
  },
  public: {
    id: `${scope}.navigation.mappingConfigCloneError`,
    defaultMessage: 'Cannot clone mapping config',
  },
  publicationSetup: {
    id: `${scope}.navigation.publicationSetup`,
    defaultMessage: 'Publication Setup',
  },
  publicationSetupModal: {
    id: `${scope}.navigation.publicationSetupModal`,
    defaultMessage: 'Product Publication Setup',
  },
  GDSNPublicationSetup: {
    id: `${scope}.navigation.GDSNPublicationSetup`,
    defaultMessage: 'GDSN Publish Product',
  },
  acceptPublicationConfirm: {
    id: `${scope}.navigation.acceptPublicationConfirm`,
    defaultMessage: 'Are you sure you want to accept?',
  },
  acceptPublicationConfirmYes: {
    id: `${scope}.navigation.acceptPublicationConfirmYes`,
    defaultMessage: 'Yes',
  },
  acceptPublicationConfirmNo: {
    id: `${scope}.navigation.acceptPublicationConfirmNo`,
    defaultMessage: 'No',
  },
  acceptPublicationSuccess: {
    id: `${scope}.navigation.acceptPublicationSuccess`,
    defaultMessage: 'Accept successfully',
  },
  acceptPublicationError: {
    id: `${scope}.navigation.acceptPublicationError`,
    defaultMessage: 'Cannot accept',
  },
  rejectPublicationConfirm: {
    id: `${scope}.navigation.rejectPublicationConfirm`,
    defaultMessage: 'Are you sure you want to reject?',
  },
  rejectPublicationConfirmYes: {
    id: `${scope}.navigation.rejectPublicationConfirmYes`,
    defaultMessage: 'Yes',
  },
  rejectPublicationConfirmNo: {
    id: `${scope}.navigation.rejectPublicationConfirmNo`,
    defaultMessage: 'No',
  },
  rejectPublicationSuccess: {
    id: `${scope}.navigation.rejectPublicationSuccess`,
    defaultMessage: 'Reject successfully',
  },
  rejectPublicationError: {
    id: `${scope}.navigation.rejectPublicationError`,
    defaultMessage: 'Cannot reject',
  },

  rejectGDSNPublication: {
    id: `${scope}.navigation.rejectGDSNPublication`,
    defaultMessage: 'Reject GDSN Publication',
  },

  reviewGDSNPublication: {
    id: `${scope}.navigation.reviewGDSNPublication`,
    defaultMessage: 'Review GDSN Publication',
  },

  reviewPublicationSuccess: {
    id: `${scope}.navigation.reviewPublicationSuccess`,
    defaultMessage: 'Review successfully',
  },
  reviewPublicationError: {
    id: `${scope}.navigation.reviewPublicationError`,
    defaultMessage: 'Review Error',
  },
  downloadNewItem: {
    FRESH_THYME: {
      id: `${scope}.ribbon.pim.downloadNewItem.FRESH_THYME`,
      defaultMessage: 'Fresh Thyme',
    },
    GFS: {
      id: `${scope}.ribbon.pim.downloadNewItem.GFS`,
      defaultMessage: 'GFS',
    },
    UNFI: {
      id: `${scope}.ribbon.pim.downloadNewItem.UNFI`,
      defaultMessage: 'UNFI',
    },
    UNFI_RFI: {
      id: `${scope}.ribbon.pim.downloadNewItem.UNFI_RFI`,
      defaultMessage: 'UNFI - RFI',
    },
    CANTEEN_FOOD_BUY: {
      id: `${scope}.ribbon.pim.downloadNewItem.CANTEEN_FOOD_BUY`,
      defaultMessage: 'Canteen / Food Buy',
    },
    DPI: {
      id: `${scope}.ribbon.pim.downloadNewItem.DPI`,
      defaultMessage: 'DPI',
    },
    error: {
      id: `${scope}.ribbon.pim.downloadNewItem.error`,
      defaultMessage: 'Cannot download new item form',
    },
  },
  ssoApprovalNav: {
    id: `${scope}.navigation.ssoApproval`,
    defaultMessage: 'SSO Management',
  },
  ssoApprovalUserNav: {
    id: `${scope}.navigation.ssoApproval.user`,
    defaultMessage: 'User Approval',
  },
  ssoApprovalMemberNav: {
    id: `${scope}.navigation.ssoApproval.member`,
    defaultMessage: 'Member Approval',
  },
  ssoApprovalSupplierNav: {
    id: `${scope}.navigation.ssoApproval.supplier`,
    defaultMessage: 'Supplier Approval',
  },
  ssoApprovalAcceptConfirm: {
    member: {
      id: `${scope}.ribbon.ssoApproval.message.accept.confirm.member`,
      defaultMessage: 'Are you sure you want to accept the selected company?',
    },
    user: {
      id: `${scope}.ribbon.ssoApproval.message.accept.confirm.user`,
      defaultMessage: 'Are you sure you want to accept the selected user?',
    },
  },
  ssoApprovalRejectConfirm: {
    member: {
      id: `${scope}.ribbon.ssoApproval.message.reject.confirm.member`,
      defaultMessage: 'Are you sure you want to reject the selected company?',
    },
    user: {
      id: `${scope}.ribbon.ssoApproval.message.reject.confirm.user`,
      defaultMessage: 'Are you sure you want to reject the selected user?',
    },
  },
  ssoApprovalConfirmYes: {
    id: `${scope}.ribbon.ssoApproval.message.confirm.yes`,
    defaultMessage: 'Yes',
  },
  ssoApprovalConfirmNo: {
    id: `${scope}.ribbon.ssoApproval.message.confirm.no`,
    defaultMessage: 'No',
  },
  ssoApprovalAcceptSuccess: {
    member: {
      id: `${scope}.ribbon.ssoApproval.message.accept.success.member`,
      defaultMessage: 'Member {name} has been approved successfully',
    },
    user: {
      id: `${scope}.ribbon.ssoApproval.message.accept.success.user`,
      defaultMessage: 'User has been approved successfully',
    },
  },
  ssoApprovalAcceptError: {
    id: `${scope}.ribbon.ssoApproval.message.accept.error`,
    defaultMessage: 'Cannot approve',
  },
  ssoApprovalRejectSuccess: {
    member: {
      id: `${scope}.ribbon.ssoApproval.message.reject.success.member`,
      defaultMessage: 'Member {name} has been rejected successfully',
    },
    user: {
      id: `${scope}.ribbon.ssoApproval.message.reject.success.user`,
      defaultMessage: 'User has been rejected successfully',
    },
  },
  ssoApprovalRejectError: {
    id: `${scope}.ribbon.ssoApproval.message.reject.error`,
    defaultMessage: 'Cannot reject',
  },
  approveSso: {
    id: `${scope}.ribbon.ssoApproval.btn.approve`,
    defaultMessage: 'Approve',
  },
  assignMember: {
    id: `${scope}.ribbon.ssoApproval.btn.assignMember`,
    defaultMessage: 'Assign Member',
  },
  assignUser: {
    id: `${scope}.ribbon.ssoApproval.btn.assignMember`,
    defaultMessage: 'Assign User',
  },
  assignSupplier: {
    id: `${scope}.ribbon.ssoApproval.btn.assignSupplier`,
    defaultMessage: 'Assign Supplier',
  },
  rejectSso: {
    id: `${scope}.ribbon.ssoApproval.btn.reject`,
    defaultMessage: 'Reject',
  },
  reasonPlaceholder: {
    id: `${scope}.ribbon.ssoApproval.rejectReason.placeholder`,
    defaultMessage: 'Please input reject reason',
  },
  reasonRequired: {
    id: `${scope}.ribbon.ssoApproval.rejectReason.reasonRequired`,
    defaultMessage: 'This field is required',
  },
  goToEditMember: {
    id: `${scope}.ribbon.member.goToEdit`,
    defaultMessage: 'Go to Edit',
  },
  saveTransitionPage: {
    id: `${scope}.ribbon.transitionPage.save`,
    defaultMessage: 'Save',
  },
  minimumRequirements: {
    id: `${scope}.navigation.minimumRequirements`,
    defaultMessage: 'Minimum Requirements',
  },
  downloadSplashFormItem: {
    id: `${scope}.ribbon.actionProduct.downloadSplashFormItem`,
    defaultMessage: 'Download SPLASH Form',
  },
  previewRequirements: {
    id: `${scope}.ribbon.previewRequirements`,
    defaultMessage: 'Preview Requirements',
  },
  previewRequirementsModalTitle: {
    Product: {
      id: `${scope}.ribbon.validateMinimumRequirement.modalTitle.product`,
      defaultMessage: 'Validate Product',
    },
    Asset: {
      id: `${scope}.ribbon.validateMinimumRequirement.modalTitle.asset`,
      defaultMessage: 'Validate Asset',
    },
    Member: {
      id: `${scope}.ribbon.validateMinimumRequirement.modalTitle.member`,
      defaultMessage: 'Validate Member',
    },
  },
  previewRequirementsValidateButton: {
    id: `${scope}.ribbon.previewRequirements.validateButton`,
    defaultMessage: 'Validate',
  },
  previewRequirementsSubmitButton: {
    id: `${scope}.ribbon.previewRequirements.submitButton`,
    defaultMessage: 'Submit',
  },
  previewRequirementsCancelButton: {
    id: `${scope}.ribbon.previewRequirements.cancelButton`,
    defaultMessage: 'Cancel',
  },
  previewRequirementsOpenButton: {
    id: `${scope}.ribbon.previewRequirements.openButton`,
    defaultMessage: 'Click To Update',
  },
  validateMinimumRequirementsSuccessMessage: {
    id: `${scope}.ribbon.previewRequirements.validate.success`,
    defaultMessage: 'Validate minimum requirement successfully',
  },
  validateMinimumRequirementsErrorMessage: {
    id: `${scope}.ribbon.previewRequirements.validate.error`,
    defaultMessage: 'Cannot validate minimum requirement',
  },
  updateUnmatchedRequirementSuccess: {
    id: `${scope}.ribbon.previewRequirements.update.success`,
    defaultMessage: 'Update Successfully',
  },
  updateUnmatchedRequirementError: {
    id: `${scope}.ribbon.previewRequirements.update.error`,
    defaultMessage: 'Cannot Update',
  },
  unmatched: {
    id: `${scope}.navigation.asset.unmatched`,
    defaultMessage: 'Unmatched Assets',
  },
  unmatchedProducts: {
    id: `${scope}.navigation.product.unmatchedProducts`,
    defaultMessage: 'Unmatched Products',
  },
  productCategory: {
    id: `${scope}.navigation.product.category`,
    defaultMessage: 'Product Category',
  },
  unmatchedMembers: {
    id: `${scope}.navigation.members.unmatchedMembers`,
    defaultMessage: 'Unmatched Members',
  },
  memActions: {
    id: `${scope}.ribbon.pim.memActions`,
    defaultMessage: 'MEM Actions',
  },
  MemberDocumentRemoveModalTextButton: {
    id: `${scope}.members.components.tabs.overview.documents.modal.textButton`,
    defaultMessage: 'Remove From Member Document',
  },
  MemberDocumentRemoveModalTitle: {
    id: `${scope}.members.components.tabs.overview.documents.modal.title`,
    defaultMessage: 'Choose to Remove or Delete',
  },
  MemberDocumentRemoveModalConfirmMessage: {
    id: `${scope}.members.components.tabs.overview.documents.modal.confirmMessage`,
    defaultMessage:
      'Do you want to remove from showcase or delete selected items?',
  },
  allRequests: {
    id: `${scope}.ribbon.ssoApproval.allRequests`,
    defaultMessage: 'Show All Requests',
  },
  pendingRequests: {
    id: `${scope}.ribbon.ssoApproval.pendingRequests`,
    defaultMessage: 'Show Pending Requests',
  },
  impersonateMemberActiveSuccess: {
    id: `${scope}.impersonate.member.active.success`,
    defaultMessage: 'Active member successfully!',
  },
  impersonateMemberActiveError: {
    id: `${scope}.impersonate.member.active.error`,
    defaultMessage: 'Active member failed!',
  },
  impersonateUserActiveSuccess: {
    id: `${scope}.impersonate.user.active.success`,
    defaultMessage: 'Active user successfully!',
  },
  impersonateUserActiveError: {
    id: `${scope}.impersonate.user.active.error`,
    defaultMessage: 'Active user failed!',
  },
  impersonateMemberStartConfirm: {
    id: `${scope}.impersonate.member.start.success`,
    defaultMessage: 'Are you sure you want to impersonate member {name}?',
  },
  impersonateUserStartConfirm: {
    id: `${scope}.impersonate.user.start.success`,
    defaultMessage: 'Are you sure you want to impersonate user {name}?',
  },
  impersonateMessageError: {
    id: `${scope}.impersonate.message.error`,
    defaultMessage: 'Can not load data!',
  },
  impersonateInfo: {
    member: {
      id: `${scope}.impersonate.impersonateInfo.member`,
      defaultMessage: 'Member:',
    },
    name: {
      id: `${scope}.impersonate.impersonateInfo.name`,
      defaultMessage: 'Member:',
    },
    user: {
      id: `${scope}.impersonate.impersonateInfo.user`,
      defaultMessage: 'User:',
    },
    email: {
      id: `${scope}.impersonate.impersonateInfo.email`,
      defaultMessage: 'Email:',
    },
    startAt: {
      id: `${scope}.impersonate.impersonateInfo.startAt`,
      defaultMessage: 'Session start at: ',
    },
    closeButton: {
      id: `${scope}.impersonate.impersonateInfo.closeButton`,
      defaultMessage: 'Close Impersonate',
    },
  },
  confirmRemoveBroker: {
    id: `${scope}.broker.message.removeBroker`,
    defaultMessage: 'Are you sure you want to delete this broker setting?',
  },
  confirmRemoveSupplier: {
    id: `${scope}.supplier.message.confirmRemoveSupplier`,
    defaultMessage: 'Are you sure you want to delete this supplier setting?',
  },
  deleteBrokerSuccessfully: {
    id: `${scope}.broker.message.removeBrokerSuccessfully`,
    defaultMessage: 'Delete broker setting successfully!',
  },
  removeSupplierSuccessfully: {
    id: `${scope}.supplier.message.removeSupplierSuccessfully`,
    defaultMessage: 'Delete supplier setting successfully!',
  },
  deleteBrokerError: {
    id: `${scope}.broker.message.removeBrokerError`,
    defaultMessage: 'Failed to delete broker setting',
  },
  deleteSupplierError: {
    id: `${scope}.supplier.message.removeSupplierError`,
    defaultMessage: 'Failed to delete supplier setting!',
  },
  downloadAssetsTemplate: {
    id: `${scope}.navigation.members.downloadAssetsTemplate`,
    defaultMessage: 'Download Assets Template',
  },
  uploadAssetsTemplate: {
    id: `${scope}.navigation.members.uploadAssetsTemplate`,
    defaultMessage: 'Upload Assets Template',
  },
  downloadAssetsTemplateMsgSuccess: {
    id: `${scope}.component.members.downloadAssetsTemplateMsg.success`,
    defaultMessage: 'Download assets template successfully.',
  },
  downloadAssetsTemplateMsgFailed: {
    id: `${scope}.component.members.downloadAssetsTemplateMsg.failed`,
    defaultMessage: 'Download assets template failed.',
  },
  uploadAssetsTemplateMsgSuccess: {
    id: `${scope}.component.members.uploadAssetsTemplateMsg.success`,
    defaultMessage: 'Upload assets template successfully.',
  },
  uploadAssetsTemplateMsgFailed: {
    id: `${scope}.component.members.uploadAssetsTemplateMsg.failed`,
    defaultMessage: 'Upload assets template failed.',
  },
  sharingAssetFailedMessage: {
    id: `${scope}.component.asset.sharingAssetMessage.failed`,
    defaultMessage: 'Fail to Shared.',
  },
  deleteQueryConfirm: {
    id: `${scope}.component.manageQuery.delete.confirmMessage`,
    defaultMessage: 'Are you sure you want to delete this query?',
  },
  detailQaSpec: {
    id: `${scope}.ribbon.pim.detailQaSpec`,
    defaultMessage: 'QA Specification',
  },
  initWorkflow: {
    id: `${scope}.ribbon.pim.initWorkflow`,
    defaultMessage: 'Initiate QA Specification Workflow',
  },
  saveQaSpecSuccess: {
    id: `${scope}.pim.QaSpecHeader.message.success`,
    defaultMessage: 'Update Header data successfully!',
  },
  saveQaSpecFailed: {
    id: `${scope}.pim.QaSpecHeader.message.failed`,
    defaultMessage: 'Update header data failed',
  },
  qaSpecType: {
    id: `${scope}.pim.QaSpecHeader.label.type`,
    defaultMessage: 'Type',
  },
  qaSpecNationalBrandTarget: {
    id: `${scope}.pim.QaSpecHeader.label.nationalBrandTarget`,
    defaultMessage: 'National Brand Target',
  },
  qaSpecSKUType: {
    id: `${scope}.pim.QaSpecHeader.label.skuType`,
    defaultMessage: 'SKU Type',
  },
  qaSpecNationalBrandTargetUpc: {
    id: `${scope}.pim.QaSpecHeader.label.nationalBrandTargetUpc`,
    defaultMessage: 'National Brand Target UPC',
  },
  qaSpecRetailBanner: {
    id: `${scope}.pim.QaSpecHeader.label.retailBanner`,
    defaultMessage: 'Retail Banner',
  },
  qaSpecSupplierLocation: {
    id: `${scope}.pim.QaSpecHeader.label.supplierLocation`,
    defaultMessage: 'Supplier Location',
  },
  qaSpecCategory1: {
    id: `${scope}.pim.QaSpecHeader.label.qaSpecCategory1`,
    defaultMessage: 'Category I',
  },
  qaSpecCategory2: {
    id: `${scope}.pim.QaSpecHeader.label.qaSpecCategory2`,
    defaultMessage: 'Category II',
  },
  qaSpecCategory3: {
    id: `${scope}.pim.QaSpecHeader.label.qaSpecCategory3`,
    defaultMessage: 'Category III',
  },
  qaSpecCategory4: {
    id: `${scope}.pim.QaSpecHeader.label.qaSpecCategory4`,
    defaultMessage: 'Category IV',
  },
  qaSpecApplicableReferenceStandard: {
    id: `${scope}.pim.QaSpecHeader.label.applicableReferenceStandard`,
    defaultMessage: 'Applicable Reference Standard',
  },
  qaSpecSmartLabelTemplate: {
    id: `${scope}.pim.QaSpecHeader.label.qaSpecSmartLabelTemplate`,
    defaultMessage: 'SmartLabel Template',
  },
  creditCardEulaTitle: {
    id: `${scope}.component.creditCard.eulaModal.title`,
    defaultMessage: 'Credit Card Terms & Conditions',
  },
  creditCardEulaRejectBtn: {
    id: `${scope}.component.creditCard.eulaModal.button.reject`,
    defaultMessage: 'Reject',
  },
  creditCardEulaAcceptBtn: {
    id: `${scope}.component.creditCard.eulaModal.button.accept`,
    defaultMessage: 'Agree',
  },
  creditCardEulaAcceptError: {
    id: `${scope}.component.creditCard.eulaModal.message.accept.error`,
    defaultMessage: 'Cannot accept',
  },
  creditCardEulaRejectConfirmTitle: {
    id: `${scope}.component.creditCard.eulaModal.title.reject.confirm`,
    defaultMessage: ' You rejected Credit Card Terms & Conditions',
  },
  creditCardEulaRejectConfirmMessage: {
    id: `${scope}.component.creditCard.eulaModal.message.reject.confirm`,
    defaultMessage:
      'You must agree to the Credit Card Processing Terms and Conditions before adding or changing a credit card.',
  },
  dataSyncTimeTracking: {
    id: `${scope}.navigation.dataSyncTimeTracking`,
    defaultMessage: 'Data Sync Time Tracking',
  },
  qcTimeTracking: {
    id: `${scope}.navigation.qcTimeTracking`,
    defaultMessage: 'QC Time Tracking',
  },
  assignCategoryToProductTitle: {
    id: `${scope}.ribbon.assignCategoryToProduct.title.product`,
    defaultMessage: 'Assign Category To Product(s)',
  },
  assignCategoryToProduct: {
    id: `${scope}.ribbon.assignCategoryToProduct.modalTitle.product`,
    defaultMessage: 'Assign Category To Product',
  },
  assignedProductTitle: {
    id: `${scope}.components.assignCategoryToProduct.title.assignedProduct`,
    defaultMessage: 'Selected Products',
  },
  assignCategoryToProductSuccess: {
    id: `${scope}.components.assignCategoryToProduct.message.assignCategoryToProductSuccess`,
    defaultMessage: 'Assign Category To Product Successfully!',
  },
  assignCategoryToProductFail: {
    id: `${scope}.components.assignCategoryToProduct.message.assignCategoryToProductFail`,
    defaultMessage: 'Assign Category To Product Failed!',
  },
  assignCategoryToProductText: {
    id: `${scope}.components.assignCategoryToProduct.text.assignCategoryToProductText`,
    defaultMessage: 'Assign',
  },
  confirmOverwriteCategoryProductGrid: {
    id: `${scope}.components.assignCategoryToProduct.text.confirmOverwriteCategoryProductGrid`,
    defaultMessage:
      'Products have been assigned to a category, are you sure you want to re-assign products to the new category?',
  },
  confirmOverwriteCategoryProductDetail: {
    id: `${scope}.components.assignCategoryToProduct.text.confirmOverwriteCategoryProductDetail`,
    defaultMessage:
      'Products have been assigned to a category, are you sure you want to re-assign product to the new category?',
  },
  downloadSuccessMessage: {
    id: `${scope}.components.productAPL.success.message`,
    defaultMessage: 'Download product APL successfully!',
  },
  downloadFailMessage: {
    id: `${scope}.components.productAPL.fail.message`,
    defaultMessage: 'Download product APL fail!',
  },
  downloadAPLTitle: {
    id: `${scope}.components.productAPL.ribbon.title`,
    defaultMessage: 'Download Product APL',
  },
  mfaVerificationLog: {
    id: `${scope}.navigation.mfaVerificationLog`,
    defaultMessage: 'MFA Verification Log',
  },
  ticketingSystem: {
    id: `${scope}.navigation.ticketingSystem`,
    defaultMessage: 'Ticketing System',
  },
  openTiketingSystem: {
    id: `${scope}.ticketingSystem.openTiketingSystem`,
    defaultMessage: 'Open',
  },
  createTiketingSystem: {
    id: `${scope}.ticketingSystem.createTiketingSystem`,
    defaultMessage: 'Create',
  },
  deleteTiketingSystem: {
    id: `${scope}.ticketingSystem.deleteTiketingSystem`,
    defaultMessage: 'Delete',
  },
  filterTiketingSystem: {
    id: `${scope}.ticketingSystem.filterTiketingSystem`,
    defaultMessage: 'Filter',
  },
  ticketingGroup: {
    id: `${scope}.navigation.ticketingGroup`,
    defaultMessage: 'Ticket Group Assignment',
  },
  createTiketingGroup: {
    id: `${scope}.navigation.createTiketingGroup`,
    defaultMessage: 'Create',
  },
  editTiketingGroup: {
    id: `${scope}.navigation.editTiketingGroup`,
    defaultMessage: 'Edit',
  },
  deleteTiketingGroup: {
    id: `${scope}.navigation.deleteTiketingGroup`,
    defaultMessage: 'Delete',
  },
  withdrawPublicationModalTitle: {
    id: `${scope}.withdrawPublication.modalTitle`,
    defaultMessage: 'Withdraw Publication',
  },
  getPublicationErrorMessage: {
    id: `${scope}.withdrawPublication.getPublicationErrorMessage`,
    defaultMessage: 'Cannot get publications',
  },
  productPackaging: {
    id: `${scope}.withdrawPublication.productPackaging`,
    defaultMessage: 'Labeling',
  },
  usePgnImage: {
    id: `${scope}.withdrawPublication.usePgnImage`,
    defaultMessage: ' Use PNG format for product image',
  },
  customImageFormatLabel: {
    id: `${scope}.withdrawPublication.label.customImageFormat`,
    defaultMessage: 'File Format',
  },
  customImageFormatPlaceholder: {
    id: `${scope}.withdrawPublication.placeholder.customImageFormat`,
    defaultMessage: 'Select Custom Image Format',
  },
  qasRequireField: {
    id: `${scope}.navigation.qasRequireField`,
    defaultMessage: ' QAS Required Fields',
  },
});
