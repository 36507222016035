import React, { useState, useMemo, useRef, useEffect } from 'react';

import { Row, Col, Divider, Empty, Typography } from 'antd';

import { CustomNotification, WithLoading } from 'common/components';

import {
  ProductPrimaryInfo,
  ProductOverviewMetadata,
} from 'pages/product-full-view/components';

import QueryProperties, { SearchProductProperties } from './QueryProperties';
import ProductDetailEdit from './ProductDetailEdit';
import ProductBaseModules from './ProductBaseModules';
import ProductAdvancedModules from './ProductAdvancedModules';
import PackageContent from './PackageContent';
import ProductCustomProperties from './ProductCustomProperties';

import {
  ModalEditForm,
  GroupElementWrapper,
  HeaderGroupElement,
  ErrorMessageProperty,
} from '../../shared/components';

import {
  useProductDetail,
  useProductDetailDispatch,
  productDetailActionTypes,
} from './ProductDetailContext';

import {
  useGetDataProductItemModules,
  useGetProductItemModulesBrickCode,
  useScrollToErrorModule,
  useVisibleModal,
} from 'pages/product-full-view/shared/hooks';

import { useGetProductEnums, useGetProductFullView } from 'hooks';

import ProductHeaderImage from 'common/components/product-header-image/ProductHeaderImage';
import ProductDetailGridItemContainer from './layout/ProductDetailGridItemContainer';
import WithProductDetailExpandBtnfrom, {
  COMPONENT_TYPE,
} from './layout/WithProductDetailExpandBtn';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';

import {
  filterModuleProductSchema,
  filterModuleBrickCode,
  generateModuleProductToTree,
  uppercaseFirstLetter,
} from 'pages/product-full-view/shared/utils';

import {
  getFormValues,
  lowercaseProductData,
  groupProductModuleProperties,
  filterParamFormValues,
  getNearestFieldPath,
  generateFormValuesForPublishing,
  getIdxNameFormOfProperty,
  getPropertyByFieldFullPath,
  getNearestFieldFullPathError,
  checkTypeOfFormNested,
  validateForm,
  checkFormHasError,
  checkIsAllergenModuleName,
  mappingDataProductByFieldFullPath,
  findFieldFullPathFormData,
} from './utils';

import { sleep } from 'utils/delay';
import classnames from 'classnames';
import reactCSS from 'reactcss';
import { useTabExpandProductDetail } from 'hooks/useTabExpandProduct';

import * as productDetailServices from 'services/product';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useSyncProductModules } from './hooks';

import './ProductDetail.less';

const DELAY_TIME_ERROR_FORM = 1000;

const ProductDetailView = (props) => {
  const { product, productSchema, onResolveErrorPublicationSuccess } = props;
  const { productId, brickCode, ownerId } = product;

  const refCurrentFormError = useRef([]);
  const ixoneChangedFieldRef = useRef({});

  const {
    formInstance,
    baseModule: {
      isExpandAll,
      isShowOnlyValue: isShowOnlyValueBase,
      searchText: baseSearchText,
    },
    advancedModule: { searchText: advancedSearchText, isFullView },
    publicationError: { fieldFullPathError, moduleNameError },
  } = useProductDetail();

  const dispatch = useProductDetailDispatch();

  const [visibleEditModal, { onModal, offModal }] = useVisibleModal();

  const [visiblePackageContent, setVisiblePackageContent] = useState(false);
  const [editedModuleProperties, setEditModuleProperties] = useState({});

  const [statusSubmit, setStatusSubmit] = useState('idle');
  const [statusValidate, setStatusValidate] = useState('idle');

  const { productEnums } = useGetProductEnums();
  const reloadPage = useDispatchReloadPage();
  const { modulesBrickCode } = useGetProductItemModulesBrickCode(brickCode, {
    enabled: !!brickCode,
  });

  const { handleRefetchProductFullView } = useGetProductFullView({
    productId,
  });

  const {
    dataProductModules,
    statusFetchProductModules,
    refetchDataProductModules,
  } = useGetDataProductItemModules(productId, true);

  const { handleSyncQaSpec, handleSyncAllergens, handleSyncIngredients } =
    useSyncProductModules();

  useScrollToErrorModule({
    statusFetchProductModules,
    moduleNameError,
  });

  const { baseProductSchema, advancedProductSchema } = useMemo(
    () => filterModuleProductSchema(productSchema),
    [productSchema]
  );

  const basePropertiesModuleNames = baseProductSchema.map((schema) =>
    schema.moduleName.toLowerCase()
  );

  const isEditBasedModule = basePropertiesModuleNames.includes(
    editedModuleProperties?.moduleName?.toLowerCase()
  );

  const brickCodeSchema = useMemo(
    () => filterModuleBrickCode(advancedProductSchema, modulesBrickCode),
    [modulesBrickCode, advancedProductSchema]
  );

  const handleClickEditBtn = async (moduleName) => {
    onModal();

    const foundModuleProperties = productSchema.find(
      (schema) => schema.moduleName === moduleName
    );

    const isEditBasedModule = basePropertiesModuleNames.includes(
      foundModuleProperties?.moduleName?.toLowerCase()
    );

    setEditModuleProperties(
      groupProductModuleProperties(foundModuleProperties)
    );

    const foundDataProductModule = dataProductModules?.productItemModules?.find(
      (item) => item.moduleName === moduleName
    );

    const formValues = getFormValues(
      lowercaseProductData(foundDataProductModule?.data),
      foundModuleProperties?.moduleProperties
    );

    const fieldFullPathMapping = mappingDataProductByFieldFullPath(
      lowercaseProductData(formValues),
      foundModuleProperties?.moduleProperties
    );

    const searchText = isEditBasedModule ? baseSearchText : advancedSearchText;

    const foundFieldFullPathFormData = findFieldFullPathFormData(
      searchText,
      fieldFullPathMapping
    );

    if (
      moduleName?.toLowerCase() !== searchText?.split('.')[0]?.toLowerCase()
    ) {
      dispatch({
        type: productDetailActionTypes.CLEAR_SEARCH_PROPERTIES,
      });
      dispatch({
        type: productDetailActionTypes.CLEAR_SEARCH_BASE_PROPERTIES,
      });
    }

    dispatch({
      type: productDetailActionTypes.UPDATE_FOUND_FIELD_FULL_PATH,
      payload: {
        isEditBasedModule,
        foundFieldFullPath: foundFieldFullPathFormData,
      },
    });

    // Check error on publication
    if (
      fieldFullPathError &&
      moduleNameError.toLowerCase() === moduleName.toLowerCase()
    ) {
      handleValidatePublication(formValues, foundModuleProperties);
    } else {
      formInstance.setFieldsValue(formValues);
    }
  };

  // Check has error publication -> fieldFullPath
  // STEP 1: Set formValues
  // STEP 1.1: Check current value of form -> what is type of field name (isSingleNestedValue, isMultipleNestedValue, ...)
  // STEP 1.2: Set value to form

  // STEP2: Set errorField
  // Render error field after setting value form. Maybe use setTimeout for better UI?

  const handleValidatePublication = async (
    formValues,
    foundModuleProperties
  ) => {
    const isBaseProperty = basePropertiesModuleNames.includes(
      moduleNameError.toLowerCase()
    );

    const errorPropertyPaths = fieldFullPathError
      .split('.')
      .slice(1)
      .map((path) => uppercaseFirstLetter(path));

    const { moduleProperties } = foundModuleProperties;
    const errorProperty = getPropertyByFieldFullPath(
      moduleProperties,
      fieldFullPathError
    );

    const { isSingleNestedValue } = checkTypeOfFormNested(
      errorProperty?.childProperties,
      errorProperty?.isArray
    );

    const formatErrorPublication = `[Publish Product Error] - ${errorProperty?.propertyDisplayName} cannot be null.`;

    setStatusValidate('validating');

    // Not nested
    if (isBaseProperty) {
      const errorProperty = getPropertyByFieldFullPath(
        moduleProperties,
        fieldFullPathError
      );

      await formInstance.setFieldsValue(formValues);
      await sleep(DELAY_TIME_ERROR_FORM);
      await formInstance.setFields([
        {
          name: errorProperty.propertyName, // Not nested
          errors: [<ErrorMessageProperty errors={[formatErrorPublication]} />],
        },
      ]);
      await formInstance.scrollToField(errorProperty.propertyName, {
        block: 'center',
        behavior: 'smooth',
      });
    } else {
      // ['ParentProperty1', 'ParentProperty2', ..., childProperty]
      const idxNameFormErrorProperty = getIdxNameFormOfProperty({
        properties: moduleProperties,
        paths: errorPropertyPaths,
        fieldFullPathError,
      });
      const { nearestPath } = getNearestFieldPath(
        formValues,
        idxNameFormErrorProperty
      );
      const nearestFieldFullPathError = getNearestFieldFullPathError(
        nearestPath,
        moduleNameError
      );
      const otherFieldFullPath = errorPropertyPaths.filter(
        (path) =>
          !nearestPath.filter((path) => typeof path === 'string').includes(path)
      );
      const nearestFoundProperty = getPropertyByFieldFullPath(
        moduleProperties,
        nearestFieldFullPathError
      );

      const newFormValues = generateFormValuesForPublishing({
        formValues,
        foundProperty: nearestFoundProperty,
        fieldFullPathError,
        nearestPath,
        otherFieldFullPath,
      });

      await formInstance.setFieldsValue(newFormValues);
      await sleep(DELAY_TIME_ERROR_FORM);
      await formInstance.setFields([
        {
          name: idxNameFormErrorProperty,
          errors: [
            isSingleNestedValue ? (
              <Col offset={6}>
                <ErrorMessageProperty errors={[formatErrorPublication]} />
              </Col>
            ) : (
              <ErrorMessageProperty errors={[formatErrorPublication]} />
            ),
          ],
        },
      ]);

      await formInstance.scrollToField(idxNameFormErrorProperty, {
        block: 'center',
        behavior: 'smooth',
      });

      dispatch({
        type: productDetailActionTypes.SEARCH_PROPERTIES,
        payload: {
          searchText: advancedSearchText,
          moduleSchema: [groupProductModuleProperties(foundModuleProperties)],
          modeView: 'edit',
          formInstance: newFormValues, // ???
        },
      });
    }
    setStatusValidate('success');
  };

  const setFormError = (validatingResult) => {
    const errorFormField = validatingResult.map((resultItem) => {
      return {
        name: resultItem.field,
        errors: [resultItem.message],
      };
    });

    refCurrentFormError.current = errorFormField;

    formInstance.setFields(errorFormField);
  };

  const removeFormError = () => {
    if (refCurrentFormError.current?.length === 0) return;

    const removedErrors = refCurrentFormError.current.map((formStatusItem) => ({
      ...formStatusItem,
      errors: [],
    }));

    formInstance.setFields(removedErrors);
  };

  const handleValidate = async (formValues = {}) => {
    removeFormError();
    await sleep(500);

    const filterFormValues = filterParamFormValues(
      formValues,
      editedModuleProperties.moduleProperties
    );

    const validatingResult = validateForm({ formValues: filterFormValues });

    if (validatingResult?.length) {
      setFormError(validatingResult);
      return Promise.reject(new Error({ name: 'validation fail' }));
    } else {
      return Promise.resolve(filterFormValues);
    }
  };

  const getOriginalData = () => {
    const foundModuleProperties = productSchema.find(
      (schema) => schema.moduleName === editedModuleProperties?.moduleName
    );

    const foundDataProductModule = dataProductModules?.productItemModules?.find(
      (item) => item.moduleName === editedModuleProperties?.moduleName
    );

    return getFormValues(
      lowercaseProductData(foundDataProductModule?.data),
      foundModuleProperties?.moduleProperties
    );
  };

  const handleSaveProductProperties = async (ixoneIgnore) => {
    const formValues = await formInstance.validateFields();

    setStatusSubmit('loading');

    handleValidate(formValues)
      .then(async (validatedFormData) => {
        const fieldsError = await formInstance.getFieldsError();

        // We only need to check errors in the AllergenModule because the fields in the Allergen section need to be validated while selecting a value.
        if (
          checkFormHasError(fieldsError) &&
          checkIsAllergenModuleName(editedModuleProperties?.moduleName)
        ) {
          CustomNotification.error(
            'Please resolve the errors before submitting!'
          );
          setStatusSubmit('error');
          return;
        }

        try {
          const params = {
            id: productId,
            moduleName: editedModuleProperties?.moduleName,
            data: validatedFormData,
            ixOneIgnore: ixoneIgnore,
          };

          const response = await productDetailServices.saveProductItemModules(
            params
          );
          dispatch({
            type: productDetailActionTypes.CLEAR_SEARCH_PROPERTIES,
          });
          if (response?.isSuccess) {
            if (typeof onResolveErrorPublicationSuccess === 'function') {
              onResolveErrorPublicationSuccess();
            }
            handleSyncAllergens(productId);
            handleSyncIngredients(productId);
            handleSyncQaSpec(productId);
            refetchDataProductModules();
            offModal();
            reloadPage();
            ixoneChangedFieldRef.current = {};
            CustomNotification.success('Update product detail successfully');
            handleRefetchProductFullView();
            setStatusSubmit('success');
          } else {
            CustomNotification.error(
              response?.message ?? 'Something went wrong'
            );
            setStatusSubmit('error');
          }
        } catch (error) {
          CustomNotification.error(error?.message ?? 'Something went wrong');
          setStatusSubmit('error');
        }
      })
      .catch((e) => {
        setStatusSubmit('error');
      });
  };

  const isLoadingModules =
    statusFetchProductModules === 'idle' ||
    statusFetchProductModules === 'pending';

  const isValidatingError = statusValidate === 'validating';

  const {
    isTabExpanded: isExpanded,
    productHeaderImageSize,
    toggleTabExpand,
    shrinkTab,
  } = useTabExpandProductDetail(false);

  const handleToggleTabExpand = (value) => {
    toggleTabExpand(value);

    if (isFullView) {
      dispatch({
        type: productDetailActionTypes.TOGGLE_FULL_VIEW,
        payload: {
          value: false,
        },
      });
    }
  };

  const { productHeaderIndent, searchAdvanceModuleInFullModeIndent } =
    useMemo(() => {
      const productHeaderIndent = `${productHeaderImageSize?.width + 10}px`;
      const searchAdvanceModuleInFullModeIndent = `${Math.sqrt(
        Math.pow(productHeaderImageSize?.width, 2) - Math.pow(35, 2)
      )}px`;

      return {
        productHeaderIndent,
        searchAdvanceModuleInFullModeIndent,
      };
    }, [productHeaderImageSize]);

  useEffect(() => {
    return () => {
      //* turn off expand when leave page
      shrinkTab();
    };
  }, []);

  const dStyles = reactCSS({
    default: {
      productHeader: {
        imageWrap: {
          width: productHeaderIndent,
          position: 'absolute',
        },
      },
      propertyQueryLeft: {
        borderRadius: isExpanded ? 0 : 3,
        background: '#f2f2f2',
      },
      propertyQueryRight: {
        borderRadius: isExpanded ? 0 : 3,
        background: !brickCode ? (isExpanded ? '#f2f2f2' : 'none') : '#f2f2f2',
        ...(!brickCode && !isExpanded ? { padding: 0 } : {}),
      },

      productPrimary: {
        wrap: {
          flexFlow: 'nowrap',
        },
        content: {
          paddingLeft: isExpanded ? productHeaderIndent : 0,
          transition: 'all 0.3s',
          flex: 1,
        },
      },
    },
  });

  return (
    <>
      <div
        className={classnames('product-detail-view', {
          'product-detail-view--expanded': isExpanded && !isFullView,
          'product-detail-view--full': isFullView,
        })}
      >
        <>
          <ProductDetailGridItemContainer gridItemType='product-primary-info'>
            <Row style={dStyles.productPrimary.wrap}>
              {isExpanded && (
                <div style={dStyles.productHeader.imageWrap}>
                  <ProductHeaderImage
                    productDetail={product}
                    productHeaderImageSize={productHeaderImageSize}
                    hidden={!isExpanded}
                    toggleTabExpand={handleToggleTabExpand}
                    toggleExpandText='Open Full Detail View'
                  />
                </div>
              )}
              <Col
                id='product-detail-primary-container'
                style={dStyles.productPrimary.content}
              >
                <ProductPrimaryInfo product={product} />
              </Col>
            </Row>
            {!isExpanded && <Divider style={styles.divider.productPrimary} />}
          </ProductDetailGridItemContainer>

          <ProductDetailGridItemContainer gridItemType='product-meta'>
            <WithProductDetailExpandBtnfrom
              componentType={COMPONENT_TYPE.productMeta}
            >
              <ProductOverviewMetadata
                product={product}
                productItemModules={dataProductModules}
                productSchema={productSchema}
                productEnums={productEnums}
                showPackageDrawer={() => setVisiblePackageContent(true)}
              />
            </WithProductDetailExpandBtnfrom>
            <Divider style={styles.divider.metaData} />
          </ProductDetailGridItemContainer>

          {!isFullView && (
            <>
              <ProductDetailGridItemContainer
                gridItemType='query-property-left'
                style={dStyles.propertyQueryLeft}
              >
                <QueryProperties
                  moduleType='base'
                  moduleSchema={baseProductSchema}
                  isExpanded={isExpanded}
                />
              </ProductDetailGridItemContainer>

              <ProductDetailGridItemContainer
                gridItemType='product-detail-left-module'
                style={styles.detail.leftModule.container}
              >
                <WithLoading loading={isLoadingModules}>
                  <div style={styles.detail.leftModule.content}>
                    <HeaderGroupElement
                      header='Base Modules'
                      className='product-detail__group-header product-detail__group-header--module'
                    />
                    <GroupElementWrapper className='product-detail-view__group-module'>
                      <ProductBaseModules
                        productSchema={baseProductSchema}
                        customProperties={
                          <ProductCustomProperties
                            hasPermissionEditProperties={true}
                            productId={productId}
                            ownerId={ownerId}
                            queryLeftProperties={{
                              expandAll: isExpandAll,
                              onlyShowValues: isShowOnlyValueBase,
                            }}
                          />
                        }
                        dataProductModules={dataProductModules}
                        onClickEditBtn={handleClickEditBtn}
                        productEnums={productEnums}
                      />
                    </GroupElementWrapper>
                  </div>
                </WithLoading>
              </ProductDetailGridItemContainer>
            </>
          )}
        </>

        <ProductDetailGridItemContainer
          gridItemType='query-property-right'
          style={dStyles.propertyQueryRight}
        >
          <WithProductDetailExpandBtnfrom
            componentType={COMPONENT_TYPE.queryPropertyRight}
            onClick={handleToggleTabExpand}
          >
            {brickCode && (
              <div>
                <QueryProperties
                  moduleType='advanced'
                  moduleSchema={brickCodeSchema}
                  isExpanded={isExpanded}
                  isFull={isFullView}
                  searchAdvanceModuleInFullModeIndent={
                    searchAdvanceModuleInFullModeIndent
                  }
                  toggleTabExpand={toggleTabExpand}
                />
              </div>
            )}
          </WithProductDetailExpandBtnfrom>
        </ProductDetailGridItemContainer>

        <ProductDetailGridItemContainer
          gridItemType='product-brickcode-detail'
          style={styles.detail.rightModule.container}
        >
          <WithLoading loading={isLoadingModules}>
            <div style={styles.detail.rightModule.content}>
              <HeaderGroupElement
                header='Advanced Modules'
                className='product-detail__group-header product-detail__group-header--module'
              />
              {brickCode ? (
                <GroupElementWrapper className='product-detail-view__group-module'>
                  <ProductAdvancedModules
                    productSchema={brickCodeSchema}
                    onClickEditBtn={handleClickEditBtn}
                    dataProductModules={dataProductModules}
                    productEnums={productEnums}
                  />
                </GroupElementWrapper>
              ) : (
                <GroupElementWrapper style={styles.empty.brickcodeContainer}>
                  <Empty
                    style={styles.empty.brickcode}
                    description='The product does not have a brick code'
                  />
                </GroupElementWrapper>
              )}
            </div>
          </WithLoading>
        </ProductDetailGridItemContainer>
      </div>

      {visibleEditModal && (
        <IXONECertificationWrapper
          showModal
          type={TYPE_VIEW.PRODUCT_DETAIL}
          onConfirm={(ixoneIgnore) => {
            handleSaveProductProperties(ixoneIgnore);
          }}
          onCancel={() => {
            offModal();
            ixoneChangedFieldRef.current = {};
            dispatch({
              type: productDetailActionTypes.CLEAR_SEARCH_PROPERTIES,
            });
            dispatch({
              type: productDetailActionTypes.CLEAR_SEARCH_BASE_PROPERTIES,
            });
          }}
        >
          {(handleConfirmIXONE) => (
            <ModalEditForm
              className='product-detail-view__modal'
              title={
                <Row justify='space-between' align='middle'>
                  <Col flex='auto'>
                    <Typography.Text
                      strong
                      style={styles.modalEditForm.moduleNAme}
                    >
                      {editedModuleProperties?.moduleDisplayName}
                    </Typography.Text>
                  </Col>

                  <Col
                    flex='400px'
                    style={styles.modalEditForm.searchProperties}
                  >
                    <SearchProductProperties
                      treeData={generateModuleProductToTree([
                        editedModuleProperties,
                      ])}
                      value={
                        isEditBasedModule ? baseSearchText : advancedSearchText
                      }
                      onChange={(searchText) =>
                        dispatch({
                          type: isEditBasedModule
                            ? productDetailActionTypes.SEARCH_BASE_PROPERTIES
                            : productDetailActionTypes.SEARCH_PROPERTIES,
                          payload: {
                            searchText,
                            moduleSchema: [editedModuleProperties],
                            modeView: 'edit',
                            formInstance,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              }
              visible={visibleEditModal}
              onCancel={() => {
                offModal();
                ixoneChangedFieldRef.current = {};
                dispatch({
                  type: productDetailActionTypes.CLEAR_SEARCH_PROPERTIES,
                });
                dispatch({
                  type: productDetailActionTypes.CLEAR_SEARCH_BASE_PROPERTIES,
                });
              }}
              onOk={() => {
                const originalData = getOriginalData();
                handleConfirmIXONE({
                  originalData,
                  changedFields: ixoneChangedFieldRef.current,
                });
              }}
              okButtonProps={{
                loading: statusSubmit === 'loading',
              }}
            >
              {/* FIXME: Move WithLoading to outside is not working? I don't know about it. Fix later */}
              <WithLoading
                loading={isValidatingError}
                tip='Search for error fields...'
              >
                <ProductDetailEdit
                  ixoneChangedFieldRef={ixoneChangedFieldRef}
                  moduleNameProperties={editedModuleProperties}
                  isGroup={editedModuleProperties.isGroup}
                  productEnums={productEnums}
                  handleValidate={handleValidate}
                  isEditBasedModule={isEditBasedModule}
                />
              </WithLoading>
            </ModalEditForm>
          )}
        </IXONECertificationWrapper>
      )}

      {visiblePackageContent && (
        <PackageContent
          onClose={() => setVisiblePackageContent(false)}
          visible={visiblePackageContent}
        />
      )}
    </>
  );
};

export default ProductDetailView;

const styles = reactCSS({
  default: {
    empty: {
      brickcode: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      brickcodeContainer: {
        height: '100%',
      },
    },
    divider: {
      productPrimary: {
        margin: '0px 0px',
      },
      metaData: {
        margin: '4px 0px',
      },
    },

    detail: {
      leftModule: {
        container: {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          display: 'flex',
          height: 'inherit',
          flexDirection: 'column',
        },
      },
      rightModule: {
        container: {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        content: {
          display: 'flex',
          height: 'inherit',
          flexDirection: 'column',
        },
      },
    },
    modalEditForm: {
      moduleName: { fontSize: 16, color: '#6c757d' },
      searchProperties: { marginRight: 24, marginLeft: 6 },
    },
  },
});
