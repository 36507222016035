import React from 'react';

import { Button, Typography } from 'antd';

import {
  CaretUpOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

const KeyboardHint = (props) => {
  const { arrowOnly, isActive } = props;

  const buttonClassName = isActive
    ? 'page-editor-tool__btn--keyboard-hint-active'
    : 'page-editor-tool__btn--keyboard-hint';

  return (
    <>
      <Text style={{ padding: 3 }}>
        {isActive ? 'Keyboard on' : 'Keyboard off'}
      </Text>
      <div style={{ pointerEvents: 'none', paddingBottom: 5 }}>
        <table>
          <tbody>
            <tr>
              <td />
              <td>
                <Button className={buttonClassName}>
                  <CaretUpOutlined />
                </Button>
              </td>
              {new Array(3).map((value, index) => (
                <th key='index' />
              ))}
            </tr>
            <tr>
              <td>
                <Button className={buttonClassName}>
                  <CaretLeftOutlined />
                </Button>
              </td>
              <td>
                <Button className={buttonClassName}>
                  <CaretDownOutlined />
                </Button>
              </td>
              <td style={{ paddingRight: 10 }}>
                <Button className={buttonClassName}>
                  <CaretRightOutlined />
                </Button>
              </td>
              {!arrowOnly && (
                <>
                  <td>
                    <Button className={buttonClassName}>
                      <MinusOutlined />
                    </Button>
                  </td>
                  <td>
                    <Button className={buttonClassName}>
                      <PlusOutlined />
                    </Button>
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default KeyboardHint;
