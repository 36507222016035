import * as api from 'config/axios';
import * as endpoints from './endpoints';

// Get Post List
export const getPostList = (data) => {
  return api.sendPost(endpoints.POST_DETAIL_GRID, data);
};

// Get Folder List
export const getCategoryGrid = (params) => {
  return api.sendPost(endpoints.GET_HELP_CATEGORY_DETAIL_GRID, params);
};

// get category post list
export const getCategoryDetailData = (id) => {
  const options = {
    params: {
      id,
    },
  };
  return api.sendGet(endpoints.GET_HELP_CATEGORY_DETAIL_DATA, options);
};
// Get Post Content Detail
export const getPostContentDetail = (data) => {
  return api.sendGet(endpoints.POST_CONTENT_DETAIL, {
    params: data,
  });
};

export const saveHelpPost = (params) => {
  return api.sendPost(endpoints.HELP_SAVE_POST, params);
};

//* re-index category list
export const reIndexCategoryList = (idList) => {
  const data = {
    idList,
  };
  return api.sendPost(endpoints.RE_INDEX_HELP_CATEGORY, data);
};

//* re-index post list
export const reIndexPostList = (idList, categoryId) => {
  const data = {
    idList,
    categoryId,
  };
  return api.sendPost(endpoints.RE_INDEX_HELP_POST, data);
};

export const submitCategory = (params) => {
  return api.sendPost(endpoints.SAVE_HELP_CATEGORY, params);
};

export const getHelpPost = (id) => {
  const options = {
    params: {
      id,
    },
  };
  return api.sendGet(endpoints.GET_HELP_POST, options);
};

// Delete Help Posts
export const deleteHelpPosts = (params) => {
  return api.sendPost(endpoints.DELETE_HELP_POST, params);
};

// Delete Help Categories
export const deleteHelpCategories = (params) => {
  return api.sendPost(endpoints.DELETE_HELP_CATEGORY, params);
};

export const getHelpAssets = (params) => {
  return api.sendPost(endpoints.GET_HELP_ASSETS, params);
};

// Articles
export const deleteArticlesService = (params) => {
  return api.sendPost(endpoints.DELETE_ARTICLE, params);
};

export const getArticlesService = (params) => {
  return api.sendPost(endpoints.ARTICLE_DETAIL_GRID, params);
};

export const getDetailArticleService = (id) => {
  const options = {
    params: {
      id,
    },
  };
  return api.sendGet(endpoints.DETAIL_ARTICLE, options);
};

export const getArticleAssets = (params) => {
  return api.sendPost(endpoints.GET_ARTICLE_ASSETS, params);
};

export const saveArticleService = (params) => {
  return api.sendPost(endpoints.SAVE_ARTICLE, params);
};

// Communication-templates
export const getCommunicationTemplatesAssets = (params) => {
  return api.sendPost(endpoints.GET_COMMUNICATION_TEMPLATES_ASSETS, params);
};

// Backup and restore article grid view
export const backupHelpCenterService = (params) => {
  return api.sendPost(endpoints.BACKUP_HELP_CENTER, params);
};

export const restoreHelpCenterService = (params) => {
  return api.sendPost(endpoints.RESTORE_HELP_CENTER, params);
};
