const getListCounts = (listItems, field) =>
  listItems.map((item) => item[field]);

const sum = (listItems, field) => {
  const listCounts = getListCounts(listItems, field);
  return listCounts.reduce((a, b) => a + b, 0);
};

const findListItems = (selectedIds, data) => {
  const result = selectedIds.map((id) =>
    data.find((element) => element.id === id)
  );
  return result;
};

const getResultCanAccess = (listItems, field) => {
  const listBooleanAccess = getListCounts(listItems, field);

  return listBooleanAccess.every((ele) => ele);
};

const filterColumnsGrid = (columns, fields) => {
  const checkboxColumn = columns[0] ?? {};
  const filteredColumns = columns.filter((c) => fields.includes(c.displayName));
  const result = [checkboxColumn, ...filteredColumns];

  return result;
};

const checkIsMember = (items) => {
  return items.some((item) => item?.type === 'member');
};

const checkItemNotHaveType = (items) => {
  return items.some((item) => !item?.type);
};

const checkNotIsOwner = (items) => items.some((item) => !item?.isOwner);

const checkPrivate = (items) =>
  items.some((item) => item?.visibility?.toLowerCase() === 'private');

const checkNotShareable = (items) => items.some((item) => !item?.shareable);
const checkNotShareableOnlyIfShared = (items) =>
  items.some((item) => item?.visibility?.toLowerCase() !== 'only if shared');

const checkIsGdsnShield = (items) => items.some((item) => !!item?.gdsnShield);

const checkDisabledShareButton = (items) => {
  // no item selected
  if (items?.length === 0) return true;

  // item not has field "type"
  if (checkItemNotHaveType(items)) return true;
  // is member
  if (checkIsMember(items)) return true;

  if (window.location.pathname === '/products') {
    if (checkIsGdsnShield(items)) return true;
    const notSharableItems = items?.filter(
      // BP 4909: need to check current item is not sharable items. the condition is not only if shared or not shared item
      (item) =>
        item.visibility?.toLowerCase() !== 'only if shared' || !item?.isOwner
    );

    if (notSharableItems && notSharableItems.length > 0) return true;
  }

  if (checkNotIsOwner(items)) return true;

  if (checkPrivate(items)) return true;

  if (checkNotShareableOnlyIfShared(items) && checkNotShareable(items))
    return true;

  return false;
};

const checkNotCreateByMyCompanyAndVisibility = (items, memberId) => {
  return items.some(
    (item) =>
      !(
        item?.visibility === 'Company' &&
        item?.ownerCompanyId?.toString() === memberId?.toString()
      )
  );
};

//share apl - one of conditions:
//create by user login: isOwner
//visibility === Company && ownerCompanyId=== myCompany
//shareable === true: backend calculated. so using shareable is enough
const checkDisabledShareButtonForAPL = (items) => {
  // no item selected
  if (items?.length === 0) return true;

  // item not has field "type"
  if (checkItemNotHaveType(items)) return true;

  if (checkNotShareable(items)) return true;

  return false;
};

export {
  sum,
  findListItems,
  getResultCanAccess,
  filterColumnsGrid,
  checkDisabledShareButton,
  checkDisabledShareButtonForAPL,
};
