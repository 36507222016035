import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Input,
  Checkbox,
  Row,
  List,
  Col,
  Avatar,
  Radio,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import GroupPeopleCreate from './GroupPeopleCreate';
import GroupCompanyCreate from './GroupCompanyCreate';
import * as api from 'config/axios';

import { Form } from 'common/components';
import messages from 'i18n/messages/home';
import * as chatServices from 'services/chatServices';
import * as chatActions from '../../controller/actions';
import { sortBy } from 'lodash';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { Can } from 'context/Can';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import userSelectors from 'redux/user/selectors';

import * as emailActions from '../../../mail/controller/actions';
import * as emailServices from 'services/email';

import { CHATTYPE } from 'static/Constants';

import './CreateNewThreadModal.less';

export const CreateNewThreadModal = (props) => {
  const { Title } = Typography;
  const [type, setType] = useState(false);
  const [userType, setUserType] = useState('user');
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [threadInfo, setThreadInfo] = useState({});
  const {
    visible,
    closeNewChat,
    userListAttend,
    companyListAttend,
    communicationListData,
    isInEmail,
    setRequestThreadInfo,
  } = props;
  const [form] = Form.useForm();
  const [userDisplay, setUserDisplay] = useState(
    userListAttend ? userListAttend : []
  );

  const [companyDisplay, setCompanyDisplay] = useState(
    companyListAttend ? companyListAttend : []
  );
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const PERMISSION_HAVE_CHAT_COMPANY = [
    {
      action: ABILITY_ACTION.CHAT,
      subject: ABILITY_SUBJECT.CHAT_COMPANY,
    },
  ];

  const PERMISSION_HAVE_CHAT_PERSONAL = [
    {
      action: ABILITY_ACTION.CHAT,
      subject: ABILITY_SUBJECT.CHAT_PERSONAL,
    },
  ];

  const havePersonalChat = useCheckPermissions(PERMISSION_HAVE_CHAT_PERSONAL);

  const [user, setUser] = useState(havePersonalChat ? 'Personal' : 'Company');
  const userId = useSelector(userSelectors.makeSelectUserId());
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());

  const userSender = `U-${userId}`;
  const memberSender = `C-${memberId}`;
  const creatorId = user === CHATTYPE.COMPANY ? memberSender : userSender;

  useEffect(() => {
    if (threadInfo?.id) {
      updateCommunicationThreadList();
      const params = { threadId: threadInfo?.id, userId: creatorId };
      if (isInEmail) {
        dispatch(emailActions.getEmailThreadInfo(params));
        dispatch(emailActions.getEmailThreadInfoContent(threadInfo?.id));
      }
    }
  }, [threadInfo?.id]);

  useEffect(() => {
    return () => {
      setSearchText('');
    };
  }, [visible]);

  const updateCommunicationThreadList = () => {
    let result =
      communicationListData?.length > 0 ? [...communicationListData] : [];

    const existedThread = result?.find(
      (thread) => thread.threadId === threadInfo?.id
    );
    if (existedThread) {
      result = sortBy(result, ({ threadId }) =>
        threadId === existedThread?.threadId ? 0 : 1
      );
    } else if (!existedThread && threadInfo?.id) {
      result.unshift({
        ...threadInfo,
        threadId: threadInfo?.id,
        uniqueId: `${creatorId}-${threadInfo?.id}`,
      });
    }
    setThreadInfo({});

    if (isInEmail) {
      dispatch(emailActions.updateEmailThreadList(result));
    }
  };

  const onChangeHandler = (e) => {
    setSearchText(e.target.value);
  };

  async function handleSearchUser(params) {
    setLoading(true);
    await api
      .sendGet(`/api/chat/search-users-for-chat?SearchText=${params}`)
      .then((response) => {
        if (response?.isSuccess) {
          setUserList(response?.data?.usersForChat);
          setUserType('user');
        }
      });
    setLoading(false);
  }

  async function handleSearchCompany(params, loadingFunction, setFunction) {
    setLoading(true);
    await api
      .sendGet(`/api/chat/search-companies-for-chat?SearchText=${params}`)
      .then((response) => {
        if (response?.isSuccess) {
          setCompanyList(response?.data?.companiesForChat);
          setUserType('company');
        }
      });
    setLoading(false);
  }

  const submitCreateThread = async (params) => {
    const userSubmit = userDisplay.map((item) => item.userId);
    const companySubmit = companyDisplay.map((item) => item.companyId);
    const paramsSubmit = {
      userChatType:
        user === CHATTYPE.COMPANY ? CHATTYPE.COMPANY : CHATTYPE.USER,
      chatType: isInEmail ? 'Email' : 'Group',
      threadName: params.threadName,
      users: userSubmit,
      companies: companySubmit,
    };
    setLoading(true);

    if (isInEmail) {
      // Email
      try {
        const response = await emailServices.initEmailCommunication(
          paramsSubmit
        );
        if (response?.isSuccess) {
          getThreadInformation(response?.data?.threadId, creatorId);
          closeChat();
        }
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      try {
        const response = await chatServices.initChatCommunication(paramsSubmit);
        if (response?.isSuccess) {
          dispatch(chatActions.setChatView('threadMsg'));
          dispatch(
            chatActions.getThreadInfo(response?.data?.threadId, creatorId)
          );
          dispatch(chatActions.getThreadMessages(response?.data?.threadId));
          setRequestThreadInfo({
            threadId: response?.data?.threadId,
            creatorId,
          });
          closeChat();
        }
      } catch (error) {
        console.log('error: ', error);
      }
    }

    setLoading(false);
  };

  const getThreadInformation = async (threadId, userId) => {
    try {
      const params = {
        ThreadId: threadId,
        UserId: userId,
      };
      const response = await chatServices.getThreadInfo(params);
      if (response?.isSuccess) {
        setThreadInfo(response?.data);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const closeChat = () => {
    form.resetFields();
    setUserList([]);
    setCompanyList([]);
    setUserDisplay([]);
    setCompanyDisplay([]);
    setType(false);
    setUserType('user');
    closeNewChat();
  };

  const changeItem = (user) => {
    if (userDisplay?.filter((item) => item.userId === user.userId).length > 0)
      setUserDisplay(
        userDisplay?.filter((item) => item.userId !== user.userId)
      );
    else setUserDisplay(userDisplay.concat([user]));
  };
  const removeItem = (userId) => {
    setUserDisplay(userDisplay?.filter((item) => item.userId !== userId));
  };

  const changeCompanyItem = (company) => {
    if (
      companyDisplay?.filter((item) => item.companyId === company.companyId)
        .length > 0
    )
      setCompanyDisplay(
        companyDisplay?.filter((item) => item.companyId !== company.companyId)
      );
    else setCompanyDisplay(companyDisplay.concat([company]));
  };
  const removeCompanyItem = (id) => {
    setCompanyDisplay(companyDisplay?.filter((item) => item.companyId !== id));
  };
  return (
    <Modal
      title={
        isInEmail ? (
          <FormattedMessage {...messages.emailThread} />
        ) : (
          <FormattedMessage {...messages.newThread} />
        )
      }
      visible={visible}
      destroyOnClose={true}
      onCancel={() => closeChat()}
      className={'new-thread-modal'}
      width='870px'
      centered
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        name='shared'
        onFinish={(values) => submitCreateThread(values)}
      >
        <Form.Item
          label={
            isInEmail ? (
              <FormattedMessage {...messages.emailThreadName} />
            ) : (
              <FormattedMessage {...messages.threadName} />
            )
          }
          name='threadName'
          rules={[{ required: true, message: 'Please input your thread name' }]}
        >
          <Input placeholder='New thread name' />
        </Form.Item>
        <Row justify='space-between'>
          <Form.Item
            name='findContact'
            label={<FormattedMessage {...messages.findContactAddToThread} />}
          >
            <Checkbox
              onChange={(values) => {
                setType(values?.target?.checked);
                form.resetFields(['search']);
                setCompanyList([]);
                setUserList([]);
              }}
            >
              <FormattedMessage {...messages.findMember} />
            </Checkbox>
          </Form.Item>
          <Form.Item name='user'>
            <Radio.Group
              value={user}
              onChange={(e) => setUser(e.target.value)}
              defaultValue={user}
            >
              <Can I={ABILITY_ACTION.CHAT} a={ABILITY_SUBJECT.CHAT_PERSONAL}>
                <Radio.Button value='Personal'>
                  <FormattedMessage {...messages.personalTilte} />
                </Radio.Button>
              </Can>
              <Can I={ABILITY_ACTION.CHAT} a={ABILITY_SUBJECT.CHAT_COMPANY}>
                <Radio.Button value='Company'>
                  <FormattedMessage {...messages.companyTitle} />
                </Radio.Button>
              </Can>
            </Radio.Group>
          </Form.Item>
        </Row>

        <Form.Item name='search'>
          <Row style={{ marginBottom: 5 }}>
            <Input.Search
              allowClear
              placeholder={'Search users'}
              style={{ width: '40%' }}
              onSearch={(item) => {
                !type ? handleSearchUser(item) : handleSearchCompany(item);
              }}
              value={searchText}
              onChange={onChangeHandler}
            />
          </Row>
        </Form.Item>

        {userDisplay && userDisplay.length > 0 && (
          <>
            <Row>
              <Title level={5}>User list:</Title>
            </Row>
            <Row>
              <GroupPeopleCreate
                listPeople={userDisplay}
                removeItem={(item) => removeItem(item)}
              />
            </Row>
          </>
        )}
        {companyDisplay && companyDisplay.length > 0 && (
          <>
            <Row>
              <Title level={5}>Company list:</Title>
            </Row>
            <Row>
              <GroupCompanyCreate
                listPeople={companyDisplay}
                removeItem={(item) => removeCompanyItem(item)}
              />
            </Row>
          </>
        )}
        {(userList?.length > 0 || companyList?.length > 0) && (
          <Title level={4}>Search result</Title>
        )}
        {userType === 'user' ? (
          <>
            <Spin spinning={loading}>
              <List
                className='new-thread-modal__user-list scroller'
                itemLayout='horizontal'
                dataSource={userList}
                renderItem={(item) => (
                  <List.Item>
                    <Row style={{ width: '100%' }}>
                      <Col
                        span={1}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Checkbox
                          onChange={() => {
                            changeItem(item);
                          }}
                          checked={
                            userDisplay?.filter(
                              (user) => user.userId === item.userId
                            ).length > 0
                          }
                        />
                      </Col>
                      <Col span={2}>
                        <Avatar
                          src={item?.avatar && item?.avatar}
                          icon={<UserOutlined />}
                          size={50}
                        />
                      </Col>
                      <Col span={21}>
                        <Row justify='space-between'>
                          <Col span={9}>
                            <Row justify='start'>
                              <Title level={5}>{item.fullName}</Title>
                            </Row>
                            <Row justify='start'>
                              <Tooltip title='Send email to this address'>
                                <a href={`mailto:${item.email}`}>
                                  {item.email}
                                </a>
                              </Tooltip>
                            </Row>
                          </Col>
                          <Col span={6}>
                            <Row className='new-thread-modal__company-name-text'>
                              {item.companyName}
                            </Row>
                          </Col>
                          <Col span={9}>
                            <Row className='new-thread-modal__gender-text'>
                              {item.gender}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              ></List>
            </Spin>
            <Row justify='end'> {userList.length} contacts</Row>
          </>
        ) : (
          <>
            <Spin spinning={loading}>
              <List
                className='new-thread-modal__user-list scroller'
                itemLayout='horizontal'
                dataSource={companyList}
                renderItem={(item) => (
                  <List.Item>
                    <Row style={{ width: '100%' }}>
                      <Col
                        span={1}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Checkbox
                          onChange={() => {
                            changeCompanyItem(item);
                          }}
                          checked={
                            companyDisplay?.filter(
                              (company) => company.companyId === item.companyId
                            ).length > 0
                          }
                        />
                      </Col>
                      <Col span={2}>
                        <Avatar
                          src={item?.logo}
                          icon={<UserOutlined />}
                          size={50}
                        />
                      </Col>
                      <Col span={21}>
                        <Row justify='space-between'>
                          <Col>
                            <Row>
                              <Title level={5}>{item.companyName}</Title>
                            </Row>
                          </Col>
                          <Col
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Row>{item.gender}</Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              ></List>
            </Spin>
            <Row justify='end'> {companyList.length} contacts</Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateNewThreadModal;
