import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectWorkflowTemplateList = (state) =>
  state.workflowTemplate || initialState;
const makeSelectColumns = () =>
  createSelector(
    selectWorkflowTemplateList,
    (workflowTemplateState) => workflowTemplateState.columns
  );
const makeSelectItemsSelected = () =>
  createSelector(
    selectWorkflowTemplateList,
    (workflowTemplateState) => workflowTemplateState.wfItemsSelected
  );
const makeSelectIsReloadGrid = () =>
  createSelector(
    selectWorkflowTemplateList,
    (workflowTemplateState) => workflowTemplateState.isReloadGrid
  );

export {
  selectWorkflowTemplateList,
  makeSelectColumns,
  makeSelectItemsSelected,
  makeSelectIsReloadGrid,
};
