export const GET_AVAILABLE_MEMBERS_FOR_SHARING =
  '/api/share/get-available-members-for-sharing';
export const GET_AVAILABLE_USERS_FOR_SHARING =
  '/api/share/get-available-users-for-sharing';
export const GET_AVAILABLE_MEMBERS_FOR_SHARING_DISTINCT =
  '/api/share/get-available-members-for-sharing/distinct';
export const GET_AVAILABLE_USERS_FOR_SHARING_DISTINCT =
  '/api/share/get-available-users-for-sharing/distinct';
export const SHARE_BUTTON = '/api/share/share-button';
export const GET_SHARE_DATA_LIST = '/api/share/get-list-shared';
export const GET_SHARE_DATA_LIST_DISTINCT =
  '/api/share/get-list-shared-distinct';
export const UPDATE_SHARING = '/api/share/update-sharing';
export const REMOVE_SHARING = '/api/share/remove-share-entities';
export const GET_MEMBER_SHARING = '/api/share/get-member-sharing';
export const GET_MEMBER_SHARING_DISTINCT =
  '/api/share/get-member-sharing/distinct';
