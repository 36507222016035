/*
 * Common Messages
 *
 * This contains all the text for the common component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.common.components.ag-grid';

export default defineMessages({
  btnAdd: {
    id: `${scope}.components.agGridEditHeader.btn.add`,
    defaultMessage: 'Add',
  },
  btnEdit: {
    id: `${scope}.components.agGridEditHeader.btn.edit`,
    defaultMessage: 'Edit',
  },
  btnCancel: {
    id: `${scope}.components.agGridEditHeader.btn.cancel`,
    defaultMessage: 'Cancel',
  },
  btnDelete: {
    id: `${scope}.components.agGridEditHeader.btn.delete`,
    defaultMessage: 'Delete',
  },
  btnResetPassword: {
    id: `${scope}.components.agGridEditHeader.btn.resetPassword`,
    defaultMessage: ' Reset Password',
  },
  chooseDownloadFileType: {
    id: `${scope}.components.agGridEditHeader.btn.chooseDownloadOptions`,
    defaultMessage: ' Choose file type',
  },
});
