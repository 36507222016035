import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Select } from 'antd';
import { isArray } from 'lodash';
import { sleep } from 'utils/delay';

import { useGetProductEnums } from 'hooks';
import { findProductPropertyEnums } from 'pages/product-full-view/components/product-detail-view/utils';

import './CountryEditor.less';

const { Option } = Select;

export const CountryEditor = forwardRef((props, ref) => {
  const { mode = 'multiple', listString = true, column } = props;

  const { productEnums } = useGetProductEnums();

  const countryCodeEnum = findProductPropertyEnums(
    'CountryCodeEnum',
    productEnums
  );

  const processValue = (value) => {
    if (isArray(value)) {
      return value;
    } else if (value) {
      return value?.split(', ') || [];
    } else {
      return [];
    }
  };

  const [country, setCountry] = useState(processValue(props.value));
  const [editing, setEditing] = useState(true);
  const refContainer = useRef(null);
  const refSelect = useRef();
  const refValue = useRef(undefined);

  const focus = () => {
    setTimeout(() => {
      refSelect.current?.focus && refSelect.current.focus();
    }, [100]);
  };

  useEffect(() => {
    focus();
  }, []);

  const getCountryListInString = (countryValue) => {
    return listString ? countryValue : countryValue.join(', ');
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (refValue.current === undefined) {
          return getCountryListInString(country);
        } else {
          const procValue = getCountryListInString(refValue.current);
          refValue.current = undefined;
          return procValue;
        }
      },
    };
  });

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const handleChange = (value) => {
    if (!value) {
      setCountry([]);
      return;
    }
    if (typeof value === 'string') {
      setCountry([value]);
      return;
    }
    setCountry(value);
  };

  const handleKeyInMultipleMode = async (e) => {
    if (e.key === 'Control') {
      await sleep(100);

      const $dropDownActiveItem = document.querySelector(
        'div.ant-select-item-option-active > div.ant-select-item-option-content > span.option-item-value'
      );

      if ($dropDownActiveItem) {
        const nextValue = [...country];

        const activeValue = $dropDownActiveItem.getAttribute('value');

        const foundActiveValueIdx = country.findIndex(
          (valueItem) => valueItem === activeValue
        );

        if (foundActiveValueIdx !== -1) {
          nextValue.splice(foundActiveValueIdx, 1);
        } else {
          nextValue.push(activeValue);
        }

        setCountry(nextValue);
      }
    }
  };

  const handleKeyInSingleMode = async (e) => {
    if (e.key === 'Delete') {
      setCountry([]);
      refValue.current = [];
      setEditing(false);
      return;
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault();
    } else {
      await sleep(100);
      const $dropDownActiveItem = document.querySelector(
        'div.ant-select-item-option-active > div.ant-select-item-option-content > span.option-item-value'
      );

      if ($dropDownActiveItem) {
        const activeValue = $dropDownActiveItem.getAttribute('value');
        const nextValue = [activeValue];

        refValue.current = nextValue;
      }
    }
  };

  const handleInputKeyDown = async (e) => {
    if (e.key === 'Delete') {
      setCountry([]);
      setEditing(false);
      return;
    }

    if (mode === 'multiple') {
      await handleKeyInMultipleMode(e);
    }

    if (mode === 'single') {
      await handleKeyInSingleMode(e);
    }
  };

  const width = column.getActualWidth() || 300;

  return (
    <div
      id='select'
      ref={refContainer}
      className='ag-editor__country'
      tabIndex={1}
      style={{ width }}
    >
      <Select
        ref={refSelect}
        allowClear
        mode={mode}
        style={{ width: '100%' }}
        placeholder='Please select'
        onChange={handleChange}
        value={country}
        onInputKeyDown={handleInputKeyDown}
        virtual={true}
      >
        {countryCodeEnum?.map((countryItem) => {
          return (
            <Option
              key={countryItem?.enumDisplayName}
              value={countryItem?.enumDisplayName}
              style={{ display: 'flex', alignItems: 'center' }}
              className='ag-editor__country-option'
            >
              <span
                className='option-item-value'
                value={countryItem.enumDisplayName}
              >
                {countryItem.enumDisplayName}
              </span>
            </Option>
          );
        })}
      </Select>
    </div>
  );
});
