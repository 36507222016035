import React, { useState, useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';

import { InfoWithLabel } from 'common/components';
import { EditUserEmail } from '../top-content-edit';

import * as contactUtil from 'utils/contact';

const UserContactInfo = (props) => {
  const { className, isEditMode, userId, contactInfo } = props;

  const { email } = contactInfo;

  const [showEditEmail, setShowEditEmail] = useState(false);

  const contactInfoWithLabel = useMemo(() => {
    return contactUtil.getUserContact(contactInfo);
  }, [contactInfo]);

  const infoColProps = {
    className,
    infoColProps: {
      flex: 'auto',
      style: { overflow: 'hidden' },
    },
    labelColProps: {
      flex: '60px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  const onFinishEdit = (status) => {};

  const editButtons = {
    email: (
      <EditOutlined
        style={{ zIndex: 1 }}
        onClick={() => setShowEditEmail(true)}
      />
    ),
  };

  return (
    <div className='user-profile__primary-info-contact-container'>
      {contactInfoWithLabel?.map((item, index) => {
        const { info, label } = item;
        let renderEditBtn = null;

        switch (label) {
          case 'Phone':
            renderEditBtn = editButtons['contact'];
            break;
          case 'Email':
            renderEditBtn = editButtons['email'];
            break;
          default:
        }

        return (
          <InfoWithLabel
            info={info}
            label={label}
            {...infoColProps}
            key={index}
            editIconRender={isEditMode && renderEditBtn}
          />
        );
      })}
      <>
        <EditUserEmail
          visible={showEditEmail}
          onClose={() => setShowEditEmail(false)}
          onFinishEdit={onFinishEdit}
          email={email}
          userId={userId}
        />
      </>
    </div>
  );
};

export default UserContactInfo;
