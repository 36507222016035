import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Menu } from 'antd';

import { ClearOutlined } from '@ant-design/icons';

import {
  ButtonSmallIcon,
  CustomNotification,
  dialogFunction,
} from 'common/components';

import * as selectorsProduct from 'pages/branded-products/controllers/selectors';

import {
  saveGridConfig as saveGridConfigAction,
  applyGridConfig,
} from 'pages/branded-products/controllers/actions';

import { useApplyPreviewGridConfig } from './hooks';

const ClearApplyGridConfig = (props) => {
  const { defaultConfig } = props;
  const dispatch = useDispatch();

  const { isPreviewGridConfigAcive } = useApplyPreviewGridConfig();

  const { isHaveDefaultConfig } = useSelector(
    selectorsProduct.makeSelectGridConfig()
  );

  const handleClearDefaultGridConfig = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to clear applied config ?',
      onOk: async () => {
        dispatch(applyGridConfig({}));

        if (isHaveDefaultConfig) {
          const { jsonConfig, selectedColumns } = defaultConfig || {};

          dispatch(
            saveGridConfigAction({
              jsonConfig,
              selectedColumns,
              test: true,
            })
          );

          CustomNotification.success(
            'Cleared applied config successfully! Default config applied'
          );
        } else {
          dispatch(
            saveGridConfigAction({
              jsonConfig: null,
              selectedColumns: null,
            })
          );

          CustomNotification.success(
            'Cleared applied config successfully! System default config applied'
          );
        }
      },
    });
  };

  return (
    <Menu.Item
      key='clearApplyConfiguration'
      style={{ backgroundColor: 'inherit', padding: 0 }}
    >
      <ButtonSmallIcon
        className='button-item-dropdown'
        icon={<ClearOutlined />}
        label='Clear Applied Grid Configuration'
        disabled={!isPreviewGridConfigAcive}
        onClick={handleClearDefaultGridConfig}
      />
    </Menu.Item>
  );
};

export default ClearApplyGridConfig;
