import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { SnippetsOutlined } from '@ant-design/icons';
import { Badge, Popover, Table } from 'antd';

import * as maintenanceFormSelectors from 'pages/maintenance-form/controllers/selectors';

import { useLocation } from 'react-router-dom';

import './SelectedDataPointInfo.less';

const SelectedDataPointInfo = (props) => {
  const { formId, ...restProps } = props;

  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  const selectedDataPointsData = useSelector(
    maintenanceFormSelectors.makeSelectSelectDataPointsData()
  );

  const handleVisibleChange = (newVisible) => {
    if (selectedDataPointsData?.length === 0) return;
    setVisible(newVisible);
  };

  const colDef = useMemo(() => {
    let nextCol = [
      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        width: 200,
        ellipsis: true,
      },
      {
        title: 'Alias',
        dataIndex: 'alias',
        key: 'alias',
        width: 200,
        ellipsis: true,
      },
    ];

    if (pathname.indexOf('excel')) {
      nextCol = [
        ...nextCol,
        {
          title: 'Sheet Name',
          dataIndex: 'sheetname',
          key: 'sheetname',
          width: 200,
          ellipsis: true,
        },
      ];
    }

    return nextCol;
  }, [pathname]);

  const content = useMemo(() => {
    return (
      <Table
        className='selected-data-point-info-list'
        columns={colDef}
        dataSource={selectedDataPointsData}
        style={{ width: 700 }}
        scroll={{ y: 500, x: 650 }}
        pagination={false}
      />
    );
  }, [selectedDataPointsData, colDef]);

  return (
    <Popover
      title='Selected data point list'
      trigger='click'
      content={content}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <RibbonButton
        icon={
          <Badge count={selectedDataPointsData?.length} offset={[0, 4]}>
            <SnippetsOutlined />
          </Badge>
        }
        label='Selected Data Point'
        style={{ width: 106 }}
        {...restProps}
      />
    </Popover>
  );
};

export default SelectedDataPointInfo;
