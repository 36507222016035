import React from 'react';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useIntl } from 'react-intl';

import RibbonButton from 'common/components/button/RibbonButton';
import { dialogFunction } from 'common/components/index';

import Messages from 'i18n/messages/maintenance';

import * as articleSelectors from 'pages/articles/controllers/selectors';

import { deleteArticlesService } from 'services/helpMaintenance';

import { forwardTo } from 'utils/common/route';

const DeleteArticle = ({ disabled }) => {
  const intl = useIntl();
  const articleId = useSelector(articleSelectors.selectIdArticle());

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  const callApiDelete = async () => {
    try {
      const response = await deleteArticlesService({
        articleIds: [articleId],
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        forwardTo(`/maintenance/help/articles`);
        showSuccessMessageDialog();
      } else {
        showErrorMessageDialog(message);
      }
    } catch (error) {
      showErrorMessageDialog(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const showErrorMessageDialog = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.deleteArticle}
      disabled={disabled}
      onClick={confirmDelete}
    />
  );
};

export default DeleteArticle;
