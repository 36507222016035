import React from 'react';

import { Tooltip, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/common';

const EditButton = (props) => {
  const intl = useIntl();
  const { className, onClick, title, style, tooltipProps, ...rest } = props;

  const defaultTitle = intl.formatMessage(Messages.editBtn);

  return (
    <Tooltip title={title || defaultTitle} {...tooltipProps}>
      <Button
        className={className ? className : ''}
        icon={<EditOutlined />}
        type='link'
        onClick={onClick}
        style={{
          color: 'rgba(0, 0, 0, 0.85)',
          ...style,
        }}
        {...rest}
      />
    </Tooltip>
  );
};

export default EditButton;
