import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Input, InputNumber, Select } from 'antd';

import { WrapperSelect } from 'common/components';

import { useWindowSize } from 'hooks/windowSize';

let searchTimeout;

const NUMERIC_TYPE = ['int32', 'int64', 'double', 'decimal', 'long', 'int'];
const RenderValueBetween = (props) => {
  const {
    property,
    propertyEnums,
    handleQueryCondition,
    queryConditionsId,
    handleQueryDateBetween,
    acceptableValueSelect,
  } = props;

  const [valueInput, setValueInput] = useState(property?.value);
  const [otherValueInput, setOtherValueInput] = useState(property?.otherValue);

  const [screenWidth, screenHeight] = useWindowSize();

  const debounceUpdateValue = (...arg) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleQueryCondition(...arg);
    }, 300);
  };

  const handleUpdateValue = (value, key) => {
    key === 'value' ? setValueInput(value) : setOtherValueInput(value);
    debounceUpdateValue(property?.valuePath, value, key);
  };

  const listSelect =
    acceptableValueSelect?.length > 0 ? acceptableValueSelect : propertyEnums;

  const sortedList = (() => {
    const [firstValue] = listSelect;

    if (typeof firstValue === 'string') return listSelect?.sort();

    if (listSelect?.length > 0)
      return _.sortBy(listSelect, ['enumDisplayName', 'enumCode']);

    return [];
  })();

  const dataType = property?.dataType?.toLowerCase();
  const showCheckbox = dataType === 'boolean' || dataType === 'bool';

  useEffect(() => {
    setValueInput(property?.value);
    setOtherValueInput(property?.otherValue);
  }, [property?.value, property?.otherValue]);

  return (
    <>
      {property?.dataType === 'string' && (
        <div>
          {sortedList.length === 0 ? (
            <>
              <Input
                size='small'
                value={valueInput}
                placeholder='Enter start value'
                className='query-condition-draggable__between'
                onChange={(e) => {
                  handleUpdateValue(e.target.value, 'value');
                }}
              />
              <Input
                size='small'
                value={otherValueInput}
                placeholder='Enter end value'
                className='query-condition-draggable__between'
                onChange={(e) => {
                  handleUpdateValue(e.target.value, 'otherValue');
                }}
              />
            </>
          ) : (
            <WrapperSelect
              size='small'
              allowClear={false}
              getPopupContainer={() =>
                document.getElementById(queryConditionsId)
              }
              showSearch
              optionFilterProp='children'
              listHeight={screenHeight <= 768 ? 70 : 150}
              className='query-condition-draggable__input'
              placeholder='Please select value'
              onChange={(value) => {
                handleQueryCondition(property?.valuePath, value, 'value');
              }}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
            >
              {sortedList.map((item, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={item?.enumDisplayName ?? item}
                  >
                    {item.enumDisplayName ?? item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          )}
        </div>
      )}
      {property?.dataType === 'datetime' && (
        <>
          <DatePicker.RangePicker
            allowClear={false}
            defaultValue={[
              property?.value ? moment(property?.value) : null,
              property?.otherValue ? moment(property?.otherValue) : null,
            ]}
            className='query-condition-draggable__input'
            size='small'
            onChange={(dates, dateStrings) => {
              handleQueryDateBetween(property?.valuePath, dateStrings);
            }}
          />
        </>
      )}
      {NUMERIC_TYPE.includes(property?.dataType.toLowerCase()) && (
        <>
          <InputNumber
            size='small'
            value={valueInput}
            placeholder='Enter start value'
            className='query-condition-draggable__between'
            onChange={(value) => {
              handleUpdateValue(value, 'value');
            }}
          />
          <InputNumber
            size='small'
            value={otherValueInput}
            placeholder='Enter end value'
            className='query-condition-draggable__between'
            onChange={(value) => {
              handleUpdateValue(value, 'otherValue');
            }}
          />
        </>
      )}
      {showCheckbox && (
        <Checkbox
          className='query-condition-draggable__input-checkbox'
          checked={otherValueInput}
          onChange={(e) => {
            handleUpdateValue(e.target.checked, 'otherValue');
          }}
        />
      )}
    </>
  );
};

export default RenderValueBetween;
