import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Tooltip } from 'antd';

import _ from 'lodash';

import { Space, notification } from 'antd';
import SharedIcon from 'common/components/thumb/share/SharedIcon';
import DigitalRightIcon from 'common/components/digital-right-icon/DigitalRightIcon';

import { Images } from 'config/assets';

import { IconFolderAsset } from 'common/components/folder';
import customCellComponents from 'pages/folders/components/custom-cell-components';

import { getFolderShortDetail } from 'services/folder';
import * as actionsGlobal from 'redux/global/actions';
import * as folderActions from '../controllers/actions';

import { formatSizeUnits } from 'utils/formatSizeUnits';
import { formatSizeUnitByParam } from 'utils/formatSizeUnits';
import { entityTypeIcon } from 'utils/entityTypeIcon';
import { formatMDY } from 'utils/formatDate';
import { formatMDYWithParam } from 'utils/formatDate';

import { getNodeTreeData } from 'common/components/folder/utils';

import emptyFolderIcon from 'assets/folder/empty-folder.png';
import folderIcon from 'assets/folder/folder.png';
import { CustomNotification } from 'common/components';
import { sleep } from 'utils/delay';
import { forwardTo } from 'utils/common/route';
import { useGridView } from 'hooks/useGridView';
import { useDownloadFolderSetting } from 'hooks/useDownloadFolderSetting';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import folderMessages from 'i18n/messages/folder';

// Hooks

const useFetchFolder = (id) => {
  const [state, setState] = React.useReducer((_, action) => action, {
    isLoading: true,
    isReloading: false,
  });

  const { getDownloadSetting } = useDownloadFolderSetting();

  const fetchFolder = () => {
    if (!id) return;
    setState({ ...state, isReloading: true });
    getFolderShortDetail({ id, IsLoadContent: true })
      .then((res) => {
        if (res.isSuccess) {
          setState({
            isLoading: false,
            isReloading: false,
            isSuccess: true,
            data: res.data,
          });
        } else {
          notification.error({ message: res?.message || 'Folder not found' });
          forwardTo('/folders');
        }
      })
      .catch((error) => {
        notification.error({
          message: error?.message || 'Folder not found',
        });
        forwardTo('/folders');
      });
  };

  const reFetchingFolderShortDetail = () => {
    fetchFolder();
  };

  const handleLoading = () => {
    setState({ ...state, isReloading: true });
  };

  const updateFolderData = (data) => {
    setState({
      isLoading: false,
      isReloading: false,
      isSuccess: true,
      data: data,
    });
  };

  React.useEffect(() => {
    fetchFolder();
  }, [+id]);

  return {
    ...state,
    reFetchingFolderShortDetail,
    updateFolderData,
    handleLoading,
    setState,
    state,
  };
};

// const option = RIBBON_OPTIONS.BLANK;
const NAME_GRID = 'folder-detail-grid';

const useInitialComponent = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    // dispatch(actionsGlobal.changeRibbonActions(option));
    // dispatch(folderActions.getColumnsFolderDetails(NAME_GRID));
    dispatch(actionsGlobal.updateIsOnlyDetailGird(true));
    dispatch(actionsGlobal.hideSearchBar());
    return () => {
      dispatch(actionsGlobal.updateIsOnlyDetailGird(false));
    };
  }, [dispatch]);
};

//
const folderInfoFields = [
  { label: 'Owner', value: 'ownerName' },
  { label: '# Items', value: 'items' },
  { label: 'Date Created', value: 'dateCreated', type: 'Date' },
  { label: 'Last Updated', value: 'lastUpdated', type: 'Date' },
  { type: 'icon' },
];

const renderValueFolderInfo = (field, folder) => {
  if (!folder) return null;

  if (field.type === 'Date') {
    return formatMDY(folder[`${field.value}`]);
  }

  if (field.value === 'estSize') {
    return formatSizeUnits(folder[field.value]);
  }

  if (field.type === 'icon') return renderFolderIcon(folder);

  return folder[`${field.value}`];
};

const renderFolderIcon = (folder) => {
  return (
    <Space>
      {!folder?.isOwner ? <SharedIcon dataDetail={folder} /> : null}
      {folder?.drm ? <DigitalRightIcon type='Folder' size='small' /> : null}
    </Space>
  );
};

const getImageFolder = (folder) => {
  if (!folder) return;
  const { items, isOwner } = folder;

  // if (!isOwner) {
  //   return Images.sharedFolder;
  // }

  if (items > 0) {
    return Images.folder;
  }

  return Images.emptyFolder;
};

const checkOwnerFolder = (folder) => {
  return folder?.isOwner;
};

const renderPathname = (type) => {
  switch (type?.toLowerCase()) {
    case 'asset':
    case 'document':
      return 'asset';

    case 'member':
      return 'company';

    case 'reporting':
      return 'reporting';

    case 'product':
      return 'product';

    case 'folder':
      return 'folder';
    case 'query':
      return 'execute-query';

    default:
      return '';
  }
};

const updateNode = (treeData, node, folderId) => {
  return treeData?.map((nodeItem) => {
    if (nodeItem.data.id === folderId) {
      const imageFolder =
        node.data.subFolderCount > 0 ? folderIcon : emptyFolderIcon;
      return {
        ...node,
        data: { ...node.data },
        key: nodeItem.key,
        isLeaf: node.data.subFolderCount === 0 ? true : false,
        children: nodeItem.children,
        icon: <IconFolderAsset src={imageFolder} />,
      };
    } else if (nodeItem?.children && nodeItem?.children.length > 0) {
      return {
        ...nodeItem,
        children: updateNode(nodeItem.children, node, folderId),
      };
    }
    return nodeItem;
  });
};

const addNodeToTree = (tree, node, idParent, subFolderCount) => {
  return tree.map((parentNode) => {
    if (parentNode.data.id === idParent) {
      const childOfNodeParent = parentNode?.children ?? [];
      return {
        ...parentNode,
        data: { ...parentNode.data, subFolderCount },
        children: childOfNodeParent.concat(node),
        isLeaf: false,
        icon: <IconFolderAsset src={folderIcon} />,
      };
    } else if (parentNode?.children && parentNode?.children.length > 0) {
      return {
        ...parentNode,
        children: addNodeToTree(
          parentNode.children,
          node,
          idParent,
          subFolderCount
        ),
      };
    }
    return parentNode;
  });
};

const deleteSubNodesToTree = (tree, ids) => {
  return tree
    .map((item) => {
      if (ids.includes(item.data.id)) {
        return null;
      } else if (item?.children && item?.children.length > 0) {
        return {
          ...item,
          children: deleteSubNodesToTree(item?.children, ids),
        };
      }
      return item;
    })
    .filter((item) => item)
    .map((item) => {
      if (item?.children && item?.children.length === 0) {
        delete item.children;
        return {
          ...item,
          icon: <IconFolderAsset src={emptyFolderIcon} />,
          isLeaf: true,
        };
      }
      return item;
    });
};

export const getParentFolder = (id, tree) => {
  let parentNode;
  for (let i = 0; i < tree?.length; i++) {
    const node = tree[i];
    if (node?.children) {
      if (node?.children?.some((item) => item.id === id)) {
        parentNode = node;
      } else if (getParentFolder(id, node.children)) {
        parentNode = getParentFolder(id, node.children);
      }
    }
  }
  return parentNode;
};

const defaultFolderParam = {
  renderFolder: (node) => {
    const isEmptyContents = node && node.subFolderCount === 0;

    if (isEmptyContents) {
      return {
        icon: <IconFolderAsset src={emptyFolderIcon} />,
        isLeaf: true,
      };
    }

    return {};
  },
};

const useReloadFolderActionWhenSuccess = ({
  treeData,
  currentFolder,
  handleUpdateTreeData,
  handleSelectNodeById,
  handleSetStatusTree,
  viewFolderContents,
  handleGetDataList,
}) => {
  const reloadPage = useDispatchReloadPage();
  const { clearGridSelection } = useGridView();
  const intl = useIntl();

  const reloadFolderWhenCreateSuccess = async (idFolder) => {
    try {
      handleSetStatusTree('loading');
      const data = await getFolderShortDetail({
        id: idFolder,
        IsLoadContent: false,
      });
      const dataParent = await getFolderShortDetail({
        id: currentFolder.id,
        IsLoadContent: false,
      });

      const subFolderCountParent = dataParent.data.subFolderCount;

      const node = getNodeTreeData({
        node: { ...data.data, type: 'folder' },
        folderParam: defaultFolderParam,
      });

      const newTree = addNodeToTree(
        treeData,
        node,
        currentFolder.id,
        subFolderCountParent
      );

      handleUpdateTreeData(newTree);

      await sleep(100);
      handleSelectNodeById(idFolder.toString());
      handleSetStatusTree('success');

      CustomNotification.success(
        intl.formatMessage(folderMessages.createFolderSuccessMessage)
      );
    } catch (error) {
      CustomNotification.success(
        intl.formatMessage(folderMessages.createFolderFailedMessage)
      );
      handleSetStatusTree('success');
    }
  };

  const reloadFolderWhenEditSuccess = async (idFolder, updateFolderData) => {
    handleSetStatusTree('loading');
    await sleep(500);
    const folderDetail = await getFolderShortDetail({
      id: idFolder,
      IsLoadContent: false,
    });
    const newNode = getNodeTreeData({
      node: { ...folderDetail.data, type: 'folder' },
      folderParam: false,
      fileParam: false,
    });

    const newTree = updateNode(treeData, newNode, idFolder);
    handleSelectNodeById(currentFolder?.id);
    handleUpdateTreeData(newTree);

    updateFolderData(folderDetail.data);

    handleSetStatusTree('success');

    viewFolderContents === 'grid' ? reloadPage() : handleGetDataList(20, 1, '');
  };

  const reloadFolderWhenDeleteSuccess = async (
    idFolders,
    reFetchingFolderShortDetail,
    idSelected,
    level
  ) => {
    let successMessage = folderMessages.deleteItemsSuccessMessage;

    handleSetStatusTree('loading');
    await sleep(500);
    if (
      idFolders.length === 1 &&
      idFolders?.[0].toString() === idSelected.toString() &&
      level === 1
    ) {
      CustomNotification.success(intl.formatMessage(successMessage));
      handleSetStatusTree('success');
      forwardTo('/folders/owned');
      return;
    }

    if (idFolders.length === 1 && idFolders?.[0] === currentFolder.id) {
      const hierarchyList = currentFolder.hierarchy?.split('/');

      const newTree = deleteSubNodesToTree(treeData, idFolders);
      handleUpdateTreeData(newTree);

      const prevFolderId = hierarchyList[hierarchyList.length - 2];
      handleSelectNodeById(prevFolderId);

      CustomNotification.success(intl.formatMessage(successMessage));

      handleSetStatusTree('success');
      clearGridSelection();
      return;
    }

    await sleep(500);
    const newTree = deleteSubNodesToTree(treeData, idFolders);
    handleUpdateTreeData(newTree);
    reFetchingFolderShortDetail();
    CustomNotification.success(intl.formatMessage(successMessage));

    if (viewFolderContents === 'thumbnails') handleGetDataList(20, 1, '');
    handleSetStatusTree('success');
    clearGridSelection();
    reloadPage();
  };

  return {
    reloadFolderWhenCreateSuccess,
    reloadFolderWhenEditSuccess,
    reloadFolderWhenDeleteSuccess,
  };
};

const defaultColProperties = {
  resizable: true,
};

const formatColumns = (columns) => {
  if (!columns?.length) return null;

  const checkboxColumn = [
    {
      field: '',
      checkboxSelection: true,
      filter: false,
      suppressMenu: true,
      sortable: false,
    },
  ];

  const excludeColumns = ['id', 'thumbnail'];

  const columnsFilter = columns.filter(
    (column) => excludeColumns.indexOf(column?.fieldNameCamelCase) === -1
  );

  const fieldNameCamelCaseMapping = columnsFilter?.map(
    (item) => item.fieldNameCamelCase
  );

  const renderEntityTypeIcon = (item) => {
    return (
      <Tooltip title={_.capitalize(item?.data?.type)}>
        {entityTypeIcon(item?.data?.type)}
      </Tooltip>
    );
  };

  const formattedColumns = fieldNameCamelCaseMapping.map((showCol) => {
    const colData = columns.find(
      (dataCol) => dataCol?.fieldNameCamelCase === showCol
    );

    switch (showCol) {
      case 'type':
        return {
          ...colData,
          ...defaultColProperties,
          cellRenderer: renderEntityTypeIcon,
          filter: false,
          suppressMenu: true,
          width: 80,
        };
      case 'description':
        return {
          ...colData,
          ...defaultColProperties,
          minWidth: 150,
          resizable: true,
          flex: 1,
          cellRenderer: customCellComponents.renderLink,
        };

      case 'size':
        return {
          ...colData,
          ...defaultColProperties,
          width: 140,
          resizable: true,
          fieldName: 'Size',
          allowFilter: false,
          cellRenderer: formatSizeUnitByParam,
        };

      case 'lastUpdated':
        return {
          ...colData,
          ...defaultColProperties,
          flex: 1,
          minWidth: 150,
          valueFormatter: formatMDYWithParam,
          resizable: true,
        };

      case 'dateCreated':
        return {
          ...colData,
          ...defaultColProperties,
          flex: 1,
          minWidth: 150,
          valueFormatter: formatMDYWithParam,
          resizable: true,
        };

      case 'entityVisibilityType':
        return {
          ...colData,
          displayName: 'Visibility',
        };

      default:
        return {
          ...colData,
          ...defaultColProperties,
        };
    }
  });

  return checkboxColumn.concat(formattedColumns);
};

export {
  folderInfoFields,
  renderValueFolderInfo,
  getImageFolder,
  checkOwnerFolder,
  renderPathname,
  useFetchFolder,
  useInitialComponent,
  updateNode,
  addNodeToTree,
  deleteSubNodesToTree,
  defaultFolderParam,
  useReloadFolderActionWhenSuccess,
  formatColumns,
};
