import React, { useState, useImperativeHandle, forwardRef } from 'react';

import { Row, Col, Button, Space, Typography, List } from 'antd';

import { DialogModal, Form, BorderButton } from 'common/components';
import SupplementNutrientItemView from './SupplementNutrientItemView';
import { getNameAndItalicizeNameSupplement } from 'pages/product-full-view/components/product-media-area/supplement-label/utils';

const { Paragraph, Text } = Typography;

const SupplementDeleteConfirmation = forwardRef((props, forwardedRef) => {
  const form = Form.useFormInstance();

  const { type, data, fieldName } = props;

  const { name } = getNameAndItalicizeNameSupplement(data, fieldName);

  const nutrientList = Form.useWatch(fieldName, form);

  const [visible, setVisible] = useState(false);

  const closeModal = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const getChildrenItems = () => {
    if (!nutrientList?.length) return [];

    return nutrientList?.filter((nutrientItem) => {
      return nutrientItem?.ingredientsPartOf === name;
    });
  };

  const checkShowDeleteConfirm = () => {
    //* show confirm when item deleted is parent of others
    return childrenItems.length > 0;
  };

  const removeAllItem = () => {
    form.setFieldsValue({
      [fieldName]: nutrientList.filter((nutrientItem) => {
        const { name: nutrientItemName } = getNameAndItalicizeNameSupplement(
          nutrientItem,
          fieldName
        );

        const isChildren = childrenItems.some((child) => {
          const { name: childName } = getNameAndItalicizeNameSupplement(child, fieldName);
          return childName === nutrientItemName;
        });

        const isSelf = nutrientItemName === name;

        return !isChildren && !isSelf;
      }),
    });
  };

  const removeAndKeepItems = () => {
    form.setFieldsValue({
      [fieldName]: nutrientList.reduce((accumulator, nutrientItem) => {
        const { name: nutrientItemName } = getNameAndItalicizeNameSupplement(
          nutrientItem,
          fieldName
        );
        const isChildren = childrenItems.some((child) => {
          const { name: childName } = getNameAndItalicizeNameSupplement(child, fieldName);
          return childName === nutrientItemName;
        });

        if (isChildren)
          return [...accumulator, { ...nutrientItem, ingredientsPartOf: null }];

        const isSelf = nutrientItemName === name;

        if (isSelf) return accumulator;

        return [...accumulator, nutrientItem];
      }, []),
    });
  };

  const onClickCancel = () => {
    closeModal();
  };

  const onClickRemoveAll = () => {
    closeModal();

    removeAllItem();
  };

  const onClickKeepItems = () => {
    closeModal();

    removeAndKeepItems();
  };

  useImperativeHandle(forwardedRef, () => {
    return {
      showModal,
      shouldShowModal: checkShowDeleteConfirm(),
    };
  });

  const childrenItems = getChildrenItems();

  return (
    <DialogModal
      visible={visible}
      type='warn'
      bodyStyle={{
        paddingTop: 30,
      }}
      onCancel={closeModal}
      centered={false}
      closeable={false}
      footer={
        <Space>
          <Button onClick={onClickCancel}>Cancel</Button>
          <BorderButton onClick={onClickRemoveAll}>
            Remove all items
          </BorderButton>
          <Button type='primary' onClick={onClickKeepItems}>
            Delete and keep items
          </Button>
        </Space>
      }
    >
      <Paragraph style={{ fontSize: 16, textAlign: 'center' }}>
        <Text style={{ display: 'block' }}>
          The following {type}(s) is part of this {type}
        </Text>
      </Paragraph>

      <Row justify='center' style={{ marginBottom: 10 }}>
        <Col span={20}>
          <List
            bordered
            size='small'
            dataSource={childrenItems}
            renderItem={(listItem) => {
              return (
                <List.Item>
                  <Col span={24}>
                    <SupplementNutrientItemView
                      item={{ ...listItem, ingredientsPartOf: undefined }}
                      fieldName={fieldName}
                    />
                  </Col>
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>

      <Paragraph style={{ fontSize: 16, textAlign: 'center', marginBottom: 8 }}>
        <Text>Are you sure you want to delete this {type}?</Text>
      </Paragraph>
    </DialogModal>
  );
});

export default SupplementDeleteConfirmation;
