import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { List, Typography } from 'antd';
import { RoleSectionWrapper, RoleEmptySection } from '../';
import { dialogFunction } from 'common/components';

import * as roleActions from 'pages/role/controllers/actions';

import usePrevious from 'hooks/usePrevious';
import { forwardTo } from 'utils/common/route';
import classnames from 'classnames';
import { isEmpty, isEqual } from 'lodash';

import './styles.less';

const { Text } = Typography;

const RoleList = (props) => {
  const {
    title,
    roleListData,
    selectedRole,
    roleIdParams,
    memberIdParams,
    isRoleExistInList,
    isChanged,
    isCreateNew,
    formRole,
    viewType,
    refEnableAutoScroll,
    preventUpdateSelectedRole,
    onUpdateSelectedItem,
    roleItemIdPrefix,
  } = props;

  const prevSelectedRole = usePrevious(selectedRole);
  const prevRoleListData = usePrevious(roleListData);

  const dispatch = useDispatch();
  const history = useHistory();

  const confirmClickRoleItem = async (roleData) => {
    if (roleIdParams) {
      const handleClickRoleItem = () => {
        //* edit view
        let redirectLink = '';

        dispatch(roleActions.setSelectedRole('loading'));
        formRole.resetAll();

        if (memberIdParams) {
          redirectLink = `/security/role/${roleData?.id}/member/${memberIdParams}/edit`;
        } else {
          redirectLink = `/security/role/${roleData?.id}/edit`;
        }
        isCreateNew ? history.replace(redirectLink) : forwardTo(redirectLink);
      };

      isChanged
        ? dialogFunction({
            type: 'warn',
            content:
              'Do you want to cancel your edit content and start to edit another role?',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: handleClickRoleItem,
          })
        : handleClickRoleItem();
    } else {
      //* management view
      dispatch(roleActions.setSelectedRole(roleData));
    }
  };

  const setDefaultSelectedRole = ({ roleListData }) => {
    if (preventUpdateSelectedRole) return;
    dispatch(roleActions.setSelectedRole(roleListData?.[0]));
  };

  const setEditRoleToSelectedRole = ({ roleListData, roleIdParams }) => {
    const foundRole = roleListData.find(
      (role) => role?.id === Number(roleIdParams)
    );
    dispatch(roleActions.setSelectedRole(foundRole));
  };

  const setEditNewRoleToSelectedRole = ({ roleListData }) => {
    const foundRole = roleListData.find((role) => role?.id === 'new');
    dispatch(roleActions.setSelectedRole(foundRole));
  };

  useEffect(() => {
    //* mamagement view
    if (!roleIdParams && roleListData && !selectedRole) {
      //* if not back from edit page
      if (!refEnableAutoScroll?.current) {
        return setDefaultSelectedRole({ roleListData });
      }
    }

    //*edit view
    if (
      roleIdParams &&
      roleListData &&
      selectedRole?.id !== Number(roleIdParams) &&
      isRoleExistInList &&
      roleIdParams !== 'new'
    ) {
      return setEditRoleToSelectedRole({ roleListData, roleIdParams });
    }

    //*create new view
    if (
      roleIdParams &&
      roleListData &&
      selectedRole?.id !== 'new' &&
      isRoleExistInList &&
      roleIdParams === 'new'
    ) {
      return setEditNewRoleToSelectedRole({ roleListData });
    }

    //* notice if roleListData change force to reselectRole
    if (roleListData && !isEqual(roleListData, prevRoleListData)) {
      return setEditRoleToSelectedRole({ roleListData, roleIdParams });
    }
  }, [roleListData, selectedRole, roleIdParams, prevRoleListData]);

  useEffect(() => {
    const scrollToActiveRole = ({ selectedRole }) => {
      const $activeRole = document.getElementById(`#role-${selectedRole?.id}`);
      if ($activeRole) {
        $activeRole.scrollIntoView();
      }
    };

    //* handle auto scroll to selected role
    const autoScrollToSelectedRole = () => {
      if (viewType === 'role-edit') {
        if (
          (selectedRole && roleIdParams && isEmpty(prevSelectedRole)) ||
          roleIdParams === 'new'
        ) {
          scrollToActiveRole({ selectedRole });
        }
      }

      if (viewType === 'role-management') {
        if (selectedRole && refEnableAutoScroll?.current) {
          scrollToActiveRole({ selectedRole });
          refEnableAutoScroll.current = false;
        }
      }
    };

    autoScrollToSelectedRole();
  }, [selectedRole, prevSelectedRole, roleIdParams, viewType]);

  useEffect(() => {
    onUpdateSelectedItem && onUpdateSelectedItem(selectedRole);
  }, [selectedRole]);

  const mappingRoleListData = [...(roleListData ?? [])].sort((a, b) => {
    return a?.isStandardRole === b?.isStandardRole
      ? 0
      : a.isStandardRole
      ? -1
      : 1;
  });

  return (
    <RoleSectionWrapper
      className='role-list-section__wrap'
      title={title}
      style={{ height: '100%' }}
    >
      <div className='role-list-section scroller' style={{ height: '100%' }}>
        {mappingRoleListData && mappingRoleListData.length > 0 ? (
          <List
            itemLayout='horizontal'
            dataSource={mappingRoleListData || []}
            renderItem={(item) => {
              const isStandardRole = item?.isStandardRole;
              const isSelectedRole = item?.id === selectedRole?.id;
              const isNewRole = item?.id === 'new';
              const isReadOnlyRole = item?.isReadOnly;

              const roleName = isSelectedRole
                ? roleIdParams
                  ? formRole?.values?.displayName
                  : item?.displayName
                : item?.displayName;

              return (
                <List.Item
                  className={classnames('role-list-section__item', {
                    'role-list-section__item--selected': isSelectedRole,
                    'role-list-section__item--new': isNewRole,
                    'role-list-section__item--read-only': isReadOnlyRole,
                  })}
                  onClick={() => confirmClickRoleItem(item)}
                  id={`#${roleItemIdPrefix ? roleItemIdPrefix + '-' : ''}role-${
                    item?.id || 'new'
                  }`}
                >
                  <List.Item.Meta
                    avatar={
                      <div
                        className={classnames('role-list-section__item-node', {
                          'role-list-section__item-node--custom':
                            !isStandardRole,
                        })}
                      />
                    }
                    title={
                      <Text ellipsis style={{ width: '100%' }}>
                        {roleName}
                      </Text>
                    }
                  />
                </List.Item>
              );
            }}
          />
        ) : (
          <RoleEmptySection />
        )}
      </div>
    </RoleSectionWrapper>
  );
};

export default RoleList;
