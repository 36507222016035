import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';

import { ClearOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import { dialogFunction } from 'common/components/index';

const ClearQueryControl = ({ onClick }) => {
  const showConfirmMessage = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to clear Query Conditions?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onConfirm,
    });
  };

  const onConfirm = () => {
    onClick && onClick();
  };

  return (
    <ButtonSmallIcon
      icon={<ClearOutlined />}
      label={Messages.clearQuery}
      onClick={showConfirmMessage}
    />
  );
};

ClearQueryControl.propTypes = {
  clearQuery: PropTypes.func,
};

export default ClearQueryControl;
