import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import {
  FieldStringOutlined,
  CalendarOutlined,
  CheckOutlined,
  NumberOutlined,
  CloseOutlined,
  FileFilled,
} from '@ant-design/icons';
import {
  Row,
  Switch,
  Button,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Typography,
  Checkbox,
  Avatar,
  Tooltip,
} from 'antd';

import { WrapperSelect } from 'common/components';
import ModalUploadAdvancedFile from './ModalUploadAdvancedFile';

import { useWindowSize } from 'hooks/windowSize';

import moment from 'moment';
import * as constants from 'static/Constants';
import classnames from 'classnames';

import { isObject, isArray } from 'lodash';
import './EntityDraggable.less';
const primaryButton = 0;

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

const QueryDraggable = (props) => {
  const {
    task,
    index,
    isSelected,
    selectionCount,
    isGhosting,
    isHiddenDrag,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    handleRemoveQueryConditions,
    handleQueryCondition,
    handleQueryValue,
    handleConjunction,
    handleQueryOtherValue,
    viewMode,
    advancedSearchGridView,
    queryConditionsId,
    handleQueryDateBetween,
    handleUploadAdvancedFile,
    handleQueryValueConditionIn,
  } = props;

  const [screenWidth, screenHeight] = useWindowSize();

  const [visibleModal, setVisibleModal] = useState(false);

  const handleModalUploadAdvancedFile = () => {
    setVisibleModal(true);
  };

  const renderValue = (task) => {
    let acceptableValueSelect = [...task?.acceptableValue] || [];
    if (acceptableValueSelect && acceptableValueSelect.length > 0) {
      acceptableValueSelect.sort();
    }

    return (
      <>
        {task?.dataType === 'string' &&
          task?.acceptableValue &&
          task?.acceptableValue.length === 0 && (
            <Input
              value={task?.value}
              placeholder='Enter value'
              style={{
                width: 200,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(e) => {
                handleQueryValue(task?.id, e.target.value);
              }}
            />
          )}
        {task?.dataType === 'string' &&
          task?.acceptableValue &&
          task?.acceptableValue.length > 0 && (
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById(queryConditionsId)
              }
              showSearch
              optionFilterProp='children'
              value={task?.value}
              listHeight={screenHeight <= 768 ? 70 : 150}
              style={{
                width: 200,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(value) => {
                handleQueryValue(task?.id, value);
              }}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
            >
              {acceptableValueSelect.map((item, index) => {
                if (isObject(item)) {
                  return (
                    <Select.Option key={index} value={item?.value}>
                      {item?.label}
                    </Select.Option>
                  );
                }
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          )}
        {task?.dataType === 'datetime' && (
          <DatePicker
            defaultValue={task?.value ? moment(task?.value) : null}
            placeholder='Select date'
            style={{
              width: 200,
              flex: advancedSearchGridView ? 1 : 'none',
              margin: 4,
            }}
            onChange={(date, dateString) => {
              handleQueryValue(task.id, dateString);
            }}
          />
        )}
        {(task?.dataType === 'int' ||
          task?.dataType === 'double' ||
          task?.dataType === 'double?' ||
          task?.dataType === 'long' ||
          task?.dataType === 'decimal') && (
          <InputNumber
            value={task?.value}
            placeholder='Enter value'
            style={{
              width: 200,
              flex: advancedSearchGridView ? 1 : 'none',
              margin: 4,
            }}
            onChange={(value) => {
              handleQueryValue(task?.id, value);
            }}
          />
        )}
        {task?.dataType === 'bool' && (
          <Checkbox
            checked={task?.value}
            style={{
              width: 200,
              flex: advancedSearchGridView ? 1 : 'none',
              margin: 4,
            }}
            onChange={(e) => {
              handleQueryValue(task.id, e.target.checked);
            }}
          />
        )}
      </>
    );
  };

  const renderValueBetween = (task) => {
    let acceptableValueSelect = [...task?.acceptableValue] || [];
    if (acceptableValueSelect && acceptableValueSelect.length > 0) {
      acceptableValueSelect.sort();
    }
    return (
      <>
        {task?.dataType === 'string' &&
          task?.acceptableValue &&
          task?.acceptableValue?.length === 0 && (
            <>
              <Input
                value={task?.value}
                placeholder='Enter start value'
                style={{
                  width: 100,
                  flex: advancedSearchGridView ? 1 : 'none',
                  margin: 4,
                }}
                onChange={(e) => {
                  handleQueryValue(task?.id, e.target.value);
                }}
              />
              <Input
                value={task?.otherValue}
                placeholder='Enter end value'
                style={{
                  width: 100,
                  flex: advancedSearchGridView ? 1 : 'none',
                  margin: 4,
                }}
                onChange={(e) => {
                  handleQueryOtherValue(task?.id, e.target.value);
                }}
              />
            </>
          )}
        {task?.dataType === 'string' &&
          task?.acceptableValue &&
          task?.acceptableValue.length > 0 && (
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById(queryConditionsId)
              }
              showSearch
              optionFilterProp='children'
              // defaultValue={task.acceptableValue[0]}
              listHeight={screenHeight <= 768 ? 70 : 150}
              style={{
                width: 200,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(value) => {
                handleQueryValue(task?.id, value);
              }}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
            >
              {acceptableValueSelect.map((item, index) => {
                if (isObject(item)) {
                  return (
                    <Select.Option key={index} value={item?.value}>
                      {item?.label}
                    </Select.Option>
                  );
                }
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          )}
        {task?.dataType === 'datetime' && (
          <>
            <DatePicker.RangePicker
              defaultValue={[
                task?.value ? moment(task?.value) : null,
                task?.otherValue ? moment(task?.otherValue) : null,
              ]}
              style={{
                width: 200,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(dates, dateStrings) => {
                // handleQueryValue(task?.id, dateStrings[0]);
                // handleQueryOtherValue(task?.id, dateStrings[1]);
                handleQueryDateBetween(task?.id, dateStrings);
              }}
            />
            {/* <DatePicker
              defaultValue={task?.value ? moment(task?.value) : null}
              style={{
                width: 100,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(date, dateString) => {
                handleQueryValue(task?.id, dateString);
              }}
            />
            <DatePicker
              defaultValue={task?.otherValue ? moment(task?.otherValue) : null}
              style={{
                width: 100,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(date, dateString) => {
                handleQueryOtherValue(task?.id, dateString);
              }} 
            />*/}
          </>
        )}
        {(task?.dataType === 'int' ||
          task?.dataType === 'double' ||
          task?.dataType === 'double?' ||
          task?.dataType === 'long' ||
          task?.dataType === 'decimal') && (
          <>
            <InputNumber
              value={task?.value}
              placeholder='Enter start value'
              style={{
                width: 100,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(value) => {
                handleQueryValue(task?.id, value);
              }}
            />
            <InputNumber
              value={task?.otherValue}
              placeholder='Enter end value'
              style={{
                width: 100,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(value) => {
                handleQueryOtherValue(task?.id, value);
              }}
            />
          </>
        )}
        {task?.dataType === 'bool' && (
          <Checkbox
            style={{
              width: 200,
              flex: advancedSearchGridView ? 1 : 'none',
              margin: 4,
            }}
            checked={task?.value}
            onChange={(e) => {
              handleQueryOtherValue(task?.id, e.target.checked);
            }}
          />
        )}
      </>
    );
  };

  const renderValueIn = (task) => {
    let acceptableValueSelect = [...task?.acceptableValue] || [];
    if (acceptableValueSelect && acceptableValueSelect.length > 0) {
      acceptableValueSelect.sort();
    }
    return (
      <>
        {task?.dataType === 'string' &&
          task?.acceptableValue &&
          task?.acceptableValue?.length === 0 && (
            <Input
              value={task?.value}
              placeholder='Enter start value'
              style={{
                width: 100,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(e) => {
                handleQueryValue(task?.id, e.target.value);
              }}
            />
          )}
        {task?.dataType === 'string' &&
          task?.acceptableValue &&
          task?.acceptableValue.length > 0 &&
          task?.acceptableValue.length <= 100000 && (
            <WrapperSelect
              getPopupContainer={() =>
                document.getElementById(queryConditionsId)
              }
              showSearch
              optionFilterProp='children'
              mode='multiple'
              maxTagCount='responsive'
              value={task?.value}
              listHeight={screenHeight <= 768 ? 70 : 150}
              style={{
                width: 200,
                flex: advancedSearchGridView ? 1 : 'none',
                margin: 4,
              }}
              onChange={(value) => {
                handleQueryValue(task?.id, value);
              }}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
            >
              {acceptableValueSelect.map((item, index) => {
                if (isObject(item)) {
                  return (
                    <Select.Option key={index} value={item?.value}>
                      {item?.label}
                    </Select.Option>
                  );
                }
                return (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          )}

        {task?.dataType === 'string' &&
          task?.acceptableValue &&
          task?.acceptableValue.length > 0 &&
          task?.acceptableValue.length > 100000 && (
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: advancedSearchGridView ? 1 : 'none',
                margin: advancedSearchGridView ? '0px 4px' : 4,
              }}
            >
              <WrapperSelect
                getPopupContainer={() =>
                  document.getElementById(queryConditionsId)
                }
                showSearch
                optionFilterProp='children'
                mode='multiple'
                maxTagCount='responsive'
                listHeight={screenHeight <= 768 ? 70 : 150}
                style={{
                  width: advancedSearchGridView ? '100%' : 200,
                  flex: advancedSearchGridView ? 1 : 'none',
                }}
                status='error'
                placeholder={
                  <Typography.Text type='danger' style={{ fontSize: '12px' }}>
                    {task?.acceptableValue.length} items{' '}
                  </Typography.Text>
                }
              ></WrapperSelect>
              <Typography.Text type='danger' style={{ fontSize: '14px' }}>
                Exceed maximum 100,000 items
              </Typography.Text>
            </Row>
          )}
      </>
    );
  };

  const renderBtnUploadFile = (task) => {
    const showBtnUpload =
      task?.possibleOperator?.length === 1 &&
      task?.possibleOperator[0] === 'Equal';

    if (task?.dataType !== 'string' || showBtnUpload) return;

    return (
      <Button
        type={
          task?.possibleOperator && task?.possibleOperator.length === 1
            ? 'primary'
            : 'default'
        }
        icon={<FileFilled />}
        style={{ margin: 4 }}
        onClick={() => {
          if (task?.possibleOperator && task?.possibleOperator.length !== 1) {
            handleModalUploadAdvancedFile();
          } else {
            handleUploadAdvancedFile(null, task?.id, true);
          }
        }}
      />
    );
  };

  return (
    <Draggable
      draggableId={task?.mapFieldName + '_query' + '__' + task?.id}
      index={index}
    >
      {(provided, snapshot) => {
        const shouldShowSelection = snapshot.isDragging && selectionCount > 1;
        let possibleOperator = [...task?.possibleOperator] || [];
        if (possibleOperator?.length > 0) {
          possibleOperator.sort();
        }
        let queryConditionValue =
          task?.queryCondition ||
          (possibleOperator &&
            possibleOperator.length > 0 &&
            possibleOperator[0]);

        return (
          <div>
            <div style={{ display: 'flex' }}>
              {!snapshot.isDragging && task?.level > 0 && (
                <>
                  <div
                    style={{
                      marginLeft: 33 * task?.level,
                      // borderLeft: '1px dotted black',
                      // marginBottom: lastIndex ? 22 : 0,
                    }}
                  ></div>
                  {/* <div
                    style={{
                      borderTop: '1px dotted black',
                      marginLeft: 1,
                      marginTop: 15,
                      width: 8,
                    }}
                  ></div> */}
                </>
              )}
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classnames({
                  'entities-draggable__card': true,
                  'entities-draggable__card-query': true,
                  'entities-draggable__card--selected': isSelected,
                  'entities-draggable__card--ghosting':
                    isGhosting || isHiddenDrag,
                  'entities-draggable__card--drag': snapshot.isDragging,
                  'entities-draggable__card--combine-with':
                    snapshot.combineTargetFor && !isHiddenDrag,
                })}
              >
                {!advancedSearchGridView && (
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: task?.fileOverload ? 'flex-start' : 'center',
                    }}
                  >
                    {index !== 0 && (
                      <Switch
                        checkedChildren={
                          <Typography.Text style={{ color: 'white' }}>
                            AND
                          </Typography.Text>
                        }
                        unCheckedChildren={
                          <Typography.Text style={{ color: 'black' }}>
                            OR
                          </Typography.Text>
                        }
                        checked={
                          task?.conjunction === false ? task?.conjunction : true
                        }
                        onClick={(checked) => {
                          handleConjunction(task?.id, checked);
                        }}
                        style={{
                          width: 60,
                          color:
                            task?.conjunction === false
                              ? 'rgba(0, 0, 0, 0.65)'
                              : '#fff',
                          background:
                            task?.conjunction === false ? '#ccc' : '#2684ff',
                          margin: 4,
                        }}
                      />
                    )}
                    {task?.dataType === 'string' && (
                      <Avatar
                        className='entities-draggable__data-type'
                        shape='square'
                        icon={<FieldStringOutlined />}
                      />
                    )}
                    {task?.dataType === 'datetime' && (
                      <Avatar
                        className='entities-draggable__data-type'
                        shape='square'
                        icon={<CalendarOutlined />}
                      />
                    )}
                    {task?.dataType === 'bool' && (
                      <Avatar
                        className='entities-draggable__data-type'
                        shape='square'
                        icon={<CheckOutlined />}
                      />
                    )}
                    {(task?.dataType === 'int' ||
                      task?.dataType === 'double' ||
                      task?.dataType === 'double?' ||
                      task?.dataType === 'long' ||
                      task?.dataType === 'decimal') && (
                      <Avatar
                        className='entities-draggable__data-type'
                        shape='square'
                        icon={<NumberOutlined />}
                      />
                    )}
                    <Typography.Text
                      className='entities-draggable__label'
                      ellipsis
                      style={{ flex: 1 }}
                    >
                      {task?.parentDisplayName} - {task?.displayName}
                    </Typography.Text>
                    <WrapperSelect
                      getPopupContainer={() =>
                        document.getElementById(queryConditionsId)
                      }
                      showSearch
                      optionFilterProp='children'
                      listHeight={screenHeight <= 768 ? 70 : 150}
                      value={queryConditionValue}
                      style={{ width: 150, margin: 4 }}
                      onChange={(value) => {
                        if (
                          task?.queryCondition === 'In' &&
                          isArray(task?.value) &&
                          task?.value?.length > 1
                        ) {
                          handleQueryValueConditionIn(
                            task?.id,
                            value,
                            task?.value[0]
                          );
                        } else {
                          handleQueryCondition(task?.id, value);
                        }
                      }}
                      filterOption={(input, option) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {possibleOperator.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </WrapperSelect>
                    {queryConditionValue === 'Between' &&
                      renderValueBetween(task)}
                    {queryConditionValue === 'In' && renderValueIn(task)}
                    {(queryConditionValue === 'Is Not Null' ||
                      queryConditionValue === 'Is Null') && (
                      <div style={{ width: 208 }}></div>
                    )}
                    {queryConditionValue !== 'Between' &&
                      queryConditionValue !== 'Is Not Null' &&
                      queryConditionValue !== 'Is Null' &&
                      queryConditionValue !== 'In' &&
                      renderValue(task)}
                    {!viewMode && (
                      <>
                        {renderBtnUploadFile(task)}
                        <Tooltip
                          className='entities-draggable__remove'
                          title='Remove query'
                        >
                          <Button
                            danger
                            type='text'
                            icon={<CloseOutlined />}
                            style={{ margin: 4 }}
                            onClick={() => {
                              handleRemoveQueryConditions(task?.id);
                            }}
                          />
                        </Tooltip>
                        <div className='entities-draggable__keep-remove-new'></div>
                      </>
                    )}
                  </Row>
                )}
                {!!advancedSearchGridView && (
                  <>
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {task?.dataType === 'string' && (
                        <Avatar
                          className='entities-draggable__data-type'
                          shape='square'
                          icon={<FieldStringOutlined />}
                        />
                      )}
                      {task?.dataType === 'datetime' && (
                        <Avatar
                          className='entities-draggable__data-type'
                          shape='square'
                          icon={<CalendarOutlined />}
                        />
                      )}
                      {task?.dataType === 'bool' && (
                        <Avatar
                          className='entities-draggable__data-type'
                          shape='square'
                          icon={<CheckOutlined />}
                        />
                      )}
                      {(task?.dataType === 'int' ||
                        task?.dataType === 'double' ||
                        task?.dataType === 'double?' ||
                        task?.dataType === 'long' ||
                        task?.dataType === 'decimal') && (
                        <Avatar
                          className='entities-draggable__data-type'
                          shape='square'
                          icon={<NumberOutlined />}
                        />
                      )}
                      <Typography.Text
                        className='entities-draggable__label'
                        ellipsis
                        style={{ flex: 1 }}
                      >
                        {task?.parentDisplayName} - {task?.displayName}
                      </Typography.Text>
                    </Row>

                    <Row
                      style={{
                        display: 'flex',
                        alignItems: task?.fileOverload
                          ? 'flex-start'
                          : 'center',
                        height: task?.fileOverload ? 44 : 36,
                        margin: task?.fileOverload ? '4px 0px' : '0px',
                      }}
                    >
                      {index !== 0 && (
                        <Switch
                          checkedChildren={
                            <Typography.Text style={{ color: 'white' }}>
                              AND
                            </Typography.Text>
                          }
                          unCheckedChildren={
                            <Typography.Text style={{ color: 'black' }}>
                              OR
                            </Typography.Text>
                          }
                          checked={
                            task?.conjunction === false
                              ? task?.conjunction
                              : true
                          }
                          onClick={(checked) => {
                            handleConjunction(task?.id, checked);
                          }}
                          style={{
                            width: 60,
                            color:
                              task?.conjunction === false
                                ? 'rgba(0, 0, 0, 0.65)'
                                : '#fff',
                            background:
                              task?.conjunction === false ? '#ccc' : '#2684ff',
                            margin: '4px 4px 4px 0px',
                          }}
                        />
                      )}
                      {/* {task.level < 7 && ( */}
                      <WrapperSelect
                        getPopupContainer={() =>
                          document.getElementById(queryConditionsId)
                        }
                        showSearch
                        optionFilterProp='children'
                        listHeight={screenHeight <= 768 ? 70 : 150}
                        value={queryConditionValue}
                        style={{ width: 150 }}
                        onChange={(value) => {
                          if (
                            task?.queryCondition === 'In' &&
                            isArray(task?.value) &&
                            task?.value?.length > 1
                          ) {
                            handleQueryValueConditionIn(
                              task?.id,
                              value,
                              task?.value[0]
                            );
                          } else {
                            handleQueryCondition(task?.id, value);
                          }
                        }}
                        filterOption={(input, option) =>
                          option.children
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                      >
                        {possibleOperator.map((item, index) => {
                          return (
                            <Select.Option key={index} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </WrapperSelect>
                      {/* )} */}
                      {queryConditionValue === 'Between' &&
                        // task.level < 4 &&
                        renderValueBetween(task)}
                      {queryConditionValue === 'In' && renderValueIn(task)}
                      {(queryConditionValue === 'Is Not Null' ||
                        queryConditionValue === 'Is Null') && (
                        <div style={{ flex: 1 }}></div>
                      )}
                      {queryConditionValue !== 'Between' &&
                        queryConditionValue !== 'Is Not Null' &&
                        queryConditionValue !== 'Is Null' &&
                        queryConditionValue !== 'In' &&
                        // task.level < 4 &&
                        renderValue(task)}
                      {!viewMode && (
                        //  task.level < 6 &&
                        <>
                          {renderBtnUploadFile(task)}
                          <Tooltip
                            className='entities-draggable__remove'
                            title='Remove query'
                          >
                            <Button
                              danger
                              type='text'
                              icon={<CloseOutlined />}
                              onClick={() => {
                                handleRemoveQueryConditions(task?.id);
                              }}
                            />
                          </Tooltip>
                          <div className='entities-draggable__keep-remove' />
                        </>
                      )}
                    </Row>
                    {/* {task.level >= 7 && (
                      <Row>
                        <WrapperSelect
                          getPopupContainer={() =>
                            document.getElementById(queryConditionsId)
                          }
                          value={
                            task?.queryCondition || task?.possibleOperator[0]
                          }
                          style={{ width: 150 }}
                          onChange={(value) => {
                            handleQueryCondition(task?.id, value);
                          }}
                        >
                          {task?.possibleOperator.map((item, index) => {
                            return (
                              <Select.Option key={index} value={item}>
                                {item}
                              </Select.Option>
                            );
                          })}
                        </WrapperSelect>
                      </Row>
                    )} */}
                    {/* <Row style={{ marginLeft: '-4px' }}>
                      {task?.queryCondition === 'Between' &&
                        task.level >= 4 &&
                        renderValueBetween(task)}
                      {task?.queryCondition !== 'Between' &&
                        task?.queryCondition !== 'Is Not Null' &&
                        task?.queryCondition !== 'Is Null' &&
                        task.level >= 4 &&
                        renderValue(task)}
                      {!viewMode && task.level >= 6 && (
                        <>
                          <Tooltip
                            className='entities-draggable__remove'
                            title='Remove query'
                          >
                            <Button
                              danger
                              type='text'
                              icon={<CloseOutlined />}
                              onClick={() => {
                                handleRemoveQueryConditions(task?.id);
                              }}
                            />
                          </Tooltip>
                          <div className='entities-draggable__keep-remove' />
                        </>
                      )}
                    </Row> */}
                  </>
                )}
              </div>
            </div>
            <ModalUploadAdvancedFile
              visibleModal={visibleModal}
              setVisibleModal={setVisibleModal}
              handleUploadAdvancedFile={(data) =>
                handleUploadAdvancedFile(data, task?.id)
              }
            />
          </div>
        );
      }}
    </Draggable>
  );
};

QueryDraggable.propTypes = {
  task: PropTypes.object,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  selectionCount: PropTypes.number,
  isGhosting: PropTypes.bool,
  isHiddenDrag: PropTypes.bool,
  toggleSelection: PropTypes.func,
  toggleSelectionInGroup: PropTypes.func,
  multiSelectTo: PropTypes.func,
  handleRemoveQueryConditions: PropTypes.func,
  handleQueryCondition: PropTypes.func,
  handleQueryValue: PropTypes.func,
  handleConjunction: PropTypes.func,
  handleQueryOtherValue: PropTypes.func,
  viewMode: PropTypes.bool,
  advancedSearchGridView: PropTypes.bool,
  handleQueryValueConditionIn: PropTypes.func,
};

export default React.memo(QueryDraggable);
