import React from 'react';
import { useSelector } from 'react-redux';
import * as ribbonSelectors from 'redux/ribbon/selectors';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import AdvanceStack from '../../controls/home/AdvanceStack';
import GridConfig from './GridConfig';
import AdvanceFilter from '../../controls/home/AdvanceFilter';

import './GridLayout.less';

const GridLayout = () => {
  const typeView = useSelector(ribbonSelectors.selectRibbonType());
  // const disabledGrid = typeView === 'thumbnails' || typeView === 'tilesview';

  return (
    <ContainerButton style={{ maxWidth: 420 }}>
      <ItemButton span={12}>
        <AdvanceStack />
      </ItemButton>
      {/* <ItemButton span={10}>
        <AdvanceFilter />
      </ItemButton> */}
      {(window.location.pathname === '/members' ||
        window.location.pathname === '/favorite/favorite-members' ||
        window.location.pathname === '/digital-media' ||
        window.location.pathname === '/digital-media/new' ||
        window.location.pathname === '/digital-media/assets' ||
        window.location.pathname === '/digital-media/documents' ||
        window.location.pathname === '/digital-media/multi-media' ||
        window.location.pathname.includes('/assets/member/') ||
        window.location.pathname === '/favorite/favorite-assets' ||
        window.location.pathname === '/reportings' ||
        window.location.pathname === '/reportings/owned' ||
        window.location.pathname === '/reportings/shared' ||
        window.location.pathname === '/folders' ||
        window.location.pathname === '/folders/owned' ||
        window.location.pathname === '/folders/shared' ||
        window.location.pathname === '/favorite/favorite-folders') && (
        <ItemButton span={12}>
          <GridConfig
          // disabledGrid={disabledGrid}
          />
        </ItemButton>
      )}
    </ContainerButton>
  );
};

GridLayout.propTypes = {};

export default GridLayout;
