export const filterModuleProperties = (moduleNameProperties) => {
  const { moduleName } = moduleNameProperties;

  if (moduleName === 'DProperties')
    return filterDProperties(moduleNameProperties);

  return moduleNameProperties;
};

// DProperties
const hiddenBlueprintComponentsFields = ['Id', 'ComponentsPartOfId'];

export const filterDProperties = (moduleNameProperties) => {
  const { moduleGroupProperties, moduleProperties } = moduleNameProperties;

  const newModuleProperties = moduleProperties.map((property) => {
    if (property?.propertyName === 'BpComponents') {
      const newChildProperties = property?.childProperties?.filter(
        (property) =>
          !hiddenBlueprintComponentsFields.includes(property?.propertyName)
      );

      return {
        ...property,
        childProperties: newChildProperties,
      };
    }
    return property;
  });

  const newModuleGroupProperties = moduleGroupProperties.map((moduleGroup) => {
    if (moduleGroup?.nameGroup === 'Blueprint Components') {
      const newGroupProperties = moduleGroup?.groupProperties?.map(
        (property) => {
          if (property?.propertyName === 'BpComponents') {
            const newChildProperties = property?.childProperties?.filter(
              (property) =>
                !hiddenBlueprintComponentsFields.includes(
                  property?.propertyName
                )
            );

            return {
              ...property,
              childProperties: newChildProperties,
            };
          }
          return property;
        }
      );
      return {
        ...moduleGroup,
        groupProperties: newGroupProperties,
      };
    }
    return moduleGroup;
  });

  return {
    ...moduleNameProperties,
    moduleGroupProperties: newModuleGroupProperties,
    moduleProperties: newModuleProperties,
  };
};

//
