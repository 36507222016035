import React from 'react';

import { useDispatch } from 'react-redux';

import { DeleteOutlined } from '@ant-design/icons';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

import * as maintenanceFormActions from 'pages/maintenance-form/controllers/actions';

const DeleteFormBtn = (props) => {
  const { selectedForms } = props;

  const dispatch = useDispatch();

  const onClickDeleteBtn = () => {
    const formIds = selectedForms.map((formItem) => formItem?.formId);
    dispatch(maintenanceFormActions.deleteFormItem(formIds));
  };

  const isDeleteBtnDisabled = selectedForms.length === 0;

  return (
    <ButtonSmallIcon
      icon={<DeleteOutlined />}
      label={'Delete'}
      onClick={onClickDeleteBtn}
      disabled={isDeleteBtnDisabled}
    />
  );
};

export default DeleteFormBtn;
