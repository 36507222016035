import React from 'react';
import { Checkbox, Row, Tag, Tooltip } from 'antd';
import { generateOperator, NULL_VALUE, OPERATOR } from './utils';

const PropertyAdvancedFilter = ({ property }) => {
  const { operator, value, values, otherValue, displayName, dataType } =
    property ?? {};

  const genOperator = generateOperator(operator);
  const hasValue = value || values || dataType === 'boolean' || value === 0;

  return (
    <Row>
      {property?.isParent ? (
        <div style={{ width: '100%', display: 'flex' }}>
          <Tag style={{ margin: 2 }} color='blue'>
            {property?.operation} - Group conditions
          </Tag>
          {property?.strictMode && (
            <Checkbox
              style={{ marginLeft: 'auto' }}
              checked={property?.strictMode}
            >
              Nested Mode
            </Checkbox>
          )}
        </div>
      ) : (
        <Tooltip
          overlayClassName='scroller'
          overlayStyle={{ maxHeight: '50vh' }}
          title={<RenderTooltip property={property} />}
        >
          <Tag
            style={{
              margin: 2,
              maxWidth: 300,
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {displayName}
            <span style={{ margin: '0px 4px', fontWeight: 700 }}>
              {genOperator}
            </span>
            {!NULL_VALUE.includes(operator) && (
              <>
                <span>
                  {hasValue
                    ? `${
                        values?.join()
                          ? values?.join()
                          : value || value === 0
                          ? value
                          : false
                      }`
                    : '"Empty value"'}
                </span>
                {hasValue && otherValue ? ' and ' : null}
                <span>{otherValue ? `${otherValue}` : null}</span>
              </>
            )}
          </Tag>
        </Tooltip>
      )}
    </Row>
  );
};

const RenderTooltip = ({ property }) => {
  const { value, values, dataType, otherValue, fullPathDisplayName, operator } =
    property ?? {};

  const generateOperator = (operator) => {
    return operator ? OPERATOR[operator] : operator;
  };
  const genOperator = generateOperator(operator);
  return (
    <>
      {fullPathDisplayName}
      <span style={{ margin: '0px 4px', fontWeight: 700 }}>{genOperator}</span>
      {!NULL_VALUE.includes(operator) && (
        <>
          <span>
            {value || values || dataType === 'boolean' || value === 0
              ? `${
                  values?.join()
                    ? values?.join()
                    : value || value === 0
                    ? value
                    : false
                }`
              : '"Empty value"'}
          </span>
          <span>{otherValue ? `${otherValue}` : null}</span>
        </>
      )}
    </>
  );
};

export default PropertyAdvancedFilter;
