import React from 'react';
import { ChatView } from 'common/components';

import './styles.less';

const ChatVideo = (props) => {
  const { callData } = props;

  const forceSenderId = callData?.threadItem?.userId;

  return (
    <div className='chat-video-view'>
      <ChatView isVideoCallChat forceSenderId={forceSenderId} />
    </div>
  );
};

export default ChatVideo;
