import React from 'react';
import { Divider } from 'antd';
import { useLocation } from 'react-router-dom';

import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import BackControl from './components/controls/home/Back';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const ProductSyndicationHistory = () => {
  const location = useLocation();
  const disabledBackBtn = location.pathname === '/products/syndication-history';

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabled />
      </Can>

      <RibbonDivider />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <AdvanceStack isDisabled />
      </Can>
      <BackControl ribbonSide='right' isDisabled={disabledBackBtn} />
    </RibbonBar>
  );
};

export default ProductSyndicationHistory;
