import _ from 'lodash';
import React, { useState } from 'react';
import { Col, Divider, Input, Row, Space, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Form, SectionWrapper } from 'common/components';
import { AddNewItem, UpdateValueButton } from './DrugFactsButton';

const DIRECTIONS_FIELD_NAME = ['directionDosage', 'ageGroup'];

const Directions = () => {
  const form = Form.useFormInstance();
  const directionsList = Form.useWatch('directions', form);

  const [mode, setMode] = useState();

  const onClickAddNew = () => {
    form.resetFields(DIRECTIONS_FIELD_NAME);
    setMode('add');
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    form
      .validateFields(DIRECTIONS_FIELD_NAME)
      .then((value) => {
        form.setFieldsValue({
          directions: [...(directionsList || []), value],
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ padding: '8px 0px', minHeight: '30vh' }}>
      <DirectionsList setMode={setMode} mode={mode} />
      {mode === 'add' && <AddDirectionsForm />}
      <AddNewItem
        mode={mode}
        onClickAdd={onClickAdd}
        onClickCancel={onClickCancel}
        onClickAddNew={onClickAddNew}
      />
    </div>
  );
};

export default Directions;

const DirectionsList = ({ setMode, mode }) => {
  const form = Form.useFormInstance();
  const directionsList = Form.useWatch('directions', form);

  const onClickUpdate = (index) => {
    const cloneDirectionList = _.cloneDeep(directionsList);

    form
      .validateFields(DIRECTIONS_FIELD_NAME)
      .then((value) => {
        cloneDirectionList[index] = value;

        form.setFieldsValue({
          directions: cloneDirectionList,
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickDelete = (index) => {
    const cloneDirectionList = _.cloneDeep(directionsList);
    cloneDirectionList.splice(index, 1);

    form.setFieldsValue({
      directions: cloneDirectionList,
    });
  };

  const onClickEdit = (index, item) => {
    setMode(`edit-${index}`);
    form.setFieldsValue({ ...item });
  };

  const onCancel = () => {
    setMode('view');
  };

  return (
    <>
      <Form.Item name='directions' noStyle />
      {directionsList?.map((item, index) => {
        const { ageGroup, directionDosage } = item;
        const isEdit = mode === `edit-${index}`;

        return (
          <div key={index}>
            {isEdit ? (
              <>
                <AddDirectionsForm />
                <UpdateValueButton
                  onCancel={onCancel}
                  onClickUpdate={() => onClickUpdate(index)}
                />
              </>
            ) : (
              <SectionWrapper
                title={
                  <Space>
                    <EditOutlined onClick={() => onClickEdit(index, item)} />
                    <DeleteOutlined onClick={() => onClickDelete(index)} />
                  </Space>
                }
                placement='right'
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <Row>
                  <Col span={24}>
                    <Typography.Text>
                      ■ {ageGroup && <span>{ageGroup} : </span>}
                      {directionDosage}
                    </Typography.Text>
                  </Col>
                </Row>
              </SectionWrapper>
            )}
          </div>
        );
      })}
    </>
  );
};

const AddDirectionsForm = () => {
  const form = Form.useFormInstance();
  const directionsList = Form.useWatch('directions', form);
  return (
    <>
      {directionsList?.length > 0 && <Divider />}
      <Row gutter={4}>
        <Col span={24}>
          <Form.Item name='ageGroup' label='Age Group'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='directionDosage'
            label='Directions/Dosage'
            rules={[
              {
                required: true,
                message: 'Directions/Dosage is required',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
