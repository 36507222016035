import { useEffect, useRef, useState, useCallback } from 'react';
import * as _ from 'lodash';

const usePrevious = (value) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useStatePrevious = (initValue) => {
  const refState = useRef();
  const [state, setState] = useState(initValue);
  refState.current = state;

  const prevInitValue = usePrevious(initValue);

  const handleSetState = useCallback((callback) => {
    if (typeof callback === 'function') {
      const nextState = callback(refState.current);
      setState(nextState);
      refState.current = nextState;
    } else {
      setState(callback);
      refState.current = callback;
    }
  }, []);

  useEffect(() => {
    if (!_.isEqual(prevInitValue, initValue)) {
      handleSetState(initValue);
    }
  }, [initValue, prevInitValue]);

  return [state, handleSetState, refState];
};

export default usePrevious;
