import React from 'react';

const SectionWrapper = (props) => {
  const { noBorder, children, style = {}, className } = props;

  const wrapperStyle = style?.wrapperStyle || {};
  const contentStyle = style?.contentStyle || {};
  const wrapperClassName = className?.wrapperClass || '';
  const contentClassName = className?.contentClass || '';

  return (
    <div
      className={wrapperClassName}
      style={{ padding: 3, height: '100%', ...wrapperStyle }}
    >
      <div
        className={contentClassName}
        style={{
          border: noBorder ? 'none' : 'solid 1px #d3d3d3',
          borderRadius: 2,
          padding: noBorder ? 0 : 2,
          height: '100%',
          ...contentStyle,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SectionWrapper;
