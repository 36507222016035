import { useState, useEffect, useMemo, useRef } from 'react';

import { Typography } from 'antd';

import { dialogFunction, CustomNotification } from 'common/components';

import { useQaSpecGrid } from './useQaSpecGrid';
import useCheckSnapshotForRetailer from './useCheckSnapshotForRetailer';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from './useGetQaSpecRequiredFields';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import { useGetPhysicalChemicalQuery } from 'pages/qa-spec/query';

import { sleep } from 'utils/delay';
import { apiHandler } from 'utils/api';
import sortByKey from 'utils/sortByKey';
import { getAllRows } from 'pages/qa-spec/utils';
import { addErrorsToRow } from 'pages/qa-spec/utils';
import { checkSectionRequired } from 'pages/qa-spec/utils';
import { createTextEditorRenderer } from 'pages/qa-spec/utils/gridColumnUtils';
import {
  getProductSpecRequiredFields,
  validateAllRows,
  validatePhysicalAndChemicalCharacteristicGrid,
  validateRequiredFieldProductSpec,
} from 'pages/qa-spec/components/qa-prod-spec/utils';

import * as qaSpecServices from 'services/qaSpec';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/qa-spec';

export const usePhyChe = ({ productId, enumOptions, isGridEditing }) => {
  const intl = useIntl();
  const gridInst = useRef();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const [saveLoading, setSaveLoading] = useState(false);

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const physicalAndChemicalRequiredFields = getProductSpecRequiredFields({
    gridInst,
    requiredFields,
    requiredFieldPath:
      'qaSpecification.qaSpecProductSpecification.qaSpecProductSpecsPhysicalAndChemicalCharacteristics',
  });

  const isSectionRequired = checkSectionRequired(requiredFields, 'physical');

  const resetGridCallback = () => {
    if (isProductAssignedToCurrentUserAsSupplier) {
      validateAllRows({
        gridInst,
        requiredFields: physicalAndChemicalRequiredFields,
      });
    }
  };
  const { handleRefetchPhysicalChemical } = useGetPhysicalChemicalQuery({
    productId,
  });

  const { physicalChemicalData, isLoading, isFetching } =
    useGetPhysicalChemicalQuery({
      productId,
      enabled: !!productId,
    });

  const {
    rowData,
    addGridRow,
    selectedRow,
    selectGridRow,
    resetGridData,
    deleteSelectedGridRow,
  } = useQaSpecGrid({
    gridInst,
    isGridEditing,
    gridData: physicalChemicalData?.physicalAndChemicalCharacteristics,
    resetGridCallback,
  });

  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedToCurrentUserAsSupplier =
    checkAssignProductToSupplier();

  const fetchDataLoading = isLoading && isFetching;

  const columnDefs = useMemo(() => {
    const statusField = {
      minWidth: 50,
      field: 'error',
      displayName: ' ',
      cellRenderer: 'StatusRender',
      type: 'nonEditableColumn',
    };

    let cols = [
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Parameter',
        field: 'parameter',
        width: 200,
        flex: null,
        cellEditorSelector: (params) => {
          return {
            popup: true,
            component: 'SelectionFreeText',
            params: {
              values: sortByKey(enumOptions.parameter, 'enumDisplayName').map(
                (item) => ({
                  value: item.enumDisplayName,
                  displayName: item.enumDisplayName,
                })
              ),
              maxLength: 150,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Method',
        field: 'method',
        width: 200,
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Value (Food and Non-Food products)',
        field: 'value',
        width: 350,
        flex: null,
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Specification/Description/Target',
        field: 'specification',
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
        width: 280,
        flex: null,
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Tolerance',
        field: 'tolerance',
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
        width: 200,
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Reject/Fail Criteria',
        field: 'rejectOrFailCriteria',
        width: 180,
        flex: null,
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Ref. Standard',
        field: 'referenceStandard',
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Ref. Method',
        field: 'referenceMethod',
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Test Source',
        field: 'test',
        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: [
                { value: 'Internal', displayName: 'Internal' },
                { value: 'External', displayName: 'External' },
              ],
              mapValue: (value) => {
                if (value === 'null' || value === undefined) {
                  return null;
                } else {
                  return value;
                }
              },
              popup: true,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Frequency',
        field: 'frequency',
        cellEditorSelector: createTextEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Release Criteria',
        field: 'releaseCriteria',
        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: [
                { value: 'Yes', displayName: 'Yes' },
                { value: 'No', displayName: 'No' },
                { value: 'Not Applicable', displayName: 'Not Applicable' },
              ],
              mapValue: (value) => {
                if (value === 'null' || value === undefined) {
                  return null;
                } else {
                  return value;
                }
              },
              popup: true,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
    ];

    cols = isProductAssignedToCurrentUserAsSupplier
      ? [statusField, ...cols]
      : cols;

    return cols.map((fieldItem) => {
      const DEFAULT_WIDTH = 150;

      let initColSetting = {
        type: 'editableColumn',
      };

      return {
        minWidth: DEFAULT_WIDTH,
        ...initColSetting,
        ...fieldItem,
      };
    });
  }, [enumOptions, isToggleSnapshot]);

  const cancelEdit = () => {
    resetGridData();
  };

  const addNewRow = () => {
    let newRowData = {
      method: null,
      parameter: null,
      specification: null,
      tolerance: null,
      frequency: null,
      releaseCriteria: null,
      value: null,
      referenceStandard: null,
      referenceMethod: null,
      test: null,
    };

    if (isProductAssignedToCurrentUserAsSupplier) {
      const errorRequiredFields = physicalAndChemicalRequiredFields?.map(
        (field) => field?.errorMessage
      );
      newRowData = addErrorsToRow(newRowData, errorRequiredFields);
      addGridRow(newRowData);
      return;
    }

    addGridRow(newRowData);
  };

  const saveData = async (toggleEditMode, analysisNotExecuted) => {
    gridInst.current.api.stopEditing();

    const GRID_VALIDATION_DELAY = 700;
    await sleep(GRID_VALIDATION_DELAY);

    const isGridHaveError = validatePhysicalAndChemicalCharacteristicGrid({
      grid: gridInst,
    }).checkIfGridHaveError();

    if (isGridHaveError) {
      CustomNotification.error(
        `Please resolve the Physical and Chemical Characteristics grid's errors.`
      );
      return;
    }
    const allRowData = getAllRows(gridInst);

    if (
      isProductAssignedToCurrentUserAsSupplier &&
      physicalAndChemicalRequiredFields?.length > 0 &&
      allRowData?.length <= 0
    ) {
      CustomNotification.error('Please add at least one row data to save');
      return;
    }

    //* clear selected row
    selectGridRow(null);

    setSaveLoading(true);

    const params = {
      productId,
      physicalAndChemicalCharacteristics: allRowData,
      analysisNotExecuted,
    };

    const successMessage = intl.formatMessage(
      Messages.productSpecSavePhyCheSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.productSpecSavePhyCheError
    );

    apiHandler({
      service: qaSpecServices.saveQaSpecPhyCheCharacteristic,
      params,
      successMessage,
      errorMessage,
      successCallback: saveSuccessCallback(toggleEditMode),
      onFinally: saveFinally,
    });
  };

  const saveSuccessCallback = (toggleEditMode) => () => {
    toggleEditMode();

    handleRefetchPhysicalChemical();
  };

  const saveFinally = () => {
    setSaveLoading(false);
  };

  const deleteData = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onDeleteData,
    });
  };

  const onDeleteData = () => {
    deleteSelectedGridRow();
  };

  const selectRow = () => {
    selectGridRow();
  };

  const handleValidateRequiredFieldForSupplier = ({ event }) => {
    physicalAndChemicalRequiredFields?.forEach((item) => {
      if (item?.fieldName === event.column.colId)
        validateRequiredFieldProductSpec({
          gridInst,
          event,
          requiredFieldError: item.errorMessage,
        });
    });
  };

  const onCellEditingStopped = async (event) => {
    if (isProductAssignedToCurrentUserAsSupplier) {
      handleValidateRequiredFieldForSupplier({
        event,
      });
    }
  };

  useEffect(() => {
    const handleValidateAllRowDataForSupplier = () => {
      if (
        rowData &&
        rowData?.length > 0 &&
        isProductAssignedToCurrentUserAsSupplier
      ) {
        validateAllRows({
          gridInst,
          requiredFields: physicalAndChemicalRequiredFields,
        });
      }
    };

    handleValidateAllRowDataForSupplier();
  }, [
    isProductAssignedToCurrentUserAsSupplier,
    physicalAndChemicalRequiredFields,
    JSON.stringify(rowData),
  ]);
  const gridTitle = (
    <>
      {isProductAssignedToCurrentUserAsSupplier && isSectionRequired && (
        <>
          <Typography.Text type='danger'>*</Typography.Text>{' '}
        </>
      )}
      Physical and Chemical Characteristics
    </>
  );

  return {
    gridInst,
    analysisNotExecuted: physicalChemicalData?.analysisNotExecuted,
    rowData,
    columnDefs,
    addNewRow,
    saveData,
    cancelEdit,
    deleteData,
    selectedRow,
    selectRow,
    loading: fetchDataLoading || saveLoading,
    onCellEditingStopped,
    isProductAssignedToCurrentUserAsSupplier,
    gridTitle,
  };
};
