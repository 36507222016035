import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { Input, Select, Checkbox, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import { APL_VISIBILITY } from 'static/Constants';

import {
  Form,
  RibbonButton,
  StyledModal,
  WrapperSelect,
} from 'common/components';

import { editInfoMetadata } from 'services/apl';

import { makeSelectAplDetail } from 'pages/product-apl-detail/controllers/selectors';
import { getAplDetail } from 'pages/product-apl-detail/controllers/actions';

import { formatObjectFormData } from 'utils';

const EditInfoMetadata = () => {
  const [visibleModal, setVisibleModal] = useState(false);

  const aplDetail = useSelector(makeSelectAplDetail());

  return (
    <>
      <RibbonButton
        icon={<EditOutlined style={{ height: 26 }} />}
        label={Messages.editAplMetadata}
        onClick={() => setVisibleModal(true)}
        disabled={!aplDetail?.isOwner}
      />

      {visibleModal && (
        <ModalEditInfoMetadata
          visibleModal={visibleModal}
          turnOffModal={() => setVisibleModal(false)}
          aplDetail={aplDetail}
        />
      )}
    </>
  );
};

const layoutForm = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ModalEditInfoMetadata = ({ visibleModal, turnOffModal, aplDetail }) => {
  const [formInstance] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    aplId,
    aplName,
    aplDescription,
    aplLongDescription,
    visibility,
    enableScheduleMatching,
  } = aplDetail;

  useEffect(() => {
    formInstance.setFieldsValue({
      aplName,
      aplDescription,
      aplLongDescription,
      visibility,
      enableScheduleMatching,
    });
  }, [
    aplDescription,
    aplDetail,
    aplLongDescription,
    aplName,
    enableScheduleMatching,
    formInstance,
    visibility,
  ]);

  const handleSubmitForm = () => {
    formInstance.validateFields().then(() => {
      let params = formatObjectFormData(formInstance.getFieldsValue());
      params = {
        ...params,
        aplId: aplDetail?.aplId,
      };

      setIsLoading(true);

      editInfoMetadata(params).then((response) => {
        if (response?.isSuccess) {
          notification.success({
            message: intl.formatMessage(
              messagesProduct.editAplInfoMetadataSuccess
            ),
          });
          turnOffModal();
          dispatch(getAplDetail({ aplId }));
        } else {
          notification.error({
            message: response?.message,
          });
          turnOffModal();
        }
      });
    });
  };

  const modalProps = {
    title: intl.formatMessage(messagesProduct.editAplInfoMetadata),
    bodyStyle: { minHeight: '20vh' },
    width: '30vw',
    destroyOnClose: true,
    visible: visibleModal,
    onCancel: turnOffModal,
    onOk: handleSubmitForm,
    confirmLoading: !!isLoading,
  };

  return (
    <StyledModal {...modalProps}>
      <Form {...layoutForm} form={formInstance}>
        <Form.Item
          name='aplName'
          label='APL Name'
          rules={[{ required: true, message: 'Please input an APL name' }]}
        >
          <Input placeholder='Input an APL name' />
        </Form.Item>
        <Form.Item name='aplDescription' label='Description'>
          <Input placeholder='Input description' />
        </Form.Item>
        <Form.Item name='visibility' label='Visibility'>
          <WrapperSelect>
            {APL_VISIBILITY?.slice()
              .sort()
              .map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
          </WrapperSelect>
        </Form.Item>
        <Form.Item name='aplLongDescription' label='More Description'>
          <Input.TextArea rows={4} placeholder='Input more description' />
        </Form.Item>

        <Form.Item
          name='enableScheduleMatching'
          label='Schedule Matching'
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default EditInfoMetadata;
