import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import { ResizableBox } from 'react-resizable';
import videoThumbnail from 'assets/asset-icons/video-thumbnail.png';
import MarkdownAddParagraph from './MarkdownAddParagraph';
import ParagraphConfigTag from './ParagraphConfigTag';

import classnames from 'classnames';
import './MarkdownMediaCustomPreview.less';

const MarkdownMediaCustomPreview = (props) => {
  const {
    isAlign,
    align,
    space,
    width,
    height,
    maxWidth,
    handleResize,
    isKeepRatio,
    ratio,
    fileInfo,
    previewType,
  } = props;

  let { getAdditionalParagraph } = props;

  const [isAddParagraphOpen, setIsAddParagraphOpen] = useState(false);
  const [additionalParagraph, setAdditionalParagraph] = useState([]);
  const [edittingItem, setEdittingItem] = useState(null);

  const convertYoutubeToEmbedSrc = (src) => {
    if (!src) return '';
    if (src.includes('embed')) return src;
    const videoId = src.split('?v=')?.[1]?.split('&')?.[0];
    return 'https://www.youtube.com/embed/' + videoId;
  };

  const mapToResizeBoxStyle = () => {
    if (isAlign && align && space !== 0) {
      if (align === 'left') {
        return { marginRight: space + 'px', marginBottom: space + 'px' };
      }
      if (align === 'right') {
        return { marginLeft: space + 'px', marginBottom: space + 'px' };
      }
    } else {
      return {};
    }
  };

  const openAddParagraphArea = () => {
    setEdittingItem(null);
    setIsAddParagraphOpen(true);
  };

  const renderPreviewItem = () => {
    if (previewType === 'image') {
      return (
        <img
          className='md-media-custom-img-preview__preview-image'
          src={fileInfo?.url}
          alt='media-file'
        />
      );
    }
    if (previewType === 'video') {
      return (
        <video
          width='100%'
          style={{
            outline: 'none',
            controls: 'true',
            height: '100%',
          }}
          preload='none'
          controls={true}
          poster={videoThumbnail}
        >
          <source src={fileInfo?.url} type='video/mp4' />
        </video>
      );
    }
    if (previewType === 'youtube') {
      return (
        <iframe
          title='unknown'
          src={convertYoutubeToEmbedSrc(fileInfo?.url)}
          frameborder='0'
          allowfullscreen='true'
          width='100%'
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      );
    }
    return null;
  };

  const mapToResizeBoxHeight = () => {
    return ratio !== null ? (height / ratio) * maxWidth * 0.01 + 'px' : 'auto';
  };

  const onAddParagraph = ({ font, color, content }) => {
    setAdditionalParagraph((prevParagraphs) => [
      ...prevParagraphs,
      {
        font,
        color,
        content,
      },
    ]);
  };

  const onUpdateParagraph = ({ font, color, content, id }) => {
    setAdditionalParagraph((prevParagraphs) => {
      let clonePrevParagraph = [...prevParagraphs];
      clonePrevParagraph[id] = { font, color, content };
      return clonePrevParagraph;
    });
  };

  const onEdit = (itemInfo) => {
    setIsAddParagraphOpen(false);
    setEdittingItem(itemInfo);
  };

  const onDelete = (id) => {
    setAdditionalParagraph((prevParagraphs) => {
      let clonePrevParagraph = [...prevParagraphs];
      clonePrevParagraph.splice(id, 1);
      return clonePrevParagraph;
    });
  };

  getAdditionalParagraph.current = () => {
    return additionalParagraph;
  };

  return (
    <>
      <Col
        className={classnames(
          'scroller--y',
          'scoller',
          'md-media-custom-img-preview',
          {
            'md-media-custom-img-preview--white-bg':
              additionalParagraph?.length > 0,
            'disable-resize': previewType === 'youtube',
          }
        )}
        flex='1 1 0px'
      >
        <ResizableBox
          className={classnames({
            'md-media-custom-img-preview__preview-box--align-left':
              align === 'left' && isAlign,
            'md-media-custom-img-preview__preview-box--align-right':
              align === 'right' && isAlign,
          })}
          style={mapToResizeBoxStyle()}
          width={width * 0.01 * maxWidth}
          height={(height / ratio) * maxWidth * 0.01}
          maxConstraints={[maxWidth, maxWidth / ratio]}
          lockAspectRatio={isKeepRatio}
          onResize={(event, { element, size, handle }) => handleResize(size)}
        >
          <div
            className={classnames(
              'box',
              'md-media-custom-img-preview__raw-preview-image',
              {
                'md-media-custom-img-preview__raw-preview-image--dark-bg':
                  additionalParagraph?.length > 0,
              }
            )}
            id='media-storage-image-wrapper'
            style={{
              width: width * 0.01 * maxWidth + 'px',
              height: mapToResizeBoxHeight(),
              minHeight: previewType === 'youtube' ? 300 : '',
            }}
          >
            {renderPreviewItem()}
          </div>
        </ResizableBox>

        {isAlign && align && additionalParagraph.length < 1 && (
          <p
            className={classnames(
              'md-media-custom-img-preview__align-paragraph'
            )}
            onClick={openAddParagraphArea}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
        )}

        {isAlign && align && additionalParagraph.length > 0 && (
          <div
            className={classnames(
              'md-media-custom-img-preview__align-paragraph--additional'
            )}
          >
            {additionalParagraph.map((pItem, idx) => {
              return (
                <p
                  className='md-media-custom-img-preview__align-paragraph--styled'
                  style={{
                    fontFamily: pItem?.font,
                    color: pItem?.color || '#000000A6',
                  }}
                >
                  <ParagraphConfigTag
                    onDelete={() => onDelete(idx)}
                    onEdit={() =>
                      onEdit({
                        font: pItem?.font,
                        color: pItem?.color,
                        content: pItem?.content,
                        id: idx,
                      })
                    }
                    align={align}
                  />
                  {pItem?.content}
                </p>
              );
            })}
            <p
              className={classnames(
                'md-media-custom-img-preview__align-paragraph--add'
              )}
              onClick={openAddParagraphArea}
            >
              Sample Paragraph: Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat
            </p>
          </div>
        )}
      </Col>
      {(isAddParagraphOpen || edittingItem) && (
        <MarkdownAddParagraph
          onAddParagraph={onAddParagraph}
          onUpdateParagraph={onUpdateParagraph}
          edittingData={edittingItem}
          onClose={() => {
            setIsAddParagraphOpen(false);
            setEdittingItem(null);
          }}
        />
      )}
    </>
  );
};

export default MarkdownMediaCustomPreview;
