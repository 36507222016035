import React from 'react';
import PropTypes from 'prop-types';

import { forwardTo } from 'utils/common/route';
import { ThumbnailItem } from 'common/components';

import * as getLink from 'utils/common/linkBuilder';

import AssetReport from 'assets/reporting/AssetReport.png';
import ProductReport from 'assets/reporting/ProductReport.png';
import MemberReport from 'assets/reporting/MemberReport.png';

const ReportingThumbnail = (props) => {
  const {
    dataDetail,
    selected,
    onClickItemGrid,
    onClickCheckboxItem,
    pathname,
  } = props;

  const onDoubleClick = (item) => {
    const { id } = item;
    forwardTo(`${pathname}/${id}`);
  };

  const image =
    (dataDetail?.advType === 'DigitalMedia' && AssetReport) ||
    (dataDetail?.advType === 'Member' && MemberReport) ||
    (dataDetail?.advType === 'ProductItem' && ProductReport);

  const aLink = getLink.reportingLink(dataDetail?.id, dataDetail?.name);

  const status = dataDetail?.isBasicMode ? 'Basic Report' : null;
  return (
    <ThumbnailItem
      status={status}
      hideStatus={!dataDetail?.isBasicMode}
      dataDetail={dataDetail}
      labelThumbnail={aLink}
      imageThumbnail={image}
      selected={selected}
      onClickItemGrid={onClickItemGrid}
      onClickCheckboxItem={onClickCheckboxItem}
      onDoubleClick={onDoubleClick}
      shareable={dataDetail?.shareable} //shareable class for asset
      isShareBtn={true}
    />
  );
};

ReportingThumbnail.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default React.memo(ReportingThumbnail);
