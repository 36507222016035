import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Typography } from 'antd';
import _ from 'lodash';

import { EditContainer } from 'pages/qa-spec/components';
import { Form, WithLoading, SectionWrapper } from 'common/components';
import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useParams } from 'react-router-dom';

import { BasicFormItemList } from 'common/components/form/basic-form';

import { useGetQaFreshMeatTabData, useSaveQaFreshMeatTab } from './queries';
import { getKeyProductSpec } from 'pages/qa-spec/hooks/useProductSpecInfo';

import { useGetEnumOptions } from 'pages/qa-spec/hooks';

import { FRESH_MEAT_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';
import sortByKey from 'utils/sortByKey';
import { useQueryClient } from '@tanstack/react-query';
import { useGetSnapshotQaFreshMeat } from './hook';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import {
  getFormattedValue,
  renderPopoverContent,
} from 'pages/qa-spec/utils/index';

const QaFreshMeat = (props) => {
  const { isTabActive } = props;

  const { id: productId } = useParams();
  const queryClient = useQueryClient();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const editHook = useState(false);
  const [isEdit] = editHook;

  const freshMeatEnumOptions = useGetEnumOptions({
    optionMapping: useMemo(
      () => (option) => ({
        value: option.enumDisplayName,
        displayName: option.enumDisplayName,
      }),
      []
    ),
  })?.freshMeat;

  const {
    data,
    refetch: fetchQaFreshMeatData,
    isLoading: loading,
  } = useGetQaFreshMeatTabData({ productId }, { enabled: false, retry: false });

  useEffect(() => {
    if (isTabActive && !isEdit) {
      fetchQaFreshMeatData();
    }
  }, [isTabActive, isEdit, fetchQaFreshMeatData]);

  const handleSaveQaFreshMeatTab = useSaveQaFreshMeatTab();
  const { isLoading: saveLoading } = handleSaveQaFreshMeatTab;

  const { data: snapshotData, isLoading: isLoadingSnapshotFreshMeat } =
    useGetSnapshotQaFreshMeat({
      enabled: !!productId && isToggleSnapshot,
      productId,
    });

  useEffect(() => {
    if (data) {
      formInst.setFieldsValue(data);
    }
  }, [JSON.stringify(data)]);

  const [formInst] = Form.useForm();

  const handleSave = async (toggleEditMode) => {
    const formValue = formInst.getFieldsValue();
    handleSaveQaFreshMeatTab.mutate(
      {
        productId,
        qaFreshMeatFormData: formValue,
      },
      {
        onSuccess: () => {
          toggleEditMode();
          queryClient.invalidateQueries({
            queryKey: getKeyProductSpec(productId),
          });
        },
      }
    );
  };

  const handleCancel = () => {
    formInst.setFieldsValue(data);
  };

  const enumType = {
    irradiated: freshMeatEnumOptions.irradiated,
    freeRange: freshMeatEnumOptions.freeRange,
    usdaGrading: freshMeatEnumOptions.usdaGrading,
    dryAged: freshMeatEnumOptions.dryAged,
    coldSmoked: freshMeatEnumOptions.coldSmoked,
  };

  const formDefineLeft = [
    {
      name: 'breed',
      label: 'Breed',
      snapshotData: snapshotData?.['breed']?.trim() ?? '',
      supplierModifiedData: data?.['breed']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Breed',
        snapshotData?.['breed']?.trim() ?? '',
        data?.['breed']?.trim() ?? ''
      ),
    },
    {
      name: 'feed',
      label: 'Feed',
      snapshotData: snapshotData?.['feed']?.trim() ?? '',
      supplierModifiedData: data?.['feed']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Feed',
        snapshotData?.['feed']?.trim() ?? '',
        data?.['feed']?.trim() ?? ''
      ),
    },
    {
      name: 'typeOfCutOrTrimSpec',
      label: 'Type of Cut or Trim Spec',
      type: 'textarea',
      inputProps: {
        autoSize: { minRows: 3, maxRows: 3 },
        maxLength: FRESH_MEAT_LIMIT_CHARACTERS.typeOfCutOrTrimSpec,
        showCount: true,
      },
      snapshotData: snapshotData?.['typeOfCutOrTrimSpec'] ?? '',
      supplierModifiedData: data?.['typeOfCutOrTrimSpec'] ?? '',
      renderPopoverContent: renderPopoverContent(
        'Type of Cut or Trim Spec',
        snapshotData?.['typeOfCutOrTrimSpec'] ?? '',
        data?.['typeOfCutOrTrimSpec'] ?? ''
      ),
    },
    {
      name: 'productRangeClassification',
      label: 'Product Range Classification',
      snapshotData: snapshotData?.['productRangeClassification']?.trim() ?? '',
      supplierModifiedData: data?.['productRangeClassification']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Product Range Classification',
        snapshotData?.['productRangeClassification']?.trim() ?? '',
        data?.['productRangeClassification']?.trim() ?? ''
      ),
    },
    {
      name: 'irradiated',
      label: 'Irradiated',
      type: 'select',
      selectProps: {
        options: freshMeatEnumOptions.irradiated,
        inputProps: {
          allowClear: true,
        },
      },
      formattedValue: getFormattedValue(
        snapshotData?.['irradiated'],
        'irradiated',
        enumType
      ),
      snapshotData: snapshotData?.['irradiated'],
      supplierModifiedData: data?.['irradiated'],
      renderPopoverContent: renderPopoverContent(
        'Fortification',
        snapshotData?.['irradiated'],
        data?.['irradiated'],
        getFormattedValue(snapshotData?.['irradiated'], 'irradiated', enumType)
      ),
    },
    {
      name: 'fortification',
      label: 'Fortification',
      type: 'select',
      selectProps: {
        options: freshMeatEnumOptions.fortification,
        inputProps: {
          allowClear: true,
        },
      },
      snapshotData: snapshotData?.['fortification'],
      supplierModifiedData: data?.['fortification'],
      renderPopoverContent: renderPopoverContent(
        'Fortification',
        snapshotData?.['fortification'],
        data?.['fortification']
      ),
    },
    {
      name: 'freeRange',
      label: 'Free Range',
      type: 'select',
      selectProps: {
        options: freshMeatEnumOptions.freeRange,
        inputProps: {
          allowClear: true,
        },
      },
      formattedValue: getFormattedValue(
        snapshotData?.['freeRange'],
        'freeRange',
        enumType
      ),
      snapshotData: snapshotData?.['freeRange'],
      supplierModifiedData: data?.['freeRange'],
      renderPopoverContent: renderPopoverContent(
        'Free Range',
        snapshotData?.['freeRange'],
        data?.['freeRange'],
        getFormattedValue(snapshotData?.['freeRange'], 'freeRange', enumType)
      ),
    },
  ];

  const formDefineRightTop = [
    [
      { rowConfig: { gutter: [15, 15] } },
      {
        name: 'usdaGrading',
        label: 'USDA / AAFC Grading',
        type: 'select',
        selectProps: {
          options: sortByKey(
            freshMeatEnumOptions.usdaGrading,
            'enumDisplayName'
          ),
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['usdaGrading'],
          'usdaGrading',
          enumType
        ),
        snapshotData: snapshotData?.['usdaGrading'],
        supplierModifiedData: data?.['usdaGrading'],
        renderPopoverContent: renderPopoverContent(
          'USDA / AAFC Grading',
          snapshotData?.['usdaGrading'],
          data?.['usdaGrading'],
          getFormattedValue(
            snapshotData?.['usdaGrading'],
            'usdaGrading',
            enumType
          )
        ),
        colProps: { xs: 12 },
      },
      {
        name: 'piecesPerPound',
        label: 'Pieces per Pound',
        snapshotData: snapshotData?.['piecesPerPound']?.trim() ?? '',
        supplierModifiedData: data?.['piecesPerPound']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Pieces per Pound',
          snapshotData?.['piecesPerPound']?.trim() ?? '',
          data?.['piecesPerPound']?.trim() ?? ''
        ),
        colProps: { xs: 12 },
      },
    ],
    [
      {
        rowConfig: {
          gutter: [15, 15],
        },
      },
      {
        name: 'dryAged',
        label: 'Dry Aged',
        colProps: { xs: 12 },
        type: 'select',
        selectProps: {
          options: freshMeatEnumOptions.dryAged,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['dryAged'],
          'dryAged',
          enumType
        ),
        snapshotData: snapshotData?.['dryAged'],
        supplierModifiedData: data?.['dryAged'],
        renderPopoverContent: renderPopoverContent(
          'Dry Aged',
          snapshotData?.['dryAged'],
          data?.['dryAged'],
          getFormattedValue(snapshotData?.['dryAged'], 'dryAged', enumType)
        ),
      },
      {
        name: 'coldSmoked',
        label: 'Cold Smoked',
        colProps: { xs: 12 },
        type: 'select',
        selectProps: {
          options: freshMeatEnumOptions.coldSmoked,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['coldSmoked'],
          'coldSmoked',
          enumType
        ),
        snapshotData: snapshotData?.['coldSmoked'],
        supplierModifiedData: data?.['coldSmoked'],
        renderPopoverContent: renderPopoverContent(
          'Cold Smoked',
          snapshotData?.['coldSmoked'],
          data?.['coldSmoked'],
          getFormattedValue(
            snapshotData?.['coldSmoked'],
            'coldSmoked',
            enumType
          )
        ),
      },
    ],
  ];

  const formDefineRightBottom = [
    {
      name: 'totalMeatProteinMinPercentage',
      label: 'Total Meat Protein Equivalent Min Percentage',
      snapshotData:
        snapshotData?.['totalMeatProteinMinPercentage']?.trim() ?? '',
      supplierModifiedData:
        data?.['totalMeatProteinMinPercentage']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Total Meat Protein Equivalent Min Percentage',
        snapshotData?.['totalMeatProteinMinPercentage']?.trim() ?? '',
        data?.['totalMeatProteinMinPercentage']?.trim() ?? ''
      ),
    },
    {
      name: 'actualTotalMeatContentMinPercentage',
      label: 'Actual Total Meat Content Min Percentage',
      snapshotData:
        snapshotData?.['actualTotalMeatContentMinPercentage']?.trim() ?? '',
      supplierModifiedData:
        data?.['actualTotalMeatContentMinPercentage']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Actual Total Meat Content Min Percentage',
        snapshotData?.['actualTotalMeatContentMinPercentage']?.trim() ?? '',
        data?.['actualTotalMeatContentMinPercentage']?.trim() ?? ''
      ),
    },
    {
      name: 'actualLeanMeatMinPercentage',
      label: 'Actual Lean Mean Min Percentage',
      snapshotData: snapshotData?.['actualLeanMeatMinPercentage']?.trim() ?? '',
      supplierModifiedData: data?.['actualLeanMeatMinPercentage']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Actual Lean Mean Min Percentage',
        snapshotData?.['actualLeanMeatMinPercentage']?.trim() ?? '',
        data?.['actualLeanMeatMinPercentage']?.trim() ?? ''
      ),
    },
    {
      name: 'fatMaxPercentage',
      label:
        'Fat maximum percentage: total fat percentage on nutritional analyses',
      snapshotData: snapshotData?.['fatMaxPercentage']?.trim() ?? '',
      supplierModifiedData: data?.['fatMaxPercentage']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Fat maximum percentage: total fat percentage on nutritional analyses',
        snapshotData?.['fatMaxPercentage']?.trim() ?? '',
        data?.['fatMaxPercentage']?.trim() ?? ''
      ),
    },
    {
      name: 'addedRindsMaxPercentage',
      label:
        'Added rinds maximum percentage: added rind shall not exceed value',
      snapshotData: snapshotData?.['addedRindsMaxPercentage']?.trim() ?? '',
      supplierModifiedData: data?.['addedRindsMaxPercentage']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Added rinds maximum percentage: added rind shall not exceed value',
        snapshotData?.['addedRindsMaxPercentage']?.trim() ?? '',
        data?.['addedRindsMaxPercentage']?.trim() ?? ''
      ),
    },
    {
      name: 'addedGelatineMaxPercentage',
      label: 'Added gelatin maximum percentage',
      snapshotData: snapshotData?.['addedGelatineMaxPercentage']?.trim() ?? '',
      supplierModifiedData: data?.['addedGelatineMaxPercentage']?.trim() ?? '',
      renderPopoverContent: renderPopoverContent(
        'Added gelatin maximum percentage',
        snapshotData?.['addedGelatineMaxPercentage']?.trim() ?? '',
        data?.['addedGelatineMaxPercentage']?.trim() ?? ''
      ),
    },
  ];

  return (
    <Form
      className='qa-fresh-meat'
      form={formInst}
      style={{ paddingRight: 5 }}
      layout='vertical'
    >
      <WithLoading loading={loading || saveLoading}>
        <EditContainer
          type={EDIT_SECTION.QA_FRESH_MEAT}
          productId={productId}
          handleCancel={handleCancel}
          handleSave={handleSave}
          editHook={editHook}
          float
        >
          {(isEdit) => {
            return (
              <EditTabSectionWrapper.CheckerWrapper
                isEdit={isEdit}
                type={EDIT_SECTION.QA_FRESH_MEAT}
              >
                <Row>
                  <Col style={{ flex: 1 }}>
                    <BasicFormItemList
                      allowEdit={isEdit}
                      formDefine={formDefineLeft}
                      labelFn={labelFn}
                      isLoadingSnapshot={isLoadingSnapshotFreshMeat}
                    />
                  </Col>
                  <Col flex='50px' />
                  <Col style={{ flex: 1 }}>
                    <Row>
                      <Col xs={24}>
                        <BasicFormItemList
                          allowEdit={isEdit}
                          formDefine={formDefineRightTop}
                          labelFn={labelFn}
                          isLoadingSnapshot={isLoadingSnapshotFreshMeat}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <SectionWrapper title='Percentage'>
                          <BasicFormItemList
                            allowEdit={isEdit}
                            formDefine={formDefineRightBottom}
                            labelFn={labelFn}
                            isLoadingSnapshot={isLoadingSnapshotFreshMeat}
                          />
                        </SectionWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </EditTabSectionWrapper.CheckerWrapper>
            );
          }}
        </EditContainer>
      </WithLoading>
    </Form>
  );
};

const labelFn = (labelText) => {
  return <Typography.Text strong>{labelText && labelText}</Typography.Text>;
};

export default QaFreshMeat;
