import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CloneStepControl from '../../controls/wf-template-full/CloneStepControl';
import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import { notification } from 'antd';
import * as api from 'config/axios';
import * as services from 'services/wfTemplate';
import * as wfTemplateActions from 'pages/workflow-template-full/controllers/actions';

const CloneStepSection = (props) => {
  const dispatch = useDispatch();
  const { stepId, wfId, mainWorkflowId } = props;

  const onCloneStep = () => {
    const params = {
      workflowTemplateId: wfId,
      workflowStepId: stepId,
    };
    services
      .cloneWorkFlowStep(params)
      .then((response) => {
        if (response?.isSuccess) {
          dispatch(wfTemplateActions.getWfTemplateVersions(mainWorkflowId));
          dispatch(wfTemplateActions.setActiveStepKey(response?.data?.id));
          // dispatch(actionsWf.reloadGridWf(true));
          // dispatch(actionsWf.setWfItemSelected([]));
          notification.success({ message: 'Clone Workflow Step success' });
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        // setTimeout(() => {
        //   dispatch(actionsWf.reloadGridWf(false));
        // });
      });
  };

  return <CloneStepControl onClick={onCloneStep} />;
};

export default CloneStepSection;
