import { DigitalAssetTypeImage } from 'static/FileType';

import {
  ASSET_TYPE,
  ASSET_SUBTYPE,
  COMPANY_SUBTYPE,
  DOCUMENT_SUBTYPE,
  MULTIMEDIA_SUBTYPE,
} from 'static/Constants';

const documentType = [
  'txt',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
];
const zipType = ['zip', 'rar'];
const videoType = ['mp4', 'mov', 'avi'];
const audioType = ['mp3'];
const imageType = [
  'jpeg',
  'png',
  'gif',
  'tiff',
  'psd',
  'bmp',
  'ico',
  'tga',
  'jpg',
  'tif',
  'bmp',
  'cr2',
  'cur',
  'dds',
  'dng',
  'svg',
];
const emailType = ['msg'];

export const setAssetType = (assetType) => {
  if (!assetType) return null;
  if (documentType.indexOf(assetType.toLowerCase()) !== -1) return 'Document';
  if (zipType.indexOf(assetType.toLowerCase()) !== -1) return 'ZipRar';
  if (videoType.indexOf(assetType.toLowerCase()) !== -1) return 'Video';
  if (audioType.indexOf(assetType.toLowerCase()) !== -1) return 'Audio';
  if (imageType.indexOf(assetType.toLowerCase()) !== -1) return 'Image';
  if (emailType.indexOf(assetType.toLowerCase()) !== -1) return 'Email';
  return null;
};

export const mapFileTypeToImage = (assetType) => {
  const assetFile = DigitalAssetTypeImage.filter(
    (file) => file.fileType?.toLowerCase() === assetType?.toLowerCase()
  );

  return (
    assetFile?.[0]?.fileImg ||
    DigitalAssetTypeImage[DigitalAssetTypeImage.length - 1].fileImg
  );
};

export const mapOriginalFileTypeToImage = (fileName) => {
  const assetFile = DigitalAssetTypeImage.filter((file) => {
    const ext = fileName?.toLowerCase().split('.').pop();
    return ext.includes(file.fileType);
  });

  return (
    assetFile?.[0]?.fileImg ||
    DigitalAssetTypeImage[DigitalAssetTypeImage.length - 1].fileImg
  );
};

/**
 *
 * @param {*} item
 * @param {*} disabled
 * @returns //* disabled option if needed
 */
const mapToObjectField = (item, disabled) => {
  return { value: item, disabled };
};

/**
 *
 * @param {*} assetType
 * @param {*} isAddPlano
 * @returns //* return subtype from asset type check plano type first
 */
export const mapTypeToAssetSubTypeCheckPlanoType = (assetType, isAddPlano) => {
  if (!assetType) return [];
  switch (assetType) {
    case ASSET_TYPE?.[0]: //? asset
      return isAddPlano
        ? ASSET_SUBTYPE.map((item) => mapToObjectField(item, false))
        : ASSET_SUBTYPE.map((item) => {
            if (item === 'Planogram') {
              return mapToObjectField(item, true);
            } else {
              return mapToObjectField(item, false);
            }
          });

    case ASSET_TYPE?.[1]: //? company
      return COMPANY_SUBTYPE.map((item) => mapToObjectField(item, false));

    case ASSET_TYPE?.[2]: //? document
      return DOCUMENT_SUBTYPE.map((item) => mapToObjectField(item, false));

    case ASSET_TYPE?.[3]: //? multimedia
      return MULTIMEDIA_SUBTYPE.map((item) => mapToObjectField(item, false));

    default:
      return [];
  }
};

/**
 *
 * @param {*} assetType
 * @returns //* return subtype from asset type without check plano type first
 */
export const mapTypeToAssetSubType = (assetType) => {
  if (!assetType) return [];

  switch (assetType) {
    case ASSET_TYPE?.[0]: //? asset
      return ASSET_SUBTYPE.map((item) => mapToObjectField(item, false));

    case ASSET_TYPE?.[1]: //? company
      return COMPANY_SUBTYPE.map((item) => mapToObjectField(item, false));

    case ASSET_TYPE?.[2]: //? document
      return DOCUMENT_SUBTYPE.map((item) => mapToObjectField(item, false));

    case ASSET_TYPE?.[3]: //? multimedia
      return MULTIMEDIA_SUBTYPE.map((item) => mapToObjectField(item, false));

    default:
      return [];
  }
};

/**
 *
 * @param {*} fileObj
 * @returns //* return file type
 */
export const getFileType = (fileObj) => {
  if (!fileObj) return null;

  return fileObj?.name.split('.').pop();
};

export const isImageType = (file) => {
  if (!file) return null;
  const fileType = file.lastIndexOf('.');
  const imgType = file?.slice(fileType + 1)?.toLowerCase();
  return imageType.includes(imgType);
};
