/*
 * Share Messages
 *
 * This contains all the text for Share framework.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.share';

export default defineMessages({
  manageSharing: {
    id: `${scope}.ribbon.manageSharing`,
    defaultMessage: 'Manage Sharing',
  },
  removeSharing: {
    id: `${scope}.ribbon.removeSharing`,
    defaultMessage: 'Remove Sharing',
  },
  getSharingErrorMessage: {
    id: `${scope}.message.getSharingErrorMessage`,
    defaultMessage: 'Cannot get sharing data',
  },
  getSharingEmptyData: {
    id: `${scope}.message.getSharingEmptyData`,
    defaultMessage: 'Cannot get sharing data',
  },
  removeSelectedShare: {
    id: `${scope}.manage.removeSelectedShare`,
    defaultMessage: 'Remove selected share',
  },
  deleteShareConfirm: {
    id: `${scope}.message.deleteShareConfirm`,
    defaultMessage: 'Are you sure to delete?',
  },
  deleteShareError: {
    id: `${scope}.message.deleteShareError`,
    defaultMessage: 'Cannot delete',
  },
  removeShareConfirm: {
    id: `${scope}.ribbon.manageSharingDeleteShareConfirm`,
    defaultMessage: 'All sharing will be removed. Are you sure to remove?',
  },
  removeShareSuccess: {
    id: `${scope}.ribbon.removeShareSuccess`,
    defaultMessage: 'Remove sharing successfully',
  },
  removeShareError: {
    id: `${scope}.ribbon.removeShareError`,
    defaultMessage: 'Cannot remove sharing.',
  },
  removeShareErrorEntity: {
    id: `${scope}.message.removeShareErrorEntity`,
    defaultMessage: '. Because {entityName} has never been shared yet.',
  },
  removeShareErrorResponse: {
    id: `${scope}.message.removeShareErrorResponse`,
    defaultMessage: 'There are no shared to remove.',
  },
  entityHeading: {
    id: `${scope}.manage.entityHeading`,
    defaultMessage: 'Entity',
  },
  shareWithHeading: {
    id: `${scope}.manage.shareWithHeading`,
    defaultMessage: 'Shared with',
  },
  shareAtHeading: {
    id: `${scope}.manage.shareAtHeading`,
    defaultMessage: 'Shared at',
  },
  shareButton: {
    id: `${scope}.component.btn.share`,
    defaultMessage: 'Share',
  },
  removeButton: {
    id: `${scope}.component.btn.remove`,
    defaultMessage: 'Remove',
  },
});
