import { useDispatch } from 'react-redux';

import { defaultSearchText } from 'redux/global/reducer';
import * as globalActions from 'redux/global/actions';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';
import * as folderActions from '../controllers/actions';

import { useGridView } from 'hooks/useGridView';

import { getLinkPathByEntityType } from 'utils/common/linkBuilder';
import { buildHierarchyBreadcrumb } from './utils';
import { useFolderByLevel } from 'hooks/useFolderByLevel';

export const useFolderGridItem = (props) => {
  const dispatch = useDispatch();

  const { dataDetail, folderOption } = props;

  const { clearGridSelection } = useGridView();
  const { handleFetchFolderByLevel } = useFolderByLevel();

  const openEntity = () => {
    const { id, type } = dataDetail;

    if (!id) return;
    if (!type) return;

    if (type === 'query') return openQueryItem(dataDetail);

    const path = getLinkPathByEntityType(type);
    window.open(`${window.location.origin}${path}/${id}`).focus();
  };

  const openNestedFolderGrid = async () => {
    const { id } = dataDetail;

    const breadcrumbs = await buildHierarchyBreadcrumb(dataDetail);

    dispatch(gridViewActions.togglePreventCallApiGridView(true));
    dispatch(globalActions.resetSearchText(defaultSearchText));

    handleFetchFolderByLevel({
      folderId: id,
      folderOption,
      successCallback: () => {
        // Need to review
        dispatch(folderActions.updateFolderBreadcrumb(breadcrumbs));
        // await sleep(400);
        clearGridSelection();
      },
    });
  };

  const openQueryItem = (dataDetail) => {
    if (!dataDetail) return;

    const { id } = dataDetail;

    const url = `${window.location.origin}/execute-query/${id}`;

    window.open(url).focus();
    //* query execution are handled in execute-query/index
  };

  return {
    openEntity,
    openNestedFolderGrid,
  };
};
