import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Row,
  Button,
  Checkbox,
  Col,
  Spin,
  Radio,
  Tooltip,
  Input,
  Typography,
  notification,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import AgGrid from 'common/components/ag-grid/AgGrid';
import {
  Form,
  dialogOkFunction,
  dialogFunction,
  CustomNotification,
} from 'common/components';
import DigitalRight from 'assets/DRM.png';

import * as selectorGlobal from 'redux/global/selectors';
import * as actionsGlobal from 'redux/global/actions';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';

import {
  sum,
  findListItems,
  getResultCanAccess,
  filterColumnsGrid,
} from './utils';
import { GRID_ID } from 'common/components/grid-view/utils';
import * as endpointsManageSharing from 'services/manageSharing/endpoints';
import * as api from 'config/axios';

import messages from 'i18n/messages/home';
import { useIntl, FormattedMessage } from 'react-intl';

import { useDispatchReloadPage } from 'hooks/useReloadPage';

import './styles.less';

import { AbilityContext, Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
const { Paragraph } = Typography;

const fieldMemberList = ['memberName', 'glns'];
const fieldUserList = ['fullName', 'memberName'];
const DEFAULT_PAGE_CURRENT = [1, 20];

const ShareModal = (props) => {
  const { visibleHook, detailCurrentItemsSelection, disabledEditableOption } =
    props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ability = useContext(AbilityContext);
  const reloadPage = useDispatchReloadPage();

  const indexRadio = ability.can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MEMBER)
    ? 1
    : 2;

  const [visible, setVisible] = visibleHook;
  const [showGrid, setShowGrid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataListSelection, setDataListSelection] = useState([]);
  const [dataGrid, setDataGrid] = useState([]);
  const [radioList, setRadioList] = useState(indexRadio);
  const [searchValue, setSearchValue] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  const selectedMapping = detailCurrentItemsSelection?.map((item) => {
    let entityName = '';
    switch (item?.type?.toLowerCase()) {
      case 'product':
        entityName = item?.productName;
        break;
      case 'asset':
        entityName = item?.assetName;
        break;
      default:
        entityName = item?.folderName;
    }
    return {
      idSelected: item?.id,
      entityName,
    };
  });

  const convertSelectedArraytoObject = (objectArray, property) => {
    if (selectedMapping.length === 0) return null;
    return objectArray.reduce((acc, info) => {
      const key = info[property];
      return { ...acc, [key]: info };
    }, {});
  };

  const infoSelectedObj = convertSelectedArraytoObject(
    selectedMapping,
    'idSelected'
  );

  const onCancelHandler = () => {
    setLoading(false);
    setVisible(false);
    setDataListSelection([]);
  };

  const columnsMember = filterColumnsGrid(
    useSelector(selectorGlobal.selectColumnsMember()),
    fieldMemberList
  );

  const columnsUser = filterColumnsGrid(
    useSelector(selectorGlobal.selectColumnsUser()),
    fieldUserList
  );

  const [pageNumberShareableMember] = useSelector(
    gridViewSelectors.makeSelectPageCurrent()
  )[GRID_ID.SHAREABLE_MEMBER_LIST];
  const [pageNumberShareableUser] = useSelector(
    gridViewSelectors.makeSelectPageCurrent()
  )[GRID_ID.SHAREABLE_USER_LIST];

  const drmList = detailCurrentItemsSelection
    ? detailCurrentItemsSelection?.filter((item) => item?.drm)
    : [];
  const isDRM =
    drmList.length === 1 && detailCurrentItemsSelection.length === 1
      ? 'single'
      : drmList.length >= 1 && detailCurrentItemsSelection.length > 1
      ? 'multi'
      : 'none';
  function shareDrmDialog() {
    dialogFunction({
      type: 'warn',
      content: (
        <div style={{ fontSize: 13 }}>
          <img
            src={DigitalRight}
            alt='digital-right'
            height={20}
            width={20}
            style={{ marginRight: 5 }}
          />
          {isDRM === 'single'
            ? intl.formatMessage(messages.drmSingleShare)
            : intl.formatMessage(messages.drmMultipleShare)}
        </div>
      ),

      okText: 'Cancel',
      onCancel: handleModalShared,
      cancelText: 'OK',
    });
  }

  const renderErrorListMessage = (errorMessage) => {
    return errorMessage.map((item, idx) => {
      return (
        <div key={idx}>
          <Paragraph style={{ margin: '0' }}>
            {infoSelectedObj[item.id].idSelected} -{' '}
            {infoSelectedObj[item.id].entityName} - {item.reason}
          </Paragraph>
        </div>
      );
    });
  };

  const handleModalShared = () => {
    const sumProductCounts = sum(detailCurrentItemsSelection, 'productCount');
    const sumMemberCounts = sum(detailCurrentItemsSelection, 'memberCount');
    const sumAssetCounts = sum(detailCurrentItemsSelection, 'assetCount');

    const selectedItems = findListItems(dataListSelection, dataGrid);

    const canAccessProduct = getResultCanAccess(
      selectedItems,
      'canAccessProducts'
    );
    const canAccessMember = getResultCanAccess(
      selectedItems,
      'canAccessMember'
    );
    const canAccessAsset = getResultCanAccess(
      selectedItems,
      'canAccessDigitalAssets'
    );

    if (sumProductCounts > 0 && canAccessProduct === false) {
      dialogConfirm();
    } else if (sumMemberCounts > 0 && canAccessMember === false) {
      dialogConfirm();
    } else if (sumAssetCounts > 0 && canAccessAsset === false) {
      dialogConfirm();
    } else {
      acceptedShare();
    }
  };

  const acceptedShare = () => {
    if (
      (detailCurrentItemsSelection &&
        detailCurrentItemsSelection.length === 0) ||
      (dataListSelection && dataListSelection.length === 0)
    ) {
      dialogOkFunction({
        type: 'error',
        content: (
          <Row>
            {!dataListSelection && dataListSelection.length === 0 && (
              <Col span={24}>
                <FormattedMessage {...messages.shareSelectList} />
              </Col>
            )}
            {detailCurrentItemsSelection &&
              detailCurrentItemsSelection.length === 0 && (
                <Col span={24}>
                  <FormattedMessage {...messages.shareSelectGrid} />
                </Col>
              )}
          </Row>
        ),
      });
    } else {
      setLoading(true);
      let selectedEntities = [];
      detailCurrentItemsSelection &&
        detailCurrentItemsSelection.length > 0 &&
        detailCurrentItemsSelection.forEach((val) => {
          selectedEntities.push({ id: val?.id, type: val.type });
        });
      let params = {
        selectedEntities: selectedEntities,
        isEditable: isEditable,
        isShareable: true,
      };

      if (radioList === 1) {
        Object.assign(params, { sharedMemberIdList: dataListSelection });
      } else {
        Object.assign(params, { sharedUserIdList: dataListSelection });
      }
      api
        .sendPost(endpointsManageSharing.SHARE_BUTTON, params)
        .then((response) => {
          if (response.isSuccess) {
            CustomNotification.success(
              intl.formatMessage(messages.configShareSuccess)
            );          
            // reload page
            reloadPage();
          } else {
            if (
              !response?.data ||
              response?.data?.listItemsCannotShare?.length === 0
            ) {
              CustomNotification.error(
                intl.formatMessage(messages.sharingAssetFailedMessage)
              );
              return;
            }
            notification.error({
              description: renderErrorListMessage(
                response.data.listItemsCannotShare
              ),
              style: { width: 415 },
              className: 'notification-error--custom',
            });
          }
          setVisible(false);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setVisible(false);
          CustomNotification.error(
            intl.formatMessage(messages.sharingAssetFailedMessage)
          );
        });
    }
  };

  const getDataFromGrid = (data) => {
    setDataGrid(data);
  };

  const resetPageCurrent = (typeGrid) => {
    // When switching member list, will clear page current of user list. Otherwise will clear current of member list
    if (typeGrid === 1) {
      dispatch(
        gridViewActions.updatePageCurrentSharableUserList(DEFAULT_PAGE_CURRENT)
      );
    } else {
      dispatch(
        gridViewActions.updatePageCurrentSharableMemberList(
          DEFAULT_PAGE_CURRENT
        )
      );
    }
  };

  const onChangeRadioList = (e) => {
    setRadioList(e.target.value);
    setDataListSelection([]);

    // reset search text
    setSearchValue('');
    form.setFieldsValue({
      ...form.getFieldsValue(),
      searchText: '',
    });

    resetPageCurrent(e.target.value);
  };

  const handleCallbackDataListSelection = (dataList) => {
    let dataListNew = [...dataList];
    setDataListSelection(dataListNew);
  };

  const onSearchHandler = (value) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      searchText: value,
    });
    setSearchValue(value);
  };

  const handleChangeIsEditable = (e) => {
    setIsEditable(e.target.checked);
  };

  const dialogConfirm = () => {
    dialogFunction({
      type: 'warn',
      content:
        radioList === 1
          ? intl.formatMessage(messages.messageMemberPermissionsShare)
          : intl.formatMessage(messages.messageUserPermissionsShare),
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: acceptedShare,
    });
  };

  useEffect(() => {
    if (visible) {
      dispatch(actionsGlobal.memberForSharingColumnInfo('member-for-sharing'));
      dispatch(actionsGlobal.userForSharingColumnInfo('user-for-sharing'));
      setIsEditable(false);
      // delay show ag grid to avoid item selection bug
      setTimeout(() => {
        setShowGrid(true);
      }, 100);
    } else {
      setShowGrid(false);
    }
  }, [dispatch, visible]);

  const requestParams = {
    search: { searchText: searchValue },
    shareId:
      detailCurrentItemsSelection && detailCurrentItemsSelection.length === 1
        ? detailCurrentItemsSelection[0]?.id
        : 0,
    shareType:
      detailCurrentItemsSelection && detailCurrentItemsSelection.length === 1
        ? detailCurrentItemsSelection[0]?.type
        : null,
  };

  const searchPlaceholder =
    radioList === 1
      ? messages.shareSearchPlaceholderMember
      : messages.shareSearchPlaceholderUser;

  const pathname = window.location.pathname;
  const isEnableEditableCheckbox =
    pathname !== '/reportings' &&
    pathname !== '/reportings/owned' &&
    pathname !== '/reportings/shared' &&
    window.location.pathname !== '/products' &&
    !disabledEditableOption;

  return (
    <Modal
      width={'80vw'}
      bodyStyle={{ height: '70vh' }}
      title={'Share'}
      visible={visible}
      closable={!loading}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={onCancelHandler}
      footer={[
        <Button disabled={loading} key='cancel' onClick={onCancelHandler}>
          <FormattedMessage {...messages.shareCancel} />
        </Button>,
        <Button
          disabled={
            (detailCurrentItemsSelection &&
              detailCurrentItemsSelection.length === 0) ||
            (dataListSelection && dataListSelection.length === 0)
          }
          key='shared'
          type='primary'
          onClick={() => {
            isDRM === 'none' ? handleModalShared() : shareDrmDialog();
          }}
          loading={loading}
        >
          <FormattedMessage {...messages.shareShared} />
        </Button>,
      ]}
    >
      <Spin spinning={loading} tip='Shared...' size='large'>
        <Form form={form} name='shared'>
          <Row>
            {isEnableEditableCheckbox && (
              <Col span={24}>
                <Form.Item
                  label={<FormattedMessage {...messages.shareEditable} />}
                  valuePropName='checked'
                >
                  <Checkbox onChange={handleChangeIsEditable} />
                  <Tooltip
                    placement='right'
                    title={intl.formatMessage(
                      window.location.pathname.includes('/digital-media') ||
                        window.location.pathname.includes('asset')
                        ? {
                            ...messages.shareEditableAssetTooltip,
                          }
                        : {
                            ...messages.shareEditableTooltip,
                          }
                    )}
                  >
                    <QuestionCircleOutlined
                      style={{
                        margin: '0px 8px',
                        color: 'rgba(0,0,0,.45)',
                      }}
                    />
                  </Tooltip>
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                label={<FormattedMessage {...messages.shareChooseList} />}
              >
                <Radio.Group value={radioList} onChange={onChangeRadioList}>
                  <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.MEMBER}>
                    <Radio value={1}>
                      <FormattedMessage {...messages.shareMemberList} />
                    </Radio>
                  </Can>
                  <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.USER}>
                    <Radio value={2}>
                      <FormattedMessage {...messages.shareUserList} />
                    </Radio>
                  </Can>
                </Radio.Group>
              </Form.Item>
              <Form.Item name='searchText'>
                <Input.Search
                  placeholder={intl.formatMessage(searchPlaceholder)}
                  onSearch={onSearchHandler}
                  style={{ maxWidth: 500 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {showGrid &&
          radioList === 1 &&
          columnsMember &&
          columnsMember.length > 0 && (
            <Row style={{ height: 'calc(70vh - 160px)' }}>
              <AgGrid
                sortAndFilterServerside
                columnDefs={columnsMember}
                urlGrid={
                  endpointsManageSharing.GET_AVAILABLE_MEMBERS_FOR_SHARING
                }
                urlGridDistinct={
                  endpointsManageSharing.GET_AVAILABLE_MEMBERS_FOR_SHARING_DISTINCT
                }
                callbackDataListSelection={handleCallbackDataListSelection}
                getDataFromGrid={getDataFromGrid}
                responseParams='data'
                requestParams={requestParams}
                gridId={GRID_ID.SHAREABLE_MEMBER_LIST}
                pageNumberPagination={pageNumberShareableMember}
                isCacheEditMode
              />
            </Row>
          )}
        {showGrid &&
          radioList === 2 &&
          columnsUser &&
          columnsUser.length > 0 && (
            <Row style={{ height: 'calc(70vh - 160px)' }}>
              <AgGrid
                sortAndFilterServerside
                columnDefs={columnsUser}
                urlGrid={endpointsManageSharing.GET_AVAILABLE_USERS_FOR_SHARING}
                urlGridDistinct={
                  endpointsManageSharing.GET_AVAILABLE_USERS_FOR_SHARING_DISTINCT
                }
                callbackDataListSelection={handleCallbackDataListSelection}
                getDataFromGrid={getDataFromGrid}
                responseParams='data'
                requestParams={requestParams}
                gridId={GRID_ID.SHAREABLE_USER_LIST}
                pageNumberPagination={pageNumberShareableUser}
                isCacheEditMode
              />
            </Row>
          )}
      </Spin>
    </Modal>
  );
};

export default ShareModal;
