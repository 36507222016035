import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import * as qaSpecServices from 'services/qaSpec';
import { forwardTo } from 'utils/common/route';

export const qaPetFoodKeys = {
  detail: (productId) => ['qa-spec', parseInt(productId), 'pet-food'],
  snapshot: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-pet-food',
  ],
};

export const useGetQaPetFood = (productId) => {
  return useQuery({
    queryKey: qaPetFoodKeys.detail(productId),
    queryFn: async () => {
      const response = await qaSpecServices.getQaSpecDataPetFood({ productId });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
    enabled: Boolean(productId),
    staleTime: 0,
  });
};

export const useGetSnapshotQaPetFood = (productId) => {
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  return useQuery({
    queryKey: qaPetFoodKeys.snapshot(productId),
    queryFn: async () => {
      const response = await qaSpecServices.getQaPetFoodSnapshot({ productId });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
    enabled: Boolean(productId && isToggleSnapshot),
    staleTime: 0,
  });
};

export const useSaveQaPetFood = () => {
  return useMutation({
    mutationFn: qaSpecServices.saveQaSpecDataPetFood,
  });
};

export const useGetQaSpecPetFoodFormDefine = () => {
  const geometriesFormDefine = [
    {
      name: 'shape',
      label: 'Shape',
      type: 'text',
    },
    {
      name: 'dimension',
      label: 'H x W x D',
      type: 'text',
      rules: [
        {
          validator(_, value) {
            //* REGEX : (N)x(N)x(N)
            //* 3 group N with x between
            //* N : match number, accept '.' : \d*\.?\d+?
            const regex = /^(\d*\.?\d+?)x(\d*\.?\d+?)x(\d*\.?\d+?)$/g;

            const matched = regex.test(value);
            if (!value || matched) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Invalid format'));
          },
        },
      ],
    },
    {
      name: 'weight',
      label: 'Weight',
      type: 'text',
    },
    {
      name: 'variance',
      label: 'Variance',
      type: 'text',
    },
  ];

  const additionalInfoFormDefine = useMemo(() => {
    const layout = {
      labelCol: { xxl: 18, span: 12 },
      wrapperCol: { xxl: 6, span: 12 },
    };
    return [
      [
        { rowConfig: { gutter: [15, 15] } },
        [
          {
            colProps: {
              span: 24,
            },
          },
          {
            name: 'humanGrade',
            label: 'Human Grade',
            type: 'select',
            ...layout,
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
          },
          {
            name: 'containsPalmOil',
            label: 'Contains Palm Oil',
            type: 'select',
            ...layout,
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
          },
          {
            name: 'ingredientsOutsideUs',
            label: 'Ingredients in product from outside the United States',
            type: 'select',
            ...layout,
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
          },
        ],
      ],
    ];
  }, []);

  return {
    geometries: geometriesFormDefine,
    additionalInfo: additionalInfoFormDefine,
  };
};
