/**
 * ! MY-COMPANY MESSAGES
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.company-profile';

export default defineMessages({
  gridTitleLocation: {
    id: `${scope}.components.tabs.contacts.companyLocation.grid.title`,
    defaultMessage: 'Company Locations',
  },
  btnAdd: {
    id: `${scope}.components.tabs.contacts.companyLocation.btn.add`,
    defaultMessage: 'Add',
  },
  btnCancel: {
    id: `${scope}.components.tabs.contacts.companyLocation.btn.cancel`,
    defaultMessage: 'Cancel',
  },
  btnEdit: {
    id: `${scope}.components.tabs.contacts.companyLocation.btn.edit`,
    defaultMessage: 'Edit',
  },
  btnSubmit: {
    id: `${scope}.components.tabs.contacts.companyLocation.btn.submit`,
    defaultMessage: 'Submit',
  },
  labelPrimaryLocation: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.setPrimaryLocation`,
    defaultMessage: 'Set this place as primary location',
  },
  labelType: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.type`,
    defaultMessage: 'Type',
  },
  labelStatus: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.status`,
    defaultMessage: 'Status',
  },
  labelAddress: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.address`,
    defaultMessage: 'Address',
  },
  labelCity: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.city`,
    defaultMessage: 'City',
  },
  labelState: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.state`,
    defaultMessage: 'State',
  },
  labelCountry: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.country`,
    defaultMessage: 'Country',
  },
  labelZipCode: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.zipCode`,
    defaultMessage: 'ZipCode',
  },
  labelFax: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.fax`,
    defaultMessage: 'Fax Number',
  },
  labelPreviewPosition: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.previewPos`,
    defaultMessage: 'Preview Map Coordinates',
  },
  labelEditPosition: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.editPos`,
    defaultMessage: 'Custom Map Coordinates',
  },
  btnEditPosition: {
    id: `${scope}.components.tabs.contacts.companyLocation.btn.editPost`,
    defaultMessage: 'Edit Google Coordinates',
  },
  btnPreviewPosition: {
    id: `${scope}.components.tabs.contacts.companyLocation.btn.btnPreviewPosition`,
    defaultMessage: 'Close Google Coordinates Edit',
  },
  labelLocationName: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.locationName`,
    defaultMessage: 'Location Name',
  },
  labelLocationDescription: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.locationDescription`,
    defaultMessage: 'Location Description',
  },
  labelLatitude: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.latitude`,
    defaultMessage: 'Latitude',
  },
  labelLongitude: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.longitude`,
    defaultMessage: 'Longitude',
  },
  labelGender: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.labelGender`,
    defaultMessage: 'Gender',
  },
  placeHolderAddress: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.address`,
    defaultMessage: 'Please input Address',
  },
  placeHolderCity: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.city`,
    defaultMessage: 'Please input City',
  },
  placeHolderState: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.state`,
    defaultMessage: 'Please input State',
  },
  placeHolderZipCode: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.zipCode`,
    defaultMessage: 'Please input ZipCode',
  },
  placeHolderFax: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.fax`,
    defaultMessage: 'Please input fax number',
  },
  placeHolderLatitude: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.latitude`,
    defaultMessage: 'Please input latitude',
  },
  placeHolderLongitude: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.longitude`,
    defaultMessage: 'Please input longitude',
  },
  placeHolderLocationType: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.locationType`,
    defaultMessage: 'Please choose location type',
  },
  placeHolderLocationName: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.locationName`,
    defaultMessage: 'Please input location name',
  },
  placeHolderLocationDes: {
    id: `${scope}.components.tabs.contacts.companyLocation.placeholder.locationDes`,
    defaultMessage: 'Please input location description',
  },
  //! company contact
  gridTitleContact: {
    id: `${scope}.components.tabs.contacts.companyContact.grid.title`,
    defaultMessage: 'Company Contacts',
  },
  labelIsPublic: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.isPublic`,
    defaultMessage: 'Public Status',
  },
  PublicOptions: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.publicStatus.option.public`,
    defaultMessage: 'Public',
  },
  PrivateOptions: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.publicStatus.option.private`,
    defaultMessage: 'Private',
  },
  OnlyIfSharedOptions: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.publicStatus.option.onlyIfShared`,
    defaultMessage: 'Only if Shared',
  },
  labelIsPrimaryContact: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.isPrimaryContact`,
    defaultMessage: 'Is Primary Contact',
  },
  labelPrefix: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.prefix`,
    defaultMessage: 'Title',
  },
  prefixBlank: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.prefixBlank`,
    defaultMessage: 'Blank',
  },
  labelSuffix: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.suffix`,
    defaultMessage: 'Suffix',
  },
  labelTitle: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.title`,
    defaultMessage: 'Title',
  },
  labelFirstName: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.firstName`,
    defaultMessage: 'First Name',
  },
  labelLastName: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.lastName`,
    defaultMessage: 'Last Name',
  },
  labelDescription: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.description`,
    defaultMessage: 'Description',
  },
  labelPrimanyEmail: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.email`,
    defaultMessage: 'Primary Email',
  },
  labelEmail: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.emailLabel`,
    defaultMessage: 'Email',
  },
  labelAlterEmail: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.alterEmail`,
    defaultMessage: 'Alternate Emails',
  },
  labelPhone: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.phone`,
    defaultMessage: 'Phone Number',
  },
  labelMobile: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.mobile`,
    defaultMessage: 'Mobile Number',
  },
  labelDepartment: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.department`,
    defaultMessage: 'Department',
  },
  labelBirthday: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.birthday`,
    defaultMessage: 'Birthday',
  },
  labelPassword: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.password`,
    defaultMessage: 'Password',
  },
  labelTitle: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.title`,
    defaultMessage: 'Title',
  },
  labelBio: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.bio`,
    defaultMessage: 'Bio',
  },
  labelPosition: {
    id: `${scope}.components.tabs.contacts.companyContact.lbl.position`,
    defaultMessage: 'Position',
  },
  placeHolderPassword: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.password`,
    defaultMessage: 'Please input Password',
  },
  placeHolderEmail: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.email`,
    defaultMessage: 'Please input Email',
  },
  placeHolderFirstName: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.firstName`,
    defaultMessage: 'Please input First Name',
  },
  placeHolderLastName: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.lastName`,
    defaultMessage: 'Please input Last Name',
  },
  placeHolderDescription: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.description`,
    defaultMessage: 'Please input Description',
  },
  placeHolderPhone: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.phone`,
    defaultMessage: 'Please input Phone Number',
  },
  placeHolderMobile: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.mobile`,
    defaultMessage: 'Please input Mobile Number',
  },
  placeHolderDepartment: {
    id: `${scope}.components.tabs.contacts.companyContact.placeholder.department`,
    defaultMessage: 'Please input Department',
  },
  btnSending: {
    id: `${scope}.components.tabs.contacts.btn.sending`,
    defaultMessage: 'Sending',
  },
  //? message
  msgContactCreateSuccess: {
    id: `${scope}.components.tabs.contacts.companyContact.msg.crateSuccess`,
    defaultMessage: 'Created contact successfully!',
  },
  msgContactCreateFail: {
    id: `${scope}.components.tabs.contacts.companyContact.msg.createFail`,
    defaultMessage: 'Failed to create new contact!',
  },
  msgContactUpdateSuccess: {
    id: `${scope}.components.tabs.contacts.companyContact.msg.updateSuccess`,
    defaultMessage: 'Updated contact successfully!',
  },
  msgContactUpdateFail: {
    id: `${scope}.components.tabs.contacts.companyContact.msg.updateFail`,
    defaultMessage: 'Failed to update contact!',
  },

  msgLocationCreateSuccess: {
    id: `${scope}.components.tabs.contacts.companyLocation.msg.crateSuccess`,
    defaultMessage: 'Created location successfully!',
  },
  msgLocationCreateFail: {
    id: `${scope}.components.tabs.contacts.companyLocation.msg.createFail`,
    defaultMessage: 'Failed to create new location!',
  },
  msgLocationUpdateSuccess: {
    id: `${scope}.components.tabs.contacts.companyLocation.msg.updateSuccess`,
    defaultMessage: 'Updated location successfully!',
  },
  msgLocationUpdateFail: {
    id: `${scope}.components.tabs.contacts.companyLocation.msg.updateFail`,
    defaultMessage: 'Failed to update location!',
  },
  msgInvalidFields: {
    id: `${scope}.components.tabs.contacts.companyContact.msg.invalidFields`,
    defaultMessage: 'Please check invalid input fields!',
  },
  msgExcepEdit: {
    id: `${scope}.components.tabs.contacts.companyContact.msg.exceptEdit`,
    defaultMessage: ' Server Error!',
  },
  //? title
  formContactTitleNew: {
    id: `${scope}.components.tabs.contacts.companyContact.form.addNewTitle`,
    defaultMessage: 'Add New Contact',
  },
  formLocationTitleNew: {
    id: `${scope}.components.tabs.contacts.companyLocation.form.addNewTitle`,
    defaultMessage: 'Add New Location',
  },
  formLocationTitle: {
    id: `${scope}.components.tabs.contacts.companyLocation.form.title`,
    defaultMessage: 'Edit Location',
  },
  formContactTitle: {
    id: `${scope}.components.tabs.contacts.companyContact.form.title`,
    defaultMessage: 'Edit Contact',
  },
  downloadContactError: {
    id: `${scope}.components.tabs.contacts.companyContact.download.error`,
    defaultMessage: 'Cannot download contact',
  },
  downloadLocationError: {
    id: `${scope}.components.tabs.contacts.companyLocation.download.error`,
    defaultMessage: 'Cannot download location',
  },
  labelExpiredDate: {
    id: `${scope}.components.tabs.contacts.companyLocation.lbl.expirationDate`,
    defaultMessage: 'Expiry Date',
  },
});
