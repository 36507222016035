import React from 'react';
import { Checkbox } from 'antd';
import selectorUser from 'redux/user/selectors';
import { useSelector } from 'react-redux';

const CheckboxGrid = (props) => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());
  const userId = userInfo?.id;
  const { params } = props;
  return <Checkbox checked={params.value === userId} />;
};

export default CheckboxGrid;
