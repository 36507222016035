import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  error: null,
  advancedSearch: [],
  advancedSearchShow: [],
  advancedSearchFields: [],
  dataColumns: [],
  modeView: '',
  selectedResults: [],
  resultGridPagination: { pageIndex: 1, pageSize: 20 },
  setting: null,
  keepSetting: false,
  keepResultGridPagination: false,
};

/* eslint-disable default-case, no-param-reassign */
const reportingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_MODE_VIEW:
        draft.modeView = action.modeView;
        break;
      case types.SELECT_RESULT:
        draft.selectedResults = action.payload;
        break;
      case types.UPDATE_SETTING:
        draft.setting = action.payload;
        break;
      case types.KEEP_SETTING:
        draft.keepSetting = action.payload;
        break;
      case types.UPDATE_RESULT_GRID_PAGINATION:
        draft.resultGridPagination = action.payload;
        break;
      case types.KEEP_RESULT_GRID_PAGINATION:
        draft.keepResultGridPagination = action.payload;
        break;
      default:
    }
  });

export default reportingReducer;
