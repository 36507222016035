import React, { memo } from 'react';
import { Input, Select, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// component
import { Form, WrapperSelect } from 'common/components';
import {
  SearchGrid,
  SearchGridItemButton,
} from 'common/components/modal/search-entity-modal/components';
// hooks
import { useGetEnumsByEnumName } from 'hooks';
import {
  useGetAllTargetMembers,
  QUERY_KEY_QAS_REQUIRE_FIELD,
} from 'hooks/qas-require-field/useQasRequireField';
// utils
import sortByKey from 'utils/sortByKey';
// qaSpec constants
import { defaultType } from 'pages/qa-spec/constant';
// react-query
import { useQueryClient } from '@tanstack/react-query';

const { Option } = Select;

const FormQasTargetMember = (props) => {
  const {
    formInstance,
    selectedItems,
    setSelectedItems,
    searchText,
    setSearchText,
  } = props;
  const queryClient = useQueryClient();

  const { data: memberData, isFetching: memberLoading } =
    useGetAllTargetMembers(searchText);

  const qaTypeEnums = useGetEnumsByEnumName('ProductOverviewTypeCodeEnum');
  const itemIdName = 'companyId';

  const handleSearchItem = () => {
    const formValue = formInstance.getFieldsValue();

    const searchTextValue = formValue?.searchText;

    if (!searchTextValue) return;

    setSearchText(searchTextValue);
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY_QAS_REQUIRE_FIELD.GET_ALL_TARGET_MEMBER,
        searchTextValue,
      ],
    });
  };

  const handleSelectItem = (itemData) => {
    setSelectedItems((prev) => {
      let nextValue;
      if (prev !== 'init' && prev !== undefined) {
        nextValue = [...prev];
      } else {
        nextValue = [];
      }

      const foundItemIdx = nextValue.findIndex(
        (item) =>
          (item?.[itemIdName] || item?.id) ===
          (itemData?.[itemIdName] || itemData?.id)
      );

      if (foundItemIdx !== -1) {
        nextValue.splice(foundItemIdx, 1);
      } else {
        nextValue = [itemData];
      }

      return nextValue;
    });
  };
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  return (
    <Form form={formInstance} {...layout}>
      <Form.Item
        label={`Select Product Type`}
        name='productType'
        rules={[
          {
            required: true,
            message: 'Please choose product type',
          },
        ]}
      >
        <WrapperSelect>
          {sortByKey(qaTypeEnums, 'enumDisplayName').map((item) => {
            const valueOption = item.enumDisplayName ?? defaultType;
            return (
              <Option value={valueOption} key={valueOption}>
                {valueOption}
              </Option>
            );
          })}
        </WrapperSelect>
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            label='Member Name'
            name='searchText'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Input onPressEnter={handleSearchItem} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <SearchGridItemButton
            type='primary'
            onClick={handleSearchItem}
            style={{ marginLeft: 8 }}
          >
            <SearchOutlined />
          </SearchGridItemButton>
        </Col>
      </Row>
      <Row style={{ width: '100%', height: 'calc(80vh - 235px)' }}>
        <SearchGrid
          items={memberData?.allMembers}
          handleSelectItem={handleSelectItem}
          selectedItems={selectedItems}
          isLoading={memberLoading}
          searchType={'member'}
          itemIdName={itemIdName}
          showSearchHint={memberData?.allMembers?.length >= 0 ? false : true}
        />
      </Row>
    </Form>
  );
};

export default memo(FormQasTargetMember);
