import produce from 'immer';
import * as types from './constants';

export const initialState = {
  loading: false,
  loadingPost: false,
  categoryColumns: [],
  error: false,
  search: '',
  total: 0,
  posts: [],
  postColumns: [],
  pageSize: 20,
  pageNumber: 1,
  helpCategory: null,
  isDetailsView: false,
  postContentDetail: null,
  selectCategory: [],
  enableEdit: false,
  isEdit: false,
  isVisibleCategory: false,
  startSubmit: 'idle',
  submitSuccess: 'idle',
  submitError: null,
  isCreate: false,
  isCloseCategoryPanel: false,

  helpMaintenancePost: {
    isCreate: false,
    isEdit: false,
    isConfirmEdit: false,

    statusCreate: 'idle',
    errorCreate: null,
    idPost: null,
  },
  isUploadHelpMediaOpen: false,
};

const defaultHelpMaintenancePost = {
  isCreate: false,
  isEdit: false,
  isConfirmEdit: false,

  statusCreate: 'idle',
  errorCreate: null,
  idPost: null,
};

/* eslint-disable default-case, no-param-reassign */
const helpMaintenanceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN:
        draft.loading = true;
        draft.categoryColumns = [];
        break;
      case types.GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_SUCCESS:
        draft.loading = false;
        draft.categoryColumns = action.columns;
        break;
      case types.GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_ERROR:
        draft.loading = false;
        draft.categoryColumns = [];
        draft.error = action.error;
        break;
      case types.GET_POST_GRID:
        draft.loadingPost = true;
        draft.error = false;
        draft.pageSize = action.pageSize;
        draft.pageNumber = action.pageIndex;
        draft.search = action.search;
        break;
      case types.GET_POST_GRID_SUCCESS:
        draft.loadingPost = false;
        draft.posts = action.posts;
        draft.total = action.total;
        break;
      case types.GET_POST_GRID_ERROR:
        draft.loadingPost = false;
        draft.total = 0;
        draft.posts = [];
        draft.error = action.error;
        break;
      case types.GET_POST_GRID_COLUMN:
        draft.loading = true;
        draft.postColumns = [];
        break;
      case types.GET_POST_GRID_COLUMN_SUCCESS:
        draft.loading = false;
        draft.postColumns = action.columns;
        break;
      case types.GET_POST_GRID_COLUMN_ERROR:
        draft.loading = false;
        draft.postColumns = [];
        draft.error = action.error;
        break;
      case types.DISABLED_VIEW_MODE:
        draft.isDetailsView = action.isDetailsView;
        break;
      case types.GET_POST_CONTENT_DETAIL:
        draft.loading = true;
        draft.postContentDetail = null;
        draft['helpMaintenancePost'].isEdit = false;
        draft['helpMaintenancePost'].statusCreate = 'idle';

        break;
      case types.GET_POST_CONTENT_DETAIL_SUCCESS:
        draft.loading = false;
        draft.postContentDetail = action.data;
        break;
      case types.GET_POST_CONTENT_DETAIL_ERROR:
        draft.loading = false;
        draft.postContentDetail = null;
        draft.error = action.error;
        break;
      case types.CHANGE_SELECT_CATEGORY:
        draft.selectCategory = action.data;
        break;
      case types.ENABLE_EDIT_MODE:
        draft.enableEdit = action.data;
        break;
      case types.TOGGLE_EDIT_CATEGORY:
        draft.isEdit = !draft.isEdit;
        break;
      case types.TOGGLE_CREATE_CATEGORY:
        draft.isCreate = true;
        break;
      case types.CLOSE_CREATE_CATEGORY:
        draft.isCreate = false;
        draft.isCloseCategoryPanel = false;
        break;
      case types.CLOSE_EDIT_CATEGORY:
        draft.isEdit = false;
        draft.isCloseCategoryPanel = false;
        break;
      case types.CLOSE_CATEGORY_PANEL:
        draft.isCloseCategoryPanel = true;
        break;
      case types.SUBMIT_CATEGORY:
        draft.startSubmit = 'starting';
        draft.submitSuccess = 'idle';
        draft.submitError = null;
        break;
      case types.SUBMIT_CATEGORY_SUCCESS:
        draft.startSubmit = 'finish';
        draft.submitSuccess = 'success';
        break;
      case types.SUBMIT_CATEGORY_ERROR:
        draft.startSubmit = 'finish';
        draft.submitSuccess = 'error';
        draft.submitError = action.error;
        break;
      case types.SUBMIT_CATEGORY_FINISH:
        draft.startSubmit = 'idle';
        draft.submitSuccess = 'idle';
        draft.submitError = null;
        break;
      case types.GET_HELP_MAINTENANCE_CATEGORY_GRID:
        draft.loading = true;
        draft.helpCategory = null;
        draft.selectCategory = [];
        break;
      case types.GET_HELP_MAINTENANCE_CATEGORY_GRID_SUCCESS:
        draft.loading = false;
        draft.helpCategory = action.data;
        break;
      case types.GET_HELP_MAINTENANCE_CATEGORY_GRID_ERROR:
        draft.loading = false;
        draft.helpCategory = null;
        draft.error = action.error;
        break;
      case types.UPDATE_CATEGORY_PANEL:
        draft.isVisibleCategory = action.isVisibleCategory;
        break;

      case types.ENABLE_HELP_MAINTENANCE_CREATE_POST:
        draft['helpMaintenancePost'].isCreate = true;
        draft['helpMaintenancePost'].isEdit = false;
        break;

      case types.ENABLE_HELP_MAINTENANCE_EDIT_POST:
        draft['helpMaintenancePost'].isEdit = true;
        draft['helpMaintenancePost'].isCreate = false;
        break;

      case types.DISABLE_HELP_MAINTENANCE_EDIT_POST:
        draft['helpMaintenancePost'].isEdit = false;
        draft['helpMaintenancePost'].isCreate = false;
        break;

      case types.SAVE_HELP_MAINTENANCE_POST:
        draft['helpMaintenancePost'].statusCreate = 'loading';
        draft['helpMaintenancePost'].isConfirmEdit = false;
        break;

      case types.SAVE_HELP_MAINTENANCE_POST_SUCCESS:
        draft['helpMaintenancePost'].statusCreate = 'success';
        draft['helpMaintenancePost'].idPost = action.data;
        // draft['helpMaintenancePost'].isCreate = false;
        draft['helpMaintenancePost'].isEdit = false;
        break;

      case types.SAVE_HELP_MAINTENANCE_POST_ERROR:
        draft['helpMaintenancePost'].statusCreate = 'error';
        draft['helpMaintenancePost'].isConfirmEdit = false;
        draft['helpMaintenancePost'].errorCreate = action.error;
        break;

      case types.SAVE_ID_POST:
        draft['helpMaintenancePost'].statusCreate = 'idle';
        draft['helpMaintenancePost'].idPost = action.data;
        break;

      case types.RESET_DATA_POST:
        draft['helpMaintenancePost'] = defaultHelpMaintenancePost;
        break;

      case types.CONFIRM_CHANGE_UPDATE:
        draft['helpMaintenancePost'].isConfirmEdit = true;
        break;

      case types.UPDATE_HELP_ASSET_FILE_SUCCESS:
        draft.helpAssetFiles = action.payload;
        break;
      case types.TOGGLE_HELP_UPLOAD_MEDIA:
        draft.isUploadHelpMediaOpen = action.status;
        break;

      case types.GET_HELP_ASSETS_SUCCESS:
        draft.helpAssetFiles = action.payload;
        break;
      default:
        break;
    }
  });

export default helpMaintenanceReducer;
