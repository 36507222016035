import React from 'react';

import { useSelector } from 'react-redux';

import { Can } from 'context/Can';

import { RibbonSection } from 'common/components';

import { EditTransitionPageButton, CreateTransitionPageButton } from '.';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const ManipulateTransitionPageGridSection = (props) => {
  const selectedTransitionPageDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const dataLength = selectedTransitionPageDetail?.length;

  const disableEditButton = dataLength > 1 || dataLength === 0;

  const selectedTransitionPageIds = selectedTransitionPageDetail?.map(
    (transitionItem) => transitionItem?.transitionPageId
  );

  return (
    <Can I={ABILITY_ACTION.MANAGE} a={ABILITY_SUBJECT.ALL}>
      <RibbonSection>
        <EditTransitionPageButton
          disabled={disableEditButton}
          selectedTransitionPageIds={selectedTransitionPageIds}
        />

        <CreateTransitionPageButton />
      </RibbonSection>
    </Can>
  );
};

export default ManipulateTransitionPageGridSection;
