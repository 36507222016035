import React from 'react';

import { Row, Col } from 'antd';

import { SocialLink } from 'common/components/';

const UserSocialLink = (props) => {
  const { classNamePrefix, userSocial } = props;

  // fake data
  return (
    <Row className={`${classNamePrefix}-container`}>
      <Col className={`${classNamePrefix}-social-inner`} span={24}>
        <SocialLink socialLinks={userSocial} />
      </Col>
    </Row>
  );
};

export default UserSocialLink;
