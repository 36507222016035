export const PERMISSION = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  VIEW_MEMBERS: 'VIEW_MEMBERS',
  CREATE_MEMBER: 'CREATE_MEMBER',
  EDIT_MEMBER: 'EDIT_MEMBER',
  DELETE_MEMBERS: 'DELETE_MEMBERS',
  VIEW_ACTIVITY_LOG_OF_USER: 'VIEW_ACTIVITY_LOG_OF_USER',
  IMPERSONATE_PERMISSION: 'IMPERSONATE_PERMISSION',
  VIEW_MEMBER_DEFINED_PROPERTIES: 'VIEW_MEMBER_DEFINED_PROPERTIES',
  EDIT_MEMBER_DEFINED_PROPERTIES: 'EDIT_MEMBER_DEFINED_PROPERTIES',
  EDIT_COMPANY_PROFILE: 'EDIT_COMPANY_PROFILE',
  VIEW_FULL_COMPANY_PROFILE: 'VIEW_FULL_COMPANY_PROFILE',
  VIEW_SYSTEM_TAB_IN_COMPANY_PROFILE: 'VIEW_SYSTEM_TAB_IN_COMPANY_PROFILE',
  MANAGE_BAN_LIST: 'MANAGE_BAN_LIST',
  VIEW_COMMUNICATION_TEMPLATE: 'VIEW_COMMUNICATION_TEMPLATE',
  MANAGE_COMMUNICATION_TEMPLATE: 'MANAGE_COMMUNICATION_TEMPLATE',
  EXECUTE_COMMUNICATION_TEMPLATE: 'EXECUTE_COMMUNICATION_TEMPLATE',
  VIEW_REPORTING: 'VIEW_REPORTING',
  CREATE_REPORTING: 'CREATE_REPORTING',
  EDIT_REPORTING: 'EDIT_REPORTING',
  DELETE_REPORTING: 'DELETE_REPORTING',
  VIEW_PRODUCTS: 'VIEW_PRODUCTS',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  DELETE_PRODUCTS: 'DELETE_PRODUCTS',
  VIEW_ASSETS: 'VIEW_ASSETS',
  CREATE_ASSETS: 'CREATE_ASSETS',
  EDIT_ASSET: 'EDIT_ASSET',
  DELETE_ASSETS: 'DELETE_ASSETS',
  VIEW_FOLDERS: 'VIEW_FOLDERS',
  CREATE_FOLDER: 'CREATE_FOLDER',
  EDIT_FOLDER: 'EDIT_FOLDER',
  DELETE_FOLDERS: 'DELETE_FOLDERS',
  VIEW_USERS: 'VIEW_USERS',
  CREATE_USER: 'CREATE_USER',
  EDIT_USER: 'EDIT_USER',
  DELETE_USERS: 'DELETE_USERS',
  EDIT_USERS_PERMISSIONS: 'EDIT_USERS_PERMISSIONS',
  SEND_CONTACT_CARD: 'SEND_CONTACT_CARD',
  SEND_EMAIL: 'SEND_EMAIL',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_TEXT: 'SEND_TEXT',
  VIEW_HELP_CATEGORIES: 'VIEW_HELP_CATEGORIES',
  MANAGE_HELP_CATEGORY: 'MANAGE_HELP_CATEGORY',
  VIEW_HELP_POSTS: 'VIEW_HELP_POSTS',
  MANAGE_HELP_POST: 'MANAGE_HELP_POST',
  VIEW_EULAS: 'VIEW_EULAS',
  MANAGE_EULAS: 'MANAGE_EULAS',
  VIEW_ARTICLES: 'VIEW_ARTICLES',
  MANAGE_ARTICLES: 'MANAGE_ARTICLES',
  VIEW_HELP_ASSETS: 'VIEW_HELP_ASSETS',
  MANAGE_HELP_ASSETS: 'MANAGE_HELP_ASSETS',
  VIEW_SHARED_ENTITIES: 'VIEW_SHARED_ENTITIES',
  MANAGE_SHARED_ENTITIES: 'MANAGE_SHARED_ENTITIES',
  CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT: 'CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT',
  CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT: 'CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT',
  VIEW_MANAGE_MEMBER: 'VIEW_MANAGE_MEMBER',
  MANAGE_MAIN_PAGE: 'MANAGE_MAIN_PAGE',
  MANAGE_CURATED_QUERY: 'MANAGE_CURATED_QUERY',
  MANAGE_PIM_MAPPING: 'MANAGE_PIM_MAPPING',
  EDIT_SHARED_PRODUCT_OR_ASSET: 'EDIT_SHARED_PRODUCT_OR_ASSET',
  VIEW_NEW_ITEM_FORMS: 'VIEW_NEW_ITEM_FORMS',
  MANAGE_MEMBER_CREDIT_CARD: 'MANAGE_MEMBER_CREDIT_CARD',
  PAY_WITH_MEMBER_CREDIT_CARD: 'PAY_WITH_MEMBER_CREDIT_CARD',
  MANAGE_BILLABLE_TRACKING: 'MANAGE_BILLABLE_TRACKING',
  EDIT_BRAND: 'EDIT_BRAND',
  MANAGE_MEMBER_CATEGORIES: 'MANAGE_MEMBER_CATEGORIES',
  ASSIGN_PRODUCT_CATEGORY: 'ASSIGN_PRODUCT_CATEGORY',
  ACCESS_WORKFLOW: 'ACCESS_WORKFLOW',
  VIEW_QA_SPECIFICATION: 'VIEW_QA_SPECIFICATION',
  EDIT_QA_SPECIFICATION: 'EDIT_QA_SPECIFICATION',
  VIEW_TICKET: 'VIEW_TICKET',
  CREATE_TICKET: 'CREATE_TICKET',
  EDIT_TICKET: 'EDIT_TICKET',
  DELETE_TICKET: 'DELETE_TICKET',
  MANAGE_TICKET_GROUP: 'MANAGE_TICKET_GROUP',
  MANAGE_QA_SPEC_WORKFLOW: 'MANAGE_QA_SPEC_WORKFLOW',
  VIEW_EVALUATION_FORM: 'VIEW_EVALUATION_FORM',
  EDIT_EVALUATION_FORM: 'EDIT_EVALUATION_FORM',
  MEMBER_BILLING_MANAGEMENT: 'MANAGE_MEMBER_BILLING',
};

export const WORKFLOW_ROLES = {
  APPROVER: 'Approver',
  PARTICIPANT: 'Participant',
  INITIATOR: 'Initiator',
  CREATOR: 'Creator',
  ADMINISTRATOR: 'Administrator',
};

export const ABILITY_ACTION = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  EXECUTE: 'EXECUTE',
  DELETE: 'DELETE',
  CREATE: 'CREATE',
  MAINTAIN: 'MAINTAIN ',
  MANAGE: 'manage',
  COPY: 'COPY',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  CHAT: 'CHAT',
  ACCESS: 'ACCESS',
  IMPERSONATE: 'IMPERSONATE',
  PAY: 'PAY',
  ASSIGN: 'ASSIGN',
};

export const ABILITY_SUBJECT = {
  //? ALL FOR AUTHORIZATION
  ALL_AUTH: 'ALL_AUTH',

  //? All FOR SUPPER ADMIN
  ALL: 'all',
  USER: 'USER',
  PRODUCT: 'PRODUCT',
  PRODUCT_APL: 'PRODUCT_APL',
  MEMBER: 'MEMBER',
  COMPANY_PROFILE: 'COMPANY_PROFILE',
  ASSET: 'ASSET',
  GDSN: 'GDSN',
  SYNDICATION: 'SYNDICATION',
  //* Maintenance
  MAINTENANCE: 'MAINTENANCE',
  FOLDER: 'FOLDER',
  MEMBER_DEFINED_PROPERTIES: 'MEMBER_DEFINED_PROPERTIES',
  USER_PERMISSIONS: 'USER_PERMISSIONS',
  REPORTING: 'REPORTING',
  MAINTAINANCE_NAV: 'MAINTAINANCE_NAV',
  HELP_CENTER_NAV: 'HELP_CENTER_NAV',
  HELP_CATEGORY: 'HELP_CATEGORY',
  HELP_POST: 'HELP_POST',
  ARTICLE: 'ARTICLE',
  EULA_NAV: 'EULA_NAV',
  EULA: 'EULA',
  COMMUNICATION_TEMPLATE: 'COMMUNICATION_TEMPLATE',
  SHARED_ENTITY: 'SHARED_ENTITY',
  MANAGE_MEMBER: 'MANAGE_MEMBER',
  BAN_LIST: 'BAN_LIST',
  WORKFLOW: 'WORKFLOW',
  WORKFLOW_MAINTENANCE: 'WORKFLOW_MAINTENANCE',
  WORKFLOW_ADMINISTRATION: 'WORKFLOW_ADMINISTRATION',
  WORKFLOW_DEFINITION: 'WORKFLOW_DEFINITION',
  WORKFLOW_STEP: 'WORKFLOW_STEP',

  //* Maintenance
  //*Folders
  // EDIT_FOLDER: 'EDIT_FOLDER',
  // CREATE_FOLDER: 'CREATE_FOLDER',
  // DELETE_FOLDER: 'DELETE_FOLDER',
  // COPY_FOLDER: 'COPY_FOLDER',

  // MAINTENANCE_HELP: 'MAINTENANCE',
  //* chat
  CHAT_COMPANY: 'CHAT_COMPANY',
  CHAT_PERSONAL: 'CHAT_PERSONAL',
  MARKUP_NAV: 'MARKUP_NAV',
  MARKUP: 'MARKUP',
  CURATED_QUERY: 'CURATED_QUERY',
  PIM_MAPPING: 'PIM_MAPPING',
  SHARED_PRODUCT: 'SHARED_PRODUCT',
  SHARED_ASSET: 'SHARED_ASSET',
  NEW_ITEM_FORMS: 'NEW_ITEM_FORMS',
  QA_SPECIFICATION: 'QA_SPECIFICATION',
  EVALUATION_FORM: 'EVALUATION_FORM',
  //* Credit Card
  CREDIT_CARD: 'CREDIT_CARD',
  BILLABLE_TRACKING: 'BILLABLE_TRACKING',
  SPLASH_DOWNLOAD_HISTORY: 'SPLASH_DOWNLOAD_HISTORY',
  MY_QUERY: 'MY_QUERY',
  SHARED_QUERY: 'SHARED_QUERY',
  SUPPLIER_ASSIGNED_PRODUCT: 'SUPPLIER_ASSIGNED_PRODUCT',
  SHARED_ONLY_ASSIGNED_PRODUCT: 'SHARED_ONLY_ASSIGNED_PRODUCT',
  RECIPIENTS_FIELDS: 'RECIPIENTS_FIELDS',
  BRAND: 'BRAND',
  MEMBERS: 'MEMBERS',
  FULL_COMPANY_PROFILE: 'FULL_COMPANY_PROFILE',
  MEMBER_CATEGORIES: 'MEMBER_CATEGORIES',
  PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
  TICKET_SYSTEM: 'TICKET_SYSTEM',
  TICKET_SYSTEM_GROUP: 'TICKET_SYSTEM_GROUP',
  QA_SPEC_WORKFLOW: 'QA_SPEC_WORKFLOW',
  GDSN_RECEIVED_PRODUCT: 'GDSN_RECEIVED_PRODUCT',
  MY_SUBSCRIPTIONS: 'MY_SUBSCRIPTIONS',
};

export const PERMISSION_VIEW_ADVANCED_FILTER_IN_FOLDER = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.FOLDER,
  },
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.REPORTING,
  },
];

export const PERMISSION_VIEW_ADVANCED_FILTER_IN_ASSET_GRID = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.ASSET,
  },
  {
    action: ABILITY_ACTION.VỈEW,
    subject: ABILITY_SUBJECT.REPORTING,
  },
];

export const PERMISSION_EDIT_FTP_REGENERATE = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.COMPANY_PROFILE,
  },
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.MEMBER,
  },
];

export const PERMISSION_ACCESS_WORKFLOW = [
  {
    action: ABILITY_ACTION.ACCESS,
    subject: ABILITY_SUBJECT.WORKFLOW,
  },
];

export const PERMISSION_ADD_EDIT_USERS_TO_ROLE = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.USER_PERMISSIONS,
  },
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.MEMBER,
  },
];

export const ADMIN_PERMISSION = [
  {
    action: ABILITY_ACTION.MANAGE,
    subject: ABILITY_SUBJECT.ALL,
  },
];

export const PERMISSION_EDIT_PRODUCTS = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.PRODUCT,
  },
];
export const PERMISSION_CREATE_PRODUCTS = [
  {
    action: ABILITY_ACTION.CREATE,
    subject: ABILITY_SUBJECT.PRODUCT,
  },
];
export const EDIT_PRODUCT_MANAGEMENT = [
  [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.COMPANY_PROFILE],
  [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
];

export const PERMISSION_VIEW_NEW_ITEM_FORMS = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.NEW_ITEM_FORMS,
  },
];

export const EDIT_INTEROPERABILITY_MANAGEMENT = [
  [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.COMPANY_PROFILE],
  [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
];

export const SHOULD_ASSIGN_PRODUCT_CATEGORY = [
  {
    action: ABILITY_ACTION.ASSIGN,
    subject: ABILITY_SUBJECT.PRODUCT_CATEGORY,
  },
];

export const PERMISSION_MANAGE_QA_SPEC_WORKFLOW = [
  {
    action: ABILITY_ACTION.MANAGE,
    subject: ABILITY_SUBJECT.QA_SPEC_WORKFLOW,
  },
];

export const VIEW_ARTICLES = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.ARTICLE,
  },
];

export const PERMISSION_VIEW_QA_SPECIFICATION = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.QA_SPECIFICATION,
  },
];

export const PERMISSION_MANAGE_CURATED_QUERY = [
  {
    action: ABILITY_ACTION.MANAGE,
    subject: ABILITY_SUBJECT.CURATED_QUERY,
  },
];

export const PERMISSION_VIEW_PRODUCT = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.PRODUCT,
  },
];
