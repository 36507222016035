import React, { useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';

import { Space, List, Typography, Tooltip, Button } from 'antd';

import {
  StyledModal,
  FormDeleteButton,
  FormCancelButton,
} from 'common/components';

import { WarnIcon } from 'common/components/dialog-function/DialogIcon';

import { useDeleteQaGroupProduct } from './hooks';

export const QaGroupDeletion = ({
  productGroups,
  productId,
  onCancel,
  onDeleteSuccess,
  ...rest
}) => {
  const [selectedGroups, setSelectedGroups] = useState(productGroups);

  const deleteMutation = useDeleteQaGroupProduct(productId);

  const handleDeleteGroup = () => {
    const params = {
      productItemId: productId,
      groupIds: selectedGroups?.map((group) => group?.groupId),
    };

    deleteMutation.mutate(params, {
      onSuccess: () => {
        onCancel();
        onDeleteSuccess && onDeleteSuccess();
      },
    });
  };

  const isMultipleGroups = selectedGroups?.length > 1;

  const defaultTextProps = {
    style: {
      color: '#6c757d',
      fontSize: 16,
    },
    ellipsis: false,
  };

  return (
    <StyledModal
      title={null}
      width={420}
      bodyStyle={{ paddingTop: 24 }}
      footer={[
        <Space>
          <FormDeleteButton
            loading={deleteMutation.isLoading}
            onClick={handleDeleteGroup}
          />
          <FormCancelButton onClick={onCancel} />
        </Space>,
      ]}
      onCancel={onCancel}
      {...rest}
    >
      <WarnIcon />
      <div style={{ textAlign: 'center', marginTop: 4 }}>
        {isMultipleGroups ? (
          <Typography.Text {...defaultTextProps}>
            Are you sure you want to delete the group(s)?
          </Typography.Text>
        ) : (
          <Typography.Text {...defaultTextProps}>
            Are you sure you want to delete&nbsp;
            <strong>{selectedGroups?.[0]?.groupName?.toUpperCase()}</strong>
            &nbsp;group?
          </Typography.Text>
        )}
      </div>

      {isMultipleGroups && (
        <List
          style={{ marginTop: 12 }}
          dataSource={selectedGroups}
          renderItem={(item) => (
            <List.Item
              actions={
                selectedGroups?.length > 1
                  ? [
                      <Tooltip title='Ignore group'>
                        <Button
                          danger
                          type='text'
                          icon={<CloseOutlined />}
                          onClick={() => {
                            setSelectedGroups((prevGroups) =>
                              prevGroups.filter(
                                (group) => group?.groupId !== item?.groupId
                              )
                            );
                          }}
                        />
                      </Tooltip>,
                    ]
                  : null
              }
            >
              <List.Item.Meta
                title={
                  <Typography.Text ellipsis={{ tooltip: item?.groupName }}>
                    {item?.groupName?.toUpperCase()}
                  </Typography.Text>
                }
              />
            </List.Item>
          )}
        />
      )}
    </StyledModal>
  );
};
