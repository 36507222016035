import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import { InputNumber } from 'antd';

import './NumericEditor.less';

export const NumericEditor = forwardRef((props, ref) => {
  const { value: initValue, column, ...restProps } = props;

  const [value, setValue] = useState(initValue);
  const [editing, setEditing] = useState(true);
  const refContainer = useRef(null);
  const refInput = useRef(null);

  const focus = () => {
    setTimeout(() => {
      if (refInput.current && refInput.current?.focus) {
        refInput.current.focus();
      }
    }, [100]);
  };

  useEffect(() => {
    focus();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const handleChange = (value) => {
    setValue(value);
  };

  const handleEnter = () => {
    setEditing(false);
  };

  const width = column.getActualWidth() || 300;

  return (
    <div
      ref={refContainer}
      className='ag-editor__numeric'
      tabIndex={1}
      style={{ width }}
    >
      <InputNumber
        ref={refInput}
        value={value}
        onChange={handleChange}
        style={{ width: '100%' }}
        onEnter={handleEnter}
        onPressEnter={handleEnter}
        stringMode
        {...restProps}
      />
    </div>
  );
});
