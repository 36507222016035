import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import {
  EmailHeader,
  EmailThreadInfo,
  EmailThreadList,
  EmailInputBar,
  EmailScrollButton,
} from './components';
import { cloneDeep } from 'lodash';
import { notification } from 'antd';

import emailSaga from './controller/saga';
import emailReducer from './controller/reducer';
import * as emailSelector from './controller/selectors';
import * as emailActions from './controller/actions';
import * as chatSelectors from '../message/controller/selectors';
import * as emailServices from 'services/email';
import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';
import * as _ from 'lodash';
import userSelectors from 'redux/user/selectors';
import Draggable from 'react-draggable';

import * as userActions from 'redux/user/actions';

import * as memberSelectors from 'pages/company-profile/controllers/selectors';
import * as memberActions from 'pages/company-profile/controllers/actions';

import useUpdateContactCard from 'hooks/useUpdateContactCard';

// From Message
import CreateNewThreadPrivate from '../message/ui/MaximizeChatView/CreateNewThreadPrivate';

import { checkInvalidFile } from '../message/components/fields/utils';

import {
  attachmentActions,
  attachmentReducer,
  defaultStateAttachment,
} from '../message/components/fields/hooks';
import './Email.less';

const CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT = [
  {
    action: ABILITY_ACTION.CHAT,
    subject: ABILITY_SUBJECT.CHAT_PERSONAL,
  },
];
const CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT = [
  {
    action: ABILITY_ACTION.CHAT,
    subject: ABILITY_SUBJECT.CHAT_COMPANY,
  },
];

const key = 'email';
var debounceSearch;

function Email() {
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const updateContactCard = useUpdateContactCard();

  // Permissions
  const hasPersonalChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT
  );
  const hasCompanyChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT
  );

  useInjectReducer({ key, reducer: emailReducer });
  useInjectSaga({ key, saga: emailSaga });

  // Current ThreadID
  const [threadId, setThreadId] = useState(null);

  // Filter & search
  const [searchText, setSearchText] = useState('');
  const [filterSearch, setFilterSearch] = useState(
    [
      hasPersonalChatPermission && 'Personal',
      hasCompanyChatPermission && 'Company',
    ].filter(Boolean)
  );

  // Input
  const [emailText, setEmailText] = useState('');
  const [attachList, setAttachList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(100);
  const [isUploading, setIsUploading] = useState(false);
  const [fileId, setFileId] = useState([]);

  // Thread Info
  const [groupUsers, setGroupUsers] = useState([]);

  // Create new mail
  const [createNewMailThread, SetCreateNewMailThread] = useState(false);

  //Draggable
  const [activeDrags, setActiveDrags] = useState(0);
  const [isDragged, setIsDragged] = useState(false);
  const [tempCss, setTempCss] = useState(false);
  const [shouldShowScrollBtn, setShouldShowScrollButton] = useState(false);

  // Warning messages
  const [warningMessage, setWarningMessage] = useState(null);
  const warningRef = useRef(null);

  const isChatViewOpen = useSelector(globalSelectors.selectIsChatViewOpen());
  const showMinimizedThreadList = useSelector(
    chatSelectors.selectShowMinimizedThreadList()
  );
  const toggleChatBox = useSelector(chatSelectors.selectToggleChatBox());
  const emailThreadList = useSelector(emailSelector.selectEmailThreadList());
  const emailThreadInfo = useSelector(emailSelector.selectEmailThreadInfo());
  const newEmailInfo = useSelector(globalSelectors.selectNewEmailInfo());
  const userID = useSelector(userSelectors.makeSelectUserId());
  const memberID = useSelector(userSelectors.makeSelectUserMemberId());
  const toggleNewMailMessage = useSelector(
    emailSelector.selectToggleNewMailMessage()
  );
  const isFinishLoadDown = useSelector(emailSelector.selectFinishLoadDown());
  const selectedSearchResultPosition = useSelector(
    emailSelector.selectSelectedSearchResultPosition()
  );
  const emailThreadInfoContent = useSelector(
    emailSelector.selectEmailThreadInfoContent()
  );

  const latestMessage = useSelector(emailSelector.selectLastMessage());

  const checkGetListAfterSendEmail = useSelector(
    globalSelectors.selectCheckGetListAfterSendEmail()
  );

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const contactCardInfo = useSelector(
    userSelectors.makeSelectContactCardInfo()
  );

  const userProfileHeader = useSelector(
    userSelectors.makeSelectUserProfileHeader()
  );

  const memberInfo = useSelector(memberSelectors.selectMemberProfile());

  const emailThreadInfoData = emailThreadInfo?.data;
  const emailThreadInfoLoading = emailThreadInfo?.loading;
  const emailThreadListData = emailThreadList?.data;
  const emailThreadListLoading = emailThreadList?.loading;
  const emailThreadListHasMore = emailThreadList?.hasMore;

  const userSender = `U-${userID}`;
  const memberSender = `C-${memberID}`;

  const MAX_NUMBER_OF_FILES = 6;
  const [attachmentState, attachmentDispatch] = useReducer(
    attachmentReducer,
    defaultStateAttachment
  );
  useEffect(() => {
    onGetEmailThreadList(filterSearch, searchText, 1, 20);

    if (_.isEmpty(contactCardInfo)) {
      dispatch(userActions.getUserProfileHeader(userInfo?.id));
    }

    if (_.isEmpty(memberInfo)) {
      dispatch(memberActions.getMemberProfileHeader(userInfo?.member?.id));
    }

    return () => {
      dispatch(emailActions.getEmailThreadListFail(null));
    };
  }, []);

  const updateContactCardInfo = (data) => {
    const cloneData = _.cloneDeep(data);
    //* add memberId to data if on my profile view
    if (cloneData) {
      cloneData.memberId = userInfo?.member?.id;
    }
    updateContactCard(cloneData);
  };

  // get user profile info when component mount
  useEffect(() => {
    if (!_.isEmpty(userProfileHeader)) updateContactCardInfo(userProfileHeader);
  }, [userProfileHeader]);

  useEffect(() => {
    if (checkGetListAfterSendEmail) {
      onGetEmailThreadList(filterSearch, searchText, 1, 20);
      dispatch(globalActions.updateListAfterSendEmail(false));
    }
  }, [checkGetListAfterSendEmail]);

  // Thread Info
  useEffect(() => {
    if (emailThreadInfoData?.users?.length > 0) {
      let userIds = onSetListUserIds(emailThreadInfoData?.users);
      onGetUsersInfo(userIds);
    }
  }, [JSON.stringify(emailThreadInfoData?.users)]);

  const onSetListUserIds = (users) => {
    return users.map((user) => user.userId);
  };

  useEffect(() => {
    scrollToMessage();
  }, [selectedSearchResultPosition]);

  const onGetUsersInfo = (userIds) => {
    emailServices
      .getEmailThreadUsersInfo(userIds)
      .then((res) => {
        if (res.isSuccess) {
          onSetUsersInfo(emailThreadInfoData?.users, res.data.senders);
        } else {
          onSetUsersInfo(emailThreadInfoData?.users);
        }
      })
      .catch((err) => console.log('err ', err));
  };

  const onStart = () => {
    setActiveDrags((prevActiveDrags) => ++prevActiveDrags);
  };

  const onStop = () => {
    setActiveDrags((prevActiveDrags) => --prevActiveDrags);
    if (!isDragged) {
      setTempCss(styles.container);
      setIsDragged(true);
    }
  };

  /*
  /* info1: info in thread-info 
  /*  info2: info in users-info
  */
  const onSetUsersInfo = (info1, info2) => {
    if (info2.length > 0) {
      const users = info1.map((info) => {
        let data = info2.find(
          (firstInfo) => firstInfo.senderId === info.userId
        );
        return Object.assign({}, info, data);
      });

      setGroupUsers(users);
    } else {
      setGroupUsers(info1);
    }
  };

  const onGetEmailThreadList = (filterArr, searchText, pageIndex, pageSize) => {
    const params = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchText,
      GetCompanyThread: filterArr.indexOf('Company') > -1 ? true : false,
      GetPrivateThread: filterArr.indexOf('Personal') > -1 ? true : false,
    };
    dispatch(emailActions.getEmailThreadList(params));
  };

  const handleSearch = (value, filter) => {
    dispatch(emailActions.getEmailThreadListFail(null));
    if (debounceSearch) clearTimeout(debounceSearch);
    debounceSearch = setTimeout(() => {
      onGetEmailThreadList(filter, value, 1, 20);
    }, 500);
  };

  const onGetEmailThreadListMore = (
    filterArr,
    searchText,
    pageIndex,
    pageSize
  ) => {
    const params = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchText,
      GetCompanyThread: filterArr.indexOf('Company') > -1 ? true : false,
      GetPrivateThread: filterArr.indexOf('Personal') > -1 ? true : false,
    };

    dispatch(emailActions.getEmailThreadListMore(params));
  };

  // Search filter
  const handleChangeFilter = (filter) => {
    setFilterSearch(filter);
    handleSearch(searchText, filter);
  };

  // Search text input
  const handleChangeSearchInput = (event) => {
    setSearchText(event.target.value);
    handleSearch(event.target.value, filterSearch);
  };

  // Scroll to load more email
  const handleLoadMoreEmail = (nextPage) => {
    onGetEmailThreadListMore(filterSearch, searchText, nextPage, 20);
  };

  const onCloseEmailView = () => {
    dispatch(globalActions.toggleEmailView(false));
    dispatch(emailActions.getEmailThreadInfoSuccess(null));
    dispatch(emailActions.saveActiveEmailThreadInfo(null));
    dispatch(emailActions.saveAccountMail(null));
    handleResetNewEmail();
  };

  const handleResetNewEmail = () => {
    dispatch(globalActions.resetNewEmailInfo());
  };

  const scroller = useRef(null);

  const handleScroll = () => {
    const scrollEl = scroller.current;
    const scrollPos = scrollEl.scrollTop;
    const viewHeight = scrollEl.offsetHeight;
    const scrollHeight = scrollEl.scrollHeight;

    if (scrollPos >= scrollHeight - viewHeight - 10) {
      setShouldShowScrollButton(false);
      dispatchScrollBottomEvent(scrollPos);
    }

    if (scrollPos < scrollHeight - viewHeight - 100) {
      setShouldShowScrollButton(true);
    }
  };

  const scrollToBottom = React.useCallback(() => {
    if (!scroller.current) return;

    const scrollerHeight = scroller.current.scrollHeight;

    scroller.current.scrollTop = scrollerHeight;
  }, [scroller]);

  const scrollToTop = React.useCallback(() => {
    if (!scroller.current) return;

    scroller.current.scrollTop = 0;
  }, []);

  const scrollToMessage = () => {
    if (!selectedSearchResultPosition) return;

    const scroll = scroller.current;
    const viewHeight = scroll.offsetHeight;
    scroll.scrollTop = selectedSearchResultPosition - viewHeight / 2;

    // const scrollDestination = selectedSearchResultPosition - viewHeight + 40;

    // if (scrollDestination < 0) {
    //   scroll.scrollTop = 0;
    //   return;
    // }

    // const currentScrollPosition = scroll.scrollTop;

    // const isOutsideView =
    // scrollDestination > currentScrollPosition ||
    // scrollDestination < currentScrollPosition - viewHeight;

    // if (isOutsideView) scroll.scrollTop = scrollDestination;
  };

  const dispatchScrollBottomEvent = (scrollPos) => {
    if (window.chatScrollPos < scrollPos) {
      const event = new CustomEvent('emailScrollBottom', {
        detail: {
          emailThreadInfoContent,
          isFinishLoadDown,
        },
      });

      document.dispatchEvent(event);
    }
    window.chatScrollPos = scrollPos;
  };

  const handleGoBack = () => {
    if (emailThreadList && !emailThreadInfoData) {
      scrollToTop();
    }
    dispatch(emailActions.getEmailThreadListFail(null));
    dispatch(emailActions.getEmailThreadInfoSuccess(null));
    dispatch(emailActions.saveActiveEmailThreadInfo(null));
    dispatch(emailActions.saveAccountMail(null));

    setThreadId('');
    if (emailText) {
      resetEmailInput();
    }
    onGetEmailThreadList(filterSearch, searchText, 1, 20);
  };

  const onChangeMailInput = (message) => {
    setEmailText(message);
  };

  const resetEmailInput = () => {
    setEmailText('');
  };

  // handle Upload Attachment
  const getAttachFile = (uniqueFilesState) => {
    const { data: uniqueFiles, action, removedFileId } = uniqueFilesState;

    if (action === 'none') {
      setAttachList([]);
      attachmentDispatch({
        type: attachmentActions.RESET,
      });
      return;
    }

    if (action === 'add') {
      if (checkInvalidFile(uniqueFiles)) {
        setWarningMessage(
          `Your uploaded file size exceeds the allowed limit. Maximum file size is 10MB`
        );
        warningRef.current.onClick();
        return;
      }

      if (
        uniqueFiles.length + attachmentState.attachment.length >
        MAX_NUMBER_OF_FILES
      ) {
        setWarningMessage(`Maximum number of files is ${MAX_NUMBER_OF_FILES}`);
        warningRef.current.onClick();
        return;
      }
      setAttachList((prevFiles) => prevFiles.concat(uniqueFiles));
      attachmentDispatch({
        type: attachmentActions.SAVE_ATTACHMENT,
        newAttachment: uniqueFiles,
      });
    }

    if (action === 'remove') {
      setAttachList((prevFiles) =>
        prevFiles.filter((file) => file.uid !== removedFileId)
      );
      attachmentDispatch({
        type: attachmentActions.REMOVE_ATTACHMENT,
        fileUid: removedFileId,
      });
    }
  };

  const createParamsFormData = (values) => {
    let formData = new FormData();
    formData.append('attachment', values);
    formData.append('threadId', emailThreadInfoData?.id);
    return formData;
  };

  const handleUpload = useCallback(
    async (fileList) => {
      setIsUploading(true);
      let fileProgress;
      let promises = fileList?.map((attachment) => {
        const options = {
          onUploadProgress: (progressEvent) => {
            fileProgress = (progressEvent.loaded * 100) / progressEvent.total;
            setUploadProgress(fileProgress.toFixed(1));
          },
        };
        const formData = createParamsFormData(attachment?.originFileObj);

        return emailServices
          .uploadEmailAttachment(formData, options)
          .then((res) => {
            return res?.data;
          })
          .catch((err) => {
            console.log(err);
          });
      });
      Promise.all(promises).then((values) => {
        setFileId([...fileId, ...values]);
        setIsUploading(false);
      });
    },
    [attachList, fileId]
  );

  const handleRemoveFile = useCallback(
    (fileName) => {
      const filterFiles = fileId.filter((file) => file.fileName !== fileName);

      setFileId(filterFiles);
    },
    [attachList, fileId]
  );

  //* handle new messages
  const onUpdateThreadListWhenHavingNewMsg = async () => {
    let result;
    if (!_.isEmpty(newEmailInfo)) {
      result = emailThreadListData?.length > 0 ? [...emailThreadListData] : [];
      const existedThread = result?.filter(
        (thread) => thread.threadId === newEmailInfo?.threadId
      );
      const threads = result?.filter(
        (thread) => thread.threadId !== newEmailInfo?.threadId
      );
      if (existedThread?.length > 0) {
        const newThreads = existedThread.map((thread) => {
          return {
            ...thread,
            lastMessageText: newEmailInfo?.messageText,
            lastMessageTime: newEmailInfo?.sendTime,
            readStatus: false,
          };
        });
        dispatch(
          emailActions.updateEmailThreadList([...newThreads, ...threads])
        );
      } else if (
        existedThread?.length === 0 &&
        emailThreadListData?.length !== 0
      ) {
        // const onGetUsersInfo = (userIds) => {
        //   emailServices
        //     .getEmailThreadUsersInfo(userIds)
        //     .then((res) => {
        //       if (res.isSuccess)
        //         return res?.data?.senders !== newEmailInfo?.senderId;
        //     })
        //     .catch((err) => console.log('err ', err));
        // };
        // const senderId = await onGetUsersInfo(newEmailInfo?.senderId);

        const response = await onGetThreadInfo(
          newEmailInfo?.threadId,
          `U-${userID}`
        );
        const senderName = await onGetSenderName(newEmailInfo?.senderId);
        const threadInfo = response?.isSuccess ? response?.data : null;
        const newThread = [
          {
            emailSender: senderName?.data?.senders[0]?.name,
            ...newEmailInfo,
            ...threadInfo,
            readStatus: false,
            uniqueId: `U-${userID}-${threadInfo?.id}`,
          },
        ];
        dispatch(
          emailActions.updateEmailThreadList([
            ...newThread,
            ...emailThreadListData,
          ])
        );
      } else if (!existedThread) {
        let tempThreadInfoList = [];
        if (hasPersonalChatPermission) {
          const personalResponse = await onGetThreadInfo(
            newEmailInfo?.threadId,
            `U-${userID}`
          );
          let userThreadInfo =
            personalResponse?.isSuccess && personalResponse?.data
              ? personalResponse?.data
              : null;
          if (userThreadInfo) {
            tempThreadInfoList = [
              {
                ...userThreadInfo,
                uniqueId: `U-${userID}-${userThreadInfo?.id}`,
              },
            ];
          }
        }
        if (hasCompanyChatPermission) {
          const companyResponse = await onGetThreadInfo(
            newEmailInfo?.threadId,
            `C-${memberID}`
          );
          let companyThreadInfo =
            companyResponse?.isSuccess && companyResponse?.data
              ? companyResponse?.data
              : null;
          if (companyThreadInfo) {
            const companyThreadInfoArray = [
              {
                ...companyThreadInfo,
                uniqueId: `C-${memberID}-${companyThreadInfo?.id}`,
              },
            ];
            tempThreadInfoList = [
              ...tempThreadInfoList,
              ...companyThreadInfoArray,
            ];
          }
        }

        result =
          tempThreadInfoList?.length > 0
            ? [...tempThreadInfoList, ...result]
            : result;

        dispatch(emailActions.updateEmailThreadList(result));
      }
    }
  };

  const onGetThreadInfo = (threadId, userId) => {
    const params = {
      threadId,
      userId,
    };
    return emailServices.getEmailThreadInfo(params);
  };

  const onGetSenderName = (userId) => {
    return emailServices.getEmailThreadUsersInfo(userId);
  };

  useEffect(() => {
    if (!_.isEmpty(newEmailInfo)) {
      setTimeout(() => {
        onUpdateThreadListWhenHavingNewMsg();
      }, 500);
    }
  }, [JSON.stringify(newEmailInfo)]);

  const styles = {
    container: {
      right:
        isChatViewOpen && toggleChatBox
          ? '450px'
          : showMinimizedThreadList && !toggleChatBox
          ? '80px'
          : '5px',
    },
    spin: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const emailView = () => {
    if (emailThreadList && !emailThreadInfoData) {
      return (
        <EmailThreadList
          handleResetNewEmail={handleResetNewEmail}
          emailThreadInfoLoading={emailThreadInfoLoading}
          emailThreadListData={emailThreadListData}
          emailThreadListLoading={emailThreadListLoading}
          emailThreadListHasMore={emailThreadListHasMore}
          handleLoadMoreEmail={handleLoadMoreEmail}
          setThreadId={setThreadId}
          threadId={threadId}
        />
      );
    }

    if (emailThreadInfoData) {
      return (
        <EmailThreadInfo
          data={emailThreadInfoData}
          participants={groupUsers}
          threadId={threadId || emailThreadInfoData?.id}
          scroller={scroller.current}
          isThreadLoading={emailThreadInfoLoading}
          scrollToBottom={scrollToBottom}
          toggleNewMailMessage={toggleNewMailMessage}
          emailThreadInfoContent={emailThreadInfoContent}
        />
      );
    }
  };

  const dragHandlers = { onStart, onStop };
  return (
    <Draggable bounds='parent' handle='strong' {...dragHandlers}>
      <div
        className='email__container'
        style={!isDragged ? styles.container : tempCss}
      >
        <div className='email'>
          <strong className='cursor email__top-drag-zone'>
            <EmailHeader
              isInEmailThread={emailThreadInfoData}
              isShowBackButton={emailThreadInfoData}
              info={emailThreadInfoData}
              onCloseEmailView={onCloseEmailView}
              onChangeEmailFilter={handleChangeFilter}
              handleGoBack={handleGoBack}
              searchText={searchText}
              onHandleSearch={handleChangeSearchInput}
              newEmailThread={() => SetCreateNewMailThread(true)}
              groupUsers={groupUsers}
            />
          </strong>
          <div style={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
            <div
              className='scroller'
              ref={scroller}
              style={{ flex: '1 auto', padding: 7, height: '100%' }}
              onScroll={handleScroll}
            >
              {emailView()}
              <EmailScrollButton
                threadId={emailThreadInfoData?.id}
                scrollToBottom={scrollToBottom}
                shouldShowScrollBtn={shouldShowScrollBtn}
                threadMessages={emailThreadInfoContent}
                latestMessage={latestMessage}
              />
            </div>
          </div>
          <EmailInputBar
            threadId={emailThreadInfoData?.id}
            value={emailText}
            attachList={attachList}
            getAttachFile={getAttachFile}
            onChangeMailInput={onChangeMailInput}
            show={!!emailThreadInfoData}
            resetEmailInput={resetEmailInput}
            handleUpload={handleUpload}
            groupUsers={groupUsers}
            uploadProgress={uploadProgress}
            isUploading={isUploading}
            fileId={fileId}
            attachmentState={attachmentState}
            attachmentDispatch={attachmentDispatch}
            setAttachList={setAttachList}
            warningMessage={warningMessage}
            warningRef={warningRef}
          />

          <CreateNewThreadPrivate
            visible={createNewMailThread}
            closeNewChat={() => SetCreateNewMailThread(false)}
            communicationListData={emailThreadListData}
            isInEmail={true}
          />
        </div>
      </div>
    </Draggable>
  );
}

export default Email;
