// Service Type List
import dropbox from 'assets/system/dropbox.png';
import ftp from 'assets/system/ftp.png';
import instacart from 'assets/system/instacart.png';
import salsify from 'assets/system/salsify.png';
import sftp from 'assets/system/sftp.png';
import sharepoint from 'assets/system/sharepoint.jpg';
import sagetree from 'assets/system/sagetree.png';
import gs1 from 'assets/gs1.png';

const serviceTypeList = [
  {
    value: 'dropbox',
    icon: dropbox,
  },
  {
    value: 'ftp',
    icon: ftp,
  },
  {
    value: 'instacart',
    icon: instacart,
  },
  {
    value: 'salsify',
    icon: salsify,
  },
  {
    value: 'sftp',
    icon: sftp,
  },
  {
    value: 'sharepoint',
    icon: sharepoint,
  },
  {
    value: 'sagetree',
    icon: sagetree,
  },
  {
    value: 'gs1',
    icon: gs1,
  },
];

export default serviceTypeList;
