import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { getDefaultGridConfig } from 'services/grid';

import { useAsync } from 'hooks';

import { saveGridConfig } from 'pages/branded-products/controllers/actions';

import { useApplyPreviewGridConfig } from 'pages/home/ribbon/components/sections/customize-grid';

const useGetDefaultGridConfig = ({ isEnabled }) => {
  const { data, run } = useAsync();
  const dispatch = useDispatch();

  const { isPreviewGridConfigAcive, applyGridConfig } =
    useApplyPreviewGridConfig();

  useEffect(() => {
    if (isEnabled) {
      run(getDefaultGridConfig('product-detail-grid'));
    }
  }, [isEnabled, run]);

  useEffect(() => {
    if (!isEnabled) return;

    if (!isPreviewGridConfigAcive) {
      if (data) {
        dispatch(
          saveGridConfig({
            jsonConfig: data.jsonConfig,
            selectedColumns: data.selectedColumns,
            isHaveDefaultConfig: true,
          })
        );
      }
    } else {
      dispatch(
        saveGridConfig({
          jsonConfig: applyGridConfig?.jsonConfig,
          selectedColumns: applyGridConfig?.selectedColumns,
        })
      );
    }
  }, [data, dispatch, isEnabled, applyGridConfig, isPreviewGridConfigAcive]);
};

export default useGetDefaultGridConfig;
