import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { notification } from 'antd';

import * as folderActions from '../../controllers/actions';
import * as folderSelectors from '../../controllers/selectors';
import { getSubMappingSubscription } from 'services/mapping';

import { PRODUCT_CONFIG as productConfigs } from 'static/Constants';

const useSetIndeterminateCheckbox = (downloadSetting, setIndeterminate) => {
  useEffect(() => {
    if (downloadSetting) {
      const { hasTrueValue, name } = checkDefaultSetting(
        productConfigs,
        downloadSetting
      );
      if (hasTrueValue) {
        setIndeterminate({ [name]: true });
      }
    }
  }, [downloadSetting, setIndeterminate]);
};

const useCheckSelectMapping = () => {
  const downloadSetting = useSelector(folderSelectors.selectDownloadSetting());
  const selectedMapping = useSelector(folderSelectors.selectSelectedMapping());
  const statusDownloadFolder = useSelector(
    folderSelectors.selectStatusDownloadFolder()
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!downloadSetting?.productCount) return;

    if (selectedMapping === null && statusDownloadFolder === 'loading') {
      notification.error({
        message: 'Please select a mapping',
      });

      dispatch(folderActions.saveStatusDownloadFolder('idle'));
    }
  }, [
    dispatch,
    downloadSetting?.productCount,
    selectedMapping,
    statusDownloadFolder,
  ]);
};

const checkContainsSomeTrueValue = (names, form) => {
  const mergedChildValues = getMergedChildValues(names, form);
  return mergedChildValues?.some((value) => value === true);
};

const checkContainsAllTrueValue = (names, form) => {
  const mergedChildValues = getMergedChildValues(names, form);
  return mergedChildValues?.every((value) => value === true);
};

const getMergedChildValues = (names, form) => {
  const mergedChildValues = names?.reduce((accumulator, currentValue) => {
    if (form.getFieldValue(`${currentValue}`)) {
      return accumulator.concat(
        Object.values(form.getFieldValue(`${currentValue}`))
      );
    }
    return [];
  }, []);

  return mergedChildValues;
};

const checkAllTrue = (list) => list.every((value) => value === true);

const checkIfOneTrue = (list) => list.some((value) => value === true);

const checkOneOfThemHasTrueValue = (namesChild, form) => {
  for (const name of namesChild) {
    const valueChild = Object.values(form.getFieldValue()[`${name}`]);
    if (checkIfOneTrue(valueChild)) return true;
  }

  return false;
};

const checkDefaultSetting = (productConfigs, folderDownload) => {
  if (folderDownload == null || !folderDownload) return {};

  for (const config of productConfigs) {
    const { name: nameParent, option: optionParent } = config;

    for (const childOption of optionParent) {
      const { name: nameChild, option: valueChild } = childOption;
      if (folderDownload[`${nameChild}`]?.length === valueChild.length) {
        return {
          hasTrueValue: true,
          name: nameParent,
        };
      }
    }
  }
  return {
    hasTrueValue: false,
    name: '',
  };
};

const findMappingSubscription = async (mappingId) => {
  const params = {
    pageIndex: 1,
    pageSize: 9999,
    filters: [
      {
        fieldName: 'id',
        filterType: 'Equal',
        value: mappingId,
      },
    ],
    enableRivirDownload: true,
  };
  try {
    const response = await getSubMappingSubscription(params);
    if (response?.isSuccess) {
      return new Promise((resolve) => resolve(response.data?.gridData?.[0]));
    } else {
      return new Promise((resolve) => resolve(null));
    }
  } catch (error) {
    return new Promise((resolve) => resolve(null));
  }
};

export {
  useSetIndeterminateCheckbox,
  useCheckSelectMapping,
  checkContainsSomeTrueValue,
  checkContainsAllTrueValue,
  checkOneOfThemHasTrueValue,
  checkAllTrue,
  checkDefaultSetting,
  findMappingSubscription,
  // dropdownConfigs,
};

export const initProductConfigValue = (
  downloadSetting,
  form,
  onChangeBotCb
) => {
  productConfigs.forEach((productItem) => {
    for (const [key, parent] of productItem.option?.entries()) {
      for (const [key, grandChild] of parent.option?.entries()) {
        form.setFieldsValue({
          [parent.name]: {
            [grandChild]: downloadSetting?.[parent.name]?.includes(grandChild),
          },
        });
      }
    }

    for (const [key, parent] of productItem.option?.entries()) {
      for (const [key, grandChild] of parent.option?.entries()) {
        onChangeBotCb(grandChild, parent, productItem);
      }
    }
  });
};
