import { useMutation } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';
import { qaSpecLinkToDocCer } from 'services/product';

export const useMutationLinkAssetToPim = (options = {}) => {
  return useMutation({
    mutationFn: async (params) => {
      const res = await qaSpecLinkToDocCer(params);

      const { message, isSuccess } = res;

      if (isSuccess) {
        CustomNotification.success('Link supporting document successfully');
        return Promise.resolve();
      } else {
        CustomNotification.error(message || 'Server Error');
      }
    },
    ...options,
  });
};
