import React from 'react';
import { useSelector } from 'react-redux';

import EmailMessage from './EmailMessage';

import * as emailSelectors from '../../controller/selectors';

function EmailTurnItem(props) {
  const { item, participants } = props;
  const selectedSearchResult = useSelector(
    emailSelectors.selectSelectedSearchResult()
  );

  const isSelectedSearchResult =
    selectedSearchResult?.messageId &&
    selectedSearchResult?.messageId === item.messageId;

  const getParticipantInfo = () => {
    return participants?.find((participant) => {
      return participant.userId === item.senderId;
    });
  };

  const participant = getParticipantInfo();

  return (
    <EmailMessage
      item={item}
      participant={participant}
      isSelectedSearchResult={isSelectedSearchResult}
    />
  );
}

export default EmailTurnItem;
