import produce from 'immer';
import * as types from './constants';

// PROGRESSION: 'initial', '75warning', 'overdue', 'expired', 'completion'

// initial state
export const initialState = {
  loading: false,
  error: null,
  messageSuccess: null,
  templateView: {
    type: '', // PROGRESSION, TASK
    //typeId: null
    versionId: null,
    step: null,
    progressionId: null,
    taskId: null,
    status: 'idl',
  },
  detailWfTemplate: null,
  detailSteps: [],
  toggleNewStep: false,
  toggleNewTask: {
    status: false,
    stepId: null,
  },

  loadingStepInfo: false,
  stepInfo: null,
  stepInfoError: null,

  loadingCreateStep: false,
  createStepSuccess: false,
  createStepError: null,

  loadingWorkflowTemplateDetail: false,
  workflowTemplateDetail: false,
  workflowTemplateDetailError: null,

  trees: [], // array of template versions on Left Panel
  activeStepKey: null,
  wfTemplateVersions: [],
  wfTemplateVersionsLoading: false,

  userWorkflowRoles: [],

  taskInfo: null,
  workflowMetric: null,
  statusWorkflow: null,
  requiredTask: null,

  workflowStepMetricLoading: false,
  workflowStepMetric: false,
  workflowStepMetricError: false,
  isCloneStep: false,
  isCloseTask: false,
};

/* eslint-disable default-case, no-param-reassign */
const wfTemplateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_WORKFLOW_TEMPLATE_VIEW:
        draft.templateView = { ...action.templateView, status: 'loaded' };
        break;
      case types.GET_WORKFLOW_TEMPLATE_DETAIL:
        draft.loading = true;
        draft.detailWfTemplate = null;
        break;
      case types.GET_WORKFLOW_TEMPLATE_DETAIL_SUCCESS:
        draft.loading = false;
        draft.detailWfTemplate = action.detailWfTemplate;
        draft.workflowTemplateDetail = action.detailWfTemplate;
        break;
      case types.GET_WORKFLOW_TEMPLATE_DETAIL_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.TOGGLE_NEW_STEP:
        draft.toggleNewStep = action.status;
        break;
      case types.TOGGLE_NEW_TASK:
        draft.toggleNewTask.status = action.status;
        draft.toggleNewTask.stepId = action.stepId;
        break;
      case types.GET_WORKFLOW_STEP_INFO:
        draft.loading = true;
        draft.stepInfo = false;
        break;
      case types.GET_WORKFLOW_STEP_INFO_SUCCESS:
        draft.loading = false;
        draft.stepInfo = action.stepInfo;
        break;
      case types.GET_WORKFLOW_STEP_INFO_ERROR:
        draft.loading = false;
        draft.stepInfoError = action.error;
        break;
      case types.CREATE_WORKFLOW_STEP:
        draft.loading = true;
        draft.messageSuccess = false;
        break;
      case types.CREATE_WORKFLOW_STEP_SUCCESS:
        draft.loading = false;
        draft.messageSuccess = 'Create successfully!';
        break;
      case types.CREATE_WORKFLOW_STEP_ERROR:
        draft.loadingCreateStep = false;
        draft.loading = false;
        draft.createStepError = action.error;
        break;
      case types.EDIT_WORKFLOW_STEP:
        draft.loading = true;
        draft.messageSuccess = false;
        break;
      case types.EDIT_WORKFLOW_STEP_SUCCESS:
        draft.loading = false;
        draft.stepInfo = action.stepInfo;
        draft.messageSuccess = 'Save successfully!';
        break;
      case types.EDIT_WORKFLOW_STEP_ERROR:
        draft.loading = false;
        break;
      case types.GET_WORKFLOW_TEMPLATE_DETAIL_INFO:
        draft.loading = true;
        draft.workflowTemplateDetail = false;
        draft.workflowTemplateDetailError = null;
        break;
      case types.GET_WORKFLOW_TEMPLATE_DETAIL_INFO_SUCCESS:
        draft.loading = false;
        draft.workflowTemplateDetail = action.workflowTemplateDetail;
        break;
      case types.GET_WORKFLOW_TEMPLATE_DETAIL_INFO_ERROR:
        draft.loading = false;
        draft.workflowTemplateDetailError = null;
        break;
      case types.SET_TREE:
        draft.trees = action.trees;
        break;
      case types.SET_ACTIVE_STEP_KEY:
        draft.activeStepKey = action.id;
        break;
      case types.GET_WORKFLOW_TEMPLATE_VERSIONS:
        draft.wfTemplateVersions = [];
        draft.trees = [];
        draft.detailWfTemplate = null;
        draft.wfTemplateVersionsLoading = true;
        break;
      case types.GET_WORKFLOW_TEMPLATE_VERSIONS_SUCCESS:
        draft.wfTemplateVersions = action.versions;
        draft.wfTemplateVersionsLoading = false;
        break;
      case types.GET_WORKFLOW_TEMPLATE_VERSIONS_ERROR:
        draft.wfTemplateVersions = [];
        draft.wfTemplateVersionsLoading = false;
        break;
      case types.SET_WORKFLOW_TEMPLATE_DETAIL:
        draft.workflowTemplateDetail = action.workflowTemplateDetail;
        break;
      case types.EDIT_WORKFLOW_TEMPLATE:
        draft.loading = true;
        draft.messageSuccess = false;
        break;
      case types.EDIT_WORKFLOW_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.messageSuccess = 'Save successfully!';
        // draft.workflowTemplateDetail = action.workflowTemplateDetail;
        break;
      case types.EDIT_WORKFLOW_TEMPLATE_ERROR:
        draft.loading = false;
        draft.workflowTemplateDetailError = action.error;
        break;

      case types.GET_USER_WORKFLOW_ROLES_DETAIL:
        draft.userWorkflowRoles = false;
        break;
      case types.GET_USER_WORKFLOW_ROLES_DETAIL_SUCCESS:
        draft.userWorkflowRoles = action.userWorkflowRoles;
        break;
      case types.GET_USER_WORKFLOW_ROLES_DETAIL_ERROR:
        break;
      case types.GET_WORKFLOW_TASK_INFO:
        draft.loading = true;
        draft.taskInfo = false;
        break;
      case types.GET_WORKFLOW_TASK_INFO_SUCCESS:
        draft.loading = false;
        draft.taskInfo = action.taskInfo;
        break;
      case types.GET_WORKFLOW_TASK_INFO_ERROR:
        draft.loading = false;
        break;
      case types.EDIT_WORKFLOW_TASK:
        draft.loading = true;
        // draft.loadingWorkflowTemplateDetail = true;
        draft.messageSuccess = false;
        break;
      case types.EDIT_WORKFLOW_TASK_SUCCESS:
        draft.loading = false;
        // draft.loadingWorkflowTemplateDetail = false;
        draft.taskInfo = action.taskInfo;
        draft.messageSuccess = 'Save successfully!';
        break;
      case types.EDIT_WORKFLOW_TASK_ERROR:
        draft.loading = false;
        // draft.workflowTemplateDetailError = action.error;
        break;
      case types.SET_WORKFLOW_STEP_INFO:
        draft.stepInfo = action.stepInfo;
        break;
      case types.CREATE_WORKFLOW_TASK:
        draft.loading = true;
        draft.messageSuccess = false;
        break;
      case types.CREATE_WORKFLOW_TASK_SUCCESS:
        draft.loading = false;
        draft.taskInfo = action.taskInfo;
        draft.messageSuccess = 'Create successfully!';
        break;
      case types.CREATE_WORKFLOW_TASK_ERROR:
        draft.loading = false;
        break;
      case types.GET_WORKFLOW_METRIC:
        break;
      case types.GET_WORKFLOW_METRIC_SUCCESS:
        draft.workflowMetric = action.workflowMetric;
        break;
      case types.GET_WORKFLOW_METRIC_ERROR:
        break;
      case types.SET_STATUS_WORKFLOW_CLICK:
        draft.statusWorkflow = action.statusWorkflow;
        break;
      case types.GET_REQUIRED_TASK:
        break;
      case types.GET_REQUIRED_TASK_SUCCESS:
        draft.requiredTask = action.requiredTask;
        break;
      case types.GET_REQUIRED_TASK_ERROR:
        break;
      case types.GET_WORKFLOW_STEP_METRIC:
        draft.workflowStepMetricLoading = true;
        draft.workflowStepMetricError = null;
        break;
      case types.GET_WORKFLOW_STEP_METRIC_SUCCESS:
        draft.workflowStepMetricLoading = false;
        draft.workflowStepMetric = action.workflowStepMetric;
        break;
      case types.GET_WORKFLOW_STEP_METRIC_ERROR:
        draft.workflowStepMetricError = action.error;
        draft.workflowStepMetricLoading = false;
        break;
      case types.UPDATE_MESSAGE_ACTIONS:
        draft.messageSuccess = action.msg;
        break;
      case types.TRIGGER_CLONE_STEP:
        draft.isCloneStep = true;
        break;
      case types.TRIGGER_CLONE_TASK:
        draft.isCloneTask = true;
        break;
      case types.HIDE_CLONE_STEP:
        draft.isCloneStep = false;
        break;
      case types.HIDE_CLONE_TASK:
        draft.isCloneTask = false;
        break;
      default:
        break;
    }
  });

export default wfTemplateReducer;
