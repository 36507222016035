import React, { useState, useEffect, useContext } from 'react';

import { Image } from 'antd';
import { YoutubeOutlined } from '@ant-design/icons';
import { useDragDropPosHooks } from 'common/components';
import { DragContext } from 'common/components/drapDropWrap/drag-drop-pos/context/DragContext';

import defaultYoutubeIcon from 'assets/landing-page/youtube-icon.png';
import { COMPONENT_TYPE, CREATE_NEW_STATUS } from '../../constants';
import {
  useTogglePageWrapLayer,
  useGetNewComponentPosition,
  useRemoveNewComponentOutSizeContainer,
} from '../../hooks';

import './PageEditorToolAddNewYoutube.less';

const PageEditorToolAddNewYoutube = (props) => {
  const {
    wrapId,
    wrapKey,
    selectedHook,
    detailShowTargetHook,
    showDetailComponentData,
    componentHandlers,
    scale,
  } = props;

  const [selected, setSelected, clearSelect] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const startDragHook = useState();
  const [startDrag, setStartDrag] = startDragHook;
  const [newKey, setNewKey] = useState(null);
  const createStatusHook = useState(CREATE_NEW_STATUS.DONE);
  const [createStatus, setCreateStatus] = createStatusHook;

  const { onCreateNewComponent, onDragNewComponent } = componentHandlers;

  const addNewYoutubeKey = `${wrapKey}-new-youtube`;

  const [dragPosInst, dragStyle, refDragTag] = useDragDropPosHooks([
    clearSelect,
    addNewYoutubeKey,
  ]);

  const togglePageWrapLayer = useTogglePageWrapLayer(wrapKey);
  const newPosition = useGetNewComponentPosition(
    wrapKey,
    COMPONENT_TYPE.YOUTUBE,
    scale
  );

  const CreateNewYoutube = () => {
    const newComponentKey = onCreateNewComponent({
      componentType: COMPONENT_TYPE.YOUTUBE,
      values: {
        top: newPosition?.top,
        left: newPosition?.left,
      },
    });

    setNewKey(newComponentKey);
    setDetailShowTarget(newComponentKey);
  };

  const onDragNewYoutube = async () => {
    CreateNewYoutube();
    setStartDrag(true);
    setCreateStatus(CREATE_NEW_STATUS.START);
  };

  const stopDragNewYoutube = () => {
    // setNewKey(false);
  };

  const isDragging = useContext(DragContext);

  useRemoveNewComponentOutSizeContainer(
    createStatusHook,
    isDragging,
    newKey,
    componentHandlers
  );

  useEffect(() => {
    //* start handle move new component
    const startMoveNewComponent = () => {
      if (startDrag && newKey) {
        onDragNewComponent({
          showDetailComponentData,
          key: newKey,
          fieldNameX: 'left',
          fieldNameY: 'top',
          createStatusHook,
          startDragHook,
          wrapId,
          dragPosInst,
        });
      }
    };

    startMoveNewComponent();
  }, [startDrag, showDetailComponentData, newKey]);

  return (
    <div className='page-editor-add-new-youtube'>
      <div onMouseDown={onDragNewYoutube} onMouseLeave={stopDragNewYoutube}>
        <YoutubeOutlined id={addNewYoutubeKey} ref={refDragTag} />
      </div>
      <Image style={{ width: 0, height: 0 }} src={defaultYoutubeIcon} />
    </div>
  );
};

export default PageEditorToolAddNewYoutube;
