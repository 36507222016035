import React, { useState } from 'react';

import { Col, Form, Row, Select } from 'antd';
import {
  CustomNotification,
  StyledModal,
  WithLoading,
  WrapperSelect,
} from 'common/components';

import { GroupName } from 'pages/product-full-view/components/product-media-area/multiple-panel/components';

import {
  GroupAssignAllergens,
  GroupAssignFactsPanels,
  GroupAssignIngredients,
} from 'pages/product-full-view/components/product-media-area/shared/GroupAssignParts';

import { useAssignQaGroupProduct, useGetQaGroupDetail } from './hooks';

import { appendDataGroup } from 'pages/product-full-view/components/product-media-area/shared/utils';
import { mappingAllergenGroupsWhenEditing } from './utils';

const defaultModalProps = {
  destroyOnClose: true,
  width: 1140,
  maskClosable: false,
  bodyStyle: {
    minHeight: '60vh',
    maxHeight: '70vh',
  },
};

export const QaGroupAssign = (props) => {
  const {
    defaultGroupId,
    selectedGroupIds = [],
    productGroups = [],
    productId: productIdProp,
    onCancel: offModal,
    additionalIngredientsGroups = [],
    additionalAllergenGroups = [],
    additionalNutritionFacts = [],
    additionalSupplementFacts = [],
    additionalDrugFacts = [],
    additionalPetFood = [],
    onAssignSuccess,
    ...rest
  } = props;

  const [formInstance] = Form.useForm();

  const [groupId, setGroupId] = useState(defaultGroupId);

  const qaAssignGroupMutation = useAssignQaGroupProduct(productIdProp, groupId);

  const { groupDetail, isLoading } = useGetQaGroupDetail({
    productId: productIdProp,
    groupId,
  });

  const {
    ingredientGroups = [],
    allergenGroups = [],
    nutritionFacts = [],
    supplementFacts = [],
    drugFacts = [],
    petNutritionFacts = [],
  } = groupDetail ?? {};

  const appendedIngredientGroups = appendDataGroup(
    additionalIngredientsGroups,
    ingredientGroups
  );

  const appendedAllergenGroups = appendDataGroup(
    additionalAllergenGroups,
    allergenGroups
  );

  const appendedNutritionFacts = appendDataGroup(
    additionalNutritionFacts,
    nutritionFacts
  );

  const appendedSupplementGroups = appendDataGroup(
    additionalSupplementFacts,
    supplementFacts
  );

  const appendedDrugFactsGroups = appendDataGroup(
    additionalDrugFacts,
    drugFacts
  );

  const appendedPetFoodGroups = appendDataGroup(
    additionalPetFood,
    petNutritionFacts
  );

  // Ordered
  const appendedFactsPanels = [
    ...appendDataGroup(
      additionalNutritionFacts,
      additionalSupplementFacts,
      additionalDrugFacts,
      additionalPetFood
    ),
    ...appendDataGroup(
      nutritionFacts,
      supplementFacts,
      drugFacts,
      petNutritionFacts
    ),
  ];

  const filterProductGroups = productGroups.filter(
    (group) => !selectedGroupIds.includes(group.groupId)
  );

  const handleAssignGroup = () => {
    const newSupplementFactIds = appendedSupplementGroups.map(
      (supplement) => supplement.id
    );

    const newNutritionFactIds = appendedNutritionFacts.map(
      (nutrition) => nutrition.id
    );

    const newAllergenGroupIndices = appendedAllergenGroups.map(
      (allergen) => allergen.index
    );

    const newIngredientGroupIndices = appendedIngredientGroups.map(
      (ingredient) => ingredient.index
    );

    const newDrugFactIds = appendedDrugFactsGroups.map(
      (drugFacts) => drugFacts.id
    );

    const newPetFoodIds = appendedPetFoodGroups.map((petFood) => petFood.id);

    const params = {
      productItemId: productIdProp,
      groupId,
      ingredientGroupIndices: newIngredientGroupIndices,
      allergenGroupIndices: newAllergenGroupIndices,
      nutritionFactIds: newNutritionFactIds,
      supplementFactIds: newSupplementFactIds,
      drugFactIds: newDrugFactIds,
      petNutritionFactIds: newPetFoodIds,
    };

    qaAssignGroupMutation.mutate(params, {
      onSuccess: (result) => {
        result?.isSuccess &&
          CustomNotification.success('Assign group successfully');
        formInstance.resetFields();
        offModal();
        onAssignSuccess && onAssignSuccess();
      },
    });
  };

  return (
    <StyledModal
      {...defaultModalProps}
      title='Assign Group'
      onCancel={offModal}
      onOk={handleAssignGroup}
      okButtonProps={{
        loading: qaAssignGroupMutation.isLoading,
      }}
      {...rest}
    >
      <WithLoading loading={isLoading}>
        <Form form={formInstance}>
          <GroupName>
            <WrapperSelect
              allowClear={false}
              placeholder='Please select a group name'
              onChange={(value) => setGroupId(value)}
              defaultValue={groupId}
            >
              {filterProductGroups?.map((group) => {
                return (
                  <Select.Option key={group.groupId} value={group.groupId}>
                    {group.groupName}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </GroupName>
        </Form>

        <Row gutter={[12, 16]} className='group-layout__assign-item'>
          <Col span={24}>
            <GroupAssignIngredients
              allIngredientGroups={appendedIngredientGroups}
              additionalIngredientGroups={additionalIngredientsGroups}
            />
          </Col>
          <Col span={24}>
            <GroupAssignAllergens
              allAllergenGroups={mappingAllergenGroupsWhenEditing(
                appendedAllergenGroups
              )}
              additionalAllergenGroups={additionalAllergenGroups}
            />
          </Col>
          <Col span={24}>
            <GroupAssignFactsPanels
              factsPanels={appendedFactsPanels}
              appendedDrugFactsGroups={appendedDrugFactsGroups}
              additionalNutritionFacts={additionalNutritionFacts}
              additionalSupplementFacts={additionalSupplementFacts}
              additionalPetFood={additionalPetFood}
            />
          </Col>
        </Row>
      </WithLoading>
    </StyledModal>
  );
};
