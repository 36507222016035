import React from 'react';

import { GridItem } from './GridItem';
import { Skeleton, Empty } from 'antd';

import reactCSS from 'reactcss';
import { RIBBON_TYPES, ENTITY_TYPE } from 'static/Constants';

const styles = reactCSS({
  default: {
    grid: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'wrap',
      alignContent: 'flex-start',
    },
    gridWrap: {
      width: '100%',
      height: '100%',
    },
  },
});

const Grid = (props) => {
  const {
    items,
    isLoading,
    searchType = ENTITY_TYPE.FOLDER,
    viewType = RIBBON_TYPES.THUMBNAILS,
    handleDoubleClick,
  } = props;

  return (
    <div style={styles.gridWrap}>
      {items?.length === 0 ? (
        <Empty style={{ margin: 'auto' }} />
      ) : (
        <Skeleton loading={isLoading}>
          <div style={styles.grid}>
            {items?.map((item, index) => {
              item.thumbnail = item.thumb;
              item.isOwner = true;

              return (
                <GridItem
                  searchType={searchType}
                  viewType={viewType}
                  dataDetail={item}
                  handleDoubleClick={handleDoubleClick}
                />
              );
            })}
          </div>
        </Skeleton>
      )}
    </div>
  );
};

export default Grid;
