import React, { useMemo } from 'react';

import { Tooltip } from 'antd';
import { QA_SPEC_STYLE_CONST } from 'pages/qa-spec/constant';
import { QA_SPEC_EXPAND_STYLE } from 'static/Constants';

import classnames from 'classnames';

import './QaSpecTabHeader.less';

const TabHeader = ({
  title,
  icon,
  des,
  idx,
  isTabExpanded,
  productHeaderImageSize,
}) => {
  const { TAB_HEADER_DISTANCE_TO_ICON } = QA_SPEC_STYLE_CONST;
  const { TAB_HEIGHT } = QA_SPEC_EXPAND_STYLE;

  const marginStyle = useMemo(() => {
    const headerImageHeight = productHeaderImageSize?.height;

    return isTabExpanded && idx === 0
      ? {
          marginLeft:
            Math.sqrt(
              Math.pow(headerImageHeight, 2) -
                Math.pow(headerImageHeight - TAB_HEIGHT, 2)
            ) - TAB_HEADER_DISTANCE_TO_ICON,
        }
      : {};
  }, [productHeaderImageSize?.height, idx, isTabExpanded]);

  return (
    <Tooltip title={title}>
      <div
        className={classnames('tab-header', { 'tab-header--with-des': des })}
        style={marginStyle}
      >
        <div className='tab-header__icon'>{icon || title}</div>
        {des && <div className='tab-header__des'> {des} </div>}
      </div>
    </Tooltip>
  );
};

export default TabHeader;
