import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forwardTo } from 'utils/common/route';

import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { clearQueryCondition } from 'utils/queryCondition';

import NewImage from 'assets/navigation/new.png';
import { PRODUCT_NAV } from 'static/Constants';
import { SSO_APPROVAL_NAV } from 'static/Constants';

import { FilterIndicator } from './FilterIndicator';

//* STYLING
import './HomeNavActionGroup.less';

function HomeNavActionGroup(props) {
  const dispatch = useDispatch();

  const { actionData, absoluteRight, absoluteTop, link, type } = props;

  const isMemberNew = useSelector(globalSelectors.selectDisplayMemberNew());
  const isAssetNew = useSelector(globalSelectors.selectDisplayAssetNew());
  const isProductNew = useSelector(globalSelectors.selectDisplayProductNew());

  const isAssetUnmatch = useSelector(
    globalSelectors.selectDisplayAssetUnmatch()
  );
  const isProductUnmatch = useSelector(
    globalSelectors.selectDisplayProductUnmatch()
  );
  const isMemberUnmatch = useSelector(
    globalSelectors.selectDisplayMemberUnmatch()
  );

  const filterSsoUserPending = useSelector(
    globalSelectors.selectToggleSsoUserPending()
  );
  const filterSsoMemberPending = useSelector(
    globalSelectors.selectToggleSsoMemberPending()
  );
  const filterProducts = useSelector(globalSelectors.selectFilterProducts());
  const filterAssets = useSelector(globalSelectors.selectFilterAssets());
  const filterMembers = useSelector(globalSelectors.selectFilterMembers());

  const prevPathnameQuery = useSelector(
    selectorsGridView.makeSelectPrevPathnameQuery()
  );

  const isGdsnReceivedProducts = link === PRODUCT_NAV.subItems[2].link;
  const isSsoMemberApproval = link === SSO_APPROVAL_NAV.subItems[0].link;
  const isSsoUserApproval = link === SSO_APPROVAL_NAV.subItems[1].link;

  const actionCollection = {
    '/members': {
      target: 'new',
      isFiltering: isMemberNew,
      isUnmatched: isMemberUnmatch,
      toggleNew: 'toggleMemberNew',
      toggleUnmatched: 'toggleMemberUnmatch',
      resetPage: 'resetPageCurrentMember',
      filter: filterMembers,
      updateFilter: 'updateFilterMembers',
    },
    '/digital-media': {
      target: 'new',
      isFiltering: isAssetNew,
      isUnmatched: isAssetUnmatch,
      toggleNew: 'toggleAssetNew',
      toggleUnmatched: 'toggleAssetUnmatch',
      resetPage: 'resetPageCurrentMedia',
      filter: filterAssets,
      updateFilter: 'updateFilterAssets',
    },
    '/products': {
      target: 'new',
      isFiltering: isProductNew,
      isUnmatched: isProductUnmatch,
      toggleNew: 'toggleProductNew',
      toggleUnmatched: 'toggleProductUnmatch',
      resetPage: 'resetPageCurrentProduct',
      filter: filterProducts,
      updateFilter: 'updateFilterProducts',
    },
    '/products/syndication-history': {
      target: 'new',
      toggleNew: 'toggleNewSyndicationHistory',
    },
    '/products/gdsn-received-products': {
      target: 'new',
      toggleNew: 'toggleFilterPendingPublication',
    },
    '/security-matrix/permission-request': {
      target: 'new',
      toggleNew: 'toggleRequestNew',
    },
    '/sso-approval/member': {
      target: 'pending',
      toggleNew: 'toggleFilterPendingSsoMember',
      filter: filterSsoMemberPending,
    },
    '/sso-approval/user': {
      target: 'pending',
      toggleNew: 'toggleFilterPendingSsoUser',
      filter: filterSsoUserPending,
    },
    // '/ticketing-system': {
    //   target: 'new',
    //   toggleNew: 'toggleNewTicketingSystem',
    // },
  };

  const toggleNew = () => {
    const actions = actionCollection[link];
    if (actions.target !== 'new') return;
    if (!actions.toggleNew) return;

    // turnUnmatchedOff();

    if (actions.isFiltering) {
      dispatch(globalActions[actions.toggleNew](false));
      setTimeout(() => {
        dispatch(globalActions[actions.toggleNew](true));
      }, 100);
    } else {
      dispatch(globalActions[actions.toggleNew](true));
    }

    actions.resetPage && dispatch(actionsGridView[actions.resetPage]());
  };

  const toggleUnmatched = () => {
    const actions = actionCollection[link];

    if (!actions.toggleUnmatched) return;

    // turnNewOff();

    if (!actions.isUnmatched) {
      dispatch(globalActions[actions.toggleUnmatched](true));
    }

    const filterValue = actions.filters?.includes('unmatched')
      ? []
      : ['unmatched'];
    actions.updateFilter &&
      dispatch(globalActions[actions.updateFilter](filterValue));

    actions.resetPage && dispatch(actionsGridView[actions.resetPage]());
  };

  const filterPendingSso = () => {
    const actions = actionCollection[link];
    if (actions.target !== 'pending') return;
    if (actions.filter.length > 0) return;
    dispatch(
      globalActions[actions.toggleNew]([
        {
          fieldName: 'poolStatus',
          values: ['Pending'],
          filterType: 'In',
        },
      ])
    );
    actions.resetPage && dispatch(actionsGridView[actions.resetPage]());
  };

  const turnUnmatchedOff = () => {
    isAssetUnmatch && dispatch(globalActions.toggleAssetUnmatch(false));
    isProductUnmatch && dispatch(globalActions.toggleProductUnmatch(false));
    isMemberUnmatch && dispatch(globalActions.toggleMemberUnmatch(false));
  };

  const turnNewOff = () => {
    isAssetNew && dispatch(globalActions.toggleAssetNew(false));
    isProductNew && dispatch(globalActions.toggleProductNew(false));
    isMemberNew && dispatch(globalActions.toggleMemberNew(false));
  };

  const onIndicatorClick = (target) => () => {
    const actions = actionCollection[link];
    if (!actions) return;

    if (target === actions.target) {
      toggleNew();
      filterPendingSso();
    }

    target === 'unmatched' && toggleUnmatched();

    clearQueryCondition(dispatch, '', prevPathnameQuery);
    forwardTo(link);
  };

  const targetToggleFilter =
    isSsoMemberApproval || isSsoUserApproval ? 'pending' : 'new';

  return (
    <React.Fragment>
      {!!actionData &&
        actionData > 0 &&
        type === 'new' &&
        !isGdsnReceivedProducts && (
          <FilterIndicator
            absoluteRight={absoluteRight}
            absoluteTop={absoluteTop}
            actionData={actionData}
            onClickAction={onIndicatorClick(targetToggleFilter)}
          >
            <Image
              className={classnames(
                'home-nav-action-group__link-tag',
                'home-nav-action-group__link-tag--new'
              )}
              src={NewImage}
              width={21}
              preview={false}
            />
          </FilterIndicator>
        )}

      {!!actionData && actionData > 0 && type === 'unmatched' && (
        <FilterIndicator
          absoluteRight={absoluteRight}
          absoluteTop={absoluteTop}
          actionData={actionData}
          onClickAction={onIndicatorClick('unmatched')}
        >
          <i
            className='fa-solid fa-triangle-exclamation'
            style={{ fontSize: 16, color: '#ffc107' }}
          ></i>
        </FilterIndicator>
      )}
      {!!actionData && isGdsnReceivedProducts && actionData > 0 && (
        <FilterIndicator
          isGdsnReceivedProducts={isGdsnReceivedProducts}
          absoluteRight={'15px'}
          absoluteTop={absoluteTop}
          actionData={actionData}
          onClickAction={onIndicatorClick(targetToggleFilter)}
        ></FilterIndicator>
      )}
    </React.Fragment>
  );
}

HomeNavActionGroup.propTypes = {
  //? link - path name
  link: PropTypes.string,
  //? actionData - action status of activity and new
  actionData: PropTypes.number,
  //? absoluteRight - position absolute right position
  absoluteRight: PropTypes.number,
};

export default HomeNavActionGroup;
