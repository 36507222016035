import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { Row, Col, Button, Input, Empty, Spin, notification } from 'antd';
import { Form, CustomNotification } from 'common/components';
import { createIFrame, editIFrame } from 'services/digitalAsset';
import { AssetUploadSelect } from 'pages/asset-full-view/components';
import { useLocalStorage } from 'hooks/localStorage';
import './IframeAsset.less';

// const layout = { labelCol: { span: 4 } };
const layout = {};
let iframe;

const IframeAsset = (props) => {
  const {
    onSuccess,
    assetData,
    setUploadType,
    uploadType,
    isCreateAsset,
    methodList,
    typeViewAsset,
  } = props;
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [currentIFrame, setCurrentIFrame] = useLocalStorage('currentIFrame', [
    false,
    '',
  ]);

  const [form] = Form.useForm();

  const onInputChange = (input) => {
    if (input.trim()) setBtnDisabled(false);
    else setBtnDisabled(true);
  };

  const openNotify = (result, description) => {
    CustomNotification[result ? 'success' : 'error'](description);
  };

  const onPreview = () => {
    const iFrameLink = form.getFieldValue('iFrameLink');
    setLink('');
    setLoading(true);
    setTimeout(function () {
      setLink(iFrameLink);
      setLoading(false);
    }, 3000);
  };

  const onFinish = (values) => {
    if (assetData?.id) {
      editIFrame({
        Id: assetData?.id,
        IFrameLink: values.iFrameLink.trim(),
      }).then((response) => {
        if (response.isSuccess) {
          openNotify(response.isSuccess, 'iFrame Asset Updated!');
          setTimeout(function () {
            setCurrentIFrame([true, response.data?.iFrameLink]);
            onSuccess(response.data);
          }, 2000);
        } else openNotify(response.isSuccess, response.message);
      });
    } else {
      createIFrame({
        IFrameLink: values.iFrameLink.trim(),
      }).then((response) => {
        if (response.isSuccess && response.data?.iFrameLink) {
          openNotify(response.isSuccess, 'iFrame Asset Created!');
          const metaData = response.data;
          const id = metaData.id;
          setTimeout(function () {
            setCurrentIFrame([false, '']);
            onSuccess(id, metaData);
          }, 2000);
        } else openNotify(response.isSuccess, response.message);
      });
    }
  };

  const iFramePreviewRender = (link) => {
    iframe = `<iframe height="100%" width="1024" src="${link}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    return (
      <div
        style={{ height: '100%' }}
        dangerouslySetInnerHTML={{ __html: iframe ? iframe : '' }}
      />
    );
  };

  return (
    <div className='iframe-asset'>
      {isCreateAsset && (
        <AssetUploadSelect
          setUploadType={setUploadType}
          uploadType={uploadType}
          methodList={methodList}
          typeViewAsset={typeViewAsset}
        />
      )}
      <Form
        {...layout}
        form={form}
        className='iframe-asset__form'
        name='iframe-form'
        onFinish={onFinish}
      >
        <Col span={16} style={{ maxWidth: 700 }}>
          <Form.Item
            className='iframe-asset__form-item'
            name='iFrameLink'
            label='URL'
          >
            <Input
              className='folder-modal__input'
              placeholder='Input the URL'
              size='large'
              onChange={() => onInputChange(form.getFieldValue('iFrameLink'))}
            />
          </Form.Item>
        </Col>
      </Form>
      <Row className='iframe-asset__preview' align='middle' justify='center'>
        <Row
          className='iframe-asset__preview-inner'
          align='middle'
          justify='center'
        >
          {link ? (
            iFramePreviewRender(link)
          ) : loading ? (
            <Spin size='large' tip='IFrame Loading...' />
          ) : (
            <Empty
              className='iframe-asset__preview-empty'
              description='No Preview'
            />
          )}
        </Row>
        <Col xs={24}>
          <Row style={{ marginTop: 10 }}>
            <Col flex='auto' />
            <Col>
              <Button
                className='iframe-asset__form-button'
                type='primary'
                onClick={() => onPreview()}
                // size='large'
                disabled={btnDisabled}
              >
                Preview
              </Button>
              <Button
                className='iframe-asset__form-button'
                type='primary'
                htmlType='submit'
                form='iframe-form'
                // size='large'
                disabled={btnDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

IframeAsset.propTypes = {
  onSuccess: PropTypes.func,
  assetData: oneOfType([PropTypes.array, PropTypes.object]),
  setUploadType: PropTypes.func,
};

export default React.memo(IframeAsset);
