import React from 'react';
import Action from './Action';
import { MailOutlined } from '@ant-design/icons';

const Email = (props) => {
  const { count, offset, iconStyle, onClick } = props;
  return (
    <Action
      count={count}
      offset={offset}
      icon={MailOutlined}
      iconStyle={iconStyle}
      onClick={onClick}
    />
  );
};

export default Email;
