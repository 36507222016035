import React from 'react';

import { Typography } from 'antd';
import classnames from 'classnames';

const { Text } = Typography;

const MinimumRequirementsText = ({
  text,
  className,
  isError,
  ...restProps
}) => {
  return (
    <Text
      type={isError ? 'danger' : undefined}
      className={classnames({
        'minimum-requirement__text': true,
        [className]: !!className,
      })}
      {...restProps}
    >
      {text}
    </Text>
  );
};

export default MinimumRequirementsText;
