import React from 'react';
import PropTypes from 'prop-types';
import { Row, Divider } from 'antd';
import { StyledModal, FormDeleteButton } from 'common/components';
import ThreadUserList from './ThreadUserList';

import './ThreadInfoModal.less';

const ThreadInfoModal = ({
  visibleModal,
  setVisibleModal,
  isCreator,
  groupUsers,
  thread,
  onDeleteThread,
}) => {
  const modalProps = {
    title: 'Information',
    width: '30vw',
    destroyOnClose: true,
    visible: visibleModal,
    footer: null,
    className: 'thread-info-modal',
    onCancel: () => setVisibleModal(false),
    maskClosable: false,
  };

  return (
    <StyledModal {...modalProps}>
      <ThreadUserList groupUsers={groupUsers} />
      {isCreator && thread?.type === 'ChatGroup' && (
        <>
          <Divider />
          <Row className='thread-info-modal__remove'>
            <FormDeleteButton
              text='Remove Chat'
              onClick={() => onDeleteThread()}
            />
          </Row>
        </>
      )}
    </StyledModal>
  );
};

ThreadInfoModal.propTypes = {};

export default ThreadInfoModal;
