import React from 'react';
import { useDispatch } from 'react-redux';
import Messages from 'i18n/messages/maintenance';
import { TeamOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import * as memberMaintenanceActions from 'pages/member-maintenance/controllers/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import { forwardTo } from 'utils/common/route';

const Members = () => {
  const changeMaintenanceView = () => {
    forwardTo(`/maintenance/member-management`);
  };

  return (
    <RibbonButton
      icon={<TeamOutlined />}
      label={Messages.members}
      onClick={changeMaintenanceView}
    />
  );
};

export default Members;
