export const GET_TRANSITION_PAGE_GRID =
  '/api/NMAOTTAY/get-transition-page-manage-grid';

export const GET_TRANSITION_PAGE_GRID_DISTINCT =
  '/api/NMAOTTAY/get-transition-page-manage-grid/distinct';

export const GET_TRANSITION_PAGE_SHORT_DETAIL =
  '/api/NMAOTTAY/get-transition-page-short-detail';

export const GET_TRANSITION_PAGE_FULL_DETAIL =
  '/api/NMAOTTAY/get-transition-page-detail';

export const SAVE_TRANSITION_PAGE = '/api/NMAOTTAY/save-transition-page';
