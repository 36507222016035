import { handleCallApiReducer } from 'utils/api';

import { deleteObjectField } from 'utils';
import { formatMDYWithParam } from 'utils/formatDate';

const propertyFields = [
  { label: 'Created', key: 'created' },
  { label: 'Form type', key: 'formType' },
  { label: 'File name', key: 'fileName' },
  { label: 'Created by', key: 'createdByName' },
  { label: 'Member expoted', key: 'executedByMember' },
  { label: 'Entity type', key: 'entityType' },
];

const timeCols = ['Created', 'LastModified'];
const hiddenHistoryGridCols = ['DownloadLink', 'UniqueId'];

export const mapToCommonFetchState = (
  action,
  _draft,
  field,
  isKeepPrevData
) => {
  handleCallApiReducer(action, _draft, field, isKeepPrevData);
};

const mapDataPointToSubmitForm = (dataPointList, isExcel) => {
  if (!dataPointList || dataPointList.length === 0) return [];

  const nextDataPointList = [...dataPointList];

  return nextDataPointList.map((dataPointItem) => {
    let nextDataPointItem = { ...dataPointItem };

    deleteObjectField(nextDataPointItem, [
      'errorMessage',
      '@uuid',
      '@loopForEachEntity',
      '@condition',
      '@fillValue',
      '@style',
    ]);

    if (!isExcel) {
      deleteObjectField(nextDataPointItem, ['sheetName']);
    }

    if (dataPointItem?.['isManualFillOnDemand'] === true) {
      deleteObjectField(nextDataPointItem, ['condition', 'fillValue']);

      return nextDataPointItem;
    }

    if (dataPointItem?.['@condition'] === false) {
      deleteObjectField(nextDataPointItem, ['condition']);
    }

    if (dataPointItem?.['@fillValue'] === false) {
      deleteObjectField(nextDataPointItem, ['fillValue']);
    }

    if (dataPointItem?.['@style'] === false) {
      deleteObjectField(nextDataPointItem, ['style']);
    }

    if (dataPointItem?.['@loopForEachEntity'] === false) {
      deleteObjectField(nextDataPointItem, ['loopForEachEntity']);
    }

    if (dataPointItem?.['alias']) {
      nextDataPointItem.alias = nextDataPointItem.alias.trim();
    }

    return nextDataPointItem;
  });
};

export const mapToFormValue = (formValue, dataPoints, isExcel) => {
  if (!formValue) return {};

  let nextFormValue = { ...formValue };

  if (formValue?.formName && formValue?.formName.length > 0) {
    nextFormValue.formName = formValue.formName.trim();
  }

  const submitDataPoints = mapDataPointToSubmitForm(dataPoints, isExcel);

  return { ...nextFormValue, dataPoints: submitDataPoints };
};

export const mapToShortDetailFormProperties = (shortDetailData) => {
  if (!shortDetailData) return [];

  return Object.keys(shortDetailData).reduce(
    (propertyDataList, propertyName) => {
      const propertyValue = shortDetailData[propertyName];
      if (propertyValue) {
        const foundField = propertyFields.find(
          (field) => field?.key === propertyName
        );
        if (foundField) {
          propertyDataList = [
            ...propertyDataList,
            {
              label: foundField?.label,
              info: propertyValue,
            },
          ];
        }
      }

      return propertyDataList;
    }
  );
};

export const formaFormtHistoryGridColumns = (columns = []) => {
  let newColumns = [
    {
      checkboxSelection: true,
      field: '',
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
  ];

  if (columns.length > 0) {
    columns.forEach((col) => {
      if (hiddenHistoryGridCols.includes(col?.fieldName)) {
        return;
      }

      if (timeCols.includes(col.fieldName)) {
        newColumns.push({
          ...col,
          valueFormatter: formatMDYWithParam,
          resizable: true,
        });
      } else if (col.fieldName === 'HistoryId') {
        newColumns.push({
          ...col,
          width: 100,
          resizable: true,
        });
      } else if (['FormName', 'FileName'].includes(col.fieldName)) {
        newColumns.push({
          ...col,
          minWidth: 200,
          flex: 1,
          resizable: true,
        });
      } else {
        newColumns.push({
          ...col,
          resizable: true,
        });
      }
    });
  }
  return newColumns;
};

export const mapToExistedFormNameMessage = (formInstance) => {
  const formName = formInstance.getFieldsValue()?.formName;

  return `The Form name: '${formName}' is existing.`;
};
