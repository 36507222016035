import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Image, Typography } from 'antd';

import AssetQuery from 'assets/query/AssetQuery.png';
import ProductQuery from 'assets/query/ProductQuery.png';
import MemberQuery from 'assets/query/MemberQuery.png';
import FolderQuery from 'assets/query/FolderQuery.png';
import ReportQuery from 'assets/query/ReportQuery.png';
import { Images } from 'config/assets';

import { InfoWithLabel, FavoriteStarIconRender } from 'common/components';
import { formatMDY } from 'utils/formatDate';

import QueryConditions from 'common/components/advanced-search/QueryConditions';
import AdvancedSearchTitle from 'common/components/advanced-search/AdvancedSearchTitle';
import { DragDropContext } from 'react-beautiful-dnd';

import './QueryPanel.less';

const QueryPanel = ({ dataDetail }) => {
  let image =
    dataDetail?.gridName === 'member-detail-grid'
      ? MemberQuery
      : dataDetail?.gridName === 'digital-asset-detail-grid'
      ? AssetQuery
      : dataDetail?.gridName === 'folder-detail-grid'
      ? FolderQuery
      : dataDetail?.gridName === 'products-for-member' ||
        dataDetail?.gridName === 'product-detail-grid'
      ? ProductQuery
      : dataDetail?.gridName === 'assets-for-member'
      ? AssetQuery
      : dataDetail?.gridName === 'query-reporting-grid'
      ? ReportQuery
      : Images.RIVIR_LOGO_DEFAULT;

  const infoColProps = {
    infoColProps: {
      flex: 'auto',
    },
    labelColProps: {
      flex: '130px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  const query = JSON.parse(dataDetail?.query);

  return (
    <Row className='query-panel' style={{ margin: 8 }}>
      <Row
        align='bottom'
        className='query-panel__main'
        style={{ width: '100%', marginTop: 37 }}
      >
        <Col span={7}>
          <Image
            src={image}
            width={100}
            height={100}
            style={{ pointerEvents: 'none' }}
          ></Image>
        </Col>
        <Col span={17}>
          <Typography.Title
            style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}
          >
            {/* <FavoriteStarIconRender
              isFavorited={shortDetail?.isFavorited}
              style={{ marginRight: '6px' }}
            /> */}
            {dataDetail?.name}
          </Typography.Title>
        </Col>
      </Row>
      <Row className='query-panel__des'>
        <Col span={24}>
          <InfoWithLabel
            info={dataDetail?.description}
            label={'Description'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <Row className='query-panel__body'>
        <Col span={12}>
          <InfoWithLabel
            info={dataDetail?.ownerFullName}
            label={'Owner'}
            {...infoColProps}
          />
        </Col>
        <Col span={12}>
          <InfoWithLabel
            info={dataDetail?.created && formatMDY(dataDetail?.created)}
            label={'Date Created'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <Row className='query-panel__body'>
        <Col span={12}>
          <InfoWithLabel
            info={!dataDetail?.editable ? 'No' : 'Yes'}
            label={'Editable'}
            {...infoColProps}
          />
        </Col>
        <Col span={12}>
          <InfoWithLabel
            info={
              dataDetail?.lastModified && formatMDY(dataDetail?.lastModified)
            }
            label={'Last Updated'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <Row className='query-panel__body'>
        <Col span={12}>
          <InfoWithLabel
            info={!dataDetail?.shareable ? 'No' : 'Yes'}
            label={'Shareable'}
            {...infoColProps}
          />
        </Col>
        <Col span={12}>
          <InfoWithLabel
            info={dataDetail?.dateShared && formatMDY(dataDetail?.dateShared)}
            label={'Date Shared'}
            {...infoColProps}
          />
        </Col>
      </Row>
      <DragDropContext>
        <Row className='query-panel__body' style={{ marginTop: 8 }}>
          {/* <Col
            span={24}
            className='query-panel__list'
            style={{ marginBottom: 8 }}
          >
            <AdvancedSearchTitle title={'Data Columns'} />
            <DataColumns
              dataColumns={query?.dataColumns}
              selectedTaskIds={[]}
              viewMode
            />
          </Col> */}
          <Col span={24} className='query-panel__list'>
            <AdvancedSearchTitle title={'Query Conditions'} />
            <QueryConditions
              queryConditions={query?.queryConditions}
              selectedTaskIds={[]}
              viewMode
              advancedSearchGridView={true}
            />
          </Col>
        </Row>
      </DragDropContext>
    </Row>
  );
};

QueryPanel.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default QueryPanel;
