import React, { useMemo } from 'react';

import { Checkbox, Typography, Tooltip, Row, Col, Badge } from 'antd';
import {
  EditOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import {
  ColumnContainer,
  RowContainer,
  TextInfo,
  Loading,
  CollapseView,
} from './ShareComponents';

import {
  getInfoProductItem,
  formatInfo,
  findDataProperties,
  groupProductModuleProperties,
  checkGroupPropertiesHaveData,
  getTitleGroupName,
  getNumberOfDisplayedProperties,
} from './utils';

import { useCheckAllowEditProducts, useGetProductFullView } from 'hooks';

const CollapseViewInfo = (props) => {
  const {
    isLoading,
    hasPermissionEditProperties,
    productSchema = [],
    activeKeyPanels,
    productItemModules,
    handleCollapseView,
    handleClickEditBtn,
    errorInfo,
    productEnums,
    foundModuleName,
    productId,
  } = props;

  const { productFull } = useGetProductFullView({ productId });

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const renderIconEdit = (moduleName, moduleDisplayName) => {
    const isShowEditBtn =
      checkAllowEditProductFull(productFull) &&
      hasPermissionEditProperties &&
      activeKeyPanels.includes(moduleName);

    return (
      <>
        {isShowEditBtn && (
          <Tooltip placement='left' title={`Edit ${moduleDisplayName}`}>
            <EditOutlined
              className='product-detail-view__edit-icon'
              onClick={(event) => {
                event.stopPropagation();
                handleClickEditBtn(moduleName, moduleDisplayName);
              }}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const checkError = (module) => {
    if (!errorInfo || errorInfo?.moduleName !== module?.moduleName)
      return false;

    if (errorInfo?.errorProductId) {
      return 'error';
    }

    if (!errorInfo?.errorProductId) {
      return 'warning';
    }
  };

  return (
    <div className='product-detail-view__list-info'>
      {isLoading ? (
        <Loading />
      ) : (
        productSchema.map((module) => {
          const errorStatus = checkError(module);

          return (
            <CollapseView
              activeKey={activeKeyPanels}
              collapsible='header'
              headerPanel={
                <Typography.Title
                  level={5}
                  className={classnames(
                    'product-detail-view__list-info-title',
                    {
                      'found-module-scroll':
                        module?.moduleName === foundModuleName,
                      'product-detail-view__list-info-title--suggestion':
                        module?.moduleName === foundModuleName,
                      'product-detail-view__list-info-title--error':
                        errorStatus === 'error',
                      'product-detail-view__list-info-title--warning':
                        errorStatus === 'warning',
                    }
                  )}
                >
                  {module.moduleDisplayName}
                  {errorStatus === 'warning' && (
                    <WarningOutlined style={{ margin: '0 4px' }} />
                  )}
                  {errorStatus === 'error' && (
                    <CloseCircleOutlined style={{ margin: '0 4px' }} />
                  )}
                </Typography.Title>
              }
              keyPanel={module.moduleName}
              expandIconPosition='left'
              extraPanel={() =>
                renderIconEdit(module.moduleName, module.moduleDisplayName)
              }
              key={module.moduleName}
              onChange={handleCollapseView}
            >
              <ViewProductInfo
                dataProperties={findDataProperties(
                  module.moduleName,
                  productItemModules
                )}
                properties={module.moduleProperties}
                errorInfo={errorInfo}
                errorStatus={errorStatus}
                productEnums={productEnums}
                moduleData={module}
              />
            </CollapseView>
          );
        })
      )}
    </div>
  );
};

const ViewProductInfo = React.memo((props) => {
  const {
    dataProperties,
    properties,
    errorInfo,
    errorStatus,
    productEnums,
    moduleData,
  } = props;

  const groupProperties = useMemo(
    () => groupProductModuleProperties(moduleData),
    [moduleData]
  );

  const listGroupName = Object.keys(groupProperties?.moduleProperties) ?? [];

  // ['Flavor', 'null', ...] or something like this. ['null'] is the non group property
  const isGroupProperties = listGroupName.some((val) => val !== 'null');

  const renderView = (propertyDisplayName, propertyName, listName, isArray) => {
    const valueProperty = getInfoProductItem(propertyName, dataProperties);
    const invalidValue =
      typeof valueProperty !== 'number' &&
      typeof valueProperty !== 'boolean' &&
      isEmpty(valueProperty);

    if (invalidValue) return null;

    return (
      <RowContainer
        key={propertyName}
        className='product-detail-view__row-view-product'
      >
        {renderLeftSide({
          propertyDisplayName,
          propertyName,
          errorStatus,
          errorInfo,
        })}
        {renderRightSide(propertyName, listName, isArray)}
      </RowContainer>
    );
  };

  const renderLeftSide = ({
    propertyDisplayName,
    propertyName,
    errorStatus,
    errorInfo,
  }) => {
    const isInvalid = errorInfo?.fieldName === propertyName;

    return (
      <ColumnContainer style={{ textAlign: 'left' }}>
        <TextInfo
          ellipsis
          strong
          className={classnames({
            'product-detail-view__text-info--warning':
              errorStatus === 'warning' && isInvalid,

            'product-detail-view__text-info--error':
              errorStatus === 'error' && isInvalid,
          })}
        >
          {propertyDisplayName}:
        </TextInfo>
      </ColumnContainer>
    );
  };
  const renderRightSide = (propertyName, listName, isArray) => {
    const info = formatInfo(
      getInfoProductItem(
        propertyName,
        dataProperties,
        listName,
        productEnums,
        isArray
      )
    );

    if (info === 'true') {
      return (
        <ColumnContainer style={{ paddingLeft: 14, textAlign: 'left' }}>
          <Checkbox checked />
        </ColumnContainer>
      );
    } else if (info === 'false') {
      return (
        <ColumnContainer style={{ paddingLeft: 14, textAlign: 'left' }}>
          <Checkbox checked={false} />
        </ColumnContainer>
      );
    }

    return (
      <ColumnContainer style={{ paddingLeft: 5, textAlign: 'left' }}>
        <TextInfo ellipsis style={{ whiteSpace: 'pre-wrap' }}>
          {info}
        </TextInfo>
      </ColumnContainer>
    );
  };

  return (
    <>
      {isGroupProperties ? (
        <>
          {listGroupName.map((groupName) => {
            const isGroupPropertiesHaveData = checkGroupPropertiesHaveData(
              dataProperties,
              groupProperties?.moduleProperties[`${groupName}`]
            );

            return (
              <>
                {isGroupPropertiesHaveData ? (
                  <Row key={groupName}>
                    <Col span={24} style={{ paddingLeft: 16 }}>
                      <Badge
                        count={getNumberOfDisplayedProperties(
                          dataProperties,
                          groupProperties?.moduleProperties[`${groupName}`]
                        )}
                        className='product-detail-view__badge'
                      >
                        <Typography.Title
                          level={5}
                          style={{ color: '#595959' }}
                        >
                          {getTitleGroupName(
                            groupName,
                            isGroupPropertiesHaveData
                          )}
                        </Typography.Title>
                      </Badge>
                    </Col>
                    <Col
                      span={24}
                      style={{ paddingLeft: 20, paddingBottom: 20 }}
                    >
                      {groupProperties?.moduleProperties[`${groupName}`].map(
                        (property) =>
                          renderView(
                            property.propertyDisplayName,
                            property.propertyName,
                            property.listName,
                            property.isArray
                          )
                      )}
                    </Col>
                  </Row>
                ) : null}
              </>
            );
          })}
        </>
      ) : (
        <Col span={24}>
          {properties.map((property) =>
            renderView(
              property.propertyDisplayName,
              property.propertyName,
              property.listName,
              property.isArray
            )
          )}
        </Col>
      )}
    </>
  );
});

export default CollapseViewInfo;
