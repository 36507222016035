export const POST_DETAIL_GRID = '/api/help/manage/help-post-detail-grid';
export const POST_DETAIL_GRID_DISTINCT =
  '/api/help/manage/help-post-detail-grid/distinct';
export const POST_CONTENT_DETAIL = '/api/help/get-help-post-data';
export const GET_HELP_CATEGORY_DETAIL_GRID =
  '/api/help/manage/help-category-detail-grid';
export const GET_HELP_CATEGORY_DETAIL_GRID_DISTINCT =
  '/api/help/manage/help-category-detail-grid/distinct';
export const GET_HELP_CATEGORY_DETAIL_DATA =
  '/api/help/manage/get-help-category-short-detail';

export const HELP_SAVE_POST = '/api/help/manage/save-help-post';
export const RE_INDEX_HELP_CATEGORY =
  '/api/help/manage/reindex-help-categories';
export const RE_INDEX_HELP_POST = '/api/help/manage/reindex-help-posts';
export const SAVE_HELP_CATEGORY = '/api/help/manage/save-help-category';

export const GET_HELP_POST = '/api/help/manage/get-help-post-short-detail';

export const DELETE_HELP_POST = '/api/help/manage/delete-help-posts';
export const UPLOAD_HELP_ASSET = '/api/help/manage/upload-help-asset';

export const DELETE_HELP_CATEGORY = '/api/help/manage/delete-help-categories';
export const GET_HELP_ASSETS = '/api/help/manage/get-help-assets';

export const ARTICLE_DETAIL_GRID = '/api/article/article-details-grid';
export const ARTICLE_DETAIL_GRID_DISTINCT =
  '/api/article/article-detail-grid/distinct';
export const GET_ALL_ARTICLES = '/api/article/get-all-articles';
export const DETAIL_ARTICLE = '/api/article/article-details';
export const SAVE_ARTICLE = '/api/article/save-article';
export const DELETE_ARTICLE = '/api/article/delete-articles';
export const GET_ARTICLE_ASSETS = '/api/article/get-article-assets';
export const UPLOAD_ARTICLE_ASSETS = '/api/article/upload-article-asset';

// Communication-templates
export const GET_COMMUNICATION_TEMPLATES_ASSETS =
  '/api/member-manager/get-communicate-template-assets';

export const UPLOAD_COMMUNICATION_TEMPLATES_ASSETS =
  '/api/member-manager/upload-communicate-template-asset';

// Backup and restore article grid view
export const BACKUP_HELP_CENTER = '/api/system/backup-help-center';
export const RESTORE_HELP_CENTER = '/api/system/restore-help-center';
