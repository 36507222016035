import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import axios from 'axios';
import moment from 'moment';

import { ProfileContactHistory } from 'common/components';

import { getUserContactHistory } from 'services/user';

const UserContactHistory = (props) => {
  const PAGE_SIZE = 6;
  const PAGE_INDEX = 1;
  // cancel api flag
  let isCancel = false;
  // signal for cancel axios api calling
  const signal = axios.CancelToken.source();

  const [contactHistory, setContactHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const generateDataField = () => {
    return [
      {
        key: 'contactDate',
        options: {
          ellipsis: true,
          width: '15%',
          render: (text) => {
            return moment(text).format('MM/DD/YYYY');
          },
        },
      },
      {
        key: 'companyName',
        options: {
          ellipsis: true,
          width: '20%',
        },
      },
      {
        key: 'userName',
        options: {
          ellipsis: true,
          width: '20%',
        },
      },
      {
        key: 'messageSubject',
        options: {
          ellipsis: true,
          width: 'auto',
        },
      },
    ];
  };

  const fetchApi = () => {
    // just get the 6 first items
    const historyApiParams = {
      pageIndex: PAGE_INDEX,
      pageSize: PAGE_SIZE,
    };

    getUserContactHistory(historyApiParams, signal.token)
      .then((response) => {
        const contactHistories = get(response, 'gridData', []);
        // get total item, if can not, use pageSize to show 1 button

        if (contactHistories && !isCancel) {
          setContactHistory(contactHistories);
        }
      })
      .catch((err) => {})
      .finally(() => {
        if (!isCancel) setLoading(false);
      });
  };

  useEffect(() => {
    fetchApi();
    return () => {
      // cancel api calling when unmounted
      signal.cancel();
      // eslint-disable-next-line
      isCancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ProfileContactHistory
      data={contactHistory}
      dataField={generateDataField()}
      arrowField='isInBound'
      typeField='contactType'
      dataKey='id'
      loading={loading}
    />
  );
};
ProfileContactHistory.propTypes = {
  fields: PropTypes.array,
};

ProfileContactHistory.defaultProps = {
  fields: [
    {
      key: 'contactDate',
      options: {
        ellipsis: true,
        width: '15%',
        render: (text) => {
          return moment(text).format('MM/DD/YYYY');
        },
      },
    },
    {
      key: 'companyName',
      options: {
        ellipsis: true,
        width: '20%',
      },
    },
    {
      key: 'userName',
      options: {
        ellipsis: true,
        width: '20%',
      },
    },
    {
      key: 'messageSubject',
      options: {
        ellipsis: true,
        width: 'auto',
      },
    },
  ],
};

export default UserContactHistory;
