import React from 'react';
import PropTypes from 'prop-types';

import { ThumbnailItem } from 'common/components';
import { useCheckAllowEditProducts } from 'hooks';

const ProductThumbnail = (props) => {
  const {
    dataDetail,
    selected,
    onClickItemGrid,
    onClickCheckboxItem,
    onDoubleClick,
    labelThumbnail,
    imageThumbnail,
    ...rest
  } = props;

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const isAllowEditProduct = checkAllowEditProductFull(dataDetail);

  return (
    <ThumbnailItem
      dataDetail={dataDetail}
      labelThumbnail={labelThumbnail}
      imageThumbnail={imageThumbnail}
      selected={selected}
      onClickItemGrid={onClickItemGrid}
      onClickCheckboxItem={onClickCheckboxItem}
      onDoubleClick={onDoubleClick}
      isShareBtn={true}
      shareable={
        isAllowEditProduct &&
        dataDetail?.visibility?.toLowerCase() === 'only if shared'
      } //shareable class for asset
      isProductThumbnail={true}
      isEditable={isAllowEditProduct}
      {...rest}
    />
  );
};
ProductThumbnail.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductThumbnail;
