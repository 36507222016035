import { useEffect } from 'react';

import * as servicesMember from 'services/members';

import { useFetch } from 'hooks/useAsync';

export const useMemberPublicContact = (props) => {
  const { isEnabled, memberId } = props;
  const { data, run } = useFetch();

  const fetchMemberPublicContact = (memberId) => {
    const params = {
      memberId: memberId,
      pageIndex: 1,
      pageSize: 100,
    };

    run(servicesMember.getPublicContact(params));
  };

  useEffect(() => {
    isEnabled && fetchMemberPublicContact(memberId);
  }, []);

  return {
    memberPublicContactList: data?.data?.gridData,
    fetchMemberPublicContact,
  };
};
