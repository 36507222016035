import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { UpSquareOutlined, DownSquareOutlined } from '@ant-design/icons';

import * as maintenanceFormActions from 'pages/maintenance-form/controllers/actions';
import * as maintenanceFormSelectors from 'pages/maintenance-form/controllers/selectors';

const ToggleFloatModeFormBtn = (props) => {
  const { formId, ...restProps } = props;

  const dispatch = useDispatch();

  const isFormDetailFloatMode = useSelector(
    maintenanceFormSelectors.makeSelectIsFormDetailFloatMode()
  );

  const onClickToggleFloatMode = () => {
    dispatch(maintenanceFormActions.toggleFormDetailFloatMode());
  };

  return (
    <RibbonButton
      icon={
        isFormDetailFloatMode ? <UpSquareOutlined /> : <DownSquareOutlined />
      }
      label={isFormDetailFloatMode ? 'Float Mode On' : 'Float Mode Off'}
      onClick={onClickToggleFloatMode}
      style={{ width: 106 }}
      {...restProps}
    />
  );
};

export default ToggleFloatModeFormBtn;
