import React, { useEffect, useState, useRef } from 'react';

import { Button, Form, Row, Col } from 'antd';
import { StepForm, StyledModal, CountdownTrack } from 'common/components';
import { LeftOutlined } from '@ant-design/icons';
import OtpCheck from '../otp-check/OtpCheck';
import OtpTrigger from '../otp-trigger/OtpTrigger';

import { sessionTimeFormatter, getNewResendOtpTime } from '../../utils/utils';
import { SessionEnded } from '../shared/SessionEnded';

import { useStatePrevious } from 'hooks/usePrevious';
import { OTP_ACTION, OTP_TYPE } from '../../constants';

import './OtpVerifyChangeMfaPhone.less';

const OtpVerifyChangeMfaPhone = (props) => {
  const { visible, onClose } = props;
  const allowResendHook = useStatePrevious(false);
  const timeAllowResendHook = useState();

  const [step, setStep] = useState(0);

  const handleClicBackToChangePhoneNumber = () => {
    setStep(0);
  };

  const modalTitle = (
    <Row style={{ minHeight: 26 }}>
      <Col flex='auto'></Col>

      {step === 1 && (
        <Col>
          <Button
            type='primary'
            shape='round'
            icon={<LeftOutlined />}
            style={{ display: 'table', marginLeft: 'auto', marginRight: 10 }}
            onClick={handleClicBackToChangePhoneNumber}
          >
            Change Phone Number
          </Button>
        </Col>
      )}
    </Row>
  );

  return (
    <StyledModal
      title={modalTitle}
      visible={visible}
      footer={false}
      onCancel={onClose}
      width={400}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <ModalContent
        step={step}
        setStep={setStep}
        onClose={onClose}
        visible={visible}
        allowResendHook={allowResendHook}
        timeAllowResendHook={timeAllowResendHook}
      />
    </StyledModal>
  );
};

const ModalContent = (props) => {
  const {
    onClose,
    visible,
    step,
    setStep,
    allowResendHook,
    timeAllowResendHook,
  } = props;

  const [otp, setOtp] = useState();
  const [changeMfaPhoneSession, setChangeMfaPhoneSession] = useState(null);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [otpCheckError, setOtpCheckError] = useState('');

  const [isAllowResend] = allowResendHook;

  const refOtpTrigger = useRef();
  const [formInst] = Form.useForm();
  const [timeAllowResend, setTimeAllowResend] = timeAllowResendHook;

  const { expireAt } = changeMfaPhoneSession || {};

  const onResendOtp = () => {
    refOtpTrigger.current.onResendOtp();
    setTimeAllowResend(getNewResendOtpTime());
  };

  const getSessionRemainingTime = (remainingTime) => {
    if (remainingTime === 0 && !isSessionExpired) {
      setIsSessionExpired(true);
    }
  };

  useEffect(() => {
    if (visible) {
      setStep(0);
    }
  }, [visible]);

  useEffect(() => {
    if (step === 0) {
      setOtp([]);
    }
  }, [step]);

  useEffect(() => {
    if (isSessionExpired) {
      setStep(2);
    }
  }, [isSessionExpired]);

  const stepDefine = [
    {
      step: 0,
      wrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 350,
      },
      component: (
        <OtpTrigger
          ref={refOtpTrigger}
          setStep={setStep}
          isAllowAddPhone
          formInst={formInst}
          setChangeMfaPhoneSession={setChangeMfaPhoneSession}
          isAllowResend={isAllowResend}
          setTimeAllowResend={setTimeAllowResend}
          timeAllowResend={timeAllowResend}
          setOtpCheckError={setOtpCheckError}
          otpAction={OTP_ACTION.changeMfaPhone}
          addNew
        />
      ),
    },
    {
      step: 1,
      wrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 350,
      },
      component: (
        <div>
          <OtpCheck
            formInst={formInst}
            otp={otp}
            setOtp={setOtp}
            onResendOtp={onResendOtp}
            step={step}
            addNew
            changeMfaPhoneSession={changeMfaPhoneSession}
            onClose={onClose}
            allowResendHook={allowResendHook}
            timeAllowResendHook={timeAllowResendHook}
            otpCheckError={otpCheckError}
            setOtpCheckError={setOtpCheckError}
            otpAction={OTP_ACTION.changeMfaPhone}
            otpType={OTP_TYPE.phone}
          />
        </div>
      ),
    },
    {
      step: 2,
      wrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      component: (
        <div>
          <SessionEnded message='MFA session ended, Please try again' />
        </div>
      ),
    },
  ];
  return (
    <>
      <div>
        <StepForm currentStep={step} stepDefine={stepDefine} />
      </div>
      {expireAt && (
        <CountdownTrack
          wrapperClass='otp-verify-change-mfa-phone__session-count-down'
          expirationTime={expireAt}
          totalTime={600}
          getRemainingTime={getSessionRemainingTime}
          timeFormatter={sessionTimeFormatter}
          noProgress
        />
      )}
    </>
  );
};

export default OtpVerifyChangeMfaPhone;
