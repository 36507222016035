import React from 'react';
import { LabelValueWrapper, TypePicker } from 'common/components';
import { Row, Col } from 'antd';

import { FACING, ANGLE, PACKAGING_DISPOSITION } from 'static/Constants';

const AssetType = (props) => {
  const {
    assetType,
    assetSubType,
    packageLevel,
    packagingDisposition,
    angle,
    facing,
    language,
    panelType,
    logoType,
    certificateType,
    documentType,
  } = props;

  let objectData = { assetType, assetSubType };

  const getFieldLabel = ({ constant, value }) => {
    return constant.find((constItem) => {
      return constItem.value?.toLowerCase() === value?.toLowerCase();
    })?.label;
  };

  if (assetSubType === 'Planogram') {
    objectData = {
      ...objectData,
      packageLevel,
      packagingDisposition: getFieldLabel({
        constant: PACKAGING_DISPOSITION,
        value: packagingDisposition,
      }),
      angle: getFieldLabel({ constant: ANGLE, value: angle }),
      facing: getFieldLabel({ constant: FACING, value: facing }),
      language,
    };
  } else if (assetSubType === 'Panel') {
    objectData = {
      ...objectData,
      panelType,
    };
  } else if (assetSubType === 'Logo') {
    objectData = {
      ...objectData,
      logoType,
    };
  } else if (assetSubType === 'Certificate') {
    objectData = {
      ...objectData,
      certificateType,
    };
  } else if (assetSubType === 'Company') {
    objectData = {
      ...objectData,
      documentType,
    };
  }

  return (
    <>
      <div className='asset-type'>
        <Row>
          <Col span={24}>
            <LabelValueWrapper
              object={objectData}
              parentScoop='digitalAsset.overview'
              colspan={12}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AssetType;
