import React, {
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AgGridClient } from 'common/components';

import * as utils from './utils';

const disableRowClickCols = ['unRegisterProduct'];

const PublicationTable = (props) => {
  const { data, onSelect } = props;

  const refAggrid = useRef();
  const [rowData, setRowData] = useState([]);

  const columns = useMemo(() => {
    const firstRowData = data?.[0];

    return utils.getColumnDefs({ item: firstRowData });
  }, [data?.[0]]);

  const onSelectRow = (params) => {
    const selectedRows = params.api.getSelectedRows();
    onSelect && onSelect(selectedRows);
  };

  const onCellClick = (clickEvent) => {
    const colId = clickEvent?.column?.colId;
    if (!disableRowClickCols.includes(colId)) {
      clickEvent.node.setSelected(true);
    }
  };

  useEffect(() => {
    const newData = data.map((dataItem) => ({
      unRegisterProduct: false,
      ...dataItem,
    }));

    setRowData(newData);
  }, [data]);

  return (
    <AgGridClient
      rowData={rowData}
      ref={refAggrid}
      columnDefs={columns}
      containerStyle={{ height: 400 }}
      rowSelection='multiple'
      onSelectionChanged={onSelectRow}
      onCellClicked={onCellClick}
      suppressRowClickSelection
    />
  );
};

export default PublicationTable;
