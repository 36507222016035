import moment from 'moment';

import { useEffect } from 'react';

import { getUsersMeeting, getMeetingDetails } from 'services/dashboard';

import { useAsync } from 'hooks';

const PAGE_INDEX = 1;
const PAGE_SIZE = 9999;

const formatYMD = (data) => {
  return data ? moment(data).format('YYYY/MM/DD') : '';
};

export const getFormattedDateNow = () => {
  return moment(Date.now()).format('dddd, MMMM Do YYYY, h:mm a');
};

export const useGetAttendeesMeeting = ({ searchText, isEnabled }) => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      const params = {
        pageIndex: PAGE_INDEX,
        pageSize: searchText ? PAGE_SIZE : 100,
        search: {
          searchText,
        },
      };
      run(getUsersMeeting(params));
    }
  }, [isEnabled, run, searchText]);

  return {
    attendees: data?.gridData ?? [],
    isLoadingSearchAttendees: status === 'pending',
  };
};

export const useGetTopAttendeesMeeting = () => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    const params = {
      pageIndex: 1,
      pageSize: 100,
    };
    run(getUsersMeeting(params));
  }, [run]);

  return {
    topAttendees: data?.gridData ?? [],
    isLoadingTopAttendees: status === 'pending',
  };
};

export const useFetchMeetingDetails = ({ meetingId, isEnabled }) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(getMeetingDetails({ meetingId }));
    }
  }, [isEnabled, meetingId, run]);

  return {
    meetingDetail: data ?? {},
  };
};

export const groupAttendees = (users) => {
  let result = {};
  let memberNames = [];

  users.forEach((user) => {
    if (!memberNames.includes(user.memberName)) {
      result[user.memberName] = [].concat(user);
      memberNames = memberNames.concat(user.memberName);
    } else {
      result[user.memberName] = result[user.memberName].concat(user);
    }
  });
  return result;
};

export const concatenateTime = (date, time) => {
  const formattedDate = formatYMD(date);
  const formattedTime = moment(time, 'hh:mm:ss A').format('HH:mm:ss');

  const result = moment(
    formattedDate + ' ' + formattedTime,
    'YYYY/MM/DD HH:mm:ss'
  ).toISOString();

  return result;
};

export const getInitialFormValues = () => {
  const d = new Date();
  const hour = d.getHours();

  return {
    startDate: moment(Date.now()),
    endDate: moment(Date.now()),
    startTime: moment(`${hour + 1}:00:00`, 'HH:mm:ss'),
    endTime: moment(`${hour + 2}:00:00`, 'HH:mm:ss'),
  };
};

export const generateDisabledHours = (hour) => {
  let hours = [];
  if (hour) {
    for (let index = 1; index < hour; index++) {
      hours.push(index);
    }
  }
  return hours;
};

export const checkAttendeeIsSelected = (attendeeIds = [], selectedId) => {
  return attendeeIds.includes(selectedId);
};

export const correctTime = (fullTime) => {
  const formattedFullTime = moment(fullTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
  return formattedFullTime;
};

export const displayFullName = (attendee) => {
  const { fullName, firstName, lastName } = attendee;

  if (fullName) return fullName;
  return `${firstName} ${lastName}`;
};
