import React, { useState } from 'react';

import { WithAbsoluteContainer } from 'common/components/';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';

import { useGetPublishingProduct } from './hooks';

import ProdductItemSearchDrawer from './ProductItemSearchDrawer';
import PublicationErrorDetailModal from './PublicationErrorDetailModal';
import PublicationErrorChecker from './PublicationErrorChecker';

import productFullViewSaga from 'pages/product-full-view/controllers/saga';
import productFullViewReducer from 'pages/product-full-view/controllers/reducer';

import './styles.less';

const key = 'productFullView';

const PublicationValidationErrorCheck = (props) => {
  const {
    validationResult,
    isErrorListModalOpen,
    toggleErrorListModal,
    getPublishCheckList,
    publishCheckLoading,
  } = props;

  const errorList = validationResult?.errors || [];
  const packageLevelItem = validationResult;
  const hierarchyItems = [validationResult];

  const productItemId = packageLevelItem?.productId;

  useInjectReducer({ key, reducer: productFullViewReducer });
  useInjectSaga({ key, saga: productFullViewSaga });

  const [isErrModalVisible, setErrModalVisible] = useState(false);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [currentError, setCurrentError] = useState(null);
  const [productId, setProductId] = useState(null);
  const selectedErrorIndexHook = useState(null);
  const [reloadOneError, setReloadOneError] = useState(false);

  const { publishingProduct } = useGetPublishingProduct({
    productId,
    isEnabled: Boolean(productId),
  });

  const toggleCheckErrorModal = (productId, errorInfo) => {
    setErrModalVisible((prev) => {
      if (!prev) {
        setProductId(productId);
        setCurrentError(errorInfo);
      } else {
        setCurrentError(null);
        setProductId(null);
      }
      return !prev;
    });
  };

  const toggleIsDrawerOpen = () => setIsDrawerVisible((prev) => !prev);

  const setEditProductId = (productId) => {
    setProductId(productId);
  };

  return (
    <WithAbsoluteContainer>
      <PublicationErrorChecker
        isErrorListModalOpen={isErrorListModalOpen}
        errorList={errorList}
        setProductId={setProductId}
        toggleCheckErrorModal={toggleCheckErrorModal}
        toggleErrorListModal={toggleErrorListModal}
        selectedErrorIndexHook={selectedErrorIndexHook}
        reloadOneError={reloadOneError}
        getPublishCheckList={getPublishCheckList}
        publishCheckLoading={publishCheckLoading}
        productItemId={productItemId}
        packageLevelItem={packageLevelItem}
      />

      {isErrModalVisible && (
        <PublicationErrorDetailModal
          isErrModalVisible={isErrModalVisible}
          publishingProduct={publishingProduct}
          toggleCheckErrorModal={toggleCheckErrorModal}
          currentError={currentError}
          toggleIsDrawerOpen={toggleIsDrawerOpen}
          getPublishCheckList={getPublishCheckList}
          selectedErrorIndexHook={selectedErrorIndexHook}
          setReloadOneError={setReloadOneError}
          setErrModalVisible={setErrModalVisible}
        />
      )}

      <ProdductItemSearchDrawer
        visile={isDrawerVisible}
        toggleIsDrawerOpen={toggleIsDrawerOpen}
        isDrawerVisible={isDrawerVisible}
        productDetail={publishingProduct}
        setEditProductId={setEditProductId}
        hierarchyItems={hierarchyItems}
      />
    </WithAbsoluteContainer>
  );
};

export default PublicationValidationErrorCheck;
