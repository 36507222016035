import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';

import { Typography, Tag, Button, Upload, Image, Tooltip, Col } from 'antd';
import {
  SendOutlined,
  FileExcelOutlined,
  LinkOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import {
  CustomNotification,
  dialogFunction,
  FormButton,
} from 'common/components';
import { ConfirmContent } from 'pages/asset-full-view/components';
import { IconFileAsset, IconFolderAsset } from '../shared/components';
import CreateFolderModal from 'pages/home/ribbon/components/controls/folders/CreateFolderModal';

import * as homeActions from 'pages/home/ribbon/asset-full/controllers/actions';
import * as assetActions from 'pages/asset-full-view/controllers/actions';
import * as assetSelectors from 'pages/asset-full-view/controllers/selectors';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as ribbonSelector from 'redux/ribbon/selectors';
import * as brandingActions from 'redux/branding/actions';
import * as globalActions from 'redux/global/actions';

import { forwardTo, navigateToRouteAsset } from 'utils/common/route';
import {
  getFormDataParams,
  filterFoldersAndFilesInSelectedItems,
  getFilesPath,
  getFoldersPath,
  getFormValues,
} from '../shared/utils.js';
import { sleep } from 'utils/delay';

import { isImageType } from 'utils/fileType';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useCheckAllowCreateFolder } from '../shared/hooks';

import {
  importAssetsFromInterop,
  createReplacedAsset,
} from 'services/interoperability';

import xlsFile from 'assets/file-formats/xls.png';

import { RIBBON_VIEW, ROUTE } from 'static/Constants';

import * as endpointAsset from 'services/digitalAsset/endpoints';
import * as damServices from 'services/digitalAsset';

import * as api from 'config/axios';

import './DropboxActions.less';

const typeExcel =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const DropboxActions = (props) => {
  const {
    idConnection,
    formInstance,
    selectedItems,
    multipleImport,
    typeViewAsset,
    // statusCopy,
    importedFolderParams,
    onDeleteItem,
    handleCloseModal,
    onCopyFileNameExcel,
    destinationFolder,
    isEnabledImportFolder,
  } = props;

  const dispatch = useDispatch();

  const [status, setStatus] = useState('idle');
  const [file, setFile] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);

  const replacedGridAsset = useSelector(
    gridSelector.makeSelectItemCurrentSelection()
  );

  const replacedFullView = useSelector(assetSelectors.assetDataLoading());

  const isAssetFullView =
    useSelector(ribbonSelector.selectRibbon()) ===
    RIBBON_VIEW.ASSET_FULL_VIEW.NAME;

  const isAllowCreateFolder = useCheckAllowCreateFolder();

  const assetId = isAssetFullView
    ? replacedFullView?.id
    : replacedGridAsset?.id;

  const reloadPage = useDispatchReloadPage();

  const isHasDestinationFolder = Boolean(
    importedFolderParams?.destinationFolderId
  );

  const handleReplaceSingleAsset = async () => {
    const singleItem = selectedItems[0];
    const params = {
      id: idConnection,
      storageServiceType: 'dropbox',
      filePath: [singleItem.data.path],
    };

    const formData = getFormDataParams(params);

    setStatus('loading');
    updateProgressBar(selectedItems, 'uploading', 0);

    try {
      const response = await createReplacedAsset(formData, assetId);

      if (response?.isSuccess) {
        const metaData = response.data;

        setStatus('success');
        handleCreateSingleAssetSuccess(metaData);
        updateProgressBar(selectedItems, 'done', 100);
      } else {
        setStatus('error');
        updateProgressBar(selectedItems, 'error', 0);
      }
    } catch (error) {
      setStatus('error');
      updateProgressBar(selectedItems, 'error', 0);
    }
  };

  const handleCreateSingleAsset = async () => {
    const singleItem = selectedItems[0];
    const params = {
      id: idConnection,
      storageServiceType: 'dropbox',
      filePaths: [singleItem.data.path],
      isCreateThumbs: true,
    };
    const formData = getFormDataParams(params);

    setStatus('loading');
    updateProgressBar(selectedItems, 'uploading', 0);

    try {
      const response = await importAssetsFromInterop(formData);
      if (response.isSuccess) {
        const metaData = response.data;
        setStatus('success');
        handleCreateSingleAssetSuccess(metaData);
        updateProgressBar(selectedItems, 'done', 100);
      } else {
        setStatus('error');
        updateProgressBar(selectedItems, 'error', 0);
      }
    } catch (error) {
      setStatus('error');
      updateProgressBar(selectedItems, 'error', 0);
    }
  };

  const handleCreateSingleAssetSuccess = (metaData) => {
    const id = metaData.id;

    handleCloseModal();
    dispatch(assetActions.cancelUpdateSuccess());
    dispatch(assetActions.createDigitalAssetFullSuccess(metaData));
    dispatch(homeActions.editAsset());
    dispatch(homeActions.creatingAsset());
    dispatch(brandingActions.getBrandingNoLoading());

    navigateToRouteAsset({
      typeViewAsset,
      id,
      isMultipleImport: multipleImport,
    });
  };

  const handleCloseCreateFolder = () => setVisibleModal(false);

  const handleCreateMultipleAssets = async (otherParams = {}) => {
    const formValues = getFormValues(formInstance);

    const { folders, files } =
      filterFoldersAndFilesInSelectedItems(selectedItems);

    const filePaths = getFilesPath(folders, files);
    const folderPaths = getFoldersPath(folders);

    const params = {
      id: idConnection,
      storageServiceType: 'dropbox',
      filePaths,
      folderPaths,
      templateFile: file,
      ...importedFolderParams,
      ...formValues,
      ...otherParams,
    };

    let formData = getFormDataParams(params);

    setStatus('loading');
    updateProgressBar(selectedItems, 'uploading', 0);

    try {
      await sleep(1000);
      const response = await importAssetsFromInterop(formData);

      if (response?.isSuccess) {
        setStatus('success');
        handleCloseModal();
        handleCloseCreateFolder();

        dispatch(brandingActions.getBrandingNoLoading());

        updateProgressBar(selectedItems, 'done', 100);

        CustomNotification.success('Create asset successfully');
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
        setStatus('error');
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Something went wrong');
      setStatus('error');
      updateProgressBar(selectedItems, 'error', 0);
    }
  };

  const handleReplaceThumbnail = async () => {
    const singleItem = selectedItems[0];
    const isImage = isImageType(singleItem?.data?.path);
    if (!isImage) {
      CustomNotification.error('Only support image type');
      return;
    }
    const params = {
      assetId,
      id: idConnection,
      storageServiceType: 'dropbox',
      filePath: singleItem?.data?.path,
    };
    const formData = getFormDataParams(params);
    setStatus('loading');
    try {
      const response = await damServices.replaceDigitalAssetsThumbnails(
        formData
      );
      if (response?.isSuccess) {
        setStatus('success');
        handleCloseModal();
        reloadPage({ clearSelection: true });
        CustomNotification.success('Replace Preview successfully');
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
        setStatus('error');
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Something went wrong');
      setStatus('error');
    }
  };

  const modalConfirmImportAsset = (otherParams = {}) => {
    const multiImportFormValue = getFormValues(formInstance);

    const newDestinationFolder = otherParams?.destinationFolderId
      ? { ...destinationFolder, destinationFolder: { ...otherParams } }
      : destinationFolder;

    dialogFunction({
      type: 'warn',
      content: (
        <ConfirmContent
          destinationFolder={newDestinationFolder}
          isEnabledImportFolder={isEnabledImportFolder}
          multiImportFormValue={multiImportFormValue}
        />
      ),
      onOk: async () => {
        CustomNotification.warning(
          'Please wait while the import process is being completed. This may require additional time, especially for larger datasets. We appreciate your understanding and patience during this process',
          30
        );
        handleCreateMultipleAssets(otherParams);
        forwardTo(ROUTE.DIGITAL_MEDIA);
      },
      okText: 'Upload',
      width: 450,
    });
  };

  const handleCreateAssetFromInterop = () => {
    // If user doesn't have create folder permission, we won't show modal for creating destination folder
    const isIgnoreShowModalCreate = !isAllowCreateFolder;

    if (multipleImport) {
      if (isIgnoreShowModalCreate) {
        modalConfirmImportAsset();
      } else if (isEnabledImportFolder && !isHasDestinationFolder) {
        setVisibleModal(true);
      } else {
        modalConfirmImportAsset();
      }
    } else {
      if (typeViewAsset === 'replace-asset') {
        handleReplaceSingleAsset();
      } else if (typeViewAsset === 'create-asset') {
        handleCreateSingleAsset();
      } else {
        handleReplaceThumbnail();
        return;
      }
    }
  };

  const handleUploadFile = (file) => {
    if (file?.type === typeExcel) {
      CustomNotification.success('The file is attached successfully');
      setFile(file);
    } else {
      CustomNotification.error('Only accept xls file');
      setFile(null);
    }
    return false;
  };

  const updateProgressBar = (selectedItems = [], status, percent) => {
    if (status === 'error' || status === 'done' || status === 'uploading') {
      selectedItems.forEach((fileItem) => {
        dispatch(
          globalActions.updateProgressBar({
            type: 'upload',
            name: fileItem.data.path,
            percent,
            status,
            uid: fileItem.data.itemId,
          })
        );
      });
    }
  };

  const disabledBtn = selectedItems.length === 0;

  return (
    <div
      className={classnames({
        'asset-dropbox__action': true,
        'asset-dropbox__action--multiple': multipleImport,
      })}
    >
      <div style={{ display: 'flex' }}>
        <Typography.Title level={5}>Selected Items</Typography.Title>

        {file && (
          <div style={{ marginLeft: 4, display: 'flex', alignItems: 'center' }}>
            <Tooltip title={file.name}>
              <Image
                className='asset-ftp__sub-title-xls-img'
                height={28}
                width={23}
                src={xlsFile}
                preview={false}
                placeholder={
                  <LoadingOutlined
                    style={{ fontSize: 24, backgroundColor: 'white' }}
                  />
                }
              />
            </Tooltip>
            <Button
              className='dropbox-asset__btn-delete'
              danger
              shape='circle'
              icon={<CloseOutlined />}
              style={{
                marginLeft: 4,
                border: 'none',
              }}
              onClick={() => setFile(null)}
            />
          </div>
        )}
      </div>

      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          className={
            multipleImport
              ? 'dropbox-asset__result dropbox-asset__result--multiple scroller'
              : 'dropbox-asset__result dropbox-asset__result--single'
          }
        >
          {selectedItems.map((item) => (
            <SelectedFile
              key={item.data.itemId}
              name={item.data.name}
              itemId={item.data.itemId}
              type={item.data.type}
              onDeleteItem={onDeleteItem}
            />
          ))}
        </div>
        <div
          className={
            multipleImport
              ? 'dropbox-asset__btn-container-multiple'
              : 'dropbox-asset__btn-container-single'
          }
        >
          {multipleImport && (
            <>
              <FormButton
                icon={<FileExcelOutlined />}
                onClick={() => {
                  api.sendDownload({
                    url: endpointAsset.GET_IMPORT_ASSET_TEMPLATE || '/unknown',
                  });
                }}
                type='default '
                text='Download xls Template'
                disabled
              />
              <FormButton
                icon={<LinkOutlined />}
                type='default'
                text='Copy All Selected Items'
                onClick={onCopyFileNameExcel}
                disabled
              />
              <Upload beforeUpload={handleUploadFile} style={{ width: '100%' }}>
                <FormButton
                  style={{ width: '100%' }}
                  icon={<LinkOutlined />}
                  type='default'
                  text='Attach file (.xls)'
                  disabled
                />
              </Upload>
            </>
          )}
          <FormButton
            icon={<SendOutlined />}
            text='Send'
            // loading={status === 'loading'}
            onClick={handleCreateAssetFromInterop}
            style={{ width: '100%' }}
            disabled={disabledBtn}
          />
        </div>
      </div>

      {isAllowCreateFolder && (
        <CreateFolderModal
          visibleHook={[visibleModal, setVisibleModal]}
          successCallback={(responseData) => {
            modalConfirmImportAsset({
              destinationFolderId: responseData?.id,
            });
          }}
          additionalButton={() => {
            return (
              <Col span={12}>
                <Button
                  type='default'
                  onClick={() => modalConfirmImportAsset()}
                >
                  Continue without destination folder
                </Button>
              </Col>
            );
          }}
        />
      )}
    </div>
  );
};

const SelectedFile = ({ name, itemId, type, onDeleteItem }) => {
  return (
    <Tag
      icon={
        type === 'folder' ? (
          <IconFolderAsset
            width={18}
            height={18}
            style={{
              marginTop: 2,
            }}
          />
        ) : (
          <IconFileAsset
            width={18}
            height={18}
            style={{
              marginTop: 2,
            }}
          />
        )
      }
      color='success'
      className='dropbox-asset__result-tag'
    >
      <Typography.Text
        strong
        style={{ color: '#1e8e3e', marginLeft: 4, fontSize: 14 }}
      >
        {name}
      </Typography.Text>
      <Button
        className='dropbox-asset__btn-delete'
        danger
        shape='circle'
        icon={<CloseOutlined />}
        style={{
          marginLeft: 4,
          border: 'none',
        }}
        onClick={() => onDeleteItem(itemId)}
      />
    </Tag>
  );
};

export default DropboxActions;
