import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Row, Col, Spin } from 'antd';

import {
  AssetTile,
  AssetThumbnail,
  AssetPanel,
} from 'pages/branded-assets/components';
import { GridView } from 'common/components/';
import { OpenItemContentPane } from 'common/components/openItem';

import { selectRibbonType } from 'redux/ribbon/selectors';
import * as actions from 'redux/global/actions';
import * as actionsGlobal from 'redux/global/actions';
import * as selectorsGlobal from 'redux/global/selectors';
import * as brandingSelectors from 'redux/branding/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import reducer from 'pages/branded-assets/controllers/reducer';
import saga from 'pages/branded-assets/controllers/saga';
import * as actionsAsset from 'pages/branded-assets/controllers/actions';
import * as selectorAsset from 'pages/branded-assets/controllers/selectors';
import reducerLinkAssets from 'pages/branded-assets/components/panel/link-assets/controller/reducers';
import sagaLinkAssets from 'pages/branded-assets/components/panel/link-assets/controller/saga';

import * as selectorsAssets from 'pages/branded-assets/controllers/selectors';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import * as actionsAssetFull from 'pages/home/ribbon/asset-full/controllers/actions';
import * as actionsLinkAssets from 'pages/branded-assets/components/panel/link-assets/controller/actions';

import assetReducer from 'pages/asset-full-view/controllers/reducer';
import sagaDigitalAsset from 'pages/asset-full-view/controllers/saga';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import { getGridName } from 'utils/getGridName';
import { updateSizeCurrent } from 'common/components/grid-view/utils';
import { clearQueryCondition } from 'utils/queryCondition';

import { useWindowSize } from 'hooks/windowSize';
import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';
import {
  useSaveConfigColumns,
  useFilterDefaultColumns,
  useSaveAllColumns,
  useSaveChosenColumns,
} from 'hooks/configGridHooks';
import {
  useListenReloadPage,
  useUpdateSelectedData,
} from 'hooks/useReloadPage';
import { useClearSearchText } from 'hooks/useClearSearchText';
import { useFilterGrid } from 'hooks/useFilterGrid';

import { useGetMemberId } from './hooks';

import { SEARCH_CRITERIA, RIBBON_OPTIONS, OPEN_ITEM } from 'static/Constants';
import * as constant from 'static/Constants';

import * as endpointsAsset from 'services/assetProduct/endpoints';
import _ from 'lodash';
import { handleCreateSearchContainer } from 'pages/reporting/utils';
import { filterAsset } from './utils';

import { handlePayloadParamsQuery } from 'utils/handlePayloadParamsQuery';

const key = 'assetList';
const keyLinkAssets = 'linkAssets';
const keyDigitalAsset = 'digitalAsset';

const Assets = (props) => {
  const option = RIBBON_OPTIONS.ASSET_FOR_MEMBER;
  const searchCriteria = SEARCH_CRITERIA.ASSET;
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  useInjectReducer({ key: keyLinkAssets, reducer: reducerLinkAssets });
  useInjectSaga({ key: keyLinkAssets, saga: sagaLinkAssets });
  useInjectReducer({ key: keyDigitalAsset, reducer: assetReducer });
  useInjectSaga({ key: keyDigitalAsset, saga: sagaDigitalAsset });

  const { mediaType } = props;
  const dispatch = useDispatch();
  const isFavoriteRoute = useCheckFavoriteRoute();
  const { refMemberId } = useGetMemberId();
  const pathname = window.location.pathname;

  const fetchAssetForMember = useCallback((params) => {
    if (!refMemberId.current) return;
    dispatch(
      actionsAsset.getDigitalAssetForMemberList({
        ...params,
      })
    );
  }, []);

  const columns = useSelector(selectorAsset.makeSelectColumns());
  const searchCategoriesEffected = useSelector(
    selectorsGridView.makeSelectSearchCategoriesList()
  );

  const [requestParams, setRequestParams] = useState({
    DigitalMediaType: mediaType,
    searchCategory: searchCategoriesEffected || [],
  });
  const [filterFromAssets, setFilterFromAssets] = useState([]);
  const asset = useSelector(selectorAsset.makeSelectAssetList());
  const totalPagination = useSelector(selectorAsset.makeSelectTotal());

  const detailCurrentItemsSelection = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const selectAsset = detailCurrentItemsSelection?.[0];

  const {
    assetForMemberPage: [currentPageNumber, currentPageSize],
  } = useSelector(selectorsGridView.makeSelectPageCurrent());

  const visibleContentPane = useSelector(selectorsGridView.makeSelectVisible());

  const loading = useSelector(selectorAsset.makeSelectLoading());

  const { assetForMemberText } = useSelector(
    selectorsGlobal.selectSelectSearchText()
  );

  const typeView = useSelector(selectRibbonType());
  const assetData = useSelector(selectorAsset.makeSelectAssetData());
  const loadingDetail = useSelector(selectorAsset.makeSelectLoadingDetail());
  const statusDelete = useSelector(selectorsAssets.makeSelectStatusDelete());

  const {
    allColumns,
    defaultColumns: defaultColumnsState,
    isRefreshGrid,
  } = useSelector(selectorsGridView.makeSelectGridConfig());

  const showAdvanceFilter = useSelector(
    selectorsGlobal.selectShowAdvanceFilter()
  );

  const isNewAssetFilter = useSelector(selectorsGlobal.selectDisplayAssetNew());
  const brandingData = useSelector(brandingSelectors.getBranding());

  const fromDate = isNewAssetFilter ? brandingData?.lastGetNewDamsTime : null;
  const cachedFromDate = useSelector(selectorAsset.makeSelectCachedFromDate());

  const [width] = useWindowSize();
  const gridName = getGridName(pathname);

  const stringSearchCategories = JSON.stringify(searchCategoriesEffected);

  const queryConditions = useSelector(
    selectorsGridView.makeSelectAssetsForMemberQuery()
  );
  const prevPathnameQuery = useSelector(
    selectorsGridView.makeSelectPrevPathnameQuery()
  );
  clearQueryCondition(dispatch, pathname, prevPathnameQuery);
  const checkQueryCondition = useSelector(
    selectorsGridView.makeSelectCheckQueryCondition()
  );
  const myQueryDoubleClick = useSelector(
    selectorsGridView.makeSelectMyQueryDoubleClick()
  );

  useSaveConfigColumns(gridName);
  useSaveAllColumns(columns);
  const defaultColumns = useFilterDefaultColumns(columns);
  useSaveChosenColumns(columns);

  const { gridFilters: filterAssets, updateGridFilter } = useFilterGrid({
    entityType: 'asset',
    clearOtherFilter: true,
  });

  const searchText = assetForMemberText;

  const saveFromDateValue = () => {
    if (isNewAssetFilter && fromDate && !cachedFromDate) {
      dispatch(actionsAsset.cacheFromDate(fromDate));
    }
  };

  const updateRibbonCallback = useCallback(() => {
    dispatch(actions.changeRibbonActions(option));
  }, [dispatch]);
  const updateSearchCriteriaCallback = useCallback(() => {
    dispatch(actions.updateSearchCriteria(searchCriteria));
  }, [dispatch]);

  useEffect(() => {
    updateRibbonCallback();
  }, [updateRibbonCallback]);

  useEffect(() => {
    updateSearchCriteriaCallback();
  }, [updateSearchCriteriaCallback]);

  useEffect(() => {
    // the first time come branded-asset will remove assetData on content pane
    dispatch(actionsAsset.getDigitalAssetShortDetailSuccess(null));
    // reset member id of asset
    dispatch(actionsAsset.saveIdMember(null));
  }, [dispatch]);

  useEffect(() => {
    if (visibleContentPane && selectAsset) {
      dispatch(actionsAsset.getDigitalAssetShortDetail(selectAsset.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAsset, visibleContentPane]);

  useEffect(() => {
    setRequestParams({
      ...requestParams,
      searchCategory: searchCategoriesEffected,
      fromDate: cachedFromDate,
    });
  }, [JSON.stringify(searchCategoriesEffected), cachedFromDate]);

  useEffect(() => {
    if (stringSearchCategories === 'null') return;

    const filters = filterAsset(refMemberId, filterAssets);

    setFilterFromAssets(filters);
    saveFromDateValue();
    if (
      //!warning = this condtion might be wrong since the querycondition should be applied for all view type
      // typeView !== constant.RIBBON_TYPES.DETAILSVIEW &&
      !checkQueryCondition &&
      !myQueryDoubleClick
    ) {
      const advancedSearchContainer =
        handleCreateSearchContainer(queryConditions);
      requestParams.advancedSearchContainer = advancedSearchContainer;
      requestParams.searchCategory = searchCategoriesEffected;

      setRequestParams(requestParams);

      //* prevent call api when filtering new asset but fromDate not loaded yet
      // if ((isNewAssetFilter && !fromDate) || (!isNewAssetFilter && fromDate)) return;

      if (typeView !== constant.RIBBON_TYPES.DETAILSVIEW) {
        isNewAssetFilter &&
          fromDate &&
          filters.length === 0 &&
          fetchAssetForMember({
            pageSize: currentPageSize,
            pageNumber: currentPageNumber,
            search: searchText,
            filters,
            mediaType,
            isFavoriteRoute,
            advancedSearchContainer,
            searchCategory: searchCategoriesEffected,
            fromDate: cachedFromDate || fromDate || null,
          });

        !isNewAssetFilter &&
          !fromDate &&
          fetchAssetForMember({
            pageSize: currentPageSize,
            pageNumber: currentPageNumber,
            search: searchText,
            filters,
            mediaType,
            isFavoriteRoute,
            advancedSearchContainer,
            searchCategory: searchCategoriesEffected,
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    searchText,
    filterAssets,
    mediaType,
    isFavoriteRoute,
    typeView,
    stringSearchCategories,
    isNewAssetFilter,
    currentPageSize,
    currentPageNumber,
    // JSON.stringify(searchCategoriesEffected),
  ]);

  useEffect(() => {
    return () => {
      dispatch(actionsGridView.deleteItemsSelection());
      dispatch(actionsGridView.updatePagesSelection([]));
      dispatch(actionsGridView.updateItemPageSelection([]));
    };
  }, [
    dispatch,
    searchText,
    filterAssets,
    JSON.stringify(searchCategoriesEffected),
  ]);

  useEffect(() => {
    if (typeView === constant.RIBBON_TYPES.DETAILSVIEW) {
      dispatch(
        actionsAsset.gridDigitalAssetColumnInfo('digital-asset-detail-grid')
      );
    }
  }, [dispatch, typeView, pathname, isNewAssetFilter]);

  useEffect(() => {
    if (isNewAssetFilter) {
      filterAssets?.length > 0 && updateGridFilter([]);
      dispatch(actionsAsset.updateLatestGetNewAsset());
    }
  }, [dispatch, isNewAssetFilter]);

  useEffect(() => {
    if (checkQueryCondition && myQueryDoubleClick) {
      updateSizeCurrent(dispatch, 1, currentPageSize, pathname);

      const advancedSearchContainer =
        handleCreateSearchContainer(queryConditions);
      requestParams.advancedSearchContainer = advancedSearchContainer;
      requestParams.searchCategory = searchCategoriesEffected;

      setRequestParams(requestParams);

      dispatch(actionsGridView.deleteItemsSelection());

      if (typeView === constant.RIBBON_TYPES.DETAILSVIEW) {
        dispatch(actionsGridView.updateIsRefreshGrid());
      } else {
        fetchAssetForMember({
          pageSize: currentPageSize,
          pageNumber: 1,
          search: searchText,
          filters: filterFromAssets,
          mediaType,
          isFavoriteRoute,
          advancedSearchContainer,
          searchCategory: searchCategoriesEffected,
          fromDate: cachedFromDate,
        });
      }
      dispatch(actionsGridView.checkQueryCondition(false));
      dispatch(actionsGridView.myQueryDoubleClick(false));
    }
  }, [
    dispatch,
    checkQueryCondition,
    //* add currentPageSize help keep page size when clear adv filter
    currentPageSize,
  ]);

  useEffect(() => {
    dispatch(actionsAssetFull.disableModeLinkAsset());
    dispatch(actionsLinkAssets.resetLinkAsset());
    dispatch(actionsGridView.resetPageCurrentMember());
    dispatch(actionsGlobal.updateSearchTextProduct(''));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actionsAsset.showLoading());
  }, []);

  useEffect(() => {
    return () => {
      clearNewAssetFilter();
    };
  }, []);

  // re-call api when page need to reload
  const isReloadPage = useListenReloadPage(() => {
    const advancedSearchContainer =
      handleCreateSearchContainer(queryConditions);

    fetchAssetForMember({
      pageSize: currentPageSize,
      pageNumber: currentPageNumber,
      search: searchText,
      filters: filterFromAssets,
      mediaType,
      isFavoriteRoute,
      advancedSearchContainer,
      searchCategory: searchCategoriesEffected,
      fromDate: cachedFromDate,
    });
  });

  useUpdateSelectedData(asset);
  useClearSearchText(dispatch, 'assetForMember', pathname);

  const AssetGridThumbnail = (configProps) => {
    return <AssetThumbnail {...configProps} {...{ pathname: '/asset' }} />;
  };

  const AssetGridTile = (configProps) => {
    return <AssetTile {...configProps} {...{ pathname: '/asset' }} />;
  };

  function updateAssetTypePane(value) {
    const submitForm = {
      id: selectAsset?.id,
      ...value,
    };
    dispatch(actionsAsset.updateAssetTypePane(submitForm));
  }

  const clearNewAssetFilter = () => {
    dispatch(actionsAsset.toggleAssetNew(false));
    dispatch(actionsAsset.toggleAssetUnmatch(false));
    dispatch(actionsAsset.cacheFromDate(null));
  };

  const urlGrid = endpointsAsset.DIGITAL_ASSET_DETAIL_GRID;
  const urlGridDistinct = endpointsAsset.DIGITAL_ASSET_DETAIL_GRID_DISTINCT;

  return (
    <>
      <Row
        style={{
          width: '100%',
          height: 'calc(100vh - 148px)',
          maxHeight: 'calc(100vh - 148px)',
          overflow: 'hidden',
        }}
      >
        <Col
          style={{
            width: showAdvanceFilter ? 'calc(100% - 500px)' : '100%',
            height: '100%',
          }}
        >
          <Skeleton
            paragraph={{
              rows: Math.ceil(width / constant.WIDTH_ROW_SKELETION),
            }}
            active={true}
            loading={loading}
          >
            {statusDelete === 'loading' ? (
              <Row
                style={{
                  position: 'absolute',
                  top: '40%',
                  left: '45%',
                  zIndex: 10,
                }}
                align='middle'
                justify='center'
              >
                <Spin size='large' />
              </Row>
            ) : null}

            <GridView
              dataList={asset}
              typeView={typeView}
              thumbnailGridChildren={AssetGridThumbnail}
              tileGridBody={AssetGridTile}
              totalPagination={totalPagination}
              pageSizePagination={currentPageSize}
              pageNumberPagination={currentPageNumber}
              columnDefs={
                defaultColumnsState.length === 0 ? allColumns : defaultColumns
              }
              urlGrid={urlGrid}
              urlGridDistinct={urlGridDistinct}
              responseParams='data'
              pathname='/asset'
              panelDetail={
                <OpenItemContentPane
                  config={{
                    type: OPEN_ITEM.TYPE.ASSET_CONTENT_PANE,
                    mode: OPEN_ITEM.MODE.VIEW,
                    url: `/asset/${assetData?.id}`,
                    name:
                      assetData?.assetName || 'Asset Full View (default view)',
                  }}
                >
                  <AssetPanel
                    key='asset-content-pane'
                    loadingDetail={loadingDetail}
                    assetData={assetData}
                    saveFolder={() => console.log('todo-save-folder')}
                    onSubmit={(value) => updateAssetTypePane(value)}
                  />
                </OpenItemContentPane>
              }
              filterFromMainPage={filterFromAssets}
              requestParams={requestParams}
              shouldReloadDetailGrid={isRefreshGrid}
              showAdvanceFilter={showAdvanceFilter}
              queryConditions={queryConditions}
              enableDragToFolder
              isDisplaySearchCategories
              isToggle={isNewAssetFilter}
              onClickToSeeAll={clearNewAssetFilter}
              applyDefaultSort={searchText ? false : true}
            />
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};

Assets.propTypes = { mediaType: PropTypes.string };

export default Assets;
