import React from 'react';
import { Row, Col, Space, Button, Empty } from 'antd';
import { StyledModal } from 'common/components';

import './MdTableList.less';

const MdTableList = (props) => {
  const {
    tableList,
    visible,
    toggleTableList,
    setEdittingTable,
    toggleIsEditTableOpen,
    onDeleteTable,
    onInsertTable,
  } = props;

  const handleClickEditTable = (tableItem) => {
    setEdittingTable && setEdittingTable(tableItem);
    toggleIsEditTableOpen && toggleIsEditTableOpen();
  };

  const handleDeleteEditTable = (tableName) => {
    onDeleteTable && onDeleteTable(tableName);
  };

  const handleInsertEditTable = (tableName) => {
    onInsertTable && onInsertTable(tableName);
  };

  return (
    <StyledModal
      className='md-table-list__modal'
      title='Table List'
      visible={visible}
      onOk={toggleTableList && toggleTableList}
      cancelButtonProps={{ style: { visibility: 'hidden' } }}
      onCancel={toggleTableList && toggleTableList}
      okText={'OK'}
      cancelText={'Cancel'}
      maskClosable={false}
    >
      <div className='md-table-list'>
        {tableList && tableList.length > 0 ? (
          <Row className='md-table-list__header'>
            <Col xs={5}>Table ID</Col>
            <Col xs={10}> Name</Col>
            <Col flex='auto'>Control</Col>
          </Row>
        ) : (
          <Empty />
        )}

        {tableList && tableList.length > 0 && (
          <React.Fragment>
            {tableList.map((tableItem) => (
              <Row
                className='md-table-list__item'
                key={'list-item-' + tableItem?.name}
              >
                <Col xs={5}>{tableItem?.name}</Col>
                <Col xs={10}>{tableItem?.displayName}</Col>

                <Col>
                  <Space>
                    <Button onClick={() => handleClickEditTable(tableItem)}>
                      Edit
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => handleInsertEditTable(tableItem?.name)}
                    >
                      Insert
                    </Button>
                    <Button
                      danger
                      onClick={() => handleDeleteEditTable(tableItem?.name)}
                    >
                      Delete
                    </Button>
                  </Space>
                </Col>
              </Row>
            ))}
          </React.Fragment>
        )}
      </div>
    </StyledModal>
  );
};

export default MdTableList;
