import React from 'react';

import { Divider } from 'antd';

import { ViewLayout, AdvanceStack } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import EditArticlesGridView from './components/controls/maintenance/help/EditArticlesGridView';
import CreateArticle from './components/controls/maintenance/help/CreateArticle';
import DeleteArticles from './components/controls/maintenance/help/DeleteArticles';
import Backup from './components/controls/maintenance/help/Backup';
import Restore from './components/controls/maintenance/help/Restore';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const HelpArticlesGridView = () => {
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.ARTICLE}>
        <EditArticlesGridView />
        <CreateArticle isSmallBtn />
        <DeleteArticles isSmallBtn />
        <RibbonDivider />
      </Can>
      <Backup />
      <Restore />
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default HelpArticlesGridView;
