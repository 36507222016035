import React from 'react';
import { useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import Members from '../../controls/maintenance/member/Members';
import Users from '../../controls/maintenance/member/Users';
import ExecuteTemplate from '../../controls/maintenance/member/ExecuteTemplate';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';

const MaintenanceInfoSection = (props) => {
  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );

  const selectedMembers = useSelector(
    gridViewSelectors.makeSelectItemsSelection()
  );

  return (
    <ContainerButton {...props}>
      <ItemButton
        style={{ display: 'flex', justifyContent: 'center', width: 69 }}
        noneSpan
      >
        {isMemberGridInfoView ? (
          <Users disabled={selectedMembers?.length !== 1} />
        ) : (
          <Members />
        )}
      </ItemButton>
    </ContainerButton>
  );
};

export default MaintenanceInfoSection;
