import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import moment from 'moment';
import { Button, Modal, Form } from 'antd';
import MutationGridConfig from './MutationGridConfig';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { useGetDataQueryConditions, useCheckPermissions } from 'hooks';
import {
  useGetCuratedQueryDetail,
  useGetMembers,
  useGetAllMemberRoles,
} from './hooks';

import './style.less';
import { handleCreateSearchContainer } from 'pages/reporting/utils';

const PERMISSION_EDIT_CURATED_QUERY = [
  {
    action: ABILITY_ACTION.MANAGE,
    subject: ABILITY_SUBJECT.CURATED_QUERY,
  },
];

const ModalFormEdit = (props) => {
  const [form] = Form.useForm();
  const {
    editedData,
    visible,
    editConfig,
    setVisible,
    setIsMounted,
    statusUpdate,
  } = props;

  const hasPermissionEditCuratedQuery = useCheckPermissions(
    PERMISSION_EDIT_CURATED_QUERY
  );

  const [toggleCuratedQuery, setCuratedQuery] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [shareType, setShareType] = useState('MemberRole');

  const { memberRoles } = useGetAllMemberRoles();

  const { gridName, isDefault, name, description } = editedData;
  const { chosenColumns } = useSelector(
    selectorsGridView.makeSelectGridConfig()
  );
  const searchCategory = useSelector(
    selectorsGridView.makeSelectSearchCategoriesList()
  );

  const { curatedQueryData, refetchGetCuratedQueryDetail } =
    useGetCuratedQueryDetail({
      curatedQueryId: editedData?.id,
      enabled: hasPermissionEditCuratedQuery && Boolean(editedData?.id),
    });

  const {
    effectedDate,
    expirationDate,
    isMostPopularQuery = false,
    isCuratedQuery,
    curatedQueryShareToMemberType,
    curatedQueryShareType,
    curatedQueryShareToTotalsMembers,
    curatedQueryShareToMembers,
  } = curatedQueryData;

  const filteredMembers = useGetMembers({
    valuesFilter: curatedQueryShareToMembers,
    enabled:
      hasPermissionEditCuratedQuery &&
      curatedQueryShareToTotalsMembers > 0 &&
      curatedQueryShareType === 'SpecificMembers',
  });

  useEffect(() => {
    if (visible)
      form.setFieldsValue({
        name,
        description,
        isMostPopularQuery,
      });
    else form.resetFields();
  }, [form, name, description, isMostPopularQuery, visible]);

  useEffect(() => {
    if (statusUpdate === 'success') {
      refetchGetCuratedQueryDetail();
    }
  }, [refetchGetCuratedQueryDetail, statusUpdate]);

  useEffect(() => {
    if (hasPermissionEditCuratedQuery) {
      let curatedQueryShareToMemberRoleId;
      if (curatedQueryShareToMemberType) {
        curatedQueryShareToMemberRoleId =
          memberRoles.find(
            (role) => role?.memberRoleName === curatedQueryShareToMemberType
          )?.memberRoleId ?? null;
      }

      //TODO: DATETIME
      form.setFieldsValue({
        dateActive: [
          effectedDate ? moment(effectedDate) : null,
          expirationDate ? moment(expirationDate) : null,
        ],
        isCuratedQuery,
        curatedQueryShareToMemberType,
        curatedQueryShareToMemberRoleId,
      });
    }
  }, [
    hasPermissionEditCuratedQuery,
    form,
    effectedDate,
    expirationDate,
    isCuratedQuery,
    curatedQueryShareToMemberType,
    memberRoles,
  ]);

  useEffect(() => {
    if (hasPermissionEditCuratedQuery && filteredMembers.length > 0) {
      setSelectedMembers(filteredMembers);
    }
  }, [filteredMembers, hasPermissionEditCuratedQuery]);

  useEffect(() => {
    if (hasPermissionEditCuratedQuery) {
      setCuratedQuery(isCuratedQuery ?? false);
      setShareType(curatedQueryShareType);
    }
  }, [
    hasPermissionEditCuratedQuery,
    form,
    isCuratedQuery,
    curatedQueryShareType,
  ]);

  const handleEditConfig = async () => {
    const formValue = await form.validateFields();
    const { isCuratedQuery, dateActive, ...values } = formValue;

    const queryData = JSON.parse(editedData?.query);
    const queryConditions = queryData?.queryConditions;

    const advancedSearchContainer =
      handleCreateSearchContainer(queryConditions);

    const downloadJsonFormat = {
      filters: [],
      searchCategory,
      advancedSearchContainer,
      search: {
        searchText: queryData?.searchText,
      },
      isFavoriteRoute: false,
      fromDate: null,
      pageSize: 20,
      pageIndex: 1,
    };

    let params = {
      id: editedData?.id,
      name,
      description,
      gridName,
      isCuratedQuery,
      downloadJsonFormat: JSON.stringify(downloadJsonFormat),
      ...values,
    };

    // Add params curated query
    if (hasPermissionEditCuratedQuery && curatedQueryData?.isCuratedQuery) {
      const { curatedQueryShareType, curatedQueryShareToMemberRoleId } = values;

      params = { ...params, ...curatedQueryData?.isCuratedQuery };

      if (curatedQueryShareType === 'MemberRole') {
        if (curatedQueryShareToMemberRoleId) {
          params = {
            ...params,
            curatedQueryShareToMemberRoleId,
            curatedQueryShareToMemberType:
              memberRoles.find(
                (role) => role.memberRoleId === curatedQueryShareToMemberRoleId
              )?.memberRoleName ?? null,
          };
        }
        params = {
          ...params,
          curatedQueryShareType,
          curatedQueryShareToMembers: [],
        };
      }

      if (curatedQueryShareType === 'SpecificMembers') {
        params = {
          ...params,
          curatedQueryShareType,
          curatedQueryShareToMembers: selectedMembers.map(
            (member) => member.id
          ),
        };
      }

      if (curatedQueryShareType === 'AllMember') {
        params = {
          ...params,
          curatedQueryShareType,
          curatedQueryShareToMemberType: null,
          curatedQueryShareToMembers: [],
        };
      }

      if (dateActive) {
        params = {
          ...params,
          effectedDate: dateActive[0] ? dateActive[0].toISOString() : null,
          expirationDate: dateActive[1] ? dateActive[1].toISOString() : null,
        };
      } else {
        params = {
          ...params,
          effectedDate: null,
          expirationDate: null,
        };
      }
    }

    params = Object.assign(params, { query: editedData?.query });

    editConfig(params);
  };

  return (
    <>
      {visible && (
        <Modal
          title='Update Query'
          width={1100}
          visible={visible}
          onCancel={() => setVisible(false)}
          destroyOnClose
          okText='Submit'
          onOk={handleEditConfig}
        >
          <MutationGridConfig
            form={form}
            checked={isDefault}
            renderDefault={false}
            setIsMounted={setIsMounted}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            toggleCuratedQuery={toggleCuratedQuery}
            setCuratedQuery={setCuratedQuery}
            shareType={shareType}
            setShareType={setShareType}
            gridName={gridName}
            memberRoles={memberRoles}
          />
        </Modal>
      )}
    </>
  );
};

export default ModalFormEdit;
