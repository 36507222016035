import React, { useMemo } from 'react';

import { CustomNotification, StyledModal } from 'common/components';

import { Form, Input } from 'antd';

import { useGetIngredients } from '../qa-ingredient/hook';
import { useGetSpecAllergens } from '../qa-allergen/hooks';
import { useQaFactsPanelsGroup, useSaveQaGroupProduct } from './hooks';

import { GroupLayoutProvider } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/GroupLayoutProvider';
import {
  AllergensGroup,
  FactsPanelGroup,
  GroupName,
  IngredientsGroup,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/components';

import { separateSupplementAndNutritionIds } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/utils';
import { mappingQaAllergenList } from './utils';

const defaultModalProps = {
  destroyOnClose: true,
  width: 1140,
  maskClosable: false,
  bodyStyle: {
    minHeight: '60vh',
  },
};

export const QaGroupAddition = (props) => {
  const {
    onCancel: offModal,
    productId: productIdProp,
    onAddSuccess,
    ...rest
  } = props;

  const [formInstance] = Form.useForm();

  const { factsPanelsGroup } = useQaFactsPanelsGroup(productIdProp); 

  const { data: qaIngredient } = useGetIngredients(productIdProp);
  const { ingredientStatements: ingredientList = [] } = qaIngredient ?? {};

  const { qaAllergenList } = useGetSpecAllergens(productIdProp);
  const { allergensModel = [] } = qaAllergenList ?? {};

  const mappingAllergenList = useMemo(() => {
    return mappingQaAllergenList(allergensModel);
  }, [allergensModel]);

  const qaGroupSaveMutation = useSaveQaGroupProduct(productIdProp);

  const handleSaveGroup = async () => {
    const {
      groupName,
      ingredientGroupIndices,
      allergenGroupIndices,
      factsPanelsGroupIndices,
    } = await formInstance.validateFields();

    const { supplementFactIds, nutritionFactIds, drugFactIds, petNutritionFactIds } =
      separateSupplementAndNutritionIds(
        factsPanelsGroup,
        factsPanelsGroupIndices
      );

    const params = {
      productItemId: productIdProp ?? null,
      groupId: null,
      groupName,
      ingredientGroupIndices: ingredientGroupIndices ?? null,
      allergenGroupIndices: allergenGroupIndices ?? null,
      nutritionFactIds,
      supplementFactIds,
      drugFactIds,
      petNutritionFactIds,
    };

    qaGroupSaveMutation.mutate(params, {
      onSuccess: (result) => {
        result?.isSuccess &&
          CustomNotification.success('Add group data successfully');
        formInstance.resetFields();
        offModal();
        onAddSuccess && onAddSuccess();
      },
    });
  };

  return (
    <StyledModal
      {...defaultModalProps}
      title='Add Group'
      onCancel={offModal}
      onOk={handleSaveGroup}
      okButtonProps={{
        loading: qaGroupSaveMutation.isLoading,
      }}
      wrapClassName='group-layout__addition'
      {...rest}
    >
      <GroupLayoutProvider>
        <Form form={formInstance}>
          <GroupName>
            <Input placeholder='Please input group name' />
          </GroupName>
          <IngredientsGroup
            ingredientList={ingredientList}
            productId={productIdProp}
          />
          <AllergensGroup
            allergenList={mappingAllergenList}
            productId={productIdProp}
          />
          <FactsPanelGroup
            factsPanelsGroup={factsPanelsGroup}
            productId={productIdProp}
          />
        </Form>
      </GroupLayoutProvider>
    </StyledModal>
  );
};
