import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FolderSelector } from 'common/components';

import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as actionGlobal from 'redux/global/actions';
import AddToFolderActions from './AddToFolderActions';

import { useAddToFolder } from 'hooks/useAddToFolder';

import './AddToFolder.less';

const AddToFolder = () => {
  const dispatch = useDispatch();

  const selectedGridItems = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const {
    folderSelectorRef,
    currentFolderId,
    selectedFolder,
    onSelectFolder,
    onBreadcrumbChange,
    onExpandFolder,
    handleSubmit,
    handleAddToFolder,
  } = useAddToFolder({ close: () => onCloseAddToFolder() });

  const onCloseAddToFolder = () => {
    dispatch(actionGlobal.toggleAddToFolder());
  };

  const handleSuccessCallback = (folder) => {
    handleAddToFolder(selectedGridItems, folder);
  };

  const disableSubmitButton = !selectedFolder || selectedFolder?.id === 'home';
  const selectedFolderId =
    selectedFolder?.id !== 'home' ? selectedFolder?.id : null;

  return (
    <div className='add-to-folder'>
      <div className='add-to-folder_title'>
        <h4>Add to folder</h4>
      </div>

      <div className='add-to-folder_list'>
        <FolderSelector
          ref={folderSelectorRef}
          onDrop={handleSubmit}
          onSelectFolder={onSelectFolder}
          onBreadcrumbChange={onBreadcrumbChange}
          onExpandFolder={onExpandFolder}
        />
      </div>

      <div className='add-to-folder_footer'>
        <AddToFolderActions
          parentId={selectedFolderId || currentFolderId}
          currentFolderId={currentFolderId}
          onCancel={onCloseAddToFolder}
          successCallback={handleSuccessCallback}
          disableSubmitButton={disableSubmitButton}
          onSubmit={() => handleSubmit(selectedGridItems, selectedFolder)}
        />
      </div>
    </div>
  );
};

export default AddToFolder;
