import React, { useRef } from 'react';
import Image from 'common/components/image/Image';
import { FavoriteStarIconRender } from 'common/components';
import { get } from 'lodash';
import { Row, Col, Typography, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { AssetPanelDownload } from '../index';
import { forwardTo } from 'utils/common/route';
import iframe from 'assets/iframe.png';
import { Images } from 'config/assets';
import useDoubleClick from 'hooks/useDoubleClick';

const { Title } = Typography;

const AssetPanelThumbnailInfo = (props) => {
  const { assetData } = props;
  const thumbUrl = get(assetData, 'thumbnail', '');
  const fileName = get(assetData, 'assetName', '');
  const isFavorited = get(assetData, 'isFavorited', false);
  const divRef = useRef();

  useDoubleClick({
    onDoubleClick: (e) => {
      forwardTo(`/asset/${assetData?.id}`);
    },
    ref: divRef,
    latency: 250,
  });

  return (
    <div className='asset-panel-thumbnail'>
      <Row align='top' justify='space-between'>
        <Col span={12}>
          <Tooltip
            title='View Detail'
            placement='right'
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <EyeOutlined
              className='asset-panel-thumbnail__button'
              onClick={() => {
                forwardTo(`/asset/${assetData?.id}`);
              }}
            />
          </Tooltip>
        </Col>
        <Col>
          {!assetData?.isIFrame && <AssetPanelDownload assetData={assetData} />}
        </Col>
      </Row>
      <Col span={24} className='asset-panel-thumbnail__thumb'>
        <div className='asset-panel-thumbnail__thumb-logo' ref={divRef}>
          <Image
            src={
              thumbUrl
                ? thumbUrl
                : assetData?.isIFrame
                ? iframe
                : Images.RIVIR_LOGO_DEFAULT
            }
          />
        </div>
        <Title className='asset-panel-thumbnail__thumb-file-name'>
          <FavoriteStarIconRender
            isFavorited={isFavorited}
            style={{ marginRight: 8 }}
          />
          {fileName}
        </Title>
      </Col>
    </div>
  );
};

export default AssetPanelThumbnailInfo;
