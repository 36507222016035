import React from 'react';
import PropTypes from 'prop-types';
import { Col, Typography } from 'antd';
import MdToolTextSampleSyntax from './MdToolTextSampleSyntax';
import {
  mdTextTypeList,
  PhrasingSamples,
  ParagraphSamples,
} from 'static/Constants';

import './MdToolTextGuideAndResult.less';

const { Title } = Typography;

const MdToolTextGuideAndResult = (props) => {
  const { textType, sideView, radioOption, result } = props;

  if (sideView === radioOption[1].value)
    return (
      <Col className='scroller' xs={14} style={{ height: 162 }}>
        {textType === mdTextTypeList[0].value && (
          <React.Fragment>
            <Title level={5}>
              {textType === mdTextTypeList[0].value && 'Paragraph Sample'}
            </Title>
            {ParagraphSamples.map((sampleItem) => (
              <MdToolTextSampleSyntax sampleItem={sampleItem} />
            ))}
          </React.Fragment>
        )}
        {textType === mdTextTypeList[1].value && (
          <React.Fragment>
            <Title level={5}>
              {textType === mdTextTypeList[1].value &&
                'Phrasing Content Sample'}
            </Title>
            {PhrasingSamples.map((sampleItem) => (
              <MdToolTextSampleSyntax sampleItem={sampleItem} />
            ))}
          </React.Fragment>
        )}
      </Col>
    );
  if (sideView === radioOption[0].value)
    return (
      <Col className='md-tool-text-guide-and-result scroller' xs={14}>
        {result?.error || result}
      </Col>
    );
};

MdToolTextGuideAndResult.propTypes = {
  textType: PropTypes.string,
  sideView: PropTypes.string,
  radioOption: PropTypes.object,
  result: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default MdToolTextGuideAndResult;
