import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Typography, Tooltip, Pagination, notification, Col } from 'antd';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import moment from 'moment';
import { dialogFunction } from 'common/components/index';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import Messages from 'i18n/messages/folder';
import * as folderActions from '../../controllers/actions';
import * as folderService from 'services/folder';
import DigitalRightIcon from 'common/components/digital-right-icon/DigitalRightIcon';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import {
  DeleteOutlined,
  CodeSandboxOutlined,
  PlaySquareOutlined,
  UserOutlined,
  QuestionOutlined,
  SearchOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-040843 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Global Vertical Innovations, LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 11 May 2024 )____[v2]_MTcxNTM4MjAwMDAwMA==d035e6c32ae72202e23beccd712ab105'
);

const { Title } = Typography;

function iconRenderer(params) {
  if (params.data.type.toLowerCase() === 'product')
    return (
      <>
        <Tooltip title='Click for preview'>
          <CodeSandboxOutlined />
        </Tooltip>
        {params.data.drm && (
          <DigitalRightIcon
            size='small'
            className='folder-panel-content__drm-grid'
          />
        )}
      </>
    );
  else if (params.data.type === 'asset')
    return (
      <>
        <Tooltip title='Click for preview'>
          <PlaySquareOutlined />
        </Tooltip>
        {params.data.drm && (
          <DigitalRightIcon
            size='small'
            className='folder-panel-content__drm-grid'
          />
        )}
      </>
    );
  else if (params.data.type === 'member')
    return (
      <>
        <Tooltip title='Click for preview'>
          <UserOutlined />
        </Tooltip>
        {params.data.drm && (
          <DigitalRightIcon
            size='small'
            className='folder-panel-content__drm-grid'
          />
        )}
      </>
    );
  else if (params.data.type === 'Query')
    return (
      <>
        <Tooltip title='Click for preview'>
          <SearchOutlined />
        </Tooltip>
        {params.data.drm && (
          <DigitalRightIcon
            size='small'
            className='folder-panel-content__drm-grid'
          />
        )}
      </>
    );
  else if (params.data.type === 'Reporting')
    return (
      <>
        <Tooltip title='Click for preview'>
          <SnippetsOutlined />
        </Tooltip>
        {params.data.drm && (
          <DigitalRightIcon
            size='small'
            className='folder-panel-content__drm-grid'
          />
        )}
      </>
    );
  else return <QuestionOutlined />;
}

// function digitalRightIconRender(params) {
//   if (params.data.drm)
//     return (
//       <DigitalRightIcon
//         size='small'
//         className='folder-panel-content__drm-grid'
//       />
//     );
//   else return null;
// }

function dateFormatter(params) {
  return moment(params.value).format('MM/DD/YYYY');
}

function convertNodeData(nodes) {
  const result = nodes.map((node) => node.data);
  return result;
}

function coverNewData(rowsSelection, gridRowsSelection, itemSelection) {
  let result = rowsSelection;

  const idxRows = result.findIndex((row) => row.id === itemSelection.id);
  const idxGridRows = gridRowsSelection.findIndex(
    (row) => row.id === itemSelection.id
  );

  if (idxRows !== -1 && idxGridRows === -1) {
    result = result.filter((row) => row.id !== itemSelection.id);
    return result;
  }

  // Set value: { {}, {} } -> Array.from -> [{}, {}]
  result = Array.from(new Set([...rowsSelection, ...gridRowsSelection]));
  return result;
}

const PanelContent = (props) => {
  const dispatch = useDispatch();
  const reloadPage = useDispatchReloadPage();

  const { ownerFolder, shortDetail, seePreview, isEdit, intl } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const [gridApi, setGridApi] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    if (!gridApi) return;

    gridApi.forEachNode((node) => {
      const idxRow = selectedRows.findIndex(
        (selectedRow) => selectedRow.id === node.data.id
      );
      if (idxRow !== -1) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
    setSelectedItem({});
  }, [currentPage, gridApi, selectedRows]);

  const getAgGridProps = (rowData = []) => {
    return {
      headerHeight: 32,
      rowHeight: 32,
      rowSelection: 'multiple',
      // rowMultiSelectWithClick: true,
      rowData: rowData.filter((item, index) => {
        return (
          index >= (currentPage - 1) * currentPageSize &&
          index <= currentPageSize * currentPage - 1
        );
      }),
      pivotPanelShow: 'never',
      onRowClicked: (params) => {
        setSelectedItem(params.data);
        // setSelectedRows([params.data]);
      },
      onRowDoubleClicked: (params) => {
        if (seePreview) seePreview(params.data);
      },
      onSelectionChanged: () => {
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedDataNodes = convertNodeData(selectedNodes);
        // const result = coverNewData(
        //   selectedRows,
        //   selectedDataNodes,
        //   selectedItem
        // );
        setSelectedRows(selectedDataNodes);
      },
      onCellClicked: (params) => {
        if (params.colDef.field === '' && seePreview) seePreview(params.data);
      },
      onGridReady: onGridReady,
    };
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const gridColumn = [
    {
      key: '1',
      field: '',
      width: 70,
      cellRendererFramework: iconRenderer,
      menuTabs: [],
    },
    // {
    //   key: '2',
    //   field: 'drm',
    //   cellRendererFramework: digitalRightIconRender,
    //   width: 30,
    //   menuTabs: [],
    // },
    {
      key: '2',
      field: 'description',
      width: 300,
      menuTabs: [],
    },
    {
      key: '3',
      field: 'dateAdded',
      cellRendererFramework: dateFormatter,
      width: 130,
      menuTabs: [],
    },
  ];
  const rowData =
    shortDetail && shortDetail.folderContents ? shortDetail.folderContents : [];

  const confirmDelete = () => {
    if (selectedRows.length > 0) {
      dialogFunction({
        type: 'warn',
        content: intl.formatMessage(Messages.areYouSureToDelete),
        onOk: callApiDelete,
      });
    } else {
      notification.warn({
        message: 'Must select at least one items to delete.',
      });
    }
  };

  const callApiDelete = async () => {
    const itemList = selectedRows.map((item) => {
      return { id: item.id, type: item.type };
    });

    try {
      const response =
        await folderService.deleteEntityAssocicationFromShortContent({
          folderId: shortDetail?.id,
          itemList,
        });
      const { isSuccess, message } = response;
      if (isSuccess) {
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSelectedRows([]);

        showSuccessMessageDialog();
        reloadPage();
      } else {
        callApiErrorHandler(message);
      }
    } catch (error) {
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <div className='folder-panel-content'>
      <Row justify='space-between'>
        <Title
          className='folder-panel-content__folder-name'
          style={{ fontSize: '16px' }}
        >
          {intl.formatMessage({
            id: 'Taco.folder.components.contentPane.content',
          })}
        </Title>
        {isEdit && (
          <div className='folder-panel-content__content-button'>
            <Tooltip
              title={intl.formatMessage({
                id: 'Taco.folder.components.contentPane.delete',
              })}
            >
              <DeleteOutlined
                onClick={confirmDelete}
                className={'folder-panel-content__button'}
              />
            </Tooltip>
          </div>
        )}
      </Row>

      <div
        id='folder-panel-content__grid'
        style={{ height: 270, width: '100%', marginTop: 10 }}
        className='ag-theme-alpine'
      >
        <AgGridReact {...getAgGridProps(rowData)}>
          {gridColumn?.map((column) => (
            <AgGridColumn {...column} />
          ))}
        </AgGridReact>
      </div>
      <div className='folder-panel-content__pagination'>
        <Pagination
          showSizeChanger
          onChange={(page, size) => {
            setCurrentPage(page);
            setCurrentPageSize(size);
          }}
          pageSize={currentPageSize}
          defaultCurrent={currentPage}
          total={rowData && rowData.length}
        />
      </div>
      {rowData.filter((item) => item.drm).length > 0 && (
        <Row style={{ marginTop: 20 }}>
          <Col span={2}>
            <DigitalRightIcon width={35} height={35} />
          </Col>
          <Col span={22} style={{ fontWeight: 500 }}>
            {intl.formatMessage({
              id: 'Taco.common.components.folderDRM',
            })}
          </Col>
        </Row>
      )}
      <button
        style={{ display: 'none' }}
        onClick={() => {
          setSelectedRows([]);
          setCurrentPageSize(10);
          setCurrentPage(1);
        }}
        id='button-clear-table-item'
      >
        test
      </button>
    </div>
  );
};

export default injectIntl(PanelContent);
