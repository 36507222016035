import React from 'react';

import { Col, Row } from 'antd';

import BasicFormItem from './BasicFormItem';
import isArray from 'lodash/isArray';

import SnapshotFormItem from 'pages/qa-spec/components/qa-spec-tab/SnapshotFormItem';

const BasicFormItemList = (props) => {
  const {
    formDefine = [],
    allowEdit = true,
    style = {},
    labelFn,
    isLoadingSnapshot,
  } = props;

  return (
    <div style={style}>
      {formDefine?.map((formConfigItem, idx) => {
        const { label } = formConfigItem;

        let finalLabel = labelFn ? labelFn(label) : label;

        if (isArray(formConfigItem)) {
          return (
            <Row {...(formConfigItem?.[0]?.rowConfig || {})}>
              {formConfigItem.map((childFormItem, idx) => {
                if (childFormItem?.rowConfig) return null;

                if (isArray(childFormItem)) {
                  const [firstChild, ...restChildren] = childFormItem;

                  return (
                    <Col {...(firstChild?.colProps || {})}>
                      <BasicFormItemList
                        formDefine={
                          firstChild?.colProps ? restChildren : childFormItem
                        }
                        allowEdit={allowEdit}
                        style={style}
                        labelFn={labelFn}
                      />
                    </Col>
                  );
                }

                const { label } = childFormItem;

                let finalLabel = labelFn ? labelFn(label) : label;

                return (
                  <Col {...(childFormItem?.colProps || {})}>
                    <SnapshotFormItem
                      supplierModifiedData={childFormItem?.supplierModifiedData}
                      snapshotData={childFormItem?.snapshotData}
                      isLoadingSnapshot={isLoadingSnapshot}
                      renderPopoverContent={childFormItem?.renderPopoverContent}
                    >
                      <BasicFormItem
                        key={idx}
                        allowEdit={allowEdit}
                        {...childFormItem}
                        label={finalLabel}
                      />
                    </SnapshotFormItem>
                  </Col>
                );
              })}
            </Row>
          );
        }
        return (
          <SnapshotFormItem
            supplierModifiedData={formConfigItem?.supplierModifiedData}
            snapshotData={formConfigItem?.snapshotData}
            isLoadingSnapshot={isLoadingSnapshot}
            renderPopoverContent={formConfigItem?.renderPopoverContent}
          >
            <BasicFormItem
              key={idx}
              allowEdit={allowEdit}
              {...formConfigItem}
              label={finalLabel}
            />
          </SnapshotFormItem>
        );
      })}
    </div>
  );
};

export default BasicFormItemList;
