import * as types from './constants';

export function getMainPageInfo(payload) {
  return {
    type: types.GET_MAIN_PAGE_INFO,
    payload,
  };
}

export function getMainPageInfoSuccess(payload) {
  return {
    type: types.GET_MAIN_PAGE_INFO_SUCCESS,
    payload,
  };
}

export function getMainPageInfoFail() {
  return {
    type: types.GET_MAIN_PAGE_INFO_FAIL,
  };
}

export function getCuratedQueryGrid(payload) {
  return {
    type: types.GET_CURATED_QUERIES_GRID,
    payload,
  };
}
export function getMarkupDetail(payload) {
  return {
    type: types.GET_MARKUP_DETAIL,
    payload,
  };
}

export function getCuratedQueryGridSucess(payload) {
  return {
    type: types.GET_CURATED_QUERIES_GRID_SUCCESS,
    payload,
  };
}
export function getMarkupDetailSuccess(payload) {
  return {
    type: types.GET_MARKUP_DETAIL_SUCCESS,
    payload,
  };
}

export function getMarkupDetailFail(payload) {
  return {
    type: types.GET_MARKUP_DETAIL_FAIL,
    payload,
  };
}

export function clearMainPageInfo() {
  return {
    type: types.CLEAR_MAIN_PAGE_INFO,
  };
}

export function reloadMarkupGrid(payload) {
  return {
    type: types.RELOAD_MARKUP_GRID,
  };
}

export function reloadMarkupGridSucces(status) {
  return {
    type: types.RELOAD_MARKUP_GRID_SUCCESS,
    status,
  };
}
