import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Card,
  Checkbox,
  Avatar,
  Row,
  Typography,
  Button,
  Spin,
  Col,
  Tooltip,
} from 'antd';
import { LoadingOutlined, StarFilled } from '@ant-design/icons';
import { useMedia } from 'react-media';
import classnames from 'classnames';
import _ from 'lodash';

import EditableIcon from 'common/components/thumb/product/EditableIcon';
import SharedIcon from './share/SharedIcon';
import DigitalRightIcon from 'common/components/digital-right-icon/DigitalRightIcon';
import EntityStatusTag from 'common/components/tag/entity-status-tag/EntityStatusTag';
import ProductThumbFooter from './product/ProductThumbFooter';
import ProductPackLevel from './product/ProductPackLevel';
import ProductSubscription from './product/ProductSubscription';
import GdsnShieldIcon from './product/GdsnShieldIcon';
import PrivateLabelIcon from './product/PrivateLabelcon';

import { Images } from 'config/assets';

import userSelectors from 'redux/user/selectors';

import useDoubleClick from 'hooks/useDoubleClick';
import { useImageLoaded } from 'hooks';

import { entityTypeIcon } from 'utils/entityTypeIcon';
import { checkIsOwner } from 'utils/checkIsOwner';

import { GLOBAL_MEDIA_QUERIES } from 'static/MediaQuery';

import { FormattedMessage } from 'react-intl';

import './ThumbnailItem.less';

const { Text } = Typography;

const ThumbnailItem = (props) => {
  const {
    status,
    dataDetail,
    imageThumbnail,
    labelThumbnail,
    subLabelThumbnail,
    selected,
    onClickItemGrid,
    onClickCheckboxItem,
    onDoubleClick,
    hideCheckbox,
    hideStatus,
    hideLabel,
    responsive,
    enableCustom,
    className,
    isShareBtn,
    isEditMode,
    loading,
    errorMessage,
    drmType,
    showTypeItem = false,
    isProductThumbnail = false,
    isMemberThumbnail = false,
    style,
    shareable = false,
    isMarketing = false,
    isSetPrimary = false,
    isLoadingBtnPrimary = false,
    handlePrimaryProduct,
    isEditable = false,
    enabledDoubleClick, // (now, we're handling double click event outside on grid folder view, but folder detail view is handling inside, I will find a solution after)
    enableSingleClick = true,
  } = props;

  const userId = useSelector(userSelectors.makeSelectUserId());

  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const divRef = useRef();

  useDoubleClick({
    onSingleClick: (e) => {
      if (onClickItemGrid) onClickItemGrid(dataDetail, e);
    },
    onDoubleClick: (e) => {
      if (onDoubleClick) onDoubleClick(dataDetail, e);
    },
    ref: divRef,
    latency: 250,
    isEnabled: enabledDoubleClick || enableSingleClick,
  });

  const [ref, loaded, onLoad] = useImageLoaded();

  const [visibleShareInfo, setVisibleShareInfo] = useState(false);

  const handleVisibleChange = () => {
    setVisibleShareInfo(!visibleShareInfo);
  };

  const replaceError = (value) => {
    value.target.src = Images.RIVIR_LOGO_DEFAULT;
  };

  const renderImgAvatar = () => {
    if (isProductThumbnail) {
      return (
        dataDetail?.thumb ||
        dataDetail?.thumb100 ||
        dataDetail?.thumb300 ||
        dataDetail?.thumbnail ||
        dataDetail?.productPrimaryImage ||
        Images.RIVIR_LOGO_DEFAULT
      );
    }

    return imageThumbnail
      ? (imageThumbnail || '').toUrl()
      : dataDetail?.thumbnail
      ? dataDetail?.thumbnail.toUrl()
      : Images.RIVIR_LOGO_DEFAULT;
  };

  const tagStatus = status || dataDetail?.status;

  return (
    <Row
      className={classnames(className, {
        'thumbnail-item': true,
        'thumbnail-item--custom': enableCustom,
        'thumbnail-item--selected': selected,
        'thumbnail-item--responsive': responsive,
        'thumbnail-item--sm': matches.sm,
        'thumbnail-item--md': matches.md,
        'thumbnail-item--lg': matches.lg,
        'thumbnail-item--xl': matches.xl,
        'thumbnail-item--xxl': matches.xxl,
        'thumbnail-item--edit-mode': isEditMode,
        'thumbnail-item-shareable': shareable,
      })}
      style={style}
    >
      {isShareBtn && (
        <Row className='thumbnail-item__header'>
          {!hideCheckbox && (
            <Col>
              <Checkbox
                className='thumbnail-item__checkbox'
                checked={selected}
                onChange={(e) => {
                  if (onClickCheckboxItem) onClickCheckboxItem(dataDetail, e);
                }}
              />
            </Col>
          )}

          <Col flex='auto' />

          <Col
            style={{
              display: 'inline-flex',
              alignItems: 'flex-start',
              gap: '2px',
              paddingTop: '4.5px',
            }}
          >
            {isProductThumbnail && dataDetail?.packageLevel && (
              <ProductPackLevel
                packLevel={dataDetail?.packageLevel}
                id={dataDetail?.id}
              />
            )}
            {showTypeItem && (
              <Tooltip title={_.capitalize(dataDetail?.type)}>
                <span className='thumbnail-item__type'>
                  {entityTypeIcon(dataDetail?.type)}
                </span>
              </Tooltip>
            )}
            {dataDetail?.drm && <DigitalRightIcon width={19} type={drmType} />}
            {isShareBtn && (
              <>
                {checkIsOwner(dataDetail, userId) ? null : (
                  <SharedIcon dataDetail={dataDetail} />
                )}
              </>
            )}

            {(isProductThumbnail || isMemberThumbnail) &&
              dataDetail?.subscription && (
                <ProductSubscription color={dataDetail?.subscriptionShield} />
              )}

            {isProductThumbnail && dataDetail?.gdsnShield && (
              <GdsnShieldIcon gdsnShield={dataDetail?.gdsnShield} />
            )}

            {isEditable && isProductThumbnail && <EditableIcon />}
          </Col>
        </Row>
      )}

      <Row
        className={classnames({
          'thumbnail-item__format thumbnail-item__format--selected': selected,
          'thumbnail-item__format': !selected,
        })}
        style={{ padding: '4px 0px' }}
      >
        {!isShareBtn && !hideCheckbox && (
          <Checkbox
            className='thumbnail-item__checkbox thumbnail-item__checkbox--absolute'
            checked={selected}
            onChange={(e) => {
              if (onClickCheckboxItem) onClickCheckboxItem(dataDetail, e);
            }}
          />
        )}
        {isSetPrimary && (
          <Row className='thumbnail-item__btn-primary'>
            <Button
              type='primary'
              size={isMarketing ? 'middle' : 'large'}
              style={{ borderRadius: '4px', opacity: '1 !important' }}
              loading={isLoadingBtnPrimary}
              onClick={handlePrimaryProduct}
            >
              Set Primary
            </Button>
            {isMarketing && (
              <Button
                size='middle'
                ghost
                style={{
                  borderRadius: '4px',
                  opacity: '1 !important',
                  marginTop: 8,
                }}
                onClick={onClickItemGrid}
              >
                Preview
              </Button>
            )}
          </Row>
        )}
        {/*
            Placeholder on top for handle the click event
          */}
        <div
          ref={divRef}
          className={classnames({
            'thumbnail-item__placeholder-click-event': true,
            'thumbnail-item__placeholder-loading': !loaded,
          })}
        />

        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <Spin spinning={loading} indicator={<LoadingOutlined />}>
            <EntityStatusTag
              status={hideStatus ? null : tagStatus}
              exceptions={['Active', 'ACTIVE', 'Basic Report']}
            >
              {errorMessage ? (
                <Card bordered={false}>
                  <Text
                    className='thumbnail-item__image-error-message'
                    type='danger'
                  >
                    <FormattedMessage {...errorMessage} />
                  </Text>
                </Card>
              ) : (
                <Avatar
                  className={classnames(className, {
                    'thumbnail-item__image': true,
                  })}
                  shape='square'
                  size={100}
                >
                  <div style={{ backgroundColor: 'white', margin: '0 auto' }}>
                    <img
                      ref={ref}
                      onLoad={onLoad}
                      src={renderImgAvatar()}
                      onError={(value) => replaceError(value)}
                      style={{ width: '100%', height: '100%' }}
                      loading='lazy'
                    />
                  </div>
                </Avatar>
              )}
            </EntityStatusTag>
          </Spin>
        </Card>
      </Row>

      {isProductThumbnail ? (
        <ProductThumbFooter {...dataDetail} />
      ) : (
        !hideLabel && (
          <Row className='thumbnail-item__text'>
            {
              /* render link */
              /$<Link/.test(labelThumbnail) ? (
                { labelThumbnail }
              ) : (
                <Typography.Paragraph
                  ellipsis={{ tooltip: labelThumbnail }}
                  style={styles.paragraph}
                >
                  {labelThumbnail}
                </Typography.Paragraph>
              )
            }
            {subLabelThumbnail && (
              <Typography.Paragraph
                ellipsis={{ tooltip: subLabelThumbnail }}
                style={styles.paragraph}
              >
                {subLabelThumbnail}
              </Typography.Paragraph>
            )}
          </Row>
        )
      )}
      <AbsoluteComponentContainer>
        <div
          className={classnames(className, {
            'thumbnail-item__right-bottom-group-icon': !isProductThumbnail,
            'thumbnail-item__right-bottom-group-icon-product':
              isProductThumbnail,
          })}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: 4,
              gap: 2,
            }}
          >
            {dataDetail?.isPrivateLabel && <PrivateLabelIcon />}
            {dataDetail?.isFavorited && (
              <StarFilled style={{ fontSize: '18px', color: '#fec810' }} />
            )}
          </div>
        </div>
      </AbsoluteComponentContainer>
    </Row>
  );
};

const styles = {
  paragraph: {
    marginBottom: '0px',
    height: '17px',
    lineHeight: '17px',
  },
};

const AbsoluteComponentContainer = (props) => (
  <>{props.children && props.children} </>
);

ThumbnailItem.propTypes = {
  dataDetail: PropTypes.object,
  imageThumbnail: PropTypes.string,
  labelThumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subLabelThumbnail: PropTypes.string,
  selected: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  hideLabel: PropTypes.bool,
  responsive: PropTypes.bool,
  onClickItemGrid: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClickCheckboxItem: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isShareBtn: PropTypes.bool,
  isEditMode: PropTypes.bool,
  loading: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLogo: PropTypes.bool,
  style: PropTypes.object,
};

ThumbnailItem.defaultProps = {
  hideCheckbox: false,
  hideLabel: false,
  responsive: false,
  onClickItemGrid: () => void 0,
  onClickCheckboxItem: () => void 0,
  onDoubleClick: () => void 0,
  isShareBtn: false,
  isEditMode: false,
  loading: false,
  isLogo: false,
  style: {},
};
export default React.memo(ThumbnailItem);
