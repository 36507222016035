import { useEffect, useRef } from 'react';

import * as constant from 'static/Constants';
import * as actionsAsset from 'pages/branded-assets/controllers/actions';
import * as actionsGlobal from 'redux/global/actions';

import { useGetQuery } from 'hooks/useQuery';

const useColumnsGrid = (dispatch, typeView) => {
  useEffect(() => {
    if (typeView === constant.RIBBON_TYPES.DETAILSVIEW)
      dispatch(actionsAsset.getColumnsAssetsForMember('assets-for-member'));
  }, [dispatch, typeView]);
};

const useAssetsForMember = (
  dispatch,
  pageIndex,
  pageSize,
  memberId,
  searchText
) => {
  useEffect(() => {
    const params = {
      pageIndex,
      pageSize,
      memberId,
      search: { searchText },
    };

    dispatch(actionsAsset.getAssetsForMember(params));
  }, [dispatch, memberId, pageIndex, pageSize, searchText]);
};

const useSaveIdMember = (dispatch, memberId) => {
  useEffect(() => {
    dispatch(actionsAsset.saveIdMember(memberId));
  }, [dispatch, memberId]);
};

const useToggleAssetsForMember = (dispatch) => {
  useEffect(() => {
    dispatch(actionsGlobal.toggleAssetsToMember());
  }, [dispatch]);
};

const useGetMemberId = () => {
  const refMemberId = useRef();
  const query = useGetQuery();
  const memberId = query.get('memberId');

  useEffect(() => {
    refMemberId.current = memberId;
  }, [memberId]);

  return { memberId, refMemberId };
};

export {
  useColumnsGrid,
  useAssetsForMember,
  useSaveIdMember,
  useToggleAssetsForMember,
  useGetMemberId,
};
