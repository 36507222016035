import React from 'react';

import { Button } from 'antd';
import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';

import './ExpandTabButton.less';

const ExpandTabButton = (props) => {
  const { onClick, isTabExpanded } = props;

  return (
    <Button
      className='qa-expand-tab-button'
      icon={isTabExpanded ? <ShrinkOutlined /> : <ExpandAltOutlined />}
      onClick={onClick}
    ></Button>
  );
};
export default ExpandTabButton;
