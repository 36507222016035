import { useState, useEffect } from 'react';

import { replaceAllTextInString } from 'utils';
import { cloneDeep, set, get } from 'lodash';
import { useAsync } from 'hooks';
import { visitorGetMemberDetail } from 'services/members';

import { sleep } from 'utils/delay';

const KEY_LEVELS = ['Segment', 'Families', 'Classes', 'Bricks'];
const KEY_CODE_LEVELS = ['SegmentCode', 'FamilyCode', 'ClassCode', 'BrickCode'];

const useGetFilterBrickModules = ({
  productBrickCodeModulesOrigin,
  setExpandedKeys,
  initSelectTree,
  handleScrollTreeToTop,
}) => {
  const [filteredBrickModules, setFilterBrickModules] = useState(
    productBrickCodeModulesOrigin
  );

  const [loading, setLoading] = useState(false);

  const handleFilterBrickModules = async (searchText) => {
    if (!searchText) {
      setLoading(false);
      setFilterBrickModules(productBrickCodeModulesOrigin);
      setExpandedKeys([]);
      await sleep(1500);
      initSelectTree();
      return;
    }

    setLoading(true);
    await sleep(300);
    let newProductBrickCodeModules = [];
    let expandCodeList = [];

    const setData = (path, item) => {
      set(newProductBrickCodeModules, path, item);
    };

    const matchText = (item, pathArray) => {
      return pathArray.some((pathItem) =>
        item[pathItem]?.toLowerCase()?.includes(searchText?.toLocaleLowerCase())
      );
    };

    const focusSearchText = (idxArray) => {
      const [segmentIdx, familyIdx, classIdx, brickIdx] = idxArray;

      let path;
      let currentDes;
      let currentCode;
      let groupCodePath = [];

      const createCodePath = (idx = 0, prevPath = []) => {
        if (idxArray?.[idx] !== undefined) {
          const nextPath =
            idx === 0
              ? [...prevPath, idxArray?.[idx]]
              : [...prevPath, KEY_LEVELS?.[idx], idxArray?.[idx]];

          const nextCodePath = [...nextPath, KEY_CODE_LEVELS?.[idx]];

          groupCodePath.push(nextCodePath);

          createCodePath(idx + 1, nextPath);
        } else {
          return;
        }
      };

      const replaceText = (desKey, codeKey) => {
        const desPath = [...path, desKey];
        const codePath = [...path, codeKey];

        const itemDes = get(newProductBrickCodeModules, desPath);
        const itemCode = get(newProductBrickCodeModules, codePath);

        currentDes = `${itemCode}-${itemDes}`;

        if (!itemDes) return;

        const replacement = replaceAllTextInString(
          searchText,
          currentDes,
          (originalText) =>
            `<span class="brick-code_search-text" style="background: #000000a6; color: white; padding: 2px; border-radius: 2px">${originalText}</span>`
        )?.result;

        setData(desPath, replacement);
      };

      const expandFoundText = () => {
        if (!currentDes) return;
        const foundSearchText = currentDes
          .toLowerCase()
          .indexOf(searchText.toLowerCase());

        if (foundSearchText !== -1) {
          groupCodePath.forEach((codePath) => {
            currentCode = get(newProductBrickCodeModules, codePath);
            const foundCode = expandCodeList.includes(currentCode);

            if (!foundCode) {
              expandCodeList = [...expandCodeList, currentCode];
            }
          });
        }
      };

      createCodePath();

      if (brickIdx !== undefined) {
        path = [
          segmentIdx,
          'Families',
          familyIdx,
          'Classes',
          classIdx,
          'Bricks',
          brickIdx,
        ];
        replaceText('BrickDescription', 'BrickCode');
        expandFoundText();
        return;
      }

      if (classIdx !== undefined) {
        path = [segmentIdx, 'Families', familyIdx, 'Classes', classIdx];
        replaceText('ClassDescription', 'ClassCode');
        expandFoundText();
        return;
      }

      if (familyIdx !== undefined) {
        path = [segmentIdx, 'Families', familyIdx];
        replaceText('FamilyDescription', 'FamilyCode');
        expandFoundText();
        return;
      }

      if (segmentIdx !== undefined) {
        path = [segmentIdx];
        replaceText('SegmentDescription', 'SegmentCode');
        expandFoundText();
        return;
      }
    };

    const cloneItem = (item) => {
      return cloneDeep(item);
    };

    const handleTreeData = async (idxArray, itemArray) => {
      const [segmentIdx, familyIdx, classIdx, brickIdx] = idxArray;
      const [segmentItem, familyItem, classItem, brickItem] = itemArray;

      const level = itemArray?.length;

      if (level === 1) {
        setData([segmentIdx], cloneItem(segmentItem));
      } else if (level === 2) {
        setData([segmentIdx], {
          ...segmentItem,
          Families:
            cloneItem(newProductBrickCodeModules?.[segmentIdx]?.Families) || [],
        });
        setData([segmentIdx, 'Families', familyIdx], cloneItem(familyItem));
      } else if (level === 3) {
        setData([segmentIdx], {
          ...segmentItem,
          Families:
            cloneItem(newProductBrickCodeModules?.[segmentIdx]?.Families) || [],
        });
        setData([segmentIdx, 'Families', familyIdx], {
          ...familyItem,
          Classes:
            cloneItem(
              newProductBrickCodeModules?.[segmentIdx]?.Families?.[familyIdx]
                ?.Classes
            ) || [],
        });
        setData(
          [segmentIdx, 'Families', familyIdx, 'Classes', classIdx],
          cloneItem(classItem)
        );
      } else if (level === 4) {
        setData([segmentIdx], {
          ...segmentItem,
          Families:
            cloneItem(newProductBrickCodeModules?.[segmentIdx]?.Families) || [],
        });
        setData([segmentIdx, 'Families', familyIdx], {
          ...familyItem,
          Classes:
            cloneItem(
              newProductBrickCodeModules?.[segmentIdx]?.Families?.[familyIdx]
                ?.Classes
            ) || [],
        });

        setData([segmentIdx, 'Families', familyIdx, 'Classes', classIdx], {
          ...classItem,
          Bricks:
            cloneItem(
              newProductBrickCodeModules?.[segmentIdx]?.Families?.[familyIdx]
                ?.Classes?.[classIdx]?.Bricks
            ) || [],
        });

        setData(
          [
            segmentIdx,
            'Families',
            familyIdx,
            'Classes',
            classIdx,
            'Bricks',
            brickIdx,
          ],
          cloneItem(brickItem)
        );
      }
    };

    productBrickCodeModulesOrigin.forEach((segmentItem, segmentIdx) => {
      const searched = matchText(segmentItem, [
        'SegmentDescription',
        'SegmentCode',
      ]);

      if (searched) {
        return handleTreeData([segmentIdx], [segmentItem]);
      }
      segmentItem.Families.forEach((familyItem, familyIdx) => {
        const searched = matchText(familyItem, [
          'FamilyDescription',
          'FamilyCode',
        ]);
        if (searched) {
          return handleTreeData(
            [segmentIdx, familyIdx],
            [segmentItem, familyItem]
          );
        }
        familyItem.Classes.forEach((classItem, classIdx) => {
          const searched = matchText(classItem, [
            'ClassDescription',
            'ClassCode',
          ]);
          if (searched) {
            return handleTreeData(
              [segmentIdx, familyIdx, classIdx],
              [segmentItem, familyItem, classItem]
            );
          }
          classItem.Bricks.forEach((brickItem, brickIdx) => {
            const searched = matchText(brickItem, [
              'BrickDescription',
              'BrickCode',
            ]);
            if (searched) {
              return handleTreeData(
                [segmentIdx, familyIdx, classIdx, brickIdx],
                [segmentItem, familyItem, classItem, brickItem]
              );
            }
          });
        });
      });
    });

    newProductBrickCodeModules.forEach((segmentItem, segmentIdx) => {
      focusSearchText([segmentIdx]);
      segmentItem?.Families.forEach((familyItem, familyIdx) => {
        focusSearchText([segmentIdx, familyIdx]);
        familyItem?.Classes.forEach((classItem, classIdx) => {
          focusSearchText([segmentIdx, familyIdx, classIdx]);
          classItem?.Bricks.forEach((brickItem, brickIdx) => {
            focusSearchText([segmentIdx, familyIdx, classIdx, brickIdx]);
          });
        });
      });
    });

    setExpandedKeys([]);
    await sleep(500);
    setExpandedKeys(expandCodeList);

    setFilterBrickModules(newProductBrickCodeModules);
    setLoading(false);

    handleScrollTreeToTop();
  };

  const handleSearch = (value) => {
    handleFilterBrickModules(value);
  };

  return { filteredBrickModules, handleSearch, searchLoading: loading };
};

export { useGetFilterBrickModules };

export const useGetMemberInfo = (memberId) => {
  const { data, status, run } = useAsync();

  const handleGetMemberInfo = () => {
    run(
      visitorGetMemberDetail({
        MemberId: memberId,
      })
    );
  };
  useEffect(() => {
    if (memberId) handleGetMemberInfo();
  }, [memberId]);

  return {
    memberInfo: data ?? null,
    loading: status === 'idle' || status === 'pending',
  };
};
