import classnames from 'classnames';

import { DrugFactSection, DrugFactHeading } from '.';

import DrugFactText from './DrugFactText';

const DrugFactActiveIngredient = ({ property, snapshotValue }) => {
  const { activeIngredients, dosageUnit } = property;

  if (!activeIngredients?.length && !dosageUnit) return null;

  const isHighlighDosageUnit =
    snapshotValue &&
    JSON.stringify(dosageUnit) !== JSON.stringify(snapshotValue?.dosageUnit);

  const splitFirstLetter = (text) => {
    if (typeof text !== 'string') return { first: '', last: '' };

    const first = text.slice(0, 1);
    const last = text.slice(1, text.length);

    return { first, last };
  };

  const splitFirstLine = (text, purpose) => {
    if (typeof text !== 'string') return { first: '', last: '' };

    if (!purpose) return { first: text, last: '' };

    const maxLetter = 18;

    const firstLineLetter = text.split(' ').reduce(
      (accumulator, currentWord, index) => {
        //* stop reduce if reach max
        if (accumulator.stop) return accumulator;

        const currentCount = accumulator.count + currentWord.length;

        if (currentCount < maxLetter)
          return { count: currentCount + 1, stop: false }; //* 1 is white space;

        if (index > 0) return { ...accumulator, stop: true };

        //* reach max at the first word
        return { count: maxLetter, stop: true };
      },
      { count: 0, stop: false }
    );

    const first = text.slice(0, firstLineLetter.count);
    const last = text.slice(firstLineLetter.count, text.length);

    return { first, last };
  };

  const headingText =
    activeIngredients.length > 1 ? 'Active ingredients' : 'Active ingredient';

  return (
    <DrugFactSection className='drug-fact__active-ingredient'>
      <div className='drug-fact__active-ingredient-heading'>
        <DrugFactHeading
          heading={headingText}
          headingDescription={dosageUnit}
          styles={{ backgroundColor: isHighlighDosageUnit && '#e6f7ff' }}
        />
        <DrugFactHeading
          heading='Purpose'
          className='drug-fact__active-ingredient-purpose'
        />
      </div>

      {activeIngredients?.map((item, idx) => {
        const name = splitFirstLine(
          `${item?.ingredientName} ${item?.quantity ? item?.quantity : ''}${
            item?.quantityUom ? item?.quantityUom : ''
          }`,
          item.purposeDescriptor
        );

        return (
          <div
            className='drug-fact__active-ingredient-item'
            key={idx}
            style={{
              backgroundColor: item?.hightLightText ? item?.hightLightText : '',
            }}
          >
            <DrugFactText
              italic={item?.italicName}
              className={classnames({
                'chapter drug-fact__active-ingredient-info':
                  !!item?.purposeDescriptor,
              })}
            >
              {name.first}
              {item?.purposeDescriptor ? (
                <span className='drug-fact__active-ingredient-name-line'></span>
              ) : null}
              {name.last ? <br /> : null}
              {name.last}
            </DrugFactText>
            <DrugFactText
              italic={item?.italicPurposeDescriptor}
              className='drug-fact__active-ingredient-desc-purpose'
            >
              <span className='drug-fact__active-ingredient-desc-purpose--first-letter'>
                {splitFirstLetter(item?.purposeDescriptor).first}
              </span>
              {splitFirstLetter(item?.purposeDescriptor).last}
            </DrugFactText>
          </div>
        );
      })}
    </DrugFactSection>
  );
};

export default DrugFactActiveIngredient;
