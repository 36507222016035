// import Tiff from 'tiff.js';
// import { Images } from 'config/assets';

export const getUploadImageUrl = (imageFile, onloadHandler) => {
  const fileReader = new FileReader();
  fileReader.onloadstart = () => {
    // show loading
  };
  fileReader.onloadend = (e) => {
    const imageSrc = e.target.result;
    onloadHandler({ imageSrc, imageFile });
  };
  fileReader.readAsDataURL(imageFile);
};

export const getBase64 = async (img) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const cropImageWithConfig = (imageObject, cropData) => {
  if (imageObject && imageObject.src) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = cropData.cropWidth;
    canvas.height = cropData.cropHeight;

    ctx.drawImage(
      imageObject,
      cropData.offsetX,
      cropData.offsetY,
      cropData.cropWidth,
      cropData.cropHeight,
      0,
      0,
      cropData.cropWidth,
      cropData.cropHeight
    );
    return canvas;
  }
  return null;
};

// const readImageAsUrl = (blob, callbackFn) => {
//   const reader = new FileReader();
//   reader.onload = function (e) {
//     // The file's text will be printed here
//     callbackFn(e.target.result);
//   };
//   reader.readAsDataURL(blob);
// };

// const readTiffAsUrl = async (blob, callbackFn) => {
//   try {
//     let buffer = await blob.arrayBuffer();
//     let tiff = new Tiff({ buffer: buffer });
//     callbackFn(tiff.toDataURL());
//   } catch (error) {
//     // cover case tiff file but incorrect tiff format
//     readImageAsUrl(blob, callbackFn);
//   }
// };

/**
 * althrough img tag could display most of image file type, tiff file could not.
 * We need to
 * @param {string} imageUrl
 */
// export const supportTiffPreview = (imageUrl, setActivebase64) => {
//   if (!imageUrl) return false;
//   //const url = imageInfo?.thumb || imageInfo?.fileUrl;
//   var xhr = new XMLHttpRequest();
//   xhr.responseType = 'blob';
//   xhr.open('GET', imageUrl);
//   xhr.setRequestHeader('X-PINGOTHER', 'pingpong');
//   xhr.onload = async function (e) {
//     // get array buffer
//     let blob = xhr.response;

//     if (blob.type === 'image/tiff') {
//       readTiffAsUrl(blob, setActivebase64);
//     } else {
//       readImageAsUrl(blob, setActivebase64);
//     }
//   };
//   xhr.send();
// };

// export const supportTiffThumb = (imageUrl, callbackFn) => {
//   if (!imageUrl) return callbackFn(Images.RIVIR_LOGO_DEFAULT);
//   //const url = imageInfo?.thumb || imageInfo?.fileUrl;
//   var xhr = new XMLHttpRequest();
//   xhr.responseType = 'blob';
//   xhr.open('GET', imageUrl);
//   xhr.setRequestHeader('X-PINGOTHER', 'pingpong');
//   xhr.onload = function (e) {
//     // get array buffer
//     let blob = xhr.response;

//     if (blob.type === 'image/tiff') {
//       readTiffAsUrl(blob, callbackFn);
//     } else {
//       readImageAsUrl(blob, callbackFn);
//     }
//   };
//   xhr.send();
// };

export const getImageSizeFromBlob = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      const img = new Image();

      img.onload = function () {
        const imageSize = {
          width: img.width,
          height: img.height,
        };

        resolve(imageSize);
      };

      img.onerror = function () {
        reject(new Error('Failed to load the image.'));
      };

      img.src = reader.result;
    };

    reader.onerror = function () {
      reject(new Error('Failed to read the blob.'));
    };

    reader.readAsDataURL(blob);
  });
};

export const parseBase64ToBlob = (base64String) => {
  const parts = base64String.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const rawBase64 = parts[1];
  const decodedData = atob(rawBase64); // Convert base64 to binary

  const blob = new Blob(
    [
      new Uint8Array(decodedData.length).map((_, i) =>
        decodedData.charCodeAt(i)
      ),
    ],
    {
      type: contentType,
    }
  );

  return blob;
};

export const parseBase64ToFile = (base64String, fileName) => {
  const blob = parseBase64ToBlob(base64String);
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

const isBase64DataURL = (url) => {
  return /^data:image\/[a-z]+;base64,/.test(url);
};

export const checkImageSrcForBase64 = (callback) => {
  const images = document.querySelectorAll('img');
  images.forEach((img) => {
    const src = img.getAttribute('src');
    if (src && isBase64DataURL(src)) {
      callback(img);
    }
  });
};
