import avi from 'assets/file-formats/avi.png';
import bmp from 'assets/file-formats/bmp.png';
import doc from 'assets/file-formats/doc.png';
import docx from 'assets/file-formats/docx.png';
import email from 'assets/file-formats/email.png';
import gif from 'assets/file-formats/gif.png';
import ico from 'assets/file-formats/ico.png';
import jpeg from 'assets/file-formats/jpeg.png';
import mov from 'assets/file-formats/mov.png';
import mp3 from 'assets/file-formats/mp3.png';
import mp4 from 'assets/file-formats/mp4.png';
import pdf from 'assets/file-formats/pdf.png';
import png from 'assets/file-formats/png.png';
import ppt from 'assets/file-formats/ppt.png';
import pptx from 'assets/file-formats/pptx.png';
import psd from 'assets/file-formats/psd.png';
import rar from 'assets/file-formats/rar.png';
import tga from 'assets/file-formats/tga.png';
import tiff from 'assets/file-formats/tiff.png';
import txt from 'assets/file-formats/txt.png';
import xls from 'assets/file-formats/xls.png';
import xlsx from 'assets/file-formats/xlsx.png';
import zip from 'assets/file-formats/zip.png';
import msg from 'assets/file-formats/msg.png';
import tar from 'assets/file-formats/tar.png';
import iframe from 'assets/iframe.png';
import fileIcon from 'assets/file-formats/file-icon.webp';
import csv from 'assets/file-formats/csv.png';
import unknown from 'assets/file-formats/unknown.jpg';

// Array of types digital asset map with images
export const DigitalAssetTypeImage = [
  { fileType: 'avi', fileImg: avi },
  { fileType: 'bmp', fileImg: bmp },
  { fileType: 'doc', fileImg: doc },
  { fileType: 'docx', fileImg: docx },
  { fileType: 'email', fileImg: email },
  { fileType: 'gif', fileImg: gif },
  { fileType: 'ico', fileImg: ico },
  { fileType: 'jpg', fileImg: jpeg },
  { fileType: 'jpeg', fileImg: jpeg },
  { fileType: 'mov', fileImg: mov },
  { fileType: 'mp3', fileImg: mp3 },
  { fileType: 'mp4', fileImg: mp4 },
  { fileType: 'pdf', fileImg: pdf },
  { fileType: 'png', fileImg: png },
  { fileType: 'ppt', fileImg: ppt },
  { fileType: 'pptx', fileImg: pptx },
  { fileType: 'psd', fileImg: psd },
  { fileType: 'rar', fileImg: rar },
  { fileType: 'tga', fileImg: tga },
  { fileType: 'tiff', fileImg: tiff },
  { fileType: 'tif', fileImg: tiff },
  { fileType: 'txt', fileImg: txt },
  { fileType: 'xls', fileImg: xls },
  { fileType: 'xlsx', fileImg: xlsx },
  { fileType: 'csv', fileImg: csv },
  { fileType: 'zip', fileImg: zip },
  { fileType: 'tar', fileImg: tar },
  { fileType: 'iframe', fileImg: iframe },
  { fileType: 'msg', fileImg: msg },
  { fileType: 'ftpUnknown', fileImg: fileIcon },
  { fileType: 'unknown', fileImg: unknown }, // let unknown is the last of array of DigitalAssetTypeImage
];

export const VIDEO_TYPES_PREVIEW = ['mp4', 'mov'];
export const TYPES_CONVERT_PDF_PREVIEW = ['ppt', 'pptx', 'doc', 'docx','xlsx','xls','csv'];
export const IMAGE_TYPES_PREVIEW = [
  'gif',
  'jpg',
  'jpeg',
  'png',
  'tiff',
  'tif',
  'psd',
  'tga',
];
export const UNPREVIEWABLE_FILE_TYPES = [];
export const ASSET_TYPES_FOR_PREVIEW = [
  'docx',
  'doc',
  'jpg',
  'png',
  'jpeg',
  'gif',
  'bmp',
  'mp4',
  'webm',
  'mp3',
  'pdf',
  'iframe',
  'xlsx',
  'xls',
  'csv',
];
