import React, { useState } from 'react';

import { BookOutlined, LoadingOutlined } from '@ant-design/icons';

import { RibbonButton, CustomNotification } from 'common/components';

import { downloadSplashManual } from 'services/maintenanceForm';

const SplashUserManual = () => {
  const [statusDownload, setStatusDownload] = useState('idle');

  const handleDownloadSplashUser = async () => {
    try {
      setStatusDownload('loading');
      const response = await downloadSplashManual();
      if (response?.isSuccess) {
        setStatusDownload('success');
        CustomNotification.success('Download SPLASH User Manual successfully!');
      } else {
        setStatusDownload('error');
        CustomNotification.error(response?.message ?? 'Something went wrong!');
      }
    } catch (error) {
      setStatusDownload('error');
      CustomNotification.error('Something went wrong!');
    }
  };

  const isDownloading = statusDownload === 'loading';

  return (
    <RibbonButton
      icon={isDownloading ? <LoadingOutlined /> : <BookOutlined />}
      label='SPLASH User Manual'
      onClick={handleDownloadSplashUser}
    />
  );
};

export default SplashUserManual;
