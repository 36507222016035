import { CHECKABLE_DISABLED_FIELDS } from './constants';

import { v4 as uuidv4 } from 'uuid';

const checkHasAllergenValueChanged = (a, b) => {
  return (
    a.isPresent !== b.isPresent ||
    a.levelOfContainment !== b.levelOfContainment ||
    a.containmentSource !== b.containmentSource
  );
};

const checkDifference = ({ value1, value2, compareFunction }) => {
  for (const [index, val] of value1.entries()) {
    if (compareFunction(val, value2[index])) return true;
  }
  return false;
};

export const validateEditableGrid = ({ oldGrid, newGrid }) => {
  return checkDifference({
    value1: oldGrid,
    value2: newGrid,
    compareFunction: checkHasAllergenValueChanged,
  });
};

export const checkDisabledFormField = ({
  propertyName = '',
  levelOfCode = '',
}) => {
  if (CHECKABLE_DISABLED_FIELDS.includes(propertyName)) {
    if (
      propertyName?.toLowerCase() === 'containmentsource' &&
      levelOfCode?.toLowerCase() !== 'may contain'
    )
      return true;

    return false;
  }

  return false;
};

export const checkRequiredFormField = (levelOfContainmentCode = '') => {
  return {
    AllergenTypeCode: true,
    LevelOfContainmentCode: true,
    ContainmentSource: levelOfContainmentCode?.toLowerCase() === 'may contain',
  };
};

export const addIdToAllergens = (allergens) => {
  return allergens.map((allergen) => ({ ...allergen, uuid: uuidv4() }));
};
