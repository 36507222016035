export const PRODUCT_LIST_GET_ENDPOINT = '/api/Product';

export const GET_PRODUCT_SHORT_DETAIL = '/api/pim/get-product-short-detail';

export const GET_PRODUCT_HEADER = '/api/pim/get-product-header';

// get product documents
export const GET_PRODUCT_DOCUMENTS_ENDPOINT =
  '/api/pim/get-product-documents-assets';

export const SEARCH_PRODUCT_FOR_ADD_HIERARCHY =
  '/api/pim/search-products-for-hierarchy';

export const GET_PRODUCT_HIERARCHY_DETAIL =
  '/api/pim/get-product-hierarchies-details';

export const SAVE_PRODUCT_HIERARCHY = '/api/pim/save-hierarchy';
export const EDIT_PRODUCT_HIERARCHY = '/api/pim/edit-hierarchy';
export const DELETE_PRODUCT_HIERARCHY = '/api/pim/delete-hierarchy';

export const GET_PRODUCT_HIERARCHIES_SHORT_DETAIL =
  '/api/pim/get-product-hierarchies-short-details';
export const GET_PRODUCT_FULL_VIEW = '/api/pim/get-product-details-full-view';

//* product header
export const TOGGLE_SHOW_PRODUCT_HEADER_PROFILE_IMAGE = '/api/test';
export const TOGGLE_SHOW_PRODUCT_HEADER_ADDRESS = '/api/test';
export const GET_PRODUCT_BRAND_IMAGE = '/api/pim/get-product-brand-images';
export const GET_PRODUCT_COMPANY_INFO =
  '/api/pim/get-product-company-information';
export const GET_PRODUCT_SOCIAL_MEDIA = '/api/pim/get-product-social-medias';
export const ADD_PRODUCT_SOCIAL_MEDIA = '/api/pim/save-product-social-media';
export const DELETE_PRODUCT_SOCIAL_MEDIA =
  '/api/pim/remove-product-social-media';
export const GET_PRODUCT_HEADER_BACKGROUND =
  '/api/pim/get-product-background-header';
export const SAVE_PRODUCT_HEADER_BACKGROUND =
  '/api/pim/save-product-background-header';
export const EDIT_PRODUCT_HEADER_BACKGROUND_CONFIG =
  '/api/pim/edit-product-background-header-configuration';
export const GET_PRODUCT_IMAGERY = '/api/pim/get-product-imagery';
export const DOWNLOAD_PRODUCT_IMAGERY = '/api/pim/download-product-imagery';

//* Facts panel
export const GET_PRODUCT_FACTS_PANEL = '/api/pim/get-product-nutrition-facts';
export const GET_PRODUCT_ENUMS = '/api/pim/get-product-item-enums';
export const UPDATE_PRODUCT_FACTS_PANEL =
  '/api/pim/save-product-item-nutrition';
export const GET_PRODUCT_INGREDIENTS = '/api/pim/get-product-ingredients';
export const SAVE_PRODUCT_INGREDIENT = '/api/pim/save-product-ingredients';

//* Add Product
export const GET_MEMBER_BRAND_PIM = '/api/pim/get-member-brands';

export const GET_PRODUCT_VIA_SPREADSHEET =
  '/api/pim/get-import-products-via-spreadsheet';
export const IMPORT_PRODUCT_VIA_SPREADSHEET =
  '/api/pim/import-products-via-spreadsheet';

export const GET_TDC_PRODUCT_VIA_SPREADSHEET =
  '/api/pim/download-import-products-from-tdc-template';
export const IMPORT_TDC_PRODUCT_VIA_SPREADSHEET =
  '/api/pim/upload-template-import-products-from-tdc';

export const GET_PRODUCT_HISTORY = '/api/pim/get-product-changes-history';
export const GET_PRODUCT_DETAIL_HISTORY =
  '/api/pim/get-product-changes-history-details';
export const GET_PROPERTIES_FOR_HISTORY_PRODUCT =
  '/api/pim/get-properties-for-history-product';

export const GET_PRODUCT_DETAIL_GRID = '/api/pim/get-product-detail-grid';
export const GET_PRODUCT_DETAIL_GRID_DISTINCT =
  '/api/pim/get-product-detail-grid/distinct';

export const GET_PRODUCT_DETAIL_CUSTOMIZED_GRID =
  '/api/pim/get-product-detail-customize-grid';
export const GET_PRODUCT_DETAIL_CUSTOMIZED_GRID_DISTINCT =
  '/api/pim/get-product-detail-customize-grid/distinct';

export const GET_FAVORITE_PRODUCT_DETAIL_CUSTOMIZED_GRID =
  '/api/favorites/get-favorite-products-customize-grid';
export const GET_FAVORITE_PRODUCT_DETAIL_CUSTOMIZED_GRID_DISTINCT =
  '/api/favorites/get-favorite-products-customize-grid/distinct';

export const GET_SEARCH_PRODUCT_INTO_ASSET =
  '/api/pim/search-products-into-digital-asset';

export const EDIT_BRAND_IMAGES_BY_PRODUCT =
  '/api/pim/edit-brand-image-by-product';
export const GET_MARKETING_ASSETS = '/api/pim/get-marketing-assets';

// product-apl
export const PRODUCT_APL_GRID = '/api/apl/get-apl-grid';
export const PRODUCT_APL_GRID_DISTINCT = '/api/apl/get-apl-grid/distinct';

export const GET_PRODUCT_HIERARCHY_DETAILS =
  '/api/pim/get-product-hierarchy-details';
export const DOWNLOAD_PRODUCT_HIERARCHY_DETAILS =
  '/api/pim/download-product-hierarchy-details';

// Add Product
export const ADD_PRODUCT = '/api/pim/add-product-via-form';
// Product detail modules
export const GET_PRODUCT_ITEM_DETAIL_SCHEMA =
  '/api/pim/get-product-item-detail-schema';
export const GET_PRODUCT_ITEM_MODULES = '/api/pim/get-product-item-modules';
export const SAVE_PRODUCT_ITEM_MODULES = '/api/pim/save-product-item-module';
export const GET_PRODUCT_ITEM_ENUMS = '/api/pim/get-product-item-enums';
export const GET_PRODUCT_PRIVATE_PROPERTIES =
  '/api/pim/get-product-private-properties';
export const SAVE_PRODUCT_PRIVATE_PROPERTIES =
  '/api/pim/save-product-private-properties';
export const GET_PRODUCT_ITEM_MODULES_BY_BRICK_CODE =
  '/api/pim/get-product-item-modules-by-brick-code';
// end

export const EDIT_PRODUCT_DETAIL = '/api/pim/edit-product-detail';
export const GET_FAVORITE_PRODUCT_DETAIL_GRID =
  '/api/favorites/get-favorite-products-detail-grid';
export const GET_FAVORITE_PRODUCT_DETAIL_GRID_DISTINCT =
  '/api/favorites/get-favorite-products-detail-grid/distinct';

export const SEARCH_ASSET_NOT_ASSOCIATING =
  '/api/dam/search-digital-assets-not-associating';
export const UPLOAD_PIM_ASSET = '/api/pim/upload-digital-asset';
export const REPLACE_PIM_ASSET = '/api/pim/replace-digital-asset';

export const PIM_UNLINK_ASSET = '/api/pim/unlink-product-image';
export const PIM_LINK_ASSET = '/api/pim/link-product-image';
export const PIM_LINK_MULTI_ASSET = '/api/pim/link-product-images';
export const PIM_LINK_ASSET_DOCUMENT = '/api/pim/link-product-document';
export const PIM_UNLINK_ASSET_DOCUMENT = '/api/pim/unlink-product-document';

export const UPDATE_PRODUCT_IMAGE_LINK_METADATA =
  '/api/pim/update-product-image-link-metadata';

//* product full - syndication
export const GET_PRODUCT_MAPPING_GRID_DATA =
  '/api/mapping/get-product-mappings-grid';
export const GET_PRODUCT_MAPPING_GRID_DISTINC =
  '/api/mapping/get-product-mappings-grid/distinct';

export const GET_PRODUCT_MAPPING_METADATA =
  '/api/mapping/get-mapping-product-metadata';
export const DOWNLOAD_PRODUCT_MAPPING_METADATA =
  '/api/mapping/download-mapping-data-properties';
export const GET_MAPPING__PRODUCT_DATA_PROPERTIES_GRID =
  '/api/mapping/get-mapping-product-data-properties-grid';
export const GET_MAPPING_PRODUCT_SUPPORTED_MEMBERS_GRID =
  '/api/mapping/get-mapping-supported-members';

//* Syndication
export const UPDATE_GDSN_PUBLICATION_CONFIG =
  '/api/syndication/update-gdsn-publication-config';

export const GET_PARTY_INFO_GRID = '/api/syndication/get-all-party-info-grid';
export const GET_PARTY_INFO_GRID_DISTINCT =
  '/api/syndication/get-all-party-info-grid/distinct';

export const GET_PUBLISH_CHECKLIST = '/api/syndication/get-publish-checklist';

export const GET_GDSN_RECEIVED_PRODUCTS =
  '/api/syndication/get-gdsn-received-products';
export const GET_GDSN_RECEIVED_PRODUCTS_DISTINCT =
  '/api/syndication/get-gdsn-received-products/distinct';
export const DELETE_PRODUCT_ITEMS = '/api/pim/delete-product-items';

export const GET_SYNDICATION_HISTORY =
  '/api/syndication/get-syndication-history-grid';
export const GET_SYNDICATION_HISTORY_DISTINCT =
  '/api/syndication/get-syndication-history-grid/distinct';

export const ACCEPT_PUBLICATION =
  '/api/syndication/accept-gdsn-received-hierarchy';
export const REJECT_PUBLICATION =
  '/api/syndication/reject-gdsn-received-hierarchy';

export const REVIEW_PUBLICATION =
  '/api/syndication/review-gdsn-received-hierarchy';

export const GET_SOURCE_GLN = '/api/syndication/get-all-member-GLNs';
export const GET_SYNDICATION_HISTORY_SHORT_DETAILS =
  '/api/syndication/get-syndication-history-short-details';
export const EXECUTE_PUBLISH_PRODUCTS =
  '/api/syndication/execute-publish-products';

export const SAVE_QUICK_PUBLICATION_FIX_FORM =
  '/api/syndication/save-product-data-for-publishing';

export const UPDATE_TIME_UNREAD_GDSN_ACTIVITIES =
  '/api/syndication/update-last-time-get-unread-gdsn-activities';

export const SYNC_FROM_NUTRITION_FACT_TO_ALLERGEN_MODULE =
  '/api/pim/sync-product-item-allergen-module-from-nutrition-panel';

export const SYNC_FROM_ALLERGEN_MODULE_TO_NUTRITION_FACT =
  '/api/pim/sync-product-item-nutrition-panel-from-allergen-module';

export const SYNC_FROM_NUTRITION_FACT_TO_INGREDIENT_MODULE =
  '/api/pim/sync-product-item-ingredient-module-from-nutrition-panel';

export const SYNC_FROM_INGREDIENT_MODULE_TO_NUTRITION_FACT =
  '/api/pim/sync-product-item-nutrition-panel-from-ingredient-module';

export const DELETE_PRODUCT_NUTRITION_PANEL =
  '/api/pim/delete-product-nutrition-panel';
export const DELETE_PRODUCT_ITEMS_PUBLICATION =
  '/api/syndication/withdraw-published-products';
export const GET_ALL_PUBLICATIONS = '/api/syndication/get-all-publication';

export const MOVE_PRODUCT_TO_MEMBER = '/api/pim/move-products-to-member';

export const DOWNLOAD_NEW_ITEM = '/api/pim/download-product-via-form';

export const GET_PROPERTIES_CUSTOMIZED_GRID =
  '/api/pim/get-product-properties-customize-grid';

export const GET_PRODUCT_CUSTOM_PROPERTIES_DATA =
  '/api/pim/get-product-custom-fields-data';
export const GET_MEMBER_PRODUCT_CUSTOM_PROPERTIES =
  '/api/pim/get-product-custom-fields';
export const SAVE_MEMBER_PRODUCT_CUSTOM_PROPERTIES =
  '/api/pim/save-product-custom-fields';

export const GET_PRODUCT_PROPERTIES_TO_EXPORT =
  '/api/pim/get-product-properties-to-export-data';
export const EXPORT_PRODUCT_PROPERTIES =
  '/api/pim/export-data-selected-products';
export const IMPORT_PRODUCT_FOR_BULK_EDITING =
  '/api/pim/import-template-editing-bulk-products';
export const DOWNLOAD_MANUAL_BULK_EDITING_PRODUCT =
  '/api/pim/download-user-manual-editing-bulk-products';
export const GET_QA_PRODUCT_INGREDIENT =
  '/api/pim/get-qa-spec-data-ingredients';
export const SAVE_QA_PRODUCT_INGREDIENT =
  '/api/pim/save-qa-spec-data-ingredients';
export const GET_QA_PRODUCT_ALLERGEN = '/api/pim/get-qa-spec-data-allergens';
export const SAVE_QA_PRODUCT_ALLERGEN = '/api/pim/save-qa-spec-data-allergens';
export const GET_DATA_FORMULA = '/api/pim/get-qa-spec-data-formula';
export const GET_SNAPSHOT_DATA_FORMULA =
  '/api/pim/snapshot/get-qa-spec-formula';
export const SAVE_DATA_FORMULA = '/api/pim/save-qa-spec-data-formula';

export const GET_QA_SPEC_DATA_DOCUMENT = '/api/pim/get-qa-spec-data-documents';
export const GET_QA_SPEC_SNAPSHOT_DATA_DOCUMENT =
  '/api/pim/snapshot/get-qa-spec-documents';

export const GET_QA_SPEC_DATA_CERTIFICATE =
  '/api/pim/get-qa-spec-data-certificates';
export const GET_QA_SPEC_SNAPSHOT_DATA_CERTIFICATE =
  '/api/pim/snapshot/get-qa-spec-certificates';

export const QA_SPEC_LINK_TO_DOCUMENT_CERTIFICATE =
  '/api/pim/qa-spec-link-to-document-or-certificate';
export const QA_SPEC_UNLINK_TO_DOCUMENT_CERTIFICATE =
  '/api/pim/qa-spec-unlink-to-document-or-certificate';

export const GET_QA_SPEC_DATA_NUTRITION_FACTS =
  '/api/pim/get-qa-spec-data-nutrition-facts';
export const GET_QA_SPEC_DRUG_FACTS = '/api/pim/get-qa-spec-data-drug-facts';
export const SAVE_QA_SPEC_DATA_NUTRITION_FACTS =
  '/api/pim/save-qa-spec-data-nutrition-fact';
export const DELETE_QA_SPEC_NUTRITION_FACT =
  '/api/pim/delete-qa-spec-data-nutrition-fact';
export const DELETE_QA_SPEC_DRUG_FACT =
  '/api/pim/delete-qa-spec-data-drug-facts';
export const GET_QA_SPEC_DATA_SUPPLEMENT_FACTS =
  '/api/pim/get-qa-spec-data-supplement-facts';
export const SAVE_QA_SPEC_DATA_SUPPLEMENT_FACT =
  '/api/pim/save-qa-spec-data-supplement-fact';
export const DELETE_QA_SPEC_SUPPLEMENT_FACT =
  '/api/pim/delete-qa-spec-data-supplement-fact';

export const SAVE_DRUG_FACTS = '/api/pim/save-product-item-drug-facts';
export const SAVE_QA_DRUG_FACTS = '/api/pim/save-qa-spec-data-drug-facts';

export const SET_PRIMARY_PRODUCT_IMAGE = '/api/pim/set-primary-product-image';

export const DOWNLOAD_APL_PRODUCT_LIST = 'api/apl/download-apl-product-list';

export const DOWNLOAD_GDSN_RECEIVED_PRODUCT =
  'api/syndication/download-gdsn-received-product';

export const LINK_QA_SPEC_DATA_PRODUCT_MSDS_DOCUMENT =
  '/api/pim/link-qa-spec-data-product-specification-msds-document';

export const UNLINK_QA_SPEC_DATA_PRODUCT_MSDS_DOCUMENT =
  '/api/pim/unlink-qa-spec-data-product-specification-msds-document';

export const SAVE_QA_SPEC_DATA_FRESH_SEAFOOD =
  '/api/pim/save-qa-spec-data-fresh-seafood';

export const SAVE_QA_SPEC_DATA_FRESH_MEAT =
  '/api/pim/save-qa-spec-data-fresh-meat';

export const GET_GDSN_SCHEMA =
  '/api/syndication/get-gdsn-received-product-schema-detail';

// Multiple Panels
export const GET_PRODUCT_ALLERGENS = '/api/pim/get-product-allergens';

export const SAVE_PRODUCT_ALLERGENS = '/api/pim/save-product-allergens';

export const SAVE_SUPPLEMENT_FACTS =
  '/api/pim/save-product-item-supplement-facts';

export const GET_PRODUCT_SUPPLEMENT_FACTS =
  '/api/pim/get-product-supplement-facts';

export const DELETE_PRODUCT_SUPPLEMENT_PANEL =
  '/api/pim/delete-product-item-supplement-facts';
export const GET_PROPERTIES_FOR_COPY_PRODUCT =
  '/api/pim/get-properties-for-copy-product';
export const COPY_PRODUCT_ITEM = '/api/pim/copy-product-item';
export const GET_PRODUCT_DRUG_FACTS = '/api/pim/get-product-drug-facts';
export const DELETE_PRODUCT_DRUG_FACTS =
  '/api/pim/delete-product-item-drug-facts';

// Group data
export const GET_ALL_GROUP_PRODUCT_DATA = '/api/pim/get-all-group-data';
export const GET_GROUP_DATA_DETAIL = '/api/pim/get-group-data-detail';
export const SAVE_GROUP_PRODUCT_DATA = '/api/pim/save-group-data';
export const ASSIGN_GROUP_PRODUCT_DATA = '/api/pim/assign-group-data';
export const DELETE_GROUP_PRODUCT_DATA = '/api/pim/delete-group-data';
// QA Group data
export const GET_QA_GROUP_DATA_DETAIL =
  '/api/pim/get-qa-spec-group-data-detail';
export const SAVE_QA_GROUP_PRODUCT_DATA = '/api/pim/save-qa-spec-group-data';
export const ASSIGN_QA_GROUP_PRODUCT_DATA =
  '/api/pim/assign-qa-spec-group-data';
export const DELETE_QA_GROUP_PRODUCT_DATA =
  '/api/pim/delete-qa-spec-group-data';

export const SAVE_QA_SPEC_DATA_PACKAGING = '/api/pim/save-product-labeling';

export const SAVE_QA_SPEC_DATA_CLAIMS = '/api/pim/save-qa-spec-data-claims';
export const SAVE_QA_SPEC_PRODUCT_CONTAIN =
  '/api/pim/save-qa-spec-data-product-contains';

// Walmart
export const POST_TO_WALMART = '/api/pim/post-to-walmart';

export const GET_PRODUCT_MEMBER_ADDRESS =
  '/api/pim/get-product-company-information';

export const EDIT_PRODUCT_LINKED_ADDRESS =
  'api/pim/edit-product-linked-address';

export const GET_PRODUCT_PET_NUTRITION_FACTS =
  '/api/pim/get-product-pet-nutrition-facts';
export const SAVE_PRODUCT_PET_NUTRITION_FACTS =
  '/api/pim/save-product-pet-nutrition-fact';
export const DELETE_PRODUCT_PET_NUTRITION_FACTS =
  '/api/pim/delete-product-pet-nutrition-facts';

export const SAVE_QA_SPEC_PRODUCT_PET_NUTRITION_FACTS =
  '/api/pim/save-qa-spec-data-pet-nutrition-fact';
export const GET_QA_SPEC_PET_NUTRITION_FACTS =
  '/api/pim/get-qa-spec-data-pet-nutrition-facts';
export const DELETE_QA_SPEC_PET_NUTRITION_FACTS =
  '/api/pim/delete-qa-spec-data-pet-nutrition-facts';
export const SAVE_QA_SPEC_PET_NON_FOOD =
  '/api/pim/save-qa-spec-data-pet-non-food';
// Snapshot
export const GET_SNAPSHOT_QA_SPEC_HEADER =
  '/api/pim/snapshot/get-qa-spec-header';

export const GET_SNAPSHOT_DATA_NUTRITION_FACTS =
  '/api/pim/snapshot/get-qa-spec-nutrition-facts';

export const GET_SNAPSHOT_DATA_SUPPLEMENT_FACTS =
  '/api/pim/snapshot/get-qa-spec-supplement-facts';

export const GET_SNAPSHOT_DATA_DRUG_FACTS =
  '/api/pim/snapshot/get-qa-spec-drug-facts';

export const GET_QA_PET_FOOD_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-pet-nutrition-facts';

export const GET_SNAPSHOT_QA_SPEC_CLAIMS =
  '/api/pim/snapshot/get-qa-spec-claims';

export const GET_SNAPSHOT_QA_SPEC_PACKING =
  '/api/pim/snapshot/get-qa-spec-packing';

export const GET_SNAPSHOT_QA_SPEC_PRODUCT_CONTAINS =
  '/api/pim/snapshot/get-qa-spec-product-contains';

export const GET_SNAPSHOT_QA_SPEC_FRESH_MEAT =
  '/api/pim/snapshot/get-qa-spec-fresh-meat';

export const GET_SNAPSHOT_QA_SPEC_FRESH_SEAFOOD =
  '/api/pim/snapshot/get-qa-spec-fresh-seafood';

export const GET_QA_PRODUCT_SPECIFICATION_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-product-specification';

export const GET_QA_PRODUCT_ORGANOLEPTIC_CHARACTERISTICS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-organoleptic-characteristics';

export const GET_QA_PRODUCT_PHYSICAL_CHEMICAL_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-physical-and-chemical-characteristics';

export const GET_QA_PRODUCT_CONTAMINANT_CHARACTERISTIC_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-contaminant-characteristics';

export const GET_QA_PRODUCT_MICROBIOLOGICAL_CHARACTERISTICS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-microbiological-characteristics';

export const GET_QA_PRODUCT_SPEC_DOCUMENTS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-product-specification-documents';

export const GET_QA_PRODUCT_FRESH_PRODUCE_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-fresh-produce';

export const GET_QA_PRODUCT_MANUFACTURING_LOCATIONS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-manufacturing-locations';

export const GET_QA_PRODUCT_PROCESS_STAGE_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-manufacturing-processes';

export const GET_QA_PRODUCT_MANUFACTURING_DOCUMENTS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-manufacturing-process-documents';

export const GET_QA_ALLERGENS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-allergens';

export const GET_QA_INGREDIENTS_SNAPSHOT =
  '/api/pim/snapshot/get-qa-spec-ingredients';

export const UPDATE_PRODUCT_IXONE_SHIELD =
  '/api/pim/update-product-ixone-shield';

export const GET_EDITING_FIELDS_FORM_TEMPLATE =
  '/api/pim/get-editing-fields-from-template';

export const GET_PRODUCT_CHANGES_HISTORY =
  '/api/pim/get-product-changes-history';
export const GET_QA_CLAIMS = '/api/pim/get-qa-spec-data-claims';

export const GET_QA_PACKING = '/api/pim/get-product-labeling';

export const GET_FRESH_MEAT = '/api/pim/get-qa-spec-data-fresh-meat';

export const GET_FRESH_SEAFOOD = '/api/pim/get-qa-spec-data-fresh-seafood';

export const GET_PRODUCT_CONTAINS =
  '/api/pim/get-qa-spec-data-product-contains';

export const GET_PRODUCT_HISTORY_DETAIL_SCHEMA =
  '/api/pim/get-product-history-detail-schema';
