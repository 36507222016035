import React from 'react';
import { useDispatch } from 'react-redux';
import { FilterOutlined } from '@ant-design/icons';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import Messages from 'i18n/messages/home';
import ButtonSmallIcon from '../button/ButtonSmallIcon';

const OpenAdvanceFilter = ({ disabled }) => {
  const dispatch = useDispatch();

  const handleOpenAdvanceFilter = () => {
    dispatch(actionsGridView.updateIsOpenAdvanceSearch(true));
  };

  return (
    <>
      <ButtonSmallIcon
        icon={<FilterOutlined />}
        label={Messages.advanceFilter}
        disabled={disabled}
        onClick={handleOpenAdvanceFilter}
        className='grid-config__button'
      />
    </>
  );
};

export default OpenAdvanceFilter;
