import { useQuery } from '@tanstack/react-query';

import * as assetServices from 'services/assetProduct';

const getAssetGrid = async ({ queryKey }) => {
  const params = { ...queryKey[1] };
  const response = await assetServices.getAssetList({
    ...queryKey[1],
    pageNumber: params?.pageIndex,
  });

  const { isSuccess, data } = response;

  return isSuccess && data ? data : [];
};

export const useQueryAssetGrid = (props) => {
  const { params, enabled = false, ...restProps } = props;

  const query = useQuery({
    queryKey: ['asset-grid-view', params],
    queryFn: getAssetGrid,
    enabled,
    ...restProps,
  });

  return query;
};
