import React from 'react';

import { useSelector } from 'react-redux';

import { RibbonSection, RibbonBar, RibbonDivider } from 'common/components';
import OpenAssetSection from './components/sections/asset/grid/OpenAssetSection';
import PreviewSection from './components/sections/asset/grid/PreviewSection';
import AssetActionsSection from './components/sections/asset/AssetActionsSection';
import { FilterSection, VersionSection } from './components/sections/asset';
import Addition from './components/sections/home-grid/AdditionSection';
import ShareSection from './components/sections/shared/ShareSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import Categories from './components/sections/home-grid/Categories';
import ExecuteWorkflow from './components/sections/home-grid/Execute';

import gridSelectors from 'common/components/grid-view/controllers/selectors';
import BackControl from './components/controls/home/Back';
import { Can } from 'context/Can';
import {
  useCheckIsSuperAdmin,
  useCheckPermissions,
  usePermissionEditAsset,
  usePermissionEditSharedAsset,
} from 'hooks';
import {
  ABILITY_ACTION,
  ABILITY_SUBJECT,
  PERMISSION_VIEW_ADVANCED_FILTER_IN_ASSET_GRID,
} from 'static/Permission';

import * as selectorsGlobal from 'redux/global/selectors';
import * as ribbonSelectors from 'redux/ribbon/selectors';

// const productsForMember = 'products/member';
// const assetsForMember = 'assets/member';

const AssetsGrid = () => {
  const pathname = window.location.pathname;
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );
  // const isBackToMemberProfile =
  //   pathname.includes(productsForMember) || pathname.includes(assetsForMember);
  // const isReportingsBack = pathname.includes('/reporting');

  const hasPermissionViewAdvancedFilter = useCheckPermissions(
    PERMISSION_VIEW_ADVANCED_FILTER_IN_ASSET_GRID
  );

  const hasPermissionEditAsset = usePermissionEditAsset();
  const hasPermissionEditSharedAsset = usePermissionEditSharedAsset();
  const isSuperAdmin = useCheckIsSuperAdmin();

  const isNewAssetFilter = useSelector(selectorsGlobal.selectDisplayAssetNew());

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  const isDisabledAddition = selectedItemList?.length === 0;
  const isFavoriteView = pathname === '/favorite/favorite-assets';

  const isShowPreviewAsset =
    isSuperAdmin || hasPermissionEditAsset || hasPermissionEditSharedAsset;

  const isDisabledCloseDetail =
    window.location.search !== '?query=1' ||
    closeDetailStack?.name !== 'query' ||
    closeDetailStack?.stack.length < 2;

  return (
    <RibbonBar>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.SHARED_ENTITY}>
        <ManageSharingSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.ASSET}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.ASSET}>
        <ViewLayout disabledGridQuery={isNewAssetFilter} />
      </Can>
      <RibbonDivider />
      <Addition
        type='asset'
        isDisabled={isDisabledAddition}
        selectedItemList={selectedItemList}
        isFavoriteView={isFavoriteView}
      />
      <ShareSection selectedItemList={selectedItemList} />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.ASSET}>
        <OpenAssetSection />
      </Can>
      <RibbonDivider />
      <AssetActionsSection />

      {isShowPreviewAsset && <PreviewSection />}

      <RibbonDivider />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.ASSET}>
        <RibbonSection>
          <VersionSection />
          <RibbonDivider />
          <FilterSection />
        </RibbonSection>
      </Can>
      <Categories />
      <RibbonDivider />
      {/* TODO: Add role permission later */}
      <ExecuteWorkflow entityType='DigitalAsset' />
      <RibbonDivider />
      <AdvanceStack
        isNested
        selectedItemList={selectedItemList}
        disabledAdvanceFilter={
          !hasPermissionViewAdvancedFilter || isNewAssetFilter
        }
      />
      <BackControl ribbonSide='right' isDisabled={isDisabledCloseDetail} />
    </RibbonBar>
  );
};

export default AssetsGrid;
