import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectWfTemplate = (state) => state.wfTemplate || initialState;

const makeSelectWfTemplateView = () =>
  createSelector(selectWfTemplate, (state) => state.templateView);
const makeSelectWfTemplateDetail = () =>
  createSelector(selectWfTemplate, (state) => state.detailWfTemplate);

const makeSelectToggleNewStep = () =>
  createSelector(selectWfTemplate, (state) => state.toggleNewStep);

const makeSelectToggleNewTask = () =>
  createSelector(selectWfTemplate, (state) => state.toggleNewTask);

const makeSelectloading = () =>
  createSelector(selectWfTemplate, (state) => state.loading);

const makeSelectLoadingStepInfo = () =>
  createSelector(selectWfTemplate, (state) => state.loadingStepInfo);
const makeSelectStepInfo = () =>
  createSelector(selectWfTemplate, (state) => state.stepInfo);
const makeSelectStepInfoError = () =>
  createSelector(selectWfTemplate, (state) => state.stepInfoError);
const makeSelectLoadingCreateStep = () =>
  createSelector(selectWfTemplate, (state) => state.loadingCreateStep);
const makeSelectCreateStepSuccess = () =>
  createSelector(selectWfTemplate, (state) => state.createStepSuccess);
const makeSelectCreateStepError = () =>
  createSelector(selectWfTemplate, (state) => state.createStepError);
const makeSelectTrees = () =>
  createSelector(selectWfTemplate, (state) => state.trees);
const makeSelectActiveStepKey = () =>
  createSelector(selectWfTemplate, (state) => state.activeStepKey);
const makeSelectWfTemplateVersions = () =>
  createSelector(selectWfTemplate, (state) => state.wfTemplateVersions);
const makeSelectWfTemplateVersionsLoading = () =>
  createSelector(selectWfTemplate, (state) => state.wfTemplateVersionsLoading);

const makeSelectLoadingWorkflowTemplateDetail = () =>
  createSelector(
    selectWfTemplate,
    (state) => state.loadingWorkflowTemplateDetail
  );
const makeSelectWorkflowTemplateDetail = () =>
  createSelector(selectWfTemplate, (state) => state.workflowTemplateDetail);
const makeSelectWorkflowTemplateDetailError = () =>
  createSelector(
    selectWfTemplate,
    (state) => state.workflowTemplateDetailError
  );

const makeSelectUserWorkflowRoles = () =>
  createSelector(selectWfTemplate, (state) => state.userWorkflowRoles);
const makeSelectTaskInfo = () =>
  createSelector(selectWfTemplate, (state) => state.taskInfo);
const makeSelectWorkflowMetric = () =>
  createSelector(selectWfTemplate, (state) => state.workflowMetric);
const makeSelectStatusWorkflow = () =>
  createSelector(selectWfTemplate, (state) => state.statusWorkflow);
const makeSelectRequiredTask = () =>
  createSelector(selectWfTemplate, (state) => state.requiredTask);
const makeSelectMessageSuccess = () =>
  createSelector(selectWfTemplate, (state) => state.messageSuccess);

const makeSelectWorkflowStepMetricLoading = () =>
  createSelector(selectWfTemplate, (state) => state.workflowStepMetricLoading);

const makeSelectWorkflowStepMetric = () =>
  createSelector(selectWfTemplate, (state) => state.workflowStepMetric);
const makeSelectCloneStep = () =>
  createSelector(selectWfTemplate, (state) => state.isCloneStep);
const makeSelectCloneTask = () =>
  createSelector(selectWfTemplate, (state) => state.isCloneTask);
const makeSelectWorkflowStepMetricError = () =>
  createSelector(selectWfTemplate, (state) => state.workflowStepMetricError);

export {
  selectWfTemplate,
  makeSelectWfTemplateView,
  makeSelectWfTemplateDetail,
  makeSelectToggleNewStep,
  makeSelectToggleNewTask,
  makeSelectloading,
  makeSelectLoadingStepInfo,
  makeSelectStepInfo,
  makeSelectStepInfoError,
  makeSelectLoadingCreateStep,
  makeSelectCreateStepSuccess,
  makeSelectCreateStepError,
  makeSelectLoadingWorkflowTemplateDetail,
  makeSelectWorkflowTemplateDetail,
  makeSelectWorkflowTemplateDetailError,
  makeSelectTrees,
  makeSelectActiveStepKey,
  makeSelectWfTemplateVersions,
  makeSelectUserWorkflowRoles,
  makeSelectTaskInfo,
  makeSelectWorkflowMetric,
  makeSelectStatusWorkflow,
  makeSelectRequiredTask,
  makeSelectMessageSuccess,
  makeSelectWfTemplateVersionsLoading,
  makeSelectWorkflowStepMetricLoading,
  makeSelectWorkflowStepMetric,
  makeSelectWorkflowStepMetricError,
  makeSelectCloneStep,
  makeSelectCloneTask,
};
