import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

const ProductCategory = (props) => {
  const { dataDetail } = props;

  return (
    <Fragment>
      <Row className='product-info__category'>
        <Col className='product-info__category-title' span={6}>
          <Text strong='true'>Category:&nbsp;</Text>
        </Col>
        <Col span={6}>{dataDetail && dataDetail.categoryName}</Col>
        <Col className='product-info__category-title' span={6}>
          <Text strong='true'>Classification:&nbsp;</Text>
        </Col>
        <Col span={6}>{dataDetail && dataDetail.classificationName}</Col>
      </Row>
      <Row className='product-info__category'>
        <Col className='product-info__category-title' span={6}>
          <Text strong='true'>Sub-Category:&nbsp;</Text>
        </Col>
        <Col span={6}>{dataDetail && dataDetail.subCategoryName}</Col>
        <Col className='product-info__category-title' span={6}>
          <Text strong='true'>Sub-classi:&nbsp;</Text>
        </Col>
        <Col span={6}>{dataDetail && dataDetail.subClassificationName}</Col>
      </Row>
    </Fragment>
  );
};

ProductCategory.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductCategory;
