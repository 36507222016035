import React from 'react';
import { Row, Avatar } from 'antd';
import PropTypes from 'prop-types';
import { Images } from 'config/assets';
import { getThumbnailImage } from '../utils';

const FolderTileHeader = (props) => {
  const { dataDetail } = props;

  const type = dataDetail?.type?.toLowerCase();
  const image = getThumbnailImage(dataDetail, type);

  return (
    <Row className='folder-tile__header' justify='center'>
      <div className='folder-tile__header--background'></div>

      <div className='folder-tile__header--image-wrapper'>
        <Avatar
          className='folder-tile__header--image'
          src={image || Images.RIVIR_LOGO_DEFAULT}
          alt={dataDetail && dataDetail.folderName}
          style={{ backgroundColor: '#FFF' }}
        />
      </div>
    </Row>
  );
};

FolderTileHeader.propTypes = {
  dataDetail: PropTypes.object,
};

export default FolderTileHeader;
