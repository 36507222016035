import {
  CreditCardOutlined,
  IdcardOutlined,
  DollarCircleOutlined,
  TrophyOutlined,
  SendOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  GlobalOutlined,
  LockOutlined,
  PhoneOutlined,
  HomeOutlined,
  HistoryOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { ReactComponent as billing } from 'assets/locations/billing.svg';
import { ReactComponent as hq } from 'assets/locations/hq.svg';
import { ReactComponent as office } from 'assets/locations/office.svg';
import { ReactComponent as shipping } from 'assets/locations/shipping.svg';
import { ReactComponent as store } from 'assets/locations/store.svg';
import { ReactComponent as warehouse } from 'assets/locations/warehouse.svg';
import { ReactComponent as factory } from 'assets/locations/factory.svg';

const iconList = [
  {
    id: 1,
    value: 'CreditCard',
    icon: CreditCardOutlined,
  },
  {
    id: 2,
    value: 'MemberCredit',
    icon: IdcardOutlined,
  },
  {
    id: 3,
    value: 'MembershipFee',
    icon: DollarCircleOutlined,
  },
  {
    id: 4,
    value: 'PastDue',
    icon: TrophyOutlined,
  },
  {
    id: 5,
    value: 'CanceledTransaction',
    icon: SendOutlined,
  },
];

const locationTypeIcon = [
  {
    id: 1,
    value: 'Headquarters',
    label: 'Headquarters',
    icon: hq,
  },
  {
    id: 2,
    value: 'Billing',
    label: 'Billing',
    icon: billing,
  },
  {
    id: 6,
    value: 'Office',
    label: 'Office',
    icon: office,
  },
  {
    id: 7,
    value: 'ProductionFacility',
    label: 'Production Facility',
    icon: factory,
  },
  {
    id: 5,
    value: 'RetailLocation',
    label: 'Retail Location',
    icon: store,
  },
  {
    id: 3,
    value: 'Shipping',
    label: 'Shipping',
    icon: shipping,
  },
  {
    id: 4,
    value: 'Warehouse',
    label: 'Warehouse',
    icon: warehouse,
  },
];

const publicPrivateIcon = [
  {
    id: 1,
    value: 'Public',
    icon: GlobalOutlined,
    tooltip: 'Public',
  },
  {
    id: 2,
    value: 'Private',
    icon: LockOutlined,
    tooltip: 'Private',
  },
];

const primaryContactIcon = [
  {
    id: 1,
    value: true,
    icon: PhoneOutlined,
    tooltip: 'Primary Contact',
  },
];

const primaryLocationIcon = [
  {
    id: 1,
    value: true,
    icon: HomeOutlined,
    tooltip: 'Primary Address',
  },
];

const statusIcon = [
  {
    id: 1,
    value: 'Active',
    icon: CheckCircleOutlined,
    suffix: 'active',
  },
  {
    id: 2,
    value: 'Deleted',
    icon: CloseCircleOutlined,
    suffix: 'deleted',
  },
  {
    id: 2,
    value: 'Pending',
    icon: ExclamationCircleOutlined,
    suffix: 'pending',
  },
];

const contactStatusIcon = [
  {
    id: 1,
    value: 'Active',
    icon: CheckCircleOutlined,
    suffix: 'active',
    editable: true,
  },
  {
    id: 2,
    value: 'Deleted',
    icon: CloseCircleOutlined,
    suffix: 'deleted',
  },
  {
    id: 3,
    value: 'Inactive',
    icon: ExclamationCircleOutlined,
    suffix: 'pending',
    editable: true,
  },
  {
    id: 4,
    value: 'Expired',
    icon: HistoryOutlined,
    suffix: 'deleted',
    editable: true,
  },
  {
    id: 5,
    value: 'PasswordExpired',
    tooltip: 'Password Expired',
    icon: WarningOutlined,
    suffix: 'pending',
  },
  {
    id: 5,
    value: 'Locked',
    tooltip: 'Locked',
    icon: LockOutlined,
    suffix: 'deleted',
  },
];

const locationStatusIcon = [
  {
    id: 1,
    value: 'Active',
    icon: CheckCircleOutlined,
    suffix: 'active',
  },
  {
    id: 2,
    value: 'Deleted',
    icon: CloseCircleOutlined,
    suffix: 'deleted',
  },
];

export {
  iconList,
  locationTypeIcon,
  publicPrivateIcon,
  primaryContactIcon,
  primaryLocationIcon,
  statusIcon,
  contactStatusIcon,
  locationStatusIcon,
};
