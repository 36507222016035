import React from 'react';

import { UploadFile } from 'common/components';

const UploadFileFormInput = (props) => {
  const {
    onChange,
    height,
    handleClickUpload,
    multiple = false,
    supportTypes,
  } = props;

  const onFileChange = (file) => {
    const fileList = file?.fileList;

    const fileUploadList = fileList;
    if (fileUploadList?.length <= 0) return;

    const isHaveError = fileUploadList.some((fileItem) =>
      fileItem?.status.includes('error')
    );

    if (isHaveError) return;
    onChange && onChange(file?.fileList);
  };

  return (
    <div style={{ height: height || 300 }}>
      <UploadFile
        getFileChange={onFileChange}
        manualUpload
        showSubmitBtn
        showPreview
        showList
        multiple={multiple}
        showMultiple={multiple}
        manualUploadHandler={handleClickUpload}
        supportTypes={supportTypes}
      />
    </div>
  );
};
export default UploadFileFormInput;
