export const GET_COMMUNICATION_TEMPLATES = 'GET_COMMUNICATION_TEMPLATES';
export const GET_COMMUNICATION_TEMPLATES_SUCCESS =
  'GET_COMMUNICATION_TEMPLATES_SUCCESS';
export const GET_COMMUNICATION_TEMPLATES_ERROR =
  'GET_COMMUNICATION_TEMPLATES_ERROR';

export const GET_COMMUNICATION_TEMPLATES_COLUMNS =
  'GET_COMMUNICATION_TEMPLATES_COLUMNS';
export const GET_COMMUNICATION_TEMPLATES_COLUMNS_SUCCESS =
  'GET_COMMUNICATION_TEMPLATES_COLUMNS_SUCCESS';
export const GET_COMMUNICATION_TEMPLATES_COLUMNS_ERROR =
  'GET_COMMUNICATION_TEMPLATES_COLUMNS_ERROR';

export const ENABLE_CREATE_TEMPLATE = 'ENABLE_CREATE_TEMPLATE';
export const ENABLE_EDIT_TEMPLATE = 'ENABLE_EDIT_TEMPLATE';
export const RESET_TEMPLATE = 'RESET_TEMPLATE';
export const SAVE_ID_TEMPLATE = 'SAVE_ID_TEMPLATE';
export const ENABLE_SAVE_TEMPLATE = 'ENABLE_SAVE_TEMPLATE';
export const UNABLE_SAVE_TEMPLATE = 'UNABLE_SAVE_TEMPLATE';
export const DISABLE_UPLOAD_MEDIA = 'DISABLE_UPLOAD_MEDIA';
