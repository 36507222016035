import { SESSION_KEY, ANON_KEY, SSO_KEY } from 'static/Constants';
import axios from 'axios';

/**
 * utility function to parse JWT payload
 * @param {object} token JWT token
 */
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

/**
 * Store received token into local storage
 * @param {object} token jwt token
 * @param {string} key local storage item key
 */
export function setSession(token, expires, refreshToken, key = SESSION_KEY) {
  const currentTime = new Date().getTime();
  const expiresTime = currentTime + expires * 1000;
  const tokenObj = {
    token: token,
    expiresTime,
  };

  // update axios header
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

  localStorage.setItem(key, JSON.stringify(tokenObj));
  localStorage.setItem('RefreshToken', refreshToken);
}

/**
 * Get access token from local storage
 */
export function clearToken() {
  localStorage.removeItem(SESSION_KEY);
  localStorage.removeItem('RefreshToken');
}

/**
 * Get access token from local storage
 */
export function getToken() {
  const sessionStr = localStorage.getItem(SESSION_KEY);
  const sessionObj = JSON.parse(sessionStr);
  return sessionObj && sessionObj.token;
}

export function getExpiredDate() {
  const sessionStr = localStorage.getItem(SESSION_KEY);
  const sessionObj = JSON.parse(sessionStr);

  return sessionObj?.expiresTime;
}

//* set anonymous basic info
export function setAnnoInfo(anonInfo) {
  if (!anonInfo) return;

  const procAnonInfo = JSON.stringify(anonInfo);
  localStorage.setItem(ANON_KEY, procAnonInfo);
}

//* clear anonymous basic info
export function clearAnnoInfo() {
  localStorage.removeItem(ANON_KEY);
}

//* get anonymous token
export function getAnonToken() {
  const anonStorage = localStorage.getItem(ANON_KEY);

  if (!anonStorage) return;

  return JSON.parse(anonStorage)?.userUniqueId;
}

//* get anonymous token
export function getAnonInfo() {
  const anonStorage = localStorage.getItem(ANON_KEY);

  if (!anonStorage) return;

  return JSON.parse(anonStorage);
}

// SSO Token
export function setSsoToken(ssoToken) {
  if (!ssoToken) return;

  localStorage.setItem(SSO_KEY, JSON.stringify(ssoToken));
}

export function getSsoToken() {
  const ssoToken = localStorage.getItem(SSO_KEY);
  if (!ssoToken) return;

  return JSON.parse(ssoToken);
}

//

/**
 * validate token availability within storage
 * @param {string} key local storage item key
 */
export function isAuthed(key = SESSION_KEY) {
  const tokenObjStr = localStorage.getItem(key);
  const anonToken = getAnonToken();

  if (!tokenObjStr) return false;
  // get expire from token payload
  const { expiresTime } = JSON.parse(tokenObjStr);
  // const expiresIn = parseInt(exp) * 1000;
  const currentTime = Date.parse(new Date());
  // validate if token is still valid
  return expiresTime > currentTime && !anonToken;
}
