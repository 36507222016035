import React from 'react';

import AddAssetPimModal from 'pages/product-full-view/components/modal/add-asset-pim-modal';

const UpdateMinimumRequirementProductDocumentModal = (props) => {
  const { visible, setVisible, productDetail } = props;

  const onCloseModal = () => {
    setVisible(false);
  };

  return visible ? (
    <AddAssetPimModal
      onCloseModal={onCloseModal}
      addType={'Document'}
      productDetail={productDetail}
    />
  ) : null;
};

export default UpdateMinimumRequirementProductDocumentModal;
