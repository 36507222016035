import { put, call, takeLatest, all } from 'redux-saga/effects';
import { get } from 'lodash';

import * as servicesGrid from 'services/grid';
import * as types from './constants';
import * as actions from './actions';
import * as servicesMarkup from 'services/markupMaintenance';
import {
  getMarkupList,
  getMarkupContentDetail,
  getCuratedQueryShortDetail,
} from 'services/markupMaintenance';
import { formatMDYWithParam } from 'utils/formatDate';
import { DEFAULT_SORT } from 'static/Constants';

const formatColumns = (columns) => {
  const checkboxColumn = [
    {
      field: '',
      checkboxSelection: true,
      filter: false,
      suppressMenu: true,
      sortable: false,
    },
  ];
  const showColumns = [
    'markupContent',
    'status',
    'effectedDate',
    'created',
    'createdBy',
    'lastModified',
    'lastModifiedBy',
  ];

  const formattedColumns = showColumns.map((showCol) => {
    const colData = columns.find(
      (dataCol) => dataCol.fieldNameCamelCase === showCol
    );

    switch (showCol) {
      case 'status':
        return {
          ...colData,
          minWidth: 110,
          resizable: true,
        };

      case 'markupContent':
        return {
          ...colData,
          flex: 1,
          minWidth: 150,
          resizable: true,
        };
      case 'effectedDate':
      case 'created':
      case 'lastModified':
        return {
          ...colData,
          minWidth: 150,
          valueFormatter: formatMDYWithParam,
          resizable: true,
        };
      case 'createdBy':
      case 'lastModifiedBy':
        return {
          ...colData,
          minWidth: 170,
          resizable: true,
        };
      default:
        return colData;
    }
  });

  return checkboxColumn.concat(formattedColumns);
};

export function* getMarkupGridColumnsSaga(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
    const { columns } = response;
    const formattedColumns = formatColumns(columns);
    if (columns.length) {
      yield put(actions.getMarkupGridColumnsSuccess(formattedColumns));
    } else {
      yield put(actions.getMarkupGridColumnsFailure());
    }
  } catch (err) {
    yield put(actions.getMarkupGridColumnsFailure());
  }
}

export function* getMarkupListSaga(payload) {
  try {
    const { pageSize, pageIndex, search } = payload;
    const params = {
      pageSize,
      pageIndex,
      search: { searchText: search },
      sort: DEFAULT_SORT,
    };
    const response = yield call(getMarkupList, params);

    const dataList = get(response, 'data.gridData', []);
    const isSuccess = get(response, 'isSuccess', false);
    const total = get(response, 'data.paging.totalRecord', 0);

    if (isSuccess) {
      yield put(actions.getMarkupListSuccess(dataList, total));
    } else {
      yield put(actions.getMarkupListFailure());
    }
  } catch (err) {
    yield put(actions.getMarkupListFailure());
  }
}

export function* createMarkupSaga(action) {
  try {
    const { isSuccess, data, message } = yield call(
      servicesMarkup.createMarkup,
      action.payload
    );
    if (isSuccess) {
      yield put(actions.createMarkupSuccess(data.id));
    } else {
      yield put(actions.createMarkupError(message));
    }
  } catch (error) {
    yield put(actions.createMarkupError(error));
  }
}

export function* cloneMarkupSaga(action) {
  try {
    const { isSuccess, data, message } = yield call(
      servicesMarkup.cloneMarkup,
      action.payload
    );
    if (isSuccess) {
      yield put(actions.cloneMarkupSuccess(data.id));
    } else {
      yield put(actions.cloneMarkupError(message));
    }
  } catch (error) {
    yield put(actions.cloneMarkupError(error));
  }
}

export function* getMarkupContentDetailSaga(payload) {
  try {
    const response = yield call(getMarkupContentDetail, payload.params);
    if (response.isSuccess) {
      yield put(actions.getMarkupContentDetailSuccess(response.data));
    } else {
      yield put(actions.getMarkupContentDetailError(response.message));
    }
  } catch (error) {
    yield put(actions.getMarkupContentDetailError(error.message));
  }
}

// Curated Query Markup
export function* getCuratedQueryListSaga(payload) {
  try {
    const params = {
      type: payload.type,
      PageSize: payload.pageSize,
      PageIndex: payload.pageIndex,
      'Search.SearchText': payload['Search.SearchText'],
      sort: DEFAULT_SORT,
    };
    const response = yield call(servicesMarkup.getCuratedQuery, params);

    if (response.isSuccess) {
      yield put(
        actions.getCuratedQueryListSuccess(
          response.data.gridData,
          response.data.paging.totalRecord
        )
      );
    } else {
      yield put(actions.getCuratedQueryListError(response.message));
    }
  } catch (error) {
    yield put(actions.getCuratedQueryListError(error));
  }
}

// Curated Query Markup short details
export function* getCuratedQueryShortDetailSaga(payload) {
  try {
    const response = yield call(getCuratedQueryShortDetail, payload.params);
    if (response.isSuccess) {
      yield put(actions.getCuratedQueryShortDetailSuccess(response.data));
    } else {
      yield put(actions.getCuratedQueryShortDetailError(response.message));
    }
  } catch (error) {
    yield put(actions.getCuratedQueryShortDetailError(error.message));
  }
}

const formatCuratedQuery = (columns) => {
  const checkboxColumn = [
    {
      field: '',
      checkboxSelection: true,
      filter: false,
      suppressMenu: true,
      sortable: false,
    },
  ];
  const showColumns = [
    'name',
    'curatedQueryShareType',
    'gridName',
    'curatedQueryShareStatus',
    'lastModified',
    'lastModifiedByName',
    'curatedQueryShareToTotalsMembers',
  ];

  const formattedColumns = showColumns.map((showCol) => {
    const colData = columns.find(
      (dataCol) => dataCol.fieldNameCamelCase === showCol
    );

    switch (showCol) {
      case 'curatedQueryShareStatus':
        return {
          ...colData,
          minWidth: 110,
          resizable: true,
        };

      case 'curatedQueryShareToMemberType':
        return {
          ...colData,
          minWidth: 120,
          resizable: true,
        };
      case 'curatedQueryShareToTotalsMembers':
      case 'curatedQueryShareType':
      case 'gridName':
        return {
          ...colData,
          minWidth: 120,
          resizable: true,
        };
      case 'id':
        return { ...colData, minWidth: 110, resizable: true };
      case 'lastModified':
        return {
          ...colData,
          minWidth: 150,
          valueFormatter: formatMDYWithParam,
          resizable: true,
        };
      case 'lastModifiedBy':
        return {
          ...colData,
          minWidth: 170,
          resizable: true,
        };
      case 'name':
        return { ...colData, minWdith: 250, resizable: true };
      default:
        return colData;
    }
  });

  return checkboxColumn.concat(formattedColumns);
};

export function* getCuratedQueryGridColumnsSaga(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
    const { columns } = response;
    const formattedColumns = formatCuratedQuery(columns);
    yield put(actions.getCuratedQueryGridColumnsSuccess(formattedColumns));
  } catch (error) {
    yield put(actions.getCuratedQueryGridColumnsError(error));
  }
}

function* watchAll() {
  yield all([
    takeLatest(
      types.GET_MARKUP_MAINTENANCE_GRID_COLUMNS,
      getMarkupGridColumnsSaga
    ),
    takeLatest(types.CREATE_MARKUP, createMarkupSaga),
    takeLatest(types.CLONE_MARKUP, cloneMarkupSaga),
    takeLatest(types.GET_MARKUP_MAINTENANCE_LIST, getMarkupListSaga),

    takeLatest(types.GET_MARKUP_CONTENT_DETAIL, getMarkupContentDetailSaga),
    // Curated Query
    takeLatest(
      types.GET_CURATED_QUERY_SHORT_DETAIL,
      getCuratedQueryShortDetailSaga
    ),
    takeLatest(types.GET_CURATED_QUERY_LIST, getCuratedQueryListSaga),
    takeLatest(
      types.GET_CURATED_QUERY_GRID_COLUMNS,
      getCuratedQueryGridColumnsSaga
    ),
  ]);
}

export default watchAll;
