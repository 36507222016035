import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/maintenance';

import { DeleteOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { forwardTo } from 'utils/common/route';
import { notification } from 'antd';
import { dialogFunction } from 'common/components/index';
import * as helpMaintenanceSelectors from 'pages/maintenance/controllers/selectors';
import * as helpMaintenanceService from 'services/helpMaintenance';

const DeletePost = ({ intl, disabled }) => {
  const helpMaintenancePost = useSelector(
    helpMaintenanceSelectors.selectHelpMaintenancePost()
  );

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  const callApiDelete = async () => {
    const postIds = [helpMaintenancePost.idPost];

    try {
      const response = await helpMaintenanceService.deleteHelpPosts({
        postIds,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        forwardTo(`/maintenance/help/post`);
        showSuccessMessageDialog();
      } else {
        callApiErrorHandler(message);
      }
    } catch (error) {
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  const label = {
    id: 'Taco.maintenance.help.ribbon.deletePost',
    defaultMessage: intl.formatMessage(Messages.deletePost),
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={label}
      onClick={confirmDelete}
      disabled={disabled}
    />
  );
};

export default injectIntl(DeletePost);
