import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import * as wfTemplateGridSelectors from 'pages/workflow-template/controllers/selectors';
import { forwardTo } from 'utils/common/route';

const OpenWorkflow = (props) => {
  const dispatch = useDispatch();

  const listSelected = useSelector(
    wfTemplateGridSelectors.makeSelectItemsSelected()
  );

  const onRedirect = () => {
    forwardTo(
      `/workflow-maintenance/definition/${listSelected[0]?.mainWorkflowId}`
    );
  };

  return (
    <RibbonButton
      icon={<ExportOutlined />}
      label='Open Workflow'
      onClick={onRedirect}
      disabled={!(listSelected.length === 1)}
    />
  );
};

export default OpenWorkflow;
