import React, { useMemo } from 'react';

import { Col, Typography, List, Tag, Row } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import { TAG_COLORS } from 'static/Constants';

import { FormDeleteButton } from 'common/components';

import './index.less';

const messagesError = [
  {
    title: 'Alphabet characters',
  },
  {
    title: 'Number characters',
  },
  {
    title: 'Underscore ( _ ) ',
  },
  {
    title: 'Dash ( - )',
  },
];

const ValidateFieldNameMapping = () => {
  return (
    <Col offset={6}>
      <Typography.Text strong style={{ color: '#ff4d4f' }}>
        Your field name should be allowed:
      </Typography.Text>
      <List
        className='validate-field-name__list'
        itemLayout='horizontal'
        bordered={false}
        dataSource={messagesError}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<InfoCircleOutlined />}
              title={<Typography.Text>{item.title}</Typography.Text>}
            />
          </List.Item>
        )}
      />
    </Col>
  );
};

const DisplayErrorsGlns = ({ selectedGlns, errors, deleteAll }) => {
  const existedGlns = useMemo(
    () => getListGlns(errors, selectedGlns),
    [errors, selectedGlns]
  );

  return (
    <Row>
      {existedGlns.length > 0 && (
        <Col style={{ marginBottom: 14 }} span={24}>
          <Typography.Text strong style={{ color: '#ff4d4f' }}>
            Have GLNs are already being linked
          </Typography.Text>
          <FormDeleteButton
            type='text'
            text='Clear All'
            size='small'
            danger
            style={{ marginLeft: 242, borderRadius: 0 }}
            onClick={deleteAll}
          />
        </Col>
      )}
      <Col style={{ marginBottom: 6 }} span={24}>
        {existedGlns.map((gln) => (
          <Tag
            color={getColorTag(gln)}
            key={gln}
            style={{
              marginBottom: 6,
            }}
          >
            GLN {gln}
          </Tag>
        ))}
      </Col>
    </Row>
  );
};

const getListGlns = (errors, selectedGlns) => {
  if (errors?.length > 0) {
    const glnsErrors = errors.map((error) => error.split(' ')[0]);
    return glnsErrors.filter((gln) => selectedGlns.includes(gln));
  }
  return [];
};

const getColorTag = (gln) => {
  if (gln[0] === '0') return TAG_COLORS[0];

  if (parseInt(gln.substring(0, 2)) % 2 === 0) return TAG_COLORS[1];

  if (parseInt(gln.substring(0, 2)) % 2 !== 0) return TAG_COLORS[2];
};

export { DisplayErrorsGlns };

export default ValidateFieldNameMapping;
