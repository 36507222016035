import React, { useState } from 'react';

import RibbonButton from 'common/components/button/RibbonButton';
import { FileAddOutlined } from '@ant-design/icons';

import AddHierarchyModal from '../../modal/product-hierarchy/AddHierarchyModal';

import Messages from 'i18n/messages/home';

const AddHierarchy = (props) => {
  const { sectionDisabled } = props;

  const [visible, setVisible] = useState(false);

  const onClickHandler = () => {
    setVisible(true);
  };

  const isDisabled = sectionDisabled;

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<FileAddOutlined style={{ height: 26 }} />}
        label={Messages.addHierarchy}
        onClick={onClickHandler}
        disabled={isDisabled}
      />
      <AddHierarchyModal visible={visible} setVisible={setVisible} isAdd />
    </>
  );
};

export default AddHierarchy;
