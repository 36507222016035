import React from 'react';
import PropTypes, { oneOfType, oneOf } from 'prop-types';
import { Tooltip, Button, Popover } from 'antd';
import { QuestionCircleOutlined, MoreOutlined } from '@ant-design/icons';

import IconItem from './IconItem';

import iconList from './assets';

import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/common';

import './IconList.less';

const IconList = (props) => {
  const {
    className,
    itemList,
    size,
    tooltipPosition,
    numberOfShowedItems,
    noId,
    intl,
  } = props;

  const itemListLength = itemList?.length;
  // items to show
  const showedItemList = numberOfShowedItems
    ? itemList?.slice(0, numberOfShowedItems)
    : itemList;

  const renderList = (list) => {
    return (
      <>
        {list?.length > 0 &&
          list.map((item, index) => {
            // find icon from assets icon list
            let mappedItem;
            if (noId)
              mappedItem = iconList?.find(
                (icon) =>
                  icon.segmentDescription?.toLowerCase()?.trim() ===
                  item?.toLowerCase()?.trim()
              );
            else if (typeof item == 'object' && item?.hasOwnProperty('name'))
              // cover the case iconList has package level icon when showing)
              mappedItem = item;
            else
              mappedItem = iconList?.find(
                (icon) => icon.segmentId === item.segmentId
              );

            const mappedIcon = mappedItem?.segmentIcon
              ? { type: 'image', src: mappedItem?.segmentIcon }
              : mappedItem?.antIcon
              ? { type: 'antIcon', src: mappedItem?.antIcon }
              : null;

            const itemStrinng = typeof item === 'string' ? item : undefined;

            const desc =
              item?.segmentDescription ||
              item?.description ||
              itemStrinng ||
              mappedItem?.segmentDescription;

            return (
              <Tooltip
                placement={tooltipPosition}
                key={item?.id || index}
                title={desc}
              >
                <div
                  className={`icon-list__item ${
                    size === 'small'
                      ? 'icon-list__item--small'
                      : size === 'large'
                      ? 'icon-list__item--large'
                      : ''
                  }`}
                  style={item?.wrapperStyle}
                >
                  <IconItem
                    mappedIcon={mappedIcon}
                    desc={desc}
                    style={item?.style}
                  />
                </div>
              </Tooltip>
            );
          })}
      </>
    );
  };

  const renderShowMore = () => {
    const shouldShowMore = numberOfShowedItems < itemListLength;
    const hiddenList = shouldShowMore
      ? itemList.slice(numberOfShowedItems)
      : [];
    const content = renderList(hiddenList);
    return shouldShowMore ? (
      <Popover
        content={content}
        placement='rightTop'
        trigger='focus'
        overlayClassName='icon-list__show-more-popup'
      >
        <Tooltip title={intl.formatMessage(Messages.iconListShowMore)}>
          <Button
            className='icon-list__show-more-button'
            type='text'
            icon={<MoreOutlined className='icon-list__show-more-icon' />}
            onClick={handleClickShowMoreIcon}
          ></Button>
        </Tooltip>
      </Popover>
    ) : null;
  };

  const handleClickShowMoreIcon = (e) => {
    // prevent show content pane
    e.stopPropagation();
  };

  return (
    <div className={`icon-list ${className !== undefined ? className : ''}`}>
      {renderList(showedItemList)}
      {renderShowMore()}
    </div>
  );
};

IconList.propTypes = {
  iconList: oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        segmentId: PropTypes.number,
        segmentDescription: PropTypes.string,
        isPublic: PropTypes.bool,
      })
    ),
    PropTypes.object,
  ]),
  /**
   * Number of Showed Items
   */
  numberOfShowedItems: PropTypes.number,
  size: oneOf(['small', 'default', 'large']),
  tooltipPosition: oneOf([
    'top',
    'left',
    'right',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'leftTop',
    'leftBottom',
    'rightTop',
    'rightBottom',
  ]),
  className: PropTypes.string,
  noId: PropTypes.bool,
};

export default injectIntl(IconList);
