import React from 'react';
import { Row } from 'antd';
import './AssetAssociations.less';
import AgGrid from 'common/components/ag-grid/AgGrid';
import * as endpoints from 'services/digitalAsset/endpoints';

const AssetAssociations = (props) => {
  const { associationColumn, assetData } = props;
  return (
    <Row className='asset-associations'>
      <AgGrid
        columnDefs={associationColumn}
        urlGrid={endpoints.GET_ASSET_ASSOCIATION_GRID}
        urlGridDistinct={endpoints.GET_ASSET_ASSOCIATION_GRID_DISTINCT}
        paramsGrid={{ id: assetData?.id }}
        responseParams='data'
      />
    </Row>
  );
};

AssetAssociations.propTypes = {};

export default AssetAssociations;
