import React, { useEffect } from 'react';

import { Button, Row, message, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { useMutation } from 'hooks';

import { deleteQuery } from 'services/query';

import { getGridName } from 'utils/getGridName';

const MutationGrid = (props) => {
  const {
    setEditedData,
    data,
    setVisible,
    setEditGrid,
    gridApi,
    turnOffModal,
  } = props;

  const dispatch = useDispatch();
  const { id, query } = data;
  const [{ status: statusDelete, error: errorDelete }, deleteConfig] =
    useMutation(deleteQuery);

  const gridName = getGridName(window.location.pathname);

  // const [
  //   { status: statusSetDefault, error: errorSetDefault },
  //   setDefaultGridConfig,
  // ] = useMutation(setDefaultGridConfigApi);

  const { queryConditions, columns, ribbonTypes } = JSON.parse(query);

  useEffect(() => {
    if (statusDelete === 'success') {
      message.success('Deleted successfully');

      setTimeout(() => {
        gridApi.rivirPurgeServerSideCache();
        setEditGrid(false);
        dispatch(
          actionsGridView.getGridQuery({
            gridName,
            pageIndex: 1,
            pageSize: 20,
          })
        );
      }, 500);
    }

    if (statusDelete === 'error') {
      message.success(errorDelete);
    }

    return;
  }, [errorDelete, gridApi, setEditGrid, statusDelete]);

  // useEffect(() => {
  //   if (statusSetDefault === 'success') {
  //     message.success('Set default successfully');
  //   }

  //   if (statusSetDefault === 'error') {
  //     message.success(errorSetDefault);
  //   }

  //   return;
  // }, [errorSetDefault, statusSetDefault]);

  const handleDeleteGridConfig = () => {
    deleteConfig({ listQueries: [id] });
  };

  const handleSetDefault = () => {
    // setDefaultGridConfig({ id, gridName });

    setTimeout(() => {
      setEditGrid(false);
      setVisible(false);
      turnOffModal();
    }, 500);

    dispatch(actionsGridView.chooseGridColumns(columns, id));
  };

  return (
    <Row className='grid-configuration__container-btn'>
      <Button
        type='primary'
        onClick={() => {
          setVisible(true);
          setEditedData(data);
        }}
        disabled={!data?.editable}
        className='grid-configuration__btn--edit'
      >
        Edit
      </Button>
      <Popconfirm
        placement='top'
        title='Do you want delete item?'
        onConfirm={handleDeleteGridConfig}
        okText='Yes'
        cancelText='No'
      >
        <Button type='primary' className='grid-configuration__btn--delete'>
          Delete
        </Button>
      </Popconfirm>

      {/* <Button
        type='default'
        disabled={isDefault || !data?.editable}
        onClick={handleSetDefault}
        className='grid-configuration__btn--isDefault'
      >
        Default
      </Button> */}
    </Row>
  );
};

export default MutationGrid;
