import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { AgGridDownloadOptions } from 'common/components';

import * as memberMaintenanceActions from 'pages/member-maintenance/controllers/actions';
import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';

import Messages from 'i18n/messages/maintenance';

const ExportButton = (props) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    memberMaintenanceSelectors.selectExportLoading()
  );

  const handleDownload = (fileType) => {
    //* trigger download member grid event
    const exportMemberEvent = new CustomEvent('exportMemberEvent', {
      detail: fileType,
    });
    document.dispatchEvent(exportMemberEvent);
    //*show loading
    dispatch(memberMaintenanceActions.showExportLoading());
  };

  return (
    <Dropdown
      overlay={
        <AgGridDownloadOptions
          handleDownload={handleDownload}
          loading={isLoading}
        />
      }
      placement='bottomLeft'
      style={{ backgroundColor: '#fff' }}
    >
      <RibbonButton icon={<DownloadOutlined />} label={Messages.exportMember} />
    </Dropdown>
  );
};

export default ExportButton;
