import React, { useRef } from 'react';

import { Col, Row } from 'antd';

import reactCSS from 'reactcss';

import { ProductPrimaryInfo } from 'pages/product-full-view/components';

import { useGetProductFullView } from 'hooks';

const QaSpecHeader = ({ isTabExpanded, productHeaderImageSize, productId }) => {
  const refPrimaryInfo = useRef(null);
  const { productFull } = useGetProductFullView({ productId });

  const productHeaderIndent = `${productHeaderImageSize?.width + 10}px`;
  const styles = reactCSS({
    default: {
      productPrimary: {
        paddingLeft: isTabExpanded ? productHeaderIndent : 0,
        transition: 'all 0.3s',
      },
      category: {
        width: 300,
        marginRight: 35,
      },
      styleLabel: {
        fontWeight: 'bold',
        padding: '0 10px',
        width: 100,
        display: 'inline-block',
        textAlign: 'right',
      },
      styleParagraph: {
        marginBottom: '0',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  });

  return (
    <div className='qa-spec__header' id='qa-spec-header-wrap'>
      <Row wrap={false} align='middle'>
        <Col style={styles.productPrimary} xs={15}>
          <ProductPrimaryInfo
            product={productFull}
            refPrimaryInfo={refPrimaryInfo}
          />
        </Col>
        <Col flex={1} />
      </Row>
    </div>
  );
};

export default QaSpecHeader;
