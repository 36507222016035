import produce from 'immer';
import {
  MODULE_NAME_NEED_TO_CHECK_VALIDATION,
  PROPERTY_NAME_NEED_TO_CHECK_VALIDATION,
} from '../../constants';

import { US_VALUE_LOWERCASE } from 'pages/qa-spec/components/qa-allergen/constants';

import {
  DEFAULT_US_ALLERGENS,
  DEFAULT_WW_ALLERGENS,
} from 'static/AllergenConstants';

export const checkAllergenModuleName = (moduleName) =>
  moduleName === MODULE_NAME_NEED_TO_CHECK_VALIDATION;

export const checkAllergenProperty = (indexField = []) =>
  indexField.includes(PROPERTY_NAME_NEED_TO_CHECK_VALIDATION);

// indexFields is [parentIndex, ..., changedField]
export const getChangedField = (indexField = []) => {
  return indexField[indexField.length - 1];
};

export const checkIsAllergenTypeCode = (field) => field === 'AllergenTypeCode';

export const checkIsRegion = (field) => field === 'Regional';

export const getNestedIndexField = (indexField, changedField) => {
  return indexField.slice(0, indexField.length - 1).concat(changedField);
};

export const checkChangedTypeCodeIsDefaultCode = ({
  valueRegion,
  valueAllergenTypeCode,
}) => {
  const isUS =
    !valueRegion || valueRegion?.toLowerCase() === US_VALUE_LOWERCASE;

  if (isUS)
    return DEFAULT_US_ALLERGENS.includes(valueAllergenTypeCode?.toLowerCase());

  return DEFAULT_WW_ALLERGENS.includes(valueAllergenTypeCode?.toLowerCase());
};

export const getFormValuesAfterUpdatingValueField = ({
  formValues,
  nestedIndex,
  propertyName,
  value,
}) => {
  return produce(formValues, (draftState) => {
    nestedIndex.forEach((idx) => {
      if (idx === propertyName) {
        draftState[`${idx}`] = value;
      } else {
        draftState = draftState[idx];
      }
    });
  });
};

export const getParentIndexToAllergen = (indexField) => {
  let result = [];

  for (const idx of indexField) {
    result.push(idx);
    if (idx === 'Allergen') return result;
  }

  return result;
};

export const checkIsUSRegion = (region) => {
  return !region || region?.toLowerCase() === US_VALUE_LOWERCASE;
};

export const separateAllergenTypeRegion = (allergenValues) => {
  let usAllergenTypes = [];
  let wwAllergenTypes = [];

  allergenValues?.forEach((val) => {
    if (checkIsUSRegion(val?.Regional)) {
      usAllergenTypes.push(val?.AllergenTypeCode);
    } else {
      wwAllergenTypes.push(val?.AllergenTypeCode);
    }
  });

  return {
    usAllergenTypes,
    wwAllergenTypes,
  };
};

export const getIdxChangedAllergenTypeCode = (indexField) => {
  let result = null;

  for (const idx of indexField) {
    if (typeof idx === 'number') {
      result = idx;
    }
    if (idx === 'AllergenTypeCode') return result;
  }

  return result;
};

export const checkDuplicationAllergenCodeInSameRegion = ({
  indexAllergenTypeField,
  changedAllergenType,
  formInstance,
  valueRegion,
}) => {
  const indexAllergen = getParentIndexToAllergen(indexAllergenTypeField);
  const idxChangedAllergenTypeCode = getIdxChangedAllergenTypeCode(
    indexAllergenTypeField
  );

  const allergenValues = formInstance.getFieldValue(indexAllergen) ?? [];
  const restAllergenValues = [
    ...allergenValues.slice(0, idxChangedAllergenTypeCode),
    ...allergenValues.slice(idxChangedAllergenTypeCode + 1),
  ];

  const { usAllergenTypes, wwAllergenTypes } =
    separateAllergenTypeRegion(restAllergenValues);

  const isUS = checkIsUSRegion(valueRegion);

  return isUS
    ? usAllergenTypes.includes(changedAllergenType)
    : wwAllergenTypes.includes(changedAllergenType);
};

export const checkFormHasError = (formFields) =>
  formFields.some((field) => field.error.length > 0);
