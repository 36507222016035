import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SyncOutlined } from '@ant-design/icons';
import { RibbonButton, CustomNotification } from 'common/components';

import * as productFullViewActions from 'pages/product-full-view/controllers/actions';
import * as productFullViewSelectors from 'pages/product-full-view/controllers/selectors';

import * as mappingServices from 'services/mapping';

import { sleep } from 'utils/delay';
import { useHandleReloadPage } from 'hooks/useReloadPage';

const MappingInitSyncSection = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [__, setReloadPage] = useHandleReloadPage();

  const selectedMappingSupportMem = useSelector(
    productFullViewSelectors.makeSelectSelectedMappingSupportMem()
  );

  const mappingMetaInfo = useSelector(
    productFullViewSelectors.makeSelectMappingMetadata()
  );

  const productHierarchyInfo = useSelector(
    productFullViewSelectors.makeSelectCurrentHierarchy()
  );

  const mappingId = mappingMetaInfo?.data?.mappingId;
  const hierarchyId = productHierarchyInfo?.productHierarchyId;

  const handleClick = async () => {
    if (selectedMappingSupportMem.length === 0) {
      return;
    }

    try {
      setLoading(true);

      const respone = await mappingServices.initProductMapping({
        hierarchyId,
        mappingIds: [mappingId],
        memberIds: selectedMappingSupportMem,
      });

      setLoading(false);
      const { isSuccess } = respone;

      if (isSuccess) {
        CustomNotification.success('Successfully init product mapping');
        const params = {
          mappingId,
          hierarchyId,
        };

        await sleep(2000);
        dispatch(productFullViewActions.getMappingMetadata(params));
        dispatch(productFullViewActions.reloadMappingSupportMemGrid());
        setReloadPage();
      }
    } catch (e) {
      setLoading(false);
      CustomNotification.error('Fail to init product mapping!');
    }
  };

  return (
    <RibbonButton
      size='small'
      icon={<SyncOutlined style={{ height: 26 }} />}
      label='Init mapping'
      onClick={handleClick}
      loading={loading}
      disabled={selectedMappingSupportMem?.length <= 0 || !hierarchyId}
      className='button-item-dropdown'
    />
  );
};

export default MappingInitSyncSection;
