export const enableCountry = [
    'US',
    'FR',
    'ES',
    'PT',
    'DE',
    'IN',
    'IT',
    'RU',
    'IR',
    'CN',
  ];
  
  export const customLabels = {
    US: 'English',
    FR: 'French',
    ES: 'Spanish',
    PT: 'Portuguese',
    DE: 'German',
    IN: 'Hindil',
    IT: 'Italian',
    RU: 'Russian',
    IR: 'Persian',
    CN: 'Chinese',
  };
  