import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Avatar, Tooltip, Popover, Menu, Typography, Tag } from 'antd';
import {
  CommentOutlined,
  UserOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { NewMsgReceive } from '../index';

import * as chatActions from '../../controller/actions';
import * as globalSelectors from 'redux/global/selectors';
import * as chatSelectors from '../../controller/selectors';
import * as _ from 'lodash';
import { dialogFunction } from 'common/components';
import { findAccount } from '../fields/utils';
import userSelectors from 'redux/user/selectors';

import './ChatMinimizeItem.less';

const { Text } = Typography;

const mockAvatar =
  'https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png';

const ChatMinimizeItem = (props) => {
  const dispatch = useDispatch();
  const {
    isToggle,
    isShowMore,
    communicationItemData,
    threadItem,
    minimizedChatThreadList,
    hiddenCount,
    hiddenList,
    hasNewMsg,
    newMsg,
    toggleChatBox,
    onCloseChatView,
    notifyCount,
    isMainChatMinimize,
    view,
    setView,
  } = props;
  const { Title } = Typography;

  const [isFocus, setIsFocus] = useState(false);
  const [hasNewMsgNotify, setHasNewMsgNotify] = useState(false);
  const [visible, setVisible] = useState(false);
  const [threadId, setThreadId] = useState(null);

  const isActive =
    communicationItemData?.id === threadItem?.id && view === 'threadMsg';
  const newMsgInfo = useSelector(globalSelectors.selectNewMsgInfo());
  const threadStatus = useSelector(chatSelectors.selectStatusThreadInfo());
  const isShowWarningModal = useSelector(
    chatSelectors.selectIsShowWarningModal()
  );
  const threadInfoId = useSelector(chatSelectors.selectThreadId());
  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const { accountUser, accountCompany } = findAccount(
    threadItem?.users,
    userInfo?.id,
    userInfo?.member?.id
  );

  useEffect(() => {
    if (isShowWarningModal && threadStatus === 'error' && threadInfoId) {
      handleRemoveThread();
      dispatch(chatActions.updateShowThreadWarningModal(false));
    }
  }, [threadStatus]);

  const handleRemoveThread = (dropData) => {
    dialogFunction({
      type: 'warn',
      content: <Title level={5}>This thread is not available for you.</Title>,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => {
        dispatch(chatActions.removeThread(threadInfoId));
      },
    });
  };

  useEffect(() => {
    if (
      communicationItemData &&
      minimizedChatThreadList?.findIndex(
        (thread) => thread.id === communicationItemData.id
      ) > -1 &&
      view === 'threadMsg'
    ) {
      removeMinimizedThread(communicationItemData);
    }
  }, [JSON.stringify(communicationItemData), view]);

  // Show dot when there is a new message
  useEffect(() => {
    if (!_.isEmpty(newMsgInfo)) {
      if (threadItem?.id === newMsgInfo?.threadId) {
        setHasNewMsgNotify(true);
      }
    }
  }, [JSON.stringify(newMsgInfo)]);

  //* function
  const openChatThread = () => {
    if (!toggleChatBox) {
      onClickChatBoxToggleBtn();
    }
    dispatch(chatActions.getThreadInfoSuccess(threadItem));
  };

  const removeMinimizedThread = (removeThread) => {
    const clonedList = cloneDeep(minimizedChatThreadList);

    const newList = clonedList.filter((thread) => {
      return thread.id !== removeThread.id;
    });

    dispatch(chatActions.minimizeChatThread(newList));
  };

  const removeHiddenThread = () => {
    const clonedList = cloneDeep(minimizedChatThreadList);
    const hiddenIdList = hiddenList?.map((hiddenItem) => {
      return hiddenItem.id;
    });
    const newList = clonedList?.filter((thread) => {
      return !hiddenIdList?.includes(thread.id);
    });

    dispatch(chatActions.minimizeChatThread(newList));
  };

  //* event
  const onMouseEnterHandler = () => {
    setIsFocus(true);
  };

  const onMouseLeaverHandler = () => {
    setIsFocus(false);
  };

  const onClickChatBoxToggleBtn = () => {
    dispatch(chatActions.toggleChatBox());
    setHasNewMsgNotify(false);
  };

  const onClickChatThread = () => {
    if (!isActive) {
      setView('threadMsg');
      openChatThread();
      removeMinimizedThread(threadItem);
      if (accountUser) getThreadInfo(threadItem.id, accountUser?.userId);
      if (accountCompany) getThreadInfo(threadItem.id, accountCompany?.userId);
    }
  };

  const onClickHandler = () => {
    if (isShowMore) return;

    if (isToggle) {
      onClickChatBoxToggleBtn();
      return;
    }
    onClickChatThread();
  };

  const onFocusHandler = () => {
    if (!isShowMore) return;

    setVisible(true);
  };

  const onClickCloseBtnHandler = () => {
    if (isToggle) {
      onCloseChatView();
      return;
    }
    if (isShowMore) {
      removeHiddenThread();
      return;
    }
    removeMinimizedThread(threadItem);
  };

  const onClickHiddenThread = ({ item }) => {
    const {
      props: { data },
    } = item;

    setVisible(false);

    if (communicationItemData?.id !== data?.id) {
      removeMinimizedThread(data);
      const foundAccounts = findAccount(
        data?.users,
        userInfo?.id,
        userInfo?.member?.id
      );

      if (foundAccounts?.accountUser)
        getThreadInfo(data.id, foundAccounts?.accountUser?.userId);
      if (foundAccounts?.accountCompany)
        getThreadInfo(data.id, foundAccounts?.accountCompany?.userId);
      setThreadId(data.id);
    }
  };

  const getThreadInfo = (threadId, userId) => {
    dispatch(chatActions.getThreadInfo(threadId, userId));
    dispatch(chatActions.getThreadMessages(threadId));
  };

  //* render
  const Icon = isToggle ? CommentOutlined : UserOutlined;

  const threadBtnClassName = classnames({
    'chat__minimize-thread-btn': true,
    'chat__minimize-thread-btn--active': isActive,
    'chat__minimize-btn': true,
  });

  const toggleBtnClassName = classnames({
    'chat__minimize-toggle-btn': true,
    'chat__minimize-btn': true,
  });

  const className = isToggle ? toggleBtnClassName : threadBtnClassName;

  const closeItemBtn = (
    <CloseCircleFilled
      className='chat__minimize-close-btn'
      onClick={onClickCloseBtnHandler}
    />
  );

  const renderMoreList = () => {
    return hiddenCount && isShowMore ? (
      <Menu className='chat__minimize-more-menu' onClick={onClickHiddenThread}>
        {hiddenList?.map((thread, index) => (
          <Menu.Item key={thread.id || index} data={thread}>
            <Text className='chat__minimize-more-menu-title' ellipsis strong>
              {thread.name}{' '}
            </Text>{' '}
            {/* <Tag color='#f5222d' style={{ borderRadius: 10 }}>
              99
            </Tag> */}
          </Menu.Item>
        ))}
      </Menu>
    ) : null;
  };

  const mainChatMinimize = isMainChatMinimize
    ? notifyCount
    : hasNewMsgNotify
    ? false
    : null;

  const count = isFocus ? closeItemBtn : mainChatMinimize;

  return (
    <Popover
      overlayClassName={
        isShowMore && visible
          ? 'chat__minimize-more-popup'
          : 'chat__minimize-more-popup--hidden'
      }
      content={renderMoreList()}
      placement='left'
      trigger='click'
      visible={isShowMore && visible}
    >
      <div className='chat__minimize-more-popup__area'>
        <Tooltip placement='left' title={threadItem?.name}>
          <div
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaverHandler}
          >
            <Badge
              count={count}
              offset={[-5, 5]}
              overflowCount={99}
              style={{ zIndex: 3 }}
            >
              <Avatar
                className={className}
                src={
                  !isToggle &&
                  !isShowMore &&
                  (threadItem?.avatarUrl || mockAvatar)
                }
                size={48}
                icon={!hiddenCount && <Icon />}
                onClick={onClickHandler}
                onFocus={onFocusHandler}
                tabIndex={100}
              >
                {hiddenCount ? `+${hiddenCount}` : null}
              </Avatar>
            </Badge>
          </div>
        </Tooltip>
        {!isToggle && (
          <NewMsgReceive
            hasNewMsg={hasNewMsg}
            newMsg={newMsg}
            threadId={threadItem?.id}
          />
        )}
      </div>
    </Popover>
  );
};

export default ChatMinimizeItem;
