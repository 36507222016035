import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectEmail = (state) => state.email || initialState;

const selectEmailLoading = () =>
  createSelector(selectEmail, (state) => state.loading);

const selectEmailThreadList = () =>
  createSelector(selectEmail, (state) => state.emailThreadList);

const selectEmailThreadInfo = () =>
  createSelector(selectEmail, (state) => state.emailThreadInfo);

const selectEmailThreadInfoContent = () =>
  createSelector(selectEmail, (state) => state.emailThreadInfoContent);

const selectEmailActiveThreadInfo = () =>
  createSelector(selectEmail, (state) => state.emailActiveThreadInfo);

const selectEmailAccount = () =>
  createSelector(selectEmail, (state) => state.accountMail);

const selectToggleNewMailMessage = () =>
  createSelector(selectEmail, (state) => state.toggleNewMailMessage);
const selectSelectedSearchResult = () =>
  createSelector(selectEmail, (subState) => subState.selectedSearchResult);

const selectFinishLoadDown = () =>
  createSelector(selectEmail, (subState) => subState.isFinishLoadDown);

const selectSelectedSearchResultPosition = () =>
  createSelector(
    selectEmail,
    (subState) => subState.selectedSearchResultPosition
  );

const selectLastMessage = () =>
  createSelector(selectEmail, (subState) => subState.lastMessage);

export {
  selectEmailThreadList,
  selectEmailLoading,
  selectEmailThreadInfo,
  selectEmailThreadInfoContent,
  selectEmailActiveThreadInfo,
  selectEmailAccount,
  selectToggleNewMailMessage,
  selectSelectedSearchResult,
  selectSelectedSearchResultPosition,
  selectFinishLoadDown,
  selectLastMessage,
};
