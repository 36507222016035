import DefaultLandingPageLogo from 'assets/landing-page/default-landing-page.png';
import DefaultBackgroundImage from 'assets/login-introduction.png';
import DefaultImage from 'assets/asset-icons/image-thumbnail.jpg';

export const INIT_PAGE_EDITOR_DATA = {
  components: [],
  componentDetails: {},
};

export const EDIT_TYPE = {
  ALL_TYPES: [
    'background',
    'markup',
    'markupText',
    'upload-media',
    'form',
    'default',
  ],
  BACKGROUND: 'background',
  TEXT: 'text',
  IMG: 'image',
  VIDEO: 'video',
  YOUTUBE: 'youtube',
  FORM: 'form',
  UPLOAD: 'upload-media',
  DEFAULT: 'default',
};

export const COMPONENT_TYPE = {
  ALL_TYPES: ['image', 'video', 'text'],
  IMAGE: 'image',
  VIDEO: 'video',
  YOUTUBE: 'youtube',
  TEXT: 'text',
  BACKGROUND: 'background',
};

export const EDIT_TYPE_DEFAULT = EDIT_TYPE.DEFAULT;

export const UNSELECTABLE_TABS = [
  EDIT_TYPE.TEXT,
  EDIT_TYPE.VIDEO,
  EDIT_TYPE.IMG,
];

export const BACKGROUND_CONFIG_DEFAULT = {
  src: DefaultLandingPageLogo,
  left: 0,
  top: 0,
  transOriginX: 0,
  transOriginY: 0,
  transOriginOffest: 0,
  scale: 1.05,
};

export const NEW_BACKGROUND_DATA = {
  top: 0,
  left: 10,
  scale: 1,
  type: COMPONENT_TYPE.BACKGROUND,
};

export const NEW_IMAGE_DATA = {
  src: DefaultImage,
  width: 100,
  radius: 0,
  borderWidth: 0,
  borderColor: '',
  type: COMPONENT_TYPE.IMAGE,
};

export const NEW_YOUTUBE_DATA = {
  src: '',
  width: 200,
  radius: 5,
  borderWidth: 0,
  borderColor: '',
  type: COMPONENT_TYPE.YOUTUBE,
};

export const NEW_TEXT_DATA = {
  textColor: '',
  textSize: 13,
  textBorderWidth: 0,
  textBorderColor: '',
  textShadow: null,
  textBackground: null,
  textRadius: 0,
  textPadding: 0,
  textWeight: 400,
  textFont: null,
  text: '',
  type: COMPONENT_TYPE.TEXT,
};

export const CREATE_NEW_STATUS = {
  START: 'START',
  DRAGGING: 'DRAGGING',
  ALLOW_CREATE_NEW: 'ALLOW',
  FAIL: 'FAIL',
  DONE: 'DONE',
};

export const TOOL_TAIL_ID = 'page-editor-tool';
export const WRAP_TAIL_ID = 'page-editor-wrap';

export const MOVE_PERCENT_STEP = 1;
export const TOLERANCE_SIZE = 5;
