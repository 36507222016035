import {
  useEffect,
  useState,
  useRef,
  useContext,
  useImperativeHandle,
} from 'react';

import { mapToComponentByType } from '../mappers';
import { useWindowSize } from 'hooks/windowSize';

import DefaultImage from 'assets/asset-icons/image-thumbnail.jpg';

import {
  getDistanceBetweenElements,
  getCoverRangeComponent,
  checkComponentInside,
  $id,
} from 'utils/element';

import {
  TOOL_TAIL_ID,
  WRAP_TAIL_ID,
  INIT_PAGE_EDITOR_DATA,
  COMPONENT_TYPE,
  CREATE_NEW_STATUS,
  TOLERANCE_SIZE,
} from '../constants';

import { sleep } from 'utils/delay';

import { DragContext } from 'common/components/drapDropWrap/drag-drop-pos/context/DragContext';

/**
 * ! hooks to filter component data
 * @param {*} componentData
 * @param {*} componentType
 */
const useGetComponentByType = (componentData, componentType) => {
  const [filteredComponentData, setFilteredComponentData] = useState({});

  useEffect(() => {
    //* filter component by type from component data
    const getComponentByType = () => {
      const nextComponentData = mapToComponentByType(
        componentData,
        componentType
      );
      setFilteredComponentData(nextComponentData);
    };

    getComponentByType();
  }, [componentData, componentType]);

  return filteredComponentData;
};

/**
 * ! get init position of new component
 * @param {*} param
 */
const useGetNewComponentPosition = (wrapKey, componentType, scale = 1) => {
  const [screenWidth, screenHeight] = useWindowSize();

  const refWrap = useRef();
  const refTool = useRef();

  const [newComponentPos, setNewComponentPosition] = useState({});

  useEffect(() => {
    if (!refWrap.current && !refTool.current) {
      refWrap.current = document.getElementById(`${wrapKey}_${WRAP_TAIL_ID}`);
      refTool.current = document.getElementById(`${wrapKey}_${TOOL_TAIL_ID}`);
    }
  }, [wrapKey]);

  useEffect(() => {
    if (screenWidth && screenHeight) {
      const distance = getDistanceBetweenElements(
        refWrap.current,
        refTool.current
      );

      const { size: wrapSize } = getCoverRangeComponent(refWrap.current, scale);

      const ADJUST_LEFT =
        componentType === COMPONENT_TYPE.IMAGE
          ? -60
          : componentType === COMPONENT_TYPE.YOUTUBE
          ? -115
          : componentType === COMPONENT_TYPE.TEXT
          ? -60
          : 0;

      const ADJUST_TOP =
        componentType === COMPONENT_TYPE.IMAGE
          ? 30
          : componentType === COMPONENT_TYPE.YOUTUBE
          ? 10
          : componentType === COMPONENT_TYPE.TEXT
          ? 42
          : 0;

      const newPosition = {
        left: wrapSize?.width / 2 - distance?.[0] / scale + ADJUST_LEFT,
        top: wrapSize?.height / 2 - distance?.[1] / scale + ADJUST_TOP,
      };

      setNewComponentPosition(newPosition);
    }
  }, [screenWidth, screenHeight]);

  return newComponentPos;
};

/**
 * ! return function to change component layer to top
 * @param {*} wrapKey
 * @returns
 */
const useTogglePageWrapLayer = (wrapKey) => {
  const togglePageWrapLayer = (status) => {
    const $pageEditorWrap = document.getElementById(
      `${wrapKey}_${WRAP_TAIL_ID}`
    );

    if (!$pageEditorWrap) return;

    if (status) {
      $pageEditorWrap.classList.add('allow-element-lie-on-tool');
    } else {
      $pageEditorWrap.classList.remove('allow-element-lie-on-tool');
    }
  };

  return togglePageWrapLayer;
};

const useChangeComponentLayerToTopWhenDragging = (wrapKey) => {
  const isDragging = useContext(DragContext);

  const togglePageWrapLayer = useTogglePageWrapLayer(wrapKey);

  useEffect(() => {
    //* when dragging change component layer to the top
    const changeComponentLayerTopTop = () => {
      if (isDragging) {
        togglePageWrapLayer(true);
      } else {
        togglePageWrapLayer(false);
      }
    };

    changeComponentLayerTopTop();
  }, [isDragging]);
};

/**
 * ! change detail show target when select other component
 * @param {*} selectedHook
 * @param {*} detailShowTargetHook
 */
const useChangeDetailShowWhenSelectOther = (
  selectedHook,
  detailShowTargetHook,
  pageDataHook,
  editTypeHook
) => {
  const [selected] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const [pageData, setPageData, refPageData] = pageDataHook;
  const [editType, setEditType] = editTypeHook;

  useEffect(() => {
    //* change detail target if change select
    const changeDetailTargetWhenSelectOther = () => {
      if (selected?.id && detailShowTarget) {
        if (detailShowTarget !== selected?.id) {
          const componentType =
            refPageData.current?.componentDetails?.[selected?.id]?.type;

          setDetailShowTarget(selected?.id);
          setEditType(componentType);
        }
      }
    };
    changeDetailTargetWhenSelectOther();
  }, [selected?.id, detailShowTarget]);
};

/**
 * ! set init data for page editor for the first time if available
 * @param {*} initPageData
 * @param {*} pageDataHook
 */
const useSetInitPageData = (initPageData, pageDataHook) => {
  const [allowInit, setAllowInit] = useState(true);
  const [__, setPageData] = pageDataHook;

  useEffect(() => {
    //* init data for page editor if data is not set
    const initPageEditorData = () => {
      if (allowInit && initPageData?.components) {
        setPageData(initPageData);
        setAllowInit(false);
      } else if (allowInit && !initPageData?.components && initPageData) {
        //* wrong format should create default empty page data
        setPageData(INIT_PAGE_EDITOR_DATA);
        setAllowInit(false);
      }
    };

    initPageEditorData();
  }, [initPageData]);
};

/**
 * ! embedded instance
 * @param {} ref
 * @param {*} param1
 */
const usePageEditorInstance = (ref, methods) => {
  useImperativeHandle(ref, () => ({
    setPageData: methods?.setPageData,
    getPageData: methods?.getPageData,
    handleCloseForm: methods?.handleCloseForm,
  }));

  // useEffect(() => {
  //   return () => {
  //     ref.current = null;
  //   };
  // }, []);
};

const useRemoveNewComponentOutSizeContainer = (
  createStatusHook,
  isDragging,
  newKey,
  componentHandlers
) => {
  const [createStatus, setCreateStatus] = createStatusHook;
  const { onDeleteComponent } = componentHandlers;

  useEffect(() => {
    const checkFailDraggingNewComponent = () => {
      if (!isDragging && createStatus === CREATE_NEW_STATUS.DRAGGING) {
        setCreateStatus(CREATE_NEW_STATUS.FAIL);
      }
      if (!isDragging && createStatus === CREATE_NEW_STATUS.ALLOW_CREATE_NEW) {
        setCreateStatus(CREATE_NEW_STATUS.DONE);
      }
    };

    checkFailDraggingNewComponent();
  }, [isDragging, createStatus, newKey]);

  useEffect(() => {
    //* remove component when fail
    const removeFailComponent = () => {
      if (createStatus === CREATE_NEW_STATUS.FAIL) {
        setCreateStatus(CREATE_NEW_STATUS.DONE);
        onDeleteComponent({ key: newKey });
      }
    };

    removeFailComponent();
  }, [createStatus, newKey]);
};

/**
 * ! adjust component position when deps change
 * @param {*} param0
 */
const useAdjustPositionWhenSizeChange = ({
  wrapId,
  componentId,
  onChangeComponentValue,
  fieldNameX,
  fieldNameY,
  scale,
  deps,
}) => {
  const refWrap = useRef();
  const refComponnet = useRef();

  useEffect(() => {
    //* extract component to ref;
    const getComponnetToRef = () => {
      if (!refWrap.current || !refComponnet.current) {
        refWrap.current = $id(wrapId);
        refComponnet.current = $id(componentId);
      }
    };

    getComponnetToRef();
  }, [wrapId, componentId]);

  useEffect(() => {
    //* adjust component position when size changed
    const adjustPosition = () => {
      if (refWrap.current && refComponnet.current) {
        const { offset, size } = getCoverRangeComponent(
          refComponnet.current,
          scale
        );
        const { size: wrapSize } = getCoverRangeComponent(
          refWrap.current,
          scale
        );
        const checkInsideResultArray = checkComponentInside({
          elements: [refComponnet.current],
          elementCover: refWrap.current,
          tolerance: TOLERANCE_SIZE,
        });

        if (size?.width === 0 && size?.height === 0) return;

        const { isInside, touchLeft, touchRight, touchTop, touchBottom } =
          checkInsideResultArray?.[0];

        !isInside &&
          touchLeft &&
          onChangeComponentValue({
            key: componentId,
            fieldName: fieldNameX,
            value: TOLERANCE_SIZE * 2,
          });

        !isInside &&
          touchTop &&
          onChangeComponentValue({
            key: componentId,
            fieldName: fieldNameY,
            value: TOLERANCE_SIZE * 2,
          });

        !isInside &&
          touchBottom &&
          onChangeComponentValue({
            key: componentId,
            fieldName: fieldNameY,
            value: wrapSize?.height - size?.height - TOLERANCE_SIZE * 2,
          });

        !isInside &&
          touchRight &&
          onChangeComponentValue({
            key: componentId,
            fieldName: fieldNameX,
            value: wrapSize?.width - size?.width - TOLERANCE_SIZE * 2,
          });
      }
    };

    adjustPosition();
  }, [...deps]);
};

/**
 * ! get wrap and component element from their ID
 * @param {*} wrapId
 * @param {*} componentId
 * @returns
 */
const useGet$wrapAnd$component = (wrapId, componentId) => {
  const refWrap = useRef();
  const refComponent = useRef();

  useEffect(() => {
    //* get wrap component and component
    const getWrapAndComponent = () => {
      if (!refWrap.current && wrapId) {
        refWrap.current = $id(wrapId);
      }

      if (!refComponent.current && componentId) {
        refComponent.current = $id(componentId);
      }
    };

    getWrapAndComponent();
  }, [wrapId, componentId]);

  return [refWrap, refComponent];
};

const useAdjustComponentSizeWhenChangeSrc = ({
  refComponent,
  refWrap,
  componentHandlers,
  componentKey,
  src,
  ratio,
}) => {
  const { onChangeComponentValue } = componentHandlers;

  const [isAdjustingSize, setIsAdjustingSize] = useState(false);

  useEffect(() => {
    //* handle adjust component size when change src and ratio
    const adjustSizeByChangeSrc = async () => {
      if (src && ratio && src !== DefaultImage) {
        const refComponentSize = {
          width: refComponent.current.offsetWidth,
          height: refComponent.current.offsetHeight,
        };

        if (refComponentSize.width === 0 && refComponentSize.height === 0) {
          return;
        }

        const checkInsideResultArray = checkComponentInside({
          elements: [refComponent.current],
          elementCover: refWrap.current,
          tolerance: TOLERANCE_SIZE,
        });

        const { isInside, touchRight, touchBottom, overRight, overBottom } =
          checkInsideResultArray?.[0];

        if (!isInside) {
          let nextSize;

          setIsAdjustingSize(true);

          const biasSize =
            refWrap.current.offsetWidth / refWrap.current.offsetHeight > ratio
              ? 'height'
              : 'width';

          if (touchRight && (!touchBottom || biasSize === 'width')) {
            const newWidth =
              refComponent.current.offsetWidth - overRight - TOLERANCE_SIZE * 2;
            nextSize = {
              width: refComponent.current.offsetWidth - overRight,
              height: newWidth / ratio,
            };
          }

          if (touchBottom && (!touchRight || biasSize === 'height')) {
            const newHeight =
              refComponent.current.offsetHeight -
              overBottom -
              TOLERANCE_SIZE * 2;

            nextSize = {
              width: newHeight * ratio,
              height: newHeight,
            };
          }

          await sleep(500);

          onChangeComponentValue({
            key: componentKey,
            fieldName: 'width',
            value: nextSize?.width,
          });

          await sleep(500);

          setIsAdjustingSize(false);
        }
      }
    };

    adjustSizeByChangeSrc();
  }, [src, ratio]);

  return [isAdjustingSize];
};

export {
  useGetComponentByType,
  useGetNewComponentPosition,
  useTogglePageWrapLayer,
  useChangeComponentLayerToTopWhenDragging,
  useChangeDetailShowWhenSelectOther,
  useSetInitPageData,
  usePageEditorInstance,
  useRemoveNewComponentOutSizeContainer,
  useAdjustPositionWhenSizeChange,
  useGet$wrapAnd$component,
  useAdjustComponentSizeWhenChangeSrc,
};
