import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Tooltip, Badge } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import * as chatActions from '../../controller/actions';

import './ScrollButton.less';

const ScrollButton = (props) => {
  const dispatch = useDispatch();
  const {
    toggleNewMessage,
    scrollToBottom,
    shouldShowScrollBtn,
    threadMessages,
    latestMessage,
    threadId,
  } = props;

  const onClickHandler = () => {
    const shouldReloadMessages = checkReloadMessages();
    if (shouldReloadMessages) {
      dispatch(chatActions.getThreadMessages(threadId));
      return;
    }
    scrollToBottom();
  };

  const checkReloadMessages = () => {
    if (!threadMessages || !latestMessage) return true;

    const latestMessageData = threadMessages.find((message) => {
      return message.messageId === latestMessage;
    });

    return !latestMessageData;
  };

  return (
    <Tooltip title='Scroll to last message'>
      <Badge
        className={classnames({
          'chat-scroll-button': true,
          'chat-scroll-button--show': shouldShowScrollBtn,
        })}
        dot={toggleNewMessage?.length > 0}
        offset={[-5, 5]}
      >
        <Button
          onClick={onClickHandler}
          shape='circle'
          icon={<DownOutlined />}
          size='large'
          className='chat-scroll-button__button'
        />
      </Badge>
    </Tooltip>
  );
};

export default ScrollButton;
