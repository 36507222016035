export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_FAIL = 'GET_ROLE_LIST_FAIL';

export const GET_AVAILABLE_PERMISSIONS = 'GET_AVAILABLE_PERMISSIONS';
export const GET_AVAILABLE_PERMISSIONS_SUCCESS =
  'GET_AVAILABLE_PERMISSIONS_SUCCESS';
export const GET_AVAILABLE_PERMISSIONS_FAIL = 'GET_AVAILABLE_PERMISSIONS_FAIL';

export const GET_COLUMNS_GRID_ROLE_USERS = 'GET_COLUMNS_GRID_ROLE_USERS';
export const GET_COLUMNS_GRID_ROLE_USERS_SUCCESS =
  'GET_COLUMNS_GRID_ROLE_USERS_SUCCESS';
export const GET_COLUMNS_GRID_ROLE_USERS_FAIL =
  'GET_COLUMNS_GRID_ROLE_USERS_FAIL';

export const GET_ROLE_PERMISSION_LIST = 'GET_ROLE_PERMISSION_LIST';
export const GET_ROLE_PERMISSION_LIST_SUCCESS =
  'GET_ROLE_PERMISSION_LIST_SUCCESS';
export const GET_ROLE_PERMISSION_LIST_FAIL = 'GET_ROLE_PERMISSION_LIST_FAIL';

export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';

export const CLEAR_ROLE_STATE = 'CLEAR_ROLE_STATE';

//* security matrix action
export const GET_MEMBER_ROLE_LIST = 'GET_MEMBER_ROLE_LIST';
export const GET_MEMBER_ROLE_LIST_SUCCESS = 'GET_MEMBER_ROLE_LIST_SUCCESS';
export const GET_MEMBER_ROLE_LIST_FAIL = 'GET_MEMBER_ROLE_LIST_FAIL';

export const GET_MEMBER_ROLE_PERMISSION_LIST =
  'GET_MEMBER_ROLE_PERMISSION_LIST';
export const GET_MEMBER_ROLE_PERMISSION_LIST_SUCCESS =
  'GET_MEMBER_ROLE_PERMISSION_LIST_SUCCESS';
export const GET_MEMBER_ROLE_PERMISSION_LIST_FAIL =
  'GET_MEMBER_ROLE_PERMISSION_LIST_FAIL';

export const SET_SELECTED_MEMBER_ROLE = 'SET_SELECTED_MEMBER_ROLE';

export const GET_ALL_MEMBER_PERMISSION_LIST = 'GET_ALL_MEMBER_PERMISSION_LIST';
export const GET_ALL_MEMBER_PERMISSION_LIST_SUCCESS =
  'GET_ALL_MEMBER_PERMISSION_LIST_SUCCESS';
export const GET_ALL_MEMBER_PERMISSION_LIST_FAIL =
  'GET_ALL_MEMBER_PERMISSION_LIST_FAIL';

export const SAVE_MEMBER_ROLE = 'SAVE_MEMBER_ROLE';

export const DELETE_MEMBER_ROLE = 'DELETE_MEMBER_ROLE';

export const GET_COLUMNS_GRID_ROLE_MEMBERS = 'GET_COLUMNS_GRID_ROLE_MEMBERS';
export const GET_COLUMNS_GRID_ROLE_MEMBERS_SUCCESS =
  'GET_COLUMNS_GRID_ROLE_MEMBERS_SUCCESS';
export const GET_COLUMNS_GRID_ROLE_MEMBERS_FAIL =
  'GET_COLUMNS_GRID_ROLE_MEMBERS_FAIL';

export const GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE =
  'GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE';
export const GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_SUCCESS =
  'GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_SUCCESS';
export const GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_FAIL =
  'GET_COLUMNS_GRID_MEMBERS_NOT_IN_ROLE_FAIL';

export const REMOVE_MEMBER_FROM_ROLE = 'REMOVE_MEMBER_FORM_ROLE';

export const defaultUserGridColumns = [
  {
    field: '',
    checkboxSelection: true,
    filter: false,
    suppressMenu: true,
    width: 50,
    resizable: false,
  },
  {
    suppressMenu: true,
    dataType: 'string',
    displayName: 'Full Name',
    fieldName: 'FullName',
    fieldNameCamelCase: 'fullName',
    allowFilter: true,
    allowSort: true,
    width: 150,
  },
  {
    suppressMenu: true,
    dataType: 'string',
    displayName: 'Email',
    fieldName: 'Email',
    fieldNameCamelCase: 'email',
    allowFilter: true,
    allowSort: true,
    flex: 1,
    minWidth: 200,
  },
  {
    suppressMenu: true,
    dataType: 'datetime',
    displayName: 'Date Assigned',
    fieldName: 'DateAssigned',
    fieldNameCamelCase: 'dateAssigned',
    allowFilter: true,
    allowSort: true,
    width: 150,
  },
  {
    suppressMenu: true,
    dataType: 'string',
    displayName: 'Assigned Name',
    fieldName: 'AssignedByName',
    fieldNameCamelCase: 'assignedByName',
    allowFilter: true,
    allowSort: true,
    width: 150,
  },
];

export const defaultNewUserGridColumns = [
  {
    field: '',
    checkboxSelection: true,
    filter: false,
    suppressMenu: true,
    width: 50,
    resizable: false,
  },
  {
    suppressMenu: true,
    dataType: 'string',
    displayName: 'Full Name',
    fieldName: 'FullName',
    fieldNameCamelCase: 'fullName',
    allowFilter: true,
    allowSort: true,
    width: 150,
  },
  {
    suppressMenu: true,
    dataType: 'string',
    displayName: 'Email',
    fieldName: 'Email',
    fieldNameCamelCase: 'email',
    allowFilter: true,
    allowSort: true,
    flex: 1,
    minWidth: 200,
  },
];
