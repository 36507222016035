import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CustomNotification } from 'common/components';

import { getKeySupplementPanels } from '../../multiple-panel/facts-panel/useQueryFactsPanel';
import { getKeyQaSpecSupplementPanels } from 'pages/qa-spec/components/qa-nutrition-data/hook';

import * as productServices from 'services/product';

import { sleep } from 'utils/delay';

const supplementFactsApi = {
  'qa-spec': productServices.saveQaSpecDataSupplementFacts,
  overview: productServices.updateProductSupplementPanel,
};

export const useAddSupplementLabel = ({ productId, typeView }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['add-label'],
    mutationFn: supplementFactsApi[typeView],
    onSuccess: async () => {
      await sleep(1000);
      queryClient.invalidateQueries({
        queryKey: getKeySupplementPanels(productId),
      });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecSupplementPanels(productId),
      });
    },
    onError: (error) => {
      CustomNotification.error('Something went wrong!');
    },
  });

  return mutation;
};
