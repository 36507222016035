import React from 'react';
import { Avatar } from 'antd';
import { Images } from 'config/assets';

const MemberLogo = (props) => {
  const logoSrc = props?.thumb300 || props?.thumb || Images.RIVIR_LOGO_DEFAULT;
  return (
    <Avatar
      src={logoSrc}
      size={24}
      style={{
        display: 'block',
        margin: '2px auto 0 auto',
      }}
    />
  );
};

export default MemberLogo;
