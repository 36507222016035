import React from 'react';

import { Tooltip, Button } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/common';

const ShowHideButton = (props) => {
  const intl = useIntl();

  const { show, ...rest } = props;

  const icon = show ? <EyeInvisibleOutlined /> : <EyeOutlined />;
  const tooltip = show ? Messages.tooltipShow : Messages.tooltipHide;

  return (
    <Tooltip placement='top' title={intl.formatMessage(tooltip)}>
      <Button icon={icon} type='link' {...rest} />
    </Tooltip>
  );
};

export default ShowHideButton;
