import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import { Row, Col } from 'antd';
import {
  PlusOutlined,
  PlusCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';

import { Form } from 'common/components';
import SupplementNutrientList from './SupplementNutrientList';
import SupplementFormButton from './SupplementFormButton';
import SupplementNutrientForm from './SupplementNutrientForm';
import SupplementIngredientForm from './SupplementIngredientForm';

import { getNameFieldSupplement } from 'pages/product-full-view/components/product-media-area/supplement-label/utils';

import { useScrollTo } from 'hooks/useScrollTo';

import { SUPPLEMENT_NUTRIENT_NAME, INITIAL_FORM } from '../../constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';

const SupplementNutrient = forwardRef((props, forwardedRef) => {
  const intl = useIntl();
  const form = Form.useFormInstance();

  const { name } = props;

  const ADD_NUTRIENT_NAME_PATH = ['_add', name];

  const originData = useRef();

  const modeRef = useRef({
    nutrients: 'view',
    ingredients: 'view',
  });

  const nutrients = Form.useWatch(name, form) || [];

  const nutrientAdded = Form.useWatch(ADD_NUTRIENT_NAME_PATH, form) || [];

  const [mode, setMode] = useState('view');

  const { scrollElRef, triggerScrollTo } = useScrollTo();

  const resetAddNutrientForm = () => {
    form.setFieldsValue({
      _add: {
        [name]: {
          ...INITIAL_FORM[name],
        },
      },
    });
  };

  useEffect(() => {
    const originalValue = form.getFieldsValue([name]);
    originData.current = originalValue[name];

    if (modeRef.current) {
      modeRef.current[name] = mode;
    }
  }, [mode]);

  const getOriginalDataSubmitOnEdit = () => {
    if (modeRef.current[name]?.includes('edit')) {
      
      const errorList = form.getFieldsError();
      const hasError = errorList.some((item) => item.errors.length > 0);

      if (hasError) return;

      form.setFieldsValue({ [name]: originData.current });
    }
  };

  const resetState = () => {
    resetAddNutrientForm();
    setMode('view');
  };

  const onClickAddNew = () => {
    setMode('add');
    resetAddNutrientForm();
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    const nameField = getNameFieldSupplement(name);
    form
      .validateFields([
        [...ADD_NUTRIENT_NAME_PATH, nameField],
        [...ADD_NUTRIENT_NAME_PATH, 'quantity'],
        [...ADD_NUTRIENT_NAME_PATH, 'dailyValue'],
      ])
      .then(() => {
        if (!nutrientAdded) return;
        const nutrientAddedForm = {
          ...nutrientAdded,
          quantityUom: !isNil(nutrientAdded?.quantity)
            ? nutrientAdded.quantityUom
            : null,
        };
        form?.setFieldsValue({ [name]: [...nutrients, nutrientAddedForm] });
        triggerScrollTo('bottom');
        resetAddNutrientForm();
        setMode('view');
      });
  };

  const AddNewButton = SupplementFormButton;
  const AddButton = SupplementFormButton;
  const CancelButton = SupplementFormButton;
  const isDisableAddNewButton = mode?.includes('edit');

  const NutrientForm =
    name === SUPPLEMENT_NUTRIENT_NAME.NUTRIENT
      ? SupplementNutrientForm
      : name === SUPPLEMENT_NUTRIENT_NAME.INGREDIENT
      ? SupplementIngredientForm
      : null;

  useImperativeHandle(forwardedRef, () => ({
    resetState,
    getOriginalDataSubmitOnEdit,
  }));

  return (
    <>
      <Form.List name={name}>
        {(_, { remove }) => {
          return (
            <div
              ref={scrollElRef}
              className='supplement-form__nutrient-list scroller--y'
              style={{
                maxHeight: `calc(100% - ${mode === 'add' ? 310 : 45}px)`,
              }}
            >
              <SupplementNutrientList
                fieldName={name}
                remove={remove}
                mode={mode}
                setMode={setMode}
              />
            </div>
          );
        }}
      </Form.List>

      <div className='supplement-form__add-new-nutrient'>
        {mode === 'add' ? (
          <NutrientForm fieldName={name} namePath={ADD_NUTRIENT_NAME_PATH}>
            <Row gutter={4}>
              <Col span={12}>
                <CancelButton
                  color='#f5222d'
                  icon={<StopOutlined />}
                  text={intl.formatMessage(Messages.supplementCancelButton)}
                  onClick={onClickCancel}
                />
              </Col>
              <Col span={12}>
                <AddButton
                  type='primary'
                  icon={<PlusCircleOutlined />}
                  text={intl.formatMessage(Messages.supplementAddButton)}
                  onClick={onClickAdd}
                />
              </Col>
            </Row>
          </NutrientForm>
        ) : (
          <AddNewButton
            icon={<PlusOutlined />}
            disabled={isDisableAddNewButton}
            color={!isDisableAddNewButton && '#0070cc'}
            text={intl.formatMessage(Messages.supplementAddNewButton)}
            onClick={onClickAddNew}
          />
        )}
      </div>
    </>
  );
});

export default SupplementNutrient;
