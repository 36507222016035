import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import * as _ from 'lodash';
import { AssetThumbnailCard } from 'pages/branded-assets/components';

function AssetThumbView(props) {
  const { thumbData, handleToggleAssetContext } = props;
  return (
    <React.Fragment>
      {!!thumbData && (
        <List
          grid={{
            gutter: 3,
          }}
          dataSource={thumbData}
          renderItem={(thumbItemData, ind) => (
            <List.Item>
              <AssetThumbnailCard
                key={
                  'asset-thumb-view__thumb-item' +
                  _.get(thumbItemData, 'id') +
                  ind
                }
                src={_.get(thumbItemData, 'imgUrl')}
                handleToggleAssetContext={handleToggleAssetContext}
              />
            </List.Item>
          )}
        />
      )}
    </React.Fragment>
  );
}

AssetThumbView.propTypes = {
  // ? thumbData -> data of thumb table
  thumbData: PropTypes.array,
};

export default React.memo(AssetThumbView);
