import React from 'react';

import { Select, Input } from 'antd';

/* 
  Add clear button in dropdown
*/

const WrapperSelect = ({ children, readOnly, value, ...otherProps }) => {
  if (otherProps?.mode === 'multiple' && otherProps?.value === null) {
    otherProps.value = [];
  }

  return (
    <>
      {readOnly ? (
        <Input value={value} readOnly />
      ) : (
        <Select value={value} allowClear showArrow {...otherProps}>
          {children}
        </Select>
      )}
    </>
  );
};

export default WrapperSelect;
