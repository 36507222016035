import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';

import { Row, Input, Skeleton, Typography, Pagination } from 'antd';

import ItemMap from './ItemMap';
import MarkerMap from './MarkerMap';
import useCustomScroll from 'hooks/useCustomScroll';

import * as api from 'config/axios';

import './GoogleMapViewUserLocation.less';
import { debounce } from 'lodash';

import * as constants from 'static/Constants';

const GoogleMapViewUserLocation = (props) => {
  const {
    currentFilter,

    loadingOffice,
    centerData,
    fetchOffice,
    totalOffice,
    memberId,
  } = props;
  const [loading, setLoading] = useState(false);
  // const [placesData, setPlacesData] = useState([]);
  // const [placesSearch, setPlacesSearch] = useState([]);
  const [places, setPlaces] = useState([]);
  const [mapId, setMapId] = useState(false);
  const [hoverMapId, setHoverMapId] = useState(false);
  const [search, setSearch] = useState('');
  const [pageCurrent, setPageCurrent] = useState(1);

  const [centerMap, setCenterMap] = useState([34.0522, -118.2437]);
  const [zoomMap, setZoomMap] = useState(0);
  const [mapLoaded, setMapLoaded] = useState(false);

  const addressRef = useRef(null);

  useEffect(() => {
    setLoading(true);

    const officePlace = [
      {
        ...centerData,
        show: false,
        hover: false,
      },
    ];

    setPlaces(officePlace);
    if (centerData) {
      setCenterMap([
        parseFloat(centerData.latitude || 0),
        parseFloat(centerData.longitude || 0),
      ]);
    }

    setLoading(false);
  }, [centerData]);

  useEffect(() => {
    if (loadingOffice) setLoading(true);
    setLoading(false);
  }, [loadingOffice]);

  const onBoundsChange = (center, zoom, bounds, marginBounds) => {
    setZoomMap(zoom);
  };

  const onChildClickCallback = (key, childProps) => {
    handleShowInfoAndSelectedItem(key, true);
  };

  const onClickItemMap = (item) => {
    handleShowInfoAndSelectedItem(item.id);
  };

  const onCloseInfoWindow = () => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((val) => {
      val.show = false;
      return val;
    });
    setPlaces(tempPlaces);
    setMapId(false);
  };

  const onMouseEnterItemMap = (item) => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((val) => {
      if (val.id === item.id) {
        val.hover = true;
      } else {
        val.hover = false;
      }
      return val;
    });
    setPlaces(tempPlaces);
  };

  const onMouseLeaveItemMap = (item) => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((val) => {
      val.hover = false;
      return val;
    });
    setPlaces(tempPlaces);
  };
  const handleShowInfoAndSelectedItem = (id, scrollSelected) => {
    let tempPlaces = [...places];
    let indexScroll = 0;
    tempPlaces = tempPlaces.map((val, index) => {
      if (val?.id?.toString() === id?.toString()) {
        setCenterMap([parseFloat(val.latitude), parseFloat(val.longitude)]);
        indexScroll = index;
        val.show = true;
      } else {
        val.show = false;
      }
      return val;
    });
    setPlaces(tempPlaces);
    setMapId(id);
    if (addressRef.current) addressRef.current.scrollTop = indexScroll * 126;
  };

  const handleApiLoaded = (map, maps) => {
    setMapLoaded(true);
  };

  return (
    <Row
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Row
        style={{
          flex: 3,
        }}
      >
        <GoogleMapReact
          defaultZoom={12}
          center={centerMap}
          bootstrapURLKeys={{
            key: constants.GOOGLE_MAP_API_KEY,
          }}
          //use this api to open google map for testing in local
          // bootstrapURLKeys={{
          //   key: 'AIzaSyDqSn7TgIgTfPIFn1neZTz_TIYyfwU-r48',
          // }}
          onChildClick={onChildClickCallback}
          onBoundsChange={onBoundsChange}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          key={mapLoaded}
        >
          {places &&
            mapLoaded &&
            places.length > 0 &&
            places.map((place) => {
              if (place) {
                return (
                  <MarkerMap
                    key={place.id}
                    lat={place.latitude || 0}
                    lng={place.longitude || 0}
                    show={place.show}
                    hover={place.hover}
                    place={place}
                    style={{ width: 27, height: 43 }}
                    zoomMap={zoomMap}
                    onCloseInfoWindow={onCloseInfoWindow}
                  />
                );
              }
            })}
        </GoogleMapReact>
      </Row>
    </Row>
  );
};

export default GoogleMapViewUserLocation;
