import React from 'react';
import { useDispatch } from 'react-redux';

import { Dropdown, Menu } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { RibbonButton } from 'common/components';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { useFilterGrid } from 'hooks/useFilterGrid';
import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';

import { FILTER_TYPE_USER_MAINTENANCE } from 'static/Constants';

import Messages from 'i18n/messages/maintenance';

const FilterUsersSection = () => {
  const dispatch = useDispatch();

  const { gridFilters, updateGridFilter } = useFilterGrid({
    entityType: 'userMaintenance',
  });

  const { label, icon, toggle } = useDisplayFilterButton({
    filterOptions: FILTER_TYPE_USER_MAINTENANCE,
    filters: gridFilters.filter((filterItem) => {
      return FILTER_TYPE_USER_MAINTENANCE.find(
        (FILTER_ITEM) => FILTER_ITEM.value === filterItem
      );
    }),
  });

  const handleFilterUsers = (filter) => {
    dispatch(actionsGridView.resetPageCurrent());

    if (gridFilters.indexOf(filter) > -1) {
      updateGridFilter(gridFilters.filter((val) => val !== filter));
    } else {
      let newFilterProducts = [...gridFilters];

      newFilterProducts.push(filter);

      updateGridFilter(newFilterProducts);
    }
  };

  const filterMenu = (
    <Menu>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<LockOutlined />}
          label={Messages.lockedUser}
          onClick={() => handleFilterUsers('locked')}
          toggle={gridFilters.indexOf('locked') > -1}
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={filterMenu} arrow>
      <RibbonButton icon={icon} label={label} toggle={toggle} />
    </Dropdown>
  );
};

export default FilterUsersSection;
