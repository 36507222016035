import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';

import CertificationImages from './CertificationImages';
import CertificationModal from './CertificationModal';

import {
  CERTIFICATION_IMAGES,
  CERTIFICATION_IMAGES_TYPE_BOOLEAN,
} from 'static/ProductCertifications';
import { Images } from 'config/assets';

import * as _ from 'lodash';

import './ProductOverviewCertification.less';

const ProductOverviewCertification = ({
  productItemModules,
  productSchema,
  productEnums,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [certs, setCerts] = useState([]);

  useEffect(() => {
    if (
      !_.isEmpty(productItemModules) &&
      !_.isEmpty(productSchema) &&
      !_.isEmpty(productEnums)
    ) {
      setCertificationData();
    }
  }, [
    JSON.stringify(productItemModules),
    JSON.stringify(productSchema),
    JSON.stringify(productEnums),
  ]);

  const setCertificationData = () => {
    let certs = filterProductCertificationsData(
      productItemModules?.productItemModules
    );

    const productCertificationSchemas = productSchema?.find(
      (item) => item?.moduleName === 'Certification'
    )?.moduleProperties;

    certs = fillCertificationsProperties(
      certs,
      productCertificationSchemas,
      productEnums
    );
    let filteredCerts = certs?.filter(
      (c) => !!c?.certImage && typeof c?.type === 'string'
    );
    setCerts(filteredCerts);
  };

  const filterProductCertificationsData = (itemModules) => {
    const certificationsList = itemModules?.find(
      (item) => item?.moduleName === 'Certification'
    );

    let productCertifications;
    if (!_.isEmpty(certificationsList)) {
      let properties = certificationsList?.data;
      productCertifications = _.pickBy(properties, function (value, key) {
        return !_.isEmpty(value) || value === true || typeof value == 'number';
      });
    }

    return productCertifications;
  };

  const fillCertificationsProperties = (certObj, schemas, productEnums) => {
    let schema;
    let productEnum;

    //![bug] - fix Hanh's bug - crash product detail view
    if (!certObj) return [];

    const data = Object.entries(certObj).reduce(
      (newArray, [key, values], index) => {
        schema = schemas.find((item) => item?.propertyName === key);
        let newObj = { name: key, type: values, ...schema };

        if (values instanceof Array) {
          productEnum = productEnums?.find(
            (item) => item?.enumName === schema?.listName
          );

          let valueTemps = values?.length > 0 && values?.filter((val) => !!val);
          const filledValues =
            valueTemps &&
            valueTemps?.length > 0 &&
            valueTemps.map((value) => {
              let enumProperty =
                productEnum &&
                productEnum?.enumProperties?.length > 0 &&
                productEnum?.enumProperties?.find(
                  (enumProper) => enumProper?.enumDisplayName === value
                );

              let certImage = renderCertificationImage(key, value, false);
              return {
                name: key,
                type: enumProperty?.enumDisplayName || value,
                certImage: certImage,
                ...schema,
                ...enumProperty,
              };
            });

          return newArray.concat(filledValues);
        } else if (values === true) {
          let certImage = renderCertificationImage(key, values, true);
          newObj = { ...newObj, boolean: true, certImage: certImage };
        } else if (typeof values === 'number') {
          let certImage = renderCertificationImage(
            key,
            values?.toString(), // convert number to string
            true
          );
          newObj = { ...newObj, boolean: true, certImage: certImage };
        } else if (typeof values === 'string') {
          let certImage = renderCertificationImage(key, values, false);
          newObj = { ...newObj, certImage: certImage };
        }
        return newArray.concat(newObj);
      },
      []
    );

    return data;
  };

  const renderCertificationImage = (certName, certSub, booleanType = false) => {
    let certNameFormat = certName?.replace(/\W/g, '')?.toUpperCase();

    if (booleanType) {
      return CERTIFICATION_IMAGES_TYPE_BOOLEAN[certNameFormat] || null;
    }

    if (certName) {
      let formatCertSub = certSub?.replace(/\W/g, '')?.toUpperCase();

      const image = certSub
        ? `${certNameFormat}_${formatCertSub}`
        : certNameFormat;

      return image ? CERTIFICATION_IMAGES[image] : null;
    }
  };

  return (
    <>
      <Row className='product-overview-certifications' align='bottom'>
        <CertificationImages
          certs={certs}
          onShowMore={() => setVisibleModal(true)}
        />
      </Row>
      {visibleModal && (
        <CertificationModal
          certs={certs}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      )}
    </>
  );
};

ProductOverviewCertification.propTypes = { certifications: PropTypes.array };

export default ProductOverviewCertification;
