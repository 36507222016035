import * as api from 'config/axios';
import * as endpoints from './endpoints';

import { REPLACE_DIGITAL_ASSET } from 'services/digitalAsset/endpoints';

export const getDropboxLoginUrl = (params) => {
  return api.sendGet(endpoints.GET_DROPBOX_LOGIN_URL, { params });
};

export const getDropboxCallbackService = (params) => {
  return api.sendGet(endpoints.GET_DROPBOX_CALLBACK, { params });
};

export const getInteropConnections = (params) => {
  return api.sendPost(endpoints.GET_INTEROP_CONNECTION_GRID, params);
};

export const getListConnections = (params) => {
  return api.sendPost(endpoints.GET_LIST_CONNECTIONS, params);
};

export const saveInteropConnection = (params) => {
  return api.sendPost(endpoints.SAVE_INTEROP_CONNECTION, params);
};

export const deleteInteropConnection = (params) => {
  return api.sendPost(endpoints.DELETE_INTEROP_CONNECTION, params);
};

export const getDetailInteropConnection = (params) => {
  return api.sendGet(endpoints.GET_DETAIL_INTEROP_CONNECTION, { params });
};

export const testInteropConnection = (params) => {
  return api.sendPost(endpoints.TEST_INTEROP_CONNECTION, params);
};

export const getInteropListItems = (params) => {
  return api.sendGet(endpoints.GET_INTEROP_LIST_ITEMS, { params });
};

export const createAssetFromInterop = (params) => {
  return api.sendPost(endpoints.CREATE_ASSET_FROM_INTEROP, params);
};

export const importAssetsFromInterop = (params) => {
  return api.sendPost(endpoints.IMPORT_ASSETS_FROM_INTEROP, params);
};

export const createReplacedAsset = (params, assetId) => {
  return api.sendPost(`${REPLACE_DIGITAL_ASSET}/${assetId}`, params);
};
