import React from 'react';
import { Typography, Button } from 'antd';
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import './ChatContentHeader.less';

const { Title } = Typography;

const ChatContentHeader = (props) => {
  const { title, newChatThread } = props;

  return (
    <div className='chat-content-header'>
      <Title className='chat-content-header__title'>{title}</Title>
      <div className='chat-content-header__action'>
        <Button>
          <SearchOutlined />
        </Button>
        <Button>
          <UserAddOutlined onClick={() => newChatThread()} />
        </Button>
      </div>
    </div>
  );
};

export default ChatContentHeader;
