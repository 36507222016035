import React, { useMemo } from 'react';

import { Row, Col } from 'antd';

import {
  GroupElementWrapper as ProductDisplayWrapper,
  HeaderGroupElement,
} from '../../shared/components';

import ProductPropertyItem from './ProductPropertyItem';

import {
  checkEmptyDataProductModule,
  mappingDataProductByFieldFullPath,
  lowercaseProductData,
  checkInvalidDataInGroupProperties,
} from './utils';

import { filterModuleProperties } from './filterModuleUtils';

const DisplayProductInfo = (props) => {
  const { moduleProduct, dataProductModule = {}, productEnums } = props;

  const filteredModuleProduct = useMemo(
    () => filterModuleProperties(moduleProduct),
    [moduleProduct]
  );

  const { isGroup, moduleProperties, moduleGroupProperties } =
    filteredModuleProduct;

  const { data } = dataProductModule;

  const isEmptyData = checkEmptyDataProductModule(data);

  const formattedData = useMemo(
    () =>
      mappingDataProductByFieldFullPath(
        lowercaseProductData(data),
        moduleProperties
      ),
    [data, moduleProperties]
  );

  if (isEmptyData) return null;

  if (isGroup) {
    return (
      <Row
        style={{
          position: 'relative',
          marginTop: 6,
          marginLeft: 6,
        }}
      >
        <Col span={24}>
          {moduleGroupProperties.map((groupItem) => {
            const invalidData = checkInvalidDataInGroupProperties(
              formattedData,
              groupItem.groupProperties
            );

            if (invalidData || groupItem?.groupProperties?.length === 0) {
              return null;
            }

            return (
              <Row
                key={groupItem.nameGroup}
                className='product-detail__property-container'
              >
                <ProductDisplayWrapper
                  span={24}
                  style={{
                    width: '100%',
                    marginTop: 12,
                  }}
                >
                  <HeaderGroupElement
                    header={groupItem.nameGroup}
                    style={{ marginTop: 8 }}
                  />
                  <Col
                    span={24}
                    style={{ marginLeft: 12, padding: '12px 0px' }}
                  >
                    {groupItem.groupProperties.map((property) => (
                      <ProductPropertyItem
                        key={property.propertyName}
                        property={property}
                        data={formattedData}
                        productEnums={productEnums}
                      />
                    ))}
                  </Col>
                </ProductDisplayWrapper>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }

  return (
    <Row
      style={{
        position: 'relative',
        marginLeft: 6,
      }}
    >
      <Row className='product-detail__property-container'>
        <Col span={24} style={{ marginLeft: 12 }}>
          {moduleProperties.map((property) => (
            <ProductPropertyItem
              key={property.propertyName}
              property={property}
              data={formattedData}
              productEnums={productEnums}
            />
          ))}
        </Col>
      </Row>
    </Row>
  );
};

export default DisplayProductInfo;
