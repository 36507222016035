import React, { useState, useEffect } from 'react';
import { Input, Row, Col, Pagination, Empty } from 'antd';

import { WithLoading } from 'common/components';

import { useGetPartyInfo } from './utils';
import { PartyItem } from './PartyItem';

import classnames from 'classnames';
import './PartyDataList.less';

const { Search } = Input;

export const PartyDataList = ({
  handleAddGln,
  handleRemoveGln,
  recipientGLNs,
}) => {
  const [searchText, setSearchText] = useState('');

  const [page, setPage] = useState({
    pageIndex: 1,
    pageSize: 20,
  });

  const { gridData, paging, isLoading } = useGetPartyInfo({
    searchText,
    pageIndex: page.pageIndex,
    pageSize: page.pageSize,
  });

  const { currentPageIndex, currentPageSize, totalRecord } = paging;

  useEffect(() => {
    if (currentPageIndex && currentPageSize) {
      setPage({
        pageIndex: currentPageIndex,
        pageSize: currentPageSize,
      });
    }
  }, [currentPageIndex, currentPageSize]);

  return (
    <WithLoading loading={isLoading}>
      <div
        style={{
          display: 'flex',
          paddingBottom: '5px',
          width: '100%',
        }}
      >
        <Search
          placeholder='Search recipients for publish product'
          allowClear
          onSearch={(value) => {
            setPage({
              pageIndex: 1,
              pageSize: page.pageSize,
            });
            setSearchText(value);
          }}
        />
      </div>

      <Row className='partylist__container scroller' gutter={4}>
        {gridData?.length > 0
          ? gridData.map((recipientGLN, index) => (
              <Col span={12} key={index}>
                <PartyItem
                  item={recipientGLN}
                  recipientGLNs={recipientGLNs}
                  handleAddGln={handleAddGln}
                  handleRemoveGln={handleRemoveGln}
                />
              </Col>
            ))
          : !isLoading && (
              <div
                className={classnames('partylist__container')}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  height: '58vh',
                  alignItems: 'center',
                }}
              >
                <Empty />
              </div>
            )}
      </Row>

      {gridData?.length > 0 && (
        <div style={{ position: 'absolute', bottom: '0px' }}>
          <Pagination
            current={page.pageIndex}
            pageSize={page.pageSize}
            total={totalRecord}
            onChange={(pageIndex, pageSize) => {
              setPage({
                pageIndex,
                pageSize,
              });
            }}
            size='small'
          />
        </div>
      )}
    </WithLoading>
  );
};
