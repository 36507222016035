import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectProduct = (state) => state.productFullView || initialState;

const makeSelectLoading = () =>
  createSelector(selectProduct, (productState) => productState.loading);

const makeSelectProductDetailHeader = () =>
  createSelector(
    selectProduct,
    (productState) => productState.productDetailHeader
  );

const makeSelectProductDetailHeaderError = () =>
  createSelector(selectProduct, (productState) => productState.error);

const makeSelectMappingDataProperties = () =>
  createSelector(
    selectProduct,
    (productState) => productState.dataPropertiesColumn
  );

const makeSelectDocuments = () =>
  createSelector(selectProduct, (productState) => productState.documents);

const makeSelectActiveContent = () =>
  createSelector(selectProduct, (productState) => productState.activeContent);

const makeSelectSelectedHierarchy = () =>
  createSelector(
    selectProduct,
    (productState) => productState.selectedHierarchy
  );

const makeSelectBrandImage = () =>
  createSelector(selectProduct, (productState) => productState.brandImage);

const makeSelectAdditionalImages = () =>
  createSelector(
    selectProduct,
    (productState) => productState.additionalImages
  );

const makeSelectImageryStatus = () =>
  createSelector(selectProduct, (productState) => productState.imageryStatus);
const makeSelectImageryLoading = () =>
  createSelector(selectProduct, (productState) => productState.imageryLoading);
const makeSelectPlanogramImages = () =>
  createSelector(selectProduct, (productState) => productState.planogramImages);

const makeSelectIsImageryOwner = () =>
  createSelector(selectProduct, (productState) => productState.isImageryOwner);

const makeSelectProductSocialMedia = () =>
  createSelector(selectProduct, (productState) => productState.socialMedia);

const makeSelectMarketingAssets = () =>
  createSelector(selectProduct, (productState) => productState.marketingAsset);
const makeSelectMarketingPrimaryAssets = () =>
  createSelector(
    selectProduct,
    (productState) => productState.marketingAssetPrimary
  );
const makeSelectFactsPanel = () =>
  createSelector(selectProduct, (productState) => productState.factsPanel);

const makeSelectBasicUomTypes = () =>
  createSelector(selectProduct, (productState) => productState.basicUomTypes);

const makeSelectNutritionTypeCodeTypes = () =>
  createSelector(
    selectProduct,
    (productState) => productState.nutritionTypeCodeTypes
  );

const makeSelectMeasurementPrecisionCodeTypes = () =>
  createSelector(
    selectProduct,
    (productState) => productState.measurementPrecisionCodeTypes
  );

const makeSelectUpdateFactsStatus = () =>
  createSelector(
    selectProduct,
    (productState) => productState.updateFactsPanelStatus
  );

const makeSelectUpdateFactsErrorMessage = () =>
  createSelector(
    selectProduct,
    (productState) => productState.updateFactsPanelErrorMessage
  );

const makeSelectCurrentHierarchy = () =>
  createSelector(
    selectProduct,
    (productState) => productState.currentHierarchy
  );

const makeSelectCurrentHierarchyShortDetails = () =>
  createSelector(
    selectProduct,
    (productState) => productState.currentHierarchyShortDetails
  );
const makeSelectHierarchyShortDetailsDisplayMode = () =>
  createSelector(
    selectProduct,
    (productState) => productState.hierarchyShortDetailsDisplayMode
  );

const makeSelectHierarchyEndpoint = () =>
  createSelector(
    selectProduct,
    (productState) => productState.hierarchyEndpoint
  );

const makeSelectUpdateProductDetailStatus = () =>
  createSelector(
    selectProduct,
    (productState) => productState.updateProductStatus
  );

const makeSelectUpdateProductError = () =>
  createSelector(
    selectProduct,
    (productState) => productState.updateProductError
  );

const makeSelectColumnSubscriptionProduct = () =>
  createSelector(
    selectProduct,
    (productState) => productState.columnSubscriptionProduct
  );

const makeSelectMappingColumn = () =>
  createSelector(selectProduct, (productState) => productState.columnMapping);

const makeSelectMappingSupportMemColumn = () =>
  createSelector(
    selectProduct,
    (productState) => productState.columnMappingSupportMem
  );

const makeSelectSelectionSubscription = () =>
  createSelector(
    selectProduct,
    (productState) => productState.selectionSubscription
  );

const makeSelectMappingMetadata = () =>
  createSelector(selectProduct, (productState) => productState.mappingMetadata);

const makeSelectSelectedMappingSupportMem = () =>
  createSelector(
    selectProduct,
    (productState) => productState.selectedMappingSupportMem
  );

const makeSelectReloadMappingSupportMemGrid = () =>
  createSelector(
    selectProduct,
    (productState) => productState.reloadMappingSupportMemGrid
  );

const makeSelectSelectedMapping = () =>
  createSelector(selectProduct, (productState) => productState.selectedMapping);

const makeSelectColumnsSyndicationHistory = () =>
  createSelector(
    selectProduct,
    (productState) => productState.columnsSyndicationHistory
  );

const makeSelectProductSyndicationHistoryShortDetails = () =>
  createSelector(
    selectProduct,
    (productState) => productState.productSyndicationHistoryShortDetails
  );

const makeSelectFactsPanelLoading = () =>
  createSelector(
    selectProduct,
    (productState) => productState.factsPanelLoading
  );

const selectIsProductDetailExpand = () =>
  createSelector(
    selectProduct,
    (productState) => productState.isProductDetailExpand
  );

export {
  makeSelectLoading,
  makeSelectProductDetailHeader,
  makeSelectProductDetailHeaderError,
  makeSelectDocuments,
  makeSelectActiveContent,
  makeSelectSelectedHierarchy,
  makeSelectBrandImage,
  makeSelectProductSocialMedia,
  makeSelectMarketingAssets,
  makeSelectMarketingPrimaryAssets,
  makeSelectFactsPanel,
  makeSelectBasicUomTypes,
  makeSelectNutritionTypeCodeTypes,
  makeSelectMeasurementPrecisionCodeTypes,
  makeSelectUpdateFactsStatus,
  makeSelectUpdateFactsErrorMessage,
  makeSelectAdditionalImages,
  makeSelectPlanogramImages,
  makeSelectIsImageryOwner,
  makeSelectCurrentHierarchy,
  makeSelectCurrentHierarchyShortDetails,
  makeSelectHierarchyShortDetailsDisplayMode,
  makeSelectHierarchyEndpoint,
  makeSelectUpdateProductDetailStatus,
  makeSelectUpdateProductError,
  makeSelectColumnSubscriptionProduct,
  makeSelectSelectionSubscription,
  makeSelectMappingMetadata,
  makeSelectMappingColumn,
  makeSelectMappingSupportMemColumn,
  makeSelectMappingDataProperties,
  makeSelectSelectedMappingSupportMem,
  makeSelectReloadMappingSupportMemGrid,
  makeSelectSelectedMapping,
  makeSelectColumnsSyndicationHistory,
  makeSelectProductSyndicationHistoryShortDetails,
  makeSelectFactsPanelLoading,
  makeSelectImageryLoading,
  makeSelectImageryStatus,
  selectIsProductDetailExpand,
};
