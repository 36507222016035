import DrugFactSection from './DrugFactSection';
import DrugFactHeading from './DrugFactHeading';
import DrugFactText from './DrugFactText';
import DrugFactDetailList from './DrugFactDetailList';

const DrugFactUses = ({ uses }) => {
  if (!uses?.useText && !uses?.useDetail?.length) return null;

  const useList = uses?.useDetail?.map((item) => {
    return { text: item.useDetail, ...item };
  });
  return (
    <DrugFactSection>
      <div className='drug-fact__uses-heading'>
        <DrugFactHeading heading='Uses' />
        <DrugFactText styles={{ marginLeft: '4px' }}>
          {uses?.useText}
        </DrugFactText>
      </div>
      {useList?.length > 0 ? <DrugFactDetailList list={useList} /> : null}
    </DrugFactSection>
  );
};

export default DrugFactUses;
