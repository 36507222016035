export const UPDATE_NEW_FORM_TEMPLATE = 'UPDATE_NEW_FORM_TEMPLATE';
export const UPDATE_NEW_FORM_TEMPLATE_SUCCESS =
  'UPDATE_NEW_FORM_TEMPLATE_SUCCESS';

export const UPDATE_NEW_FORM_TEMPLATE_ERROR = 'UPDATE_NEW_FORM_TEMPLATE_ERROR';

export const GET_FORM_DETAIL = 'GET_FORM_DETAIL';
export const GET_FORM_DETAIL_SUCCESS = 'GET_FORM_DETAIL_SUCCESS';
export const GET_FORM_DETAIL_FAIL = 'GET_FORM_DETAIL_FAIL';

export const FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL =
  'FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL';
export const FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_SUCCESS =
  'FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_SUCCESS';
export const FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_FAIL =
  'FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_FAIL';

export const FORM_MANAGE_TOGGLE_VIEW_LOADING =
  'FORM_MANAGE_TOGGLE_VIEW_LOADING';

export const UPDATE_SELECTED_DATA_POINT = 'UPDATE_SELECTED_DATA_POINT';

export const GET_FORM_TEMPLATE_DETAIL = 'GET_FORM_TEMPLATE_DETAIL';
export const GET_FORM_TEMPLATE_DETAIL_SUCCESS =
  'GET_FORM_TEMPLATE_DETAIL_SUCCESS';
export const GET_FORM_TEMPLATE_DETAIL_ERROR = 'GET_FORM_TEMPLATE_DETAIL_ERROR';

export const EDIT_FORM_TEMPLATE = 'EDIT_FORM_TEMPLATE';
export const EDIT_FORM_TEMPLATE_SUCCESS = 'EDIT_FORM_TEMPLATE_SUCCESS';
export const EDIT_FORM_TEMPLATE_FAIL = 'EDIT_FORM_TEMPLATE_FAIL';

export const UPDATE_UNMOUTE_SUCCESS = 'UPDATE_UNMOUTE_SUCCESS';
export const UPDATE_MODE_VIEW_MAINTENANCE_FORM =
  'UPDATE_MODE_VIEW_MAINTENANCE_FORM';

export const FORM_MANAGE_REPLACE_FORM_FILE_SUCCESS =
  'FORM_MANAGE_REPLACE_FORM_FILE_SUCCESS';

export const FORM_MANAGE_DELETE_FORM_ITEM = 'FORM_MANAGE_DELETE_FORM_ITEM';
export const FORM_MANAGE_DELETE_FORM_ITEM_SUCCESS =
  'FORM_MANAGE_DELETE_FORM_ITEM_SUCCESS';
export const FORM_MANAGE_DELETE_FORM_ITEM_FAIL =
  'FORM_MANAGE_DELETE_FORM_ITEM_FAIL';

export const FORM_MANAGE_TOGGLE_REFRESH_DATA_POINT_LIST =
  'FORM_MANAGE_TOGGLE_REFRESH_DATA_POINT_LIST';
export const FORM_MANAGE_TOGGLE_REFRESH_DATA_POINT_LIST_SUCCESS =
  'FORM_MANAGE_TOGGLE_REFRESH_DATA_POINT_LIST_SUCCESS';

export const FORM_MANAGE_CLONE_FORM_ITEM = 'FORM_MANAGE_CLONE_FORM_ITEM';
export const FORM_MANAGE_CLONE_FORM_ITEM_FAIL =
  'FORM_MANAGE_CLONE_FORM_ITEM_FAIL';
export const FORM_MANAGE_CLONE_FORM_ITEM_SUCCESS =
  'FORM_MANAGE_CLONE_FORM_ITEM_SUCCESS';
export const REPLACE_FORM_TEMPLATE_FILE = 'REPLACE_FORM_TEMPLATE_FILE';

export const TOGGLE_CLEAR_DATA_POINT_LIST = 'TOGGLE_CLEAR_DATA_POINT_LIST';

export const TOGGLE_CLEAR_DATA_POINT_SEARCH_TEXT =
  'TOGGLE_CLEAR_DATA_POINT_SEARCH_TEXT';

export const TOGGLE_FORM_DETAIL_FULL_SCREEN = 'TOGGLE_FORM_DETAIL_FULL_SCREEN';
export const TOGGLE_FORM_DETAIL_FLOAT_MODE = 'TOGGLE_FORM_DETAIL_FLOAT_MODE';

export const UPDATE_SELECTED_DATA_POINT_DATA =
  'UPDATE_SELECTED_DATA_POINT_DATA';

export const REFRESH_DATA_POINT_DETAIL = 'REFRESH_DATA_POINT_DETAIL';
export const REFRESH_DATA_POINT_DETAIL_SUCCESS =
  'REFRESH_DATA_POINT_DETAIL_SUCCESS';

export const FORM_MANAGE_DOWNLOAD_EXPORTED_FORM =
  'FORM_MANAGE_DOWNLOAD_EXPORTED_FORM';

export const UPDATE_LOAD_SCRIPT_SPREADJS = 'UPDATE_LOAD_SCRIPT_SPREADJS';