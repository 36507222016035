import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { PlusCircleOutlined } from '@ant-design/icons';

import { Row, Button, Col } from 'antd';
import ColumnDraggable from './ColumnDraggable';
import useGetProperties from '../hook/useGetProperties';
import { UPDATE_COLUMNS } from '../utils/constans';

import './DataColumns.less';

const DataColumns = (props) => {
  const {
    propertiesData,
    entityType,
    viewMode,
    dataColumns,
    handleUpdateDataColumns,
  } = props;

  const { getPropertyData, selectTreeData } = useGetProperties({
    propertiesData,
    entityType,
  });

  return (
    <Droppable droppableId={'data_columns'}>
      {(provided, { isDraggingOver }) => (
        <div
          ref={provided.innerRef}
          className={classnames({
            'data-columns': true,
            'data-columns scroller-extend': true,
            'data-columns--dragging-over': isDraggingOver,
          })}
        >
          <div style={{ pointerEvents: viewMode ? 'none' : 'auto' }}>
            {dataColumns?.map((fieldName, index) => {
              const property = getPropertyData(fieldName);
              const key = !!fieldName ? fieldName : index;
              return (
                <ColumnDraggable
                  key={key + '_columns'}
                  index={index}
                  viewMode={viewMode}
                  property={property}
                  dataColumns={dataColumns}
                  selectTreeData={selectTreeData}
                  handleUpdateDataColumns={handleUpdateDataColumns}
                />
              );
            })}
            {!viewMode && (
              <Row>
                <Col
                  span={24}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    ghost
                    type='primary'
                    shape='round'
                    onClick={() => {
                      handleUpdateDataColumns({
                        type: UPDATE_COLUMNS.ADD,
                      });
                    }}
                    size='small'
                    icon={<PlusCircleOutlined />}
                  >
                    Add Column
                  </Button>
                </Col>
              </Row>
            )}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default React.memo(DataColumns);
