import { handleCallApiReducer } from 'utils/api';

export const mapToRoleDetail = (action, _draft) => {
  handleCallApiReducer(action, _draft, 'fieldname');
};

export const mapToCommonFetchState = (
  action,
  _draft,
  field,
  isKeepPrevData
) => {
  handleCallApiReducer(action, _draft, field, isKeepPrevData);
};
