import React, { useEffect, useRef } from 'react';

import { Row, Col, Typography, Select } from 'antd';

import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import classnames from 'classnames';

import {
  CustomNotification,
  Form,
  SectionWrapper,
  WithLoading,
} from 'common/components';

import {
  EDIT_SECTION,
  EditTabSectionWrapper,
} from 'pages/qa-spec/components/edit-tab-checker';

import EditContainer from '../container/EditContainer';

import {
  qaPetFoodKeys,
  useGetQaPetFood,
  useGetQaSpecPetFoodFormDefine,
  useGetSnapshotQaPetFood,
  useSaveQaPetFood,
} from './hooks';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import {
  HighlightContent,
  PrimaryFieldSnapshot,
  RetailerSnapshot,
} from '../qa-prod-spec/snapshot/RetailerSnapshot';
import QaSpecPetFoodGeometries from './QaSpecPetFoodGeometries';

import './QaPetFood.less';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const QaPetFood = () => {
  const [formInstance] = Form.useForm();

  const { id: productId } = useParams();

  const refEditContainer = useRef();
  const { data: qaPetFood, isLoading } = useGetQaPetFood(productId);
  const { data: qaPetFoodSnapshot } = useGetSnapshotQaPetFood(productId);
  const queryClient = useQueryClient();

  const petFoodMutation = useSaveQaPetFood(productId);
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  useEffect(() => {
    if (qaPetFood) {
      formInstance.setFieldsValue({ ...qaPetFood });
    }
  }, [formInstance, qaPetFood]);

  const handleSave = async (toggleEditMode, ixoneIgnore) => {
    try {
      const formValues = await formInstance.validateFields();

      const params = {
        productId,
        ...formValues,
      };
      petFoodMutation.mutate(params, {
        onSuccess: async (response) => {
          if (response?.isSuccess) {
            queryClient.invalidateQueries({
              queryKey: qaPetFoodKeys.detail(productId),
            });
            CustomNotification.success('Update Pet Food data successfully!');
            await handleUpdateIXONEShield(ixoneIgnore);
            toggleEditMode();
          } else {
            CustomNotification.error(
              response?.message ?? 'Something went wrong'
            );
          }
        },
      });
    } catch (error) {
      if (error?.errorFields?.length) {
        CustomNotification.error(' Please resolve errors in the Pet Food tab');
        return;
      }

      CustomNotification.error('Failed to save data');
    }
  };

  const formDefine = useGetQaSpecPetFoodFormDefine();

  const handleCancel = () => {
    formInstance.setFieldsValue({ ...qaPetFood });
  };

  return (
    <IXONECertificationWrapper
      onConfirm={(ixoneIgnore) => {
        const toggleEditMode = refEditContainer?.current?.onToggleEditMode;
        handleSave(toggleEditMode, ixoneIgnore);
      }}
      showModal
      type={TYPE_VIEW.EDIT_PRODUCT}
    >
      {(handleConfirmIXONE) => (
        <Form form={formInstance} className='qa-pet-food'>
          <WithLoading loading={isLoading}>
            <EditContainer
              ref={refEditContainer}
              type={EDIT_SECTION.QA_PET_FOOD}
              productId={productId}
              saveLoading={petFoodMutation.isLoading}
              handleSave={async () => {
                handleConfirmIXONE({
                  originalData: qaPetFood,
                  changedFields: await formInstance.validateFields(),
                });
              }}
              handleCancel={handleCancel}
              float
            >
              {(toggleEditMode) => {
                const disabled = !toggleEditMode;

                return (
                  <EditTabSectionWrapper.CheckerWrapper
                    isEdit={toggleEditMode}
                    type={EDIT_SECTION.QA_PET_FOOD}
                  >
                    <Row
                      gutter={[8, 8]}
                      wrap={false}
                      className={classnames('qa-pet-food__input', {
                        'qa-pet-food__input--edit': toggleEditMode,
                      })}
                    >
                      <Col
                        span={10}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <SectionWrapper title='Feeding'>
                          <PetFoodFeeding
                            disabled={disabled}
                            qaPetFood={qaPetFood}
                            qaPetFoodSnapshot={qaPetFoodSnapshot}
                          />
                        </SectionWrapper>
                        <SectionWrapper
                          title='Additional Information'
                          style={{ paddingTop: 30, flex: '1' }}
                          className='qa-pet-food__additional-information'
                        >
                          <PetFoodAdditionalInformation
                            disabled={disabled}
                            qaPetFood={qaPetFood}
                            qaPetFoodSnapshot={qaPetFoodSnapshot}
                          />
                        </SectionWrapper>
                      </Col>
                      <Col flex={1}>
                        <SectionWrapper title='Details'>
                          <PetFoodDetails
                            disabled={disabled}
                            qaPetFood={qaPetFood}
                            qaPetFoodSnapshot={qaPetFoodSnapshot}
                          />
                        </SectionWrapper>
                      </Col>
                    </Row>
                    <Row gutter={[8, 0]} style={{ marginTop: 8 }}>
                      <Col span={24}>
                        <QaSpecPetFoodGeometries
                          isEdit={toggleEditMode}
                          formDefine={formDefine.geometries}
                          geometries={qaPetFood?.geometries ?? []}
                          geometriesSnapshot={
                            qaPetFoodSnapshot?.geometries ?? []
                          }
                        />
                      </Col>
                    </Row>
                  </EditTabSectionWrapper.CheckerWrapper>
                );
              }}
            </EditContainer>
          </WithLoading>
        </Form>
      )}
    </IXONECertificationWrapper>
  );
};

const PetFoodFeeding = ({ disabled, qaPetFood, qaPetFoodSnapshot }) => {
  return (
    <Row>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.dailyFeedingRecommendations}
          snapshotValue={qaPetFoodSnapshot?.dailyFeedingRecommendations}
          highlightContent={
            <HighlightContent
              label='Daily Feeding Recommendations:'
              value={qaPetFoodSnapshot?.dailyFeedingRecommendations}
            />
          }
        >
          <Form.Item
            name='dailyFeedingRecommendations'
            label={<LabelForm label='Daily Feeding Recommendations:' />}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <PrimaryFieldSnapshot readOnly={disabled} />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.feedingInstructions}
          snapshotValue={qaPetFoodSnapshot?.feedingInstructions}
          highlightContent={
            <HighlightContent
              label='Feeding Instructions:'
              value={qaPetFoodSnapshot?.feedingInstructions}
            />
          }
        >
          <Form.Item
            name='feedingInstructions'
            label={<LabelForm label='Feeding Instructions:' />}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <PrimaryFieldSnapshot readOnly={disabled} />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.feedingGuidelines}
          snapshotValue={qaPetFoodSnapshot?.feedingGuidelines}
          highlightContent={
            <HighlightContent
              label='Feeding Guidelines:'
              value={qaPetFoodSnapshot?.feedingGuidelines}
            />
          }
        >
          <Form.Item
            name='feedingGuidelines'
            label={<LabelForm label='Feeding Guidelines:' />}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <PrimaryFieldSnapshot readOnly={disabled} />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.appetiteControlCareProgram}
          snapshotValue={qaPetFoodSnapshot?.appetiteControlCareProgram}
          highlightContent={
            <HighlightContent
              label='Appetite Control Care Program:'
              value={qaPetFoodSnapshot?.appetiteControlCareProgram}
            />
          }
        >
          <Form.Item
            name='appetiteControlCareProgram'
            label={<LabelForm label='Appetite Control Care Program:' />}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <PrimaryFieldSnapshot readOnly={disabled} />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.veterinarianStatement}
          snapshotValue={qaPetFoodSnapshot?.veterinarianStatement}
          highlightContent={
            <HighlightContent
              label='Use Only As Directed by your Veterinarian Statement:'
              value={qaPetFoodSnapshot?.veterinarianStatement}
            />
          }
        >
          <Form.Item
            name='veterinarianStatement'
            label={
              <LabelForm label='Use Only As Directed by your Veterinarian Statement:' />
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <PrimaryFieldSnapshot readOnly={disabled} />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
    </Row>
  );
};

const PetFoodAdditionalInformation = ({
  disabled,
  qaPetFood,
  qaPetFoodSnapshot,
}) => {
  return (
    <>
      <QaPetFoodSnapshot
        supplierModifiedValue={qaPetFood?.humanGrade}
        snapshotValue={qaPetFoodSnapshot?.humanGrade}
        highlightContent={
          <HighlightContent
            label='Human Grade:'
            value={qaPetFoodSnapshot?.humanGrade}
          />
        }
      >
        <Form.Item
          name='humanGrade'
          label={<LabelForm label='Human Grade' />}
          labelCol={{ span: 12 }}
        >
          <PrimaryFieldSnapshot variant='select' disabled={disabled}>
            {['Yes', 'No'].map((option) => (
              <Select.Option value={option}>{option}</Select.Option>
            ))}
          </PrimaryFieldSnapshot>
        </Form.Item>
      </QaPetFoodSnapshot>
      <QaPetFoodSnapshot
        supplierModifiedValue={qaPetFood?.containsPalmOil}
        snapshotValue={qaPetFoodSnapshot?.containsPalmOil}
        highlightContent={
          <HighlightContent
            label='Contains Palm Oil'
            value={qaPetFoodSnapshot?.containsPalmOil}
          />
        }
      >
        <Form.Item
          name='containsPalmOil'
          label={<LabelForm label='Contains Palm Oil' />}
          labelCol={{ span: 12 }}
        >
          <PrimaryFieldSnapshot variant='select' disabled={disabled}>
            {['Yes', 'No'].map((option) => (
              <Select.Option value={option}>{option}</Select.Option>
            ))}
          </PrimaryFieldSnapshot>
        </Form.Item>
      </QaPetFoodSnapshot>

      <QaPetFoodSnapshot
        supplierModifiedValue={qaPetFood?.ingredientsOutsideUs}
        snapshotValue={qaPetFoodSnapshot?.ingredientsOutsideUs}
        highlightContent={
          <HighlightContent
            label='Ingredients in product from outside the United States'
            value={qaPetFoodSnapshot?.ingredientsOutsideUs}
          />
        }
      >
        <Form.Item
          name='ingredientsOutsideUs'
          label={
            <LabelForm label='Ingredients in product from outside the United States' />
          }
          labelCol={{ span: 12 }}
        >
          <PrimaryFieldSnapshot variant='select' disabled={disabled}>
            {['Yes', 'No'].map((option) => (
              <Select.Option value={option}>{option}</Select.Option>
            ))}
          </PrimaryFieldSnapshot>
        </Form.Item>
      </QaPetFoodSnapshot>
    </>
  );
};

const PetFoodDetails = ({ disabled, qaPetFood, qaPetFoodSnapshot }) => {
  return (
    <Row gutter={[0, 4]}>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.benefits}
          snapshotValue={qaPetFoodSnapshot?.benefits}
          highlightContent={
            <HighlightContent
              label='Benefits:'
              value={qaPetFoodSnapshot?.benefits}
            />
          }
        >
          <Form.Item
            name='benefits'
            label={<LabelForm label='Benefits' />}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <PrimaryFieldSnapshot
              variant='textarea'
              autoSize={{ minRows: 4, maxRows: 5 }}
              readOnly={disabled}
            />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.warnings}
          snapshotValue={qaPetFoodSnapshot?.warnings}
          highlightContent={
            <HighlightContent
              label='Warnings:'
              value={qaPetFoodSnapshot?.warnings}
            />
          }
        >
          <Form.Item
            name='warnings'
            label={<LabelForm label='Warnings' />}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <PrimaryFieldSnapshot
              variant='textarea'
              autoSize={{ minRows: 4, maxRows: 5 }}
              readOnly={disabled}
            />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.nutritionalAdequacyStatement}
          snapshotValue={qaPetFoodSnapshot?.nutritionalAdequacyStatement}
          highlightContent={
            <HighlightContent
              label='Nutritional Adequacy Statement:'
              value={qaPetFoodSnapshot?.nutritionalAdequacyStatement}
            />
          }
        >
          <Form.Item
            name='nutritionalAdequacyStatement'
            label={<LabelForm label='Nutritional Adequacy Statement' />}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <PrimaryFieldSnapshot
              variant='textarea'
              autoSize={{ minRows: 4, maxRows: 5 }}
              readOnly={disabled}
            />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.productClaims}
          snapshotValue={qaPetFoodSnapshot?.productClaims}
          highlightContent={
            <HighlightContent
              label='Product Claims:'
              value={qaPetFoodSnapshot?.productClaims}
            />
          }
        >
          <Form.Item
            name='productClaims'
            label={<LabelForm label='Product Claims' />}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <PrimaryFieldSnapshot
              variant='textarea'
              autoSize={{ minRows: 4, maxRows: 5 }}
              readOnly={disabled}
            />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.usageInstructions}
          snapshotValue={qaPetFoodSnapshot?.usageInstructions}
          highlightContent={
            <HighlightContent
              label='Usage Instructions:'
              value={qaPetFoodSnapshot?.usageInstructions}
            />
          }
        >
          <Form.Item
            name='usageInstructions'
            label={<LabelForm label='Usage Instructions' />}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <PrimaryFieldSnapshot
              variant='textarea'
              autoSize={{ minRows: 4, maxRows: 5 }}
              readOnly={disabled}
            />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
      <Col span={24}>
        <QaPetFoodSnapshot
          supplierModifiedValue={qaPetFood?.proteinContentClaim}
          snapshotValue={qaPetFoodSnapshot?.proteinContentClaim}
          highlightContent={
            <HighlightContent
              label='Protein Content Claim:'
              value={qaPetFoodSnapshot?.proteinContentClaim}
            />
          }
        >
          <Form.Item
            name='proteinContentClaim'
            label={<LabelForm label='Protein Content Claim' />}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <PrimaryFieldSnapshot
              variant='textarea'
              autoSize={{ minRows: 4, maxRows: 5 }}
              readOnly={disabled}
            />
          </Form.Item>
        </QaPetFoodSnapshot>
      </Col>
    </Row>
  );
};

const LabelForm = ({ label }) => {
  return (
    <Typography.Text strong ellipsis={{ tooltip: label }}>
      {label}
    </Typography.Text>
  );
};

export const QaPetFoodSnapshot = (props) => {
  const { id: productId } = useParams();
  const { isSuccess, data } = useGetSnapshotQaPetFood(productId);

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  if (isSuccess && isToggleSnapshot && data) {
    return <RetailerSnapshot {...props} />;
  }

  return <>{props.children}</>;
};

export default QaPetFood;
