import React from 'react';

const LinearVitaminLabel = ({
  label,
  gram,
  labelBold = false,
  percent,
  isEnd = false,
}) => {
  return (
    <span>
      <span>
        <span style={{ fontWeight: labelBold ? 'bold' : '' }}>{label}</span>
        <span style={{ paddingLeft: '2px' }}>{gram}</span>{' '}
        {percent === 0 || percent > 0 ? `(${percent}% DV)` : `(0% DV)`}
        {isEnd ? '.' : ','}
      </span>
    </span>
  );
};

export default LinearVitaminLabel;
