import { useQuery } from '@tanstack/react-query';
import { getQaSpecComponentsSnapshot } from 'services/qaSpec';

const qaSpecComponentKeys = {
  snapshot: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-spec-components',
  ],
};

export const useQueryQaSpecComponentSnapshot = ({
  productId,
  ...options
} = {}) => {
  return useQuery({
    queryKey: qaSpecComponentKeys.snapshot(productId),
    queryFn: async () => {
      const response = await getQaSpecComponentsSnapshot({ productId });
      const { isSuccess, data, message } = response || {};
      if (isSuccess) {
        return data;
      }
      return Promise.reject(message || 'Server Error');
    },
    ...options,
  });
};
