import { defineMessages } from 'react-intl';

export const scope = 'Taco.assetFullView.components.AssetUpload';
export default defineMessages({
  titleSelectLocalFile: {
    id: `${scope}.title.localFile`,
    defaultMessage: 'Choose a Local File to Upload',
  },
  titleSelectAdvFtpFile: {
    id: `${scope}.title.advftpFile`,
    defaultMessage: 'Choose a File from Advantage FTP server',
  },
  titleSelectFtpFile: {
    id: `${scope}.title.ftpFile`,
    defaultMessage: 'Choose a File from FTP Server',
  },
  titleSelectDropboxFile: {
    id: `${scope}.title.dropboxFile`,
    defaultMessage: 'Choose a File from Dropbox',
  },
  titleSelectSageTreeFile: {
    id: `${scope}.title.sageTreeFile`,
    defaultMessage: 'Choose a File from Sage Tree',
  },
  //* dropdown options
  fptSelectOption: {
    id: `${scope}.method.option.ftpFile`,
    defaultMessage: 'From FTP Server',
  },
  localSelectOption: {
    id: `${scope}.method.option.localFile`,
    defaultMessage: 'From Local Directory',
  },
  advfptSelectOption: {
    id: `${scope}.method.option.advFtpFile`,
    defaultMessage: 'From Advantage FTP',
  },
  dropboxSelectOption: {
    id: `${scope}.method.option.dropboxLocalFile`,
    defaultMessage: 'From Dropbox',
  },
  sageTreeSelectOption: {
    id: `${scope}.method.option.sageTreeFile`,
    defaultMessage: 'From SageTree',
  },
  //*Ftp selection
  ftpHeader: {
    id: `${scope}.ftp.ftpHeader`,
    defaultMessage: 'Cloud Files',
  },
  selectedFileHeader: {
    id: `${scope}.ftp.selectedFileHeader`,
    defaultMessage: 'Choose File',
  },
  selectedAllFileHeader: {
    id: `${scope}.ftp.selectedAllFileHeader`,
    defaultMessage: 'Choose All',
  },
});
