import * as types from './constants';

export const getColumnsRequireFields = (gridName) => ({
  type: types.GET_COLUMNS_REQUIRED_FIELDS,
  gridName,
});

export const getColumnsRequireFieldsSuccess = (data) => ({
  type: types.GET_COLUMNS_REQUIRED_FIELDS_SUCCESS,
  payload: data,
});

export const getColumnsRequireFieldsFail = () => ({
  type: types.GET_COLUMNS_REQUIRED_FIELDS_FAIL,
});

export const getRequiredFields = (pageNumber, pageSize, search) => {
  return {
    type: types.GET_REQUIRED_FIELDS,
    pageIndex: pageNumber,
    pageSize,
    'Search.SearchText': search,
  };
};

export const getRequiredFieldsSuccess = (data, total) => {
  return {
    type: types.GET_REQUIRED_FIELDS_SUCCESS,
    data,
    total,
  };
};

export const getRequiredFieldsFail = (error) => {
  return {
    type: types.GET_REQUIRED_FIELDS_FAIL,
    error,
  };
};

export const toggleReloadGrid = (toggle) => {
  return {
    type: types.TOGGLE_RELOAD_GRID,
    toggle,
  };
};

export const getRequiredFieldShortPanel = (params) => ({
  type: types.GET_REQUIRED_FIELD_SHORT_PANEL,
  payload: params,
});

export const getRequiredFieldShortPanelSuccess = (params) => ({
  type: types.GET_REQUIRED_FIELD_SHORT_PANEL_SUCCESS,
  payload: params,
});

export const getRequiredFieldShortPanelFailure = (params) => ({
  type: types.GET_REQUIRED_FIELD_SHORT_PANEL_FAILURE,
  payload: params,
});
