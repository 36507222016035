import React from 'react';
import { Modal } from 'antd';
import './DialogFunction.less';

import { ErrorIcon, InfoIcon, SuccessIcon, WarnIcon } from './DialogIcon';

function dialogOkFunction(props) {
  const { type } = props;

  return Modal.error({
    ...props,
    className: 'dialog-modal',
    icon:
      type === 'warn' ? (
        <WarnIcon />
      ) : type === 'error' ? (
        <ErrorIcon />
      ) : type === 'success' ? (
        <SuccessIcon />
      ) : (
        <InfoIcon />
      ),
    okButtonProps: { ...props.okButtonProps, className: 'dialog-ok-button' },
  });
}
dialogOkFunction.defaultProps = {
  okText: 'OK',
  //   cancelText: 'Cancel',
  type: 'info',
};

export default dialogOkFunction;
