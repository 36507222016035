import React from 'react';

import { List } from 'antd';
import DataDisplay from './DataDisplay';

const EntityList = (props) => {
  const { entityList, children, col = 1, ...dataListProps } = props;

  return (
    <DataDisplay data={entityList} {...dataListProps}>
      <List
        grid={{ gutter: 8, column: col }}
        dataSource={entityList}
        renderItem={children}
      />
    </DataDisplay>
  );
};

export default EntityList;
