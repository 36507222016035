export const QUERY_GRID_VIEW = '/api/query/query-grid-view';

export const QUERY_DETAIL_GRID = '/api/query/query-detail-grid';

export const QUERY_DETAIL_GRID_DISTINCT =
  '/api/query/query-detail-grid/distinct';

export const SAVE_QUERY = '/api/query/save-query';

export const EDIT_QUERY = '/api/query/edit-query';

export const DELETE_QUERY = '/api/query/delete-query';

export const QUERY_DETAIL_PANE = '/api/query/query-detail-pane';

export const FAVORITE_QUERY_GRID_VIEW =
  '/api/favorites/favorite-query-grid-view';
export const FAVORITE_QUERY_GRID_VIEW_DISTINCT =
  '/api/favorites/favorite-query-grid-view/distinct';

export const GET_ALL_MEMBER_ROLES = '/api/main-page/get-all-member-roles';
