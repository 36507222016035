import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';

import * as qaSpecServices from 'services/qaSpec';
import { forwardTo } from 'utils/common/route';

export const qaFreshDeliKeys = {
  detail: (productId) => ['qa-spec', parseInt(productId), 'fresh-deli'],
  snapshot: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-fresh-deli',
  ],
};

export const useGetFreshDeli = (productId) => {
  return useQuery({
    queryKey: qaFreshDeliKeys.detail(productId),
    queryFn: async () => {
      const response = await qaSpecServices.getQaSpecDeli({ productId });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
  });
};

export const useSaveFreshDeli = (productId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: qaSpecServices.saveQaSpecDeli,
    onSuccess: (response) => {
      if (response?.isSuccess) {
        queryClient.invalidateQueries({
          queryKey: qaFreshDeliKeys.detail(productId),
        });
        CustomNotification.success('Update Fresh-Deli data successfully!');
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
      }
    },
    onError: (error) => {
      CustomNotification.error(error || 'Server Error');
    },
  });
};

export const useQueryQaFreshDeliSnapshot = ({ productId, ...options } = {}) => {
  return useQuery({
    queryKey: qaFreshDeliKeys.snapshot(productId),
    queryFn: async () => {
      const { isSuccess, data, message } =
        await qaSpecServices.getQaSpecDeliSnapshot({ productId });
      if (isSuccess) {
        return data;
      }

      return Promise.reject(message || 'Server Error');
    },
    ...options,
  });
};
