import React, { useState, useEffect, useContext } from 'react';

import { Typography } from 'antd';
import { useDragDropPosHooks } from 'common/components';
import { DragContext } from 'common/components/drapDropWrap/drag-drop-pos/context/DragContext';

import { COMPONENT_TYPE, CREATE_NEW_STATUS } from '../../constants';
import {
  useTogglePageWrapLayer,
  useGetNewComponentPosition,
  useRemoveNewComponentOutSizeContainer,
} from '../../hooks';

import './PageEditorToolAddNewText.less';

const { Text } = Typography;

const PageEditorToolAddNewText = (props) => {
  const {
    wrapId,
    wrapKey,
    selectedHook,
    detailShowTargetHook,
    showDetailComponentData,
    componentHandlers,
    scale,
  } = props;

  const [selected, setSelected, clearSelect] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const startDragHook = useState();
  const [startDrag, setStartDrag] = startDragHook;
  const [newKey, setNewKey] = useState(null);
  const createStatusHook = useState(CREATE_NEW_STATUS.DONE);
  const [createStatus, setCreateStatus] = createStatusHook;

  const { onCreateNewComponent, onDragNewComponent } = componentHandlers;

  const addNewTextKey = `${wrapKey}-new-text`;

  const [dragPosInst, dragStyle, refDragTag] = useDragDropPosHooks([
    clearSelect,
    addNewTextKey,
  ]);

  const togglePageWrapLayer = useTogglePageWrapLayer(wrapKey);

  const newPosition = useGetNewComponentPosition(
    wrapKey,
    COMPONENT_TYPE.TEXT,
    scale
  );

  const CreateNewText = () => {
    const newComponentKey = onCreateNewComponent({
      componentType: COMPONENT_TYPE.TEXT,
      values: {
        textTop: newPosition?.top,
        textLeft: newPosition?.left,
      },
    });

    setNewKey(newComponentKey);
    setDetailShowTarget(newComponentKey);
  };

  const onDragNewText = async () => {
    CreateNewText();
    setStartDrag(true);
    setCreateStatus(CREATE_NEW_STATUS.START);
  };

  const stopDragNewText = () => {
    // setNewKey(false);
  };

  const isDragging = useContext(DragContext);

  useRemoveNewComponentOutSizeContainer(
    createStatusHook,
    isDragging,
    newKey,
    componentHandlers
  );

  useEffect(() => {
    //* start handle move new component
    const startMoveNewComponent = () => {
      if (startDrag && newKey) {
        onDragNewComponent({
          showDetailComponentData,
          key: newKey,
          fieldNameX: 'textLeft',
          fieldNameY: 'textTop',
          createStatusHook,
          startDragHook,
          wrapId,
          dragPosInst,
        });
      }
    };

    startMoveNewComponent();
  }, [startDrag, showDetailComponentData, newKey]);

  return (
    <div className='page-editor-add-new-text'>
      <div
        onMouseDown={onDragNewText}
        onMouseLeave={stopDragNewText}
        id={addNewTextKey}
        ref={refDragTag}
      >
        <Text className='page-editor-add-new-text__new-icon'>New Text</Text>
      </div>
    </div>
  );
};

export default PageEditorToolAddNewText;
