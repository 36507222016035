export const URL_BLOCKING_EVENT_NAME = 'urlBlocked';

export const dispatchCustomReactRouterBlockingEvent = ({ location } = {}) => {
  const customBlockRoutingEvent = new CustomEvent(URL_BLOCKING_EVENT_NAME, {
    detail: { location },
  });

  window.dispatchEvent(customBlockRoutingEvent);
};

export const CANCEL_ROUTING_EVENT_NAME = 'cancelRouting';

export const dispatchCancelRouting = () => {
  const cancelRoutingEvent = new CustomEvent(CANCEL_ROUTING_EVENT_NAME, {
    detail: {},
  });

  window.dispatchEvent(cancelRoutingEvent);
};
