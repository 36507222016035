import React from 'react';
import { Col, Row, Divider, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { bytesToMegaBytes } from 'utils/string';

const { Text, Link } = Typography;

const EmailFileList = (props) => {
  const { fileList } = props;

  const handleDownloadAll = async () => {
    const fileArrUrl = fileList?.map((file) => file?.attachmentUrl);

    var link = document.createElement('a');

    link.setAttribute('download', null);
    link.style.display = 'none';
    document.body.appendChild(link);

    for (var i = 0; i < fileArrUrl.length; i++) {
      link.setAttribute('href', fileArrUrl[i]);
      link.click();
    }
  };

  if (!fileList || fileList?.length <= 0) return null;
  return (
    <Col span={24} className='email-thread__mail__files'>
      {fileList?.length > 1 && (
        <Divider orientation='right'>
          <Text onClick={handleDownloadAll}>Download All</Text>
        </Divider>
      )}
      {fileList?.map((attach) => {
        return (
          <Row
            className='email-thread__mail-attachment-item'
            style={{
              borderTop: fileList?.length === 1 ? '1px solid gainsboro' : 0,
            }}
          >
            <Col className='email-thread__mail-attachment-name' flex={1}>
              <Link ellipsis href={attach?.attachmentUrl}>
                <LinkOutlined className='email-thread__mail-attachment-icon' />
                {attach?.fileName}
              </Link>
            </Col>
            <Col flex='50px'>
              <Text>{bytesToMegaBytes(attach?.fileSize) + 'MB'}</Text>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

export default EmailFileList;
