import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { FormOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/recipients-fields';

import { RibbonButton } from 'common/components';
import { SaveRequiredFields } from './ShareComponent';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import { getRequiredFieldShortDetail } from 'services/recipients-fields';

import { useAsync } from 'hooks';

const EditField = () => {
  const selectedIds = useSelector(gridSelector.makeSelectItemsSelection());

  const [visible, setVisible] = useState(false);

  const { data, loading } = useRequireFieldDetail({
    id: selectedIds?.[0],
    isEnabled: selectedIds?.length === 1 && visible,
  });

  return (
    <>
      <RibbonButton
        disabled={selectedIds?.length !== 1}
        icon={<FormOutlined />}
        label={Messages.editField}
        onClick={() => setVisible(true)}
      />

      {visible && (
        <SaveRequiredFields
          visible={visible}
          onCancel={() => setVisible(false)}
          editedData={data}
          loading={loading}
          isEdit
        />
      )}
    </>
  );
};

export const useRequireFieldDetail = ({ id, isEnabled }) => {
  const { data, run, status } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(getRequiredFieldShortDetail({ id }));
    }
  }, [id, isEnabled, run]);

  return {
    data,
    loading: status === 'pending',
  };
};

export default EditField;
