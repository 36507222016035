import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import CategoriesControl from '../../controls/member-grid/CategoriesControl';
import userSelectors from 'redux/user/selectors';

const CategoriesSection = () => {
  const dispatch = useDispatch();
  const searchCategories = useSelector(
    userSelectors.makeSelectUserSearchCategories()
  );

  const searchCategoriesEffected = useSelector(
    selectorsGridView.makeSelectSearchCategoriesList()
  );

  useEffect(() => {
    searchCategoriesEffected === null && // initial = null, if searchCategoriesEffected = null, make searchCategoriesEffected=[] in the first place
      dispatch(gridViewActions.setUserSearchCategoriesEffected([]));
  }, [searchCategories]);

  return (
    <CategoriesControl
      searchCategories={searchCategories}
      searchCategoriesEffected={searchCategoriesEffected}
    />
  );
};

export default CategoriesSection;
