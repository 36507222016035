import React from 'react';
import { Typography } from 'antd';
import { LayoutOutlined } from '@ant-design/icons';
import ViewControl from './InlineControl';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/home';
const { Text } = Typography;
const QuickViewControl = (props) => {
  const { quickViewClick } = props;
  return (
    <ViewControl
      icon={
        <LayoutOutlined
          style={{
            fontSize: 14,
            transform: 'rotate(-90deg)',
          }}
        />
      }
      text={
        <Text className='text'>
          <FormattedMessage {...Messages.quickView} />
        </Text>
      }
      onClick={quickViewClick}
    ></ViewControl>
  );
};

QuickViewControl.propTypes = {};

export default QuickViewControl;
