import React from 'react';
import { Row, Col } from 'antd';

import { formatMDY } from 'utils/formatDate';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/folder';

const QueryTileFooter = (props) => {
  const { dataDetail } = props;

  const dateCreated = get(dataDetail, 'created', '');
  const lastUpdated = get(dataDetail, 'lastModified', '');

  return (
    <Row className='query-tile__footer' justify='center' align='middle'>
      <Col flex='auto'>
        <Row>
          <Col style={{ width: '85px', color: '#000' }}>
            <FormattedMessage {...Messages.createdDate} />
          </Col>
          <Col>{dateCreated && formatMDY(dateCreated)}</Col>
        </Row>
        <Row>
          <Col style={{ width: '85px', color: '#000' }}>
            <FormattedMessage {...Messages.lastUpdated} />
          </Col>
          <Col>{lastUpdated && formatMDY(lastUpdated)}</Col>
        </Row>
      </Col>
    </Row>
  );
};

QueryTileFooter.propTypes = {
  size: PropTypes.string,
  lastUpdate: PropTypes.string,
};

export default QueryTileFooter;
