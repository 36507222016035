import React from 'react';
import Messages from 'i18n/messages/home';

import { ReactComponent as IconAddBrand } from 'common/components/button/svg-icons/ICO_10.44.svg';

import RibbonButton from 'common/components/button/RibbonButton';

const AddBrand = () => {
  return (
    <RibbonButton
      icon={<IconAddBrand style={{ height: 26 }} />}
      label={Messages.addBrand}
    />
  );
};

export default AddBrand;
