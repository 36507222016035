import React from 'react';

import { Tooltip, Typography } from 'antd';

import './QaGridCell.less';

const TextRender = (props) => {
  const { value } = props;

  return (
    <div className='qa-grid-cell__wrapper'>
      <Tooltip title={value}>
        <Typography.Text ellipsis className='ag-edit__text-render'>
          {value}
        </Typography.Text>
      </Tooltip>
    </div>
  );
};

export default TextRender;
