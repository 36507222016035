import React from 'react';

import EmailEditorSection from './EmailEditorSection';
import EmailEditorContentElement from './EmailEditorContentElement';
import EmailEditorTag from './EmailEditorTag';

import {
  EmailEditorSectionSetting,
  EmailEditorContentSettings,
} from '../settings';

import { useEmailEditorContext } from '../../controllers/context';
import { EMAIL_EDITOR_BLANK_CONTENT_PLACEHOLDER } from 'static/Constants';

const EmailEditorContent = () => {
  const { template } = useEmailEditorContext();

  const content =
    template?.id === 1 ? (
      <div>
        We are currently reviewing your products for our upcoming
        <EmailEditorTag tag='projectName' /> project and would like to request
        samples for evaluation. Please submit
        <EmailEditorTag tag='numberSample' /> samples of each product identified
        to the following address. Be sure to include samples in retail packaging
        to ensure full evaluation of both product and packaging quality.
      </div>
    ) : (
      <div>
        <span>{EMAIL_EDITOR_BLANK_CONTENT_PLACEHOLDER} </span>
        <EmailEditorTag tag='projectName' />{' '}
        {EMAIL_EDITOR_BLANK_CONTENT_PLACEHOLDER}
        <EmailEditorTag tag='numberSample' />{' '}
      </div>
    );

  return (
    <EmailEditorSectionSetting settings={<EmailEditorContentSettings />}>
      <EmailEditorSection tags={['projectName', 'numberSample']}>
        <EmailEditorContentElement
          freeTyping
          content={content}
          suppressContentEditableWarning={true}
        />
      </EmailEditorSection>
    </EmailEditorSectionSetting>
  );
};

EmailEditorContent.craft = {
  displayName: 'Content',
  props: {
    text: '',
    settings: {
      bold: false,
      italic: false,
      underLine: false,
      fontSize: 14,
      color: '#333',
    },
  },
  custom: { tags: ['projectName', 'numberSample'] },
};

export default EmailEditorContent;
