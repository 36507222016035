import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRoleState = (state) => state.role || initialState;

const selectRoleList = () =>
  createSelector(selectRoleState, (state) => state.roleList);

const selectAvailPermission = () =>
  createSelector(selectRoleState, (state) => state.availPermissionList);

const selectUserRoleColDef = () =>
  createSelector(selectRoleState, (state) => state.roleUserColDef);

const selectSelectedRole = () =>
  createSelector(selectRoleState, (state) => state.selectedRole);

const selectRolePermisionList = () =>
  createSelector(selectRoleState, (state) => state.rolePermissionList);

//* security matrix
const selectMemberRoleList = () =>
  createSelector(selectRoleState, (state) => state.memberRoleList);

const selectSelectedMemberRole = () =>
  createSelector(selectRoleState, (state) => state.selectedMemberRole);

const selectAvailMemberPermissionList = () =>
  createSelector(selectRoleState, (state) => state.availMemberPermissionList);

const selectMemberRoleColDef = () =>
  createSelector(selectRoleState, (state) => state.roleMemberColDef);

const selectMemberNotInRoleColDef = () =>
  createSelector(selectRoleState, (state) => state.roleMemberNotInRoleColDef);

export {
  selectAvailPermission,
  selectUserRoleColDef,
  selectRoleList,
  selectSelectedRole,
  selectRolePermisionList,
  selectMemberRoleList,
  selectSelectedMemberRole,
  selectAvailMemberPermissionList,
  selectMemberRoleColDef,
  selectMemberNotInRoleColDef,
};
