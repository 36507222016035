import React from 'react';

import ThumbnailItem from 'common/components/thumb/ThumbnailItem';
import { Row, Col, Typography, Button } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import * as getLink from 'utils/common/linkBuilder';
import { Images } from 'config/assets';
import classnames from 'classnames';

import './MemberMapping.less';

const { Text } = Typography;

const slideInRight = classnames(
  'ani--slideInLeft',
  'ani--fill-forward',
  'ani--iter-inf',
  'ani--easy'
);

const DummyMember = (props) => {
  const { text } = props;

  return (
    <div className='move-all-to-member-mapping__dummy-wrapper'>
      <div className='move-all-to-member-mapping__dummy'>
        <Text type='secondary'>{text && text}</Text>
      </div>
    </div>
  );
};

const MemberMapping = (props) => {
  const { originMember, targetMember, onRemoveTargetMember } = props;

  return (
    <Row style={{ paddingBottom: 5 }}>
      <Col style={{ paddingRight: 12 }}>
        <ThumbnailItem
          dataDetail={originMember}
          labelThumbnail={getLink.memberLink(
            originMember?.id || originMember?.companyId,
            originMember?.memberName,
            '_blank'
          )}
          imageThumbnail={
            originMember?.thumb300 ||
            originMember?.thumb ||
            originMember?.logo ||
            Images.RIVIR_LOGO_DEFAULT
          }
          hideCheckbox
          isMemberThumbnail
        />
      </Col>
      <Col
        flex={1}
        className={classnames('move-all-to-member-mapping__moving')}
      >
        <div
          className={classnames('move-all-to-member-mapping__icon', {
            'move-all-to-member-mapping__icon--moving': !!targetMember,
            [slideInRight]: !!targetMember,
          })}
          style={{ animationDuration: '1.3s' }}
        >
          <DoubleRightOutlined style={{ fontSize: 30 }} />
        </div>
      </Col>
      <Col style={{ paddingRight: 12 }}>
        {targetMember ? (
          <div style={{ position: 'relative' }}>
            <ThumbnailItem
              dataDetail={targetMember}
              labelThumbnail={getLink.memberLink(
                targetMember?.id || targetMember?.companyId,
                targetMember?.companyName,
                '_blank'
              )}
              imageThumbnail={
                targetMember?.thumb300 ||
                targetMember?.thumb ||
                targetMember?.logo ||
                Images.RIVIR_LOGO_DEFAULT
              }
              hideCheckbox
              isMemberThumbnail
              isShareBtn={false}
            />
            <Button
              className='move-all-to-member-mapping__btn-remove-target-member'
              danger
              type='primary'
              shape='circle'
              onClick={onRemoveTargetMember}
            >
              <i class='fa fa-times' aria-hidden='true'></i>
            </Button>
          </div>
        ) : (
          <DummyMember text='Target Member' />
        )}
      </Col>
    </Row>
  );
};

export default MemberMapping;
