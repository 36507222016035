import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ViewLayout, AdvanceStack } from './components';
import { Divider } from 'antd';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import Addition from './components/sections/home-grid/AdditionSection';
import Categories from './components/sections/home-grid/Categories';
import Execute from './components/sections/home-grid/Execute';
import BackControl from './components/controls/home/Back';
import FilterPackageSection from './components/sections/product/FilterPackageSection';
import FilterProductSection from './components/sections/product/FilterProductSection';
import HistorySection from './components/sections/product/HistorySection';
import OpenProduct from './components/sections/product/OpenProduct';
import ActionProduct from './components/sections/product/ActionProduct';
import ShareSection from './components/sections/shared/ShareSection';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';
import * as ribbonSelectors from 'redux/ribbon/selectors';

import * as selectorProduct from 'pages/branded-products/controllers/selectors';
import * as selectorsGlobal from 'redux/global/selectors';

import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';

import { ROUTE } from 'static/Constants';

import { useGetMemberId } from 'pages/branded-members/components/products-for-member/hooks';

const ProductForMemberRibbon = () => {
  const selectedProducts = useSelector(
    gridViewSelectors.makeSelectDetailCurrentITemsSelection()
  );
  const isDisplayProductNew = useSelector(
    selectorsGlobal.selectDisplayProductNew()
  );

  const products = useSelector(selectorProduct.makeSelectProducts());

  const isFavoriteRoute = useCheckFavoriteRoute();

  const searchCategoriesEffected = useSelector(
    gridViewSelectors.makeSelectSearchCategoriesList()
  );

  const { productForMemberText } = useSelector(
    selectorsGlobal.selectSelectSearchText()
  );

  const searchText = productForMemberText;

  const filterProducts = useSelector(selectorsGlobal.selectFilterProducts());

  const queryConditions = useSelector(
    gridViewSelectors.makeSelectProductsForMemberQuery()
  );

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  const { memberId } = useGetMemberId();

  const disabledFilterSearch = useMemo(() => {
    let nextDisableFilterSearch = true;

    if (
      searchCategoriesEffected?.length > 0 ||
      searchText?.length > 0 ||
      filterProducts?.length > 0 ||
      queryConditions?.length > 0
    ) {
      nextDisableFilterSearch = false;
    }
    if (products?.length === 0 || !products) {
      nextDisableFilterSearch = true;
    }

    return nextDisableFilterSearch;
  }, [products, filterProducts, searchText, JSON.stringify(queryConditions)]);

  const additionDisabled =
    selectedProducts?.length === 0 && disabledFilterSearch;

  // const isDisabledCloseDetail =
  //   window.location.search !== '?query=1' ||
  //   closeDetailStack?.name !== 'query' ||
  //   closeDetailStack?.stack.length < 2;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabledGridQuery={isDisplayProductNew} />
      </Can>

      <RibbonDivider />
      <Addition
        type='product'
        selectedItemList={selectedProducts}
        isDisabled={additionDisabled}
        disabledFilterSearch={disabledFilterSearch}
        searchCategoriesEffected={searchCategoriesEffected}
        searchText={searchText}
        filterProducts={filterProducts}
        queryConditions={queryConditions}
        isFavoriteView={isFavoriteRoute}
      />
      <ShareSection selectedItemList={selectedProducts} />
      <RibbonDivider />
      {/* TODO: Add role permission later */}
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <OpenProduct />
      </Can>
      <RibbonDivider />

      <ActionProduct
        viewType={ROUTE.MEMBER_PRODUCTS}
        memberId={parseInt(memberId)}
      />

      <RibbonDivider />
      <FilterPackageSection entityType='memberProduct' />
      <Categories />
      <FilterProductSection
        viewType={ROUTE.MEMBER_PRODUCTS}
        entityType='memberProduct'
      />
      {/* <RibbonDivider /> */}
      {/* <SubscriptionSection />
      <SyndicationSection /> */}
      <Divider
        type='vertical'
        style={{ height: 40, margin: '4px 8px', marginLeft: 12 }}
      />
      {/* TODO: Add role permission later */}
      <Execute entityType='Product' />
      <RibbonDivider />
      <HistorySection />

      {/* <PublicationSetup products={selectedProducts} />
      <GDSNPublication products={selectedProducts} /> */}
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <RibbonDivider />
        <AdvanceStack
          isNested
          selectedItemList={selectedProducts}
          disabledAdvanceFilter={isDisplayProductNew}
          // disabledAdvanceFilter={true}
        />
      </Can>
      <BackControl ribbonSide='right' />
    </RibbonBar>
  );
};

export default ProductForMemberRibbon;
