import React from 'react';

import { Divider } from 'antd';

import { ViewLayout, AdvanceStack } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import CreateNewRoleSection from './components/sections/role-security-management/CreateNewRoleSection';
import BackControl from './components/controls/home/Back';

const RibbonRoleAndSecurityManage = () => {
  const isMaintenance = window.location.pathname.includes('/security/member/');

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <RibbonDivider />
      <CreateNewRoleSection />
      <RibbonDivider />
      <AdvanceStack isDisabled={true} />
      {isMaintenance ? <BackControl ribbonSide='right' /> : null}
    </RibbonBar>
  );
};

export default RibbonRoleAndSecurityManage;
