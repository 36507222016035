import React from 'react';
import { Switch } from 'antd';

import { ButtonSmallIcon } from 'common/components';

const DataSwitchButton = ({ checked, onClick }) => {
  return (
    <ButtonSmallIcon
      icon={
        <Switch checked={checked} size='small' style={{ marginRight: 4 }} />
      }
      label={'Basic Mode'}
      onClick={onClick}
    />
  );
};

export default DataSwitchButton;
