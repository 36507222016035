import { useSelector } from 'react-redux';

import { CustomNotification } from 'common/components';
import { getNewResendOtpTime } from '../utils/utils';

import userSelectors from 'redux/user/selectors';

import {
  useGetOtpForUnlockAccountByEmail,
  useGetOtpForUnlockAccountByPhone,
  useGetOtpForNewMfaPhoneUserProfile,
  useGetOtpForLogin,
} from '../queries';

import { LOGIN_SESSION_EXP_MESSAGE_FROM_BE } from '../constants';

export const useOtpTrigger = ({
  formInst,
  userInfo,
  setChangeMfaPhoneSession,
  setStep,
  setTimeAllowResend,
  loginSession,
  setError,
  setMfaSession,
  setOtpCheckError,
  setIsSessionExpired,
}) => {
  const getOtpForUnlockAccountByEmail = useGetOtpForUnlockAccountByEmail();
  const getOtpForUnlockAccountByPhone = useGetOtpForUnlockAccountByPhone();
  const getOtpForNewMfaPhoneUserProfile = useGetOtpForNewMfaPhoneUserProfile();
  const getOtpForLogin = useGetOtpForLogin();

  const { isLoading: loadingGetOtpUnlockAccountByEmail } =
    getOtpForUnlockAccountByEmail;

  const { isLoading: loadingGetOtpUnlockAccountByPhone } =
    getOtpForUnlockAccountByPhone;

  const { isLoading: loadingGetOtpForNewMfaPhoneUserProfile } =
    getOtpForNewMfaPhoneUserProfile;

  const { isLoading: loadingGetOtpForLogin } = getOtpForLogin;

  const isLoading =
    loadingGetOtpUnlockAccountByEmail ||
    loadingGetOtpUnlockAccountByPhone ||
    loadingGetOtpForNewMfaPhoneUserProfile ||
    loadingGetOtpForLogin;

  const showError = (errorMessage) => {
    if (typeof errorMessage === 'string') {
      setError(errorMessage);
    }

    if (errorMessage === LOGIN_SESSION_EXP_MESSAGE_FROM_BE) {
      setIsSessionExpired && setIsSessionExpired(true);
    }
  };

  const showErrorOnOtpCheck = (errorMessage) => {
    if (typeof errorMessage === 'string') {
      setOtpCheckError(errorMessage);
    }
    if (errorMessage === LOGIN_SESSION_EXP_MESSAGE_FROM_BE) {
      setIsSessionExpired && setIsSessionExpired(true);
    }
  };

  const unlockAccountLoginInfo = useSelector(
    userSelectors.makeSelectUnlockAccountInfo()
  );

  const handleExpception = (err) => {
    if (err?.errorFields?.[0]?.errors) {
      return;
    }
  };

  const onGetOtpForNewMfaPhoneUserProfile = ({ isResend }) => {
    formInst
      .validateFields()
      .then((values) => {
        const { phoneNumber: newPhoneNumber } = values || {};

        const params = {
          userId: userInfo?.id,
          mfaPhoneNumber: newPhoneNumber,
        };

        getOtpForNewMfaPhoneUserProfile
          .mutateAsync(params)
          .then((data) => {
            setChangeMfaPhoneSession && setChangeMfaPhoneSession(data);
            setStep(1);
            setTimeAllowResend(getNewResendOtpTime());
          })
          .catch((errorMessage) => {
            if (!isResend) {
              showError(errorMessage);
            } else {
              showErrorOnOtpCheck(errorMessage);
            }
          });
      })
      .catch((err) => {
        handleExpception(err);
      });
  };

  const onGetOtpForLoginAllowAddMfaPhone = ({ isResend }) => {
    formInst
      .validateFields()
      .then((values) => {
        const { phoneNumber: newPhoneNumber } = values || {};

        const params = {
          loginSession,
          mfaPhoneNumber: newPhoneNumber,
        };

        getOtpForLogin
          .mutateAsync(params)
          .then(() => {
            setError('');
            setStep(1);
            CustomNotification.success('The OTP was sent to your phone');
            setTimeAllowResend(getNewResendOtpTime());
          })
          .catch((errorMessage) => {
            if (!isResend) {
              showError(errorMessage);
            } else {
              showErrorOnOtpCheck(errorMessage);
            }
          });
      })
      .catch((err) => {
        handleExpception(err);
      });
  };

  const onGetOtpForLogin = ({ isResend }) => {
    const params = {
      loginSession,
    };

    getOtpForLogin
      .mutateAsync(params)
      .then(() => {
        setError('');
        setStep(1);
        CustomNotification.success('The OTP was sent to your phone');
        setTimeAllowResend(getNewResendOtpTime());
      })
      .catch((errorMessage) => {
        if (!isResend) {
          showError(errorMessage);
        } else {
          showErrorOnOtpCheck(errorMessage);
        }
      });
  };

  const onGetOtpForUnlockAccountByPhone = ({ isResend }) => {
    formInst
      .validateFields()
      .then((values) => {
        const { phoneNumber } = values || {};
        const { userName } = unlockAccountLoginInfo || {};

        const params = {
          userName,
          mfaPhoneNumber: phoneNumber,
        };

        getOtpForUnlockAccountByPhone
          .mutateAsync(params)
          .then((data) => {
            setMfaSession && setMfaSession(data);
            setError('');
            setStep(1);
            CustomNotification.success('The OTP was sent to your phone');
            setTimeAllowResend(getNewResendOtpTime());
          })
          .catch((errorMessage) => {
            if (!isResend) {
              showError(errorMessage);
            } else {
              showErrorOnOtpCheck(errorMessage);
            }
          });
      })
      .catch((err) => {
        handleExpception(err);
      });
  };

  const onGetOtpForUnlockAccountByEmail = ({ isResend }) => {
    formInst
      .validateFields()
      .then((values) => {
        const { email } = values || {};
        const { userName } = unlockAccountLoginInfo || {};

        const params = {
          userName,
          email,
        };

        getOtpForUnlockAccountByEmail
          .mutateAsync(params)
          .then((data) => {
            setMfaSession && setMfaSession(data);
            setError('');
            setStep(1);
            CustomNotification.success('The OTP was sent to your email');
            setTimeAllowResend(getNewResendOtpTime());
          })
          .catch((errorMessage) => {
            if (!isResend) {
              showError(errorMessage);
            } else {
              showErrorOnOtpCheck(errorMessage);
            }
          });
      })
      .catch((err) => {
        handleExpception(err);
      });
  };

  return {
    onGetOtpForNewMfaPhoneUserProfile,
    onGetOtpForLoginAllowAddMfaPhone,
    onGetOtpForLogin,
    onGetOtpForUnlockAccountByEmail,
    onGetOtpForUnlockAccountByPhone,
    isLoading,
  };
};
