import React from 'react';

import { RibbonSection } from 'common/components';
import DashboardLayout from '../../controls/dashboard/DashboardLayout';
import ResetLayout from '../../controls/dashboard/ResetLayout';

const LayoutSection = (props) => {
  return (
    <RibbonSection>
      <DashboardLayout />
      <ResetLayout />
    </RibbonSection>
  );
};

export default LayoutSection;
