import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import classnames from 'classnames';
import * as _ from 'lodash';

const fixDefaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

const HEADER_HEIGHT = 32;
const ROW_HEIGHT = 32;
const OTHER_HEIGHT = 3;

const fixCheckboxSelection = true;

function AgGridClient(props) {
  const { relatedGridsApi, relatedFuncsAndValues, mapToColumnDef } = props;
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  function sendAgApiToParent(gridApi, columApi, parentCallBack) {
    !!parentCallBack && parentCallBack(gridApi, columApi);
  }

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    sendAgApiToParent(params.api, params.columnApi, _.get(props, 'getAgApi'));
  }

  //* defaultColDef process
  let defaultColDef = {
    ...fixDefaultColDef,
    ..._.get(props, 'defaultColDef', {}),
  };
  defaultColDef = _.pickBy(defaultColDef, _.identity);

  //* checkbox selection
  const checkboxSelection =
    _.get(props, 'checkboxSelection') || fixCheckboxSelection;

  //! columnDefs is absolutely passed config -- might vary if need
  let columnDefs = _.get(props, 'columnDefs', []);

  //* combine all configs
  let configs = {
    ...props,
    defaultColDef,
    rowSelection: _.get(props, 'rowSelection'),
    suppressSizeToFit: _.get(props, 'suppressSizeToFit'),
    checkboxSelection,
    rowData: _.get(props, 'rowData'),
    onGridReady: onGridReady,

    headerHeight: HEADER_HEIGHT,
    rowHeight: ROW_HEIGHT,
    disableStaticMarkup: true,
  };
  configs = _.pickBy(configs, _.identity);

  const calculateHeight = (rowData, rowHeight, headerHeight) => {
    if (!rowData && !rowHeight && !headerHeight) return 0;
    return rowData.length * rowHeight + headerHeight;
  };

  useEffect(() => {
    if (!!mapToColumnDef) {
      columnDefs = mapToColumnDef(
        [...columnDefs],
        relatedGridsApi,
        relatedFuncsAndValues
      );
      !_.isEmpty(gridApi) && gridApi.setColumnDefs(columnDefs || []);
    }
  }, [relatedGridsApi, relatedFuncsAndValues, gridApi, gridColumnApi]);

  return (
    <div
      style={{
        height: calculateHeight(
          _.get(configs, 'rowData', []),
          ROW_HEIGHT,
          HEADER_HEIGHT + OTHER_HEIGHT
        ),
      }}
      className={classnames({
        'tt-ag-grid-client': true,
      })}
    >
      <AgGridReact {...configs} enableCellChangeFlash>
        {columnDefs.map((columnDef, idx) => (
          <AgGridColumn {...columnDef} />
        ))}
      </AgGridReact>
    </div>
  );
}

export default AgGridClient;
