import React from 'react';
import { useDispatch } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import * as globalActions from 'redux/global/actions';

import AddOpenItemBtn from '../../controls/common/AddOpenItemBtn';

const MemberAddOpenItemSection = () => {
  const dispatch = useDispatch();

  const toggleAddOpenItem = () => {
    dispatch(globalActions.toggleIsAddingOpenItem(true));
  };

  return (
    <ContainerButton style={{ display: 'flex', maxWidth: 250 }}>
      <ItemButton span={12}>
        <AddOpenItemBtn
          clickActionCallback={() => {
            toggleAddOpenItem();
          }}
        />
      </ItemButton>
    </ContainerButton>
  );
};

export default MemberAddOpenItemSection;
