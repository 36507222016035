import * as api from 'config/axios';
import * as endpoints from './endpoints';

import { getAnonToken } from 'utils/common/session';

export const anonGetThreadMessage = (params) => {
  const token = getAnonToken();
  if (!token) return;

  const requestParams = {
    threadId: params.threadId,
    timestamp: params.timestamp,
    direction: params.direction,
  };
  return api.sendGet(`${endpoints.ANON_GET_THREAD_MESSAGE}/${token}`, {
    params: requestParams,
  });
};

export const anonGetThreadInfo = (param) => {
  const token = getAnonToken();
  if (!token) return;

  return api.sendGet(`${endpoints.ANON_GET_THREAD_INFO}/${token}`, {
    params: param,
  });
};

export const anonGetChatUsersInfo = (params) => {
  const token = getAnonToken();
  if (!token) return;

  return api.sendPost(`${endpoints.ANON_GET_CHAT_USER_INFO}/${token}`, {
    senderIds: params,
  });
};

export const anonGetSenderInfo = (params) => {
  const token = getAnonToken();
  if (!token) return;

  return api.sendGet(`${endpoints.ANON_GET_SENDER_INFO}/${token}`, {
    params: params,
  });
};

export const makeAThreadAsRead = async (params) => {
  const token = getAnonToken();
  if (!token) return;

  return api.sendPost(`${endpoints.ANON_MAKE_A_THREAD_AS_READ}/${token}`, {
    ...params,
  });
};

export const anonUploadChatFile = (params) => {
  const token = getAnonToken();
  if (!token) return;

  return api.sendPost(endpoints.ANON_UPLOAD_CHAT_FILE, params);
};

export const anonDownloadChatFile = (params) => {
  const token = getAnonToken();
  if (!token) return;

  return api.sendPost(endpoints.ANON_DOWNLOAD_CHAT_FILE, params);
};

export const anonGetChatFileInfo = (params) => {
  return api.sendPost(endpoints.ANON_GET_CHAT_FILE_INFO, params);
};
