import React from 'react';
import Messages from 'i18n/messages/home';

import { FileExcelOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const ListBan = (props) => {
  const { handleClick } = props;
  return (
    <ButtonSmallIcon
      onClick={() => handleClick()}
      icon={<FileExcelOutlined />}
      label={Messages.listBan}
      style={{ paddingBottom: 0 }}
    />
  );
};

ListBan.propTypes = {};

export default ListBan;
