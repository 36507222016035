/**
 * Folder message
 *
 * This contains all the text for the Folder component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.folder';

export default defineMessages({
  everythingWantDownload: {
    id: `${scope}.components.tile.everythingWantDownload`,
    defaultMessage: 'Everything I Want To Download',
  },
  product: {
    id: `${scope}.components.tile.product`,
    defaultMessage: 'Product',
  },
  asset: {
    id: `${scope}.components.tile.asset`,
    defaultMessage: 'Asset',
  },
  products: {
    id: `${scope}.components.tile.products`,
    defaultMessage: 'Products',
  },
  assets: {
    id: `${scope}.components.tile.assets`,
    defaultMessage: 'Assets',
  },
  createdDate: {
    id: `${scope}.components.tile.createdDate`,
    defaultMessage: 'Create Date:',
  },
  lastUpdated: {
    id: `${scope}.components.tile.lastUpdated`,
    defaultMessage: 'Last Updated:',
  },
  areYouSureToDelete: {
    id: `${scope}.components.panel.areYouSureToDelete`,
    defaultMessage: 'Are you sure to delete the selected item(s)?',
  },
  deleteSuccess: {
    id: `${scope}.components.panel.deleteSuccess`,
    defaultMessage: 'Deleted successfully!',
  },
  folderNameEdit: {
    id: `${scope}.components.panel.edit.folderNameEdit`,
    defaultMessage: 'Folder Name',
  },
  ownEdit: {
    id: `${scope}.components.panel.edit.ownEdit`,
    defaultMessage: 'Owner',
  },
  dateCreatedEdit: {
    id: `${scope}.components.panel.edit.dateCreatedEdit`,
    defaultMessage: 'Date Created',
  },
  dateSharedEdit: {
    id: `${scope}.components.panel.edit.dateSharedEdit`,
    defaultMessage: 'Date Shared',
  },
  editableEdit: {
    id: `${scope}.components.panel.edit.editable`,
    defaultMessage: 'Editable',
  },
  expirationDate: {
    id: `${scope}.components.panel.edit.expirationDate`,
    defaultMessage: 'Expiry Date',
  },
  shareableEdit: {
    id: `${scope}.components.panel.edit.shareableEdit`,
    defaultMessage: 'Shareable',
  },
  specialPurposeEdit: {
    id: `${scope}.components.panel.edit.specialPurposeEdit`,
    defaultMessage: 'Special Purpose',
  },
  itemsEdit: {
    id: `${scope}.components.panel.edit.itemsEdit`,
    defaultMessage: 'Items',
  },
  estSizeEdit: {
    id: `${scope}.components.panel.edit.estSizeEdit`,
    defaultMessage: 'Est Size',
  },
  lastUpdatedEdit: {
    id: `${scope}.components.panel.edit.lastUpdatedEdit`,
    defaultMessage: 'Last Update',
  },
  lastUpdatedByEdit: {
    id: `${scope}.components.panel.edit.lastUpdatedByEdit`,
    defaultMessage: 'Last Update By',
  },
  lastDownloadEdit: {
    id: `${scope}.components.panel.edit.lastDownloadEdit`,
    defaultMessage: 'Last Download',
  },
  lastScheduleEdit: {
    id: `${scope}.components.panel.edit.lastScheduleEdit`,
    defaultMessage: 'Last Schedule',
  },
  inputRequireTextEdit: {
    id: `${scope}.components.panel.edit.pleaseInputTextEdit`,
    defaultMessage: 'This field is required',
  },
  inputOnlyWhitespace: {
    id: `${scope}.components.panel.edit.inputOnlyWhitespace`,
    defaultMessage: 'Only has whitespace is not allowed',
  },
  saveBtnEdit: {
    id: `${scope}.components.panel.edit.saveBtnEdit`,
    defaultMessage: 'Save',
  },
  cancelBtnEdit: {
    id: `${scope}.components.panel.edit.cancelBtnEdit`,
    defaultMessage: 'Cancel',
  },
  createModalTitle: {
    id: `${scope}.create.modal.title`,
    defaultMessage: 'Create a new folder',
  },
  createModalCancelButton: {
    id: `${scope}.create.modal.btn.cancel`,
    defaultMessage: 'Cancel',
  },
  createModalCreateButton: {
    id: `${scope}.create.modal.btn.cancel`,
    defaultMessage: 'Create',
  },
  createModalNameLabel: {
    id: `${scope}.create.form.name.label`,
    defaultMessage: 'Name',
  },
  createModalNamePlaceholder: {
    id: `${scope}.create.form.name.placeholder`,
    defaultMessage: 'Input folder name',
  },
  nameRequiredMessage: {
    id: `${scope}.create.form.name.message.required`,
    defaultMessage: 'This field is required',
  },
  nameWhitespaceMessage: {
    id: `${scope}.create.form.name.message.whitespace`,
    defaultMessage: 'Only whitespace characters are allowed',
  },
  createFolderError: {
    id: `${scope}.create.message.error`,
    defaultMessage: 'Cannot create new folder',
  },
  createFolderSuccess: {
    id: `${scope}.create.message.success`,
    defaultMessage: 'Create new folder successfully!',
  },
  resolutionCustomize: {
    id: `${scope}.assetDownload.resolution.customize`,
    defaultMessage: 'Resolution Customization Error!',
  },
  resolutionMissing: {
    id: `${scope}.assetDownload.resolution.missing`,
    defaultMessage: 'The resolution is missing!',
  },
  resolutionDuplicated: {
    id: `${scope}.assetDownload.resolution.duplicated`,
    defaultMessage: 'The resolution is duplicated!',
  },
  imageSizeCustomize: {
    id: `${scope}.assetDownload.imageSize.customize`,
    defaultMessage: 'Image Size Customization Error!',
  },
  imageSizeMissing: {
    id: `${scope}.assetDownload.imageSize.missing`,
    defaultMessage: 'The image size is missing!',
  },
  imageSizeDuplicated: {
    id: `${scope}.assetDownload.imageSize.duplicated`,
    defaultMessage: 'The image size is duplicated!',
  },
  memberDataFormat: {
    id: `${scope}.download.configuration.memberDataFormat`,
    defaultMessage: 'Data Format',
  },
  memberDefinedSheets: {
    id: `${scope}.download.configuration.memberDefinedSheets`,
    defaultMessage: 'Defined Sheets',
  },
  disabledScheduleDownloadMessage: {
    id: `${scope}.download.message.disabledScheduleDownload`,
    defaultMessage:
      'The schedule download will not be active when select direct download',
  },
  openFolder: {
    id: `${scope}.detail.action.open`,
    defaultMessage: 'Open',
  },
  copyFolder: {
    id: `${scope}.detail.action.copy`,
    defaultMessage: 'Copy Subfolder',
  },
  moveFolder: {
    id: `${scope}.detail.action.copy`,
    defaultMessage: 'Move',
  },
  moreButton: {
    id: `${scope}.detail.action.moreButton`,
    defaultMessage: 'More',
  },
  downloadNowBtn: {
    id: `${scope}.detail.action.downloadNow`,
    defaultMessage: 'Download Now',
  },
  scheduleDownloadBtn: {
    id: `${scope}.detail.action.scheduleDownload`,
    defaultMessage: 'Schedule Download',
  },
  addSubFolder: {
    id: `${scope}.detail.action.addSubFolder`,
    defaultMessage: 'Add Subfolder',
  },
  editSubFolder: {
    id: `${scope}.detail.action.editSubFolder`,
    defaultMessage: 'Edit Subfolder',
  },
  upLevelText: {
    id: `${scope}.detail.action.upLevelText`,
    defaultMessage: 'Back To Previous',
  },
  createFolderSuccessMessage: {
    id: `${scope}.detail.component.message.createFolderSuccessMessage`,
    defaultMessage: 'Create new folder successfully!',
  },
  createFolderFailedMessage: {
    id: `${scope}.detail.component.message.createFolderFailedMessage`,
    defaultMessage: 'Create folder fail!',
  },
  deleteItemsSuccessMessage: {
    id: `${scope}.detail.component.message.deleteItemsSuccessMessage`,
    defaultMessage: 'Delete successfully!',
  },
  deleteItemsFailedMessage: {
    id: `${scope}.detail.component.message.deleteItemsFailedMessage`,
    defaultMessage: 'Delete fail!',
  },
  confirmDeleteItemMessage: {
    id: `${scope}.detail.component.message.confirmDeleteItemMessage`,
    defaultMessage: 'Are you sure you want to delete selected item(s)?',
  },
  confirmDeleteFolderMessage: {
    id: `${scope}.detail.component.message.confirmDeleteFolderMessage`,
    defaultMessage: 'Are you sure you want to delete selected Folder(s)?',
  },
  downloadIncludeAll: {
    id: `${scope}.download.config.includeAll`,
    defaultMessage: 'Include all items in subfolders',
  },
  downloadKeepStructure: {
    id: `${scope}.download.config.keepStructure`,
    defaultMessage: 'Keep subfolder structure',
  },
  copyModalTitle: {
    id: `${scope}.detail.copyModal.title`,
    defaultMessage: 'Copy Folder',
  },
  copyFolderModalTitle: {
    id: `${scope}.detail.copyModal.copyFoldername`,
    defaultMessage: 'Copy Folder',
  },
  copyFoldername: {
    id: `${scope}.detail.copyModal.copyFoldername`,
    defaultMessage: 'Copy folder name',
  },
  nameInvalidMessage: {
    id: `${scope}.detail.copyModal.nameInvalidMessage`,
    defaultMessage: 'Invalid Folder Name',
  },
  copyFolderSuccess: {
    id: `${scope}.detail.copyModal.message.copySuccess`,
    defaultMessage: 'Copy Folder Successfully',
  },
  copyFolderError: {
    id: `${scope}.detail.copyModal.message.copyError`,
    defaultMessage: 'Cannot Copy Folder',
  },
  moveFolderModalTitle: {
    id: `${scope}.detail.moveModal.title`,
    defaultMessage: 'Move',
  },
  moveToRoot: {
    id: `${scope}.detail.moveModal.moveToRoot`,
    defaultMessage: 'Move to root',
  },
  moveFolderSuccess: {
    id: `${scope}.detail.moveModal.message.moveSuccess`,
    defaultMessage: 'Move Item Successfully',
  },
  moveFolderError: {
    id: `${scope}.detail.moveModal.message.moveError`,
    defaultMessage: 'Cannot Move Item',
  },
  moveToRootWarningMessage: {
    id: `${scope}.detail.moveModal.message.moveToRoot`,
    defaultMessage: 'Only folders will be moved',
  },
});
