import React from 'react';

import { Modal } from 'antd';

import ContactCard from '../contact-card/ContactCard';

const ContactCardModal = (props) => {
  const { visible, setVisible, userInfo } = props;

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      footer={false}
      closable={false}
      width={530}
    >
      <ContactCard userInfo={userInfo} />
    </Modal>
  );
};

export default ContactCardModal;
