import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { updateProductIXONEShield } from 'services/product';
import { CustomNotification } from '..';
import { productFullViewKeys } from 'hooks';

const useUpdateIXONEShield = () => {
  const queryClient = useQueryClient();

  const { id } = useParams();

  const updateIXONEShield = useMutation({
    mutationFn: updateProductIXONEShield,
  });

  const handleUpdateIXONEShield = async (ixoneIgnore, ids) => {
    if (ixoneIgnore) {
      await updateIXONEShield.mutateAsync(
        { ids: ids ? ids : [+id], ixOneIgnore: ixoneIgnore },
        {
          onSuccess: (result) => {
            if (result?.isSuccess) {
              CustomNotification.success('Update IX-ONE Shield successfully');
              queryClient.invalidateQueries({
                queryKey: productFullViewKeys.itemData(id),
              });
            } else {
              CustomNotification.error(result?.message || 'server error!');
            }
          },
        }
      );
    }
  };

  return { updateIXONEShield, handleUpdateIXONEShield };
};

export default useUpdateIXONEShield;
