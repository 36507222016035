import React, { useState, useCallback, useRef, useEffect } from 'react';
import reactCSS from 'reactcss';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { PlusOutlined } from '@ant-design/icons';
import useClickOuter from 'hooks/useClickOuter';

import './ColorPicker.less';

const ColorPicker = (props) => {
  const {
    initialColor,
    callbackFunc,
    wrapClassName = '',
    onChange,
    isShowAddBtn = true,
    popoverStyle,
    presetColors,
    width,
  } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor || '#000000');
  const refColorPickerWrap = useRef();

  const handleCloseColorPicker = useCallback(() => {
    setDisplayColorPicker(false);
  }, []);

  useClickOuter(refColorPickerWrap, handleCloseColorPicker);

  var { EditableInput } = require('react-color/lib/components/common');

  var inputStyles = {
    wrap: { marginTop: 10, textAlign: 'center' },
    input: { width: '100%', textAlign: 'center' },
    inputlabel: { fontSize: '12px', color: '#000' },
  };

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev);
  };

  const swatchHandleChange = (color) => {
    setColor(color.hex);
    onChange && onChange(color.hex);
  };

  const hexHandleChange = (color) => {
    setColor(color.Hex);
    onChange && onChange(color.Hex);
  };

  const checkHexColor = useCallback(
    () =>
      /^#[0-9A-F]{6}$/i.test(color.toUpperCase()) ||
      /^#([0-9A-F]{3}){1,2}$/i.test(color.toUpperCase()),
    [color]
  );

  const handleAdd = () => {
    if (checkHexColor()) callbackFunc && callbackFunc(color.toUpperCase());
  };

  const styles = reactCSS({
    default: {
      wrapper: { width: '100%' },
      action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '5px',
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      color: {
        width: '50px',
        height: '14px',
        borderRadius: '2px',
        background: color,
      },
      add: {
        flex: 'none',
        padding: '8px',
        display: 'block',
        cursor: 'pointer',
      },
      popover: popoverStyle || { zIndex: '2', position: 'fixed' },
    },
  });

  return (
    <div className={wrapClassName} style={styles.wrapper}>
      <div style={styles.action}>
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        {isShowAddBtn && (
          <a style={styles.add} onClick={handleAdd}>
            <PlusOutlined />
          </a>
        )}
      </div>
      {displayColorPicker && (
        <div style={styles.popover} ref={refColorPickerWrap}>
          <SketchPicker
            width={width || '95%'}
            disableAlpha
            color={color}
            onChange={swatchHandleChange}
            presetColors={presetColors}
          />
          <EditableInput
            style={inputStyles}
            label='Hex'
            value={color || initialColor}
            onChange={(e) => hexHandleChange(e)}
          />
        </div>
      )}
    </div>
  );
};

ColorPicker.propTypes = {
  initialColor: PropTypes.string,
  callbackFunc: PropTypes.func,
};

export default ColorPicker;
