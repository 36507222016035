import React from 'react';
import classnames from 'classnames';

import { FixedSizeList } from 'react-window';
import { Droppable } from 'react-beautiful-dnd';

const DropZone = ({
  renderClone,
  droppableId,
  isDropDisabled,
  children,
  className,
  ...restProps
}) => {
  return (
    <Droppable
      mode='virtual'
      isDropDisabled={true}
      droppableId={droppableId}
      renderClone={renderClone}
    >
      {(dropProvided) => (
        <>
          <FixedSizeList
            className={classnames({
              ' scroller-extend': true,
              [className]: !!className,
            })}
            useIsScrolling
            outerRef={dropProvided.innerRef}
            {...restProps}
          >
            {children}
          </FixedSizeList>
        </>
      )}
    </Droppable>
  );
};

export default DropZone;
