import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import serviceTypeList from './ServiceTypeMapping';
import classNames from 'classnames';
import { Images } from 'config/assets';

import './ServiceType.less';

const { Text } = Typography;

const ServiceType = (props) => {
  const { className, type, hideTitle } = props;
  const service = serviceTypeList.filter(
    (icon) => icon.value === type?.toLowerCase()
  )[0]?.icon;
  return (
    <div className={`${className} service-type`}>
      <img
        className={classNames({
          'service-type__half-image': !hideTitle,
          'service-type__full-image': hideTitle,
        })}
        src={service ? service : Images.RIVIR_LOGO_DEFAULT}
        alt={type}
      />
      {!hideTitle && <Text>{type || 'UNKNOWN SERVICE'}</Text>}
    </div>
  );
};

ServiceType.propsTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  hideTitle: PropTypes.bool,
};

export default ServiceType;
