import React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, message, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/common';

const CopyButton = (props) => {
  const intl = useIntl();
  const { text } = props;

  const copying = () => {
    message.success(intl.formatMessage(Messages?.copyMsg));
  };

  return (
    <CopyToClipboard text={text}>
      <Tooltip
        placement='top'
        title={intl.formatMessage(Messages?.tooltipCopy)}
      >
        <Button icon={<CopyOutlined />} onClick={copying} type='link' />
      </Tooltip>
    </CopyToClipboard>
  );
};

export default CopyButton;
