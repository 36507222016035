import React, { useState, useEffect } from 'react';

import { Responsive, WidthProvider } from 'react-grid-layout';
import classnames from 'classnames';

// react-grid-layout style
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './ArrangeableLayout.less';

const GridLayout = WidthProvider(Responsive);

let visibleTimeout;

const ArrangeableLayout = (props) => {
  const { layouts, children, ...rest } = props;

  const [visible, setVisible] = useState(false);

  const showGrid = () => {
    visibleTimeout = setTimeout(() => {
      setVisible(true);
    }, 300);
  };

  const clearVisibleTimeout = () => {
    clearTimeout(visibleTimeout);
  };

  useEffect(() => {
    showGrid();
    // unmount
    return () => {
      clearVisibleTimeout();
    };
  }, []);

  const renderChild = (child) => {
    return <div key={child?.props?.itemKey}>{child}</div>;
  };

  return layouts ? (
    <GridLayout
      className={classnames({ 'arrangeable-layout': true, visible: visible })}
      layouts={layouts}
      breakpoints={{ lg: 1366, md: 0 }}
      cols={{ lg: 24, md: 24 }}
      rowHeight={30}
      margin={[5, 5]}
      containerPadding={[5, 5]}
      // resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
      {...rest}
    >
      {children?.length ? children?.map(renderChild) : renderChild(children)}
    </GridLayout>
  ) : null;
};

export default ArrangeableLayout;
