import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Typography, Pagination } from 'antd';
import Messages from 'i18n/messages/home';
import { FormattedMessage } from 'react-intl';

const { Text } = Typography;

const FindEntityInFolderResultFooter = (props) => {
  const {
    totalResult,
    pageSize,
    pageIndex,
    onChangePage,
    classNamePrefix,
    onShowSizeChange,
  } = props;

  return (
    <Row align='center'>
      <Col flex={1}>
        <Pagination
          current={pageIndex}
          total={totalResult}
          pageSize={pageSize}
          onChange={onChangePage}
          className={`${classNamePrefix}__pagination`}
          onShowSizeChange={onShowSizeChange}
          showSizeChanger={true}
        />
      </Col>
      <Col
        style={{ padding: '0px 5px', display: 'flex', alignItems: 'center' }}
      >
        <Text strong type='warning'>
          <FormattedMessage {...Messages.searchEntityInAllFoldersTotal} />:
          &nbsp;
          {totalResult}
        </Text>
      </Col>
    </Row>
  );
};

FindEntityInFolderResultFooter.propTypes = {
  totalResult: PropTypes.number,
  pageSize: PropTypes.number,
  pageIndex: PropTypes.number,
  onChangePage: PropTypes.func,
  classNamePrefix: PropTypes.string,
  onShowSizeChange: PropTypes.func,
};

FindEntityInFolderResultFooter.defaultProps = {
  onChangePage: () => null,
  onShowSizeChange: () => null,
};

export default FindEntityInFolderResultFooter;
