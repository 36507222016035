import React, { useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';

import { RibbonButton, WithAbsoluteContainer } from 'common/components';

import messages from 'i18n/messages/home';

import { SearchNifFormModal } from './components';

const DownloadNewItem = (props) => {
  const { detailItems, isAllowToDownloadNifForm } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleNifListModal = () => setIsModalOpen((prev) => !prev);

  const openExportNifListModal = () => {
    toggleNifListModal();
  };

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        disabled={!isAllowToDownloadNifForm}
        icon={<DownloadOutlined />}
        label={messages.downloadNewItemForm}
        onClick={openExportNifListModal}
      />

      <WithAbsoluteContainer>
        <SearchNifFormModal
          isOpen={isModalOpen}
          toggleModal={toggleNifListModal}
          detailItems={detailItems}
        />
      </WithAbsoluteContainer>
    </>
  );
};

export default DownloadNewItem;
