import logo from 'assets/logo.jpg';
import ixone from 'assets/ixone.png';
import RIVIR_LOGO_DEFAULT from 'assets/RIVIR_Logo_R3-Icon-2.png';
import RIVIR_LOGO_DEFAULT_WHITE_BACKGROUND from 'assets/RIVIR_Logo_R3-Icon-2.jpg';

import RIVIR_BACKGROUND_DEFAULT from 'assets/logo_river.png';
import BROOKSHIRE_LOGO_DEFAULT from 'assets/member/brookshire.png';

import emptyFolder from 'assets/folder/empty-folder.png';
import folder from 'assets/folder/folder.png';
import sharedFolder from 'assets/folder/shared-folder.png';
import eulaHeaderBackground from 'assets/pattern/dark_geometric.png';
import eulaFooterBackground from 'assets/pattern/fancy-cushion.png';

import { ReactComponent as Help } from 'assets/Help.svg';

/**
 * Export all static assets which are defined in assets folder
 */
export const Images = {
  logo,
  ixone,
  emptyFolder,
  folder,
  sharedFolder,
  eulaHeaderBackground,
  eulaFooterBackground,
  RIVIR_LOGO_DEFAULT,
  RIVIR_LOGO_DEFAULT_WHITE_BACKGROUND,
  RIVIR_BACKGROUND_DEFAULT,
  BROOKSHIRE_LOGO_DEFAULT,
};

export const Svg = {
  Help,
};
