import React from 'react';

import { useIsMutating } from '@tanstack/react-query';

import { Button, Typography, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { CustomNotification, StyledModal } from 'common/components';
import {
  GroupElementWrapper as AllergenAdditionWrapper,
  HeaderGroupElement as AllergenHeader,
} from 'pages/product-full-view/shared/components';

import { useVisibleModal, useCheckPermissions } from 'hooks';
import { customAllergenKeys } from './hooks';

import { validateForm } from 'pages/product-full-view/components/product-new-detail-view/utils';
import { getAllRows } from 'pages/qa-spec/utils';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

const PERMISSION_ADD_CUSTOM_ALLERGEN = [
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.QA_SPECIFICATION,
  },
];

const CustomAllergenAddition = (props) => {
  const {
    gridInstance,
    productId,
    regional,
    onAddCustomAllergen: onAddCustomAllergenProp,
    children,
  } = props;

  const [visible, { onModal, offModal }] = useVisibleModal();

  const customAllergenForm = Form.useFormInstance();

  const isMutatingCustomAllergen = useIsMutating({
    mutationKey: customAllergenKeys.addCustomAllergen(productId),
  });

  const hasAddAllergenPermission = useCheckPermissions(
    PERMISSION_ADD_CUSTOM_ALLERGEN
  );

  const { isQASpecLocked } = useCheckQaSpecLocked();

  const modalProps = {
    title: 'Add Allergen',
    visible,
    destroyOnClose: true,
    width: 700,
    maskClosable: false,
    okButtonProps: {
      loading: Boolean(isMutatingCustomAllergen),
    },
  };

  const handleValidateAllergen = async () => {
    const customAllergenValue = await customAllergenForm.validateFields();

    // converting data into a nested format in order to validate its value
    const mappingFormValues = {
      AllergenRelatedInformation: [
        {
          Allergen: [customAllergenValue],
        },
      ],
    };

    const validatedResult = validateForm({ formValues: mappingFormValues });

    if (validatedResult?.length > 0) {
      customAllergenForm.setFields([
        {
          name: 'ContainmentSource',
          errors: [
            <div
              style={{
                paddingTop: 4,
                paddingBottom: 6,
                paddingRight: 12,
              }}
            >
              <Typography.Text style={{ color: '#d93026' }}>
                {validatedResult[0].message}
              </Typography.Text>
            </div>,
          ],
        },
      ]);

      return 'failed';
    }

    customAllergenForm.setFields([
      {
        name: 'ContainmentSource',
        errors: [],
      },
    ]);

    return 'passed';
  };

  const handleCheckDuplicateAllergen = async () => {
    const {
      AllergenTypeCode: allergenType,
    } = await customAllergenForm.validateFields();

    const allRows = getAllRows(gridInstance);

    const allergenTypeList = allRows.map((row) =>
      row?.allergenType?.toLowerCase()
    );

    return allergenTypeList.includes(allergenType?.toLowerCase());
  };

  const handleSubmitAllergen = async () => {
    const isDuplicate = await handleCheckDuplicateAllergen();

    // ContainmentSource will be disabled until LevelOfContainmentCode is set to "May Contain".
    // This validation function is currently unnecessary, but we will keep this logic for future use in case it becomes useful for checking.
    const statusValidation = await handleValidateAllergen();

    if (isDuplicate) {
      CustomNotification.warning(
        'The allergen field already exists. Please add a different field!'
      );
      return;
    }

    if (statusValidation === 'failed') return;

    onAddCustomAllergenProp && onAddCustomAllergenProp();
    offModal();
  };

  return (
    <>
      {!isQASpecLocked ? (
        <Button
          type='default'
          onClick={() => {
            onModal();
            customAllergenForm.setFieldsValue({
              Regional: regional,
            });
          }}
          icon={<PlusOutlined />}
          style={{ borderRadius: 4 }}
          disabled={!hasAddAllergenPermission}
        >
          Add value
        </Button>
      ) : null}

      <StyledModal
        onOk={handleSubmitAllergen}
        onCancel={() => {
          offModal();
          customAllergenForm.resetFields();
        }}
        {...modalProps}
      >
        <AllergenAdditionWrapper style={{ paddingTop: 12, paddingLeft: 4 }}>
          <AllergenHeader header={`${regional} - Allergen`} />
          {children}
        </AllergenAdditionWrapper>
      </StyledModal>
    </>
  );
};

export default CustomAllergenAddition;
