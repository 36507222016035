import React from 'react';
import { useSelector, useState, useEffect } from 'react-redux';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import DownloadAsset from '../../controls/asset/DownloadAsset';
import * as assetSelectors from 'pages/asset-full-view/controllers/selectors';
import homeSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';
import { message } from 'antd';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import { downloadDigitalAssetSelectedId } from 'services/digitalAsset';
import * as api from 'config/axios';

const DownloadAssetSection = (props) => {
  const { viewType } = props;

  const selectedAssetDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const assetFullData = useSelector(assetSelectors.assetDataLoading());

  const drmList = selectedAssetDetail?.filter((item) => item?.drm);

  const isDRM =
    (selectedAssetDetail.length === 1) & (drmList.length === 1)
      ? 'single'
      : (selectedAssetDetail.length > 1) & (drmList.length >= 1)
      ? 'multi'
      : 'none';
  const isDRMfull = assetFullData?.drm ? 'single' : 'none';
  const uniqueId = useSelector(homeSelectors.selectAssetUniqueId());

  const uniqueIds =
    selectedAssetDetail && selectedAssetDetail.length > 0
      ? selectedAssetDetail.map((asset) => {
          return asset?.uniqueId;
        })
      : [];

  const assetIds = uniqueId ? [uniqueId] : uniqueIds;
  const checkIsIframe = () => {
    for (const asset of selectedAssetDetail) {
      if (asset?.isIFrame) {
        return true;
      }
    }
    return false;
  };

  const downloadAction = async () => {
    try {
      const result = await downloadDigitalAssetSelectedId({
        uniqueIds: assetIds,
      });
      const params =
        result && result.data && result.data.url ? result.data.url : '';
      if (result && result.data && result.isSuccess && params !== '') {
        api.sendDownload({
          url: params,
        });
      } else {
        message.error('Download fail! Please try again');
      }
    } catch (error) {
      message.error('Download fail! Please try again');
    }
  };

  return (
    <ContainerButton>
      <ItemButton>
        <DownloadAsset
          downloadAsset={downloadAction}
          isDRM={selectedAssetDetail.length === 0 ? isDRMfull : isDRM}
          disabled={assetIds.length === 0 || checkIsIframe()}
          viewType={viewType}
        />
      </ItemButton>
    </ContainerButton>
  );
};

export default DownloadAssetSection;
