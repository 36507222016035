import React from 'react';

import { Row, Col, Input, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Form, UploadFile, FormButton } from 'common/components';

import * as productEndpoints from 'services/product/endpoints';

import { UPLOAD_MAX_SIZE } from 'static/Constants';
import { SUPPORT_TYPES } from './constants';

export const FormUploadAsset = (props) => {
  return (
    <div style={{ height: 300, marginTop: 12 }}>
      <UploadFile
        multiple={false}
        multipleImport={false}
        supportTypes={SUPPORT_TYPES}
        showSubmitBtn
        showList
        manualUpload
        showPreview
        showMultiple={false}
        apiUrl={productEndpoints.UPLOAD_PIM_ASSET}
        maxSize={UPLOAD_MAX_SIZE.GENERAL}
        {...props}
      />
    </div>
  );
};

export const FormDocumentName = ({
  formInstance,
  isSubmitting,
  onBack,
  onSubmitForm,
}) => {
  return (
    <Form style={{ height: 350 }} form={formInstance} onFinish={onSubmitForm}>
      <Row style={{ flexDirection: 'column', height: '100%', paddingTop: 30 }}>
        <Col>
          <Form.Item label='Document Name' name='documentName'>
            <Input />
          </Form.Item>
        </Col>
        <Col flex={1}></Col>
        <Col>
          <Space style={{ float: 'right' }}>
            <FormButton
              type='default'
              icon={<ArrowLeftOutlined />}
              text='Back to asset creation'
              onClick={onBack}
            />
            <FormButton
              htmlType='submit'
              text='Complete Setup'
              loading={isSubmitting}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
