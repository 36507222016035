import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Input,
  Checkbox,
  Row,
  List,
  Col,
  Avatar,
  Button,
  Spin,
  Radio,
  Typography,
  Tooltip,
} from 'antd';
import { Form } from 'common/components';
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import messages from 'i18n/messages/home';
import * as chatServices from 'services/chatServices';
import * as chatActions from '../../controller/actions';
import userSelectors from 'redux/user/selectors';
import * as api from 'config/axios';
import { CHATTYPE } from 'static/Constants';
import * as emailActions from '../../../mail/controller/actions';
import * as emailServices from 'services/email';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { sortBy } from 'lodash';

import './CreateNewThreadModal.less';

export const CreateNewThreadPrivate = (props) => {
  const { Title } = Typography;

  const CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT = [
    {
      action: ABILITY_ACTION.CHAT,
      subject: ABILITY_SUBJECT.CHAT_PERSONAL,
    },
  ];
  const CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT = [
    {
      action: ABILITY_ACTION.CHAT,
      subject: ABILITY_SUBJECT.CHAT_COMPANY,
    },
  ];

  const [type, setType] = useState(false);
  const [userType, setUserType] = useState('user');
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [form] = Form.useForm();
  const [user, setUser] = useState('Personal');
  const {
    visible,
    closeNewChat,
    setRequestThreadInfo,
    isInEmail,
    communicationListData,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [threadInfo, setThreadInfo] = useState({});

  const userId = useSelector(userSelectors.makeSelectUserId());
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());

  const userSender = `U-${userId}`;
  const memberSender = `C-${memberId}`;
  const creatorId = user === CHATTYPE.COMPANY ? memberSender : userSender;

  const dispatch = useDispatch();

  const hasPersonalChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT
  );
  const hasCompanyChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT
  );

  useEffect(() => {
    if (threadInfo?.id) {
      updateCommunicationThreadList();
      const params = { threadId: threadInfo?.id, userId: creatorId };

      if (isInEmail) {
        dispatch(emailActions.getEmailThreadInfo(params));
        dispatch(emailActions.getEmailThreadInfoContent(threadInfo?.id));
      }
    }
  }, [threadInfo?.id]);

  const updateCommunicationThreadList = () => {
    let result =
      communicationListData?.length > 0 ? [...communicationListData] : [];

    const existedThread = result?.find(
      (thread) => thread.threadId === threadInfo?.id
    );
    if (existedThread) {
      result = sortBy(result, ({ threadId }) =>
        threadId === existedThread?.threadId ? 0 : 1
      );
    } else if (!existedThread && threadInfo?.id) {
      result.unshift({
        ...threadInfo,
        threadId: threadInfo?.id,
        uniqueId: `${creatorId}-${threadInfo?.id}`,
      });
    }
    setThreadInfo({});

    if (isInEmail) {
      dispatch(emailActions.updateEmailThreadList(result));
    }
  };

  useEffect(() => {
    return () => {
      setSearchText('');
    };
  }, [visible]);

  useEffect(() => {
    if (searchText) handleSearch(searchText);
  }, [type]);

  const onChangeHandler = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (item) => {
    !type ? handleSearchUser(item) : handleSearchCompany(item);
  };

  async function handleSearchUser(params) {
    setLoading(true);
    await api
      .sendGet(`/api/chat/search-users-for-chat?SearchText=${params}`)
      .then((response) => {
        if (response?.isSuccess) {
          setUserList(response?.data?.usersForChat);
          setUserType('user');
        }
      });
    setLoading(false);
  }

  async function handleSearchCompany(params, loadingFunction, setFunction) {
    setLoading(true);
    await api
      .sendGet(`/api/chat/search-companies-for-chat?SearchText=${params}`)
      .then((response) => {
        if (response?.isSuccess) {
          setCompanyList(response?.data?.companiesForChat);
          setUserType('company');
        }
      });
    setLoading(false);
  }

  const getThreadInformation = async (threadId, userId) => {
    try {
      const params = {
        ThreadId: threadId,
        UserId: userId,
      };
      const response = await chatServices.getThreadInfo(params);
      if (response?.isSuccess) {
        setThreadInfo(response?.data);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const submitCreateEmail = async (params, name) => {
    const threadName = form.getFieldValue('threadName');
    form.submit();
    if (threadName) {
      const userSubmit = userType === 'user' ? [params] : [];
      const companySubmit = userType === 'company' ? [params] : [];
      const paramsSubmit = {
        userChatType:
          user === CHATTYPE.COMPANY ? CHATTYPE.COMPANY : CHATTYPE.USER,
        chatType: isInEmail ? 'Email' : 'P2P',
        threadName: isInEmail ? threadName : '',
        users: userSubmit,
        companies: companySubmit,
      };
      setLoading(true);
      if (isInEmail) {
        // Email
        try {
          const response = await emailServices.initEmailCommunication(
            paramsSubmit
          );
          if (response?.isSuccess) {
            getThreadInformation(response?.data?.threadId, creatorId);
            closeChat();
          }
        } catch (error) {
          console.log('error: ', error);
        }
      } else {
        try {
          const response = await chatServices.initChatCommunication(
            paramsSubmit
          );
          if (response?.isSuccess) {
            dispatch(
              chatActions.getThreadInfo(response?.data?.threadId, creatorId)
            );
            dispatch(chatActions.getThreadMessages(response?.data?.threadId));
            setRequestThreadInfo({
              threadId: response?.data?.threadId,
              creatorId,
            });
            closeChat();
          }
        } catch (error) {
          console.log('error: ', error);
        }
      }
      setLoading(false);
    }
  };

  const submitCreateThread = async (params, name) => {
    const threadName = form.getFieldValue('threadName');
    form.submit();
    const userSubmit = userType === 'user' ? [params] : [];
    const companySubmit = userType === 'company' ? [params] : [];
    const paramsSubmit = {
      userChatType:
        user === CHATTYPE.COMPANY ? CHATTYPE.COMPANY : CHATTYPE.USER,
      chatType: isInEmail ? 'Email' : 'P2P',
      threadName: isInEmail ? threadName : '',
      users: userSubmit,
      companies: companySubmit,
    };
    setLoading(true);
    if (isInEmail) {
      // Email
      try {
        const response = await emailServices.initEmailCommunication(
          paramsSubmit
        );
        if (response?.isSuccess) {
          getThreadInformation(response?.data?.threadId, creatorId);
          closeChat();
        }
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      try {
        const response = await chatServices.initChatCommunication(paramsSubmit);
        if (response?.isSuccess) {
          dispatch(chatActions.setChatView('threadMsg'));
          dispatch(
            chatActions.getThreadInfo(response?.data?.threadId, creatorId)
          );
          dispatch(chatActions.getThreadMessages(response?.data?.threadId));
          setRequestThreadInfo({
            threadId: response?.data?.threadId,
            creatorId,
          });
          closeChat();
        }
      } catch (error) {
        console.log('error: ', error);
      }
    }
    setLoading(false);
  };

  const closeChat = () => {
    form.resetFields();
    setUserList([]);
    setCompanyList([]);
    setType(false);
    setUserType('user');
    closeNewChat();
  };

  useEffect(() => {
    //* set type of user of creater for new chat/email thread
    const setDefaultUserState = () => {
      hasPersonalChatPermission && setUser('Personal');
      hasCompanyChatPermission && setUser('Company');
    };

    setDefaultUserState();
  }, [hasPersonalChatPermission, hasCompanyChatPermission]);

  return (
    <Modal
      title={
        isInEmail ? (
          <FormattedMessage {...messages.addEmail} />
        ) : (
          <FormattedMessage {...messages.newPrivateChat} />
        )
      }
      visible={visible}
      destroyOnClose
      onCancel={() => closeChat()}
      className={'new-thread-private'}
      width='870px'
      centered
      footer={<></>}
    >
      <Form form={form}>
        {isInEmail && (
          <Form.Item
            label={<FormattedMessage {...messages.emailThreadName} />}
            name='threadName'
            rules={[
              {
                required: true,
                message: 'Email title could not be empty',
              },
            ]}
          >
            <Input placeholder='Enter your new email' />
          </Form.Item>
        )}

        <Row justify='space-between'>
          <Form.Item
            name='findContact'
            label={<FormattedMessage {...messages.findContactAddToThread} />}
          >
            <Checkbox
              onChange={(values) => {
                setType(values?.target?.checked);
                form.resetFields(['search']);
                setCompanyList([]);
                setUserList([]);
              }}
            >
              Find member
            </Checkbox>
          </Form.Item>
          <Form.Item name='user'>
            <Radio.Group
              value={user}
              onChange={(e) => setUser(e.target.value)}
              defaultValue={user}
            >
              <Radio.Button
                value='Personal'
                disabled={!hasPersonalChatPermission}
              >
                <FormattedMessage {...messages.personalTilte} />
              </Radio.Button>
              <Radio.Button
                value='Company'
                disabled={!hasCompanyChatPermission}
              >
                <FormattedMessage {...messages.companyTitle} />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Row>
        <Form.Item name='search'>
          <Row style={{ marginBottom: 5 }}>
            <Input.Search
              allowClear
              placeholder={'Search users'}
              style={{ width: '40%' }}
              onSearch={handleSearch}
              value={searchText}
              onChange={onChangeHandler}
            />
          </Row>
        </Form.Item>
      </Form>
      {(userList?.length > 0 || companyList?.length > 0) && (
        <Title level={4}>Search result</Title>
      )}

      {userType === 'user' ? (
        <>
          <Spin spinning={loading}>
            <List
              className='new-thread-modal__user-list scroller'
              itemLayout='horizontal'
              dataSource={userList}
              renderItem={(item) => (
                <List.Item>
                  <Row style={{ width: '100%' }}>
                    <Col span={3}>
                      <Avatar
                        src={item?.avatar}
                        icon={<UserOutlined />}
                        size={50}
                      />
                    </Col>
                    <Col span={21}>
                      <Row justify='space-between'>
                        <Col span={9}>
                          <Row justify='start'>
                            <Title level={5}>{item.fullName}</Title>
                          </Row>
                          <Row justify='start'>
                            <Tooltip title='Send email to this address'>
                              <a href={`mailto:${item.email}`}>{item.email}</a>
                            </Tooltip>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <Row className='new-thread-private__company-name-text'>
                            {item.companyName}
                          </Row>
                        </Col>
                        <Col span={9}>
                          <Row justify='end'>{item.gender}</Row>
                          <Row justify='end'>
                            <Button
                              type='primary'
                              style={{ width: 50, borderRadius: 4 }}
                              onClick={() => {
                                if (isInEmail) {
                                  submitCreateEmail(
                                    item?.userId,
                                    item.fullName
                                  );
                                } else {
                                  submitCreateThread(item?.userId);
                                }
                              }}
                            >
                              Go
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </List.Item>
              )}
            ></List>
          </Spin>
          <Row justify='end'> {userList.length} contacts</Row>
        </>
      ) : (
        <>
          <Spin spinning={loading}>
            <List
              className='new-thread-modal__user-list scroller'
              itemLayout='horizontal'
              dataSource={companyList}
              renderItem={(item) => (
                <List.Item>
                  <Row style={{ width: '100%' }}>
                    <Col span={3}>
                      <Avatar
                        src={item?.logo}
                        icon={<UserOutlined />}
                        size={50}
                      />
                    </Col>
                    <Col span={21}>
                      <Row justify='space-between'>
                        <Col>
                          <Row>
                            <Title level={5}>{item.companyName}</Title>
                          </Row>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            type='primary'
                            style={{ width: 50, borderRadius: 4 }}
                            onClick={() => {
                              if (isInEmail) {
                                submitCreateThread(
                                  item?.companyId,
                                  item?.companyName
                                );
                              } else {
                                submitCreateThread(
                                  item?.companyId,
                                  item?.companyName
                                );
                              }
                            }}
                          >
                            Go
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </List.Item>
              )}
            ></List>
          </Spin>
          <Row justify='end'> {companyList.length} contacts</Row>
        </>
      )}
    </Modal>
  );
};

export default CreateNewThreadPrivate;
