import React, { useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Input,
  Dropdown,
  Space,
  Menu,
  Tooltip,
  Empty,
} from 'antd';

import { DownOutlined } from '@ant-design/icons';

import { Form, CustomNotification, StyledModal } from 'common/components';
import LockFormItemInput from './LockFormItemInput';
import ToggleLockAllFormItemInput from './ToggleLockAllFormItemInput';

import { useInitExportManualForm } from './hooks';

import { exportFormForSelectedEntities } from 'services/maintenanceForm';
import ValueFormItemInput from './ValueFormItemInput';
import ManualFillingNoLoop from './ManualFieldNoLoop';

import { set } from 'lodash';

import './ManualFieldFilling.less';

const { Text } = Typography;
export const ManualFieldFilling = ({
  visible,
  prepareFormData,
  onCancel,
  children,
}) => {
  const {
    manualFields = [],
    entityType,
    formId,
    selectedEntityIds,
  } = prepareFormData;

  const [formInstance] = Form.useForm();

  const [statusExport, setStatusExport] = useState('idle');

  const modalProps = {
    title: 'Manual Input Value',
    visible,
    onCancel,
    destroyOnClose: true,
    width: 700,
    bodyStyle: {
      minHeight: '60vh',
    },
    maskClosable: false,
    okText: 'Export',
  };

  const handleExportFormFields = async () => {
    const values = await formInstance.validateFields();

    try {
      setStatusExport('loading');
      const params = {
        entityType,
        formId,
        selectedEntityIds,
        manualFields: getManualFieldsParams(manualFields, values),
      };

      const response = await exportFormForSelectedEntities(params);
      if (response?.isSuccess) {
        CustomNotification.success('Export form successfully!');

        setStatusExport('success');
        onCancel();
      } else {
        setStatusExport('error');
        CustomNotification.error(response?.message ?? 'Something went wrong!');
      }
    } catch (error) {
      setStatusExport('error');
      CustomNotification.error('Something went wrong!');
    }
  };

  return (
    <StyledModal
      {...modalProps}
      onOk={handleExportFormFields}
      okButtonProps={{
        loading: statusExport === 'loading',
        disabled: manualFields?.length === 0,
      }}
      className='manual-field-filling__modal'
    >
      <Form
        form={formInstance}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {children(formInstance)}
      </Form>
    </StyledModal>
  );
};

export const ManualFillingModalContent = (props) => {
  const {
    entityData,
    manualFields,
    entityType,
    formInstance,
    exportIntoASingleFile,
    noLoopManualDataPointList,
  } = props;

  useInitExportManualForm({
    manualFields,
    formInstance,
  });

  return (
    <div>
      {exportIntoASingleFile && (
        <ManualFillingNoLoop
          noLoopManualDataPointList={noLoopManualDataPointList}
          entityData={entityData}
          formInstance={formInstance}
        />
      )}

      {entityData?.length > 0 ? (
        entityData.map((entity) => (
          <ManualField key={entity.entityId}>
            <ManualFieldHeader entityType={entityType} entity={entity} />
            <ManualFieldInput
              entityId={entity.entityId}
              manualFields={manualFields}
              formInstance={formInstance}
              entityData={entityData}
              exportIntoASingleFile={exportIntoASingleFile}
            />
          </ManualField>
        ))
      ) : (
        <Empty description='No manual fields' />
      )}
    </div>
  );
};

export const ManualField = ({ children }) => {
  return <div style={{ marginBottom: 6 }}>{children}</div>;
};

const ManualFieldHeaderPIM = ({ entity }) => {
  const { entityName, gtin, items = [] } = entity;

  const multiPackageLevel = items?.length > 1;
  const emptyPackageLevel = items === null || items?.length === 0;
  const firstPackageLevel = items?.[0]?.packageLevel;

  return (
    <Col span={24}>
      <Row>
        {emptyPackageLevel ? null : (
          <Col className='manual-field-header__package-level'>
            {multiPackageLevel ? (
              <Dropdown
                overlay={() => (
                  <Menu className='manual-field-header__menu'>
                    {items.slice(1).map((item) => (
                      <Menu.Item key={item.packageLevel}>
                        <Text strong>{item.packageLevel}</Text>
                      </Menu.Item>
                    ))}
                  </Menu>
                )}
              >
                <Space>
                  <Text strong>Pallet</Text>
                  <DownOutlined />
                </Space>
              </Dropdown>
            ) : (
              <Text strong>{firstPackageLevel}</Text>
            )}
          </Col>
        )}

        <Col className='manual-field-header__gtin' flex='200px'>
          <Text strong>GTIN - {gtin ?? ''}</Text>
        </Col>
        <Col
          className='manual-field-header__entity-name'
          style={{ flex: 1, overflow: 'hidden' }}
        >
          <Text ellipsis strong>
            {entityName}
          </Text>
        </Col>
      </Row>
    </Col>
  );
};

const ManualFieldHeaderAnother = ({ entity }) => {
  const { entityName } = entity;

  return (
    <Col span={24}>
      <Row>
        <Col
          className='manual-field-header__entity-name'
          style={{ flex: 1, overflow: 'hidden' }}
        >
          <Text ellipsis strong>
            {entityName}
          </Text>
        </Col>
      </Row>
    </Col>
  );
};

export const ManualFieldHeader = ({ entityType, entity }) => {
  const isPIM = entityType === 'PIM';
  const isDAM = entityType === 'DAM';

  if (isPIM) {
    return <ManualFieldHeaderPIM entity={entity} />;
  } else if (isDAM) {
    return <ManualFieldHeaderAnother entity={entity} />;
  } else {
    return <ManualFieldHeaderAnother entity={entity} />;
  }
};

export const ManualFieldInput = ({
  entityId,
  manualFields,
  formInstance,
  entityData,
  exportIntoASingleFile,
}) => {
  const fields =
    manualFields.find((field) => field.entityId === entityId)?.manualData ?? [];

  const onChangeLock = ({ value, dataPointId, thisLockEntityId }) => {
    if (value) {
      const nextFormValues = formInstance.getFieldsValue();

      let forceValue = null;
      entityData.forEach((entityDataItem) => {
        if (
          nextFormValues?.[entityDataItem?.entityId]?.[dataPointId]?.lock ===
            true &&
          entityDataItem?.entityId !== thisLockEntityId
        ) {
          forceValue =
            nextFormValues?.[entityDataItem?.entityId]?.[dataPointId]?.value ||
            '';

          return;
        }
      });

      if (forceValue !== null) {
        set(
          nextFormValues,
          [`${thisLockEntityId}`, `${dataPointId}`, 'value'],
          forceValue
        );
      }

      formInstance.setFieldsValue(nextFormValues);
    }
  };

  const onLockAll = (dataPointId, entityId) => {
    let nextFormValues = formInstance.getFieldsValue();

    const forceValue = nextFormValues?.[entityId]?.[dataPointId]?.value;

    //* lock all & set all values with current item value
    entityData.forEach((entityDataItem) => {
      set(
        nextFormValues,
        [`${entityDataItem?.entityId}`, `${dataPointId}`, 'lock'],
        true
      );
      if (forceValue) {
        set(
          nextFormValues,
          [`${entityDataItem?.entityId}`, `${dataPointId}`, 'value'],
          forceValue
        );
      }
    });

    formInstance.setFieldsValue(nextFormValues);
  };

  const onUnlockAll = (dataPointId) => {
    let nextFormValues = formInstance.getFieldsValue();

    entityData.forEach((entityDataItem) => {
      set(
        nextFormValues,
        [`${entityDataItem?.entityId}`, `${dataPointId}`, 'lock'],
        false
      );
    });

    formInstance.setFieldsValue(nextFormValues);
  };

  const onChangeValue = (dataPointId, value) => {
    let nextFormValues = formInstance.getFieldsValue();

    entityData.forEach((entityDataItem) => {
      if (
        nextFormValues?.[entityDataItem?.entityId]?.[dataPointId]?.lock === true
      ) {
        set(
          nextFormValues,
          [`${entityDataItem?.entityId}`, `${dataPointId}`, 'value'],
          value
        );
      }
    });

    formInstance.setFieldsValue(nextFormValues);
  };

  return (
    <>
      {fields.map((field) => {
        const hideNoLoopManual =
          (field?.isLoop === false) & exportIntoASingleFile;
        return (
          <Row
            justify='center'
            align='middle'
            style={{ marginTop: 12, display: hideNoLoopManual ? 'none' : '' }}
          >
            <Col span={22} key={field.dataPointId}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  currentValues?.[entityId]?.[field.dataPointId]?.lock !==
                    prevValues?.[entityId]?.[field.dataPointId]?.lock ||
                  currentValues?.[entityId]?.[field.dataPointId]?.value !==
                    prevValues?.[entityId]?.[field.dataPointId]?.value
                }
              >
                {({ getFieldValue }) => {
                  const isLock = getFieldValue([
                    `${entityId}`,
                    `${field.dataPointId}`,
                    'lock',
                  ]);

                  return (
                    <Form.Item
                      label={
                        <Tooltip title={field.alias}>
                          <Typography.Text strong ellipsis>
                            {field.alias}
                          </Typography.Text>
                        </Tooltip>
                      }
                      name={[`${entityId}`, `${field.dataPointId}`, 'value']}
                      rules={[
                        {
                          required: Boolean(field.required),
                          message: `${field.alias} cannot be empty!`,
                        },
                      ]}
                    >
                      <ValueFormItemInput
                        onChangeValue={onChangeValue}
                        isLock={isLock}
                        dataPointId={field.dataPointId}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col xs={1} justify='center' align='middle'>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  currentValues?.[entityId]?.[field.dataPointId]?.lock !==
                  prevValues?.[entityId]?.[field.dataPointId]?.lock
                }
              >
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name={[`${entityId}`, `${field.dataPointId}`, 'lock']}
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <LockFormItemInput
                        onChangeLock={onChangeLock}
                        dataPointId={field.dataPointId}
                        entityId={entityId}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col xs={1} justify='center' align='middle'>
              <Form.Item
                name={[`${entityId}`, `${field.dataPointId}`, 'lock']}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 24 }}
              >
                <ToggleLockAllFormItemInput
                  onLockAll={onLockAll}
                  onUnlockAll={onUnlockAll}
                  dataPointId={field.dataPointId}
                  entityId={entityId}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name={[`${entityId}`, `${field.dataPointId}`, 'isLoop']}
              hidden
            >
              <Input />
            </Form.Item>
          </Row>
        );
      })}
    </>
  );
};

/*
  Form field data: 
      entityId: {
        dataPointId: { 
          alias: value
        }
      }
*/
const getManualFieldsParams = (manualFields, formData) => {
  let params = manualFields;

  manualFields.forEach((field) => {
    field.manualData.forEach((manualItem) => {
      manualItem.value =
        formData[`${field.entityId}`][`${manualItem.dataPointId}`].value;
    });
  });

  return params;
};

export default ManualFieldFilling;
