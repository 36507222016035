import React, { useEffect, useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  StarOutlined,
  FilterOutlined,
} from '@ant-design/icons';
//redux
import { useDispatch } from 'react-redux';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
// hooks
import { useFilterGrid } from 'hooks/useFilterGrid';
import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';
// hooks-query
import { useQueryClient } from '@tanstack/react-query';
import { useGetTicketTags } from 'hooks';
// common components
import { ButtonSmallIcon, RibbonButton } from 'common/components';
// constants
import { FILTER_TICKETING_SYSTEM } from 'static/Constants';
// lodash
import { intersection } from 'lodash';

const { SubMenu } = Menu;

const FilterTicketingSystem = (props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { entityType = 'ticketingSystem' } = props;

  const { data: tagList } = useGetTicketTags({ enabled: true });

  const filterTagList = useMemo(() => {
    return tagList?.map((tag) => ({
      value: tag,
      label: tag,
      icon: <FilterOutlined />,
    }));
  }, [tagList]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['all-ticket-tags'],
    });
  }, []);

  const filterOptions = [...filterTagList, ...FILTER_TICKETING_SYSTEM];

  const { gridFilters, updateGridFilter } = useFilterGrid({
    entityType,
  });

  const { label, icon, toggle } = useDisplayFilterButton({
    filterOptions: filterOptions,
    filters: gridFilters.filter((filterItem) => {
      return filterOptions.find(
        (FILTER_ITEM) => FILTER_ITEM.value === filterItem
      );
    }),
  });

  const handleFilter = (filter) => {
    dispatch(actionsGridView.resetPageCurrent());

    if (gridFilters.indexOf(filter) > -1) {
      updateGridFilter(gridFilters.filter((val) => val !== filter));
    } else {
      let newFilter = [...gridFilters];

      newFilter.push(filter);

      updateGridFilter(newFilter);
    }
  };

  const toggleStatus = intersection(gridFilters, [
    'open',
    'pending',
    'solved',
    'deleted',
  ]);
  const toogleTag = intersection(gridFilters, tagList);

  const menuOptions = (
    <Menu>
      <Menu.Item
        style={{ backgroundColor: 'inherit' }}
        key='ticket-assigned-tome'
      >
        <ButtonSmallIcon
          label={'Assigned To Me'}
          toggle={gridFilters?.includes('assignedToMe')}
          onClick={() => handleFilter('assignedToMe')}
        />
      </Menu.Item>

      <SubMenu
        style={{ backgroundColor: 'inherit' }}
        title={
          <ButtonSmallIcon
            className='button-item-dropdown'
            label='Status'
            toggle={toggleStatus?.length > 0}
          />
        }
        key='ticket-status'
      >
        <Menu.Item
          style={{
            backgroundColor: 'inherit',
          }}
          key='ticket-open'
        >
          <RibbonButton
            size='small'
            label={'Open'}
            icon={<StarOutlined />}
            toggle={gridFilters?.includes('open')}
            onClick={() => handleFilter('open')}
          />
        </Menu.Item>
        <Menu.Item
          style={{
            backgroundColor: 'inherit',
          }}
          key='ticket-pending'
        >
          <RibbonButton
            size='small'
            label={'Pending'}
            icon={<ClockCircleOutlined />}
            toggle={gridFilters?.includes('pending')}
            onClick={() => handleFilter('pending')}
          />
        </Menu.Item>
        <Menu.Item
          style={{
            backgroundColor: 'inherit',
          }}
          key='ticket-solved'
        >
          <RibbonButton
            size='small'
            label={'Solved'}
            icon={<CheckCircleOutlined />}
            toggle={gridFilters?.includes('solved')}
            onClick={() => handleFilter('solved')}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        popupClassName='ticketing-system-ribbon__menu-tags'
        style={{ backgroundColor: 'inherit' }}
        title={
          <ButtonSmallIcon
            className='button-item-dropdown'
            label='Tags'
            toggle={toogleTag?.length > 0}
          />
        }
        key='ticket-tag'
      >
        {tagList.map((tag, index) => (
          <Menu.Item
            style={{
              backgroundColor: 'inherit',
            }}
            key={`${tag}-${index}}`}
          >
            <RibbonButton
              size='small'
              label={tag}
              icon={<FilterOutlined />}
              toggle={gridFilters?.includes(tag)}
              onClick={() => handleFilter(tag)}
            />
          </Menu.Item>
        ))}
      </SubMenu>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
      <RibbonButton icon={icon} label={label} toggle={toggle} />
    </Dropdown>
  );
};

export default FilterTicketingSystem;
