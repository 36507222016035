import { isAliasNameEmpty, isAliasNameHaveWhiteSpace } from '../utils';

export const FORM_UPLOAD_TYPES = [
  'pptx',
  'pptm',
  'ppt',
  'docx',
  'docm',
  'doc',
  'xls',
  'xlsx',
  'xlsm',
];

let initColSetting = {
  minWidth: 200,
};

export const DOC_PPT_COL = [
  {
    ...initColSetting,
    field: 'errorMessage',
    headerName: '',
    width: 55,
    maxWidth: 55,
    cellRenderer: 'StatusRender',
  },
  {
    ...initColSetting,
    field: 'position',
    headerName: 'Position',
    cellRenderer: 'PositionRender',
    minWidth: 50,
    width: 200,
  },
  {
    ...initColSetting,
    field: 'alias',
    headerName: 'Alias',
    minWidth: 50,
    width: 200,
  },
  {
    ...initColSetting,
    field: 'note',
    headerName: 'Note',
    minWidth: 50,
    width: 300,
  },
];

export const EXCEL_COL = [
  {
    ...initColSetting,
    field: 'errorMessage',
    headerName: '',
    width: 80,
    maxWidth: 80,
    cellRenderer: 'ExcelStatusRender',
  },
  {
    ...initColSetting,
    field: 'position',
    headerName: 'Position',
    cellRenderer: 'ExcelPositionRender',
    minWidth: 50,
    width: 200,
  },
  {
    ...initColSetting,
    field: 'alias',
    headerName: 'Alias',
    minWidth: 50,
    width: 200,
  },
  {
    ...initColSetting,
    field: 'sheetName',
    headerName: 'Sheet Name',
    minWidth: 50,
    width: 200,
  },
  {
    ...initColSetting,
    field: 'note',
    headerName: 'Note',
    minWidth: 50,
    width: 200,
  },
];

export const ENTITY_OPTIONS = [
  'PIM - Single item',
  'PIM - Hierarchy',
  'DAM',
  'MEM',
];
export const ENTITY_XLS_OPTIONS = [
  'PIM - Single item',
  'PIM - Hierarchy',
  'DAM',
  'MEM',
];
export const OUTPUT_TYPE_OPTIONS = {
  XLS: ['XLSX', 'XLSM', 'XLS'],
  DOC: ['DOCX', 'DOC', 'DOCM', 'PDF'],
  PPT: ['PPTX', 'PPT', 'PPTM'],
};

export const FORM_FILE_TYPES = {
  XLS: ['xlsx', 'xlsm', 'xls', 'xlsb'],
  DOC: ['docx', 'docm', 'doc'],
  PPT: ['pptx', 'pptm', 'ppt'],
};

export const FORM_FILE_STATUS = ['Pending', 'Active', 'Disabled'];

export const QA_AND_LOCAL_DOCUMENT_SEVER =
  'http://qa-tacotruck.gvivn.global:8000/';

export const TEST_DOCUMENT_SERVER = 'https://gvi.vn:8000/';

export const TEMP_HTTPS_DOCUMENT_SERVER = 'https://chat.gorivir.com:8003/';

export const FIELD_DATA_TYPES = {
  STRING: 'String',
  BOOLEAN: 'Boolean',
  DATE_TIME: 'DateTime',
  DECIMAL: 'Decimal',
  INT32: 'Int32',
  DOUBLE: 'Double',
  INT64: 'Int64',
};

export const OPERATOR_OPTIONS = [
  { name: 'Contains', value: 'Contains' },
  { name: 'Does not contain', value: 'DoesNotContain' },
  { name: '=', value: 'Equal' },
  { name: '<>', value: 'NotEqual' },
  { name: '>', value: 'GreaterThan' },
  { name: '<', value: 'LessThan' },
  { name: '>=', value: 'GreaterThanOrEqual' },
  { name: '<=', value: 'LessThanOrEqual' },
  { name: 'Is null', value: 'IsNull' },
  { name: 'Is not null', value: 'IsNotNull' },
  { name: 'Any', value: 'Any' },
  { name: 'Not any', value: 'NotAny' },
];

/**
 * ! OPERATOR OPTIONS FOR CELL VALUE
 */
export const OPERATOR_OPTIONS_FOR_CELL_VALUE = [
  { name: 'Contains', value: 'Contains' },
  { name: 'Does not contain', value: 'DoesNotContain' },
  { name: '=', value: 'Equal' },
  { name: '<>', value: 'NotEqual' },
  { name: '>', value: 'GreaterThan' },
  { name: '<', value: 'LessThan' },
  { name: '>=', value: 'GreaterThanOrEqual' },
  { name: '<=', value: 'LessThanOrEqual' },
  { name: 'Is null', value: 'IsNull' },
  { name: 'Is not null', value: 'IsNotNull' },
];

/**
 * ! OPERATOR OPTIONS FOR DECIMAL FIELD
 */
export const OPERATOR_OPTIONS_FOR_DECIMAL = [
  { name: 'Contains', value: 'Contains' },
  { name: 'Does not contain', value: 'DoesNotContain' },
  { name: '=', value: 'Equal' },
  { name: '<>', value: 'NotEqual' },
  { name: '>', value: 'GreaterThan' },
  { name: '<', value: 'LessThan' },
  { name: '>=', value: 'GreaterThanOrEqual' },
  { name: '<=', value: 'LessThanOrEqual' },
  { name: 'is null', value: 'IsNull' },
  { name: 'is not null', value: 'IsNotNull' },
];

/**
 * ! OPERATOR OPTIONS FOR STRING FIELD
 */
export const OPERATOR_OPTIONS_FOR_STRING = [
  { name: 'Contains', value: 'Contains' },
  { name: 'Does not contain', value: 'DoesNotContain' },
  { name: '=', value: 'Equal' },
  { name: '<>', value: 'NotEqual' },
  { name: 'is Null', value: 'IsNull' },
  { name: 'is Not null', value: 'IsNotNull' },
];

/**
 * ! OPERATOR OPTIONS FOR BOOLEAN FIELD
 */
export const OPERATOR_OPTIONS_FOR_BOOLEAN = [
  { name: '=', value: 'Equal' },
  { name: '<>', value: 'NotEqual' },
  { name: 'is null', value: 'IsNull' },
  { name: 'is not null', value: 'IsNotNull' },
];

/**
 * ! OPERATOR OPTIONS FOR DATE TIME
 */
export const OPERATOR_OPTIONS_FOR_DATE_TIME = [
  { name: '=', value: 'Equal' },
  { name: '<>', value: 'NotEqual' },
  { name: '>', value: 'GreaterThan' },
  { name: '<', value: 'LessThan' },
  { name: '>=', value: 'GreaterThanOrEqual' },
  { name: '<=', value: 'LessThanOrEqual' },
  { name: 'Is null', value: 'IsNull' },
  { name: 'Is not null', value: 'IsNotNull' },
];

/**
 * ! OPERATOR OPTIONS FOR INT32 FIELD
 */
export const OPERATOR_OPTIONS_FOR_INT32 = [
  { name: '=', value: 'Equal' },
  { name: '<>', value: 'NotEqual' },
  { name: '>', value: 'GreaterThan' },
  { name: '<', value: 'LessThan' },
  { name: '>=', value: 'GreaterThanOrEqual' },
  { name: '<=', value: 'LessThanOrEqual' },
  { name: 'Is null', value: 'IsNull' },
  { name: 'Is not null', value: 'IsNotNull' },
];

/**
 * ! OPERATOR OPTIONS FOR LIST<DATA TYPE>
 */
export const OPERATOR_OPTIONS_FOR_LIST = [
  { name: 'Contains', value: 'Contains' },
  { name: 'Does not contain', value: 'DoesNotContain' },
  { name: 'Any', value: 'Any' },
  { name: 'Not any', value: 'NotAny' },
];

/**
 * ! OPERATOR OPTIONS FOR DATA TYPE WITHOUT HAVING COMPARISON VALUE
 */
export const OPERATOR_OPTIONS_WITHOUT_COMPARE_VALUE = [
  { name: 'Is not null', value: 'IsNotNull' },
  { name: 'Is null', value: 'IsNull' },
];

export const LOOP_OPTIONS = [
  { name: 'Vertical', value: 'Vertical' },
  { name: 'Horizontal', value: 'Horizontal' },
];

export const EXPRESSION_PARAMETER_COL = [
  {
    ...initColSetting,
    field: 'errorMessage',
    headerName: '',
    width: 55,
    maxWidth: 55,
    cellRenderer: 'StatusRender',
    editable: false,
    valueGetter: (params) => {
      const aliasValue = params.data.alias;

      return isAliasNameEmpty(aliasValue)
        ? 'Alias is empty'
        : isAliasNameHaveWhiteSpace(aliasValue)
        ? 'Alias must not include white space'
        : aliasValue.length > 50
        ? 'Alias length must be less than or euqal 50'
        : '';
    },
  },
  {
    ...initColSetting,
    field: 'alias',
    headerName: 'Parameter Alias',
    minWidth: 50,
    width: 200,
  },
  {
    ...initColSetting,
    field: 'fieldName',
    headerName: 'Field Name',
    editable: false,
    minWidth: 50,
    width: 200,
  },
];

export const EXPRESSION_PARAMETER_COL_FOR_PIM = [
  ...EXPRESSION_PARAMETER_COL,
  {
    ...initColSetting,
    field: 'fieldPackageLevel',
    headerName: 'Package Level',
    editable: false,
    minWidth: 50,
    width: 200,
  },
];

export const FONT_OPTIONS = [
  'Abadi MT',
  'Minion Web',
  'Agency FB',
  'Aharoni Bold',
  'Aldhabi',
  'Algerian',
  'Almanac MT',
  'American Uncial',
  'Andale Mono',
  'Andalus',
  'Andy',
  'AngsanaUPC',
  'Angsana New',
  'Aparajita',
  'Arabic Transparent',
  'Arabic Typesetting',
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Arial Narrow Special',
  'Arial Rounded MT',
  'Arial Special',
  'Arial Unicode MS',
  'Augsburger Initials',
  'Avenir Next LT Pro',
  'Bahnschrift',
  'Baskerville Old Face',
  'Batang &amp; BatangChe',
  'Bauhaus 93',
  'Beesknees ITC',
  'Bell MT',
  'Bembo',
  'Berlin Sans FB',
  'Bernard MT Condensed',
  'Bickley Script',
  'Biome',
  'BIZ UDGothic',
  'BIZ UDMincho Medium',
  'Blackadder ITC',
  'Bodoni MT',
  'Bodoni MT Condensed',
  'Bon Apetit MT',
  'Bookman Old Style',
  'Bookshelf Symbol',
  'Book Antiqua',
  'Bradley Hand ITC',
  'Braggadocio',
  'BriemScript',
  'Britannic Bold',
  'Broadway',
  'BrowalliaUPC',
  'Browallia New',
  'Brush Script MT',
  'Calibri',
  'Californian FB',
  'Calisto MT',
  'Cambria',
  'Cambria Math',
  'Candara',
  'Cariadings',
  'Castellar',
  'Cavolini',
  'Centaur',
  'Century',
  'Century Gothic',
  'Century Schoolbook',
  'Chiller',
  'Colonna MT',
  'Comic Sans MS',
  'Consolas',
  'Constantia',
  'Contemporary Brush',
  'Cooper Black',
  'Copperplate Gothic',
  'Corbel',
  'CordiaUPC',
  'Cordia New',
  'Courier New',
  'Curlz MT',
  'Dante',
  'DaunPenh',
  'David',
  'Daytona',
  'Desdemona',
  'DFKai-SB',
  'DilleniaUPC',
  'Directions MT',
  'DokChampa',
  'Dotum &amp; DotumChe',
  'Ebrima',
  'Eckmann',
  'Edda',
  'Edwardian Script ITC',
  'Elephant',
  'Engravers MT',
  'Enviro',
  'Eras ITC',
  'Estrangelo Edessa',
  'EucrosiaUPC',
  'Euphemia',
  'Eurostile',
  'FangSong',
  'Felix Titling',
  'Fine Hand',
  'Fixed Miriam Transparent',
  'Flexure',
  'Footlight MT',
  'Forte',
  'Franklin Gothic',
  'Franklin Gothic Medium',
  'FrankRuehl',
  'FreesiaUPC',
  'Freestyle Script',
  'French Script MT',
  'Futura',
  'Gabriola',
  'Gadugi',
  'Garamond',
  'Garamond MT',
  'Gautami',
  'Georgia',
  'Georgia Ref',
  'Gigi',
  'Gill Sans MT',
  'Gill Sans MT Condensed',
  'Gisha',
  'Gloucester',
  'Goudy Old Style',
  'Goudy Stout',
  'Gradl',
  'Grotesque',
  'Gulim &amp; GulimChe',
  'Gungsuh &amp; GungsuhChe',
  'Hadassah Friedlaender',
  'Haettenschweiler',
  'Harlow Solid Italic',
  'Harrington',
  'HGGothicE',
  'HGMinchoE',
  'HGSoeiKakugothicUB',
  'High Tower Text',
  'Holidays MT',
  'HoloLens MDL2 Assets',
  'Impact',
  'Imprint MT Shadow',
  'Informal Roman',
  'IrisUPC',
  'Iskoola Pota',
  'JasmineUPC',
  'Javanese Text',
  'Jokerman',
  'Juice ITC',
  'KaiTi',
  'Kalinga',
  'Kartika',
  'Keystrokes MT',
  'Khmer UI',
  'Kigelia',
  'Kino MT',
  'KodchiangUPC',
  'Kokila',
  'Kristen ITC',
  'Kunstler Script',
  'Lao UI',
  'Latha',
  'LCD',
  'Leelawadee',
  'Levenim MT',
  'LilyUPC',
  'Lucida Blackletter',
  'Lucida Bright',
  'Lucida Bright Math',
  'Lucida Calligraphy',
  'Lucida Console',
  'Lucida Fax',
  'Lucida Handwriting',
  'Lucida Sans',
  'Lucida Sans Typewriter',
  'Lucida Sans Unicode',
  'Magneto',
  'Maiandra GD',
  'Malgun Gothic',
  'Mangal',
  'Map Symbols',
  'Marlett',
  'Matisse ITC',
  'Matura MT Script Capitals',
  'McZee',
  'Mead Bold',
  'Meiryo',
  'Mercurius Script MT Bold',
  'Microsoft GothicNeo',
  'Microsoft Himalaya',
  'Microsoft JhengHei',
  'Microsoft JhengHei UI',
  'Microsoft New Tai Lue',
  'Microsoft PhagsPa',
  'Microsoft Sans Serif',
  'Microsoft Tai Le',
  'Microsoft Uighur',
  'Microsoft YaHei',
  'Microsoft YaHei UI',
  'Microsoft Yi Baiti',
  'MingLiU',
  'MingLiU-ExtB',
  'MingLiU_HKSCS',
  'MingLiU_HKSCS-ExtB',
  'Minion Web',
  'Miriam',
  'Miriam Fixed',
  'Mistral',
  'Modern Love',
  'Modern No. 20',
  'Mongolian Baiti',
  'Monotype.com',
  'Monotype Corsiva',
  'Monotype Sorts',
  'MoolBoran',
  'MS Gothic',
  'MS LineDraw',
  'MS Mincho',
  'MS Outlook',
  'MS PGothic',
  'MS PMincho',
  'MS Reference',
  'MS UI Gothic',
  'MT Extra',
  'MV Boli',
  'Myanmar Text',
  'Narkisim',
  'News Gothic MT',
  'New Caledonia',
  'Niagara',
  'Nirmala UI',
  'Nyala',
  'OCR-B-Digits',
  'OCRB',
  'OCR A Extended',
  'Old English Text MT',
  'Onyx',
  'Palace Script MT',
  'Palatino Linotype',
  'Papyrus',
  'Parade',
  'Parchment',
  'Parties MT',
  'Peignot Medium',
  'Pepita MT',
  'Perpetua',
  'Perpetua Titling MT',
  'Placard Condensed',
  'Plantagenet Cherokee',
  'Playbill',
  'PMingLiU',
  'PMingLiU-ExtB',
  'Poor Richard',
  'Posterama',
  'Pristina',
  'Quire Sans',
  'Raavi',
  'Rage Italic',
  'Ransom',
  'Ravie',
  'RefSpecialty',
  'Rockwell',
  'Rockwell Nova',
  'Rod',
  'Runic MT Condensed',
  'Sabon Next LT',
  'Sagona',
  'Sakkal Majalla',
  'Script MT Bold',
  'Segoe Chess',
  'Segoe Print',
  'Segoe Script',
  'Segoe UI',
  'Segoe UI Symbol',
  'Selawik',
  'Shonar Bangla',
  'Showcard Gothic',
  'Shruti',
  'Signs MT',
  'SimHei',
  'Simplified Arabic Fixed',
  'SimSun',
  'SimSun-ExtB',
  'Sitka',
  'NSimSun',
  'Snap ITC',
  'Sports MT',
  'STCaiyun',
  'Stencil',
  'STFangsong',
  'STHupo',
  'STKaiti',
  'Stop',
  'STXihei',
  'STXingkai',
  'STXinwei',
  'STZhongsong',
  'Sylfaen',
  'Symbol',
  'Tahoma',
  'Tempo Grunge',
  'Tempus Sans ITC',
  'Temp Installer Font',
  'The Hand',
  'The Serif Hand',
  'Times New Roman',
  'Times New Roman Special',
  'Tisa Offc Serif Pro',
  'Traditional Arabic',
  'Transport MT',
  'Trebuchet MS',
  'Tunga',
  'Tw Cen MT',
  'Univers',
  'Urdu Typesetting',
  'Utsaah',
  'Vacation MT',
  'Vani',
  'Verdana',
  'Verdana Ref',
  'Vijaya',
  'Viner Hand ITC',
  'Vivaldi',
  'Vixar ASCI',
  'Vladimir Script',
  'Vrinda',
  'Walbaum',
  'Webdings',
  'Westminster',
  'Wide Latin',
  'Wingdings',
];

export const ALIAS_NAME_REGEX = /^[a-zA-Z0-9\s\_\-\/\.]{1,200}$/;

export const PARAM_ALIAS_NAME_REGEX = /^[a-zA-Z0-9\s\_]{1,200}$/;

export const ADD_DATA_POINT_TYPE = {
  CELL_POSISION: 'CELL_POSITION',
  ALIAS: 'ALIAS',
};

export const ENTITY_TYPE_SUPPORT_FORM_IMAGE = ['PIM', 'DAM'];

export const FORM_IMAGE_TYPE = [
  'Any Planogram',
  'Planogram - Top',
  'Planogram - Bottom',
  'Planogram - Left',
  'Planogram - Right',
  'Planogram - Front',
  'Planogram - Back',
  'Any Marketing',
  'Primary Marketing',
  'Marketing - Top',
  'Marketing - Bottom',
  'Marketing - Left',
  'Marketing - Right',
  'Marketing - Front',
  'Marketing - Back',
];

export const SHORT_DETAIL_ENTITY_LIST_COLUMN = [
  {
    ...initColSetting,
    field: 'entityId',
    headerName: 'Entity ID',
    editable: false,
    minWidth: 50,
    width: 100,
    cellRenderer: 'EntityLink',
  },
  {
    ...initColSetting,
    field: 'entityName',
    headerName: 'Entity Name',
    editable: false,
    minWidth: 50,
    width: 200,
  },
  {
    ...initColSetting,
    field: 'ownerName',
    headerName: 'Owner Name',
    editable: false,
    minWidth: 50,
    width: 200,
  },
];

export const PACKAGE_LEVEL_SPLASH = [
  { value: 'SelectedItem', label: 'Selected Item' },
  { value: 'Unit', label: 'Unit' },
  { value: 'InnerPack', label: 'Inner Pack' },
  { value: 'Case', label: 'Case' },
  { value: 'MasterCase', label: 'Master Case' },
  { value: 'Pallet', label: 'Pallet' },
  { value: 'LowestPackageLevelItem', label: 'Lowest Pkg Level' },
];
