import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getQaSpecDataHeader = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_HEADER, { params: data });
};

export const saveQaSpecDataHeader = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_HEADER, params);
};

export const getQaSpecProductSpec = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_PRODUCT_SPEC, { params: data });
};
export const saveQaSpecProductSpec = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_PRODUCT_SPEC, params);
};

export const updateQaSpecProductDocument = (params) => {
  return api.sendPost(endpoints.UPDATE_QA_SPEC_PRODUCT_SPEC_DOCUMENT, params);
};

export const getQaSpecOrganolepticCharacteristic = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_ORGANOLEPTIC_CHARACTERISTIC, {
    params: data,
  });
};
export const saveQaSpecOrganolepticCharacteristic = (params) => {
  return api.sendPost(
    endpoints.SAVE_QA_SPEC_ORGANOLEPTIC_CHARACTERISTIC,
    params
  );
};

export const getQaSpecContaminantCharacteristic = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_CONTAMINANT_CHARACTERISTIC, {
    params: data,
  });
};
export const saveQaSpecContaminantCharacteristic = (params) => {
  return api.sendPost(
    endpoints.SAVE_QA_SPEC_CONTAMINANT_CHARACTERISTIC,
    params
  );
};

export const getQaSpecDataManufacturingProcess = (data) => {
  return api.sendGet(endpoints.GET_SPEC_DATA_MANUFACTURING_PROCESS, {
    params: data,
  });
};

export const saveQaSpecDataManufacturingProcess = (params) => {
  return api.sendPost(endpoints.SAVE_SPEC_DATA_MANUFACTURING_PROCESS, params);
};

export const getQaSpecDataManufacturingLocation = (data) => {
  return api.sendGet(endpoints.GET_SPEC_DATA_MANUFACTURING_LOCATION, {
    params: data,
  });
};

export const saveQaSpecDataManufacturingLocation = (params) => {
  return api.sendPost(endpoints.SAVE_SPEC_DATA_MANUFACTURING_LOCATION, params);
};

export const getQaSpecDataManufacturingProcessDocuments = (data) => {
  return api.sendGet(endpoints.GET_SPEC_DATA_MANUFACTURING_PROCESS_DOCUMENT, {
    params: data,
  });
};

export const linkQaSpecDataManufacturingProcessDocument = (params) => {
  return api.sendPost(
    endpoints.LINK_QA_SPEC_MANUFACTURING_PROCESS_DOCUMENT,
    params
  );
};

export const updateManufacturingProcessDocument = (params) => {
  return api.sendPost(
    endpoints.UPDATE_QA_SPEC_MANUFACTURING_PROCESS_DOCUMENT,
    params
  );
};

export const getQaSpecPhyCheCharacteristic = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_PHY_CHE_CHARACTERISTIC, {
    params: data,
  });
};
export const saveQaSpecPhyCheCharacteristic = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_PHY_CHE_CHARACTERISTIC, params);
};

export const getQaSpecMicrobiologicalCharacteristic = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_MICROBIOLOGICAL_CHARACTERISTIC, {
    params: data,
  });
};
export const saveQaSpecMicrobiologicalCharacteristic = (params) => {
  return api.sendPost(
    endpoints.SAVE_QA_SPEC_MICROBIOLOGICAL_CHARACTERISTIC,
    params
  );
};

export const getQaSpecProductSpecDocument = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DOCUMENT, {
    params: data,
  });
};

export const linkQaSpecProductSpecDocument = (params) => {
  return api.sendPost(endpoints.LINK_QA_SPEC_DOCUMENT, params);
};
export const unlinkQaSpecProductSpecDocument = (params) => {
  return api.sendPost(endpoints.UNLINK_QA_SPEC_DOCUMENT, params);
};
export const unlinkQaSpecDataManufacturingProcessDocument = (params) => {
  return api.sendPost(
    endpoints.UNLINK_QA_SPEC_MANUFACTURING_PROCESS_DOCUMENT,
    params
  );
};

export const getQaSpecDataContact = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_CONTACT, { params: data });
};
export const getQaSpecDataContactSnapshot = (data) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_CONTACT_SNAPSHOT, {
    params: data,
  });
};

export const saveQaSpecDataContact = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_CONTACT, params);
};

export const updateDocumentOrCertification = (params) => {
  return api.sendPost(endpoints.UPDATE_DOCS_OR_CERTIFICATION, params);
};

export const getDownloadQaSpecUrl = (params) => {
  return api.sendGet(endpoints.GET_DOWNLOAD_QA_SPEC_URL, { params });
};

// QA Evaluation Form
export const getQaEvaluationFormDetail = (params) => {
  return api.sendGet(endpoints.GET_QA_EVALUATION_FORM, { params });
};

export const saveDraftQaSpecEvaluationForm = (params) => {
  return api.sendPost(endpoints.SAVE_DRAFT_QA_SPEC_EVALUATION_FORM, params);
};

export const downloadQaSpecEvaluationForm = (params) => {
  return api.sendGet(endpoints.DOWNLOAD_QA_SPEC_FORM, { params });
};

export const completeQaSpecEvaluationForm = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_EVALUATION_FORM, params);
};

export const unlinkQaSpecEvaluationAsset = (params) => {
  return api.sendPost(endpoints.UNLINK_QA_SPEC_EVALUATION_FORM_ASSET, params);
};

export const getQaSpecComponents = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_COMPONENTS, { params });
};
export const getQaSpecComponentsSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_COMPONENTS_SNAPSHOT, { params });
};
export const saveQaSpecComponents = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_COMPONENTS, params);
};

export const addCustomAllergen = (params) => {
  return api.sendPost(endpoints.ADD_CUSTOM_ALLERGEN, params);
};

export const linkQaSpecProductSpecCertification = (params) => {
  api.sendPost(endpoints.LINK_QA_SPEC_PRODUCT_SPEC_CERTIFICATION, params);
};

// produce - deli
export const getQaSpecProduce = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_PRODUCE, { params });
};

export const saveQaSpecProduce = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_PRODUCE, params);
};

export const getQaSpecDeli = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DELI, { params });
};
export const getQaSpecDeliSnapshot = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DELI_SNAPSHOT, { params });
};
export const saveQaSpecDeli = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DELI, params);
};

export const saveQaSpecCompanyLocation = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_MANUFACTURING_LOCATION, params);
};

// Pet Food
export const getQaSpecDataPetFood = (params) => {
  return api.sendGet(endpoints.GET_QA_SPEC_DATA_PET_FOOD, { params });
};

export const saveQaSpecDataPetFood = (params) => {
  return api.sendPost(endpoints.SAVE_QA_SPEC_DATA_PET_FOOD, params);
};

export const getQaPetFoodSnapshot = (data) => {
  return api.sendGet(endpoints.GET_QA_PET_FOOD_SNAPSHOT, {
    params: data,
  });
};

export const getQaPetNonFoodSnapshot = (data) => {
  return api.sendGet(endpoints.GET_QA_PET_NON_FOOD_SNAPSHOT, {
    params: data,
  });
};

export const addQaSpecDataManufacturingLocation = (params) => {
  return api.sendPost(
    endpoints.ADD_QA_SPEC_DATA_MANUFACTURING_LOCATION,
    params
  );
};

export const deleteQaSpecDataManufacturingLocation = (params) => {
  return api.sendPost(
    endpoints.DELETE_QA_SPEC_DATA_MANUFACTURING_LOCATION,
    params
  );
};
