import React from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/common';

const FormEditButton = (props) => {
  const { text, style, disabled, ...otherProps } = props;

  const disabledStyle = {
    color: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f5f5f5',
    borderColor: '#dedede',
  };

  let styles = {
    borderRadius: '4px',
    backgroundColor: '#1e8e3e',
    borderColor: '#1e8e3e',
    ...style,
  };

  styles = disabled ? { ...styles, ...disabledStyle } : styles;

  return (
    <Button
      type='primary'
      style={styles}
      icon={<EditOutlined />}
      disabled={disabled}
      {...otherProps}
    >
      {text === null ? null : (
        <span>{text || <FormattedMessage {...Messages.editBtn} />}</span>
      )}
    </Button>
  );
};

export default FormEditButton;
