import React, { useState, useEffect, useRef } from 'react';

import { Typography, Input, Space, Col, Row } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { Form, UploadFile, FormButton } from 'common/components';
import LinkAssetToQaSpec from '../qa-grid-cell/LinkAssetToQaSpec';

import { UPLOAD_MAX_SIZE } from 'static/Constants';
import { SUPPORT_TYPES } from 'pages/qa-spec/components/qa-manufacturing-process/constants';

import * as productEndpoints from 'services/product/endpoints';

import { PRODUCT_SPEC_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';

import Messages from 'i18n/messages/qa-spec';
import { useIntl } from 'react-intl';
import { useSelectProductFullDetailData } from 'pages/qa-spec/hooks';

const QaSpecLinkProductSpecDocumentModal = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { visible, setVisible, addDocument, productId } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [assetId, setAssetId] = useState(null);
  const [loading, setLoading] = useState(false);

  const productDetail = useSelectProductFullDetailData({ productId });

  const onClickBackBtn = () => {
    setCurrentStep(0);
  };

  const onClickMoveToConfig = () => {
    setCurrentStep(1);
  };

  const onFileChange = () => {
    setAssetId(null);
  };

  const handleUploadFile = (response) => {
    if (!response?.data) return;

    const {
      data: { assetId },
    } = response.data;

    if (assetId) {
      setAssetId(assetId);
      setCurrentStep(1);
    }
    return;
  };

  const onSubmitConfigForm = (values) => {
    setLoading(true);

    const params = {
      digitalAssetId: assetId,
      ...values,
      successCallback: () => {
        setVisible(false);
      },
      onFinally: () => {
        setLoading(false);
      },
    };

    addDocument(params);
  };

  const resetState = () => {
    if (visible) return;

    setCurrentStep(0);
    form.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    resetState();
  }, [visible]);

  const renderUploadFile = () => {
    return (
      <>
        <div style={{ height: 300, marginTop: 12 }}>
          <UploadFile
            multiple={false}
            multipleImport={false}
            supportTypes={SUPPORT_TYPES}
            showSubmitBtn
            showList
            manualUpload
            showPreview
            showMultiple={false}
            additionalBodyPayload={{
              assetType: 'Document',
              assetSubType: 'General',
              ownerId: productDetail?.ownerId,
              productId,
            }}
            apiUrl={productEndpoints.UPLOAD_PIM_ASSET}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
            getResponse={handleUploadFile}
            onFileChange={onFileChange}
            forceDisableManudalUpload={assetId}
          />
        </div>
        {assetId ? (
          <Row justify='end' style={{ marginTop: 12 }}>
            <FormButton
              type='default'
              icon={<ArrowRightOutlined />}
              text={intl.formatMessage(Messages.moveToConfig)}
              onClick={onClickMoveToConfig}
            />
          </Row>
        ) : null}
      </>
    );
  };

  const renderConfigForm = () => {
    return (
      <Form
        style={{ height: 350 }}
        form={form}
        onFinish={onSubmitConfigForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Row
          style={{ flexDirection: 'column', height: '100%', paddingTop: 30 }}
        >
          <Col>
            <Form.Item
              name='documentName'
              label={intl.formatMessage(Messages.productSpecDocumentName)}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='characteristicsType'
              label={intl.formatMessage(Messages.productSpecCharacteristicType)}
            >
              <Input
                showCount
                maxLength={
                  PRODUCT_SPEC_LIMIT_CHARACTERS.document.characteristicsType
                }
              />
            </Form.Item>
          </Col>
          <Col flex={1}></Col>
          <Col>
            <Space style={{ float: 'right' }}>
              <FormButton
                type='default'
                icon={<ArrowLeftOutlined />}
                text={intl.formatMessage(Messages.backToUploadFileBtn)}
                onClick={onClickBackBtn}
              />
              <FormButton
                htmlType='submit'
                text={intl.formatMessage(Messages.finishSetupButton)}
                loading={loading}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <LinkAssetToQaSpec
      modalProps={{
        title: 'Link Document of Product Specification to QA Specification',
        visible: visible,
        onCancel: () => setVisible(false),
        width: 460,
        bodyStyle: {
          minHeight: '375px',
        },
      }}
      stepProps={{
        currentStep,
        items: [
          {
            key: 0,
            title: (
              <Typography.Text strong style={{ fontSize: 16 }}>
                Create an asset
              </Typography.Text>
            ),
          },
          {
            key: 1,
            title: (
              <Typography.Text strong style={{ fontSize: 16 }}>
                Link to QA specification
              </Typography.Text>
            ),
          },
        ],
      }}
    >
      <div style={{ display: currentStep === 0 ? 'block' : 'none' }}>
        {renderUploadFile()}
      </div>
      <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
        {renderConfigForm()}
      </div>
    </LinkAssetToQaSpec>
  );
};

export default QaSpecLinkProductSpecDocumentModal;
