import { useState, useEffect } from 'react';

const useToggleActiveRibbon = (isPathActive) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isPathActive) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [isPathActive]);

  return isActive;
};

export default useToggleActiveRibbon;
