import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import * as _ from 'lodash';
import {
  AssetItemCardTop,
  AssetItemCardBottom,
} from 'pages/branded-assets/components';

import './AssetItemCard.less';

const AssetItemCard = (props) => {
  const { detailItemData, handleToggleAssetContext } = props;

  return (
    <Card
      className='item-card'
      bodyStyle={{ padding: 0 }}
      onDoubleClick={() =>
        handleToggleAssetContext(_.get(detailItemData, 'id'))
      }
    >
      <AssetItemCardTop
        upc12={_.get(detailItemData, 'upc12')}
        brand={_.get(detailItemData, 'brand')}
        name={_.get(detailItemData, 'name')}
        packageLevels={_.get(detailItemData, 'packageLevels')}
        snl={_.get(detailItemData, 'snl')}
        assetImgUrl={_.get(detailItemData, 'imgUrl')}
        publishImgUrl={_.get(detailItemData, 'imgUrl')}
        handleToggleAssetContext={handleToggleAssetContext}
      />
      <AssetItemCardBottom
        lastUpdateDate={_.get(detailItemData, 'lastUpdateDate')}
        imageUpdateDate={_.get(detailItemData, 'imageUpdateDate')}
      />
    </Card>
  );
};

// define propTypes with type
AssetItemCard.propTypes = {
  // ? detailItemData
  detailItemData: PropTypes.object,
  // ? handleToggleAssetContext
  handleToggleAssetContext: PropTypes.func,
};

export default AssetItemCard;
