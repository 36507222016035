import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { useGetEnumOptions } from 'pages/qa-spec/hooks';
import { Button } from 'antd';
import { PACKAGING_LIMIT_CHARACTERS } from 'pages/qa-spec/constant';
import CertificateFormList from './CertificateFormList';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from 'common/components';
import { BasicFormItem } from 'common/components/form/basic-form';
import { sortBy } from 'lodash';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import SnapshotFormItem from 'pages/qa-spec/components/qa-spec-tab/SnapshotFormItem';
import { getSnapshotQaSpecPackaging } from 'services/product';

import { getFormattedValue } from 'pages/qa-spec/utils/index';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import { renderPopoverContent } from 'pages/qa-spec/utils';

import { parsePackagingData } from './utils';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';

const makeSecondaryLanguageOption = (option) => {
  return {
    value: option?.enumCode,
    displayName: `${
      option.enumDescription
    } - (${option.enumCode.toUpperCase()})`,
  };
};

export const useGetQaPackagingFormDefine = ({
  handleToggleUploadDocumentModal,
  setUploadModalTitle,
  snapshotData,
  supplierModifiedData,
  productFull,
}) => {
  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedToSupplier = checkAssignProductToSupplier();

  const packagingEnumOptions = useGetEnumOptions({
    optionMapping: useMemo(
      () => (option) => ({
        ...option,
        value: option.enumDisplayName,
        displayName: option.enumDisplayName,
      }),
      []
    ),
  })?.packaging;

  const { id: productId } = useParams();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const { isLoading } = useGetSnapshotQaPackaging({
    enabled: !!productId && isToggleSnapshot,
    productId,
  });

  const enumType = {
    uom: packagingEnumOptions.uom,
    frontOfPackIcons: packagingEnumOptions.frontOfPackIcons,
    recyclable: packagingEnumOptions.recyclabel,
    qrCode: packagingEnumOptions.qrCode,
    bilingual: packagingEnumOptions.bilingual,
    hasFoodGradePlasticsCertifier:
      packagingEnumOptions.hasFoodGradePlasticsCertifier,
    recycledMaterial: packagingEnumOptions.recycledMaterial,
    soyInk: packagingEnumOptions.soyInk,
    secondaryLanguage: packagingEnumOptions.secondaryLanguage,
    vegetableBasedInk: packagingEnumOptions.vegetableBasedInk,
    hasFoodGradePlasticsCertifier:
      packagingEnumOptions.hasFoodGradePlasticsCertifier,
  };

  const getSecondaryLanguageFormatted = (snapshotData, name) => {
    if (!snapshotData?.length) return 'N/A';
    const arrList = snapshotData.reduce((accumulator, currentValue) => {
      const currentEnum = enumType[name]?.find(
        (item) => currentValue === item.enumCode
      );

      accumulator.push(
        `${
          currentEnum?.enumDescription
        } - (${currentEnum?.enumCode?.toUpperCase()})`
      );

      return accumulator;
    }, []);
    return arrList.join(', ');
  };

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const checkFieldRequired = (fieldName) => {
    const isAWGOwnerRequired =
      fieldName === 'productContainsBioengineeredIngredients' &&
      productFull?.ownerId === +process.env.REACT_APP_AWG_ID;

    if (isAWGOwnerRequired) return true;

    if (!requiredFields) return false;

    if (!isProductAssignedToSupplier) return false;

    return requiredFields.includes(`qaSpecification.labeling.${fieldName}`);
  };

  const qaPackagingFormDefine = useMemo(() => {
    const formProductDefine = [
      {
        name: 'productName',
        label: 'Product Name:',
        layout: 'horizontal',
        labelCol: { flex: '135px' },
        supplierModifiedData:
          supplierModifiedData?.['productName']?.trim() ?? '',
        snapshotData: snapshotData?.['productName']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Product Name',
          snapshotData?.['productName']?.trim() ?? '',
          supplierModifiedData?.['productName']?.trim() ?? ''
        ),
      },
      {
        name: 'upc12',
        label: 'UPC12:',
        inputProps: {
          style: { width: '100%' },
          min: '0',
          onKeyPress: (event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          },
        },
        layout: 'horizontal',
        labelCol: { flex: '135px' },
        supplierModifiedData: supplierModifiedData?.['upc12']?.trim() ?? '',
        snapshotData: snapshotData?.['upc12']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'UPC12',
          snapshotData?.['upc12']?.trim() ?? '',
          supplierModifiedData?.['upc12']?.trim() ?? ''
        ),
      },
      {
        name: 'truncatedUpc',
        label: 'Truncated UPC:',
        type: 'numeric',
        layout: 'horizontal',
        labelCol: { flex: '135px' },
        inputProps: {
          style: { width: '100%' },
          min: '0',
          onKeyPress: (event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          },
        },
        supplierModifiedData: supplierModifiedData?.['truncatedUpc'],
        snapshotData: snapshotData?.['truncatedUpc'],
        renderPopoverContent: renderPopoverContent(
          'Truncated UPC',
          snapshotData?.['truncatedUpc'],
          supplierModifiedData?.['truncatedUpc']
        ),
      },
      {
        name: 'secondaryDescriptor',
        label: 'Secondary Descriptor:',
        layout: 'horizontal',
        labelCol: { flex: '135px' },
        supplierModifiedData:
          supplierModifiedData?.['secondaryDescriptor']?.trim() ?? '',
        snapshotData: snapshotData?.['secondaryDescriptor']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Secondary Descriptor',
          snapshotData?.['secondaryDescriptor']?.trim() ?? '',
          supplierModifiedData?.['secondaryDescriptor']?.trim() ?? ''
        ),
      },
      {
        name: 'tertiaryDescriptor',
        label: 'Tertiary Descriptor:',
        layout: 'horizontal',
        labelCol: { flex: '135px' },
        supplierModifiedData:
          supplierModifiedData?.['tertiaryDescriptor']?.trim() ?? '',
        snapshotData: snapshotData?.['tertiaryDescriptor']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Tertiary Descriptor',
          snapshotData?.['tertiaryDescriptor']?.trim() ?? '',
          supplierModifiedData?.['tertiaryDescriptor']?.trim() ?? ''
        ),
      },
      [
        { rowConfig: { gutter: [15, 15] } },
        {
          name: 'size',
          label: 'Size:',
          type: 'numeric',
          layout: 'horizontal',
          labelCol: { flex: '135px' },
          colProps: {
            xs: 12,
          },
          inputProps: {
            style: { width: '100%' },
            min: 0,
          },
          style: { flexWrap: 'nowrap' },
          supplierModifiedData: supplierModifiedData?.['size'],
          snapshotData: snapshotData?.['size'],
          renderPopoverContent: renderPopoverContent(
            'Size',
            snapshotData?.['size'],
            supplierModifiedData?.['size']
          ),
        },
        {
          name: 'uom',
          label: 'UOM:',
          type: 'select',
          selectProps: {
            options: sortBy(packagingEnumOptions.uom, ['displayName']),
            inputProps: {
              allowClear: true,
              showSearch: true,
            },
          },
          layout: 'horizontal',
          labelCol: { flex: '40px' },
          colProps: {
            xs: 12,
          },
          formattedValue: getFormattedValue(
            snapshotData?.['uom'],
            'uom',
            enumType
          ),
          supplierModifiedData: supplierModifiedData?.['uom'],
          snapshotData: snapshotData?.['uom'],
          renderPopoverContent: renderPopoverContent(
            'UOM',
            snapshotData?.['uom'],
            supplierModifiedData?.['uom'],
            getFormattedValue(snapshotData?.['uom'], 'uom', enumType),
            null
          ),
        },
      ],
    ];

    const formLeftBottomDefine = [
      {
        name: 'ingredientStatement',
        label: 'Ingredient Statement',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.ingredientStatement,
          showCount: true,
        },
        supplierModifiedData:
          supplierModifiedData?.['ingredientStatement'] ?? '',
        snapshotData: snapshotData?.['ingredientStatement'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Ingredient Statement',
          snapshotData?.['ingredientStatement'] ?? '',
          supplierModifiedData?.['ingredientStatement'] ?? ''
        ),
      },
      {
        name: 'allergenStatement',
        label: 'Allergen Statement',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.allergenStatement,
          showCount: true,
        },
        supplierModifiedData: supplierModifiedData?.['allergenStatement'] ?? '',
        snapshotData: snapshotData?.['allergenStatement'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Allergen Statement',
          snapshotData?.['allergenStatement'] ?? '',
          supplierModifiedData?.['allergenStatement'] ?? ''
        ),
      },
      {
        name: 'storageInstructions',
        label: 'Storage Instructions',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.storageInstructions,
          showCount: true,
        },
        supplierModifiedData:
          supplierModifiedData?.['storageInstructions'] ?? '',
        snapshotData: snapshotData?.['storageInstructions'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Storage Instructions',
          snapshotData?.['storageInstructions'] ?? '',
          supplierModifiedData?.['storageInstructions'] ?? ''
        ),
      },
      {
        name: 'handlingInstructions',
        label: 'Handling Instructions',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.handlingInstructions,
          showCount: true,
        },
        supplierModifiedData:
          supplierModifiedData?.['handlingInstructions'] ?? '',
        snapshotData: snapshotData?.['handlingInstructions'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Handling Instructions',
          snapshotData?.['handlingInstructions'] ?? '',
          supplierModifiedData?.['handlingInstructions'] ?? ''
        ),
      },
      {
        name: 'thawingInstructions',
        label: 'Thawing Instructions',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.thawingInstructions,
          showCount: true,
        },
        supplierModifiedData:
          supplierModifiedData?.['thawingInstructions'] ?? '',
        snapshotData: snapshotData?.['thawingInstructions'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Thawing Instructions',
          snapshotData?.['thawingInstructions'] ?? '',
          supplierModifiedData?.['thawingInstructions'] ?? ''
        ),
      },
      {
        name: 'productContainsBioengineeredIngredients',
        label: 'Product Contains Bioengineered Ingredients',
        type: 'select',
        rules: [
          {
            required: checkFieldRequired(
              'productContainsBioengineeredIngredients'
            ),
            message: 'Product Contains Bioengineered Ingredients is required',
          },
        ],
        selectProps: {
          options: packagingEnumOptions.productContainsBioengineeredIngredients,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['productContainsBioengineeredIngredients'],
          'productContainsBioengineeredIngredients',
          enumType
        ),
        supplierModifiedData:
          supplierModifiedData?.['productContainsBioengineeredIngredients'],
        snapshotData: snapshotData?.['productContainsBioengineeredIngredients'],
        renderPopoverContent: renderPopoverContent(
          'Product Contains Bioengineered Ingredients',
          snapshotData?.['productContainsBioengineeredIngredients'],
          supplierModifiedData?.['productContainsBioengineeredIngredients'],
          getFormattedValue(
            snapshotData?.['productContainsBioengineeredIngredients'],
            'productContainsBioengineeredIngredients',
            enumType
          ),
          null
        ),
      },
      {
        name: 'bioengineeredStatement',
        label: 'Bioengineered Ingredients Statement',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.bioengineeredStatement,
          showCount: true,
        },
        supplierModifiedData:
          supplierModifiedData?.['bioengineeredStatement'] ?? '',
        snapshotData: snapshotData?.['bioengineeredStatement'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Bioengineered Ingredients Statement',
          snapshotData?.['bioengineeredStatement'] ?? '',
          supplierModifiedData?.['bioengineeredStatement'] ?? ''
        ),
      },
      {
        name: 'warningStatement',
        label: 'Warning / Caution Statement',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.warningStatement,
          showCount: true,
        },
        supplierModifiedData: supplierModifiedData?.['warningStatement'] ?? '',
        snapshotData: snapshotData?.['warningStatement'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Warning / Caution Statement',
          snapshotData?.['warningStatement'] ?? '',
          supplierModifiedData?.['warningStatement'] ?? ''
        ),
      },
      [
        { rowConfig: { gutter: [15, 15] } },
        {
          name: 'frontOfPackIcons',
          label: 'Front of Pack Icons',
          type: 'select',
          selectProps: {
            options: packagingEnumOptions.frontOfPackIcons,
            inputProps: {
              allowClear: true,
            },
          },
          colProps: { xs: 12 },
          formattedValue: getFormattedValue(
            snapshotData?.['frontOfPackIcons'],
            'frontOfPackIcons',
            enumType
          ),
          supplierModifiedData: supplierModifiedData?.['frontOfPackIcons'],
          snapshotData: snapshotData?.['frontOfPackIcons'],
          renderPopoverContent: renderPopoverContent(
            'Front of Pack Icons',
            snapshotData?.['frontOfPackIcons'],
            supplierModifiedData?.['frontOfPackIcons'],
            getFormattedValue(
              snapshotData?.['frontOfPackIcons'],
              'frontOfPackIcons',
              enumType
            ),
            null
          ),
        },
        {
          noStyle: true,
          shouldUpdate: (prevValues, currentValues) =>
            prevValues?.['frontOfPackIcons'] !==
            currentValues?.['frontOfPackIcons'],
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const frontOfPackIconsValue = getFieldValue('frontOfPackIcons');

              return (
                <Form.Item name='frontPackageIcons-upload-btn' label=' '>
                  <Button
                    type=''
                    danger
                    icon={<UploadOutlined />}
                    disabled={
                      frontOfPackIconsValue === 'No' ||
                      !frontOfPackIconsValue ||
                      !allowEdit
                    }
                    onClick={() => {
                      handleToggleUploadDocumentModal();
                      setUploadModalTitle(
                        'Upload Front of Pack Icons Document'
                      );
                    }}
                  >
                    Upload
                  </Button>
                </Form.Item>
              );
            },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15] } },
        {
          name: 'recyclable',
          label: 'Recyclable',
          type: 'select',
          selectProps: {
            options: packagingEnumOptions.recyclabel,
            inputProps: {
              allowClear: true,
            },
          },
          formattedValue: getFormattedValue(
            snapshotData?.['recyclable'],
            'recyclable',
            enumType
          ),
          supplierModifiedData: supplierModifiedData?.['recyclable'],
          snapshotData: snapshotData?.['recyclable'],
          renderPopoverContent: renderPopoverContent(
            'Recyclable',
            snapshotData?.['recyclable'],
            supplierModifiedData?.['recyclable'],
            getFormattedValue(
              snapshotData?.['recyclable'],
              'recyclable',
              enumType
            ),
            null
          ),
          colProps: { xs: 12 },
        },
        {
          noStyle: true,
          shouldUpdate: (prevValues, currentValues) =>
            prevValues?.['recyclable'] !== currentValues?.['recyclable'],
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const recyclableValue = getFieldValue('recyclable');

              const disabled = recyclableValue === 'No' || !recyclableValue;

              return (
                <SnapshotFormItem
                  supplierModifiedData={
                    supplierModifiedData?.['recyclableTypes']
                  }
                  snapshotData={snapshotData?.['recyclableTypes']}
                  isLoadingSnapshot={isLoading}
                  renderPopoverContent={renderPopoverContent(
                    '',
                    snapshotData?.['recyclableTypes'],
                    supplierModifiedData?.['recyclableTypes']
                  )}
                >
                  <BasicFormItem
                    name='recyclableTypes'
                    label=' '
                    type='select'
                    selectProps={{
                      inputProps: {
                        disabled,
                        allowClear: true,
                        mode: 'multiple',
                      },
                      options: sortBy(packagingEnumOptions.recyclableType, [
                        'displayName',
                      ]),
                    }}
                    allowEdit={allowEdit}
                  />
                </SnapshotFormItem>
              );
            },
          colProps: { xs: 12 },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15] } },
        {
          name: 'totalRecyclableContentPercentage',
          label: 'Total Recycled Content Percentage',
          type: 'numeric',
          colProps: { xs: 12 },
          inputProps: { style: { width: '100%' } },
          supplierModifiedData:
            supplierModifiedData?.['totalRecyclableContentPercentage'],
          snapshotData: snapshotData?.['totalRecyclableContentPercentage'],
          renderPopoverContent: renderPopoverContent(
            'Total Recycled Content Percentage',
            snapshotData?.['totalRecyclableContentPercentage'],
            supplierModifiedData?.['totalRecyclableContentPercentage']
          ),
        },
        {
          name: 'postConsumerRecycledContentPercentage',
          label: 'Post Consumer Recycled Content Percentage',
          type: 'numeric',
          colProps: { xs: 12 },
          inputProps: { style: { width: '100%' } },
          supplierModifiedData:
            supplierModifiedData?.['postConsumerRecycledContentPercentage'],
          snapshotData: snapshotData?.['postConsumerRecycledContentPercentage'],
          renderPopoverContent: renderPopoverContent(
            'Post Consumer Recycled Content Percentage',
            snapshotData?.['postConsumerRecycledContentPercentage'],
            supplierModifiedData?.['postConsumerRecycledContentPercentage']
          ),
        },
      ],
      {
        name: 'packagingType',
        label: 'Packaging Type',
        type: 'select',
        selectProps: {
          options: sortBy(packagingEnumOptions.packagingType, ['displayName']),
          inputProps: {
            allowClear: true,
            showSearch: true,
          },
        },
        supplierModifiedData: supplierModifiedData?.['packagingType'],
        snapshotData: snapshotData?.['packagingType'],
        renderPopoverContent: renderPopoverContent(
          'Packaging Type',
          snapshotData?.['packagingType'],
          supplierModifiedData?.['packagingType']
        ),
      },
      {
        name: 'packagingMaterial',
        label: 'Packaging Material',
        type: 'select',
        selectProps: {
          options: sortBy(packagingEnumOptions.packagingMaterial, [
            'displayName',
          ]),
          inputProps: {
            allowClear: true,
            showSearch: true,
          },
        },
        supplierModifiedData: supplierModifiedData?.['packagingMaterial'],
        snapshotData: snapshotData?.['packagingMaterial'],
        renderPopoverContent: renderPopoverContent(
          'Packaging Material',
          snapshotData?.['packagingMaterial'],
          supplierModifiedData?.['packagingMaterial']
        ),
      },
      [
        { rowConfig: { gutter: [15, 15] } },
        {
          name: 'qrCode',
          label: 'QR Code',
          type: 'select',
          selectProps: {
            options: packagingEnumOptions.qrCode,
            inputProps: {
              allowClear: true,
            },
          },
          formattedValue: getFormattedValue(
            snapshotData?.['qrCode'],
            'qrCode',
            enumType
          ),
          supplierModifiedData: supplierModifiedData?.['qrCode'],
          snapshotData: snapshotData?.['qrCode'],
          renderPopoverContent: renderPopoverContent(
            'QR Code',
            snapshotData?.['qrCode'],
            supplierModifiedData?.['qrCode'],
            getFormattedValue(snapshotData?.['qrCode'], 'qrCode', enumType),
            null
          ),
          colProps: { xs: 12 },
        },
        {
          noStyle: true,
          shouldUpdate: (prevValues, currentValues) =>
            prevValues?.['qrCode'] !== currentValues?.['qrCode'],
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const qrCodeValue = getFieldValue('qrCode');

              return (
                <Form.Item name='qrCode-upload-btn' label=' '>
                  <Button
                    type=''
                    danger
                    icon={<UploadOutlined />}
                    disabled={
                      qrCodeValue === 'No' || !qrCodeValue || !allowEdit
                    }
                    onClick={() => {
                      handleToggleUploadDocumentModal();
                      setUploadModalTitle('Upload QR Code Document');
                    }}
                  >
                    Upload
                  </Button>
                </Form.Item>
              );
            },
        },
        {
          name: 'bilingual',
          label: 'Additional Languages on Label',
          type: 'select',
          selectProps: {
            options: packagingEnumOptions.bilingual,
            inputProps: {
              allowClear: true,
            },
          },
          formattedValue: getFormattedValue(
            snapshotData?.['bilingual'],
            'bilingual',
            enumType
          ),
          supplierModifiedData: supplierModifiedData?.['bilingual'],
          snapshotData: snapshotData?.['bilingual'],
          renderPopoverContent: renderPopoverContent(
            'Additional Languages on Label',
            snapshotData?.['bilingual'],
            supplierModifiedData?.['bilingual'],
            getFormattedValue(
              snapshotData?.['bilingual'],
              'bilingual',
              enumType
            ),
            null
          ),
          colProps: { xs: 12 },
        },
        {
          noStyle: true,
          shouldUpdate: (prevValues, currentValues) =>
            prevValues?.['bilingual'] !== currentValues?.['bilingual'],
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const bilingualValue = getFieldValue('bilingual');

              const disabled = bilingualValue === 'No' || !bilingualValue;

              return (
                <SnapshotFormItem
                  supplierModifiedData={
                    supplierModifiedData?.['secondaryLanguage']
                  }
                  snapshotData={snapshotData?.['secondaryLanguage']}
                  isLoadingSnapshot={isLoading}
                  renderPopoverContent={renderPopoverContent(
                    'Secondary Language',
                    snapshotData?.['secondaryLanguage'],
                    supplierModifiedData?.['secondaryLanguage'],
                    getSecondaryLanguageFormatted(
                      snapshotData?.['secondaryLanguage'],
                      'secondaryLanguage'
                    ),
                    null
                  )}
                >
                  <BasicFormItem
                    name='secondaryLanguage'
                    label='Secondary Language'
                    type='select'
                    selectProps={{
                      inputProps: {
                        showSearch: true,
                        allowClear: true,
                        disabled,
                        mode: 'multiple',
                      },
                      options: sortBy(
                        packagingEnumOptions.secondaryLanguage?.map((option) =>
                          makeSecondaryLanguageOption(option)
                        ),
                        ['displayName']
                      ),
                    }}
                    allowEdit={allowEdit}
                  />
                </SnapshotFormItem>
              );
            },
          colProps: { xs: 12 },
        },
      ],
    ];

    const formProductClaimFirstSectionDefine = [
      {
        name: 'claim1',
        label: 'Claim 1:',
        layout: 'horizontal',
        supplierModifiedData: supplierModifiedData?.['claim1']?.trim() ?? '',
        snapshotData: snapshotData?.['claim1']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Claim 1',
          snapshotData?.['claim1']?.trim() ?? '',
          supplierModifiedData?.['claim1']?.trim() ?? ''
        ),
      },
      {
        name: 'claim2',
        label: 'Claim 2:',
        layout: 'horizontal',
        supplierModifiedData: supplierModifiedData?.['claim2']?.trim() ?? '',
        snapshotData: snapshotData?.['claim2']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Claim 2',
          snapshotData?.['claim2']?.trim() ?? '',
          supplierModifiedData?.['claim2']?.trim() ?? ''
        ),
      },
      {
        name: 'claim3',
        label: 'Claim 3:',
        layout: 'horizontal',
        supplierModifiedData: supplierModifiedData?.['claim3']?.trim() ?? '',
        snapshotData: snapshotData?.['claim3']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Claim 3',
          snapshotData?.['claim3']?.trim() ?? '',
          supplierModifiedData?.['claim3']?.trim() ?? ''
        ),
      },
      {
        name: 'claim4',
        label: 'Claim 4:',
        layout: 'horizontal',
        supplierModifiedData: supplierModifiedData?.['claim4']?.trim() ?? '',
        snapshotData: snapshotData?.['claim4']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Claim 4',
          snapshotData?.['claim4']?.trim() ?? '',
          supplierModifiedData?.['claim4']?.trim() ?? ''
        ),
      },
      {
        name: 'claim5',
        label: 'Claim 5:',
        layout: 'horizontal',
        supplierModifiedData: supplierModifiedData?.['claim5']?.trim() ?? '',
        snapshotData: snapshotData?.['claim5']?.trim() ?? '',
        renderPopoverContent: renderPopoverContent(
          'Claim 5',
          snapshotData?.['claim5']?.trim() ?? '',
          supplierModifiedData?.['claim5']?.trim() ?? ''
        ),
      },
    ];

    const formRightSecondSection = [
      {
        name: 'romanceCopy',
        label: 'Romance Copy',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.romanceCopy,
          showCount: true,
        },
        supplierModifiedData: supplierModifiedData?.['romanceCopy'] ?? '',
        snapshotData: snapshotData?.['romanceCopy'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Romance Copy',
          snapshotData?.['romanceCopy'] ?? '',
          supplierModifiedData?.['romanceCopy'] ?? ''
        ),
      },
      {
        name: 'usage',
        label: 'Usage',
        type: 'textarea',
        rules: [
          {
            required: checkFieldRequired('usage'),
            message: 'Usage is required',
          },
        ],
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.usage,
          showCount: true,
        },
        supplierModifiedData: supplierModifiedData?.['usage'] ?? '',
        snapshotData: snapshotData?.['usage'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Usage',
          snapshotData?.['usage'] ?? '',
          supplierModifiedData?.['usage'] ?? ''
        ),
      },
      {
        name: 'cookingInstructions',
        label: 'Cooking Instructions/ Directions',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.cookingInstructions,
          showCount: true,
        },
        supplierModifiedData:
          supplierModifiedData?.['cookingInstructions'] ?? '',
        snapshotData: snapshotData?.['cookingInstructions'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Cooking Instructions/ Directions',
          snapshotData?.['cookingInstructions'] ?? '',
          supplierModifiedData?.['cookingInstructions'] ?? ''
        ),
      },
      {
        name: 'recipe',
        label: 'Recipe',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.recipe,
          showCount: true,
        },
        supplierModifiedData: supplierModifiedData?.['recipe'] ?? '',
        snapshotData: snapshotData?.['recipe'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Recipe',
          snapshotData?.['recipe'] ?? '',
          supplierModifiedData?.['recipe'] ?? ''
        ),
      },
    ];

    const formDietaryThirdSectionDefine = [
      [
        { rowConfig: { gutter: [15, 15], style: { marginBottom: 10 } } },
        {
          name: 'kosherYN',
          label: 'Kosher:',
          type: 'checkbox',
          valuePropName: 'checked',
          supplierModifiedData: supplierModifiedData?.['kosherYN'] ?? false,
          snapshotData: snapshotData?.['kosherYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Kosher',
            snapshotData?.['kosherYN'] ?? false,
            supplierModifiedData?.['kosherYN'] ?? false,
            null,
            'checkbox'
          ),
          colProps: {
            xs: 8,
          },
          layout: 'horizontal',
          labelCol: {
            flex: '79px',
          },
        },
        {
          colProps: {
            xs: 16,
          },
          noStyle: true,
          shouldUpdate: () => true,
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const enabled = getFieldValue('kosherYN');
              const seletecterCertifiers = getSelectedCertifiers({
                getFieldValue,
              });

              return (
                <CertificateFormList
                  name='kosherCertifier'
                  certificateType='Kosher'
                  type='select'
                  isEdit={allowEdit}
                  disabled={!enabled}
                  selectProps={{
                    options:
                      packagingEnumOptions.dietaryCertificate.kosherCertifier,
                    optionProps: (option) => ({
                      disabled: seletecterCertifiers?.includes(option.value),
                    }),
                    inputProps: {
                      allowClear: true,
                      showSearch: true,
                    },
                  }}
                  snapshotData={snapshotData}
                  supplierModifiedData={supplierModifiedData}
                  isLoadingSnapshot={isLoading}
                />
              );
            },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15], style: { marginBottom: 10 } } },
        {
          name: 'organicYN',
          label: 'Organic:',
          type: 'checkbox',
          layout: 'horizontal',
          valuePropName: 'checked',
          supplierModifiedData: supplierModifiedData?.['organicYN'] ?? false,
          snapshotData: snapshotData?.['organicYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Organic',
            snapshotData?.['organicYN'] ?? false,
            supplierModifiedData?.['organicYN'] ?? false,
            null,
            'checkbox'
          ),
          colProps: {
            xs: 8,
          },
          labelCol: {
            flex: '79px',
          },
        },
        {
          colProps: {
            xs: 16,
          },
          noStyle: true,
          shouldUpdate: () => true,
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const enabled = getFieldValue('organicYN');

              return (
                <CertificateFormList
                  name='organicCertifier'
                  certificateType='Organic'
                  isEdit={allowEdit}
                  disabled={!enabled}
                  snapshotData={snapshotData}
                  supplierModifiedData={supplierModifiedData}
                  isLoadingSnapshot={isLoading}
                />
              );
            },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15], style: { marginBottom: 10 } } },
        {
          name: 'halalYN',
          label: 'Halal:',
          type: 'checkbox',
          valuePropName: 'checked',
          supplierModifiedData: supplierModifiedData?.['halalYN'] ?? false,
          snapshotData: snapshotData?.['halalYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Halal',
            snapshotData?.['halalYN'] ?? false,
            supplierModifiedData?.['halalYN'] ?? false,
            null,
            'checkbox'
          ),
          colProps: {
            xs: 8,
          },
          layout: 'horizontal',
          labelCol: {
            flex: '79px',
          },
        },
        {
          colProps: {
            xs: 16,
          },
          noStyle: true,
          shouldUpdate: () => true,
          renderInput:
            ({ allowEdit, inputProps }) =>
            ({ getFieldValue }) => {
              const enabled = getFieldValue('halalYN');
              const seletecterCertifiers = getSelectedCertifiers({
                getFieldValue,
              });

              return (
                <CertificateFormList
                  name='halalCertifier'
                  certificateType='Halal'
                  type='select'
                  isEdit={allowEdit}
                  disabled={!enabled}
                  selectProps={{
                    options:
                      packagingEnumOptions.dietaryCertificate.halalCertifier,
                    optionProps: (option) => ({
                      disabled: seletecterCertifiers?.includes(option.value),
                    }),
                    inputProps: {
                      allowClear: true,
                      showSearch: true,
                    },
                  }}
                  snapshotData={snapshotData}
                  supplierModifiedData={supplierModifiedData}
                  isLoadingSnapshot={isLoading}
                />
              );
            },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15], style: { marginBottom: 10 } } },
        {
          name: 'nonGMOYN',
          label: 'Non-GMO:',
          type: 'checkbox',
          valuePropName: 'checked',
          supplierModifiedData: supplierModifiedData?.['nonGMOYN'] ?? false,
          snapshotData: snapshotData?.['nonGMOYN'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'Non-GMO',
            snapshotData?.['nonGMOYN'] ?? false,
            supplierModifiedData?.['nonGMOYN'] ?? false,
            null,
            'checkbox'
          ),
          colProps: {
            xs: 8,
          },
          layout: 'horizontal',
          labelCol: {
            flex: '79px',
          },
        },
        {
          colProps: {
            xs: 16,
          },
          noStyle: true,
          shouldUpdate: () => true,
          renderInput:
            ({ allowEdit, inputProps }) =>
            ({ getFieldValue }) => {
              const enabled = getFieldValue('nonGMOYN');
              const seletecterCertifiers = getSelectedCertifiers({
                getFieldValue,
              });

              return (
                <CertificateFormList
                  name='nonGMOCertifier'
                  certificateType='Non GMO'
                  type='select'
                  isEdit={allowEdit}
                  disabled={!enabled}
                  selectProps={{
                    options:
                      packagingEnumOptions.dietaryCertificate.nonGMOCertifier,
                    optionProps: (option) => ({
                      disabled: seletecterCertifiers?.includes(option.value),
                    }),
                    inputProps: {
                      allowClear: true,
                      showSearch: true,
                    },
                  }}
                  snapshotData={snapshotData}
                  supplierModifiedData={supplierModifiedData}
                  isLoadingSnapshot={isLoading}
                />
              );
            },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15], style: { marginBottom: 10 } } },
        {
          name: 'isoyn',
          label: 'ISO:',
          type: 'checkbox',
          layout: 'horizontal',
          valuePropName: 'checked',
          supplierModifiedData: supplierModifiedData?.['isoyn'] ?? false,
          snapshotData: snapshotData?.['isoyn'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'ISO',
            snapshotData?.['isoyn'] ?? false,
            supplierModifiedData?.['isoyn'] ?? false,
            null,
            'checkbox'
          ),
          colProps: {
            xs: 8,
          },
          labelCol: {
            flex: '79px',
          },
        },
        {
          colProps: {
            xs: 16,
          },
          noStyle: true,
          shouldUpdate: () => true,
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const enabled = getFieldValue('isoyn');

              return (
                <CertificateFormList
                  name='isoCertifier'
                  certificateType='ISO'
                  isEdit={allowEdit}
                  disabled={!enabled}
                  snapshotData={snapshotData}
                  supplierModifiedData={supplierModifiedData}
                  isLoadingSnapshot={isLoading}
                />
              );
            },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15], style: { marginBottom: 10 } } },
        {
          name: 'brcyn',
          label: 'BRC:',
          type: 'checkbox',
          layout: 'horizontal',
          valuePropName: 'checked',
          supplierModifiedData: supplierModifiedData?.['brcyn'] ?? false,
          snapshotData: snapshotData?.['brcyn'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'BRC',
            snapshotData?.['brcyn'] ?? false,
            supplierModifiedData?.['brcyn'] ?? false,
            null,
            'checkbox'
          ),
          colProps: {
            xs: 8,
          },
          labelCol: {
            flex: '79px',
          },
        },
        {
          colProps: {
            xs: 16,
          },
          noStyle: true,
          shouldUpdate: () => true,
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const enabled = getFieldValue('brcyn');

              return (
                <CertificateFormList
                  name='brcCertifier'
                  certificateType='BRC'
                  isEdit={allowEdit}
                  disabled={!enabled}
                  snapshotData={snapshotData}
                  supplierModifiedData={supplierModifiedData}
                  isLoadingSnapshot={isLoading}
                />
              );
            },
        },
      ],
      [
        { rowConfig: { gutter: [15, 15], style: { marginBottom: 10 } } },
        {
          name: 'ifsyn',
          label: 'IFS:',
          type: 'checkbox',
          layout: 'horizontal',
          valuePropName: 'checked',
          supplierModifiedData: supplierModifiedData?.['ifsyn'] ?? false,
          snapshotData: snapshotData?.['ifsyn'] ?? false,
          renderPopoverContent: renderPopoverContent(
            'IFS',
            snapshotData?.['ifsyn'] ?? false,
            supplierModifiedData?.['ifsyn'] ?? false,
            null,
            'checkbox'
          ),
          colProps: {
            xs: 8,
          },
          labelCol: {
            flex: '79px',
          },
        },
        {
          colProps: {
            xs: 16,
          },
          noStyle: true,
          shouldUpdate: () => true,
          renderInput:
            ({ allowEdit }) =>
            ({ getFieldValue }) => {
              const enabled = getFieldValue('ifsyn');

              return (
                <CertificateFormList
                  name='ifsCertifier'
                  certificateType='IFS'
                  isEdit={allowEdit}
                  disabled={!enabled}
                  snapshotData={snapshotData}
                  supplierModifiedData={supplierModifiedData}
                  isLoadingSnapshot={isLoading}
                />
              );
            },
        },
      ],
    ];

    const formRightFourthSectionDefine = [
      {
        name: 'otherCertifications',
        label: 'Other Certifications',
        type: 'textarea',
        inputProps: {
          autoSize: { minRows: 3, maxRows: 3 },
          maxLength: PACKAGING_LIMIT_CHARACTERS.otherCertifications,
          showCount: true,
        },
        supplierModifiedData:
          supplierModifiedData?.['otherCertifications'] ?? '',
        snapshotData: snapshotData?.['otherCertifications'] ?? '',
        renderPopoverContent: renderPopoverContent(
          'Other Certifications',
          snapshotData?.['otherCertifications'] ?? '',
          supplierModifiedData?.['otherCertifications'] ?? ''
        ),
      },
      {
        name: 'hasFoodGradePlasticsCertifier',
        label: 'Contains Food Grade Plastics',
        type: 'select',
        selectProps: {
          options: packagingEnumOptions.hasFoodGradePlasticsCertifier,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['hasFoodGradePlasticsCertifier'],
          'hasFoodGradePlasticsCertifier',
          enumType
        ),
        supplierModifiedData:
          supplierModifiedData?.['hasFoodGradePlasticsCertifier'],
        snapshotData: snapshotData?.['hasFoodGradePlasticsCertifier'],
        renderPopoverContent: renderPopoverContent(
          'Contains Food Grade Plastics',
          snapshotData?.['hasFoodGradePlasticsCertifier'],
          supplierModifiedData?.['hasFoodGradePlasticsCertifier'],
          getFormattedValue(
            snapshotData?.['hasFoodGradePlasticsCertifier'],
            'hasFoodGradePlasticsCertifier',
            enumType
          ),
          null
        ),
      },
      {
        name: 'recycledMaterial',
        label: 'Made With Recycled Material',
        type: 'select',
        selectProps: {
          options: packagingEnumOptions.recycledMaterial,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['recycledMaterial'],
          'recycledMaterial',
          enumType
        ),
        supplierModifiedData: supplierModifiedData?.['recycledMaterial'],
        snapshotData: snapshotData?.['recycledMaterial'],
        renderPopoverContent: renderPopoverContent(
          'Made With Recycled Material',
          snapshotData?.['recycledMaterial'],
          supplierModifiedData?.['recycledMaterial'],
          getFormattedValue(
            snapshotData?.['recycledMaterial'],
            'recycledMaterial',
            enumType
          ),
          null
        ),
      },
      {
        name: 'soyInk',
        label: 'Soy Ink',
        type: 'select',
        selectProps: {
          options: packagingEnumOptions.soyInk,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['soyInk'],
          'soyInk',
          enumType
        ),
        supplierModifiedData: supplierModifiedData?.['soyInk'],
        snapshotData: snapshotData?.['soyInk'],
        renderPopoverContent: renderPopoverContent(
          'Soy Ink',
          snapshotData?.['soyInk'],
          supplierModifiedData?.['soyInk'],
          getFormattedValue(snapshotData?.['soyInk'], 'soyInk', enumType),
          null
        ),
      },
      {
        name: 'vegetableBasedInk',
        label: 'Vegetable Based Ink ',
        type: 'select',
        selectProps: {
          options: packagingEnumOptions.vegetableBasedInk,
          inputProps: {
            allowClear: true,
          },
        },
        formattedValue: getFormattedValue(
          snapshotData?.['vegetableBasedInk'],
          'vegetableBasedInk',
          enumType
        ),
        supplierModifiedData: supplierModifiedData?.['vegetableBasedInk'],
        snapshotData: snapshotData?.['vegetableBasedInk'],
        renderPopoverContent: renderPopoverContent(
          'Vegetable Based Ink',
          snapshotData?.['vegetableBasedInk'],
          supplierModifiedData?.['vegetableBasedInk'],
          getFormattedValue(
            snapshotData?.['vegetableBasedInk'],
            'vegetableBasedInk',
            enumType
          )
        ),
      },
    ];

    return {
      formProductDefine,
      formProductClaimFirstSectionDefine,
      formRightSecondSection,
      formLeftBottomDefine,
      formDietaryThirdSectionDefine,
      formRightFourthSectionDefine,
    };
  }, [
    packagingEnumOptions,
    setUploadModalTitle,
    supplierModifiedData,
    snapshotData,
    enumType,
    getSecondaryLanguageFormatted,
    handleToggleUploadDocumentModal,
    isProductAssignedToSupplier,
    isLoading,
  ]);

  return qaPackagingFormDefine;
};

const getSelectedCertifiers = ({ getFieldValue }) => {
  return [
    ...(getFieldValue('kosherCertifier') ?? []),
    ...(getFieldValue('organicCertifier') ?? []),
    ...(getFieldValue('halalCertifier') ?? []),
    ...(getFieldValue('nonGMOCertifier') ?? []),
  ];
};

const getQueryKeyQaPackaging = (productId) => {
  return ['pim', 'snapshot', parseInt(productId), 'qa-packaging'];
};

export const useGetSnapshotQaPackaging = ({
  enabled,
  productId,
  ...restProps
}) => {
  const queryClient = useQueryClient();

  const getSnapshotQaPackagingData = async () => {
    const response = await getSnapshotQaSpecPackaging({ productId });
    const { isSuccess, data } = response;
    if (!isSuccess) return null;

    return parsePackagingData(data);
  };

  const query = useQuery({
    queryKey: getQueryKeyQaPackaging(productId),
    queryFn: getSnapshotQaPackagingData,
    enabled,
    ...restProps,
  });

  const invalidateSnapshotQaSpecPackaging = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyQaPackaging(productId),
    });
  };

  return { ...query, invalidateSnapshotQaSpecPackaging };
};
