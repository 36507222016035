import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getMarkupList = (data) => {
  return api.sendPost(endpoints.GET_MAIN_PAGE_GRID, data);
};

export const createMarkup = (params) => {
  return api.sendPost(endpoints.CREATE_MARKUP, params);
};

// export const cloneMarkup = (params) => {
//   return api.sendPost(endpoints.CLONE_MARKUP, params);
// };

export const getMarkupDetail = (params) => {
  return api.sendPost(endpoints.GET_MARKUP_DETAIL, params);
};

export const getMarkupContentDetail = (params) => {
  return api.sendGet(endpoints.GET_MAIN_PAGE_SHORT_DETAILS, { params });
};

// Curated Query Markup
export const getCuratedQuery = (data) => {
  return api.sendPost(endpoints.GET_CURATED_QUERY, data);
};

export const getCuratedQueryShortDetail = (params) => {
  return api.sendGet(endpoints.GET_CURATED_QUERY_SHORT_DETAIL, { params });
};

export const getAllQueriesNotCuratedQueryGrid = (params) => {
  return api.sendPost(endpoints.GET_ALL_QUERIES_NOT_CURATED_QUERY_GRID, params);
};

export const saveCuratedQuery = (params) => {
  return api.sendPost(endpoints.SAVE_CURATED_QUERY, params);
};

export const getMarkupAsset = (params) => {
  return api.sendPost(endpoints.GET_MARKUP_ASSET, params);
};

export const saveMarkup = (params) => {
  return api.sendPost(endpoints.SAVE_MARKUP, params);
};

export const deleteMarkup = (params) => {
  return api.sendPost(endpoints.DELETE_MARKUP, params);
};

export const cloneMarkup = (params) => {
  return api.sendPost(endpoints.CLONE_MARKUP, params);
};

export const getMarkupText = (params) => {
  return api.sendGet(endpoints.GET_MARKUP_TEXT, { params });
};

export const revokeCuratedQuery = (params) => {
  return api.sendPost(endpoints.REVOKE_CURATED_QUERY, params);
};
