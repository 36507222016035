import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { saveFile } from 'utils/saveFile';
import { UNPREVIEWABLE_FILE_TYPES } from 'static/FileType';
import './PreviewableModalBody.less';

import FileViewer from 'common/components/file-viewer';

const PreviewableModalBody = (props) => {
  const {
    link,
    fileName,
    fileType,
    isDocument,
    pdfPreview,
    mediaType,
    originLink,
    fileSize,
  } = props;

  const isEmptyFile = fileSize === 0;

  const ImgPreview = ({ link, fileName }) => (
    <div className='previewable-modal--image-wrapper'>
      <div>
        <img
          src={link}
          alt={fileName || 'image'}
          style={{ zIndex: ' 500' }}
        ></img>
      </div>
    </div>
  );

  const Iframe = ({ url }) => {
    const iframe = `<iframe style="width: ${'100vh'}; height: ${'100%'}" src="${url}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
        dangerouslySetInnerHTML={{ __html: iframe ? iframe : '' }}
      />
    );
  };

  const isRenderUnsupportedFileViewer = () => {
    return (
      UNPREVIEWABLE_FILE_TYPES.indexOf(fileType) > -1 || !link || isEmptyFile
    );
  };

  return (
    <>
      {fileType && fileType?.toLowerCase() === 'iframe' ? (
        <Iframe url={link} />
      ) : isDocument ? (
        isRenderUnsupportedFileViewer() ? (
          <UnsupportedFileViewerCustom
            link={link}
            originLink={originLink}
            fileName={fileName}
            isEmptyFile={isEmptyFile}
          />
        ) : (
          <FileViewerRender
            {...props}
            originLink={originLink}
            fileName={fileName}
            isEmptyFile={isEmptyFile}
          />
        )
      ) : (
        <ImgPreview link={link} fileName={fileName} />
      )}
    </>
  );
};

const FileViewerRender = (props) => {
  const {
    mediaType,
    fileType,
    pdfPreview,
    link,
    originLink,
    fileName,
    isEmptyFile,
  } = props;
  const [type, setFileType] = useState('');
  const [filePath, setFilePath] = useState('');

  const onError = (e) => {
    console.log(e, 'error in file-viewer');
  };

  const onSetFileType = () => {
    return mediaType &&
      mediaType === 'Document' &&
      pdfPreview &&
      pdfPreview !== ''
      ? 'pdf'
      : fileType;
  };

  const onSetFilePath = () => {
    return mediaType &&
      mediaType === 'Document' &&
      pdfPreview &&
      pdfPreview !== ''
      ? pdfPreview
      : link;
  };

  useEffect(() => {
    let type = onSetFileType();
    let path = onSetFilePath();
    if (fileType === 'pdf' && !link) {
      setFileType('unsupported');
      return;
    }
    setFileType(type);
    setFilePath(path);
  }, [mediaType, fileType, pdfPreview, link]);

  const renderUnsupportedFile = () => (
    <UnsupportedFileViewer
      link={link}
      originLink={originLink}
      fileName={fileName}
      isEmptyFile={isEmptyFile}
    />
  );

  return (
    <>
      {type && filePath && (
        <div className='file-viewer'>
          <FileViewer
            fileType={type}
            filePath={filePath}
            onError={onError}
            unsupportedComponent={renderUnsupportedFile}
          />
        </div>
      )}
    </>
  );
};

const UnsupportedFileViewer = ({ link, originLink, fileName, isEmptyFile }) => (
  <div className='previewable-modal--unsupported-component'>
    <div className='previewable-modal--unsupported-title'>
      {isEmptyFile
        ? 'No preview available. Please download to view detail.'
        : 'The file type is not supported. You can download it right here.'}
    </div>
    <Button
      className='preview-card__modal--title-download'
      onClick={() => saveFile(link || originLink, fileName)}
      disabled={!originLink && !link}
      icon={<DownloadOutlined />}
    >
      Download
    </Button>
  </div>
);

const UnsupportedFileViewerCustom = ({
  link,
  originLink,
  fileName,
  isEmptyFile,
}) => (
  <div className='file-viewer-unsupported__wrapper'>
    <div className='file-viewer-unsupported__content'>
      <UnsupportedFileViewer
        link={link}
        originLink={originLink}
        fileName={fileName}
        isEmptyFile={isEmptyFile}
      />
    </div>
  </div>
);

PreviewableModalBody.propTypes = {
  link: PropTypes.string,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  isDocument: PropTypes.bool,
  pdfPreview: PropTypes.string,
  mediaType: PropTypes.string,
  originLink: PropTypes.string, // origin link to preview or download
};

PreviewableModalBody.defaultProps = {
  isDocument: false,
};

export default PreviewableModalBody;
