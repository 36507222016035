import React from 'react';
import { useSelector } from 'react-redux';

import { useNode, useEditor } from '@craftjs/core';

import EmailEditorSection from './EmailEditorSection';
import EmailEditorInlineText from './EmailEditorInlineText';
import EmailEditorContentElement from './EmailEditorContentElement';
import {
  EmailEditorSectionSetting,
  EmailEditorSigningOffSettings,
} from '../settings';

import userSelectors from 'redux/user/selectors';

const EmailEditorSigningOff = (props) => {
  const { id } = useNode();
  const { actions } = useEditor();

  const { text } = props;

  const userInfo = useSelector(userSelectors.makeSelectUserProfileHeader());
  const fullName = `${userInfo?.firstName} ${userInfo?.lastName}`;

  const onChangeContent = (value) => {
    actions.history.throttle().setProp(id, (props) => (props.text = value));
  };

  return (
    <EmailEditorSectionSetting settings={<EmailEditorSigningOffSettings />}>
      <EmailEditorSection>
        <EmailEditorContentElement
          content={
            <EmailEditorInlineText
              content={text}
              onChangeContent={onChangeContent}
            />
          }
        />
        <EmailEditorContentElement content={fullName} editable={false} />
      </EmailEditorSection>
    </EmailEditorSectionSetting>
  );
};

EmailEditorSigningOff.craft = {
  displayName: 'Signing Off',
  props: {
    text: 'Regards,',
    settings: {
      bold: false,
      italic: false,
      underLine: false,
      fontSize: 14,
      color: '#333',
    },
  },
};

export default EmailEditorSigningOff;
