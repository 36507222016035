import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import Messages from 'i18n/messages/maintenance';

import { forwardTo } from 'utils/common/route';

import { dialogFunction } from 'common/components/index';
import RibbonButton from 'common/components/button/RibbonButton';

import { deleteCommunicationTemplatesService } from 'services/communicationTemplate';
import * as communicationTemplateSelectors from 'pages/communication-template/controllers/selectors';

const DeleteCommunicationTemplate = () => {
  const intl = useIntl();

  const idTemplate = useSelector(
    communicationTemplateSelectors.selectIdTemplate()
  );
  const { isCreate } = useSelector(
    communicationTemplateSelectors.selectCommunicationMode()
  );

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  const callApiDelete = async () => {
    try {
      const response = await deleteCommunicationTemplatesService({
        communicationTemplatesIds: [idTemplate],
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        forwardTo('/communication-templates');
        showSuccessMessageDialog();
      } else {
        showErrorMessageDialog(message);
      }
    } catch (error) {
      showErrorMessageDialog(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const showErrorMessageDialog = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.deleteTemplate}
      onClick={confirmDelete}
      disabled={isCreate}
    />
  );
};

export default DeleteCommunicationTemplate;
