import React from 'react';
import classnames from 'classnames';

import './SectionWrapper.less';

const SectionWrapper = (props) => {
  const {
    className,
    title,
    placement = 'left',
    children,
    style,
    ...restProps
  } = props;

  return (
    <div
      className={classnames('section-wrapper__wrapper', className)}
      style={style}
    >
      <div
        className={classnames('section-wrapper__container', className)}
        {...restProps}
      >
        {title && (
          <div
            className='section-wrapper__title'
            style={{
              left: placement === 'right' ? 'unset' : undefined,
              right: placement === 'right' ? 10 : 'unset',
            }}
          >
            {title && title}
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default SectionWrapper;
