import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import {
  RibbonBar,
  RibbonDivider,
  DownloadFormItem,
  MinimumRequirementsValidate,
} from 'common/components';
import { ViewLayout, AdvanceStack } from './components';
import OpenMember from './components/sections/member-grid/OpenMember';
import BanMember from './components/sections/member-grid/BanMember';
import Categories from './components/sections/home-grid/Categories';
import ExecuteWorkflow from './components/sections/home-grid/Execute';
import Addition from './components/sections/home-grid/AdditionSection';
import ActionsRibbonBar from 'common/components/contact-action/ActionsRibbonBar';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import SubscriptionMember from './components/sections/member-grid/SubscriptionMember';
import DetailSection from './components/sections/home-grid/DetailSection';

import gridSelectors from 'common/components/grid-view/controllers/selectors';
import BackControl from './components/controls/home/Back';

import * as selectorsGlobal from 'redux/global/selectors';
import * as ribbonSelectors from 'redux/ribbon/selectors';
import FilterMemberSection from './components/sections/member-grid/FilterMemberSection';
import MemAction from './components/sections/member-grid/MemAction';

// const productsForMember = 'products/member';
// const assetsForMember = 'assets/member';

const Members = () => {
  const pathname = window.location.pathname;
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );
  const isDisplayMemberNew = useSelector(
    selectorsGlobal.selectDisplayMemberNew()
  );

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  // const isBackToMemberProfile =
  //   pathname.includes(productsForMember) || pathname.includes(assetsForMember);
  // const isReportingsBack = pathname.includes('/reporting');

  const isFavoriteView = pathname === '/favorite/favorite-members';

  const isDisabledAddition = selectedItemList?.length === 0;

  const isDisabledCloseDetail =
    window.location.search !== '?query=1' ||
    closeDetailStack?.name !== 'query' ||
    closeDetailStack?.stack.length < 2;

  return (
    <RibbonBar className='branded-member-grid__ribbon'>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabledGridQuery={isDisplayMemberNew} />

      <RibbonDivider />

      <Addition
        type='member'
        isDisabled={isDisabledAddition}
        selectedItemList={selectedItemList}
        isFavoriteView={isFavoriteView}
      />
      <ActionsRibbonBar view='member' selectedItemList={selectedItemList} />

      <RibbonDivider />

      <BanMember />

      <RibbonDivider />

      <MemAction />

      <OpenMember />

      <RibbonDivider />

      <Categories />

      <RibbonDivider />

      <FilterMemberSection />
      <RibbonDivider />
      {/* TODO: Add role permission later */}
      <ExecuteWorkflow entityType='Member' />

      <RibbonDivider />

      <AdvanceStack
        isNested
        selectedItemList={selectedItemList}
        disabledAdvanceFilter={isDisplayMemberNew}
      />

      <BackControl
        ribbonSide='right'
        isDisabled={isDisabledCloseDetail}
        // isBackToMemberProfile={isBackToMemberProfile}
        // isReportingsBack={isReportingsBack}
      />
    </RibbonBar>
  );
};

export default Members;
