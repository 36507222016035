import React from 'react';
import { Spin } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import './WithLoading.less';

const WithIndicator = (props) => {
  const { loading, delay, intl, ...rest } = props;

  return (
    <Spin
      wrapperClassName='with-loading'
      spinning={loading}
      delay={delay}
      tip={intl.formatMessage({
        id: 'Taco.common.components.indicator.loading',
      })}
      {...rest}
    >
      {props?.children && props.children}
    </Spin>
  );
};

WithIndicator.propTypes = {
  loading: PropTypes.bool,
  delay: PropTypes.number,

  intl: PropTypes.object,
};

export default injectIntl(WithIndicator);
