import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import { useParams } from 'react-router-dom';

import { Row, Col } from 'antd';

import { Form, SectionWrapper, CustomNotification } from 'common/components';
import SupplementForm from './SupplementForm';
import SupplementNutrient from './SupplementNutrient';
import { useInvalidateGroupDetail } from '../../../shared/hooks';

import { useAddSupplementLabel } from '../../hook/useAddSupplementLabel';

import {
  SUPPLEMENT_NUTRIENT_NAME,
  SUPPLEMENT_INITIAL_FORM,
} from 'pages/product-full-view/components/product-media-area/nutrition-label/constants';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

import './AddSupplementLabel.less';

const AddSupplementLabel = forwardRef((props, forwardedRef) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { id } = useParams();

  const nutrientsHandleRef = useRef();
  const ingredientsHandleRef = useRef();

  const {
    visible,
    factsPanel,
    typeView,
    onSubmitSuccess,
    onSubmitFail,
    setIsChangedForm,
  } = props;

  const mutation = useAddSupplementLabel({ productId: id, typeView });
  const { handleSyncAllProductDetailGroup, handleSyncAllQaDetailGroup } =
    useInvalidateGroupDetail(id);

  const initForm = (on) => {
    const initialData =
      on === 'add'
        ? SUPPLEMENT_INITIAL_FORM
        : {
            ...factsPanel,
            [SUPPLEMENT_NUTRIENT_NAME.NUTRIENT]: factsPanel.nutrients,
            [SUPPLEMENT_NUTRIENT_NAME.INGREDIENT]:
              factsPanel.dietaryIngredients,
          };

    form.setFieldsValue(initialData);
  };

  const submitForm = async (ixoneIgnore) => {
    nutrientsHandleRef?.current?.getOriginalDataSubmitOnEdit();
    ingredientsHandleRef?.current?.getOriginalDataSubmitOnEdit();

    const formValues = await form.validateFields();

    if (!formValues) return;

    const {
      [SUPPLEMENT_NUTRIENT_NAME.NUTRIENT]: nutrients,
      [SUPPLEMENT_NUTRIENT_NAME.INGREDIENT]: ingredients,
      _add, //*  payload excludes _add
      ...restFormValues
    } = formValues;

    const params = {
      productItemId: +id,
      data: {
        id: factsPanel?.id,
        nutrients: nutrients,
        dietaryIngredients: ingredients,
        ...restFormValues,
      },
      ixOneIgnore: factsPanel?.id ? ixoneIgnore : null,
    };

    mutation.mutate(params, {
      onSuccess: (result) => {
        if (result.isSuccess) {
          resetForm();
          handleSyncAllProductDetailGroup();
          handleSyncAllQaDetailGroup();
          onSubmitSuccess && onSubmitSuccess();

          CustomNotification.success('Update Supplement Fact successfully');
        } else {
          form.resetFields();

          onSubmitFail && onSubmitFail();

          CustomNotification.error('Something went wrong!');
        }
      },
    });
  };

  const resetForm = () => {
    form.resetFields();

    nutrientsHandleRef?.current?.resetState();
    ingredientsHandleRef?.current?.resetState();
  };

  const onFieldsChange = (changedFields, allFields) => {
    setIsChangedForm(true);

    const caloriesField = allFields.find(
      (fieldItem) => fieldItem.name[0] === 'calories'
    );

    const caloriesValue = caloriesField?.value;

    if (!caloriesValue) form.setFieldsValue({ caloriesFromFat: null });
  };

  useImperativeHandle(forwardedRef, () => {
    return {
      submitForm,
      initForm,
      resetForm,
    };
  });

  const SupplementIngredient = SupplementNutrient;

  return visible ? (
    <Form
      form={form}
      className='supplement-form'
      layout='vertical'
      onFieldsChange={onFieldsChange}
    >
      <Row className='supplement-form__wrapper' wrap={false} gutter={16}>
        <Col span={8}>
          <SupplementForm />
        </Col>
        <Col span={8}>
          <SectionWrapper
            className='supplement-form__nutrient'
            title={intl.formatMessage(Messages.supplementLabelNutrients)}
          >
            <SupplementNutrient
              ref={nutrientsHandleRef}
              name={SUPPLEMENT_NUTRIENT_NAME.NUTRIENT}
            />
          </SectionWrapper>
        </Col>
        <Col span={8}>
          <SectionWrapper
            className='supplement-form__nutrient'
            title={intl.formatMessage(
              Messages.supplementLabelDietaryIngredient
            )}
          >
            <SupplementIngredient
              ref={ingredientsHandleRef}
              name={SUPPLEMENT_NUTRIENT_NAME.INGREDIENT}
            />
          </SectionWrapper>
        </Col>
      </Row>
    </Form>
  ) : null;
});

export default AddSupplementLabel;
