import React from 'react';
import { Button, Layout } from 'antd';

import { CloseCircleOutlined } from '@ant-design/icons';

import { ReactComponent as ErrorIcon } from 'assets/svg-icons/error-svgrepo-com.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg-icons/success-svgrepo-com.svg';

import './style.less';

const ResultPage = ({
	isSuccess,
	btn = 'Click me !',
	onBtnClick,
	message = 'Sorry, something went wrong.',
	title = 'Oh no !',
}) => {
	return (
		<Layout className='result-page'>
			<div className={`${isSuccess ? 'success' : 'failed'} container-modal`}>
				<div>
					<div className='container-modal_image'>
						{isSuccess ? <SuccessIcon /> : <ErrorIcon />}
					</div>
					<div className='container-modal_content'>
						<p className='container-modal_content-title'>{title}</p>
						<p className='container-modal_content-message'>{message}</p>
					</div>
				</div>
				<div className='container-modal_button'>
					<Button size='large' shape='round' onClick={onBtnClick}>
						{btn}
					</Button>
				</div>
			</div>
		</Layout>
	);
};

export default ResultPage;
