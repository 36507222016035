// Grid communication templates
export const GET_COMMUNICATION_DETAILS_GRID =
  '/api/member-manager/communication-template-details-grid';

export const GET_COMMUNICATION_DETAILS_GRID_DISTINCT =
  '/api/member-manager/communication-template-details-grid/distinct';

// Mutate communication templates
export const CREATE_COMMUNICATION_TEMPLATE_TEXT =
  '/api/member-manager/create-new-communication-template-for-text';

export const EDIT_COMMUNICATION_TEMPLATE_TEXT =
  '/api/member-manager/edit-communication-template-for-text';

export const CREATE_COMMUNICATION_TEMPLATE_VIDEO =
  '/api/member-manager/create-new-communication-template-for-video';

export const EDIT_COMMUNICATION_TEMPLATE_VIDEO =
  '/api/member-manager/edit-communication-template-for-video';

export const DELETE_COMMUNICATION_TEMPLATES =
  '/api/member-manager/delete-communication-templates';

export const GET_COMMUNICATION_TEMPLATE_DETAIL =
  '/api/member-manager/communication-template-short-details';
