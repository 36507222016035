import React, { useEffect, useMemo } from 'react';

import { ProductDetailView } from 'pages/product-full-view/components/product-new-detail-view';

import {
  useProductDetail,
  useProductDetailDispatch,
  productDetailActionTypes,
} from 'pages/product-full-view/components/product-new-detail-view/ProductDetailContext';

import {
  filterModuleProductSchema,
  uppercaseFirstLetter,
} from 'pages/product-full-view/shared/utils';

const PublicationErrorProductDetail = ({
  productDetail,
  productSchema,
  currentError = {},
  onResolveErrorPublicationSuccess,
}) => {
  const { fieldName: fieldFullPath } = currentError;
  const moduleNameError = fieldFullPath.split('.')[0];

  const dispatch = useProductDetailDispatch();

  const { formInstance } = useProductDetail();

  const { baseProductSchema, advancedProductSchema } = useMemo(
    () => filterModuleProductSchema(productSchema),
    [productSchema]
  );

  const baseProductModuleNames = baseProductSchema.map((schemaProd) =>
    schemaProd.moduleName.toLowerCase()
  );

  const isBaseProductSchema = Boolean(
    baseProductModuleNames.includes(moduleNameError.toLowerCase())
  );

  const advancedProductModuleNames = advancedProductSchema.map((schemaProd) =>
    schemaProd.moduleName.toLowerCase()
  );

  const isAdvancedProductSchema = Boolean(
    advancedProductModuleNames.includes(moduleNameError.toLowerCase())
  );

  useEffect(() => {
    if (isBaseProductSchema) {
      dispatch({
        type: productDetailActionTypes.ACTIVE_MODULES,
        payload: {
          value: [uppercaseFirstLetter(moduleNameError)], // header -> Header,... Elastic changed format data
          moduleView: 'base',
        },
      });
      dispatch({
        type: productDetailActionTypes.SET_ERROR_FIELD_FULL_PATH,
        payload: {
          fieldFullPath,
        },
      });
    }
  }, [dispatch, fieldFullPath, isBaseProductSchema, moduleNameError]);

  useEffect(() => {
    if (isAdvancedProductSchema) {
      dispatch({
        type: productDetailActionTypes.ACTIVE_MODULES,
        payload: {
          value: [uppercaseFirstLetter(moduleNameError)], // header -> Header,... Elastic changed format data
          moduleView: 'advanced',
        },
      });
      dispatch({
        type: productDetailActionTypes.SET_ERROR_FIELD_FULL_PATH,
        payload: {
          fieldFullPath,
        },
      });

      dispatch({
        type: productDetailActionTypes.SEARCH_PROPERTIES,
        payload: {
          searchText: fieldFullPath,
          moduleSchema: advancedProductSchema,
          modeView: 'view',
          formInstance,
        },
      });
    }
  }, [
    advancedProductSchema,
    dispatch,
    fieldFullPath,
    formInstance,
    isAdvancedProductSchema,
    moduleNameError,
  ]);

  return (
    <div className='publication-product-detail__wrapper'>
      <ProductDetailView
        product={productDetail}
        productSchema={productSchema}
        onResolveErrorPublicationSuccess={onResolveErrorPublicationSuccess}
      />
    </div>
  );
};

export default PublicationErrorProductDetail;
