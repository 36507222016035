import React from 'react';

import { Form } from 'antd';

const CustomForm = (props) => {
  const { children, ...rest } = props;

  return (
    <Form autoComplete='off' {...rest}>
      {children}
    </Form>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { ...Form, render: CustomForm };
