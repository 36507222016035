import React from 'react';
import PropTypes from 'prop-types';
import Messages from 'i18n/messages/home';
import { FolderAddOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import { forwardTo } from 'utils/common/route';

const CreateReporting = () => {
  return (
    <RibbonButton
      icon={<FolderAddOutlined />}
      label={Messages.reportingCreate}
      onClick={() => forwardTo(`/reporting/create`)}
      // disabled={isCreating || isEditting}
    />
  );
};

CreateReporting.propTypes = {
  createReporting: PropTypes.func,
};

export default CreateReporting;
