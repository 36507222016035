import * as _ from 'lodash';
import * as emailServices from 'services/email';

const mapEmailsWithAttachUrl = (messages, threadId) => {
  return new Promise(async (resolve) => {
    if (!messages) return [];

    let attachIdList = [];
    let attachInfoList = [];

    messages.forEach((msgItem, idx) => {
      if (msgItem?.attachmentIds?.length === 0) return;
      if (msgItem?.attachmentIds === null) return;
      attachIdList = [...attachIdList, ...msgItem?.attachmentIds];
    });

    if (attachIdList.length === 0) return resolve(messages);

    const response = await emailServices.getEmailAttachment({
      attachmentIds: attachIdList,
      threadId,
    });

    //* if get file fail
    if (!response?.isSuccess) return resolve(messages);

    //* if get file success
    attachInfoList = response?.data?.emailAttachmentInfoModels;

    const newMsgsList = await Promise.all(
      messages.map(async (msgItem) => {
        const attachmentIds = msgItem?.attachmentIds;

        if (!attachmentIds) return msgItem;

        let attachmentInfos = [];

        attachmentIds.forEach((attachId) => {
          let attachDetail = attachInfoList.find((o) => o.id === attachId);

          if (attachDetail) attachmentInfos.push(attachDetail);
        });

        return { ...msgItem, attachmentInfos };
      })
    );

    resolve(newMsgsList);
  });
};

export default mapEmailsWithAttachUrl;
