import { useEffect, useMemo, useRef } from 'react';
/**
 * A custom hook that converts a callback to a ref to avoid triggering re-renders when passed as a
 * prop or avoid re-executing effects when passed as a dependency.
 *
 * Avoid stale closure in hooks
 *
 * This hooks also called "useEventEffect" in React v18
 */
export const useCallbackRef = (callback) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  // https://github.com/facebook/react/issues/19240
  return useMemo(
    () =>
      (...args) =>
        callbackRef.current?.(...args),
    []
  );
};
