import { useEffect } from 'react';

import * as emailActions from '../../controller/actions';

const useSendEmailMessage = (hubConnection, dispatch) => {
  useEffect(() => {
    if (hubConnection) {
      hubConnection.on('SendMessageResponse', (response) => {
        if (response?.isSuccess) {
          dispatch(emailActions.sendEmailMessageSuccess(response.data));
          dispatch(emailActions.updateLastMessage(response.data?.messageId));
        } else {
          dispatch(emailActions.sendEmailMessageFail(response.data));
        }
      });
    }
  }, [hubConnection, dispatch]);
};

export { useSendEmailMessage };
