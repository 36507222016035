import React from 'react';
import PropTypes from 'prop-types';

import { BackControl } from '../../index';
import MemberAddOpenItemSection from '../member-full/MemberAddOpenItemSection';

import { RibbonSection } from 'common/components';
import { RibbonSectionDivider } from 'pages/home/ribbon/components';

const OtherLayout = (props) => {
  const {
    ribbonType,
    isHaveAddOpenItem = true,
    disableBackControl,
    closeDetailCallback,
  } = props;

  return (
    <RibbonSection>
      {isHaveAddOpenItem && (
        <>
          <MemberAddOpenItemSection />
          <RibbonSectionDivider />
        </>
      )}
      <BackControl
        ribbonType={ribbonType}
        isDisabled={disableBackControl}
        closeDetailCallback={closeDetailCallback}
      />
    </RibbonSection>
  );
};

OtherLayout.propTypes = {
  /**
   * to detect type of ribbon view
   */
};

export default OtherLayout;
