import { put, call, takeLatest, all } from 'redux-saga/effects';
import React from 'react';

import * as types from './constants';
import * as actions from './actions';
import * as actionsBranding from 'redux/branding/actions';
import * as actionsRibbon from 'pages/home/ribbon/asset-full/controllers/actions';

import * as servicesGrid from 'services/grid';
import { formatMDYWithParam } from 'utils/formatDate';
import {
  AssociationIconRendered,
  AssociationIconRenderedBound,
} from '../components/index';
import { notification } from 'antd';

import {
  getAssetData,
  serviceToGetFtpFileList,
  updateDigitalAssetService,
  updateDigitalReplaceAssetService,
  editIFrame,
  updateAssetType,
} from 'services/digitalAsset';

import * as tagRestrictionsServices from 'services/tag-restrictions';

export function* getAssetDataSaga(payload) {
  try {
    const response = yield call(getAssetData, payload.assetId);
    if (response.isSuccess) {
      yield put(actions.getAssetDataSuccess(response.data));
    } else {
      yield put(actions.getAssetDataError(response.message));
    }
  } catch (error) {
    yield put(actions.getAssetDataError(error));
  }
}

//* GET FTP FILE LIST SAGA
export function* getFtpFileListSaga(action) {
  try {
    const options = {
      params: {
        ServiceType: action.payload,
      },
    };
    const response = yield call(serviceToGetFtpFileList, options);
    if (response.isSuccess) {
      yield put(actions.getFtpFileListSuccess(response.data.assetsModels));
    } else {
      yield put(actions.getFtpFileListError(response.message));
    }
  } catch (error) {
    yield put(actions.getFtpFileListError(error));
  }
}

export function* updateDigitalAsset(payload) {
  try {
    let response;
    if (payload.params?.isIFrame)
      response = yield call(editIFrame, payload.params);
    else response = yield call(updateDigitalAssetService, payload.params);
    if (response?.isSuccess) {
      yield put(actions.updateRequiredField(response?.data?.requiredFields));
      yield put(actions.updateDigitalAssetSuccess(response.data));
      yield put(actionsBranding.getBrandingNoLoading());

      if (response.data?.requiredFields?.length) {
        yield put(actionsRibbon.editAsset());
      } else {
        yield put(actions.getAssetData(payload.params.id));
        yield put(actionsRibbon.cancelEditAsset());
        yield put(actions.updateIsActive(true));
      }
    } else {
      yield put(actions.updateDigitalAssetError(response?.message));
      yield put(actionsRibbon.cancelSavingAsset());
    }
  } catch (error) {
    yield put(actions.updateDigitalAssetError(error));
    yield put(actionsRibbon.cancelSavingAsset());
  }
}
export function* updateDigitalReplaceAsset(payload) {
  try {
    const response = yield call(
      updateDigitalReplaceAssetService,
      payload.params
    );
    if (response?.isSuccess) {
      yield put(actions.updateRequiredField(response?.data?.requiredFields));
      yield put(actionsBranding.getBrandingNoLoading());
      if (response.data?.requiredFields?.length) {
        yield put(actionsRibbon.editAsset());
      } else {
        yield put(actions.updateIsActive(true));
      }
      yield put(actions.updateDigitalAssetSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.updateDigitalAssetError(error));
  }
}

export function* updateAssetData(payload) {
  try {
    const response = yield call(updateAssetType, payload.params);
    if (response.isSuccess) {
      notification.success({
        message: 'Update Asset Type Success',
      });
      yield put(actions.getAssetData(payload.params.id));
    }
  } catch (error) {
    yield put(actions.updateAssetTypeError(error));
  }
}
const renderIconType = (params) => {
  return <AssociationIconRendered item={params} />;
};

const renderIconBound = (params) => {
  return <AssociationIconRenderedBound item={params} />;
};

const renderFileType = (params) => {
  return params.value?.toUpperCase() ?? null;
};

export function* gridDigitalAssetColumnInfo(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);

    let columns = [
      {
        field: '',
        width: 30,
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
      },
    ];

    if (response?.columns?.length > 0) {
      response.columns.forEach((val) => {
        if (!['id', 'otherAssetId'].includes(val.fieldNameCamelCase)) {
          if (val.fieldNameCamelCase === 'created') {
            val = {
              ...val,
              width: 100,
              suppressMenu: true,
              cellRenderer: formatMDYWithParam,
            };
          } else if (val.fieldNameCamelCase === 'isInBound') {
            val = {
              ...val,
              headerName: '',
              dataType: 'string',
              width: 100,
              suppressMenu: true,
              cellRenderer: renderIconBound,
            };
          } else if (val.fieldNameCamelCase === 'type') {
            val = {
              ...val,
              headerName: '',
              width: 100,
              suppressMenu: true,
              cellRenderer: renderIconType,
            };
          } else if (val.fieldNameCamelCase === 'assetName') {
            val = { ...val, flex: 1, minWidth: 130, suppressMenu: true };
          } else if (val.fieldNameCamelCase === 'fileType') {
            val = {
              ...val,
              width: 100,
              suppressMenu: true,
              // cellRenderer: renderFileType,
            };
          }
          columns.push(val);
        }
      });
    }

    yield put(actions.gridColumnAssetAssociationSuccess(columns));
  } catch (error) {
    yield put(actions.gridColumnAssetAssociationError(error));
  }
}

export function* getAuthorizedTagsSaga(payload) {
  try {
    const response = yield call(
      tagRestrictionsServices.getAuthorizedTags,
      payload.memberId
    );
    if (response.isSuccess) {
      yield put(actions.getAuthorizedTagsSuccess(response.data));
    } else {
      yield put(actions.getAuthorizedTagsError(response.message));
    }
  } catch (error) {
    yield put(actions.getAuthorizedTagsError(error));
  }
}

function* watchAll() {
  yield all([
    takeLatest(types.GET_ASSET_DATA, getAssetDataSaga),
    takeLatest(types.GET_FTP_FILE_LIST, getFtpFileListSaga),
    takeLatest(types.UPDATE_DIGITAL_ASSET, updateDigitalAsset),
    takeLatest(types.UPDATE_DIGITAL_REPLACE_ASSET, updateDigitalReplaceAsset),
    takeLatest(
      types.GET_ASSET_ASSOCIATION_GRID_COLUMN,
      gridDigitalAssetColumnInfo
    ),
    takeLatest(types.UPDATE_ASSET_TYPE, updateAssetData),
    takeLatest(types.GET_AUTHORIZED_TAGS, getAuthorizedTagsSaga),
  ]);
}

export default watchAll;
