import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Dropdown, Menu } from 'antd';
import { FolderOutlined, DownloadOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import CopyFolder from '../../controls/folders/CopyFolder';
import MoveFolder from '../../controls/folders/MoveFolder';
import BulkEditProduct from '../../controls/product/BulkEditProduct';
import CopyFolderModal from '../../controls/folders/CopyFolderModal';
import MoveFolderModal from '../../controls/folders/MoveFolderModal';

// redux
import gridSelector from 'common/components/grid-view/controllers/selectors';
import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';
import reducer from 'common/components/grid-view/controllers/reducer';
import saga from 'common/components/grid-view/controllers/saga';

import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { usePermissionEditSharedProduct, useCheckIsSuperAdmin } from 'hooks';

import Messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import './FolderSection.less';
import { Can } from 'context/Can';
import {
  ABILITY_ACTION,
  ABILITY_SUBJECT,
  PERMISSION_EDIT_PRODUCTS,
} from 'static/Permission';
import EditFolderDetail from '../../controls/folders/EditFolderDetail';
import DeleteFolderDetail from '../../controls/folders/DeleteFolderDetail';
import CreateFolderDetail from '../../controls/folders/CreateFolderDetail';

const key = 'gridView';
const { SubMenu } = Menu;

const FolderDetailSection = (props) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const {
    folderDetail,
    handleLoading,
    onReloadWhenSaveSuccess,
    onMoveFolder,
    onCopyFolder,
  } = props;

  const [copyFolderModalVisible, setCopyFolderVisible] = useState(false);
  const [moveFolderModalVisible, setMoveFolderVisible] = useState(false);

  let detailItems = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const checkItemsProduct = () => {
    return detailItems
      .filter((item) => item?.type?.toLowerCase() === 'product')
      .map((item) => item?.id);
  };

  const detailItemsProduct = checkItemsProduct();

  const hasPermissionEditProducts = useCheckPermissions(
    PERMISSION_EDIT_PRODUCTS
  );

  const hasPermissionEditSharedProduct = usePermissionEditSharedProduct();
  const isSuperAdmin = useCheckIsSuperAdmin();

  const isAllowActionsPim =
    isSuperAdmin || hasPermissionEditProducts || hasPermissionEditSharedProduct;

  const closeCopyModal = () => {
    setCopyFolderVisible(false);
  };

  const closeMoveModal = () => {
    setMoveFolderVisible(false);
  };

  const menuOptions = (
    <Menu className='folder-section__menu'>
      <Menu.Item key='createFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <CreateFolderDetail
              disabled={!allowed || !folderDetail.editable}
              selectedFolders={folderDetail}
              handleLoading={handleLoading}
              onReloadWhenSaveSuccess={onReloadWhenSaveSuccess}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item key='editFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <EditFolderDetail
              disabled={!allowed || !folderDetail.editable}
              selectedFolders={folderDetail}
              handleLoading={handleLoading}
              onReloadWhenSaveSuccess={onReloadWhenSaveSuccess}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item key='deleteFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.DELETE} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <DeleteFolderDetail
              disabled={!allowed || !folderDetail.editable}
              selectedFolders={folderDetail}
              handleLoading={handleLoading}
              onReloadWhenSaveSuccess={onReloadWhenSaveSuccess}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item key='copyFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.COPY} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <CopyFolder
              disabled={!allowed}
              selectedFolders={[folderDetail]}
              onClick={() => setCopyFolderVisible(true)}
            />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item key='moveFolder' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.COPY} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <MoveFolder
              disabled={!allowed || !folderDetail.editable}
              selectedFolders={[folderDetail]}
              onClick={() => setMoveFolderVisible(true)}
            />
          )}
        </Can>
      </Menu.Item>

      <SubMenu
        key={'bulk-edit-product'}
        style={{ backgroundColor: 'inherit' }}
        disabled={!isAllowActionsPim}
        title={
          <RibbonButton
            size='small'
            className='button-item-dropdown'
            icon={<DownloadOutlined />}
            disabled={!isAllowActionsPim}
            label={messagesProduct.bulkEditProduct}
          />
        }
      >
        <BulkEditProduct
          disabled={!isAllowActionsPim}
          disabledExport={!isAllowActionsPim}
          selectedProducts={detailItemsProduct}
          selectedFolders={[folderDetail?.id]}
        />
      </SubMenu>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
        <RibbonButton
          icon={<FolderOutlined />}
          label={Messages.folderActions}
        />
      </Dropdown>
      <CopyFolderModal
        visible={copyFolderModalVisible}
        closeModal={closeCopyModal}
        selectedFolder={folderDetail}
        onCopy={onCopyFolder}
      />
      <MoveFolderModal
        visible={moveFolderModalVisible}
        currentFolderId={folderDetail?.id}
        closeModal={closeMoveModal}
        selectedItems={[folderDetail]}
        onMove={onMoveFolder}
      />
    </>
  );
};

export default FolderDetailSection;
