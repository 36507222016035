import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Row } from 'antd';
import { MESSAGE_TYPE, CHATTYPE } from 'static/Constants';

import * as emailActions from '../../controller/actions';
import * as emailServices from 'services/email';
import * as emailSelector from '../../controller/selectors';
import userSelectors from 'redux/user/selectors';

import InfiniteScroll from 'react-infinite-scroller';
import EmailTurnList from './EmailTurnList';
import * as _ from 'lodash';

import * as globalSelectors from 'redux/global/selectors';
import * as globalActions from 'redux/global/actions';

import mapEmailWithAttachUrl from '../../mapper/mapEmailsWithAttachUrl';

let scrollBottomTimeout;
function EmailThreadInfo(props) {
  const {
    scroller,
    threadId,
    participants,
    emailThreadInfoContent,
    scrollToBottom,
    toggleNewMailMessage,
  } = props;

  const dispatch = useDispatch();

  const [hasMore, setHasMore] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isFinishLoad, setIsFinishLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const newEmailInfo = useSelector(globalSelectors.selectNewEmailInfo());

  const userId = useSelector(userSelectors.makeSelectUserId());
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());
  const accountSend = useSelector(emailSelector.selectEmailAccount());
  const senderType = useSelector(
    emailSelector.selectEmailActiveThreadInfo()
  )?.userType;
  const userSender = `U-${userId}`;
  const memberSender = `C-${memberId}`;

  const handleScrollDown = (e) => {
    const isFinishLoadDown = e.detail.isFinishLoadDown;
    if (isFinishLoadDown) return;
    clearTimeout(scrollBottomTimeout);
    scrollBottomTimeout = setTimeout(() => {
      loadMoreMessagesDown(e);
    }, 100);
  };

  const loadMoreMessagesDown = (e) => {
    const emailThreadInfoContent = e.detail.emailThreadInfoContent;
    if (!threadId && emailThreadInfoContent.length === 0) return;

    const requestParams = {
      threadId: threadId,
      timestamp:
        emailThreadInfoContent?.length > 0
          ? emailThreadInfoContent[0]?.sendTime + 1
          : null,
      direction: 1, // 0: up; 1: down
    };

    emailServices
      .getEmailThreadInfoContent(requestParams)
      .then(async (res) => {
        if (res.isSuccess) {
          const messages = res.data.threadMessages;

          const handledMessages = await mapEmailWithAttachUrl(
            messages,
            threadId
          );

          if (handledMessages?.length < 20) {
            dispatch(emailActions.updateFinishLoadDown(true));
          }
          if (handledMessages?.length === 0) {
            return;
          }
          dispatch(
            emailActions.getEmailThreadInfoContentSuccess([
              ...handledMessages.reverse(),
              ...emailThreadInfoContent,
            ])
          );
        } else {
          setIsFinishLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Make Thread as read when receive new message
  useEffect(() => {
    if (accountSend) {
      const payload = {
        userId: senderType === CHATTYPE.COMPANY ? memberSender : userSender,
        threadId,
      };
      dispatch(globalActions.makeAThreadAsRead(payload));
    }
  }, [newEmailInfo?.messageId]);

  useEffect(() => {
    if (conditionToAppendNewMsg(newEmailInfo)) {
      const emailInfo = {
        attachmentIds: newEmailInfo?.attachmentIds,
        messageId: newEmailInfo?.messageId,
        sendTime: newEmailInfo?.sendTime,
        messageText: newEmailInfo?.messageText,
        messageType: MESSAGE_TYPE[`${newEmailInfo?.messageType}`],
        senderId: newEmailInfo?.senderId,
      };

      dispatch(emailActions.setNewEmailReceived(emailInfo));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEmailInfo?.messageId]);

  const conditionToAppendNewMsg = (msgInfo) => {
    return (
      !_.isEmpty(msgInfo) &&
      msgInfo?.threadId === threadId &&
      msgInfo?.senderId !== userSender &&
      msgInfo?.senderId !== memberSender
    );
  };

  const onLoadMoreThreadMsg = () => {
    if (!threadId) return;
    setLoading(true);
    const requestParams = {
      threadId: threadId,
      timestamp:
        emailThreadInfoContent?.length > 0
          ? emailThreadInfoContent[emailThreadInfoContent.length - 1]?.sendTime
          : null,
      direction: 0, // 0: up; 1: down
    };

    emailServices
      .getEmailThreadInfoContent(requestParams)
      .then(async (res) => {
        if (res.isSuccess) {
          const messages = res.data.threadMessages;

          const handledMessages = await mapEmailWithAttachUrl(
            messages,
            threadId
          );

          if (handledMessages?.length === 1) {
            // end
            setIsFinishLoad(true);
            return;
          }
          if (handledMessages?.length < 20) {
            setIsFinishLoad(true);
          }
          dispatch(
            emailActions.getEmailThreadInfoContentSuccess(
              emailThreadInfoContent.concat(
                handledMessages?.slice(1, handledMessages.length)
              )
            )
          );
        } else {
          setIsFinishLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const scrollBottomWhenLoad = () => {
    scrollToBottom();
    setFirstLoad(true);
  };

  useEffect(() => {
    setTimeout(() => {
      scrollBottomWhenLoad();
    }, 1500);

    if (toggleNewMailMessage) {
      scrollToBottom();
      dispatch(emailActions.toggleNewMailMessage(false));
    }
  }, [scrollToBottom, toggleNewMailMessage]);

  useEffect(() => {
    document.addEventListener('emailScrollBottom', handleScrollDown);
    return () => {
      document.removeEventListener('emailScrollBottom', handleScrollDown);
      clearTimeout(scrollBottomTimeout);
    };
  }, []);

  useEffect(() => {
    if (emailThreadInfoContent?.length >= 20) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [emailThreadInfoContent?.length]);

  return (
    <InfiniteScroll
      pageStart={0}
      initialLoad={false}
      loadMore={onLoadMoreThreadMsg}
      hasMore={hasMore && !isFinishLoad}
      useWindow={false}
      isReverse={true}
    >
      <Spin tip='Loading...' spinning={!firstLoad || loading}>
        <div className='email-thread'>
          <EmailTurnList
            data={emailThreadInfoContent?.slice().reverse()}
            participants={participants}
          />
        </div>
      </Spin>
    </InfiniteScroll>
  );
}

export default EmailThreadInfo;
