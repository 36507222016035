import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useParams } from 'react-router-dom';

import { FormAddButton, FormDeleteButton } from 'common/components';

import { QaGridEdit } from 'pages/qa-spec/components';
import { WithQaGridHeader } from 'pages/qa-spec/components';

import { useGetProductEnums } from 'hooks';
import {
  useGetFormulaGridHandlers,
  useGetFormulaColDefs,
} from 'pages/qa-spec/hooks/useFormula';

import { getAllRows } from 'pages/qa-spec/utils';

const QaFormulaGrid = forwardRef((props, forwardedRef) => {
  const gridInst = useRef();
  const { id: productId } = useParams();

  const { title, isEdit, formulaGridData, snapshotGridValues, useSnapshot } =
    props;

  const [selectedRows, setSelectedRows] = useState([]);

  const { productEnums } = useGetProductEnums();

  const fields = useGetFormulaColDefs(productEnums, productId, { useSnapshot });

  const { handleAddFormula, handleDeleteFormula } =
    useGetFormulaGridHandlers(gridInst);

  const getGridData = () => {
    return getAllRows(gridInst).map((formulaItem) => {
      return {
        ...formulaItem,
        country: formulaItem?.country?.length > 0 ? formulaItem?.country : null,
        digitalAssetId: formulaItem?.documents?.digitalAssetId,
      };
    });
  };

  const stopEditing = () => {
    gridInst.current.api.stopEditing();
  };

  const onRowSelected = () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();
    setSelectedRows(selectedRows);
  };

  useImperativeHandle(forwardedRef, () => ({
    getGridData: () => {
      return getGridData();
    },
    updateGridData: (data) => {
      gridInst.current.api.setRowData(data);
    },
    stopEditing: () => {
      stopEditing();
    },
    clearSelectedRows: () => {
      setSelectedRows([]);
    },
  }));

  return (
    <WithQaGridHeader
      title={title}
      forceEdit={isEdit}
      showSaveBtn={false}
      showCancelBtn={false}
      showEditBtn={false}
      buttons={[
        () => (
          <FormAddButton text='Add new formula' onClick={handleAddFormula} />
        ),
        () => (
          <FormDeleteButton
            disabled={selectedRows?.length === 0}
            onClick={handleDeleteFormula}
          />
        ),
      ]}
      productId={productId}
    >
      {(isEdit) => {
        return (
          <QaGridEdit
            fields={fields}
            isEdit={isEdit}
            gridInst={gridInst}
            gridData={formulaGridData}
            getRowNodeId={(data) => {
              return data?.['uuid'];
            }}
            onRowSelected={onRowSelected}
            snapshotGridValues={snapshotGridValues}
          />
        );
      }}
    </WithQaGridHeader>
  );
});

export default QaFormulaGrid;
