import { useEffect } from 'react';

import * as chatActions from '../../controller/actions';
import * as emailActions from '../../../mail/controller/actions';

import {
  getNewStateAttachment,
  formatAttachmentFromResponse,
  getUniqueAttachment,
  getResponseChatFiles,
  getAccountInGroup,
  formatAttachmentAfterAdd,
} from './utils';

const defaultStateAttachment = {
  attachmentIds: [],
  cacheAttachment: [],
  attachment: [],
};

const attachmentActions = {
  UPDATE_ATTACHMENT_ID: 'UPDATE_ATTACHMENT_ID',
  UPDATE_CACHE_ATTACHMENT: 'UPDATE_CACHE_ATTACHMENT',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  SAVE_ATTACHMENT: 'SAVE_ATTACHMENT',
  SAVE_ATTACHMENT_SUCCESS: 'SAVE_ATTACHMENT_SUCCESS',
  RESET: 'RESET',
};

const attachmentReducer = (state = defaultStateAttachment, action) => {
  switch (action.type) {
    case attachmentActions.UPDATE_ATTACHMENT_ID: {
      return {
        ...state,
        attachmentIds: action.attachmentIds,
      };
    }

    case attachmentActions.UPDATE_CACHE_ATTACHMENT: {
      return {
        ...state,
        cacheAttachment: action.cacheAttachment,
      };
    }

    case attachmentActions.REMOVE_ATTACHMENT: {
      const { newAttachmentIds, newCacheAttachment } = getNewStateAttachment(
        state.cacheAttachment,
        action.fileUid
      );

      return {
        ...state,
        attachmentIds: newAttachmentIds,
        cacheAttachment: newCacheAttachment,
        attachment: state.attachment.filter(
          (item) => item.uid !== action.fileUid
        ),
      };
    }

    case attachmentActions.SAVE_ATTACHMENT: {
      const newAttachment = action.newAttachment.map((attachment) => {
        return {
          ...attachment,
          status: 'uploading',
        };
      });

      return {
        ...state,
        attachment: state.attachment.concat(newAttachment),
      };
    }

    case attachmentActions.SAVE_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        attachment: action.attachment,
      };
    }

    case attachmentActions.RESET:
      return defaultStateAttachment;

    default:
      return state;
  }
};

const useUploadChatFiles = ({
  threadId,
  attachList,
  prevAttachList,
  attachmentState,
  dispatch,
  isEmail,
}) => {
  const { cacheAttachment } = attachmentState;

  useEffect(() => {
    if (attachList.length === 0) {
      dispatch({ type: attachmentActions.RESET });
      return;
    }

    // Add file
    if (attachList?.length > prevAttachList?.length) {
      // Cache stored some attachment
      if (cacheAttachment.length === 0) {
        getResponseChatFiles(threadId, attachList, isEmail).then((response) => {
          const { attachmentIds, cacheAttachment, newAttachment } =
            formatAttachmentFromResponse(response, attachList);

          dispatch({
            type: attachmentActions.UPDATE_ATTACHMENT_ID,
            attachmentIds,
          });

          dispatch({
            type: attachmentActions.UPDATE_CACHE_ATTACHMENT,
            cacheAttachment,
          });

          dispatch({
            type: attachmentActions.SAVE_ATTACHMENT_SUCCESS,
            attachment: newAttachment,
          });
        });
      } else {
        const uniqueAttachList = getUniqueAttachment(
          attachList,
          cacheAttachment
        );

        if (uniqueAttachList.length > 0) {
          getResponseChatFiles(threadId, uniqueAttachList, isEmail).then(
            (response) => {
              const { attachmentIds, cacheAttachment, newAttachment } =
                formatAttachmentFromResponse(response, uniqueAttachList);

              dispatch({
                type: attachmentActions.UPDATE_ATTACHMENT_ID,
                attachmentIds: [
                  ...attachmentState.attachmentIds,
                  ...attachmentIds,
                ],
              });

              dispatch({
                type: attachmentActions.UPDATE_CACHE_ATTACHMENT,
                cacheAttachment: [
                  ...attachmentState.cacheAttachment,
                  ...cacheAttachment,
                ],
              });

              dispatch({
                type: attachmentActions.SAVE_ATTACHMENT_SUCCESS,
                attachment: formatAttachmentAfterAdd(
                  attachmentState.attachment,
                  newAttachment
                ),
              });
            }
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId, attachList, prevAttachList, dispatch, cacheAttachment]);

  return [];
};

const useSendMessage = (hubConnection, dispatch) => {
  useEffect(() => {
    if (hubConnection) {
      hubConnection.on('SendMessageResponse', (response) => {
        if (response?.isSuccess) {
          dispatch(chatActions.sendMessageChatSuccess(response.data));
          dispatch(chatActions.updateLastMessage(response.data?.messageId));
        } else {
          dispatch(chatActions.sendMessageChatError(response.data));
        }
      });
    }
  }, [hubConnection, dispatch]);
};

const useSaveAccountChat = ({
  groupUsers,
  dispatch,
  setSenderId,
  userId,
  memberId,
  isEnabled,
  isInEmail,
}) => {
  useEffect(() => {
    if (!isEnabled) return;

    if (userId || memberId) {
      const account = getAccountInGroup(groupUsers, userId, memberId);

      if (account) {
        if (account.type === 'Both Accounts' || account.type === 'Only User') {
          if (isInEmail) {
            dispatch(emailActions.saveAccountMail(account.accountUser));
          } else {
            dispatch(chatActions.saveAccountChat(account.accountUser));
          }
          setSenderId(`U-${userId}`);
        }

        if (account.type === 'Only Company') {
          if (isInEmail) {
            dispatch(emailActions.saveAccountMail(account.accountCompany));
          } else {
            dispatch(chatActions.saveAccountChat(account.accountCompany));
          }
          setSenderId(`C-${memberId}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupUsers, dispatch, userId, isEnabled, memberId, setSenderId]);
};

export {
  defaultStateAttachment,
  useSaveAccountChat,
  useSendMessage,
  useUploadChatFiles,
  attachmentReducer,
  attachmentActions,
};
