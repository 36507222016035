import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import {
  UploadOutlined,
  DownloadOutlined,
  AuditOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
  EditOutlined,
  FolderAddOutlined,
  FormOutlined,
  UserAddOutlined,
} from '@ant-design/icons';

import { UploadFileModal } from 'common/components';

import Messages from 'i18n/messages/maintenance';
import * as memberMaintenanceActions from 'pages/member-maintenance/controllers/actions';

import * as api from 'config/axios';

import * as memberMaintainEnpoints from 'services/memberMaintenance/endpoints';

import RibbonButton from 'common/components/button/RibbonButton';
import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';

import { UPLOAD_MAX_SIZE } from 'static/Constants';

import './MemberTemplateSection.less';

const { SubMenu } = Menu;

const UPLOAD_TEMPLATE_PERMISSION = [
  {
    action: ABILITY_ACTION.CREATE,
    subject: ABILITY_SUBJECT.MEMBER,
  },
  {
    action: ABILITY_ACTION.EDIT,
    subject: ABILITY_SUBJECT.MEMBER,
  },
];

const mapToMemberValidateGrid = (data) => {
  const nextData = _.cloneDeep(data);
  const allValidateKeys = Object.keys(nextData);
  allValidateKeys.forEach((templateKey) => {
    nextData[templateKey] = nextData[templateKey].map((rowItem) => ({
      checkbox: true,
      ...rowItem,
      uuid: uuidv4().toString(),
    }));
  });
  return nextData;
};

const MemberTemplateSection = (props) => {
  const [isUploadTemplateModalOpen, setIsUploadTemplateModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  const hasPermissionUploadTemplate = useCheckPermissions(
    UPLOAD_TEMPLATE_PERMISSION
  );

  const downloadEditMemberTemplate = () => {
    api.sendDownload({
      url: memberMaintainEnpoints.GET_TEMPLATE_EDIT_MEMBER,
    });
  };

  const downloadCreateMemberTemplate = () => {
    api.sendDownload({
      url: memberMaintainEnpoints.GET_TEMPLATE_CREATE_MEMBER,
    });
  };

  const downloadEditUserTemplate = () => {
    api.sendDownload({
      url: memberMaintainEnpoints.GET_TEMPLATE_EDIT_USER,
    });
  };

  const downloadCreateUserTemplate = () => {
    api.sendDownload({
      url: memberMaintainEnpoints.GET_TEMPLATE_CREATE_USER,
    });
  };

  const toggleUploadTemplateModal = () => {
    setIsUploadTemplateModalOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleUploadMemberOrUserTemplate = (result) => {
    if (result?.data?.isSuccess) {
      const proccessGridData = mapToMemberValidateGrid(result?.data?.data);
      toggleUploadTemplateModal();
      dispatch(
        memberMaintenanceActions.setTemplateValidation(proccessGridData)
      );
    }
  };

  const renderEditTemplateOpions = (
    <SubMenu
      title={<ButtonSmallIcon icon={<FormOutlined />} label={'Edit'} />}
      popupClassName='rb-member-communicate-section-submenu'
    >
      <Menu.Item>
        <ButtonSmallIcon
          icon={
            <div style={{ display: 'inline-block', position: 'relative' }}>
              <UsergroupAddOutlined />
              <EditOutlined className='rb-member-communicate-section-submenu__icon-subicon' />
            </div>
          }
          label={'Member'}
          onClick={downloadEditMemberTemplate}
        />
      </Menu.Item>
      <Menu.Item key={'Load'}>
        <ButtonSmallIcon
          icon={
            <div style={{ display: 'inline-block', position: 'relative' }}>
              <UserDeleteOutlined />
              <EditOutlined className='rb-member-communicate-section-submenu__icon-subicon' />
            </div>
          }
          label={'User'}
          onClick={downloadEditUserTemplate}
        />
      </Menu.Item>
    </SubMenu>
  );

  const renderCreateTemplateOpions = (
    <SubMenu
      title={<ButtonSmallIcon icon={<FolderAddOutlined />} label={'Create'} />}
      popupClassName='rb-member-communicate-section-submenu'
    >
      <Menu.Item>
        <ButtonSmallIcon
          icon={<UsergroupAddOutlined />}
          label={'Member'}
          onClick={downloadCreateMemberTemplate}
        />
      </Menu.Item>
      <Menu.Item key={'Load'}>
        <ButtonSmallIcon
          icon={<UserAddOutlined />}
          label={'User'}
          onClick={downloadCreateUserTemplate}
        />
      </Menu.Item>
    </SubMenu>
  );

  const menuOptions = (
    <Menu className='rb-member-communicate-section-menu'>
      <SubMenu
        key='Download'
        popupClassName='rb-member-communicate-section-submenu'
        style={{ backgroundColor: 'inherit' }}
        title={
          <ButtonSmallIcon
            icon={<DownloadOutlined />}
            label={'Download Template'}
          />
        }
      >
        {renderEditTemplateOpions}
        {renderCreateTemplateOpions}
      </SubMenu>

      {hasPermissionUploadTemplate && (
        <Menu.Item key={'Load'}>
          <ButtonSmallIcon
            icon={<UploadOutlined />}
            label={'Load Template'}
            onClick={toggleUploadTemplateModal}
          />

          <UploadFileModal
            apiUrl={
              memberMaintainEnpoints.MEMBER_MAINTAIN_VALIDATE_BULK_TEMPLATE
            }
            title='Upload Templates'
            isOpen={isUploadTemplateModalOpen}
            onCancel={toggleUploadTemplateModal}
            width={700}
            bodyStyle={{ height: 600 }}
            isUploadMultipleModal
            fileParamName='TemplateFiles'
            showServerMessage
            getResponse={handleUploadMemberOrUserTemplate}
            maxSize={UPLOAD_MAX_SIZE.GENERAL}
            supportTypes={['xlsx', 'xls']}
          />
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions}>
      <RibbonButton icon={<AuditOutlined />} label={Messages.template} />
    </Dropdown>
  );
};

export default MemberTemplateSection;
