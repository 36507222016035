import React from 'react';

import { Menu, Dropdown } from 'antd';

import { PLANOGRAM_TYPE } from 'static/Constants';

const ImageDownloadOption = (props) => {
  const { onClick, children, ...rest } = props;

  const downloadOptions = (
    <Menu onClick={onClick}>
      <Menu.Item key={'original'}>Original</Menu.Item>
      {PLANOGRAM_TYPE.map((type, index) => {
        return <Menu.Item key={type}>{type}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={downloadOptions}
      placement='bottomRight'
      trigger='click'
      {...rest}
    >
      {children}
    </Dropdown>
  );
};

export default ImageDownloadOption;
