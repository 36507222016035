import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Action from './Action';
import {
  List,
  Popover,
  Button,
  notification,
  Spin,
  Row,
  Col,
  Typography,
  Divider,
} from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { forwardTo } from 'utils/common/route';
import classnames from 'classnames';
// import MeetingNotification from './MeetingNotification';
import gridViewSelectors from 'common/components/grid-view/controllers/selectors';
import * as workflowActions from 'pages/workflow/controllers/actions';
import * as api from 'config/axios';

import QaSpecWorkflowTodoList from './components/QaSpecWorkflowTodoList';

import { useGetQaSpecTodoList } from 'pages/qa-spec-workflow/hook';
import { updateSearchText } from 'pages/home/utils';
import { useCheckPermissions } from 'hooks';

import { PERMISSION_MANAGE_QA_SPEC_WORKFLOW } from 'static/Permission';
import {
  TO_DO_DISPLAY_BELL_COLUMN,
  TO_DO_NOT_DISPLAY_BELL_COLUMN,
  SEE_MORE_DISPLAY_BELL_COLUMN,
  SEE_MORE_NOT_DISPLAY_BELL_COLUMN,
} from './constants';

import './Task.less';

const Task = (props) => {
  const {
    count,
    offset,
    iconStyle,
    workflowsNotification,
    updateTodoList,
    awaitingList,
    updateAwaitingList,
    isDisplayBell,
  } = props;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { Title } = Typography;

  const dispatch = useDispatch();

  const visibleContentPane = useSelector(gridViewSelectors.makeSelectVisible());
  const pathname = window.location.pathname;
  const isDisplayMeeting = true;

  // const hasPermissionManageQaSpecWorkflow = useCheckPermissions(
  //   PERMISSION_MANAGE_QA_SPEC_WORKFLOW
  // );

  // const { qaSpecWorkflowTodoList } = useGetQaSpecTodoList(
  //   hasPermissionManageQaSpecWorkflow
  // );

  const updateIsAcceptStepGrid = () => {
    if (visibleContentPane && pathname === '/workflows') {
      dispatch(workflowActions.updateAcceptStepGrid(true));
    }
  };

  const updateIsApproveStepGrid = () => {
    if (visibleContentPane && pathname === '/workflows') {
      dispatch(workflowActions.updateApproveStepGrid(true));
    }
  };

  const onClickSeemore = () => {
    forwardTo('/workflows');
    setVisible(false);
  };

  const onClickViewItem = (item) => {
    forwardTo(
      `/workflow?workflowImplId=${item?.workflowImplementationId}&stepImplId=${item?.workflowImplementationStepId}`
    );
    setVisible(false);
  };

  const onRejectTodo = (event, id) => {
    setLoading(true);
    event.stopPropagation();
    const params = {
      id: id,
      isAccepted: false,
    };
    api
      .sendPost('/api/workflow/accept-or-reject-todo-list', params)
      .then((response) => {
        if (response?.isSuccess) {
          notification.success({ message: 'Reject Todo list success' });
          updateTodoList();
          updateIsAcceptStepGrid();
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onAcceptTodo = (event, id) => {
    setLoading(true);
    event.stopPropagation();
    const params = {
      id: id,
      isAccepted: true,
    };
    api
      .sendPost('/api/workflow/accept-or-reject-todo-list', params)
      .then((response) => {
        if (response.isSuccess) {
          notification.success({ message: 'Accept Todo list success' });
          updateTodoList();
          updateIsAcceptStepGrid();
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onRejectAwaiting = (event, id) => {
    setLoading(true);
    event.stopPropagation();
    const params = {
      wfImpStepId: id,
      isApprove: false,
    };
    api
      .sendPost('/api/workflow/accept-waiting-for-approve', params)
      .then((response) => {
        if (response?.isSuccess) {
          notification.success({ message: 'Awaiting workflow is rejected' });
          updateAwaitingList();
          updateIsApproveStepGrid();
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onAcceptAwaiting = (event, id) => {
    setLoading(true);
    event.stopPropagation();
    const params = {
      wfImpStepId: id,
      isApprove: true,
    };
    api
      .sendPost('/api/workflow/accept-waiting-for-approve', params)
      .then((response) => {
        if (response.isSuccess) {
          notification.success({ message: 'Awaiting workflow is accepted.' });
          updateAwaitingList();
          updateIsApproveStepGrid();
        } else {
          notification.error({ message: response?.message });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  // const showMoreQaSpecWorkflowGrid = () => {
  //   const qaSpecPath = '/qa-spec-workflows';
  //   forwardTo(qaSpecPath);
  //   setVisible(false);
  //   updateSearchText(dispatch, '', qaSpecPath);
  // };

  const workflowList = () => {
    return (
      <Spin spinning={loading}>
        <Row style={{ maxHeight: '600px' }} className='scroller scoller--y'>
          {isDisplayBell && (
            <Col
              // span={8}
              span={12}
            >
              <Title level={5} className='header-task__popover-title'>
                Awaiting List
              </Title>
              <Divider style={{ margin: 0 }} />
              <List
                itemLayout='horizontal'
                dataSource={awaitingList}
                renderItem={(item) => (
                  <List.Item
                    className='header-task__popover-item'
                    extra={
                      <div>
                        <Button
                          onClick={(event) => {
                            onAcceptAwaiting(event, item?.id);
                          }}
                          style={{ borderRadius: '4px' }}
                          type='primary'
                          className='header-task__popover-item--accept-button'
                        >
                          Accept
                        </Button>
                        <Button
                          style={{ borderRadius: '4px' }}
                          onClick={(event) => {
                            onRejectAwaiting(event, item?.id);
                          }}
                          danger
                        >
                          Reject
                        </Button>
                      </div>
                    }
                  >
                    <List.Item.Meta
                      title={item?.name}
                      description={
                        item?.description
                          ? item?.description
                          : 'Workflow is waiting for accept.'
                      }
                    />
                  </List.Item>
                )}
              />
            </Col>
          )}

          <Col
            span={
              isDisplayBell
                ? TO_DO_DISPLAY_BELL_COLUMN
                : TO_DO_NOT_DISPLAY_BELL_COLUMN
            }
            // span={12}
          >
            <Title
              level={5}
              className={classnames({
                'header-task__popover-title': true,
                'header-task__popover-title--left': isDisplayBell,
                'header-task__popover-title--center': !isDisplayBell,
              })}
            >
              To do List
            </Title>
            <Divider style={{ margin: 0 }} />
            <List
              itemLayout='horizontal'
              dataSource={workflowsNotification}
              renderItem={(item) => (
                <List.Item
                  className='header-task__popover-item'
                  extra={
                    item?.status === 'Accepted' ? (
                      <>
                        <Button
                          style={{ borderRadius: '4px' }}
                          onClick={(event) => {
                            onClickViewItem(item);
                          }}
                          type='primary'
                          className='header-task__popover-item--view-button'
                        >
                          View
                        </Button>
                      </>
                    ) : (
                      <div>
                        <Button
                          onClick={(event) => {
                            onAcceptTodo(event, item?.id);
                          }}
                          style={{ borderRadius: '4px' }}
                          type='primary'
                          className='header-task__popover-item--accept-button'
                        >
                          Accept
                        </Button>
                        <Button
                          style={{ borderRadius: '4px' }}
                          onClick={(event) => {
                            onRejectTodo(event, item?.id);
                          }}
                          danger
                        >
                          Reject
                        </Button>
                      </div>
                    )
                  }
                >
                  <List.Item.Meta
                    title={item?.name}
                    description={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className={classnames(
                            'header-task__popover-item--status-dot',
                            {
                              'accepted-dot': item?.status === 'Accepted',
                              'waiting-dot': item?.status === 'Waiting',
                            }
                          )}
                        ></div>
                        {item?.status}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
          {/* <Col flex={1}>
            <QaSpecWorkflowTodoList setVisible={setVisible} visible={visible} />
          </Col> */}
        </Row>
        <Row>
          <Col
            // span={
            //   isDisplayBell
            //     ? SEE_MORE_DISPLAY_BELL_COLUMN
            //     : SEE_MORE_NOT_DISPLAY_BELL_COLUMN
            // }
            span={24}
          >
            <div
              className='header-task__popover-see-more'
              onClick={() => onClickSeemore()}
            >
              <div className='header-task__popover-see-more--item'>
                See More
              </div>
            </div>
          </Col>

          <>
            {/* <Col flex={1}>
              <div
                className={classnames({
                  'header-task__popover-see-more': true,
                  'header-task__popover-see-more--disabled':
                    !hasPermissionManageQaSpecWorkflow ||
                    qaSpecWorkflowTodoList?.length === 0,
                })}
                onClick={showMoreQaSpecWorkflowGrid}
              >
                <div className='header-task__popover-see-more--item'>
                  See More
                </div>
              </div>
            </Col> */}
          </>
        </Row>
      </Spin>
    );
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const renderTotalCount = () => {
    // const workflowCount = qaSpecWorkflowTodoList?.length ?? 0;
    // return parseInt(count) + parseInt(workflowCount);
    return parseInt(count);
  };

  const totalCount = renderTotalCount();

  return (
    <div className='header-task'>
      <Popover
        title={false}
        content={workflowList}
        // overlayClassName={classnames('header-task__popover', {
        //   'width-900': !isDisplayBell,
        //   'width-1160': isDisplayBell,
        // })}
        overlayClassName={classnames('header-task__popover', {
          'width-500': !isDisplayBell,
          'width-900': isDisplayBell,
        })}
        placement='bottomRight'
        trigger='click'
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Action
          onClick={() => setVisible(true)}
          count={totalCount}
          offset={offset}
          icon={BellOutlined}
          iconStyle={iconStyle}
        />
      </Popover>
    </div>
  );
};

export default Task;
