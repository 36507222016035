import React from 'react';

import { Row, Col, Typography, Divider } from 'antd';

const { Text } = Typography;

const SectionHeader = (props) => {
  const { title, children } = props;

  return (
    <div className='section-header'>
      <Row wrap={false} style={{ minWidth: 0 }}>
        <Col flex={1}>
          <Text style={{ fontWeight: 700 }}>{title}</Text>
        </Col>
        <Col flex={0}>{children}</Col>
      </Row>
      <Divider style={{ margin: 4 }} />
    </div>
  );
};

export default SectionHeader;
