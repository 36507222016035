import { EDIT_TYPE, MOVE_PERCENT_STEP } from '../constants';

/**
 * ! get page editor image handlers
 * @param {*} componentHandlers
 * @param {*} detailShowTargetHook
 * @param {*} editTypeHook
 * @param {*} changeFileTargetHook
 * @returns
 */
const useGetImageHandlers = (
  componentHandlers,
  detailShowTargetHook,
  editTypeHook,
  changeFileTargetHook
) => {
  const { onChangeComponentValue } = componentHandlers;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const [editType, setEditType] = editTypeHook;
  const [changeFileTarget, setChangeFileTarget] = changeFileTargetHook;

  const imageHandlers = {
    moveLeft: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'left',
        mapValue: (prev) => prev?.left - MOVE_PERCENT_STEP,
      });
    },

    moveRight: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'left',
        mapValue: (prev) => prev?.left + MOVE_PERCENT_STEP,
      });
    },

    moveUp: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'top',
        mapValue: (prev) => prev?.top - MOVE_PERCENT_STEP,
      });
    },

    moveDown: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'top',
        mapValue: (prev) => prev?.top + MOVE_PERCENT_STEP,
      });
    },

    setRadius: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'radius',
        value,
      });
    },
    setBorderWidth: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'borderWidth',
        value,
      });
    },
    setBorderColor: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'borderColor',
        value,
      });
    },

    handleToggleMedia: () => {
      setEditType(EDIT_TYPE.UPLOAD);
      setChangeFileTarget(detailShowTarget);
    },
  };

  return imageHandlers;
};

/**
 * ! get youtube component handlers
 * @param {*} componentHandlers
 * @param {*} detailShowTargetHook
 * @param {*} editTypeHook
 * @param {*} changeFileTargetHook
 * @returns
 */
const useGetYoutubeHandlers = (
  componentHandlers,
  detailShowTargetHook,
  editTypeHook,
  changeFileTargetHook
) => {
  const { onChangeComponentValue } = componentHandlers;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const [editType, setEditType] = editTypeHook;
  const [changeFileTarget, setChangeFileTarget] = changeFileTargetHook;

  const youtubeHandlers = {
    moveLeft: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'left',
        mapValue: (prev) => prev?.left - MOVE_PERCENT_STEP,
      });
    },

    moveRight: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'left',
        mapValue: (prev) => prev?.left + MOVE_PERCENT_STEP,
      });
    },

    moveUp: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'top',
        mapValue: (prev) => prev?.top - MOVE_PERCENT_STEP,
      });
    },

    moveDown: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'top',
        mapValue: (prev) => prev?.top + MOVE_PERCENT_STEP,
      });
    },

    setRadius: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'radius',
        value,
      });
    },
    setBorderWidth: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'borderWidth',
        value,
      });
    },
    setBorderColor: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'borderColor',
        value,
      });
    },

    setSrc: (e) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'src',
        value: e.target.value,
      });
    },
  };

  return youtubeHandlers;
};

/**
 * ! get page editor text handlers
 * @param {*} componentHandlers
 * @param {*} detailShowTargetHook
 * @param {*} editTypeHook
 * @param {*} changeFileTargetHook
 * @returns
 */
const useGetTextHandlers = (componentHandlers, detailShowTargetHook) => {
  const { onChangeComponentValue } = componentHandlers;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;

  const textHandlers = {
    moveLeft: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textLeft',
        mapValue: (prev) => prev?.textLeft - MOVE_PERCENT_STEP,
      });
    },

    moveRight: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textLeft',
        mapValue: (prev) => prev?.textLeft + MOVE_PERCENT_STEP,
      });
    },

    moveUp: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textTop',
        mapValue: (prev) => prev?.textTop - MOVE_PERCENT_STEP,
      });
    },

    moveDown: () => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textTop',
        mapValue: (prev) => prev?.textTop + MOVE_PERCENT_STEP,
      });
    },

    setFontSize: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textSize',
        value,
      });
    },

    setTextFont: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textFont',
        value,
      });
    },

    setTextWeight: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textWeight',
        mapValue: (prev) => {
          let newValue;
          if (value < 100 || (value >= 100 && value % 50 === 0)) {
            newValue = value;
          } else if (value % 50 !== 0) {
            const refValue = Math.floor(value / 100) * 100;
            const mid = refValue + 50;
            const max = refValue + 100;
            const range = [refValue, mid, max];
            const distance = range.map((ref) => Math.abs(value - ref));
            const minDistance = Math.min(...distance);
            const foundedMinIndex = distance.findIndex(
              (dis) => dis === minDistance
            );
            newValue = range[foundedMinIndex];
          }
          return newValue;
        },
      });
    },

    setTextColor: (color) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textColor',
        value: color,
      });
    },

    setTextPadding: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textPadding',
        value,
      });
    },

    setRadius: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textRadius',
        value,
      });
    },

    setBorderWidth: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textBorderWidth',
        value,
      });
    },
    setBorderColor: (value) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textBorderColor',
        value,
      });
    },
    setBackgroundColor: (color) => {
      onChangeComponentValue({
        key: detailShowTarget,
        fieldName: 'textBackground',
        value: color,
      });
    },
  };
  return textHandlers;
};

const useGetBackgroundHandlers = (
  backgroundKey,
  componentHandlers,
  detailShowTargetHook,
  editTypeHook,
  changeFileTargetHook
) => {
  const { onChangeComponentValue } = componentHandlers;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const [editType, setEditType] = editTypeHook;
  const [changeFileTarget, setChangeFileTarget] = changeFileTargetHook;

  const backgroundHandlers = {
    zoomIn: () => {
      onChangeComponentValue({
        key: backgroundKey,
        fieldName: 'scale',
        mapValue: (prev) => prev?.scale + 0.05,
      });
    },
    zoomOut: () => {
      onChangeComponentValue({
        key: backgroundKey,
        fieldName: 'scale',
        mapValue: (prev) =>
          prev?.scale <= 1.05 ? prev?.scale : prev?.scale - 0.05,
      });
    },
    moveRight: () => {
      onChangeComponentValue({
        key: backgroundKey,
        fieldName: 'left',
        mapValue: (prev) => {
          return prev?.left === 100
            ? prev?.left
            : prev?.left + MOVE_PERCENT_STEP;
        },
      });
    },
    moveLeft: () => {
      onChangeComponentValue({
        key: backgroundKey,
        fieldName: 'left',
        mapValue: (prev) => {
          return prev?.left === 0 ? prev?.left : prev?.left - MOVE_PERCENT_STEP;
        },
      });
    },
    moveDown: () => {
      onChangeComponentValue({
        key: backgroundKey,
        fieldName: 'top',
        mapValue: (prev) => {
          return prev?.top === 100 ? prev?.top : prev?.top + MOVE_PERCENT_STEP;
        },
      });
    },
    moveUp: () => {
      onChangeComponentValue({
        key: backgroundKey,
        fieldName: 'top',
        mapValue: (prev) => {
          return prev?.top === 0 ? prev?.top : prev?.top - MOVE_PERCENT_STEP;
        },
      });
    },
    handleToggleMedia: () => {
      setEditType(EDIT_TYPE.UPLOAD);
      setChangeFileTarget(backgroundKey);
    },
  };

  return backgroundHandlers;
};

export {
  useGetImageHandlers,
  useGetTextHandlers,
  useGetBackgroundHandlers,
  useGetYoutubeHandlers,
};
