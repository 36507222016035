import React, { useState, useContext, forwardRef, useRef } from 'react';

import { Form } from 'antd';
import {
  PageEditorDragText,
  PageEditorDragImage,
  PageEditorDragYoutube,
  PageEditorTool,
  PageEditorBackground,
  PageEditorForm,
  PageEditorInner,
} from './components';
import { WithAbsoluteContainer } from 'common/components';
import { useClickOuterSelectedDrag } from 'common/components/drapDropWrap/drag-drop-pos';
import { DragContext } from 'common/components/drapDropWrap/drag-drop-pos/context/DragContext';

import {} from './hooks';

import {
  COMPONENT_TYPE,
  INIT_PAGE_EDITOR_DATA,
  EDIT_TYPE,
  EDIT_TYPE_DEFAULT,
} from './constants';
import {
  useChangeDetailShowWhenSelectOther,
  useSetInitPageData,
  useGetComponentByType,
  useChangeComponentLayerToTopWhenDragging,
  usePageEditorInstance,
} from './hooks';
import { useGetComponetEditorHandlers } from './hooks/pageEditorHooks';
import { useStatePrevious } from 'hooks/usePrevious';

import * as _ from 'lodash';

import './PageEditor.less';

const PageEditor = forwardRef((props, ref) => {
  const {
    wrapKey = '',
    isEdit = true,
    initPageData,
    formComponent,
    isContainerFull = true,
    containerSize,
    scale,
  } = props;

  const pageDataHook = useStatePrevious(INIT_PAGE_EDITOR_DATA);
  const [pageData, setPageData] = pageDataHook;

  const editTypeHook = useState(EDIT_TYPE_DEFAULT);
  const [editType, setEditType] = editTypeHook;

  const changeFileTargetHook = useState(null);
  const detailShowTargetHook = useState(null);

  const refPageEditor = useRef();

  const [pageEditorFormInstance] = Form.useForm();

  const selectedHook = useClickOuterSelectedDrag();

  const componentHandlers = useGetComponetEditorHandlers(
    pageDataHook,
    selectedHook,
    detailShowTargetHook,
    wrapKey,
    scale
  );
  const { onChangeComponentValue } = componentHandlers;

  useChangeDetailShowWhenSelectOther(
    selectedHook,
    detailShowTargetHook,
    pageDataHook,
    editTypeHook
  );

  useChangeComponentLayerToTopWhenDragging(wrapKey);
  useSetInitPageData(initPageData, pageDataHook);

  const getPageData = () => pageData;
  const handleCloseForm = () => setEditType(EDIT_TYPE.DEFAULT);

  const isDragging = useContext(DragContext);

  const componentTextData = useGetComponentByType(
    pageData,
    COMPONENT_TYPE.TEXT
  );
  const componentBackgroundData = useGetComponentByType(
    pageData,
    COMPONENT_TYPE.BACKGROUND
  );
  const componentImageData = useGetComponentByType(
    pageData,
    COMPONENT_TYPE.IMAGE
  );
  const componentYoutubeData = useGetComponentByType(
    pageData,
    COMPONENT_TYPE.YOUTUBE
  );

  const isHaveBackground = componentBackgroundData?.components?.length > 0;

  usePageEditorInstance(ref, { setPageData, getPageData, handleCloseForm });

  const centerKey = `${wrapKey}_page-editor-center`;
  const wrapId = `${wrapKey}_page-editor-wrap`;

  const isFormOpen = editType === EDIT_TYPE.FORM;

  return (
    <div ref={refPageEditor} className='page-editor'>
      <PageEditorInner
        isContainerFull={isContainerFull}
        containerSize={containerSize}
        isEdit={isEdit}
        wrapId={wrapId}
      >
        {componentImageData?.components?.map((componentId) => {
          const componentData =
            componentImageData?.componentDetails?.[componentId];

          return (
            <PageEditorDragImage
              wrapId={wrapId}
              key={componentId}
              centerKey={centerKey}
              componentKey={componentId}
              componentData={componentData}
              componentHandlers={componentHandlers}
              selectedHook={selectedHook}
              editTypeHook={editTypeHook}
              detailShowTargetHook={detailShowTargetHook}
              changeFileTargetHook={changeFileTargetHook}
              isEdit={isEdit}
              scale={scale}
            />
          );
        })}
        {componentTextData?.components?.map((componentId) => {
          const componentData =
            componentTextData?.componentDetails?.[componentId];

          return (
            <PageEditorDragText
              wrapId={wrapId}
              key={componentId}
              componentKey={componentId}
              componentData={componentData}
              componentHandlers={componentHandlers}
              selectedHook={selectedHook}
              editTypeHook={editTypeHook}
              detailShowTargetHook={detailShowTargetHook}
              isEdit={isEdit}
            />
          );
        })}
        {componentYoutubeData?.components?.map((componentId) => {
          const componentData =
            componentYoutubeData?.componentDetails?.[componentId];

          return (
            <PageEditorDragYoutube
              wrapId={wrapId}
              centerKey={centerKey}
              key={componentId}
              componentKey={componentId}
              componentData={componentData}
              componentHandlers={componentHandlers}
              selectedHook={selectedHook}
              editTypeHook={editTypeHook}
              detailShowTargetHook={detailShowTargetHook}
              isEdit={isEdit}
            />
          );
        })}
      </PageEditorInner>
      <PageEditorBackground
        backgroundConfig={
          componentBackgroundData?.componentDetails?.[
            componentBackgroundData?.components[0]
          ]
        }
        wrapKey={wrapKey}
        onChangeComponentValue={onChangeComponentValue}
      />

      {isEdit && (
        <WithAbsoluteContainer>
          <PageEditorTool
            wrapId={wrapId}
            wrapKey={wrapKey}
            selectedHook={selectedHook}
            editTypeHook={editTypeHook}
            onChangeComponentValue={onChangeComponentValue}
            changeFileTargetHook={changeFileTargetHook}
            detailShowTargetHook={detailShowTargetHook}
            pageData={pageData}
            componentHandlers={componentHandlers}
            isDragging={isDragging}
            isHaveBackground={isHaveBackground}
            scale={scale}
          />
          <PageEditorForm
            isOpen={isFormOpen}
            handleCloseForm={handleCloseForm}
            formComponent={formComponent}
            formInstance={pageEditorFormInstance}
          />
        </WithAbsoluteContainer>
      )}
    </div>
  );
});

export default PageEditor;
