import React from 'react';
import { Typography } from 'antd';
import { useIntl } from 'react-intl';

import SupplierProductGrid from './SupplierProductGrid';
import ProductItemManagementSection from './ProductItemManagementSection';
import SupplierProductListAction from './SupplierProductListAction';
import SupplierPermission from './SupplierPermission';
import Messages from 'i18n/messages/supplier-manager';
const { Text } = Typography;

const SupplierManagerSettingSection = (props) => {
  const {
    brandData,
    productData,
    supplierPermissions,
    selectedEntityLevel,
    isProduct,
    isProductDetail,
    handleAddEntityData,
    handleRemoveEntityData,
    handleUpdateSelectedLevel,
    handleUpdateSupplierPermission,
    handleResetAll,
  } = props;

  const intl = useIntl();

  return (
    <>
      <ProductItemManagementSection>
        <SupplierPermission
          supplierPermissions={supplierPermissions}
          handleUpdateSupplierPermission={handleUpdateSupplierPermission}
        />
        {!isProductDetail && (
          <div className='supplier-management-modal__select-product'>
            <SupplierProductListAction
              brandData={brandData}
              productData={productData}
              isProduct={isProduct}
              selectedEntityLevel={selectedEntityLevel}
              handleAddEntityData={handleAddEntityData}
              handleRemoveEntityData={handleRemoveEntityData}
              handleResetAll={handleResetAll}
            />

            <div className='supplier-management-modal__select-product-list'>
              <Text strong level={5} style={{ padding: '8px 0px' }}>
                {intl.formatMessage(
                  Messages.selectEntityLabel.productSelectedTitle
                )}
              </Text>
              <SupplierProductGrid
                productData={productData}
                selectedEntityLevel={selectedEntityLevel}
                handleUpdateSelectedLevel={handleUpdateSelectedLevel}
              />
            </div>
          </div>
        )}
      </ProductItemManagementSection>
    </>
  );
};

export default SupplierManagerSettingSection;
