import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';

import { Row, Col, Divider } from 'antd';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import { StyledModal } from 'common/components';
import SupplierGrid from './SupplierGrid';
import EditingSupplierItem from './EditingSupplierItem';
import SupplierManagerHintMessage from './SupplierManagerHintMessage';
import SupplierManagerSettingSection from './SupplierManagerSettingSection';

import {
  useSupplierManager,
  useGetSupplierDetail,
} from '../hooks/useSupplierManager';
import { useGetQaSpecDataHeader } from 'pages/qa-spec/query';
import { useGetQaSpecTodoList } from 'pages/qa-spec-workflow/hook';

import { apiHandler } from 'utils/api';
import {
  assignSupplier,
  assignSupplierPIMAcTion,
  editSupplier,
} from 'services/supplier-manager';

import Messages from 'i18n/messages/supplier-manager';

import { qaToggleReloadDocumentCertificateTab } from 'pages/qa-spec/controllers/actions';

import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { useDispatchReloadPage } from 'hooks/useReloadPage';

import { useGetProductFullView } from 'hooks';

import './SupplierManager.less';
import { useGetProductVersionList } from 'pages/product-history/hook';
const SupplierManagerModal = (props) => {
  const intl = useIntl();
  const {
    visible,
    setVisible,
    isEdit,
    memberSupplier,
    getSupplierList,
    isPIMAction,
    title,
    productSelected,
    isProduct,
    isProductDetail,
    isQaSpecPath,
    isWorkflowModal = false,
    productId,
  } = props;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const dispatch = useDispatch();

  const {
    state,
    memberId,
    handleAddEntityData,
    handleRemoveEntityData,
    handleUpdateSupplierData,
    handleUpdateSelectedLevel,
    handleUpdateSelectedItems,
    handleUpdateImpersonateLevel,
    handleUpdateSupplierPermission,
    handleResetDataProduct,
  } = useSupplierManager();

  const {
    productData,
    selectedSupplier,
    impersonateLevel,
    supplierPermissions,
    selectedEntityLevel,
  } = state;

  const { supplierDetail } = useGetSupplierDetail(
    memberSupplier?.shareId,
    isEdit
  );

  const { refetchQaSpecDataHeader } = useGetQaSpecDataHeader({
    id: productId,
    isEnabled: false,
  });

  const { handleRefetchProductFullView } = useGetProductFullView({
    productId: productId,
    enabled: false,
  });

  const { refetchQaSpecWorkflowTodoList } = useGetQaSpecTodoList();

  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const { handleRefetchProductVersioning } = useGetProductVersionList({
    enabled: false,
  });

  const reloadPage = useDispatchReloadPage();

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const brokerState = isEdit ? 'edit' : 'assign';

    const params = {
      ...(isEdit && { memberId }),
      permissions: supplierPermissions || [],
      memberShareOutLink: productData.map((productItem) => ({
        id: productItem?.id,
        shareType: 'Product',
      })),
    };

    const messageSuccessType = isWorkflowModal
      ? Messages.initiateQASpecificationWorkflow
      : Messages.successSupplierMessage[brokerState];

    const successMessage = intl.formatMessage(messageSuccessType);
    const errorMessage = intl.formatMessage(
      Messages.errorSupplierMessage[brokerState]
    );

    const apiService = {
      assign: {
        params: { ...params, supplierId: selectedSupplier?.id },
        service: isPIMAction ? assignSupplierPIMAcTion : assignSupplier,
      },
      edit: {
        params: { ...params, supplierId: memberSupplier?.supplierId },
        service: editSupplier,
      },
    };

    apiHandler({
      params: apiService[brokerState].params,
      service: apiService[brokerState].service,
      successMessage,
      errorMessage,
      successCallback: onSuccessCallback,
      onFinally: onFinally,
    });
  };

  const onSuccessCallback = () => {
    setVisible(false);
    getSupplierList && getSupplierList();
    if (isProductDetail || isQaSpecPath) {
      refetchQaSpecDataHeader();
      refetchQaSpecWorkflowTodoList();
      dispatch(qaToggleReloadDocumentCertificateTab());
      handleRefetchProductFullView();
    }

    if (selectedItemDetailList?.length > 0) reloadPage();

    dispatch(actionsGridView.deleteItemsSelection());
    handleRefetchProductVersioning();
  };

  const onFinally = () => {
    setLoadingSubmit(false);
  };

  useEffect(() => {
    if (supplierDetail && isEdit) handleUpdateSupplierData(supplierDetail);
    if (isProduct) {
      handleResetAll(productSelected);
    }
  }, [JSON.stringify(supplierDetail), isProduct]);

  const handleResetAll = () => {
    handleResetDataProduct(productSelected);
  };

  const settingSectionProps = {
    productData,
    supplierPermissions,
    impersonateLevel,
    selectedEntityLevel,
    isProduct,
    isProductDetail,
    handleAddEntityData,
    handleRemoveEntityData,
    handleUpdateSelectedLevel,
    handleUpdateImpersonateLevel,
    handleUpdateSupplierPermission,
    handleResetAll,
  };

  const disableSubmitButton =
    (!isEdit && !selectedSupplier.id) || productData?.length === 0;

  return (
    <StyledModal
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => handleSubmit()}
      okText='Submit'
      okButtonProps={{
        disabled: disableSubmitButton,
        loading: loadingSubmit,
      }}
      visible={visible}
      destroyOnClose
      width={isEdit ? 950 : 1220}
      wrapClassName='supplier-management-modal'
      title={
        title ? title : intl.formatMessage(Messages.assignSupplierModalTitle)
      }
      bodyStyle={{ height: '80vh', overflow: 'hidden', padding: 10 }}
      maskClosable={false}
    >
      <Row style={{ height: '100%' }} wrap={false}>
        <Col flex={1}>
          {isEdit ? (
            <Row justify='center'>
              <EditingSupplierItem memberSupplier={memberSupplier} />
            </Row>
          ) : (
            <SupplierGrid
              isPIMAction={isPIMAction}
              selectedSupplier={selectedSupplier}
              handleUpdateSelectedItems={handleUpdateSelectedItems}
            />
          )}
        </Col>

        <Divider type='vertical' style={{ height: '100%' }} />

        <Col flex={'500px'}>
          {selectedSupplier?.id || isEdit ? (
            <SupplierManagerSettingSection {...settingSectionProps} />
          ) : (
            <SupplierManagerHintMessage>
              {intl.formatMessage(Messages.selectSupplierHint)}
            </SupplierManagerHintMessage>
          )}
        </Col>
      </Row>
    </StyledModal>
  );
};

export default SupplierManagerModal;
