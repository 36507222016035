import React from 'react';

import { AssetThumbnail } from 'pages/branded-assets/components';

import { Col, Button } from 'antd';

import { CloseOutlined } from '@ant-design/icons';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './SelectedAssetList.less';

const SelectedAssetList = (props) => {
  const { assetList, handleRemoveActiveAsset } = props;

  return (
    <div className='selected-asset-list scroller' style={{ height: '100%' }}>
      <TransitionGroup component='div'>
        {assetList &&
          assetList.length > 0 &&
          assetList.map((item, index) => {
            item = { ...item, thumbnail: item?.thumb, isOwner: true };

            return (
              <CSSTransition
                key={item?.id}
                timeout={500}
                classNames='selected-asset-list__ani'
              >
                <div className='selected-asset-list__item'>
                  <AssetThumbnail
                    imageThumbnail={item?.thumb || item?.thumbnail}
                    dataDetail={item}
                    isShareBtn
                    pathname='/asset'
                  />
                  <Button
                    className='selected-asset-list__item-btn-delete'
                    shape='circle'
                    danger
                    icon={<CloseOutlined />}
                    type='primary'
                    size='small'
                    onClick={() => handleRemoveActiveAsset(item)}
                  />
                </div>
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </div>
  );
};

export default SelectedAssetList;
