import {
  useCheckPermissions,
  useCheckPermissionOR,
} from 'hooks/useCheckPermissions';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

export const useCheckCreditCardPermission = () => {
  const checkPermissionOr = useCheckPermissionOR();

  const canAccessBilling = checkPermissionOr([
    [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.ALL],
    [ABILITY_ACTION.PAY, ABILITY_SUBJECT.CREDIT_CARD],
    [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.CREDIT_CARD],
    [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
  ]);

  const canActionCard = checkPermissionOr([
    [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.ALL],
    [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.CREDIT_CARD],
    [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
  ]);

  const canEditPayment = checkPermissionOr([
    [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.ALL],
    [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
  ]);

  const canPayViaCreditCard = checkPermissionOr([
    [ABILITY_ACTION.PAY, ABILITY_SUBJECT.CREDIT_CARD],
  ]);

  return {
    canAccessBilling,
    canActionCard,
    canEditPayment,
    canPayViaCreditCard,
  };
};
