import React, { useState, forwardRef, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, Input } from 'antd';

import {
  FormAddButton,
  FormDeleteButton,
  FullWidthSpace,
  Form,
} from 'common/components';
import { EditContainer } from 'pages/qa-spec/components';

import QaFormulaActiveIngredientGrid from './QaFormulaActiveIngredientGrid';

import { useTabExpand } from 'hooks/useTabExpandProduct';
import { useFormulaActiveIngredientHandler } from 'pages/qa-spec/hooks/useFormula';

import { isEqual } from 'lodash';
import {
  SnapshotInputWrapper,
  SnapshotFormItemWrapper,
  FormLabel,
} from 'pages/qa-spec/components/form';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import { formatCompareValue } from 'pages/qa-spec/utils';

const QaFormulaDrugForm = forwardRef((props, ref) => {
  const gridInst = useRef();
  const { id: productId } = useParams();

  const { drugFactData, drugFactSnapshotData, isEdit = false, mode } = props;

  const useSnapshot = useCheckSnapshotForRetailer();

  const [selectedRows, setSelectedRows] = useState([]);
  const editHook = useState(isEdit);

  const { tableStyle } = useTabExpand();

  const { handleAddActiveIngredient, handleDeleteIngredient } =
    useFormulaActiveIngredientHandler(gridInst);

  const getSnapshotFormItemWrapperProps = ({ fieldName, label }) => {
    const snapshotFieldValue = drugFactSnapshotData?.[fieldName];
    const procSnapshotFieldValue = formatCompareValue(snapshotFieldValue);

    return {
      snapshotValue: (
        <span>
          <FormLabel label={label} />:
          <span> &nbsp;{procSnapshotFieldValue}</span>
        </span>
      ),
      checkShowSnapshotFn: ({ value }) => {
        const procValue = isEdit
          ? formatCompareValue(value)
          : formatCompareValue(drugFactData?.[fieldName]);

        return (
          !isEqual(procValue, procSnapshotFieldValue) &&
          useSnapshot &&
          mode !== 'add'
        );
      },
    };
  };

  return (
    <div style={{ padding: '0 5px' }}>
      <EditContainer
        title={'Active Ingredients'}
        editHook={editHook}
        showSaveButton={false}
        showCancelButton={false}
        showEditButton={false}
        buttons={[
          <FormAddButton
            text='Add new ingredient'
            onClick={handleAddActiveIngredient}
          />,
          <FormDeleteButton
            disabled={selectedRows?.length === 0}
            onClick={handleDeleteIngredient}
          />,
        ]}
        productId={productId}
      >
        {(isEdit) => {
          return (
            <FullWidthSpace size={20}>
              <SnapshotFormItemWrapper
                {...getSnapshotFormItemWrapperProps({
                  fieldName: 'drugDosageUnit',
                  label: 'Dosage Unit',
                })}
              >
                {(inputWrapperProps) => (
                  <Form.Item
                    name={'drugDosageUnit'} //* remove name to show value on view mode
                    label='Dosage Unit'
                    style={{ marginBottom: 0 }}
                  >
                    <SnapshotInputWrapper
                      {...inputWrapperProps}
                      popoverProps={{ visible: false }}
                    >
                      {isEdit ? (
                        <Input />
                      ) : (
                        <Input value={drugFactData?.drugDosageUnit} readOnly />
                      )}
                    </SnapshotInputWrapper>
                  </Form.Item>
                )}
              </SnapshotFormItemWrapper>

              <div style={{ ...tableStyle }}>
                <Form.Item name='drugActiveIngredients' hidden />
                <QaFormulaActiveIngredientGrid
                  ref={ref}
                  gridInst={gridInst}
                  isEdit={isEdit}
                  activeIngredientList={drugFactData?.drugActiveIngredients}
                  snapShotActiveIngredientList={
                    drugFactSnapshotData?.drugActiveIngredients
                  }
                  setSelectedRows={setSelectedRows}
                  mode={mode}
                />
              </div>

              <SnapshotFormItemWrapper
                {...getSnapshotFormItemWrapperProps({
                  fieldName: 'drugInactiveIngredients',
                  label: 'Inactive Ingredients',
                })}
              >
                {(inputWrapperProps) => (
                  <Form.Item
                    name={'drugInactiveIngredients'}
                    label={<FormLabel label='Inactive Ingredients' />}
                  >
                    <SnapshotInputWrapper
                      {...inputWrapperProps}
                      popoverProps={{ visible: false }}
                    >
                      {isEdit ? (
                        <Input.TextArea
                          autoSize={{ minRows: 5, maxRows: 5 }}
                          showCount
                          maxLength={500}
                        />
                      ) : (
                        <Input.TextArea
                          autoSize={{ minRows: 5, maxRows: 5 }}
                          showCount
                          maxLength={500}
                          readOnly
                          value={drugFactData?.drugInactiveIngredients}
                        />
                      )}
                    </SnapshotInputWrapper>
                  </Form.Item>
                )}
              </SnapshotFormItemWrapper>
            </FullWidthSpace>
          );
        }}
      </EditContainer>
    </div>
  );
});

export default QaFormulaDrugForm;
