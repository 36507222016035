import { put, call } from 'redux-saga/effects';
import * as types from './constants';
import * as actions from './actions';
import { takeLatest } from 'redux-saga/effects';

import {
  getBrandingService,
  updateGetNewMember,
  updateGetNewProduct,
  updateGetNewAsset,
} from 'services/preload';

export function* brandingSaga() {
  try {
    let response = yield call(getBrandingService) || {};

    const { isSuccess, data, message } = response;

    if (isSuccess) {
      yield put(actions.getBrandingSuccess(data));
    } else {
      yield put(
        actions.getBrandingFail(message || 'Failed to get navigation data')
      );
    }
  } catch (error) {
    if (error) {
      yield put(actions.getBrandingFail('Server error'));
      return;
    }
  }
}

export function* brandingNoLoadingSaga() {
  try {
    let response = yield call(getBrandingService) || {};

    const { isSuccess, data, message } = response;

    if (isSuccess) {
      yield put(actions.getBrandingNoLoadingSuccess(data));
    } else {
      yield put(
        actions.getBrandingNoLoadingFail(
          message || 'Failed to get navigation data'
        )
      );
    }
  } catch (error) {
    if (error) {
      yield put(actions.getBrandingNoLoadingFail('Server error'));
      return;
    }
  }
}

export function* updateLastGetNewMember() {
  try {
    let response = yield call(updateGetNewMember);
    if (!response && !response.isSuccess) {
      yield put(actions.updateLastGetNewMemberError(response.message));
    } else {
      yield put(actions.updateLastGetNewMemberSuccess());
      yield put(actions.getBrandingNoLoading());
    }
  } catch (error) {
    if (error?.status === 500) {
      yield put(actions.updateLastGetNewMemberError(error?.statusText));
      return;
    }
    if (error) {
      yield put(actions.updateLastGetNewMemberError('server error'));
      return;
    }
  }
}

export function* updateLastGetNewAsset() {
  try {
    let response = yield call(updateGetNewAsset);
    if (!response && !response.isSuccess) {
      yield put(actions.updateLastGetNewAssetsError(response.message));
    } else {
      yield put(actions.updateLastGetNewAssetsSuccess());
      yield put(actions.getBrandingNoLoading());
    }
  } catch (error) {
    if (error?.status === 500) {
      yield put(actions.updateLastGetNewAssetsError(error?.statusText));
      return;
    }
    if (error) {
      yield put(actions.updateLastGetNewAssetsError('server error'));
      return;
    }
  }
}

export function* updateLatestGetNewProduct() {
  try {
    let response = yield call(updateGetNewProduct);
    if (response.isSuccess) {
      yield put(actions.updateLastGetNewProductSuccess());
      yield put(actions.getBrandingNoLoading());
    } else {
      yield put(actions.updateLastGetNewProductError(response.message));
    }
  } catch (error) {
    yield put(actions.updateLastGetNewProductError(error));
  }
}

export function* updateLastGetUnmatchMember() {
  try {
    let response = yield call(updateGetNewMember);
    if (!response && !response.isSuccess) {
      yield put(actions.updateLastGetUnmatchMemberError(response.message));
    } else {
      yield put(actions.updateLastGetUnmatchMemberSuccess());
      yield put(actions.getBrandingNoLoading());
    }
  } catch (error) {
    if (error?.status === 500) {
      yield put(actions.updateLastGetUnmatchMemberError(error?.statusText));
      return;
    }
    if (error) {
      yield put(actions.updateLastGetUnmatchMemberError('server error'));
      return;
    }
  }
}

export function* updateLastGetUnmatchAsset() {
  try {
    let response = yield call(updateGetNewAsset);
    if (!response && !response.isSuccess) {
      yield put(actions.updateLastGetUnmatchAssetsError(response.message));
    } else {
      yield put(actions.updateLastGetUnmatchAssetsSuccess());
      yield put(actions.getBrandingNoLoading());
    }
  } catch (error) {
    if (error?.status === 500) {
      yield put(actions.updateLastGetUnmatchAssetsError(error?.statusText));
      return;
    }
    if (error) {
      yield put(actions.updateLastGetUnmatchAssetsError('server error'));
      return;
    }
  }
}

export default function* watchBranding() {
  yield takeLatest(types.GET_BRANDING, brandingSaga);
  yield takeLatest(types.UPDATE_LAST_GET_NEW_MEMBER, updateLastGetNewMember);
  yield takeLatest(types.GET_BRANDING_NO_LOADING, brandingNoLoadingSaga);
  yield takeLatest(
    types.UPDATE_LAST_GET_NEW_PRODUCT,
    updateLatestGetNewProduct
  );
  yield takeLatest(types.UPDATE_LAST_GET_NEW_ASSET, updateLastGetNewAsset);
  yield takeLatest(
    types.UPDATE_LAST_GET_UNMATCH_MEMBER,
    updateLastGetUnmatchMember
  );
}
