import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import userSelectors from 'redux/user/selectors';

import { dialogFunction } from 'common/components';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import { EVENT } from 'static/Constants';
import { CustomNotification } from 'common/components';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getTicketComment,
  saveTicketComment,
  deleteTicketComment,
} from 'services/ticketingSystem';

export const getKeyCommentList = (id) => {
  return ['ticketing-system/get-comment-list', parseInt(id)];
};

export const useQueryTicketCommentList = ({ id }) => {
  const { isLoading, data } = useQuery({
    queryKey: getKeyCommentList(id),
    queryFn: async () => {
      const { data } = await getTicketComment({ TicketId: id });
      const ticketComments = data ? data?.ticketComments : [];

      return ticketComments;
    },
    onSuccess: () => {},
    onError: () => {
      CustomNotification.error('Something went wrong!');
    },
  });
  return {
    commentList: data || [],
    isLoading,
  };
};

export const useMutationDeleteComment = ({ id }) => {
  const queryClient = useQueryClient();

  const commentDeleteMutation = useMutation({
    mutationFn: deleteTicketComment,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: getKeyCommentList(id),
      });
    },
    onError: () => {
      CustomNotification.error('Delete failed!');
    },
  });

  return {
    commentDeleteMutation,
  };
};

export const useMutationSaveComment = ({ id }) => {
  const queryClient = useQueryClient();

  const saveCommentMutation = useMutation({
    mutationFn: saveTicketComment,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: getKeyCommentList(id),
      });
    },
    onError: () => {
      CustomNotification.error('Save failed!');
    },
  });

  return {
    saveCommentMutation,
  };
};

export const useTicketComment = ({ editorRef, ticketId: id, isAdd } = {}) => {
  const commentInputRef = useRef();

  const [selectedComment, setSelectedComment] = useState(null);

  const { saveCommentMutation } = useMutationSaveComment({ id });

  const { commentList } = useQueryTicketCommentList({ id });
  const { commentDeleteMutation } = useMutationDeleteComment({ id });

  const userId = useSelector(userSelectors.makeSelectUserId());

  const getEditor = () => {
    const editor = editorRef.current?.getEditor();

    return editor;
  };

  const getComment = () => {
    const editor = getEditor();

    if (!editor) return;

    const content = editor.root.innerHTML;
    return content;
  };

  const initComment = (content) => {
    const editor = getEditor();

    if (!editor) return;

    editor.root.innerHTML = content;
  };

  const focusEditor = () => {
    const editor = getEditor();

    if (!editor) return;

    editor.focus();
  };

  const getDeltaComment = (data) => {
    const editor = getEditor();

    if (!editor) return;

    const content = data || editor.root.innerHTML;

    return editor.clipboard.convert(content).ops;
  };

  const saveComment = async () => {
    let inputValue = commentInputRef.current?.getValueEditorInput();

    if (!inputValue) return dispatchSaveCommentDoneEvent();

    const params = {
      ticketId: id,
      comment: inputValue,
    };

    await saveCommentMutation.mutateAsync(params, {
      onSuccess: (result) => {
        if (result?.isSuccess) {
          inputValue = '';
          commentInputRef.current?.resetValue();
        } else {
          CustomNotification.error(result?.message);
        }
      },
    });

    dispatchSaveCommentDoneEvent();
  };

  const dispatchSaveCommentDoneEvent = () => {
    //* this event will call api save ticket attachment in useTicketAttachment
    if (!isAdd && !selectedComment) return;

    const event = new CustomEvent(EVENT.SAVE_TICKET_COMMENT_DONE_EVENT);

    document.dispatchEvent(event);
  };

  const cancelUpdateComment = () => {
    const originalCommentItem = commentList.find(
      (item) => item.id === selectedComment.id
    );

    const currentDeltaComment = getDeltaComment();
    const originalDeltaComment = getDeltaComment(originalCommentItem.comment);

    const isNewChangeComment =
      JSON.stringify(currentDeltaComment) !==
      JSON.stringify(originalDeltaComment);

    if (isNewChangeComment) {
      dialogFunction({
        type: 'warn',
        content: 'Are you sure you want to discard this draft?',
        okText: 'Discard',
        okButtonProps: {
          type: 'danger',
        },
        onOk: () => {
          setSelectedComment(null);
          initComment(originalCommentItem.comment ?? '');
        },
      });
    } else {
      setSelectedComment(null);
    }
  };

  const selectComment = (comment) => {
    setSelectedComment(comment);

    setTimeout(() => {
      focusEditor();
    }, 100);
  };

  const updateSelectedComment = async () => {
    const currentComment = getComment();

    const ticketId = id;

    const params = {
      id: selectedComment?.id,
      ticketId,
      comment: currentComment,
    };
    await saveCommentMutation.mutateAsync(params, {
      onSuccess: (result) => {
        if (result?.isSuccess) {
          setSelectedComment(null);
          initComment(currentComment ?? '');
        } else {
          CustomNotification.error(result?.message);
        }
      },
    });
  };

  const deleteComment = (id) => {
    showDeleteConfirmation(id);
  };

  const showDeleteConfirmation = (id) => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete this comment?',
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => {
        callApiDeleteTicket(id);
      },
    });
  };

  const callApiDeleteTicket = async (id) => {
    await commentDeleteMutation.mutateAsync(
      { id },
      {
        onSuccess: (result) => {
          if (result?.isSuccess) {
            setSelectedComment(null);
          } else {
            CustomNotification.error(result?.message);
          }
        },
      }
    );
  };

  const generateShortName = (name) => {
    if (!name) return;
    const nameParts = name?.trim().split(' ');
    if (nameParts.length === 0) return '';
    if (nameParts.length === 1) return nameParts[0].charAt(0).toUpperCase();
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    );
  };

  useDocumentAttachEvent({
    name: EVENT.SAVE_TICKET_EVENT,
    handler: saveComment,
  });

  return {
    cancelUpdateComment,
    selectComment,
    updateSelectedComment,
    deleteComment,
    userId,
    commentInputRef,
    commentList,
    selectedComment,
    generateShortName,
    initComment,
  };
};
