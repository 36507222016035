import React from 'react';
import { Typography } from 'antd';
import classnames from 'classnames';
import './MarkdownGroupInput.less';

const { Text } = Typography;

const MarkdownGroupInput = (props) => {
  const { children, style, className, title } = props;
  return (
    <div
      className={classnames('markdown-group-input', className)}
      style={style}
    >
      <Text className={'markdown-group-input__title'}>{title && title}</Text>
      {children}
    </div>
  );
};

export default MarkdownGroupInput;
