import { useState, useEffect, useRef } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { isArray, isEmpty } from 'lodash';

import { apiHandler } from 'utils/api';
import { useGetProductFullView } from 'hooks';

import * as qaSpecServices from 'services/qaSpec';

import { PRODUCT_SPEC_DIETARY_CERTIFICATION_FIELDS } from 'pages/qa-spec/constant';

import Messages from 'i18n/messages/qa-spec';
import { useIntl } from 'react-intl';
import { getQaFreshSeafoodTabDataQueryKey } from '../components/qa-fresh-seafood/queries';
import { getQaPackagingTabDataQueryKey } from '../components/qa-packaging/queries';
import { getQaFreshMeatTabDataQueryKey } from '../components/qa-fresh-meat/queries';
import { CustomNotification } from 'common/components';
import { getQaClaimsTabDataQueryKey } from '../components/qa-claims/queries';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';
import { keyGetProductItemModules } from 'pages/product-full-view/shared/hooks';

export const getKeyProductSpec = (productId) => {
  return ['qa-spec', parseInt(productId), 'product-specification'];
};

export const useQueryDataProductSpec = ({ id }) => {
  const { isLoading, data, ...resQuery } = useQuery({
    queryKey: getKeyProductSpec(id),
    queryFn: async () => {
      const { data } = await qaSpecServices.getQaSpecProductSpec({
        ProductId: id,
      });
      return data || {};
    },
  });

  return {
    dataProductSpec: data,
    isLoading,
    ...resQuery,
  };
};

export const useProductSpecInfo = ({
  productId,
  form,
  refetchProductItems,
  isRequiredCountryOfOrigin,
}) => {
  const intl = useIntl();

  const [statusSubmit, setStatusSubmit] = useState('idle');

  const [disabledCertifier, setDisabledCertifier] = useState({});
  const [initialData, setInitialData] = useState({});

  const [countryOfOrigin, setCountryOfOrigin] = useState();
  const [nonfoodIngredientStatement, setNonfoodIngredientStatement] =
    useState();

  const { handleRefetchProductFullView } = useGetProductFullView({ productId });

  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const queryClient = useQueryClient();

  const onValuesChange = (changedValues) => {
    disableCertifier(changedValues);
  };

  const disableCertifier = (value) => {
    const field = Object.keys(value)?.[0];
    if (!field) return;

    const keys = Object.keys(PRODUCT_SPEC_DIETARY_CERTIFICATION_FIELDS);

    if (!keys.includes(field)) return;

    const matchedDisabledKey = PRODUCT_SPEC_DIETARY_CERTIFICATION_FIELDS[field];
    const fieldValue = value[field];

    setDisabledCertifier((prevState) => {
      return {
        ...prevState,
        [matchedDisabledKey]: !fieldValue,
      };
    });
  };

  const getCertificateParams = (formValues) => {
    return Object.keys(disabledCertifier).reduce((accumulator, currentKey) => {
      const isDisabled = disabledCertifier[currentKey];
      const value = formValues[currentKey];

      return {
        ...accumulator,
        [currentKey]: isDisabled ? null : value,
      };
    }, {});
  };

  const onSubmit = async (toggleEditMode, ixoneIgnore) => {
    setStatusSubmit('submitted');

    await form
      .validateFields()
      .then(async (formValues) => {
        if (
          isRequiredCountryOfOrigin &&
          checkIsInvalidCountryOfOrigin(countryOfOrigin)
        ) {
          return;
        }

        setStatusSubmit('submitting');

        const { ...restValue } = formValues;

        const certificateParams = getCertificateParams(formValues);

        let tempCountryOfOrigin;
        if (isArray(countryOfOrigin)) {
          tempCountryOfOrigin = [...countryOfOrigin];

          tempCountryOfOrigin = tempCountryOfOrigin.filter(
            (val) =>
              !(
                isEmpty(val) ||
                (isEmpty(val?.countryCode) &&
                  isEmpty(val?.countrySubdivisionCode))
              )
          );
        }
        let tempNonfoodIngredientStatement;
        if (isArray(nonfoodIngredientStatement)) {
          tempNonfoodIngredientStatement = [...nonfoodIngredientStatement];

          tempNonfoodIngredientStatement =
            tempNonfoodIngredientStatement.filter(
              (val) =>
                !(
                  isEmpty(val) ||
                  (isEmpty(val?.value) &&
                    isEmpty(val?.languageCode) &&
                    isEmpty(val?.codeListVersion) &&
                    isEmpty(val?.formattingPattern) &&
                    isEmpty(val?.sequenceNumber))
                )
            );
        }
        const params = {
          productId,
          countryOfOrigin: tempCountryOfOrigin,
          nonfoodIngredientStatement: tempNonfoodIngredientStatement,
          ...restValue,
          ...certificateParams,
        };

        const isTargetMarketChanged =
          params?.targetMarket !== initialData?.targetMarket;
        const isUsdaGrade = params?.usdaGrade !== initialData?.usdaGrade;

        const successMessage = intl.formatMessage(
          Messages.productSpecSaveInfoSuccess
        );
        const errorMessage = intl.formatMessage(
          Messages.productSpecSaveInfoError
        );

        apiHandler({
          service: qaSpecServices.saveQaSpecProductSpec,
          params,
          successMessage,
          errorMessage,
          successCallback: onSaveSuccess(toggleEditMode),
          onFinally: async () => {
            setStatusSubmit('idle');
            if (isTargetMarketChanged) {
              handleRefetchProductFullView();
            }
            if (isUsdaGrade) {
              queryClient.invalidateQueries({
                queryKey: getQaFreshMeatTabDataQueryKey(productId),
              });
            }
            await handleUpdateIXONEShield(ixoneIgnore);
          },
        });
      })
      .catch((e) => {
        if (e?.errorFields?.length > 0) {
          CustomNotification.error(
            'Please resolve errors in the Product Specification tab.'
          );
        }
      });
  };

  const onSaveSuccess = (toggleEditMode) => () => {
    queryClient.invalidateQueries({
      queryKey: getQaFreshSeafoodTabDataQueryKey(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getKeyProductSpec(productId),
    });
    queryClient.invalidateQueries({
      queryKey: getQaPackagingTabDataQueryKey(productId),
    });

    //* sync to qa claim data
    queryClient.invalidateQueries({
      queryKey: getQaClaimsTabDataQueryKey(productId),
    });
    //* sync to product detail view
    queryClient.invalidateQueries({
      queryKey: keyGetProductItemModules(productId),
    });
    toggleEditMode();
    refetchProductItems();
  };

  const onCancel = () => {
    form.resetFields();
    setStatusSubmit('idle');
    initFormValue(initialData);
  };

  const { dataProductSpec, isLoading } = useQueryDataProductSpec({
    id: productId,
  });

  useEffect(() => {
    if (!dataProductSpec) return;
    initFormValue(dataProductSpec);
    setInitialData(dataProductSpec);
  }, [dataProductSpec]);

  const initFormValue = (productSpecInfo) => {
    const { targetMarketCountryCode, ...restInfo } = productSpecInfo;

    initDisabledCertifier(productSpecInfo);

    form.setFieldsValue({
      targetMarketCountryCode: targetMarketCountryCode
        ? targetMarketCountryCode + ''
        : null,
      ...restInfo,
    });
    setCountryOfOrigin(productSpecInfo?.countryOfOrigin);
    setNonfoodIngredientStatement(productSpecInfo?.nonfoodIngredientStatement);
  };

  const initDisabledCertifier = (initDisabledCertifier) => {
    const { kosherYN, organicYN, halalYN, nonGMOYN, isoyn, brcyn, ifsyn } =
      initDisabledCertifier;
    disableCertifier({ kosherYN });
    disableCertifier({ organicYN });
    disableCertifier({ halalYN });
    disableCertifier({ nonGMOYN });
    disableCertifier({ isoyn });
    disableCertifier({ brcyn });
    disableCertifier({ ifsyn });
  };

  const checkRequiredField = (requiredFields, fieldName) => {
    if (!requiredFields) return false;

    const fieldFullPath =
      'qaSpecification.qaSpecProductSpecification.' + fieldName;

    const isRequired = requiredFields.includes(fieldFullPath);

    return isRequired;
  };

  return {
    loading: statusSubmit === 'submitting' || isLoading,
    isSubmitted: statusSubmit === 'submitted',
    disabledCertifier,
    countryOfOrigin,
    setCountryOfOrigin,
    nonfoodIngredientStatement,
    setNonfoodIngredientStatement,
    checkRequiredField,
    onSubmit,
    onValuesChange,
    onCancel,
    dataProductSpec,
  };
};

/**
 * ? auto focus added field
 */
export const useAutoFocusAddedField = () => {
  const [allowAddedFieldFocus, setAllowAddedFieldFocus] = useState(false);

  const refFocusFieldIdx = useRef();
  const refField = useRef();

  const handleFocusAddedField = () => {
    if (refField.current) {
      refField.current.focus();
      refField.current = null;
      setAllowAddedFieldFocus(false);
    }
  };

  const inputRef = (ref, idx) => {
    if (idx === refFocusFieldIdx.current) {
      refField.current = ref;
      refFocusFieldIdx.current = null;
      setAllowAddedFieldFocus(true);
    }
  };

  const onAddField = (idx) => {
    refFocusFieldIdx.current = idx;
  };

  useEffect(() => {
    if (allowAddedFieldFocus) {
      handleFocusAddedField();
    }
  }, [allowAddedFieldFocus]);

  return { inputRef, onAddField };
};

export const useMutationUploadCertificate = () => {
  const mutationUploadCertificate = useMutation({
    mutationFn: qaSpecServices.linkQaSpecProductSpecCertification,
    onSuccess: async () => {
      //
    },
  });

  return {
    mutationUploadCertificate,
  };
};

export const checkIsInvalidCountryOfOrigin = (countryOfOrigin) => {
  const isEmptyCountryOfOrigin = isEmpty(countryOfOrigin);

  if (isEmptyCountryOfOrigin) return true;

  const isInvalidValuesCountryOfOrigin = countryOfOrigin?.every((country) => {
    const invalidCountryCode = isEmpty(country?.countryCode);

    const invalidCountrySubdivisionCode = isEmpty(
      country?.countrySubdivisionCode
    );

    return invalidCountryCode && invalidCountrySubdivisionCode;
  });

  return isInvalidValuesCountryOfOrigin;
};
