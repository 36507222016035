import _ from 'lodash';

import sortByKey from 'utils/sortByKey';
import { DEFAULT_NUTRIENT_VALUE, NUTRITION_TYPE } from './Constant';

// export const setDefaultValue = (nutritionFactProperties) => {
//   const missingValue = [];
//   DEFAULT_NUTRIENT_VALUE.forEach((typeCode) => {
//     const isMissing = nutritionFactProperties.every(
//       (item) => item?.nutritionTypeCode !== typeCode
//     );
//     if (isMissing) missingValue.push({ nutritionTypeCode: typeCode });
//   });
//   return missingValue.concat(nutritionFactProperties) || [];
// };

export const getNutrientList = (nutritionFactProperties) => {
  const nutrientFormatType = [];
  nutritionFactProperties.forEach((item) => {
    const { nutritionTypeCode } = item;

    if (NUTRITION_TYPE[nutritionTypeCode]) {
      nutrientFormatType.push({
        ...NUTRITION_TYPE[nutritionTypeCode],
        ...item,
      });
    }
  });

  return sortByKey(nutrientFormatType, 'index');
};

export const addMissingDefaultValue = ({ snapshotValue, currentValue }) => {
  const cloneSnapshotValue = _.cloneDeep(snapshotValue);
  const cloneCurrentValue = _.cloneDeep(currentValue);

  DEFAULT_NUTRIENT_VALUE.forEach((nutrientName) => {
    const hasSnapshotValue = snapshotValue?.some(
      (nutrient) => nutrient?.nutritionTypeCode === nutrientName
    );
    const hasCurrentValue = currentValue?.some(
      (nutrient) => nutrient?.nutritionTypeCode === nutrientName
    );

    if (hasSnapshotValue && !hasCurrentValue) {
      cloneCurrentValue?.push({ nutritionTypeCode: nutrientName });
    }

    if (!hasSnapshotValue && hasCurrentValue) {
      cloneSnapshotValue?.push({ nutritionTypeCode: nutrientName });
    }
  });
  return { snapshotValue: cloneSnapshotValue, currentValue: cloneCurrentValue };
};

export const compareSnapshotNutrients = ({ snapshotValue, currentValue }) => {
  const cloneSnapshotValue = _.cloneDeep(currentValue);
  currentValue?.forEach((nutrient, index) => {
    const isEqual = snapshotValue?.some(
      (item) => JSON.stringify(item) === JSON.stringify(nutrient)
    );
    if (!isEqual) {
      cloneSnapshotValue[index] = { ...nutrient, hightLightText: '#e6f7ff' };
    }
  });
  return cloneSnapshotValue;
};

export const compareOriginNutrients = ({
  snapshotValue,
  currentValue,
  fieldName,
}) => {
  const cloneSnapshotValue = _.cloneDeep(snapshotValue);
  snapshotValue?.forEach((nutrient, index) => {
    const currentNutrient = currentValue?.find(
      (item) => item?.[fieldName] === nutrient?.[fieldName]
    );

    if (!!currentNutrient) {
      if (JSON.stringify(currentNutrient) === JSON.stringify(nutrient)) {
        cloneSnapshotValue[index] = { ...nutrient, hightLightText: '' };
      } else {
        cloneSnapshotValue[index] = { ...nutrient, hightLightText: '#e6f7ff' };
      }
    } else {
      cloneSnapshotValue[index] = { ...nutrient, hightLightText: '#ffa39e' };
    }
  });
  return cloneSnapshotValue;
};
export const addSnapshotData = ({
  snapshotValue,
  currentValue,
  isSnapshot,
}) => {
  if (currentValue?.length === 0 && isSnapshot) {
    return snapshotValue?.map((snapshot) => {
      return {
        ...snapshot,
        isDeleted: true,
        snapshotData: snapshot,
      };
    });
  }

  const addSnapshotValue = currentValue?.map((item, index) => {
    return {
      ...item,
      snapshotData: isSnapshot ? snapshotValue?.[index] || {} : null,
    };
  });
  return addSnapshotValue;
};
