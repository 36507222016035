import React from 'react';

import { Row, Col, Space } from 'antd';
import { FormDeleteButton } from 'common/components';

const RemoveUserRoleAction = (props) => {
  const { onClickRemoveCallback, assignedRoles } = props;

  const onClickRemoveBtn = () => {
    onClickRemoveCallback && onClickRemoveCallback();
  };
  return (
    <Row>
      <Col flex={1}></Col>
      <Col flex={0}>
        <Space>
          <FormDeleteButton
            text='Remove'
            disabled={assignedRoles?.length <= 1}
            onClick={onClickRemoveBtn}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default RemoveUserRoleAction;
