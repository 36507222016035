import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReporting = (state) => state.reportingFullView || initialState;

const makeSelectLoading = () =>
  createSelector(selectReporting, (reportingState) => reportingState.loading);
const makeSelectModeView = () =>
  createSelector(selectReporting, (reportingState) => reportingState.modeView);

const makeSelectSelectedResults = () =>
  createSelector(
    selectReporting,
    (reportingState) => reportingState.selectedResults
  );

const makeSelectSetting = () =>
  createSelector(selectReporting, (reportingState) => {
    return reportingState.setting;
  });

const makeSelectKeepSetting = () =>
  createSelector(
    selectReporting,
    (reportingState) => reportingState.keepSetting
  );

const makeSelectResultGridPagination = () =>
  createSelector(
    selectReporting,
    (reportingState) => reportingState.resultGridPagination
  );

const makeSelectKeepResultGridPagination = () =>
  createSelector(
    selectReporting,
    (reportingState) => reportingState.keepResultGridPagination
  );

export {
  selectReporting,
  makeSelectLoading,
  makeSelectModeView,
  makeSelectSelectedResults,
  makeSelectSetting,
  makeSelectKeepSetting,
  makeSelectResultGridPagination,
  makeSelectKeepResultGridPagination,
};
