import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getAplDetail = (params) => {
  return api.sendGet(endpoints.GET_APL_DETAIL, { params: params });
};

export const editAplNote = (params) => {
  return api.sendPost(endpoints.EDIT_APL_NOTE, params);
};

export const addAplComment = (params) => {
  return api.sendPost(endpoints.ADD_APL_COMMENT, params);
};
export const getAplCommentList = (params) => {
  return api.sendPost(endpoints.GET_APL_COMMENT_LIST, params);
};

export const executeMatchingApl = (params) => {
  return api.sendPost(endpoints.EXECUTE_MATCHING_APL, params);
};

export const editAplProductContactService = (params) => {
  return api.sendPost(endpoints.EDIT_APL_PRODUCT_CONTACT, params);
};
