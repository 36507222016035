import React from 'react';
import Messages from 'i18n/messages/maintenance';
import { Divider, Col } from 'antd';
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { RibbonDivider } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';
import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import BackToMarkup from './components/controls/maintenance/markup/BackToMarkup';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const MaintenanceMarkupCreateView = (props) => {
  const { isCreate, handleCloneMarkup, statusClone, handleCancelMarkup } =
    props;

  const isLoading = statusClone === 'loading';
  const disabledSaveAndCancel = isCreate;

  return (
    <>
      <ItemButton>
        <ContainerButton>
          <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.MARKUP}>
            <RibbonButton
              icon={<SaveOutlined />}
              label={Messages.saveMarkup}
              onClick={handleCloneMarkup}
              loading={isLoading}
              disabled={disabledSaveAndCancel}
            />
          </Can>
          <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.MARKUP}>
            <RibbonButton
              icon={<CloseCircleOutlined />}
              label={Messages.cancelMarkup}
              onClick={handleCancelMarkup}
              disabled={disabledSaveAndCancel}
            />
          </Can>
        </ContainerButton>
      </ItemButton>
      <Col flex='auto' />
      <RibbonDivider />
      <BackToMarkup />
    </>
  );
};

export default MaintenanceMarkupCreateView;
