import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { Dropdown, Menu, Divider } from 'antd';
import { SubnodeOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';

import { PRODUCT_VIEW } from 'static/Constants';

import { RibbonButton, DownloadFormItem } from 'common/components';
import DownloadNifFormSection from 'pages/home/ribbon/components/sections/product/DownloadNifFormSection';
import AssignCategoryToProduct from 'pages/home/ribbon/components/sections/product/AssignCategoryToProduct';

import ProductClone from './ProductClone';
import SyndicationSectionHistory from './SyndicationHistorySection';
import MappingInitSyncSection from './MappingInitSyncSection';
import ProductSheets from './ProductSheets';
import BulkEditProduct from '../../controls/product/BulkEditProduct';

import HistoryEntity from 'pages/home/ribbon/form-manange/components/form-history-grid/HistoryEntity';

import GDSNPublication from 'common/components/product-publication-setup/GDSNPublication';
import PostToWalmart from 'common/components/product-publication-setup/PostToWalmart';
import CreateProductViaFormModal from 'common/components/product-add/CreateProductViaFormModal';

import WithdrawPublicationButton from '../../controls/product/WithdrawPublicationButton';

import { CanShow } from 'common/components/wrapper/show-condition';
import AssignProductToSupplier from '../product/AssignProductToSupplier';


import { useCheckPermissionAnd } from 'hooks/useCheckPermissions';
import {
  useCheckIsSuperAdmin,
  useCheckAllowEditProducts,
  usePermissionEditSharedProduct,
  useCheckMemberSupplier,
  useCheckIsSuperMember,
} from 'hooks';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { SECURE_CONFIG } from 'utils/SecureRoute';
import {
  ABILITY_ACTION,
  ABILITY_SUBJECT,
  PERMISSION_EDIT_PRODUCTS,
  PERMISSION_VIEW_NEW_ITEM_FORMS,
} from 'static/Permission';




import { Can } from 'context/Can';

import messages from 'i18n/messages/home';
import messagesProduct from 'i18n/messages/product';

import * as ssoProductSelectors from 'pages/sso-product/controllers/selectors';

import { useCheckDisableWithdrawPublicationModal } from 'hooks';
import IXOneSection from '../../sections/product/IXOneSection';
import useCheckIXOneCapture from 'hooks/useCheckIXOneCapture';

const sectionAfterProductSlashIdRegex = /[^\/product\/(0-9)][a-z]*/g;

const { SubMenu } = Menu;

const ProductActions = ({ productFull, productId }) => {
  const location = useLocation();

  const [addModalVisible, setAddModalVisible] = useState(false);

  const isBpSsoProductValidate = useSelector(
    ssoProductSelectors.selectIsBpSsoProductValidate()
  );

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const isValidPermissionAND = useCheckPermissionAnd();

  const activeSectionAfterProductId = location.pathname.match(
    sectionAfterProductSlashIdRegex
  )?.[0];

  const validateOverview = () => {
    const regex = /\/product\/\d+$/;
    const isOverview = regex.test(location.pathname);
    return isOverview ? PRODUCT_VIEW.OVERVIEW : null;
  };

  const getActiveContent = () => {
    const regex = /\/(?:product)\/(.+)(?=\/\d+$)/;

    const results = location?.pathname?.match(regex);

    return results?.length
      ? results[1]
      : isBpSsoProductValidate
        ? PRODUCT_VIEW.OVERVIEW
        : validateOverview();
  };

  const activeContent = getActiveContent();

  const isActiveView = [
    PRODUCT_VIEW.DETAILVIEW,
    PRODUCT_VIEW.OVERVIEW,
    PRODUCT_VIEW.QA_SPEC,
    PRODUCT_VIEW.QA_EVALUATION_FORM,
    PRODUCT_VIEW.VERSIONS,
  ].includes(activeContent);

  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  const allowSeeProductSyndication = isValidPermissionAND([
    SECURE_CONFIG.ACCESS_SYNDICATION.PASS,
    SECURE_CONFIG.ACCESS_GDSN.PASS,
    SECURE_CONFIG.PRODUCT.PASS,
  ]);

  const hasPermissionEditProducts = useCheckPermissions(
    PERMISSION_EDIT_PRODUCTS
  );

  const hasPermissionEditSharedProduct = usePermissionEditSharedProduct();
  const isSuperAdmin = useCheckIsSuperAdmin();
  const isMemberSupplierUser = useCheckMemberSupplier();
  const isSuperMember = useCheckIsSuperMember();

  const isAllowActionsPim =
    isSuperAdmin || hasPermissionEditProducts || hasPermissionEditSharedProduct;

  const isAllowToDownloadNifForm = useCheckPermissions(
    PERMISSION_VIEW_NEW_ITEM_FORMS
  );

  const isDisableWithdrawPublicationModal =
    useCheckDisableWithdrawPublicationModal(productFull);

  const disabledDropdown =
    !isActiveView &&
    !isSuperAdmin &&
    !isSuperMember &&
    isMemberSupplierUser &&
    !isAllowActionsPim;

  const isShowIXOneCapture = useCheckIXOneCapture();

  const menuOptions = (
    <Menu>
      {/* {isShowIXOneCapture ? (
        <>
          <Menu.Item>
            <IXOneSection
              size='small'
              label='Send to IX-ONE for Capture'
              width='20px'
            />
          </Menu.Item>
          <Divider style={{ margin: 0 }} />
        </>
      ) : null} */}

      {isActiveView && (
        <>
          <Menu.Item
            key='edit-product'
            style={{
              backgroundColor: 'inherit',
            }}
          >
            <RibbonButton
              size='small'
              icon={<EditOutlined style={{ height: 26 }} />}
              label={messages.productEdit}
              onClick={() => setAddModalVisible(true)}
              disabled={!isAllowEditProduct}
              className='button-item-dropdown'
            />
          </Menu.Item>
          <Menu.Item
            key='clone-product'
            style={{
              backgroundColor: 'inherit',
            }}
          >
            <ProductClone
              isDisabled={!isAllowEditProduct}
              productId={productFull?.productId}
            />
          </Menu.Item>
        </>
      )}

      <CanShow.SharedOnlyMember>
        <Menu.Item
          key='syndication-history'
          style={{
            backgroundColor: 'inherit',
          }}
        >
          <SyndicationSectionHistory
            productFull={productFull}
            disabled={
              productFull?.status !== 'Active' || !allowSeeProductSyndication
            }
          />
        </Menu.Item>

        {[PRODUCT_VIEW.SYNDICATION].includes(activeSectionAfterProductId) && (
          <Menu.Item
            key='mapping-sync'
            style={{
              backgroundColor: 'inherit',
            }}
          >
            <MappingInitSyncSection />
          </Menu.Item>
        )}

        <Menu.Item
          key='sheets'
          style={{
            backgroundColor: 'inherit',
          }}
        >
          <ProductSheets productId={productFull?.productId} disabled={true} />
        </Menu.Item>
        <Menu.Item
          key='publish-gdsn-product'
          style={{
            backgroundColor: 'inherit',
          }}
        >
          <GDSNPublication isFullView productFull={productFull} />
        </Menu.Item>

        {isSuperAdmin && (
          <Menu.Item
            key='post-to-walmart'
            style={{
              backgroundColor: 'inherit',
            }}
          >
            <PostToWalmart isFullView />
          </Menu.Item>
        )}

        <Menu.Item
          key='delete-publication'
          style={{
            backgroundColor: 'inherit',
          }}
        >
          <WithdrawPublicationButton
            products={[{ id: productFull?.productId }]}
            isDisabled={isDisableWithdrawPublicationModal}
          />
        </Menu.Item>

        <Menu.Item
          key='download-nif-form'
          style={{
            backgroundColor: 'inherit',
          }}
          disabled={!isAllowToDownloadNifForm}
        >
          <DownloadNifFormSection
            detailItems={[{ id: productFull?.productId }]}
            isAllowToDownloadNifForm={isAllowToDownloadNifForm}
          />
        </Menu.Item>

        <Menu.Item
          key='download-splash-form'
          style={{ backgroundColor: 'inherit' }}
        >
          <DownloadFormItem
            selectedEntityIds={[productFull?.productId]}
            entityType='PIM'
          />
        </Menu.Item>
        <Menu.Item key='history-entity' style={{ backgroundColor: 'inherit' }}>
          <HistoryEntity
            size='small'
            entityId={productFull?.productId}
            entityType='PIM'
          />
        </Menu.Item>
      </CanShow.SharedOnlyMember>

      <SubMenu
        key={'bulk-edit-product'}
        style={{ backgroundColor: 'inherit' }}
        disabled={!isAllowActionsPim}
        title={
          <RibbonButton
            size='small'
            className='button-item-dropdown'
            icon={<DownloadOutlined />}
            disabled={!isAllowActionsPim}
            label={messagesProduct.bulkEditProduct}
          />
        }
      >
        <BulkEditProduct
          disabled={!isAllowActionsPim}
          disabledExport={!isAllowEditProduct}
          selectedProducts={[productFull?.productId]}
          isProductDetail
        />
      </SubMenu>
      <Can I={ABILITY_ACTION.ASSIGN} a={ABILITY_SUBJECT.PRODUCT_CATEGORY}>
        <Menu.Item
          key='assign-category-to-product-detail'
          style={{ backgroundColor: 'inherit' }}
        >
          <AssignCategoryToProduct isProductDetail={true} />
        </Menu.Item>
      </Can>
      {/* <Menu.Item
        key='assign-product-to-supplier'
        style={{ backgroundColor: 'inherit' }}
      >
        <AssignProductToSupplier
          isProductDetail={true}
          productData={productFull}
        />
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menuOptions}
        placement='bottomLeft'
        disabled={disabledDropdown}
        arrow
      >
        <RibbonButton
          icon={<SubnodeOutlined />}
          label={messages.productActions}
        />
      </Dropdown>

      {addModalVisible && (
        <CreateProductViaFormModal
          addModalVisible={addModalVisible}
          setAddModalVisible={setAddModalVisible}
          isEdit
          productId={productId}
        />
      )}
    </>

  );
};

export default ProductActions;
