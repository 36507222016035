import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Typography, Checkbox, Tooltip } from 'antd';
import { isObject } from 'lodash';

import './InfoWithLabel.less';
const { Text } = Typography;

const InfoWithLabel = (props) => {
  const {
    label,
    info,
    className,
    labelColProps,
    infoColProps,
    boldInfo,
    labelAlign = 'left',
    infoTextStyle = {},
    labelTextStyle = {},
    align,
    infoAppend,
    gutter,
    showTooltip,
    editIconRender,
    style,
    log,
  } = props;
  const clp = 'info-with-label';
  const isBoolean = typeof info === 'boolean';

  const renderInfo = () => {
    if (info !== null) {
      if (isBoolean) return <Checkbox checked={info} />;

      if (React.isValidElement(info)) return info;

      if (!isObject(info)) return info;
    }

    return null;
  };

  return label ? (
    <Row
      gutter={gutter || [10, 0]}
      className={`info-with-label ${className ? className : ''}`}
      align={align}
      style={style}
    >
      <Col
        className={`${clp}__label-col ${clp}__label--text-${labelAlign}`}
        {...labelColProps}
      >
        <Text
          strong
          className={`${clp}__label-text`}
          style={{ ...infoTextStyle, ...labelTextStyle }}
        >
          {label}:
        </Text>
      </Col>
      <Col className={`${clp}__info`} {...infoColProps}>
        <Text
          strong={boldInfo}
          className={`${clp}__info-text`}
          style={infoTextStyle || {}}
          ellipsis={{ tooltip: showTooltip && renderInfo() }}
        >
          {renderInfo()}
          {infoAppend ? infoAppend : null}
        </Text>
      </Col>
      {editIconRender ? <Col>{editIconRender}</Col> : null}
    </Row>
  ) : null;
};
InfoWithLabel.propTypes = {
  /**
   * className of container
   */
  className: PropTypes.string,
  /**
   * Props of label col - Ant Col Component props
   */
  labelColProps: PropTypes.object,
  /**
   * Props of info col - Ant Col Component props
   */
  infoColProps: PropTypes.object,
  /**
   * Make info text bolder
   */
  boldInfo: PropTypes.bool,
  /**
   * Text alignment of label
   */
  labelAlign: PropTypes.oneOf(['center', 'left', 'right']),
  /**
   * Text alignment of label
   */
  infoComponent: PropTypes.func,
};
export default InfoWithLabel;
