import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';

import { Typography, Button, Row, Col, Form } from 'antd';
import OtpHintImage from '../shared/OtpHintImage';
import { PhoneInfo } from '../shared/PhoneSection';
import { CountdownTrack } from 'common/components';

import selectorUser from 'redux/user/selectors';

import { getOtpTimeFormartter } from '../../utils/utils';
import { useOtpTrigger } from '../../hooks/useOtpTrigger';

import {
  LOGIN_SESSION_EXP_MESSAGE_FROM_BE,
  OTP_VIEW_CUSTOM_CONFIG,
  OTP_TYPE,
  OTP_ACTION,
} from '../../constants';

import './OtpTrigger.less';

const { Text } = Typography;

const OtpTrigger = (props, ref) => {
  const {
    mfaLoginInfo,
    formInst,
    setStep,
    setChangeMfaPhoneSession,
    isAllowResend,
    setTimeAllowResend,
    timeAllowResend,
    setOtpCheckError,
    setIsSessionExpired,
    otpType = OTP_TYPE.phone,
    otpAction = OTP_ACTION.verfiyOnly,
    addNew,
    setMfaSession,
  } = props;

  const [error, setError] = useState('');

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const { mfaPhoneNumber, loginSession } = mfaLoginInfo || {};

  const {
    onGetOtpForNewMfaPhoneUserProfile,
    onGetOtpForLoginAllowAddMfaPhone,
    onGetOtpForLogin,
    onGetOtpForUnlockAccountByEmail,
    onGetOtpForUnlockAccountByPhone,
    isLoading,
  } = useOtpTrigger({
    formInst,
    userInfo,
    setChangeMfaPhoneSession,
    setMfaSession,
    setStep,
    setTimeAllowResend,
    loginSession,
    setError,
    setOtpCheckError,
    setIsSessionExpired,
  });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onGetOtp();
    }
  };

  const onGetOtp = ({ isResend } = { isResend: false }) => {
    if (otpAction === OTP_ACTION.changeMfaPhone) {
      onGetOtpForNewMfaPhoneUserProfile({ isResend });
    } else if (otpAction === OTP_ACTION.login) {
      if (addNew) {
        onGetOtpForLoginAllowAddMfaPhone({ isResend });
      } else {
        onGetOtpForLogin({ isResend });
      }
    } else if (otpAction === OTP_ACTION.unlockAccount) {
      if (otpType === OTP_TYPE.email) {
        onGetOtpForUnlockAccountByEmail({ isResend });
      } else if (otpType === OTP_TYPE.phone) {
        onGetOtpForUnlockAccountByPhone({ isResend });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onResendOtp: () => onGetOtp({ isResend: true }),
  }));

  const otpSendViewConfig = OTP_VIEW_CUSTOM_CONFIG?.[otpType]?.otpSend;

  const InputRender = otpSendViewConfig?.inputComponent
    ? otpSendViewConfig?.inputComponent
    : null;

  return (
    <div className='otp-trigger'>
      <Form form={formInst}>
        <Text className='otp-trigger__title'>{otpSendViewConfig?.title}</Text>

        {otpType === OTP_TYPE.phone ? (
          <OtpHintImage
            src={OTP_VIEW_CUSTOM_CONFIG.phone.otpSend.hintImageSrc}
          />
        ) : otpType === OTP_TYPE.email ? (
          <OtpHintImage
            src={OTP_VIEW_CUSTOM_CONFIG.email.otpSend.hintImageSrc}
          />
        ) : null}

        {addNew ? (
          InputRender && <InputRender onKeyDown={handleKeyDown} />
        ) : otpAction === OTP_ACTION.login ? (
          <PhoneInfo mfaPhoneNumber={mfaPhoneNumber} />
        ) : null}

        <Text className='otp-trigger__hint'>
          {otpSendViewConfig?.inputHint}
        </Text>

        <Row className='otp-trigger__control'>
          <Col xs={24}>
            <Button
              className='otp-trigger__btn-send'
              type='primary'
              onClick={onGetOtp}
              loading={isLoading}
              disabled={!isAllowResend}
            >
              Get OTP
            </Button>
          </Col>
        </Row>
        {!isAllowResend && (
          <CountdownTrack
            wrapperClass='otp-check__hint-count-down'
            expirationTime={timeAllowResend}
            totalTime={60}
            timeFormatter={getOtpTimeFormartter}
            style={{ display: isAllowResend ? 'none' : '' }}
            noProgress
          />
        )}
        <Row>
          <Col xs={24} style={{ minHeight: 18 }}>
            {error && <Text type='danger'>{error}</Text>}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default forwardRef(OtpTrigger);
