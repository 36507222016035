import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import * as helpMaintenanceSelectors from 'pages/maintenance/controllers/selectors';

import {
  YoutubeOutlined,
  PictureOutlined,
  TableOutlined,
  FontColorsOutlined,
  RedoOutlined,
  UndoOutlined,
  LinkOutlined,
} from '@ant-design/icons';

import MdTextTool from './components/text/MdToolText';
import MdToolTable from './components/table/MdToolTable';
import MdCCEula from './components/shortcode/MdCCEula';
import { MarkdownMediaCustomModal } from 'common/components/markdown/uploadMedia/components';
import classnames from 'classnames';

import './MdEditorCustomToolbar.less';

const EDIT_VIEW = {
  NONE_EDIT: 'NONE_EDIT',
  TEXT_EDIT: 'TEXT_EDIT',
  TABLE_EDIT: 'TABLE_EDIT',
  YOUTUBE_EDIT: 'YOUTUBE_EDIT',
};

const MdEditorCustomToolbar = (props) => {
  const {
    onChangeColor,
    mdEditor,
    setMediaOpen,
    isMediaOpenSelector,
    markdownRefSelector,
    markdownRefUpdateAction,
    setEntityOpen,
    setInterEntityOpen,
    setIsEntityInsideTable,
    toolList,
    tempMdTableData,
  } = props;

  let isHelpUploadMediaOpen;

  const [editView, setEditView] = useState(EDIT_VIEW.NONE_EDIT);

  isHelpUploadMediaOpen = useSelector(
    isMediaOpenSelector
      ? isMediaOpenSelector()
      : helpMaintenanceSelectors.selectIsHelpUploadMediaOpen()
  );

  const closeEditView = () => {
    setEditView(EDIT_VIEW.NONE_EDIT);
    setIsEntityInsideTable(false);
  };

  const closeCustomYoutubeModal = (status) => {
    if (!status) closeEditView();
  };

  const handleToggleEditView = (currentEditView, view) => {
    if (currentEditView === view) {
      setEditView(EDIT_VIEW.NONE_EDIT);
      setIsEntityInsideTable(false);
    } else {
      if (view === EDIT_VIEW.TABLE_EDIT) {
        setIsEntityInsideTable(true);
      } else {
        setIsEntityInsideTable(false);
      }
      setEditView(view);
    }
  };

  const toggleTextEditTool = () => {
    handleToggleEditView(editView, EDIT_VIEW.TEXT_EDIT);
  };

  const addCCEulaShortCode = (event) => {
    event.stopPropagation();

    mdEditor.doc.replaceSelection(`<CCEula />`);
  };

  const isInToolList = (name) => {
    return toolList ? toolList.find((toolItem) => toolItem === name) : false;
  };

  const handleUndo = () => {
    mdEditor.doc.undo();
  };

  const handleRedo = () => {
    mdEditor.doc.redo();
  };

  return (
    <Row className='md-editor-custom-toolbar'>
      <Button
        className={classnames('md-editor-custom-toolbar__btn')}
        onClick={(event) => {
          event.stopPropagation();
          mdEditor.doc.replaceSelection(`[]()`);
        }}
      >
        <LinkOutlined />
      </Button>
      {isInToolList('text') && (
        <Button
          className='md-editor-custom-toolbar__btn'
          onClick={(event) => {
            event.stopPropagation();
            handleToggleEditView(editView, EDIT_VIEW.TEXT_EDIT);
          }}
        >
          <FontColorsOutlined />
        </Button>
      )}
      {isInToolList('table') && (
        <Button
          className='md-editor-custom-toolbar__btn'
          onClick={(event) => {
            event.stopPropagation();
            handleToggleEditView(editView, EDIT_VIEW.TABLE_EDIT);
          }}
        >
          <TableOutlined />
        </Button>
      )}
      {isInToolList('media') && (
        <Button
          className='md-editor-custom-toolbar__btn'
          onClick={(event) => {
            event.stopPropagation();
            setMediaOpen && setMediaOpen(!isHelpUploadMediaOpen);
          }}
        >
          <PictureOutlined />
        </Button>
      )}
      {isInToolList('youtube') && (
        <Button
          className='md-editor-custom-toolbar__btn'
          onClick={(event) => {
            event.stopPropagation();
            handleToggleEditView(editView, EDIT_VIEW.YOUTUBE_EDIT);
          }}
        >
          <YoutubeOutlined />
        </Button>
      )}

      {isInToolList('entity') && (
        <>
          <Button
            className={classnames(
              'md-editor-custom-toolbar__btn',
              'md-editor-custom-toolbar__btn-entity'
            )}
            onClick={(event) => {
              event.stopPropagation();
              setEntityOpen && setEntityOpen((prevStatus) => !prevStatus);
            }}
          >
            <span>[[</span>
            <span>e</span>
            <span>]]</span>
          </Button>
          <Button
            className={classnames(
              'md-editor-custom-toolbar__btn',
              'md-editor-custom-toolbar__btn-interact-entity'
            )}
            onClick={(event) => {
              event.stopPropagation();
              setInterEntityOpen &&
                setInterEntityOpen((prevStatus) => !prevStatus);
            }}
          >
            <span>{`[{`}</span>
            <span>ie</span>
            <span>{`}]`}</span>
          </Button>
        </>
      )}
      {editView === EDIT_VIEW.TEXT_EDIT && (
        <MdTextTool
          mdEditor={mdEditor}
          isColorPickerOpen={editView === EDIT_VIEW.TEXT_EDIT}
          toggleTextEditTool={toggleTextEditTool}
          onchangeColor={onChangeColor}
        />
      )}
      {editView === EDIT_VIEW.TABLE_EDIT && (
        <MdToolTable
          mdEditor={mdEditor}
          markdownRefSelector={markdownRefSelector}
          markdownRefUpdateAction={markdownRefUpdateAction}
          closeEditView={closeEditView}
          setEntityOpen={setEntityOpen}
          setInterEntityOpen={setInterEntityOpen}
          tempMdTableData={tempMdTableData}
          toolList={toolList}
        />
      )}

      {editView === EDIT_VIEW.YOUTUBE_EDIT && (
        <MarkdownMediaCustomModal
          isCustomImageModalOpen={editView === EDIT_VIEW.YOUTUBE_EDIT}
          setIsCustomImageModalOpen={closeCustomYoutubeModal}
          mdEditor={mdEditor}
          maxWidth={550}
          previewType='youtube'
          modalTitle='Custom Youtube Video'
        />
      )}
      {isInToolList('ccEula') && <MdCCEula onAddCCEula={addCCEulaShortCode} />}
      <Col flex='auto' />
      <Button className='md-editor-custom-toolbar__btn' onClick={handleUndo}>
        <UndoOutlined />
      </Button>
      <Button className='md-editor-custom-toolbar__btn' onClick={handleRedo}>
        <RedoOutlined />
      </Button>
    </Row>
  );
};

MdEditorCustomToolbar.propTypes = {
  onChangeColor: PropTypes.func,
  //? mdEditor - editor instance
  mdEditor: PropTypes.object,
  //? setMediaOpen - set open/hide media upload
  setMediaOpen: PropTypes.func,
  //? isMediaOpenSelector - selector to media upload open redux state
  isMediaOpenSelector: PropTypes.func,
  //? toolList - select additional tool item
  toolList: PropTypes.array,
};

export default MdEditorCustomToolbar;
