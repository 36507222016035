import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { QuestionCircleTwoTone } from '@ant-design/icons';

import * as _ from 'lodash';
import { Row, Col, Divider, Button, Typography, Tooltip } from 'antd';

import { InfoWithLabel } from 'common/components';
import ProductOverviewBricCode from './ProductOverviewBricCode';
import ProductOverviewDimension from './ProductOverviewDimension';
import ProductOverviewQuestionButton from './ProductOverviewQuestionButton';
import ProductOverviewCertification from '../certifications/ProductOverviewCertification';

import { findDimensionEnumDisplay } from '../../mappers/mapToEnums';
import * as globalSelectors from 'redux/global/selectors';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

import { Images } from 'config/assets';
import CalculatedPropertiesModal from './CalculatedPropertiesModal';

const ProductOverviewMetadata = ({
  product,
  showPackageDrawer,
  productItemModules,
  productSchema,
  productEnums,
  refContainer,
  isHiddenQuestionIcon,
  ...rest
}) => {
  const intl = useIntl();
  const [displayDimensionUOM, setDisplayDimensionUOM] = useState(null);
  const [displayImperialDimensionUOM, setDisplayImperialDimensionUOM] =
    useState(null);
  const [truncated, setTruncated] = useState(false);

  const targetMarketList = useSelector(
    globalSelectors.selectProductTargetMarket()
  );

  const getTargetMarketName = () => {
    // const productTargetMarketData = targetMarketList.find(
    //   (targetMarketItem) =>
    //     targetMarketItem.Numeric.toString() === product?.targetMarket
    // );

    // return productTargetMarketData?.Country || '';
    return product?.targetMarket;
  };

  useEffect(() => {
    if (!_.isEmpty(product) && !_.isEmpty(productEnums)) {
      const dimensionUOMEnumDisplay = findDimensionEnumDisplay(
        product?.dimensionUOM,
        productEnums
      );
      const imperialDimensionUOMEnumDisplay = findDimensionEnumDisplay(
        product?.imperialDimensionUOM,
        productEnums
      );
      setDisplayDimensionUOM(dimensionUOMEnumDisplay || product?.dimensionUOM);
      setDisplayImperialDimensionUOM(
        imperialDimensionUOMEnumDisplay || product?.imperialDimensionUOM
      );
    }
  }, [JSON.stringify(product), JSON.stringify(productEnums)]);

  const renderSupplier = () => {
    return (
      <Row>
        {product &&
          product.suppliers?.length > 0 &&
          product.suppliers.map((val, index) => (
            <Typography.Link
              key={val.id}
              href={`/company/${val?.id}`}
              style={{
                fontSize: '13px',
                fontWeight: 500,
                letterSpacing: '1px',
              }}
              target='_blank'
            >
              {val.name}
              {index < product.suppliers.length - 1 && ',\u00A0'}
            </Typography.Link>
          ))}
      </Row>
    );
  };

  const renderMetadata = () => {
    const metadata = [
      {
        label: intl.formatMessage(Messages.riverId),
        info: product?.rivirId,
      },
      {
        label: intl.formatMessage(Messages.ixOneId),
        info: product?.ixOneId,
        showTooltip: true
      },
      {
        label: intl.formatMessage(Messages.brandName),
        info: product?.brandName,
      },
      {
        label: intl.formatMessage(Messages.targetMarket),
        info: getTargetMarketName(),
      },
      {
        label: intl.formatMessage(Messages.barcode),
        info: product?.gtin,
        infoAppend: !isHiddenQuestionIcon ? (
          <ProductOverviewQuestionButton
            modalContent='Add content component here'
            showPackageDrawer={showPackageDrawer}
            mode='Imperial'
          />
        ) : null,
      },
      // {
      //   label: intl.formatMessage(Messages.productType),
      //   info: product?.productType,
      // },
      {
        label: intl.formatMessage(Messages.bricCode),
        info: (
          <ProductOverviewBricCode
            brickCode={product?.brickCode}
            brickCodeName={product?.brickCodeName}
            // categories={product?.categories} // todo: update if changing later
          />
        ),
      },
      {
        label: intl.formatMessage(Messages.size),
        info: (
          <RenderSize productDetail={product} productEnums={productEnums} />
        ),
        className: 'info-with-label_render-size',
      },
      {
        label: intl.formatMessage(Messages.dimensions),
        info: (
          <ProductOverviewDimension
            imperialHeight={product?.imperialHeight}
            imperialWidth={product?.imperialWidth}
            imperialDepth={product?.imperialDepth}
            imperialDimensionUOM={
              displayImperialDimensionUOM || product?.imperialDimensionUOM
            }
            imperialWeight={product?.imperialWeight}
            imperialWeightUOM={
              findDimensionEnumDisplay(
                product?.imperialWeightUOM,
                productEnums
              ) || product?.imperialWeightUOM
            }
            metricHeight={product?.height} // primary is metric
            metricWidth={product?.width}
            metricDepth={product?.depth}
            metricDimensionUOM={displayDimensionUOM || product?.dimensionUOM}
            metricWeight={product?.weight}
            metricWeightUOM={
              findDimensionEnumDisplay(product?.weightUOM, productEnums) ||
              product?.weightUOM
            }
            showPackageDrawer={showPackageDrawer}
          />
        ),
        infoTextStyle: { width: '100%' },
      },
      {
        label: intl.formatMessage(Messages.certification),
        info: (
          <ProductOverviewCertification
            productItemModules={productItemModules}
            productSchema={productSchema}
            productEnums={productEnums}
          />
        ),
        infoTextStyle: { minWidth: '100%' },
      },
    ];

    // if (product?.isPrivateLabel) {
    //   metadata.splice(2, 0, {
    //     label: intl.formatMessage(Messages.supplierNames),
    //     info: renderSupplier(),
    //   });
    // }

    return metadata.map((item, index) => {
      const props = {
        ...item,
        labelAlign: 'right',
        labelColProps: {
          ...item?.labelColProps,
          flex: '115px',
        },
        infoColProps: {
          flex: 1,
        },
        gutter: [20, 0],
      };

      return <InfoWithLabel key={index} {...props} />;
    });
  };

  const primaryImg = () => {
    return product?.productPrimaryImage || Images.RIVIR_LOGO_DEFAULT;
  };

  return (
    <Row style={{ flexWrap: 'nowrap', height: '100%' }} ref={refContainer}>
      <Col flex={'250px'} className='product-overview__primary-image-col'>
        <div
          className='product-overview__primary-image'
          style={{ backgroundImage: `url(${primaryImg()})` }}
        />
      </Col>
      <Col flex={0}>
        <Divider type='vertical' style={{ height: '100%' }} />
      </Col>
      <Col
        className='product-overview__metadata'
        style={{ overflow: 'hidden' }}
        flex={1}
      >
        {renderMetadata()}
      </Col>
    </Row>
  );
};

const RenderSize = ({ productDetail, productEnums }) => {
  const [visible, setVisible] = useState();

  const uiEnumDisplay =
    findDimensionEnumDisplay(productDetail?.primarySizeUOM, productEnums) ?? '';

  const primarySize = isNaN(parseFloat(productDetail?.primarySize))
    ? 'N/A'
    : Math.round(parseFloat(productDetail?.primarySize) * 100) / 100 +
      ' ' +
      uiEnumDisplay;

  return (
    <>
      {productDetail?.netContentsStatement
        ? productDetail?.netContentsStatement
        : primarySize}
      <Button type='link' onClick={() => setVisible(true)}>
        <QuestionCircleTwoTone style={{ fontSize: 16 }} />
      </Button>
      <CalculatedPropertiesModal
        visible={visible}
        setVisible={setVisible}
        primarySize={primarySize}
        productDetail={productDetail}
      />
    </>
  );
};

ProductOverviewMetadata.propTypes = {
  product: PropTypes.object,
  showPackageDrawer: PropTypes.func,
};

export default ProductOverviewMetadata;
