import React from 'react';

import { EditFilled } from '@ant-design/icons';
import { Dropdown, Menu, Typography } from 'antd';

import reactCSS from 'reactcss';

const { Text } = Typography;

const EditableIcon = (props) => {
  const { shape = 'square', style = {} } = props;

  const styles = reactCSS({
    default: {
      icon: {
        color: 'white',
        background: '#389e0d',
        padding: 2,
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconSquare: {
        width: 19,
        height: 19,
        borderRadius: 2,
        ...style,
      },
      iconRound: {
        width: 22,
        height: 22,
        borderRadius: '100px',
        ...style,
      },
      overlay: {
        padding: 4,
        fontWeight: 900,
      },
    },
  });

  const iconWrapperStyle = {
    square: { ...styles.icon, ...styles.iconSquare },
    round: { ...styles.icon, ...styles.iconRound },
  };

  const overLay = (
    <Menu style={styles.overlay}>
      <Menu.Item>
        <Text strong>Editable</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={overLay} placement='bottomRight'>
      <EditFilled style={iconWrapperStyle[shape]} />
    </Dropdown>
  );
};

export default EditableIcon;
