import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Select, InputNumber, Button } from 'antd';
import { StopOutlined } from '@ant-design/icons';

import { Form, WrapperSelect } from 'common/components';
import { useGetNutritionEnum } from '../hook/useNutritionLabel';

import { sortBy } from 'lodash';

const { Option } = Select;

const QUANTITY_UOM = [
  {
    value: 'Gram',
    displayName: 'g',
  },
  {
    value: 'Milligram',
    displayName: 'mg',
  },
  {
    value: 'Microgram',
    displayName: 'mcg',
  },
  {
    value: 'International unit per litre [iU/L]',
    displayName: 'IU/L',
  },
  {
    value: 'International unit per millilitre [iU/mL]',
    displayName: 'IU/ML',
  },
];

const NutrientForm = (props) => {
  const { isEdit = true, method, form, dv, onCancel } = props;

  const refAddBtn = useRef();

  const { NUTRITION, measurementPrecisionCodeEnum } = useGetNutritionEnum();

  const filterSelectedDV = useMemo(() => {
    const dvName = dv?.map((dv) => dv.nutritionTypeCode);

    if (!NUTRITION) return [];

    return sortBy(
      NUTRITION.filter(
        (nutrition) => !dvName.includes(nutrition.enumDisplayName)
      ),
      ['enumDisplayName', 'enumGroup']
    );
  }, [dv, NUTRITION]);

  const handleNumberInput = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const styles = {
    wrapper: {
      margin: '15px 0',
    },
    inputNumber: {
      width: '100%',
    },
    selectAfter: {
      width: '72px',
    },
    unit: {
      padding: '5px 8px',
      marginLeft: '5px',
      background: '#DCDCDC',
      borderRadius: '20px',
    },
    form: {
      marginBottom: 10,
    },
    formItem: {
      marginBottom: '4px',
    },
    dv: {
      textAlign: 'center',
    },
    rowMargin: {
      marginBottom: 5,
    },
    cancelBtn: {
      width: '100%',
      height: 35,
    },
  };

  useEffect(() => {
    refAddBtn.current && refAddBtn.current.focus();
  }, [form]);

  const quantity = Form.useWatch('quantity', form);

  return (
    <div style={styles.wrapper}>
      {isEdit && (
        <React.Fragment>
          <Form
            form={form}
            layout='vertical'
            name='dvForm'
            initialValues={{
              quantityUom: 'Gram',
            }}
            style={styles.form}
          >
            <Form.Item
              name='nutritionTypeCode'
              rules={[{ required: true, message: 'Please select nutrient' }]}
              style={styles.formItem}
            >
              <WrapperSelect
                placeholder='Select'
                showSearch
                filterOption={(input, option) =>
                  option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(triggerNode) => {
                  return triggerNode;
                }}
                optionFilterProp='children'
              >
                {filterSelectedDV?.map((item, idx) => (
                  <Option
                    key={idx}
                    value={item?.enumDisplayName}
                    title={item?.enumDisplayName + '(' + item?.enumCode + ')'}
                  >
                    {item?.enumDisplayName} ({item?.enumCode})
                  </Option>
                ))}
              </WrapperSelect>
            </Form.Item>
            <Form.Item name='measurementPrecisionCode' style={styles.formItem}>
              <WrapperSelect
                placeholder='Select measurement precision'
                showSearch
                filterOption={(input, option) =>
                  option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >=
                  0
                }
                getPopupContainer={(triggerNode) => {
                  return triggerNode;
                }}
              >
                {sortBy(measurementPrecisionCodeEnum?.[0]?.enumProperties, [
                  'enumDisplayName',
                  'enumGroup',
                ])?.map((item, index) => (
                  <Option value={item?.enumDisplayName} key={index}>
                    {item?.enumDisplayName}
                  </Option>
                ))}
              </WrapperSelect>
            </Form.Item>
            <Row>
              <Col span={24}>
                <Form.Item
                  name='dailyValueIntakePercent'
                  style={styles.formItem}
                >
                  <InputNumber
                    placeholder='Daily Value'
                    min={0}
                    style={styles.inputNumber}
                    onKeyPress={handleNumberInput}
                    addonAfter={'%'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={16}>
                <Form.Item name='quantity' style={styles.formItem}>
                  <InputNumber
                    style={styles.inputNumber}
                    min={0}
                    max={999}
                    placeholder='Quantity'
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name='quantityUom'
                  style={styles.formItem}
                  rules={[
                    {
                      required: quantity != null,
                      message: 'Quality Unit is required',
                    },
                  ]}
                >
                  <WrapperSelect
                    placeholder='Unit'
                    getPopupContainer={(triggerNode) => {
                      return triggerNode;
                    }}
                  >
                    {QUANTITY_UOM?.map((item, index) => (
                      <Option value={item?.value} key={index}>
                        {item?.displayName}
                      </Option>
                    ))}
                  </WrapperSelect>
                </Form.Item>
              </Col>
            </Row>
            <Row style={styles.rowMargin}>
              <Col xs={12}>
                <Button
                  icon={<StopOutlined />}
                  onClick={onCancel}
                  style={styles.cancelBtn}
                  danger
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={12}>
                {method === 'update' ? (
                  <Button
                    htmlType='submit'
                    style={{ width: '100%', height: '35px' }}
                    type='primary'
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    ref={refAddBtn}
                    htmlType='submit'
                    style={{ width: '100%', height: '35px' }}
                    type='primary'
                  >
                    Add New
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item name='nutrientIdx' hidden>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </React.Fragment>
      )}
    </div>
  );
};

NutrientForm.propsTypes = {
  method: PropTypes.oneOf(['add', 'update']),
};

export default NutrientForm;
