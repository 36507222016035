import { Popover, Typography } from 'antd';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

import _ from 'lodash';

const SnapshotFormItem = ({
  children,
  supplierModifiedData,
  snapshotData,
  isLoadingSnapshot,
  renderPopoverContent,
}) => {
  const isSnapshot = useCheckSnapshotForRetailer();

  const isDiffWithPreVersion = !_.isEqualWith(
    supplierModifiedData,
    snapshotData
  );

  if (isLoadingSnapshot) return <>{children}</>;

  if (isSnapshot && isDiffWithPreVersion) {
    return (
      <div className='basic-form'>
        <Popover
          content={renderPopoverContent}
          title={<Typography.Text strong>Previous Version</Typography.Text>}
          placement='top'
          destroyTooltipOnHide={true}
          overlayInnerStyle={{ minWidth: 400 }}
        >
          <div className='basic-form-item--hightLight-input'>{children}</div>
        </Popover>
      </div>
    );
  }

  return <>{children}</>;
};

export default SnapshotFormItem;
