import React, { useState, useRef } from 'react';

import { Form } from 'antd';

import {
  FormAddButton,
  PreviewableModal,
  FormEditButton,
} from 'common/components';

import QaSpecCharacteristicGrid from './QaSpecCharacteristicGrid';
import QaSpecLinkProductSpecDocumentModal from './QaSpecLinkProductSpecDocumentModal';

import { useProductSpecDocument } from 'pages/qa-spec/hooks/useProductSpecDocument';

import Messages from 'i18n/messages/qa-spec';
import { useIntl } from 'react-intl';
import ModalUpdateProductDocument from './ModalUpdateProductDocument';
import { EDIT_SECTION } from 'pages/qa-spec/components/edit-tab-checker';

import { checkShowPdfPreview } from 'utils/checkShowPdfPreview';
import { useGetQaProductSpecDocumentsSnapshot } from './snapshot/hooks';

const QaSpecProductDocument = (props) => {
  const intl = useIntl();
  const gridInst = useRef();

  const { productId } = props;

  const [linkDocModalVisible, setLinkDocModalVisible] = useState(false);
  const [updateDocumentModalVisible, setUpdateDocumentModalVisible] =
    useState(false);

  const [formInstance] = Form.useForm();

  const {
    rowData,
    columnDefs,
    loading,
    addDocument,
    previewData,
    setPreviewData,
    updateProductDocument,
    selectedRow,
    setSelectedRow,
  } = useProductSpecDocument({
    productId,
    gridInst,
  });

  const { qaProductSpecDocumentsSnapshot } =
    useGetQaProductSpecDocumentsSnapshot();

  const { linkPreview, extensionFile } = checkShowPdfPreview(
    previewData?.pdfPreview,
    previewData?.linkUrl,
    previewData?.fileType
  );

  const onClickAddNewDocumentBtn = () => {
    setLinkDocModalVisible(true);
  };

  const handleToggleUpdateDocument = () => {
    const { data } = selectedRow;

    formInstance.setFieldsValue({
      documentName: data?.documentName,
      characteristicsType: data?.characteristicsType,
    });
    setUpdateDocumentModalVisible(true);
  };

  const handleUpdateDocument = () => {
    const formValues = formInstance.getFieldsValue();

    const params = {
      ...formValues,
      linkId: selectedRow?.data?.linkId,
    };

    updateProductDocument({
      params,
      successCallback: () => {
        setUpdateDocumentModalVisible(false);
        setSelectedRow(null);
      },
    });
  };

  const headerButtons = [
    () => (
      <>
        <FormAddButton
          text={intl.formatMessage(Messages.productSpecAddNewDocumentButton)}
          onClick={onClickAddNewDocumentBtn}
          style={{ marginRight: 8 }}
        />
        <FormEditButton
          text='Update Document'
          onClick={handleToggleUpdateDocument}
          disabled={!selectedRow}
        />
      </>
    ),
  ];

  return (
    <>
      <QaSpecCharacteristicGrid
        type={EDIT_SECTION.PROD_SPEC_DOC}
        editCheckerHideSaveBtn
        title='Documents'
        loading={loading}
        gridInst={gridInst}
        columnDefs={columnDefs}
        rowData={rowData}
        showSaveBtn={false}
        headerButtons={headerButtons}
        onRowSelected={() => {
          const selectedRows = gridInst.current.api.getSelectedNodes();
          setSelectedRow(selectedRows[0]);
        }}
        onClearSelectedRow={() => setSelectedRow(null)}
        productId={productId}
        snapshotGridValues={qaProductSpecDocumentsSnapshot}
      />
      <QaSpecLinkProductSpecDocumentModal
        visible={linkDocModalVisible}
        setVisible={setLinkDocModalVisible}
        addDocument={addDocument}
        productId={productId}
      />
      {!!previewData ? (
        <PreviewableModal
          title={previewData?.documentName}
          link={linkPreview}
          visible={!!previewData}
          cancelModal={() => setPreviewData(null)}
          isDocument={true}
          isDownload={false}
          downloadLink={previewData?.linkUrl}
          fileType={extensionFile}
          type={previewData?.fileType}
          fileSize={previewData?.fileSize}
        />
      ) : null}

      {updateDocumentModalVisible && (
        <ModalUpdateProductDocument
          visible={updateDocumentModalVisible}
          onCancel={() => setUpdateDocumentModalVisible(false)}
          formInstance={formInstance}
          onOk={handleUpdateDocument}
        />
      )}
    </>
  );
};

export default QaSpecProductDocument;
