import React, { useRef, useState, useEffect } from 'react';

import { Tooltip } from 'antd';

const TextTooltip = (props) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);

  const { text, style } = props;

  const [isShowTooltip, setShowTooltip] = useState(false);
  const [visible, setVisible] = useState(false);

  const checkTextLength = () => {
    const containerWidth = containerRef?.current?.clientWidth;
    const textWidth = textRef?.current?.clientWidth;

    if (textWidth > containerWidth) setShowTooltip(true);
  };

  const onVisibleChange = (visible) => {
    if (isShowTooltip) setVisible(visible);
  };

  useEffect(() => {
    checkTextLength();
  }, []);

  return (
    <div
      className='text-tooltip__container'
      ref={containerRef}
      style={{ width: 'calc(100% - 11px)' }}
    >
      <Tooltip
        title={text}
        visible={visible}
        onVisibleChange={onVisibleChange}
        overlayStyle={props?.tooltipStyle}
      >
        <span
          ref={textRef}
          style={{
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: isShowTooltip ? '100%' : 'auto',
            ...style,
          }}
        >
          {text}
        </span>
      </Tooltip>
    </div>
  );
};

export default TextTooltip;
