import React from 'react';
import { Typography, Row, Col } from 'antd';

import classnames from 'classnames';

const ProductTileFooter = (props) => {
  const { upc12, rivirId, gtin } = props;
  const { Text } = Typography;

  return (
    <div className='product-tile__footer' justify='center' align='middle'>
      {upc12 || gtin ? (
        <>
          <Row style={{ width: '100%' }}>
            <Col xs={24} className='product-tile__footer-content'>
              <Text className='product-tile__footer-label' strong>
                GTIN:
              </Text>
              <Text className='product-tile__footer-text' ellipsis={true}>
                {gtin || 'n/a'}
              </Text>
            </Col>
            <Col xs={24} className='product-tile__footer-content'>
              <Text className='product-tile__footer-label' strong>
                UPC12:
              </Text>
              <Text className='product-tile__footer-text' ellipsis={true}>
                {upc12 || 'n/a'}
              </Text>
            </Col>
          </Row>
        </>
      ) : (
        <Row style={{ width: '100%' }}>
          <Col
            xs={24}
            className={classnames(
              'product-tile__footer-content',
              'product-tile__footer-content--rivir-id'
            )}
          >
            <Text className='product-tile__footer-label' ellipsis={true} strong>
              RIVIR ID:
            </Text>
            <Text className='product-tile__footer-text' ellipsis={true}>
              {rivirId || 'n/a'}
            </Text>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProductTileFooter;
