import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  Button,
  Typography,
  Checkbox,
  message,
  Switch,
  Space,
  Input,
} from 'antd';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FormComponents } from 'common/components/index';
import MarkdownGroupInput from './MarkdownGroupInput';
import { MarkdownMediaCustomPreview } from '../index';

import classnames from 'classnames';
import * as _ from 'lodash';
import './MarkdownMediaCustomModal.less';

const { Text } = Typography;

const MarkDownMediaCustomModal = (props) => {
  const {
    fileInfo,
    isCustomImageModalOpen,
    setIsCustomImageModalOpen,
    maxWidth,
    mdEditor,
    host,
    isAlwaysKeepScale,
    previewType,
    modalTitle,
  } = props;

  const { SliderNumber } = FormComponents;

  const [width, setWidth] = useState(100);
  const [ratio, setRatio] = useState(null);
  const [height, setHeight] = useState(100);
  const [isKeepRatio, setIsKeepRatio] = useState(
    previewType === 'video' ? true : false
  );
  const [isAlign, setIsAlign] = useState(false);
  const [space, setSpace] = useState(0);
  const [align, setAlign] = useState(null);

  const [youtubeLink, setYoutubeLink] = useState('');

  const getAddParagraph = useRef();

  const onChange = (value) => {
    setWidth(value);
    if (isKeepRatio) {
      setHeight(value);
    }
  };
  const onChangeHeight = (value) => {
    setHeight(value);
    if (isKeepRatio) {
      setWidth(value);
    }
  };

  const toggleKeepRatio = (e) => {
    setIsKeepRatio(e.target.checked);
    if (!align) {
      setWidth(100);
      setHeight(100);
    }
    if (align) {
      setWidth(50);
      setHeight(50);
    }
  };

  const handleResize = (size) => {
    if (size.width <= maxWidth) {
      setWidth((size.width / maxWidth) * 100);
    }
    if (size.height <= maxWidth / ratio) {
      setHeight((size.height / (maxWidth / ratio)) * 100);
    }
  };

  const isConfigAvailable = useCallback(({ color, font }) => {
    if (font || color) {
      return true;
    }
    return false;
  }, []);

  const generateMarkDownContent = () => {
    let initSyntax = '';
    if (previewType === 'image') initSyntax = '![custom_image';
    if (previewType === 'video' || previewType === 'youtube')
      initSyntax = '![@video';
    let mdContent =
      initSyntax +
      `${isAlign && align ? ' ?align=' + align : ''}` +
      `${isAlign && align && space ? ' ?space=' + space : ''}` +
      '](' +
      `${
        previewType !== 'youtube'
          ? host
            ? host + fileInfo?.url
            : fileInfo?.url
          : ''
      }` +
      `${previewType === 'youtube' ? youtubeLink : ''}` +
      ' =' +
      Math.floor(width) +
      'x' +
      Math.floor(height) +
      ')';
    const additionalParagraphs = getAddParagraph.current
      ? getAddParagraph.current()
      : [];

    let mdParagraph = '';
    let index = -1;
    additionalParagraphs.forEach((paragraphItem, idx) => {
      const haveConfig = isConfigAvailable({
        color: paragraphItem?.color,
        font: paragraphItem?.font,
      });
      if (idx === 0) mdParagraph += '\n';
      if (index !== 0)
        mdParagraph +=
          '\n' +
          `${haveConfig ? '(!p' : ''}` +
          `${paragraphItem?.color ? '|color=' + paragraphItem?.color : ''}` +
          `${paragraphItem?.font ? '|font=' + paragraphItem?.font : ''}` +
          `${haveConfig ? ')\n' : ''}` +
          paragraphItem?.content +
          '\n';
    });

    return mdContent + mdParagraph;
  };

  const handleInsert = () => {
    if (mdEditor) {
      mdEditor.doc.replaceSelection(generateMarkDownContent());
      message.success({
        content: <Text>Insert Image Successfully</Text>,
      });
    }
  };

  const onChangeSpace = (value) => {
    setSpace(value);
  };

  const setAlignConfig = (event, alignValue) => {
    if (event.target.checked) {
      setAlign(alignValue);
      setWidth(50);
      previewType !== 'youtube' && setHeight(50);
    } else {
      setAlign(null);
    }
  };

  const setIsAlignImage = (checked) => {
    setIsAlign(checked);
  };

  const onChangeLink = (e) => {
    setYoutubeLink(e.target.value);
  };

  useEffect(() => {
    if (isCustomImageModalOpen) {
      setTimeout(() => {
        const $imgWrap = document.getElementById('media-storage-image-wrapper');
        $imgWrap && setRatio($imgWrap.clientWidth / $imgWrap.clientHeight);
      }, 100);
      return;
    }
    if (!isCustomImageModalOpen) {
      setWidth(100);
      setHeight(100);
    }
  }, [isCustomImageModalOpen, ratio]);

  useEffect(() => {
    if (isAlwaysKeepScale) {
      setIsKeepRatio(true);
    }
  }, [isAlwaysKeepScale]);

  return (
    <React.Fragment>
      {isCustomImageModalOpen && (
        <Modal
          title={modalTitle}
          visible={isCustomImageModalOpen}
          mask={false}
          className={classnames('media-storage-custom-image__modal')}
          closable={true}
          footer={false}
          width={maxWidth + 300}
          onCancel={() => setIsCustomImageModalOpen(false)}
          maskClosable={false}
          destroyOnClose
        >
          <Row style={{ width: '100%', height: '100%' }}>
            <Col style={{ width: 240 }}>
              {previewType === 'youtube' && (
                <MarkdownGroupInput title='Link' style={{ marginBottom: 10 }}>
                  <Row gutter={[10, 10]}>
                    <Col xs={24}>
                      <Input onChange={onChangeLink} />
                    </Col>
                  </Row>
                </MarkdownGroupInput>
              )}
              <MarkdownGroupInput
                title='Alignment'
                style={{ marginBottom: 12 }}
              >
                <Row gutter={[10, 10]} style={{ paddingLeft: 5 }}>
                  <Col xs={24}>
                    <Space>
                      <Text className='media-storage-custom-image__text'>
                        {previewType === 'video'
                          ? 'Align Video'
                          : previewType === 'image'
                          ? 'Align Image'
                          : previewType === 'youtube'
                          ? 'Align Video'
                          : ''}
                      </Text>
                      <Switch
                        className='media-storage-custom-image__switch'
                        onChange={setIsAlignImage}
                      />
                    </Space>
                  </Col>
                </Row>
                <Row gutter={[10, 10]} style={{ paddingLeft: 5 }}>
                  <Col xs={24}>
                    <Checkbox
                      checked={align === 'left'}
                      onChange={(e) => setAlignConfig(e, 'left')}
                      disabled={!isAlign}
                    >
                      <Text className='media-storage-custom-image__text'>
                        left
                      </Text>
                    </Checkbox>
                    <Checkbox
                      disabled={!isAlign}
                      checked={align === 'right'}
                      onChange={(e) => setAlignConfig(e, 'right')}
                    >
                      <Text className='media-storage-custom-image__text'>
                        right
                      </Text>
                    </Checkbox>
                  </Col>
                </Row>

                <SliderNumber
                  label='Space'
                  min={0}
                  max={60}
                  labelStyle={{ color: 'white' }}
                  value={typeof space === 'number' ? space : 0}
                  disabled={!isAlign || !align}
                  onChange={onChangeSpace}
                />
              </MarkdownGroupInput>
              <MarkdownGroupInput
                title='Size Adjustment'
                style={{ marginBottom: 10 }}
              >
                <SliderNumber
                  label='Width'
                  min={1}
                  max={100}
                  labelStyle={{ color: 'white' }}
                  value={typeof space === 'number' ? Math.floor(width) : 0}
                  onChange={onChange}
                />

                <SliderNumber
                  label='Height'
                  min={1}
                  max={100}
                  labelStyle={{ color: 'white' }}
                  value={typeof height === 'number' ? Math.floor(height) : 0}
                  onChange={onChangeHeight}
                  disabled={previewType === 'youtube'}
                />

                <Row style={{ marginTop: 12, paddingLeft: 5 }}>
                  <Col xs={24}>
                    <Checkbox
                      disabled={
                        isAlwaysKeepScale ||
                        previewType === 'video' ||
                        previewType === 'youtube'
                      }
                      checked={isKeepRatio}
                      onChange={(e) => {
                        toggleKeepRatio(e);
                      }}
                    >
                      <Text className='media-storage-custom-image__text'>
                        keep original ratio
                      </Text>
                    </Checkbox>
                  </Col>
                </Row>
              </MarkdownGroupInput>
              <Row style={{ marginTop: 21, paddingLeft: 5 }}>
                <Col>
                  <CopyToClipboard
                    text={generateMarkDownContent()}
                    onCopy={() => {
                      message.success({
                        content: <Text>Copied Image Successfully</Text>,
                      });
                    }}
                  >
                    <Button
                      type='primary'
                      disabled={
                        previewType === 'youtube' && youtubeLink === ''
                          ? true
                          : false
                      }
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>
                </Col>
                <Col offset={1}>
                  <Button
                    type='primary'
                    onClick={() => handleInsert()}
                    disabled={
                      previewType === 'youtube' && youtubeLink === ''
                        ? true
                        : false
                    }
                  >
                    Insert
                  </Button>
                </Col>
                <Col xs={24} style={{ marginTop: 10 }}>
                  <Text className='media-storage-custom-image__text'>
                    * Hint: press bottom right corner to resize image
                  </Text>
                </Col>
              </Row>
            </Col>

            <MarkdownMediaCustomPreview
              isAlign={isAlign}
              align={align}
              space={space}
              width={width}
              height={height}
              maxWidth={maxWidth}
              handleResize={handleResize}
              isKeepRatio={isKeepRatio}
              ratio={ratio}
              fileInfo={
                previewType !== 'youtube' ? fileInfo : { url: youtubeLink }
              }
              previewType={previewType}
              getAdditionalParagraph={getAddParagraph}
            />
          </Row>
        </Modal>
      )}
    </React.Fragment>
  );
};

MarkDownMediaCustomModal.propTypes = {
  fileInfo: PropTypes.object,
  isCustomImageModalOpen: PropTypes.bool,
  setIsCustomImageModalOpen: PropTypes.func,
  maxWidth: PropTypes.number,
  mdEditor: PropTypes.object,
};

export default MarkDownMediaCustomModal;
