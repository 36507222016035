import React, { useState } from 'react';

import { FolderAddOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/recipients-fields';

import { RibbonButton } from 'common/components';
import { SaveRequiredFields } from './ShareComponent';

const CreateField = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        size='small'
        icon={<FolderAddOutlined />}
        label={Messages.createField}
        onClick={() => setVisible(true)}
      />

      {visible && (
        <SaveRequiredFields
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default CreateField;
