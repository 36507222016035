import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components/index';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as helpMaintenanceActions from 'pages/maintenance/controllers/actions';
import * as helpMaintenanceSelectors from 'pages/maintenance/controllers/selectors';
import * as selectorsGlobal from 'redux/global/selectors';

import * as helpMaintenanceService from 'services/helpMaintenance';

import { useFilterGrid } from 'hooks/useFilterGrid';

import Messages from 'i18n/messages/maintenance';
import { injectIntl } from 'react-intl';

const DeletePost = ({ intl }) => {
  const dispatch = useDispatch();

  const pageSizePagination = useSelector(
    helpMaintenanceSelectors.selectPageSizePost()
  );
  const pageNumberPagination = useSelector(
    helpMaintenanceSelectors.selectPageNumberPost()
  );
  const searchAllItems = useSelector(selectorsGlobal.selectSearchAllItems());

  const selectedPostDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  useFilterGrid({
    clearOtherFilter: true,
  });

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  const callApiDelete = async () => {
    const postIds = selectedPostDetail.map((postId) => {
      return postId.id;
    });

    try {
      const response = await helpMaintenanceService.deleteHelpPosts({
        postIds,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        dispatch(
          helpMaintenanceActions.getPostList(
            pageSizePagination,
            pageNumberPagination,
            searchAllItems
          )
        );
        showSuccessMessageDialog();
      } else {
        callApiErrorHandler(message);
      }
    } catch (error) {
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  return (
    <RibbonButton
      size='small'
      icon={<DeleteOutlined />}
      label={Messages.deletePosts}
      onClick={confirmDelete}
      disabled={selectedPostDetail.length === 0}
    />
  );
};

export default injectIntl(DeletePost);
