import React from 'react';
import Messages from 'i18n/messages/home';
import { DeleteOutlined } from '@ant-design/icons';
// component
import { dialogFunction, CustomNotification } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';
// redux
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'pages/ticketing-group/controllers/actions';
import * as selectors from 'pages/ticketing-group/controllers/selectors';
// hooks-query
import { useDeleteTicketGroup } from 'pages/ticketing-group/hooks-query/useTicketingGroup';
// lodash
import { isEmpty } from 'lodash';

const DeleteTicketingGroup = () => {
  const dispatch = useDispatch();
  const deleteTicketGroup = useDeleteTicketGroup();
  const selectedGroup = useSelector(selectors.makeSelectSelectedGroup());

  const handleDeleteModal = () => {
    dialogFunction({
      type: 'warn',
      content: `Are you sure you want to delete ticket group ${selectedGroup?.name}?`,
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: onDelete,
    });
  };

  const onDelete = () => {
    const params = { id: selectedGroup?.id };
    deleteTicketGroup.mutate(params, {
      onSuccess: (result) => {
        if (result?.isSuccess) {
          CustomNotification.success('Delete Ticket Group successfully.');
          dispatch(actions.selectedGroup(null));
        } else {
          CustomNotification.error(result?.message || 'Something went wrong!');
        }
      },
      onError: (error) => {
        CustomNotification.error('Something went wrong!');
      },
    });
  };

  return (
    <RibbonButton
      icon={<DeleteOutlined />}
      label={Messages.deleteTiketingGroup}
      onClick={handleDeleteModal}
      disabled={isEmpty(selectedGroup)}
    />
  );
};

export default DeleteTicketingGroup;
