import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Row, Col, Select, Input, Space } from 'antd';

import {
  Form,
  FormCancelButton,
  FormCreateButton,
  WrapperSelect,
  CustomNotification,
} from 'common/components';

import { qaSpecLinkProductToMSDSDocument } from 'services/product';
import * as constants from 'static/Constants';
import { sleep } from 'utils/delay';

import * as brandingActions from 'redux/branding/actions';

import { DOCUMENT_CERTIFICATE_LIMIT_CHARACTER } from 'pages/qa-spec/constant';

const layoutCol = {
  labelCol: {
    xxl: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xxl: { span: 12 },
    xl: { span: 12 },
  },
};

const CreateMSDSDocumentForm = (props) => {
  const { assetId, onReloadPage, productInfo, handleBackToCreateAsset } = props;

  const [loading, setLoading] = useState('');

  const [msdsDocForm] = Form.useForm();

  const dispatch = useDispatch();

  const specificInformationSectionView = () => {
    return (
      <Col span={24}>
        <>
          <Form.Item
            name='type'
            label='Type: '
            rules={[
              {
                required: true,
                message: 'Please select asset type',
              },
            ]}
          >
            <WrapperSelect disabled>
              {constants.ASSET_TYPE?.slice()
                .sort()
                .map((item, index) => {
                  return (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  );
                })}
            </WrapperSelect>
          </Form.Item>

          <Form.Item
            name='subtype'
            label='Sub Type: '
            rules={[
              {
                required: true,
                message: 'Please select asset subtype',
              },
            ]}
          >
            <WrapperSelect disabled={true}>
              {['MSDS'].map((item, index) => {
                return (
                  <Select.Option value={item} key={index}>
                    {item}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </Form.Item>
        </>

        <Form.Item name='documentName' label='Document Name'>
          <Input
            maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.documentType}
          />
        </Form.Item>

        <Form.Item name='documentType' label='Document Type' hidden>
          <Input
            maxLength={DOCUMENT_CERTIFICATE_LIMIT_CHARACTER.documentType}
            showCount
          />
        </Form.Item>
      </Col>
    );
  };

  const createMSDSDoc = () => {
    msdsDocForm
      .validateFields()
      .then(async () => {
        let documentName = msdsDocForm.getFieldValue('documentName');

        setLoading('add');

        const qaSpecLinkDocumentParams = {
          digitalAssetId: assetId,
          productItemId: productInfo.productId,
          documentName: documentName,
        };

        const linkResponse = await qaSpecLinkProductToMSDSDocument(
          qaSpecLinkDocumentParams
        );

        //* BE doest not update link data instantly
        await sleep(1000);

        if (linkResponse.isSuccess) {
          CustomNotification.success(
            'Link data has been updated successfully!'
          );
          onReloadPage && onReloadPage();
        } else {
          CustomNotification.error('Link data has been updated failed!');
        }
        setLoading('');
        dispatch(brandingActions.getBrandingNoLoading());
      })
      .catch((e) => {
        if (e?.errorFields?.length > 0) {
          CustomNotification.error('Please fill out all required fields');
        } else {
          CustomNotification.error('Failed to submit form');
        }
      });
  };

  return (
    <Form
      form={msdsDocForm}
      initialValues={{
        type: 'Document',
        subtype: 'MSDS',
      }}
      {...layoutCol}
      id='msds-form'
    >
      <Row gutter={8}>{specificInformationSectionView()}</Row>

      <Row>
        <Col flex='auto' />
        <Col>
          <Space>
            <FormCancelButton
              text={' Back to asset creation'}
              icon={<ArrowLeftOutlined />}
              onClick={() =>
                handleBackToCreateAsset && handleBackToCreateAsset()
              }
              disabled={loading}
            />

            <FormCreateButton
              text='Complete setup'
              onClick={createMSDSDoc}
              loading={loading === 'add'}
            />
          </Space>
        </Col>
        <Col xs={2} />
      </Row>
    </Form>
  );
};

export default injectIntl(CreateMSDSDocumentForm);
