import React, { useState } from 'react';
import { Typography, Modal, Button } from 'antd';

const { Paragraph, Text } = Typography;

function ErrorModal({ errorList, isVisible, title }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {errorList?.length !== 0 && (
        <div>
          <Paragraph
            ellipsis={{
              rows: 3,
            }}
            type='danger'
          >
            {errorList?.[0]}
          </Paragraph>
          {errorList?.length > 1 && (
            <Text style={styles.showMore} onClick={() => setShowModal(true)}>
              Show more
            </Text>
          )}
        </div>
      )}
      {showModal && (
        <Modal
          title={title}
          centered
          visible={showModal}
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          width={1000}
          destroyOnClose
          footer={
            <Button key='ok' type='primary' onClick={() => setShowModal(false)}>
              Ok
            </Button>
          }
          bodyStyle={{ height: '70vh', overflow: 'auto' }}
        >
          <ul>
            {errorList?.map((message) => (
              <li>
                <Paragraph type='danger' copyable={{ tooltips: false }}>
                  {message}
                </Paragraph>
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </>
  );
}

export default ErrorModal;

const styles = {
  showMore: {
    cursor: 'pointer',
    color: '#1890ff',
    float: 'right',
  },
};
