import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Input, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';
import { Form, StyledModal, RibbonSection } from 'common/components';

import * as roleActions from 'pages/role/controllers/actions';
import userSelectors from 'redux/user/selectors';

import * as rolesServices from 'services/roles-security';

import { validateRoleName } from 'pages/role/mappers/validateRoleFormFields';

import { apiHandler } from 'utils/api';
import { forwardTo } from 'utils/common/route';

const CreateNewRoleSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [formInstance] = Form.useForm();

  const dispatch = useDispatch();

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const memberIdOfUser = userInfo?.member?.id;

  const regex = /\/security\/member\//;

  const pathName = window.location.pathname;

  const memberIdParam = pathName.match(regex)
    ? pathName.split(regex).filter(Boolean)?.[0]
    : null;

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleClick = () => toggleModal();

  const onCancel = () => toggleModal();

  const onOk = async () => {
    await formInstance
      .validateFields()
      .then(async (value) => {
        let params = {
          displayName: value?.roleName,
          description: '',
          permissions: [],
          memberId: memberIdParam || memberIdOfUser,
        };

        const successMessage = 'Create new role successfully';
        const errorMessage = 'Failed to create new role';

        const saveRoleCallback = (data) => {
          const responseRoleId = data?.roleId;
          let redirectLink = '';

          dispatch(
            roleActions.getRoleList({
              memberId: memberIdParam || memberIdOfUser,
            })
          );

          if (memberIdParam) {
            redirectLink = `/security/role/${responseRoleId}/member/${memberIdParam}/edit`;
          } else {
            redirectLink = `/security/role/${responseRoleId}/edit`;
          }
          forwardTo(redirectLink);
          setIsLoading(false);
          onCancel();
        };

        setIsLoading(true);

        await apiHandler({
          service: rolesServices.editRole,
          params,
          successMessage,
          errorMessage,
          successCallback: saveRoleCallback,
          onFinally: () => {
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
          },
        });
      })
      .catch((error) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      });
  };

  return (
    <RibbonSection>
      <RibbonButton
        icon={<UserAddOutlined style={{ height: 26 }} />}
        label={'Create new role'}
        onClick={handleClick}
      />
      {isModalOpen && (
        <StyledModal
          visible={isModalOpen}
          title={`Please input new role's name`}
          maskClosable={false}
          footer={null}
          onCancel={onCancel}
        >
          <Form form={formInstance}>
            <Form.Item
              name='roleName'
              label={`New role's name`}
              rules={[
                () => ({
                  validator(rule, value) {
                    const errors = validateRoleName(value);

                    if (errors.length > 0) {
                      return Promise.reject(errors?.[0]);
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input placeholder='please input role name' />
            </Form.Item>
            <Form.Item
              style={{
                textAlign: 'right',
                marginTop: 6,
              }}
            >
              <Button
                type='default'
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                onClick={onOk}
                loading={isLoading}
                style={{ marginLeft: 6 }}
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </StyledModal>
      )}
    </RibbonSection>
  );
};

export default CreateNewRoleSection;
