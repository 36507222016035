import React, { useState } from 'react';
import { Row, Empty } from 'antd';
import { EnhancedMarkdown } from 'common/components';

const alternativeCompanyTagLine = 'this is sample of tagline content'; // todo: remove this

const MemberTagLine = (props) => {
  const { content, refSource, ...restProps } = props;
  return (
    <Row className='scroller-extend scroller--x'>
      {content ? (
        <EnhancedMarkdown
          source={content || alternativeCompanyTagLine}
          refSource={refSource}
          {...restProps}
        />
      ) : (
        <Empty style={{ margin: 'auto' }} />
      )}
    </Row>
  );
};

export default React.memo(MemberTagLine);
