import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ContactsOutlined,
  MailOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { Space, Modal, Typography } from 'antd';
import './ContactActions.less';

const { Paragraph } = Typography;
const ContactActions = (props) => {
  // props
  const { title, alignment, className } = props;
  // state
  const [showContact, setShowContact] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const sendContact = () => {
    setShowContact(true);
  };

  const sendEmail = () => {
    setShowEmail(true);
  };

  const sendMessage = () => {
    setShowMessage(true);
  };

  const handleCancelContact = (e) => {
    setShowContact(false);
  };
  const handleCancelEmail = (e) => {
    setShowEmail(false);
  };
  const handleCancelMessage = (e) => {
    setShowMessage(false);
  };

  return (
    <div
      className={`contact-action__container contact-action__container--align-${alignment} ${className}`}>
      {title && (
        <Paragraph className='contact-action__title'>{title}:</Paragraph>
      )}
      <Space direction='horizontal' className='contact-action__contact-list'>
        <ContactsOutlined
          className='contact-action__contact-list-item'
          onClick={sendContact}
        />
        <MailOutlined
          className='contact-action__contact-list-item'
          onClick={sendEmail}
        />
        <MessageOutlined
          className='contact-action__contact-list-item'
          onClick={sendMessage}
        />
      </Space>

      <Modal
        title='Send Contact Card'
        visible={showContact}
        onCancel={handleCancelContact}>
        <p>contact action</p>
      </Modal>
      <Modal
        title='Send Email'
        visible={showEmail}
        onCancel={handleCancelEmail}>
        <p>send mail</p>
      </Modal>
      <Modal
        title='Send Message'
        visible={showMessage}
        onCancel={handleCancelMessage}>
        <p>send message</p>
      </Modal>
    </div>
  );
};

ContactActions.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
};

ContactActions.defaultProps = {
  alignment: 'center',
};
export default ContactActions;
