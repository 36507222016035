// Format Size Units
export const formatSizeUnits = (bytes) => {
  if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    bytes = bytes + ' bytes';
  } else if (bytes === 1) {
    bytes = bytes + ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
};

export const formatSizeUnitByParam = (param) => {
  return param ? formatSizeUnits(param?.value) : '';
};

export const calculateToPercent = (num) => {
  return Math.round(num * 100);
};

export const calculateToPercentByParam = (param) => {
  return param ? `${calculateToPercent(param?.value)}%` : '';
};
