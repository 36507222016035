import React from 'react';

import {
  Form,
  Input,
  InputNumber,
  Modal,
  Button,
  Collapse,
  Col,
  Typography,
  Row,
  Checkbox,
  DatePicker,
  Tooltip,
  Spin,
} from 'antd';

import {
  CloseCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { checkRenderTextArea } from './utils';

import { useFormContext } from './FormContext';

import { DATE_FORMAT } from 'static/Constants';

import classnames from 'classnames';



const { Text } = Typography;

const LabelFormItem = ({ label, tooltip }) => {
  const { searchModuleName } = useFormContext();

  const labelOfFoundField = label === searchModuleName;

  return (
    <>
      <Typography.Text
        ellipsis
        className={classnames({
          'product-detail-view__label-form-item': true,
          'product-detail-view__label-form-item--found-field': labelOfFoundField,
        })}
        strong
      >
        {label}
      </Typography.Text>

      {tooltip && (
        <Tooltip title={tooltip}>
          <QuestionCircleOutlined className='product-detail-view__hint-button' />
        </Tooltip>
      )}
    </>
  );
};

const getPropsFormItem = (property) => {
  if (!property) return null;

  return {
    name: property.propertyName,
    label: property.propertyDisplayName ? (
      <LabelFormItem
        label={property.propertyDisplayName}
        tooltip={property.propertyDescription}
      />
    ) : null,
  };
};

const FormItemText = ({ property, placeholder, maxLength, ...otherProps }) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      <Input
        placeholder={
          placeholder
            ? placeholder
            : `Please Input ${property.propertyDisplayName}`
        }
        showCount={maxLength ? true : false}
        maxLength={maxLength}
      />
    </FormItemContainer>
  );
};

const FormItemTextArea = ({
  property,
  placeholder,
  maxLength,
  ...otherProps
}) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      <Input.TextArea
        placeholder={
          placeholder
            ? placeholder
            : `Please Input ${property.propertyDisplayName}`
        }
        rows={4}
        showCount={maxLength ? true : false}
        maxLength={maxLength}
      />
    </FormItemContainer>
  );
};

const FormItemNumber = ({
  property,
  placeholder,
  maxLength,
  ...otherProps
}) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      {maxLength ? (
        <InputNumber
          placeholder={
            placeholder
              ? placeholder
              : `Please Input ${property.propertyDisplayName}`
          }
          step='0.1'
          stringMode
        />
      ) : (
        <InputNumber
          placeholder={
            placeholder
              ? placeholder
              : `Please Input ${property.propertyDisplayName}`
          }
        />
      )}
    </FormItemContainer>
  );
};

const FormItemBoolean = ({ property, onChange, ...otherProps }) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      <Checkbox onChange={onChange} />
    </FormItemContainer>
  );
};

const FormItemDatetime = ({ property, ...otherProps }) => {
  return (
    <FormItemContainer property={property} {...otherProps}>
      <DatePicker format={DATE_FORMAT.SYSTEM_FORMAT} />
    </FormItemContainer>
  );
};

const FormItemContainer = ({ property, children, ...otherProps }) => {
  return (
    <Form.Item {...getPropsFormItem(property)} {...otherProps}>
      {children}
    </Form.Item>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 8 },
  },
};

const FormItemList = ({ property, variant = 'text', ...otherProps }) => {
  const { name = '', label = '' } = getPropsFormItem(property) ?? {};

  return (
    <Form.List name={name} {...otherProps}>
      {(fields, { add, remove }, formListParams) => {
        const errors = formListParams?.errors;
        return (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? label : ''}
                required={false}
                key={field.key}
              >
                <Form.Item {...field} noStyle>
                  {variant === 'text' ? (
                    checkRenderTextArea(property.propertyDisplayName) ? (
                      <Input.TextArea
                        placeholder={`Please Input ${property.propertyDisplayName}`}
                        rows={4}
                        autoFocus
                      />
                    ) : (
                      <Input
                        placeholder={`Please Input ${property.propertyDisplayName}`}
                        autoFocus
                      />
                    )
                  ) : (
                    <InputNumber
                      placeholder={`Please Input ${property.propertyDisplayName}`}
                      autoFocus
                    />
                  )}
                </Form.Item>

                {fields.length > 0 ? (
                  <CloseCircleOutlined
                    className='product-detail-view__delete-icon'
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}

            <Form.Item label={label}>
              <Button onClick={() => add()} icon={<PlusOutlined />}>
                Add value
              </Button>
              <div id={'#form-list__' + name}>
                <Text className='ant-form-item-explain-error'>
                  {errors && errors?.[0]}
                </Text>
              </div>
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

const ModalForm = ({ children, ...otherProps }) => {
  return (
    <Modal
      title='Edit Information'
      width={'70vw'}
      bodyStyle={{ maxHeight: '80vh' }}
      closable={true}
      maskClosable={false}
      destroyOnClose
      centered
      {...otherProps}
    >
      {children}
    </Modal>
  );
};

const RowContainer = ({ children, ...otherProps }) => {
  return <Row {...otherProps}>{children}</Row>;
};

const ColumnContainer = ({ children, ...otherProps }) => {
  return (
    <Col span={12} {...otherProps}>
      {children}
    </Col>
  );
};

const TextInfo = ({ children, className, ...otherProps }) => {
  return (
    <Typography.Text
      className={classnames('product-detail-view__text-info', className)}
      {...otherProps}
    >
      {children}
    </Typography.Text>
  );
};

const ItemTextValue = ({ value, strong, ...otherProps }) => {
  return (
    <ColumnContainer {...otherProps}>
      <TextInfo strong={strong} ellipsis>
        {value}
      </TextInfo>
    </ColumnContainer>
  );
};

const CollapseView = ({
  children,
  headerPanel,
  keyPanel,
  extraPanel,
  ...otherProps
}) => {
  return (
    <Collapse
      bordered={false}
      ghost
      expandIconPosition='right'
      className='product-detail-view__collapse'
      {...otherProps}
    >
      <Collapse.Panel
        header={headerPanel}
        key={keyPanel}
        extra={typeof extraPanel === 'function' && extraPanel()}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

const Loading = ({ style, ...otherProps }) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      {...otherProps}
    >
      <Spin indicator={loadingIcon} />
    </div>
  );
};

export {
  ColumnContainer,
  RowContainer,
  FormItemList,
  FormItemBoolean,
  FormItemText,
  FormItemNumber,
  FormItemDatetime,
  FormItemContainer,
  TextInfo,
  ModalForm,
  CollapseView,
  Loading,
  ItemTextValue,
  formItemLayout,
  formItemLayoutWithOutLabel,
  FormItemTextArea,
};
