import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import FlipMove from 'react-flip-move';
import { List, Avatar, Typography, Spin, Badge } from 'antd';
import {
  DownOutlined,
  LoadingOutlined,
  HomeOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';

import { WithLoading } from 'common/components';

import * as chatActions from '../../controller/actions';
import useGetThreadAndMsgInfo from '../../controller/hooks';
import * as globalActions from 'redux/global/actions';

import { exportToChatTime } from 'utils/formatDate';
import { formatMessage } from 'utils/string';
import { sleep } from 'utils/delay';

import './Thread.less';
import { CHATTYPE } from 'static/Constants';

const { Paragraph } = Typography;

const CommunicationList = (props) => {
  const dispatch = useDispatch();
  const {
    communicationListData,
    communicationItemData,
    getThreadListMore,
    communicationListLoading,
    communicationListHasMore,
    communicationListLoadingMore,
    setView,
  } = props;

  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [userId, setUserId] = useState(null);

  useGetThreadAndMsgInfo(currentThreadId, userId);

  const handleClickCommunicationItem = async (communicationItem) => {
    // Make Thread as read
    const { threadId, userId, readStatus } = communicationItem;
    const payload = { userId, threadId };

    setView && setView('threadMsg');

    // dispatch(chatActions.getThreadInfoSuccess(null));
    // await sleep(500);

    if (!readStatus) {
      dispatch(globalActions.makeAThreadAsRead(payload));
    }

    setCurrentThreadId(communicationItem?.threadId);
    setUserId(communicationItem?.userId);
    onUpdateReadStatusForThread(communicationItem?.threadId);
    dispatch(chatActions.updateShowThreadWarningModal(true));
    dispatch(chatActions.toggleNewMessage(true));
    dispatch(chatActions.saveActiveThreadInfo(communicationItem));
    dispatch(chatActions.setUnreadMessages([])); // change between thread in Maximize view
  };

  const onUpdateReadStatusForThread = (threadId) => {
    let result =
      communicationListData?.length > 0 ? [...communicationListData] : [];

    if (threadId) {
      let existedThreadIndex = result?.findIndex(
        (thread) => thread.threadId === threadId
      );
      if (existedThreadIndex > -1) {
        let threadUpdate = {
          ...communicationListData[existedThreadIndex],
          readStatus: true,
        };
        result[existedThreadIndex] = threadUpdate;

        dispatch(chatActions.updateCommunicationThreadList(result));
      }
    }
  };

  const handleLoadMore = (page) => {
    getThreadListMore(page);
  };

  const handleFormatLatestText = (text) => {
    if (text?.lastMessageType === 2) {
      return (
        <span>
          <IdcardOutlined /> {text?.lastMessageText}
        </span>
      );
    } else {
      return formatMessage(text?.lastMessageText).replace(/<[^>]*>?/gm, '');
    }
  };

  return (
    <>
      {communicationListLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <WithLoading
            loading={communicationListLoading}
            indicator={<LoadingOutlined />}
          />
        </div>
      ) : (
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={handleLoadMore}
          hasMore={communicationListHasMore && !communicationListLoadingMore}
          useWindow={false}
        >
          <List className='communication-list' itemLayout='horizontal'>
            <FlipMove
              staggerDurationBy='30'
              duration={500}
              enterAnimation={'none'}
              leaveAnimation={'none'}
              typeName='div'
            >
              {communicationListData?.length > 0 &&
                communicationListData.map((thread) => (
                  <div key={thread?.uniqueId}>
                    <List.Item
                      className={{
                        active: thread?.threadId === communicationItemData?.id,
                      }}
                      onClick={() => handleClickCommunicationItem(thread)}
                      actions={[
                        <div className='communication-list__item-status'>
                          <div className='communication-list__item-last-time'>
                            {exportToChatTime(thread?.lastMessageTime)}
                          </div>
                          {thread.readStatus === false && (
                            <div className='communication-list__notify'>
                              <div className='communication-list__item-dot' />
                              <p className='communication-list__item-unread'>
                                unread message
                              </p>
                            </div>
                          )}
                        </div>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <>
                            {thread?.userType === CHATTYPE.COMPANY ? (
                              <Badge
                                count={<HomeOutlined />}
                                offset={[-5, 40]}
                                className='communication-list__badge'
                              >
                                <div className='communication-list__avatar-wrapper'>
                                  <Avatar src={thread?.avatarUrl} size={38}>
                                    {thread?.name?.charAt(0)}
                                  </Avatar>
                                </div>
                              </Badge>
                            ) : (
                              <div className='communication-list__avatar-wrapper'>
                                <Avatar src={thread?.avatarUrl} size={38}>
                                  {thread?.name?.charAt(0)}
                                </Avatar>
                              </div>
                            )}
                          </>
                        }
                        title={
                          <Paragraph
                            style={{ marginBottom: 0 }}
                            ellipsis={true}
                          >
                            {thread?.name}
                          </Paragraph>
                        }
                        description={handleFormatLatestText(thread)}
                      />
                    </List.Item>
                  </div>
                ))}
            </FlipMove>
            <div className='communication-list__scroll-container'>
              {communicationListLoadingMore && <Spin />}
              {communicationListHasMore && !communicationListLoadingMore && (
                <>
                  <p>Scroll to show more</p>
                  <DownOutlined
                    style={{ fontSize: '15px', color: '#1d3557' }}
                  />
                </>
              )}
            </div>
          </List>
        </InfiniteScroll>
      )}
    </>
  );
};

export default CommunicationList;
