import React from 'react';

import { put, call, takeLatest, select, delay } from 'redux-saga/effects';

import {
  OverviewLocationsIcon,
  UserActivityIcon,
} from 'pages/user-profile/components/index';

import * as actions from './actions';
import selectors from './selectors';
import * as globalActions from 'redux/global/actions';
import * as gridViewActions from 'common/components/grid-view/controllers/actions';

import {
  getUserProfile,
  getUserProfileHeader,
  getUserProfileLocations,
  getAllArticles,
} from 'services/user';

import * as workflowServices from 'services/workflow';
import * as services from 'services/login';
import * as servicesGrid from 'services/grid';
import * as extMeetingServices from 'services/ext-meeting';

import { forwardTo } from 'utils/common/route';
import { setSession, setAnnoInfo, clearAnnoInfo } from 'utils/common/session';
import { getMfaHash } from 'utils/mfa';

import * as types from './constants';

import loginMessages from 'i18n/messages/login';
import userMessages from 'i18n/messages/user';
import { FormattedMessage } from 'react-intl';

import { CustomNotification, dialogFunction } from 'common/components';

import moment from 'moment';
import { getParameterFromQuery, postFlowRedirect } from 'utils/common/route';
import { LOCKED_ACCOUNT_MESSAGE } from 'static/Constants';
import { clearToken } from 'utils/common/session';

import store from 'index';

const formatMessage = (message) => {
  return <FormattedMessage {...message} />;
};

function* loginHandlerSaga({
  response,
  returnUrl,
  isOtpLogin = false,
  isUnlockLogin = false,
  isSSO = false,
}) {
  try {
    if (!response) return;

    const currentUserInfo = yield select(selectors.makeSelectUserInfo());

    const { data } = response || {};

    const { access_token, expires_in, refresh_token } = data;
    const expires = parseInt(expires_in);
    //* store toke
    yield call(setSession, access_token, expires, refresh_token);

    const userInfoResponse = yield call(services.getUserInfo);
    const userInfo = userInfoResponse && userInfoResponse.data;
    if (userInfo) {
      //* clear annon info if needed
      clearAnnoInfo();
      // store in redux
      yield put(actions.loginSuccess(userInfo));
      if (!userInfo?.isImpersonating) localStorage.removeItem('impersonate');

      if (currentUserInfo?.id && userInfo?.id !== currentUserInfo?.id) {
        yield put(globalActions.updateProgressBarSuccess([]));
      }

      //? clear all saved pagination grid info
      yield put(gridViewActions.resetPageCurrent());
      // redirect to next process
      if (isOtpLogin) {
        yield call(postFlowRedirect, 'login-otp', returnUrl, store.dispatch);
        yield delay(500);
        yield put(actions.updateMfaLoginInfo(null));
      } else if (isUnlockLogin) {
        yield call(forwardTo, `/waiting?returnUrl=${returnUrl}`);
        yield put(actions.updateUnlockAccountLoginInfo(null));
      } else if (isSSO) {
        /**
         * ! pending - duy - under development
         */
      } else {
        // yield call(handl)
        yield call(forwardTo, `/waiting?returnUrl=${returnUrl}`);
      }
    } else {
      // show error if cannot get user info
      const getUserInfoErrMsg = formatMessage(loginMessages.getUserInfoError);
      yield put(actions.loginFailure(getUserInfoErrMsg));
    }
  } catch (errors) {
    console.log('errors', errors);
  }
}

export function* loginSaga(payload) {
  try {
    //* importart -- clear mfa Login info as long as login form submmited
    yield put(actions.updateMfaLoginInfo(null));

    const hashToken = getMfaHash(payload?.user?.userName);

    const params = {
      ...payload?.user,
      browserHash: hashToken,
    };

    const response = yield call(services.loginService, params);
    let returnUrl = getParameterFromQuery('returnUrl') || '/';

    let { isSuccess, message, data } = response || {};
    let { requireMfa, mfaPhoneNumber, loginSession, loginSessionExpireAt } =
      data || {};

    if (!isSuccess) {
      const isAccountLocked = message === LOCKED_ACCOUNT_MESSAGE;

      if (isAccountLocked) {
        yield call(clearToken);
        yield put(actions.toggleLoading(false));
        yield call(dialogFunction, {
          type: 'warn',
          content: 'Your account has been locked. Please unlock your account.',
          okText: 'Unlock Account',
          okButtonProps: {
            type: 'danger',
          },
          onOk: () => {
            store.dispatch(actions.startFlowOfUnlockAccount(payload));
          },
        });
      } else {
        yield put(actions.loginFailure(message));
      }
    } else if (requireMfa) {
      yield call(clearToken);
      const mfaLoginInfo = {
        mfaPhoneNumber,
        loginSession,
        loginSessionExpireAt,
        userName: payload?.user?.userName,
      };
      yield put(actions.updateMfaLoginInfo(mfaLoginInfo));
      yield put(actions.toggleLoading(false));
      yield put(actions.loginSuccess(null));

      yield call(forwardTo, `/waiting?returnUrl=${returnUrl}`);

      return;
    } else {
      yield call(loginHandlerSaga, { response, returnUrl });
    }
  } catch (error) {
    // error handling
    const loginError = formatMessage(loginMessages.loginError);
    yield put(actions.loginFailure(loginError));
  }
}

function* startUnlockAccountFlowSaga({ payload }) {
  const { userName } = payload?.user || {};

  const unlockAccountLoginInfo = {
    userName,
  };
  yield put(actions.updateUnlockAccountLoginInfo(unlockAccountLoginInfo));
  yield put(actions.loginSuccess(null));
  yield delay(1000);
  yield call(forwardTo, `/unlock-account`);
}

export function* loginWithOtpSaga({ payload }) {
  yield call(loginHandlerSaga, { ...payload, isOtpLogin: true });
}

export function* loginWithUnlockAccountSaga({ payload }) {
  yield call(loginHandlerSaga, { ...payload, isUnlockLogin: true });
}

/**
 * ! pending - Duy - under development
 * @param {*} param0
 */
export function* loginWithSso({ payload }) {
  let ssoToken = getParameterFromQuery('token');
}

export function* anonLoginSaga(action) {
  try {
    const params = action?.payload;
    const { meetingUniqueId, ...userBasicInfo } = params;

    const response = yield call(extMeetingServices.anonLoginForMeeting, params);

    const { isSuccess, data } = response;

    if (!isSuccess) {
      const { message } = response;
      yield call(CustomNotification.error, message);
    } else {
      const anonUserInfo = {
        ...userBasicInfo,
        ...data,
        isAnonymous: true,
        href: window.location.href,
      };

      yield put(
        actions.updateUserInfo({
          ...anonUserInfo,
          id: data?.userChatId,
          member: { id: null },
        })
      );

      yield call(setAnnoInfo, anonUserInfo);
    }
  } catch (error) {
    yield call(CustomNotification.error, 'Failed to login as a guest');
  }
}

// get user workflow roles
export function* getUserWorkflowRolesSaga() {
  try {
    const response = yield call(workflowServices.getUserWorkflowRolesService);
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getUserWorkflowRolesSuccess(data));
    } else {
      yield put(actions.getUserWorkflowRolesError(message));
    }
  } catch (error) {
    yield put(actions.getUserWorkflowRolesError(error));
  }
}

export function* userProfileSaga() {
  const errMsg = formatMessage(userMessages.getProfileError);
  try {
    const userProfile = yield call(getUserProfile);
    if (!userProfile) {
      yield put(actions.getUserProfileFailure(errMsg));
    } else {
      yield put(actions.getUserProfileSuccess(userProfile));
    }
  } catch (error) {
    // error handling
    yield put(actions.getUserProfileFailure(errMsg));
  }
}

export function* getUserProfileHeaderSaga(payload) {
  try {
    const params = {
      userId: Number(payload.userId),
    };
    const response = yield call(getUserProfileHeader, params);
    if (response.isSuccess) {
      yield put(actions.getUserProfileHeaderSuccess(response.data));
    } else {
      yield put(actions.getUserProfileHeaderFailure(response.message));
    }
  } catch (error) {
    yield put(actions.getUserProfileHeaderFailure(error));
  }
}

/***********************************************************/
// user locations
export function* userProfileLocationsSaga(payload) {
  const errMsg = formatMessage(userMessages.getProfileLocationsError);
  try {
    const response = yield call(getUserProfileLocations, {
      ...payload,
      userId: Number(payload.userId),
    });
    if (!response) {
      yield put(actions.getUserProfileLocationsFailure(errMsg));
    } else {
      yield put(actions.getUserProfileLocationsSuccess(response));
    }
  } catch (error) {
    // error handling
    yield put(actions.getUserProfileLocationsFailure(errMsg));
  }
}

const locationIcon = (props) => {
  return <OverviewLocationsIcon value={props.data.isPrimaryAddress} />;
};

function dateFormatter(params) {
  return moment(params.value).format('MM/DD/YYYY');
}

export function* userProfileLocationsGridColumnInfo(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 50,
        resizable: true,
      },
      {
        field: '',
        filter: false,
        isShowFilter: 'none',
        suppressMenu: true,
        width: 60,
        resizable: true,
        cellRenderer: locationIcon,
        // valueGetter: function (params) {
        //   return params.data.isPrimary;
        // },
      },
    ];
    if (response?.columns?.length > 0) {
      let hiddenCol = [
        'id',
        'isPrimaryAddress',
        'status',
        'addressType',
        'latitude',
        'longitude',
      ];
      response.columns.forEach((col, index) => {
        if (hiddenCol.indexOf(col.fieldNameCamelCase) === -1) {
          col = { ...col, flex: 1, resizable: true };
          columns.push(col);
        }
      });
    }
    yield put(actions.userProfileLocationsGridColumnInfoSuccess(columns));
  } catch (error) {
    yield put(actions.userProfileLocationsGridColumnInfoError(error));
  }
}

/******************************************/
// user activity log
const activityLogIcon = (props) => {
  return <UserActivityIcon value={props.data.method} />;
};

export function* userProfileActivityLogsGridColumnInfo(payload) {
  try {
    const response = yield call(
      servicesGrid.gridColumnInfo,
      payload.gridNameActivityLogs
    );
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        width: 50,
        resizable: true,
      },
      {
        field: '',
        filter: false,
        isShowFilter: 'none',
        width: 60,
        cellRenderer: activityLogIcon,
        resizable: true,
        valueGetter: function (params) {
          return params.data.method;
        },
      },
    ];
    if (response.columns && response.columns.length > 0) {
      response.columns.forEach((val, index) => {
        if (val.fieldName === 'Date') {
          val = { ...val, cellRenderer: dateFormatter, resizable: true };
        }
        if (val.fieldName !== 'Id' && val.fieldName !== 'Date') {
          columns.push({ ...val, resizable: true });
        }
      });
    }
    yield put(actions.userProfileActivityLogsGridColumnInfoSuccess(columns));
  } catch (error) {
    yield put(actions.userProfileActivityLogsGridColumnInfoError(error));
  }
}

export function* sagaGetUserArticle() {
  try {
    const response = yield call(getAllArticles);
    if (response.isSuccess) {
      yield put(actions.getUserArticleSuccess(response?.data?.articles));
    } else {
      yield put(actions.getUserArticleFail('server error'));
    }
  } catch (err) {
    yield put(actions.getUserArticleFail(err));
  }
}

export default function* watchBranding() {
  yield takeLatest(types.LOGIN, loginSaga);
  yield takeLatest(types.lOGIN_WITH_OTP, loginWithOtpSaga);
  yield takeLatest(types.lOGIN_WITH_UNLOCK_ACCOUNT, loginWithUnlockAccountSaga);
  yield takeLatest(types.GET_USER_PROFILE, userProfileSaga);
  yield takeLatest(types.GET_USER_PROFILE_HEADER, getUserProfileHeaderSaga);
  yield takeLatest(types.GET_USER_PROFILE_LOCATIONS, userProfileLocationsSaga);
  yield takeLatest(
    types.USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO,
    userProfileLocationsGridColumnInfo
  );
  yield takeLatest(
    types.USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO,
    userProfileActivityLogsGridColumnInfo
  );
  yield takeLatest(types.GET_ALL_USER_ARTICLE, sagaGetUserArticle);
  yield takeLatest(types.GET_USER_WORKFLOW_ROLES, getUserWorkflowRolesSaga);
  yield takeLatest(types.ANON_LOGIN, anonLoginSaga);
  yield takeLatest(
    types.START_FLOW_OF_UNLOCK_ACCOUNT,
    startUnlockAccountFlowSaga
  );
}
