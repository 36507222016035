import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Col,
  Typography,
  Badge,
} from 'antd';

import { Form, WrapperSelect } from 'common/components';

import { CloseOutlined } from '@ant-design/icons';

import { useInjectReducer } from 'utils/common/injectedReducers';
import { useInjectSaga } from 'utils/common/injectSaga';

import { AgGrid, FormAddButton } from 'common/components';
import MemberThumbnailCard from 'pages/branded-members/components/card/MemberThumbnailCard';

import {
  MEMBER_DETAIL_GRID,
  MEMBER_DETAIL_GRID_DISTINCT,
} from 'services/members/endpoints';

import {
  GET_ALL_QUERIES_NOT_CURATED_QUERY_GRID,
  GET_ALL_QUERIES_NOT_CURATED_QUERY_GRID_DISTINCT,
} from 'services/markupMaintenance/endpoints';

import reducerMember from 'pages/branded-members/controllers/reducer';
import sagaMember from 'pages/branded-members/controllers/saga';
import { makeSelectColumns } from 'pages/branded-members/controllers/selectors';
import { gridMemberColumnInfo } from 'pages/branded-members/controllers/actions';

import usePrevious from 'hooks/usePrevious';

import { filterColumnsGrid, filterSelectedMembers } from './utils';

import { DATE_FORMAT } from 'static/Constants';

import './AddCuratedQueryConfig.less';

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const AddCuratedQueryConfig = (props) => {
  const {
    form,
    selectedQueryDetail,
    setSelectedQuery,
    selectedMembers,
    setSelectedMembers,
    shareType,
    setShareType,
    mode,
    memberRoles,
  } = props;

  useEffect(() => {
    if (mode === 'create') {
      form.setFieldsValue({
        curatedQueryShareType: 'MemberRole',
      });
    } else if (mode === 'edit' && selectedQueryDetail?.length > 0) {
      /* Keep memberType prevent errors from old data */
      if (selectedQueryDetail[0]?.curatedQueryShareType === 'MemberType') {
        setShareType('MemberRole');
        form.setFieldsValue({
          curatedQueryShareType: 'MemberRole',
        });
      } else {
        setShareType(selectedQueryDetail[0]?.curatedQueryShareType);
        form.setFieldsValue({
          curatedQueryShareType: selectedQueryDetail[0]?.curatedQueryShareType,
        });
      }
    }
  }, [form, mode]);

  return (
    <Row gutter={[2, 2]} className='scroller'>
      <Col span={shareType === 'SpecificMembers' ? 12 : 24}>
        {mode === 'create' && (
          <NonCuratedQuerySelection setSelectedQuery={setSelectedQuery} />
        )}
        <Form {...layout} form={form} className='create-grid-config__form'>
          <Form.Item name='curatedQueryShareType' label='Share Type'>
            <Radio.Group onChange={(e) => setShareType(e.target.value)}>
              <Radio value='MemberRole'>Member Role</Radio>
              <Radio value='SpecificMembers'>Pickup Members</Radio>
              <Radio value='AllMember'>All Member</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Keep memberType prevent errors from old data */}
          {shareType === 'MemberRole' || shareType === 'MemberType' ? (
            <Form.Item
              name='curatedQueryShareToMemberRoleId'
              label='Member Role'
            >
              <WrapperSelect placeholder='Select member role'>
                {memberRoles
                  ?.slice()
                  .sort()
                  .map((role) => (
                    <Select.Option
                      key={role?.memberRoleId}
                      value={role?.memberRoleId}
                    >
                      {role?.memberRoleName}
                    </Select.Option>
                  ))}
              </WrapperSelect>
            </Form.Item>
          ) : null}

          <Form.Item name='dateActive' label='Date Active'>
            <RangePicker format={DATE_FORMAT.SYSTEM_FORMAT} />
          </Form.Item>

          {shareType === 'SpecificMembers' && selectedMembers.length > 0 && (
            <Row>
              <Col span={5} style={{ marginTop: 5, textAlign: 'right' }}>
                <Text
                  style={{
                    fontSize: 13,
                    color: 'rgba(0, 0, 0, 0.85)',
                    marginRight: 8,
                  }}
                >
                  Members:
                </Text>
              </Col>
              <Col
                span={18}
                style={{ maxHeight: mode === 'create' ? 300 : 385 }}
                className='scroller'
              >
                <MemberDisplay
                  selectedMembers={selectedMembers}
                  setSelectedMembers={setSelectedMembers}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Col>

      {shareType === 'SpecificMembers' && (
        <Col span={12}>
          <MemberSelection
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
          />
        </Col>
      )}
    </Row>
  );
};

const nameGrid = 'member-detail-grid';
const key = 'memberList';

const MemberSelection = ({ selectedMembers, setSelectedMembers }) => {
  useInjectReducer({ key, reducer: reducerMember });
  useInjectSaga({ key, saga: sagaMember });

  const [selectedIds, setSelectedIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [membersGrid, setMembersGrid] = useState([]);

  const prevSearchText = usePrevious(searchText);

  const [pagination, setPagination] = useState(1);

  const dispatch = useDispatch();

  const memberColumns = useSelector(makeSelectColumns());

  useEffect(() => {
    dispatch(gridMemberColumnInfo(nameGrid));
  }, [dispatch]);

  useEffect(() => {
    if (searchText !== prevSearchText) {
      setPagination(1);
    }
  }, [dispatch, prevSearchText, searchText]);

  const filteredMemberColumns = useMemo(
    () => filterColumnsGrid(memberColumns),
    [memberColumns]
  );

  const handleAddMembers = () => {
    const filteredMembers = filterSelectedMembers(
      membersGrid,
      selectedIds,
      selectedMembers
    );

    setSelectedMembers((prevMembers) => [...prevMembers, ...filteredMembers]);
  };

  return (
    <div style={{ height: '430px', marginBottom: 40 }}>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <Search
          placeholder='Search member'
          allowClear
          onSearch={(value) => setSearchText(value)}
        />
        <FormAddButton
          text='Add'
          style={{ marginLeft: 10 }}
          onClick={handleAddMembers}
        />
      </div>

      {filteredMemberColumns?.length > 0 && (
        <AgGrid
          columnDefs={filteredMemberColumns}
          urlGrid={MEMBER_DETAIL_GRID}
          urlGridDistinct={MEMBER_DETAIL_GRID_DISTINCT}
          responseParams='data'
          callbackDataListSelection={(ids) => setSelectedIds(ids)}
          handleChangePagination={(page) => setPagination(page)}
          getDataFromGrid={(items) => setMembersGrid(items)}
          paramsGrid={{
            search: {
              searchText,
            },
          }}
          pageNumberPagination={pagination}
          rowMultiSelectWithClick={true}
        />
      )}
    </div>
  );
};

const MemberDisplay = ({ selectedMembers, setSelectedMembers }) => {
  const handleDeleteMember = (id) => {
    const result = selectedMembers.filter((member) => member.id !== id);
    setSelectedMembers(result);
  };

  return (
    <Row>
      {selectedMembers.map((member) => (
        <Col key={member.id}>
          <Badge
            count={
              <span onClick={() => handleDeleteMember(member.id)}>
                <CloseOutlined />
              </span>
            }
            className='member-display__badge'
          >
            <MemberThumbnailCard
              dataDetail={member}
              hideCheckbox={true}
              style={{ margin: '0px 10px 5px 0px' }}
            />
          </Badge>
        </Col>
      ))}
    </Row>
  );
};

const NonCuratedQuerySelection = (props) => {
  const { setSelectedQuery } = props;
  const [searchText, setSearchText] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const prevSearchText = usePrevious(searchText);
  const [pagination, setPagination] = useState(1);

  const nonCuratedQueryColumns = [
    {
      checkboxSelection: true,
      field: '',
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      displayName: 'Query Name',
      fieldName: 'Name',
      fieldNameCamelCase: 'name',
      resizable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      displayName: 'Grid Name',
      fieldName: 'GridName',
      fieldNameCamelCase: 'gridName',
      resizable: true,
      width: 200,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'int32',
      displayName: 'Created By',
      fieldName: 'CreatedBy',
      fieldNameCamelCase: 'createdBy',
      resizable: true,
      width: 120,
    },
    {
      allowFilter: true,
      allowSort: true,
      dataType: 'string',
      displayName: 'Created By Name',
      fieldName: 'CreatedName',
      fieldNameCamelCase: 'createdName',
      resizable: true,
      width: 250,
    },
  ];

  useEffect(() => {
    if (searchText !== prevSearchText) {
      setPagination(1);
    }
  }, [prevSearchText, searchText]);

  return (
    <div style={{ height: '430px', marginBottom: 40 }}>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <Search
          placeholder='Search non-curated query'
          allowClear
          onSearch={(value) => setSearchText(value)}
        />
      </div>
      {nonCuratedQueryColumns?.length > 0 && (
        <AgGrid
          columnDefs={nonCuratedQueryColumns}
          urlGrid={GET_ALL_QUERIES_NOT_CURATED_QUERY_GRID}
          urlGridDistinct={GET_ALL_QUERIES_NOT_CURATED_QUERY_GRID_DISTINCT}
          responseParams='data'
          callbackDataListSelection={(ids) => {
            setSelectedIds(ids);
            setSelectedQuery(ids);
          }}
          handleChangePagination={(page) => setPagination(page)}
          paramsGrid={{
            search: {
              searchText,
            },
          }}
          gridConfigProps={{
            rowSelection: 'single',
          }}
          pageNumberPagination={pagination}
        />
      )}
    </div>
  );
};

export default AddCuratedQueryConfig;
