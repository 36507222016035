import { CustomNotification } from 'common/components';
import _ from 'lodash';

import { useSelector } from 'react-redux';

import {
  checkTotalQueryConditions,
  combineQueryCondition,
  createQuery,
  filterFields,
  getDropProperty,
  handleAddQueryConditions,
  handleCoverModeModule,
  handleUpdateBetweenTime,
  handleUpdateConjunction,
  handleUpdateOperator,
  mappingQueryCondition,
  removeQueryCondition,
} from 'pages/reporting/utils';
import { useCallback, useReducer } from 'react';
import {
  getAssetSearchableFields,
  getFolderSearchableFields,
  getMemberSearchableFields,
  getProductSearchableFields,
  getReportingSearchableFields,
} from 'services/reporting';

import selectorUser from 'redux/user/selectors';
import { actionConfig, configReducer, initState } from './reducer';
import { LAYOUT_ADVANCED_SEARCH } from 'static/Constants';

const entityApi = {
  member: getMemberSearchableFields,
  product: getProductSearchableFields,
  asset: getAssetSearchableFields,
  reporting: getReportingSearchableFields,
  folder: getFolderSearchableFields,
};

export const useAdvanceFilter = ({ modeData = null }) => {
  const [state, dispatchReporting] = useReducer(configReducer, initState);
  const {
    dataSearch,
    propertiesData,
    selectedPropertyIds,
    dataQueryConditions,
  } = state;

  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const handleUpdateLoading = (payload) => {
    dispatchReporting({ type: actionConfig.UPDATE_LOADING, payload: payload });
  };

  const handleUpdateQueryCondition = (payload) => {
    dispatchReporting({
      type: actionConfig.UPDATE_QUERY_CONDITIONS,
      payload: payload,
    });
  };

  const handleUpdateSearch = (payload) => {
    dispatchReporting({
      type: actionConfig.UPDATE_DATA_SEARCH,
      payload: payload,
    });
  };

  const handleUpdateSearchText = (searchValue) => {
    dispatchReporting({
      type: actionConfig.UPDATE_SEARCH_TEXT,
      payload: searchValue,
    });
  };

  const handleUpdateDataEntity = (data, modeData) => {
    const mappedChildFieldsData = data.map((dataItem) => {
      const { fields, ...rest } = dataItem;
      return { ...rest, childFields: fields };
    });

    const properties = handleCoverModeModule(mappedChildFieldsData, modeData);
    handleUpdateSearch(null);

    dispatchReporting({
      type: actionConfig.UPDATE_DATA_ENTITY,
      payload: properties,
    });
  };

  const toggleSelection = useCallback(
    (taskId, areaDrop) => {
      const wasSelected = selectedPropertyIds.includes(taskId);

      const newTaskIds = (() => {
        // Task was not previously selected
        // now will be the only selected item
        if (!wasSelected) {
          return [taskId];
        }

        // Task was part of a selected group
        // will now become the only selected item
        if (selectedPropertyIds.length > 1) {
          return [taskId];
        }

        // task was previously selected but not in a group
        // we will now clear the selection
        return [];
      })();

      dispatchReporting({
        type: actionConfig.UPDATE_SELECTED_PROPERTY_IDS,
        payload: newTaskIds,
      });
    },
    [selectedPropertyIds]
  );

  const toggleSelectionInGroup = useCallback(
    (taskId, areaDrop) => {
      const index = selectedPropertyIds.indexOf(taskId);

      // if not selected - add it to the selected items
      if (index === -1) {
        dispatchReporting({
          type: actionConfig.UPDATE_SELECTED_PROPERTY_IDS,
          payload: [...selectedPropertyIds, taskId],
        });
        return;
      }

      // it was previously selected and now needs to be removed from the group
      const shallow = [...selectedPropertyIds];
      shallow.splice(index, 1);
      dispatchReporting({
        type: actionConfig.UPDATE_SELECTED_PROPERTY_IDS,
        payload: shallow,
      });
    },
    [selectedPropertyIds]
  );

  let _draggingIds;

  const onDragStart = (start) => {
    _draggingIds = start.draggableId;
    const selected = selectedPropertyIds.find(
      (taskId) => taskId === _draggingIds
    );

    // if dragging an item that is not selected - unselect all items
    if (!selected && selectedPropertyIds?.length) {
      dispatchReporting({
        type: actionConfig.UPDATE_SELECTED_PROPERTY_IDS,
        payload: [],
      });
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId, combine } = result;

    const data = dataSearch?.length > 0 ? dataSearch : propertiesData;
    const dropEntityData = getDropProperty(
      data,
      selectedPropertyIds,
      draggableId
    );

    if (_draggingIds.indexOf('_entity') > -1) {
      handleDropProperty(dropEntityData, destination, source);
    }

    if (!destination || result.reason === 'CANCEL') {
      if (combine) {
        handleQueryConditionCombine(dropEntityData, combine);

        dispatchReporting({
          type: actionConfig.UPDATE_SELECTED_PROPERTY_IDS,
          payload: [],
        });

        return;
      }
    }
    dispatchReporting({
      type: actionConfig.UPDATE_SELECTED_PROPERTY_IDS,
      payload: [],
    });
    _draggingIds = null;
  };

  const handleQueryConditionCombine = (dropEntityData, combine) => {
    const mappingQueryCondition = combineQueryCondition(
      dropEntityData,
      dataQueryConditions,
      combine
    );

    const isAccept = checkTotalQueryConditions(mappingQueryCondition);

    if (isAccept) {
      handleUpdateQueryCondition(mappingQueryCondition);
    } else {
      CustomNotification.error(
        'Failed to update conditions. Conditions exceed 50 items.'
      );
    }
  };

  const handleDropProperty = (dropEntityData, destination) => {
    const keyDropEntity = destination?.droppableId;

    if (keyDropEntity === 'query_conditions') {
      const dataDropQueryCondition = mappingQueryCondition(
        dropEntityData,
        dataQueryConditions
      );

      const isAccept = checkTotalQueryConditions(dataDropQueryCondition);

      if (isAccept) {
        handleUpdateQueryCondition(dataDropQueryCondition);
      } else {
        CustomNotification.error(
          'Failed to update conditions. Conditions exceed 50 items.'
        );
      }
    }
  };

  const handleRemoveQueryConditions = (valuePath) => {
    const dataRemoveQueryCondition = removeQueryCondition(
      dataQueryConditions,
      valuePath
    );

    handleUpdateQueryCondition(dataRemoveQueryCondition);
  };

  const handleQueryDateBetween = (valuePath, dateString) => {
    const updateOperator = handleUpdateBetweenTime(
      dataQueryConditions,
      valuePath,
      dateString
    );

    handleUpdateQueryCondition(updateOperator);
  };

  const handleUpdateSearchEntity = (searchValue) => {
    const dataSearchEntity = filterFields(propertiesData, searchValue);

    handleUpdateSearchText(searchValue);
    handleUpdateSearch(dataSearchEntity);
  };

  const handleConjunction = (valuePath, checked) => {
    const updateDataConjunction = handleUpdateConjunction(
      dataQueryConditions,
      valuePath,
      checked
    );

    handleUpdateQueryCondition(updateDataConjunction);
  };

  const updateQueryCondition = (valuePath, value, keyObject) => {
    const updateOperator = handleUpdateOperator(
      dataQueryConditions,
      valuePath,
      value,
      keyObject
    );

    handleUpdateQueryCondition(updateOperator);
  };

  const handleUpdateQueryConditionFile = ({ path, values }) => {
    let dataConditions = _.cloneDeep(dataQueryConditions);
    let updateOperator = {};

    values?.forEach(({ value, field }) => {
      updateOperator = handleUpdateOperator(dataConditions, path, value, field);
    });

    handleUpdateQueryCondition(updateOperator);
  };

  const addQueryCondition = ({ path, type }) => {
    let data = {};

    if (path === 'children') {
      data = createQuery(type, dataQueryConditions);
    } else {
      data = handleAddQueryConditions(dataQueryConditions, path, type);
    }

    handleUpdateQueryCondition(data);
  };

  const fetchEntityAttribute = (layoutToggle) => {
    const storedEntityAttribute =
      window.advancedSearch?.entityAttributes?.[layoutToggle];
    const isUserExisting = window?.userInfo?.id === userInfo?.id;

    if (storedEntityAttribute && isUserExisting)
      return new Promise((resolve) => {
        resolve(storedEntityAttribute);
      });

    const services = entityApi[layoutToggle];

    return services().then((response) => {
      const { isSuccess, data } = response;

      if (isSuccess) {
        const attributeData =
          layoutToggle === LAYOUT_ADVANCED_SEARCH.PRODUCT
            ? mapProductModuleToAttribute(data.advanceSearch)
            : data.advanceSearch;

        _.set(
          window,
          ['advancedSearch', 'entityAttributes', layoutToggle],
          attributeData
        );

        _.set(window, ['userInfo', 'id'], userInfo?.id);

        return attributeData;
      } else {
        throw new Error('fetch entity attributes error');
      }
    });
  };
  const mapProductModuleToAttribute = (data) => {
    if (!data?.length) return data;

    const productModule = data[0];

    const mappedModuleName = {
      ...productModule,
      fields: productModule.fields.map((property) => {
        return { ...property, fieldName: `product.${property.fieldName}` };
      }),
    };

    data.shift();

    return [mappedModuleName, ...data];
  };

  const handleGetEntityAndAttributes = async (layoutToggle) => {
    handleUpdateLoading(true);
    try {
      const entityAttrs = await fetchEntityAttribute(layoutToggle);

      if (entityAttrs) {
        handleUpdateDataEntity(entityAttrs, modeData);
      }
    } catch (error) {
    } finally {
      handleUpdateLoading(false);
    }
  };

  return {
    state,
    onDragEnd,
    onDragStart,
    toggleSelection,
    handleConjunction,
    addQueryCondition,
    updateQueryCondition,
    handleUpdateDataEntity,
    fetchEntityAttribute,
    handleUpdateSearchText,
    handleQueryDateBetween,
    toggleSelectionInGroup,
    handleUpdateQueryCondition,
    handleUpdateSearchEntity,
    handleRemoveQueryConditions,
    handleGetEntityAndAttributes,
    handleUpdateQueryConditionFile,
  };
};
