import React from 'react';
import PropTypes from 'prop-types';
import { ThumbnailItem } from 'common/components';

import * as getLink from 'utils/common/linkBuilder';

import './FolderThumbnail.less';
import { getThumbnailImage } from '../utils';

/**
 * Folder thumbnail is inherit from thumbnail item
 * @param {object} props
 */
const FolderThumbnailCard = (props) => {
  const {
    dataDetail,
    selected,
    onClickItemGrid,
    onClickCheckboxItem,
    disableEvent,
  } = props;

  const type = dataDetail?.type?.toLowerCase();
  const image = getThumbnailImage(dataDetail, type);

  const alink = getLink.folderLink(
    dataDetail?.id,
    dataDetail?.folderName,
    dataDetail?.type
  );

  return (
    <ThumbnailItem
      className='folder-thumbnail'
      dataDetail={dataDetail}
      labelThumbnail={alink}
      imageThumbnail={image}
      selected={selected}
      onClickItemGrid={!disableEvent && onClickItemGrid}
      onClickCheckboxItem={!disableEvent && onClickCheckboxItem}
      hideCheckbox={disableEvent}
      isShareBtn={true}
      shareable={dataDetail?.shareable} //shareable class for asset
      drmType={'Folder'}
      showTypeItem={type !== 'folder'}
      enableSingleClick={false}
    />
  );
};

FolderThumbnailCard.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

FolderThumbnailCard.defaultProps = {
  disableEvent: false,
};

export default React.memo(FolderThumbnailCard);
