import React from 'react';
import { Row, Avatar } from 'antd';
import PropTypes from 'prop-types';

import AssetQuery from 'assets/query/AssetQuery.png';
import ProductQuery from 'assets/query/ProductQuery.png';
import MemberQuery from 'assets/query/MemberQuery.png';
import FolderQuery from 'assets/query/FolderQuery.png';
import ReportQuery from 'assets/query/ReportQuery.png';
import { Images } from 'config/assets';

const QueryTileHeader = (props) => {
  const { dataDetail } = props;
  let image =
    dataDetail?.gridName === 'member-detail-grid'
      ? MemberQuery
      : dataDetail?.gridName === 'digital-asset-detail-grid'
      ? AssetQuery
      : dataDetail?.gridName === 'folder-detail-grid'
      ? FolderQuery
      : dataDetail?.gridName === 'products-for-member' ||
        dataDetail?.gridName === 'product-detail-grid'
      ? ProductQuery
      : dataDetail?.gridName === 'assets-for-member'
      ? AssetQuery
      : dataDetail?.gridName === 'query-reporting-grid'
      ? ReportQuery
      : Images.RIVIR_LOGO_DEFAULT;

  return (
    <Row className='query-tile__header' justify='center'>
      <div className='query-tile__header--background'></div>

      <div className='query-tile__header--image-wrapper'>
        <Avatar
          className='query-tile__header--image'
          src={image}
          alt={dataDetail && dataDetail.name}
        />
      </div>
    </Row>
  );
};

QueryTileHeader.propTypes = {
  dataDetail: PropTypes.object,
};

export default QueryTileHeader;
