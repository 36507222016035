import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { getSnapshotQaSpecFreshSeafood } from 'services/product';

const getQueryKeyQaFreshSeafood = (productId) => {
  return ['pim', 'snapshot', parseInt(productId), 'qa-fresh-seafood'];
};

export const useGetSnapshotQaFreshSeafood = ({
  enabled,
  productId,
  ...restProps
}) => {
  const queryClient = useQueryClient();

  const getSnapshotQaFreshSeafoodData = async () => {
    const response = await getSnapshotQaSpecFreshSeafood({ productId });
    const { isSuccess, data } = response;
    if (!isSuccess) return null;

    const { productId: id, ...restValue } = data;
    return restValue;
  };

  const query = useQuery({
    queryKey: getQueryKeyQaFreshSeafood(productId),
    queryFn: getSnapshotQaFreshSeafoodData,
    enabled,
    ...restProps,
  });

  const invalidateSnapshotQaSpecFreshSeafood = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyQaFreshSeafood(productId),
    });
  };

  return { ...query, invalidateSnapshotQaSpecFreshSeafood };
};
