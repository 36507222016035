import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Card, Space, Typography } from 'antd';
import { CreditCardTwoTone } from '@ant-design/icons';

import * as ribbonActions from 'redux/ribbon/actions';

const { Text } = Typography;

const CreditCardAlert = () => {
  const dispatch = useDispatch();

  const onClickLink = () => {
    dispatch(ribbonActions.changeMemberLayout('add-credit-card'));
  };

  return (
    <Card size='small'>
      <Space size={8} align='center'>
        <CreditCardTwoTone style={{ fontSize: 24 }} twoToneColor='#52c41a' />
        <Text>
          Click{' '}
          <Link
            to='/company'
            style={{ textDecoration: 'underline' }}
            onClick={onClickLink}
          >
            here
          </Link>{' '}
          to add a <Text strong>Credit Card</Text> to your Account
        </Text>
      </Space>
    </Card>
  );
};

export default CreditCardAlert;
