import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col, Avatar, Typography, Badge } from 'antd';

import { HomeOutlined } from '@ant-design/icons';

import classnames from 'classnames';

import ChatMessage from './ChatMessage';
import VideoMessage from './VideoMessage';

import userSelectors from 'redux/user/selectors';
import * as chatSelector from '../../controller/selectors';

import { CHATTYPE } from 'static/Constants';
import { formatTime } from 'utils/formatDate';

const { Text } = Typography;

const mockAvatar =
  'https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png';

const ChatSpeech = (props) => {
  const { item, participant, isNewChain } = props;

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const memberId = useSelector(userSelectors.makeSelectUserMemberId());
  const selectedSearchResult = useSelector(
    chatSelector.selectSelectedSearchResult()
  );
  const isSelectedSearchResult =
    selectedSearchResult?.messageId &&
    selectedSearchResult?.messageId === item.messageId;

  const isAnonymous = userInfo?.isAnonymous;

  const isSender = isAnonymous
    ? item?.senderId === userInfo?.userChatId
    : item?.senderId === `U-${userInfo?.id}` ||
      item.senderId === `C-${memberId}`;

  const containerClassName = classnames({
    'chat-thread__turn-container': true,
    'chat-thread__turn-container--receiver': isSender,
    'chat-thread__turn-container--starting': isNewChain,
  });

  const renderMessage = (item, participant, showName) => {
    if (item?.messageText?.type === 'video') {
      return <VideoMessage message={item?.messageText} />;
    }

    return (
      <ChatMessage
        item={item}
        participant={participant}
        showErrorMessage={item?.status === 'error'}
        isSelectedSearchResult={isSelectedSearchResult}
        isShowName={showName}
      />
    );
  };

  const isShowName = isSender === false && isNewChain === true;
  const isCompanyAccount = participant?.senderType === CHATTYPE.COMPANY;

  return (
    <Row gutter={[8, 0]} className={containerClassName}>
      {!isSender ? (
        <Col
          flex='45px'
          style={{ flexWrap: 'nowrap', paddingRight: isSender ? 0 : 4 }}
        >
          {isNewChain ? (
            <Badge
              count={<HomeOutlined />}
              offset={[-3, 30]}
              className='communication-list__badge'
              style={{ display: isCompanyAccount ? '' : 'none', fontSize: 11 }}
            >
              <div className='communication-list__avatar-wrapper'>
                <Avatar src={participant?.avatar || mockAvatar} size={28}>
                  {participant?.name?.charAt(0)}
                </Avatar>
              </div>
            </Badge>
          ) : null}
        </Col>
      ) : null}
      <Col style={{ flex: '1', overflow: 'hidden' }}>
        {renderMessage(item, participant, isShowName)}
      </Col>

      <Col flex={'55px'}>
        <Text className='chat-thread__turn-time'>
          {isNewChain ? formatTime(item.sendTime) : null}
        </Text>
      </Col>
    </Row>
  );
};

export default ChatSpeech;
