import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Popover, Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './MdToolTextSampleSyntax.less';

const { Text } = Typography;

const MdToolTextSampleSyntax = (props) => {
  const { sampleItem } = props;

  const helpRender = (helpContent) => {
    return (
      <ul>
        {helpContent.map((helpItem) => (
          <li>{helpItem}</li>
        ))}
      </ul>
    );
  };

  const mapToCopiedSyntax = () => {
    const head =
      `${sampleItem?.head?.[0] || ''}` +
      `${sampleItem?.head?.[1]?.lineBreak ? '\n' : ''}`;

    const sampleText =
      `${sampleItem?.text?.[0] || ''}` +
      `${sampleItem?.text[1]?.lineBreak ? '\n' : ''}`;

    const tail =
      `${sampleItem?.tail?.[0] || ''}` +
      `${sampleItem?.tail?.[1]?.lineBreak ? '\n' : ''}`;
    return head + sampleText + tail;
  };

  const hanldeMessageCopySyntax = () => {
    message.success({
      content: <Text>Copied Syntax Successfully</Text>,
    });
  };

  return (
    <Row className='md-tool-text-sample-syntax'>
      <Row style={{ position: 'absolute', width: '100%' }}>
        <Col flex='auto' />
        <CopyToClipboard
          text={mapToCopiedSyntax()}
          onCopy={hanldeMessageCopySyntax}
        >
          <Button
            className='md-tool-text-sample-syntax__control-btn'
            icon={<CopyOutlined />}
          >
            Copy
          </Button>
        </CopyToClipboard>
      </Row>

      <Col xs={sampleItem?.head[1].lineBreak && 24}>
        <Text mark>{sampleItem?.head[0]}</Text>
      </Col>
      <Col xs={sampleItem?.text[1].lineBreak && 24}>
        <Text code>
          {sampleItem?.text[0]}
          {sampleItem?.text[1].help && (
            <div className='md-tool-text-sample-syntax__help'>
              <Popover
                content={helpRender(sampleItem?.text[1].help)}
                title='Guide'
                trigger='hover'
              >
                <div>(?)</div>
              </Popover>
            </div>
          )}
        </Text>
      </Col>
      {sampleItem?.tail?.[1] && (
        <Col xs={sampleItem?.tail[1].lineBreak && 24}>
          <Text mark>{sampleItem?.tail[0]}</Text>
        </Col>
      )}
    </Row>
  );
};

MdToolTextSampleSyntax.propTypes = {
  sampleItem: PropTypes.object,
};

export default MdToolTextSampleSyntax;
