import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DoubleRightOutlined } from '@ant-design/icons';

import { ReassignGLNToMemberModal } from 'common/components';

import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import selectorUser from 'redux/user/selectors';

import * as memberServices from 'services/members';
import { checkIsSuperAdmin } from 'utils';

const MoveGLNToMember = () => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const isSuperAdmin = checkIsSuperAdmin(userInfo?.roles);
  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const refSearchGridItemModal = useRef();

  const openModalMoveAssetToMember = () => {
    refSearchGridItemModal.current.openModal();
  };

  const defaultProductParams = {
    searchText: '',
  };

  const firstSelectedMemberItem = selectedItemDetailList?.[0];

  const isAllowToMoveGln = useMemo(() => {
    const isAllow =
      (userInfo?.isSuperMember || isSuperAdmin) &&
      selectedItemDetailList?.[0]?.glns?.length !== 0 &&
      selectedItemDetailList?.length === 1;

    return isAllow;
  }, [selectedItemDetailList, userInfo, isSuperAdmin, selectedItemDetailList]);

  return (
    <>
      <RibbonButton
        size='small'
        icon={<DoubleRightOutlined />}
        label='Reassign GLNs to Member'
        onClick={openModalMoveAssetToMember}
        disabled={!isAllowToMoveGln}
      />

      <ReassignGLNToMemberModal
        title='Assign GLN(s) to Member'
        ref={refSearchGridItemModal}
        isMultiple={false}
        service={memberServices.getAllMemberForReassign}
        dataPath='data.allMembers'
        params={defaultProductParams}
        mapSearch={(searchText) => ({ searchText })}
        tagRender={{ value: (itemData) => itemData?.companyName }}
        itemIdName='companyId'
        glnList={firstSelectedMemberItem?.glns}
        memberId={firstSelectedMemberItem?.id}
      />
    </>
  );
};

export default MoveGLNToMember;
