import { useRef } from 'react';

export const useScrollTo = () => {
  const scrollElRef = useRef();

  const triggerScrollTo = (to) => {
    const el = scrollElRef.current;

    if (!el) return;

    const destinationCollection = {
      top: 0,
      bottom: el.scrollHeight,
    };
    const destination = destinationCollection[to];

    if (!destination) return;

    el.scrollTop = destination;
  };

  return { scrollElRef, triggerScrollTo };
};
