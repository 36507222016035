import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import * as productServices from 'services/product';

export const useGetProductEnums = () => {
  const queries = useQuery({
    queryKey: ['branded-product/enums'],
    queryFn: async () => {
      const response = await productServices.getProductEnums();
      return response?.data;
    },
    staleTime: Infinity,
  });

  return {
    productEnums: useMemo(() => queries?.data?.enums, [queries?.data]) ?? [],
    flatProductEnums:
      useMemo(
        () => flattenProductEnums(queries?.data?.enums),
        [queries?.data]
      ) ?? {},
    ...queries,
  };
};

const flattenProductEnums = (productEnums) => {
  let result = {};

  if (productEnums) {
    productEnums.forEach((productEnum) => {
      result = {
        ...result,
        [productEnum?.enumName]: productEnum,
      };
    });
  }
  return result;
};

export const findProductEnums = (flatDataEnums, enumName) => {
  return flatDataEnums[`${enumName}`]?.enumProperties ?? [];
};

export const useGetEnumsByEnumName = (enumName) => {
  const { flatProductEnums } = useGetProductEnums();

  return useMemo(
    () => findProductEnums(flatProductEnums, enumName),
    [enumName, flatProductEnums]
  );
};
