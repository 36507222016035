import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import {
  createQasRequiredFields,
  editQasRequiredFields,
  deleteQasRequiredFields,
  getQasRequiredFields,
  getAllProductFields,
  getAllTargetMembers,
} from 'services/qas-require-field/';

export const QUERY_KEY_QAS_REQUIRE_FIELD = {
  GET_ALL_TARGET_MEMBER: 'qas/get-all-target-members',
  GET_ALL_PRODUCT_FIELDS: 'qas/get-all-product-fields',
  GET_QAS_REQUIRED_FIELDS: 'qas/get-qas-required-fields',
};

export const useCreateQasRequiredFields = () =>
  useMutation({ mutationFn: createQasRequiredFields });

export const useEditQasRequiredFields = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editQasRequiredFields,
    onSuccess: (response) => {
      if (response?.isSuccess)
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY_QAS_REQUIRE_FIELD.GET_QAS_REQUIRED_FIELDS,
            response?.data?.id,
          ],
        });
    },
  });
};

export const useDeleteQasRequiredFields = () =>
  useMutation({ mutationFn: deleteQasRequiredFields });

export const useGetQasRequiredFields = (id) =>
  useQuery({
    queryKey: [QUERY_KEY_QAS_REQUIRE_FIELD.GET_QAS_REQUIRED_FIELDS, id],
    queryFn: async () => {
      const reponse = await getQasRequiredFields({ id });
      return reponse?.data;
    },
    enabled: !!id,
  });

export const useGetAllTargetMembers = (searchText) =>
  useQuery({
    queryKey: [QUERY_KEY_QAS_REQUIRE_FIELD.GET_ALL_TARGET_MEMBER, searchText],
    queryFn: async () => {
      const reponse = await getAllTargetMembers({ searchText });
      return reponse?.data;
    },
    enabled: !!searchText,
  });

export const useGetAllProductFields = (option) =>
  useQuery({
    queryKey: [QUERY_KEY_QAS_REQUIRE_FIELD.GET_ALL_PRODUCT_FIELDS],
    queryFn: async () => {
      const response = await getAllProductFields();
      return response?.data?.groups || [];
    },
    ...option,
  });
