import React, { useMemo } from 'react';

import classnames from 'classnames';
import _ from 'lodash';

import {
  FormAddButton,
  FormDeleteButton,
  WithLoading,
} from 'common/components';

import { QaGridEdit, WithQaGridHeader } from 'pages/qa-spec/components';

import {
  EDIT_SECTION,
  EditTabSectionWrapper,
} from 'pages/qa-spec/components/edit-tab-checker';

import { useTabExpand } from 'hooks/useTabExpandProduct';

const QaSpecCharacteristicGrid = (props) => {
  const {
    className,
    loading,
    gridInst,
    title,
    columnDefs,
    rowData,
    onCellEditingStopped,
    handleAdd,
    handleEdit,
    handleSave,
    handleCancel,
    handleDelete,
    showSaveBtn,
    headerButtons = [],
    selectedRow,
    onRowSelected,
    analysisNotExecuted,
    isShowAnalysisNotExecuted,
    type,
    editCheckerHideSaveBtn = false,
    productId,
    snapshotGridValues = [],
    ...rest
  } = props;

  const { tableStyle } = useTabExpand();

  const rowClassRules = useMemo(
    () => ({
      'ag-row--error': (params) => {
        return !!params?.data?.error?.length > 0;
      },
    }),
    []
  );

  return (
    <div
      className={classnames('qa-spec-product__characteristic-grid', className)}
    >
      <WithQaGridHeader
        title={title}
        showSaveBtn={showSaveBtn}
        handleEdit={handleEdit}
        handleSave={handleSave}
        handleCancel={handleCancel}
        analysisNotExecuted={analysisNotExecuted}
        buttons={[
          handleAdd ? () => <FormAddButton onClick={handleAdd} /> : null,
          handleDelete
            ? () => (
                <FormDeleteButton
                  onClick={handleDelete}
                  disabled={!selectedRow}
                />
              )
            : null,
          ...headerButtons,
        ]}
        isShowAnalysisNotExecuted={isShowAnalysisNotExecuted}
        type={type}
        productId={productId}
        {...rest}
      >
        {(isEdit) => {
          // Prevent Document trigger "Review Editing Item(s)"
          const triggerReviewEditing =
            type === EDIT_SECTION.PROD_SPEC_DOC ? false : isEdit;

          return (
            <WithLoading loading={loading}>
              <EditTabSectionWrapper.CheckerWrapper
                isEdit={triggerReviewEditing}
                type={type}
                hideSaveBtn={editCheckerHideSaveBtn}
              >
                <div style={{ ...tableStyle }}>
                  <QaGridEdit
                    gridInst={gridInst}
                    gridData={_.cloneDeep(rowData)}
                    fields={columnDefs}
                    isEdit={isEdit}
                    getRowNodeId={(data) => {
                      return data?.['uuid'];
                    }}
                    onCellEditingStopped={onCellEditingStopped}
                    rowClassRules={rowClassRules}
                    onRowSelected={onRowSelected}
                    rowSelection='single'
                    snapshotGridValues={snapshotGridValues}
                  />
                </div>
              </EditTabSectionWrapper.CheckerWrapper>
            </WithLoading>
          );
        }}
      </WithQaGridHeader>
    </div>
  );
};

export default QaSpecCharacteristicGrid;
