import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { forwardTo } from 'utils/common/route';

import { Can } from 'context/Can';
import * as _ from 'lodash';

import * as globalActions from 'redux/global/actions';
import store from 'index';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { RIBBON_OPTIONS } from 'static/Constants';

import { useCheckPermissionAnd } from 'hooks/useCheckPermissions';
import { isArray } from 'lodash';

export const SECURE_CONFIG = {
  ALL_AUTH: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.ALL_AUTH],
  },
  USER: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.USER],
  },
  PRODUCT: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.PRODUCT],
  },
  PRODUCT_APL: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.PRODUCT_APL],
  },
  MEMBER: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MEMBER],
  },
  EDIT_MEMBER: {
    PASS: [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
  },
  COMPANY_PROFILE: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.COMPANY_PROFILE],
  },
  EDIT_COMPANY_PROFILE: {
    PASS: [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.COMPANY_PROFILE],
  },
  ASSET: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.ASSET],
  },
  ASSET_CREATE: {
    PASS: [ABILITY_ACTION.CREATE, ABILITY_SUBJECT.ASSET],
  },
  ASSET_EDIT: {
    PASS: [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.ASSET],
  },
  FOLDER: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.FOLDER],
  },
  HELP_CATEGORY: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.HELP_CATEGORY],
  },
  HELP_POST: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.HELP_POST],
  },
  HELP_POST_MANAGE: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.HELP_POST],
  },
  ARTICLE: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.ARTICLE],
  },
  ARTICLE_MANAGE: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.ARTICLE],
  },
  EULA: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.EULA],
  },
  EULA_MANAGE: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.EULA],
  },
  COMMUNICATION_TEMPLATE: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.COMMUNICATION_TEMPLATE],
  },
  COMMUNICATION_TEMPLATE_MANAGE: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.COMMUNICATION_TEMPLATE],
  },
  REPORTING: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.REPORTING],
  },
  REPORTING_CREATE: {
    PASS: [ABILITY_ACTION.CREATE, ABILITY_SUBJECT.REPORTING],
  },
  SHARED_ENTITY: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.SHARED_ENTITY],
  },
  MANAGE_MEMBER: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MANAGE_MEMBER],
  },
  SUPER_ADMIN: {
    PASS: [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.ALL],
  },
  EDIT_USER_PERMISSION: {
    PASS: [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.USER_PERMISSIONS],
  },
  WORKFLOW: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW],
  },
  WORKFLOW_MAINTENANCE: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.WORKFLOW_MAINTENANCE],
  },
  WORKFLOW_ADMINISTRATION: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_ADMINISTRATION],
  },
  WORKFLOW_DEFINITION: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_DEFINITION],
  },
  CHAT_PERSONAL: {
    PASS: [ABILITY_ACTION.CHAT, ABILITY_SUBJECT.CHAT_PERSONAL],
  },
  CHAT_COMPANY: {
    PASS: [ABILITY_ACTION.CHAT, ABILITY_SUBJECT.CHAT_COMPANY],
  },
  MARKUP: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MARKUP],
  },
  MARKUP_MANAGE: {
    PASS: [ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.MARKUP],
  },
  CURATED_QUERY: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.CURATED_QUERY],
  },
  MY_QUERY: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.MY_QUERY],
  },
  SHARED_QUERY: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SHARED_QUERY],
  },
  RECIPIENTS_FIELDS: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.RECIPIENTS_FIELDS],
  },
  MANAGE_PIM_MAPPING: {
    PASS: [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.PIM_MAPPING],
  },
  MANAGE_SSO_APPROVAL: {
    PASS: [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.ALL],
  },
  ACCESS_GDSN: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.GDSN],
  },
  ACCESS_SYNDICATION: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SYNDICATION],
  },
  VIEW_NEW_ITEM_FORMS: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.NEW_ITEM_FORMS],
  },
  MANAGE_IMPERSONATE: {
    PASS: [
      [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.COMPANY_PROFILE],
      [ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER],
    ],
  },
  VIEW_QA_SPECIFICATION: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.QA_SPECIFICATION],
  },
  VIEW_EVALUATION_FORM: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.EVALUATION_FORM],
  },
  MANAGE_BILLABLE_TRACKING: {
    PASS: [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.BILLABLE_TRACKING],
  },
  DOWNLOAD_SPLASH_HISTORY: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SPLASH_DOWNLOAD_HISTORY],
  },
  ACCESS_WORKFLOW: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.WORKFLOW],
  },
  TICKET_SYSTEM: {
    PASS: [ABILITY_ACTION.VIEW, ABILITY_SUBJECT.TICKET_SYSTEM],
  },
  TICKET_GROUP: {
    PASS: [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.TICKET_SYSTEM_GROUP],
  },
  MANAGE_QA_SPEC_WORKFLOW: {
    PASS: [ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.QA_SPEC_WORKFLOW],
  },
  ACCESS_GDSN_RECEIVED_PRODUCT: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.GDSN_RECEIVED_PRODUCT],
  },
  ACCESS_MY_SUBSCRIPTIONS: {
    PASS: [ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.MY_SUBSCRIPTIONS],
  },
};

const WithSecureRoute =
  ({ secureConfig }) =>
  (Component, additionalProps) =>
  (props) => {
    const storeState = store.getState();
    const showRibbonBar = _.get(storeState, 'global.showRibbonBar');
    const isRibbonHome = storeState?.ribbon?.view === 'Home';

    return (
      <ImprovedCan
        secureConfig={secureConfig}
        Component={Component}
        additionalProps={additionalProps}
        showRibbonBar={showRibbonBar}
        isRibbonHome={isRibbonHome}
        componentProps={props}
      />
    );
  };

/**
 * ! Make Can HOC accept multipe secureconfig options
 * @param {*} props
 * @returns
 */
const ImprovedCan = (props) => {
  const {
    secureConfig,
    Component,
    additionalProps,
    componentProps,
    showRibbonBar,
    isRibbonHome,
  } = props;

  const checkPermissionAND = useCheckPermissionAnd();
  const dispatch = useDispatch();

  const isMultipleConfig = isArray(secureConfig);

  const allowed = isMultipleConfig
    ? checkPermissionAND(secureConfig)
    : checkPermissionAND([secureConfig.PASS]);

  if (allowed) {
    return <Component {...componentProps} {...additionalProps} />;
  } else {
    if (showRibbonBar) {
      !isRibbonHome &&
        dispatch(globalActions.changeRibbonActions(RIBBON_OPTIONS.BLANK));
      dispatch(globalActions.hideSearchBar());
    }
    forwardTo(`/dashboard`);
    return null;
  }
};

export default WithSecureRoute;
