import React, { useState } from 'react';

import { CopyOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import AddHierarchyModal from '../../modal/product-hierarchy/AddHierarchyModal';

import Messages from 'i18n/messages/home';

const CopyHierarchy = (props) => {
  const { selectedHierarchy, sectionDisabled } = props;

  const [visible, setVisible] = useState(false);

  const onClickHandler = () => {
    setVisible(true);
  };

  const isDisabled = !selectedHierarchy || sectionDisabled;

  return (
    <>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<CopyOutlined style={{ height: 26 }} />}
        label={Messages.copyHierarchy}
        onClick={onClickHandler}
        disabled={isDisabled}
      />
      <AddHierarchyModal
        visible={visible}
        setVisible={setVisible}
        selectedHierarchy={selectedHierarchy}
        isCopy
      />
    </>
  );
};

export default CopyHierarchy;
