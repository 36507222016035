import _ from 'lodash';
import { DEFAULT_PET_NUTRITION_FACT, SPECIAL_CARBOHYDRATE } from './constants';

const removeSpecialCharacter = (text) => {
  return text?.replace(/[^A-Z0-9]+/gi, '');
};

export const addDefaultNutrient = ({ nutrients = [] }) => {
  const missingValue = [];
  const cloneNutrients = _.cloneDeep(nutrients);

  const hasCarbohydrate = nutrients?.some((item) =>
    removeSpecialCharacter(item?.nutrientName)
      ?.toLowerCase()
      ?.includes('totalcarbohydrate')
  );

  DEFAULT_PET_NUTRITION_FACT.forEach((nutrient) => {
    const currentNutrients = nutrients?.find((item) => {
      return (
        removeSpecialCharacter(nutrient?.nutrientName)?.toLowerCase() ===
        removeSpecialCharacter(item?.nutrientName)?.toLowerCase()
      );
    });

    if (currentNutrients) {
      const hasSpecialNutrients = SPECIAL_CARBOHYDRATE?.includes(
        removeSpecialCharacter(currentNutrients?.nutrientName)?.toLowerCase()
      );

      const offset = hasCarbohydrate && hasSpecialNutrients ? 18 : null;

      missingValue.push({
        ...nutrient,
        ...currentNutrients,
        offset,
      });

      _.remove(
        cloneNutrients,
        (item) => JSON.stringify(item) === JSON.stringify(currentNutrients)
      );
    }
  });

  return missingValue.concat(cloneNutrients) || [];
};
