import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Space } from 'antd';
import { StopOutlined, SaveOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Message from 'i18n/messages/folder';
import './FolderPanelEditHeader.less';

const FolderPanelEditHeader = (props) => {
  const { onCancelClick, onSaveClick, disableSaveBtn } = props;
  return (
    <Row className='folder-panel-edit-header' justify='end'>
      <Space>
        <Button
          type='primary'
          onClick={onSaveClick}
          style={{ borderRadius: '4px' }}
          disabled={disableSaveBtn}
          icon={<SaveOutlined className='folder-panel-edit-header__icon' />}
        >
          <FormattedMessage {...Message.saveBtnEdit} />
        </Button>
        <Button
          onClick={onCancelClick}
          style={{ borderRadius: '4px' }}
          icon={<StopOutlined className='folder-panel-edit-header__icon' />}
        >
          <FormattedMessage {...Message.cancelBtnEdit} />
        </Button>
      </Space>
    </Row>
  );
};

FolderPanelEditHeader.propTypes = {
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  disableSaveBtn: PropTypes.bool,
};

FolderPanelEditHeader.defaultProps = {
  disableSaveBtn: true,
};

export default FolderPanelEditHeader;
