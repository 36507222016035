import { createContext } from 'react';
import ability from 'common/casl/ability';
import { createContextualCan, createCanBoundTo } from '@casl/react';

export const AbilityContext = createContext();
/**
 * Create Can component to validate user permission
 *
 *
 */

export const MyCan = createCanBoundTo(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
