import { useRef } from 'react';

import { Space, Typography } from 'antd';

import { PreviewStatements } from '../multiple-panel/components';
import {
  CarouselSection,
  CarouselNext,
  CarouselPrevious,
} from 'common/components';

import { getComponentFactsPanelType } from '../multiple-panel/components/utils';

import '../multiple-panel/components/GroupLayout.less';

const defaultStyleText = {
  style: {
    textTransform: 'uppercase',
    fontSize: 14,
  },
};

export const PreviewIngredients = ({
  ingredientGroups: ingredientGroupsProp,
}) => {
  const ingredientGroups = ingredientGroupsProp ?? [];
  const isEmptyIngredient = ingredientGroups?.length === 0;

  if (isEmptyIngredient) {
    return (
      <Typography.Text
        strong
        {...defaultStyleText}
        className='group-layout__preview-allergen-containment'
      >
        Ingredients: N/A
      </Typography.Text>
    );
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Typography.Text
        strong
        {...defaultStyleText}
        className='group-layout__preview-allergen-containment'
      >
        Ingredients:
      </Typography.Text>

      {ingredientGroups?.map((ingredientGroup, index) => {
        const ingredients = ingredientGroup?.ingredients ?? [];
        const isEmptyStatements = ingredients.length === 0;

        if (isEmptyStatements) return null;

        return (
          <div key={index} style={{ marginLeft: 6 }}>
            <Typography.Text strong style={{ textTransform: 'uppercase' }}>
              -&nbsp;
            </Typography.Text>
            <PreviewStatements statements={ingredients} />
          </div>
        );
      })}
    </Space>
  );
};

export const PreviewAllergens = ({
  previewData,
  presentAllergenGroups: presentAllergenGroupsProp,
}) => {
  const presentAllergenGroups = presentAllergenGroupsProp ?? [];
  const isEmptyAllergen = presentAllergenGroups?.length === 0;

  if (isEmptyAllergen) {
    return (
      <Typography.Text
        strong
        {...defaultStyleText}
        className='group-layout__preview-allergen-containment'
      >
        Contains: N/A
      </Typography.Text>
    );
  }

  return (
    <>
      {previewData?.map((allergenGroup, index) => {
        const containsAllergens = allergenGroup?.containsAllergens ?? [];
        const mayContainAllergens = allergenGroup?.mayContainAllergens ?? [];
        const derivedFromAllergens = allergenGroup?.derivedFromAllergens ?? [];

        const isEmptyAllergens =
          containsAllergens?.length +
            mayContainAllergens?.length +
            derivedFromAllergens?.length ===
          0;

        if (isEmptyAllergens) return null;

        return (
          <div key={index} style={{ paddingBottom: 6 }}>
            {containsAllergens.length > 0 && (
              <>
                <Typography.Text
                  strong
                  {...defaultStyleText}
                  className='group-layout__preview-allergen-containment'
                >
                  Contains:&nbsp;
                </Typography.Text>
                <PreviewStatements
                  {...defaultStyleText}
                  statements={containsAllergens}
                />
                .&nbsp;
              </>
            )}
            {mayContainAllergens.length > 0 && (
              <>
                <Typography.Text
                  strong
                  {...defaultStyleText}
                  className='group-layout__preview-allergen-containment'
                >
                  May Contain:&nbsp;
                </Typography.Text>
                <PreviewStatements
                  {...defaultStyleText}
                  statements={mayContainAllergens}
                />
                .&nbsp;
              </>
            )}
            {derivedFromAllergens.length > 0 && (
              <>
                <Typography.Text
                  strong
                  {...defaultStyleText}
                  className='group-layout__preview-allergen-containment'
                >
                  Derived From:&nbsp;
                </Typography.Text>
                <PreviewStatements
                  {...defaultStyleText}
                  statements={derivedFromAllergens}
                />
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

const MAX_FACTS_PANEL_TO_SHOW = 2;

export const PreviewFactsPanels = ({ factsPanelsGroup }) => {
  const ref = useRef();

  const isEmptyFactsPanels = factsPanelsGroup?.length === 0;
  const disabledCarouselBtn =
    factsPanelsGroup?.length <= MAX_FACTS_PANEL_TO_SHOW;

  return (
    <>
      {disabledCarouselBtn ? null : (
        <Space>
          <CarouselPrevious
            onClick={() => {
              if (ref?.current) ref.current.prev();
            }}
            disabled={disabledCarouselBtn} // need to check permission in here
          />
          <CarouselNext
            onClick={() => {
              if (ref?.current) ref.current.next();
            }}
            disabled={disabledCarouselBtn} // need to check permission in here
          />
        </Space>
      )}

      {isEmptyFactsPanels ? null : (
        <div style={{ marginTop: 6 }}>
          <CarouselSection
            ref={ref}
            // adaptiveHeight
            slidesToShow={MAX_FACTS_PANEL_TO_SHOW}
            infinite={false}
            variableWidth
          >
            {factsPanelsGroup?.map((factsPanel, index) => {
              const FactsPanelType = getComponentFactsPanelType(factsPanel);

              return (
                <Space key={factsPanel.id}>
                  <div className='group-layout__assign-fact-panel' key={index}>
                    <FactsPanelType key={index} label={factsPanel} />
                  </div>
                </Space>
              );
            })}
          </CarouselSection>
        </div>
      )}
    </>
  );
};
