import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as folderSelectors from '../../controllers/selectors';
import { Divider, Button, Alert } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import DownloadInformation from './DownloadInformation';
import DownloadConfiguration from './DownloadConfiguration';
import { FOLDER_DOWNLOAD_TYPE } from 'static/Constants';
import { useIntl } from 'react-intl';
import './style.less';
import DigitalRight from 'assets/DRM.png';
import { dialogFunction } from 'common/components';
import Messages from 'i18n/messages/folder';

const DownloadPanel = (props) => {
  const intl = useIntl();
  const {
    ownerFolder,
    shortDetail,
    id,
    onClosePanel,
    continueSchedule,
    hiddenBackToBtn,
    hiddenExecuteNow,
    memberOwnerId,
  } = props;

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();
  const [method, setMethod] = useState();

  const downloadSetting = useSelector(folderSelectors.selectDownloadSetting());
  const isDRM =
    shortDetail?.folderContents.filter((item) => item.drm).length > 0;
  const isDisabledScheduleDownload =
    !downloadSetting ||
    !FOLDER_DOWNLOAD_TYPE.includes(type) ||
    !['email', 'ftp'].includes(method);
  const drmDialog = () => {
    dialogFunction({
      type: 'warn',
      content: (
        <div style={{ fontSize: 13 }}>
          <img
            src={DigitalRight}
            alt='digital-right'
            height={20}
            width={20}
            style={{ marginRight: 5 }}
          />
          {intl.formatMessage({
            id: 'Taco.folder.download.drm',
          })}
        </div>
      ),

      okText: 'Cancel',
      cancelButtonProps: { htmlType: 'submit', form: 'folder' },
      cancelText: 'OK',
    });
  };

  return (
    <div className='download-panel'>
      <div className='download-panel__navigation'>
        {hiddenBackToBtn ? null : (
          <Button
            type='link'
            icon={<ArrowLeftOutlined />}
            onClick={onClosePanel}
          >
            {intl.formatMessage(Messages.upLevelText)}
          </Button>
        )}

        <div className='download-panel__navigation-forward-link'>
          {hiddenExecuteNow ? null : (
            <Button
              form='folder'
              htmlType={isDRM ? 'button' : 'submit'}
              onClick={() => {
                if (isDRM) {
                  drmDialog();
                }
              }}
              type='link'
              loading={loading}
              disabled={!downloadSetting}
            >
              {intl.formatMessage({
                id: 'Taco.folder.download.execute',
              })}
              <ArrowRightOutlined style={{ marginLeft: 8 }} />
            </Button>
          )}

          <Button
            type='link'
            disabled={isDisabledScheduleDownload}
            onClick={() =>
              document
                .getElementById('download-configuration__schedule-button')
                .click()
            }
          >
            {intl.formatMessage({
              id: 'Taco.folder.download.scheduleSetup',
            })}
            <ArrowRightOutlined style={{ marginLeft: 8 }} />
          </Button>
        </div>
      </div>
      <DownloadInformation
        downloadSetting={downloadSetting}
        ownerFolder={ownerFolder}
      />
      <Divider className='download-panel__divider' />
      <DownloadConfiguration
        continueSchedule={continueSchedule}
        downloadSetting={downloadSetting?.folderDownloadSetting}
        downloadEntityAmount={{
          digitalAsset: downloadSetting?.assetCount,
          product: downloadSetting?.productCount,
          query: downloadSetting?.queryCount,
          member: downloadSetting?.memberCount,
          report: downloadSetting?.reportingCount,
        }}
        id={id}
        setLoading={setLoading}
        onSetType={setType}
        onSetMethod={setMethod}
        memberOwnerId={memberOwnerId}
      />
      {isDisabledScheduleDownload ? (
        <Alert
          type='warning'
          message={intl.formatMessage(Messages.disabledScheduleDownloadMessage)}
          showIcon
        />
      ) : null}
    </div>
  );
};

DownloadPanel.propTypes = {
  ownerFolder: PropTypes.bool,
  id: PropTypes.number,
  onClosePanel: PropTypes.func,
};

export default DownloadPanel;
