import React from 'react';
import { useSelector } from 'react-redux';

import { VideoCameraOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/home';
import DeletePreview from '../../../controls/asset/DeletePreview';
import GeneratePreview from '../../../controls/asset/GeneratePreview';
import ReplacePreview from '../../../controls/asset/ReplacePreview';
import RibbonButton from 'common/components/button/RibbonButton';

import * as digitalAssetSelectors from 'pages/asset-full-view/controllers/selectors';
import AssetRibbonSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';

import './PreviewSection.less';

const { Text } = Typography;

const AssetActionSection = ({ isDisabled }) => {
  const currentSelectedAssets = useSelector(
    digitalAssetSelectors.makeSelectAssetData()
  );

  const isCreating = useSelector(AssetRibbonSelectors.selectAssetCreating());
  const isEditing = useSelector(AssetRibbonSelectors.selectAssetEdit());
  const isReplacing = useSelector(AssetRibbonSelectors.selectReplacePreview());
  const assetFullView = useSelector(
    digitalAssetSelectors.makeSelectAssetData()
  );
  const isIframe = assetFullView?.isIFrame;

  const sectionDisabled = isCreating || isEditing || isReplacing;

  const menuOptions = (
    <Menu>
      {!isIframe ? (
        <Menu.Item key='generatePreview' style={{ backgroundColor: 'inherit' }}>
          <GeneratePreview
            currentSelectedAssets={[
              currentSelectedAssets && currentSelectedAssets,
            ]}
            sectionDisabled={sectionDisabled}
          />
        </Menu.Item>
      ) : null}
      <Menu.Item key='replacePreview' style={{ backgroundColor: 'inherit' }}>
        <ReplacePreview
          sectionDisabled={sectionDisabled}
          isReplacing={isReplacing}
          isIframe={isIframe}
        />
      </Menu.Item>

      <Menu.Item key='deleteAsset' style={{ backgroundColor: 'inherit' }}>
        <DeletePreview
          currentSelectedAssets={[
            currentSelectedAssets && currentSelectedAssets,
          ]}
          sectionDisabled={sectionDisabled}
          typeView='full'
          isIframe={isIframe}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menuOptions}
      placement='bottomLeft'
      arrow
      disabled={isDisabled}
    >
      <RibbonButton
        icon={<VideoCameraOutlined />}
        disabled={isDisabled}
        label={Messages.mediaPreview}
      />
    </Dropdown>
  );
};

export default AssetActionSection;
