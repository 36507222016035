import { DrugFactHeading } from '.';
import DrugFactWarningItem from './DrugFactWarningItem';
import DrugFactSection from './DrugFactSection';

const DrugFactWarnings = ({ warnings }) => {
  if (!warnings?.length) return null;

  return (
    <DrugFactSection>
      <DrugFactHeading heading='Warnings' />
      {warnings.map((warningItem, index) => {
        const isLastWarningItem = index === warnings.length - 1;
        return (
          <DrugFactWarningItem
            key={index}
            warningItem={warningItem}
            isLastWarningItem={isLastWarningItem}
          />
        );
      })}
    </DrugFactSection>
  );
};

export default DrugFactWarnings;
