import React from 'react';

import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const RemoveButtonWrapper = (props) => {
  const { children, onRemove, visible } = props;

  return (
    <div
      className='remove-button-wrapper'
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {visible ? (
        <Button
          shape='circle'
          danger
          icon={<CloseOutlined />}
          type='primary'
          size='small'
          style={{
            position: 'absolute',
            top: 4,
            right: -8,
            zIndex: 99,
            backgroundColor: '#ff4d4f',
          }}
          onClick={onRemove}
        />
      ) : null}
    </div>
  );
};

export default RemoveButtonWrapper;
