import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getNifList = () => {
  return api.sendGet(endpoints.GET_NIF_LIST);
};

export const downloadNifForm = (params) => {
  const options = {
    ...params,
  };

  const urlPath = 'data.fileUrl';

  return api.requestToDownload({
    method: 'post',
    apiEndpoint: endpoints.DOWNLOAD_NIF_FORM,
    payload: options,
    urlPath,
  });
};
