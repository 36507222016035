import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Space, Row, Tag } from 'antd';
import { SafetyCertificateFilled } from '@ant-design/icons';
import { get } from 'lodash';

import { StarFilled } from '@ant-design/icons';
import IconList from 'common/components/icon-list/IconList';
import ProductSubscription from 'common/components/thumb/product/ProductSubscription';
import EntityStatusTag from 'common/components/tag/entity-status-tag/EntityStatusTag';
import { MemberTypeTile } from 'common/components/thumb/member/MemberType';

const { Paragraph, Title, Button } = Typography;

const AssetTileBody = (props) => {
  const { dataDetail } = props;
  const renderTitle = (value, key) => {
    return (
      <Title
        key={key}
        className='member-tile__body--title'
        style={{ fontSize: '12px' }}
      >
        {dataDetail?.memberName}
      </Title>
    );
  };

  const renderText = (value, key) => {
    return (
      <>
        {value && (
          <Paragraph key={key} className={`asset-tile__body--element ${key}`}>
            {value}
          </Paragraph>
        )}
      </>
    );
  };

  const memberName = get(dataDetail, 'memberName', '');
  const mainAddress = get(
    dataDetail,
    'primaryAddress.primaryAddressMainAddress',
    ''
  );

  const country = get(dataDetail, 'primaryAddress.primaryAddressCountry', '');
  const city = get(dataDetail, 'primaryAddress.primaryAddressCity', '');
  const state = get(dataDetail, 'primaryAddress.primaryAddressState', '');
  const countryAddress = `${city} ${city && ','} ${state}${
    country === 'USA' ? '' : `${state && ', '}` + country
  }`;

  const zipcode = get(dataDetail, 'primaryAddress.primaryAddressZipcode', '');
  const segments = get(dataDetail, 'segments', []).map((segmentIcon) => ({
    ...segmentIcon,
    wrapperStyle: {
      marginRight: 4,
    },
  }));
  const haveProductsCertificated = get(
    dataDetail,
    'haveProductsCertificated',
    false
  );

  const infoText = [
    {
      field: 'memberName',
      value: memberName,
      render: renderTitle,
    },
    {
      field: 'mainAddress',
      value: mainAddress,
      render: renderText,
    },
    {
      field: 'country',
      value: countryAddress,
      render: renderText,
    },
    {
      field: 'zipcode',
      value: zipcode,
      render: renderText,
    },
  ];

  return (
    <div className='member-tile__body'>
      <Row justify='center' className='member-tile__body--info-row'>
        <Space direction='vertical' size={0}>
          {infoText &&
            infoText.map((textItem) => {
              const { value, field, render } = textItem;
              return render ? render(value, field) : value;
            })}
        </Space>
      </Row>
      <Row className='member-tile__body--status-row' justify='center'>
        <EntityStatusTag
          status={dataDetail?.status}
          position='static'
          exceptions={['Active', 'ACTIVE']}
        />
      </Row>
      <Row style={{ flex: 1 }} />
      <Row className='member-tile__body--segment-row' wrap>
        {haveProductsCertificated ? (
          <SafetyCertificateFilled className='member-tile__icon-safety' />
        ) : null}
        {dataDetail?.isFavorited && (
          <StarFilled style={{ fontSize: '20px', color: '#fec810' }} />
        )}

        {dataDetail?.memberType && (
          <MemberTypeTile memberType={dataDetail.memberType} />
        )}
        {dataDetail?.subscription && (
          <ProductSubscription
            color={dataDetail?.subscriptionShield}
            type='member'
          />
        )}
        <IconList
          numberOfShowedItems={2}
          itemList={segments}
          size='default'
          tooltipPosition='top'
        />
      </Row>
    </div>
  );
};

AssetTileBody.propTypes = {
  dataDetail: PropTypes.object,
};

export default AssetTileBody;
