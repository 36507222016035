import React, { useMemo, useState } from 'react';

import { Col, Form, Row, Select } from 'antd';

import { useParams } from 'react-router-dom';

import classnames from 'classnames';

import { StyledModal, WithLoading, WrapperSelect } from 'common/components';

import { GroupName } from 'pages/product-full-view/components/product-media-area/multiple-panel/components';

import {
  filterPreviewAllergens,
  mappingPreviewAllergens,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/components/utils';

import {
  PreviewAllergens,
  PreviewFactsPanels,
  PreviewIngredients,
} from 'pages/product-full-view/components/product-media-area/shared/GroupPreviewParts';

import { useGetQaGroupDetail } from './hooks';

const defaultModalProps = {
  destroyOnClose: true,
  width: 1140,
  maskClosable: false,
  bodyStyle: {
    minHeight: '60vh',
  },
};

export const QaGroupPreview = (props) => {
  const {
    defaultGroupId,
    productGroups = [],
    productId: productIdProp,
    ...rest
  } = props;

  const [formInstance] = Form.useForm();

  const { id: productId } = useParams();

  const [groupId, setGroupId] = useState(defaultGroupId);

  const { groupDetail, isLoading } = useGetQaGroupDetail({
    productId: productIdProp ?? productId,
    groupId,
  });

  const {
    ingredientGroups = [],
    allergenGroups = [],
    nutritionFacts = [],
    supplementFacts = [],
    drugFacts = [],
    petNutritionFacts = [],
  } = groupDetail ?? {};

  const factsPanelsGroup = [
    ...nutritionFacts,
    ...supplementFacts,
    ...drugFacts,
    ...petNutritionFacts,
  ];

  const presentAllergenGroups = useMemo(
    () => filterPreviewAllergens(allergenGroups),
    [allergenGroups]
  );

  const displayAllergens = mappingPreviewAllergens(presentAllergenGroups);

  return (
    <StyledModal
      {...defaultModalProps}
      title='Preview Group'
      footer={null}
      {...rest}
    >
      <WithLoading loading={isLoading}>
        <Form form={formInstance}>
          <GroupName>
            <WrapperSelect
              allowClear={false}
              placeholder='Please select a group name'
              onChange={(value) => setGroupId(value)}
              defaultValue={groupId}
            >
              {productGroups.map((group) => {
                return (
                  <Select.Option key={group.groupId} value={group.groupId}>
                    {group.groupName}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </GroupName>
        </Form>
        <Row gutter={[12, 16]} className='group-layout__assign-item'>
          <Col span={24}>
            <PreviewIngredients ingredientGroups={ingredientGroups} />
          </Col>
          <Col span={24}>
            <PreviewAllergens
              previewData={displayAllergens}
              presentAllergenGroups={presentAllergenGroups}
            />
          </Col>

          <Col
            span={24}
            className={classnames({
              'group-layout__assign-fact-panel-empty':
                factsPanelsGroup?.length === 0,
            })}
          >
            <PreviewFactsPanels factsPanelsGroup={factsPanelsGroup} />
          </Col>
        </Row>
      </WithLoading>
    </StyledModal>
  );
};
