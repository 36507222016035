import React from 'react';
import { useHistory } from 'react-router-dom';

import { EditOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/home';

const EditMember = (props) => {
  const history = useHistory();
  const { companyInfo, disabled, size } = props;

  const onClick = () => {
    if (!companyInfo.id) return;

    history.push(`/member-management-company/${companyInfo.id}`);
  };

  const disabledBtn = !companyInfo?.id || disabled;

  return (
    <RibbonButton
      size={size}
      icon={<EditOutlined />}
      label={Messages.goToEditMember}
      onClick={onClick}
      disabled={disabledBtn}
    />
  );
};

export default EditMember;
