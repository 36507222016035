// Action Types
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RESET_LOGIN = 'RESET_LOGIN';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';
export const GET_USER_PROFILE_ACTIVITY_LOGS = 'GET_USER_PROFILE_ACTIVITY_LOGS';
export const GET_USER_PROFILE_ACTIVITY_LOGS_SUCCESS =
  'GET_USER_PROFILE_ACTIVITY_LOGS_SUCCESS';
export const GET_USER_PROFILE_ACTIVITY_LOGS_FAILURE =
  'GET_USER_PROFILE_ACTIVITY_LOGS_FAILURE';
export const GET_USER_PROFILE_LOCATIONS = 'GET_USER_PROFILE_LOCATIONS';
export const GET_USER_PROFILE_LOCATIONS_SUCCESS =
  'GET_USER_PROFILE_LOCATIONS_SUCCESS';
export const GET_USER_PROFILE_LOCATIONS_FAILURE =
  'GET_USER_PROFILE_LOCATIONS_FAILURE';
export const USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO =
  'USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO';
export const USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO_SUCCESS =
  'USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO_SUCCESS';
export const USER_PROFILE_LOCATIONS_GRID_COLUMN_INFO_ERROR =
  'GRID_COLUMN_INFO_ERROR';

export const USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO =
  'USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO';
export const USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_SUCCESS =
  'USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_SUCCESS';
export const USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_ERROR =
  'USER_PROFILE_ACTIVITY_LOGS_GRID_COLUMN_INFO_ERROR';

//* GET ALL ARTICLE
export const GET_ALL_USER_ARTICLE = 'GET_ALL_USER_ARTICLE';
export const GET_ALL_USER_ARTICLE_SUCCESS = 'GET_ALL_USER_ARTICLE_SUCCESS';
export const GET_ALL_USER_ARTICLE_FAILURE = 'GET_ALL_USER_ARTICLE_FAILURE';

//* TOGGLE SHOW SPLASH SCREEN
export const TOGGLE_SPLASH_SCREEN = 'TOGGLE_SPLASH_SCREEN';

// Update activity-log tab
export const UPDATE_IS_ACTIVITY_LOG_TAB = 'UPDATE_IS_ACTIVITY_LOG_TAB';

// edit mode
export const TOGGLE_EDIT_USER_MODE = 'TOGGLE_EDIT_USER_MODE';

//* CONTACT CARD
export const UPDATE_CONTACT_CARD = 'UPDATE_CONTACT_CARD';
//User profile header
export const GET_USER_PROFILE_HEADER = 'GET_USER_PROFILE_HEADER';
export const GET_USER_PROFILE_HEADER_SUCCESS =
  'GET_USER_PROFILE_HEADER_SUCCESS';
export const GET_USER_PROFILE_HEADER_FAILURE =
  'GET_USER_PROFILE_HEADER_FAILURE';

export const GET_MEMBER_ID = 'GET_MEMBER_ID';

// get user workflow roles
export const GET_USER_WORKFLOW_ROLES = 'GET_USER_WORKFLOW_ROLES';
export const GET_USER_WORKFLOW_ROLES_SUCCESS =
  'GET_USER_WORKFLOW_ROLES_SUCCESS';
export const GET_USER_WORKFLOW_ROLES_ERROR = 'GET_USER_WORKFLOW_ROLES_ERROR';

// get all workflow templates to start workflow
export const GET_ALL_PRODUCT_WORKFLOW_TEMPLATES =
  'GET_ALL_PRODUCT_WORKFLOW_TEMPLATES';
export const GET_ALL_MEMBER_WORKFLOW_TEMPLATES =
  'GET_ALL_MEMBER_WORKFLOW_TEMPLATES';
export const GET_ALL_DIGITAL_MEDIA_WORKFLOW_TEMPLATES =
  'GET_ALL_DIGITAL_MEDIA_WORKFLOW_TEMPLATES';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const ANON_LOGIN = 'ANON_LOGIN';

export const UPDATE_MFA_LOGIN_INFO = 'UPDATE_MFA_LOGIN_INFO';

export const UPDATE_UNLOCK_ACCOUNT_LOGIN_INFO =
  'UPDATE_UNLOCK_ACCOUNT_LOGIN_INFO';

export const TOGGLE_LOADING = 'TOGGLE_LOADING';

export const lOGIN_WITH_OTP = 'lOGIN_WITH_OTP';

export const lOGIN_WITH_UNLOCK_ACCOUNT = 'lOGIN_WITH_UNLOCK_ACCOUNT';

export const START_FLOW_OF_UNLOCK_ACCOUNT = 'START_FLOW_OF_UNLOCK_ACCOUNT';
