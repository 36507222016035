import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from './actions';

const useGetThreadAndMsgInfo = (threadId, userId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    //! warining - use userId in this useEffect why dont you add it in dependencies ?
    if (threadId && userId) {
      dispatch(actions.getThreadInfo(threadId, userId));
      dispatch(actions.getThreadMessages(threadId));
    }
  }, [dispatch, threadId, userId]);

  return;
};

export default useGetThreadAndMsgInfo;
