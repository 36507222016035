export const GET_EMAIL_THREAD_LIST = 'GET_EMAIL_THREAD_LIST';
export const GET_EMAIL_THREAD_LIST_SUCCESS = 'GET_EMAIL_THREAD_LIST_SUCCESS';
export const GET_EMAIL_THREAD_LIST_FAIL = 'GET_EMAIL_THREAD_LIST_FAIL';

export const GET_EMAIL_THREAD_LIST_MORE = 'GET_EMAIL_THREAD_LIST_MORE';
export const GET_EMAIL_THREAD_LIST_MORE_SUCCESS =
  'GET_EMAIL_THREAD_LIST_MORE_SUCCESS';
export const GET_EMAIL_THREAD_LIST_MORE_FAIL = 'GET_EMAIL_THREAD_LIST_MORE';

export const GET_EMAIL_THREAD_INFO = 'GET_EMAIL_THREAD_INFO';
export const GET_EMAIL_THREAD_INFO_SUCCESS = 'GET_EMAIL_THREAD_INFO_SUCCESS';
export const GET_EMAIL_THREAD_INFO_FAIL = 'GET_EMAIL_THREAD_INFO_FAIL';

export const GET_EMAIL_THREAD_INFO_CONTENT = 'GET_EMAIL_THREAD_INFO_CONTENT';
export const GET_EMAIL_THREAD_INFO_CONTENT_SUCCESS =
  'GET_EMAIL_THREAD_INFO_CONTENT_SUCCESS';
export const GET_EMAIL_THREAD_INFO_CONTENT_FAIL =
  'GET_EMAIL_THREAD_INFO_CONTENT_FAIL';

export const GET_EMAIL_THREAD_INFO_FILES = 'GET_EMAIL_THREAD_INFO_FILES';
export const GET_EMAIL_THREAD_INFO_FILES_SUCCESS =
  'GET_EMAIL_THREAD_INFO_FILES_SUCCESS';
export const GET_EMAIL_THREAD_INFO_FILES_FAIL =
  'GET_EMAIL_THREAD_INFO_FILES_FAIL';

export const UPDATE_EMAIL_THREAD_LIST = 'UPDATE_EMAIL_THREAD_LIST';
export const SET_NEW_EMAIL_MESSAGE = 'SET_NEW_EMAIL_MESSAGE';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';

export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_FAIL = 'UPLOAD_ATTACHMENT_FAIL';

export const ACTIVE_EMAIL_THREAD_INFO = 'ACTIVE_EMAIL_THREAD_INFO';
export const SAVE_ACCOUNT_MAIL = 'SAVE_ACCOUNT_MAIL';

export const TOGGLE_NEW_MAIL_MESSAGE = 'TOGGLE_NEW_MAIL_MESSAGE';
export const EMAIL_SELECT_SEARCH_RESULT = 'EMAIL_SELECT_SEARCH_RESULT';
export const EMAIL_UPDATE_FINISH_LOAD_DOWN = 'EMAIL_UPDATE_FINISH_LOAD_DOWN ';
export const EMAIL_UPDATE_SEARCH_RESULT_POSITION =
  'EMAIL_UPDATE_SEARCH_RESULT_POSITION';
export const EMAIL_UPDATE_LAST_MESSAGE = 'EMAIL_UPDATE_LAST_MESSAGE';
