import React from 'react';
import { Divider } from 'antd';
import {
  FormOutlined,
  SaveOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';

import Messages from 'i18n/messages/maintenance';

import { RibbonBar, RibbonDivider } from 'common/components';
import DeleteArticle from './components/controls/maintenance/help/DeleteArticle';
import BackToArticle from './components/controls/maintenance/help/BackToArticle';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

import { ToggleHelpUploadMediaSection } from './components';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import * as articleActions from 'pages/articles/controllers/actions';
import * as articleSelectors from 'pages/articles/controllers/selectors';

const HelpArticleFullView = () => {
  const isCreate = useSelector(articleSelectors.selectIsCreateArticle());
  const isEdit = useSelector(articleSelectors.selectIsEditArticle());

  const allowUploadMedia = isCreate || isEdit;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.ARTICLE}>
        {isEdit ? <EditView /> : <CreateView disabled={isCreate} />}
        <RibbonDivider />
      </Can>
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.ARTICLE}>
        <DeleteArticle disabled={isCreate} />
        <RibbonDivider />
      </Can>
      <ToggleHelpUploadMediaSection disabled={!allowUploadMedia} />
      <RibbonDivider />
      <AdvanceStack isDisabled />
      <BackToArticle ribbonSide='right' />
    </RibbonBar>
  );
};

const CreateView = ({ disabled }) => {
  const dispatch = useDispatch();

  return (
    <RibbonButton
      icon={<FormOutlined />}
      label={Messages.editArticle}
      disabled={disabled}
      onClick={() => dispatch(articleActions.enableEditArticle())}
    />
  );
};

const EditView = () => {
  const dispatch = useDispatch();

  const isUpdating =
    useSelector(articleSelectors.selectStatusUpdateArticle()) === 'loading';

  const handleSaveEditArticle = () => {
    dispatch(articleActions.confirmEditArticle());
  };

  const handleCancelEdit = () => {
    dispatch(articleActions.disableEditArticle());
  };

  return (
    <>
      <RibbonButton
        icon={<SaveOutlined />}
        label={Messages.savePost}
        onClick={handleSaveEditArticle}
        loading={isUpdating}
      />
      <RibbonButton
        icon={<CloseCircleOutlined />}
        label={Messages.cancelEula}
        onClick={handleCancelEdit}
      />
    </>
  );
};

export default HelpArticleFullView;
