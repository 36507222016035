import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { Typography, Button, Col, Row, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { FormCancelButton, FormSaveButton } from 'common/components';
import { useCheckAllowEdit } from 'pages/qa-spec/hooks';
import { EditTabSectionWrapper } from 'pages/qa-spec/components/edit-tab-checker';

import { useDetectSticky } from 'hooks/useComponent';
import classnames from 'classnames';

import './EditContainer.less';
import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';

const { Title } = Typography;

const EditContainer = (props, ref) => {
  const {
    title,
    children,
    buttons,
    extraButtons,
    allowEdit = true,
    handleCancel,
    handleSave,
    showSaveButton = true,
    showCancelButton = true,
    showEditButton = true,
    type,
    float = false,
    saveLoading = false,
    productId,
    editHook,
    onEditChange,
  } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [isEditProps, setIsEditProps] = editHook || [];
  const editState = editHook ? isEditProps : isEdit;

  const handleSetEditState = (value) => {
    if (editHook) {
      setIsEditProps(value);
      return;
    }

    setIsEdit(value);
  };

  const [isSticky, refSticky] = useDetectSticky();

  const toggleEditMode = () => {
    handleSetEditState((prev) => !prev);
  };

  const onCancel = () => {
    toggleEditMode();
    handleCancel && handleCancel();
  };

  const onSave = () => {
    handleSave(toggleEditMode);
  };

  const hasPermissionEdit = useCheckAllowEdit(productId);

  const { isQASpecLocked } = useCheckQaSpecLocked();

  useEffect(() => {
    onEditChange && onEditChange(isEdit);
  }, [isEdit]);

  useImperativeHandle(ref, () => {
    return { onToggleEditMode: toggleEditMode };
  });

  return (
    <div className={classnames('qa-spec-edit-container')}>
      <Row
        ref={refSticky}
        align='middle'
        gutter={[5, 5]}
        className={classnames('qa-spec-edit-container__edit', {
          'qa-spec-edit-container__edit--float': float,
          'qa-spec-edit-container__edit--sticked': isSticky && float,
        })}
      >
        <Col>
          {title && (
            <Title level={5} strong style={{ marginBottom: 0 }}>
              {title}
            </Title>
          )}
        </Col>

        <Col flex='auto' />
        <Col
          className={classnames('qa-spec-edit-container__control', {
            'qa-spec-edit-container__control--sticked': isSticky && float,
          })}
        >
          <div
            className={classnames('qa-spec-edit-container__control-container', {
              'qa-spec-edit-container__control-container--sticked-edit':
                isSticky && float && editState && hasPermissionEdit,
              'qa-spec-edit-container__control-container--sticked-no-edit':
                isSticky && float && !editState && hasPermissionEdit,
              'qa-spec-edit-container__control-container--hide-sticked-edit':
                isQASpecLocked,
            })}
          >
            {!isQASpecLocked ? (
              <Space>
                {editState && (
                  <>
                    {buttons?.map((buttonItem, idx) => (
                      <React.Fragment key={idx}>{buttonItem}</React.Fragment>
                    ))}
                  </>
                )}
                {editState && showSaveButton && (
                  <EditTabSectionWrapper.SaveActionTriggerWrapper
                    onSave={onSave}
                    type={type}
                  >
                    <FormSaveButton onClick={onSave} loading={saveLoading} />
                  </EditTabSectionWrapper.SaveActionTriggerWrapper>
                )}

                {showEditButton &&
                  !editState &&
                  allowEdit &&
                  hasPermissionEdit && (
                    <Button
                      className='qa-spec-edit-container__edit-btn'
                      icon={<EditOutlined />}
                      onClick={toggleEditMode}
                      style={{ border: 'none' }}
                    />
                  )}

                {editState && showCancelButton && (
                  <EditTabSectionWrapper.CancelActionTriggerWrapper
                    onCancel={onCancel}
                    type={type}
                  >
                    <FormCancelButton
                      onClick={onCancel}
                      disabled={saveLoading}
                    />
                  </EditTabSectionWrapper.CancelActionTriggerWrapper>
                )}

                {extraButtons?.map((buttonItem, idx) => (
                  <React.Fragment key={idx}>
                    {buttonItem(editState)}
                  </React.Fragment>
                ))}
              </Space>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row style={{ flex: 1 }}>
        <Col xs={24}>{children && children(editState)}</Col>
      </Row>
    </div>
  );
};

export default forwardRef(EditContainer);
