import { useMemo } from 'react';

import * as _ from 'lodash';

import ApplyFilter from '../components/ApplyFilter';

import {
  GET_FAVORITE_PRODUCT_DETAIL_CUSTOMIZED_GRID,
  GET_PRODUCT_DETAIL_CUSTOMIZED_GRID,
} from 'services/product/endpoints';

const URL_LIST_TO_DISABLE_SORT_IS_OWNER = [
  GET_PRODUCT_DETAIL_CUSTOMIZED_GRID,
  GET_FAVORITE_PRODUCT_DETAIL_CUSTOMIZED_GRID,
];

const useGetColumnDefs = ({
  urlGrid,
  columnDefs,
  notShowHeaderCheckbox,
  hideAgSetColumnFilter,
}) => {
  const procColDef = useMemo(() => {
    if (!columnDefs) return [];
    let nextColDefs = [];

    columnDefs.forEach((colDef, index) => {
      if (colDef && colDef?.checkboxSelection) {
        nextColDefs.push({
          ...colDef,
          width: 60,
          field: 'checkboxSelection',
          filter: false,
          sortable: false,
          resizable: false,
          suppressMenu: true,
          cellRenderer: 'CheckboxSelectionRender',

          headerComponent: !notShowHeaderCheckbox
            ? 'HeaderCheckboxRender'
            : 'HeaderNullRender',
          headerComponentParams: {
            urlGrid,
          },
          checkboxSelection: false,
          header: true,
        });
        return;
      }

      if (colDef && colDef.field === 'dragColumn') {
        nextColDefs.push({
          ...colDef,
          width: 50,
          field: 'dragColumn',
          filter: false,
          sortable: false,
          resizable: false,
          suppressMenu: true,
          cellRenderer: 'DragRender',
          headerComponent: () => null,
        });
        return;
      }

      nextColDefs.push({
        headerName: colDef && colDef.headerName,
        headerClass: colDef && colDef.headerClass,
        field: (colDef && colDef.field) || colDef.fieldNameCamelCase,
        width: colDef && colDef.width,
        minWidth: colDef && colDef.minWidth,
        maxWidth: colDef && colDef.maxWidth,
        flex: colDef && colDef.flex,
        filter: getFilter(colDef),
        filterParams: getFilterParams(colDef, hideAgSetColumnFilter),

        filterFramework: colDef && colDef.filterFramework,
        hide: colDef && colDef.hide,
        pinned: colDef && colDef.pinned,
        lockPosition: colDef && colDef.lockPosition,
        lockVisible: colDef && colDef.lockVisible,
        lockPinned: colDef && colDef.lockPinned,
        sortable: getSortable(colDef),
        sort: colDef && colDef.sort,
        sortIndex: null,
        resizable: colDef && colDef.resizable,
        cellRendererFramework:
          colDef && colDef.dataType !== 'boolean'
            ? colDef.linkTo
              ? 'LinkRender'
              : colDef.cellRenderer
            : 'CheckboxRender',

        cellRenderer:
          colDef && colDef.dataType !== 'boolean'
            ? colDef.linkTo
              ? 'LinkRender'
              : colDef.cellRenderer
            : 'CheckboxRender',

        valueFormatter: colDef && colDef.valueFormatter,
        suppressMenu: colDef && colDef.suppressMenu,
        key: index,
        rowDrag: colDef && colDef.rowDrag,
        orgFieldName: colDef && colDef.orgFieldName,
        separateFieldName: colDef && colDef.separateFieldName,
        displayName: colDef && colDef.displayName,
        linkTo: colDef && colDef.linkTo,
        valueGetter: colDef && colDef.valueGetter,
        cellStyle: colDef && colDef.cellStyle,
        headerComponent: colDef?.headerComponent,
      });
    });

    nextColDefs = nextColDefs.map((colItem) => {
      if (URL_LIST_TO_DISABLE_SORT_IS_OWNER.includes(urlGrid)) {
        if (colItem?.field === 'isOwner') {
          return { ...colItem, sortable: false };
        }
      }

      return { ...colItem };
    });

    return nextColDefs;
  }, [columnDefs, hideAgSetColumnFilter, notShowHeaderCheckbox, urlGrid]);

  return procColDef;
};

const getFilter = (colDef) => {
  const disabledFilterType = ['boolean', 'subscriptionshieldtype'];
  const disabledFilterField = ['sharedSupplierNames'];

  if (!colDef) return false;

  if (!colDef?.allowFilter) return false;

  if (colDef?.filter) return colDef.filter;

  if (colDef.isShowFilter === 'none') return false;

  if (disabledFilterType.indexOf(colDef.dataType) > -1) return false;

  if (disabledFilterField?.includes(colDef.field)) return false;

  return 'agMultiColumnFilter';
};

const getSortable = (colDef) => {
  const disabledSortableType = ['boolean', 'subscriptionshieldtype'];
  const disabledSortableField = ['sharedSupplierNames'];

  if (!colDef) return false;

  if (disabledSortableType.indexOf(colDef?.dataType) > -1) return false;

  if (disabledSortableField?.includes(colDef.field)) return false;

  return colDef?.allowSort;
};

const filterParamsButton = {
  buttons: ['apply', 'reset'],
  closeOnApply: true,
};

const getFilterParams = (data, hideAgSetColumnFilter) => {
  let textFilter =
    data.dataType === 'string'
      ? 'agTextColumnFilter'
      : data.dataType === 'int' ||
        data.dataType === 'decimal' ||
        data.dataType === 'int32' ||
        data.dataType === 'int64'
      ? 'agNumberColumnFilter'
      : data.dataType === 'datetime'
      ? 'agDateColumnFilter'
      : true;
  let filter = !data.allowFilter ? false : textFilter;
  filter = data?.filter === 'agSetColumnFilter' ? data?.filter : filter;
  let filterParams = {};

  if (filter === 'agTextColumnFilter') {
    let isHideAgSetColumnFilter =
      typeof hideAgSetColumnFilter === 'string'
        ? data.fieldName === hideAgSetColumnFilter
        : _.isArray(hideAgSetColumnFilter)
        ? hideAgSetColumnFilter.includes(data.fieldName)
        : false;

    if (isHideAgSetColumnFilter) {
      filterParams = {
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              filterOptions: [
                'contains',
                'equals',
                'notEqual',
                'blank',
                'notBlank',
              ],
              suppressAndOrCondition: true,
              ...filterParamsButton,
            },
          },
          {
            filter: ApplyFilter,
          },
        ],
      };
    } else {
      filterParams = {
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              filterOptions: [
                'contains',
                'equals',
                'notEqual',
                'blank',
                'notBlank',
              ],
              suppressAndOrCondition: true,
              ...filterParamsButton,
            },
          },
          {
            filter: 'agSetColumnFilter',
            filterParams: {
              ...filterParamsButton,
            },
          },
          {
            filter: ApplyFilter,
          },
        ],
      };
    }
  }
  if (filter === 'agNumberColumnFilter') {
    filterParams = {
      filters: [
        {
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: [
              'equals',
              'notEqual',
              'lessThan',
              'lessThanOrEqual',
              'greaterThan',
              'greaterThanOrEqual',
            ],
            ...filterParamsButton,
            suppressAndOrCondition: true,
          },
        },
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            ...filterParamsButton,
          },
        },
        {
          filter: ApplyFilter,
        },
      ],
    };
  }
  if (filter === 'agDateColumnFilter') {
    filterParams = {
      filters: [
        {
          filter: 'agDateColumnFilter',
          filterParams: {
            filterOptions: [
              'equals',
              'lessThan',
              'lessThanOrEqual',
              'greaterThan',
              'greaterThanOrEqual',
            ],
            ...filterParamsButton,
            suppressAndOrCondition: true,
            minValidYear: 0,
            maxValidYear: 9999,
          },
        },
        {
          filter: ApplyFilter,
        },
        // {
        //   filter: 'agSetColumnFilter',
        // },
      ],
    };
  }

  if (filter === 'agSetColumnFilter') {
    filterParams = _.cloneDeep(data.filterParams) || {};
    filterParams = {
      filters: [
        {
          ...filterParams,
          ...filterParamsButton,
        },
        {
          filter: ApplyFilter,
        },
        // {
        //   filter: 'agSetColumnFilter',
        // },
      ],
    };
  }

  if (data.dataType === 'boolean') {
    filterParams = {
      filters: [
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: (params) => { 
              return params.value === true ||
                params.value?.toLowerCase() === 'true'
                ? 'True'
                : 'False';
            },
            ...filterParamsButton,
          },
        },
        {
          filter: ApplyFilter,
        },
        // {
        //   filter: 'agSetColumnFilter',
        // },
      ],
      // filters:
      // filter: 'agSetColumnFilter',
      // filterParams: {
      //   valueFormatter: (params) => {
      //     return params.value === true || params.value?.toLowerCase() === 'true'
      //       ? 'True'
      //       : 'False';
      //   },
      //   ...filterParamsButton,
      // },

      // suppressAndOrCondition: true,
    };
  }

  Object.assign(filterParams, {
    values: (params) => {
      setTimeout(() => {
        params.success([]);
      }, 500);
    },
  });

  return filterParams;
};

export { useGetColumnDefs };
