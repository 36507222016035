import * as types from './constants';

//* get grid columns
export const getMarkupGridColumns = (gridName) => ({
  type: types.GET_MARKUP_MAINTENANCE_GRID_COLUMNS,
  gridName,
});

export const getMarkupGridColumnsSuccess = (data) => ({
  type: types.GET_MARKUP_MAINTENANCE_GRID_COLUMNS_SUCCESS,
  payload: data,
});

export const getMarkupGridColumnsFailure = () => ({
  type: types.GET_MARKUP_MAINTENANCE_GRID_COLUMNS_FAILURE,
});

export const createMarkup = (params) => ({
  type: types.CREATE_MARKUP,
  payload: params,
});

export const createMarkupSuccess = (id) => ({
  type: types.CREATE_MARKUP_SUCCESS,
  id,
});

export const createMarkupError = (error) => ({
  type: types.CREATE_MARKUP_ERROR,
  error,
});

export const cloneMarkup = (params) => ({
  type: types.CLONE_MARKUP,
  payload: params,
});

export const cloneMarkupSuccess = (id) => ({
  type: types.CLONE_MARKUP_SUCCESS,
  id,
});

export const cloneMarkupError = (error) => ({
  type: types.CLONE_MARKUP_ERROR,
  error,
});

export const resetSaveMarkup = () => ({
  type: types.RESET_SAVE_MARKUP,
});

export const enableEditMarkup = () => ({
  type: types.ENABLE_MARKUP_MAINTENANCE_EDIT,
});

export const disableEditMarkup = () => ({
  type: types.DISABLE_MARKUP_MAINTENANCE_EDIT,
});

export const enableCreateMarkup = () => ({
  type: types.ENABLE_MARKUP_MAINTENANCE_CREATE,
});

export const disableCreateMarkup = () => ({
  type: types.DISABLE_MARKUP_MAINTENANCE_CREATE,
});

export const confirmCloneMarkup = () => ({
  type: types.CONFIRM_CLONE_MARKUP_MAINTENANCE,
});

export const saveIdMarkup = (id) => ({
  type: types.SAVE_ID_MARKUP,
  data: id,
});

//* get grid data
export const getMarkupList = ({ pageSize, pageNumber, search }) => ({
  type: types.GET_MARKUP_MAINTENANCE_LIST,
  pageSize: pageSize,
  pageIndex: pageNumber,
  search,
});

export const getMarkupListSuccess = (data, total) => ({
  type: types.GET_MARKUP_MAINTENANCE_LIST_SUCCESS,
  payload: data,
  total,
});

export const getMarkupListFailure = () => ({
  type: types.GET_MARKUP_MAINTENANCE_LIST_FAILURE,
});
export function updateIsDetailsViewMarkup(isDetailsView) {
  return {
    type: types.MARKUP_DISABLED_VIEW_MODE,
    isDetailsView,
  };
}

export const getMarkupContentDetail = (params) => {
  return {
    type: types.GET_MARKUP_CONTENT_DETAIL,
    params,
  };
};
export function getMarkupContentDetailSuccess(data) {
  return {
    type: types.GET_MARKUP_CONTENT_DETAIL_SUCCESS,
    data,
  };
}

export function getMarkupContentDetailError(error) {
  return {
    type: types.GET_MARKUP_CONTENT_DETAIL_ERROR,
    error,
  };
}

//* toggle upload media box
export function toggleUploadMarkupMedia(status) {
  return {
    type: types.TOGGLE_MARKUP_UPLOAD_MEDIA,
    status,
  };
}

// Curated Query Markup
export const getCuratedQueryGridColumns = (gridName) => ({
  type: types.GET_CURATED_QUERY_GRID_COLUMNS,
  gridName,
});

export const getCuratedQueryGridColumnsSuccess = (columns) => ({
  type: types.GET_CURATED_QUERY_GRID_COLUMNS_SUCCESS,
  columns,
});

export const getCuratedQueryGridColumnsError = (error) => ({
  type: types.GET_CURATED_QUERY_GRID_COLUMNS_ERROR,
  error,
});

export function getCuratedQueryList(pageNumber, pageSize, search) {
  return {
    type: types.GET_CURATED_QUERY_LIST,
    pageIndex: pageNumber,
    pageSize,
    'Search.SearchText': search,
  };
}

export function getCuratedQueryListSuccess(curatedQuery, total) {
  return {
    type: types.GET_CURATED_QUERY_LIST_SUCCESS,
    curatedQuery,
    total,
  };
}

export function getCuratedQueryListError(error) {
  return {
    type: types.GET_CURATED_QUERY_LIST_ERROR,
    error,
  };
}

export const getCuratedQueryShortDetail = (params) => {
  return {
    type: types.GET_CURATED_QUERY_SHORT_DETAIL,
    params,
  };
};
export function getCuratedQueryShortDetailSuccess(data) {
  return {
    type: types.GET_CURATED_QUERY_SHORT_DETAIL_SUCCESS,
    data,
  };
}

export function getCuratedQueryShortDetailError(error) {
  return {
    type: types.GET_CURATED_QUERY_SHORT_DETAIL_ERROR,
    error,
  };
}
