import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectQaSpecScope = (state) => state?.qaSpec || initialState;

const selectLoading = () =>
  createSelector(selectQaSpecScope, (qaSpecState) => qaSpecState.loading);

const selectIsTabExpanded = () =>
  createSelector(selectQaSpecScope, (qaSpecState) => qaSpecState.isTabExpanded);

const selectReloadDocumentAndCertificate = () =>
  createSelector(
    selectQaSpecScope,
    (qaSpecState) => qaSpecState.reloadDocumentAndCertificateTab
  );

const selectReloadDocumentWhenDelete = () =>
  createSelector(
    selectQaSpecScope,
    (qaSpecState) => qaSpecState.reloadDocumentWhenDelete
  );

const selectReloadQaSpecEditBulk = () =>
  createSelector(selectQaSpecScope, (qaSpecState) => qaSpecState.editBulk);

export {
  selectLoading,
  selectIsTabExpanded,
  selectReloadDocumentAndCertificate,
  selectReloadDocumentWhenDelete,
  selectReloadQaSpecEditBulk,
};
