import React from 'react';

import ProductThumbnail from 'pages/branded-products/components/card/thumbnail/ProductThumbnail';

import { EntityList } from 'common/components/list';
import { mappingSelectedItems } from 'utils/brokerManagements';

const SupplierProductGrid = (props) => {
  const { productData, selectedEntityLevel, handleUpdateSelectedLevel } = props;

  const mappingProductList = mappingSelectedItems(
    selectedEntityLevel,
    productData
  );

  return (
    <EntityList
      loading={false}
      entityList={mappingProductList}
      col={3}
      classNameObj={{ 'scroller--y': true }}
    >
      {(entityItem) => {
        return (
          <ProductThumbnail
            dataDetail={entityItem}
            selected={entityItem.selected}
            imageThumbnail={entityItem?.thumb || entityItem?.thumbnail}
            onClickItemGrid={handleUpdateSelectedLevel}
            onClickCheckboxItem={handleUpdateSelectedLevel}
            isProductThumbnail={true}
            isShareBtn={false}
          />
        );
      }}
    </EntityList>
  );
};

export default SupplierProductGrid;
