import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import DetailView from '../../controls/home/DetailView';

import * as actions from 'redux/global/actions';
import * as selectorsGlobal from 'redux/global/selectors';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import * as helpMaintenanceSelectors from 'pages/maintenance/controllers/selectors';
import * as helpMaintenanceActions from 'pages/maintenance/controllers/actions';
import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';
import * as memberMaintenanceActions from 'pages/member-maintenance/controllers/actions';

const DetailSection = (props) => {
  const { disabled } = props;
  const dispatch = useDispatch();
  const isShowDetailOn = useSelector(selectorsGlobal.selectShowDetail());
  const showAdvanceFilter = useSelector(
    selectorsGlobal.selectShowAdvanceFilter()
  );
  const isDetailShow = useSelector(selectorsGridView.makeSelectVisible());

  const itemsSelection = useSelector(
    selectorsGridView.makeSelectItemsSelection()
  );
  const selectedItemsDetail = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );
  const selectedCategory = useSelector(
    helpMaintenanceSelectors.selectSelectCategory()
  );
  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );
  const isVisibleCategory = useSelector(
    helpMaintenanceSelectors.selectIsVisibleCategory()
  );

  const disablePreview =
    !disabled &&
    (selectedItemsDetail.length === 1 || selectedCategory?.length === 1) &&
    !showAdvanceFilter
      ? false
      : true;

  const detailClickHandler = () => {
    toggleDetailView();
    showContentPane();

    if (window.location.pathname === '/maintenance/help/category') {
      dispatch(helpMaintenanceActions.updateCategoryPanel(!isVisibleCategory));
    }

    if (isDetailShow) {
      dispatch(actionsGridView.updateVisible(false));
    } else {
      dispatch(actionsGridView.updateVisible(true));
    }
  };

  const showContentPane = () => {
    const shouldShowDetail = !isShowDetailOn && itemsSelection?.length === 1;

    shouldShowDetail && dispatch(actionsGridView.updateVisible(true));
  };

  const toggleDetailView = () => {
    dispatch(actions.toggleDetail());
  };

  useEffect(() => {
    return () => {
      dispatch(memberMaintenanceActions.changeMemberGridInfoView(true));
      dispatch(actionsGridView.updateVisible(false));
      dispatch(actions.hideDetail());
    };
  }, [dispatch]);

  useEffect(() => {
    (disablePreview || !isMemberGridInfoView) &&
      dispatch(actionsGridView.updateVisible(false));
  }, [disablePreview, isMemberGridInfoView]);

  return (
    <DetailView
      detailClick={detailClickHandler}
      disabledBtn={disablePreview || !isMemberGridInfoView}
      isDetailShow={isDetailShow}
    />
  );
};

export default DetailSection;
