import { RetweetOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Form, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  CustomNotification,
  FormAddButton,
  ProductCategoryTree,
  dialogFunction,
} from 'common/components';

import SelectedProductList from './SelectedProductList';
import messages from 'i18n/messages/home';
import { StyledModal } from 'common/components';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import { useGetMemberId } from 'hooks/useGetMemberId';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useGetProductCategoryTreeView } from 'pages/company-profile/components/tabs/system/category-management/hooks/useProductCategoryManagement';
import * as categoryManagement from 'services/categoryManagement';
import { EVENT } from 'static/Constants';
import { apiHandler } from 'utils/api';

import ProductCategoryForm from 'pages/company-profile/components/tabs/system/category-management/components/ProductCategoryForm';
import {
  generateIdsBySelectedKey,
  getCategoryObjectWhenSelectNode,
} from 'pages/company-profile/components/tabs/system/category-management/utils';

import './AssignCategoryToProductModal.less';
import { useUserInfo } from 'hooks';
import { useGetProductVersionList } from 'pages/product-history/hook';

const { Title } = Typography;

const AssignCategoryToProductModal = ({
  visible,
  setVisible,
  isProductDetail = false,
  productFull,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [nodeSelected, setNodeSelected] = useState(null);
  const [idDeletedList, setIdDeletedList] = useState([]);
  const [isPrefetching, setIsPrefetching] = useState(true);
  const [isBlankCategory, setIsBlankCategory] = useState(false);
  const [loadingAssignCategoryToProduct, setLoadingAssignCategoryToProduct] =
    useState(false);

  const { memberId } = useGetMemberId();

  const { userInfo } = useUserInfo();
  const currentMemberId = userInfo?.member.id;

  const reloadPage = useDispatchReloadPage();

  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const { handleRefetchProductVersioning } = useGetProductVersionList({
    enabled: false,
  });

  const { nestedCategory, isLoading } = useGetProductCategoryTreeView(
    currentMemberId,
    visible,
    isPrefetching,
    setIsPrefetching
  );

  const getProductSelectedList = () => {
    const productSelected = isProductDetail
      ? [productFull]
      : selectedItemDetailList;

    const productFilter = productSelected.filter(
      (item) => !idDeletedList.includes(item?.id)
    );
    return productFilter;
  };

  const productSelectedList = getProductSelectedList();

  const isDisabled =
    !isBlankCategory && (!nodeSelected || !productSelectedList.length);

  const onCancel = () => {
    setVisible(false);
  };

  const dispatchReloadCategoryEvent = () => {
    const event = new CustomEvent(EVENT.RELOAD_CATEGORY);
    document.dispatchEvent(event);
  };

  const resetActiveProducts = () => {
    setIdDeletedList([]);
  };

  const handleRemoveActiveProduct = (productItem) => {
    setIdDeletedList((prev) => [...prev, productItem?.id]);
  };

  const onSelectNode = (nodeFound) => {
    const fieldsValues = getCategoryObjectWhenSelectNode(
      nestedCategory,
      nodeFound
    );
    setIsBlankCategory(false);
    form.setFieldsValue(fieldsValues);
    setNodeSelected(nodeFound);
  };

  const sendPayLoadToBeWhenAssignCategoryToProduct = (forceUpdate = false) => {
    let params = {};

    const idProductAssignList = productSelectedList.map(
      (productItem) => productItem.id
    );

    if (isBlankCategory) {
      params = {
        category1Id: null,
        category2Id: null,
        category3Id: null,
        category4Id: null,
      };
    } else {
      const idCategoryList = generateIdsBySelectedKey(nodeSelected?.key ?? '');
      for (let i = 0; i < idCategoryList.length; i++) {
        params[`category${i + 1}Id`] = idCategoryList[i];
      }
    }

    const idProductList = isProductDetail
      ? [productFull.productId]
      : idProductAssignList;

    return (params = {
      ...params,
      memberId,
      productItemIds: idProductList,
      forceUpdate,
    });
  };

  const unSelectNode = () => {
    const unSelectNode = new CustomEvent('unSelectNode', {
      detail: 'reset-category-modal',
    });
    document.dispatchEvent(unSelectNode);
  };

  const setEmptyCategory = () => {
    form.resetFields();
    unSelectNode();
    setNodeSelected(null);
    setIsBlankCategory(true);
  };

  const successCallbackReassign = () => {
    dispatchReloadCategoryEvent();
    setVisible(false);
    reloadPage({ clearSelection: true });
    setLoadingAssignCategoryToProduct(false);
    dispatch(actionsGridView.updatePagesSelection([]));
    dispatch(actionsGridView.updateItemPageSelection([]));
    handleRefetchProductVersioning();
  };

  const onOkReassignCategoryToProduct = () => {
    setLoadingAssignCategoryToProduct(true);
    const params = sendPayLoadToBeWhenAssignCategoryToProduct(true);
    apiHandler({
      service: categoryManagement.assignCategoryToProduct,
      params,
      successMessage: intl.formatMessage(
        messages.assignCategoryToProductSuccess
      ),
      errorMessage: intl.formatMessage(messages.assignCategoryToProductFail),

      successCallback: successCallbackReassign,
      onFinally: () => {
        setLoadingAssignCategoryToProduct(false);
      },
    });
  };

  const AssignCategoryToProduct = () => {
    setLoadingAssignCategoryToProduct(true);
    const confirmOverWriteCategory = isProductDetail
      ? 'confirmOverwriteCategoryProductDetail'
      : 'confirmOverwriteCategoryProductGrid';

    const params = sendPayLoadToBeWhenAssignCategoryToProduct();

    apiHandler({
      service: categoryManagement.assignCategoryToProduct,
      params,
      successMessage: intl.formatMessage(
        messages.assignCategoryToProductSuccess
      ),
      errorMessage: intl.formatMessage(messages.assignCategoryToProductFail),
      skipNotification: true,
      successCallback: (data) => {
        if (!data.existProductLinks) {
          CustomNotification.success(
            intl.formatMessage(messages.assignCategoryToProductSuccess)
          );
          successCallbackReassign();
        }
      },
      failCallback: () => {
        setLoadingAssignCategoryToProduct(false);
        dialogFunction({
          type: 'warn',
          content: intl.formatMessage(messages[confirmOverWriteCategory]),
          okText: 'OK',
          cancelText: 'Cancel',
          onOk: () => onOkReassignCategoryToProduct(),
        });
      },
    });
  };

  const renderModalFooter = () => {
    const CancelBtn = Button;
    const AssignBtn = Button;

    return (
      <Row>
        <Col flex={1}></Col>
        <Col flex={0}>
          <CancelBtn
            onClick={onCancel}
            disabled={isLoading || loadingAssignCategoryToProduct}
          >
            {intl.formatMessage(messages.previewRequirementsCancelButton)}
          </CancelBtn>
          <AssignBtn
            type='primary'
            onClick={AssignCategoryToProduct}
            loading={loadingAssignCategoryToProduct}
            disabled={isDisabled}
          >
            {intl.formatMessage(messages.assignCategoryToProductText)}
          </AssignBtn>
        </Col>
      </Row>
    );
  };

  const modalProps = {
    title: intl.formatMessage(messages.assignCategoryToProduct),
    visible,
    onCancel,
    width: '1150px',
    bodyStyle: { maxHeight: '670px', height: '66vh' },
    forceRender: true,
    footer: renderModalFooter(),
    destroyOnClose: true,
  };

  return (
    <StyledModal {...modalProps}>
      <Row
        gutter={[24, 0]}
        style={{ height: '100%' }}
        className='assign-category-product'
      >
        <Col span={13} style={{ height: '50%' }}>
          <Form
            autoComplete='off'
            form={form}
            className='assign-category-product__form scroller scroller--y'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <div style={{ paddingLeft: '25px' }}>
              <ProductCategoryForm disableForm={isBlankCategory} />
            </div>
          </Form>
        </Col>
        <Col span={11} style={{ height: '50%' }}>
          <ProductCategoryTree
            mode={false}
            isLoading={isLoading}
            nestedCategory={nestedCategory}
            showEmptyCategory
            onSelect={onSelectNode}
            setEmptyCategory={setEmptyCategory}
          />
        </Col>
        <Col span={24} style={{ height: '50%' }}>
          <Row justify='space-between' align='middle'>
            <Col>
              <Title
                level={5}
                style={{
                  fontSize: '14px',
                }}
              >
                {intl.formatMessage(messages.assignedProductTitle)} (
                {productSelectedList.length})
              </Title>
            </Col>
            <Col className='assign-category-product_reset'>
              <FormAddButton
                text='Reset'
                icon={<RetweetOutlined />}
                onClick={resetActiveProducts}
                disabled={!idDeletedList.length}
              />
            </Col>
            <Col
              span={24}
              className='assign-category-product__selected-product scroller'
            >
              {productSelectedList.length > 0 ? (
                <SelectedProductList
                  productList={productSelectedList}
                  handleRemoveActiveProduct={handleRemoveActiveProduct}
                />
              ) : (
                <Row className='assign-category-product__empty'>
                  <Empty />
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default AssignCategoryToProductModal;
