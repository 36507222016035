import React from 'react';
import { useDispatch } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/maintenance';

import RibbonButton from 'common/components/button/RibbonButton';
import { resetTemplate } from 'pages/communication-template/controllers/actions';

import * as maintainHelpActions from 'pages/maintenance/controllers/actions';

import { forwardTo } from 'utils/common/route';

const BackToTemplate = () => {
  const dispatch = useDispatch();

  const handleBackToTemplate = () => {
    dispatch(resetTemplate());
    dispatch(maintainHelpActions.toggleUploadHelpMedia(false));
    forwardTo('/communication-templates');
  };

  return (
    <RibbonButton
      icon={<LogoutOutlined style={{ transform: 'rotate(180deg)' }} />}
      label={Messages.backToTemplate}
      onClick={handleBackToTemplate}
    />
  );
};

export default BackToTemplate;
