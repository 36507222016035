import produce from 'immer';
import * as types from './constants';

export const initialState = {
  loading: false,
  assetData: null,
  digitalAsset: null,
  requiredFields: [],
  isActive: false,
  metaData: null,
  error: null,
  columns: [],
  gridName: '',
  ftpFileList: {
    data: null,
    loading: false,
    error: null,
  },
  postFtpFile: {
    status: null,
    loading: false,
    error: null,
  },
  editSuccess: false,
  editError: false,
  authorizedTagsLoading: false,
  authorizedTags: {
    enforceTagRestrictions: false,
    authorizedTags: [],
  },
  authorizedTagsError: null,
};

/* eslint-disable default-case, no-param-reassign */
const assetDataReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_ASSET_DATA:
        draft.loading = true;
        draft.error = null;
        // draft.assetData = null;
        break;
      case types.GET_ASSET_DATA_SUCCESS:
        draft.loading = false;
        draft.editSuccess = false;
        draft.assetData = action.data;
        draft.error = null;
        break;
      case types.GET_ASSET_DATA_ERROR:
        draft.loading = false;
        draft.editSuccess = false;
        draft.error = action.error;
        draft.assetData = null;
        break;
      //* get ftp file list
      case types.GET_FTP_FILE_LIST:
        draft.ftpFileList.loading = true;
        draft.ftpFileList.error = null;
        break;
      case types.GET_FTP_FILE_LIST_SUCCESS:
        draft.ftpFileList.loading = false;
        draft.ftpFileList.data = action.payload;
        draft.ftpFileList.error = null;
        break;
      case types.GET_FTP_FILE_LIST_ERROR:
        draft.ftpFileList.loading = false;
        draft.ftpFileList.error = action.error;
        draft.ftpFileList.data = null;
        break;
      //*create asset
      case types.CREATE_DIGITAL_ASSET_FULL:
        draft.loading = true;
        break;
      case types.CREATE_DIGITAL_ASSET_FULL_SUCCESS:
        draft.loading = false;
        draft.metaData = { ...action.metaData, isOwner: true };
        break;
      case types.CREATE_DIGITAL_ASSET_FULL_ERROR:
        draft.loading = false;
        break;
      case types.UPDATE_DIGITAL_ASSET:
        draft.loading = true;
        break;
      case types.UPDATE_IS_ACTIVE:
        draft.isActive = action.payload;
        break;
      case types.UPDATE_DIGITAL_REPLACE_ASSET:
        draft.loading = true;
        draft.editError = false;
        break;
      case types.UPDATE_DIGITAL_ASSET_SUCCESS:
        draft.loading = false;
        draft.editSuccess = true;
        break;
      case types.UPDATE_REQUIRED_FIELDS:
        draft.requiredFields = action.params;
        break;
      case types.UPDATE_DIGITAL_ASSET_ERROR:
        draft.loading = false;
        draft.editError = action.error;
        draft.editSuccess = false;
        break;
      case types.GET_ASSET_ASSOCIATION_GRID_COLUMN:
        draft.loading = true;
        draft.error = false;
        draft.gridName = action.gridName;
        break;
      case types.GET_ASSET_ASSOCIATION_GRID_COLUMN_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;
      case types.GET_ASSET_ASSOCIATION_GRID_COLUMN_ERROR:
        // draft.loading = false;
        break;
      case types.CANCEL_UPDATE_SUCCESS:
        draft.editSuccess = false;
        break;
      case types.GET_AUTHORIZED_TAGS:
        draft.authorizedTagsLoading = true;
        break;
      case types.GET_AUTHORIZED_TAGS_SUCCESS:
        draft.authorizedTagsLoading = false;
        draft.authorizedTags = action.data;
        break;
      case types.GET_AUTHORIZED_TAGS_ERROR:
        draft.authorizedTagsLoading = false;
        draft.authorizedTagsError = action.error;
        break;
      default:
        break;
    }
  });

export default assetDataReducer;
