import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Typography } from 'antd';
import { StyledModal, dialogFunction } from 'common/components';
import {
  MemberRoleList,
  MemberRolePermissionList,
  MemberRoleSectionWrapper,
  MemberRoleDescription,
} from 'common/components';

import * as roleActions from 'pages/role/controllers/actions';
import * as roleSelectors from 'pages/role/controllers/selectors';

import * as securityServices from 'services/roles-security';

import { useHandleReloadPage } from 'hooks/useReloadPage';
import { apiHandler } from 'utils/api';

import './AddRoleToMemberModal.less';

/**
 * ! MAIN COMPONENT
 * @param {*} props
 * @returns
 */
const AddRoleToMemberModal = (props) => {
  const { isVisible, toggleModal, selectedMembers } = props;

  const dispatch = useDispatch();

  const [__, setReloadGrid] = useHandleReloadPage();

  const selectedMemberRole = useSelector(
    roleSelectors.selectSelectedMemberRole()
  );

  const handleAddMemberToRole = async () => {
    const params = {
      roleId: selectedMemberRole?.roleId,
      memberIds: selectedMembers,
    };

    const successCallback = () => {
      toggleModal();
      setReloadGrid();
    };
    await apiHandler({
      service: securityServices.addMembersToRole,
      params,
      successMessage: 'Assign role to selected member successfully',
      errorMessage: 'Failed to assign role to selected(member)',
      successCallback,
    });
  };

  const confirmAddMemberToRole = () => {
    const infoContent = (
      <Typography.Paragraph>
        Do you want to add the selected member(s) to the role&nbsp;
        <Typography.Text strong>{selectedMemberRole?.roleName}</Typography.Text>
        ?
      </Typography.Paragraph>
    );

    dialogFunction({
      type: 'warn',
      content: infoContent,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleAddMemberToRole,
    });
  };

  useEffect(() => {
    //* reset selected member role
    const resetSelectedMemberRole = () => {
      if (!isVisible) {
        dispatch(roleActions.setSelectedMemberRole(null));
      }
    };

    resetSelectedMemberRole();
  }, [isVisible]);

  return (
    <StyledModal
      className='add-role-to-member-modal'
      title='Assign role to member'
      visible={isVisible}
      okText='Assign role'
      maskClosable={false}
      destroyOnClose={true}
      onCancel={toggleModal}
      onOk={confirmAddMemberToRole}
      width={800}
      okButtonProps={{ disabled: !selectedMemberRole }}
    >
      <AddRoleModalContent selectedMemberRole={selectedMemberRole} />
    </StyledModal>
  );
};

/**
 * ! SUB COMPONENT
 * @param {*} props
 * @returns
 */
const AddRoleModalContent = (props) => {
  const { selectedMemberRole } = props;

  const dispatch = useDispatch();

  const memberRoleList = useSelector(roleSelectors.selectMemberRoleList());

  const memberRoleListData = memberRoleList?.data;

  const availMemberPermissionList = useSelector(
    roleSelectors.selectAvailMemberPermissionList()
  );

  useEffect(() => {
    //* get member role list
    const getMemberRoleList = () => {
      dispatch(roleActions.getMemberRoleList());
    };

    //* get all member (avail) permission
    const getAllMemberPermisisonList = () => {
      dispatch(roleActions.getAllMemberPermissionList());
    };

    getMemberRoleList();
    getAllMemberPermisisonList();
  }, []);
  return (
    <div className='add-role-to-member-modal'>
      <Row gutter={[5, 5]} style={{ flex: 'auto', overflow: 'hidden' }}>
        <Col xs={12} style={{ height: '100%' }}>
          <MemberRoleSectionWrapper
            className='add-role-to-member-modal__role-list'
            title='member role list'
            isEdit={false}
          >
            <MemberRoleList
              roleListData={memberRoleListData}
              selectedRole={selectedMemberRole}
            />
          </MemberRoleSectionWrapper>
        </Col>
        <Col xs={12} style={{ height: '100%' }}>
          <MemberRoleSectionWrapper
            className='add-role-to-member-modal__permission-list'
            title='member permission list'
            isEdit={false}
          >
            <MemberRolePermissionList
              selectedRole={selectedMemberRole}
              availMemberPermissionList={availMemberPermissionList}
            />
          </MemberRoleSectionWrapper>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <MemberRoleSectionWrapper
            className='add-role-to-member-modal__role-desc'
            title='member role description'
            isEdit={false}
          >
            <MemberRoleDescription selectedRole={selectedMemberRole} />
          </MemberRoleSectionWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default AddRoleToMemberModal;
