import { useDispatch, useSelector } from 'react-redux';

import { CustomNotification } from 'common/components';

import * as folderActions from 'pages/folders/controllers/actions';
import * as folderSelectors from 'pages/folders/controllers/selectors';

import { sleep } from 'utils/delay';
import { sortFolderToTop } from 'pages/folders/shared/utils';

import { getItemsOfFolder, getFavoriteFolderList } from 'services/new-folder';

export const useFolderByLevel = () => {
  const dispatch = useDispatch();

  const { folderBreadcrumb, shouldKeepFolderBreadcrumb } = useSelector(
    folderSelectors.selectFolderListByLevel()
  );

  const showGridLoading = ({ folderOption, preventCallApiGridView } = {}) => {
    dispatch(
      folderActions.getFolderListByLevelSuccess({
        data: [],
        folderOption,
        loading: true,
        preventCallApiGridView,
      })
    );
  };

  const handleFetchFolderByLevel = async ({
    folderId,
    params = {},
    folderOption,
    successCallback,
  }) => {
    const defaultParams = {
      folderId: folderId,
      pageIndex: 1, // change later
      pageSize: 99999, // change later
      filters: [],
      sort: [
        {
          fieldName: 'id',
          isAscending: false,
        },
      ],
    };

    const requestParams = { ...defaultParams, ...params };

    //* reset state, show loading
    showGridLoading({ preventCallApiGridView: true, folderOption });
    await sleep(200);

    const response =
      params.isFavoriteRoute && !folderId //* just only call favorite grid api when at home
        ? await getFavoriteFolderList(requestParams)
        : await getItemsOfFolder(requestParams);

    try {
      if (response?.isSuccess) {
        const gridData = response?.data?.gridData ?? [];
        const folderData = gridData
          .map((folder) => ({
            ...folder,
            folderName: folder?.folderName || folder?.description,
          }))
          .sort(sortFolderToTop);

        dispatch(
          folderActions.getFolderListByLevelSuccess({
            data: folderData,
            folderOption,
            loading: false,
            preventCallApiGridView: false,
          })
        );

        successCallback && successCallback(folderData);
      } else {
        dispatch(
          folderActions.getFolderListByLevelSuccess({
            data: [],
            folderOption,
            loading: false,
          })
        );
        CustomNotification.error('Something went wrong');
      }
    } catch (error) {}
  };

  const fetchThumbnailList = (additionalParams = {}) => {
    const { folderOption = 'Folders' } = additionalParams;
    const currentFolder = folderBreadcrumb[folderBreadcrumb.length - 1];
    const isHome = currentFolder?.id === 'home';

    let params = {
      pageIndex: 1,
      pageSize: 9999, // don't need the pagination, so fetch as much as possible
      ...additionalParams,
    };

    handleFetchFolderByLevel({
      folderId: isHome ? null : currentFolder?.id,
      folderOption,
      params,
    });
  };

  const keepFolderBreadcrumb = (value) => {
    dispatch(folderActions.keepBreadcrumb(value));
  };

  const updateShouldKeepBreadcrumb = (value) => {
    dispatch(folderActions.updateShouldKeepBreadcrumb(value));
  };

  const updateFolderBreadcrumb = (folderBreadcrumb) => {
    dispatch(folderActions.updateFolderBreadcrumb(folderBreadcrumb)); // keep breadcrumb
  };

  const resetFolderBreadcrumb = () => {
    updateFolderBreadcrumb([
      {
        id: 'home',
        folderName: 'Home',
      },
    ]);
  };

  const isRoot = folderBreadcrumb.length === 1; // only has home path
  const currentBreadcrumb = folderBreadcrumb[folderBreadcrumb.length - 1];

  return {
    isRoot,

    folderBreadcrumb,
    currentBreadcrumb,
    updateFolderBreadcrumb,
    resetFolderBreadcrumb,

    handleFetchFolderByLevel,
    fetchThumbnailList,

    keepFolderBreadcrumb,
    shouldKeepFolderBreadcrumb,
    updateShouldKeepBreadcrumb,
    showGridLoading,
  };
};
