import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as communicationSelectors from 'pages/communication-template/controllers/selectors';
import * as communicationActions from 'pages/communication-template/controllers/actions';
import * as selectorsGlobal from 'redux/global/selectors';

import { deleteCommunicationTemplatesService } from 'services/communicationTemplate';

import { useFilterGrid } from 'hooks/useFilterGrid';

import Messages from 'i18n/messages/maintenance';
import { useIntl } from 'react-intl';

const DeleteCommunicationTemplates = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { pageSize, pageNumber } = useSelector(
    communicationSelectors.selectPaging()
  );

  const communicationTemplatesIds = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  ).map((communication) => communication.id);

  const searchAllItems = useSelector(selectorsGlobal.selectSearchAllItems());

  useFilterGrid({
    clearOtherFilter: true,
  });

  const callApiDelete = async () => {
    try {
      const response = await deleteCommunicationTemplatesService({
        communicationTemplatesIds,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        dispatch(
          communicationActions.getCommunicationTemplates(
            pageSize,
            pageNumber,
            searchAllItems
          )
        );
        showSuccessMessageDialog();
      } else {
        callApiErrorHandler(message);
      }
    } catch (error) {
      callApiErrorHandler(error);
    }
  };

  const showSuccessMessageDialog = () => {
    notification.success({
      message: intl.formatMessage(Messages.deleteSuccess),
    });
  };

  const callApiErrorHandler = (error) => {
    notification.error({
      message: error,
    });
  };

  const handleDeleteCommunications = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.areYouSureToDelete),
      onOk: callApiDelete,
    });
  };

  return (
    <RibbonButton
      size='small'
      icon={<DeleteOutlined />}
      label={Messages.deleteTemplate}
      onClick={handleDeleteCommunications}
      disabled={communicationTemplatesIds.length === 0}
    />
  );
};

export default DeleteCommunicationTemplates;
