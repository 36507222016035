import React, { useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import CopyProductPropertiesModal from 'pages/home/ribbon/components/modal/bulk-edit-product/CopyProductPropertiesModal';
import messages from 'i18n/messages/home';
import { RibbonButton } from 'common/components';

function ProductClone({ isDisabled, productId }) {
  const [cloneModalVisible, setCloneModalVisible] = useState(false);

  return (
    <>
      <RibbonButton
        size='small'
        icon={<CopyOutlined style={{ height: 26 }} />}
        label={messages.productClone}
        onClick={() => setCloneModalVisible(true)}
        disabled={isDisabled}
        className='button-item-dropdown'
      />
      {cloneModalVisible && (
        <CopyProductPropertiesModal
          visible={cloneModalVisible}
          setVisible={setCloneModalVisible}
          productId={productId}
        />
      )}
    </>
  );
}

export default ProductClone;
