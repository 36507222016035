import React from 'react';

import { Menu, Dropdown } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import SearchFolder from './SearchFolder';
import DeleteFolder from './DeleteFolder';

import Messages from 'i18n/messages/home';
import RibbonButton from 'common/components/button/RibbonButton';

import { Can, AbilityContext } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import OpenAdvanceFilter from 'common/components/nested-advance-Search/OpenAdvanceFilter';
import NestedAdvanceFilter from 'common/components/nested-advance-Search/NestedAdvanceFilter';

const AdvanceStack = (props) => {
  const { isDisabled, disabledAdvanceFilter, selectedItemList } = props;
  // check section disabled
  const checkSectionDisabled = () => {
    const hasSelectedItem = selectedItemList?.length > 0;

    const selectedFolder = selectedItemList?.filter((selectedItem) => {
      return selectedItem?.type === 'Folder';
    });

    const hasSelectedFolder = selectedFolder?.length > 0;

    return !hasSelectedItem || hasSelectedFolder;
  };

  const menuOptionProps = {
    selectedItemList,
    isSectionDisabled: checkSectionDisabled(),
  };

  const renderMenuOptions = (menuOptionProps) => (
    <Menu
      onClick={({ item, key }) => {
        // if (key === 'advancedSearch') {
        //   dispatch()
        // }
      }}
    >
      {/* <Menu.Item key='advancedSearch' style={{ backgroundColor: 'inherit' }}>
        <AdvanceSearch />
      </Menu.Item> */}
      <Menu.Item style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.FOLDER} passThrough>
          {(allowed) => (
            <SearchFolder disabled={!allowed} {...menuOptionProps} />
          )}
        </Can>
      </Menu.Item>
      <Menu.Item style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.FOLDER}>
          <DeleteFolder {...menuOptionProps} />
        </Can>
      </Menu.Item>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.REPORTING}>
        <Menu.Item style={{ backgroundColor: 'inherit' }}>
          <OpenAdvanceFilter disabled={disabledAdvanceFilter} />
        </Menu.Item>
      </Can>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={renderMenuOptions(menuOptionProps)}
        placement='bottomLeft'
        arrow
        disabled={isDisabled}
      >
        <RibbonButton
          icon={<SettingOutlined />}
          disabled={isDisabled}
          label={Messages.advanceStack}
        />
      </Dropdown>
      <NestedAdvanceFilter />
    </>
  );
};

AdvanceStack.propTypes = {};

export default AdvanceStack;
