export const GET_POST_GRID = 'GET_POST_GRID';
export const GET_POST_GRID_SUCCESS = 'GET_POST_GRID_SUCCESS';
export const GET_POST_GRID_ERROR = 'GET_POST_GRID_ERROR';
export const GET_POST_GRID_COLUMN = 'GET_POST_GRID_COLUMN';
export const GET_POST_GRID_COLUMN_SUCCESS = 'GET_POST_GRID_COLUMN_SUCCESS';
export const GET_POST_GRID_COLUMN_ERROR = 'GET_POST_GRID_COLUMN_ERROR';
export const TOGGLE_EDIT_CATEGORY = 'TOGGLE_EDIT_CATEGORY';
export const TOGGLE_CATEGORY_DETAIL = 'TOGGLE_CATEGORY_DETAIL';
export const GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN =
  'GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN';
export const GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_SUCCESS =
  'GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_SUCCESS';
export const GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_ERROR =
  'GET_HELP_MAINTENANCE_CATEGORY_GRID_COLUMN_ERROR';
export const GET_HELP_MAINTENANCE_CATEGORY_GRID =
  'GET_HELP_MAINTENANCE_CATEGORY_GRID';
export const GET_HELP_MAINTENANCE_CATEGORY_GRID_SUCCESS =
  'GET_HELP_MAINTENANCE_CATEGORY_GRID_SUCCESS';
export const GET_HELP_MAINTENANCE_CATEGORY_GRID_ERROR =
  'GET_HELP_MAINTENANCE_CATEGORY_GRID_ERROR';
export const DISABLED_VIEW_MODE = 'DISABLED_VIEW_MODE';
export const GET_POST_CONTENT_DETAIL = 'GET_POST_CONTENT_DETAIL';
export const GET_POST_CONTENT_DETAIL_SUCCESS =
  'GET_POST_CONTENT_DETAIL_SUCCESS';
export const GET_POST_CONTENT_DETAIL_ERROR = 'GET_POST_CONTENT_DETAIL_ERROR';
export const CHANGE_SELECT_CATEGORY = 'CHANGE_SELECT_CATEGORY';
export const ENABLE_EDIT_MODE = 'ENABLE_EDIT_MODE';
export const CLOSE_EDIT_CATEGORY = 'CLOSE_EDIT_CATEGORY';
export const SUBMIT_CATEGORY = 'SUBMIT_CATEGORY';
export const SUBMIT_CATEGORY_SUCCESS = 'SUBMIT_CATEGORY_SUCCESS';
export const SUBMIT_CATEGORY_ERROR = 'SUBMIT_CATEGORY_ERROR';
export const SUBMIT_CATEGORY_FINISH = 'SUBMIT_CATEGORY_FINISH';
export const TOGGLE_CREATE_CATEGORY = 'TOGGLE_CREATE_CATEGORY';
export const CLOSE_CREATE_CATEGORY = 'CLOSE_CREATE_CATEGORY';

// Help-maintenance post
export const ENABLE_HELP_MAINTENANCE_CREATE_POST =
  'ENABLE_HELP_MAINTENANCE_CREATE_POST';
// Help-maintenance post
export const ENABLE_HELP_MAINTENANCE_OPEN_POST =
  'ENABLE_HELP_MAINTENANCE_OPEN_POST';
export const ENABLE_HELP_MAINTENANCE_EDIT_POST =
  'ENABLE_HELP_MAINTENANCE_EDIT_POST';
export const DISABLE_HELP_MAINTENANCE_EDIT_POST =
  'DISABLE_HELP_MAINTENANCE_EDIT_POST';

export const SAVE_HELP_MAINTENANCE_POST = 'SAVE_HELP_MAINTENANCE_POST';
export const SAVE_HELP_MAINTENANCE_POST_SUCCESS =
  'SAVE_HELP_MAINTENANCE_POST_SUCCESS';
export const SAVE_HELP_MAINTENANCE_POST_ERROR =
  'SAVE_HELP_MAINTENANCE_POST_ERROR';

export const SAVE_POST_DETAIL = 'SAVE_POST_DETAIL';
export const SAVE_POST_DETAIL_SUCCESS = 'SAVE_POST_DETAIL_SUCCESS';
export const SAVE_POST_DETAIL_FAIL = 'SAVE_POST_DETAIL_FAIL';

export const RESET_DATA_POST = 'RESET_DATA_POST';
export const CONFIRM_CHANGE_UPDATE = 'CONFIRM_CHANGE_UPDATE';
export const SAVE_ID_POST = 'SAVE_ID_POST';
//* upload media source for create/edit help post
export const UPDATE_HELP_ASSET_FILE = 'UPDATE_HELP_ASSET_FILE';
export const UPDATE_HELP_ASSET_FILE_SUCCESS = 'UPDATE_HELP_ASSET_FILE_SUCCESS';
//* toggle upload media
export const TOGGLE_HELP_UPLOAD_MEDIA = 'TOGGLE_HELP_UPLOAD_MEDIA';

export const CLOSE_CATEGORY_PANEL = 'CLOSE_CATEGORY_PANEL';
//* get help asset files
export const GET_HELP_ASSETS = 'GET_HELP_ASSETS';
export const GET_HELP_ASSETS_SUCCESS = 'GET_HELP_ASSETS_SUCCESS';
//* update category panel
export const UPDATE_CATEGORY_PANEL = 'UPDATE_CATEGORY_PANEL';
