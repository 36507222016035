import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { URL_BLOCKING_EVENT_NAME } from './event';
import { checkRouteBlocked } from './utils';
import { dispatchCustomReactRouterBlockingEvent } from './event';

import history from 'utils/common/history';

const useHandleHistoryBlockEvent = ({
  blockId,
  setIsShowModal,
  setNextUrl,
  isPreventRouting,
}) => {
  const location = useLocation();
  /**
   ** all block checkers will listen to this event handlder
   * @param {*} e
   * @returns
   */
  const handleHistoryBlockEvent = (e) => {
    const { location } = e.detail;
    const isSomeBlocked = checkRouteBlocked();
    const isBlocked = checkRouteBlocked(blockId);

    if (isSomeBlocked) {
      if (location) {
        setNextUrl(`${location.pathname}${location.search}`);
      } else {
        setNextUrl(undefined);
      }
      if (isBlocked) {
        setIsShowModal(true);
      }

      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    global.routeUnblock = history.block(function (location) {
      const isSomeBlocked = checkRouteBlocked();

      if (isSomeBlocked) {
        dispatchCustomReactRouterBlockingEvent({ location });
        return false;
      } else {
        return true;
      }
    });
    return global.routeUnblock;
  }, [location, isPreventRouting, setIsShowModal]);

  useEffect(() => {
    window.addEventListener(URL_BLOCKING_EVENT_NAME, handleHistoryBlockEvent);

    return () => {
      window.removeEventListener(
        URL_BLOCKING_EVENT_NAME,
        handleHistoryBlockEvent
      );
    };
  }, []);
};

export { useHandleHistoryBlockEvent };
