import React, { useState } from 'react';

import { Row, Col, Checkbox, Typography, Input, AutoComplete } from 'antd';

import { getFieldsEditedSuggestion, getFieldsSuggestion } from './utils';

import reactCSS from 'reactcss';

const QueryProperties = React.memo((props) => {
  const {
    sideProperties,
    isEdit = false,
    showSearch = false,
    showCheckbox = true,
    productSchema,
    onSearch,
    onExpandAll,
    onOnlyShowValues,
    isExpanded,
  } = props;

  const [fieldsSuggestion, setFieldsSuggestion] = useState([]);

  const handleSearch = (value) => {
    if (isEdit) {
      const suggestion = getFieldsEditedSuggestion(value, productSchema);
      setFieldsSuggestion(suggestion);
    } else {
      const suggestion = getFieldsSuggestion(value, productSchema);
      setFieldsSuggestion(suggestion);
    }

    onSearch(value);
  };

  const handleSelectSuggestion = (_, option) => {
    onSearch(option);
  };

  const styles = reactCSS({
    default: {
      wrapper: {
        marginBottom: 6,
        paddingRight: 10,
      },
      inputSearchWrap: {
        paddingLeft: 20,
      },
    },
  });

  return (
    <Row align='middle' justify='end' style={styles.wrapper}>
      {showSearch && (
        <Col xxl={14} xl={12} md={9} xs={7} style={styles.inputSearchWrap}>
          <AutoComplete
            style={{ width: '100%' }}
            onSearch={handleSearch}
            onSelect={handleSelectSuggestion}
            options={fieldsSuggestion}
          >
            <Input.Search
              placeholder='Search for field'
              allowClear
              // enterButton
            />
          </AutoComplete>
        </Col>
      )}

      {showCheckbox && (
        <>
          <Col flex='auto' />
          <Col>
            <Checkbox
              onChange={(event) => {
                onExpandAll(event.target.checked, sideProperties);
              }}
            >
              <Typography.Text strong>Expand All</Typography.Text>
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              onChange={(event) => {
                onOnlyShowValues(event.target.checked, sideProperties);
              }}
            >
              <Typography.Text strong>Show Values Only</Typography.Text>
            </Checkbox>
          </Col>
          {isExpanded && <Col flex='35px' />}
        </>
      )}
    </Row>
  );
});

export default QueryProperties;
