module.exports = {
  '@primary-color': '#1890ff',
  '@success-color': '#1e8e3e',
  '@info-color': '@primary-color',
  '@warning-color': '#ffc440',
  '@error-color': '#d93026',
  '@processing-color': '@primary-color',
  '@text-color': 'fade(#000, 65%)',
  '@text-color-secondary': 'fade(#000, 45%)',
  '@border-color-base': '#dedede',
  '@border-color-split': '#dedede',
  '@layout-body-background': '#fafafa',
  '@font-size-base': '13px',
  '@border-radius-base': '0',
  '@border-radius-sm': '0',
  '@padding-lg': '16px',
  '@padding-md': '8px',
  '@padding-sm': '6px',
  '@padding-xs': '4px',
  '@outline-width': '0',
  '@outline-color': '#737373',
  '@background-color-base': 'hsv(0, 0, 96%)',
  '@btn-font-weight': '500',
  '@btn-default-bg': '#fafafa',
  '@btn-default-border': '#dedede',
  '@btn-height-base': '26px',
  '@btn-height-lg': '36px',
  '@btn-height-sm': '20px',
  '@dropdown-line-height': '26px',
  '@radio-button-bg': 'transparent',
  '@radio-button-checked-bg': 'transparent',
  '@form-item-margin-bottom': '16px',
  '@input-height-base': '26px',
  '@input-height-lg': '32px',
  '@input-height-sm': '20px',
  '@input-hover-border-color': '#737373',
  '@progress-radius': '0',
  '@menu-inline-toplevel-item-height': '26px',
  '@menu-item-height': '26px',
  '@table-header-bg': '#fafafa',
  '@table-row-hover-bg': '#fafafa',
  '@table-padding-vertical': '5px',
  '@table-padding-horizontal': '10px',
  '@tabs-card-gutter': '-1px',
  '@tabs-card-tab-active-border-top': '2px solid @primary-color',
  '@switch-color': '@success-color',
  '@pagination-item-size': '26px',
  '@pagination-item-size-sm': '20px',
  '@pagination-font-weight-active': '600',
  '@breadcrumb-base-color': '@text-color',
  '@breadcrumb-last-item-color': '@text-color-secondary',
  '@slider-handle-border-width': '1px',
  '@slider-handle-shadow': '1px 1px 4px 0 rgba(0,0,0,.13)',
  '@slider-track-background-color': '@primary-color',
  '@slider-track-background-color-hover': '@primary-color',
  '@slider-handle-color': '#dedede',
  '@slider-handle-color-hover': '#dedede',
  '@slider-handle-color-focus': '#dedede',
  '@slider-handle-color-focus-shadow': 'transparent',
  '@slider-handle-color-tooltip-open': '#ddd',
  '@alert-success-border-color': '#dff4e5',
  '@alert-success-bg-color': '#dff4e5',
  '@alert-info-border-color': '#e5f3ff',
  '@alert-info-bg-color': '#e5f3ff',
  '@alert-warning-border-color': '#fff7db',
  '@alert-warning-bg-color': '#fff7db',
  '@alert-error-border-color': '#fcebea',
  '@alert-error-bg-color': '#fcebea',
  '@gray-6': '#bfbfbf',
};
