import React from 'react';

import { Tooltip, Image } from 'antd';

import classnames from 'classnames';

import { Images } from 'config/assets';

import { useImageLoaded } from 'hooks';

const AgGridThumbnail = (props) => {
  const {
    thumbnail,
    thumbnail500,
    thumbnail1000,
    showLargeImage = true,
  } = props;

  // const [ref, loaded, onLoad] = useImageLoaded();

  const loaded = true;

  const renderImage = () => {
    const thumbnailSrc = thumbnail || Images.RIVIR_LOGO_DEFAULT;

    return loaded ? (
      <div
        className='ag-grid__thumbnail'
        style={{ backgroundImage: `url(${thumbnailSrc})` }}
      ></div>
    ) : (
      <img
        style={{ width: '100%', height: '100%' }}
        // ref={ref}
        src={thumbnail || Images.RIVIR_LOGO_DEFAULT}
        // onLoad={onLoad}
        loading='lazy'
        alt=''
      />
    );
  };

  return (
    <div
      className={classnames({
        'ag-grid__thumbnail': true,
        'ag-grid__thumbnail-loading': !loaded,
      })}
    >
      {showLargeImage ? (
        <Tooltip
          placement='rightBottom'
          color='#fff'
          title={
            <LargeThumbnail
              thumbnail500={thumbnail500}
              thumbnail1000={thumbnail1000}
            />
          }
        >
          {renderImage()}
        </Tooltip>
      ) : (
        renderImage()
      )}
    </div>
  );
};

const LargeThumbnail = ({ thumbnail500, thumbnail1000 }) => {
  const srcUrl = thumbnail1000
    ? thumbnail1000
    : thumbnail500 ?? Images.RIVIR_LOGO_DEFAULT;

  return (
    <Image
      style={{
        maxWidth: 500,
        maxHeight: 500,
      }}
      src={srcUrl}
      preview={false}
    />
  );
};

export default AgGridThumbnail;
