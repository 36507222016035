import { formatMDY } from 'utils/formatDate';

const filterParamsColumns = (columns) => {
  // first checkbox element hasn't fieldName
  const removedCheckboxColumns = columns.slice(2);
  let result = [];

  // check second grid folder grid is Icon
  if (removedCheckboxColumns[0]?.field === 'type') {
    const fieldNameColumns = removedCheckboxColumns
      .slice(1)
      .map((c) => ({ fieldName: c.fieldName, width: c.width }));
    result = fieldNameColumns;
  } else {
    result = removedCheckboxColumns.map((c) => ({
      fieldName: c.fieldName,
      width: c.width,
    }));
  }

  return result;
};

const getStringFilteredColumns = (columns) => {
  const removedCheckboxColumns = columns.slice(2);
  let result = [];

  // check second grid folder grid is Icon
  if (removedCheckboxColumns[0]?.field === 'type') {
    const fieldNameColumns = removedCheckboxColumns
      .slice(1)
      .map((c) => c.fieldName);
    result = fieldNameColumns;
  } else {
    result = removedCheckboxColumns.map((c) => c.fieldName);
  }

  return result.join();
};

const getListFieldName = (columns) => {
  // first checkbox element hasn't fieldName
  const removedCheckboxColumns = columns.slice(2);
  let result = [];

  // check first removedCheckboxColumns type is column (Icon)
  if (removedCheckboxColumns[0]?.field === 'type') {
    const fieldNameColumns = removedCheckboxColumns
      .slice(1)
      .map((c) => c.fieldName);
    result = ['type', ...fieldNameColumns];
    return result;
  }

  return columns.slice(1).map((c) => c.fieldName);
};

const deleteColumnCheckbox = (columns) => {
  const removedCheckboxColumns = columns.slice(2);

  if (removedCheckboxColumns[0]?.field === 'type') {
    return columns.slice(3);
  }

  return removedCheckboxColumns;
};

const defaultColumns = [
  {
    checkboxSelection: true,
    field: '',
    filter: false,
    suppressMenu: true,
  },
  {
    allowFilter: true,
    allowSort: true,
    dataType: 'string',
    displayName: 'Query Name',
    fieldName: 'name',
    fieldNameCamelCase: 'name',
    resizable: true,
    suppressMenu: true,
    width: 250,
    minWidth: 200,
  },
  {
    dataType: 'string',
    displayName: 'Description',
    fieldName: 'Description',
    fieldNameCamelCase: 'description',
    allowFilter: true,
    allowSort: true,
    suppressMenu: true,
    minWidth: 200,
    flex: 1,
  },
  {
    dataType: 'datetime',
    displayName: 'Created',
    fieldName: 'Created',
    fieldNameCamelCase: 'created',
    allowFilter: true,
    allowSort: true,
    suppressMenu: true,
    width: 150,
    minWidth: 150,
    cellRenderer: ({ value }) => {
      return formatMDY(value);
    },
  },
  {
    dataType: 'boolean',
    displayName: 'Editable',
    fieldName: 'Editable',
    fieldNameCamelCase: 'editable',
    allowFilter: true,
    allowSort: true,
    width: 120,
    minWidth: 120,
    suppressMenu: true,
  },
  {
    dataType: 'string',
    displayName: 'Owned By',
    fieldName: 'OwnerFullName',
    fieldNameCamelCase: 'ownerFullName',
    allowFilter: true,
    allowSort: true,
    width: 200,
    minWidth: 150,
    suppressMenu: true,
  },
  {
    dataType: 'boolean',
    displayName: 'Shareable',
    fieldName: 'Shareable',
    fieldNameCamelCase: 'shareable',
    allowFilter: true,
    allowSort: true,
    width: 100,
    suppressMenu: true,
  },
  {
    resizable: true,
    dataType: 'string',
    displayName: 'Shared By',
    fieldName: 'sharedByName',
    fieldNameCamelCase: 'sharedByName',
    width: 150,
    minWidth: 150,
    suppressMenu: true,
  },
];

const isDefaultFieldColumn = {
  allowFilter: false,
  allowSort: false,
  displayName: 'Active',
  fieldName: 'active',
  fieldNameCamelCase: 'active',
  resizable: true,
  width: 100,
  suppressMenu: true,
};

const shareFieldColumn = {
  fieldNameCamelCase: '',
  resizable: true,
  width: 350,
  suppressMenu: true,
};

const actionFieldColumn = [
  {
    key: 4,
    field: '',
    resizable: true,
    width: 150,
    menuTabs: [],
  },
];

const getNewDefaultColumns = (listFieldNames, allColumns, chosenColumns) => {
  const removedCheckboxColumns = allColumns.slice(2);

  let defaultColumns = listFieldNames.reduce((result, fieldName) => {
    const oldColumn = chosenColumns.find((col) => col.fieldName === fieldName);
    if (oldColumn) {
      result.push(oldColumn);
    } else {
      const newColumn = allColumns.find((col) => col.fieldName === fieldName);
      result.push(newColumn);
    }

    return result;
  }, []);

  if (removedCheckboxColumns[0]?.field === 'type') {
    defaultColumns = [...allColumns.slice(0, 3), ...defaultColumns];
  } else {
    defaultColumns = [...allColumns.slice(0, 2), ...defaultColumns];
  }
  return defaultColumns ?? [];
};

const getColumnsAfterChangeWidth = (fieldName, width, chosenColumns) => {
  const defaultColumns = chosenColumns.map((col) => {
    if (col.fieldName === fieldName) {
      return { ...col, width };
    }
    return col;
  });

  return defaultColumns;
};

const getDefaultConfig = (columns) => {
  const removedCheckboxColumns =
    columns[2]?.field === 'type' ? columns.slice(3) : columns.slice(2);

  return removedCheckboxColumns.map((col) => ({
    fieldName: col.fieldName,
    width: col.width,
  }));
};

const filteredColumns = ['MemberName', 'IxOneId', 'CompanyBrief'];

const filterColumnsGrid = (columns) => {
  let result = [];

  if (columns?.length > 0) {
    columns.forEach((column) => {
      if (filteredColumns.includes(column.fieldName)) {
        if (column.fieldName === 'MemberName') {
          result.push({
            ...column,
            flex: 1,
          });
        }

        if (column.fieldName === 'IxOneId') {
          result.push({
            ...column,
            width: 150,
          });
        }

        if (column.fieldName === 'CompanyBrief') {
          result.push({
            ...column,
            width: 150,
          });
        }
      }
    });

    result = [columns[0], ...result];
  }

  return result;
};

const filterSelectedMembers = (members, selectedIds, selectedMembers) => {
  let result = [];
  let existingIds = selectedMembers.map((member) => member.id);

  members.forEach((member) => {
    // prevent add same member in array
    if (selectedIds.includes(member.id) && !existingIds.includes(member.id)) {
      result.push(member);
    }
  });

  return result;
};

export {
  filterParamsColumns,
  getStringFilteredColumns,
  defaultColumns,
  isDefaultFieldColumn,
  shareFieldColumn,
  actionFieldColumn,
  getListFieldName,
  deleteColumnCheckbox,
  getNewDefaultColumns,
  getColumnsAfterChangeWidth,
  getDefaultConfig,
  filterColumnsGrid,
  filterSelectedMembers,
};
