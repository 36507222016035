import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import usePrevious from 'hooks/usePrevious';

import './styles.less';

const StepForm = (props) => {
  const { stepDefine, currentStep, ...restProps } = props;

  const prevStep = usePrevious(currentStep);

  return (
    <div className='step-form__wrapper' {...restProps}>
      {stepDefine.map((stepItem) => {
        return (
          <div
            className={classnames('step-form', {
              'step-form--hidden': ![currentStep, prevStep].includes(
                stepItem?.step
              ),
              'step-form--slide-in-left':
                currentStep === stepItem?.step && currentStep < prevStep,
              'step-form--slide-in-right':
                currentStep === stepItem?.step && currentStep > prevStep,
              'step-form--slide-out-left':
                currentStep !== stepItem?.step &&
                stepItem?.step === prevStep &&
                currentStep > prevStep,
              'step-form--slide-out-right':
                currentStep !== stepItem?.step &&
                stepItem?.step === prevStep &&
                currentStep < prevStep,
            })}
            key={stepItem.step}
          >
            <div style={stepItem?.wrapperStyle}>
              {stepItem?.component || null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

StepForm.propTypes = {
  currentStep: PropTypes.number,
  stepDefine: PropTypes.array,
};

export default StepForm;
