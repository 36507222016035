import { uniq } from 'lodash';

/**
 * ? handle categorrized permission list
 * @param {*}
 * @returns
 */
const makeCategorizedPermissionList = ({
  nextAvailablePermissions,
  permissionList,
  groupPermit,
}) => {
  for (let {
    displayName,
    name,
    groupDisplayName,
  } of nextAvailablePermissions) {
    if (permissionList.includes(name)) {
      groupPermit[groupDisplayName].permission = [
        ...groupPermit[groupDisplayName].permission,
        { name, displayName, isActive: true },
      ];
    } else {
      groupPermit[groupDisplayName].permission = [
        ...groupPermit[groupDisplayName].permission,
        { name, displayName, isActive: false },
      ];
    }
  }

  return Promise.resolve(groupPermit);
};

/**
 * ? filter permission array by search key
 * @param {*}
 * @returns
 */
const filterpermissionListBySearchKey = async ({
  availPermissions,
  searchText,
}) => {
  let filteredPermissionArray = [];

  if (!searchText) {
    filteredPermissionArray = availPermissions;
    return Promise.resolve(filteredPermissionArray);
  }

  filteredPermissionArray = availPermissions.filter((permissionItem) =>
    permissionItem.displayName?.toLowerCase().includes(searchText.toLowerCase())
  );

  return Promise.resolve(filteredPermissionArray);
};

/**
 * ? filter permission array by category
 * @param {*}
 * @returns
 */
const filterPermissionListByCategory = async ({
  nextAvailablePermissions,
  searchCategories,
}) => {
  let filteredPermissionArray = [];

  if (searchCategories?.length === 0) {
    filteredPermissionArray = nextAvailablePermissions;
    return Promise.resolve(filteredPermissionArray);
  }

  nextAvailablePermissions.forEach((permissionItem) => {
    if (searchCategories.includes(permissionItem?.groupDisplayName)) {
      filteredPermissionArray.push(permissionItem);
    }
  });

  return Promise.resolve(filteredPermissionArray);
};

/**
 * ? categorized and process permission list
 * @param {*}
 * @returns
 */
export const mapToGroupedMemberPermissionList = async ({
  permissionList,
  availPermissions,
  searchText,
  searchCategories,
  allCategory,
}) => {
  if (!permissionList || !availPermissions || allCategory?.length <= 0)
    return {};

  let groupPermit = initGroupPermit({ allCategory });

  const categorizedPermission = await filterpermissionListBySearchKey({
    availPermissions,
    searchText,
  })
    .then((nextAvailablePermissions) => {
      return filterPermissionListByCategory({
        nextAvailablePermissions,
        searchCategories,
      });
    })

    .then((nextAvailablePermissions) => {
      return makeCategorizedPermissionList({
        nextAvailablePermissions,
        groupPermit,
        permissionList,
      });
    });

  return categorizedPermission;
};

/**
 * ? initialize group permit by avail category
 * @param {*}
 * @returns
 */
const initGroupPermit = ({ allCategory }) => {
  if (allCategory?.length === 0) return {};

  let newGroupPermit = {};

  allCategory.forEach((cateName) => {
    newGroupPermit[cateName] = { permission: [] };
  });

  return newGroupPermit;
};

/**
 * ? extract the category list from all avail member role permissions
 * @param {*}
 * @returns
 */
export const getAllCateFromAllMemberPermissionList = ({ availPermissions }) => {
  let cateList = [];

  availPermissions.forEach((permissionItem) => {
    cateList.push(permissionItem.groupDisplayName);
  });

  return uniq(cateList);
};
