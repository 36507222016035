import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Menu, Dropdown } from 'antd';
import {
  SafetyCertificateFilled,
  CloudSyncOutlined,
  FilterOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  ApartmentOutlined,
  ShareAltOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { useFilterGrid } from 'hooks/useFilterGrid';
import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';
import { FILTER_TYPE_PRODUCTS, ROUTE } from 'static/Constants';

import Messages from 'i18n/messages/home';

import './FilterSection.less';

import _ from 'lodash';
import FilterProductCategoryModal from './FilterProductCategoryModal';

const FilterProductSection = (props) => {
  const dispatch = useDispatch();

  const { viewType, entityType = 'product' } = props;

  const [visibleModal, setVisibleModal] = useState();
  const { gridFilters, updateGridFilter } = useFilterGrid({
    entityType,
  });

  const { label, icon, toggle } = useDisplayFilterButton({
    filterOptions: FILTER_TYPE_PRODUCTS,
    filters: gridFilters.filter((filterItem) => {
      return FILTER_TYPE_PRODUCTS.find(
        (FILTER_ITEM) => FILTER_ITEM.value === filterItem
      );
    }),
  });

  const handleFilterProducts = (filter) => {
    dispatch(actionsGridView.resetPageCurrent());

    if (gridFilters.indexOf(filter) > -1) {
      updateGridFilter(gridFilters.filter((val) => val !== filter));
    } else {
      let newFilterProducts = [...gridFilters];

      newFilterProducts.push(filter);
      updateGridFilter(newFilterProducts);
    }
  };

  const handleToggleProductHierarchyType = () => {
    if (gridFilters.includes('brickCodeHierarchy'))
      updateGridFilter(
        gridFilters.filter((val) => val !== 'brickCodeHierarchy')
      );
    else if (gridFilters.includes('productCategory'))
      updateGridFilter(gridFilters.filter((val) => val !== 'productCategory'));
    else {
      setVisibleModal(true);
    }
  };

  const packageMenu = (
    <Menu>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<SafetyCertificateFilled />}
          label={Messages.subscriptions}
          onClick={() => handleFilterProducts('subscription')}
          toggle={gridFilters.indexOf('subscription') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<CloudSyncOutlined />}
          label={Messages.syndication}
          onClick={() => handleFilterProducts('syndication')}
          toggle={gridFilters.indexOf('syndication') > -1}
        />
      </Menu.Item>
      {viewType !== ROUTE.MEMBER_PRODUCTS && (
        <>
          <Menu.Item>
            <RibbonButton
              size='small'
              icon={<FilterOutlined />}
              label={Messages.myCompanyProducts}
              onClick={() => handleFilterProducts('owner')}
              toggle={gridFilters.indexOf('owner') > -1}
            />
          </Menu.Item>
          <Menu.Item>
            <RibbonButton
              size='small'
              icon={<ShareAltOutlined />}
              label={Messages.productShareToMe}
              onClick={() => handleFilterProducts('shared')}
              toggle={gridFilters.indexOf('shared') > -1}
            />
          </Menu.Item>
        </>
      )}
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<ClockCircleOutlined />}
          label={Messages.pendingProducts}
          onClick={() => handleFilterProducts('pending')}
          toggle={gridFilters.indexOf('pending') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<CheckCircleOutlined />}
          label='Active Products'
          onClick={() => handleFilterProducts('active')}
          toggle={gridFilters.indexOf('active') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<ApartmentOutlined />}
          label={Messages.productCategory}
          onClick={() => handleToggleProductHierarchyType()}
          toggle={
            gridFilters.indexOf('productCategory') > -1 ||
            gridFilters.indexOf('brickCodeHierarchy') > -1
          }
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<WarningOutlined />}
          label={Messages.unmatchedProducts}
          onClick={() => handleFilterProducts('unmatched')}
          toggle={gridFilters.indexOf('unmatched') > -1}
        />
      </Menu.Item>
      <Menu.Item>
        <RibbonButton
          size='small'
          icon={<i class='fa-solid fa-handshake'></i>}
          label={'Private Label'}
          onClick={() => handleFilterProducts('privateLabel')}
          toggle={gridFilters.indexOf('privateLabel') > -1}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={packageMenu} arrow>
        <RibbonButton icon={icon} label={label} toggle={toggle} />
      </Dropdown>

      <FilterProductCategoryModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        entityType={entityType}
      />
    </>
  );
};

export default FilterProductSection;
