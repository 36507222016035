import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Typography, Checkbox, Button, Modal, Switch } from 'antd';
import { IdcardTwoTone } from '@ant-design/icons';

import { IconList, ContactCardModal } from 'common/components';
import AssetDownloadPreferencesForm from 'pages/user-profile/components/form/AssetDownloadPreferencesForm';

import * as userProfileService from 'services/user';
import { formatMDY } from 'utils/formatDate';

import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/user-profile.js';

const { Text, Paragraph } = Typography;

const ViewOverviewInfo = (props) => {
  const { overviewInfo } = props;

  const [reportTo, setReportTo] = useState();
  const [showContactCard, setShowContactCard] = useState();
  const [showAssetDownloadModal, setShownAssetDownloadModal] = useState(false);

  const userId = overviewInfo?.id;
  const reportToId = overviewInfo?.reportToUserId;

  useEffect(() => {
    if (userId) getReportToInfo();
  }, [userId, reportToId]);

  const getReportToInfo = () => {
    if (overviewInfo?.reportToUserId)
      userProfileService
        .getUserProfileHeader({ UserId: reportToId })
        .then((res) => {
          if (res?.isSuccess) {
            setReportTo(res?.data);
          }
        });
  };

  const handleContactCardRender = () => {
    const contactCardUserInfo = {
      fullName: reportTo?.fullName,
      image: reportTo?.imageUrl,
      position: reportTo?.position,
      email: reportTo?.email,
      address: reportTo?.primaryAddress?.mainAddress,
      phoneNumber: reportTo?.primaryAddress?.phoneNumber,
      mobileNumber: reportTo?.primaryAddress?.mobileNumber,
      faxNumber: reportTo?.primaryAddress?.faxNumber,
      country: reportTo?.primaryAddress?.country,
      city: reportTo?.primaryAddress?.city,
      state: reportTo?.primaryAddress?.state,
    };

    return (
      <ContactCardModal
        visible={showContactCard}
        setVisible={setShowContactCard}
        userInfo={contactCardUserInfo}
      />
    );
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.companyName} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>{overviewInfo?.companyName}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.userName} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>{overviewInfo?.userName}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.created} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>
                {overviewInfo?.created && formatMDY(overviewInfo.created)}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.title} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>{overviewInfo?.title}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.position} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>{overviewInfo?.position}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.department} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>{overviewInfo?.department}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.reportsTo} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>{overviewInfo?.reportTo}</Paragraph>
              {reportToId && (
                <IdcardTwoTone
                  className='user-profile-overview__info-contact-card-icon'
                  onClick={() => setShowContactCard(true)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.lastActivity} />:
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph ellipsis={true}>
                {formatMDY(overviewInfo?.lastActivity)}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={12} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.searchCategories} />:
              </Text>
            </Col>
            <Col span={12}>
              <IconList
                numberOfShowedItems={40}
                itemList={overviewInfo?.searchCategories}
                size='default'
                tooltipPosition='top'
                noId
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={16} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.receiveCompanyCommunications} />:
              </Text>
            </Col>
            <Col span={8} style={{ display: 'flex', alignItems: 'end' }}>
              <Checkbox checked={overviewInfo?.receiveCompanyCommunications} />
            </Col>
          </Row>
          <Row>
            <Col span={16} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.status} />:
              </Text>
            </Col>
            <Col span={8}>
              <Paragraph ellipsis={true}>{overviewInfo?.status}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={16} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.landingPage} />:
              </Text>
            </Col>
            <Col span={8}>
              <Paragraph
                style={{ textTransform: 'capitalize' }}
                ellipsis={true}
              >
                {overviewInfo?.landingPage}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={16} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.availableForContact} />:
              </Text>
            </Col>
            <Col span={8}>
              <Checkbox checked={overviewInfo?.availableForContact} />
            </Col>
          </Row>
          <Row>
            <Col span={16} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.accountExpires} />:
              </Text>
            </Col>
            <Col span={8}>
              <Paragraph ellipsis={true}>
                {overviewInfo?.accountExpires &&
                  formatMDY(overviewInfo.accountExpires)}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={16} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.passwordExpiration} />:
              </Text>
            </Col>
            <Col span={8}>
              <Paragraph ellipsis={true}>
                {overviewInfo?.passwordExpiration &&
                  formatMDY(overviewInfo.passwordExpiration)}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={16} className='user-profile-overview__info-title'>
              <Text>
                <FormattedMessage {...Messages.optOut} />:
              </Text>
            </Col>
            <Col span={8}>
              <Paragraph ellipsis={true}>
                <Checkbox checked={overviewInfo?.optOutEmail}>Email</Checkbox>
              </Paragraph>
              <Paragraph ellipsis={true}>
                <Checkbox checked={overviewInfo?.optOutText}>Text</Checkbox>
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                className='user-profile-overview__info-change-btn'
                onClick={() => setShownAssetDownloadModal(true)}
              >
                <FormattedMessage {...Messages.assetDownloadPreferences} />
              </Button>
            </Col>
          </Row>
        </Col>
        {handleContactCardRender()}
      </Row>
      <Modal
        bodyStyle={{ paddingBottom: 0 }}
        title={<FormattedMessage {...Messages.assetDownloadPreferences} />}
        visible={showAssetDownloadModal}
        onCancel={() => setShownAssetDownloadModal(false)}
        destroyOnClose={true}
        footer={null}
        className='asset-download-preferences-modal'
        maskClosable={false}
      >
        <AssetDownloadPreferencesForm closeModal={setShownAssetDownloadModal} />
      </Modal>
    </>
  );
};

ViewOverviewInfo.propTypes = {
  overviewInfo: PropTypes.object,
  showModal: PropTypes.func,
};

export default ViewOverviewInfo;
