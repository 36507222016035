import { Draggable } from 'react-beautiful-dnd';
import { getStyle } from './utils';

const RowDragging = (props) => {
  const { data, index, style, isScrolling } = props;

  const { getDraggableId, isDragDisabled, children } = data;

  const draggableId = getDraggableId(index);

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      key={draggableId}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <DragItem
          style={style}
          provided={provided}
          snapshot={snapshot}
          isScrolling={isScrolling}
        >
          {children({ index, provided, snapshot, isScrolling })}
        </DragItem>
      )}
    </Draggable>
  );
};

export const DragItem = ({ provided, snapshot, style, children }) => {
  const positionElement = getStyle({ style, provided });

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...provided.draggableProps.style,
        ...positionElement,
      }}
    >
      {provided.placeholder}
      {children}
    </div>
  );
};

export default RowDragging;
