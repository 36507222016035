import React from 'react';

import { Input } from 'antd';

const NumberTextInput = (props) => {
  const handleNumberInput = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return <Input min='0' onKeyPress={handleNumberInput} {...props} />;
};

export default NumberTextInput;
