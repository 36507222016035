import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import QueryConditions from 'pages/reporting/components/QueryConditions';
import TitleSection from 'pages/reporting/components/TitleSection';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';
import ClearQueryControl from 'pages/home/ribbon/components/controls/advanced-search/ClearQueryControl';

import { useGetDataQueryConditions } from 'hooks';
import HeaderEntityAttributes from '../reporting/entity-and-attributes/HeaderEntityAttributes';
import EntitiesAndAttributes from '../reporting/entity-and-attributes/EntitiesAndAttributes';
import dialogFunction from '../dialog-function/DialogFunction';
import { ADVANCED_FILTER_MODE } from 'pages/reporting/utils/constans';

import './NestedAdvanceSearch.less';

const NestedAdvanceSearch = (props) => {
  const pathName = window.location.pathname;
  const queryConditions = useGetDataQueryConditions(pathName);

  const visible = useSelector(
    selectorsGridView.makeSelectIsOpenAdvanceSearch()
  );

  const {
    state,
    entityType,
    onDragEnd,
    modeData,
    onDragStart,
    setModeData,
    toggleSelection,
    handleConjunction,
    addQueryCondition,
    fetchEntityAttribute,
    handleUpdateDataEntity,
    updateQueryCondition,
    handleUpdateSearchText,
    toggleSelectionInGroup,
    handleQueryDateBetween,
    handleUpdateSearchEntity,
    handleUpdateQueryCondition,
    handleRemoveQueryConditions,
    handleGetEntityAndAttributes,
    handleUpdateQueryConditionFile,
  } = props;

  const {
    searchText,
    dataSearch,
    propertiesData,
    dataQueryConditions,
    selectedPropertyIds,
  } = state;

  const showConfirmMessage = (refSearchText) => {
    dialogFunction({
      type: 'warn',
      content: (
        <div>
          <p style={{ marginBottom: 0 }}>
            Are you sure you want to switch to&nbsp;
            {modeData === ADVANCED_FILTER_MODE.FLAT_DATA
              ? 'Advanced Mode'
              : 'Basic Mode'}
            ?
          </p>
        </div>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleToggleMode(refSearchText),
    });
  };

  const handleToggleMode = (refSearchText) => {
    const entityProperties =
      window?.advancedSearch?.entityAttributes?.[entityType];

    if (modeData === ADVANCED_FILTER_MODE.FLAT_DATA) {
      setModeData(ADVANCED_FILTER_MODE.NESTED_DATA);
      handleUpdateDataEntity(
        entityProperties,
        ADVANCED_FILTER_MODE.NESTED_DATA
      );
    } else {
      setModeData(ADVANCED_FILTER_MODE.FLAT_DATA);
      handleUpdateDataEntity(entityProperties, ADVANCED_FILTER_MODE.FLAT_DATA);
    }

    handleUpdateSearchText('');
    refSearchText.current?.onClearSearchText();
  };

  useEffect(() => {
    if (!visible) return;
    handleGetEntityAndAttributes(entityType);
    if (queryConditions) {
      handleUpdateQueryCondition(queryConditions);
    }
  }, [JSON.stringify(queryConditions), visible]);

  const hasQueryConditions = dataQueryConditions?.children?.length > 0;

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Row
        style={{ width: '100%', height: '100%' }}
        className='advanced-search'
      >
        <Col span={8} style={{ height: '100%' }}>
          <Row className='advanced-search__condition' style={{ padding: 8 }}>
            <Col span={24} className='nested-advance-search__list'>
              <HeaderEntityAttributes
                type={entityType}
                modeData={modeData}
                setModeData={setModeData}
                onClick={showConfirmMessage}
                handleUpdateSearchEntity={handleUpdateSearchEntity}
              >
                <EntitiesAndAttributes
                  searchText={searchText}
                  dataSearch={dataSearch}
                  propertiesData={propertiesData}
                  selectedTaskIds={selectedPropertyIds}
                  toggleSelection={toggleSelection}
                  toggleSelectionInGroup={toggleSelectionInGroup}
                />
              </HeaderEntityAttributes>
            </Col>
          </Row>
        </Col>
        <Col span={16} style={{ height: '100%' }}>
          <Row
            className='advanced-search__condition'
            style={{ padding: '8px 8px 8px 0px' }}
          >
            <Col span={24} className='nested-advance-search__list'>
              <TitleSection title={'Query Conditions'}>
                {hasQueryConditions && (
                  <ClearQueryControl
                    className='advanced-search-title__button'
                    onClick={() => {
                      handleUpdateQueryCondition([]);
                    }}
                  />
                )}
              </TitleSection>
              <QueryConditions
                addQueryCondition={addQueryCondition}
                queryConditions={dataQueryConditions}
                propertiesData={propertiesData}
                fetchEntityAttribute={fetchEntityAttribute}
                handleRemoveQueryConditions={handleRemoveQueryConditions}
                handleConjunction={handleConjunction}
                handleQueryCondition={updateQueryCondition}
                handleQueryDateBetween={handleQueryDateBetween}
                handleUpdateQueryConditionFile={handleUpdateQueryConditionFile}
                entityType={entityType}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DragDropContext>
  );
};

export default React.memo(NestedAdvanceSearch);
