import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { useParams } from 'react-router-dom';

import {
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  Typography,
  Tooltip,
} from 'antd';
import { sortBy, round } from 'lodash';
import { FileTextOutlined } from '@ant-design/icons';

import { Form, WrapperSelect, CustomNotification } from 'common/components';
import NutrientList from './NutrientList';

import { useQueryFactsPanel } from '../../multiple-panel/facts-panel/useQueryFactsPanel';

import { useGetProductEnums, useProductFullView } from 'hooks';
import { useAddNutritionLabel } from '../hook/useAddNutritionLabel';

import { deleteObjectField } from 'utils';

import { DEFAULT_FOOTER_NOTE } from '../DailyValueConstants';
import { NUTRITION_LABEL_TYPE } from 'static/Constants';
import { useInvalidateGroupDetail } from '../../shared/hooks';
import { SCREEN_WIDTH, useWindowSize } from 'hooks/windowSize';

const { Option } = Select;
const { Paragraph } = Typography;

const INITIAL_VALUE = {
  servingSizeMeasurement: 'Gram',
  nutrientFormatType: 'US_FDA_NFP_2020_STANDARD',
};

const AddNutritionLabel = forwardRef((props, forwardedRef) => {
  const dvFormRef = useRef();
  const [form] = Form.useForm();

  const { id } = useParams();

  const {
    visible,
    typeView,
    setIsUpdating,
    setIsAddingNew,
    factsPanel,
    onSubmitSuccess,
    onSubmitFail,
    setIsChangedForm,
  } = props;

  const { productEnums } = useGetProductEnums();
  const { productFull } = useProductFullView({ productId: id });
  const [screenWidth, _] = useWindowSize();

  const [nutritionFactProperties, setNutritionFactProperties] = useState([]);
  const { listFactsPanel } = useQueryFactsPanel({ id, typeView });
  const { handleSyncAllProductDetailGroup, handleSyncAllQaDetailGroup } =
    useInvalidateGroupDetail(id);

  const mutation = useAddNutritionLabel({ productId: id, typeView });

  const dailyValuesField = form.getFieldValue('nutritionFactProperties') || [];

  const initForm = (on) => {
    if (on === 'edit') initEditLabel();

    if (on === 'add') initAddLabel();
  };

  const initEditLabel = () => {
    form.setFieldsValue({
      ...factsPanel,
      nutrientFormatType: factsPanel.nutrientFormatType
        ? factsPanel.nutrientFormatType
        : 'US_FDA_NFP_2020_STANDARD',
      servingSizeMeasurement: factsPanel.servingSizeMeasurement
        ? factsPanel.servingSizeMeasurement
        : 'Gram',
    });
    setNutritionFactProperties(factsPanel?.nutritionFactProperties);
  };

  const initAddLabel = () => {
    setNutritionFactProperties([]);
    form.setFieldsValue({
      ...INITIAL_VALUE,
      nutrientFormatType:
        primaryFactsPanel?.nutrientFormatType || 'US_FDA_NFP_2020_STANDARD',
      languageCode: 'en',
    });
  };

  const handleRemoveDV = (ingredient) => {
    const dv = form.getFieldValue('nutritionFactProperties');
    const removeIngredient = dv.filter(
      (dv) => dv.nutritionTypeCode !== ingredient.nutritionTypeCode
    );

    form.setFieldsValue({
      ...form.getFieldsValue(),
      nutritionFactProperties: removeIngredient,
    });

    setNutritionFactProperties(removeIngredient);
  };

  const submitForm = async (ixoneIgnore) => {
    const values = await form.validateFields();

    if (nutritionFactProperties?.length > 0) {
      const productId = productFull?.productId;

      const paramsKey = {
        overview: {
          id: 'productItemId',
          data: 'data',
          nutritionFacts: 'properties',
        },
        'qa-spec': {
          id: 'productId',
          data: 'nutritionFact',
          nutritionFacts: 'nutritionFactProperties',
        },
      };

      const id = factsPanel?.id ? factsPanel?.id : null;
      const params = {
        [paramsKey[typeView].id]: productId,
        [paramsKey[typeView].data]: {
          ...values,
          id,
          nutritionFactProperties,
          nutrientFormatType: values.nutrientFormatType,
          [paramsKey[typeView].nutritionFacts]: nutritionFactProperties,
        },
        ixOneIgnore: factsPanel?.id ? ixoneIgnore : null,
      };

      mutation.mutate(params, {
        onSuccess: (result) => {
          if (result.isSuccess) {
            form.resetFields();
            setNutritionFactProperties([]);
            handleSyncAllProductDetailGroup();
            handleSyncAllQaDetailGroup();
            onSubmitSuccess && onSubmitSuccess();

            CustomNotification.success('Update Nutrition Fact Success.');
          } else {
            form.resetFields();
            setNutritionFactProperties([]);

            onSubmitFail && onSubmitFail();

            CustomNotification.error('Something went wrong!');
          }
        },
      });
    } else {
      dvFormRef?.current?.validateForm();
      CustomNotification.error('Please add at least one nutrient!');
      return;
    }
  };

  const resetForm = () => {
    form.resetFields();
    setNutritionFactProperties([]);
  };

  const onFormProviderFinish = (name, { values, forms }) => {
    if (name === 'dvForm') {
      const { labelForm } = forms;
      const dv = labelForm.getFieldValue('nutritionFactProperties');
      const { dailyValueIntakePercent, quantity, nutrientIdx } = values;

      let newDv = {
        ...values,
        dailyValueIntakePercent: dailyValueIntakePercent ?? null,
        quantity: quantity ?? null,
      };

      deleteObjectField(newDv, ['nutrientIdx']);

      let properties = dv ? dv : '';

      if (nutrientIdx === null) {
        labelForm.setFieldsValue({
          nutritionFactProperties: [...properties, newDv],
        });

        setNutritionFactProperties([...nutritionFactProperties, newDv]);
        setIsAddingNew(false);
      } else {
        properties[nutrientIdx] = newDv;

        labelForm.setFieldsValue({
          nutritionFactProperties: properties,
        });

        setNutritionFactProperties(properties);
        setIsUpdating(false);
      }
    }
  };

  const handleFooterNote = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      fdaLabelFootNote: DEFAULT_FOOTER_NOTE,
    });
  };

  const productBasicUOM = productEnums?.filter(
    (type) => type.enumName === 'BasicUomEnum'
  );

  const productNutrientBasisQuantityTypeCodeEnum = productEnums?.find(
    (type) => type.enumName === 'NutrientBasisQuantityTypeCodeEnum'
  );

  const servingPerContainerDescriptorEnum = productEnums?.find(
    (type) => type.enumName === 'ServingPerContainerDescriptorEnum'
  );

  const dv = Array.isArray(nutritionFactProperties)
    ? nutritionFactProperties
    : [];

  const [primaryFactsPanel] = listFactsPanel;

  const existingFormatType = NUTRITION_LABEL_TYPE.filter(
    (item) => item?.value === primaryFactsPanel?.nutrientFormatType
  );

  const numberOfFactPanelToCompare = factsPanel ? 2 : 1;
  const nutrientFormatTypeOptions =
    existingFormatType?.length &&
    listFactsPanel?.length >= numberOfFactPanelToCompare
      ? existingFormatType
      : NUTRITION_LABEL_TYPE;

  const languageCodeEnum = productEnums?.filter(
    (type) => type.enumName === 'LanguageCodeEnum'
  );

  useImperativeHandle(forwardedRef, () => {
    return {
      initForm,
      submitForm,
      resetForm,
    };
  });

  return visible ? (
    <Form.Provider
      onFormFinish={onFormProviderFinish}
      onFormChange={() => {
        setIsChangedForm(true);
      }}
    >
      <Form layout='vertical' name='labelForm' form={form} preserve={false}>
        <Row gutter={16}>
          <Col span={8}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label='Label Type' name='nutrientFormatType'>
                  <WrapperSelect
                    defaultValue={nutrientFormatTypeOptions?.[0].value}
                    allowClear={false}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode;
                    }}
                  >
                    {[
                      nutrientFormatTypeOptions.map((labelType) => {
                        return (
                          <Option value={labelType?.value}>
                            {labelType?.label}
                          </Option>
                        );
                      }),
                    ]}
                  </WrapperSelect>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label='Nutrient Basis Quantity Type Code'
                  name='nutrientBasisQuantityTypeCode'
                >
                  <WrapperSelect
                    getPopupContainer={(triggerNode) => {
                      return triggerNode;
                    }}
                  >
                    {sortBy(
                      productNutrientBasisQuantityTypeCodeEnum?.enumProperties,
                      ['enumDisplayName', 'enumGroup']
                    ).map((item, index) => (
                      <Option value={item?.enumDisplayName} key={index}>
                        {item?.enumDisplayName}
                      </Option>
                    ))}
                  </WrapperSelect>
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                <Form.Item label='Nutrient Basis' name='nutrientBasisValue'>
                  <InputNumber
                    min={0}
                    precision={2}
                    style={styles.inputNumber}
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                <Form.Item label='Unit of Measure' name='unitOfMeasure'>
                  <WrapperSelect
                    showSearch
                    filterOption={(input, option) =>
                      option?.value
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    getPopupContainer={(triggerNode) => {
                      return triggerNode;
                    }}
                  >
                    {sortBy(
                      productBasicUOM?.[0]?.enumProperties?.filter((type) =>
                        ['Mass', 'Volume'].includes(type.enumGroup)
                      ),
                      ['enumDisplayName', 'enumGroup']
                    )?.map((item, index) => (
                      <Option value={item?.enumDisplayName} key={index}>
                        {item?.enumDisplayName}
                      </Option>
                    ))}
                  </WrapperSelect>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    screenWidth > SCREEN_WIDTH.EXTRA_LARGE
                      ? 'Servings Per Container Descriptor'
                      : 'S/C Descriptor'
                  }
                  name='servingPerContainerDescriptor'
                >
                  <WrapperSelect
                    getPopupContainer={(triggerNode) => {
                      return triggerNode;
                    }}
                  >
                    {sortBy(servingPerContainerDescriptorEnum?.enumProperties, [
                      'enumDisplayName',
                      'enumGroup',
                    ]).map((item, index) => (
                      <Option value={item?.enumDisplayName} key={index}>
                        {item?.enumDisplayName}
                      </Option>
                    ))}
                  </WrapperSelect>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    screenWidth > SCREEN_WIDTH.EXTRA_LARGE
                      ? 'Servings Per Container'
                      : 'S/C'
                  }
                  name='numberOfServingsPerPackage'
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (Number(value) > 1000) {
                          return Promise.reject([
                            'Servings Per Container should be smaller than 1000.',
                          ]);
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={1000}
                    style={styles.inputNumber}
                    formatter={(value) => {
                      if (+value > 0)
                        return (+value)?.toFixed(4)?.replace(/(\.0+|0+)$/, '');
                      return value;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                <Form.Item label='Description' name='servingSizeDescription'>
                  <Input type='text' maxLength={25} />
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                <Form.Item label='Size' name='servingSize'>
                  <InputNumber
                    min={0}
                    max={9999}
                    style={styles.inputNumber}
                    precision={2}
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                <Form.Item label='Size Unit' name='servingSizeMeasurement'>
                  <WrapperSelect
                    showSearch
                    filterOption={(input, option) =>
                      option?.value
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    getPopupContainer={(triggerNode) => {
                      return triggerNode;
                    }}
                  >
                    {sortBy(
                      productBasicUOM?.[0]?.enumProperties?.filter((type) =>
                        ['Mass', 'Volume'].includes(type.enumGroup)
                      ),
                      ['enumDisplayName', 'enumGroup']
                    )?.map((item, index) => (
                      <Option value={item?.enumDisplayName} key={index}>
                        {item?.enumDisplayName}
                      </Option>
                    ))}
                  </WrapperSelect>
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                <Form.Item label=' Calories' name='calories'>
                  <InputNumber
                    min={0}
                    max={9999}
                    style={styles.inputNumber}
                    precision={2}
                  />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label='Special Character'
                  style={{ display: 'flex' }}
                >
                  <Paragraph copyable={{ text: '*' }}>
                    * Percent Daily Values are based a 2,000 calories diet.
                  </Paragraph>
                  <Paragraph copyable={{ text: '†' }}>
                    † Percent Daily Value not established
                  </Paragraph>
                  <Paragraph copyable={{ text: '™' }}>™ Trademark</Paragraph>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8} style={{ height: '100%' }}>
            <NutrientList
              ref={dvFormRef}
              dv={dv}
              isEdit
              dailyValuesField={dailyValuesField}
              setNutritionFactProperties={setNutritionFactProperties}
              handleRemoveDV={handleRemoveDV}
            />
          </Col>
          {/* // Start Vitamins */}
          <Col span={8}>
            <Col span={24}>
              <Form.Item name='ingredientNotice' label='Ingredient Notice'>
                <Input.TextArea rows={5} maxLength={300} />
              </Form.Item>
              <Form.Item
                name='fdaLabelFootNote'
                label={
                  <span>
                    Footer Note
                    <Tooltip title='Use Default Note'>
                      <FileTextOutlined
                        onClick={handleFooterNote}
                        style={{ marginLeft: '5px' }}
                      />
                    </Tooltip>
                  </span>
                }
              >
                <Input.TextArea rows={5} maxLength={300} />
              </Form.Item>

              <Form.Item name='languageCode' label='Language Code'>
                <WrapperSelect
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  optionFilterProp='children'
                  getPopupContainer={(triggerNode) => {
                    return triggerNode;
                  }}
                >
                  {sortBy(languageCodeEnum?.[0]?.enumProperties, [
                    'enumDisplayName',
                    'enumGroup',
                  ])?.map((item, index) => (
                    <Option value={item?.enumCode} key={index}>
                      {`${
                        item.enumDescription
                      } - (${item.enumCode.toUpperCase()})`}
                    </Option>
                  ))}
                </WrapperSelect>
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    </Form.Provider>
  ) : null;
});

const styles = {
  inputNumber: {
    width: '100%',
  },
  selectAfter: {
    width: '72px',
  },
  li: {
    display: 'flex',
    justifyContent: 'flex-end',
    border: '1px solid #ddd',
    padding: '8px 12px',
    borderRadius: '4px',
    alignItems: 'center',
  },
  unit: {
    padding: '5px 8px',
    marginLeft: '5px',
    background: '#DCDCDC',
    borderRadius: '20px',
  },
  formItem: {
    marginBottom: '0px',
  },
  dv: {
    textAlign: 'center',
  },
};

export default AddNutritionLabel;
