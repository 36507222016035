import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Col, Row, Typography, Input, Form, Select, Button } from 'antd';

import messages from 'i18n/messages/home';
import { skuPropertiesList, defaultType } from 'pages/qa-spec/constant';
import EditInputCountry from 'common/components/form/edit-input-country/EditInputCountry';
import { WrapperSelect } from 'common/components';
import { CountryEditorRender } from 'pages/qa-spec/components';

import SelectCategory from 'pages/home/ribbon/components/sections/product/SelectCategory';
import { generateIdsBySelectedKey } from 'pages/company-profile/components/tabs/system/category-management/utils';

import { useCheckPermissions, useGetEnumsByEnumName } from 'hooks';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';
import { SHOULD_ASSIGN_PRODUCT_CATEGORY } from 'static/Permission';
import sortByKey from 'utils/sortByKey';
import { sortBy } from 'lodash';
import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import classnames from 'classnames';
import { red } from '@ant-design/colors';

import './QaSpecHeaderProperty.less';

import { useGetSnapshotQaDataHeader } from './hook';
import SnapshotFormItem from '../qa-spec-tab/SnapshotFormItem';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import { renderPopoverContent } from 'pages/qa-spec/utils';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const formItemProps = {
  labelCol: {
    flex: '190px',
  },
};

const styleLabelApplicable = {
  margin: '0px 0px 5px 0px',
};

const QaSpecHeaderProperty = ({
  isEditable,
  qaSpecDataHeader,
  setCategoryList,
}) => {
  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedToSupplier = checkAssignProductToSupplier();

  return (
    <>
      {isEditable ? (
        <RenderEditHeaderProperty
          qaSpecDataHeader={qaSpecDataHeader}
          setCategoryList={setCategoryList}
          isProductAssignedToSupplier={isProductAssignedToSupplier}
        />
      ) : (
        <Row style={{ padding: '0 10px' }} className='qa-sku__view'>
          <RenderHeaderProperties
            qaSpecDataHeader={qaSpecDataHeader}
            isProductAssignedToSupplier={isProductAssignedToSupplier}
          />
        </Row>
      )}
    </>
  );
};

const RenderEditHeaderProperty = ({
  setCategoryList,
  qaSpecDataHeader,
  isProductAssignedToSupplier,
}) => {
  const intl = useIntl();
  const form = Form.useFormInstance();

  const [visibleModal, setVisibleModal] = useState(false);

  const qaTypeEnums = useGetEnumsByEnumName('ProductOverviewTypeCodeEnum');

  const qaSmartLabelTemplateEnum = useGetEnumsByEnumName(
    'SmartLabelTemplateEnum'
  );

  const countryCodeEnum = useGetEnumsByEnumName('CountryCodeEnum');

  const hasPermissionAssignCategory = useCheckPermissions(
    SHOULD_ASSIGN_PRODUCT_CATEGORY
  );

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId: qaSpecDataHeader?.productId,
  });

  const checkRequiredField = (fieldName) => {
    if (!isProductAssignedToSupplier) return false;

    if (!requiredFields) return false;

    return requiredFields.includes(`qaSpecification.${fieldName}`);
  };

  const handleSelectedCategory = (node, formValue) => {
    let params = {};

    if (node) {
      const categoryList = generateIdsBySelectedKey(node?.key);
      for (let i = 0; i < categoryList.length; i++) {
        params[`category${i + 1}Id`] = categoryList[i];
      }
    } else {
      for (let i = 0; i < 4; i++) {
        params[`category${i + 1}Id`] = null;
      }
    }

    setCategoryList(params);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...formValue,
    });
  };

  const QASkuProperty = {
    selectType: {
      component: (
        <WrapperSelect showSearch allowClear={false} listHeight={350}>
          {sortByKey(qaTypeEnums, 'enumDisplayName').map((item) => {
            const valueOption = item.enumDisplayName ?? defaultType;
            return (
              <Option value={valueOption} key={valueOption}>
                {valueOption}
              </Option>
            );
          })}
        </WrapperSelect>
      ),
      selectProps: {
        options: sortBy(qaTypeEnums, ['displayName']),
        inputProps: {
          allowClear: true,
          showSearch: true,
        },
      },
    },
    selectSmartLabel: {
      component: (
        <WrapperSelect showSearch allowClear={true}>
          {sortByKey(qaSmartLabelTemplateEnum, 'enumDisplayName').map(
            (item) => {
              const valueOption = item.enumDisplayName ?? defaultType;
              return (
                <Option value={valueOption} key={valueOption}>
                  {valueOption}
                </Option>
              );
            }
          )}
        </WrapperSelect>
      ),
      selectProps: {
        options: sortBy(qaSmartLabelTemplateEnum, ['displayName']),
        inputProps: {
          allowClear: true,
          showSearch: true,
        },
      },
    },
    input: {
      component: <Input />,
    },
    disableInput: {
      component: <Input disabled={true} />,
    },
    countrySelect: {
      component: <EditInputCountry className='qa-sku-form__location' />,
      selectProps: {
        options: sortBy(countryCodeEnum, ['enumDisplayName']),
        inputProps: {
          allowClear: true,
          showSearch: true,
        },
      },
    },
  };

  return (
    <>
      <div className={classnames('qaSpec-header-sku')}>
        {skuPropertiesList(isProductAssignedToSupplier)?.map((item, idx) => {
          const label = intl.formatMessage(messages[item.label]);

          const isRequired = checkRequiredField(item.value);
          const itemRules = item.rules || [];
          const rules = [
            { required: isRequired, message: `${label} is required` },
            ...itemRules,
          ];

          return (
            <Form.Item
              key={idx}
              label={
                <Text
                  className='label-qa-header'
                  style={{ fontWeight: 'bold' }}
                >
                  {intl.formatMessage(messages[item.label])}
                </Text>
              }
              style={{ marginBottom: '6px' }}
              name={item.value}
              className={`qa-sku-form__${item.value}`}
              rules={rules}
              {...formItemProps}
            >
              {QASkuProperty[item.inputType]?.component}
            </Form.Item>
          );
        })}
        <Col lg={24} xl={24} xxl={12}>
          <Form.Item
            label={
              <Text
                className='label-qa-header'
                style={{ fontWeight: 'bold' }}
              ></Text>
            }
            className={`qa-sku-form__select-category`}
            style={{ marginBottom: '6px' }}
            {...formItemProps}
          >
            <Button
              type='primary'
              size='default'
              onClick={() => setVisibleModal(true)}
              disabled={!hasPermissionAssignCategory}
            >
              Select Category
            </Button>
          </Form.Item>
        </Col>
      </div>
      <Col span={16}>
        <Paragraph style={styleLabelApplicable}>
          <Text className='label-qa-header'>
            {intl.formatMessage(messages.qaSpecApplicableReferenceStandard)}
          </Text>
        </Paragraph>

        <Form.Item name='applicableReferenceStandard'>
          <TextArea
            rows={4}
            style={{ width: '100%', paddingLeft: '4px' }}
            maxLength={500}
            showCount
          />
        </Form.Item>
      </Col>
      <SelectCategory
        showEmptyCategory
        visible={visibleModal}
        setVisible={setVisibleModal}
        onClick={handleSelectedCategory}
      />
    </>
  );
};

const RenderHeaderProperties = ({
  qaSpecDataHeader,
  isProductAssignedToSupplier,
}) => {
  const intl = useIntl();

  const { id: productId } = useParams();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const { data: snapshotData, isLoading } = useGetSnapshotQaDataHeader({
    productId,
    enabled: !!productId && isToggleSnapshot,
  });

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId: qaSpecDataHeader?.productId,
  });

  const checkRequiredField = (fieldName) => {
    if (!isProductAssignedToSupplier) return false;

    if (!requiredFields) return false;

    return requiredFields.includes(`qaSpecification.${fieldName}`);
  };

  const renderValueSku = (field, value) => {
    if (field.toLowerCase() === 'supplierlocation')
      return (
        <CountryEditorRender
          wrapperStyle={{
            display: 'inline-flex',
            padding: 0,
          }}
          value={value}
        />
      );

    return value;
  };

  const getQaSpecHeaderValue = (field, data) => {
    const isCategoryField = field.indexOf('category') > -1;
    const isTypeField = field === 'type';

    const value = isCategoryField ? data?.[field]?.categoryName : data?.[field];

    if (isTypeField && !value) return defaultType;

    return value;
  };

  return (
    <>
      <div className='qaSpec-header-sku'>
        {skuPropertiesList(isProductAssignedToSupplier)?.map((item, idx) => {
          const supplierModifiedData =
            getQaSpecHeaderValue(item.value, qaSpecDataHeader) ?? '';

          const snapshotHeaderData =
            getQaSpecHeaderValue(item.value, snapshotData) ?? '';

          const needRequiredMark = checkRequiredField(item.value);

          const isDiffWithPreVersion =
            snapshotHeaderData !== supplierModifiedData;
          const shouldHighlight = isDiffWithPreVersion && isToggleSnapshot;
          return (
            <>
              <SnapshotFormItem
                snapshotData={snapshotHeaderData}
                supplierModifiedData={supplierModifiedData}
                isLoadingSnapshot={isLoading}
                renderPopoverContent={renderPopoverContent(
                  `${intl.formatMessage(messages[item.label])}`,
                  snapshotHeaderData,
                  supplierModifiedData
                )}
              >
                <Row style={{ width: '100%' }} wrap={false} key={idx}>
                  <Col
                    style={{ textAlign: 'right' }}
                    {...formItemProps.labelCol}
                  >
                    {needRequiredMark && (
                      <span
                        style={{
                          color: red[4],
                          fontWeight: 'bold',
                          lineHeight: 1,
                        }}
                      >
                        *
                      </span>
                    )}
                    <Text className='label-qa-header'>
                      {intl.formatMessage(messages[item.label])}:
                    </Text>
                  </Col>
                  <Col style={{ display: 'contents' }}>
                    {isLoading ? (
                      <> {renderValueSku(item.value, supplierModifiedData)}</>
                    ) : (
                      <Text
                        ellipsis={{
                          tooltip: supplierModifiedData,
                        }}
                        style={{
                          backgroundColor: shouldHighlight ? '#e6f7ff' : null,
                          minWidth: '10px',
                        }}
                      >
                        {renderValueSku(item.value, supplierModifiedData)}
                      </Text>
                    )}
                  </Col>
                </Row>
              </SnapshotFormItem>
            </>
          );
        })}
      </div>
      <Col span={16} style={{ marginTop: '38px' }}>
        <SnapshotFormItem
          snapshotData={snapshotData?.applicableReferenceStandard}
          supplierModifiedData={qaSpecDataHeader?.applicableReferenceStandard}
          isLoadingSnapshot={isLoading}
          renderPopoverContent={renderPopoverContent(
            'Applicable Reference Standard',
            snapshotData?.applicableReferenceStandard ?? '',
            qaSpecDataHeader?.applicableReferenceStandard ?? ''
          )}
          label={'Applicable Reference Standard:'}
        >
          <Paragraph style={styleLabelApplicable}>
            <Text className='label-qa-header'>
              {intl.formatMessage(messages.qaSpecApplicableReferenceStandard)}
            </Text>
          </Paragraph>
          <TextArea
            rows={4}
            style={{
              width: '100%',
              paddingLeft: '4px',
            }}
            readOnly={true}
            maxLength={500}
            value={qaSpecDataHeader?.applicableReferenceStandard}
            showCount
          />
        </SnapshotFormItem>
      </Col>
    </>
  );
};

export default QaSpecHeaderProperty;
