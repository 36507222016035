import React, { useEffect, useRef } from 'react';
import { useQaTabChecker } from './EditTabCheckerContext';

const CheckerWrapper = (props) => {
  const { isEdit, children, type = {}, hideSaveBtn = false } = props;

  const { id } = type;

  const { addEditItem, removeEditItem } = useQaTabChecker();

  useEffect(() => {
    const editItemInfo = {
      ...type,
      hideSaveBtn,
    };

    if (isEdit) {
      addEditItem(editItemInfo);
    } else {
      removeEditItem(editItemInfo);
    }
  }, [isEdit, type, hideSaveBtn]);

  return <div id={id}>{children && children}</div>;
};

const SaveActionTriggerWrapper = (props) => {
  const { children, onSave, type } = props;

  const refManipulateItem = useRef();

  const { manipulateItem } = useQaTabChecker();

  useEffect(() => {
    const { id, action } = manipulateItem || {};

    if (
      manipulateItem &&
      id === type?.id &&
      action === 'save' &&
      !refManipulateItem.current
    ) {
      refManipulateItem.current = manipulateItem;
      onSave && onSave();
    }

    if (!manipulateItem) {
      refManipulateItem.current = null;
    }
  }, [manipulateItem, type, onSave]);

  return <>{children && children} </>;
};

const CancelActionTriggerWrapper = (props) => {
  const { children, onCancel, type } = props;

  const refManipulateItem = useRef();

  const { manipulateItem } = useQaTabChecker();

  useEffect(() => {
    const { id, action } = manipulateItem || {};
    if (
      manipulateItem &&
      id === type?.id &&
      action === 'cancel' &&
      !refManipulateItem.current
    ) {
      refManipulateItem.current = manipulateItem;
      onCancel && onCancel();
    }

    if (!manipulateItem) {
      refManipulateItem.current = null;
    }
  }, [manipulateItem, type, onCancel]);

  return <>{children && children} </>;
};

const TabCheckerWrapper = (props) => {
  const { children, onChangeTab } = props;

  const refManipulateItem = useRef();

  const { manipulateItem } = useQaTabChecker();

  useEffect(() => {
    const { action, tab } = manipulateItem || {};

    if (manipulateItem && action === 'view' && !refManipulateItem.current) {
      refManipulateItem.current = manipulateItem;
      onChangeTab && onChangeTab(tab);
    }

    if (!manipulateItem) {
      refManipulateItem.current = null;
    }
  }, [manipulateItem, onChangeTab]);

  return <>{children && children} </>;
};

const EditTabSectionWrapper = {
  CheckerWrapper,
  SaveActionTriggerWrapper,
  CancelActionTriggerWrapper,
  TabCheckerWrapper,
};

export default EditTabSectionWrapper;
