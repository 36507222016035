import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ShareControl from '../../controls/shared/ShareControl';

import { AbilityContext } from 'context/Can';

import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { useCheckPrivateMember } from 'hooks/useCheckPrivateMember';

const ShareSection = (props) => {
  const { selectedItemList, type, assetDetailId } = props;

  const ability = useContext(AbilityContext);

  const isPrivateMember = useCheckPrivateMember();

  return (
    <React.Fragment>
      {!isPrivateMember && (
        <>
          {ability.can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SHARED_ENTITY) &&
            (ability.can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.SHARED_ENTITY) ||
              ability.can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MEMBER) ||
              ability.can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.USER)) && (
              <ShareControl
                type={type}
                detailCurrentItemsSelection={selectedItemList}
                assetDetailId={assetDetailId}
              />
            )}
        </>
      )}
    </React.Fragment>
  );
};

ShareSection.propsTypes = {
  selectedItemList: PropTypes.array,
};

export default ShareSection;
