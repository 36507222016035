import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import * as globalSelectors from 'redux/global/selectors';
import * as globalActions from 'redux/global/actions';

export const useFilterGrid = ({ entityType = 'none', clearOtherFilter }) => {
  const dispatch = useDispatch();

  const selectorCollection = {
    product: 'selectFilterProducts',
    asset: 'selectFilterAssets',
    member: 'selectFilterMembers',
    ssoApprovalUser: 'selectToggleSsoUserPending',
    ssoApprovalMember: 'selectToggleSsoMemberPending',
    memberProduct: 'selectFilterMemberProducts',
    userMaintenance: 'selectFilterUserMaintenance',
    ticketingSystem: 'selectFilterTicketingSystem',
    none: 'selectFilterNone',
  };

  const actionCollection = {
    product: { action: 'updateFilterProducts', defaultParam: [] },
    asset: { action: 'updateFilterAssets', defaultParam: [] },
    member: { action: 'updateFilterMembers', defaultParam: [] },
    ssoApprovalUser: {
      action: 'toggleFilterPendingSsoUser',
      defaultParam: [
        {
          fieldName: 'poolStatus',
          values: ['Pending'],
          filterType: 'In',
        },
      ],
    },
    ssoApprovalMember: {
      action: 'toggleFilterPendingSsoMember',
      defaultParam: [
        {
          fieldName: 'poolStatus',
          values: ['Pending'],
          filterType: 'In',
        },
      ],
    },
    memberProduct: { action: 'updateFilterMemberProduct', defaultParam: [] },
    userMaintenance: {
      action: 'updateFilterUserMaintenance',
      defaultParam: [],
    },
    ticketingSystem: {
      action: 'updateFilterTicketingSystem',
      defaultParam: [],
    },
  };

  const gridFilters = useSelector(
    globalSelectors[selectorCollection[entityType]]()
  );

  const updateGridFilter = (filters) => {
    if (entityType === 'none') {
      console.error(
        'Please add entityType if you want to use updateGridFilter'
      );
      return;
    }

    dispatch(globalActions[actionCollection[entityType].action](filters));
  };

  const clearOtherFilters = () => {
    //* clear filters of other page, keep current page
    const otherPage = Object.keys(actionCollection).filter((page) => {
      return page !== entityType;
    });

    if (!otherPage.length) return;

    otherPage.forEach((page) => {
      dispatch(
        globalActions[actionCollection[page].action](
          actionCollection[page].defaultParam
        )
      );
    });
  };

  useEffect(() => {
    clearOtherFilter && clearOtherFilters();
  }, []);

  return {
    gridFilters,
    updateGridFilter,
  };
};
