import { set, get } from 'lodash';

import { PERMISSION_CATEGORY_DEFINE } from 'static/Constants';

const categorizeCondition = ({
  cate,
  fieldName,
  permission,
  nextGroupedPermit,
}) => {
  if (cate?.permission.includes(permission?.permissionDisplayName)) {
    setPermissionValue({ fieldName, permission, nextGroupedPermit });
    return true;
  }

  return false;
};

const setPermissionValue = ({ fieldName, permission, nextGroupedPermit }) => {
  const currentValue = get(nextGroupedPermit, fieldName);
  set(nextGroupedPermit, fieldName, [...(currentValue || []), permission]);
};

const handleCategorizeOnePermission = ({ permission, nextGroupedPermit }) => {
  for (let [key, value] of Object.entries(PERMISSION_CATEGORY_DEFINE)) {
    const isMatch = categorizeCondition({
      cate: value,
      fieldName: key,
      permission,
      nextGroupedPermit,
    });

    if (isMatch) return;
  }
};

const categorizePermisison = ({ rolePermissions }) => {
  if (!rolePermissions || rolePermissions?.length <= 0) return {};

  let nextGroupedPermit = {};

  rolePermissions.forEach((permission) =>
    handleCategorizeOnePermission({ permission, nextGroupedPermit })
  );

  return nextGroupedPermit;
};

const lookForPermission = ({ searchText, rolePermissions }) => {
  if (!searchText) return rolePermissions;

  return rolePermissions.filter((permission) => {
    let permissionName = permission?.permissionDisplayName;

    if (typeof permissionName === 'string') {
      return permissionName
        .toLocaleLowerCase()
        .includes(searchText.toLowerCase());
    } else {
      return true;
    }
  });
};

const getRolePermissionList = ({
  type,
  availPermissions,
  rolePermissionListData,
  formRole,
}) => {
  const filterToGetRemainAvailPermisions = (permit) =>
    !(formRole?.values?.permissions || []).find(
      (addedPermit) => addedPermit?.permissionName === permit?.permissionName
    );

  const mapToPermissionListWithNewItemInfo = (permit, type = 'add') => {
    if (!rolePermissionListData) return permit;

    const isFoundInPrevPermissionList = rolePermissionListData.find(
      (prevPermit) => prevPermit?.permissionName === permit?.permissionName
    );

    if (type === 'add' && isFoundInPrevPermissionList) {
      return { ...permit, isNew: true };
    } else if (type === 'remove' && !isFoundInPrevPermissionList) {
      return { ...permit, isNew: true };
    }

    return permit;
  };

  //*NOTE - 20/1/2021 - arcording to requirement we need to remove super admin permission from adding list
  const filterPermissionNotSuperAdminInAddPermisionList = (permit) =>
    permit?.permissionName === 'SUPER_ADMIN' ? false : permit;

  switch (type) {
    case 'add':
      return (availPermissions || [])
        .filter(filterPermissionNotSuperAdminInAddPermisionList)
        .filter(filterToGetRemainAvailPermisions)
        .map((permit) => mapToPermissionListWithNewItemInfo(permit, type));
    case 'remove':
      return (formRole?.values?.permissions || []).map((permit) =>
        mapToPermissionListWithNewItemInfo(permit, type)
      );
    default:
      return rolePermissionListData;
  }
};

const mapToCategoriesAndSearchPermisison = ({
  availPermissions,
  rolePermissionListData,
  searchText,
  searchCategories,
  type,
  formRole,
}) => {
  const rolePermissions = getRolePermissionList({
    type,
    availPermissions,
    rolePermissionListData,
    formRole,
  });

  const searchedPermission = lookForPermission({
    searchText,
    rolePermissions,
  });

  let categorizedResult = categorizePermisison({
    rolePermissions: searchedPermission,
  });

  let searchCategoryFiltered = {};

  searchCategories.forEach((categoryName) => {
    const isHavePermission = !!categorizedResult?.[categoryName];
    if (isHavePermission) {
      searchCategoryFiltered[categoryName] = categorizedResult?.[categoryName];
    }
  });

  return [searchCategoryFiltered, categorizedResult];
};

export { mapToCategoriesAndSearchPermisison };
