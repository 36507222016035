import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { BasicFormItem } from 'common/components/form/basic-form';
import { Form, BorderButton } from 'common/components';

import SnapshotFormItem from 'pages/qa-spec/components/qa-spec-tab/SnapshotFormItem';

import { getSnapshotQaSpecProductContains } from 'services/product';
import { getFormattedValue } from 'pages/qa-spec/utils';
import { renderPopoverContent } from 'pages/qa-spec/utils';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

const enumType = {
  palmOilCertified: [
    { displayName: 'Yes', value: 'Yes' },
    { displayName: 'No', value: 'No' },
  ],
};

export const useGetQaProductContainFormDefine = ({
  handleCheckRequireFields,
  setUploadModalTitle,
  snapshotData,
  supplierModifiedData,
  handleToggleUploadDocumentModal,
}) => {
  const { id: productId } = useParams();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const { isLoading } = useGetSnapshotQaProductContains({
    enabled: !!productId && isToggleSnapshot,
    productId,
  });

  const requiredRule = useCallback(
    ({ label, fieldName }) => ({
      required: handleCheckRequireFields(fieldName),
      message: `${label ? label : 'This field'} is required`,
    }),
    []
  );

  const formDefine = useMemo(() => {
    return [
      [
        { rowConfig: { gutter: [15, 15] } },
        [
          {
            colProps: {
              sm: 12,
              xxl: 11,
            },
          },
          {
            name: '_1_Bromopropane',
            label: '1-Bromopropane',
            type: 'select',
            required: handleCheckRequireFields('_1_Bromopropane'),
            rules: [
              requiredRule({
                fieldName: '_1_Bromopropane',
                label: '1-Bromopropane',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['_1_Bromopropane'],
            snapshotData: snapshotData?.['_1_Bromopropane'],
            renderPopoverContent: renderPopoverContent(
              '1-Bromopropane',
              snapshotData?.['_1_Bromopropane'],
              supplierModifiedData?.['_1_Bromopropane']
            ),
          },
          {
            name: 'asbestos',
            label: 'Asbestos',
            type: 'select',
            required: handleCheckRequireFields('asbestos'),
            rules: [requiredRule({ label: 'Asbestos', fieldName: 'asbestos' })],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['asbestos'],
            snapshotData: snapshotData?.['asbestos'],
            renderPopoverContent: renderPopoverContent(
              'Asbestos',
              snapshotData?.['asbestos'],
              supplierModifiedData?.['asbestos']
            ),
          },
          {
            name: '_1_4_Dioxane',
            label: '1.4-dioxane',
            type: 'select',
            required: handleCheckRequireFields('_1_4_Dioxane'),
            rules: [
              requiredRule({ fieldName: '_1_4_Dioxane', label: '1.4-dioxane' }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['_1_4_Dioxane'],
            snapshotData: snapshotData?.['_1_4_Dioxane'],
            renderPopoverContent: renderPopoverContent(
              '1.4-dioxane',
              snapshotData?.['_1_4_Dioxane'],
              supplierModifiedData?.['_1_4_Dioxane']
            ),
          },
          {
            name: 'hbcd',
            label:
              'Cyclic Aliphatic Bromides Cluster of flame retardants (HBCD)',
            type: 'select',
            required: handleCheckRequireFields('hbcd'),
            rules: [
              requiredRule({
                fieldName: 'hbcd',
                label:
                  'Cyclic Aliphatic Bromides Cluster of flame retardants (HBCD)',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['hbcd'],
            snapshotData: snapshotData?.['hbcd'],
            renderPopoverContent: renderPopoverContent(
              'Cyclic Aliphatic Bromides Cluster of flame retardants (HBCD)',
              snapshotData?.['hbcd'],
              supplierModifiedData?.['hbcd']
            ),
          },
          {
            name: 'd4',
            label: 'Octamethylcyclotetrasiloxane (D4)',
            type: 'select',
            required: handleCheckRequireFields('d4'),
            rules: [
              requiredRule({
                label: 'Octamethylcyclotetrasiloxane (D4)',
                fieldName: 'd4',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['d4'],
            snapshotData: snapshotData?.['d4'],
            renderPopoverContent: renderPopoverContent(
              'Octamethylcyclotetrasiloxane (D4)',
              snapshotData?.['d4'],
              supplierModifiedData?.['d4']
            ),
          },
          {
            name: 'leadCompounds',
            label: 'Lead and Lead Compounds',
            type: 'select',
            required: handleCheckRequireFields('leadCompounds'),
            rules: [
              requiredRule({
                fieldName: 'leadCompounds',
                label: 'Lead and Lead Compounds',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['leadCompounds'],
            snapshotData: snapshotData?.['leadCompounds'],
            renderPopoverContent: renderPopoverContent(
              'Lead and Lead Compounds',
              snapshotData?.['leadCompounds'],
              supplierModifiedData?.['leadCompounds']
            ),
          },
          {
            name: 'cadmiumCompounds',
            label: 'Cadmium and Cadmium Compounds',
            type: 'select',
            required: handleCheckRequireFields('cadmiumCompounds'),
            rules: [
              requiredRule({
                label: 'Cadmium and Cadmium Compounds',
                fieldName: 'cadmiumCompounds',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['cadmiumCompounds'],
            snapshotData: snapshotData?.['cadmiumCompounds'],
            renderPopoverContent: renderPopoverContent(
              'Cadmium and Cadmium Compounds',
              snapshotData?.['cadmiumCompounds'],
              supplierModifiedData?.['cadmiumCompounds']
            ),
          },
          {
            name: 'bisphenolA',
            label: 'Bisphenol A (BPA)',
            type: 'select',
            required: handleCheckRequireFields('bisphenolA'),
            rules: [
              requiredRule({
                label: 'Bisphenol A (BPA)',
                fieldName: 'bisphenolA',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['bisphenolA'],
            snapshotData: snapshotData?.['bisphenolA'],
            renderPopoverContent: renderPopoverContent(
              'Bisphenol A (BPA)',
              snapshotData?.['bisphenolA'],
              supplierModifiedData?.['bisphenolA']
            ),
          },
          {
            name: 'diethylPhthalate',
            label: 'Diethyl phthalate',
            type: 'select',
            required: handleCheckRequireFields('diethylPhthalate'),
            rules: [
              requiredRule({
                label: 'Diethyl phthalate',
                fieldName: 'diethylPhthalate',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['diethylPhthalate'],
            snapshotData: snapshotData?.['diethylPhthalate'],
            renderPopoverContent: renderPopoverContent(
              'Diethyl phthalate',
              snapshotData?.['diethylPhthalate'],
              supplierModifiedData?.['diethylPhthalate']
            ),
          },
          {
            name: 'dibutylPhthalate',
            label: 'Dibutyl phthalate',
            type: 'select',
            required: handleCheckRequireFields('dibutylPhthalate'),
            rules: [
              requiredRule({
                label: 'Dibutyl phthalate',
                fieldName: 'dibutylPhthalate',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['dibutylPhthalate'],
            snapshotData: snapshotData?.['dibutylPhthalate'],
            renderPopoverContent: renderPopoverContent(
              'Dibutyl phthalate',
              snapshotData?.['dibutylPhthalate'],
              supplierModifiedData?.['dibutylPhthalate']
            ),
          },
        ],
        [
          {
            colProps: {
              sm: 12,
              xxl: 11,
            },
          },
          {
            name: 'tetrachloroethylene',
            label: 'Tetrachloroethylene (PERC)',
            type: 'select',
            required: handleCheckRequireFields('tetrachloroethylene'),
            rules: [
              requiredRule({
                label: 'Tetrachloroethylene (PERC)',
                fieldName: 'tetrachloroethylene',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['tetrachloroethylene'],
            snapshotData: snapshotData?.['tetrachloroethylene'],
            renderPopoverContent: renderPopoverContent(
              'Tetrachloroethylene (PERC)',
              snapshotData?.['tetrachloroethylene'],
              supplierModifiedData?.['tetrachloroethylene']
            ),
          },
          {
            name: 'formaldehyde',
            label: 'Formaldehyde',
            type: 'select',
            required: handleCheckRequireFields('formaldehyde'),
            rules: [
              requiredRule({
                label: 'Formaldehyde',
                fieldName: 'formaldehyde',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['formaldehyde'],
            snapshotData: snapshotData?.['formaldehyde'],
            renderPopoverContent: renderPopoverContent(
              'Formaldehyde',
              snapshotData?.['formaldehyde'],
              supplierModifiedData?.['formaldehyde']
            ),
          },
          {
            name: 'toluene',
            label: 'Toluene',
            type: 'select',
            required: handleCheckRequireFields('toluene'),
            rules: [
              requiredRule({
                fieldName: 'toluene',
                label: 'Toluene',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['toluene'],
            snapshotData: snapshotData?.['toluene'],
            renderPopoverContent: renderPopoverContent(
              'Toluene',
              snapshotData?.['toluene'],
              supplierModifiedData?.['toluene']
            ),
          },
          {
            name: 'nonylphenolEthoxylates',
            label: 'Nonylphenol Ethoxylates',
            type: 'select',
            required: handleCheckRequireFields('nonylphenolEthoxylates'),
            rules: [
              requiredRule({
                fieldName: 'nonylphenolEthoxylates',
                label: 'Nonylphenol Ethoxylates',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData:
              supplierModifiedData?.['nonylphenolEthoxylates'],
            snapshotData: snapshotData?.['nonylphenolEthoxylates'],
            renderPopoverContent: renderPopoverContent(
              'Nonylphenol Ethoxylates',
              snapshotData?.['nonylphenolEthoxylates'],
              supplierModifiedData?.['nonylphenolEthoxylates']
            ),
          },
          {
            name: 'butylparaben',
            label: 'Butylparaben',
            type: 'select',
            required: handleCheckRequireFields('butylparaben'),
            rules: [
              requiredRule({
                fieldName: 'butylparaben',
                label: 'Butylparaben',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['butylparaben'],
            snapshotData: snapshotData?.['butylparaben'],
            renderPopoverContent: renderPopoverContent(
              'Butylparaben',
              snapshotData?.['butylparaben'],
              supplierModifiedData?.['butylparaben']
            ),
          },
          {
            name: 'propylparaben',
            label: 'Propylparaben',
            type: 'select',
            required: handleCheckRequireFields('propylparaben'),
            rules: [
              requiredRule({
                fieldName: 'propylparaben',
                label: 'Propylparaben',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['propylparaben'],
            snapshotData: snapshotData?.['propylparaben'],
            renderPopoverContent: renderPopoverContent(
              'Propylparaben',
              snapshotData?.['propylparaben'],
              supplierModifiedData?.['propylparaben']
            ),
          },
          {
            name: 'trichloroethylene',
            label: 'Trichloroethylene',
            type: 'select',
            required: handleCheckRequireFields('trichloroethylene'),
            rules: [
              requiredRule({
                fieldName: 'trichloroethylene',
                label: 'Trichloroethylene',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['trichloroethylene'],
            snapshotData: snapshotData?.['trichloroethylene'],
            renderPopoverContent: renderPopoverContent(
              'Trichloroethylene',
              snapshotData?.['trichloroethylene'],
              supplierModifiedData?.['trichloroethylene']
            ),
          },
          {
            name: 'triclocarban',
            label: 'Triclocarban',
            type: 'select',
            required: handleCheckRequireFields('triclocarban'),
            rules: [
              requiredRule({
                fieldName: 'triclocarban',
                label: 'Triclocarban',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['triclocarban'],
            snapshotData: snapshotData?.['triclocarban'],
            renderPopoverContent: renderPopoverContent(
              'Triclocarban',
              snapshotData?.['triclocarban'],
              supplierModifiedData?.['triclocarban']
            ),
          },
          {
            name: 'triclosan',
            label: 'Triclosan',
            type: 'select',
            required: handleCheckRequireFields('triclosan'),
            rules: [
              requiredRule({
                fieldName: 'triclosan',
                label: 'Triclosan',
              }),
            ],
            selectProps: {
              inputProps: {
                allowClear: true,
              },
              options: [
                { displayName: 'Yes', value: 'Yes' },
                { displayName: 'No', value: 'No' },
              ],
            },
            supplierModifiedData: supplierModifiedData?.['triclosan'],
            snapshotData: snapshotData?.['triclosan'],
            renderPopoverContent: renderPopoverContent(
              'Triclosan',
              snapshotData?.['triclosan'],
              supplierModifiedData?.['triclosan']
            ),
          },
          [
            { rowConfig: { gutter: [0, 0], wrap: false } },
            [
              {
                colProps: {
                  flex: 1,
                },
              },
            ],
            [
              {
                colProps: {
                  flex: '384px',
                },
              },
              {
                noStyle: true,
                shouldUpdate: (prevValues, currentValues) => true,
                renderInput:
                  ({ allowEdit }) =>
                  ({ getFieldValue }) => {
                    const palmOilValue = getFieldValue('palmOil');
                    const palmOilCertified = getFieldValue('palmOilCertified');

                    const disabled = palmOilValue === 'No' || !palmOilValue;

                    return (
                      <>
                        <Row
                          className='qa-product-contain__palm-oil-group'
                          align='middle'
                          wrap={false}
                        >
                          <Col flex='150px' style={{ position: 'unset' }}>
                            <SnapshotFormItem
                              supplierModifiedData={
                                supplierModifiedData?.['palmOil']
                              }
                              snapshotData={snapshotData?.['palmOil']}
                              renderPopoverContent={renderPopoverContent(
                                'Palm Oil',
                                snapshotData?.['palmOil'],
                                supplierModifiedData?.['palmOil']
                              )}
                            >
                              <BasicFormItem
                                layout='horizontal'
                                name='palmOil'
                                label='Palm Oil'
                                type='select'
                                labelCol={{
                                  flex: '65px',
                                }}
                                wrapperCol={{
                                  flex: '65px',
                                }}
                                allowEdit={allowEdit}
                                rules={[
                                  requiredRule({
                                    fieldName: 'palmOil',
                                    label: 'Palm Oil',
                                  }),
                                ]}
                                selectProps={{
                                  style: { width: '70px' },
                                  inputProps: {
                                    allowClear: true,
                                  },
                                  options: [
                                    { displayName: 'Yes', value: 'Yes' },
                                    { displayName: 'No', value: 'No' },
                                  ],
                                }}
                              />
                            </SnapshotFormItem>
                          </Col>
                          <Col flex='160px' style={{ position: 'unset' }}>
                            <SnapshotFormItem
                              supplierModifiedData={
                                supplierModifiedData?.['palmOilCertified']
                              }
                              snapshotData={snapshotData?.['palmOilCertified']}
                              isLoadingSnapshot={isLoading}
                              renderPopoverContent={renderPopoverContent(
                                'Certified',
                                snapshotData?.['palmOilCertified'],
                                supplierModifiedData?.['palmOilCertified'],
                                getFormattedValue(
                                  snapshotData?.['palmOilCertified'],
                                  'palmOilCertified',
                                  enumType
                                ),
                                null
                              )}
                            >
                              <BasicFormItem
                                layout='horizontal'
                                name='palmOilCertified'
                                label='Certified'
                                type='select'
                                labelCol={{
                                  flex: '65px',
                                }}
                                wrapperCol={{
                                  flex: '70px',
                                }}
                                allowEdit={allowEdit}
                                rules={[
                                  {
                                    required:
                                      handleCheckRequireFields('palmOil') &&
                                      palmOilValue === 'Yes',
                                    message: 'Certified is required',
                                  },
                                ]}
                                selectProps={{
                                  style: { width: '70px' },
                                  inputProps: {
                                    disabled,
                                    allowClear: true,
                                  },
                                  options: [
                                    { displayName: 'Yes', value: 'Yes' },
                                    { displayName: 'No', value: 'No' },
                                  ],
                                }}
                              />
                            </SnapshotFormItem>
                          </Col>
                          <Col style={{ position: 'unset' }}>
                            <Form.Item
                              name='isAssetLink'
                              label=''
                              help={
                                !palmOilValue ||
                                palmOilValue === 'No' ||
                                !palmOilCertified ||
                                palmOilCertified === 'No'
                                  ? ''
                                  : undefined
                              }
                              rules={[
                                {
                                  required:
                                    palmOilValue === 'Yes' &&
                                    palmOilCertified === 'Yes',
                                  message: 'Palm Oil Certificate is required',
                                },
                              ]}
                            >
                              <BorderButton
                                type=''
                                danger
                                icon={<UploadOutlined />}
                                disabled={
                                  !allowEdit ||
                                  !palmOilCertified ||
                                  palmOilCertified === 'No'
                                }
                                onClick={() => {
                                  handleToggleUploadDocumentModal();
                                  setUploadModalTitle(
                                    'Upload Palm Oil Certificate'
                                  );
                                }}
                              >
                                Upload
                              </BorderButton>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    );
                  },
              },
            ],
          ],
        ],
      ],
    ];
  }, [
    snapshotData,
    handleToggleUploadDocumentModal,
    supplierModifiedData,
    setUploadModalTitle,
    isLoading,
    requiredRule,
  ]);

  return formDefine;
};

const getQueryKeyQaProductContains = (productId) => {
  return ['pim', 'snapshot', parseInt(productId), 'qa-product-contain'];
};

export const useGetSnapshotQaProductContains = ({
  enabled,
  productId,
  ...restProps
}) => {
  const queryClient = useQueryClient();

  const getSnapshotQaProductContainsData = async () => {
    const response = await getSnapshotQaSpecProductContains({ productId });
    const { isSuccess, data } = response;
    if (!isSuccess) return null;

    return {
      ...data,
      contains: data?.contains || [],
      doesNotContain: data?.doesNotContain || [],
    };
  };

  const query = useQuery({
    queryKey: getQueryKeyQaProductContains(productId),
    queryFn: getSnapshotQaProductContainsData,
    enabled,
    ...restProps,
  });

  const invalidateSnapshotQaSpecProductContains = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyQaProductContains(productId),
    });
  };

  return { ...query, invalidateSnapshotQaSpecProductContains };
};
