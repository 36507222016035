import produce from 'immer';
import * as types from './constants';

export const initialState = {
  loading: false,
  paging: {
    pageSize: 20,
    pageNumber: 1,
    total: 0,
  },
  columns: [],
  communications: [],
  mode: {
    isCreate: false,
    isEdit: false,
  },
  idTemplate: null,
  isToggleSave: false,
  disableUploadMedia: false,
  error: null,
};

const communicationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_COMMUNICATION_TEMPLATES_COLUMNS:
        draft.loading = true;
        break;

      case types.GET_COMMUNICATION_TEMPLATES_COLUMNS_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;

      case types.GET_COMMUNICATION_TEMPLATES_COLUMNS_ERROR:
        draft.loading = false;
        draft.columns = [];
        draft.error = action.error;
        break;

      case types.GET_COMMUNICATION_TEMPLATES:
        draft.loading = true;
        draft.error = false;
        break;

      case types.GET_COMMUNICATION_TEMPLATES_SUCCESS:
        draft.loading = false;
        draft.communications = action.communications;
        draft['paging'].pageSize = action.paging.currentPageSize;
        draft['paging'].pageNumber = action.paging.currentPageIndex;
        draft['paging'].total = action.paging.totalRecord;
        break;

      case types.GET_COMMUNICATION_TEMPLATES_ERROR:
        draft.loading = false;
        draft['paging'].total = 0;
        draft.communications = [];
        draft.error = action.error;
        break;

      case types.ENABLE_CREATE_TEMPLATE:
        draft['mode'].isCreate = true;
        draft['mode'].isEdit = false;
        break;

      case types.ENABLE_EDIT_TEMPLATE:
        draft['mode'].isEdit = true;
        draft['mode'].isCreate = false;
        break;

      case types.RESET_TEMPLATE:
        draft['mode'].isEdit = false;
        draft['mode'].isCreate = false;
        draft.idTemplate = null;
        draft.isToggleSave = false;
        break;

      case types.SAVE_ID_TEMPLATE:
        draft.idTemplate = action.id;
        break;

      case types.ENABLE_SAVE_TEMPLATE:
        draft.isToggleSave = true;
        break;

      case types.UNABLE_SAVE_TEMPLATE:
        draft.isToggleSave = false;
        break;

      case types.DISABLE_UPLOAD_MEDIA:
        draft.disableUploadMedia = action.status;
        break;

      default:
        break;
    }
  });

export default communicationsReducer;
