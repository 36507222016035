import React from 'react';
import Messages from 'i18n/messages/home';

import { DownloadOutlined } from '@ant-design/icons';

// import { ReactComponent as IconDownloadBrand } from 'common/components/button/svg-icons/ICO_10.43.svg';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const DownloadBrand = () => {
  return (
    <ButtonSmallIcon
      icon={<DownloadOutlined />}
      label={Messages.downloadBrand}
    />
  );
};

export default DownloadBrand;
