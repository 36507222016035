import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Space, Tooltip } from 'antd';
import {
  DragOutlined,
  PhoneOutlined,
  CompressOutlined,
  ExpandOutlined,
  RollbackOutlined,
  MessageOutlined,
  MinusOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';

import VideoToolBtnWrap from '../button/VideoToolBtnWrap';

import { ReactComponent as ShareScreenIcon } from 'common/components/icon/ShareScreen.svg';

import * as chatActions from 'common/components/message/controller/actions';
import * as globalActions from 'redux/global/actions';

import { useCheckPermissions } from 'hooks/useCheckPermissions';

import classnames from 'classnames';
import { sleep } from 'utils/delay';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import './VideoCallToolbox.less';

const CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT = [
  {
    action: ABILITY_ACTION.CHAT,
    subject: ABILITY_SUBJECT.CHAT_COMPANY,
  },
];
const CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT = [
  {
    action: ABILITY_ACTION.CHAT,
    subject: ABILITY_SUBJECT.CHAT_PERSONAL,
  },
];

const VideoCallToolbox = (props) => {
  const {
    loading,
    isCallAccepted,
    isFullScreen,
    isScreenSharing,
    isChatViewVideoOn,
    toggleFullScreen,
    endCall,
    toggleLockFrame,
    endLoadScreen,
    toggleShareScreen,
    toggleChatView,
    toggleMinimize,
    callData,
    userInfo,
    isMinized,
  } = props;

  const dispatch = useDispatch();

  const hasPersonalChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT
  );
  const hasCompanyChatPermission = useCheckPermissions(
    CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT
  );

  const hasChatPermission =
    hasPersonalChatPermission ||
    hasCompanyChatPermission ||
    userInfo?.isAnonymous;

  const handleToggleFullScreen = () => toggleFullScreen && toggleFullScreen();

  const handleEndCall = () => {
    endCall && endCall();
    isFullScreen && toggleFullScreen();
  };

  const handleMouseLeave = () => toggleLockFrame(false);

  const handleMouseEnter = () => toggleLockFrame(true);

  const setView = (viewName) => {
    dispatch(chatActions.setChatView(viewName));
  };

  const handleClickCommunicationItem = async (communicationItem) => {
    // Make Thread as read
    if (isChatViewVideoOn) return;
    const { threadId, userId, readStatus } = communicationItem;
    const payload = { userId: userId, threadId };

    setView && setView('threadMsg');

    dispatch(chatActions.getThreadInfoSuccess(null));
    await sleep(500);

    if (!readStatus) {
      dispatch(globalActions.makeAThreadAsRead(payload));
    }

    dispatch(chatActions.getThreadInfo(threadId, userId));
    dispatch(chatActions.getThreadMessages(threadId));

    dispatch(chatActions.updateShowThreadWarningModal(true));
    dispatch(chatActions.toggleNewMessage(true));
    dispatch(chatActions.saveActiveThreadInfo(communicationItem));
    dispatch(chatActions.setUnreadMessages([])); // change between thread in Maximize view
  };

  const handleToggleChatView = () => {
    if (!hasChatPermission) return;

    toggleChatView && toggleChatView();
    const threadItem = callData?.threadItem;

    if (threadItem?.threadId) {
      handleClickCommunicationItem(threadItem);
    }
  };

  const handleToggleMinimize = () => {
    toggleMinimize && toggleMinimize();
  };

  if (loading && isCallAccepted)
    return (
      <Tooltip placement='topRight' title={'exit'}>
        <VideoToolBtnWrap
          className={classnames('video-call-tool__btn')}
          onClick={endLoadScreen}
        >
          <RollbackOutlined />
        </VideoToolBtnWrap>
      </Tooltip>
    );

  if (!loading && isCallAccepted)
    return (
      <Space direction='vertical'>
        <Tooltip placement='right' title='Move' mouseEnterDelay={1}>
          <VideoToolBtnWrap
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isHidden={isFullScreen}
          >
            <strong className='video-call-tool__dragger'>
              <DragOutlined />
            </strong>
          </VideoToolBtnWrap>
        </Tooltip>
        <Tooltip
          placement='right'
          title={isMinized ? 'Expand view' : 'Minize view'}
          mouseEnterDelay={1}
        >
          <VideoToolBtnWrap
            onClick={handleToggleMinimize}
            isHidden={isFullScreen}
          >
            {isMinized ? <FullscreenOutlined /> : <MinusOutlined />}
          </VideoToolBtnWrap>
        </Tooltip>
        <Tooltip
          placement='right'
          title={isFullScreen ? 'Window view' : 'Full screen view'}
          mouseEnterDelay={1}
        >
          <VideoToolBtnWrap onClick={handleToggleFullScreen}>
            {isFullScreen ? <CompressOutlined /> : <ExpandOutlined />}
          </VideoToolBtnWrap>
        </Tooltip>
        <Tooltip
          placement='right'
          title={isScreenSharing ? 'Share screen off' : 'Share screen on'}
          mouseEnterDelay={1}
        >
          <VideoToolBtnWrap
            onClick={toggleShareScreen}
            isActive={isScreenSharing}
          >
            <ShareScreenIcon width={26} height={26} />
          </VideoToolBtnWrap>
        </Tooltip>
        <Tooltip
          placement='right'
          title={
            hasChatPermission
              ? isChatViewVideoOn
                ? 'Close Chat View'
                : 'Open Chat View'
              : 'You do not have chat permission'
          }
          mouseEnterDelay={1}
        >
          <VideoToolBtnWrap
            onClick={handleToggleChatView}
            isActive={isChatViewVideoOn}
            isHidden={isMinized}
          >
            <MessageOutlined />
          </VideoToolBtnWrap>
        </Tooltip>
        <Tooltip placement='right' title='End call' mouseEnterDelay={1}>
          <VideoToolBtnWrap
            className={classnames('video-call-tool__btn-hang')}
            onClick={handleEndCall}
          >
            <PhoneOutlined />
          </VideoToolBtnWrap>
        </Tooltip>
      </Space>
    );

  return null;
};

VideoCallToolbox.propTypes = {
  loading: PropTypes.bool,
  isCallAccepted: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  toggleFullScreen: PropTypes.func,
  //* end call
  endCall: PropTypes.func,
  //* lock frame for drag
  toggleLockFrame: PropTypes.func,
  //* quit video screen wnen load fail
  endLoadScreen: PropTypes.func,
};

export default VideoCallToolbox;
