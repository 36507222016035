// import ribbon components
import Favorite from './sections/home-grid/Favorite';
import View from './sections/home-grid/ViewLayout';
import Folder from './sections/home-grid/Folder';
import Search from './sections/home-grid/SearchLayout';
import Grid from './sections/home-grid/GridLayout';
import Other from './sections/home-grid/OtherLayout';

import Addition from './controls/home/Addition';
import AddFolder from './controls/home/AddFolder';
import Detail from './controls/home/DetailView';
import QuickView from './controls/home/QuickView';
import SearchFolder from './controls/home/SearchFolder';
import DeleteFolder from './controls/home/DeleteFolder';
import ViewAlt from './controls/home/InlineControl';
import SearchAlt from './controls/home/Search';
import BackAlt from './controls/home/Back';
import AdvanceSearch from './controls/home/AdvanceSearch';
import GridColumns from './controls/home/grid-query/GridColumns';
import SaveGrid from './controls/home/grid-query/SaveGrid';
import AddCuratedQuery from './controls/home/grid-query/AddCuratedQuery';
import ManageGridQuery from './controls/home/grid-query/ManageGridQuery';
import SavedConfigurations from './controls/home/grid-query/SavedConfigurations';
import ClearQuery from './controls/home/grid-query/ClearQuery';
import EditMember from './sections/member-full/EditMember';
import BrandImage from './sections/member-full/BrandImage';
import ToggleHelpUploadMediaSection from './sections/post-full/ToggleHelpUploadMediaSection';
import ToggleEulaUploadMediaSection from './sections/eula-full/ToggleEulaUploadMediaSection';
import ToggleTagLineUploadMediaSection from './sections/member-full/ToggleTagLineUploadMediaSection';

import OpenAsset from './controls/asset/OpenAsset';

import GeneratePreviewModal from './modal/generate-preview/GeneratePreviewModal';
import GenerateConfirmModal from './modal/generate-confirm/GenerateConfirmModal';
import GeneratePreviewItem from './modal/generate-preview/GeneratePreviewItem';
import ManageShareModal from './modal/manage-share/ManageShareModal';
import ManageShareModalToolbar from './modal/manage-share/ManageShareModalToolbar';

import AdvanceFilter from './controls/home/AdvanceFilter';
import AdvanceStack from './controls/home/AdvanceStack';
import PimActions from './controls/home/PimActions';
import GridConfig from './sections/home-grid/GridConfig';

import ManageSharingSection from '../components/sections/shared/ManageSharingSection';
import DetailSection from '../components/sections/home-grid/DetailSection';

//? ribbon btn divider
import RibbonSectionDivider from './ribbbonSectionDivider/RibbonSectionDivider';

// export ribbon component

export { EditMember, BrandImage };

export const FavoriteLayout = Favorite;
export const ViewLayout = View;
export const FolderLayout = Folder;
export const SearchLayout = Search;
export const GridLayout = Grid;
export const OtherLayout = Other;

export const AdditionControl = Addition;
export const AddFolderControl = AddFolder;
export const DetailControl = Detail;
export const QuickViewControl = QuickView;
export const ViewLayoutControl = ViewAlt;
export const SearchFolderControl = SearchFolder;
export const DeleteFolderControl = DeleteFolder;
export const SearchControl = SearchAlt;
export const BackControl = BackAlt;
export const AdvanceSearchControl = AdvanceSearch;
export const GridColumnsControl = GridColumns;
export const SaveGridControl = SaveGrid;
export const AddCuratedQueryControl = AddCuratedQuery;
export const ManageQuery = ManageGridQuery;

export const OpenAssetControl = OpenAsset;

export {
  GeneratePreviewModal,
  GeneratePreviewItem,
  GenerateConfirmModal,
  ManageShareModal,
  ManageShareModalToolbar,
  ToggleHelpUploadMediaSection,
  ToggleEulaUploadMediaSection,
  ToggleTagLineUploadMediaSection,
  SavedConfigurations,
  AdvanceFilter,
  ClearQuery,
  AdvanceStack,
  PimActions,
  GridConfig,
  RibbonSectionDivider,
  ManageSharingSection,
  DetailSection,
};
