import React, { useMemo, useEffect } from 'react';

import { Form } from 'common/components';

import {
  findProductModule,
  findDataProperties,
  formatObjectToCheckValueDatetime,
  RenderNewFormItem,
  useGetProductItemModules,
  RenderFormItem,
  isLeftModule,
  NewFormItemContainer,
  // filterModuleProductSchema,
  // filterModuleBrickCode,
} from 'pages/branded-products/components';

import { FormContextProvider } from 'pages/product-full-view/components/product-detail-view/FormContext';

import { pickBy, identity } from 'lodash';

import './InstantFixForm.less';

const layoutForm = {
  labelCol: { span: 11 },
  wrapperCol: { span: 11 },
};
//todo - add product brick code if needed
const InstantFixInput = (props) => {
  const {
    productSchema,
    productEnums,
    errorInfo,
    productId,
    // productBrickCode,
    productItemModules,
    formName,
  } = props;

  const editableModuleName = errorInfo?.moduleName;

  const [formInstance] = Form.useForm();

  // const { leftProductSchema, rightProductSchema } = useMemo(
  //   () => filterModuleProductSchema(productSchema),
  //   [productSchema]
  // );

  // const brickCodeSchema = useMemo(
  //   () => filterModuleBrickCode(rightProductSchema, modulesBrickCode),
  //   [modulesBrickCode, rightProductSchema]
  // );

  // const mixedProductSchema = useMemo(
  //   () => [...(leftProductSchema || []), ...(brickCodeSchema || [])],
  //   [leftProductSchema, brickCodeSchema]
  // );

  const { nonGroupModule } = useMemo(() => {
    return findProductModule(editableModuleName, productSchema);
  }, [editableModuleName, productSchema]);

  const isPrivateProperties = isLeftModule(nonGroupModule?.moduleName);

  const foundInputFromModule = (
    nonGroupModule?.moduleProperties?.length > 0
      ? nonGroupModule?.moduleProperties
      : []
  ).find((property) => property?.propertyName === errorInfo?.fieldName);

  const foundInputFromGroupModule = useMemo(() => {
    if (!isPrivateProperties) return null;

    let result;

    // eslint-disable-next-line no-unused-expressions
    Object.entries(nonGroupModule?.moduleProperties)?.find(
      ([title, modPropers]) =>
        modPropers.find((property) => {
          if (property?.propertyName === errorInfo?.fieldName) {
            result = { title, property: property || {} };
            return true;
          }
        })
    );

    return result;
  }, [isPrivateProperties, errorInfo, nonGroupModule]);

  const foundDataProperties = useMemo(() => {
    if (!productItemModules) return [];

    return findDataProperties(editableModuleName, productItemModules);
  }, [editableModuleName, productItemModules]);

  useEffect(() => {
    if (editableModuleName) {
      const newFormatData =
        formatObjectToCheckValueDatetime(foundDataProperties) || {};

      let formData = pickBy(newFormatData, identity);

      const customFormData = {
        ...formData,
        productId: productId,
        fieldName: errorInfo?.fieldName,
        moduleName: errorInfo?.moduleName,
      };

      formInstance.setFieldsValue(customFormData);
    }
  }, [
    editableModuleName,
    formInstance,
    foundDataProperties,
    productId,
    errorInfo,
  ]);

  const isModuleFound = nonGroupModule?.moduleProperties?.length > 0;

  return (
    <FormContextProvider>
      <Form
        {...layoutForm}
        form={formInstance}
        className='publication-instant-fix-input'
        id='form-group'
        name={formName}
      >
        {isModuleFound && (
          <>
            {isPrivateProperties ? (
              <>
                <NewFormItemContainer
                  title={foundInputFromGroupModule?.title}
                  numberOfFields={1}
                >
                  <RenderNewFormItem
                    property={foundInputFromGroupModule?.property}
                    productEnums={productEnums}
                  />
                </NewFormItemContainer>
              </>
            ) : (
              <RenderFormItem
                property={foundInputFromModule}
                productEnums={productEnums}
              />
            )}
          </>
        )}

        <Form.Item name='productId' hidden></Form.Item>
        <Form.Item name='moduleName' hidden></Form.Item>
        <Form.Item name='fieldName' hidden></Form.Item>
      </Form>
    </FormContextProvider>
  );
};

export default InstantFixInput;
