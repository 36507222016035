import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContactsOutlined,
  MailOutlined,
  MessageOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/home';

import RibbonButton from 'common/components/button/RibbonButton';

import * as globalAtions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';

import userSelectors from 'redux/user/selectors';

import * as chatActions from 'common/components/message/controller/actions';
import * as emailActions from 'common/components/mail/controller/actions';

import * as chatServices from 'services/chatServices';
import { CHATTYPE } from 'static/Constants';

import { SECURE_CONFIG } from 'utils/SecureRoute';
import { useCheckPermissionOR } from 'hooks/useCheckPermissions';

const ActionsRibbonBar = (props) => {
  const { view, selectedItemList } = props;

  const dispatch = useDispatch();

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const contactCardInfo = useSelector(
    userSelectors.makeSelectContactCardInfo()
  );

  const hubConnection = useSelector(globalSelectors.selectSignalRConnection());

  const isChatViewOpen = useSelector(globalSelectors.selectIsChatViewOpen());
  const isEmailViewOpen = useSelector(globalSelectors.selectIsEmailViewOpen());

  const checkPermission = useCheckPermissionOR();
  const canChat = checkPermission([SECURE_CONFIG.CHAT_PERSONAL.PASS]);

  const isUser = view === 'user';
  const viewedUserCompany = contactCardInfo?.memberId;
  const loggedUserCompany = userInfo?.member?.id;
  const isUserSameCompany = viewedUserCompany === loggedUserCompany;

  const shouldShowContactCard = isUser && isUserSameCompany;

  const getTooltipProps = () => {
    const tooltipProps = {
      title: <FormattedMessage {...Messages.contactControlTooltip} />,
      mouseEnterDelay: 0,
      mouseLeaveDelay: 0,
    };

    if (!shouldShowContactCard) tooltipProps.visible = false;

    return tooltipProps;
  };

  const handleSendMessage = async (type, user) => {
    try {
      const paramsSubmit = {
        userChatType: CHATTYPE.USER,
        chatType: type === 'email' ? 'Email' : 'P2P',
        threadName:
          type === 'email'
            ? view === 'user'
              ? selectedItemList &&
                selectedItemList.length > 0 &&
                selectedItemList[0]?.fullName
              : selectedItemList &&
                selectedItemList.length > 0 &&
                selectedItemList[0]?.memberName
            : '',
        users:
          view === 'user'
            ? [
                selectedItemList &&
                  selectedItemList.length > 0 &&
                  selectedItemList[0]?.id,
              ]
            : [],
        companies:
          view === 'user'
            ? []
            : [
                selectedItemList &&
                  selectedItemList.length > 0 &&
                  selectedItemList[0]?.id,
              ],
      };
      if (!isChatViewOpen && type !== 'email') {
        dispatch(globalAtions.toggleChatView(true));
      }

      if (!isEmailViewOpen && type === 'email') {
        dispatch(globalAtions.toggleEmailView(true));
      }

      const response = await chatServices.initChatCommunication(paramsSubmit);
      if (response?.isSuccess) {
        if (hubConnection) {
          try {
            setTimeout(() => {
              if (type === 'email') {
                const params = {
                  threadId: response?.data?.threadId,
                  userId: `U-${userInfo?.id}`,
                };
                dispatch(emailActions.getEmailThreadInfo(params));
                dispatch(
                  emailActions.getEmailThreadInfoContent(
                    response?.data?.threadId
                  )
                );
              } else {
                dispatch(chatActions.setChatView('threadMsg'));
                dispatch(
                  chatActions.getThreadInfo(
                    response?.data?.threadId,
                    `U-${userInfo?.id}`
                  )
                );
                dispatch(
                  chatActions.getThreadMessages(response?.data?.threadId)
                );
                dispatch(
                  globalAtions.updateListAfterSendMessage({
                    threadId: response?.data?.threadId,
                    creatorId: `U-${userInfo?.id}`,
                  })
                );
              }
            }, 500);
          } catch (error) {}
        }
      }
    } catch (error) {}
  };

  //* render
  const disabledDropdown = !(
    (view === 'member' ||
      view === 'product-shared' ||
      (view === 'user' && contactCardInfo?.userId !== userInfo?.id)) &&
    selectedItemList?.length === 1
  );

  const menuOptions = (
    <Menu>
      {/* <Menu.Item key='contact' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<ContactsOutlined />}
          label={Messages.cardMemberFull}
          onClick={onButtonClickHandler}
          className='button-item-dropdown'
        />
      </Menu.Item> */}
      <Menu.Item key='message' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<MessageOutlined />}
          label={Messages.messageMemberFull}
          onClick={() => handleSendMessage()}
          className='button-item-dropdown'
          disabled={!canChat}
        />
      </Menu.Item>

      <Menu.Item key='email' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<MailOutlined />}
          label={Messages.emailMemberFull}
          onClick={() => handleSendMessage('email')}
          className='button-item-dropdown'
          disabled={!canChat}
        />
      </Menu.Item>
      <Menu.Item key='text' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<FileTextOutlined />}
          label={Messages.sendText}
          // onClick={() => setShowText(true)}
          className='button-item-dropdown'
          disabled
        />
      </Menu.Item>

      {/* <Menu.Item key='card' style={{ backgroundColor: 'inherit' }}>
        <ButtonSmallIcon
          icon={<ContactsOutlined />}
          label={Messages.cardMemberFull}
          onClick={() => setShowContact(true)}
          className='contact-config__dropdown--item'
        />
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menuOptions}
      arrow
      disabled={disabledDropdown || !canChat}
    >
      <Tooltip {...getTooltipProps()}>
        <RibbonButton
          icon={<ContactsOutlined />}
          label={Messages.contactControl}
          disabled={disabledDropdown || !canChat}
        />
      </Tooltip>
    </Dropdown>
  );
};

export default ActionsRibbonBar;
