import React from 'react';

import classnames from 'classnames';

import './styles.less';

const PageEditorInner = (props) => {
  const { isContainerFull, containerSize, isEdit, wrapId, children } = props;

  const innerContainerStyle = {
    width: isContainerFull ? '100%' : containerSize?.width,
    height: isContainerFull ? '100%' : containerSize?.height,
  };
  return (
    <div
      className={classnames('page-editor-inner', {})}
      style={innerContainerStyle}
    >
      {!isContainerFull && isEdit && (
        <div className='page-editor-inner__header'>
          {containerSize?.width} x {containerSize?.height}
        </div>
      )}
      <div
        id={wrapId}
        className={classnames('page-editor-inner__content', {
          'page-editor-inner__content--full': isContainerFull,
          'page-editor-inner__content--edit': isEdit,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default PageEditorInner;
