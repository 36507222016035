const getListCounts = (listItems, field) =>
  listItems.map((item) => item[field]);

const sum = (listItems, field) => {
  const listCounts = getListCounts(listItems, field);
  return listCounts.reduce((a, b) => a + b, 0);
};

const findListItems = (selectedIds, data) => {
  const result = selectedIds.map((id) =>
    data.find((element) => element.id === id)
  );
  return result;
};

const getResultCanAccess = (listItems, field) => {
  const listBooleanAccess = getListCounts(listItems, field);

  return listBooleanAccess.every((ele) => ele);
};

const filterColumnsGrid = (columns, fields) => {
  const checkboxColumn = columns[0] ?? {};
  const filteredColumns = columns.filter((c) =>
    fields.includes(c.fieldNameCamelCase)
  );
  const result = [checkboxColumn, ...filteredColumns];

  return result;
};

const checkIsNotShareableFolder = (items) => {
  return items.some((item) => item?.type === 'Folder' && !item?.shareable);
};

const checkIsMember = (items) => {
  return items.some((item) => item?.type === 'member');
};

const checkIsNotOwnedAsset = (items) => {
  return items.some((item) => item?.type === 'asset' && !item?.isOwner);
};

const checkItemNotHaveType = (items) => {
  return items.some((item) => !item?.type);
};

const checkDisabledShareButton = (items) => {
  // no item selected
  if (!items || items?.length === 0) return true;
  // item not has field "type"
  if (checkItemNotHaveType(items)) return true;
  // is member
  if (checkIsMember(items)) return true;
  // is not owned asset
  if (checkIsNotOwnedAsset(items)) return true;
  // is not sharable folder
  if (checkIsNotShareableFolder(items)) return true;

  return false;
};

export {
  sum,
  findListItems,
  getResultCanAccess,
  filterColumnsGrid,
  checkDisabledShareButton,
};
