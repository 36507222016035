import React from 'react';
import { Row, Col } from 'antd';
import { get } from 'lodash';
import { InfoWithLabel } from 'common/components';
import { formatMDY } from 'utils/formatDate';
import { setAssetType } from 'utils/fileType';

import { formatSizeUnits } from 'utils/formatSizeUnits';

const AssetPanelMetaInfo = (props) => {
  const { assetData } = props;
  const assetType = get(assetData, 'assetType', '');
  const dataType = setAssetType(assetType);

  function classificateData(data) {
    if (!data) return [];
    if (data === 'Document')
      return [
        {
          label: 'Owner',
          info:
            get(assetData, 'metadata.owner', '') &&
            get(assetData, 'metadata.owner', ''),
        },
        {
          label: 'File Size',
          info: formatSizeUnits(get(assetData, 'metadata.fileSize', '')),
        },
        {
          label: 'File Type',
          info:
            get(assetData, 'metadata.fileType', '') &&
            get(assetData, 'metadata.fileType', ''),
        },
        {
          label: 'Page Count',
          info:
            get(assetData, 'metadata.pageCount', '') &&
            get(assetData, 'metadata.pageCount', ''),
        },
        {
          label: 'Loaded Date',

          info:
            formatMDY(get(assetData, 'metadata.loadedDate', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.loadedDate', ''))
              : '',
        },
        {
          label: 'Date Modified',
          info:
            formatMDY(get(assetData, 'metadata.lastModified', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.lastModified', ''))
              : '',
        },
      ];
    if (data === 'Audio')
      return [
        {
          label: 'Owner',
          info:
            get(assetData, 'metadata.owner', '') &&
            get(assetData, 'metadata.owner', ''),
        },

        {
          label: 'File Size',
          info: formatSizeUnits(get(assetData, 'metadata.fileSize', '')),
        },
        {
          label: 'File Type',
          info:
            get(assetData, 'metadata.fileType', '') &&
            get(assetData, 'metadata.fileType', ''),
        },
        {
          label: 'Bit Rate',
          info:
            get(assetData, 'metadata.bitRate', '') &&
            get(assetData, 'metadata.bitRate', ''),
        },
        {
          label: 'Frame Rate',
          info:
            get(assetData, 'metadata.frameRate', '') &&
            get(assetData, 'metadata.frameRate', ''),
        },
        {
          label: 'Frame Size',
          info:
            get(assetData, 'metadata.frameSize', '') &&
            get(assetData, 'metadata.frameSize', ''),
        },
        {
          label: 'Sample Rate',
          info:
            get(assetData, 'metadata.sampleRate', '') &&
            get(assetData, 'metadata.sampleRate', ''),
        },
        {
          label: 'Chanel Output',
          info:
            get(assetData, 'metadata.channelOutput', '') &&
            get(assetData, 'metadata.channelOutput', ''),
        },
        {
          label: 'Media Format',
          info:
            get(assetData, 'metadata.mediaFormat', '') &&
            get(assetData, 'metadata.mediaFormat', ''),
        },
        {
          label: 'Duration',
          info:
            get(assetData, 'metadata.duration', '') &&
            get(assetData, 'metadata.duration', ''),
        },
        {
          label: 'Loaded Date',
          info:
            formatMDY(get(assetData, 'metadata.loadedDate', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.loadedDate', ''))
              : '',
        },
        {
          label: 'Date Modified',
          info:
            formatMDY(get(assetData, 'metadata.lastModified', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.lastModified', ''))
              : '',
        },
      ];
    if (data === 'ZipRar')
      return [
        {
          label: 'Owner',
          info:
            get(assetData, 'metadata.owner', '') &&
            get(assetData, 'metadata.owner', ''),
        },
        {
          label: 'File Size',
          info: formatSizeUnits(get(assetData, 'metadata.fileSize', '')),
        },
        {
          label: 'File Type',
          info:
            get(assetData, 'metadata.fileType', '') &&
            get(assetData, 'metadata.fileType', ''),
        },
        {
          label: 'Loaded Date',
          info:
            formatMDY(get(assetData, 'metadata.loadedDate', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.loadedDate', ''))
              : '',
        },
        {
          label: 'Date Modified',
          info:
            formatMDY(get(assetData, 'metadata.lastModified', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.lastModified', ''))
              : '',
        },
      ];
    if (data === 'Email')
      return [
        {
          label: 'Owner',
          info:
            get(assetData, 'metadata.owner', '') &&
            get(assetData, 'metadata.owner', ''),
        },
        {
          label: 'File Size',
          info: formatSizeUnits(get(assetData, 'metadata.fileSize', '')),
        },
        {
          label: 'File Type',
          info:
            get(assetData, 'metadata.fileType', '') &&
            get(assetData, 'metadata.fileType', ''),
        },
        {
          label: 'Loaded Date',
          info:
            formatMDY(get(assetData, 'metadata.loadedDate', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.loadedDate', ''))
              : '',
        },
        {
          label: 'Date Modified',
          info:
            formatMDY(get(assetData, 'metadata.lastModified', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.lastModified', ''))
              : '',
        },
      ];
    if (data === 'Video')
      return [
        {
          label: 'Owner',
          info:
            get(assetData, 'metadata.owner', '') &&
            get(assetData, 'metadata.owner', ''),
        },

        {
          label: 'File Size',
          info: formatSizeUnits(get(assetData, 'metadata.fileSize', '')),
        },
        {
          label: 'File Type',
          info:
            get(assetData, 'metadata.fileType', '') &&
            get(assetData, 'metadata.fileType', ''),
        },
        {
          label: 'Bit Rate',
          info:
            get(assetData, 'metadata.bitRate', '') &&
            get(assetData, 'metadata.bitRate', ''),
        },
        {
          label: 'Frame Rate',
          info:
            get(assetData, 'metadata.frameRate', '') &&
            get(assetData, 'metadata.frameRate', ''),
        },
        {
          label: 'Color Model',
          info:
            get(assetData, 'metadata.colorModel', '') &&
            get(assetData, 'metadata.colorModel', ''),
        },
        {
          label: 'Frame Size',
          info:
            get(assetData, 'metadata.frameSize', '') &&
            get(assetData, 'metadata.frameSize', ''),
        },
        {
          label: 'Fps',
          info:
            get(assetData, 'metadata.fps', '') &&
            get(assetData, 'metadata.fps', ''),
        },
        {
          label: 'Media Format',
          info:
            get(assetData, 'metadata.mediaFormat', '') &&
            get(assetData, 'metadata.mediaFormat', ''),
        },
        {
          label: 'Duration',
          info:
            get(assetData, 'metadata.duration', '') &&
            get(assetData, 'metadata.duration', ''),
        },
        {
          label: 'Loaded Date',
          info:
            formatMDY(get(assetData, 'metadata.loadedDate', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.loadedDate', ''))
              : '',
        },
        {
          label: 'Date Modified',
          info:
            formatMDY(get(assetData, 'metadata.lastModified', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.lastModified', ''))
              : '',
        },
      ];
    if (data === 'Image')
      return [
        {
          label: 'Owner',
          info:
            get(assetData, 'metadata.owner', '') &&
            get(assetData, 'metadata.owner', ''),
        },
        {
          label: 'File Size',
          info: formatSizeUnits(get(assetData, 'metadata.fileSize', '')),
        },
        {
          label: 'File Type',
          info:
            get(assetData, 'metadata.fileType', '') &&
            get(assetData, 'metadata.fileType', ''),
        },
        {
          label: 'Width',
          info:
            get(assetData, 'metadata.width', '') &&
            get(assetData, 'metadata.width', '') + ' px',
        },
        {
          label: 'Height',
          info:
            get(assetData, 'metadata.height', '') &&
            get(assetData, 'metadata.height', '') + ' px',
        },
        // {
        //   label: 'Crop Range',
        //   info:
        //     get(assetData, 'metadata.cropRange', '') &&
        //     get(assetData, 'metadata.cropRange', '') + ' px',
        // },
        {
          label: 'Clipping Path',
          info:
            get(assetData, 'metadata.clippingPath', '').toString() === 'true'
              ? 'YES'
              : 'NO',
        },
        {
          label: 'Color Depth',
          info:
            get(assetData, 'metadata.colorDepth', '') &&
            get(assetData, 'metadata.colorDepth', '') + ' bit',
        },
        {
          label: 'Resolution',
          info:
            get(assetData, 'metadata.resolution', '') &&
            get(assetData, 'metadata.resolution', '') + ' DPI',
        },
        {
          label: 'Page Count',
          info:
            get(assetData, 'metadata.pageCount', '') &&
            get(assetData, 'metadata.pageCount', ''),
        },
        {
          label: 'Loaded Date',
          info:
            formatMDY(get(assetData, 'metadata.loadedDate', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.loadedDate', ''))
              : '',
        },
        {
          label: 'Date Modified',
          info:
            formatMDY(get(assetData, 'metadata.lastModified', '')) !==
            'Invalid Date'
              ? formatMDY(get(assetData, 'metadata.lastModified', ''))
              : '',
        },
      ];
  }

  const dataInfo = classificateData(dataType);

  const dataLeft = dataInfo
    ? dataInfo.slice(0, Math.ceil(dataInfo.length / 2))
    : [];
  const dataRight = dataInfo
    ? dataInfo.slice(Math.ceil(dataInfo.length / 2), dataInfo.length)
    : [];

  const infoColProps = {
    infoColProps: {
      flex: 'auto',
    },
    labelColProps: {
      flex: '100px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  return (
    <>
      {dataInfo.length > 0 && (
        <>
          <Row className='asset-panel-meta'>
            <Col span={12}>
              {dataLeft &&
                dataLeft.length > 0 &&
                dataLeft.map((item, index) => {
                  return (
                    <InfoWithLabel
                      key={index}
                      info={item.info}
                      label={item.label}
                      {...infoColProps}
                    />
                  );
                })}
            </Col>
            <Col span={12}>
              {dataRight &&
                dataRight.length > 0 &&
                dataRight.map((item, index) => {
                  return (
                    <InfoWithLabel
                      key={index}
                      info={item.info}
                      label={item.label}
                      {...infoColProps}
                    />
                  );
                })}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AssetPanelMetaInfo;
