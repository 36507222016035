import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { ThumbnailItem } from 'common/components';
import EditLogoModal from '../modal-edit-logo/EditLogoModal';
import noImage from 'assets/product-no-image.jpg';

import './ProfileImage.less';

const ProfileImage = (props) => {
  const {
    src,
    title,
    responsive,
    enableCustom,
    className,
    isEditMode,
    loading,
    uploadBtnProps,
    style,
    isDefaultSrc = false,
    onSubmit,
    getLogoAssetsService,
    logoAssetsUniqueId,
    editLogoTitle,
    getLogoAssetResponseParams,
  } = props;

  const [editLogoModalVisible, setEditLogoModalVisible] = useState(false);

  const onClickUploadBtn = () => {
    showUploadModal();
  };

  const showUploadModal = () => {
    setEditLogoModalVisible(true);
  };

  //* render
  const isShowEditButton = isEditMode && !loading;
  const UploadBtn = Button;

  return (
    <div className='profile-image__container' style={{ ...style }}>
      <ThumbnailItem
        labelThumbnail={title}
        imageThumbnail={src || (isDefaultSrc && noImage) || ''}
        dataDetail={null}
        hideCheckbox
        hideLabel
        responsive={responsive}
        enableCustom={enableCustom}
        className={className}
        isEditMode={isEditMode}
        loading={loading}
      />
      {isShowEditButton ? (
        <UploadBtn
          className='profile-image__upload-btn'
          shape='circle'
          icon={<CameraOutlined />}
          size='small'
          onClick={onClickUploadBtn}
          {...uploadBtnProps}
        />
      ) : null}
      {onSubmit ? (
        <EditLogoModal
          title={editLogoTitle}
          visible={editLogoModalVisible}
          setVisible={setEditLogoModalVisible}
          onSubmit={onSubmit}
          getLogoAssetsService={getLogoAssetsService}
          logoAssetsUniqueId={logoAssetsUniqueId}
          getLogoAssetResponseParams={getLogoAssetResponseParams}
        />
      ) : null}
    </div>
  );
};

ProfileImage.propsTypes = {
  /**
   * Profile Image Source
   */
  src: PropTypes.string.isRequired,
  /**
   * Profile Image Alt
   */
  alt: PropTypes.string,
  /**
   * Size of Profile Image
   */
  size: PropTypes.number,
  /**
   * Edit mode
   */
  isEditMode: PropTypes.bool,
  /**
   * upload image handler
   */
  onUploadHandler: PropTypes.func,
  /**
   * upload image loading
   */
  loading: PropTypes.bool,
};

ProfileImage.defaultProps = {
  size: 150,
  className: 'profile-image',
  alt: 'Profile Image',
};

export default ProfileImage;
