import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import * as productServices from 'services/product';

export const useGetProductSchema = () => {
  const queries = useQuery({
    queryKey: ['product-detail/schema'],
    queryFn: async () => {
      const response = await productServices.getProductItemDetailSchema();
      return response?.data;
    },
    staleTime: Infinity,
  });

  return {
    productSchema: useMemo(() => queries?.data?.modules, [queries?.data]) ?? [],
    ...queries,
  };
};
