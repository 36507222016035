import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import { RibbonBar, RibbonDivider } from 'common/components';
import MaintenanceInfoSection from './components/sections/member-maintenance-grid/MaintenanceInfoSection';
import MemberCommunicateSection from './components/sections/member-maintenance-grid/MemberCommunicateSection';
import MemberEditSection from './components/sections/member-maintenance-grid/MemberEditSection';
import MemberExportSection from './components/sections/member-maintenance-grid/MemberExportSection';
import UserActionsSection from './components/sections/user-maintenance-grid/UserActionsSection';
import MemberTemplateSection from './components/sections/member-maintenance-grid/MemberTemplateSection';
import OpenMemberSection from './components/sections/member-maintenance-grid/OpenMemberSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import MemberEditRoleSecuritySection from './components/sections/member-maintenance-grid/MemberEditRoleSecuritySection';
// import OpenTicketGroupMember from './components/sections/member-maintenance-grid/OpenTicketGroupMember';
import MemberActions from './components/sections/member-maintenance-grid/member-action';
import UserReactive from './components/controls/maintenance/user/UserReactive';
import { ViewLayout, AdvanceStack } from './components';

import * as memberMaintenanceSelectors from 'pages/member-maintenance/controllers/selectors';

import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const COMMUNICATION_PERMISSION = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.COMMUNICATION_TEMPLATE,
  },
  {
    action: ABILITY_ACTION.MAINTAIN,
    subject: ABILITY_SUBJECT.COMMUNICATION_TEMPLATE,
  },
];

const SUPER_ADMIN_PERMISSION = [
  { action: ABILITY_ACTION.MANAGE, subject: ABILITY_SUBJECT.ALL },
];

const MemberMaintenanceGridView = () => {
  const isMemberGridInfoView = useSelector(
    memberMaintenanceSelectors.selectIsMemberGridInfoView()
  );

  const hasPermissionCommunication = useCheckPermissions(
    COMMUNICATION_PERMISSION
  );

  const hasSuperAdminPermission = useCheckPermissions(SUPER_ADMIN_PERMISSION);

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <MaintenanceInfoSection />
      <RibbonDivider />
      <MemberTemplateSection />
      <RibbonDivider />
      <MemberExportSection />
      <RibbonDivider />
      {hasPermissionCommunication && (
        <>
          <MemberCommunicateSection />
          <RibbonDivider />
        </>
      )}
      {hasSuperAdminPermission && isMemberGridInfoView && (
        <>
          <MemberEditRoleSecuritySection />
          <RibbonDivider />
          <MemberActions />
          <RibbonDivider />
          {/* <OpenTicketGroupMember />
          <RibbonDivider /> */}
        </>
      )}

      {isMemberGridInfoView && (
        <>
          <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.MANAGE_MEMBER}>
            <OpenMemberSection />
            <Divider
              type='vertical'
              style={{ height: 40, margin: '4px 8px' }}
            />
          </Can>
          <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.MEMBER}>
            <MemberEditSection />
          </Can>
        </>
      )}
      <UserReactive />
      {!isMemberGridInfoView ? <UserActionsSection /> : null}
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default MemberMaintenanceGridView;
