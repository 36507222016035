import { useState, useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import toInteger from 'lodash/toInteger';

import { CustomNotification, dialogFunction } from 'common/components';

import {
  getHierarchyIngredientData,
  getIngredientPercentTotal,
  transformValueIngredientsPartOf,
  validatedRowData,
} from 'pages/qa-spec/utils/ingredient';

import * as productServices from 'services/product/index';

import { getAllRows } from 'pages/qa-spec/utils';
import {
  validateRequiredFieldForSupplier,
  validateIngredientGrid,
} from 'pages/qa-spec/utils/ingredient';

import { INGREDIENT_LIST_ERRORS } from 'pages/qa-spec/constant';

import { createIngredientColDef } from 'pages/qa-spec/utils/gridColumnUtils';

import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import { useGetQaSpecRequiredFieldsInQaSpecFullView } from 'pages/qa-spec/hooks/useGetQaSpecRequiredFields';

import { useAsync } from 'hooks';
import { sleep } from 'utils/delay';
import { useCheckSnapshotForRetailer, useSelectProductFullDetailData } from '.';
import { errorRequiredFieldsForSupplier } from '../components/qa-ingredient/utils';

export const useGetIngredientPartSelection = (data) => {
  const ingredientSelections = useMemo(() => {
    return data
      .filter(
        (ingredientItem) =>
          ingredientItem?.nameOfIngredients &&
          ingredientItem?.IngredientsPartOf === null
      )
      .map((ingredientData) => ingredientData?.nameOfIngredients);
  }, [data]);

  return ingredientSelections;
};

export const useProcessIngredientData = (data) => {
  const processedData = useMemo(() => {
    return getHierarchyIngredientData(data);
  }, [data]);

  return processedData;
};

export const useGetIngredientGridHandlers = (gridInst, setTotal, productId) => {
  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedByRetailer = checkAssignProductToSupplier();

  const { data: requiredFields } = useGetQaSpecRequiredFieldsInQaSpecFullView({
    productId,
  });

  const [gridRequiredFields, setGridRequiredFields] = useState([]);

  const checkFieldRequired = (fieldName) => {
    if (!requiredFields) return false;
    if (!isProductAssignedByRetailer) return false;

    return requiredFields.includes(
      `qaSpecification.qaSpecIngredients.${fieldName}`
    );
  };

  const getRequiredFieldsMessage = () => {
    if (!gridInst.current) return;

    const columns = gridInst.current.columnApi.columnModel.columnDefs;

    const requiredFieldsMessages = columns
      .filter((colItem) => checkFieldRequired(colItem.field))
      .map((colItem) => `${colItem.displayName} is required`);

    setGridRequiredFields(requiredFieldsMessages);

    handleValidateRequiredFieldForSupplierAllData(gridInst);

    return requiredFieldsMessages;
  };

  const handleAddChildIngredient = async () => {
    const newId = uuidv4();

    gridInst.current.api.stopEditing();
    await sleep();

    const selectedRows = gridInst.current.api.getSelectedNodes();

    const selectedRowIdx = toInteger(selectedRows?.[0]?.rowIndex);
    const selectedRowData = selectedRows?.[0]?.data;

    const selectedRowIngredientName = selectedRowData?.nameOfIngredients;

    if (selectedRows?.length === 0) {
      CustomNotification.error(
        'Please select an ingredient to add new sub-ingredient'
      );
      return;
    }

    if (
      !selectedRowIngredientName ||
      selectedRowIngredientName.trim()?.length === 0
    ) {
      CustomNotification.error(
        'Please input ingredient name for selected ingredient before adding new sub-ingredient'
      );
      return;
    }

    const allRowData = getAllRows(gridInst);

    // get index of same level child. Exact to a function
    let nextLevel = { level: 1, rowIdx: selectedRowIdx };
    let getChildItem;
    allRowData.forEach((rowData, idx) => {
      if (
        rowData?.ingredientsPartOf ===
        transformValueIngredientsPartOf(selectedRowData)
      ) {
        nextLevel = { level: rowData?.level + 1, rowIdx: idx };
        getChildItem = rowData;
      }
      // get rowIdx when level child has child
      if (
        getChildItem &&
        rowData?.ingredientsPartOf ===
          transformValueIngredientsPartOf(getChildItem)
      ) {
        nextLevel.rowIdx = idx;
      }
    });

    const newRowIndex = nextLevel?.rowIdx + 1;

    gridInst.current.api.applyTransaction({
      add: [
        {
          id: newId,
          isParent: false,
          isChild: true,
          nameOfIngredients: null,
          ingredientsPartOf: transformValueIngredientsPartOf(selectedRowData),
          ingredientsPartOfId: selectedRowData?.id ?? null,
          ingredientsFunction: null,
          ingredientsOrigin: null,
          ingredientsCountryOfOrigin: null,
          religiousClaim: null,
          gmo: null,
          percentAddedOnProduction: null,
          percentInFinalProduct: null,
          declare: null,
          total: null,
          organicOrBio: null,
          level: nextLevel?.level,
          levelIdx: `${selectedRowData?.levelIdx}.${nextLevel?.level}`,
          error: isProductAssignedByRetailer
            ? getRequiredFieldsMessage()
            : [INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EMPTY],
        },
      ],
      addIndex: newRowIndex,
    });

    gridInst.current.api.ensureIndexVisible(newRowIndex);
    const newRow = gridInst.current.api.getRowNode(newId);
    newRow.setSelected(true, true);
  };

  const handleAddIngredient = async () => {
    const newId = uuidv4();

    gridInst.current.api.stopEditing();
    await sleep();

    const allRowData = getAllRows(gridInst);

    const lastTopIngredient = allRowData
      .reverse()
      .find((rowDataItem) => !rowDataItem?.ingredientsPartOf);

    const newRowIndex = allRowData?.length;

    gridInst.current.api.applyTransaction({
      add: [
        {
          id: newId,
          isParent: true,
          isChild: false,
          nameOfIngredients: null,
          ingredientsPartOf: null,
          ingredientsPartOfId: null,
          ingredientsFunction: null,
          ingredientsOrigin: null,
          ingredientsCountryOfOrigin: null,
          religiousClaim: null,
          gmo: null,
          percentAddedOnProduction: null,
          percentInFinalProduct: null,
          declare: null,
          total: null,
          ingredientStatement: null,
          organicOrBio: null,
          ingredientsProductClaims: null,
          level: 1,
          levelIdx: `${(toInteger(lastTopIngredient?.levelIdx) || 0) + 1}`,
          // error: [INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EMPTY],
          error: isProductAssignedByRetailer
            ? getRequiredFieldsMessage()
            : [INGREDIENT_LIST_ERRORS.INGREDIENT_NAME_EMPTY],
        },
      ],
      addIndex: newRowIndex,
    });

    gridInst.current.api.ensureIndexVisible(newRowIndex);
    const newRow = gridInst.current.api.getRowNode(newId);
    newRow.setSelected(true, true);
  };

  const onDeleteIngredient = async () => {
    const selectedRows = gridInst.current.api.getSelectedNodes();

    const removeList = selectedRows.map(
      (selectedRowItem) => selectedRowItem?.data
    );

    gridInst.current.api.applyTransaction({
      remove: removeList,
    });

    const allRowData = getAllRows(gridInst);
    const nextData = getHierarchyIngredientData(allRowData, false);
    gridInst.current.api.setRowData(nextData);
    await sleep(500);
    validateIngredientGrid(gridInst).validateSameIngredientName();

    const totalPercent = getIngredientPercentTotal(allRowData);
    setTotal(totalPercent);
  };

  const confirmDeleteIngredient = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to delete the selected item?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: onDeleteIngredient,
    });
  };

  const handleDeleteIngredient = () => {
    confirmDeleteIngredient();
  };

  const handleValidateRequiredFieldForSupplier = ({ gridInst, event }) => {
    if (!requiredFields) return;

    const colId = event.column.colId;
    const displayName = event.column?.colDef?.displayName;
    const isRequired = checkFieldRequired(colId);

    const requiredFieldMessage = `${
      displayName ? displayName : colId
    } is required`;

    isRequired &&
      validateRequiredFieldForSupplier({
        gridInst,
        event,
        requiredFieldError: requiredFieldMessage,
      });
  };

  const onCellEditingStopped = (event) => {
    const colId = event.column.colId;

    let rowData = event.data;
    const allRowData = getAllRows(gridInst);

    let nextData = [...allRowData];

    if (colId === 'nameOfIngredients') {
      if (event.oldValue === event.value) return;

      validateIngredientGrid(gridInst, rowData).validateIngredientNameEmpty({
        event,
        isProductAssignedByRetailer,
      });
      validateIngredientGrid(gridInst).validateSameIngredientName();
    }

    if (colId === 'ingredientsPartOf') {
      if (event.oldValue === event.value) {
        return;
      }

      nextData = validatedRowData({
        rowData: nextData,
        changedRow: rowData,
        changedValue: event.value,
      });

      nextData = getHierarchyIngredientData(nextData, false);
      gridInst.current.api.setRowData(nextData);

      validateIngredientGrid(gridInst).validateSameIngredientName();

      return;
    }

    if (colId === 'percentInFinalProduct') {
      const totalPercent = getIngredientPercentTotal(allRowData);
      setTotal(totalPercent);
    }

    isProductAssignedByRetailer &&
      handleValidateRequiredFieldForSupplier({
        gridInst,
        event,
      });
  };

  const handleValidateRequiredFieldForSupplierAllData = (gridInst) => {
    if (!gridInst.current) return;

    const gridColumns = gridInst?.current?.columnApi?.columnModel?.gridColumns;

    const allRowData = getAllRows(gridInst);
    allRowData.forEach((row) => {
      gridColumns.forEach((column) => {
        const field = column?.colDef?.field;
        if (!field) return;

        isProductAssignedByRetailer &&
          handleValidateRequiredFieldForSupplier({
            gridInst,
            event: {
              column,
              value: row[field],
              data: { id: row?.id },
            },
          });
      });
    });
  };

  useEffect(() => {
    //* validate required fields when grid first render, after change product type
    handleValidateRequiredFieldForSupplierAllData(gridInst);
  }, [JSON.stringify(gridRequiredFields)]);

  return {
    gridRequiredFields,
    getRequiredFieldsMessage,
    onCellEditingStopped,
    handleAddChildIngredient,
    handleAddIngredient,
    handleDeleteIngredient,
    handleValidateRequiredFieldForSupplierAllData,
  };
};

export const useGetIngredientColDefs = (
  isEdit,
  ingredientOptions,
  productId
) => {
  const isToggleSnapshot = useCheckSnapshotForRetailer();
  const productDetail = useSelectProductFullDetailData({ productId });

  const ingredientColDef = useMemo(() => {
    const { productId, ownerId: productOwnerId } = productDetail;
    return createIngredientColDef(
      isEdit,
      ingredientOptions,
      productOwnerId,
      productId,
      isToggleSnapshot
    );
  }, [isEdit, ingredientOptions, productDetail, isToggleSnapshot]);

  return ingredientColDef;
};

export const useGetIngredientData = () => {
  const { data, setData, run } = useAsync();
  const { id: productId } = useParams();

  const fetchIngredientData = useCallback(
    (productId) => {
      run(productServices.getQaProductIngredient({ productId }));
    },
    [run]
  );

  useEffect(() => {
    if (productId) {
      fetchIngredientData(productId);
    }
    return;
  }, [productId, run, fetchIngredientData]);

  return { data, setData, fetchIngredientData };
};
