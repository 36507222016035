import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import NewTaskControl from '../../controls/wf-template-full/NewTaskControl';
import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import * as wfTemplateActions from 'pages/workflow-template-full/controllers/actions';

const NewTaskSection = (props) => {
  const { stepId } = props;
  const dispatch = useDispatch();
  const onAddNewTask = () => {
    dispatch(wfTemplateActions.toggleAddNewTask(true, stepId));
  };

  return <NewTaskControl onClick={onAddNewTask} />;
};

export default NewTaskSection;
