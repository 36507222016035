import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getKeyQueryQaSpecWorkflowTodoList } from 'pages/qa-spec-workflow/hook';

export const useRefetchQaSpecData = () => {
  const queryClient = useQueryClient();
  const { id: productId } = useParams();

  const refetchQaSpecData = () => {
    if (!productId) return null;

    queryClient.invalidateQueries({
      queryKey: ['qa-spec', parseInt(productId)],
    });

    queryClient.invalidateQueries({
      queryKey: ['pim', 'snapshot', parseInt(productId)],
    });

    queryClient.invalidateQueries({
      queryKey: getKeyQueryQaSpecWorkflowTodoList(),
    });
  };

  return refetchQaSpecData;
};
