import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { forwardTo } from 'utils/common/route';
import * as STATUS_CODE from 'static/StatusCodes';
import FakeData from './FakeData';
import * as action from '../redux/global/actions';
import { getData } from 'utils/storage';
import {
  setSession,
  getToken,
  getExpiredDate,
  getAnonToken,
} from 'utils/common/session';
import { getFileName } from 'utils/processReponse';
import * as endpoints from 'services/login/endpoints';
import { sleep } from 'utils/delay';
import produce from 'immer';

var store;
var isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
/**
 * An apply function to setup defaults axios configuration
 * @param {object} defaultObj axios defaults setup. See https://github.com/axios/axios#request-config
 */
export const applyApiDefaults = (defaultObj, storeParams) => {
  _.merge(axios.defaults, defaultObj);
  store = storeParams;
};

// when need cancel request, use this source
export const source = axios.CancelToken.source();

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // TODO: apply logger or any prior action
    let token = getToken();
    if (!token) return config;
    config.headers['Authorization'] = 'bearer ' + getToken();

    // add cancel token
    config.cancelToken = source.token;
    return config;
  },
  function (error) {
    // TODO: apply error logger
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error?.config;

    const anonKey = getAnonToken();

    if (error?.response?.status === STATUS_CODE.BAD_REQUEST) {
      return Promise.resolve(error.response);
    }

    if (
      !isRefreshing &&
      error?.response?.status === 401 &&
      //* allow to pass if anon mode is off
      !anonKey &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      let refreshToken = localStorage.getItem('RefreshToken');
      return new Promise((resolve, reject) => {
        axios
          .get(endpoints.GET_REFRESH_TOKEN, {
            params: { token: refreshToken },
          })
          .then(({ data }) => {
            if (data.isSuccess) {
              const expires = parseInt(data?.data?.expires_in);
              setSession(
                data?.data?.access_token,
                expires,
                data?.data?.refresh_token
              );
              processQueue(null, data?.data?.refresh_token);
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + data?.data?.refresh_token;
              originalRequest.headers['Authorization'] =
                'Bearer ' + data?.data?.refresh_token;
              resolve(axios(originalRequest));
            } else {
              const mfaLoginInfo = store.getState()?.user?.loginOtpVerify;

              if (!mfaLoginInfo) {
                forwardTo('/login');
              }
              resolve(data);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    if (error.code === 'ECONNABORTED') {
      // if timeout, redirect to timeout
      // forwardTo('/timeout');
      const event = new Event('requestTimeout');
      document.dispatchEvent(event);
      const timeoutError = {
        timeout: true,
      };
      return Promise.reject(timeoutError);
    }
    const status = error?.response?.status;
    if (status === STATUS_CODE.NOT_FOUND) {
      // redirect to notfound
      // forwardTo('/notfound');
    }
    if (status === STATUS_CODE.INTERNAL_SERVER_ERROR) {
      // store &&
      //   store.dispatch(
      //     action.updateErrorMessageInternalSever(
      //       error?.response?.data?.title,
      //       error?.response?.data?.detail
      //     )
      //   );
      // redirect to notfound
      // forwardTo(`/500-error`);
    }
    const user = getData('User');
    let logging = {
      type: 'axios',
      date: new Date(),
      email: user ? user?.email : '',
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      errorMessage: error?.response?.data,
    };

    return Promise.reject(error.response);
  }
);

export const fakeResponse = (data, handleFakeReponse) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(handleFakeReponse ? handleFakeReponse(data) : data);
    }, 900);
  });
};

// GET request
export const sendGet = (api, options, dataOnly = true, handleFakeReponse) => {
  //

  if (api !== '/api/Product' && api !== '/api/MemberProfile/header') {
    if (FakeData[api])
      return !handleFakeReponse
        ? fakeResponse(FakeData[api])
        : fakeResponse(FakeData[api], handleFakeReponse);
  }

  return new Promise((resolve, reject) => {
    axios
      .get(api, options)
      .then((response) => {
        if (dataOnly) resolve(response.data);
        else resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// POST request
export const sendPost = (api, payload, options = {}, dataOnly = true) => {
  //
  if (api !== '/api/Product') {
    if (FakeData[api]) {
      return fakeResponse(FakeData[api]);
    }
  }

  const { ignoreServerError = false, ...postOptions } = options;

  return new Promise((resolve, reject) => {
    axios
      .post(api, payload, postOptions)
      .then((response) => {
        if (dataOnly) resolve(response.data);
        else resolve(response);
      })
      .catch((error) => {
        if (ignoreServerError) {
          resolve(error);
          return;
        }

        reject(error);
      });
  });
};

//* request to download
export const requestToDownload = ({
  method,
  apiEndpoint,
  payload,
  urlPath,
  options,
  redownload,
}) => {
  if (method?.toLowerCase() === 'get') {
    return new Promise((resolve, reject) => {
      axios
        .get(apiEndpoint, options)
        .then((response) => {
          const path = urlPath ? `data.${urlPath}` : 'data.data.url';
          const downloadUrl = _.get(response, path);
          downloadUrl &&
            sendDownload({
              method,
              url: downloadUrl,
              apiEndpoint,
              options,
              redownload,
            });
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else if (method?.toLowerCase() === 'post') {
    return new Promise((resolve, reject) => {
      axios
        .post(apiEndpoint, payload, options)
        .then((response) => {
          const path = urlPath ? `data.${urlPath}` : 'data.data.url';
          const downloadUrl = _.get(response, path);
          downloadUrl &&
            sendDownload({
              method,
              url: downloadUrl,
              apiEndpoint,
              options,
              payload,
              redownload,
            });
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else return false;
};

//* Download request
export const sendDownload = async ({
  method,
  apiEndpoint,
  url,
  options = {},
  payload,
  name,
  redownload,
}) => {
  const downloadItemUid = 'rc-download-' + uuidv4().toString();
  var itemInfo = {
    type: 'download',
    name: name || downloadItemUid,
    percent: 0,
    status: 'uploading',
    uid: redownload ? redownload?.uid : downloadItemUid,
    method: method,
    url: url,
    apiEndpoint: apiEndpoint,
    options: options,
    payload,
  };
  store.dispatch(action.updateProgressBar(itemInfo));

  await sleep(1000);

  return new Promise((resolve, reject) => {
    axios({
      url,
      onDownloadProgress(progress) {
        store.dispatch(
          action.updateProgressBar({
            ...itemInfo,
            percent: Math.ceil(progress.loaded / progress.total) * 100,
          })
        );
      },
      responseType: 'blob',
      data: payload,
      ...options,
    })
      .then(async (response) => {
        if (response.status === 200) {
          const type = response.headers['content-type'];
          const contentDisposition = response.headers['content-disposition'];
          const defaultName =
            getFileName(contentDisposition) ||
            response.config.url.split('/').reverse()[0];
          const fileName = decodeURIComponent(name || defaultName);

          const blob = new Blob([response.data], {
            type,
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          const progressItemName = fileName;

          link.click();
          store.dispatch(
            action.updateProgressBar({
              ...itemInfo,
              percent: 100,
              status: 'done',
              name: progressItemName,
            })
          );
          resolve(response);
        }
      })
      .catch((error) => {
        store.dispatch(
          action.updateProgressBar({
            ...itemInfo,
            status: 'error',
          })
        );
        reject(error);
      });
  });
};

//* Upload request
export const sendUpload = ({
  api,
  payload,
  additionalBodyPayload,
  options = {},
  onEventHandlers,
  isBase64 = false,
  isUploadMultiple = false,
  name,
  nameFormData,
}) => {
  if (api !== '/api/Product') {
    if (FakeData[api]) return fakeResponse(FakeData[api]);
  }

  const emptyFunc = function () {};

  const onProgress = onEventHandlers?.onProgress || emptyFunc;
  const onSuccess = onEventHandlers?.onSuccess || emptyFunc;
  const onError = onEventHandlers?.onError || emptyFunc;

  let postData;
  let defaultName;
  let itemInfo = [];

  if (isBase64) {
    postData = {
      data: payload,
    };
    const uploadItemUid = 'rc-upload-' + uuidv4().toString();
    itemInfo = [
      {
        type: 'upload',
        name: name || defaultName || uploadItemUid,
        percent: 0,
        status: 'uploading',
        uid: uploadItemUid,
      },
    ];
  } else if (isUploadMultiple) {
    postData = payload;
    const fileLists = postData.getAll('files');
    fileLists.forEach((file) => {
      const uploadItemUid = 'rc-upload-' + uuidv4().toString();
      itemInfo = [
        ...itemInfo,
        {
          type: 'upload',
          name: file.name,
          percent: 0,
          status: 'uploading',
          uid: uploadItemUid,
        },
      ];
    });
  } else {
    const formData = new FormData();
    formData.append(nameFormData || 'file', payload.file);
    additionalBodyPayload &&
      Object.keys(additionalBodyPayload).forEach((keyObject) => {
        formData.append(keyObject, additionalBodyPayload[keyObject]);
      });
    postData = formData;
    defaultName = payload?.file?.name;
    const uploadItemUid = 'rc-upload-' + uuidv4().toString();
    itemInfo = [
      {
        type: 'upload',
        name: name || defaultName || uploadItemUid,
        percent: 0,
        status: 'uploading',
        uid: uploadItemUid,
      },
    ];
  }

  itemInfo.forEach((item) => action.updateProgressBar(item));

  return new Promise((resolve, reject) => {
    axios
      .post(api, postData, {
        onUploadProgress: (event) => {
          onProgress(event);
          itemInfo.forEach((item) => {
            store.dispatch(
              action.updateProgressBar({
                ...item,
                percent: Math.ceil(event.loaded / event.total) * 100,
              })
            );
          });
        },
        ...options,
      })
      .then((result) => {
        if (result?.data?.isSuccess) {
          !!onSuccess && onSuccess(result.data);
          itemInfo.forEach((item) =>
            store.dispatch(
              action.updateProgressBar({
                ...item,
                percent: 100,
                status: 'done',
              })
            )
          );
        } else {
          !!onError && onError('error');
          itemInfo.forEach((item) =>
            store.dispatch(
              action.updateProgressBar({
                ...item,
                status: 'error',
              })
            )
          );
        }

        resolve(result);
      })
      .catch((error) => {
        !!onError && onError(error);

        itemInfo.forEach((item) =>
          store.dispatch(
            action.updateProgressBar({
              ...item,
              status: 'error',
            })
          )
        );
        resolve(error);
      });
  });
};

export const customQueryFn = async ({ queryKey }) => {
  const IGNORE_KEY_REGEX = /\{.*\}$/; //*all key items in format {xxxx} will be removed from keys list

  const queryKeyInfo = queryKey.reduce(
    (keyInfo, key) => {
      return produce(keyInfo, (newKeyInfo) => {
        if (!IGNORE_KEY_REGEX.test(key) && typeof key === 'string') {
          newKeyInfo.keys.push(key);
          return;
        }

        if (typeof key === 'object') {
          newKeyInfo.options = { ...newKeyInfo.options, ...key };
        }
      });
    },
    { keys: [], options: {} }
  );

  const { params, payload, downloadOptions, uploadOptions } =
    queryKeyInfo.options || {};

  const baseUrl = `api/${queryKeyInfo.keys.join('/')}`;

  let response;

  if (params) {
    response = await sendGet(baseUrl, { params });
  } else if (payload) {
    response = await sendPost(baseUrl, payload);
  } else if (downloadOptions) {
    response = await sendDownload(downloadOptions);
  } else if (uploadOptions) {
    response = await sendUpload(uploadOptions);
  } else {
    response = await sendGet(baseUrl);
  }

  const { isSuccess, data, message } = response || {};

  if (isSuccess) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(message || 'Server Error');
  }
};
