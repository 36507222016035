import React, { useState } from 'react';

import { WithAbsoluteContainer, ModalCreateRequest } from 'common/components';

import { ROUTE } from 'static/Constants';
import { forwardTo } from 'utils/common/route';

import './styles.less';

const MemberRoleNeedPermission = () => {
  const [visible, setVisible] = useState(false);

  const toggleModalCreateRequest = () => {
    setVisible((prev) => !prev);
  };

  const handleCreateNewRequest = (e) => {
    e.preventDefault();
    toggleModalCreateRequest();
  };

  const onFinishCreateNewPermissionRequest = () => {
    forwardTo(ROUTE.PERMISSION_REQUEST);
  };

  return (
    <div>
      <a
        className='member-role-need-permission__link'
        onClick={handleCreateNewRequest}
      >
        Need a new permission? Click here to make a request
      </a>
      <WithAbsoluteContainer>
        <ModalCreateRequest
          visible={visible}
          onCancel={toggleModalCreateRequest}
          onFinish={onFinishCreateNewPermissionRequest}
        />
      </WithAbsoluteContainer>
    </div>
  );
};

export default MemberRoleNeedPermission;
