import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Row, Col, Input, Space } from 'antd';
import AgGrid from 'common/components/ag-grid/AgGrid';
import { RoleSectionWrapper, RoleEmptySection } from '../';
import {
  FormAddButton,
  FormDeleteButton,
  dialogFunction,
  CustomNotification,
} from 'common/components';
import NewUserModal from './NewUserModal';

import * as roleSelectors from 'pages/role/controllers/selectors';
import * as roleActions from 'pages/role/controllers/actions';

import * as securityEndpoints from 'services/roles-security/endpoints';
import * as securityServices from 'services/roles-security';

import { useStatePrevious } from 'hooks/usePrevious';
import { useHandleReloadPage } from 'hooks/useReloadPage';
import usePrevious from 'hooks/usePrevious';
import { isEqual } from 'lodash';

import './styles.less';
import {
  defaultNewUserGridColumns,
  defaultUserGridColumns,
} from 'pages/role/controllers/constants';

const { Text } = Typography;
const { Search } = Input;

const rowStyles = { marginBottom: 5 };

const RoleUserList = (props) => {
  const { title, selectedRole, memberId } = props;

  const [searchText, setSearchText] = useState('');
  const [isAddNewUserModalOpen, setIsAddNewUserModalOpen] = useState(false);
  const [selectedRows, setSelectedRow, refSelectedRow] = useStatePrevious([]);

  const dispatch = useDispatch();
  const refGridApi = useRef();
  const refSearch = useRef();

  const roleUserColDef = useSelector(roleSelectors.selectUserRoleColDef());

  const [reloadGrid, setReloadGrid] = useHandleReloadPage();

  const userGridColumns =
    roleUserColDef?.data?.userGridColumns ?? defaultUserGridColumns;

  const newUserColumns =
    roleUserColDef?.data?.newUserGridColumns ?? defaultNewUserGridColumns;

  const roleId = selectedRole?.id;

  const prevRoleId = usePrevious(roleId);

  const toggleIsAddNewUserModalOpen = (status) => {
    if (status === undefined) {
      setIsAddNewUserModalOpen((prev) => !prev);
    } else {
      setIsAddNewUserModalOpen(status);
    }
  };

  const onSearch = (value) => {
    setSearchText(value);
    setSelectedRow([]);
  };

  const handleSelectRows = (selectedNodesDetail) => {
    const selectedUserIdList = selectedNodesDetail.map(
      (nodeDetail) => nodeDetail?.id
    );
    setSelectedRow(selectedUserIdList);
  };

  const confirmDelete = () => {
    dialogFunction({
      type: 'warn',
      content: `Do you want to delete selected users from role ${selectedRole?.displayName}`,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleDeleteUsersOfRole,
    });
  };

  const handleDeleteUsersOfRole = async () => {
    const params = {
      roleId: selectedRole?.id,
      removeUserIds: selectedRows,
    };

    const notifyFail = (message) =>
      CustomNotification.error(
        message ||
          `Failed to delete user from role ${
            selectedRole?.displayName ? selectedRole?.displayName : ''
          }`
      );

    await securityServices
      .removeUsersFromRole(params)
      .then((res) => {
        if (res.isSuccess) {
          CustomNotification.success('Delete successfully');
          setReloadGrid();
        } else {
          notifyFail(res.message);
        }
      })
      .catch((error) => notifyFail());
  };

  const resetSearch = () => {
    refSearch?.current?.handleReset &&
      refSearch.current.handleReset({
        type: 'click',
        target: refSearch.current.Input,
      });

    setSearchText('');
  };

  const isRoleIdChange = !isEqual(roleId, prevRoleId);

  useEffect(() => {
    dispatch(roleActions.gridColumnRoleUsers({ gridName: 'role-users-grid' }));
  }, []);

  useEffect(() => {
    if (isRoleIdChange) {
      resetSearch();
    }
  }, [isRoleIdChange]);

  const procSearchText = isRoleIdChange ? '' : searchText;

  const paramsGrid = {
    roleId,
    memberId,
    search: { searchText: procSearchText },
  };

  return (
    <RoleSectionWrapper className='role-user-list-section__wrap' title={title}>
      {userGridColumns ? (
        <>
          <Row gutter={[5, 10]} style={rowStyles}>
            <Col style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Text style={{ color: '#F3705A' }}>
                Add users to selected role or remove selected user from selected
                role
              </Text>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Space>
                <FormAddButton
                  text='Add'
                  onClick={toggleIsAddNewUserModalOpen}
                />
                <FormDeleteButton
                  text='Remove'
                  onClick={confirmDelete}
                  disabled={selectedRows?.length <= 0}
                />
                <Search
                  ref={refSearch}
                  placeholder='Search users...'
                  allowClear
                  onSearch={onSearch}
                  style={{ width: 160 }}
                  enterButton
                />
              </Space>
            </Col>
          </Row>
          <Row style={{ flex: 1 }}>
            <Col xs={24}>
              <AgGrid
                sortAndFilterServerside
                columnDefs={userGridColumns}
                urlGrid={securityEndpoints.GET_USERS_HAVE_ROLE_ENDPOINT}
                urlGridDistinct={
                  securityEndpoints.GET_USERS_HAVE_ROLE__DISTINCT_ENDPOINT
                }
                paramsGrid={paramsGrid}
                getGridApi={(gridApi) => {
                  refGridApi.current = gridApi;
                }}
                isShowItemSelectionLog={false}
                onHandleSelectedRowCustom={handleSelectRows}
                responseParams={'data'}
                resizePagination={true}
                reloadGrid={reloadGrid}
              />
            </Col>
          </Row>
        </>
      ) : (
        <RoleEmptySection />
      )}
      <>
        {isAddNewUserModalOpen && (
          <NewUserModal
            visible={isAddNewUserModalOpen}
            newUserColumns={newUserColumns}
            memberId={memberId}
            role={selectedRole}
            onClose={toggleIsAddNewUserModalOpen}
          />
        )}
      </>
    </RoleSectionWrapper>
  );
};

export default RoleUserList;
