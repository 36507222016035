export const NUTRITION = [
  // { nutritionName: 'Additives', nutritionTypeCode: 'ADDITIVES' },
  { nutritionName: 'Agave Syrup', nutritionTypeCode: 'X_AGAVE' },
  { nutritionName: 'Alcohol', nutritionTypeCode: 'ALC' },
  // { nutritionName: 'Almonds', nutritionTypeCode: 'ALMONDS' },
  { nutritionName: 'Aluminium', nutritionTypeCode: 'AL' },
  // { nutritionName: 'Ancient Grains', nutritionTypeCode: 'ANCIENT_GRAINS' },
  // { nutritionName: 'Animal Cruelty', nutritionTypeCode: 'ANIMAL_CRUELTY' },
  // { nutritionName: 'Antibiotics', nutritionTypeCode: 'ANTIBIOTICS' },
  // { nutritionName: 'Antioxidants', nutritionTypeCode: 'ANTIOXIDANTS' },
  // {
  //   nutritionName: 'Artificial Colour',
  //   nutritionTypeCode: 'ARTIFICIAL_COLOUR',
  // },
  // {
  //   nutritionName: 'Artificial flavour',
  //   nutritionTypeCode: 'ARTIFICIAL_FLAVOUR',
  // },
  // {
  //   nutritionName: 'Artificial Preservatives',
  //   nutritionTypeCode: 'ARTIFICIAL_PRESERVATIVES',
  // },
  // {
  //   nutritionName: 'Artificial Sweeteners',
  //   nutritionTypeCode: 'ARTIFICIAL_SWEETENERS',
  // },
  //{ nutritionName: 'Aspartame', nutritionTypeCode: 'ASPARTAME' },
  // { nutritionName: 'Azo Dye', nutritionTypeCode: 'AZO_DYE' },
  // { nutritionName: 'Azodicarbonamide', nutritionTypeCode: 'AZODICARBONAMIDE' },
  // { nutritionName: 'Barley', nutritionTypeCode: 'BARLEY' },
  // { nutritionName: 'Beef Gelatine', nutritionTypeCode: 'BEEF_GELATINE' },
  // { nutritionName: 'BHA', nutritionTypeCode: 'BHA' },
  // { nutritionName: 'BHT', nutritionTypeCode: 'BHT' },
  { nutritionName: 'Biotin', nutritionTypeCode: 'BIOT' },
  // { nutritionName: 'Bleach', nutritionTypeCode: 'BLEACH' },
  // { nutritionName: 'BPA', nutritionTypeCode: 'BPA' },
  // { nutritionName: 'Brazil nuts', nutritionTypeCode: 'BRAZIL_NUTS' },
  { nutritionName: 'Caffeine', nutritionTypeCode: 'CAFFN' },
  { nutritionName: 'Calcium', nutritionTypeCode: 'CA' }, // remove that is duplicated with CA
  // { nutritionName: 'Calorie', nutritionTypeCode: 'CALORIE' },
  // { nutritionName: 'Cane Sugar', nutritionTypeCode: 'CANE_SUGAR' },
  { nutritionName: 'Carbohydrates', nutritionTypeCode: 'CHO_' },
  // { nutritionName: 'Carrageenan', nutritionTypeCode: 'CARRAGEENAN' },
  // { nutritionName: 'Cashews', nutritionTypeCode: 'CASHEWS' },
  // { nutritionName: 'Celery', nutritionTypeCode: 'CELERY' },
  { nutritionName: 'Chloride', nutritionTypeCode: 'CLD' },
  { nutritionName: 'Cholesterol', nutritionTypeCode: 'CHOL_' },
  { nutritionName: 'Choline', nutritionTypeCode: 'CHOLN' },
  { nutritionName: 'Chromium', nutritionTypeCode: 'CR' },
  // { nutritionName: 'Cloned Foods', nutritionTypeCode: 'CLONED_FOODS' },
  // { nutritionName: 'Colouring Agents', nutritionTypeCode: 'COLOURING_AGENTS' },
  { nutritionName: 'Copper', nutritionTypeCode: 'CU' },
  // { nutritionName: 'Corn', nutritionTypeCode: 'CORN' },
  // { nutritionName: 'Corn Syrup', nutritionTypeCode: 'CORN_SYRUP' },
  // { nutritionName: 'Crustacean', nutritionTypeCode: 'CRUSTACEAN' },
  // { nutritionName: 'Dairy', nutritionTypeCode: 'DAIRY' },
  // { nutritionName: 'Decaffeinated', nutritionTypeCode: 'DECAFFEINATED' },
  { nutritionName: 'Dietary Fibre', nutritionTypeCode: 'FIBTG' },
  // { nutritionName: 'Eggs', nutritionTypeCode: 'EGGS' },
  { nutritionName: 'Energy', nutritionTypeCode: 'ENER_' },
  { nutritionName: 'Fibre', nutritionTypeCode: 'FIB_' },
  // { nutritionName: 'Fish', nutritionTypeCode: 'FISH' },
  // {
  //   nutritionName: 'Flavour Enhancers',
  //   nutritionTypeCode: 'FLAVOUR_ENHANCERS',
  // },
  { nutritionName: 'Fluoride', nutritionTypeCode: 'FD' },
  { nutritionName: 'Folate', nutritionTypeCode: 'FOL' },
  { nutritionName: 'Folic Acid', nutritionTypeCode: 'FOLAC' },
  // { nutritionName: 'Formaldehyde', nutritionTypeCode: 'FORMALDEHYDE' },
  // {
  //   nutritionName: 'Formaldehyde Releasing Chemicals',
  //   nutritionTypeCode: 'FORMALDEHYDE_RELEASING_CHEMICALS',
  // },
  // { nutritionName: 'Fragrance', nutritionTypeCode: 'FRAGRANCE' },
  { nutritionName: 'Fructose', nutritionTypeCode: 'FRUS' },
  // { nutritionName: 'Fruit Juice', nutritionTypeCode: 'FRUIT_JUICE' },
  // { nutritionName: 'Fruit Syrup', nutritionTypeCode: 'FRUIT_SYRUP' },
  // { nutritionName: 'Gelatine', nutritionTypeCode: 'GELATINE' },
  // { nutritionName: 'Glyzyrrhizin', nutritionTypeCode: 'GLYZYRRHIZIN' },
  // { nutritionName: 'GMO', nutritionTypeCode: 'GMO' },
  // { nutritionName: 'Grains', nutritionTypeCode: 'GRAINS' },
  // { nutritionName: 'Hazelnuts', nutritionTypeCode: 'HAZELNUTS' },
  // {
  //   nutritionName: 'High Fructose Corn Syrup',
  //   nutritionTypeCode: 'HIGH_FRUCTOSE_CORN_SYRUP',
  // },
  // { nutritionName: 'Hormones', nutritionTypeCode: 'HORMONES' },
  // {
  //   nutritionName: 'Hydrocarbon Propellant',
  //   nutritionTypeCode: 'HYDROCARBON_PROPELLANT',
  // },
  // { nutritionName: 'Hydroquinone', nutritionTypeCode: 'HYDROQUINONE' },
  { nutritionName: 'Insoluble Fibre', nutritionTypeCode: 'FIBINS' },
  { nutritionName: 'Iodine', nutritionTypeCode: 'ID' },
  { nutritionName: 'Iron', nutritionTypeCode: 'FE' },
  { nutritionName: 'Juice', nutritionTypeCode: 'JUICE' },
  // { nutritionName: 'Latex', nutritionTypeCode: 'LATEX' },
  { nutritionName: 'Legume Protein', nutritionTypeCode: 'PROPLA' },
  { nutritionName: 'Liquorice', nutritionTypeCode: 'LIQUORICE' },
  { nutritionName: 'Macadamia', nutritionTypeCode: 'MACADAMIA' },
  { nutritionName: 'Magnesium', nutritionTypeCode: 'MG' },
  { nutritionName: 'Malt', nutritionTypeCode: 'MALT' },
  // { nutritionName: 'Maple Syrup', nutritionTypeCode: 'MAPLE_SYRUP' },
  // { nutritionName: 'Meat', nutritionTypeCode: 'MEAT' },
  // { nutritionName: 'Milk', nutritionTypeCode: 'MILK' },
  // { nutritionName: 'Milk Protein', nutritionTypeCode: 'MILK_PROTEIN' },
  // { nutritionName: 'Mineral Oil', nutritionTypeCode: 'MINERAL_OIL' },
  // { nutritionName: 'Molasses', nutritionTypeCode: 'MOLASSES' },
  // { nutritionName: 'Molluscs', nutritionTypeCode: 'MOLLUSCS' },
  // {
  //   nutritionName: 'Mono Unsaturated Fat',
  //   nutritionTypeCode: 'MONO_UNSATURATED_FAT',
  // },
  // { nutritionName: 'Monosodium Glutamate', nutritionTypeCode: 'MSG' },
  // { nutritionName: 'Multigrain', nutritionTypeCode: 'MULTIGRAIN' },
  // { nutritionName: 'Mustard', nutritionTypeCode: 'MUSTARD' },
  // { nutritionName: 'Nanoparticle', nutritionTypeCode: 'NANOPARTICLE' },
  // { nutritionName: 'Natural Flavour', nutritionTypeCode: 'NATURAL_FLAVOUR' },
  // { nutritionName: 'Natural Gluten', nutritionTypeCode: 'NATURAL_GLUTEN' },
  // {
  //   nutritionName: 'Naturally Occuring Sugars',
  //   nutritionTypeCode: 'NATURALLY_OCCURING_SUGARS',
  // },
  { nutritionName: 'Niacin', nutritionTypeCode: 'NIA' },
  { nutritionName: 'Nitrate', nutritionTypeCode: 'NITRA' },
  // {
  //   nutritionName: 'Non-Caloric Sweeteners',
  //   nutritionTypeCode: 'NON_CALORIC_SWEETENERS',
  // },
  // {
  //   nutritionName: 'Nonylphenol Ethoxylates (NPEs)',
  //   nutritionTypeCode: 'NONYLPHENOL_ETHOXYLATES',
  // },
  // { nutritionName: 'Nuts', nutritionTypeCode: 'NUTS' },
  // { nutritionName: 'Oat', nutritionTypeCode: 'OAT' },
  {
    nutritionName: 'Omega 3 Fatty Acids',
    nutritionTypeCode: 'FACN3',
  },
  // {
  //   nutritionName: 'Optical Brighteners',
  //   nutritionTypeCode: 'OPTICAL_BRIGHTENERS',
  // },
  // { nutritionName: 'Oxybenzone', nutritionTypeCode: 'OXYBENZONE' },
  // { nutritionName: 'Palm oil', nutritionTypeCode: 'PALM_OIL' },
  { nutritionName: 'Pantothenic Acid', nutritionTypeCode: 'PANTAC' },
  // { nutritionName: 'Paraben', nutritionTypeCode: 'PARABEN' },
  // {
  //   nutritionName: 'Partially Hydrogenated Vegetable Oil',
  //   nutritionTypeCode: 'PARTIALLY_HYDROGENATED_VEGETABLE_OIL',
  // },
  // { nutritionName: 'Peanuts', nutritionTypeCode: 'PEANUTS' },
  // { nutritionName: 'Pecans', nutritionTypeCode: 'PECANS' },
  // { nutritionName: 'Peroxide', nutritionTypeCode: 'PEROXIDE' },
  // { nutritionName: 'Phenoxyethanol', nutritionTypeCode: 'PHENOXYETHANOL' },
  //{ nutritionName: 'Phenylalanine', nutritionTypeCode: 'PHENYLALANINE' },
  // { nutritionName: 'Phosphate', nutritionTypeCode: 'PHOSPHATE' },
  { nutritionName: 'Phosphorus', nutritionTypeCode: 'P' },
  // { nutritionName: 'Phthalate', nutritionTypeCode: 'PHTHALATE' },
  // { nutritionName: 'Pinenuts', nutritionTypeCode: 'PINENUTS' },
  // { nutritionName: 'Pistachios', nutritionTypeCode: 'PISTACHIOS' },
  // { nutritionName: 'Plant sterols', nutritionTypeCode: 'PLANT_STEROLS' },
  // {
  //   nutritionName: 'Polyethylene Microbeads',
  //   nutritionTypeCode: 'POLYETHYLENE_MICROBEADS',
  // },
  {
    nutritionName: 'Monounsaturated Fat',
    nutritionTypeCode: 'FAMS',
  },
  {
    nutritionName: 'Polyunsaturated Fat',
    nutritionTypeCode: 'FAPU',
  },
  // { nutritionName: 'Pork Gelatine', nutritionTypeCode: 'PORK_GELATINE' },
  { nutritionName: 'Potassium', nutritionTypeCode: 'K' },
  // { nutritionName: 'Preservatives', nutritionTypeCode: 'PRESERVATIVES' },
  // { nutritionName: 'Probiotics', nutritionTypeCode: 'PROBIOTICS' },
  { nutritionName: 'Protein', nutritionTypeCode: 'PRO_' },
  // { nutritionName: 'PVC (polyvinyl chloride)', nutritionTypeCode: 'PVC' },
  // {
  //   nutritionName: 'PVC (polyvinyl chloride) with Phthalates',
  //   nutritionTypeCode: 'PVC_WITH_PHTHALATES',
  // },
  // {
  //   nutritionName: 'PVC (polyvinyl chloride) without Phthalates',
  //   nutritionTypeCode: 'PVC_WITHOUT_PHTHALATES',
  // },
  // { nutritionName: 'Raw  Beet Sugar', nutritionTypeCode: 'SUGAN' },
  // {
  //   nutritionName: 'Retinyl Palmitate',
  //   nutritionTypeCode: 'RETINYL_PALMITATE',
  // },
  // { nutritionName: 'Rye', nutritionTypeCode: 'RYE' },
  { nutritionName: 'Saturated Fat', nutritionTypeCode: 'FASAT' },
  { nutritionName: 'Selenium', nutritionTypeCode: 'SE' },
  // { nutritionName: 'Sesame', nutritionTypeCode: 'SESAME' },
  // { nutritionName: 'Silicone', nutritionTypeCode: 'SILICONE' },
  // { nutritionName: 'Smoke Flavour', nutritionTypeCode: 'SMOKE_FLAVOUR' },
  // { nutritionName: 'Sodium Nitrite', nutritionTypeCode: 'SODIUM_NITRITE' },
  // { nutritionName: 'Sodium Salt', nutritionTypeCode: 'SODIUM_SALT' },
  { nutritionName: 'Soluble Fibre', nutritionTypeCode: 'FIBSOL' },
  //{ nutritionName: 'Sorbitol', nutritionTypeCode: 'SORTL' },
  // { nutritionName: 'Soy', nutritionTypeCode: 'SOY' },
  // { nutritionName: 'Soya', nutritionTypeCode: 'SOYA' },
  // { nutritionName: 'Stevia', nutritionTypeCode: 'STEVIA' },
  { nutritionName: 'Sugars', nutritionTypeCode: 'SUGAR' },
  // { nutritionName: 'Sulfate', nutritionTypeCode: 'SULFATE' },
  // { nutritionName: 'Sweeteners', nutritionTypeCode: 'SWEETENERS' },
  { nutritionName: 'Thiamin', nutritionTypeCode: 'THIA' },
  { nutritionName: 'Trans fat', nutritionTypeCode: 'FATRN' },
  // { nutritionName: 'Tree nuts', nutritionTypeCode: 'TREE_NUTS' },
  // { nutritionName: 'Triclocarban', nutritionTypeCode: 'TRICLOCARBAN' },
  // { nutritionName: 'Triclosan', nutritionTypeCode: 'TRICLOSAN' },
  // { nutritionName: 'Triticale', nutritionTypeCode: 'TRITICALE' },
  // { nutritionName: 'Unsaturated Fat', nutritionTypeCode: 'UNSATURATED_FAT' },
  { nutritionName: 'Vitamin A', nutritionTypeCode: 'VITA' },
  { nutritionName: 'Vitamin B12', nutritionTypeCode: 'VITB12' },
  { nutritionName: 'Vitamin B6', nutritionTypeCode: 'VITB6_' },
  { nutritionName: 'Vitamin C', nutritionTypeCode: 'VITC' },
  { nutritionName: 'Vitamin D', nutritionTypeCode: 'VITD' },
  { nutritionName: 'Vitamin E', nutritionTypeCode: 'VITE' },
  { nutritionName: 'Vitamin K', nutritionTypeCode: 'VITK' },
  // {
  //   nutritionName: 'Vitamins and or Minerals',
  //   nutritionTypeCode: 'VITAMINS_AND_OR_MINERALS',
  // },
  // { nutritionName: 'Walnuts', nutritionTypeCode: 'WALNUTS' },
  // { nutritionName: 'Wheat', nutritionTypeCode: 'WHEAT' },
  // { nutritionName: 'White Sugar', nutritionTypeCode: 'WHITE_SUGAR' },
  // { nutritionName: 'Whole grain', nutritionTypeCode: 'WHOLE_GRAIN' },
  // { nutritionName: 'Whole wheat', nutritionTypeCode: 'WHOLE_WHEAT' },
  // { nutritionName: 'Yeast', nutritionTypeCode: 'YEAST' },
  { nutritionName: '10-Formyldihydrofolate', nutritionTypeCode: '_10FDHF' },
  { nutritionName: '10-Formylfolic Acid', nutritionTypeCode: '_10FFOLAC' },
  { nutritionName: '5-Formyltetrahydrofolate', nutritionTypeCode: '_5FTHF' },
  { nutritionName: '5-Methyl-Dihydrofolic Acid', nutritionTypeCode: '_5MDHF' },
  { nutritionName: '5-Methyltetrahydrofolate', nutritionTypeCode: '_5MTHF' },
  { nutritionName: 'Amino Acids, Total Aromatic', nutritionTypeCode: 'AAA' },
  {
    nutritionName:
      'Amino Acids, Total Aromatic; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'AAAN',
  },
  {
    nutritionName:
      'Amino Acids, Total Aromatic; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'AAAP',
  },
  {
    nutritionName: 'Amino Acids, Total Essential; Unknown Or Variable',
    nutritionTypeCode: 'AAE_',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential: 8 Basic Plus Arginine And Histidine',
    nutritionTypeCode: 'AAE10A',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen;Includes The Eight Basic Essential Amino Acids Plus Arginine And Histidine',
    nutritionTypeCode: 'AAE10AN',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Arginine And Histidine',
    nutritionTypeCode: 'AAE10AP',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential: 8 Basic Plus Cystine And Tyrosine',
    nutritionTypeCode: 'AAE10B',
  },
  // {
  //   nutritionName:
  //     'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Cystine And Tyrosine',
  //   nutritionTypeCode: 'AAE10BN',
  // },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Cystine And Tyrosine',
    nutritionTypeCode: 'AAE10BP',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential: 8 Basic Plus Arginine, Histidine, Cystine And Tyrosine',
    nutritionTypeCode: 'AAE12',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen; Includes The Eight Basic Essential Amino Acids Plus Arginine, Histidine, Cystine, And Tyrosine',
    nutritionTypeCode: 'AAE12N',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Arginine, Histidine, Cystine, And Tyrosine',
    nutritionTypeCode: 'AAE12P',
  },
  {
    nutritionName: 'Amino Acid (Aa 7 Ess. Excl.Trp)',
    nutritionTypeCode: 'AAE7',
  },
  {
    nutritionName:
      'Amino Acids, 8 Basic Essential: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan And Valine',
    nutritionTypeCode: 'AAE8',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen; Includes The Eight Basic Essential Amino Acids: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan, And Valine',
    nutritionTypeCode: 'AAE8N',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan And Valine',
    nutritionTypeCode: 'AAE8P',
  },
  {
    nutritionName: 'Amino Acid (Aa 9 Ess. Incl. His)',
    nutritionTypeCode: 'AAE9',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen; Unknown Which Amino Acids Are Included In The Total Value',
    nutritionTypeCode: 'AAEN_',
  },
  {
    nutritionName:
      'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Unknown Which Amino Acids Are Included In The Total Value',
    nutritionTypeCode: 'AAEP_',
  },
  {
    nutritionName:
      'Amino Acids, Essential To Total Amino Acid Ratio; Neither The Numerator (Which Amino Acids Are Considered Essential',
    nutritionTypeCode: 'AAET_',
  },
  {
    nutritionName:
      'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids Plus Arginine And Histidine',
    nutritionTypeCode: 'AAET10A',
  },
  {
    nutritionName:
      'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids Plus Cystine And Tyrosine',
    nutritionTypeCode: 'AAET10B',
  },
  {
    nutritionName:
      'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids Plus Arginine, Histidine, Cystine, And Tyrosine',
    nutritionTypeCode: 'AAET12',
  },
  {
    nutritionName:
      'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan, And Valine',
    nutritionTypeCode: 'AAET8_',
  },
  {
    nutritionName:
      'Amino Acids, Limiting Amino Acid; Determined Through Biological Experiment',
    nutritionTypeCode: 'AALB',
  },
  {
    nutritionName:
      'Amino Acids, Limiting Amino Acid; Determined Through Calculation',
    nutritionTypeCode: 'AALC',
  },
  { nutritionName: 'Amino Acid (Aa Non Ess.)', nutritionTypeCode: 'AANE' },
  {
    nutritionName:
      'Amino Acids, Total Sulphur-Containing: Cystine Plus Methionine',
    nutritionTypeCode: 'AAS',
  },
  {
    nutritionName:
      'Amino Acids, Total Sulphur-Containing; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'AASN',
  },
  {
    nutritionName:
      'Amino Acids, Total Sulphur-Containing; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'AASP',
  },
  {
    nutritionName: 'Amino Acids, Total, Precise Definition Not Specified',
    nutritionTypeCode: 'AAT_',
  },
  { nutritionName: 'Amino Acids (Aa 19)', nutritionTypeCode: 'AAT19' },
  { nutritionName: 'Amino Acids (Aa 20)', nutritionTypeCode: 'AAT20' },
  { nutritionName: 'Amino Acids (Aa 24)', nutritionTypeCode: 'AAT24' },
  {
    nutritionName: 'Amino Acids, Total; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'AATN',
  },
  {
    nutritionName: 'Amino Acids, Total; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'AATP',
  },
  { nutritionName: 'Acetic Acid', nutritionTypeCode: 'ACEAC' },
  { nutritionName: 'Acesulfam-K', nutritionTypeCode: 'ACESK' },
  { nutritionName: 'Adenine', nutritionTypeCode: 'ADEN' },
  { nutritionName: 'Silver (Agar-Agar)', nutritionTypeCode: 'AG' },
  { nutritionName: 'Agar-Agar (Agar)', nutritionTypeCode: 'AGAR' },
  // { nutritionName: 'Aluminium', nutritionTypeCode: 'AL' },
  { nutritionName: 'Alanine', nutritionTypeCode: 'ALA' },
  {
    nutritionName: 'Alanine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'ALAN',
  },
  {
    nutritionName: 'Alanine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'ALAP',
  },
  { nutritionName: 'Albumin', nutritionTypeCode: 'ALBU' },
  { nutritionName: 'Albumin And Globulin', nutritionTypeCode: 'ALGL' },
  { nutritionName: 'Alginates', nutritionTypeCode: 'ALGNT' },
  { nutritionName: 'Ammonia', nutritionTypeCode: 'AMMON' },
  { nutritionName: 'Amylopectin', nutritionTypeCode: 'AMYP' },
  { nutritionName: 'Amylopectin; Expressed In', nutritionTypeCode: 'AMYPM' },
  { nutritionName: 'Amylose', nutritionTypeCode: 'AMYS' },
  {
    nutritionName: 'Amylose; Expressed In Monosaccharide',
    nutritionTypeCode: 'AMYSM',
  },
  { nutritionName: 'Anthocyanidin, Tot.', nutritionTypeCode: 'ANTCYAN' },
  { nutritionName: 'Apigenin', nutritionTypeCode: 'APIGEN' },
  { nutritionName: 'Arabinose In Dietary Fibre', nutritionTypeCode: 'ARAFB' },
  { nutritionName: 'Arabinan', nutritionTypeCode: 'ARAN' },
  { nutritionName: 'Arabinose', nutritionTypeCode: 'ARAS' },
  { nutritionName: 'Arginine', nutritionTypeCode: 'ARG' },
  {
    nutritionName: 'Arginine & Histidine (ARG+HIS)',
    nutritionTypeCode: 'ARGHIS',
  },
  {
    nutritionName: 'Arginine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'ARGN',
  },
  {
    nutritionName: 'Arginine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'ARGP',
  },
  { nutritionName: 'Arsenic', nutritionTypeCode: 'AS' },
  { nutritionName: 'Arsenic 3+', nutritionTypeCode: 'AS3+' },
  { nutritionName: 'Arsenic 5+', nutritionTypeCode: 'AS5+' },
  { nutritionName: 'L-Dehydroascorbic Acid', nutritionTypeCode: 'ASCDL' },
  { nutritionName: 'L-Ascorbic Acid', nutritionTypeCode: 'ASCL' },
  { nutritionName: 'Ash', nutritionTypeCode: 'ASH' },
  { nutritionName: 'Arsenic, Inorganic', nutritionTypeCode: 'ASIO' },
  { nutritionName: 'Asparagine', nutritionTypeCode: 'ASN' },
  {
    nutritionName: 'Asparagine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'ASNN',
  },
  {
    nutritionName: 'Asparagine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'ASNP',
  },
  { nutritionName: 'Arsenic, Organic', nutritionTypeCode: 'ASO' },
  { nutritionName: 'Aspartic Acid', nutritionTypeCode: 'ASP' },
  // { nutritionName: 'Aspartame', nutritionTypeCode: 'ASPM' },
  {
    nutritionName: 'Aspartic Acid; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'ASPN',
  },
  {
    nutritionName: 'Aspartic Acid; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'ASPP',
  },
  { nutritionName: 'Astaxanthin', nutritionTypeCode: 'ATX' },
  { nutritionName: 'Gold', nutritionTypeCode: 'AU' },
  {
    nutritionName: 'Avenasterol (delta 5-avenasterol)',
    nutritionTypeCode: 'AVED5',
  },
  {
    nutritionName: 'Avenasterol (delta 7-avenasterol)',
    nutritionTypeCode: 'AVED7',
  },
  { nutritionName: 'Avenasterol, Total', nutritionTypeCode: 'AVEDT' },
  { nutritionName: 'Boron', nutritionTypeCode: 'B' },
  { nutritionName: 'Barium', nutritionTypeCode: 'BA' },
  { nutritionName: 'Benzoic Acid', nutritionTypeCode: 'BENAC' },
  { nutritionName: 'Betaine', nutritionTypeCode: 'BETN' },
  { nutritionName: 'Bismuth', nutritionTypeCode: 'BI' },
  // { nutritionName: 'Biotin', nutritionTypeCode: 'BIOT' },
  { nutritionName: 'Brassicasterol', nutritionTypeCode: 'BRASTR' },
  { nutritionName: 'Bromide', nutritionTypeCode: 'BRD' },
  // { nutritionName: 'Calcium', nutritionTypeCode: 'CA' },
  { nutritionName: 'Cadaverine', nutritionTypeCode: 'CADAVT' },
  // { nutritionName: 'Caffeine', nutritionTypeCode: 'CAFFN' },
  { nutritionName: 'Delta 5-Campesterol', nutritionTypeCode: 'CAMD5' },
  { nutritionName: 'Delta 7-Campesterol', nutritionTypeCode: 'CAMD7' },
  { nutritionName: 'Campesterol, Total.', nutritionTypeCode: 'CAMT' },
  { nutritionName: 'Capsaicin', nutritionTypeCode: 'CAPSA' },
  { nutritionName: 'Carageenan', nutritionTypeCode: 'CARGN' },
  { nutritionName: 'Carotene, Total', nutritionTypeCode: 'CAROT' },
  { nutritionName: 'Alpha-Carotene', nutritionTypeCode: 'CARTA' },
  { nutritionName: 'Beta-Carotene', nutritionTypeCode: 'CARTB' },
  { nutritionName: 'B-Carotene Cis', nutritionTypeCode: 'CARTBCIS' },
  { nutritionName: 'Beta-Carotene Equivalents', nutritionTypeCode: 'CARTBEQ' },
  { nutritionName: 'Gamma-Carotene', nutritionTypeCode: 'CARTG' },
  { nutritionName: 'Carotenoids, Total', nutritionTypeCode: 'CARTOID' },
  { nutritionName: 'Casein', nutritionTypeCode: 'CASN' },
  { nutritionName: 'Catechins, Total', nutritionTypeCode: 'CATEC' },
  { nutritionName: 'Cadmium', nutritionTypeCode: 'CD' },
  { nutritionName: 'Cellobiose', nutritionTypeCode: 'CELLO' },
  { nutritionName: 'Cellulose', nutritionTypeCode: 'CELLU' },
  { nutritionName: 'Chemical Score', nutritionTypeCode: 'CHEMSC' },
  { nutritionName: 'Chitin', nutritionTypeCode: 'CHITIN' },
  {
    nutritionName: 'Chlorogenic Acid Phosphatidyl Choline',
    nutritionTypeCode: 'CHLNP',
  },
  { nutritionName: 'Chlorogenic Acid', nutritionTypeCode: 'CHLRAC' },
  // {
  //   nutritionName:
  //     'Carbohydrate, Total; Method Of Determination Unknown Or Variable',
  //   nutritionTypeCode: 'CHO_',
  // },
  { nutritionName: 'Carbohydrate, Available', nutritionTypeCode: 'CHOAVL' },
  {
    nutritionName: 'Carbohydrate, Other (CHO avl. unk.)',
    nutritionTypeCode: 'CHOAVL_',
  },
  {
    nutritionName: 'Carbohydrate, Other (CHO avl. diff.)',
    nutritionTypeCode: 'CHOAVLDF',
  },
  {
    nutritionName:
      'Carbohydrate, Other (carbohydrate, available; expressed in monosaccharide equivalents)',
    nutritionTypeCode: 'CHOAVLM',
  },
  { nutritionName: 'Cholecalciferol', nutritionTypeCode: 'CHOCAL' },
  {
    nutritionName: 'Cholecalciferol (1,25 dihydroxyD2)',
    nutritionTypeCode: 'CHOCALDOH',
  },
  {
    nutritionName: 'Cholecalciferol (25 hydroxyD2)',
    nutritionTypeCode: 'CHOCALOH',
  },
  {
    nutritionName:
      'Cholecalciferol (carbohydrate, total; calculated by difference)',
    nutritionTypeCode: 'CHOCDF',
  },
  {
    nutritionName:
      'Cholecalciferol (carbohydrate, total; calculated by summation)',
    nutritionTypeCode: 'CHOCSM',
  },
  // {
  //   nutritionName: 'Cholesterol; Method Of Determination Unknown Or Variable',
  //   nutritionTypeCode: 'CHOL_',
  // },
  {
    nutritionName: 'Cholesterol; Determined By Chemical Method (Classical',
    nutritionTypeCode: 'CHOLC',
  },
  {
    nutritionName:
      'Cholesterol; Determined By Enzymatic Or Chromatographic Method',
    nutritionTypeCode: 'CHOLE',
  },
  {
    nutritionName: 'Cholesterol (24-methylcholest-7-erol)',
    nutritionTypeCode: 'CHOLM',
  },
  {
    nutritionName: 'Cholesterol (24-methylene cholesterol)',
    nutritionTypeCode: 'CHOLME',
  },
  { nutritionName: 'Choline, Total', nutritionTypeCode: 'CHOLN' },
  { nutritionName: 'Choline, Free', nutritionTypeCode: 'CHOLNF' },
  {
    nutritionName: 'Choline From Glycerophosphocholine',
    nutritionTypeCode: 'CHOLNGPC',
  },
  {
    nutritionName: 'Choline From Phosphocholine',
    nutritionTypeCode: 'CHOLNPC',
  },
  {
    nutritionName: 'Choline From Phosphatidylcholine',
    nutritionTypeCode: 'CHOLNPTC',
  },
  { nutritionName: 'Choline From Sphingomyelin', nutritionTypeCode: 'CHOLNSM' },
  { nutritionName: 'Chromoproteins', nutritionTypeCode: 'CHRPRO' },
  { nutritionName: 'Citric Acid', nutritionTypeCode: 'CITAC' },
  // { nutritionName: 'Chloride', nutritionTypeCode: 'CLD' },
  { nutritionName: 'Cobalt', nutritionTypeCode: 'CO' },
  { nutritionName: 'Cobalt 2+', nutritionTypeCode: 'CO2+' },
  { nutritionName: 'Carbon Dioxide, Free', nutritionTypeCode: 'CO2F' },
  { nutritionName: 'Cobalt 3+', nutritionTypeCode: 'CO3+' },
  { nutritionName: 'Collagen', nutritionTypeCode: 'COLG' },
  {
    nutritionName: 'Protein (connective tissue protein)',
    nutritionTypeCode: 'CONPRO',
  },
  { nutritionName: 'Coumestrol', nutritionTypeCode: 'COUMEST' },
  // { nutritionName: 'Chromium', nutritionTypeCode: 'CR' },
  { nutritionName: 'Chromium 3+', nutritionTypeCode: 'CR3+' },
  { nutritionName: 'Chromium 6+', nutritionTypeCode: 'CR6+' },
  { nutritionName: 'Creatinine', nutritionTypeCode: 'CREATN' },
  { nutritionName: 'Cryptoxanthin', nutritionTypeCode: 'CRYPX' },
  { nutritionName: 'A-Cryptoxanthin', nutritionTypeCode: 'CRYPXA' },
  { nutritionName: 'B-Cryptoxanthin', nutritionTypeCode: 'CRYPXB' },
  { nutritionName: 'Cesium', nutritionTypeCode: 'CS' },
  { nutritionName: 'Cesium-137', nutritionTypeCode: 'CS137' },
  { nutritionName: 'Canthaxanthin', nutritionTypeCode: 'CTX' },
  // { nutritionName: 'Copper', nutritionTypeCode: 'CU' },
  { nutritionName: 'Copper 1+', nutritionTypeCode: 'CU1+' },
  { nutritionName: 'Copper 2+', nutritionTypeCode: 'CU2+' },
  { nutritionName: 'Cyanidin', nutritionTypeCode: 'CYAD' },
  { nutritionName: 'Cyanide', nutritionTypeCode: 'CYAN' },
  { nutritionName: 'Cyclamate', nutritionTypeCode: 'CYCL' },
  { nutritionName: 'Cystine', nutritionTypeCode: 'CYS' },
  {
    nutritionName: 'Cystine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'CYSN',
  },
  {
    nutritionName: 'Cystine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'CYSP',
  },
  { nutritionName: 'Cysteine', nutritionTypeCode: 'CYSTE' },
  { nutritionName: 'Daidzin', nutritionTypeCode: 'DAIDZN' },
  { nutritionName: 'Acetyl-Daidzin', nutritionTypeCode: 'DAIDZNA' },
  { nutritionName: 'Malonyl-Daidzin', nutritionTypeCode: 'DAIDZNM' },
  { nutritionName: 'Daidzein', nutritionTypeCode: 'DDZEIN' },
  { nutritionName: 'Delphinidin', nutritionTypeCode: 'DELPH' },
  { nutritionName: 'Density', nutritionTypeCode: 'DEN' },
  { nutritionName: 'Dextrins', nutritionTypeCode: 'DEXTN' },
  {
    nutritionName: 'Dextrins; Expressed In Monosaccharide Equivalents',
    nutritionTypeCode: 'DEXTNM',
  },
  { nutritionName: 'Diglycerides, Total', nutritionTypeCode: 'DGLY' },
  { nutritionName: 'Dihydrofolic Acid', nutritionTypeCode: 'DHF' },
  { nutritionName: 'Disaccharides, Total', nutritionTypeCode: 'DISAC' },
  {
    nutritionName:
      'Disaccharides, Total; Expressed In Monosaccharide Equivalents',
    nutritionTypeCode: 'DISACM',
  },
  { nutritionName: 'Dry Matter', nutritionTypeCode: 'DM' },
  { nutritionName: 'Dopamine', nutritionTypeCode: 'DOPN' },
  { nutritionName: 'Edible', nutritionTypeCode: 'EDIBLE' },
  { nutritionName: 'Elastin', nutritionTypeCode: 'ELAN' },
  {
    nutritionName:
      'Calories/Energy (energy; method of determination unknown or variable)',
    nutritionTypeCode: 'ENER_',
  },
  {
    nutritionName:
      'Calories/Energy (energy, gross; determined by direct analysis using bomb calorimetry)',
    nutritionTypeCode: 'ENERA',
  },
  {
    nutritionName:
      'Calories/Energy (energy, total metabolizable; calculated from the energy-producing food components)',
    nutritionTypeCode: 'ENERC',
  },
  { nutritionName: 'Energy (energy % from alc.)', nutritionTypeCode: 'ENERPA' },
  {
    nutritionName: 'Energy, Percent Contributed By Carbohydrate',
    nutritionTypeCode: 'ENERPC',
  },
  {
    nutritionName: 'Energy % From Dietary Fibre',
    nutritionTypeCode: 'ENERPDF',
  },
  {
    nutritionName: 'Energy, Percent Contributed By Fat',
    nutritionTypeCode: 'ENERPF',
  },
  {
    nutritionName: 'Energy, Percent Contributed By Protein',
    nutritionTypeCode: 'ENERPP',
  },
  { nutritionName: 'Energy % From Added Sugar', nutritionTypeCode: 'ENERPSA' },
  {
    nutritionName: 'Energy % From Natural Sugar',
    nutritionTypeCode: 'ENERPSN',
  },
  { nutritionName: 'Epicatechin', nutritionTypeCode: 'EPICATEC' },
  { nutritionName: 'Epicatechin-3-Gallate', nutritionTypeCode: 'EPICATECG3' },
  { nutritionName: 'Epigallocatechin', nutritionTypeCode: 'EPICATEGC' },
  { nutritionName: 'Ergocalciferol', nutritionTypeCode: 'ERGCAL' },
  { nutritionName: '25 Hydroxy D3', nutritionTypeCode: 'ERGCALOH' },
  { nutritionName: 'Ergosterol', nutritionTypeCode: 'ERGSTR' },
  { nutritionName: 'Erythritol', nutritionTypeCode: 'ERYTHL' },
  { nutritionName: 'Ethanolamines, Total', nutritionTypeCode: 'ETHAM' },
  { nutritionName: 'Fat (fatty acid 10:0)', nutritionTypeCode: 'F10D0' },
  {
    nutritionName:
      'Fat (fatty acid 10:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F10D0F',
  },
  { nutritionName: 'Fat (fatty acid 10:1)', nutritionTypeCode: 'F10D1' },
  {
    nutritionName:
      'Fat (fatty acid 10:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F10D1F',
  },
  { nutritionName: 'Fat (fatty acid 12:0)', nutritionTypeCode: 'F12D0' },
  {
    nutritionName:
      'Fat (fatty acid 12:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F12D0F',
  },
  { nutritionName: 'Fat (fatty acid 12:1)', nutritionTypeCode: 'F12D1' },
  {
    nutritionName:
      'Fat (fatty acid 12:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F12D1F',
  },
  { nutritionName: 'Fat (fatty acid 13:0)', nutritionTypeCode: 'F13D0' },
  {
    nutritionName:
      'Fat (fatty acid 13:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F13D0F',
  },
  { nutritionName: 'Fat (fatty acid 14:0)', nutritionTypeCode: 'F14D0' },
  {
    nutritionName:
      'Fat (fatty acid 14:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F14D0F',
  },
  { nutritionName: 'Fat (fatty acid 14:1)', nutritionTypeCode: 'F14D1' },
  {
    nutritionName:
      'Fat (fatty acid 14:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F14D1F',
  },
  { nutritionName: 'Fat (fatty acid 15:0)', nutritionTypeCode: 'F15D0' },
  {
    nutritionName:
      'Fat (fatty acid 15:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F15D0F',
  },
  { nutritionName: 'Fat (fatty acid 15:1)', nutritionTypeCode: 'F15D1' },
  {
    nutritionName:
      'Fat (fatty acid 15:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F15D1F',
  },
  { nutritionName: 'Fat (fatty acid 15:1 n-9)', nutritionTypeCode: 'F15D1N9' },
  {
    nutritionName:
      'Fat (fatty acid 15:1; n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F15D1N9F',
  },
  { nutritionName: 'Fat (fatty acid 16:0)', nutritionTypeCode: 'F16D0' },
  {
    nutritionName:
      'Fat (fatty acid 16:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F16D0F',
  },
  { nutritionName: 'Fat (fatty acid 16:1)', nutritionTypeCode: 'F16D1' },
  { nutritionName: 'Fat (fatty acid cis 16:1)', nutritionTypeCode: 'F16D1C' },
  {
    nutritionName:
      'Fat (fatty acid 16:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F16D1F',
  },
  {
    nutritionName: 'Fat, Trans (fatty acid trans 16:1)',
    nutritionTypeCode: 'F16D1T',
  },
  { nutritionName: 'Fat (FA 16:2)', nutritionTypeCode: 'F16D2' },
  { nutritionName: 'Fat (fatty acid 16:2 n-4)', nutritionTypeCode: 'F16D2N4' },
  {
    nutritionName: 'Fat (fatty acid 16:2; n-4)',
    nutritionTypeCode: 'F16D2N4F',
  },
  { nutritionName: 'Fat (fatty acid 16:2 n-7)', nutritionTypeCode: 'F16D2N7' },
  {
    nutritionName: 'Fat (fatty acid 16:2; n-7)',
    nutritionTypeCode: 'F16D2N7F',
  },
  { nutritionName: 'Fat (FA 16:3)', nutritionTypeCode: 'F16D3' },
  { nutritionName: 'Fat (fatty acid 16:3 n-3)', nutritionTypeCode: 'F16D3N3' },
  {
    nutritionName: 'Fat (fatty acid 16:3; n-3)',
    nutritionTypeCode: 'F16D3N3F',
  },
  { nutritionName: 'Fat (fatty acid 16:3 n-4)', nutritionTypeCode: 'F16D3N4' },
  {
    nutritionName: 'Fat (fatty acid 16:3; n-4)',
    nutritionTypeCode: 'F16D3N4F',
  },
  { nutritionName: 'Fat (fatty acid 16:4 n-1)', nutritionTypeCode: 'F16D4N1' },
  {
    nutritionName: 'Fat (fatty acid 16:4; n-1)',
    nutritionTypeCode: 'F16D4N1F',
  },
  { nutritionName: 'Fat (fatty acid 16:4 n-4)', nutritionTypeCode: 'F16D4N4' },
  {
    nutritionName: 'Fat (fatty acid 16:4; n-4)',
    nutritionTypeCode: 'F16D4N4F',
  },
  { nutritionName: 'Fat (fatty acid 17:0)', nutritionTypeCode: 'F17D0' },
  {
    nutritionName:
      'Fat (fatty acid 17:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F17D0F',
  },
  { nutritionName: 'Fat (fatty acid 17:1)', nutritionTypeCode: 'F17D1' },
  {
    nutritionName:
      'Fat (fatty acid 17:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F17D1F',
  },
  { nutritionName: 'Fat (fatty acid 18:0)', nutritionTypeCode: 'F18D0' },
  {
    nutritionName:
      'Fat (fatty acid 18:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D0F',
  },
  { nutritionName: 'Fat (fatty acid 18:1)', nutritionTypeCode: 'F18D1' },
  { nutritionName: 'Fat (fatty acid cis 18:1)', nutritionTypeCode: 'F18D1C' },
  {
    nutritionName: 'Fat (fatty acid 18:1 cis n-9)',
    nutritionTypeCode: 'F18D1CN9',
  },
  {
    nutritionName:
      'Fat (fatty acid 18:1 ; cis n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D1CN9F',
  },
  {
    nutritionName:
      'Fat (fatty acid 18:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D1F',
  },
  { nutritionName: 'Fat (fatty acid 18:1 n-7)', nutritionTypeCode: 'F18D1N7' },
  {
    nutritionName:
      'Fat (fatty acid 18:1; n-7 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D1N7F',
  },
  { nutritionName: 'Fat (fatty acid 18:1 n-9)', nutritionTypeCode: 'F18D1N9' },
  {
    nutritionName:
      'Fat (fatty acid 18:1; n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D1N9F',
  },
  {
    nutritionName: 'Fat, Trans (fatty acid trans 18:1)',
    nutritionTypeCode: 'F18D1T',
  },
  {
    nutritionName: 'Fat, Trans (fatty acid trans 18:1 n-9)',
    nutritionTypeCode: 'F18D1TN9',
  },
  {
    nutritionName:
      'Fat, Trans (fatty acid 18:1 Trans ; n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D1TN9F',
  },
  { nutritionName: 'Fat (fatty acid 18:2)', nutritionTypeCode: 'F18D2' },
  {
    nutritionName: 'Fat (fatty acid 18:2cis n-3)',
    nutritionTypeCode: 'F18D2CN3',
  },
  {
    nutritionName: 'Fat (fatty acid 18:2cis n-3F)',
    nutritionTypeCode: 'F18D2CN3F',
  },
  {
    nutritionName: 'Fat (fatty acid cis,cis 18:2 n-6)',
    nutritionTypeCode: 'F18D2CN6',
  },
  {
    nutritionName:
      'Fat (fatty acid cis,cis 18:2 n-6; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D2CN6F',
  },
  {
    nutritionName:
      'Fat (fatty acid 18:2; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D2F',
  },
  { nutritionName: 'Fat (fatty acid 18:2 n-4)', nutritionTypeCode: 'F18D2N4' },
  {
    nutritionName: 'Fat (fatty acid 18:2 n-4F)',
    nutritionTypeCode: 'F18D2N4F',
  },
  { nutritionName: 'Fat (fatty acid 18:2, n-6)', nutritionTypeCode: 'F18D2N6' },
  {
    nutritionName: 'Fat (fatty acid 18:2, n-6F)',
    nutritionTypeCode: 'F18D2N6F',
  },
  { nutritionName: 'Fat (fatty acid 18:2 n-9)', nutritionTypeCode: 'F18D2N9' },
  {
    nutritionName: 'Fat (fatty acid 18:2 n-9F)',
    nutritionTypeCode: 'F18D2N9F',
  },
  {
    nutritionName: 'Fat, Trans (fatty acid 18:2trans n-6)',
    nutritionTypeCode: 'F18D2TN6',
  },
  {
    nutritionName: 'Fat, Trans (fatty acid 18:2trans n-6F)',
    nutritionTypeCode: 'F18D2TN6F',
  },
  { nutritionName: 'Fat (fatty acid 18:3)', nutritionTypeCode: 'F18D3' },
  {
    nutritionName: 'Fat (fatty acid cis, cis, cis 18:3 n-3)',
    nutritionTypeCode: 'F18D3CN3',
  },
  {
    nutritionName:
      'Fat (fatty acid 18:3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D3F',
  },
  { nutritionName: 'Fat (fatty acid 18:3 n-3)', nutritionTypeCode: 'F18D3N3' },
  {
    nutritionName:
      'Fat (fatty acid 18:3 n-3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D3N3F',
  },
  { nutritionName: 'Fat (fatty acid 18:3 n-6)', nutritionTypeCode: 'F18D3N6' },
  {
    nutritionName:
      'Fat (fatty acid 18:3 n-6; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D3N6F',
  },
  { nutritionName: 'Fat (fatty acid 18:4)', nutritionTypeCode: 'F18D4' },
  {
    nutritionName:
      'Fat (fatty acid 18:4; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D4F',
  },
  { nutritionName: 'Fat (fatty acid 18:4 n-3)', nutritionTypeCode: 'F18D4N3' },
  {
    nutritionName:
      'Fat (fatty acid 18:4 n-3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F18D4N3F',
  },
  { nutritionName: 'Fat (fatty acid 19:0)', nutritionTypeCode: 'F19D0' },
  {
    nutritionName:
      'Fat (fatty acid 19:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F19D0F',
  },
  { nutritionName: 'Fat (fatty acid 19:1 n-7)', nutritionTypeCode: 'F19D1N7' },
  {
    nutritionName:
      'Fat (fatty acid 19:1; n-7 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F19D1N7F',
  },
  { nutritionName: 'Fat (fatty acid 20:0)', nutritionTypeCode: 'F20D0' },
  {
    nutritionName:
      'Fat (fatty acid 20:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D0F',
  },
  { nutritionName: 'Fat (fatty acid 20:1)', nutritionTypeCode: 'F20D1' },
  {
    nutritionName:
      'Fat (fatty acid 20:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D1F',
  },
  {
    nutritionName: 'Fat (fatty acid 20:1 n-11)',
    nutritionTypeCode: 'F20D1N11',
  },
  {
    nutritionName:
      'Fat (fatty acid 20:1; n-11 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D1N11F',
  },
  { nutritionName: 'Fat (fatty acid 20:1 n-7)', nutritionTypeCode: 'F20D1N7' },
  {
    nutritionName:
      'Fat (fatty acid 20:1; n-7 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D1N7F',
  },
  { nutritionName: 'Fat (fatty acid 20:1 n-9)', nutritionTypeCode: 'F20D1N9' },
  {
    nutritionName:
      'Fat (fatty acid 20:1; n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D1N9F',
  },
  { nutritionName: 'Fat (fatty acid 20:2)', nutritionTypeCode: 'F20D2' },
  {
    nutritionName: 'Fat (fatty acid cis, cis 20:2 n-6)',
    nutritionTypeCode: 'F20D2CN6',
  },
  {
    nutritionName:
      'Fat (fatty acid 20:2; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D2F',
  },
  { nutritionName: 'Fat (fatty acid 20:2 n-6)', nutritionTypeCode: 'F20D2N6' },
  {
    nutritionName: 'Fat (fatty acid 20:2 n-6F)',
    nutritionTypeCode: 'F20D2N6F',
  },
  { nutritionName: 'Fat (fatty acid 20:3)', nutritionTypeCode: 'F20D3' },
  {
    nutritionName:
      'Fat (fatty acid 20:3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D3F',
  },
  { nutritionName: 'Fat (fatty acid 20:3 n-3)', nutritionTypeCode: 'F20D3N3' },
  {
    nutritionName: 'Fat (fatty acid 20:3 n-3F)',
    nutritionTypeCode: 'F20D3N3F',
  },
  { nutritionName: 'Fat (fatty acid 20:3 n-6)', nutritionTypeCode: 'F20D3N6' },
  {
    nutritionName: 'Fat (fatty acid 20:3 n-6F)',
    nutritionTypeCode: 'F20D3N6F',
  },
  { nutritionName: 'Fat (fatty acid 20:3 n-9)', nutritionTypeCode: 'F20D3N9' },
  {
    nutritionName: 'Fat (fatty acid 20:3 n-9F)',
    nutritionTypeCode: 'F20D3N9F',
  },
  { nutritionName: 'Fat (fatty acid 20:4)', nutritionTypeCode: 'F20D4' },
  {
    nutritionName:
      'Fat (fatty acid 20:4; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D4F',
  },
  { nutritionName: 'Fat (fatty acid 20:4 n-3)', nutritionTypeCode: 'F20D4N3' },
  {
    nutritionName: 'Fat (fatty acid 20:4 n-3F)',
    nutritionTypeCode: 'F20D4N3F',
  },
  { nutritionName: 'Fat (fatty acid 20:4 n-6)', nutritionTypeCode: 'F20D4N6' },
  {
    nutritionName:
      'Fat (fatty acid 20:4 n-6; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D4N6F',
  },
  { nutritionName: 'Fat (fatty acid 20:5)', nutritionTypeCode: 'F20D5' },
  {
    nutritionName:
      'Fat (fatty acid 20:5; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D5F',
  },
  { nutritionName: 'Fat (fatty acid 20:5 n-3)', nutritionTypeCode: 'F20D5N3' },
  {
    nutritionName:
      'Fat (fatty acid 20:5 n-3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F20D5N3F',
  },
  { nutritionName: 'Fat (fatty acid 21:0)', nutritionTypeCode: 'F21D0' },
  {
    nutritionName:
      'Fat (fatty acid 21:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F21D0F',
  },
  { nutritionName: 'Fat (fatty acid 21:5 n-3)', nutritionTypeCode: 'F21D5N3' },
  {
    nutritionName: 'Fat (fatty acid 21:5 n-3F)',
    nutritionTypeCode: 'F21D5N3F',
  },
  { nutritionName: 'Fat (fatty acid 22:0)', nutritionTypeCode: 'F22D0' },
  {
    nutritionName:
      'Fat (fatty acid 22:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D0F',
  },
  { nutritionName: 'Fat (fatty acid 22:1)', nutritionTypeCode: 'F22D1' },
  {
    nutritionName: 'Fat (fatty acid cis 22:1 n-11)',
    nutritionTypeCode: 'F22D1CN11',
  },
  {
    nutritionName: 'Fat (fatty acid cis 22:1 n-9)',
    nutritionTypeCode: 'F22D1CN9',
  },
  {
    nutritionName:
      'Fat (fatty acid 22:1; cis n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D1CN9F',
  },
  {
    nutritionName:
      'Fat (fatty acid 22:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D1F',
  },
  { nutritionName: 'Fat (fatty acid 22:1 n-9)', nutritionTypeCode: 'F22D1N9' },
  {
    nutritionName:
      'Fat (fatty acid 22:1; n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D1N9F',
  },
  {
    nutritionName: 'Fat, Trans (fatty acid trans 22:1 n-9)',
    nutritionTypeCode: 'F22D1TN9',
  },
  {
    nutritionName:
      'Fat, Trans (fatty acid trans 22:1; n-9 expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D1TN9F',
  },
  { nutritionName: 'Fat (fatty acid 22:2)', nutritionTypeCode: 'F22D2' },
  {
    nutritionName:
      'Fat (fatty acid 22:2; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D2F',
  },
  { nutritionName: 'Fat (fatty acid 22:3)', nutritionTypeCode: 'F22D3' },
  {
    nutritionName:
      'Fat (fatty acid 22:3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D3F',
  },
  { nutritionName: 'Fat (fatty acid 22:4)', nutritionTypeCode: 'F22D4' },
  {
    nutritionName:
      'Fat (fatty acid 22:4; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D4F',
  },
  { nutritionName: 'Fat (fatty acid 22:4 n-3)', nutritionTypeCode: 'F22D4N3' },
  {
    nutritionName: 'Fat (fatty acid 22:4 n-3F)',
    nutritionTypeCode: 'F22D4N3F',
  },
  { nutritionName: 'Fat (fatty acid 22:5)', nutritionTypeCode: 'F22D5' },
  {
    nutritionName:
      'Fat (fatty acid 22:5; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D5F',
  },
  { nutritionName: 'Fat (fatty acid 22:5 n-3)', nutritionTypeCode: 'F22D5N3' },
  {
    nutritionName:
      'Fat (fatty acid 22:5 n-3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D5N3F',
  },
  { nutritionName: 'Fat (fatty acid 22:5 n-6)', nutritionTypeCode: 'F22D5N6' },
  {
    nutritionName:
      'Fat (fatty acid 22:5 n-6; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D5N6F',
  },
  { nutritionName: 'Fat (fatty acid 22:6)', nutritionTypeCode: 'F22D6' },
  {
    nutritionName:
      'Fat (fatty acid 22:6; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D6F',
  },
  { nutritionName: 'Fat (fatty acid 22:6 n-3)', nutritionTypeCode: 'F22D6N3' },
  {
    nutritionName:
      'Fat (fatty acid 22:6 n-3; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F22D6N3F',
  },
  { nutritionName: 'Fat (fatty acid 23:0)', nutritionTypeCode: 'F23D0' },
  {
    nutritionName:
      'Fat (fatty acid 23:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F23D0F',
  },
  { nutritionName: 'Fat (fatty acid 23:1)', nutritionTypeCode: 'F23D1' },
  {
    nutritionName:
      'Fat (fatty acid 23:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F23D1F',
  },
  { nutritionName: 'Fat (fatty acid 24:0)', nutritionTypeCode: 'F24D0' },
  {
    nutritionName:
      'Fat (fatty acid 24:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F24D0F',
  },
  { nutritionName: 'Fat (fatty acid 24:1)', nutritionTypeCode: 'F24D1' },
  { nutritionName: 'Fat (fatty acid cis 24:1)', nutritionTypeCode: 'F24D1C' },
  {
    nutritionName:
      'Fat (fatty acid 24:1; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F24D1F',
  },
  {
    nutritionName:
      'Fat (fatty acid 24:6; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F24D6',
  },
  { nutritionName: 'Fat (fatty acid 24:6 n-6)', nutritionTypeCode: 'F24D6F' },
  { nutritionName: 'Fat, Total (fatty acid 4:0)', nutritionTypeCode: 'F4D0' },
  {
    nutritionName:
      'Fat (fatty acid 4:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F4D0F',
  },
  { nutritionName: 'Fat (fatty acid 5:0)', nutritionTypeCode: 'F5D0' },
  {
    nutritionName:
      'Fat (fatty acid 5:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F5D0F',
  },
  { nutritionName: 'Fat (fatty acid 6:0)', nutritionTypeCode: 'F6D0' },
  {
    nutritionName:
      'Fat (fatty acid 6:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F6D0F',
  },
  { nutritionName: 'Fat (fatty acid 8:0)', nutritionTypeCode: 'F8D0' },
  {
    nutritionName:
      'Fat (fatty acid 8:0; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'F8D0F',
  },
  {
    nutritionName: 'Fat, Total (fatty acids, total)',
    nutritionTypeCode: 'FACID',
  },
  { nutritionName: 'Fat (FA, total, cis)', nutritionTypeCode: 'FACIS' },
  {
    nutritionName: 'Omega 3 Acids (FA, total, cis n-3)',
    nutritionTypeCode: 'FACN3',
  },
  {
    nutritionName: 'Omega 6 Acids (FA, total, cis n-6)',
    nutritionTypeCode: 'FACN6',
  },
  { nutritionName: 'Fat (FA, total, cis n-9)', nutritionTypeCode: 'FACN9' },
  {
    nutritionName: 'Fat, Trans (fatty acids, total double trans)',
    nutritionTypeCode: 'FADT',
  },
  {
    nutritionName: 'Fat (fatty acids, total essential)',
    nutritionTypeCode: 'FAESS',
  },
  {
    nutritionName:
      'Fat (fatty acids, total essential; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FAESSF',
  },
  {
    nutritionName: 'Fat (fatty acids, total free)',
    nutritionTypeCode: 'FAFRE',
  },
  {
    nutritionName:
      'Fat (fatty acids, total free; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FAFREF',
  },
  // {
  //   nutritionName: 'Fat, Monounsaturated (fatty acids, total monounsaturated)',
  //   nutritionTypeCode: 'FAMS',
  // },
  {
    nutritionName: 'Fat, Monounsaturated (FA, monounsat.cis)',
    nutritionTypeCode: 'FAMSCIS',
  },
  {
    nutritionName:
      'Fat, Monounsaturated (fatty acids, total monounsaturated; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FAMSF',
  },
  { nutritionName: 'Omega 3 Acids (FA, n-3)', nutritionTypeCode: 'FAN3' },
  { nutritionName: 'Omega 6 Acids (FA, n-6)', nutritionTypeCode: 'FAN6' },
  { nutritionName: 'Fat (FA, total, n-9)', nutritionTypeCode: 'FAN9' },
  {
    nutritionName: 'Fat, Polyunsaturated (FA, polyunsat.cis)',
    nutritionTypeCode: 'FAPUCIS',
  },
  {
    nutritionName:
      'Fat, Polyunsaturated (fatty acids, total polyunsaturated; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FAPUF',
  },
  {
    nutritionName: 'Fat, Polyunsaturated (FA, polyunsat. long chain)',
    nutritionTypeCode: 'FAPULC',
  },
  {
    nutritionName:
      'Fat, Polyunsaturated- Omega 3 Acids (fatty acids, total n-3 polyunsaturated)',
    nutritionTypeCode: 'FAPUN3',
  },
  {
    nutritionName:
      'Fat, Polyunsaturated- Omega 3 Acids (fatty acids, total n-3 polyunsaturated; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FAPUN3F',
  },
  {
    nutritionName:
      'Fat, Polyunsaturated- Omega 3 Acids (FA, polyunsat.n-3, fish)',
    nutritionTypeCode: 'FAPUN3FI',
  },
  {
    nutritionName:
      'Fat, Polyunsaturated- Omega 3 Acids (FA, polyunsat.n-3, vegetables)',
    nutritionTypeCode: 'FAPUN3VE',
  },
  {
    nutritionName:
      'Fat, Polyunsaturated- Omega 6 Acids (fatty acids, total n-6 polyunsaturated)',
    nutritionTypeCode: 'FAPUN6',
  },
  {
    nutritionName:
      'Omega 6 Acids (fatty acids, total n-6 polyunsaturated; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FAPUN6F',
  },
  { nutritionName: 'Fat (FA, polyunsat.cis n-9)', nutritionTypeCode: 'FAPUN9' },
  {
    nutritionName:
      'Fat, Saturated (fatty acids, total saturated; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FASATF',
  },
  { nutritionName: 'Fat, Total (fat, total)', nutritionTypeCode: 'FAT' },
  { nutritionName: 'Fat, Total (fat, unknown)', nutritionTypeCode: 'FAT-' },
  { nutritionName: 'Fat (Fat, animal)', nutritionTypeCode: 'FATAN' },
  { nutritionName: 'Fat (Fat, animal, Old code)', nutritionTypeCode: 'FATCAN' },
  {
    nutritionName:
      'Fat, Total (fat, total; derived by analysis using continuous extraction)',
    nutritionTypeCode: 'FATCE',
  },
  { nutritionName: 'Fat (Fat, plant, Old code)', nutritionTypeCode: 'FATCPL' },
  {
    nutritionName: 'Fat, Trans (FA, total, trans, n-3)',
    nutritionTypeCode: 'FATN3',
  },
  {
    nutritionName: 'Fat, Trans (FA, total, trans n-6)',
    nutritionTypeCode: 'FATN6',
  },
  {
    nutritionName: 'Fat, Trans (FA, total, trans n-9)',
    nutritionTypeCode: 'FATN9',
  },
  {
    nutritionName:
      'Fat, Total (total fat by NLEA definition (triglyceride equivalents of fatty acids))',
    nutritionTypeCode: 'FATNLEA',
  },
  { nutritionName: 'Fat, Plant (Fat, plant)', nutritionTypeCode: 'FATPL' },
  {
    nutritionName:
      'Fat, Trans (fatty acids, total trans; expressed per quantity of total fatty acids)',
    nutritionTypeCode: 'FATRNF',
  },
  {
    nutritionName: 'Fat, Trans (fatty acids, total trans-monoenoic)',
    nutritionTypeCode: 'FATRNM',
  },
  {
    nutritionName: 'Fat, Trans (fatty acids, total trans-polyenoic)',
    nutritionTypeCode: 'FATRNP',
  },
  {
    nutritionName:
      'Fat, Other (Fatty acids, other, non specified from Danish FCT)',
    nutritionTypeCode: 'FAUN',
  },
  { nutritionName: 'Fibrin', nutritionTypeCode: 'FBRN' },
  { nutritionName: 'Iron (Ferrous)', nutritionTypeCode: 'FE2+' },
  { nutritionName: 'Iron (Ferric)', nutritionTypeCode: 'FE3+' },
  { nutritionName: 'Iron (MFP iron)', nutritionTypeCode: 'FEMFP' },
  { nutritionName: 'Iron (ferulic acid)', nutritionTypeCode: 'FERAC' },
  {
    nutritionName: 'Fibre; Method Of Determination Unknown Or Variable',
    nutritionTypeCode: 'FIB_',
  },
  {
    nutritionName: 'Fibre; Determined By Acid Detergent',
    nutritionTypeCode: 'FIBAD',
  },
  {
    nutritionName: 'Fibre, Acid Detergent Method, Clancy',
    nutritionTypeCode: 'FIBADC',
  },
  { nutritionName: 'Fibre, Crude', nutritionTypeCode: 'FIBC' },
  { nutritionName: 'Hexoses In Dietary Fibre', nutritionTypeCode: 'FIBHEX' },
  // { nutritionName: 'Fibre, Water-Insoluble', nutritionTypeCode: 'FIBINS' },
  {
    nutritionName: 'Fibre; Determined By Neutral Detergent',
    nutritionTypeCode: 'FIBND',
  },
  { nutritionName: 'Pentoses In Dietary Fibre', nutritionTypeCode: 'FIBPEN' },
  // { nutritionName: 'Fibre, Water-Soluble', nutritionTypeCode: 'FIBSOL' },
  {
    nutritionName:
      'Dietary Fibre, total (fibre, total dietary; sum of non-starch polysaccharide components and lignin)',
    nutritionTypeCode: 'FIBTS',
  },
  {
    nutritionName:
      'Dietary Fibre, total (fibre, total dietary; Wenlock modification)',
    nutritionTypeCode: 'FIBTSW',
  },
  { nutritionName: 'Folate Unknown', nutritionTypeCode: 'FOL_' },
  // {
  //   nutritionName: 'Folic Acid, Synthetic Folic Acid',
  //   nutritionTypeCode: 'FOLAC',
  // },
  { nutritionName: 'Folate, Conjugated', nutritionTypeCode: 'FOLC' },
  {
    nutritionName: 'Folate, Dietary Folate Equivalents',
    nutritionTypeCode: 'FOLDFE',
  },
  {
    nutritionName: 'Folate Food, Naturally Occuring Food Folates',
    nutritionTypeCode: 'FOLFD',
  },
  { nutritionName: 'Folate, Free', nutritionTypeCode: 'FOLFRE' },
  { nutritionName: 'Folate, Sum Vitamers', nutritionTypeCode: 'FOLSUM' },
  { nutritionName: 'Formic Acid', nutritionTypeCode: 'FORAC' },
  { nutritionName: 'Formononetin', nutritionTypeCode: 'FORMO' },
  { nutritionName: 'Fructose In Dietary Fibre', nutritionTypeCode: 'FRUFB' },
  { nutritionName: 'Fructooligosaccharide', nutritionTypeCode: 'FRUOLSAC' },
  {
    nutritionName: 'Fructooligosaccharide; Monosacch. Eq',
    nutritionTypeCode: 'FRUOLSACM',
  },
  { nutritionName: 'Fructan', nutritionTypeCode: 'FRUTN' },
  { nutritionName: 'Fructan; Monosacch. Eq', nutritionTypeCode: 'FRUTNM' },
  { nutritionName: 'Fucose', nutritionTypeCode: 'FUCOS' },
  { nutritionName: 'Fucosterol', nutritionTypeCode: 'FUCSTR' },
  { nutritionName: '28-Iso-Fucosterol', nutritionTypeCode: 'FUCSTR28' },
  { nutritionName: 'Fumaric Acid', nutritionTypeCode: 'FUMAC' },
  { nutritionName: 'Carbon Monoxide', nutritionTypeCode: 'G_CMO' },
  { nutritionName: 'Nicotine', nutritionTypeCode: 'G_NICT' },
  { nutritionName: 'Non-milk Extrinsic Sugars', nutritionTypeCode: 'G_NMES' },
  { nutritionName: 'Tar', nutritionTypeCode: 'G_TAR' },
  { nutritionName: 'Galacturonic Acid', nutritionTypeCode: 'GALAAC' },
  { nutritionName: 'Gallocatechin', nutritionTypeCode: 'GALCATEGC' },
  { nutritionName: 'Galactose In Dietary Fibre', nutritionTypeCode: 'GALFB' },
  { nutritionName: 'Gallic Acid', nutritionTypeCode: 'GALLAC' },
  { nutritionName: 'Galactomannan', nutritionTypeCode: 'GALMN' },
  {
    nutritionName: 'Galactomannan; Monosacch. Eq',
    nutritionTypeCode: 'GALMNM',
  },
  { nutritionName: 'Galactose', nutritionTypeCode: 'GALS' },
  { nutritionName: 'Galactitol', nutritionTypeCode: 'GALTL' },
  { nutritionName: 'Galactan', nutritionTypeCode: 'GALTN' },
  {
    nutritionName: 'Galacturonic Acid D-Galacturonic Acid',
    nutritionTypeCode: 'GALURAC',
  },
  { nutritionName: 'Glutamine', nutritionTypeCode: 'GLN' },
  {
    nutritionName: 'Glutamine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'GLNN',
  },
  {
    nutritionName: 'Glutamine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'GLNP',
  },
  { nutritionName: 'Globulin', nutritionTypeCode: 'GLOB' },
  { nutritionName: 'Glutamic Acid', nutritionTypeCode: 'GLU' },
  { nutritionName: 'Alpha-Keto-Glutaric Acid', nutritionTypeCode: 'GLUAKAC' },
  { nutritionName: 'Gluconic Acid', nutritionTypeCode: 'GLUCAC' },
  { nutritionName: 'Betaglucan', nutritionTypeCode: 'GLUCNB' },
  { nutritionName: 'Glucose In Dietary Fibre', nutritionTypeCode: 'GLUFB' },
  { nutritionName: 'Glucomannan', nutritionTypeCode: 'GLUMN' },
  { nutritionName: 'Glucomannan; Monosacch. Eq', nutritionTypeCode: 'GLUMNM' },
  {
    nutritionName: 'Glutamic Acid; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'GLUN',
  },
  {
    nutritionName: 'Glutamic Acid; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'GLUP',
  },
  { nutritionName: 'Glucose', nutritionTypeCode: 'GLUS' },
  { nutritionName: 'Gluten', nutritionTypeCode: 'GLUTN' },
  { nutritionName: 'Glycine', nutritionTypeCode: 'GLY' },
  { nutritionName: 'Glycogen', nutritionTypeCode: 'GLYC' },
  {
    nutritionName:
      'Glycerides, Total; Method Of Determination Unknown Or Variable',
    nutritionTypeCode: 'GLYCER_',
  },
  {
    nutritionName: 'Glycerides, Total; Determined By Analysis',
    nutritionTypeCode: 'GLYCERA',
  },
  {
    nutritionName: 'Glycerides, Total; Calculated From Fatty Acid Composition',
    nutritionTypeCode: 'GLYCERC',
  },
  { nutritionName: 'Glycolic Acid', nutritionTypeCode: 'GLYCLAC' },
  {
    nutritionName: 'Glycogen; Expressed In Monosaccharide',
    nutritionTypeCode: 'GLYCM',
  },
  { nutritionName: 'Glycitein', nutritionTypeCode: 'GLYCTEIN' },
  { nutritionName: 'Glycitin', nutritionTypeCode: 'GLYCTN' },
  { nutritionName: 'Acetyl-Glycitin', nutritionTypeCode: 'GLYCTNA' },
  { nutritionName: 'Malonyl-Glycitin', nutritionTypeCode: 'GLYCTNM' },
  { nutritionName: 'Glycolipids, Total', nutritionTypeCode: 'GLYLIP' },
  {
    nutritionName: 'Glycine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'GLYN',
  },
  {
    nutritionName: 'Glycine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'GLYP',
  },
  { nutritionName: 'Glycerol', nutritionTypeCode: 'GLYRL' },
  { nutritionName: 'Genistein', nutritionTypeCode: 'GNSTEIN' },
  { nutritionName: 'Genistin', nutritionTypeCode: 'GNSTIN' },
  { nutritionName: 'Acetyl-Genistin', nutritionTypeCode: 'GNSTINA' },
  { nutritionName: 'Malonyl-Genistin', nutritionTypeCode: 'GNSTINM' },
  { nutritionName: 'Glycerophosphocholine', nutritionTypeCode: 'GPCHOLN' },
  { nutritionName: 'Di-Keto-Cholanic Acid', nutritionTypeCode: 'GULDKAC' },
  { nutritionName: 'Gums', nutritionTypeCode: 'GUMS' },
  { nutritionName: 'Iron, Haem', nutritionTypeCode: 'HAEM' },
  { nutritionName: 'Hemoglobin', nutritionTypeCode: 'HB' },
  { nutritionName: 'Hemicellulose', nutritionTypeCode: 'HEMCEL' },
  { nutritionName: 'Hesperidin', nutritionTypeCode: 'HESPD' },
  { nutritionName: 'Hesperetin', nutritionTypeCode: 'HESPT' },
  { nutritionName: 'Hexosan', nutritionTypeCode: 'HEXSN' },
  { nutritionName: 'Mercury', nutritionTypeCode: 'HG' },
  { nutritionName: 'Mercury 1+', nutritionTypeCode: 'HG1+' },
  { nutritionName: 'Mercury 2+', nutritionTypeCode: 'HG2+' },
  { nutritionName: 'Methyl Mercury', nutritionTypeCode: 'HGME' },
  { nutritionName: 'Histidine', nutritionTypeCode: 'HIS' },
  {
    nutritionName: 'Histidine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'HISN',
  },
  {
    nutritionName: 'Histidine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'HISP',
  },
  { nutritionName: 'Histamine', nutritionTypeCode: 'HISTN' },
  { nutritionName: 'Hydroxylysine', nutritionTypeCode: 'HYL' },
  { nutritionName: 'Hydroxyproline', nutritionTypeCode: 'HYP' },
  {
    nutritionName: 'Hydroxyproline; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'HYPN',
  },
  {
    nutritionName: 'Hydroxyproline; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'HYPP',
  },
  // { nutritionName: 'Iodide', nutritionTypeCode: 'ID' },
  { nutritionName: 'Iodide 131', nutritionTypeCode: 'ID131' },
  { nutritionName: 'Isoleucine', nutritionTypeCode: 'ILE' },
  {
    nutritionName: 'Isoleucine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'ILEN',
  },
  {
    nutritionName: 'Isoleucine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'ILEP',
  },
  { nutritionName: 'Inositol', nutritionTypeCode: 'INOTL' },
  { nutritionName: 'Inulin', nutritionTypeCode: 'INULN' },
  { nutritionName: 'Isocitric Acid', nutritionTypeCode: 'ISOCAC' },
  { nutritionName: 'Total Isoflavonoids', nutritionTypeCode: 'ISOFLVND' },
  { nutritionName: 'Isomalt', nutritionTypeCode: 'ISOMALT' },
  { nutritionName: 'Kaempferol', nutritionTypeCode: 'KAEMF' },
  { nutritionName: 'Lanthanum', nutritionTypeCode: 'LA' },
  { nutritionName: 'Lactic Acid', nutritionTypeCode: 'LACAC' },
  { nutritionName: 'D-Lactic Acid', nutritionTypeCode: 'LACACD' },
  { nutritionName: 'L-Lactic Acid', nutritionTypeCode: 'LACACL' },
  { nutritionName: 'Lactose', nutritionTypeCode: 'LACS' },
  {
    nutritionName: 'Lactose; Expressed In Monosaccharide',
    nutritionTypeCode: 'LACSM',
  },
  { nutritionName: 'Lactitol', nutritionTypeCode: 'LACTL' },
  { nutritionName: 'Leucine', nutritionTypeCode: 'LEU' },
  {
    nutritionName: 'Leucine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'LEUN',
  },
  {
    nutritionName: 'Leucine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'LEUP',
  },
  { nutritionName: 'Lithium', nutritionTypeCode: 'LI' },
  { nutritionName: 'Lignin', nutritionTypeCode: 'LIGN' },
  { nutritionName: 'Lignans, Total', nutritionTypeCode: 'LIGNANS' },
  { nutritionName: 'Luteolin', nutritionTypeCode: 'LUTEOL' },
  { nutritionName: 'Lutein', nutritionTypeCode: 'LUTN' },
  { nutritionName: 'Lycopene', nutritionTypeCode: 'LYCPN' },
  { nutritionName: 'Lysine', nutritionTypeCode: 'LYS' },
  {
    nutritionName: 'Lysine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'LYSN',
  },
  {
    nutritionName: 'Lysine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'LYSP',
  },
  {
    nutritionName:
      'Malonic acid from Slovak table. propanedioic acid, methanedicarboxylic acid',
    nutritionTypeCode: 'MALAC',
  },
  { nutritionName: 'Maltose', nutritionTypeCode: 'MALS' },
  {
    nutritionName: 'Maltose; Expressed In Monosaccharide',
    nutritionTypeCode: 'MALSM',
  },
  { nutritionName: 'Maltodextrin', nutritionTypeCode: 'MALTDEX' },
  { nutritionName: 'Maltodextrin M', nutritionTypeCode: 'MALTDEXM' },
  { nutritionName: 'Maltitol', nutritionTypeCode: 'MALTL' },
  { nutritionName: 'Maltotriose', nutritionTypeCode: 'MALTRS' },
  {
    nutritionName: 'Maltotriose; Expressed In Monosaccharide',
    nutritionTypeCode: 'MALTRSM',
  },
  { nutritionName: 'Malvidin', nutritionTypeCode: 'MALVIDIN' },
  { nutritionName: 'Mannose', nutritionTypeCode: 'MAN' },
  { nutritionName: 'Mannose In Dietary Fibre', nutritionTypeCode: 'MANFB' },
  { nutritionName: 'Mannan', nutritionTypeCode: 'MANN' },
  { nutritionName: 'Mannan; Monosacch. Eq', nutritionTypeCode: 'MANNM' },
  { nutritionName: 'Mannitol', nutritionTypeCode: 'MANTL' },
  { nutritionName: 'Matairesinol', nutritionTypeCode: 'MATAIRES' },
  { nutritionName: 'Myoglobin', nutritionTypeCode: 'MB' },
  {
    nutritionName: 'Ethanolamines, Total Emulsifier',
    nutritionTypeCode: 'MEA',
  },
  { nutritionName: 'Methionine', nutritionTypeCode: 'MET' },
  { nutritionName: 'Methyl Alcohol', nutritionTypeCode: 'METH' },
  {
    nutritionName: 'Methionine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'METN',
  },
  {
    nutritionName: 'Methionine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'METP',
  },
  { nutritionName: 'Monoglycerides, Total', nutritionTypeCode: 'MGLY' },
  { nutritionName: 'Menaquinone-10', nutritionTypeCode: 'MK10' },
  { nutritionName: 'Menaquinone-11', nutritionTypeCode: 'MK11' },
  { nutritionName: 'Menaquinone-12', nutritionTypeCode: 'MK12' },
  { nutritionName: 'Menaquinone-13', nutritionTypeCode: 'MK13' },
  { nutritionName: 'Menaquinone-4', nutritionTypeCode: 'MK4' },
  { nutritionName: 'Menaquinone-5', nutritionTypeCode: 'MK5' },
  { nutritionName: 'Menaquinone-6', nutritionTypeCode: 'MK6' },
  { nutritionName: 'Menaquinone-7', nutritionTypeCode: 'MK7' },
  { nutritionName: 'Menaquinone-8', nutritionTypeCode: 'MK8' },
  { nutritionName: 'Menaquinone-9', nutritionTypeCode: 'MK9' },
  { nutritionName: 'Manganese', nutritionTypeCode: 'MN' },
  { nutritionName: 'Manganese 2+', nutritionTypeCode: 'MN2+' },
  { nutritionName: 'Manganese3+', nutritionTypeCode: 'MN3+' },
  { nutritionName: 'Monosaccharides, Total', nutritionTypeCode: 'MNSAC' },
  { nutritionName: 'Molybdenum', nutritionTypeCode: 'MO' },
  { nutritionName: 'Molybdenum 4+', nutritionTypeCode: 'MO4+' },
  { nutritionName: 'Molybdenum 6+', nutritionTypeCode: 'MO6+' },
  {
    nutritionName: 'Malonic acid from Slovak table.',
    nutritionTypeCode: 'MOLAC',
  },
  { nutritionName: 'Mucilages', nutritionTypeCode: 'MUCIL' },
  { nutritionName: 'Myricetin', nutritionTypeCode: 'MYRIC' },
  { nutritionName: 'Sodium', nutritionTypeCode: 'NA' },
  { nutritionName: 'Salt', nutritionTypeCode: 'NACL' },
  { nutritionName: 'Nitrogen, Amino', nutritionTypeCode: 'NAM' },
  { nutritionName: 'Naringin', nutritionTypeCode: 'NARIN' },
  { nutritionName: 'Naringenin', nutritionTypeCode: 'NARING' },
  { nutritionName: 'Narirutin', nutritionTypeCode: 'NARIR' },
  { nutritionName: 'Iron, Non-Haem', nutritionTypeCode: 'NHAEM' },
  { nutritionName: 'Nickel', nutritionTypeCode: 'NI' },
  { nutritionName: 'Nickel 2+', nutritionTypeCode: 'NI2+' },
  { nutritionName: 'Nickel 3+', nutritionTypeCode: 'NI3+' },
  { nutritionName: 'Niacin, Available', nutritionTypeCode: 'NIAAVL' },
  { nutritionName: 'Niacin Equivalents, Total', nutritionTypeCode: 'NIAEQ' },
  {
    nutritionName: 'Niacin Equivalents From Tryptophan',
    nutritionTypeCode: 'NIATRP',
  },
  { nutritionName: 'Nitrates', nutritionTypeCode: 'NITRA' },
  { nutritionName: 'Nitrites', nutritionTypeCode: 'NITRI' },
  { nutritionName: 'Nitrosamine, Total', nutritionTypeCode: 'NITRN' },
  { nutritionName: 'Nitrosamine, Non-Volatile', nutritionTypeCode: 'NITRNN' },
  { nutritionName: 'Nitrosamine, Volatile', nutritionTypeCode: 'NITRNV' },
  { nutritionName: 'Nitrogen, Non-Protein', nutritionTypeCode: 'NNP' },
  { nutritionName: 'Nitrogen-Protein Ratio', nutritionTypeCode: 'NPR' },
  { nutritionName: 'Nitrogen, Protein', nutritionTypeCode: 'NPRO' },
  { nutritionName: 'Net Protein Utilization', nutritionTypeCode: 'NPU' },
  { nutritionName: 'Polysaccharides (NSP; Englyst)', nutritionTypeCode: 'NSP' },
  { nutritionName: 'Nitrogen, Total', nutritionTypeCode: 'NT' },
  { nutritionName: 'Organic Acids, Total', nutritionTypeCode: 'OA' },
  {
    nutritionName: 'Oligosaccharides, Total Available',
    nutritionTypeCode: 'OLSAC',
  },
  {
    nutritionName:
      'Oligosaccharides, Total Available; Expressed In Monosaccharide Equivalents',
    nutritionTypeCode: 'OLSACM',
  },
  { nutritionName: 'Ornithine', nutritionTypeCode: 'ORN' },
  { nutritionName: 'Oxaloacetic Acid', nutritionTypeCode: 'OXACAC' },
  { nutritionName: 'Oxalic Acid', nutritionTypeCode: 'OXALAC' },
  { nutritionName: 'Parahydrobenzoic Acid', nutritionTypeCode: 'PARHBAC' },
  { nutritionName: 'Lead', nutritionTypeCode: 'PB' },
  { nutritionName: 'Lead 2+', nutritionTypeCode: 'PB2+' },
  { nutritionName: 'Lead 4+', nutritionTypeCode: 'PB4+' },
  { nutritionName: 'Phosphocholine', nutritionTypeCode: 'PCHOLN' },
  { nutritionName: 'Pectin', nutritionTypeCode: 'PECT' },
  { nutritionName: 'Pentosan', nutritionTypeCode: 'PENSN' },
  { nutritionName: 'Peonidin', nutritionTypeCode: 'PEONIDIN' },
  { nutritionName: 'Protein Efficiency Ratio', nutritionTypeCode: 'PER' },
  { nutritionName: 'Petunidin', nutritionTypeCode: 'PETUNIDIN' },
  { nutritionName: 'Ph, Hydrogen Ion Concentration', nutritionTypeCode: 'PH' },
  { nutritionName: 'Phenylalanine', nutritionTypeCode: 'PHE' },
  {
    nutritionName: 'Phenylalanine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'PHEN',
  },
  {
    nutritionName: 'Phenylalanine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'PHEP',
  },
  { nutritionName: 'Phenylethylamine', nutritionTypeCode: 'PHETN' },
  { nutritionName: 'Phosphatidic Acid', nutritionTypeCode: 'PHOA' },
  { nutritionName: 'Phosphatidylethanolamine', nutritionTypeCode: 'PHOETHN' },
  { nutritionName: 'Phosphatidylinositol', nutritionTypeCode: 'PHOINOTL' },
  { nutritionName: 'Phospholipids, Total', nutritionTypeCode: 'PHOLIP' },
  { nutritionName: 'Phosphatidylserine', nutritionTypeCode: 'PHOSER' },
  { nutritionName: 'Phytosterols, Total', nutritionTypeCode: 'PHYSTR' },
  { nutritionName: 'Phytic Acid', nutritionTypeCode: 'PHYTAC' },
  { nutritionName: 'Phytosterol, Unsat.', nutritionTypeCode: 'PHYTSTRU' },
  { nutritionName: 'Piperine', nutritionTypeCode: 'PIPN' },
  { nutritionName: 'Plasmalogen', nutritionTypeCode: 'PLG' },
  { nutritionName: 'Polyols, Tot.', nutritionTypeCode: 'POLYL' },
  { nutritionName: 'Polysaccharides, Total', nutritionTypeCode: 'POLYSAC' },
  { nutritionName: 'Proline', nutritionTypeCode: 'PRO' },
  // {
  //   nutritionName:
  //     'Protein, Total; Method Of Determination Unknown Or Variable',
  //   nutritionTypeCode: 'PRO_',
  // },
  {
    nutritionName: 'Protein, Total; Determined By Direct Analysis',
    nutritionTypeCode: 'PROA',
  },
  { nutritionName: 'Protein From Animal Sources', nutritionTypeCode: 'PROANI' },
  {
    nutritionName: 'Protein, Total; Calculated From Amino',
    nutritionTypeCode: 'PROCNA',
  },
  {
    nutritionName: 'Protein, Total; Calculated From Protein',
    nutritionTypeCode: 'PROCNP',
  },
  {
    nutritionName: 'Protein, Total; Calculated From Total Nitrogen',
    nutritionTypeCode: 'PROCNT',
  },
  { nutritionName: 'Procyanidins, Tot.', nutritionTypeCode: 'PROCYA' },
  {
    nutritionName: 'Proline; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'PRON',
  },
  {
    nutritionName: 'Proline; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'PROP',
  },
  { nutritionName: 'Propionic Acid', nutritionTypeCode: 'PROPAC' },
  { nutritionName: 'Protein From Plant Sources', nutritionTypeCode: 'PROPLA' },
  {
    nutritionName: 'Protein, Tot., Cal. From Tot.Nitro.',
    nutritionTypeCode: 'PROT',
  },
  { nutritionName: 'Protein, Animal', nutritionTypeCode: 'PROTAN' },
  {
    nutritionName: 'Mfp Protein (Meat, Fish, Poultry',
    nutritionTypeCode: 'PROTMFP',
  },
  { nutritionName: 'Protein, Plant', nutritionTypeCode: 'PROTPL' },
  { nutritionName: 'Polysaccharides, Algal', nutritionTypeCode: 'PSACALG' },
  {
    nutritionName: 'Polysaccharides, Non-Cellulosic',
    nutritionTypeCode: 'PSACNC',
  },
  {
    nutritionName: 'Polysaccharides, Non-Cellulosic, Water-Insoluble',
    nutritionTypeCode: 'PSACNCI',
  },
  {
    nutritionName: 'Polysaccharides, Non-Cellulosic, Water-Soluble',
    nutritionTypeCode: 'PSACNCS',
  },
  { nutritionName: 'Polysaccharides, Non-Starch', nutritionTypeCode: 'PSACNS' },
  {
    nutritionName: 'Polysaccharides, Non-Starch, Water-Insoluble',
    nutritionTypeCode: 'PSACNSI',
  },
  {
    nutritionName: 'Polysaccharides, Non-Starch, Water-Soluble',
    nutritionTypeCode: 'PSACNSS',
  },
  { nutritionName: 'Plutonium 239', nutritionTypeCode: 'PU239' },
  { nutritionName: 'Polyuronic Acids', nutritionTypeCode: 'PURAC' },
  { nutritionName: 'Purines', nutritionTypeCode: 'PURN' },
  { nutritionName: 'Putrescine', nutritionTypeCode: 'PUTRSC' },
  {
    nutritionName: 'Pyruvic a+D705:E1002cid Propanoic acid, BTS;',
    nutritionTypeCode: 'PYRAC',
  },
  { nutritionName: 'Pyruvic acid USDA 247, SFK', nutritionTypeCode: 'PYRUAC' },
  { nutritionName: 'Pyridoxal', nutritionTypeCode: 'PYRXL' },
  { nutritionName: 'Pyridoxamine', nutritionTypeCode: 'PYRXM' },
  { nutritionName: 'Pyridoxine', nutritionTypeCode: 'PYRXN' },
  { nutritionName: 'Pyridoxine Hcl', nutritionTypeCode: 'PYRXNHCL' },
  { nutritionName: 'Quercetin', nutritionTypeCode: 'QUERCE' },
  { nutritionName: 'Quinic Acid', nutritionTypeCode: 'QUINAC' },
  { nutritionName: 'Raffinose', nutritionTypeCode: 'RAFS' },
  {
    nutritionName: 'Raffinose, Expressed In Monosaccharide Equivalents',
    nutritionTypeCode: 'RAFSM',
  },
  { nutritionName: 'Rubidium', nutritionTypeCode: 'RB' },
  { nutritionName: 'Refuse', nutritionTypeCode: 'REFUSE' },
  { nutritionName: 'Retinal', nutritionTypeCode: 'RETAL' },
  { nutritionName: 'Retinaldehyde', nutritionTypeCode: 'RETALD' },
  { nutritionName: 'Retinoic Acid', nutritionTypeCode: 'RETINAC' },
  { nutritionName: 'Retinol', nutritionTypeCode: 'RETOL' },
  { nutritionName: '13-Cis Retinol', nutritionTypeCode: 'RETOL13' },
  { nutritionName: 'Dehydroretinol', nutritionTypeCode: 'RETOLDH' },
  {
    nutritionName: 'All-Trans Retinol Equivalent',
    nutritionTypeCode: 'RETOLEQ',
  },
  { nutritionName: 'Rhamnose In Dietary Fibre', nutritionTypeCode: 'RHAFB' },
  { nutritionName: 'Rhamnose', nutritionTypeCode: 'RHAS' },
  { nutritionName: 'Riboflavin', nutritionTypeCode: 'RIBF' },
  { nutritionName: 'Ribose', nutritionTypeCode: 'RIBS' },
  { nutritionName: 'Rubner Energy Cf Choav', nutritionTypeCode: 'RUBA' },
  { nutritionName: 'Rubner Energy Cf Cho Total', nutritionTypeCode: 'RUBT' },
  { nutritionName: 'Rutin', nutritionTypeCode: 'RUTIN' },
  { nutritionName: 'Sulphur', nutritionTypeCode: 'S' },
  { nutritionName: 'Sulfur 4+', nutritionTypeCode: 'S4+' },
  { nutritionName: 'Sulfur 6+', nutritionTypeCode: 'S6+' },
  { nutritionName: 'Na-Saccharin', nutritionTypeCode: 'SACCNA' },
  { nutritionName: 'Salicylic Acid', nutritionTypeCode: 'SALAC' },
  { nutritionName: 'Antimony', nutritionTypeCode: 'SB' },
  { nutritionName: 'Selenium 4+', nutritionTypeCode: 'SE4+' },
  { nutritionName: 'Selenium 6+', nutritionTypeCode: 'SE6+' },
  { nutritionName: 'Secoisolarisiresinol', nutritionTypeCode: 'SECORES' },
  { nutritionName: 'Selenium, Inorganic', nutritionTypeCode: 'SEIO' },
  { nutritionName: 'Selenium, Organic', nutritionTypeCode: 'SEO' },
  { nutritionName: 'Serine', nutritionTypeCode: 'SER' },
  {
    nutritionName: 'Serine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'SERN',
  },
  { nutritionName: 'Serotonin', nutritionTypeCode: 'SEROTN' },
  {
    nutritionName: 'Serine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'SERP',
  },
  { nutritionName: 'Shikimic Acid', nutritionTypeCode: 'SHIKAC' },
  { nutritionName: 'Silicon', nutritionTypeCode: 'SI' },
  { nutritionName: 'Silicon 2+', nutritionTypeCode: 'SI2+' },
  { nutritionName: 'Silicon 4+', nutritionTypeCode: 'SI4+' },
  { nutritionName: 'Sitosterol', nutritionTypeCode: 'SITSTR' },
  { nutritionName: 'Tin', nutritionTypeCode: 'SN' },
  { nutritionName: 'Solids, Total', nutritionTypeCode: 'SOLID' },
  { nutritionName: 'Other Solids', nutritionTypeCode: 'SOLO' },
  { nutritionName: 'Sorbic Acid', nutritionTypeCode: 'SORAC' },
  { nutritionName: 'Sorbitol', nutritionTypeCode: 'SORTL' },
  { nutritionName: 'Spermindine', nutritionTypeCode: 'SPERDN' },
  { nutritionName: 'Spermine', nutritionTypeCode: 'SPERN' },
  { nutritionName: 'Sphingolipid', nutritionTypeCode: 'SPHL' },
  { nutritionName: 'Spinasterol', nutritionTypeCode: 'SPISTR' },
  { nutritionName: 'Strontium', nutritionTypeCode: 'SR' },
  { nutritionName: 'Strontium 90', nutritionTypeCode: 'SR90' },
  { nutritionName: 'Starch, Part. Hydrolysed', nutritionTypeCode: 'STAHY' },
  {
    nutritionName: 'Starch, Part. Hydrolysed; Monosacch. Eq',
    nutritionTypeCode: 'STAHYM',
  },
  { nutritionName: 'Starch, Modified', nutritionTypeCode: 'STAMO' },
  {
    nutritionName: 'Starch, Modif.; Monosacch. Eq',
    nutritionTypeCode: 'STAMOM',
  },
  { nutritionName: 'Starch, Total', nutritionTypeCode: 'STARCH' },
  {
    nutritionName: 'Starch, Avl, Expression Unknown',
    nutritionTypeCode: 'STARCH_',
  },
  {
    nutritionName: 'Starch, Total; Expressed In Monosaccharide Equivalents',
    nutritionTypeCode: 'STARCHM',
  },
  { nutritionName: 'Starch, Resistant', nutritionTypeCode: 'STARES' },
  { nutritionName: 'Starch, Resist. Rs1', nutritionTypeCode: 'STARES1' },
  { nutritionName: 'Starch, Resist. Rs2', nutritionTypeCode: 'STARES2' },
  { nutritionName: 'Starch, Resist.Rs3', nutritionTypeCode: 'STARES3' },
  { nutritionName: 'Starch, Resist. Rs4', nutritionTypeCode: 'STARES4' },
  { nutritionName: 'Stachyose', nutritionTypeCode: 'STAS' },
  { nutritionName: 'Stachyose; Monosacch. Eq', nutritionTypeCode: 'STASM' },
  { nutritionName: 'Sterols, Other', nutritionTypeCode: 'STEOTH' },
  { nutritionName: 'Sterols, Free', nutritionTypeCode: 'STERFRE' },
  { nutritionName: 'Sterols, Total', nutritionTypeCode: 'STERT' },
  {
    nutritionName: 'Stigmasterol (stigmasterol )',
    nutritionTypeCode: 'STGSTR',
  },
  {
    nutritionName: 'Stigmasterol (delta 7-stigmasterol)',
    nutritionTypeCode: 'STID7',
  },
  {
    nutritionName: 'Stigmasterol (delta 7911-stigmastadienol)',
    nutritionTypeCode: 'STID7911',
  },
  { nutritionName: 'Succinic Acid', nutritionTypeCode: 'SUCAC' },
  { nutritionName: 'Sucrose', nutritionTypeCode: 'SUCS' },
  {
    nutritionName: 'Sucrose; Expressed In Monosaccharide',
    nutritionTypeCode: 'SUCSM',
  },
  { nutritionName: 'Sugar, Added', nutritionTypeCode: 'SUGAD' },
  { nutritionName: 'Sugars, Natural', nutritionTypeCode: 'SUGAN' },
  { nutritionName: 'Sugars, Total', nutritionTypeCode: 'SUGAR' },
  {
    nutritionName: 'Sugars, Tot, Expression Unknown',
    nutritionTypeCode: 'SUGAR_',
  },
  {
    nutritionName: 'Sugars, Total; Expressed In Monosaccharide',
    nutritionTypeCode: 'SUGARM',
  },
  { nutritionName: 'Invert Sugar', nutritionTypeCode: 'SUGIN' },
  { nutritionName: 'Sugars, Non-Reducing', nutritionTypeCode: 'SUGNRD' },
  { nutritionName: 'Sugars, Reducing', nutritionTypeCode: 'SUGRD' },
  { nutritionName: 'Tannins', nutritionTypeCode: 'TAN' },
  { nutritionName: 'Tangeretin', nutritionTypeCode: 'TANGER' },
  { nutritionName: 'Tartaric Acid', nutritionTypeCode: 'TARAC' },
  { nutritionName: 'Taurine', nutritionTypeCode: 'TAU' },
  { nutritionName: 'Taurine Based On Nitrogen', nutritionTypeCode: 'TAUN' },
  { nutritionName: 'Triglycerides, Total', nutritionTypeCode: 'TGLY' },
  { nutritionName: 'Theobromine', nutritionTypeCode: 'THEBRN' },
  { nutritionName: 'Tetrahydrofolate', nutritionTypeCode: 'THF' },
  { nutritionName: 'Threonine', nutritionTypeCode: 'THR' },
  {
    nutritionName: 'Threonine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'THRN',
  },
  {
    nutritionName: 'Threonine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'THRP',
  },
  { nutritionName: 'Titanum', nutritionTypeCode: 'TI' },
  { nutritionName: 'Alpha-Tocopherol', nutritionTypeCode: 'TOCPHA' },
  { nutritionName: 'Beta-Tocopherol', nutritionTypeCode: 'TOCPHB' },
  { nutritionName: 'Delta-Tocopherol', nutritionTypeCode: 'TOCPHD' },
  { nutritionName: 'Gamma-Tocopherol', nutritionTypeCode: 'TOCPHG' },
  { nutritionName: 'Total Tocopheral', nutritionTypeCode: 'TOCPHT' },
  { nutritionName: 'Alpha-Tocotrienol', nutritionTypeCode: 'TOCTRA' },
  { nutritionName: 'Beta-Tocotrienol', nutritionTypeCode: 'TOCTRB' },
  { nutritionName: 'Delta-Tocotrienol', nutritionTypeCode: 'TOCTRD' },
  { nutritionName: 'Gamma-Tocotrienol', nutritionTypeCode: 'TOCTRG' },
  { nutritionName: 'Tocotrienols, Total', nutritionTypeCode: 'TOCTRT' },
  { nutritionName: 'Trehalose', nutritionTypeCode: 'TRES' },
  { nutritionName: 'Tryptophan', nutritionTypeCode: 'TRP' },
  {
    nutritionName: 'Tryptophan; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'TRPN',
  },
  {
    nutritionName: 'Tryptophan; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'TRPP',
  },
  { nutritionName: 'Tryptamine', nutritionTypeCode: 'TRYPN' },
  { nutritionName: 'Tyrosine', nutritionTypeCode: 'TYR' },
  { nutritionName: 'Tyramine', nutritionTypeCode: 'TYRA' },
  {
    nutritionName: 'Tyrosine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'TYRN',
  },
  {
    nutritionName: 'Tyrosine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'TYRP',
  },
  { nutritionName: 'Unsaponifiable Matter', nutritionTypeCode: 'UNSAP' },
  { nutritionName: 'Vanadium', nutritionTypeCode: 'V' },
  { nutritionName: 'Vanadium 4+', nutritionTypeCode: 'V4+' },
  { nutritionName: 'Vanadium 5+', nutritionTypeCode: 'V5+' },
  { nutritionName: 'Valine', nutritionTypeCode: 'VAL' },
  {
    nutritionName: 'Valine; Expressed Per Quantity Of Nitrogen',
    nutritionTypeCode: 'VALN',
  },
  {
    nutritionName: 'Valine; Expressed Per Quantity Of Protein',
    nutritionTypeCode: 'VALP',
  },
  { nutritionName: 'Verbascose', nutritionTypeCode: 'VERS' },
  { nutritionName: 'Verbascose; Monosacch. Eq', nutritionTypeCode: 'VERSM' },
  {
    nutritionName: 'Vitamin A; Method Of Determination Unknown',
    nutritionTypeCode: 'VITA_',
  },
  {
    nutritionName: 'Vitamin A; Determined By Bioassay',
    nutritionTypeCode: 'VITAA',
  },
  { nutritionName: 'Vitamin A Acetate', nutritionTypeCode: 'VITAACT' },
  { nutritionName: 'Vitamin A Palmitate', nutritionTypeCode: 'VITAPAL' },
  {
    nutritionName: 'Vitamin B-6, Total; Determined By Analysis',
    nutritionTypeCode: 'VITB6A',
  },
  {
    nutritionName: 'Vitamin B-6, Total; Calculated By Summation',
    nutritionTypeCode: 'VITB6C',
  },
  { nutritionName: 'Vitamin C (Unknown)', nutritionTypeCode: 'VITC_' },
  {
    nutritionName:
      'Vitamin D (vitamin D; method of determination unknown or variable)',
    nutritionTypeCode: 'VITD_',
  },
  {
    nutritionName: 'Vitamin D (vitamin D; determined by bioassay)',
    nutritionTypeCode: 'VITDA',
  },
  {
    nutritionName: 'Vitamin D3 (Vit D3 + 5 x 25 hydroxyD2)',
    nutritionTypeCode: 'VITDEQ',
  },
  {
    nutritionName:
      'Vitamin E, Method Of Determination Unknown Or Variable; Expressed As Alpha- Tocopherol Equivalents.',
    nutritionTypeCode: 'VITE_',
  },
  {
    nutritionName: 'Vitamin E; Determined By Bioassay',
    nutritionTypeCode: 'VITEA',
  },
  { nutritionName: 'Vitamin K-1', nutritionTypeCode: 'VITK1' },
  {
    nutritionName: 'Vitamin K-1 (dihydro-vita K-1)',
    nutritionTypeCode: 'VITK1D',
  },
  { nutritionName: 'Vitamin K-2', nutritionTypeCode: 'VITK2' },
  { nutritionName: 'Water', nutritionTypeCode: 'WATER' },
  { nutritionName: 'Wax, Total', nutritionTypeCode: 'WAX' },
  { nutritionName: 'Whey Protein', nutritionTypeCode: 'WHEY' },
  { nutritionName: 'Fat, Trans from animal', nutritionTypeCode: 'X_FATRNAN' },
  { nutritionName: 'Fat, Trans from plant', nutritionTypeCode: 'X_FATRNPL' },
  { nutritionName: 'Honey', nutritionTypeCode: 'X_HONEY' },
  { nutritionName: 'Middle Chain Triglycerides', nutritionTypeCode: 'X_MCT' },
  {
    nutritionName: 'Organic Agave Syrup',
    nutritionTypeCode: 'X_ORGANIC_AGAVE',
  },
  { nutritionName: 'Energy Cf For Acetic Acid', nutritionTypeCode: 'XACEAC' },
  { nutritionName: 'Energy Cf For Alcohol.', nutritionTypeCode: 'XALC' },
  {
    nutritionName: 'Conv. Fact. For Ate Of A-Tocotrienol',
    nutritionTypeCode: 'XATT',
  },
  {
    nutritionName: 'Conv. Fact. For B-Carot. Eq. Of A-Carotene',
    nutritionTypeCode: 'XBCARTA',
  },
  {
    nutritionName: 'Conv. Fact. For B-Carot. Eq. Of Gamma-Carotene',
    nutritionTypeCode: 'XBCARTG',
  },
  {
    nutritionName: 'Conv. Fact. For B-Carot. Eq. Of A-Cryptoxanthin',
    nutritionTypeCode: 'XBCRYPXA',
  },
  {
    nutritionName: 'Conv. Fact. For B-Carot. Eq. Of B-Cryptoxanthin',
    nutritionTypeCode: 'XBCRYPXB',
  },
  { nutritionName: 'Energy Cf For Benzoic Acid', nutritionTypeCode: 'XBENAC' },
  {
    nutritionName: 'Conv. Fact. For Ate Of B-Tocopherol',
    nutritionTypeCode: 'XBTP',
  },
  {
    nutritionName: 'Conv. Fact. For Ate Of B-Tocotrienol',
    nutritionTypeCode: 'XBTT',
  },
  { nutritionName: 'Energy Cf Choavl', nutritionTypeCode: 'XCHOAVL' },
  { nutritionName: 'Energy Cf Choavlm', nutritionTypeCode: 'XCHOAVLM' },
  { nutritionName: 'Conv. Fact. 25 Hydroxyd2', nutritionTypeCode: 'XCHOCALOH' },
  { nutritionName: 'Energy Cf Chocdf', nutritionTypeCode: 'XCHOCDF' },
  { nutritionName: 'Energy Cf For Citric Acid', nutritionTypeCode: 'XCITAC' },
  {
    nutritionName: 'Conv. Fact. For Ate Of D-Tocopherol',
    nutritionTypeCode: 'XDTP',
  },
  { nutritionName: 'Energy Cf For Fat.', nutritionTypeCode: 'XFAT' },
  {
    nutritionName: 'Conv. Fact. For Diet. Folate Eq. Of Folic Acid',
    nutritionTypeCode: 'XFOLAC',
  },
  { nutritionName: 'Energy Cf For Fumaric Acid', nutritionTypeCode: 'XFUMAC' },
  {
    nutritionName: 'Conv. Fact. For Ate Of G-Tocopherol',
    nutritionTypeCode: 'XGTP',
  },
  {
    nutritionName: 'Conv. Fact. For Ate Of G-Tocotrienol',
    nutritionTypeCode: 'XGTT',
  },
  {
    nutritionName: 'Energy Cf For Di-Keto-Cholanic Acid',
    nutritionTypeCode: 'XGULDKAC',
  },
  { nutritionName: 'Energy Cf For Inositol', nutritionTypeCode: 'XINOTL' },
  {
    nutritionName: 'Energy Cf For Iso-Citric Acid',
    nutritionTypeCode: 'XISOCAC',
  },
  { nutritionName: 'Energy Cf For Lactic Acid', nutritionTypeCode: 'XLACAC' },
  {
    nutritionName: 'Energy Cf For D-Lactic Acid',
    nutritionTypeCode: 'XLACACD',
  },
  {
    nutritionName: 'Energy Cf For L-Lactic Acid',
    nutritionTypeCode: 'XLACACL',
  },
  { nutritionName: 'Energy Cf For Malic Acid', nutritionTypeCode: 'XMALAC' },
  { nutritionName: 'Energy Cf For Mannitol', nutritionTypeCode: 'XMANTL' },
  { nutritionName: 'Energy Cf For Organic Acids', nutritionTypeCode: 'XOA' },
  { nutritionName: 'Energy Cf For Oxalic Acid', nutritionTypeCode: 'XOXALAC' },
  { nutritionName: 'Energy Cf For Phytic Acid', nutritionTypeCode: 'XPHYTAC' },
  { nutritionName: 'Energy Cf For Total Polyols', nutritionTypeCode: 'XPOLYL' },
  {
    nutritionName: 'Energy Cf For Propionic Acid',
    nutritionTypeCode: 'XPROPAC',
  },
  { nutritionName: 'Energy Cf For Protein.', nutritionTypeCode: 'XPROT' },
  { nutritionName: 'Energy Cf For Quinic Acid', nutritionTypeCode: 'XQUINAC' },
  {
    nutritionName: 'Conv. Fact. For Re Of Gamma-Carotene',
    nutritionTypeCode: 'XRCARTG',
  },
  {
    nutritionName: 'Conv. Fact. For Re Of A-Carotene',
    nutritionTypeCode: 'XRECARTA',
  },
  {
    nutritionName: 'Conv. Fact. For Re Of B-Carotene',
    nutritionTypeCode: 'XRECARTB',
  },
  {
    nutritionName: 'Conv. Fact. For Re Of B-Carotene Eq.',
    nutritionTypeCode: 'XRECARTBEQ',
  },
  {
    nutritionName: 'Conv. Fact. For Re. Of A-Cryptoxanthin',
    nutritionTypeCode: 'XRECRYPXA',
  },
  {
    nutritionName: 'Conv. Fact. For Re. Of B-Cryptoxanthin',
    nutritionTypeCode: 'XRECRYPXB',
  },
  {
    nutritionName: 'Conv. Fact. Ret. Eq. For Retinaldehyde',
    nutritionTypeCode: 'XRETALD',
  },
  {
    nutritionName: 'Conv. Fact. Ret. Eq. For 13-Cis Retinol',
    nutritionTypeCode: 'XRETOL13',
  },
  {
    nutritionName: 'Energy Cf For Salicylic Acid',
    nutritionTypeCode: 'XSALAC',
  },
  { nutritionName: 'Energy Cf For Sorbitol', nutritionTypeCode: 'XSORTL' },
  { nutritionName: 'Energy Cf For Succinic Acid', nutritionTypeCode: 'XSUCAC' },
  { nutritionName: 'Energy Cf For Tartaric Acid', nutritionTypeCode: 'XTARAC' },
  {
    nutritionName: 'Con. Fact. For Niacin Eq. Of Tryptophan',
    nutritionTypeCode: 'XTRP',
  },
  { nutritionName: 'Energy Cf For Xylitol', nutritionTypeCode: 'XXYLTL' },
  { nutritionName: 'Xylose In Dietary Fibre', nutritionTypeCode: 'XYLFB' },
  { nutritionName: 'Xylan', nutritionTypeCode: 'XYLN' },
  { nutritionName: 'Xylose', nutritionTypeCode: 'XYLS' },
  { nutritionName: 'Xylitol', nutritionTypeCode: 'XYLTL' },
  { nutritionName: 'Zeaxanthin', nutritionTypeCode: 'ZEA' },
  { nutritionName: 'Zinc', nutritionTypeCode: 'ZN' },
];

export const NUTRITION_LABEL_TYPE = [
  { label: 'Dual Column', value: 'US_FDA_NFP_2020_DUAL_COLUMN' },
  { label: 'Dual Tabular', value: 'US_FDA_NFP_2020_TABULAR_SMALL_PACKAGES' },
  {
    label: 'Linear for Small Packages',
    value: 'US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES',
  },
  { label: 'Standard', value: 'US_FDA_NFP_2020_STANDARD' },
  {
    label: 'Standard Side By Side',
    value: 'US_FDA_NFP_2020_STANDARD_SIDE_BY_SIDE',
  },
  { label: 'Tabular', value: 'US_FDA_NFP_2020_TABULAR' },
];

export const DISABLED_LABEL_TYPE = [
  'US_FDA_NFP_2020_TABULAR',
  'US_FDA_NFP_2020_TABULAR_SMALL_PACKAGES',
  'US_FDA_NFP_2020_DUAL_COLUMN',
];
export const DEFAULT_FOOTER_NOTE =
  'The % Daily Value(DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2, 000 calories a day is used for general nutrition advice.';

const gramUnits = ['gram', 'milligram', 'microgram'];
const checkIsTypeOfGramUnits = (type) => {
  return gramUnits.includes(type);
};
const getGramUnitsAbbreviations = (type) => {
  if (type === 'gram') return 'g';
  if (type === 'milligram') return 'mg';
  if (type === 'microgram') return 'mcg';
};

// Abbreviation
export const getUomTypesAbbreviations = (enumProperties, types = '') => {
  if (checkIsTypeOfGramUnits(types?.toLowerCase())) {
    return getGramUnitsAbbreviations(types?.toLowerCase());
  } else {
    switch (types) {
      // Maybe later unit bellow will change
      case 'ONZ':
        return 'oz';
      case 'KGM':
        return 'kg';
      case 'LBR':
        return 'lb';
      case 'TNE':
        return 't';
      case 'X_NGM':
        return 'ng';
      case '_23':
        return 'g/m³';
      case '_4G':
        return 'µL';

      // case '_58':
      //   return 'NAN';
      case 'c':
        return 'DWT';
      case 'APZ':
        return 'oz t';
      case 'BFT':
        return 'FBM';
      case 'BLL':
        return 'bbl';
      case 'BP':
        return 'BDFT';
      case 'BUA':
        return 'bsh.';
      case 'BUI':
        return 'bsh.';
      case 'C18':
        return 'mmol';
      case 'C34':
        return 'mol';
      case 'CGM':
        return 'cg';
      case 'CLT':
        return 'cl';
      case 'CMQ':
        return 'cc';
      case 'CTM':
        return 'ct';
      case 'CWA':
        return 'CWT';
      case 'CWI':
        return 'CWT';
      case 'D40':
        return 'TL';
      case 'D43':
        return 'AMU';
      case 'DG':
        return 'dg';
      case 'DLT':
        return 'dl';
      case 'DMQ':
        return 'DM3';
      // case 'DRA':
      //   return 'NAN';
      // case 'DRI':
      //   return 'NAN';
      case 'E4':
        return 'GKG';
      case 'FH':
        return 'μmol';
      case 'FTQ':
        return 'cu ft';
      // case 'G21':
      //   return 'NAN';
      case 'G23':
        return 'pk';
      case 'G24':
        return 'tbsp';
      case 'G25':
        return 'tsp.';
      case 'GFI':
        return 'NAN';
      case 'GL':
        return 'g/L';
      case 'GLI':
        return 'gal';
      case 'GLL':
        return 'gal';
      case 'GRN':
        return 'gr';
      case 'H83':
        return 'L/Kg';
      case 'HGM':
        return 'hg';
      case 'HLT':
        return 'hl';
      case 'INQ':
        return 'cu in';
      case 'K6':
        return 'kl';
      case 'KDW':
        return 'KDW';
      // case 'KHY':
      //   return 'NAN';
      // case 'KMA':
      //   return 'NAN';
      // case 'KNI':
      //   return 'NAN';
      // case KPH
      // : return NAN"
      // case KPO
      // : return NAN"
      // case KPP
      // : return NAN"
      // case KSD
      // : return NAN"
      // case KSH
      // : return NAN"
      // case KUR
      // : return NAN"
      // case LPA
      // : return NAN"
      case 'LTN':
        return 't';
      case 'LTR':
        return 'L';
      case 'MLT':
        return 'ml';
      case 'MMQ':
        return 'mm3';
      case 'MTQ':
        return 'm³';
      case 'OZA':
        return 'fl. oz.';
      case 'OZI':
        return 'fl oz';
      case 'PTD':
        return 'pt';
      case 'PTI':
        return 'pt';
      case 'PTL':
        return 'NAN';
      case 'Q32':
        return 'fL';
      case 'Q33':
        return 'NAN';
      case 'Q34':
        return 'nL';
      // case QTD
      // : return NAN"
      // case QTL
      // : return NAN"
      case 'STN':
        return 't';
      case 'X_DWT':
        return 'dwt';

      default:
        return getDisplayNameUom(enumProperties, types);
    }
  }
};
const getDisplayNameUom = (enumProperties, code) => {
  if (enumProperties?.length > 0 && code) {
    const foundEnum = enumProperties.find(
      (item) => item?.enumDisplayName === code
    );
    return foundEnum?.enumDisplayName ?? code;
  }
  return code;
};
