import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FolderOpenOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
// import { FindEntityInFolderResultModal } from './../../index';

import Messages from 'i18n/messages/home';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import FindInAllFolderModal from 'common/components/modal/grid-view-modal/find-in-all-folder-modal/FindInAllFolderModal';

const ViewFolderControl = (props) => {
  const { isSectionDisabled, selectedItemList } = props;

  //* state
  const showResultHook = useState(false);
  const [, setShowResult] = showResultHook;
  //* function
  const clickSearchHandler = () => {
    showFindResultDialog();
  };

  const showFindResultDialog = () => {
    setShowResult(true);
  };

  const VIEW_FOLDERS = [
    { action: ABILITY_ACTION.VIEW, subject: ABILITY_SUBJECT.FOLDER },
  ];

  const disabled = !useCheckPermissions(VIEW_FOLDERS) || isSectionDisabled;

  return (
    <>
      <ButtonSmallIcon
        icon={<FolderOpenOutlined />}
        label={Messages.searchFolders}
        className='button-item-dropdown'
        disabled={disabled}
        onClick={clickSearchHandler}
      />
      {/* <FindEntityInFolderResultModal
        showResultHook={showResultHook}
        selectedItemList={selectedItemList}
      /> */}

      <FindInAllFolderModal
        showResultHook={showResultHook}
        selectedItemList={selectedItemList}
      />
    </>
  );
};

ViewFolderControl.propTypes = {
  selectedItemList: PropTypes.array,
  isSectionDisabled: PropTypes.bool,
};

export default ViewFolderControl;
