// * GET BRANDING INFO
export const GET_BRANDING = 'GET_BRANDING';
export const GET_BRANDING_SUCCESS = 'GET_BRANDING_SUCCESS';
export const GET_BRANDING_FAIL = 'GET_BRANDING_FAIL';
export const CLEAR_BRANDING = 'CLEAR_BRANDING';

export const UPDATE_LAST_GET_NEW_MEMBER = 'UPDATE_LAST_GET_NEW_MEMBER';
export const UPDATE_LAST_GET_NEW_MEMBER_SUCCESS =
  'UPDATE_LAST_GET_NEW_MEMBER_SUCCESS';
export const UPDATE_LAST_GET_NEW_MEMBER_ERROR =
  'UPDATE_LAST_GET_NEW_MEMBER_ERROR';
export const GET_BRANDING_NO_LOADING = 'GET_BRANDING_NO_LOADING';
export const GET_BRANDING_NO_LOADING_SUCCESS =
  'GET_BRANDING_NO_LOADING_SUCCESS';
export const GET_BRANDING_NO_LOADING_FAIL = 'GET_BRANDING_NO_LOADING_FAIL';
export const UPDATE_LAST_GET_NEW_PRODUCT = 'UPDATE_LAST_GET_NEW_PRODUCT';
export const UPDATE_LAST_GET_NEW_PRODUCT_SUCCESS =
  'UPDATE_LAST_GET_NEW_PRODUCT_SUCCESS';
export const UPDATE_LAST_GET_NEW_PRODUCT_ERROR =
  'UPDATE_LAST_GET_NEW_PRODUCT_ERROR';
export const UPDATE_LAST_GET_NEW_ASSET = 'UPDATE_LAST_GET_NEW_ASSET';
export const UPDATE_LAST_GET_NEW_ASSET_SUCCESS =
  'UPDATE_LAST_GET_NEW_ASSET_SUCCESS';
export const UPDATE_LAST_GET_NEW_ASSET_ERROR =
  'UPDATE_LAST_GET_NEW_ASSET_ERROR';

// * GET BRANDING INFO

export const UPDATE_LAST_GET_UNMATCH_MEMBER = 'UPDATE_LAST_GET_UNMATCH_MEMBER';
export const UPDATE_LAST_GET_UNMATCH_MEMBER_SUCCESS =
  'UPDATE_LAST_GET_UNMATCH_MEMBER_SUCCESS';
export const UPDATE_LAST_GET_UNMATCH_MEMBER_ERROR =
  'UPDATE_LAST_GET_UNMATCH_MEMBER_ERROR';
export const UPDATE_LAST_GET_UNMATCH_ASSET = 'UPDATE_LAST_GET_UNMATCH_ASSET';
export const UPDATE_LAST_GET_UNMATCH_ASSET_SUCCESS =
  'UPDATE_LAST_GET_UNMATCH_ASSET_SUCCESS';
export const UPDATE_LAST_GET_UNMATCH_ASSET_ERROR =
  'UPDATE_LAST_GET_UNMATCH_ASSET_ERROR';
