import React, { useState, useEffect } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  Row,
  Col,
  Modal,
  notification,
  Collapse,
  Form,
  Spin,
  Typography,
} from 'antd';
import IconList from 'common/components/icon-list/IconList';

import {
  LabelValueWrapper,
  EditButton,
  FormAddButton,
  FormDeleteButton,
  FormCancelButton,
} from 'common/components';
import { dialogFunction } from 'common/components';
import {
  CameraOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import DigitalRightIcon from 'common/components/digital-right-icon/DigitalRightIcon';
import AssetTypeEdit from 'common/components/asset-type-edit';
import MemberDefinedPropertiesPanel from './MemberDefinedPropertiesPanel';
// import AssetGS1Edit from './AssetGS1Edit';

import AssetGS1Edit from '../add-link-product/AssetGS1Edit';
import AssetGS1Preview from '../add-link-product/AssetGS1Preview';

import { FormattedMessage, injectIntl } from 'react-intl';
import AddDRMForm from './AddDRMForm';
import messages from 'i18n/messages/asset-full-view';
import { formatMDY } from 'utils/formatDate';
import { formatSizeUnits } from 'utils/formatSizeUnits';
import {
  ADD_DRM_ASSET,
  DELETE_DRM_ASSET,
} from 'services/digitalAsset/endpoints';
import * as metadataTypesConstant from 'static/Metadata';
import * as api from 'config/axios';

import './AssetOverview.less';
import _ from 'lodash';

import { useCheckAllowEditAssets } from 'hooks';

import { useDeepCompareEffect } from 'common/components/mapping-properties/hooks';

const { Panel } = Collapse;

const AssetOverview = (props) => {
  const {
    isFullView,
    assetData,
    classifications,
    gs1ImageData = [],
    assetId,
    typeData,
    submitAssetType,
    canEdit,
    setReloadData,
    intl,
    onReloadPage,
    memberDefinedPropertiesEditable,
    isHiddenEditSection = false,
    drm,
  } = props;

  const [leftCol, setLeftCol] = useState([]);
  const [isEditDRM, setIsEditDRM] = useState(false);
  const [rightCol, setRightCol] = useState([]);
  const [exifDataShow, setExifDataShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeGS1, setActiveGS1] = useState([]);
  const [metadataParams, setMetadataParams] = useState([]);
  const [editGS1, setEditGS1] = useState(false);
  const [isAddDRM, setIsAddDRM] = useState(false);
  const [activeCollapseKey, setActiveCollapseKey] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [isAddDRM, form]);

  useDeepCompareEffect(() => {
    setActiveGS1(gs1ImageData);
  }, [gs1ImageData]);

  const memberDefinedProperties = assetData?.memberDefinedProperties;

  const { checkAllowEditAssetFull } = useCheckAllowEditAssets();
  const isAllowEdit = checkAllowEditAssetFull();

  const allowModifyDRM = isAllowEdit;

  // EXIF Data Modal Relates Functions
  const showModal = () => {
    setExifDataShow(true);
  };

  const handleCancel = (e) => {
    setExifDataShow(false);
  };

  // Exif DATA Modify
  const exifDataModify = (value) => {
    if (value)
      return (
        <CameraOutlined
          className='asset-overview__exif-data-icon'
          onClick={showModal}
        />
      );
    else return 'No';
  };

  // Modify Data
  const modifyData = (key, value) => {
    if (typeof value === 'boolean') {
      switch (value) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return value;
      }
    } else {
      switch (key) {
        case 'lastModified':
        case 'loadedDate':
          return formatMDY(value);
        case 'resolution':
          return value ? value + ' DPI' : '';
        case 'colorDepth':
          return value ? value + ' Bit' : '';
        case 'cropRange':
        case 'height':
        case 'width':
          return value ? value + 'px' : '';
        case 'fileSize':
          return formatSizeUnits(value);
        case 'exifData':
          return exifDataModify(value);
        default:
          return value;
      }
    }
  };

  // Filter Object By Value
  const filterObjectByValue = (obj, filter, direction) => {
    let newObj = Object.entries(obj)
      .map(([key, value]) => {
        return [
          key,
          value && typeof value === 'object'
            ? filterObjectByValue(value, filter)
            : value,
        ];
      })
      .reduce((newObj, [key, value]) => {
        if (!filter.includes(value)) {
          newObj[key] = value;
        }
        return newObj;
      }, {});

    let objectLength = Object.keys(newObj).length;

    return Object.entries(newObj).reduce((newObj, [key, value], index) => {
      if (direction === 'left-col' && index < Math.ceil(objectLength / 2)) {
        newObj[key] = modifyData(key, value);
      }
      if (direction === 'right-col' && index >= Math.ceil(objectLength / 2)) {
        newObj[key] = modifyData(key, value);
      }
      return newObj;
    }, {});
  };

  // Filter Object By Key
  const filterObjectByKey = (obj, filter) => {
    return Object.entries(obj)
      .map(([key, value]) => {
        return [
          key,
          value && typeof value === 'object'
            ? filterObjectByValue(value, filter)
            : value,
        ];
      })
      .reduce((newObj, [key, value]) => {
        if (!filter.includes(key)) {
          newObj[key] = modifyData(key, value);
        }
        return newObj;
      }, {});
  };

  useEffect(() => {
    setMetadataParams(onSetMetadataType(assetData?.fileType));
  }, [assetData]);

  // Remove all undefined values
  useEffect(() => {
    if (assetData?.metadata) {
      let metadateNew = assetData?.isIFrame
        ? _.pick(assetData?.metadata, ['loadedDate', 'lastModified', 'owner'])
        : assetData?.metadata;

      setLeftCol(filterObjectByMetadataOfType(metadateNew, 'left-col'));
      setRightCol(filterObjectByMetadataOfType(metadateNew, 'right-col'));

      filterObjectByMetadataOfType(metadateNew, [], 'left-col');
    }
  }, [assetData, metadataParams]);

  const exifData = `<p>${
    assetData && assetData.metadata && assetData.metadata.exifData
      ? assetData?.metadata?.exifData?.replace(/;/g, '</br>')
      : ''
  }</p>`;

  useEffect(() => {
    if (gs1ImageData?.length) {
      let filter = gs1ImageData?.filter(
        (item) => item.status?.toLowerCase() !== 'deleted'
      );
      setActiveGS1(filter);
    }
  }, [gs1ImageData, editGS1]);

  useEffect(() => {
    if (activeGS1?.length >= 1) {
      setActiveCollapseKey(['1']);
    }
  }, [activeGS1]);

  const onSetMetadataType = (type) => {
    let params = [];
    metadataTypesConstant.TYPEGROUP.forEach((group) => {
      if (metadataTypesConstant[group].filetypes.indexOf(type) > -1) {
        params = metadataTypesConstant[group].metaParams;
      }
    });
    if (params?.length === 0) {
      params = metadataTypesConstant.OTHER_METADATA.metaParams;
    }
    return params;
  };

  const filterObjectByMetadataOfType = (object, direction) => {
    let paramsFilter = onFilterParamsByType(object);
    let objectLength = Object.keys(paramsFilter).length;

    const resultParams = Object.entries(paramsFilter).reduce(
      (newObj, [key, value], index) => {
        if (direction === 'left-col' && index < Math.ceil(objectLength / 2)) {
          newObj[key] = modifyData(key, value);
        }
        if (direction === 'right-col' && index >= Math.ceil(objectLength / 2)) {
          newObj[key] = modifyData(key, value);
        }
        return newObj;
      },
      {}
    );

    return resultParams;
  };

  const onFilterParamsByType = (object) => {
    let paramsFilter = Object.entries(object)
      .map(([key, value]) => {
        return [key, value];
      })
      .reduce((newObj, [key, value]) => {
        if (metadataParams.includes(key)) {
          newObj[key] = value;
        }
        return newObj;
      }, {});
    return paramsFilter;
  };

  const typeDataRender = () => {
    return (
      <div style={{ width: '100%' }}>
        <AssetTypeEdit
          onSubmit={(value) => submitAssetType(value)}
          typeData={typeData}
          canEdit={false}
        />
      </div>
    );
  };

  const isEditDrmAsset = isAllowEdit && !isHiddenEditSection;

  const drmAssetRender = () => {
    return (
      <div style={{ marginBottom: 30, marginTop: 30, width: '100%' }}>
        <Spin spinning={loading}>
          <Collapse
            className='asset-overview__collapse'
            ghost
            expandIconPosition='right'
            defaultActiveKey={['1']}
          >
            <Panel
              header={
                <Row>
                  <DigitalRightIcon size='normal' style={{ marginRight: 5 }} />
                  <div style={{ marginTop: 5 }}>
                    {assetData?.digitalRightsType?.toLowerCase() ===
                    'ActiveDigitalRights'.toLowerCase() ? (
                      <FormattedMessage {...messages.activeDRM} />
                    ) : assetData?.digitalRightsType?.toLowerCase() ===
                      'ExpiredDigitalRights'.toLowerCase() ? (
                      <FormattedMessage {...messages.expiredDRM} />
                    ) : (
                      <FormattedMessage {...messages.noDRM} />
                    )}
                  </div>
                </Row>
              }
              key='1'
            >
              {isEditDrmAsset && assetEditDRMRender()}

              {assetData?.digitalRightsType?.toLowerCase() !==
                'NoDigitalRights'.toLowerCase() && (
                <Row style={{ marginBottom: 5 }}>
                  <Col span={2}></Col>
                  <Col span={14}>
                    <div style={{ fontWeight: 700 }}>
                      <FormattedMessage {...messages.DRA} />
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ fontWeight: 700 }}>
                      <FormattedMessage {...messages.expired} />
                    </div>
                  </Col>
                </Row>
              )}

              {assetData?.digitalRights?.map((item, index) => {
                return (
                  <Row style={{ marginBottom: 5 }} key={index}>
                    <Col span={1} offset={1}>
                      {item.status === 'Active' ? (
                        <CheckCircleOutlined className='asset-overview__drm-active' />
                      ) : (
                        <ExclamationCircleOutlined className='asset-overview__drm-expired' />
                      )}
                    </Col>
                    <Col span={14}>{item.digitalRightsName}</Col>
                    <Col span={4}>{formatMDY(item.expirationDate)}</Col>
                    <Col span={4}>
                      {isEditDRM && (
                        <FormDeleteButton
                          className='asset-overview__remove-drm-button'
                          onClick={() => {
                            dialogDeleteDRM(item.id);
                          }}
                          disabled={!allowModifyDRM}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Panel>
          </Collapse>
        </Spin>
      </div>
    );
  };

  const digitalAssetRender = () => {
    return (
      <>
        <Collapse
          className='asset-overview__collapse'
          ghost
          expandIconPosition='right'
          defaultActiveKey={['1']}
        >
          <Panel header='Digital Asset Specifications' key='1' forceRender>
            <Row>
              <Col span={24}>
                {leftCol && (
                  <LabelValueWrapper
                    object={leftCol}
                    parentScoop='digitalAsset.overview'
                    colspan={12}
                  />
                )}
              </Col>
              <Col span={24}>
                {rightCol && (
                  <LabelValueWrapper
                    object={rightCol}
                    parentScoop='digitalAsset.overview'
                    colspan={12}
                  />
                )}
              </Col>
            </Row>
            {classifications?.length > 0 && classificationRender()}
          </Panel>
        </Collapse>
      </>
    );
  };

  const addDRMModal = () => {
    return (
      <Modal
        title='Add DRM'
        visible={isAddDRM}
        centered
        onCancel={() => setIsAddDRM(false)}
        onOk={() => form.submit()}
        className='add-drm-modal'
      >
        <AddDRMForm form={form} handleAddDRM={handleAddDRM} loading={loading} />
      </Modal>
    );
  };

  async function handleAddDRM(data) {
    setLoading(true);
    const submitData = {
      DigitalAssetId: assetId,
      DigitalRightsName: data?.digitalRightsName,
      EffectiveDate: data?.dateEffective[0].toISOString(),
      ExpirationDate: data?.dateEffective[1]?.toISOString(),
    };
    await api
      .sendPost(ADD_DRM_ASSET, submitData)
      .then((response) => {
        if (response.isSuccess) {
          notification.success({ message: 'Add new DRM successful!' });
          onReloadPage();
          setIsAddDRM(false);
        } else notification.error({ message: 'Add new DRM error' });
      })
      .catch((error) => {
        notification.error({ message: 'Add new DRM error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const dialogDeleteDRM = (data) => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(messages.deleteDRM),
      onOk: () => handleDeleteDRM(data),
    });
  };

  async function handleDeleteDRM(data) {
    setLoading(true);
    const submitData = {
      DigitalAssetId: assetId,
      DigitalRightsId: data,
    };
    await api.sendPost(DELETE_DRM_ASSET, submitData).then((response) => {
      if (response.isSuccess) {
        notification.success({ message: 'Remove DRM successful!' });
        onReloadPage();
      } else notification.error({ message: 'Remove DRM error' });
    });
    setLoading(false);
  }

  const assetEditDRMRender = () => {
    return (
      <>
        <Row justify='end'>
          {isEditDRM ? (
            <div className='asset-overview__grp-button'>
              <FormAddButton
                onClick={() => setIsAddDRM(true)}
                className='asset-overview__edit-button'
                disabled={!allowModifyDRM}
              />
              <FormCancelButton
                onClick={() => {
                  setIsEditDRM(false);
                }}
                className='asset-overview__edit-button'
              />
            </div>
          ) : (
            allowModifyDRM && (
              <div className='asset-overview__edit-button-trigger'>
                <EditButton
                  onClick={() => {
                    setIsEditDRM(true);
                  }}
                />
              </div>
            )
          )}
        </Row>
        {addDRMModal()}
      </>
    );
  };

  const classificationRender = () => {
    return (
      <Col span={24}>
        <Row className='asset-overview__row'>
          <Col className='asset-overview__row-label' span={8}>
            {intl.formatMessage({
              id: `Taco.digitalAsset.overview.classifications`,
            })}
          </Col>
          <Col span={16} style={{ marginLeft: -4 }}>
            <IconList
              itemList={classifications}
              size='default'
              tooltipPosition='bottom'
            />
          </Col>
        </Row>
      </Col>
    );
  };

  const exifDataRender = () => {
    return (
      <Modal
        title={intl.formatMessage({
          id: `Taco.digitalAsset.overview.exifData`,
        })}
        visible={exifDataShow}
        onCancel={handleCancel}
        footer={null}
        className='asset-exif-modal'
      >
        <div
          className='content'
          dangerouslySetInnerHTML={{ __html: exifData }}
        />
      </Modal>
    );
  };

  const memberDefinedRender = () => {
    return (
      <MemberDefinedPropertiesPanel
        memberDefinedProperties={memberDefinedProperties}
        assetId={assetData?.id}
        isOwner={assetData?.isOwner}
        isEditable={memberDefinedPropertiesEditable}
        isActive={assetData?.enforceMemberDefinedProperties}
        isHiddenEditSection={isHiddenEditSection}
      />
    );
  };

  const gs1Render = () => {
    return (
      <Collapse
        className='asset-overview__collapse'
        ghost
        expandIconPosition='right'
        style={{ position: 'relative' }}
        activeKey={activeCollapseKey}
        onChange={(key) => setActiveCollapseKey(key)}
      >
        <Panel header='Link Data' key='1' forceRender>
          {editGS1 && !assetData?.isIFrame ? (
            <AssetGS1Edit
              gs1ImageData={gs1ImageData}
              assetId={assetData?.id}
              onReloadPage={onReloadPage}
              setEditGS1={setEditGS1}
              isDisabledGtin={true}
            />
          ) : (
            <AssetGS1Preview
              assetData={assetData}
              activeGS1={activeGS1}
              drm={drm}
              editGS1={editGS1}
              canEdit={canEdit}
              gs1ImageData={gs1ImageData}
              assetId={assetData?.id}
              setReloadData={setReloadData}
              onReloadPage={onReloadPage}
              isDisabledGtin={true}
            />
          )}
        </Panel>
      </Collapse>
    );
  };

  return (
    <Row className='asset-overview' gutter={8}>
      {typeDataRender()}
      {!assetData?.isIFrame && memberDefinedRender()}
      {!assetData?.isIFrame && digitalAssetRender()}
      {isFullView
        ? !assetData?.isIFrame
          ? gs1Render()
          : null
        : gs1ImageData?.length > 0
        ? gs1Render()
        : null}
      {!assetData?.isIFrame && exifDataShow && exifDataRender()}
      {isFullView && drmAssetRender()}
    </Row>
  );
};

AssetOverview.propTypes = {
  assetData: oneOfType([PropTypes.array, PropTypes.object]),
  classifications: oneOfType([PropTypes.array, PropTypes.object]),
  gs1ImageData: oneOfType([PropTypes.array, PropTypes.object]),
  assetId: oneOfType([PropTypes.string, PropTypes.number]),
  canEdit: PropTypes.bool,
  setReloadData: PropTypes.func,
  isHiddenEditSection: PropTypes.bool,
};

export default injectIntl(AssetOverview);
