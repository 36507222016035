import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as folderSelectors from 'pages/folders/controllers/selectors';
import * as folderActions from 'pages/folders/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { useFetch } from 'hooks/useAsync';
import { sleep } from 'utils/delay';

import * as folderServices from 'services/folder';

const shortDetailServiceCollection = {
  folder: folderServices.getFolderShortDetail,
  asset: folderServices.getDigitalAssetPreview,
  member: folderServices.getMemberPreview,
  product: folderServices.getProductPreview,
  reporting: folderServices.getReportingPreview,
  query: folderServices.getQueryPreview,
};

const useFolderContentPane = () => {
  const dispatch = useDispatch();

  const { run, data, status, resetData } = useFetch();

  const shortDetail = data?.data;

  const [contentPaneType, setContentPaneType] = useState(null);

  const visibleContentPane = useSelector(selectorsGridView.makeSelectVisible());

  const selectedItems = useSelector(
    selectorsGridView.makeSelectDetailCurrentITemsSelection()
  );

  const { folderBreadcrumb } = useSelector(
    folderSelectors.selectFolderListByLevel()
  );
  const currentFolder = folderBreadcrumb[folderBreadcrumb?.length - 1];

  const selectedItem = selectedItems?.[0];

  const currentShortItem = selectedItem
    ? selectedItem
    : currentFolder?.id === 'home'
    ? {}
    : currentFolder;

  const fetchShortDetail = ({ id, type }) => {
    if (!id) return;

    const service = shortDetailServiceCollection[type?.toLowerCase()];

    if (!service) return;

    const params = {
      id,
      ...(type?.toLowerCase() === 'folder' && { IsLoadContent: true }),
    };

    run(service(params), () => {
      setContentPaneType(type?.toLowerCase());
    });
  };

  const clearContentPane = () => {
    resetData();
  };

  const fetchData = async () => {
    if (!currentShortItem) return;

    const { id, type } = currentShortItem;

    await fetchShortDetail(currentShortItem);

    if (type?.toLowerCase() === 'folder')
      dispatch(folderActions.getDownloadSetting(id));
  };

  useEffect(() => {
    if (visibleContentPane && currentShortItem?.id) {
      fetchData();
      return;
    }

    //* clear data when close panel, it will prevent show old data when selecting other item
    clearContentPane();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //* separate into 2 useEffect to prevent duplicated api call
  useEffect(() => {
    //* re-fetch short content data when selecting another item while content panel is opening
    if (status !== 'resolved') return;

    if (visibleContentPane && currentShortItem?.id) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShortItem?.id]);

  return {
    shortDetail,
    isLoading: status === 'pending',
    contentPaneType,
  };
};

export { useFolderContentPane };
