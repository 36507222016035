import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col, Input } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';

import { Form, CustomNotification, WithLoading } from 'common/components';

import { EditContainer } from 'pages/qa-spec/components';
import QaFormulaGrid from './QaFormulaGrid';
import QaFormulaDrug from './QaFormulaDrug';

import { sleep } from 'utils/delay';
import { useTabExpand } from 'hooks/useTabExpandProduct';
import { useReloadDocumentCertificateTab } from 'hooks/useReloadDocumentCertificateTab';
import { getKeyDrugPanels } from 'pages/product-full-view/components/product-media-area/multiple-panel/facts-panel/useQueryFactsPanel';
import { getKeyQaSpecDrugFactPanels } from '../qa-nutrition-data/hook';

import {
  EditTabSectionWrapper,
  EDIT_SECTION,
} from 'pages/qa-spec/components/edit-tab-checker';

import { saveDataFormula } from 'services/product';
import { cloneDeep } from 'lodash';

import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';
import {
  useQueryQaFormulaData,
  useQueryQaFormulaSnapshotData,
} from './queries';

import { v4 as uuidv4 } from 'uuid';
import {
  FormLabel,
  SnapshotInputWrapper,
  SnapshotFormItemWrapper,
} from 'pages/qa-spec/components/form';

import { useGetSnapshotFormItemWrapperProps } from 'pages/qa-spec/components/form/hooks';

import './QaFormula.less';

const QaFormula = (props) => {
  const [formInst] = Form.useForm();
  const { id: productId } = useParams();
  const formulaGridRef = useRef({});
  const drugFactRef = useRef({});

  const queryClient = useQueryClient();

  const { type } = props;
  const isDrug = type === 'Drug' || type === 'HBC / OTC';

  const [loading, setLoading] = useState(false);
  const editHook = useState(false);
  const [isEdit] = editHook;

  const { tableStyle } = useTabExpand();

  const { reloadDocumentWhenDelete, reloadDocumentCertificateTab } =
    useReloadDocumentCertificateTab();

  const useSnapshot = useCheckSnapshotForRetailer();

  const { data: qaFormulaData, invalidateQaFormulaData } =
    useQueryQaFormulaData({
      productId,
      productType: type,
      enabled: Boolean(productId && !isEdit),
    });

  const { data: qaFormulaSnapshotData } = useQueryQaFormulaSnapshotData({
    productId,
    productType: type,
    enabled: Boolean(productId && useSnapshot),
  });

  const mapToProcFormulaData = useCallback((formulaData) => {
    const { formula, drugFactsFormula } = formulaData || {};

    return {
      formulaGridData:
        formula &&
        formula?.map((item) => {
          return { ...item, uuid: uuidv4() };
        }),
      formulaForm: {
        epaRegistrationNumber: formulaData?.epaRegistrationNumber,
        formulaActiveIngredients: formulaData?.formulaActiveIngredients,
        formulaFreeFrom: formulaData?.formulaFreeFrom,
        formulaInactiveIngredients: formulaData?.formulaInactiveIngredients,
        formulaIngredientDefinitions: formulaData?.formulaIngredientDefinitions,
        formulaIngredientsPurposeStrength:
          formulaData?.formulaIngredientsPurposeStrength,
        formulaOtherIngredients: formulaData?.formulaOtherIngredients,
        formulaProductClaims: formulaData?.formulaProductClaims,
      },
      drugFactsFormula: drugFactsFormula ?? [],
    };
  }, []);

  const procQaFormulaData = useMemo(() => {
    return mapToProcFormulaData(qaFormulaData);
  }, [JSON.stringify(qaFormulaData), mapToProcFormulaData]);

  const procQaFormulaSnapshotData = useMemo(() => {
    return mapToProcFormulaData(qaFormulaSnapshotData);
  }, [JSON.stringify(qaFormulaSnapshotData), mapToProcFormulaData]);

  const formulaDrugFacts = procQaFormulaData?.drugFactsFormula?.drugFacts;
  const snapshotFormulaDrugFacts =
    procQaFormulaSnapshotData?.drugFactsFormula?.drugFacts;

  const handleSave = async (toggleEdit) => {
    setLoading(true);
    const formValue = formInst.getFieldValue();

    formulaGridRef?.current?.stopEditing();

    //* make sure get form value first
    await sleep(100);

    const formulaListData = formulaGridRef?.current?.getGridData();
    const drugFactsListData = isDrug
      ? drugFactRef?.current?.getDrugFactData?.()
      : formulaDrugFacts;

    const submitValue = {
      productId,
      ...formValue,
      formula: formulaListData,
      drugFactsFormula: { drugFacts: drugFactsListData },
    };

    try {
      const { isSuccess } = await saveDataFormula(submitValue);

      if (isSuccess) {
        toggleEdit();
        formulaGridRef?.current?.clearSelectedRows();
        invalidateQaFormulaData({ productId, productType: type });

        reloadDocumentCertificateTab();

        CustomNotification.success('Update Formula data successfully!');
        //* reload drug fact panel in overview
        queryClient.invalidateQueries({
          queryKey: getKeyDrugPanels(productId),
        });
        //* reload drug fact panel in qa spec fact panel tab
        queryClient.invalidateQueries({
          queryKey: getKeyQaSpecDrugFactPanels(productId),
        });
      } else {
        CustomNotification.error('Fail to update Formula data');
      }
    } catch (error) {
      CustomNotification.error('Fail to update Formula data');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    formInst.setFieldsValue(procQaFormulaData.formulaForm);
    //* formulaGrid do stuff
    formulaGridRef?.current?.clearSelectedRows();
    formulaGridRef?.current?.updateGridData(
      cloneDeep([...(procQaFormulaData.formulaGridData ?? [])])
    );

    //* reset drug fact list
    drugFactRef?.current?.reset?.();
  };

  useEffect(() => {
    formInst.setFieldsValue(procQaFormulaData.formulaForm);
  }, [JSON.stringify(procQaFormulaData.formulaForm), type]);

  useEffect(() => {
    if (reloadDocumentWhenDelete) {
      invalidateQaFormulaData(productId);
    }
  }, [reloadDocumentWhenDelete]);

  const { getSnapshotFormItemWrapperProps } =
    useGetSnapshotFormItemWrapperProps({
      snapshotValues: procQaFormulaSnapshotData?.formulaForm,
    });

  return (
    <Form
      className='qa-formula'
      form={formInst}
      layout='vertical'
      style={{ padding: '0 8px' }}
    >
      <WithLoading loading={loading}>
        <EditContainer
          type={EDIT_SECTION.QA_FORMULA}
          productId={productId}
          handleCancel={handleCancel}
          handleSave={handleSave}
          editHook={editHook}
          float
        >
          {(isEdit) => {
            return (
              <EditTabSectionWrapper.CheckerWrapper
                isEdit={isEdit}
                type={EDIT_SECTION.QA_FORMULA}
              >
                <Row
                  className={classnames('qa-formula__input', {
                    'qa-formula__input--edit': isEdit,
                  })}
                  gutter={[32, 0]}
                >
                  <Col xs={12}>
                    {isDrug ? (
                      <QaFormulaDrug
                        ref={drugFactRef}
                        isEdit={isEdit}
                        drugFactList={formulaDrugFacts}
                        snapshotDrugFactList={snapshotFormulaDrugFacts}
                      />
                    ) : null}
                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'formulaActiveIngredients',
                        label: 'Active Ingredients',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='formulaActiveIngredients'
                          label={<FormLabel label={'Active Ingredients'} />}
                          hidden={isDrug}
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>
                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'formulaInactiveIngredients',
                        label: 'Inactive Ingredients',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='formulaInactiveIngredients'
                          label={<FormLabel label='Inactive Ingredients' />}
                          hidden={isDrug}
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>

                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'formulaIngredientsPurposeStrength',
                        label: 'Ingredients Purpose / Strength',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='formulaIngredientsPurposeStrength'
                          label={
                            <FormLabel label='Ingredients Purpose / Strength' />
                          }
                          hidden={isDrug}
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>
                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'formulaOtherIngredients',
                        label: 'Other Ingredients',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='formulaOtherIngredients'
                          label={<FormLabel label='Other Ingredients' />}
                          hidden={isDrug}
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>
                  </Col>

                  <Col xs={12}>
                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'formulaProductClaims',
                        label: 'Formula/Ingredient Claims',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='formulaProductClaims'
                          label={
                            <FormLabel label='Formula/Ingredient Claims' />
                          }
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>

                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'formulaFreeFrom',
                        label: 'Free From',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='formulaFreeFrom'
                          label={<FormLabel label='Free From' />}
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>

                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'formulaIngredientDefinitions',
                        label: 'Ingredient Definitions',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='formulaIngredientDefinitions'
                          label={<FormLabel label='Ingredient Definitions' />}
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>

                    <SnapshotFormItemWrapper
                      {...getSnapshotFormItemWrapperProps({
                        fieldPath: 'epaRegistrationNumber',
                        label: 'EPA Registration Number',
                      })}
                    >
                      {(inputWrapperProps) => (
                        <Form.Item
                          name='epaRegistrationNumber'
                          label={<FormLabel label='EPA Registration Number' />}
                        >
                          <SnapshotInputWrapper
                            {...inputWrapperProps}
                            popoverProps={{ visible: false }}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              showCount
                              maxLength={500}
                              readOnly={!isEdit}
                            />
                          </SnapshotInputWrapper>
                        </Form.Item>
                      )}
                    </SnapshotFormItemWrapper>
                  </Col>
                </Row>

                <Row style={{ ...tableStyle, marginBottom: 20 }}>
                  <Col xs={24}>
                    <QaFormulaGrid
                      isEdit={isEdit}
                      ref={formulaGridRef}
                      formulaGridData={cloneDeep(
                        procQaFormulaData.formulaGridData ?? []
                      )}
                      snapshotGridValues={
                        procQaFormulaSnapshotData.formulaGridData
                      }
                      useSnapshot={useSnapshot}
                    />
                  </Col>
                </Row>
              </EditTabSectionWrapper.CheckerWrapper>
            );
          }}
        </EditContainer>
      </WithLoading>
    </Form>
  );
};

export default QaFormula;
