import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { notification } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as actionsRibbon from 'redux/ribbon/actions';
import * as actionsGlobal from 'redux/global/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import * as ribbonActions from 'redux/ribbon/actions';
import * as ribbonSelectors from 'redux/ribbon/selectors';

import { forwardTo } from 'utils/common/route';
import { RIBBON_TYPES } from 'static/Constants';
import { updateSearchText } from 'pages/home/utils';
import {
  updateQueryConditions,
  updateQueryAdvance,
  updateIdQuery,
} from 'utils/queryCondition';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

const OpenQuery = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectedDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const id =
    selectedDetail && selectedDetail?.length > 0
      ? selectedDetail[0].id
      : undefined;

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  const handleOpenQuery = () => {
    const { queryConditions, columns, ribbonTypes, urlForwardTo, searchText } =
      JSON.parse(selectedDetail[0]?.query);

    const invalidQuery = !urlForwardTo;

    if (invalidQuery) {
      showErrorMessage();
      return;
    }

    pushCloseDetailStack(urlForwardTo);
    forwardTo(`${urlForwardTo}?query=1`);

    dispatch(actionsGridView.myQueryDoubleClick(true));
    if (ribbonTypes === RIBBON_TYPES.DETAILSVIEW) {
      dispatch(actionsGridView.chooseGridColumns(columns, id));
    } else {
      dispatch(actionsGridView.chooseGridColumns([], id));
    }

    updateQueryConditions(
      dispatch,
      queryConditions,
      window.location.pathname,
      true
    );
    updateQueryAdvance(
      dispatch,
      queryConditions,
      window.location.pathname,
      true
    );
    updateIdQuery(dispatch, id, window.location.pathname, true);

    dispatch(actionsGridView.checkQueryCondition(true));
    dispatch(actionsRibbon.changeType(ribbonTypes));
    dispatch(actionsGridView.updateVisible(false));
    dispatch(actionsGlobal.updateIsBackFromQuery(true));
    updateSearchText(dispatch, searchText, urlForwardTo);

    setTimeout(() => {
      dispatch(actionsGridView.toggleRefreshQuery(true));
      dispatch(
        actionsGridView.updatePrevPathnameQuery(window.location.pathname)
      );
    }, 200);
  };

  const showErrorMessage = () => {
    notification.error({
      message: intl.formatMessage(Messages.queryOpenError),
    });
  };

  const pushCloseDetailStack = (urlForwardTo) => {
    const { name, stack } = closeDetailStack;
    dispatch(
      ribbonActions.updateCloseDetailStack({
        name,
        stack: [...stack, urlForwardTo],
      })
    );
  };

  return (
    <RibbonButton
      icon={<PlayCircleOutlined />}
      label={Messages.queryOpen}
      onClick={handleOpenQuery}
      disabled={id === undefined || selectedDetail?.length !== 1}
    />
  );
};
OpenQuery.propTypes = {};
export default OpenQuery;
