import React, { useState, useEffect, useMemo, useRef } from 'react';

import _ from 'lodash';
import { Tree, Typography, Row, Col } from 'antd';
import { MemberRoleSectionWrapper as SectionWrapper } from 'pages/role/components';
import { EVENT } from 'static/Constants';

import useDocumentAttachEvent from 'hooks/documentAttachEventHook';
import * as exportProductPropertiesUtils from 'utils/exportProductProperties';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

const { Title, Text, Paragraph } = Typography;

const ExportPropertiesTree = (props) => {
  const intl = useIntl();
  const movingRightToLeftKeysRef = useRef([]);
  const treeContainerRef = useRef([]);

  const {
    visible,
    dataSource,
    direction,
    addKeysHook,
    selectKeysHook,
    setLeftExpandedKeys,
    leftExpandedKeys,
    isShowSelection,
    searchText,
    propertyTitle,
    rootData,
  } = props;

  // console.log('leftExpandedKeys: ', leftExpandedKeys);
  const [addedKeys, setAddedKey] = addKeysHook;
  const [selectedKeys, setSelectedKeys] = selectKeysHook;

  const [treeData, setTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [treeHeight, setTreeHeight] = useState(450);

  const semiFlattenData = useMemo(() => {
    const semiFlattenData = [];
    exportProductPropertiesUtils.semiFlatData({
      data: treeData,
      result: semiFlattenData,
    });

    return semiFlattenData;
  }, [treeData]);

  const onExpand = (expandedKeysValue, e) => {
    if (e?.node?.data) return;
    if (direction === 'right') return;
    const newExpandedKeys = exportProductPropertiesUtils.getExpandKeysOnEvent(
      leftExpandedKeys,
      e
    );
    setLeftExpandedKeys(newExpandedKeys);
  };

  const onSelect = (selectedKeys, e) => {
    if (direction === 'right') return;
    const newExpandedKeys = exportProductPropertiesUtils.getExpandKeysOnEvent(
      leftExpandedKeys,
      e
    );
    setLeftExpandedKeys(newExpandedKeys);
  };

  const onCheck = (_, e) => {
    const { checkedNodes, node } = e;

    const checkedKeys = checkedNodes.map((nodeItem) => nodeItem.key);
    const reCheckedKeys = reGenCheckedKeys(checkedKeys);
    const reSelectedKeys = reGenSelectedKeys(reCheckedKeys);

    setCheckedKeys(reCheckedKeys);
    setSelectedKeys(reSelectedKeys);

    expandOnCheckedNode(node);
  };

  const reGenCheckedKeys = (originCheckedKeys) => {
    return originCheckedKeys.reduce((accumulator, currentKey) => {
      const currentData = semiFlattenData.find((dataItem) => {
        return dataItem.key === currentKey;
      });

      if (!currentData?.children?.length) accumulator.push(currentKey);

      return accumulator;
    }, []);
  };

  const reGenSelectedKeys = (checkedKey) => {
    if (direction === 'right') return checkedKey;

    return checkedKey.filter((keyItem) => {
      return !addedKeys.includes(keyItem);
    });
  };
  const expandOnCheckedNode = (node) => {
    //* expand this node and all its children
    if (node?.data) return;
    if (direction === 'right') return;

    const shouldCollapse = node.expanded && node.checked;

    const expandKeysOnNode = [node.key];
    exportProductPropertiesUtils.getAllChildrenKeyDeep(
      node?.children,
      expandKeysOnNode
    );

    const newExpandedKeys = shouldCollapse
      ? leftExpandedKeys.filter((leftExpandedKeyItem) => {
          return !expandKeysOnNode.includes(leftExpandedKeyItem);
        })
      : [...expandKeysOnNode, ...leftExpandedKeys];

    setLeftExpandedKeys(newExpandedKeys);
  };

  const expandAllTreeNodes = (treeData) => {
    const expandedKeys = getExpandKeyFromTreeData(treeData);
    setLeftExpandedKeys(expandedKeys);
  };

  const getExpandKeyFromTreeData = (treeData) => {
    const semiFlattenData = [];
    exportProductPropertiesUtils.semiFlatData({
      data: treeData,
      result: semiFlattenData,
    });
    return semiFlattenData.map((dataItem) => dataItem.key);
  };

  const generateTreeDataFromSource = ({ data, inCase }) => {
    const treeData = exportProductPropertiesUtils.getTreeData(data);

    if (direction === 'left') return generateLeftTree({ treeData, inCase });
    else return generateRightTree(rootData);
  };

  const generateTreeDataFromFiltered = () => {
    if (direction === 'right')
      return generateTreeDataFromSource({ data: dataSource, inCase: 'filter' });

    const filteredData = _.cloneDeep(treeData);

    const flattenData = [];

    exportProductPropertiesUtils.flatData({
      data: filteredData,
      result: flattenData,
    });

    const newTreeData = exportProductPropertiesUtils.getTreeData(flattenData);

    return generateLeftTree({ treeData: newTreeData });
  };

  const generateLeftTree = ({ treeData, inCase }) => {
    if (inCase === 'update')
      updateCheckedKeysByAddedKeys({
        treeData,
        movingKeys: movingRightToLeftKeysRef.current,
      });
    return exportProductPropertiesUtils.mapDisabledData(treeData, addedKeys);
  };

  const generateRightTree = (data) => {
    const arrRightData = data.filter((val) => {
      const propertyPathArray = val?.fieldFullPath?.split('.');
      //* remove the first path as it is module path
      if (val?.moduleDisplayName) propertyPathArray.shift();

      const realPropertyPath = propertyPathArray.join('.');
      const modulePath = val?.moduleName || 'noHeader';
      const key = modulePath + '.' + realPropertyPath;

      return addedKeys.includes(key);
    });
    const newRightData =
      exportProductPropertiesUtils.generateSourceData(arrRightData);
    const treeRightData =
      exportProductPropertiesUtils.getTreeData(newRightData);

    return treeRightData;
  };

  const filterShowSelection = () => {
    if (direction === 'right') return;

    setTreeData((prevState) => {
      const clonedData = _.cloneDeep(prevState);

      const filteredData = exportProductPropertiesUtils.createFilterData(
        clonedData,
        checkedKeys,
        addedKeys
      );

      //* all checkbox should be checked
      checkAllFilterSelection(filteredData);

      //* disabled added items
      const newState = exportProductPropertiesUtils.mapDisabledData(
        filteredData,
        addedKeys
      );

      return newState;
    });
  };

  const checkAllFilterSelection = (filteredData) => {
    // treeData
    const semiFlattenData = [];
    exportProductPropertiesUtils.semiFlatData({
      data: filteredData,
      result: semiFlattenData,
    });
    const newCheckedKeys = semiFlattenData.map((dataItem) => {
      return dataItem.key;
    });

    setCheckedKeys(newCheckedKeys);
  };

  const deFilterShowSelection = () => {
    if (direction === 'right') return;
    const treeData = exportProductPropertiesUtils.getTreeData(dataSource);
    const treeDataWithDisabledNote =
      exportProductPropertiesUtils.mapDisabledData(treeData, addedKeys);
    deFilterUpdateCheckedKeys(treeDataWithDisabledNote);
    setTreeData(treeDataWithDisabledNote);
  };

  const deFilterUpdateCheckedKeys = (data) => {
    const semiFlattenData = [];
    exportProductPropertiesUtils.semiFlatData({
      data,
      result: semiFlattenData,
    });
    const newCheckedKeys = semiFlattenData.reduce(
      (accumulator, currentDataItem) => {
        const isChecked =
          !currentDataItem.children.length &&
          checkedKeys.includes(currentDataItem.key);

        const isAllChildrenChecked =
          exportProductPropertiesUtils.checkAllChildrenCheckedDeep(
            currentDataItem,
            checkedKeys
          );

        if (isChecked || isAllChildrenChecked)
          accumulator.push(currentDataItem.key);

        return accumulator;
      },
      []
    );
    //* if there are added keys, they should be checked
    setCheckedKeys([...newCheckedKeys, ...addedKeys]);
  };

  const updateCheckedKeysByAddedKeys = ({ treeData, movingKeys }) => {
    const semiFlattenData = [];
    exportProductPropertiesUtils.semiFlatData({
      data: treeData,
      result: semiFlattenData,
    });

    const filteredOutMovingKeys = selectedKeys.filter(
      (selectedKeyItem) => !movingKeys.includes(selectedKeyItem)
    );

    const newCheckedKeys = semiFlattenData.reduce(
      (accumulator, currentDataItem) => {
        const isChecked =
          !currentDataItem?.children?.length &&
          filteredOutMovingKeys.includes(currentDataItem.key);

        const isAllChildrenAdded =
          exportProductPropertiesUtils.checkAllChildrenAddedDeep(
            currentDataItem,
            addedKeys
          );

        if (isAllChildrenAdded || isChecked)
          return [...accumulator, currentDataItem.key];
        return accumulator;
      },
      []
    );

    setCheckedKeys(newCheckedKeys);
  };

  const getExpandedKeyFromAddedKey = () => {
    return addedKeys.reduce((accumulator, currentAddedKey) => {
      const currentAddedKeyPath = currentAddedKey.split('.');
      currentAddedKeyPath.forEach((path, index) => {
        const parentKey = currentAddedKeyPath.slice(0, index + 1).join('.');
        accumulator.push(parentKey);
      });
      return accumulator;
    }, []);
  };

  const onMoveLeftCallback = (e) => {
    const { rightSelectedKeys: movingKeys } = e.detail;

    movingRightToLeftKeysRef.current = movingKeys;

    if (direction === 'left') return;
    setCheckedKeys([]);
  };

  useDocumentAttachEvent({
    name: EVENT.EXPORT_PRODUCT_PROPERTIES_TRANSFER.toLeft,
    handler: onMoveLeftCallback,
  });

  // const initTreeData = () => {
  //   const treeData = generateTreeData();

  //   setTreeData(treeData);
  // };

  const updateTreeData = () => {
    const treeData = isShowSelection
      ? generateTreeDataFromFiltered()
      : generateTreeDataFromSource({ data: dataSource, inCase: 'update' });

    setTreeData(treeData);
  };

  const searchProperties = () => {
    if (direction === 'right') return;

    const originTreeData = generateTreeDataFromSource({
      data: dataSource,
      inCase: 'search',
    });

    const searchedResults =
      exportProductPropertiesUtils.searchItems(originTreeData, searchText) ||
      [];

    setTreeData(searchedResults);
    expandAllTreeNodes(searchedResults);
  };

  const getTotalItemSelected = () => {
    const totalItemSelected = exportProductPropertiesUtils.getTotalItemSelected(
      {
        data: semiFlattenData,
        selectedKeys,
        addedKeys: direction === 'left' ? addedKeys : [],
      }
    );
    return totalItemSelected;
  };

  const clearState = () => {
    if (visible) return;

    setAddedKey([]);
    setSelectedKeys([]);
    setTreeData([]);
    setCheckedKeys([]);
  };
  const getTreeHeight = () => {
    const treeContainer = treeContainerRef.current;

    setTreeHeight(treeContainer.offsetHeight);
  };

  const getTreeHeightDebounce = _.debounce(getTreeHeight, 500);

  //* make tree vertical responsive
  useEffect(() => {
    window.addEventListener('resize', getTreeHeightDebounce);
    return () => {
      window.removeEventListener('resize', getTreeHeightDebounce);
    };
  }, []);

  useEffect(() => {
    isShowSelection && filterShowSelection();
    !isShowSelection && deFilterShowSelection();
  }, [isShowSelection]);

  useEffect(() => {
    if (direction === 'left' && searchText) searchProperties();
    else updateTreeData();
  }, [addedKeys, searchText, dataSource]);

  useEffect(() => {
    clearState();
  }, [visible]);

  const renderTitle = (nodeData) => {
    const { title, data, fieldName } = nodeData;

    if (title)
      return (
        <Title level={5} className='export-properties__tree-item-group-title'>
          {title}
        </Title>
      );

    return (
      <div
        className='export-properties__tree-item-data'
        style={{ padding: '0 8px' }}
      >
        <Row>
          <Text className='export-properties__tree-item-title'>
            {data?.displayName || fieldName}
          </Text>
        </Row>
        {data ? (
          <Row gutter={[8]}>
            <Col span={14}>
              <Text strong className='export-properties__tree-item-label'>
                {intl.formatMessage(
                  Messages.exportProductPropertiesFieldNameLabel
                )}
                :{' '}
              </Text>
              <Text>{data?.fieldName}</Text>
            </Col>
            <Col span={10}>
              <Text strong>
                {intl.formatMessage(
                  Messages.exportProductPropertiesFieldTypeLabel
                )}
                :{' '}
              </Text>
              <Text style={{ textTransform: 'capitalize' }}>
                {data.dataType}
              </Text>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  const expandedKeys =
    direction === 'right' ? getExpandedKeyFromAddedKey() : leftExpandedKeys;

  const sectionTitle =
    direction === 'left' ? 'productProperties' : propertyTitle;

  const totalItemSelected = getTotalItemSelected({
    data: dataSource,
    selectedKeys,
  });

  const totalItemSelectedText =
    totalItemSelected > 1 ? 'totalItemsSelected' : 'totalItemSelected';

  return (
    <div className='export-properties__tree-wrapper'>
      <SectionWrapper
        style={{ height: 'calc(100% - 30px)' }}
        title={intl.formatMessage(Messages.sectionTitle[sectionTitle])}
      >
        <div className='export-properties__tree-inner' ref={treeContainerRef}>
          <Tree
            blockNode
            checkable
            height={treeHeight}
            selectable={true}
            switcherIcon={direction === 'right' ? () => ' ' : undefined}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            onCheck={onCheck}
            onSelect={onSelect}
            checkedKeys={checkedKeys}
            treeData={treeData}
            titleRender={renderTitle}
            defaultExpandParent={direction === 'right'}
          />
        </div>
      </SectionWrapper>
      {propertyTitle === 'exportProperties' && (
        <Paragraph className='export-properties__total-selected-text'>
          {totalItemSelected}{' '}
          {intl.formatMessage(Messages[totalItemSelectedText])}
        </Paragraph>
      )}
    </div>
  );
};

export default ExportPropertiesTree;
