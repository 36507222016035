import React, { forwardRef, useImperativeHandle } from 'react';
import { Col, Row, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { CustomNotification, Form, SectionWrapper } from 'common/components';
import Directions from './components/Directions';
import Uses from './components/Uses';
import OtherInformation from './components/OtherInformation';
import ActiveIngredients from './components/ActiveIngredients';
import InactiveIngredients from './components/InactiveIngredients';
import Warning from './components/Warning';

import { useAddDrugFactsLabel } from './hook';
import { DRUG_FACTS_INITIAL_FORM } from '../../constants';

import './AdDrugFactsLabel.less';
import { getKeyDrugPanels } from '../../../multiple-panel/facts-panel/useQueryFactsPanel';
import { getKeyQaSpecDrugFactPanels } from 'pages/qa-spec/components/qa-nutrition-data/hook';

const labelTypeOptions = [
  {
    label: 'Standard',
    value: 'US_FDA_NFP_2020_STANDARD',
  },
];

const AddDrugFactsLabel = forwardRef((props, forwardedRef) => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const { visible, factsPanel, typeView, onSubmitFail, onSubmitSuccess } =
    props;

  const queryClient = useQueryClient();

  const mutation = useAddDrugFactsLabel({ productId: id, typeView });

  const submitForm = async () => {
    const formValues = await form.validateFields();

    if (!formValues) return;
    const { useDetail, useText, ...restFormValues } = formValues;

    const params = {
      productItemId: +id,
      data: {
        id: factsPanel?.id || 0,
        uses: {
          useText,
          useDetail,
        },
        ...restFormValues,
      },
      deletedFields: [],
    };

    mutation.mutate(params, {
      onSuccess: (result) => {
        if (result.isSuccess) {
          resetForm();
          onSubmitSuccess && onSubmitSuccess();

          queryClient.invalidateQueries({
            queryKey: getKeyDrugPanels(id),
          });
          queryClient.invalidateQueries({
            queryKey: getKeyQaSpecDrugFactPanels(id),
          });
          CustomNotification.success('Update Drug Facts successfully');
        } else {
          form.resetFields();

          onSubmitFail && onSubmitFail();

          CustomNotification.error('Something went wrong!');
        }
      },
    });
  };

  const initForm = (on) => {
    const initialData = on === 'add' ? DRUG_FACTS_INITIAL_FORM : factsPanel;
    form.setFieldsValue({
      ...initialData,
      useText: initialData?.uses?.useText,
      useDetail: initialData?.uses?.useDetail,
    });
  };

  const resetForm = () => {
    form.resetFields();
  };

  useImperativeHandle(forwardedRef, () => {
    return {
      submitForm,
      initForm,
      resetForm,
    };
  });

  return visible ? (
    <Form form={form} layout='vertical' className='drug-facts-form'>
      <Row wrap={false} gutter={16}>
        <Col span={8}>
          <Form.Item name='nutrientFormatType' label='Label Type'>
            <Select
              defaultValue={labelTypeOptions}
              options={labelTypeOptions}
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            />
          </Form.Item>
          <SectionWrapper title='Directions'>
            <Directions />
          </SectionWrapper>
          <SectionWrapper title='Uses'>
            <Uses />
          </SectionWrapper>
        </Col>
        <Col span={8}>
          <SectionWrapper title='Active Ingredients'>
            <ActiveIngredients />
          </SectionWrapper>
          <SectionWrapper title='Inactive Ingredients'>
            <InactiveIngredients />
          </SectionWrapper>
        </Col>
        <Col span={8}>
          <SectionWrapper title='Warning'>
            <Warning />
          </SectionWrapper>
          <SectionWrapper title='Other Information'>
            <OtherInformation />
          </SectionWrapper>
        </Col>
      </Row>
    </Form>
  ) : null;
});

export default AddDrugFactsLabel;
