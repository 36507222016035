import { combineReducers } from 'redux';
import memberProfileReducer, {
  initialState as initialStateProfile,
} from './reducers/memberProfileReducer';
import memberOverviewReducer, {
  initialState as initialStateOverview,
} from './reducers/memberOverviewReducer';
import memberContactHistoryReducer, {
  initialState as initialContactHistory,
} from './reducers/memberContactHistoryReducer';
import memberColumnGridReducer, {
  initialState as initialStateMemberColumnGridReducer,
} from './reducers/memberColumnGridReducer';
import memberTagLineReducer, {
  initialState as initialStateTagLineReducer,
} from './reducers/memberTagLineReducer';
import memberPreviewReducer, {
  initialState as initialStatePreviewReducer,
} from './reducers/memberPreviewReducer';

const initialStateMember = {
  profile: null,
  contactHistory: {},
  overview: {},
  gridColumn: null,
  tagLine: {},
};

export {
  initialStateMember,
  initialStateProfile,
  initialStateOverview,
  initialContactHistory,
  initialStateMemberColumnGridReducer,
  initialStateTagLineReducer,
  initialStatePreviewReducer,
};

export default combineReducers({
  profile: memberProfileReducer,
  contactHistory: memberContactHistoryReducer,
  overview: memberOverviewReducer,
  gridColumn: memberColumnGridReducer,
  tagLine: memberTagLineReducer,
  preview: memberPreviewReducer,
});
