import { useEffect } from 'react';
import { useAsync } from 'hooks';

import { getProductFullView } from 'services/product';

export const useGetPublishingProduct = ({ productId, isEnabled }) => {
  const { data, run } = useAsync();

  useEffect(() => {
    if (isEnabled) {
      run(
        getProductFullView({
          productItemId: productId,
        })
      );
    }
  }, [productId, run, isEnabled]);

  return {
    publishingProduct: data ?? {},
  };
};
