import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

//? COMPONENT
import { Col, Form, Modal, Row, Steps } from 'antd';
import {
  StepForm,
  StyledModal,
  WithAbsoluteContainer,
} from 'common/components';
import AssetGS1Edit from 'pages/asset-full-view/components/add-link-product/AssetGS1Edit';
import UploadPimAsset from './UploadPimAsset';

//? REDUX

//? UTILS
import { PIM_ASSET_TYPE_DEFINE } from 'static/Constants';

import * as _ from 'lodash';

import { useGetProductFullView } from 'hooks';

import './styles.less';

const currentStepDefine = [0, 1];
const stepDefault = currentStepDefine[0];

const assetIdDefine = [null, '', Number];
const assetIdDefault = assetIdDefine[0];

const { Step } = Steps;

const AddAssetPimModal = (props) => {
  const {
    addType,
    onCloseModal,
    getImagery,
    getMarketingAssets,
    getDocument,
    qaSpec,
    certificateType,
    isLoadTestResults = false,
    title,
    noCapitalize = false,
  } = props;

  const [preview, setPreview] = useState(null);
  const [assetId, setAssetId] = useState(assetIdDefault);
  const [currentStep, setCurrentStep] = useState(stepDefault);
  const [isAllowedUpload, setIsAllowedUpload] = useState(false);

  const [PLANO, ADD, MARKET, DOC] = PIM_ASSET_TYPE_DEFINE;

  const { productId: qaSpecIdParam, id: productIdParam } = useParams();

  const productIdParams = qaSpecIdParam || productIdParam;

  const { productFull } = useGetProductFullView({ productId: productIdParams });

  const productInfo = props.productDetail || productFull;

  const productId = productInfo?.productId;

  const [formInstance] = Form.useForm();

  const onSelectAttachFile = (fileList) => {
    if (!fileList) return;
    const file = fileList.slice(-1).pop();
    if (!file) return;

    setAssetId(assetIdDefault);

    if (file?.status === 'error.fileType') {
      setIsAllowedUpload(false);
      return;
    }

    setIsAllowedUpload(true);
  };

  const handleCancelPreview = () => setPreview({ previewVisible: false });

  const handleBackToCreateAsset = () => setCurrentStep(0);

  const handleMoveToLinkAsset = () => setCurrentStep(1);

  const handleUploadSuccess = (res) => {
    if (!res?.data) return;

    const { data } = res?.data;

    if (!assetId) {
      setAssetId(data?.assetId);
    }
    handleMoveToLinkAsset();
    setIsAllowedUpload(false);
  };

  //!- RENDER
  const stepDefine = [
    {
      step: 0,
      component: (
        <UploadPimAsset
          formInstance={formInstance}
          onSelectAttachFile={onSelectAttachFile}
          addType={addType}
          assetId={assetId}
          handleMoveToLinkAsset={handleMoveToLinkAsset}
          isAllowedUpload={isAllowedUpload}
          handleUploadSuccess={handleUploadSuccess}
          productInfo={productInfo}
          isLoadTestResults={isLoadTestResults}
        />
      ),
    },
    {
      step: 1,
      component: (
        <div className='pim-add-asset-modal__gs1-edit'>
          <AssetGS1Edit
            isPimImport
            pimId={productId}
            assetId={assetId}
            productInfo={productInfo}
            isDisabledGtin={true}
            handleBackToCreateAsset={handleBackToCreateAsset}
            isPlanoType={addType === PLANO}
            onReloadPage={() => {
              if (qaSpec) {
                getDocument && getDocument();
              } else {
                getImagery && getImagery();
                onCloseModal && onCloseModal();
                if (
                  (addType === ADD || addType === MARKET) &&
                  getMarketingAssets
                ) {
                  getMarketingAssets();
                }

                if (addType === DOC) {
                  getDocument && getDocument();
                }
              }
            }}
            addType={addType}
            qaSpec={qaSpec}
            certificateType={certificateType}
            isImageTypeFromLinkProduct={
              addType === MARKET || addType === PLANO || addType === ADD
            }
          />
        </div>
      ),
    },
  ];
  const renderTitleModal = () => {
    if (noCapitalize && title) {
      return <span>{title}</span>;
    }
    if (title) {
      return (
        <span style={{ textTransform: 'capitalize' }}>{title && title}</span>
      );
    }

    return (
      <>
        {isLoadTestResults ? (
          <span style={{ textTransform: 'capitalize' }}>load test results</span>
        ) : (
          <span>
            Link <span style={{ textTransform: 'capitalize' }}>{addType} </span>
            {qaSpec
              ? 'of Documents & Certificates to QA Specification'
              : 'Asset to Product'}
          </span>
        )}
      </>
    );
  };

  return (
    <StyledModal
      wrapClassName={qaSpec ? 'qa-spec-add-asset-modal' : 'pim-add-asset-modal'}
      title={renderTitleModal()}
      onCancel={onCloseModal}
      onOk={() => setCurrentStep((prev) => prev + 1)}
      maskClosable={false}
      footer={null}
      visible
      destroyOnClose
    >
      <Row style={{ marginBottom: 20 }}>
        <Col xs={24}>
          <Steps current={currentStep}>
            <Step title='Create an asset' />
            <Step
              title={qaSpec ? 'Link to QA Specification' : 'Link to product'}
            />
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <StepForm currentStep={currentStep} stepDefine={stepDefine} />
        </Col>
      </Row>
      <WithAbsoluteContainer>
        <Modal
          visible={preview?.previewVisible}
          title={preview?.previewTitle}
          footer={null}
          onCancel={handleCancelPreview}
        >
          <img
            alt='example'
            style={{ width: '100%' }}
            src={preview?.previewImage}
          />
        </Modal>
      </WithAbsoluteContainer>
    </StyledModal>
  );
};

AddAssetPimModal.propTypes = {
  addType: PropTypes.string,
  onCloseModal: PropTypes.func,
  getImagery: PropTypes.func,
};

export default AddAssetPimModal;
