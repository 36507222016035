import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import * as _ from 'lodash';
import { AssetItemCard } from 'pages/branded-assets/components';

function AssetDetailView(props) {
  const { detailData, handleToggleAssetContext } = props;
  return (
    <React.Fragment>
      {!!detailData && (
        <List
          grid={{
            gutter: 10,
          }}
          dataSource={detailData}
          renderItem={(detailItemData, ind) => (
            <List.Item>
              <AssetItemCard
                key={
                  'asset-detail-view__card-item-' +
                  _.get(detailItemData, 'id') +
                  ind
                }
                detailItemData={detailItemData}
                handleToggleAssetContext={handleToggleAssetContext}
              />
            </List.Item>
          )}
        />
      )}
    </React.Fragment>
  );
}

AssetDetailView.propTypes = {
  // ? tileData -> data of tile table
  detailData: PropTypes.array,
  // ? handleToggleAssetContext
  handleToggleAssetContext: PropTypes.func,
};

export default React.memo(AssetDetailView);
