import React, { memo } from 'react';
// antd
import { Typography, Badge, Avatar, Tooltip, Button } from 'antd';
import {
  FieldStringOutlined,
  CalendarOutlined,
  CheckOutlined,
  NumberOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import './ModalFormQasRequireField.less';

const QasField = (props) => {
  const {
    item,
    shouldShowSelection,
    selectedId,
    dragDisabled,
    highlightSearchText,
    handleRemove,
  } = props;
  return (
    <Badge
      className='qas-spec-draggable__badge'
      count={shouldShowSelection ? selectedId?.length : null}
    >
      {item?.dataType === 'string' && (
        <Avatar
          className='qas-spec-draggable__data-type'
          shape='square'
          icon={<FieldStringOutlined />}
        />
      )}
      {item?.dataType === 'datetime' && (
        <Avatar
          className='qas-spec-draggable__data-type'
          shape='square'
          icon={<CalendarOutlined />}
        />
      )}
      {item?.dataType === 'bool' ||
        (item?.dataType === 'boolean' && (
          <Avatar
            className='qas-spec-draggable__data-type'
            shape='square'
            icon={<CheckOutlined />}
          />
        ))}
      {(item?.dataType === 'int' ||
        item?.dataType === 'double' ||
        item?.dataType === 'double?' ||
        item?.dataType === 'long' ||
        item?.dataType === 'decimal') && (
        <Avatar
          className='qas-spec-draggable__data-type'
          shape='square'
          icon={<NumberOutlined />}
        />
      )}
      <Typography.Text
        className='qas-spec-draggable__label'
        ellipsis={{ tooltip: item?.displayName }}
      >
        {dragDisabled ? item?.displayName : highlightSearchText}
      </Typography.Text>
      {dragDisabled && (
        <Tooltip
          title={`Remove field ${item?.displayName}`}
          className='qas-spec-draggable__remove'
        >
          <Button
            danger
            type='text'
            icon={<CloseOutlined />}
            style={{ margin: 2 }}
            onClick={() => {
              handleRemove(item?.fieldFullPath);
            }}
          />
        </Tooltip>
      )}
    </Badge>
  );
};

export default memo(QasField);
