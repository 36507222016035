import React from 'react';

import { MemberThumbnailCard } from 'pages/branded-members/components';

const EditingSupplierItem = ({ memberSupplier }) => {
  return (
    <MemberThumbnailCard
      dataDetail={memberSupplier}
      selected={false}
      isShareBtn={false}
      hideCheckbox
    />
  );
};

export default EditingSupplierItem;
