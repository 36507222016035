import _ from 'lodash';
import {
  HIERARCHY_LEVEL,
  INIT_ADDING_HIERARCHY_LEVELS,
} from 'static/Constants';

export const getOffsetLevels = (productList) => {
  const highestLevel = getHighestLevel(productList);
  const highestLevelKey = Object.values(HIERARCHY_LEVEL).find((LEVEL) => {
    return LEVEL.NAME === highestLevel;
  })?.KEY;

  const highestLevelIndex = INIT_ADDING_HIERARCHY_LEVELS.findIndex((level) => {
    return (
      level.packageLevel === highestLevel ||
      level?.equalWith?.includes(highestLevelKey)
    );
  });

  return INIT_ADDING_HIERARCHY_LEVELS.slice(0, highestLevelIndex);
};

export const getHighestLevel = (levelList) => {
  if (!levelList) return;

  const orderedLevels = Object.values(HIERARCHY_LEVEL).map(
    (LEVEL) => LEVEL.NAME
  );

  const currentProductPackageList = levelList
    .filter((levelItem) => {
      return levelItem.productItemId;
    })
    .map((levelItem) => levelItem?.packageLevel);

  const sortedLevels = currentProductPackageList.sort((a, b) => {
    return orderedLevels.indexOf(a) - orderedLevels.indexOf(b);
  });

  return sortedLevels[0];
};

export const getInitLevelList = (productList) => {
  return productList.map((product) => {
    const { packageLevel, productItemId, parentProductItemId } = product;

    const levelKey = Object.values(HIERARCHY_LEVEL).find(
      (LEVEL) => LEVEL.NAME === packageLevel
    )?.KEY;

    const equalWith = INIT_ADDING_HIERARCHY_LEVELS.find((LEVEL) =>
      LEVEL?.equalWith?.includes(levelKey)
    )?.equalWith;

    return {
      packageLevel,
      productItemId,
      parentProductItemId,
      key: levelKey,
      revertProduct: productItemId,
      quantity: product.quantity,
      equalWith,
    };
  });
};

export const sortLevelList = (levelList) => {
  if (!levelList) return levelList;
  let sortResult = [];
  let srcList = reIndexLevelList(levelList);
  //* move highest level to the top of the list
  const highestLevel = getHighestLevel(srcList);

  sortResult.push(
    srcList.find((levelItem) => levelItem.packageLevel === highestLevel)
  );

  srcList = srcList.filter(
    (levelItem) => levelItem.packageLevel !== highestLevel
  );

  //* move children level
  while (srcList.length) {
    const cloneResult = _.cloneDeep(sortResult);

    // eslint-disable-next-line no-loop-func
    sortResult.forEach((sortedLevel) => {
      const movedItems = srcList.filter(
        (levelItem) =>
          levelItem.parentProductItemId === sortedLevel.productItemId
      );

      const insertIndex = cloneResult.findIndex(
        (clonedItem) => clonedItem.index === sortedLevel.index
      );

      cloneResult.splice(insertIndex + 1, 0, ...movedItems);

      //* remove moved items in srcList
      const removedSrcItems = movedItems.map(
        (movedItem) => movedItem.parentProductItemId
      );
      srcList = srcList.filter(
        (srcLevel) => !removedSrcItems.includes(srcLevel.parentProductItemId)
      );
    });

    //* refresh sort result before start new while-loop round
    sortResult = cloneResult;
  } //* end while-loop

  return sortResult;
};

export const fillLevelList = (levelList) => {
  const reIndexedList = reIndexLevelList(levelList);

  const accumulatorInit = _.cloneDeep(reIndexedList);

  return reIndexedList.reduce((accumulator, currentLevel) => {
    //* It is highest level in levelList if it has no parent - do nothing with it
    if (!currentLevel.parentProductItemId) return accumulator;

    const parentLevel = levelList.find(
      (levelItem) =>
        levelItem.productItemId === currentLevel.parentProductItemId
    );

    const parentLevelPackageIndex = INIT_ADDING_HIERARCHY_LEVELS.findIndex(
      (LEVEL) => {
        return (
          LEVEL.key === parentLevel.key ||
          LEVEL?.equalWith?.includes(parentLevel.key)
        );
      }
    );

    const currentLevelPackageIndex = INIT_ADDING_HIERARCHY_LEVELS.findIndex(
      (LEVEL) => {
        return (
          LEVEL.key === currentLevel.key ||
          LEVEL?.equalWith?.includes(currentLevel.key)
        );
      }
    );

    const fillLevel = INIT_ADDING_HIERARCHY_LEVELS.slice(
      parentLevelPackageIndex + 1,
      currentLevelPackageIndex
    ).map((fillLevelItem) => ({ ...fillLevelItem, index: null })); //* remove index to avoid conflicting index with insert index

    const insertIndex = accumulator.findIndex((level) => {
      return level.index === currentLevel.index;
    });

    accumulator.splice(insertIndex, 0, ...fillLevel);
    return accumulator;
  }, accumulatorInit);
};

export const reIndexLevelList = (levelList) => {
  return levelList.map((level, index) => {
    return {
      ...level,
      index,
    };
  });
};

export const getHighestLevelProduct = ({ levelList }) => {
  const highestLevel = getHighestLevel(levelList);

  const hightestLevelProduct = levelList.find(
    (product) => product.packageLevel === highestLevel
  );

  return hightestLevelProduct?.productItemId;
};

export const getSelectedHierarchyHighestLevelProduct = ({
  selectedHierarchy,
  levelList,
}) => {
  if (!selectedHierarchy) return;

  const highestLevel = getHighestLevel(levelList);
  if (!highestLevel) return;

  const hightestLevelProduct = selectedHierarchy.productItemLinks.find(
    (product) => product.packageLevel === highestLevel
  );

  return hightestLevelProduct?.productItemId;
};

export const getChildrenLevelList = ({ selectedLevel, levelList }) => {
  const selectedLevelIndex = selectedLevel.index;

  const nextSameLevelIndex = levelList.findIndex((level) => {
    return level.key === selectedLevel.key && level.index > selectedLevelIndex;
  });

  const childrenLevelList =
    nextSameLevelIndex > -1
      ? levelList.slice(selectedLevelIndex + 1, nextSameLevelIndex)
      : levelList.slice(selectedLevelIndex + 1);

  return childrenLevelList;
};

export const setParentProductForChildrenLevel = ({
  levelList,
  productList,
  level,
  addedProductId,
}) => {
  const HIERARCHY_LEVEL_VALUES = Object.values(HIERARCHY_LEVEL);
  const levelConstantIndex = HIERARCHY_LEVEL_VALUES.find(
    (LEVEL_ITEM) => LEVEL_ITEM.KEY === level.key
  )?.INDEX;
  const lowerLevelPackage = HIERARCHY_LEVEL_VALUES.filter(
    (LEVEL_ITEM) => LEVEL_ITEM.INDEX > levelConstantIndex
  ).map((LEVEL_ITEM) => LEVEL_ITEM.NAME);

  const loopList = levelList.filter(
    (levelItem) => levelItem.index > level.index
  );

  let stop = false;
  loopList.forEach((loopLevelItem) => {
    if (stop) return;
    if (!lowerLevelPackage.includes(loopLevelItem.packageLevel)) {
      stop = true;
      return;
    }

    //* no product - no parent
    if (!loopLevelItem.productItemId) return;

    if (loopLevelItem.parentProductItemId) {
      const parentProductItem = productList.find(
        (productItem) =>
          productItem.productItemId === loopLevelItem.parentProductItemId
      );

      if (lowerLevelPackage.includes(parentProductItem?.packageLevel)) {
        return;
      }
    }

    _.set(
      levelList,
      `[${loopLevelItem.index}].parentProductItemId`,
      addedProductId
    );

    //* set parent for only one closest level
    stop = true;
  });
};

export const setParentProductForThisLevel = ({ levelList, level }) => {
  const levelConstantIndex = INIT_ADDING_HIERARCHY_LEVELS.findIndex(
    (levelItem) =>
      levelItem.key === level.key || levelItem?.equalWith?.includes(level.key) //* Display Shipper
  );

  const upperLevel = Object.values(HIERARCHY_LEVEL)
    .filter((LEVEL) => {
      return LEVEL.INDEX < levelConstantIndex;
    })
    .map((LEVEL) => LEVEL.KEY);

  const loopList = levelList.filter(
    (levelItem) => levelItem.index < level.index
  );

  let isSet = false;
  upperLevel.reverse().forEach((upperLevelPackage) => {
    if (isSet) return;
    let nextPackageLevel = false;

    _.forEachRight(loopList, (levelItem) => {
      if (nextPackageLevel) return;
      if (isSet) return;
      if (levelItem.key !== upperLevelPackage) {
        return;
      }
      if (!levelItem.productItemId) {
        nextPackageLevel = true;
        return;
      }

      _.set(
        levelList,
        `${level.index}.parentProductItemId`,
        levelItem.productItemId
      );
      isSet = true;
    });
  });
};

export const checkRevertable = ({
  productList,
  level,
  shouldChangeProduct,
}) => {
  const originalProduct = level?.revertProduct;
  const setProduct = productList.find(
    (product) => product?.productItemId === level?.productItemId
  )?.productItemId;

  if (shouldChangeProduct)
    return originalProduct && setProduct && originalProduct !== setProduct;

  return originalProduct && originalProduct !== setProduct;
};

export const getInsertPosition = ({ level, levelList }) => {
  if (!level) return;
  const levelConstantIndex = INIT_ADDING_HIERARCHY_LEVELS.findIndex(
    (LEVEL) => level.key === LEVEL.key || level?.equalWith?.includes(LEVEL.key)
  );

  const higherLevelsList = INIT_ADDING_HIERARCHY_LEVELS.slice(
    0,
    levelConstantIndex + 1
  ).map((levelItem) => levelItem.key);

  return _.findIndex(
    levelList,
    (levelItem) => {
      return higherLevelsList.includes(levelItem.key);
    },
    level.index + 1
  );
};

export const getAppendLevels = (levelData) => {
  const { packageLevel, productItemId, equalWith } = levelData;

  const levelIndex = INIT_ADDING_HIERARCHY_LEVELS.findIndex(
    (LEVEL) =>
      LEVEL.packageLevel === packageLevel || equalWith?.includes(LEVEL.key)
  );

  const appendLevels = INIT_ADDING_HIERARCHY_LEVELS.slice(levelIndex + 1);

  return appendLevels.map((level, index) => ({
    ...level,
    temp: true,
    productItemId: null,
    parentProductItemId: index === 0 ? productItemId : null,
  }));
};

export const getOriginalProduct = ({
  selectedHierarchy,
  productPackageLevel,
}) => {
  if (!selectedHierarchy) return;
  return selectedHierarchy.productItemLinks.find(
    (product) => product.packageLevel === productPackageLevel
  );
};

export const getLevelProduct = ({ level, productList }) => {
  return productList?.find(
    (product) => product.productItemId === level.productItemId
  );
};

export const getRemovedLevelResultList = ({ deletedLevel, levelList }) => {
  const childrenLevelList = getChildrenLevelList({
    selectedLevel: deletedLevel,
    levelList: levelList,
  });

  const childrenLevelListIndex = childrenLevelList.map((level) => level.index);

  const highestLevel = getHighestLevel(levelList);

  return levelList.filter((levelItem) => {
    const isDeletedLevel =
      levelItem.index === deletedLevel.index && levelItem.index > 4;

    const isChildLevelTemps =
      childrenLevelListIndex.includes(levelItem.index) &&
      levelItem.temp === true &&
      deletedLevel.packageLevel !== highestLevel;

    return !isDeletedLevel && !isChildLevelTemps;
  });
};

export const getInitialQuantity = ({ levelList }) => {
  return levelList.reduce((accumulator, currentLevel, index) => {
    _.set(accumulator, `${index}`, currentLevel.quantity);

    return accumulator;
  }, {});
};

export const getInitialProductList = ({ productList, currentProduct }) => {
  return productList.map((product) => {
    const isCurrentProduct = product.productItemId === currentProduct.productId;

    const productName = isCurrentProduct
      ? currentProduct.productName
      : product.productName;

    const thumbnail = isCurrentProduct
      ? currentProduct.productPrimaryImage
      : product.productImage;

    return {
      ...product,
      productName,
      thumbnail,
    };
  });
};

export const countCurrentProduct = ({ levelList, currentProduct }) => {
  return levelList.reduce((accumulator, currentLevel) => {
    if (currentLevel.productItemId === currentProduct.productId) ++accumulator;

    return accumulator;
  }, 0);
};
