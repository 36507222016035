import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space } from 'antd';
import * as globalSelectors from 'redux/global/selectors';
import ChatMinimizeItem from './ChatMinimizeItem';
import * as chatSelectors from '../../controller/selectors';

const ChatMinimizeList = (props) => {
  const {
    className,
    minimizedChatThreadList,
    toggleChatBox,
    communicationItemData,
    hasNewMsg,
    newMsgInfo,
    onCloseChatView,
    view,
    setView,
  } = props;

  const messageNotifyCount = useSelector(
    globalSelectors.selectMessageNotifyCount()
  );

  const trimSize = 3;
  const trimmedList = minimizedChatThreadList.slice(0, trimSize);
  const hiddenList = minimizedChatThreadList.slice(
    trimSize - minimizedChatThreadList.length
  );
  const hiddenCount = minimizedChatThreadList.length - trimSize;
  const shouldShowMore = minimizedChatThreadList.length > trimSize;
  // const newThreadInfo = useSelector(chatSelectors.selectThreadInfo());

  const renderChatThreadList = () => {
    return (
      <>
        {trimmedList?.reverse().map((thread) => {
          return (
            <ChatMinimizeItem
              key={thread?.id}
              threadItem={thread}
              minimizedChatThreadList={minimizedChatThreadList}
              communicationItemData={communicationItemData}
              hasNewMsg={hasNewMsg}
              newMsg={newMsgInfo}
              threadNotify={hasNewMsg}
              notifyCount={messageNotifyCount}
              toggleChatBox={toggleChatBox}
              view={view}
              setView={setView}
            />
          );
        })}
      </>
    );
  };

  const renderShowMore = () => {
    return shouldShowMore ? (
      <ChatMinimizeItem
        isShowMore
        hiddenCount={hiddenCount}
        hiddenList={hiddenList}
        communicationItemData={communicationItemData}
        minimizedChatThreadList={minimizedChatThreadList}
        notifyCount={messageNotifyCount}
        toggleChatBox={toggleChatBox}
        view={view}
        setView={setView}
      />
    ) : null;
  };

  return (
    <Space direction='vertical' className={className} size='small'>
      {renderShowMore()}
      {renderChatThreadList()}
      <ChatMinimizeItem
        isToggle={true}
        newMsg={newMsgInfo}
        notifyCount={messageNotifyCount}
        toggleChatBox={toggleChatBox}
        onCloseChatView={onCloseChatView}
        isMainChatMinimize={true}
        view={view}
        setView={setView}
      />
    </Space>
  );
};

export default ChatMinimizeList;
