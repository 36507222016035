import { CUSTOM_INPUT_TYPES } from 'static/minimumRequirementFieldsSchema';
import * as constants from 'static/Constants';
import memberDetailSchema from 'pages/company-profile/components/tabs/overview/editDetailSchema';
import { formatSpecialEnum } from 'pages/product-full-view/shared/utils';

const inputTypeCollection = {
  String: 'text',
  Int32: 'number',
  Int64: 'number',
  Decimal: 'number',
  Double: 'number',
  Long: 'number',
  Boolean: 'checkbox',
  dateTime: 'datePicker',
  DateTime: 'datePicker',
};

export const getMemberPropertySchema = (
  requirementPropertyItem,
  productEnums
) => {
  const { fieldFullPath, dataType, isMultipleValue } = requirementPropertyItem;
  const propertySchemaInDetailView = memberDetailSchema.find((schemaItem) => {
    return schemaItem?.name?.toLowerCase() === fieldFullPath?.toLowerCase();
  });

  if (propertySchemaInDetailView) {
    propertySchemaInDetailView.options = mapEnumToPropertiesSchema(
      propertySchemaInDetailView,
      productEnums
    );
  }
  const optionsCollection = {
    Visibility: constants.MEMBER_PUBLIC_STATUS_OPTIONS,
    ProductsVisibility: constants.MEMBER_PUBLIC_STATUS_OPTIONS,
    DigitalAssetsVisibility: constants.MEMBER_PUBLIC_STATUS_OPTIONS,
    UserAccounts: [
      ...constants.MEMBER_PUBLIC_STATUS_OPTIONS,
      { label: 'Main Contact Only', value: 'Main Contact Only' },
    ],
    FoldersVisibility: [
      ...constants.MEMBER_PUBLIC_STATUS_OPTIONS,
      { label: 'Only If Share', value: 'Only If Share' },
    ],
    LandingPage: constants.LANDING_PAGE,
    PasswordStrength: constants.PASSWORD_STRENGTH,
    Gtinupc12: constants.GTIN_UPC12,
    UserPermission: constants.USER_PERMISSION,
  };

  const options = optionsCollection[fieldFullPath];

  const propertySchema = propertySchemaInDetailView
    ? propertySchemaInDetailView
    : {
        type: options ? 'select' : inputTypeCollection[dataType],
        isList: isMultipleValue,
        options,
      };

  return { ...propertySchema, name: fieldFullPath };
};

export const getAssetPropertySchema = (requirementPropertyItem) => {
  const { fieldFullPath, dataType, isMultipleValue } = requirementPropertyItem;

  const assetOptionCollection = {
    Status: constants.ASSET_STATUS_OPTIONS.map((option) => {
      return {
        value: option,
        label: option,
      };
    }),
    Visibility: constants.ASSET_VISIBILITY.map((item) => {
      return {
        value: item,
        label: item,
      };
    }),
    AssetType: constants.ASSET_TYPE.map((type) => {
      return {
        value: type,
        label: type,
      };
    }),
    AssetSubType: [
      ...constants.ASSET_SUBTYPE,
      ...constants.COMPANY_SUBTYPE,
      ...constants.DOCUMENT_SUBTYPE,
    ].map((type) => {
      return {
        value: type,
        label: type,
      };
    }),
  };

  const options = assetOptionCollection[fieldFullPath];

  return {
    name: fieldFullPath,
    type: options ? 'select' : inputTypeCollection[dataType],
    isList: isMultipleValue,
    options,
  };
};

export const getProductPropertySchema = (
  requirementPropertyItem,
  productEnums
) => {
  return getProductSchemaModule(requirementPropertyItem, productEnums);
};

const getProductSchemaModule = (requirementPropertyItem, productEnums) => {
  const inputSchema = transformPropertyDataToInputSchema(
    requirementPropertyItem,
    requirementPropertyItem?.listName,
    productEnums
  );

  return {
    ...inputSchema,
  };
};

const transformPropertyDataToInputSchema = (
  requirementPropertyItem,
  listName,
  productEnums
) => {
  const propertyEnum = productEnums.find(
    (item) => item.enumName === listName
  )?.enumProperties;

  const options = propertyEnum?.length
    ? propertyEnum?.map((enumItem) => {
        if (listName === 'LanguageCodeEnum') {
          return {
            label: formatSpecialEnum(enumItem),
            value: enumItem.enumCode,
          };
        }
        return {
          label: enumItem.enumDisplayName
            ? enumItem.enumDisplayName
            : enumItem.enumDescription,
          value: enumItem.enumDisplayName,
        };
      })
    : null;

  let inputType = inputTypeCollection[requirementPropertyItem.dataType];
  inputType = listName && propertyEnum ? 'select' : inputType;
  const isCustomInput = CUSTOM_INPUT_TYPES.includes(
    requirementPropertyItem?.fieldName
  );
  inputType = isCustomInput ? 'custom' : inputType;

  return {
    name: requirementPropertyItem.fieldFullPath,
    type: inputType,
    showSearch: options?.length > 5,
    isList: requirementPropertyItem.isMultipleValue,
    options,
  };
};

export const getPropertiesParams = (values) => {
  return Object.keys(values).reduce((accumulator, currentKey) => {
    const currentValue = values[currentKey];

    if (currentValue)
      accumulator.push({
        fieldName: currentKey,
        value: currentValue,
      });

    return accumulator;
  }, []);
};

export const getAdditionalFieldData = (fieldName, entityType) => {
  return constants.additionalRequirementData[entityType].find((fieldData) => {
    return fieldData.fieldName?.toLowerCase() === fieldName?.toLowerCase();
  });
};

export const getRequiredFieldData = (entityProperties, fieldName) => {
  return (
    entityProperties.find((propertyItem) => {
      return propertyItem.fieldFullPath === fieldName;
    }) ?? {}
  );
};
export const mapEnumToPropertiesSchema = (property, productEnums) => {
  const enumName = property.enumName;

  const enumData = productEnums?.find((item) => item.enumName === enumName);

  if (enumData) {
    return enumData.enumProperties.map((enumProperty) => {
      const label =
        enumName === 'LanguageCodeISO639_1Enum'
          ? `${
              enumProperty.enumDescription
            } - (${enumProperty.enumCode?.toUpperCase()})`
          : enumProperty.enumDescription;

      return {
        label,
        value: enumProperty.enumCode,
      };
    });
  }

  return property.options;
};
