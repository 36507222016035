import React from 'react';
import { Typography, Col, AutoComplete } from 'antd';

import './MdTextSize.less';

const { Text } = Typography;

const MdTextSize = (props) => {
  const { size, handleChangeFontSize } = props;

  return (
    <Col>
      <Text className='md-text-size__title'>Size</Text>
      <AutoComplete
        style={{ width: 35 }}
        placeholder='size'
        options={[...Array(100).keys()].map((number) => ({ value: number }))}
        onChange={handleChangeFontSize}
        value={size}
        defaultValue={16}
      />
    </Col>
  );
};

export default MdTextSize;
