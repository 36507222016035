import {
  CLOSE_DETAIL_STACK_TYPE_BY_PATHNAME,
  CLOSE_DETAIL_STACK_REGEX,
  CLOSE_DETAIL_DETAIL_STACK,
  CLOSE_DETAIL_HARD_URL,
  CLOSE_DETAIL_SAFE_ROUTE,
  SWITCH_DETAIL_STACK_REGEX,
} from 'static/Constants';

export const getStackType = ({ pathname }) => {
  let type = _getGridType({ pathname });

  if (!type) type = _getDetailType({ pathname });

  return type;
};

const _getGridType = ({ pathname }) => {
  let type = null;

  Object.keys(CLOSE_DETAIL_STACK_TYPE_BY_PATHNAME).forEach((key) => {
    const acceptPathnameList = CLOSE_DETAIL_STACK_TYPE_BY_PATHNAME[key];

    if (!type) type = acceptPathnameList.includes(pathname) ? key : type;
  });

  return type;
};

const _getDetailType = ({ pathname }) => {
  let type = null;

  Object.keys(CLOSE_DETAIL_DETAIL_STACK).forEach((key) => {
    const regex = CLOSE_DETAIL_DETAIL_STACK[key];

    if (!type) type = regex.test(pathname) ? key : type;
  });

  return type;
};

export const checkCloseDetailRegex = ({
  closeDetailStack,
  pathname,
  action,
}) => {
  const { name, stack } = closeDetailStack;
  const regex = CLOSE_DETAIL_STACK_REGEX[name];

  if (!regex) return;

  const regexIndex = action === 'push' ? stack.length - 1 : regex.length - 1;

  return regex[regexIndex]?.test(pathname);
};
export const getSwitchStack = ({ pathname }) => {
  const switchStackNameList = Object.keys(SWITCH_DETAIL_STACK_REGEX);
  const switchStackName = switchStackNameList.reduce(
    (accumulator, currentName) => {
      const regex = SWITCH_DETAIL_STACK_REGEX[currentName];
      const isMatched = regex.test(pathname);
      return isMatched ? currentName : accumulator;
    },
    null
  );
  return switchStackName;
};

export const getHardGoBackRoute = (stackName) => {
  return CLOSE_DETAIL_HARD_URL[stackName];
};

export const checkSafeRoute = ({ pathname }) => {
  return CLOSE_DETAIL_SAFE_ROUTE.includes(pathname);
};
