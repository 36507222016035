import React, { useEffect, useRef, useState } from 'react';

import { StyledModal, CustomNotification } from 'common/components';
import { Dropdown, Button, Menu, Space, Select, Typography, Image } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import * as htmlToImage from 'html-to-image';
import * as api from 'config/axios';

import './PanelContextContainer.less';

const { Text } = Typography;

const RATIO_OPTIONS = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
];

const PanelContextContainer = (props) => {
  const { downloadFileName, labelPreview, children } = props;

  const refLabelPreview = useRef();
  const refDefaultLabelPreview = useRef();

  const [openPreview, setOpenPreview] = useState();
  const [previewImageSrc, setPreviewImageSrc] = useState();
  const [ratio, setRatio] = useState();

  const refPanelWrapper = useRef();

  useEffect(() => {
    const initPreview = async () => {
      if (openPreview) {
        setRatio(1);
        generatePreviewImage();
      }
    };

    const resetPanelContextState = () => {
      if (!openPreview) {
        setPreviewImageSrc();
        setRatio();
      }
    };

    initPreview();
    resetPanelContextState();
  }, [openPreview]);

  const handleDownloadDefaultLabelImage = () => {
    htmlToImage
      .toJpeg(refDefaultLabelPreview.current, { pixelRatio: 1 })
      .then(function (dataUrl) {
        //* add .jpg into file name to prevent download as jpeg- jfif format
        const jgpName = downloadFileName + '.jpg';

        api.sendDownload({
          url: dataUrl,
          name: jgpName,
        });
      })
      .catch(function (error) {
        CustomNotification.error('Failed to download the panel!');
      });
  };

  const handleDownloadFromPreviewModal = () => {
    const jgpName = downloadFileName + `_x${ratio}.jpg`;
    api.sendDownload({
      url: previewImageSrc,
      name: jgpName,
    });
  };

  const generatePreviewImage = (ratio = 1) => {
    htmlToImage
      .toJpeg(refDefaultLabelPreview.current, { pixelRatio: ratio })
      .then(function (dataUrl) {
        setPreviewImageSrc(dataUrl);
      })
      .catch(function (error) {
        CustomNotification.error('Cannot generate preview image!');
      });
  };

  const changePreviewRatio = (value) => {
    setRatio(value);
    generatePreviewImage(value);
  };

  const togglePreviewModal = () => {
    setOpenPreview((prev) => !prev);
  };

  const contextMenu = () => {
    return (
      <div className='rv-dropdown__context'>
        <Menu className='rv-dropdown__context-menu'>
          <Menu.Item onClick={handleDownloadDefaultLabelImage}>
            Save Label as JPEG
          </Menu.Item>
          <Menu.Item onClick={togglePreviewModal}>Preview Label</Menu.Item>
        </Menu>
      </div>
    );
  };

  const ModalFooter = (
    <Space>
      <Text strong> Preview Ratio</Text>
      <Select
        style={{ width: 50 }}
        defaultValue={1}
        onChange={changePreviewRatio}
        options={RATIO_OPTIONS}
        value={ratio}
      />
      <Button onClick={togglePreviewModal}> Cancel</Button>
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        onClick={handleDownloadFromPreviewModal}
      >
        Save Label as JPEG (x{ratio} resolution)
      </Button>
    </Space>
  );

  const previewModalProps = {
    title: 'Preview Label',
    visible: openPreview,
    onCancel: togglePreviewModal,
    bodyStyle: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: 'min(500px, 65vh)',
      maxHeight: '65vh',
    },
    width: 1000,
    okText: 'Download Panel as JPEG',
    footer: ModalFooter,
    destroyOnClose: true,
  };

  return (
    <>
      <Dropdown
        overlay={contextMenu}
        trigger={['contextMenu']}
        destroyPopupOnHide
      >
        <div ref={refPanelWrapper}>{children && children}</div>
      </Dropdown>
      <StyledModal {...previewModalProps}>
        <PreviewModalContent
          labelPreview={labelPreview}
          previewImageSrc={previewImageSrc}
          refLabelPreview={refLabelPreview}
        />
      </StyledModal>
      <div
        style={{
          position: 'fixed',
          top: -10000,
          width: 3000,
        }}
      >
        <div
          ref={refDefaultLabelPreview}
          className='panel-context-container__label-preview'
        >
          {labelPreview && labelPreview}
        </div>
      </div>
    </>
  );
};

const PreviewModalContent = (props) => {
  const { previewImageSrc } = props;

  return <Image src={previewImageSrc} preview={false} />;
};

export default PanelContextContainer;
