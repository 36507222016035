import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import * as productServices from 'services/product';

export const getProductHierarchiesQueryKey = (productId) => {
  return ['product-hierarchies', parseInt(productId)];
};

export const useProductHierarchies = (props) => {
  const { productId, ...restProps } = props;

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: getProductHierarchiesQueryKey(productId),
    queryFn: async ({ queryKey }) => {
      const params = {
        productId: queryKey[1],
      };

      const { data } = await productServices.getProductHierarchy(params);

      return data;
    },
    ...restProps,
  });

  const saveHierarchiesMutation = useMutation({
    mutationFn: productServices.saveProductHierarchy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getProductHierarchiesQueryKey(productId),
      });
    },
  });

  const deleteHierarchiesMutation = useMutation({
    mutationFn: productServices.deleteHierarchy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getProductHierarchiesQueryKey(productId),
      });
    },
  });

  const hierarchyData = query?.data?.productHierarchies || [];
  const isLoading = query?.status === 'loading';

  return {
    hierarchyData,
    isLoading,
    saveHierarchiesMutation,
    deleteHierarchiesMutation,
  };
};
