import React from 'react';
import { injectIntl } from 'react-intl';
import Messages from 'i18n/messages/maintenance';
import { Divider } from 'antd';
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { ToggleHelpUploadMediaSection } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';
import BackToPost from './components/controls/maintenance/help/BackToPost';
import DeletePost from './components/controls/maintenance/help/DeletePost';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const HelpPostCreateView = ({
  intl,
  onClick,
  statusCreate,
  disableModeEdit,
}) => {
  const label = [
    {
      id: 'Taco.maintenance.help.ribbon.savePost',
      defaultMessage: intl.formatMessage(Messages.savePost),
    },
    {
      id: 'Taco.maintenance.help.ribbon.cancelPost',
      defaultMessage: intl.formatMessage(Messages.cancelPost),
    },
  ];

  const isLoading = statusCreate === 'loading';

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <Can I={ABILITY_ACTION.MAINTAIN} a={ABILITY_SUBJECT.HELP_POST}>
        <RibbonButton
          icon={<SaveOutlined />}
          label={label[0]}
          onClick={onClick}
          loading={isLoading}
          style={{ width: 60 }}
        />
        <RibbonButton
          icon={<CloseCircleOutlined />}
          label={label[1]}
          onClick={disableModeEdit}
        />
        <RibbonDivider />
        <DeletePost />
        <RibbonDivider />
      </Can>
      <ToggleHelpUploadMediaSection />
      <RibbonDivider />
      <AdvanceStack isDisabled />
      <BackToPost ribbonSide='right' />
    </RibbonBar>
  );
};

export default injectIntl(HelpPostCreateView);
