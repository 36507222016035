import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const addToFavorite = (data, type) => {
  const endpointByType = {
    asset: endpoints.ADD_ASSET_TO_FAVORITE,
    member: endpoints.ADD_MEMBER_TO_FAVORITE,
    folder: endpoints.ADD_FOLDER_TO_FAVORITE,
    Query: endpoints.ADD_QUERY_TO_FAVORITE,
    product: endpoints.ADD_PRODUCT_TO_FAVORITE,
  };
  const fields = {
    asset: 'dataAssetIds',
    member: 'memberIds',
    folder: 'folderIds',
    Query: 'queryIds',
    product: 'productIds',
  };
  const requestData = {
    [fields[type]]: data,
  };
  return api.sendPost(endpointByType[type], requestData);
};

export const removeFromFavorite = (data, type) => {
  const endpointByType = {
    asset: endpoints.REMOVE_ASSET_FROM_FAVORITE,
    member: endpoints.REMOVE_MEMBER_FROM_FAVORITE,
    folder: endpoints.REMOVE_FOLDER_FROM_FAVORITE,
    Query: endpoints.REMOVE_QUERY_FROM_FAVORITE,
    product: endpoints.REMOVE_PRODUCT_FROM_FAVORITE,
  };
  const fields = {
    asset: 'dataAssetIds',
    member: 'memberIds',
    folder: 'folderIds',
    Query: 'queryIds',
    product: 'productIds',
  };
  const requestData = {
    [fields[type]]: data,
  };
  return api.sendPost(endpointByType[type], requestData);
};
