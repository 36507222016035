import { useState, useRef } from 'react';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { dialogFunction, CustomNotification } from 'common/components';

import {
  deleteProductItemsPublication,
  getAllPublications,
} from 'services/product';

export const useWithdrawPublication = (props = {}) => {
  const shouldReloadRef = useRef();
  const { products, enabled = false } = props;

  const [selectedPublications, setSelectedPublications] = useState([]);

  const queryClient = useQueryClient();

  const publicationsQuery = useQuery({
    queryKey: ['get-all-publication', products?.[0]?.id],
    queryFn: async ({ queryKey }) => {
      const productId = queryKey?.[1];

      if (!productId) return;

      const params = { productId };
      const response = await getAllPublications(params);

      if (!response?.isSuccess) throw new Error(response?.message);

      return response?.data;
    },
    enabled,
    retry: false,
  });

  const withdrawPublicationMutation = useMutation({
    mutationKey: 'withdraw-publication',
    mutationFn: (params) => {
      return deleteProductItemsPublication(params);
    },
    onSuccess: (response) => {
      response?.isSuccess &&
        queryClient.invalidateQueries({
          queryKey: ['get-all-publication', products?.[0]?.id],
        });

      shouldReloadRef.current = response?.isSuccess;
    },
    onError: () => {
      CustomNotification.error(
        'Cannot withdraw product(s) publication/registration!'
      );
    },
  });

  const getWithdrawPublicationParams = () => {
    return selectedPublications.map((publicationItem) => {
      return {
        catalogueItemReferenceGtin: publicationItem?.gtin,
        dataSourceGln: publicationItem?.dataSourceGln,
        dataRecipientGln: publicationItem?.dataRecipientGln,
        catalogueItemReferenceTargetMarketCountryCode:
          publicationItem?.targetMarket,
        unRegisterProduct: publicationItem?.unRegisterProduct,
      };
    });
  };

  const confirmDeleteProductsPublication = () => {
    dialogFunction({
      type: 'warn',
      content:
        'Are you sure to withdraw the selected product(s) publication/registration?',
      okText: 'Confirm',
      okButtonProps: {
        type: 'danger',
      },
      onOk: handleWithdrawPublication,
    });
  };

  const handleWithdrawPublication = async () => {
    withdrawPublicationMutation.mutate(
      {
        withdrawPublishedProducts: getWithdrawPublicationParams(),
      },
      {
        onSuccess: (response) => {
          if (response?.isSuccess) {
            CustomNotification.success(
              'Withdraw product(s) publication/registration successfully!'
            );
          } else {
            CustomNotification.error(
              response?.message ||
                'Cannot withdraw product(s) publication/registration!'
            );
          }
          setSelectedPublications([]);
        },
      }
    );
  };

  const selectPublications = (publicationSelected) => {
    setSelectedPublications(publicationSelected);
  };

  const unSelectPublication = (publication) => {
    setSelectedPublications((prevState) =>
      prevState.filter(
        (publicationItem) => publicationItem.id !== publication.id
      )
    );
  };

  return {
    publicationsQuery,
    confirmDeleteProductsPublication,
    selectPublications,
    selectedPublications,
    unSelectPublication,
    shouldReload: shouldReloadRef.current,
  };
};
