import React, { useState } from 'react';
import {
  Avatar,
  Row,
  Col,
  Button,
  Typography,
  Tooltip,
  Dropdown,
  Menu,
} from 'antd';
import {
  CloseOutlined,
  LeftOutlined,
  FilterOutlined,
  UserAddOutlined,
  PlusOutlined,
  MailOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import EmailSearch from '../search/EmailSearch';
import SearchEmailButton from '../search/SearchEmailButton';
import EmailFilter from './EmailFilter';

import classnames from 'classnames';

import './EmailHeader.less';

function EmailHeader(props) {
  const {
    onHandleSearch,
    isInEmailThread,
    onCloseEmailView,
    searchText,
    onChangeEmailFilter,
    handleGoBack,
    info,
    newEmailThread,
    groupUsers,
  } = props;

  const { Text } = Typography;

  const mockInfo = {
    avatar:
      'https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png',
    title: 'Group Chat TacoTruck',
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => newEmailThread()}>New Mail</Menu.Item>
    </Menu>
  );

  return (
    <div className='email-header'>
      <Row style={{ alignItems: 'center', paddingBottom: 8 }}>
        <Col>
          <Text className='email-header__title'>Email</Text>
        </Col>

        <Col flex='auto' />
        <Col>
          <Button className='email-header__btn' onClick={onCloseEmailView}>
            <CloseOutlined />
          </Button>
        </Col>
      </Row>

      {!isInEmailThread ? (
        <Row style={{ alignItems: 'center' }}>
          <Col span={22}>
            <EmailSearch value={searchText} onChange={onHandleSearch} />
          </Col>
          <Col span={2} style={{ textAlign: 'center', paddingTop: '8px' }}>
            <Tooltip title='New Mail'>
              <Dropdown overlay={menu} placement='bottomLeft'>
                <Button className='chat-header__btn'>
                  <MailOutlined
                    style={{ fontSize: '20px', marginLeft: '-3px' }}
                  />
                  <PlusOutlined className='chat-header__plus-btn' />
                </Button>
              </Dropdown>
            </Tooltip>
          </Col>

          <EmailFilter
            onChangeEmailFilter={onChangeEmailFilter}
            showFilter={true}
          />
        </Row>
      ) : (
        <Row
          style={{
            alignItems: 'center',
            height: 42,
            paddingTop: '8px',
            flexWrap: 'nowrap',
          }}
        >
          <Col>
            <Button
              className={classnames(
                'email-header__btn',
                'email-header__btn--back'
              )}
              style={{ borderRadius: '100px' }}
              onClick={() => handleGoBack()}
            >
              <LeftOutlined />
            </Button>

            <Avatar size={32} src={info.avatarUrl || mockInfo?.avatar}>
              {/* {item?.name?.charAt(0)} */}
            </Avatar>
          </Col>

          <Col flex={1}>
            <Text className='email-header__text' ellipsis>
              {info?.name || mockInfo?.title}
            </Text>
          </Col>
          <Col>
            <SearchEmailButton
              groupUsers={groupUsers}
              threadInfo={info}
              placement='left'
              width={250}
              resultWidth={290}
              resultPosition='topLeft'
            >
              <Button className='email-header__btn' icon={<SearchOutlined />} />
            </SearchEmailButton>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default EmailHeader;
