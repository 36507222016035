import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { List, Modal, notification, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';

import * as api from 'config/axios';
import { CustomNotification, UploadFile, WithLoading } from 'common/components';

import {
  GET_EDITING_FIELDS_FORM_TEMPLATE,
  IMPORT_PRODUCT_FOR_BULK_EDITING,
} from 'services/product/endpoints';
import { UPLOAD_MAX_SIZE } from 'static/Constants';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useProductFullView } from 'hooks/useProductFullView';

import {
  getKeyFactPanels,
  getKeySupplementPanels,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/facts-panel/useQueryFactsPanel';

import { groupProductData } from 'pages/product-full-view/components/product-media-area/shared/hooks';

import { useReloadDocumentCertificateTab } from 'hooks/useReloadDocumentCertificateTab';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

import { useGetProductVersionList } from 'pages/product-history/hook';
import { useRefetchQaSpecData } from 'hooks/qa-spec/useRefetchQaSpecData';
import { useGetDataProductItemModules } from 'pages/product-full-view/shared/hooks';

const { Text, Paragraph } = Typography;

const ImportProductBulkEditModal = (props) => {
  const intl = useIntl();
  const reloadPage = useDispatchReloadPage();
  const { reloadDocumentCertificateTab } = useReloadDocumentCertificateTab();

  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const queryClient = useQueryClient();
  const { visible, setVisible, isProductDetail } = props;

  const { id } = useParams();

  const [errorList, setErrorList] = useState([]);
  const [fileUpload, setFileUpload] = useState();
  const [tdcProductIds, setTdcProductIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bulkEditLoading, setBulkEditLoading] = useState(false);

  const productId = isProductDetail ? id : null;

  const { productFull, reloadProductFullView } = useProductFullView({
    productId,
  });

  const { handleRefetchProductVersioning } = useGetProductVersionList({
    enabled: false,
  });
  const { refetchDataProductModules } = useGetDataProductItemModules(
    productId,
    false
  );

  const refetchQaSpecData = useRefetchQaSpecData();

  const onCancel = () => {
    closeModal();
    setErrorList([]);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleResponseUpload = async (response, ixoneIgnore) => {
    setErrorList([]);

    if (response?.isSuccess) {
      if (ixoneIgnore === 'continue') {
        await handleUpdateIXONEShield(ixoneIgnore, tdcProductIds);
      }
      const systemMessage = response?.message;
      CustomNotification.success(
        systemMessage ||
          intl.formatMessage(Messages.importProductBulkEditSuccess)
      );
      reloadPage();

      if (productFull?.productId) {
        queryClient.invalidateQueries({
          queryKey: getKeySupplementPanels(productFull?.productId),
        });
        queryClient.invalidateQueries({
          queryKey: getKeyFactPanels(productFull?.productId),
        });

        queryClient.invalidateQueries({
          queryKey: groupProductData.getAllGroups(productId),
        });

        reloadProductFullView();

        reloadDocumentCertificateTab();

        refetchQaSpecData();

        handleRefetchProductVersioning();

        refetchDataProductModules();
      }

      setBulkEditLoading(false);
      closeModal();
    } else {
      notification.error({
        message:
          response?.message ||
          intl.formatMessage(Messages.importProductBulkEditError),
      });
      if (response?.data?.validateFailDetails) {
        setErrorList(response?.data?.validateFailDetails);
      }
      setBulkEditLoading(false);
    }
  };

  const renderErrorList = () => {
    return (
      <div className='scroller' style={{ height: 200, paddingTop: '8px' }}>
        <List
          header={
            <Text style={{ fontWeight: 700 }} type='danger'>
              <InfoCircleOutlined /> Error
            </Text>
          }
          bordered
          dataSource={errorList}
          renderItem={(errorItem) => {
            const errorMessage = errorItem.reason || 'Invalid data';
            return (
              <List.Item>
                <Paragraph
                  type='danger'
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Text strong type='danger'>
                    {errorMessage}
                  </Text>{' '}
                  in{' '}
                  <Text strong type='danger'>
                    {errorItem?.sheetName}
                  </Text>{' '}
                  sheet, on row{' '}
                  <Text strong type='danger'>
                    {errorItem?.row}
                  </Text>
                  , column{' '}
                  <Text strong type='danger'>
                    {errorItem?.column}
                  </Text>
                </Paragraph>
              </List.Item>
            );
          }}
        />
      </div>
    );
  };

  const getFileChange = (paramsUpload) => {
    setFileUpload(paramsUpload.file.originFileObj);
  };

  const handleValidateFileUpload = (handleConfirmIXONE) => {
    setLoading(true);
    api
      .sendUpload({
        api: GET_EDITING_FIELDS_FORM_TEMPLATE,
        payload: {
          file: fileUpload,
        },
      })
      .then(({ data }) => {
        if (data?.isSuccess) {
          const productList = data?.data?.data;
          let Ids = [];
          productList?.forEach((product) => {
            const { ixOneId, ixOneCertifiedStatus, productId } = product;
            if (ixOneId && ixOneCertifiedStatus !== 'Removed') {
              Ids.push(+productId);
            }
          });
          setTdcProductIds(Ids);

          handleConfirmIXONE({
            originalData: Ids,
            changedFields: data?.data?.fields,
          });
          setLoading(false);
        } else {
          setLoading(false);
          CustomNotification.warning(data?.message ?? 'Server error');
        }
      })
      .catch(() => {
        setLoading(false);
        CustomNotification.warning('Server error');
      });
  };

  const handleUploadBulkEdit = (ixoneIgnore) => {
    setBulkEditLoading(true);
    api
      .sendUpload({
        api: IMPORT_PRODUCT_FOR_BULK_EDITING,
        payload: {
          file: fileUpload,
        },
      })
      .then(async (response) => {
        await handleResponseUpload(response?.data, ixoneIgnore);
      })
      .catch(() => {
        CustomNotification.warning('Server error');
        setBulkEditLoading(false);
      });
  };

  return (
    <IXONECertificationWrapper
      showModal
      type={TYPE_VIEW.BULK_EDIT}
      onConfirm={(ixoneIgnore) => {
        handleUploadBulkEdit(ixoneIgnore);
      }}
      onCancel={onCancel}
    >
      {(handleConfirmIXONE) => (
        <Modal
          visible={visible}
          title={intl.formatMessage(Messages.importProductBulkEditTitle)}
          onCancel={onCancel}
          footer={null}
          width={1000}
          destroyOnClose
        >
          <WithLoading loading={loading || bulkEditLoading}>
            <div style={{ height: 450, overflow: 'hidden' }}>
              <UploadFile
                uploadAPL //* for getting error list
                manualUpload={true}
                isBase64={false}
                showMultiple={false}
                supportTypes={['xlsx', 'xls']}
                showXlsTemplate={false}
                apiUrl={IMPORT_PRODUCT_FOR_BULK_EDITING}
                showUploadSuccessMsg={false}
                showServerMessage={false}
                notShowMessage={true}
                getFileChange={getFileChange}
                manualUploadHandler={() => {
                  handleValidateFileUpload(handleConfirmIXONE);
                }}
                maxSize={UPLOAD_MAX_SIZE.GENERAL}
              />
            </div>
          </WithLoading>
          {errorList?.length ? renderErrorList() : null}
        </Modal>
      )}
    </IXONECertificationWrapper>
  );
};

export default ImportProductBulkEditModal;
