// Action Types
export const TOGGLE_EDIT_LAYOUT = 'TOGGLE_EDIT_LAYOUT';

export const UPDATE_LAYOUT_CHANGED = 'UPDATE_LAYOUT_CHANGED';

export const CALENDAR_DAY_VIEW = 'CALENDAR_DAY_VIEW';
export const CALENDAR_WEEK_VIEW = 'CALENDAR_WEEK_VIEW';
export const CALENDAR_MONTH_VIEW = 'CALENDAR_MONTH_VIEW';
export const CALENDAR_SCHEDULE_VIEW = 'CALENDAR_SCHEDULE_VIEW';

export const GET_MEETING_LIST = 'GET_MEETING_LIST';
export const GET_MEETING_LIST_SUCCESS = 'GET_MEETING_LIST_SUCCESS';
export const GET_MEETING_LIST_ERROR = 'GET_MEETING_LIST_ERROR';

export const GET_SCHEDULE_LIST = 'GET_SCHEDULE_LIST';
export const GET_SCHEDULE_LIST_SUCCESS = 'GET_SCHEDULE_LIST_SUCCESS';
export const GET_SCHEDULE_LIST_ERROR = 'GET_SCHEDULE_LIST_ERROR';

export const GET_PROJECTS_GRID = 'GET_PROJECTS_GRID';
export const GET_PROJECTS_GRID_SUCCESS = 'GET_PROJECTS_GRID_SUCCESS';
export const GET_PROJECTS_GRID_ERROR = 'GET_PROJECTS_GRID_ERROR';

export const UPDATE_CALENDAR_DATE = 'UPDATE_CALENDAR_DATE';

export const SAVE_ID_MEETING = 'SAVE_ID_MEETING';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';

export const GET_MEETING_DETAILS = 'GET_MEETING_DETAILS';
export const GET_MEETING_DETAILS_SUCCESS = 'GET_MEETING_DETAILS_SUCCESS';
export const GET_MEETING_DETAILS_ERROR = 'GET_MEETING_DETAILS_ERROR';
export const RESET_MEETING_DETAILS = 'RESET_MEETING_DETAILS';
