import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkOutlined, DownloadOutlined } from '@ant-design/icons';

import { Row, Col, Typography, Avatar, Divider } from 'antd';
import classnames from 'classnames';

import EmailFileList from './EmailFileList';

import * as emailActions from '../../controller/actions';

import { exportToChatTime } from 'utils/formatDate';
import {
  detectUrl,
  formatMessage,
  detectObjectStrContactCard,
} from 'utils/string';
import ContactCardMessage from 'common/components/contact-card/messages/ContactCardMessages';

import * as emailSelector from '../../controller/selectors';
import * as emailServices from 'services/email';

const { Text, Link } = Typography;

const EmailMessage = ({
  item: message,
  participant,
  isSelectedSearchResult,
}) => {
  const mockAvatar =
    'https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png';

  const domRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    //* need re-factor later
    if (
      message?.attachmentIds?.length !== 0 &&
      message?.attachmentIds &&
      message?.attachmentInfos?.length !== message?.attachmentIds?.length
    ) {
      setTimeout(() => {
        handleGetAttachment(message?.attachmentIds);
      }, 300);
    }
  }, [message?.attachmentIds]);

  const getMessageType = () => {
    if (detectUrl(message?.messageText)) return 'url';
    if (detectObjectStrContactCard(message?.messageText)) return 'contact-card';
    return 'text';
  };
  const emailThreadInfo = useSelector(emailSelector.selectEmailThreadInfo());
  const [fileArr, setFileArr] = useState([]);

  const handleGetAttachment = (attachmentIds) => {
    const threadId = emailThreadInfo?.data?.id;
    const params = { attachmentIds, threadId };
    return emailServices
      .getEmailAttachment(params)
      .then((res) => setFileArr(res.data?.emailAttachmentInfoModels));
  };

  const updateSelectedSearchResultPosition = () => {
    if (isSelectedSearchResult) {
      const el = domRef.current;
      const listItem = el.closest('.ant-list-item');
      const position = listItem.offsetTop;
      // const itemHeight = listItem.offsetHeight;
      // const scrollDestination = position + itemHeight;
      const scrollDestination = position;
      dispatch(
        emailActions.updateSelectedSearchResultPosition(scrollDestination)
      );
    }
  };

  useEffect(() => {
    updateSelectedSearchResultPosition();
  }, [isSelectedSearchResult]);

  const type = getMessageType();
  const renderMessage = () => {
    const messageComp = {
      text: (
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: formatMessage(message?.messageText),
            }}
          />
        </p>
      ),
      url: (
        <p>
          <Link href={message} target='_blank'>
            {message?.messageText}
          </Link>
        </p>
      ),
      'contact-card': (
        <ContactCardMessage
          userInfo={message?.messageText}
          isInChatThread={true}
        />
      ),
    };

    return messageComp[type];
  };

  const emailClassName = classnames({
    'email-thread__mail': true,
    'email-thread__mail--search': isSelectedSearchResult,
    'email-thread__mail--contact-card': type === 'contact-card' ? true : null,
  });

  const fileList = useMemo(() => {
    if (message?.attachmentInfos?.length > 0) return message?.attachmentInfos;
    if (fileArr?.length > 0) return fileArr;
  }, [message, fileArr]);

  return (
    <div className={emailClassName} ref={domRef}>
      <Row className='email-thread__mail-header'>
        <Col span={2}>
          <div className='email-thread__mail-avatar'>
            <Avatar src={participant?.avatar || mockAvatar} size={28}>
              {participant?.name?.charAt(0)}
            </Avatar>
          </div>
        </Col>

        <Col span={9}>
          <Text className='email-thread__mail-from'>{participant?.name}</Text>
        </Col>
        <Col span={12}>
          <Text className='email-thread__mail-header-time'>
            {exportToChatTime(message?.sendTime)}
          </Text>
        </Col>
      </Row>
      <Row className='email-thread__mail-body'>
        <Col span={24}>{message?.messageText && renderMessage()}</Col>
        <EmailFileList fileList={fileList} />
      </Row>
    </div>
  );
};

export default React.memo(EmailMessage);
