import React, { useState, useEffect, useContext } from 'react';

import {
  Modal,
  Row,
  Col,
  Button,
  Tooltip,
  Space,
  Dropdown,
  Menu,
  Typography,
} from 'antd';
import './PreviewGalleryModal.less';
import ImageArea from './ImageArea';
import ImagePreviewArea from './ImagePreviewArea';
import DownloadModal from './DownloadModal';
import AddAssetPimModal from '../modal/add-asset-pim-modal';
import LinkedAssetEditModal from './linked-asset-edit/LinkedAssetEditModal';
import ImageDownloadOption from './ImageDownloadOption';

import { imageType } from 'static/Constants';

import LinkToAssetModal from '../modal/link-to-asset-modal/LinkToAssetModal';
import {
  VerticalAlignBottomOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/product';

import { PIM_ASSET_TYPE_DEFINE } from 'static/Constants';
import { AbilityContext } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import { dialogFunction } from 'common/components';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

import { useCheckAllowEditProducts } from 'hooks';

const PreviewGalleryModal = (props) => {
  const {
    productInfo,
    visible,
    onCloseModal,
    imageList,
    title,
    titleModal,
    getImagery,
    handleUnlinkAsset,
    unlinkAssetLoading,
    getMarketingAssets,
  } = props;

  const [imagePreview, setImagePreview] = useState([]);

  const [triggerDownload, setTriggerDownload] = useState(false);
  const [isOpenLink, setIsOpenLink] = useState(false);
  const [itemPreview, setItemPreview] = useState(null);

  const [isAddAssetPimModalOpen, setIsAddAssetPimModalOpen] = useState(false);

  const [isEditLinkedAsset, setIsEditLinkedAsset] = useState(false);

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const addType = PIM_ASSET_TYPE_DEFINE.find((type) => type === title) || '';
  const ability = useContext(AbilityContext);

  const isAllowLinkExistAsset = ability.can(
    ABILITY_ACTION.VIEW,
    ABILITY_SUBJECT.ASSET
  );

  const isAllowEditProduct = checkAllowEditProductFull(productInfo);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsAddAssetPimModalOpen(true)}>
        <FormattedMessage {...Messages.linkToNewAsset} />
      </Menu.Item>
      <Menu.Item
        onClick={() => setIsOpenLink(true)}
        disabled={!isAllowLinkExistAsset}
      >
        <FormattedMessage {...Messages.linkToExistingAsset} />
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (imageList?.length > 0) {
      setItemPreview(imageList[0]);
    } else {
      setItemPreview(null);
    }
  }, [imageList]);

  const checkImagePreview = (value) => {
    if (value === 'remove') {
      setImagePreview([]);
    } else if (value === 'all') {
      setImagePreview(imageList);
    } else {
      const isTrue = imagePreview?.filter((item) => item === value).length > 0;

      if (imagePreview && isTrue) {
        const imageTemp = imagePreview.filter(
          (item) => item?.digitalAssetId !== value?.digitalAssetId
        );
        setImagePreview(imageTemp);
      } else {
        setImagePreview(imagePreview.concat([value]));
      }
    }
  };

  const onClickItem = (item) => {
    setItemPreview(item);
  };

  const getConditionDownload = (value) => {
    if (
      value.filter((item) => imageType.includes(item?.fileType?.toLowerCase()))
        .length > 0
    )
      return true;
    return false;
  };

  const toggleAddAssetPimModal = () => {
    setIsAddAssetPimModalOpen((prev) => !prev);
  };

  const handleUnlink = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning text='linked asset' ixoneIgnore={ixoneIgnore}>
          Are you sure you want to delete this selected link data?
        </IXONEShieldWarning>
      ),
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        const productIds = imagePreview.map((product) => product?.id);
        const isSuccess = await handleUnlinkAsset(productIds);
        if (isSuccess) {
          setImagePreview([]);
          itemPreview?.id === productIds?.[0] && setItemPreview(null);
          await handleUpdateIXONEShield(ixoneIgnore);
        }
      },
    });
  };

  const openEditLinkAssetPimModal = () => {
    setIsEditLinkedAsset(true);
  };

  const onClickDownloadOptions =
    ({ downloadFile, showDrmNotify, isDrm }) =>
    ({ key }) => {
      const namingType = key;

      !isDrm ? downloadFile({ namingType }) : showDrmNotify({ namingType });
    };

  const renderDownloadBtn = ({ Button, loading, ...rest }) => {
    return (
      <ImageDownloadOption onClick={onClickDownloadOptions(rest)}>
        <Button loading={loading} />
      </ImageDownloadOption>
    );
  };

  return (
    <>
      <Modal
        title={
          <Row className='preview-gallery-modal__title' justify='space-between'>
            <div>{titleModal ? titleModal : `${title} Image Preview`}</div>
            <Col>
              {isAllowEditProduct && (
                <Space>
                  <Dropdown
                    overlay={menu}
                    placement='bottomLeft'
                    disabled={!isAllowEditProduct}
                  >
                    <Button
                      icon={<ShareAltOutlined />}
                      style={{ borderRadius: 4 }}
                      type='primary'
                    >
                      Link
                    </Button>
                  </Dropdown>

                  <Tooltip title='Remove asset from product'>
                    <IXONECertificationWrapper
                      showModal={imagePreview[0]?.fromTdcImage}
                      type={TYPE_VIEW.IMAGE_PRODUCT}
                      onConfirm={(ixoneIgnore) => {
                        handleUnlink(ixoneIgnore);
                      }}
                    >
                      {(handleConfirmIXONE) => (
                        <Button
                          icon={<DeleteOutlined />}
                          style={{ borderRadius: 4 }}
                          type='primary'
                          danger
                          onClick={handleConfirmIXONE}
                          disabled={
                            !isAllowEditProduct ||
                            imagePreview.length === 0 ||
                            imagePreview.length > 1
                          }
                          loading={unlinkAssetLoading}
                        >
                          Unlink Asset
                        </Button>
                      )}
                    </IXONECertificationWrapper>
                  </Tooltip>
                  <Tooltip title={'Edit Linked Asset'}>
                    <Button
                      icon={<EditOutlined />}
                      style={{ borderRadius: 4 }}
                      type='primary'
                      disabled={
                        !isAllowEditProduct ||
                        imagePreview.length === 0 ||
                        imagePreview.length > 1
                      }
                      onClick={openEditLinkAssetPimModal}
                    >
                      Edit
                    </Button>
                  </Tooltip>
                </Space>
              )}
              <Tooltip title='Support download image'>
                <Button
                  icon={<VerticalAlignBottomOutlined />}
                  style={{ borderRadius: 4, marginLeft: 8 }}
                  type='primary'
                  onClick={() => setTriggerDownload(true)}
                  disabled={
                    !imagePreview ||
                    (imagePreview && imagePreview.length === 0) ||
                    !getConditionDownload(imagePreview)
                  }
                >
                  Download With Custom Configuration
                </Button>
              </Tooltip>
            </Col>
          </Row>
        }
        destroyOnClose
        visible={visible}
        onCancel={() => {
          setImagePreview([]);
          setItemPreview(null);
          onCloseModal();
        }}
        className={'preview-gallery-modal'}
      >
        <Row style={{ height: '100%' }}>
          <Col span={12} style={{ height: '100%' }}>
            <ImageArea
              imageList={imageList}
              title={title}
              imagePreview={imagePreview}
              visible={visible}
              idField={'digitalAssetId'}
              type={'Imagery'}
              onClick={(item) => checkImagePreview(item)}
              onClickItem={onClickItem}
              itemPreview={itemPreview}
            />
          </Col>
          <Col span={12} style={{ height: '100%' }}>
            <ImagePreviewArea
              imageInfo={itemPreview}
              renderDownloadBtn={title === 'Planogram' && renderDownloadBtn}
            />
          </Col>
        </Row>
      </Modal>
      <DownloadModal
        visible={triggerDownload}
        onCloseModal={() => setTriggerDownload(false)}
        imagePreview={imagePreview}
        idField={'id'}
        showNamingField={title === 'Planogram'}
      />
      <LinkToAssetModal
        productInfo={productInfo}
        onClose={() => setIsOpenLink(false)}
        visible={isOpenLink}
        getImagery={getImagery}
        type={title}
        getMarketingAssets={getMarketingAssets}
      />
      <>
        {isAddAssetPimModalOpen && (
          <AddAssetPimModal
            onCloseModal={toggleAddAssetPimModal}
            addType={addType}
            getImagery={getImagery}
            getMarketingAssets={getMarketingAssets}
            productDetail={productInfo}
          />
        )}
      </>
      {isEditLinkedAsset && (
        <LinkedAssetEditModal
          imageList={imageList}
          imagePreview={imagePreview}
          itemPreview={itemPreview}
          setItemPreview={setItemPreview}
          setImagePreview={setImagePreview}
          visible={isEditLinkedAsset}
          setIsEditLinkedAsset={setIsEditLinkedAsset}
          addType={addType}
          getImagery={getImagery}
          getMarketingAssets={getMarketingAssets}
        />
      )}
    </>
  );
};

export default PreviewGalleryModal;
