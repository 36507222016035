import React from 'react';
import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

import { formatMDY } from 'utils/formatDate';
import { FullWidthSpace, InfoWithLabel } from 'common/components';

const ProductOverviewStatusInfo = (props) => {
  const { data } = props;
  const intl = useIntl();
  const info = [
    {
      label: intl.formatMessage(Messages.status),
      info: data?.status,
    },
    {
      label: intl.formatMessage(Messages.modified),
      info: formatMDY(data?.lastModified),
    },
    {
      label: intl.formatMessage(Messages.visibility),
      info: data?.visibility,
    },
  ];

  const itemProps = {
    labelAlign: 'right',
    labelColProps: {
      flex: '80px',
    },
    infoColProps: {
      // flex: '80px',
      style: {
        minWidth: '10px',
      },
    },
  };
  return (
    <FullWidthSpace size={0}>
      {info?.map((item, index) => {
        return <InfoWithLabel key={index} {...item} {...itemProps} />;
      })}
    </FullWidthSpace>
  );
};

export default ProductOverviewStatusInfo;
