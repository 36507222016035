import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import { Col, Menu, Row, Space, Typography } from 'antd';

import { sleep } from 'utils/delay';
import { CarouselSection, CarouselNext, CarouselPrevious } from './Carousel';

import {
  FormAddButton as AddButton,
  FormEditButton as EditButton,
  FormDeleteButton as DeleteButton,
  CustomNotification,
  dialogFunction,
  WithLoading,
} from 'common/components';

import {
  productFullViewKeys,
  useCheckAllowEditProducts,
  useGetProductFullView,
} from 'hooks';

import { useMutationDeleteFactsPanel } from './facts-panel/useMutationDeleteFactsPanel';

import AddLabel from '../nutrition-label/add-label';
import LinearLabel from './facts-panel/LinearLabel';
import VerticalLabel from './facts-panel/VerticalLabel';
import { NutritionProvider } from '../nutrition-label/context/nutritionContext';
import VerticalLabelWithMicronutrient from './facts-panel/VerticalLabelWithMicronutrient';
import SupplementStandardLabel from 'pages/product-full-view/components/product-media-area/supplement-label/SupplementStandardLabel';
import DrugStandardLabel from 'pages/product-full-view/components/product-media-area/drug-label';
import {
  SUPPLEMENT_TYPE,
  NUTRITION_TYPE,
  DRUG_FACTS_TYPE,
  PET_NUTRITION_TYPE,
  AMINO_ACID_TYPE,
} from './constants';

import {
  GroupActions,
  GroupAddition,
  GroupAssign,
  GroupDeletion,
  GroupEdit,
  GroupPartName,
  GroupPreview,
  MenuItem,
  PanelContextContainer,
} from './components';
import {
  getSelectedItemGroup,
  transformToTextGroupName,
} from '../shared/utils';
import {
  TYPE_GROUPS,
  useGetAllGroupProduct,
  useInvalidateGroupDetail,
  useVisibleGroupModal,
} from '../shared/hooks';

import { filterProductGroups } from './tab-allergens/utils';
import { GroupLayoutProvider } from './components/GroupLayoutProvider';
import { useCheckAllowManageGroupData } from 'hooks/useCheckPermissionManageGroup';
import { useQueryQaFormulaData } from 'pages/qa-spec/components/qa-formula/queries';
import PetNutritionFacts from '../nutrition-label/add-label/pet-nutrition-label/PetNutritionFacts';

import './TabFactPanel.less';
import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import { useQueryClient } from '@tanstack/react-query';

//* let 1 slide scroll, we can ignore label width
const DEFAULT_SLIDE_SHOW = 1;

const TabFactPanels = ({ loading, totalFactsPanels }) => {
  const carouselRef = useRef();
  const addLabelRef = useRef();

  const { id } = useParams();

  const { invalidateQaFormulaData } = useQueryQaFormulaData({
    enabled: false,
  });
  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);
  const [factPanelItem, setFactPanelItem] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const { productFull } = useGetProductFullView({ productId: id });

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const hasManageGroupPermission = useCheckAllowManageGroupData();

  const {
    visibleModalAddGroup,
    visibleModalEditGroup,
    visibleModalPreviewGroup,
    visibleModalAssignGroup,
    visibleModalDeleteGroup,
    handler: { onGroupModal, offGroupModal },
  } = useVisibleGroupModal();

  const { productGroups } = useGetAllGroupProduct(id);

  const selectedFactsPanelGroupIds = factPanelItem?.groupIds ?? [];

  const { defaultGroup, selectedProductGroups } = getSelectedItemGroup(
    selectedFactsPanelGroupIds,
    productGroups
  );

  const filteredProductGroups = filterProductGroups(
    selectedFactsPanelGroupIds,
    productGroups
  );

  const { handleSyncAllProductDetailGroup } = useInvalidateGroupDetail(id);

  const {
    nutritionDeleteMutation,
    supplementDeleteMutation,
    drugFactDeleteMutation,
    petFoodDeleteMutation,
  } = useMutationDeleteFactsPanel({ id });

  const handleSelectPanel = (panelItem, index) => {
    if (panelItem?.id === factPanelItem?.id) setFactPanelItem(null);
    else setFactPanelItem({ ...panelItem, index });
  };

  const getSelectedPanelInfo = () => {
    const deleteInfo = {
      mutationInfo: null,
      params: null,
    };

    if (factPanelItem.factsPanelType.toLowerCase() === SUPPLEMENT_TYPE) {
      deleteInfo.mutationInfo = supplementDeleteMutation;
      deleteInfo.params = {
        productItemId: id,
        supplementPanelIds: [factPanelItem?.id],
      };
    }

    if (
      factPanelItem.factsPanelType.toLowerCase() === NUTRITION_TYPE ||
      factPanelItem.factsPanelType.toLowerCase() === AMINO_ACID_TYPE
    ) {
      deleteInfo.mutationInfo = nutritionDeleteMutation;
      deleteInfo.params = {
        productItemId: id,
        nutritionPanelId: factPanelItem?.id,
      };
    }

    if (factPanelItem.factsPanelType.toLowerCase() === DRUG_FACTS_TYPE) {
      deleteInfo.mutationInfo = drugFactDeleteMutation;
      deleteInfo.params = {
        productItemId: id,
        drugPanelIds: [factPanelItem?.id],
      };
    }

    if (factPanelItem.factsPanelType.toLowerCase() === PET_NUTRITION_TYPE) {
      deleteInfo.mutationInfo = petFoodDeleteMutation;
      deleteInfo.params = {
        productItemId: id,
        petNutritionFactPanelIds: [factPanelItem?.id],
      };
    }

    return deleteInfo;
  };

  const confirmDelete = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning
          text='selected Facts Panel'
          ixoneIgnore={ixoneIgnore}
        >
          Are you sure you want to delete the selected Facts Panel?
        </IXONEShieldWarning>
      ),
      okText: 'Delete',
      okButtonProps: {
        type: 'danger',
      },
      onOk: async () => {
        const { params, mutationInfo } = getSelectedPanelInfo();

        await mutationInfo.mutateAsync(
          { ...params, ixOneIgnore: ixoneIgnore },
          {
            onSuccess: (result) => {
              if (result?.isSuccess) {
                invalidateQaFormulaData({ productId: id });
                CustomNotification.success(
                  'Delete selected Facts Panel successfully.'
                );
                handleSyncAllProductDetailGroup();
                queryClient.invalidateQueries({
                  queryKey: productFullViewKeys.itemData(id),
                });
                setFactPanelItem(null);
              } else {
                CustomNotification.error(result?.message);
              }
            },
          }
        );
      },
    });
  };

  const handleEditPanel = async () => {
    setVisible(true);
    await sleep(100);
    addLabelRef?.current?.initAddLabel('edit');
  };

  const handleAddPanel = async () => {
    setFactPanelItem(null);
    setVisible(true);
    await sleep();
    addLabelRef?.current?.initAddLabel('add');
  };

  const isAllowEditProduct = checkAllowEditProductFull(productFull);
  const isDisable = !isAllowEditProduct || !factPanelItem;

  const showCarousel = totalFactsPanels.every(
    (item) =>
      item.nutrientFormatType !== 'US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES'
  );

  const disableBtnCarousel =
    showCarousel && DEFAULT_SLIDE_SHOW < totalFactsPanels?.length;

  return (
    <NutritionProvider>
      <Row className='tab-fact-panels__list-button' justify='space-between'>
        <Col>
          <Space>
            <Space>
              <CarouselPrevious
                onClick={() => {
                  if (carouselRef?.current) carouselRef.current.prev();
                }}
                disabled={!disableBtnCarousel || currentSlide === 0}
              />
              <CarouselNext
                onClick={() => {
                  if (carouselRef?.current) carouselRef.current.next();
                }}
                disabled={
                  !disableBtnCarousel ||
                  currentSlide === totalFactsPanels?.length - DEFAULT_SLIDE_SHOW
                }
              />
            </Space>
            <TotalPanels totalValue={totalFactsPanels?.length || 0} />
          </Space>
        </Col>
        <Col>
          <Space>
            <AddButton
              onClick={handleAddPanel}
              disabled={!isAllowEditProduct}
            />
            <EditButton onClick={handleEditPanel} disabled={isDisable} />
            <IXONECertificationWrapper
              showModal={!!factPanelItem && factPanelItem?.fromTdc}
              type={TYPE_VIEW.FACT_PANEL}
              onConfirm={(ixoneIgnore) => {
                confirmDelete(ixoneIgnore);
              }}
            >
              {(handleConfirmIXONE) => (
                <DeleteButton
                  onClick={() => {
                    handleConfirmIXONE({
                      originalData: {},
                      changedFields: factPanelItem.factsPanelType.toLowerCase(),
                    });
                  }}
                  disabled={isDisable}
                />
              )}
            </IXONECertificationWrapper>

            <GroupActions
              overlay={() => (
                <Space>
                  <Menu>
                    <MenuItem>
                      <MenuItem.AddGroup
                        disabled={!hasManageGroupPermission}
                        onClick={() => onGroupModal(TYPE_GROUPS.addGroup)}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.PreviewGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.previewGroup)}
                        disabled={selectedProductGroups?.length === 0}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.AssignGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.assignGroup)}
                        disabled={
                          factPanelItem === null ||
                          filteredProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.EditGroup
                        disabled={
                          factPanelItem === null ||
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                        onClick={() => onGroupModal(TYPE_GROUPS.editGroup)}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.DeleteGroup
                        onClick={() => {
                          onGroupModal(TYPE_GROUPS.deleteGroup);
                        }}
                        disabled={
                          factPanelItem === null ||
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                      />
                    </MenuItem>
                  </Menu>
                </Space>
              )}
            >
              <GroupActions.ButtonWrapper />
            </GroupActions>
          </Space>
        </Col>
      </Row>
      <WithLoading loading={loading}>
        <div
          className={classNames({
            'tab-fact-panels__hidden-clone-carousel':
              totalFactsPanels?.length < DEFAULT_SLIDE_SHOW,
            'tab-fact-panels__slider': true,
            'hidden-border-field-set': true,
          })}
        >
          {showCarousel ? (
            <CarouselSection
              ref={carouselRef}
              slidesToShow={DEFAULT_SLIDE_SHOW}
              slidesToScroll={DEFAULT_SLIDE_SHOW}
              variableWidth={true}
              centerMode={false}
              infinite={false}
              afterChange={(e) => {
                setCurrentSlide(e);
              }}
            >
              {totalFactsPanels?.map((factPanel, index) => {
                const labelType = factPanel?.nutrientFormatType;
                return (
                  <RenderLabelType
                    key={index}
                    type={labelType}
                    productId={id}
                    factPanel={factPanel}
                    factPanelItem={factPanelItem}
                    onSelectPanel={(item) => handleSelectPanel(item, index)}
                  />
                );
              })}
            </CarouselSection>
          ) : (
            <div className='tab-fact-panels__linear-label scroller'>
              {totalFactsPanels?.map((factPanel, index) => {
                const labelType = factPanel?.nutrientFormatType;
                return (
                  <RenderLabelType
                    key={index}
                    type={labelType}
                    productId={id}
                    factPanel={factPanel}
                    factPanelItem={factPanelItem}
                    onSelectPanel={(item) => handleSelectPanel(item, index)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </WithLoading>

      <AddLabel
        ref={addLabelRef}
        visible={visible}
        setVisible={setVisible}
        factsPanel={factPanelItem}
        typeView='overview'
        onReset={() => {
          setFactPanelItem(null);
        }}
      />

      {visibleModalPreviewGroup && (
        <GroupPreview
          visible={visibleModalPreviewGroup}
          onCancel={offGroupModal}
          defaultGroupId={defaultGroup?.groupId}
          productGroups={selectedProductGroups}
        />
      )}

      {visibleModalAddGroup && (
        <GroupAddition
          title='Create Group'
          visible={visibleModalAddGroup}
          onCancel={offGroupModal}
          onAddSuccess={() => {
            setFactPanelItem(null);
          }}
        />
      )}

      {visibleModalEditGroup && (
        <GroupLayoutProvider>
          <GroupEdit
            title='Edit Group'
            visible={visibleModalEditGroup}
            onCancel={offGroupModal}
            defaultGroupId={defaultGroup?.groupId}
            productGroups={selectedProductGroups}
            onEditSuccess={() => {
              setFactPanelItem(null);
            }}
          />
        </GroupLayoutProvider>
      )}

      {visibleModalAssignGroup && (
        <GroupAssign
          visible={visibleModalAssignGroup}
          onCancel={offGroupModal}
          defaultGroupId={filteredProductGroups?.[0]?.groupId}
          productGroups={filteredProductGroups}
          additionalNutritionFacts={
            factPanelItem?.factsPanelType === 'NutritionFactsPanel'
              ? [factPanelItem]
              : []
          }
          additionalSupplementFacts={
            factPanelItem?.factsPanelType === 'SupplementFactsPanel'
              ? [factPanelItem]
              : []
          }
          additionalDrugFacts={
            factPanelItem?.factsPanelType === 'DrugFactsPanel'
              ? [factPanelItem]
              : []
          }
          additionalPetFood={
            factPanelItem?.factsPanelType === 'PetNutritionFactsPanel'
              ? [factPanelItem]
              : []
          }
          selectedGroupIds={selectedFactsPanelGroupIds}
          onAssignSuccess={() => {
            setFactPanelItem(null);
          }}
        />
      )}

      {visibleModalDeleteGroup && (
        <GroupDeletion
          productId={id}
          onCancel={offGroupModal}
          visible={visibleModalDeleteGroup}
          productGroups={selectedProductGroups}
          onDeleteSuccess={() => {
            setFactPanelItem(null);
          }}
        />
      )}
    </NutritionProvider>
  );
};

export default TabFactPanels;

export const TotalPanels = ({ totalValue }) => {
  return <Typography.Text strong>Total: {totalValue} Panel(s)</Typography.Text>;
};

export const RenderLabelType = ({
  type,
  factPanel,
  productId,
  factPanelItem,
  onSelectPanel,
}) => {
  const { productGroups } = useGetAllGroupProduct(productId);

  const labelType = {
    US_FDA_NFP_2020_STANDARD_NUTRITIONFACTSPANEL: (
      <VerticalLabel label={factPanel} />
    ),
    US_FDA_NFP_2020_STANDARD_SIDE_BY_SIDE_NUTRITIONFACTSPANEL: (
      <VerticalLabelWithMicronutrient label={factPanel} />
    ),
    US_FDA_NFP_2020_LINEAR_SMALL_PACKAGES_NUTRITIONFACTSPANEL: (
      <LinearLabel label={factPanel} />
    ),
    US_FDA_NFP_2020_STANDARD_SUPPLEMENTFACTSPANEL: (
      <SupplementStandardLabel label={factPanel} />
    ),
    US_FDA_NFP_2020_STANDARD_DRUGFACTSPANEL: (
      <DrugStandardLabel label={factPanel} />
    ),
    US_FDA_NFP_2020_STANDARD_PETNUTRITIONFACTSPANEL: (
      <PetNutritionFacts label={factPanel} />
    ),
  };

  const panelFactsType = `${type}_${factPanel?.factsPanelType?.toUpperCase()}`;

  const title = transformToTextGroupName(factPanel?.groupIds, productGroups);

  return (
    <GroupPartName title={title}>
      <div
        className={classNames({
          'panel-item': true,
          'scroller--y': true,
          'panel-item__selected': factPanelItem?.id === factPanel?.id,
        })}
        onClick={() => onSelectPanel(factPanel)}
      >
        <PanelContextContainer
          labelPreview={
            labelType[panelFactsType] || <VerticalLabel label={factPanel} />
          }
          downloadFileName={`${factPanel?.factsPanelType}_${factPanel?.id}`}
        >
          {labelType[panelFactsType] || <VerticalLabel label={factPanel} />}
        </PanelContextContainer>
      </div>
    </GroupPartName>
  );
};
