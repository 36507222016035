import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  error: false,

  aplDetail: false,
  aplDetailFilter: [],

  loadingColumnProductList: false,
  columnProductList: false,
  errorColumnProductList: false,

  aplProductListSelect: [],
  aplProductDetailListSelect: [],
  aplDetailSearchText: '',

  reloadGrid: false,

  loadingAplCommentList: false,
  aplCommentList: false,
  errorAplCommentList: false,
};

/* eslint-disable default-case, no-param-reassign */
const aplDetailReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_APL_DETAIL:
        draft.loading = true;
        draft.aplDetail = false;
        draft.error = false;
        break;
      case types.GET_APL_DETAIL_SUCCESS:
        draft.loading = false;
        draft.aplDetail = action.aplDetail;
        break;
      case types.GET_APL_DETAIL_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;
      case types.UPDATE_APL_DETAIL_FILTER:
        draft.aplDetailFilter = action.aplDetailFilter;
        break;
      case types.APL_PRODUCT_GRID_COLUMN_INFO:
        draft.loadingColumnProductList = true;
        draft.columnProductList = false;
        draft.errorColumnProductList = false;
        break;
      case types.APL_PRODUCT_GRID_COLUMN_INFO_SUCCESS:
        draft.loadingColumnProductList = false;
        draft.columnProductList = action.columnProductList;
        break;
      case types.APL_PRODUCT_GRID_COLUMN_INFO_ERROR:
        draft.errorColumnProductList = action.error;
        draft.loadingColumnProductList = false;
        break;
      case types.UPDATE_APL_PRODUCT_LIST_SELECT:
        draft.aplProductListSelect = action.aplProductListSelect;
        break;
      case types.UPDATE_APL_PRODUCT_DETAIL_LIST_SELECT:
        draft.aplProductDetailListSelect = action.aplProductDetailListSelect;
        break;
      case types.UPDATE_APL_DETAIL_SEARCH_TEXT:
        draft.aplDetailSearchText = action.aplDetailSearchText;
        break;
      case types.TOGGLE_RELOAD_GRID_APL_DETAIL:
        draft.reloadGrid = action.value;
        break;
      case types.GET_APL_COMMENT_LIST:
        draft.loadingAplCommentList = true;
        draft.aplCommentList = false;
        draft.errorAplCommentList = false;
        break;
      case types.GET_APL_COMMENT_LIST_SUCCESS:
        draft.loadingAplCommentList = false;
        draft.aplCommentList = action.aplCommentList;
        break;
      case types.GET_APL_COMMENT_LIST_ERROR:
        draft.errorAplCommentList = action.error;
        draft.loadingAplCommentList = false;
        break;
      default:
        break;
    }
  });

export default aplDetailReducer;
