// Action Types
export const UPDATE_VISIBLE_SELECTION = 'UPDATE_VISIBLE_SELECTION';
export const UPDATE_ITEM_CURRENT_SELECTION = 'UPDATE_ITEM_CURRENT_SELECTION';
export const UPDATE_ITEMS_SELECTION_DIALOG = 'UPDATE_ITEMS_SELECTION_DIALOG';
export const UPDATE_ITEMS_SELECTION = 'UPDATE_ITEMS_SELECTION';
export const UPDATE_PAGES_SELECTION = 'UPDATE_PAGES_SELECTION';
export const UPDATE_ITEM_PAGE_SELECTION = 'UPDATE_ITEM_PAGE_SELECTION';
export const UPDATE_COUNT_PAGE_SELECTION = 'UPDATE_COUNT_PAGE_SELECTION';
export const UPDATE_IS_DISPLAY_MASK = 'UPDATE_IS_DISPLAY_MASK';
export const UPDATE_VISIBLE = 'UPDATE_VISIBLE';

export const UPDATE_PAGE_CURRENT_MEMBER_MAINTAIN_USER =
  'UPDATE_PAGE_CURRENT_MEMBER_MAINTAIN_USER';

export const UPDATE_PAGE_CURRENT_MEMBER = 'UPDATE_PAGE_CURRENT_MEMBER';
export const UPDATE_PAGE_CURRENT_FOLDER = 'UPDATE_PAGE_CURRENT_FOLDER';
export const UPDATE_PAGE_CURRENT_OWNED_FOLDER =
  'UPDATE_PAGE_CURRENT_OWNED_FOLDER';
export const UPDATE_PAGE_CURRENT_SHARED_FOLDER =
  'UPDATE_PAGE_CURRENT_SHARED_FOLDER';

//* rerpoting
export const UPDATE_PAGE_CURRENT_REPORTING = 'UPDATE_PAGE_CURRENT_REPORTING';
export const UPDATE_PAGE_CURRENT_OWNED_REPORTING =
  'UPDATE_PAGE_CURRENT_OWNED_REPORTING';
export const UPDATE_PAGE_CURRENT_SHARED_REPORTING =
  'UPDATE_PAGE_CURRENT_SHARED_REPORTING';

export const UPDATE_PAGE_CURRENT_PRODUCT = 'UPDATE_PAGE_CURRENT_PRODUCT';
export const UPDATE_PAGE_CURRENT_DIGITAL_MEDIA =
  'UPDATE_PAGE_CURRENT_DIGITAL_MEDIA';
export const UPDATE_PAGE_CURRENT_DIGITAL_MEDIA_NEW =
  'UPDATE_PAGE_CURRENT_DIGITAL_MEDIA_NEW';
export const UPDATE_PAGE_CURRENT_ASSET = 'UPDATE_PAGE_CURRENT_ASSET';
export const UPDATE_PAGE_CURRENT_ASSET_FOR_MEMBER =
  'UPDATE_PAGE_CURRENT_ASSET_FOR_MEMBER';

export const UPDATE_PAGE_CURRENT_DOCUMENT = 'UPDATE_PAGE_CURRENT_DOCUMENT';
export const UPDATE_PAGE_CURRENT_MULTI_MEDIA =
  'UPDATE_SIZE_CURRENT_MULTI_MEDIA';
export const UPDATE_PAGE_CURRENT_SHARE_MODAL =
  'UPDATE_PAGE_CURRENT_SHARE_MODAL';
export const UPDATE_PAGE_CURRENT_FAVORITE_ASSET =
  'UPDATE_PAGE_CURRENT_FAVORITE_ASSET';
export const UPDATE_PAGE_CURRENT_FAVORITE_PRODUCT =
  'UPDATE_PAGE_CURRENT_FAVORITE_PRODUCT';
export const UPDATE_PAGE_CURRENT_FAVORITE_FOLDER =
  'UPDATE_PAGE_CURRENT_FAVORITE_FOLDER';
export const UPDATE_PAGE_CURRENT_FAVORITE_MEMBER =
  'UPDATE_PAGE_CURRENT_FAVORITE_MEMBER';
export const UPDATE_PAGE_CURRENT_WORKFLOW_ACCEPT_STEP =
  'UPDATE_PAGE_CURRENT_WORKFLOW_ACCEPT_STEP';
export const UPDATE_PAGE_CURRENT_WORKFLOW_APPROVE_STEP =
  'UPDATE_PAGE_CURRENT_WORKFLOW_APPROVE_STEP';
export const UPDATE_PAGE_CURRENT_MAPPING_SHARED_MEMBERS =
  'UPDATE_PAGE_CURRENT_MAPPING_SHARED_MEMBERS';

export const RESET_PAGE_CURRENT = 'RESET_PAGE_CURRENT';
export const RESET_PAGE_CURRENT_KEEP_PAGE_SIZE =
  'RESET_PAGE_CURRENT_KEEP_PAGE_SIZE';

export const RESET_PAGE_CURRENT_MEMBER = 'RESET_PAGE_CURRENT_MEMBER';
export const RESET_PAGE_CURRENT_MEDIA = 'RESET_PAGE_CURRENT_MEDIA';
export const RESET_PAGE_CURRENT_FAVORITE_ASSET =
  'RESET_PAGE_CURRENT_FAVORITE_ASSET';
export const RESET_PAGE_CURRENT_FAVORITE_FOLDER =
  'RESET_PAGE_CURRENT_FAVORITE_FOLDER';
export const RESET_PAGE_CURRENT_FAVORITE_MEMBER =
  'RESET_PAGE_CURRENT_FAVORITE_MEMBER';
export const RESET_PAGE_CURRENT_PRODUCTS = 'RESET_PAGE_CURRENT_PRODUCTS';

export const UPDATE_PAGE_CURRENT_PRODUCT_FOR_MEMBER =
  'UPDATE_PAGE_CURRENT_PRODUCT_FOR_MEMBER';

export const UPDATE_PAGE_CURRENT_FORM_MANANGE =
  'UPDATE_PAGE_CURRENT_FORM_MANANGE';
export const UPDATE_PAGE_CURRENT_HISTORY_ENTITY =
  'UPDATE_PAGE_CURRENT_HISTORY_ENTITY';

export const UPDATE_PAGE_CURRENT_PUBLICATION =
  'UPDATE_PAGE_CURRENT_PUBLICATION';

export const UPDATE_SIZE_CURRENT = 'UPDATE_SIZE_CURRENT';
export const UPDATE_TYPE_VIEW_CURRENT = 'UPDATE_TYPE_VIEW_CURRENT';
export const UPDATE_SEARCH_CURRENT = 'UPDATE_SEARCH_CURRENT';
export const UPDATE_DETAIL_CURRENT_ITEMS_SELECTION =
  'UPDATE_DETAIL_CURRENT_ITEMS_SELECTION';
export const DELETE_ITEMS_SELECTION = 'DELETE_ITEMS_SELECTION';
export const DELETE_CURRENT_ITEMS_SELECTION = 'DELETE_CURRENT_ITEMS_SELECTION';
export const UPDATE_AG_GRID_ITEMS_SELECTION = 'UPDATE_AG_GRID_ITEMS_SELECTION';
export const GET_FOLDER_LIST = 'GET_FOLDER_LIST';
export const GET_FOLDER_LIST_SUCCESS = 'GET_FOLDER_LIST_SUCCESS';
export const GET_FOLDER_LIST_ERROR = 'GET_FOLDER_LIST_ERROR';
export const ITEM_FOLDER_SELECTED = 'ITEM_FOLDER_SELECTED';
export const ADD_ENTITY_TO_FOLDER = 'ADD_ENTITY_TO_FOLDER';
export const ADD_ENTITY_TO_FOLDER_SUCCESS = 'ADD_ENTITY_TO_FOLDER_SUCCESS';
export const ADD_ENTITY_TO_FOLDER_ERROR = 'ADD_ENTITY_TO_FOLDER_ERROR';
export const UPDATE_MESSAGE_ADD_ENTITY_TO_FOLDER =
  'UPDATE_MESSAGE_ADD_ENTITY_TO_FOLDER';

// Choose grid columns
export const SAVE_GRID_COLUMNS = 'SAVE_GRID_COLUMNS';
export const CHOOSE_GRID_COLUMNS = 'CHOOSE_GRID_COLUMNS';

export const CHANGE_SELECTED_COLUMNS = 'CHANGE_SELECTED_COLUMNS';

export const CREATE_GRID_CONFIG = 'CREATE_GRID_CONFIG';
export const CREATE_GRID_CONFIG_SUCCESS = 'CREATE_GRID_CONFIG_SUCCESS';
export const CREATE_GRID_CONFIG_ERROR = 'CREATE_GRID_CONFIG_ERROR';
export const SAVE_ID_DEFAULT_CONFIG = 'SAVE_ID_DEFAULT_CONFIG';

export const GET_DEFAULT_GRID_COLUMNS = 'GET_DEFAULT_GRID_COLUMNS';
export const GET_DEFAULT_GRID_COLUMNS_SUCCESS =
  'GET_DEFAULT_GRID_COLUMNS_SUCCESS';
export const GET_DEFAULT_GRID_COLUMNS_ERROR = 'GET_DEFAULT_GRID_COLUMNS_ERROR';

export const SAVE_CHOSEN_COLUMNS = 'SAVE_CHOSEN_COLUMNS';

export const SAVE_CONFIG_COLUMNS = 'SAVE_CONFIG_COLUMNS';
export const SAVE_CONFIG_COLUMNS_SUCCESS = 'SAVE_CONFIG_COLUMNS_SUCCESS';
export const SAVE_CONFIG_COLUMNS_ERROR = 'SAVE_CONFIG_COLUMNS_ERROR';

export const CHANGE_POSITION_COLUMNS = 'CHANGE_POSITION_COLUMNS';
export const CHANGE_DISPLAYED_COLUMNS = 'CHANGE_DISPLAYED_COLUMNS ';

export const TOGGLE_CHANGE_GRID_CONFIG = 'TOGGLE_CHANGE_GRID_CONFIG';
export const CHANGE_WIDTH_COLUMN = 'CHANGE_WIDTH_COLUMN';
export const UPDATE_GRID_VIEW_PAGINATION = 'UPDATE_GRID_VIEW_PAGINATION';
export const UPDATE_GRID_VIEW_PAGINATION_SUCCESS =
  'UPDATE_GRID_VIEW_PAGINATION_SUCCESS';
export const UPDATE_IS_ONLY_DETAIL_GRID = 'UPDATE_IS_ONLY_DETAIL_GRID';

export const RESET_PAGE_CURRENT_ASSETS = 'RESET_PAGE_CURRENT_ASSETS';
export const UPDATE_ACTIVE_GRID_NAME = 'UPDATE_ACTIVE_GRID_NAME';

export const ADVANCED_FILTER_GRID_VIEW = 'ADVANCED_FILTER_GRID_VIEW';
export const QUERY_ADVANCED_FILTER = 'QUERY_ADVANCED_FILTER';
export const QUERY_ID_ACTIVE = 'QUERY_ID_ACTIVE';
export const UPDATE_IS_REFRESH_GRID = 'UPDATE_IS_REFRESH_GRID';
export const CLEAR_GRID_CONFIG = 'CLEAR_GRID_CONFIG';
export const GET_GRID_QUERY = 'GET_GRID_QUERY';
export const GET_GRID_QUERY_SUCCESS = 'GET_GRID_QUERY_SUCCESS';
export const GET_GRID_QUERY_ERROR = 'GET_GRID_QUERY_ERROR';

export const GET_FOLDER_LIST_QUERY = 'GET_FOLDER_LIST_QUERY';
export const GET_FOLDER_LIST_QUERY_SUCCESS = 'GET_FOLDER_LIST_QUERY_SUCCESS';
export const GET_FOLDER_LIST_QUERY_ERROR = 'GET_FOLDER_LIST_QUERY_ERROR';

export const TOGGLE_REFRESH_QUERY = 'TOGGLE_REFRESH_QUERY';
export const CHECK_QUERY_CONDITION = 'CHECK_QUERY_CONDITION';
export const MY_QUERY_DOUBLE_CLICK = 'MY_QUERY_DOUBLE_CLICK';

export const UPDATE_QUERY_FAV_MEMBER = 'UPDATE_QUERY_FAV_MEMBER';
export const UPDATE_QUERY_FAV_ASSET = 'UPDATE_QUERY_FAV_ASSET';
export const UPDATE_QUERY_FAV_FOLDER = 'UPDATE_QUERY_FAV_FOLDER';
export const UPDATE_QUERY_FAV_PRODUCT = 'UPDATE_QUERY_FAV_PRODUCT';
export const UPDATE_QUERY_PRODUCT = 'UPDATE_QUERY_PRODUCT';
export const UPDATE_QUERY_MEMBER = 'UPDATE_QUERY_MEMBER';
export const UPDATE_QUERY_DIGITAL_MEDIA = 'UPDATE_QUERY_DIGITAL_MEDIA';
export const UPDATE_QUERY_ASSET = 'UPDATE_QUERY_ASSET';
export const UPDATE_QUERY_DOCUMENT = 'UPDATE_QUERY_DOCUMENT';
export const UPDATE_QUERY_MULTI_MEDIA = 'UPDATE_QUERY_MULTI_MEDIA';
export const UPDATE_QUERY_REPORTING = 'UPDATE_QUERY_REPORTING';
export const UPDATE_QUERY_REPORTING_OWNED = 'UPDATE_QUERY_REPORTING_OWNED';
export const UPDATE_QUERY_REPORTING_SHARED = 'UPDATE_QUERY_REPORTING_SHARED';
export const UPDATE_QUERY_FOLDER = 'UPDATE_QUERY_FOLDER';
export const UPDATE_QUERY_FOLDER_OWNED = 'UPDATE_QUERY_FOLDER_OWNED';
export const UPDATE_QUERY_FOLDER_SHARED = 'UPDATE_QUERY_FOLDER_SHARED';
export const UPDATE_QUERY_ASSET_FOR_MEMBER = 'UPDATE_QUERY_ASSET_FOR_MEMBER';
export const UPDATE_QUERY_PRODUCT_FOR_MEMBER =
  'UPDATE_QUERY_PRODUCT_FOR_MEMBER';
export const UPDATE_QUERY_DIGITAL_MEDIA_NEW = 'UPDATE_QUERY_DIGITAL_MEDIA_NEW';

export const UPDATE_QUERY_ADVANCE_FAV_MEMBER =
  'UPDATE_QUERY_ADVANCE_FAV_MEMBER';
export const UPDATE_QUERY_ADVANCE_FAV_ASSET = 'UPDATE_QUERY_ADVANCE_FAV_ASSET';
export const UPDATE_QUERY_ADVANCE_FAV_FOLDER =
  'UPDATE_QUERY_ADVANCE_FAV_FOLDER';
export const UPDATE_QUERY_ADVANCE_FAV_PRODUCT =
  'UPDATE_QUERY_ADVANCE_FAV_PRODUCT';
export const UPDATE_QUERY_ADVANCE_PRODUCT = 'UPDATE_QUERY_ADVANCE_PRODUCT';
export const UPDATE_QUERY_ADVANCE_MEMBER = 'UPDATE_QUERY_ADVANCE_MEMBER';
export const UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA =
  'UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA';
export const UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA_NEW =
  'UPDATE_QUERY_ADVANCE_DIGITAL_MEDIA_NEW';
export const UPDATE_QUERY_ADVANCE_ASSET = 'UPDATE_QUERY_ADVANCE_ASSET';
export const UPDATE_QUERY_ADVANCE_DOCUMENT = 'UPDATE_QUERY_ADVANCE_DOCUMENT';
export const UPDATE_QUERY_ADVANCE_MULTI_MEDIA =
  'UPDATE_QUERY_ADVANCE_MULTI_MEDIA';
export const UPDATE_QUERY_ADVANCE_REPORTING = 'UPDATE_QUERY_ADVANCE_REPORTING';
export const UPDATE_QUERY_ADVANCE_REPORTING_OWNED =
  'UPDATE_QUERY_ADVANCE_REPORTING_OWNED';
export const UPDATE_QUERY_ADVANCE_REPORTING_SHARED =
  'UPDATE_QUERY_ADVANCE_REPORTING_SHARED';
export const UPDATE_QUERY_ADVANCE_FOLDER = 'UPDATE_QUERY_ADVANCE_FOLDER';
export const UPDATE_QUERY_ADVANCE_FOLDER_OWNED =
  'UPDATE_QUERY_ADVANCE_FOLDER_OWNED';
export const UPDATE_QUERY_ADVANCE_FOLDER_SHARED =
  'UPDATE_QUERY_ADVANCE_FOLDER_SHARED';
export const UPDATE_QUERY_ADVANCE_ASSET_FOR_MEMBER =
  'UPDATE_QUERY_ADVANCE_ASSET_FOR_MEMBER';
export const UPDATE_QUERY_ADVANCE_PRODUCT_FOR_MEMBER =
  'UPDATE_QUERY_ADVANCE_PRODUCT_FOR_MEMBER';

export const UPDATE_ID_QUERY_FAV_MEMBER = 'UPDATE_ID_QUERY_FAV_MEMBER';
export const UPDATE_ID_QUERY_FAV_ASSET = 'UPDATE_ID_QUERY_FAV_ASSET';
export const UPDATE_ID_QUERY_FAV_FOLDER = 'UPDATE_ID_QUERY_FAV_FOLDER';
export const UPDATE_ID_QUERY_FAV_PRODUCT = 'UPDATE_ID_QUERY_FAV_PRODUCT';
export const UPDATE_ID_QUERY_PRODUCT = 'UPDATE_ID_QUERY_PRODUCT';
export const UPDATE_ID_QUERY_MEMBER = 'UPDATE_ID_QUERY_MEMBER';
export const UPDATE_ID_QUERY_DIGITAL_MEDIA = 'UPDATE_ID_QUERY_DIGITAL_MEDIA';
export const UPDATE_ID_QUERY_DIGITAL_MEDIA_NEW =
  'UPDATE_ID_QUERY_DIGITAL_MEDIA_NEW';
export const UPDATE_ID_QUERY_ASSET = 'UPDATE_ID_QUERY_ASSET';
export const UPDATE_ID_QUERY_DOCUMENT = 'UPDATE_ID_QUERY_DOCUMENT';
export const UPDATE_ID_QUERY_MULTI_MEDIA = 'UPDATE_ID_QUERY_MULTI_MEDIA';
export const UPDATE_ID_QUERY_REPORTING = 'UPDATE_ID_QUERY_REPORTING';
export const UPDATE_ID_QUERY_REPORTING_OWNED =
  'UPDATE_ID_QUERY_REPORTING_OWNED';
export const UPDATE_ID_QUERY_REPORTING_SHARED =
  'UPDATE_ID_QUERY_REPORTING_SHARED';

export const UPDATE_ID_QUERY_FOLDER = 'UPDATE_ID_QUERY_FOLDER';
export const UPDATE_ID_QUERY_FOLDER_OWNED = 'UPDATE_ID_QUERY_FOLDER_OWNED';
export const UPDATE_ID_QUERY_FOLDER_SHARED = 'UPDATE_ID_QUERY_FOLDER_SHARED';
export const UPDATE_ID_QUERY_ASSET_FOR_MEMBER =
  'UPDATE_ID_QUERY_ASSET_FOR_MEMBER';

export const SET_USER_SEARCH_CATEGORIES_EFFECTED =
  'SET_USER_SEARCH_CATEGORIES_EFFECTED';

export const UPDATE_PAGE_CURRENT_PARTY_GDSN = 'UPDATE_PAGE_CURRENT_PARTY_GDSN';

export const TOGGLE_RELOAD_GRID = 'TOGGLE_RELOAD_GRID';
export const UPDATE_PARAMS_GRID_VIEW = 'UPDATE_PARAMS_GRID_VIEW';
export const CLEAR_FILTER_PARAMS_VIEW = 'CLEAR_FILTER_PARAMS_VIEW';

export const UPDATE_PAGE_CURRENT_REQUEST_GRID =
  'UPDATE_PAGE_CURRENT_REQUEST_GRID';

export const UPDATE_PAGE_CURRENT_TRANSITION = 'UPDATE_PAGE_CURRENT_TRANSITION';
export const UPDATE_PAGE_CURRENT_SSO_APPROVAL_MEMBER =
  'UPDATE_PAGE_CURRENT_SSO_APPROVAL_MEMBER';

export const UPDATE_PAGE_CURRENT_SHAREABLE_MEMBER_LIST =
  'UPDATE_PAGE_CURRENT_SHAREABLE_MEMBER_LIST';

export const UPDATE_PAGE_CURRENT_SHAREABLE_USER_LIST =
  'UPDATE_PAGE_CURRENT_SHAREABLE_USER_LIST';

export const UPDATE_PAGE_CURRENT_MANAGE_QUERY =
  'UPDATE_PAGE_CURRENT_MANAGE_QUERY';

export const UPDATE_PAGE_CURRENT_QAS_REQUIRE_FIELD =
  'UPDATE_PAGE_CURRENT_QAS_REQUIRE_FIELD';

export const UPDATE_PREV_PATHNAME_QUERY = 'UPDATE_PREV_PATHNAME_QUERY';

// Folder Grid View
export const TOGGLE_PREVENT_CALL_API_GRID_VIEW =
  'TOGGLE_PREVENT_CALL_API_GRID_VIEW';

export const UPDATE_IS_OPEN_ADVANCE_SEARCH = 'UPDATE_IS_OPEN_ADVANCE_SEARCH';

export const UPDATE_PAGE_CURRENT_TICKETING_SYSTEM = 'UPDATE_PAGE_CURRENT_TICKETING_SYSTEM';