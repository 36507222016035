/*
 * Request timeout Messages
 *
 * This contains all the text for the timeout page
 */
import { defineMessages } from 'react-intl';

export const scope = 'Taco.error.timeout';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: '408 Request Timeout',
  },
  subTitle: {
    id: `${scope}.subtitle`,
    defaultMessage: 'Sorry, the request has time out',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Back to Home',
  },
});
