import { Dropdown, Menu, Space } from 'antd';

import {
  EyeOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  MenuOutlined,
  PullRequestOutlined,
} from '@ant-design/icons';

import {
  RibbonButton as MenuItemButton,
  FormButton as Button,
} from 'common/components';

import './GroupActions.less';

export const GroupActions = ({
  overlay = DefaultOverlayButtons,
  children,
  ...rest
}) => {
  return (
    <Dropdown
      overlayClassName='group-actions__dropdown'
      overlay={overlay}
      {...rest}
    >
      {children}
    </Dropdown>
  );
};

export const DefaultOverlayButtons = () => {
  return (
    <Space>
      <Menu>
        <MenuItem key='add-group'>
          <MenuItemAddGroup />
        </MenuItem>
        <MenuItem key='preview-group'>
          <MenuItemPreviewGroup />
        </MenuItem>
        <MenuItem key='preview-group'>
          <MenuItemAssignGroup />
        </MenuItem>
        <MenuItem key='edit-group'>
          <MenuItemEditGroup />
        </MenuItem>
        <MenuItem key='remove-group'>
          <MenuItemDeleteGroup />
        </MenuItem>
      </Menu>
    </Space>
  );
};

export const MenuItem = ({ style, children, ...rest }) => {
  return (
    <Menu.Item
      style={{
        backgroundColor: 'inherit',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Menu.Item>
  );
};

export const MenuItemAddGroup = (props) => {
  return (
    <MenuItemButton
      icon={<PlusCircleOutlined />}
      label='Add Group'
      size='small'
      {...props}
    />
  );
};

export const MenuItemPreviewGroup = (props) => {
  return (
    <MenuItemButton
      icon={<EyeOutlined />}
      label='Preview Group'
      size='small'
      {...props}
    />
  );
};

export const MenuItemAssignGroup = (props) => {
  return (
    <MenuItemButton
      icon={<PullRequestOutlined />}
      label='Assign Group'
      size='small'
      {...props}
    />
  );
};

export const MenuItemEditGroup = (props) => {
  return (
    <MenuItemButton
      icon={<EditOutlined />}
      label='Edit Group'
      size='small'
      {...props}
    />
  );
};

export const MenuItemDeleteGroup = (props) => {
  return (
    <MenuItemButton
      icon={<DeleteOutlined />}
      label='Delete Group'
      size='small'
      {...props}
    />
  );
};

export const ButtonGroupWrapper = (props) => {
  return (
    <Button
      text='Group Actions'
      type='default'
      icon={<MenuOutlined />}
      {...props}
    />
  );
};

// Menu Item
MenuItem.AddGroup = MenuItemAddGroup;
MenuItem.PreviewGroup = MenuItemPreviewGroup;
MenuItem.AssignGroup = MenuItemAssignGroup;
MenuItem.EditGroup = MenuItemEditGroup;
MenuItem.DeleteGroup = MenuItemDeleteGroup;

// GroupActions
GroupActions.ButtonWrapper = ButtonGroupWrapper;
