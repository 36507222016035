import React from 'react';
import PropTypes from 'prop-types';
import './VideoPreview.less';

export const Video = ({ url, className }) => {
  return (
    <div className='video-preview'>
      <video controls className={`video-preview__video ${className}`} key={url}>
        <source src={url} />
      </video>
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};

export default Video;
