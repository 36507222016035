import React from 'react';

import { Divider } from 'antd';
import './RibbonDivider.less';

const RibbonDivider = () => {
  return (
    <div className='ribbon-divider'>
      <Divider type='vertical' style={{ height: 40, margin: 'auto 8px' }} />
    </div>
  );
};

export default RibbonDivider;
