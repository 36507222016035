import React from 'react';
import { useSelector } from 'react-redux';

import { cloneDeep } from 'lodash';

import {
  AssetActionsSection,
  PreviewSection,
  VersionSection,
} from './components/sections/asset';
import { OtherLayout, ViewLayout, AdvanceStack } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import ShareSection from './components/sections/shared/ShareSection';
import Addition from './components/sections/home-grid/AdditionSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import * as assetFullViewSelector from 'pages/asset-full-view/controllers/selectors';
import homeSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';
import assetSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';

import { useFolderByLevel } from 'hooks/useFolderByLevel';

const AssetFull = () => {
  const assetFullView = useSelector(
    assetFullViewSelector.makeSelectAssetData()
  );

  const isIframe = assetFullView?.isIFrame;
  const isCreating = useSelector(homeSelectors.selectAssetCreating());
  const isEditing = useSelector(assetSelectors.selectAssetEdit());

  const { shouldKeepFolderBreadcrumb, keepFolderBreadcrumb } =
    useFolderByLevel();

  const getAssetWithType = () => {
    const shareAsset = cloneDeep(assetFullView);

    if (shareAsset) {
      shareAsset.type = 'asset';
      return shareAsset;
    }

    // return origin asset if !shareAsset
    return assetFullView;
  };

  const closeDetailCallback = () => {
    //* keep folder breadcrumb when close folder item detail
    shouldKeepFolderBreadcrumb && keepFolderBreadcrumb(true);
  };

  const selectedAsset = getAssetWithType();

  const isVersionPage =
    window.location.pathname.indexOf('asset/versions/') > -1;

  const isUploadCreatePage =
    window.location.pathname.indexOf('/asset/create') > -1;

  const isDisabled = isVersionPage || isUploadCreatePage;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled={true} />
      <RibbonDivider />
      <Addition
        type='asset'
        selectedItemList={[selectedAsset]}
        isDisabled={isDisabled}
      />
      <ShareSection
        selectedItemList={[selectedAsset]}
        assetDetailId={assetFullView?.id}
      />
      <RibbonDivider />
      <AssetActionsSection
        isCreating={isCreating}
        isEditting={isEditing}
        isIframe={isIframe}
        isDisabled={isDisabled}
        viewType='assetFullView'
        assetDetailId={assetFullView?.id}
      />
      <RibbonDivider />
      <PreviewSection isDisabled={isDisabled} />
      <RibbonDivider />
      <VersionSection isCreating={isCreating} />
      <RibbonDivider />

      <AdvanceStack
        selectedItemList={[selectedAsset]}
        isDisabled={isDisabled}
        disabledAdvanceFilter={true}
      />
      <OtherLayout
        ribbonSide='right'
        ribbonType='assets'
        closeDetailCallback={closeDetailCallback}
      />
    </RibbonBar>
  );
};

export default AssetFull;
