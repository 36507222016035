import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectEulaMaintenance = (state) => state.eulaMaintenance || initialState;

const selectEulaGridLoading = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.gridLoading);

const selectEulaForm = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.eulaForm);

const selectEulaGridPageSize = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.pageSize);

const selectEulaGridPageNumber = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.pageNumber);

const selectEulaGridSearch = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.search);

const selectEulaList = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.eulaList);

const selectEulaGridColumns = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.gridColumns);

const selectEulaGridTotal = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.gridTotal);

const selectEulaDetailData = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.detailData);

const selectIsDetailsViewEula = () =>
  createSelector(selectEulaMaintenance, (eulaState) => eulaState.isDetailsView);

const selectIsEulaUploadMediaOpen = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.isUploadEulaMediaOpen
  );

// Accepted user eula
const selectAcceptedUserEulaLoading = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedEulaLoading
  );
const selectAcceptedUserEulaColumns = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedEulaColumns
  );
const selectAcceptedUserEula = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedUserEula
  );
const selectAcceptedUserEulaPageSize = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedEulaPageSize
  );
const selectAcceptedUserEulaPageNumber = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedEulaPageNumber
  );
const selectAcceptedUserEulaTotal = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedEulaTotal
  );
const selectAcceptedUserEulaError = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedEulaError
  );
const selectAcceptedUserEulaSearch = () =>
  createSelector(
    selectEulaMaintenance,
    (eulaState) => eulaState.acceptedSearch
  );

export {
  selectEulaGridLoading,
  selectEulaGridPageSize,
  selectEulaGridPageNumber,
  selectEulaGridSearch,
  selectEulaList,
  selectEulaGridColumns,
  selectEulaGridTotal,
  selectEulaDetailData,
  selectIsDetailsViewEula,
  selectEulaForm,
  selectIsEulaUploadMediaOpen,
  selectAcceptedUserEulaLoading,
  selectAcceptedUserEulaColumns,
  selectAcceptedUserEula,
  selectAcceptedUserEulaPageSize,
  selectAcceptedUserEulaPageNumber,
  selectAcceptedUserEulaTotal,
  selectAcceptedUserEulaError,
  selectAcceptedUserEulaSearch,
};
