import React, { useState, useEffect, useRef, useContext } from 'react';

import { Typography } from 'antd';

import { getLocaleTime } from 'utils/timezone';
import { LocaleContext } from 'context/Locale';
import { TimezoneContext } from 'context/Timezone';

const { Text } = Typography;

/**
 * A simple text displays current time by locale & timezone
 * @author Brian Pick
 */
const Counter = (props) => {
  let intervalId = useRef(null);
  const [currentTime, setCurrentTime] = useState(undefined);

  // locale context
  const context = useContext(LocaleContext);
  // eslint-disable-next-line
  const { locale, changeLocale } = context;

  // timezone context
  const tzContext = useContext(TimezoneContext);
  // eslint-disable-next-line
  const { timezone, changeTimezone } = tzContext;

  useEffect(() => {
    if (!intervalId.current) clearInterval(intervalId.current);
    intervalId.current = setInterval(() =>
      setCurrentTime(
        getLocaleTime(new Date(), locale, {
          timeZone: timezone,
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })
      )
    );
    return () => clearInterval(intervalId.current);
  });
  return <Text {...props}>{currentTime}</Text>;
};

export default Counter;
