import gridSelector from 'common/components/grid-view/controllers/selectors';
import { useGetProductFullView } from 'hooks';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useCheckIXOneCapture = () => {
  const { id: productId } = useParams();

  const { productFull } = useGetProductFullView({ productId });

  const selectedProductGrid = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const shouldShowIXOneInProductDetail = () => {
    return productFull?.isOwner;
  };

  const shouldShowIXOneInProductGrid = () => {
    if (selectedProductGrid?.length <= 0) return false;
    return selectedProductGrid?.every((productItem) => productItem?.isOwner);
  };

  return productId
    ? shouldShowIXOneInProductDetail()
    : shouldShowIXOneInProductGrid();
};

export default useCheckIXOneCapture;
