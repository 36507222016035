import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import useCustomScroll from 'hooks/useCustomScroll';

import QueryDraggable from './QueryDraggable';

import { Typography, Row } from 'antd';
import { DragOutlined } from '@ant-design/icons';

import './QueryConditions.less';

const QueryConditions = (props) => {
  const {
    queryConditions,
    draggingTaskId,
    selectedTaskIds,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    queryChildHidden,
    handleRemoveQueryConditions,
    handleQueryCondition,
    handleQueryValueConditionIn,
    handleQueryValue,
    handleConjunction,
    handleQueryOtherValue,
    viewMode,
    advancedSearchGridView,
    queryConditionsId = 'query-conditions',
    handleQueryDateBetween,
    handleUploadAdvancedFile,
  } = props;
  const nameClass = !!advancedSearchGridView
    ? 'query-conditions-adv'
    : 'query-conditions';
  useCustomScroll(`.${nameClass}`);
  return (
    <Droppable
      droppableId={'query_conditions'}
      isDropDisabled={
        draggingTaskId && draggingTaskId.indexOf('_column') > -1 ? true : false
      }
      isCombineEnabled
    >
      {(provided, { isDraggingOver }) => (
        <div
          ref={provided.innerRef}
          className={classnames({
            'query-conditions-adv scroller-extend':
              true && advancedSearchGridView,
            'query-conditions-adv--dragging-over':
              isDraggingOver && advancedSearchGridView,
            'query-conditions scroller-extend': true && !advancedSearchGridView,
            'query-conditions--dragging-over':
              isDraggingOver && !advancedSearchGridView,
          })}
          id={queryConditionsId}
        >
          <div style={{ pointerEvents: viewMode ? 'none' : 'auto' }}>
            {queryConditions &&
              queryConditions.length > 0 &&
              queryConditions.map((task, index) => {
                const isSelected =
                  selectedTaskIds.indexOf(task.mapFieldName + '_query') > -1;
                const isGhosting =
                  isSelected &&
                  draggingTaskId &&
                  draggingTaskId !== task.mapFieldName + '_query';
                const isHiddenDrag =
                  queryChildHidden &&
                  queryChildHidden.length > 0 &&
                  queryChildHidden.indexOf(task.id) > -1;
                return (
                  <QueryDraggable
                    task={task}
                    index={index}
                    lastIndex={
                      queryConditions[index]?.level !==
                      queryConditions[index + 1]?.level
                    }
                    key={task?.mapFieldName + '_query' + '__' + task?.id}
                    isSelected={isSelected}
                    isGhosting={isGhosting}
                    isHiddenDrag={isHiddenDrag}
                    selectionCount={selectedTaskIds.length}
                    toggleSelection={toggleSelection}
                    toggleSelectionInGroup={toggleSelectionInGroup}
                    multiSelectTo={multiSelectTo}
                    handleRemoveQueryConditions={handleRemoveQueryConditions}
                    handleQueryCondition={handleQueryCondition}
                    handleQueryValueConditionIn={handleQueryValueConditionIn}
                    handleQueryValue={handleQueryValue}
                    handleConjunction={handleConjunction}
                    handleQueryOtherValue={handleQueryOtherValue}
                    viewMode={viewMode}
                    advancedSearchGridView={advancedSearchGridView}
                    queryConditionsId={queryConditionsId}
                    handleQueryDateBetween={handleQueryDateBetween}
                    handleUploadAdvancedFile={handleUploadAdvancedFile}
                  />
                );
              })}
          </div>
          {queryConditions?.length === 0 && (
            <Row className={`${nameClass}__none-data`}>
              <DragOutlined className={`${nameClass}__none-data-icon`} />
              <Typography.Title level={5}>
                Drag Attributes to this area to Query Condtions
              </Typography.Title>
            </Row>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

QueryConditions.propTypes = {
  queryConditions: PropTypes.array,
  draggingTaskId: PropTypes.string,
  selectedTaskIds: PropTypes.array,
  toggleSelection: PropTypes.func,
  toggleSelectionInGroup: PropTypes.func,
  multiSelectTo: PropTypes.func,
  queryChildHidden: PropTypes.array,
  handleRemoveQueryConditions: PropTypes.func,
  handleQueryCondition: PropTypes.func,
  handleQueryValueConditionIn: PropTypes.func,
  handleQueryValue: PropTypes.func,
  handleConjunction: PropTypes.func,
  handleQueryOtherValue: PropTypes.func,
  viewMode: PropTypes.bool,
  advancedSearchGridView: PropTypes.bool,
};

export default React.memo(QueryConditions);
