export const handleResponse = ({ response, errorMessage } = {}) => {
  const { isSuccess, message, data } = response || {};

  if (isSuccess) {
    return Promise.resolve(data);
  } else {
    const finalErrorMessage = message || errorMessage || 'Server Error';

    return Promise.reject(finalErrorMessage);
  }
};
