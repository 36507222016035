import * as actions from './constants';

export function updateNewFormTemplate(formData) {
  return {
    type: actions.UPDATE_NEW_FORM_TEMPLATE,
    formData,
  };
}
export function updateNewFormTemplateError(error) {
  return {
    type: actions.UPDATE_NEW_FORM_TEMPLATE_ERROR,
    error,
  };
}

export function updateNewFormTemplateSuccess() {
  return {
    type: actions.UPDATE_NEW_FORM_TEMPLATE_SUCCESS,
  };
}

export function getFormTemplateDetail(formId) {
  return {
    type: actions.GET_FORM_TEMPLATE_DETAIL,
    formId,
  };
}
export function getFormTemplateDetailError(error) {
  return {
    type: actions.GET_FORM_TEMPLATE_DETAIL_ERROR,
    error,
  };
}

export function getFormTemplateDetailSuccess(formData) {
  return {
    type: actions.GET_FORM_TEMPLATE_DETAIL_SUCCESS,
    formData,
  };
}

export function updateSelectedDataPoint(payload) {
  return {
    type: actions.UPDATE_SELECTED_DATA_POINT,
    payload,
  };
}

export const getFormDetail = (payload, options) => ({
  type: actions.GET_FORM_DETAIL,
  payload,
  options,
});
export const getFormDetailSuccess = (payload) => ({
  type: actions.GET_FORM_DETAIL_SUCCESS,
  payload,
});
export const getFormDetailFail = (payload) => ({
  type: actions.GET_FORM_DETAIL_FAIL,
  payload,
});

export const toggleViewLoading = (boolean) => ({
  type: actions.FORM_MANAGE_TOGGLE_VIEW_LOADING,
  boolean,
});

export const editFormTemplate = (params) => ({
  type: actions.EDIT_FORM_TEMPLATE,
  params,
});
export const editFormTemplateSuccess = () => ({
  type: actions.EDIT_FORM_TEMPLATE_SUCCESS,
});
export const editFormTemplateFail = (error) => ({
  type: actions.EDIT_FORM_TEMPLATE_FAIL,
  error,
});

export const updateUnmountSuccess = () => ({
  type: actions.UPDATE_UNMOUTE_SUCCESS,
});
export const updateModeView = (modeViewForm) => ({
  type: actions.UPDATE_MODE_VIEW_MAINTENANCE_FORM,
  modeViewForm,
});

export const replaceFormFileSuccess = (payload) => ({
  type: actions.FORM_MANAGE_REPLACE_FORM_FILE_SUCCESS,
  payload,
});

export const deleteFormItem = (payload) => ({
  type: actions.FORM_MANAGE_DELETE_FORM_ITEM,
  payload,
});
export const deleteFormItemSuccess = (payload) => ({
  type: actions.FORM_MANAGE_DELETE_FORM_ITEM_SUCCESS,
  payload,
});
export const deleteFormItemFail = (payload) => ({
  type: actions.FORM_MANAGE_DELETE_FORM_ITEM_FAIL,
  payload,
});

export const toggleRefreshDataPointList = () => ({
  type: actions.FORM_MANAGE_TOGGLE_REFRESH_DATA_POINT_LIST,
});

export const toggleRefreshDataPointListSuccess = (boolean) => ({
  type: actions.FORM_MANAGE_TOGGLE_REFRESH_DATA_POINT_LIST_SUCCESS,
  boolean,
});

export const cloneFormItem = (payload) => ({
  type: actions.FORM_MANAGE_CLONE_FORM_ITEM,
  payload,
});
export const cloneFormItemFail = (payload) => ({
  type: actions.FORM_MANAGE_CLONE_FORM_ITEM_FAIL,
  payload,
});
export const cloneFormItemSuccess = (payload) => ({
  type: actions.FORM_MANAGE_CLONE_FORM_ITEM_SUCCESS,
  payload,
});
export function replaceFormTemplateFile(formData) {
  return {
    type: actions.REPLACE_FORM_TEMPLATE_FILE,
    formData,
  };
}

export const toggleClearDataPointList = (boolean) => ({
  type: actions.TOGGLE_CLEAR_DATA_POINT_LIST,
  boolean,
});

export const toggleClearDataPointSearchText = (boolean) => ({
  type: actions.TOGGLE_CLEAR_DATA_POINT_SEARCH_TEXT,
  boolean,
});

export const toggleFormDetailFullScreen = (boolean) => ({
  type: actions.TOGGLE_FORM_DETAIL_FULL_SCREEN,
  boolean,
});

export const toggleFormDetailFloatMode = (boolean) => ({
  type: actions.TOGGLE_FORM_DETAIL_FLOAT_MODE,
  boolean,
});

export const updateSelectedDataPointData = (payload) => ({
  type: actions.UPDATE_SELECTED_DATA_POINT_DATA,
  payload,
});

export const refreshDataPointDetail = () => ({
  type: actions.REFRESH_DATA_POINT_DETAIL,
});
export const refreshDataPointDetailSuccess = (boolean) => ({
  type: actions.REFRESH_DATA_POINT_DETAIL_SUCCESS,
  boolean,
});

export const downloadExportedFormSaga = (payload) => ({
  type: actions.FORM_MANAGE_DOWNLOAD_EXPORTED_FORM,
  payload,
});

export const getExportedFormShortDetail = (payload) => ({
  type: actions.FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL,
  payload,
});
export const getExportedFormShortDetailSuccess = (payload) => ({
  type: actions.FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_SUCCESS,
  payload,
});
export const getExportedFormShortDetailFail = (payload) => ({
  type: actions.FORM_MANAGE_GET_EXPOTED_FORM_SHORT_DETAIL_FAIL,
  payload,
});
export const loadScriptSpreadJs = () => ({
  type: actions.UPDATE_LOAD_SCRIPT_SPREADJS,
});