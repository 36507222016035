import React from 'react';

import { Button } from 'antd';

import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

const AssetPreviewDownloadBtn = (props) => {
  const { loading, onClick } = props;

  return (
    <Button
      className='dam-preview__btn'
      type='primary'
      icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
      onClick={onClick}
    />
  );
};

export default AssetPreviewDownloadBtn;
