import React from 'react';
import { Row, Col, Typography, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  ScheduleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import ConfirmResult from './ConfirmResult';
import { DATE_FORMAT } from 'static/Constants';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { formatMDY } from 'utils/formatDate';

const { Text } = Typography;

const ConfirmContent = (props) => {
  const {
    multiImportFormValue,
    destinationFolder,
    isUploadFromLocal,
    isEnabledImportFolder,
  } = props;

  const { destinationFolder: folder, isCreateSubfolder } =
    destinationFolder ?? {};

  const importConfirmContent = [
    {
      label: 'Default status',
      rerult: (
        <ConfirmResult
          value={multiImportFormValue?.defaultStatus}
          iconsRender={[
            {
              status: 'active',
              icon: CheckCircleOutlined,
              text: 'Active',
              conditionCheck: (value) => value.toLowerCase() === 'active',
            },
            {
              status: 'inactive',
              icon: WarningOutlined,
              text: 'Inactive',
              conditionCheck: (value) => value.toLowerCase() === 'inactive',
            },
            {
              status: 'pending',
              icon: ExclamationCircleOutlined,
              text: 'Pending',
              conditionCheck: (value) => value.toLowerCase() === 'pending',
            },
          ]}
        />
      ),
    },
    {
      label: 'Create thumbs',
      rerult: (
        <ConfirmResult
          value={multiImportFormValue.isCreateThumbs}
          iconsRender={[
            {
              status: 'active',
              icon: CheckSquareOutlined,
              text: 'Yes',
              conditionCheck: (value) => value === true,
            },
            {
              status: 'inactive',
              icon: CloseSquareOutlined,
              text: 'No',
              conditionCheck: (value) => value === false,
            },
          ]}
        />
      ),
    },
    {
      label: 'Auto apply/replace on product',
      rerult: (
        <ConfirmResult
          value={multiImportFormValue.autoApplyOrReplaceOnProducts}
          iconsRender={[
            {
              status: 'active',
              icon: CheckSquareOutlined,
              text: 'Yes',
              conditionCheck: (value) => value === true,
            },
            {
              status: 'inactive',
              icon: CloseSquareOutlined,
              text: 'No',
              conditionCheck: (value) => value === false,
            },
          ]}
        />
      ),
    },
    {
      label: 'Effective Date',
      rerult: (
        <ConfirmResult
          value={
            //TODO: DATETIME
            multiImportFormValue?.EffectedDate
              ? formatMDY(multiImportFormValue?.EffectedDate)
              : 'N/A'
          }
          placeholderValue='No Effective Date'
          iconsRender={[
            {
              status: 'active',
              icon: ScheduleOutlined,
            },
            {
              status: 'inactive',
              icon: ScheduleOutlined,
            },
          ]}
          isDateRender
        />
      ),
    },
    {
      label: 'Expiration Date',
      rerult: (
        <ConfirmResult
          value={
            multiImportFormValue?.ExpirationDate
              ? formatMDY(multiImportFormValue?.ExpirationDate)
              : 'N/A'
          }
          placeholderValue='No Expiration Date'
          iconsRender={[
            {
              status: 'active',
              icon: ScheduleOutlined,
            },
            {
              status: 'inactive',
              icon: ScheduleOutlined,
            },
          ]}
          isDateRender
        />
      ),
    },
  ];

  const showCreateSubFolder = {
    label: 'Create subfolder',
    rerult: (
      <ConfirmResult
        value={isCreateSubfolder}
        iconsRender={[
          {
            status: 'active',
            icon: CheckSquareOutlined,
            text: 'Yes',
            conditionCheck: (value) => value === true,
          },
          {
            status: 'inactive',
            icon: CloseSquareOutlined,
            text: 'No',
            conditionCheck: (value) => value === false,
          },
        ]}
      />
    ),
  };

  const folderName = folder?.folderName || folder?.description;

  const showDestinationFolder = {
    label: 'Destination folder',
    rerult: (
      <ConfirmResult
        value={folder ? true : false}
        iconsRender={[
          {
            status: 'active',
            icon: CheckSquareOutlined,
            text: 'Yes',
            conditionCheck: (value) => value === true,
          },
          {
            status: 'inactive',
            icon: CloseSquareOutlined,
            text: 'No',
            conditionCheck: (value) => value === false,
          },
        ]}
      />
    ),
  };

  const addEntityToConfirmModal = ((content) => {
    const showList = cloneDeep(content);
    if (!isEnabledImportFolder) return showList;

    showList.splice(2, 0, showDestinationFolder);
    if (!isUploadFromLocal) showList.splice(2, 0, showCreateSubFolder);

    return showList;
  })(importConfirmContent);

  return (
    <div className='asset-upload-confirm'>
      <div>
        <Text strong style={{ fontSize: 18 }}>
          Please confirm your setting before uploading
        </Text>
      </div>
      {addEntityToConfirmModal.map((rowItem, idx) => {
        return (
          <Row key={'create-asset-confirm-content-' + idx} justify='center'>
            <Col xs={14} style={{ textAlign: 'right' }}>
              {rowItem.label}:
            </Col>
            {rowItem.rerult}
          </Row>
        );
      })}
    </div>
  );
};

export default ConfirmContent;
