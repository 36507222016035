import React from 'react';

import { Button } from 'antd';

import classnames from 'classnames';

import './GeneralButton.less';
// clone from SearchGridItemButton.js
const GeneralButton = (props) => {
  const { classNames = [], children, ...restProps } = props;
  return (
    <Button
      className={classnames('general-btn', [...classNames])}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default GeneralButton;
