import React from 'react';

import { BlockPicker } from 'react-color';

const EmailEditorColorInput = (props) => {
  const { color, onChange } = props;

  const colors = [
    '#f5222d',
    '#fa541c',
    '#fa8c16',
    '#faad14',
    '#fadb14',
    '#a0d911',
    '#52c41a',
    '#13c2c2',
    '#1890ff',
    '#2f54eb',
    '#722ed1',
    '#eb2f96',
    '#bfbfbf',
    '#262626',
    '#000000',
  ];

  return (
    <div>
      <BlockPicker
        triangle='hide'
        onChange={onChange}
        color={color}
        colors={colors}
      />
    </div>
  );
};

export default EmailEditorColorInput;
