import React from 'react';
import { Modal } from 'antd';
import './DialogModal.less';

import { ErrorIcon, InfoIcon, SuccessIcon, WarnIcon } from './DialogIcon';

function DialogModal(props) {
  const { type, visible, children, onCancel, onOk, ...restProps } = props;

  return (
    <Modal
      wrapClassName='dialog-modal-custom'
      title=''
      centered
      visible={visible}
      icon={
        type === 'warn' ? (
          <WarnIcon />
        ) : type === 'error' ? (
          <ErrorIcon />
        ) : type === 'success' ? (
          <SuccessIcon />
        ) : (
          <InfoIcon />
        )
      }
      onCancel={() => onCancel && onCancel()}
      onOk={() => onOk && onOk()}
      {...restProps}
    >
      {type === 'warn' ? (
        <WarnIcon />
      ) : type === 'error' ? (
        <ErrorIcon />
      ) : type === 'success' ? (
        <SuccessIcon />
      ) : (
        <InfoIcon />
      )}
      {children}
    </Modal>
  );
}

export default DialogModal;
