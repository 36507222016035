import React from 'react';
import PropTypes from 'prop-types';
import Control from '../Control';
const InlineControl = (props) => {
  const { icon, text, ...rest } = props;
  return (
    <Control
      textAlign='left'
      mode='inline'
      cardBodyStyle={{
        padding: '0px 4px',
        margin: '0px 0px 8px 0px',
      }}
      {...rest}
    >
      {icon}
      {text}
    </Control>
  );
};

InlineControl.propTypes = {
  icon: PropTypes.element,
};

export default InlineControl;
