import React from 'react';
import {
  UserOutlined,
  TeamOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  InboxOutlined,
  FolderOpenOutlined,
  BookOutlined,
} from '@ant-design/icons';

export const helpIcon = [
  { type: 'UserOutlined', icon: UserOutlined, name: 'User' },
  { type: 'TeamOutlined', icon: TeamOutlined, name: 'Member' },
  { type: 'PictureOutlined', icon: PictureOutlined, name: 'Picture' },
  { type: 'VideoCameraOutlined', icon: VideoCameraOutlined, name: 'Video' },
  { type: 'FileTextOutlined', icon: FileTextOutlined, name: 'File' },
  { type: 'InboxOutlined', icon: InboxOutlined, name: 'Inbox' },
  { type: 'FolderOpenOutlined', icon: FolderOpenOutlined, name: 'Folder' },
  { type: 'BookOutlined', icon: BookOutlined, name: 'General' },
];
