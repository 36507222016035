import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RibbonButton } from 'common/components';
import gridSelector from 'common/components/grid-view/controllers/selectors';

import { useGetProductFullView } from 'hooks';

import messages from 'i18n/messages/home';
import AssignCategoryToProductModal from 'pages/home/ribbon/components/controls/product/AssignCategoryToProductModal';

const AssignCategoryToProduct = ({ isProductDetail }) => {
  const { id: productId } = useParams();

  const intl = useIntl();
  const selectedItemDetailList = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const { productFull } = useGetProductFullView({
    productId,
  });
  const [modalVisible, setModalVisible] = useState(false);

  const onClickButton = () => {
    openAssignCategoryToProductModal();
  };

  const openAssignCategoryToProductModal = () => {
    setModalVisible(true);
  };

  return (
    <>
      <RibbonButton
        size={'small'}
        className='button-item-dropdown'
        icon={<i className='fa-solid fa-tags'></i>}
        label={intl.formatMessage(messages.assignCategoryToProductTitle)}
        onClick={onClickButton}
        disabled={
          isProductDetail ? !productFull : !selectedItemDetailList?.length
        }
      />
      {modalVisible ? (
        <AssignCategoryToProductModal
          visible={modalVisible}
          setVisible={setModalVisible}
          isProductDetail={isProductDetail}
          productFull={productFull}
        />
      ) : null}
    </>
  );
};

export default AssignCategoryToProduct;
