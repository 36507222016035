import produce from 'immer';
import * as types from './constants';

// initial state
export const initialState = {
  loading: false,
  error: false,
  members: [],
  total: 0,
  pageSize: 20,
  pageNumber: 1,
  search: '',
  columns: [],
  gridName: '',
  memberData: null,
  loadingDetail: false,
  publicContactList: null,
  banListColumns: [],

  productsForMember: {
    loading: false,
    pageIndex: 1,
    pageSize: 20,
    totalItems: 0,
    data: [],
    columns: [],
    memberId: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const productReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SHOW_LOADING:
        draft.loading = true;
        break;
      case types.GET_MEMBER_LIST:
        draft.loading = true;
        draft.error = false;
        draft.pageSize = action.pageSize;
        draft.pageNumber = action.pageNumber;
        draft.search = action.search;
        break;
      case types.GET_MEMBER_LIST_SUCCESS:
        draft.loading = false;
        draft.members = action.members;
        draft.total = action.total;
        break;
      case types.GET_MEMBER_LIST_ERROR:
        draft.loading = false;
        draft.total = 0;
        draft.members = [];
        draft.error = action.error;
        break;
      case types.MEMBER_GRID_COLUMN_INFO:
        draft.error = false;
        draft.gridName = action.gridName;
        break;
      case types.MEMBER_GRID_COLUMN_INFO_SUCCESS:
        draft.loading = false;
        draft.columns = action.columns;
        break;
      case types.MEMBER_GRID_COLUMN_INFO_ERROR:
        draft.loading = false;
        break;
      case types.BAN_LIST_GRID_COLUMN_INFO:
        // draft.error = false;
        break;
      case types.BAN_LIST_GRID_COLUMN_INFO_SUCCESS:
        draft.banListColumns = action.columns;
        break;
      case types.BAN_LIST_GRID_COLUMN_INFO_ERROR:
        break;
      case types.GET_MEMBER_SHORT_DETAIL:
        draft.loadingDetail = true;
        draft.error = false;
        break;
      case types.GET_MEMBER_SHORT_DETAIL_SUCCESS:
        draft.loadingDetail = false;
        draft.memberData = action.data;
        break;
      case types.GET_MEMBER_SHORT_DETAIL_ERROR:
        draft.loadingDetail = false;
        draft.error = action.error;
        break;

      case types.GET_PRODUCTS_FOR_MEMBER:
        draft.productsForMember['loading'] = true;
        break;

      case types.GET_PRODUCTS_FOR_MEMBER_SUCCESS:
        draft.productsForMember['loading'] = false;
        draft.productsForMember['data'] = action.data.gridData;
        draft.productsForMember['pageIndex'] =
          action.data.paging.currentPageIndex;
        draft.productsForMember['pageSize'] =
          action.data.paging.currentPageSize;
        draft.productsForMember['totalItems'] = action.data.paging.totalRecord;
        break;

      case types.RESET_PRODUCT_FOR_MEMBER_STATE:
        draft.productsForMember['loading'] = false;
        draft.productsForMember['data'] = [];
        draft.productsForMember['pageIndex'] = 1;
        draft.productsForMember['pageSize'] = 20;
        draft.productsForMember['totalItems'] = 0;
        break;

      case types.GET_COLUMNS_PRODUCTS_FOR_MEMBER_SUCCESS:
        draft.productsForMember['columns'] = action.columns;
        break;

      case types.SAVE_ID_MEMBER:
        draft.productsForMember['memberId'] = action.id;
        break;

      case types.GET_PUBLIC_CONTACT_MEMBER:
        draft.loadingDetail = true;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_MEMBER_SUCCESS:
        draft.loadingDetail = false;
        draft.publicContactList = action.data;
        draft.error = null;
        break;
      case types.GET_PUBLIC_CONTACT_MEMBER_ERROR:
        draft.loadingDetail = false;
        draft.publicContactList = null;
        draft.error = action.error;
        break;

    }
  });

export default productReducer;
