import React from 'react';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import AdditionControl from '../../controls/home/Addition';

const AdditionSection = ({
  selectedItemList,
  type,
  style,
  disabledFavorite,
  isDisabled,
  disabledFilterSearch,
  searchCategoriesEffected,
  searchText,
  filterProducts,
  queryConditions,
  isFavoriteView,
}) => {
  return (
    <AdditionControl
      type={type}
      selectedItemList={selectedItemList}
      disabledFavorite={disabledFavorite}
      isDisabled={isDisabled}
      disabledFilterSearch={disabledFilterSearch}
      searchCategoriesEffected={searchCategoriesEffected}
      searchText={searchText}
      filterProducts={filterProducts}
      queryConditions={queryConditions}
      isFavoriteView={isFavoriteView}
    />
  );
};

export default AdditionSection;
