import React from 'react';
import classNames from 'classnames';

import './Fieldset.less';

const Fieldset = (props) => {
  const { children, title, className, ...otherProps } = props;

  return (
    <div className='fieldset'>
      {title && <div className='fieldset__heading'>{title}</div>}
      <div
        className={classNames({
          fieldset__container: true,
          [className]: !!className,
        })}
        {...otherProps}
      >
        {children}
      </div>
    </div>
  );
};

export const FieldsetWrapper = ({ children, className, ...rest }) => {
  return (
    <div
      className={classNames({
        fieldset: true,
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

export const FieldsetTitle = ({ title, className, ...rest }) => {
  return (
    <>
      {title && (
        <div
          className={classNames({
            fieldset__heading: true,
            [className]: !!className,
          })}
          {...rest}
        >
          {title}
        </div>
      )}
    </>
  );
};

export const FieldsetContent = ({ children, className, ...rest }) => {
  return (
    <div
      className={classNames({
        fieldset__container: true,
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Fieldset;
