import React from 'react';
import { Row, Col, Typography, Empty, Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/product';
import { FormattedMessage } from 'react-intl';
import ProductSyndicationHistoryStatus from './ProductSyndicationHistoryStatus';
import ProductSyndicationHistoryMessage from './ProductSyndicationHistoryMessage';

import moment from 'moment';
import { addUtcForIsoString } from 'utils/timezone';
import { formatFullTime } from 'utils/formatDate';

import { InfoWithLabel } from 'common/components';
import { TYPE_ERROR_MESSAGE_SYNDICATION } from 'static/Constants';

import './ProductSyndicationHistoryPanel.less';

const { Paragraph } = Typography;

const ProductSyndicationHistoryPanel = (props) => {
  const { detailData, status } = props;

  const infoProps = {
    infoColProps: {
      flex: 'auto',
    },
    labelColProps: {
      flex: '130px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  const filterNotEmptyMessage = () => {
    if (detailData?.messages && detailData?.messages.length > 0) {
      return detailData?.messages.filter(
        (item) => item.messageContent !== null
      );
    }
    return null;
  };

  const messageError = filterNotEmptyMessage();

  return (
    <Row className='syndication-history-panel'>
      {status === 'loading' ? (
        <Loading style={{ width: '100%' }} />
      ) : status === 'error' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Empty />
        </div>
      ) : (
        <Col span={24}>
          <Row>
            <Col span={18}>
              <InfoWithLabel
                info={
                  detailData?.time &&
                  formatFullTime(moment(addUtcForIsoString(detailData?.time)))
                }
                label={<FormattedMessage {...Messages.startAt} />}
                {...infoProps}
              />
              <InfoWithLabel
                info={detailData?.gtin}
                label={<FormattedMessage {...Messages.GTIN} />}
                {...infoProps}
              />
              <InfoWithLabel
                info={detailData?.fromGLN}
                label={<FormattedMessage {...Messages.sourceGLN} />}
                {...infoProps}
              />
              <InfoWithLabel
                info={detailData?.sendToGLN}
                label={<FormattedMessage {...Messages.recipientGLN} />}
                {...infoProps}
              />
              <InfoWithLabel
                info={detailData?.sendToCompany}
                label={<FormattedMessage {...Messages.targetCompany} />}
                {...infoProps}
                infoColProps={{
                  style: {
                    maxWidth: '70%',
                  },
                }}
                showTooltip
              />
              <InfoWithLabel
                info={detailData?.syncMethod}
                label={<FormattedMessage {...Messages.method} />}
                {...infoProps}
              />
              <InfoWithLabel
                info={detailData?.dataFormat}
                label={<FormattedMessage {...Messages.dataFormat} />}
                {...infoProps}
              />
              <InfoWithLabel
                info={detailData?.publicationType}
                label={<FormattedMessage {...Messages.publicationType} />}
                {...infoProps}
                labelColProps={{ style: { paddingLeft: 3 } }}
              />
            </Col>
            <Col span={6}>
              <ProductSyndicationHistoryStatus
                status={detailData?.status}
                isInCompleted={detailData?.messages?.length > 0}
              />
            </Col>
          </Row>

          {detailData?.rejectReasonMessages?.length > 0 && (
            <Paragraph className='syndication-history-panel__rejection-title'>
              <FormattedMessage {...Messages.rejectReasonMessages} />
            </Paragraph>
          )}
          {detailData?.rejectReasonMessages?.length &&
            (detailData?.rejectReasonMessages).map((rejectionReason) => {
              return (
                <Row className='syndication-history-panel__rejection-reason'>
                  <Col span={24}>
                    <Paragraph className='syndication-history-panel__rejection-reason--description'>
                      {rejectionReason}
                    </Paragraph>
                  </Col>
                </Row>
              );
            })}
          {TYPE_ERROR_MESSAGE_SYNDICATION.includes(
            detailData?.status?.toUpperCase()
          ) && (
            <div>
              {messageError && messageError?.length > 0 && (
                <Paragraph className='syndication-history-panel__error-title'>
                  <FormattedMessage {...Messages.errorMessages} />
                </Paragraph>
              )}

              {messageError &&
                messageError?.length > 0 &&
                messageError?.map((message) => {
                  return <ProductSyndicationHistoryMessage message={message} />;
                })}
            </div>
          )}
        </Col>
      )}
    </Row>
  );
};

const Loading = ({ style, ...otherProps }) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      {...otherProps}
    >
      <Spin indicator={loadingIcon} />
    </div>
  );
};

export default ProductSyndicationHistoryPanel;
