import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, message, Spin, Result } from 'antd';
import { cloneDeep } from 'lodash';

import { GeneratePreviewItem } from '../../index';

import { getGeneratedPreview } from 'services/digitalAsset';

import Messages from 'i18n/messages/assetPreview';
import { injectIntl } from 'react-intl';

import './GeneratePreviewModal.less';

const GeneratePreviewModal = (props) => {
  // props
  const {
    rootDataHook,
    selectGenerateThumbHook,
    selectedAssets,
    intl,
    loadingHook,
  } = props;
  const [
    selectedGeneratedThumb,
    setSelectedGeneratedThumb,
  ] = selectGenerateThumbHook;

  // state
  const [loading, setLoading] = loadingHook;

  // this is original data, the copy of generate thumbnail response data, use for reverting thumb
  const [orgData, setOrgData] = useState([]);

  // this is generate thumbnail response data, and the last data submit to update asset preview thumb
  // when user upload new thumb, this data will update
  const [rootData, setRootData] = rootDataHook;

  const [errorMessage, setErrorMessage] = useState(false);

  // function
  const handleToggleSelectItem = (id) => () => {
    const isSelected = selectedGeneratedThumb?.includes(id);
    // check if id is selected, unselect this id, else select this id
    const newSelectedGeneratedThumb = isSelected
      ? selectedGeneratedThumb.filter((item) => item !== id)
      : [...selectedGeneratedThumb, id];

    setSelectedGeneratedThumb(newSelectedGeneratedThumb);
  };

  const generateSelectedAssetId = () => {
    return (
      selectedAssets?.length > 0 &&
      selectedAssets.map((asset) => {
        return asset.id;
      })
    );
  };

  const setResponseData = (responseData) => {
    const responseDataId = responseData.map((data) => data.assetId);

    setRootData(cloneDeep(responseData));
    // create copy of response data to store independently
    setOrgData(cloneDeep(responseData));
    setSelectedGeneratedThumb(responseDataId);
  };

  const fetchPreview = () => {
    const selectedAssetId = generateSelectedAssetId();
    const params = {
      assetIds: selectedAssetId,
    };

    getGeneratedPreview(params)
      .then((response) => {
        const { isSuccess, data } = response;
        if (isSuccess && data?.data?.length) {
          setResponseData(data.data);
        } else {
          errorHandler();
        }
      })
      .catch(() => errorHandler())
      .finally(() => {
        setLoading(false);
      });
  };

  const errorHandler = () => {
    setErrorMessage(intl.formatMessage(Messages.generatePreviewError));
  };

  const updateRootData = (id, newImage) => {
    try {
      // find updated data in root data
      const updatedData = cloneDeep(
        rootData.find((data) => data.assetId === id)
      );

      // update image in updated data
      updatedData.data = newImage;

      // create new root data and update it
      const index = rootData.findIndex((data) => data.assetId === id);
      let newRootData = cloneDeep(rootData);

      newRootData[index] = updatedData;

      // update root data state
      setRootData(newRootData);
    } catch (e) {
      // handle error
      updateRootDataErrorHandler();
    }
  };

  const revertRootData = (id) => {
    try {
      // find revert data in orgData
      const revertData = cloneDeep(orgData.find((data) => data.assetId === id));

      // create new root data and update it
      const index = rootData.findIndex((data) => data.assetId === id);

      let newRootData = cloneDeep(rootData);

      newRootData[index] = revertData;

      // update root data state
      setRootData(newRootData);
    } catch (error) {
      updateRootDataErrorHandler(true);
    }
  };

  const updateRootDataErrorHandler = (isRevert) => {
    const msg = isRevert
      ? Messages.generatePreviewRevertThumbError
      : Messages.generatePreviewUpdateThumbError;
    message.error(intl.formatMessage(msg));
  };

  const checkPreviewItemRevertable = (id) => {
    // get image from root data
    const rootItem = rootData.find((previewItem) => {
      return previewItem.assetId === id;
    });
    // get image from original data
    const originItem = orgData.find((previewItem) => {
      return previewItem.assetId === id;
    });

    return rootItem && originItem && rootItem.data !== originItem.data;
  };

  const renderGenerateThumbnailList = () => {
    // generate render list from root data
    const renderList =
      !!rootData.length &&
      rootData.map((thumbItem) => {
        // find asset detail from selected asset items
        const assetDetail = selectedAssets.find((assetDetailItem) => {
          return assetDetailItem.id === thumbItem.assetId;
        });

        return {
          ...thumbItem,
          assetName: assetDetail?.assetName,
        };
      });

    // render
    return (
      renderList &&
      renderList.map((renderItem, index) => {
        const { assetId } = renderItem;
        const isSelected = selectedGeneratedThumb.includes(assetId);
        const revertable = checkPreviewItemRevertable(assetId);

        return (
          <Col flex={0} className='generate-preview__item' key={index}>
            <GeneratePreviewItem
              key={assetId}
              item={renderItem}
              selected={isSelected}
              handleToggleSelectItem={handleToggleSelectItem}
              updateRootData={updateRootData}
              revertRootData={revertRootData}
              revertable={revertable}
            />
          </Col>
        );
      })
    );
  };

  const resetData = () => {
    setOrgData([]);
    setRootData([]);
    setErrorMessage(false);
  };

  // did mount
  useEffect(() => {
    fetchPreview();

    // component unmount
    return () => {
      resetData();
    };
    // eslint-disable-next-line
  }, []);

  const loadingTip = intl.formatMessage(Messages.generateLoading);

  return (
    <Spin spinning={loading} tip={loadingTip}>
      <Row className='generate-preview__list'>
        {!loading && !!errorMessage ? (
          <Result
            className='generate-preview__error'
            status='error'
            title={errorMessage}
          ></Result>
        ) : (
          renderGenerateThumbnailList()
        )}
      </Row>
    </Spin>
  );
};

GeneratePreviewModal.propTypes = {
  // hook to get update root data and send it back to generate thumbnail modal
  rootDataHook: PropTypes.array,
  // hook to get selected item and send it back to generate thumbnail modal
  selectGenerateThumbHook: PropTypes.array,
  // show loading hook
  loadingHook: PropTypes.array,
  // current selected asset items
  selectedAssets: PropTypes.array,
};

GeneratePreviewModal.defaultProps = {
  handleSetSelectedItem: () => {},
  selectedAssets: [],
};

export default injectIntl(GeneratePreviewModal);
