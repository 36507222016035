import React from 'react';

import { Menu, Dropdown } from 'antd';
import { SubnodeOutlined } from '@ant-design/icons';

import { RibbonButton, DownloadFormItem } from 'common/components';
import EditMember from '../../controls/member-full/EditMember';

import HistoryEntity from 'pages/home/ribbon/form-manange/components/form-history-grid/HistoryEntity';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { SECURE_CONFIG } from 'utils/SecureRoute';

import { useCheckPermissionAnd } from 'hooks/useCheckPermissions';

import messages from 'i18n/messages/home';

const MemberFullAction = (props) => {
  const { companyInfo } = props;

  const isValidPermissionAND = useCheckPermissionAnd();

  const allowToEditCompanyMember = isValidPermissionAND([
    SECURE_CONFIG.MANAGE_MEMBER.PASS,
    SECURE_CONFIG.EDIT_MEMBER.PASS,
  ]);

  const menuOptions = (
    <Menu>
      <Menu.Item key='go-to-edit' style={{ backgroundColor: 'inherit' }}>
        <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.MEMBER}>
          <EditMember
            size='small'
            companyInfo={companyInfo}
            disabled={!allowToEditCompanyMember}
          />
        </Can>
      </Menu.Item>

      <Menu.Item
        key='download-splash-form'
        style={{ backgroundColor: 'inherit' }}
      >
        <DownloadFormItem
          entityType='MEM'
          selectedEntityIds={[companyInfo?.id]}
        />
      </Menu.Item>
      <Menu.Item key='history-entity' style={{ backgroundColor: 'inherit' }}>
        <HistoryEntity
          size='small'
          entityId={companyInfo?.id}
          entityType='MEM'
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
      <RibbonButton icon={<SubnodeOutlined />} label={messages.memActions} />
    </Dropdown>
  );
};

export default MemberFullAction;
