import { useState, useEffect, useMemo, useRef } from 'react';

import { Typography } from 'antd';
import { CustomNotification } from 'common/components';

import { useQaSpecGrid } from './useQaSpecGrid';

import { getAllRows } from 'pages/qa-spec/utils';
import { apiHandler } from 'utils/api';

import * as qaSpecServices from 'services/qaSpec';

import { useGetOrganolepticQuery } from 'pages/qa-spec/query';

import useCheckAssignProductToSupplier from 'pages/qa-spec/hooks/useCheckAssignProductToSupplier';
import useCheckSnapshotForRetailer from './useCheckSnapshotForRetailer';

import { validateOrganolepticCharacteristicGrid } from 'pages/qa-spec/components/qa-prod-spec/utils';
import { sleep } from 'utils/delay';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/qa-spec';

const textEditorRenderer =
  (maxLength = 150) =>
  () => {
    return {
      component: 'TextEditor',
      popup: true,
      params: {
        maxLength: maxLength,
      },
    };
  };

export const useOrganoleptic = ({
  productId,
  isGridEditing,
  requiredFields,
  isSectionRequired,
}) => {
  const intl = useIntl();
  const gridInst = useRef();

  const [saveLoading, setSaveLoading] = useState(false);

  const { checkAssignProductToSupplier } = useCheckAssignProductToSupplier();
  const isProductAssignedToCurrentUserAsSupplier =
    checkAssignProductToSupplier();

  const initRowData = (gridData) => {
    const defaultData = [
      {
        parameter: 'Appearance',
      },
      {
        parameter: 'Color',
      },
      {
        parameter: 'Aroma/Odor',
      },
      {
        parameter: 'Flavor/Taste',
      },
      {
        parameter: 'Texture',
      },
    ];

    let procData = [...defaultData];

    if (gridData?.length) {
      procData = gridData.map((dataItem, index) => ({
        ...dataItem,
        ...defaultData?.[index],
      }));
    }

    return procData;
  };

  const resetGridCallback = () => {
    if (isProductAssignedToCurrentUserAsSupplier) {
      validateOrganolepticCharacteristicGrid({
        grid: gridInst,
        requiredFields,
      }).validateAllRows();
    }
  };

  const { organolepticData, handleRefetchOrganoleptic } =
    useGetOrganolepticQuery({
      productId,
      enabled: !!productId,
    });

  const { fetchDataLoading, rowData, resetGridData } = useQaSpecGrid({
    gridInst,
    gridData: organolepticData?.organolepticCharacteristics,
    isGridEditing,
    customInitRowData: initRowData,
    resetGridCallback,
  });

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const columnDefs = useMemo(() => {
    const statusField = {
      minWidth: 50,
      maxWidth: 50,
      field: 'error',
      displayName: ' ',
      cellRenderer: 'StatusRender',
      type: 'nonEditableColumn',
    };

    let cols = [
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Parameter',
        field: 'parameter',
        editable: false,
        width: 200,
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Preparation Method',
        field: 'preparationMethod',
        cellEditorSelector: textEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
        // cellRenderer: (props) => {
        //   console.log('columnDefs  props:', props);
        //   return <div>ABC</div>;
        // },
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Specification/Description/Target',
        field: 'specification',
        cellEditorSelector: textEditorRenderer(5000),
        minWidth: 300,
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Tolerance',
        field: 'tolerance',
        cellEditorSelector: textEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Reject/Fail Criteria',
        field: 'rejectOrFailCriteria',
        width: 180,
        flex: null,
        cellEditorSelector: textEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Frequency',
        field: 'frequency',
        cellEditorSelector: textEditorRenderer(),
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
      {
        allowFilter: true,
        allowSort: true,
        dataType: 'string',
        headerName: 'Release Criteria',
        field: 'releaseCriteria',
        cellEditorSelector: (params) => {
          return {
            component: 'SelectionEditor',
            params: {
              values: [
                { value: 'Yes', displayName: 'Yes' },
                { value: 'No', displayName: 'No' },
                { value: 'Not Applicable', displayName: 'Not Applicable' },
              ],
              mapValue: (value) => {
                if (value === 'null' || value === undefined) {
                  return null;
                } else {
                  return value;
                }
              },
              popup: true,
            },
          };
        },
        cellRenderer: isToggleSnapshot ? 'TextRenderSnapshot' : 'TextRender',
      },
    ];

    cols = isProductAssignedToCurrentUserAsSupplier
      ? [statusField, ...cols]
      : cols;

    return cols.map((fieldItem) => {
      const DEFAULT_WIDTH = 150;

      let initColSetting = {
        type: 'editableColumn',
      };

      return {
        minWidth: DEFAULT_WIDTH,
        ...initColSetting,
        ...fieldItem,
      };
    });
  }, [isProductAssignedToCurrentUserAsSupplier, isToggleSnapshot]);

  const saveData = async (toggleEditMode, analysisNotExecuted) => {
    gridInst.current.api.stopEditing();

    const GRID_VALIDATION_DELAY = 700;
    await sleep(GRID_VALIDATION_DELAY);

    const isGridHaveError = validateOrganolepticCharacteristicGrid({
      grid: gridInst,
      requiredFields,
    }).checkIfGridHaveError();

    if (isGridHaveError) {
      CustomNotification.error(
        `Please resolve the Organoleptic Characteristics grid's errors.`
      );
      return;
    }

    setSaveLoading(true);

    const allRowData = getAllRows(gridInst);

    const params = {
      productId,
      organolepticCharacteristics: allRowData,
      analysisNotExecuted: analysisNotExecuted,
    };

    const successMessage = intl.formatMessage(
      Messages.productSpecSaveOrganolepticSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.productSpecSaveOrganolepticError
    );

    apiHandler({
      service: qaSpecServices.saveQaSpecOrganolepticCharacteristic,
      params,
      successMessage,
      errorMessage,
      successCallback: saveSuccessCallback(toggleEditMode),
      onFinally: saveFinally,
    });
  };

  const saveSuccessCallback = (toggleEditMode) => () => {
    toggleEditMode();

    handleRefetchOrganoleptic();
  };

  const saveFinally = () => {
    setSaveLoading(false);
  };

  const cancelEdit = () => {
    resetGridData();
  };

  const onCellEditingStopped = async (event) => {
    if (isProductAssignedToCurrentUserAsSupplier) {
      await validateOrganolepticCharacteristicGrid({
        grid: gridInst,
        requiredFields,
      }).checkAllRequiredFieldsByParameterType({ event });
    }
  };

  useEffect(() => {
    const handleValidateAllRowDataForSupplier = async () => {
      if (
        rowData &&
        rowData?.length > 0 &&
        isProductAssignedToCurrentUserAsSupplier
      ) {
        await validateOrganolepticCharacteristicGrid({
          grid: gridInst,
          requiredFields,
        }).validateAllRows();
      }
    };

    handleValidateAllRowDataForSupplier();
  }, [
    isProductAssignedToCurrentUserAsSupplier,
    JSON.stringify(rowData),
    JSON.stringify(requiredFields),
  ]);

  const gridTitle = (
    <>
      {isProductAssignedToCurrentUserAsSupplier && isSectionRequired && (
        <>
          <Typography.Text type='danger'>*</Typography.Text>{' '}
        </>
      )}
      Organoleptic Characteristics
    </>
  );

  return {
    gridInst,
    analysisNotExecuted: organolepticData?.analysisNotExecuted,
    rowData,
    columnDefs,
    saveData,
    cancelEdit,
    loading: fetchDataLoading || saveLoading,
    onCellEditingStopped,
    isProductAssignedToCurrentUserAsSupplier,
    gridTitle,
  };
};
