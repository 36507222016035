import React, { useEffect } from 'react';

import PreviewGalleryModal from 'pages/product-full-view/components/preview-modal/PreviewGalleryModal';

import { useProductGallery } from 'hooks/useProductGallery';

const UpdateMinimumRequirementProductGalleryModal = (props) => {
  const { visible, setVisible, type, entity = {}, productDetail } = props;
  const { id, gtin, upc12, rivirId, productName } = entity;

  const {
    additionalImages,
    planogramImages,
    marketingAssets,
    getImagery,
    unlinkAssetLoading,
    handleUnlinkAsset,
  } = useProductGallery({
    productId: id,
    type,
  });

  const handleCloseModal = () => {
    setVisible(false);
  };

  const getModalTitle = () => {
    const productCode = gtin ? gtin : upc12 ? upc12 : rivirId ? rivirId : null;

    return `${productCode ? productCode + ' - ' : ''}${productName}`;
  };

  useEffect(() => {
    visible && getImagery();
  }, [visible]);

  const modalTitle = getModalTitle();

  const imageCollection = {
    Planogram: planogramImages,
    Additional: additionalImages,
    Marketing: marketingAssets,
  };

  const imageList = imageCollection[type] || [];

  return (
    <PreviewGalleryModal
      imageList={imageList}
      visible={visible}
      titleModal={modalTitle}
      title={type}
      onCloseModal={handleCloseModal}
      getImagery={getImagery}
      handleUnlinkAsset={handleUnlinkAsset}
      unlinkAssetLoading={unlinkAssetLoading}
      productInfo={productDetail}
    />
  );
};

export default UpdateMinimumRequirementProductGalleryModal;
