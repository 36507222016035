import { createContext, useContext, useMemo, useState } from 'react';

export const GroupLayoutContext = createContext({
  factsPanels: [],
  allergens: [],
  ingredients: [],
});

export const GroupLayoutProvider = ({
  modeFactsPanel = 'multiple',
  modeAllergens = 'multiple',
  modeIngredients = 'multiple',
  children,
  ...rest
}) => {
  const [groupLayoutState, setGroupLayoutState] = useState({
    factsPanels: [],
    allergens: [],
    ingredients: [],
  });

  const onUpdateSingleGroup = (partGroup, { checked, value }) => {
    setGroupLayoutState((prevState) => ({
      ...prevState,
      [partGroup]: checked ? [value] : [],
    }));
  };

  const onUpdateMultipleGroup = (partGroup, { checked, value }) => {
    const appendedValues = groupLayoutState[partGroup].concat(value);
    const filteredValues = groupLayoutState[partGroup].filter(
      (prevVal) => prevVal !== value
    );

    setGroupLayoutState((prevState) => ({
      ...prevState,
      [partGroup]: checked ? appendedValues : filteredValues,
    }));
  };

  const onSelectFactsPanels = ({ target }) => {
    if (modeFactsPanel === 'single') {
      onUpdateSingleGroup('factsPanels', target);
    } else {
      onUpdateMultipleGroup('factsPanels', target);
    }
  };

  const onSelectAllergens = ({ target }) => {
    if (modeAllergens === 'single') {
      onUpdateSingleGroup('allergens', target);
    } else {
      onUpdateMultipleGroup('allergens', target);
    }
  };

  const onSelectIngredients = ({ target }) => {
    if (modeIngredients === 'single') {
      onUpdateSingleGroup('ingredients', target);
    } else {
      onUpdateMultipleGroup('ingredients', target);
    }
  };

  // memo it if needed
  const value = {
    onSelectFactsPanels,
    onSelectAllergens,
    onSelectIngredients,
    onSetGroupLayoutState: setGroupLayoutState,
    factsPanels: groupLayoutState.factsPanels,
    allergens: groupLayoutState.allergens,
    ingredients: groupLayoutState.ingredients,
  };

  return (
    <GroupLayoutContext.Provider
      value={useMemo(
        () => value,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [Object.keys(value)]
      )}
      {...rest}
    >
      {children}
    </GroupLayoutContext.Provider>
  );
};

export const useGetGroupLayoutContext = () => {
  const context = useContext(GroupLayoutContext);

  if (context === undefined) {
    throw new Error(
      'useGetGroupLayoutContext must be used within a GroupLayoutProvider'
    );
  }
  return context;
};
