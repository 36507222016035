import { getTargetNodesById } from 'common/components/product-category-tree/utils';
import {
  CATEGORY_LEVEL_4,
  CATEGORY_LEVEL_1,
  CATEGORY_LEVEL_2,
  CATEGORY_LEVEL_3,
} from './constant';

export const isNotSupportMaximumLevel = (mode, nodeSelected) => {
  return mode === 'add' && nodeSelected?.categoryLevel === CATEGORY_LEVEL_4;
};

export const allowResetModeWhenSelect = (info, mode) => {
  if (isNotSupportMaximumLevel(mode, info)) {
    return true;
  }

  if (!info && mode === 'edit') {
    return true;
  }

  return false;
};

export const findCategoryNodeById = (id, tree) => {
  let categoryItem = null;

  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === id) {
      categoryItem = tree[i];
      break;
    } else if (tree[i]?.children?.length > 0) {
      categoryItem = findCategoryNodeById(id, tree[i]?.children);
    }

    if (categoryItem) {
      return categoryItem;
    }
  }
  return categoryItem;
};

export const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children.length) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

export const getParentNode = (key, tree) => {
  let parentNode;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children.length) {
      if (node.children.some((item) => item.key === key)) {
        parentNode = node;
      } else if (getParentKey(key, node.children)) {
        parentNode = getParentNode(key, node.children);
      }
    }
  }
  return parentNode;
};

export const getKeyExpanded = (treeData) => {
  const keyExpanded = [];
  const traverseTree = (tree) => {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (!node.isLeaf) {
        keyExpanded.push(node.key);
      }
      if (node.children) {
        traverseTree(node.children);
      }
    }
  };
  traverseTree(treeData);
  return keyExpanded;
};

export const getListKeySearch = (keySearch, searchText, tree) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].key.toLowerCase().includes(searchText.toLowerCase())) {
      keySearch.push(tree[i].key);
    }
    if (tree[i].children) {
      getListKeySearch(keySearch, searchText, tree[i].children);
    }
  }
};

const convertParentPath = (parent, current) => {
  if (parent === null) return current.toString();
  return `${parent}-${current}`;
};

export const mapDataToTree = (data, parent_path_id, parent_path_name) => {
  if (!data.children.length) {
    return {
      ...data,
      title: data.categoryName,
      titleValue: data.categoryName,
      namePath: convertParentPath(parent_path_name, data.categoryName),
      key: convertParentPath(parent_path_id, data.id),
      categoryLevel: convertParentPath(parent_path_id, data.id)
        .toString()
        .split('-').length,
      isLeaf: !data.children.length,
      children: data.children,
    };
  }
  if (Array.isArray(data.children)) {
    const key = convertParentPath(parent_path_id, data.id);
    const namePath = convertParentPath(parent_path_name, data.categoryName);
    const children = data.children.map((d) => mapDataToTree(d, key, namePath));
    return {
      ...data,
      title: data.categoryName,
      titleValue: data.categoryName,
      key,
      namePath,
      categoryLevel: key.toString().split('-').length,
      isLeaf: false,
      children,
    };
  }
};

export const generateIdsBySelectedKey = (key) => {
  if (key) return key.split('-').map((item) => Number(item));
  return [];
};

export const getCategoryObjectWhenSelectNode = (nestedCategory, nodeFound) => {
  const { key, categoryLevel } = nodeFound || {};
  const idList = generateIdsBySelectedKey(key);

  let nodeList = getTargetNodesById(nestedCategory, idList);

  const fieldsValues = {
    category1: nodeList?.[0]?.categoryName,
    category2: categoryLevel >= 2 ? nodeList?.[1].categoryName : undefined,
    category3: categoryLevel >= 3 ? nodeList?.[2].categoryName : undefined,
    category4: categoryLevel === 4 ? nodeList?.[3].categoryName : undefined,
  };
  return fieldsValues;
};

const checkFormValueSelectedNodeEqualFormUserInputWhenEdit = (
  formUserInput,
  nestedCategory,
  nodeFound
) => {
  const fieldValuesOfNodeSelected = getCategoryObjectWhenSelectNode(
    nestedCategory,
    nodeFound
  );

  return (
    JSON.stringify(formUserInput) === JSON.stringify(fieldValuesOfNodeSelected)
  );
};

export const getCategoryLevelByKey = (categoryFormInput, categoryKey) => {
  const categoryFormItem = categoryFormInput.find(
    (item) => item.name === categoryKey
  );
  return categoryFormItem?.level;
};

export const mapObjectFormValueToArrayFormValue = (
  categoryFormInput,
  valueForm
) => {
  return Object.entries(valueForm)
    .filter(([_, value]) => {
      return value?.trim();
    })
    .map(([key, value]) => {
      const level = getCategoryLevelByKey(categoryFormInput, key.toString());

      return { categoryName: value.trim(), level };
    });
};

export const getCategoryItemByLevel = (categoryFormInput, valueForm, level) => {
  const arrValueMappingForm = mapObjectFormValueToArrayFormValue(
    categoryFormInput,
    valueForm
  );
  return arrValueMappingForm.find((item) => item.level === level);
};

export const checkExistNamePathInCategory = (path, tree) => {
  if (!path) return null;

  let categoryItem = null;

  for (let i = 0; i < tree.length; i++) {
    if (tree[i].namePath === path) {
      return true;
    } else if (tree[i]?.children?.length > 0) {
      categoryItem = checkExistNamePathInCategory(path, tree[i]?.children);
    }

    if (categoryItem) return true;
  }
  return categoryItem;
};

export const checkIsMissingCategoryParent = (formValue) => {
  const formValueList = Object.values(formValue);

  for (let i = formValueList.length - 1; i >= 1; i--) {
    if (formValueList[i] && !formValueList[i - 1]) {
      return true;
    }
  }
  return false;
};

const checkNameAlreadyExistInSignleTree = (nodeSelected, valueEdited) => {
  for (let i = 0; i < nodeSelected.length; i++) {
    if (nodeSelected[i].categoryName === valueEdited) {
      return true;
    }

    if (nodeSelected[i].children.length) {
      const isExistName = checkNameAlreadyExistInSignleTree(
        nodeSelected[i].children,
        valueEdited
      );

      if (isExistName) return isExistName;
    }
  }
};

export const checkIsDuplicateValue = (nodeSelected, formValue) => {
  const formValueList = Object.values(formValue).filter((item) => item);
  const isDuplicateValueForm =
    new Set(formValueList).size !== formValueList.length;

  const valueEdited = formValueList[formValueList.length - 1]?.trim();

  if (!nodeSelected) {
    return isDuplicateValueForm;
  }

  const isNameAlreadyExist = checkNameAlreadyExistInSignleTree(
    [nodeSelected],
    valueEdited
  );

  return isDuplicateValueForm || isNameAlreadyExist;
};

export const autoFocusInput = (mode, nodeSelected, getMap) => {
  const isNotSupportLevel = isNotSupportMaximumLevel(mode, nodeSelected);
  const isCancelAutoFocus =
    isNotSupportLevel || !mode || (!nodeSelected && mode === 'edit');

  if (isCancelAutoFocus) {
    return;
  }

  const map = getMap();

  if (!nodeSelected) {
    map.get(CATEGORY_LEVEL_1).focus();
    return;
  }

  const level =
    mode === 'edit'
      ? nodeSelected.categoryLevel
      : nodeSelected.categoryLevel + 1;

  const node = map.get(level);

  node.focus();
};

export const checkIsShowMessageInValidCategory = (
  pathName,
  nestedCategory,
  valueForm,
  nodeSelected,
  categoryValue,
  mode
) => {
  const isExistCategory = checkExistNamePathInCategory(
    pathName,
    nestedCategory
  );
  const isValueOfNodeSelectedEqualFormUserInput = checkFormValueSelectedNodeEqualFormUserInputWhenEdit(
    valueForm,
    nestedCategory,
    nodeSelected
  );

  if (isValueOfNodeSelectedEqualFormUserInput) return false;

  const isMissingParentCategory = checkIsMissingCategoryParent(valueForm);
  const isDuplicateValue = checkIsDuplicateValue(nodeSelected, valueForm);
  if (isMissingParentCategory) {
    return 'missingParentCategoryLevelMessage';
  }
  if (mode === 'edit' && !categoryValue) {
    return 'valueBlankMessage';
  }
  if (isExistCategory) {
    return 'existCategoryNameMessage';
  }
  if (isDuplicateValue) {
    return 'duplicateCategoryMessage';
  }

  return false;
};

export const getPathByFormValue = (formValue) => {
  if (!formValue) return;
  return Object.values(formValue)
    .filter((item) => item)
    .join('-');
};

export const findCategoryNodeByPathName = (path, tree) => {
  if (!path) return null;

  let categoryItem = null;

  for (let i = 0; i < tree.length; i++) {
    if (tree[i].namePath === path) {
      categoryItem = tree[i];
      break;
    } else if (tree[i]?.children?.length > 0) {
      categoryItem = findCategoryNodeByPathName(path, tree[i]?.children);
    }

    if (categoryItem) {
      return categoryItem;
    }
  }
  return categoryItem;
};

export const generateCategoryIdForm = (idList) => {
  if (!idList) return null;
  let params = {};

  for (let i = 0; i < idList.length; i++) {
    params[`category${i + 1}Id`] = Number(idList[i]);
  }
  return params;
};

export const generateCategoryIdByKey = (key) => {
  return key.split('-').map((item) => Number(item));
};

export const getCategoryPath = (node) => {
  if (node.children.length === 0) {
    return node.categoryName;
  }
  const childCategoryPath = getCategoryPath(node.children[0]);
  return `${node.categoryName}-${childCategoryPath}`;
};

export const checkEmptyChildCategoryInAddMode = (formValue) => {
  const formValueList = Object.values(formValue);

  let levelError = [];
  let maxLevelHasValue = null;
  for (let i = formValueList.length - 1; i >= 0; i--) {
    if (formValueList[i] && !formValueList[i - 1]) {
      maxLevelHasValue = i;
      break;
    }
  }
  if (!maxLevelHasValue) return null;
  for (let k = maxLevelHasValue; k >= 0; k--) {
    if (!formValueList[k]) {
      const level = k + 1;
      levelError.push(level);
    }
  }
  return levelError;
};

export const resetFieldError = (form) => {
  const fieldEmptyErrorMapping = [
    CATEGORY_LEVEL_1,
    CATEGORY_LEVEL_2,
    CATEGORY_LEVEL_3,
    CATEGORY_LEVEL_4,
  ].map((item) => ({
    name: `category${item}`,
    errors: [],
  }));
  form.setFields(fieldEmptyErrorMapping);
};
