import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { LayoutOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import RibbonButton from 'common/components/button/RibbonButton';
import * as selectorsGlobal from 'redux/global/selectors';

import './DetailView.less';

const DetailControl = ({ detailClick, disabledBtn, isDetailShow }) => {
  const isDisplayShowPreviewAcceptedEula = useSelector(
    selectorsGlobal.selectIsDisplayShowPreviewAcceptedEula()
  );

  const handleClickDetail = () => {
    detailClick();
  };

  return (
    <RibbonButton
      icon={<LayoutOutlined />}
      label={Messages.toggleDetail}
      onClick={handleClickDetail}
      toggle={isDetailShow}
      disabled={disabledBtn || isDisplayShowPreviewAcceptedEula}
    />
  );
};

DetailControl.propTypes = {
  detailClick: PropTypes.func,
};

export default DetailControl;
