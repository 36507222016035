import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import * as _ from 'lodash';
import * as chatServices from 'services/chatServices';
import * as globalSelectors from 'redux/global/selectors';
import { formatMessage } from 'utils/string';

import './NewMsgReceive.less';

const NewMsgReceive = (props) => {
  const { threadId } = props;
  const [senderInfo, setSenderInfo] = useState({});
  const [hasNewMsg, setHasNewMsg] = useState(false);
  const newMsgInfo = useSelector(globalSelectors.selectNewMsgInfo());
  useEffect(() => {
    if (!_.isEmpty(newMsgInfo)) {
      setHasNewMsg(true);
      setTimeout(() => {
        setHasNewMsg(false);
      }, 2000);
    }
  }, [JSON.stringify(newMsgInfo)]);

  useEffect(() => {
    if (!_.isEmpty(newMsgInfo) && threadId === newMsgInfo?.threadId) {
      newMsgInfo.senderId && onGetSenderInfo(newMsgInfo?.senderId);
    }
  }, [newMsgInfo?.senderId]);

  const onGetSenderInfo = useCallback(
    (senderId) => {
      chatServices
        .getSenderInfo({ senderId: senderId })
        .then((res) => {
          if (res.isSuccess) {
            setSenderInfo(res.data);
          } else {
            setSenderInfo({});
          }
        })
        .catch((err) => console.log('err: ', err));
    },
    [newMsgInfo?.senderId]
  );

  return (
    <div
      className={classnames({
        'new-msg-receive': true,
        'new-msg-receive--show':
          hasNewMsg &&
          threadId &&
          threadId === newMsgInfo?.threadId &&
          !_.isEmpty(senderInfo),
      })}
    >
      <div className='new-msg-receive__name'>
        {senderInfo?.name || 'Sender'}
      </div>
      <div className='new-msg-receive__text'>
        {newMsgInfo?.messageText &&
          formatMessage(newMsgInfo?.messageText).replace(/<[^>]*>?/gm, '')}
      </div>
    </div>
  );
};

NewMsgReceive.propTypes = {};

export default NewMsgReceive;
