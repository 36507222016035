import React from 'react';
import { useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { SubnodeOutlined } from '@ant-design/icons';

import messages from 'i18n/messages/home';

import { RibbonButton } from 'common/components';
import DownloadAplTemplate from '../../controls/product-apl/DownloadAplTemplate';
import CreateApl from '../../controls/product-apl/CreateApl';
import DeleteAplControl from '../../controls/product-apl/DeleteAplControl';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';

const AplGridActions = () => {
  const selectedProducts = useSelector(
    gridViewSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const menuOptions = (
    <Menu>
      <Menu.Item key='pim-add-product' style={{ backgroundColor: 'inherit' }}>
        <DownloadAplTemplate />
      </Menu.Item>
      <Menu.Item key='create-apl' style={{ backgroundColor: 'inherit' }}>
        <CreateApl />
      </Menu.Item>
      <Menu.Item
        key='delete-product-apl-grid'
        style={{ backgroundColor: 'inherit' }}
      >
        <DeleteAplControl selectedItems={selectedProducts} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions} placement='bottomLeft' arrow>
      <RibbonButton icon={<SubnodeOutlined />} label={messages.aplActions} />
    </Dropdown>
  );
};

export default AplGridActions;
