import React, { useState, useCallback } from 'react';

export const NutritionContext = React.createContext({
  iaAddingNew: false,
  isUpdating: false,
});

export const NutritionProvider = (props) => {
  const { children } = props;

  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const clearEditNutrientState = useCallback(() => {
    setIsAddingNew(false);
    setIsUpdating(false);
  }, []);

  return (
    <NutritionContext.Provider
      value={{
        isAddingNew,
        setIsAddingNew,
        isUpdating,
        setIsUpdating,
        clearEditNutrientState,
      }}
    >
      {children && children}
    </NutritionContext.Provider>
  );
};
