import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, List } from 'antd';
import {
  CodeSandboxOutlined,
  PictureOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import './AssetItemCardTop.less';

const { Text } = Typography;

const AssetItemCardTop = (props) => {
  const {
    upc12,
    brand,
    name,
    packageLevels,
    snl,
    assetImgUrl,
    publishImgUrl,
  } = props;

  return (
    <Row className='item-card__top-detail'>
      <Col xs={24}>
        <Row>
          <Col xs={6}>
            <img className='item-card__logo' alt='product' src={assetImgUrl} />
          </Col>
          <Col xs={13} flex='column'>
            <Row>
              <Col xs={24}>
                <Text>{upc12}</Text>
              </Col>
              <Col xs={24}>
                <Text> {brand}</Text>
              </Col>
              <Col xs={24}>
                <Text> {name}</Text>
              </Col>
              <Col xs={24}>
                <Text>
                  <List
                    dataSource={packageLevels}
                    renderItem={(packageItem, idx) => (
                      <React.Fragment>
                        <span>{packageItem}</span>
                        {idx !== packageLevels.length - 1 && <span>/</span>}
                      </React.Fragment>
                    )}
                  />
                </Text>
              </Col>
            </Row>
          </Col>
          <Col xs={5}>
            <Row>
              <Col xs={24} className='item-card__certified-logo'>
                <img alt='publish' src={publishImgUrl} />
              </Col>
              <Col xs={24} className='item-card__certified-serial'>
                <Text>{snl}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} className='item-card__action'>
            <CodeSandboxOutlined className='item-card__action-icon' />
            <PictureOutlined className='item-card__action-icon' />
            <RedoOutlined className='item-card__action-icon' />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

AssetItemCardTop.propTypes = {
  // ? upc12
  upc12: PropTypes.string,
  // ? brand
  brand: PropTypes.string,
  // ? name
  name: PropTypes.string,
  // ? packageLevels
  packageLevels: PropTypes.arrayOf(PropTypes.string),
  // ? snl
  snl: PropTypes.string,
  // ? assetImgUrl
  assetImgUrl: PropTypes.string,
  //? publish
  publishImgUrl: PropTypes.string,
};

export default React.memo(AssetItemCardTop);
