import React, { useState } from 'react';
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Input, Row, Col } from 'antd';

import classnames from 'classnames';

import './ChatSearchInput.less';

const ChatSearchInput = (props) => {
  const { className, value, onChange, onClear, maximizeChatView } = props;

  const handleChange = (event) => {
    onChange && onChange(event);
  };

  const handleClickClear = (event) => {
    onClear && onClear(event);
  };

  return (
    <Row>
      <Col xs={24}>
        <div
          className={classnames({
            className: true,
            'chat-search-input__wrapper': !maximizeChatView,
            'chat-search-input__wrapper_max': maximizeChatView,
          })}
        >
          {value !== '' && (
            <ArrowLeftOutlined
              className='ani--easy-in ani--iter-1 ani--time-70 ani--fill-forward ani--slideIn'
              style={{ paddingRight: 6 }}
              onClick={(e) => handleClickClear(e)}
            />
          )}
          <div className='chat-search-input'>
            {value === '' && (
              <SearchOutlined className='chat-search-input__icon-search' />
            )}
            <Input value={value} onChange={handleChange} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ChatSearchInput;
