export const ANON_GET_SENDER_INFO =
  '/api/external-meeting-chat/get-sender-info';

export const ANON_GET_CHAT_USER_INFO =
  '/api/external-meeting-chat/get-chat-users-info';

export const ANON_GET_THREAD_INFO =
  '/api/external-meeting-chat/get-thread-info';

export const ANON_GET_THREAD_MESSAGE =
  '/api/external-meeting-chat/get-thread-messages';

export const ANON_UPLOAD_CHAT_FILE =
  '/api/external-meeting-chat/upload-chat-file';

export const ANON_DOWNLOAD_CHAT_FILE =
  '/api/external-meeting-chat/download-chat-file';

export const ANON_GET_CHAT_FILE_INFO =
  '/api/external-meeting-chat/get-chat-file-info';

export const ANON_MAKE_A_THREAD_AS_READ =
  '/api/external-meeting-chat/make-a-thread-as-read';

export const ANON_MAKE_A_MESSAGE_AS_READ =
  '/api/external-meeting-chat/make-a-message-as-read';
