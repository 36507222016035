import { useDispatch, useSelector } from 'react-redux';

import {
  qaToggleReloadDocumentCertificateTab,
  qaToggleReloadDocumentWhenDelete,
} from 'pages/qa-spec/controllers/actions';

import * as qaSelectors from 'pages/qa-spec/controllers/selectors';

export const useReloadDocumentCertificateTab = () => {
  const dispatch = useDispatch();

  const reloadDocumentWhenDelete = useSelector(
    qaSelectors.selectReloadDocumentWhenDelete()
  );

  const toggleReloadDocumentWhenDelete = () => {
    dispatch(qaToggleReloadDocumentWhenDelete());
  };

  const reloadDocumentCertificateTab = () => {
    dispatch(qaToggleReloadDocumentCertificateTab());
  };

  return {
    reloadDocumentWhenDelete,
    reloadDocumentCertificateTab,
    toggleReloadDocumentWhenDelete,
  };
};
