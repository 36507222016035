import * as actions from './constants';

export function selectedGroup(payload) {
  return {
    type: actions.SELECTED_GROUP,
    payload,
  };
}

export function updateMemberId(payload) {
  return {
    type: actions.UPDATE_MEMBER_ID,
    payload,
  };
}
export function updatTicketGroupList(payload) {
  return {
    type: actions.UPDATE_TICKET_GROUP_LIST,
    payload,
  };
}
