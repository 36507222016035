import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Select, Typography, Popover } from 'antd';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import * as _ from 'lodash';

//* COMPONENTS
import { locationTypeIcon } from 'common/components/icon-renderer/IconRendererMapping';
import {
  Form,
  CustomNotification,
  StyledModal,
  WrapperSelect,
  FlagCountrySelectionFieldInput,
} from 'common/components';

//* SERVICES
import * as memberServices from 'services/members';
import * as companyActions from 'pages/company-profile/controllers/actions';
import Messages from 'i18n/messages/message-my-company';

//* UTILS
import { searchCountryCode } from 'utils/country';
import CountryCode from 'static/CountryCode';

import './CompanyLocationForm.less';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

const CompanyLocationForm = (props) => {
  const {
    isOpen,
    isAddNew,
    setIsOpen,
    itemData,
    intl,
    gridApi,
    memberId,
    type,
    onSubmit,
  } = props;

  const [formInstance] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const refCountry = useRef(null);

  const handleSubmitForm = async () => {
    try {
      const formValue = await formInstance.validateFields();

      if (onSubmit) {
        return onSubmit({ formValue, setIsLoading });
      }

      setIsLoading(true);

      const response = isAddNew
        ? await memberServices.addMyCompanyLocation({
            memberId,
            ...formValue,
          })
        : await memberServices.updateMyCompanyLocation({
            id: itemData?.id,
            memberId,
            ...formValue,
          });

      setIsLoading(false);

      if (response?.isSuccess) {
        CustomNotification.success(
          intl.formatMessage(
            isAddNew
              ? Messages.msgLocationCreateSuccess
              : Messages.msgLocationUpdateSuccess
          )
        );
        gridApi?.rivirPurgeServerSideCache();
        dispatch(companyActions.getMemberProfileHeader(memberId));
        setIsOpen(false);
      } else {
        CustomNotification.error(
          response?.message ||
            intl.formatMessage(Messages.msgLocationUpdateFail)
        );
      }
    } catch (error) {
      setIsLoading(false);

      if (error?.errorFields) {
        formInstance.scrollToField(error?.errorFields[0].name[0]);
        CustomNotification.error(intl.formatMessage(Messages.msgInvalidFields));
      } else {
        CustomNotification.error(intl.formatMessage(Messages.msgExcepEdit));
      }
    }
  };

  useEffect(() => {
    //* set form Data to edit
    const setInitFormDataForEdit = async () => {
      if (isOpen && itemData?.id && !isAddNew) {
        setIsLoading(true);
        const response = await memberServices.getCompanyLocationDetail(
          itemData?.id
        );
        setIsLoading(false);

        if (response?.isSuccess) {
          formInstance.setFieldsValue(response?.data);
          refCountry &&
            refCountry.current &&
            refCountry.current.updateSelected(
              searchCountryCode(response.data?.country)
            );
        } else {
          CustomNotification.error('Failed to get contact detail info');
        }
      }
    };

    setInitFormDataForEdit();
  }, [isOpen, itemData, isAddNew]);

  return (
    <React.Fragment>
      <StyledModal
        className={classnames('company-location-form__wrapper', {
          'company-location-form__wrapper--loading': isLoading,
        })}
        title={
          isAddNew ? (
            <FormattedMessage {...Messages.formLocationTitleNew} />
          ) : (
            <FormattedMessage {...Messages.formLocationTitle} />
          )
        }
        visible={isOpen || isAddNew}
        onOk={handleSubmitForm}
        onCancel={() => setIsOpen(false)}
        okText={
          isLoading ? (
            <React.Fragment>
              <LoadingOutlined />
              <Text style={{ color: 'white' }}>
                <FormattedMessage {...Messages.btnSending} />
              </Text>
            </React.Fragment>
          ) : (
            <FormattedMessage {...Messages.btnSubmit} />
          )
        }
        cancelText={<FormattedMessage {...Messages.btnCancel} />}
        maskClosable={false}
        destroyOnClose
      >
        <CompanyLocationFormBody
          formInstance={formInstance}
          isLoading={isLoading}
          type={type}
        />
      </StyledModal>
    </React.Fragment>
  );
};

/**
 *  ! SUB COMPONENT - company location form content
 * @param {*} props
 * @returns
 */
const CompanyLocationFormBody = injectIntl((props) => {
  const { formInstance, intl, isLoading, refCountry, type } = props;

  useEffect(() => {
    return () => {
      formInstance.resetFields();
      formInstance.setFieldsValue({
        addressType: type ? type : null,
        country: CountryCode['US'],
      });
    };
  }, []);

  useEffect(() => {
    formInstance.setFieldsValue({
      addressType: type ? type : null,
    });
  }, [type]);

  return (
    <Form
      form={formInstance}
      className='company-location-form'
      initialValues={{
        status: 'Active',
        country: CountryCode['US'],
      }}
      layout='vertical'
      scrollToFirstError={true}
      id='company-location-edit-form'
    >
      <Form.Item
        name='addressType'
        label={<FormattedMessage {...Messages.labelType} />}
        rules={[
          {
            required: true,
            message: 'Type is required',
          },
        ]}
      >
        <WrapperSelect
          getPopupContainer={() =>
            document.getElementById('company-location-edit-form')
          }
          placeholder={intl.formatMessage(Messages.placeHolderLocationType)}
          disabled={!!type}
        >
          {locationTypeIcon.map((locationIconItem) => (
            <Option
              className={classnames(
                'company-location-form__select-option',
                'company-location-form__select-option--with-icon'
              )}
              value={locationIconItem.value}
              key={locationIconItem.value}
            >
              <locationIconItem.icon />
              {locationIconItem.label}
            </Option>
          ))}
        </WrapperSelect>
      </Form.Item>
      <Form.Item
        name='mainAddress'
        label={<FormattedMessage {...Messages.labelAddress} />}
        rules={[
          {
            required: true,
            message: 'Address is required',
          },
        ]}
      >
        <Input
          name='address'
          placeholder={intl.formatMessage(Messages.placeHolderAddress)}
        />
      </Form.Item>
      <Form.Item
        name='city'
        label={<FormattedMessage {...Messages.labelCity} />}
        rules={[
          {
            required: true,
            message: 'City is required',
          },
        ]}
      >
        <Input
          name='city'
          placeholder={intl.formatMessage(Messages.placeHolderCity)}
        />
      </Form.Item>
      <Form.Item
        name='state'
        label={<FormattedMessage {...Messages.labelState} />}
      >
        <Input
          name='state'
          placeholder={intl.formatMessage(Messages.placeHolderState)}
        />
      </Form.Item>
      <Form.Item
        name='country'
        label={<FormattedMessage {...Messages.labelCountry} />}
      >
        <FlagCountrySelectionFieldInput
          searchable={true}
          className='company-location-form__country-input'
          placeholder=''
          onSelect={(value) => {
            formInstance.setFieldsValue({
              ...formInstance.getFieldsValue(),
              country: CountryCode[value],
            });
          }}
          ref={refCountry}
          defaultCountry='US'
        />
      </Form.Item>
      <Form.Item
        name='zipcode'
        label='Zipcode'
        rules={[
          () => ({
            validator(rule, value) {
              const regex = /^\d{5}[-\s]?(?:\d{4})?$/gm;
              if (regex.exec(value) || !value) {
                return Promise.resolve();
              } else {
                return Promise.reject('Invalid Zipcode');
              }
            },
          }),
        ]}
      >
        <Input
          name='zip'
          placeholder={intl.formatMessage(Messages.placeHolderZipCode)}
        />
      </Form.Item>
      <Form.Item
        name='locationName'
        label={<FormattedMessage {...Messages.labelLocationName} />}
        rules={[
          {
            required: true,
            message: 'Location Name is required',
          },
        ]}
      >
        <Input
          name='locationName'
          placeholder={intl.formatMessage(Messages.placeHolderLocationName)}
        />
      </Form.Item>
      <Form.Item
        name='locationDescription'
        label={<FormattedMessage {...Messages.labelLocationDescription} />}
      >
        <TextArea
          showCount={false}
          maxLength={300}
          placeholder={intl.formatMessage(Messages.placeHolderLocationDes)}
          autoSize={{ minRows: 5, maxRows: 5 }}
        />
      </Form.Item>
      <Form.Item
        name='phoneNumber'
        label={
          <div>
            <FormattedMessage {...Messages.labelPhone} />
          </div>
        }
      >
        <Input
          name='phoneNumber'
          placeholder={intl.formatMessage(Messages.placeHolderPhone)}
        />
      </Form.Item>
      <Form.Item
        name='mobileNumber'
        label={
          <div>
            <FormattedMessage {...Messages.labelMobile} />
          </div>
        }
      >
        <Input
          name='mobileNumber'
          placeholder={intl.formatMessage(Messages.placeHolderMobile)}
        />
      </Form.Item>
      <Form.Item
        name='faxNumber'
        label={<FormattedMessage {...Messages.labelFax} />}
      >
        <Input
          name='faxNumber'
          placeholder={intl.formatMessage(Messages.placeHolderFax)}
        />
      </Form.Item>
      <Form.Item
        name='email'
        label={<FormattedMessage {...Messages.labelEmail} />}
        rules={[{ type: 'email', message: 'Invalid Email' }]}
      >
        <Input
          name='email'
          placeholder={intl.formatMessage(Messages.placeHolderEmail)}
        />
      </Form.Item>

      {/* BP 12/28/2020 - remove latitude and longitude fields
  {isAddNew && (
    <>
      <Form.Item
        name='latitude'
        label='Latitude'
        rules={[
          () => ({
            validator(rule, value) {
              const regex = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
              if (regex.exec(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Invalid Latitude');
              }
            },
          }),
        ]}
      >
        <Input
          name='latitude'
          label={<FormattedMessage {...Messages.labelLatitude} />}
          placeholder={intl.formatMessage(
            Messages.placeHolderLatitude
          )}
        />
      </Form.Item>
      <Form.Item
        name='longitude'
        label={<FormattedMessage {...Messages.labelLongitude} />}
        rules={[
          () => ({
            validator(rule, value) {
              const regex = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
              if (regex.exec(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject('Invalid Longitude');
              }
            },
          }),
        ]}
      >
        <Input
          name='longitude'
          placeholder={intl.formatMessage(
            Messages.placeHolderLongitude
          )}
        />
      </Form.Item>
    </>
  )} */}
    </Form>
  );
});

CompanyLocationForm.propTypes = {
  isOpen: PropTypes.bool,
  isAddNew: PropTypes.bool,
  setIsOpen: PropTypes.func,
  itemData: PropTypes.object,
};

export default injectIntl(CompanyLocationForm);
