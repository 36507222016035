import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import { OtherLayout, ViewLayout, AdvanceStack } from './components';
import { RibbonBar, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import NewStepSection from '../ribbon/components/sections/wf-template-full/NewStepSection';
import NewTaskSection from '../ribbon/components/sections/wf-template-full/NewTaskSection';
import CloneTaskSection from '../ribbon/components/sections/wf-template-full/CloneTaskSection';
import CloneStepSection from '../ribbon/components/sections/wf-template-full/CloneStepSection';

import * as selectors from 'pages/workflow-template-full/controllers/selectors';

const WfTemplateFullView = () => {
  const { type, step, taskId } = useSelector(
    selectors.makeSelectWfTemplateView()
  );
  const wf = useSelector(selectors.makeSelectWfTemplateDetail());
  const isCloneStep = useSelector(selectors.makeSelectCloneStep());
  const isCloneTask = useSelector(selectors.makeSelectCloneTask());
  const statusWf = useSelector(selectors.makeSelectStatusWorkflow());
  const isActiveWf = statusWf?.toLowerCase() === 'active';

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout disabled />
      <RibbonDivider />
      <NewStepSection />
      {type === 'STEP' && isActiveWf && <NewTaskSection stepId={step?.id} />}
      {type === 'STEP' && isActiveWf && isCloneStep && (
        <CloneStepSection
          stepId={step?.id}
          wfId={wf?.id}
          mainWorkflowId={wf?.mainWorkflowId}
        />
      )}
      {type === 'TASK' && isActiveWf && isCloneTask && (
        <CloneTaskSection
          id={taskId}
          stepId={step?.id}
          wfId={wf?.id}
          mainWorkflowId={wf?.mainWorkflowId}
        />
      )}
      <RibbonDivider />
      <AdvanceStack isDisabled />
      <OtherLayout
        ribbonSide='right'
        ribbonType={'wf-template'}
        isHaveAddOpenItem={false}
      />
    </RibbonBar>
  );
};

export default WfTemplateFullView;
