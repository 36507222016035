import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from 'antd';
import { MinusSquareFilled, PlusSquareFilled } from '@ant-design/icons';
import classnames from 'classnames';

import * as productActions from '../../controllers/actions';

import Messages from 'i18n/messages/product';
import { useIntl } from 'react-intl';

const { Text } = Typography;

const ProductHierarchyTree = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    id,
    isContainer,
    label,
    upc12,
    gtin,
    nest,
    icon,
    productId,
    isActive,
  } = props;

  const [checked, setChecked] = useState(!!nest);

  const onClickLabelHandler = () => {
    showEndpointInfo();
  };

  const onClickExpandIcon = (e) => {
    e.stopPropagation();

    setChecked((currentState) => {
      return !currentState;
    });
  };

  const showEndpointInfo = () => {
    const payload = {
      productId,
      hierarchyId: id,
    };
    dispatch(productActions.updateHierarchyEndpoint(payload));
  };

  useEffect(() => {
    setChecked(!!nest);
  }, [nest]);

  const ExpandIcon = checked ? MinusSquareFilled : PlusSquareFilled;

  const renderTree = () => {
    return (
      <li>
        <label
          className={classnames({
            'product-hierarchy__tree-label': true,
            'product-hierarchy__tree-label--checked': checked,
            'product-hierarchy__tree-label--expandable': !!nest,
            'product-hierarchy__tree-label--inactive': !isActive,
          })}
          onClick={isActive && onClickLabelHandler}
        >
          {!!nest && !isContainer ? (
            <ExpandIcon
              className='product-hierarchy__tree-expand-icon'
              onClick={isActive && onClickExpandIcon}
            />
          ) : null}
          {icon ? (
            <span className='product-hierarchy__tree-level-icon'>{icon}</span>
          ) : null}
          <Text className='product-hierarchy__tree-level'>{label}</Text>
          <Text className='product-hierarchy__tree-numbs-container'>
            <Text
              className='product-hierarchy__tree-num'
              style={{ textAlign: 'right' }}
            >
              {upc12}
            </Text>
            /<Text className='product-hierarchy__tree-num'>{gtin}</Text>
          </Text>
        </label>
        {nest && checked ? (
          <ul>
            {nest?.map((nestItem, index) => {
              return <ProductHierarchyTree {...nestItem} key={index} id={id} />;
            })}
          </ul>
        ) : null}
      </li>
    );
  };

  return isContainer ? (
    <ul className={'product-hierarchy__tree'}>{renderTree()}</ul>
  ) : (
    renderTree()
  );
};

export default ProductHierarchyTree;
