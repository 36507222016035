import React from 'react';
import PropTypes from 'prop-types';

import './PageEditorBackground.less';

function PageEditorBackground(props) {
  const { backgroundConfig, key } = props;

  const backgroundEleId = `${key}_background`;

  return (
    <div className='page-editor-background__wrap'>
      <div
        id={backgroundEleId}
        className='page-editor-background'
        style={{
          backgroundImage: `url(${backgroundConfig?.src})`,
          backgroundPosition: `0% ${backgroundConfig?.top}%`,
          backgrounSize: 'cover',
          transform: `scale(${backgroundConfig?.scale})`,
          transformOrigin: `${backgroundConfig?.left}% ${backgroundConfig?.top}%`,
        }}
      />
    </div>
  );
}

PageEditorBackground.propTypes = {};

export default PageEditorBackground;
