import React from 'react';
import Messages from 'i18n/messages/home';

import { CloseCircleOutlined } from '@ant-design/icons';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';

const DeleteBrand = () => {
  return (
    <ButtonSmallIcon
      icon={<CloseCircleOutlined />}
      label={Messages.deleteBrand}
    />
  );
};

export default DeleteBrand;
