import { defineMessages } from 'react-intl';

export const scope = 'Taco.product.apl';

export default defineMessages({
  createdBy: {
    id: `${scope}.components.panel.createdBy`,
    defaultMessage: 'Created By',
  },
  brands: {
    id: `${scope}.components.panel.brand`,
    defaultMessage: '# Brands',
  },
  dateCreated: {
    id: `${scope}.components.panel.dateCreated`,
    defaultMessage: 'Date Created',
  },
  categories: {
    id: `${scope}.components.panel.categories`,
    defaultMessage: '# Categories',
  },
  shared: {
    id: `${scope}.components.panel.shared`,
    defaultMessage: 'Shared',
  },
  products: {
    id: `${scope}.components.panel.products`,
    defaultMessage: '# Products',
  },
  manufacturers: {
    id: `${scope}.components.panel.manufacturers`,
    defaultMessage: '# Manufacturers',
  },
  totalBrands: {
    id: `${scope}.components.panel.totalProducts`,
    defaultMessage: 'Total Brands',
  },
  totalMatchedBrands: {
    id: `${scope}.components.panel.totalMatchedProducts`,
    defaultMessage: 'Total Matched Brands',
  },
  nonMatchedBrands: {
    id: `${scope}.components.panel.nonMatchedBrands`,
    defaultMessage: 'Non-Matched Brands',
  },
  brandsTitle: {
    id: `${scope}.components.panel.brandsTitle`,
    defaultMessage: 'Brands',
  },
  completeBrandText: {
    id: `${scope}.components.panel.completeBrandText`,
    defaultMessage: 'Complete Brand Sets vs Brands',
  },
  aplMatch: {
    id: `${scope}.components.panel.aplMatch`,
    defaultMessage: 'APL Match',
  },
  averageMatch: {
    id: `${scope}.components.panel.averageMatch`,
    defaultMessage: 'Average Match',
  },
  sendMeetingInvite: {
    id: `${scope}.components.sendMeetingInvite`,
    defaultMessage: 'Send Meeting Invite',
  },
  sendQuestions: {
    id: `${scope}.components.sendQuestions`,
    defaultMessage: 'Send Questions',
  },
  aplDetailActions: {
    id: `${scope}.components.aplDetailActions`,
    defaultMessage: 'APL Actions',
  },
  aplDetailTemplate: {
    id: `${scope}.components.aplDetailTemplate`,
    defaultMessage: 'Template',
  },
  sendQuestion: {
    id: `${scope}.components.sendQuestion`,
    defaultMessage: 'Questions',
  },
  addQuestion: {
    id: `${scope}.components.addQuestion`,
    defaultMessage: 'Add Question',
  },
  contactEmail: {
    id: `${scope}.components.contactEmail`,
    defaultMessage: 'Contact Email',
  },
  questionLabel: {
    id: `${scope}.components.questionLabel`,
    defaultMessage: 'Question',
  },
  noInfo: {
    id: `${scope}.components.noInfo`,
    defaultMessage: 'No Supplier Email',
  },
  emailContent: {
    id: `${scope}.components.emailContent`,
    defaultMessage: 'Email Content',
  },
  emailTitle: {
    id: `${scope}.components.emailTitle`,
    defaultMessage: 'Email Title',
  },
  subject: {
    id: `${scope}.components.subject`,
    defaultMessage: 'Subject',
  },
  requiredRule: {
    id: `${scope}.components.requiredRule`,
    defaultMessage: 'This field is required',
  },
  sendQuestionSuccess: {
    id: `${scope}.components.sendQuestionSuccess`,
    defaultMessage: 'Send question successfully',
  },
  sendQuestionError: {
    id: `${scope}.components.sendQuestionError`,
    defaultMessage: 'Cannot send question',
  },
  requestSample: {
    id: `${scope}.components.requestSample`,
    defaultMessage: 'Request Samples',
  },
  sendAward: {
    id: `${scope}.components.sendAward`,
    defaultMessage: 'Send Award',
  },
  editContact: {
    id: `${scope}.components.editContact`,
    defaultMessage: 'Edit Contact',
  },
  send: {
    id: `${scope}.components.send`,
    defaultMessage: 'Send',
  },
  recipientNameLabel: {
    id: `${scope}.components.recipientNameLabel`,
    defaultMessage: 'Recipient Name',
  },
  projectNameLabel: {
    id: `${scope}.components.projectNameLabel`,
    defaultMessage: 'Project Name',
  },
  numberSampleLabel: {
    id: `${scope}.components.numberSampleLabel`,
    defaultMessage: 'Number of Sample',
  },
  shippingNameLabel: {
    id: `${scope}.components.shippingNameLabel`,
    defaultMessage: 'Shipping Name',
  },
  shippingCompanyLabel: {
    id: `${scope}.components.shippingCompanyLabel`,
    defaultMessage: 'Shipping Company',
  },
  shippingAddress1Label: {
    id: `${scope}.components.shippingAddress1Label`,
    defaultMessage: 'Shipping Address 1',
  },
  shippingAddress2Label: {
    id: `${scope}.components.shippingAddress2Label`,
    defaultMessage: 'Shipping Address 2',
  },
  additionalInfoLabel: {
    id: `${scope}.components.additionInfoLabel`,
    defaultMessage: 'Additional Information',
  },
  emailRequired: {
    id: `${scope}.components.emailRequired`,
    defaultMessage: 'Email is required',
  },
  subjectRequired: {
    id: `${scope}.components.subjectRequired`,
    defaultMessage: 'Subject is required',
  },
  emailInvalid: {
    id: `${scope}.components.emailInvalid`,
    defaultMessage: 'Invalid email',
  },
  sendSampleRequestSuccess: {
    id: `${scope}.message.sendSampleRequest.success`,
    defaultMessage: 'Send sample request success',
  },
  sendSampleRequestError: {
    id: `${scope}.message.sendSampleRequest.error`,
    defaultMessage: 'Cannot send sample request',
  },
  closeMessage: {
    id: `${scope}.message.sendSampleRequest.close`,
    defaultMessage: 'Are you sure you want to close? Your edits will be lost.',
  },
  updateEmailSuccess: {
    id: `${scope}.message.updateEmailSuccess`,
    defaultMessage: 'Update contact email successfully',
  },
  updateEmailError: {
    id: `${scope}.message.updateEmailError`,
    defaultMessage: 'Cannot update contact email',
  },
  requiredMessage: {
    id: `${scope}.message.requiredMessage`,
    defaultMessage: 'This field is required',
  },
  matchedProducts: {
    id: `${scope}.components.panel.matchedProducts`,
    defaultMessage: '# Matched Products',
  },
});
