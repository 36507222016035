import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import { getSnapshotQaSpecFreshMeat } from 'services/product';

const getQueryKeyQaFreshMeat = (productId) => {
  return ['pim', 'snapshot', parseInt(productId), 'qa-fresh-meat'];
};

export const useGetSnapshotQaFreshMeat = ({
  enabled,
  productId,
  ...restProps
}) => {
  const queryClient = useQueryClient();

  const getSnapshotQaFreshMeatData = async () => {
    const response = await getSnapshotQaSpecFreshMeat({ productId });
    const { isSuccess, data } = response;
    if (!isSuccess) return null;

    const { productId: id, ...restValue } = data;
    return restValue;
  };

  const query = useQuery({
    queryKey: getQueryKeyQaFreshMeat(productId),
    queryFn: getSnapshotQaFreshMeatData,
    enabled,
    ...restProps,
  });

  const invalidateSnapshotQaSpecFreshMeat = () => {
    queryClient.invalidateQueries({
      queryKey: getQueryKeyQaFreshMeat(productId),
    });
  };

  return { ...query, invalidateSnapshotQaSpecFreshMeat };
};
