import React from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/home';

import { PlayCircleOutlined } from '@ant-design/icons';

import { forwardTo } from 'utils/common/route';
import RibbonButton from 'common/components/button/RibbonButton';
import gridSelector from 'common/components/grid-view/controllers/selectors';

const OpenMapping = ({ disabled = false }) => {
  const selectedDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  const id =
    selectedDetail && selectedDetail.length > 0
      ? selectedDetail[0].id
      : undefined;

  return (
    <RibbonButton
      icon={<PlayCircleOutlined />}
      label={Messages.mappingOpen}
      onClick={() => forwardTo(`mapping/config/${id}`)}
      disabled={disabled || id === undefined || selectedDetail.length !== 1}
    />
  );
};

export default OpenMapping;
