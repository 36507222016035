import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './VerticalButton.less';

/**
 * A vertical alignment icon button
 * @example
  <VerticalButton 
    icon={<SearchOutlined style={{ fontSize: "24px" }} />}
    text="Icon Vertical Align"
  />
 * @param {object} props
 */
const VerticalButton = (props) => {
  const { icon, text, className, alignTop, ...rest } = props;
  let iconVertCls = 'ant-btn__icon--vertical';
  let items = [icon, text];
  if (!alignTop) _.reverse(items);
  return (
    <Button className={iconVertCls + ' ' + className} {...rest}>
      {_.map(items, (item) => item)}
    </Button>
  );
};

VerticalButton.propTypes = {
  /**
   * Icon node
   */
  icon: PropTypes.element,
  /**
   * Button text
   */
  text: PropTypes.string,
  /**
   * Button custom class
   */
  className: PropTypes.string,
  /**
   * Align icon on top
   */
  alignTop: PropTypes.bool,
};

VerticalButton.defaultProps = {
  className: '',
  alignTop: true,
};

export default VerticalButton;
