import React from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import CreateFolder from './components/controls/folders/CreateFolder';
import DeleteFolders from './components/controls/folders/DeleteFolders';
import CopyFolder from './components/controls/folders/CopyFolder';

import Addition from './components/sections/home-grid/AdditionSection';
import ShareSection from './components/sections/shared/ShareSection';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import './Folder.less';

const SharedFolders = () => {
  const { pathname } = useLocation();

  const selectedFolders = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Addition type='folder' selectedItemList={selectedFolders} />
      <ShareSection selectedItemList={selectedFolders} />
      <Divider type='vertical' style={{ height: 50, marginBottom: 15 }} />
      <ContainerButton>
        <ItemButton span={7}>
          <CreateFolder pathname={pathname} />
        </ItemButton>
        <ItemButton span={9}>
          <DeleteFolders pathname={pathname} />
        </ItemButton>
        <ItemButton span={8}>
          <CopyFolder pathname={pathname} />
        </ItemButton>
      </ContainerButton>
    </div>
  );
};

export default SharedFolders;
