import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Row, Col, Input, Space } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { Form, StyledModal } from 'common/components';

import { useCheckDisableInitialWorkflowSpec } from 'hooks/useCheckDisableInitialWorkflowSpec';

import {
  SearchGridItemPagination,
  SearchGrid,
  SearchGridItemButton,
  SearchItemSelectList,
  SectionContainer,
} from '../components';

import { mapToSearchParams } from '../mapper/mapToSearchParams';

import * as api from 'config/axios';

import { get } from 'lodash';

const defaultPageInfo = {
  currentPageIndex: 1,
  currentPageSize: 20,
  totalRecord: null,
};

const selectedItemsDefine = ['init', undefined, Array];

const SearchGridItemModal = (props, ref) => {
  const {
    searchType = 'asset',
    isMultiple = true,
    apiUrl,
    params,
    title,
    onOk,
    onCancel,
    tagRender,
    savedItem,
    savedSelected = false,
    service,
    dataPath,
    mapSearch,
    itemIdName,
    allowSelectAll = false,
    ...rest
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(selectedItemsDefine[0]);

  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [isLoading, setIsLoading] = useState(false);

  const refSelectedItems = useRef([]);

  const [formInstance] = Form.useForm();

  const isDisableInitialQaSpecWorkflow = useCheckDisableInitialWorkflowSpec({
    productList: selectedItems,
  });

  const getServices = (params) => {
    if (service) return service(params);

    const endpoint = apiUrl;
    return api.sendPost(endpoint, params);
  };

  const fetchItemList = async (customParams = {}) => {
    setIsLoading(true);

    const response = await getServices({
      ...params,
      ...customParams,
    });
    setIsLoading(false);
    if (response?.isSuccess) {
      const gridData = dataPath
        ? get(response, dataPath)
        : response?.data?.gridData;
      const paging = response?.data?.paging;
      setItems(gridData);
      setPageInfo(paging);
    }
  };

  const handleSearchItem = () => {
    const formValue = formInstance.getFieldsValue();

    const procParams = mapToSearchParams(formValue, params, mapSearch);

    fetchItemList(procParams);
  };

  const handleClearSearch = () => {
    formInstance.resetFields();
    fetchItemList(params);
  };

  const onChangePagination = (page, pageSize) => {
    const formValue = formInstance.getFieldsValue();
    const procParams = mapToSearchParams(formValue, params, mapSearch);

    fetchItemList({ ...procParams, pageIndex: page, pageNumber: page });
  };

  const handleSelectItem = (itemData) => {
    setSelectedItems((prev) => {
      let nextValue;
      if (prev !== 'init' && prev !== undefined) {
        nextValue = [...prev];
      } else {
        nextValue = [];
      }

      const foundItemIdx = nextValue.findIndex(
        (item) =>
          (item?.[itemIdName] || item?.id) ===
          (itemData?.[itemIdName] || itemData?.id)
      );

      if (foundItemIdx !== -1) {
        nextValue.splice(foundItemIdx, 1);
      } else {
        if (!isMultiple) {
          nextValue = [itemData];
        } else {
          nextValue.push(itemData);
        }
      }

      return nextValue;
    });
  };

  const handleClickOk = () => {
    if (selectedItems !== 'init') {
      refSelectedItems.current = selectedItems;
      onOk && onOk(selectedItems);
    } else {
      setSelectedItems(refSelectedItems.current);
      onCancel && onCancel(refSelectedItems.current);
      setIsModalOpen(false);
    }
  };

  const resetAll = () => {
    setSelectedItems(selectedItemsDefine[0]);
    setPageInfo(defaultPageInfo);
    formInstance.resetFields();
  };

  const handleSelectAll = () => {
    setSelectedItems(items);
  };

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setIsModalOpen(false);
    },
    openModal: () => {
      setIsModalOpen(true);
    },
    removeItem: (itemData) => {
      setSelectedItems((prev) => {
        return prev?.filter((product) => product?.id !== itemData?.id);
      });
    },
  }));

  useEffect(() => {
    if (isModalOpen) {
      fetchItemList(params);
    } else {
      !savedSelected && resetAll();
    }
  }, [isModalOpen, params, savedSelected]);

  return (
    <StyledModal
      visible={isModalOpen}
      className='search-grid-item__modal'
      title={title}
      onCancel={() => {
        setSelectedItems(refSelectedItems.current);
        onCancel && onCancel(refSelectedItems.current);
        setIsModalOpen(false);
      }}
      onOk={handleClickOk}
      okButtonProps={{
        disabled: !selectedItems?.length || isDisableInitialQaSpecWorkflow,
      }}
      centered
      destroyOnClose
      maskClosable={false}
      {...rest}
    >
      <Form form={formInstance}>
        <Row style={{ height: '100%' }}>
          <Col>
            <Form.Item label='Search Text' name='searchText'>
              <Input
                style={{ width: 200 }}
                onPressEnter={handleSearchItem}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col>
            <Space>
              <SearchGridItemButton type='primary' onClick={handleSearchItem}>
                <SearchOutlined />
              </SearchGridItemButton>

              <SearchGridItemButton type='primary' onClick={handleClearSearch}>
                Reset
              </SearchGridItemButton>
              {allowSelectAll && (
                <SearchGridItemButton type='primary' onClick={handleSelectAll}>
                  SelectAll
                </SearchGridItemButton>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
      <Row className='search-grid-item__modal-grid-n-preview'>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ flex: 1, overflow: 'hidden' }}>
            <Col xs={24} style={{ height: '100%' }}>
              <SectionContainer>
                <SearchGrid
                  items={items}
                  handleSelectItem={handleSelectItem}
                  selectedItems={selectedItems}
                  isLoading={isLoading}
                  searchType={searchType}
                  itemIdName={itemIdName}
                />
              </SectionContainer>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <SearchGridItemPagination
                pageInfo={pageInfo}
                onChangePagination={onChangePagination}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ flex: 1, overflow: 'hidden' }}>
          <SectionContainer innerStyle={{ border: 'none' }}>
            <SearchItemSelectList
              selectedItems={selectedItems}
              items={items}
              handleSelectItem={handleSelectItem}
              tagRender={tagRender}
            />
          </SectionContainer>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default forwardRef(SearchGridItemModal);
