import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Row, Col, Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { Form, SectionWrapper } from 'common/components';
import NutrientForm from './NutrientForm';
import SupplementFormButton from './supplement-label/SupplementFormButton';

import {
  useGetNutritionEnum,
  useNutritionContext,
} from '../hook/useNutritionLabel';
import { parseNumber } from 'utils/parser';

import { getUomTypesAbbreviations } from '../DailyValueConstants';

const NutrientList = forwardRef((props, forwardedRef) => {
  const [form] = Form.useForm();

  const { dv = [], dailyValuesField, isEdit = true, handleRemoveDV } = props;

  const [selectedNutrient, setSelectedNutrient] = useState(null);

  useImperativeHandle(forwardedRef, () => {
    return {
      validateForm,
    };
  });

  const {
    isAddingNew,
    setIsAddingNew,
    isUpdating,
    setIsUpdating,
    clearEditNutrientState,
  } = useNutritionContext();

  const { basicUomProperties } = useGetNutritionEnum();

  const validateForm = () => {
    form.validateFields();
  };

  const onClickAddNew = () => {
    setSelectedNutrient(null);
    setIsAddingNew(true);
    form.resetFields();
    form.setFieldsValue({ nutrientIdx: null });
  };

  const onAddNew = () => {
    setSelectedNutrient(null);
    setIsAddingNew(false);
    form.resetFields();
  };

  const onCancel = () => {
    setSelectedNutrient(null);
    clearEditNutrientState();
    form.resetFields();
  };

  const clickEditNutrient = (nutrientData, index) => {
    setSelectedNutrient(index);
    setIsAddingNew(false);
    setIsUpdating(true);
    form.setFieldsValue({
      ...nutrientData,
      nutrientIdx: index,
    });
  };

  const styles = {
    inputNumber: {
      width: '100%',
    },
    selectAfter: {
      width: '72px',
    },
    nutrientItem: (index) => {
      return {
        display: 'flex',
        justifyContent: 'flex-end',
        border: '1px solid #ddd',
        padding: '8px 8px',
        borderRadius: '4px',
        borderColor: index === selectedNutrient ? '#238dd9' : '#ddd',
        marginBottom: 3,
      };
    },
    unit: {
      padding: '3px 8px',
      marginRight: '5px',
      background: '#DCDCDC',
      borderRadius: 6,
    },
    form: {
      marginBottom: 10,
    },
    formItem: {
      marginBottom: '0px',
    },
    dv: {
      textAlign: 'center',
    },
    rowMargin: {
      marginBottom: 5,
    },
    addBtnWrapper: {
      marginTop: 30,
    },
    icon: {
      margin: '0 4px',
    },
    editIcon: (disable) => {
      if (disable) {
        return {
          opacity: 0,
          pointerEvent: 'none !important',
          cursorPoiner: 'none',
        };
      }

      return {};
    },
    nutrientFormWrapper: {
      boxShadow: `rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px`,
      padding: 8,
    },
    precisionCode: {
      wordBreak: 'break-inherit',
      marginBottom: 5,
    },
  };

  const renderItem = (dvItem, index) => {
    const shouldViewForm = selectedNutrient === index && isUpdating;
    return (
      <SectionWrapper
        key={index}
        title={
          shouldViewForm || !isEdit ? null : ( //* isEdit is in QA Spec
            <React.Fragment>
              <EditOutlined
                style={styles.icon}
                onClick={() => clickEditNutrient(dvItem, index)}
              />

              <DeleteOutlined
                style={styles.icon}
                onClick={() => handleRemoveDV(dvItem)}
              />
            </React.Fragment>
          )
        }
        placement='right'
      >
        {shouldViewForm ? (
          <NutrientForm
            selectedNutrient={selectedNutrient}
            method='update'
            form={form}
            dv={dv}
            onCancel={onCancel}
            onAddNew={onAddNew}
            isEdit={isEdit}
          />
        ) : (
          renderItemView(dvItem, index)
        )}
      </SectionWrapper>
    );
  };

  const renderItemView = (dvItem, index) => {
    return (
      <Row>
        <Col style={{ flex: 1 }}>
          <Row
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flex: 1,
              marginBottom: 7,
            }}
          >
            <Col xs={24}>
              <span style={{ wordBreak: 'break-inherit', fontWeight: 'bold' }}>
                {dvItem.nutritionTypeCode}
              </span>
            </Col>
          </Row>
          <Row>
            <Col flex={18}>
              <span>{dvItem.measurementPrecisionCode}</span>
            </Col>
            <Col flex={4}>
              {dvItem.quantity != null
                ? `${parseNumber(dvItem.quantity)}${getUomTypesAbbreviations(
                    basicUomProperties,
                    dvItem.quantityUom
                  )}`
                : null}
            </Col>
            <Col flex={2} style={{ textAlign: 'right' }}>
              {dvItem.dailyValueIntakePercent != null
                ? `${parseNumber(dvItem.dailyValueIntakePercent)}%`
                : null}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <SectionWrapper
      title={
        dailyValuesField?.length !== 0 ? (
          'Nutrients'
        ) : (
          <>
            <span style={{ color: 'red' }}>*</span> Nutrients
          </>
        )
      }
    >
      {dailyValuesField?.length !== 0 && (
        <React.Fragment>
          <Row style={styles.rowMargin}>
            <Col xs={24}>{dailyValuesField.map(renderItem)}</Col>
          </Row>
        </React.Fragment>
      )}

      {isEdit && !isAddingNew && (
        <Row>
          <Col xs={24}>
            <SupplementFormButton
              text='Add new'
              icon={<PlusOutlined />}
              onClick={onClickAddNew}
              color={!isUpdating && '#0070cc'}
              disabled={isUpdating}
            />
          </Col>
        </Row>
      )}
      {isAddingNew && (
        <NutrientForm
          method='add'
          form={form}
          dv={dv}
          onCancel={onCancel}
          isEdit={isEdit}
        />
      )}
    </SectionWrapper>
  );
});

export default NutrientList;
