const compareNumber = (item1, item2) => {
  if (item1 < item2) {
    return -1;
  }
  if (item1 > item2) {
    return 1;
  }
  return 0;
};

const compareId = (item1, item2) => {
  if (item1 && item2 && item1.planoFileName && item2.planoFileName) {
    const orderNumber1 = item1?.planoFileName?.split('.');
    const orderNumber2 = item2?.planoFileName?.split('.');
    if (compareNumber(orderNumber1[1], orderNumber2[1]) !== 0)
      return compareNumber(orderNumber1[1], orderNumber2[1]);
    else return compareNumber(orderNumber1[0], orderNumber2[0]);
  } else return compareNumber(item1?.id, item2?.id);
};

export const sortList = (list) => {
  const UnitList = list
    .filter((item) => item.packageLevel?.toLowerCase() === 'unit')
    .sort((item1, item2) => compareId(item1, item2));
  const InnerPackList = list
    .filter((item) => item.packageLevel?.toLowerCase() === 'inner pack')
    .sort((item1, item2) => compareId(item1, item2));
  const CaseList = list
    .filter((item) => item.packageLevel?.toLowerCase() === 'case')
    .sort((item1, item2) => compareId(item1, item2));
  const MCaseList = list
    .filter((item) => item.packageLevel?.toLowerCase() === 'master case')
    .sort((item1, item2) => compareId(item1, item2));
  const PalletList = list
    .filter((item) => item.packageLevel?.toLowerCase() === 'pallet')
    .sort((item1, item2) => compareId(item1, item2));
  return [
    ...UnitList,
    ...InnerPackList,
    ...CaseList,
    ...MCaseList,
    ...PalletList,
  ];
};
