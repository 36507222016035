import React from 'react';

import { message, Spin, Typography } from 'antd';
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

export const messageAddSameAsset = (setStatusDragDrop, content) => {
  message.info({
    content: content,
    duration: 1.5,
    style: {
      fontSize: 16,
    },
  });
  setStatusDragDrop({ isExistAsset: false });
};

export const messageSelfAsset = (setStatusDragDrop, content) => {
  message.info({
    content: content,
    duration: 1.5,
    style: {
      fontSize: 16,
    },
  });
  setStatusDragDrop({ isSelfAsset: false });
};

export const messageSuccessUpdate = (content) => {
  message.success(content);
};

export const DeleteAssetGrid = ({ data, handleDeleteAssetGrid, nameGrid }) => {
  return (
    <CloseCircleOutlined
      className='action-delete-asset'
      onClick={() => handleDeleteAssetGrid(data, nameGrid)}
    />
  );
};

export const Loading = ({ className, size = 'default' }) => {
  return (
    <div className={className}>
      <Spin size={size} />
    </div>
  );
};

export const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div>
        <InfoCircleOutlined className='not-found__icon' />
      </div>
      <Typography strong style={{ marginTop: 9, fontSize: 16 }}>
        Not Found
      </Typography>
    </div>
  );
};
