import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';

import { FormattedMessage } from 'react-intl';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import messages from 'i18n/messages/gridView';
import { useCheckPermissions } from 'hooks/useCheckPermissions';

import CreateFolderModal from 'pages/home/ribbon/components/controls/folders/CreateFolderModal';

const AddToFolderActions = (props) => {
  const {
    loading,
    onSubmit,
    onCancel,
    parentId,
    currentFolderId,
    disableSubmitButton,
    successCallback,
  } = props;
  const createFolderModalVisibleHook = useState(false);
  const [, setVisibleModal] = createFolderModalVisibleHook;

  const addToNewFolder = () => {
    setVisibleModal(true);
  };

  const PERMISSION_SUBMIT_ADD_TO_FOLDER = [
    {
      action: ABILITY_ACTION.EDIT,
      subject: ABILITY_SUBJECT.FOLDER,
    },
    {
      action: ABILITY_ACTION.VIEW,
      subject: ABILITY_SUBJECT.FOLDER,
    },
  ];

  const hasPermissionAddToFolder = useCheckPermissions(
    PERMISSION_SUBMIT_ADD_TO_FOLDER
  );

  return (
    <>
      <Row>
        <Col flex={0}>
          <Can I={ABILITY_ACTION.CREATE} a={ABILITY_SUBJECT.FOLDER}>
            <Button disabled={loading} onClick={addToNewFolder}>
              <FormattedMessage {...messages.addToNewFolder} />
            </Button>
          </Can>
        </Col>
        <Col flex={1}></Col>
        <Col flex={0}>
          <Button
            disabled={loading}
            onClick={onCancel}
            style={{ marginRight: 8 }}
          >
            <FormattedMessage {...messages.cancelAddToFolder} />
          </Button>
          <Button
            loading={loading}
            disabled={disableSubmitButton || !hasPermissionAddToFolder}
            onClick={onSubmit}
            type='primary'
          >
            <FormattedMessage {...messages.submit} />
          </Button>
        </Col>
      </Row>
      <CreateFolderModal
        parentId={parentId}
        skipNotification={true}
        currentFolderId={currentFolderId}
        visibleHook={createFolderModalVisibleHook}
        successCallback={successCallback}
      />
    </>
  );
};

export default AddToFolderActions;
