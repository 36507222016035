import React, { useState, useEffect, useRef, useMemo } from 'react';

import { AgGridReact, AgGridColumn } from 'ag-grid-react';

import CheckboxRender from '../qa-grid-cell/CheckboxRender';
import StringTooltipRender from '../qa-grid-cell/StringTooltipRender';
import StatusRender from '../qa-grid-cell/StatusRender';
import DefaultCellRender from '../qa-grid-cell/DefaultCellRender';
import CountryEditorRender from '../qa-grid-cell/CountryEditorRender';
import CountryEditorRenderSnapshot from '../qa-grid-cell/CountryEditorRenderSnapshot';
import TextRender from '../qa-grid-cell/TextRender';
import TextRenderSnapshot from '../qa-grid-cell/TextRenderSnapshot';
import FileUploadRender from '../qa-grid-cell/FileUploadRender';
import FileUploadRenderSnapshot from '../qa-grid-cell/FileUploadRenderSnapshot';
import CheckboxRenderSnapshot from '../qa-grid-cell/CheckboxRenderSnapshot';

import { SelectionEditor } from '../qa-grid-cell/SelectionEditor';
import { CountryEditor } from '../qa-grid-cell/CountryEditor';
import { NumericEditor } from '../qa-grid-cell/NumericEditor';
import { CheckboxEditor } from '../qa-grid-cell/CheckboxEditor';
import { FreeTextSelector } from '../qa-grid-cell/FreeTextSelector';
import { FileUploadEditor } from '../qa-grid-cell/FileUploadEditor';
import { CountryEditorSingle } from '../qa-grid-cell/CountryEditorSingle';
import { TextEditor } from '../qa-grid-cell/TextEditor';
import { SelectionFreeText } from '../qa-grid-cell/SelectionFreeText';

import classnames from 'classnames';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import './QaGridEdit.less';

const QaGridEdit = (props) => {
  const {
    gridData = [],
    fields,
    gridInst,
    rowClassRules,
    getRowNodeId,
    onCellEditingStopped,
    isEdit,
    onRowSelected,
    snapshotGridValues,
    onReady,
    ...otherProps
  } = props;

  const gridRef = useRef();
  let refAllowEdit = useRef(false);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const isCellEditable = (params) => {
    return refAllowEdit.current;
  };

  const columnTypes = useMemo(() => {
    return {
      editableColumn: {
        editable: (params) => {
          return isCellEditable(params);
        },
        cellStyle: (params) => {
          // if (isCellEditable(params)) {
          //   return { backgroundColor: 'lightBlue' };
          // }
        },
      },
    };
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if (gridInst) {
      gridInst.current = {
        api: params.api,
        columnApi: params.columnApi,
      };

      onReady && onReady(params);
    }
  };

  const procField = useMemo(() => {
    const nullEditor = () => {};

    return fields.map((fieldItem) => {
      return {
        cellEditorSelector: nullEditor,
        ...fieldItem,
      };
    });
  }, [fields]);

  useEffect(() => {
    if (gridData && gridApi) {
      gridApi.refreshCells();
    }
  }, [gridData, gridApi]);

  useEffect(() => {
    if (gridApi) {
      gridApi.redrawRows();
    }
  }, [rowClassRules, gridApi, JSON.stringify(snapshotGridValues)]);

  useEffect(() => {
    refAllowEdit.current = isEdit;

    gridRef.current.api && gridRef.current.api.redrawRows();
  }, [isEdit]);

  const gridContext = useMemo(() => {
    return {
      snapshotGridValues,
    };
  }, [JSON.stringify(snapshotGridValues)]);

  return (
    <div className='qa-aggrid-edit'>
      <div className='qa-aggrid-edit__wrapper'>
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
          className={classnames(
            'ag-theme-alpine',
            'ag-theme-alpine--with-scroller',
            'qa-aggrid-edit__content'
          )}
        >
          <AgGridReact
            context={gridContext}
            ref={gridRef}
            // disableStaticMarkup={true}
            rowData={gridData?.slice()}
            defaultColDef={{
              flex: 1,
              minWidth: 110,
              resizable: true,
            }}
            columnTypes={columnTypes}
            frameworkComponents={{
              CheckboxRender,
              CheckboxRenderSnapshot,
              StringTooltipRender,
              StatusRender,
              DefaultCellRender,
              CountryEditor,
              CountryEditorRender,
              CountryEditorRenderSnapshot,
              CountryEditorSingle,
              SelectionEditor,
              NumericEditor,
              CheckboxEditor,
              FreeTextSelector,
              FileUploadEditor,
              FileUploadRender,
              FileUploadRenderSnapshot,
              TextEditor,
              TextRender,
              TextRenderSnapshot,
              SelectionFreeText,
            }}
            onGridReady={onGridReady}
            onCellValueChanged={(params) => {
              let rowData = [];
              params.api.forEachNode((node) => rowData.push(node.data));
            }}
            rowClassRules={rowClassRules}
            getRowNodeId={getRowNodeId}
            onCellEditingStopped={onCellEditingStopped}
            rowSelection='multiple'
            overlayNoRowsTemplate='No Rows to Show'
            onRowSelected={onRowSelected}
            singleClickEdit={true}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            {...otherProps}
          >
            {procField &&
              procField?.length > 0 &&
              procField.map((fieldItem, idx) => {
                const cellRendererConfig = fieldItem?.cellRendererFramework
                  ? { cellRenderer: fieldItem?.cellRenderer }
                  : {};

                const cellEditorDefault = {
                  cellEditorSelector: (params) => {
                    return {
                      component: 'TextEditor',
                      popup: true,
                    };
                  },
                  cellRenderer: 'TextRender',
                };

                const agGridColumnProps = {
                  ...cellEditorDefault,
                  ...fieldItem,
                  ...cellRendererConfig,
                };

                return <AgGridColumn key={idx} {...agGridColumnProps} />;
              })}
          </AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default QaGridEdit;
