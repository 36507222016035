import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Divider } from 'antd';

import {
  NewMeeting,
  MeetingNow,
  EditMeeting,
  CancelMeeting,
} from '../../controls/dashboard';
import { RibbonSection, RibbonDivider } from 'common/components';

import * as dashboardSelectors from 'pages/dashboard/controllers/selectors';
import { getMeetingList } from 'pages/dashboard/controllers/actions';
import userSelectors from 'redux/user/selectors';

import * as formatDate from 'utils/formatDate';

const ActionsMeeting = () => {
  // const meetingId = useSelector(dashboardSelectors.makeSelectMeetingId());
  const userId = useSelector(userSelectors.makeSelectUserId());

  const dispatch = useDispatch();

  // const { meetingDetail } = useFetchMeetingDetails({
  //   meetingId,
  //   isEnabled: Boolean(meetingId),
  // });
  const meetingDetails = useSelector(
    dashboardSelectors.makeSelectMeetingDetails()
  );
  const { data: meetingDetail } = meetingDetails;

  const { isHost } =
    meetingDetail?.meetingUsers?.find((user) => user.userId === userId) ?? {};

  const disabled =
    !meetingDetail?.meetingId ||
    !isHost ||
    meetingDetail.status !== 'Scheduled';

  const calendarDate = useSelector(dashboardSelectors.makeSelectCalendarDate());
  const calendarView = useSelector(dashboardSelectors.makeSelectCalendarView());

  const handleRefetchMeetingCalendar = () => {
    const typeView = calendarView === 'agenda' ? 'month' : calendarView;
    const fromDate = formatDate.startISOTime(calendarDate, typeView);
    const toDate = formatDate.endISOTime(calendarDate, typeView);

    dispatch(getMeetingList(fromDate, toDate));
    // dispatch(saveIdMeeting(null));
  };

  return (
    <RibbonSection>
      <NewMeeting onRefetchMeetingCalendar={handleRefetchMeetingCalendar} />
      <MeetingNow />

      <RibbonDivider />

      <EditMeeting
        meetingDetail={meetingDetail}
        disabled={disabled}
        onRefetchMeetingCalendar={handleRefetchMeetingCalendar}
      />
      <CancelMeeting
        meetingDetail={meetingDetail}
        disabled={disabled}
        onRefetchMeetingCalendar={handleRefetchMeetingCalendar}
      />
    </RibbonSection>
  );
};

export default ActionsMeeting;
