import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import * as userActions from 'redux/user/actions';
import userSelectors from 'redux/user/selectors';

//* fetch and manage user profile data
export const useRefetchUserProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const userProfileHeaderLoading = useSelector(
    userSelectors.makeSelectUserProfileHeaderLoading()
  );

  const userProfileHeader = useSelector(
    userSelectors.makeSelectUserProfileHeader()
  );

  const { data: loginUserProfile, loading: userProfileLoading } = useSelector(
    userSelectors.makeSelectUserProfile()
  );

  const userProfileData = useMemo(() => {
    if (id) {
      return userProfileHeader;
    } else {
      return loginUserProfile;
    }
  }, [id, userProfileHeader, loginUserProfile]);

  const fetchUserProfile = () => {
    if (id) {
      dispatch(userActions.getUserProfileHeader(id));
    } else {
      dispatch(userActions.getUserProfile());
    }
  };

  return {
    fetch: fetchUserProfile,
    loading: userProfileHeaderLoading || userProfileLoading,
    userProfileData,
  };
};
