import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

const DropWrap = (props) => {
  const {
    children,
    onDropData,
    dropClassName = '',
    className,
    ...rest
  } = props;
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onDrop = (e) => {
    const dropData = JSON.parse(e.dataTransfer.getData('dragData'));
    setIsDraggingOver(false);
    onDropData && onDropData(dropData);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const onDragLeave = (e) => {
    setIsDraggingOver(false);
  };

  return (
    <div
      className={classnames({
        [className]: Boolean(className),
        [dropClassName]: isDraggingOver,
      })}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      {...rest}
    >
      {children}
    </div>
  );
};

DropWrap.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  handleDropData: PropTypes.func,
  dropClassName: PropTypes.string,
};

export default DropWrap;
