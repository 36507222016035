import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from 'react';

import { Select, Tooltip, Typography } from 'antd';

import { FontColorsOutlined } from '@ant-design/icons';

import { sleep } from 'utils/delay';

import classnames from 'classnames';

import './SelectionFreeText.less';

const { Option } = Select;
const { Text } = Typography;

export const SelectionFreeText = forwardRef((props, ref) => {
  const {
    values,
    value: initValue,
    mode = 'single',
    mapValue,
    disabled,
    maxLength,
    column,
  } = props;

  const processInitValue = useMemo(() => {
    if (initValue === null || initValue === undefined || initValue === '') {
      return [];
    } else {
      return [initValue];
    }
  }, [initValue]);

  const [value, setValue] = useState(processInitValue);
  const [editing, setEditing] = useState(true);

  const refContainer = useRef(null);
  const refSelect = useRef();

  const refValue = useRef(undefined);
  const refSearchValue = useRef(undefined);

  const focus = async () => {
    await sleep(50);
    if (refContainer.current && refContainer.current?.focus) {
      refContainer.current.focus();
    }
    await sleep(150);
    if (refSelect.current && refSelect.current?.focus) {
      refSelect.current.focus();
    }
  };

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const extractValue = (value) => {
    return mapValue ? mapValue(value) : value;
  };

  const isValidLength = (value) => {
    if (!maxLength === null || maxLength === undefined || !value) return true;
    return maxLength && value?.length <= maxLength;
  };

  const handleChange = (value) => {
    let nextValue = value.slice(-1)[0];

    nextValue = nextValue ? extractValue(nextValue) : undefined;

    if (nextValue) {
      const isSelectedItemInList = values
        .map((item) => item?.value)
        .includes(nextValue);

      if (isSelectedItemInList) {
        if (!isValidLength(nextValue)) return;
      } else {
        nextValue = maxLength ? nextValue.slice(0, maxLength) : nextValue;
      }
    }

    nextValue = nextValue ? [nextValue] : [];

    setValue(nextValue);
    refValue.current = nextValue;
  };

  const valueItemType =
    typeof values?.[0]?.displayName === 'string' ? 'object' : 'string';

  const getActiveDropDownItem = () => {
    const $dropDownActiveItem = document.querySelector(
      'div.ant-select-item-option-active > div.ant-select-item-option-content > div.option-item'
    );

    if ($dropDownActiveItem) {
      const activeValue = $dropDownActiveItem.getAttribute('value');

      return activeValue;
    } else {
      return undefined;
    }
  };

  const handleInputKeyDown = async (e) => {
    if (e.key === 'Delete') {
      setValue([]);
      refValue.current = [];
      setEditing(false);
      return;
    }

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      await sleep(200);

      const activeValue = getActiveDropDownItem();

      if (activeValue !== undefined) {
        refValue.current = [activeValue];
      } else if (activeValue === undefined) {
        refValue.current = [refSearchValue.current];
      }
    }
  };

  const onSearch = (searchValue) => {
    const valid = isValidLength(searchValue);

    if (searchValue) {
      if (valid) {
        refSearchValue.current = searchValue;
        refValue.current = [searchValue];
      } else {
        const trimValue = maxLength
          ? searchValue.slice(0, maxLength)
          : searchValue;
        refSearchValue.current = trimValue;
        refValue.current = [trimValue];
      }
    }
  };

  const getTagValueInString = (valueArray) => {
    return valueArray.join(', ');
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (refValue.current === undefined) {
          return getTagValueInString(value);
        } else {
          const procValue = getTagValueInString(refValue.current);

          refValue.current = undefined;
          return procValue;
        }
      },
    };
  });

  const width = column.getActualWidth() || 300;

  return (
    <div
      ref={refContainer}
      className={classnames('ag-editor-selection-free-text', {
        'ag-editor-selection-free-text--hide-search': value?.length > 0,
      })}
      tabIndex={1}
      style={{ width }}
    >
      <div>
        <Select
          className='ag-editor-selection-free-text__select'
          ref={refSelect}
          mode='tags'
          style={{ width: '100%' }}
          placeholder='Input or select an option'
          onChange={handleChange}
          onSearch={onSearch}
          value={value}
          disabled={disabled}
          onInputKeyDown={handleInputKeyDown}
          listHeight={250}
          searchValue={undefined}
        >
          {values?.map((valueItem, idx) => {
            const key =
              valueItemType === 'string' ? valueItem : valueItem?.value;
            const displayName =
              valueItemType === 'string' ? valueItem : valueItem?.displayName;

            return (
              <Option
                key={idx}
                value={key}
                className='ag-editor__selection-option'
              >
                <div className={`option-item`} value={key}>
                  <Text ellipsis={{ tooltip: displayName }}>{displayName}</Text>
                </div>
              </Option>
            );
          })}
        </Select>
        {maxLength && (
          <div style={{ textAlign: 'right' }}>
            <Text>
              {value?.[0]?.length || 0}/{maxLength}
            </Text>
          </div>
        )}
        <Tooltip title='Free-text selection also support custom text'>
          <FontColorsOutlined className='ag-editor-selection-free-text__hint' />
        </Tooltip>
      </div>
    </div>
  );
});
