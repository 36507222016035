import { Tooltip, Typography, Form } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';

export const LabelFormItem = ({ label, tooltip }) => {
  return (
    <>
      <Tooltip title={label}>
        <Typography.Text ellipsis strong>
          {label}
        </Typography.Text>
      </Tooltip>

      {tooltip && (
        <Tooltip title={tooltip} color='#238dd9'>
          <QuestionCircleOutlined style={{ marginLeft: 4, color: '#595959' }} />
        </Tooltip>
      )}
    </>
  );
};

export const FormItemContainer = ({
  required,
  name,
  tooltip,
  displayName,
  children,
  ...rest
}) => {
  return (
    <Form.Item
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      label={<LabelFormItem label={displayName} tooltip={tooltip} />}
      style={{ marginBottom: 8 }}
      rules={[
        {
          required,
          message: 'Please input your value!',
        },
      ]}
      name={name}
      {...rest}
    >
      {children}
    </Form.Item>
  );
};
