import produce from 'immer';
import * as types from './constants';
import _ from 'lodash';

import { mapToEmailList, mapToEmailInfo } from './mapToReducerState';

export const initialState = {
  emailThreadInfo: null,
  loading: false,

  emailThreadList: {
    loading: false,
    data: [],
    error: false,
    hasMore: false,
    loadingMore: false,
  },

  emailThreadInfoContent: null,
  emailThreadInfoFiles: [],
  error: null,
  emailActiveThreadInfo: null,
  accountMail: null,
  toggleNewMailMessage: false,
  selectedSearchResult: null,
  selectedSearchResultPosition: null,
  lastMessage: null,
  isFinishLoadDown: true,
};

const emailReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_EMAIL_THREAD_LIST_MORE:
      case types.GET_EMAIL_THREAD_LIST_MORE_FAIL:
      case types.GET_EMAIL_THREAD_LIST_MORE_SUCCESS:
        mapToEmailList(action, draft, state);
        break;

      case types.GET_EMAIL_THREAD_LIST:
        draft.emailThreadList = {
          loading: true,
          error: null,
          data: null,
          hasMore: false,
        };

        break;
      case types.GET_EMAIL_THREAD_LIST_SUCCESS:
        draft.emailThreadList = {
          loading: false,
          data: action?.payload?.gridData,
          hasMore: action?.payload?.gridData?.length === 20 ? true : false,
        };
        break;
      case types.GET_EMAIL_THREAD_LIST_FAIL:
        draft.emailThreadList = {
          loading: false,
          data: null,
        };
        break;

      case types.GET_EMAIL_THREAD_INFO:
      case types.GET_EMAIL_THREAD_INFO_FAIL:
      case types.GET_EMAIL_THREAD_INFO_SUCCESS:
        mapToEmailInfo(action, draft);
        break;
      case types.GET_EMAIL_THREAD_INFO_CONTENT:
        break;
      case types.GET_EMAIL_THREAD_INFO_CONTENT_SUCCESS:
        draft.emailThreadInfoContent = action.payload;
        break;

      case types.GET_EMAIL_THREAD_INFO_FILES:
        break;
      case types.GET_EMAIL_THREAD_INFO_FILES_SUCCESS:
        draft.emailThreadInfoFiles = action.payload;
        break;

      case types.UPDATE_EMAIL_THREAD_LIST:
        draft.emailThreadList.data = action.payload;
        break;

      case types.SET_NEW_EMAIL_MESSAGE: {
        const newThreadMsg = state?.emailThreadInfoContent?.filter(
          (msg) => msg.messageId !== action.emailInfo.messageId
        );
        draft.emailThreadInfoContent = [action.emailInfo, ...newThreadMsg];
        break;
      }

      case types.SEND_EMAIL: {
        const newThreadEmail = [
          action.payload,
          ...state.emailThreadInfoContent,
        ];
        draft.emailThreadInfoContent = newThreadEmail;
        draft.toggleNewMailMessage = true;

        break;
      }

      case types.SEND_EMAIL_SUCCESS: {
        const indexMessage = state.emailThreadInfoContent.findIndex(
          (message) => message?.uniqueId === action.payload.uniqueId
        );
        draft.emailThreadInfoContent[indexMessage].status = 'Success';
        draft.emailThreadInfoContent[indexMessage].messageId =
          action.payload.messageId;
        break;
      }

      case types.SEND_EMAIL_FAIL: {
        const indexMessage = state.emailThreadInfoContent.findIndex(
          (message) => message?.uniqueId === action.payload.uniqueId
        );
        draft.emailThreadInfoContent[indexMessage].status = 'Error';
        draft.emailThreadInfoContent[indexMessage].messageId =
          action.payload.messageId;
        break;
      }

      case types.ACTIVE_EMAIL_THREAD_INFO:
        draft.emailActiveThreadInfo = action.threadInfo;
        break;

      case types.SAVE_ACCOUNT_MAIL:
        draft.accountMail = action.payload;
        break;

      case types.TOGGLE_NEW_MAIL_MESSAGE:
        draft.toggleNewMailMessage = action.payload;
        break;
      case types.EMAIL_SELECT_SEARCH_RESULT:
        draft.selectedSearchResult = action.payload;
        break;

      case types.EMAIL_UPDATE_FINISH_LOAD_DOWN:
        draft.isFinishLoadDown = action.payload;
        break;

      case types.EMAIL_UPDATE_SEARCH_RESULT_POSITION:
        draft.selectedSearchResultPosition = action.payload;
        break;

      case types.EMAIL_UPDATE_LAST_MESSAGE:
        draft.lastMessage = action.payload;
        break;

      default:
        break;
    }
  });

export default emailReducer;
