import React, { useMemo, useRef, useState } from 'react';
import UploadFile from './UploadFile';
import { StyledModal } from 'common/components';
import { UseUploadModal } from 'hooks/useUploadModal';
import './UploadFileModal.less';

import classnames from 'classnames';

const UploadFileModal = (props) => {
  const {
    isLoading,
    bodyStyle,
    wrapperClassName = [],
    title,
    isOpen,
    width,
    onCancel,
    isUploadMultipleModal,
    onOkAction,
    isLoadingOkBtn,
    fileParamName,
    onFileChangeOutside,
    ...restProps
  } = props;

  const [fileList, setFileList] = useState(null);
  const [uploadInst] = UseUploadModal();

  const onFileChange = (fileList) => {
    setFileList(fileList);
    onFileChangeOutside && onFileChangeOutside(fileList);
  };

  const isOkDisabled = () => {
    return fileList?.length === 0 ||
      fileList?.some((fileItem) => fileItem?.status.includes('error'))
      ? true
      : false;
  };

  const uploadFileConfigProps = useMemo(() => {
    return isUploadMultipleModal
      ? {
          showList: true,
          multipleImport: true,
          showXlsTemplate: false,
          manualUpload: true,
          multiple: true,
          showSubmitBtn: false,
          onFileChange,
        }
      : {
          onFileChange,
          showSubmitBtn: false,
          showPreview: true,
          multiple: false,
          showMultiple: false,
          manualUpload: true,
        };
  }, [isUploadMultipleModal]);

  const handleUpload = () => {
    if (onOkAction) {
      onOkAction();
      return;
    } else {
      uploadInst.current.handleRequestMultiple(fileParamName);
    }
  };

  return (
    <StyledModal
      className={classnames('upload-file-modal', wrapperClassName)}
      title={title}
      visible={isOpen}
      onCancel={onCancel}
      maskClosable={false}
      width={width}
      bodyStyle={bodyStyle}
      destroyOnClose
      isLoading={isLoadingOkBtn}
      onOk={handleUpload}
      okButtonProps={{ disabled: isOkDisabled() }}
    >
      <UploadFile ref={uploadInst} {...restProps} {...uploadFileConfigProps} />
    </StyledModal>
  );
};

export default UploadFileModal;
