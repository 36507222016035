import React, { useMemo } from 'react';
import { Row, Col, Typography } from 'antd';
import { ScheduleOutlined } from '@ant-design/icons';
import ConfirmResult from './ConfirmResult';
import { DATE_FORMAT } from 'static/Constants';
import moment from 'moment';

const { Text } = Typography;

const ConfirmContent = (props) => {
  const { effDate, expDate } = props;

  const dateFormat = useMemo(() => DATE_FORMAT.SYSTEM_FORMAT, []);

  const convertEffDate = useMemo(
    () => (effDate ? moment(effDate).format(dateFormat) : null),
    [effDate, dateFormat]
  );
  const convertExpDate = useMemo(
    () => (expDate ? moment(expDate).format(dateFormat) : null),
    [expDate, dateFormat]
  );

  const importConfirmContent = useMemo(
    () => [
      {
        label: 'Effective Date',
        rerult: (
          <ConfirmResult
            value={convertEffDate}
            placeholderValue='Today'
            iconsRender={[
              {
                status: 'active',
                icon: ScheduleOutlined,
              },
              {
                status: 'inactive',
                icon: ScheduleOutlined,
              },
            ]}
            isDateRender
          />
        ),
      },
      {
        label: 'Expiration Date',
        rerult: (
          <ConfirmResult
            value={convertExpDate}
            placeholderValue='No Expiration Date'
            iconsRender={[
              {
                status: 'active',
                icon: ScheduleOutlined,
              },
              {
                status: 'inactive',
                icon: ScheduleOutlined,
              },
            ]}
            isDateRender
          />
        ),
      },
    ],
    [convertExpDate, convertEffDate]
  );

  return (
    <div className='asset-upload-confirm'>
      <div>
        <Text strong style={{ fontSize: 18 }}>
          Do you want to set effective date as a future date?
        </Text>
      </div>
      {importConfirmContent.map((rowItem) => {
        return (
          <Row>
            <Col xs={12} style={{ textAlign: 'right' }}>
              {rowItem.label}:
            </Col>
            {rowItem.rerult}
          </Row>
        );
      })}
    </div>
  );
};

export default ConfirmContent;
