import React from 'react';
import { Space } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FileExclamationOutlined,
  MailOutlined,
  MessageOutlined,
  FileImageOutlined,
  IdcardOutlined,
} from '@ant-design/icons';

const IconRenderer = (props) => {
  const { item } = props;
  return (
    <Space direction='horizontal' className='activity-tab__table--icon-columns'>
      {item &&
        item.data &&
        (item.data.isInBound ? (
          <ArrowDownOutlined className='activity-tab__table--icon-left' />
        ) : (
          <ArrowUpOutlined className='activity-tab__table--icon-left' />
        ))}
      {item &&
        item.data &&
        (item.data.type === 'DAM' ? (
          <FileImageOutlined className='activity-tab__table--icon-right' />
        ) : item.data.type === 'Email' ? (
          <MailOutlined className='activity-tab__table--icon-right' />
        ) : item.data.type === 'Request' ? (
          <MessageOutlined className='activity-tab__table--icon-right' />
        ) : item.data.type === 'Registration' ? (
          <IdcardOutlined className='activity-tab__table--icon-right' />
        ) : (
          <FileExclamationOutlined className='activity-tab__table--icon-right' />
        ))}
    </Space>
  );
};
export default IconRenderer;
