import { Empty, Space, Tag, Typography } from 'antd';

import classnames from 'classnames';

import {
  FieldsetContent,
  FieldsetTitle,
  FieldsetWrapper,
} from 'common/components';

import './GroupPart.less';

export const PartHeader = ({ title, children, ...rest }) => {
  return (
    <Space {...rest}>
      <Title>{title}</Title>
      {children}
    </Space>
  );
};

export const Title = ({ title, style, ...rest }) => {
  return (
    <Typography.Text strong style={{ textTransform: 'uppercase', ...rest }}>
      {title}
    </Typography.Text>
  );
};

export const GroupPartItem = ({ title, children }) => {
  return (
    <>
      <Title title={title} />
      {children}
    </>
  );
};

export const PartStatements = ({
  className,
  title,
  statements = [],
  ...rest
}) => {
  return (
    <GroupPartName
      title={title}
      className={classnames({
        'group-part__list': true,
        [className]: Boolean(className),
      })}
      {...rest}
    >
      <PartStatement statements={statements} />
    </GroupPartName>
  );
};

export const PartStatement = ({ statements: statementsProp, ...rest }) => {
  const statements = statementsProp ?? [];
  const isEmptyStatements = statements.length === 0;

  if (isEmptyStatements) return <EmptyGroup />;

  return (
    <Space wrap={true} size={0} {...rest}>
      {statements?.map((statement) => (
        <Tag key={statement} className='group-part__statement'>
          {statement}
        </Tag>
      ))}
    </Space>
  );
};

export const PreviewStatements = ({ statements: statementsProp, ...rest }) => {
  const statements = statementsProp ?? [];
  const isEmptyStatements = statements.length === 0;

  if (isEmptyStatements) return null;

  return (
    <Typography.Text strong style={{ textTransform: 'uppercase' }} {...rest}>
      {statements.join(', ')}
    </Typography.Text>
  );
};

// Need to style
export const GroupPartName = (props) => {
  const { children, title, ...otherProps } = props;

  return (
    <FieldsetWrapper className='group-name__wrapper'>
      {title && (
        <FieldsetTitle
          title={
            <Typography.Text
              className='group-name__title'
              ellipsis={{ tooltip: title }}
            >
              {title}
            </Typography.Text>
          }
        />
      )}
      <FieldsetContent {...otherProps}>{children}</FieldsetContent>
    </FieldsetWrapper>
  );
};

export const EmptyGroup = (props) => {
  return <Empty {...props} />;
};

GroupPartItem.Header = PartHeader;
GroupPartItem.GroupPartName = GroupPartName;
GroupPartItem.EmptyGroup = EmptyGroup;
