// Asset Data Action Types
export const GET_ASSET_DATA = 'GET_ASSET_DATA';
export const GET_ASSET_DATA_SUCCESS = 'GET_ASSET_DATA_SUCCESS';
export const GET_ASSET_DATA_ERROR = 'GET_ASSET_DATA_ERROR';
//* get ftp file list
export const GET_FTP_FILE_LIST = 'GET_FTP_FILE_LIST';
export const GET_FTP_FILE_LIST_SUCCESS = 'GET_FTP_FILE_LIST_SUCCESS';
export const GET_FTP_FILE_LIST_ERROR = 'GET_FTP_FILE_LIST_ERROR';
//* create digital asset
export const CREATE_DIGITAL_ASSET_FULL = 'CREATE_DIGITAL_ASSET_FULL';
export const CREATE_DIGITAL_ASSET_FULL_SUCCESS =
  'CREATE_DIGITAL_ASSET_FULL_SUCCESS';
export const CREATE_DIGITAL_ASSET_FULL_ERROR =
  'CREATE_DIGITAL_ASSET_FULL_ERROR';
//* edit digital asset
export const UPDATE_DIGITAL_ASSET = 'UPDATE_DIGITAL_ASSET';
export const UPDATE_DIGITAL_REPLACE_ASSET = 'UPDATE_DIGITAL_REPLACE_ASSET';
export const UPDATE_DIGITAL_ASSET_SUCCESS = 'UPDATE_DIGITAL_ASSET_SUCCESS';
export const UPDATE_REQUIRED_FIELDS = 'UPDATE_REQUIRED_FIELD';
export const UPDATE_DIGITAL_ASSET_ERROR = 'UPDATE_DIGITAL_ASSET_ERROR';
export const UPDATE_IS_ACTIVE = 'UPDATE_IS_ACTIVE';
export const GET_ASSET_ASSOCIATION_GRID_COLUMN =
  'GET_ASSET_ASSOCIATION_GRID_COLUMN';
export const GET_ASSET_ASSOCIATION_GRID_COLUMN_SUCCESS =
  'GET_ASSET_ASSOCIATION_GRID_COLUMN_SUCCESS';
export const GET_ASSET_ASSOCIATION_GRID_COLUMN_ERROR =
  'GET_ASSET_ASSOCIATION_GRID_COLUMN_ERROR';
export const CANCEL_UPDATE_SUCCESS = 'CANCEL_UPDATE_SUCCESS';
export const RELOAD_FULL_VIEW = 'RELOAD_FULL_VIEW';
export const UPDATE_ASSET_TYPE = 'UPDATE_ASSET_TYPE';
export const UPDATE_ASSET_TYPE_SUCCESS = 'UPDATE_ASSET_TYPE_SUCCESS';
export const UPDATE_ASSET_TYPE_ERROR = 'UPDATE_ASSET_TYPE_ERROR';

export const GET_AUTHORIZED_TAGS = 'GET_AUTHORIZED_TAGS';
export const GET_AUTHORIZED_TAGS_SUCCESS = 'GET_AUTHORIZED_TAGS_SUCCESS';
export const GET_AUTHORIZED_TAGS_ERROR = 'GET_AUTHORIZED_TAGS_ERROR';
