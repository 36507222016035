import React from 'react';

import {
  Breadcrumb,
  Typography,
  Popover,
  Row,
  Col,
  Button,
  Tooltip,
} from 'antd';

import {
  EllipsisOutlined,
  HomeFilled,
  HomeOutlined,
  HomeTwoTone,
} from '@ant-design/icons';

import './FolderTree.less';

/*
  Will support limit items based on container later
*/
export const FolderBreadcrumb = (props) => {
  const { items, onClick: onClickBreadcrumb, maxItems, ...rest } = props;

  const isLimited = Boolean(maxItems) && items.length > maxItems;

  const onClick = (breadcrumb) => {
    onClickBreadcrumb && onClickBreadcrumb(breadcrumb?.id, items);
  };

  if (isLimited) {
    const { overflowBreadcrumbs, otherBreadcrumbs } = separateBreadcrumbs(
      items,
      maxItems
    );

    const content = () => (
      <div
        className='scroller'
        style={{ maxHeight: 400, padding: '0 8px 0 0' }}
      >
        {overflowBreadcrumbs.map((breadcrumb) => (
          <div
            key={breadcrumb?.id}
            className='folder-breadcrumb__dropdown-overflow--path'
            onClick={() => {
              onClick(breadcrumb);
            }}
          >
            <Typography.Text ellipsis>{breadcrumb?.folderName}</Typography.Text>
          </div>
        ))}
      </div>
    );

    return (
      <Row>
        <Col>
          <div className='folder-breadcrumb__btn-overflow-wrap'>
            <FolderOverflowBreadcrumb content={content}>
              <Button
                className='folder-breadcrumb__btn-overflow'
                type='link'
                icon={<EllipsisOutlined color='#8c8c8c' />}
              />
            </FolderOverflowBreadcrumb>
            /&nbsp;
          </div>
        </Col>
        <Col style={{ flex: 1 }}>
          <Breadcrumb className='folder-breadcrumb' {...rest}>
            {otherBreadcrumbs.map((breadcrumb) => (
              <Breadcrumb.Item
                key={breadcrumb?.id}
                onClick={() => {
                  onClick(breadcrumb);
                }}
              >
                <Tooltip title={breadcrumb?.folderName}>
                  <Typography.Text>{breadcrumb?.folderName}</Typography.Text>
                </Tooltip>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
      </Row>
    );
  }

  return (
    <Breadcrumb
      className='folder-breadcrumb'
      style={{ marginTop: 8 }}
      {...rest}
    >
      {items.map((breadcrumb) => (
        <Breadcrumb.Item
          key={breadcrumb?.id}
          onClick={() => {
            onClick(breadcrumb);
          }}
        >
          <Tooltip title={breadcrumb?.folderName}>
            <Typography.Text ellipsis>
              {breadcrumb?.id === 'home' ? (
                <HomeTwoTone twoToneColor='#8c8c8c' style={{ fontSize: 20 }} />
              ) : (
                breadcrumb?.folderName
              )}
            </Typography.Text>
          </Tooltip>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export const FolderOverflowBreadcrumb = ({ children, ...rest }) => {
  return (
    <Popover
      overlayClassName='folder-breadcrumb__dropdown-overflow'
      placement='bottomLeft'
      {...rest}
    >
      {children}
    </Popover>
  );
};

const separateBreadcrumbs = (items, maxItems) => {
  const countOfItems = items.length;

  return {
    overflowBreadcrumbs: items.slice(0, countOfItems - maxItems),
    otherBreadcrumbs: items.slice(countOfItems - maxItems),
  };
};

export default FolderBreadcrumb;
