import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Space, Checkbox, Card, Avatar, Row, Tooltip } from 'antd';
import { Images } from 'config/assets';
import './ListGrid.less';

const ListGrid = (props) => {
  const { dataDetail, selected, onClickItemGrid, onClickCheckboxItem } = props;
  return (
    <Row className={selected ? 'list-grid list-grid--selected' : 'list-grid'}>
      <Tooltip title={dataDetail && dataDetail.upc12 + '- Widget #1'}>
        <Checkbox
          className='list-grid__checkbox'
          checked={selected}
          onChange={(e) => {
            if (onClickCheckboxItem) onClickCheckboxItem(dataDetail, e);
          }}
        />
        <Card
          bordered={false}
          bodyStyle={{ padding: 0 }}
          className='list-grid__card'
          onClick={(e) => {
            if (onClickItemGrid) onClickItemGrid(dataDetail, e);
          }}
        >
          <Space align='center'>
            <Avatar
              className='list-grid__image'
              shape='square'
              src={
                (dataDetail && dataDetail.imageUrl) || Images.RIVIR_LOGO_DEFAULT
              }
            />
            <Typography.Text ellipsis className='list-grid__text'>
              {dataDetail && dataDetail.upc12} - Widget #1
            </Typography.Text>
          </Space>
        </Card>
      </Tooltip>
    </Row>
  );
};

ListGrid.propTypes = {
  dataDetail: PropTypes.object,
  imageThumbnail: PropTypes.string,
  labelThumbnail: PropTypes.string,
  selected: PropTypes.bool,
  onClickItemGrid: PropTypes.func,
  onClickCheckboxItem: PropTypes.func,
};

export default React.memo(ListGrid);
