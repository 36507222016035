import React from 'react';
import DigitalRight from 'assets/DRM.png';
import { Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

const DigitalRightIcon = (props) => {
  const { height, width, size, intl, type, ...restProps } = props;
  const iconHeight =
    size === 'small'
      ? 20
      : size === 'normal'
      ? 30
      : size === 'large'
      ? 50
      : height;
  const iconWidth =
    size === 'small'
      ? 20
      : size === 'normal'
      ? 30
      : size === 'large'
      ? 50
      : width;
  return (
    <>
      <Tooltip
        title={
          type === 'Folder'
            ? intl.formatMessage({
                id: 'Taco.common.components.digitalRightFolderTitle',
              })
            : intl.formatMessage({
                id: 'Taco.common.components.digitalRightTitle',
              })
        }
        placement='bottom'
      >
        <img
          {...restProps}
          src={DigitalRight}
          alt='digital-right'
          height={iconHeight}
          width={iconWidth}
        />
      </Tooltip>
    </>
  );
};
export default injectIntl(DigitalRightIcon);
