import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Spin } from 'antd';
import { isEqual, capitalize } from 'lodash';

import { OpenItemContentPane } from 'common/components/openItem';
import FolderPanel from './panel/FolderPanel';
import { AssetPanel } from 'pages/branded-assets/components';
import MemberPanel from 'common/components/member-preview/MemberPanel';
import { QueryPanel } from 'pages/favorite-queries/components';
import { ReportingPanel } from 'pages/branded-reporting/components';
import {
  ProductContent,
  ProductActions,
  ProductInformation,
  ProductLogoThumbnail,
} from 'pages/branded-products/components/content-pane';

import { useFolderContentPane } from 'pages/folders/hooks';
import { useMemberPublicContact } from 'hooks/useMemberPublicContact';
import { mapJSONToMarkdownObject } from 'utils/jsonChecker';
import * as getLink from 'utils/common/linkBuilder';

import * as constant from 'static/Constants';

const FolderContentPane = (props) => {
  const {
    setContentPreview,
    onCloseCallbackHandler,
    onOpenEdit,
    selectFolder,
    isOwner,
    setVisible,
    clickEditFolderHandler,
    addDrawerStack,
  } = props;

  const { shortDetail, isLoading, contentPaneType } = useFolderContentPane();

  const getOpenItemConfig = () => {
    const urlPath = getLink.getLinkPathByEntityType(contentPaneType);

    const configsCollection = {
      asset: {
        type: 'ASSET_CONTENT_PANE',
        name: shortDetail?.assetName,
      },
      member: {
        type: 'MEMBER_CONTENT_PANE',
        name: shortDetail?.memberName,
      },
      product: {
        type: 'PRODUCT_CONTENT_PANE',
        name: shortDetail?.productName,
      },
      reporting: {
        type: 'REPORTING_CONTENT_PANE',
        name: shortDetail?.name,
      },
      folder: {
        type: 'FOLDER_CONTENT_PANE',
        name: shortDetail?.folderName,
      },
    };

    const config = configsCollection[contentPaneType];

    return {
      type: constant.OPEN_ITEM.TYPE[config?.type],
      mode: constant.OPEN_ITEM.MODE.VIEW,
      url: `${urlPath}/${shortDetail?.id}`,
      name:
        config?.name ||
        `${capitalize(contentPaneType)} Full View (default view)`,
    };
  };

  const renderContentPane = () => {
    if (contentPaneType === 'folder') return renderFolderPanel();
    else return renderItemPanel();
  };

  const renderFolderPanel = () => {
    return (
      <FolderPanel
        clickEditFolderHandler={clickEditFolderHandler}
        ownerFolder={(selectFolder || shortDetail) && isOwner}
        loadingDetail={isLoading}
        shortDetail={shortDetail}
        seePreview={(params) => {
          setContentPreview(params);
          addDrawerStack('contentPreview');
        }}
        onClosePanel={() => {
          setVisible(false);
        }}
        onEditClick={onOpenEdit}
      />
    );
  };

  const renderItemPanel = () => {
    const panelCollection = {
      asset: AssetItemPanel,
      member: MemberItemPanel,
      product: ProductItemPanel,
      reporting: ReportingItemPanel,
      query: QueryItemPanel,
    };

    if (!contentPaneType) return null;

    const ItemPanel = panelCollection[contentPaneType];

    return <ItemPanel shortDetail={shortDetail} />;
  };

  return (
    <OpenItemContentPane
      config={getOpenItemConfig()}
      onClickCloseBtnCallback={onCloseCallbackHandler}
      folderInfo={shortDetail}
    >
      {isLoading || !shortDetail ? (
        <div className='folder-panel__loading-container'>
          <Spin />
        </div>
      ) : (
        renderContentPane()
      )}
    </OpenItemContentPane>
  );
};

const areEqual = (prevProps, nextProps) => {
  // just update when click to edit button to change view to edit mode, that button only clickable when selected folder is editable
  if (
    (prevProps?.shortDetail?.editable === nextProps?.shortDetail?.editable) ===
    true
  ) {
    return false;
  }

  if (!isEqual(prevProps.shortDetail, nextProps.shortDetail)) {
    return false;
  }

  return true;
};

const AssetItemPanel = (props) => {
  const { shortDetail } = props;

  return <AssetPanel style={{ paddingTop: 0 }} assetData={shortDetail} />;
};

const MemberItemPanel = (props) => {
  const { shortDetail } = props;

  const { memberPublicContactList } = useMemberPublicContact({
    isEnabled: true,
    memberId: shortDetail.id,
  });

  function convertTagLine(data) {
    const parsedTagline = mapJSONToMarkdownObject(data);
    return parsedTagline?.content;
  }

  return (
    <MemberPanel
      publicContactList={memberPublicContactList}
      isPreview={false}
      memberShortDetail={shortDetail}
      mdTagline={convertTagLine(shortDetail?.alternativeCompanyTagLine)}
    />
  );
};

const ProductItemPanel = (props) => {
  const { shortDetail } = props;

  return (
    <ProductContent>
      <ProductActions id={shortDetail?.id} />
      <ProductLogoThumbnail
        id={shortDetail?.id}
        logo={shortDetail.productImage}
      />
      <ProductInformation product={shortDetail} />
    </ProductContent>
  );
};

const ReportingItemPanel = (props) => {
  const { shortDetail } = props;

  return <ReportingPanel style={{ paddingTop: 0 }} dataDetail={shortDetail} />;
};

const QueryItemPanel = (props) => {
  const { shortDetail } = props;

  return <QueryPanel style={{ paddingTop: 0 }} dataDetail={shortDetail} />;
};

export default memo(FolderContentPane, areEqual);
