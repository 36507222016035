import { Checkbox, Typography } from 'antd';
const { Text } = Typography;
/**
 * ? get all row data agrid
 * @param {*} gridInst
 * @returns
 */
export const getAllRows = (gridInst) => {
  let rowData = [];
  gridInst?.current?.api?.forEachNode((node) => rowData.push(node?.data));
  return rowData;
};

/**
 * ? remote errors from row
 * @param {*} rowItem
 * @param {*} errors
 * @returns
 */
export const removeErrorsFromRow = (rowItem, errors) => {
  const newError = (rowItem?.error || []).filter(
    (errorItem) => !errors.includes(errorItem)
  );
  return { ...rowItem, error: newError };
};

/**
 * ? add errors to row
 * @param {*} rowItem
 * @param {*} errors
 * @returns
 */
export const addErrorsToRow = (rowItem, errors) => {
  if (!errors && errors?.length <= 0) return rowItem;

  const newErrorList = errors.reduce((accErrorList, newErrorItem) => {
    if (!accErrorList.includes(newErrorItem)) {
      accErrorList.push(newErrorItem);
    }
    return accErrorList;
  }, rowItem?.error || []);

  return { ...rowItem, error: newErrorList };
};

export const getFormattedValue = (snapshotData, name, enumType) => {
  const enumFound = enumType[name]?.find(
    (item) =>
      item.value === snapshotData || item.enumDisplayName === snapshotData
  );
  return enumFound?.displayName || enumFound?.enumDisplayName;
};

export const formatCompareValue = (value) => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (Array.isArray(value)) {
    return value?.length > 0 ? value : 'N/A';
  }

  return value || 'N/A';
};

export const renderPopoverContent = (
  label,
  snapshotValue,
  supplierModifiedValue,
  formattedValue,
  type
) => {
  if (snapshotValue !== supplierModifiedValue) {
    let value =
      type === 'checkbox' ? (
        <Checkbox checked={snapshotValue} disabled />
      ) : (
        formattedValue || snapshotValue?.toString() || 'N/A'
      );
    return (
      <>
        <Text strong>{label}:</Text> {value}
      </>
    );
  }
};

export const checkSectionRequired = (requiredFields, section) => {
  if (!requiredFields?.length) return;

  const sectionPathKeyMap = {
    organoleptic:
      'qaSpecProductSpecification.qaSpecProductSpecsOrganolepticCharacteristics',
    physical:
      'qaSpecProductSpecification.qaSpecProductSpecsPhysicalAndChemicalCharacteristics',
    contaminant:
      'qaSpecProductSpecification.qaSpecProductSpecsContaminantCharacteristics',
    microbiological:
      'qaSpecProductSpecification.qaSpecProductSpecsMicrobiologicalCharacteristics',
  };

  const sectionPathKey = sectionPathKeyMap[section];

  if (!sectionPathKey) return false;

  return requiredFields.some((fieldFullPath) => {
    return fieldFullPath.includes(sectionPathKey);
  });
};
