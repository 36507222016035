import { cloneDeep } from 'lodash';
import { isNil } from 'lodash';

import { SUPPLEMENT_NUTRIENT_NAME } from 'pages/product-full-view/components/product-media-area/nutrition-label/constants';

export const addChildrenKeyForNutrientItem = (nutritionFactProperties) => {
  return nutritionFactProperties?.map((item) => ({
    ...item,
    children: [],
  }));
};

export const getSupplementMappingLevel = (arr, level = 0) => {
  return arr.map((obj) => {
    const newSupplement = { ...obj, level };
    newSupplement.children = getSupplementMappingLevel(
      newSupplement.children,
      level + 1
    );
    return newSupplement;
  });
};

export const orderNutrientList = (nutrients, SUPPLEMENT_NUTRIENTS) => {
  let nutrientsClone = cloneDeep(nutrients);
  const supplementLowerCaseList = SUPPLEMENT_NUTRIENTS.map((item) =>
    item.toLowerCase()
  );
  return nutrientsClone.sort((item1, item2) => {
    let index1 = supplementLowerCaseList.findIndex((value) =>
      item1?.name?.toLowerCase()?.includes(value)
    );
    let index2 = supplementLowerCaseList.findIndex((value) =>
      item2?.name?.toLowerCase()?.includes(value)
    );

    if (index1 === -1) return 1;
    if (index2 === -1) return -1;

    return index1 - index2;
  });
};

export const roundToTwoDecimals = (number) => {
  if (isNil(number)) return null;
  return Math.round(number * 100) / 100;
};

export const renderDailyValue = (data, isSupplementWithoutDailyValue) => {
  if (isSupplementWithoutDailyValue) return data?.dailySymbol;

  if (!isNil(data?.dailyValue)) {
    const dailyValue = !isNaN(data?.dailyValue) ? data?.dailyValue : 0;

    return data.dailySymbol
      ? `${Math.trunc(dailyValue)}%${data?.dailySymbol}`
      : `${Math.trunc(dailyValue)}%`;
  }

  return data?.dailySymbol;
};

export const renderName = (name, italicName) => {
  if (italicName) return <i>{name}</i>;
  return name;
};

const addParenthesesIfNeeded = (descriptor) => {
  if (!descriptor) return '';
  const lastCharacter = descriptor[descriptor?.length - 1];
  if (!descriptor.startsWith('(') || !lastCharacter.endsWith(')')) {
    return `(${descriptor})`;
  }
  return descriptor;
};

export const renderDescription = (descriptor, italicDescriptor) => {
  if (!descriptor) return '';

  const descFormatted = addParenthesesIfNeeded(descriptor);

  if (italicDescriptor) return <i>{descFormatted}</i>;

  return descFormatted;
};

export const getNameFieldSupplement = (type) => {
  return type === SUPPLEMENT_NUTRIENT_NAME.NUTRIENT
    ? 'nutritionName'
    : 'ingredientName';
};

export const getItalicNameFieldSupplement = (type) => {
  return type === SUPPLEMENT_NUTRIENT_NAME.NUTRIENT
    ? 'italicNutrient'
    : 'italicDietary';
};

export const getNameAndItalicizeNameSupplement = (data, type) => {
  const nameKey = getNameFieldSupplement(type);

  const italicNameKey = getItalicNameFieldSupplement(type);
  return {
    name: data?.[nameKey],
    italicName: data?.[italicNameKey],
  };
};

export const groupDataByPartOf = (data, type) => {
  const dataMappingList = addChildrenKeyForNutrientItem(data);
  dataMappingList.forEach((item) => {
    if (item?.ingredientsPartOf) {
      const object = dataMappingList.find((data) => {
        const { name } = getNameAndItalicizeNameSupplement(data, type);
        return name === item?.ingredientsPartOf;
      });
      object && object.children.push(item);
    }
  });

  return dataMappingList.filter((item) => {
    return !item?.ingredientsPartOf;
  });
};
