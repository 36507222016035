import React from 'react';
import { Row, Col, Form, Tooltip, Typography } from 'antd';

import ValueFormItemInput from './ValueFormItemInput';

import { set } from 'lodash';

const ManualFillingNoLoop = (props) => {
  const { noLoopManualDataPointList, entityData, formInstance } = props;

  const firstEntityId = entityData?.[0]?.entityId;
  const entityId = firstEntityId;

  const onChangeValue = (dataPointId, value) => {
    let nextFormValues = formInstance.getFieldsValue();

    entityData.forEach((entityDataItem) => {
      if (
        nextFormValues?.[entityDataItem?.entityId]?.[dataPointId]?.lock === true
      ) {
        set(
          nextFormValues,
          [`${entityDataItem?.entityId}`, `${dataPointId}`, 'value'],
          value
        );
      }
    });

    formInstance.setFieldsValue(nextFormValues);
  };

  return (
    <>
      {noLoopManualDataPointList.length > 0 &&
        noLoopManualDataPointList.map((field) => {
          return (
            <Row justify='center' align='middle' style={{ marginTop: 12 }}>
              <Col span={22} key={field.dataPointId}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    currentValues?.[entityId]?.[field.dataPointId]?.lock !==
                      prevValues?.[entityId]?.[field.dataPointId]?.lock ||
                    currentValues?.[entityId]?.[field.dataPointId]?.value !==
                      prevValues?.[entityId]?.[field.dataPointId]?.value
                  }
                >
                  {({ getFieldValue }) => {
                    const isLock = getFieldValue([
                      `${entityId}`,
                      `${field.dataPointId}`,
                      'lock',
                    ]);

                    return (
                      <Form.Item
                        label={
                          <Tooltip title={field.alias}>
                            <Typography.Text strong ellipsis>
                              {field.alias}
                            </Typography.Text>
                          </Tooltip>
                        }
                        name={[`${entityId}`, `${field.dataPointId}`, 'value']}
                        rules={[
                          {
                            required: Boolean(field.required),
                            message: `${field.alias} cannot be empty!`,
                          },
                        ]}
                      >
                        <ValueFormItemInput
                          onChangeValue={onChangeValue}
                          isLock={isLock}
                          dataPointId={field.dataPointId}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col xs={2} />
            </Row>
          );
        })}
    </>
  );
};

export default ManualFillingNoLoop;
