import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { notification, Row, Col } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';

import DialBtnWrap from '../button/DialBtnWrap';

import useHubFilter from 'hooks/useHubFilter';

import userSelectors from 'redux/user/selectors';
import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';

import soundSrc from 'assets/sound/ringsound_1.mp3';

import * as videoCallServices from 'services/videoCallServices';
import * as servicesChat from 'services/chatServices';

import './ReceiverRington.less';

const Context = React.createContext({ name: 'Default' });

const ReceiverRington = (props) => {
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const refNotifies = useRef([]);
  const refWarns = useRef([]);

  const refRing = useRef();

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());
  const videoCall = useSelector(globalSelectors.selectVideoCall());

  const { currentCall } = videoCall;

  const [newSignal] = useHubFilter();

  const removeRefNotify = (removeKey) => {
    refNotifies.current = refNotifies.current.filter(
      (notifyItem) => removeKey !== notifyItem.key
    );
  };

  const closeNotify = (key) => {
    if (!key) return;
    notification.close(key);
    removeRefNotify(key);
  };

  const acceptCall = (threadId, key, threadItem) => {
    videoCallServices.acceptVideoMeeting({ threadId }).then((response) => {
      if (response.isSuccess) {
        dispatch(
          globalActions.toggleVideoCall({
            status: true,
            currentCall: response?.data,
            threadItem,
          })
        );
        dispatch(globalActions.toggleIsReceivingCall(false));
        dispatch(globalActions.toggleIsCallAccepted(true));
      }
    });
    refRing.current && refRing.current.pause();
    key && closeNotify(key);
  };

  const rejectCall = (threadId, key, isOnRing) => {
    videoCallServices.rejectVideoMeeting({ threadId });
    key && closeNotify(key);
    if (!isOnRing) {
      refRing.current && refRing.current.pause();
      dispatch(globalActions.toggleIsReceivingCall(false));
    }
  };

  const openNotification = useCallback((threadId, senderInfo, userId) => {
    const newKey = 'call-receive-notify-' + uuidv4().toString();

    const threadItem = { threadId, userId };

    refNotifies.current = [{ key: newKey, threadId }, ...refNotifies.current];

    api.success({
      key: newKey,
      message: `${senderInfo?.name} is calling`,
      duration: 41,
      icon: <VideoCameraOutlined style={{ color: '#6ab04c' }} />,
      closeIcon: () => null,
      onClose: () => {
        refRing.current && refRing.current.pause();
      },
      description: (
        <Context.Consumer>
          {({ name }) => (
            <div className=''>
              <Row gutter={[10, 10]}>
                <Col>
                  <DialBtnWrap
                    type='accept'
                    onClick={() => acceptCall(threadId, newKey, threadItem)}
                  >
                    Accept
                  </DialBtnWrap>
                </Col>
                <Col>
                  <DialBtnWrap
                    type='reject'
                    onClick={() => rejectCall(threadId, newKey)}
                  >
                    Reject
                  </DialBtnWrap>
                </Col>
              </Row>
            </div>
          )}
        </Context.Consumer>
      ),
    });
  }, []);

  const openBusyNotification = useCallback((threadId, senderInfo) => {
    const newKey = 'call-receive-notify-' + uuidv4().toString();

    refWarns.current = [newKey, ...refWarns.current];

    setTimeout(() => {
      rejectCall(threadId, newKey);
    }, 1000);

    api.warning({
      key: newKey,
      message: `${senderInfo?.name} tried to call you`,
      icon: <VideoCameraOutlined style={{ color: '#6ab04c' }} />,
      // onClose: () => rejectCall(threadId, newKey),
    });
  }, []);

  const handleGetReviceCall = (newSignal, userToken) => {
    if (newSignal?.signalType !== 'CallReceived') return;

    //* not in meeting
    if (
      newSignal?.threadId !== currentCall?.roomId &&
      !currentCall?.roomId &&
      userToken.findIndex((user) => newSignal?.senderId === user) === -1
    ) {
      if (refNotifies.current?.length === 0) {
        servicesChat
          .getChatUsersInfo([newSignal?.senderId])
          .then((res) => {
            if (res.isSuccess) {
              const senderInfo = res.data.senders[0];
              setTimeout(() => {
                openNotification(
                  newSignal?.threadId,
                  senderInfo,
                  newSignal?.receiverId
                );
                refRing.current.loop = true;
                refRing.current.play();
                dispatch(globalActions.toggleIsReceivingCall(true));
              }, 2000);
            }
          })
          .catch((err) => console.log('err ', err));
      } else {
        rejectCall(newSignal?.threadId, null, true);
      }
      return;
    }

    //* in meeting but different call
    if (
      newSignal?.threadId !== currentCall?.roomId &&
      currentCall?.roomId &&
      userToken.findIndex((user) => newSignal?.senderId === user) === -1
    ) {
      if (refNotifies.current?.length === 0) {
        servicesChat
          .getChatUsersInfo([newSignal?.senderId])
          .then((res) => {
            if (res.isSuccess) {
              const senderInfo = res.data.senders[0];
              setTimeout(() => {
                openBusyNotification(newSignal?.threadId, senderInfo);
              }, 2000);
            }
          })
          .catch((err) => console.log('err ', err));
      } else {
        rejectCall(newSignal?.threadId, null, true);
      }
      return;
    }

    //* in meeting but same call
    if (
      newSignal?.threadId === currentCall?.roomId &&
      userToken.findIndex((user) => newSignal?.senderId === user) === -1
    ) {
      acceptCall(newSignal?.threadId);
      return;
    }
  };

  const handleCallerEndOnDailing = (newSignal, userToken) => {
    if (newSignal?.signalType === 'CallEnded') {
      if (
        userToken.findIndex((user) => newSignal?.senderId === user) === -1 &&
        newSignal?.threadId === refNotifies.current[0]?.threadId
      ) {
        closeNotify(refNotifies.current[0]?.key);
        dispatch(globalActions.toggleIsReceivingCall(false));
        refRing.current && refRing.current.pause();
      }
    }
  };

  useEffect(() => {
    const userToken = [`U-${userInfo?.id}`, `C-${userInfo?.member?.id}`];
    handleGetReviceCall(newSignal, userToken);
    handleCallerEndOnDailing(newSignal, userToken);
  }, [newSignal, userInfo]);

  return (
    <>
      <Context.Provider value={{ calller: 'Ant Design' }}>
        {contextHolder}
      </Context.Provider>
      <audio ref={refRing} src={soundSrc} />
    </>
  );
};

export default ReceiverRington;
