import React, { useState, useRef, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { Menu, Space } from 'antd';
import {
  TableOutlined,
  ControlOutlined,
  CheckCircleOutlined,
  FileDoneOutlined,
  ClearOutlined,
} from '@ant-design/icons';

import {
  ButtonSmallIcon,
  StyledModal,
  AgGrid,
  FormAddButton,
  FormDeleteButton,
  FormEditButton,
  CustomNotification,
  dialogFunction,
} from 'common/components';

import GridConfiguration from './GridConfiguration';
import ClearApplyGridConfig from './ClearApplyGridConfig ';

import * as gridEndpoints from 'services/grid/endpoints';
import {
  deleteGridConfig,
  setDefaultGridConfig,
  saveGridConfig as saveGridConfigService,
} from 'services/grid';

import {
  saveGridConfig,
  applyGridConfig,
} from 'pages/branded-products/controllers/actions';

import { formatMDYWithParam } from 'utils/formatDate';

import { useApplyPreviewGridConfig } from 'pages/home/ribbon/components/sections/customize-grid';

import { useGetListGridConfigs } from './hooks';

import { sleep } from 'utils/delay';

import './CustomizeGrid.less';

const { SubMenu } = Menu;

const CustomizeGridMenu = () => {
  const [modalCustomizedGrid, setModalCustomizedGrid] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);

  const [reloadGrid, setReloadGrid] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);

  const { configs, fetchListGridConfigs } = useGetListGridConfigs({
    isEnabled: false,
  });

  const defaultConfig = useMemo(() => {
    return configs.find((config) => Boolean(config.isDefault));
  }, [configs]);

  const gridApiRef = useRef(null);

  const handleReloadGrid = () => {
    setSelectedConfig(null);
    setReloadGrid(true);
    setSelectedProperties([]);
    gridApiRef.current && gridApiRef.current.deselectAll();

    // reset back to default, use to for the next edition
    setTimeout(() => {
      setReloadGrid(false);
    }, 1000);
  };

  const onOpenChange = (openKeys) => {
    if (openKeys.includes('gridConfig')) {
      fetchListGridConfigs();
    }
  };

  return (
    <>
      <Menu
        className='customize-grid__menu'
        mode='vertical'
        onOpenChange={onOpenChange}
      >
        <SubMenu
          key='gridConfig'
          title={
            <ButtonSmallIcon
              icon={<TableOutlined />}
              label='Customize Grid'
              className='grid-config__button'
            />
          }
        >
          <Menu.Item
            key='customizeConfig'
            style={{ backgroundColor: 'inherit', padding: 0 }}
          >
            <CustomizeGrid
              selectedConfig={selectedConfig}
              visible={modalCustomizedGrid}
              setSelectedConfig={setSelectedConfig}
              setVisible={setModalCustomizedGrid}
              onReloadGrid={handleReloadGrid}
            />
          </Menu.Item>

          <Menu.Item
            key='configManager'
            style={{ backgroundColor: 'inherit', padding: 0 }}
          >
            <GridConfigManager
              setSelectedConfig={setSelectedConfig}
              onOpenCustomizedModal={() => setModalCustomizedGrid(true)}
              setReloadGrid={setReloadGrid}
              reloadGrid={reloadGrid}
              gridApiRef={gridApiRef}
              selectedProperties={selectedProperties}
              setSelectedProperties={setSelectedProperties}
              defaultConfig={defaultConfig}
              fetchListGridConfigs={fetchListGridConfigs}
              handleReloadGrid={handleReloadGrid}
            />
          </Menu.Item>

          <ClearApplyGridConfig defaultConfig={defaultConfig} />
        </SubMenu>
      </Menu>
    </>
  );
};

const CustomizeGrid = ({
  selectedConfig,
  setSelectedConfig,
  visible,
  setVisible,
  onReloadGrid,
}) => {
  const modalProps = {
    title: 'Customize Product Grid',
    wrapClassName: 'grid-configuration',
    visible,
    onCancel: () => {
      setVisible(false);
      setSelectedConfig(null);
    },
    width: '86vw',
    bodyStyle: {
      height: 700,
      maxHeight: '75vh',
      display: 'flex',
      flexDirection: 'column',
    },
    maskClosable: false,
    footer: null,
    destroyOnClose: true,
  };

  return (
    <>
      <ButtonSmallIcon
        icon={<TableOutlined />}
        label='Customize Configuration'
        onClick={() => setVisible(true)}
      />

      {visible && (
        <StyledModal {...modalProps}>
          <GridConfiguration
            selectedConfig={selectedConfig}
            visible={visible}
            onCancel={() => setVisible(false)}
            onReloadGrid={onReloadGrid}
          />
        </StyledModal>
      )}
    </>
  );
};

const defaultColumns = [
  {
    allowFilter: false,
    allowSort: false,
    width: 170,
    dataType: 'number',
    displayName: 'Config Id',
    fieldName: 'configId',
    fieldNameCamelCase: 'configId',
  },
  {
    allowFilter: false,
    allowSort: false,
    dataType: 'string',
    displayName: 'Config Name',
    fieldName: 'configName',
    fieldNameCamelCase: 'configName',
    resizable: true,
    flex: 1,
    suppressMenu: true,
  },
  {
    allowFilter: false,
    allowSort: false,
    dataType: 'boolean',
    displayName: 'Default Grid',
    fieldName: 'isDefault',
    fieldNameCamelCase: 'isDefault',
    resizable: true,
    suppressMenu: true,
  },
  {
    allowFilter: false,
    allowSort: false,
    dataType: 'dateTime',
    displayName: 'Last Modified',
    fieldName: 'lastModified',
    fieldNameCamelCase: 'lastModified',
    cellRenderer: formatMDYWithParam,
    resizable: true,
    suppressMenu: true,
  },
];

const GridConfigManager = (props) => {
  const {
    setSelectedConfig,
    onOpenCustomizedModal,
    reloadGrid,
    setReloadGrid,
    gridApiRef,
    selectedProperties,
    setSelectedProperties,
    defaultConfig,
    fetchListGridConfigs,
    handleReloadGrid,
  } = props;

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [statusAction, setStatusAction] = useState('idle');

  const { isPreviewGridConfigAcive } = useApplyPreviewGridConfig();

  const modalProps = {
    title: 'Configuration Manager',
    wrapClassName: 'config-manager',
    visible,
    onCancel: () => setVisible(false),
    width: '80vw',
    bodyStyle: {
      height: '80vh',
    },
    maskClosable: false,
    footer: null,
  };

  const handleSelectedRow = () => {
    const gridApi = gridApiRef.current;
    const data = gridApi?.getSelectedNodes()?.map((row) => row.data);
    setSelectedProperties(data);
  };

  const handleEditConfig = () => {
    setSelectedConfig(selectedProperties?.[0]);
    onOpenCustomizedModal();
  };

  const handleDeleteGridConfig = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to delete the selected config?',
      onOk: async () => {
        setStatusAction('loading');

        try {
          const params = {
            configId: selectedProperties?.[0]?.configId,
          };
          const response = await deleteGridConfig(params);

          if (response?.isSuccess) {
            CustomNotification.success(
              'Delete the configuration successfully!'
            );

            if (selectedProperties?.[0]?.isDefault) {
              fetchListGridConfigs();
              if (!isPreviewGridConfigAcive) {
                dispatch(
                  saveGridConfig({
                    jsonConfig: null,
                    selectedColumns: null,
                    isHaveDefautConfig: false,
                  })
                );
              }
            }

            setStatusAction('success');
            setReloadGrid(true);
          } else {
            CustomNotification.error(
              response?.message ?? 'Something went wrong!'
            );
            setStatusAction('error');
          }
        } catch (err) {
        } finally {
          setTimeout(() => {
            setStatusAction('idle');
            setReloadGrid(false);
            setSelectedProperties([]);
          }, 300);
        }
      },
    });
  };

  const handleSetDefaultConfig = async () => {
    try {
      const params = {
        configId: selectedProperties?.[0]?.configId,
        gridName: 'product-detail-grid',
      };
      const response = await setDefaultGridConfig(params);

      if (response?.isSuccess) {
        CustomNotification.success(
          'Set default the configuration successfully!'
        );

        fetchListGridConfigs();

        setVisible(false);
        if (!isPreviewGridConfigAcive) {
          dispatch(
            saveGridConfig({
              jsonConfig: selectedProperties[0].jsonConfig,
              selectedColumns: selectedProperties[0].selectedColumns,
              isHaveDefaultConfig: true,
            })
          );
        }
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong!');
        setStatusAction('error');
      }
    } catch (err) {
    } finally {
      setTimeout(() => {
        setReloadGrid(false);
        setSelectedProperties([]);
      }, 300);
    }
  };

  const handleApplyConfig = async () => {
    const firstSelectedConfig = selectedProperties?.[0] || {};

    dispatch(
      applyGridConfig({
        ...firstSelectedConfig,
        isApply: true,
      })
    );
    setVisible(false);
    setTimeout(() => {
      setReloadGrid(false);
      setSelectedProperties([]);
    }, 300);
  };

  const handleClearDefaultGridConfig = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure to clear the default grid config?',
      onOk: async () => {
        const params = {
          ...defaultConfig,
          isDefault: false,
        };

        try {
          const response = await saveGridConfigService(params);

          if (response?.isSuccess) {
            handleReloadGrid();
            fetchListGridConfigs();
            setVisible(false);

            CustomNotification.success('Clear configuration successfully!');
            if (!isPreviewGridConfigAcive) {
              dispatch(
                saveGridConfig({
                  jsonConfig: null,
                  selectedColumns: null,
                  isHaveDefaultConfig: false,
                })
              );
            } else {
              dispatch(
                saveGridConfig({
                  isHaveDefaultConfig: false,
                })
              );
            }
          } else {
            CustomNotification.error(
              response?.message ?? 'Something went wrong!'
            );
          }
        } catch (error) {}
      },
    });
  };

  const disabledBtn = selectedProperties.length !== 1;

  return (
    <>
      <ButtonSmallIcon
        icon={<ControlOutlined />}
        label='Configuration Manager'
        onClick={() => setVisible(true)}
      />

      {visible && (
        <StyledModal {...modalProps}>
          <Space
            style={{
              marginBottom: 6,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <FormAddButton
              text='Apply'
              disabled={disabledBtn}
              icon={<FileDoneOutlined />}
              onClick={handleApplyConfig}
            />
            <FormAddButton
              onClick={() => {
                onOpenCustomizedModal();
                setSelectedConfig(null);
              }}
            />

            <FormEditButton disabled={disabledBtn} onClick={handleEditConfig} />

            <FormDeleteButton
              disabled={disabledBtn}
              onClick={handleDeleteGridConfig}
              loading={statusAction === 'loading'}
            />

            <FormAddButton
              type='default'
              text='Set Default'
              disabled={disabledDefaultButton(selectedProperties)}
              icon={<CheckCircleOutlined />}
              onClick={handleSetDefaultConfig}
            />
            <FormDeleteButton
              danger
              text='Clear Default Grid Config'
              disabled={!defaultConfig}
              icon={<ClearOutlined />}
              onClick={handleClearDefaultGridConfig}
            />
          </Space>

          <div
            id='my-grid-link-asset'
            className='ag-theme-alpine'
            style={{ height: '92%' }}
          >
            <AgGrid
              columnDefs={defaultColumns}
              urlGrid={gridEndpoints.GET_LIST_GRID_CONFIGS}
              paramsGrid={{ gridName: 'product-detail-grid' }}
              responseParams='data'
              nodeIdName='configId'
              callbackDataListSelection={handleSelectedRow}
              gridConfigProps={{
                rowSelection: 'single',
              }}
              notShowHeaderCheckbox={true}
              showCheckboxSelectionRender={false}
              isHiddenAbbandonDialog={true}
              mapId={(item) => ({
                ...item,
                id: `${item?.configId}`,
              })}
              getGridApi={(api) => {
                gridApiRef.current = api;
              }}
              reloadGrid={reloadGrid}
            />
          </div>
        </StyledModal>
      )}
    </>
  );
};

const disabledDefaultButton = (selectedProperties = []) => {
  if (selectedProperties.length !== 1) return true;

  return selectedProperties?.[0]?.isDefault;
};

export default CustomizeGridMenu;
