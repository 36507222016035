import React from 'react';
import { useDispatch } from 'react-redux';
import Messages from 'i18n/messages/maintenance';
import { FolderAddOutlined } from '@ant-design/icons';
import { forwardTo } from 'utils/common/route';
import RibbonButton from 'common/components/button/RibbonButton';

import * as eulaActions from 'pages/eula-maintenance/controllers/actions';

const CreateEula = () => {
  const dispatch = useDispatch();

  const handleCreateEula = () => {
    dispatch(eulaActions.enableCreateEula());
    forwardTo(`/maintenance/eula/create`);
  };

  return (
    <RibbonButton
      size='small'
      icon={<FolderAddOutlined />}
      label={Messages.createEula}
      onClick={handleCreateEula}
    />
  );
};

export default CreateEula;
