import React, { useEffect, useState } from 'react';


import { Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { EntityList } from 'common/components/list';
import { SearchGridItemPagination } from 'common/components/modal/search-entity-modal/components';
import { MemberThumbnailCard } from 'pages/branded-members/components';
import SupplierSearchBar from './SupplierSearchBar';
import SupplierManagerHintMessage from './SupplierManagerHintMessage';

import { mappingSelectedItems } from 'utils/brokerManagements';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/supplier-manager';
import { useGetSupplierGrid } from '../hooks/useSupplierManager';

const DEFAULT_PAGE_INDEX = 1;

const SupplierGrid = ({
  selectedSupplier,
  handleUpdateSelectedItems,
  isPIMAction,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();


  const [pageInfo, setPageInfo] = useState();
  const [searchText, setSearchText] = useState('');
  const {
    supplierList,
    paging,
    loading,
    fetchSupplierList,
    showDefaultText,
  } = useGetSupplierGrid({ isPIMAction });

  const getFilterSupplier = (searchText = '') => {
    return [
      {
        fieldName: 'memberName',
        filterType: 'like',
        value: searchText,
      },
    ];
  };

  const onChangePagination = (page) => {
    fetchSupplierList({
      pageIndex: page,
      searchText,
      filters: getFilterSupplier(searchText),
    });
  };

  const onSearch = (value) => {
    setSearchText(value);
    fetchSupplierList({
      pageIndex: DEFAULT_PAGE_INDEX,
      searchText: value,
      filters: getFilterSupplier(value),
    });
  };

  const onReset = () => {
    form.resetFields();
    setSearchText('');
    fetchSupplierList({
      pageIndex: DEFAULT_PAGE_INDEX,
      searchText: '',
      filters: [],
    });
  };

  const mappingSupplierList = mappingSelectedItems(
    [selectedSupplier],
    supplierList ?? []
  );

  useEffect(() => {
    if (paging) setPageInfo(paging);
  }, [JSON.stringify(paging)]);

  return (
    <>
      <div style={{ marginBottom: 4, paddingLeft: 16 }}>
        <SupplierSearchBar
          form={form}
          onReset={onReset}
          onSearch={onSearch}
          label={intl.formatMessage(Messages.searchSupplierLabel)}
        />
      </div>
      {showDefaultText ? (
        <div style={{ height: 'calc(100% - 32px)' }}>
          <SupplierManagerHintMessage>
            <SearchOutlined /> {intl.formatMessage(Messages.supplierGridHint)}
          </SupplierManagerHintMessage>
        </div>
      ) : (
        <>
          <div style={{ height: 'calc(100% - 60px)', paddingLeft: 8 }}>
            <EntityList
              loading={loading}
              entityList={mappingSupplierList}
              col={4}
              classNameObj={{ 'scroller--y': true }}
            >
              {(entityItem) => {
                return (
                  <MemberThumbnailCard
                    linkTarget='_blank'
                    hideCheckbox
                    isShareBtn={false}
                    dataDetail={entityItem}
                    selected={entityItem.selected}
                    onClickItemGrid={handleUpdateSelectedItems}
                  />
                );
              }}
            </EntityList>
          </div>
          <div style={{ paddingLeft: 16 }}>
            <SearchGridItemPagination
              loading={loading}
              pageInfo={pageInfo}
              onChangePagination={onChangePagination}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SupplierGrid;
