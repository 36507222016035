import React, { useEffect, useRef, useState } from 'react';
import { MinusCircleOutlined, CaretDownOutlined } from '@ant-design/icons';
import {
  Row,
  Button,
  Select,
  Typography,
  Avatar,
  Tooltip,
  Col,
  TreeSelect,
} from 'antd';

import { WrapperSelect } from 'common/components';
import ModalUploadAdvancedFile from 'common/components/advanced-search/ModalUploadAdvancedFile';
import { AvatarPropertiesItem } from 'common/components/mapping-properties/MappingSourceProperties';

import classnames from 'classnames';
import RenderValue from './RenderValue';
import RenderValueIn from './RenderValueIn';
import RenderValueBetween from './RenderValueBetween';
import RenderBtnUploadFile from './RenderBtnUploadFile';
import { Draggable } from 'react-beautiful-dnd';
import SwitchConjunction from './SwitchConjunction';
import { useWindowSize } from 'hooks/windowSize';
import { OPERATOR } from 'common/components/grid-view/components/content-pane/advancedFilter/utils';
import { AddQueryButton, RenderStrictMode } from './RenderRootTitle';

const QueryDraggable = (props) => {
  const {
    index,
    property,
    viewMode,
    draggableId,
    propertyEnums,
    addQueryCondition,
    handleConjunction,
    queryConditionsId,
    selectTreeData,
    handleQueryCondition,
    handleQueryDateBetween,
    handleRemoveQueryConditions,
    handleUpdateQueryConditionFile,
  } = props;

  const { valuePath } = property;

  const [visibleModal, setVisibleModal] = useState(false);
  const [possibleOperator, setPossibleOperator] = useState([]);
  const [acceptableValueSelect, setAcceptableValueSelect] = useState([]);
  const [queryConditionValue, setQueryConditionValue] = useState();

  const [screenWidth, screenHeight] = useWindowSize();

  const handleOpenModalUploadFile = () => {
    setVisibleModal(true);
  };

  const handleChangeOperator = (value) => {
    setQueryConditionValue(value);
    handleQueryCondition(valuePath, value, 'operator');
  };

  const handleUpdateFile = (value, valuePath, key) => {
    setPossibleOperator(['In', 'Not In']);
    setAcceptableValueSelect(value);
    setQueryConditionValue('In');

    const uploadFile = [
      { value: 'In', field: 'operator' },
      { value: value, field: key },
      { value: value, field: 'acceptableValueTemp' },
    ];

    handleUpdateQueryConditionFile({
      path: valuePath,
      values: uploadFile,
    });
  };

  const value =
    property?.operator ||
    (property?.possibleOperator?.length && property?.possibleOperator[0]);

  const handleRemoveUploadFile = () => {
    const defaultOperator = property?.possibleOperator[0];

    handleInitQuery();
    setQueryConditionValue(defaultOperator);
    setPossibleOperator(property?.possibleOperator ?? []);

    const uploadFile = [
      { value: defaultOperator, field: 'operator' },
      { value: undefined, field: 'values' },
      { value: undefined, field: 'acceptableValueTemp' },
    ];

    handleUpdateQueryConditionFile({
      path: valuePath,
      values: uploadFile,
    });
  };

  const handleInitQuery = () => {
    const acceptableValue = [...(property?.acceptableValue ?? [])];
    setQueryConditionValue(value);
    setAcceptableValueSelect(acceptableValue);
    if (property?.acceptableValueTemp?.length > 0) {
      setPossibleOperator(['In', 'Not In']);
    } else {
      setPossibleOperator(property?.possibleOperator ?? []);
    }
  };

  const onChange = (newValue) => {
    const { valuePath } = property;

    const resetValue = [
      { value: newValue, field: 'fieldName' },
      { value: null, field: 'value' },
      { value: null, field: 'operator' },
      { value: null, field: 'otherValue' },
      { value: null, field: 'values' },
      { value: null, field: 'acceptableValueTemp' },
    ];

    handleUpdateQueryConditionFile({ path: valuePath, values: resetValue });
  };

  useEffect(() => {
    handleInitQuery();
  }, [property]);

  const mappingOperator =
    possibleOperator?.map((item) => {
      return {
        value: item,
        displayName: OPERATOR[item] || item,
      };
    }) || [];

  const parentPath =
    valuePath?.length > 2
      ? valuePath?.slice(0, valuePath?.length - 2)
      : valuePath?.slice(0, valuePath?.length - 1);

  return (
    <>
      <Draggable draggableId={draggableId} index={index} isDragDisabled>
        {(provided, snapshot) => {
          const backgroundColor = () => {
            if (snapshot.combineTargetFor) {
              return '#69c0ff';
            } else {
              return;
            }
          };

          return (
            <>
              {
                <div className='query-condition-draggable'>
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classnames({
                      'query-condition-draggable__card': true,
                      'query-condition-draggable__card-query': true,
                    })}
                    style={{
                      backgroundColor: backgroundColor(),
                      pointerEvents: viewMode ? 'none' : 'auto',
                      cursor: 'default',
                    }}
                  >
                    {property?.isParent ? (
                      <div style={{ display: 'flex' }}>
                        <SwitchConjunction
                          viewMode={viewMode}
                          property={property}
                          handleConjunction={handleConjunction}
                        />
                        <RenderStrictMode
                          handleQueryCondition={handleQueryCondition}
                          queryConditions={property}
                        />
                        <div
                          className='query-condition-draggable__icon'
                          style={{ marginLeft: 'auto' }}
                        >
                          <AddQueryButton
                            property={{
                              ...property,
                              valuePath: property?.valuePath,
                            }}
                            addQueryCondition={addQueryCondition}
                          />
                        </div>
                        <Tooltip
                          className='query-condition-draggable__icon'
                          title='Remove query'
                        >
                          <Button
                            type='text'
                            icon={<MinusCircleOutlined />}
                            onClick={() => {
                              handleRemoveQueryConditions(property?.valuePath);
                            }}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <Row style={{ position: 'relative' }}>
                        <Col span={12} style={{ display: 'flex' }}>
                          <DisplayName
                            text='Select query conditions'
                            onChange={onChange}
                            property={property}
                            selectTreeData={selectTreeData}
                          />
                        </Col>

                        <Col
                          span={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems:
                              property?.acceptableValueTemp?.length > 100000
                                ? 'flex-start'
                                : 'center',
                          }}
                        >
                          {property?.fieldName ? (
                            <>
                              <WrapperSelect
                                className='query-condition-draggable__select'
                                size='small'
                                getPopupContainer={() =>
                                  document.getElementById(queryConditionsId)
                                }
                                placeholder='Please select value'
                                optionFilterProp='children'
                                listHeight={screenHeight <= 768 ? 70 : 150}
                                value={queryConditionValue}
                                onChange={(value) => {
                                  handleChangeOperator(value);
                                }}
                                filterOption={(input, option) =>
                                  option.children
                                    ?.toLowerCase()
                                    .indexOf(input?.toLowerCase()) >= 0
                                }
                                allowClear={false}
                                bordered={false}
                              >
                                {mappingOperator.map((item, index) => {
                                  return (
                                    <Select.Option
                                      key={index}
                                      value={item?.value}
                                    >
                                      <Tooltip
                                        title={item?.value}
                                        arrowPointAtCenter
                                      >
                                        {item?.displayName}
                                      </Tooltip>
                                    </Select.Option>
                                  );
                                })}
                              </WrapperSelect>
                              {queryConditionValue === 'Between' && (
                                <RenderValueBetween
                                  property={property}
                                  propertyEnums={propertyEnums}
                                  acceptableValueSelect={acceptableValueSelect}
                                  queryConditionsId={queryConditionsId}
                                  handleQueryCondition={handleQueryCondition}
                                  handleQueryDateBetween={
                                    handleQueryDateBetween
                                  }
                                />
                              )}
                              {(queryConditionValue === 'In' ||
                                queryConditionValue === 'Not In') && (
                                <RenderValueIn
                                  property={property}
                                  propertyEnums={propertyEnums}
                                  acceptableValueSelect={acceptableValueSelect}
                                  handleQueryCondition={handleQueryCondition}
                                  queryConditionsId={queryConditionsId}
                                />
                              )}
                              {queryConditionValue !== 'Between' &&
                                queryConditionValue !== 'Is Not Null' &&
                                queryConditionValue !== 'Is Null' &&
                                queryConditionValue !== 'In' &&
                                queryConditionValue !== 'Not In' && (
                                  <RenderValue
                                    property={property}
                                    propertyEnums={propertyEnums}
                                    acceptableValueSelect={
                                      acceptableValueSelect
                                    }
                                    handleQueryCondition={handleQueryCondition}
                                    queryConditionsId={queryConditionsId}
                                  />
                                )}
                              {(queryConditionValue === 'Is Not Null' ||
                                queryConditionValue === 'Is Null') && (
                                <div className='query-condition-draggable__input'></div>
                              )}
                            </>
                          ) : null}
                          {
                            <>
                              <RenderBtnUploadFile
                                property={property}
                                possibleOperator={possibleOperator}
                                handleUploadAdvancedFile={
                                  handleRemoveUploadFile
                                }
                                handleOpenModalUploadFile={
                                  handleOpenModalUploadFile
                                }
                              />
                              <div
                                className='query-condition-draggable__icon'
                                title='Add query'
                              >
                                <AddQueryButton
                                  property={{
                                    ...property,
                                    valuePath: parentPath,
                                  }}
                                  addQueryCondition={addQueryCondition}
                                />
                              </div>
                              <Tooltip
                                className='query-condition-draggable__icon'
                                title='Remove query'
                              >
                                <Button
                                  type='text'
                                  icon={<MinusCircleOutlined />}
                                  onClick={() => {
                                    handleRemoveQueryConditions(
                                      property?.valuePath
                                    );
                                  }}
                                />
                              </Tooltip>
                            </>
                          }
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              }
            </>
          );
        }}
      </Draggable>
      <ModalUploadAdvancedFile
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        handleUploadAdvancedFile={(data) =>
          handleUpdateFile(data, property?.valuePath, 'values')
        }
      />
    </>
  );
};

export default QueryDraggable;

export const DisplayName = ({
  property,
  selectTreeData,
  text,
  ...otherProps
}) => {
  const treeSelectRef = useRef();
  const [isEdit, setIsEdit] = useState(false);

  const displayName = property?.fullPathDisplayName ?? property?.displayName;

  useEffect(() => {
    if (isEdit) treeSelectRef.current?.focus();
  }, [isEdit]);

  return (
    <>
      {
        <Avatar
          size='small'
          className='entities-draggable__data-type'
          shape='square'
          style={{ margin: '3px 0' }}
          icon={<AvatarPropertiesItem propertyType={property?.dataType} />}
        />
      }
      {isEdit ? (
        <TreeSelect
          ref={treeSelectRef}
          defaultOpen
          showSearch
          treeDefaultExpandAll
          bordered={false}
          treeData={selectTreeData}
          treeLine={{
            showLine: true,
            showLeafIcon: false,
          }}
          style={{
            width: '100%',
          }}
          dropdownStyle={{
            maxHeight: 400,
            overflow: 'auto',
            minWidth: 400,
          }}
          placeholder='Input to search property...'
          filterTreeNode={(inputValue, treeNode) => {
            const inputValueSearch = inputValue.toLowerCase().trim();

            const isDisplayNameMatched = treeNode.data?.displayName
              ?.toLowerCase()
              ?.includes(inputValueSearch);

            return isDisplayNameMatched;
          }}
          onDropdownVisibleChange={() => {
            setIsEdit(false);
          }}
          {...otherProps}
        />
      ) : (
        <Typography.Text
          onClick={() => {
            setIsEdit(true);
          }}
          className='query-condition-draggable__label'
          ellipsis
        >
          <span
            style={{
              backgroundColor: '#d9f7be',
              borderRadius: 4,
              padding: '0 8px',
            }}
          >
            {displayName ? displayName : text}
            &nbsp;
            <CaretDownOutlined style={{ cursor: 'pointer', width: 28 }} />
          </span>
        </Typography.Text>
      )}
    </>
  );
};
