import moment from 'moment';
import React, { useState, useEffect } from 'react';

import {
  Typography,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Input,
  InputNumber,
} from 'antd';
import { Form, DataDisplay } from 'common/components';
import { StyledModal } from 'common/components';

import * as productServices from 'services/product';
import { apiHandler } from 'utils/api';
import { formatMDY } from 'utils/formatDate';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

import { getHighlightedText } from 'common/components/reporting/entity-and-attributes/utils';

import { useGetProductFullView } from 'hooks';

const { Text } = Typography;
const { Search } = Input;

const FormEditCustomProductProperties = (props) => {
  const {
    visibleModal,
    turnOffModal,
    memberProductCustomProperties,
    productCustomProperties,
    productId,
    refetchProductCustomPropertiesData,
    error,
  } = props;

  const [formInstance] = Form.useForm();
  const intl = useIntl();

  const [filteredCustomProperties, setFilteredCustomProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusSubmit, setStatusSubmit] = useState('idle');
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { handleRefetchProductFullView } = useGetProductFullView({
    productId,
  });

  const onSearchProperties = (searchText) => {
    setSearchText(searchText);
    filterMemberProductCustomProperties(searchText);
  };
  const handleSubmitForm = () => {
    formInstance.validateFields().then((value) => {
      const customPropertiesValue = genFieldValues(value);
      callApiSaveData(customPropertiesValue);
    });
  };

  const initFormValues = () => {
    if (!visibleModal) return;
    if (!productCustomProperties) return;

    const parsers = {
      date: moment,
      number: parseFloat,
      boolean: parseBooleanField,
      bool: parseBooleanField,
    };

    const values = productCustomProperties.reduce(
      (accumulator, currentProperty) => {
        const currentMemberProperty = memberProductCustomProperties?.find(
          (propertyItem) => propertyItem.fieldName === currentProperty.fieldName
        );

        if (!currentMemberProperty) return null;

        const parser = parsers[currentMemberProperty.fieldType];

        const formValue = currentProperty.value
          ? parser
            ? parser(currentProperty.value)
            : currentProperty.value
          : null;

        accumulator[currentProperty.fieldName] = formValue;
        return accumulator;
      },
      {}
    );

    formInstance.setFieldsValue(values);
    setLoading(false);
  };

  const parseBooleanField = (value) => {
    if (!value) return value;

    const stringValue = value.toString()?.toLowerCase();

    if (stringValue === 'true') return true;

    return false;
  };

  const genFieldValues = (values) => {
    return memberProductCustomProperties.map((propertyItem) => {
      const rawValue = values[propertyItem.fieldName];
      const value =
        propertyItem.fieldType === 'date' ? formatMDY(rawValue) : rawValue;

      return {
        ...propertyItem,
        value,
      };
    });
  };

  const filterMemberProductCustomProperties = (searchText) => {
    const searchResult = memberProductCustomProperties?.filter(
      (propertyItem) => {
        return propertyItem.displayName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }
    );

    setFilteredCustomProperties(searchResult);
  };

  const callApiSaveData = (customProperties) => {
    setStatusSubmit('loading');
    const params = { productId, productCustomFieldsData: customProperties };
    const successMessage = intl.formatMessage(
      Messages.saveProductCustomPropertiesSuccess
    );
    const errorMessage = intl.formatMessage(
      Messages.saveProductCustomPropertiesError
    );

    apiHandler({
      service: productServices.saveMemberProductCustomProperties,
      params,
      successMessage,
      errorMessage,
      successCallback,
      onFinally,
    });
  };

  const successCallback = () => {
    refetchProductCustomPropertiesData();
    handleRefetchProductFullView();
    formInstance.resetFields();
  };

  const onFinally = () => {
    turnOffModal();
    setStatusSubmit('idle');
  };

  const onFieldsChange = (changedFields, allFields) => {
    updateSubmitDisabled(allFields);
  };

  const updateSubmitDisabled = (allFields) => {
    const isError = allFields.some((field) => !!field.errors.length);
    setDisabledSubmit(isError);
  };

  useEffect(() => {
    filterMemberProductCustomProperties('');
    initFormValues();
  }, [visibleModal, productCustomProperties, memberProductCustomProperties]);

  const renderTitle = () => {
    return (
      <Row justify='space-between'>
        <Col span={14}>
          <Text strong>Edit Custom Properties</Text>
        </Col>
        <Col span={10} style={{ paddingRight: 24 }}>
          <Search
            allowClear
            onSearch={onSearchProperties}
            placeholder={intl.formatMessage(
              Messages.searchProductCustomPropertiesPlaceholder
            )}
          />
        </Col>
      </Row>
    );
  };

  const renderField = (field, searchText) => {
    const highlightSearchText = getHighlightedText(
      field.displayName,
      searchText
    );

    const inputs = {
      string: Input,
      number: InputNumber,
      bool: Checkbox,
      boolean: Checkbox,
      date: DatePicker,
    };

    const InputComp = inputs[field?.fieldType];

    const isBooleanType =
      field.fieldType === 'bool' || field.fieldType === 'boolean';

    const formItemProps = {
      name: field.fieldName,
      label: <Text ellipsis>{highlightSearchText}</Text>,
      valuePropName: isBooleanType ? 'checked' : undefined,
      format: field.fieldType === 'date' ? 'MM/DD/YYYY' : undefined,
      tooltip: field.fieldType,
    };

    const inputStyle = {
      number: { width: 200 },
    };

    return (
      <Form.Item key={field.fieldName} {...formItemProps}>
        <InputComp
          style={inputStyle[field.fieldType]}
          inputReadOnly={field.fieldType === 'date'}
        />
      </Form.Item>
    );
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const errorMessage =
    error && intl.formatMessage(Messages.getProductPropertyErrorMessage);

  return (
    <StyledModal
      wrapClassName='product-detail-view__custom-property-modal'
      width={'70vw'}
      bodyStyle={{ height: 500 }}
      visible={visibleModal}
      onCancel={turnOffModal}
      onOk={handleSubmitForm}
      title={renderTitle()}
      okButtonProps={{
        loading: statusSubmit === 'loading',
        disabled: disabledSubmit,
      }}
    >
      <Form
        {...layout}
        form={formInstance}
        name='private-properties'
        className='product-detail-view__custom-property'
        autoComplete='off'
        onFieldsChange={onFieldsChange}
      >
        <DataDisplay
          loading={loading}
          error={errorMessage}
          data={filteredCustomProperties}
          classNameObj={{
            'product-detail-view__custom-property-data-list': true,
          }}
        >
          {filteredCustomProperties?.map((field) =>
            renderField(field, searchText)
          )}
        </DataDisplay>
      </Form>
    </StyledModal>
  );
};

export default FormEditCustomProductProperties;
