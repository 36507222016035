import React from 'react';

import { OtherLayout } from './components';

import { RibbonBar } from "common/components"; 
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import { ViewLayout, AdvanceStack } from './components';

const OnlyCloseRibbon = () => {
  return (
    <RibbonBar>
      <ManageSharingSection disabled={true} />
      <DetailSection disabled={true} />
      <ViewLayout disabled={true} />
      <AdvanceStack isDisabled={true} />
      <OtherLayout
        ribbonSide='right'
        isHaveAddOpenItem={false}
        ribbonType='products'
      />
    </RibbonBar>
  );
};

export default OnlyCloseRibbon;
