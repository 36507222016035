import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SaveOutlined } from '@ant-design/icons';
import { Modal, Button, Form, message } from 'antd';
import { useIntl } from 'react-intl';

import Messages from 'i18n/messages/home';
import messagesGridView from 'i18n/messages/gridView';

import ButtonSmallIcon from 'common/components/button/ButtonSmallIcon';
import MutationGridConfig from './MutationGridConfig';

import { RIBBON_TYPES } from 'static/Constants';
import { saveQuery } from 'services/query';

import { selectRibbonType } from 'redux/ribbon/selectors';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { useMutation, useGetDataQueryConditions } from 'hooks';

import { useGetAllMemberRoles } from './hooks';

import { filterParamsColumns } from './utils';
import { getGridName } from 'utils/getGridName';
import { updateIdQuery } from 'utils/queryCondition';
import { useDefaultSearchText } from 'pages/home/utils';

import _ from 'lodash';

import './SaveGrid.less';
import { handleCreateSearchContainer } from 'pages/reporting/utils';

const SaveGrid = (props) => {
  const { setIsMounted } = props;

  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [toggleCuratedQuery, setCuratedQuery] = useState(false);
  const [shareType, setShareType] = useState('MemberRole');

  const [
    { status: statusCreate, data: dataCreate },
    createConfig,
    setDefaultState,
  ] = useMutation(saveQuery);
  const { getDefaultSearchText } = useDefaultSearchText();

  const { memberRoles } = useGetAllMemberRoles();

  const { chosenColumns } = useSelector(
    selectorsGridView.makeSelectGridConfig()
  );
  const queryConditions = useGetDataQueryConditions(window.location.pathname);
  const ribbonTypes = useSelector(selectRibbonType());
  const gridName = getGridName(window.location.pathname);
  const searchText = getDefaultSearchText(window.location.pathname);

  const searchCategory = useSelector(
    selectorsGridView.makeSelectSearchCategoriesList()
  );

  useEffect(() => {
    form.setFieldsValue({ gridName });
  }, [form, gridName]);

  useEffect(() => {
    form.resetFields();
    setSelectedMembers([]);
    setCuratedQuery(false);
    setShareType('MemberRole');
  }, [form, visible]);

  useEffect(() => {
    if (statusCreate === 'success') {
      message.success(intl.formatMessage(messagesGridView.createConfigSuccess));

      updateIdQuery(dispatch, dataCreate.id, window.location.pathname);
    }

    if (statusCreate === 'error') {
      message.error(intl.formatMessage(messagesGridView.createConfigError));
    }

    form.resetFields();
    setVisible(false);
    setDefaultState();
  }, [dispatch, form, gridName, intl, statusCreate]);

  const handleCreateConfig = async () => {
    const { isDefault, isCuratedQuery, dateActive, ...values } =
      await form.validateFields();

    const advancedSearchContainer =
      handleCreateSearchContainer(queryConditions);

    let params = {
      ...values,
      gridName,
    };

    // Add params curated query
    if (isCuratedQuery) {
      const { curatedQueryShareType, curatedQueryShareToMemberRoleId } = values;

      params = { ...params, isCuratedQuery };

      if (curatedQueryShareType === 'MemberRole') {
        if (curatedQueryShareToMemberRoleId) {
          params = {
            ...params,
            curatedQueryShareToMemberRoleId,
            curatedQueryShareToMemberType:
              memberRoles.find(
                (role) => role.memberRoleId === curatedQueryShareToMemberRoleId
              )?.memberRoleName ?? null,
          };
        }
        params = {
          ...params,
          curatedQueryShareType,
          curatedQueryShareToMembers: [],
        };
      }

      if (curatedQueryShareType === 'SpecificMembers') {
        params = {
          ...params,
          curatedQueryShareType,
          curatedQueryShareToMembers: selectedMembers.map(
            (member) => member.id
          ),
        };
      }

      if (curatedQueryShareType === 'AllMember') {
        params = {
          ...params,
          curatedQueryShareType,
          curatedQueryShareToMemberType: null,
          curatedQueryShareToMembers: [],
        };
      }

      if (dateActive) {
        params = {
          ...params,
          effectedDate: dateActive[0].toISOString(),
          expirationDate: dateActive[1].toISOString(),
        };
      } else {
        params = {
          ...params,
          effectedDate: null,
          expirationDate: null,
        };
      }
    }

    let jsonConfig;
    let downloadJsonFormat;
    if (ribbonTypes === RIBBON_TYPES.DETAILSVIEW) {
      jsonConfig = JSON.stringify({
        columns: filterParamsColumns(chosenColumns),
        queryConditions: queryConditions,
        ribbonTypes: ribbonTypes,
        urlForwardTo: window.location.pathname,
        searchText,
        'search.searchText': searchText,
      });
    } else {
      jsonConfig = JSON.stringify({
        queryConditions: queryConditions,
        ribbonTypes: ribbonTypes,
        urlForwardTo: window.location.pathname,
        searchText,
        'search.searchText': searchText,
      });
    }
    downloadJsonFormat = JSON.stringify({
      advancedSearchContainer,
      search: {
        searchText,
      },
      isFavoriteRoute: false,
      fromDate: null,
      searchCategory,
      filters: [],
      pageSize: 20,
      pageIndex: 1,
    });
    params = Object.assign(params, {
      query: jsonConfig,
      downloadJsonFormat: downloadJsonFormat,
    });

    createConfig(params);
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <Modal
          title='Create Query'
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          destroyOnClose
          width={1100}
          bodyStyle={{
            maxHeight: '700px',
          }}
          maskClosable={false}
          className='modal-create-query'
          footer={[
            <Button
              key='save'
              type='primary'
              onClick={handleCreateConfig}
              loading={statusCreate === 'loading'}
            >
              Create
            </Button>,
          ]}
        >
          <MutationGridConfig
            form={form}
            setIsMounted={setIsMounted}
            renderDefault={false}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            toggleCuratedQuery={toggleCuratedQuery}
            setCuratedQuery={setCuratedQuery}
            gridName={gridName}
            shareType={shareType}
            setShareType={setShareType}
            memberRoles={memberRoles}
          />
        </Modal>
      )}

      <ButtonSmallIcon
        icon={<SaveOutlined />}
        label={Messages.saveConfig}
        onClick={() => setVisible(true)}
        className='grid-config__button'
      />
    </>
  );
};

SaveGrid.propTypes = {};

export default SaveGrid;
