import React, { useState, useEffect, useRef } from 'react';

import { Button, Space } from 'antd';
import { ResizableBox } from 'react-resizable';
import {
  DragOutlined,
  FileImageOutlined,
  SettingOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useDragDropPosHooks } from 'common/components/drapDropWrap/drag-drop-pos';

import { getBase64 } from 'utils/image';
import { sleep } from 'utils/delay';
import { checkComponentInside } from 'utils/element';
import {
  useAdjustPositionWhenSizeChange,
  useGet$wrapAnd$component,
  useAdjustComponentSizeWhenChangeSrc,
} from '../../hooks';

import { EDIT_TYPE, TOLERANCE_SIZE } from '../../constants';

import * as _ from 'lodash';
import classnames from 'classnames';

import './PageEditorDragImage.less';

const PageEditorDragImage = (props) => {
  const {
    wrapId,
    selectedHook,
    editTypeHook,
    isEdit,
    componentKey,
    componentData,
    detailShowTargetHook,
    changeFileTargetHook,
    componentHandlers,
    scale,
  } = props;

  const { onDeleteComponent, onChangeComponentValue, onDragComponent } =
    componentHandlers;

  const [, setEditType] = editTypeHook;
  const [selected, setSelected, clearSelect] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;
  const [, setChangeFileTarget] = changeFileTargetHook;

  const [markupRatio, setMarkupRatio] = useState(null);
  const [markupImgSrc, setMarkupImgSrc] = useState('');

  const refResize = useRef();
  const refPrevSize = useRef();

  const [dragPosInst, dragStyle, refDragTag] = useDragDropPosHooks([
    clearSelect,
    componentKey,
    { disableClickouter: detailShowTarget === componentKey },
  ]);

  const [refWrap, refComponent] = useGet$wrapAnd$component(
    wrapId,
    componentKey
  );

  useAdjustPositionWhenSizeChange({
    wrapId,
    componentId: componentKey,
    onChangeComponentValue,
    fieldNameX: 'left',
    fieldNameY: 'top',
    scale,
    deps: [componentData?.borderWidth],
  });

  const [isAdjustingSize] = useAdjustComponentSizeWhenChangeSrc({
    refComponent,
    refWrap,
    componentHandlers,
    componentKey,
    src: componentData?.src,
    ratio: markupRatio,
  });

  const mapToResizeBoxHeight = () => {
    const sizeBorder = componentData?.borderWidth * 2;
    return markupRatio !== null
      ? (componentData?.width - sizeBorder) / markupRatio + sizeBorder
      : 0;
  };

  const handleClickMarkup = (event) => {
    const target = event.currentTarget;
    setSelected({ id: target?.id, element: target });
  };

  const onClickDragIcon = () => {
    onDragComponent({
      componentData,
      componentKey,
      fieldNameX: 'left',
      fieldNameY: 'top',
      refWrap,
      refComponent,
      dragPosInst,
    });
  };

  const onLoadMarkup = (event) => {
    const target = event.target;
    target && setMarkupRatio(target.width / target.height);
  };

  const handleResize = (size, element) => {
    if (size.width) {
      onChangeComponentValue({
        key: componentKey,
        fieldName: 'width',
        value: size.width,
      });
    }
  };

  const onResizeStart = () => {
    refPrevSize.current = {
      width: componentData?.width,
      height: componentData?.width / markupRatio,
    };
  };

  const onResizeStop = () => {
    const checkInsideResultArray = checkComponentInside({
      elements: [refComponent.current],
      elementCover: refWrap.current,
      tolerance: TOLERANCE_SIZE,
    });

    const { isInside } = checkInsideResultArray?.[0];

    if (!isInside) {
      onChangeComponentValue({
        key: componentKey,
        fieldName: 'width',
        value: refPrevSize?.current?.width,
      });
      refResize.current.setState(refPrevSize.current);
    }
  };

  const handleSrc = async (fileObj, src) => {
    if (fileObj) {
      const imgSrc = await getBase64(fileObj);
      setMarkupImgSrc(imgSrc);
    } else {
      setMarkupImgSrc(src);
    }
  };

  const handleToggleMedia = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditType(EDIT_TYPE.UPLOAD);
    setDetailShowTarget(componentKey);
    setChangeFileTarget(componentKey);
  };

  const handleToggleEditDetailImage = () => {
    setEditType(EDIT_TYPE.IMG);
    setDetailShowTarget(componentKey);
  };

  const handleRemoveComponent = async () => {
    if (isSelected) {
      await sleep(200);

      onDeleteComponent({ key: componentKey });
      clearSelect();
    }
  };

  useEffect(() => {
    if (componentData?.src?.originFileObj) {
      handleSrc(componentData?.src?.originFileObj);
    } else {
      handleSrc(null, componentData?.src);
    }

    setMarkupRatio(null);
  }, [componentData?.src]);

  const isSelected = selected?.id === componentKey && isEdit;
  const transitionStyle = isAdjustingSize ? 'width 0.4s' : '';
  const height = mapToResizeBoxHeight();
  const visibleStyle = height !== 0 ? '' : 'none';

  return (
    <>
      <ResizableBox
        ref={refResize}
        width={componentData?.width}
        height={height}
        top={componentData?.top}
        lockAspectRatio={true}
        onResize={(event, { element, size, handle }) =>
          handleResize(size, event)
        }
        style={{
          top: componentData?.top,
          left: componentData?.left,
          position: 'absolute',
          transition: transitionStyle,
          overflow: 'visible',
          display: visibleStyle,
          ...dragStyle(isSelected),
        }}
        id={componentKey}
        className={classnames('page-editor', {
          'highlight-selected': isSelected,
        })}
        onClick={handleClickMarkup}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
      >
        {markupImgSrc && (
          <img
            className='box'
            src={markupImgSrc}
            alt=''
            style={{
              width: '100%',
              borderRadius: `${componentData?.radius}px`,
              border: `solid ${componentData?.borderWidth}px ${componentData?.borderColor}`,
              transition: 'width 0.4s',
            }}
            onLoad={onLoadMarkup}
          />
        )}
        <div
          className={classnames('page-editor__drag', {
            'page-editor__drag--show': isSelected,
          })}
        >
          <Space size={3}>
            <Button
              className={classnames(
                'page-editor__btn',
                'page-editor__btn--clear'
              )}
              onClick={handleRemoveComponent}
            >
              <CloseOutlined />
            </Button>
            <Button
              className={classnames('page-editor__btn')}
              onClick={handleToggleEditDetailImage}
            >
              <SettingOutlined />
            </Button>
            <Button
              className={classnames('page-editor__btn')}
              onClick={handleToggleMedia}
            >
              <FileImageOutlined />
            </Button>
            <Button
              className={classnames('page-editor__btn')}
              ref={refDragTag}
              onMouseDown={onClickDragIcon}
            >
              <DragOutlined />
            </Button>
          </Space>
        </div>
      </ResizableBox>
    </>
  );
};

export default PageEditorDragImage;
