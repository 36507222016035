import React, { useState, useCallback } from 'react';

import { Input, Checkbox, Typography } from 'antd';
import { Form, StyledModal, FolderSelector } from 'common/components';

import { apiHandler } from 'utils/api';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { useGridView } from 'hooks/useGridView';

import * as folderServices from 'services/folder';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/folder';

const { Title } = Typography;

const CopyFolderModal = (props) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const { visible, closeModal, selectedFolder, onCopy } = props;

  const [loading, setLoading] = useState(false);

  const reloadPage = useDispatchReloadPage();
  const { clearGridSelection } = useGridView();

  const onBreadcrumbChange = (currentBreadcrumbItem, breadcrumbList) => {
    const hierarchy =
      breadcrumbList
        .map((breadcrumbItem) => breadcrumbItem.id)
        .join('/')
        .replace('home', '') + '/';

    const folder = { id: currentBreadcrumbItem, hierarchy };

    form.setFieldsValue({ destinationFolder: folder });
  };

  const onSelectDestinationFolder = (folder) => {
    form.setFieldsValue({ destinationFolder: folder });
  };

  const onSubmit = () => {
    form.validateFields().then((values) => {
      let { newFolderName, destinationFolder, ...restValues } = values;

      const params = {
        ...restValues,
        newFolderName: newFolderName.trim(),
        folderId: selectedFolder?.id,
        destinationFolderId: destinationFolder?.id,
      };

      if (destinationFolder?.id === 'home') {
        delete params['destinationFolderId'];
        destinationFolder = undefined;
      }

      const successMessage = intl.formatMessage(Messages.copyFolderSuccess);
      const errorMessage = intl.formatMessage(Messages.copyFolderError);

      setLoading(true);

      apiHandler({
        service: folderServices.copyFolder,
        params,
        successMessage,
        errorMessage,
        successCallback: copySuccessCallback({ destinationFolder }),
        onFinally: copyFinally,
      });
    });
  };

  const cancelModal = () => {
    closeModal();
    resetState();
  };

  const copySuccessCallback = ({ destinationFolder }) => () => {
    reloadPage();
    cancelModal();

    clearGridSelection();
    onCopy && onCopy({ destinationFolder });
  };

  const copyFinally = () => {
    setLoading(false);
  };

  const resetState = () => {
    form.resetFields();
    setLoading(false);
  };

  return (
    <StyledModal
      visible={visible}
      title={intl.formatMessage(Messages.copyFolderModalTitle)}
      bodyStyle={{ height: '80vh' }}
      centered
      okButtonTest={intl.formatMessage(Messages.copyFolder)}
      onCancel={cancelModal}
      onOk={onSubmit}
      okButtonProps={{ loading }}
      destroyOnClose
    >
      <Form form={form} layout='vertical' style={{ height: '100%' }}>
        <Form.Item
          name='newFolderName'
          label={intl.formatMessage(Messages.copyFoldername)}
          required
          rules={[
            {
              required: true,
              message: intl.formatMessage(Messages.nameRequiredMessage),
            },
            {
              whitespace: true,
              message: intl.formatMessage(Messages.nameInvalidMessage),
            },
          ]}
        >
          <Input placeholder='Input Folder Name' />
        </Form.Item>
        <Form.Item name='isDuplicateContent' valuePropName={'checked'}>
          <Checkbox>
            {intl.formatMessage({
              id: 'Taco.folder.component.copyFormCheckbox',
            })}
          </Checkbox>
        </Form.Item>

        <Form.Item name='destinationFolder' hidden></Form.Item>
        <Title level={5}>Select Destination Folder</Title>
        <div style={{ height: 'calc(100% - 140px)' }}>
          <FolderSelector
            onSelectFolder={onSelectDestinationFolder}
            onExpandFolder={onSelectDestinationFolder}
            onBreadcrumbChange={onBreadcrumbChange}
          />
        </div>
      </Form>
    </StyledModal>
  );
};

export default CopyFolderModal;
