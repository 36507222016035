import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomNotification } from 'common/components';

import { useParams } from 'react-router-dom';

import * as qaSpecServices from 'services/qaSpec';
import * as productServices from 'services/product';

import { forwardTo } from 'utils/common/route';
import { getQaPackagingTabDataQueryKey } from '../qa-packaging/queries';
import { useCheckSnapshotForRetailer } from 'pages/qa-spec/hooks';

export const qaFreshProduceKeys = {
  detail: (productId) => ['qa-spec', parseInt(productId), 'fresh-produce'],
};

export const useGetFreshProduce = (productId) => {
  return useQuery({
    queryKey: qaFreshProduceKeys.detail(productId),
    queryFn: async () => {
      const response = await qaSpecServices.getQaSpecProduce({ productId });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
  });
};

export const useSaveFreshProduce = (productId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: qaSpecServices.saveQaSpecProduce,
    onSuccess: (response) => {
      if (response?.isSuccess) {
        queryClient.invalidateQueries({
          queryKey: qaFreshProduceKeys.detail(productId),
        });
        queryClient.invalidateQueries({
          queryKey: getQaPackagingTabDataQueryKey(productId),
        });
        CustomNotification.success('Update Fresh-Produce data successfully!');
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
      }
    },
    onError: (error) => {
      CustomNotification.error(error || 'Server Error');
    },
  });
};

// Snapshot
export const qaFreshProduceSnapshotKeys = {
  getFreshProduce: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-fresh-produce',
  ],
};

export const useQaGetFreshProduceSnapshot = () => {
  const { id: productId } = useParams();
  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const queries = useQuery({
    queryKey: qaFreshProduceSnapshotKeys.getFreshProduce(productId),
    queryFn: async () => {
      const response = await productServices.getQaProductFreshProduceSnapshot({
        productId,
      });
      if (response?.isSuccess) {
        return response?.data;
      }
      CustomNotification.error(response?.message ?? 'Something went wrong');
      forwardTo('/products');
    },
    enabled: !!productId && isToggleSnapshot,
  });

  return {
    qaFreshProduceSnapshot: queries?.data ?? {},
    ...queries,
  };
};
