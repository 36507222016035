import React, { useState, useEffect } from 'react';

import { ReactComponent as ScheduleDay } from 'assets/dashboard/work-schedule.svg';
import { SendOutlined } from '@ant-design/icons';

import { Form, notification, Button, Typography, Row, Col } from 'antd';

import Messages from 'i18n/messages/dashboard';
import { useIntl } from 'react-intl';

import { RibbonButton, StyledModal } from 'common/components';
import {
  FormCreateMeeting,
  AdditionAttendee,
  DisplaySomeAttendees,
  DescriptionMeeting,
  ExternalAttendee,
} from './ModalCreateMeeting';

import { concatenateTime } from './utils';

import { createNewMeeting } from 'services/dashboard';

import { useDispatchReloadPage } from 'hooks/useReloadPage';

import './NewMeeting.less';
import moment from 'moment';

const NewMeeting = ({ onRefetchMeetingCalendar }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <RibbonButton
        icon={<ScheduleDay />}
        label={Messages.newMeeting}
        onClick={() => setVisible(true)}
      />
      {visible && (
        <ModalNewMeeting
          visible={visible}
          onCancel={() => setVisible(false)}
          onRefetchMeetingCalendar={onRefetchMeetingCalendar}
        />
      )}
    </>
  );
};

const ModalNewMeeting = ({ visible, onCancel, onRefetchMeetingCalendar }) => {
  const intl = useIntl();
  const [formInstance] = Form.useForm();

  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [externalAttendees, setExternalAttendees] = useState([]);
  const [description, setDescription] = useState('');

  const [statusSubmit, setStatusSubmit] = useState('idle');

  const [startTimeInput, setStartTimeInput] = useState();
  const [startTimeChange, setStartTimeChange] = useState(false);
  const [endTimeInput, setEndTimeInput] = useState();
  const [endTimeChange, setEndTimeChange] = useState(false);

  const reloadPage = useDispatchReloadPage();

  const isAttendeesError =
    !selectedAttendees.length && !externalAttendees.length;

  useEffect(() => {
    if (visible) {
      const date = new Date();
      const startDate = moment(date).minute(0).second(0).add(1, 'h');
      const endDate = moment(startDate).add(30, 'm');
      formInstance.setFieldsValue({
        startDate,
        endDate,
        startTime: moment(startDate, 'hh:mm:ss').format('hh:mm A'),
        endTime: moment(endDate, 'hh:mm:ss').format('hh:mm A'),
      });

      setStartTimeInput(startDate.format('hh:mm A'));
      setStartTimeChange(false);
      setEndTimeInput(endDate.format('hh:mm A'));
      setEndTimeChange(false);
    }
  }, [visible]);

  const handleAddAttendees = (attendee) => {
    setSelectedAttendees((prevAttendee) => prevAttendee.concat(attendee));
  };

  const handleDeleteAttendees = (attendeeId) => {
    const filteredAttendees = selectedAttendees.filter(
      (attendee) => attendee.id !== attendeeId
    );
    setSelectedAttendees(filteredAttendees);
  };

  const handleAddExternalAttendees = (attendee) => {
    setExternalAttendees((prevAttendee) => prevAttendee.concat(attendee));
  };

  const handleDeleteExternalAttendees = (attendeeId) => {
    const filteredExternalAttendees = externalAttendees.filter(
      (attendee) => attendee.id !== attendeeId
    );
    setExternalAttendees(filteredExternalAttendees);
  };

  const handleCreateMeeting = async () => {
    try {
      const { title, startDate, startTime, endDate, endTime } =
        await formInstance.validateFields();

      setStatusSubmit('loading');

      const attendees = selectedAttendees.map((user) => user.id);
      const externalEmails = externalAttendees.map(
        (attendee) => attendee?.email
      );

      const formatDescription = description?.replaceAll('\n', '<br/>');

      const params = {
        title,
        startTime: startDate,
        endTime: endDate,
        isStartMeetingNow: false,
        description: formatDescription,
        attendees,
        externalEmails,
      };

      const response = await createNewMeeting(params);

      if (response?.isSuccess) {
        notification.success({
          message: 'Schedule meeting successfully!',
        });
        setStatusSubmit('success');
        onRefetchMeetingCalendar();
        onCancel();
        reloadPage();
      } else {
        !isAttendeesError &&
          notification.error({
            message: response?.message ?? 'Something went wrong!',
          });
        setStatusSubmit('error');
        //onCancel();
      }
    } catch (error) {
      isAttendeesError && setStatusSubmit('error');
    }
  };
  return (
    <StyledModal
      title='Schedule Meeting'
      width='1000px'
      visible={true}
      onCancel={onCancel}
      // bodyStyle={{
      //   minHeight: '74vh',
      // }}
      maskClosable={false}
      footer={null}
    >
      <FormCreateMeeting
        formInstance={formInstance}
        startTimeInput={startTimeInput}
        setStartTimeInput={setStartTimeInput}
        endTimeInput={endTimeInput}
        setEndTimeInput={setEndTimeInput}
        startTimeChange={startTimeChange}
        setStartTimeChange={setStartTimeChange}
        endTimeChange={endTimeChange}
        setEndTimeChange={setEndTimeChange}
      >
        <Button
          icon={<SendOutlined />}
          size='large'
          className='modal-meeting__btn-send'
          onClick={handleCreateMeeting}
          loading={statusSubmit === 'loading'}
          type='primary'
        >
          <Typography.Text style={{ color: '#FFF' }}>SEND</Typography.Text>
        </Button>
      </FormCreateMeeting>
      <div style={{ display: 'flex', marginLeft: '18.5%' }}>
        <AdditionAttendee
          selectedAttendees={selectedAttendees}
          handleAddAttendees={handleAddAttendees}
        />
        <ExternalAttendee
          externalAttendees={externalAttendees}
          handleAddExternalAttendees={handleAddExternalAttendees}
          handleDeleteExternalAttendees={handleDeleteExternalAttendees}
        />
      </div>

      <div style={{ height: 67 }}>
        {selectedAttendees?.length !== 0 && (
          <DisplaySomeAttendees
            selectedAttendees={selectedAttendees}
            handleAddAttendees={handleAddAttendees}
            handleDeleteAttendees={handleDeleteAttendees}
          />
        )}

        {/* //* Display External Attendees */}
        {externalAttendees?.length !== 0 && (
          <DisplaySomeAttendees
            selectedAttendees={externalAttendees}
            handleAddAttendees={handleAddExternalAttendees}
            handleDeleteAttendees={handleDeleteExternalAttendees}
            isExternalAttendees
          />
        )}
        {statusSubmit === 'error' && isAttendeesError ? (
          <Col offset={5} span={19}>
            <Typography.Text type='danger'>
              {intl.formatMessage(Messages.attendeesErrorMessage)}
            </Typography.Text>
          </Col>
        ) : null}
      </div>
      <DescriptionMeeting
        handleChangeDescription={(description) => setDescription(description)}
      />
    </StyledModal>
  );
};

export default NewMeeting;
