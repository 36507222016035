/**
 *
 * Img.js
 *
 * Renders an image, enforcing the usage of the alt="" tag
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Image component will render image with input source url
 * @author Brian Pick
 */
 function Image(props){
  const {
    src,
    test,
    alt,
    ...rest
  } = props;
  return <img
    src={src}
    alt={alt}
    {...rest}
  />;
}

Image.propTypes = {
  /**
   * input source url
   */
  src: PropTypes.string.isRequired,
  /**
   * alternative text to display when image is broken
   */
  alt: PropTypes.string.isRequired
};

Image.defaultProps = {
  alt: 'image'
}

export default Image;

