import React from 'react';

import { Divider } from 'antd';

import {
  DayView,
  WeekView,
  MonthView,
  ScheduleView,
} from '../ribbon/components/controls/dashboard';

import { RibbonBar, RibbonDivider } from 'common/components';
import LayoutSection from './components/sections/dashboard/LayoutSection';
import ActionsMeeting from './components/sections/dashboard/ActionsMeeting';

const Dashboard = () => {
  return (
    <RibbonBar>
      <DayView />
      <WeekView />
      <MonthView />
      <ScheduleView />

      <RibbonDivider />
      <ActionsMeeting />

      <RibbonDivider />
      <LayoutSection />
    </RibbonBar>
  );
};

export default Dashboard;
