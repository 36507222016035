export const initState = {
  searchTextBroker: '',
  selectedSupplier: {},
  brandData: [],
  productData: [],
  supplierPermissions: ['VIEW_PRODUCTS'],
  selectedEntityLevel: [],
};

export const actionConfig = {
  UPDATE_PRODUCT_DATA: 'UPDATE_PRODUCT_DATA',
  UPDATE_SELECTED_ITEMS: 'UPDATE_SELECTED_ITEMS',
  UPDATE_SUPPLIER_PERMISSIONS: 'UPDATE_SUPPLIER_PERMISSIONS',
  UPDATE_SELECTED_PRODUCT_FROM_SELECTED_PROUCT_GRID:
    'UPDATE_SELECTED_PRODUCT_FROM_SELECTED_PROUCT_GRID',
};

export const configReducer = (state, action) => {
  switch (action.type) {
    case actionConfig.UPDATE_SUPPLIER_PERMISSIONS:
      return {
        ...state,
        supplierPermissions: action.payload,
      };
    case actionConfig.UPDATE_SELECTED_ITEMS:
      return {
        ...state,
        selectedSupplier: action.payload,
      };
    case actionConfig.UPDATE_SELECTED_PRODUCT_FROM_SELECTED_PROUCT_GRID:
      return {
        ...state,
        selectedEntityLevel: action.payload,
      };
    case actionConfig.UPDATE_PRODUCT_DATA:
      return {
        ...state,
        productData: action.payload,
      };
    case actionConfig.UPDATE_BRAND_DATA:
      return {
        ...state,
        brandData: action.payload,
      };
    default:
      break;
  }
};
