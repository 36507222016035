import { defineMessages } from 'react-intl';

export const scope = 'Taco.mapping';

export default defineMessages({
  sharedMemberListTitle: {
    id: `${scope}.component.sharedMemberList.title`,
    defaultMessage: 'Sharing To Members',
  },
  unshareBtn: {
    id: `${scope}.component.sharedMemberList.unshareBtn`,
    defaultMessage: 'Unshare',
  },
  listErrorMessage: {
    id: `${scope}.component.sharedMemberList.errorMessage`,
    defaultMessage: 'Cannot any get sharing member',
  },
  unshareConfirmMessage: {
    id: `${scope}.messages.unshare.confirm`,
    defaultMessage:
      'Are you sure you want to unshare selecting mapping item to member?',
  },
  unshareSuccessMessage: {
    id: `${scope}.messages.unshare.success`,
    defaultMessage: 'Unshare to member successfully',
  },
  unshareErrorMessage: {
    id: `${scope}.messages.unshare.error`,
    defaultMessage: 'Cannot unshare to member',
  },
  createMapping: {
    id: `${scope}.ribbon.createMapping`,
    defaultMessage: 'Create',
  },
  editMapping: {
    id: `${scope}.ribbon.editMapping`,
    defaultMessage: 'Edit',
  },
  filterMapping: {
    id: `${scope}.ribbon.filterMapping`,
    defaultMessage: 'Filter',
  },
  mappingName: {
    id: `${scope}.component.mappingName`,
    defaultMessage: 'Mapping Name',
  },
  mappingNameIsRequired: {
    id: `${scope}.component.mappingNameIsRequired`,
    defaultMessage: 'Mapping Name is required!',
  },
  mappingDescription: {
    id: `${scope}.component.mappingDescription`,
    defaultMessage: 'Description',
  },
  companyName: {
    id: `${scope}.component.companyName`,
    defaultMessage: 'Company Name',
  },
  mappingDescriptionIsRequired: {
    id: `${scope}.component.mappingDescriptionIsRequired`,
    defaultMessage: 'Description is required! ',
  },
  formatType: {
    id: `${scope}.component.formatType`,
    defaultMessage: 'Delivery Type',
  },
  formatTypeIsRequired: {
    id: `${scope}.component.formatTypeIsRequired`,
    defaultMessage: 'Format Type is required!',
  },
  deliveryMethods: {
    id: `${scope}.component.deliveryMethods`,
    defaultMessage: 'Delivery Methods',
  },
  formatTypes: {
    id: `${scope}.component.formatTypes`,
    defaultMessage: 'Format Types',
  },
  deliveryMethodsIsRequired: {
    id: `${scope}.component.deliveryMethodsIsRequired`,
    defaultMessage: 'Delivery Methods is required!',
  },
  cloneSuccess: {
    id: `${scope}.component.cloneSuccess`,
    defaultMessage: 'Clone successfully!',
  },
  cloneError: {
    id: `${scope}.component.cloneError`,
    defaultMessage: 'Cannot clone mapping!',
  },
  share: {
    id: `${scope}.component.share`,
    defaultMessage: 'Share',
  },
  by: {
    id: `${scope}.component.by`,
    defaultMessage: 'by',
  },
  in: {
    id: `${scope}.component.in`,
    defaultMessage: 'in',
  },
});
