import React from 'react';
import { useDispatch } from 'react-redux';

import { RibbonSection } from 'common/components';
import AcceptSsoApproval from 'pages/home/ribbon/components/controls/sso-approval/AcceptSsoApproval';
import RejectSsoApproval from 'pages/home/ribbon/components/controls/sso-approval/RejectSsoApproval';

import * as gridActions from 'common/components/grid-view/controllers/actions';

const SsoApprovalUserSection = (props) => {
  const { selectedItem, sectionDisabled, approvalSubject } = props;

  const dispatch = useDispatch();

  const handleClearItemGrid = () => {
    dispatch(gridActions.updateDetailCurrentItemsSelection([]));
  };
  return (
    <RibbonSection>
      <AcceptSsoApproval
        selectedItem={selectedItem}
        sectionDisabled={sectionDisabled}
        onClearItemGrid={handleClearItemGrid}
        approvalSubject={approvalSubject}
      />

      <RejectSsoApproval
        selectedItem={selectedItem}
        sectionDisabled={sectionDisabled}
        onClearItemGrid={handleClearItemGrid}
        approvalSubject={approvalSubject}
      />
    </RibbonSection>
  );
};

export default SsoApprovalUserSection;
