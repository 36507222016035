import React from 'react';
import { Typography, Form, Input } from 'antd';

import reactCSS from 'reactcss';

const styles = reactCSS({
  default: {
    emailInput: {
      width: 240,
      margin: '0 auto',
    },
    emailInfo: {
      fontSize: 16,
    },
  },
});

const { Text } = Typography;

const EmailInput = (props) => {
  const { onKeyDown } = props;

  return (
    <Form.Item
      name='email'
      colon={false}
      label={false}
      rules={[
        { type: 'email', message: 'Invalid Email' },
        {
          required: true,
          message: `Email address is required`,
        },
      ]}
      style={styles.emailInput}
    >
      <Input
        name='phoneNumber'
        placeholder='Please input your email'
        onKeyDown={onKeyDown}
      />
    </Form.Item>
  );
};

const EmailInfo = (props) => {
  const { mfaEmail } = props;
  return (
    <div>
      <Text strong style={styles.emailInfo}>
        {mfaEmail && mfaEmail}
      </Text>
    </div>
  );
};

export { EmailInput, EmailInfo };
