import { defineMessages } from 'react-intl';

export const scope = 'Taco.favorite';

export default defineMessages({
  addToFavoriteSuccess: {
    id: `${scope}.add.success`,
    defaultMessage: 'Add to favorite successfully',
  },
  addToFavoriteError: {
    id: `${scope}.add.error`,
    defaultMessage: 'Cannot add to favorite',
  },
  removeFromFavoriteSuccess: {
    id: `${scope}.remove.success`,
    defaultMessage: 'Remove from favorite successfully',
  },
  removeFromFavoriteError: {
    id: `${scope}.remove.error`,
    defaultMessage: 'Cannot remove from favorite',
  },
  addToFavorite: {
    id: `${scope}.add.favorite`,
    defaultMessage: 'Add to Favorite',
  },
  removeFromFavorite: {
    id: `${scope}.remove.favorite`,
    defaultMessage: 'Remove from Favorite',
  },
});
