import React from 'react';

import { Popover } from 'antd';
import { useNode } from '@craftjs/core';

const EmailEditorSectionSetting = (props) => {
  const { selected } = useNode((state) => ({
    selected: state.events.selected,
  }));

  const { children, settings } = props;

  return (
    <Popover
      overlayClassName='email-editor__setting'
      placement='top'
      visible={selected}
      content={settings}
      destroyTooltipOnHide
      zIndex={1000}
    >
      {children}
    </Popover>
  );
};

export default EmailEditorSectionSetting;
