import { put, call, takeLatest, all } from 'redux-saga/effects';
import { get } from 'lodash';
import * as servicesGrid from 'services/grid';
import * as servicesMapping from 'services/mapping';
import * as types from './constants';
import * as actions from './actions';
import { formatMDYWithParam } from 'utils/formatDate';
import { DEFAULT_SORT } from 'static/Constants';

import { getMappingGridService } from 'services/mapping';

export function* getMappingGridColumns(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        resizable: true,
      },
    ];

    if (response?.columns?.length > 0) {
      let hiddenCol = ['id', 'ownerCompanyId'];
      response.columns.forEach((val) => {
        if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
          // eslint-disable-next-line no-lone-blocks

          if (val.dataType === 'datetime') {
            val = {
              ...val,
              width: 150,
              cellRenderer: formatMDYWithParam,
              resizable: true,
            };
          } else {
            val = { ...val, width: 150, resizable: true };
          }
          if (val.fieldNameCamelCase === 'mappingName') {
            val = {
              ...val,
              linkTo: '/products/mapping/config/{id}',
              minWidth: 200,
              resizable: true,
            };
          }
          if (val.fieldNameCamelCase === 'mappingDescription') {
            val = {
              ...val,
              minWidth: 250,
              resizable: true,
              flex: 1,
            };
          }
          if (val.fieldNameCamelCase === 'formatTypes') {
            val = {
              ...val,
              minWidth: 180,
              resizable: true,
              allowFilter: false,
              allowSort: false,
              suppressMenu: true,
            };
          }
          if (
            val.fieldNameCamelCase === 'ownerCompanyName' ||
            val.fieldNameCamelCase === 'createdByName'
          ) {
            val = {
              ...val,
              minWidth: 180,
              resizable: true,
            };
          }

          columns.push(val);
        }
      });
    }
    yield put(actions.getMappingGridColumnsSuccess(columns));
  } catch (error) {
    yield put(actions.getMappingGridColumnsSuccess(error));
  }
}

export function* getColumnsFilter(payload) {
  const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);
  // const filter = yield call(getFilter, response);
  return { response };
}

export function* getMappingConfigGridColumns(payload) {
  try {
    const { response } = yield call(getColumnsFilter, payload);
    let columns = [
      {
        field: '',
        checkboxSelection: true,
        filter: false,
        suppressMenu: true,
        resizable: true,
      },
    ];

    if (response?.columns?.length > 0) {
      let hiddenCol = ['id', 'mappingId'];
      response.columns.forEach((val) => {
        if (hiddenCol.indexOf(val.fieldNameCamelCase) === -1) {
          // eslint-disable-next-line no-lone-blocks
          if (val.dataType === 'targetField') {
            val = {
              ...val,
              width: 150,
              resizable: true,
            };
          } else {
            val = { ...val, width: 150, resizable: true };
          }
          if (val.fieldNameCamelCase === 'mapType') {
            val = {
              ...val,
              width: 200,
              resizable: true,
            };
          }
          if (val.fieldNameCamelCase === 'packageLevel') {
            val = {
              ...val,
              width: 250,
              resizable: true,
            };
          }

          if (val.fieldNameCamelCase === 'sourceField') {
            val = {
              ...val,
              flex: 1,
              minWidth: 150,
              resizable: true,
            };
          }
          if (val.fieldNameCamelCase === 'staticValue') {
            val = {
              ...val,
              flex: 1,
              minWidth: 200,
              resizable: true,
            };
          }
          columns.push(val);
        }
      });
    }
    yield put(actions.getMappingConfigGridColumnsSuccess(columns));
  } catch (error) {
    yield put(actions.getMappingConfigGridColumnsFailure(error));
  }
}
export function* getMappingSourceProperties(payload) {
  const response = yield call(
    servicesMapping.getMappingSourceProperties,
    payload.params
  );
  try {
    if (response?.isSuccess) {
      yield put(
        actions.getMappingSourcePropertiesSuccess(response?.data?.gridData)
      );
    } else {
      yield put(actions.getMappingSourcePropertiesError(response?.message));
    }
  } catch (error) {
    yield put(actions.getMappingSourcePropertiesError(error));
  }
}

export function* getMappingListSaga(action) {
  try {
    const response = yield call(getMappingGridService, action.payload);
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      yield put(actions.getMappingListSuccess(data));
    } else {
      yield put(actions.getMappingListError(message));
    }
  } catch (error) {
    yield put(actions.getMappingListError('error'));
  }
}

export function* getMappingConfigProperties(payload) {
  const response = yield call(
    servicesMapping.getMappingConfigProperties,
    payload.params
  );

  try {
    if (response?.isSuccess) {
      yield put(actions.getMappingConfigPropertiesSuccess(response?.data));
    } else {
      yield put(actions.getMappingConfigPropertiesFailure(response?.message));
    }
  } catch (error) {
    yield put(actions.getMappingConfigPropertiesFailure(error));
  }
}

export function* getMappingDetail(payload) {
  const response = yield call(
    servicesMapping.getMappingConfigService,
    payload.params
  );

  try {
    if (response?.isSuccess) {
      yield put(actions.getMappingDetailSuccess(response?.data));
    } else {
      yield put(actions.getMappingDetailFailure(response?.message));
    }
  } catch (error) {
    yield put(actions.getMappingDetailFailure(error));
  }
}

function* watchAll() {
  yield all([
    takeLatest(types.GET_MAPPING_GRID_COLUMNS, getMappingGridColumns),
    takeLatest(
      types.GET_MAPPING_CONFIG_GRID_COLUMNS,
      getMappingConfigGridColumns
    ),
    takeLatest(types.GET_MAPPING_SOURCE_PROPERTIES, getMappingSourceProperties),
    takeLatest(types.GET_MAPPING_GRID, getMappingListSaga),
    takeLatest(types.GET_MAPPING_CONFIG_PROPERTIES, getMappingConfigProperties),
    takeLatest(types.GET_MAPPING_CONFIG, getMappingDetail),
  ]);
}

export default watchAll;
