import React, { useState } from 'react';

import { Tooltip, Popover } from 'antd';
import ChatSearchMessage from '../chat-header/ChatSearchMessages';

const SearchMessageButton = (props) => {
  const {
    children,
    participants,
    threadInfo,
    placement = 'left',
    width,
    resultWidth,
    resultPosition,
  } = props;

  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [searchResultVisible, setSearchResultVisible] = useState(false);

  const onSearchBarVisibleChange = (visible) => {
    if (!visible) setSearchResultVisible(false);
    setSearchBarVisible(visible);
  };

  return (
    <Popover
      placement={placement}
      title={null}
      content={
        <ChatSearchMessage
          threadInfo={threadInfo}
          participants={participants}
          visible={searchResultVisible}
          setVisible={setSearchResultVisible}
          width={width}
          resultWidth={resultWidth}
          resultPosition={resultPosition}
          searchBarVisible={searchBarVisible}
        />
      }
      trigger='click'
      onVisibleChange={onSearchBarVisibleChange}
    >
      <Tooltip title='Find'>{children}</Tooltip>
    </Popover>
  );
};

export default SearchMessageButton;
