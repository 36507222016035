import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Row,
  Col,
  Form,
  Radio,
  Pagination,
  Typography,
  notification,
  Skeleton,
  Empty,
} from 'antd';

import { ProfileImage, StyledModal, ThumbnailItem } from 'common/components';
import ProductBrandEditForm from './ProductBrandEditForm';

import userSelectors from 'redux/user/selectors';

import * as productServices from 'services/product';
import * as memberServices from 'services/members';
import { useFetch } from 'hooks/useAsync';

import { FormattedMessage, useIntl } from 'react-intl';
import Messages from 'i18n/messages/product';

import noImage from 'assets/product-no-image.jpg';

//* QUERIES
import { useQueryProductCompanyInfo } from 'common/queries/product-module';

import './ProductEditBrandModal.less';

const ProductEditBrandModal = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const {
    visible,
    setVisibleEditBrand,
    productDetail,
    isAddProduct,
    setBrandId,
    setBrandThumb,
    isEdit,
    isEditProductHeader,
    editCallback,
    memberIdFromBrand,
    ...rest
  } = props;

  const userInfo = useSelector(userSelectors.makeSelectUserInfo());

  const { data: existingBrandImage, run, status } = useFetch();

  const memberId = memberIdFromBrand || userInfo?.member?.id;

  const { data: companyInfoState, refetch: refetchProductCompanyInfo } =
    useQueryProductCompanyInfo({
      productId: +productDetail?.productId,
    });

  const currentBrandImgSrc = companyInfoState?.brandImage?.fileUrl;

  // anotherBrandMode: SelectAnotherBrand or Other
  const [anotherBrandMode, setAnotherBrandMode] = useState(null);

  const [productBrandMode, setProductBrandMode] = useState(
    productDetail?.brandId ? 'EditCurrentBrand' : 'CreateNewBrand'
  );
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedLogo, setSelectedLogo] = useState(false);

  const [fileForm, setFileForm] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editLogoLoading, setEditLogoLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [newBrandLogoId, setNewBrandLogoId] = useState(null);

  const [showRequireLogoMessage, setShowRequireLogoMessage] = useState(false);

  const existingBrandImageList = existingBrandImage?.data?.gridData;
  const loading = status === 'pending';
  const totalItems = existingBrandImage?.data?.paging?.totalRecord;

  const profileImage = uploadImage
    ? uploadImage
    : productBrandMode === 'EditCurrentBrand'
    ? currentBrandImgSrc
    : noImage;

  const onCancel = () => {
    form.resetFields();

    setVisibleEditBrand(false);
  };

  const onSave = () => {
    if (anotherBrandMode === 'SelectAnotherBrand') {
      if ((isAddProduct || isEdit) && !isEditProductHeader) {
        const thumb =
          selectedLogo?.thumb100 ||
          selectedLogo?.thumb300 ||
          selectedLogo?.thumb ||
          selectedLogo?.fileUrl;

        setBrandId && setBrandId(selectedLogo?.id);
        setBrandThumb && setBrandThumb(thumb);
        onCancel();
      } else {
        callApiEditBrandImage();
      }
    } else if (anotherBrandMode === 'Other') {
      form.submit();
    }
  };

  const callApiEditBrandImage = () => {
    const formData = new FormData();
    productDetail?.productId &&
      formData.append('ProductId', productDetail?.productId);
    selectedLogo?.id && formData.append('NewBrandId', selectedLogo?.id);
    selectedLogo?.brandName &&
      formData.append('BrandName', selectedLogo?.brandName);
    formData.append('EditBrandMode', 'SelectAnotherBrand');

    setSubmitLoading(true);
    productServices
      .editBrandImageByProduct(formData)
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          notification.success({
            message: intl.formatMessage(Messages.editProductBrandSuccess),
          });

          refetchProductCompanyInfo();
          editCallback && editCallback();
          onCancel();
        } else {
          notification.error({
            message: message || intl.formatMessage(Messages.serverError),
          });
        }
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage(Messages.serverError),
        });
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onUploadImageHandler = (params) => {
    if (isEdit) {
      uploadImageForEditing(params);
      return;
    }

    uploadImageForCreating(params);
  };

  const uploadImageForEditing = (params) => {
    setEditLogoLoading(true);
    const { imageSrc } = params;
    setFileForm(params);
    setTimeout(() => {
      setUploadImage(imageSrc);
      setEditLogoLoading(false);
      setShowRequireLogoMessage(false);
    }, 100);
  };

  const uploadImageForCreating = (params) => {
    setEditLogoLoading(true);

    const formData = createFormData(params);

    memberServices
      .editBrandLogo(formData)
      .then((response) => {
        const { isSuccess, data, message } = response;
        if (isSuccess) {
          setUploadImage(params?.imageSrc);
          if (data && data.id) {
            setNewBrandLogoId(data?.id);
          }
        } else {
          notification.error({
            message: message,
          });
        }
      })
      .catch((error) => {
        notification.error({
          message:
            error?.message || 'You do not have permission to edit brand logo.',
        });
      })
      .finally(() => {
        setEditLogoLoading(false);
        setShowRequireLogoMessage(false);
      });
  };

  const createFormData = (params) => {
    const { imageFile } = params;

    const formData = new FormData();

    imageFile && formData.append('File', imageFile);
    imageFile && formData.append('FromLocal', true);
    userInfo && formData.append('MemberId', memberId);

    return formData;
  };

  const modalProps = {
    title: isAddProduct && !isEdit ? null : 'Edit Brand Image Modal',
    visible,
    width: 754,
    closable: true,
    maskClosable: false,
    onCancel: onCancel,
    onOk: onSave,
    okButtonProps: { loading: submitLoading },
    wrapClassName: 'product-edit-brand-modal',
    ...rest,
  };

  const handleChangeBrandMode = (value) => {
    setAnotherBrandMode(value);
    if (value === 'SelectAnotherBrand') {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        productBrandMode: 'SelectAnotherBrand',
      });
    }
  };

  const resetAnotherBrand = () => {
    setAnotherBrandMode('Other');
    setProductBrandMode('EditCurrentBrand');
    setSelectedLogo(false);
  };

  const selectLogoAssetItem = (item) => {
    if (selectedLogo?.id !== item?.id) {
      setSelectedLogo(item);
    } else {
      setSelectedLogo(false);
    }
  };

  const handleToggleBrandMode = (brandResponse) => {
    if (
      brandResponse?.isSuccess &&
      brandResponse?.data?.gridData?.length > 0 &&
      isEdit
    ) {
      setAnotherBrandMode('SelectAnotherBrand');
    } else {
      setAnotherBrandMode('Other');
    }
  };

  const fetchExistingBrandImage = async () => {
    if (!visible) return;

    const params = {
      pageIndex,
      pageSize,
      memberId: productDetail?.ownerId ?? memberId,
      productId: productDetail?.productId,
    };

    const service =
      isAddProduct && !isEdit
        ? productServices.getProductBrandOnAdd
        : productServices.getProductBrandImage;

    const response = await run(service(params));
    console.log('fetchExistingBrandImage  response:', response);

    handleToggleBrandMode(response);
  };

  useEffect(() => {
    fetchExistingBrandImage();
  }, [pageIndex, pageSize, visible]);

  useEffect(() => {
    if (!visible) {
      resetAnotherBrand();
    }
  }, [visible]);

  const changePageSizeHandler = (page, pageSize) => {
    setPageIndex(page);
    setPageSize(pageSize);
  };

  const renderAnotherBrand = () => {
    return (
      existingBrandImageList?.length > 0 &&
      existingBrandImageList.map((asset) => {
        const { id, fileUrl } = asset;
        const isSelected = selectedLogo.id === id;

        return (
          <Col flex={0} key={id}>
            <ThumbnailItem
              hideLabel={true}
              imageThumbnail={
                fileUrl ||
                asset?.thumbnail100 ||
                asset?.thumbSize100 ||
                asset?.thumb100 ||
                asset?.thumb
              }
              dataDetail={asset}
              onClickItemGrid={selectLogoAssetItem}
              onClickCheckboxItem={selectLogoAssetItem}
              selected={isSelected}
            />
          </Col>
        );
      })
    );
  };

  return (
    <StyledModal {...modalProps}>
      <Radio.Group
        onChange={(e) => handleChangeBrandMode(e.target.value)}
        value={anotherBrandMode}
      >
        <Radio value={'SelectAnotherBrand'}>
          <FormattedMessage {...Messages.existedBrand} />
        </Radio>
        <Radio value={'Other'}>
          <FormattedMessage {...Messages.other} />
        </Radio>
      </Radio.Group>
      {anotherBrandMode === 'SelectAnotherBrand' && (
        <Skeleton active={true} loading={loading}>
          <Row>{renderAnotherBrand()}</Row>
          {existingBrandImageList?.length > 0 ? (
            <Row className='logo-asset__pagination'>
              <Col
                span={24}
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <Pagination
                  total={totalItems}
                  current={pageIndex}
                  pageSize={pageSize}
                  showSizeChanger
                  onChange={changePageSizeHandler}
                />
                <Typography.Text strong={true} style={{ marginLeft: 8 }}>
                  {totalItems ? totalItems : ''} &nbsp;
                </Typography.Text>
                <FormattedMessage {...Messages.totalItems} />
              </Col>
            </Row>
          ) : (
            <Empty />
          )}
        </Skeleton>
      )}
      {anotherBrandMode === 'Other' && (
        <>
          <Row className='product-edit-brand'>
            <Col Col flex='110px' className='product-edit-brand__image'>
              <ProfileImage
                src={profileImage}
                isEditMode={true}
                loading={editLogoLoading}
                isDefaultSrc={true}
                style={{ width: '100px', height: '100px' }}
                onSubmit={onUploadImageHandler}
                editLogoTitle={intl.formatMessage(Messages.editLogoTitle)}
              />
              {showRequireLogoMessage ? (
                <Typography.Text type='danger'>
                  {intl.formatMessage(Messages.pleaseUpdateBrandImage)}
                </Typography.Text>
              ) : null}
            </Col>
            <Col flex='calc(100% - 120px)' className='product-edit-brand__info'>
              <ProductBrandEditForm
                form={form}
                onCancel={onCancel}
                setSubmitLoading={setSubmitLoading}
                fileForm={fileForm}
                productBrandMode={productBrandMode}
                setProductBrandMode={(value) => setProductBrandMode(value)}
                setBrandId={setBrandId}
                setBrandThumb={setBrandThumb}
                memberId={memberId}
                isAddProduct={isAddProduct}
                isEdit={isEdit}
                newBrandLogoId={newBrandLogoId}
                uploadThumb={profileImage}
                setShowRequireLogoMessage={setShowRequireLogoMessage}
                editCallback={editCallback}
                productId={productDetail?.productId}
              />
            </Col>
          </Row>
        </>
      )}
    </StyledModal>
  );
};

export default ProductEditBrandModal;
