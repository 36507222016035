import { useQueryClient, useQuery } from '@tanstack/react-query';
import * as productServices from 'services/product';

const qaSpecDocAndCerKeys = {
  docsSnapshot: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-spec-doc/cer-docs',
  ],
  certsSnapshot: (productId) => [
    'pim',
    'snapshot',
    parseInt(productId),
    'qa-spec-doc/cer-certs',
  ],
};

export const useQueryQaSpecDocumentSnapshotData = ({
  productId,
  ...options
}) => {
  return useQuery({
    queryKey: qaSpecDocAndCerKeys.docsSnapshot(productId),
    queryFn: async () => {
      const { isSuccess, data, message } =
        await productServices.getQaSpecSnapshotDataDocument({ productId });
      if (isSuccess) {
        return data;
      }

      return Promise.reject(message || 'Server Error');
    },
    ...options,
  });
};

export const useQueryQaSpecCertificateSnapshotData = ({
  productId,
  ...options
}) => {
  return useQuery({
    queryKey: qaSpecDocAndCerKeys.certsSnapshot(productId),
    queryFn: async () => {
      const { isSuccess, data, message } =
        await productServices.getQaSpecSnapshotDataCertificate({ productId });
      if (isSuccess) {
        return data;
      }

      return Promise.reject(message || 'Server Error');
    },
    ...options,
  });
};

export const getQaCertificatesDataQueryKey = (productId) => {
  return [
    'pim',
    'get-qa-spec-data-certificates',
    { params: { productId: parseInt(productId) } },
  ];
};

export const useGetQaCertificatesData = ({ productId }, options) => {
  const queryClient = useQueryClient();

  const invalidateQuery = () => {
    queryClient.invalidateQueries({
      queryKey: getQaCertificatesDataQueryKey(productId),
    });
  };
  const query = useQuery({
    queryKey: getQaCertificatesDataQueryKey(productId),
    ...options,
  });

  return { ...query, invalidateQuery };
};
