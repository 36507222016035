import React from 'react';
import { Typography } from 'antd';

const ContactCardMessageActions = ({ text, handleClick, customStyle }) => {
  return (
    <div className='contact-card-messages__actions'>
      <Typography.Title
        strong
        level={5}
        style={{ ...customStyle }}
        onClick={handleClick}
      >
        {text}
      </Typography.Title>
    </div>
  );
};

export default ContactCardMessageActions;
