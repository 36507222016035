import { useMutation } from '@tanstack/react-query';
import {
  saveProductPetNutritionFacts,
  saveQaSpecProductPetNutritionFacts,
} from 'services/product';

const nutritionFactsApi = {
  'qa-spec': saveQaSpecProductPetNutritionFacts,
  overview: saveProductPetNutritionFacts,
};

export const usePetFoodFactsLabel = ({ typeView = 'overview' }) => {
  const mutation = useMutation({
    mutationKey: ['add-label'],
    mutationFn: nutritionFactsApi[typeView],
  });

  return mutation;
};
