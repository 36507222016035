import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as globalActions from 'redux/global/actions';

import { RIBBON_OPTIONS } from 'static/Constants';
import { sleep } from 'utils/delay';

const useMountResetRibbon = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //* change ribbon to default
    const setDefaultRibbon = () => {
      const option = RIBBON_OPTIONS.HOME_BLANK;
      dispatch(globalActions.changeRibbonActions(option));
    };

    setDefaultRibbon();
  }, []);
};

const useMountHideSearchBar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //* hide search bar when mounted
    const hideSearchBar = async () => {
      await sleep(50);
      dispatch(globalActions.hideSearchBar());
    };

    hideSearchBar();
  }, []);
};

export { useMountResetRibbon, useMountHideSearchBar };
