import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Messages from 'i18n/messages/maintenance';
import { FolderAddOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

import { AddCuratedQueryControl } from 'pages/home/ribbon/components';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import { useCheckIsSuperMember } from 'hooks';

const CreateCuratedQuery = () => {
  const [visible, setVisible] = useState(false);
  const selectedQueryDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const isSuperMember = useCheckIsSuperMember();

  const handleCreateMarkup = () => {
    setVisible(true);
  };
  
  return (
    <>
      <RibbonButton
        size='small'
        icon={<FolderAddOutlined />}
        label={Messages.createCuratedQuery}
        onClick={handleCreateMarkup}
        disabled={!isSuperMember}
      />
      <AddCuratedQueryControl
        visible={visible}
        setVisible={setVisible}
        selectedQueryDetail={selectedQueryDetail}
        mode='create'
      />
    </>
  );
};

export default CreateCuratedQuery;
