import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  FileImageOutlined,
  PlaySquareOutlined,
  SnippetsOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import classNames from 'classnames';

import ProductGallery from '../ProductGallery';
import ProductDocument from './ProductDocument';
import ProductMarketingVideo from './ProductMarketingVideo';
import MultiplePanel from './multiple-panel';

import { useCheckAllowEditProducts, useGetProductFullView } from 'hooks';

import './ProductMediaContent.less';

const viewList = [
  {
    type: 'Marketing Assets',
    icon: <PlaySquareOutlined />,
    tooltip: { placement: 'bottom' },
  },
  {
    type: 'Product Imagery',
    icon: <FileImageOutlined />,
    tooltip: { placement: 'bottom' },
  },
  {
    type: 'Facts Panel',
    icon: <UnorderedListOutlined />,
    tooltip: { placement: 'bottom' },
  },
  {
    type: 'Document',
    icon: <SnippetsOutlined />,
    tooltip: { placement: 'bottomLeft' },
  },
];

const ProductMediaContent = (props) => {
  const { headerSize, getImagery, isOwner, getProductDetail } = props;

  const { id } = useParams();

  const defaultView = viewList[0].type;
  const [currentView, setCurrentView] = useState(defaultView);

  const { productFull } = useGetProductFullView({ productId: id });

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  useEffect(() => {
    if (currentView === 'Product Imagery') {
      getImagery();
    }

    return;
  }, [currentView, getImagery]);

  const mapToTabWrapperStyle = () => {
    if (!headerSize) return {};
    return {
      height: `calc(100vh - ${headerSize[1] + 140}px)`,
    };
  };

  return (
    <>
      <div className='product-media-content' style={mapToTabWrapperStyle()}>
        <Row className='product-media-content__icon-group'>
          <Col flex={1}></Col>
          <Col
            style={{
              display: 'flex',
              zIndex: 1,
              marginTop: 6,
              marginRight: 6,
            }}
          >
            {viewList?.map((item, index) => {
              return (
                <Tooltip title={item.type} key={index} {...item.tooltip}>
                  <Button
                    className={classNames({
                      'product-media-content__icon-button': true,
                      'product-media-content__icon-button--active':
                        currentView.includes(item.type),
                      'product-media-content__icon-button--disabled':
                        item?.disable,
                    })}
                    onClick={() => setCurrentView(item.type)}
                    disabled={item?.disable}
                  >
                    {item.icon}
                  </Button>
                </Tooltip>
              );
            })}
          </Col>
        </Row>
        {currentView === 'Marketing Assets' ? (
          <div className='product-media-content__view-marketing'>
            <ProductMarketingVideo
              getImagery={getImagery}
              isAllowEdit={isAllowEditProduct}
              getProductDetail={getProductDetail}
            />
          </div>
        ) : currentView === 'Product Imagery' ? (
          <div className='product-media-content__view-gallery'>
            <ProductGallery
              getImagery={getImagery}
              isOwner={isOwner}
              isAllowEdit={isAllowEditProduct}
              getProductDetail={getProductDetail}
              productId={id}
            />
          </div>
        ) : currentView === 'Document' ? (
          <div className='product-media-content__view'>
            <ProductDocument
              getImagery={getImagery}
              isAllowEdit={isAllowEditProduct}
            />
          </div>
        ) : currentView === 'Facts Panel' ? (
          <div className='product-media-content__multiple-panel'>
            {/* <ProductNutritionLabel getImagery={getImagery} /> */}
            <MultiplePanel />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default ProductMediaContent;
