import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import * as ribbonActions from 'redux/ribbon/actions';
import * as ribbonSelectors from 'redux/ribbon/selectors';

import {
  getStackType,
  checkCloseDetailRegex,
  getHardGoBackRoute,
  getSwitchStack,
} from 'utils/closeDetail';
import { forwardTo } from 'utils/common/route';

const useCloseDetail = () => {
  const history = useHistory();
  const closeDetailStackRef = useRef();
  const dispatch = useDispatch();

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  const storedStack = useSelector(ribbonSelectors.selectStoredDetailStack());

  //*  STACK
  const closeDetailStackingProcess = (nextLocation) => {
    const pathname = nextLocation.pathname;
    const closeDetailStack = closeDetailStackRef?.current;
    const isInStack =
      closeDetailStack?.name && closeDetailStack?.stack?.indexOf(pathname) > 0;
    const isInQueryStack =
      closeDetailStack?.name === 'query' && nextLocation.search === '?query=1';

    if (isInStack) return;
    if (isInQueryStack) return;

    const fullPathName = pathname + nextLocation.search;

    if (!closeDetailStack?.name) {
      createCloseDetailStack({ pathname: pathname });
      return;
    }

    const shouldPushStack = checkCloseDetailRegex({
      closeDetailStack,
      pathname: pathname,
      action: 'push',
    });

    if (shouldPushStack) {
      pushToStack({
        nextRoute: fullPathName,
      });
      return;
    }

    const shouldReplaceStack = checkCloseDetailRegex({
      closeDetailStack,
      pathname: pathname,
      action: 'replace',
    });

    if (shouldReplaceStack) {
      replaceStack({ nextRoute: fullPathName });
      return;
    }

    const switchStackName = getSwitchStack({
      pathname,
    });

    const shouldSwitchStack =
      switchStackName && switchStackName !== closeDetailStack?.name;
    if (shouldSwitchStack)
      switchStack({ switchStackName, closeDetailStack, pathname });

    createCloseDetailStack({ pathname: fullPathName });
  };

  const createCloseDetailStack = ({ pathname }) => {
    const stackType = getStackType({
      pathname,
    });

    dispatch(ribbonActions.createCloseDetailStack(stackType, pathname));
  };

  const pushToStack = ({ nextRoute }) => {
    const closeDetailStack = closeDetailStackRef?.current;

    const { name, stack } = closeDetailStack;

    dispatch(
      ribbonActions.updateCloseDetailStack({
        name,
        stack: [...stack, nextRoute],
      })
    );
  };

  const replaceStack = ({ nextRoute }) => {
    const closeDetailStack = closeDetailStackRef?.current;

    const { name, stack } = closeDetailStack;

    const newStack = [...stack];
    newStack.splice(-1, 1, nextRoute);

    dispatch(
      ribbonActions.updateCloseDetailStack({
        name,
        stack: newStack,
      })
    );
  };

  const updateStack = (stack) => {
    dispatch(
      ribbonActions.updateCloseDetailStack({
        name: closeDetailStack.name,
        stack,
      })
    );
  };

  const switchStack = ({ switchStackName, closeDetailStack }) => {
    if (!closeDetailStack?.stack) return;
    //* store current stack in redux
    dispatch(ribbonActions.storeCurrentStackToSwitch(closeDetailStack));
    //* change current stack name to switchStackName
    dispatch(
      ribbonActions.updateCloseDetailStack({
        name: switchStackName,
        stack: [],
      })
    );
    //* continue close detail process process
  };

  //* END STACK

  //* BACK
  const goBack = () => {
    if (!closeDetailStack?.stack) return;

    const stack = [...closeDetailStack?.stack];

    const backRoute = getBackRoute(stack);

    updateStack(stack);

    handleGoBack(backRoute);
  };

  const getBackRoute = (stack) => {
    stack.pop();

    const pathname = history.location.pathname;
    const backRoute = stack[stack.length - 1];

    if (pathname === backRoute) return getBackRoute(stack);

    return backRoute;
  };

  const handleGoBack = (backRoute) => {
    if (backRoute) {
      //? maybe need history.goBack()
      // history.goBack();
      history.push(backRoute); // * Fix back in evaluation form
      return;
    }

    //* check stored stack
    if (storedStack?.length) {
      backToStoreStack();
      return;
    }

    hardGoBack();
  };

  const backToStoreStack = () => {
    //* get latest stack
    const latestStack = storedStack[storedStack.length - 1];
    //* update current stack with latest stack
    dispatch(ribbonActions.updateCloseDetailStack(latestStack));

    removeCurrentStackFromStoredStack();

    const stack = latestStack?.stack;

    if (!stack) return; //* too many bugs to go on

    const backRoute = stack[stack.length - 1];
    handleGoBack(backRoute);
  };

  const hardGoBack = () => {
    const isSpecialCase = checkSpecialCase();
    const stackName = isSpecialCase
      ? getStackNameForSomeSpecialCase()
      : closeDetailStack.name;

    const route = getHardGoBackRoute(stackName);

    if (route) forwardTo(route);
    else forwardTo('/dashboard');
  };

  const removeCurrentStackFromStoredStack = () => {
    const newStoreStack = _.cloneDeep(storedStack);

    newStoreStack.pop();

    dispatch(ribbonActions.updateStoredStack(newStoreStack));
  };

  const checkSpecialCase = () => {
    const pathname = history.location.pathname;

    const specialCaseList = ['/manage-shared'];

    return specialCaseList.includes(pathname);
  };

  const getStackNameForSomeSpecialCase = () => {
    const pathname = history.location.pathname;
    const actionCollections = {
      '/manage-shared': getStackNameForManageSharing,
    };

    const action = actionCollections[pathname];

    return action ? action() : closeDetailStack.name;
  };

  const getStackNameForManageSharing = () => {
    const location = history.location;

    const stackNameCollections = {
      '?type=Asset': 'assetDetail',
      '?type=Folder': 'folderDetail',
      '?type=Member': 'memberDetail',
      '?type=Reporting': 'reportingDetail',
      '?type=Product': 'productDetail',
    };

    const stackName = stackNameCollections[location.search + ''];

    return stackName ? stackName : closeDetailStack.name;
  };
  //* END BACK

  useEffect(() => {
    closeDetailStackRef.current = closeDetailStack;
    //eslint-disable-next-line
  }, [JSON.stringify(closeDetailStack)]);

  return {
    closeDetailStack,
    closeDetailStackingProcess,
    updateStack,
    goBack,
  };
};
export default useCloseDetail;
