import React, { useState } from 'react';

import { FormOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';
import { useFetch } from 'hooks/useAsync';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

import Messages from 'i18n/messages/home';
import FolderEditModal from 'pages/folders/components/modal/FolderEditModal';
import { sleep } from 'utils/delay';
import { getFolderShortDetail } from 'services/folder';

export const WIDTH_CONTANT_PANE = 500;

const EditFolder = (props) => {
  const { selectedFolders, disabled } = props;

  const { data: response, run: runFetchFolderShortContent } = useFetch();

  const [visible, setVisible] = useState(false);
  const reloadPage = useDispatchReloadPage();

  const checkDisableEdit = () => {
    if (disabled) return true;

    if (selectedFolders.length !== 1) return true;

    //* check selected folder
    const selectedFolder = selectedFolders[0];
    const isFolder = selectedFolder?.type?.toLowerCase() === 'folder';
    const isOwner = selectedFolder?.isOwner;
    const isEditable = selectedFolder?.editable;

    if (!isFolder) return true;

    return !isOwner && !isEditable;
  };

  const clickEditFolderHandler = () => {
    runFetchFolderShortContent(
      getFolderShortDetail({
        id: selectedFolders?.[0].id,
        IsLoadContent: false,
      })
    );
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onSaveSuccess = async () => {
    await sleep(200);
    setVisible(false);
    reloadPage({ clearSelection: true });
  };

  return (
    <>
      <RibbonButton
        size='small'
        icon={<FormOutlined />}
        label={Messages.editFoldersRibbon}
        disabled={checkDisableEdit()}
        onClick={clickEditFolderHandler}
      />
      {visible ? (
        <FolderEditModal
          visible={visible}
          onCancel={onCancel}
          onSaveSuccess={onSaveSuccess}
          folderInfo={response?.data}
          idSelected={selectedFolders?.[0]?.id}
        />
      ) : null}
    </>
  );
};

export default EditFolder;
