import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { FolderAddOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import RibbonButton from 'common/components/button/RibbonButton';

import * as helpMaintenanceActions from 'pages/maintenance/controllers/actions';

import Messages from 'i18n/messages/maintenance';

const CreateCategory = (props) => {
  const { isCreating } = props;
  const dispatch = useDispatch();

  const isDisabled = isCreating;

  const onClickHandler = () => {
    dispatch(helpMaintenanceActions.toggleCreateCategory());
  };

  return (
    <RibbonButton
      size='small'
      className={classnames({
        'help-category__ribbon-button--active': true,
      })}
      icon={<FolderAddOutlined />}
      label={Messages.newCategory}
      onClick={onClickHandler}
      disabled={isDisabled}
      toggle={isCreating}
    />
  );
};

CreateCategory.propTypes = {
  selectedCategory: PropTypes.array,
  isCreating: PropTypes.bool,
};

export default CreateCategory;
