import React from 'react';
import { useSelector } from 'react-redux';
import { ViewLayout, AdvanceStack } from './components';
import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { RibbonBar, RibbonButton, RibbonDivider } from 'common/components';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';
import Addition from './components/sections/home-grid/AdditionSection';
import Categories from './components/sections/home-grid/Categories';
import Execute from './components/sections/home-grid/Execute';
import BackControl from './components/controls/home/Back';
import FilterPackageSection from './components/sections/product/FilterPackageSection';
import FilterProductSection from './components/sections/product/FilterProductSection';
import HistorySection from './components/sections/product/HistorySection';

import OpenProduct from './components/sections/product/OpenProduct';
import ActionProduct from './components/sections/product/ActionProduct';
import ShareSection from './components/sections/shared/ShareSection';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';
import * as ribbonSelectors from 'redux/ribbon/selectors';

import * as selectorProduct from 'pages/branded-products/controllers/selectors';
import * as selectorsGlobal from 'redux/global/selectors';

import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';

const Products = () => {
  const selectedProducts = useSelector(
    gridViewSelectors.makeSelectDetailCurrentITemsSelection()
  );
  const isDisplayProductNew = useSelector(
    selectorsGlobal.selectDisplayProductNew()
  );

  const products = useSelector(selectorProduct.makeSelectProducts());

  const isFavoriteRoute = useCheckFavoriteRoute();

  const searchCategoriesEffected = useSelector(
    gridViewSelectors.makeSelectSearchCategoriesList()
  );

  const { productText, favProductText } = useSelector(
    selectorsGlobal.selectSelectSearchText()
  );

  const searchText = isFavoriteRoute ? favProductText : productText;

  const filterProducts = useSelector(selectorsGlobal.selectFilterProducts());

  const queryConditions = useSelector(
    window.location.pathname === '/products'
      ? gridViewSelectors.makeSelectProductsQuery()
      : gridViewSelectors.makeSelectFavProductsQuery()
  );

  const closeDetailStack = useSelector(
    ribbonSelectors.selectCloseDetailStack()
  );

  let disabledFilterSearch = true;
  if (
    searchCategoriesEffected?.length > 0 ||
    searchText?.length > 0 ||
    filterProducts?.length > 0 ||
    queryConditions?.length > 0
  ) {
    disabledFilterSearch = false;
  }
  if (products?.length === 0 || !products) {
    disabledFilterSearch = true;
  }

  const additionDisabled =
    selectedProducts?.length === 0 && disabledFilterSearch;

  const isDisabledCloseDetail =
    window.location.search !== '?query=1' ||
    closeDetailStack?.name !== 'query' ||
    closeDetailStack?.stack.length < 2;

  return (
    <RibbonBar>
      <ManageSharingSection />
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <DetailSection />
      </Can>
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <ViewLayout disabledGridQuery={isDisplayProductNew} />
      </Can>

      <RibbonDivider />
      <Addition
        type='product'
        selectedItemList={selectedProducts}
        isDisabled={additionDisabled}
        disabledFilterSearch={disabledFilterSearch}
        searchCategoriesEffected={searchCategoriesEffected}
        searchText={searchText}
        filterProducts={filterProducts}
        queryConditions={queryConditions}
        isFavoriteView={isFavoriteRoute}
      />
      <ShareSection selectedItemList={selectedProducts} />
      <RibbonDivider />
      {/* TODO: Add role permission later */}
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <OpenProduct />
      </Can>

      <RibbonDivider />
      <ActionProduct />

      <RibbonDivider />
      <FilterPackageSection />
      <Categories />
      <FilterProductSection />
      {/* <RibbonDivider /> */}
      {/* <SubscriptionSection />
      <SyndicationSection /> */}
      <RibbonDivider />

      {/* TODO: Add role permission later */}
      <Execute entityType='Product' />
      <RibbonDivider />
      <HistorySection />

      {/* <PublicationSetup products={selectedProducts} />
      <GDSNPublication products={selectedProducts} /> */}
      <Can I={ABILITY_ACTION.VIEW} a={ABILITY_SUBJECT.PRODUCT}>
        <RibbonDivider />
        <AdvanceStack
          isNested
          selectedItemList={selectedProducts}
          disabledAdvanceFilter={isDisplayProductNew}
          // disabledAdvanceFilter={true}
        />
      </Can>
      <BackControl ribbonSide='right' isDisabled={isDisabledCloseDetail} />
    </RibbonBar>
  );
};

export default Products;
