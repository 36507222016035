import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'antd';

import { OverviewInfo, OverviewLocations } from '../index';

import userSelectors from 'redux/user/selectors';
import * as userActions from 'redux/user/actions';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

import { useRefetchUserProfile } from 'pages/user-profile/components/hook/useFetchUserProfile';

import './UserProfileOverview.less';

const UserProfileOverview = (props) => {
  const { userProfile, editable, loginUserInfo } = props;

  const { fetch: fetchUserProfile } = useRefetchUserProfile();

  const userProfileLocations = useSelector(
    userSelectors.makeSelectUserProfileLocations()
  );

  const primaryAddress = userProfile?.primaryAddress;
  const [centerData, setCenterData] = useState(primaryAddress);
  const memberId = userProfile?.memberId || loginUserInfo?.member?.id;

  useEffect(() => {
    setCenterData(primaryAddress);
  }, [primaryAddress]);

  const userLocation = userProfileLocations?.data?.gridData;

  const handleGetUserProfile = () => {
    fetchUserProfile();
  };

  return (
    <Row className='user-profile-overview'>
      <Col span={24} style={{ paddingRight: 8 }}>
        <OverviewInfo
          overviewInfo={userProfile}
          officeList={userLocation}
          centerData={centerData}
          handleGetUserProfile={handleGetUserProfile}
          editable={editable}
          memberId={memberId}
        />
        <Can I={ABILITY_ACTION.VIEW} this={ABILITY_SUBJECT.USER}>
          <OverviewLocations
            changeRow={(item) => setCenterData(item)}
            editable={editable}
            userProfile={userProfile}
          />
        </Can>
      </Col>
    </Row>
  );
};

export default UserProfileOverview;
