import produce from 'immer';
import * as types from './constants';
import _ from 'lodash';

import {
  mapToCommunicationDetail,
  mapToCommunicationList,
} from './mapToReducerState';

const view = ['threadList', 'threadMsg'];
const defaultView = view[0];

export const initialState = {
  communicationDetail: null,
  communicationList: {
    loading: false,
    data: false,
    error: false,
    hasMore: false,
    loadingMore: false,
  },
  isFullView: null,
  showMinimizedThreadList: false,
  toggleChatBox: true,
  minimizedChatThreadList: [],
  participants: null,
  threadList: null,
  chatContentDetail: null,
  chatThreadMessages: [],
  signalRConnection: null,
  signalRConnectionErr: null,
  threadInfo: null,
  threadInfoId: null,
  threadInfoError: null,
  statusThreadInfo: 'idle',
  statusThreadMsg: 'idle',
  toggleNewMessage: false,
  accountSend: null,
  selectedSearchResult: null,
  selectedSearchResultPosition: null,
  lastMessage: null,
  isFinishLoadDown: true,
  isShowWarningModal: false,

  unreadMessages: [],

  activeThreadInfo: null,

  indexChatFile: null,
  isScrollChatThreadBottom: false,
  isChatSpinningOn: false,

  chatView: defaultView,
  isFocusChat: false,
};

/* eslint-disable default-case, no-param-reassign */
const chatReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_COMMUNICATION_DETAIL_ITEM: // convert to GET_THREAD_MESSAGES
      case types.GET_COMMUNICATION_DETAIL_ITEM_FAIL:
      case types.GET_COMMUNICATION_DETAIL_ITEM_SUCCESS:
        mapToCommunicationDetail(action, draft);
        break;
      case types.MINIMIZE_CHAT:
        draft.showMinimizedThreadList = true;
        draft.toggleChatBox = false;
        break;
      case types.TOGGLE_CHAT_BOX:
        draft.toggleChatBox = !state.toggleChatBox;
        break;
      case types.MINIMIZE_CHAT_THREAD:
        draft.minimizedChatThreadList = action.payload;
        draft.showMinimizedThreadList = true;
        break;
      case types.GET_COMMUNICATION_LIST:
        draft.communicationList = {
          loading: true,
          error: null,
          data: null,
          hasMore: false,
        };

        break;
      case types.GET_COMMUNICATION_LIST_SUCCESS:
        draft.communicationList = {
          loading: false,
          data: action?.payload?.gridData,
          hasMore: action?.payload?.gridData?.length === 20 ? true : false,
        };
        break;
      case types.GET_COMMUNICATION_LIST_FAIL:
        draft.communicationList = {
          loading: false,
          data: null,
        };
        break;
      case types.GET_COMMUNICATION_LIST_MORE:
      case types.GET_COMMUNICATION_LIST_MORE_SUCCESS:
      case types.GET_COMMUNICATION_LIST_MORE_FAIL:
        mapToCommunicationList(action, draft, state);
        break;
      case types.GET_THREAD_LIST_FOR_SUPPORTER:
        break;
      case types.GET_THREAD_LIST_FOR_SUPPORTER_SUCCESS:
        draft.threadList = action.payload;
        break;
      case types.GET_THREAD_LIST_FOR_SUPPORTER_FAIL:
        break;
      case types.GET_CHAT_CONTENT_DETAIL:
        break;
      case types.GET_CHAT_CONTENT_DETAIL_SUCCESS:
        draft.chatContentDetail = action.payload;
        break;
      case types.GET_CHAT_CONTENT_DETAIL_FAIL:
        break;
      case types.GET_THREAD_MESSAGES:
        draft.statusThreadMsg = 'loading';
        break;

      case types.GET_THREAD_MESSAGES_SUCCESS:
        draft.statusThreadMsg = 'success';
        draft.chatThreadMessages = action.messages;
        break;

      case types.GET_THREAD_MESSAGES_FAIL:
        draft.statusThreadMsg = 'error';
        break;

      case types.SET_NEW_MSG: {
        const newThreadMsg = state.chatThreadMessages.filter(
          (msg) => msg.messageId !== action.msgInfo.messageId
        );
        draft.chatThreadMessages = [action.msgInfo, ...newThreadMsg];
        break;
      }

      case types.FILTER_NEW_MSG_IN_THREAD_MSG:
        const newThreadMsg = state.chatThreadMessages.filter(
          (msg) => msg.messageId !== action.newMsg.messageId
        );

        draft.chatThreadMessages = newThreadMsg;
        break;

      case types.GET_THREAD_INFO:
        draft.statusThreadInfo = 'loading';
        draft.threadInfo = null;
        break;

      case types.GET_THREAD_INFO_SUCCESS: {
        draft.threadInfo = action.threadInfo;
        draft.threadInfoError = null;
        if (action.threadInfo) {
          draft.statusThreadInfo = 'success';
        } else {
          draft.statusThreadInfo = 'idle';
        }
        break;
      }

      case types.GET_THREAD_INFO_ERROR:
        draft.threadInfo = null;
        draft.statusThreadInfo = 'error';
        draft.threadInfoError = action.error;
        draft.threadInfoId = action.id;

        break;
      case types.REMOVE_THREAD:
        const newThreadList = state.communicationList.data?.filter(
          (item) => item.threadId !== action.id
        );

        draft.communicationList.data = newThreadList;
        break;

      case types.RESET_STATUS_THREAD_INFO:
        draft.statusThreadInfo = 'idle';
        break;

      case types.SEND_MESSAGE: {
        const newThreadMessages = [action.payload, ...state.chatThreadMessages];
        draft.chatThreadMessages = newThreadMessages;
        draft.toggleNewMessage = true;
        break;
      }

      case types.SEND_MESSAGE_SUCCESS: {
        const indexMessage = state.chatThreadMessages.findIndex(
          (message) => message?.uniqueId === action.payload.uniqueId
        );
        draft.chatThreadMessages[indexMessage].status = 'success';
        draft.chatThreadMessages[indexMessage].messageId =
          action.payload.messageId;
        break;
      }

      case types.SEND_MESSAGE_ERROR: {
        const indexMessage = state.chatThreadMessages.findIndex(
          (message) => message?.uniqueId === action.payload.uniqueId
        );
        draft.chatThreadMessages[indexMessage].status = 'error';
        draft.chatThreadMessages[indexMessage].messageId =
          action.payload.messageId;
        break;
      }

      case types.TOGGLE_NEW_MESSAGE:
        draft.toggleNewMessage = action.value;
        break;
      case types.SET_UNREAD_MESSAGES:
        draft.unreadMessages = action.messages;
        break;
      case types.SET_CLEAR_UNREAD_MESSAGES:
        let unreadMsg = [...state.unreadMessages];
        draft.chatThreadMessages = [
          ...unreadMsg.slice().reverse(),
          ...state.chatThreadMessages,
        ];
        draft.unreadMessages = [];
        break;

      case types.SAVE_ACCOUNT_CHAT:
        draft.accountSend = action.payload;
        break;

      case types.UPDATE_COMMUNICATION_THREAD_LIST:
        draft.communicationList.data = action?.payload;
        break;

      case types.RECEIVE_SENT_MESSAGE_SAME_ACCOUNT:
        const indexFoundMsg = state.chatThreadMessages.findIndex(
          (msg) => msg.messageId === action.newMsg.messageId
        );

        if (indexFoundMsg === -1) {
          draft.chatThreadMessages = [
            action.newMsg,
            ...state.chatThreadMessages,
          ];
        }
        break;

      case types.SAVE_ACTIVE_THREAD_INFO:
        draft.activeThreadInfo = action.threadInfo;
        break;

      case types.RESET_THREAD_INFO:
        draft.threadInfo = null;
        break;

      case types.RESET_STATUS_MSG_THREAD:
        draft.statusThreadMsg = 'idle';
        break;
      case types.UPDATE_SHOW_THREAD_WARNING_MODAL:
        draft.isShowWarningModal = action.value;
        break;
      case types.UPDATE_THREAD_INFO_ERROR:
        draft.threadInfoError = null;
        break;

      case types.SAVE_INDEX_CHAT_FILE:
        draft.indexChatFile = action.index;
        break;

      case types.SELECT_SEARCH_RESULT:
        draft.selectedSearchResult = action.payload;
        break;

      case types.UPDATE_FINISH_LOAD_DOWN:
        draft.isFinishLoadDown = action.payload;
        break;

      case types.UPDATE_SEARCH_RESULT_POSITION:
        draft.selectedSearchResultPosition = action.payload;
        break;

      case types.UPDATE_LAST_MESSAGE:
        draft.lastMessage = action.payload;
        break;

      case types.SCROLL_CHAT_THREAD_BOTTOM:
        draft.isScrollChatThreadBottom = action.status;
        break;

      case types.TOGGLE_CHAT_SPINNING:
        draft.isChatSpinningOn = action.status;
        break;

      case types.RESET_CHAT_VIEW:
        draft.chatView = defaultView;
        break;
      case types.SET_CHAT_VIEW:
        draft.chatView = action.payload;
        break;

      case types.SET_IS_FOCUS_CHAT:
        draft.isFocusChat = action.status;
        break;

      default:
        break;
    }
  });

export default chatReducer;
