import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';

import Messages from 'i18n/messages/apl';

import { ButtonSmallIcon } from 'common/components';

const SendQuestions = (props) => {
  const { setSendType, setSendModalVisible, productList } = props;

  const onClickBtn = () => {
    setSendType('question');
    setSendModalVisible(true);
  };

  const disabled = productList?.length === 0;

  return (
    <ButtonSmallIcon
      className='button-item-dropdown'
      icon={<QuestionCircleOutlined />}
      label={Messages.sendQuestions}
      disabled={disabled}
      onClick={onClickBtn}
    />
  );
};

export default SendQuestions;
