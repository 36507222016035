import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// import useQuery from 'hooks/useQuery';
import { Button, Row, Col, message, Tooltip } from 'antd';
import { MinusOutlined, CloseOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';

//* SAGA
import * as globalActions from 'redux/global/actions';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

//* DEFINE
import { OPEN_ITEM } from 'static/Constants';

//* STYLING
import './OpenItemContentPane.less';

const OpenItemContentPane = (props) => {
  const TYPE_DEFINE = useMemo(
    () => [
      OPEN_ITEM.TYPE.ASSET_CONTENT_PANE,
      OPEN_ITEM.TYPE.MEMBER_CONTENT_PANE,
      OPEN_ITEM.TYPE.MEMBER_MAINTENANCE_CONTENT_PANE,
      OPEN_ITEM.TYPE.FOLDER_CONTENT_PANE,
      OPEN_ITEM.TYPE.REPORTING_CONTENT_PANE,
      OPEN_ITEM.TYPE.PRODUCT_CONTENT_PANE,
    ],
    []
  );

  const {
    config,
    children,
    hideOpenItemBtn,
    onClickCloseBtnCallback,
    //* data
    reportingData,
    folderInfo,
    memberInfo,
    productInfo,
  } = props;
  let elements = React.Children.toArray(children);
  let openItemUid = uuidv4().toString();

  const openItemType = _.get(config, 'type');

  const configActionOpenItems = {};

  const handleSetConfigActionItems = (data) => {
    for (const property in data) {
      configActionOpenItems[property] = data[property];
    }
  };

  const dispatch = useDispatch();

  const getItemData = useCallback(() => {
    switch (openItemType) {
      case OPEN_ITEM.TYPE.ASSET_CONTENT_PANE:
        return _.find(elements, { key: '.$asset-content-pane' })?.props
          ?.assetData;

      case OPEN_ITEM.TYPE.MEMBER_CONTENT_PANE:
        return { memberInfo };

      case OPEN_ITEM.TYPE.MEMBER_MAINTENANCE_CONTENT_PANE:
        return _.find(elements, { key: '.$member-maintenance-content-pane' })
          ?.props?.memberData;

      case OPEN_ITEM.TYPE.FOLDER_CONTENT_PANE:
        return folderInfo;

      case OPEN_ITEM.TYPE.REPORTING_CONTENT_PANE:
        return reportingData;

      case OPEN_ITEM.TYPE.PRODUCT_CONTENT_PANE:
        return productInfo;

      default:
        break;
    }
  }, [
    //todo - config,
    elements,
    //todo,
    memberInfo,
    productInfo,
    folderInfo,
    openItemType,
    reportingData,
  ]);

  const getActionCallBack = useCallback(() => {
    if (TYPE_DEFINE.includes(openItemType)) {
      configActionOpenItems.key = OPEN_ITEM.KEY.PANEL_VIEW;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, openItemUid, openItemType]);

  const onClickCloseBtnHandler = () => {
    dispatch(actionsGridView.updateVisible(false));
    onClickCloseBtnCallback && onClickCloseBtnCallback();
  };

  const handleClickActionCallback = useCallback(() => {
    getActionCallBack();
    const data = {
      ...configActionOpenItems,
      config,
      openItemUid,
    };
    handleSetConfigActionItems(data);
    dispatch(
      globalActions.updateOpenItem({
        uid: openItemUid,
        type: _.get(config, 'type'),
        mode: _.get(config, 'mode'),
        url: _.get(config, 'url'),
        name: _.get(config, 'name'),
        itemData: getItemData(),
        activated: false,
        createdTime: Date.now(),
        configActionOpenItems,
      })
    );
    dispatch(actionsGridView.updateVisible(false));
    message.success({
      content: 'Added Open Item Successfully',
      key: 'openItemContentPane',
      duration: 2,
    });
  }, [config, openItemUid, getActionCallBack, getItemData, dispatch]);

  return (
    <React.Fragment>
      <Row className='open-item-content-pane__header'>
        <Col flex='auto' />
        <Col>
          <Tooltip title='Send to Open Items' placement='topRight'>
            {!hideOpenItemBtn ? (
              <Button
                className='open-item-content-pane__minimize-btn'
                onClick={() => handleClickActionCallback()}
              >
                <MinusOutlined />
              </Button>
            ) : null}
          </Tooltip>
          <Button
            className='open-item-content-pane__minimize-btn'
            onClick={onClickCloseBtnHandler}
          >
            <CloseOutlined />
          </Button>
        </Col>
      </Row>
      {children}
    </React.Fragment>
  );
};

OpenItemContentPane.propTypes = {
  //? config - open item config
  config: PropTypes.object,
  hideOpenItemBtn: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onClickCloseBtnCallback: PropTypes.func,
};

export default OpenItemContentPane;
