import { useRef, useEffect, useCallback } from 'react';
import { checkRouteBlocked, checkRouteBlockConfirmStatus } from './utils';
import { dispatchCustomReactRouterBlockingEvent } from './event';

const useRoutingConfirm = () => {
  const refIntervalCheck = useRef();

  useEffect(() => {
    return () => {
      refIntervalCheck.current && clearInterval(refIntervalCheck.current);
    };
  }, []);

  const routingConfirmPromise = (blockId) => {
    const isRouteBlocked = checkRouteBlocked();

    if (!isRouteBlocked) return Promise.resolve(true);

    return new Promise((resolve, reject) => {
      refIntervalCheck.current = setInterval(() => {
        const confirmStatus = checkRouteBlockConfirmStatus(blockId);

        if (confirmStatus === true) {
          clearInterval(refIntervalCheck.current);
          //* all confirm yes => disable route unblock
          global.routeUnblock();
          resolve(true);
        }

        if (confirmStatus === false) {
          clearInterval(refIntervalCheck.current);
          //* if one cancel routing => all will be re-lock by is prevented routing value
          Object.entries(window.urlBlocked).forEach(([blockId, value]) => {
            window.urlBlocked[blockId] = {
              ...value,
              blocked: value?.isPreventRouting,
              confirmStatus: null,
            };
          });

          resolve(false);
        }
      }, 100);
    });
  };

  const watchRoutingConfirm = useCallback(async ({ blockId } = {}) => {
    return routingConfirmPromise(blockId);
  }, []);

  const triggerAndWatchRoutingConfirm = useCallback(
    async ({ blockId } = {}) => {
      dispatchCustomReactRouterBlockingEvent();
      return routingConfirmPromise(blockId);
    },
    []
  );

  useEffect(() => {
    return () => {
      if (refIntervalCheck.current) {
        clearInterval(refIntervalCheck.current);
      }
    };
  }, []);

  return { watchRoutingConfirm, triggerAndWatchRoutingConfirm };
};

export { useRoutingConfirm };
