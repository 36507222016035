import produce from 'immer';
import * as types from './constants';

export const initialState = {
  gridLoading: false,
  gridColumns: [],
  markupList: [],

  markupForm: {
    id: null,
    statusSubmit: 'idle',
    statusClone: 'idle',
    error: null,

    isEdit: false,
    isCreate: false,
    isClone: false,
  },
  pageSize: 20,
  pageNumber: 1,
  gridTotal: 0,
  detailData: {},
  detailError: null,
  isUploadMarkupMediaOpen: false,

  // Curated Query
  curatedQueryLoading: false,
  curatedQueryColumns: [],
  curatedQuery: [],
  curatedQueryPageSize: 20,
  curatedQueryPageNumber: 1,
  curatedQueryTotal: 0,
  curatedQueryError: false,
  curatedQuerySearch: '',
  reloadCuratedQueryPage: false,
  // Curated Query Short Details
  curatedQueryShortDetailLoading: false,
  curatedQueryShortDetailData: {},
  curatedQueryShortDetailError: false,
};

/* eslint-disable default-case, no-param-reassign */
const markupMaintenanceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MARKUP_MAINTENANCE_GRID_COLUMNS:
        draft.gridLoading = true;
        break;
      case types.GET_MARKUP_MAINTENANCE_GRID_COLUMNS_SUCCESS:
        draft.gridLoading = false;
        draft.gridColumns = action.payload;
        break;
      case types.GET_MARKUP_MAINTENANCE_GRID_COLUMNS_FAILURE:
        draft.gridLoading = false;
        break;

      case types.CREATE_MARKUP:
        draft['markupForm'].statusSubmit = 'loading';
        draft['markupForm'].statusClone = 'idle';
        draft['markupForm'].isClone = false;
        draft['markupForm'].isCreate = false;
        break;
      case types.CREATE_MARKUP_SUCCESS:
        draft['markupForm'].statusSubmit = 'success';
        draft['markupForm'].id = action.id;
        break;
      case types.CREATE_MARKUP_ERROR:
        draft['markupForm'].statusSubmit = 'error';
        draft['markupForm'].error = action.error;
        break;

      case types.CLONE_MARKUP:
        draft['markupForm'].statusClone = 'loading';
        break;
      case types.CLONE_MARKUP_SUCCESS:
        draft['markupForm'].statusClone = 'success';
        break;
      case types.CLONE_MARKUP_ERROR:
        draft['markupForm'].statusClone = 'error';
        break;

      case types.RESET_SAVE_MARKUP:
        draft['markupForm'].statusSubmit = 'idle';
        draft['markupForm'].statusClone = 'idle';
        draft['markupForm'].isClone = false;
        draft['markupForm'].isCreate = false;
        break;

      case types.ENABLE_MARKUP_MAINTENANCE_EDIT:
        draft['markupForm'].isCreate = false;
        draft['markupForm'].isEdit = true;
        break;

      case types.DISABLE_MARKUP_MAINTENANCE_EDIT:
        draft['markupForm'].isEdit = false;
        break;

      case types.ENABLE_MARKUP_MAINTENANCE_CREATE:
        draft['markupForm'].isCreate = true;
        draft['markupForm'].isEdit = false;
        break;

      case types.CONFIRM_CLONE_MARKUP_MAINTENANCE:
        draft['markupForm'].isClone = true;
        break;

      case types.SAVE_ID_MARKUP:
        draft['markupForm'].id = action.data;
        break;

      case types.GET_MARKUP_MAINTENANCE_LIST:
        draft.gridLoading = true;
        draft.pageSize = action.pageSize;
        draft.pageNumber = action.pageIndex;
        break;
      case types.GET_MARKUP_MAINTENANCE_LIST_SUCCESS:
        draft.gridLoading = false;
        draft.markupList = action.payload;
        draft.gridTotal = action.total;
        break;
      case types.GET_MARKUP_MAINTENANCE_LIST_FAILURE:
        draft.gridLoading = false;
        break;
      case types.MARKUP_DISABLED_VIEW_MODE:
        draft.isDetailsView = action.isDetailsView;
        break;
      case types.GET_MARKUP_CONTENT_DETAIL:
        draft.detailLoading = true;
        draft.detailData = null;
        draft.detailError = null;
        break;
      case types.GET_MARKUP_CONTENT_DETAIL_SUCCESS:
        draft.detailLoading = false;
        draft.detailData = action.data;
        break;
      case types.GET_MARKUP_CONTENT_DETAIL_ERROR:
        draft.detailLoading = false;
        draft.detailData = null;
        draft.detailError = action.error;
        break;
      case types.TOGGLE_MARKUP_UPLOAD_MEDIA:
        draft.isUploadMarkupMediaOpen = action.status;
        break;

      // Curated Query Markup
      case types.GET_CURATED_QUERY_GRID_COLUMNS:
        draft.curatedQueryLoading = true;
        draft.curatedQueryColumns = [];
        break;
      case types.GET_CURATED_QUERY_GRID_COLUMNS_SUCCESS:
        draft.curatedQueryLoading = false;
        draft.curatedQueryColumns = action.columns;
        break;
      case types.GET_CURATED_QUERY_GRID_COLUMNS_ERROR:
        draft.curatedQueryLoading = false;
        draft.curatedQueryColumns = [];
        draft.curatedQueryError = action.error;
        break;
      case types.GET_CURATED_QUERY_LIST:
        draft.curatedQueryLoading = true;
        draft.curatedQueryError = false;
        draft.curatedQueryPageSize = action.pageSize;
        draft.curatedQueryPageNumber = action.pageIndex;
        draft.curatedQuerySearch = action['Search.SearchText'];
        draft.reloadCuratedQueryPage = false;
        break;
      case types.GET_CURATED_QUERY_LIST_SUCCESS:
        draft.curatedQueryLoading = false;
        draft.curatedQuery = action.curatedQuery;
        draft.curatedQueryTotal = action.total;
        break;
      case types.GET_CURATED_QUERY_LIST_ERROR:
        draft.curatedQueryLoading = false;
        draft.curatedQueryTotal = 0;
        draft.curatedQuery = [];
        draft.curatedQueryError = action.error;
        break;
      case types.GET_CURATED_QUERY_SHORT_DETAIL:
        draft.curatedQueryShortDetailLoading = true;
        draft.curatedQueryShortDetailData = {};
        draft.curatedQueryShortDetailError = null;
        break;
      case types.GET_CURATED_QUERY_SHORT_DETAIL_SUCCESS:
        draft.curatedQueryShortDetailLoading = false;
        draft.curatedQueryShortDetailData = action.data;
        break;
      case types.GET_CURATED_QUERY_SHORT_DETAIL_ERROR:
        draft.curatedQueryShortDetailLoading = false;
        draft.curatedQueryShortDetailData = {};
        draft.curatedQueryShortDetailError = action.error;
        break;
      default:
        break;
    }
  });

export default markupMaintenanceReducer;
