import React from 'react';
import { useSelector } from 'react-redux';

import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import { Row, Col, Typography, Empty } from 'antd';
import ImageUnit from './ImageUnit';

import * as productDetailSelectors from '../../controllers/selectors';

import { useGetProductEnums } from 'hooks';
import { findDimensionEnumDisplay } from '../../mappers/mapToEnums';

import { PACKAGE_LEVELS } from 'static/Constants';

import Messages from 'i18n/messages/product';
import { FormattedMessage } from 'react-intl';

import './PackageContentDrawer.less';

const { Text, Paragraph } = Typography;

const PackageContentDrawer = (props) => {
  const { productItems } = props;

  const { productEnums } = useGetProductEnums();

  const displayMode = useSelector(
    productDetailSelectors.makeSelectHierarchyShortDetailsDisplayMode()
  );

  const getDisplayPackageProductList = () => {
    if (!productItems) return [];

    const sortedPackageProductList = cloneDeep(productItems)?.sort(
      (productA, productB) => {
        const levelIndexA = PACKAGE_LEVELS.indexOf(productA?.packageLevel);
        const levelIndexB = PACKAGE_LEVELS.indexOf(productB?.packageLevel);

        //* unknown package level must be at the end
        if (levelIndexA === -1) return 1;

        return levelIndexA - levelIndexB;
      }
    );

    return sortedPackageProductList;
  };

  const displayProductItems = getDisplayPackageProductList();

  const leftPackageInfo = [
    { label: <FormattedMessage {...Messages.level} /> },
    { label: <FormattedMessage {...Messages.GTIN} /> },
    { label: <FormattedMessage {...Messages.UPC12} /> },
    { label: <FormattedMessage {...Messages.UPC11} /> },
    { label: <FormattedMessage {...Messages.UPC10} /> },
    { label: <FormattedMessage {...Messages.EAN13} /> },
    { label: <FormattedMessage {...Messages.EAN8} /> },
    { label: <FormattedMessage {...Messages.height} /> },
    { label: <FormattedMessage {...Messages.width} /> },
    { label: <FormattedMessage {...Messages.depth} /> },
    { label: <FormattedMessage {...Messages.weight} /> },
  ];

  const renderLeftInfo = () => {
    return (
      <React.Fragment>
        <Text style={{ height: 158, display: 'block' }}></Text>
        {leftPackageInfo.map((item, index) => {
          return (
            <Paragraph
              strong={true}
              className={index === 7 ? 'margin-top' : ''}
              key={index}
            >
              {item.label}
            </Paragraph>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <Row className='package-content-drawer scroller'>
      {displayProductItems?.length < 1 && (
        <Col span={24} className='package-content-drawer__no-data'>
          <Empty />
        </Col>
      )}
      {displayProductItems?.length > 0 && (
        <>
          <Col flex='110px' className='package-content-drawer__left-info'>
            {renderLeftInfo()}
          </Col>
          <Col flex='calc(100% - 110px)'>
            <div className='package-content-drawer__right-info scroller'>
              {displayProductItems?.length > 0 &&
                displayProductItems.map((productItem, index) => {
                  const displayDimensionUOM = findDimensionEnumDisplay(
                    productItem?.dimensionUOM,
                    productEnums
                  );
                  const displayImperialDimensionUOM = findDimensionEnumDisplay(
                    productItem?.imperialDimensionUOM,
                    productEnums
                  );
                  const displayWeightUOM = findDimensionEnumDisplay(
                    productItem?.weightUOM,
                    productEnums
                  );
                  const displayImperialWeightUOM = findDimensionEnumDisplay(
                    productItem?.imperialWeightUOM,
                    productEnums
                  );

                  return (
                    <div
                      className={classnames({
                        'package-content-drawer__right-info--product-item even-background':
                          index % 2 === 0,
                        'package-content-drawer__right-info--product-item odd-background':
                          index % 2 !== 0,
                      })}
                      key={index}
                    >
                      <Row style={{ paddingTop: 10, paddingBottom: 20 }}>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <ImageUnit
                            imageThumbnail={productItem?.productImage}
                          />
                        </Col>
                      </Row>
                      <Paragraph ellipsis={true}>
                        {productItem?.packageLevel}
                      </Paragraph>
                      <Paragraph ellipsis={true}>{productItem?.gtin}</Paragraph>
                      <Paragraph ellipsis={true}>
                        {productItem?.upc12}
                      </Paragraph>
                      <Paragraph ellipsis={true}>
                        {productItem?.upc11}
                      </Paragraph>
                      <Paragraph ellipsis={true}>
                        {productItem?.upc10}
                      </Paragraph>
                      <Paragraph ellipsis={true}>
                        {productItem?.upc13}
                      </Paragraph>
                      <Paragraph ellipsis={true}>{productItem?.upc8}</Paragraph>
                      <Paragraph ellipsis={true} style={{ marginTop: 24 }}>
                        {displayMode === 'Metric' && (
                          <>
                            {productItem?.height &&
                              Math.round(productItem?.height * 100) / 100}
                            &nbsp;
                            {productItem?.height >= 0 &&
                              (displayDimensionUOM ||
                                productItem?.dimensionUOM)}
                          </>
                        )}
                        {displayMode === 'Imperial' && (
                          <>
                            {productItem?.imperialHeight &&
                              Math.round(productItem?.imperialHeight * 100) /
                                100}
                            &nbsp;
                            {productItem?.imperialHeight >= 0 &&
                              (displayImperialDimensionUOM ||
                                productItem?.imperialDimensionUOM)}
                          </>
                        )}
                      </Paragraph>
                      <Paragraph ellipsis={true}>
                        {displayMode === 'Metric' && (
                          <>
                            {productItem?.width &&
                              Math.round(productItem?.width * 100) / 100}
                            &nbsp;
                            {productItem?.width >= 0 &&
                              (displayDimensionUOM ||
                                productItem?.dimensionUOM)}
                          </>
                        )}
                        {displayMode === 'Imperial' && (
                          <>
                            {productItem?.imperialWidth &&
                              Math.round(productItem?.imperialWidth * 100) /
                                100}
                            &nbsp;
                            {productItem?.imperialWidth >= 0 &&
                              (displayImperialDimensionUOM ||
                                productItem?.imperialDimensionUOM)}
                          </>
                        )}
                      </Paragraph>
                      <Paragraph ellipsis={true}>
                        {displayMode === 'Metric' && (
                          <>
                            {productItem?.depth &&
                              Math.round(productItem?.depth * 100) / 100}
                            &nbsp;
                            {productItem?.depth >= 0 &&
                              (displayDimensionUOM ||
                                productItem?.dimensionUOM)}
                          </>
                        )}
                        {displayMode === 'Imperial' && (
                          <>
                            {productItem?.imperialDepth &&
                              Math.round(productItem?.imperialDepth * 100) /
                                100}
                            &nbsp;
                            {productItem?.imperialDepth >= 0 &&
                              (displayImperialDimensionUOM ||
                                productItem?.imperialDimensionUOM)}
                          </>
                        )}
                      </Paragraph>
                      <Paragraph ellipsis={true}>
                        {displayMode === 'Metric' && (
                          <>
                            {productItem?.weight &&
                              Math.round(productItem?.weight * 100) / 100}
                            &nbsp;
                            {productItem?.weight >= 0 &&
                              (displayWeightUOM || productItem?.weightUOM)}
                          </>
                        )}
                        {displayMode === 'Imperial' && (
                          <>
                            {productItem?.imperialWeight &&
                              Math.round(productItem?.imperialWeight * 100) /
                                100}
                            &nbsp;
                            {productItem?.imperialWeight >= 0 &&
                              (displayImperialWeightUOM ||
                                productItem?.imperialWeightUOM)}
                          </>
                        )}
                      </Paragraph>
                    </div>
                  );
                })}
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};

PackageContentDrawer.propTypes = {};

export default PackageContentDrawer;
