import React from 'react';
import { FilterOutlined, ClockCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Messages from 'i18n/messages/workflow';

import { Menu, Dropdown } from 'antd';

import * as workflowActions from 'pages/workflow/controllers/actions';
import * as workflowSelectors from 'pages/workflow/controllers/selectors';

import RibbonButton from 'common/components/button/RibbonButton';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';

import { useDisplayFilterButton } from 'hooks/useDisplayFilterButton';

import { FILTER_TYPE_WORKFLOW } from 'static/Constants';

import './FilterSection.less';

const FilterSection = () => {
  const dispatch = useDispatch();
  const workflowFilters = useSelector(
    workflowSelectors.selectWorkflowFilters()
  );

  const { label, icon, toggle } = useDisplayFilterButton({
    filterOptions: FILTER_TYPE_WORKFLOW,
    filters: workflowFilters,
  });

  const handleWorkflowFilters = (filter) => {
    dispatch(actionsGridView.updateSizeCurrent([1, 0]));
    if (workflowFilters.indexOf(filter) > -1) {
      dispatch(
        workflowActions.updateWorkflowFilters(
          workflowFilters.filter((val) => val !== filter)
        )
      );
    } else {
      let newWorkflowFilters = [...workflowFilters];
      newWorkflowFilters.push(filter);
      dispatch(workflowActions.updateWorkflowFilters(newWorkflowFilters));
    }
  };

  const menuOptions = (
    <Menu>
      <Menu.Item key='myAssets' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<FilterOutlined />}
          label={Messages.active}
          onClick={() => handleWorkflowFilters('active')}
          className={classNames({
            'filter-section__filter-button--active':
              workflowFilters.indexOf('active') > -1,
          })}
        />
      </Menu.Item>
      <Menu.Item key='pendingAsset' style={{ backgroundColor: 'inherit' }}>
        <RibbonButton
          size='small'
          icon={<ClockCircleOutlined />}
          label={Messages.history}
          onClick={() => handleWorkflowFilters('history')}
          className={classNames({
            'filter-section__filter-button--active':
              workflowFilters.indexOf('history') > -1,
          })}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuOptions} arrow>
      <RibbonButton icon={icon} label={label} toggle={toggle} />
    </Dropdown>
  );
};

export default FilterSection;
