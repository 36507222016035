import React from 'react';

import { UndoOutlined } from '@ant-design/icons';

import { dialogFunction, RibbonButton } from 'common/components';

import { EVENT } from 'static/Constants';

import Messages from 'i18n/messages/home';
import { useIntl } from 'react-intl';

const ResetLayout = () => {
  const intl = useIntl();

  const onClickBtn = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.dashboardResetConfirmMessage),
      okText: intl.formatMessage(Messages.dashboardLayoutOk),
      cancelText: intl.formatMessage(Messages.dashboardLayoutCancel),
      onOk: onOkConfirm,
    });
  };

  const onOkConfirm = () => {
    resetLayout();
  };

  const resetLayout = () => {
    const event = new CustomEvent(EVENT.DASHBOARD_LAYOUT, {
      detail: 'reset',
    });

    document.dispatchEvent(event);
  };

  return (
    <RibbonButton
      icon={<UndoOutlined />}
      label={Messages.dashboardLayoutReset}
      style={{ padding: '0px 10px' }}
      onClick={onClickBtn}
    />
  );
};

export default ResetLayout;
