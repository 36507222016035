import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const updateNewFormTemplateService = (data) => {
  return api.sendPost(endpoints.UPLOAD_NEW_FORM_TEMPLATE, data);
};

export const getFormDetail = (formId) => {
  const options = { params: { formId } };
  return api.sendGet(endpoints.GET_FORM_DETAIL, options);
};
export const getExportedFormShortDetail = (historyId) => {
  const options = { params: { historyId } };
  return api.sendGet(endpoints.GET_EXPORTED_FORM_SHORT_DETAIL, options);
};
export const getFormTemplateDetailService = (data) => {
  return api.sendGet(endpoints.GET_FORM_TEMPLATE_DETAIL, { params: data });
};
export const editFormTemplateService = (data) => {
  return api.sendPost(endpoints.EDIT_FORM_TEMPLATE, data);
};

export const deleteFormItem = (payload) => {
  return api.sendPost(endpoints.DELETE_FORM_ITEM, payload);
};

export const getAllProductFields = () => {
  return api.sendGet(endpoints.GET_ALL_PRODUCT_FIELDS);
};

export const getAllAssetFields = () => {
  return api.sendGet(endpoints.GET_ALL_ASSET_FIELDS);
};

export const getAllMemberFields = () => {
  return api.sendGet(endpoints.GET_ALL_MEMBER_FIELDS);
};

export const getAllFormTemplates = (params) => {
  return api.sendGet(endpoints.GET_ALL_FORM_TEMPLATES, { params });
};

export const prepareExportFormData = (params) => {
  return api.sendPost(endpoints.PREPARE_EXPORT_FORM_DATA, params);
};

export const exportFormForSelectedEntities = (params) => {
  const urlPath = 'data.fileUrl';

  return api.requestToDownload({
    method: 'post',
    apiEndpoint: endpoints.EXPORT_FORM_FOR_SELECTED_ENTITIES,
    payload: params,
    urlPath,
  });
};

export const cloneFormTemplate = (params) => {
  return api.sendPost(endpoints.CLONE_FORM_TEMPLATE, params);
};

export const replaceFormTemplateFileService = (params) => {
  return api.sendPost(endpoints.REPLACE_FORM_FILE, params);
};

export const downloadSplashManual = () => {
  return api.requestToDownload({
    method: 'get',
    apiEndpoint: endpoints.DOWNLOAD_SPLASH_MANUAL,
  });
};

export const downloadAcceptedFields = () => {
  return api.requestToDownload({
    method: 'get',
    apiEndpoint: endpoints.DOWNLOAD_ACCEPTED_FIELDS,
  });
};

export const downloadExportedForm = (downloadUrl) => {
  return api.sendDownload({ url: downloadUrl });
};
