import produce from 'immer';
import * as types from '../constants';

export const initialState = {
  loading: false,
  brand: null,
  document: null,
  asset: null,
  error: null,
  documentDetail: null,
  loadingDocumentGrid: false,
  loadingGetDocument: false,
  memberContact: {
    loading: false,
    pageIndex: 1,
    pageSize: 10000,
    totalItems: 0,
    data: [],
    columns: [],
    memberId: null,
  },
  brands: {
    loading: false,
    pageIndex: 1,
    pageSize: 20,
    totalItems: 0,
    brandsList: [],
    columns: [],
    memberId: null,
  },
  informationSheet: {
    loading: false,
    pageIndex: 1,
    pageSize: 20,
    totalItems: 0,
    informationSheetList: [],
    memberId: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const memberOverviewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_MEMBER_OVERVIEW_DOCUMENT:
        draft.loading = true;
        draft.error = null;
        break;
      case types.FETCH_MEMBER_OVERVIEW_DOCUMENT_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.document = action.data;
        break;
      case types.FETCH_MEMBER_OVERVIEW_DOCUMENT_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.GET_DOCUMENT_DETAIL:
        draft.loadingGetDocument = true;
        draft.loadingDocumentGrid = false;
        draft.error = null;

        break;
      case types.GET_DOCUMENT_DETAIL_SUCCESS:
        draft.loadingGetDocument = false;
        draft.error = null;
        draft.documentDetail = action.data;
        break;
      case types.DELETE_DOCUMENT_DETAIL:
        draft.loadingDocumentGrid = true;
        draft.error = null;
        break;
      case types.DELETE_DOCUMENT_DETAIL_ERROR:
        draft.loadingDocumentGrid = false;
        draft.error = action.data;
        break;
      case types.GET_DOCUMENT_DETAIL_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case types.GET_MEMBER_CONTACTS:
        draft.memberContact['loading'] = true;
        break;
      case types.GET_MEMBER_CONTACTS_SUCCESS:
        draft.memberContact['loading'] = false;
        draft.memberContact['data'] = action.data.gridData;
        draft.memberContact['pageIndex'] = action.data.paging.currentPageIndex;
        draft.memberContact['pageSize'] = action.data.paging.currentPageSize;
        draft.memberContact['totalItems'] = action.data.paging.totalRecord;
        break;
      case types.GET_MEMBER_CONTACTS_ERROR:
        draft.memberContact['loading'] = false;
        break;
      case types.GET_MEMBER_OVERVIEW_BRANDS:
        draft.brands['loading'] = true;
        break;
      case types.GET_MEMBER_OVERVIEW_BRANDS_SUCCESS:
        draft.brands['loading'] = false;
        draft.brands['brandsList'] = action?.data?.gridData || [];
        draft.brands['pageIndex'] =
          action?.data?.paging?.currentPageIndex ||
          initialState.brands.pageIndex;
        draft.brands['pageSize'] =
          action?.data?.paging?.currentPageSize || initialState.brands.pageSize;
        draft.brands['totalItems'] =
          action?.data?.paging?.totalRecord || initialState.brands.totalItems;
        break;
      case types.GET_MEMBER_OVERVIEW_BRANDS_ERROR:
        draft.brands['loading'] = false;
        break;
      case types.GET_MEMBER_INFORMATION_SHEET:
        draft.informationSheet['loading'] = true;
        break;
      case types.GET_MEMBER_INFORMATION_SHEET_SUCCESS:
        draft.informationSheet['loading'] = false;
        draft.informationSheet['informationSheetList'] =
          action?.data?.gridData || [];
        draft.informationSheet['pageIndex'] =
          action?.data?.paging?.currentPageIndex ||
          initialState.brands.pageIndex;
        draft.informationSheet['pageSize'] =
          action?.data?.paging?.currentPageSize ||
          initialState.informationSheet.pageSize;
        draft.informationSheet['totalItems'] =
          action?.data?.paging?.totalRecord ||
          initialState.informationSheet.totalItems;
        break;
      case types.GET_MEMBER_INFORMATION_SHEET_ERROR:
        draft.informationSheet['loading'] = false;
        break;
    }
  });

export default memberOverviewReducer;
