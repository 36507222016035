import React from 'react';
import { Col, Row, Typography } from 'antd';
import './TitleSection.less';

const TitleSection = ({ title, children }) => {
  return (
    <Row className='advanced-search-title'>
      <Typography.Text className='advanced-search-title__text'>
        {title}
      </Typography.Text>
      <Col>{children}</Col>
    </Row>
  );
};

export default TitleSection;
