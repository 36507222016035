import React from 'react';
import { Divider } from 'antd';

import { ViewLayout, AdvanceStack } from './components';
import { useCheckPermissions } from 'hooks/useCheckPermissions';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';
import { RibbonBar, RibbonDivider } from 'common/components';
import DetailSection from './components/sections/home-grid/DetailSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import UserActionsSection from './components/sections/user-maintenance-grid/UserActionsSection';
import MoveUserToMember from './components/sections/user-maintenance-grid/MoveUserToMember';
import UnlockUser from './components/sections/user-maintenance-grid/UnlockUser';
import MemberCommunicateSection from './components/sections/member-maintenance-grid/MemberCommunicateSection';
import UserEditRoleSecuritySection from './components/sections/user-maintenance-grid/UserEditRoleSecuritySection';
import FilterUsersSection from './components/sections/user-maintenance-grid/FilterUsersSection';
import FilterUserRole from './components/sections/user-maintenance-grid/FilterUserRole';

import { useCheckIsSuperAdmin } from 'hooks';

const COMMUNICATION_PERMISSION = [
  {
    action: ABILITY_ACTION.VIEW,
    subject: ABILITY_SUBJECT.COMMUNICATION_TEMPLATE,
  },
  {
    action: ABILITY_ACTION.MAINTAIN,
    subject: ABILITY_SUBJECT.COMMUNICATION_TEMPLATE,
  },
];

const UserMaintenanceGridView = () => {
  const isSuperAdmin = useCheckIsSuperAdmin();

  const hasPermissionCommunication = useCheckPermissions(
    COMMUNICATION_PERMISSION
  );
  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection disabled />
      <ViewLayout disabled />
      {hasPermissionCommunication && (
        <>
          <RibbonDivider />
          <MemberCommunicateSection />
          <RibbonDivider />
        </>
      )}
      <UserEditRoleSecuritySection />
      <RibbonDivider />
      <UserActionsSection isUsersManagement={true} />
      <RibbonDivider />
      <MoveUserToMember />
      <RibbonDivider />
      {/* {isSuperAdmin && <FilterUserRole />} */}
      <FilterUsersSection />
      <UnlockUser />
      <RibbonDivider />
      <AdvanceStack isDisabled />
    </RibbonBar>
  );
};

export default UserMaintenanceGridView;
