import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Menu, Dropdown } from 'antd';

import {
  SecurityScanOutlined,
  ClearOutlined,
  ProjectOutlined,
  SafetyOutlined,
} from '@ant-design/icons';

import {
  dialogFunction,
  WithAbsoluteContainer,
  RibbonButton,
} from 'common/components';
import AddRoleToMemberModal from './AddRoleToMemberModal';

import gridViewSelectors from 'common/components/grid-view/controllers/selectors';
import * as roleActions from 'pages/role/controllers/actions';

import { useHandleReloadPage } from 'hooks/useReloadPage';

import { forwardTo } from 'utils/common/route';

const iconStyle = { fontSize: 18 };

const MemberEditRoleSecuritySection = (props) => {
  const [__, setReloadGrid] = useHandleReloadPage();
  const [isAddRoleToMemberModalOpen, setIsAddRoleToMemberModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  const selectedMembers = useSelector(
    gridViewSelectors.makeSelectItemsSelection()
  );

  const selectedDetailMembers = useSelector(
    gridViewSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const toggleAddRoleToMemberModal = () => {
    setIsAddRoleToMemberModalOpen((prev) => !prev);
  };

  const handleAddRoleToMember = () => {};

  const openRoleSecurityView = () => {
    const memberId = selectedMembers?.[0];
    forwardTo(`/security/member/${memberId}`);
  };

  const handleRemoveRoleFromMember = async () => {
    const params = {
      memberIds: selectedMembers,
    };

    const successCallback = () => {
      setReloadGrid();
    };

    dispatch(roleActions.removeMemberFromRole({ params, successCallback }));
  };

  const confirmClickRemoveRoleFromMember = () => {
    dialogFunction({
      type: 'warn',
      content: 'Do you want to remove role from selected member(s)?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: handleRemoveRoleFromMember,
    });
  };

  const multiDisabled =
    selectedMembers?.length < 1 || selectedMembers.length > 1;

  const disabled = selectedMembers?.length <= 0;

  const disableRemovedRoleBtn = !selectedDetailMembers.some(
    (memberDetail) => memberDetail?.memberRoleName
  );

  const menuOptions = (
    <Menu className='member-communicate-section__menu'>
      <Menu.Item
        icon={<ProjectOutlined style={iconStyle} />}
        disabled={multiDisabled}
        onClick={openRoleSecurityView}
      >
        Manage user role & permission
      </Menu.Item>
      <Menu.Item
        disabled={disableRemovedRoleBtn}
        icon={<ClearOutlined style={iconStyle} />}
        onClick={confirmClickRemoveRoleFromMember}
      >
        Remove role from member
      </Menu.Item>
      <Menu.Item
        icon={<SafetyOutlined style={iconStyle} />}
        onClick={toggleAddRoleToMemberModal}
      >
        Add member role
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menuOptions} arrow={false} disabled={disabled}>
        <RibbonButton
          icon={<SecurityScanOutlined style={{ height: 26 }} />}
          label={'Role & security'}
          disabled={disabled}
        />
      </Dropdown>
      <WithAbsoluteContainer>
        <AddRoleToMemberModal
          isVisible={isAddRoleToMemberModalOpen}
          toggleModal={toggleAddRoleToMemberModal}
          handleAddRoleToMember={handleAddRoleToMember}
          selectedMembers={selectedMembers}
        />
      </WithAbsoluteContainer>
    </>
  );
};

export default MemberEditRoleSecuritySection;
