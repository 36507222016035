import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Space,
  Col,
  Row,
  Empty,
  Button,
  message,
  Typography,
  Input,
} from 'antd';
import { StyledModal, CustomNotification } from 'common/components';

import * as globalSelectors from 'redux/global/selectors';

import { searchTextByPatterm, toUniqueList } from 'utils';

import MdEditInterEntity from './MdEditInterEntity';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as globalActions from 'redux/global/actions';

import './MdInterEntityTool.less';

const { Text } = Typography;

const MdInterEntityTool = (props) => {
  const {
    setInterEntityOpen,
    interEntityOpen,
    isEntityInsideTable,
    tempMdTableData,
    mdEditor,
  } = props;

  const [text, setText] = useState('');
  const [placeholderList, setPlaceholderList] = useState([]);
  const [editItem, setEditItem] = useState(null);

  const dispatch = useDispatch();

  const isGettingPlaceholderList = useSelector(
    globalSelectors.selectIsGettingPlaceholderList()
  );

  const handleInsertInterEntityData = (placeItem) => {
    const insertContent = placeItem || `[@[${text}]]`;
    if (!isEntityInsideTable) {
      if (!mdEditor) return;
      mdEditor.doc.replaceSelection(insertContent);
      CustomNotification.success('Insert Successfully!');
      getPlaceholderList(mdEditor);
    } else {
      dispatch(
        globalActions.updateMdTableAction({
          type: 'INSERT',
          payload: insertContent,
        })
      );
    }

    setInterEntityOpen(false);
  };

  const hanldeMessageCopySyntax = () => {
    message.success({
      content: <Text>Copied Successfully</Text>,
    });
  };

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const handleSelectEditItem = (placeholder) => {
    setEditItem(placeholder);
  };

  const fromReplaceArrayToUniqueArray = useCallback((string, pattern) => {
    const searchedArray = searchTextByPatterm(string, pattern);
    return searchedArray ? toUniqueList(searchedArray) : [];
  }, []);

  const getPlaceholderList = useCallback(
    (mdEditor) => {
      if (!mdEditor) return;
      const value = mdEditor.doc.getValue();

      const contentToExtract = isEntityInsideTable
        ? JSON.stringify(tempMdTableData)
        : value;
      const placeholderRegex = /\[@\[([^\}\]\n\r]+)\]\]/g;
      const list = fromReplaceArrayToUniqueArray(
        contentToExtract,
        placeholderRegex
      );
      setPlaceholderList(list);
    },
    [fromReplaceArrayToUniqueArray, tempMdTableData]
  );

  useEffect(() => {
    if (!interEntityOpen) return;
    getPlaceholderList(mdEditor);
  }, [interEntityOpen, getPlaceholderList, mdEditor]);

  useEffect(() => {
    if (isGettingPlaceholderList) {
      getPlaceholderList(mdEditor);
      dispatch(globalActions.toggleIsGettingPlaceholderList(false));
    }
  }, [isGettingPlaceholderList, getPlaceholderList, mdEditor, dispatch]);

  return (
    <>
      <StyledModal
        className='md-inter-entity-tool-list'
        title={'Interact Entity Data'}
        visible={interEntityOpen}
        onCancel={() => {
          setInterEntityOpen && setInterEntityOpen(false);
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText={'Cancel'}
        maskClosable={false}
        width={600}
        destroyOnClose
      >
        <Row>
          <Col xs={24}>
            <Text strong> Add New Interactive Data</Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col flex='auto'>
            <Input onChange={handleChangeText} />
          </Col>
          <Col flex='100px'>
            <Space>
              <Button
                type='primary'
                onClick={() => handleInsertInterEntityData()}
                disabled={!text || placeholderList.includes(`[@[${text}]]`)}
              >
                Insert
              </Button>

              <CopyToClipboard
                text={`[@[${text}]]`}
                onCopy={hanldeMessageCopySyntax}
              >
                <Button
                  danger
                  disabled={!text || placeholderList.includes(`[@[${text}]]`)}
                >
                  Copy
                </Button>
              </CopyToClipboard>
            </Space>
          </Col>
        </Row>
        {placeholderList.includes(`[@[${text}]]`) && (
          <Row>
            <Col>
              <Text type='danger'>This interactive data already existed!</Text>
            </Col>
          </Row>
        )}
        <Row gutter={[0, 10]}>
          <Col xs={24} style={{ paddingTop: 10 }}>
            <Text strong> Created Interactive Data</Text>
          </Col>
        </Row>
        {placeholderList && placeholderList?.length > 0 ? (
          <div>
            <Row className='md-table-list__header'>
              <Col xs={17}> Name</Col>
              <Col flex='auto'>Control</Col>
            </Row>
            <>
              {placeholderList.map((placeItem, idx) => (
                <Row className='md-table-list__item' key={'field-item-' + idx}>
                  <Col xs={17}>{placeItem}</Col>
                  <Col>
                    <Space>
                      <Button onClick={() => handleSelectEditItem(placeItem)}>
                        Edit
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => handleInsertInterEntityData(placeItem)}
                      >
                        Insert
                      </Button>

                      <CopyToClipboard
                        text={`[@[${placeItem}]]`}
                        onCopy={hanldeMessageCopySyntax}
                      >
                        <Button danger>Copy</Button>
                      </CopyToClipboard>
                    </Space>
                  </Col>
                </Row>
              ))}
            </>
          </div>
        ) : (
          <div flex='auto'>
            <Empty />
          </div>
        )}
      </StyledModal>
      <MdEditInterEntity
        setEditItem={setEditItem}
        editItem={editItem}
        mdEditor={mdEditor}
        getPlaceholderList={getPlaceholderList}
        isEntityInsideTable={isEntityInsideTable}
      />
    </>
  );
};

export default MdInterEntityTool;
