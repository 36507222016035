import { Col, Switch, Tag, Typography } from 'antd';
import React, { useState } from 'react';

let searchTimeout;
const SwitchConjunction = ({
  handleConjunction,
  property,
  viewMode,
  isDragging,
}) => {
  const [value, setValue] = useState(property?.operation === 'AND');

  const debounceUpdateValue = (checked) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      handleConjunction(property?.valuePath, checked);
    }, 300);
  };

  const handleUpdateValue = (checked) => {
    setValue(checked);
    debounceUpdateValue(checked);
  };

  return (
    <Col
      className={isDragging && 'switch-conjunction'}
      style={{
        display: 'flex',
        alignItems: 'center',
        minHeight: 24,
        backgroundColor: '#ffa39e',
        borderRadius: 4,
        padding: '0 8px',
      }}
    >
      <Switch
        disabled={Boolean(viewMode)}
        size='small'
        checkedChildren={
          <Typography.Text className='query-condition__switch-button-active'>
            AND
          </Typography.Text>
        }
        unCheckedChildren={
          <Typography.Text className='query-condition__switch-button-inactivate'>
            OR
          </Typography.Text>
        }
        checked={value}
        onClick={(checked) => {
          handleUpdateValue(checked);
        }}
        style={{
          width: 44,
          color: value ? '#fff' : 'rgba(0, 0, 0, 0.65)',
          background: value ? '#2684ff' : '#f5f5f5',
          display: 'flex',
          flexShrink: '0',
        }}
      />
      <Typography.Text
        ellipsis
        style={{
          marginLeft: 5,
          fontWeight: 500,
          fontSize: 14,
          display: 'flex',
        }}
      >
        Group Query Conditions
      </Typography.Text>
    </Col>
  );
};

export default SwitchConjunction;
