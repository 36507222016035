import React from 'react';
import { SaveOutlined } from '@ant-design/icons';

import RibbonButton from 'common/components/button/RibbonButton';

import { EVENT } from 'static/Constants';
import Messages from 'i18n/messages/home';

const SaveEditTransitionPage = (props) => {
  const onClickBtn = () => {
    dispatchSaveEditEvent();
  };

  const dispatchSaveEditEvent = () => {
    const event = new Event(EVENT.SAVE_TRANSITION_PAGE);

    document.dispatchEvent(event);
  };

  return (
    <RibbonButton
      icon={<SaveOutlined />}
      label={Messages.saveTransitionPage}
      onClick={onClickBtn}
    />
  );
};

export default SaveEditTransitionPage;
