import React, { useEffect, useState, useRef } from 'react';

import { CaretLeftOutlined } from '@ant-design/icons';
import { StepForm, CountdownTrack, dialogFunction } from 'common/components';
import { Button, Divider, Form } from 'antd';
import OtpTriggerUnlockAccount from './OtpTriggerUnlockAccount';
import OtpCheckUnlockAccount from './OtpCheckUnlockAccount';

import { useStatePrevious } from 'hooks/usePrevious';
import { sessionTimeFormatter, getNewResendOtpTime } from '../../utils/utils';
import { forwardTo, getParameterFromQuery } from 'utils/common/route';
import reactCSS from 'reactcss';

import './OtpVerifyUnlockAccount.less';

const styles = reactCSS({
  default: {
    stepWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 450,
    },
  },
});

const OtpVerifyUnlockAccount = (props) => {
  const { unlockMethod, onChangeUnlockMethod } = props;
  const returnUrl = getParameterFromQuery('returnUrl') || '/';

  const [otp, setOtp] = useState([]);
  const [step, setStep] = useState(0);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const timeAllowResendHook = useState();
  const allowResendHook = useStatePrevious(false);
  const [isAllowResend, setIsAllowResend] = allowResendHook;
  const [timeAllowResend, setTimeAllowResend] = timeAllowResendHook;
  const [otpCheckError, setOtpCheckError] = useState('');
  const [mfaSession, setMfaSession] = useState();

  const refOtpTrigger = useRef();
  const [formInst] = Form.useForm();

  const { expireAt } = mfaSession || {};

  const onResendOtp = () => {
    refOtpTrigger.current.onResendOtp();
    setTimeAllowResend(getNewResendOtpTime());
  };

  const handleClickBack = () => {
    setOtp([]);
    setStep(0);
  };

  const handleClickChangeUnlockMethod = () => {
    onChangeUnlockMethod && onChangeUnlockMethod();
    setOtp([]);
    setStep(0);
  };

  useEffect(() => {
    setStep(0);
  }, []);

  useEffect(() => {
    if (!timeAllowResend) {
      setIsAllowResend(true);
    }
  }, [timeAllowResend]);

  useEffect(() => {
    if (isSessionExpired) {
      dialogFunction({
        type: 'info',
        content: 'Unlock session ended. Please try again',
        okText: 'OK',
        cancelText: 'Cancel',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: () => {
          forwardTo(`/login?returnUrl=${returnUrl}`);
        },
        maskClosable: false,
      });
    }
  }, [isSessionExpired, returnUrl]);

  const getSessionRemainingTime = (remainingTime) => {
    if (remainingTime === 0 && !isSessionExpired) {
      setIsSessionExpired(true);
    }
  };

  const stepDefine = [
    {
      step: 0,
      wrapperStyle: styles.stepWrapper,
      component: (
        <div>
          <Button
            type='primary'
            shape='round'
            icon={<CaretLeftOutlined />}
            style={{
              display: 'table',
              marginLeft: 'auto',
              marginRight: 10,
              marginBottom: 10,
            }}
            onClick={handleClickChangeUnlockMethod}
          >
            Change Unlock Method
          </Button>
          <Divider style={{ margin: '12px 0' }} />

          <OtpTriggerUnlockAccount
            refOtpTrigger={refOtpTrigger}
            setStep={setStep}
            formInst={formInst}
            setTimeAllowResend={setTimeAllowResend}
            isAllowResend={isAllowResend}
            timeAllowResend={timeAllowResend}
            setOtpCheckError={setOtpCheckError}
            setIsSessionExpired={setIsSessionExpired}
            unlockMethod={unlockMethod}
            setMfaSession={setMfaSession}
            addNew
          />
        </div>
      ),
    },
    {
      step: 1,
      wrapperStyle: styles.stepWrapper,
      component: (
        <div>
          <Button
            className='otp-verify-unlock-account__btn-back'
            type='primary'
            shape='round'
            icon={<CaretLeftOutlined />}
            onClick={handleClickBack}
          >
            Back
          </Button>
          <Divider style={{ margin: '12px 0' }} />
          <OtpCheckUnlockAccount
            unlockMethod={unlockMethod}
            otp={otp}
            setOtp={setOtp}
            onResendOtp={onResendOtp}
            step={step}
            timeAllowResendHook={timeAllowResendHook}
            allowResendHook={allowResendHook}
            otpCheckError={otpCheckError}
            setOtpCheckError={setOtpCheckError}
            mfaSession={mfaSession}
          />
        </div>
      ),
    },
  ];

  return (
    <div className='otp-verify-unlock-account'>
      <StepForm currentStep={step} stepDefine={stepDefine} />
      {expireAt && (
        <CountdownTrack
          wrapperClass='otp-verify-unlock-account__session-count-down'
          expirationTime={expireAt}
          getRemainingTime={getSessionRemainingTime}
          totalTime={600}
          timeFormatter={sessionTimeFormatter}
          noProgress
        />
      )}
    </div>
  );
};

export default OtpVerifyUnlockAccount;
