import React from 'react';
import { useHistory } from 'react-router-dom';

import { ApartmentOutlined } from '@ant-design/icons';
import RibbonButton from 'common/components/button/RibbonButton';

import { useGetProductFullView } from 'hooks';
import { useSsoProductParams } from 'hooks/useSso';
import Messages from 'i18n/messages/home';
import useToggleActiveRibbon from './hook';

const ProductHierarchy = ({ productId }) => {
  const history = useHistory();

  const { productFull } = useGetProductFullView({ productId });

  const { ssoParamUrl: ssoProductParams, productId: ssoProductId } =
    useSsoProductParams();

  const checkActiveContent = () => {
    const path = history?.location?.pathname;

    const regex = /\/product\/hierarchy\/\d+$/;
    return regex.test(path);
  };

  const isPathActive = checkActiveContent();
  const isActive = useToggleActiveRibbon(isPathActive);

  const handleClick = () => {
    if (isActive) {
      if (ssoProductParams) {
        history.push(`/product/${ssoProductId}/?${ssoProductParams}`);
      } else {
        history.push(`/product/${productFull?.productId}`);
      }
    } else {
      if (ssoProductParams) {
        history.push(`/product/hierarchy/${ssoProductId}?${ssoProductParams}`);
      } else {
        history.push(`/product/hierarchy/${productFull?.productId}`);
      }
    }
  };

  return (
    <RibbonButton
      icon={<ApartmentOutlined style={{ height: 26 }} />}
      label={Messages.productHierarchy}
      onClick={handleClick}
      toggle={isPathActive}
      disabled={!productFull?.productId}
    />
  );
};

export default ProductHierarchy;
