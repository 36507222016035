// user api
export const MEMBER_PROFILE_HEADER_ENDPOINT = '/api/MemberProfile/header';
export const MEMBER_CONTACT_HISTORY_ENDPOINT =
  '/api/MemberProfile/member-contact-history';
export const MEMBER_LOCATIONS_ENDPOINT = '/api/MemberProfile/member-locations';
export const MEMBER_COMPANY_LOCATION_GRID_DISTINCT =
  '/api/MemberProfile/member-locations/distinct';
export const MEMBER_BILLING_ENDPOINT = '/api/MemberProfile/member-billings';
export const MEMBER_BILLING_ENDPOINT_DISTINCT =
  '/api/MemberProfile/member-billings/distinct';
export const MEMBER_SHARES_ENDPOINT = '/api/v1/member-shares';
export const USER_MEMBER_OVERVIEW_BRAND = '/api/MemberProfile/brands';
export const USER_MEMBER_OVERVIEW_ASSET = '/api/MemberProfile/digitalassets';
export const USER_MEMBER_OVERVIEW_DOCUMENT = '/api/MemberProfile/documents';
export const USER_MEMBER_CRM_RELATIONSHIP =
  '/api/v1/member/crm/advantage-relationships';
export const MEMBER_CRM_ACTIVITIES_ENDPOINT =
  '/api/MemberProfile/member-crm-activities';
export const MEMBER_CRM_ACTIVITY_GRID_DISTINCT =
  '/api/MemberProfile/member-crm-activities/distinct';
export const MEMBER_AFFILIATION_ENDPOINT = '/api/v1/member/affiliation';
export const USER_MEMBER_SYSTEM_ENDPOINT = '/api/v1/member/system-information';

export const MEMBER_PROFILE_EDIT = '/api/MemberProfile/edit-member';
export const MEMBER_LIST_ENDPOINT = '/api/MemberProfile/membergridview';
export const FAVORITE_MEMBER_LIST_ENDPOINT =
  '/api/favorites/get-favorite-members-grid-view';
export const MEMBER_CONTACT = '/api/MemberProfile/member-contacts';
export const MEMBER_CONTACT_DISTINCT =
  '/api/MemberProfile/member-contacts/distinct';
export const MEMBER_DETAIL_GRID = '/api/MemberProfile/member-detail-grid';
export const FAVORITE_MEMBER_DETAIL_GRID =
  '/api/favorites/get-favorite-members-detail-grid';
export const MEMBER_DETAIL_GRID_DISTINCT =
  '/api/MemberProfile/member-detail-grid/distinct';
export const FAVORITE_MEMBER_DETAIL_GRID_DISTINCT =
  '/api/favorites/get-favorite-members-detail-grid/distinct';
export const MEMBER_SHORT_DETAIL = '/api/MemberProfile/member-short-detail';
export const ADD_NEW_MEMBER_SOCIAL_LINK =
  '/api/MemberProfile/edit/add-social-media';
export const DELETE_MEMBER_SOCIAL_LINK =
  '/api/MemberProfile/edit/delete-social-media';
export const MEMBER_SHOWCASED_MEDIA_GRID =
  '/api/MemberProfile/visitor/get-showcased-media-grid';

export const EDIT_MEMBER_NAME = '/api/MemberProfile/edit/edit-name';
export const EDIT_MEMBER_LOGO = '/api/MemberProfile/edit/edit-logo';
export const GET_MEMBER_LOGO = '/api/MemberProfile/get-member-logos';
export const GET_OFFICES_AND_FACILITES =
  '/api/MemberProfile/visitor/get-offices-and-facilities';

export const GET_BG_HEADER = '/api/MemberProfile/visitor/get-background-header';

export const MY_COMPANY_UPDATE_LOCATION =
  '/api/MemberProfile/edit/edit-company-location';
export const MY_COMPANY_DELETE_LOCATION =
  '/api/MemberProfile/edit/delete-company-locations';
export const MY_COMPANY_ADD_LOCATION =
  '/api/MemberProfile/edit/create-member-location';
export const MY_COMPANY_GET_LOCATION_DETAIL =
  '/api/MemberProfile/member-location-detail';

export const MY_COMPANY_UPDATE_CONTACT =
  '/api/MemberProfile/edit/edit-company-contact';
export const MY_COMPANY_ADD_CONTACT =
  '/api/MemberProfile/edit/create-member-contact';
export const MY_COMPANY_DELETE_CONTACT =
  '/api/MemberProfile/edit/delete-company-contacts';
export const MY_COMPANY_GET_CONTACT_DETAIL =
  '/api/MemberProfile/edit/get-company-contact-detail';

export const MY_COMPANY_RESET_PASSWORD =
  '/api/MemberProfile/edit/reset-user-password';

export const GET_MEDIA_ASSET_BY_MEMBER =
  '/api/MemberProfile/member-editor-asset/get-assets';
export const CREATE_MEDIA_ASSET =
  '/api/MemberProfile/member-editor-asset/create-company-tag-line-asset';
// end user api

export const MEMBER_EDIT_BACKGROUND_HEADER =
  '/api/MemberProfile/edit/edit-background-header';
export const MEMBER_EDIT_ALTERNATE_BACKGROUND_HEADER =
  '/api/MemberProfile/edit/edit-alternative-background-header';

export const MEMBER_EDIT_BACKGROUND_CONFIG =
  '/api/MemberProfile/edit/edit-background-header-configuration';
export const MEMBER_EDIT_ALTER_BACKGROUND_CONFIG =
  '/api/MemberProfile/edit/edit-alternative-background-header-configuration';

export const EDIT_COMPANY_TAG_LINE =
  '/api/MemberProfile/edit/edit-company-tag-line';

export const GET_PRODUCTS_FOR_MEMBER_DISTINCT =
  '/api/MemberProfile/get-products-for-member/distinct';

// Assets-for-member
export const GET_ASSETS_FOR_MEMBER = '/api/MemberProfile/get-assets-for-member';
export const GET_ASSETS_FOR_MEMBER_DISTINCT =
  '/api/MemberProfile/get-assets-for-member/distinct';
// delete Members
export const DELETE_MEMBERS = '/api/MemberProfile/delete-members';

export const REORDER_SHOWCASE_TAG_LINE = '/api/MemberProfile/reorder-showcases';
export const EDIT_SHOWCASE_TEXT = '/api/MemberProfile/edit/edit-showcase-text';
export const EDIT_MARKETING_CALL_OUT =
  '/api/MemberProfile/edit/edit-marketing-call-out';

export const DOWNLOAD_COMPANY_CONTACT =
  '/api/MemberProfile/member-contacts/download';

export const DOWNLOAD_COMPANY_LOCATION =
  '/api/MemberProfile/member-locations/download';

// edit Profile Default setting
export const EDIT_PROFILE_DEFAULT_SETTING =
  '/api/MemberProfile/edit/edit-profile-default-setting';

export const EDIT_MEMBER_VISIBILITY =
  '/api/MemberProfile/edit/edit-profile-visibility-setting';
export const PUBLIC_CONTACT_ENDPOINT =
  '/api/MemberProfile/get-public-member-contacts-grid';
export const PUBLIC_CONTACT_ENDPOINT_DISTINCT =
  '/api/MemberProfile/get-public-member-contacts-grid/distinct';
export const PUBLIC_CONTACT_LIST =
  '/api/MemberProfile/get-member-contacts-preview';

export const EDIT_MEMBER_OVERVIEW = '/api/MemberProfile/edit/edit-overview';
export const EDIT_MEMBER_DETAIL = '/api/MemberProfile/edit/edit-member-detail';

export const EDIT_MEMBER_SHOWING_OPTIONS =
  '/api/MemberProfile/edit/edit-showing-options';

export const ADD_TO_BAN_LIST = '/api/MemberProfile/add-to-ban-list';
export const REMOVE_FROM_BAN_LIST = '/api/MemberProfile/remove-from-ban-list';
export const GET_BAN_LIST = '/api/MemberProfile/get-ban-list';
export const GET_BAN_LIST_DISTINCT = '/api/MemberProfile/get-ban-list/distinct';

// Certification
export const GET_CERTIFICATIONS_OF_MEMBER =
  '/api/MemberProfile/get-member-certifications';
export const CREATE_CERTIFICATION =
  '/api/MemberProfile/edit/create-certification-info';
export const EDIT_CERTIFICATION =
  '/api/MemberProfile/edit/edit-certification-info';
export const DELETE_CERTIFICATION =
  '/api/MemberProfile/edit/delete-certification';
export const EDIT_CERTIFICATION_LOGO =
  '/api/MemberProfile/edit/edit-certification-logo';
export const GET_LOGO_CERTIFICATION_ASSETS =
  '/api/MemberProfile/get-member-certification-assets';

// member detail - brand s
export const CREATE_BRAND_INFO = '/api/MemberProfile/edit/create-brand-info';
export const EDIT_BRAND_INFO = '/api/MemberProfile/edit/edit-brand-info';
export const DELETE_BRAND = '/api/MemberProfile/edit/delete-brand';
export const CHANGE_BRAND_LOGO = '/api/MemberProfile/edit/change-brand-logo';
export const GET_MEMBER_BRAND_LOGO =
  '/api/MemberProfile/get-member-brand-assets';
export const GET_MEMBER_BRANDS = '/api/MemberProfile/get-member-brands';

// Daymon Properties
export const GET_DAYMON = '/api/MemberProfile/member-daymon-properties';
export const EDIT_DAYMON = '/api/MemberProfile/edit/edit-daymon-properties';

export const GET_ALL_ARTICLES = '/api/article/get-all-articles';
export const GET_MEMBER_DOCUMENT = '/api/MemberProfile/get-member-documents';
export const EDIT_MEMBER_DOCUMENT =
  '/api/MemberProfile/edit/add-member-showcase-document';
export const DELETE_MEMBER_DOCUMENT =
  '/api/MemberProfile/edit/delete-documents-or-remove-documents-from-showcase';

export const GET_MEMBER_DEFINED_PROPERTIES =
  '/api/MemberProfile/get-all-member-defined-properties';

export const SAVE_MEMBER_DEFINED_PROPERTIES =
  '/api/MemberProfile/member-defined-properties/save';

export const DELETE_MEMBER_DEFINED_PROPERTIES =
  '/api/MemberProfile/member-defined-properties/delete';
export const EDIT_ENFORCE_TAGS_RESTRICTIONS =
  '/api/MemberProfile/edit/enforce-tags-restrictions';
export const EDIT_ENFORCE_DEFINED_PROPERTIES =
  '/api/MemberProfile/edit/enforce-member-defined-properties';
export const EDIT_FTP_SERVICE =
  '/api/MemberProfile/edit/edit-profile-ftp-service-flag';
export const GENERATE_FTP_SERVICE =
  '/api/MemberProfile/edit/generate-profile-ftp-service-account';
export const GENERATE_FTP_PASSWORD =
  '/api/MemberProfile/edit/reset-profile-ftp-service-account-password';
export const SEND_TOKEN_VIA_EMAIL =
  '/api/MemberProfile/edit/send-api-token-to-email';
export const GENERATE_API_TOKEN = '/api/MemberProfile/edit/generate-api-token';
export const EDIT_API_SERVICE =
  '/api/MemberProfile/edit/enable-or-disable-api-token';

export const VISITOR_GET_MEMBER_DETAIL =
  '/api/MemberProfile/visitor/get-member-detail';
// Information sheet
export const GET_INFORMATION_SHEET_DETAIL_GRID =
  '/api/MemberProfile/member-information-sheet-detail-grid';
export const GET_INFORMATION_SHEET_DETAIL_GRID_DISTINCT =
  '/api/MemberProfile/member-information-sheet-detail-grid/distinct';
export const UPLOAD_MEMBER_INFORMATION_SHEET =
  '/api/MemberProfile/edit/upload-member-information-sheet';
export const DELETE_INFORMATION_SHEET =
  '/api/MemberProfile/member-info-sheet/deletes';
export const REACTIVE_INFORMATION_SHEET =
  '/api/MemberProfile/member-info-sheet/re-active';
export const DOWNLOAD_MEMBER_OVERVIEW_INFORMATION_SHEET =
  '/api/MemberProfile/member-info-sheet/download-files';

// download information sheets in grid view
export const DOWNLOAD_INFORMATION_SHEET_GRID_VIEW =
  '/api/MemberProfile/members-information-sheet/download';
export const DOWNLOAD_MEMBER_INFORMATION_SHEET =
  '/api/MemberProfile/member-information-sheet/download';
export const RE_GENERATE_MEMBER_INFORMATION_SHEET =
  '/api/MemberProfile/member-info-sheet/re-generate';

export const GET_MEMBER_INFO_SHEET = '/api/MemberProfile/get-member-info-sheet';
export const GET_MEMBER_PROFILE_IMAGE =
  '/api/MemberProfile/get-member-profile-image';

export const GET_GDSN_PARTY_INFO = '/api/MemberProfile/get-gdsn-party-info';
export const EDIT_GDSN_PARTY_INFO = '/api/MemberProfile/edit-gdsn-party-info';
export const SEND_GDSN_PARTY_REGISTER =
  '/api/syndication/send-gdsn-party-register';
export const UNREGISTER_MEMBER_GLNS =
  '/api/member-manager/unregister-member-glns';

export const ADD_NEW_SHOWCASE = '/api/MemberProfile/edit/add-member-showcase';
export const REMOVE_SHOWCASE =
  '/api/MemberProfile/edit/delete-or-remove-showcase';

export const GET_ALL_MEMBERS_FOR_REASSIGN =
  '/api/MemberProfile/get-all-members-reassign-items';

export const GET_MEMBERS_CUSTOM_PROPERTIES =
  '/api/member/get-custom-fields-manage-grid';
export const GET_MEMBERS_CUSTOM_PROPERTIES_DISTINCT =
  '/api/member/get-custom-fields-manage-grid/distinct';

export const GET_MEMBER_CUSTOM_PROPERTIES_BY_MEMBER_ID =
  '/api/member/get-custom-fields';

export const CREATE_CUSTOM_FIELD = '/api/member/create-custom-field';
export const EDIT_CUSTOM_FIELD = '/api/member/edit-custom-field';
export const DELETE_CUSTOM_FIELD = '/api/member/delete-custom-field';
export const REACTIVE_COMPANY_CONTACT = '/api/user-profile/reactive-user';
export const REACTIVE_USER = '/api/member-manager/reactive-user';

export const EDIT_E_COMMERCE_PRODUCT =
  '/api/MemberProfile/edit/edit-ecom-size-template-setting';

export const MOVE_ALL_TO_MEMBER = '/api/MemberProfile/move-all-to-member';
