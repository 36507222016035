/**
 * The global state selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = (state) => (state && state.global) || initialState;

const selectGlobalLoading = () =>
  createSelector(selectGlobal, (globalState) => globalState.loading);
const selectEula = () =>
  createSelector(selectGlobal, (globalState) => globalState.eula);
const selectAcceptedEula = () =>
  createSelector(selectGlobal, (globalState) => globalState.acceptedEula);
const selectPersonalization = () =>
  createSelector(selectGlobal, (globalState) => globalState.personalization);
const selectCriticalMessage = () =>
  createSelector(selectGlobal, (globalState) => globalState.criticalMessage);
const selectLandingPage = () =>
  createSelector(selectGlobal, (globalState) => globalState.landingPage);
const selectValidateComplete = () =>
  createSelector(
    selectGlobal,
    (globalState) =>
      globalState.eula &&
      globalState.personalization &&
      globalState.criticalMessage &&
      globalState.landingPage
  );
const selectControlOptions = () =>
  createSelector(selectGlobal, (globalState) => globalState.controlOptions);
const selectSearchCriteria = () =>
  createSelector(selectGlobal, (globalState) => globalState.searchCriteria);

const selectFavorites = () =>
  createSelector(selectGlobal, (globalState) => globalState.favorites);
const selectSelectedKey = () =>
  createSelector(selectGlobal, (globalState) => globalState.selectedKey);
const selectDetail = () =>
  createSelector(selectGlobal, (globalState) => globalState.detail);
const selectShowDetail = () =>
  createSelector(selectGlobal, (globalState) => globalState.showDetail);
const selectInContainer = () =>
  createSelector(selectGlobal, (globalState) => globalState.inContainerDetail);
const selectQuickView = () =>
  createSelector(selectGlobal, (globalState) => globalState.quickView);
const selectLogging = () =>
  createSelector(selectGlobal, (globalState) => globalState.logging);
const selectSearchAllItems = () =>
  createSelector(selectGlobal, (globalState) => globalState.searchAllItems);
const selectShowSearchBar = () =>
  createSelector(selectGlobal, (globalState) => globalState.showSearchBar);
const selectShowRibbonBar = () =>
  createSelector(selectGlobal, (globalState) => globalState.showRibbonBar);
const selectUploadDownloadProgress = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.uploadDownloadProgress
  );

const selectShowManageShare = () =>
  createSelector(selectGlobal, (globalState) => globalState.showManageShare);
const selectShowAddToFolder = () =>
  createSelector(selectGlobal, (globalState) => globalState.showAddToFolder);
const selectShowAddFolderToAPL = () =>
  createSelector(selectGlobal, (globalState) => globalState.showAddFolderToAPL);
const selectColumnsMember = () =>
  createSelector(selectGlobal, (globalState) => globalState.columnsMember);
const selectColumnsUser = () =>
  createSelector(selectGlobal, (globalState) => globalState.columnsUsers);
const selectOpenItem = () =>
  createSelector(selectGlobal, (globalState) => globalState.openItem.items);
const selectFullOpenItem = () =>
  createSelector(selectGlobal, (globalState) => globalState.openItem);
const selectOpenItemFormData = () =>
  createSelector(selectGlobal, (globalState) => globalState.openItem.formData);
const selectIsAddingOpenItem = () =>
  createSelector(selectGlobal, (globalState) => globalState.openItem.isAdding);
const selectIsAddingOpenItemFormData = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.openItem.isAddingFormData
  );
const selectCurrentTab = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.openItem.currentTab
  );

const selectCurrentOpenItem = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.openItem.currentOpenItem
  );
const selectIsHelpCenterOpen = () =>
  createSelector(selectGlobal, (globalState) => globalState.isHelpCenterOpen);
const selectOptionSearchHelp = () =>
  createSelector(selectGlobal, (globalState) => globalState.optionSearchHelp);
const selectFilterAssets = () =>
  createSelector(selectGlobal, (globalState) => globalState.filterAssets);
const selectBrandingData = () =>
  createSelector(selectGlobal, (globalState) => globalState.brandingData);

const selectMediaAssetFiles = () =>
  createSelector(selectGlobal, (subState) => subState.mediaAssetFiles);
const selectIsFinishLoadMore = () =>
  createSelector(selectGlobal, (subState) => subState.isFinishLoadMoreMedia);

const selectCurrentFolderId = () =>
  createSelector(selectGlobal, (globalState) => globalState.currentFolderId);

const selectSelectSearchText = () =>
  createSelector(selectGlobal, (globalState) => globalState.searchText);

const selectMinimizedProgressBar = () =>
  createSelector(selectGlobal, (state) => state.minimizedProgressBar);

const selectHiddenProgressBar = () =>
  createSelector(selectGlobal, (state) => state.hiddenProgressBar);

const selectReloadPage = () =>
  createSelector(selectGlobal, (state) => state.shouldReloadPage);
const selectIsOnlyDetailGrid = () =>
  createSelector(
    selectGlobal,
    (gridViewState) => gridViewState.isOnlyDetailGrid
  );

// Back to Url member
const selectIsProdToMember = () =>
  createSelector(
    selectGlobal,
    (gridViewState) => gridViewState.isProductsToMember
  );

const selectIsAssetsToMember = () =>
  createSelector(
    selectGlobal,
    (gridViewState) => gridViewState.isAssetsToMember
  );

const selectShowAdvanceFilter = () =>
  createSelector(selectGlobal, (globalState) => globalState.showAdvanceFilter);

const selectMarkdownReference = () =>
  createSelector(selectGlobal, (globalState) => globalState.markdownRef);

const selectHelpCenterMarkdownReference = () =>
  createSelector(selectGlobal, (globalState) => globalState.markdownHHelpRef);

const selectArticleShowcaseManagement = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.articleShowcaseManagement
  );

const selectMemberMaintenanceId = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.memberMaintenanceId
  );
const selectIsBackFromQuery = () =>
  createSelector(selectGlobal, (globalState) => globalState.isBackFromQuery);
const selectIsBackFromReporting = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isBackFromReporting
  );
const selectFolderIdPanel = () =>
  createSelector(selectGlobal, (globalState) => globalState.folderIdPanel);

const selectIsDisplayShowPreviewAcceptedEula = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isDisableShowPreview
  );

const selectIsShowGridQuery = () =>
  createSelector(selectGlobal, (globalState) => globalState.isShowGridQuery);
const selectTodoList = () =>
  createSelector(selectGlobal, (globalState) => globalState.todoList);

const selectTotalTodoList = () =>
  createSelector(selectGlobal, (globalState) => globalState.totalTodoList);
const selectMeetingList = () =>
  createSelector(selectGlobal, (globalState) => globalState.meetingList);

const selectTotalMeetingList = () =>
  createSelector(selectGlobal, (globalState) => globalState.totalMeetingList);
const selectAwaitingList = () =>
  createSelector(selectGlobal, (globalState) => globalState.awaitingList);

const selectMemberInfomationSheet = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.memberInfomationSheet
  );
const selectMemberInfomationSheetError = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.memberInfomationSheetError
  );

const selectIsChatViewOpen = () =>
  createSelector(selectGlobal, (globalState) => globalState.isChatViewOpen);
const selectSignalRConnection = () =>
  createSelector(selectGlobal, (globalState) => globalState.signalRConnection);
const selectSignalRConnectionError = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.signalRConnectionErr
  );
const selectNewMsgInfo = () =>
  createSelector(selectGlobal, (globalState) => globalState.newMsgInfo);

const selectNewEmailInfo = () =>
  createSelector(selectGlobal, (globalState) => globalState.newEmailInfo);

const selectIsEmailViewOpen = () =>
  createSelector(selectGlobal, (globalState) => globalState.isEmailViewOpen);

const selectVideoCall = () =>
  createSelector(selectGlobal, (globalState) => globalState.videoCall);

const selectMessageNotifyCount = () =>
  createSelector(selectGlobal, (globalState) => globalState.totalMessageUnread);

const selectEmailNotifyCount = () =>
  createSelector(selectGlobal, (globalState) => globalState.totalEmailUnread);

const selectMdTableAction = () =>
  createSelector(selectGlobal, (globalState) => globalState.mdTableAction);

const selectIsGettingPlaceholderList = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isGettingPlaceholderList
  );
const selectFilterProducts = () =>
  createSelector(selectGlobal, (globalState) => globalState.filterProducts);
const selectFilterMemberProducts = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.filterMemberProducts
  );

const selectBrickCodeModules = () =>
  createSelector(selectGlobal, (globalState) => globalState.brickCodeModules);

const selectProductTargetMarket = () =>
  createSelector(selectGlobal, (globalState) => globalState.productTargeMarket);
const selectHierarchyAlertId = () =>
  createSelector(selectGlobal, (globalState) => globalState.hierarchyAlertId);
const selectCheckGetListAfterSendMessage = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.checkGetListAfterSendMessage
  );
const selectCheckGetListAfterSendEmail = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.checkGetListAfterSendEmail
  );

const selectNewSyndicationHistory = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.newSyndicationHistory
  );

const selectFilterPendingPublication = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.filterPendingPublication
  );

const selectDisplayProductNew = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isDisplayProductNew
  );

const selectDisplayMemberNew = () =>
  createSelector(selectGlobal, (globalState) => globalState.isDisplayMemberNew);

const selectDisplayAssetNew = () =>
  createSelector(selectGlobal, (globalState) => globalState.isDisplayAssetNew);

const selectDisplayRequestNew = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isDisplayRequestNew
  );

const selectSelectedRequest = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.selectedPermissionRequest
  );

const makeSelectEnvironment = () =>
  createSelector(selectGlobal, (globalState) => globalState.environment);

const selectDisplayProductUnmatch = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isDisplayProductUnmatch
  );

const selectDisplayMemberUnmatch = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isDisplayMemberUnmatch
  );

const selectDisplayAssetUnmatch = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.isDisplayAssetUnmatch
  );
const selectFilterMembers = () =>
  createSelector(selectGlobal, (globalState) => globalState.filterMembers);

const selectFilterNone = () =>
  createSelector(selectGlobal, (globalState) => globalState.filterNone);

const selectToggleSsoUserPending = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.filterSsoUserPending
  );

const selectToggleSsoMemberPending = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.filterSsoMemberPending
  );

const selectCreditCardModalVisible = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.creditCardEula.modalVisible
  );

const selectCreditCardIsAccepted = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.creditCardEula.isAccepted
  );

const selectShowChargeWarning = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.creditCardEula.showChargeWarning
  );

const selectCreditCardShowWhen = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.creditCardEula.when
  );

const selectExportLoading = () =>
  createSelector(selectGlobal, (globalState) => globalState.exportLoading);

const selectFilterUserMaintenance = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.filterUserMaintenance
  );

const selectFilterTicketingSystem = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.filterTicketingSystem
  );
const selectNewTicketingSystem = () =>
  createSelector(selectGlobal, (globalState) => globalState.newTicketingSystem);
export {
  selectGlobalLoading,
  selectEula,
  selectAcceptedEula,
  selectPersonalization,
  selectCriticalMessage,
  selectLandingPage,
  selectValidateComplete,
  selectControlOptions,
  selectSearchCriteria,
  selectFavorites,
  selectSelectedKey,
  selectDetail,
  selectShowDetail,
  selectInContainer,
  selectQuickView,
  selectLogging,
  selectSearchAllItems,
  selectShowSearchBar,
  selectShowRibbonBar,
  selectUploadDownloadProgress,
  selectMinimizedProgressBar,
  selectShowManageShare,
  selectShowAddToFolder,
  selectColumnsMember,
  selectColumnsUser,
  selectOpenItem,
  selectOpenItemFormData,
  selectFullOpenItem,
  selectIsAddingOpenItem,
  selectIsAddingOpenItemFormData,
  selectCurrentTab,
  selectCurrentOpenItem,
  selectIsHelpCenterOpen,
  selectOptionSearchHelp,
  selectFilterAssets,
  selectMediaAssetFiles,
  selectCurrentFolderId,
  selectSelectSearchText,
  selectBrandingData,
  selectHiddenProgressBar,
  selectReloadPage,
  selectIsOnlyDetailGrid,
  selectIsProdToMember,
  selectTotalTodoList,
  selectIsAssetsToMember,
  selectShowAdvanceFilter,
  selectMarkdownReference,
  selectArticleShowcaseManagement,
  selectMemberMaintenanceId,
  selectIsBackFromQuery,
  selectIsBackFromReporting,
  selectFolderIdPanel,
  selectIsDisplayShowPreviewAcceptedEula,
  selectIsShowGridQuery,
  selectHelpCenterMarkdownReference,
  selectMemberInfomationSheet,
  selectMemberInfomationSheetError,
  selectTodoList,
  selectMeetingList,
  selectTotalMeetingList,
  selectIsChatViewOpen,
  selectAwaitingList,
  selectIsEmailViewOpen,
  selectSignalRConnection,
  selectSignalRConnectionError,
  selectNewMsgInfo,
  selectVideoCall,
  selectMessageNotifyCount,
  selectEmailNotifyCount,
  selectNewEmailInfo,
  selectMdTableAction,
  selectIsGettingPlaceholderList,
  selectFilterProducts,
  selectBrickCodeModules,
  selectIsFinishLoadMore,
  selectProductTargetMarket,
  selectHierarchyAlertId,
  selectShowAddFolderToAPL,
  selectCheckGetListAfterSendMessage,
  selectCheckGetListAfterSendEmail,
  selectNewSyndicationHistory,
  selectFilterPendingPublication,
  selectDisplayProductNew,
  selectDisplayMemberNew,
  selectDisplayAssetNew,
  selectDisplayRequestNew,
  selectSelectedRequest,
  makeSelectEnvironment,
  selectDisplayProductUnmatch,
  selectDisplayMemberUnmatch,
  selectDisplayAssetUnmatch,
  selectFilterMembers,
  selectFilterNone,
  selectToggleSsoUserPending,
  selectToggleSsoMemberPending,
  selectFilterMemberProducts,
  selectCreditCardModalVisible,
  selectCreditCardIsAccepted,
  selectCreditCardShowWhen,
  selectShowChargeWarning,
  selectExportLoading,
  selectFilterUserMaintenance,
  selectFilterTicketingSystem,
  selectNewTicketingSystem,
};
