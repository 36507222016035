import React from 'react';
import { useSelector } from 'react-redux';

import EmailEditorSection from './EmailEditorSection';
import EmailEditorContentElement from './EmailEditorContentElement';
import EmailEditorNoContentShowMessage from './EmailEditorNoContentShowMessage';
import {
  EmailEditorSectionSetting,
  EmailEditorSenderInfoSettings,
} from '../settings';

import userSelectors from 'redux/user/selectors';

import { formatMDY } from 'utils/formatDate';
import { useEmailEditorContext } from '../../controllers/context';

const EmailEditorSenderInfo = (props) => {
  const { settings } = props;

  const { senderInfo } = useEmailEditorContext();
  const userInfo = useSelector(userSelectors.makeSelectUserProfileHeader());

  const date = formatMDY(Date.now());
  const fullName = `${userInfo?.firstName} ${userInfo?.lastName}`;

  const company = senderInfo?.loggedInCompanyName
    ? senderInfo?.loggedInCompanyName
    : userInfo?.companyName;

  const senderAddress = `${senderInfo?.hqStressAddress}, ${senderInfo?.hqCity}, ${senderInfo?.hqState}`;
  const address = senderInfo
    ? senderAddress
    : userInfo?.primaryAddress?.mainAddress;

  const isShowDate = settings.show.includes('date');
  const isShowFullName = settings.show.includes('name');
  const isShowCompany = settings.show.includes('company');
  const isShowAddress = settings.show.includes('address');
  const isHideAll =
    !isShowDate && !isShowFullName && !isShowCompany && !isShowAddress;

  return (
    <EmailEditorSectionSetting
      settings={<EmailEditorSenderInfoSettings settings={settings} />}
    >
      <EmailEditorSection>
        {isShowDate ? (
          <EmailEditorContentElement content={date} editable={false} />
        ) : null}
        {isShowFullName ? (
          <EmailEditorContentElement content={fullName} editable={false} />
        ) : null}
        {isShowCompany ? (
          <EmailEditorContentElement content={company} editable={false} />
        ) : null}
        {isShowAddress ? (
          <EmailEditorContentElement content={address} editable={false} />
        ) : null}
        {isHideAll ? <EmailEditorNoContentShowMessage /> : null}
      </EmailEditorSection>
    </EmailEditorSectionSetting>
  );
};

EmailEditorSenderInfo.craft = {
  displayName: 'Sender Info',
  props: {
    settings: {
      bold: false,
      italic: false,
      underLine: false,
      fontSize: 14,
      show: ['date', 'name', 'company', 'address'],
      color: '#333',
    },
  },
};

export default EmailEditorSenderInfo;
