import React from 'react';
import { useDispatch } from 'react-redux';

import * as articleActions from 'pages/articles/controllers/actions';

import Messages from 'i18n/messages/maintenance';
import { LogoutOutlined } from '@ant-design/icons';
import { forwardTo } from 'utils/common/route';
import RibbonButton from 'common/components/button/RibbonButton';

const BackToArticle = () => {
  const dispatch = useDispatch();
  const handleBackToArticle = () => {
    dispatch(articleActions.resetModeArticle());
    dispatch(articleActions.disableConfirmEditArticle());
    dispatch(articleActions.saveIdArticle(null));
    forwardTo(`/maintenance/help/articles`);
  };
  return (
    <RibbonButton
      icon={<LogoutOutlined style={{ transform: 'rotate(180deg)' }} />}
      label={Messages.backToArticle}
      onClick={handleBackToArticle}
    />
  );
};

export default BackToArticle;
