import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useRef,
  useReducer,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Typography,
  Tooltip,
  Divider,
  Progress,
  Image,
  Button,
  Row,
  Col,
  Space,
} from 'antd';
import {
  InboxOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
  CheckCircleFilled,
  PaperClipOutlined,
  CloseOutlined,
  ClearOutlined,
  CloudUploadOutlined,
  FileExcelOutlined,
  LinkOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import * as _ from 'lodash';
import { formatAcceptFileType } from 'utils/acceptFileType';
import { formatUploadAttachment } from 'utils';

//* COMPONENTS
import { CustomNotification, dialogFunction } from 'common/components';
import xls from 'assets/file-formats/xls.png';

//* UTILS
import * as api from 'config/axios';
import { DataToMsOffice } from 'utils/dataToMSoffice';
import { mapOriginalFileTypeToImage } from 'utils/fileType';
import { DEBOUNCE_TIME, ROUTE } from 'static/Constants';
import { SUPPORT_COMMON_TYPES } from './Constants';
import { importAssetsFromInterop } from 'services/interoperability';

//* STYLING
import './UploadFile.less';
import { forwardTo } from 'utils/common/route';
import { sleep } from 'utils/delay';

const { Dragger } = Upload;
const { Text, Paragraph: p, Title } = Typography;

const origin = window.location.origin;

const mockAPI = 'https://www.mocky.io/v2/5cc8019d300000980a055e76';

const initialUniqueFilesState = {
  action: 'none',
  data: [],
  removedFileId: null,
};

const DEFAULT_ERROR_MESSAGE = {
  'error.fileSize': (maxSize) =>
    `The file you have loaded is larger than the maximum size of ${maxSize}MB.  Please contact our customer support team` ||
    `File size exceeds ${maxSize}MB`,
  'error.fileType': 'File type is not allowed',
  'error.imageOnly': 'Accept image only',
};

const DEFAULT_FAIL_UPLOAD_MSG = 'Failed to upload';

const UploadFile = forwardRef((props, ref) => {
  const {
    showList = true,
    multiple = false,
    showUploadList = false,
    //* additional props
    classNames,
    wrapperStyle,
    base64 = false,
    apiUrl,
    getResponse,
    getResponseFail,
    onlyAcceptImage = false,
    manualUpload = false,
    showMultiple = true,
    base64UploadKey = 'unknown',
    multipleImport,
    callbackDataList,
    maxSize,
    supportTypes = SUPPORT_COMMON_TYPES,
    handleUploadFile,
    getFileChange,
    onFileChange,
    showSubmitBtn = true,
    additionalBodyPayload,
    showXlsTemplate,
    templateUrl,
    confirmBeforeUpload,
    getLoading,
    showUploadSuccessMsg = true,
    showServerMessage = false,
    confirmMessage,
    accept,
    disabled,
    returnUniqueFiles = false,
    isShowCancelButton = false,
    handleCancel,
    nameFormData,
    uploadAPL,
    showPreview,
    uploadService,
    notShowMessage = false,
    customErrorMessage,
    manualUploadHandler,
    forceDisableManudalUpload,
    isRedirect = true,
  } = props;

  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [dragEnter, setDragEnter] = useState(false);
  const [paramsUpload, setParamsUpload] = useState([]);
  const [selectedItemPreview, setSelectedItemPreview] = useState(null);

  const [isUploading, setIsUploading] = useState(false);
  const [xlsList, setXlsList] = useState(undefined);

  const [uniqueFilesState, setUniqueFilesState] = useReducer(
    (_, action) => action,
    initialUniqueFilesState
  );

  const refWrapper = useRef();
  const refController = useRef(new AbortController());

  const errorMessage = useMemo(() => {
    return {
      ...DEFAULT_ERROR_MESSAGE,
      ...customErrorMessage,
    };
  }, [customErrorMessage]);

  useEffect(() => {
    if (multipleImport)
      if (typeof callbackDataList === 'function') callbackDataList(fileList);
  }, [fileList, multipleImport, callbackDataList]);

  useEffect(() => {
    if (returnUniqueFiles) {
      onFileChange && onFileChange(uniqueFilesState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueFilesState?.data]);

  const handleReturnResponse = (fileList) => {
    if (getResponse && multiple === false) {
      getResponse(fileList[fileList.length - 1]);
    }
  };

  //* get base64 data
  const getBase64 = async (img) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleRemove = (fileId) => {
    if (returnUniqueFiles) {
      setUniqueFilesState({
        action: 'remove',
        removedFileId: fileId,
      });
    }

    setFileList((fileList) => fileList.filter((item) => item.uid !== fileId));
  };

  const removeAllFile = () => {
    if (returnUniqueFiles) {
      setUniqueFilesState(initialUniqueFilesState);
    }

    setFileList([]);
  };

  const getFileExtension = (filename) => {
    return filename ? filename.split('.').pop() : '';
  };

  const checkHasSupportTypeFile = (filename) => {
    const fileExtension = getFileExtension(filename).toLowerCase();
    return supportTypes.includes(fileExtension);
  };

  const handleChange = (info) => {
    let realFileList = info.fileList;

    realFileList = realFileList.map((file) => {
      const fileUid = file?.uid;

      const isInfoFileMatchFile = fileUid === info.file.uid;

      if (file.response) {
        file.url = file.response.url;
      } else {
        if (isInfoFileMatchFile) {
          info.file.status = file.status;
        }
      }

      if (multipleImport) {
        if (onlyAcceptImage && !_.includes(file.type, 'image')) {
          file.status = 'error.imageOnly';
          return file;
        }
        if (maxSize && bytesToMegaBytes(file.size) > maxSize) {
          file.status = 'error.fileSize';

          return file;
        }

        if (!checkHasSupportTypeFile(file?.name)) {
          file.status = 'error.fileType';
          return file;
        }

        file.status = 'uploading';
      }
      return file;
    });

    if (!multiple && !multipleImport) {
      setFileList([realFileList?.[realFileList?.length - 1]]);
    } else {
      setFileList(realFileList);
    }

    if (info && getFileChange) {
      getFileChange(info);
    }

    if (info.file.status === 'uploading') {
      if (manualUpload) {
        return;
      }
      setLoading(true);
      return;
    }

    if (info.file.status === 'error') {
      setPercentage(0);
      setLoading(false);
      handleReturnResponse(realFileList);
      return;
    }

    if (info.file.status === 'done') {
      setLoading(false);
      setPercentage(0);
      handleReturnResponse(realFileList);
      return;
    }
  };

  const abortRequest = () => {
    refController.current.abort();
  };

  const onProgress = (event) => {
    if (event) {
      setPercentage(Math.ceil(event.percent));
    }
  };

  const isNotValidType = (typeArray, fileExt) => {
    return typeArray.find((type) => _.includes(fileExt, type));
  };

  const removePreview = () => setSelectedItemPreview(null);

  const debounceNotifyErrorFileSize = useCallback(
    _.debounce(
      (message) => CustomNotification.error(message),
      DEBOUNCE_TIME.GENERAL
    ),
    []
  );
  const debounceNotifyErrorFileType = useCallback(
    _.debounce(
      (message) => CustomNotification.error(message),
      DEBOUNCE_TIME.GENERAL
    ),
    []
  );
  const debounceNotifyErrorImageOnly = useCallback(
    _.debounce(
      (message) => CustomNotification.error(message),
      DEBOUNCE_TIME.GENERAL
    ),
    []
  );

  const beforeUpload = (file, fileList) => {
    if (returnUniqueFiles && fileList?.length > 0) {
      setUniqueFilesState({
        data: formatUploadAttachment(fileList),
        action: 'add',
        removedFileId: null,
      });
    }

    if (onlyAcceptImage && !_.includes(file.type, 'image')) {
      file.status = 'error.imageOnly';
      debounceNotifyErrorImageOnly('Accept image only');
      removePreview();
      return false;
    }

    if (maxSize && bytesToMegaBytes(file.size) > maxSize) {
      file.status = 'error.fileSize';

      const messageGetter = errorMessage['error.fileSize'];
      const message =
        typeof messageGetter === 'function'
          ? messageGetter(maxSize)
          : messageGetter;

      debounceNotifyErrorFileSize(message);

      removePreview();
      return false;
    }

    const fileType = file.name?.toLowerCase().split('.').pop();

    if (supportTypes.length > 0 && !isNotValidType(supportTypes, fileType)) {
      file.status = 'error.fileType';

      const messageGetter = errorMessage['error.fileType'];
      const message =
        typeof messageGetter === 'function'
          ? messageGetter(fileType)
          : messageGetter;

      debounceNotifyErrorFileType(message);
      removePreview();
      return false;
    }
    return true;
  };

  const confirmUpload = (paramsUpload) => {
    if (confirmMessage) {
      dialogFunction({
        type: 'warn',
        content: confirmMessage,
        onOk: () => handleRequest(paramsUpload),
      });
    } else {
      handleRequest(paramsUpload);
    }
    setLoading(true);
  };

  const setChangeFileItem = (newItemArray) => {
    let nextFileList = [...fileList];
    newItemArray.forEach((fileItem, idx) => {
      nextFileList[idx] = fileItem;
    });

    setFileList(nextFileList);
  };

  const handleRequest = async (params) => {
    //* handle services pass down from props
    if (uploadService) {
      const isSuccess = await uploadService();

      if (isSuccess) {
        if (params?.file?.status) params.file.status = 'done';
        params?.onSuccess && params.onSuccess(null, params.file);
      } else {
        if (params?.file?.status) params.file.status = 'error';
        params?.onError && params.onError('error');
      }
      return;
    }

    let payload;

    if (base64) {
      const base64Data = await getBase64(params.file);
      payload = {
        [base64UploadKey]: base64Data,
      };
    } else {
      payload = {
        file: params.file,
      };
    }

    setIsUploading(true);

    const imageUploadSuccessMessage =
      !!params?.file?.type?.includes('image') &&
      'Image is uploaded successfully!';

    const options = {
      signal: refController.current.signal,
    };

    api
      .sendUpload({
        api: apiUrl ? origin + apiUrl : mockAPI,
        payload,
        additionalBodyPayload:
          typeof additionalBodyPayload === 'function'
            ? await additionalBodyPayload()
            : additionalBodyPayload,
        onEventHandlers: {
          onProgress: (progress) => {
            const { loaded, total } = progress;
            params?.onProgress &&
              params.onProgress(
                { percent: Math.round((loaded / total) * 100).toFixed(2) },
                params.file
              );
          },
          onError: (error) => {
            if (params?.file?.status) params.file.status = 'error';
            params?.onError && params.onError(error);
          },
          onSuccess: (result) => {
            if (params?.file?.status) params.file.status = 'done';
            params?.onSuccess && params.onSuccess(result, params.file);
            handleUploadFile && handleUploadFile(result);
          },
        },
        isBase64: base64,
        nameFormData,
        options,
      })
      .then((response) => {
        if (response?.data?.isSuccess) {
          showUploadSuccessMsg &&
            CustomNotification.success(
              response?.data?.message ||
                imageUploadSuccessMessage ||
                'Asset is uploaded successfully!'
            );
          getResponse(response);
        } else {
          if (!notShowMessage) {
            CustomNotification.error(
              showServerMessage
                ? response?.data?.message || DEFAULT_FAIL_UPLOAD_MSG
                : DEFAULT_FAIL_UPLOAD_MSG
            );
          }
          getResponseFail && getResponseFail(response);

          if (uploadAPL) getResponse(response?.data);
          params.file.status = 'error';
          setChangeFileItem([params.file]);
        }
        setIsUploading(false);
      })
      .catch(() => {
        setIsUploading(false);
      });
  };

  const handleRequestMultiple = async (fileParamsName) => {
    let formData = new FormData();
    fileList.forEach((fileData) =>
      formData.append(fileParamsName || 'files', fileData.originFileObj)
    );

    if (additionalBodyPayload) {
      let payload = additionalBodyPayload;

      if (typeof additionalBodyPayload === 'function') {
        payload = await additionalBodyPayload();
      }

      Object.keys(payload).forEach((keyObject) => {
        formData.append(keyObject, payload[keyObject]);
      });
    }

    xlsList && formData.append('TemplateFile', xlsList);

    setIsUploading(true);
    setLoading(true);
    api
      .sendUpload({
        api: origin + apiUrl,
        payload: formData,
        onEventHandlers: {
          onProgress: (progress) => {
            const { loaded, total } = progress;
            setPercentage(Math.round((loaded / total) * 100));
          },
          onError: (error) => {
            setPercentage(0);
            setLoading(false);
          },
          onSuccess: (result) => {
            setPercentage(100);
            setLoading(false);
          },
        },
        isUploadMultiple: true,
      })
      .then((response) => {
        setIsUploading(false);
        if (response?.data?.isSuccess) {
          CustomNotification.success(
            response?.data?.message ?? 'Upload successfully!'
          );
          getResponse && getResponse(response);
        } else {
          getResponseFail && getResponseFail(response);
          if (!notShowMessage) {
            CustomNotification.error(
              showServerMessage
                ? response?.data?.message || DEFAULT_FAIL_UPLOAD_MSG
                : DEFAULT_FAIL_UPLOAD_MSG
            );
          }
        }
      })
      .catch((err) => {
        setIsUploading(false);
        CustomNotification.error(DEFAULT_FAIL_UPLOAD_MSG);
      });
  };

  const handleClickMultipleUpload = () => {
    dialogFunction({
      type: 'warn',
      content: confirmBeforeUpload,
      onOk: async () => {
        await sleep(1000);
        if (isRedirect) forwardTo(ROUTE.DIGITAL_MEDIA);
        handleRequestMultiple();
      },
      okText: 'Upload',
      width: 450,
    });
  };

  const bytesToMegaBytes = useCallback((bytes) => bytes / (1024 * 1024), []);

  const customRequest = async (params) => {
    if (onlyAcceptImage || showPreview) {
      setSelectedItemPreview({
        uid: showMultiple ? params.file.uid : null,
        imageData: await getBase64(params.file),
        name: params?.file?.name,
      });
    }

    if (manualUpload || multipleImport) {
      setParamsUpload([...paramsUpload, params]);
      return;
    }

    handleRequest(params);
  };

  const handleSelectItem = async (file) => {
    setSelectedItemPreview({
      uid: showMultiple ? file.uid : null,
      imageData: await getBase64(file.originFileObj),
    });
  };

  const mapStatusToColor = useCallback((status) => {
    switch (status) {
      case 'done':
        return '#389e0d';
      case 'error':
        return '#ff4d4f';
      default:
        return 'gray';
    }
  }, []);

  const ListItemLoading = (rawStatus) => {
    let status = _.cloneDeep(rawStatus);
    if (manualUpload && rawStatus === 'uploading') {
      status = 'preview';
    }
    if (multipleImport && rawStatus === 'uploading') {
      status = 'preview';
    }
    switch (status) {
      case 'done':
        return <CheckCircleFilled style={{ color: '#389e0d', fontSize: 15 }} />;
      case 'error':
        return (
          <ExclamationCircleFilled style={{ color: '#f5222d', fontSize: 15 }} />
        );
      case 'error.imageOnly':
        return (
          <ExclamationCircleFilled style={{ color: '#f5222d', fontSize: 15 }} />
        );
      case 'error.fileSize':
        return (
          <ExclamationCircleFilled style={{ color: '#f5222d', fontSize: 15 }} />
        );
      case 'uploading':
        return <LoadingOutlined style={{ color: '#1890ff', fontSize: 15 }} />;
      default:
        return <PaperClipOutlined style={{ color: '#1890ff', fontSize: 15 }} />;
    }
  };
  const handleAttachTemplate = () => {
    const $input = document.createElement('input');
    $input.setAttribute('type', 'file');
    const handleSelectFile = (event) => {
      let file = event.target.files[0];
      if (file) {
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          setXlsList(file);
          CustomNotification.success('The file is attached successfully');
        } else {
          CustomNotification.error('Only accept xls file');
          setXlsList(undefined);
        }
      }
    };
    $input.onchange = handleSelectFile;
    $input.click();
  };

  const handleDownloadTemplate = () => {
    api.sendDownload({
      url: templateUrl || '/unknown',
    });
  };

  const handleCopyFileNameToExcel = () => {
    fileList &&
      DataToMsOffice().copy2DArrayClipboardToExcel(
        fileList.map((fileItem) => [fileItem.name])
      );
  };

  const handleClickUpload = (e) => {
    if (ref.current) {
      const $uploadWrapper = ref.current.current;
      const $btnUpload = $uploadWrapper.querySelector('.ant-upload-btn');
      $btnUpload && $btnUpload.click();
    }
  };

  const handleAddFileOuter = (fileObjectList) => {
    setFileList((prevFileList) => {
      const newFileList = Array.from(fileObjectList).map((fileObject) => {
        const uuid = 'rc-upload-' + uuidv4().toString();
        return {
          //TODO: DATETIME
          lastModified: fileObject?.lastModified,
          lastModifiedDate: fileObject?.lastModifiedDate,
          name: fileObject?.name,
          originFileObj: fileObject,
          percent: 0,
          size: fileObject?.size,
          status: 'uploading',
          type: fileObject?.type,
          uid: uuid,
        };
      });

      return [...prevFileList, ...newFileList];
    });
  };

  const isHaveErrorInFileList = () =>
    fileList.some((fileItem) => fileItem?.status.includes('error'));
  let configs = {
    showUploadList: showUploadList,
    action: apiUrl ? origin + apiUrl : mockAPI,
    multiple: multiple || multipleImport,
    fileList: fileList,
    onChange: handleChange,
    beforeUpload,
    onProgress,
    customRequest,
    accept,
    disabled,
  };

  !apiUrl && delete configs.headers;

  useEffect(() => {
    getLoading && getLoading(loading);
  }, [loading, getLoading]);

  useEffect(() => {
    if (returnUniqueFiles) return;

    onFileChange && onFileChange(fileList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const features = function () {};
  features.prototype.handleRequestMultiple = handleRequestMultiple;
  features.prototype.handleClickUpload = handleClickUpload;
  features.prototype.handleRemove = handleRemove;
  features.prototype.removeAllFile = removeAllFile;
  features.prototype.handleAddFileOuter = handleAddFileOuter;
  features.prototype.abortRequest = abortRequest;

  useEffect(() => {
    if (ref) {
      ref.current = {
        current: refWrapper.current,
        __proto__: features.prototype,
      };
    }
  });

  return (
    <div
      className={classnames('upload-file', classNames && classNames, {
        'upload-file--show-list': showList,
      })}
      style={{
        ...wrapperStyle,
        pointerEvents: isUploading ? 'none' : 'auto',
      }}
      ref={refWrapper}
    >
      <div
        className={classnames('upload-file__dragger-wrapper', {
          // 'upload-file__dragger-wrapper--collapsed':
          //   fileList.length > 0 && showMultiple && showList && !showXlsTemplate,
          // 'upload-file__dragger-wrapper--small-collapsed':
          //   fileList.length > 0 && !showMultiple && showList,
          // 'upload-file__dragger-wrapper--with-xls':
          //   fileList.length > 0 && showMultiple && showList && showXlsTemplate,
        })}
        onDragEnter={(__) => {
          !dragEnter && setDragEnter(true);
        }}
        onDragLeave={(__) => {
          dragEnter && setDragEnter(false);
        }}
        onDrop={(__) => {
          dragEnter && setDragEnter(false);
        }}
      >
        <Dragger {...configs}>
          <div
            className={classnames({
              'upload-file__dragger-inner': true,
              'upload-file__dragger-inner--disabled': disabled,
            })}
          >
            {loading && (
              <React.Fragment>
                <Progress
                  type='circle'
                  percent={percentage}
                  width={80}
                  status='normal'
                />
                <p className='ant-upload-text'>Uploading File...</p>
              </React.Fragment>
            )}

            {!loading && !selectedItemPreview && (
              <React.Fragment>
                <InboxOutlined
                  className={classnames('upload-file__icon-drop-box', {
                    'upload-file__icon-drop-box--bouncing': dragEnter,
                  })}
                />
                <p
                  className={classnames('ant-upload-text', {
                    'upload-file__text': true,
                    'upload-file__text--hightlight': dragEnter,
                  })}
                >
                  {!multipleImport
                    ? 'Click or drag file to this area to upload'
                    : 'Click or drag files to this area to upload'}
                </p>
                {supportTypes.length > 0 && (
                  <p
                    className={classnames('ant-upload-hint', {
                      'upload-file__text': true,
                      'upload-file__text--hightlight': dragEnter,
                    })}
                  >
                    {`Support Types: ${supportTypes.join(', ')}`}
                  </p>
                )}
              </React.Fragment>
            )}
            {!loading && selectedItemPreview && (
              <React.Fragment>
                <Image
                  className={classnames(
                    'upload-file__preview-image',
                    'upload-file__preview-image--fade-in',
                    {
                      'upload-file__preview-image--fileType': !selectedItemPreview?.imageData.includes(
                        'data:image'
                      ),
                    }
                  )}
                  src={selectedItemPreview?.imageData}
                  fallback={mapOriginalFileTypeToImage(
                    fileList[fileList.length - 1].name
                  )}
                />
              </React.Fragment>
            )}
          </div>
        </Dragger>
      </div>

      <div
        className={classnames('upload-file__file-list-wrapper', {
          'upload-file__file-list-wrapper--multiple-import':
            multipleImport && fileList?.length > 0,
          'upload-file__file-list-wrapper--invisible': !showList,
        })}
      >
        {fileList.length > 0 && (
          <div className='upload-file__file-list'>
            <Divider style={{ margin: 0 }}>
              {showMultiple ? (
                <React.Fragment>
                  Upload List
                  <Text className='upload-file__file-list-count'>
                    {fileList.length}
                  </Text>
                  {xlsList && (
                    <Row className='upload-file__xls'>
                      <Col>
                        <Tooltip title={xlsList.name}>
                          <Image
                            className='upload-file__xls-img'
                            height={25}
                            width={21}
                            src={xls}
                            placeholder={
                              <LoadingOutlined
                                style={{
                                  fontSize: 23,
                                  backgroundColor: 'white',
                                }}
                              />
                            }
                          />
                        </Tooltip>
                      </Col>
                      <Col
                        className='upload-file__xls-delete'
                        style={{ paddingLeft: 4 }}
                      >
                        <CloseOutlined onClick={() => setXlsList(undefined)} />
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>Upload File</React.Fragment>
              )}
            </Divider>
            <Row
              className={classnames('upload-file__file-list-content', {
                scroller: !showXlsTemplate,
              })}
            >
              <Col
                flex='auto'
                className={classnames({
                  'upload-file__file-list-content--single': !showMultiple,
                  'upload-file__file-list-content--show-xls': showXlsTemplate,
                  scroller: showXlsTemplate,
                })}
              >
                {fileList
                  .slice(0)
                  .reverse()
                  .map((file, idx) => {
                    if (!showMultiple && idx !== 0) {
                      return null;
                    }
                    return (
                      <div
                        className={classnames('upload-file__file-list-item', {
                          'upload-file__file-list-item--selected':
                            onlyAcceptImage &&
                            _.get(selectedItemPreview, 'uid') === file.uid,
                        })}
                        onClick={() => {
                          if (onlyAcceptImage || showPreview) {
                            handleSelectItem(file);
                          }
                        }}
                        key={'upload-item-key-' + file.uid}
                      >
                        <div className='upload-file__file-list-item-status'>
                          {ListItemLoading(file.status)}
                        </div>
                        <Tooltip
                          placement='topLeft'
                          title={file.name}
                          color={mapStatusToColor(file.status)}
                          key={`file-${file.uid}`}
                          mouseLeaveDelay={0}
                        >
                          <Text
                            className='upload-file__file-list-item-name'
                            ellipsis
                          >
                            {file.name}
                          </Text>
                        </Tooltip>
                        <div
                          className={classnames({
                            'upload-file__file-list-item-control': true,
                            // 'upload-file__file-list-item-control--invisible': !showSubmitBtn,
                          })}
                        >
                          {manualUpload && !multipleImport && showSubmitBtn && (
                            <Space>
                              {isShowCancelButton && (
                                <Button
                                  type='default'
                                  style={{ backgroundColor: '#fff' }}
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </Button>
                              )}
                              <Button
                                className={classnames(
                                  'upload-file__btn',
                                  'upload-file__btn-upload'
                                )}
                                type='primary'
                                icon={<CloudUploadOutlined />}
                                onClick={() =>
                                  manualUploadHandler
                                    ? manualUploadHandler()
                                    : confirmUpload(
                                        paramsUpload[
                                          paramsUpload.length - 1 - idx
                                        ]
                                      )
                                }
                                loading={isUploading}
                                disabled={
                                  file.status === 'error.fileType' ||
                                  file.status === 'error.fileSize' ||
                                  file.status === 'error.imageOnly' ||
                                  forceDisableManudalUpload
                                }
                              >
                                {isShowCancelButton ? 'Submit' : 'Upload'}
                              </Button>
                            </Space>
                          )}
                          {multipleImport && (
                            <Button
                              danger
                              shape='circle'
                              icon={<ClearOutlined />}
                              onClick={() => {
                                handleRemove(file.uid);
                                // handleRemoveUniqueFile(file.uid);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </Col>
              {multipleImport && (
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: 5,
                  }}
                >
                  <Space direction='vertical'>
                    {showXlsTemplate && (
                      <>
                        <Button
                          className={classnames(
                            'upload-file__btn',
                            'upload-file__btn-multi-download-xls'
                          )}
                          icon={<FileExcelOutlined />}
                          onClick={() => {
                            handleDownloadTemplate();
                          }}
                          disabled={fileList.length === 0}
                          loading={isUploading}
                        >
                          Download xls Template
                        </Button>
                        <Button
                          className={classnames(
                            'upload-file__btn',
                            'upload-file__btn-multi-copy'
                          )}
                          icon={<CopyOutlined />}
                          onClick={() => handleCopyFileNameToExcel()}
                          disabled={fileList.length === 0}
                          loading={isUploading}
                        >
                          Copy All Selected Items
                        </Button>

                        <Button
                          className={classnames(
                            'upload-file__btn',
                            'upload-file__btn-multi-attach-xls'
                          )}
                          icon={<LinkOutlined />}
                          onClick={() => {
                            handleAttachTemplate();
                          }}
                          disabled={fileList.length === 0}
                          loading={isUploading}
                        >
                          Attach File (.xls)
                        </Button>
                      </>
                    )}
                    {showSubmitBtn && (
                      <Button
                        className={classnames(
                          'upload-file__btn',
                          'upload-file__btn-multi-import',
                          {
                            'upload-file__btn-multi-import--without-xls': !showXlsTemplate,
                          }
                        )}
                        type='primary'
                        icon={<CloudUploadOutlined />}
                        onClick={() => handleClickMultipleUpload()}
                        disabled={
                          fileList.length === 0 ||
                          isHaveErrorInFileList() ||
                          forceDisableManudalUpload
                        }
                        loading={isUploading}
                      >
                        Send
                        <Text
                          className={classnames(
                            'upload-file__btn-multi-import-amount'
                          )}
                        >
                          {fileList.length}
                        </Text>
                      </Button>
                    )}
                  </Space>
                </Col>
              )}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
});

UploadFile.propTypes = {
  //? multiple- upload multiple file or not
  multiple: PropTypes.bool,
  //todo: experiment show/hide upload list
  showUploadListL: PropTypes.string,
  ///? classNames - add classname for wrapper
  classNames: PropTypes.string,
  //? wrapperStyle - style object of wrapper
  wrapperStyle: PropTypes.object,
  //? apiUrl - api url to post
  apiUrl: PropTypes.string,
  //? getResponse - this is a function help you get the reponse info + data
  getResponse: PropTypes.func,
  //? base64 - post file as base64
  base64: PropTypes.bool,
  //todo: base64UploadKey - support id key for upload thumbnail
  base64UploadKey: PropTypes.string,
  //? multipleImport: is upload multiple file ot not
  multipleImport: PropTypes.bool,
  callbackDataList: PropTypes.func,
  //? maxSize: max file size
  maxSize: PropTypes.number,
  //? supportTypes: allowed file types
  supportTypes: PropTypes.array,
  //? getFileChange: get file change
  getFileChange: PropTypes.func,
  //? showSubmitBtn: show submit/delete btn or not in manual upload mode
  showSubmitBtn: PropTypes.bool,
  //? additionalBodyPayload: additional body to post in manual upload mode
  additionalBodyPayload: PropTypes.object,
  //? showXlsTemplate: show xls template options
  showXlsTemplate: PropTypes.bool,
  templateUrl: PropTypes.string,
  confirmBeforeUpload: PropTypes.node,
};

UploadFile.defaultProps = {
  showList: true,
  multiple: false,
  showUploadList: false,
  base64: false,
  onlyAcceptImage: false,
  manualUpload: false,
  showMultiple: true,
  base64UploadKey: 'unknown',
  supportTypes: SUPPORT_COMMON_TYPES,
  showSubmitBtn: true,
  showUploadSuccessMsg: true,
  showServerMessage: false,
  returnUniqueFiles: false,
  isShowCancelButton: false,
  notShowMessage: false,
};

export default UploadFile;
