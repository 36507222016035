import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

import { useSelector } from 'react-redux';

import { RibbonBar, RibbonDivider } from 'common/components';
import { ViewLayout, AdvanceStack } from './components';
import OpenSection from './components/sections/query/grid/OpenSection';
import Addition from './components/sections/home-grid/AdditionSection';
import ShareSection from './components/sections/shared/ShareSection';
import ManageSharingSection from './components/sections/shared/ManageSharingSection';
import DetailSection from './components/sections/home-grid/DetailSection';

import gridSelectors from 'common/components/grid-view/controllers/selectors';

import useCheckFavoriteRoute from 'hooks/useCheckFavoriteRoute';

const Queries = () => {
  const selectedItemList = useSelector(
    gridSelectors.makeSelectDetailCurrentITemsSelection()
  );

  const isFavoriteView = useCheckFavoriteRoute();

  return (
    <RibbonBar>
      <ManageSharingSection />
      <DetailSection />
      <ViewLayout />
      <RibbonDivider />
      <Addition
        type='Query'
        selectedItemList={selectedItemList}
        isFavoriteView={isFavoriteView}
      />
      <ShareSection selectedItemList={selectedItemList} />
      <RibbonDivider />
      <OpenSection />
      <RibbonDivider />
      <AdvanceStack disabledAdvanceFilter={true} />
    </RibbonBar>
  );
};

Queries.propTypes = {};

export default Queries;
