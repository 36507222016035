import _ from 'lodash';
import React, { useState } from 'react';
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { Form, SectionWrapper } from 'common/components';
import {
  AddNewItem,
  AddWarningDetailButton,
  UpdateValueButton,
} from './DrugFactsButton';
import { useGetEnumsByEnumName } from 'hooks';

const WARNING_FIELD_NAME = [
  'warningText',
  'warningDetail',
  'boldText',
  'boldDetail',
];

const Warning = () => {
  const form = Form.useFormInstance();
  const [mode, setMode] = useState();

  const warningList = Form.useWatch('warnings', form);

  const onClickAddNew = () => {
    form.resetFields(WARNING_FIELD_NAME);
    setMode('add');
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    form
      .validateFields(WARNING_FIELD_NAME)
      .then((value) => {
        const { boldText, boldDetail, warningDetail } = value;
        form.setFieldsValue({
          warnings: [
            ...(warningList || []),
            {
              ...value,
              boldText: boldText || false,
              boldDetail: boldDetail || false,
              warningDetail: warningDetail || [],
            },
          ],
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ minHeight: '35vh' }}>
      <WarningList setMode={setMode} mode={mode} />
      {mode === 'add' && <AddWarningForm />}
      <AddNewItem
        mode={mode}
        onClickAdd={onClickAdd}
        onClickCancel={onClickCancel}
        onClickAddNew={onClickAddNew}
      />
    </div>
  );
};

export default Warning;

const WarningList = ({ setMode, mode }) => {
  const form = Form.useFormInstance();
  const warningList = Form.useWatch('warnings', form);

  const onClickUpdate = (index) => {
    const cloneWarningList = _.cloneDeep(warningList);

    form
      .validateFields(WARNING_FIELD_NAME)
      .then((value) => {
        cloneWarningList[index] = value;

        form.setFieldsValue({
          warnings: cloneWarningList,
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickDelete = (index) => {
    const cloneWarningList = _.cloneDeep(warningList);
    cloneWarningList.splice(index, 1);

    form.setFieldsValue({
      warnings: cloneWarningList,
    });
  };

  const onClickEdit = (index, item) => {
    setMode(`edit-${index}`);
    form.setFieldsValue({ ...item });
  };

  const onCancel = () => {
    setMode('view');
  };
  return (
    <>
      <Form.Item name='warnings' hidden />
      {warningList?.map((item, index) => {
        const { warningText, warningDetail, boldText, boldDetail } = item;
        const isEdit = mode === `edit-${index}`;
        const hasOnlyOneItem = warningDetail?.length === 1;
        return (
          <div key={index}>
            {isEdit ? (
              <>
                <AddWarningForm />
                <UpdateValueButton
                  onCancel={onCancel}
                  onClickUpdate={() => onClickUpdate(index)}
                />
              </>
            ) : (
              <SectionWrapper
                title={
                  <Space>
                    <EditOutlined onClick={() => onClickEdit(index, item)} />
                    <DeleteOutlined onClick={() => onClickDelete(index)} />
                  </Space>
                }
                placement='right'
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <Row>
                  <Col span={24}>
                    <Typography.Text
                      style={{ marginBottom: 0 }}
                      strong={boldText}
                    >
                      {warningText}&nbsp;
                    </Typography.Text>
                    {warningDetail?.map(({ warningDetail }) => {
                      if (hasOnlyOneItem)
                        return (
                          <Typography.Text
                            style={{ marginBottom: 0 }}
                            strong={boldDetail}
                          >
                            {warningDetail}
                          </Typography.Text>
                        );

                      return (
                        <Typography.Paragraph
                          style={{ marginBottom: 0 }}
                          strong={boldDetail}
                        >
                          ■ {warningDetail}
                        </Typography.Paragraph>
                      );
                    })}
                  </Col>
                </Row>
              </SectionWrapper>
            )}
          </div>
        );
      })}
    </>
  );
};

const AddWarningForm = () => {
  const form = Form.useFormInstance();
  const [mode, setMode] = useState();

  const warningDetailList = Form.useWatch('warningDetail', form);

  const onClickAddNew = () => {
    form.resetFields(['detailItem']);
    setMode('add');
  };

  const onClickCancel = () => {
    setMode('view');
  };

  const onClickAdd = () => {
    form
      .validateFields(['detailItem'])
      .then((value) => {
        form.setFieldsValue({
          warningDetail: [
            ...(warningDetailList || []),
            { warningDetail: value?.detailItem },
          ],
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disabled = mode?.includes('edit');

  const warningEnums = useGetEnumsByEnumName('WarningTypeCodeEnum');

  const warningSuggestions = warningEnums?.map((item) => {
    return {
      label: item?.enumDisplayName,
      value: item?.enumDisplayName,
    };
  });

  return (
    <>
      <Divider />
      <Row gutter={4}>
        <Col span={24}>
          <Form.Item
            name='warningText'
            label='Warning Text'
            rules={[
              {
                required: true,
                message: 'Warning Text is required',
              },
            ]}
          >
            <AutoComplete
              options={warningSuggestions}
              filterOption={(inputValue, option) => {
                return (
                  option?.label
                    ?.toLowerCase()
                    ?.indexOf(inputValue?.toLowerCase()) > -1
                );
              }}
              getPopupContainer={(triggerNode) => {
                return triggerNode;
              }}
            >
              <Input />
            </AutoComplete>
          </Form.Item>
        </Col>
      </Row>
      <DetailList setMode={setMode} mode={mode} />

      {mode === 'add' ? (
        <>
          <Divider />
          <Row gutter={4}>
            <Col span={20}>
              <Form.Item
                name='detailItem'
                rules={[
                  {
                    required: true,
                    message: 'Detail is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <AddWarningDetailButton
                onClickOk={onClickAdd}
                onClickCancel={onClickCancel}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Button
          icon={<PlusOutlined />}
          style={{
            width: '100%',
            borderColor: !disabled && '#0070cc',
            color: !disabled && '#0070cc',
            height: 35,
            marginTop: 8,
            marginBottom: 8,
          }}
          disabled={disabled}
          onClick={onClickAddNew}
        >
          Add New Detail
        </Button>
      )}
      <Row>
        <Col span={12}>
          <Form.Item name='boldText' valuePropName='checked'>
            <Checkbox>Bold Text</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='boldDetail' valuePropName='checked'>
            <Checkbox>Bold Detail</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const DetailList = ({ setMode, mode }) => {
  const form = Form.useFormInstance();
  const warningDetailList = Form.useWatch('warningDetail', form);

  const onClickUpdate = (index) => {
    const cloneDetailList = _.cloneDeep(warningDetailList);

    form
      .validateFields(['detailItem'])
      .then((value) => {
        cloneDetailList[index] = { warningDetail: value?.detailItem };

        form.setFieldsValue({
          warningDetail: cloneDetailList,
        });
        setMode('view');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickDelete = (index) => {
    const cloneDetailList = _.cloneDeep(warningDetailList);
    cloneDetailList.splice(index, 1);

    form.setFieldsValue({
      warningDetail: cloneDetailList,
    });
  };

  const onClickEdit = (index, detailItem) => {
    setMode(`edit-${index}`);
    form.setFieldsValue({ detailItem });
  };

  const onCancel = () => {
    setMode('view');
  };
  return (
    <>
      <Form.Item name='warningDetail' hidden />
      <Typography.Text strong>Detail:</Typography.Text>
      {warningDetailList?.map(({ warningDetail }, index) => {
       const isEdit = mode === `edit-${index}`;

        return (
          <div key={index}>
            {isEdit ? (
              <>
                <Divider />
                <Row gutter={4}>
                  <Col span={20}>
                    <Form.Item
                      name='detailItem'
                      rules={[
                        {
                          required: true,
                          message: 'Detail Text is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <AddWarningDetailButton
                      onClickOk={() => onClickUpdate(index)}
                      onClickCancel={onCancel}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <SectionWrapper
                title={
                  <Space>
                    <EditOutlined
                      onClick={() => onClickEdit(index, warningDetail)}
                    />
                    <DeleteOutlined onClick={() => onClickDelete(index)} />
                  </Space>
                }
                placement='right'
                style={{ width: '100%', maxWidth: '100%' }}
              >
                <Row>
                  <Col span={24}>
                    <Typography.Text ellipsis={{ tooltip: warningDetail }}>
                      ■ {warningDetail}
                    </Typography.Text>
                  </Col>
                </Row>
              </SectionWrapper>
            )}
          </div>
        );
      })}
    </>
  );
};
