import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import VersionAsset from '../../controls/asset/VersionAsset';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';

import AssetRibbonSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';

const VersionsSection = (props) => {
  const isCreating = useSelector(AssetRibbonSelectors.selectAssetCreating());
  const sectionDisabled =
    isCreating || window.location.pathname === '/asset/create';

  return <VersionAsset sectionDisabled={sectionDisabled} />;
};

VersionsSection.propTypes = {};

VersionsSection.defaultProps = {};

export default VersionsSection;
