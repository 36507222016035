import React from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';

const { Text } = Typography;

const ProfileAddress = (props) => {
  const { address, className, style } = props;
  const clp = 'profile-address';
  const isAddressAvailabel =
    address &&
    typeof address === 'object' &&
    Object.values(address).filter((item) => item != null && item !== undefined)
      .length > 0;

  const renderAddresses = (address) => {
    return address ? (
      <Text strong className={`${clp}__item`} style={style}>
        {address}
      </Text>
    ) : null;
  };

  const addressData = {
    primaryAddressCity: address?.primaryAddressCity,
    primaryAddressMainAddress: address?.primaryAddressMainAddress,
    address2: address?.address2,
    address3: address?.address3,
    primaryAddressState: address?.primaryAddressState,
    primaryAddressZipcode: address?.primaryAddressZipcode,
    primaryAddressCountry: address?.primaryAddressCountry,
    address1: address?.address1,
    city: address?.city,
    country: address?.country,
    state: address?.state,
    zipCode: address?.zipCode,
  };

  const mapAddressToAddress4 = () => {
    if (address) {
      const stateAddress =
        addressData?.primaryAddressState || addressData?.state
          ? ', ' + (addressData?.primaryAddressState || addressData?.state)
          : '';
      const countryAddress =
        (addressData?.country || addressData?.primaryAddressCountry) &&
        (addressData?.country || addressData?.primaryAddressCountry) !== 'USA'
          ? ', ' + (addressData?.country || addressData?.primaryAddressCountry)
          : '';
      return `${addressData?.primaryAddressCity || addressData?.city || ''}${
        stateAddress || ''
      }${countryAddress || ''}`;
    }
  };

  return (
    <>
      {isAddressAvailabel && (
        <Space
          className={`${className} ${clp}__container`}
          direction='vertical'
          size={0}
          style={{ zIndex: 100 }}
        >
          {renderAddresses(
            addressData.primaryAddressMainAddress || addressData.address1
          )}
          {renderAddresses(addressData.address2)}
          {renderAddresses(addressData.address3)}
          {renderAddresses(mapAddressToAddress4())}
          {renderAddresses(
            addressData.primaryAddressZipcode || addressData.zipCode
          )}
        </Space>
      )}
    </>
  );
};

ProfileAddress.propTypes = {
  className: PropTypes.string,
  address: PropTypes.object,
  style: PropTypes.object,
};

export default ProfileAddress;
