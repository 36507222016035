// eslint-disable-next-line
import React from 'react';
import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  take,
  delay,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as types from './constants';
import { message } from 'antd';
import { CustomNotification, GlnTooltip } from 'common/components';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import * as ribbonActions from 'redux/ribbon/actions';
import { clearToken, getToken } from 'utils/common/session';
import { DEFAULT_OPTIONS } from 'static/Constants';
import * as chatActions from 'common/components/message/controller/actions';
import * as emailActions from 'common/components/mail/controller/actions';
import * as globalActions from './actions';
import * as globalSelectors from './selectors';
import { forwardTo } from 'utils/common/route';
import * as _ from 'lodash';
import * as servicesGrid from 'services/grid';
import * as servicesHelp from 'services/help';
import * as servicesTodoList from 'services/todoList';
import * as servicesMeetingList from 'services/meetingNotification';
import * as servicesMembers from 'services/members';
import * as servicesChat from 'services/chatServices';
import * as videoCallServices from 'services/videoCallServices';
import * as serviceProduct from 'services/product';

import { getAnonToken } from 'utils/common/session';
import { checkUserToken } from 'utils/author';

export function* eulaSaga() {}
export function* personalizationSaga() {}
export function* criticalMessageSaga() {}
export function* landingPageSaga() {}

export function* logoutSaga() {
  // remove session data in local storage
  yield call(clearToken());
}

export function* changeRibbonActions(payload) {
  try {
    // change ribbon action button to payload.option value
    // if option is empty, take home as default
    yield put(
      ribbonActions.change(payload.option?.value || DEFAULT_OPTIONS.HOME.value)
    );
  } catch (error) {
    let message = error;
    if (typeof error === 'object') message = error.message;
    console.log(message);
  }
}

export function* updateProgressBarSaga(action) {
  const upDownProgressData = yield select(
    globalSelectors.selectUploadDownloadProgress()
  );
  const itemData = action.payload;
  const findProgressItemIndex = _.findIndex(upDownProgressData, {
    uid: _.get(itemData, 'uid'),
  });

  if (findProgressItemIndex < 0) {
    yield put(
      globalActions.updateProgressBarSuccess([...upDownProgressData, itemData])
    );
  } else {
    let updatedProgressData = [...upDownProgressData];
    updatedProgressData[findProgressItemIndex] = itemData;
    yield put(globalActions.updateProgressBarSuccess(updatedProgressData));
  }
}

export function* removeProgressBarItemSaga(action) {
  const upDownProgressData = yield select(
    globalSelectors.selectUploadDownloadProgress()
  );
  const itemUid = action.payload;
  if (!itemUid) {
    yield put(globalActions.updateProgressBarSuccess([]));
  } else {
    const filteredProgressList = _.filter(
      upDownProgressData,
      (progressItem) => _.get(progressItem, 'uid') !== itemUid
    );
    yield put(globalActions.updateProgressBarSuccess(filteredProgressList));
  }
}

export function* updateOpenItemSaga(action) {
  const openItemData = yield select(globalSelectors.selectOpenItem());
  const itemData = action.payload;
  const findOpenItemIndex = _.findIndex(openItemData, {
    uid: _.get(itemData, 'uid'),
  });

  if (findOpenItemIndex < 0) {
    if (openItemData.filter((item) => item.activated === false).length < 10) {
      const data = [...openItemData, itemData];
      yield put(globalActions.updateOpenItemSuccess(data));
    }
    if (openItemData.filter((item) => item.activated === false).length === 10) {
      let openItemDataInactive = [
        ...openItemData.filter((item) => item.activated === false),
      ];
      let openItemDataActive = [
        ...openItemData.filter((item) => item.activated === true),
      ];
      openItemDataInactive.shift();
      openItemDataInactive = [...openItemDataInactive, itemData];
      const data = _.concat(openItemDataActive, openItemDataInactive);
      yield put(globalActions.updateOpenItemSuccess(data));
    }
  } else {
    let updatedOpenItem = [...openItemData];
    updatedOpenItem[findOpenItemIndex] = {
      ...openItemData[findOpenItemIndex],
      ...itemData,
    };
    yield put(globalActions.updateOpenItemSuccess(updatedOpenItem));
  }
}

const renderGlns = (params) => {
  return <GlnTooltip glns={params?.value} />;
};

export function* memberForSharingColumnInfo(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);

    let columns = [
      { field: '', checkboxSelection: true, filter: false, suppressMenu: true },
    ];

    if (response && response.columns && response.columns.length > 0) {
      response.columns.forEach((val, index) => {
        if (val.fieldName !== 'Id') {
          if (val.fieldName === 'MemberName') {
            Object.assign(val, { flex: 1 });
          }
          if (val.fieldName === 'Glns') {
            Object.assign(val, {
              flex: 1,
              allowFilter: false,
              allowSort: false,
              suppressMenu: true,
              cellRenderer: renderGlns,
            });
          }
          columns.push(val);
        }
      });
    }
    yield put(globalActions.memberForSharingColumnInfoSuccess(columns));
  } catch (error) {
    yield put(globalActions.memberForSharingColumnInfoError(error));
  }
}
export function* userForSharingColumnInfo(payload) {
  try {
    const response = yield call(servicesGrid.gridColumnInfo, payload.gridName);

    let columns = [
      { field: '', checkboxSelection: true, filter: false, suppressMenu: true },
    ];

    if (response && response.columns && response.columns.length > 0) {
      response.columns.forEach((val, index) => {
        if (val.fieldName !== 'Id' && val.fieldName !== 'MemberId') {
          Object.assign(val, { flex: 1 });
          columns.push(val);
        }
      });
    }
    yield put(globalActions.userForSharingColumnInfoSuccess(columns));
  } catch (error) {
    yield put(globalActions.userForSharingColumnInfoError(error));
  }
}
export function* updateOpenItemFormSaga(action) {
  const openItemData = yield select(globalSelectors.selectOpenItem());
  const isAddingFormData = yield select(
    globalSelectors.selectIsAddingOpenItemFormData()
  );
  const isAdding = yield select(globalSelectors.selectIsAddingOpenItem());
  if (isAddingFormData && isAdding) {
    let cloneOpenItemData = _.cloneDeep(openItemData);
    cloneOpenItemData[cloneOpenItemData.length - 1].formData = action.payload;
    yield put(globalActions.updateOpenItemSuccess(cloneOpenItemData));
    yield put(globalActions.toggleIsAddingOpenItem(false));
    yield put(globalActions.toggleIsAddingOpenItemFormData(false));
    yield call(message.success, {
      content: 'Added Open Item Successfully',
      key: 'openItemFullView',
      duration: 2,
    });
    if (window.location.pathname.indexOf('/asset') >= 0) {
      yield call(forwardTo, '/digital-media');
    }
  }
}

export function* updateCurrentTabSaga(action) {
  const urlPath = window.location.pathname;
  const currentTab = yield select(globalSelectors.selectCurrentTab());
  const tabItems = currentTab?.items;
  const tabValue = action.payload?.value;
  const tabId = action.payload?.tabId;
  const searchTabIndex = _.findIndex(currentTab.items, { tabId: tabId });

  if (urlPath === currentTab?.urlPath) {
    if (searchTabIndex < 0) {
      const data = {
        ...currentTab,
        items: [...tabItems, { tabId, value: tabValue }],
      };
      yield put(globalActions.updateCurrentTabSuccess(data));
    } else {
      let updatedTabItems = [...tabItems];
      updatedTabItems[searchTabIndex] = { tabId, value: tabValue };
      const data = {
        ...currentTab,
        items: updatedTabItems,
      };
      yield put(globalActions.updateCurrentTabSuccess(data));
    }
    return;
  }
  if (urlPath !== currentTab?.urlPath) {
    const data = {
      ...currentTab,
      urlPath,
      items: [{ tabId, value: tabValue }],
    };
    yield put(globalActions.updateCurrentTabSuccess(data));
  }
}

export function* deleteActivatedOpenItemSaga(action) {
  const openItemData = yield select(globalSelectors.selectOpenItem());
  const onGoingOpenItem = action.payload;
  const removedOpenItemData = [...openItemData].filter(
    (itemData) =>
      itemData.activated === false || itemData.uid === onGoingOpenItem.uid
  );
  yield put(globalActions.deleteActivatedOpenItemSuccess(removedOpenItemData));
}

export function* searchHelpPosts(action) {
  try {
    const response = yield call(
      servicesHelp.searchHelpAutoComplete,
      action.payload
    );
    if (response.isSuccess) {
      const procHelpSearchList = response.data.suggests.map((helpPostInfo) => ({
        key: JSON.stringify({
          categoryId: helpPostInfo?.categoryId,
          postId: helpPostInfo.postId,
        }),
        value: helpPostInfo?.title,
        label: helpPostInfo?.title,
      }));
      yield put(globalActions.searchHelpPostSuccess(procHelpSearchList));
    } else {
      yield put(globalActions.searchHelpPostError([]));
    }
  } catch (error) {
    yield put(globalActions.searchHelpPostError([]));
  }
}

//* GET MEDIA ASSET SAGA
export function* getMediaFilesSaga(action) {
  const { pageNumber, pageSize, apiService, additionalParams } = action.payload;
  let paramsApi = {
    pageSize,
    pageIndex: pageNumber,
    sort: [
      {
        fieldName: 'created',
        isAscending: false,
      },
    ],
    filters: [],
    ...additionalParams,
  };

  const helpAssetFiles = yield select(globalSelectors.selectMediaAssetFiles());

  let response = yield call(apiService, paramsApi);
  if (response?.isSuccess === true) {
    let procHelpAssets = response?.data.gridData.map((helpAsset) => ({
      ...helpAsset,
      url: helpAsset?.url,
    }));
    const fetchPageIndex = response?.data?.paging?.currentPageIndex;
    const fetchPageSize = response?.data?.paging?.currentPageSize;

    yield put(
      globalActions.getMediaAssetFileSuccess({
        data: [...helpAssetFiles?.data, ...procHelpAssets],
        pageNumber,
        totalRecord: response?.data?.paging.totalRecord,
      })
    );

    yield delay(1000);
    yield put(globalActions.toggleFinishLoadMoreMediaAsset(true));
    yield delay(1000);
    yield put(globalActions.toggleFinishLoadMoreMediaAsset(false));
  }
}

export function* getAllArticles(payload) {
  try {
    const response = yield call(servicesMembers.getAllArticles);

    if (response?.data?.articles?.length > 0) {
      let showcaseArray = response.data.articles.filter(
        (val) => val.articleKey === 'TacoTruck.company.profile.showcase'
      );
      let showcase = showcaseArray?.length > 0 ? showcaseArray[0] : null;

      yield put(globalActions.getAllArticlesSuccess(showcase));
    } else {
      yield put(globalActions.getAllArticlesError());
    }
  } catch (error) {
    yield put(globalActions.getAllArticlesError(error));
  }
}

export function* getTodoList(payload) {
  try {
    const params = {
      pageIndex: 1,
      pageSize: 10,
      sort: [
        {
          fieldName: 'created',
          isAscending: false,
        },
      ],
      filters: [
        {
          fieldName: 'Status',
          filterType: 'In',
          values: ['Waiting', 'Accepted'],
        },
      ],
    };

    const response = yield call(servicesTodoList.getTodoList, params);
    if (response.isSuccess) {
      yield put(
        globalActions.getTodoListSuccess(
          response?.data?.gridData,
          response?.data?.paging?.totalRecord
        )
      );
    } else {
      yield put(globalActions.getTodoListError());
    }
  } catch (error) {
    yield put(globalActions.getTodoListError(error));
  }
}

export function* getMeetingList(payload) {
  try {
    const params = {
      pageIndex: 1,
      pageSize: 9999,
      filters: [
        {
          fieldName: 'meetingStatus',
          filterType: 'NotEqual',
          value: 'Closed',
        },
      ],
    };

    const response = yield call(
      servicesMeetingList.getMeetingNotification,
      params
    );
    if (response.isSuccess) {
      const total = response?.data?.gridData.filter(
        (item) => item.meetingUserStatus === 'Pending'
      ).length;
      yield put(
        globalActions.getMeetingNotificationSuccess(
          response?.data?.gridData,
          total
        )
      );
    } else {
      yield put(globalActions.getMeetingNotificationError());
    }
  } catch (error) {
    yield put(globalActions.getMeetingNotificationError(error));
  }
}

export function* getAwaitingList() {
  try {
    const response = yield call(servicesTodoList.getAwaitingList);
    if (response.isSuccess) {
      yield put(globalActions.getAwaitingListSuccess(response?.data));
    } else {
      yield put(globalActions.getAwaitingListError());
    }
  } catch (error) {
    yield put(globalActions.getAwaitingListError(error));
  }
}

export function* getMemberInfoSheet(payload) {
  try {
    const response = yield call(servicesMembers.getMemberInfoSheet, payload);
    if (response?.isSuccess) {
      yield put(globalActions.getMemberInfoSheetSuccess(response.data));
    } else {
      yield put(globalActions.getMemberInfoSheetError(response.message));
    }
  } catch (error) {
    yield put(globalActions.getMemberInfoSheetError(error));
  }
}

export function* toggleChatViewSaga(action) {
  const isChatViewOpen = yield select(globalSelectors.selectIsChatViewOpen());
  const statusValue = action.status;

  if (statusValue === undefined) {
    yield put(globalActions.toggleChatViewSuccess(!isChatViewOpen));
    return;
  }
  yield put(globalActions.toggleChatViewSuccess(statusValue));
}

const initConnection = async (connection) => {
  const token = getToken();
  const anonToken = getAnonToken();

  const signalrToken = anonToken || token;

  const signalrType = anonToken ? 'ExternalMeetingAuth' : 'auth';

  await connection
    .start()
    .then((res) => {
      connection
        .invoke(signalrType, signalrToken)
        .then((result) => console.log('Init connection successfully!'))
        .catch((err) => console.log('err: ', err));

      connection.onclose((error) => {
        console.log(error);
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

function createSocketChannel(socket) {
  return eventChannel((emit) => {
    const messageReceived = (messageInfo) => {
      const threadId = messageInfo?.notificationThreadId;
      const senderId = messageInfo?.notificationSenderId;
      const messageId = messageInfo?.notificationMessageId;
      const messageType = messageInfo?.notificationMessageType;
      const messageText = messageInfo?.notificationMessageText;
      const sendTime = messageInfo?.notificationSendTime;
      const attachmentIds = messageInfo?.attachmentIds;

      emit({
        threadId,
        senderId,
        messageId,
        messageType,
        messageText,
        sendTime,
        attachmentIds,
      });
    };

    socket.on('ReceiveMessage', messageReceived);

    const unsubscribe = () => {
      socket.off('ReceiveMessage', messageReceived);
    };

    return unsubscribe;
  });
}

export function* setSignalRConnection(action) {
  try {
    const connection = new HubConnectionBuilder()
      .withUrl(action.url.toUrl())
      .withAutomaticReconnect()
      .build();

    if (connection) {
      yield call(initConnection, connection);
      yield put(globalActions.setSignalRConnectionSuccess(connection));
      const socketChannel = yield call(createSocketChannel, connection);

      while (true) {
        try {
          const messages = yield take(socketChannel);
          // 0: text, 1: email, 2: file, 3: image, 4: rating
          if (messages?.messageType === 1) {
            yield put(globalActions.setNewEmailInfo(messages));
            yield put(emailActions.updateLastMessage(messages.messageId));
          } else {
            yield put(chatActions.updateLastMessage(messages.messageId));
            yield put(globalActions.setNewMsgInfo(messages));
          }
        } catch (err) {
          console.error('socket error:', err);
        }
      }
    }
  } catch (error) {
    yield put(globalActions.setSignalRConnectionFail(error));
  }
}

export function* toggleVideoCallSaga(action) {
  const statusValue = action.payload.status;
  const requestParam = statusValue ? action.payload?.params : null;
  const currentCall =
    statusValue && !requestParam ? action.payload?.currentCall : null;

  // * close video call
  if (statusValue === false) {
    yield put(
      globalActions.toggleVideoCallSuccess({
        status: false,
        currentCall: null,
      })
    );
    return;
  }

  // * open video call for caller
  if (requestParam) {
    const response = yield call(
      videoCallServices.startVideoMeeting,
      requestParam
    );

    if (response.isSuccess) {
      yield put(
        globalActions.toggleVideoCallSuccess({
          status: true,
          currentCall: response?.data,
          threadItem: action.payload?.threadItem,
        })
      );
    } else {
      if (typeof response?.message === 'string') {
        yield call(CustomNotification.info, response.message);
      }
    }
    return;
  }

  // * open video call for receiver
  if (currentCall) {
    yield put(
      globalActions.toggleVideoCallSuccess({
        status: true,
        currentCall,
        threadItem: action.payload?.threadItem,
        isMeeting: action.payload?.isMeeting,
      })
    );
    return;
  }
}

// ! New
export function* getEmailAndMessageNotify(payload) {
  try {
    const isUserLoggedIn = yield call(checkUserToken);
    if (!isUserLoggedIn) return;

    const response = yield call(servicesChat.getEmailAndMessageNotify, payload);

    const {
      data: { totalEmailUnread, totalChatUnread },
      message,
    } = response;

    if (response) {
      yield put(globalActions.getMessageNotifySuccess(totalChatUnread));
      yield put(globalActions.getEmailNotifySuccess(totalEmailUnread));
    } else {
      yield put(globalActions.getEmailAndMessageNotifyFail(message));
    }

    if (true) {
      yield delay(60000);
      yield call(getEmailAndMessageNotify, { payload });
    }
  } catch (error) {
    yield put(globalActions.getEmailAndMessageNotifyFail(error));
  }
}

export function* makeAThreadAsRead(payload) {
  try {
    const response = yield call(servicesChat.makeAThreadAsRead, payload.params);

    if (response?.isSuccess) {
      yield put(globalActions.makeAThreadAsReadSuccess(response));

      // Call get notify api to update notify count
      yield delay(1000);
      yield call(getEmailAndMessageNotify);
    } else {
      yield put(globalActions.makeAThreadAsReadError(response.message));
    }
  } catch (error) {
    yield put(globalActions.makeAThreadAsReadError(error));
  }
}

export default function* watchGlobal() {
  yield takeLatest(types.VALIDATE_EULA, eulaSaga);
  yield takeLatest(types.VALIDATE_PERSONALIZATION, personalizationSaga);
  yield takeLatest(types.VALIDATE_CRITICAL_MESSAGE, criticalMessageSaga);
  yield takeLatest(types.VALIDATE_LANDING_PAGE, landingPageSaga);
  yield takeLatest(types.CHANGE_RIBBON_ACTIONS, changeRibbonActions);
  yield takeLatest(types.UPDATE_PROGRESS_BAR, updateProgressBarSaga);
  yield takeLatest(types.REMOVE_PROGRESS_BAR_ITEM, removeProgressBarItemSaga);
  yield takeLatest(
    types.MEMBER_FOR_SHARING_COLUMN_INFO,
    memberForSharingColumnInfo
  );
  yield takeLatest(
    types.USER_FOR_SHARING_COLUMN_INFO,
    userForSharingColumnInfo
  );
  yield takeLatest(types.UPDATE_OPEN_ITEM, updateOpenItemSaga);
  yield takeLatest(types.UPDATE_OPEN_ITEM_FORM, updateOpenItemFormSaga);
  yield takeEvery(types.UPDATE_CURRENT_TAB, updateCurrentTabSaga);
  yield takeLatest(
    types.DELETE_ACTIVATED_OPEN_ITEM,
    deleteActivatedOpenItemSaga
  );
  yield takeLatest(types.SEARCH_HELP_POST, searchHelpPosts);
  yield takeLatest(types.GET_MEDIA_ASSETS, getMediaFilesSaga);
  yield takeLatest(types.GET_ALL_ARTICLES, getAllArticles);
  yield takeLatest(types.GET_MEMBER_INFO_SHEET, getMemberInfoSheet);
  yield takeLatest(types.GET_TODO_LIST, getTodoList);
  yield takeLatest(types.GET_MEETING_NOTIFICATION, getMeetingList);
  yield takeLatest(types.GET_AWAITING_LIST, getAwaitingList);
  yield takeLatest(types.TOGGLE_CHAT_VIEW, toggleChatViewSaga);
  yield takeLatest(types.SET_SIGNALR_CONNECTION, setSignalRConnection);
  yield takeLatest(types.TOGGLE_VIDEO_CALL, toggleVideoCallSaga);
  yield takeLatest(
    types.GET_EMAIL_AND_MESSAGE_NOTIFY,
    getEmailAndMessageNotify
  );
  yield takeLatest(types.MAKE_A_THREAD_AS_READ, makeAThreadAsRead);
}
