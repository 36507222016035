import React from 'react';

import { Col, Skeleton, Empty, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ThumbnailItem from 'common/components/thumb/ThumbnailItem';
import { AssetThumbnail } from 'pages/branded-assets/components';
import * as getLink from 'utils/common/linkBuilder';
import { Images } from 'config/assets';

import classnames from 'classnames';

import './SearchGrid.less';

const SHADOW_STYLE = '#a8d2f4 0px 0px 0px 3px';

const aminationSearchHint = [
  'ani--flipIn',
  'ani--fill-forward',
  'ani--iter-1',
  'ani--time-90',
  'ani--easy',
];

const { Text } = Typography;

const SearchGrid = (props) => {
  const {
    items,
    handleSelectItem,
    selectedItems,
    isLoading,
    searchType,
    itemIdName,
    makeGridItemStyle,
    makeGridItemDisabled,
    makeGridItemConfig,
    showSearchHint,
  } = props;

  const itemStyle = (id, item) => {
    let gridItemStyle = {
      ...(makeGridItemConfig ? makeGridItemConfig(item)?.style : {}),
      ...(makeGridItemStyle ? makeGridItemStyle(item) : {}),
    };

    if (!selectedItems?.length || selectedItems === 'init') {
      return gridItemStyle;
    } else {
      const foundItemIndex = selectedItems.findIndex((itemDetail) => {
        const theItemId = itemIdName
          ? itemDetail?.[itemIdName]
          : itemDetail?.id;

        return theItemId === id;
      });

      if (foundItemIndex !== -1) {
        return { ...gridItemStyle, boxShadow: SHADOW_STYLE };
      } else {
        return gridItemStyle;
      }
    }
  };

  const itemDisabled = (item) => {
    return makeGridItemDisabled && makeGridItemDisabled(item);
  };

  const handleClickGridItem = (item) => {
    const isDisabled =
      itemDisabled(item) || makeGridItemConfig?.(item)?.disableClick;
    if (isDisabled) return;
    handleSelectItem(item);
  };

  return (
    <Col className={classnames('scroller', 'search-grid')}>
      <Skeleton active loading={isLoading} style={{ alignSelf: 'flex-start' }}>
        {showSearchHint ? (
          <div
            className={classnames(aminationSearchHint)}
            style={{ margin: 'auto' }}
          >
            <SearchOutlined />
            <Text type='secondary'>
              {searchType === 'member'
                ? 'PLEASE INPUT SEARCH TEXT TO FIND A DESTINATION MEMBER'
                : searchType === 'supplier'
                ? 'PLEASE INPUT SEARCH TEXT TO FIND A DESTINATION SUPPLIER'
                : 'PLEASE INPUT SEARCH TEXT TO SEARCH FOR ITEM'}
            </Text>
          </div>
        ) : items?.length === 0 ? (
          <Empty style={{ margin: 'auto' }} />
        ) : (
          <div className='search-grid__item-list'>
            {items &&
              items.length > 0 &&
              items.map((item, index) => {
                item.thumbnail = item.thumb;
                //todo - Duy - 24/12/2021 BE team does not return isOwner property
                item.isOwner = true;

                return (
                  <div
                    className='search-grid__item'
                    onClick={() => handleClickGridItem(item)}
                    key={item?.[itemIdName] || item?.id}
                  >
                    {searchType === 'asset' ? (
                      <AssetThumbnail
                        imageThumbnail={item?.thumb || item?.thumbnail}
                        dataDetail={item}
                        isShareBtn
                        style={itemStyle(item?.[itemIdName] || item?.id, item)}
                        pathname='/asset'
                      />
                    ) : searchType === 'member' || searchType === 'supplier' ? (
                      <ThumbnailItem
                        dataDetail={item}
                        labelThumbnail={getLink.memberLink(
                          item?.id || item?.companyId,
                          item?.companyName || item?.memberName,
                          '_blank'
                        )}
                        imageThumbnail={
                          item?.thumb300 ||
                          item?.thumb ||
                          item?.logo ||
                          Images.RIVIR_LOGO_DEFAULT
                        }
                        hideCheckbox
                        isMemberThumbnail
                        isShareBtn={false}
                        style={itemStyle(
                          item?.[itemIdName] || item?.id || item?.companyId,
                          item
                        )}
                      />
                    ) : searchType === 'brand' ? (
                      <ThumbnailItem
                        imageThumbnail={item?.thumb100 || item?.thumbnail}
                        dataDetail={item}
                        labelThumbnail={item.brandName}
                        style={itemStyle(item?.[itemIdName] || item?.id, item)}
                      />
                    ) : (
                      <ThumbnailItem
                        imageThumbnail={item?.thumb || item?.thumbnail}
                        dataDetail={item}
                        isProductThumbnail={searchType === 'product'}
                        isShareBtn
                        style={itemStyle(item?.[itemIdName] || item?.id, item)}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </Skeleton>
    </Col>
  );
};

export default SearchGrid;
