import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';
import product from 'i18n/messages/product';

import { getData, setData } from 'utils/storage';
import {
  RECENT_SEARCH_KEY,
  RECENT_SEARCH_STORE_DEFAULT,
  ROUTE,
} from 'static/Constants';

const pathnameProduct = '/products';

const pathnameMember = '/members';
const pathnameFavMember = '/favorite/favorite-members';

const pathnameDigitalMedia = '/digital-media';
const pathnameDigitalMediaNew = '/digital-media/new';
const pathnameAssets = '/digital-media/assets';
const pathnameFavAssets = '/favorite/favorite-assets';
const pathnameFavProducts = '/favorite/favorite-products';
const pathnameDocument = '/digital-media/documents';
const pathnameMultiMedia = '/digital-media/multi-media';

const pathnameReportings = '/reportings';
const pathnameReportingsOwned = '/reportings/owned';
const pathnameReportingsShared = '/reportings/shared';
const pathnameFavoriteQuires = '/favorite/queries';

const pathnameMemberMaintenance = '/maintenance/member-management';

const pathnameAssetForMember = '/member-assets';

const pathnameFolders = '/folders';
const pathnameFoldersOwned = '/folders/owned';
const pathnameFoldersShared = '/folders/shared';
const pathnameFoldersFav = '/favorite/favorite-folders';

const pathnameWfMaintenanceDefinition = '/workflow-maintenance/definition';

const pathnameMemberMaintenanceUser = '/maintenance/member-management/user';
const pathnameUserMaintenance = '/maintenance/user-management';
const pathnameProductForMember = '/member-products';
const pathnameSsoApprovalMember = '/sso-approval/member';

const pathnameTransitionPage = '/maintenance/transition-page';
const pathnameMaintenanceForm = '/maintenance/form';

const pathEulaCreditCard = '/maintenance/eula/credit-card';
const pathDataSyncTimeTracking = '/maintenance/data-sync-time-tracking';
const pathQcTimeTracking = '/maintenance/qc-time-tracking';
const pathTicketingSystem = '/ticketing-system';
const pathQaSpecWorkflow = '/qa-spec-workflows';

const addRecentSearchLocalStore = (pathname, value) => {
  //? empty string  => no store
  if (value === '') return;

  let allRecentSearch = {};

  allRecentSearch = getData(RECENT_SEARCH_KEY);

  /**
   * //*!warning -  if user delete all search recent from local storage before search text
   * //*? solution - set allRecentSearch default value to storage
   */
  if (!allRecentSearch) {
    setData(RECENT_SEARCH_STORE_DEFAULT, RECENT_SEARCH_KEY);
    allRecentSearch = RECENT_SEARCH_STORE_DEFAULT;
  }

  const setStore = (fieldKey) => {
    let nextStore = { ...allRecentSearch };

    const fieldValue = nextStore[fieldKey];
    let nextFieldValue = [...fieldValue];

    const MAX_SAVED_SEARCH_kEY = 8;

    if (nextFieldValue?.length < MAX_SAVED_SEARCH_kEY) {
      nextFieldValue = [value, ...nextFieldValue];
    } else {
      nextFieldValue.pop();
      nextFieldValue = [value, ...nextFieldValue];
    }

    nextStore[fieldKey] = nextFieldValue;

    setData(nextStore, RECENT_SEARCH_KEY);
  };

  switch (pathname) {
    case ROUTE.PRODUCTS:
      return setStore('products');
    case ROUTE.DIGITAL_MEDIA:
      return setStore('digitalAssets');
    case ROUTE.MEMBER:
      return setStore('members');
    default:
      return;
  }
};

const checkSearchAssetForMember = (pathname) =>
  pathname.includes(pathnameAssetForMember);

const checkSearchMemberMaintenanceUser = (pathname) => {
  const regex = /^\/maintenance\/member-management\/\d+$/;

  return regex.test(pathname);
};

const checkSearchProductForMember = (pathname) => {
  const regex = /^\/products\/member\/\d+$/;

  return regex.test(pathname);
};

const updateSearchText = (
  dispatch,
  value,
  pathname,
  isSaveRecentSearch = true
) => {
  const switchPathname = pathname
    ? checkSearchMemberMaintenanceUser(pathname)
      ? pathnameMemberMaintenanceUser
      : checkSearchProductForMember(pathname)
      ? pathnameProductForMember
      : pathname.toLowerCase()
    : '';

  switch (switchPathname) {
    case pathnameProduct:
      dispatch(globalActions.updateSearchTextProduct(value));
      isSaveRecentSearch && addRecentSearchLocalStore(pathname, value);
      break;
    case pathnameMember:
      dispatch(globalActions.updateSearchTextMember(value));
      isSaveRecentSearch && addRecentSearchLocalStore(pathname, value);
      break;

    case pathnameFavMember:
      dispatch(globalActions.updateSearchFavMember(value));
      break;

    case pathnameDigitalMedia:
      dispatch(globalActions.updateSearchTextDigitalMedia(value));
      isSaveRecentSearch && addRecentSearchLocalStore(pathname, value);
      break;

    case pathnameDigitalMediaNew:
      dispatch(globalActions.updateSearchTextDigitalMediaNew(value));
      break;

    case pathnameFavAssets:
      dispatch(globalActions.updateSearchFavAsset(value));
      break;

    case pathnameFavProducts:
      dispatch(globalActions.updateSearchFavProduct(value));
      break;

    case pathnameAssets:
      dispatch(globalActions.updateSearchTextAsset(value));
      break;

    case pathnameDocument:
      dispatch(globalActions.updateSearchTextDocuments(value));
      break;

    case pathnameMultiMedia:
      dispatch(globalActions.updateSearchTextMultiMedia(value));
      break;

    case pathnameReportings:
      dispatch(globalActions.updateSearchTextReportings(value));
      break;

    case pathnameReportingsOwned:
      dispatch(globalActions.updateSearchTextReportingsOwned(value));
      break;

    case pathnameReportingsShared:
      dispatch(globalActions.updateSearchTextReportingsShared(value));
      break;

    case pathnameFavoriteQuires:
      dispatch(globalActions.updateSearchTextFavoriteQueries(value));
      break;

    case pathnameMemberMaintenance:
      dispatch(globalActions.updateSearchTextMaintenance(value));
      break;

    case pathnameMemberMaintenanceUser:
      dispatch(globalActions.updateSearchTextMemberMaintenanceUser(value));
      break;

    case pathnameUserMaintenance:
      dispatch(globalActions.updateSearchTextUserMemberMaintenance(value));
      break;

    case pathnameAssetForMember:
      dispatch(globalActions.updateAssetForMemberSearch(value));
      break;

    case pathnameFolders:
      dispatch(globalActions.updateSearchTextFolder(value));
      break;

    case pathnameFoldersOwned:
      dispatch(globalActions.updateSearchTextFolderOwned(value));
      break;

    case pathnameFoldersShared:
      dispatch(globalActions.updateSearchTextFolderShared(value));
      break;

    case pathnameFoldersFav:
      dispatch(globalActions.updateSearchTextFolderFav(value));
      break;

    case pathnameWfMaintenanceDefinition:
      dispatch(globalActions.updateSearchTextWfMaintenanceDefinition(value));
      break;

    case pathnameProductForMember:
      dispatch(globalActions.updateSearchTextProductForMember(value));
      isSaveRecentSearch && addRecentSearchLocalStore(pathname, value);
      break;

    case pathnameTransitionPage:
      dispatch(globalActions.updateSearchTextTransitionPage(value));
      break;
    case pathnameSsoApprovalMember:
      dispatch(globalActions.updateSearchTextSsoApprovalMember(value));
      break;
    case pathnameMaintenanceForm:
      dispatch(globalActions.updateSearchTextMaintenanceForm(value));
      break;
    case pathEulaCreditCard:
      dispatch(globalActions.updateSearchTextEulaCard(value));
      break;
    case pathDataSyncTimeTracking:
      dispatch(globalActions.updateSearchTextDataSyncTimeTracking(value));
      break;
    case pathQcTimeTracking:
      dispatch(globalActions.updateSearchTextQcTimeTracking(value));
      break;
    case pathTicketingSystem:
      dispatch(globalActions.updateSearchTextTicketingSystem(value));
    case pathQaSpecWorkflow:
      dispatch(globalActions.updateSearchTextQaSpecWorkflow(value));
      break;
    default:
      dispatch(globalActions.searchAllItems(value));
      break;
  }
};

const useDefaultSearchText = () => {
  const {
    memberText,
    digitalMediaText,
    digitalMediaNewText,
    assetText,
    documentText,
    multiMediaText,
    reportingsText,
    reportingsOwnedText,
    reportingsSharedText,
    favoriteQueriesText,
    memberMaintenanceText,
    memberMaintenanceUserText,
    userMaintenanceText,
    favMemberText,
    favAssetText,
    favProductText,
    assetForMemberText,
    folderText,
    folderOwnedText,
    folderSharedText,
    favFolderText,
    wfDefinitionText,
    productText,
    productForMemberText,
    transitionPageText,
    ssoApprovalMemberText,
    maintenanceFormText,
    eulaCardText,
    dataSyncTimeText,
    qcTimeText,
    ticketingSystemText,
    qaSpecWorkflowText,
  } = useSelector(globalSelectors.selectSelectSearchText());
  const searchAllItems = useSelector(globalSelectors.selectSearchAllItems());

  const getDefaultSearchText = React.useCallback(
    (pathname) => {
      const switchPathname = checkSearchMemberMaintenanceUser(pathname)
        ? pathnameMemberMaintenanceUser
        : checkSearchProductForMember(pathname)
        ? pathnameProductForMember
        : pathname;

      switch (switchPathname) {
        case pathnameProduct:
          return productText;
        case pathnameMember:
          return memberText;

        case pathnameFavMember:
          return favMemberText;

        case pathnameFavAssets:
          return favAssetText;

        case pathnameFavProducts:
          return favProductText;

        case pathnameDigitalMedia:
          return digitalMediaText;

        case pathnameDigitalMediaNew:
          return digitalMediaNewText;

        case pathnameAssets:
          return assetText;

        case pathnameDocument:
          return documentText;

        case pathnameMultiMedia:
          return multiMediaText;

        case pathnameReportings:
          return reportingsText;

        case pathnameReportingsOwned:
          return reportingsOwnedText;

        case pathnameReportingsShared:
          return reportingsSharedText;

        case pathnameFavoriteQuires:
          return favoriteQueriesText;

        case pathnameMemberMaintenance:
          return memberMaintenanceText;

        case pathnameMemberMaintenanceUser:
          return memberMaintenanceUserText;

        case pathnameUserMaintenance:
          return userMaintenanceText;

        case pathnameAssetForMember:
          return assetForMemberText;

        case pathnameFolders:
          return folderText;

        case pathnameFoldersOwned:
          return folderOwnedText;

        case pathnameFoldersShared:
          return folderSharedText;

        case pathnameFoldersFav:
          return favFolderText;

        case pathnameWfMaintenanceDefinition:
          return wfDefinitionText;

        case pathnameProductForMember:
          return productForMemberText;

        case pathnameTransitionPage:
          return transitionPageText;

        case pathnameSsoApprovalMember:
          return ssoApprovalMemberText;
        case pathnameMaintenanceForm:
          return maintenanceFormText;
        case pathEulaCreditCard:
          return eulaCardText;
        case pathDataSyncTimeTracking:
          return dataSyncTimeText;
        case pathQcTimeTracking:
          return qcTimeText;
        case pathTicketingSystem:
          return ticketingSystemText;
        case pathQaSpecWorkflow:
          return qaSpecWorkflowText;
        default:
          return searchAllItems;
      }
    },
    [
      productText,
      memberText,
      favMemberText,
      favAssetText,
      favProductText,
      digitalMediaText,
      digitalMediaNewText,
      assetText,
      documentText,
      multiMediaText,
      reportingsText,
      reportingsOwnedText,
      reportingsSharedText,
      favoriteQueriesText,
      memberMaintenanceText,
      memberMaintenanceUserText,
      userMaintenanceText,
      assetForMemberText,
      folderText,
      folderOwnedText,
      folderSharedText,
      favFolderText,
      wfDefinitionText,
      productForMemberText,
      transitionPageText,
      ssoApprovalMemberText,
      maintenanceFormText,
      eulaCardText,
      dataSyncTimeText,
      qcTimeText,
      ticketingSystemText,
      searchAllItems,
      qaSpecWorkflowText,
    ]
  );

  return { getDefaultSearchText };
};

const getPreviousViewStyle = (typeView) => {
  /*
   * /member match with - /favorite/favorite-members.
   * /product/* match with -  /favorite/favorite-assets.
   * /digital-media/* match with - /favorite/favorite-assets.
   * /folders/* match with - /favorite/favorite-folders.
   * /reportings
   */

  const viewStyleList = JSON.parse(localStorage.getItem('view'));

  const pathname = window.location.pathname;

  // *Check if current route match with the effected route
  // *If not return false.

  const routeUrl = pathname.includes('products')
    ? 'products'
    : pathname.includes('assets')
    ? 'digital-media'
    : pathname.includes('digital-media')
    ? 'digital-media'
    : pathname.includes('members')
    ? 'members'
    : pathname.includes('reportings')
    ? 'reportings'
    : pathname.includes('folders')
    ? 'folders'
    : pathname.includes('queries')
    ? 'queries'
    : pathname.includes('sharedqueries')
    ? 'sharedqueries'
    : false;

  // *Get route previous view type.
  const previousViewType = _.get(viewStyleList, routeUrl);

  // *Update route view type.
  const localViewType = { ...viewStyleList, [routeUrl]: typeView };

  return { previousViewType, localViewType, routeUrl };
};

const removeLocalViewTypeOnPageRefresh = () => {
  localStorage.removeItem('view');
};

export {
  updateSearchText,
  useDefaultSearchText,
  checkSearchAssetForMember,
  checkSearchMemberMaintenanceUser,
  pathnameAssetForMember,
  pathnameMemberMaintenanceUser,
  getPreviousViewStyle,
  removeLocalViewTypeOnPageRefresh,
};
