import React, { useState, useEffect } from 'react';

const useClickEntity = (ref) => {
  const [state, setState] = useState(false);

  const handleClickOutside = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      setState(true);
    } else {
      setState(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return state;
};

export default useClickEntity;
