import { RIBBON_VIEW } from 'static/Constants';

import Home from 'pages/home/ribbon/Home';

export const ribbonViewRouter = (ribbonView) => {
  for (const ribbonKey in RIBBON_VIEW) {
    const ribbonItem = RIBBON_VIEW[ribbonKey];
    if (ribbonView === ribbonItem?.NAME) {
      return ribbonItem?.VIEW || Home;
    }
  }
  return Home;
};
