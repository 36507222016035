import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getMySubscriptionList = (params) => {
  return api.sendPost(endpoints.GET_MY_SUBSCRIPTION_DETAIL_GRID, {
    params,
  });
};
export const getSubscriptionToList = (params) => {
  return api.sendPost(endpoints.GET_SUBSCRIPTION_TO_DETAIL_GRID, {
    params,
  });
};

export const unsubscribe = (params) => {
  return api.sendPost(endpoints.UNSUBSCRIBE, params);
};

export const subscribeProductOrMember = (params) => {
  return api.sendPost(endpoints.SUBSCRIBE_PRODUCT_OR_MEMBER, params);
};

export const getSubscriptionProductOwnerDetailGrid = (params) => {
  return api.sendPost(endpoints.GET_PRODUCT_DETAIL_SUBSCRIPTIONS_GRID, params);
};
export const accpetSubscription = (params) => {
  return api.sendPost(endpoints.ACCEPT_SUBSCRIPTION, params);
};
export const rejectSubscription = (params) => {
  return api.sendPost(endpoints.REJECT_SUBSCRIPTION, params);
};
export const revokeSubscription = (params) => {
  return api.sendPost(endpoints.REVOKE_SUBSCRIPTION, params);
};

export const editSubscription = (params) => {
  return api.sendPost(endpoints.EDIT_SUBSCRIPTION, params);
};

export const sendGdsnSubscriptionToOutsideService = (params) => {
  return api.sendPost(endpoints.SEND_GDSN_SUBSCRIPTION_TO_OUTSIDE, params);
};
