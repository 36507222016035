import {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useLayoutEffect,
} from 'react';

import { scrollToFoundModuleView, uppercaseFirstLetter } from './utils';

import { useAsync } from 'hooks/useAsync';

import {
  getProductItemModules,
  getProductItemModulesByBrickCode,
} from 'services/product';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const keyGetProductItemModules = (id) => {
  return ['pim', 'get-product-item-modules', +id];
};

export const keyGetProductItemModulesBrickCode = (brickCode) => {
  return ['pim', 'get-product-item-modules-by-brick-code', brickCode];
};

export const useGetDataProductItemModules = (id, isEnabled = true) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: keyGetProductItemModules(id),
    queryFn: async () => {
      const response = await getProductItemModules({ productId: id });
      return response?.data;
    },
    enabled: isEnabled,
  });
  const refetchDataProductModules = () => {
    queryClient.invalidateQueries({ queryKey: keyGetProductItemModules(id) });
  };

  return {
    dataProductModules: query?.data,
    statusFetchProductModules: query.status,
    refetchDataProductModules,
  };
};

export const useGetProductItemModulesBrickCode = (brickCode, option = {}) => {
  const { data } = useQuery({
    queryKey: keyGetProductItemModulesBrickCode(brickCode),
    queryFn: async ({ queryKey }) => {
      const response = await getProductItemModulesByBrickCode({
        brickCode: queryKey?.[2],
      });

      return response?.data;
    },
    ...option,
  });

  return { modulesBrickCode: data?.moduleNames ?? [] };
};

export const useVisibleModal = () => {
  const [visible, setVisible] = useState(false);

  const handler = useMemo(
    () => ({
      onModal: () => setVisible(true),
      offModal: () => setVisible(false),
      toggle: () => setVisible((modal) => !modal),
    }),
    []
  );

  return [visible, handler];
};

export const useScrollToFoundModule = ({ elementByClassName }) => {
  useLayoutEffect(() => {
    if (elementByClassName?.[0]) {
      elementByClassName[0].scrollIntoView({
        block: 'center',
      });
    }
  }, [elementByClassName]);
};

export const useScrollToErrorModule = ({
  statusFetchProductModules,
  moduleNameError,
}) => {
  useLayoutEffect(() => {
    if (moduleNameError && statusFetchProductModules === 'resolved') {
      const elementByClassName = document.getElementsByClassName(
        `product-detail-view__list-info-title--${uppercaseFirstLetter(
          moduleNameError
        )}`
      );
      scrollToFoundModuleView(elementByClassName);
    }
  }, [statusFetchProductModules, moduleNameError]);
};
