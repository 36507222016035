import React, { useState } from 'react';

import QaSpecCharacteristicGrid from './QaSpecCharacteristicGrid';

import { usePhyChe } from 'pages/qa-spec/hooks/usePhyChe';
import { EDIT_SECTION } from 'pages/qa-spec/components/edit-tab-checker';
import { useGetQaPhysicalChemicalSnapshot } from './snapshot/hooks';

const PhyCheCharacteristic = (props) => {
  const { productId, enumOptions } = props;

  const [isGridEditing, setIsGridEditing] = useState(false);

  const {
    gridInst,
    analysisNotExecuted,
    rowData,
    columnDefs,
    saveData,
    cancelEdit,
    loading,
    addNewRow,
    deleteData,
    selectedRow,
    selectRow,
    onCellEditingStopped,
    gridTitle,
  } = usePhyChe({
    productId,
    enumOptions,
    isGridEditing,
  });

  const { physicalChemicalSnapshot } = useGetQaPhysicalChemicalSnapshot();

  const onEdit = (isEditing) => {
    setIsGridEditing(isEditing);
  };

  return (
    <QaSpecCharacteristicGrid
      type={EDIT_SECTION.PROD_SPEC_PHY_N_CHE}
      title={gridTitle}
      loading={loading}
      gridInst={gridInst}
      columnDefs={columnDefs}
      rowData={rowData}
      handleSave={saveData}
      handleAdd={addNewRow}
      handleDelete={deleteData}
      handleCancel={cancelEdit}
      selectedRow={selectedRow}
      onRowSelected={selectRow}
      analysisNotExecuted={analysisNotExecuted}
      isShowAnalysisNotExecuted
      productId={productId}
      onCellEditingStopped={onCellEditingStopped}
      snapshotGridValues={physicalChemicalSnapshot}
      onEditChange={onEdit}
    />
  );
};

export default PhyCheCharacteristic;
