import { DEFAULT_COLUMNS } from './constants';

import { Checkbox } from 'antd';

import { formatMDY } from 'utils/formatDate';

export const getColumnDefs = ({ item }) => {
  const fields = Object.keys(item ? item : DEFAULT_COLUMNS);
  const checkboxColumns = {
    checkboxSelection: true,
    headerCheckboxSelection: true,
    filter: false,
    resizable: false,
    suppressMenu: true,
    width: 50,
  };

  const columns = fields.reduce((accumulator, currentField, index) => {
    const COLUMN = DEFAULT_COLUMNS[currentField];
    const valueFormatter = getValueFormatter(COLUMN?.format);

    const column = {
      field: currentField,
      headerName: COLUMN?.headerName,
      valueFormatter,
      flex: 1,
    };

    accumulator.push(column);

    return accumulator;
  }, []);

  const actionColumn = {
    field: 'unRegisterProduct',
    flex: 1,
    headerName: 'Unregister Product',
    cellRenderer: (params) => {
      return <CheckboxRender params={params} />;
    },
  };
  return [checkboxColumns, ...columns, actionColumn];
};

const CheckboxRender = ({ params }) => {
  const onChangeUnregisterCheckbox = (params) => (e) => {
    const rowNode = params.node;

    rowNode.setDataValue('unRegisterProduct', e.target.checked);
  };
  return (
    <Checkbox
      checked={params?.data?.unRegisterProduct}
      onChange={onChangeUnregisterCheckbox(params)}
    />
  );
};

const getValueFormatter = (format) => {
  const formatterCollection = {
    date: formatDate,
  };

  return formatterCollection[format];
};

const formatDate = (params) => {
  return formatMDY(params.value);
};
