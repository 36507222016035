import DrugFactSection from './DrugFactSection';
import DrugFactHeading from './DrugFactHeading';
import DrugFactText from './DrugFactText';

const DrugFactInactiveIngredients = ({ inactiveIngredients }) => {
  if (!inactiveIngredients) return null;

  return (
    <DrugFactSection className='drug-fact__inactive-ingredient'>
      <DrugFactHeading heading='Inactive ingredients' />
      <DrugFactText>{inactiveIngredients}</DrugFactText>
    </DrugFactSection>
  );
};

export default DrugFactInactiveIngredients;
