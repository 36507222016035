import React, { useEffect, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Form, message, Spin, notification } from 'antd';

import * as actionsGrid from 'common/components/grid-view/controllers/actions';
import PanelEditContent from './FolderPanelEditContent';
import PanelHeader from './FolderPanelEditHeader';
import moment from 'moment';
import * as selectorsGlobal from 'redux/global/selectors';
import * as actions from '../../controllers/actions';
import * as selectors from '../../controllers/selectors';

import { useDispatchReloadPage } from 'hooks/useReloadPage';
import FolderPanelContent from '../panel/FolderPanelContent.old';

import './FolderPanelEdit.less';
import { formatMDY } from 'utils/formatDate';

const FolderPanelEdit = (props) => {
  const { folderInfo, onCloseEdit, ownerFolder, loadingDetail } = props;
  const dispatch = useDispatch();
  const reloadPage = useDispatchReloadPage();

  const [disableSaveBtn, setDisableSaveBtn] = useState(true);

  const startEdit = useSelector(selectors.selectStartEdit());
  const editSuccess = useSelector(selectors.selectEditSuccess());

  let folderInfoOrgin = {
    folderName: folderInfo?.folderName,
    specialPurpose: folderInfo?.specialPurpose,
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      folderName: folderInfo?.folderName,
      specialPurpose: folderInfo?.specialPurpose,
      visibility: folderInfo?.visibility,
    });
    // eslint-disable-next-line
  }, [folderInfo?.folderName, folderInfo?.specialPurpose]);

  useEffect(() => {
    if (startEdit === 'finish') {
      if (editSuccess === 'success') {
        handleEditSuccess();
      } else if (editSuccess === 'error') {
        handleEditError();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startEdit, editSuccess]);

  const onFinishFailed = (errorInfo) => {
    console.log('onFinishFailed: ', errorInfo);
  };

  const isFormChanged = (fieldsValue) => {
    if (!shallowEqual(folderInfoOrgin, fieldsValue)) {
      return true;
    }
    return false;
  };

  const onFinish = (values) => {
    let id = folderInfo.id;
    if (isFormChanged(values)) {
      dispatch(
        actions.editShortContent({
          ...values,
          expirationDate: values?.expirationDate
            ? moment(values?.expirationDate)
            : formatMDY(folderInfo?.expirationDate),
          id,
        })
      );
    } else {
      notification.info({
        message: "Folder information doesn't change!",
        placement: 'topRight',
        duration: 1,
      });
      setTimeout(() => {
        onCloseEdit();
      }, 200);
    }
  };

  const onSaveClick = () => {
    form.submit();
  };

  const handleEditSuccess = () => {
    setTimeout(() => {
      dispatch(actions.editShortContentFinish());
      onCloseEdit();

      reloadPage();
      setTimeout(() => {
        dispatch(actionsGrid.updateItemsSelection([folderInfo.id]));
        dispatch(actions.getFolderShortDetail(folderInfo.id));
      }, 400);
    }, 500);
  };

  const handleEditError = () => {
    message.error('Edit error - Something went wrong!');
  };

  const onValuesChange = (value) => {
    if (isFormChanged(form.getFieldsValue())) {
      setDisableSaveBtn(false);
    } else {
      setDisableSaveBtn(true);
    }
  };

  const isLoading = loadingDetail || startEdit === 'starting';

  return (
    <div className='folder-panel-edit folder-panel__wrapper'>
      <PanelHeader
        onCancelClick={onCloseEdit}
        onSaveClick={onSaveClick}
        disableSaveBtn={disableSaveBtn}
      />
      <PanelEditContent
        form={form}
        folderInfo={folderInfo && folderInfo}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onCancelClick={onCloseEdit}
        ownerFolder={ownerFolder}
        onValuesChange={onValuesChange}
        loading={isLoading}
      />
      <FolderPanelContent
        ownerFolder={ownerFolder}
        shortDetail={folderInfo}
        isEdit={true}
      />
    </div>
  );
};

FolderPanelEdit.propTypes = {
  folderInfo: PropTypes.object,
  onCloseEdit: PropTypes.func,
  ownerFolder: bool,
  folderOption: PropTypes.string,
  loadingDetail: PropTypes.bool,
};

export default FolderPanelEdit;
