export const GET_WORKFLOW_TEMPLATE_DETAIL =
  '/api/workflow/get-workflow-template-detail';

export const GET_WORKFLOW_STEP_INFO = '/api/workflow/get-workflow-step-info';

// Event Pipe
export const ADD_EVENT_PIPE_TO_PROGRESSION =
  '/api/workflow/add-event-pipe-to-progression';

export const GET_ENTITY_PROPERTIES = '/api/workflow/get-entity-properties';

export const ADD_WORKFLOW_PROGRESSION_CONDITION =
  '/api/workflow/add-workflow-progression-condition';

export const EDIT_WORKFLOW_PROGRESSION_CONDITION =
  '/api/workflow/edit-workflow-progression-condition';

export const ADD_WORKFLOW_PROGRESSION_ACTION =
  '/api/workflow/add-workflow-progression-action';

export const GET_CONDITION_COMPARE_OPERATOR =
  '/api/workflow/get-condition-compare-operators';

export const GET_WORKFLOW_TASK_FOR_CONDITION =
  '/api/workflow/get-workflow-task-for-condition';

export const SWITCH_EVENT_PIPE_CONDITION =
  '/api/workflow/switch-event-pipe-condition';

export const REMOVE_WORKFLOW_PROGRESSION_CONDITION =
  '/api/workflow/remove-workflow-progression-condition';

export const REMOVE_WORKFLOW_PROGRESSION_ACTION =
  '/api/workflow/remove-workflow-progression-action';

export const GET_WORKFLOW_PROGRESSION_INFO =
  '/api/workflow/get-workflow-progression-info';

export const REMOVE_WORKFLOW_PROGRESSION_INFO =
  '/api/workflow/remove-progression-event-pipe';

export const SET_FINAL_ACTION =
  '/api/workflow/set-final-action-for-progression-completion';
//

export const CREATE_WORKFLOW_STEP = '/api/workflow/create-workflow-step';
export const REORDER_WORKFLOW_TASKS = '/api/workflow/re-order-workflow-tasks';
export const GET_WORKFLOW_TEMPLATE = '/api/workflow/get-workflow-templates';
export const GET_WORKFLOW_TEMPLATE_DISTINCT =
  '/api/workflow/get-workflow-templates/distinct';
export const GET_WORKFLOW_TEMPLATE_VERSIONS =
  '/api/workflow/get-workflow-templates-in-group';
export const EDIT_WORKFLOW_TEMPLATE = '/api/workflow/edit-workflow-template';

export const EDIT_WORKFLOW_STEP = '/api/workflow/edit-workflow-step';
export const GET_USER_WORKFLOW_ROLES = '/api/workflow/get-user-workflow-roles';
export const GET_WORKFLOW_TASK_INFO = '/api/workflow/get-workflow-task-info';
export const EDIT_WORKFLOW_TASK = '/api/workflow/edit-workflow-task';

export const GET_USERS_TO_ASSIGN = '/api/workflow/get-users-to-assign';
export const ADD_WORKFLOW_ACTION =
  '/api/workflow/add-workflow-progression-action';
export const REMOVE_WORKFLOW_ACTION =
  '/api/workflow/remove-workflow-progression-action';
export const EDIT_WORKFLOW_ACTION =
  '/api/workflow/edit-workflow-progression-action';
export const CREATE_WORKFLOW_TASK = '/api/workflow/create-workflow-task';
export const GET_WORKFLOW_METRIC = '/api/workflow/get-workflow-metric';
export const GET_REQUIRED_TASK = '/api/workflow/get-required-tasks';
export const GET_WORKFLOW_STEP_METRIC =
  '/api/workflow/get-workflow-step-metric';

export const CLONE_WORKFLOW = '/api/workflow/clone-workflow-template';
export const CLONE_WORKFLOW_STEP = '/api/workflow/clone-workflow-step';
export const CLONE_WORKFLOW_TASK = '/api/workflow/clone-workflow-task';
