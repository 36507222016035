export const SHOW_LOADING = 'SHOW_LOADING';
export const GET_DIGITAL_ASSET_LIST = 'GET_DIGITAL_ASSET_LIST';
export const GET_DIGITAL_ASSET_FOR_MEMBER_LIST =
  'GET_DIGITAL_ASSET_FOR_MEMBER_LIST';
export const GET_DIGITAL_ASSET_LIST_SUCCESS = 'GET_DIGITAL_ASSET_LIST_SUCCESS';
export const GET_DIGITAL_ASSET_LIST_ERROR = 'GET_DIGITAL_ASSET_LIST_ERROR';

export const DIGITAL_ASSET_GRID_COLUMN_INFO = 'DIGITAL_ASSET_GRID_COLUMN_INFO';
export const DIGITAL_ASSET_GRID_COLUMN_INFO_SUCCESS =
  'DIGITAL_ASSET_GRID_COLUMN_INFO_SUCCESS';
export const DIGITAL_ASSET_GRID_COLUMN_INFO_ERROR =
  'DIGITAL_ASSET_GRID_COLUMN_INFO_ERROR';
export const GET_DIGITAL_ASSET_SHORT_DETAIL = 'GET_DIGITAL_ASSET_SHORT_DETAIL';
export const GET_DIGITAL_ASSET_SHORT_DETAIL_SUCCESS =
  'GET_DIGITAL_ASSET_SHORT_DETAIL_SUCCESS';
export const GET_DIGITAL_ASSET_SHORT_DETAIL_ERROR =
  'GET_DIGITAL_ASSET_SHORT_DETAIL_ERROR';

export const RELOAD_PAGE = 'RELOAD_PAGE';
export const UPDATE_CACHE_IMAGE = 'UPDATE_CACHE_IMAGE';

export const DELETE_ASSETS = 'DELETE_ASSETS';
export const DELETED_ASSETS_SUCCESS = 'DELETED_ASSETS_SUCCESS';
export const DELETED_ASSETS_ERROR = 'DELETED_ASSETS_ERROR';
export const RESET_DELETE_ASSETS = 'RESET_DELETE_ASSETS';
export const UPDATE_ASSET_TYPE_PANE = 'UPDATE_ASSET_TYPE_PANE';
export const UPDATE_ASSET_TYPE_PANE_SUCCESS = 'UPDATE_ASSET_TYPE_PANE_SUCCESS';
export const UPDATE_ASSET_TYPE_PANE_ERROR = 'UPDATE_ASSET_TYPE_PANE_ERROR';

// Assets for members
export const GET_ASSETS_FOR_MEMBER = 'GET_ASSETS_FOR_MEMBER';
export const GET_ASSETS_FOR_MEMBER_SUCCESS = 'GET_ASSETS_FOR_MEMBER_SUCCESS';
export const GET_ASSETS_FOR_MEMBER_ERROR = 'GET_ASSETS_FOR_MEMBER_ERROR';

export const GET_COLUMNS_ASSETS_FOR_MEMBER = 'GET_COLUMNS_ASSETS_FOR_MEMBER';
export const GET_COLUMNS_ASSETS_FOR_MEMBER_SUCCESS =
  'GET_COLUMNS_ASSETS_FOR_MEMBER_SUCCESS';
export const GET_COLUMNS_ASSETS_FOR_MEMBER_ERROR =
  'GET_COLUMNS_ASSETS_FOR_MEMBER_ERROR';

export const SAVE_ID_MEMBER = 'SAVE_ID_MEMBER';
export const TOGGLE_ASSET_NEW = 'TOGGLE_ASSET_NEW';
export const CACHE_ASSET_FROM_DATE = 'CACHE_ASSET_FROM_DATE';
export const UPDATE_LATEST_GET_NEW_ASSET = 'UPDATE_LATEST_GET_NEW_ASSET';
export const UPDATE_LATEST_GET_UNMATCH_ASSET =
  'UPDATE_LATEST_GET_UNMATCH_ASSET';
export const TOGGLE_ASSET_UNMATCH = 'TOGGLE_ASSET_UNMATCH';
