import React from 'react';

import { Button } from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import { PRODUCT_SPEC_DIETARY_CERTIFICATION_MAP_FIELD_SCHEMA_TO_CERTIFICATE_TYPE } from '../../constant';

const DietaryCertificateUploadBtn = (props) => {
  const { openModalToUploadCertificate, disabled, fieldSchema } = props;

  const certificateItemType =
    PRODUCT_SPEC_DIETARY_CERTIFICATION_MAP_FIELD_SCHEMA_TO_CERTIFICATE_TYPE?.[
      fieldSchema.name
    ];

  const handleClickUploadCertificate = () => {
    openModalToUploadCertificate(certificateItemType);
  };

  return (
    <Button
      icon={<UploadOutlined />}
      onClick={handleClickUploadCertificate}
      disabled={disabled}
      danger
    >
      Upload cetificate document
    </Button>
  );
};

export default DietaryCertificateUploadBtn;
