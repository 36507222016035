import React, { useState } from 'react';
import { ThumbnailItem } from 'common/components';
import { Row, Col, Typography, Button, Badge } from 'antd';
import { StyledModal } from 'common/components';
import { CloseOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

export const PublishProductComponent = ({ products, handleRemoveProduct }) => {
  const [visible, setVisible] = useState(false);

  const modalProps = {
    title: 'Publish Products',
    visible: visible,
    width: '80vw',
    height: '75vh',
    closable: true,
    maskClosable: false,

    onCancel: () => setVisible(false),
    wrapClassName: 'document-modal',
    onOk: () => setVisible(false),
  };

  return (
    <>
      <Title level={5} style={{ marginBottom: 0 }}>
        Total Products: {`${products?.length}`}
      </Title>
      <Row>
        {products.slice(0, 2)?.map((product) => (
          <Col flex={0} key={product?.id}>
            <PublishContainer
              handleClose={() => handleRemoveProduct(product?.id)}
            >
              <ThumbnailItem
                imageThumbnail={
                  product?.thumbnail || product?.productPrimaryImage
                }
                dataDetail={product}
                isProductThumbnail
                hideLabel
                isShareBtn
                hideCheckbox
              />
            </PublishContainer>
          </Col>
        ))}

        {products?.length > 2 && (
          <Col span={24}>
            <Paragraph
              underline
              style={{
                color: '#0070cc',
                margin: '0px 15px',
                cursor: 'pointer',
              }}
              onClick={() => setVisible(true)}
            >
              Show more...
            </Paragraph>
          </Col>
        )}
      </Row>
      {visible && (
        <StyledModal
          {...modalProps}
          bodyStyle={{ height: '70vh' }}
          footer={[
            <Button type='primary' onClick={() => setVisible(false)}>
              Close
            </Button>,
          ]}
        >
          <Row>
            {products?.map((product) => (
              <Col flex={0} key={product?.id}>
                <PublishContainer
                  handleClose={() => handleRemoveProduct(product?.id)}
                >
                  <ThumbnailItem
                    imageThumbnail={
                      product?.thumbnail || product?.productPrimaryImage
                    }
                    isProductThumbnail={true}
                    dataDetail={product}
                    hideLabel={true}
                    isShareBtn={true}
                    hideCheckbox={false}
                  />
                </PublishContainer>
              </Col>
            ))}
          </Row>
        </StyledModal>
      )}
    </>
  );
};

export const PublishContainer = ({ handleClose, children }) => {
  return (
    <Badge
      className='publish-product__badge'
      count={
        <span onClick={handleClose}>
          <CloseOutlined style={{ padding: 3 }} />
        </span>
      }
    >
      {children}
    </Badge>
  );
};
