import React from 'react';

import { Checkbox } from 'antd';

const CheckboxSelectionRender = (props) => {
  const { value } = props;
  return (
    <Checkbox name='ag-grid-input-check-clicked' checked={value}></Checkbox>
  );
};

export default CheckboxSelectionRender;
