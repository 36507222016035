import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { FileSyncOutlined } from '@ant-design/icons';
import Messages from 'i18n/messages/home';

import RibbonButton from 'common/components/button/RibbonButton';

import {
  toggleReplacePreview,
  cancelReplacePreview,
} from 'pages/home/ribbon/asset-full/controllers/actions';

// import * as assetFullActions from 'pages/asset-full-view/controllers/actions';

import { useHandleReloadPage } from 'hooks/useReloadPage';

import { AssetUpload } from 'pages/asset-full-view/components';

import * as digitalAssetEndpoint from 'services/digitalAsset/endpoints';
import * as digitalAssetServices from 'services/digitalAsset';

import {
  EditButton,
  FormSaveButton,
  FormCancelButton,
  CustomNotification,
} from 'common/components';

import { useCheckAllowEditAssets } from 'hooks';

import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { ASSET_UPLOAD_TYPE, TYPE_VIEW_ASSET } from 'static/Constants';

import './ReplacePreviewGrid.less';

const ReplacePreviewGrid = (props) => {
  // props
  const { sectionDisabled, isIframe, digitalAssetData } = props;
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const [uploadType, setUploadType] = useState(
    ASSET_UPLOAD_TYPE.UPLOAD_LOCAL_FILE.name
  );
  const [idSelectionDropbox, setIdSelectionDropbox] = useState(null);

  const dispatch = useDispatch();

  const [__, reloadGrid] = useHandleReloadPage();

  const { checkAllowEditAssetGrid } = useCheckAllowEditAssets();

  const isAllowEditGrid = checkAllowEditAssetGrid();
  const isDisabled = !isAllowEditGrid || sectionDisabled;

  const handleCancel = () => {
    setVisible(false);
  };

  const handleResponse = (response) => {
    if (response?.isSuccess) {
      CustomNotification.success(
        intl.formatMessage(Messages.replacePreviewSuccess)
      );
      reloadGrid();
      setVisible(false);
    } else {
      CustomNotification.error(intl.formatMessage(Messages.replacePreviewFail));
    }
  };

  const replacePreviewUploadCallback = (result) => {
    const response = result?.response;
    if (!response) return;

    handleResponse(response);
  };

  const getReplacePreviewResult = (response) => {
    handleResponse(response);
  };

  const customReplacePreviewFromFtp = (selectedFile) => {
    const params = {
      assetId: digitalAssetData?.id,
      path: selectedFile?.[0]?.interopPath,
    };
    return digitalAssetServices.updateDigitalAssetThumbByAdvFpt(params);
  };

  return (
    <>
      <Modal
        className='replace-preview__modal'
        title='Replace Preview'
        visible={visible}
        // onOk={handleCancel}
        onCancel={() => handleCancel()}
        width='80vw'
        bodyStyle={{ height: '80vh' }}
        footer={null}
      >
        <div style={{ height: '100%' }}>
          {visible && (
            <AssetUpload
              isBase64={true}
              onlyAcceptImage={true}
              showMultiple={false}
              apiUrl={digitalAssetEndpoint.UPDATE_DIGITAL_ASSET_THUMBNAIL}
              ftpApiUrl={digitalAssetEndpoint.UPDATE_DIGITAL_ASSET_THUMBNAIL}
              manualUpload={true}
              base64UploadKey={digitalAssetData?.id}
              getLocalFileResponse={replacePreviewUploadCallback}
              getFtpFileResponse={getReplacePreviewResult}
              customRequestFtp={customReplacePreviewFromFtp}
              supportTypes={['jpg', 'png', 'jpeg']}
              showUploadSuccessMsg={false}
              maxSize={5}
              isShowCancelButton={true}
              handleCancel={handleCancel}
              uploadType={uploadType}
              setUploadType={setUploadType}
              idSelectionDropbox={idSelectionDropbox}
              setIdSelectionDropbox={setIdSelectionDropbox}
              typeViewAsset={TYPE_VIEW_ASSET.REPLACE_THUMBNAIL}
            />
          )}
        </div>
      </Modal>
      <RibbonButton
        size='small'
        className='button-item-dropdown'
        icon={<FileSyncOutlined />}
        label={Messages.replacePreview}
        onClick={() => setVisible(true)}
        disabled={isDisabled}
      />
    </>
  );
};

ReplacePreviewGrid.propTypes = {
  sectionDisabled: PropTypes.bool,
  isReplacing: PropTypes.bool,
};

export default ReplacePreviewGrid;
