import React, { useState, useEffect } from 'react';
import { Form, Input, Select, InputNumber, Row, Col } from 'antd';
import { ProductBrickCode } from 'pages/branded-products/components';
import { FormEditButton, WrapperSelect } from 'common/components';
import { sortByKeyCallback } from 'utils/sortByKey';

const { Option } = Select;

const MAX_NUMBER_INPUT_UPC = 9999999999999999999;

const handleNumberInput = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const PACKAGE_LEVELS = [
  'Unit',
  'Inner Pack',
  'Case',
  'Master Case',
  'Pallet',
];

export const textAreaEnums = [{ name: 'productDescription' }];

export const inputNumberEnums = [
  { name: 'primarySize', min: 0, max: 999999 },
  { name: 'width', min: 0, max: MAX_NUMBER_INPUT_UPC },
  { name: 'height', min: 0, max: MAX_NUMBER_INPUT_UPC },
  { name: 'depth', min: 0, max: MAX_NUMBER_INPUT_UPC },
  { name: 'weight', min: 0, max: MAX_NUMBER_INPUT_UPC },
];

export const selectEnums = [
  {
    name: 'productType',
    options: (productTypeEnums) => {
      return productTypeEnums?.[0]?.enumProperties
        ?.sort(sortByKeyCallback('enumDisplayName'))
        .map((type, index) => (
          <Option value={type?.enumDisplayName} key={index}>
            {type?.enumDisplayName}
          </Option>
        ));
    },
  },
  {
    name: 'packageLevel',
    options: PACKAGE_LEVELS,
  },
  {
    name: 'visibility',
    options: ['Only If Shared', 'Public', 'Private'],
  },
  {
    name: 'status',
    options: ['Active', 'Pending'],
  },
  {
    name: 'primarySizeUOM',
    options: ({ productBasicUOM }) => {
      return (
        <>
          {productBasicUOM?.[0]?.enumProperties
            ?.filter((type) => type.enumGroup === 'Mass')
            .sort(sortByKeyCallback('enumDisplayName'))
            .map((item, index) => (
              <Option value={item?.enumDisplayName} key={index}>
                {item?.enumDisplayName}
              </Option>
            ))}
        </>
      );
    },
  },
  {
    name: 'weightUOM',
    options: ({ productBasicUOM }) => {
      return (
        <>
          {productBasicUOM?.[0]?.enumProperties
            ?.filter((type) => type.enumGroup === 'Mass')
            .sort(sortByKeyCallback('enumDisplayName'))
            .map((item, index) => (
              <Option value={item?.enumDisplayName} key={index}>
                {item?.enumDisplayName}
              </Option>
            ))}
        </>
      );
    },
  },
  {
    name: 'dimensionUOM',
    options: ({ productBasicUOM }) => {
      return (
        <>
          {productBasicUOM?.[0]?.enumProperties
            ?.filter((type) => type.enumGroup === 'Dimensions')
            .sort(sortByKeyCallback('enumDisplayName'))
            .map((item, index) => (
              <Option value={item?.enumDisplayName} key={index}>
                {item?.enumDisplayName}
              </Option>
            ))}
        </>
      );
    },
  },
];

export const inputTextEnums = [
  { name: 'ixOneId', maxLength: 30 },
  { name: 'productName', maxLength: 70 },
  {
    name: 'gtin',
    maxLength: MAX_NUMBER_INPUT_UPC,
    onKeyPress: handleNumberInput,
  },
  {
    name: 'upc12',
    maxLength: MAX_NUMBER_INPUT_UPC,
    onKeyPress: handleNumberInput,
  },
  {
    name: 'upc8',
    maxLength: MAX_NUMBER_INPUT_UPC,
    onKeyPress: handleNumberInput,
  },
  {
    name: 'upc10',
    maxLength: MAX_NUMBER_INPUT_UPC,
    onKeyPress: handleNumberInput,
  },
  {
    name: 'upc11',
    maxLength: MAX_NUMBER_INPUT_UPC,
    onKeyPress: handleNumberInput,
  },
  {
    name: 'upc13',
    maxLength: MAX_NUMBER_INPUT_UPC,
    onKeyPress: handleNumberInput,
  },
];

export const brickCodeEnums = [{ name: 'brickCode' }];

const BrickCodeInput = (props) => {
  const { onChange, brickCode, brickCodeName } = props;

  const [brickModalVisible, setBrickModalVisible] = useState(false);
  const [brick, setBrick] = useState();

  const handleBrickCode = (brick) => {
    setBrick({
      brickCode: brick?.BrickCode,
      brickCodeName: brick?.BrickDescription,
    });
    setBrickModalVisible(false);
  };

  const toggleBrickModal = () => {
    setBrickModalVisible((prev) => !prev);
  };

  useEffect(() => {
    setBrick({
      brickCode: brickCode,
      brickCodeName: brickCodeName,
    });
  }, [brickCode, brickCodeName]);

  useEffect(() => {
    onChange && onChange(brick);
  }, [brick, onChange]);

  return (
    <Row gutter={[10, 10]}>
      <Col style={{ flex: 1 }}>
        <Input disabled value={brick?.brickCode} />
      </Col>

      <Col>
        <FormEditButton text={null} onClick={toggleBrickModal} />
      </Col>
      <>
        <ProductBrickCode
          visible={brickModalVisible}
          handleCancel={() => setBrickModalVisible(false)}
          handleBrickCode={handleBrickCode}
          initialBrickCode={brick?.brickCode}
        />
      </>
    </Row>
  );
};

export const FormBrickCodeInput = (props) => {
  const { brickCode, brickCodeName, ...restProps } = props;

  return (
    <Form.Item {...restProps}>
      <BrickCodeInput brickCode={brickCode} brickCodeName={brickCodeName} />
    </Form.Item>
  );
};

export const FormTextAreaInput = (props) => {
  return (
    <Form.Item {...props}>
      <Input.TextArea />
    </Form.Item>
  );
};

export const FormTextInput = (props) => {
  return (
    <Form.Item {...props}>
      <Input />
    </Form.Item>
  );
};

export const FormSelectInput = (props) => {
  const { options, optionParams = {}, ...restProps } = props;
  return (
    <Form.Item {...restProps}>
      <WrapperSelect style={{ width: '100%' }}>
        {typeof options === 'function'
          ? options && options(optionParams)
          : options
              ?.sort(sortByKeyCallback(null))
              .map((option, index) => <Option value={option} key={index} />)}
      </WrapperSelect>
    </Form.Item>
  );
};

export const FormNumberInput = (props) => {
  return (
    <Form.Item {...props}>
      <InputNumber min={0} max={999999} />
    </Form.Item>
  );
};
