import React, { useEffect } from 'react';

function useClickOuter(ref, handleClickOutside, currentTargetConfig) {
  useEffect(
    (handler = handleClickOutside) => {
      function handleClickOutside(event) {
        const $target = event.target;

        const $wrapper = ref.current;
        if ($wrapper && !$wrapper.contains($target)) {
          if (currentTargetConfig) {
            const { id, currentId } = currentTargetConfig;
            if (currentId !== id) return;
          }
          handler && handler();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    [ref, handleClickOutside]
  );
}

export default useClickOuter;
