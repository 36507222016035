import React from 'react';
import { Row } from 'antd';
import { Recipient } from './Recipient';
import { GeneralButton } from 'common/components';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import './PartyItem.less';
export const PartyItem = ({
  item,
  recipientGLNs = [],
  handleAddGln,
  handleRemoveGln,
}) => {
  const selectedGln =
    recipientGLNs.find((recipient) => recipient?.partyGln === item?.partyGln) ??
    {};

  const isSelected = Object.keys(selectedGln).length > 0;

  return (
    <div className='gs1-party'>
      <Recipient
        className='gs1-party-recipient'
        item={item}
        style={{ width: '80%', paddingRight: '10px' }}
      />
      <div className='gs1-party-action'>
        {!isSelected ? (
          <GeneralButton type='primary' onClick={() => handleAddGln(item)}>
            <PlusOutlined />
          </GeneralButton>
        ) : (
          <GeneralButton type='danger' onClick={() => handleRemoveGln(item)}>
            <CloseOutlined />
          </GeneralButton>
        )}
      </div>
    </div>
  );
};
