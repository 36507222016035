export const getStyle = ({ provided, style, isDragging }) => {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const marginBottom = 8;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
  };
  return withSpacing;
};

export const handleAddFields = ({ index, property, data }) => {
  const cloneData = data?.slice();
  const childFields = cloneData?.[index]?.childFields;

  const level = property?.level ? property?.level + 1 : 1;

  const addLevelToField = childFields?.map((item) => {
    return { ...item, level };
  });

  cloneData?.splice(index + 1, 0, ...addLevelToField);
  cloneData?.splice(index, 1, { ...property, isActive: true });

  return cloneData;
};

export const handleRemoveFields = ({ property, index, data }) => {
  const cloneData = data?.slice();
  let countFieldRemove = 1;

  const moduleLevel = property?.level ? property?.level : 0;

  let i = index + 1;
  const totalData = data.length;

  for (i; i < totalData; i++) {
    if (moduleLevel < data[i]?.level) countFieldRemove++;
    else break;
  }

  cloneData?.splice(index, countFieldRemove, {
    ...property,
    isActive: false,
  });

  return cloneData;
};

export const getHighlightedText = (text, highlight = '') => {
  if (!highlight) return text;
  // Split on highlight term and include term into parts, ignore case
  const removeSpecialCharacters = highlight?.replace(/[^A-Z0-9\s]+/gi, '');

  const parts = text.split(new RegExp(`(${removeSpecialCharacters})`, 'gi'));

  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { backgroundColor: '#1e88e5', color: '#fff' }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
};
