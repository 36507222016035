/**
 * The global state selectors
 */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

const brandingDomain = (state) => state.branding || initialState;

const getBrandingLoading = () =>
  createSelector(brandingDomain, (state) => state.brandingRoot.loading);

const getBrandingErr = () =>
  createSelector(brandingDomain, (state) => state.brandingRoot.error);

const getBranding = () =>
  createSelector(brandingDomain, (state) => state.brandingRoot.data);

export { getBrandingLoading, getBranding, getBrandingErr };
