import React from 'react';

import { RibbonButton } from 'common/components';
import { ReactComponent as IconCopyFolder } from 'common/components/button/svg-icons/copy.svg';

import Messages from 'i18n/messages/home';
import { injectIntl } from 'react-intl';

const CopyFolder = ({ selectedFolders, disabled, onClick }) => {
  const isDisable = selectedFolders?.length === 0 || disabled;

  return (
    <RibbonButton
      size='small'
      icon={<IconCopyFolder />}
      label={Messages.copyFolder}
      disabled={isDisable}
      onClick={onClick}
    />
  );
};

export default injectIntl(CopyFolder);
