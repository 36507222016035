import * as api from 'config/axios';
import * as endpoints from './endpoints';

export const getSupplierListApi = (data) => {
  return api.sendPost(endpoints.GET_LIST_SUPPLIER, data);
};

export const getAllSuppliers = (params) => {
  return api.sendPost(endpoints.GET_ALL_SUPPLIERS, params);
};

export const assignSupplier = (params) => {
  return api.sendPost(endpoints.ASSIGN_SUPPLIER, params);
};

export const assignSupplierPIMAcTion = (params) => {
  return api.sendPost(endpoints.ASSIGN_SUPPLIER_PIM_ACTION, params);
};

export const getSupplierDetail = (params) => {
  return api.sendGet(endpoints.GET_SUPPLIER_DETAIL, { params });
};

export const editSupplier = (params) => {
  return api.sendPost(endpoints.EDIT_SUPPLIER, params);
};

export const removeSupplier = (params) => {
  return api.sendPost(endpoints.REMOVE_SUPPLIER, params);
};
