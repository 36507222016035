import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Messages from 'i18n/messages/maintenance';
import { notification } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { dialogFunction } from 'common/components/index';
import { useIntl } from 'react-intl';
import RibbonButton from 'common/components/button/RibbonButton';

import gridSelector from 'common/components/grid-view/controllers/selectors';

import { revokeCuratedQuery } from 'services/markupMaintenance';
import * as markupActions from 'pages/markup-maintenance/controllers/actions';
import * as markupSelectors from 'pages/markup-maintenance/controllers/selectors';
import { useCheckIsSuperMember } from 'hooks';

const RevokeCuratedQuery = () => {
  const isSuperMember = useCheckIsSuperMember();
  const dispatch = useDispatch();
  const intl = useIntl();

  const pageSize = useSelector(markupSelectors.selectCuratedQueryPageSize());

  const selectedQueryDetail = useSelector(
    gridSelector.makeSelectDetailCurrentITemsSelection()
  );
  const isExpiredQuery =
    selectedQueryDetail?.length > 0 &&
    selectedQueryDetail[0]?.curatedQueryShareStatus === 'Expired';

  const confirmRevoke = () => {
    dialogFunction({
      type: 'warn',
      content: intl.formatMessage(Messages.confirmRevokeCuratedQuery),
      onOk: handleRevokeCuratedQuery,
    });
  };

  const handleRevokeCuratedQuery = async () => {
    try {
      const response = await revokeCuratedQuery({
        curatedQueryId: selectedQueryDetail[0]?.id,
      });
      const { isSuccess, message } = response;
      if (isSuccess) {
        notification.success({
          message: 'Revoke curated query successfully!',
        });
        dispatch(markupActions.getCuratedQueryList(1, pageSize, ''));
      } else {
        notification.error({
          message: message || 'Cannot revoke curated query!',
        });
      }
    } catch (error) {
      notification.error({ message: error || 'Cannot revoke curated query!' });
    }
  };

  return (
    <RibbonButton
      icon={<RollbackOutlined />}
      label={Messages.revokeCuratedQuery}
      onClick={confirmRevoke}
      disabled={
        !isSuperMember || selectedQueryDetail?.length !== 1 || isExpiredQuery
      }
    />
  );
};

export default RevokeCuratedQuery;
