export const GET_MEMBER_MAINTENANCE_GRID = 'GET_MEMBER_MAINTENANCE_GRID';
export const GET_MEMBER_MAINTENANCE_GRID_SUCCESS =
  'GET_MEMBER_MAINTENANCE_GRID_SUCCESS';
export const GET_MEMBER_MAINTENANCE_GRID_ERROR =
  'GET_MEMBER_MAINTENANCE_GRID_ERROR';

export const GET_USER_MAINTENANCE_GRID = 'GET_USER_MAINTENANCE_GRID';
export const GET_USER_MAINTENANCE_GRID_SUCCESS =
  'GET_USER_MAINTENANCE_GRID_SUCCESS';
export const GET_USER_MAINTENANCE_GRID_ERROR =
  'GET_USER_MAINTENANCE_GRID_ERROR';

export const GET_MEMBER_MAINTENANCE_GRID_COLUMN =
  'GET_MEMBER_MAINTENANCE_GRID_COLUMN';
export const GET_MEMBER_MAINTENANCE_GRID_COLUMN_SUCCESS =
  'GET_MEMBER_MAINTENANCE_GRID_COLUMN_SUCCESS';
export const GET_MEMBER_MAINTENANCE_GRID_COLUMN_ERROR =
  'GET_MEMBER_MAINTENANCE_GRID_COLUMN_ERROR';

export const CHANGE_MEMBER_GRID_INFO_VIEW = 'CHANGE_MEMBER_GRID_INFO_VIEW';

export const GET_ALL_COMMUNICATION_TEMPLATES_LIST =
  'GET_ALL_COMMUNICATION_TEMPLATES_LIST';
export const GET_ALL_COMMUNICATION_TEMPLATES_LIST_SUCCESS =
  'GET_ALL_COMMUNICATION_TEMPLATES_LIST_SUCCESS';
export const GET_ALL_COMMUNICATION_TEMPLATES_LIST_ERROR =
  'GET_ALL_COMMUNICATION_TEMPLATES_LIST_ERROR';

export const GET_MEMBER_MAINTENANCE_SHORT_DETAIL =
  'GET_MEMBER_MAINTENANCE_SHORT_DETAIL';
export const GET_MEMBER_MAINTENANCE_SHORT_DETAIL_SUCCESS =
  'GET_MEMBER_MAINTENANCE_SHORT_DETAIL_SUCCESS';
export const GET_MEMBER_MAINTENANCE_SHORT_DETAIL_ERROR =
  'GET_MEMBER_MAINTENANCE_SHORT_DETAIL_ERROR';
export const GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE =
  'GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE';
export const GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_SUCCESS =
  'GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_SUCCESS';
export const GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_ERROR =
  'GET_PUBLIC_CONTACT_MEMBER_MAINTENANCE_ERROR';

export const SHOW_EXPORT_LOADING = 'SHOW_EXPORT_LOADING';
export const HIDE_EXPORT_LOADING = 'HIDE_EXPORT_LOADING';

export const MEMBER_MAINTAIN_SET_CURRENT_EXECUTION_TEMPLATE =
  'MEMBER_MAINTAIN_SET_CURRENT_EXECUTION_TEMPLATE';

export const TOGGLE_ADD_NEW_USER = 'TOGGLE_ADD_NEW_USER';
export const TOGGLE_EDIT_USER = 'TOGGLE_EDIT_USER';

export const MEMBER_MAINTAIN_SET_TEMPLATE_VALIDATION =
  'MEMBER_MAINTAIN_SET_TEMPLATE_VALIDATION';
