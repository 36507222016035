import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/brandedProduct.js';
import ProductPackageLevelInfo from './info/ProductPackageLevelInfo';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductPackageLevel.less';

const { Paragraph } = Typography;

const compare = (a, b) => {
  if (a.packageLevel < b.packageLevel) {
    return -1;
  }
  if (a.packageLevel > b.packageLevel) {
    return 1;
  }
  return 0;
};

const ProductPackageLevel = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [transformPixel, setTransformPixel] = useState(0);
  const { dataDetail } = props;
  const slides =
    dataDetail &&
    dataDetail.packageLevelInformation &&
    dataDetail.packageLevelInformation.length > 0
      ? [...dataDetail.packageLevelInformation].sort(compare)
      : [];

  const selectSilde = (index) => {
    if (currentSlide === index) return;
    setCurrentSlide(index);
    if (slides.length < 2) return;
    setTransformPixel(
      transformPixel - ((index - currentSlide) * 100) / slides.length
    );
  };

  const onClickLeftSlider = () => {
    if (currentSlide === 0) return;
    const current = currentSlide - 1;
    setTransformPixel(transformPixel + 100 / slides.length);
    setCurrentSlide(current);
  };

  const onClickRightSlider = () => {
    if (currentSlide >= slides.length - 3) return;
    const current = currentSlide + 1;
    setTransformPixel(transformPixel - 100 / slides.length);
    setCurrentSlide(current);
  };

  return (
    <Row className='product-package-level font-size-12'>
      <Col className='product-package-level__title' span={4}>
        <Paragraph style={{ height: '30px' }} strong='true'>
          &nbsp;
        </Paragraph>
        <Paragraph className='config' strong='true'>
          <FormattedMessage {...Messages.config} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.gtin} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.upc12} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.height} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.width} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.depth} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.height} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.cube} />:
        </Paragraph>
        <Paragraph ellipsis='true' strong='true'>
          <FormattedMessage {...Messages.pack} />:
        </Paragraph>
      </Col>
      <Col span={20}>
        <Row className='product-package-level__slick-slider slick-initialized'>
          <LeftOutlined
            className={currentSlide === 0 ? 'arrow-disabled' : ''}
            onClick={() => onClickLeftSlider()}
            style={{
              fontSize: '20px',
              color: 'black',
              position: 'absolute',
              zIndex: 10,
              cursor: 'pointer',
              left: '0',
            }}
          />
          <RightOutlined
            className={
              currentSlide >= slides.length - 3 ? 'arrow-disabled' : ''
            }
            disabled={true}
            onClick={() => onClickRightSlider()}
            style={{
              fontSize: '20px',
              color: 'black',
              position: 'absolute',
              right: '0',
              zIndex: 10,
              cursor: 'pointer',
            }}
          />
          <Col className='slick-list' span={24}>
            <Row
              className='slick-track transition-slider'
              style={{
                width: `${(100 * slides.length) / 3}%`,
                opacity: 1,
                transform: `translate3d(${transformPixel}%, 0px, 0px)`,
              }}
            >
              {slides &&
                slides.length &&
                slides.map((item, index) => {
                  let cssSlider = 'slick-slide';
                  if (index >= currentSlide && index <= currentSlide + 2) {
                    cssSlider += ' slick-active';
                  }
                  if (index === currentSlide) {
                    cssSlider += ' slick-current';
                  }
                  return (
                    <Col
                      key={index}
                      data-index={index}
                      onClick={() => selectSilde(index)}
                      style={{
                        outline: 'none',
                        width: `${100 / slides.length}%`,
                      }}
                      className={cssSlider}
                    >
                      <ProductPackageLevelInfo packageLevelInformation={item} />
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ProductPackageLevel.propTypes = {
  dataDetail: PropTypes.object.isRequired,
};

export default ProductPackageLevel;
