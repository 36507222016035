import React from 'react';

import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { checkUserAuth } from 'utils/author';
import { forwardTo } from 'utils/common/route';
import selectorUser from 'redux/user/selectors';

/**
 * private route using cookie to check user has already login
 * @param {*} props
 */
const PrivateRoute = ({
  component: Component,
  validateFn,
  path,
  returnUrl = '/',
  ...rest
}) => {
  const userInfo = useSelector(selectorUser.makeSelectUserInfo());

  const isRedirectToWaiting = path !== '/waiting' && !checkUserAuth(userInfo);

  const isValidRoute = validateFn();

  return (
    <Route
      path={path}
      render={(props) => {
        if (!isValidRoute) {
          if (returnUrl === '/login') {
            forwardTo('/login');
          } else {
            forwardTo(`/login?returnUrl=${returnUrl}`);
          }
        } else if (isRedirectToWaiting) {
          forwardTo(`/waiting?returnUrl=${returnUrl}`);
        } else {
          return <Component {...props} />;
        }
      }}
      {...rest}
    />
  );
};

export default PrivateRoute;
