import { useQuery, useMutation } from '@tanstack/react-query';

import { editProductLinkedAddress } from 'services/product';
import * as productEndpoints from 'services/product/endpoints';

import { handleResponse } from 'common/queries/utils';

const productMemberAddress = {
  url: productEndpoints.GET_PRODUCT_MEMBER_ADDRESS,
  all: ['pim', 'get-member-addresses'],
  one: (productId) => [
    ...productMemberAddress.all,
    { params: { ProductId: productId } },
  ],
};

const keys = {
  productMemberAddress,
};

export const useQueryMemberAddressFromProductId = ({
  productId,
  ...options
} = {}) => {
  return useQuery({
    queryKey: keys.productMemberAddress.one(productId),
    enabled: Boolean(productId),
    ...options,
  });
};

export const useMutateProductLinkedAddress = () => {
  return useMutation({
    mutationFn: async ({ productId, addressId }) => {
      const params = { productId, addressId };

      const response = await editProductLinkedAddress(params);

      return handleResponse({ response });
    },
  });
};
