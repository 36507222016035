import React from 'react';

import { Space, Button, Card, Tooltip } from 'antd';
import { UndoOutlined, RedoOutlined } from '@ant-design/icons';
import { useEditor } from '@craftjs/core';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const EmailEditorToolbar = () => {
  const intl = useIntl();

  const { canUndo, canRedo, actions } = useEditor((state, query) => ({
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));

  const onClickUndoRedo = (type) => () => {
    if (type === 'undo') actions.history.undo();
    if (type === 'redo') actions.history.redo();
  };

  return (
    <Card size='small' className='email-editor__toolbar' bordered={false}>
      <Space>
        <Tooltip title={intl.formatMessage(Messages.undo)}>
          <Button
            className='email-editor__toolbar-undo-redo'
            icon={<UndoOutlined />}
            disabled={!canUndo}
            onClick={onClickUndoRedo('undo')}
          />
        </Tooltip>
        <Tooltip title={intl.formatMessage(Messages.redo)}>
          <Button
            className='email-editor__toolbar-undo-redo'
            icon={<RedoOutlined />}
            disabled={!canRedo}
            onClick={onClickUndoRedo('redo')}
          />
        </Tooltip>
      </Space>
    </Card>
  );
};

export default EmailEditorToolbar;
