import React, { useState, useEffect, useMemo } from 'react';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Input, Select, Row, Col } from 'antd';
import {
  Form,
  StyledModal,
  dialogFunction,
  FormAddButton,
} from 'common/components';
import { useDispatchReloadPage } from 'hooks/useReloadPage';
import { apiHandler } from 'utils/api';
import { useIntl } from 'react-intl';
import { CIC_REASON, CIC_REASON_FREE_FORM } from 'static/Constants';

import * as productServices from 'services/product';
import Messages from 'i18n/messages/home';
import RibbonButton from 'common/components/button/RibbonButton';

import { WrapperSelect } from 'common/components';

import { filterParams, getUniqueReasons, mappingReason } from './utils';

import './index.less';

const { Option } = Select;

const RejectPublication = (props) => {
  const {
    selectionPublication,
    sectionDisabled,
    onRefetchNavigationMenu,
    onClearItemGrid,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [reasons, setReasons] = useState([{ displayText: '' }]);

  const [form] = Form.useForm();
  const reloadPage = useDispatchReloadPage();
  const intl = useIntl();

  const uniqueReasons = useMemo(() => {
    return getUniqueReasons(reasons);
  }, [reasons]);

  const isHiddenAddReason = reasons?.length === CIC_REASON.length;

  useEffect(() => {
    if (!isOpenModal) {
      setReasons([{ displayText: '' }]);
    }
  }, [isOpenModal]);

  const onClickBtn = () => {
    setIsOpenModal(true);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      const mappingValues = mappingReason(values);
      const filteredValues = filterParams(mappingValues);
      callApiAcceptPublication(filteredValues);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsOpenModal(false);
  };

  const callApiAcceptPublication = (values) => {
    const params = {
      id: selectionPublication?.id,
      ...values,
    };

    const successMessage = intl.formatMessage(
      Messages.rejectPublicationSuccess
    );
    const errorMessage = intl.formatMessage(Messages.rejectPublicationError);

    apiHandler({
      service: productServices.rejectPublication,
      params,
      successMessage,
      errorMessage,
      successCallback,
    });
  };
  const successCallback = () => {
    form.resetFields();
    reloadPage();
    onRefetchNavigationMenu();
    onClearItemGrid();
    setIsOpenModal(false);
  };

  const handleRemoveReason = (key) => {
    const newReasons = [...reasons.slice(0, key), ...reasons.slice(key + 1)];
    setReasons(newReasons);
  };

  const checkDisabled = () => {
    const status = selectionPublication?.status;

    const statusDisabled = status && status?.toLowerCase() !== 'pending';

    return sectionDisabled || statusDisabled;
  };

  const handleChangeReason = (value, key) => {
    let foundReason = reasons[key];

    foundReason = {
      ...foundReason,
      displayText: value,
    };

    const newReasons = [
      ...reasons.slice(0, key),
      foundReason,
      ...reasons.slice(key + 1),
    ];

    setReasons(newReasons);
  };

  return (
    <>
      <RibbonButton
        size='small'
        icon={<CloseOutlined />}
        label={Messages.rejectSubscription}
        onClick={onClickBtn}
        disabled={checkDisabled()}
      />

      {isOpenModal && (
        <StyledModal
          title={intl.formatMessage(Messages.rejectGDSNPublication)}
          visible={isOpenModal}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose
        >
          <Form
            name='reject-publication'
            autoComplete='off'
            initialValues={{
              gdsnCicReasons: [{ reasons: null, additionalDescription: '' }],
            }}
            form={form}
            layout='vertical'
            className='review-publication'
          >
            <Form.List name='gdsnCicReasons'>
              {(fields, { add, remove }) => (
                <Row>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <React.Fragment key={key}>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, 'reason']}
                          fieldKey={[fieldKey, 'reason']}
                          rules={[
                            {
                              required: true,
                              message: 'CIC Code is required',
                            },
                          ]}
                          label={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <span>CIC Reason</span>
                              {fields?.length > 1 && (
                                <DeleteOutlined
                                  style={{
                                    fontSize: 17,
                                    paddingTop: 2,
                                    paddingLeft: 5,
                                    color: '#ff4d4f',
                                  }}
                                  onClick={() => {
                                    dialogFunction({
                                      type: 'warn',
                                      content:
                                        'Are you sure want to delete the selecting reason?',
                                      onOk: () => {
                                        remove(name);
                                        handleRemoveReason(name);
                                      },
                                    });
                                  }}
                                />
                              )}
                            </div>
                          }
                        >
                          <WrapperSelect
                            showSearch
                            placeholder='CIC Reason'
                            filterOption={(input, option) =>
                              option.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              handleChangeReason(value, name);
                            }}
                          >
                            {uniqueReasons.map((item, idx) => (
                              <Option
                                key={item.statusCode}
                                value={item.displayText}
                              >
                                {item.displayText}
                              </Option>
                            ))}
                          </WrapperSelect>
                        </Form.Item>

                        {reasons?.[name]?.displayText ===
                          CIC_REASON_FREE_FORM.displayText && (
                          <Form.Item
                            {...restField}
                            label='Additional Description'
                            name={[name, 'additionalDescription']}
                            fieldKey={[fieldKey, 'additionalDescription']}
                          >
                            <Input.TextArea
                              placeholder='Additional Description'
                              rows={4}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </React.Fragment>
                  ))}

                  {isHiddenAddReason ? null : (
                    <Col span={24}>
                      <FormAddButton
                        text={'Add Field'}
                        style={{ borderRadius: 'none' }}
                        onClick={() => {
                          add();
                          setReasons((prevReasons) =>
                            prevReasons.concat({
                              displayText: '',
                            })
                          );
                        }}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </Form.List>
          </Form>
        </StyledModal>
      )}
    </>
  );
};

export default RejectPublication;
