import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';

import { DownloadOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip } from 'antd';

import AgGrid from 'common/components/ag-grid/AgGrid';
import PreviewableModal from 'common/components/previewable-modal/PreviewableModal';
import * as productService from 'services/product';
import * as endpoints from 'services/product/endpoints';

import {
  CustomNotification,
  FormAddButton,
  WithAbsoluteContainer,
  dialogFunction,
} from 'common/components';
import productFullViewReducer from 'pages/product-full-view/controllers/reducer';
import saga from 'pages/product-full-view/controllers/saga';
import { checkShowPdfPreview } from 'utils/checkShowPdfPreview';
import { useInjectSaga } from 'utils/common/injectSaga';
import { useInjectReducer } from 'utils/common/injectedReducers';

import AddAssetPimModal from '../modal/add-asset-pim-modal';

import * as api from 'config/axios';
import * as actionsGlobal from 'redux/global/actions';

import { sleep } from 'utils/delay';

import { apiHandler } from 'utils/api';


import useDocumentAttachEvent from 'hooks/documentAttachEventHook';

import { EVENT } from 'static/Constants';
import { useCheckAllowEditProducts, useGetProductFullView } from 'hooks';

import './ProductDocument.less';

const key = 'productFullView';
const addType = 'Document';

const ProductDocument = (props) => {
  useInjectReducer({ key, reducer: productFullViewReducer });
  useInjectSaga({ key, saga });

  const { getImagery, isAllowEdit } = props;

  const dispatch = useDispatch();

  const { id: productId } = useParams();

  const { productFull } = useGetProductFullView({ productId });

  const [, setGridApi] = useState(null);
  const [rowsSelected, setRowSelected] = useState([]);
  const [isLinkToNewAssetModalOpen, setIsLinkToNewAssetModalOpen] = useState(
    false
  );
  const [reloadGrid, setReloadGrid] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleToggleLinkAssetModal = () => {
    setIsLinkToNewAssetModalOpen((prev) => !prev);
  };

  const [itemSelected] = rowsSelected;

  const { linkPreview, extensionFile } = checkShowPdfPreview(
    itemSelected?.pdfPreview,
    itemSelected?.originalFile,
    itemSelected?.fileType
  );

  const cellRenderAction = (params) => {
    return (
      <>
        <Button
          type='link'
          icon={<EyeOutlined />}
          onClick={() => {
            setRowSelected([params?.data]);
            setVisible(true);
          }}
        />
        <Button
          type='link'
          icon={<DownloadOutlined />}
          onClick={() => {
            const downloadUrl = params?.data?.originalFile;

            if (!downloadUrl) {
              CustomNotification.error('Could not download file!');
            }
            api.sendDownload({
              url: downloadUrl,
            });
          }}
        />
      </>
    );
  };

  const requestParams = { productId };

  const linkAssetsColumns = [
    {
      displayName: 'Actions',
      suppressMenu: true,
      width: 120,
      cellRenderer: cellRenderAction,
    },
    {
      checkboxSelection: true,
      field: '',
      filter: false,
      resizable: false,
      suppressMenu: true,
      width: 50,
    },
    {
      dataType: 'string',
      displayName: 'Asset Name',
      fieldName: 'assetName',
      fieldNameCamelCase: 'assetName',
      flex: 1,
      minWidth: 200,
    },
    {
      dataType: 'string',
      displayName: 'Document Name',
      fieldName: 'documentName',
      fieldNameCamelCase: 'documentName',
    },
    {
      dataType: 'string',
      displayName: 'File Name',
      fieldName: 'fileName',
      fieldNameCamelCase: 'fileName',
      flex: 1,
      minWidth: 150,
    },
    {
      dataType: 'string',
      displayName: 'File Type',
      fieldName: 'fileType',
      fieldNameCamelCase: 'fileType',
      flex: 1,
      minWidth: 150,
    },
    {
      dataType: 'string',
      displayName: 'Type',
      fieldName: 'type',
      fieldNameCamelCase: 'type',
    },
    {
      dataType: 'string',
      displayName: 'Sub Type',
      fieldName: 'subtype',
      fieldNameCamelCase: 'subtype',
    },
    {
      dataType: 'string',
      displayName: 'Status',
      fieldName: 'status',
      fieldNameCamelCase: 'status',
      flex: 1,
      minWidth: 100,
    },
  ];

  const getDocument = async () => {
    setReloadGrid(true);
    await sleep(500);
    setReloadGrid(false);
  };

  useEffect(() => {
    dispatch(actionsGlobal.hideSearchBar());
  }, [dispatch]);

  useDocumentAttachEvent({
    name: EVENT.RELOAD_DOC_GRID_WHEN_ACCEPT_WORKFLOW,
    handler: async () => {
      await getDocument();
    },
  });

  const downloadItem = () => {
    const downloadUrl =
      rowsSelected[0]?.fileUrl || rowsSelected[0]?.originalFile;

    if (!downloadUrl) {
      CustomNotification.error('Could not download file');
    }
    api.sendDownload({
      url: downloadUrl,
    });
  };

  const unlinkItem = async () => {
    const params = {
      linkId: rowsSelected[0].linkId,
      productItemId: productId,
      digitalAssetId: rowsSelected[0].digitalAssetId,
    };

    await apiHandler({
      service: productService.unlinkProductDocument,
      params,
      successMessage: 'Unlink successfully!',
      errorMessage: 'Unlink fail!',
      failCallback: (message) => {
        CustomNotification.error(message);
      },
      successCallback: async () => {
        productService.getQaSpeecDataDocument({ productId });
        await getDocument();
      },
    });
  };

  const showConfirmUnlinkModal = () => {
    dialogFunction({
      type: 'warn',
      content: 'Are you sure you want to unlink this document?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => unlinkItem(),
    });
  };

  const onSetSelectedRows = (selectedNodesDetail) => {
    setRowSelected(selectedNodesDetail);
  };

  const mapId = (item) => ({
    ...item,
    id: `${item?.linkId}`,
  });

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();
  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  const disabledUnlinkDocument =
    !rowsSelected || rowsSelected?.length !== 1 || !isAllowEditProduct;

  return (
    <>
      <div className='product-document'>
        <Row style={{ height: '100%' }}>
          <Col xs={24}>
            <AgGrid
              columnDefs={linkAssetsColumns}
              urlGrid={endpoints.GET_PRODUCT_DOCUMENTS_ENDPOINT}
              responseParams='data'
              isShowItemSelectionLog={false}
              onHandleSelectedRowCustom={onSetSelectedRows}
              getGridApi={(gridApi) => setGridApi(gridApi)}
              requestParams={requestParams}
              reloadGrid={reloadGrid}
              mapId={mapId}
            />

            {isAllowEdit && (
              <FormAddButton
                text={null}
                icon={<PlusOutlined />}
                style={{ position: 'absolute', bottom: 13, right: 9 }}
                onClick={handleToggleLinkAssetModal}
              />
            )}
          </Col>
        </Row>

        <WithAbsoluteContainer>
          <div className='product-marketing-video__btn-document'>
            <Space>
              <Tooltip title='Download lastest selected document'>
                <Button
                  onClick={() => downloadItem()}
                  disabled={!rowsSelected || rowsSelected?.length !== 1}
                >
                  Download
                </Button>
              </Tooltip>
              <Tooltip title='Unlink selected document'>
                <Button
                  onClick={showConfirmUnlinkModal}
                  disabled={disabledUnlinkDocument}
                >
                  Unlink document
                </Button>
              </Tooltip>
            </Space>
          </div>

          {isLinkToNewAssetModalOpen && (
            <AddAssetPimModal
              onCloseModal={handleToggleLinkAssetModal}
              addType={addType}
              getImagery={getImagery}
              getDocument={getDocument}
            />
          )}
        </WithAbsoluteContainer>
      </div>

      {visible && (
        <PreviewableModal
          id={itemSelected?.linkId}
          link={linkPreview}
          title={itemSelected?.fileName}
          visible={visible}
          fileName={itemSelected?.fileName}
          cancelModal={() => setVisible(false)}
          downloadLink={itemSelected?.originalFile}
          isDownload={false}
          fileType={extensionFile}
          fileSize={itemSelected?.fileSize}
          isDocument={true}
        />
      )}
    </>
  );
};

export default ProductDocument;
