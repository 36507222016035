import { v4 as uuidv4 } from 'uuid';
import { $id, checkComponentInside } from 'utils/element';

import {
  COMPONENT_TYPE,
  NEW_IMAGE_DATA,
  NEW_TEXT_DATA,
  NEW_YOUTUBE_DATA,
  NEW_BACKGROUND_DATA,
  CREATE_NEW_STATUS,
  TOLERANCE_SIZE,
} from '../constants';

import { useTogglePageWrapLayer } from '../hooks';

import { getCoverRangeComponent } from 'utils/element';

const useGetComponetEditorHandlers = (
  pageDataHook,
  selectedHook,
  detailShowTargetHook,
  wrapKey,
  scale = 1
) => {
  const [pageData, setPageData] = pageDataHook;
  const [selected, setSelected, clearSelect] = selectedHook;
  const [detailShowTarget, setDetailShowTarget] = detailShowTargetHook;

  const togglePageWrapLayer = useTogglePageWrapLayer(wrapKey);

  const onDeleteComponent = ({ key }) => {
    setPageData((prevPageData) => {
      let nextPageData = { ...prevPageData };

      //* if change data of existed component
      let foundKey = nextPageData.components.find(
        (componentKey) => componentKey === key
      );

      if (foundKey) {
        nextPageData.components = prevPageData.components.filter(
          (componentKey) => componentKey !== key
        );

        delete nextPageData.componentDetails[key];

        //* if remove item selected remove selected
        if (foundKey === selected?.id) {
          clearSelect();
        }

        //* if remove item showing detail => remove show detail keyCode
        if (foundKey === detailShowTarget) {
          setDetailShowTarget(null);
        }
      }

      return nextPageData;
    });
  };

  const onCreateNewComponent = ({ componentType, values }) => {
    const newComponentKey =
      componentType === COMPONENT_TYPE.BACKGROUND
        ? `${wrapKey}_background`
        : `${wrapKey}_${uuidv4()}`;

    const nextValue =
      componentType === COMPONENT_TYPE.IMAGE
        ? { ...NEW_IMAGE_DATA, ...(values || {}) }
        : componentType === COMPONENT_TYPE.TEXT
        ? { ...NEW_TEXT_DATA, ...(values || {}) }
        : componentType === COMPONENT_TYPE.YOUTUBE
        ? { ...NEW_YOUTUBE_DATA, ...(values || {}) }
        : componentType === COMPONENT_TYPE.BACKGROUND
        ? { ...NEW_BACKGROUND_DATA, ...(values || {}) }
        : {};

    setPageData((prev) => {
      return {
        components: [...prev?.components, newComponentKey],
        componentDetails: {
          ...prev?.componentDetails,
          [newComponentKey]: nextValue,
        },
      };
    });
    return newComponentKey;
  };

  const onChangeComponentValue = ({ key, fieldName, value, mapValue }) => {
    setPageData((prevPageData) => {
      let nextPageData = { ...prevPageData };

      //* if change data of existed component
      let foundKey = nextPageData.components.find(
        (componentKey) => componentKey === key
      );

      if (foundKey) {
        let prevComponentData = {
          ...prevPageData.componentDetails[foundKey],
        };

        const updateValue = mapValue ? mapValue(prevComponentData) : value;

        if (fieldName) {
          nextPageData.componentDetails[foundKey] = {
            ...prevComponentData,
            [fieldName]: updateValue,
          };
        } else {
          nextPageData.componentDetails[foundKey] = {
            ...prevComponentData,
            ...updateValue,
          };
        }
      }

      return nextPageData;
    });
  };

  const onDragComponent = ({
    componentData,
    componentKey,
    fieldNameX,
    fieldNameY,
    refWrap,
    refComponent,
    dragPosInst,
  }) => {
    const tempPos = [componentData?.[fieldNameX], componentData?.[fieldNameY]];

    const { offset, size } = getCoverRangeComponent(
      refComponent.current,
      scale
    );

    dragPosInst.current.attach(async (moveX, moveY) => {
      const checkInsideResultArray = checkComponentInside({
        elements: [{ offset, size }],
        elementCover: refWrap.current,
        tolerance: TOLERANCE_SIZE,
        futureMove: {
          x: -moveX / scale,
          y: -moveY / scale,
        },
        scale,
      });

      const { isInside, touchLeft, touchRight, touchTop, touchBottom } =
        checkInsideResultArray?.[0];

      !touchLeft &&
        !touchRight &&
        onChangeComponentValue({
          key: componentKey,
          fieldName: fieldNameX,
          value: tempPos[0] - moveX / scale,
        });

      !touchTop &&
        !touchBottom &&
        onChangeComponentValue({
          key: componentKey,
          fieldName: fieldNameY,
          value: tempPos[1] - moveY / scale,
        });

      !isInside &&
        touchLeft &&
        onChangeComponentValue({
          key: componentKey,
          fieldName: fieldNameX,
          value: TOLERANCE_SIZE * 2,
        });

      !isInside &&
        touchTop &&
        onChangeComponentValue({
          key: componentKey,
          fieldName: fieldNameY,
          value: TOLERANCE_SIZE * 2,
        });

      !isInside &&
        touchBottom &&
        onChangeComponentValue({
          key: componentKey,
          fieldName: fieldNameY,
          value:
            refWrap.current?.offsetHeight - size?.height - TOLERANCE_SIZE * 2,
        });

      !isInside &&
        touchRight &&
        onChangeComponentValue({
          key: componentKey,
          fieldName: fieldNameX,
          value:
            refWrap.current?.offsetWidth - size?.width - TOLERANCE_SIZE * 2,
        });
    });
  };

  const onDragNewComponent = ({
    showDetailComponentData,
    key,
    fieldNameX,
    fieldNameY,
    createStatusHook,
    startDragHook,
    wrapId,
    dragPosInst,
  }) => {
    const [createStatus, setCreateStatus] = createStatusHook;
    const [startDrag, setStartDrag] = startDragHook;

    togglePageWrapLayer(true);

    const componentDataFromShowDetail = Object.values(
      showDetailComponentData
    )?.[0];

    const tempPos = [
      componentDataFromShowDetail?.[fieldNameX],
      componentDataFromShowDetail?.[fieldNameY],
    ];

    const $wrapElement = $id(wrapId);

    dragPosInst.current.attach((moveX, moveY) => {
      const $dragElement = $id(key);
      setCreateStatus(CREATE_NEW_STATUS.DRAGGING);

      if ($dragElement) {
        const isComponentsInsideWrap = checkComponentInside({
          elements: [$dragElement],
          elementCover: $wrapElement,
          scale,
        });

        if (isComponentsInsideWrap?.[0]?.isInside) {
          setCreateStatus(CREATE_NEW_STATUS.ALLOW_CREATE_NEW);
        }
      }

      onChangeComponentValue({
        key,
        fieldName: fieldNameX,
        value: tempPos[0] - moveX / scale,
      });
      onChangeComponentValue({
        key,
        fieldName: fieldNameY,
        value: tempPos[1] - moveY / scale,
      });
    });
    setStartDrag(false);
  };

  return {
    onChangeComponentValue,
    onCreateNewComponent,
    onDeleteComponent,
    onDragNewComponent,
    onDragComponent,
  };
};

export { useGetComponetEditorHandlers };
