import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import { Select } from 'antd';
import { useIsMutating } from '@tanstack/react-query';

import { StyledModal, WrapperSelect } from 'common/components';

import AddNutritionLabel from './AddNutritionLabel';
import AddSupplementLabel from './supplement-label/AddSupplementLabel';

import { useNutritionContext } from '../hook/useNutritionLabel';

import {
  SUPPLEMENT_TYPE,
  NUTRITION_TYPE,
  DRUG_FACTS_TYPE,
  PET_NUTRITION_TYPE,
} from './../../multiple-panel/constants';
import AddDrugFactsLabel from './drug-facts-label/AddDrugFactsLabel';
import PetNutritionLabel from './pet-nutrition-label/PetNutritionLabel';

import { useQueryQaFormulaData } from 'pages/qa-spec/components/qa-formula/queries';
import { getKeyQaSpecDrugFactPanels } from 'pages/qa-spec/components/qa-nutrition-data/hook';
import {
  IXONECertificationWrapper,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import { productFullViewKeys } from 'hooks';

const { Option } = Select;

const AddLabel = forwardRef(
  ({ factsPanel, visible, setVisible, onReset, typeView }, ref) => {
    const addNutritionRef = useRef();
    const addSupplementRef = useRef();
    const addDrugFactsRef = useRef();
    const addPetNutritionRef = useRef();

    const queryClient = useQueryClient();

    const { invalidateQaFormulaData } = useQueryQaFormulaData({
      enabled: false,
    });

    const { id: productId } = useParams();

    const [factType, setFactType] = useState(NUTRITION_TYPE);
    const [isChangedForm, setIsChangedForm] = useState(false);

    const isMutatingAddLabel = useIsMutating({ mutationKey: ['add-label'] });

    const { setIsAddingNew, setIsUpdating, clearEditNutrientState } =
      useNutritionContext();

    const handleOk = async (ixoneIgnore) => {
      if (isNutrition) addNutritionRef.current?.submitForm(ixoneIgnore);

      if (isSupplement) addSupplementRef.current?.submitForm(ixoneIgnore);

      if (isDrugFacts) addDrugFactsRef.current?.submitForm();

      if (isPetNutrition) addPetNutritionRef.current?.submitForm(ixoneIgnore);
    };

    const initAddLabel = async (on) => {
      const panelTypeCollection = {
        [NUTRITION_TYPE]: NUTRITION_TYPE,
        [SUPPLEMENT_TYPE]: SUPPLEMENT_TYPE,
        [DRUG_FACTS_TYPE]: DRUG_FACTS_TYPE,
        [PET_NUTRITION_TYPE]: PET_NUTRITION_TYPE,
      };
      const panelType =
        panelTypeCollection[factsPanel?.factsPanelType?.toLowerCase()];

      setFactType(panelType || NUTRITION_TYPE);

      addNutritionRef?.current?.initForm(on);
      addSupplementRef?.current?.initForm(on);
      addDrugFactsRef?.current?.initForm(on);
      addPetNutritionRef?.current?.initForm(on);
    };

    const handleCancel = () => {
      setVisible(false);
      setIsChangedForm(false);
      clearEditNutrientState(false);
      addNutritionRef.current?.resetForm();
      addSupplementRef.current?.resetForm();
      addDrugFactsRef.current?.resetForm();
      addPetNutritionRef.current?.resetForm();
    };

    const onChangeLabelType = (value) => {
      setFactType(value);

      if (value === NUTRITION_TYPE) addNutritionRef?.current?.initForm('add');

      if (value === SUPPLEMENT_TYPE) addSupplementRef?.current?.initForm('add');

      if (value === DRUG_FACTS_TYPE) addDrugFactsRef?.current?.initForm('add');

      if (value === PET_NUTRITION_TYPE)
        addDrugFactsRef?.current?.initForm('add');
    };

    const onSubmitSuccess = () => {
      invalidateQaFormulaData({ productId });
      queryClient.invalidateQueries({
        queryKey: getKeyQaSpecDrugFactPanels(productId),
      });
      queryClient.invalidateQueries({
        queryKey: productFullViewKeys.itemData(productId),
      });
      setIsChangedForm(false);
      clearEditNutrientState(false);
      setVisible(false);
      onReset();
    };

    const onSubmitFail = () => {
      handleCancel();
    };

    useImperativeHandle(ref, () => {
      return {
        initAddLabel,
      };
    });

    const isNutrition = factType === NUTRITION_TYPE;
    const isSupplement = factType === SUPPLEMENT_TYPE;
    const isDrugFacts = factType === DRUG_FACTS_TYPE;
    const isPetNutrition = factType === PET_NUTRITION_TYPE;

    const modalProps = {
      title: 'Facts Panel',
      visible: visible,
      width: 'max(1200px, 80%)',
      closable: true,
      centered: true,
      maskClosable: false,
      destroyOnClose: true,
      onCancel: handleCancel,
      wrapClassName: 'fact-panels-modal',
      bodyStyle: factType !== NUTRITION_TYPE ? { height: '80vh' } : undefined,
    };

    const showConfirmModal =
      !!factsPanel && factsPanel?.fromTdc && isChangedForm;
    return (
      <div className='nutrition-facts__add'>
        <IXONECertificationWrapper
          showModal={showConfirmModal}
          type={TYPE_VIEW.FACT_PANEL}
          onConfirm={(ixoneIgnore) => {
            handleOk(ixoneIgnore);
          }}
          onCancel={handleCancel}
        >
          {(handleConfirmIXONE) => (
            <StyledModal
              {...modalProps}
              onOk={() => {
                handleConfirmIXONE({
                  originalData: {},
                  changedFields: factType,
                });
              }}
              isLoading={isMutatingAddLabel}
              loadingContent={{ loadingText: 'Loading' }}
            >
              <div style={{ paddingBottom: '10px' }}>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#000',
                    paddingRight: '5px',
                  }}
                >
                  Type
                </span>
                <WrapperSelect
                  value={factType}
                  style={{ width: '20%' }}
                  onChange={onChangeLabelType}
                  disabled={!!factsPanel}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode;
                  }}
                >
                  <Option value={NUTRITION_TYPE}>Nutrition Label</Option>
                  <Option value={SUPPLEMENT_TYPE}>Supplement Label</Option>
                  <Option value={DRUG_FACTS_TYPE}>Drug Facts Label</Option>
                  <Option value={PET_NUTRITION_TYPE}>
                    Pet Nutrition Label
                  </Option>
                </WrapperSelect>
              </div>
              <AddNutritionLabel
                ref={addNutritionRef}
                typeView={typeView}
                visible={factType === NUTRITION_TYPE}
                factsPanel={factsPanel}
                setIsUpdating={setIsUpdating}
                setIsAddingNew={setIsAddingNew}
                setIsChangedForm={setIsChangedForm}
                onSubmitSuccess={onSubmitSuccess}
                onSubmitFail={onSubmitFail}
              />
              <AddSupplementLabel
                ref={addSupplementRef}
                typeView={typeView}
                visible={factType === SUPPLEMENT_TYPE}
                factsPanel={factsPanel}
                setIsChangedForm={setIsChangedForm}
                onSubmitSuccess={onSubmitSuccess}
                onSubmitFail={onSubmitFail}
              />
              <AddDrugFactsLabel
                ref={addDrugFactsRef}
                typeView={typeView}
                visible={factType === DRUG_FACTS_TYPE}
                factsPanel={factsPanel}
                onSubmitSuccess={onSubmitSuccess}
                onSubmitFail={onSubmitFail}
              />

              <PetNutritionLabel
                ref={addPetNutritionRef}
                typeView={typeView}
                visible={factType === PET_NUTRITION_TYPE}
                factsPanel={factsPanel}
                setIsChangedForm={setIsChangedForm}
                onSubmitSuccess={onSubmitSuccess}
                onSubmitFail={onSubmitFail}
              />
            </StyledModal>
          )}
        </IXONECertificationWrapper>
      </div>
    );
  }
);

export default AddLabel;
