import React from 'react';

import {
  TeamOutlined,
  GoldOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';

import { dialogFunction, RibbonSection } from 'common/components';
import RibbonButton from 'common/components/button/RibbonButton';

import { LAYOUT_ADVANCED_SEARCH, REPORTING_VIEW_MODE } from 'static/Constants';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/home';

import './LayoutSection.less';

import { Can } from 'context/Can';
import { ABILITY_ACTION, ABILITY_SUBJECT } from 'static/Permission';

const LayoutSection = (props) => {
  const intl = useIntl();
  const { onClickRibbonBtn, modeView, type } = props;

  const handleChangeLayout = (type) => {
    if (props.type === type) {
      return;
    }

    let confirmMessage = intl.formatMessage(
      Messages.changeViewQueryConfirm[type]
    );

    dialogFunction({
      type: 'warn',
      content: confirmMessage,
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: (close) => handleConfirmChangeLayout(close, type),
    });
  };

  const handleConfirmChangeLayout = (close, type) => {
    onClickRibbonBtn('changeType', { type });
    return close();
  };

  const checkDisabledBtn = (btnType) => {
    return modeView === REPORTING_VIEW_MODE.VIEW && btnType !== type;
  };

  const layoutBtnCollection = [
    {
      type: LAYOUT_ADVANCED_SEARCH.MEMBER,
      icon: <TeamOutlined />,
      ability: 'MEMBER',
    },
    {
      type: LAYOUT_ADVANCED_SEARCH.ASSET,
      icon: <GoldOutlined />,
      ability: 'ASSET',
    },
    {
      type: LAYOUT_ADVANCED_SEARCH.PRODUCT,
      icon: <ShoppingOutlined />,
      ability: 'PRODUCT',
    },
  ];

  return (
    <Can I={ABILITY_ACTION.EDIT} a={ABILITY_SUBJECT.REPORTING}>
      <RibbonSection className='view-layout'>
        {layoutBtnCollection.map((layoutBtn) => {
          return (
            <Can
              key={layoutBtn.type}
              I={ABILITY_ACTION.VIEW}
              a={ABILITY_SUBJECT[layoutBtn.ability]}
            >
              <RibbonButton
                className='view-layout__radio-button'
                icon={layoutBtn.icon}
                label={Messages.viewQuery[layoutBtn.type]}
                onClick={() => handleChangeLayout(layoutBtn.type)}
                toggle={props.type === layoutBtn.type}
                disabled={checkDisabledBtn(layoutBtn.type)}
              />
            </Can>
          );
        })}
      </RibbonSection>
    </Can>
  );
};

export default LayoutSection;
