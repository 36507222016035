import produce from 'immer';
import * as types from './constants';
import {
  mapToWfSelectedStep,
  mapToWfImplSteps,
  mapToWorkflowTasks,
  mapToWfImplInfo,
} from './mapToReducerState';

const initData = {
  data: null,
  loading: false,
  error: null,
};

export const initialState = {
  workflows: {
    loading: false,
    pageIndex: 1,
    pageSize: 20,
    totalItems: 0,
    workflowList: [],
  },
  workflowColumns: [],
  workflowFilters: [],
  wfSelectedStep: initData,
  workflowTasks: initData,
  wfImplInfo: initData,
  wfImplSteps: initData,
  wfCurrentImplStep: null,
  answeredTask: {},
  currentStep: {
    data: null,
    taskList: null,
  },
  isUpdateAcceptStepGrid: false,
  isUpdateApproveStepGrid: false,
};

const memberMaintenanceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // workflow gird
      case types.GET_WORKFLOW_GRID:
        draft.workflows['loading'] = true;
        break;
      case types.GET_WORKFLOW_GRID_SUCCESS:
        draft.workflows['loading'] = false;
        draft.workflows['workflowList'] = action?.data?.gridData || [];
        draft.workflows['pageIndex'] =
          action?.data?.paging?.currentPageIndex ||
          initialState.workflows.pageIndex;
        draft.workflows['pageSize'] =
          action?.data?.paging?.currentPageSize ||
          initialState.workflows.pageSize;
        draft.workflows['totalItems'] =
          action?.data?.paging?.totalRecord ||
          initialState.workflows.totalItems;
        break;
      case types.GET_WORKFLOW_GRID_ERROR:
        draft.workflows['loading'] = false;
        break;
      // workflow gird
      case types.GET_WORKFLOW_GRID_COLUMN:
        draft.workflowColumns = [];
        break;
      case types.GET_WORKFLOW_GRID_COLUMN_SUCCESS:
        draft.workflowColumns = action.columns;
        break;
      case types.GET_WORKFLOW_GRID_COLUMN_ERROR:
        draft.workflowColumns = [];
        break;
      case types.UPDATE_WORKFLOW_FILTER:
        draft.workflowFilters = action.workflowFilters;
        break;

      case types.GET_WORKFLOW_SELECTED_STEP:
      case types.GET_WORKFLOW_SELECTED_STEP_FAIL:
      case types.GET_WORKFLOW_SELECTED_STEP_SUCCESS:
        mapToWfSelectedStep(action, draft);
        break;

      case types.GET_WF_IMPL_INFO:
      case types.GET_WF_IMPL_INFO_FAIL:
      case types.GET_WF_IMPL_INFO_SUCCESS:
        mapToWfImplInfo(action, draft);
        break;

      case types.GET_WF_IMPL_STEPS:
      case types.GET_WF_IMPL_STEPS_SUCCESS:
      case types.GET_WF_IMPL_STEPS_FAIL:
        mapToWfImplSteps(action, draft);
        break;

      case types.GET_WORKFLOW_TASKS_INFO:
      case types.GET_WORKFLOW_TASKS_INFO_SUCCESS:
      case types.GET_WORKFLOW_TASKS_INFO_FAIL:
        mapToWorkflowTasks(action, draft);
        break;

      case types.GET_WF_IMPL_CURRENT_STEP:
        draft.wfCurrentImplStep = action.payload;
        break;

      case types.UPDATE_ANSWERED_TASK:
        draft.answeredTask = action.payload;
        break;

      case types.UPDATE_CURRENT_STEP:
        draft.currentStep.data = state.wfSelectedStep?.data;
        draft.currentStep.taskList = state.workflowTasks?.data;
        break;
      case types.UPDATE_ACCEPT_STEP_GRID:
        draft.isUpdateAcceptStepGrid = action.isUpdateAcceptStepGrid;
        break;
      case types.UPDATE_APPROVE_STEP_GRID:
        draft.isUpdateApproveStepGrid = action.isUpdateApproveStepGrid;
        break;

      default:
        break;
    }
  });

export default memberMaintenanceReducer;
