import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import classnames from 'classnames';

import {
  CollapseView,
  ButtonEditModuleProperties,
  EmptyDataProduct,
} from '../../shared/components';

import DisplayProductInfo from './DisplayProductInfo';

import {
  productDetailActionTypes,
  useProductDetail,
  useProductDetailDispatch,
} from './ProductDetailContext';

import { useCheckAllowEditProducts, useGetProductFullView } from 'hooks';

import {
  getDataProductModule,
  groupProductModuleProperties,
  getProductSchemaHasValidData,
} from './utils';

const ProductAdvancedModules = (props) => {
  const { productSchema, dataProductModules, productEnums, onClickEditBtn } =
    props;

  const { id: productId } = useParams();

  const {
    advancedModule: { activeModules, searchText, isShowOnlyValue },
    publicationError: { moduleNameError },
  } = useProductDetail();

  const dispatch = useProductDetailDispatch();

  const { productFull } = useGetProductFullView({ productId });

  const { checkAllowEditProductFull } = useCheckAllowEditProducts();

  const filteredProductSchema = useMemo(() => {
    if (!isShowOnlyValue) {
      return productSchema;
    } else {
      return getProductSchemaHasValidData(dataProductModules, productSchema);
    }
  }, [isShowOnlyValue, productSchema, dataProductModules]);

  const isEmptyProductSchema = filteredProductSchema?.length === 0;

  if (isEmptyProductSchema) {
    return <EmptyDataProduct />;
  }

  const isAllowEditProduct = checkAllowEditProductFull(productFull);

  return (
    <div className='product-detail-view__list-info scroller'>
      {filteredProductSchema.map((module) => {
        const hasModuleName = activeModules.includes(module.moduleName);
        const isActiveBtnEdit = hasModuleName && isAllowEditProduct;

        const foundModuleName = searchText?.split('.')[0]; // searchText is fieldFullPath of a property
        const isFoundModule =
          foundModuleName?.toLowerCase() === module?.moduleName?.toLowerCase();
        const isErrorPublicationModuleName =
          module.moduleName.toLowerCase() === moduleNameError.toLowerCase();

        return (
          <CollapseView
            activeKey={activeModules}
            key={module.moduleName}
            expandIconPosition='left'
            collapsible='header'
            keyPanel={module.moduleName}
            headerPanel={
              <Typography.Title
                level={5}
                className={classnames({
                  'product-detail-view__list-info-title': true,
                  [`product-detail-view__list-info-title--${module.moduleName}`]: true,
                  'product-detail-view__list-info-title--found-module':
                    isFoundModule,
                  'product-detail-view__list-info-title--publication-error':
                    isErrorPublicationModuleName,
                })}
              >
                {module.moduleDisplayName}
                {isErrorPublicationModuleName && (
                  <CloseCircleOutlined style={{ margin: '0 4px' }} />
                )}
              </Typography.Title>
            }
            extraPanel={() => {
              return (
                <>
                  {isActiveBtnEdit && (
                    <ButtonEditModuleProperties
                      moduleName={module.moduleName}
                      moduleDisplayName={module.moduleDisplayName}
                      onClick={onClickEditBtn}
                    />
                  )}
                </>
              );
            }}
            onChange={(value) => {
              dispatch({
                type: productDetailActionTypes.ACTIVE_MODULES,
                payload: {
                  value,
                  moduleView: 'advanced',
                },
              });
            }}
          >
            <DisplayProductInfo
              moduleProduct={groupProductModuleProperties(module)}
              dataProductModule={getDataProductModule(
                dataProductModules,
                module.moduleName
              )}
              productEnums={productEnums}
            />
          </CollapseView>
        );
      })}
    </div>
  );
};

export default ProductAdvancedModules;
