import React from 'react';

import { Typography, Tooltip } from 'antd';

const { Text } = Typography;

const StringTooltipRender = (params) => {
  const { value } = params;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: 'inherit',
        maxWidth: '100%',
      }}
    >
      <Tooltip title={value} mouseEnterDelay={0.5} mouseLeaveDelay={0}>
        <Text ellipsis>{value && value}</Text>
      </Tooltip>
    </div>
  );
};

export default StringTooltipRender;
