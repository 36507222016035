export const GET_QA_SPEC_WORKFLOW_TO_DO_LIST =
  '/api/qa-spec-workflow/get-todo-list';

export const APPROVE_OR_REJECT_QA_SPEC_WORKFLOW =
  '/api/pim/approve-or-reject-qa-spec-workflow';

export const GET_QA_SPEC_WORKFLOW_GRID = '/api/pim/get-qa-spec-workflow-grid';

export const GET_QA_SPEC_WORKFLOW_GRID_DISTINCT =
  '/api/pim/get-qa-spec-workflow-grid/distinct';

export const SUBMIT_QA_SPEC_WORKFLOW = '/api/pim/submit-qa-spec-workflow';

export const UNLOCK_QA_SPEC_WORKFLOW = '/api/pim/unlock-qa-spec-workflow';

export const EXPORT_QA_WORKFLOW_GRID = '/api/pim/export-qa-spec-workflow-grid';
