import React from 'react';

import { Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { useIntl } from 'react-intl';
import Messages from 'i18n/messages/email-editor';

const { Text } = Typography;

const EmailEditorNoContentShowMessage = () => {
  const intl = useIntl();

  return (
    <Text type='secondary' className='email-editor__section-no-content-show'>
      {intl.formatMessage(Messages.noContentShow)}
      {intl.formatMessage(Messages.pleaseClick)}
      <EyeOutlined /> {intl.formatMessage(Messages.toShowContent)}
    </Text>
  );
};

export default EmailEditorNoContentShowMessage;
