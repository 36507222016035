import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tag, Typography } from 'antd';
import './HelpCenterTag.less';

const { Text } = Typography;

const HelpCenterTagItem = (props) => {
  const { tag, customText, onClickTag, style } = props;

  const [isActive, setIsActive] = useState(false);

  const {
    text,
    color,
    bgColor,
    actColor,
    actBgColor,
    transition,
    fontSize,
  } = tag;

  const setTagActive = () => setIsActive(true);
  const setTagInActive = () => setIsActive(false);

  const mapToTagColor = isActive ? (actColor ? actColor : '') : color;
  const mapToTagBgColor = isActive ? (actBgColor ? actBgColor : '') : bgColor;
  const mapToTransition = isActive
    ? transition
      ? `all ${transition / 1000}s`
      : ''
    : '';

  const tagText =
    customText && typeof customText === 'function' ? customText(text) : text;

  const tagElem = (
    <Tag
      className='help-center-tag'
      style={{
        background: mapToTagBgColor,
        transition: mapToTransition,
        ...style,
      }}
      onMouseEnter={setTagActive}
      onMouseLeave={setTagInActive}
      onClick={() => onClickTag && onClickTag(tagText)}
    >
      <Text
        strong
        style={{
          color: mapToTagColor,
          transition: mapToTransition,
          fontSize,
        }}
      >
        {tagText}
      </Text>
    </Tag>
  );
  return (
    <span key={tag} style={{ display: 'inline-block' }}>
      {tagElem}
    </span>
  );
};

HelpCenterTagItem.propTypes = {
  tag: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  customText: PropTypes.func,
  onClickTag: PropTypes.func,
};

export default HelpCenterTagItem;
