import React, { useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import RibbonButton from 'common/components/button/RibbonButton';
import { UploadOutlined } from '@ant-design/icons';

import { UploadFileModal, WithAbsoluteContainer } from 'common/components';

import * as maintenanceFormActions from 'pages/maintenance-form/controllers/actions';

import * as maintenanceFormEnpoints from 'services/maintenanceForm/endpoints';

import { UPLOAD_MAX_SIZE } from 'static/Constants';

import { FORM_FILE_TYPES } from 'pages/maintenance-form/constant';

/**
 *  ! MAIN COMPONENT
 * @param {*} props
 * @returns
 */
const ReplaceFormBtn = (props) => {
  const { formDetailData, ...restProps } = props;

  const [replaceModalOpen, setReplaceModalOpen] = useState(false);

  const dispatch = useDispatch();

  const onClickReplaceForm = () => {
    toggleReplaceModal();
  };

  const toggleReplaceModal = () => setReplaceModalOpen((prev) => !prev);

  return (
    <>
      <RibbonButton
        icon={<UploadOutlined />}
        label={'Replace Form File'}
        onClick={onClickReplaceForm}
        {...restProps}
      />
      <WithAbsoluteContainer>
        <ReplaceFormModal
          isOpen={replaceModalOpen}
          formDetailData={formDetailData}
          dispatch={dispatch}
          toggleReplaceModal={toggleReplaceModal}
          disabled
        />
      </WithAbsoluteContainer>
    </>
  );
};

const ReplaceFormModal = (props) => {
  const { toggleReplaceModal, formDetailData, isOpen, dispatch } = props;

  const refFile = useRef();

  const getReplaceFormFileResponse = (result) => {
    if (result?.data?.isSuccess) {
      const payload = {
        formId: formDetailData?.formId,
        newFile: refFile.current,
      };
      dispatch(maintenanceFormActions.replaceFormFileSuccess(payload));
      toggleReplaceModal();
    }
  };

  const getFile = (fileList) => {
    refFile.current = fileList?.[0];
  };

  const supportTypes = useMemo(() => {
    if (
      !formDetailData ||
      !formDetailData?.formFormat ||
      typeof formDetailData?.formFormat !== 'string'
    )
      return [];

    const formFormat = formDetailData?.formFormat.toLowerCase();

    return formFormat.includes('doc')
      ? FORM_FILE_TYPES.DOC
      : formFormat.includes('xls')
      ? FORM_FILE_TYPES.XLS
      : formFormat.includes('ppt')
      ? FORM_FILE_TYPES.PPT
      : [];
  }, [formDetailData]);

  return (
    <UploadFileModal
      apiUrl={maintenanceFormEnpoints.REPLACE_FORM_FILE}
      title='Replace Form File'
      isOpen={isOpen}
      onCancel={toggleReplaceModal}
      width={700}
      bodyStyle={{ height: 500 }}
      isUploadMultipleModal={false}
      fileParamName='File'
      showServerMessage
      getResponse={getReplaceFormFileResponse}
      maxSize={UPLOAD_MAX_SIZE.GENERAL}
      additionalBodyPayload={{ formId: formDetailData?.formId }}
      onFileChangeOutside={getFile}
      supportTypes={supportTypes}
    />
  );
};

export default ReplaceFormBtn;
