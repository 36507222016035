import { formatMDY, exportFormDate } from 'utils/formatDate';
import TextRenderSnapshot from 'pages/qa-spec/components/qa-grid-cell/TextRenderSnapshot';
import CellActionRenderSnapshotWrapper from 'pages/qa-spec/components/qa-grid-cell/CellActionRenderSnapshotWrapper';

export const getCellRenderer = ({
  snapshotGridValues,
  useSnapshot,
  CellRenderAction,
}) => {
  return useSnapshot
    ? {
        TextRender: 'TextRenderSnapshot',
        TextRenderForDate: (params) => {
          const { column, rowIndex } = params;

          const cellSnapshotValue =
            snapshotGridValues?.[rowIndex]?.[column.colId];

          const snapshotValueRender = cellSnapshotValue
            ? formatMDY(cellSnapshotValue)
            : 'N/A';

          return (
            <TextRenderSnapshot
              {...params}
              snapshotValueRender={snapshotValueRender}
            />
          );
        },
        CellRenderAction: (params) => {
          return (
            <CellActionRenderSnapshotWrapper {...params}>
              <CellRenderAction {...params} />
            </CellActionRenderSnapshotWrapper>
          );
        },
      }
    : {
        TextRender: 'TextRender',
        TextRenderForDate: 'TextRender',
        CellRenderAction,
      };
};
