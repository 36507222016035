import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Drawer } from 'antd';
import { clone } from 'lodash';

import { GridView } from 'common/components/';
import FolderContentPane from '../FolderContentPane';
// import { MemberPanel } from 'pages/branded-members/components/index';
import ModalAddFolderToAPL from './ModalAddFolderToAPL';

import {
  FolderGridThumbnail,
  FolderGridTile,
} from 'pages/folders/shared/components';

import * as constant from 'static/Constants';
import useCheckIsFolderOwner from 'hooks/useCheckIsFolderOwner';
import { useAgGridFolder } from '../../hooks/useAgGridFolder';

import * as folderEndpoints from 'services/folder/endpoints';

import * as folderActions from '../../controllers/actions';
// import * as folderSelectors from '../../controllers/selectors';
import * as globalActions from 'redux/global/actions';
import * as globalSelectors from 'redux/global/selectors';
import * as actionsGridView from 'common/components/grid-view/controllers/actions';
import selectorsGridView from 'common/components/grid-view/controllers/selectors';

import { injectIntl } from 'react-intl';
import { sortFolderToTop } from 'pages/folders/shared/utils';

const FolderGridView = (props) => {
  const {
    loading,
    dataList,
    totalPagination,
    pageSizePagination,
    pageNumberPagination,
    loadingDetail,
    selectFolder,
    enableDownload,
    disableItemEvent,
    typeView,
    isInModal,
    visibleContentPane,
    hidePagination,
    folderOption,
    isFavoriteRoute,
    queryConditions,
    requestParams,
    shouldReloadDetailGrid,
    enableDragToFolder,
    shouldShowAdvanceFilterTags,
    searchText,
  } = props;
  const dispatch = useDispatch();
  const isShowDetail = useSelector(globalSelectors.selectShowDetail());
  const itemsSelection = useSelector(
    selectorsGridView.makeSelectItemsSelection()
  );
  const isOwner = useCheckIsFolderOwner();
  const isEditable = selectFolder?.editable;
  const [visible, setVisible] = useState(false);
  const [contentPreview, setContentPreview] = useState(null);

  // const queryPreview = useSelector(folderSelectors.selectQueryPreview());
  const {
    gridConfigProps,
    columnDefs,
    shouldReloadAgGrid,
    requestParamsFolder,
    // requestParams: requestParamsFolders,
  } = useAgGridFolder({
    folderOption,
    searchText,
    typeView,
    requestParams,
  });

  useEffect(() => {
    if (!visibleContentPane) {
      document.getElementById('button-clear-table-item') &&
        document.getElementById('button-clear-table-item').click();
    }
  }, [visibleContentPane]);

  useEffect(() => {
    return () => {
      // turn edit mode off when close ed panel
      dispatch(folderActions.turnEditShortContentOn(false));
    };
  }, []);

  const onClose = () => {
    dispatch(globalActions.removeCurrentFolder());
    setVisible(false);
  };

  const clickEditFolderHandler = () => {
    dispatch(folderActions.turnEditShortContentOn(true));
    dispatch(actionsGridView.updateVisible(true));
  };

  useEffect(() => {
    if (contentPreview && contentPreview?.type?.toLowerCase() === 'asset') {
      dispatch(folderActions.getDigitalAssetPreview(contentPreview.id));
    } else if (
      contentPreview &&
      contentPreview?.type?.toLowerCase() === 'member'
    ) {
      dispatch(folderActions.getMemberPreview(contentPreview.id));
      dispatch(folderActions.getPublicContact(contentPreview.id));
    } else if (
      contentPreview &&
      contentPreview?.type?.toLowerCase() === 'query'
    ) {
      dispatch(folderActions.getQueryPreview(contentPreview.id));
    } else if (
      contentPreview &&
      contentPreview?.type?.toLowerCase() === 'reporting'
    ) {
      dispatch(folderActions.getReportingPreview(contentPreview.id));
    } else if (
      contentPreview &&
      contentPreview?.type?.toLowerCase() === 'product'
    ) {
      dispatch(folderActions.getProductPreview(contentPreview.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPreview]);

  useEffect(() => {
    if (visibleContentPane && selectFolder && visible) {
      dispatch(folderActions.getDownloadSetting(selectFolder.id));
    }
  }, [visibleContentPane, selectFolder, visible]);

  useEffect(() => {
    // close download content pane when click edit button
    onClose();
    // eslint-disable-next-line
  }, [visibleContentPane]);

  const closeDetailContentPane = useCallback(() => {
    const shouldHideContentPane =
      (!isShowDetail && visibleContentPane && !isEditable) ||
      itemsSelection?.length !== 1;

    shouldHideContentPane && dispatch(actionsGridView.updateVisible(false));

    // eslint-disable-next-line
  }, [itemsSelection, isEditable]);

  const switchToDetailView = useCallback(() => {
    const shouldSwitchToDetailView =
      !isEditable && itemsSelection?.length === 1;

    shouldSwitchToDetailView &&
      dispatch(folderActions.turnEditShortContentOn(false));
    // eslint-disable-next-line
  }, [itemsSelection, isEditable]);

  useEffect(() => {
    // close detail content pane if needed
    closeDetailContentPane();
    // switch to detail content view if needed
    switchToDetailView();
  }, [closeDetailContentPane, switchToDetailView]);

  const onOpenEdit = () => {
    dispatch(folderActions.turnEditShortContentOn(true));
  };

  const onCloseEdit = () => {
    dispatch(folderActions.turnEditShortContentOn(false));
    // close detail content pane if show detail mode is off
    !isShowDetail && dispatch(actionsGridView.updateVisible(false));
  };

  const onCloseCallbackHandler = () => {
    onCloseEdit();
  };

  const folderContentPaneProps = {
    setContentPreview,
    onCloseCallbackHandler,
    onCloseEdit,
    onOpenEdit,
    loadingDetail,
    selectFolder,
    folderOption,
    isOwner,
    setVisible,
    clickEditFolderHandler,
  };

  const urlGrid = isFavoriteRoute
    ? folderEndpoints.FAVORITE_FOLDER_DETAIL_GRID
    : folderEndpoints.FOLDER_DETAIL_GRID;
  const urlGridDistinct = isFavoriteRoute
    ? folderEndpoints.FAVORITE_FOLDER_DETAIL_GRID_DISTINCT
    : folderEndpoints.FOLDER_DETAIL_GRID_DISTINCT;

  const isApplyDefaultSort = searchText ? false : true;

  return (
    <>
      <Skeleton active={true} loading={loading}>
        <div className='folder-grid-view__grid-wrap'>
          <GridView
            isInModal={isInModal}
            dataList={dataList}
            totalPagination={totalPagination}
            pageSizePagination={pageSizePagination}
            pageNumberPagination={pageNumberPagination}
            thumbnailGridChildren={(configProps) => {
              const newConfigProps = {
                ...configProps,
                folderOption,
                disableEvent: true,
              };
              return <FolderGridThumbnail {...newConfigProps} />;
            }}
            tileGridBody={(configProps) => {
              const { clickEvents } = configProps;
              const newConfigProps = {
                ...clickEvents,
                ...configProps,
                folderOption,
                disableEvent: true,
              };
              return <FolderGridTile {...newConfigProps} />;
            }}
            columnDefs={columnDefs}
            urlGrid={urlGrid}
            urlGridDistinct={urlGridDistinct}
            responseParams='data'
            requestParams={{
              folderOption,
              ...requestParamsFolder,
            }}
            shouldReloadDetailGrid={shouldReloadAgGrid}
            queryConditions={queryConditions}
            hidePagination={hidePagination}
            panelDetail={<FolderContentPane {...folderContentPaneProps} />}
            typeView={typeView}
            type={'Folder'}
            disableItemEvent={disableItemEvent}
            enableDragToFolder={enableDragToFolder}
            notShowHeaderCheckbox={true}
            gridConfigProps={gridConfigProps}
            shouldShowAdvanceFilterTags={shouldShowAdvanceFilterTags}
            applyDefaultSort={isApplyDefaultSort}
            sortDataHandler={sortFolderToTop}
          />
        </div>
      </Skeleton>
      <ModalAddFolderToAPL />
    </>
  );
};

FolderGridView.propTypes = {
  loading: PropTypes.bool,
  dataList: PropTypes.array,
  totalPagination: PropTypes.number,
  pageSizePagination: PropTypes.number,
  pageNumberPagination: PropTypes.number,
  columns: PropTypes.array,
  enableDownload: PropTypes.bool,
  disableItemEvent: PropTypes.bool,
  typeView: PropTypes.string,
  isInModal: PropTypes.bool,
  visibleContentPane: PropTypes.bool,
  hidePagination: PropTypes.bool,
  folderOption: PropTypes.string,
  isFavoriteRoute: PropTypes.bool,
};

FolderGridView.defaultProps = {
  enableDownload: true,
  propTypeView: false,
};

export default injectIntl(FolderGridView);
