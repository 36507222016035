import React from 'react';

import { Row, Col, Form, Typography, Empty } from 'antd';

import { SearchEntityBtn, FormCancelButton } from 'common/components';

import {
  ArrowRightOutlined,
  CloseOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';

import * as productEndpoints from 'services/product/endpoints';

import ThumbnailItem from 'common/components/thumb/ThumbnailItem';

import './SearchProductForm.less';

const { Text } = Typography;

const tagTextStyle = { maxWidth: '100%', display: 'block', color: 'white' };

const SearchProductForm = (props) => {
  const {
    assetId,
    getSelectedProduct,
    selectedProduct,
    refSelectGridItem,
    setSelectedProduct,
    handleMoveToLinkAsset,
    isMultipleSelection,
  } = props;

  const defaultProductParams = {
    digitalAssetId: assetId,
    filters: [],
    pageIndex: 1,
    pageSize: 20,
    search: { searchText: '' },
    sort: [{ fieldName: 'id', isAscending: false }],
  };

  const handleRemoveTag = (itemData) => {
    refSelectGridItem?.current?.removeItem(itemData);
  };

  const handleRemoveProduct = (itemData) => {
    setSelectedProduct((prev) => {
      return prev?.filter((product) => product?.id !== itemData?.id);
    });

    handleRemoveTag(itemData);
  };

  return (
    <div className='search-product-form'>
      <Row>
        <Col xs={24}>
          <Form.Item
            labelCol={{ span: 13 }}
            wrapperCol={{ span: 9 }}
            label='Select Product(s)'
          >
            <SearchEntityBtn
              ref={refSelectGridItem}
              searchType='product'
              apiUrl={productEndpoints.GET_SEARCH_PRODUCT_INTO_ASSET}
              params={defaultProductParams}
              tagRender={({ itemData }) => {
                return (
                  <div className='search-product-form__tag'>
                    <div
                      className='search-product-form__tag-remove'
                      onClick={() => handleRemoveTag(itemData)}
                    >
                      <CloseOutlined />
                    </div>
                    <div className='search-product-form__tag-result'>
                      <div>
                        <Text ellipsis style={tagTextStyle}>
                          Product Name: {itemData?.productName}
                        </Text>
                        <Text ellipsis style={tagTextStyle}>
                          GTIN: {itemData?.gtin || 'n/a'}
                        </Text>
                        <Text ellipsis style={tagTextStyle}>
                          UPC12: {itemData?.upc12 || 'n/a'}
                        </Text>
                      </div>
                    </div>
                  </div>
                );
              }}
              onOk={getSelectedProduct}
              onCancel={getSelectedProduct}
              savedSelected
              isMultiple={isMultipleSelection}
              mapSearch={(searchText) => ({ search: { searchText } })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ flex: 1 }}>
        <Col
          xs={24}
          style={{
            border: 'solid 1px lightgray',
            borderRadius: 2,
            height: 250,
          }}
          className='scroller'
        >
          {selectedProduct?.length > 0 ? (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selectedProduct.map((item) => (
                <did className='list-product-form__thumb'>
                  <div
                    className='list-product-form__thumb-remove'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveProduct(item);
                    }}
                  >
                    <CloseCircleFilled />
                  </div>
                  <div style={{ pointerEvents: 'none' }}>
                    <ThumbnailItem
                      key={item.id}
                      imageThumbnail={item?.thumb || item?.thumbnail}
                      dataDetail={item}
                      isProductThumbnail
                      isShareBtn
                    />
                  </div>
                </did>
              ))}
            </div>
          ) : (
            <div className='search-product-form__empty'>
              <Empty description='No Product Selected' />
            </div>
          )}
        </Col>
      </Row>
      <Row className='search-product-form__control'>
        <Col flex='auto' />
        <Col>
          <FormCancelButton
            icon={<ArrowRightOutlined />}
            text='Move to link step'
            disabled={selectedProduct?.length <= 0}
            onClick={handleMoveToLinkAsset}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SearchProductForm;
