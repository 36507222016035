/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/common/history';
import globalReducer from './redux/global/reducer';
import brandingReducer from './redux/branding/reducer';
import ribbonReducer from './redux/ribbon/reducer';
import userReducer from 'redux/user/reducers';
import chatReducer from 'common/components/message/controller/reducer';
import ssoProductReducer from 'pages/sso-product/controllers/reducer';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
import { LOGOUT, RESET_REDUX_STATE } from 'redux/global/constants';

export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    global: globalReducer,
    branding: brandingReducer,
    ribbon: ribbonReducer,
    chat: chatReducer,
    user: userReducer,
    ssoProduct: ssoProductReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    if (action.type === LOGOUT || action.type === RESET_REDUX_STATE) {
      return appReducer(undefined, action);
    }
    return appReducer(state, action);
  };

  return rootReducer;
}
