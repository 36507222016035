import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

//? COMPONENT
import { Row, Col, Steps } from 'antd';
import { StyledModal, StepForm } from 'common/components';
import AssetGS1Edit from 'pages/asset-full-view/components/add-link-product/AssetGS1Edit';
import SearchProductForm from './SearchProductForm';

//? REDUX
// import * as productFullViewSelectors from 'pages/product-full-view/controllers/selectors';

//? UTILS
import { PIM_ASSET_TYPE_DEFINE } from 'static/Constants';

import * as _ from 'lodash';
import classnames from 'classnames';

import './LinkAssetToPimModal.less';

const currentStepDefine = [0, 1];
const stepDefault = currentStepDefine[0];

const { Step } = Steps;

const LinkAssetToPimModal = (props) => {
  const {
    gs1ImageData,
    addType,
    onCloseModal,
    assetId,
    isAssetImport,
    isAssetImportEdit,
    assetData,
    selectedEditLink,
    onReloadPage,
  } = props;

  const [currentStep, setCurrentStep] = useState(stepDefault);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [PLANO] = PIM_ASSET_TYPE_DEFINE;

  const refSelectGridItem = useRef();

  const { id } = useParams();

  const handleBackToCreateAsset = () => setCurrentStep(0);

  const handleMoveToLinkAsset = () => setCurrentStep(1);

  const getSelectedProduct = (itemList) => {
    refSelectGridItem.current.closeModal();
    setSelectedProduct(itemList);
  };

  /**
   * ? Duy Nguyen - 12/22/2021
   * * we will need to convert this into an array when support link multiple product
   */
  const selectedProductInfo = selectedProduct?.[0];

  //!- RENDER
  const stepDefine = !isAssetImportEdit
    ? [
        {
          step: 0,
          component: (
            <SearchProductForm
              refSelectGridItem={refSelectGridItem}
              assetId={assetId}
              setSelectedProduct={setSelectedProduct}
              getSelectedProduct={getSelectedProduct}
              selectedProduct={selectedProduct}
              handleMoveToLinkAsset={handleMoveToLinkAsset}
              isMultipleSelection
            />
          ),
        },
        {
          step: 1,
          component: (
            <div className='asset-add-pim-modal__gs1-edit'>
              <AssetGS1Edit
                isAssetImportEdit={isAssetImportEdit}
                isAssetImport={isAssetImport}
                pimId={id}
                assetId={assetId}
                isDisabledGtin={true}
                handleBackToCreateAsset={handleBackToCreateAsset}
                isPlanoType={addType === PLANO}
                addType='unknown'
                assetData={assetData}
                productInfo={{
                  ...selectedProductInfo,
                  productId: selectedProductInfo?.id,
                }}
                onReloadPage={() => {
                  onReloadPage && onReloadPage();
                  onCloseModal && onCloseModal();
                }}
                isLinkMultiple
                selectedProduct={selectedProduct}
              />
            </div>
          ),
        },
      ]
    : [
        {
          step: 0,
          component: (
            <div className='asset-add-pim-modal__gs1-edit'>
              <AssetGS1Edit
                gs1ImageData={gs1ImageData}
                pimId={id}
                assetId={assetId}
                isDisabledGtin={true}
                handleBackToCreateAsset={handleBackToCreateAsset}
                isPlanoType={addType === PLANO}
                onReloadPage={() => {
                  onReloadPage && onReloadPage();
                  onCloseModal && onCloseModal();
                }}
                addType='unknown'
                assetData={assetData}
                isAssetImportEdit={isAssetImportEdit}
                isAssetImport={isAssetImport}
                linkInfo={selectedEditLink}
                associationTypeDisabledOnEdit
              />
            </div>
          ),
        },
      ];

  const onlyShowAssetGs1Edit = isAssetImportEdit;

  return (
    <StyledModal
      wrapClassName={classnames('asset-add-pim-modal', {
        'asset-add-pim-modal--edit': onlyShowAssetGs1Edit,
      })}
      title={onlyShowAssetGs1Edit ? `Edit Asset Link` : `Link Asset to Product`}
      onCancel={onCloseModal}
      onOk={() => setCurrentStep((prev) => prev + 1)}
      maskClosable={false}
      footer={null}
      centered
      visible
      destroyOnClose
      width='540px'
      bodyStyle={{ height: 460 }}
    >
      {!onlyShowAssetGs1Edit && (
        <Row style={{ marginBottom: 20 }}>
          <Col xs={24}>
            <Steps current={currentStep}>
              <Step title='Search and Select Product' />
              <Step title='Link Asset to selected Product' />
            </Steps>
          </Col>
        </Row>
      )}
      <Row style={{ flex: 1, overflow: 'hidden' }}>
        <Col xs={24} style={{ height: '100%' }}>
          <StepForm currentStep={currentStep} stepDefine={stepDefine} />
        </Col>
      </Row>
    </StyledModal>
  );
};

LinkAssetToPimModal.propTypes = {
  addType: PropTypes.string,
  onCloseModal: PropTypes.func,
  getImagery: PropTypes.func,
};

export default LinkAssetToPimModal;
