import React from 'react';

import { Typography } from 'antd';

import ImageDownloadOption from './ImageDownloadOption';

import './MarketingPreviewArea.less';

import { AssetPreview } from 'pages/asset-full-view/components';

const { Title } = Typography;

const MarketingPreviewArea = (props) => {
  const { imageInfo } = props;

  const mapImageInfoToAssetData = (_imageInfo) => {
    return {
      ..._imageInfo,
      fileUrl: _imageInfo?.originalFile,
      metadata: { fileType: _imageInfo?.fileType },
      thumbnail:
        _imageInfo?.thumbFull ||
        _imageInfo?.thumb1000 ||
        _imageInfo?.thumb500 ||
        _imageInfo?.thumb300,
    };
  };

  const assetData = mapImageInfoToAssetData(imageInfo);

  return (
    <div className='image-preview-area'>
      <div className='image-preview-area__image-wrapper'>
        {imageInfo ? (
          <AssetPreview assetData={assetData} />
        ) : (
          <Title level={2} className='image-preview-area__no-preview-message'>
            No Image
          </Title>
        )}
      </div>
    </div>
  );
};

export default MarketingPreviewArea;
