import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import assetSelectors from 'pages/home/ribbon/asset-full/controllers/selectors';
import * as actions from 'pages/home/ribbon/asset-full/controllers/actions';

import EditControl from '../../../controls/asset/EditAsset';
import SaveControl from '../../../controls/asset/SaveAsset';
import CancelEdit from '../../../controls/asset/CancelAsset';

import ContainerButton from 'common/components/button/ContainerButton';
import ItemButton from 'common/components/button/ItemButton';
import { forwardTo } from 'utils/common/route';

const EditAsset = () => {
  const dispatch = useDispatch();
  const isEditting = useSelector(assetSelectors.selectAssetEdit());
  const isCreating = useSelector(assetSelectors.selectAssetCreating());
  const canEdit = useSelector(assetSelectors.selectCanEditAsset());

  const cancelAction = () => {
    if (isCreating) {
      forwardTo('/digital-media');
    }
    dispatch(actions.cancelEditAsset());
  };

  if (!isEditting) {
    return (
      <ContainerButton>
        <EditControl
          canEdit={canEdit}
          confirmEdit={() => dispatch(actions.editAsset())}
        />
      </ContainerButton>
    );
  }

  return (
    <ContainerButton>
      <ItemButton>
        <SaveControl confirmEdit={() => dispatch(actions.savingAsset())} />
      </ItemButton>
      <ItemButton>
        <CancelEdit confirmEdit={cancelAction} />
      </ItemButton>
    </ContainerButton>
  );
};

export default EditAsset;
