import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Space, Row, Tooltip } from 'antd';
import { get, capitalize } from 'lodash';

import { formatSizeUnits } from 'utils/formatSizeUnits';
import { entityTypeIcon } from 'utils/entityTypeIcon';

import { FormattedMessage } from 'react-intl';
import Messages from 'i18n/messages/folder';

const { Paragraph, Title, Text } = Typography;

const FolderTileBody = (props) => {
  const { dataDetail } = props;

  const folderName = get(dataDetail, 'folderName', '');
  const folderSize = get(dataDetail, 'folderSize', 0);
  const productCount = get(dataDetail, 'productCount', 0);
  const assetCount = get(dataDetail, 'assetCount', 0);
  const ownerName = get(dataDetail, 'ownerName', '');
  const memberName = get(dataDetail, 'memberName', '');

  const isFolder = dataDetail?.type?.toLowerCase() === 'folder';

  const infoText = [
    {
      field: 'folderName',
      value: folderName,
    },
    {
      field: 'ownerName',
      value: ownerName,
    },
    {
      field: 'memberName',
      value: memberName,
    },
    {
      field: 'folderSize',
      value: folderSize,
    },
    {
      field: 'productCount',
      value: productCount,
    },
    {
      field: 'assetCount',
      value: assetCount,
    },
  ];

  const renderFolderBodyInfo = (infoText) => {
    return infoText.map(({ field, value }, index) => {
      if (field === 'folderName') {
        return (
          <Title key={index} className='folder-tile__body--title'>
            {value}
          </Title>
        );
      } else if (isFolder) {
        if (field === 'folderSize') {
          return (
            <Paragraph
              key={field}
              className={`folder-tile__body--element ${field}`}
            >
              {formatSizeUnits(value)}
            </Paragraph>
          );
        } else if (field === 'productCount' || field === 'assetCount') {
          let text;
          if (field === 'productCount') {
            text =
              value > 0 ? (
                <FormattedMessage {...Messages.products} />
              ) : (
                <FormattedMessage {...Messages.product} />
              );
          } else {
            text =
              value > 0 ? (
                <FormattedMessage {...Messages.assets} />
              ) : (
                <FormattedMessage {...Messages.asset} />
              );
          }
          return value > 0 ? (
            <Paragraph
              key={field}
              className={`folder-tile__body--element ${field}`}
            >
              {value}&nbsp;{text}
            </Paragraph>
          ) : null;
        } else {
          return (
            value && (
              <Paragraph
                key={field}
                className={`folder-tile__body--element ${field}`}
              >
                {value}
              </Paragraph>
            )
          );
        }
      }
    });
  };

  return (
    <div className='folder-tile__body'>
      <Row justify='center' className='folder-tile__body--info-row'>
        <Space direction='vertical' size={0}>
          {renderFolderBodyInfo(infoText)}
        </Space>
      </Row>
      <Row justify='center' style={{ height: 20 }}>
        {!isFolder ? (
          <Tooltip title={capitalize(dataDetail?.type)}>
            <span className='thumbnail-item__type'>
              {entityTypeIcon(dataDetail?.type)}
            </span>
          </Tooltip>
        ) : null}
      </Row>
    </div>
  );
};

FolderTileBody.propTypes = {
  dataDetail: PropTypes.object,
};

export default FolderTileBody;
