import React from 'react';
import { DRAG_DEFINE } from 'static/Constants';

const mapItemDataToDragNamePath = (data) =>
  DRAG_DEFINE.find((item) => item?.type === data?.type)?.namePath;

const mapItemDataToDragIcon = (data) => {
  const renderIcon = (AntIcon) => (
    <div
      style={{
        background: 'white',
        borderRadius: '3px',
        minWidth: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {AntIcon && <AntIcon />}
    </div>
  );

  const icon = DRAG_DEFINE.find((item) => item?.type === data?.type)?.dragIcon;

  return renderIcon(icon);
};

const mapItemDataToDetailLink = (data) => {
  const searchType = DRAG_DEFINE.find((item) => {
    if (item?.type === data?.type) {
      if (item?.advType) {
        if (data?.advType === item?.advType) {
          return true;
        }
      } else {
        return true;
      }
    }
  });
  return searchType?.detailLink;
};

const mapItemDataToAvatarConfirmList = (data) => {
  const searchType = DRAG_DEFINE.find((item) => {
    if (item?.type === data?.type) {
      if (item?.advType) {
        if (data?.advType === item?.advType) {
          return true;
        }
      } else {
        return true;
      }
    }
  });

  if (searchType?.confirmImage.includes('/')) return searchType?.confirmImage;
  return data[searchType?.confirmImage];
};

export {
  mapItemDataToDragIcon,
  mapItemDataToDragNamePath,
  mapItemDataToDetailLink,
  mapItemDataToAvatarConfirmList,
};
