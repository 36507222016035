import React from 'react';

import { Row, Col, Avatar } from 'antd';

const ContactCardContactItem = (props) => {
  const { icon, children, backgroundColor } = props;

  return (
    <Row className='contact-card__contact-item' gutter={8} align='middle'>
      <Col flex={0}>
        <Avatar size={36} icon={icon} style={{ backgroundColor }} />
      </Col>
      <Col flex={1}>{children}</Col>
    </Row>
  );
};

export default ContactCardContactItem;
