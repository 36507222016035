import { Ability, AbilityBuilder } from '@casl/ability';
import {
  PERMISSION,
  WORKFLOW_ROLES,
  ABILITY_ACTION,
  ABILITY_SUBJECT,
} from 'static/Permission';

import * as _ from 'lodash';

import {
  checkIsSharedOnlyMemberUser,
  checkIsSuperAdmin,
  checkIsSupplierMemberUser,
} from 'utils';

export function defineRulesFor(userInfo, navData) {
  const { permissions, isSuperMember, roles } = userInfo;
  const isSuperAdmin = checkIsSuperAdmin(roles);

  const { can, rules, cannot } = new AbilityBuilder();

  _.toArray(ABILITY_ACTION).forEach(
    (action) =>
      action !== ABILITY_ACTION.MANAGE && can(action, ABILITY_SUBJECT.ALL_AUTH)
  );

  getRuleFromPermissionList({
    navData,
    permissions,
    can,
    cannot,
    isSuperMember,
    isSuperAdmin,
    userInfo,
  });

  return rules;
}

export function defineRulesForWorkflow(userInfo, workflowRoles, navData) {
  const { can, rules, cannot } = new AbilityBuilder();
  const { canAccessWorkflow } = navData;

  const isSupplierMemberUser = checkIsSupplierMemberUser(userInfo);
  const isSharedOnLyMemberUser = checkIsSharedOnlyMemberUser(userInfo);

  const { roles, isSuperMember } = userInfo;
  const isSuperAdmin = checkIsSuperAdmin(roles);

  workflowRoles &&
    workflowRoles.length > 0 &&
    workflowRoles.forEach((workflowRole) => {
      switch (workflowRole.roleName) {
        case WORKFLOW_ROLES.APPROVER:
          can(ABILITY_ACTION.APPROVE, ABILITY_SUBJECT.WORKFLOW_STEP);
          break;
        case WORKFLOW_ROLES.PARTICIPANT:
          // TODO: Change ABILITY_ACTION later
          break;
        case WORKFLOW_ROLES.INITIATOR:
          // TODO: Change ABILITY_ACTION later
          break;
        case WORKFLOW_ROLES.CREATOR:
          canAccessWorkflow &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.WORKFLOW_MAINTENANCE);
          canAccessWorkflow &&
            can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_DEFINITION);
          break;
        case WORKFLOW_ROLES.ADMINISTRATOR:
          canAccessWorkflow &&
            can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW);
          canAccessWorkflow &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.WORKFLOW_MAINTENANCE);
          canAccessWorkflow &&
            can(
              ABILITY_ACTION.MAINTAIN,
              ABILITY_SUBJECT.WORKFLOW_ADMINISTRATION
            );
          canAccessWorkflow &&
            can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_DEFINITION);
          break;

        default:
          break;
      }
    });

  if (
    (isSharedOnLyMemberUser && !isSuperMember && !isSuperAdmin) ||
    !canAccessWorkflow
  ) {
    cannot(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW);
    cannot(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.WORKFLOW_MAINTENANCE);
    cannot(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_ADMINISTRATION);
    cannot(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_DEFINITION);
  }

  if (isSuperAdmin) {
    can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW);
    can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.WORKFLOW_MAINTENANCE);
    can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_ADMINISTRATION);
    can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW_DEFINITION);
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.WORKFLOW);
  }

  return rules;
}

/**
 * ! get casl rule from permisison list
 * @param {*} navData
 * @param {*} permissions
 * @param {*} can
 * @param {*} isSuperMember
 * @param {*} userInfo
 */
const getRuleFromPermissionList = ({
  navData,
  permissions,
  can,
  cannot,
  isSuperMember,
  isSuperAdmin,
  userInfo,
}) => {
  const {
    canAccessChat,
    canAccessDigitalAssets,
    canAccessFolders,
    canAccessMember,
    canAccessProducts,
    canAccessReporting,
    canManageHelpSystem,
    canAccessQaSpec,
    canAccessFTP,
    canAccessInterops,
    canAccessSyndication,
    canAccessGdsn,
    canAccessWorkflow,
    canAccessTicketSystem,
    // BP 11/19/2021: remove 3 options
    // 3 options do not have requirements
    /* canAccessThirdPartyAPI,
    canAccessVideoConf,
    canAccessVideoStreaming, */
    // end BP 11/19/2021: remove 3 options

    // canAccessWorkflowAdministration,
    // canAccessWorkflowDefinition,
    // canAccessWorkflowMaintenance,
  } = navData;

  const userMemberType = userInfo?.member?.memberType?.toLowerCase();

  //? default permission
  if (true) {
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SPLASH_DOWNLOAD_HISTORY);
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.MY_QUERY);
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SHARED_QUERY);
  }

  const isSupplierMemberUser = checkIsSupplierMemberUser(userInfo);
  const isSharedOnLyMemberUser = checkIsSharedOnlyMemberUser(userInfo);

  permissions &&
    permissions.length > 0 &&
    permissions.forEach((permission) => {
      switch (permission) {
        case PERMISSION.VIEW_USERS:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.USER);
          break;
        // DAM view
        case PERMISSION.VIEW_ASSETS:
          canAccessDigitalAssets &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.ASSET);
          break;
        case PERMISSION.CREATE_ASSETS:
          canAccessDigitalAssets &&
            can(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.ASSET);
          break;

        case PERMISSION.EDIT_ASSET:
          if (canAccessDigitalAssets) {
            can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.ASSET);

            if (isSuperMember) {
              can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SHARED_ASSET);
            }
          }
          break;

        case PERMISSION.DELETE_ASSETS:
          if (canAccessDigitalAssets) {
            can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.ASSET);

            if (isSuperMember) {
              can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.SHARED_ASSET);
            }
          }

          break;
        // end DAM view

        // PIM view
        case PERMISSION.VIEW_PRODUCTS:
          if (canAccessProducts) {
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.PRODUCT);
            if (!isSharedOnLyMemberUser) {
              can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.PRODUCT_APL);
            }
          }
          break;

        case PERMISSION.EDIT_PRODUCT:
          if (canAccessProducts) {
            can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.PRODUCT);

            if (isSuperMember) {
              can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SHARED_PRODUCT);
            }

            if (isSupplierMemberUser) {
              can(
                ABILITY_ACTION.EDIT,
                ABILITY_SUBJECT.SUPPLIER_ASSIGNED_PRODUCT
              );
            }

            if (isSharedOnLyMemberUser) {
              can(
                ABILITY_ACTION.EDIT,
                ABILITY_SUBJECT.SHARED_ONLY_ASSIGNED_PRODUCT
              );
            }
          }
          break;

        case PERMISSION.CREATE_PRODUCT:
          canAccessProducts &&
            can(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.PRODUCT);
          break;

        case PERMISSION.DELETE_PRODUCTS:
          if (canAccessProducts) {
            can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.PRODUCT);

            if (isSuperMember) {
              can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.SHARED_PRODUCT);
            }
          }
          break;
        // end PIM view

        // My company view
        case PERMISSION.VIEW_FULL_COMPANY_PROFILE:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.COMPANY_PROFILE);
          break;
        // End my company view

        // Reporting view
        case PERMISSION.VIEW_REPORTING:
          canAccessReporting &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.REPORTING);
          break;
        case PERMISSION.CREATE_REPORTING:
          canAccessReporting &&
            can(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.REPORTING);
          break;
        case PERMISSION.EDIT_REPORTING:
          canAccessReporting &&
            can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.REPORTING);
          break;
        case PERMISSION.DELETE_REPORTING:
          canAccessReporting &&
            can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.REPORTING);
          break;
        // end reporting view

        // Folder view
        case PERMISSION.VIEW_FOLDERS:
          canAccessFolders && can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.FOLDER);
          break;
        case PERMISSION.EDIT_FOLDER:
          canAccessFolders && can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.FOLDER);
          break;
        case PERMISSION.CREATE_FOLDER:
          canAccessFolders &&
            can(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.FOLDER);
          canAccessFolders && can(ABILITY_ACTION.COPY, ABILITY_SUBJECT.FOLDER);
          break;
        case PERMISSION.DELETE_FOLDERS:
          canAccessFolders &&
            can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.FOLDER);
          break;
        // end folder view

        case PERMISSION.VIEW_SHARED_ENTITIES:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.SHARED_ENTITY);
          break;
        case PERMISSION.MANAGE_SHARED_ENTITIES:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SHARED_ENTITY);
          break;

        // Help view
        case PERMISSION.VIEW_HELP_CATEGORIES:
          canManageHelpSystem &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.HELP_CATEGORY);
          break;
        case PERMISSION.MANAGE_HELP_CATEGORY:
          canManageHelpSystem &&
            can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.HELP_CATEGORY);
          break;
        case PERMISSION.VIEW_HELP_POSTS:
          canManageHelpSystem &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.HELP_POST);
          break;
        case PERMISSION.MANAGE_HELP_POST:
          canManageHelpSystem &&
            can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.HELP_POST);
          break;
        case PERMISSION.VIEW_ARTICLES:
          canManageHelpSystem &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.ARTICLE);
          break;
        case PERMISSION.MANAGE_ARTICLES:
          canManageHelpSystem &&
            can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.ARTICLE);
          break;
        // end Help view

        case PERMISSION.VIEW_EULAS:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.EULA);
          break;
        case PERMISSION.MANAGE_EULAS:
          can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.EULA);
          break;
        case PERMISSION.VIEW_COMMUNICATION_TEMPLATE:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.COMMUNICATION_TEMPLATE);
          break;
        case PERMISSION.MANAGE_COMMUNICATION_TEMPLATE:
          can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.COMMUNICATION_TEMPLATE);
          break;

        case PERMISSION.EDIT_COMPANY_PROFILE:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.COMPANY_PROFILE);
          break;
        case PERMISSION.VIEW_MEMBER_DEFINED_PROPERTIES:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MEMBER_DEFINED_PROPERTIES);
          break;
        case PERMISSION.EDIT_MEMBER_DEFINED_PROPERTIES:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER_DEFINED_PROPERTIES);
          break;
        case PERMISSION.EDIT_USERS_PERMISSIONS:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.USER_PERMISSIONS);
          break;

        // Members view
        case PERMISSION.VIEW_MEMBERS:
          canAccessMember && can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MEMBER);
          break;
        case PERMISSION.CREATE_MEMBER:
          can(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.MEMBER);
          break;
        case PERMISSION.EDIT_MEMBER:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MEMBER);
          break;
        // end Members view

        case PERMISSION.EXECUTE_COMMUNICATION_TEMPLATE:
          can(ABILITY_ACTION.EXECUTE, ABILITY_SUBJECT.COMMUNICATION_TEMPLATE);
          break;
        case PERMISSION.CREATE_USER:
          can(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.USER);
          break;
        case PERMISSION.EDIT_USER:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.USER);
          break;
        case PERMISSION.DELETE_USERS:
          can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.USER);
          break;
        case PERMISSION.SUPER_ADMIN:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.ALL);
          break;
        case PERMISSION.VIEW_MANAGE_MEMBER:
          // bp 11/19/2021: comment VIEW MEMBER if user has VIEW_MANAGE_MEMBER
          // can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MEMBER);
          //  can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.COMPANY_PROFILE);
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.MANAGE_MEMBER);

          break;
        case PERMISSION.MANAGE_BAN_LIST:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.BAN_LIST);
          break;
        case PERMISSION.CHAT_CALL_VIDEO_VIA_COMPANY_ACCOUNT:
          if (userInfo?.receiveCompanyCommunications)
            canAccessChat &&
              can(ABILITY_ACTION.CHAT, ABILITY_SUBJECT.CHAT_COMPANY);
          break;
        case PERMISSION.CHAT_CALL_VIDEO_VIA_PERSONAL_ACCOUNT:
          canAccessChat &&
            can(ABILITY_ACTION.CHAT, ABILITY_SUBJECT.CHAT_PERSONAL);
          break;

        case PERMISSION.MANAGE_CURATED_QUERY:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.CURATED_QUERY);
          break;
        case PERMISSION.MANAGE_PIM_MAPPING:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.PIM_MAPPING);
          break;

        case PERMISSION.VIEW_NEW_ITEM_FORMS:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.NEW_ITEM_FORMS);
          break;
        case PERMISSION.IMPERSONATE_PERMISSION:
          can(ABILITY_ACTION.IMPERSONATE, ABILITY_SUBJECT.USER);
          break;
        case PERMISSION.VIEW_QA_SPECIFICATION:
          canAccessQaSpec &&
            can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.QA_SPECIFICATION);
          break;
        case PERMISSION.EDIT_QA_SPECIFICATION:
          canAccessQaSpec &&
            can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.QA_SPECIFICATION);
          break;

        case PERMISSION.VIEW_EVALUATION_FORM:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.EVALUATION_FORM);
          break;
        case PERMISSION.EDIT_EVALUATION_FORM:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.EVALUATION_FORM);
          break;

        case PERMISSION.MANAGE_MEMBER_CREDIT_CARD:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.CREDIT_CARD);
          break;
        case PERMISSION.PAY_WITH_MEMBER_CREDIT_CARD:
          can(ABILITY_ACTION.PAY, ABILITY_SUBJECT.CREDIT_CARD);
          break;
        case PERMISSION.MANAGE_BILLABLE_TRACKING:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.BILLABLE_TRACKING);
          break;
        case PERMISSION.EDIT_BRAND:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.BRAND);
          break;
        case PERMISSION.MANAGE_MEMBER_CATEGORIES:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.MEMBER_CATEGORIES);
          break;
        case PERMISSION.ASSIGN_PRODUCT_CATEGORY:
          can(ABILITY_ACTION.ASSIGN, ABILITY_SUBJECT.PRODUCT_CATEGORY);
          break;
        // TICKET SYSTEM
        case PERMISSION.VIEW_TICKET:
          can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.TICKET_SYSTEM);
          break;
        case PERMISSION.CREATE_TICKET:
          can(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.TICKET_SYSTEM);
          break;
        case PERMISSION.EDIT_TICKET:
          can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.TICKET_SYSTEM);
          break;
        case PERMISSION.DELETE_TICKET:
          can(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.TICKET_SYSTEM);
          break;
        case PERMISSION.MANAGE_TICKET_GROUP:
          can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.TICKET_SYSTEM_GROUP);
          break;
        case PERMISSION.MANAGE_QA_SPEC_WORKFLOW:
          canAccessWorkflow &&
            can(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.QA_SPEC_WORKFLOW);
          break;
        default:
          break;
      }
    });

  if (canAccessGdsn) {
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.GDSN);
    can(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.RECIPIENTS_FIELDS);
  }

  if (userMemberType === 'retailer' || userMemberType === 'distributor') {
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.GDSN_RECEIVED_PRODUCT);
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.MY_SUBSCRIPTIONS);
  }

  if (canAccessSyndication) {
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SYNDICATION);
  }

  if (canAccessWorkflow) {
    can(ABILITY_ACTION.MAINTAIN, ABILITY_SUBJECT.WORKFLOW);
    can(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.WORKFLOW);
  }

  if (!canAccessTicketSystem && !isSuperMember && !isSuperAdmin) {
    cannot(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.TICKET_SYSTEM);
    cannot(ABILITY_ACTION.CREATE, ABILITY_SUBJECT.TICKET_SYSTEM);
    cannot(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.TICKET_SYSTEM);
    cannot(ABILITY_ACTION.DELETE, ABILITY_SUBJECT.TICKET_SYSTEM);
    cannot(ABILITY_ACTION.MANAGE, ABILITY_SUBJECT.TICKET_SYSTEM_GROUP);
  }

  if (isSharedOnLyMemberUser && !isSuperMember && !isSuperAdmin) {
    cannot(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.RECIPIENTS_FIELDS);
    cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SPLASH_DOWNLOAD_HISTORY);
    cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.GDSN);
    cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.MY_QUERY);
    cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SHARED_QUERY);
  }

  // if (isSupplierMemberUser && !isSuperMember && !isSuperAdmin) {
  //   // cannot(ABILITY_ACTION.VIEW, ABILITY_SUBJECT.RECIPIENTS_FIELDS);
  //   // cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SPLASH_DOWNLOAD_HISTORY);
  //   // cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.GDSN);
  //   // cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.MY_QUERY);
  //   // cannot(ABILITY_ACTION.ACCESS, ABILITY_SUBJECT.SHARED_QUERY);
  // }
};

/**
 * Defines how to detect object's type
 * @param {*} item
 */
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }

  return item.__type;
}

const ability = new Ability([], { subjectName });

export default ability;
