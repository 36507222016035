import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Popover, notification } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, StyledModal } from 'common/components';
import { supportPhoneFormats, REGEX_CONTACT_PHONE } from 'static/Constants';
import { toggleReloadGrid } from 'pages/product-apl-detail/controllers/actions';
import * as aplServices from 'services/aplDetail';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const ProductContactModal = (props) => {
  const { productList, form, isLoading, setIsLoading, visible, onCancel } =
    props;
  const dispatch = useDispatch();
  const aplDetailId = productList[0]?.id;
  useEffect(() => {
    if (aplDetailId) {
      form.setFieldsValue({
        category: productList[0]?.category,
        contactName: productList[0]?.contactName,
        contactEmail: productList[0]?.contactEmail,
        contactPhone: productList[0]?.contactPhone,
      });
    }
  }, [aplDetailId]);

  const editAplProductContact = async () => {
    form.validateFields().then((values) => {
      const payload = {
        aplProductId: aplDetailId,
        ...values,
      };
      setIsLoading(true);
      aplServices
        .editAplProductContactService(payload)
        .then(async (response) => {
          const { isSuccess, message } = response;

          if (isSuccess) {
            notification.success({
              message: 'Edit successfully!',
            });

            setIsLoading(false);
            dispatch(toggleReloadGrid(true));
            onCancel();
          } else {
            setIsLoading(false);
            notification.error({
              message: message || 'Something went wrong!',
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          notification.error({
            message: 'Something went wrong!',
          });
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(toggleReloadGrid(false));
          }, 200);
        });
    });
  };

  const modalProps = {
    title: 'Edit Contact',
    visible: visible,
    onCancel: onCancel,
    onOk: editAplProductContact,
    confirmLoading: !!isLoading,
  };

  return (
    <StyledModal {...modalProps}>
      <Form {...layout} form={form}>
        <Form.Item name='category' label='Category'>
          <Input placeholder='Input category' />
        </Form.Item>
        <Form.Item name='contactName' label='Contact Name'>
          <Input placeholder='Input Contact Name' />
        </Form.Item>
        <Form.Item
          name='contactEmail'
          label='Contact Email'
          rules={[
            {
              type: 'email',
              message: 'Invalid email address',
            },
          ]}
        >
          <Input placeholder='Input Contact Email' />
        </Form.Item>
        <Form.Item
          name='contactPhone'
          label={
            <div>
              Contact Phone
            </div>
          }
        >
          <Input placeholder='Contact Phone Number' />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default ProductContactModal;
