import React from 'react';
import PropTypes from 'prop-types';
import { DigitalAssetTypeImage } from 'static/FileType';
import { DownloadMethodImage } from 'static/DownloadMethod';
import { LoadingOutlined } from '@ant-design/icons';
import unknownType from 'assets/file-formats/unknown.jpg';

import './TypePicker.less';

const TypePicker = (props) => {
  const { group, type, loading } = props;
  let optionList;
  if (group === 'file-type') {
    optionList = DigitalAssetTypeImage;
  } else if (group === 'download-method') {
    optionList = DownloadMethodImage;
  } else optionList = [];
  const image =
    optionList.filter((image) => image.fileType === type?.toLowerCase())[0]
      ?.fileImg || unknownType;

  return (
    <>
      {loading ? (
        <LoadingOutlined className='type-picker__loader' />
      ) : (
        <img src={image} alt={type} style={{ width: '100%' }} />
      )}
    </>
  );
};

TypePicker.propsTypes = {
  group: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

export default TypePicker;
