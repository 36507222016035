import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as transitionPageServices from 'services/transition-page';

import { CustomNotification } from 'common/components';

import history from 'utils/common/history';

import * as types from './constants';
import * as actions from './actions';

import { ROUTE } from 'static/Constants';

import { sleep } from 'utils/delay';

function* getTransitionPageDetailSaga(action) {
  const params = action.payload;
  try {
    const response = yield call(
      transitionPageServices.getTransitionPageFullDetail,
      params
    );

    const { isSuccess, message, data } = response;

    if (isSuccess) {
      yield put(actions.getTransitionPageDetailSuccess(data));
    } else {
      yield put(actions.getTransitionPageDetailFail(message));
    }
  } catch (error) {
    yield put(actions.getTransitionPageDetailFail(error));
  }
}

function* saveTransitionPageSaga(action) {
  const params = action.payload;

  const submitTransitionPageId = params?.transitionPageId;

  try {
    const response = yield call(
      transitionPageServices.saveTransitionPage,
      params
    );

    const { isSuccess, message, data } = response;

    if (isSuccess) {
      const savePageId = data?.transitionPageId;

      yield call(sleep, 1000);
      if (submitTransitionPageId) {
        yield call(
          CustomNotification.success,
          'Successfully save transition page'
        );
        yield put(
          actions.getTransitionPageDetail({ transitionPageId: savePageId })
        );
      } else {
        const nextRoute = ROUTE.TRANSITION_PAGE_MANAGE + `/${savePageId}`;
        yield call(
          CustomNotification.success,
          'Successfully create transition page'
        );
        yield call(history.replace, nextRoute);
      }

      yield put(actions.saveTransitionPageSuccess());
    } else {
      yield put(actions.saveTransitionPageFail(message));
      yield call(CustomNotification.error, 'Fail to save transition page');
    }
  } catch (error) {
    yield put(actions.saveTransitionPageFail(error));
  }
}

function* watchAll() {
  yield all([
    takeLatest(types.GET_TRANSITION_PAGE_DETAIL, getTransitionPageDetailSaga),
    takeLatest(types.SAVE_TRANSITION_PAGE, saveTransitionPageSaga),
  ]);
}

export default watchAll;
