import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Menu, Space, Typography } from 'antd';

import {
  FormAddButton as AddButton,
  CarouselSection,
  CustomNotification,
  FormDeleteButton as DeleteButton,
  FormEditButton as EditButton,
  WithLoading,
  dialogFunction,
  useCarousel,
} from 'common/components';

import { AllergenGroupHeader, AllergenGroupSection } from './AllergenGroup';

import { AllergenGroupAdd } from './AllergenGroupAdd';
import { AllergenGroupEdit } from './AllergenGroupEdit';

import {
  GroupActions,
  MenuItem,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/components';

import {
  QaGroupAddition,
  QaGroupAssign,
  QaGroupDeletion,
  QaGroupEdit,
  QaGroupPreview,
} from '../group-data';

import { GroupLayoutProvider } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/GroupLayoutProvider';

import {
  TYPE_ALLERGEN_ACTIONS,
  useGetSpecAllergens,
  useGetSpecAllergensSnapshot,
  useSaveAllergenGroup,
  useVisibleAllergenModal,
} from './hooks';

import {
  TYPE_GROUPS,
  useGetAllGroupProduct,
  useVisibleGroupModal,
} from 'pages/product-full-view/components/product-media-area/shared/hooks';

import { useCheckAllowManageGroupData } from 'hooks';

import { filterProductGroups } from 'pages/product-full-view/components/product-media-area/multiple-panel/tab-allergens/utils';
import { getSelectedItemGroup } from 'pages/product-full-view/components/product-media-area/shared/utils';
import { getAdditionalAllergenGroups } from '../group-data/utils';

import { useCheckQaSpecLocked } from 'pages/qa-spec/hooks/useCheckQaSpecLocked';
import {
  useCheckAllowEdit,
  useCheckSnapshotForRetailer,
} from 'pages/qa-spec/hooks';

import './QaAllergen.less';
import {
  IXONECertificationWrapper,
  IXONEShieldWarning,
  TYPE_VIEW,
} from 'common/components/product-add/IXONECertificationWrapper';
import useUpdateIXONEShield from 'common/components/product-add/useUpdateIXONEShield';

const MAX_SLIDE_TO_SHOW = 1;

const QaAllergen = () => {
  const { id: productId } = useParams();

  const [idxCurrentSlide, setIdxCurrentSlide] = useState(null);

  const { carouselRef, onPrevCarousel, onNextCarousel } = useCarousel();

  const { qaAllergenList, isLoading } = useGetSpecAllergens(productId);
  const { handleUpdateIXONEShield } = useUpdateIXONEShield();

  const { allergenStatement, allergensModel = [] } = qaAllergenList ?? {};

  const { productGroups } = useGetAllGroupProduct(productId);

  const { isLoading: isLoadingSnapshot } = useGetSpecAllergensSnapshot();

  const isToggleSnapshot = useCheckSnapshotForRetailer();

  const defaultIdxCurrentSlide = idxCurrentSlide ?? 0;

  const selectedAllergenGroupIds =
    allergensModel?.[defaultIdxCurrentSlide]?.groupIds ?? [];

  const selectedAllergensModel = allergensModel?.[defaultIdxCurrentSlide] ?? {};

  const { defaultGroup, selectedProductGroups } = getSelectedItemGroup(
    selectedAllergenGroupIds,
    productGroups
  );
  const filteredProductGroups = filterProductGroups(
    selectedAllergenGroupIds,
    productGroups
  );

  const allergenMutation = useSaveAllergenGroup(productId);

  const {
    visibleAddAllergen,
    visibleEditAllergen,
    handler: { onAllergenModal, offAllergenModal },
  } = useVisibleAllergenModal();

  const { isQASpecLocked } = useCheckQaSpecLocked();

  /* Group Actions */
  const {
    visibleModalAddGroup,
    visibleModalEditGroup,
    visibleModalPreviewGroup,
    visibleModalAssignGroup,
    visibleModalDeleteGroup,
    handler: { onGroupModal, offGroupModal },
  } = useVisibleGroupModal();

  const hasManageGroupPermission = useCheckAllowManageGroupData();

  /* End Groups  */

  const handleDeleteAllergen = (ixoneIgnore) => {
    dialogFunction({
      type: 'warn',
      content: (
        <IXONEShieldWarning text='Allergen' ixoneIgnore={ixoneIgnore}>
          Are you sure you want to delete current Allergen?
        </IXONEShieldWarning>
      ),
      onOk: async () => {
        let newAllergensModel = [...allergensModel];
        if (idxCurrentSlide === null) {
          newAllergensModel[0] = {
            ...newAllergensModel[0],
            isRemoved: true,
          };
        } else {
          newAllergensModel[idxCurrentSlide] = {
            ...newAllergensModel[idxCurrentSlide],
            isRemoved: true,
          };
        }

        const params = {
          productId,
          allergenStatement,
          allergensModel: newAllergensModel,
        };

        await allergenMutation.mutateAsync(params, {
          onSuccess: async (response) => {
            if (response?.isSuccess) {
              CustomNotification.success('Delete the Allergen successfully!');
              await handleUpdateIXONEShield(ixoneIgnore);
            }
          },
        });
      },
    });
  };

  const totalAllergens = allergensModel?.length ?? 0;
  const disabledBtnPrevious =
    allergensModel?.length <= MAX_SLIDE_TO_SHOW || defaultIdxCurrentSlide === 0;
  const disabledBtnNext =
    allergensModel?.length <= MAX_SLIDE_TO_SHOW ||
    defaultIdxCurrentSlide === totalAllergens - 1;

  const isLoadingAllergen = isToggleSnapshot ? isLoadingSnapshot : isLoading;

  const isAllowEditProduct = useCheckAllowEdit();

  return (
    <WithLoading loading={isLoadingAllergen}>
      <AllergenGroupHeader
        // title={null} // New products should have no allergens by default, but the response is displaying a value. Showing 1 allergen as the default is incorrect; hiding the total is the correct approach.
        title={
          <Typography.Text strong>
            Total: {totalAllergens} Allergen(s)
          </Typography.Text>
        }
        disabledNext={disabledBtnNext}
        disabledPrevious={disabledBtnPrevious}
        onNextCarousel={onNextCarousel}
        onPrevCarousel={onPrevCarousel}
      >
        {!isQASpecLocked && isAllowEditProduct ? (
          <Space>
            <AddButton
              onClick={() => onAllergenModal(TYPE_ALLERGEN_ACTIONS.addAllergen)}
            />
            <EditButton
              onClick={() =>
                onAllergenModal(TYPE_ALLERGEN_ACTIONS.editAllergen)
              }
              disabled={totalAllergens === 0}
            />
            <IXONECertificationWrapper
              showModal
              type={TYPE_VIEW.ALLERGENS}
              onConfirm={(ixoneIgnore) => {
                handleDeleteAllergen(ixoneIgnore);
              }}
            >
              {(handleConfirmIXONE) => (
                <DeleteButton
                  onClick={() => {
                    handleConfirmIXONE({
                      originalData: {},
                      changedFields: true,
                    });
                  }}
                  disabled={allergensModel?.length === 1}
                />
              )}
            </IXONECertificationWrapper>

            <GroupActions
              overlay={() => (
                <Space>
                  <Menu>
                    <MenuItem>
                      <MenuItem.AddGroup
                        disabled={!hasManageGroupPermission}
                        onClick={() => onGroupModal(TYPE_GROUPS.addGroup)}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.PreviewGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.previewGroup)}
                        disabled={selectedProductGroups?.length === 0}
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.AssignGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.assignGroup)}
                        disabled={
                          filteredProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.EditGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.editGroup)}
                        disabled={
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                      />
                    </MenuItem>
                    <MenuItem>
                      <MenuItem.DeleteGroup
                        onClick={() => onGroupModal(TYPE_GROUPS.deleteGroup)}
                        disabled={
                          selectedProductGroups?.length === 0 ||
                          !hasManageGroupPermission
                        }
                      />
                    </MenuItem>
                  </Menu>
                </Space>
              )}
            >
              <GroupActions.ButtonWrapper />
            </GroupActions>
          </Space>
        ) : null}
      </AllergenGroupHeader>
      <CarouselSection
        lazyLoad
        fade
        ref={carouselRef}
        infinite={false}
        beforeChange={(_, next) => setIdxCurrentSlide(next)}
        className='qa-allergen__carousel'
      >
        {allergensModel?.map((item) => (
          <AllergenGroupSection
            key={item?.index}
            productId={productId}
            idxCurrentSlide={idxCurrentSlide ?? item?.index} // default index
            allergenStatement={allergenStatement}
            allergenModel={
              allergensModel[idxCurrentSlide] ?? allergensModel[item?.index]
            }
          />
        ))}
      </CarouselSection>

      {visibleAddAllergen && (
        <AllergenGroupAdd
          title='Create Allergen'
          visible={visibleAddAllergen}
          onCancel={offAllergenModal}
          productId={productId}
          allergensModel={allergensModel}
          allergenStatement={allergenStatement}
        />
      )}

      {visibleEditAllergen && (
        <AllergenGroupEdit
          title='Edit Allergen'
          visible={visibleEditAllergen}
          onCancel={offAllergenModal}
          productId={productId}
          allergenStatement={allergenStatement}
          selectedIdx={idxCurrentSlide ?? 0} // default
          allergensModel={allergensModel}
        />
      )}

      {/* Group Data */}

      {visibleModalAddGroup && (
        <QaGroupAddition
          visible={visibleModalAddGroup}
          onCancel={offGroupModal}
          productId={productId}
        />
      )}

      {visibleModalEditGroup && (
        <GroupLayoutProvider>
          <QaGroupEdit
            visible={visibleModalEditGroup}
            onCancel={offGroupModal}
            defaultGroupId={defaultGroup?.groupId}
            productGroups={selectedProductGroups}
            productId={productId}
          />
        </GroupLayoutProvider>
      )}

      {visibleModalPreviewGroup && (
        <QaGroupPreview
          visible={visibleModalPreviewGroup}
          onCancel={offGroupModal}
          defaultGroupId={defaultGroup?.groupId}
          productGroups={selectedProductGroups}
          productId={productId}
        />
      )}

      {visibleModalAssignGroup && (
        <QaGroupAssign
          visible={visibleModalAssignGroup}
          onCancel={offGroupModal}
          defaultGroupId={filteredProductGroups?.[0]?.groupId}
          productGroups={filteredProductGroups}
          additionalAllergenGroups={[
            getAdditionalAllergenGroups(selectedAllergensModel),
          ]}
          selectedGroupIds={selectedAllergenGroupIds}
          productId={productId}
        />
      )}

      {visibleModalDeleteGroup && (
        <QaGroupDeletion
          visible={visibleModalDeleteGroup}
          onCancel={offGroupModal}
          productGroups={selectedProductGroups}
          productId={productId}
        />
      )}

      {/* End Group Data */}
    </WithLoading>
  );
};

export default QaAllergen;
