import { defineMessages } from 'react-intl';

export const scope = 'Taco.user-profile';
export default defineMessages({
  changePassword: {
    id: `${scope}.components.modal.changePassword`,
    defaultMessage: 'Change password',
  },
  cancel: {
    id: `${scope}.components.modal.cancel`,
    defaultMessage: 'Cancel',
  },
  currentPassword: {
    id: `${scope}.components.form.currentPassword`,
    defaultMessage: 'Current password',
  },
  newPassword: {
    id: `${scope}.components.form.newPassword`,
    defaultMessage: 'New password',
  },
  confirmedPassword: {
    id: `${scope}.components.form.confirmedPassword`,
    defaultMessage: 'Re-type new password',
  },
  passwordNotMatch: {
    id: `${scope}.components.form.passwordNotMatch`,
    defaultMessage: 'Passwords do not match!',
  },
  passwordNotComplex: {
    id: `${scope}.components.form.passwordNotComplex`,
    defaultMessage: 'Password is not sufficiently complex!',
  },
  passwordContain: {
    id: `${scope}.components.form.passwordContain`,
    defaultMessage: 'Only accept following special characters: !@#$%^&*',
  },
  yourPasswordMust: {
    id: `${scope}.components.form.yourPasswordMust`,
    defaultMessage: 'Your password must have:',
  },
  inputYourCurrentPassword: {
    id: `${scope}.components.form.inputYourCurrentPassword`,
    defaultMessage: 'Please input your current password!',
  },
  inputYourNewPassword: {
    id: `${scope}.components.form.inputYourNewPassword`,
    defaultMessage: 'Please input your new password!',
  },
  inputYourConfirmedPassword: {
    id: `${scope}.components.form.inputYourConfirmedPassword`,
    defaultMessage: 'Please input your confirmed password!',
  },
  changePasswordSuccess: {
    id: `${scope}.components.form.changePasswordSuccess`,
    defaultMessage: 'Your password has been changed successfully!',
  },
  passwordShouldNotSame: {
    id: `${scope}.components.form.passwordShouldNotSame`,
    defaultMessage: 'Current and new password should not be same!',
  },
  companyName: {
    id: `${scope}.components.overview.companyName`,
    defaultMessage: 'Company Name',
  },
  userName: {
    id: `${scope}.components.overview.userName`,
    defaultMessage: 'User Name',
  },
  lastActivity: {
    id: `${scope}.components.overview.lastActivity`,
    defaultMessage: 'Last Activity',
  },
  searchCategories: {
    id: `${scope}.components.overview.searchCategories`,
    defaultMessage: 'Search Categories',
  },
  receiveCompanyCommunications: {
    id: `${scope}.components.overview.receiveCompanyCommunications`,
    defaultMessage: 'Receive Company Communications',
  },
  status: {
    id: `${scope}.components.overview.status`,
    defaultMessage: 'Status',
  },
  availableForContact: {
    id: `${scope}.components.overview.availableForContact`,
    defaultMessage: 'Available for Contact',
  },
  optOut: {
    id: `${scope}.components.overview.optOut`,
    defaultMessage: 'Opt Out',
  },
  enableMFA: {
    id: `${scope}.components.overview.enableMFA`,
    defaultMessage: 'Enable MFA',
  },
  assetDownloadPreferences: {
    id: `${scope}.components.overview.assetDownloadPreferences`,
    defaultMessage: 'Asset Download Preferences',
  },
  created: {
    id: `${scope}.components.overview.created`,
    defaultMessage: 'Date Created',
  },
  landingPage: {
    id: `${scope}.components.overview.landingPage`,
    defaultMessage: 'Landing Page',
  },
  title: {
    id: `${scope}.components.overview.title`,
    defaultMessage: 'Title',
  },
  systemTheme: {
    id: `${scope}.components.overview.systemTheme`,
    defaultMessage: 'System Theme',
  },
  position: {
    id: `${scope}.components.overview.position`,
    defaultMessage: 'Position',
  },
  accountExpires: {
    id: `${scope}.components.overview.accountExpires`,
    defaultMessage: 'Account Expires',
  },
  department: {
    id: `${scope}.components.overview.department`,
    defaultMessage: 'Department',
  },
  passwordExpiration: {
    id: `${scope}.components.overview.passwordExpiration`,
    defaultMessage: 'Password Expiration',
  },
  reportsTo: {
    id: `${scope}.components.overview.reportsTo`,
    defaultMessage: 'Reports To',
  },
  deletedSuccess: {
    id: `${scope}.components.overview.deletedSuccess`,
    defaultMessage: 'Deleted successfully!',
  },
  deletedFail: {
    id: `${scope}.components.overview.deletedFail`,
    defaultMessage: 'Deleted failed!',
  },
  shouldChooseItemDelete: {
    id: `${scope}.components.overview.shouldChooseItemDelete`,
    defaultMessage: 'Should choose item to delete!',
  },
  areYouSureDelete: {
    id: `${scope}.components.overview.areYouSureDelete`,
    defaultMessage: 'Are you sure to delete?',
  },
  downloadLocationError: {
    id: `${scope}.components.overview.downloadLocationError`,
    defaultMessage: 'Cannot download location!',
  },
  locations: {
    id: `${scope}.components.overview.locations`,
    defaultMessage: 'Locations',
  },
  contactCard: {
    id: `${scope}.components.overview.contactCard`,
    defaultMessage: 'Contact Card',
  },
  address: {
    id: `${scope}.components.overview.address`,
    defaultMessage: 'Address',
  },
  city: {
    id: `${scope}.components.overview.city`,
    defaultMessage: 'City',
  },
  state: {
    id: `${scope}.components.overview.state`,
    defaultMessage: 'State',
  },
  country: {
    id: `${scope}.components.overview.country`,
    defaultMessage: 'Country',
  },
  postalCode: {
    id: `${scope}.components.overview.postalCode`,
    defaultMessage: 'Postal Code',
  },
  phone: {
    id: `${scope}.components.overview.phone`,
    defaultMessage: 'Phone',
  },
  mobile: {
    id: `${scope}.components.overview.mobile`,
    defaultMessage: 'Mobile',
  },
  fax: {
    id: `${scope}.components.overview.fax`,
    defaultMessage: 'Fax',
  },
  cannotGetLocations: {
    id: `${scope}.components.overview.cannotGetLocations`,
    defaultMessage: 'Cannot get user profile locations.',
  },
  bio: {
    id: `${scope}.components.overview.bio`,
    defaultMessage: 'Bio',
  },
  //* user permission
  securityRoleTitle: {
    id: `${scope}.components.permission.securityRoleTitle`,
    defaultMessage: 'Security Roles',
  },
  systemPermissionTitle: {
    id: `${scope}.components.permission.systemPermissionTitle`,
    defaultMessage: 'System Permissons',
  },
  colRoleTitle: {
    id: `${scope}.components.permission.colRoleTitle`,
    defaultMessage: 'Role',
  },
  colPermissionTitle: {
    id: `${scope}.components.permission.colPermissionTitle`,
    defaultMessage: 'User Role & Security',
  },
  colDateAssignedTitle: {
    id: `${scope}.components.permission.colDateAssignedTitle`,
    defaultMessage: 'Date Assigned',
  },
  colAssignedByTitle: {
    id: `${scope}.components.permission.colAssignedByTitle`,
    defaultMessage: 'Assigned By',
  },
  noInfoMessage: {
    id: `${scope}.components.contactCard.noInfoMessage`,
    defaultMessage: 'No information',
  },
});
