import React from 'react';
import { Tooltip } from 'antd';
import {
  ReportingTileBody,
  ReportingTileFooter,
  ReportingTileHeader,
} from '../index';
import { get } from 'lodash';
import useDoubleClick from 'hooks/useDoubleClick';
import './ReportingTile.less';

const ReportingTile = (props) => {
  const { dataDetail, disableEvent } = props;
  const { onClickItemGrid, onDoubleClick } = props.clickEvents;
  const name = get(dataDetail, 'name', '');

  const divRef = React.useRef();
  useDoubleClick({
    onSingleClick: (e) => {
      if (onClickItemGrid) onClickItemGrid(dataDetail, e);
    },
    onDoubleClick: (e) => {
      if (onDoubleClick) onDoubleClick(dataDetail);
    },
    ref: divRef,
    latency: 250,
  });

  return (
    <Tooltip title={name}>
      <div className='reporting-tile'>
        {/*Placeholder for click events*/}
        <div
          style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            zIndex: 30,
            width: '100%',
            height: '100%',
          }}
          ref={divRef}
        />
        <ReportingTileHeader dataDetail={dataDetail} />
        <ReportingTileBody dataDetail={dataDetail} />
        <ReportingTileFooter dataDetail={dataDetail} />
      </div>
    </Tooltip>
  );
};

export default ReportingTile;
