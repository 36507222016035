import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSsoProduct = (state) => state.ssoProduct || initialState;

const selectLoading = () =>
  createSelector(selectSsoProduct, (state) => state.loading);
const selectError = () =>
  createSelector(selectSsoProduct, (state) => state.error);
const selectIsBpSsoProductValidate = () =>
  createSelector(selectSsoProduct, (state) => state.isBpSsoProductValidate);
const selectIsBpSsoProductValidateDetail = () =>
  createSelector(
    selectSsoProduct,
    (state) => state.isBpSsoProductValidateDetail
  );
const selectBpSsoProductData = () =>
  createSelector(selectSsoProduct, (state) => state.bpSsoProductData);
const selectIsRedirect = () =>
  createSelector(selectSsoProduct, (state) => state.isRedirect);
const selectpSsoProductSuccess = () =>
  createSelector(selectSsoProduct, (state) => state.bpSsoProductSuccess);
const selectRivirRetailerId = () =>
  createSelector(selectSsoProduct, (state) => state.rivirRetailerId);

export {
  selectLoading,
  selectError,
  selectIsBpSsoProductValidate,
  selectIsBpSsoProductValidateDetail,
  selectBpSsoProductData,
  selectIsRedirect,
  selectpSsoProductSuccess,
  selectRivirRetailerId,
};
