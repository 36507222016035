import React from 'react';

import { Select } from 'antd';

import WrapperSelect from '../select/WrapperSelect';

import { useGetTargetMarketList } from 'hooks/createProductHooks';

const TargetMarketInput = (props) => {
  const targetMarketList = useGetTargetMarketList();

  const sortTargetMarket = () => {
    if (!targetMarketList) return [];
    const USMarket = targetMarketList.find((marketItem, index) => {
      if (marketItem.Numeric !== 840) return false;

      return true;
    });

    const noneUSMarketList = targetMarketList.filter((marketItem) => {
      return marketItem.Numeric !== 840;
    });

    const sortedNoneUSMarketList = noneUSMarketList.sort((a, b) => {
      return a.Country - b.Country;
    });

    return [USMarket, ...sortedNoneUSMarketList];
  };

  const sortedTargetMarket = sortTargetMarket();

  return (
    <WrapperSelect
      showSearch
      allowClear
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      getPopupContainer={(trigger) => trigger.parentNode}
      {...props}
    >
      {targetMarketList?.length > 0 &&
        sortedTargetMarket?.map((item, index) => (
          <Select.Option
            key={index}
            value={item?.Country ? item?.Country + '' : null}
          >
            {item?.Country}
          </Select.Option>
        ))}
    </WrapperSelect>
  );
};

export default TargetMarketInput;
