import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Select } from 'antd';

import { sleep } from 'utils/delay';

import './SelectionEditor.less';

const { Option } = Select;

export const SelectionEditor = forwardRef((props, ref) => {
  const { values, value: initValue, mode, mapValue, disabled, column } = props;

  const [value, setValue] = useState(initValue);
  const [editing, setEditing] = useState(true);
  const refContainer = useRef(null);
  const refSelect = useRef();

  const refValue = useRef(undefined);

  const focus = () => {
    setTimeout(() => {
      refSelect.current?.focus && refSelect.current.focus();
    }, [100]);
  };

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    if (!editing) {
      props.stopEditing();
    }
  }, [editing]);

  const extractValue = (value) => {
    return mapValue ? mapValue(value) : value;
  };

  const handleChange = (value) => {
    const nextValue = extractValue(value);
    setValue(nextValue);
    refValue.current = nextValue;
    props.stopEditing();
  };

  const valueItemType =
    typeof values?.[0]?.displayName === 'string' ? 'object' : 'string';

  const handleInputKeyDown = async (e) => {
    if (e.key === 'Delete') {
      setValue(null);
      refValue.current = null;
      setEditing(false);
      return;
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault();
    } else {
      await sleep(200);
      const $dropDownActiveItem = document.querySelector(
        'div.ant-select-item-option-active > div.ant-select-item-option-content > div.option-item'
      );

      if ($dropDownActiveItem) {
        const activeValue = $dropDownActiveItem.getAttribute('value');
        refValue.current = activeValue;
      }
    }
  };

  const handleMouseEnter = (e, idx) => {
    const nextRefValue =
      valueItemType === 'string' ? values?.[idx] : value?.[idx]?.value;

    refValue.current = extractValue(nextRefValue);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (refValue.current === undefined) {
          return value;
        } else {
          const procValue = refValue.current;
          refValue.current = undefined;
          return procValue;
        }
      },
    };
  });

  const width = column.getActualWidth() || 300;

  return (
    <div
      ref={refContainer}
      className='ag-editor__selection'
      tabIndex={1}
      style={{ width }}
    >
      <Select
        ref={refSelect}
        mode={mode}
        allowClear
        showSearch
        style={{ width: '100%' }}
        placeholder='Please select'
        onChange={handleChange}
        value={value}
        disabled={disabled}
        onInputKeyDown={handleInputKeyDown}
        listHeight={250}
      >
        {values.map((valueItem, idx) => {
          const key = valueItemType === 'string' ? valueItem : valueItem?.value;
          const displayName =
            valueItemType === 'string' ? valueItem : valueItem?.displayName;

          return (
            <Option
              key={idx}
              value={key}
              onMouseEnter={(e) => handleMouseEnter(e, idx)}
              className='ag-editor__selection-option'
            >
              <div className={`option-item`} value={key}>
                {displayName}
              </div>
            </Option>
          );
        })}
      </Select>
    </div>
  );
});
