import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Image, Typography, Tooltip, Space } from 'antd';
import { get } from 'lodash';

import { InfoWithLabel, FavoriteStarIconRender } from 'common/components';
import EmptyFolderImg from 'assets/folder/empty-folder.png';
import FolderImg from 'assets/folder/folder.png';
import ShareFolderImg from 'assets/folder/shared-folder.png';
import DownloadIcon from 'assets/DownloadIcon.png';
import { injectIntl } from 'react-intl';

import { formatMDY } from 'utils/formatDate';
const { Title } = Typography;

const PanelHeader = (props) => {
  const { shortDetail, ownerFolder, openDownloadPanel, intl } = props;

  const [thumbnailImg, setThumbnailImg] = useState('');

  const onClickDownloadBtn = () => {
    openDownloadPanel && openDownloadPanel();
  };

  useEffect(() => {
    const thumbnailSrc = !ownerFolder
      ? ShareFolderImg
      : get(shortDetail, 'items') > 0
      ? FolderImg
      : EmptyFolderImg;
    setThumbnailImg(thumbnailSrc);
    // eslint-disable-next-line
  }, [ownerFolder]);

  const metaData = [
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.owner',
      }),
      info:
        get(shortDetail, 'ownerName', '') && get(shortDetail, 'ownerName', ''),
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.dateCreated',
      }),
      info:
        formatMDY(get(shortDetail, 'dateCreated', '')) !== 'Invalid Date'
          ? formatMDY(get(shortDetail, 'dateCreated', ''))
          : '',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.dateShared',
      }),
      info:
        formatMDY(get(shortDetail, 'dateShared', '')) !== 'Invalid Date'
          ? formatMDY(get(shortDetail, 'dateShared', ''))
          : '',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.editable',
      }),
      info: get(shortDetail, 'editable', '') ? 'Yes' : 'No',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.shareable',
      }),
      info: get(shortDetail, 'shareable', '') ? 'Yes' : 'No',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.specialPurpose',
      }),
      info: get(shortDetail, 'specialPurpose', '') ? 'Yes' : 'No',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.visibility',
      }),
      info: get(shortDetail, 'visibility', ''),
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.items',
      }),
      info: get(shortDetail, 'items', '') && get(shortDetail, 'items', ''),
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.expirationDate',
      }),
      info:
        formatMDY(get(shortDetail, 'expirationDate', '')) !== 'Invalid Date'
          ? formatMDY(get(shortDetail, 'expirationDate', ''))
          : '',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.lastUpdated',
      }),
      info: (
        <>
          <div>
            {formatMDY(get(shortDetail, 'lastUpdated', '')) !== 'Invalid Date'
              ? formatMDY(get(shortDetail, 'lastUpdated', ''))
              : ''}
          </div>
          <div>
            {get(shortDetail, 'lastUpdatedBy', '') &&
              get(shortDetail, 'lastUpdatedBy', '')}
          </div>
        </>
      ),
    },

    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.lastDownload',
      }),
      info:
        formatMDY(get(shortDetail, 'lastDownload', '')) !== 'Invalid Date'
          ? formatMDY(get(shortDetail, 'lastDownload', ''))
          : '',
    },
    {
      label: intl.formatMessage({
        id: 'Taco.folder.components.contentPane.lastSchedule',
      }),
      info:
        formatMDY(get(shortDetail, 'lastSchedule', '')) !== 'Invalid Date'
          ? formatMDY(get(shortDetail, 'lastSchedule', ''))
          : '',
    },
  ];

  const infoColProps = {
    infoColProps: {
      flex: 'auto',
    },
    labelColProps: {
      flex: '130px',
    },
    boldInfo: true,
    labelAlign: 'right',
  };

  const dataLeft = metaData
    ? metaData.slice(0, Math.ceil(metaData.length / 2 + 1))
    : [];
  const dataRight = metaData
    ? metaData.slice(Math.ceil(metaData.length / 2 + 1))
    : [];

  const folderName = get(shortDetail, 'folderName', '');

  return (
    <div className='folder-panel-header'>
      <Row className='folder-panel-header__btn-wrap'>
        <Space
          size={0}
          direction='horizontal'
          style={{ width: '100%', justifyContent: 'flex-end' }}
        >
          <Tooltip
            placement='top'
            title={'Download'}
            onClick={onClickDownloadBtn}
          >
            <div className='folder-panel-header__button-wrap'>
              <img
                alt='schedule-button'
                className='folder-panel-header__schedule-button'
                src={DownloadIcon}
              ></img>
            </div>
          </Tooltip>
        </Space>
      </Row>
      <Row align='bottom' className='folder-panel-header__thumbnail-info'>
        <Col span={7}>
          <Image
            src={thumbnailImg}
            preview={false}
            style={{ maxWidth: 100, maxHeight: 100, pointerEvents: 'none' }}
          />
        </Col>
        <Col span={16}>
          <Title
            className='folder-panel-header__folder-name'
            style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}
          >
            <FavoriteStarIconRender
              isFavorited={shortDetail?.isFavorited}
              style={{ marginRight: '6px' }}
            />
            {folderName}
          </Title>
        </Col>
      </Row>
      <div className='folder-panel-header__meta-info'>
        <Row>
          <Col span={12}>
            {dataLeft &&
              dataLeft.length > 0 &&
              dataLeft.map((item, index) => {
                return (
                  <InfoWithLabel
                    key={index}
                    info={item.info}
                    label={item.label}
                    {...infoColProps}
                  />
                );
              })}
          </Col>
          <Col span={12}>
            {dataRight &&
              dataRight.length > 0 &&
              dataRight.map((item, index) => {
                return (
                  <InfoWithLabel
                    key={index}
                    info={item.info}
                    label={item.label}
                    {...infoColProps}
                  />
                );
              })}
          </Col>
        </Row>
      </div>
      <hr className='folder-panel-header__divider-line'></hr>
    </div>
  );
};

PanelHeader.propTypes = {
  onTrigger: PropTypes.func,
  shortDetail: PropTypes.object,
  ownerFolder: PropTypes.bool,
  onEditClick: PropTypes.func,
};

export default injectIntl(PanelHeader);
