import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Radio, Select, Row, Col, Badge, Empty, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import moment from 'moment';

import { MAPPING_TYPE } from 'static/Constants';

import { Form, SourceFieldComplexType, WrapperSelect } from 'common/components';
import { useDeepCompareEffect } from 'common/components/mapping-properties/hooks';

import {
  COMPARE_TYPES,
  SET_TYPES,
  getConditionTypes,
  getSelectedParameter,
  getValueInputCompareField,
  getCompareValueParam,
  checkShowErrorFormStatement,
  checkRenderSetType,
  checkRenderCondition,
  checkDisabledTypeStatement,
} from './utils';

import { useAddStatement } from './hooks';

import {
  CompareToValueComponent,
  CompareToOtherFieldComponent,
  SelectSourceField,
  SetParameters,
} from './ShareComponents';
import sortByKey from 'utils/sortByKey';

const { Title } = Typography;

const AddStatement = (props) => {
  const {
    visible,
    setVisibleAddStatement,
    submitAddStatement,
    mapType,
    modeStatement,
    dataSelectedStatement,
    rowData,
    rowSelected,
  } = props;

  const [form] = Form.useForm();

  const {
    statementState,
    handleSelectPackageLevel,
    handleSelectToFieldPackageLevel,
    handleSelectToOtherFieldPackageLevel,
    handleGetSourceField,
    handleGetSourceToField,
    handleGetSourceToOtherField,
    handleFilterProperties,
    handleSelectCompareField,
    handleChangeCondition,
    handleSaveNameRenderSourceField,
    handleResetNameRenderSourceField,
    handleSetType,
    handleAddValueParameter,
    handleAddOtherFieldParameter,
    handleChangeTypeValueParameter,
    handleDeleteParameter,
    handleShowSourceFieldParameter,
    handleChangePropertyPackageLevelParam,
    handleChangePropertyFieldParam,
    handleChangePropertiesOfTypeValue,
    handleResetState,
    handleSaveStatementState,
    handleSaveCompareToValue,
    handleSaveCompareOtherValue,
    handleChangeStatementType,
    handleResetSourceFieldFilter,
  } = useAddStatement();

  const {
    compareFieldPackageLevel,
    compareFieldPath,
    compareToFieldPackageLevel,
    compareToFieldPath,
    compareToOtherFieldPackageLevel,
    compareToOtherFieldPath,
    compareType,
    condition,
    valueFilter,
    nameRenderSourceField,
    setType,
    setParameters,
    indexParam,
    dataType,
    compareToValue,
    compareToOtherValue,
    statementType,
  } = statementState;

  useEffect(() => {
    //* Update form values on change Compare Field
    if (compareFieldPath) {
      form.setFieldsValue(statementState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareFieldPath, form]);

  const [showError, setShowError] = useState(false);
  const isEdit = modeStatement === 'edit';

  const selectedParameter = useMemo(
    () => getSelectedParameter(setParameters, indexParam),
    [indexParam, setParameters]
  );

  useDeepCompareEffect(() => {
    if (dataType === 'datetime') {
      const formattedToValue = compareToValue ? moment(compareToValue) : null;
      const formattedToOtherValue = compareToOtherValue
        ? moment(compareToOtherValue)
        : null;

      if (condition === 'Between') {
        form.setFieldsValue({
          ...statementState,
          compareToValue: [formattedToValue, formattedToOtherValue],
        });
      } else {
        form.setFieldsValue({
          ...statementState,
          compareToValue: formattedToValue,
        });
      }
    } else {
      const { compareToValue, compareToOtherValue } = form.getFieldValue();

      form.setFieldsValue({
        ...statementState,
        compareToValue,
        compareToOtherValue,
      });
    }
  }, [form, statementState, handleResetState]);

  useEffect(() => {
    if (condition === null) {
      form.setFieldsValue({
        compareToValue: null,
        compareToOtherValue: null,
      });
    }
  }, [condition, form]);

  useEffect(() => {
    if (!visible) {
      handleResetState();
      setShowError(false);
    }
  }, [handleResetState, visible, form]);

  useEffect(() => {
    if (visible && isEdit) {
      handleSaveStatementState(dataSelectedStatement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isEdit, handleSaveStatementState, dataSelectedStatement]);

  const conditionTypesSelection = useMemo(
    () => getConditionTypes(dataType),
    [dataType]
  );

  const handleAddStatement = () => {
    const { statementType, condition, compareType } = form.getFieldsValue();

    const { compareToOtherValue, compareToValue } = statementState;

    let statement = {
      statementType,
      compareFieldPackageLevel,
      compareFieldPath,
      condition,
      compareType,
      dataType,
      setType,
    };

    if (compareType === 'Value') {
      const { value, otherValue } = getCompareValueParam(
        dataType,
        condition,
        compareToValue,
        compareToOtherValue
      );

      if (condition === 'Between') {
        statement = {
          ...statement,
          compareToValue: value,
          compareToOtherValue: otherValue,
        };
      } else {
        statement = {
          ...statement,
          compareToValue: value,
        };
      }
    } else if (compareType === 'OtherField') {
      if (condition === 'Between') {
        statement = {
          ...statement,
          compareToFieldPackageLevel,
          compareToFieldPath,
          compareToOtherFieldPackageLevel,
          compareToOtherFieldPath,
        };
      } else {
        statement = {
          ...statement,
          compareToFieldPackageLevel,
          compareToFieldPath,
        };
      }
    }

    statement = { ...statement, setParameters };

    form.validateFields().then((values) => {
      // Related to task 4873.
      if (statementType === 'Else') {
        submitAddStatement(statement);
        handleResetState();
        setVisibleAddStatement(false);
      }

      if (
        checkShowErrorFormStatement(
          compareType,
          compareToValue,
          dataType,
          condition
        )
      ) {
        setShowError(true);
        return;
      } else {
        submitAddStatement(statement);
        handleResetState();
        setVisibleAddStatement(false);
      }
    });
  };
  const handleCancel = () => {
    handleResetNameRenderSourceField();
    setVisibleAddStatement(false);
  };

  const onFinish = (values) => {
    submitAddStatement(values);
  };

  const handleStatementType = (statementType) => {
    handleResetState();

    setTimeout(() => {
      handleChangeStatementType(statementType);
      form.setFieldsValue({ statementType: statementType });
    });
  };

  //* Handle set type with source field filter
  const handleSetTypeWithFilter = (e) => {
    handleResetSourceFieldFilter();
    handleSetType(e);
  };

  //* Handle compare filed with source field filter
  const handleSelectCompareTypeFieldWithFilter = (e) => {
    handleResetSourceFieldFilter();
    handleSelectCompareField(e);
    handleSaveCompareToValue('');
    handleSetType('StringConcate');
  };

  //* Handle show and reset source field filter
  const handleShowSourceField = () => {
    handleResetNameRenderSourceField();
    handleSaveNameRenderSourceField('compareFieldPath');
    handleFilterProperties('all');
  };

  //* Handle change condition and reset value field
  const handleChangeConditionAndResetValue = (e) => {
    form.setFieldsValue({
      compareToValue: null,
      compareToOtherValue: null,
    });

    handleSaveCompareToValue(null);
    handleSaveCompareOtherValue(null);

    handleChangeCondition(e);
  };

  const handleChangeRangeDate = (rangeDate) => {
    handleSaveCompareToValue(rangeDate?.[0]);
    handleSaveCompareOtherValue(rangeDate?.[1]);
    setShowError(false);
  };

  const handleChangeDate = (date) => {
    form.setFieldsValue({ compareToValue: date });
    handleSaveCompareToValue(date);
    setShowError(false);
  };
  const onValuesChange = (changedValues, allValues) => {
    if (allValues?.compareToValue === '') {
      handleSaveCompareToValue('');
    }
  };

  const handleChangeCompareOtherValue = (value) => {
    handleSaveCompareOtherValue(value);
  };

  const handleChangeCompareValue = (value) => {
    handleSaveCompareToValue(value);
  };

  return (
    <>
      {visible && (
        <Modal
          title={!isEdit ? 'Add Statement' : 'Edit Statement'}
          visible={visible}
          width={1350}
          bodyStyle={{ height: '660px' }}
          maskClosable={false}
          onOk={handleAddStatement}
          onCancel={handleCancel}
          destroyOnClose={true}
        >
          <Row gutter={[16, 0]} style={{ height: '100%' }}>
            <Col span={12}>
              <Form
                name='addStatement'
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                onFinish={onFinish}
                onValuesChange={(changedValues, allValues) =>
                  onValuesChange(changedValues, allValues)
                }
              >
                <FormItem label='Type' name='statementType'>
                  <Radio.Group
                    onChange={(e) => handleStatementType(e?.target?.value)}
                  >
                    <Row>
                      <Col span={12}>
                        <Radio value='If'>If</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio
                          value='Else'
                          disabled={checkDisabledTypeStatement(
                            rowData,
                            rowSelected,
                            modeStatement
                          )}
                        >
                          Else
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </FormItem>

                {statementType !== 'Else' && (
                  <FormItem
                    label='Compare Field'
                    name='compareFieldPath'
                    rules={[
                      {
                        required: statementType === 'If',
                        message: 'Compare Field cannot be empty!',
                      },
                    ]}
                  >
                    <SelectSourceField
                      onClick={handleShowSourceField}
                      valueInput={getValueInputCompareField(
                        compareFieldPackageLevel,
                        compareFieldPath
                      )}
                    />
                  </FormItem>
                )}

                {checkRenderCondition(compareFieldPath, statementType) && (
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Form.Item
                        label='Compare Type'
                        name='compareType'
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                      >
                        <WrapperSelect
                          value={compareType}
                          onChange={handleSelectCompareTypeFieldWithFilter}
                        >
                          {COMPARE_TYPES?.slice()
                            .sort()
                            .map((compare) => (
                              <Select.Option
                                value={compare.value}
                                key={compare.value}
                              >
                                {compare.label}
                              </Select.Option>
                            ))}
                        </WrapperSelect>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <FormItem
                        label='Condition'
                        name='condition'
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                      >
                        <WrapperSelect
                          value={condition}
                          onChange={(value) => {
                            handleChangeConditionAndResetValue(value);
                          }}
                        >
                          {sortByKey(conditionTypesSelection, 'label')?.map(
                            (condition) => (
                              <Select.Option
                                value={condition.value}
                                key={condition.value}
                              >
                                {condition.label}
                              </Select.Option>
                            )
                          )}
                        </WrapperSelect>
                      </FormItem>
                    </Col>
                  </Row>
                )}
                {compareType === 'Value' ? (
                  <CompareToValueComponent
                    dataType={dataType}
                    condition={condition}
                    onChangeRangeDate={handleChangeRangeDate}
                    handleChangeCompareValue={handleChangeCompareValue}
                    handleChangeCompareOtherValue={
                      handleChangeCompareOtherValue
                    }
                    compareToValue={compareToValue}
                    compareToOtherValue={compareToOtherValue}
                    handleChangeDate={handleChangeDate}
                    showError={showError}
                    statementType={statementType}
                  />
                ) : (
                  <CompareToOtherFieldComponent
                    compareFieldPath={compareFieldPath}
                    dataType={dataType}
                    condition={condition}
                    handleShowToSourceField={handleSaveNameRenderSourceField}
                    compareToFieldPackageLevel={compareToFieldPackageLevel}
                    compareToOtherFieldPackageLevel={
                      compareToOtherFieldPackageLevel
                    }
                    compareToFieldPath={compareToFieldPath}
                    compareToOtherFieldPath={compareToOtherFieldPath}
                    handleFilterProperties={handleFilterProperties}
                    statementType={statementType}
                  />
                )}

                {checkRenderSetType(
                  compareToValue,
                  compareToFieldPath,
                  dataType,
                  condition,
                  statementType
                ) && (
                  <>
                    <Form.Item label='Set Type' name='setType'>
                      <WrapperSelect
                        value={setType}
                        onChange={handleSetTypeWithFilter}
                      >
                        {sortByKey(SET_TYPES, 'label')?.map((type) => (
                          <Select.Option value={type.value} key={type.value}>
                            {type.label}
                          </Select.Option>
                        ))}
                      </WrapperSelect>
                    </Form.Item>
                    {setType && (
                      <SetParameters
                        setType={setType}
                        setParameters={setParameters}
                        handleAddValueParameter={handleAddValueParameter}
                        handleAddOtherFieldParameter={
                          handleAddOtherFieldParameter
                        }
                        handleChangeTypeValueParameter={
                          handleChangeTypeValueParameter
                        }
                        handleDeleteParameter={handleDeleteParameter}
                        handleShowToSourceField={(index) => {
                          handleShowSourceFieldParameter(index);
                        }}
                        handleChangePropertiesOfTypeValue={
                          handleChangePropertiesOfTypeValue
                        }
                        handleFilterProperties={handleFilterProperties}
                      />
                    )}
                  </>
                )}
              </Form>
            </Col>
            <Col span={12}>
              <Title level={5}>Properties List</Title>

              {nameRenderSourceField === null && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Empty />
                </div>
              )}

              {nameRenderSourceField === 'compareFieldPath' &&
                mapType === MAPPING_TYPE[2].value && (
                  <ContainerSourceFieldComplexType
                    handleResetNameRenderSourceField={
                      handleResetNameRenderSourceField
                    }
                  >
                    <SourceFieldComplexType
                      className='mapping-config__source-field-complex'
                      valueFilter={valueFilter}
                      packageLevel={compareFieldPackageLevel}
                      handleSelectPackageLevel={handleSelectPackageLevel}
                      handleResetNameRenderSourceField={
                        handleResetNameRenderSourceField
                      }
                      handleGetSourceField={handleGetSourceField}
                      handleFilterProperties={handleFilterProperties}
                      compareType={compareType}
                      nameRenderSourceField={nameRenderSourceField}
                      compareFieldPath={true}
                    />
                  </ContainerSourceFieldComplexType>
                )}
              {nameRenderSourceField === 'compareToFieldPath' &&
                mapType === MAPPING_TYPE[2].value && (
                  <ContainerSourceFieldComplexType
                    handleResetNameRenderSourceField={
                      handleResetNameRenderSourceField
                    }
                  >
                    <SourceFieldComplexType
                      className='mapping-config__source-field-complex'
                      valueFilter={valueFilter}
                      packageLevel={compareToFieldPackageLevel}
                      handleSelectPackageLevel={handleSelectToFieldPackageLevel}
                      handleResetNameRenderSourceField={
                        handleResetNameRenderSourceField
                      }
                      handleGetSourceField={handleGetSourceToField}
                      handleFilterProperties={handleFilterProperties}
                      compareType={compareType}
                      nameRenderSourceField={nameRenderSourceField}
                    />
                  </ContainerSourceFieldComplexType>
                )}
              {nameRenderSourceField === 'compareToOtherFieldPath' &&
                mapType === MAPPING_TYPE[2].value && (
                  <ContainerSourceFieldComplexType
                    handleResetNameRenderSourceField={
                      handleResetNameRenderSourceField
                    }
                  >
                    <SourceFieldComplexType
                      className='mapping-config__source-field-complex'
                      valueFilter={valueFilter}
                      packageLevel={compareToOtherFieldPackageLevel}
                      handleSelectPackageLevel={
                        handleSelectToOtherFieldPackageLevel
                      }
                      handleResetNameRenderSourceField={
                        handleResetNameRenderSourceField
                      }
                      handleGetSourceField={handleGetSourceToOtherField}
                      handleFilterProperties={handleFilterProperties}
                      compareType={compareType}
                      nameRenderSourceField={nameRenderSourceField}
                    />
                  </ContainerSourceFieldComplexType>
                )}
              {selectedParameter?.nameRenderSourceField ===
                nameRenderSourceField && (
                <ContainerSourceFieldComplexType
                  handleResetNameRenderSourceField={
                    handleResetNameRenderSourceField
                  }
                  key={selectedParameter.nameRenderSourceField}
                >
                  <SourceFieldComplexType
                    className='mapping-config__source-field-complex'
                    valueFilter={valueFilter}
                    packageLevel={selectedParameter?.setByFieldPackageLevel}
                    handleSelectPackageLevel={
                      handleChangePropertyPackageLevelParam
                    }
                    handleResetNameRenderSourceField={
                      handleResetNameRenderSourceField
                    }
                    handleFilterProperties={handleFilterProperties}
                    handleGetSourceField={handleChangePropertyFieldParam}
                    compareType={compareType}
                    nameRenderSourceField={nameRenderSourceField}
                    isSetParameter={true}
                  />
                </ContainerSourceFieldComplexType>
              )}
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

const FormItem = ({ children, ...otherProps }) => {
  return <Form.Item {...otherProps}>{children}</Form.Item>;
};

const ContainerSourceFieldComplexType = ({
  handleResetNameRenderSourceField,
  children,
}) => {
  return (
    <Badge
      className='mapping-config__badge'
      count={
        <span onClick={handleResetNameRenderSourceField}>
          <CloseOutlined style={{ padding: 3 }} />
        </span>
      }
    >
      {children}
    </Badge>
  );
};

export default AddStatement;
