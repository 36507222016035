import React from 'react';

import classnames from 'classnames';

import './MaskLoading.less';

const MaskLoading = (props) => {
  const { loading } = props;

  return (
    <div
      className={classnames({
        'mask-loading': true,
        'mask-loading--on': loading,
      })}
    />
  );
};

export default MaskLoading;
