import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  SendOutlined,
  FileExcelOutlined,
  LinkOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Typography,
  Button,
  Skeleton,
  Empty,
  Row,
  Col,
  Tooltip,
  Image,
  Result,
  Checkbox,
} from 'antd';
import Messages from 'i18n/messages/messageAssetUpload';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import * as _ from 'lodash';
import { useMedia } from 'react-media';

//* COMPONENT
import { CustomNotification, dialogFunction } from 'common/components';
import { SelectedFile, TreeFiles } from './shared/components';
import { ConfirmContent } from 'pages/asset-full-view/components';
import xls from 'assets/file-formats/xls.png';

//* UTILS
import * as api from 'config/axios';
import { DataToMsOffice } from 'utils/dataToMSoffice';
import * as globalActions from 'redux/global/actions';

import {
  mappingInteropItemsToTreeData,
  getExpandedData,
  updateTreeData,
  findNodeTree,
  getChildNodesInFolder,
  filterArrayInAnotherArray,
  filterArrayObjectInAnotherArrayObject,
  findAncestorsOfChildNode,
  getOnlySelectedParentItems,
  getFormDataParams,
  filterFoldersAndFilesInSelectedItems,
  getFormValues,
  getFilesPath,
  getFoldersPath,
  findListNodeInTree,
  getAllFilesInFolders,
  getTreeDataAfterExpandingAll,
  getAllExpandedKeys,
  getFilesAfterExpandingAll,
} from './shared/utils';

import { useCheckAllowCreateFolder, useGetTreeData } from './shared/hooks';
import { useDispatchReloadPage } from 'hooks/useReloadPage';

//* MAPPERS
// import mapFtpListToAntTree from './mappers/mapFtpListToAntTree';
// import mapFtpListFile from './mappers/mapFtpListFile';

import {
  createReplacedAsset,
  importAssetsFromInterop,
} from 'services/interoperability';

import * as homeActions from 'pages/home/ribbon/asset-full/controllers/actions';
import * as assetActions from 'pages/asset-full-view/controllers/actions';
import gridSelector from 'common/components/grid-view/controllers/selectors';
import * as ribbonSelector from 'redux/ribbon/selectors';
import * as brandingActions from 'redux/branding/actions';
import * as assetSelectors from 'pages/asset-full-view/controllers/selectors';

import CreateFolderModal from 'pages/home/ribbon/components/controls/folders/CreateFolderModal';

import { forwardTo, navigateToRouteAsset } from 'utils/common/route';
import { sleep } from 'utils/delay';
import { isImageType } from 'utils/fileType';
import { getVirtualScrollHeight } from './shared/utils';

import { GLOBAL_MEDIA_QUERIES } from 'static/MediaQuery';
import { RIBBON_VIEW, ROUTE } from 'static/Constants';

//* STYLING

import * as damServices from 'services/digitalAsset';

import './AssetFtpList.less';

const { Title, Text } = Typography;

function AssetFtpList(props) {
  const {
    ftpListFile,
    multiple,
    multipleImport = false,
    callbackDataList,
    templateUrl,
    formInstance,
    typeViewAsset,
    handleCloseModal,
    confirmBeforeUpload,
    importedFolderParams = {},
    isEnabledImportFolder,
    destinationFolder,
    multiImportFormValue,
  } = props;

  const reloadPage = useDispatchReloadPage();

  const [selectedFile, setSelectedFile] = useState(null);
  const [xlsList, setXlsList] = useState(undefined);
  const [statusLoading, setStatusLoading] = useState('idle');
  const [visibleModal, setVisibleModal] = useState(false);

  const isHasDestinationFolder = Boolean(
    importedFolderParams?.destinationFolderId
  );

  const dispatch = useDispatch();

  const replacedGridAsset = useSelector(
    gridSelector.makeSelectItemCurrentSelection()
  );

  const replacedFullView = useSelector(assetSelectors.assetDataLoading());

  const isAllowCreateFolder = useCheckAllowCreateFolder();

  const isAssetFullView =
    useSelector(ribbonSelector.selectRibbon()) ===
    RIBBON_VIEW.ASSET_FULL_VIEW.NAME;

  const assetId = isAssetFullView
    ? replacedFullView?.id
    : replacedGridAsset?.id;

  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const largeScreen = matches.lg || matches.md || matches.xl;

  const updateProgressBar = (selectedItems = [], status, percent) => {
    if (status === 'error' || status === 'done' || status === 'uploading') {
      selectedItems.forEach((fileItem) => {
        dispatch(
          globalActions.updateProgressBar({
            type: 'upload',
            name: fileItem.data.path,
            percent,
            status,
            uid: fileItem.data.itemId,
          })
        );
      });
    }
  };

  const virtualScrollHeight = getVirtualScrollHeight(
    largeScreen,
    multipleImport
  );

  const handleReplaceSingleAsset = async () => {
    const singleItem = selectedItems[0];
    const params = {
      storageServiceType: 'ftp',
      filePath: [singleItem.data.path],
    };

    const formData = getFormDataParams(params);

    updateProgressBar(selectedItems, 'uploading', 0);

    try {
      const response = await createReplacedAsset(formData, assetId);

      if (response?.isSuccess) {
        const metaData = response.data;

        handleCreateSingleAssetSuccess(metaData);
        updateProgressBar(selectedItems, 'done', 100);
      } else {
        updateProgressBar(selectedItems, 'error', 0);
      }
    } catch (error) {
      updateProgressBar(selectedItems, 'error', 0);
    }
  };

  const handleCreateSingleAsset = async () => {
    const singleItem = selectedItems[0];

    const params = {
      storageServiceType: 'ftp', // I don't know why BE does not use 'advFTP'. I cried so much in here, good luck to me
      filePaths: [singleItem.data.path],
      isCreateThumbs: true,
    };
    const formData = getFormDataParams(params);

    updateProgressBar(selectedItems, 'uploading', 0);

    try {
      const response = await importAssetsFromInterop(formData);

      if (response.isSuccess) {
        const metaData = response.data;
        handleCreateSingleAssetSuccess(metaData);
        updateProgressBar(selectedItems, 'done', 100);
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
        updateProgressBar(selectedItems, 'error', 0);
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Something went wrong');
      updateProgressBar(selectedItems, 'error', 0);
    }
  };

  const handleCreateSingleAssetSuccess = (metaData) => {
    const id = metaData.id;

    handleCloseModal();
    dispatch(assetActions.cancelUpdateSuccess());
    dispatch(assetActions.createDigitalAssetFullSuccess(metaData));
    dispatch(homeActions.editAsset());
    dispatch(homeActions.creatingAsset());
    dispatch(brandingActions.getBrandingNoLoading());

    navigateToRouteAsset({
      typeViewAsset,
      id,
      isMultipleImport: multipleImport,
    });
  };

  const handleCreateMultipleAssets = async (otherParams = {}) => {
    const formValues = getFormValues(formInstance);
    const { folders, files } =
      filterFoldersAndFilesInSelectedItems(selectedItems);
    const filePaths = getFilesPath(folders, files);
    const folderPaths = getFoldersPath(folders);
    const params = {
      id: null,
      storageServiceType: 'ftp',
      filePaths,
      folderPaths,
      templateFile: xlsList,
      ...importedFolderParams,
      ...formValues,
      ...otherParams,
    };
    let formData = getFormDataParams(params);
    updateProgressBar(selectedItems, 'uploading', 0);
    try {
      const response = await importAssetsFromInterop(formData);
      if (response?.isSuccess) {
        handleCloseModal();
        navigateToRouteAsset({
          isMultipleImport: multipleImport,
        });
        dispatch(brandingActions.getBrandingNoLoading());
        updateProgressBar(selectedItems, 'done', 100);
        CustomNotification.success('Create successfully');
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Something went wrong');
      updateProgressBar(selectedItems, 'error', 0);
    }
  };

  const handleAttachTemplate = () => {
    const $input = document.createElement('input');
    $input.setAttribute('type', 'file');
    const handleSelectFile = (event) => {
      let file = event.target.files[0];
      if (file) {
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          setXlsList(file);
          CustomNotification.success('The file is attached successfully');
        } else {
          CustomNotification.error('Only accept xls file');
          setXlsList(undefined);
        }
      }
    };
    $input.onchange = handleSelectFile;
    $input.click();
  };

  const handleDownloadTemplate = () => {
    api.sendDownload({
      url: templateUrl || '/unknown',
    });
  };

  const handleCopyFileNameToExcel = async () => {
    setStatusCopy('loading');

    let copyItems = [];

    const selectedFiles = selectedItems.filter(
      (item) => item.data.type === 'file'
    );
    const selectedFolders = selectedItems.filter(
      (item) => item.data.type === 'folder'
    );

    if (expandAll.status === 'success') {
      const folderNodes = findListNodeInTree(treeData, selectedFolders);
      const filesInFolder = getAllFilesInFolders(folderNodes);

      copyItems = selectedFiles.concat(filesInFolder);
    } else {
      const expandedFiles = await getFilesAfterExpandingAll({
        folderNodes: selectedFolders,
        id: null,
        storageServiceType: 'advFTP',
        multipleImport,
      });

      copyItems = selectedFiles.concat(expandedFiles);
    }

    DataToMsOffice().copy2DArrayClipboardToExcel(
      copyItems.map((fileItem) => [fileItem.data.name])
    );

    setStatusCopy('success');
  };

  const replaceThumbnail = async () => {
    const singleItem = selectedItems[0];
    const isImgType = isImageType(singleItem?.data?.path);
    if (!isImgType) {
      CustomNotification.error('Only support image type');
      return;
    }
    const params = {
      assetId,
      storageServiceType: 'ftp',
      filePath: singleItem?.data?.path,
    };
    const formData = getFormDataParams(params);
    setStatusLoading('loading');
    try {
      const response = await damServices.replaceDigitalAssetsThumbnails(
        formData
      );
      if (response?.isSuccess) {
        setStatusLoading('success');
        handleCloseModal();
        reloadPage({ clearSelection: true });
        CustomNotification.success('Replace Preview successfully');
      } else {
        CustomNotification.error(response?.message ?? 'Something went wrong');
        setStatusLoading('error');
      }
    } catch (error) {
      CustomNotification.error(error?.message ?? 'Something went wrong');
      setStatusLoading('error');
    }
  };

  const modalConfirmImportAsset = (otherParams = {}) => {
    const newDestinationFolder = otherParams?.destinationFolderId
      ? { ...destinationFolder, destinationFolder: { ...otherParams } }
      : destinationFolder;

    dialogFunction({
      type: 'warn',
      content: (
        <ConfirmContent
          destinationFolder={newDestinationFolder}
          multiImportFormValue={multiImportFormValue}
          isEnabledImportFolder={isEnabledImportFolder}
        />
      ),
      onOk: async () => {
        await sleep(1000);
        CustomNotification.warning(
          'Please wait while the import process is being completed. This may require additional time, especially for larger datasets. We appreciate your understanding and patience during this process',
          30
        );
        handleCreateMultipleAssets(otherParams);
        forwardTo(ROUTE.DIGITAL_MEDIA);
      },
      okText: 'Upload',
      width: 450,
    });
  };

  const handleClickSend = () => {
    // If user doesn't have create folder permission, we won't show modal for creating destination folder
    const isIgnoreShowModalCreate = !isAllowCreateFolder;

    if (multipleImport) {
      if (isIgnoreShowModalCreate) {
        modalConfirmImportAsset();
      } else if (isEnabledImportFolder && !isHasDestinationFolder) {
        setVisibleModal(true);
      } else {
        modalConfirmImportAsset();
      }
      return;
    } else {
      if (typeViewAsset === 'replace-asset') {
        handleReplaceSingleAsset();
      } else if (typeViewAsset === 'create-asset') {
        handleCreateSingleAsset();
      } else {
        replaceThumbnail();
        return;
      }
    }
  };

  useEffect(() => {
    setSelectedFile(null);
    if (typeof callbackDataList === 'function') callbackDataList([], true);
  }, [ftpListFile, callbackDataList]);

  const { treeData, handleSetTreeData } = useGetTreeData({
    multipleImport,
    storageServiceType: 'advFTP',
  });

  const [{ selectedItems, selectedKeys, expandedKeys }, setSelectedFtp] =
    useState({
      selectedItems: [],
      selectedKeys: [],
      expandedKeys: [],
    });

  /* 
    status === 'loading': treeData is loading data inside
    status === 'success': treeData is already loaded data
  */
  const [expandAll, setExpandAll] = useState({
    value: false,
    status: 'idle',
  });

  const [statusCopy, setStatusCopy] = useState('idle');

  const handleLoadData = async ({ key, children, data }) => {
    if (expandAll.status === 'success') return;

    const { path } = data;
    const expandedData = await getExpandedData({
      pathInfo: path,
      storageServiceType: 'advFTP',
    });

    return new Promise((resolve) => {
      if (children) {
        resolve();
        return;
      }
      const mappedTreeData = mappingInteropItemsToTreeData(
        expandedData,
        multipleImport
      );
      const newData = updateTreeData(treeData, key, mappedTreeData);

      handleSetTreeData(newData);
      resolve();
    });
  };

  const handleSelectItems = (keys, { selectedNodes: items }) => {
    if (multipleImport) {
      const parentSelectedItems = getOnlySelectedParentItems(
        treeData,
        items,
        keys
      );

      setSelectedFtp((prevItems) => ({
        ...prevItems,
        selectedItems: parentSelectedItems,
        selectedKeys: keys,
      }));
    } else {
      setSelectedFtp((prevItems) => ({
        ...prevItems,
        selectedItems: items,
        selectedKeys: keys,
      }));
    }
  };

  const handleCheckItems = (keys, { checkedNodes: items }) => {
    if (multipleImport) {
      const parentSelectedItems = getOnlySelectedParentItems(
        treeData,
        items,
        keys
      );

      setSelectedFtp((prevItems) => ({
        ...prevItems,
        selectedItems: parentSelectedItems,
        selectedKeys: keys,
      }));
    } else {
      setSelectedFtp((prevItems) => ({
        ...prevItems,
        selectedItems: items,
        selectedKeys: keys,
      }));
    }
  };

  const handleDeleteItem = (itemId) => {
    if (!multipleImport) {
      setSelectedFtp((prevItems) => ({
        ...prevItems,
        selectedItems: [],
        selectedKeys: [],
      }));
    } else {
      const foundNode = findNodeTree(treeData, itemId);
      const isHaveChildren = foundNode?.children?.length > 0;

      const ancestorNodes = findAncestorsOfChildNode(treeData, foundNode);
      const ancestorKeys = ancestorNodes.map((node) => node.key);

      if (isHaveChildren) {
        const childNodes = getChildNodesInFolder(foundNode.children);
        const childKeys = childNodes.map((node) => node.key);

        const allKeys = [foundNode.key].concat(ancestorKeys).concat(childKeys); // include select key and ancestor keys
        const allNodes = [foundNode].concat(ancestorNodes).concat(childNodes);

        const filteredSelectedKeys = filterArrayInAnotherArray(
          selectedKeys,
          allKeys
        );
        const filteredSelectedItems = filterArrayObjectInAnotherArrayObject(
          selectedItems,
          allNodes
        );

        setSelectedFtp((prevItems) => ({
          ...prevItems,
          selectedItems: filteredSelectedItems,
          selectedKeys: filteredSelectedKeys,
        }));
      } else {
        const allKeys = [foundNode.key].concat(ancestorKeys); // include select key and ancestor keys
        const allNodes = [foundNode].concat(ancestorNodes);

        const filteredSelectedKeys = filterArrayInAnotherArray(
          selectedKeys,
          allKeys
        );
        const filteredSelectedItems = filterArrayObjectInAnotherArrayObject(
          selectedItems,
          allNodes
        );

        setSelectedFtp((prevItems) => ({
          ...prevItems,
          selectedItems: filteredSelectedItems,
          selectedKeys: filteredSelectedKeys,
        }));
      }
    }
  };
  // after 30 days if the function is not restored please delete this code, thanks

  // const handleExpandAllFiles = async (event) => {
  //   const checked = event.target.checked;

  //   if (checked) {
  //     setExpandAll((prevVal) => ({ ...prevVal, value: checked }));

  //     // If treeData loaded data, then does not need to call api again.
  //     if (expandAll.status !== 'success') {
  //       setExpandAll((prevVal) => ({ ...prevVal, status: 'loading' }));

  //       const newTreeData = await getTreeDataAfterExpandingAll({
  //         treeData,
  //         id: null,
  //         storageServiceType: 'advFTP',
  //         multipleImport,
  //       });

  //       const expandedKeys = getAllExpandedKeys(newTreeData);

  //       setSelectedFtp((prevItems) => ({
  //         ...prevItems,
  //         expandedKeys,
  //       }));

  //       handleSetTreeData(newTreeData);
  //       setExpandAll((prevVal) => ({ ...prevVal, status: 'success' }));
  //     } else {
  //       const expandedKeys = getAllExpandedKeys(treeData);

  //       setSelectedFtp((prevItems) => ({
  //         ...prevItems,
  //         expandedKeys,
  //       }));
  //     }
  //   } else {
  //     setExpandAll((prevVal) => ({ ...prevVal, value: checked }));
  //     setSelectedFtp((prevItems) => ({
  //       ...prevItems,
  //       expandedKeys: [],
  //     }));
  //   }
  // };

  const handleExpandItems = (expandedKeys) => {
    setSelectedFtp((prevItems) => ({
      ...prevItems,
      expandedKeys,
    }));
  };

  const ftpListError = _.get(ftpListFile, 'error');

  const disabledBtn = selectedItems.length === 0;

  return (
    <>
      <Skeleton paragraph={{ rows: 4 }} loading={_.get(ftpListFile, 'loading')}>
        <div className='asset-ftp'>
          <div className='asset-ftp__cloud'>
            <div style={{ display: 'flex', paddingBottom: 6 }}>
              <Title level={4} className='asset-ftp__sub-title'>
                <FormattedMessage {...Messages.ftpHeader} />
              </Title>
              {/* <Checkbox
                style={{ marginLeft: 24 }}
                onChange={handleExpandAllFiles}
              >
                Expand All
              </Checkbox> */}
            </div>

            <div
              className={classnames('asset-ftp__list', {
                'asset-ftp__list--multiple': multiple || multipleImport,
                'asset-ftp__list--single': !multipleImport,
              })}
            >
              {ftpListError ? (
                <Result
                  className='asset-ftp__error'
                  status='error'
                  title={ftpListError}
                />
              ) : treeData?.length ? (
                <TreeFiles
                  height={virtualScrollHeight}
                  checkable={multipleImport}
                  selectable={multipleImport ? false : true}
                  multiple={multipleImport}
                  treeData={treeData}
                  loadData={handleLoadData}
                  onSelect={handleSelectItems}
                  onCheck={handleCheckItems}
                  selectedKeys={selectedKeys}
                  checkedKeys={selectedKeys}
                  expandedKeys={expandedKeys}
                  onExpand={handleExpandItems}
                />
              ) : (
                <Empty className='asset-ftp__no-data' />
              )}
            </div>
          </div>
          <div
            className={classnames('asset-ftp__result-container', {
              'asset-ftp__result-container--multi': multipleImport,
            })}
          >
            <Title level={4} className='asset-ftp__sub-title'>
              Selected Items
              {xlsList && multipleImport && (
                <Row className='asset-ftp__sub-title-xls'>
                  <Col>
                    <Tooltip title={xlsList.name}>
                      <Image
                        className='asset-ftp__sub-title-xls-img'
                        height={28}
                        width={23}
                        src={xls}
                        placeholder={
                          <LoadingOutlined
                            style={{ fontSize: 27, backgroundColor: 'white' }}
                          />
                        }
                        preview={false}
                      />
                    </Tooltip>
                  </Col>
                  <Col
                    className='asset-ftp__sub-title-xls-delete'
                    style={{ paddingLeft: 4 }}
                  >
                    <CloseOutlined onClick={() => setXlsList(undefined)} />
                  </Col>
                </Row>
              )}
            </Title>

            <div
              className={classnames({
                'asset-ftp__result': true,
                'asset-ftp__result--single': !multipleImport,
                'asset-ftp__result--multiple': multipleImport,
              })}
            >
              <div
                className={classnames('asset-ftp__result-list', {
                  'asset-ftp__result-list--single': !multipleImport,
                  'asset-ftp__result-list--multiple': multipleImport,
                  scroller: multipleImport,
                })}
              >
                <React.Fragment>
                  {selectedItems.map((item) => (
                    <SelectedFile
                      key={item.data.itemId}
                      name={item.data.name}
                      itemId={item.data.itemId}
                      type={item.data.type}
                      onDeleteItem={handleDeleteItem}
                    />
                  ))}
                </React.Fragment>
              </div>
              <div
                className={classnames('asset-ftp__btn-group', {
                  'asset-ftp__btn-group--single': !multipleImport,
                })}
              >
                <Row style={{ height: '100%' }}>
                  <Col
                    xs={24}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    {multipleImport && (
                      <React.Fragment>
                        <Button
                          className={classnames(
                            'asset-ftp__btn',
                            'asset-ftp__btn-download-xls'
                          )}
                          icon={<FileExcelOutlined />}
                          onClick={handleDownloadTemplate}
                          disabled
                        >
                          Download xls Template
                        </Button>

                        <Button
                          className={classnames(
                            'asset-ftp__btn',
                            'asset-ftp__btn-copy'
                          )}
                          icon={<LinkOutlined />}
                          onClick={handleCopyFileNameToExcel}
                          disabled
                        >
                          Copy All Selected Items
                        </Button>

                        <Button
                          className={classnames(
                            'asset-ftp__btn',
                            'asset-ftp__btn-attach-xls'
                          )}
                          icon={<LinkOutlined />}
                          onClick={handleAttachTemplate}
                          disabled
                        >
                          Attach file (.xls)
                        </Button>
                      </React.Fragment>
                    )}
                    <Button
                      className={classnames(
                        'asset-ftp__btn',
                        'asset-ftp__btn-select'
                      )}
                      type='primary'
                      icon={<SendOutlined />}
                      loading={statusLoading === 'loading'}
                      disabled={disabledBtn}
                      onClick={() => handleClickSend()}
                    >
                      Send
                      {multipleImport &&
                        selectedFile &&
                        selectedFile?.length > 0 && (
                          <Text
                            className={classnames(
                              'asset-ftp__btn-select-amount'
                            )}
                          >
                            {selectedFile.length}
                          </Text>
                        )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Skeleton>

      {isAllowCreateFolder && (
        <CreateFolderModal
          visibleHook={[visibleModal, setVisibleModal]}
          successCallback={(responseData) => {
            modalConfirmImportAsset({
              destinationFolderId: responseData?.id,
            });
          }}
          additionalButton={() => {
            return (
              <Col span={12}>
                <Button
                  type='default'
                  onClick={() => modalConfirmImportAsset()}
                >
                  Continue without destination folder
                </Button>
              </Col>
            );
          }}
        />
      )}
    </>
  );
}

AssetFtpList.propTypes = {
  //? ftpListFile - ftp files list
  ftpListFile: PropTypes.object,
  //? apiUrl - to post Ftp File
  apiUrl: PropTypes.string,
  //? getResponse - send response to prarent to process
  getResponse: PropTypes.func,
  //? onlyAccpetImage - only show image ftp file
  onlyAccpetImage: PropTypes.bool,
  //? customRequestFtp - custom request of ftp method
  customRequestFtp: PropTypes.func,
  multipleImport: PropTypes.bool,
  callbackDataList: PropTypes.func,
};

export default AssetFtpList;
