import React, { useMemo, useState } from 'react';

import {
  CustomNotification,
  StyledModal,
  WithLoading,
  WrapperSelect,
} from 'common/components';

import { Form, Select } from 'antd';

import {
  AllergensGroup,
  FactsPanelGroup,
  GroupName,
  IngredientsGroup,
} from 'pages/product-full-view/components/product-media-area/multiple-panel/components';

import { useFactsPanelsGroup } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/hooks';
import { useGetQaGroupDetail, useSaveQaGroupProduct } from './hooks';
import {
  useInitialFormGroup,
  useInvalidateGroupDetail,
} from 'pages/product-full-view/components/product-media-area/shared/hooks';

import { separateSupplementAndNutritionIds } from 'pages/product-full-view/components/product-media-area/multiple-panel/components/utils';
import { defaultModalProps } from '../qa-allergen/AllergenGroup';
import { mappingAllergenGroupsWhenEditing } from './utils';

export const QaGroupEdit = (props) => {
  const {
    defaultGroupId,
    productGroups = [],
    productId: productIdProp,
    onCancel: offModal,
    onEditSuccess,
    ...rest
  } = props;

  const [formInstance] = Form.useForm();

  const [groupId, setGroupId] = useState(defaultGroupId);

  const { factsPanelsGroup: allFactsPanels } =
    useFactsPanelsGroup(productIdProp);

  const editMutation = useSaveQaGroupProduct(productIdProp);

  const { groupDetail, isLoading } = useGetQaGroupDetail({
    productId: productIdProp,
    groupId,
  });

  const { handleSyncSingleQaDetailGroup } = useInvalidateGroupDetail(
    productIdProp,
    groupId
  );

  const {
    ingredientGroups = [],
    allergenGroups = [],
    nutritionFacts = [],
    supplementFacts = [],
    drugFacts = [],
    petNutritionFacts = [],
  } = groupDetail ?? {};

  const mappingAllergenGroups = useMemo(
    () => mappingAllergenGroupsWhenEditing(allergenGroups),
    [allergenGroups]
  );

  const factsPanelGroups = useMemo(
    () => [
      ...nutritionFacts,
      ...supplementFacts,
      ...drugFacts,
      ...petNutritionFacts,
    ],
    [nutritionFacts, supplementFacts, drugFacts, petNutritionFacts]
  );

  useInitialFormGroup({
    formInstance,
    groupDetail,
  });

  const handleSaveGroup = async () => {
    const {
      ingredientGroupIndices,
      allergenGroupIndices,
      factsPanelsGroupIndices,
    } = await formInstance.getFieldsValue();

    const {
      supplementFactIds,
      nutritionFactIds,
      drugFactIds,
      petNutritionFactIds,
    } = separateSupplementAndNutritionIds(
      allFactsPanels,
      factsPanelsGroupIndices
    );

    const params = {
      productItemId: productIdProp,
      groupId,
      ingredientGroupIndices: ingredientGroupIndices ?? null,
      allergenGroupIndices: allergenGroupIndices ?? null,
      nutritionFactIds,
      supplementFactIds,
      drugFactIds,
      petNutritionFactIds,
    };

    editMutation.mutate(params, {
      onSuccess: (result) => {
        result?.isSuccess &&
          CustomNotification.success('Edit group data successfully');
        formInstance.resetFields();
        offModal();
        handleSyncSingleQaDetailGroup();
        onEditSuccess && onEditSuccess();
      },
    });
  };

  return (
    <StyledModal
      {...defaultModalProps}
      title='Edit Group'
      onCancel={offModal}
      onOk={handleSaveGroup}
      okButtonProps={{
        loading: editMutation.isLoading,
      }}
      {...rest}
    >
      <WithLoading loading={isLoading}>
        <Form form={formInstance}>
          <GroupName>
            <WrapperSelect
              allowClear={false}
              placeholder='Please select a group name'
              onChange={(value) => setGroupId(value)}
              defaultValue={groupId}
            >
              {productGroups.map((group) => {
                return (
                  <Select.Option key={group.groupId} value={group.groupId}>
                    {group.groupName}
                  </Select.Option>
                );
              })}
            </WrapperSelect>
          </GroupName>
          <IngredientsGroup
            ingredientList={ingredientGroups}
            productId={productIdProp}
          />
          <AllergensGroup
            allergenList={mappingAllergenGroups}
            productId={productIdProp}
          />
          <FactsPanelGroup
            factsPanelsGroup={factsPanelGroups}
            productId={productIdProp}
          />
        </Form>
      </WithLoading>
    </StyledModal>
  );
};
